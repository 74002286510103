import Panel from '../../components/Panel'

/*
Used icon sets:
vsc
io
tb
im
md
fi
sl
*/

export default function Acknowledgments() {
    return (
        <Panel 
            contentStyle={{display:'inline-block', whiteSpace: 'pre-wrap', }}
            spacing
            header="Acknowledgments"
        >
            All the graphics on this website have been generated by <a href="https://www.midjourney.com/" target="_blank">MidJourney</a>.<br/>
            All icons have been provided by <a href="https://github.com/react-icons/react-icons" target="_blank">React Icons</a> project. Special thanks to creators of those icon sets: <a target="_blank" href="https://github.com/microsoft/vscode-codicons">VS Code Icons</a>, <a target="_blank" href="https://ionicons.com/">Ionicons</a>, <a target="_blank" href="https://github.com/tabler/tabler-icons">Tabler Icons</a>, <a target="_blank" href="https://github.com/Keyamoon/IcoMoon-Free">IcoMoon Free</a>, <a target="_blank" href="http://google.github.io/material-design-icons/">Material Design</a>, <a target="_blank" href="https://github.com/icons8/flat-color-icons">Flat Color Icons</a>, <a target="_blank" href="https://thesabbir.github.io/simple-line-icons/">Simple Line Icons</a>.
        </Panel>
    )
}