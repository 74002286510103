import { styled } from 'goober'
import { motion } from 'framer-motion'
import { useColorMode, useTheme, useThemeColors } from 'domain/theme'
import { ColorTheme } from 'domain/theme/types'

const Container = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 14px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.medium}px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.small}px) {
        margin-bottom: -22px;
    }
`

const Name = styled('div')`
    display: flex;
    align-self: center;
    padding-left: 0px;
    text-align: center;

    font-family: Logo;
    font-weight: bolder;
    font-size: 24px;
    line-height: 24px;
    color: ${props => props.theme.text};

    @media only screen and (max-width: ${props => props.theme.breakpoints.medium}px) {
        display: none;
    }
`

const LOGO_VARIANTS = {
    [`${ColorTheme.Dark}_rest`]: { scale: 1 },
    [`${ColorTheme.Dark}_hover`]: { scale: 1.06 },
    [`${ColorTheme.Dark}_press`]: { scale: 1 },
    [`${ColorTheme.Light}_rest`]: { scale: 1 },
    [`${ColorTheme.Light}_hover`]: { scale: 1.06 },
    [`${ColorTheme.Light}_press`]: { scale: 1 },
}

function Icon() {
    const colors = useThemeColors()

    const LEAF_VARIANTS = {
        [`${ColorTheme.Dark}_rest`]: { fill: colors.text },
        [`${ColorTheme.Dark}_hover`]: { fill: '#4fa177' },
        [`${ColorTheme.Light}_rest`]: { fill: colors.text },
        [`${ColorTheme.Light}_hover`]: { fill: '#4fa177' },
    }

    return  (
        <motion.svg version="1.0" xmlns="http://www.w3.org/2000/svg" variants={LOGO_VARIANTS} width={64} viewBox="0 0 693.000000 693.000000" preserveAspectRatio="xMidYMid meet">
            <g fill={colors.text} transform="translate(0.000000,693.000000) scale(0.100000,-0.100000)" >
                <path d="M3355 6840 c-219 -34 -403 -175 -513 -395 -58 -116 -65 -154 -71
                -374 l-6 -204 -110 -22 c-144 -30 -240 -65 -239 -87 2 -21 125 -208 137 -208
                5 0 39 13 75 29 118 52 171 50 192 -5 21 -55 -20 -88 -145 -115 -33 -7 -62
                -14 -64 -15 -11 -9 102 -138 260 -299 98 -99 208 -216 245 -260 212 -254 411
                -655 475 -960 66 -319 67 -584 3 -840 -77 -309 -213 -538 -473 -795 -255 -254
                -520 -406 -742 -426 l-77 -7 -5 -251 c-6 -253 -11 -301 -62 -529 l-17 -78
                -247 3 c-234 3 -248 4 -293 26 -56 28 -122 91 -151 146 -21 40 -22 52 -27 528
                l-5 486 -65 69 c-36 37 -82 88 -103 113 l-37 45 0 -304 0 -305 -38 -45 c-109
                -129 -373 -462 -391 -492 -31 -51 -28 -101 10 -143 36 -41 84 -55 123 -34 28
                14 96 89 216 236 41 51 77 92 80 92 3 0 5 -62 5 -138 0 -134 1 -139 33 -206
                62 -133 193 -231 369 -277 65 -17 111 -21 276 -22 l197 -2 -6 -282 c-4 -154
                -3 -302 0 -327 l7 -46 105 0 104 0 0 325 0 325 908 0 c1747 0 1723 0 1861 46
                42 14 109 47 149 74 l72 50 0 -214 c0 -199 1 -215 20 -239 26 -32 79 -51 111
                -38 13 5 35 21 49 36 l25 27 0 464 c0 255 1 464 2 464 0 0 60 -74 132 -165
                125 -156 166 -195 208 -195 31 0 83 39 100 75 27 56 8 94 -134 271 -70 88
                -167 208 -215 269 l-88 110 0 1165 c0 1203 -4 1353 -41 1535 -89 440 -370 840
                -789 1122 -166 112 -488 243 -597 243 l-23 0 0 164 c0 245 -31 373 -127 518
                -138 210 -399 326 -648 288z m220 -205 c133 -35 243 -127 311 -260 l39 -78 3
                -199 3 -198 -474 0 -473 0 1 188 c1 179 3 189 28 244 72 158 195 271 331 304
                71 17 161 17 231 -1z m345 -954 c16 -30 12 -76 -8 -95 -17 -15 -60 -16 -452
                -14 l-433 3 -19 26 c-21 28 -18 57 8 84 13 13 77 15 454 15 424 0 440 -1 450
                -19z m382 -87 c250 -89 513 -274 690 -483 159 -188 242 -338 302 -546 70 -244
                67 -165 64 -1810 l-3 -1480 -33 -67 c-47 -96 -136 -167 -247 -197 -28 -7 -439
                -11 -1325 -11 -1208 -1 -1285 0 -1297 16 -19 26 -16 68 6 95 l19 24 1281 2
                1281 3 43 21 c55 28 84 55 110 104 l22 40 0 1490 0 1490 -29 125 c-71 310
                -183 509 -413 730 -158 153 -347 275 -503 325 -124 39 -149 64 -124 123 11 27
                18 32 48 32 19 0 68 -12 108 -26z"/>
                <path d="M1642 1678 l3 -424 27 -41 c43 -66 75 -75 275 -79 l174 -3 9 23 c17
                47 59 274 69 381 14 141 20 325 11 325 -4 0 -46 7 -93 15 -138 24 -292 93
                -430 193 l-48 34 3 -424z"/>
                <motion.path id="leaf" variants={LEAF_VARIANTS} d="M2236 5752 c-7 -53 -69 -198 -117 -275 -46 -73 -162 -227 -170 -227
                -4 0 -11 10 -14 21 -8 23 -16 12 -124 -161 l-40 -64 -11 28 -10 28 -22 -27
                c-11 -15 -47 -77 -78 -137 l-58 -111 -19 38 -20 38 -20 -29 c-30 -43 -88 -161
                -107 -221 -10 -29 -19 -53 -22 -53 -2 0 -11 14 -20 32 -15 29 -17 30 -27 13
                -16 -29 -57 -196 -57 -235 -1 -59 -15 -68 -41 -27 -13 20 -27 37 -30 37 -17 0
                -60 -212 -75 -362 -3 -38 -10 -68 -15 -68 -5 0 -16 11 -25 25 -31 47 -37 34
                -54 -113 -6 -51 -11 -167 -11 -258 -1 -161 -2 -166 -21 -160 -20 5 -20 2 -14
                -82 25 -308 93 -504 264 -762 157 -237 455 -489 699 -590 81 -34 219 -68 230
                -57 4 4 9 159 11 345 l4 337 -143 98 c-79 54 -214 148 -299 208 -85 60 -205
                143 -267 184 -61 41 -113 80 -116 87 -7 22 12 48 36 48 22 0 109 -58 527 -355
                102 -72 201 -140 220 -152 l36 -22 0 264 1 264 -71 42 c-110 66 -396 248 -551
                351 -115 76 -140 97 -140 117 0 13 8 29 17 34 14 9 39 -4 133 -68 64 -44 155
                -105 203 -136 158 -102 347 -222 377 -239 l30 -17 3 216 c2 165 0 220 -10 232
                -11 15 -136 92 -496 308 -100 59 -121 83 -101 107 18 21 46 17 92 -15 40 -27
                450 -276 492 -298 20 -11 20 -7 24 177 2 103 0 200 -4 216 -3 17 -20 38 -38
                50 -263 170 -316 218 -272 250 22 16 20 18 186 -103 63 -46 116 -83 119 -83 3
                0 5 129 5 286 0 283 0 287 22 301 19 13 24 12 42 -6 20 -20 21 -31 21 -321 0
                -165 2 -300 5 -300 8 0 173 111 255 170 96 70 104 73 130 52 31 -25 20 -53
                -33 -82 -23 -13 -113 -71 -199 -128 l-158 -104 0 -214 c0 -118 3 -214 6 -214
                12 0 399 236 524 320 70 46 87 52 111 39 14 -7 19 -19 17 -36 -2 -21 -19 -35
                -84 -72 -45 -25 -192 -113 -328 -195 l-246 -148 0 -205 c0 -199 0 -203 19
                -193 31 16 275 173 491 315 107 70 201 130 210 132 22 6 43 -21 35 -46 -5 -18
                -196 -146 -570 -384 -82 -53 -158 -102 -168 -109 -16 -12 -17 -33 -15 -266 l3
                -253 105 73 c162 114 448 326 513 380 33 26 69 48 82 48 29 0 48 -23 41 -48
                -4 -11 -163 -131 -354 -267 -191 -137 -357 -257 -369 -268 l-23 -20 0 -345 0
                -345 52 7 c141 19 332 107 500 232 186 136 395 389 505 608 112 223 137 335
                137 626 1 135 -4 247 -13 300 -15 98 -63 307 -83 363 -12 34 -16 37 -27 23
                -21 -26 -28 -19 -35 35 -9 69 -46 164 -110 284 -87 162 -229 385 -246 385 -4
                0 -13 -13 -20 -30 -15 -36 -26 -38 -34 -6 -12 49 -127 203 -242 326 -141 151
                -179 198 -257 315 -57 87 -167 297 -167 322 0 6 -4 14 -9 17 -5 3 -11 -16 -15
                -42z"/>
            </g>
        </motion.svg>
    )
}

export default function Logo() {
    const theme = useTheme()
    return (
        <Container animate={`${theme}_rest`} whileHover={`${theme}_hover`} whileTap={`${theme}_press`}>
            <Icon />
            <Name>Wander<br/>Garden</Name>
        </Container>
    )
}

