const airportsMap = {
  "00AK": {
      "icao": "00AK",
      "iata": "",
      "name": "Lowell Field",
      "city": "Anchor Point",
      "state": "Alaska",
      "country": "US",
      "elevation": 450,
      "lat": 59.94919968,
      "lon": -151.695999146,
      "tz": "America\/Anchorage"
  },
  "00AL": {
      "icao": "00AL",
      "iata": "",
      "name": "Epps Airpark",
      "city": "Harvest",
      "state": "Alabama",
      "country": "US",
      "elevation": 820,
      "lat": 34.8647994995,
      "lon": -86.7703018188,
      "tz": "America\/Chicago"
  },
  "00AZ": {
      "icao": "00AZ",
      "iata": "",
      "name": "Cordes Airport",
      "city": "Cordes",
      "state": "Arizona",
      "country": "US",
      "elevation": 3810,
      "lat": 34.3055992126,
      "lon": -112.1650009155,
      "tz": "America\/Phoenix"
  },
  "00CA": {
      "icao": "00CA",
      "iata": "",
      "name": "Goldstone \/Gts\/ Airport",
      "city": "Barstow",
      "state": "California",
      "country": "US",
      "elevation": 3038,
      "lat": 35.3504981995,
      "lon": -116.888000488,
      "tz": "America\/Los_Angeles"
  },
  "00CO": {
      "icao": "00CO",
      "iata": "",
      "name": "Cass Field",
      "city": "Briggsdale",
      "state": "Colorado",
      "country": "US",
      "elevation": 4830,
      "lat": 40.6222000122,
      "lon": -104.34400177,
      "tz": "America\/Denver"
  },
  "00FA": {
      "icao": "00FA",
      "iata": "",
      "name": "Grass Patch Airport",
      "city": "Bushnell",
      "state": "Florida",
      "country": "US",
      "elevation": 53,
      "lat": 28.6455001831,
      "lon": -82.21900177,
      "tz": "America\/New_York"
  },
  "00FL": {
      "icao": "00FL",
      "iata": "",
      "name": "River Oak Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 27.2308998108,
      "lon": -80.9692001343,
      "tz": "America\/New_York"
  },
  "00GA": {
      "icao": "00GA",
      "iata": "",
      "name": "Lt World Airport",
      "city": "Lithonia",
      "state": "Georgia",
      "country": "US",
      "elevation": 700,
      "lat": 33.7675018311,
      "lon": -84.0682983398,
      "tz": "America\/New_York"
  },
  "00ID": {
      "icao": "00ID",
      "iata": "",
      "name": "Delta Shores Airport",
      "city": "Clark Fork",
      "state": "Idaho",
      "country": "US",
      "elevation": 2064,
      "lat": 48.1453018188,
      "lon": -116.2139968872,
      "tz": "America\/Los_Angeles"
  },
  "00IL": {
      "icao": "00IL",
      "iata": "",
      "name": "Hammer Airport",
      "city": "Polo",
      "state": "Illinois",
      "country": "US",
      "elevation": 840,
      "lat": 41.9784011841,
      "lon": -89.5604019165,
      "tz": "America\/Chicago"
  },
  "00IS": {
      "icao": "00IS",
      "iata": "",
      "name": "Hayenga's Cant Find Farms Airport",
      "city": "Kings",
      "state": "Illinois",
      "country": "US",
      "elevation": 820,
      "lat": 40.0256004333,
      "lon": -89.1229019165,
      "tz": "America\/Chicago"
  },
  "00KS": {
      "icao": "00KS",
      "iata": "",
      "name": "Hayden Farm Airport",
      "city": "Gardner",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 38.7277984619,
      "lon": -94.9304962158,
      "tz": "America\/Chicago"
  },
  "00KY": {
      "icao": "00KY",
      "iata": "",
      "name": "Robbins Roost Airport",
      "city": "Stanford",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1265,
      "lat": 37.4094009399,
      "lon": -84.6196975708,
      "tz": "America\/New_York"
  },
  "00LS": {
      "icao": "00LS",
      "iata": "",
      "name": "Lejeune Airport",
      "city": "Esterwood",
      "state": "Louisiana",
      "country": "US",
      "elevation": 12,
      "lat": 30.1362991333,
      "lon": -92.429397583,
      "tz": "America\/Chicago"
  },
  "00MD": {
      "icao": "00MD",
      "iata": "",
      "name": "Slater Field",
      "city": "Federalsburg",
      "state": "Maryland",
      "country": "US",
      "elevation": 45,
      "lat": 38.7570991516,
      "lon": -75.7537994385,
      "tz": "America\/New_York"
  },
  "00MN": {
      "icao": "00MN",
      "iata": "",
      "name": "Battle Lake Municipal Airport",
      "city": "Battle Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1365,
      "lat": 46.2999992371,
      "lon": -95.700302124,
      "tz": "America\/Chicago"
  },
  "00MO": {
      "icao": "00MO",
      "iata": "",
      "name": "Cooper Flying Service Airport",
      "city": "Alba",
      "state": "Missouri",
      "country": "US",
      "elevation": 970,
      "lat": 37.2028007507,
      "lon": -94.412399292,
      "tz": "America\/Chicago"
  },
  "00MT": {
      "icao": "00MT",
      "iata": "",
      "name": "Sands Ranch Airport",
      "city": "Havre",
      "state": "Montana",
      "country": "US",
      "elevation": 2600,
      "lat": 48.5374984741,
      "lon": -109.7050018311,
      "tz": "America\/Denver"
  },
  "00NC": {
      "icao": "00NC",
      "iata": "",
      "name": "North Raleigh Airport",
      "city": "Louisburg",
      "state": "North Carolina",
      "country": "US",
      "elevation": 348,
      "lat": 36.0852012634,
      "lon": -78.3713989258,
      "tz": "America\/New_York"
  },
  "00NY": {
      "icao": "00NY",
      "iata": "",
      "name": "Weiss Airfield",
      "city": "West Bloomfield",
      "state": "New York",
      "country": "US",
      "elevation": 1000,
      "lat": 42.900100708,
      "lon": -77.4997024536,
      "tz": "America\/New_York"
  },
  "00OH": {
      "icao": "00OH",
      "iata": "",
      "name": "Exit 3 Airport",
      "city": "Wauseon",
      "state": "Ohio",
      "country": "US",
      "elevation": 785,
      "lat": 41.5909004211,
      "lon": -84.1421966553,
      "tz": "America\/New_York"
  },
  "00PN": {
      "icao": "00PN",
      "iata": "",
      "name": "Ferrell Field",
      "city": "Mercer",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1301,
      "lat": 41.2995,
      "lon": -80.211111,
      "tz": "America\/New_York"
  },
  "00PS": {
      "icao": "00PS",
      "iata": "",
      "name": "Thomas Field",
      "city": "Loysville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 815,
      "lat": 40.3777999878,
      "lon": -77.3653030396,
      "tz": "America\/New_York"
  },
  "00SC": {
      "icao": "00SC",
      "iata": "",
      "name": "Flying O Airport",
      "city": "Sumter",
      "state": "South Carolina",
      "country": "US",
      "elevation": 150,
      "lat": 34.0093994141,
      "lon": -80.2671966553,
      "tz": "America\/New_York"
  },
  "00TN": {
      "icao": "00TN",
      "iata": "",
      "name": "Ragsdale Road Airport",
      "city": "Manchester",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1100,
      "lat": 35.5158996582,
      "lon": -85.9535980225,
      "tz": "America\/Chicago"
  },
  "00TS": {
      "icao": "00TS",
      "iata": "",
      "name": "Alpine Range Airport",
      "city": "Everman",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 32.6076011658,
      "lon": -97.2419967651,
      "tz": "America\/Chicago"
  },
  "00VA": {
      "icao": "00VA",
      "iata": "",
      "name": "Vaughan Airport",
      "city": "Alton",
      "state": "Virginia",
      "country": "US",
      "elevation": 551,
      "lat": 36.57600021,
      "lon": -78.9991684,
      "tz": "America\/New_York"
  },
  "00VI": {
      "icao": "00VI",
      "iata": "",
      "name": "Groundhog Mountain Airport",
      "city": "Hillsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 2680,
      "lat": 36.6632995605,
      "lon": -80.49949646,
      "tz": "America\/New_York"
  },
  "00WA": {
      "icao": "00WA",
      "iata": "",
      "name": "Howell Airport",
      "city": "Longbranch",
      "state": "Washington",
      "country": "US",
      "elevation": 150,
      "lat": 47.1783981323,
      "lon": -122.7720031738,
      "tz": "America\/Los_Angeles"
  },
  "00WI": {
      "icao": "00WI",
      "iata": "",
      "name": "Northern Lite Airport",
      "city": "Waupaca",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 860,
      "lat": 44.3042984009,
      "lon": -89.0501022339,
      "tz": "America\/Chicago"
  },
  "00WN": {
      "icao": "00WN",
      "iata": "",
      "name": "Hawks Run Airport",
      "city": "Asotin",
      "state": "Washington",
      "country": "US",
      "elevation": 2900,
      "lat": 46.25,
      "lon": -117.2490005493,
      "tz": "America\/Los_Angeles"
  },
  "00WV": {
      "icao": "00WV",
      "iata": "",
      "name": "Lazy J. Aerodrome",
      "city": "Beverly",
      "state": "West Virginia",
      "country": "US",
      "elevation": 2060,
      "lat": 38.8288993835,
      "lon": -79.8660964966,
      "tz": "America\/New_York"
  },
  "00XS": {
      "icao": "00XS",
      "iata": "",
      "name": "L P Askew Farms Airport",
      "city": "O'Donnell",
      "state": "Texas",
      "country": "US",
      "elevation": 3110,
      "lat": 33.0334014893,
      "lon": -101.9339981079,
      "tz": "America\/Chicago"
  },
  "01AL": {
      "icao": "01AL",
      "iata": "",
      "name": "Ware Island Airport",
      "city": "Clanton",
      "state": "Alabama",
      "country": "US",
      "elevation": 344,
      "lat": 32.9459991455,
      "lon": -86.5139007568,
      "tz": "America\/Chicago"
  },
  "01CL": {
      "icao": "01CL",
      "iata": "",
      "name": "Swansboro Country Airport",
      "city": "Placerville",
      "state": "California",
      "country": "US",
      "elevation": 2594,
      "lat": 38.7999000549,
      "lon": -120.7340011597,
      "tz": "America\/Los_Angeles"
  },
  "01FA": {
      "icao": "01FA",
      "iata": "",
      "name": "Rybolt Ranch Airport",
      "city": "Orlando",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 28.5893993378,
      "lon": -81.144203186,
      "tz": "America\/New_York"
  },
  "01FL": {
      "icao": "01FL",
      "iata": "",
      "name": "Cedar Knoll Flying Ranch Airport",
      "city": "Geneva",
      "state": "Florida",
      "country": "US",
      "elevation": 19,
      "lat": 28.7819004059,
      "lon": -81.1592025757,
      "tz": "America\/New_York"
  },
  "01GE": {
      "icao": "01GE",
      "iata": "",
      "name": "The Farm Airport",
      "city": "Wrightsville",
      "state": "Georgia",
      "country": "US",
      "elevation": 375,
      "lat": 32.6744003296,
      "lon": -82.7711029053,
      "tz": "America\/New_York"
  },
  "01IA": {
      "icao": "01IA",
      "iata": "",
      "name": "Stender Airport",
      "city": "Maysville",
      "state": "Iowa",
      "country": "US",
      "elevation": 725,
      "lat": 41.6610984802,
      "lon": -90.7413024902,
      "tz": "America\/Chicago"
  },
  "01ID": {
      "icao": "01ID",
      "iata": "",
      "name": "Lava Hot Springs Airport",
      "city": "Lava Hot Springs",
      "state": "Idaho",
      "country": "US",
      "elevation": 5268,
      "lat": 42.6082000732,
      "lon": -112.0319976807,
      "tz": "America\/Boise"
  },
  "01II": {
      "icao": "01II",
      "iata": "",
      "name": "Myers Field",
      "city": "Lizton",
      "state": "Indiana",
      "country": "US",
      "elevation": 950,
      "lat": 39.8849983215,
      "lon": -86.5066986084,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "01IS": {
      "icao": "01IS",
      "iata": "",
      "name": "William E. Koenig Airport",
      "city": "Dow",
      "state": "Illinois",
      "country": "US",
      "elevation": 670,
      "lat": 39.0162010193,
      "lon": -90.3181991577,
      "tz": "America\/Chicago"
  },
  "01KS": {
      "icao": "01KS",
      "iata": "",
      "name": "Flying N Ranch Airport",
      "city": "Lost Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 1485,
      "lat": 38.540599823,
      "lon": -97.0033035278,
      "tz": "America\/Chicago"
  },
  "01LA": {
      "icao": "01LA",
      "iata": "",
      "name": "Barham Airport",
      "city": "Oak Ridge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 90,
      "lat": 32.638999939,
      "lon": -91.7736968994,
      "tz": "America\/Chicago"
  },
  "01LL": {
      "icao": "01LL",
      "iata": "",
      "name": "Schumaier Restricted Landing Area",
      "city": "Pinckneyville",
      "state": "Illinois",
      "country": "US",
      "elevation": 555,
      "lat": 38.1258010864,
      "lon": -89.4638977051,
      "tz": "America\/Chicago"
  },
  "01LS": {
      "icao": "01LS",
      "iata": "",
      "name": "Country Breeze Airport",
      "city": "Slaughter",
      "state": "Louisiana",
      "country": "US",
      "elevation": 125,
      "lat": 30.7059993744,
      "lon": -91.0774002075,
      "tz": "America\/Chicago"
  },
  "01MT": {
      "icao": "01MT",
      "iata": "",
      "name": "Crystal Lakes Resort Airport",
      "city": "Fortine",
      "state": "Montana",
      "country": "US",
      "elevation": 3141,
      "lat": 48.789100647,
      "lon": -114.8799972534,
      "tz": "America\/Denver"
  },
  "01NC": {
      "icao": "01NC",
      "iata": "",
      "name": "Topsail Airpark",
      "city": "Holly Ridge",
      "state": "North Carolina",
      "country": "US",
      "elevation": 65,
      "lat": 34.4752998352,
      "lon": -77.5813980103,
      "tz": "America\/New_York"
  },
  "01NE": {
      "icao": "01NE",
      "iata": "",
      "name": "Detour Airport",
      "city": "Wellfleet",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3000,
      "lat": 40.8436012268,
      "lon": -100.6529998779,
      "tz": "America\/Chicago"
  },
  "01NH": {
      "icao": "01NH",
      "iata": "",
      "name": "Moore Airfield",
      "city": "Enfield",
      "state": "New Hampshire",
      "country": "US",
      "elevation": 835,
      "lat": 43.6445007324,
      "lon": -72.0869979858,
      "tz": "America\/New_York"
  },
  "01NM": {
      "icao": "01NM",
      "iata": "",
      "name": "Champion Ranch Airport",
      "city": "Artesia",
      "state": "New Mexico",
      "country": "US",
      "elevation": 3630,
      "lat": 33.008611,
      "lon": -104.540278,
      "tz": "America\/Denver"
  },
  "01NV": {
      "icao": "01NV",
      "iata": "",
      "name": "Lantana Ranch Airport",
      "city": "Yerington",
      "state": "Nevada",
      "country": "US",
      "elevation": 4600,
      "lat": 38.7639007568,
      "lon": -119.0270004272,
      "tz": "America\/Los_Angeles"
  },
  "01OK": {
      "icao": "01OK",
      "iata": "",
      "name": "Lawrence Airport",
      "city": "Eakly",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1525,
      "lat": 35.2944984436,
      "lon": -98.6364974976,
      "tz": "America\/Chicago"
  },
  "01OR": {
      "icao": "01OR",
      "iata": "",
      "name": "Red & White Flying Service Airport",
      "city": "Silver Lake",
      "state": "Oregon",
      "country": "US",
      "elevation": 4346,
      "lat": 43.1193008423,
      "lon": -121.0449981689,
      "tz": "America\/Los_Angeles"
  },
  "01PN": {
      "icao": "01PN",
      "iata": "",
      "name": "Bierly(Personal Use) Airport",
      "city": "Bellefonte",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 960,
      "lat": 40.9305992126,
      "lon": -77.738899231,
      "tz": "America\/New_York"
  },
  "01PS": {
      "icao": "01PS",
      "iata": "",
      "name": "Nort's Resort Airport",
      "city": "Meshoppen",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1040,
      "lat": 41.5959014893,
      "lon": -76.0296020508,
      "tz": "America\/New_York"
  },
  "01SC": {
      "icao": "01SC",
      "iata": "",
      "name": "York Airport",
      "city": "York",
      "state": "South Carolina",
      "country": "US",
      "elevation": 779,
      "lat": 35.0321006775,
      "lon": -81.2527999878,
      "tz": "America\/New_York"
  },
  "01TE": {
      "icao": "01TE",
      "iata": "",
      "name": "Smith Field",
      "city": "Forney",
      "state": "Texas",
      "country": "US",
      "elevation": 505,
      "lat": 32.7375984192,
      "lon": -96.4280014038,
      "tz": "America\/Chicago"
  },
  "01TN": {
      "icao": "01TN",
      "iata": "",
      "name": "Colonial Air Park",
      "city": "Collierville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 370,
      "lat": 34.9958992004,
      "lon": -89.7305984497,
      "tz": "America\/Chicago"
  },
  "01TX": {
      "icao": "01TX",
      "iata": "",
      "name": "Mims Farm Ultralightport",
      "city": "Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 610,
      "lat": 32.3875999451,
      "lon": -96.8775024414,
      "tz": "America\/Chicago"
  },
  "01UT": {
      "icao": "01UT",
      "iata": "",
      "name": "La Sal Junction Airport",
      "city": "La Sal",
      "state": "Utah",
      "country": "US",
      "elevation": 6000,
      "lat": 38.3083000183,
      "lon": -109.3960037231,
      "tz": "America\/Denver"
  },
  "01VA": {
      "icao": "01VA",
      "iata": "",
      "name": "Pickles Airport",
      "city": "Berryville",
      "state": "Virginia",
      "country": "US",
      "elevation": 500,
      "lat": 39.125,
      "lon": -77.9250030518,
      "tz": "America\/New_York"
  },
  "01WI": {
      "icao": "01WI",
      "iata": "",
      "name": "Prehn Cranberry Company Airport",
      "city": "Tomah",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 930,
      "lat": 44.0099983215,
      "lon": -90.3891983032,
      "tz": "America\/Chicago"
  },
  "01WY": {
      "icao": "01WY",
      "iata": "",
      "name": "Keyhole Airport",
      "city": "Moorcroft",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4250,
      "lat": 44.3471984863,
      "lon": -104.8109970093,
      "tz": "America\/Denver"
  },
  "02AK": {
      "icao": "02AK",
      "iata": "",
      "name": "Rustic Wilderness Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 190,
      "lat": 61.8768997192,
      "lon": -150.0980072021,
      "tz": "America\/Anchorage"
  },
  "02AL": {
      "icao": "02AL",
      "iata": "",
      "name": "Bass Field",
      "city": "Foley",
      "state": "Alabama",
      "country": "US",
      "elevation": 61,
      "lat": 30.3715000153,
      "lon": -87.7643966675,
      "tz": "America\/Chicago"
  },
  "02AR": {
      "icao": "02AR",
      "iata": "",
      "name": "Three Rivers Airport",
      "city": "Little Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 264,
      "lat": 34.8224449158,
      "lon": -92.4444198608,
      "tz": "America\/Chicago"
  },
  "02AZ": {
      "icao": "02AZ",
      "iata": "",
      "name": "Winchester Farm Airstrip",
      "city": "Willcox",
      "state": "Arizona",
      "country": "US",
      "elevation": 4200,
      "lat": 32.3763999939,
      "lon": -109.93699646,
      "tz": "America\/Phoenix"
  },
  "02CD": {
      "icao": "02CD",
      "iata": "",
      "name": "Shannon Field",
      "city": "Clarksdale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 165,
      "lat": 34.1296005249,
      "lon": -90.5240020752,
      "tz": "America\/Chicago"
  },
  "02CL": {
      "icao": "02CL",
      "iata": "",
      "name": "Conover Air Lodge Airport",
      "city": "Frazier Park",
      "state": "California",
      "country": "US",
      "elevation": 5160,
      "lat": 34.761100769,
      "lon": -119.0589981079,
      "tz": "America\/Los_Angeles"
  },
  "02CO": {
      "icao": "02CO",
      "iata": "",
      "name": "Mc Cullough Airport",
      "city": "Monte Vista",
      "state": "Colorado",
      "country": "US",
      "elevation": 7615,
      "lat": 37.6432991028,
      "lon": -106.04699707,
      "tz": "America\/Denver"
  },
  "02FA": {
      "icao": "02FA",
      "iata": "",
      "name": "Osborn Airfield",
      "city": "Groveland",
      "state": "Florida",
      "country": "US",
      "elevation": 121,
      "lat": 28.5266990662,
      "lon": -81.8748016357,
      "tz": "America\/New_York"
  },
  "02FL": {
      "icao": "02FL",
      "iata": "",
      "name": "Cuchens Airport",
      "city": "Defuniak Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 215,
      "lat": 30.642778,
      "lon": -86.123889,
      "tz": "America\/Chicago"
  },
  "02GA": {
      "icao": "02GA",
      "iata": "",
      "name": "Doug Bolton Field",
      "city": "Commerce",
      "state": "Georgia",
      "country": "US",
      "elevation": 884,
      "lat": 34.2025985718,
      "lon": -83.4290008545,
      "tz": "America\/New_York"
  },
  "02GE": {
      "icao": "02GE",
      "iata": "",
      "name": "Etowah Fields Airport",
      "city": "Euharlee",
      "state": "Georgia",
      "country": "US",
      "elevation": 710,
      "lat": 34.1753005981,
      "lon": -84.9244003296,
      "tz": "America\/New_York"
  },
  "02ID": {
      "icao": "02ID",
      "iata": "",
      "name": "Morgan Ranch Airport",
      "city": "Cascade",
      "state": "Idaho",
      "country": "US",
      "elevation": 5634,
      "lat": 44.5555000305,
      "lon": -115.3050003052,
      "tz": "America\/Boise"
  },
  "02II": {
      "icao": "02II",
      "iata": "",
      "name": "King Ultralightport",
      "city": "Westfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 925,
      "lat": 40.0623016357,
      "lon": -86.2105026245,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "02IN": {
      "icao": "02IN",
      "iata": "",
      "name": "Diamond P. Field",
      "city": "Muncie",
      "state": "Indiana",
      "country": "US",
      "elevation": 904,
      "lat": 40.2089004517,
      "lon": -85.540802002,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "02KS": {
      "icao": "02KS",
      "iata": "",
      "name": "Jmj Landing Airport",
      "city": "St Marys",
      "state": "Kansas",
      "country": "US",
      "elevation": 1170,
      "lat": 39.2221984863,
      "lon": -96.0552978516,
      "tz": "America\/Chicago"
  },
  "02ME": {
      "icao": "02ME",
      "iata": "",
      "name": "Old Acton Airfield",
      "city": "Acton",
      "state": "Maine",
      "country": "US",
      "elevation": 700,
      "lat": 43.5373001099,
      "lon": -70.929397583,
      "tz": "America\/New_York"
  },
  "02MI": {
      "icao": "02MI",
      "iata": "",
      "name": "Fairplains Airpark",
      "city": "Greenville",
      "state": "Michigan",
      "country": "US",
      "elevation": 850,
      "lat": 43.1571006775,
      "lon": -85.1484985352,
      "tz": "America\/Detroit"
  },
  "02MN": {
      "icao": "02MN",
      "iata": "",
      "name": "Greenbush Municipal Airport",
      "city": "Greenbush",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1070,
      "lat": 48.6833000183,
      "lon": -96.1920013428,
      "tz": "America\/Chicago"
  },
  "02MO": {
      "icao": "02MO",
      "iata": "",
      "name": "Troy Airpark",
      "city": "Troy",
      "state": "Missouri",
      "country": "US",
      "elevation": 650,
      "lat": 39.0499992371,
      "lon": -91.0335006714,
      "tz": "America\/Chicago"
  },
  "02MS": {
      "icao": "02MS",
      "iata": "",
      "name": "Watts Field",
      "city": "Rochdale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 153,
      "lat": 34.1001014709,
      "lon": -90.8468017578,
      "tz": "America\/Chicago"
  },
  "02MT": {
      "icao": "02MT",
      "iata": "",
      "name": "Barrett Field",
      "city": "Cascade",
      "state": "Montana",
      "country": "US",
      "elevation": 3350,
      "lat": 47.2374000549,
      "lon": -111.7419967651,
      "tz": "America\/Denver"
  },
  "02MU": {
      "icao": "02MU",
      "iata": "",
      "name": "Timber Line Airpark",
      "city": "Cassville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1550,
      "lat": 36.6542015076,
      "lon": -93.7974014282,
      "tz": "America\/Chicago"
  },
  "02NE": {
      "icao": "02NE",
      "iata": "",
      "name": "Benes Service Airport",
      "city": "Valparaiso",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1550,
      "lat": 41.0745010376,
      "lon": -96.9045028687,
      "tz": "America\/Chicago"
  },
  "02NV": {
      "icao": "02NV",
      "iata": "",
      "name": "Paiute Meadows Airport",
      "city": "Winnemucca",
      "state": "Nevada",
      "country": "US",
      "elevation": 4443,
      "lat": 41.3032989502,
      "lon": -118.5979995728,
      "tz": "America\/Los_Angeles"
  },
  "02OH": {
      "icao": "02OH",
      "iata": "",
      "name": "Zimmerman Airport",
      "city": "Fremont",
      "state": "Ohio",
      "country": "US",
      "elevation": 614,
      "lat": 41.3763999939,
      "lon": -83.0832977295,
      "tz": "America\/New_York"
  },
  "02OI": {
      "icao": "02OI",
      "iata": "",
      "name": "Murtha Airport",
      "city": "Conneaut",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 41.8019981384,
      "lon": -80.5653991699,
      "tz": "America\/New_York"
  },
  "02OR": {
      "icao": "02OR",
      "iata": "",
      "name": "Rowena Dell Airport",
      "city": "The Dalles",
      "state": "Oregon",
      "country": "US",
      "elevation": 705,
      "lat": 45.6814994812,
      "lon": -121.3160018921,
      "tz": "America\/Los_Angeles"
  },
  "02PR": {
      "icao": "02PR",
      "iata": "",
      "name": "Cuylers Airport",
      "city": "Vega Baja",
      "state": "Vega Baja",
      "country": "PR",
      "elevation": 15,
      "lat": 18.4533004761,
      "lon": -66.366897583,
      "tz": "America\/Puerto_Rico"
  },
  "02PS": {
      "icao": "02PS",
      "iata": "",
      "name": "Hughes Ultralightport",
      "city": "Tioga",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1700,
      "lat": 41.9006004333,
      "lon": -77.2303009033,
      "tz": "America\/New_York"
  },
  "02SC": {
      "icao": "02SC",
      "iata": "",
      "name": "Harpers Airport",
      "city": "Estill",
      "state": "South Carolina",
      "country": "US",
      "elevation": 111,
      "lat": 32.7584991455,
      "lon": -81.2248001099,
      "tz": "America\/New_York"
  },
  "02TN": {
      "icao": "02TN",
      "iata": "",
      "name": "Ellis Field",
      "city": "Murfreesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 840,
      "lat": 35.7806015015,
      "lon": -86.584197998,
      "tz": "America\/Chicago"
  },
  "02TX": {
      "icao": "02TX",
      "iata": "",
      "name": "The Palms At Kitty Hawk Airport",
      "city": "New Home",
      "state": "Texas",
      "country": "US",
      "elevation": 3235,
      "lat": 33.3704032898,
      "lon": -101.92288208,
      "tz": "America\/Chicago"
  },
  "02UT": {
      "icao": "02UT",
      "iata": "",
      "name": "Lucin Airport",
      "city": "Lucin",
      "state": "Utah",
      "country": "US",
      "elevation": 4412,
      "lat": 41.3662986755,
      "lon": -113.8430023193,
      "tz": "America\/Denver"
  },
  "02VA": {
      "icao": "02VA",
      "iata": "",
      "name": "The Greenhouse Airport",
      "city": "Culpeper",
      "state": "Virginia",
      "country": "US",
      "elevation": 320,
      "lat": 38.4356994629,
      "lon": -77.8572006226,
      "tz": "America\/New_York"
  },
  "02WA": {
      "icao": "02WA",
      "iata": "",
      "name": "Cawleys South Prairie Airport",
      "city": "South Prairie",
      "state": "Washington",
      "country": "US",
      "elevation": 690,
      "lat": 47.1523017883,
      "lon": -122.09400177,
      "tz": "America\/Los_Angeles"
  },
  "02WI": {
      "icao": "02WI",
      "iata": "",
      "name": "Beer Airport",
      "city": "Hudson",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 920,
      "lat": 45.0318984985,
      "lon": -92.6557998657,
      "tz": "America\/Chicago"
  },
  "02WN": {
      "icao": "02WN",
      "iata": "",
      "name": "Fowler Field",
      "city": "Tonasket",
      "state": "Washington",
      "country": "US",
      "elevation": 2150,
      "lat": 48.7458000183,
      "lon": -119.3190002441,
      "tz": "America\/Los_Angeles"
  },
  "02XS": {
      "icao": "02XS",
      "iata": "",
      "name": "Seidel Ranch Airport",
      "city": "Elroy",
      "state": "Texas",
      "country": "US",
      "elevation": 510,
      "lat": 30.1476993561,
      "lon": -97.6628036499,
      "tz": "America\/Chicago"
  },
  "03AZ": {
      "icao": "03AZ",
      "iata": "",
      "name": "Thompson International Aviation Airport",
      "city": "Hereford",
      "state": "Arizona",
      "country": "US",
      "elevation": 4275,
      "lat": 31.4333992004,
      "lon": -110.0839996338,
      "tz": "America\/Phoenix"
  },
  "03CO": {
      "icao": "03CO",
      "iata": "",
      "name": "Kugel-Strong Airport",
      "city": "Platteville",
      "state": "Colorado",
      "country": "US",
      "elevation": 4950,
      "lat": 40.2125015259,
      "lon": -104.744003296,
      "tz": "America\/Denver"
  },
  "03FA": {
      "icao": "03FA",
      "iata": "",
      "name": "Lake Persimmon Airstrip",
      "city": "Lake Placid",
      "state": "Florida",
      "country": "US",
      "elevation": 70,
      "lat": 27.353099823,
      "lon": -81.4080963135,
      "tz": "America\/New_York"
  },
  "03FD": {
      "icao": "03FD",
      "iata": "",
      "name": "Tharpe Airport",
      "city": "Bonifay",
      "state": "Florida",
      "country": "US",
      "elevation": 115,
      "lat": 30.8288002014,
      "lon": -85.7310028076,
      "tz": "America\/Chicago"
  },
  "03IA": {
      "icao": "03IA",
      "iata": "",
      "name": "East Field",
      "city": "Montezuma",
      "state": "Iowa",
      "country": "US",
      "elevation": 954,
      "lat": 41.5819015503,
      "lon": -92.4613037109,
      "tz": "America\/Chicago"
  },
  "03ID": {
      "icao": "03ID",
      "iata": "",
      "name": "Flying Y Ranch Airport",
      "city": "Council",
      "state": "Idaho",
      "country": "US",
      "elevation": 3180,
      "lat": 44.7943000793,
      "lon": -116.5329971313,
      "tz": "America\/Boise"
  },
  "03II": {
      "icao": "03II",
      "iata": "",
      "name": "Davis Field Ultralightport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 465,
      "lat": 37.9620018005,
      "lon": -87.7789001465,
      "tz": "America\/Chicago"
  },
  "03IL": {
      "icao": "03IL",
      "iata": "",
      "name": "Wix Airport",
      "city": "Monee",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 41.4023017883,
      "lon": -87.8167037964,
      "tz": "America\/Chicago"
  },
  "03IN": {
      "icao": "03IN",
      "iata": "",
      "name": "Heinzman Airport",
      "city": "Arcadia",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 40.1800003052,
      "lon": -86.0124969482,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "03KY": {
      "icao": "03KY",
      "iata": "",
      "name": "Flying H Farms Airport",
      "city": "Henderson",
      "state": "Kentucky",
      "country": "US",
      "elevation": 385,
      "lat": 37.7916984558,
      "lon": -87.5413970947,
      "tz": "America\/Chicago"
  },
  "03MA": {
      "icao": "03MA",
      "iata": "",
      "name": "Hadley Airport",
      "city": "Hadley",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 150,
      "lat": 42.3931007385,
      "lon": -72.5520019531,
      "tz": "America\/New_York"
  },
  "03ME": {
      "icao": "03ME",
      "iata": "",
      "name": "Maple Ridge Airport",
      "city": "Harrison",
      "state": "Maine",
      "country": "US",
      "elevation": 556,
      "lat": 44.0842018127,
      "lon": -70.6272964478,
      "tz": "America\/New_York"
  },
  "03MN": {
      "icao": "03MN",
      "iata": "",
      "name": "Nauerth Land Ranch Airport",
      "city": "Lakefield",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1435,
      "lat": 43.6251983643,
      "lon": -95.2247009277,
      "tz": "America\/Chicago"
  },
  "03MO": {
      "icao": "03MO",
      "iata": "",
      "name": "Cahoochie Airport",
      "city": "Urbana",
      "state": "Missouri",
      "country": "US",
      "elevation": 1010,
      "lat": 37.8844985962,
      "lon": -93.1315994263,
      "tz": "America\/Chicago"
  },
  "03MT": {
      "icao": "03MT",
      "iata": "",
      "name": "Cascade Airport",
      "city": "Cascade",
      "state": "Montana",
      "country": "US",
      "elevation": 3580,
      "lat": 47.2708015442,
      "lon": -111.7220001221,
      "tz": "America\/Denver"
  },
  "03MU": {
      "icao": "03MU",
      "iata": "",
      "name": "Mc Donnell Airport",
      "city": "Archie",
      "state": "Missouri",
      "country": "US",
      "elevation": 874,
      "lat": 38.4925003052,
      "lon": -94.4124984741,
      "tz": "America\/Chicago"
  },
  "03NC": {
      "icao": "03NC",
      "iata": "",
      "name": "Pilots Ridge Airport",
      "city": "Carolina Beach",
      "state": "North Carolina",
      "country": "US",
      "elevation": 35,
      "lat": 34.1043014526,
      "lon": -77.9041976929,
      "tz": "America\/New_York"
  },
  "03ND": {
      "icao": "03ND",
      "iata": "",
      "name": "Olafson Brothers Airport",
      "city": "Edinburg",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1045,
      "lat": 48.6263999939,
      "lon": -97.8290023804,
      "tz": "America\/Chicago"
  },
  "03NE": {
      "icao": "03NE",
      "iata": "",
      "name": "Hyde Ranch Airport",
      "city": "Comstock",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2430,
      "lat": 41.5463981628,
      "lon": -99.331199646,
      "tz": "America\/Chicago"
  },
  "03NV": {
      "icao": "03NV",
      "iata": "",
      "name": "Llama Ranch Airport",
      "city": "Ruby Valley",
      "state": "Nevada",
      "country": "US",
      "elevation": 6120,
      "lat": 40.584400177,
      "lon": -115.297996521,
      "tz": "America\/Los_Angeles"
  },
  "03NY": {
      "icao": "03NY",
      "iata": "",
      "name": "Talmage Field",
      "city": "Riverhead",
      "state": "New York",
      "country": "US",
      "elevation": 95,
      "lat": 40.9584007263,
      "lon": -72.716796875,
      "tz": "America\/New_York"
  },
  "03OH": {
      "icao": "03OH",
      "iata": "",
      "name": "Gibbs Field",
      "city": "Fremont",
      "state": "Ohio",
      "country": "US",
      "elevation": 580,
      "lat": 41.4183998108,
      "lon": -83.0174026489,
      "tz": "America\/New_York"
  },
  "03OK": {
      "icao": "03OK",
      "iata": "",
      "name": "Sahoma Lake Airport",
      "city": "Sapulpa",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 890,
      "lat": 36.0411987305,
      "lon": -96.1613998413,
      "tz": "America\/Chicago"
  },
  "03OR": {
      "icao": "03OR",
      "iata": "",
      "name": "Powwatka Ridge Airport",
      "city": "Troy",
      "state": "Oregon",
      "country": "US",
      "elevation": 3340,
      "lat": 45.8554000854,
      "lon": -117.4840011597,
      "tz": "America\/Los_Angeles"
  },
  "03PS": {
      "icao": "03PS",
      "iata": "",
      "name": "Ziggy's Field",
      "city": "Bellefonte",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1050,
      "lat": 40.8499984741,
      "lon": -77.9056015015,
      "tz": "America\/New_York"
  },
  "03TA": {
      "icao": "03TA",
      "iata": "",
      "name": "Gay Hill Farm Airport",
      "city": "Gay Hill",
      "state": "Texas",
      "country": "US",
      "elevation": 505,
      "lat": 30.2626991272,
      "lon": -96.5001983643,
      "tz": "America\/Chicago"
  },
  "03TE": {
      "icao": "03TE",
      "iata": "",
      "name": "Barronena Ranch Airport",
      "city": "Hebbronville",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 27.4911003113,
      "lon": -98.6695022583,
      "tz": "America\/Chicago"
  },
  "03UT": {
      "icao": "03UT",
      "iata": "",
      "name": "A Z Minerals Corporation Airport",
      "city": "Mexican Hat",
      "state": "Utah",
      "country": "US",
      "elevation": 5315,
      "lat": 37.1208000183,
      "lon": -109.986000061,
      "tz": "America\/Denver"
  },
  "03WA": {
      "icao": "03WA",
      "iata": "",
      "name": "Spangle Field",
      "city": "Spangle",
      "state": "Washington",
      "country": "US",
      "elevation": 2440,
      "lat": 47.4081993103,
      "lon": -117.3720016479,
      "tz": "America\/Los_Angeles"
  },
  "03WI": {
      "icao": "03WI",
      "iata": "",
      "name": "Zink Airport",
      "city": "Berlin",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 880,
      "lat": 44.0275001526,
      "lon": -88.880897522,
      "tz": "America\/Chicago"
  },
  "03WN": {
      "icao": "03WN",
      "iata": "",
      "name": "Aerostone Ranch Airport",
      "city": "Goldendale",
      "state": "Washington",
      "country": "US",
      "elevation": 2320,
      "lat": 45.875,
      "lon": -120.6699981689,
      "tz": "America\/Los_Angeles"
  },
  "03XS": {
      "icao": "03XS",
      "iata": "",
      "name": "Creekside Airport",
      "city": "Mertzon",
      "state": "Texas",
      "country": "US",
      "elevation": 2100,
      "lat": 31.3180999756,
      "lon": -100.7539978027,
      "tz": "America\/Chicago"
  },
  "04AZ": {
      "icao": "04AZ",
      "iata": "",
      "name": "Chinle Airport",
      "city": "Chinle",
      "state": "Arizona",
      "country": "US",
      "elevation": 5515,
      "lat": 36.1542015076,
      "lon": -109.5510025024,
      "tz": "America\/Denver"
  },
  "04CA": {
      "icao": "04CA",
      "iata": "",
      "name": "Gray Butte Field",
      "city": "Palmdale",
      "state": "California",
      "country": "US",
      "elevation": 3020,
      "lat": 34.5638999939,
      "lon": -117.6750030518,
      "tz": "America\/Los_Angeles"
  },
  "04CL": {
      "icao": "04CL",
      "iata": "",
      "name": "Hunt's Sky Ranch Airport",
      "city": "Julian",
      "state": "California",
      "country": "US",
      "elevation": 2310,
      "lat": 33.0816993713,
      "lon": -116.4410018921,
      "tz": "America\/Los_Angeles"
  },
  "04FA": {
      "icao": "04FA",
      "iata": "",
      "name": "Richards Field",
      "city": "Homestead",
      "state": "Florida",
      "country": "US",
      "elevation": 9,
      "lat": 25.5587005615,
      "lon": -80.5150985718,
      "tz": "America\/New_York"
  },
  "04FL": {
      "icao": "04FL",
      "iata": "",
      "name": "Cross Creek Farms Airport",
      "city": "Ormond",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 29.2394008636,
      "lon": -81.2220001221,
      "tz": "America\/New_York"
  },
  "04IA": {
      "icao": "04IA",
      "iata": "",
      "name": "Middlekoop Airport",
      "city": "Packwood",
      "state": "Iowa",
      "country": "US",
      "elevation": 801,
      "lat": 41.0882987976,
      "lon": -92.0546035767,
      "tz": "America\/Chicago"
  },
  "04ID": {
      "icao": "04ID",
      "iata": "",
      "name": "Lanham Field",
      "city": "Emmett",
      "state": "Idaho",
      "country": "US",
      "elevation": 2343,
      "lat": 43.8777008057,
      "lon": -116.5370025635,
      "tz": "America\/Boise"
  },
  "04II": {
      "icao": "04II",
      "iata": "",
      "name": "Turkey Run Airport",
      "city": "New Haven",
      "state": "Indiana",
      "country": "US",
      "elevation": 765,
      "lat": 41.1305999756,
      "lon": -84.994102478,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "04IL": {
      "icao": "04IL",
      "iata": "",
      "name": "Schertz Aerial Service - Hudson Airport",
      "city": "Hudson",
      "state": "Illinois",
      "country": "US",
      "elevation": 755,
      "lat": 40.6375007629,
      "lon": -89.0070037842,
      "tz": "America\/Chicago"
  },
  "04IS": {
      "icao": "04IS",
      "iata": "",
      "name": "Van Gorder Airport",
      "city": "Mansfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 728,
      "lat": 40.1786003113,
      "lon": -88.5690002441,
      "tz": "America\/Chicago"
  },
  "04KY": {
      "icao": "04KY",
      "iata": "",
      "name": "Natchez Trace Farm Airport",
      "city": "Hiseville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 710,
      "lat": 37.1351013184,
      "lon": -85.7893981934,
      "tz": "America\/Chicago"
  },
  "04LL": {
      "icao": "04LL",
      "iata": "",
      "name": "Brunner Airport",
      "city": "West Dundee",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 42.136100769,
      "lon": -88.2875976563,
      "tz": "America\/Chicago"
  },
  "04ME": {
      "icao": "04ME",
      "iata": "",
      "name": "Beech Hill Airport",
      "city": "Mercer",
      "state": "Maine",
      "country": "US",
      "elevation": 625,
      "lat": 44.6666984558,
      "lon": -69.8938980103,
      "tz": "America\/New_York"
  },
  "04MI": {
      "icao": "04MI",
      "iata": "",
      "name": "Rapids Airway Airport",
      "city": "Eaton Rapids",
      "state": "Michigan",
      "country": "US",
      "elevation": 880,
      "lat": 42.5345001221,
      "lon": -84.6283035278,
      "tz": "America\/Detroit"
  },
  "04MN": {
      "icao": "04MN",
      "iata": "",
      "name": "Helblad Airport",
      "city": "Kelliher",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1195,
      "lat": 47.9858016968,
      "lon": -94.6868972778,
      "tz": "America\/Chicago"
  },
  "04MO": {
      "icao": "04MO",
      "iata": "",
      "name": "Airpark Private Airport",
      "city": "Vandalia",
      "state": "Missouri",
      "country": "US",
      "elevation": 767,
      "lat": 39.3012008667,
      "lon": -91.451499939,
      "tz": "America\/Chicago"
  },
  "04MS": {
      "icao": "04MS",
      "iata": "",
      "name": "Nick's Flying Service Inc Airport",
      "city": "Rolling Fork",
      "state": "Mississippi",
      "country": "US",
      "elevation": 100,
      "lat": 32.9396018982,
      "lon": -90.8373031616,
      "tz": "America\/Chicago"
  },
  "04MT": {
      "icao": "04MT",
      "iata": "",
      "name": "Pluhar Airport",
      "city": "Cohagen",
      "state": "Montana",
      "country": "US",
      "elevation": 2715,
      "lat": 47.1374977778,
      "lon": -106.488095,
      "tz": "America\/Denver"
  },
  "04NE": {
      "icao": "04NE",
      "iata": "",
      "name": "Mc Connell Field Airport",
      "city": "Sarben",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3035,
      "lat": 41.1599998474,
      "lon": -101.299003601,
      "tz": "America\/Denver"
  },
  "04NJ": {
      "icao": "04NJ",
      "iata": "",
      "name": "Emmanuel Airport",
      "city": "Elmer",
      "state": "New Jersey",
      "country": "US",
      "elevation": 155,
      "lat": 39.5968017578,
      "lon": -75.2334976196,
      "tz": "America\/New_York"
  },
  "04NV": {
      "icao": "04NV",
      "iata": "",
      "name": "Kingston Ranch Airport",
      "city": "Sandy Valley",
      "state": "California",
      "country": "US",
      "elevation": 2620,
      "lat": 35.7555007935,
      "lon": -115.665000916,
      "tz": "America\/Los_Angeles"
  },
  "04NY": {
      "icao": "04NY",
      "iata": "",
      "name": "Klaverack Airport",
      "city": "Hudson",
      "state": "New York",
      "country": "US",
      "elevation": 250,
      "lat": 42.262298584,
      "lon": -73.6961975098,
      "tz": "America\/New_York"
  },
  "04OH": {
      "icao": "04OH",
      "iata": "",
      "name": "Bossow Airport",
      "city": "Garrettsville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1080,
      "lat": 41.2476997375,
      "lon": -81.1007003784,
      "tz": "America\/New_York"
  },
  "04OR": {
      "icao": "04OR",
      "iata": "",
      "name": "Collins Landing Strip",
      "city": "Mitchell",
      "state": "Oregon",
      "country": "US",
      "elevation": 1500,
      "lat": 44.7499008179,
      "lon": -120.200996399,
      "tz": "America\/Los_Angeles"
  },
  "04TN": {
      "icao": "04TN",
      "iata": "",
      "name": "Hensley Airpark",
      "city": "Chuckey",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1430,
      "lat": 36.1833000183,
      "lon": -82.6789016724,
      "tz": "America\/New_York"
  },
  "04TX": {
      "icao": "04TX",
      "iata": "",
      "name": "Pocock Airport",
      "city": "China Spring",
      "state": "Texas",
      "country": "US",
      "elevation": 565,
      "lat": 31.7322006226,
      "lon": -97.3688964844,
      "tz": "America\/Chicago"
  },
  "04UT": {
      "icao": "04UT",
      "iata": "",
      "name": "Navajo Mountain Airport",
      "city": "Navajo Mountain Trdng Post",
      "state": "Utah",
      "country": "US",
      "elevation": 6160,
      "lat": 37.0083007812,
      "lon": -110.794998169,
      "tz": "America\/Denver"
  },
  "04VG": {
      "icao": "04VG",
      "iata": "",
      "name": "Manquin Flight Park Ultralightport",
      "city": "Manquin",
      "state": "Virginia",
      "country": "US",
      "elevation": 50,
      "lat": 37.7144012451,
      "lon": -77.2117996216,
      "tz": "America\/New_York"
  },
  "04VT": {
      "icao": "04VT",
      "iata": "",
      "name": "Lightning Bolt Field Airport",
      "city": "Cabot",
      "state": "Vermont",
      "country": "US",
      "elevation": 2156,
      "lat": 44.4011001587,
      "lon": -72.2636032104,
      "tz": "America\/New_York"
  },
  "04WA": {
      "icao": "04WA",
      "iata": "",
      "name": "Ox Meadows Airport",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2345,
      "lat": 47.5817985535,
      "lon": -117.43699646,
      "tz": "America\/Los_Angeles"
  },
  "04WI": {
      "icao": "04WI",
      "iata": "",
      "name": "Dutch Gap Airstrip",
      "city": "Bristol",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 760,
      "lat": 42.523399353,
      "lon": -88.0167007446,
      "tz": "America\/Chicago"
  },
  "04WN": {
      "icao": "04WN",
      "iata": "",
      "name": "Stillwater Creek Airport",
      "city": "Royal City",
      "state": "Washington",
      "country": "US",
      "elevation": 1510,
      "lat": 46.963889,
      "lon": -119.641667,
      "tz": "America\/Los_Angeles"
  },
  "05AK": {
      "icao": "05AK",
      "iata": "",
      "name": "Wasilla Creek Airpark",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 620,
      "lat": 61.66830063,
      "lon": -149.1880035,
      "tz": "America\/Anchorage"
  },
  "05AR": {
      "icao": "05AR",
      "iata": "",
      "name": "Ozark Skies Airpark",
      "city": "Kingston",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1900,
      "lat": 36.000633,
      "lon": -93.57025,
      "tz": "America\/Chicago"
  },
  "05CL": {
      "icao": "05CL",
      "iata": "",
      "name": "Pope Valley Airport",
      "city": "Pope Valley",
      "state": "California",
      "country": "US",
      "elevation": 618,
      "lat": 38.6106987,
      "lon": -122.39099884,
      "tz": "America\/Los_Angeles"
  },
  "05CO": {
      "icao": "05CO",
      "iata": "",
      "name": "Rancho De Aereo Airport",
      "city": "Mead",
      "state": "Colorado",
      "country": "US",
      "elevation": 4978,
      "lat": 40.2149839,
      "lon": -104.9844228,
      "tz": "America\/Denver"
  },
  "05FA": {
      "icao": "05FA",
      "iata": "",
      "name": "Melanie's Airport",
      "city": "Florala",
      "state": "Florida",
      "country": "US",
      "elevation": 295,
      "lat": 30.9188995361,
      "lon": -86.2182998657,
      "tz": "America\/Chicago"
  },
  "05FL": {
      "icao": "05FL",
      "iata": "",
      "name": "Charlton Strip",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 36,
      "lat": 26.5230998993,
      "lon": -81.4083023071,
      "tz": "America\/New_York"
  },
  "05GA": {
      "icao": "05GA",
      "iata": "",
      "name": "Raju Airport",
      "city": "Richland",
      "state": "Georgia",
      "country": "US",
      "elevation": 625,
      "lat": 32.0713996887,
      "lon": -84.7144012451,
      "tz": "America\/New_York"
  },
  "05IA": {
      "icao": "05IA",
      "iata": "",
      "name": "Spotts Field",
      "city": "Nora Springs",
      "state": "Iowa",
      "country": "US",
      "elevation": 1155,
      "lat": 43.1375007629,
      "lon": -93.0682983398,
      "tz": "America\/Chicago"
  },
  "05ID": {
      "icao": "05ID",
      "iata": "",
      "name": "Running Creek Ranch Airport",
      "city": "Grangeville",
      "state": "Idaho",
      "country": "US",
      "elevation": 2969,
      "lat": 45.914100647,
      "lon": -114.8359985352,
      "tz": "America\/Los_Angeles"
  },
  "05II": {
      "icao": "05II",
      "iata": "",
      "name": "Reichhart Airport",
      "city": "New Haven",
      "state": "Indiana",
      "country": "US",
      "elevation": 795,
      "lat": 41.0287017822,
      "lon": -84.9972000122,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "05IL": {
      "icao": "05IL",
      "iata": "",
      "name": "Classic Landings Airport",
      "city": "Bonfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 635,
      "lat": 41.1567001343,
      "lon": -88.0167007446,
      "tz": "America\/Chicago"
  },
  "05IN": {
      "icao": "05IN",
      "iata": "",
      "name": "Cooper Airport",
      "city": "Belleville",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 39.641998291,
      "lon": -86.5042037964,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "05IS": {
      "icao": "05IS",
      "iata": "",
      "name": "Hardy Airport",
      "city": "Mount Auburn",
      "state": "Illinois",
      "country": "US",
      "elevation": 605,
      "lat": 39.7258987427,
      "lon": -89.263999939,
      "tz": "America\/Chicago"
  },
  "05KS": {
      "icao": "05KS",
      "iata": "",
      "name": "Darbro Field",
      "city": "Elk City",
      "state": "Kansas",
      "country": "US",
      "elevation": 945,
      "lat": 37.2662010193,
      "lon": -95.959197998,
      "tz": "America\/Chicago"
  },
  "05KY": {
      "icao": "05KY",
      "iata": "",
      "name": "Cartersville Airport",
      "city": "Berea",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1070,
      "lat": 37.53689957,
      "lon": -84.40720367,
      "tz": "America\/New_York"
  },
  "05LA": {
      "icao": "05LA",
      "iata": "",
      "name": "Greg's Flying Service Airport",
      "city": "Baskin",
      "state": "Louisiana",
      "country": "US",
      "elevation": 76,
      "lat": 32.2653007507,
      "lon": -91.6977996826,
      "tz": "America\/Chicago"
  },
  "05LS": {
      "icao": "05LS",
      "iata": "",
      "name": "Grass Roots Airport",
      "city": "Alexandria",
      "state": "Louisiana",
      "country": "US",
      "elevation": 83,
      "lat": 31.252199173,
      "lon": -92.4757995605,
      "tz": "America\/Chicago"
  },
  "05MD": {
      "icao": "05MD",
      "iata": "",
      "name": "Breezecroft Airport",
      "city": "Chestertown",
      "state": "Maryland",
      "country": "US",
      "elevation": 20,
      "lat": 39.2500991821,
      "lon": -76.2052001953,
      "tz": "America\/New_York"
  },
  "05ME": {
      "icao": "05ME",
      "iata": "",
      "name": "Drisko Airport",
      "city": "Jonesboro",
      "state": "Maine",
      "country": "US",
      "elevation": 70,
      "lat": 44.6570014954,
      "lon": -67.5591964722,
      "tz": "America\/New_York"
  },
  "05NE": {
      "icao": "05NE",
      "iata": "",
      "name": "Mc Kay Airport",
      "city": "Spalding",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2010,
      "lat": 41.7221984863,
      "lon": -98.3089981079,
      "tz": "America\/Chicago"
  },
  "05OI": {
      "icao": "05OI",
      "iata": "",
      "name": "Dorlon Airpark",
      "city": "Columbia Station",
      "state": "Ohio",
      "country": "US",
      "elevation": 830,
      "lat": 41.2883987427,
      "lon": -81.962600708,
      "tz": "America\/New_York"
  },
  "05OK": {
      "icao": "05OK",
      "iata": "",
      "name": "Hawk Haven Airport",
      "city": "Crescent",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1075,
      "lat": 35.8534011841,
      "lon": -97.5674972534,
      "tz": "America\/Chicago"
  },
  "05OR": {
      "icao": "05OR",
      "iata": "",
      "name": "Peacock Ranch Airport",
      "city": "Enterprise",
      "state": "Oregon",
      "country": "US",
      "elevation": 3500,
      "lat": 45.4415016174,
      "lon": -117.3509979248,
      "tz": "America\/Los_Angeles"
  },
  "05PS": {
      "icao": "05PS",
      "iata": "",
      "name": "Mills Brothers Airport",
      "city": "Burnt Cabins",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1210,
      "lat": 40.1427993774,
      "lon": -77.8619003296,
      "tz": "America\/New_York"
  },
  "05TA": {
      "icao": "05TA",
      "iata": "",
      "name": "Brandes Air Field",
      "city": "Sealy",
      "state": "Texas",
      "country": "US",
      "elevation": 276,
      "lat": 29.8090991974,
      "lon": -96.2650985718,
      "tz": "America\/Chicago"
  },
  "05TE": {
      "icao": "05TE",
      "iata": "",
      "name": "Hilde-Griff Field",
      "city": "Georgetown",
      "state": "Texas",
      "country": "US",
      "elevation": 950,
      "lat": 30.7220993042,
      "lon": -97.7913970947,
      "tz": "America\/Chicago"
  },
  "05TS": {
      "icao": "05TS",
      "iata": "",
      "name": "Dew Drop Airport",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 750,
      "lat": 33.1292991638,
      "lon": -97.3585968018,
      "tz": "America\/Chicago"
  },
  "05TX": {
      "icao": "05TX",
      "iata": "",
      "name": "Circle 'A' Ranch Airport",
      "city": "Blum",
      "state": "Texas",
      "country": "US",
      "elevation": 608,
      "lat": 32.1291999817,
      "lon": -97.4041976929,
      "tz": "America\/Chicago"
  },
  "05UT": {
      "icao": "05UT",
      "iata": "",
      "name": "Oljato Airport",
      "city": "Oljato",
      "state": "Utah",
      "country": "US",
      "elevation": 4838,
      "lat": 37.0332984924,
      "lon": -110.3170013428,
      "tz": "America\/Denver"
  },
  "05VA": {
      "icao": "05VA",
      "iata": "",
      "name": "Providence Airport",
      "city": "Farmville",
      "state": "Virginia",
      "country": "US",
      "elevation": 445,
      "lat": 37.2242012024,
      "lon": -78.4186019897,
      "tz": "America\/New_York"
  },
  "05WI": {
      "icao": "05WI",
      "iata": "",
      "name": "Ames Private Airport",
      "city": "Darien",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 958,
      "lat": 42.6511001587,
      "lon": -88.7459030151,
      "tz": "America\/Chicago"
  },
  "05WN": {
      "icao": "05WN",
      "iata": "",
      "name": "Flat Creek Field",
      "city": "Kettle Falls",
      "state": "Washington",
      "country": "US",
      "elevation": 1570,
      "lat": 48.702222,
      "lon": -118.0475,
      "tz": "America\/Los_Angeles"
  },
  "05XS": {
      "icao": "05XS",
      "iata": "",
      "name": "Johnson Memorial Airport",
      "city": "Wilmot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 105,
      "lat": 33.0792999268,
      "lon": -91.5431976318,
      "tz": "America\/Chicago"
  },
  "06AK": {
      "icao": "06AK",
      "iata": "",
      "name": "June Lake Airpark",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 425,
      "lat": 61.627619,
      "lon": -149.575331,
      "tz": "America\/Anchorage"
  },
  "06AR": {
      "icao": "06AR",
      "iata": "",
      "name": "Bondair Airport",
      "city": "Higden",
      "state": "Arkansas",
      "country": "US",
      "elevation": 499,
      "lat": 35.5444984436,
      "lon": -92.1848983765,
      "tz": "America\/Chicago"
  },
  "06CO": {
      "icao": "06CO",
      "iata": "",
      "name": "Jecan Airport",
      "city": "Branson",
      "state": "Colorado",
      "country": "US",
      "elevation": 5100,
      "lat": 37.3875007629,
      "lon": -103.6910018921,
      "tz": "America\/Denver"
  },
  "06FA": {
      "icao": "06FA",
      "iata": "",
      "name": "William P Gwinn Airport",
      "city": "Jupiter",
      "state": "Florida",
      "country": "US",
      "elevation": 28,
      "lat": 26.9083995819,
      "lon": -80.3289031982,
      "tz": "America\/New_York"
  },
  "06FD": {
      "icao": "06FD",
      "iata": "",
      "name": "Grass Roots Airpark",
      "city": "Mascotte",
      "state": "Florida",
      "country": "US",
      "elevation": 105,
      "lat": 28.6417007446,
      "lon": -81.8850021362,
      "tz": "America\/New_York"
  },
  "06IA": {
      "icao": "06IA",
      "iata": "",
      "name": "Rich Field",
      "city": "Walford",
      "state": "Iowa",
      "country": "US",
      "elevation": 880,
      "lat": 41.8414001465,
      "lon": -91.8342971802,
      "tz": "America\/Chicago"
  },
  "06ID": {
      "icao": "06ID",
      "iata": "",
      "name": "Larkin Airport",
      "city": "Kuna",
      "state": "Idaho",
      "country": "US",
      "elevation": 2750,
      "lat": 43.4626998901,
      "lon": -116.3590011597,
      "tz": "America\/Boise"
  },
  "06IL": {
      "icao": "06IL",
      "iata": "",
      "name": "Humm Airport",
      "city": "Earlville",
      "state": "Illinois",
      "country": "US",
      "elevation": 708,
      "lat": 41.5994987488,
      "lon": -88.8699035645,
      "tz": "America\/Chicago"
  },
  "06IN": {
      "icao": "06IN",
      "iata": "",
      "name": "Ellis Fly-In Airport",
      "city": "Blackhawk",
      "state": "Indiana",
      "country": "US",
      "elevation": 575,
      "lat": 39.2827987671,
      "lon": -87.3035964966,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "06IS": {
      "icao": "06IS",
      "iata": "",
      "name": "Sinele Strip",
      "city": "Nauvoo",
      "state": "Illinois",
      "country": "US",
      "elevation": 695,
      "lat": 40.5463981628,
      "lon": -91.2742996216,
      "tz": "America\/Chicago"
  },
  "06MN": {
      "icao": "06MN",
      "iata": "",
      "name": "Pike Field Airport",
      "city": "Virginia",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1463,
      "lat": 47.6519012451,
      "lon": -92.4167022705,
      "tz": "America\/Chicago"
  },
  "06MO": {
      "icao": "06MO",
      "iata": "",
      "name": "Noahs Ark Airport",
      "city": "Waldron",
      "state": "Missouri",
      "country": "US",
      "elevation": 755,
      "lat": 39.2305984497,
      "lon": -94.804397583,
      "tz": "America\/Chicago"
  },
  "06MT": {
      "icao": "06MT",
      "iata": "",
      "name": "Torgerson Airport",
      "city": "Ethridge",
      "state": "Montana",
      "country": "US",
      "elevation": 3541,
      "lat": 48.5583000183,
      "lon": -112.1090011597,
      "tz": "America\/Denver"
  },
  "06NC": {
      "icao": "06NC",
      "iata": "",
      "name": "Tailwinds Airport",
      "city": "St. Pauls",
      "state": "North Carolina",
      "country": "US",
      "elevation": 163,
      "lat": 34.8274993896,
      "lon": -78.9302978516,
      "tz": "America\/New_York"
  },
  "06ND": {
      "icao": "06ND",
      "iata": "",
      "name": "Fitterer's Strip",
      "city": "Glen Ullin",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2180,
      "lat": 46.7489013672,
      "lon": -101.6689987183,
      "tz": "America\/North_Dakota\/New_Salem"
  },
  "06NH": {
      "icao": "06NH",
      "iata": "",
      "name": "Cole Farm Airport",
      "city": "Kensington",
      "state": "New Hampshire",
      "country": "US",
      "elevation": 160,
      "lat": 42.9283981323,
      "lon": -70.9730987549,
      "tz": "America\/New_York"
  },
  "06NY": {
      "icao": "06NY",
      "iata": "",
      "name": "Murphy Field",
      "city": "Auburn",
      "state": "New York",
      "country": "US",
      "elevation": 1100,
      "lat": 42.8428001404,
      "lon": -76.4452972412,
      "tz": "America\/New_York"
  },
  "06OH": {
      "icao": "06OH",
      "iata": "",
      "name": "Hal Joy Airfield",
      "city": "Williamsfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1160,
      "lat": 41.5097999573,
      "lon": -80.5912017822,
      "tz": "America\/New_York"
  },
  "06OI": {
      "icao": "06OI",
      "iata": "",
      "name": "Green Acres Airport",
      "city": "New Lebanon",
      "state": "Ohio",
      "country": "US",
      "elevation": 915,
      "lat": 39.7347984314,
      "lon": -84.3822021484,
      "tz": "America\/New_York"
  },
  "06OR": {
      "icao": "06OR",
      "iata": "",
      "name": "Hayden Mountain Airport",
      "city": "Forest Grove",
      "state": "Oregon",
      "country": "US",
      "elevation": 850,
      "lat": 45.4650993347,
      "lon": -123.0800018311,
      "tz": "America\/Los_Angeles"
  },
  "06PA": {
      "icao": "06PA",
      "iata": "",
      "name": "Freefall Oz Airport",
      "city": "Ceres",
      "state": "New York",
      "country": "US",
      "elevation": 1440,
      "lat": 42.0083999634,
      "lon": -78.258102417,
      "tz": "America\/New_York"
  },
  "06SN": {
      "icao": "06SN",
      "iata": "",
      "name": "Beyer Farm Airport",
      "city": "Derby",
      "state": "Kansas",
      "country": "US",
      "elevation": 1340,
      "lat": 37.5839004517,
      "lon": -97.2530975342,
      "tz": "America\/Chicago"
  },
  "06TA": {
      "icao": "06TA",
      "iata": "",
      "name": "Glaser Field",
      "city": "Buckholts",
      "state": "Texas",
      "country": "US",
      "elevation": 480,
      "lat": 30.9085006714,
      "lon": -97.116897583,
      "tz": "America\/Chicago"
  },
  "06TE": {
      "icao": "06TE",
      "iata": "",
      "name": "Ainsworth Airport",
      "city": "Cleveland",
      "state": "Texas",
      "country": "US",
      "elevation": 129,
      "lat": 30.3127002716,
      "lon": -95.0269012451,
      "tz": "America\/Chicago"
  },
  "06TN": {
      "icao": "06TN",
      "iata": "",
      "name": "Hunter STOLport",
      "city": "Columbia",
      "state": "Tennessee",
      "country": "US",
      "elevation": 640,
      "lat": 35.6972999573,
      "lon": -86.9569015503,
      "tz": "America\/Chicago"
  },
  "06TS": {
      "icao": "06TS",
      "iata": "",
      "name": "Roscoe Mc Connico Airport",
      "city": "Kerens",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 32.1207008362,
      "lon": -96.1791000366,
      "tz": "America\/Chicago"
  },
  "06TX": {
      "icao": "06TX",
      "iata": "",
      "name": "Diamond N Ranch Airport",
      "city": "Hockley",
      "state": "Texas",
      "country": "US",
      "elevation": 215,
      "lat": 30.0576000214,
      "lon": -95.8128967285,
      "tz": "America\/Chicago"
  },
  "06R": {
      "icao": "06R",
      "iata": "",
      "name": "Grawunder Field Airport",
      "city": "Bellville",
      "state": "Texas",
      "country": "US",
      "elevation": 89,
      "lat": 29.9409722222,
      "lon": -96.24525,
      "tz": "America\/Chicago"
  },
  "06VA": {
      "icao": "06VA",
      "iata": "",
      "name": "Mount Horeb Field",
      "city": "Grottoes",
      "state": "Virginia",
      "country": "US",
      "elevation": 1160,
      "lat": 38.2490005493,
      "lon": -78.8553009033,
      "tz": "America\/New_York"
  },
  "06WI": {
      "icao": "06WI",
      "iata": "",
      "name": "Young Private Airport",
      "city": "Frederic",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1075,
      "lat": 45.6627006531,
      "lon": -92.4502029419,
      "tz": "America\/Chicago"
  },
  "06WN": {
      "icao": "06WN",
      "iata": "",
      "name": "Western Airpark",
      "city": "Yelm",
      "state": "Washington",
      "country": "US",
      "elevation": 394,
      "lat": 46.9249992371,
      "lon": -122.5530014038,
      "tz": "America\/Los_Angeles"
  },
  "06XS": {
      "icao": "06XS",
      "iata": "",
      "name": "Campbell Field",
      "city": "Aubrey",
      "state": "Texas",
      "country": "US",
      "elevation": 546,
      "lat": 33.3246994019,
      "lon": -97.0441970825,
      "tz": "America\/Chicago"
  },
  "07AR": {
      "icao": "07AR",
      "iata": "",
      "name": "Morrilton Airport",
      "city": "Morrilton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 300,
      "lat": 35.1944999695,
      "lon": -92.800201416,
      "tz": "America\/Chicago"
  },
  "07CL": {
      "icao": "07CL",
      "iata": "",
      "name": "Richvale Airport",
      "city": "Richvale",
      "state": "California",
      "country": "US",
      "elevation": 100,
      "lat": 39.4976997375,
      "lon": -121.7710037231,
      "tz": "America\/Los_Angeles"
  },
  "07CO": {
      "icao": "07CO",
      "iata": "",
      "name": "Comanche Creek Airport",
      "city": "Kiowa",
      "state": "Colorado",
      "country": "US",
      "elevation": 6620,
      "lat": 39.2635993958,
      "lon": -104.4270019531,
      "tz": "America\/Denver"
  },
  "07FA": {
      "icao": "07FA",
      "iata": "",
      "name": "Ocean Reef Club Airport",
      "city": "Key Largo",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 25.3253993988,
      "lon": -80.2748031616,
      "tz": "America\/New_York"
  },
  "07FD": {
      "icao": "07FD",
      "iata": "",
      "name": "Flying G Ranch STOLport",
      "city": "Lee",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 30.4179992676,
      "lon": -83.2837982178,
      "tz": "America\/New_York"
  },
  "07GA": {
      "icao": "07GA",
      "iata": "",
      "name": "Alcovy Airport",
      "city": "Covington",
      "state": "Georgia",
      "country": "US",
      "elevation": 750,
      "lat": 33.6749992371,
      "lon": -83.7630996704,
      "tz": "America\/New_York"
  },
  "07ID": {
      "icao": "07ID",
      "iata": "",
      "name": "Interstate Airport",
      "city": "Malta",
      "state": "Idaho",
      "country": "US",
      "elevation": 4580,
      "lat": 42.2781982422,
      "lon": -113.3050003052,
      "tz": "America\/Boise"
  },
  "07IN": {
      "icao": "07IN",
      "iata": "",
      "name": "Gray Airport",
      "city": "Boggstown",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 39.5736999512,
      "lon": -85.9139022827,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "07KS": {
      "icao": "07KS",
      "iata": "",
      "name": "Heape Field",
      "city": "Augusta",
      "state": "Kansas",
      "country": "US",
      "elevation": 1321,
      "lat": 37.7341995239,
      "lon": -96.9366989136,
      "tz": "America\/Chicago"
  },
  "07KY": {
      "icao": "07KY",
      "iata": "",
      "name": "Blue Lick Airport",
      "city": "Louisville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 532,
      "lat": 38.0806007385,
      "lon": -85.6932983398,
      "tz": "America\/Kentucky\/Louisville"
  },
  "07MD": {
      "icao": "07MD",
      "iata": "",
      "name": "Baugher's Orchard Airport",
      "city": "Westminster",
      "state": "Maryland",
      "country": "US",
      "elevation": 700,
      "lat": 39.6082992554,
      "lon": -77.051902771,
      "tz": "America\/New_York"
  },
  "07MI": {
      "icao": "07MI",
      "iata": "",
      "name": "Scott Airstrip",
      "city": "Lake",
      "state": "Michigan",
      "country": "US",
      "elevation": 1065,
      "lat": 43.864200592,
      "lon": -85.0239028931,
      "tz": "America\/Detroit"
  },
  "07MN": {
      "icao": "07MN",
      "iata": "",
      "name": "Dairyview Airport",
      "city": "Hadley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1680,
      "lat": 44.0550003052,
      "lon": -95.9095001221,
      "tz": "America\/Chicago"
  },
  "07MO": {
      "icao": "07MO",
      "iata": "",
      "name": "Lake Sexton Airport",
      "city": "Warrensburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 880,
      "lat": 38.6500015259,
      "lon": -93.616897583,
      "tz": "America\/Chicago"
  },
  "07MT": {
      "icao": "07MT",
      "iata": "",
      "name": "Glasgow Industrial Airport",
      "city": "Glasgow",
      "state": "Montana",
      "country": "US",
      "elevation": 2762,
      "lat": 48.4211006165,
      "lon": -106.5279998779,
      "tz": "America\/Denver"
  },
  "07NC": {
      "icao": "07NC",
      "iata": "",
      "name": "Hawks Meadow Airport",
      "city": "Waxhaw",
      "state": "North Carolina",
      "country": "US",
      "elevation": 625,
      "lat": 34.8331985474,
      "lon": -80.7511978149,
      "tz": "America\/New_York"
  },
  "07OK": {
      "icao": "07OK",
      "iata": "",
      "name": "Pleasant Valley Airport",
      "city": "Snyder",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1322,
      "lat": 34.6333999634,
      "lon": -99.0336990356,
      "tz": "America\/Chicago"
  },
  "07OR": {
      "icao": "07OR",
      "iata": "",
      "name": "Bruce's Airport",
      "city": "Milwaukie",
      "state": "Oregon",
      "country": "US",
      "elevation": 250,
      "lat": 45.4217987061,
      "lon": -122.6200027466,
      "tz": "America\/Los_Angeles"
  },
  "07PN": {
      "icao": "07PN",
      "iata": "",
      "name": "Folmar Airport",
      "city": "Grassflat",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1690,
      "lat": 40.998889,
      "lon": -78.125278,
      "tz": "America\/New_York"
  },
  "07SN": {
      "icao": "07SN",
      "iata": "",
      "name": "Churchill Airport",
      "city": "Phillipsburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1965,
      "lat": 39.7728004456,
      "lon": -99.3600997925,
      "tz": "America\/Chicago"
  },
  "07TA": {
      "icao": "07TA",
      "iata": "",
      "name": "Salaika Aviation Airport",
      "city": "Danbury",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 29.2404994965,
      "lon": -95.3447036743,
      "tz": "America\/Chicago"
  },
  "07TE": {
      "icao": "07TE",
      "iata": "",
      "name": "Cuddihy Field",
      "city": "Corpus Christi",
      "state": "Texas",
      "country": "US",
      "elevation": 39,
      "lat": 27.7210998535,
      "lon": -97.512802124,
      "tz": "America\/Chicago"
  },
  "07TN": {
      "icao": "07TN",
      "iata": "",
      "name": "Corntassel Airport",
      "city": "Vonore",
      "state": "Tennessee",
      "country": "US",
      "elevation": 970,
      "lat": 35.5685997009,
      "lon": -84.2410964966,
      "tz": "America\/New_York"
  },
  "07TS": {
      "icao": "07TS",
      "iata": "",
      "name": "Cross-Country Estates Airport",
      "city": "Georgetown",
      "state": "Texas",
      "country": "US",
      "elevation": 690,
      "lat": 30.625,
      "lon": -97.5727996826,
      "tz": "America\/Chicago"
  },
  "07VA": {
      "icao": "07VA",
      "iata": "",
      "name": "Alpha Hotel Airport",
      "city": "Clover",
      "state": "Virginia",
      "country": "US",
      "elevation": 350,
      "lat": 36.8195991516,
      "lon": -78.7830963135,
      "tz": "America\/New_York"
  },
  "07VT": {
      "icao": "07VT",
      "iata": "",
      "name": "Meadow STOLport",
      "city": "Jericho",
      "state": "Vermont",
      "country": "US",
      "elevation": 801,
      "lat": 44.4472007751,
      "lon": -72.9147033691,
      "tz": "America\/New_York"
  },
  "07WI": {
      "icao": "07WI",
      "iata": "",
      "name": "Bartell Strip",
      "city": "Genessee Depot",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 990,
      "lat": 42.9653015137,
      "lon": -88.3915023804,
      "tz": "America\/Chicago"
  },
  "08AK": {
      "icao": "08AK",
      "iata": "",
      "name": "Fisher Airport",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 61.569639,
      "lon": -149.724439,
      "tz": "America\/Anchorage"
  },
  "08CL": {
      "icao": "08CL",
      "iata": "",
      "name": "Kistler Ranch Airport",
      "city": "Jamestown",
      "state": "California",
      "country": "US",
      "elevation": 1025,
      "lat": 37.8596000671,
      "lon": -120.5540008545,
      "tz": "America\/Los_Angeles"
  },
  "08CO": {
      "icao": "08CO",
      "iata": "",
      "name": "Terra Firma Airport",
      "city": "Rush",
      "state": "Colorado",
      "country": "US",
      "elevation": 5600,
      "lat": 38.7324981689,
      "lon": -104.0410003662,
      "tz": "America\/Denver"
  },
  "08FA": {
      "icao": "08FA",
      "iata": "",
      "name": "Duda Airstrip",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 26.5797996521,
      "lon": -81.4837036133,
      "tz": "America\/New_York"
  },
  "08FD": {
      "icao": "08FD",
      "iata": "",
      "name": "Sunniland Ranch Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 27.3631000519,
      "lon": -80.7727966309,
      "tz": "America\/New_York"
  },
  "08FL": {
      "icao": "08FL",
      "iata": "",
      "name": "J. H. Hendrie Farms Airport",
      "city": "Lake Placid",
      "state": "Florida",
      "country": "US",
      "elevation": 103,
      "lat": 27.0844993591,
      "lon": -81.3286972046,
      "tz": "America\/New_York"
  },
  "08GA": {
      "icao": "08GA",
      "iata": "",
      "name": "Sapelo Island Airport",
      "city": "Darien",
      "state": "Georgia",
      "country": "US",
      "elevation": 12,
      "lat": 31.4258003235,
      "lon": -81.2858963013,
      "tz": "America\/New_York"
  },
  "08ID": {
      "icao": "08ID",
      "iata": "",
      "name": "Symms Airport",
      "city": "Marsing",
      "state": "Idaho",
      "country": "US",
      "elevation": 2680,
      "lat": 43.5693016052,
      "lon": -116.7770004272,
      "tz": "America\/Boise"
  },
  "08IL": {
      "icao": "08IL",
      "iata": "",
      "name": "Harms Airstrip",
      "city": "Tampico",
      "state": "Illinois",
      "country": "US",
      "elevation": 643,
      "lat": 41.5769996643,
      "lon": -89.7939987183,
      "tz": "America\/Chicago"
  },
  "08IS": {
      "icao": "08IS",
      "iata": "",
      "name": "Hemmer RLA Restricted Landing Area",
      "city": "Du Quoin",
      "state": "Illinois",
      "country": "US",
      "elevation": 400,
      "lat": 37.9777984619,
      "lon": -89.2029037476,
      "tz": "America\/Chicago"
  },
  "08KS": {
      "icao": "08KS",
      "iata": "",
      "name": "Alderson Airport",
      "city": "Bennington",
      "state": "Kansas",
      "country": "US",
      "elevation": 1250,
      "lat": 39.0346984863,
      "lon": -97.5800018311,
      "tz": "America\/Chicago"
  },
  "08KY": {
      "icao": "08KY",
      "iata": "",
      "name": "Boss Airport",
      "city": "Burnside",
      "state": "Kentucky",
      "country": "US",
      "elevation": 820,
      "lat": 36.9725990295,
      "lon": -84.6260986328,
      "tz": "America\/New_York"
  },
  "08LL": {
      "icao": "08LL",
      "iata": "",
      "name": "Sauer Airport",
      "city": "Dwight",
      "state": "Illinois",
      "country": "US",
      "elevation": 665,
      "lat": 41.0752983093,
      "lon": -88.4891967773,
      "tz": "America\/Chicago"
  },
  "08LS": {
      "icao": "08LS",
      "iata": "",
      "name": "Aeleron Airport",
      "city": "Grand Coteau",
      "state": "Louisiana",
      "country": "US",
      "elevation": 51,
      "lat": 30.4036006927,
      "lon": -92.0235977173,
      "tz": "America\/Chicago"
  },
  "08MA": {
      "icao": "08MA",
      "iata": "",
      "name": "Wormid Airport",
      "city": "Southborough",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 360,
      "lat": 42.2939987183,
      "lon": -71.4962005615,
      "tz": "America\/New_York"
  },
  "08ME": {
      "icao": "08ME",
      "iata": "",
      "name": "Lindbergh Airport",
      "city": "Avon",
      "state": "Maine",
      "country": "US",
      "elevation": 554,
      "lat": 44.814201355,
      "lon": -70.3452987671,
      "tz": "America\/New_York"
  },
  "08MO": {
      "icao": "08MO",
      "iata": "",
      "name": "Twin Oaks Airport",
      "city": "Jasper",
      "state": "Missouri",
      "country": "US",
      "elevation": 955,
      "lat": 37.3955993652,
      "lon": -94.2837982178,
      "tz": "America\/Chicago"
  },
  "08MS": {
      "icao": "08MS",
      "iata": "",
      "name": "Williams Field",
      "city": "Coldwater",
      "state": "Mississippi",
      "country": "US",
      "elevation": 345,
      "lat": 34.66220093,
      "lon": -89.86830139,
      "tz": "America\/Chicago"
  },
  "08MT": {
      "icao": "08MT",
      "iata": "",
      "name": "Clark Airport",
      "city": "Grassrange",
      "state": "Montana",
      "country": "US",
      "elevation": 3450,
      "lat": 47.0638999939,
      "lon": -108.8170013428,
      "tz": "America\/Denver"
  },
  "08NC": {
      "icao": "08NC",
      "iata": "",
      "name": "Whiteheart Farm Airport",
      "city": "Lewisville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 750,
      "lat": 36.0676002502,
      "lon": -80.4655990601,
      "tz": "America\/New_York"
  },
  "08ND": {
      "icao": "08ND",
      "iata": "",
      "name": "Brecht Strip",
      "city": "Golden Valley",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1990,
      "lat": 47.3231010437,
      "lon": -102.0979995728,
      "tz": "America\/North_Dakota\/Beulah"
  },
  "08NE": {
      "icao": "08NE",
      "iata": "",
      "name": "Laurel Municipal Airport",
      "city": "Laurel",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1467,
      "lat": 42.4317016602,
      "lon": -97.079498291,
      "tz": "America\/Chicago"
  },
  "08NY": {
      "icao": "08NY",
      "iata": "",
      "name": "Md1 Airport",
      "city": "Middletown",
      "state": "New York",
      "country": "US",
      "elevation": 650,
      "lat": 41.3680992126,
      "lon": -74.5055999756,
      "tz": "America\/New_York"
  },
  "08OH": {
      "icao": "08OH",
      "iata": "",
      "name": "Kenley Airport",
      "city": "Hartford",
      "state": "Ohio",
      "country": "US",
      "elevation": 1230,
      "lat": 41.2916984558,
      "lon": -80.5636978149,
      "tz": "America\/New_York"
  },
  "08OK": {
      "icao": "08OK",
      "iata": "",
      "name": "Parks Airport",
      "city": "Wilburton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 635,
      "lat": 34.9314994812,
      "lon": -95.2583007813,
      "tz": "America\/Chicago"
  },
  "08OR": {
      "icao": "08OR",
      "iata": "",
      "name": "Saxon Sycan Airport",
      "city": "Silver Lake",
      "state": "Oregon",
      "country": "US",
      "elevation": 4990,
      "lat": 42.8392982483,
      "lon": -121.1169967651,
      "tz": "America\/Los_Angeles"
  },
  "08TE": {
      "icao": "08TE",
      "iata": "",
      "name": "B. J. Mc Combs Sisterdale Airport",
      "city": "Comfort",
      "state": "Texas",
      "country": "US",
      "elevation": 1447,
      "lat": 29.9759998322,
      "lon": -98.743598938,
      "tz": "America\/Chicago"
  },
  "08TS": {
      "icao": "08TS",
      "iata": "",
      "name": "Puesta Del Sol Airport",
      "city": "La Gloria",
      "state": "Texas",
      "country": "US",
      "elevation": 420,
      "lat": 26.8878002167,
      "lon": -98.5072021484,
      "tz": "America\/Chicago"
  },
  "08TX": {
      "icao": "08TX",
      "iata": "",
      "name": "Cross Wind Airport",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 1040,
      "lat": 32.8582000732,
      "lon": -97.7474975586,
      "tz": "America\/Chicago"
  },
  "08VA": {
      "icao": "08VA",
      "iata": "",
      "name": "Springwood Airstrip",
      "city": "Buchanan",
      "state": "Virginia",
      "country": "US",
      "elevation": 1000,
      "lat": 37.5540008545,
      "lon": -79.7498016357,
      "tz": "America\/New_York"
  },
  "08XS": {
      "icao": "08XS",
      "iata": "",
      "name": "Peterson Airport",
      "city": "Old Ocean",
      "state": "Texas",
      "country": "US",
      "elevation": 45,
      "lat": 29.1081008911,
      "lon": -95.7774963379,
      "tz": "America\/Chicago"
  },
  "09AK": {
      "icao": "09AK",
      "iata": "",
      "name": "West Beaver Airport",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 228,
      "lat": 61.589361,
      "lon": -149.847333,
      "tz": "America\/Anchorage"
  },
  "09AZ": {
      "icao": "09AZ",
      "iata": "",
      "name": "Stronghold Airport",
      "city": "St David",
      "state": "Arizona",
      "country": "US",
      "elevation": 4970,
      "lat": 31.9253005981,
      "lon": -110.0380020142,
      "tz": "America\/Phoenix"
  },
  "09CL": {
      "icao": "09CL",
      "iata": "",
      "name": "Alta Sierra Airport",
      "city": "Grass Valley",
      "state": "California",
      "country": "US",
      "elevation": 2275,
      "lat": 39.1138000488,
      "lon": -121.0569992065,
      "tz": "America\/Los_Angeles"
  },
  "09CO": {
      "icao": "09CO",
      "iata": "",
      "name": "Cottonwood Field",
      "city": "Swink",
      "state": "Colorado",
      "country": "US",
      "elevation": 4180,
      "lat": 38.0555992126,
      "lon": -103.6529998779,
      "tz": "America\/Denver"
  },
  "09FA": {
      "icao": "09FA",
      "iata": "",
      "name": "Placid Lakes Airport",
      "city": "Lake Placid",
      "state": "Florida",
      "country": "US",
      "elevation": 130,
      "lat": 27.2455997467,
      "lon": -81.4131011963,
      "tz": "America\/New_York"
  },
  "09FD": {
      "icao": "09FD",
      "iata": "",
      "name": "Cheryl-Lane Landings Airport",
      "city": "Bushnell",
      "state": "Florida",
      "country": "US",
      "elevation": 71,
      "lat": 28.6711006165,
      "lon": -82.0873031616,
      "tz": "America\/New_York"
  },
  "09FL": {
      "icao": "09FL",
      "iata": "",
      "name": "Sunnybreeze Airport",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 27.0555992126,
      "lon": -81.9705963135,
      "tz": "America\/New_York"
  },
  "09GA": {
      "icao": "09GA",
      "iata": "",
      "name": "Sunbelt Strip",
      "city": "Moultrie",
      "state": "Georgia",
      "country": "US",
      "elevation": 230,
      "lat": 31.1112995148,
      "lon": -83.68409729,
      "tz": "America\/New_York"
  },
  "09IA": {
      "icao": "09IA",
      "iata": "",
      "name": "Skydive Iowa Airport",
      "city": "Brooklyn",
      "state": "Iowa",
      "country": "US",
      "elevation": 820,
      "lat": 41.7458000183,
      "lon": -92.4093017578,
      "tz": "America\/Chicago"
  },
  "09ID": {
      "icao": "09ID",
      "iata": "",
      "name": "Taylor Ranch Landing Area Airport",
      "city": "Moscow",
      "state": "Idaho",
      "country": "US",
      "elevation": 3835,
      "lat": 45.1039009094,
      "lon": -114.8550033569,
      "tz": "America\/Boise"
  },
  "09II": {
      "icao": "09II",
      "iata": "",
      "name": "Gibbons Field",
      "city": "Valeene",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 38.4472999573,
      "lon": -86.4449996948,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "09IN": {
      "icao": "09IN",
      "iata": "",
      "name": "Gordon Airport",
      "city": "Columbia City",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 41.1208992004,
      "lon": -85.4250030518,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "09KS": {
      "icao": "09KS",
      "iata": "",
      "name": "Coffman Airport",
      "city": "Wellsville",
      "state": "Kansas",
      "country": "US",
      "elevation": 970,
      "lat": 38.7070007324,
      "lon": -95.1382980347,
      "tz": "America\/Chicago"
  },
  "09LL": {
      "icao": "09LL",
      "iata": "",
      "name": "Pine Hill Airport",
      "city": "Capron",
      "state": "Illinois",
      "country": "US",
      "elevation": 1000,
      "lat": 42.4681015015,
      "lon": -88.7432022095,
      "tz": "America\/Chicago"
  },
  "09MA": {
      "icao": "09MA",
      "iata": "",
      "name": "Kallander Field",
      "city": "Southborough",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 403,
      "lat": 42.3042984009,
      "lon": -71.505897522,
      "tz": "America\/New_York"
  },
  "09ME": {
      "icao": "09ME",
      "iata": "",
      "name": "Perrotti Skyranch Airfield",
      "city": "Berwick",
      "state": "Maine",
      "country": "US",
      "elevation": 260,
      "lat": 43.340801239,
      "lon": -70.8546981812,
      "tz": "America\/New_York"
  },
  "09MN": {
      "icao": "09MN",
      "iata": "",
      "name": "W Johnson Field",
      "city": "Hazel Run",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1060,
      "lat": 44.7799987793,
      "lon": -95.6635971069,
      "tz": "America\/Chicago"
  },
  "09MO": {
      "icao": "09MO",
      "iata": "",
      "name": "Hogue Farm Airport",
      "city": "Willard",
      "state": "Missouri",
      "country": "US",
      "elevation": 1210,
      "lat": 37.3783988953,
      "lon": -93.3632965088,
      "tz": "America\/Chicago"
  },
  "09NC": {
      "icao": "09NC",
      "iata": "",
      "name": "William Irving Lewis Airport",
      "city": "Broadway",
      "state": "North Carolina",
      "country": "US",
      "elevation": 310,
      "lat": 35.3292999268,
      "lon": -79.0299987793,
      "tz": "America\/New_York"
  },
  "09NE": {
      "icao": "09NE",
      "iata": "",
      "name": "Summer Hill Farm Airport",
      "city": "Bennington",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1250,
      "lat": 41.3333015442,
      "lon": -96.1936035156,
      "tz": "America\/Chicago"
  },
  "09OK": {
      "icao": "09OK",
      "iata": "",
      "name": "Ragtime Aerodrome",
      "city": "Collinsville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 700,
      "lat": 36.3544998169,
      "lon": -95.863899231,
      "tz": "America\/Chicago"
  },
  "09PA": {
      "icao": "09PA",
      "iata": "",
      "name": "Eagle Field",
      "city": "Port Matilda",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1780,
      "lat": 40.8269996643,
      "lon": -77.9880981445,
      "tz": "America\/New_York"
  },
  "09TA": {
      "icao": "09TA",
      "iata": "",
      "name": "Lazy G Bar Ranch Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 923,
      "lat": 33.2821006775,
      "lon": -97.4970016479,
      "tz": "America\/Chicago"
  },
  "09TE": {
      "icao": "09TE",
      "iata": "",
      "name": "Running M Ranch Airport",
      "city": "Glen Rose",
      "state": "Texas",
      "country": "US",
      "elevation": 787,
      "lat": 32.2667999268,
      "lon": -97.8669967651,
      "tz": "America\/Chicago"
  },
  "09TS": {
      "icao": "09TS",
      "iata": "",
      "name": "Pitcock Rosillos Mountain Ranch Airport",
      "city": "Marathon",
      "state": "Texas",
      "country": "US",
      "elevation": 3100,
      "lat": 29.4824008942,
      "lon": -103.1650009155,
      "tz": "America\/Chicago"
  },
  "09TX": {
      "icao": "09TX",
      "iata": "",
      "name": "Smokey Mtn Ranch Airport",
      "city": "Sheffield",
      "state": "Texas",
      "country": "US",
      "elevation": 2522,
      "lat": 30.7390995026,
      "lon": -102.0240020752,
      "tz": "America\/Chicago"
  },
  "09VA": {
      "icao": "09VA",
      "iata": "",
      "name": "Alum Ridge STOLport",
      "city": "Floyd",
      "state": "Virginia",
      "country": "US",
      "elevation": 2375,
      "lat": 36.9765014648,
      "lon": -80.4938964844,
      "tz": "America\/New_York"
  },
  "09WI": {
      "icao": "09WI",
      "iata": "",
      "name": "Parkway Farm Strip",
      "city": "Holmen",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 705,
      "lat": 44.0036010742,
      "lon": -91.3076019287,
      "tz": "America\/Chicago"
  },
  "0AK0": {
      "icao": "0AK0",
      "iata": "",
      "name": "Scotts Airport",
      "city": "North Pole",
      "state": "Alaska",
      "country": "US",
      "elevation": 800,
      "lat": 64.3925018311,
      "lon": -146.8619995117,
      "tz": "America\/Anchorage"
  },
  "0AK1": {
      "icao": "0AK1",
      "iata": "",
      "name": "Anderson Lake Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 463,
      "lat": 61.6169013977,
      "lon": -149.3220062256,
      "tz": "America\/Anchorage"
  },
  "0AK2": {
      "icao": "0AK2",
      "iata": "",
      "name": "Yentna Bend Strip",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 81,
      "lat": 61.725556,
      "lon": -150.678611,
      "tz": "America\/Anchorage"
  },
  "0AK3": {
      "icao": "0AK3",
      "iata": "",
      "name": "Parker Lake Airport",
      "city": "Montana Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 250,
      "lat": 62.035900116,
      "lon": -150.4940032959,
      "tz": "America\/Anchorage"
  },
  "0AK5": {
      "icao": "0AK5",
      "iata": "",
      "name": "Young Creek Airport",
      "city": "May Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 1580,
      "lat": 61.3564987183,
      "lon": -142.733001709,
      "tz": "America\/Anchorage"
  },
  "0AK6": {
      "icao": "0AK6",
      "iata": "",
      "name": "Victory Airport",
      "city": "Victory Bible Camp",
      "state": "Alaska",
      "country": "US",
      "elevation": 1800,
      "lat": 61.8033981323,
      "lon": -147.9440002441,
      "tz": "America\/Anchorage"
  },
  "0AK7": {
      "icao": "0AK7",
      "iata": "",
      "name": "Bradley Lake Hydroelectri Project Airstrip",
      "city": "Homer",
      "state": "Alaska",
      "country": "US",
      "elevation": 18,
      "lat": 59.7761001587,
      "lon": -150.9589996338,
      "tz": "America\/Anchorage"
  },
  "0AK9": {
      "icao": "0AK9",
      "iata": "",
      "name": "Falcon Lake Strip",
      "city": "Point Mackenzie",
      "state": "Alaska",
      "country": "US",
      "elevation": 110,
      "lat": 61.3325996399,
      "lon": -150.0590057373,
      "tz": "America\/Anchorage"
  },
  "0AL1": {
      "icao": "0AL1",
      "iata": "",
      "name": "Resort Airport",
      "city": "Foley",
      "state": "Alabama",
      "country": "US",
      "elevation": 97,
      "lat": 30.4438991547,
      "lon": -87.6568984985,
      "tz": "America\/Chicago"
  },
  "0AL5": {
      "icao": "0AL5",
      "iata": "",
      "name": "Flomaton Airport",
      "city": "Flomaton",
      "state": "Alabama",
      "country": "US",
      "elevation": 247,
      "lat": 31.0321998596,
      "lon": -87.2527999878,
      "tz": "America\/Chicago"
  },
  "0AL9": {
      "icao": "0AL9",
      "iata": "",
      "name": "Wilson Creek Airport",
      "city": "Florence",
      "state": "Alabama",
      "country": "US",
      "elevation": 590,
      "lat": 34.8499984741,
      "lon": -87.6324996948,
      "tz": "America\/Chicago"
  },
  "0AR1": {
      "icao": "0AR1",
      "iata": "",
      "name": "Hooterville South Airport",
      "city": "Centerville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 370,
      "lat": 35.0946998596,
      "lon": -93.202003479,
      "tz": "America\/Chicago"
  },
  "0AR2": {
      "icao": "0AR2",
      "iata": "",
      "name": "Mission Field-Marotti Memorial Airport",
      "city": "Crawfordsville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 217,
      "lat": 35.2667999268,
      "lon": -90.3500976563,
      "tz": "America\/Chicago"
  },
  "0AR3": {
      "icao": "0AR3",
      "iata": "",
      "name": "Taylor's Air Strip",
      "city": "Deer",
      "state": "Arkansas",
      "country": "US",
      "elevation": 2160,
      "lat": 35.8989982605,
      "lon": -93.2610015869,
      "tz": "America\/Chicago"
  },
  "0AR7": {
      "icao": "0AR7",
      "iata": "",
      "name": "Yancopin Airport",
      "city": "Watson",
      "state": "Arkansas",
      "country": "US",
      "elevation": 150,
      "lat": 33.9500999451,
      "lon": -91.1811981201,
      "tz": "America\/Chicago"
  },
  "0AZ1": {
      "icao": "0AZ1",
      "iata": "",
      "name": "Taylor Field",
      "city": "Marana",
      "state": "Arizona",
      "country": "US",
      "elevation": 2130,
      "lat": 32.3008995056,
      "lon": -111.3239974976,
      "tz": "America\/Phoenix"
  },
  "0AZ2": {
      "icao": "0AZ2",
      "iata": "",
      "name": "Western Sky Airport",
      "city": "Salome",
      "state": "Arizona",
      "country": "US",
      "elevation": 1930,
      "lat": 33.7780990601,
      "lon": -113.641998291,
      "tz": "America\/Phoenix"
  },
  "0AZ4": {
      "icao": "0AZ4",
      "iata": "",
      "name": "Flying Bucket Ranch Airport",
      "city": "Mobile",
      "state": "Arizona",
      "country": "US",
      "elevation": 1505,
      "lat": 32.9822998047,
      "lon": -112.2870025635,
      "tz": "America\/Phoenix"
  },
  "0AZ5": {
      "icao": "0AZ5",
      "iata": "",
      "name": "Castle Well Airport",
      "city": "Morristown",
      "state": "Arizona",
      "country": "US",
      "elevation": 2080,
      "lat": 33.8658981323,
      "lon": -112.6029968262,
      "tz": "America\/Phoenix"
  },
  "0CA1": {
      "icao": "0CA1",
      "iata": "",
      "name": "Northside Airpark",
      "city": "Santa Maria",
      "state": "California",
      "country": "US",
      "elevation": 190,
      "lat": 34.9883003235,
      "lon": -120.4580001831,
      "tz": "America\/Los_Angeles"
  },
  "0CA3": {
      "icao": "0CA3",
      "iata": "",
      "name": "Crawford Airport",
      "city": "Santa Ynez",
      "state": "California",
      "country": "US",
      "elevation": 570,
      "lat": 34.5875015259,
      "lon": -120.0130004883,
      "tz": "America\/Los_Angeles"
  },
  "0CA4": {
      "icao": "0CA4",
      "iata": "",
      "name": "Shepherd Ranch Airport",
      "city": "Santa Ynez",
      "state": "California",
      "country": "US",
      "elevation": 660,
      "lat": 34.6222000122,
      "lon": -120.0650024414,
      "tz": "America\/Los_Angeles"
  },
  "0CA5": {
      "icao": "0CA5",
      "iata": "",
      "name": "Hoffman Private Airport",
      "city": "Santa Ysabel",
      "state": "California",
      "country": "US",
      "elevation": 3100,
      "lat": 33.1431007385,
      "lon": -116.7450027466,
      "tz": "America\/Los_Angeles"
  },
  "0CA6": {
      "icao": "0CA6",
      "iata": "",
      "name": "Emory Ranch Airport",
      "city": "Ocotillo",
      "state": "California",
      "country": "US",
      "elevation": 480,
      "lat": 32.7494444,
      "lon": -116.0161111,
      "tz": "America\/Los_Angeles"
  },
  "0CA8": {
      "icao": "0CA8",
      "iata": "",
      "name": "Ward Ranch Airport",
      "city": "Aguanga",
      "state": "California",
      "country": "US",
      "elevation": 2500,
      "lat": 33.4277992249,
      "lon": -116.843002319,
      "tz": "America\/Los_Angeles"
  },
  "0CA9": {
      "icao": "0CA9",
      "iata": "",
      "name": "Blech Ranch Airport",
      "city": "Shandon",
      "state": "California",
      "country": "US",
      "elevation": 1140,
      "lat": 35.5950012207,
      "lon": -120.338996887,
      "tz": "America\/Los_Angeles"
  },
  "0CD2": {
      "icao": "0CD2",
      "iata": "",
      "name": "Foxx Valley Airport",
      "city": "Yoder",
      "state": "Colorado",
      "country": "US",
      "elevation": 5800,
      "lat": 38.6525001526,
      "lon": -104.23400116,
      "tz": "America\/Denver"
  },
  "0CD5": {
      "icao": "0CD5",
      "iata": "",
      "name": "Pinon Canyon Airport",
      "city": "Trinidad",
      "state": "Colorado",
      "country": "US",
      "elevation": 5686,
      "lat": 37.4905014038,
      "lon": -104.143997192,
      "tz": "America\/Denver"
  },
  "0CD7": {
      "icao": "0CD7",
      "iata": "",
      "name": "Fox Hole Airport",
      "city": "Lafayette",
      "state": "Colorado",
      "country": "US",
      "elevation": 5135,
      "lat": 40.004699707,
      "lon": -105.071998596,
      "tz": "America\/Denver"
  },
  "0CL0": {
      "icao": "0CL0",
      "iata": "",
      "name": "Yosemite Hidden Lake Ranch Airport",
      "city": "Raymond",
      "state": "California",
      "country": "US",
      "elevation": 500,
      "lat": 37.1180000305,
      "lon": -119.913002014,
      "tz": "America\/Los_Angeles"
  },
  "0CL1": {
      "icao": "0CL1",
      "iata": "",
      "name": "Krey Field Airport",
      "city": "Adelanto",
      "state": "California",
      "country": "US",
      "elevation": 3042,
      "lat": 34.5694007874,
      "lon": -117.555999756,
      "tz": "America\/Los_Angeles"
  },
  "0CL3": {
      "icao": "0CL3",
      "iata": "",
      "name": "John Nichol's Field Airport",
      "city": "Chula Vista",
      "state": "California",
      "country": "US",
      "elevation": 490,
      "lat": 32.6330986023,
      "lon": -116.892997742,
      "tz": "America\/Los_Angeles"
  },
  "0CL6": {
      "icao": "0CL6",
      "iata": "",
      "name": "Bohunk's Airpark",
      "city": "Lancaster",
      "state": "California",
      "country": "US",
      "elevation": 2410,
      "lat": 34.6946983337,
      "lon": -118.282997131,
      "tz": "America\/Los_Angeles"
  },
  "0CL8": {
      "icao": "0CL8",
      "iata": "",
      "name": "Tera Ultralightport",
      "city": "Ridgecrest",
      "state": "California",
      "country": "US",
      "elevation": 2510,
      "lat": 35.5913009644,
      "lon": -117.634002686,
      "tz": "America\/Los_Angeles"
  },
  "0CO1": {
      "icao": "0CO1",
      "iata": "",
      "name": "Dave's Airport",
      "city": "Louisville",
      "state": "Colorado",
      "country": "US",
      "elevation": 5170,
      "lat": 40.0332984924,
      "lon": -105.124000549,
      "tz": "America\/Denver"
  },
  "0CO2": {
      "icao": "0CO2",
      "iata": "",
      "name": "Buckhorn Ranch Airport",
      "city": "Crested Butte",
      "state": "Colorado",
      "country": "US",
      "elevation": 8980,
      "lat": 38.8518981934,
      "lon": -106.932998657,
      "tz": "America\/Denver"
  },
  "0CO3": {
      "icao": "0CO3",
      "iata": "",
      "name": "Greggs Nr 1 Airport",
      "city": "Bennett",
      "state": "Colorado",
      "country": "US",
      "elevation": 5373,
      "lat": 39.8894004822,
      "lon": -104.544998169,
      "tz": "America\/Denver"
  },
  "0CO5": {
      "icao": "0CO5",
      "iata": "",
      "name": "Chenoweth Airport",
      "city": "Last Chance",
      "state": "Colorado",
      "country": "US",
      "elevation": 4697,
      "lat": 39.8333015442,
      "lon": -103.589996338,
      "tz": "America\/Denver"
  },
  "0CO6": {
      "icao": "0CO6",
      "iata": "",
      "name": "Flying M & M Ranch Airport",
      "city": "Norwood",
      "state": "Colorado",
      "country": "US",
      "elevation": 8000,
      "lat": 38.2193984985,
      "lon": -108.212997437,
      "tz": "America\/Denver"
  },
  "0CO8": {
      "icao": "0CO8",
      "iata": "",
      "name": "Cartwheel Airport",
      "city": "Mead",
      "state": "Colorado",
      "country": "US",
      "elevation": 5010,
      "lat": 40.2083015442,
      "lon": -105.013000488,
      "tz": "America\/Denver"
  },
  "0CO9": {
      "icao": "0CO9",
      "iata": "",
      "name": "Van Treese Airport",
      "city": "Monte Vista",
      "state": "Colorado",
      "country": "US",
      "elevation": 7613,
      "lat": 37.6582984924,
      "lon": -106.033996582,
      "tz": "America\/Denver"
  },
  "0FA1": {
      "icao": "0FA1",
      "iata": "",
      "name": "Frierson Grove Airport",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 27.303899765,
      "lon": -81.8804016113,
      "tz": "America\/New_York"
  },
  "0FD0": {
      "icao": "0FD0",
      "iata": "",
      "name": "Big 'G' Airport",
      "city": "Ormond Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 40,
      "lat": 29.2674999237,
      "lon": -81.2301025391,
      "tz": "America\/New_York"
  },
  "0FD3": {
      "icao": "0FD3",
      "iata": "",
      "name": "Dugger Field",
      "city": "Freeport",
      "state": "Florida",
      "country": "US",
      "elevation": 70,
      "lat": 30.4964008331,
      "lon": -86.0938034058,
      "tz": "America\/Chicago"
  },
  "0FD5": {
      "icao": "0FD5",
      "iata": "",
      "name": "Breezy Knoll Airport",
      "city": "Defuniak Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 235,
      "lat": 30.8201999664,
      "lon": -86.3443984985,
      "tz": "America\/Chicago"
  },
  "0FD9": {
      "icao": "0FD9",
      "iata": "",
      "name": "Joy Farms Airport",
      "city": "Defuniak Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 290,
      "lat": 30.6520996094,
      "lon": -86.1093978882,
      "tz": "America\/Chicago"
  },
  "0FL0": {
      "icao": "0FL0",
      "iata": "",
      "name": "Harper's Fly-In Ranch Airport",
      "city": "Clewiston",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 26.7306003571,
      "lon": -81.1639022827,
      "tz": "America\/New_York"
  },
  "0FL1": {
      "icao": "0FL1",
      "iata": "",
      "name": "Thompson Airfield",
      "city": "Branford",
      "state": "Florida",
      "country": "US",
      "elevation": 110,
      "lat": 29.9832992554,
      "lon": -82.8356018066,
      "tz": "America\/New_York"
  },
  "0FL6": {
      "icao": "0FL6",
      "iata": "",
      "name": "Stanchester Airport",
      "city": "Lithia",
      "state": "Florida",
      "country": "US",
      "elevation": 94,
      "lat": 27.8059005737,
      "lon": -82.1400985718,
      "tz": "America\/New_York"
  },
  "0FL9": {
      "icao": "0FL9",
      "iata": "",
      "name": "Mc Daniel Ranch Inc. Airport",
      "city": "Clewiston",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 26.3938999176,
      "lon": -81.0231018066,
      "tz": "America\/New_York"
  },
  "0GA0": {
      "icao": "0GA0",
      "iata": "",
      "name": "Halls Flying Ranch Airport",
      "city": "Hampton",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.3562011719,
      "lon": -84.3671035767,
      "tz": "America\/New_York"
  },
  "0GA1": {
      "icao": "0GA1",
      "iata": "",
      "name": "Mustang Field",
      "city": "Hartwell",
      "state": "Georgia",
      "country": "US",
      "elevation": 693,
      "lat": 34.4779014587,
      "lon": -82.897102356,
      "tz": "America\/New_York"
  },
  "0GA2": {
      "icao": "0GA2",
      "iata": "",
      "name": "Airnautique Inc. Airport",
      "city": "Hartwell",
      "state": "Georgia",
      "country": "US",
      "elevation": 720,
      "lat": 34.3778991699,
      "lon": -82.9457015991,
      "tz": "America\/New_York"
  },
  "0GA3": {
      "icao": "0GA3",
      "iata": "",
      "name": "Ayresouth Airport",
      "city": "Temple",
      "state": "Georgia",
      "country": "US",
      "elevation": 1287,
      "lat": 33.7700996399,
      "lon": -85.060798645,
      "tz": "America\/New_York"
  },
  "0GA4": {
      "icao": "0GA4",
      "iata": "",
      "name": "Paradise Falls Airport",
      "city": "High Shoals",
      "state": "Georgia",
      "country": "US",
      "elevation": 736,
      "lat": 33.8129005432,
      "lon": -83.5056991577,
      "tz": "America\/New_York"
  },
  "0GA6": {
      "icao": "0GA6",
      "iata": "",
      "name": "Sunset Strip",
      "city": "Jersey",
      "state": "Georgia",
      "country": "US",
      "elevation": 780,
      "lat": 33.7333984375,
      "lon": -83.8188018799,
      "tz": "America\/New_York"
  },
  "0GA7": {
      "icao": "0GA7",
      "iata": "",
      "name": "Hickory Level Airfield",
      "city": "Temple",
      "state": "Georgia",
      "country": "US",
      "elevation": 1080,
      "lat": 33.6833992004,
      "lon": -84.9999008179,
      "tz": "America\/New_York"
  },
  "0GA8": {
      "icao": "0GA8",
      "iata": "",
      "name": "Paso Fino Farm Airport",
      "city": "Valdosta",
      "state": "Georgia",
      "country": "US",
      "elevation": 200,
      "lat": 30.8871002197,
      "lon": -83.4160003662,
      "tz": "America\/New_York"
  },
  "0GA9": {
      "icao": "0GA9",
      "iata": "",
      "name": "Roberta Industrial Park Airport",
      "city": "Roberta",
      "state": "Georgia",
      "country": "US",
      "elevation": 505,
      "lat": 32.7139015198,
      "lon": -84.0274963379,
      "tz": "America\/New_York"
  },
  "0GE0": {
      "icao": "0GE0",
      "iata": "",
      "name": "Pinewood Airport",
      "city": "Douglasville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1085,
      "lat": 33.7821006775,
      "lon": -84.8007965088,
      "tz": "America\/New_York"
  },
  "0GE1": {
      "icao": "0GE1",
      "iata": "",
      "name": "Crystal Lake Airpark",
      "city": "Irwinville",
      "state": "Georgia",
      "country": "US",
      "elevation": 327,
      "lat": 31.6655006409,
      "lon": -83.4470977783,
      "tz": "America\/New_York"
  },
  "0GE3": {
      "icao": "0GE3",
      "iata": "",
      "name": "Lookout Mountain Airport",
      "city": "Trenton",
      "state": "Georgia",
      "country": "US",
      "elevation": 563,
      "lat": 34.9047012329,
      "lon": -85.4597015381,
      "tz": "America\/New_York"
  },
  "0GE4": {
      "icao": "0GE4",
      "iata": "",
      "name": "Pecan Patch Airstrip",
      "city": "Sunnyside",
      "state": "Georgia",
      "country": "US",
      "elevation": 860,
      "lat": 33.331401825,
      "lon": -84.2667007446,
      "tz": "America\/New_York"
  },
  "0GE5": {
      "icao": "0GE5",
      "iata": "",
      "name": "Mountain Airpark",
      "city": "Cleveland",
      "state": "Georgia",
      "country": "US",
      "elevation": 1438,
      "lat": 34.5617980957,
      "lon": -83.7135009766,
      "tz": "America\/New_York"
  },
  "0GE7": {
      "icao": "0GE7",
      "iata": "",
      "name": "Carpenter Airport",
      "city": "Appling",
      "state": "Georgia",
      "country": "US",
      "elevation": 450,
      "lat": 33.5203018188,
      "lon": -82.3724975586,
      "tz": "America\/New_York"
  },
  "0GE9": {
      "icao": "0GE9",
      "iata": "",
      "name": "Pegasus Ranch Airport",
      "city": "Rocky Ford",
      "state": "Georgia",
      "country": "US",
      "elevation": 230,
      "lat": 32.693901062,
      "lon": -81.7985992432,
      "tz": "America\/New_York"
  },
  "0IA3": {
      "icao": "0IA3",
      "iata": "",
      "name": "Hawkeye Airport",
      "city": "Waterloo",
      "state": "Iowa",
      "country": "US",
      "elevation": 883,
      "lat": 42.4347000122,
      "lon": -92.3280029297,
      "tz": "America\/Chicago"
  },
  "0IA4": {
      "icao": "0IA4",
      "iata": "",
      "name": "Sharar Field",
      "city": "Mediapolis",
      "state": "Iowa",
      "country": "US",
      "elevation": 700,
      "lat": 41.0334014893,
      "lon": -91.0501022339,
      "tz": "America\/Chicago"
  },
  "0IA5": {
      "icao": "0IA5",
      "iata": "",
      "name": "Moore Private Airport",
      "city": "Millerton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1060,
      "lat": 40.8722000122,
      "lon": -93.2627029419,
      "tz": "America\/Chicago"
  },
  "0IA6": {
      "icao": "0IA6",
      "iata": "",
      "name": "Rinehart Airport",
      "city": "Victor",
      "state": "Iowa",
      "country": "US",
      "elevation": 920,
      "lat": 41.6596984863,
      "lon": -92.2899017334,
      "tz": "America\/Chicago"
  },
  "0IA8": {
      "icao": "0IA8",
      "iata": "",
      "name": "Hannen Airport",
      "city": "Center Point",
      "state": "Iowa",
      "country": "US",
      "elevation": 925,
      "lat": 42.2280006409,
      "lon": -91.7509994507,
      "tz": "America\/Chicago"
  },
  "0ID0": {
      "icao": "0ID0",
      "iata": "",
      "name": "South Fork Ranch Airport",
      "city": "Featherville",
      "state": "Idaho",
      "country": "US",
      "elevation": 4861,
      "lat": 43.6063995361,
      "lon": -115.1070022583,
      "tz": "America\/Boise"
  },
  "0ID1": {
      "icao": "0ID1",
      "iata": "",
      "name": "Ziggy's Airport",
      "city": "Filer",
      "state": "Idaho",
      "country": "US",
      "elevation": 3225,
      "lat": 42.6412010193,
      "lon": -114.6050033569,
      "tz": "America\/Boise"
  },
  "0ID2": {
      "icao": "0ID2",
      "iata": "",
      "name": "Flying Joseph Ranch Airport",
      "city": "May",
      "state": "Idaho",
      "country": "US",
      "elevation": 5600,
      "lat": 44.4429016113,
      "lon": -113.773002625,
      "tz": "America\/Boise"
  },
  "0ID3": {
      "icao": "0ID3",
      "iata": "",
      "name": "Coyote Run Airport",
      "city": "Mountain Home",
      "state": "Idaho",
      "country": "US",
      "elevation": 3150,
      "lat": 43.0758018494,
      "lon": -115.672996521,
      "tz": "America\/Boise"
  },
  "0ID4": {
      "icao": "0ID4",
      "iata": "",
      "name": "Black Butte Ranch Airport",
      "city": "Shoshone",
      "state": "Idaho",
      "country": "US",
      "elevation": 4020,
      "lat": 43.0476989746,
      "lon": -114.4700012207,
      "tz": "America\/Boise"
  },
  "0ID5": {
      "icao": "0ID5",
      "iata": "",
      "name": "Ez Lope Ranch Airport",
      "city": "Oreana",
      "state": "Idaho",
      "country": "US",
      "elevation": 3130,
      "lat": 43.0531005859,
      "lon": -116.4619979858,
      "tz": "America\/Boise"
  },
  "0ID7": {
      "icao": "0ID7",
      "iata": "",
      "name": "Z X Ranch Airport",
      "city": "Murphy",
      "state": "Idaho",
      "country": "US",
      "elevation": 3820,
      "lat": 43.230308,
      "lon": -116.763192,
      "tz": "America\/Boise"
  },
  "0ID8": {
      "icao": "0ID8",
      "iata": "",
      "name": "Pinnacle Airport",
      "city": "Grangeville",
      "state": "Idaho",
      "country": "US",
      "elevation": 3800,
      "lat": 45.775197,
      "lon": -116.178475,
      "tz": "America\/Los_Angeles"
  },
  "0II0": {
      "icao": "0II0",
      "iata": "",
      "name": "Chuck's Airport",
      "city": "Albany",
      "state": "Indiana",
      "country": "US",
      "elevation": 955,
      "lat": 40.2599983215,
      "lon": -85.2533035278,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0II1": {
      "icao": "0II1",
      "iata": "",
      "name": "Unsicker Airport",
      "city": "Bremen",
      "state": "Indiana",
      "country": "US",
      "elevation": 816,
      "lat": 40.4281005859,
      "lon": -86.1532974243,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0II2": {
      "icao": "0II2",
      "iata": "",
      "name": "Creighton Airport",
      "city": "Bremen",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 41.4572982788,
      "lon": -86.1243972778,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0II3": {
      "icao": "0II3",
      "iata": "",
      "name": "Miller Strip",
      "city": "Bunker Hill",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 40.6277999878,
      "lon": -86.0378036499,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0II5": {
      "icao": "0II5",
      "iata": "",
      "name": "Marshall Field",
      "city": "Chalmers",
      "state": "Indiana",
      "country": "US",
      "elevation": 705,
      "lat": 40.6595001221,
      "lon": -86.8572006226,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0II6": {
      "icao": "0II6",
      "iata": "",
      "name": "Kropf Airport",
      "city": "Goshen",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 41.6431007385,
      "lon": -85.8296966553,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0II7": {
      "icao": "0II7",
      "iata": "",
      "name": "Frost Field",
      "city": "Greenfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 900,
      "lat": 39.7956008911,
      "lon": -85.7861022949,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0II8": {
      "icao": "0II8",
      "iata": "",
      "name": "Sutton's Field",
      "city": "Lowell",
      "state": "Indiana",
      "country": "US",
      "elevation": 740,
      "lat": 41.324798584,
      "lon": -87.4758987427,
      "tz": "America\/Chicago"
  },
  "0II9": {
      "icao": "0II9",
      "iata": "",
      "name": "Winters Airport",
      "city": "Hall",
      "state": "Indiana",
      "country": "US",
      "elevation": 755,
      "lat": 39.534198761,
      "lon": -86.5772018433,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0IL2": {
      "icao": "0IL2",
      "iata": "",
      "name": "Griffin Airport",
      "city": "Alma",
      "state": "Illinois",
      "country": "US",
      "elevation": 541,
      "lat": 38.803056,
      "lon": -88.997778,
      "tz": "America\/Chicago"
  },
  "0IL3": {
      "icao": "0IL3",
      "iata": "",
      "name": "Flying B Ranch Airport",
      "city": "Danville",
      "state": "Illinois",
      "country": "US",
      "elevation": 696,
      "lat": 40.1570014954,
      "lon": -87.6744995117,
      "tz": "America\/Chicago"
  },
  "0IL8": {
      "icao": "0IL8",
      "iata": "",
      "name": "Walter Airport",
      "city": "De Kalb",
      "state": "Illinois",
      "country": "US",
      "elevation": 890,
      "lat": 41.8725013733,
      "lon": -88.7237014771,
      "tz": "America\/Chicago"
  },
  "0IL9": {
      "icao": "0IL9",
      "iata": "",
      "name": "Jack W. Watson Airport",
      "city": "De Kalb",
      "state": "Illinois",
      "country": "US",
      "elevation": 880,
      "lat": 41.854801178,
      "lon": -88.7873001099,
      "tz": "America\/Chicago"
  },
  "0IN1": {
      "icao": "0IN1",
      "iata": "",
      "name": "Snider Field",
      "city": "Decker",
      "state": "Indiana",
      "country": "US",
      "elevation": 400,
      "lat": 38.5,
      "lon": -87.5959014893,
      "tz": "America\/Chicago"
  },
  "0IN2": {
      "icao": "0IN2",
      "iata": "",
      "name": "The Lazy K Airport",
      "city": "Tocsin",
      "state": "Indiana",
      "country": "US",
      "elevation": 835,
      "lat": 40.8278007507,
      "lon": -85.0860977173,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0IN3": {
      "icao": "0IN3",
      "iata": "",
      "name": "Graves Landing Strip",
      "city": "Winamac",
      "state": "Indiana",
      "country": "US",
      "elevation": 704,
      "lat": 41.1166992188,
      "lon": -86.6667022705,
      "tz": "America\/Indiana\/Winamac"
  },
  "0IN4": {
      "icao": "0IN4",
      "iata": "",
      "name": "Air Park Field",
      "city": "Woodburn",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 41.1277999878,
      "lon": -84.9471969604,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "0IN5": {
      "icao": "0IN5",
      "iata": "",
      "name": "Byrne Field",
      "city": "Byrneville",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 38.3289985657,
      "lon": -86.0335998535,
      "tz": "America\/Kentucky\/Louisville"
  },
  "0IN9": {
      "icao": "0IN9",
      "iata": "",
      "name": "Parrish Airport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 390,
      "lat": 37.9930992126,
      "lon": -87.9514007568,
      "tz": "America\/Chicago"
  },
  "0IS3": {
      "icao": "0IS3",
      "iata": "",
      "name": "Cady Aerial RLA Restricted Landing Area",
      "city": "Deer Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 41.6608009338,
      "lon": -89.6936035156,
      "tz": "America\/Chicago"
  },
  "0IS4": {
      "icao": "0IS4",
      "iata": "",
      "name": "Dawson Farms Airport",
      "city": "Lovington",
      "state": "Illinois",
      "country": "US",
      "elevation": 670,
      "lat": 39.7625007629,
      "lon": -88.6715011597,
      "tz": "America\/Chicago"
  },
  "0KS0": {
      "icao": "0KS0",
      "iata": "",
      "name": "J V Ranch Airport",
      "city": "Osage City",
      "state": "Kansas",
      "country": "US",
      "elevation": 1180,
      "lat": 38.6333007813,
      "lon": -95.9253005981,
      "tz": "America\/Chicago"
  },
  "0KS2": {
      "icao": "0KS2",
      "iata": "",
      "name": "Kellie Mann Airfield",
      "city": "Ottawa",
      "state": "Kansas",
      "country": "US",
      "elevation": 1045,
      "lat": 38.5600013733,
      "lon": -95.30909729,
      "tz": "America\/Chicago"
  },
  "0KS3": {
      "icao": "0KS3",
      "iata": "",
      "name": "Camp Chippewa Airport",
      "city": "Ottawa",
      "state": "Kansas",
      "country": "US",
      "elevation": 1120,
      "lat": 38.5666999817,
      "lon": -95.366897583,
      "tz": "America\/Chicago"
  },
  "0KS4": {
      "icao": "0KS4",
      "iata": "",
      "name": "Norris Airport",
      "city": "Lawrence",
      "state": "Kansas",
      "country": "US",
      "elevation": 1065,
      "lat": 38.807239,
      "lon": -95.370056,
      "tz": "America\/Chicago"
  },
  "0KS6": {
      "icao": "0KS6",
      "iata": "",
      "name": "Kendrigan Airport",
      "city": "Peck",
      "state": "Kansas",
      "country": "US",
      "elevation": 1240,
      "lat": 37.4522018433,
      "lon": -97.3178024292,
      "tz": "America\/Chicago"
  },
  "0KS7": {
      "icao": "0KS7",
      "iata": "",
      "name": "Mono Aircraft Airport",
      "city": "Cheney",
      "state": "Kansas",
      "country": "US",
      "elevation": 1450,
      "lat": 37.6927986145,
      "lon": -97.8863983154,
      "tz": "America\/Chicago"
  },
  "0KS8": {
      "icao": "0KS8",
      "iata": "",
      "name": "Pearce Field",
      "city": "Parsons",
      "state": "Kansas",
      "country": "US",
      "elevation": 950,
      "lat": 37.3824996948,
      "lon": -95.3747024536,
      "tz": "America\/Chicago"
  },
  "0KS9": {
      "icao": "0KS9",
      "iata": "",
      "name": "Lmn 120 Airport",
      "city": "Douglas",
      "state": "Kansas",
      "country": "US",
      "elevation": 1330,
      "lat": 37.5158996582,
      "lon": -96.9197998047,
      "tz": "America\/Chicago"
  },
  "0KY0": {
      "icao": "0KY0",
      "iata": "",
      "name": "Owen Air Park",
      "city": "Owenton",
      "state": "Kentucky",
      "country": "US",
      "elevation": 890,
      "lat": 38.6227989197,
      "lon": -84.7789001465,
      "tz": "America\/New_York"
  },
  "0KY1": {
      "icao": "0KY1",
      "iata": "",
      "name": "Arnemann Farms Airport",
      "city": "Franklin",
      "state": "Kentucky",
      "country": "US",
      "elevation": 504,
      "lat": 36.7655982971,
      "lon": -86.6183013916,
      "tz": "America\/Chicago"
  },
  "0KY4": {
      "icao": "0KY4",
      "iata": "",
      "name": "Cambron Field",
      "city": "Owensboro",
      "state": "Kentucky",
      "country": "US",
      "elevation": 427,
      "lat": 37.5643997192,
      "lon": -87.0819015503,
      "tz": "America\/Chicago"
  },
  "0KY5": {
      "icao": "0KY5",
      "iata": "",
      "name": "Falin Landing Strip",
      "city": "Bowling Green",
      "state": "Kentucky",
      "country": "US",
      "elevation": 600,
      "lat": 36.8125,
      "lon": -86.375,
      "tz": "America\/Chicago"
  },
  "0KY7": {
      "icao": "0KY7",
      "iata": "",
      "name": "Clinton-Hickman County Airport",
      "city": "Clinton",
      "state": "Kentucky",
      "country": "US",
      "elevation": 360,
      "lat": 36.636100769,
      "lon": -88.9985961914,
      "tz": "America\/Chicago"
  },
  "0KY8": {
      "icao": "0KY8",
      "iata": "",
      "name": "Sky Airport",
      "city": "Manchester",
      "state": "Kentucky",
      "country": "US",
      "elevation": 850,
      "lat": 37.1171989441,
      "lon": -83.7360992432,
      "tz": "America\/New_York"
  },
  "0LA1": {
      "icao": "0LA1",
      "iata": "",
      "name": "Double H Ranch Airport",
      "city": "Gonzales",
      "state": "Louisiana",
      "country": "US",
      "elevation": 15,
      "lat": 30.2294006348,
      "lon": -90.9733963013,
      "tz": "America\/Chicago"
  },
  "0LA2": {
      "icao": "0LA2",
      "iata": "",
      "name": "Don Babin-Private Airport",
      "city": "Gonzales",
      "state": "Louisiana",
      "country": "US",
      "elevation": 17,
      "lat": 30.2779998779,
      "lon": -90.9632034302,
      "tz": "America\/Chicago"
  },
  "0LA3": {
      "icao": "0LA3",
      "iata": "",
      "name": "Belcher Airpatch Airport",
      "city": "Belcher",
      "state": "Louisiana",
      "country": "US",
      "elevation": 280,
      "lat": 32.7492980957,
      "lon": -93.8734970093,
      "tz": "America\/Chicago"
  },
  "0LA9": {
      "icao": "0LA9",
      "iata": "",
      "name": "Rebel Field",
      "city": "Watson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 74,
      "lat": 30.5862998962,
      "lon": -90.8615036011,
      "tz": "America\/Chicago"
  },
  "0LL0": {
      "icao": "0LL0",
      "iata": "",
      "name": "Kaufield Airport",
      "city": "Belvidere",
      "state": "Illinois",
      "country": "US",
      "elevation": 783,
      "lat": 42.2647018433,
      "lon": -88.7300033569,
      "tz": "America\/Chicago"
  },
  "0LL1": {
      "icao": "0LL1",
      "iata": "",
      "name": "Jerseyville Aviation Inc Airport",
      "city": "Jerseyville",
      "state": "Illinois",
      "country": "US",
      "elevation": 604,
      "lat": 39.0942001343,
      "lon": -90.3134002686,
      "tz": "America\/Chicago"
  },
  "0LL2": {
      "icao": "0LL2",
      "iata": "",
      "name": "Murk's Strip",
      "city": "St. Augustine",
      "state": "Illinois",
      "country": "US",
      "elevation": 700,
      "lat": 40.738899231,
      "lon": -90.3806991577,
      "tz": "America\/Chicago"
  },
  "0LL3": {
      "icao": "0LL3",
      "iata": "",
      "name": "Koenig Airport",
      "city": "Jerseyville",
      "state": "Illinois",
      "country": "US",
      "elevation": 614,
      "lat": 39.0550003052,
      "lon": -90.3421020508,
      "tz": "America\/Chicago"
  },
  "0LL4": {
      "icao": "0LL4",
      "iata": "",
      "name": "Flaherty Field",
      "city": "La Salle",
      "state": "Illinois",
      "country": "US",
      "elevation": 664,
      "lat": 41.4192008972,
      "lon": -89.1231002808,
      "tz": "America\/Chicago"
  },
  "0LL5": {
      "icao": "0LL5",
      "iata": "",
      "name": "Busboom RLA Restricted Landing Area",
      "city": "St Joseph",
      "state": "Illinois",
      "country": "US",
      "elevation": 675,
      "lat": 40.1100006104,
      "lon": -88.0783996582,
      "tz": "America\/Chicago"
  },
  "0LL6": {
      "icao": "0LL6",
      "iata": "",
      "name": "Gord Airport",
      "city": "Sandwich",
      "state": "Illinois",
      "country": "US",
      "elevation": 710,
      "lat": 41.6983985901,
      "lon": -88.6075973511,
      "tz": "America\/Chicago"
  },
  "0LS0": {
      "icao": "0LS0",
      "iata": "",
      "name": "Theriot Field",
      "city": "Thibodaux",
      "state": "Louisiana",
      "country": "US",
      "elevation": 13,
      "lat": 29.7497005463,
      "lon": -90.7358016968,
      "tz": "America\/Chicago"
  },
  "0LS4": {
      "icao": "0LS4",
      "iata": "",
      "name": "Buller's Airstrip",
      "city": "Bunkie",
      "state": "Louisiana",
      "country": "US",
      "elevation": 47,
      "lat": 30.8087005615,
      "lon": -92.18800354,
      "tz": "America\/Chicago"
  },
  "0LS5": {
      "icao": "0LS5",
      "iata": "",
      "name": "Trahan Ultralightport",
      "city": "Cankton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 40,
      "lat": 30.3069000244,
      "lon": -92.1297988892,
      "tz": "America\/Chicago"
  },
  "0LS6": {
      "icao": "0LS6",
      "iata": "",
      "name": "Dufour Airport",
      "city": "Morrow",
      "state": "Louisiana",
      "country": "US",
      "elevation": 42,
      "lat": 30.7877006531,
      "lon": -92.0503997803,
      "tz": "America\/Chicago"
  },
  "0LS7": {
      "icao": "0LS7",
      "iata": "",
      "name": "Morgan Field",
      "city": "New Roads",
      "state": "Louisiana",
      "country": "US",
      "elevation": 36,
      "lat": 30.6128005981,
      "lon": -91.4841995239,
      "tz": "America\/Chicago"
  },
  "0LS8": {
      "icao": "0LS8",
      "iata": "",
      "name": "Lonesome Dove Airfield",
      "city": "Woodlawn",
      "state": "Louisiana",
      "country": "US",
      "elevation": 34,
      "lat": 30.2858009338,
      "lon": -92.9412994385,
      "tz": "America\/Chicago"
  },
  "0LS9": {
      "icao": "0LS9",
      "iata": "",
      "name": "Huenefeld Airport",
      "city": "Monroe",
      "state": "Louisiana",
      "country": "US",
      "elevation": 72,
      "lat": 32.5042991638,
      "lon": -91.9821014404,
      "tz": "America\/Chicago"
  },
  "0MD1": {
      "icao": "0MD1",
      "iata": "",
      "name": "Taylor Field",
      "city": "Sudlersville",
      "state": "Maryland",
      "country": "US",
      "elevation": 75,
      "lat": 39.1873016357,
      "lon": -75.7927017212,
      "tz": "America\/New_York"
  },
  "0MD2": {
      "icao": "0MD2",
      "iata": "",
      "name": "Squier Landing Airport",
      "city": "Cobb Island",
      "state": "Maryland",
      "country": "US",
      "elevation": 16,
      "lat": 38.2878990173,
      "lon": -76.8675003052,
      "tz": "America\/New_York"
  },
  "0MD4": {
      "icao": "0MD4",
      "iata": "",
      "name": "Pond View Private Airport",
      "city": "Chestertown",
      "state": "Maryland",
      "country": "US",
      "elevation": 47,
      "lat": 39.2667999268,
      "lon": -76.1872024536,
      "tz": "America\/New_York"
  },
  "0MD6": {
      "icao": "0MD6",
      "iata": "",
      "name": "Walters Airport",
      "city": "Mount Airy",
      "state": "Maryland",
      "country": "US",
      "elevation": 750,
      "lat": 39.3811988831,
      "lon": -77.105796814,
      "tz": "America\/New_York"
  },
  "0ME4": {
      "icao": "0ME4",
      "iata": "",
      "name": "Peasley Field",
      "city": "Otis",
      "state": "Maine",
      "country": "US",
      "elevation": 271,
      "lat": 44.742469,
      "lon": -68.474403,
      "tz": "America\/New_York"
  },
  "0MI1": {
      "icao": "0MI1",
      "iata": "",
      "name": "Sugar Springs Airport",
      "city": "Gladwin",
      "state": "Michigan",
      "country": "US",
      "elevation": 940,
      "lat": 44.1403007507,
      "lon": -84.4375,
      "tz": "America\/Detroit"
  },
  "0MI2": {
      "icao": "0MI2",
      "iata": "",
      "name": "Stier Airstrip",
      "city": "Glennie",
      "state": "Michigan",
      "country": "US",
      "elevation": 850,
      "lat": 44.5222015381,
      "lon": -83.6832962036,
      "tz": "America\/Detroit"
  },
  "0MI3": {
      "icao": "0MI3",
      "iata": "",
      "name": "Kelleys Airport",
      "city": "Grayling",
      "state": "Michigan",
      "country": "US",
      "elevation": 1000,
      "lat": 44.6333999634,
      "lon": -84.4833984375,
      "tz": "America\/Detroit"
  },
  "0MI4": {
      "icao": "0MI4",
      "iata": "",
      "name": "Lesterson Field",
      "city": "Gwinn",
      "state": "Michigan",
      "country": "US",
      "elevation": 1120,
      "lat": 46.270198822,
      "lon": -87.3864974976,
      "tz": "America\/Detroit"
  },
  "0MI6": {
      "icao": "0MI6",
      "iata": "",
      "name": "Young's Airport",
      "city": "Hulbert",
      "state": "Michigan",
      "country": "US",
      "elevation": 800,
      "lat": 46.3875007629,
      "lon": -85.1839981079,
      "tz": "America\/Detroit"
  },
  "0MI8": {
      "icao": "0MI8",
      "iata": "",
      "name": "Twin Lakes Airport",
      "city": "Lewiston",
      "state": "Michigan",
      "country": "US",
      "elevation": 1275,
      "lat": 44.9000015259,
      "lon": -84.3000030518,
      "tz": "America\/Detroit"
  },
  "0MN1": {
      "icao": "0MN1",
      "iata": "",
      "name": "Brinkman Airport",
      "city": "St. Peter",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1020,
      "lat": 44.2625007629,
      "lon": -94.0697021484,
      "tz": "America\/Chicago"
  },
  "0MN5": {
      "icao": "0MN5",
      "iata": "",
      "name": "Pankratz Airport",
      "city": "Springfield",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1033,
      "lat": 44.3072013855,
      "lon": -94.9169006348,
      "tz": "America\/Chicago"
  },
  "0MN6": {
      "icao": "0MN6",
      "iata": "",
      "name": "Matson Field",
      "city": "Spring Valley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1365,
      "lat": 43.6622009277,
      "lon": -92.404296875,
      "tz": "America\/Chicago"
  },
  "0MN8": {
      "icao": "0MN8",
      "iata": "",
      "name": "Keller Airport",
      "city": "Stillwater",
      "state": "Minnesota",
      "country": "US",
      "elevation": 990,
      "lat": 45.135799408,
      "lon": -92.8593978882,
      "tz": "America\/Chicago"
  },
  "0MO0": {
      "icao": "0MO0",
      "iata": "",
      "name": "Ferros Ranch-Aero Airport",
      "city": "Clinton",
      "state": "Missouri",
      "country": "US",
      "elevation": 775,
      "lat": 38.4103012085,
      "lon": -93.8501968384,
      "tz": "America\/Chicago"
  },
  "0MO1": {
      "icao": "0MO1",
      "iata": "",
      "name": "Flying G Airport",
      "city": "Creighton",
      "state": "Missouri",
      "country": "US",
      "elevation": 920,
      "lat": 38.5444984436,
      "lon": -94.0947036743,
      "tz": "America\/Chicago"
  },
  "0MO2": {
      "icao": "0MO2",
      "iata": "",
      "name": "Short-N-Ruff Airport",
      "city": "Caledonia",
      "state": "Missouri",
      "country": "US",
      "elevation": 950,
      "lat": 37.7867012024,
      "lon": -90.7800979614,
      "tz": "America\/Chicago"
  },
  "0MO3": {
      "icao": "0MO3",
      "iata": "",
      "name": "Arvin Ranch Airport",
      "city": "Drexel",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 38.4402999878,
      "lon": -94.5932998657,
      "tz": "America\/Chicago"
  },
  "0MO5": {
      "icao": "0MO5",
      "iata": "",
      "name": "Joe D Lewis Airport",
      "city": "Edina",
      "state": "Missouri",
      "country": "US",
      "elevation": 780,
      "lat": 40.1333999634,
      "lon": -92.1001968384,
      "tz": "America\/Chicago"
  },
  "0MO6": {
      "icao": "0MO6",
      "iata": "",
      "name": "Hall Airport",
      "city": "Edina",
      "state": "Missouri",
      "country": "US",
      "elevation": 850,
      "lat": 40.1166992188,
      "lon": -92.2668991089,
      "tz": "America\/Chicago"
  },
  "0MO8": {
      "icao": "0MO8",
      "iata": "",
      "name": "Sloan's Airport",
      "city": "Elsberry",
      "state": "Missouri",
      "country": "US",
      "elevation": 437,
      "lat": 39.1405982971,
      "lon": -90.7368011475,
      "tz": "America\/Chicago"
  },
  "0MS0": {
      "icao": "0MS0",
      "iata": "",
      "name": "Topton Air Estates Airport",
      "city": "Meridian",
      "state": "Mississippi",
      "country": "US",
      "elevation": 453,
      "lat": 32.4749984741,
      "lon": -88.6166992188,
      "tz": "America\/Chicago"
  },
  "0MS1": {
      "icao": "0MS1",
      "iata": "",
      "name": "Franklin Field",
      "city": "Meadville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 200,
      "lat": 31.4251995087,
      "lon": -90.9029006958,
      "tz": "America\/Chicago"
  },
  "0MS2": {
      "icao": "0MS2",
      "iata": "",
      "name": "Morgan Field",
      "city": "Seminary",
      "state": "Mississippi",
      "country": "US",
      "elevation": 300,
      "lat": 31.5417995453,
      "lon": -89.3917007446,
      "tz": "America\/Chicago"
  },
  "0MS5": {
      "icao": "0MS5",
      "iata": "",
      "name": "Lewis Air Service Airport",
      "city": "Leland",
      "state": "Mississippi",
      "country": "US",
      "elevation": 120,
      "lat": 33.3917999268,
      "lon": -90.9168014526,
      "tz": "America\/Chicago"
  },
  "0MS7": {
      "icao": "0MS7",
      "iata": "",
      "name": "Hale Field",
      "city": "Pontotoc",
      "state": "Mississippi",
      "country": "US",
      "elevation": 346,
      "lat": 34.3426017761,
      "lon": -89.1430969238,
      "tz": "America\/Chicago"
  },
  "0MS8": {
      "icao": "0MS8",
      "iata": "",
      "name": "Catfish Point Airport",
      "city": "Benoit",
      "state": "Mississippi",
      "country": "US",
      "elevation": 154,
      "lat": 33.6992988586,
      "lon": -91.1651000977,
      "tz": "America\/Chicago"
  },
  "0MS9": {
      "icao": "0MS9",
      "iata": "",
      "name": "Shenandoah Valley Farms Airport",
      "city": "Houston",
      "state": "Mississippi",
      "country": "US",
      "elevation": 300,
      "lat": 33.8007011414,
      "lon": -89.0272979736,
      "tz": "America\/Chicago"
  },
  "0MT1": {
      "icao": "0MT1",
      "iata": "",
      "name": "Williams Field",
      "city": "Augusta",
      "state": "Montana",
      "country": "US",
      "elevation": 4070,
      "lat": 47.4975013733,
      "lon": -112.3909988403,
      "tz": "America\/Denver"
  },
  "0MT4": {
      "icao": "0MT4",
      "iata": "",
      "name": "Kimp Airport",
      "city": "Hamilton",
      "state": "Montana",
      "country": "US",
      "elevation": 3510,
      "lat": 46.2785987854,
      "lon": -114.1409988403,
      "tz": "America\/Denver"
  },
  "0MT5": {
      "icao": "0MT5",
      "iata": "",
      "name": "Cottonwood Airport",
      "city": "Laurel",
      "state": "Montana",
      "country": "US",
      "elevation": 3350,
      "lat": 45.6342010498,
      "lon": -108.8489990234,
      "tz": "America\/Denver"
  },
  "0MT6": {
      "icao": "0MT6",
      "iata": "",
      "name": "Hanson Airport",
      "city": "Marion",
      "state": "Montana",
      "country": "US",
      "elevation": 3900,
      "lat": 48.040599823,
      "lon": -114.6790008545,
      "tz": "America\/Denver"
  },
  "0MT7": {
      "icao": "0MT7",
      "iata": "",
      "name": "Pinehurst Ranch Airport",
      "city": "Thompson Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 2460,
      "lat": 47.6608009338,
      "lon": -115.4039993286,
      "tz": "America\/Denver"
  },
  "0MT9": {
      "icao": "0MT9",
      "iata": "",
      "name": "Lone Hawk Airport",
      "city": "White Fish",
      "state": "Montana",
      "country": "US",
      "elevation": 3284,
      "lat": 48.4832992554,
      "lon": -114.4929962158,
      "tz": "America\/Denver"
  },
  "0MU0": {
      "icao": "0MU0",
      "iata": "",
      "name": "Eagle Field",
      "city": "Ellington",
      "state": "Missouri",
      "country": "US",
      "elevation": 784,
      "lat": 37.2346992493,
      "lon": -91.0935974121,
      "tz": "America\/Chicago"
  },
  "0MU1": {
      "icao": "0MU1",
      "iata": "",
      "name": "Sunderland Airport",
      "city": "Avilla",
      "state": "Missouri",
      "country": "US",
      "elevation": 1027,
      "lat": 37.2467002869,
      "lon": -94.1738967896,
      "tz": "America\/Chicago"
  },
  "0MU4": {
      "icao": "0MU4",
      "iata": "",
      "name": "Slaughter Airport",
      "city": "Marceline",
      "state": "Missouri",
      "country": "US",
      "elevation": 800,
      "lat": 39.6161003113,
      "lon": -92.866897583,
      "tz": "America\/Chicago"
  },
  "0MU7": {
      "icao": "0MU7",
      "iata": "",
      "name": "Lambs Field",
      "city": "Lone Jack",
      "state": "Missouri",
      "country": "US",
      "elevation": 1070,
      "lat": 38.875,
      "lon": -94.1397018433,
      "tz": "America\/Chicago"
  },
  "0MU8": {
      "icao": "0MU8",
      "iata": "",
      "name": "Prince STOLport",
      "city": "Millersville",
      "state": "Missouri",
      "country": "US",
      "elevation": 582,
      "lat": 37.4387016296,
      "lon": -89.7590026855,
      "tz": "America\/Chicago"
  },
  "0NA1": {
      "icao": "0NA1",
      "iata": "",
      "name": "Goerger Airport",
      "city": "Barney",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1045,
      "lat": 46.2454986572,
      "lon": -97.0375976563,
      "tz": "America\/Chicago"
  },
  "0NA5": {
      "icao": "0NA5",
      "iata": "",
      "name": "Sorlie Airport",
      "city": "Maddock",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1580,
      "lat": 48.0010986328,
      "lon": -99.5628967285,
      "tz": "America\/Chicago"
  },
  "0NA9": {
      "icao": "0NA9",
      "iata": "",
      "name": "Boyd's Turf Airport",
      "city": "Golva",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2750,
      "lat": 46.75,
      "lon": -103.8010025024,
      "tz": "America\/Denver"
  },
  "0NC1": {
      "icao": "0NC1",
      "iata": "",
      "name": "Bearwallow Farm Airport",
      "city": "Hendersonville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 3608,
      "lat": 35.4570999146,
      "lon": -82.3753967285,
      "tz": "America\/New_York"
  },
  "0NC7": {
      "icao": "0NC7",
      "iata": "",
      "name": "Lindsay Airport",
      "city": "Madison",
      "state": "North Carolina",
      "country": "US",
      "elevation": 700,
      "lat": 36.3572006226,
      "lon": -79.9974975586,
      "tz": "America\/New_York"
  },
  "0NC8": {
      "icao": "0NC8",
      "iata": "",
      "name": "Double Creek Ranch Airport",
      "city": "Cleveland",
      "state": "North Carolina",
      "country": "US",
      "elevation": 830,
      "lat": 35.7556991577,
      "lon": -80.7352981567,
      "tz": "America\/New_York"
  },
  "0ND0": {
      "icao": "0ND0",
      "iata": "",
      "name": "Gilbertson Field",
      "city": "Flora",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1550,
      "lat": 47.9291992188,
      "lon": -99.4003982544,
      "tz": "America\/Chicago"
  },
  "0ND6": {
      "icao": "0ND6",
      "iata": "",
      "name": "Schumacher Strip",
      "city": "Hague",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2020,
      "lat": 46.0550003052,
      "lon": -99.917098999,
      "tz": "America\/Chicago"
  },
  "0ND7": {
      "icao": "0ND7",
      "iata": "",
      "name": "Saville Private Airport",
      "city": "Hazelton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1961,
      "lat": 46.4653015137,
      "lon": -100.1050033569,
      "tz": "America\/Chicago"
  },
  "0NE0": {
      "icao": "0NE0",
      "iata": "",
      "name": "Merrihew Airport",
      "city": "Ashby",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3815,
      "lat": 41.8499984741,
      "lon": -101.9499969482,
      "tz": "America\/Denver"
  },
  "0NE1": {
      "icao": "0NE1",
      "iata": "",
      "name": "Runner Landing Area Airport",
      "city": "Ashby",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3760,
      "lat": 42.4071998596,
      "lon": -101.9319992065,
      "tz": "America\/Denver"
  },
  "0NE2": {
      "icao": "0NE2",
      "iata": "",
      "name": "Merry Airport",
      "city": "Ashby",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3791,
      "lat": 41.8666000366,
      "lon": -101.9169998169,
      "tz": "America\/Denver"
  },
  "0NE5": {
      "icao": "0NE5",
      "iata": "",
      "name": "Newman Airport",
      "city": "Big Springs",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3525,
      "lat": 41.0210990906,
      "lon": -102.0179977417,
      "tz": "America\/Denver"
  },
  "0NE6": {
      "icao": "0NE6",
      "iata": "",
      "name": "Frager Field",
      "city": "Fairfield",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1765,
      "lat": 40.4333000183,
      "lon": -98.070602417,
      "tz": "America\/Chicago"
  },
  "0NJ5": {
      "icao": "0NJ5",
      "iata": "",
      "name": "Fla-Net Airport",
      "city": "Netcong",
      "state": "New Jersey",
      "country": "US",
      "elevation": 1040,
      "lat": 40.8858985901,
      "lon": -74.7057037354,
      "tz": "America\/New_York"
  },
  "0NJ6": {
      "icao": "0NJ6",
      "iata": "",
      "name": "Dix Field",
      "city": "Linwood",
      "state": "New Jersey",
      "country": "US",
      "elevation": 8,
      "lat": 39.3167991638,
      "lon": -74.6328964233,
      "tz": "America\/New_York"
  },
  "0NK0": {
      "icao": "0NK0",
      "iata": "",
      "name": "Berdick Field",
      "city": "Cattaraugus",
      "state": "New York",
      "country": "US",
      "elevation": 1690,
      "lat": 42.3339996338,
      "lon": -78.7909011841,
      "tz": "America\/New_York"
  },
  "0NK1": {
      "icao": "0NK1",
      "iata": "",
      "name": "Spring Brook Airport",
      "city": "Central Square",
      "state": "New York",
      "country": "US",
      "elevation": 450,
      "lat": 43.3616981506,
      "lon": -76.220199585,
      "tz": "America\/New_York"
  },
  "0NK2": {
      "icao": "0NK2",
      "iata": "",
      "name": "Westwind Farm Airport",
      "city": "Charlton",
      "state": "New York",
      "country": "US",
      "elevation": 800,
      "lat": 42.9833984375,
      "lon": -74.0161972046,
      "tz": "America\/New_York"
  },
  "0NK3": {
      "icao": "0NK3",
      "iata": "",
      "name": "Seven Gullies Airport",
      "city": "Groveland",
      "state": "New York",
      "country": "US",
      "elevation": 600,
      "lat": 42.6958999634,
      "lon": -77.7921981812,
      "tz": "America\/New_York"
  },
  "0NK6": {
      "icao": "0NK6",
      "iata": "",
      "name": "Lapeer Flyer Airport",
      "city": "Marathon",
      "state": "New York",
      "country": "US",
      "elevation": 1280,
      "lat": 42.4384002686,
      "lon": -76.0887985229,
      "tz": "America\/New_York"
  },
  "0NK8": {
      "icao": "0NK8",
      "iata": "",
      "name": "Rocky Reef Farm Airport",
      "city": "Attlebury",
      "state": "New York",
      "country": "US",
      "elevation": 450,
      "lat": 41.9208984375,
      "lon": -73.6704025269,
      "tz": "America\/New_York"
  },
  "0NK9": {
      "icao": "0NK9",
      "iata": "",
      "name": "Lakeview Airport",
      "city": "Chittenango",
      "state": "New York",
      "country": "US",
      "elevation": 1050,
      "lat": 43.0102996826,
      "lon": -75.8268966675,
      "tz": "America\/New_York"
  },
  "0NM0": {
      "icao": "0NM0",
      "iata": "",
      "name": "Columbus Municipal Airport",
      "city": "Columbus",
      "state": "New Mexico",
      "country": "US",
      "elevation": 4024,
      "lat": 31.8236999512,
      "lon": -107.6269989014,
      "tz": "America\/Denver"
  },
  "0NM7": {
      "icao": "0NM7",
      "iata": "",
      "name": "Negrito Airstrip",
      "city": "Reserve",
      "state": "New Mexico",
      "country": "US",
      "elevation": 8143,
      "lat": 33.5209007263,
      "lon": -108.5429992676,
      "tz": "America\/Denver"
  },
  "0NY0": {
      "icao": "0NY0",
      "iata": "",
      "name": "Bennetts Airport",
      "city": "North Creek",
      "state": "New York",
      "country": "US",
      "elevation": 1040,
      "lat": 43.7000999451,
      "lon": -73.9829025269,
      "tz": "America\/New_York"
  },
  "0NY1": {
      "icao": "0NY1",
      "iata": "",
      "name": "Russell Field",
      "city": "Northumberland",
      "state": "New York",
      "country": "US",
      "elevation": 260,
      "lat": 43.1391983032,
      "lon": -73.6531982422,
      "tz": "America\/New_York"
  },
  "0NY3": {
      "icao": "0NY3",
      "iata": "",
      "name": "Kermizian Airport",
      "city": "Ohio",
      "state": "New York",
      "country": "US",
      "elevation": 1440,
      "lat": 43.313999176,
      "lon": -74.9320983887,
      "tz": "America\/New_York"
  },
  "0NY7": {
      "icao": "0NY7",
      "iata": "",
      "name": "Murphys Landing Strip",
      "city": "Perth",
      "state": "New York",
      "country": "US",
      "elevation": 940,
      "lat": 43.0376014709,
      "lon": -74.1843032837,
      "tz": "America\/New_York"
  },
  "0NY8": {
      "icao": "0NY8",
      "iata": "",
      "name": "Wenskoski Field",
      "city": "Perth",
      "state": "New York",
      "country": "US",
      "elevation": 800,
      "lat": 42.9972991943,
      "lon": -74.2453994751,
      "tz": "America\/New_York"
  },
  "0OH2": {
      "icao": "0OH2",
      "iata": "",
      "name": "Jenkins Field",
      "city": "North Hampton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1080,
      "lat": 39.9706001282,
      "lon": -83.9115982056,
      "tz": "America\/New_York"
  },
  "0OH6": {
      "icao": "0OH6",
      "iata": "",
      "name": "Fry Field",
      "city": "Spring Valley",
      "state": "Ohio",
      "country": "US",
      "elevation": 775,
      "lat": 39.6006011963,
      "lon": -84.0196990967,
      "tz": "America\/New_York"
  },
  "0OH7": {
      "icao": "0OH7",
      "iata": "",
      "name": "Apple Airport",
      "city": "Piqua",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 40.1432991028,
      "lon": -84.1718978882,
      "tz": "America\/New_York"
  },
  "0OH8": {
      "icao": "0OH8",
      "iata": "",
      "name": "Fry Airport",
      "city": "Piqua",
      "state": "Ohio",
      "country": "US",
      "elevation": 900,
      "lat": 40.1884002686,
      "lon": -84.2561035156,
      "tz": "America\/New_York"
  },
  "0OH9": {
      "icao": "0OH9",
      "iata": "",
      "name": "Parknavy STOLport",
      "city": "Plain City",
      "state": "Ohio",
      "country": "US",
      "elevation": 943,
      "lat": 40.0717010498,
      "lon": -83.2298965454,
      "tz": "America\/New_York"
  },
  "0OI2": {
      "icao": "0OI2",
      "iata": "",
      "name": "Unger Field",
      "city": "Greenfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 940,
      "lat": 39.3597984314,
      "lon": -83.4177017212,
      "tz": "America\/New_York"
  },
  "0OI3": {
      "icao": "0OI3",
      "iata": "",
      "name": "Galloway Airport",
      "city": "Sandusky",
      "state": "Ohio",
      "country": "US",
      "elevation": 643,
      "lat": 41.3820991516,
      "lon": -82.7161026001,
      "tz": "America\/New_York"
  },
  "0OI4": {
      "icao": "0OI4",
      "iata": "",
      "name": "Salt Box Airport",
      "city": "Hiram",
      "state": "Ohio",
      "country": "US",
      "elevation": 1250,
      "lat": 41.3242645264,
      "lon": -81.1593093872,
      "tz": "America\/New_York"
  },
  "0OI6": {
      "icao": "0OI6",
      "iata": "",
      "name": "Victor's Landing Airport",
      "city": "Kingsville",
      "state": "Ohio",
      "country": "US",
      "elevation": 860,
      "lat": 41.8381004333,
      "lon": -80.643699646,
      "tz": "America\/New_York"
  },
  "0OI9": {
      "icao": "0OI9",
      "iata": "",
      "name": "Hidden Quarry Airport",
      "city": "Lucasville",
      "state": "Ohio",
      "country": "US",
      "elevation": 830,
      "lat": 38.8945007324,
      "lon": -83.1135025024,
      "tz": "America\/New_York"
  },
  "0OK0": {
      "icao": "0OK0",
      "iata": "",
      "name": "Edmond Airport",
      "city": "Edmond",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1169,
      "lat": 35.6833992004,
      "lon": -97.4336013794,
      "tz": "America\/Chicago"
  },
  "0OK1": {
      "icao": "0OK1",
      "iata": "",
      "name": "Mckey Airport",
      "city": "Elmore City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1056,
      "lat": 34.6570014954,
      "lon": -97.4017028809,
      "tz": "America\/Chicago"
  },
  "0OK3": {
      "icao": "0OK3",
      "iata": "",
      "name": "Mckinley Ranch Airport",
      "city": "Geary",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1420,
      "lat": 35.6194992065,
      "lon": -98.5225982666,
      "tz": "America\/Chicago"
  },
  "0OK4": {
      "icao": "0OK4",
      "iata": "",
      "name": "Rock Creek Farm Airport",
      "city": "Coal Gate",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 730,
      "lat": 34.6605987549,
      "lon": -96.2536010742,
      "tz": "America\/Chicago"
  },
  "0OK5": {
      "icao": "0OK5",
      "iata": "",
      "name": "Chappell Airport",
      "city": "Guthrie",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1070,
      "lat": 35.8889007568,
      "lon": -97.4002990723,
      "tz": "America\/Chicago"
  },
  "0OK6": {
      "icao": "0OK6",
      "iata": "",
      "name": "Ellis\/Harvey Airport",
      "city": "Guthrie",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1220,
      "lat": 35.801700592,
      "lon": -97.386100769,
      "tz": "America\/Chicago"
  },
  "0OK8": {
      "icao": "0OK8",
      "iata": "",
      "name": "Cade's Airport",
      "city": "Covington",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1140,
      "lat": 36.2924995422,
      "lon": -97.5931015015,
      "tz": "America\/Chicago"
  },
  "0OK9": {
      "icao": "0OK9",
      "iata": "",
      "name": "Crystal Airport",
      "city": "Jennings",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1016,
      "lat": 36.2134017944,
      "lon": -96.6370010376,
      "tz": "America\/Chicago"
  },
  "0OR3": {
      "icao": "0OR3",
      "iata": "",
      "name": "Long Ranch Airport",
      "city": "Merrill",
      "state": "Oregon",
      "country": "US",
      "elevation": 4090,
      "lat": 42.0190010071,
      "lon": -121.7040023804,
      "tz": "America\/Los_Angeles"
  },
  "0OR5": {
      "icao": "0OR5",
      "iata": "",
      "name": "East Oregon Cattle Co Airport",
      "city": "Eagle Point",
      "state": "Oregon",
      "country": "US",
      "elevation": 1345,
      "lat": 42.5035018921,
      "lon": -122.855003357,
      "tz": "America\/Los_Angeles"
  },
  "0OR6": {
      "icao": "0OR6",
      "iata": "",
      "name": "Rome Service Airport",
      "city": "Rome",
      "state": "Oregon",
      "country": "US",
      "elevation": 3387,
      "lat": 42.8339996338,
      "lon": -117.6289978027,
      "tz": "America\/Boise"
  },
  "0OR7": {
      "icao": "0OR7",
      "iata": "",
      "name": "Marr Field",
      "city": "Monmouth",
      "state": "Oregon",
      "country": "US",
      "elevation": 275,
      "lat": 44.8540000916,
      "lon": -123.263999939,
      "tz": "America\/Los_Angeles"
  },
  "0OR8": {
      "icao": "0OR8",
      "iata": "",
      "name": "Sutton on Rogue Airport",
      "city": "White City",
      "state": "Oregon",
      "country": "US",
      "elevation": 1260,
      "lat": 42.4847984314,
      "lon": -122.8659973145,
      "tz": "America\/Los_Angeles"
  },
  "0OR9": {
      "icao": "0OR9",
      "iata": "",
      "name": "Hanel Field",
      "city": "Mount Hood",
      "state": "Oregon",
      "country": "US",
      "elevation": 1626,
      "lat": 45.5926017761,
      "lon": -121.5490036011,
      "tz": "America\/Los_Angeles"
  },
  "0PA0": {
      "icao": "0PA0",
      "iata": "",
      "name": "Philadelphia Gliderport",
      "city": "Hilltown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 670,
      "lat": 40.3283996582,
      "lon": -75.2413024902,
      "tz": "America\/New_York"
  },
  "0PA2": {
      "icao": "0PA2",
      "iata": "",
      "name": "Haunstein Ultralightport",
      "city": "Shermans Dale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 600,
      "lat": 40.3167991638,
      "lon": -77.1247024536,
      "tz": "America\/New_York"
  },
  "0PA3": {
      "icao": "0PA3",
      "iata": "",
      "name": "Robbins Farm Airport",
      "city": "Troy",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1207,
      "lat": 41.7495002747,
      "lon": -76.7602005005,
      "tz": "America\/New_York"
  },
  "0PA4": {
      "icao": "0PA4",
      "iata": "",
      "name": "Ecko Field",
      "city": "Hummelstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 380,
      "lat": 40.2375984192,
      "lon": -76.7247009277,
      "tz": "America\/New_York"
  },
  "0PA5": {
      "icao": "0PA5",
      "iata": "",
      "name": "Fisher Airport",
      "city": "Franklin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1500,
      "lat": 41.4944992065,
      "lon": -79.8309020996,
      "tz": "America\/New_York"
  },
  "0PA6": {
      "icao": "0PA6",
      "iata": "",
      "name": "Hostetler Airport",
      "city": "Huntingdon",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 760,
      "lat": 40.6309013367,
      "lon": -77.8503036499,
      "tz": "America\/New_York"
  },
  "0PA7": {
      "icao": "0PA7",
      "iata": "",
      "name": "Linvill Airport",
      "city": "Media",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 180,
      "lat": 39.8847999573,
      "lon": -75.4149017334,
      "tz": "America\/New_York"
  },
  "0PN0": {
      "icao": "0PN0",
      "iata": "",
      "name": "Fletcher Airport",
      "city": "Sheakeyville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1335,
      "lat": 41.4435997009,
      "lon": -80.1539001465,
      "tz": "America\/New_York"
  },
  "0PN2": {
      "icao": "0PN2",
      "iata": "",
      "name": "Flying Acres Airport",
      "city": "Slippery Rock",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1247,
      "lat": 41.0731010437,
      "lon": -80.0702972412,
      "tz": "America\/New_York"
  },
  "0PN4": {
      "icao": "0PN4",
      "iata": "",
      "name": "Kitner Airport",
      "city": "New Bloomfield",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 960,
      "lat": 40.4128990173,
      "lon": -77.2414016724,
      "tz": "America\/New_York"
  },
  "0PN5": {
      "icao": "0PN5",
      "iata": "",
      "name": "Nichols Airport",
      "city": "Spartansburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1761,
      "lat": 41.7792015076,
      "lon": -79.5983963013,
      "tz": "America\/New_York"
  },
  "0PN6": {
      "icao": "0PN6",
      "iata": "",
      "name": "Memmi Airport",
      "city": "Three Springs",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1138,
      "lat": 40.1689987183,
      "lon": -77.9925003052,
      "tz": "America\/New_York"
  },
  "0PN7": {
      "icao": "0PN7",
      "iata": "",
      "name": "Drillmore Acres Airport",
      "city": "Newburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 680,
      "lat": 40.1282997131,
      "lon": -77.631401062,
      "tz": "America\/New_York"
  },
  "0PN8": {
      "icao": "0PN8",
      "iata": "",
      "name": "Shriver Airport",
      "city": "Titusville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1560,
      "lat": 41.6166992188,
      "lon": -79.6830978394,
      "tz": "America\/New_York"
  },
  "0PN9": {
      "icao": "0PN9",
      "iata": "",
      "name": "Paul Personal Use Airport",
      "city": "Titusville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1645,
      "lat": 41.7195014954,
      "lon": -79.6778030396,
      "tz": "America\/New_York"
  },
  "0PS0": {
      "icao": "0PS0",
      "iata": "",
      "name": "Frederick Airpark",
      "city": "Tylersburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1560,
      "lat": 41.3708992004,
      "lon": -79.3416976929,
      "tz": "America\/New_York"
  },
  "0PS3": {
      "icao": "0PS3",
      "iata": "",
      "name": "Carsonville Airport",
      "city": "Carsonville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 800,
      "lat": 40.490398407,
      "lon": -76.7666015625,
      "tz": "America\/New_York"
  },
  "0PS4": {
      "icao": "0PS4",
      "iata": "",
      "name": "Old Orchard Airport",
      "city": "Curwensville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1980,
      "lat": 41.0083999634,
      "lon": -78.5608978271,
      "tz": "America\/New_York"
  },
  "0PS7": {
      "icao": "0PS7",
      "iata": "",
      "name": "Harman Airport",
      "city": "Elizabethville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 720,
      "lat": 40.5223007202,
      "lon": -76.8454971313,
      "tz": "America\/New_York"
  },
  "0SD0": {
      "icao": "0SD0",
      "iata": "",
      "name": "Lenling Airport",
      "city": "Glencross",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2142,
      "lat": 45.4249992371,
      "lon": -100.891998291,
      "tz": "America\/Denver"
  },
  "0SD1": {
      "icao": "0SD1",
      "iata": "",
      "name": "Lodi Airport",
      "city": "Wakonda",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1200,
      "lat": 42.9721984863,
      "lon": -96.9802017212,
      "tz": "America\/Chicago"
  },
  "0SD3": {
      "icao": "0SD3",
      "iata": "",
      "name": "Bledsoe Ranch Airport",
      "city": "Hoover",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2872,
      "lat": 45.2694015503,
      "lon": -103.2170028687,
      "tz": "America\/Denver"
  },
  "0SD6": {
      "icao": "0SD6",
      "iata": "",
      "name": "Oasis Ranch Airport",
      "city": "Philip",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2580,
      "lat": 44.288898468,
      "lon": -101.8259963989,
      "tz": "America\/Denver"
  },
  "0SD7": {
      "icao": "0SD7",
      "iata": "",
      "name": "Porch Ranch Airport",
      "city": "Wanblee",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2775,
      "lat": 43.5243988037,
      "lon": -101.7340011597,
      "tz": "America\/Denver"
  },
  "0SD8": {
      "icao": "0SD8",
      "iata": "",
      "name": "Juhnke Airport",
      "city": "Vivian",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1900,
      "lat": 43.7827987671,
      "lon": -100.2929992676,
      "tz": "America\/Chicago"
  },
  "0TA1": {
      "icao": "0TA1",
      "iata": "",
      "name": "Buddy Harmel Airport",
      "city": "Halfway",
      "state": "Texas",
      "country": "US",
      "elevation": 3552,
      "lat": 34.2195014954,
      "lon": -101.9800033569,
      "tz": "America\/Chicago"
  },
  "0TA2": {
      "icao": "0TA2",
      "iata": "",
      "name": "Smith Farms Airport",
      "city": "Halfway",
      "state": "Texas",
      "country": "US",
      "elevation": 3487,
      "lat": 34.2167015076,
      "lon": -101.9250030518,
      "tz": "America\/Chicago"
  },
  "0TA3": {
      "icao": "0TA3",
      "iata": "",
      "name": "Tate Ranch Airport",
      "city": "Dryden",
      "state": "Texas",
      "country": "US",
      "elevation": 2650,
      "lat": 30.3043003082,
      "lon": -102.1380004883,
      "tz": "America\/Chicago"
  },
  "0TA4": {
      "icao": "0TA4",
      "iata": "",
      "name": "Erco Field",
      "city": "Kaufman",
      "state": "Texas",
      "country": "US",
      "elevation": 395,
      "lat": 32.4978981018,
      "lon": -96.2705001831,
      "tz": "America\/Chicago"
  },
  "0TA7": {
      "icao": "0TA7",
      "iata": "",
      "name": "Alta Vista Ranch Airport",
      "city": "Marfa",
      "state": "Texas",
      "country": "US",
      "elevation": 4702,
      "lat": 30.1481990814,
      "lon": -103.8929977417,
      "tz": "America\/Chicago"
  },
  "0TA8": {
      "icao": "0TA8",
      "iata": "",
      "name": "Womack Plantation Airport",
      "city": "Paris",
      "state": "Texas",
      "country": "US",
      "elevation": 410,
      "lat": 33.8487014771,
      "lon": -95.4072036743,
      "tz": "America\/Chicago"
  },
  "0TE0": {
      "icao": "0TE0",
      "iata": "",
      "name": "Robertson Ranch Airport",
      "city": "Comstock",
      "state": "Texas",
      "country": "US",
      "elevation": 1663,
      "lat": 29.6930007935,
      "lon": -101.1620025635,
      "tz": "America\/Chicago"
  },
  "0TE3": {
      "icao": "0TE3",
      "iata": "",
      "name": "Buzz Field",
      "city": "Roaring Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 2614,
      "lat": 33.9291992188,
      "lon": -100.8420028687,
      "tz": "America\/Chicago"
  },
  "0TE4": {
      "icao": "0TE4",
      "iata": "",
      "name": "Hilltop Lakes Airport",
      "city": "Hilltop Lakes",
      "state": "Texas",
      "country": "US",
      "elevation": 501,
      "lat": 31.0809993744,
      "lon": -96.2152023315,
      "tz": "America\/Chicago"
  },
  "0TE5": {
      "icao": "0TE5",
      "iata": "",
      "name": "Santa Maria Ranch Airport",
      "city": "Laredo",
      "state": "Texas",
      "country": "US",
      "elevation": 516,
      "lat": 27.4337997437,
      "lon": -99.1858978271,
      "tz": "America\/Chicago"
  },
  "0TE6": {
      "icao": "0TE6",
      "iata": "",
      "name": "Gorman Airport",
      "city": "Gorman",
      "state": "Texas",
      "country": "US",
      "elevation": 1452,
      "lat": 32.2042999268,
      "lon": -98.6656036377,
      "tz": "America\/Chicago"
  },
  "0TE7": {
      "icao": "0TE7",
      "iata": "",
      "name": "Lbj Ranch Airport",
      "city": "Johnson City",
      "state": "Texas",
      "country": "US",
      "elevation": 1515,
      "lat": 30.2518005371,
      "lon": -98.6224975586,
      "tz": "America\/Chicago"
  },
  "0TN0": {
      "icao": "0TN0",
      "iata": "",
      "name": "R & S Buzzard Airport",
      "city": "Spring Hill",
      "state": "Tennessee",
      "country": "US",
      "elevation": 810,
      "lat": 35.676700592,
      "lon": -86.896697998,
      "tz": "America\/Chicago"
  },
  "0TN2": {
      "icao": "0TN2",
      "iata": "",
      "name": "Village Airport",
      "city": "Greenback",
      "state": "Tennessee",
      "country": "US",
      "elevation": 920,
      "lat": 35.6558990479,
      "lon": -84.1855010986,
      "tz": "America\/New_York"
  },
  "0TN5": {
      "icao": "0TN5",
      "iata": "",
      "name": "Mc Donald Airport",
      "city": "Murfreesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 600,
      "lat": 35.867401123,
      "lon": -86.4978027344,
      "tz": "America\/Chicago"
  },
  "0TN6": {
      "icao": "0TN6",
      "iata": "",
      "name": "Riner Farm Airport",
      "city": "Norris",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1125,
      "lat": 36.1833992004,
      "lon": -84.0990982056,
      "tz": "America\/New_York"
  },
  "0TS0": {
      "icao": "0TS0",
      "iata": "",
      "name": "Flower Mound Airport",
      "city": "Flower Mound",
      "state": "Texas",
      "country": "US",
      "elevation": 625,
      "lat": 33.0228996277,
      "lon": -97.1224975586,
      "tz": "America\/Chicago"
  },
  "0TS1": {
      "icao": "0TS1",
      "iata": "",
      "name": "Dooley Airport",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 650,
      "lat": 33.0987014771,
      "lon": -97.2724990845,
      "tz": "America\/Chicago"
  },
  "0TS2": {
      "icao": "0TS2",
      "iata": "",
      "name": "Ultralight International Ultralightport",
      "city": "Haslet",
      "state": "Texas",
      "country": "US",
      "elevation": 820,
      "lat": 32.9486999512,
      "lon": -97.3289031982,
      "tz": "America\/Chicago"
  },
  "0TS4": {
      "icao": "0TS4",
      "iata": "",
      "name": "Ullrich Airport",
      "city": "Ledbetter",
      "state": "Texas",
      "country": "US",
      "elevation": 410,
      "lat": 30.0918998718,
      "lon": -96.7949981689,
      "tz": "America\/Chicago"
  },
  "0TS5": {
      "icao": "0TS5",
      "iata": "",
      "name": "Lake Bay Gall Airport",
      "city": "Cleveland",
      "state": "Texas",
      "country": "US",
      "elevation": 230,
      "lat": 30.4419002533,
      "lon": -95.1863021851,
      "tz": "America\/Chicago"
  },
  "0TS7": {
      "icao": "0TS7",
      "iata": "",
      "name": "Flying U Airport",
      "city": "Mineral Wells",
      "state": "Texas",
      "country": "US",
      "elevation": 978,
      "lat": 32.8334999084,
      "lon": -98.1252975464,
      "tz": "America\/Chicago"
  },
  "0TS8": {
      "icao": "0TS8",
      "iata": "",
      "name": "Flying 'K' Ranch Ultralightport",
      "city": "Mercedes",
      "state": "Texas",
      "country": "US",
      "elevation": 81,
      "lat": 26.1504001617,
      "lon": -97.8835983276,
      "tz": "America\/Chicago"
  },
  "0TS9": {
      "icao": "0TS9",
      "iata": "",
      "name": "Jim Roach Field",
      "city": "Niederwald",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 30.015499115,
      "lon": -97.737197876,
      "tz": "America\/Chicago"
  },
  "0TX0": {
      "icao": "0TX0",
      "iata": "",
      "name": "Nassau Bay Airport",
      "city": "Granbury",
      "state": "Texas",
      "country": "US",
      "elevation": 714,
      "lat": 32.4196014404,
      "lon": -97.7127990723,
      "tz": "America\/Chicago"
  },
  "0TX1": {
      "icao": "0TX1",
      "iata": "",
      "name": "Pecan Plantation Airport",
      "city": "Granbury",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 32.3540000916,
      "lon": -97.676399231,
      "tz": "America\/Chicago"
  },
  "0TX5": {
      "icao": "0TX5",
      "iata": "",
      "name": "Shiloh Airport",
      "city": "Flower Mound",
      "state": "Texas",
      "country": "US",
      "elevation": 635,
      "lat": 33.0239982605,
      "lon": -97.1196975708,
      "tz": "America\/Chicago"
  },
  "0TX6": {
      "icao": "0TX6",
      "iata": "",
      "name": "Elm Creek Airpark",
      "city": "Seguin",
      "state": "Texas",
      "country": "US",
      "elevation": 550,
      "lat": 29.5051994324,
      "lon": -97.9969024658,
      "tz": "America\/Chicago"
  },
  "0TX7": {
      "icao": "0TX7",
      "iata": "",
      "name": "Lazy K Acres Airport",
      "city": "Grapevine",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.9500999451,
      "lon": -97.1502990723,
      "tz": "America\/Chicago"
  },
  "0TX8": {
      "icao": "0TX8",
      "iata": "",
      "name": "Jacobia Field",
      "city": "Greenville",
      "state": "Texas",
      "country": "US",
      "elevation": 570,
      "lat": 33.1804008484,
      "lon": -96.043296814,
      "tz": "America\/Chicago"
  },
  "0TX9": {
      "icao": "0TX9",
      "iata": "",
      "name": "Card Aerodrome",
      "city": "Greenville",
      "state": "Texas",
      "country": "US",
      "elevation": 540,
      "lat": 33.2500991821,
      "lon": -96.116897583,
      "tz": "America\/Chicago"
  },
  "0VA1": {
      "icao": "0VA1",
      "iata": "",
      "name": "Johnson Fox Field",
      "city": "Bedford",
      "state": "Virginia",
      "country": "US",
      "elevation": 900,
      "lat": 37.2335014343,
      "lon": -79.5852966309,
      "tz": "America\/New_York"
  },
  "0VA2": {
      "icao": "0VA2",
      "iata": "",
      "name": "Riverwood Airport",
      "city": "Iron Gate",
      "state": "Virginia",
      "country": "US",
      "elevation": 1020,
      "lat": 37.7965011597,
      "lon": -79.7667007446,
      "tz": "America\/New_York"
  },
  "0VA3": {
      "icao": "0VA3",
      "iata": "",
      "name": "Hunt Airport",
      "city": "Manquin",
      "state": "Virginia",
      "country": "US",
      "elevation": 150,
      "lat": 37.6987991333,
      "lon": -77.0952987671,
      "tz": "America\/New_York"
  },
  "0VA4": {
      "icao": "0VA4",
      "iata": "",
      "name": "Worley Field",
      "city": "Rocky Mount",
      "state": "Virginia",
      "country": "US",
      "elevation": 1219,
      "lat": 36.9500999451,
      "lon": -79.8795013428,
      "tz": "America\/New_York"
  },
  "0VA5": {
      "icao": "0VA5",
      "iata": "",
      "name": "Coffman Field",
      "city": "Richmond",
      "state": "Virginia",
      "country": "US",
      "elevation": 280,
      "lat": 37.6941443722,
      "lon": -77.6608943939,
      "tz": "America\/New_York"
  },
  "0VA7": {
      "icao": "0VA7",
      "iata": "",
      "name": "Flatrock Air Strip",
      "city": "Richmond",
      "state": "Virginia",
      "country": "US",
      "elevation": 425,
      "lat": 37.5232009888,
      "lon": -77.8193969727,
      "tz": "America\/New_York"
  },
  "0VA8": {
      "icao": "0VA8",
      "iata": "",
      "name": "Jayarz Airport",
      "city": "Mannboro",
      "state": "Virginia",
      "country": "US",
      "elevation": 210,
      "lat": 37.2858009338,
      "lon": -77.7911987305,
      "tz": "America\/New_York"
  },
  "0VA9": {
      "icao": "0VA9",
      "iata": "",
      "name": "Handy Strip",
      "city": "Gloucester Court House",
      "state": "Virginia",
      "country": "US",
      "elevation": 8,
      "lat": 37.4473991394,
      "lon": -76.4537963867,
      "tz": "America\/New_York"
  },
  "0VI1": {
      "icao": "0VI1",
      "iata": "",
      "name": "Brooklyn Airport",
      "city": "Manquin",
      "state": "Virginia",
      "country": "US",
      "elevation": 145,
      "lat": 37.6888999939,
      "lon": -77.1014022827,
      "tz": "America\/New_York"
  },
  "0WA1": {
      "icao": "0WA1",
      "iata": "",
      "name": "Dwight Field",
      "city": "Chehalis",
      "state": "Washington",
      "country": "US",
      "elevation": 350,
      "lat": 46.617099762,
      "lon": -122.8259963989,
      "tz": "America\/Los_Angeles"
  },
  "0WA2": {
      "icao": "0WA2",
      "iata": "",
      "name": "Curtis Airport",
      "city": "Curtis",
      "state": "Washington",
      "country": "US",
      "elevation": 254,
      "lat": 46.5904006958,
      "lon": -123.1050033569,
      "tz": "America\/Los_Angeles"
  },
  "0WI1": {
      "icao": "0WI1",
      "iata": "",
      "name": "Mort's Landing Airport",
      "city": "Comstock",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1255,
      "lat": 45.5071983337,
      "lon": -92.1418991089,
      "tz": "America\/Chicago"
  },
  "0WI2": {
      "icao": "0WI2",
      "iata": "",
      "name": "Doering Farms Airfield",
      "city": "Monches",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 910,
      "lat": 43.2014007568,
      "lon": -88.3333969116,
      "tz": "America\/Chicago"
  },
  "0WI4": {
      "icao": "0WI4",
      "iata": "",
      "name": "Snow Crest Ranch Airport",
      "city": "Montello",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 810,
      "lat": 43.8293991089,
      "lon": -89.3526000977,
      "tz": "America\/Chicago"
  },
  "0WI5": {
      "icao": "0WI5",
      "iata": "",
      "name": "Crash In International Airport",
      "city": "Husher",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 755,
      "lat": 42.7933998108,
      "lon": -87.8906021118,
      "tz": "America\/Chicago"
  },
  "0WI6": {
      "icao": "0WI6",
      "iata": "",
      "name": "Simandl Field\/Private Airport",
      "city": "Muskego",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 805,
      "lat": 42.8736000061,
      "lon": -88.1578979492,
      "tz": "America\/Chicago"
  },
  "0WI7": {
      "icao": "0WI7",
      "iata": "",
      "name": "With Wings And A Halo Airport",
      "city": "Neenah",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 760,
      "lat": 44.1335983276,
      "lon": -88.4992980957,
      "tz": "America\/Chicago"
  },
  "0WI8": {
      "icao": "0WI8",
      "iata": "",
      "name": "Oconomowoc Airport",
      "city": "Oconomowoc",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 885,
      "lat": 43.1389007568,
      "lon": -88.4722976685,
      "tz": "America\/Chicago"
  },
  "0WI9": {
      "icao": "0WI9",
      "iata": "",
      "name": "Mc Manus Hoonch-Na-Shee-Kaw Airport",
      "city": "Oregon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 931,
      "lat": 42.9314002991,
      "lon": -89.3386993408,
      "tz": "America\/Chicago"
  },
  "0WN0": {
      "icao": "0WN0",
      "iata": "",
      "name": "Rucilla's Roost Airport",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 15,
      "lat": 48.137298584,
      "lon": -123.1129989624,
      "tz": "America\/Los_Angeles"
  },
  "0WN1": {
      "icao": "0WN1",
      "iata": "",
      "name": "Shangri-La Airport",
      "city": "Selah",
      "state": "Washington",
      "country": "US",
      "elevation": 1500,
      "lat": 46.7518997192,
      "lon": -120.6019973755,
      "tz": "America\/Los_Angeles"
  },
  "0WN2": {
      "icao": "0WN2",
      "iata": "",
      "name": "Coopers Landing",
      "city": "Kennewick",
      "state": "Washington",
      "country": "US",
      "elevation": 435,
      "lat": 46.127778,
      "lon": -119.015,
      "tz": "America\/Los_Angeles"
  },
  "0WN5": {
      "icao": "0WN5",
      "iata": "",
      "name": "Potts Field",
      "city": "North Cape",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 815,
      "lat": 42.7997016907,
      "lon": -88.0486984253,
      "tz": "America\/Chicago"
  },
  "0WN9": {
      "icao": "0WN9",
      "iata": "",
      "name": "Wings For Christ Airport",
      "city": "Omak",
      "state": "Washington",
      "country": "US",
      "elevation": 1000,
      "lat": 48.430464,
      "lon": -119.486058,
      "tz": "America\/Los_Angeles"
  },
  "0WY0": {
      "icao": "0WY0",
      "iata": "",
      "name": "Freedom Air Ranch Airport",
      "city": "Freedom",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5698,
      "lat": 43.039722,
      "lon": -111.038056,
      "tz": "America\/Boise"
  },
  "0WY1": {
      "icao": "0WY1",
      "iata": "",
      "name": "Dorsey Creek Ranch Airport",
      "city": "Basin",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4017,
      "lat": 44.411111,
      "lon": -108.163333,
      "tz": "America\/Denver"
  },
  "0XA4": {
      "icao": "0XA4",
      "iata": "",
      "name": "Seven C's Ranch Airport",
      "city": "Freer",
      "state": "Texas",
      "country": "US",
      "elevation": 331,
      "lat": 27.9969005585,
      "lon": -98.8822021484,
      "tz": "America\/Chicago"
  },
  "0XA5": {
      "icao": "0XA5",
      "iata": "",
      "name": "74 Ranch Airport",
      "city": "Campbellton",
      "state": "Texas",
      "country": "US",
      "elevation": 320,
      "lat": 28.6849002838,
      "lon": -98.3827972412,
      "tz": "America\/Chicago"
  },
  "0XA7": {
      "icao": "0XA7",
      "iata": "",
      "name": "Bar 3 Ranch Airport",
      "city": "Georgetown",
      "state": "Texas",
      "country": "US",
      "elevation": 744,
      "lat": 30.66270065,
      "lon": -97.62509918,
      "tz": "America\/Chicago"
  },
  "0XA8": {
      "icao": "0XA8",
      "iata": "",
      "name": "Ohho Airport",
      "city": "Dripping Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 1340,
      "lat": 30.2700004578,
      "lon": -98.1082992554,
      "tz": "America\/Chicago"
  },
  "0XS0": {
      "icao": "0XS0",
      "iata": "",
      "name": "Diamond H Ranch Airport",
      "city": "Catarina",
      "state": "Texas",
      "country": "US",
      "elevation": 511,
      "lat": 28.4002990723,
      "lon": -99.5587005615,
      "tz": "America\/Chicago"
  },
  "0XS1": {
      "icao": "0XS1",
      "iata": "",
      "name": "Rodgers Roost Airport",
      "city": "El Campo",
      "state": "Texas",
      "country": "US",
      "elevation": 129,
      "lat": 29.2877998352,
      "lon": -96.3497009277,
      "tz": "America\/Chicago"
  },
  "0XS2": {
      "icao": "0XS2",
      "iata": "",
      "name": "John Fields Ranch Airport",
      "city": "Sonora",
      "state": "Texas",
      "country": "US",
      "elevation": 2342,
      "lat": 30.5548992157,
      "lon": -100.533996582,
      "tz": "America\/Chicago"
  },
  "0XS6": {
      "icao": "0XS6",
      "iata": "",
      "name": "Lakeside Beach Airport",
      "city": "Spicewood",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 30.5009994507,
      "lon": -98.1470031738,
      "tz": "America\/Chicago"
  },
  "0XS7": {
      "icao": "0XS7",
      "iata": "",
      "name": "Anacacho Ranch Airport",
      "city": "Spofford",
      "state": "Texas",
      "country": "US",
      "elevation": 1067,
      "lat": 29.1919002533,
      "lon": -100.2750015259,
      "tz": "America\/Chicago"
  },
  "0XS8": {
      "icao": "0XS8",
      "iata": "",
      "name": "Dunbar Ranch Airport",
      "city": "Spofford",
      "state": "Texas",
      "country": "US",
      "elevation": 958,
      "lat": 29.0760993958,
      "lon": -100.375,
      "tz": "America\/Chicago"
  },
  "0XS9": {
      "icao": "0XS9",
      "iata": "",
      "name": "French Field",
      "city": "Bullard",
      "state": "Texas",
      "country": "US",
      "elevation": 540,
      "lat": 32.134042,
      "lon": -95.339583,
      "tz": "America\/Chicago"
  },
  "10AK": {
      "icao": "10AK",
      "iata": "",
      "name": "Hunt Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 61.5918998718,
      "lon": -149.6779937744,
      "tz": "America\/Anchorage"
  },
  "10AZ": {
      "icao": "10AZ",
      "iata": "",
      "name": "Pierce Airport",
      "city": "Buckeye",
      "state": "Arizona",
      "country": "US",
      "elevation": 860,
      "lat": 33.3664016724,
      "lon": -112.6179962158,
      "tz": "America\/Phoenix"
  },
  "10CL": {
      "icao": "10CL",
      "iata": "",
      "name": "Joe Gottlieb Field",
      "city": "Bakersfield",
      "state": "California",
      "country": "US",
      "elevation": 347,
      "lat": 35.3680000305,
      "lon": -119.1949996948,
      "tz": "America\/Los_Angeles"
  },
  "10CO": {
      "icao": "10CO",
      "iata": "",
      "name": "Silkman Farms Inc. Airport",
      "city": "Burlington",
      "state": "Colorado",
      "country": "US",
      "elevation": 4000,
      "lat": 39.45470047,
      "lon": -102.212997437,
      "tz": "America\/Denver"
  },
  "10FA": {
      "icao": "10FA",
      "iata": "",
      "name": "Flying Baron Estates Airport",
      "city": "Leesburg",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 28.7975006104,
      "lon": -81.9358978271,
      "tz": "America\/New_York"
  },
  "10FD": {
      "icao": "10FD",
      "iata": "",
      "name": "Seven Feathers Airport",
      "city": "Dunnellon",
      "state": "Florida",
      "country": "US",
      "elevation": 68,
      "lat": 29.0065994263,
      "lon": -82.4046020508,
      "tz": "America\/New_York"
  },
  "10GA": {
      "icao": "10GA",
      "iata": "",
      "name": "Beaverbrook Aerodrome",
      "city": "Griffin",
      "state": "Georgia",
      "country": "US",
      "elevation": 910,
      "lat": 33.3250999451,
      "lon": -84.2983016968,
      "tz": "America\/New_York"
  },
  "10IA": {
      "icao": "10IA",
      "iata": "",
      "name": "Flyers Airport",
      "city": "Waterloo",
      "state": "Iowa",
      "country": "US",
      "elevation": 830,
      "lat": 42.4166984558,
      "lon": -92.2501983643,
      "tz": "America\/Chicago"
  },
  "10ID": {
      "icao": "10ID",
      "iata": "",
      "name": "Flat Top Airstrip",
      "city": "Muldoon",
      "state": "Idaho",
      "country": "US",
      "elevation": 5841,
      "lat": 43.4944000244,
      "lon": -113.9219970703,
      "tz": "America\/Boise"
  },
  "10IN": {
      "icao": "10IN",
      "iata": "",
      "name": "Squires Airport",
      "city": "Connersville",
      "state": "Indiana",
      "country": "US",
      "elevation": 1000,
      "lat": 39.7183990479,
      "lon": -85.0488967896,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "10LA": {
      "icao": "10LA",
      "iata": "",
      "name": "Industrial Helicopters Inc. Airport",
      "city": "Scott",
      "state": "Louisiana",
      "country": "US",
      "elevation": 35,
      "lat": 30.2474002838,
      "lon": -92.0960006714,
      "tz": "America\/Chicago"
  },
  "10LS": {
      "icao": "10LS",
      "iata": "",
      "name": "G & J Fly A Way Ultralightport",
      "city": "Youngsville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.0463008881,
      "lon": -91.9945983887,
      "tz": "America\/Chicago"
  },
  "10MO": {
      "icao": "10MO",
      "iata": "",
      "name": "Eckerts Airstrip",
      "city": "Williamsburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 839,
      "lat": 38.9145011902,
      "lon": -91.7042999268,
      "tz": "America\/Chicago"
  },
  "10MT": {
      "icao": "10MT",
      "iata": "",
      "name": "Hoolie Airport",
      "city": "Haxby",
      "state": "Montana",
      "country": "US",
      "elevation": 2526,
      "lat": 47.8333015442,
      "lon": -106.4840011597,
      "tz": "America\/Denver"
  },
  "10NC": {
      "icao": "10NC",
      "iata": "",
      "name": "Johnston Farm Airport",
      "city": "Jackson",
      "state": "North Carolina",
      "country": "US",
      "elevation": 60,
      "lat": 36.3431015015,
      "lon": -77.5093994141,
      "tz": "America\/New_York"
  },
  "10ND": {
      "icao": "10ND",
      "iata": "",
      "name": "Smith Strip",
      "city": "Halliday",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2200,
      "lat": 47.1875,
      "lon": -102.3619995117,
      "tz": "America\/Denver"
  },
  "10OH": {
      "icao": "10OH",
      "iata": "",
      "name": "Zoellner Airport",
      "city": "Huron",
      "state": "Ohio",
      "country": "US",
      "elevation": 617,
      "lat": 41.4028015137,
      "lon": -82.6290969849,
      "tz": "America\/New_York"
  },
  "10OK": {
      "icao": "10OK",
      "iata": "",
      "name": "Hickory Hills Airport",
      "city": "Norman",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1175,
      "lat": 35.1666984558,
      "lon": -97.2170028687,
      "tz": "America\/Chicago"
  },
  "10OR": {
      "icao": "10OR",
      "iata": "",
      "name": "Stevens Mountain Airport",
      "city": "Vernonia",
      "state": "Oregon",
      "country": "US",
      "elevation": 1000,
      "lat": 45.7892990112,
      "lon": -123.2320022583,
      "tz": "America\/Los_Angeles"
  },
  "10TE": {
      "icao": "10TE",
      "iata": "",
      "name": "Gottwald Field",
      "city": "Harwood",
      "state": "Texas",
      "country": "US",
      "elevation": 440,
      "lat": 29.6669006348,
      "lon": -97.5002975464,
      "tz": "America\/Chicago"
  },
  "10TN": {
      "icao": "10TN",
      "iata": "",
      "name": "Flatwood Field",
      "city": "Lebanon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 600,
      "lat": 36.0718994141,
      "lon": -86.3544006348,
      "tz": "America\/Chicago"
  },
  "10TS": {
      "icao": "10TS",
      "iata": "",
      "name": "Saint's Strip Ultralightport",
      "city": "Marion",
      "state": "Texas",
      "country": "US",
      "elevation": 980,
      "lat": 29.5524997711,
      "lon": -98.1031036377,
      "tz": "America\/Chicago"
  },
  "10VA": {
      "icao": "10VA",
      "iata": "",
      "name": "Nashs Airport",
      "city": "Appomattox",
      "state": "Virginia",
      "country": "US",
      "elevation": 785,
      "lat": 37.3862991333,
      "lon": -78.8131027222,
      "tz": "America\/New_York"
  },
  "10WA": {
      "icao": "10WA",
      "iata": "",
      "name": "Mullan Hill Airport",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2310,
      "lat": 47.554599762,
      "lon": -117.4130020142,
      "tz": "America\/Los_Angeles"
  },
  "10WI": {
      "icao": "10WI",
      "iata": "",
      "name": "Buds Landing Airport",
      "city": "Pound",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 45.1157989502,
      "lon": -88.1894989014,
      "tz": "America\/Chicago"
  },
  "10WY": {
      "icao": "10WY",
      "iata": "",
      "name": "Willow Creek Ranch Airport",
      "city": "Kaycee",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5521,
      "lat": 43.423333,
      "lon": -106.823611,
      "tz": "America\/Denver"
  },
  "10XS": {
      "icao": "10XS",
      "iata": "",
      "name": "Flying S Air Ranch Airport",
      "city": "Brownwood",
      "state": "Texas",
      "country": "US",
      "elevation": 1447,
      "lat": 31.779499054,
      "lon": -98.9093017578,
      "tz": "America\/Chicago"
  },
  "11AK": {
      "icao": "11AK",
      "iata": "",
      "name": "Rocking T. Ranch Airport",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1190,
      "lat": 63.9995994568,
      "lon": -145.501998901,
      "tz": "America\/Anchorage"
  },
  "11AZ": {
      "icao": "11AZ",
      "iata": "",
      "name": "Whetstone Airport",
      "city": "Huachuca City",
      "state": "Arizona",
      "country": "US",
      "elevation": 4250,
      "lat": 31.6837005615,
      "lon": -110.2900009155,
      "tz": "America\/Phoenix"
  },
  "11CA": {
      "icao": "11CA",
      "iata": "",
      "name": "Turner Field",
      "city": "Fowler",
      "state": "California",
      "country": "US",
      "elevation": 300,
      "lat": 36.6733016968,
      "lon": -119.7220001221,
      "tz": "America\/Los_Angeles"
  },
  "11CL": {
      "icao": "11CL",
      "iata": "",
      "name": "Hansen Airport",
      "city": "Adelanto",
      "state": "California",
      "country": "US",
      "elevation": 2885,
      "lat": 34.6300010681,
      "lon": -117.6529998779,
      "tz": "America\/Los_Angeles"
  },
  "11FA": {
      "icao": "11FA",
      "iata": "",
      "name": "B & L Farms Airport",
      "city": "Homestead",
      "state": "Florida",
      "country": "US",
      "elevation": 9,
      "lat": 25.5837001801,
      "lon": -80.4998016357,
      "tz": "America\/New_York"
  },
  "11FL": {
      "icao": "11FL",
      "iata": "",
      "name": "Williams Field",
      "city": "Middleburg",
      "state": "Florida",
      "country": "US",
      "elevation": 88,
      "lat": 30.0429992676,
      "lon": -81.880897522,
      "tz": "America\/New_York"
  },
  "11ID": {
      "icao": "11ID",
      "iata": "",
      "name": "Riddle Airport",
      "city": "Riddle",
      "state": "Idaho",
      "country": "US",
      "elevation": 5356,
      "lat": 42.1855010986,
      "lon": -116.1129989624,
      "tz": "America\/Boise"
  },
  "11II": {
      "icao": "11II",
      "iata": "",
      "name": "Atterbury Field (Camp Atterbury)",
      "city": "Nineveh",
      "state": "Indiana",
      "country": "US",
      "elevation": 710,
      "lat": 39.3417015076,
      "lon": -86.0305023193,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "11IN": {
      "icao": "11IN",
      "iata": "",
      "name": "Jr's Airport",
      "city": "Danville",
      "state": "Indiana",
      "country": "US",
      "elevation": 950,
      "lat": 39.818901062,
      "lon": -86.5381011963,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "11KS": {
      "icao": "11KS",
      "iata": "",
      "name": "Sheller's Airport",
      "city": "Tonganoxie",
      "state": "Kansas",
      "country": "US",
      "elevation": 1090,
      "lat": 39.1166992188,
      "lon": -95.1489028931,
      "tz": "America\/Chicago"
  },
  "11LL": {
      "icao": "11LL",
      "iata": "",
      "name": "Thacker Airport",
      "city": "Chenoa",
      "state": "Illinois",
      "country": "US",
      "elevation": 720,
      "lat": 40.7230987549,
      "lon": -88.7630996704,
      "tz": "America\/Chicago"
  },
  "11LS": {
      "icao": "11LS",
      "iata": "",
      "name": "Jasmine Hill Airport",
      "city": "Glenmora",
      "state": "Louisiana",
      "country": "US",
      "elevation": 120,
      "lat": 30.9759998322,
      "lon": -92.5690002441,
      "tz": "America\/Chicago"
  },
  "11MA": {
      "icao": "11MA",
      "iata": "",
      "name": "Bulljump Airport",
      "city": "Wareham",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 60,
      "lat": 41.8073005676,
      "lon": -70.7583999634,
      "tz": "America\/New_York"
  },
  "11MD": {
      "icao": "11MD",
      "iata": "",
      "name": "Tim's Airport",
      "city": "Salisbury",
      "state": "Maryland",
      "country": "US",
      "elevation": 42,
      "lat": 38.4333000183,
      "lon": -75.6277999878,
      "tz": "America\/New_York"
  },
  "11MO": {
      "icao": "11MO",
      "iata": "",
      "name": "Redgate Ranch Airport",
      "city": "Williamsburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 38.9016990662,
      "lon": -91.6352005005,
      "tz": "America\/Chicago"
  },
  "11NE": {
      "icao": "11NE",
      "iata": "",
      "name": "Kaan Airport",
      "city": "Harrison",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4650,
      "lat": 42.4425010681,
      "lon": -103.9160003662,
      "tz": "America\/Denver"
  },
  "11NK": {
      "icao": "11NK",
      "iata": "",
      "name": "Hop House Airpark",
      "city": "Fort Plain",
      "state": "New York",
      "country": "US",
      "elevation": 530,
      "lat": 42.913898468,
      "lon": -74.6117019653,
      "tz": "America\/New_York"
  },
  "11NY": {
      "icao": "11NY",
      "iata": "",
      "name": "Saikkonen Airport",
      "city": "Spencer",
      "state": "New York",
      "country": "US",
      "elevation": 969,
      "lat": 42.2089996338,
      "lon": -76.4496994019,
      "tz": "America\/New_York"
  },
  "11OA": {
      "icao": "11OA",
      "iata": "",
      "name": "Hide-A-Way Hills Resort Airport",
      "city": "Bremen",
      "state": "Ohio",
      "country": "US",
      "elevation": 780,
      "lat": 39.6431007385,
      "lon": -82.466796875,
      "tz": "America\/New_York"
  },
  "11OG": {
      "icao": "11OG",
      "iata": "",
      "name": "Unity Airport",
      "city": "Unity",
      "state": "Oregon",
      "country": "US",
      "elevation": 3975,
      "lat": 44.4513015747,
      "lon": -118.18699646,
      "tz": "America\/Los_Angeles"
  },
  "11OK": {
      "icao": "11OK",
      "iata": "",
      "name": "Beefor Ranch Airport",
      "city": "Purcell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1150,
      "lat": 35.0722999573,
      "lon": -97.3835983276,
      "tz": "America\/Chicago"
  },
  "11OR": {
      "icao": "11OR",
      "iata": "",
      "name": "Holiday Sky Ranch Airport",
      "city": "Sutherlin",
      "state": "Oregon",
      "country": "US",
      "elevation": 510,
      "lat": 43.3935012817,
      "lon": -123.2850036621,
      "tz": "America\/Los_Angeles"
  },
  "11PN": {
      "icao": "11PN",
      "iata": "",
      "name": "Eakin Airport",
      "city": "Cranberry",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1489,
      "lat": 41.3525,
      "lon": -79.732778,
      "tz": "America\/New_York"
  },
  "11TE": {
      "icao": "11TE",
      "iata": "",
      "name": "Flying M Ranch Airport",
      "city": "Grandview",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.3000984192,
      "lon": -97.204498291,
      "tz": "America\/Chicago"
  },
  "11TN": {
      "icao": "11TN",
      "iata": "",
      "name": "Salem Field",
      "city": "Clarksville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 425,
      "lat": 36.4749984741,
      "lon": -87.3666992188,
      "tz": "America\/Chicago"
  },
  "11TS": {
      "icao": "11TS",
      "iata": "",
      "name": "Pt Enterprise D&W Ranch Airport",
      "city": "Mexia",
      "state": "Texas",
      "country": "US",
      "elevation": 526,
      "lat": 31.6578998566,
      "lon": -96.4113998413,
      "tz": "America\/Chicago"
  },
  "11TX": {
      "icao": "11TX",
      "iata": "",
      "name": "Butler Airport",
      "city": "Sherman",
      "state": "Texas",
      "country": "US",
      "elevation": 840,
      "lat": 33.6668014526,
      "lon": -96.6905975342,
      "tz": "America\/Chicago"
  },
  "11VG": {
      "icao": "11VG",
      "iata": "",
      "name": "Devil's Reach Landing Airport",
      "city": "Oak Grove",
      "state": "Virginia",
      "country": "US",
      "elevation": 28,
      "lat": 38.209400177,
      "lon": -77.0006027222,
      "tz": "America\/New_York"
  },
  "11WA": {
      "icao": "11WA",
      "iata": "",
      "name": "Homeport Airport",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2320,
      "lat": 47.5237998962,
      "lon": -117.4700012207,
      "tz": "America\/Los_Angeles"
  },
  "12AR": {
      "icao": "12AR",
      "iata": "",
      "name": "Gibbons Airport",
      "city": "Marvell",
      "state": "Arkansas",
      "country": "US",
      "elevation": 178,
      "lat": 34.515444,
      "lon": -90.973722,
      "tz": "America\/Chicago"
  },
  "12AZ": {
      "icao": "12AZ",
      "iata": "",
      "name": "Ranta Strip",
      "city": "Morristown",
      "state": "Arizona",
      "country": "US",
      "elevation": 1840,
      "lat": 33.8539009094,
      "lon": -112.6529998779,
      "tz": "America\/Phoenix"
  },
  "12CA": {
      "icao": "12CA",
      "iata": "",
      "name": "Faber Vineyards Airport",
      "city": "Lodi",
      "state": "California",
      "country": "US",
      "elevation": 70,
      "lat": 38.1096000671,
      "lon": -121.1689987183,
      "tz": "America\/Los_Angeles"
  },
  "12CL": {
      "icao": "12CL",
      "iata": "",
      "name": "Newton Field",
      "city": "Stratford",
      "state": "California",
      "country": "US",
      "elevation": 194,
      "lat": 36.1568984985,
      "lon": -119.8499984741,
      "tz": "America\/Los_Angeles"
  },
  "12CO": {
      "icao": "12CO",
      "iata": "",
      "name": "Omega 1 STOLport",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 6409,
      "lat": 38.8354988098,
      "lon": -107.8150024414,
      "tz": "America\/Denver"
  },
  "12FA": {
      "icao": "12FA",
      "iata": "",
      "name": "Hi-Acres Airport",
      "city": "Clermont",
      "state": "Florida",
      "country": "US",
      "elevation": 105,
      "lat": 28.4785995483,
      "lon": -81.7145004272,
      "tz": "America\/New_York"
  },
  "12IA": {
      "icao": "12IA",
      "iata": "",
      "name": "Plueger Airfield",
      "city": "Lemars",
      "state": "Iowa",
      "country": "US",
      "elevation": 1245,
      "lat": 42.8004989624,
      "lon": -96.137802124,
      "tz": "America\/Chicago"
  },
  "12ID": {
      "icao": "12ID",
      "iata": "",
      "name": "Flying B Ranch Landing Strip",
      "city": "Salmon",
      "state": "Idaho",
      "country": "US",
      "elevation": 3647,
      "lat": 44.9679985046,
      "lon": -114.733001709,
      "tz": "America\/Boise"
  },
  "12II": {
      "icao": "12II",
      "iata": "",
      "name": "Pentecost Airport",
      "city": "Centerville",
      "state": "Indiana",
      "country": "US",
      "elevation": 1020,
      "lat": 39.8562011719,
      "lon": -84.9721984863,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "12IL": {
      "icao": "12IL",
      "iata": "",
      "name": "Hawker Airport",
      "city": "Kankakee",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 41.2000007629,
      "lon": -88.0167007446,
      "tz": "America\/Chicago"
  },
  "12IN": {
      "icao": "12IN",
      "iata": "",
      "name": "J & S Field",
      "city": "Darmstadt",
      "state": "Indiana",
      "country": "US",
      "elevation": 465,
      "lat": 38.118598938,
      "lon": -87.6474990845,
      "tz": "America\/Chicago"
  },
  "12KS": {
      "icao": "12KS",
      "iata": "",
      "name": "Sooter Airport",
      "city": "Sedgwick",
      "state": "Kansas",
      "country": "US",
      "elevation": 1420,
      "lat": 37.9352989197,
      "lon": -97.4878005981,
      "tz": "America\/Chicago"
  },
  "12KY": {
      "icao": "12KY",
      "iata": "",
      "name": "John M. Foree Airport",
      "city": "New Castle",
      "state": "Kentucky",
      "country": "US",
      "elevation": 853,
      "lat": 38.4603004456,
      "lon": -85.1686019897,
      "tz": "America\/New_York"
  },
  "12LA": {
      "icao": "12LA",
      "iata": "",
      "name": "Kml Airport",
      "city": "Sulphur",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.2758998871,
      "lon": -93.3573989868,
      "tz": "America\/Chicago"
  },
  "12LL": {
      "icao": "12LL",
      "iata": "",
      "name": "Lambdins Field",
      "city": "Wolf Lake",
      "state": "Illinois",
      "country": "US",
      "elevation": 350,
      "lat": 37.5402984619,
      "lon": -89.4861984253,
      "tz": "America\/Chicago"
  },
  "12LS": {
      "icao": "12LS",
      "iata": "",
      "name": "Hains Airstrip",
      "city": "Rayne",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.1786003113,
      "lon": -92.2850036621,
      "tz": "America\/Chicago"
  },
  "12ME": {
      "icao": "12ME",
      "iata": "",
      "name": "Ridgeview Airport",
      "city": "Searsmont",
      "state": "Maine",
      "country": "US",
      "elevation": 240,
      "lat": 44.3222999573,
      "lon": -69.2072982788,
      "tz": "America\/New_York"
  },
  "12MI": {
      "icao": "12MI",
      "iata": "",
      "name": "John's Airport",
      "city": "Davison",
      "state": "Michigan",
      "country": "US",
      "elevation": 820,
      "lat": 43.051700592,
      "lon": -83.491897583,
      "tz": "America\/Detroit"
  },
  "12MO": {
      "icao": "12MO",
      "iata": "",
      "name": "Ferguson Farms Airport",
      "city": "Windsor",
      "state": "Missouri",
      "country": "US",
      "elevation": 990,
      "lat": 38.5806007385,
      "lon": -93.5947036743,
      "tz": "America\/Chicago"
  },
  "12MS": {
      "icao": "12MS",
      "iata": "",
      "name": "Green Acres Airport",
      "city": "Woodville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 300,
      "lat": 31.0930995941,
      "lon": -91.3190002441,
      "tz": "America\/Chicago"
  },
  "12MT": {
      "icao": "12MT",
      "iata": "",
      "name": "Lincolns Field",
      "city": "Hingham",
      "state": "Montana",
      "country": "US",
      "elevation": 2931,
      "lat": 48.7742004395,
      "lon": -110.4420013428,
      "tz": "America\/Denver"
  },
  "12NC": {
      "icao": "12NC",
      "iata": "",
      "name": "Atlantic Field Mcolf Airport",
      "city": "Atlantic",
      "state": "North Carolina",
      "country": "US",
      "elevation": 20,
      "lat": 34.8833007812,
      "lon": -76.3499984741,
      "tz": "America\/New_York"
  },
  "12ND": {
      "icao": "12ND",
      "iata": "",
      "name": "Pietschtree Airstrip",
      "city": "Burlington",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1566,
      "lat": 48.29529953,
      "lon": -101.4270019531,
      "tz": "America\/Chicago"
  },
  "12NE": {
      "icao": "12NE",
      "iata": "",
      "name": "Reisig Brothers Airport",
      "city": "Scottsbluff",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3985,
      "lat": 41.9066009521,
      "lon": -103.6999969482,
      "tz": "America\/Denver"
  },
  "12NH": {
      "icao": "12NH",
      "iata": "",
      "name": "Gile Pond Airport",
      "city": "Sanbornton",
      "state": "New Hampshire",
      "country": "US",
      "elevation": 485,
      "lat": 43.495278,
      "lon": -71.654444,
      "tz": "America\/New_York"
  },
  "12NK": {
      "icao": "12NK",
      "iata": "",
      "name": "Westport Airport",
      "city": "Westport",
      "state": "New York",
      "country": "US",
      "elevation": 276,
      "lat": 44.1584014893,
      "lon": -73.4328994751,
      "tz": "America\/New_York"
  },
  "12NM": {
      "icao": "12NM",
      "iata": "",
      "name": "Columbus Stockyards Airport",
      "city": "Columbus",
      "state": "New Mexico",
      "country": "US",
      "elevation": 4017,
      "lat": 31.7917995453,
      "lon": -107.6380004883,
      "tz": "America\/Denver"
  },
  "12OH": {
      "icao": "12OH",
      "iata": "",
      "name": "Mcgregor Airfield",
      "city": "Brookville",
      "state": "Ohio",
      "country": "US",
      "elevation": 940,
      "lat": 39.8348007202,
      "lon": -84.3347015381,
      "tz": "America\/New_York"
  },
  "12OK": {
      "icao": "12OK",
      "iata": "",
      "name": "Expressway Airpark",
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1070,
      "lat": 35.5413017273,
      "lon": -97.4531021118,
      "tz": "America\/Chicago"
  },
  "12OR": {
      "icao": "12OR",
      "iata": "",
      "name": "Skinner Ranch Airport",
      "city": "Jordan Valley",
      "state": "Oregon",
      "country": "US",
      "elevation": 4273,
      "lat": 42.9513015747,
      "lon": -117.2819976807,
      "tz": "America\/Boise"
  },
  "12SC": {
      "icao": "12SC",
      "iata": "",
      "name": "Over the Hill Airport",
      "city": "Elgin",
      "state": "South Carolina",
      "country": "US",
      "elevation": 250,
      "lat": 34.1413993835,
      "lon": -80.7466964722,
      "tz": "America\/New_York"
  },
  "12TE": {
      "icao": "12TE",
      "iata": "",
      "name": "Curtis Ranch Field",
      "city": "Brady",
      "state": "Texas",
      "country": "US",
      "elevation": 1740,
      "lat": 31.3167991638,
      "lon": -99.2337036133,
      "tz": "America\/Chicago"
  },
  "12TN": {
      "icao": "12TN",
      "iata": "",
      "name": "Riley Creek Airport",
      "city": "Kingston",
      "state": "Tennessee",
      "country": "US",
      "elevation": 750,
      "lat": 35.8236999512,
      "lon": -84.5388031006,
      "tz": "America\/New_York"
  },
  "12TS": {
      "icao": "12TS",
      "iata": "",
      "name": "Blo Airport",
      "city": "Mineral Wells",
      "state": "Texas",
      "country": "US",
      "elevation": 1050,
      "lat": 32.9085006714,
      "lon": -97.9773025513,
      "tz": "America\/Chicago"
  },
  "12TX": {
      "icao": "12TX",
      "iata": "",
      "name": "Griffin Airport",
      "city": "Bertram",
      "state": "Texas",
      "country": "US",
      "elevation": 1235,
      "lat": 30.8213996887,
      "lon": -98.0805969238,
      "tz": "America\/Chicago"
  },
  "12VA": {
      "icao": "12VA",
      "iata": "",
      "name": "Hop-Along Airport",
      "city": "Clifton Forge",
      "state": "Virginia",
      "country": "US",
      "elevation": 1060,
      "lat": 37.7881011963,
      "lon": -79.7677993774,
      "tz": "America\/New_York"
  },
  "12WA": {
      "icao": "12WA",
      "iata": "",
      "name": "Fowlers Nw 40 Airport",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2313,
      "lat": 47.5206985474,
      "lon": -117.4629974365,
      "tz": "America\/Los_Angeles"
  },
  "12WT": {
      "icao": "12WT",
      "iata": "",
      "name": "Walker Airport",
      "city": "Palouse",
      "state": "Washington",
      "country": "US",
      "elevation": 2450,
      "lat": 46.911,
      "lon": -117.218667,
      "tz": "America\/Los_Angeles"
  },
  "12WY": {
      "icao": "12WY",
      "iata": "",
      "name": "Black Diamond Airport",
      "city": "Burlington",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4400,
      "lat": 44.45,
      "lon": -108.384667,
      "tz": "America\/Denver"
  },
  "12XS": {
      "icao": "12XS",
      "iata": "",
      "name": "Mc Croskey Field",
      "city": "Butlerville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 253,
      "lat": 34.95470047,
      "lon": -91.8371963501,
      "tz": "America\/Chicago"
  },
  "13AK": {
      "icao": "13AK",
      "iata": "",
      "name": "Satterbergs Airport",
      "city": "Houston",
      "state": "Alaska",
      "country": "US",
      "elevation": 350,
      "lat": 61.6547012329,
      "lon": -149.8999938965,
      "tz": "America\/Anchorage"
  },
  "13AZ": {
      "icao": "13AZ",
      "iata": "",
      "name": "Waltenberry Field Ultralightport",
      "city": "Tonopah",
      "state": "Arizona",
      "country": "US",
      "elevation": 1213,
      "lat": 33.535,
      "lon": -112.853472222,
      "tz": "America\/Phoenix"
  },
  "13CL": {
      "icao": "13CL",
      "iata": "",
      "name": "Maine Prairie Airport",
      "city": "Dixon",
      "state": "California",
      "country": "US",
      "elevation": 40,
      "lat": 38.3813018799,
      "lon": -121.8239974976,
      "tz": "America\/Los_Angeles"
  },
  "13FA": {
      "icao": "13FA",
      "iata": "",
      "name": "Earle Airpark",
      "city": "Hastings",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 29.6944007874,
      "lon": -81.5137023926,
      "tz": "America\/New_York"
  },
  "13GA": {
      "icao": "13GA",
      "iata": "",
      "name": "Oak Ridge Plantation Airport",
      "city": "Sylvester",
      "state": "Georgia",
      "country": "US",
      "elevation": 350,
      "lat": 31.3666000366,
      "lon": -83.7548980713,
      "tz": "America\/New_York"
  },
  "13GE": {
      "icao": "13GE",
      "iata": "",
      "name": "Holt Airpark",
      "city": "Oak Park",
      "state": "Georgia",
      "country": "US",
      "elevation": 280,
      "lat": 32.3608016968,
      "lon": -82.3180999756,
      "tz": "America\/New_York"
  },
  "13IA": {
      "icao": "13IA",
      "iata": "",
      "name": "Hillside Stables Airport",
      "city": "Sabula",
      "state": "Iowa",
      "country": "US",
      "elevation": 760,
      "lat": 42.0457000732,
      "lon": -90.1911010742,
      "tz": "America\/Chicago"
  },
  "13ID": {
      "icao": "13ID",
      "iata": "",
      "name": "Silva Ranch Airport",
      "city": "Stanley",
      "state": "Idaho",
      "country": "US",
      "elevation": 6400,
      "lat": 44.2666015625,
      "lon": -115.0009994507,
      "tz": "America\/Boise"
  },
  "13IN": {
      "icao": "13IN",
      "iata": "",
      "name": "Gage Airport",
      "city": "Decatur",
      "state": "Indiana",
      "country": "US",
      "elevation": 814,
      "lat": 40.8152999878,
      "lon": -84.927696228,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "13KS": {
      "icao": "13KS",
      "iata": "",
      "name": "Daniel's Landing Airport",
      "city": "Andover",
      "state": "Kansas",
      "country": "US",
      "elevation": 1325,
      "lat": 37.6977996826,
      "lon": -97.1102981567,
      "tz": "America\/Chicago"
  },
  "13KY": {
      "icao": "13KY",
      "iata": "",
      "name": "Miles Airport",
      "city": "Owensboro",
      "state": "Kentucky",
      "country": "US",
      "elevation": 407,
      "lat": 37.7056007385,
      "lon": -87.1560974121,
      "tz": "America\/Chicago"
  },
  "13LS": {
      "icao": "13LS",
      "iata": "",
      "name": "Tee Brad's Airport",
      "city": "Kaplan",
      "state": "Louisiana",
      "country": "US",
      "elevation": 12,
      "lat": 29.9939002991,
      "lon": -92.385597229,
      "tz": "America\/Chicago"
  },
  "13MA": {
      "icao": "13MA",
      "iata": "",
      "name": "Metropolitan Airport",
      "city": "Palmer",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 418,
      "lat": 42.2233009338,
      "lon": -72.3114013672,
      "tz": "America\/New_York"
  },
  "13ME": {
      "icao": "13ME",
      "iata": "",
      "name": "Socatean Bay Airport",
      "city": "Rockwood",
      "state": "Maine",
      "country": "US",
      "elevation": 1179,
      "lat": 45.7764015198,
      "lon": -69.8093032837,
      "tz": "America\/New_York"
  },
  "13MN": {
      "icao": "13MN",
      "iata": "",
      "name": "Melby Airport",
      "city": "Ashby",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1250,
      "lat": 46.1007995605,
      "lon": -95.8313980103,
      "tz": "America\/Chicago"
  },
  "13MO": {
      "icao": "13MO",
      "iata": "",
      "name": "Domeyer Airport",
      "city": "Lenox",
      "state": "Missouri",
      "country": "US",
      "elevation": 1260,
      "lat": 37.676399231,
      "lon": -91.7064971924,
      "tz": "America\/Chicago"
  },
  "13MS": {
      "icao": "13MS",
      "iata": "",
      "name": "Greener Pastures Airpark",
      "city": "Carriere",
      "state": "Mississippi",
      "country": "US",
      "elevation": 180,
      "lat": 30.6333007813,
      "lon": -89.6639022827,
      "tz": "America\/Chicago"
  },
  "13MT": {
      "icao": "13MT",
      "iata": "",
      "name": "Sorenson Airport",
      "city": "Kremlin",
      "state": "Montana",
      "country": "US",
      "elevation": 2873,
      "lat": 48.5208015442,
      "lon": -110.1129989624,
      "tz": "America\/Denver"
  },
  "13NC": {
      "icao": "13NC",
      "iata": "",
      "name": "Oak Grove Mcolf Airport",
      "city": "Pollocksville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 27,
      "lat": 35.0335006714,
      "lon": -77.2497024536,
      "tz": "America\/New_York"
  },
  "13NE": {
      "icao": "13NE",
      "iata": "",
      "name": "Hock Airport",
      "city": "Culbertson",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2777,
      "lat": 40.3166999817,
      "lon": -100.8089981079,
      "tz": "America\/Chicago"
  },
  "13NM": {
      "icao": "13NM",
      "iata": "",
      "name": "Beaverhead Airstrip",
      "city": "Silver City",
      "state": "New Mexico",
      "country": "US",
      "elevation": 7378,
      "lat": 33.4194984436,
      "lon": -108.138999939,
      "tz": "America\/Denver"
  },
  "13OH": {
      "icao": "13OH",
      "iata": "",
      "name": "Rose Field",
      "city": "Jewell",
      "state": "Ohio",
      "country": "US",
      "elevation": 728,
      "lat": 41.3302993774,
      "lon": -84.2983016968,
      "tz": "America\/New_York"
  },
  "13OR": {
      "icao": "13OR",
      "iata": "",
      "name": "Aubrey Mountain Airstrip",
      "city": "Oakridge",
      "state": "Oregon",
      "country": "US",
      "elevation": 1680,
      "lat": 43.7406997681,
      "lon": -122.4260025024,
      "tz": "America\/Los_Angeles"
  },
  "13PA": {
      "icao": "13PA",
      "iata": "",
      "name": "Rigrtona Airport",
      "city": "Tidioute",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1703,
      "lat": 41.682598114,
      "lon": -79.452003479,
      "tz": "America\/New_York"
  },
  "13PN": {
      "icao": "13PN",
      "iata": "",
      "name": "Richland Acres Ultralightport",
      "city": "Quakertown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 40.4542999268,
      "lon": -75.3046035767,
      "tz": "America\/New_York"
  },
  "13TE": {
      "icao": "13TE",
      "iata": "",
      "name": "Varisco Airport",
      "city": "Bryan",
      "state": "Texas",
      "country": "US",
      "elevation": 240,
      "lat": 30.6560001373,
      "lon": -96.5382995605,
      "tz": "America\/Chicago"
  },
  "13TS": {
      "icao": "13TS",
      "iata": "",
      "name": "Van Es Ranch Airport",
      "city": "Oilton",
      "state": "Texas",
      "country": "US",
      "elevation": 830,
      "lat": 27.4552993774,
      "lon": -98.926399231,
      "tz": "America\/Chicago"
  },
  "13TX": {
      "icao": "13TX",
      "iata": "",
      "name": "Allison Ranch Airport",
      "city": "Sonora",
      "state": "Texas",
      "country": "US",
      "elevation": 2350,
      "lat": 30.5312995911,
      "lon": -100.4509963989,
      "tz": "America\/Chicago"
  },
  "13WI": {
      "icao": "13WI",
      "iata": "",
      "name": "Nowatzski Field",
      "city": "Markesan",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 920,
      "lat": 43.6963996887,
      "lon": -88.9996032715,
      "tz": "America\/Chicago"
  },
  "14AK": {
      "icao": "14AK",
      "iata": "",
      "name": "Four Corners Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.6015014648,
      "lon": -149.2480010986,
      "tz": "America\/Anchorage"
  },
  "14AR": {
      "icao": "14AR",
      "iata": "",
      "name": "Rice-Bell Field Airport",
      "city": "Forrest City",
      "state": "Arkansas",
      "country": "US",
      "elevation": 221,
      "lat": 35.0527992249,
      "lon": -90.6750030518,
      "tz": "America\/Chicago"
  },
  "14AZ": {
      "icao": "14AZ",
      "iata": "",
      "name": "Ruby Star Airpark",
      "city": "Green Valley",
      "state": "Arizona",
      "country": "US",
      "elevation": 4025,
      "lat": 31.9083003998,
      "lon": -111.1220016479,
      "tz": "America\/Phoenix"
  },
  "14CO": {
      "icao": "14CO",
      "iata": "",
      "name": "Good Pasture Airport",
      "city": "Beulah",
      "state": "Colorado",
      "country": "US",
      "elevation": 6000,
      "lat": 38.0971984863,
      "lon": -104.9100036621,
      "tz": "America\/Denver"
  },
  "14FA": {
      "icao": "14FA",
      "iata": "",
      "name": "Burrs Strip",
      "city": "Miami",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 25.5701007843,
      "lon": -80.3998031616,
      "tz": "America\/New_York"
  },
  "14FD": {
      "icao": "14FD",
      "iata": "",
      "name": "Sunshine Farms Airport",
      "city": "St Augustine",
      "state": "Florida",
      "country": "US",
      "elevation": 23,
      "lat": 29.9491004944,
      "lon": -81.4904022217,
      "tz": "America\/New_York"
  },
  "14FL": {
      "icao": "14FL",
      "iata": "",
      "name": "Wells Flying Service Airport",
      "city": "Jay",
      "state": "Florida",
      "country": "US",
      "elevation": 240,
      "lat": 30.8654994965,
      "lon": -87.177696228,
      "tz": "America\/Chicago"
  },
  "14ID": {
      "icao": "14ID",
      "iata": "",
      "name": "Peaceful Cove Airport",
      "city": "Boise",
      "state": "Idaho",
      "country": "US",
      "elevation": 2920,
      "lat": 43.6809997559,
      "lon": -116.2030029297,
      "tz": "America\/Boise"
  },
  "14IN": {
      "icao": "14IN",
      "iata": "",
      "name": "De Motte Airport",
      "city": "De Motte",
      "state": "Indiana",
      "country": "US",
      "elevation": 655,
      "lat": 41.2070007324,
      "lon": -87.2125015259,
      "tz": "America\/Chicago"
  },
  "14KS": {
      "icao": "14KS",
      "iata": "",
      "name": "G & S Space Port Airport",
      "city": "Parker",
      "state": "Kansas",
      "country": "US",
      "elevation": 940,
      "lat": 38.2691993713,
      "lon": -94.904800415,
      "tz": "America\/Chicago"
  },
  "14KY": {
      "icao": "14KY",
      "iata": "",
      "name": "Blue Haven Farm Airport",
      "city": "Paris",
      "state": "Kentucky",
      "country": "US",
      "elevation": 985,
      "lat": 38.1736984253,
      "lon": -84.3683013916,
      "tz": "America\/New_York"
  },
  "14LA": {
      "icao": "14LA",
      "iata": "",
      "name": "Killarny Farm Airport",
      "city": "St Francisville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 53,
      "lat": 30.8409996033,
      "lon": -91.5082015991,
      "tz": "America\/Chicago"
  },
  "14ME": {
      "icao": "14ME",
      "iata": "",
      "name": "Ring Hill Airport",
      "city": "Carmel",
      "state": "Maine",
      "country": "US",
      "elevation": 340,
      "lat": 44.7925987244,
      "lon": -69.0702972412,
      "tz": "America\/New_York"
  },
  "14MI": {
      "icao": "14MI",
      "iata": "",
      "name": "East-West Paris Airport",
      "city": "Dutton",
      "state": "Michigan",
      "country": "US",
      "elevation": 750,
      "lat": 42.8508987427,
      "lon": -85.5670013428,
      "tz": "America\/Detroit"
  },
  "14MO": {
      "icao": "14MO",
      "iata": "",
      "name": "Stevinson Farm Airport",
      "city": "Blue Springs",
      "state": "Missouri",
      "country": "US",
      "elevation": 900,
      "lat": 39.0499992371,
      "lon": -94.3169021606,
      "tz": "America\/Chicago"
  },
  "14MS": {
      "icao": "14MS",
      "iata": "",
      "name": "Rose Field",
      "city": "Hickory",
      "state": "Mississippi",
      "country": "US",
      "elevation": 400,
      "lat": 32.3452987671,
      "lon": -89.0342025757,
      "tz": "America\/Chicago"
  },
  "14MT": {
      "icao": "14MT",
      "iata": "",
      "name": "Holland Ranch Airport",
      "city": "Lewistown",
      "state": "Montana",
      "country": "US",
      "elevation": 4050,
      "lat": 47.0516014099,
      "lon": -109.0749969482,
      "tz": "America\/Denver"
  },
  "14NC": {
      "icao": "14NC",
      "iata": "",
      "name": "Camp Davis Mcolf Airport",
      "city": "Holly Ridge",
      "state": "North Carolina",
      "country": "US",
      "elevation": 60,
      "lat": 34.5167007446,
      "lon": -77.5500030518,
      "tz": "America\/New_York"
  },
  "14ND": {
      "icao": "14ND",
      "iata": "",
      "name": "Hagen Private Airport",
      "city": "Reeder",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2810,
      "lat": 46.2078018188,
      "lon": -102.9079971313,
      "tz": "America\/Denver"
  },
  "14NE": {
      "icao": "14NE",
      "iata": "",
      "name": "Aaron's Field",
      "city": "Aurora",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1825,
      "lat": 40.8624992371,
      "lon": -98.064201355,
      "tz": "America\/Chicago"
  },
  "14NK": {
      "icao": "14NK",
      "iata": "",
      "name": "Mountain View Airport",
      "city": "Kingsbury",
      "state": "New York",
      "country": "US",
      "elevation": 243,
      "lat": 43.3400001526,
      "lon": -73.4789962769,
      "tz": "America\/New_York"
  },
  "14OH": {
      "icao": "14OH",
      "iata": "",
      "name": "Heilman Airport",
      "city": "Kenton",
      "state": "Ohio",
      "country": "US",
      "elevation": 930,
      "lat": 40.6688995361,
      "lon": -83.4434967041,
      "tz": "America\/New_York"
  },
  "14OI": {
      "icao": "14OI",
      "iata": "",
      "name": "Pleasant Home Gliding Club Gliderport",
      "city": "Pleasant Home",
      "state": "Ohio",
      "country": "US",
      "elevation": 1050,
      "lat": 40.9192008972,
      "lon": -82.1139984131,
      "tz": "America\/New_York"
  },
  "14OK": {
      "icao": "14OK",
      "iata": "",
      "name": "Reherman Airport",
      "city": "Kingfisher",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1105,
      "lat": 35.8041992188,
      "lon": -97.9337005615,
      "tz": "America\/Chicago"
  },
  "14PA": {
      "icao": "14PA",
      "iata": "",
      "name": "Dimascio Field",
      "city": "Pottstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 350,
      "lat": 40.2909011841,
      "lon": -75.6591033936,
      "tz": "America\/New_York"
  },
  "14PS": {
      "icao": "14PS",
      "iata": "",
      "name": "Hideaway Ultralightport",
      "city": "Quarryville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 630,
      "lat": 39.8843994141,
      "lon": -76.118598938,
      "tz": "America\/New_York"
  },
  "14TA": {
      "icao": "14TA",
      "iata": "",
      "name": "Dean Ranch Airport",
      "city": "Startzville",
      "state": "Texas",
      "country": "US",
      "elevation": 1180,
      "lat": 29.8985996246,
      "lon": -98.4030990601,
      "tz": "America\/Chicago"
  },
  "14TE": {
      "icao": "14TE",
      "iata": "",
      "name": "Smith I-Ranch Airport",
      "city": "Hext",
      "state": "Texas",
      "country": "US",
      "elevation": 1793,
      "lat": 30.9167995453,
      "lon": -99.4837036133,
      "tz": "America\/Chicago"
  },
  "14TS": {
      "icao": "14TS",
      "iata": "",
      "name": "O S Wyatt Airport",
      "city": "Realitos",
      "state": "Texas",
      "country": "US",
      "elevation": 532,
      "lat": 27.4216995239,
      "lon": -98.6044998169,
      "tz": "America\/Chicago"
  },
  "14TX": {
      "icao": "14TX",
      "iata": "",
      "name": "Keyes Ranch Airport",
      "city": "Dripping Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 1150,
      "lat": 30.2894001007,
      "lon": -98.1738967896,
      "tz": "America\/Chicago"
  },
  "14VA": {
      "icao": "14VA",
      "iata": "",
      "name": "Krens Farm Airport",
      "city": "Hillsboro",
      "state": "Virginia",
      "country": "US",
      "elevation": 790,
      "lat": 39.2290000916,
      "lon": -77.7466964722,
      "tz": "America\/New_York"
  },
  "14WA": {
      "icao": "14WA",
      "iata": "",
      "name": "Lz Ranch Airport",
      "city": "Olympia",
      "state": "Washington",
      "country": "US",
      "elevation": 240,
      "lat": 46.9706993103,
      "lon": -122.7369995117,
      "tz": "America\/Los_Angeles"
  },
  "14WS": {
      "icao": "14WS",
      "iata": "",
      "name": "Lakewood Lodge Airport",
      "city": "Stone Lake",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1365,
      "lat": 45.7779998779,
      "lon": -91.5585021973,
      "tz": "America\/Chicago"
  },
  "14XA": {
      "icao": "14XA",
      "iata": "",
      "name": "Sherman (Frog Pond) Airport",
      "city": "Sherman",
      "state": "Texas",
      "country": "US",
      "elevation": 686,
      "lat": 33.6578884021,
      "lon": -96.7882847786,
      "tz": "America\/Chicago"
  },
  "14XS": {
      "icao": "14XS",
      "iata": "",
      "name": "Isbell Ranch Airport",
      "city": "Davilla",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 30.8085002899,
      "lon": -97.2739028931,
      "tz": "America\/Chicago"
  },
  "15AK": {
      "icao": "15AK",
      "iata": "",
      "name": "Golden North Airfield",
      "city": "Cantwell",
      "state": "Alaska",
      "country": "US",
      "elevation": 2250,
      "lat": 63.3707008362,
      "lon": -148.8500061035,
      "tz": "America\/Anchorage"
  },
  "15AR": {
      "icao": "15AR",
      "iata": "",
      "name": "Dawson's Airport",
      "city": "Brickeys",
      "state": "Arkansas",
      "country": "US",
      "elevation": 220,
      "lat": 34.8259010315,
      "lon": -90.543296814,
      "tz": "America\/Chicago"
  },
  "15AZ": {
      "icao": "15AZ",
      "iata": "",
      "name": "Quail Mesa Ranch Airport",
      "city": "Quartzsite",
      "state": "Arizona",
      "country": "US",
      "elevation": 290,
      "lat": 33.7444992065,
      "lon": -114.476997375,
      "tz": "America\/Phoenix"
  },
  "15CL": {
      "icao": "15CL",
      "iata": "",
      "name": "Hunt Farms Airport",
      "city": "Merced",
      "state": "California",
      "country": "US",
      "elevation": 223,
      "lat": 37.3427009583,
      "lon": -120.4039993286,
      "tz": "America\/Los_Angeles"
  },
  "15FD": {
      "icao": "15FD",
      "iata": "",
      "name": "Orange Hill Airport",
      "city": "Wausau",
      "state": "Florida",
      "country": "US",
      "elevation": 260,
      "lat": 30.6585006714,
      "lon": -85.5298995972,
      "tz": "America\/Chicago"
  },
  "15FL": {
      "icao": "15FL",
      "iata": "",
      "name": "Cannon Creek Airpark",
      "city": "Lake City",
      "state": "Florida",
      "country": "US",
      "elevation": 125,
      "lat": 30.1536998749,
      "lon": -82.6663970947,
      "tz": "America\/New_York"
  },
  "15GA": {
      "icao": "15GA",
      "iata": "",
      "name": "Darla's Airport",
      "city": "Kite",
      "state": "Georgia",
      "country": "US",
      "elevation": 328,
      "lat": 32.6801986694,
      "lon": -82.4789962769,
      "tz": "America\/New_York"
  },
  "15ID": {
      "icao": "15ID",
      "iata": "",
      "name": "Scanlon Airport",
      "city": "Coeur D Alene",
      "state": "Washington",
      "country": "US",
      "elevation": 2000,
      "lat": 47.6856002808,
      "lon": -117.0380020142,
      "tz": "America\/Los_Angeles"
  },
  "15IL": {
      "icao": "15IL",
      "iata": "",
      "name": "Gittleson Farms Airport",
      "city": "Franklin Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 836,
      "lat": 41.7972984314,
      "lon": -89.2732009888,
      "tz": "America\/Chicago"
  },
  "15IN": {
      "icao": "15IN",
      "iata": "",
      "name": "Bugtown Airport",
      "city": "New Harmony",
      "state": "Indiana",
      "country": "US",
      "elevation": 464,
      "lat": 38.1506004333,
      "lon": -87.8403015137,
      "tz": "America\/Chicago"
  },
  "15KS": {
      "icao": "15KS",
      "iata": "",
      "name": "Sickler Airstrip",
      "city": "Emporia",
      "state": "Kansas",
      "country": "US",
      "elevation": 1200,
      "lat": 38.4305992126,
      "lon": -96.0883026123,
      "tz": "America\/Chicago"
  },
  "15KY": {
      "icao": "15KY",
      "iata": "",
      "name": "Brennan Farm Airport",
      "city": "Paris",
      "state": "Kentucky",
      "country": "US",
      "elevation": 910,
      "lat": 38.1570014954,
      "lon": -84.3015975952,
      "tz": "America\/New_York"
  },
  "15LL": {
      "icao": "15LL",
      "iata": "",
      "name": "Cloverleaf Ranch Airport",
      "city": "Tiskilwa",
      "state": "Illinois",
      "country": "US",
      "elevation": 807,
      "lat": 41.2374992371,
      "lon": -89.5366973877,
      "tz": "America\/Chicago"
  },
  "15MA": {
      "icao": "15MA",
      "iata": "",
      "name": "Canapitsit Airport",
      "city": "Gosnold",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 10,
      "lat": 41.4222984314,
      "lon": -70.9095001221,
      "tz": "America\/New_York"
  },
  "15ME": {
      "icao": "15ME",
      "iata": "",
      "name": "Eagle Field",
      "city": "North Yarmouth",
      "state": "Maine",
      "country": "US",
      "elevation": 150,
      "lat": 43.8152999878,
      "lon": -70.2292022705,
      "tz": "America\/New_York"
  },
  "15MN": {
      "icao": "15MN",
      "iata": "",
      "name": "Tyler Farms Airport",
      "city": "Watkins",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1185,
      "lat": 45.2621994019,
      "lon": -94.5106964111,
      "tz": "America\/Chicago"
  },
  "15MO": {
      "icao": "15MO",
      "iata": "",
      "name": "Applegate Airport",
      "city": "Queen City",
      "state": "Missouri",
      "country": "US",
      "elevation": 997,
      "lat": 40.391998291,
      "lon": -92.5654983521,
      "tz": "America\/Chicago"
  },
  "15MT": {
      "icao": "15MT",
      "iata": "",
      "name": "Saubak Airport",
      "city": "Lustre",
      "state": "Montana",
      "country": "US",
      "elevation": 2701,
      "lat": 48.5004005432,
      "lon": -105.9100036621,
      "tz": "America\/Denver"
  },
  "15NC": {
      "icao": "15NC",
      "iata": "",
      "name": "Dragonfly Field",
      "city": "Ahoskie",
      "state": "North Carolina",
      "country": "US",
      "elevation": 49,
      "lat": 36.2397003174,
      "lon": -76.9738998413,
      "tz": "America\/New_York"
  },
  "15NE": {
      "icao": "15NE",
      "iata": "",
      "name": "Sindt Airport",
      "city": "Upland",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1990,
      "lat": 40.2910995483,
      "lon": -98.8234024048,
      "tz": "America\/Chicago"
  },
  "15NK": {
      "icao": "15NK",
      "iata": "",
      "name": "Knox Landing Airport",
      "city": "Sardinia",
      "state": "New York",
      "country": "US",
      "elevation": 1845,
      "lat": 42.5764007568,
      "lon": -78.5567016602,
      "tz": "America\/New_York"
  },
  "15OK": {
      "icao": "15OK",
      "iata": "",
      "name": "Bluebird Airport",
      "city": "Muskogee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 531,
      "lat": 35.703889,
      "lon": -95.310833,
      "tz": "America\/Chicago"
  },
  "15PA": {
      "icao": "15PA",
      "iata": "",
      "name": "Cavage Personal Use Airport",
      "city": "Prompton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1400,
      "lat": 41.5942993164,
      "lon": -75.3554992676,
      "tz": "America\/New_York"
  },
  "15TA": {
      "icao": "15TA",
      "iata": "",
      "name": "J R Ranch Airport",
      "city": "Briggs",
      "state": "Texas",
      "country": "US",
      "elevation": 1125,
      "lat": 30.2544002533,
      "lon": -99.9469985962,
      "tz": "America\/Chicago"
  },
  "15TX": {
      "icao": "15TX",
      "iata": "",
      "name": "Ed Shadle Airport",
      "city": "Stephenville",
      "state": "Texas",
      "country": "US",
      "elevation": 1550,
      "lat": 32.3042984009,
      "lon": -98.325302124,
      "tz": "America\/Chicago"
  },
  "15VA": {
      "icao": "15VA",
      "iata": "",
      "name": "Fox Acres Airport",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 450,
      "lat": 38.7223014832,
      "lon": -77.8996963501,
      "tz": "America\/New_York"
  },
  "15WA": {
      "icao": "15WA",
      "iata": "",
      "name": "Sunset Airport",
      "city": "Stanwood",
      "state": "Washington",
      "country": "US",
      "elevation": 156,
      "lat": 48.27230072,
      "lon": -122.3570023,
      "tz": "America\/Los_Angeles"
  },
  "15WI": {
      "icao": "15WI",
      "iata": "",
      "name": "Peterson Field",
      "city": "Oregon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 978,
      "lat": 42.9275016785,
      "lon": -89.4181976318,
      "tz": "America\/Chicago"
  },
  "15XS": {
      "icao": "15XS",
      "iata": "",
      "name": "Toy Airpark",
      "city": "Liverpool",
      "state": "Texas",
      "country": "US",
      "elevation": 36,
      "lat": 29.3327007294,
      "lon": -95.3340988159,
      "tz": "America\/Chicago"
  },
  "16AK": {
      "icao": "16AK",
      "iata": "",
      "name": "Gattis Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 320,
      "lat": 61.5992012024,
      "lon": -149.3470001221,
      "tz": "America\/Anchorage"
  },
  "16AR": {
      "icao": "16AR",
      "iata": "",
      "name": "Heifer Creek Ranch Airport",
      "city": "Springfield",
      "state": "Arkansas",
      "country": "US",
      "elevation": 730,
      "lat": 35.3011016846,
      "lon": -92.5733032227,
      "tz": "America\/Chicago"
  },
  "16AZ": {
      "icao": "16AZ",
      "iata": "",
      "name": "Yav'pe Ma'ta Airport",
      "city": "Skull Valley",
      "state": "Arizona",
      "country": "US",
      "elevation": 4366,
      "lat": 34.5088996887,
      "lon": -112.6800003052,
      "tz": "America\/Phoenix"
  },
  "16CO": {
      "icao": "16CO",
      "iata": "",
      "name": "Dry Pen Airport",
      "city": "Parachute",
      "state": "Colorado",
      "country": "US",
      "elevation": 5331,
      "lat": 39.40995,
      "lon": -108.085667,
      "tz": "America\/Denver"
  },
  "16FA": {
      "icao": "16FA",
      "iata": "",
      "name": "Little Deer Airport",
      "city": "Ochopee",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 26.0308990479,
      "lon": -81.0386962891,
      "tz": "America\/New_York"
  },
  "16FD": {
      "icao": "16FD",
      "iata": "",
      "name": "Skinners Wholesale Nursery Airport",
      "city": "Crescent City",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 29.4050006866,
      "lon": -81.4959030151,
      "tz": "America\/New_York"
  },
  "16FL": {
      "icao": "16FL",
      "iata": "",
      "name": "J-22 Ranch Airport",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 200,
      "lat": 30.7252006531,
      "lon": -87.2014007568,
      "tz": "America\/Chicago"
  },
  "16IA": {
      "icao": "16IA",
      "iata": "",
      "name": "Stangl STOLport",
      "city": "Coon Rapids",
      "state": "Iowa",
      "country": "US",
      "elevation": 1230,
      "lat": 41.8807983398,
      "lon": -94.7771987915,
      "tz": "America\/Chicago"
  },
  "16II": {
      "icao": "16II",
      "iata": "",
      "name": "Harrington Field",
      "city": "San Pierre",
      "state": "Indiana",
      "country": "US",
      "elevation": 710,
      "lat": 41.1831016541,
      "lon": -86.9336013794,
      "tz": "America\/Chicago"
  },
  "16IL": {
      "icao": "16IL",
      "iata": "",
      "name": "Harold Bunger Airport",
      "city": "Kirkland",
      "state": "Illinois",
      "country": "US",
      "elevation": 830,
      "lat": 42.0666999817,
      "lon": -88.8333969116,
      "tz": "America\/Chicago"
  },
  "16IS": {
      "icao": "16IS",
      "iata": "",
      "name": "Kellums Airport",
      "city": "Goreville",
      "state": "Illinois",
      "country": "US",
      "elevation": 720,
      "lat": 37.5778007507,
      "lon": -89.0092010498,
      "tz": "America\/Chicago"
  },
  "16KY": {
      "icao": "16KY",
      "iata": "",
      "name": "Praise God Airport",
      "city": "Carter",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1070,
      "lat": 38.4440002441,
      "lon": -83.1227035522,
      "tz": "America\/New_York"
  },
  "16LS": {
      "icao": "16LS",
      "iata": "",
      "name": "Atchafalaya Flying Company Airport",
      "city": "Simmsport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 41,
      "lat": 30.9444007874,
      "lon": -91.7938995361,
      "tz": "America\/Chicago"
  },
  "16MD": {
      "icao": "16MD",
      "iata": "",
      "name": "High Valley Airport",
      "city": "North East",
      "state": "Maryland",
      "country": "US",
      "elevation": 363,
      "lat": 39.668611,
      "lon": -75.960278,
      "tz": "America\/New_York"
  },
  "16MI": {
      "icao": "16MI",
      "iata": "",
      "name": "Barnstormers 5 Airport",
      "city": "Gaines",
      "state": "Michigan",
      "country": "US",
      "elevation": 823,
      "lat": 42.8891983032,
      "lon": -83.8666000366,
      "tz": "America\/Detroit"
  },
  "16MO": {
      "icao": "16MO",
      "iata": "",
      "name": "Findley Field",
      "city": "Beaufort",
      "state": "Missouri",
      "country": "US",
      "elevation": 755,
      "lat": 38.4056015015,
      "lon": -91.1574020386,
      "tz": "America\/Chicago"
  },
  "16MT": {
      "icao": "16MT",
      "iata": "",
      "name": "Bangart Field",
      "city": "Roberts",
      "state": "Montana",
      "country": "US",
      "elevation": 4320,
      "lat": 45.4052009583,
      "lon": -109.1299972534,
      "tz": "America\/Denver"
  },
  "16NC": {
      "icao": "16NC",
      "iata": "",
      "name": "Lee's Airport",
      "city": "Edenton",
      "state": "North Carolina",
      "country": "US",
      "elevation": 31,
      "lat": 36.1960983276,
      "lon": -76.6652984619,
      "tz": "America\/New_York"
  },
  "16NE": {
      "icao": "16NE",
      "iata": "",
      "name": "Korver Airport",
      "city": "Panama",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1390,
      "lat": 40.5600013733,
      "lon": -96.4916992188,
      "tz": "America\/Chicago"
  },
  "16OI": {
      "icao": "16OI",
      "iata": "",
      "name": "Trump Airport",
      "city": "Greenville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1038,
      "lat": 40.1253013611,
      "lon": -84.5824966431,
      "tz": "America\/New_York"
  },
  "16OK": {
      "icao": "16OK",
      "iata": "",
      "name": "Lower Forty Airport",
      "city": "Harrah",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1160,
      "lat": 35.4667015076,
      "lon": -97.200302124,
      "tz": "America\/Chicago"
  },
  "16PA": {
      "icao": "16PA",
      "iata": "",
      "name": "Gregg Airport",
      "city": "Eighty Four",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.1805992126,
      "lon": -80.1276016235,
      "tz": "America\/New_York"
  },
  "16SC": {
      "icao": "16SC",
      "iata": "",
      "name": "Southern Aero Sports Airport",
      "city": "Cross Anchor",
      "state": "South Carolina",
      "country": "US",
      "elevation": 600,
      "lat": 34.6250991821,
      "lon": -81.898399353,
      "tz": "America\/New_York"
  },
  "16TA": {
      "icao": "16TA",
      "iata": "",
      "name": "Seven Springs Airport",
      "city": "Toyahvale",
      "state": "Texas",
      "country": "US",
      "elevation": 3500,
      "lat": 30.9197006226,
      "lon": -103.7829971313,
      "tz": "America\/Chicago"
  },
  "16TE": {
      "icao": "16TE",
      "iata": "",
      "name": "Holly Lake Ranch Airport",
      "city": "Hawkins",
      "state": "Texas",
      "country": "US",
      "elevation": 409,
      "lat": 32.6976013184,
      "lon": -95.2077026367,
      "tz": "America\/Chicago"
  },
  "16TS": {
      "icao": "16TS",
      "iata": "",
      "name": "Pineridge STOLport",
      "city": "Tyler",
      "state": "Texas",
      "country": "US",
      "elevation": 420,
      "lat": 32.2226982117,
      "lon": -95.3180007935,
      "tz": "America\/Chicago"
  },
  "16TX": {
      "icao": "16TX",
      "iata": "",
      "name": "Ratliff Airport",
      "city": "Stephenville",
      "state": "Texas",
      "country": "US",
      "elevation": 1300,
      "lat": 32.263999939,
      "lon": -98.1072998047,
      "tz": "America\/Chicago"
  },
  "16WA": {
      "icao": "16WA",
      "iata": "",
      "name": "Tightcliff Airport",
      "city": "Nine Mile Falls",
      "state": "Washington",
      "country": "US",
      "elevation": 1855,
      "lat": 47.8446998596,
      "lon": -117.6940002441,
      "tz": "America\/Los_Angeles"
  },
  "16XS": {
      "icao": "16XS",
      "iata": "",
      "name": "Outlaw Flyers Airport",
      "city": "Conroe",
      "state": "Texas",
      "country": "US",
      "elevation": 270,
      "lat": 30.404367,
      "lon": -95.35985,
      "tz": "America\/Chicago"
  },
  "17AR": {
      "icao": "17AR",
      "iata": "",
      "name": "Bredlow Farm Airport",
      "city": "England",
      "state": "Arkansas",
      "country": "US",
      "elevation": 236,
      "lat": 34.5531005859,
      "lon": -92.0914001465,
      "tz": "America\/Chicago"
  },
  "17CL": {
      "icao": "17CL",
      "iata": "",
      "name": "Las Trancas Airport",
      "city": "Davenport",
      "state": "California",
      "country": "US",
      "elevation": 125,
      "lat": 37.0880012512,
      "lon": -122.274002075,
      "tz": "America\/Los_Angeles"
  },
  "17CO": {
      "icao": "17CO",
      "iata": "",
      "name": "Skylane Ranch Airport",
      "city": "Berthoud",
      "state": "Colorado",
      "country": "US",
      "elevation": 5000,
      "lat": 40.272258,
      "lon": -105.032625,
      "tz": "America\/Denver"
  },
  "17FA": {
      "icao": "17FA",
      "iata": "",
      "name": "Cotton Strip",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 26.7133998871,
      "lon": -81.5354003906,
      "tz": "America\/New_York"
  },
  "17FL": {
      "icao": "17FL",
      "iata": "",
      "name": "Jumbolair-Greystone Airport",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 29.2803001404,
      "lon": -82.1248016357,
      "tz": "America\/New_York"
  },
  "17GA": {
      "icao": "17GA",
      "iata": "",
      "name": "Panther Creek Airport",
      "city": "Newnan",
      "state": "Georgia",
      "country": "US",
      "elevation": 850,
      "lat": 33.4667015076,
      "lon": -84.8660964966,
      "tz": "America\/New_York"
  },
  "17ID": {
      "icao": "17ID",
      "iata": "",
      "name": "Coyote Ridge Airport",
      "city": "Midvale",
      "state": "Idaho",
      "country": "US",
      "elevation": 3365,
      "lat": 44.413898468,
      "lon": -116.591003418,
      "tz": "America\/Boise"
  },
  "17II": {
      "icao": "17II",
      "iata": "",
      "name": "Dreessen Field",
      "city": "Walkerton",
      "state": "Indiana",
      "country": "US",
      "elevation": 706,
      "lat": 41.4500007629,
      "lon": -86.5102996826,
      "tz": "America\/Chicago"
  },
  "17IN": {
      "icao": "17IN",
      "iata": "",
      "name": "Pruss Airport",
      "city": "Farmers Retreat",
      "state": "Indiana",
      "country": "US",
      "elevation": 890,
      "lat": 38.9784011841,
      "lon": -85.109703064,
      "tz": "America\/New_York"
  },
  "17KS": {
      "icao": "17KS",
      "iata": "",
      "name": "Tevis Airport",
      "city": "Melvern",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 38.5027999878,
      "lon": -95.6772003174,
      "tz": "America\/Chicago"
  },
  "17KY": {
      "icao": "17KY",
      "iata": "",
      "name": "Shannon Field",
      "city": "Sacramento",
      "state": "Kentucky",
      "country": "US",
      "elevation": 440,
      "lat": 37.393901825,
      "lon": -87.2589035034,
      "tz": "America\/Chicago"
  },
  "17LL": {
      "icao": "17LL",
      "iata": "",
      "name": "Oink Acres Airport",
      "city": "Mahomet",
      "state": "Illinois",
      "country": "US",
      "elevation": 725,
      "lat": 40.1605987549,
      "lon": -88.4103012085,
      "tz": "America\/Chicago"
  },
  "17LS": {
      "icao": "17LS",
      "iata": "",
      "name": "Yankee Field",
      "city": "Port Barre",
      "state": "Louisiana",
      "country": "US",
      "elevation": 45,
      "lat": 30.6056995392,
      "lon": -91.9917984009,
      "tz": "America\/Chicago"
  },
  "17ME": {
      "icao": "17ME",
      "iata": "",
      "name": "Bresett'S Mountainside Airport",
      "city": "Van Buren",
      "state": "Maine",
      "country": "US",
      "elevation": 590,
      "lat": 47.2025,
      "lon": -67.991667,
      "tz": "America\/Moncton"
  },
  "17MO": {
      "icao": "17MO",
      "iata": "",
      "name": "Rgl Field",
      "city": "Montgomery City",
      "state": "Missouri",
      "country": "US",
      "elevation": 825,
      "lat": 38.9850006104,
      "lon": -91.5348968506,
      "tz": "America\/Chicago"
  },
  "17MT": {
      "icao": "17MT",
      "iata": "",
      "name": "Abel Ranch Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2900,
      "lat": 48.1071014404,
      "lon": -114.1770019531,
      "tz": "America\/Denver"
  },
  "17NC": {
      "icao": "17NC",
      "iata": "",
      "name": "Mitchell Field",
      "city": "Elizabethtown",
      "state": "North Carolina",
      "country": "US",
      "elevation": 23,
      "lat": 34.4663009644,
      "lon": -78.3285980225,
      "tz": "America\/New_York"
  },
  "17NK": {
      "icao": "17NK",
      "iata": "",
      "name": "Re-Dun Field",
      "city": "Reading Center",
      "state": "New York",
      "country": "US",
      "elevation": 1351,
      "lat": 42.5395011902,
      "lon": -76.9483032227,
      "tz": "America\/New_York"
  },
  "17OH": {
      "icao": "17OH",
      "iata": "",
      "name": "Kosik Private Airport",
      "city": "Kipton",
      "state": "Ohio",
      "country": "US",
      "elevation": 855,
      "lat": 41.2425994873,
      "lon": -82.2702026367,
      "tz": "America\/New_York"
  },
  "17OI": {
      "icao": "17OI",
      "iata": "",
      "name": "Haas Airport",
      "city": "Bainbridge",
      "state": "Ohio",
      "country": "US",
      "elevation": 750,
      "lat": 39.2266998291,
      "lon": -83.3177032471,
      "tz": "America\/New_York"
  },
  "17OK": {
      "icao": "17OK",
      "iata": "",
      "name": "J-B Airstrip",
      "city": "Kremlin",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1142,
      "lat": 36.5250015259,
      "lon": -97.7586975098,
      "tz": "America\/Chicago"
  },
  "17OR": {
      "icao": "17OR",
      "iata": "",
      "name": "Glide Aero Airport",
      "city": "Glide",
      "state": "Oregon",
      "country": "US",
      "elevation": 980,
      "lat": 43.2641983032,
      "lon": -123.1100006104,
      "tz": "America\/Los_Angeles"
  },
  "17PS": {
      "icao": "17PS",
      "iata": "",
      "name": "Mountain Crest Airport",
      "city": "Tidioute",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1641,
      "lat": 41.5792007446,
      "lon": -79.4417037964,
      "tz": "America\/New_York"
  },
  "17SC": {
      "icao": "17SC",
      "iata": "",
      "name": "Carolina Cow Country Airport",
      "city": "Clinton",
      "state": "South Carolina",
      "country": "US",
      "elevation": 680,
      "lat": 34.5326004028,
      "lon": -81.8861999512,
      "tz": "America\/New_York"
  },
  "17TE": {
      "icao": "17TE",
      "iata": "",
      "name": "Comfort Airpark",
      "city": "Comfort",
      "state": "Texas",
      "country": "US",
      "elevation": 1470,
      "lat": 29.9291000366,
      "lon": -98.9402999878,
      "tz": "America\/Chicago"
  },
  "17TN": {
      "icao": "17TN",
      "iata": "",
      "name": "Murphy Field",
      "city": "Jonesborough",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1720,
      "lat": 36.2994003296,
      "lon": -82.6042022705,
      "tz": "America\/New_York"
  },
  "17TX": {
      "icao": "17TX",
      "iata": "",
      "name": "Kimzey Airport",
      "city": "Stephenville",
      "state": "Texas",
      "country": "US",
      "elevation": 1100,
      "lat": 32.4486999512,
      "lon": -98.2549972534,
      "tz": "America\/Chicago"
  },
  "17WI": {
      "icao": "17WI",
      "iata": "",
      "name": "Ha-Rail Airport",
      "city": "Lake Mills",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 885,
      "lat": 43.0360984802,
      "lon": -88.8932037354,
      "tz": "America\/Chicago"
  },
  "17XA": {
      "icao": "17XA",
      "iata": "",
      "name": "Jacksonville \/ Hunter Field",
      "city": "Jacksonville",
      "state": "Texas",
      "country": "US",
      "elevation": 580,
      "lat": 32.017778,
      "lon": -95.7969444445,
      "tz": "America\/Chicago"
  },
  "17XS": {
      "icao": "17XS",
      "iata": "",
      "name": "Quahadi Ranch Airport",
      "city": "Dublin",
      "state": "Texas",
      "country": "US",
      "elevation": 1422,
      "lat": 32.1777992249,
      "lon": -98.4349975586,
      "tz": "America\/Chicago"
  },
  "18AR": {
      "icao": "18AR",
      "iata": "",
      "name": "Buck Mountain Airport",
      "city": "Eureka Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1450,
      "lat": 36.3442001343,
      "lon": -93.831703186,
      "tz": "America\/Chicago"
  },
  "18AZ": {
      "icao": "18AZ",
      "iata": "",
      "name": "Sky Ranch At Carefree Airport",
      "city": "Carefree",
      "state": "Arizona",
      "country": "US",
      "elevation": 2568,
      "lat": 33.8180999756,
      "lon": -111.8980026245,
      "tz": "America\/Phoenix"
  },
  "18CL": {
      "icao": "18CL",
      "iata": "",
      "name": "Bowles Airport",
      "city": "Live Oak",
      "state": "California",
      "country": "US",
      "elevation": 75,
      "lat": 39.2859992981,
      "lon": -121.6940002441,
      "tz": "America\/Los_Angeles"
  },
  "18FA": {
      "icao": "18FA",
      "iata": "",
      "name": "Tropical Plantation Airport",
      "city": "Palm City",
      "state": "Florida",
      "country": "US",
      "elevation": 19,
      "lat": 27.1028003693,
      "lon": -80.2934036255,
      "tz": "America\/New_York"
  },
  "18FD": {
      "icao": "18FD",
      "iata": "",
      "name": "Sky Ranch Airport",
      "city": "Baker",
      "state": "Florida",
      "country": "US",
      "elevation": 250,
      "lat": 30.8540992737,
      "lon": -86.6671981812,
      "tz": "America\/Chicago"
  },
  "18GA": {
      "icao": "18GA",
      "iata": "",
      "name": "Sleepy Hollow Airport",
      "city": "Social Circle",
      "state": "Georgia",
      "country": "US",
      "elevation": 750,
      "lat": 33.6931991577,
      "lon": -83.657699585,
      "tz": "America\/New_York"
  },
  "18II": {
      "icao": "18II",
      "iata": "",
      "name": "Mc Gill Airport",
      "city": "Cicero",
      "state": "Indiana",
      "country": "US",
      "elevation": 855,
      "lat": 40.1199989319,
      "lon": -86.0682983398,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "18IN": {
      "icao": "18IN",
      "iata": "",
      "name": "Kester Fly Inn Airport",
      "city": "Farmersburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 575,
      "lat": 39.2784004211,
      "lon": -87.4135971069,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "18JY": {
      "icao": "18JY",
      "iata": "",
      "name": "Skamokawa East Valley Airport",
      "city": "Skamokawa",
      "state": "Washington",
      "country": "US",
      "elevation": 19,
      "lat": 46.2846984863,
      "lon": -123.4430007935,
      "tz": "America\/Los_Angeles"
  },
  "18KS": {
      "icao": "18KS",
      "iata": "",
      "name": "Cherokee Strip",
      "city": "Udall",
      "state": "Kansas",
      "country": "US",
      "elevation": 1270,
      "lat": 37.3708992004,
      "lon": -97.1072998047,
      "tz": "America\/Chicago"
  },
  "18ME": {
      "icao": "18ME",
      "iata": "",
      "name": "Ruby Airport",
      "city": "Bristol",
      "state": "Maine",
      "country": "US",
      "elevation": 216,
      "lat": 43.893597,
      "lon": -69.495406,
      "tz": "America\/New_York"
  },
  "18MN": {
      "icao": "18MN",
      "iata": "",
      "name": "Hines Farm Airport",
      "city": "Motley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1283,
      "lat": 46.2372016907,
      "lon": -94.5931015015,
      "tz": "America\/Chicago"
  },
  "18MO": {
      "icao": "18MO",
      "iata": "",
      "name": "Gimlin Airport",
      "city": "Ozark",
      "state": "Missouri",
      "country": "US",
      "elevation": 1380,
      "lat": 36.962600708,
      "lon": -93.1707000732,
      "tz": "America\/Chicago"
  },
  "18MT": {
      "icao": "18MT",
      "iata": "",
      "name": "Fish Ranch Airport",
      "city": "Jackson",
      "state": "Montana",
      "country": "US",
      "elevation": 7200,
      "lat": 45.2159996033,
      "lon": -113.4990005493,
      "tz": "America\/Denver"
  },
  "18NC": {
      "icao": "18NC",
      "iata": "",
      "name": "Lanni Field",
      "city": "Lenoir",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1160,
      "lat": 35.888999939,
      "lon": -81.6137008667,
      "tz": "America\/New_York"
  },
  "18NE": {
      "icao": "18NE",
      "iata": "",
      "name": "Doc's Airport",
      "city": "Geneva",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1640,
      "lat": 40.5139007568,
      "lon": -97.6106033325,
      "tz": "America\/Chicago"
  },
  "18NY": {
      "icao": "18NY",
      "iata": "",
      "name": "Strip in the Woods Airport",
      "city": "Woodstock",
      "state": "New York",
      "country": "US",
      "elevation": 500,
      "lat": 42.0164985657,
      "lon": -74.0490036011,
      "tz": "America\/New_York"
  },
  "18OH": {
      "icao": "18OH",
      "iata": "",
      "name": "Jer-Mar Airpark",
      "city": "Lagrange",
      "state": "Ohio",
      "country": "US",
      "elevation": 800,
      "lat": 41.2561988831,
      "lon": -82.1437988281,
      "tz": "America\/New_York"
  },
  "18OI": {
      "icao": "18OI",
      "iata": "",
      "name": "Boggy Bottoms Airport",
      "city": "Vickery",
      "state": "Ohio",
      "country": "US",
      "elevation": 575,
      "lat": 41.4234008789,
      "lon": -82.9826965332,
      "tz": "America\/New_York"
  },
  "18OR": {
      "icao": "18OR",
      "iata": "",
      "name": "Red's Field",
      "city": "Amity",
      "state": "Oregon",
      "country": "US",
      "elevation": 190,
      "lat": 45.0918655396,
      "lon": -123.182350159,
      "tz": "America\/Los_Angeles"
  },
  "18PA": {
      "icao": "18PA",
      "iata": "",
      "name": "Slack Airport",
      "city": "Forest Grove",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 275,
      "lat": 40.3000984192,
      "lon": -75.082901001,
      "tz": "America\/New_York"
  },
  "18PN": {
      "icao": "18PN",
      "iata": "",
      "name": "Spud View Airport",
      "city": "Upper Strasburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 733,
      "lat": 40.0653991699,
      "lon": -77.6928024292,
      "tz": "America\/New_York"
  },
  "18SC": {
      "icao": "18SC",
      "iata": "",
      "name": "Connelly Field",
      "city": "Silverstreet",
      "state": "South Carolina",
      "country": "US",
      "elevation": 570,
      "lat": 34.2739982605,
      "lon": -81.773399353,
      "tz": "America\/New_York"
  },
  "18TA": {
      "icao": "18TA",
      "iata": "",
      "name": "West Kerr Ranch Airport",
      "city": "Junction",
      "state": "Texas",
      "country": "US",
      "elevation": 2330,
      "lat": 30.1362991333,
      "lon": -99.7436981201,
      "tz": "America\/Chicago"
  },
  "18TE": {
      "icao": "18TE",
      "iata": "",
      "name": "Alert Field",
      "city": "Big Sandy",
      "state": "Texas",
      "country": "US",
      "elevation": 390,
      "lat": 32.5852012634,
      "lon": -95.0639038086,
      "tz": "America\/Chicago"
  },
  "18TX": {
      "icao": "18TX",
      "iata": "",
      "name": "Flying 'T' Ranch Airport",
      "city": "Caddo Mills",
      "state": "Texas",
      "country": "US",
      "elevation": 545,
      "lat": 32.9524993896,
      "lon": -96.2281036377,
      "tz": "America\/Chicago"
  },
  "18WV": {
      "icao": "18WV",
      "iata": "",
      "name": "Lynn Airport",
      "city": "Newburg",
      "state": "West Virginia",
      "country": "US",
      "elevation": 1680,
      "lat": 39.41,
      "lon": -79.856667,
      "tz": "America\/New_York"
  },
  "18XS": {
      "icao": "18XS",
      "iata": "",
      "name": "Gardner Farm Airport",
      "city": "Mc Adoo",
      "state": "Texas",
      "country": "US",
      "elevation": 3000,
      "lat": 33.8000984192,
      "lon": -101.033996582,
      "tz": "America\/Chicago"
  },
  "19AK": {
      "icao": "19AK",
      "iata": "ICY",
      "name": "Icy Bay Airport",
      "city": "Icy Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 50,
      "lat": 59.96900177,
      "lon": -141.662002563,
      "tz": "America\/Anchorage"
  },
  "19AR": {
      "icao": "19AR",
      "iata": "",
      "name": "Naylor field",
      "city": "Naylor",
      "state": "Arkansas",
      "country": "US",
      "elevation": 230,
      "lat": 35.15670013,
      "lon": -92.22419739,
      "tz": "America\/Chicago"
  },
  "19AZ": {
      "icao": "19AZ",
      "iata": "",
      "name": "Montezuma Airport",
      "city": "Camp Verde",
      "state": "Arizona",
      "country": "US",
      "elevation": 3370,
      "lat": 34.6049995422,
      "lon": -111.8649978638,
      "tz": "America\/Phoenix"
  },
  "19CL": {
      "icao": "19CL",
      "iata": "",
      "name": "Palisades Ranch Airport",
      "city": "Helendale",
      "state": "California",
      "country": "US",
      "elevation": 2510,
      "lat": 34.7122001648,
      "lon": -117.3509979248,
      "tz": "America\/Los_Angeles"
  },
  "19GA": {
      "icao": "19GA",
      "iata": "",
      "name": "Willow Pond Aviation Inc Airport",
      "city": "Fayetteville",
      "state": "Georgia",
      "country": "US",
      "elevation": 868,
      "lat": 33.4236984253,
      "lon": -84.4999008179,
      "tz": "America\/New_York"
  },
  "19IA": {
      "icao": "19IA",
      "iata": "",
      "name": "Ancam Antique Airfield",
      "city": "West Burlington",
      "state": "Iowa",
      "country": "US",
      "elevation": 690,
      "lat": 40.8568000793,
      "lon": -91.2037963867,
      "tz": "America\/Chicago"
  },
  "19IL": {
      "icao": "19IL",
      "iata": "",
      "name": "Eagle Pass Airport",
      "city": "Bloomington",
      "state": "Illinois",
      "country": "US",
      "elevation": 810,
      "lat": 40.5494003296,
      "lon": -89.1060028076,
      "tz": "America\/Chicago"
  },
  "19IN": {
      "icao": "19IN",
      "iata": "",
      "name": "Clark Airport",
      "city": "Frankfort",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 40.1944999695,
      "lon": -86.5231018066,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "19IS": {
      "icao": "19IS",
      "iata": "",
      "name": "Skillet Fork Farm Airport",
      "city": "Wayne City",
      "state": "Illinois",
      "country": "US",
      "elevation": 430,
      "lat": 38.2958984375,
      "lon": -88.5569992065,
      "tz": "America\/Chicago"
  },
  "19KY": {
      "icao": "19KY",
      "iata": "",
      "name": "Conrads Airport",
      "city": "Dry Ridge",
      "state": "Kentucky",
      "country": "US",
      "elevation": 940,
      "lat": 38.7065010071,
      "lon": -84.599899292,
      "tz": "America\/New_York"
  },
  "19LL": {
      "icao": "19LL",
      "iata": "",
      "name": "Neiner Airport",
      "city": "Manteno",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 41.2569999695,
      "lon": -87.9195022583,
      "tz": "America\/Chicago"
  },
  "19NC": {
      "icao": "19NC",
      "iata": "",
      "name": "Double S Airport",
      "city": "Red Oak",
      "state": "North Carolina",
      "country": "US",
      "elevation": 195,
      "lat": 36.0648994446,
      "lon": -77.922996521,
      "tz": "America\/New_York"
  },
  "19ND": {
      "icao": "19ND",
      "iata": "",
      "name": "Breckheimer Airport",
      "city": "Tolna",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1458,
      "lat": 47.7332992554,
      "lon": -98.4887008667,
      "tz": "America\/Chicago"
  },
  "19NE": {
      "icao": "19NE",
      "iata": "",
      "name": "Hoyt Airport",
      "city": "Mc Cook\/Culbertson",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2707,
      "lat": 40.1082992554,
      "lon": -100.8089981079,
      "tz": "America\/Chicago"
  },
  "19NK": {
      "icao": "19NK",
      "iata": "",
      "name": "Riveredge Airpark",
      "city": "Central Square",
      "state": "New York",
      "country": "US",
      "elevation": 378,
      "lat": 43.2439002991,
      "lon": -76.1528015137,
      "tz": "America\/New_York"
  },
  "19NY": {
      "icao": "19NY",
      "iata": "",
      "name": "Four Seasons Airport",
      "city": "Reading",
      "state": "New York",
      "country": "US",
      "elevation": 1650,
      "lat": 42.4062004089,
      "lon": -77.9608001709,
      "tz": "America\/New_York"
  },
  "19OH": {
      "icao": "19OH",
      "iata": "",
      "name": "Harris Corn Field",
      "city": "Litchfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 915,
      "lat": 41.1730995178,
      "lon": -82.0593032837,
      "tz": "America\/New_York"
  },
  "19OK": {
      "icao": "19OK",
      "iata": "",
      "name": "Gerhart Airport",
      "city": "Okmulgee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 940,
      "lat": 35.7284011841,
      "lon": -95.9772033691,
      "tz": "America\/Chicago"
  },
  "19OR": {
      "icao": "19OR",
      "iata": "",
      "name": "Nelson Ranch Airport",
      "city": "Maupin",
      "state": "Oregon",
      "country": "US",
      "elevation": 2000,
      "lat": 45.1096000671,
      "lon": -121.2239990234,
      "tz": "America\/Los_Angeles"
  },
  "19PA": {
      "icao": "19PA",
      "iata": "",
      "name": "Lake Airport",
      "city": "Millville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 750,
      "lat": 41.1170005798,
      "lon": -76.5207977295,
      "tz": "America\/New_York"
  },
  "19SC": {
      "icao": "19SC",
      "iata": "",
      "name": "Sexton Airport",
      "city": "Newberry",
      "state": "South Carolina",
      "country": "US",
      "elevation": 593,
      "lat": 34.353833,
      "lon": -81.802358,
      "tz": "America\/New_York"
  },
  "19TA": {
      "icao": "19TA",
      "iata": "",
      "name": "Lagrone Ranch Airport",
      "city": "Mc Clendon-Chisholm",
      "state": "Texas",
      "country": "US",
      "elevation": 567,
      "lat": 32.8222999573,
      "lon": -96.4169006348,
      "tz": "America\/Chicago"
  },
  "19TE": {
      "icao": "19TE",
      "iata": "",
      "name": "Cut and Shoot Airport",
      "city": "Conroe",
      "state": "Texas",
      "country": "US",
      "elevation": 170,
      "lat": 30.3169002533,
      "lon": -95.3335037231,
      "tz": "America\/Chicago"
  },
  "19TN": {
      "icao": "19TN",
      "iata": "",
      "name": "Ferraraccio Field",
      "city": "Clarksville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 620,
      "lat": 36.4994010925,
      "lon": -87.1902999878,
      "tz": "America\/Chicago"
  },
  "19TX": {
      "icao": "19TX",
      "iata": "",
      "name": "Woody Mc Clellan Ranch Airport",
      "city": "Temple",
      "state": "Texas",
      "country": "US",
      "elevation": 690,
      "lat": 31.238199234,
      "lon": -97.5141983032,
      "tz": "America\/Chicago"
  },
  "19VA": {
      "icao": "19VA",
      "iata": "",
      "name": "Flying W Airport",
      "city": "St. Paul",
      "state": "Virginia",
      "country": "US",
      "elevation": 1760,
      "lat": 36.8897018433,
      "lon": -82.2977981567,
      "tz": "America\/New_York"
  },
  "19WA": {
      "icao": "19WA",
      "iata": "",
      "name": "Key Way Airport",
      "city": "Stevenson",
      "state": "Washington",
      "country": "US",
      "elevation": 972,
      "lat": 45.7196006775,
      "lon": -121.8860015869,
      "tz": "America\/Los_Angeles"
  },
  "19WI": {
      "icao": "19WI",
      "iata": "",
      "name": "Erickson Field",
      "city": "Prairie Farm",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1180,
      "lat": 45.1952018738,
      "lon": -92.0570983887,
      "tz": "America\/Chicago"
  },
  "19XS": {
      "icao": "19XS",
      "iata": "",
      "name": "Draggintail Acres Airport",
      "city": "Maypearl",
      "state": "Texas",
      "country": "US",
      "elevation": 585,
      "lat": 32.2971992493,
      "lon": -97.0868988037,
      "tz": "America\/Chicago"
  },
  "1AK1": {
      "icao": "1AK1",
      "iata": "",
      "name": "Crevice Creek Airport",
      "city": "Crevice Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 840,
      "lat": 67.3729019165,
      "lon": -152.0160064697,
      "tz": "America\/Anchorage"
  },
  "1AK2": {
      "icao": "1AK2",
      "iata": "",
      "name": "Crosswind Lake Airport",
      "city": "Crosswind Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 2125,
      "lat": 62.4009017944,
      "lon": -146.0130004883,
      "tz": "America\/Anchorage"
  },
  "1AK4": {
      "icao": "1AK4",
      "iata": "",
      "name": "Kenai River Airpark",
      "city": "Soldotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 60.5242004395,
      "lon": -150.7519989014,
      "tz": "America\/Anchorage"
  },
  "1AK6": {
      "icao": "1AK6",
      "iata": "",
      "name": "Talachulitna River Airport",
      "city": "Sketna",
      "state": "Alaska",
      "country": "US",
      "elevation": 282,
      "lat": 61.8527984619,
      "lon": -151.4080047607,
      "tz": "America\/Anchorage"
  },
  "1AK8": {
      "icao": "1AK8",
      "iata": "",
      "name": "Talaheim Airport",
      "city": "Skwentna",
      "state": "Alaska",
      "country": "US",
      "elevation": 610,
      "lat": 61.6768989563,
      "lon": -151.3869934082,
      "tz": "America\/Anchorage"
  },
  "1AL2": {
      "icao": "1AL2",
      "iata": "",
      "name": "Tri-L Acres Airport",
      "city": "Columbiana",
      "state": "Alabama",
      "country": "US",
      "elevation": 580,
      "lat": 33.2486000061,
      "lon": -86.5975036621,
      "tz": "America\/Chicago"
  },
  "1AL4": {
      "icao": "1AL4",
      "iata": "",
      "name": "Elsanor Airport",
      "city": "Robertsdale",
      "state": "Alabama",
      "country": "US",
      "elevation": 180,
      "lat": 30.5447006226,
      "lon": -87.5594024658,
      "tz": "America\/Chicago"
  },
  "1AL5": {
      "icao": "1AL5",
      "iata": "",
      "name": "Berry Field",
      "city": "Loxley",
      "state": "Alabama",
      "country": "US",
      "elevation": 170,
      "lat": 30.6364002228,
      "lon": -87.7478027344,
      "tz": "America\/Chicago"
  },
  "1AL8": {
      "icao": "1AL8",
      "iata": "",
      "name": "Moore Field",
      "city": "Moody",
      "state": "Alabama",
      "country": "US",
      "elevation": 730,
      "lat": 33.6110992432,
      "lon": -86.4744033813,
      "tz": "America\/Chicago"
  },
  "1AR0": {
      "icao": "1AR0",
      "iata": "",
      "name": "Breckenridge Airport",
      "city": "Beedeville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 221,
      "lat": 35.3987007141,
      "lon": -91.1334991455,
      "tz": "America\/Chicago"
  },
  "1AR1": {
      "icao": "1AR1",
      "iata": "",
      "name": "Walls Airport",
      "city": "Coy",
      "state": "Arkansas",
      "country": "US",
      "elevation": 215,
      "lat": 34.5271987915,
      "lon": -91.8727035522,
      "tz": "America\/Chicago"
  },
  "1AR2": {
      "icao": "1AR2",
      "iata": "",
      "name": "Taylor Airstrip",
      "city": "Des Arc",
      "state": "Arkansas",
      "country": "US",
      "elevation": 208,
      "lat": 34.9868011475,
      "lon": -91.5503005981,
      "tz": "America\/Chicago"
  },
  "1AR3": {
      "icao": "1AR3",
      "iata": "",
      "name": "Bob Norman Airstrip",
      "city": "Des Arc",
      "state": "Arkansas",
      "country": "US",
      "elevation": 208,
      "lat": 34.9732017517,
      "lon": -91.5260009766,
      "tz": "America\/Chicago"
  },
  "1AR4": {
      "icao": "1AR4",
      "iata": "",
      "name": "Foggy River Airport",
      "city": "Elkins",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1240,
      "lat": 36.0178985596,
      "lon": -94.0184020996,
      "tz": "America\/Chicago"
  },
  "1AR5": {
      "icao": "1AR5",
      "iata": "",
      "name": "Cherokee Strip",
      "city": "Gentry",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1194,
      "lat": 36.2994003296,
      "lon": -94.5847015381,
      "tz": "America\/Chicago"
  },
  "1AR6": {
      "icao": "1AR6",
      "iata": "",
      "name": "Diamond Bluff Airport",
      "city": "Greers Ferry",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1000,
      "lat": 35.5032997131,
      "lon": -92.1967010498,
      "tz": "America\/Chicago"
  },
  "1AR8": {
      "icao": "1AR8",
      "iata": "",
      "name": "Flying W Air Park Inc Airport",
      "city": "Lavaca",
      "state": "Arkansas",
      "country": "US",
      "elevation": 420,
      "lat": 35.3256988525,
      "lon": -94.1867980957,
      "tz": "America\/Chicago"
  },
  "1AR9": {
      "icao": "1AR9",
      "iata": "",
      "name": "Country Air Estates Airport",
      "city": "Lonoke",
      "state": "Arkansas",
      "country": "US",
      "elevation": 250,
      "lat": 34.8138999939,
      "lon": -92,
      "tz": "America\/Chicago"
  },
  "1AZ0": {
      "icao": "1AZ0",
      "iata": "",
      "name": "Mobile Airport",
      "city": "Mobile",
      "state": "Arizona",
      "country": "US",
      "elevation": 1261,
      "lat": 33.1119003296,
      "lon": -112.2689971924,
      "tz": "America\/Phoenix"
  },
  "1AZ2": {
      "icao": "1AZ2",
      "iata": "",
      "name": "Paradise Air Park",
      "city": "Tolleson",
      "state": "Arizona",
      "country": "US",
      "elevation": 1015,
      "lat": 33.4117012024,
      "lon": -112.1819992065,
      "tz": "America\/Phoenix"
  },
  "1AZ8": {
      "icao": "1AZ8",
      "iata": "",
      "name": "Willow Springs Ranch Airport",
      "city": "Bullhead City",
      "state": "Arizona",
      "country": "US",
      "elevation": 3750,
      "lat": 35.2957992554,
      "lon": -114.3730010986,
      "tz": "America\/Phoenix"
  },
  "1CA1": {
      "icao": "1CA1",
      "iata": "",
      "name": "Belridge Strip",
      "city": "South Belridge",
      "state": "California",
      "country": "US",
      "elevation": 575,
      "lat": 35.4679985046,
      "lon": -119.7220001221,
      "tz": "America\/Los_Angeles"
  },
  "1CA4": {
      "icao": "1CA4",
      "iata": "",
      "name": "Aha-Quin Airport",
      "city": "Blythe",
      "state": "California",
      "country": "US",
      "elevation": 300,
      "lat": 33.8694992065,
      "lon": -114.5270004272,
      "tz": "America\/Los_Angeles"
  },
  "1CA6": {
      "icao": "1CA6",
      "iata": "",
      "name": "On the Rocks Airport",
      "city": "Alpine",
      "state": "California",
      "country": "US",
      "elevation": 2650,
      "lat": 32.7650985718,
      "lon": -116.7229995728,
      "tz": "America\/Los_Angeles"
  },
  "1CD1": {
      "icao": "1CD1",
      "iata": "",
      "name": "Reed Airport",
      "city": "Nunn",
      "state": "Colorado",
      "country": "US",
      "elevation": 5350,
      "lat": 40.6902999878,
      "lon": -104.8669967651,
      "tz": "America\/Denver"
  },
  "1CD2": {
      "icao": "1CD2",
      "iata": "",
      "name": "Tonga Airport",
      "city": "Platteville",
      "state": "Colorado",
      "country": "US",
      "elevation": 4925,
      "lat": 40.1671981812,
      "lon": -104.7929992676,
      "tz": "America\/Denver"
  },
  "1CD4": {
      "icao": "1CD4",
      "iata": "",
      "name": "Eagle Soaring Airport",
      "city": "Steam Boat Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 6600,
      "lat": 40.5093002319,
      "lon": -106.9430007935,
      "tz": "America\/Denver"
  },
  "1CL1": {
      "icao": "1CL1",
      "iata": "",
      "name": "Little Buttes Antique Airfield",
      "city": "Lancaster",
      "state": "California",
      "country": "US",
      "elevation": 2433,
      "lat": 34.7941017151,
      "lon": -118.2779998779,
      "tz": "America\/Los_Angeles"
  },
  "1CL2": {
      "icao": "1CL2",
      "iata": "",
      "name": "Pontious Airport",
      "city": "Mojave",
      "state": "California",
      "country": "US",
      "elevation": 2610,
      "lat": 34.9425010681,
      "lon": -118.1699981689,
      "tz": "America\/Los_Angeles"
  },
  "1CO2": {
      "icao": "1CO2",
      "iata": "",
      "name": "Williams Ranch Airport",
      "city": "Norwood",
      "state": "Colorado",
      "country": "US",
      "elevation": 6842,
      "lat": 38.1624984741,
      "lon": -108.3399963379,
      "tz": "America\/Denver"
  },
  "1CO3": {
      "icao": "1CO3",
      "iata": "",
      "name": "Bellmore Farms Airport",
      "city": "Nunn",
      "state": "Colorado",
      "country": "US",
      "elevation": 5225,
      "lat": 40.7041015625,
      "lon": -104.797996521,
      "tz": "America\/Denver"
  },
  "1CO4": {
      "icao": "1CO4",
      "iata": "",
      "name": "Clifford Field",
      "city": "Olathe",
      "state": "Colorado",
      "country": "US",
      "elevation": 5560,
      "lat": 38.5750007629,
      "lon": -107.9589996338,
      "tz": "America\/Denver"
  },
  "1CO5": {
      "icao": "1CO5",
      "iata": "",
      "name": "Melon Field",
      "city": "Rocky Ford",
      "state": "Colorado",
      "country": "US",
      "elevation": 4260,
      "lat": 38.0153007507,
      "lon": -103.6989974976,
      "tz": "America\/Denver"
  },
  "1CO7": {
      "icao": "1CO7",
      "iata": "",
      "name": "Dodsworth Airport",
      "city": "Salida",
      "state": "Colorado",
      "country": "US",
      "elevation": 7480,
      "lat": 38.551399231,
      "lon": -105.9919967651,
      "tz": "America\/Denver"
  },
  "1CO8": {
      "icao": "1CO8",
      "iata": "",
      "name": "Everitt Airport",
      "city": "Parker",
      "state": "Colorado",
      "country": "US",
      "elevation": 6295,
      "lat": 39.5292015076,
      "lon": -104.6579971313,
      "tz": "America\/Denver"
  },
  "1DE5": {
      "icao": "1DE5",
      "iata": "",
      "name": "Mckeown Airport",
      "city": "Middletown",
      "state": "Delaware",
      "country": "US",
      "elevation": 60,
      "lat": 39.4956016541,
      "lon": -75.7332992554,
      "tz": "America\/New_York"
  },
  "1FA1": {
      "icao": "1FA1",
      "iata": "",
      "name": "Post Oak Ranch Airport",
      "city": "Crystal River",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 28.8558006287,
      "lon": -82.5525970459,
      "tz": "America\/New_York"
  },
  "1FA3": {
      "icao": "1FA3",
      "iata": "",
      "name": "Pine Island Airport",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 12,
      "lat": 26.6562004089,
      "lon": -82.1212005615,
      "tz": "America\/New_York"
  },
  "1FD0": {
      "icao": "1FD0",
      "iata": "",
      "name": "Lawrence Airport",
      "city": "Grand Ridge",
      "state": "Florida",
      "country": "US",
      "elevation": 130,
      "lat": 30.7154998779,
      "lon": -85.0243988037,
      "tz": "America\/Chicago"
  },
  "1FD2": {
      "icao": "1FD2",
      "iata": "",
      "name": "Bass Limited Airport",
      "city": "Madison",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 30.6194000244,
      "lon": -83.3040008545,
      "tz": "America\/New_York"
  },
  "1FD4": {
      "icao": "1FD4",
      "iata": "",
      "name": "Corkscrew Trace Airpark",
      "city": "Estero",
      "state": "Florida",
      "country": "US",
      "elevation": 29,
      "lat": 26.445400238,
      "lon": -81.6072998047,
      "tz": "America\/New_York"
  },
  "1FL3": {
      "icao": "1FL3",
      "iata": "",
      "name": "Z Ranch Airport",
      "city": "Lake City",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 30.0344009399,
      "lon": -82.7820968628,
      "tz": "America\/New_York"
  },
  "1GA0": {
      "icao": "1GA0",
      "iata": "",
      "name": "Eagle Neck Airport",
      "city": "Shellman Bluff",
      "state": "Georgia",
      "country": "US",
      "elevation": 10,
      "lat": 31.6394004822,
      "lon": -81.3290023804,
      "tz": "America\/New_York"
  },
  "1GA2": {
      "icao": "1GA2",
      "iata": "",
      "name": "Flying N Estates Airport",
      "city": "Luthersville",
      "state": "Georgia",
      "country": "US",
      "elevation": 870,
      "lat": 33.1772994995,
      "lon": -84.7484970093,
      "tz": "America\/New_York"
  },
  "1GA4": {
      "icao": "1GA4",
      "iata": "",
      "name": "Southern Agricultural Aviation Airport",
      "city": "Statesboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 180,
      "lat": 32.3284988403,
      "lon": -81.7554016113,
      "tz": "America\/New_York"
  },
  "1GA5": {
      "icao": "1GA5",
      "iata": "",
      "name": "Shilo Farms Airport",
      "city": "Hahira",
      "state": "Georgia",
      "country": "US",
      "elevation": 211,
      "lat": 30.9309997559,
      "lon": -83.3834991455,
      "tz": "America\/New_York"
  },
  "1GA6": {
      "icao": "1GA6",
      "iata": "",
      "name": "De De Airport",
      "city": "Barnesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.0089988708,
      "lon": -84.1107025146,
      "tz": "America\/New_York"
  },
  "1GA8": {
      "icao": "1GA8",
      "iata": "",
      "name": "South One Ten Airport",
      "city": "Nashville",
      "state": "Georgia",
      "country": "US",
      "elevation": 225,
      "lat": 31.1784992218,
      "lon": -83.2265014648,
      "tz": "America\/New_York"
  },
  "1GA9": {
      "icao": "1GA9",
      "iata": "",
      "name": "Aerie Airport",
      "city": "Monroe",
      "state": "Georgia",
      "country": "US",
      "elevation": 830,
      "lat": 33.7615013123,
      "lon": -83.6527023315,
      "tz": "America\/New_York"
  },
  "1GE2": {
      "icao": "1GE2",
      "iata": "",
      "name": "C & W Air Park",
      "city": "Woodland",
      "state": "Georgia",
      "country": "US",
      "elevation": 1306,
      "lat": 32.8443984985,
      "lon": -84.5438995361,
      "tz": "America\/New_York"
  },
  "1GE3": {
      "icao": "1GE3",
      "iata": "",
      "name": "Answered Prayer Airport",
      "city": "Franklin",
      "state": "Georgia",
      "country": "US",
      "elevation": 890,
      "lat": 33.2538986206,
      "lon": -85.1703033447,
      "tz": "America\/New_York"
  },
  "1GE4": {
      "icao": "1GE4",
      "iata": "",
      "name": "Beckley Farms Airport",
      "city": "Fort Valley",
      "state": "Georgia",
      "country": "US",
      "elevation": 425,
      "lat": 32.5010986328,
      "lon": -83.9332962036,
      "tz": "America\/New_York"
  },
  "1GE5": {
      "icao": "1GE5",
      "iata": "",
      "name": "Flying D Airport",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 838,
      "lat": 33.1012001038,
      "lon": -84.3935012817,
      "tz": "America\/New_York"
  },
  "1GE6": {
      "icao": "1GE6",
      "iata": "",
      "name": "Forestry-Strip Airport",
      "city": "Patterson",
      "state": "Georgia",
      "country": "US",
      "elevation": 105,
      "lat": 31.3724002838,
      "lon": -82.1234970093,
      "tz": "America\/New_York"
  },
  "1GE7": {
      "icao": "1GE7",
      "iata": "",
      "name": "Hay Field",
      "city": "Madison",
      "state": "Georgia",
      "country": "US",
      "elevation": 650,
      "lat": 33.51720047,
      "lon": -83.4396972656,
      "tz": "America\/New_York"
  },
  "1GE9": {
      "icao": "1GE9",
      "iata": "",
      "name": "Waverly Landing Airport",
      "city": "Waverly Hall",
      "state": "Georgia",
      "country": "US",
      "elevation": 675,
      "lat": 32.7144012451,
      "lon": -84.7181015015,
      "tz": "America\/New_York"
  },
  "1IA0": {
      "icao": "1IA0",
      "iata": "",
      "name": "Brown Truck Sales Airport",
      "city": "Corning",
      "state": "Iowa",
      "country": "US",
      "elevation": 1300,
      "lat": 40.8972015381,
      "lon": -94.7910995483,
      "tz": "America\/Chicago"
  },
  "1IA4": {
      "icao": "1IA4",
      "iata": "",
      "name": "Wallace Field",
      "city": "Creston",
      "state": "Iowa",
      "country": "US",
      "elevation": 1290,
      "lat": 41.0555000305,
      "lon": -94.3946990967,
      "tz": "America\/Chicago"
  },
  "1IA6": {
      "icao": "1IA6",
      "iata": "",
      "name": "Aero-Lane Airport",
      "city": "Randolph",
      "state": "Iowa",
      "country": "US",
      "elevation": 1134,
      "lat": 40.8849983215,
      "lon": -95.4852981567,
      "tz": "America\/Chicago"
  },
  "1IA9": {
      "icao": "1IA9",
      "iata": "",
      "name": "Newbrough Airport",
      "city": "Britt",
      "state": "Iowa",
      "country": "US",
      "elevation": 1192,
      "lat": 43.0676994324,
      "lon": -93.9021987915,
      "tz": "America\/Chicago"
  },
  "1ID3": {
      "icao": "1ID3",
      "iata": "",
      "name": "Beaux Ranch Field",
      "city": "Sandpoint",
      "state": "Idaho",
      "country": "US",
      "elevation": 2079,
      "lat": 48.1679992676,
      "lon": -116.7269973755,
      "tz": "America\/Los_Angeles"
  },
  "1ID4": {
      "icao": "1ID4",
      "iata": "",
      "name": "Red Baron Airpark",
      "city": "Oasis",
      "state": "Idaho",
      "country": "US",
      "elevation": 3259,
      "lat": 43.3105555556,
      "lon": -115.9025,
      "tz": "America\/Boise"
  },
  "1ID7": {
      "icao": "1ID7",
      "iata": "",
      "name": "Fairbanks Airfield",
      "city": "Eden",
      "state": "Idaho",
      "country": "US",
      "elevation": 3830,
      "lat": 42.5877990723,
      "lon": -114.3339996338,
      "tz": "America\/Boise"
  },
  "1ID9": {
      "icao": "1ID9",
      "iata": "",
      "name": "Skyline STOLport",
      "city": "Inkom",
      "state": "Idaho",
      "country": "US",
      "elevation": 6150,
      "lat": 42.7806015015,
      "lon": -112.1709976196,
      "tz": "America\/Boise"
  },
  "1II0": {
      "icao": "1II0",
      "iata": "",
      "name": "Webster Airport",
      "city": "Homer",
      "state": "Indiana",
      "country": "US",
      "elevation": 910,
      "lat": 39.5766983032,
      "lon": -85.5877990723,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1II2": {
      "icao": "1II2",
      "iata": "",
      "name": "Adams Airport",
      "city": "Geneva",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 40.6157989502,
      "lon": -84.8348007202,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1II3": {
      "icao": "1II3",
      "iata": "",
      "name": "Davis Airport",
      "city": "Carlisle",
      "state": "Indiana",
      "country": "US",
      "elevation": 500,
      "lat": 38.9570007324,
      "lon": -87.394203186,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1II4": {
      "icao": "1II4",
      "iata": "",
      "name": "Amy Airport",
      "city": "Corydon",
      "state": "Indiana",
      "country": "US",
      "elevation": 665,
      "lat": 38.1250991821,
      "lon": -86.1622009277,
      "tz": "America\/Kentucky\/Louisville"
  },
  "1II5": {
      "icao": "1II5",
      "iata": "",
      "name": "Van De Mark Airport",
      "city": "Culver",
      "state": "Indiana",
      "country": "US",
      "elevation": 730,
      "lat": 41.2472991943,
      "lon": -86.5030975342,
      "tz": "America\/Indiana\/Knox"
  },
  "1II8": {
      "icao": "1II8",
      "iata": "",
      "name": "Morris Airport",
      "city": "Greenfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 39.814201355,
      "lon": -85.818901062,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1IL0": {
      "icao": "1IL0",
      "iata": "",
      "name": "Barnstorm Field",
      "city": "El Paso",
      "state": "Illinois",
      "country": "US",
      "elevation": 731,
      "lat": 40.7750015259,
      "lon": -89.0010986328,
      "tz": "America\/Chicago"
  },
  "1IL1": {
      "icao": "1IL1",
      "iata": "",
      "name": "Horsefeathers Ranch Airport",
      "city": "Irving",
      "state": "Illinois",
      "country": "US",
      "elevation": 653,
      "lat": 39.2444992065,
      "lon": -89.4259033203,
      "tz": "America\/Chicago"
  },
  "1IL2": {
      "icao": "1IL2",
      "iata": "",
      "name": "Hobby Hideaway RLA",
      "city": "Delavan",
      "state": "Illinois",
      "country": "US",
      "elevation": 660,
      "lat": 40.3470001221,
      "lon": -89.470703125,
      "tz": "America\/Chicago"
  },
  "1IL4": {
      "icao": "1IL4",
      "iata": "",
      "name": "Heitman Aerdrome Airport",
      "city": "Millstadt",
      "state": "Illinois",
      "country": "US",
      "elevation": 631,
      "lat": 38.4187011719,
      "lon": -90.1312026978,
      "tz": "America\/Chicago"
  },
  "1IL6": {
      "icao": "1IL6",
      "iata": "",
      "name": "Bally's Strip",
      "city": "Dixon",
      "state": "Illinois",
      "country": "US",
      "elevation": 786,
      "lat": 41.8666992188,
      "lon": -89.4418029785,
      "tz": "America\/Chicago"
  },
  "1IL8": {
      "icao": "1IL8",
      "iata": "",
      "name": "Loy Airport",
      "city": "Donovan",
      "state": "Illinois",
      "country": "US",
      "elevation": 665,
      "lat": 40.9319992065,
      "lon": -87.5959014893,
      "tz": "America\/Chicago"
  },
  "1IL9": {
      "icao": "1IL9",
      "iata": "",
      "name": "Holland Field",
      "city": "Mc Lean",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 40.3005981445,
      "lon": -89.1243972778,
      "tz": "America\/Chicago"
  },
  "1IN0": {
      "icao": "1IN0",
      "iata": "",
      "name": "Belknap-Icarus Acres Airport",
      "city": "Parker City",
      "state": "Indiana",
      "country": "US",
      "elevation": 1040,
      "lat": 40.1333999634,
      "lon": -85.2304992676,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1IN1": {
      "icao": "1IN1",
      "iata": "",
      "name": "Shearer STOLport",
      "city": "Lewisville",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 39.4883995056,
      "lon": -86.6530990601,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1IN3": {
      "icao": "1IN3",
      "iata": "",
      "name": "Confer's Place Airport",
      "city": "Arcola",
      "state": "Indiana",
      "country": "US",
      "elevation": 865,
      "lat": 41.0978012085,
      "lon": -85.2425003052,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1IN4": {
      "icao": "1IN4",
      "iata": "",
      "name": "Robinson Airpark",
      "city": "Elizabeth",
      "state": "Indiana",
      "country": "US",
      "elevation": 775,
      "lat": 38.1431007385,
      "lon": -85.9878005981,
      "tz": "America\/Kentucky\/Louisville"
  },
  "1IN5": {
      "icao": "1IN5",
      "iata": "",
      "name": "Bottoms Brothers Airport",
      "city": "Somerville",
      "state": "Indiana",
      "country": "US",
      "elevation": 545,
      "lat": 38.2848014832,
      "lon": -87.3820037842,
      "tz": "America\/Chicago"
  },
  "1IN6": {
      "icao": "1IN6",
      "iata": "",
      "name": "Cottingham Airport",
      "city": "Pine Village",
      "state": "Indiana",
      "country": "US",
      "elevation": 685,
      "lat": 40.4016990662,
      "lon": -87.2202987671,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "1IS0": {
      "icao": "1IS0",
      "iata": "",
      "name": "Harold Emmerich Airport",
      "city": "Belleville",
      "state": "Illinois",
      "country": "US",
      "elevation": 515,
      "lat": 38.5242004395,
      "lon": -89.9322967529,
      "tz": "America\/Chicago"
  },
  "1IS2": {
      "icao": "1IS2",
      "iata": "",
      "name": "Triple Creek Airport",
      "city": "Bement",
      "state": "Illinois",
      "country": "US",
      "elevation": 666,
      "lat": 39.8964004517,
      "lon": -88.5223007202,
      "tz": "America\/Chicago"
  },
  "1IS3": {
      "icao": "1IS3",
      "iata": "",
      "name": "Cribbet Airport",
      "city": "Blue Mound",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 39.7277984619,
      "lon": -89.0595016479,
      "tz": "America\/Chicago"
  },
  "1IS4": {
      "icao": "1IS4",
      "iata": "",
      "name": "Swan Valley Farm Airport",
      "city": "Lanark",
      "state": "Illinois",
      "country": "US",
      "elevation": 916,
      "lat": 42.1344985962,
      "lon": -89.8246002197,
      "tz": "America\/Chicago"
  },
  "1IS5": {
      "icao": "1IS5",
      "iata": "",
      "name": "Walder's Farm Airport",
      "city": "Paw Paw",
      "state": "Illinois",
      "country": "US",
      "elevation": 900,
      "lat": 41.6530990601,
      "lon": -89.0015029907,
      "tz": "America\/Chicago"
  },
  "1JY2": {
      "icao": "1JY2",
      "iata": "",
      "name": "Mahogany Mtn. Airport",
      "city": "Silver Lake",
      "state": "Oregon",
      "country": "US",
      "elevation": 4620,
      "lat": 43.0671005249,
      "lon": -121.0530014038,
      "tz": "America\/Los_Angeles"
  },
  "1KS0": {
      "icao": "1KS0",
      "iata": "",
      "name": "Huey Airport",
      "city": "Hutchinson",
      "state": "Kansas",
      "country": "US",
      "elevation": 1700,
      "lat": 38.1694984436,
      "lon": -97.9711990356,
      "tz": "America\/Chicago"
  },
  "1KS1": {
      "icao": "1KS1",
      "iata": "",
      "name": "Cochran Airport",
      "city": "Richmond",
      "state": "Kansas",
      "country": "US",
      "elevation": 1005,
      "lat": 38.4589004517,
      "lon": -95.2260971069,
      "tz": "America\/Chicago"
  },
  "1KS2": {
      "icao": "1KS2",
      "iata": "",
      "name": "Risky Airport",
      "city": "Easton",
      "state": "Kansas",
      "country": "US",
      "elevation": 1133,
      "lat": 39.313999176,
      "lon": -95.0716018677,
      "tz": "America\/Chicago"
  },
  "1KS3": {
      "icao": "1KS3",
      "iata": "",
      "name": "Leo's Place STOLport",
      "city": "Riley",
      "state": "Kansas",
      "country": "US",
      "elevation": 1350,
      "lat": 39.3041992188,
      "lon": -96.7585983276,
      "tz": "America\/Chicago"
  },
  "1KS4": {
      "icao": "1KS4",
      "iata": "",
      "name": "Prichard Airstrip",
      "city": "Enterprise",
      "state": "Kansas",
      "country": "US",
      "elevation": 1164,
      "lat": 38.9000015259,
      "lon": -97.1169967651,
      "tz": "America\/Chicago"
  },
  "1KS5": {
      "icao": "1KS5",
      "iata": "",
      "name": "Keyser Airport",
      "city": "St Marys",
      "state": "Kansas",
      "country": "US",
      "elevation": 950,
      "lat": 39.1624984741,
      "lon": -96.0963973999,
      "tz": "America\/Chicago"
  },
  "1KS6": {
      "icao": "1KS6",
      "iata": "",
      "name": "Silers Plane Valley Airport",
      "city": "Salina",
      "state": "Kansas",
      "country": "US",
      "elevation": 1204,
      "lat": 38.8666992188,
      "lon": -97.5363998413,
      "tz": "America\/Chicago"
  },
  "1KS7": {
      "icao": "1KS7",
      "iata": "",
      "name": "Hitch Feeders Ii Inc. Airport",
      "city": "Satanta",
      "state": "Kansas",
      "country": "US",
      "elevation": 3028,
      "lat": 37.6735992432,
      "lon": -101.016998291,
      "tz": "America\/Chicago"
  },
  "1KS8": {
      "icao": "1KS8",
      "iata": "",
      "name": "Buehler Airport",
      "city": "Scott City",
      "state": "Kansas",
      "country": "US",
      "elevation": 3038,
      "lat": 38.5153007507,
      "lon": -100.9950027466,
      "tz": "America\/Chicago"
  },
  "1KS9": {
      "icao": "1KS9",
      "iata": "",
      "name": "Huff Airport",
      "city": "Bonner Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 935,
      "lat": 39.0456008911,
      "lon": -94.9489974976,
      "tz": "America\/Chicago"
  },
  "1KY7": {
      "icao": "1KY7",
      "iata": "",
      "name": "Jordan Hill Farm Airport",
      "city": "Richmond",
      "state": "Kentucky",
      "country": "US",
      "elevation": 910,
      "lat": 37.8325004578,
      "lon": -84.1808013916,
      "tz": "America\/New_York"
  },
  "1LA0": {
      "icao": "1LA0",
      "iata": "",
      "name": "Ken Guidry Nr 2 Airport",
      "city": "Gueydan",
      "state": "Louisiana",
      "country": "US",
      "elevation": 6,
      "lat": 30.0168991089,
      "lon": -92.5279006958,
      "tz": "America\/Chicago"
  },
  "1LA1": {
      "icao": "1LA1",
      "iata": "",
      "name": "Triche Field",
      "city": "Hahnville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.983499527,
      "lon": -90.4347991943,
      "tz": "America\/Chicago"
  },
  "1LA3": {
      "icao": "1LA3",
      "iata": "",
      "name": "Goose Island Airport",
      "city": "Hayes",
      "state": "Louisiana",
      "country": "US",
      "elevation": 4,
      "lat": 30.0307998657,
      "lon": -92.8335037231,
      "tz": "America\/Chicago"
  },
  "1LA5": {
      "icao": "1LA5",
      "iata": "",
      "name": "Morrow Strip",
      "city": "Morrow",
      "state": "Louisiana",
      "country": "US",
      "elevation": 40,
      "lat": 30.799200058,
      "lon": -92.0597000122,
      "tz": "America\/Chicago"
  },
  "1LL0": {
      "icao": "1LL0",
      "iata": "",
      "name": "Boondox Field",
      "city": "Seneca",
      "state": "Illinois",
      "country": "US",
      "elevation": 625,
      "lat": 41.3292007446,
      "lon": -88.6000976563,
      "tz": "America\/Chicago"
  },
  "1LL2": {
      "icao": "1LL2",
      "iata": "",
      "name": "Spring Brook Airport",
      "city": "Seneca",
      "state": "Illinois",
      "country": "US",
      "elevation": 500,
      "lat": 41.3005981445,
      "lon": -88.635597229,
      "tz": "America\/Chicago"
  },
  "1LL4": {
      "icao": "1LL4",
      "iata": "",
      "name": "Sies Landing Area Airport",
      "city": "Litchfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 39.1833992004,
      "lon": -89.7001037598,
      "tz": "America\/Chicago"
  },
  "1LL6": {
      "icao": "1LL6",
      "iata": "",
      "name": "Janssen Airport",
      "city": "Shannon",
      "state": "Illinois",
      "country": "US",
      "elevation": 877,
      "lat": 42.228099823,
      "lon": -89.7381973267,
      "tz": "America\/Chicago"
  },
  "1LL7": {
      "icao": "1LL7",
      "iata": "",
      "name": "Edwin G. Bennett Airport",
      "city": "Sheffield",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 41.3498001099,
      "lon": -89.7732009888,
      "tz": "America\/Chicago"
  },
  "1LL8": {
      "icao": "1LL8",
      "iata": "",
      "name": "Zoomer Field",
      "city": "Sheldon",
      "state": "Illinois",
      "country": "US",
      "elevation": 680,
      "lat": 40.788898468,
      "lon": -87.5584030151,
      "tz": "America\/Chicago"
  },
  "1LS0": {
      "icao": "1LS0",
      "iata": "",
      "name": "Cal Mire Field",
      "city": "Gonzales",
      "state": "Louisiana",
      "country": "US",
      "elevation": 14,
      "lat": 30.2241001129,
      "lon": -90.8820037842,
      "tz": "America\/Chicago"
  },
  "1LS5": {
      "icao": "1LS5",
      "iata": "",
      "name": "Cameron Airstrip",
      "city": "Cameron",
      "state": "Louisiana",
      "country": "US",
      "elevation": 5,
      "lat": 29.8033008575,
      "lon": -93.334602356,
      "tz": "America\/Chicago"
  },
  "1LS8": {
      "icao": "1LS8",
      "iata": "",
      "name": "Summerell Airport",
      "city": "Ferriday",
      "state": "Louisiana",
      "country": "US",
      "elevation": 64,
      "lat": 31.682100296,
      "lon": -91.4987030029,
      "tz": "America\/Chicago"
  },
  "1MA5": {
      "icao": "1MA5",
      "iata": "",
      "name": "Unknown Field",
      "city": "Southborough",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 432,
      "lat": 42.2789993286,
      "lon": -71.5477981567,
      "tz": "America\/New_York"
  },
  "1MD1": {
      "icao": "1MD1",
      "iata": "",
      "name": "Big Oak Farm Airport",
      "city": "Cambridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 4,
      "lat": 38.5690002441,
      "lon": -76.2863006592,
      "tz": "America\/New_York"
  },
  "1MD3": {
      "icao": "1MD3",
      "iata": "",
      "name": "Krastel Farms Airport",
      "city": "Kennedyville",
      "state": "Maryland",
      "country": "US",
      "elevation": 75,
      "lat": 39.3089981079,
      "lon": -75.987701416,
      "tz": "America\/New_York"
  },
  "1MD8": {
      "icao": "1MD8",
      "iata": "",
      "name": "Mayberry Run Airport",
      "city": "Westminster\/Silver Run",
      "state": "Maryland",
      "country": "US",
      "elevation": 580,
      "lat": 39.6828994751,
      "lon": -77.0971984863,
      "tz": "America\/New_York"
  },
  "1MI1": {
      "icao": "1MI1",
      "iata": "",
      "name": "Nikkila Farms Airport",
      "city": "Mass",
      "state": "Michigan",
      "country": "US",
      "elevation": 1100,
      "lat": 46.7027015686,
      "lon": -88.9973983765,
      "tz": "America\/Detroit"
  },
  "1MI3": {
      "icao": "1MI3",
      "iata": "",
      "name": "Black River Ranch Airport",
      "city": "Onaway",
      "state": "Michigan",
      "country": "US",
      "elevation": 880,
      "lat": 45.1958007813,
      "lon": -84.3217010498,
      "tz": "America\/Detroit"
  },
  "1MI4": {
      "icao": "1MI4",
      "iata": "",
      "name": "Ramsy Farm Airport",
      "city": "Perronville",
      "state": "Michigan",
      "country": "US",
      "elevation": 880,
      "lat": 45.8389015198,
      "lon": -87.3224029541,
      "tz": "America\/Detroit"
  },
  "1MI5": {
      "icao": "1MI5",
      "iata": "",
      "name": "Mckenzie's Landing Airport",
      "city": "Howell",
      "state": "Michigan",
      "country": "US",
      "elevation": 955,
      "lat": 42.603099823,
      "lon": -83.859703064,
      "tz": "America\/Detroit"
  },
  "1MI6": {
      "icao": "1MI6",
      "iata": "",
      "name": "Rosedale Airport",
      "city": "Sault Ste Marie",
      "state": "Michigan",
      "country": "US",
      "elevation": 675,
      "lat": 46.3777999878,
      "lon": -84.3110961914,
      "tz": "America\/Detroit"
  },
  "1MI7": {
      "icao": "1MI7",
      "iata": "",
      "name": "Thompson Airport",
      "city": "Selkirk",
      "state": "Michigan",
      "country": "US",
      "elevation": 857,
      "lat": 44.3166999817,
      "lon": -84.0667037964,
      "tz": "America\/Detroit"
  },
  "1MI8": {
      "icao": "1MI8",
      "iata": "",
      "name": "Vlachos Acres Airport",
      "city": "Hubbard",
      "state": "Michigan",
      "country": "US",
      "elevation": 785,
      "lat": 44.846698761,
      "lon": -83.6369018555,
      "tz": "America\/Detroit"
  },
  "1MI9": {
      "icao": "1MI9",
      "iata": "",
      "name": "Southfork Airport",
      "city": "Marquette",
      "state": "Michigan",
      "country": "US",
      "elevation": 660,
      "lat": 46.4721984863,
      "lon": -87.3500976563,
      "tz": "America\/Detroit"
  },
  "1MN0": {
      "icao": "1MN0",
      "iata": "",
      "name": "Wetherbee Farm Airport",
      "city": "Tenney",
      "state": "Minnesota",
      "country": "US",
      "elevation": 980,
      "lat": 46.043598175,
      "lon": -96.5462036133,
      "tz": "America\/Chicago"
  },
  "1MN1": {
      "icao": "1MN1",
      "iata": "",
      "name": "Beyer Airport",
      "city": "Tenney",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 45.9733009338,
      "lon": -96.564201355,
      "tz": "America\/Chicago"
  },
  "1MN2": {
      "icao": "1MN2",
      "iata": "",
      "name": "Hay Acres Airport",
      "city": "Akiey",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1430,
      "lat": 45.9382019043,
      "lon": -94.7606964111,
      "tz": "America\/Chicago"
  },
  "1MN5": {
      "icao": "1MN5",
      "iata": "",
      "name": "Molnau Airpark",
      "city": "Waconia",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 44.8436012268,
      "lon": -93.7399978638,
      "tz": "America\/Chicago"
  },
  "1MN8": {
      "icao": "1MN8",
      "iata": "",
      "name": "Sky Harbor Residential Airpark",
      "city": "Webster",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1111,
      "lat": 44.5257987976,
      "lon": -93.3268966675,
      "tz": "America\/Chicago"
  },
  "1MO2": {
      "icao": "1MO2",
      "iata": "",
      "name": "Hart Airport",
      "city": "Freeman",
      "state": "Missouri",
      "country": "US",
      "elevation": 825,
      "lat": 38.5494995117,
      "lon": -94.476600647,
      "tz": "America\/Chicago"
  },
  "1MO3": {
      "icao": "1MO3",
      "iata": "",
      "name": "Lake Viking Airport",
      "city": "Gallatin",
      "state": "Missouri",
      "country": "US",
      "elevation": 940,
      "lat": 39.9333992004,
      "lon": -94.0737991333,
      "tz": "America\/Chicago"
  },
  "1MO4": {
      "icao": "1MO4",
      "iata": "",
      "name": "Longwood Mfg Corp Airport",
      "city": "Gallatin",
      "state": "Missouri",
      "country": "US",
      "elevation": 800,
      "lat": 39.8917007446,
      "lon": -93.8640975952,
      "tz": "America\/Chicago"
  },
  "1MO6": {
      "icao": "1MO6",
      "iata": "",
      "name": "Double S Ranch Airport",
      "city": "Grandin",
      "state": "Missouri",
      "country": "US",
      "elevation": 737,
      "lat": 36.8362007141,
      "lon": -90.7222976685,
      "tz": "America\/Chicago"
  },
  "1MO7": {
      "icao": "1MO7",
      "iata": "",
      "name": "Hines Airport",
      "city": "Gorin",
      "state": "Missouri",
      "country": "US",
      "elevation": 750,
      "lat": 40.363899231,
      "lon": -91.9751968384,
      "tz": "America\/Chicago"
  },
  "1MO8": {
      "icao": "1MO8",
      "iata": "",
      "name": "Sherlock Field",
      "city": "Gravois Mills",
      "state": "Missouri",
      "country": "US",
      "elevation": 800,
      "lat": 38.2527999878,
      "lon": -92.8030014038,
      "tz": "America\/Chicago"
  },
  "1MO9": {
      "icao": "1MO9",
      "iata": "",
      "name": "Eagle's Point & Red Barn Village Airpark",
      "city": "Greenfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1040,
      "lat": 37.4791984558,
      "lon": -93.851600647,
      "tz": "America\/Chicago"
  },
  "1MS0": {
      "icao": "1MS0",
      "iata": "",
      "name": "Tapley Airport",
      "city": "Shaw",
      "state": "Mississippi",
      "country": "US",
      "elevation": 129,
      "lat": 33.5998001099,
      "lon": -90.7811965942,
      "tz": "America\/Chicago"
  },
  "1MS2": {
      "icao": "1MS2",
      "iata": "",
      "name": "Peterson Airport",
      "city": "Goodman",
      "state": "Mississippi",
      "country": "US",
      "elevation": 240,
      "lat": 32.9417991638,
      "lon": -89.9306030273,
      "tz": "America\/Chicago"
  },
  "1MS6": {
      "icao": "1MS6",
      "iata": "",
      "name": "Eagles Ridge Airport",
      "city": "Hernando",
      "state": "Mississippi",
      "country": "US",
      "elevation": 262,
      "lat": 34.7972984314,
      "lon": -89.9131011963,
      "tz": "America\/Chicago"
  },
  "1MS8": {
      "icao": "1MS8",
      "iata": "",
      "name": "Columbus Air Force Base Aux Field Airfield",
      "city": "Shuqualak",
      "state": "Mississippi",
      "country": "US",
      "elevation": 260,
      "lat": 32.9401016235,
      "lon": -88.5792007446,
      "tz": "America\/Chicago"
  },
  "1MS9": {
      "icao": "1MS9",
      "iata": "",
      "name": "Piker-Too Airport",
      "city": "Centreville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 300,
      "lat": 31.011800766,
      "lon": -90.9682006836,
      "tz": "America\/Chicago"
  },
  "1MT0": {
      "icao": "1MT0",
      "iata": "",
      "name": "Nine Quarter Circle Ranch Airport",
      "city": "Gallatin Gateway",
      "state": "Montana",
      "country": "US",
      "elevation": 6974,
      "lat": 45.067401886,
      "lon": -111.2969970703,
      "tz": "America\/Denver"
  },
  "1MT2": {
      "icao": "1MT2",
      "iata": "",
      "name": "Skyrider Ultralightport",
      "city": "Billings",
      "state": "Montana",
      "country": "US",
      "elevation": 3540,
      "lat": 45.9430999756,
      "lon": -108.5490036011,
      "tz": "America\/Denver"
  },
  "1MT3": {
      "icao": "1MT3",
      "iata": "",
      "name": "Wood Strip",
      "city": "Elliston",
      "state": "Montana",
      "country": "US",
      "elevation": 5100,
      "lat": 46.5581016541,
      "lon": -112.4120025635,
      "tz": "America\/Denver"
  },
  "1MT4": {
      "icao": "1MT4",
      "iata": "",
      "name": "Davis Airport",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 3780,
      "lat": 46.6884994507,
      "lon": -111.8710021973,
      "tz": "America\/Denver"
  },
  "1MT6": {
      "icao": "1MT6",
      "iata": "",
      "name": "Bobcat Field",
      "city": "Philipsburg",
      "state": "Montana",
      "country": "US",
      "elevation": 4824,
      "lat": 46.3568992615,
      "lon": -113.5100021362,
      "tz": "America\/Denver"
  },
  "1MT7": {
      "icao": "1MT7",
      "iata": "",
      "name": "Prill Field",
      "city": "Sand Coulee",
      "state": "Montana",
      "country": "US",
      "elevation": 3420,
      "lat": 47.4211006165,
      "lon": -111.158996582,
      "tz": "America\/Denver"
  },
  "1MT9": {
      "icao": "1MT9",
      "iata": "",
      "name": "Wilcox Airport",
      "city": "Billings",
      "state": "Montana",
      "country": "US",
      "elevation": 3390,
      "lat": 45.7891998291,
      "lon": -108.6839981079,
      "tz": "America\/Denver"
  },
  "1MU0": {
      "icao": "1MU0",
      "iata": "",
      "name": "Medcalf Field",
      "city": "Republic",
      "state": "Missouri",
      "country": "US",
      "elevation": 1260,
      "lat": 37.1666984558,
      "lon": -93.5626983643,
      "tz": "America\/Chicago"
  },
  "1MU2": {
      "icao": "1MU2",
      "iata": "",
      "name": "Smitty's Landing Airport",
      "city": "Ridgely",
      "state": "Missouri",
      "country": "US",
      "elevation": 800,
      "lat": 39.4473991394,
      "lon": -94.6671981812,
      "tz": "America\/Chicago"
  },
  "1MU4": {
      "icao": "1MU4",
      "iata": "",
      "name": "Short Air Airport",
      "city": "Warrensburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 840,
      "lat": 38.6516990662,
      "lon": -93.7788009644,
      "tz": "America\/Chicago"
  },
  "1MU6": {
      "icao": "1MU6",
      "iata": "",
      "name": "Simerly Airport",
      "city": "Fillmore",
      "state": "Missouri",
      "country": "US",
      "elevation": 971,
      "lat": 40.1108016968,
      "lon": -94.9689025879,
      "tz": "America\/Chicago"
  },
  "1MU7": {
      "icao": "1MU7",
      "iata": "",
      "name": "Mooseberry Airport",
      "city": "Jackson",
      "state": "Missouri",
      "country": "US",
      "elevation": 480,
      "lat": 37.4653015137,
      "lon": -89.6119995117,
      "tz": "America\/Chicago"
  },
  "1MU8": {
      "icao": "1MU8",
      "iata": "",
      "name": "Church's Landing Airport",
      "city": "Rushville",
      "state": "Missouri",
      "country": "US",
      "elevation": 826,
      "lat": 39.5541992188,
      "lon": -94.9985961914,
      "tz": "America\/Chicago"
  },
  "1MY1": {
      "icao": "1MY1",
      "iata": "",
      "name": "Carlson Agricultural Airport",
      "city": "Wendell",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1033,
      "lat": 45.9852981567,
      "lon": -96.1943969727,
      "tz": "America\/Chicago"
  },
  "1NA0": {
      "icao": "1NA0",
      "iata": "",
      "name": "Bohn Airstrip",
      "city": "Mountain",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1142,
      "lat": 48.7322006226,
      "lon": -97.889503479,
      "tz": "America\/Chicago"
  },
  "1NA5": {
      "icao": "1NA5",
      "iata": "",
      "name": "Gienger\/Box Bar Ranch Airport",
      "city": "Streeter",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1950,
      "lat": 46.625,
      "lon": -99.4504013062,
      "tz": "America\/Chicago"
  },
  "1NA8": {
      "icao": "1NA8",
      "iata": "",
      "name": "Preszler Airstrip",
      "city": "Braddock",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1795,
      "lat": 46.6904983521,
      "lon": -100.0950012207,
      "tz": "America\/Chicago"
  },
  "1NA9": {
      "icao": "1NA9",
      "iata": "",
      "name": "Myran Airstrip",
      "city": "Taylor",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2120,
      "lat": 47.0332984924,
      "lon": -102.4960021973,
      "tz": "America\/Denver"
  },
  "1NC0": {
      "icao": "1NC0",
      "iata": "",
      "name": "Delk's Airport",
      "city": "Asheboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 460,
      "lat": 35.7220993042,
      "lon": -79.9413986206,
      "tz": "America\/New_York"
  },
  "1NC1": {
      "icao": "1NC1",
      "iata": "",
      "name": "Ron's Ultralight Field",
      "city": "Pittsboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 525,
      "lat": 35.8119010925,
      "lon": -79.2386016846,
      "tz": "America\/New_York"
  },
  "1NC2": {
      "icao": "1NC2",
      "iata": "",
      "name": "Atwell Airport",
      "city": "Mooresville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 830,
      "lat": 35.6547012329,
      "lon": -80.7893981934,
      "tz": "America\/New_York"
  },
  "1NC3": {
      "icao": "1NC3",
      "iata": "",
      "name": "Fletcher's Airport",
      "city": "Elizabeth City",
      "state": "North Carolina",
      "country": "US",
      "elevation": 7,
      "lat": 36.1806983948,
      "lon": -76.1491012573,
      "tz": "America\/New_York"
  },
  "1NC4": {
      "icao": "1NC4",
      "iata": "",
      "name": "Bell Strip",
      "city": "Pollocksville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 35,
      "lat": 34.9953994751,
      "lon": -77.2576980591,
      "tz": "America\/New_York"
  },
  "1NC5": {
      "icao": "1NC5",
      "iata": "",
      "name": "Riley Field",
      "city": "Bunn",
      "state": "North Carolina",
      "country": "US",
      "elevation": 360,
      "lat": 35.9332008362,
      "lon": -78.3486022949,
      "tz": "America\/New_York"
  },
  "1NC6": {
      "icao": "1NC6",
      "iata": "",
      "name": "Arant Airport",
      "city": "Wingate",
      "state": "North Carolina",
      "country": "US",
      "elevation": 535,
      "lat": 35.0559997559,
      "lon": -80.4501037598,
      "tz": "America\/New_York"
  },
  "1NC8": {
      "icao": "1NC8",
      "iata": "",
      "name": "Lonesome Field",
      "city": "Richfield",
      "state": "North Carolina",
      "country": "US",
      "elevation": 665,
      "lat": 35.5158004761,
      "lon": -80.258102417,
      "tz": "America\/New_York"
  },
  "1NC9": {
      "icao": "1NC9",
      "iata": "",
      "name": "Northbrook International Ultraport Ultralightport",
      "city": "Cherryville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1030,
      "lat": 35.4496994019,
      "lon": -81.426399231,
      "tz": "America\/New_York"
  },
  "1ND3": {
      "icao": "1ND3",
      "iata": "",
      "name": "Andvik Airport",
      "city": "Kindred",
      "state": "North Dakota",
      "country": "US",
      "elevation": 980,
      "lat": 46.6477012634,
      "lon": -97.1305999756,
      "tz": "America\/Chicago"
  },
  "1ND4": {
      "icao": "1ND4",
      "iata": "",
      "name": "Walkinshaw Airport",
      "city": "Argusville",
      "state": "North Dakota",
      "country": "US",
      "elevation": 900,
      "lat": 47.0583000183,
      "lon": -97.016998291,
      "tz": "America\/Chicago"
  },
  "1ND7": {
      "icao": "1ND7",
      "iata": "",
      "name": "Dittmer Airport",
      "city": "Lynchburg",
      "state": "North Dakota",
      "country": "US",
      "elevation": 943,
      "lat": 46.8291015625,
      "lon": -97.266998291,
      "tz": "America\/Chicago"
  },
  "1NE0": {
      "icao": "1NE0",
      "iata": "",
      "name": "Higgins Bros Airport",
      "city": "Brownlee",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2860,
      "lat": 42.3166999817,
      "lon": -100.4169998169,
      "tz": "America\/Chicago"
  },
  "1NE1": {
      "icao": "1NE1",
      "iata": "",
      "name": "Paul Ridder Ranch Airport",
      "city": "Callaway",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2780,
      "lat": 41.28329849,
      "lon": -100.0500031,
      "tz": "America\/Chicago"
  },
  "1NE2": {
      "icao": "1NE2",
      "iata": "",
      "name": "Witthuhn Airport",
      "city": "Callaway",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2700,
      "lat": 41.3250007629,
      "lon": -99.917098999,
      "tz": "America\/Chicago"
  },
  "1NE5": {
      "icao": "1NE5",
      "iata": "",
      "name": "Sibbernsen Airport",
      "city": "Washington",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1280,
      "lat": 41.4061012268,
      "lon": -96.2855987549,
      "tz": "America\/Chicago"
  },
  "1NE6": {
      "icao": "1NE6",
      "iata": "",
      "name": "Miller Airstrip",
      "city": "Dalton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4100,
      "lat": 41.340801239,
      "lon": -102.7779998779,
      "tz": "America\/Denver"
  },
  "1NK0": {
      "icao": "1NK0",
      "iata": "",
      "name": "Roxbury Runway Airport",
      "city": "Roxbury",
      "state": "New York",
      "country": "US",
      "elevation": 1600,
      "lat": 42.2972984314,
      "lon": -74.5482025146,
      "tz": "America\/New_York"
  },
  "1NK5": {
      "icao": "1NK5",
      "iata": "",
      "name": "Westtown Airport",
      "city": "Westtown",
      "state": "New York",
      "country": "US",
      "elevation": 600,
      "lat": 41.3614997864,
      "lon": -74.5242996216,
      "tz": "America\/New_York"
  },
  "1NK6": {
      "icao": "1NK6",
      "iata": "",
      "name": "Catalano Airfield",
      "city": "Wilmington",
      "state": "New York",
      "country": "US",
      "elevation": 840,
      "lat": 44.4236984253,
      "lon": -73.7496032715,
      "tz": "America\/New_York"
  },
  "1NK7": {
      "icao": "1NK7",
      "iata": "",
      "name": "Boonville Inc Airport",
      "city": "Boonville",
      "state": "New York",
      "country": "US",
      "elevation": 1200,
      "lat": 43.4667015076,
      "lon": -75.2496032715,
      "tz": "America\/New_York"
  },
  "1NK8": {
      "icao": "1NK8",
      "iata": "",
      "name": "Chenango Bridge Airport",
      "city": "Binghamton",
      "state": "New York",
      "country": "US",
      "elevation": 940,
      "lat": 42.1916999817,
      "lon": -75.8413009644,
      "tz": "America\/New_York"
  },
  "1NM0": {
      "icao": "1NM0",
      "iata": "",
      "name": "Me-Own Airport",
      "city": "Silver City",
      "state": "New Mexico",
      "country": "US",
      "elevation": 7554,
      "lat": 33.2120018005,
      "lon": -108.0260009766,
      "tz": "America\/Denver"
  },
  "1NV1": {
      "icao": "1NV1",
      "iata": "",
      "name": "Fallon Southwest Airpark",
      "city": "Fallon",
      "state": "Nevada",
      "country": "US",
      "elevation": 3950,
      "lat": 39.4152984619,
      "lon": -118.8369979858,
      "tz": "America\/Los_Angeles"
  },
  "1NY2": {
      "icao": "1NY2",
      "iata": "",
      "name": "Kidder Field",
      "city": "Pulaski",
      "state": "New York",
      "country": "US",
      "elevation": 360,
      "lat": 43.6166992188,
      "lon": -76.1626968384,
      "tz": "America\/New_York"
  },
  "1NY3": {
      "icao": "1NY3",
      "iata": "",
      "name": "Richland Airport",
      "city": "Richland",
      "state": "New York",
      "country": "US",
      "elevation": 560,
      "lat": 43.5666999817,
      "lon": -76.0329971313,
      "tz": "America\/New_York"
  },
  "1NY4": {
      "icao": "1NY4",
      "iata": "",
      "name": "High Banks Farm Landing Area Airport",
      "city": "Saranac",
      "state": "New York",
      "country": "US",
      "elevation": 1520,
      "lat": 44.6175003052,
      "lon": -73.9110031128,
      "tz": "America\/New_York"
  },
  "1NY5": {
      "icao": "1NY5",
      "iata": "",
      "name": "Sherman Field",
      "city": "Poughkeepsie",
      "state": "New York",
      "country": "US",
      "elevation": 625,
      "lat": 41.638999939,
      "lon": -73.7428970337,
      "tz": "America\/New_York"
  },
  "1NY7": {
      "icao": "1NY7",
      "iata": "",
      "name": "Minard Farms Airport",
      "city": "Clinton Dale",
      "state": "New York",
      "country": "US",
      "elevation": 450,
      "lat": 41.7084007263,
      "lon": -74.0617980957,
      "tz": "America\/New_York"
  },
  "1NY8": {
      "icao": "1NY8",
      "iata": "",
      "name": "Killian Airfield",
      "city": "Scipio",
      "state": "New York",
      "country": "US",
      "elevation": 1020,
      "lat": 42.8000984192,
      "lon": -76.5830001831,
      "tz": "America\/New_York"
  },
  "1NY9": {
      "icao": "1NY9",
      "iata": "",
      "name": "Caughdenoy Airport",
      "city": "Caughdenoy",
      "state": "New York",
      "country": "US",
      "elevation": 400,
      "lat": 43.2658996582,
      "lon": -76.18800354,
      "tz": "America\/New_York"
  },
  "1OA1": {
      "icao": "1OA1",
      "iata": "",
      "name": "Bashore Airport",
      "city": "Pleasant Hill",
      "state": "Ohio",
      "country": "US",
      "elevation": 940,
      "lat": 40.0458984375,
      "lon": -84.3343963623,
      "tz": "America\/New_York"
  },
  "1OA2": {
      "icao": "1OA2",
      "iata": "",
      "name": "Mole Airport",
      "city": "Grafton",
      "state": "Ohio",
      "country": "US",
      "elevation": 854,
      "lat": 41.2616996765,
      "lon": -81.9906997681,
      "tz": "America\/New_York"
  },
  "1OA3": {
      "icao": "1OA3",
      "iata": "",
      "name": "Barnett Airpark",
      "city": "Blanchester",
      "state": "Ohio",
      "country": "US",
      "elevation": 1010,
      "lat": 39.3334007263,
      "lon": -83.8554992676,
      "tz": "America\/New_York"
  },
  "1OA4": {
      "icao": "1OA4",
      "iata": "",
      "name": "Kepes Flying Field",
      "city": "Springfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1080,
      "lat": 39.9235992432,
      "lon": -83.6616973877,
      "tz": "America\/New_York"
  },
  "1OA5": {
      "icao": "1OA5",
      "iata": "",
      "name": "Double J Airport",
      "city": "Fayetteville",
      "state": "Ohio",
      "country": "US",
      "elevation": 972,
      "lat": 39.2195014954,
      "lon": -83.9526977539,
      "tz": "America\/New_York"
  },
  "1OA6": {
      "icao": "1OA6",
      "iata": "",
      "name": "Frith Airport",
      "city": "Morrow",
      "state": "Ohio",
      "country": "US",
      "elevation": 910,
      "lat": 39.3362007141,
      "lon": -84.0457992554,
      "tz": "America\/New_York"
  },
  "1OA7": {
      "icao": "1OA7",
      "iata": "",
      "name": "Yoder Airport",
      "city": "Louisville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1149,
      "lat": 40.8417015076,
      "lon": -81.2415008545,
      "tz": "America\/New_York"
  },
  "1OA8": {
      "icao": "1OA8",
      "iata": "",
      "name": "Erdy Farm Airport",
      "city": "Kilbourne",
      "state": "Ohio",
      "country": "US",
      "elevation": 981,
      "lat": 40.346698761,
      "lon": -82.9044036865,
      "tz": "America\/New_York"
  },
  "1OH1": {
      "icao": "1OH1",
      "iata": "",
      "name": "S and S Field",
      "city": "Quincy",
      "state": "Ohio",
      "country": "US",
      "elevation": 1057,
      "lat": 40.301399231,
      "lon": -83.9760971069,
      "tz": "America\/New_York"
  },
  "1OH2": {
      "icao": "1OH2",
      "iata": "",
      "name": "Dougherty Airport",
      "city": "Bellevue",
      "state": "Ohio",
      "country": "US",
      "elevation": 807,
      "lat": 41.2439994812,
      "lon": -82.8663024902,
      "tz": "America\/New_York"
  },
  "1OH3": {
      "icao": "1OH3",
      "iata": "",
      "name": "Mather Field",
      "city": "Castalia",
      "state": "Ohio",
      "country": "US",
      "elevation": 640,
      "lat": 41.3891983032,
      "lon": -82.7627029419,
      "tz": "America\/New_York"
  },
  "1OH4": {
      "icao": "1OH4",
      "iata": "",
      "name": "Fortman Airport",
      "city": "St Marys",
      "state": "Ohio",
      "country": "US",
      "elevation": 885,
      "lat": 40.5553016663,
      "lon": -84.3865966797,
      "tz": "America\/New_York"
  },
  "1OH7": {
      "icao": "1OH7",
      "iata": "",
      "name": "Derry Landing Strip",
      "city": "Sonora",
      "state": "Ohio",
      "country": "US",
      "elevation": 905,
      "lat": 39.9939994812,
      "lon": -81.8637008667,
      "tz": "America\/New_York"
  },
  "1OH8": {
      "icao": "1OH8",
      "iata": "",
      "name": "Lisbon Airfield",
      "city": "South Charleston",
      "state": "Ohio",
      "country": "US",
      "elevation": 1160,
      "lat": 39.8666992188,
      "lon": -83.6333007813,
      "tz": "America\/New_York"
  },
  "1OH9": {
      "icao": "1OH9",
      "iata": "",
      "name": "Lewis Airport",
      "city": "Peebles",
      "state": "Ohio",
      "country": "US",
      "elevation": 862,
      "lat": 38.9351005554,
      "lon": -83.3993988037,
      "tz": "America\/New_York"
  },
  "1OI1": {
      "icao": "1OI1",
      "iata": "",
      "name": "Pauls Airport",
      "city": "Middlefield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1191,
      "lat": 41.4283981323,
      "lon": -81.0640029907,
      "tz": "America\/New_York"
  },
  "1OI6": {
      "icao": "1OI6",
      "iata": "",
      "name": "Stone Airport",
      "city": "New Lebanon",
      "state": "Ohio",
      "country": "US",
      "elevation": 978,
      "lat": 39.7700996399,
      "lon": -84.4166030884,
      "tz": "America\/New_York"
  },
  "1OI7": {
      "icao": "1OI7",
      "iata": "",
      "name": "Knight Airport",
      "city": "Norwalk",
      "state": "Ohio",
      "country": "US",
      "elevation": 925,
      "lat": 41.1584014893,
      "lon": -82.5665969849,
      "tz": "America\/New_York"
  },
  "1OI9": {
      "icao": "1OI9",
      "iata": "",
      "name": "Mc Colloch's Airport",
      "city": "Piqua",
      "state": "Ohio",
      "country": "US",
      "elevation": 990,
      "lat": 40.1772994995,
      "lon": -84.2944030762,
      "tz": "America\/New_York"
  },
  "1OK0": {
      "icao": "1OK0",
      "iata": "",
      "name": "Neversweat Airport",
      "city": "Bristow",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 775,
      "lat": 35.8945007324,
      "lon": -96.2794036865,
      "tz": "America\/Chicago"
  },
  "1OK1": {
      "icao": "1OK1",
      "iata": "",
      "name": "Dave's Place Airport",
      "city": "Kingfisher",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1160,
      "lat": 35.8250007629,
      "lon": -97.8059005737,
      "tz": "America\/Chicago"
  },
  "1OK2": {
      "icao": "1OK2",
      "iata": "",
      "name": "Wyatt Airport",
      "city": "Goldsby",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1163,
      "lat": 35.0987014771,
      "lon": -97.4698028564,
      "tz": "America\/Chicago"
  },
  "1OK3": {
      "icao": "1OK3",
      "iata": "",
      "name": "Wolfe Field Airport",
      "city": "Bray",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1194,
      "lat": 34.5760993958,
      "lon": -97.8141021729,
      "tz": "America\/Chicago"
  },
  "1OK5": {
      "icao": "1OK5",
      "iata": "",
      "name": "Hohman Airport",
      "city": "Lone Wolf",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1563,
      "lat": 34.9833984375,
      "lon": -99.2294998169,
      "tz": "America\/Chicago"
  },
  "1OK6": {
      "icao": "1OK6",
      "iata": "",
      "name": "Stamper Ranch Airport",
      "city": "Luther",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1020,
      "lat": 35.7219009399,
      "lon": -97.2046966553,
      "tz": "America\/Chicago"
  },
  "1OK7": {
      "icao": "1OK7",
      "iata": "",
      "name": "Grimes Airport",
      "city": "Maysville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1035,
      "lat": 34.8000984192,
      "lon": -97.4822006226,
      "tz": "America\/Chicago"
  },
  "1OK8": {
      "icao": "1OK8",
      "iata": "",
      "name": "5B Ranch Airport",
      "city": "Edmond",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1085,
      "lat": 35.729801178,
      "lon": -97.5413970947,
      "tz": "America\/Chicago"
  },
  "1OK9": {
      "icao": "1OK9",
      "iata": "",
      "name": "Hankins Airport",
      "city": "Marietta",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 843,
      "lat": 33.9751014709,
      "lon": -97.0892028809,
      "tz": "America\/Chicago"
  },
  "1OL2": {
      "icao": "1OL2",
      "iata": "",
      "name": "Steciak Strip",
      "city": "Harrah",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 35.4889984131,
      "lon": -97.1920013428,
      "tz": "America\/Chicago"
  },
  "1OR0": {
      "icao": "1OR0",
      "iata": "",
      "name": "Sunnyhill Airport",
      "city": "North Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 320,
      "lat": 43.4832000732,
      "lon": -124.2030029297,
      "tz": "America\/Los_Angeles"
  },
  "1OR3": {
      "icao": "1OR3",
      "iata": "",
      "name": "Sunset Air Strip",
      "city": "North Plains",
      "state": "Oregon",
      "country": "US",
      "elevation": 207,
      "lat": 45.5914993286,
      "lon": -123.0100021362,
      "tz": "America\/Los_Angeles"
  },
  "1OR4": {
      "icao": "1OR4",
      "iata": "",
      "name": "North Plains Gliderport",
      "city": "North Plains",
      "state": "Oregon",
      "country": "US",
      "elevation": 210,
      "lat": 45.6040000916,
      "lon": -123.0250015259,
      "tz": "America\/Los_Angeles"
  },
  "1OR5": {
      "icao": "1OR5",
      "iata": "",
      "name": "Reed Airport",
      "city": "Olex",
      "state": "Oregon",
      "country": "US",
      "elevation": 1550,
      "lat": 45.4874000549,
      "lon": -120.2259979248,
      "tz": "America\/Los_Angeles"
  },
  "1OR6": {
      "icao": "1OR6",
      "iata": "",
      "name": "Clackamas Heights Airport",
      "city": "Oregon City",
      "state": "Oregon",
      "country": "US",
      "elevation": 545,
      "lat": 45.3713989258,
      "lon": -122.5540008545,
      "tz": "America\/Los_Angeles"
  },
  "1OR7": {
      "icao": "1OR7",
      "iata": "",
      "name": "Skyhill Airport",
      "city": "Oregon City",
      "state": "Oregon",
      "country": "US",
      "elevation": 735,
      "lat": 45.2878990173,
      "lon": -122.4560012817,
      "tz": "America\/Los_Angeles"
  },
  "1PA0": {
      "icao": "1PA0",
      "iata": "",
      "name": "Hinaman Acres Airport",
      "city": "Jersey Shore",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 800,
      "lat": 41.1333999634,
      "lon": -77.1996994019,
      "tz": "America\/New_York"
  },
  "1PA3": {
      "icao": "1PA3",
      "iata": "",
      "name": "Ridgeview Airport",
      "city": "Kellers Church",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 520,
      "lat": 40.4500999451,
      "lon": -75.1996002197,
      "tz": "America\/New_York"
  },
  "1PA4": {
      "icao": "1PA4",
      "iata": "",
      "name": "Wagner Airport",
      "city": "Lewistown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 520,
      "lat": 40.5526008606,
      "lon": -77.6200027466,
      "tz": "America\/New_York"
  },
  "1PA5": {
      "icao": "1PA5",
      "iata": "",
      "name": "Beaver Creek Ultralightport",
      "city": "New Galilee",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 928,
      "lat": 40.830244,
      "lon": -80.413678,
      "tz": "America\/New_York"
  },
  "1PA6": {
      "icao": "1PA6",
      "iata": "",
      "name": "Whittle Airport",
      "city": "Kennett Square",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 345,
      "lat": 39.8273010254,
      "lon": -75.7235031128,
      "tz": "America\/New_York"
  },
  "1PA9": {
      "icao": "1PA9",
      "iata": "",
      "name": "Schadels Airport",
      "city": "Klingerstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 700,
      "lat": 40.6668014526,
      "lon": -76.6829986572,
      "tz": "America\/New_York"
  },
  "1PN0": {
      "icao": "1PN0",
      "iata": "",
      "name": "Bentley Airport",
      "city": "Pottstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 690,
      "lat": 40.2014007568,
      "lon": -75.7142028809,
      "tz": "America\/New_York"
  },
  "1PN1": {
      "icao": "1PN1",
      "iata": "",
      "name": "Napodano Airport",
      "city": "Transfer",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1060,
      "lat": 41.3342018127,
      "lon": -80.4206008911,
      "tz": "America\/New_York"
  },
  "1PS0": {
      "icao": "1PS0",
      "iata": "",
      "name": "Ickes Airport",
      "city": "Osterburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1320,
      "lat": 40.1918983459,
      "lon": -78.5646972656,
      "tz": "America\/New_York"
  },
  "1PS4": {
      "icao": "1PS4",
      "iata": "",
      "name": "Sankey Airport",
      "city": "Osceola Mills",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1620,
      "lat": 40.8609008789,
      "lon": -78.258102417,
      "tz": "America\/New_York"
  },
  "1PS9": {
      "icao": "1PS9",
      "iata": "",
      "name": "Wicker & Wings Aerodrome",
      "city": "Trumbauersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 590,
      "lat": 40.4034004211,
      "lon": -75.3704986572,
      "tz": "America\/New_York"
  },
      "1S2": {
      "icao": "1S2",
      "iata": "",
      "name": "Darrington Municipal Airport",
      "city": "Darrington",
      "state": "Washington",
      "country": "US",
      "elevation": 553,
      "lat": 48.2586125,
      "lon": -121.6102036,
      "tz": "America\/Los_Angeles"
  },
  "1SC2": {
      "icao": "1SC2",
      "iata": "",
      "name": "Gwinn Field",
      "city": "Hopkins",
      "state": "South Carolina",
      "country": "US",
      "elevation": 390,
      "lat": 33.9337997437,
      "lon": -80.7960968018,
      "tz": "America\/New_York"
  },
  "1SD0": {
      "icao": "1SD0",
      "iata": "",
      "name": "Dorsey Ranch Airport",
      "city": "Glad Valley",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2350,
      "lat": 45.3230018616,
      "lon": -101.7419967651,
      "tz": "America\/Denver"
  },
  "1SD1": {
      "icao": "1SD1",
      "iata": "",
      "name": "Burke Field",
      "city": "Scotland",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1352,
      "lat": 43.1654014587,
      "lon": -97.7309036255,
      "tz": "America\/Chicago"
  },
  "1SD3": {
      "icao": "1SD3",
      "iata": "",
      "name": "Turkey Ridge Airport",
      "city": "Hurley",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1325,
      "lat": 43.2332992554,
      "lon": -97.1502990723,
      "tz": "America\/Chicago"
  },
  "1SD4": {
      "icao": "1SD4",
      "iata": "",
      "name": "Vig Limousin Airport",
      "city": "Faith",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2552,
      "lat": 44.8666992188,
      "lon": -102.4169998169,
      "tz": "America\/Denver"
  },
  "1SD5": {
      "icao": "1SD5",
      "iata": "",
      "name": "Drake Farm Airport",
      "city": "White Lake",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1659,
      "lat": 43.7686004639,
      "lon": -98.7986984253,
      "tz": "America\/Chicago"
  },
  "1SD6": {
      "icao": "1SD6",
      "iata": "",
      "name": "Bogner No Ii Airport",
      "city": "Oelrich's",
      "state": "South Dakota",
      "country": "US",
      "elevation": 3500,
      "lat": 43.1102981567,
      "lon": -103.2300033569,
      "tz": "America\/Denver"
  },
  "1SD8": {
      "icao": "1SD8",
      "iata": "",
      "name": "Tribitt Airport",
      "city": "Albee",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1175,
      "lat": 45.0476989746,
      "lon": -96.5525970459,
      "tz": "America\/Chicago"
  },
  "1TA2": {
      "icao": "1TA2",
      "iata": "",
      "name": "Vigo Park Airport",
      "city": "Tulia",
      "state": "Texas",
      "country": "US",
      "elevation": 3383,
      "lat": 34.650100708,
      "lon": -101.5,
      "tz": "America\/Chicago"
  },
  "1TA4": {
      "icao": "1TA4",
      "iata": "",
      "name": "Last Resort Airport",
      "city": "Crockett",
      "state": "Texas",
      "country": "US",
      "elevation": 420,
      "lat": 31.4584999084,
      "lon": -95.2960968018,
      "tz": "America\/Chicago"
  },
  "1TA5": {
      "icao": "1TA5",
      "iata": "",
      "name": "Two Leggs Airport",
      "city": "Denver City",
      "state": "Texas",
      "country": "US",
      "elevation": 3625,
      "lat": 32.9417991638,
      "lon": -103,
      "tz": "America\/Chicago"
  },
  "1TA7": {
      "icao": "1TA7",
      "iata": "",
      "name": "Thompson Field",
      "city": "Canton",
      "state": "Texas",
      "country": "US",
      "elevation": 515,
      "lat": 32.6160011292,
      "lon": -95.8683013916,
      "tz": "America\/Chicago"
  },
  "1TE0": {
      "icao": "1TE0",
      "iata": "",
      "name": "Locker Brothers Airport",
      "city": "Muleshoe",
      "state": "Texas",
      "country": "US",
      "elevation": 3802,
      "lat": 34.2695007324,
      "lon": -102.7170028687,
      "tz": "America\/Chicago"
  },
  "1TE1": {
      "icao": "1TE1",
      "iata": "",
      "name": "Briscoes Catarina Ranch Airport",
      "city": "Catarina",
      "state": "Texas",
      "country": "US",
      "elevation": 650,
      "lat": 28.2502994537,
      "lon": -99.8170013428,
      "tz": "America\/Chicago"
  },
  "1TE2": {
      "icao": "1TE2",
      "iata": "",
      "name": "Flying F Ranch Airport",
      "city": "Crosby",
      "state": "Texas",
      "country": "US",
      "elevation": 65,
      "lat": 29.9862995148,
      "lon": -95.0168991089,
      "tz": "America\/Chicago"
  },
  "1TE3": {
      "icao": "1TE3",
      "iata": "",
      "name": "Bolton Airport",
      "city": "Jacksonville",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 31.9174003601,
      "lon": -95.2071990967,
      "tz": "America\/Chicago"
  },
  "1TE4": {
      "icao": "1TE4",
      "iata": "",
      "name": "Zuehl Airport",
      "city": "Marion",
      "state": "Texas",
      "country": "US",
      "elevation": 592,
      "lat": 29.4955005646,
      "lon": -98.1577987671,
      "tz": "America\/Chicago"
  },
  "1TE5": {
      "icao": "1TE5",
      "iata": "",
      "name": "Corpora Airport",
      "city": "Hearne",
      "state": "Texas",
      "country": "US",
      "elevation": 270,
      "lat": 30.8162994385,
      "lon": -96.6010971069,
      "tz": "America\/Chicago"
  },
  "1TE6": {
      "icao": "1TE6",
      "iata": "",
      "name": "Moore Airport",
      "city": "Wildorado",
      "state": "Texas",
      "country": "US",
      "elevation": 3850,
      "lat": 35.1180992126,
      "lon": -102.1849975586,
      "tz": "America\/Chicago"
  },
  "1TE7": {
      "icao": "1TE7",
      "iata": "",
      "name": "Ray Farm Airport",
      "city": "Floresville",
      "state": "Texas",
      "country": "US",
      "elevation": 560,
      "lat": 29.121099472,
      "lon": -98.3656005859,
      "tz": "America\/Chicago"
  },
  "1TE8": {
      "icao": "1TE8",
      "iata": "",
      "name": "Kahuna Bay Airport",
      "city": "Howe",
      "state": "Texas",
      "country": "US",
      "elevation": 702,
      "lat": 33.5489997864,
      "lon": -96.5582962036,
      "tz": "America\/Chicago"
  },
  "1TE9": {
      "icao": "1TE9",
      "iata": "",
      "name": "Rmr Ranch Airport",
      "city": "Franklin",
      "state": "Texas",
      "country": "US",
      "elevation": 420,
      "lat": 31.271900177,
      "lon": -96.439201355,
      "tz": "America\/Chicago"
  },
  "1TN0": {
      "icao": "1TN0",
      "iata": "",
      "name": "Cedar Crest Field",
      "city": "Lebanon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 540,
      "lat": 36.2463989258,
      "lon": -86.3028030396,
      "tz": "America\/Chicago"
  },
  "1TN2": {
      "icao": "1TN2",
      "iata": "",
      "name": "Kay Airport",
      "city": "Rogersville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1250,
      "lat": 36.4458999634,
      "lon": -83.1123962402,
      "tz": "America\/New_York"
  },
  "1TN3": {
      "icao": "1TN3",
      "iata": "",
      "name": "Wagner Field",
      "city": "Belvidere",
      "state": "Tennessee",
      "country": "US",
      "elevation": 975,
      "lat": 35.1397018433,
      "lon": -86.2221984863,
      "tz": "America\/Chicago"
  },
  "1TN6": {
      "icao": "1TN6",
      "iata": "",
      "name": "Higdon Airfield",
      "city": "Karns",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1000,
      "lat": 35.9725990295,
      "lon": -84.1568984985,
      "tz": "America\/New_York"
  },
  "1TS0": {
      "icao": "1TS0",
      "iata": "",
      "name": "Eagle Air Airport",
      "city": "Oak Island",
      "state": "Texas",
      "country": "US",
      "elevation": 8,
      "lat": 29.6704998016,
      "lon": -94.6707992554,
      "tz": "America\/Chicago"
  },
  "1TS1": {
      "icao": "1TS1",
      "iata": "",
      "name": "Laas Farm Airport",
      "city": "Pattison",
      "state": "Texas",
      "country": "US",
      "elevation": 165,
      "lat": 29.9127006531,
      "lon": -95.994102478,
      "tz": "America\/Chicago"
  },
  "1TS2": {
      "icao": "1TS2",
      "iata": "",
      "name": "Bar 16 Airport",
      "city": "Hubbard",
      "state": "Texas",
      "country": "US",
      "elevation": 547,
      "lat": 31.824300766,
      "lon": -96.778503418,
      "tz": "America\/Chicago"
  },
  "1TS3": {
      "icao": "1TS3",
      "iata": "",
      "name": "B & S Ultralightport",
      "city": "San Leon",
      "state": "Texas",
      "country": "US",
      "elevation": 16,
      "lat": 29.496099472,
      "lon": -94.9505004883,
      "tz": "America\/Chicago"
  },
  "1TS6": {
      "icao": "1TS6",
      "iata": "",
      "name": "Dentonio Ranch Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 825,
      "lat": 28.2693996429,
      "lon": -99.9345016479,
      "tz": "America\/Chicago"
  },
  "1TS8": {
      "icao": "1TS8",
      "iata": "",
      "name": "Landers Ranch Airport",
      "city": "Colorado City",
      "state": "Texas",
      "country": "US",
      "elevation": 2275,
      "lat": 32.2876014709,
      "lon": -100.7220001221,
      "tz": "America\/Chicago"
  },
  "1TS9": {
      "icao": "1TS9",
      "iata": "",
      "name": "Four Winds Airport",
      "city": "Celina",
      "state": "Texas",
      "country": "US",
      "elevation": 775,
      "lat": 33.368056,
      "lon": -96.752778,
      "tz": "America\/Chicago"
  },
  "1TX0": {
      "icao": "1TX0",
      "iata": "",
      "name": "Lytle Airpark",
      "city": "Lytle",
      "state": "Texas",
      "country": "US",
      "elevation": 780,
      "lat": 29.2533340454,
      "lon": -98.861114502,
      "tz": "America\/Chicago"
  },
  "1TX1": {
      "icao": "1TX1",
      "iata": "",
      "name": "Yoakum Airport",
      "city": "Gustine",
      "state": "Texas",
      "country": "US",
      "elevation": 1130,
      "lat": 31.8556995392,
      "lon": -98.4141998291,
      "tz": "America\/Chicago"
  },
  "1TX2": {
      "icao": "1TX2",
      "iata": "",
      "name": "J Y Ranch-R B Masterson Iii Estate Airport",
      "city": "Guthrie",
      "state": "Texas",
      "country": "US",
      "elevation": 1808,
      "lat": 33.7168006897,
      "lon": -100.1500015259,
      "tz": "America\/Chicago"
  },
  "1TX3": {
      "icao": "1TX3",
      "iata": "",
      "name": "Beggs Ranch Airport",
      "city": "Guthrie",
      "state": "Texas",
      "country": "US",
      "elevation": 1870,
      "lat": 33.4250984192,
      "lon": -100.5419998169,
      "tz": "America\/Chicago"
  },
  "1TX4": {
      "icao": "1TX4",
      "iata": "",
      "name": "Shoreline Ranch Airport",
      "city": "Lago Vista",
      "state": "Texas",
      "country": "US",
      "elevation": 900,
      "lat": 30.4286003113,
      "lon": -97.9704971313,
      "tz": "America\/Chicago"
  },
  "1TX5": {
      "icao": "1TX5",
      "iata": "",
      "name": "Laney Farm Airport",
      "city": "Hale Center",
      "state": "Texas",
      "country": "US",
      "elevation": 3483,
      "lat": 34.1291999817,
      "lon": -101.908996582,
      "tz": "America\/Chicago"
  },
  "1TX6": {
      "icao": "1TX6",
      "iata": "",
      "name": "Muxworthy Airport",
      "city": "Hamilton",
      "state": "Texas",
      "country": "US",
      "elevation": 1210,
      "lat": 31.6984996796,
      "lon": -98.0963973999,
      "tz": "America\/Chicago"
  },
  "1TX7": {
      "icao": "1TX7",
      "iata": "",
      "name": "Killion Ranch Airport",
      "city": "Hamlin",
      "state": "Texas",
      "country": "US",
      "elevation": 1845,
      "lat": 32.8056983948,
      "lon": -100.1839981079,
      "tz": "America\/Chicago"
  },
  "1TX8": {
      "icao": "1TX8",
      "iata": "",
      "name": "Hart Aerial Airport",
      "city": "Hart",
      "state": "Texas",
      "country": "US",
      "elevation": 3655,
      "lat": 34.3694992065,
      "lon": -102.0839996338,
      "tz": "America\/Chicago"
  },
  "1TX9": {
      "icao": "1TX9",
      "iata": "",
      "name": "Hawk Ranch Airport",
      "city": "Hawkins",
      "state": "Texas",
      "country": "US",
      "elevation": 335,
      "lat": 32.6375999451,
      "lon": -95.1613006592,
      "tz": "America\/Chicago"
  },
  "1VA0": {
      "icao": "1VA0",
      "iata": "",
      "name": "Sabot Airport",
      "city": "Sabot",
      "state": "Virginia",
      "country": "US",
      "elevation": 255,
      "lat": 37.6292991638,
      "lon": -77.7474975586,
      "tz": "America\/New_York"
  },
  "1VA1": {
      "icao": "1VA1",
      "iata": "",
      "name": "Micro Airport",
      "city": "Stuart",
      "state": "Virginia",
      "country": "US",
      "elevation": 2923,
      "lat": 36.7351989746,
      "lon": -80.4487991333,
      "tz": "America\/New_York"
  },
  "1VA3": {
      "icao": "1VA3",
      "iata": "",
      "name": "Tye River Airport",
      "city": "Lovingston",
      "state": "Virginia",
      "country": "US",
      "elevation": 650,
      "lat": 37.6581993103,
      "lon": -78.945602417,
      "tz": "America\/New_York"
  },
  "1VA5": {
      "icao": "1VA5",
      "iata": "",
      "name": "Hoffman's Farm Airport",
      "city": "Severn",
      "state": "Virginia",
      "country": "US",
      "elevation": 3,
      "lat": 37.270401001,
      "lon": -76.3927001953,
      "tz": "America\/New_York"
  },
  "1VA7": {
      "icao": "1VA7",
      "iata": "",
      "name": "Aaron Penston Field",
      "city": "South Boston",
      "state": "Virginia",
      "country": "US",
      "elevation": 413,
      "lat": 36.6660003662,
      "lon": -78.7225036621,
      "tz": "America\/New_York"
  },
  "1VA8": {
      "icao": "1VA8",
      "iata": "",
      "name": "Hazelswart Airport",
      "city": "Chase City",
      "state": "Virginia",
      "country": "US",
      "elevation": 485,
      "lat": 36.8619003296,
      "lon": -78.4111022949,
      "tz": "America\/New_York"
  },
  "1VA9": {
      "icao": "1VA9",
      "iata": "",
      "name": "Flying T Farm Airport",
      "city": "Stafford",
      "state": "Virginia",
      "country": "US",
      "elevation": 255,
      "lat": 38.4328994751,
      "lon": -77.4704971313,
      "tz": "America\/New_York"
  },
  "1VG2": {
      "icao": "1VG2",
      "iata": "",
      "name": "Whithall Farm Airport",
      "city": "Montross",
      "state": "Virginia",
      "country": "US",
      "elevation": 23,
      "lat": 38.1277008057,
      "lon": -76.689201355,
      "tz": "America\/New_York"
  },
  "1WA2": {
      "icao": "1WA2",
      "iata": "",
      "name": "Pilot's Pastures Airport",
      "city": "Winlock",
      "state": "Washington",
      "country": "US",
      "elevation": 450,
      "lat": 46.5191993713,
      "lon": -122.8899993896,
      "tz": "America\/Los_Angeles"
  },
  "1WA6": {
      "icao": "1WA6",
      "iata": "",
      "name": "Fall City Airport",
      "city": "Fall City",
      "state": "Washington",
      "country": "US",
      "elevation": 140,
      "lat": 47.5595016479,
      "lon": -121.8639984131,
      "tz": "America\/Los_Angeles"
  },
  "1WA9": {
      "icao": "1WA9",
      "iata": "",
      "name": "Friday West Airport",
      "city": "Friday Harbor",
      "state": "Washington",
      "country": "US",
      "elevation": 150,
      "lat": 48.5350990295,
      "lon": -123.0449981689,
      "tz": "America\/Los_Angeles"
  },
  "1WI0": {
      "icao": "1WI0",
      "iata": "",
      "name": "Spring Valley Farm Airport",
      "city": "Orfordville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 42.6425018311,
      "lon": -89.3112030029,
      "tz": "America\/Chicago"
  },
  "1WI1": {
      "icao": "1WI1",
      "iata": "",
      "name": "Williams Airport",
      "city": "Oshkosh",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 43.932800293,
      "lon": -88.7045974731,
      "tz": "America\/Chicago"
  },
  "1WI2": {
      "icao": "1WI2",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Port Washington",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 705,
      "lat": 43.3652992249,
      "lon": -87.8870010376,
      "tz": "America\/Chicago"
  },
  "1WI3": {
      "icao": "1WI3",
      "iata": "",
      "name": "Bed-Ah-Wick Field",
      "city": "Princeton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 787,
      "lat": 43.8499984741,
      "lon": -89.1167984009,
      "tz": "America\/Chicago"
  },
  "1WI6": {
      "icao": "1WI6",
      "iata": "",
      "name": "Aero Estates Airport",
      "city": "Raymond Township",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 825,
      "lat": 42.8403015137,
      "lon": -88.0640029907,
      "tz": "America\/Chicago"
  },
  "1WI7": {
      "icao": "1WI7",
      "iata": "",
      "name": "Buzzards Roost Airport",
      "city": "Redgranite",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 805,
      "lat": 44.0222015381,
      "lon": -89.096496582,
      "tz": "America\/Chicago"
  },
  "1WI8": {
      "icao": "1WI8",
      "iata": "",
      "name": "Jorgensen - Stoller Airport",
      "city": "Algoma",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 690,
      "lat": 44.635799408,
      "lon": -87.4402008057,
      "tz": "America\/Chicago"
  },
  "1WI9": {
      "icao": "1WI9",
      "iata": "",
      "name": "Blackhawk Island Airport",
      "city": "Fort Atkinson",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 42.913898468,
      "lon": -88.8667984009,
      "tz": "America\/Chicago"
  },
  "1WN0": {
      "icao": "1WN0",
      "iata": "",
      "name": "Schmidt Ranch Airport",
      "city": "Northport",
      "state": "Washington",
      "country": "US",
      "elevation": 1335,
      "lat": 48.933381,
      "lon": -117.749808,
      "tz": "America\/Los_Angeles"
  },
  "1WN1": {
      "icao": "1WN1",
      "iata": "",
      "name": "Kettle Moraine Airport",
      "city": "Dundee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1040,
      "lat": 43.6679992676,
      "lon": -88.1953964233,
      "tz": "America\/Chicago"
  },
  "1WN2": {
      "icao": "1WN2",
      "iata": "",
      "name": "Covered Bridge Fields Airport",
      "city": "Cedarburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 875,
      "lat": 43.3292007446,
      "lon": -87.9972991943,
      "tz": "America\/Chicago"
  },
  "1WN3": {
      "icao": "1WN3",
      "iata": "",
      "name": "Danielson Field Airport",
      "city": "Star Prairie",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 45.2118988037,
      "lon": -92.5360031128,
      "tz": "America\/Chicago"
  },
  "1WN5": {
      "icao": "1WN5",
      "iata": "",
      "name": "Simons Airfield",
      "city": "Land-O-Lakes",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1735,
      "lat": 46.1547012329,
      "lon": -89.3174972534,
      "tz": "America\/Chicago"
  },
  "1XA0": {
      "icao": "1XA0",
      "iata": "",
      "name": "Rab Ranch Airport",
      "city": "Andice",
      "state": "Texas",
      "country": "US",
      "elevation": 1095,
      "lat": 30.79369926,
      "lon": -97.90119934,
      "tz": "America\/Chicago"
  },
  "1XA1": {
      "icao": "1XA1",
      "iata": "",
      "name": "Sky Acres Airport",
      "city": "Bowie",
      "state": "Texas",
      "country": "US",
      "elevation": 1070,
      "lat": 33.6477012634,
      "lon": -97.8290023804,
      "tz": "America\/Chicago"
  },
  "1XA2": {
      "icao": "1XA2",
      "iata": "",
      "name": "Chase Field Industrial Airport",
      "city": "Beeville",
      "state": "Texas",
      "country": "US",
      "elevation": 190,
      "lat": 28.4349994659,
      "lon": -97.6517028809,
      "tz": "America\/Chicago"
  },
  "1XA4": {
      "icao": "1XA4",
      "iata": "",
      "name": "Mikeska Field",
      "city": "Brookshire",
      "state": "Texas",
      "country": "US",
      "elevation": 161,
      "lat": 29.8738002777,
      "lon": -96.0044021606,
      "tz": "America\/Chicago"
  },
  "1XA5": {
      "icao": "1XA5",
      "iata": "",
      "name": "Flying Armadillo Field",
      "city": "Paige",
      "state": "Texas",
      "country": "US",
      "elevation": 410,
      "lat": 30.1350002289,
      "lon": -97.1428985596,
      "tz": "America\/Chicago"
  },
  "1XA6": {
      "icao": "1XA6",
      "iata": "",
      "name": "Tailwheel Acres Airport",
      "city": "Valley View",
      "state": "Texas",
      "country": "US",
      "elevation": 702,
      "lat": 33.4737014771,
      "lon": -97.1223983765,
      "tz": "America\/Chicago"
  },
  "1XA7": {
      "icao": "1XA7",
      "iata": "",
      "name": "Gloster Aerodrome",
      "city": "Sealy",
      "state": "Texas",
      "country": "US",
      "elevation": 145,
      "lat": 29.7334003448,
      "lon": -96.0591964722,
      "tz": "America\/Chicago"
  },
  "1XA8": {
      "icao": "1XA8",
      "iata": "",
      "name": "Hackberry Airport",
      "city": "Chatfield",
      "state": "Texas",
      "country": "US",
      "elevation": 433,
      "lat": 32.2324981689,
      "lon": -96.3974990845,
      "tz": "America\/Chicago"
  },
  "1XS0": {
      "icao": "1XS0",
      "iata": "",
      "name": "Double U Ranch Airport",
      "city": "Spring Branch",
      "state": "Texas",
      "country": "US",
      "elevation": 1170,
      "lat": 29.9223995209,
      "lon": -98.4809036255,
      "tz": "America\/Chicago"
  },
  "1XS1": {
      "icao": "1XS1",
      "iata": "",
      "name": "Dunham Field",
      "city": "Crosby",
      "state": "Texas",
      "country": "US",
      "elevation": 60,
      "lat": 29.9382991791,
      "lon": -95.0466003418,
      "tz": "America\/Chicago"
  },
  "1XS2": {
      "icao": "1XS2",
      "iata": "",
      "name": "Skye Dance Airport",
      "city": "Elgin",
      "state": "Texas",
      "country": "US",
      "elevation": 532,
      "lat": 30.3808002472,
      "lon": -97.4274978638,
      "tz": "America\/Chicago"
  },
  "1XS4": {
      "icao": "1XS4",
      "iata": "",
      "name": "Barton Field",
      "city": "Taylor",
      "state": "Texas",
      "country": "US",
      "elevation": 530,
      "lat": 30.4535007477,
      "lon": -97.2555007935,
      "tz": "America\/Chicago"
  },
  "1XS7": {
      "icao": "1XS7",
      "iata": "",
      "name": "Heath Canyon Airport",
      "city": "Terlingua",
      "state": "Texas",
      "country": "US",
      "elevation": 1857,
      "lat": 29.4501991272,
      "lon": -102.8320007324,
      "tz": "America\/Chicago"
  },
  "1XS8": {
      "icao": "1XS8",
      "iata": "",
      "name": "Pinon Ranch Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 1640,
      "lat": 29.6340999603,
      "lon": -100.3740005493,
      "tz": "America\/Chicago"
  },
  "1XS9": {
      "icao": "1XS9",
      "iata": "",
      "name": "Beefmaster's Best Airport",
      "city": "Three Rivers",
      "state": "Texas",
      "country": "US",
      "elevation": 250,
      "lat": 28.3955993652,
      "lon": -98.2208023071,
      "tz": "America\/Chicago"
  },
  "20AK": {
      "icao": "20AK",
      "iata": "",
      "name": "Owen Field",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 61.503789,
      "lon": -149.896044,
      "tz": "America\/Anchorage"
  },
  "20AR": {
      "icao": "20AR",
      "iata": "",
      "name": "Rak Airport",
      "city": "Guy",
      "state": "Arkansas",
      "country": "US",
      "elevation": 660,
      "lat": 35.30789948,
      "lon": -92.32019806,
      "tz": "America\/Chicago"
  },
  "20AZ": {
      "icao": "20AZ",
      "iata": "",
      "name": "Eds Field",
      "city": "Picacho",
      "state": "Arizona",
      "country": "US",
      "elevation": 1644,
      "lat": 32.6833992004,
      "lon": -111.5009994507,
      "tz": "America\/Phoenix"
  },
  "20CL": {
      "icao": "20CL",
      "iata": "",
      "name": "Mysterious Valley Airport",
      "city": "Pope Valley",
      "state": "California",
      "country": "US",
      "elevation": 1410,
      "lat": 38.7492980957,
      "lon": -122.3669967651,
      "tz": "America\/Los_Angeles"
  },
  "20FA": {
      "icao": "20FA",
      "iata": "",
      "name": "Golden Land Ranch Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 43,
      "lat": 27.3962001801,
      "lon": -80.9206008911,
      "tz": "America\/New_York"
  },
  "20FD": {
      "icao": "20FD",
      "iata": "",
      "name": "Pratt Airport",
      "city": "Greenville",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 30.4255008698,
      "lon": -83.5759963989,
      "tz": "America\/New_York"
  },
  "20FL": {
      "icao": "20FL",
      "iata": "",
      "name": "Lake Suzy Estates Airport",
      "city": "Port Charlotte",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 27.0426006317,
      "lon": -82.0437011719,
      "tz": "America\/New_York"
  },
  "20GA": {
      "icao": "20GA",
      "iata": "",
      "name": "Earl L. Small Jr. Field\/Stockmar Airport",
      "city": "Villa Rica",
      "state": "Georgia",
      "country": "US",
      "elevation": 1110,
      "lat": 33.7565002441,
      "lon": -84.8846969604,
      "tz": "America\/New_York"
  },
  "20ID": {
      "icao": "20ID",
      "iata": "",
      "name": "Harrington Airport",
      "city": "Idaho City",
      "state": "Idaho",
      "country": "US",
      "elevation": 4200,
      "lat": 43.720439,
      "lon": -116.005667,
      "tz": "America\/Boise"
  },
  "20II": {
      "icao": "20II",
      "iata": "",
      "name": "Kay Air Airport",
      "city": "Mooresville",
      "state": "Indiana",
      "country": "US",
      "elevation": 651,
      "lat": 39.5872001648,
      "lon": -86.2755966187,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "20IN": {
      "icao": "20IN",
      "iata": "",
      "name": "Canary's Airport",
      "city": "Franklin",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 39.5139007568,
      "lon": -86.051399231,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "20IS": {
      "icao": "20IS",
      "iata": "",
      "name": "Mitek Airport",
      "city": "Winslow",
      "state": "Illinois",
      "country": "US",
      "elevation": 777,
      "lat": 42.4972000122,
      "lon": -89.7918014526,
      "tz": "America\/Chicago"
  },
  "20KS": {
      "icao": "20KS",
      "iata": "",
      "name": "Airpark Estates Airport",
      "city": "Augusta",
      "state": "Kansas",
      "country": "US",
      "elevation": 1340,
      "lat": 37.6758003235,
      "lon": -96.8852996826,
      "tz": "America\/Chicago"
  },
  "20KY": {
      "icao": "20KY",
      "iata": "",
      "name": "Madi's Meadows Airport",
      "city": "Verona",
      "state": "Kentucky",
      "country": "US",
      "elevation": 880,
      "lat": 38.8168983459,
      "lon": -84.6710968018,
      "tz": "America\/New_York"
  },
  "20LS": {
      "icao": "20LS",
      "iata": "",
      "name": "Clark Field",
      "city": "Zachary",
      "state": "Louisiana",
      "country": "US",
      "elevation": 62,
      "lat": 30.6229000092,
      "lon": -91.0329971313,
      "tz": "America\/Chicago"
  },
  "20MO": {
      "icao": "20MO",
      "iata": "",
      "name": "Royal Wood Aerodrome",
      "city": "Kearney",
      "state": "Missouri",
      "country": "US",
      "elevation": 860,
      "lat": 39.3582992554,
      "lon": -94.3169021606,
      "tz": "America\/Chicago"
  },
  "20NC": {
      "icao": "20NC",
      "iata": "",
      "name": "Mountain View Airport",
      "city": "Sherrills Ford",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1020,
      "lat": 35.5923995972,
      "lon": -81.0559005737,
      "tz": "America\/New_York"
  },
  "20ND": {
      "icao": "20ND",
      "iata": "",
      "name": "Crooked Lake Airstrip",
      "city": "Turtle Lake",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1900,
      "lat": 47.6539001465,
      "lon": -100.8909988403,
      "tz": "America\/Chicago"
  },
  "20NY": {
      "icao": "20NY",
      "iata": "",
      "name": "Stafford Airport",
      "city": "Stafford",
      "state": "New York",
      "country": "US",
      "elevation": 891,
      "lat": 43.0088996887,
      "lon": -78.0430984497,
      "tz": "America\/New_York"
  },
  "20OH": {
      "icao": "20OH",
      "iata": "",
      "name": "Kruggel Airport",
      "city": "Litchfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1055,
      "lat": 41.1431007385,
      "lon": -82.028503418,
      "tz": "America\/New_York"
  },
  "20OK": {
      "icao": "20OK",
      "iata": "",
      "name": "Will and Wiley's Aerodrome",
      "city": "Claremore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 700,
      "lat": 36.3256988525,
      "lon": -95.6468963623,
      "tz": "America\/Chicago"
  },
  "20OR": {
      "icao": "20OR",
      "iata": "",
      "name": "Warner's Airport",
      "city": "Redland",
      "state": "Oregon",
      "country": "US",
      "elevation": 325,
      "lat": 45.3250007629,
      "lon": -122.4240036011,
      "tz": "America\/Los_Angeles"
  },
  "20SC": {
      "icao": "20SC",
      "iata": "",
      "name": "O Neal Field",
      "city": "Greer",
      "state": "South Carolina",
      "country": "US",
      "elevation": 929,
      "lat": 35.0065002441,
      "lon": -82.2642974854,
      "tz": "America\/New_York"
  },
  "20TA": {
      "icao": "20TA",
      "iata": "",
      "name": "Mag Drop Airport",
      "city": "Bells",
      "state": "Texas",
      "country": "US",
      "elevation": 740,
      "lat": 33.559167,
      "lon": -96.409444,
      "tz": "America\/Chicago"
  },
  "20TE": {
      "icao": "20TE",
      "iata": "",
      "name": "Reece Ranch Airport",
      "city": "Itasca",
      "state": "Texas",
      "country": "US",
      "elevation": 880,
      "lat": 32.1959991455,
      "lon": -97.0892028809,
      "tz": "America\/Chicago"
  },
  "20TS": {
      "icao": "20TS",
      "iata": "",
      "name": "Bains Private Airport",
      "city": "Bandera",
      "state": "Texas",
      "country": "US",
      "elevation": 1420,
      "lat": 29.8099002838,
      "lon": -99.0680999756,
      "tz": "America\/Chicago"
  },
  "20VA": {
      "icao": "20VA",
      "iata": "",
      "name": "Woodford Airpark",
      "city": "Bowling Green",
      "state": "Virginia",
      "country": "US",
      "elevation": 245,
      "lat": 38.0824012756,
      "lon": -77.4882965088,
      "tz": "America\/New_York"
  },
  "20VG": {
      "icao": "20VG",
      "iata": "",
      "name": "Hawk Ridge Airport",
      "city": "Bedford",
      "state": "Virginia",
      "country": "US",
      "elevation": 760,
      "lat": 37.2887001038,
      "lon": -79.4468994141,
      "tz": "America\/New_York"
  },
  "20WA": {
      "icao": "20WA",
      "iata": "",
      "name": "Skatter Creek Airport",
      "city": "Rochester",
      "state": "Washington",
      "country": "US",
      "elevation": 174,
      "lat": 46.824798584,
      "lon": -123.0350036621,
      "tz": "America\/Los_Angeles"
  },
  "20WI": {
      "icao": "20WI",
      "iata": "",
      "name": "Harju Airport",
      "city": "Redgranite",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 44.0214004517,
      "lon": -89.0792999268,
      "tz": "America\/Chicago"
  },
  "20XS": {
      "icao": "20XS",
      "iata": "",
      "name": "Klutts Field",
      "city": "Chisholm",
      "state": "Texas",
      "country": "US",
      "elevation": 495,
      "lat": 32.8353996277,
      "lon": -96.3779983521,
      "tz": "America\/Chicago"
  },
  "21AK": {
      "icao": "21AK",
      "iata": "",
      "name": "Montana Creek Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 250,
      "lat": 62.0717010498,
      "lon": -150.0690002441,
      "tz": "America\/Anchorage"
  },
  "21AR": {
      "icao": "21AR",
      "iata": "",
      "name": "Skarda\/Tollville Airport",
      "city": "Hazen",
      "state": "Arkansas",
      "country": "US",
      "elevation": 218,
      "lat": 34.7176017761,
      "lon": -91.5390014648,
      "tz": "America\/Chicago"
  },
  "21AZ": {
      "icao": "21AZ",
      "iata": "",
      "name": "White Mountain Lake Airport",
      "city": "Show Low",
      "state": "Arizona",
      "country": "US",
      "elevation": 6066,
      "lat": 34.3522987366,
      "lon": -109.9700012207,
      "tz": "America\/Phoenix"
  },
  "21FA": {
      "icao": "21FA",
      "iata": "",
      "name": "Rockledge Airport",
      "city": "Rockledge",
      "state": "Florida",
      "country": "US",
      "elevation": 27,
      "lat": 28.2982997894,
      "lon": -80.7186965942,
      "tz": "America\/New_York"
  },
  "21FD": {
      "icao": "21FD",
      "iata": "",
      "name": "Land's Field",
      "city": "Marianna",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 30.6945991516,
      "lon": -85.310798645,
      "tz": "America\/Chicago"
  },
  "21FL": {
      "icao": "21FL",
      "iata": "",
      "name": "Kings Field",
      "city": "Clermont",
      "state": "Florida",
      "country": "US",
      "elevation": 98,
      "lat": 28.508600235,
      "lon": -81.8003005981,
      "tz": "America\/New_York"
  },
  "21GE": {
      "icao": "21GE",
      "iata": "",
      "name": "Deer Crossing Airport",
      "city": "Cleveland",
      "state": "Georgia",
      "country": "US",
      "elevation": 1390,
      "lat": 34.5497016907,
      "lon": -83.8221969604,
      "tz": "America\/New_York"
  },
  "21IN": {
      "icao": "21IN",
      "iata": "",
      "name": "Minneman Airport",
      "city": "Hartford City",
      "state": "Indiana",
      "country": "US",
      "elevation": 905,
      "lat": 40.4183998108,
      "lon": -85.3554992676,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "21KY": {
      "icao": "21KY",
      "iata": "",
      "name": "Lincoln Farm Airport",
      "city": "Hodgenville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 780,
      "lat": 37.5376014709,
      "lon": -85.7375030518,
      "tz": "America\/New_York"
  },
  "21LA": {
      "icao": "21LA",
      "iata": "",
      "name": "Southern Helicopters Airport",
      "city": "Baton Rouge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 28,
      "lat": 30.2968997955,
      "lon": -91.2149963379,
      "tz": "America\/Chicago"
  },
  "21LS": {
      "icao": "21LS",
      "iata": "",
      "name": "Solitude Airstrip",
      "city": "St Francisville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 181,
      "lat": 30.8703994751,
      "lon": -91.431098938,
      "tz": "America\/Chicago"
  },
  "21MO": {
      "icao": "21MO",
      "iata": "",
      "name": "Martins Airport",
      "city": "Lawson",
      "state": "Missouri",
      "country": "US",
      "elevation": 995,
      "lat": 39.5013999939,
      "lon": -94.1773986816,
      "tz": "America\/Chicago"
  },
  "21MU": {
      "icao": "21MU",
      "iata": "",
      "name": "The Peninsula Airport",
      "city": "Shell Knob",
      "state": "Missouri",
      "country": "US",
      "elevation": 1015,
      "lat": 36.5648994446,
      "lon": -93.5095977783,
      "tz": "America\/Chicago"
  },
  "21ND": {
      "icao": "21ND",
      "iata": "",
      "name": "Rosenau Airport",
      "city": "Upham",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1450,
      "lat": 48.5821990967,
      "lon": -100.8550033569,
      "tz": "America\/Chicago"
  },
  "21NE": {
      "icao": "21NE",
      "iata": "",
      "name": "Byron Airport",
      "city": "Byron",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1670,
      "lat": 40.0041999817,
      "lon": -97.7731018066,
      "tz": "America\/Chicago"
  },
  "21NH": {
      "icao": "21NH",
      "iata": "",
      "name": "Propwash Airport",
      "city": "Kensington",
      "state": "New Hampshire",
      "country": "US",
      "elevation": 190,
      "lat": 42.9169006348,
      "lon": -70.9353027344,
      "tz": "America\/New_York"
  },
  "21NK": {
      "icao": "21NK",
      "iata": "",
      "name": "Don Kichote Airport",
      "city": "Morris",
      "state": "New York",
      "country": "US",
      "elevation": 1750,
      "lat": 42.5527992249,
      "lon": -75.1911010742,
      "tz": "America\/New_York"
  },
  "21OG": {
      "icao": "21OG",
      "iata": "",
      "name": "Ames Airport",
      "city": "Jefferson",
      "state": "Oregon",
      "country": "US",
      "elevation": 720,
      "lat": 44.771806,
      "lon": -122.969444,
      "tz": "America\/Los_Angeles"
  },
  "21OI": {
      "icao": "21OI",
      "iata": "",
      "name": "Clearwater Airpark",
      "city": "Owensville",
      "state": "Ohio",
      "country": "US",
      "elevation": 905,
      "lat": 39.1281013489,
      "lon": -84.0926971436,
      "tz": "America\/New_York"
  },
  "21SC": {
      "icao": "21SC",
      "iata": "",
      "name": "Graham Airport",
      "city": "Darlington",
      "state": "South Carolina",
      "country": "US",
      "elevation": 154,
      "lat": 34.3479003906,
      "lon": -79.8799972534,
      "tz": "America\/New_York"
  },
  "21TE": {
      "icao": "21TE",
      "iata": "",
      "name": "Seaberg Ranch Airport",
      "city": "Dayton",
      "state": "Texas",
      "country": "US",
      "elevation": 68,
      "lat": 30.0126991272,
      "lon": -94.9241027832,
      "tz": "America\/Chicago"
  },
  "21TN": {
      "icao": "21TN",
      "iata": "",
      "name": "Ray's Stall Airport",
      "city": "Dandridge",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1020,
      "lat": 35.9852981567,
      "lon": -83.0922012329,
      "tz": "America\/New_York"
  },
  "21TX": {
      "icao": "21TX",
      "iata": "",
      "name": "Shilling's Airport",
      "city": "Texarkana",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 33.4995994568,
      "lon": -94.0943984985,
      "tz": "America\/Chicago"
  },
  "21VA": {
      "icao": "21VA",
      "iata": "",
      "name": "Eagles Nest Airport",
      "city": "Cheriton",
      "state": "Virginia",
      "country": "US",
      "elevation": 40,
      "lat": 37.3031997681,
      "lon": -75.9348983765,
      "tz": "America\/New_York"
  },
  "21XS": {
      "icao": "21XS",
      "iata": "",
      "name": "Houston Airpark",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 48,
      "lat": 29.5196990967,
      "lon": -95.2751998901,
      "tz": "America\/Chicago"
  },
  "22AK": {
      "icao": "22AK",
      "iata": "",
      "name": "Helmericks Airport",
      "city": "Deadhorse",
      "state": "Alaska",
      "country": "US",
      "elevation": 7,
      "lat": 70.428338,
      "lon": -150.402899,
      "tz": "America\/Anchorage"
  },
  "22CL": {
      "icao": "22CL",
      "iata": "",
      "name": "Double Tree Farm Airport",
      "city": "Marysville",
      "state": "California",
      "country": "US",
      "elevation": 550,
      "lat": 39.2999000549,
      "lon": -121.3590011597,
      "tz": "America\/Los_Angeles"
  },
  "22CO": {
      "icao": "22CO",
      "iata": "",
      "name": "Flying E Airport",
      "city": "Brighton",
      "state": "Colorado",
      "country": "US",
      "elevation": 5100,
      "lat": 39.9580574036,
      "lon": -104.485557556,
      "tz": "America\/Denver"
  },
  "22FA": {
      "icao": "22FA",
      "iata": "",
      "name": "Hidden River Airport",
      "city": "Sarasota",
      "state": "Florida",
      "country": "US",
      "elevation": 47,
      "lat": 27.3031005859,
      "lon": -82.2725982666,
      "tz": "America\/New_York"
  },
  "22FL": {
      "icao": "22FL",
      "iata": "",
      "name": "Farm Air Service Airport",
      "city": "Altha",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.6019001007,
      "lon": -85.1399002075,
      "tz": "America\/Chicago"
  },
  "22GA": {
      "icao": "22GA",
      "iata": "",
      "name": "Riverside Airport",
      "city": "Plainville",
      "state": "Georgia",
      "country": "US",
      "elevation": 620,
      "lat": 34.4292984009,
      "lon": -85.054397583,
      "tz": "America\/New_York"
  },
  "22ID": {
      "icao": "22ID",
      "iata": "",
      "name": "Treasure Gulch Airport",
      "city": "Placerville",
      "state": "Idaho",
      "country": "US",
      "elevation": 4350,
      "lat": 43.9446983337,
      "lon": -115.9410018921,
      "tz": "America\/Boise"
  },
  "22II": {
      "icao": "22II",
      "iata": "",
      "name": "Lewis Airport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 370,
      "lat": 37.9169998169,
      "lon": -87.761100769,
      "tz": "America\/Chicago"
  },
  "22IL": {
      "icao": "22IL",
      "iata": "",
      "name": "Heller Airport",
      "city": "Galena",
      "state": "Illinois",
      "country": "US",
      "elevation": 820,
      "lat": 42.4119987488,
      "lon": -90.400100708,
      "tz": "America\/Chicago"
  },
  "22IN": {
      "icao": "22IN",
      "iata": "",
      "name": "Mooney Field",
      "city": "Auburn",
      "state": "Indiana",
      "country": "US",
      "elevation": 865,
      "lat": 41.3483009338,
      "lon": -85.0074996948,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "22IS": {
      "icao": "22IS",
      "iata": "",
      "name": "Johnson Farm Airport",
      "city": "Carman",
      "state": "Illinois",
      "country": "US",
      "elevation": 535,
      "lat": 40.7262001038,
      "lon": -91.0699005127,
      "tz": "America\/Chicago"
  },
  "22LA": {
      "icao": "22LA",
      "iata": "",
      "name": "Wd Flyers Airport",
      "city": "Simmesport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 48,
      "lat": 30.9974994659,
      "lon": -91.8811035156,
      "tz": "America\/Chicago"
  },
  "22LL": {
      "icao": "22LL",
      "iata": "",
      "name": "Pike's Airport",
      "city": "Ottawa",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 41.3134002686,
      "lon": -88.8606033325,
      "tz": "America\/Chicago"
  },
  "22LS": {
      "icao": "22LS",
      "iata": "",
      "name": "Couvillion Airport",
      "city": "Arnaudville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 29,
      "lat": 30.3978004456,
      "lon": -91.9738998413,
      "tz": "America\/Chicago"
  },
  "22MD": {
      "icao": "22MD",
      "iata": "",
      "name": "Laura's Landing Airport",
      "city": "Hagerstown",
      "state": "Maryland",
      "country": "US",
      "elevation": 550,
      "lat": 39.5868988037,
      "lon": -77.6449966431,
      "tz": "America\/New_York"
  },
  "22MO": {
      "icao": "22MO",
      "iata": "",
      "name": "Belly Acres Ranch Airport",
      "city": "Mountain Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 1285,
      "lat": 37.2611999512,
      "lon": -92.3667984009,
      "tz": "America\/Chicago"
  },
  "22MS": {
      "icao": "22MS",
      "iata": "",
      "name": "Sky Landings Airport",
      "city": "Lucedale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 260,
      "lat": 30.840278,
      "lon": -88.574722,
      "tz": "America\/Chicago"
  },
  "22MT": {
      "icao": "22MT",
      "iata": "",
      "name": "Wounded Buck Ranch Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2905,
      "lat": 48.1194000244,
      "lon": -114.1699981689,
      "tz": "America\/Denver"
  },
  "22NC": {
      "icao": "22NC",
      "iata": "",
      "name": "Flying Dove Field",
      "city": "Siler City",
      "state": "North Carolina",
      "country": "US",
      "elevation": 650,
      "lat": 35.8210983276,
      "lon": -79.4682998657,
      "tz": "America\/New_York"
  },
  "22NE": {
      "icao": "22NE",
      "iata": "",
      "name": "Grone Airport",
      "city": "Davenport",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1643,
      "lat": 40.297000885,
      "lon": -97.7981033325,
      "tz": "America\/Chicago"
  },
  "22NR": {
      "icao": "22NR",
      "iata": "",
      "name": "Bully Field",
      "city": "Burlington",
      "state": "North Carolina",
      "country": "US",
      "elevation": 550,
      "lat": 36.153611,
      "lon": -79.400556,
      "tz": "America\/New_York"
  },
  "22OG": {
      "icao": "22OG",
      "iata": "",
      "name": "Withrotor Airport",
      "city": "Lakeview",
      "state": "Oregon",
      "country": "US",
      "elevation": 4920,
      "lat": 42.2753982544,
      "lon": -120.3939971924,
      "tz": "America\/Los_Angeles"
  },
  "22OI": {
      "icao": "22OI",
      "iata": "",
      "name": "Giovannone Airport",
      "city": "Lordstown",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 41.1488990784,
      "lon": -80.8401031494,
      "tz": "America\/New_York"
  },
  "22OK": {
      "icao": "22OK",
      "iata": "",
      "name": "Smith Field",
      "city": "Springer",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 860,
      "lat": 34.315278,
      "lon": -97.175833,
      "tz": "America\/Chicago"
  },
  "22OR": {
      "icao": "22OR",
      "iata": "",
      "name": "Iron Crown Airport",
      "city": "Silverton",
      "state": "Oregon",
      "country": "US",
      "elevation": 790,
      "lat": 44.9417991638,
      "lon": -122.7630004883,
      "tz": "America\/Los_Angeles"
  },
  "22TA": {
      "icao": "22TA",
      "iata": "",
      "name": "Standard Industries Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 690,
      "lat": 29.341299057,
      "lon": -98.6596984863,
      "tz": "America\/Chicago"
  },
  "22TE": {
      "icao": "22TE",
      "iata": "",
      "name": "Fenner Ranch Airport",
      "city": "Cordele",
      "state": "Texas",
      "country": "US",
      "elevation": 126,
      "lat": 29.1933002472,
      "lon": -96.6891021729,
      "tz": "America\/Chicago"
  },
  "22WA": {
      "icao": "22WA",
      "iata": "",
      "name": "Gollehon Airport",
      "city": "Wilbur",
      "state": "Washington",
      "country": "US",
      "elevation": 2360,
      "lat": 47.8202018738,
      "lon": -118.68699646,
      "tz": "America\/Los_Angeles"
  },
  "22WN": {
      "icao": "22WN",
      "iata": "",
      "name": "Southwind Airport",
      "city": "Dodgeville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1030,
      "lat": 43.0685997009,
      "lon": -90.2194976807,
      "tz": "America\/Chicago"
  },
  "22WY": {
      "icao": "22WY",
      "iata": "",
      "name": "Kissack\/Reynolds Airport",
      "city": "Rozet",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4380,
      "lat": 44.338056,
      "lon": -105.162117,
      "tz": "America\/Denver"
  },
  "22XS": {
      "icao": "22XS",
      "iata": "",
      "name": "Longhorn Aux Landing Strip",
      "city": "Fort Hood(Killeen)",
      "state": "Texas",
      "country": "US",
      "elevation": 720,
      "lat": 31.3710002899,
      "lon": -97.6669998169,
      "tz": "America\/Chicago"
  },
  "23AK": {
      "icao": "23AK",
      "iata": "",
      "name": "Yuknis Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 340,
      "lat": 61.5941009521,
      "lon": -149.3110046387,
      "tz": "America\/Anchorage"
  },
  "23AR": {
      "icao": "23AR",
      "iata": "",
      "name": "Classic Airstrip",
      "city": "Jonesboro",
      "state": "Arkansas",
      "country": "US",
      "elevation": 260,
      "lat": 35.8259010315,
      "lon": -90.547203064,
      "tz": "America\/Chicago"
  },
  "23AZ": {
      "icao": "23AZ",
      "iata": "",
      "name": "Moreton Airpark",
      "city": "Wickenburg",
      "state": "Arizona",
      "country": "US",
      "elevation": 2455,
      "lat": 34.0195007324,
      "lon": -112.8259963989,
      "tz": "America\/Phoenix"
  },
  "23CA": {
      "icao": "23CA",
      "iata": "",
      "name": "Lefko Airport",
      "city": "Fort Jones",
      "state": "California",
      "country": "US",
      "elevation": 2860,
      "lat": 41.6249008179,
      "lon": -122.7649993896,
      "tz": "America\/Los_Angeles"
  },
  "23CD": {
      "icao": "23CD",
      "iata": "",
      "name": "Aviation Acres Airport",
      "city": "Burlington",
      "state": "Colorado",
      "country": "US",
      "elevation": 4210,
      "lat": 39.29945,
      "lon": -102.321817,
      "tz": "America\/Denver"
  },
  "23CN": {
      "icao": "23CN",
      "iata": "",
      "name": "Douthitt Strip",
      "city": "El Centro",
      "state": "California",
      "country": "US",
      "elevation": 45,
      "lat": 32.788898468,
      "lon": -115.5299987793,
      "tz": "America\/Los_Angeles"
  },
  "23CO": {
      "icao": "23CO",
      "iata": "",
      "name": "High Mesa Airport",
      "city": "Rush",
      "state": "Colorado",
      "country": "US",
      "elevation": 6000,
      "lat": 38.8438987732,
      "lon": -103.9899978638,
      "tz": "America\/Denver"
  },
  "23DE": {
      "icao": "23DE",
      "iata": "",
      "name": "Full Throttle Farm Airport",
      "city": "Clayton",
      "state": "Delaware",
      "country": "US",
      "elevation": 81,
      "lat": 39.312567,
      "lon": -75.728983,
      "tz": "America\/New_York"
  },
  "23FD": {
      "icao": "23FD",
      "iata": "",
      "name": "Scott 2000 Airport",
      "city": "Vero Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 27,
      "lat": 27.5473003387,
      "lon": -80.6620025635,
      "tz": "America\/New_York"
  },
  "23FL": {
      "icao": "23FL",
      "iata": "",
      "name": "Gyro Town Usa STOLport",
      "city": "Wimauma",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 27.6844005585,
      "lon": -82.2442016602,
      "tz": "America\/New_York"
  },
  "23GE": {
      "icao": "23GE",
      "iata": "",
      "name": "Lt Landing Airport",
      "city": "Dublin",
      "state": "Georgia",
      "country": "US",
      "elevation": 286,
      "lat": 32.383056,
      "lon": -83.1,
      "tz": "America\/New_York"
  },
  "23IS": {
      "icao": "23IS",
      "iata": "",
      "name": "Clark Airport",
      "city": "Plymouth",
      "state": "Illinois",
      "country": "US",
      "elevation": 570,
      "lat": 40.3259010315,
      "lon": -90.8275985718,
      "tz": "America\/Chicago"
  },
  "23KS": {
      "icao": "23KS",
      "iata": "",
      "name": "Ney Airport",
      "city": "Bonner Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 950,
      "lat": 39.0699996948,
      "lon": -94.948600769,
      "tz": "America\/Chicago"
  },
  "23KY": {
      "icao": "23KY",
      "iata": "",
      "name": "Barnes Farm Airport",
      "city": "Burna",
      "state": "Kentucky",
      "country": "US",
      "elevation": 500,
      "lat": 37.2308006287,
      "lon": -88.3186035156,
      "tz": "America\/Chicago"
  },
  "23LA": {
      "icao": "23LA",
      "iata": "",
      "name": "Warner-Thunder Hill Airport",
      "city": "Covington",
      "state": "Louisiana",
      "country": "US",
      "elevation": 185,
      "lat": 30.6301994324,
      "lon": -90.079498291,
      "tz": "America\/Chicago"
  },
  "23ME": {
      "icao": "23ME",
      "iata": "",
      "name": "Dyer's Field",
      "city": "Rumford",
      "state": "Maine",
      "country": "US",
      "elevation": 650,
      "lat": 44.5264015198,
      "lon": -70.6635971069,
      "tz": "America\/New_York"
  },
  "23MI": {
      "icao": "23MI",
      "iata": "",
      "name": "Betsie River Airstrip",
      "city": "Thompsonville",
      "state": "Michigan",
      "country": "US",
      "elevation": 825,
      "lat": 44.573600769,
      "lon": -85.888999939,
      "tz": "America\/Detroit"
  },
  "23MN": {
      "icao": "23MN",
      "iata": "",
      "name": "High Grove Airport",
      "city": "Preston",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1240,
      "lat": 43.6411018372,
      "lon": -92.022102356,
      "tz": "America\/Chicago"
  },
  "23MO": {
      "icao": "23MO",
      "iata": "",
      "name": "Oerke Enterprises Airport",
      "city": "Butler",
      "state": "Missouri",
      "country": "US",
      "elevation": 770,
      "lat": 38.2200012207,
      "lon": -94.4961013794,
      "tz": "America\/Chicago"
  },
  "23MT": {
      "icao": "23MT",
      "iata": "",
      "name": "Waterfall Airport",
      "city": "Bozeman",
      "state": "Montana",
      "country": "US",
      "elevation": 4950,
      "lat": 45.6571998596,
      "lon": -111.0400009155,
      "tz": "America\/Denver"
  },
  "23ND": {
      "icao": "23ND",
      "iata": "",
      "name": "Minnkota Private Airport",
      "city": "Center",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2110,
      "lat": 47.0821990967,
      "lon": -101.266998291,
      "tz": "America\/North_Dakota\/Center"
  },
  "23NE": {
      "icao": "23NE",
      "iata": "",
      "name": "Stromsburg Municipal Airport",
      "city": "Stromsburg",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1675,
      "lat": 41.1291999817,
      "lon": -97.5775985718,
      "tz": "America\/Chicago"
  },
  "23NH": {
      "icao": "23NH",
      "iata": "",
      "name": "Windswept Airport",
      "city": "Windsor",
      "state": "New Hampshire",
      "country": "US",
      "elevation": 1289,
      "lat": 43.13029861,
      "lon": -72.01499939,
      "tz": "America\/New_York"
  },
  "23NJ": {
      "icao": "23NJ",
      "iata": "",
      "name": "Alliance Airport",
      "city": "Pittsgrove",
      "state": "New Jersey",
      "country": "US",
      "elevation": 110,
      "lat": 39.504699707,
      "lon": -75.0931015015,
      "tz": "America\/New_York"
  },
  "23NK": {
      "icao": "23NK",
      "iata": "",
      "name": "Long Acre Farms Airport",
      "city": "Macedon",
      "state": "New York",
      "country": "US",
      "elevation": 520,
      "lat": 43.1124992371,
      "lon": -77.3125,
      "tz": "America\/New_York"
  },
  "23NR": {
      "icao": "23NR",
      "iata": "",
      "name": "Whitfield'S East Airport",
      "city": "Fairfield",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1,
      "lat": 35.560556,
      "lon": -76.234722,
      "tz": "America\/New_York"
  },
  "23NY": {
      "icao": "23NY",
      "iata": "",
      "name": "Jolamtra Landing Area Airport",
      "city": "Bath",
      "state": "New York",
      "country": "US",
      "elevation": 1700,
      "lat": 42.279800415,
      "lon": -77.3399963379,
      "tz": "America\/New_York"
  },
  "23OA": {
      "icao": "23OA",
      "iata": "",
      "name": "Dade Field",
      "city": "Christianburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 1138,
      "lat": 40.050278,
      "lon": -84.013056,
      "tz": "America\/New_York"
  },
  "23PA": {
      "icao": "23PA",
      "iata": "",
      "name": "Cedar Acres Private Group Airport",
      "city": "Revere",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 500,
      "lat": 40.5000991821,
      "lon": -75.1467971802,
      "tz": "America\/New_York"
  },
  "23TA": {
      "icao": "23TA",
      "iata": "",
      "name": "Rowland R Airfield",
      "city": "Tom Bean",
      "state": "Texas",
      "country": "US",
      "elevation": 705,
      "lat": 33.4873008728,
      "lon": -96.4558029175,
      "tz": "America\/Chicago"
  },
  "23TS": {
      "icao": "23TS",
      "iata": "",
      "name": "Bertani Ranch Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1110,
      "lat": 29.422700882,
      "lon": -100.7389984131,
      "tz": "America\/Chicago"
  },
  "23TX": {
      "icao": "23TX",
      "iata": "",
      "name": "The Grove\/Ranch Airport",
      "city": "The Grove\/Ranch",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 31.2495994568,
      "lon": -97.5227966309,
      "tz": "America\/Chicago"
  },
  "23VA": {
      "icao": "23VA",
      "iata": "",
      "name": "Wheatland Airport",
      "city": "Buckingham",
      "state": "Virginia",
      "country": "US",
      "elevation": 490,
      "lat": 37.5570983887,
      "lon": -78.6200027466,
      "tz": "America\/New_York"
  },
  "23VG": {
      "icao": "23VG",
      "iata": "",
      "name": "Murdock's Holly Bu Airport",
      "city": "Boydton",
      "state": "Virginia",
      "country": "US",
      "elevation": 465,
      "lat": 36.7097015381,
      "lon": -78.4281005859,
      "tz": "America\/New_York"
  },
  "23VT": {
      "icao": "23VT",
      "iata": "",
      "name": "North Windham Airport",
      "city": "Londonderry",
      "state": "Vermont",
      "country": "US",
      "elevation": 1750,
      "lat": 43.2215003967,
      "lon": -72.7128982544,
      "tz": "America\/New_York"
  },
  "23WN": {
      "icao": "23WN",
      "iata": "",
      "name": "Willie's Airport",
      "city": "Springbrook",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1110,
      "lat": 45.9110984802,
      "lon": -91.6285018921,
      "tz": "America\/Chicago"
  },
  "23WV": {
      "icao": "23WV",
      "iata": "",
      "name": "Bocamanu Airport",
      "city": "Fayetteville",
      "state": "West Virginia",
      "country": "US",
      "elevation": 2501,
      "lat": 38.025833,
      "lon": -80.982778,
      "tz": "America\/New_York"
  },
  "23XS": {
      "icao": "23XS",
      "iata": "",
      "name": "Shorthorn Aux Landing Strip",
      "city": "Fort Hood\/Killeen\/",
      "state": "Texas",
      "country": "US",
      "elevation": 720,
      "lat": 31.3586006165,
      "lon": -97.6710968018,
      "tz": "America\/Chicago"
  },
  "24AK": {
      "icao": "24AK",
      "iata": "",
      "name": "Toad Lake Strip",
      "city": "Meadow Lakes",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 61.6156997681,
      "lon": -149.7070007324,
      "tz": "America\/Anchorage"
  },
  "24AR": {
      "icao": "24AR",
      "iata": "",
      "name": "Griffin Agricultural Airport",
      "city": "Lundell",
      "state": "Arkansas",
      "country": "US",
      "elevation": 200,
      "lat": 34.1981010437,
      "lon": -90.952796936,
      "tz": "America\/Chicago"
  },
  "24AZ": {
      "icao": "24AZ",
      "iata": "",
      "name": "Pleasant Valley Airstrip",
      "city": "Young",
      "state": "Arizona",
      "country": "US",
      "elevation": 5688,
      "lat": 34.1581001282,
      "lon": -110.9349975586,
      "tz": "America\/Phoenix"
  },
  "24CL": {
      "icao": "24CL",
      "iata": "",
      "name": "Salyer Farms Airport",
      "city": "Corcoran",
      "state": "California",
      "country": "US",
      "elevation": 205,
      "lat": 36.0887985229,
      "lon": -119.5429992676,
      "tz": "America\/Los_Angeles"
  },
  "24CT": {
      "icao": "24CT",
      "iata": "",
      "name": "Bee Field",
      "city": "Jewett City",
      "state": "Connecticut",
      "country": "US",
      "elevation": 280,
      "lat": 41.566886,
      "lon": -71.966883,
      "tz": "America\/New_York"
  },
  "24FL": {
      "icao": "24FL",
      "iata": "",
      "name": "Garcon Field",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 16,
      "lat": 30.4899997711,
      "lon": -87.0858001709,
      "tz": "America\/Chicago"
  },
  "24IA": {
      "icao": "24IA",
      "iata": "",
      "name": "Isley Field",
      "city": "Cedar Falls",
      "state": "Iowa",
      "country": "US",
      "elevation": 982,
      "lat": 42.5158004761,
      "lon": -92.5197982788,
      "tz": "America\/Chicago"
  },
  "24IN": {
      "icao": "24IN",
      "iata": "",
      "name": "Kenstin Manor Airport",
      "city": "Helmsburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 658,
      "lat": 39.2625007629,
      "lon": -86.2874984741,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "24KS": {
      "icao": "24KS",
      "iata": "",
      "name": "Blocker Field",
      "city": "Haven",
      "state": "Kansas",
      "country": "US",
      "elevation": 1523,
      "lat": 37.8582992554,
      "lon": -97.8178024292,
      "tz": "America\/Chicago"
  },
  "24KY": {
      "icao": "24KY",
      "iata": "",
      "name": "Williams Farm Airport",
      "city": "Mortons Gap",
      "state": "Kentucky",
      "country": "US",
      "elevation": 415,
      "lat": 37.2167015076,
      "lon": -87.4306030273,
      "tz": "America\/Chicago"
  },
  "24LL": {
      "icao": "24LL",
      "iata": "",
      "name": "Lambert Airport",
      "city": "Pinckneyville",
      "state": "Illinois",
      "country": "US",
      "elevation": 510,
      "lat": 38.0862007141,
      "lon": -89.4589996338,
      "tz": "America\/Chicago"
  },
  "24ME": {
      "icao": "24ME",
      "iata": "",
      "name": "Slip Knot Landing Airport",
      "city": "Windham",
      "state": "Maine",
      "country": "US",
      "elevation": 310,
      "lat": 43.8222007751,
      "lon": -70.4428024292,
      "tz": "America\/New_York"
  },
  "24MI": {
      "icao": "24MI",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Alto",
      "state": "Michigan",
      "country": "US",
      "elevation": 810,
      "lat": 42.7999992371,
      "lon": -85.3707962036,
      "tz": "America\/Detroit"
  },
  "24MN": {
      "icao": "24MN",
      "iata": "",
      "name": "Bergemann Airport",
      "city": "Garden City",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1001,
      "lat": 44.0041007996,
      "lon": -94.1780014038,
      "tz": "America\/Chicago"
  },
  "24MO": {
      "icao": "24MO",
      "iata": "",
      "name": "Mistwood Airport",
      "city": "Camdenton",
      "state": "Missouri",
      "country": "US",
      "elevation": 760,
      "lat": 38.1333999634,
      "lon": -92.8335037231,
      "tz": "America\/Chicago"
  },
  "24MT": {
      "icao": "24MT",
      "iata": "",
      "name": "Haggerty Airport",
      "city": "Bozeman",
      "state": "Montana",
      "country": "US",
      "elevation": 5170,
      "lat": 45.6194000244,
      "lon": -111.0920028687,
      "tz": "America\/Denver"
  },
  "24MU": {
      "icao": "24MU",
      "iata": "",
      "name": "Steele Airport",
      "city": "Fairfax",
      "state": "Missouri",
      "country": "US",
      "elevation": 1077,
      "lat": 40.3628005981,
      "lon": -95.4385986328,
      "tz": "America\/Chicago"
  },
  "24NC": {
      "icao": "24NC",
      "iata": "",
      "name": "Mc Gee Field",
      "city": "Monroe",
      "state": "North Carolina",
      "country": "US",
      "elevation": 550,
      "lat": 35.1272010803,
      "lon": -80.4749984741,
      "tz": "America\/New_York"
  },
  "24NE": {
      "icao": "24NE",
      "iata": "",
      "name": "Orr Airport",
      "city": "Lakeside",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3827,
      "lat": 42.3838996887,
      "lon": -102.4390029907,
      "tz": "America\/Denver"
  },
  "24NY": {
      "icao": "24NY",
      "iata": "",
      "name": "Ely Air Park",
      "city": "Bergen",
      "state": "New York",
      "country": "US",
      "elevation": 690,
      "lat": 43.0666999817,
      "lon": -77.9589004517,
      "tz": "America\/New_York"
  },
  "24OH": {
      "icao": "24OH",
      "iata": "",
      "name": "Milburn Airport",
      "city": "Louisville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1197,
      "lat": 40.8805999756,
      "lon": -81.2914962769,
      "tz": "America\/New_York"
  },
  "24OI": {
      "icao": "24OI",
      "iata": "",
      "name": "Reeds Airport",
      "city": "St Paris",
      "state": "Ohio",
      "country": "US",
      "elevation": 1200,
      "lat": 40.0778007507,
      "lon": -83.8972015381,
      "tz": "America\/New_York"
  },
  "24PA": {
      "icao": "24PA",
      "iata": "",
      "name": "Kindelberger Landing Strip",
      "city": "Freedom",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1140,
      "lat": 40.6916999817,
      "lon": -80.1778030396,
      "tz": "America\/New_York"
  },
  "24SC": {
      "icao": "24SC",
      "iata": "",
      "name": "The Farm Airport",
      "city": "Summit",
      "state": "South Carolina",
      "country": "US",
      "elevation": 580,
      "lat": 33.935798645,
      "lon": -81.4297027588,
      "tz": "America\/New_York"
  },
  "24TA": {
      "icao": "24TA",
      "iata": "",
      "name": "Moore Ranch Airport",
      "city": "Centerville",
      "state": "Texas",
      "country": "US",
      "elevation": 315,
      "lat": 31.2131996155,
      "lon": -95.8274993896,
      "tz": "America\/Chicago"
  },
  "24TX": {
      "icao": "24TX",
      "iata": "",
      "name": "Thurber Lake Airport",
      "city": "Thurber",
      "state": "Texas",
      "country": "US",
      "elevation": 1092,
      "lat": 32.5000991821,
      "lon": -98.3836975098,
      "tz": "America\/Chicago"
  },
  "24VA": {
      "icao": "24VA",
      "iata": "",
      "name": "Cherrystone Airport",
      "city": "Cheriton",
      "state": "Virginia",
      "country": "US",
      "elevation": 14,
      "lat": 37.2882003784,
      "lon": -75.9997024536,
      "tz": "America\/New_York"
  },
  "24WI": {
      "icao": "24WI",
      "iata": "",
      "name": "Irlbeck Airport",
      "city": "Somerset",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 990,
      "lat": 45.095500946,
      "lon": -92.6737976074,
      "tz": "America\/Chicago"
  },
  "24WN": {
      "icao": "24WN",
      "iata": "",
      "name": "Prairie View Farm Airport",
      "city": "Lake Geneva",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 890,
      "lat": 42.5541000366,
      "lon": -88.4274978638,
      "tz": "America\/Chicago"
  },
  "24XA": {
      "icao": "24XA",
      "iata": "",
      "name": "Cross-B Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 530,
      "lat": 29.320969,
      "lon": -98.370294,
      "tz": "America\/Chicago"
  },
  "25AK": {
      "icao": "25AK",
      "iata": "",
      "name": "Honeybee Lake Aero Park Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 61.7122001648,
      "lon": -150.06300354,
      "tz": "America\/Anchorage"
  },
  "25AR": {
      "icao": "25AR",
      "iata": "",
      "name": "Reeves Private Airport",
      "city": "Mount Pleasant",
      "state": "Arkansas",
      "country": "US",
      "elevation": 600,
      "lat": 35.9305992126,
      "lon": -91.7460021973,
      "tz": "America\/Chicago"
  },
  "25AZ": {
      "icao": "25AZ",
      "iata": "",
      "name": "Mystery Well Ranch Airport",
      "city": "Portal",
      "state": "Arizona",
      "country": "US",
      "elevation": 4173,
      "lat": 31.8159008026,
      "lon": -109.0569992065,
      "tz": "America\/Denver"
  },
  "25CA": {
      "icao": "25CA",
      "iata": "",
      "name": "Loma Madera Ranch Airport",
      "city": "Santa Ysabel",
      "state": "California",
      "country": "US",
      "elevation": 3375,
      "lat": 33.1791992188,
      "lon": -116.7929992676,
      "tz": "America\/Los_Angeles"
  },
  "25CD": {
      "icao": "25CD",
      "iata": "",
      "name": "Lux Field",
      "city": "Hartsel",
      "state": "Colorado",
      "country": "US",
      "elevation": 9300,
      "lat": 39.152333,
      "lon": -105.720167,
      "tz": "America\/Denver"
  },
  "25CL": {
      "icao": "25CL",
      "iata": "",
      "name": "Van Dyke Strip",
      "city": "Pleasant Grove",
      "state": "California",
      "country": "US",
      "elevation": 48,
      "lat": 38.8666000366,
      "lon": -121.4840011597,
      "tz": "America\/Los_Angeles"
  },
  "25CO": {
      "icao": "25CO",
      "iata": "",
      "name": "Crystal Lakes Airport",
      "city": "Red Feather Lakes",
      "state": "Colorado",
      "country": "US",
      "elevation": 8440,
      "lat": 40.851600647,
      "lon": -105.6330032349,
      "tz": "America\/Denver"
  },
  "25FL": {
      "icao": "25FL",
      "iata": "",
      "name": "Connell's Wahoo Airport",
      "city": "Bushnell",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 28.6319007874,
      "lon": -82.1577987671,
      "tz": "America\/New_York"
  },
  "25GA": {
      "icao": "25GA",
      "iata": "",
      "name": "Miller Farm Airport",
      "city": "Douglasville",
      "state": "Georgia",
      "country": "US",
      "elevation": 750,
      "lat": 33.6595993042,
      "lon": -84.6630020142,
      "tz": "America\/New_York"
  },
  "25IL": {
      "icao": "25IL",
      "iata": "",
      "name": "Bob Davis Flying Service Airport",
      "city": "Virden",
      "state": "Illinois",
      "country": "US",
      "elevation": 680,
      "lat": 39.5167007446,
      "lon": -89.8582992554,
      "tz": "America\/Chicago"
  },
  "25IN": {
      "icao": "25IN",
      "iata": "",
      "name": "Reid-Eash Airport",
      "city": "Howe",
      "state": "Indiana",
      "country": "US",
      "elevation": 879,
      "lat": 41.7028007507,
      "lon": -85.4194030762,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "25KS": {
      "icao": "25KS",
      "iata": "",
      "name": "Wyrill Farming Airport",
      "city": "Kirwin",
      "state": "Kansas",
      "country": "US",
      "elevation": 1700,
      "lat": 39.6833000183,
      "lon": -99.12840271,
      "tz": "America\/Chicago"
  },
  "25LL": {
      "icao": "25LL",
      "iata": "",
      "name": "Bradbury Airport",
      "city": "Piper City",
      "state": "Illinois",
      "country": "US",
      "elevation": 694,
      "lat": 40.7523002625,
      "lon": -88.2228012085,
      "tz": "America\/Chicago"
  },
  "25MD": {
      "icao": "25MD",
      "iata": "",
      "name": "Whalen Field",
      "city": "Sudlersville",
      "state": "Maryland",
      "country": "US",
      "elevation": 70,
      "lat": 39.1674995422,
      "lon": -75.8402023315,
      "tz": "America\/New_York"
  },
  "25MI": {
      "icao": "25MI",
      "iata": "",
      "name": "Farver Field",
      "city": "Caseville",
      "state": "Michigan",
      "country": "US",
      "elevation": 600,
      "lat": 43.9166984558,
      "lon": -83.2750015259,
      "tz": "America\/Detroit"
  },
  "25MO": {
      "icao": "25MO",
      "iata": "",
      "name": "Cook Airport",
      "city": "Carl Junction",
      "state": "Missouri",
      "country": "US",
      "elevation": 920,
      "lat": 37.185798645,
      "lon": -94.5511016846,
      "tz": "America\/Chicago"
  },
  "25MT": {
      "icao": "25MT",
      "iata": "",
      "name": "Blatter Airport",
      "city": "Glasgow",
      "state": "Montana",
      "country": "US",
      "elevation": 2800,
      "lat": 48.4221992493,
      "lon": -106.2529983521,
      "tz": "America\/Denver"
  },
  "25NC": {
      "icao": "25NC",
      "iata": "",
      "name": "Smith Air Strip",
      "city": "Asheboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 560,
      "lat": 35.6333007813,
      "lon": -79.7099990845,
      "tz": "America\/New_York"
  },
  "25NE": {
      "icao": "25NE",
      "iata": "",
      "name": "Corr Airport",
      "city": "Melbeta",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4000,
      "lat": 41.7240982056,
      "lon": -103.5110015869,
      "tz": "America\/Denver"
  },
  "25NK": {
      "icao": "25NK",
      "iata": "",
      "name": "Loucks Airport",
      "city": "Hammondsport",
      "state": "New York",
      "country": "US",
      "elevation": 1425,
      "lat": 42.4361991882,
      "lon": -77.1322021484,
      "tz": "America\/New_York"
  },
  "25NM": {
      "icao": "25NM",
      "iata": "",
      "name": "Dream Catcher Ranch Airport",
      "city": "Pie Town",
      "state": "New Mexico",
      "country": "US",
      "elevation": 7253,
      "lat": 34.476944,
      "lon": -108.031111,
      "tz": "America\/Denver"
  },
  "25OH": {
      "icao": "25OH",
      "iata": "",
      "name": "Hammond Airport",
      "city": "Louisville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1050,
      "lat": 40.9131011963,
      "lon": -81.259803772,
      "tz": "America\/New_York"
  },
  "25OI": {
      "icao": "25OI",
      "iata": "",
      "name": "Morrison Field",
      "city": "Bristolville",
      "state": "Ohio",
      "country": "US",
      "elevation": 865,
      "lat": 41.4225997925,
      "lon": -80.8778991699,
      "tz": "America\/New_York"
  },
  "25SC": {
      "icao": "25SC",
      "iata": "",
      "name": "Mountain Ridge Airport",
      "city": "Greenville",
      "state": "South Carolina",
      "country": "US",
      "elevation": 980,
      "lat": 34.960556,
      "lon": -82.339444,
      "tz": "America\/New_York"
  },
  "25TA": {
      "icao": "25TA",
      "iata": "",
      "name": "Ferris Airport",
      "city": "Baytown",
      "state": "Texas",
      "country": "US",
      "elevation": 35,
      "lat": 29.83659935,
      "lon": -94.9673995972,
      "tz": "America\/Chicago"
  },
  "25TE": {
      "icao": "25TE",
      "iata": "",
      "name": "Taylor's Air Park",
      "city": "Joshua",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.4500999451,
      "lon": -97.4002990723,
      "tz": "America\/Chicago"
  },
  "25TS": {
      "icao": "25TS",
      "iata": "",
      "name": "Miller Airfield",
      "city": "Dalhart",
      "state": "Texas",
      "country": "US",
      "elevation": 3961,
      "lat": 36.0917015076,
      "lon": -102.4160003662,
      "tz": "America\/Chicago"
  },
  "25TX": {
      "icao": "25TX",
      "iata": "",
      "name": "Hubbard Airport",
      "city": "Lufkin",
      "state": "Texas",
      "country": "US",
      "elevation": 300,
      "lat": 31.3127994537,
      "lon": -94.6443023682,
      "tz": "America\/Chicago"
  },
  "25VA": {
      "icao": "25VA",
      "iata": "",
      "name": "Toddsbury Farm Airport",
      "city": "Aylett",
      "state": "Virginia",
      "country": "US",
      "elevation": 50,
      "lat": 37.8176002502,
      "lon": -77.1029968262,
      "tz": "America\/New_York"
  },
  "25WA": {
      "icao": "25WA",
      "iata": "",
      "name": "Hart Ranch Airport",
      "city": "Tonasket",
      "state": "Washington",
      "country": "US",
      "elevation": 2608,
      "lat": 48.576499939,
      "lon": -119.0650024414,
      "tz": "America\/Los_Angeles"
  },
  "25WN": {
      "icao": "25WN",
      "iata": "",
      "name": "Little Plum Creek Airport",
      "city": "Pepin",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 12,
      "lat": 44.528718,
      "lon": -92.120341,
      "tz": "America\/Chicago"
  },
  "25XA": {
      "icao": "25XA",
      "iata": "",
      "name": "Headwaters Airport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 1974,
      "lat": 30.098869,
      "lon": -98.704617,
      "tz": "America\/Chicago"
  },
  "25XS": {
      "icao": "25XS",
      "iata": "",
      "name": "O'Brien Airpark",
      "city": "Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 650,
      "lat": 32.4864997864,
      "lon": -96.8463973999,
      "tz": "America\/Chicago"
  },
  "26AL": {
      "icao": "26AL",
      "iata": "",
      "name": "Richardson Field",
      "city": "Mobile",
      "state": "Alabama",
      "country": "US",
      "elevation": 180,
      "lat": 30.6242008209,
      "lon": -88.2786026001,
      "tz": "America\/Chicago"
  },
  "26AR": {
      "icao": "26AR",
      "iata": "",
      "name": "Fly N K Airport",
      "city": "Searcy",
      "state": "Arkansas",
      "country": "US",
      "elevation": 400,
      "lat": 35.2154998779,
      "lon": -91.807800293,
      "tz": "America\/Chicago"
  },
  "26AZ": {
      "icao": "26AZ",
      "iata": "",
      "name": "Flying Dare's Ranch Airport",
      "city": "Aguila",
      "state": "Arizona",
      "country": "US",
      "elevation": 2192,
      "lat": 34.0120010376,
      "lon": -113.2030029297,
      "tz": "America\/Phoenix"
  },
  "26CA": {
      "icao": "26CA",
      "iata": "",
      "name": "Boeckmann Ranch Airport",
      "city": "Wilton",
      "state": "California",
      "country": "US",
      "elevation": 100,
      "lat": 38.4268989563,
      "lon": -121.1719970703,
      "tz": "America\/Los_Angeles"
  },
  "26FD": {
      "icao": "26FD",
      "iata": "",
      "name": "Hennessy Airport",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 27.0512008667,
      "lon": -81.8480987549,
      "tz": "America\/New_York"
  },
  "26GA": {
      "icao": "26GA",
      "iata": "",
      "name": "Murphree Airport",
      "city": "Newnan",
      "state": "Georgia",
      "country": "US",
      "elevation": 760,
      "lat": 33.3362007141,
      "lon": -84.9138031006,
      "tz": "America\/New_York"
  },
  "26ID": {
      "icao": "26ID",
      "iata": "",
      "name": "Seven Shamrock Airport",
      "city": "Sandpoint",
      "state": "Idaho",
      "country": "US",
      "elevation": 2081,
      "lat": 48.363889,
      "lon": -116.403611,
      "tz": "America\/Los_Angeles"
  },
  "26IL": {
      "icao": "26IL",
      "iata": "",
      "name": "Duane E. Davis Airport",
      "city": "Leaf River",
      "state": "Illinois",
      "country": "US",
      "elevation": 741,
      "lat": 42.1197013855,
      "lon": -89.3733978271,
      "tz": "America\/Chicago"
  },
  "26IN": {
      "icao": "26IN",
      "iata": "",
      "name": "Willcox Airport",
      "city": "Knightstown",
      "state": "Indiana",
      "country": "US",
      "elevation": 990,
      "lat": 39.8233985901,
      "lon": -85.5919036865,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "26IS": {
      "icao": "26IS",
      "iata": "",
      "name": "Ficklin-Airtech Airport",
      "city": "Fairbury",
      "state": "Illinois",
      "country": "US",
      "elevation": 672,
      "lat": 40.7667007446,
      "lon": -88.5055999756,
      "tz": "America\/Chicago"
  },
  "26LL": {
      "icao": "26LL",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Sycamore",
      "state": "Illinois",
      "country": "US",
      "elevation": 830,
      "lat": 42.0094985962,
      "lon": -88.7175979614,
      "tz": "America\/Chicago"
  },
  "26MA": {
      "icao": "26MA",
      "iata": "",
      "name": "Pepperell Airport",
      "city": "Pepperell",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 176,
      "lat": 42.6962013245,
      "lon": -71.5501022339,
      "tz": "America\/New_York"
  },
  "26MI": {
      "icao": "26MI",
      "iata": "",
      "name": "Van Strien Company Airport",
      "city": "Lowell",
      "state": "Michigan",
      "country": "US",
      "elevation": 624,
      "lat": 42.9239006042,
      "lon": -85.3780975342,
      "tz": "America\/Detroit"
  },
  "26MN": {
      "icao": "26MN",
      "iata": "",
      "name": "Christianson Field",
      "city": "Halstad",
      "state": "Minnesota",
      "country": "US",
      "elevation": 874,
      "lat": 47.3300018311,
      "lon": -96.7836990356,
      "tz": "America\/Chicago"
  },
  "26MO": {
      "icao": "26MO",
      "iata": "",
      "name": "Taber Field",
      "city": "Theodosia",
      "state": "Missouri",
      "country": "US",
      "elevation": 985,
      "lat": 36.6133995056,
      "lon": -92.7845993042,
      "tz": "America\/Chicago"
  },
  "26MT": {
      "icao": "26MT",
      "iata": "",
      "name": "Hutchinson Airport",
      "city": "Hingham",
      "state": "Montana",
      "country": "US",
      "elevation": 2900,
      "lat": 48.8356018066,
      "lon": -110.4420013428,
      "tz": "America\/Denver"
  },
  "26NC": {
      "icao": "26NC",
      "iata": "",
      "name": "Boonville Airport",
      "city": "Boonville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1003,
      "lat": 36.2229003906,
      "lon": -80.7155990601,
      "tz": "America\/New_York"
  },
  "26ND": {
      "icao": "26ND",
      "iata": "",
      "name": "Chitwood Airstrip",
      "city": "Alexander",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2180,
      "lat": 47.7097015381,
      "lon": -103.6439971924,
      "tz": "America\/Chicago"
  },
  "26NV": {
      "icao": "26NV",
      "iata": "",
      "name": "Darrow Field",
      "city": "Fallon",
      "state": "Nevada",
      "country": "US",
      "elevation": 3965,
      "lat": 39.4534262223,
      "lon": -118.866330385,
      "tz": "America\/Los_Angeles"
  },
  "26OG": {
      "icao": "26OG",
      "iata": "",
      "name": "Oakridge Ranch Airport",
      "city": "Eagle Point",
      "state": "Washington",
      "country": "US",
      "elevation": 1560,
      "lat": 46.463211,
      "lon": -122.733972,
      "tz": "America\/Los_Angeles"
  },
  "26OH": {
      "icao": "26OH",
      "iata": "",
      "name": "Hitz Airport",
      "city": "Louisville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1180,
      "lat": 40.8753013611,
      "lon": -81.2950973511,
      "tz": "America\/New_York"
  },
  "26OK": {
      "icao": "26OK",
      "iata": "",
      "name": "Duck Creek Airport",
      "city": "Mounds",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 700,
      "lat": 35.8821983337,
      "lon": -96.0044021606,
      "tz": "America\/Chicago"
  },
  "26OR": {
      "icao": "26OR",
      "iata": "",
      "name": "Cub Port Airport",
      "city": "Happy Valley",
      "state": "Oregon",
      "country": "US",
      "elevation": 640,
      "lat": 45.4379005432,
      "lon": -122.5279998779,
      "tz": "America\/Los_Angeles"
  },
  "26TX": {
      "icao": "26TX",
      "iata": "",
      "name": "Lowrance Ranch Airport",
      "city": "Truscott",
      "state": "Texas",
      "country": "US",
      "elevation": 1692,
      "lat": 33.7648010254,
      "lon": -100.0179977417,
      "tz": "America\/Chicago"
  },
  "26WI": {
      "icao": "26WI",
      "iata": "",
      "name": "Kitty-Wompus Airport",
      "city": "Exeland",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1336,
      "lat": 45.6991004944,
      "lon": -91.3034973145,
      "tz": "America\/Chicago"
  },
  "26XS": {
      "icao": "26XS",
      "iata": "",
      "name": "Tatum Ranch Airport",
      "city": "Blanco",
      "state": "Texas",
      "country": "US",
      "elevation": 1420,
      "lat": 30.1140995026,
      "lon": -98.4614028931,
      "tz": "America\/Chicago"
  },
  "27AL": {
      "icao": "27AL",
      "iata": "",
      "name": "Little Texas Airport",
      "city": "Tuskegee",
      "state": "Alabama",
      "country": "US",
      "elevation": 500,
      "lat": 32.4534988403,
      "lon": -85.5581970215,
      "tz": "America\/Chicago"
  },
  "27AZ": {
      "icao": "27AZ",
      "iata": "",
      "name": "Eagle Roost Airpark",
      "city": "Aguila",
      "state": "Arizona",
      "country": "US",
      "elevation": 2206,
      "lat": 33.9194984436,
      "lon": -113.1669998169,
      "tz": "America\/Phoenix"
  },
  "27CA": {
      "icao": "27CA",
      "iata": "",
      "name": "Holiday Ranch Airport",
      "city": "Apple Valley",
      "state": "California",
      "country": "US",
      "elevation": 3260,
      "lat": 34.5611000061,
      "lon": -117.0810012817,
      "tz": "America\/Los_Angeles"
  },
  "27CL": {
      "icao": "27CL",
      "iata": "",
      "name": "Sopwith Farm Airport",
      "city": "Pleasant Grove",
      "state": "California",
      "country": "US",
      "elevation": 20,
      "lat": 38.7471008301,
      "lon": -121.5729980469,
      "tz": "America\/Los_Angeles"
  },
  "27CO": {
      "icao": "27CO",
      "iata": "",
      "name": "Roubideau Airport",
      "city": "Delta",
      "state": "Colorado",
      "country": "US",
      "elevation": 4900,
      "lat": 38.7260017395,
      "lon": -108.1289978027,
      "tz": "America\/Denver"
  },
  "27FL": {
      "icao": "27FL",
      "iata": "",
      "name": "Watson Airport",
      "city": "Campbellton",
      "state": "Florida",
      "country": "US",
      "elevation": 122,
      "lat": 30.9526996613,
      "lon": -85.4173965454,
      "tz": "America\/Chicago"
  },
  "27GA": {
      "icao": "27GA",
      "iata": "",
      "name": "Wilson International Airport",
      "city": "Carrollton",
      "state": "Georgia",
      "country": "US",
      "elevation": 1050,
      "lat": 33.6584014893,
      "lon": -85.0099029541,
      "tz": "America\/New_York"
  },
  "27IA": {
      "icao": "27IA",
      "iata": "",
      "name": "Oldfield Aero",
      "city": "Keosauqua",
      "state": "Iowa",
      "country": "US",
      "elevation": 750,
      "lat": 40.7513008118,
      "lon": -92.0307006836,
      "tz": "America\/Chicago"
  },
  "27IS": {
      "icao": "27IS",
      "iata": "",
      "name": "Gelfius International Airport",
      "city": "Dahlgren",
      "state": "Illinois",
      "country": "US",
      "elevation": 530,
      "lat": 38.1488990784,
      "lon": -88.6703033447,
      "tz": "America\/Chicago"
  },
  "27KS": {
      "icao": "27KS",
      "iata": "",
      "name": "Bellamy Farm Airport",
      "city": "Goodland",
      "state": "Kansas",
      "country": "US",
      "elevation": 3650,
      "lat": 39.1288986206,
      "lon": -101.7170028687,
      "tz": "America\/Denver"
  },
  "27LL": {
      "icao": "27LL",
      "iata": "",
      "name": "Ralph Jacobs Airport",
      "city": "Valmeyer",
      "state": "Illinois",
      "country": "US",
      "elevation": 400,
      "lat": 38.3750991821,
      "lon": -90.3192977905,
      "tz": "America\/Chicago"
  },
  "27MI": {
      "icao": "27MI",
      "iata": "",
      "name": "Miller Field",
      "city": "Vassar",
      "state": "Michigan",
      "country": "US",
      "elevation": 705,
      "lat": 43.3727989197,
      "lon": -83.4866027832,
      "tz": "America\/Detroit"
  },
  "27MN": {
      "icao": "27MN",
      "iata": "",
      "name": "Lake Bronson Airport",
      "city": "Lake Bronson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 960,
      "lat": 48.7374992371,
      "lon": -96.6545028687,
      "tz": "America\/Chicago"
  },
  "27MO": {
      "icao": "27MO",
      "iata": "",
      "name": "Route 66 Airfield",
      "city": "Carthage",
      "state": "Missouri",
      "country": "US",
      "elevation": 1055,
      "lat": 37.193901062,
      "lon": -94.2241973877,
      "tz": "America\/Chicago"
  },
  "27MT": {
      "icao": "27MT",
      "iata": "",
      "name": "Rahn Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 3090,
      "lat": 48.3218994141,
      "lon": -114.327003479,
      "tz": "America\/Denver"
  },
  "27NE": {
      "icao": "27NE",
      "iata": "",
      "name": "Hendricks Field",
      "city": "Grant",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3450,
      "lat": 40.7228012085,
      "lon": -101.7959976196,
      "tz": "America\/Denver"
  },
  "27NK": {
      "icao": "27NK",
      "iata": "",
      "name": "Mohawk Air Park",
      "city": "Schyler",
      "state": "New York",
      "country": "US",
      "elevation": 1079,
      "lat": 43.113317,
      "lon": -75.044467,
      "tz": "America\/New_York"
  },
  "27NR": {
      "icao": "27NR",
      "iata": "",
      "name": "Angier (C A G Farms) Airport",
      "city": "Angier",
      "state": "North Carolina",
      "country": "US",
      "elevation": 310,
      "lat": 35.4687616608,
      "lon": -78.6520028114,
      "tz": "America\/New_York"
  },
  "27OH": {
      "icao": "27OH",
      "iata": "",
      "name": "Schaller Airport",
      "city": "Findlay",
      "state": "Ohio",
      "country": "US",
      "elevation": 830,
      "lat": 40.9467010498,
      "lon": -83.679901123,
      "tz": "America\/New_York"
  },
  "27OI": {
      "icao": "27OI",
      "iata": "",
      "name": "Auburn Airport",
      "city": "Chagrin Falls",
      "state": "Ohio",
      "country": "US",
      "elevation": 1200,
      "lat": 41.4103012085,
      "lon": -81.2843017578,
      "tz": "America\/New_York"
  },
  "27OK": {
      "icao": "27OK",
      "iata": "",
      "name": "Thomas Landing Airport",
      "city": "Ponca City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1110,
      "lat": 36.7422981262,
      "lon": -96.9638977051,
      "tz": "America\/Chicago"
  },
  "27PN": {
      "icao": "27PN",
      "iata": "",
      "name": "Shelley Private Airport",
      "city": "Richfield",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 750,
      "lat": 40.6811981201,
      "lon": -77.1619033813,
      "tz": "America\/New_York"
  },
  "27WA": {
      "icao": "27WA",
      "iata": "",
      "name": "J C's Airport",
      "city": "Conway",
      "state": "Washington",
      "country": "US",
      "elevation": 236,
      "lat": 48.3250999451,
      "lon": -122.3160018921,
      "tz": "America\/Los_Angeles"
  },
  "27WI": {
      "icao": "27WI",
      "iata": "",
      "name": "Tegeler Airport",
      "city": "Prescott",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 950,
      "lat": 44.8077011108,
      "lon": -92.7137985229,
      "tz": "America\/Chicago"
  },
  "27XA": {
      "icao": "27XA",
      "iata": "",
      "name": "Arnett Landing Airport",
      "city": "Poolville",
      "state": "Texas",
      "country": "US",
      "elevation": 1180,
      "lat": 32.979389,
      "lon": -97.889917,
      "tz": "America\/Chicago"
  },
  "27XS": {
      "icao": "27XS",
      "iata": "",
      "name": "Sport Flyers Airport",
      "city": "Brookshire",
      "state": "Texas",
      "country": "US",
      "elevation": 180,
      "lat": 29.8379993439,
      "lon": -95.9494018555,
      "tz": "America\/Chicago"
  },
  "28AK": {
      "icao": "28AK",
      "iata": "",
      "name": "Farrars Airport",
      "city": "Sutton",
      "state": "Alaska",
      "country": "US",
      "elevation": 1875,
      "lat": 61.8088989258,
      "lon": -147.8589935303,
      "tz": "America\/Anchorage"
  },
  "28AL": {
      "icao": "28AL",
      "iata": "",
      "name": "Golden Pond Airport",
      "city": "Ashville",
      "state": "Alabama",
      "country": "US",
      "elevation": 540,
      "lat": 33.8625984192,
      "lon": -86.1206970215,
      "tz": "America\/Chicago"
  },
  "28AR": {
      "icao": "28AR",
      "iata": "",
      "name": "Henley Aerodrome",
      "city": "Charlotte",
      "state": "Arkansas",
      "country": "US",
      "elevation": 377,
      "lat": 35.8333015442,
      "lon": -91.4332962036,
      "tz": "America\/Chicago"
  },
  "28AZ": {
      "icao": "28AZ",
      "iata": "",
      "name": "Sampley's Airport",
      "city": "Aguila",
      "state": "Arizona",
      "country": "US",
      "elevation": 2208,
      "lat": 33.9227981567,
      "lon": -113.1809997559,
      "tz": "America\/Phoenix"
  },
  "28CA": {
      "icao": "28CA",
      "iata": "",
      "name": "Dos Palos Airport",
      "city": "Dos Palos",
      "state": "California",
      "country": "US",
      "elevation": 118,
      "lat": 36.9623985291,
      "lon": -120.6299972534,
      "tz": "America\/Los_Angeles"
  },
  "28FL": {
      "icao": "28FL",
      "iata": "",
      "name": "Jim Finlay Farm Airport",
      "city": "Cresent City",
      "state": "Florida",
      "country": "US",
      "elevation": 40,
      "lat": 29.4419002533,
      "lon": -81.5830993652,
      "tz": "America\/New_York"
  },
  "28II": {
      "icao": "28II",
      "iata": "",
      "name": "Cedar Farm Airport",
      "city": "Laconia",
      "state": "Indiana",
      "country": "US",
      "elevation": 453,
      "lat": 37.9747009277,
      "lon": -86.0561981201,
      "tz": "America\/Kentucky\/Louisville"
  },
  "28IL": {
      "icao": "28IL",
      "iata": "",
      "name": "West Grove Airport",
      "city": "Leaf River",
      "state": "Illinois",
      "country": "US",
      "elevation": 910,
      "lat": 42.0971984863,
      "lon": -89.4192962646,
      "tz": "America\/Chicago"
  },
  "28IN": {
      "icao": "28IN",
      "iata": "",
      "name": "Marchino Field",
      "city": "Vincennes",
      "state": "Indiana",
      "country": "US",
      "elevation": 540,
      "lat": 38.6377983093,
      "lon": -87.4347000122,
      "tz": "America\/Indiana\/Vincennes"
  },
  "28IS": {
      "icao": "28IS",
      "iata": "",
      "name": "O K Flying Club Inc Airport",
      "city": "Fillmore",
      "state": "Illinois",
      "country": "US",
      "elevation": 665,
      "lat": 39.1948013306,
      "lon": -89.3117980957,
      "tz": "America\/Chicago"
  },
  "28KS": {
      "icao": "28KS",
      "iata": "",
      "name": "Highland Farm Airport",
      "city": "Rantoul",
      "state": "Kansas",
      "country": "US",
      "elevation": 1010,
      "lat": 38.5424995422,
      "lon": -95.1560974121,
      "tz": "America\/Chicago"
  },
  "28KY": {
      "icao": "28KY",
      "iata": "",
      "name": "Brandon Airdrome Airport",
      "city": "Murray",
      "state": "Kentucky",
      "country": "US",
      "elevation": 525,
      "lat": 36.5759010315,
      "lon": -88.3134002686,
      "tz": "America\/Chicago"
  },
  "28LL": {
      "icao": "28LL",
      "iata": "",
      "name": "Williamson Airport",
      "city": "Westervelt",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 39.4603004456,
      "lon": -88.8684005737,
      "tz": "America\/Chicago"
  },
  "28MA": {
      "icao": "28MA",
      "iata": "",
      "name": "Cannizzaro Field",
      "city": "Southwick",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 250,
      "lat": 42.0797996521,
      "lon": -72.8180999756,
      "tz": "America\/New_York"
  },
  "28MI": {
      "icao": "28MI",
      "iata": "",
      "name": "Miller-Herrold Airport",
      "city": "Cedar",
      "state": "Michigan",
      "country": "US",
      "elevation": 624,
      "lat": 44.8744010925,
      "lon": -85.7926025391,
      "tz": "America\/Detroit"
  },
  "28MO": {
      "icao": "28MO",
      "iata": "",
      "name": "Pasley Airport",
      "city": "Roscoe",
      "state": "Missouri",
      "country": "US",
      "elevation": 790,
      "lat": 37.9738998413,
      "lon": -93.8188018799,
      "tz": "America\/Chicago"
  },
  "28MT": {
      "icao": "28MT",
      "iata": "",
      "name": "Cain Ranch Airport",
      "city": "Lincoln",
      "state": "Montana",
      "country": "US",
      "elevation": 4480,
      "lat": 46.953556,
      "lon": -112.748083,
      "tz": "America\/Denver"
  },
  "28NE": {
      "icao": "28NE",
      "iata": "",
      "name": "Thies Airport",
      "city": "Randolph",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1750,
      "lat": 42.4416999817,
      "lon": -97.2966995239,
      "tz": "America\/Chicago"
  },
  "28NK": {
      "icao": "28NK",
      "iata": "",
      "name": "Ritchie Airfield",
      "city": "Clayton",
      "state": "New York",
      "country": "US",
      "elevation": 385,
      "lat": 44.186944,
      "lon": -76.076389,
      "tz": "America\/New_York"
  },
  "28NY": {
      "icao": "28NY",
      "iata": "",
      "name": "Spaudling Aerodrome",
      "city": "Cassadaga",
      "state": "New York",
      "country": "US",
      "elevation": 1607,
      "lat": 42.308333,
      "lon": -79.225,
      "tz": "America\/New_York"
  },
  "28OH": {
      "icao": "28OH",
      "iata": "",
      "name": "Newbury Airport",
      "city": "Lyons",
      "state": "Ohio",
      "country": "US",
      "elevation": 743,
      "lat": 41.7033996582,
      "lon": -84.0027008057,
      "tz": "America\/New_York"
  },
  "28OK": {
      "icao": "28OK",
      "iata": "",
      "name": "Pellar Farm Airport",
      "city": "El Reno",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1400,
      "lat": 35.5027999878,
      "lon": -98.0406036377,
      "tz": "America\/Chicago"
  },
  "28OR": {
      "icao": "28OR",
      "iata": "",
      "name": "Parrett Mountain Airport",
      "city": "Newberg",
      "state": "Oregon",
      "country": "US",
      "elevation": 790,
      "lat": 45.4790000916,
      "lon": -122.2340011597,
      "tz": "America\/Los_Angeles"
  },
  "28PA": {
      "icao": "28PA",
      "iata": "",
      "name": "Yarrow Airport",
      "city": "Schwenksville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 330,
      "lat": 40.2512016296,
      "lon": -75.4455032349,
      "tz": "America\/New_York"
  },
  "28TA": {
      "icao": "28TA",
      "iata": "",
      "name": "Duval County Ranch Co Airport",
      "city": "Freer",
      "state": "Texas",
      "country": "US",
      "elevation": 665,
      "lat": 27.83359909,
      "lon": -98.71700287,
      "tz": "America\/Chicago"
  },
  "28TE": {
      "icao": "28TE",
      "iata": "",
      "name": "Midlake Airport",
      "city": "Elmendorf",
      "state": "Texas",
      "country": "US",
      "elevation": 535,
      "lat": 29.2682991028,
      "lon": -98.3369979858,
      "tz": "America\/Chicago"
  },
  "28TS": {
      "icao": "28TS",
      "iata": "",
      "name": "Boe-Wrinkle Airport",
      "city": "Azle",
      "state": "Texas",
      "country": "US",
      "elevation": 802,
      "lat": 32.904800415,
      "lon": -97.5950012207,
      "tz": "America\/Chicago"
  },
  "28TX": {
      "icao": "28TX",
      "iata": "",
      "name": "Lehman Airport",
      "city": "Vernon",
      "state": "Texas",
      "country": "US",
      "elevation": 1275,
      "lat": 34.085899353,
      "lon": -99.4551010132,
      "tz": "America\/Chicago"
  },
  "28WA": {
      "icao": "28WA",
      "iata": "",
      "name": "Robert L Delanoy Airport",
      "city": "Vancouver",
      "state": "Washington",
      "country": "US",
      "elevation": 30,
      "lat": 45.7304000854,
      "lon": -122.7350006104,
      "tz": "America\/Los_Angeles"
  },
  "28WI": {
      "icao": "28WI",
      "iata": "",
      "name": "Rutherford Airport",
      "city": "Arkansaw",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 790,
      "lat": 44.6040992737,
      "lon": -92.0466003418,
      "tz": "America\/Chicago"
  },
  "28XS": {
      "icao": "28XS",
      "iata": "",
      "name": "Flying G Airport",
      "city": "Kaufman",
      "state": "Texas",
      "country": "US",
      "elevation": 470,
      "lat": 32.6459999084,
      "lon": -96.325302124,
      "tz": "America\/Chicago"
  },
  "29AK": {
      "icao": "29AK",
      "iata": "",
      "name": "Remington Field",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1250,
      "lat": 64.0495986938,
      "lon": -145.4190063477,
      "tz": "America\/Anchorage"
  },
  "29AZ": {
      "icao": "29AZ",
      "iata": "",
      "name": "Paloma Ranch Airport",
      "city": "Paloma",
      "state": "Arizona",
      "country": "US",
      "elevation": 737,
      "lat": 32.9056015015,
      "lon": -112.9000015259,
      "tz": "America\/Phoenix"
  },
  "29CN": {
      "icao": "29CN",
      "iata": "",
      "name": "Dubey Airport",
      "city": "Georgetown",
      "state": "California",
      "country": "US",
      "elevation": 2400,
      "lat": 38.849899292,
      "lon": -120.8339996338,
      "tz": "America\/Los_Angeles"
  },
  "29GA": {
      "icao": "29GA",
      "iata": "",
      "name": "Rolling Meadows Airfield",
      "city": "Sharpsburg",
      "state": "Georgia",
      "country": "US",
      "elevation": 808,
      "lat": 33.3843002319,
      "lon": -84.6318969727,
      "tz": "America\/New_York"
  },
  "29II": {
      "icao": "29II",
      "iata": "",
      "name": "Norm's Airport",
      "city": "Michigan City",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 41.6598014832,
      "lon": -86.8075027466,
      "tz": "America\/Chicago"
  },
  "29IN": {
      "icao": "29IN",
      "iata": "",
      "name": "Homestead Airport",
      "city": "Columbia City",
      "state": "Indiana",
      "country": "US",
      "elevation": 863,
      "lat": 41.0759010315,
      "lon": -85.386100769,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "29KS": {
      "icao": "29KS",
      "iata": "",
      "name": "Navrat Airport",
      "city": "Lincolnville",
      "state": "Kansas",
      "country": "US",
      "elevation": 1470,
      "lat": 38.48279953,
      "lon": -97.0113983154,
      "tz": "America\/Chicago"
  },
  "29KY": {
      "icao": "29KY",
      "iata": "",
      "name": "Cramer Aerodrome",
      "city": "Hopkinsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 640,
      "lat": 36.8931007385,
      "lon": -87.4272003174,
      "tz": "America\/Chicago"
  },
  "29MD": {
      "icao": "29MD",
      "iata": "",
      "name": "Schlosser Airport",
      "city": "Galena",
      "state": "Maryland",
      "country": "US",
      "elevation": 53,
      "lat": 39.3628005981,
      "lon": -75.8127975464,
      "tz": "America\/New_York"
  },
  "29MI": {
      "icao": "29MI",
      "iata": "",
      "name": "Sawyer Field",
      "city": "Mason",
      "state": "Michigan",
      "country": "US",
      "elevation": 910,
      "lat": 42.5999984741,
      "lon": -84.4499969482,
      "tz": "America\/Detroit"
  },
  "29MN": {
      "icao": "29MN",
      "iata": "",
      "name": "Wetenkamp Airport",
      "city": "Tintah",
      "state": "Minnesota",
      "country": "US",
      "elevation": 990,
      "lat": 46.028301239,
      "lon": -96.3628005981,
      "tz": "America\/Chicago"
  },
  "29MO": {
      "icao": "29MO",
      "iata": "",
      "name": "Rollert Farm Airport",
      "city": "Smithville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.3507995605,
      "lon": -94.5186004639,
      "tz": "America\/Chicago"
  },
  "29MT": {
      "icao": "29MT",
      "iata": "",
      "name": "Craik Airport",
      "city": "Trout Creek",
      "state": "Montana",
      "country": "US",
      "elevation": 2500,
      "lat": 47.738899231,
      "lon": -115.5029983521,
      "tz": "America\/Denver"
  },
  "29NC": {
      "icao": "29NC",
      "iata": "",
      "name": "29NC Airport",
      "city": "Shelby",
      "state": "North Carolina",
      "country": "US",
      "elevation": 930,
      "lat": 35.3116989136,
      "lon": -81.6252975464,
      "tz": "America\/New_York"
  },
  "29NE": {
      "icao": "29NE",
      "iata": "",
      "name": "Bartmess Airport",
      "city": "Wallace",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3150,
      "lat": 40.761100769,
      "lon": -101.1809997559,
      "tz": "America\/Chicago"
  },
  "29OH": {
      "icao": "29OH",
      "iata": "",
      "name": "Fox Airport",
      "city": "Magnolia",
      "state": "Ohio",
      "country": "US",
      "elevation": 1020,
      "lat": 40.6864013672,
      "lon": -81.3025970459,
      "tz": "America\/New_York"
  },
  "29OK": {
      "icao": "29OK",
      "iata": "",
      "name": "Ditch Witch Airport",
      "city": "Perry",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1120,
      "lat": 36.2999992371,
      "lon": -97.3170013428,
      "tz": "America\/Chicago"
  },
  "29OR": {
      "icao": "29OR",
      "iata": "",
      "name": "Smith Private Airport",
      "city": "Brooks",
      "state": "Oregon",
      "country": "US",
      "elevation": 180,
      "lat": 45.0317993164,
      "lon": -122.9570007324,
      "tz": "America\/Los_Angeles"
  },
  "29PA": {
      "icao": "29PA",
      "iata": "",
      "name": "Gardner Airport",
      "city": "Breinigsville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 500,
      "lat": 40.5568008423,
      "lon": -75.6432037354,
      "tz": "America\/New_York"
  },
  "29SC": {
      "icao": "29SC",
      "iata": "",
      "name": "Hannah Rhea Field",
      "city": "Walterboro",
      "state": "South Carolina",
      "country": "US",
      "elevation": 89,
      "lat": 32.9014015198,
      "lon": -80.8535995483,
      "tz": "America\/New_York"
  },
  "29TA": {
      "icao": "29TA",
      "iata": "",
      "name": "Cactus Hill Airport",
      "city": "Wichita Falls",
      "state": "Texas",
      "country": "US",
      "elevation": 1040,
      "lat": 33.873053,
      "lon": -98.645906,
      "tz": "America\/Chicago"
  },
  "29TX": {
      "icao": "29TX",
      "iata": "",
      "name": "Lockett Airport",
      "city": "Vernon",
      "state": "Texas",
      "country": "US",
      "elevation": 1282,
      "lat": 34.0945014954,
      "lon": -99.3656005859,
      "tz": "America\/Chicago"
  },
  "29VA": {
      "icao": "29VA",
      "iata": "",
      "name": "Rhynalds Ranch Airport",
      "city": "Remington",
      "state": "Virginia",
      "country": "US",
      "elevation": 285,
      "lat": 38.5293006897,
      "lon": -77.738899231,
      "tz": "America\/New_York"
  },
  "29WI": {
      "icao": "29WI",
      "iata": "",
      "name": "Whittlesey Cranberry Co Airport",
      "city": "Babcock",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 982,
      "lat": 44.3255004883,
      "lon": -90.0273971558,
      "tz": "America\/Chicago"
  },
  "2AK0": {
      "icao": "2AK0",
      "iata": "",
      "name": "Mac Kenzie Country Airpark",
      "city": "Point Mackenzie",
      "state": "Alaska",
      "country": "US",
      "elevation": 120,
      "lat": 61.2559013367,
      "lon": -149.975006104,
      "tz": "America\/Anchorage"
  },
  "2AK1": {
      "icao": "2AK1",
      "iata": "",
      "name": "Blair Lake Airport",
      "city": "Fairbanks \/Ft Wainwright\/",
      "state": "Alaska",
      "country": "US",
      "elevation": 910,
      "lat": 64.3637008667,
      "lon": -147.3639984131,
      "tz": "America\/Anchorage"
  },
  "2AK2": {
      "icao": "2AK2",
      "iata": "",
      "name": "Clear Creek Airport",
      "city": "Fairbanks \/Ft Wainwright\/",
      "state": "Alaska",
      "country": "US",
      "elevation": 660,
      "lat": 64.4534988403,
      "lon": -147.56300354,
      "tz": "America\/Anchorage"
  },
  "2AK4": {
      "icao": "2AK4",
      "iata": "",
      "name": "Oyster Cove Airport",
      "city": "Homer",
      "state": "Alaska",
      "country": "US",
      "elevation": 45,
      "lat": 59.4700012207,
      "lon": -151.5119934082,
      "tz": "America\/Anchorage"
  },
  "2AK6": {
      "icao": "2AK6",
      "iata": "HGZ",
      "name": "Hog River Airport",
      "city": "Hogatza",
      "state": "Alaska",
      "country": "US",
      "elevation": 534,
      "lat": 66.2161026,
      "lon": -155.6690063,
      "tz": "America\/Anchorage"
  },
  "2AK7": {
      "icao": "2AK7",
      "iata": "",
      "name": "Bald Mountain Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 3600,
      "lat": 62.3073997498,
      "lon": -149.7519989014,
      "tz": "America\/Anchorage"
  },
  "2AK8": {
      "icao": "2AK8",
      "iata": "",
      "name": "Icy Cape Afs Airport",
      "city": "Icy Cape",
      "state": "Alaska",
      "country": "US",
      "elevation": 48,
      "lat": 70.2925033569,
      "lon": -161.9029998779,
      "tz": "America\/Anchorage"
  },
  "2AK9": {
      "icao": "2AK9",
      "iata": "",
      "name": "Independence Creek Airport",
      "city": "Independence Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 250,
      "lat": 65.673500061,
      "lon": -162.4640045166,
      "tz": "America\/Nome"
  },
  "2AL0": {
      "icao": "2AL0",
      "iata": "",
      "name": "Paulling Place Airstrip",
      "city": "Dayton",
      "state": "Alabama",
      "country": "US",
      "elevation": 275,
      "lat": 32.3517990112,
      "lon": -87.5456008911,
      "tz": "America\/Chicago"
  },
  "2AL1": {
      "icao": "2AL1",
      "iata": "",
      "name": "Collier Airpark",
      "city": "Magnolia Springs",
      "state": "Alabama",
      "country": "US",
      "elevation": 74,
      "lat": 30.4241008759,
      "lon": -87.7778015137,
      "tz": "America\/Chicago"
  },
  "2AL3": {
      "icao": "2AL3",
      "iata": "",
      "name": "Emerald Mountain Airport",
      "city": "Wetumpka",
      "state": "Alabama",
      "country": "US",
      "elevation": 365,
      "lat": 32.4553985596,
      "lon": -86.1192016602,
      "tz": "America\/Chicago"
  },
  "2AL5": {
      "icao": "2AL5",
      "iata": "",
      "name": "Flying J Ranch Airport",
      "city": "Fort Payne",
      "state": "Alabama",
      "country": "US",
      "elevation": 1430,
      "lat": 34.3927993774,
      "lon": -85.6650009155,
      "tz": "America\/Chicago"
  },
  "2AL6": {
      "icao": "2AL6",
      "iata": "",
      "name": "Dale O. Galer Aerodrome",
      "city": "Theodore",
      "state": "Alabama",
      "country": "US",
      "elevation": 40,
      "lat": 30.4993000031,
      "lon": -88.1981964111,
      "tz": "America\/Chicago"
  },
  "2AL7": {
      "icao": "2AL7",
      "iata": "",
      "name": "Mount Aero Lake Farm Airport",
      "city": "Hanceville",
      "state": "Alabama",
      "country": "US",
      "elevation": 625,
      "lat": 34.0628013611,
      "lon": -86.7230987549,
      "tz": "America\/Chicago"
  },
  "2AL8": {
      "icao": "2AL8",
      "iata": "",
      "name": "Finkley Farm Airport",
      "city": "Phenix City",
      "state": "Alabama",
      "country": "US",
      "elevation": 413,
      "lat": 32.4211006165,
      "lon": -85.2481002808,
      "tz": "America\/Chicago"
  },
  "2AR0": {
      "icao": "2AR0",
      "iata": "",
      "name": "Bully Henry Airport",
      "city": "Lonoke",
      "state": "Arkansas",
      "country": "US",
      "elevation": 245,
      "lat": 34.8680992126,
      "lon": -91.9742965698,
      "tz": "America\/Chicago"
  },
  "2AR1": {
      "icao": "2AR1",
      "iata": "",
      "name": "Seratt Airport",
      "city": "Rogers",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1305,
      "lat": 36.292098999,
      "lon": -94.0952987671,
      "tz": "America\/Chicago"
  },
  "2AR2": {
      "icao": "2AR2",
      "iata": "",
      "name": "Davidson Field",
      "city": "Sage",
      "state": "Arkansas",
      "country": "US",
      "elevation": 835,
      "lat": 36.0152015686,
      "lon": -91.7966003418,
      "tz": "America\/Chicago"
  },
  "2AR4": {
      "icao": "2AR4",
      "iata": "",
      "name": "Jaynes Field",
      "city": "Trumann",
      "state": "Arkansas",
      "country": "US",
      "elevation": 212,
      "lat": 35.6707000732,
      "lon": -90.5278015137,
      "tz": "America\/Chicago"
  },
  "2AR5": {
      "icao": "2AR5",
      "iata": "",
      "name": "Ashmore Field",
      "city": "Centerton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1362,
      "lat": 36.3830986023,
      "lon": -94.2938995361,
      "tz": "America\/Chicago"
  },
  "2AR6": {
      "icao": "2AR6",
      "iata": "",
      "name": "Glenn Winchester Airport",
      "city": "Mineral Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 365,
      "lat": 33.8818016052,
      "lon": -93.9284973145,
      "tz": "America\/Chicago"
  },
  "2AR7": {
      "icao": "2AR7",
      "iata": "",
      "name": "Head Airfield",
      "city": "Prescott",
      "state": "Arkansas",
      "country": "US",
      "elevation": 441,
      "lat": 33.8490982056,
      "lon": -93.4791030884,
      "tz": "America\/Chicago"
  },
  "2AR8": {
      "icao": "2AR8",
      "iata": "",
      "name": "L C Hickman Airport",
      "city": "Centerton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1380,
      "lat": 36.3527984619,
      "lon": -94.3330993652,
      "tz": "America\/Chicago"
  },
  "2AZ1": {
      "icao": "2AZ1",
      "iata": "",
      "name": "Indian Hills Airpark",
      "city": "Salome",
      "state": "Arizona",
      "country": "US",
      "elevation": 1866,
      "lat": 33.7592010498,
      "lon": -113.6149978638,
      "tz": "America\/Phoenix"
  },
  "2AZ3": {
      "icao": "2AZ3",
      "iata": "",
      "name": "El Mirage-Village Square Airport",
      "city": "El Mirage",
      "state": "Arizona",
      "country": "US",
      "elevation": 1150,
      "lat": 33.6128005981,
      "lon": -112.3349990845,
      "tz": "America\/Phoenix"
  },
  "2AZ4": {
      "icao": "2AZ4",
      "iata": "",
      "name": "Millar Airport",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1215,
      "lat": 33.0536994934,
      "lon": -112.1460037231,
      "tz": "America\/Phoenix"
  },
  "2AZ5": {
      "icao": "2AZ5",
      "iata": "",
      "name": "Donnelly Residence Airport",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1518,
      "lat": 32.8863983154,
      "lon": -112.1299972534,
      "tz": "America\/Phoenix"
  },
  "2AZ7": {
      "icao": "2AZ7",
      "iata": "",
      "name": "Mc Gill's Ultralight Field",
      "city": "Phoenix",
      "state": "Arizona",
      "country": "US",
      "elevation": 1360,
      "lat": 33.6842002869,
      "lon": -112.164001465,
      "tz": "America\/Phoenix"
  },
  "2AZ8": {
      "icao": "2AZ8",
      "iata": "",
      "name": "Tubac Ultralight Flightpark Ultralightport",
      "city": "Tubac",
      "state": "Arizona",
      "country": "US",
      "elevation": 3200,
      "lat": 31.6145000458,
      "lon": -111.0360031128,
      "tz": "America\/Phoenix"
  },
  "2AZ9": {
      "icao": "2AZ9",
      "iata": "",
      "name": "Tribal Air Airport",
      "city": "Mcneal",
      "state": "Arizona",
      "country": "US",
      "elevation": 4200,
      "lat": 31.6023006439,
      "lon": -109.6510009766,
      "tz": "America\/Phoenix"
  },
  "2CA2": {
      "icao": "2CA2",
      "iata": "",
      "name": "Cones Field",
      "city": "Twentynine Palms",
      "state": "California",
      "country": "US",
      "elevation": 1800,
      "lat": 34.1627998352,
      "lon": -116.047996521,
      "tz": "America\/Los_Angeles"
  },
  "2CA3": {
      "icao": "2CA3",
      "iata": "",
      "name": "Crosswinds Airport",
      "city": "Twentynine Palms",
      "state": "California",
      "country": "US",
      "elevation": 1835,
      "lat": 34.1599998474,
      "lon": -115.9970016479,
      "tz": "America\/Los_Angeles"
  },
  "2CA4": {
      "icao": "2CA4",
      "iata": "",
      "name": "Blackinton Airport",
      "city": "Valley Center",
      "state": "California",
      "country": "US",
      "elevation": 1156,
      "lat": 33.2588996887,
      "lon": -117.0920028687,
      "tz": "America\/Los_Angeles"
  },
  "2CA8": {
      "icao": "2CA8",
      "iata": "",
      "name": "B & E Ranch Airport",
      "city": "Yucca Valley",
      "state": "California",
      "country": "US",
      "elevation": 2793,
      "lat": 34.4206008911,
      "lon": -116.611000061,
      "tz": "America\/Los_Angeles"
  },
  "2CD3": {
      "icao": "2CD3",
      "iata": "",
      "name": "Bijou Springs Ranch Airport",
      "city": "Peyton",
      "state": "Colorado",
      "country": "US",
      "elevation": 6600,
      "lat": 39.119472,
      "lon": -104.431806,
      "tz": "America\/Denver"
  },
  "2CD6": {
      "icao": "2CD6",
      "iata": "",
      "name": "Safer Airport",
      "city": "Elizabeth",
      "state": "Colorado",
      "country": "US",
      "elevation": 6510,
      "lat": 39.39485,
      "lon": -104.547033,
      "tz": "America\/Denver"
  },
  "2CD9": {
      "icao": "2CD9",
      "iata": "",
      "name": "Kellogg Airstrip",
      "city": "Laporte",
      "state": "Colorado",
      "country": "US",
      "elevation": 5180,
      "lat": 40.6814,
      "lon": -105.13855,
      "tz": "America\/Denver"
  },
  "2CL2": {
      "icao": "2CL2",
      "iata": "",
      "name": "Mc Cabe Ranch Airport",
      "city": "Arbuckle",
      "state": "California",
      "country": "US",
      "elevation": 65,
      "lat": 39.0110015869,
      "lon": -122.097999573,
      "tz": "America\/Los_Angeles"
  },
  "2CL3": {
      "icao": "2CL3",
      "iata": "",
      "name": "Longbell Ranch Airport",
      "city": "Tennant",
      "state": "California",
      "country": "US",
      "elevation": 4607,
      "lat": 41.6421012878,
      "lon": -121.8899993896,
      "tz": "America\/Los_Angeles"
  },
  "2CL9": {
      "icao": "2CL9",
      "iata": "",
      "name": "Mustang Airport",
      "city": "Galt",
      "state": "California",
      "country": "US",
      "elevation": 45,
      "lat": 38.334400177,
      "lon": -121.3040008545,
      "tz": "America\/Los_Angeles"
  },
  "2CN3": {
      "icao": "2CN3",
      "iata": "",
      "name": "Tenaja Valley Airport",
      "city": "Murrieta",
      "state": "California",
      "country": "US",
      "elevation": 2350,
      "lat": 33.511111,
      "lon": -117.328611,
      "tz": "America\/Los_Angeles"
  },
  "2CN4": {
      "icao": "2CN4",
      "iata": "",
      "name": "Paramount Farms Airport",
      "city": "Lost Hills",
      "state": "California",
      "country": "US",
      "elevation": 617,
      "lat": 35.6663888889,
      "lon": -119.896388889,
      "tz": "America\/Los_Angeles"
  },
  "2CN8": {
      "icao": "2CN8",
      "iata": "",
      "name": "Lake Arrowhead Airport",
      "city": "Lake Arrowhead",
      "state": "California",
      "country": "US",
      "elevation": 4610,
      "lat": 34.304167,
      "lon": -117.151389,
      "tz": "America\/Los_Angeles"
  },
  "2CO1": {
      "icao": "2CO1",
      "iata": "",
      "name": "Cherokee Trail Ranch Airport",
      "city": "Peyton",
      "state": "Colorado",
      "country": "US",
      "elevation": 7240,
      "lat": 39.1105003357,
      "lon": -104.5839996338,
      "tz": "America\/Denver"
  },
  "2CO2": {
      "icao": "2CO2",
      "iata": "",
      "name": "CMRS Airdrome Airport",
      "city": "Poncha Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 7872,
      "lat": 38.5402984619,
      "lon": -106.105003357,
      "tz": "America\/Denver"
  },
  "2CO3": {
      "icao": "2CO3",
      "iata": "",
      "name": "Jackrabbit Strip",
      "city": "Milliken",
      "state": "Colorado",
      "country": "US",
      "elevation": 5000,
      "lat": 40.375,
      "lon": -104.8730010986,
      "tz": "America\/Denver"
  },
  "2CO5": {
      "icao": "2CO5",
      "iata": "",
      "name": "Edenway Airport",
      "city": "Pueblo",
      "state": "Colorado",
      "country": "US",
      "elevation": 4970,
      "lat": 38.3471984863,
      "lon": -104.6320037842,
      "tz": "America\/Denver"
  },
  "2DE2": {
      "icao": "2DE2",
      "iata": "",
      "name": "Willaview Airport",
      "city": "Farmington",
      "state": "Delaware",
      "country": "US",
      "elevation": 63,
      "lat": 38.8549995422,
      "lon": -75.5721969604,
      "tz": "America\/New_York"
  },
  "2FA2": {
      "icao": "2FA2",
      "iata": "",
      "name": "Monroe Airpark",
      "city": "Belleview",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 29.0188999176,
      "lon": -82.1137008667,
      "tz": "America\/New_York"
  },
  "2FA4": {
      "icao": "2FA4",
      "iata": "",
      "name": "Southern Ranch Airport",
      "city": "Clewiston",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 26.3794994354,
      "lon": -80.945602417,
      "tz": "America\/New_York"
  },
  "2FA5": {
      "icao": "2FA5",
      "iata": "",
      "name": "Thunderbird Air Park",
      "city": "Crescent City",
      "state": "Florida",
      "country": "US",
      "elevation": 67,
      "lat": 29.4722003937,
      "lon": -81.5719985962,
      "tz": "America\/New_York"
  },
  "2FA6": {
      "icao": "2FA6",
      "iata": "",
      "name": "Freeflight International Airport",
      "city": "Coleman",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 28.8111000061,
      "lon": -82.0653991699,
      "tz": "America\/New_York"
  },
  "2FA9": {
      "icao": "2FA9",
      "iata": "",
      "name": "Mount Olive Farm Airport",
      "city": "Lamont",
      "state": "Florida",
      "country": "US",
      "elevation": 73,
      "lat": 30.336389,
      "lon": -83.783611,
      "tz": "America\/New_York"
  },
  "2FD0": {
      "icao": "2FD0",
      "iata": "",
      "name": "Flints Flying Ranch Airport",
      "city": "Branford",
      "state": "Florida",
      "country": "US",
      "elevation": 45,
      "lat": 29.9757995605,
      "lon": -82.9073028564,
      "tz": "America\/New_York"
  },
  "2FD1": {
      "icao": "2FD1",
      "iata": "",
      "name": "Hobby Hill STOLport",
      "city": "Weirsdale",
      "state": "Florida",
      "country": "US",
      "elevation": 95,
      "lat": 28.9755001068,
      "lon": -81.9187011719,
      "tz": "America\/New_York"
  },
  "2FD2": {
      "icao": "2FD2",
      "iata": "",
      "name": "Marjorie Kennan Rawlings Airport",
      "city": "Island Grove",
      "state": "Florida",
      "country": "US",
      "elevation": 78,
      "lat": 29.47929955,
      "lon": -82.05329895,
      "tz": "America\/New_York"
  },
  "2FD6": {
      "icao": "2FD6",
      "iata": "",
      "name": "Highlander Airport",
      "city": "Oak Hill",
      "state": "Florida",
      "country": "US",
      "elevation": 15,
      "lat": 28.8880004883,
      "lon": -80.8664016724,
      "tz": "America\/New_York"
  },
  "2FD8": {
      "icao": "2FD8",
      "iata": "",
      "name": "Lib Field",
      "city": "Welaka",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 29.5210990906,
      "lon": -81.6323013306,
      "tz": "America\/New_York"
  },
  "2FL0": {
      "icao": "2FL0",
      "iata": "",
      "name": "Crystal Village Airport",
      "city": "Wausau",
      "state": "Florida",
      "country": "US",
      "elevation": 113,
      "lat": 30.4568996429,
      "lon": -85.6859970093,
      "tz": "America\/Chicago"
  },
  "2FL3": {
      "icao": "2FL3",
      "iata": "",
      "name": "Folsom Airport",
      "city": "Cypress",
      "state": "Florida",
      "country": "US",
      "elevation": 155,
      "lat": 30.6725997925,
      "lon": -85.0835037231,
      "tz": "America\/Chicago"
  },
  "2FL8": {
      "icao": "2FL8",
      "iata": "",
      "name": "Tiger Lake Airport",
      "city": "River Ranch",
      "state": "Florida",
      "country": "US",
      "elevation": 54,
      "lat": 27.883600235,
      "lon": -81.3630981445,
      "tz": "America\/New_York"
  },
  "2GA0": {
      "icao": "2GA0",
      "iata": "",
      "name": "Kennedy Intranational Airport",
      "city": "Newborn",
      "state": "Georgia",
      "country": "US",
      "elevation": 695,
      "lat": 33.5265007019,
      "lon": -83.6380004883,
      "tz": "America\/New_York"
  },
  "2GA1": {
      "icao": "2GA1",
      "iata": "",
      "name": "Poole Farm Airport",
      "city": "Oxford",
      "state": "Georgia",
      "country": "US",
      "elevation": 830,
      "lat": 33.6806983948,
      "lon": -83.8526992798,
      "tz": "America\/New_York"
  },
  "2GA2": {
      "icao": "2GA2",
      "iata": "",
      "name": "Swaids Field",
      "city": "Springfield",
      "state": "Georgia",
      "country": "US",
      "elevation": 85,
      "lat": 32.3945999146,
      "lon": -81.2807006836,
      "tz": "America\/New_York"
  },
  "2GA3": {
      "icao": "2GA3",
      "iata": "",
      "name": "Wrights Field",
      "city": "Pinehurst",
      "state": "Georgia",
      "country": "US",
      "elevation": 383,
      "lat": 32.2062988281,
      "lon": -83.7863006592,
      "tz": "America\/New_York"
  },
  "2GA4": {
      "icao": "2GA4",
      "iata": "",
      "name": "Mack's Field",
      "city": "Pinehurst",
      "state": "Georgia",
      "country": "US",
      "elevation": 400,
      "lat": 32.1712989807,
      "lon": -83.7556991577,
      "tz": "America\/New_York"
  },
  "2GA5": {
      "icao": "2GA5",
      "iata": "",
      "name": "Windrift Aerodrome",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 780,
      "lat": 33.0418014526,
      "lon": -84.4320983887,
      "tz": "America\/New_York"
  },
  "2GA6": {
      "icao": "2GA6",
      "iata": "",
      "name": "Catoosa Springs STOLport",
      "city": "Ringgold",
      "state": "Georgia",
      "country": "US",
      "elevation": 930,
      "lat": 34.917301178,
      "lon": -85.0524978638,
      "tz": "America\/New_York"
  },
  "2GA8": {
      "icao": "2GA8",
      "iata": "",
      "name": "Shannon Flight Strip",
      "city": "Shannon",
      "state": "Georgia",
      "country": "US",
      "elevation": 677,
      "lat": 34.3498001099,
      "lon": -85.0768966675,
      "tz": "America\/New_York"
  },
  "2GA9": {
      "icao": "2GA9",
      "iata": "",
      "name": "Lenora Airport",
      "city": "Snellville",
      "state": "Georgia",
      "country": "US",
      "elevation": 940,
      "lat": 33.804599762,
      "lon": -83.9962997437,
      "tz": "America\/New_York"
  },
  "2GE3": {
      "icao": "2GE3",
      "iata": "",
      "name": "Smith Airport",
      "city": "Greensboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 550,
      "lat": 33.6762008667,
      "lon": -83.2593002319,
      "tz": "America\/New_York"
  },
  "2GE5": {
      "icao": "2GE5",
      "iata": "",
      "name": "Chukkar Farm Ultralightport",
      "city": "Alpharetta",
      "state": "Georgia",
      "country": "US",
      "elevation": 1000,
      "lat": 34.1977996826,
      "lon": -84.3193969727,
      "tz": "America\/New_York"
  },
  "2GE7": {
      "icao": "2GE7",
      "iata": "",
      "name": "Petty Farms Airport",
      "city": "Crandall",
      "state": "Georgia",
      "country": "US",
      "elevation": 760,
      "lat": 34.9580993652,
      "lon": -84.7791976929,
      "tz": "America\/New_York"
  },
  "2GE8": {
      "icao": "2GE8",
      "iata": "",
      "name": "Andy Fields Airport",
      "city": "Newnan",
      "state": "Georgia",
      "country": "US",
      "elevation": 980,
      "lat": 33.4641990662,
      "lon": -84.6632995605,
      "tz": "America\/New_York"
  },
  "2IA9": {
      "icao": "2IA9",
      "iata": "",
      "name": "South 80 Field",
      "city": "Crystal Lake",
      "state": "Iowa",
      "country": "US",
      "elevation": 1210,
      "lat": 43.1907997131,
      "lon": -93.7707977295,
      "tz": "America\/Chicago"
  },
  "2ID2": {
      "icao": "2ID2",
      "iata": "",
      "name": "Linda's Roost Airport",
      "city": "Mackay",
      "state": "Idaho",
      "country": "US",
      "elevation": 6250,
      "lat": 43.9543991089,
      "lon": -113.6470031738,
      "tz": "America\/Boise"
  },
  "2ID3": {
      "icao": "2ID3",
      "iata": "",
      "name": "Josephine Ranch Airport",
      "city": "Triangle",
      "state": "Idaho",
      "country": "US",
      "elevation": 5100,
      "lat": 42.751833,
      "lon": -116.677333,
      "tz": "America\/Boise"
  },
  "2ID4": {
      "icao": "2ID4",
      "iata": "",
      "name": "Silverwood Airport",
      "city": "Athol",
      "state": "Idaho",
      "country": "US",
      "elevation": 2350,
      "lat": 47.9081993103,
      "lon": -116.7089996338,
      "tz": "America\/Los_Angeles"
  },
  "2ID5": {
      "icao": "2ID5",
      "iata": "",
      "name": "Splan Airport",
      "city": "Mccall",
      "state": "Idaho",
      "country": "US",
      "elevation": 4987,
      "lat": 44.807467,
      "lon": -116.049483,
      "tz": "America\/Boise"
  },
  "2ID6": {
      "icao": "2ID6",
      "iata": "",
      "name": "Lemons Field",
      "city": "Fruitland",
      "state": "Idaho",
      "country": "US",
      "elevation": 2280,
      "lat": 43.927336,
      "lon": -116.946475,
      "tz": "America\/Boise"
  },
  "2ID7": {
      "icao": "2ID7",
      "iata": "",
      "name": "Cayuse Creek \/US Forest Service\/ Airport",
      "city": "Cayuse Creek",
      "state": "Idaho",
      "country": "US",
      "elevation": 3500,
      "lat": 46.6665992737,
      "lon": -115.072998047,
      "tz": "America\/Los_Angeles"
  },
  "2IG4": {
      "icao": "2IG4",
      "iata": "",
      "name": "Ed-Air Airport",
      "city": "Oaktown",
      "state": "Indiana",
      "country": "US",
      "elevation": 426,
      "lat": 38.851398468,
      "lon": -87.4997024536,
      "tz": "America\/Chicago"
  },
  "2II0": {
      "icao": "2II0",
      "iata": "",
      "name": "Indian Hills Flying Field",
      "city": "Kokomo",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 40.4174003601,
      "lon": -86.1524963379,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2II1": {
      "icao": "2II1",
      "iata": "",
      "name": "Yoder Airport",
      "city": "Emma",
      "state": "Indiana",
      "country": "US",
      "elevation": 905,
      "lat": 41.6097984314,
      "lon": -85.5254974365,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2II2": {
      "icao": "2II2",
      "iata": "",
      "name": "Indian Creek Airport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 380,
      "lat": 37.9874992371,
      "lon": -87.9384002686,
      "tz": "America\/Chicago"
  },
  "2II3": {
      "icao": "2II3",
      "iata": "",
      "name": "Stottlemyer Airport",
      "city": "Frankton",
      "state": "Indiana",
      "country": "US",
      "elevation": 865,
      "lat": 40.2266998291,
      "lon": -85.8163986206,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2II6": {
      "icao": "2II6",
      "iata": "",
      "name": "Baird-Wolford Airport",
      "city": "Tipton",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 40.2648010254,
      "lon": -86.1027984619,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2IL0": {
      "icao": "2IL0",
      "iata": "",
      "name": "Sneek Airport",
      "city": "Shannon",
      "state": "Illinois",
      "country": "US",
      "elevation": 909,
      "lat": 42.1814002991,
      "lon": -89.676902771,
      "tz": "America\/Chicago"
  },
  "2IL1": {
      "icao": "2IL1",
      "iata": "",
      "name": "Mc Cartney Airport",
      "city": "Durand",
      "state": "Illinois",
      "country": "US",
      "elevation": 908,
      "lat": 42.413898468,
      "lon": -89.3292999268,
      "tz": "America\/Chicago"
  },
  "2IL2": {
      "icao": "2IL2",
      "iata": "",
      "name": "Watters Airport",
      "city": "Dwight",
      "state": "Illinois",
      "country": "US",
      "elevation": 660,
      "lat": 41.0533981323,
      "lon": -88.4611968994,
      "tz": "America\/Chicago"
  },
  "2IL3": {
      "icao": "2IL3",
      "iata": "",
      "name": "Mc Neal's Field",
      "city": "East Moline",
      "state": "Illinois",
      "country": "US",
      "elevation": 575,
      "lat": 41.5158996582,
      "lon": -90.3796005249,
      "tz": "America\/Chicago"
  },
  "2IL4": {
      "icao": "2IL4",
      "iata": "",
      "name": "Mountain Airport",
      "city": "Perry",
      "state": "Illinois",
      "country": "US",
      "elevation": 590,
      "lat": 39.7792015076,
      "lon": -90.7485961914,
      "tz": "America\/Chicago"
  },
  "2IL5": {
      "icao": "2IL5",
      "iata": "",
      "name": "Sutton Airport",
      "city": "Omaha",
      "state": "Illinois",
      "country": "US",
      "elevation": 470,
      "lat": 37.9342002869,
      "lon": -88.2316970825,
      "tz": "America\/Chicago"
  },
  "2IL6": {
      "icao": "2IL6",
      "iata": "",
      "name": "Low and Slow Airport",
      "city": "Hardin",
      "state": "Illinois",
      "country": "US",
      "elevation": 455,
      "lat": 39.1375007629,
      "lon": -90.6299972534,
      "tz": "America\/Chicago"
  },
  "2IL7": {
      "icao": "2IL7",
      "iata": "",
      "name": "Ben Emge Airport",
      "city": "Belleville",
      "state": "Illinois",
      "country": "US",
      "elevation": 555,
      "lat": 38.5153007507,
      "lon": -90.0279006958,
      "tz": "America\/Chicago"
  },
  "2IL9": {
      "icao": "2IL9",
      "iata": "",
      "name": "Meadow Creek Airport",
      "city": "Monee",
      "state": "Illinois",
      "country": "US",
      "elevation": 770,
      "lat": 41.432800293,
      "lon": -87.7817001343,
      "tz": "America\/Chicago"
  },
  "2IN0": {
      "icao": "2IN0",
      "iata": "",
      "name": "Skip's Place Airport",
      "city": "Ossian",
      "state": "Indiana",
      "country": "US",
      "elevation": 858,
      "lat": 40.8368988037,
      "lon": -85.1710968018,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2IN2": {
      "icao": "2IN2",
      "iata": "",
      "name": "Gerig's Field",
      "city": "Roanoke",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 41.0092010498,
      "lon": -85.3713989258,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2IN3": {
      "icao": "2IN3",
      "iata": "",
      "name": "Tatertown Airport",
      "city": "Medaryville",
      "state": "Indiana",
      "country": "US",
      "elevation": 700,
      "lat": 41.0848007202,
      "lon": -86.9242019653,
      "tz": "America\/Chicago"
  },
  "2IN4": {
      "icao": "2IN4",
      "iata": "",
      "name": "Scott Field",
      "city": "Argos",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 41.2566986084,
      "lon": -86.1896972656,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2IN5": {
      "icao": "2IN5",
      "iata": "",
      "name": "Midkiff Airport",
      "city": "Sulphur Springs",
      "state": "Indiana",
      "country": "US",
      "elevation": 1038,
      "lat": 40.0152015686,
      "lon": -85.4729003906,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2IN6": {
      "icao": "2IN6",
      "iata": "",
      "name": "Galloway Airport",
      "city": "Noblesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 40.0419006348,
      "lon": -85.9169006348,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2IN9": {
      "icao": "2IN9",
      "iata": "",
      "name": "Berry Field",
      "city": "New Whiteland",
      "state": "Indiana",
      "country": "US",
      "elevation": 795,
      "lat": 39.5666999817,
      "lon": -86.1181030273,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "2IS3": {
      "icao": "2IS3",
      "iata": "",
      "name": "Jackson Field",
      "city": "Elmira",
      "state": "Illinois",
      "country": "US",
      "elevation": 760,
      "lat": 41.1716995239,
      "lon": -89.8068008423,
      "tz": "America\/Chicago"
  },
  "2IS4": {
      "icao": "2IS4",
      "iata": "",
      "name": "Ritter Field",
      "city": "Illinois City",
      "state": "Illinois",
      "country": "US",
      "elevation": 552,
      "lat": 41.3370018005,
      "lon": -91.0131988525,
      "tz": "America\/Chicago"
  },
  "2IS5": {
      "icao": "2IS5",
      "iata": "",
      "name": "Parrish RLA Restricted Landing Area",
      "city": "Lacon",
      "state": "Illinois",
      "country": "US",
      "elevation": 640,
      "lat": 40.9523010254,
      "lon": -89.4078979492,
      "tz": "America\/Chicago"
  },
  "2IS6": {
      "icao": "2IS6",
      "iata": "",
      "name": "Red Shed Field",
      "city": "Lena",
      "state": "Illinois",
      "country": "US",
      "elevation": 900,
      "lat": 42.4180984497,
      "lon": -89.8226013184,
      "tz": "America\/Chicago"
  },
  "2IS7": {
      "icao": "2IS7",
      "iata": "",
      "name": "Somers Blossom Airport",
      "city": "Lindenwood",
      "state": "Illinois",
      "country": "US",
      "elevation": 789,
      "lat": 42.0606002808,
      "lon": -89.00340271,
      "tz": "America\/Chicago"
  },
  "2IS9": {
      "icao": "2IS9",
      "iata": "",
      "name": "Schertz Aerial Service - Cooksville Airport",
      "city": "Cooksville",
      "state": "Illinois",
      "country": "US",
      "elevation": 741,
      "lat": 40.5780982971,
      "lon": -88.7078018188,
      "tz": "America\/Chicago"
  },
  "2KS0": {
      "icao": "2KS0",
      "iata": "",
      "name": "Rupp Airport",
      "city": "Sedan",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 37.2066993713,
      "lon": -96.0768966675,
      "tz": "America\/Chicago"
  },
  "2KS1": {
      "icao": "2KS1",
      "iata": "",
      "name": "Rush Airport",
      "city": "Severance",
      "state": "Kansas",
      "country": "US",
      "elevation": 950,
      "lat": 39.7743988037,
      "lon": -95.2510986328,
      "tz": "America\/Chicago"
  },
  "2KS2": {
      "icao": "2KS2",
      "iata": "",
      "name": "Stuber Flying Ranch Airport",
      "city": "Severy",
      "state": "Kansas",
      "country": "US",
      "elevation": 1200,
      "lat": 37.6556015015,
      "lon": -96.2906036377,
      "tz": "America\/Chicago"
  },
  "2KS3": {
      "icao": "2KS3",
      "iata": "",
      "name": "Wilson Airport",
      "city": "Wilson",
      "state": "Kansas",
      "country": "US",
      "elevation": 1727,
      "lat": 38.8381004333,
      "lon": -98.4856033325,
      "tz": "America\/Chicago"
  },
  "2KS4": {
      "icao": "2KS4",
      "iata": "",
      "name": "Dick Airport",
      "city": "Spivey",
      "state": "Kansas",
      "country": "US",
      "elevation": 1549,
      "lat": 37.4477996826,
      "lon": -98.2253036499,
      "tz": "America\/Chicago"
  },
  "2KS5": {
      "icao": "2KS5",
      "iata": "",
      "name": "Plains Municipal Airport",
      "city": "Plains",
      "state": "Kansas",
      "country": "US",
      "elevation": 2762,
      "lat": 37.2709007263,
      "lon": -100.5879974365,
      "tz": "America\/Chicago"
  },
  "2KS8": {
      "icao": "2KS8",
      "iata": "",
      "name": "Vonada Airport",
      "city": "Sylvan Grove",
      "state": "Kansas",
      "country": "US",
      "elevation": 1629,
      "lat": 39.1166992188,
      "lon": -98.4002990723,
      "tz": "America\/Chicago"
  },
  "2KS9": {
      "icao": "2KS9",
      "iata": "",
      "name": "Starshire Farm Airport",
      "city": "Topeka",
      "state": "Kansas",
      "country": "US",
      "elevation": 990,
      "lat": 38.9000015259,
      "lon": -95.5836029053,
      "tz": "America\/Chicago"
  },
  "2KY1": {
      "icao": "2KY1",
      "iata": "",
      "name": "Kitty Hawk Farm Ultralightport",
      "city": "Paint Lick",
      "state": "Kentucky",
      "country": "US",
      "elevation": 980,
      "lat": 37.6192016602,
      "lon": -84.381401062,
      "tz": "America\/New_York"
  },
  "2KY3": {
      "icao": "2KY3",
      "iata": "",
      "name": "Plane-O-Field Airport",
      "city": "Bowling Green",
      "state": "Kentucky",
      "country": "US",
      "elevation": 655,
      "lat": 36.8347015381,
      "lon": -86.4582977295,
      "tz": "America\/Chicago"
  },
  "2KY4": {
      "icao": "2KY4",
      "iata": "",
      "name": "Oz Airport",
      "city": "Elizabethtown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 820,
      "lat": 37.6549987793,
      "lon": -85.8135986328,
      "tz": "America\/New_York"
  },
  "2KY5": {
      "icao": "2KY5",
      "iata": "",
      "name": "Womstead Field",
      "city": "Olive Hill",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1050,
      "lat": 38.375,
      "lon": -83.1547012329,
      "tz": "America\/New_York"
  },
  "2KY8": {
      "icao": "2KY8",
      "iata": "",
      "name": "Seldom Scene Airport",
      "city": "Millville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 860,
      "lat": 38.1055984497,
      "lon": -84.8499984741,
      "tz": "America\/New_York"
  },
  "2LA0": {
      "icao": "2LA0",
      "iata": "",
      "name": "Central Industries Airport",
      "city": "Intracoastal City",
      "state": "Louisiana",
      "country": "US",
      "elevation": 2,
      "lat": 29.7896995544,
      "lon": -92.15650177,
      "tz": "America\/Chicago"
  },
  "2LA4": {
      "icao": "2LA4",
      "iata": "",
      "name": "Bunkie Flying Service Airport",
      "city": "Bunkie",
      "state": "Louisiana",
      "country": "US",
      "elevation": 65,
      "lat": 30.9291000366,
      "lon": -92.1792984009,
      "tz": "America\/Chicago"
  },
  "2LA5": {
      "icao": "2LA5",
      "iata": "",
      "name": "Reed Flying Service Inc Airport",
      "city": "Iota",
      "state": "Louisiana",
      "country": "US",
      "elevation": 35,
      "lat": 30.3432998657,
      "lon": -92.4320983887,
      "tz": "America\/Chicago"
  },
  "2LA6": {
      "icao": "2LA6",
      "iata": "",
      "name": "Howell 1 Airport",
      "city": "Jackson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 180,
      "lat": 30.8334999084,
      "lon": -91.1668014526,
      "tz": "America\/Chicago"
  },
  "2LA7": {
      "icao": "2LA7",
      "iata": "",
      "name": "Costello Airport",
      "city": "Oak Grove",
      "state": "Louisiana",
      "country": "US",
      "elevation": 75,
      "lat": 32.9670982361,
      "lon": -91.4262008667,
      "tz": "America\/Chicago"
  },
  "2LL1": {
      "icao": "2LL1",
      "iata": "",
      "name": "Cwian Field",
      "city": "Sheridan",
      "state": "Illinois",
      "country": "US",
      "elevation": 550,
      "lat": 41.5416984558,
      "lon": -88.6890029907,
      "tz": "America\/Chicago"
  },
  "2LL2": {
      "icao": "2LL2",
      "iata": "",
      "name": "Weihler Airport",
      "city": "Sherrard",
      "state": "Illinois",
      "country": "US",
      "elevation": 740,
      "lat": 41.3148002625,
      "lon": -90.5521011353,
      "tz": "America\/Chicago"
  },
  "2LL3": {
      "icao": "2LL3",
      "iata": "",
      "name": "Roy Burden Restricted Landing Area",
      "city": "Shirley",
      "state": "Illinois",
      "country": "US",
      "elevation": 730,
      "lat": 40.3889007568,
      "lon": -89.0691986084,
      "tz": "America\/Chicago"
  },
  "2LL4": {
      "icao": "2LL4",
      "iata": "",
      "name": "Snow Airport",
      "city": "Macon",
      "state": "Illinois",
      "country": "US",
      "elevation": 728,
      "lat": 39.7498016357,
      "lon": -88.9794998169,
      "tz": "America\/Chicago"
  },
  "2LL5": {
      "icao": "2LL5",
      "iata": "",
      "name": "Justus Airport",
      "city": "Sidney",
      "state": "Illinois",
      "country": "US",
      "elevation": 685,
      "lat": 39.9986000061,
      "lon": -88.0599975586,
      "tz": "America\/Chicago"
  },
  "2LL7": {
      "icao": "2LL7",
      "iata": "",
      "name": "Adams Restricted Landing Area Number 2",
      "city": "Pontiac",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 40.9347000122,
      "lon": -88.7376022339,
      "tz": "America\/Chicago"
  },
  "2LL9": {
      "icao": "2LL9",
      "iata": "",
      "name": "George Airport",
      "city": "Somonauk",
      "state": "Illinois",
      "country": "US",
      "elevation": 740,
      "lat": 41.7042007446,
      "lon": -88.6930999756,
      "tz": "America\/Chicago"
  },
  "2LS2": {
      "icao": "2LS2",
      "iata": "",
      "name": "Chitimacha Air Park",
      "city": "Charenton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 14,
      "lat": 29.872222,
      "lon": -91.543056,
      "tz": "America\/Chicago"
  },
  "2LS3": {
      "icao": "2LS3",
      "iata": "",
      "name": "Majors Airpark",
      "city": "Melville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 34,
      "lat": 30.618244,
      "lon": -91.752064,
      "tz": "America\/Chicago"
  },
  "2MD0": {
      "icao": "2MD0",
      "iata": "",
      "name": "Anderson Farm Airport",
      "city": "Marion",
      "state": "Maryland",
      "country": "US",
      "elevation": 8,
      "lat": 38.0486984253,
      "lon": -75.7034988403,
      "tz": "America\/New_York"
  },
  "2MD1": {
      "icao": "2MD1",
      "iata": "",
      "name": "Recompense Farm Airport",
      "city": "Clements",
      "state": "Maryland",
      "country": "US",
      "elevation": 30,
      "lat": 38.3084983826,
      "lon": -76.7247009277,
      "tz": "America\/New_York"
  },
  "2MD3": {
      "icao": "2MD3",
      "iata": "",
      "name": "Fly Away Farm Airport",
      "city": "Boyds",
      "state": "Maryland",
      "country": "US",
      "elevation": 501,
      "lat": 39.229,
      "lon": -77.345333,
      "tz": "America\/New_York"
  },
  "2MD4": {
      "icao": "2MD4",
      "iata": "",
      "name": "Ennis Aerodrome",
      "city": "Salisbury",
      "state": "Maryland",
      "country": "US",
      "elevation": 44,
      "lat": 38.3945999146,
      "lon": -75.5574035645,
      "tz": "America\/New_York"
  },
  "2MD6": {
      "icao": "2MD6",
      "iata": "",
      "name": "Cherry Field",
      "city": "Lexington Park",
      "state": "Maryland",
      "country": "US",
      "elevation": 8,
      "lat": 38.1440010071,
      "lon": -76.4721984863,
      "tz": "America\/New_York"
  },
  "2MD8": {
      "icao": "2MD8",
      "iata": "",
      "name": "Greater Gortner Airport",
      "city": "Oakland",
      "state": "Maryland",
      "country": "US",
      "elevation": 2500,
      "lat": 39.3334007263,
      "lon": -79.4413986206,
      "tz": "America\/New_York"
  },
  "2ME3": {
      "icao": "2ME3",
      "iata": "",
      "name": "Charleston \/ Harte Field",
      "city": "Charleston",
      "state": "Maine",
      "country": "US",
      "elevation": 230,
      "lat": 45.04135,
      "lon": -68.990021,
      "tz": "America\/New_York"
  },
  "2MI0": {
      "icao": "2MI0",
      "iata": "",
      "name": "Woodside Airport",
      "city": "Chesaning",
      "state": "Michigan",
      "country": "US",
      "elevation": 615,
      "lat": 43.2167015076,
      "lon": -84.047203064,
      "tz": "America\/Detroit"
  },
  "2MI3": {
      "icao": "2MI3",
      "iata": "",
      "name": "Larry D Boven Airport",
      "city": "Galesburg",
      "state": "Michigan",
      "country": "US",
      "elevation": 890,
      "lat": 42.3125,
      "lon": -85.4633026123,
      "tz": "America\/Detroit"
  },
  "2MI4": {
      "icao": "2MI4",
      "iata": "",
      "name": "Mc Jilton Field",
      "city": "St Louis",
      "state": "Michigan",
      "country": "US",
      "elevation": 729,
      "lat": 43.4056015015,
      "lon": -84.5714035034,
      "tz": "America\/Detroit"
  },
  "2MI5": {
      "icao": "2MI5",
      "iata": "",
      "name": "Somerville Airport",
      "city": "Ada",
      "state": "Michigan",
      "country": "US",
      "elevation": 660,
      "lat": 42.9416999817,
      "lon": -85.4863967896,
      "tz": "America\/Detroit"
  },
  "2MI7": {
      "icao": "2MI7",
      "iata": "",
      "name": "Flugplatz Airport",
      "city": "Lexington",
      "state": "Michigan",
      "country": "US",
      "elevation": 700,
      "lat": 43.3469009399,
      "lon": -82.5522003174,
      "tz": "America\/Detroit"
  },
  "2MI8": {
      "icao": "2MI8",
      "iata": "",
      "name": "Vassar Field",
      "city": "Vassar",
      "state": "Michigan",
      "country": "US",
      "elevation": 675,
      "lat": 43.3433990479,
      "lon": -83.5352020264,
      "tz": "America\/Detroit"
  },
  "2MI9": {
      "icao": "2MI9",
      "iata": "",
      "name": "Capen Airport",
      "city": "Mecosta",
      "state": "Michigan",
      "country": "US",
      "elevation": 1015,
      "lat": 43.6241989136,
      "lon": -85.3178024292,
      "tz": "America\/Detroit"
  },
  "2MN0": {
      "icao": "2MN0",
      "iata": "",
      "name": "Pribbs Field",
      "city": "East Grand Forks",
      "state": "Minnesota",
      "country": "US",
      "elevation": 818,
      "lat": 48.0820999146,
      "lon": -97.0162963867,
      "tz": "America\/Chicago"
  },
  "2MN1": {
      "icao": "2MN1",
      "iata": "",
      "name": "Winter Strip",
      "city": "Gluek",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1040,
      "lat": 45.0461006165,
      "lon": -95.4645004272,
      "tz": "America\/Chicago"
  },
  "2MN2": {
      "icao": "2MN2",
      "iata": "",
      "name": "Dreamcatcher Airport",
      "city": "Jacobson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1250,
      "lat": 46.9403991699,
      "lon": -93.2978973389,
      "tz": "America\/Chicago"
  },
  "2MN6": {
      "icao": "2MN6",
      "iata": "",
      "name": "Van Norman's Airport",
      "city": "St Charles",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 44.0471992493,
      "lon": -92.0929031372,
      "tz": "America\/Chicago"
  },
  "2MN7": {
      "icao": "2MN7",
      "iata": "",
      "name": "Fussy Airport",
      "city": "North Prairie",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 45.7915992737,
      "lon": -94.3407974243,
      "tz": "America\/Chicago"
  },
  "2MN8": {
      "icao": "2MN8",
      "iata": "",
      "name": "Trygstad Airport",
      "city": "Rochester",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1227,
      "lat": 43.9961013794,
      "lon": -92.3357009888,
      "tz": "America\/Chicago"
  },
  "2MN9": {
      "icao": "2MN9",
      "iata": "",
      "name": "F. Dillenburg Airport",
      "city": "Foley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1295,
      "lat": 45.8244018555,
      "lon": -93.995300293,
      "tz": "America\/Chicago"
  },
  "2MO0": {
      "icao": "2MO0",
      "iata": "",
      "name": "Fletcher Field",
      "city": "Centerview",
      "state": "Missouri",
      "country": "US",
      "elevation": 805,
      "lat": 38.7994003296,
      "lon": -93.8641967773,
      "tz": "America\/Chicago"
  },
  "2MO1": {
      "icao": "2MO1",
      "iata": "",
      "name": "Bird Field",
      "city": "Willard",
      "state": "Missouri",
      "country": "US",
      "elevation": 1250,
      "lat": 37.3199996948,
      "lon": -93.4198989868,
      "tz": "America\/Chicago"
  },
  "2MO2": {
      "icao": "2MO2",
      "iata": "",
      "name": "Northwood Airport",
      "city": "Holt",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.4641990662,
      "lon": -94.3079986572,
      "tz": "America\/Chicago"
  },
  "2MO4": {
      "icao": "2MO4",
      "iata": "",
      "name": "Breckenridge Airport",
      "city": "Rockville",
      "state": "Missouri",
      "country": "US",
      "elevation": 805,
      "lat": 38.1143989563,
      "lon": -94.1766967773,
      "tz": "America\/Chicago"
  },
  "2MO5": {
      "icao": "2MO5",
      "iata": "",
      "name": "Pegasus Ranch Aerodrome",
      "city": "Houston",
      "state": "Missouri",
      "country": "US",
      "elevation": 1430,
      "lat": 37.3819999695,
      "lon": -92.2107009888,
      "tz": "America\/Chicago"
  },
  "2MO6": {
      "icao": "2MO6",
      "iata": "",
      "name": "Hunziker Airport",
      "city": "Hurdland",
      "state": "Missouri",
      "country": "US",
      "elevation": 890,
      "lat": 40.1344985962,
      "lon": -92.2976989746,
      "tz": "America\/Chicago"
  },
  "2MO7": {
      "icao": "2MO7",
      "iata": "",
      "name": "Fawn Lake Airport",
      "city": "Warrenton",
      "state": "Missouri",
      "country": "US",
      "elevation": 750,
      "lat": 38.8666992188,
      "lon": -91.0860977173,
      "tz": "America\/Chicago"
  },
  "2MO8": {
      "icao": "2MO8",
      "iata": "",
      "name": "Frerer Strip",
      "city": "Carthage",
      "state": "Missouri",
      "country": "US",
      "elevation": 1020,
      "lat": 37.1351013184,
      "lon": -94.3640975952,
      "tz": "America\/Chicago"
  },
  "2MO9": {
      "icao": "2MO9",
      "iata": "",
      "name": "Runway Ranch Airport",
      "city": "Kansas City",
      "state": "Missouri",
      "country": "US",
      "elevation": 929,
      "lat": 38.9500007629,
      "lon": -94.4502029419,
      "tz": "America\/Chicago"
  },
  "2MS7": {
      "icao": "2MS7",
      "iata": "",
      "name": "Puff Airpark",
      "city": "Puckett",
      "state": "Mississippi",
      "country": "US",
      "elevation": 400,
      "lat": 32.0584983826,
      "lon": -89.8167037964,
      "tz": "America\/Chicago"
  },
  "2MS8": {
      "icao": "2MS8",
      "iata": "",
      "name": "Spencer Field",
      "city": "Grenada",
      "state": "Mississippi",
      "country": "US",
      "elevation": 175,
      "lat": 33.7896995544,
      "lon": -89.8743972778,
      "tz": "America\/Chicago"
  },
  "2MS9": {
      "icao": "2MS9",
      "iata": "",
      "name": "Kimmel Land & Cattle Airport",
      "city": "Houston",
      "state": "Mississippi",
      "country": "US",
      "elevation": 265,
      "lat": 33.8300018311,
      "lon": -88.9227981567,
      "tz": "America\/Chicago"
  },
  "2MT0": {
      "icao": "2MT0",
      "iata": "",
      "name": "Bates Airstrip",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 3110,
      "lat": 48.2999992371,
      "lon": -114.4140014648,
      "tz": "America\/Denver"
  },
  "2MT1": {
      "icao": "2MT1",
      "iata": "",
      "name": "Ryan Field",
      "city": "West Glacier",
      "state": "Montana",
      "country": "US",
      "elevation": 3660,
      "lat": 48.4831008911,
      "lon": -113.9589996338,
      "tz": "America\/Denver"
  },
  "2MT2": {
      "icao": "2MT2",
      "iata": "",
      "name": "Braidwater Farm Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2910,
      "lat": 48.2000007629,
      "lon": -114.2580032349,
      "tz": "America\/Denver"
  },
  "2MT5": {
      "icao": "2MT5",
      "iata": "",
      "name": "Briar Creek Airport",
      "city": "Bozeman",
      "state": "Montana",
      "country": "US",
      "elevation": 5175,
      "lat": 45.5941009521,
      "lon": -111.1520004272,
      "tz": "America\/Denver"
  },
  "2MT8": {
      "icao": "2MT8",
      "iata": "",
      "name": "South Boulder Airport",
      "city": "Cardwell",
      "state": "Montana",
      "country": "US",
      "elevation": 4560,
      "lat": 45.8151,
      "lon": -111.9259,
      "tz": "America\/Denver"
  },
  "2MT9": {
      "icao": "2MT9",
      "iata": "",
      "name": "Gold Creek Airport",
      "city": "Drummond",
      "state": "Montana",
      "country": "US",
      "elevation": 4956,
      "lat": 46.513889,
      "lon": -112.983333,
      "tz": "America\/Denver"
  },
  "2MU9": {
      "icao": "2MU9",
      "iata": "",
      "name": "Monroe Field",
      "city": "Hawk Point",
      "state": "Missouri",
      "country": "US",
      "elevation": 710,
      "lat": 38.9655990601,
      "lon": -91.1181030273,
      "tz": "America\/Chicago"
  },
  "2MY2": {
      "icao": "2MY2",
      "iata": "",
      "name": "Zarn Airport",
      "city": "Amboy",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1050,
      "lat": 43.907417,
      "lon": -94.274667,
      "tz": "America\/Chicago"
  },
  "2MY4": {
      "icao": "2MY4",
      "iata": "",
      "name": "Miller Airport",
      "city": "Clear Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 987,
      "lat": 45.433869,
      "lon": -93.948714,
      "tz": "America\/Chicago"
  },
  "2NA0": {
      "icao": "2NA0",
      "iata": "",
      "name": "Soderquist Airport",
      "city": "Wilton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1880,
      "lat": 47.2566986084,
      "lon": -100.7959976196,
      "tz": "America\/Chicago"
  },
  "2NA7": {
      "icao": "2NA7",
      "iata": "",
      "name": "Slater Farm Airport",
      "city": "Esmond",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1600,
      "lat": 48.028301239,
      "lon": -99.6165008545,
      "tz": "America\/Chicago"
  },
  "2NC0": {
      "icao": "2NC0",
      "iata": "",
      "name": "Mountain Air Airport",
      "city": "Burnsville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 4432,
      "lat": 35.8686981201,
      "lon": -82.341796875,
      "tz": "America\/New_York"
  },
  "2NC1": {
      "icao": "2NC1",
      "iata": "",
      "name": "Hawk's Knoll Airport",
      "city": "Waxhaw",
      "state": "North Carolina",
      "country": "US",
      "elevation": 695,
      "lat": 34.9973983765,
      "lon": -80.8009033203,
      "tz": "America\/New_York"
  },
  "2NC4": {
      "icao": "2NC4",
      "iata": "",
      "name": "Scottbrook Farm Airport",
      "city": "Goldsboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 160,
      "lat": 35.3378982544,
      "lon": -78.1547012329,
      "tz": "America\/New_York"
  },
  "2NC6": {
      "icao": "2NC6",
      "iata": "",
      "name": "Flying M Airport",
      "city": "Lexington",
      "state": "North Carolina",
      "country": "US",
      "elevation": 845,
      "lat": 35.7995986938,
      "lon": -80.3962020874,
      "tz": "America\/New_York"
  },
  "2NC7": {
      "icao": "2NC7",
      "iata": "",
      "name": "Scotland Neck East Airport",
      "city": "Scotland Neck",
      "state": "North Carolina",
      "country": "US",
      "elevation": 103,
      "lat": 36.1679000854,
      "lon": -77.3989028931,
      "tz": "America\/New_York"
  },
  "2NC8": {
      "icao": "2NC8",
      "iata": "",
      "name": "Goodnight's Airport",
      "city": "Kannapolis",
      "state": "North Carolina",
      "country": "US",
      "elevation": 740,
      "lat": 35.5253982544,
      "lon": -80.6398010254,
      "tz": "America\/New_York"
  },
  "2ND0": {
      "icao": "2ND0",
      "iata": "",
      "name": "Kraft Airport",
      "city": "Mapleton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 910,
      "lat": 46.8143997192,
      "lon": -97.0497970581,
      "tz": "America\/Chicago"
  },
  "2ND1": {
      "icao": "2ND1",
      "iata": "",
      "name": "Westerlind Airport",
      "city": "Mercer",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1910,
      "lat": 47.4925003052,
      "lon": -100.6999969482,
      "tz": "America\/Chicago"
  },
  "2ND2": {
      "icao": "2ND2",
      "iata": "",
      "name": "Makeeff Airport",
      "city": "Mercer",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1910,
      "lat": 47.5668983459,
      "lon": -100.7210006714,
      "tz": "America\/Chicago"
  },
  "2ND3": {
      "icao": "2ND3",
      "iata": "",
      "name": "Casslindan Airport",
      "city": "Grand Forks",
      "state": "North Dakota",
      "country": "US",
      "elevation": 832,
      "lat": 48.0008010864,
      "lon": -97.1223983765,
      "tz": "America\/Chicago"
  },
  "2ND7": {
      "icao": "2ND7",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Newburg",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1460,
      "lat": 48.751701355,
      "lon": -100.8140029907,
      "tz": "America\/Chicago"
  },
  "2ND9": {
      "icao": "2ND9",
      "iata": "",
      "name": "Brekhus Field",
      "city": "Norma",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1850,
      "lat": 48.8166999817,
      "lon": -101.9169998169,
      "tz": "America\/Chicago"
  },
  "2NE0": {
      "icao": "2NE0",
      "iata": "",
      "name": "Johnson Lake Airport",
      "city": "Elwood",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2625,
      "lat": 40.6967010498,
      "lon": -99.8342971802,
      "tz": "America\/Chicago"
  },
  "2NE4": {
      "icao": "2NE4",
      "iata": "",
      "name": "Spring Lake Airport",
      "city": "Gordon",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3815,
      "lat": 42.4500007629,
      "lon": -102.1340026855,
      "tz": "America\/Denver"
  },
  "2NE5": {
      "icao": "2NE5",
      "iata": "",
      "name": "Fiese Airstrip",
      "city": "Gothenburg",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2535,
      "lat": 40.9000015259,
      "lon": -100.0950012207,
      "tz": "America\/Chicago"
  },
  "2NE6": {
      "icao": "2NE6",
      "iata": "",
      "name": "Coppersmith Airport",
      "city": "Grainton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3240,
      "lat": 40.9207992554,
      "lon": -101.2969970703,
      "tz": "America\/Denver"
  },
  "2NE7": {
      "icao": "2NE7",
      "iata": "",
      "name": "Kumor Airport",
      "city": "Grant",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3520,
      "lat": 40.851398468,
      "lon": -101.9430007935,
      "tz": "America\/Denver"
  },
  "2NH5": {
      "icao": "2NH5",
      "iata": "",
      "name": "Pilgrim's Home Airfield",
      "city": "Westmoreland",
      "state": "New Hampshire",
      "country": "US",
      "elevation": 600,
      "lat": 42.9902992249,
      "lon": -72.4017028809,
      "tz": "America\/New_York"
  },
  "2NJ1": {
      "icao": "2NJ1",
      "iata": "",
      "name": "Jugtown Mountain Airport",
      "city": "Pattenburg",
      "state": "New Jersey",
      "country": "US",
      "elevation": 600,
      "lat": 40.6333999634,
      "lon": -75.0662994385,
      "tz": "America\/New_York"
  },
  "2NJ3": {
      "icao": "2NJ3",
      "iata": "",
      "name": "Weidel\/Private\/ Airport",
      "city": "Pennington",
      "state": "New Jersey",
      "country": "US",
      "elevation": 301,
      "lat": 40.3404006958,
      "lon": -74.8543014526,
      "tz": "America\/New_York"
  },
  "2NJ5": {
      "icao": "2NJ5",
      "iata": "",
      "name": "Hartung Airport",
      "city": "Phillipsburg",
      "state": "New Jersey",
      "country": "US",
      "elevation": 350,
      "lat": 40.6520004272,
      "lon": -75.1859970093,
      "tz": "America\/New_York"
  },
  "2NJ6": {
      "icao": "2NJ6",
      "iata": "",
      "name": "Markle Airport",
      "city": "Phillipsburg",
      "state": "New Jersey",
      "country": "US",
      "elevation": 320,
      "lat": 40.7737007141,
      "lon": -75.1593017578,
      "tz": "America\/New_York"
  },
  "2NK1": {
      "icao": "2NK1",
      "iata": "",
      "name": "Gaskin's Hilltop Airport",
      "city": "Canisteo",
      "state": "New York",
      "country": "US",
      "elevation": 1730,
      "lat": 42.2961997986,
      "lon": -77.4654998779,
      "tz": "America\/New_York"
  },
  "2NK3": {
      "icao": "2NK3",
      "iata": "",
      "name": "Rose Field",
      "city": "Orient",
      "state": "New York",
      "country": "US",
      "elevation": 20,
      "lat": 41.1543006897,
      "lon": -72.2800979614,
      "tz": "America\/New_York"
  },
  "2NK7": {
      "icao": "2NK7",
      "iata": "",
      "name": "Walton Airport",
      "city": "Walton",
      "state": "New York",
      "country": "US",
      "elevation": 1200,
      "lat": 42.1579017639,
      "lon": -75.1479034424,
      "tz": "America\/New_York"
  },
  "2NK9": {
      "icao": "2NK9",
      "iata": "",
      "name": "Old Orchard Airpark",
      "city": "Modena",
      "state": "New York",
      "country": "US",
      "elevation": 660,
      "lat": 41.6571998596,
      "lon": -74.0772018433,
      "tz": "America\/New_York"
  },
  "2NV2": {
      "icao": "2NV2",
      "iata": "",
      "name": "Gibb Ranch Airport",
      "city": "Sparks",
      "state": "Nevada",
      "country": "US",
      "elevation": 4242,
      "lat": 39.8347015381,
      "lon": -119.6750030518,
      "tz": "America\/Los_Angeles"
  },
  "2NY0": {
      "icao": "2NY0",
      "iata": "",
      "name": "Catskill Valley Airpark",
      "city": "South Cairo",
      "state": "New York",
      "country": "US",
      "elevation": 190,
      "lat": 42.2806015015,
      "lon": -73.9529037476,
      "tz": "America\/New_York"
  },
  "2NY4": {
      "icao": "2NY4",
      "iata": "",
      "name": "Sackett Farms Airstrip",
      "city": "Byron",
      "state": "New York",
      "country": "US",
      "elevation": 665,
      "lat": 43.0736999512,
      "lon": -78.0500030518,
      "tz": "America\/New_York"
  },
  "2NY7": {
      "icao": "2NY7",
      "iata": "",
      "name": "Towner Farm Airport",
      "city": "Addison",
      "state": "New York",
      "country": "US",
      "elevation": 1248,
      "lat": 42.1214981079,
      "lon": -77.2114028931,
      "tz": "America\/New_York"
  },
  "2NY9": {
      "icao": "2NY9",
      "iata": "",
      "name": "Kayutah Lake Airport",
      "city": "Alpine",
      "state": "New York",
      "country": "US",
      "elevation": 1385,
      "lat": 42.370098114,
      "lon": -76.7225036621,
      "tz": "America\/New_York"
  },
  "2OA1": {
      "icao": "2OA1",
      "iata": "",
      "name": "Bristol Airstrip",
      "city": "Bristolville",
      "state": "Ohio",
      "country": "US",
      "elevation": 865,
      "lat": 41.4151000977,
      "lon": -80.8843002319,
      "tz": "America\/New_York"
  },
  "2OA2": {
      "icao": "2OA2",
      "iata": "",
      "name": "Mackie's Airport",
      "city": "Midland",
      "state": "Ohio",
      "country": "US",
      "elevation": 993,
      "lat": 39.2481002808,
      "lon": -83.9150009155,
      "tz": "America\/New_York"
  },
  "2OA4": {
      "icao": "2OA4",
      "iata": "",
      "name": "Victory Field",
      "city": "Donnellsville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1055,
      "lat": 39.9318008423,
      "lon": -83.9075012207,
      "tz": "America\/New_York"
  },
  "2OA5": {
      "icao": "2OA5",
      "iata": "",
      "name": "Fl-Airfield",
      "city": "Marysville",
      "state": "Ohio",
      "country": "US",
      "elevation": 980,
      "lat": 40.1824989319,
      "lon": -83.3508987427,
      "tz": "America\/New_York"
  },
  "2OA6": {
      "icao": "2OA6",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Cambridge",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 40.0864982605,
      "lon": -81.5882034302,
      "tz": "America\/New_York"
  },
  "2OA7": {
      "icao": "2OA7",
      "iata": "",
      "name": "Utter Field",
      "city": "Felicity",
      "state": "Ohio",
      "country": "US",
      "elevation": 890,
      "lat": 38.85139847,
      "lon": -84.10559845,
      "tz": "America\/New_York"
  },
  "2OG4": {
      "icao": "2OG4",
      "iata": "",
      "name": "El Rancho Airport",
      "city": "Fields",
      "state": "Oregon",
      "country": "US",
      "elevation": 4130,
      "lat": 42.330278,
      "lon": -118.647222,
      "tz": "America\/Los_Angeles"
  },
  "2OG5": {
      "icao": "2OG5",
      "iata": "",
      "name": "Mendenhall Airstrip",
      "city": "Willamina",
      "state": "Oregon",
      "country": "US",
      "elevation": 350,
      "lat": 45.117978,
      "lon": -123.506458,
      "tz": "America\/Los_Angeles"
  },
  "2OH0": {
      "icao": "2OH0",
      "iata": "",
      "name": "Bohannan Airport",
      "city": "Kilbourne",
      "state": "Ohio",
      "country": "US",
      "elevation": 960,
      "lat": 40.345500946,
      "lon": -82.9404983521,
      "tz": "America\/New_York"
  },
  "2OH2": {
      "icao": "2OH2",
      "iata": "",
      "name": "Merritt Airport",
      "city": "Staunton",
      "state": "Ohio",
      "country": "US",
      "elevation": 990,
      "lat": 39.4720001221,
      "lon": -83.4898986816,
      "tz": "America\/New_York"
  },
  "2OH4": {
      "icao": "2OH4",
      "iata": "",
      "name": "Cedar Creek Airport",
      "city": "Shauck",
      "state": "Ohio",
      "country": "US",
      "elevation": 1220,
      "lat": 40.6245002747,
      "lon": -82.6354980469,
      "tz": "America\/New_York"
  },
  "2OH6": {
      "icao": "2OH6",
      "iata": "",
      "name": "Leavelle Airstrip",
      "city": "Troy",
      "state": "Ohio",
      "country": "US",
      "elevation": 935,
      "lat": 40.0120010376,
      "lon": -84.2813034058,
      "tz": "America\/New_York"
  },
  "2OH7": {
      "icao": "2OH7",
      "iata": "",
      "name": "Jbr Airport",
      "city": "Hamersville",
      "state": "Ohio",
      "country": "US",
      "elevation": 945,
      "lat": 38.9112014771,
      "lon": -84.0260009766,
      "tz": "America\/New_York"
  },
  "2OH8": {
      "icao": "2OH8",
      "iata": "",
      "name": "Port-O-John Airport",
      "city": "Derby",
      "state": "Ohio",
      "country": "US",
      "elevation": 925,
      "lat": 39.79529953,
      "lon": -83.223197937,
      "tz": "America\/New_York"
  },
  "2OH9": {
      "icao": "2OH9",
      "iata": "",
      "name": "Caesar Creek Gliderport",
      "city": "Waynesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 940,
      "lat": 39.4766998291,
      "lon": -84.0938034058,
      "tz": "America\/New_York"
  },
  "2OI2": {
      "icao": "2OI2",
      "iata": "",
      "name": "Air Jordan Airport",
      "city": "Waynesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 920,
      "lat": 39.5611000061,
      "lon": -84.051902771,
      "tz": "America\/New_York"
  },
  "2OI4": {
      "icao": "2OI4",
      "iata": "",
      "name": "Aero Lake Farm Airport",
      "city": "Westfield Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 1195,
      "lat": 41.04529953,
      "lon": -81.9365005493,
      "tz": "America\/New_York"
  },
  "2OI8": {
      "icao": "2OI8",
      "iata": "",
      "name": "K & D Airways Airport",
      "city": "Mansfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1248,
      "lat": 40.863899231,
      "lon": -82.4206008911,
      "tz": "America\/New_York"
  },
  "2OK0": {
      "icao": "2OK0",
      "iata": "",
      "name": "Burford Corp. Airport",
      "city": "Maysville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 945,
      "lat": 34.8320007324,
      "lon": -97.4100036621,
      "tz": "America\/Chicago"
  },
  "2OK1": {
      "icao": "2OK1",
      "iata": "",
      "name": "Frost Ranch Airport",
      "city": "Meeker",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1030,
      "lat": 35.5900993347,
      "lon": -97.010597229,
      "tz": "America\/Chicago"
  },
  "2OK2": {
      "icao": "2OK2",
      "iata": "",
      "name": "Twin Lakes Airport",
      "city": "Midwest City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1199,
      "lat": 35.3292007446,
      "lon": -97.2697982788,
      "tz": "America\/Chicago"
  },
  "2OK4": {
      "icao": "2OK4",
      "iata": "",
      "name": "Ragwing Acres Airport",
      "city": "Mounds",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 780,
      "lat": 35.8167991638,
      "lon": -96.0586013794,
      "tz": "America\/Chicago"
  },
  "2OK5": {
      "icao": "2OK5",
      "iata": "",
      "name": "Ferrell Ranch Airport",
      "city": "Mountain View",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1575,
      "lat": 34.9290008545,
      "lon": -98.7397994995,
      "tz": "America\/Chicago"
  },
  "2OK6": {
      "icao": "2OK6",
      "iata": "",
      "name": "Flying N Ranch Airport",
      "city": "Meeker",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1095,
      "lat": 35.4819984436,
      "lon": -97.0205993652,
      "tz": "America\/Chicago"
  },
  "2OK7": {
      "icao": "2OK7",
      "iata": "",
      "name": "Cole Landing Area Airport",
      "city": "Newcastle",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1353,
      "lat": 35.2583999634,
      "lon": -97.6628036499,
      "tz": "America\/Chicago"
  },
  "2OK8": {
      "icao": "2OK8",
      "iata": "",
      "name": "D & G Farms Airport",
      "city": "Newcastle",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1300,
      "lat": 35.2251014709,
      "lon": -97.6461029053,
      "tz": "America\/Chicago"
  },
  "2OK9": {
      "icao": "2OK9",
      "iata": "",
      "name": "Pata Skyhaven Airport",
      "city": "Norman",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1136,
      "lat": 35.1958999634,
      "lon": -97.3084030151,
      "tz": "America\/Chicago"
  },
  "2OL2": {
      "icao": "2OL2",
      "iata": "",
      "name": "Myers Field Airstrip",
      "city": "Bixby",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 665,
      "lat": 35.887833,
      "lon": -95.919639,
      "tz": "America\/Chicago"
  },
  "2OR0": {
      "icao": "2OR0",
      "iata": "",
      "name": "Nielsen Airport",
      "city": "Oregon City",
      "state": "Oregon",
      "country": "US",
      "elevation": 510,
      "lat": 45.3442993164,
      "lon": -122.5179977417,
      "tz": "America\/Los_Angeles"
  },
  "2OR1": {
      "icao": "2OR1",
      "iata": "",
      "name": "Big Muddy Ranch Airport",
      "city": "Antelope",
      "state": "Oregon",
      "country": "US",
      "elevation": 1641,
      "lat": 44.8284988403,
      "lon": -120.4950027466,
      "tz": "America\/Los_Angeles"
  },
  "2OR3": {
      "icao": "2OR3",
      "iata": "",
      "name": "Davidson Field",
      "city": "Jefferson",
      "state": "Oregon",
      "country": "US",
      "elevation": 188,
      "lat": 44.7946014404,
      "lon": -123.0979995728,
      "tz": "America\/Los_Angeles"
  },
  "2OR4": {
      "icao": "2OR4",
      "iata": "",
      "name": "Heavens Gate Ranch Airport",
      "city": "Oakland",
      "state": "Oregon",
      "country": "US",
      "elevation": 965,
      "lat": 43.4748001099,
      "lon": -123.3460006714,
      "tz": "America\/Los_Angeles"
  },
  "2OR6": {
      "icao": "2OR6",
      "iata": "",
      "name": "Lockhart Airport",
      "city": "Ironside",
      "state": "Oregon",
      "country": "US",
      "elevation": 4615,
      "lat": 44.1813011169,
      "lon": -118.2149963379,
      "tz": "America\/Boise"
  },
  "2OR7": {
      "icao": "2OR7",
      "iata": "",
      "name": "Sweet Home Airport",
      "city": "Sweet Home",
      "state": "Oregon",
      "country": "US",
      "elevation": 645,
      "lat": 44.3992996216,
      "lon": -122.6849975586,
      "tz": "America\/Los_Angeles"
  },
  "2PA0": {
      "icao": "2PA0",
      "iata": "",
      "name": "Zettlemoyer Airport",
      "city": "Kutztown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 40.55680084,
      "lon": -75.69799805,
      "tz": "America\/New_York"
  },
  "2PA1": {
      "icao": "2PA1",
      "iata": "",
      "name": "Boehm's Field",
      "city": "Lackawaxen",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1330,
      "lat": 41.4430999756,
      "lon": -75.0078964233,
      "tz": "America\/New_York"
  },
  "2PA2": {
      "icao": "2PA2",
      "iata": "",
      "name": "Lehman Airport",
      "city": "Dallas",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1300,
      "lat": 41.3194999695,
      "lon": -76.0444030762,
      "tz": "America\/New_York"
  },
  "2PA3": {
      "icao": "2PA3",
      "iata": "",
      "name": "Reed Airport",
      "city": "Lake Ariel",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1400,
      "lat": 41.5000991821,
      "lon": -75.4162979126,
      "tz": "America\/New_York"
  },
  "2PA4": {
      "icao": "2PA4",
      "iata": "",
      "name": "Boden Airport",
      "city": "Lakewood",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2120,
      "lat": 41.846698761,
      "lon": -75.4462966919,
      "tz": "America\/New_York"
  },
  "2PA5": {
      "icao": "2PA5",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Port Allegany",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1506,
      "lat": 41.7806015015,
      "lon": -78.269203186,
      "tz": "America\/New_York"
  },
  "2PA6": {
      "icao": "2PA6",
      "iata": "",
      "name": "The Old Commonwealth Aerodrome",
      "city": "Shartlesville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 540,
      "lat": 40.4911994934,
      "lon": -76.0977020264,
      "tz": "America\/New_York"
  },
  "2PA7": {
      "icao": "2PA7",
      "iata": "",
      "name": "Egolf Airport",
      "city": "Landisburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 610,
      "lat": 40.3334007263,
      "lon": -77.299697876,
      "tz": "America\/New_York"
  },
  "2PA8": {
      "icao": "2PA8",
      "iata": "",
      "name": "Shulls Airport",
      "city": "Landisburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 640,
      "lat": 40.3000984192,
      "lon": -77.299697876,
      "tz": "America\/New_York"
  },
  "2PN0": {
      "icao": "2PN0",
      "iata": "",
      "name": "Strawberry Acres Airport",
      "city": "Wattsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1300,
      "lat": 42.0153007507,
      "lon": -79.8178024292,
      "tz": "America\/New_York"
  },
  "2PN1": {
      "icao": "2PN1",
      "iata": "",
      "name": "Malco Airport",
      "city": "Westfield",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1990,
      "lat": 41.9833984375,
      "lon": -77.5246963501,
      "tz": "America\/New_York"
  },
  "2PN3": {
      "icao": "2PN3",
      "iata": "",
      "name": "Market Garden Airport",
      "city": "York Springs",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 600,
      "lat": 39.961101532,
      "lon": -77.1003036499,
      "tz": "America\/New_York"
  },
  "2PN4": {
      "icao": "2PN4",
      "iata": "",
      "name": "Quemahening Flightpark Ultralightport",
      "city": "Stoystown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1997,
      "lat": 40.0817985535,
      "lon": -78.9455032349,
      "tz": "America\/New_York"
  },
  "2PN5": {
      "icao": "2PN5",
      "iata": "",
      "name": "Baker-Sell Airport",
      "city": "Woodbury",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1371,
      "lat": 40.2484016418,
      "lon": -78.3608016968,
      "tz": "America\/New_York"
  },
  "2PN7": {
      "icao": "2PN7",
      "iata": "",
      "name": "Brennan Personal Use Airport",
      "city": "Zelienople",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1210,
      "lat": 40.8555984497,
      "lon": -80.070602417,
      "tz": "America\/New_York"
  },
  "2PS3": {
      "icao": "2PS3",
      "iata": "",
      "name": "Mathna Airport",
      "city": "York Springs",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 490,
      "lat": 40.0167999268,
      "lon": -77.0413970947,
      "tz": "America\/New_York"
  },
  "2SC5": {
      "icao": "2SC5",
      "iata": "",
      "name": "Ridgewood Air Airport",
      "city": "Waterloo",
      "state": "South Carolina",
      "country": "US",
      "elevation": 560,
      "lat": 34.2916984558,
      "lon": -82.0539016724,
      "tz": "America\/New_York"
  },
  "2SC7": {
      "icao": "2SC7",
      "iata": "",
      "name": "Laurel Hill Farms Airport",
      "city": "Mcclellanville",
      "state": "South Carolina",
      "country": "US",
      "elevation": 8,
      "lat": 33.051700592,
      "lon": -79.5413970947,
      "tz": "America\/New_York"
  },
  "2SC8": {
      "icao": "2SC8",
      "iata": "",
      "name": "Gaston Airport",
      "city": "Gaston",
      "state": "South Carolina",
      "country": "US",
      "elevation": 460,
      "lat": 33.7868003845,
      "lon": -81.0948028564,
      "tz": "America\/New_York"
  },
  "2SC9": {
      "icao": "2SC9",
      "iata": "",
      "name": "Mcintosh Airport",
      "city": "Kingstree",
      "state": "South Carolina",
      "country": "US",
      "elevation": 76,
      "lat": 33.750278,
      "lon": -79.957778,
      "tz": "America\/New_York"
  },
  "2SD0": {
      "icao": "2SD0",
      "iata": "",
      "name": "Paradise Valley Airport",
      "city": "Nemo",
      "state": "South Dakota",
      "country": "US",
      "elevation": 4500,
      "lat": 44.1636009216,
      "lon": -103.4789962769,
      "tz": "America\/Denver"
  },
  "2SD2": {
      "icao": "2SD2",
      "iata": "",
      "name": "Pepper Port Airport",
      "city": "Bonesteel",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1965,
      "lat": 43.1058006287,
      "lon": -98.9303970337,
      "tz": "America\/Chicago"
  },
  "2SD3": {
      "icao": "2SD3",
      "iata": "",
      "name": "Bollweg Farm Airport",
      "city": "Harrold",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1746,
      "lat": 44.5633010864,
      "lon": -99.7250976563,
      "tz": "America\/Chicago"
  },
  "2SD4": {
      "icao": "2SD4",
      "iata": "",
      "name": "Nicolaisen Airport",
      "city": "Mount Vernon",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1440,
      "lat": 43.6833000183,
      "lon": -98.265296936,
      "tz": "America\/Chicago"
  },
  "2SD7": {
      "icao": "2SD7",
      "iata": "",
      "name": "Dangel Airport",
      "city": "Hurley",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1284,
      "lat": 43.3333015442,
      "lon": -97.0627975464,
      "tz": "America\/Chicago"
  },
  "2SD8": {
      "icao": "2SD8",
      "iata": "",
      "name": "Bixler Ridge Airport",
      "city": "Centerville",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1250,
      "lat": 43.0456008911,
      "lon": -96.9391021729,
      "tz": "America\/Chicago"
  },
  "2SD9": {
      "icao": "2SD9",
      "iata": "",
      "name": "Tc Field",
      "city": "Ipswich",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1515,
      "lat": 45.4522018433,
      "lon": -99.0101013184,
      "tz": "America\/Chicago"
  },
  "2TA1": {
      "icao": "2TA1",
      "iata": "",
      "name": "Gravco STOLport",
      "city": "Lufkin",
      "state": "Texas",
      "country": "US",
      "elevation": 284,
      "lat": 31.3901996613,
      "lon": -94.6910018921,
      "tz": "America\/Chicago"
  },
  "2TA3": {
      "icao": "2TA3",
      "iata": "",
      "name": "Triangle Ranch Private Airport",
      "city": "Pine Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 4400,
      "lat": 31.7182006836,
      "lon": -104.5979995728,
      "tz": "America\/Chicago"
  },
  "2TA4": {
      "icao": "2TA4",
      "iata": "",
      "name": "Mario's Flying Pizza Airport",
      "city": "Sealy",
      "state": "Texas",
      "country": "US",
      "elevation": 233,
      "lat": 29.7476997375,
      "lon": -96.2927017212,
      "tz": "America\/Chicago"
  },
  "2TA6": {
      "icao": "2TA6",
      "iata": "",
      "name": "Pyramid Ranch Airport",
      "city": "Fairfield",
      "state": "Texas",
      "country": "US",
      "elevation": 362,
      "lat": 31.8626995087,
      "lon": -96.1975021362,
      "tz": "America\/Chicago"
  },
  "2TA8": {
      "icao": "2TA8",
      "iata": "",
      "name": "El Coyote Ranch Airport",
      "city": "Encino",
      "state": "Texas",
      "country": "US",
      "elevation": 196,
      "lat": 26.85840034,
      "lon": -98.22200012,
      "tz": "America\/Chicago"
  },
  "2TA9": {
      "icao": "2TA9",
      "iata": "",
      "name": "A M I G O For Christ Airport",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 840,
      "lat": 32.6604003906,
      "lon": -97.8420028687,
      "tz": "America\/Chicago"
  },
  "2TE0": {
      "icao": "2TE0",
      "iata": "",
      "name": "Eagle Air Park",
      "city": "Brazoria",
      "state": "Texas",
      "country": "US",
      "elevation": 15,
      "lat": 28.9822006226,
      "lon": -95.5796966553,
      "tz": "America\/Chicago"
  },
  "2TE2": {
      "icao": "2TE2",
      "iata": "",
      "name": "Flying Oaks Airport",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 720,
      "lat": 32.8306999207,
      "lon": -97.5335998535,
      "tz": "America\/Chicago"
  },
  "2TE3": {
      "icao": "2TE3",
      "iata": "",
      "name": "Weems Farm Airport",
      "city": "Aubrey",
      "state": "Texas",
      "country": "US",
      "elevation": 590,
      "lat": 33.2942008972,
      "lon": -96.9281005859,
      "tz": "America\/Chicago"
  },
  "2TE4": {
      "icao": "2TE4",
      "iata": "",
      "name": "Frels Airport",
      "city": "El Campo",
      "state": "Texas",
      "country": "US",
      "elevation": 100,
      "lat": 29.1791992188,
      "lon": -96.3227005005,
      "tz": "America\/Chicago"
  },
  "2TE5": {
      "icao": "2TE5",
      "iata": "",
      "name": "Piano Ranch Airport",
      "city": "Flatonia",
      "state": "Texas",
      "country": "US",
      "elevation": 383,
      "lat": 29.6951007843,
      "lon": -97.1632995605,
      "tz": "America\/Chicago"
  },
  "2TE6": {
      "icao": "2TE6",
      "iata": "",
      "name": "Burris Ranch Airport",
      "city": "Karnes City",
      "state": "Texas",
      "country": "US",
      "elevation": 448,
      "lat": 28.8878002167,
      "lon": -98.0503005981,
      "tz": "America\/Chicago"
  },
  "2TE7": {
      "icao": "2TE7",
      "iata": "",
      "name": "Beach Ranch Airport",
      "city": "Post",
      "state": "Texas",
      "country": "US",
      "elevation": 2303,
      "lat": 33.228167,
      "lon": -101.13535,
      "tz": "America\/Chicago"
  },
  "2TE8": {
      "icao": "2TE8",
      "iata": "",
      "name": "Wagner-Braxdale Airport",
      "city": "Crystal City",
      "state": "Texas",
      "country": "US",
      "elevation": 570,
      "lat": 28.6783008575,
      "lon": -99.7986984253,
      "tz": "America\/Chicago"
  },
  "2TN2": {
      "icao": "2TN2",
      "iata": "",
      "name": "Wings Field",
      "city": "Hickory Valley",
      "state": "Tennessee",
      "country": "US",
      "elevation": 540,
      "lat": 35.2235984802,
      "lon": -89.1889038086,
      "tz": "America\/Chicago"
  },
  "2TN4": {
      "icao": "2TN4",
      "iata": "",
      "name": "Shoemaker-Shelby Forest Airport",
      "city": "Millington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 335,
      "lat": 35.3180999756,
      "lon": -90.0353012085,
      "tz": "America\/Chicago"
  },
  "2TN7": {
      "icao": "2TN7",
      "iata": "",
      "name": "Wolf Creek Airport",
      "city": "Kingston",
      "state": "Tennessee",
      "country": "US",
      "elevation": 890,
      "lat": 35.8044013977,
      "lon": -84.415802002,
      "tz": "America\/New_York"
  },
  "2TN8": {
      "icao": "2TN8",
      "iata": "",
      "name": "Soggy Bottom Airport",
      "city": "Dowelltown",
      "state": "Tennessee",
      "country": "US",
      "elevation": 580,
      "lat": 36.0180015564,
      "lon": -85.9359970093,
      "tz": "America\/Chicago"
  },
  "2TS0": {
      "icao": "2TS0",
      "iata": "",
      "name": "Myska Field",
      "city": "Denton",
      "state": "Texas",
      "country": "US",
      "elevation": 595,
      "lat": 33.1557006836,
      "lon": -97.1380996704,
      "tz": "America\/Chicago"
  },
  "2TS2": {
      "icao": "2TS2",
      "iata": "",
      "name": "Shofner Farms Airport",
      "city": "Harlingen",
      "state": "Texas",
      "country": "US",
      "elevation": 57,
      "lat": 26.1683998108,
      "lon": -97.7335968018,
      "tz": "America\/Chicago"
  },
  "2TS3": {
      "icao": "2TS3",
      "iata": "",
      "name": "Tigerbird Field",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 31.6793003082,
      "lon": -97.2406005859,
      "tz": "America\/Chicago"
  },
  "2TS4": {
      "icao": "2TS4",
      "iata": "",
      "name": "Circle R Ranch Airport",
      "city": "Malakoff",
      "state": "Texas",
      "country": "US",
      "elevation": 395,
      "lat": 32.1397018433,
      "lon": -95.9702987671,
      "tz": "America\/Chicago"
  },
  "2TS5": {
      "icao": "2TS5",
      "iata": "",
      "name": "Hedley Airport",
      "city": "Hedley",
      "state": "Texas",
      "country": "US",
      "elevation": 2510,
      "lat": 34.893699646,
      "lon": -100.6100006104,
      "tz": "America\/Chicago"
  },
  "2TS6": {
      "icao": "2TS6",
      "iata": "",
      "name": "Eagle's Nest Estates Airport",
      "city": "Midlothian",
      "state": "Texas",
      "country": "US",
      "elevation": 780,
      "lat": 32.5125999451,
      "lon": -96.9266967773,
      "tz": "America\/Chicago"
  },
  "2TS8": {
      "icao": "2TS8",
      "iata": "",
      "name": "Bailey Airport",
      "city": "Stockdale",
      "state": "Texas",
      "country": "US",
      "elevation": 452,
      "lat": 29.220500946,
      "lon": -97.8716964722,
      "tz": "America\/Chicago"
  },
  "2TX0": {
      "icao": "2TX0",
      "iata": "",
      "name": "Blue Sky Airfield",
      "city": "Amarillo",
      "state": "Texas",
      "country": "US",
      "elevation": 3615,
      "lat": 35.0644989014,
      "lon": -101.8489990234,
      "tz": "America\/Chicago"
  },
  "2TX1": {
      "icao": "2TX1",
      "iata": "",
      "name": "Russell Paradise Airport",
      "city": "Elmendorf",
      "state": "Texas",
      "country": "US",
      "elevation": 535,
      "lat": 29.2252006531,
      "lon": -98.3835983276,
      "tz": "America\/Chicago"
  },
  "2TX2": {
      "icao": "2TX2",
      "iata": "",
      "name": "Ray Smith Farm Airport",
      "city": "Hico",
      "state": "Texas",
      "country": "US",
      "elevation": 1050,
      "lat": 32.0265007019,
      "lon": -98.0100021362,
      "tz": "America\/Chicago"
  },
  "2TX3": {
      "icao": "2TX3",
      "iata": "",
      "name": "La Fonda Ranch Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 1020,
      "lat": 29.2168998718,
      "lon": -100.6169967651,
      "tz": "America\/Chicago"
  },
  "2TX4": {
      "icao": "2TX4",
      "iata": "",
      "name": "Lewis Ranch Airport",
      "city": "Encinal",
      "state": "Texas",
      "country": "US",
      "elevation": 540,
      "lat": 27.9836006165,
      "lon": -99.376701355,
      "tz": "America\/Chicago"
  },
  "2TX5": {
      "icao": "2TX5",
      "iata": "",
      "name": "Berry Airport",
      "city": "Gatesville",
      "state": "Texas",
      "country": "US",
      "elevation": 757,
      "lat": 31.4535007477,
      "lon": -97.7514038086,
      "tz": "America\/Chicago"
  },
  "2TX6": {
      "icao": "2TX6",
      "iata": "",
      "name": "Everitt Airport",
      "city": "Idalou",
      "state": "Texas",
      "country": "US",
      "elevation": 3200,
      "lat": 33.6818008423,
      "lon": -101.6679992676,
      "tz": "America\/Chicago"
  },
  "2TX7": {
      "icao": "2TX7",
      "iata": "",
      "name": "Jw Airport",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 875,
      "lat": 33.0946006775,
      "lon": -97.3961029053,
      "tz": "America\/Chicago"
  },
  "2TX8": {
      "icao": "2TX8",
      "iata": "",
      "name": "Eagle's Landing Airport",
      "city": "Krum",
      "state": "Texas",
      "country": "US",
      "elevation": 930,
      "lat": 33.3082008362,
      "lon": -97.379699707,
      "tz": "America\/Chicago"
  },
  "2UT2": {
      "icao": "2UT2",
      "iata": "",
      "name": "High Meadow Ranch Airport",
      "city": "Duck Creek Village",
      "state": "Utah",
      "country": "US",
      "elevation": 8280,
      "lat": 37.5208015442,
      "lon": -112.641998291,
      "tz": "America\/Denver"
  },
  "2UT3": {
      "icao": "2UT3",
      "iata": "",
      "name": "Fort Ranch Airport",
      "city": "Brigham City",
      "state": "Utah",
      "country": "US",
      "elevation": 4440,
      "lat": 41.4921989441,
      "lon": -112.591003418,
      "tz": "America\/Denver"
  },
  "2VA0": {
      "icao": "2VA0",
      "iata": "",
      "name": "Red Birds Airyard Airport",
      "city": "Moneta",
      "state": "Virginia",
      "country": "US",
      "elevation": 940,
      "lat": 37.1125984192,
      "lon": -79.6091995239,
      "tz": "America\/New_York"
  },
  "2VA1": {
      "icao": "2VA1",
      "iata": "",
      "name": "Jett Airpark",
      "city": "Edwardsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 90,
      "lat": 37.8959999084,
      "lon": -76.3552017212,
      "tz": "America\/New_York"
  },
  "2VA2": {
      "icao": "2VA2",
      "iata": "",
      "name": "Melville Airstrip",
      "city": "Surry",
      "state": "Virginia",
      "country": "US",
      "elevation": 90,
      "lat": 37.1459999084,
      "lon": -76.7900009155,
      "tz": "America\/New_York"
  },
  "2VA3": {
      "icao": "2VA3",
      "iata": "",
      "name": "Merlin Aerodrome",
      "city": "Amelia Court House",
      "state": "Virginia",
      "country": "US",
      "elevation": 235,
      "lat": 37.3149986267,
      "lon": -77.8660964966,
      "tz": "America\/New_York"
  },
  "2VA5": {
      "icao": "2VA5",
      "iata": "",
      "name": "Rosegill Farm Airstrip",
      "city": "Urbanna",
      "state": "Virginia",
      "country": "US",
      "elevation": 32,
      "lat": 37.6306991577,
      "lon": -76.5650024414,
      "tz": "America\/New_York"
  },
  "2VA6": {
      "icao": "2VA6",
      "iata": "",
      "name": "Arrowhead Point Airport",
      "city": "Saluda",
      "state": "Virginia",
      "country": "US",
      "elevation": 85,
      "lat": 37.6237983704,
      "lon": -76.6010971069,
      "tz": "America\/New_York"
  },
  "2VA8": {
      "icao": "2VA8",
      "iata": "",
      "name": "Brandywyne Farms Airport",
      "city": "Holland",
      "state": "Virginia",
      "country": "US",
      "elevation": 66,
      "lat": 36.6837997437,
      "lon": -76.8300018311,
      "tz": "America\/New_York"
  },
  "2VA9": {
      "icao": "2VA9",
      "iata": "",
      "name": "Airlie Airport",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 522,
      "lat": 38.757900238,
      "lon": -77.7872009277,
      "tz": "America\/New_York"
  },
  "2VG2": {
      "icao": "2VG2",
      "iata": "",
      "name": "Upperville Airport",
      "city": "Upperville",
      "state": "Virginia",
      "country": "US",
      "elevation": 551,
      "lat": 38.9718017578,
      "lon": -77.8696975708,
      "tz": "America\/New_York"
  },
  "2VG3": {
      "icao": "2VG3",
      "iata": "",
      "name": "Cub Haven Airport",
      "city": "Harrisonburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 1350,
      "lat": 38.5485992432,
      "lon": -78.8711013794,
      "tz": "America\/New_York"
  },
  "2VG7": {
      "icao": "2VG7",
      "iata": "",
      "name": "Seven Gables Airport",
      "city": "Orange",
      "state": "Virginia",
      "country": "US",
      "elevation": 411,
      "lat": 38.163898468,
      "lon": -77.8499984741,
      "tz": "America\/New_York"
  },
  "2VG8": {
      "icao": "2VG8",
      "iata": "",
      "name": "Folly Neck Airport",
      "city": "Warsaw",
      "state": "Virginia",
      "country": "US",
      "elevation": 12,
      "lat": 37.8722000122,
      "lon": -76.7311019897,
      "tz": "America\/New_York"
  },
  "2WA1": {
      "icao": "2WA1",
      "iata": "",
      "name": "Diamond Point Airstrip",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 262,
      "lat": 48.0926017761,
      "lon": -122.9290008545,
      "tz": "America\/Los_Angeles"
  },
  "2WA3": {
      "icao": "2WA3",
      "iata": "",
      "name": "Stuart Island West Airport",
      "city": "Friday Harbor",
      "state": "Washington",
      "country": "US",
      "elevation": 200,
      "lat": 48.6845016479,
      "lon": -123.2099990845,
      "tz": "America\/Los_Angeles"
  },
  "2WA6": {
      "icao": "2WA6",
      "iata": "",
      "name": "Rice Ranch Airport",
      "city": "Grand Coulee",
      "state": "Washington",
      "country": "US",
      "elevation": 2434,
      "lat": 47.9981994629,
      "lon": -119.0889968872,
      "tz": "America\/Los_Angeles"
  },
  "2WA8": {
      "icao": "2WA8",
      "iata": "",
      "name": "Kramer Ranch Airport",
      "city": "Harrington",
      "state": "Washington",
      "country": "US",
      "elevation": 2181,
      "lat": 47.3816986084,
      "lon": -118.2969970703,
      "tz": "America\/Los_Angeles"
  },
  "2WA9": {
      "icao": "2WA9",
      "iata": "",
      "name": "Touchet Valley Airport",
      "city": "Huntsville",
      "state": "Washington",
      "country": "US",
      "elevation": 1350,
      "lat": 46.2891998291,
      "lon": -118.1009979248,
      "tz": "America\/Los_Angeles"
  },
  "2WI0": {
      "icao": "2WI0",
      "iata": "",
      "name": "Bender's Airport",
      "city": "Rothschild",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1197,
      "lat": 44.8526992798,
      "lon": -89.6000976563,
      "tz": "America\/Chicago"
  },
  "2WI1": {
      "icao": "2WI1",
      "iata": "",
      "name": "Uff-Da Airport",
      "city": "Stoughton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 945,
      "lat": 42.9435997009,
      "lon": -89.2865982056,
      "tz": "America\/Chicago"
  },
  "2WI2": {
      "icao": "2WI2",
      "iata": "",
      "name": "Shullsburg Airport",
      "city": "Shullsburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1080,
      "lat": 42.5569000244,
      "lon": -90.2276000977,
      "tz": "America\/Chicago"
  },
  "2WI4": {
      "icao": "2WI4",
      "iata": "",
      "name": "Plows & Props Airport",
      "city": "Springfield",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 840,
      "lat": 42.6427993774,
      "lon": -88.3986968994,
      "tz": "America\/Chicago"
  },
  "2WI5": {
      "icao": "2WI5",
      "iata": "",
      "name": "Blair Lake Airport",
      "city": "Mercer",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1625,
      "lat": 46.0974006653,
      "lon": -90.1632003784,
      "tz": "America\/Chicago"
  },
  "2WI6": {
      "icao": "2WI6",
      "iata": "",
      "name": "Matson Airport",
      "city": "Stoughton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 915,
      "lat": 42.9136009216,
      "lon": -89.1862030029,
      "tz": "America\/Chicago"
  },
  "2WI7": {
      "icao": "2WI7",
      "iata": "",
      "name": "Hecklers' Strip",
      "city": "Mount Vernon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1130,
      "lat": 42.9696998596,
      "lon": -89.665397644,
      "tz": "America\/Chicago"
  },
  "2WI8": {
      "icao": "2WI8",
      "iata": "",
      "name": "Davies Airport",
      "city": "Oostburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 645,
      "lat": 43.5974998474,
      "lon": -87.7789993286,
      "tz": "America\/Chicago"
  },
  "2WI9": {
      "icao": "2WI9",
      "iata": "",
      "name": "Bulldog Ranch Airport",
      "city": "Oxford",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 955,
      "lat": 43.7599983215,
      "lon": -89.634803772,
      "tz": "America\/Chicago"
  },
  "2WN2": {
      "icao": "2WN2",
      "iata": "",
      "name": "Gaffney Airport",
      "city": "Briggsville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 827,
      "lat": 43.6542015076,
      "lon": -89.6303024292,
      "tz": "America\/Chicago"
  },
  "2WN3": {
      "icao": "2WN3",
      "iata": "",
      "name": "Curns Airport",
      "city": "New London",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 790,
      "lat": 44.3578987122,
      "lon": -88.7613983154,
      "tz": "America\/Chicago"
  },
  "2WN4": {
      "icao": "2WN4",
      "iata": "",
      "name": "Mcfaul Airport",
      "city": "New London",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 780,
      "lat": 44.3394012451,
      "lon": -88.7811965942,
      "tz": "America\/Chicago"
  },
  "2WN5": {
      "icao": "2WN5",
      "iata": "",
      "name": "Murmuring Springs Airport",
      "city": "Necedah",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 910,
      "lat": 44.006401062,
      "lon": -90.019203186,
      "tz": "America\/Chicago"
  },
  "2WN6": {
      "icao": "2WN6",
      "iata": "",
      "name": "Cunningham Airport",
      "city": "Granton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1089,
      "lat": 44.5297012329,
      "lon": -90.4192962646,
      "tz": "America\/Chicago"
  },
  "2WN7": {
      "icao": "2WN7",
      "iata": "",
      "name": "Planeacres Airport",
      "city": "Fisk",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 844,
      "lat": 43.9486999512,
      "lon": -88.6732025146,
      "tz": "America\/Chicago"
  },
  "2WN8": {
      "icao": "2WN8",
      "iata": "",
      "name": "Oshkosh Sky Ranch Airport",
      "city": "Omro",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 770,
      "lat": 44.015228,
      "lon": -88.714269,
      "tz": "America\/Chicago"
  },
  "2WN9": {
      "icao": "2WN9",
      "iata": "",
      "name": "Hillcrest Airport",
      "city": "Goldendale",
      "state": "Washington",
      "country": "US",
      "elevation": 2405,
      "lat": 45.8451004028,
      "lon": -120.7009963989,
      "tz": "America\/Los_Angeles"
  },
  "2WS2": {
      "icao": "2WS2",
      "iata": "",
      "name": "Connor'S Lake Landing",
      "city": "Webster",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 45.913686,
      "lon": -92.293239,
      "tz": "America\/Chicago"
  },
  "2WS3": {
      "icao": "2WS3",
      "iata": "",
      "name": "C. R. Acres Airport",
      "city": "Greenleaf",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 44.280428,
      "lon": -88.093578,
      "tz": "America\/Chicago"
  },
  "2WV3": {
      "icao": "2WV3",
      "iata": "",
      "name": "Hales Landing Airport",
      "city": "Elizabeth",
      "state": "West Virginia",
      "country": "US",
      "elevation": 650,
      "lat": 39.1171989441,
      "lon": -81.3917007446,
      "tz": "America\/New_York"
  },
  "2WV5": {
      "icao": "2WV5",
      "iata": "",
      "name": "Willow Bend Airport",
      "city": "Union",
      "state": "West Virginia",
      "country": "US",
      "elevation": 2150,
      "lat": 37.5466995239,
      "lon": -80.5183029175,
      "tz": "America\/New_York"
  },
  "2WY3": {
      "icao": "2WY3",
      "iata": "",
      "name": "Haas Airport",
      "city": "Merna",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7980,
      "lat": 43.0071983337,
      "lon": -110.3170013428,
      "tz": "America\/Denver"
  },
  "2WY8": {
      "icao": "2WY8",
      "iata": "",
      "name": "Bar Flying E Airport",
      "city": "Cody",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5550,
      "lat": 44.4043998718,
      "lon": -109.2819976807,
      "tz": "America\/Denver"
  },
  "2XA0": {
      "icao": "2XA0",
      "iata": "",
      "name": "Foard County Airport",
      "city": "Crowell",
      "state": "Texas",
      "country": "US",
      "elevation": 1479,
      "lat": 33.9793014526,
      "lon": -99.7128982544,
      "tz": "America\/Chicago"
  },
  "2XA1": {
      "icao": "2XA1",
      "iata": "",
      "name": "El Caballero Airport",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 317,
      "lat": 28.1324005127,
      "lon": -98.9321975708,
      "tz": "America\/Chicago"
  },
  "2XA2": {
      "icao": "2XA2",
      "iata": "",
      "name": "Knape Airport",
      "city": "Danbury",
      "state": "Texas",
      "country": "US",
      "elevation": 20,
      "lat": 29.2203998566,
      "lon": -95.3108978271,
      "tz": "America\/Chicago"
  },
  "2XA4": {
      "icao": "2XA4",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Collinsville",
      "state": "Texas",
      "country": "US",
      "elevation": 730,
      "lat": 33.5807991028,
      "lon": -96.8003005981,
      "tz": "America\/Chicago"
  },
  "2XA5": {
      "icao": "2XA5",
      "iata": "",
      "name": "Someday Ranch Airport",
      "city": "Lockhart",
      "state": "Texas",
      "country": "US",
      "elevation": 466,
      "lat": 29.8036994934,
      "lon": -97.6912002563,
      "tz": "America\/Chicago"
  },
  "2XA7": {
      "icao": "2XA7",
      "iata": "",
      "name": "Tailwheel Airport",
      "city": "Longview",
      "state": "Texas",
      "country": "US",
      "elevation": 360,
      "lat": 32.6150016785,
      "lon": -94.7656021118,
      "tz": "America\/Chicago"
  },
  "2XS1": {
      "icao": "2XS1",
      "iata": "",
      "name": "Harris Ranch Airport",
      "city": "Johnson City",
      "state": "Texas",
      "country": "US",
      "elevation": 1188,
      "lat": 30.2196998596,
      "lon": -98.3025970459,
      "tz": "America\/Chicago"
  },
  "2XS2": {
      "icao": "2XS2",
      "iata": "",
      "name": "Indio-Faith Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 750,
      "lat": 28.2628002167,
      "lon": -100.162002563,
      "tz": "America\/Chicago"
  },
  "2XS3": {
      "icao": "2XS3",
      "iata": "",
      "name": "Glad Oaks Airport",
      "city": "Athens",
      "state": "Texas",
      "country": "US",
      "elevation": 487,
      "lat": 32.0167999268,
      "lon": -95.7002029419,
      "tz": "America\/Chicago"
  },
  "2XS4": {
      "icao": "2XS4",
      "iata": "",
      "name": "Skida Patch Airport",
      "city": "Bells",
      "state": "Texas",
      "country": "US",
      "elevation": 665,
      "lat": 33.6613998413,
      "lon": -96.4064025879,
      "tz": "America\/Chicago"
  },
  "2XS5": {
      "icao": "2XS5",
      "iata": "",
      "name": "Cross Triangle Ranch Airport",
      "city": "Twin Sisters",
      "state": "Texas",
      "country": "US",
      "elevation": 1420,
      "lat": 30.0104999542,
      "lon": -98.4253005981,
      "tz": "America\/Chicago"
  },
  "2XS6": {
      "icao": "2XS6",
      "iata": "",
      "name": "Foster Ranch Airport",
      "city": "Utopia",
      "state": "Texas",
      "country": "US",
      "elevation": 1687,
      "lat": 29.7210998535,
      "lon": -99.5711975098,
      "tz": "America\/Chicago"
  },
  "2XS7": {
      "icao": "2XS7",
      "iata": "",
      "name": "Annandale Ranch Airport",
      "city": "Uvalde",
      "state": "Texas",
      "country": "US",
      "elevation": 1205,
      "lat": 29.4501991272,
      "lon": -99.6837005615,
      "tz": "America\/Chicago"
  },
  "2XS8": {
      "icao": "2XS8",
      "iata": "",
      "name": "Benson Airstrip",
      "city": "Uvalde",
      "state": "Texas",
      "country": "US",
      "elevation": 929,
      "lat": 29.2294006348,
      "lon": -99.8238983154,
      "tz": "America\/Chicago"
  },
  "30AK": {
      "icao": "30AK",
      "iata": "",
      "name": "Doyle Estates Airport",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 50,
      "lat": 60.5027999878,
      "lon": -151.2599945068,
      "tz": "America\/Anchorage"
  },
  "30AR": {
      "icao": "30AR",
      "iata": "",
      "name": "Phalanx Airport",
      "city": "Greers Ferry",
      "state": "Arkansas",
      "country": "US",
      "elevation": 584,
      "lat": 35.54436111,
      "lon": -92.0561676,
      "tz": "America\/Chicago"
  },
  "30AZ": {
      "icao": "30AZ",
      "iata": "",
      "name": "Gila Compressor Station Airport",
      "city": "Arlington",
      "state": "Arizona",
      "country": "US",
      "elevation": 838,
      "lat": 33.25,
      "lon": -112.81300354,
      "tz": "America\/Phoenix"
  },
  "30CO": {
      "icao": "30CO",
      "iata": "",
      "name": "Coyote Creek Ranch Airport",
      "city": "Guffey",
      "state": "Colorado",
      "country": "US",
      "elevation": 8030,
      "lat": 38.6725006104,
      "lon": -105.3359985352,
      "tz": "America\/Denver"
  },
  "30GA": {
      "icao": "30GA",
      "iata": "",
      "name": "Elliott Field",
      "city": "Dawsonville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1500,
      "lat": 34.4589996338,
      "lon": -84.1812973022,
      "tz": "America\/New_York"
  },
  "30ID": {
      "icao": "30ID",
      "iata": "",
      "name": "Mason Airport",
      "city": "Murtaugh",
      "state": "Idaho",
      "country": "US",
      "elevation": 4210,
      "lat": 42.432222,
      "lon": -114.166389,
      "tz": "America\/Boise"
  },
  "30IN": {
      "icao": "30IN",
      "iata": "",
      "name": "Wigent Airport",
      "city": "Columbia City",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 41.2092018127,
      "lon": -85.4580001831,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "30IS": {
      "icao": "30IS",
      "iata": "",
      "name": "Aero Lake Estates Airport",
      "city": "Genoa",
      "state": "Illinois",
      "country": "US",
      "elevation": 875,
      "lat": 42.0772018433,
      "lon": -88.6250991821,
      "tz": "America\/Chicago"
  },
  "30KS": {
      "icao": "30KS",
      "iata": "",
      "name": "Tyler Airport",
      "city": "Arkansas City",
      "state": "Kansas",
      "country": "US",
      "elevation": 1200,
      "lat": 37.1323013306,
      "lon": -97.0734024048,
      "tz": "America\/Chicago"
  },
  "30LL": {
      "icao": "30LL",
      "iata": "",
      "name": "Williams Airpark",
      "city": "Chapin",
      "state": "Illinois",
      "country": "US",
      "elevation": 614,
      "lat": 39.7541999817,
      "lon": -90.3654022217,
      "tz": "America\/Chicago"
  },
  "30MI": {
      "icao": "30MI",
      "iata": "",
      "name": "Wolverton's Field",
      "city": "Buchanan",
      "state": "Michigan",
      "country": "US",
      "elevation": 750,
      "lat": 41.8591995239,
      "lon": -86.327796936,
      "tz": "America\/Detroit"
  },
  "30MO": {
      "icao": "30MO",
      "iata": "",
      "name": "Matzie Airport",
      "city": "Lebanon",
      "state": "Missouri",
      "country": "US",
      "elevation": 1129,
      "lat": 37.6459007263,
      "lon": -92.4945983887,
      "tz": "America\/Chicago"
  },
  "30NC": {
      "icao": "30NC",
      "iata": "",
      "name": "Happy Bottom Airport",
      "city": "Advance",
      "state": "North Carolina",
      "country": "US",
      "elevation": 705,
      "lat": 36.0485992432,
      "lon": -80.4569015503,
      "tz": "America\/New_York"
  },
  "30NE": {
      "icao": "30NE",
      "iata": "",
      "name": "Rs Ag-Land Airport",
      "city": "Campbell",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2000,
      "lat": 40.3558006287,
      "lon": -98.6830978394,
      "tz": "America\/Chicago"
  },
  "30NY": {
      "icao": "30NY",
      "iata": "",
      "name": "Coye Field",
      "city": "Canadice",
      "state": "New York",
      "country": "US",
      "elevation": 1610,
      "lat": 42.7500991821,
      "lon": -77.5580978394,
      "tz": "America\/New_York"
  },
  "30OK": {
      "icao": "30OK",
      "iata": "",
      "name": "Lobo Field",
      "city": "Calvin",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 860,
      "lat": 34.9168014526,
      "lon": -96.2335968018,
      "tz": "America\/Chicago"
  },
  "30OR": {
      "icao": "30OR",
      "iata": "",
      "name": "Bero Field",
      "city": "Vernonia",
      "state": "Oregon",
      "country": "US",
      "elevation": 700,
      "lat": 45.8026008606,
      "lon": -123.2799987793,
      "tz": "America\/Los_Angeles"
  },
  "30PA": {
      "icao": "30PA",
      "iata": "",
      "name": "Kings Airport",
      "city": "Schwenksville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 290,
      "lat": 40.2678985596,
      "lon": -75.4365997314,
      "tz": "America\/New_York"
  },
  "30TA": {
      "icao": "30TA",
      "iata": "",
      "name": "Tri-Modal Air Park",
      "city": "Georgetown",
      "state": "Texas",
      "country": "US",
      "elevation": 812,
      "lat": 30.5918998718,
      "lon": -97.7225036621,
      "tz": "America\/Chicago"
  },
  "30TE": {
      "icao": "30TE",
      "iata": "",
      "name": "Cone Airport",
      "city": "Ralls",
      "state": "Texas",
      "country": "US",
      "elevation": 3144,
      "lat": 33.8111991882,
      "lon": -101.3960037231,
      "tz": "America\/Chicago"
  },
  "30TS": {
      "icao": "30TS",
      "iata": "",
      "name": "Hall Airport",
      "city": "Ore City",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 32.8284988403,
      "lon": -94.7188034058,
      "tz": "America\/Chicago"
  },
  "30TX": {
      "icao": "30TX",
      "iata": "",
      "name": "Farmer's Co-Op Airport",
      "city": "Harlingen",
      "state": "Texas",
      "country": "US",
      "elevation": 35,
      "lat": 26.1355991364,
      "lon": -97.6947021484,
      "tz": "America\/Chicago"
  },
  "30WA": {
      "icao": "30WA",
      "iata": "",
      "name": "Weller Canyon Airport",
      "city": "Waitsburg",
      "state": "Washington",
      "country": "US",
      "elevation": 1300,
      "lat": 46.3148994446,
      "lon": -118.1780014038,
      "tz": "America\/Los_Angeles"
  },
  "30WI": {
      "icao": "30WI",
      "iata": "",
      "name": "Chambers Island Airport",
      "city": "Chambers Island",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 588,
      "lat": 45.1918983459,
      "lon": -87.3592987061,
      "tz": "America\/Chicago"
  },
  "30XS": {
      "icao": "30XS",
      "iata": "",
      "name": "Farwell Spraying Service Inc Airport",
      "city": "Farwell",
      "state": "Texas",
      "country": "US",
      "elevation": 4115,
      "lat": 34.3823013306,
      "lon": -103.0179977417,
      "tz": "America\/Chicago"
  },
  "31AK": {
      "icao": "31AK",
      "iata": "",
      "name": "Dalrymple's Airport",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 450,
      "lat": 64.832901001,
      "lon": -147.4250030518,
      "tz": "America\/Anchorage"
  },
  "31AZ": {
      "icao": "31AZ",
      "iata": "",
      "name": "Benson Airport",
      "city": "Benson",
      "state": "Arizona",
      "country": "US",
      "elevation": 3628,
      "lat": 31.9636993408,
      "lon": -110.2600021362,
      "tz": "America\/Phoenix"
  },
  "31FA": {
      "icao": "31FA",
      "iata": "",
      "name": "S & S Avion Ranch Airport",
      "city": "Oxford",
      "state": "Florida",
      "country": "US",
      "elevation": 70,
      "lat": 28.9591999054,
      "lon": -82.1342010498,
      "tz": "America\/New_York"
  },
  "31FL": {
      "icao": "31FL",
      "iata": "",
      "name": "Tedford Ranch Airport",
      "city": "Melbourne",
      "state": "Florida",
      "country": "US",
      "elevation": 54,
      "lat": 28.0433998108,
      "lon": -81.019203186,
      "tz": "America\/New_York"
  },
  "31II": {
      "icao": "31II",
      "iata": "",
      "name": "Rising Sun Airport",
      "city": "Rising Sun",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 38.9258995056,
      "lon": -84.9119033813,
      "tz": "America\/Indiana\/Vevay"
  },
  "31IN": {
      "icao": "31IN",
      "iata": "",
      "name": "Timber House Airport",
      "city": "Lafayette",
      "state": "Indiana",
      "country": "US",
      "elevation": 726,
      "lat": 40.2849998474,
      "lon": -86.8563995361,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "31KS": {
      "icao": "31KS",
      "iata": "",
      "name": "Mills Field",
      "city": "South Hutchinson",
      "state": "Kansas",
      "country": "US",
      "elevation": 1520,
      "lat": 37.9958000183,
      "lon": -97.9294967651,
      "tz": "America\/Chicago"
  },
  "31KY": {
      "icao": "31KY",
      "iata": "",
      "name": "Wild Blue Airport",
      "city": "Paris",
      "state": "Kentucky",
      "country": "US",
      "elevation": 890,
      "lat": 38.1105995178,
      "lon": -84.3221969604,
      "tz": "America\/New_York"
  },
  "31LL": {
      "icao": "31LL",
      "iata": "",
      "name": "Seeman Airport",
      "city": "Chenoa",
      "state": "Illinois",
      "country": "US",
      "elevation": 689,
      "lat": 40.7785987854,
      "lon": -88.6661987305,
      "tz": "America\/Chicago"
  },
  "31MA": {
      "icao": "31MA",
      "iata": "",
      "name": "Norm's Field",
      "city": "Belchertown",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 460,
      "lat": 42.2620010376,
      "lon": -72.4080963135,
      "tz": "America\/New_York"
  },
  "31MI": {
      "icao": "31MI",
      "iata": "",
      "name": "Rentz Ultralightport",
      "city": "Niles",
      "state": "Michigan",
      "country": "US",
      "elevation": 800,
      "lat": 41.779800415,
      "lon": -86.1772003174,
      "tz": "America\/Detroit"
  },
  "31MO": {
      "icao": "31MO",
      "iata": "",
      "name": "Hannah Airport",
      "city": "Bolckow",
      "state": "Missouri",
      "country": "US",
      "elevation": 937,
      "lat": 40.1054992676,
      "lon": -94.7433013916,
      "tz": "America\/Chicago"
  },
  "31NE": {
      "icao": "31NE",
      "iata": "",
      "name": "Grimm Farm Airport",
      "city": "Crofton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1670,
      "lat": 42.7550010681,
      "lon": -97.7051010132,
      "tz": "America\/Chicago"
  },
  "31TA": {
      "icao": "31TA",
      "iata": "",
      "name": "Vitek Field",
      "city": "Granger",
      "state": "Texas",
      "country": "US",
      "elevation": 580,
      "lat": 30.7294006348,
      "lon": -97.4253005981,
      "tz": "America\/Chicago"
  },
  "31TS": {
      "icao": "31TS",
      "iata": "",
      "name": "Flyers Field",
      "city": "Greenville",
      "state": "Texas",
      "country": "US",
      "elevation": 640,
      "lat": 33.1903991699,
      "lon": -96.1891021729,
      "tz": "America\/Chicago"
  },
  "31TX": {
      "icao": "31TX",
      "iata": "",
      "name": "Scott Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 590,
      "lat": 31.5967998505,
      "lon": -97.3300018311,
      "tz": "America\/Chicago"
  },
  "31VA": {
      "icao": "31VA",
      "iata": "",
      "name": "Aberdeen Field",
      "city": "Smithfield",
      "state": "Virginia",
      "country": "US",
      "elevation": 33,
      "lat": 37.0237998962,
      "lon": -76.5886001587,
      "tz": "America\/New_York"
  },
  "31WI": {
      "icao": "31WI",
      "iata": "",
      "name": "Mcdermott Air Park",
      "city": "Sullivan",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 875,
      "lat": 43.0071983337,
      "lon": -88.6019973755,
      "tz": "America\/Chicago"
  },
  "31WN": {
      "icao": "31WN",
      "iata": "",
      "name": "Berlin Field",
      "city": "Berlin",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 773,
      "lat": 43.9916000366,
      "lon": -88.962600708,
      "tz": "America\/Chicago"
  },
  "31XS": {
      "icao": "31XS",
      "iata": "",
      "name": "Fly-N-Ski Airport",
      "city": "Merit",
      "state": "Texas",
      "country": "US",
      "elevation": 638,
      "lat": 33.1837005615,
      "lon": -96.2703018188,
      "tz": "America\/Chicago"
  },
  "32AK": {
      "icao": "32AK",
      "iata": "",
      "name": "Hardrock Field",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 664,
      "lat": 64.90930176,
      "lon": -147.9140015,
      "tz": "America\/Anchorage"
  },
  "32CA": {
      "icao": "32CA",
      "iata": "",
      "name": "Stone Airstrip",
      "city": "Lemoore",
      "state": "California",
      "country": "US",
      "elevation": 220,
      "lat": 36.3333015442,
      "lon": -119.8249969482,
      "tz": "America\/Los_Angeles"
  },
  "32CL": {
      "icao": "32CL",
      "iata": "",
      "name": "Bob's Flying Service Inc Airport",
      "city": "Knights Landing",
      "state": "California",
      "country": "US",
      "elevation": 40,
      "lat": 38.8331985474,
      "lon": -121.7180023193,
      "tz": "America\/Los_Angeles"
  },
  "32CO": {
      "icao": "32CO",
      "iata": "",
      "name": "Braun Airport",
      "city": "Penrose",
      "state": "Colorado",
      "country": "US",
      "elevation": 5543,
      "lat": 38.4552993774,
      "lon": -105.0839996338,
      "tz": "America\/Denver"
  },
  "32FA": {
      "icao": "32FA",
      "iata": "",
      "name": "Sunset Strip Airpark",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 34,
      "lat": 27.2341995239,
      "lon": -80.9037017822,
      "tz": "America\/New_York"
  },
  "32GA": {
      "icao": "32GA",
      "iata": "",
      "name": "Sebastian Cove Airport",
      "city": "Eatonton",
      "state": "Georgia",
      "country": "US",
      "elevation": 490,
      "lat": 33.4542007446,
      "lon": -83.2778015137,
      "tz": "America\/New_York"
  },
  "32II": {
      "icao": "32II",
      "iata": "",
      "name": "Roberts Airport",
      "city": "Vevay",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 38.8163986206,
      "lon": -85.106300354,
      "tz": "America\/Indiana\/Vevay"
  },
  "32IS": {
      "icao": "32IS",
      "iata": "",
      "name": "Baker Airport",
      "city": "Kilbourne",
      "state": "Illinois",
      "country": "US",
      "elevation": 485,
      "lat": 40.1352996826,
      "lon": -91.0095977783,
      "tz": "America\/Chicago"
  },
  "32KS": {
      "icao": "32KS",
      "iata": "",
      "name": "Wilkens Airport",
      "city": "Healy",
      "state": "Kansas",
      "country": "US",
      "elevation": 2856,
      "lat": 38.5931015015,
      "lon": -100.6129989624,
      "tz": "America\/Chicago"
  },
  "32LL": {
      "icao": "32LL",
      "iata": "",
      "name": "The Sandbox Airport",
      "city": "Cordova",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 41.7042007446,
      "lon": -90.259803772,
      "tz": "America\/Chicago"
  },
  "32LS": {
      "icao": "32LS",
      "iata": "",
      "name": "Stevens Strip",
      "city": "Ponchatoula",
      "state": "Louisiana",
      "country": "US",
      "elevation": 13,
      "lat": 30.41959953,
      "lon": -90.35900116,
      "tz": "America\/Chicago"
  },
  "32MD": {
      "icao": "32MD",
      "iata": "",
      "name": "Roseland Airport",
      "city": "Sudlersville",
      "state": "Maryland",
      "country": "US",
      "elevation": 70,
      "lat": 39.1893997192,
      "lon": -75.8768005371,
      "tz": "America\/New_York"
  },
  "32MN": {
      "icao": "32MN",
      "iata": "",
      "name": "Don's Landing Field",
      "city": "Watkins",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1140,
      "lat": 45.3675003052,
      "lon": -94.3993988037,
      "tz": "America\/Chicago"
  },
  "32MO": {
      "icao": "32MO",
      "iata": "",
      "name": "Phillips Airport",
      "city": "Fredericktown",
      "state": "Missouri",
      "country": "US",
      "elevation": 860,
      "lat": 37.5766983032,
      "lon": -90.2259979248,
      "tz": "America\/Chicago"
  },
  "32MS": {
      "icao": "32MS",
      "iata": "",
      "name": "Browns Landing",
      "city": "Foxworth",
      "state": "Mississippi",
      "country": "US",
      "elevation": 385,
      "lat": 31.194167,
      "lon": -89.9525,
      "tz": "America\/Chicago"
  },
  "32NC": {
      "icao": "32NC",
      "iata": "",
      "name": "Hall Field",
      "city": "Parkton",
      "state": "North Carolina",
      "country": "US",
      "elevation": 175,
      "lat": 34.8964004517,
      "lon": -79.0430984497,
      "tz": "America\/New_York"
  },
  "32NE": {
      "icao": "32NE",
      "iata": "",
      "name": "Mc Ginn Ranch Airport",
      "city": "Dunning",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2758,
      "lat": 41.7210998535,
      "lon": -100.087997437,
      "tz": "America\/Chicago"
  },
  "32NK": {
      "icao": "32NK",
      "iata": "",
      "name": "Schoharie Creek Airport",
      "city": "Esperance",
      "state": "New York",
      "country": "US",
      "elevation": 620,
      "lat": 42.7532997131,
      "lon": -74.3110961914,
      "tz": "America\/New_York"
  },
  "32OH": {
      "icao": "32OH",
      "iata": "",
      "name": "Rall Field",
      "city": "Mansfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1330,
      "lat": 40.7611999512,
      "lon": -82.4179000854,
      "tz": "America\/New_York"
  },
  "32OK": {
      "icao": "32OK",
      "iata": "",
      "name": "Flying A Ranch Airport",
      "city": "Hartshorne",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 670,
      "lat": 34.8317985535,
      "lon": -95.5175018311,
      "tz": "America\/Chicago"
  },
  "32OR": {
      "icao": "32OR",
      "iata": "",
      "name": "Pine Hollow Airport",
      "city": "Wamic",
      "state": "Oregon",
      "country": "US",
      "elevation": 1820,
      "lat": 45.2547988892,
      "lon": -121.2939987183,
      "tz": "America\/Los_Angeles"
  },
  "32PA": {
      "icao": "32PA",
      "iata": "",
      "name": "Yost Personal Use Airport",
      "city": "Shermans Dale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 560,
      "lat": 40.3301010132,
      "lon": -77.1772003174,
      "tz": "America\/New_York"
  },
  "32PN": {
      "icao": "32PN",
      "iata": "",
      "name": "Black Rock Airport",
      "city": "New Brighton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1150,
      "lat": 40.7340011597,
      "lon": -80.2759017944,
      "tz": "America\/New_York"
  },
  "32TA": {
      "icao": "32TA",
      "iata": "",
      "name": "Wilbourn Ranch Airport",
      "city": "Harwood",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 29.6919002533,
      "lon": -97.4336013794,
      "tz": "America\/Chicago"
  },
  "32TE": {
      "icao": "32TE",
      "iata": "",
      "name": "Granite Shoals Municipal Airport",
      "city": "Granite Shoals",
      "state": "Texas",
      "country": "US",
      "elevation": 860,
      "lat": 30.5893993378,
      "lon": -98.370300293,
      "tz": "America\/Chicago"
  },
  "32TX": {
      "icao": "32TX",
      "iata": "",
      "name": "Hinson Airport",
      "city": "Kenney",
      "state": "Texas",
      "country": "US",
      "elevation": 365,
      "lat": 30.0580997467,
      "lon": -96.3591995239,
      "tz": "America\/Chicago"
  },
  "32VA": {
      "icao": "32VA",
      "iata": "",
      "name": "Old South Aerodrome",
      "city": "Abingdon",
      "state": "Virginia",
      "country": "US",
      "elevation": 1970,
      "lat": 36.677778,
      "lon": -81.928722,
      "tz": "America\/New_York"
  },
  "32WA": {
      "icao": "32WA",
      "iata": "",
      "name": "Whitestone Airport",
      "city": "Tonasket",
      "state": "Washington",
      "country": "US",
      "elevation": 1310,
      "lat": 48.7227777778,
      "lon": -119.467222222,
      "tz": "America\/Los_Angeles"
  },
  "32WI": {
      "icao": "32WI",
      "iata": "",
      "name": "Dalonia Airport",
      "city": "Hortonville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 44.3163986206,
      "lon": -88.7008972168,
      "tz": "America\/Chicago"
  },
  "32WN": {
      "icao": "32WN",
      "iata": "",
      "name": "Bowman Airstrip",
      "city": "Suring",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 840,
      "lat": 45.099606,
      "lon": -88.419733,
      "tz": "America\/Chicago"
  },
  "33AK": {
      "icao": "33AK",
      "iata": "",
      "name": "Nugget Bench Airport",
      "city": "Nugget Bench",
      "state": "Alaska",
      "country": "US",
      "elevation": 2010,
      "lat": 62.5172996521,
      "lon": -150.9450073242,
      "tz": "America\/Anchorage"
  },
  "33AR": {
      "icao": "33AR",
      "iata": "",
      "name": "Skypoint Estates Airport",
      "city": "Greers Ferry",
      "state": "Arkansas",
      "country": "US",
      "elevation": 547,
      "lat": 35.6021995544,
      "lon": -92.1425018311,
      "tz": "America\/Chicago"
  },
  "33AZ": {
      "icao": "33AZ",
      "iata": "",
      "name": "Yolo Ranch Airport",
      "city": "Camp Wood",
      "state": "Arizona",
      "country": "US",
      "elevation": 5950,
      "lat": 34.7985992432,
      "lon": -112.9700012207,
      "tz": "America\/Phoenix"
  },
  "33CA": {
      "icao": "33CA",
      "iata": "",
      "name": "Lloyd's Landing Airport",
      "city": "Rosamond",
      "state": "California",
      "country": "US",
      "elevation": 2690,
      "lat": 34.9054985046,
      "lon": -118.3020019531,
      "tz": "America\/Los_Angeles"
  },
  "33CL": {
      "icao": "33CL",
      "iata": "",
      "name": "Oak Country Ranch Airport",
      "city": "Paso Robles",
      "state": "California",
      "country": "US",
      "elevation": 1200,
      "lat": 35.5682983398,
      "lon": -120.7969970703,
      "tz": "America\/Los_Angeles"
  },
  "33CO": {
      "icao": "33CO",
      "iata": "",
      "name": "Melby Ranch Airstrip",
      "city": "San Luis",
      "state": "Colorado",
      "country": "US",
      "elevation": 8820,
      "lat": 37.0649986267,
      "lon": -105.4639968872,
      "tz": "America\/Denver"
  },
  "33CT": {
      "icao": "33CT",
      "iata": "",
      "name": "Irish Hills Farms Airport",
      "city": "Bethlehem",
      "state": "Connecticut",
      "country": "US",
      "elevation": 830,
      "lat": 41.609444,
      "lon": -73.239722,
      "tz": "America\/New_York"
  },
  "33FA": {
      "icao": "33FA",
      "iata": "",
      "name": "Recreation Corporation Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 67,
      "lat": 27.5981006622,
      "lon": -80.8459014893,
      "tz": "America\/New_York"
  },
  "33FD": {
      "icao": "33FD",
      "iata": "",
      "name": "R O Ranch STOLport",
      "city": "Mayo",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 29.9144001007,
      "lon": -83.2665023804,
      "tz": "America\/New_York"
  },
  "33FL": {
      "icao": "33FL",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Kenansville",
      "state": "Florida",
      "country": "US",
      "elevation": 72,
      "lat": 27.8608608246,
      "lon": -80.9998626709,
      "tz": "America\/New_York"
  },
  "33GA": {
      "icao": "33GA",
      "iata": "",
      "name": "Hudson River Landing Airport",
      "city": "Carnesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 665,
      "lat": 34.2599983215,
      "lon": -83.2866973877,
      "tz": "America\/New_York"
  },
  "33IL": {
      "icao": "33IL",
      "iata": "",
      "name": "John Scharff Airport",
      "city": "Clinton",
      "state": "Illinois",
      "country": "US",
      "elevation": 730,
      "lat": 40.1660995483,
      "lon": -88.8983001709,
      "tz": "America\/Chicago"
  },
  "33IS": {
      "icao": "33IS",
      "iata": "",
      "name": "Howell Airport",
      "city": "Findlay",
      "state": "Illinois",
      "country": "US",
      "elevation": 672,
      "lat": 39.5444984436,
      "lon": -88.757598877,
      "tz": "America\/Chicago"
  },
  "33KS": {
      "icao": "33KS",
      "iata": "",
      "name": "Buena Terra Airport",
      "city": "Topeka",
      "state": "Kansas",
      "country": "US",
      "elevation": 950,
      "lat": 39.1530990601,
      "lon": -95.6093978882,
      "tz": "America\/Chicago"
  },
  "33KY": {
      "icao": "33KY",
      "iata": "",
      "name": "Bigger (Stol) STOLport",
      "city": "Utica",
      "state": "Kentucky",
      "country": "US",
      "elevation": 406,
      "lat": 37.6123008728,
      "lon": -87.0569000244,
      "tz": "America\/Chicago"
  },
  "33LA": {
      "icao": "33LA",
      "iata": "",
      "name": "Sky Ranch Airport",
      "city": "Youngsville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 25,
      "lat": 30.0695991516,
      "lon": -91.9861984253,
      "tz": "America\/Chicago"
  },
  "33LL": {
      "icao": "33LL",
      "iata": "",
      "name": "Isley Airport",
      "city": "Wheeler",
      "state": "Illinois",
      "country": "US",
      "elevation": 595,
      "lat": 39.0875015259,
      "lon": -88.2889022827,
      "tz": "America\/Chicago"
  },
  "33MI": {
      "icao": "33MI",
      "iata": "",
      "name": "Great Lakes Airport",
      "city": "Sagola",
      "state": "Michigan",
      "country": "US",
      "elevation": 1300,
      "lat": 46.0666007996,
      "lon": -88.007598877,
      "tz": "America\/Menominee"
  },
  "33MN": {
      "icao": "33MN",
      "iata": "",
      "name": "Swift Private Airport",
      "city": "Wendell",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 46.0900001526,
      "lon": -96.0970001221,
      "tz": "America\/Chicago"
  },
  "33MO": {
      "icao": "33MO",
      "iata": "",
      "name": "Leaming Field",
      "city": "Golden City",
      "state": "Missouri",
      "country": "US",
      "elevation": 1135,
      "lat": 37.2513999939,
      "lon": -94.0569000244,
      "tz": "America\/Chicago"
  },
  "33NC": {
      "icao": "33NC",
      "iata": "",
      "name": "Pettigrew Moore Aerodrome",
      "city": "Wilmington",
      "state": "North Carolina",
      "country": "US",
      "elevation": 30,
      "lat": 34.4268989563,
      "lon": -78.0894012451,
      "tz": "America\/New_York"
  },
  "33NE": {
      "icao": "33NE",
      "iata": "",
      "name": "Orr Ranch Airport",
      "city": "Hay Springs",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3880,
      "lat": 42.386100769,
      "lon": -102.5749969482,
      "tz": "America\/Denver"
  },
  "33NR": {
      "icao": "33NR",
      "iata": "",
      "name": "Wolf Ridge Airport",
      "city": "Mars Hill",
      "state": "North Carolina",
      "country": "US",
      "elevation": 4605,
      "lat": 35.941667,
      "lon": -82.506667,
      "tz": "America\/New_York"
  },
  "33NY": {
      "icao": "33NY",
      "iata": "",
      "name": "Wayne Delp Airport",
      "city": "West Coxsackie",
      "state": "New York",
      "country": "US",
      "elevation": 800,
      "lat": 42.3995018005,
      "lon": -73.8662033081,
      "tz": "America\/New_York"
  },
  "33OH": {
      "icao": "33OH",
      "iata": "",
      "name": "Sunset Strip",
      "city": "Jerome",
      "state": "Ohio",
      "country": "US",
      "elevation": 945,
      "lat": 40.1852989197,
      "lon": -83.1735992432,
      "tz": "America\/New_York"
  },
  "33OI": {
      "icao": "33OI",
      "iata": "",
      "name": "Soaring Horse Airport",
      "city": "Chatham Township",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 41.0828018188,
      "lon": -82.0431976318,
      "tz": "America\/New_York"
  },
  "33OK": {
      "icao": "33OK",
      "iata": "",
      "name": "Myrick Airport",
      "city": "Edmond",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1195,
      "lat": 35.7432903732,
      "lon": -97.4055790901,
      "tz": "America\/Chicago"
  },
  "33OR": {
      "icao": "33OR",
      "iata": "",
      "name": "Crow-Mag Airport",
      "city": "Elmira",
      "state": "Oregon",
      "country": "US",
      "elevation": 450,
      "lat": 44.0637016296,
      "lon": -123.3909988403,
      "tz": "America\/Los_Angeles"
  },
  "33PA": {
      "icao": "33PA",
      "iata": "",
      "name": "Sutliff Private Airport",
      "city": "Shickshinny",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 800,
      "lat": 41.1668014526,
      "lon": -76.2497024536,
      "tz": "America\/New_York"
  },
  "33TA": {
      "icao": "33TA",
      "iata": "",
      "name": "Lake Bonanza Airport",
      "city": "Montgomery",
      "state": "Texas",
      "country": "US",
      "elevation": 301,
      "lat": 30.3330001831,
      "lon": -95.612197876,
      "tz": "America\/Chicago"
  },
  "33VA": {
      "icao": "33VA",
      "iata": "",
      "name": "Fox Fire Airport",
      "city": "Halifax",
      "state": "Virginia",
      "country": "US",
      "elevation": 535,
      "lat": 36.7843017578,
      "lon": -79.049697876,
      "tz": "America\/New_York"
  },
  "33WA": {
      "icao": "33WA",
      "iata": "",
      "name": "Franz Ranch Airport",
      "city": "Warden",
      "state": "Washington",
      "country": "US",
      "elevation": 1478,
      "lat": 47.0499000549,
      "lon": -118.8509979248,
      "tz": "America\/Los_Angeles"
  },
  "34AK": {
      "icao": "34AK",
      "iata": "",
      "name": "Cardwell Strip",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 425,
      "lat": 61.625,
      "lon": -149.2890014648,
      "tz": "America\/Anchorage"
  },
  "34AR": {
      "icao": "34AR",
      "iata": "",
      "name": "Woodbridge Field",
      "city": "Marked Tree",
      "state": "Arkansas",
      "country": "US",
      "elevation": 215,
      "lat": 35.5811004639,
      "lon": -90.3852996826,
      "tz": "America\/Chicago"
  },
  "34AZ": {
      "icao": "34AZ",
      "iata": "",
      "name": "Gila River Memorial Airport",
      "city": "Chandler",
      "state": "Arizona",
      "country": "US",
      "elevation": 1185,
      "lat": 33.2434005737,
      "lon": -111.9130020142,
      "tz": "America\/Phoenix"
  },
  "34CD": {
      "icao": "34CD",
      "iata": "",
      "name": "Elk Park Ranch Airport",
      "city": "Allenspark",
      "state": "Colorado",
      "country": "US",
      "elevation": 7900,
      "lat": 40.262683,
      "lon": -105.448011,
      "tz": "America\/Denver"
  },
  "34CN": {
      "icao": "34CN",
      "iata": "",
      "name": "Bonanza Hills Airport",
      "city": "Snelling",
      "state": "California",
      "country": "US",
      "elevation": 295,
      "lat": 37.5234985352,
      "lon": -120.3980026245,
      "tz": "America\/Los_Angeles"
  },
  "34CO": {
      "icao": "34CO",
      "iata": "",
      "name": "Simons Airport",
      "city": "Aurora",
      "state": "Colorado",
      "country": "US",
      "elevation": 5525,
      "lat": 39.7164001465,
      "lon": -104.7320022583,
      "tz": "America\/Denver"
  },
  "34FD": {
      "icao": "34FD",
      "iata": "",
      "name": "Blue Ridge Flightpark Airport",
      "city": "Oakhill",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 28.8572006226,
      "lon": -80.9069976807,
      "tz": "America\/New_York"
  },
  "34FL": {
      "icao": "34FL",
      "iata": "",
      "name": "Ellis Agricultural Field",
      "city": "Paxton",
      "state": "Florida",
      "country": "US",
      "elevation": 324,
      "lat": 30.9654998779,
      "lon": -86.302696228,
      "tz": "America\/Chicago"
  },
  "34ID": {
      "icao": "34ID",
      "iata": "",
      "name": "Freeman Creek Airport",
      "city": "Cavendish",
      "state": "Idaho",
      "country": "US",
      "elevation": 3100,
      "lat": 46.570333,
      "lon": -116.343611,
      "tz": "America\/Los_Angeles"
  },
  "34II": {
      "icao": "34II",
      "iata": "",
      "name": "Burk Personal Use Airport",
      "city": "Anderson",
      "state": "Indiana",
      "country": "US",
      "elevation": 930,
      "lat": 40.0505981445,
      "lon": -85.6027984619,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "34IL": {
      "icao": "34IL",
      "iata": "",
      "name": "G. Bray Airport",
      "city": "Lewistown",
      "state": "Illinois",
      "country": "US",
      "elevation": 550,
      "lat": 40.3624992371,
      "lon": -90.1084976196,
      "tz": "America\/Chicago"
  },
  "34IN": {
      "icao": "34IN",
      "iata": "",
      "name": "Windy P Ridge Airport",
      "city": "Portland",
      "state": "Indiana",
      "country": "US",
      "elevation": 1035,
      "lat": 40.3403015137,
      "lon": -85.0179977417,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "34IS": {
      "icao": "34IS",
      "iata": "",
      "name": "Jim Wehrli Memorial Airport",
      "city": "Marseilles",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 41.4448013306,
      "lon": -88.7166976929,
      "tz": "America\/Chicago"
  },
  "34KY": {
      "icao": "34KY",
      "iata": "",
      "name": "Lone Pine Aerodrome",
      "city": "Auburn",
      "state": "Kentucky",
      "country": "US",
      "elevation": 720,
      "lat": 36.9124984741,
      "lon": -86.7827987671,
      "tz": "America\/Chicago"
  },
  "34LA": {
      "icao": "34LA",
      "iata": "",
      "name": "Swamp Smith Airport",
      "city": "De Ridder",
      "state": "Louisiana",
      "country": "US",
      "elevation": 180,
      "lat": 30.7716007233,
      "lon": -93.2713012695,
      "tz": "America\/Chicago"
  },
  "34LS": {
      "icao": "34LS",
      "iata": "",
      "name": "Coastal Ridge Airpark",
      "city": "Abbeville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.0289001465,
      "lon": -92.014503479,
      "tz": "America\/Chicago"
  },
  "34MI": {
      "icao": "34MI",
      "iata": "",
      "name": "De Witt Property Airport",
      "city": "Spring Lake",
      "state": "Michigan",
      "country": "US",
      "elevation": 605,
      "lat": 43.1002998352,
      "lon": -86.2233963013,
      "tz": "America\/Detroit"
  },
  "34MS": {
      "icao": "34MS",
      "iata": "",
      "name": "Colle Field",
      "city": "Gautier",
      "state": "Mississippi",
      "country": "US",
      "elevation": 25,
      "lat": 30.4738006592,
      "lon": -88.6961975098,
      "tz": "America\/Chicago"
  },
  "34ND": {
      "icao": "34ND",
      "iata": "",
      "name": "Plath Farms Airport",
      "city": "Davenport",
      "state": "North Dakota",
      "country": "US",
      "elevation": 928,
      "lat": 46.7057991028,
      "lon": -97.15650177,
      "tz": "America\/Chicago"
  },
  "34NE": {
      "icao": "34NE",
      "iata": "",
      "name": "Evans Ranch Airport",
      "city": "Hershey",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2895,
      "lat": 41.2238998413,
      "lon": -100.9349975586,
      "tz": "America\/Chicago"
  },
  "34NY": {
      "icao": "34NY",
      "iata": "",
      "name": "Hendershot Airport",
      "city": "Hilton",
      "state": "New York",
      "country": "US",
      "elevation": 300,
      "lat": 43.2901000977,
      "lon": -77.8273010254,
      "tz": "America\/New_York"
  },
  "34OH": {
      "icao": "34OH",
      "iata": "",
      "name": "Arend Airport",
      "city": "Mark Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 720,
      "lat": 41.2569999695,
      "lon": -84.6416015625,
      "tz": "America\/New_York"
  },
  "34OI": {
      "icao": "34OI",
      "iata": "",
      "name": "Snoddy Air Strip",
      "city": "Shreve",
      "state": "Ohio",
      "country": "US",
      "elevation": 1180,
      "lat": 40.6944999695,
      "lon": -82.1178970337,
      "tz": "America\/New_York"
  },
  "34OK": {
      "icao": "34OK",
      "iata": "",
      "name": "Double Bar S Ranches Airport",
      "city": "Shamrock",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 950,
      "lat": 35.900100708,
      "lon": -96.5503005981,
      "tz": "America\/Chicago"
  },
  "34PA": {
      "icao": "34PA",
      "iata": "",
      "name": "Waltz Airport",
      "city": "Gettysburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 542,
      "lat": 39.7607002258,
      "lon": -77.1930007935,
      "tz": "America\/New_York"
  },
  "34PN": {
      "icao": "34PN",
      "iata": "",
      "name": "Fox Field",
      "city": "Newport",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 730,
      "lat": 40.485556,
      "lon": -77.220556,
      "tz": "America\/New_York"
  },
  "34TA": {
      "icao": "34TA",
      "iata": "",
      "name": "Jsi Airport",
      "city": "Princeton",
      "state": "Texas",
      "country": "US",
      "elevation": 590,
      "lat": 33.167098999,
      "lon": -96.487197876,
      "tz": "America\/Chicago"
  },
  "34TE": {
      "icao": "34TE",
      "iata": "",
      "name": "Bee Creek Airport",
      "city": "Maypearl",
      "state": "Texas",
      "country": "US",
      "elevation": 760,
      "lat": 32.2321014404,
      "lon": -97.0085983276,
      "tz": "America\/Chicago"
  },
  "34TS": {
      "icao": "34TS",
      "iata": "",
      "name": "Canyon Lake Airport",
      "city": "Canyon Lake",
      "state": "Texas",
      "country": "US",
      "elevation": 940,
      "lat": 29.9158000946,
      "lon": -98.2474975586,
      "tz": "America\/Chicago"
  },
  "34WI": {
      "icao": "34WI",
      "iata": "",
      "name": "Thiessen Field",
      "city": "Baraboo",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1200,
      "lat": 43.4178009033,
      "lon": -89.7654037476,
      "tz": "America\/Chicago"
  },
  "34XS": {
      "icao": "34XS",
      "iata": "",
      "name": "Flying Hare Airport",
      "city": "Conroe",
      "state": "Texas",
      "country": "US",
      "elevation": 225,
      "lat": 30.391944,
      "lon": -95.538611,
      "tz": "America\/Chicago"
  },
  "35AR": {
      "icao": "35AR",
      "iata": "",
      "name": "Black Horse Landing Ultralightport",
      "city": "Eureka Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1207,
      "lat": 36.3563995361,
      "lon": -93.6863021851,
      "tz": "America\/Chicago"
  },
  "35AZ": {
      "icao": "35AZ",
      "iata": "",
      "name": "Continental Airport",
      "city": "Continental",
      "state": "Arizona",
      "country": "US",
      "elevation": 2869,
      "lat": 31.8437004089,
      "lon": -110.9769973755,
      "tz": "America\/Phoenix"
  },
  "35CL": {
      "icao": "35CL",
      "iata": "",
      "name": "Flying M Ranch Airport",
      "city": "Merced",
      "state": "California",
      "country": "US",
      "elevation": 154,
      "lat": 37.2318992615,
      "lon": -120.4789962769,
      "tz": "America\/Los_Angeles"
  },
  "35CN": {
      "icao": "35CN",
      "iata": "",
      "name": "Farnsworth Ranch Airstrip",
      "city": "Grimes",
      "state": "California",
      "country": "US",
      "elevation": 50,
      "lat": 39.099899292,
      "lon": -121.9339981079,
      "tz": "America\/Los_Angeles"
  },
  "35CO": {
      "icao": "35CO",
      "iata": "",
      "name": "Lone Tree Ranch Airport",
      "city": "Punkin Center",
      "state": "Colorado",
      "country": "US",
      "elevation": 5525,
      "lat": 38.8819999695,
      "lon": -103.8089981079,
      "tz": "America\/Denver"
  },
  "35FA": {
      "icao": "35FA",
      "iata": "",
      "name": "Rimes Lakecrest Airport",
      "city": "Cross Creek",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 29.4678993225,
      "lon": -82.1587982178,
      "tz": "America\/New_York"
  },
  "35FD": {
      "icao": "35FD",
      "iata": "",
      "name": "Rush Airport",
      "city": "Trenton",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 29.6923007965,
      "lon": -82.7292022705,
      "tz": "America\/New_York"
  },
  "35II": {
      "icao": "35II",
      "iata": "",
      "name": "Brown Airport",
      "city": "Goshen",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 41.542301178,
      "lon": -85.8419036865,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "35IL": {
      "icao": "35IL",
      "iata": "",
      "name": "Clarion Field",
      "city": "La Moille",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 41.5339012146,
      "lon": -89.202796936,
      "tz": "America\/Chicago"
  },
  "35IN": {
      "icao": "35IN",
      "iata": "",
      "name": "Ellison Airport",
      "city": "Tipton",
      "state": "Indiana",
      "country": "US",
      "elevation": 875,
      "lat": 40.2944984436,
      "lon": -85.9424972534,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "35IS": {
      "icao": "35IS",
      "iata": "",
      "name": "Enoch Airport",
      "city": "Findlay",
      "state": "Illinois",
      "country": "US",
      "elevation": 676,
      "lat": 39.5284004211,
      "lon": -88.763999939,
      "tz": "America\/Chicago"
  },
  "35KS": {
      "icao": "35KS",
      "iata": "",
      "name": "Selby Farm Airport",
      "city": "Derby",
      "state": "Kansas",
      "country": "US",
      "elevation": 1250,
      "lat": 37.5335998535,
      "lon": -97.3116989136,
      "tz": "America\/Chicago"
  },
  "35KY": {
      "icao": "35KY",
      "iata": "",
      "name": "Welcome Field",
      "city": "Franklin",
      "state": "Kentucky",
      "country": "US",
      "elevation": 695,
      "lat": 36.7112007141,
      "lon": -86.636100769,
      "tz": "America\/Chicago"
  },
  "35LA": {
      "icao": "35LA",
      "iata": "",
      "name": "Ms Pats Airport",
      "city": "Abbeville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.9745006561,
      "lon": -92.1761016846,
      "tz": "America\/Chicago"
  },
  "35ME": {
      "icao": "35ME",
      "iata": "",
      "name": "Matinicus Island Airport",
      "city": "Matinicus Island",
      "state": "Maine",
      "country": "US",
      "elevation": 15,
      "lat": 43.8711013794,
      "lon": -68.8933029175,
      "tz": "America\/New_York"
  },
  "35MI": {
      "icao": "35MI",
      "iata": "",
      "name": "Law Field",
      "city": "Vermontville",
      "state": "Michigan",
      "country": "US",
      "elevation": 920,
      "lat": 42.6338996887,
      "lon": -84.9858016968,
      "tz": "America\/Detroit"
  },
  "35MN": {
      "icao": "35MN",
      "iata": "",
      "name": "Wipline Airport",
      "city": "Inver Grove Heights",
      "state": "Minnesota",
      "country": "US",
      "elevation": 687,
      "lat": 44.8154983521,
      "lon": -93.0134963989,
      "tz": "America\/Chicago"
  },
  "35NC": {
      "icao": "35NC",
      "iata": "",
      "name": "Johnston Airport",
      "city": "Mooresville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 715,
      "lat": 35.5382995605,
      "lon": -80.7514038086,
      "tz": "America\/New_York"
  },
  "35NE": {
      "icao": "35NE",
      "iata": "",
      "name": "Shelburnes Airport",
      "city": "Ogallala",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3475,
      "lat": 41.0499992371,
      "lon": -101.8050003052,
      "tz": "America\/Denver"
  },
  "35NY": {
      "icao": "35NY",
      "iata": "",
      "name": "Ciszak Airport",
      "city": "Springville",
      "state": "New York",
      "country": "US",
      "elevation": 1397,
      "lat": 42.5125999451,
      "lon": -78.7352981567,
      "tz": "America\/New_York"
  },
  "35OK": {
      "icao": "35OK",
      "iata": "",
      "name": "Schumacher Field",
      "city": "Clinton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1595,
      "lat": 35.5,
      "lon": -99.0355987549,
      "tz": "America\/Chicago"
  },
  "35OL": {
      "icao": "35OL",
      "iata": "",
      "name": "Henderson Farm Airport",
      "city": "Mooreland",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1750,
      "lat": 36.6330986023,
      "lon": -99.1511993408,
      "tz": "America\/Chicago"
  },
  "35OR": {
      "icao": "35OR",
      "iata": "",
      "name": "Freight Wagon Field",
      "city": "Redmond",
      "state": "Oregon",
      "country": "US",
      "elevation": 3195,
      "lat": 44.1926002502,
      "lon": -121.1790008545,
      "tz": "America\/Los_Angeles"
  },
  "35TS": {
      "icao": "35TS",
      "iata": "",
      "name": "Hempstead Gliderport",
      "city": "Hempstead",
      "state": "Texas",
      "country": "US",
      "elevation": 260,
      "lat": 30.1215991974,
      "lon": -96.0866012573,
      "tz": "America\/Chicago"
  },
  "35TX": {
      "icao": "35TX",
      "iata": "",
      "name": "Flying B Ranch Airstrip",
      "city": "Abilene",
      "state": "Texas",
      "country": "US",
      "elevation": 1750,
      "lat": 32.4840011597,
      "lon": -99.8255996704,
      "tz": "America\/Chicago"
  },
  "35WA": {
      "icao": "35WA",
      "iata": "",
      "name": "Fisher Ranch Airport",
      "city": "Washtucna",
      "state": "Washington",
      "country": "US",
      "elevation": 1521,
      "lat": 46.7499008179,
      "lon": -118.4179992676,
      "tz": "America\/Los_Angeles"
  },
  "35WI": {
      "icao": "35WI",
      "iata": "",
      "name": "Barker Strip",
      "city": "East Troy",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 42.7456016541,
      "lon": -88.4375991821,
      "tz": "America\/Chicago"
  },
  "36AK": {
      "icao": "36AK",
      "iata": "",
      "name": "Flyway Farm Airstrip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 61.3432998657,
      "lon": -150.0670013428,
      "tz": "America\/Anchorage"
  },
  "36AR": {
      "icao": "36AR",
      "iata": "",
      "name": "David Stanley Memorial Airport",
      "city": "Gregory",
      "state": "Arkansas",
      "country": "US",
      "elevation": 200,
      "lat": 35.206401825,
      "lon": -91.318901062,
      "tz": "America\/Chicago"
  },
  "36AZ": {
      "icao": "36AZ",
      "iata": "",
      "name": "Valley Farms Airport",
      "city": "Coolidge",
      "state": "Arizona",
      "country": "US",
      "elevation": 1478,
      "lat": 32.9936981201,
      "lon": -111.4509963989,
      "tz": "America\/Phoenix"
  },
  "36CA": {
      "icao": "36CA",
      "iata": "",
      "name": "Stone Land County Airport",
      "city": "Stratford",
      "state": "California",
      "country": "US",
      "elevation": 265,
      "lat": 36.1319007874,
      "lon": -119.986000061,
      "tz": "America\/Los_Angeles"
  },
  "36CN": {
      "icao": "36CN",
      "iata": "",
      "name": "Blackwell Land Company Inc Airport",
      "city": "Lost Hills",
      "state": "California",
      "country": "US",
      "elevation": 760,
      "lat": 35.6363983154,
      "lon": -120.0090026855,
      "tz": "America\/Los_Angeles"
  },
  "36CO": {
      "icao": "36CO",
      "iata": "",
      "name": "Fat Chance Airport",
      "city": "Peyton",
      "state": "Colorado",
      "country": "US",
      "elevation": 7100,
      "lat": 39.106098175,
      "lon": -104.5439987183,
      "tz": "America\/Denver"
  },
  "36GA": {
      "icao": "36GA",
      "iata": "",
      "name": "Lola Landing Airport",
      "city": "Conyers",
      "state": "Georgia",
      "country": "US",
      "elevation": 870,
      "lat": 33.7300987244,
      "lon": -83.9746017456,
      "tz": "America\/New_York"
  },
  "36II": {
      "icao": "36II",
      "iata": "",
      "name": "Newby Landing Airport",
      "city": "Brownsburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 878,
      "lat": 39.8412017822,
      "lon": -86.3486022949,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "36IN": {
      "icao": "36IN",
      "iata": "",
      "name": "Peterson Ultralightport",
      "city": "North Liberty",
      "state": "Indiana",
      "country": "US",
      "elevation": 730,
      "lat": 41.5362014771,
      "lon": -86.443901062,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "36IS": {
      "icao": "36IS",
      "iata": "",
      "name": "Gillen Airport",
      "city": "Monmouth",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 40.926399231,
      "lon": -90.7312011719,
      "tz": "America\/Chicago"
  },
  "36KS": {
      "icao": "36KS",
      "iata": "",
      "name": "White Farms Airport",
      "city": "Galesburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 37.4748001099,
      "lon": -95.3435974121,
      "tz": "America\/Chicago"
  },
  "36KY": {
      "icao": "36KY",
      "iata": "",
      "name": "Arnolds Airport",
      "city": "Springfield",
      "state": "Kentucky",
      "country": "US",
      "elevation": 843,
      "lat": 37.6556015015,
      "lon": -85.1538009644,
      "tz": "America\/New_York"
  },
  "36MI": {
      "icao": "36MI",
      "iata": "",
      "name": "Tecumseh Products Airport",
      "city": "Tecumseh",
      "state": "Michigan",
      "country": "US",
      "elevation": 795,
      "lat": 42.032699585,
      "lon": -83.8783035278,
      "tz": "America\/Detroit"
  },
  "36MN": {
      "icao": "36MN",
      "iata": "",
      "name": "Wagner Farm Airport",
      "city": "Barnesville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 935,
      "lat": 46.6716003418,
      "lon": -96.575302124,
      "tz": "America\/Chicago"
  },
  "36MO": {
      "icao": "36MO",
      "iata": "",
      "name": "Harrison Private Airport",
      "city": "Rolla",
      "state": "Missouri",
      "country": "US",
      "elevation": 1050,
      "lat": 37.8527984619,
      "lon": -91.6467971802,
      "tz": "America\/Chicago"
  },
  "36NC": {
      "icao": "36NC",
      "iata": "",
      "name": "Greensboro North Airport",
      "city": "Greensboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 900,
      "lat": 36.2536010742,
      "lon": -79.9080963135,
      "tz": "America\/New_York"
  },
  "36NE": {
      "icao": "36NE",
      "iata": "",
      "name": "Frevert Airstrip",
      "city": "Wausa",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1800,
      "lat": 42.5141983032,
      "lon": -97.5809020996,
      "tz": "America\/Chicago"
  },
  "36NY": {
      "icao": "36NY",
      "iata": "",
      "name": "Dew Airpark",
      "city": "Cuba",
      "state": "New York",
      "country": "US",
      "elevation": 2100,
      "lat": 42.2359008789,
      "lon": -78.2117004395,
      "tz": "America\/New_York"
  },
  "36OH": {
      "icao": "36OH",
      "iata": "",
      "name": "Wright's Field",
      "city": "Conesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 770,
      "lat": 40.1902999878,
      "lon": -81.9739990234,
      "tz": "America\/New_York"
  },
  "36OI": {
      "icao": "36OI",
      "iata": "",
      "name": "Verhoff Airport",
      "city": "Continental",
      "state": "Ohio",
      "country": "US",
      "elevation": 728,
      "lat": 41.0731010437,
      "lon": -84.2126998901,
      "tz": "America\/New_York"
  },
  "36OK": {
      "icao": "36OK",
      "iata": "",
      "name": "Jones Air Park",
      "city": "Tecumseh",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 35.2723007202,
      "lon": -96.8816986084,
      "tz": "America\/Chicago"
  },
  "36OR": {
      "icao": "36OR",
      "iata": "",
      "name": "Jasper Ridge Airstrip",
      "city": "Springfield\/Jasper",
      "state": "Oregon",
      "country": "US",
      "elevation": 1125,
      "lat": 44.0032997131,
      "lon": -122.8799972534,
      "tz": "America\/Los_Angeles"
  },
  "36TN": {
      "icao": "36TN",
      "iata": "",
      "name": "Ruckman Field",
      "city": "Clarksville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 660,
      "lat": 36.4770011902,
      "lon": -87.1398010254,
      "tz": "America\/Chicago"
  },
  "36TX": {
      "icao": "36TX",
      "iata": "",
      "name": "Bevoni-Flying B Airport",
      "city": "Whitesboro",
      "state": "Texas",
      "country": "US",
      "elevation": 763,
      "lat": 33.7209014893,
      "lon": -96.8613967896,
      "tz": "America\/Chicago"
  },
  "36WA": {
      "icao": "36WA",
      "iata": "",
      "name": "Bob's Field",
      "city": "Trout Lake",
      "state": "Washington",
      "country": "US",
      "elevation": 1650,
      "lat": 45.956401825,
      "lon": -121.4980010986,
      "tz": "America\/Los_Angeles"
  },
  "36WI": {
      "icao": "36WI",
      "iata": "",
      "name": "Holland Air Park",
      "city": "Holmen",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 730,
      "lat": 44.0321998596,
      "lon": -91.2995986938,
      "tz": "America\/Chicago"
  },
  "36XS": {
      "icao": "36XS",
      "iata": "",
      "name": "Flying F Airport",
      "city": "Nocona",
      "state": "Texas",
      "country": "US",
      "elevation": 890,
      "lat": 33.8777008057,
      "lon": -97.7297973633,
      "tz": "America\/Chicago"
  },
  "37AR": {
      "icao": "37AR",
      "iata": "",
      "name": "Beech Creek Airport",
      "city": "Hamburg",
      "state": "Arkansas",
      "country": "US",
      "elevation": 175,
      "lat": 33.1824989319,
      "lon": -91.6283035278,
      "tz": "America\/Chicago"
  },
  "37AZ": {
      "icao": "37AZ",
      "iata": "",
      "name": "Sarita Airport",
      "city": "Coolidge",
      "state": "Arizona",
      "country": "US",
      "elevation": 1464,
      "lat": 32.9356002808,
      "lon": -111.4850006104,
      "tz": "America\/Phoenix"
  },
  "37CA": {
      "icao": "37CA",
      "iata": "",
      "name": "Billy Joe Airport",
      "city": "Temecula",
      "state": "California",
      "country": "US",
      "elevation": 1350,
      "lat": 33.5069999695,
      "lon": -117.0709991455,
      "tz": "America\/Los_Angeles"
  },
  "37CL": {
      "icao": "37CL",
      "iata": "",
      "name": "Lyall-Roberts Airport",
      "city": "Escondido",
      "state": "California",
      "country": "US",
      "elevation": 925,
      "lat": 33.3245010376,
      "lon": -116.9899978638,
      "tz": "America\/Los_Angeles"
  },
  "37CN": {
      "icao": "37CN",
      "iata": "",
      "name": "Arnold Ranch Airport",
      "city": "Fresno",
      "state": "California",
      "country": "US",
      "elevation": 382,
      "lat": 36.9143981934,
      "lon": -119.7860031128,
      "tz": "America\/Los_Angeles"
  },
  "37FL": {
      "icao": "37FL",
      "iata": "",
      "name": "Flying Harness Farms Airport",
      "city": "Bell",
      "state": "Florida",
      "country": "US",
      "elevation": 78,
      "lat": 29.7185993195,
      "lon": -82.8445968628,
      "tz": "America\/New_York"
  },
  "37GA": {
      "icao": "37GA",
      "iata": "",
      "name": "Blue Bird Field",
      "city": "Crandall",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 34.9003982544,
      "lon": -84.778503418,
      "tz": "America\/New_York"
  },
  "37ID": {
      "icao": "37ID",
      "iata": "",
      "name": "Hungry Ridge Ranch Airport",
      "city": "Grangeville",
      "state": "Idaho",
      "country": "US",
      "elevation": 4599,
      "lat": 45.782417,
      "lon": -115.938,
      "tz": "America\/Los_Angeles"
  },
  "37II": {
      "icao": "37II",
      "iata": "",
      "name": "Winn Field",
      "city": "Culver",
      "state": "Indiana",
      "country": "US",
      "elevation": 808,
      "lat": 41.1755981445,
      "lon": -86.3735961914,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "37IL": {
      "icao": "37IL",
      "iata": "",
      "name": "Minder Airport",
      "city": "Lincoln",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 40.1333999634,
      "lon": -89.3251037598,
      "tz": "America\/Chicago"
  },
  "37IN": {
      "icao": "37IN",
      "iata": "",
      "name": "Felix Airport",
      "city": "Stockwell",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 40.2916984558,
      "lon": -86.7802963257,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "37IS": {
      "icao": "37IS",
      "iata": "",
      "name": "Hilbert Airport",
      "city": "Marengo",
      "state": "Illinois",
      "country": "US",
      "elevation": 890,
      "lat": 42.3321990967,
      "lon": -88.6055984497,
      "tz": "America\/Chicago"
  },
  "37KS": {
      "icao": "37KS",
      "iata": "",
      "name": "Bar P Ranch Airport",
      "city": "Howard",
      "state": "Kansas",
      "country": "US",
      "elevation": 1060,
      "lat": 37.4819984436,
      "lon": -96.3377990723,
      "tz": "America\/Chicago"
  },
  "37LA": {
      "icao": "37LA",
      "iata": "",
      "name": "Wayne Brown Airport",
      "city": "Clayton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 59,
      "lat": 31.7276992798,
      "lon": -91.582901001,
      "tz": "America\/Chicago"
  },
  "37LL": {
      "icao": "37LL",
      "iata": "",
      "name": "Dale Curten Farm Airport",
      "city": "Modoc",
      "state": "Illinois",
      "country": "US",
      "elevation": 615,
      "lat": 38.0477981567,
      "lon": -89.9971008301,
      "tz": "America\/Chicago"
  },
  "37MI": {
      "icao": "37MI",
      "iata": "",
      "name": "Handleman Sky Ranch Airport",
      "city": "Oxford",
      "state": "Michigan",
      "country": "US",
      "elevation": 1150,
      "lat": 42.8527984619,
      "lon": -83.2276992798,
      "tz": "America\/Detroit"
  },
  "37MN": {
      "icao": "37MN",
      "iata": "",
      "name": "Nagel and Schultz Airport",
      "city": "Belle Plaine",
      "state": "Minnesota",
      "country": "US",
      "elevation": 970,
      "lat": 44.5821990967,
      "lon": -93.8015975952,
      "tz": "America\/Chicago"
  },
  "37MO": {
      "icao": "37MO",
      "iata": "",
      "name": "Mayes Homestead Airport",
      "city": "Polo",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.5750007629,
      "lon": -94.1836013794,
      "tz": "America\/Chicago"
  },
  "37ND": {
      "icao": "37ND",
      "iata": "",
      "name": "Sunset Strip",
      "city": "Dunn Center",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2360,
      "lat": 47.2319984436,
      "lon": -102.5090026855,
      "tz": "America\/Denver"
  },
  "37NE": {
      "icao": "37NE",
      "iata": "",
      "name": "Watermeier Airport",
      "city": "Burr",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1100,
      "lat": 40.60779953,
      "lon": -96.307800293,
      "tz": "America\/Chicago"
  },
  "37OI": {
      "icao": "37OI",
      "iata": "",
      "name": "Cole Airfield",
      "city": "Crestline",
      "state": "Ohio",
      "country": "US",
      "elevation": 1100,
      "lat": 40.8250999451,
      "lon": -82.7967987061,
      "tz": "America\/New_York"
  },
  "37OR": {
      "icao": "37OR",
      "iata": "",
      "name": "Vey Sheep Ranch Airport",
      "city": "Starkey",
      "state": "Oregon",
      "country": "US",
      "elevation": 4158,
      "lat": 45.0932006836,
      "lon": -118.3990020752,
      "tz": "America\/Los_Angeles"
  },
  "37PA": {
      "icao": "37PA",
      "iata": "",
      "name": "Roadcap Airport",
      "city": "Middleburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 731,
      "lat": 40.7883987427,
      "lon": -77.1061019897,
      "tz": "America\/New_York"
  },
  "37TE": {
      "icao": "37TE",
      "iata": "",
      "name": "Johnnie Volk Field",
      "city": "Hitchcock",
      "state": "Texas",
      "country": "US",
      "elevation": 13,
      "lat": 29.360200882,
      "lon": -95.0099029541,
      "tz": "America\/Chicago"
  },
  "37TS": {
      "icao": "37TS",
      "iata": "",
      "name": "Skinner Airport",
      "city": "Greenville",
      "state": "Texas",
      "country": "US",
      "elevation": 460,
      "lat": 32.9906997681,
      "lon": -96.0400009155,
      "tz": "America\/Chicago"
  },
  "37TX": {
      "icao": "37TX",
      "iata": "",
      "name": "Yates Field",
      "city": "San Saba",
      "state": "Texas",
      "country": "US",
      "elevation": 1400,
      "lat": 31.3215999603,
      "lon": -98.8656005859,
      "tz": "America\/Chicago"
  },
  "37VT": {
      "icao": "37VT",
      "iata": "",
      "name": "Brisson Airport",
      "city": "Shoreham",
      "state": "Vermont",
      "country": "US",
      "elevation": 320,
      "lat": 43.868598938,
      "lon": -73.3180999756,
      "tz": "America\/New_York"
  },
  "37WA": {
      "icao": "37WA",
      "iata": "",
      "name": "Baumann Farm Inc. Airport",
      "city": "Washtucna",
      "state": "Washington",
      "country": "US",
      "elevation": 1600,
      "lat": 46.8317985535,
      "lon": -118.4619979858,
      "tz": "America\/Los_Angeles"
  },
  "37WI": {
      "icao": "37WI",
      "iata": "",
      "name": "Docken Field",
      "city": "Mount Horeb",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1230,
      "lat": 42.9911003113,
      "lon": -89.7535018921,
      "tz": "America\/Chicago"
  },
  "37XS": {
      "icao": "37XS",
      "iata": "",
      "name": "Lake Whitney Country Club Airport",
      "city": "Whitney",
      "state": "Texas",
      "country": "US",
      "elevation": 627,
      "lat": 32.0001983643,
      "lon": -97.4169998169,
      "tz": "America\/Chicago"
  },
  "38AK": {
      "icao": "38AK",
      "iata": "",
      "name": "Mels Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 340,
      "lat": 61.5625,
      "lon": -149.6679992676,
      "tz": "America\/Anchorage"
  },
  "38AR": {
      "icao": "38AR",
      "iata": "",
      "name": "Williams Field",
      "city": "Siloam Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1150,
      "lat": 36.1188011169,
      "lon": -94.4682006836,
      "tz": "America\/Chicago"
  },
  "38AZ": {
      "icao": "38AZ",
      "iata": "",
      "name": "Peabody Bedard Field",
      "city": "Kayenta",
      "state": "Arizona",
      "country": "US",
      "elevation": 6564,
      "lat": 36.471698761,
      "lon": -110.4179992676,
      "tz": "America\/Denver"
  },
  "38CA": {
      "icao": "38CA",
      "iata": "",
      "name": "Cashen Airport",
      "city": "Wasco",
      "state": "California",
      "country": "US",
      "elevation": 225,
      "lat": 35.6916007996,
      "lon": -119.4889984131,
      "tz": "America\/Los_Angeles"
  },
  "38CL": {
      "icao": "38CL",
      "iata": "",
      "name": "Riego Flight Strip",
      "city": "Verona",
      "state": "California",
      "country": "US",
      "elevation": 21,
      "lat": 38.7541007996,
      "lon": -121.56300354,
      "tz": "America\/Los_Angeles"
  },
  "38CN": {
      "icao": "38CN",
      "iata": "",
      "name": "Sanborn Airport",
      "city": "Meridian",
      "state": "California",
      "country": "US",
      "elevation": 36,
      "lat": 39.1092987061,
      "lon": -121.8840026855,
      "tz": "America\/Los_Angeles"
  },
  "38FA": {
      "icao": "38FA",
      "iata": "",
      "name": "Blue Springs Airport",
      "city": "Madison",
      "state": "Florida",
      "country": "US",
      "elevation": 95,
      "lat": 30.483499527,
      "lon": -83.2499008179,
      "tz": "America\/New_York"
  },
  "38ID": {
      "icao": "38ID",
      "iata": "",
      "name": "Sky Ranch North Airport",
      "city": "Nampa",
      "state": "Idaho",
      "country": "US",
      "elevation": 2750,
      "lat": 43.5094444444,
      "lon": -116.667916667,
      "tz": "America\/Boise"
  },
  "38II": {
      "icao": "38II",
      "iata": "",
      "name": "Hampton Field",
      "city": "Greencastle",
      "state": "Indiana",
      "country": "US",
      "elevation": 895,
      "lat": 39.728611,
      "lon": -86.823056,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "38IN": {
      "icao": "38IN",
      "iata": "",
      "name": "Fuller Field",
      "city": "Brownsburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 910,
      "lat": 39.9020004272,
      "lon": -86.3630981445,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "38IS": {
      "icao": "38IS",
      "iata": "",
      "name": "Winchester Airport",
      "city": "Nauvoo",
      "state": "Illinois",
      "country": "US",
      "elevation": 685,
      "lat": 40.5820007324,
      "lon": -91.3584976196,
      "tz": "America\/Chicago"
  },
  "38KS": {
      "icao": "38KS",
      "iata": "",
      "name": "Savute Airport",
      "city": "Kechi",
      "state": "Kansas",
      "country": "US",
      "elevation": 1400,
      "lat": 37.7778015137,
      "lon": -97.2774963379,
      "tz": "America\/Chicago"
  },
  "38LA": {
      "icao": "38LA",
      "iata": "",
      "name": "Whiteville Airport",
      "city": "Ville Platte",
      "state": "Louisiana",
      "country": "US",
      "elevation": 43,
      "lat": 30.7844009399,
      "lon": -92.1822967529,
      "tz": "America\/Chicago"
  },
  "38LS": {
      "icao": "38LS",
      "iata": "",
      "name": "Ace Flying Airport",
      "city": "Parks",
      "state": "Louisiana",
      "country": "US",
      "elevation": 23,
      "lat": 30.236700058,
      "lon": -91.8296966553,
      "tz": "America\/Chicago"
  },
  "38MI": {
      "icao": "38MI",
      "iata": "",
      "name": "Chicora Field",
      "city": "Allegan",
      "state": "Michigan",
      "country": "US",
      "elevation": 700,
      "lat": 42.4668998718,
      "lon": -85.9792022705,
      "tz": "America\/Detroit"
  },
  "38MN": {
      "icao": "38MN",
      "iata": "",
      "name": "Chandler Personal Use Airport",
      "city": "Bemidji",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1380,
      "lat": 47.4110984802,
      "lon": -94.7724990845,
      "tz": "America\/Chicago"
  },
  "38MO": {
      "icao": "38MO",
      "iata": "",
      "name": "Barber Airport",
      "city": "Annada",
      "state": "Missouri",
      "country": "US",
      "elevation": 480,
      "lat": 39.2555999756,
      "lon": -90.8556976318,
      "tz": "America\/Chicago"
  },
  "38ND": {
      "icao": "38ND",
      "iata": "",
      "name": "Schroeder Private Airport",
      "city": "Erie",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1140,
      "lat": 47.0979995728,
      "lon": -97.4011993408,
      "tz": "America\/Chicago"
  },
  "38NE": {
      "icao": "38NE",
      "iata": "",
      "name": "Boardman Aerial Airport",
      "city": "Henderson",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1720,
      "lat": 40.7536010742,
      "lon": -97.8514022827,
      "tz": "America\/Chicago"
  },
  "38NY": {
      "icao": "38NY",
      "iata": "",
      "name": "Greenlawn Farm Airport",
      "city": "Dundee",
      "state": "New York",
      "country": "US",
      "elevation": 1010,
      "lat": 42.5486984253,
      "lon": -76.9580001831,
      "tz": "America\/New_York"
  },
  "38OH": {
      "icao": "38OH",
      "iata": "",
      "name": "Industry Air Park",
      "city": "Baltimore",
      "state": "Ohio",
      "country": "US",
      "elevation": 877,
      "lat": 39.8447990417,
      "lon": -82.5795974731,
      "tz": "America\/New_York"
  },
  "38OI": {
      "icao": "38OI",
      "iata": "",
      "name": "Ronshausen Airport",
      "city": "Darbyville",
      "state": "Ohio",
      "country": "US",
      "elevation": 750,
      "lat": 39.6851005554,
      "lon": -83.0718994141,
      "tz": "America\/New_York"
  },
  "38PA": {
      "icao": "38PA",
      "iata": "",
      "name": "Aerequus Airport",
      "city": "Solebury",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 390,
      "lat": 40.3908996582,
      "lon": -75.0140991211,
      "tz": "America\/New_York"
  },
  "38PN": {
      "icao": "38PN",
      "iata": "",
      "name": "Waisley Airport",
      "city": "Mc Kean",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 950,
      "lat": 42.0000991821,
      "lon": -80.1664962769,
      "tz": "America\/New_York"
  },
  "38SC": {
      "icao": "38SC",
      "iata": "",
      "name": "La Dolce Terra Airport",
      "city": "Gaffney",
      "state": "North Carolina",
      "country": "US",
      "elevation": 890,
      "lat": 35.177778,
      "lon": -81.747778,
      "tz": "America\/New_York"
  },
  "38TN": {
      "icao": "38TN",
      "iata": "",
      "name": "Sugar Grove Airport",
      "city": "Westmoreland",
      "state": "Tennessee",
      "country": "US",
      "elevation": 800,
      "lat": 36.6253013611,
      "lon": -86.2733001709,
      "tz": "America\/Chicago"
  },
  "38WA": {
      "icao": "38WA",
      "iata": "BYW",
      "name": "Blakely Island Airport",
      "city": "Blakely Island",
      "state": "Washington",
      "country": "US",
      "elevation": 66,
      "lat": 48.5789985657,
      "lon": -122.825996399,
      "tz": "America\/Los_Angeles"
  },
  "38WI": {
      "icao": "38WI",
      "iata": "",
      "name": "Northport Airport",
      "city": "Royalton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 44.3885993958,
      "lon": -88.8526000977,
      "tz": "America\/Chicago"
  },
  "38WV": {
      "icao": "38WV",
      "iata": "",
      "name": "River's Edge Farm Airport",
      "city": "Capon Bridge",
      "state": "West Virginia",
      "country": "US",
      "elevation": 810,
      "lat": 39.3252983093,
      "lon": -78.4255981445,
      "tz": "America\/New_York"
  },
  "38XS": {
      "icao": "38XS",
      "iata": "",
      "name": "San Rafael Ranch Airport",
      "city": "Santa Elena",
      "state": "Texas",
      "country": "US",
      "elevation": 312,
      "lat": 26.8152008057,
      "lon": -98.4793014526,
      "tz": "America\/Chicago"
  },
  "39AK": {
      "icao": "39AK",
      "iata": "",
      "name": "Gilmore Strip",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 340,
      "lat": 61.5859985352,
      "lon": -149.2359924316,
      "tz": "America\/Anchorage"
  },
  "39AR": {
      "icao": "39AR",
      "iata": "",
      "name": "Twin Cities Airport",
      "city": "Van Buren",
      "state": "Arkansas",
      "country": "US",
      "elevation": 410,
      "lat": 35.4171981812,
      "lon": -94.3264007568,
      "tz": "America\/Chicago"
  },
  "39AZ": {
      "icao": "39AZ",
      "iata": "",
      "name": "Lukachukai Airport",
      "city": "Lukachukai",
      "state": "Arizona",
      "country": "US",
      "elevation": 6433,
      "lat": 36.4006004333,
      "lon": -109.2570037842,
      "tz": "America\/Denver"
  },
  "39CO": {
      "icao": "39CO",
      "iata": "",
      "name": "Flying M Ranch Airport",
      "city": "Montrose",
      "state": "Colorado",
      "country": "US",
      "elevation": 7200,
      "lat": 38.4879989624,
      "lon": -107.6740036011,
      "tz": "America\/Denver"
  },
  "39FA": {
      "icao": "39FA",
      "iata": "",
      "name": "Sanders Ranch Airport",
      "city": "Melrose",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 29.6625003815,
      "lon": -82.0093002319,
      "tz": "America\/New_York"
  },
  "39FD": {
      "icao": "39FD",
      "iata": "",
      "name": "Ancient Oaks Airport",
      "city": "Steinhatchee",
      "state": "Florida",
      "country": "US",
      "elevation": 26,
      "lat": 29.7294006348,
      "lon": -83.3535003662,
      "tz": "America\/New_York"
  },
  "39IA": {
      "icao": "39IA",
      "iata": "",
      "name": "Husband Field",
      "city": "Dallas Center",
      "state": "Iowa",
      "country": "US",
      "elevation": 1010,
      "lat": 41.6794013977,
      "lon": -94.0202026367,
      "tz": "America\/Chicago"
  },
  "39ID": {
      "icao": "39ID",
      "iata": "",
      "name": "Albion Municipal Airport",
      "city": "Albion",
      "state": "Idaho",
      "country": "US",
      "elevation": 4777,
      "lat": 42.3977012634,
      "lon": -113.5579986572,
      "tz": "America\/Boise"
  },
  "39II": {
      "icao": "39II",
      "iata": "",
      "name": "Miller Field",
      "city": "Topeka",
      "state": "Indiana",
      "country": "US",
      "elevation": 957,
      "lat": 41.5148010254,
      "lon": -85.5522003174,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "39IL": {
      "icao": "39IL",
      "iata": "",
      "name": "Mason Airport",
      "city": "Lincoln",
      "state": "Illinois",
      "country": "US",
      "elevation": 591,
      "lat": 40.0938987732,
      "lon": -89.3628997803,
      "tz": "America\/Chicago"
  },
  "39IN": {
      "icao": "39IN",
      "iata": "",
      "name": "Roberts Field",
      "city": "Sulphur Springs",
      "state": "Indiana",
      "country": "US",
      "elevation": 1085,
      "lat": 40.0023002625,
      "lon": -85.4289016724,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "39IS": {
      "icao": "39IS",
      "iata": "",
      "name": "Hagi Landing Area Airport",
      "city": "Streator",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 41.1100006104,
      "lon": -88.7956008911,
      "tz": "America\/Chicago"
  },
  "39KS": {
      "icao": "39KS",
      "iata": "",
      "name": "Rolling Meadows Airport",
      "city": "Worden",
      "state": "Kansas",
      "country": "US",
      "elevation": 1050,
      "lat": 38.7860984802,
      "lon": -95.3075027466,
      "tz": "America\/Chicago"
  },
  "39LA": {
      "icao": "39LA",
      "iata": "",
      "name": "Whitaker Ultralightport",
      "city": "Chataignier",
      "state": "Louisiana",
      "country": "US",
      "elevation": 65,
      "lat": 30.58833333,
      "lon": -92.23055556,
      "tz": "America\/Chicago"
  },
  "39LL": {
      "icao": "39LL",
      "iata": "",
      "name": "Sullivan Airport",
      "city": "Hamilton",
      "state": "Illinois",
      "country": "US",
      "elevation": 635,
      "lat": 40.401389,
      "lon": -91.303056,
      "tz": "America\/Chicago"
  },
  "39MI": {
      "icao": "39MI",
      "iata": "",
      "name": "Huber Airport",
      "city": "Crystal Falls",
      "state": "Michigan",
      "country": "US",
      "elevation": 1418,
      "lat": 46.168800354,
      "lon": -88.1724014282,
      "tz": "America\/Menominee"
  },
  "39MN": {
      "icao": "39MN",
      "iata": "",
      "name": "Anthony Private Airport",
      "city": "Two Harbors",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 47.0368995667,
      "lon": -91.7105026245,
      "tz": "America\/Chicago"
  },
  "39MO": {
      "icao": "39MO",
      "iata": "",
      "name": "Gardner Airport",
      "city": "Springfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1325,
      "lat": 37.1186981201,
      "lon": -93.2049026489,
      "tz": "America\/Chicago"
  },
  "39OH": {
      "icao": "39OH",
      "iata": "",
      "name": "Crosswind Meadows Airport",
      "city": "Minerva",
      "state": "Ohio",
      "country": "US",
      "elevation": 1160,
      "lat": 40.8123016357,
      "lon": -81.0550994873,
      "tz": "America\/New_York"
  },
  "39OK": {
      "icao": "39OK",
      "iata": "",
      "name": "Paradise Air Haven Airport",
      "city": "Goldsby",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1205,
      "lat": 35.1114997864,
      "lon": -97.4793014526,
      "tz": "America\/Chicago"
  },
  "39PA": {
      "icao": "39PA",
      "iata": "",
      "name": "Gehris Airport",
      "city": "Souderton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 570,
      "lat": 40.3172988892,
      "lon": -75.2904968262,
      "tz": "America\/New_York"
  },
  "39PN": {
      "icao": "39PN",
      "iata": "",
      "name": "Nelson's Run Airport",
      "city": "Mercer",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1305,
      "lat": 41.2159004211,
      "lon": -80.1976013184,
      "tz": "America\/New_York"
  },
  "39TA": {
      "icao": "39TA",
      "iata": "",
      "name": "Flying Tigers Airport",
      "city": "Paris",
      "state": "Texas",
      "country": "US",
      "elevation": 588,
      "lat": 33.6525993347,
      "lon": -95.6557998657,
      "tz": "America\/Chicago"
  },
  "39TE": {
      "icao": "39TE",
      "iata": "",
      "name": "Seminole Spraying Service Airport",
      "city": "Seminole",
      "state": "Texas",
      "country": "US",
      "elevation": 3372,
      "lat": 32.7182006836,
      "lon": -102.737998962,
      "tz": "America\/Chicago"
  },
  "39TN": {
      "icao": "39TN",
      "iata": "",
      "name": "Big Sandy Airpark",
      "city": "Big Sandy",
      "state": "Tennessee",
      "country": "US",
      "elevation": 632,
      "lat": 36.279722,
      "lon": -88.043611,
      "tz": "America\/Chicago"
  },
  "39WA": {
      "icao": "39WA",
      "iata": "",
      "name": "Tailskid Ranch Airport",
      "city": "Tum Tum",
      "state": "Washington",
      "country": "US",
      "elevation": 1910,
      "lat": 47.8518981934,
      "lon": -117.7819976807,
      "tz": "America\/Los_Angeles"
  },
  "39WI": {
      "icao": "39WI",
      "iata": "",
      "name": "S & S Ranch Airport",
      "city": "Viola",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1200,
      "lat": 43.4846992493,
      "lon": -90.6199035645,
      "tz": "America\/Chicago"
  },
  "3AK1": {
      "icao": "3AK1",
      "iata": "",
      "name": "Deshka Landing Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 110,
      "lat": 61.7187995911,
      "lon": -150.1959991455,
      "tz": "America\/Anchorage"
  },
  "3AK2": {
      "icao": "3AK2",
      "iata": "",
      "name": "Niklason Lake Estates Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 410,
      "lat": 61.6274986267,
      "lon": -149.2870025635,
      "tz": "America\/Anchorage"
  },
  "3AK3": {
      "icao": "3AK3",
      "iata": "",
      "name": "Songlo Vista Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 825,
      "lat": 62.5638008118,
      "lon": -150.220993042,
      "tz": "America\/Anchorage"
  },
  "3AK4": {
      "icao": "3AK4",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 102,
      "lat": 60.6324996948,
      "lon": -151.3399963379,
      "tz": "America\/Anchorage"
  },
  "3AK5": {
      "icao": "3AK5",
      "iata": "",
      "name": "Drift River Airport",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 30,
      "lat": 60.5889015198,
      "lon": -152.1620025635,
      "tz": "America\/Anchorage"
  },
  "3AK6": {
      "icao": "3AK6",
      "iata": "",
      "name": "B & B Boys Ranch Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.5928993225,
      "lon": -149.3020019531,
      "tz": "America\/Anchorage"
  },
  "3AK7": {
      "icao": "3AK7",
      "iata": "",
      "name": "Laub Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 61.7655982971,
      "lon": -150.3390045166,
      "tz": "America\/Anchorage"
  },
  "3AK8": {
      "icao": "3AK8",
      "iata": "",
      "name": "Boisselle's Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 400,
      "lat": 61.6624984741,
      "lon": -149.391998291,
      "tz": "America\/Anchorage"
  },
  "3AK9": {
      "icao": "3AK9",
      "iata": "",
      "name": "River John Airport",
      "city": "Skwentna",
      "state": "Alaska",
      "country": "US",
      "elevation": 170,
      "lat": 61.941944,
      "lon": -151.036111,
      "tz": "America\/Anchorage"
  },
  "3AL1": {
      "icao": "3AL1",
      "iata": "",
      "name": "Flying H Ranch Airport",
      "city": "Hartford",
      "state": "Alabama",
      "country": "US",
      "elevation": 264,
      "lat": 31.0503997803,
      "lon": -85.6853027344,
      "tz": "America\/Chicago"
  },
  "3AL5": {
      "icao": "3AL5",
      "iata": "",
      "name": "Edwards Farm Airport",
      "city": "Jacksonville",
      "state": "Alabama",
      "country": "US",
      "elevation": 750,
      "lat": 33.8353004456,
      "lon": -85.7639007568,
      "tz": "America\/Chicago"
  },
  "3AL6": {
      "icao": "3AL6",
      "iata": "",
      "name": "Town & Country Airpark",
      "city": "Branchville",
      "state": "Alabama",
      "country": "US",
      "elevation": 730,
      "lat": 33.4901008606,
      "lon": -86.4124984741,
      "tz": "America\/Chicago"
  },
  "3AL7": {
      "icao": "3AL7",
      "iata": "",
      "name": "Flowers Field",
      "city": "Thomaston",
      "state": "Alabama",
      "country": "US",
      "elevation": 280,
      "lat": 32.2849998474,
      "lon": -87.5199966431,
      "tz": "America\/Chicago"
  },
  "3AL8": {
      "icao": "3AL8",
      "iata": "",
      "name": "Flint River Ranch Airport",
      "city": "Owens Crossroads",
      "state": "Alabama",
      "country": "US",
      "elevation": 590,
      "lat": 34.6192016602,
      "lon": -86.4669036865,
      "tz": "America\/Chicago"
  },
  "3AR0": {
      "icao": "3AR0",
      "iata": "",
      "name": "Frost Flying Inc Airport",
      "city": "Marianna",
      "state": "Arkansas",
      "country": "US",
      "elevation": 240,
      "lat": 34.8306007385,
      "lon": -90.853302002,
      "tz": "America\/Chicago"
  },
  "3AR2": {
      "icao": "3AR2",
      "iata": "",
      "name": "Ridgeway Field",
      "city": "Harrison",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1400,
      "lat": 36.320098877,
      "lon": -93.2015991211,
      "tz": "America\/Chicago"
  },
  "3AR3": {
      "icao": "3AR3",
      "iata": "",
      "name": "Cypress Creek Airport",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 280,
      "lat": 35.0596008301,
      "lon": -92.0820007324,
      "tz": "America\/Chicago"
  },
  "3AR4": {
      "icao": "3AR4",
      "iata": "",
      "name": "Four Mile Creek Ranch Airport",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 500,
      "lat": 35.0056991577,
      "lon": -92.0774002075,
      "tz": "America\/Chicago"
  },
  "3AR5": {
      "icao": "3AR5",
      "iata": "",
      "name": "Tripp Strip",
      "city": "Griffithville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 210,
      "lat": 35.1179008484,
      "lon": -91.6119003296,
      "tz": "America\/Chicago"
  },
  "3AR6": {
      "icao": "3AR6",
      "iata": "",
      "name": "Crystal Ridge Airport",
      "city": "Little Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 500,
      "lat": 34.7285995483,
      "lon": -92.5164031982,
      "tz": "America\/Chicago"
  },
  "3AR7": {
      "icao": "3AR7",
      "iata": "",
      "name": "Taylor Field",
      "city": "Lowell",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1315,
      "lat": 36.2877998352,
      "lon": -94.1866989136,
      "tz": "America\/Chicago"
  },
  "3AR8": {
      "icao": "3AR8",
      "iata": "",
      "name": "River Acres Airport",
      "city": "Heber Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 260,
      "lat": 35.4667015076,
      "lon": -91.966796875,
      "tz": "America\/Chicago"
  },
  "3AR9": {
      "icao": "3AR9",
      "iata": "",
      "name": "Quinn Field",
      "city": "Paragould",
      "state": "Arkansas",
      "country": "US",
      "elevation": 240,
      "lat": 36.0519981384,
      "lon": -90.3583984375,
      "tz": "America\/Chicago"
  },
  "3AZ2": {
      "icao": "3AZ2",
      "iata": "",
      "name": "U of A Maricopa Ag Center Airport",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1181,
      "lat": 33.0802993774,
      "lon": -111.983001709,
      "tz": "America\/Phoenix"
  },
  "3AZ5": {
      "icao": "3AZ5",
      "iata": "",
      "name": "Hualapai Airport",
      "city": "Peach Springs",
      "state": "Arizona",
      "country": "US",
      "elevation": 5317,
      "lat": 35.5722007751,
      "lon": -113.2919998169,
      "tz": "America\/Phoenix"
  },
  "3AZ8": {
      "icao": "3AZ8",
      "iata": "",
      "name": "High Mesa Airpark",
      "city": "Solomon",
      "state": "Arizona",
      "country": "US",
      "elevation": 3080,
      "lat": 32.762298584,
      "lon": -109.6529998779,
      "tz": "America\/Phoenix"
  },
  "3CA3": {
      "icao": "3CA3",
      "iata": "",
      "name": "Dixon Airport",
      "city": "Cuyama",
      "state": "California",
      "country": "US",
      "elevation": 2440,
      "lat": 34.9221992493,
      "lon": -119.5260009766,
      "tz": "America\/Los_Angeles"
  },
  "3CA5": {
      "icao": "3CA5",
      "iata": "",
      "name": "Haws Airport",
      "city": "Madera",
      "state": "California",
      "country": "US",
      "elevation": 192,
      "lat": 36.9416007996,
      "lon": -120.2429962158,
      "tz": "America\/Los_Angeles"
  },
  "3CA7": {
      "icao": "3CA7",
      "iata": "",
      "name": "Metz Airport",
      "city": "Greenfield",
      "state": "California",
      "country": "US",
      "elevation": 240,
      "lat": 36.3283004761,
      "lon": -121.18699646,
      "tz": "America\/Los_Angeles"
  },
  "3CA9": {
      "icao": "3CA9",
      "iata": "",
      "name": "Clark Ranch Airport",
      "city": "Soledad",
      "state": "California",
      "country": "US",
      "elevation": 276,
      "lat": 36.3633003235,
      "lon": -121.3099975586,
      "tz": "America\/Los_Angeles"
  },
  "3CL2": {
      "icao": "3CL2",
      "iata": "",
      "name": "Meadow Airstrip",
      "city": "Red Bluff",
      "state": "California",
      "country": "US",
      "elevation": 345,
      "lat": 40.1563987732,
      "lon": -122.3020019531,
      "tz": "America\/Los_Angeles"
  },
  "3CO0": {
      "icao": "3CO0",
      "iata": "",
      "name": "Sky Island Ranch Airport",
      "city": "Delta",
      "state": "Colorado",
      "country": "US",
      "elevation": 5300,
      "lat": 38.7374992371,
      "lon": -108.0059967041,
      "tz": "America\/Denver"
  },
  "3CO1": {
      "icao": "3CO1",
      "iata": "",
      "name": "Cridler Field",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 6460,
      "lat": 38.8483009338,
      "lon": -107.8330001831,
      "tz": "America\/Denver"
  },
  "3CO2": {
      "icao": "3CO2",
      "iata": "",
      "name": "Mertens Airport",
      "city": "Sterling",
      "state": "Colorado",
      "country": "US",
      "elevation": 4192,
      "lat": 40.6175003052,
      "lon": -103.3330001831,
      "tz": "America\/Denver"
  },
  "3CO4": {
      "icao": "3CO4",
      "iata": "",
      "name": "Tercio Ranch Airstrip",
      "city": "Stonewall",
      "state": "Colorado",
      "country": "US",
      "elevation": 7957,
      "lat": 37.0708999634,
      "lon": -105.0189971924,
      "tz": "America\/Denver"
  },
  "3CO7": {
      "icao": "3CO7",
      "iata": "",
      "name": "Dietrichs Airport",
      "city": "Elizabeth",
      "state": "Colorado",
      "country": "US",
      "elevation": 6780,
      "lat": 39.3325004578,
      "lon": -104.5660018921,
      "tz": "America\/Denver"
  },
  "3FD0": {
      "icao": "3FD0",
      "iata": "",
      "name": "Last Chance Ranch Airport",
      "city": "Lake Placid",
      "state": "Florida",
      "country": "US",
      "elevation": 70,
      "lat": 27.0216999054,
      "lon": -81.4512023926,
      "tz": "America\/New_York"
  },
  "3FD4": {
      "icao": "3FD4",
      "iata": "",
      "name": "Florida Flying Gators Ultralightport",
      "city": "Minneola",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 28.6277999878,
      "lon": -81.8029022217,
      "tz": "America\/New_York"
  },
  "3FD6": {
      "icao": "3FD6",
      "iata": "",
      "name": "Tradewinds Aerodrome",
      "city": "Scottsmoor",
      "state": "Florida",
      "country": "US",
      "elevation": 5,
      "lat": 28.7642002106,
      "lon": -80.8538970947,
      "tz": "America\/New_York"
  },
  "3FL0": {
      "icao": "3FL0",
      "iata": "",
      "name": "Mount Royal Airport",
      "city": "Welaka",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 29.4361000061,
      "lon": -81.6567001343,
      "tz": "America\/New_York"
  },
  "3FL1": {
      "icao": "3FL1",
      "iata": "",
      "name": "Two J's Flying Ranch Airport",
      "city": "Dade City",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 28.4524993896,
      "lon": -82.2078018188,
      "tz": "America\/New_York"
  },
  "3FL5": {
      "icao": "3FL5",
      "iata": "",
      "name": "Mills Ranch South Airport",
      "city": "Kenansville",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 27.7782993317,
      "lon": -80.9218978882,
      "tz": "America\/New_York"
  },
  "3FL8": {
      "icao": "3FL8",
      "iata": "",
      "name": "Hart Airport",
      "city": "Malone",
      "state": "Florida",
      "country": "US",
      "elevation": 125,
      "lat": 30.9463005066,
      "lon": -85.2738037109,
      "tz": "America\/Chicago"
  },
  "3GA0": {
      "icao": "3GA0",
      "iata": "",
      "name": "Grant Airport",
      "city": "Stockbridge",
      "state": "Georgia",
      "country": "US",
      "elevation": 790,
      "lat": 33.5275001526,
      "lon": -84.1568984985,
      "tz": "America\/New_York"
  },
  "3GA1": {
      "icao": "3GA1",
      "iata": "",
      "name": "Prattsburg Airport",
      "city": "Talbotton",
      "state": "Georgia",
      "country": "US",
      "elevation": 554,
      "lat": 32.7251014709,
      "lon": -84.3582992554,
      "tz": "America\/New_York"
  },
  "3GA3": {
      "icao": "3GA3",
      "iata": "",
      "name": "Warren Field",
      "city": "Lookout Mountain",
      "state": "Georgia",
      "country": "US",
      "elevation": 1866,
      "lat": 34.871111,
      "lon": -85.4375,
      "tz": "America\/New_York"
  },
  "3GA5": {
      "icao": "3GA5",
      "iata": "",
      "name": "Diamond R Ranch Airport",
      "city": "Villa Rica",
      "state": "Georgia",
      "country": "US",
      "elevation": 1213,
      "lat": 33.6371002197,
      "lon": -84.9387969971,
      "tz": "America\/New_York"
  },
  "3GA6": {
      "icao": "3GA6",
      "iata": "",
      "name": "Erlen Airport",
      "city": "Whitesburg",
      "state": "Georgia",
      "country": "US",
      "elevation": 1044,
      "lat": 33.5705986023,
      "lon": -84.8983001709,
      "tz": "America\/New_York"
  },
  "3GA7": {
      "icao": "3GA7",
      "iata": "",
      "name": "Rhodes Air Ranch Airport",
      "city": "Waynesboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 195,
      "lat": 33.1624984741,
      "lon": -81.7683029175,
      "tz": "America\/New_York"
  },
  "3GA8": {
      "icao": "3GA8",
      "iata": "",
      "name": "Cauley's Airstrip",
      "city": "Soperton",
      "state": "Georgia",
      "country": "US",
      "elevation": 251,
      "lat": 32.3293991089,
      "lon": -82.6350021362,
      "tz": "America\/New_York"
  },
  "3GA9": {
      "icao": "3GA9",
      "iata": "",
      "name": "Vintage Field",
      "city": "Zebulon",
      "state": "Georgia",
      "country": "US",
      "elevation": 873,
      "lat": 33.1334991455,
      "lon": -84.3774032593,
      "tz": "America\/New_York"
  },
  "3GE3": {
      "icao": "3GE3",
      "iata": "",
      "name": "Broad River Air Park",
      "city": "Carnesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 690,
      "lat": 34.4118995667,
      "lon": -83.1841964722,
      "tz": "America\/New_York"
  },
  "3GE4": {
      "icao": "3GE4",
      "iata": "",
      "name": "Fox Mountain Airport",
      "city": "Rising Fawn",
      "state": "Alabama",
      "country": "US",
      "elevation": 925,
      "lat": 34.7299995422,
      "lon": -85.5419006348,
      "tz": "America\/Chicago"
  },
  "3GE7": {
      "icao": "3GE7",
      "iata": "",
      "name": "Eden Field",
      "city": "Darien",
      "state": "Georgia",
      "country": "US",
      "elevation": 30,
      "lat": 31.4127006531,
      "lon": -81.4373016357,
      "tz": "America\/New_York"
  },
  "3GE8": {
      "icao": "3GE8",
      "iata": "",
      "name": "Prater Ranch Airport",
      "city": "Lincolnton",
      "state": "Georgia",
      "country": "US",
      "elevation": 460,
      "lat": 33.84349823,
      "lon": -82.4789962769,
      "tz": "America\/New_York"
  },
  "3GE9": {
      "icao": "3GE9",
      "iata": "",
      "name": "Holly Farm Airport",
      "city": "Woodstock",
      "state": "Georgia",
      "country": "US",
      "elevation": 900,
      "lat": 34.1399993896,
      "lon": -84.3780975342,
      "tz": "America\/New_York"
  },
  "3IA1": {
      "icao": "3IA1",
      "iata": "",
      "name": "Whites Airport",
      "city": "Melbourne",
      "state": "Iowa",
      "country": "US",
      "elevation": 1050,
      "lat": 41.9608001709,
      "lon": -93.1199035645,
      "tz": "America\/Chicago"
  },
  "3IA4": {
      "icao": "3IA4",
      "iata": "",
      "name": "Robinson Airport",
      "city": "Missouri Valley",
      "state": "Iowa",
      "country": "US",
      "elevation": 1000,
      "lat": 41.5318984985,
      "lon": -95.9420013428,
      "tz": "America\/Chicago"
  },
  "3IA5": {
      "icao": "3IA5",
      "iata": "",
      "name": "Kerr Airport",
      "city": "Marcus",
      "state": "Iowa",
      "country": "US",
      "elevation": 1450,
      "lat": 42.8772010803,
      "lon": -95.8824996948,
      "tz": "America\/Chicago"
  },
  "3IA9": {
      "icao": "3IA9",
      "iata": "",
      "name": "Rake Airport",
      "city": "Rake",
      "state": "Iowa",
      "country": "US",
      "elevation": 1180,
      "lat": 43.4757995605,
      "lon": -93.9072036743,
      "tz": "America\/Chicago"
  },
  "3ID2": {
      "icao": "3ID2",
      "iata": "",
      "name": "The Last Resort Airport",
      "city": "Weippe",
      "state": "Idaho",
      "country": "US",
      "elevation": 3155,
      "lat": 46.350675,
      "lon": -115.977,
      "tz": "America\/Los_Angeles"
  },
  "3ID7": {
      "icao": "3ID7",
      "iata": "",
      "name": "Indian Creek Ranch Airport",
      "city": "Kuna",
      "state": "Idaho",
      "country": "US",
      "elevation": 2674,
      "lat": 43.4760017395,
      "lon": -116.4039993286,
      "tz": "America\/Boise"
  },
  "3IG3": {
      "icao": "3IG3",
      "iata": "",
      "name": "Terry's Airport",
      "city": "Columbia City",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 41.1702003479,
      "lon": -85.4216003418,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3II0": {
      "icao": "3II0",
      "iata": "",
      "name": "Fremont (Murphy) Airport",
      "city": "Fremont",
      "state": "Indiana",
      "country": "US",
      "elevation": 1050,
      "lat": 41.6910848513,
      "lon": -84.864256382,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3II1": {
      "icao": "3II1",
      "iata": "",
      "name": "Shenandoah Flying Field",
      "city": "Gosport",
      "state": "Indiana",
      "country": "US",
      "elevation": 720,
      "lat": 39.4166984558,
      "lon": -86.6358032227,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3II2": {
      "icao": "3II2",
      "iata": "",
      "name": "Fifer Field",
      "city": "Griffin",
      "state": "Indiana",
      "country": "US",
      "elevation": 445,
      "lat": 38.2127990723,
      "lon": -87.9195022583,
      "tz": "America\/Chicago"
  },
  "3II3": {
      "icao": "3II3",
      "iata": "",
      "name": "Basting Airport",
      "city": "Woodburn",
      "state": "Indiana",
      "country": "US",
      "elevation": 755,
      "lat": 41.0870018005,
      "lon": -84.8411026001,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3II5": {
      "icao": "3II5",
      "iata": "",
      "name": "Stout Field",
      "city": "Medaryville",
      "state": "Indiana",
      "country": "US",
      "elevation": 700,
      "lat": 41.0839004517,
      "lon": -86.7733001709,
      "tz": "America\/Indiana\/Winamac"
  },
  "3II8": {
      "icao": "3II8",
      "iata": "",
      "name": "Birkey Private Airport",
      "city": "Bremen",
      "state": "Indiana",
      "country": "US",
      "elevation": 796,
      "lat": 41.4422988892,
      "lon": -86.261100769,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3II9": {
      "icao": "3II9",
      "iata": "",
      "name": "Dick's Strip",
      "city": "Arcola",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 41.0973014832,
      "lon": -85.2422027588,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IL1": {
      "icao": "3IL1",
      "iata": "",
      "name": "Silver Creek Gliderport",
      "city": "New Douglas",
      "state": "Illinois",
      "country": "US",
      "elevation": 595,
      "lat": 38.9253005981,
      "lon": -89.6622009277,
      "tz": "America\/Chicago"
  },
  "3IL2": {
      "icao": "3IL2",
      "iata": "",
      "name": "Sweedler Airport",
      "city": "Elwood",
      "state": "Illinois",
      "country": "US",
      "elevation": 669,
      "lat": 41.4123001099,
      "lon": -88.0486984253,
      "tz": "America\/Chicago"
  },
  "3IL7": {
      "icao": "3IL7",
      "iata": "",
      "name": "Home Free Airport",
      "city": "Steward",
      "state": "Illinois",
      "country": "US",
      "elevation": 819,
      "lat": 41.8033981323,
      "lon": -89.0217971802,
      "tz": "America\/Chicago"
  },
  "3IL9": {
      "icao": "3IL9",
      "iata": "",
      "name": "Sugar Creek Farm Airport",
      "city": "Atlanta",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 40.2694015503,
      "lon": -89.3022003174,
      "tz": "America\/Chicago"
  },
  "3IN2": {
      "icao": "3IN2",
      "iata": "",
      "name": "Dupouy Airport",
      "city": "Point Isabel",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 40.4192008972,
      "lon": -85.8561019897,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IN3": {
      "icao": "3IN3",
      "iata": "",
      "name": "Carlson Farm Airport",
      "city": "Portage",
      "state": "Indiana",
      "country": "US",
      "elevation": 690,
      "lat": 41.5231018066,
      "lon": -87.1639022827,
      "tz": "America\/Chicago"
  },
  "3IN4": {
      "icao": "3IN4",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Markle",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 40.7750015259,
      "lon": -85.3388977051,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IN5": {
      "icao": "3IN5",
      "iata": "",
      "name": "Pippenger Airport",
      "city": "Albion",
      "state": "Indiana",
      "country": "US",
      "elevation": 980,
      "lat": 41.3168983459,
      "lon": -85.3713989258,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IN6": {
      "icao": "3IN6",
      "iata": "",
      "name": "Holloway Field",
      "city": "New Washington",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 38.588333,
      "lon": -85.505833,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IN7": {
      "icao": "3IN7",
      "iata": "",
      "name": "Chain-O-Lakes Airport",
      "city": "South Bend",
      "state": "Indiana",
      "country": "US",
      "elevation": 743,
      "lat": 41.6624984741,
      "lon": -86.3542022705,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IN8": {
      "icao": "3IN8",
      "iata": "",
      "name": "Ddt Field",
      "city": "Culver",
      "state": "Indiana",
      "country": "US",
      "elevation": 823,
      "lat": 41.20470047,
      "lon": -86.3458023071,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IN9": {
      "icao": "3IN9",
      "iata": "",
      "name": "Thomas Airport",
      "city": "Cicero",
      "state": "Indiana",
      "country": "US",
      "elevation": 890,
      "lat": 40.1514015198,
      "lon": -86.1192016602,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3IS1": {
      "icao": "3IS1",
      "iata": "",
      "name": "Mc Coy Airport",
      "city": "Blue Mound",
      "state": "Illinois",
      "country": "US",
      "elevation": 615,
      "lat": 39.7000007629,
      "lon": -89.2089996338,
      "tz": "America\/Chicago"
  },
  "3IS2": {
      "icao": "3IS2",
      "iata": "",
      "name": "Earp Airport",
      "city": "Roseville",
      "state": "Illinois",
      "country": "US",
      "elevation": 740,
      "lat": 40.7406005859,
      "lon": -90.6696014404,
      "tz": "America\/Chicago"
  },
  "3IS3": {
      "icao": "3IS3",
      "iata": "",
      "name": "Noland RLA Restricted Landing Area",
      "city": "Blue Mound",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 39.7333984375,
      "lon": -89.150100708,
      "tz": "America\/Chicago"
  },
  "3IS4": {
      "icao": "3IS4",
      "iata": "",
      "name": "Merkle Airport",
      "city": "Hanover",
      "state": "Illinois",
      "country": "US",
      "elevation": 970,
      "lat": 42.288898468,
      "lon": -90.3453979492,
      "tz": "America\/Chicago"
  },
  "3IS5": {
      "icao": "3IS5",
      "iata": "",
      "name": "Holmes Airport",
      "city": "Pawnee",
      "state": "Illinois",
      "country": "US",
      "elevation": 590,
      "lat": 39.6845016479,
      "lon": -89.5987014771,
      "tz": "America\/Chicago"
  },
  "3IS6": {
      "icao": "3IS6",
      "iata": "",
      "name": "Davy Jones \/Private\/ Airport",
      "city": "Bonnie",
      "state": "Illinois",
      "country": "US",
      "elevation": 500,
      "lat": 38.2228012085,
      "lon": -88.8827972412,
      "tz": "America\/Chicago"
  },
  "3IS7": {
      "icao": "3IS7",
      "iata": "",
      "name": "Foote Airport",
      "city": "Wenona",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 41.0867004395,
      "lon": -89.0959014893,
      "tz": "America\/Chicago"
  },
  "3IS8": {
      "icao": "3IS8",
      "iata": "BDF",
      "name": "Rinkenberger Restricted Landing Area",
      "city": "Bradford",
      "state": "Illinois",
      "country": "US",
      "elevation": 808,
      "lat": 41.2308998108,
      "lon": -89.6156997681,
      "tz": "America\/Chicago"
  },
  "3KS1": {
      "icao": "3KS1",
      "iata": "",
      "name": "Mesa Verde Airport",
      "city": "Topeka",
      "state": "Kansas",
      "country": "US",
      "elevation": 1080,
      "lat": 39.1556015015,
      "lon": -95.7433013916,
      "tz": "America\/Chicago"
  },
  "3KS3": {
      "icao": "3KS3",
      "iata": "",
      "name": "Rogers Airport",
      "city": "Udall",
      "state": "Kansas",
      "country": "US",
      "elevation": 1210,
      "lat": 37.4000015259,
      "lon": -97.1920013428,
      "tz": "America\/Chicago"
  },
  "3KS4": {
      "icao": "3KS4",
      "iata": "",
      "name": "Eveleigh Farms Airport",
      "city": "Ulysses",
      "state": "Kansas",
      "country": "US",
      "elevation": 3207,
      "lat": 37.7677993774,
      "lon": -101.4349975586,
      "tz": "America\/Chicago"
  },
  "3KS5": {
      "icao": "3KS5",
      "iata": "",
      "name": "High Point Airport",
      "city": "Valley Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1395,
      "lat": 37.8427155871,
      "lon": -97.3514413834,
      "tz": "America\/Chicago"
  },
  "3KS7": {
      "icao": "3KS7",
      "iata": "",
      "name": "Berwick Airport",
      "city": "Valley Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1400,
      "lat": 37.8292007446,
      "lon": -97.3488998413,
      "tz": "America\/Chicago"
  },
  "3KS8": {
      "icao": "3KS8",
      "iata": "",
      "name": "Reed-Wilsonton Airport",
      "city": "Altamont",
      "state": "Kansas",
      "country": "US",
      "elevation": 920,
      "lat": 37.2565002441,
      "lon": -95.3336029053,
      "tz": "America\/Chicago"
  },
  "3KY0": {
      "icao": "3KY0",
      "iata": "",
      "name": "Lamar Field",
      "city": "Hawesville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 410,
      "lat": 37.8967018127,
      "lon": -86.7893981934,
      "tz": "America\/Chicago"
  },
  "3KY1": {
      "icao": "3KY1",
      "iata": "",
      "name": "Goode Airpark",
      "city": "Utica",
      "state": "Kentucky",
      "country": "US",
      "elevation": 395,
      "lat": 37.6413993835,
      "lon": -87.1316986084,
      "tz": "America\/Chicago"
  },
  "3KY2": {
      "icao": "3KY2",
      "iata": "",
      "name": "Chesnut Knolls Airport",
      "city": "London",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1265,
      "lat": 37.1511001587,
      "lon": -84.2574996948,
      "tz": "America\/New_York"
  },
  "3KY3": {
      "icao": "3KY3",
      "iata": "",
      "name": "Mason Valley Airport",
      "city": "Taylorsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 510,
      "lat": 38.0382995605,
      "lon": -85.3593978882,
      "tz": "America\/New_York"
  },
  "3KY4": {
      "icao": "3KY4",
      "iata": "",
      "name": "Craw Daddy Landing Airport",
      "city": "Carrollton",
      "state": "Kentucky",
      "country": "US",
      "elevation": 460,
      "lat": 38.6727981567,
      "lon": -85.182800293,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "3KY6": {
      "icao": "3KY6",
      "iata": "",
      "name": "Russell Airport",
      "city": "Battletown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 430,
      "lat": 38.0306015015,
      "lon": -86.2316970825,
      "tz": "America\/Kentucky\/Louisville"
  },
  "3KY7": {
      "icao": "3KY7",
      "iata": "",
      "name": "Eagle's Nest Airport",
      "city": "Battletown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 450,
      "lat": 38.1032981873,
      "lon": -86.4569015503,
      "tz": "America\/Indiana\/Marengo"
  },
  "3KY9": {
      "icao": "3KY9",
      "iata": "",
      "name": "Miles Field",
      "city": "Shelbyville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 850,
      "lat": 38.125,
      "lon": -85.125,
      "tz": "America\/New_York"
  },
  "3LA0": {
      "icao": "3LA0",
      "iata": "",
      "name": "Harrington Flying Service Airport",
      "city": "Kaplan",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.9197006226,
      "lon": -92.2473983765,
      "tz": "America\/Chicago"
  },
  "3LA1": {
      "icao": "3LA1",
      "iata": "",
      "name": "Wilder Airport",
      "city": "Kinder",
      "state": "Louisiana",
      "country": "US",
      "elevation": 55,
      "lat": 30.5585002899,
      "lon": -92.8042984009,
      "tz": "America\/Chicago"
  },
  "3LA3": {
      "icao": "3LA3",
      "iata": "",
      "name": "La Coste Construction County Airport",
      "city": "Baton Rouge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 34,
      "lat": 30.5023994446,
      "lon": -91.2428970337,
      "tz": "America\/Chicago"
  },
  "3LA4": {
      "icao": "3LA4",
      "iata": "",
      "name": "Little Pecan Island Airport",
      "city": "Lake Arthur",
      "state": "Louisiana",
      "country": "US",
      "elevation": 5,
      "lat": 29.8001995087,
      "lon": -92.8001022339,
      "tz": "America\/Chicago"
  },
  "3LA6": {
      "icao": "3LA6",
      "iata": "",
      "name": "Morgan Crop Service Nr 2 Airport",
      "city": "Lake Charles",
      "state": "Louisiana",
      "country": "US",
      "elevation": 12,
      "lat": 30.1376991272,
      "lon": -93.0792999268,
      "tz": "America\/Chicago"
  },
  "3LA8": {
      "icao": "3LA8",
      "iata": "",
      "name": "Open A-1 Ranch Airport",
      "city": "Lake Charles",
      "state": "Louisiana",
      "country": "US",
      "elevation": 16,
      "lat": 30.1385002136,
      "lon": -93.227897644,
      "tz": "America\/Chicago"
  },
  "3LA9": {
      "icao": "3LA9",
      "iata": "",
      "name": "Morgan Crop Service Airport",
      "city": "Lake Charles",
      "state": "Louisiana",
      "country": "US",
      "elevation": 12,
      "lat": 30.2294006348,
      "lon": -93.0885009766,
      "tz": "America\/Chicago"
  },
  "3LL0": {
      "icao": "3LL0",
      "iata": "",
      "name": "Miller Airport",
      "city": "South Beloit",
      "state": "Illinois",
      "country": "US",
      "elevation": 760,
      "lat": 42.4916992188,
      "lon": -89.0959014893,
      "tz": "America\/Chicago"
  },
  "3LL1": {
      "icao": "3LL1",
      "iata": "",
      "name": "Herschel Hunter Airport",
      "city": "Marissa",
      "state": "Illinois",
      "country": "US",
      "elevation": 470,
      "lat": 38.2223014832,
      "lon": -89.7042999268,
      "tz": "America\/Chicago"
  },
  "3LL3": {
      "icao": "3LL3",
      "iata": "",
      "name": "Kibler Airport",
      "city": "Marshall",
      "state": "Illinois",
      "country": "US",
      "elevation": 588,
      "lat": 39.4460983276,
      "lon": -87.6445007324,
      "tz": "America\/Chicago"
  },
  "3LL4": {
      "icao": "3LL4",
      "iata": "",
      "name": "Pillow Hill Airport",
      "city": "Spring Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 815,
      "lat": 42.4472007751,
      "lon": -88.201499939,
      "tz": "America\/Chicago"
  },
  "3LL5": {
      "icao": "3LL5",
      "iata": "",
      "name": "Richardson Field",
      "city": "Spring Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 860,
      "lat": 42.4645004272,
      "lon": -88.2342987061,
      "tz": "America\/Chicago"
  },
  "3LL6": {
      "icao": "3LL6",
      "iata": "",
      "name": "Bickel Airport",
      "city": "New Athens",
      "state": "Illinois",
      "country": "US",
      "elevation": 530,
      "lat": 38.335,
      "lon": -89.769722,
      "tz": "America\/Chicago"
  },
  "3LL8": {
      "icao": "3LL8",
      "iata": "",
      "name": "Mc Leansboro Airport",
      "city": "Mc Leansboro",
      "state": "Illinois",
      "country": "US",
      "elevation": 415,
      "lat": 38.0741996765,
      "lon": -88.5375976562,
      "tz": "America\/Chicago"
  },
  "3LL9": {
      "icao": "3LL9",
      "iata": "",
      "name": "Compton Airport",
      "city": "Pocahontas",
      "state": "Illinois",
      "country": "US",
      "elevation": 535,
      "lat": 38.8041992188,
      "lon": -89.6089019775,
      "tz": "America\/Chicago"
  },
  "3LS7": {
      "icao": "3LS7",
      "iata": "",
      "name": "Bock Farms Airport",
      "city": "Williamsville",
      "state": "Illinois",
      "country": "US",
      "elevation": 592,
      "lat": 39.9864006042,
      "lon": -89.5117034912,
      "tz": "America\/Chicago"
  },
  "3MA2": {
      "icao": "3MA2",
      "iata": "",
      "name": "Baines Airport",
      "city": "Middlefield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 1160,
      "lat": 42.3162002563,
      "lon": -73.0319976807,
      "tz": "America\/New_York"
  },
  "3MA5": {
      "icao": "3MA5",
      "iata": "",
      "name": "Westport Airport",
      "city": "Westport",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 80,
      "lat": 41.5432014465,
      "lon": -71.0353012085,
      "tz": "America\/New_York"
  },
  "3MD0": {
      "icao": "3MD0",
      "iata": "",
      "name": "Burhans Memorial Airport",
      "city": "Frederick",
      "state": "Maryland",
      "country": "US",
      "elevation": 490,
      "lat": 39.3482017517,
      "lon": -77.3360977173,
      "tz": "America\/New_York"
  },
  "3MD4": {
      "icao": "3MD4",
      "iata": "",
      "name": "Fairview Airport",
      "city": "Annapolis",
      "state": "Maryland",
      "country": "US",
      "elevation": 150,
      "lat": 38.9776000977,
      "lon": -76.6393966675,
      "tz": "America\/New_York"
  },
  "3MD5": {
      "icao": "3MD5",
      "iata": "",
      "name": "Hidden Hills Airport",
      "city": "Hurlock",
      "state": "Maryland",
      "country": "US",
      "elevation": 20,
      "lat": 38.6212005615,
      "lon": -75.8413009644,
      "tz": "America\/New_York"
  },
  "3MD6": {
      "icao": "3MD6",
      "iata": "",
      "name": "West St Mary's Airport",
      "city": "Drayden",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 38.188999176,
      "lon": -76.4468994141,
      "tz": "America\/New_York"
  },
  "3MD8": {
      "icao": "3MD8",
      "iata": "",
      "name": "Pokety Airport",
      "city": "Cambridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 10,
      "lat": 38.6123008728,
      "lon": -76.1710968018,
      "tz": "America\/New_York"
  },
  "3MD9": {
      "icao": "3MD9",
      "iata": "",
      "name": "Chandler Airport",
      "city": "Ridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 17,
      "lat": 38.1181983948,
      "lon": -76.3912963867,
      "tz": "America\/New_York"
  },
  "3MI0": {
      "icao": "3MI0",
      "iata": "",
      "name": "Doss Field",
      "city": "Lake George",
      "state": "Michigan",
      "country": "US",
      "elevation": 1050,
      "lat": 43.9370002747,
      "lon": -85.0220031738,
      "tz": "America\/Detroit"
  },
  "3MI2": {
      "icao": "3MI2",
      "iata": "",
      "name": "South Fox Island Airport",
      "city": "Northport",
      "state": "Michigan",
      "country": "US",
      "elevation": 616,
      "lat": 45.3992004395,
      "lon": -85.8301010132,
      "tz": "America\/Detroit"
  },
  "3MI5": {
      "icao": "3MI5",
      "iata": "",
      "name": "Innes Acres Airport",
      "city": "Decker",
      "state": "Michigan",
      "country": "US",
      "elevation": 775,
      "lat": 43.4500007629,
      "lon": -83.0277023315,
      "tz": "America\/Detroit"
  },
  "3MI7": {
      "icao": "3MI7",
      "iata": "",
      "name": "Willie Run Airport",
      "city": "Ortonville",
      "state": "Michigan",
      "country": "US",
      "elevation": 1043,
      "lat": 42.8292007446,
      "lon": -83.368598938,
      "tz": "America\/Detroit"
  },
  "3MN1": {
      "icao": "3MN1",
      "iata": "",
      "name": "Stahlberg-Mohr Airport",
      "city": "Iron Junction",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1380,
      "lat": 47.4485015869,
      "lon": -92.6448974609,
      "tz": "America\/Chicago"
  },
  "3MN3": {
      "icao": "3MN3",
      "iata": "",
      "name": "Honker Flats Airport",
      "city": "Middle River",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1190,
      "lat": 48.538898468,
      "lon": -95.952003479,
      "tz": "America\/Chicago"
  },
  "3MN5": {
      "icao": "3MN5",
      "iata": "",
      "name": "Little Rock\/Rock Port Airport",
      "city": "Rice",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1055,
      "lat": 45.7229995728,
      "lon": -94.193901062,
      "tz": "America\/Chicago"
  },
  "3MN7": {
      "icao": "3MN7",
      "iata": "",
      "name": "Blue Sky Airport",
      "city": "Sabin",
      "state": "Minnesota",
      "country": "US",
      "elevation": 927,
      "lat": 46.8004989624,
      "lon": -96.5950012207,
      "tz": "America\/Chicago"
  },
  "3MN8": {
      "icao": "3MN8",
      "iata": "",
      "name": "Aysta Field",
      "city": "St Cloud",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1024,
      "lat": 45.5227012634,
      "lon": -94.1228027344,
      "tz": "America\/Chicago"
  },
  "3MN9": {
      "icao": "3MN9",
      "iata": "",
      "name": "Schumacher Airport",
      "city": "Oster",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1020,
      "lat": 44.9844017029,
      "lon": -93.9360961914,
      "tz": "America\/Chicago"
  },
  "3MO2": {
      "icao": "3MO2",
      "iata": "",
      "name": "Ultra Flight Airpark",
      "city": "Berger",
      "state": "Missouri",
      "country": "US",
      "elevation": 502,
      "lat": 38.6908988953,
      "lon": -91.341003418,
      "tz": "America\/Chicago"
  },
  "3MO4": {
      "icao": "3MO4",
      "iata": "",
      "name": "Penman Airport",
      "city": "Portageville",
      "state": "Missouri",
      "country": "US",
      "elevation": 287,
      "lat": 36.4505996704,
      "lon": -89.6280975342,
      "tz": "America\/Chicago"
  },
  "3MO5": {
      "icao": "3MO5",
      "iata": "",
      "name": "Nimsick Airport",
      "city": "Carthage",
      "state": "Missouri",
      "country": "US",
      "elevation": 1095,
      "lat": 37.1281013489,
      "lon": -94.2161026001,
      "tz": "America\/Chicago"
  },
  "3MO6": {
      "icao": "3MO6",
      "iata": "",
      "name": "Kitty Hawk Estates Airport",
      "city": "Kearney",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.3356018066,
      "lon": -94.4835968018,
      "tz": "America\/Chicago"
  },
  "3MO7": {
      "icao": "3MO7",
      "iata": "",
      "name": "Fairbanks Airport",
      "city": "King City",
      "state": "Missouri",
      "country": "US",
      "elevation": 1083,
      "lat": 40.0833015442,
      "lon": -94.616897583,
      "tz": "America\/Chicago"
  },
  "3MO8": {
      "icao": "3MO8",
      "iata": "",
      "name": "Fizzle Ridge Airport",
      "city": "King City",
      "state": "Missouri",
      "country": "US",
      "elevation": 1096,
      "lat": 40.0318984985,
      "lon": -94.5376968384,
      "tz": "America\/Chicago"
  },
  "3MS1": {
      "icao": "3MS1",
      "iata": "",
      "name": "Thomas Field",
      "city": "Holly Springs",
      "state": "Mississippi",
      "country": "US",
      "elevation": 485,
      "lat": 34.8058013916,
      "lon": -89.460899353,
      "tz": "America\/Chicago"
  },
  "3MS2": {
      "icao": "3MS2",
      "iata": "",
      "name": "Thunderfoot Ranch Airport",
      "city": "Tylertown",
      "state": "Mississippi",
      "country": "US",
      "elevation": 390,
      "lat": 31.0790996552,
      "lon": -90.0283966064,
      "tz": "America\/Chicago"
  },
  "3MS3": {
      "icao": "3MS3",
      "iata": "",
      "name": "Root Hog Airport",
      "city": "Flora",
      "state": "Mississippi",
      "country": "US",
      "elevation": 220,
      "lat": 32.5158996582,
      "lon": -90.3511962891,
      "tz": "America\/Chicago"
  },
  "3MS5": {
      "icao": "3MS5",
      "iata": "",
      "name": "Mitchell's Airport",
      "city": "Vaiden",
      "state": "Mississippi",
      "country": "US",
      "elevation": 323,
      "lat": 33.3017997742,
      "lon": -89.8143005371,
      "tz": "America\/Chicago"
  },
  "3MS6": {
      "icao": "3MS6",
      "iata": "",
      "name": "E E Lane Airport",
      "city": "Flora",
      "state": "Mississippi",
      "country": "US",
      "elevation": 268,
      "lat": 32.5542984009,
      "lon": -90.3029022217,
      "tz": "America\/Chicago"
  },
  "3MS8": {
      "icao": "3MS8",
      "iata": "",
      "name": "Fairview Farms Airport",
      "city": "Scooba",
      "state": "Mississippi",
      "country": "US",
      "elevation": 170,
      "lat": 32.942199707,
      "lon": -88.3635025024,
      "tz": "America\/Chicago"
  },
  "3MS9": {
      "icao": "3MS9",
      "iata": "",
      "name": "Union Municipal Airport",
      "city": "Union",
      "state": "Mississippi",
      "country": "US",
      "elevation": 512,
      "lat": 32.5806999207,
      "lon": -89.1361999512,
      "tz": "America\/Chicago"
  },
  "3MT3": {
      "icao": "3MT3",
      "iata": "",
      "name": "Three Cross Ranch Airport",
      "city": "Ryegate",
      "state": "Montana",
      "country": "US",
      "elevation": 3930,
      "lat": 46.125556,
      "lon": -109.365,
      "tz": "America\/Denver"
  },
  "3NA0": {
      "icao": "3NA0",
      "iata": "",
      "name": "Erickson Airport",
      "city": "Clifford",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1095,
      "lat": 47.4166984558,
      "lon": -97.5114974976,
      "tz": "America\/Chicago"
  },
  "3NA2": {
      "icao": "3NA2",
      "iata": "",
      "name": "Lorentzen Airport",
      "city": "Washburn",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1790,
      "lat": 47.3027992249,
      "lon": -101.0670013428,
      "tz": "America\/Chicago"
  },
  "3NA6": {
      "icao": "3NA6",
      "iata": "",
      "name": "Risovi Ranch Strip",
      "city": "Hamberg",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1560,
      "lat": 47.7705993652,
      "lon": -99.4617996216,
      "tz": "America\/Chicago"
  },
  "3NC0": {
      "icao": "3NC0",
      "iata": "",
      "name": "Clyde Valley Airport",
      "city": "Morganton",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1100,
      "lat": 35.6903991699,
      "lon": -81.7123031616,
      "tz": "America\/New_York"
  },
  "3NC1": {
      "icao": "3NC1",
      "iata": "",
      "name": "Welborn Farm Airport",
      "city": "Boonville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1030,
      "lat": 36.2053985596,
      "lon": -80.6584014893,
      "tz": "America\/New_York"
  },
  "3NC3": {
      "icao": "3NC3",
      "iata": "",
      "name": "Tucker Field",
      "city": "Locust",
      "state": "North Carolina",
      "country": "US",
      "elevation": 645,
      "lat": 35.2468986511,
      "lon": -80.4075012207,
      "tz": "America\/New_York"
  },
  "3NC4": {
      "icao": "3NC4",
      "iata": "",
      "name": "Mc Donald Field",
      "city": "Marshville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 545,
      "lat": 35.05350113,
      "lon": -80.40899658,
      "tz": "America\/New_York"
  },
  "3NC5": {
      "icao": "3NC5",
      "iata": "",
      "name": "Flying Bj Airport",
      "city": "Stony Point",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1000,
      "lat": 35.8121986389,
      "lon": -81.0699996948,
      "tz": "America\/New_York"
  },
  "3NC6": {
      "icao": "3NC6",
      "iata": "",
      "name": "Mc Cachren Field",
      "city": "Harrisburg",
      "state": "North Carolina",
      "country": "US",
      "elevation": 565,
      "lat": 35.3117980957,
      "lon": -80.644203186,
      "tz": "America\/New_York"
  },
  "3NC7": {
      "icao": "3NC7",
      "iata": "",
      "name": "Maxwell Airport",
      "city": "Sparta",
      "state": "North Carolina",
      "country": "US",
      "elevation": 3050,
      "lat": 36.5192985535,
      "lon": -81.1367034912,
      "tz": "America\/New_York"
  },
  "3NC9": {
      "icao": "3NC9",
      "iata": "",
      "name": "Womble Field",
      "city": "Chapel Hill",
      "state": "North Carolina",
      "country": "US",
      "elevation": 475,
      "lat": 35.8750991821,
      "lon": -79.0871963501,
      "tz": "America\/New_York"
  },
  "3ND5": {
      "icao": "3ND5",
      "iata": "",
      "name": "Gage Flying Farmer Airport",
      "city": "Oriska",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1235,
      "lat": 47.0611000061,
      "lon": -97.7712020874,
      "tz": "America\/Chicago"
  },
  "3NE2": {
      "icao": "3NE2",
      "iata": "",
      "name": "Phillips Private Airport",
      "city": "Hemingford",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4370,
      "lat": 42.3277015686,
      "lon": -103.1940002441,
      "tz": "America\/Denver"
  },
  "3NE3": {
      "icao": "3NE3",
      "iata": "",
      "name": "Wells Airport",
      "city": "Holdrege",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2275,
      "lat": 40.5,
      "lon": -99.3170013428,
      "tz": "America\/Chicago"
  },
  "3NE6": {
      "icao": "3NE6",
      "iata": "",
      "name": "Burkinshaw Field",
      "city": "Jamison",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2200,
      "lat": 42.9667015076,
      "lon": -99.266998291,
      "tz": "America\/Chicago"
  },
  "3NE7": {
      "icao": "3NE7",
      "iata": "",
      "name": "Pawlet Ranch Airport",
      "city": "Lakeside",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3850,
      "lat": 41.8291015625,
      "lon": -102.3430023193,
      "tz": "America\/Denver"
  },
  "3NE9": {
      "icao": "3NE9",
      "iata": "",
      "name": "Phelps Airport",
      "city": "Lorenzo",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4400,
      "lat": 41.0410995483,
      "lon": -103.0680007935,
      "tz": "America\/Denver"
  },
  "3NJ1": {
      "icao": "3NJ1",
      "iata": "",
      "name": "Pemberton Airport",
      "city": "Pemberton",
      "state": "New Jersey",
      "country": "US",
      "elevation": 65,
      "lat": 39.9821014404,
      "lon": -74.6927032471,
      "tz": "America\/New_York"
  },
  "3NJ5": {
      "icao": "3NJ5",
      "iata": "",
      "name": "Mock Airport",
      "city": "Quakertown",
      "state": "New Jersey",
      "country": "US",
      "elevation": 560,
      "lat": 40.5334014893,
      "lon": -74.9162979126,
      "tz": "America\/New_York"
  },
  "3NJ6": {
      "icao": "3NJ6",
      "iata": "",
      "name": "Inductotherm Airport",
      "city": "Rancocas",
      "state": "New Jersey",
      "country": "US",
      "elevation": 65,
      "lat": 40.0150985718,
      "lon": -74.8429031372,
      "tz": "America\/New_York"
  },
  "3NJ9": {
      "icao": "3NJ9",
      "iata": "",
      "name": "Allen Airstrip",
      "city": "Vincentown",
      "state": "New Jersey",
      "country": "US",
      "elevation": 51,
      "lat": 39.9411125183,
      "lon": -74.7708358765,
      "tz": "America\/New_York"
  },
  "3NK0": {
      "icao": "3NK0",
      "iata": "",
      "name": "Mc Kinney Airport",
      "city": "Brockport",
      "state": "New York",
      "country": "US",
      "elevation": 547,
      "lat": 43.2030982971,
      "lon": -77.9024963379,
      "tz": "America\/New_York"
  },
  "3NK4": {
      "icao": "3NK4",
      "iata": "",
      "name": "Laska Airport",
      "city": "Jamestown",
      "state": "New York",
      "country": "US",
      "elevation": 1480,
      "lat": 42.0278015137,
      "lon": -79.1941986084,
      "tz": "America\/New_York"
  },
  "3NK5": {
      "icao": "3NK5",
      "iata": "",
      "name": "Secret Spot Airport",
      "city": "Plattsburgh",
      "state": "New York",
      "country": "US",
      "elevation": 155,
      "lat": 44.6166992188,
      "lon": -73.4653015137,
      "tz": "America\/New_York"
  },
  "3NK6": {
      "icao": "3NK6",
      "iata": "",
      "name": "Tuscarora Plateau Airport",
      "city": "Tuscarora",
      "state": "New York",
      "country": "US",
      "elevation": 900,
      "lat": 42.6370010376,
      "lon": -77.8839035034,
      "tz": "America\/New_York"
  },
  "3NK8": {
      "icao": "3NK8",
      "iata": "",
      "name": "B Flat Farm Airport",
      "city": "Copake",
      "state": "New York",
      "country": "US",
      "elevation": 800,
      "lat": 42.1405982971,
      "lon": -73.6119003296,
      "tz": "America\/New_York"
  },
  "3NR3": {
      "icao": "3NR3",
      "iata": "",
      "name": "Transylvania County Airport",
      "city": "Brevard",
      "state": "North Carolina",
      "country": "US",
      "elevation": 2110,
      "lat": 35.270278,
      "lon": -82.644167,
      "tz": "America\/New_York"
  },
  "3NY0": {
      "icao": "3NY0",
      "iata": "",
      "name": "The Ranch Airport",
      "city": "Charleston",
      "state": "New York",
      "country": "US",
      "elevation": 1110,
      "lat": 42.8273010254,
      "lon": -74.4039993286,
      "tz": "America\/New_York"
  },
  "3NY3": {
      "icao": "3NY3",
      "iata": "",
      "name": "De Ronda Airport",
      "city": "Springfield",
      "state": "New York",
      "country": "US",
      "elevation": 1650,
      "lat": 42.8597984314,
      "lon": -74.8243026733,
      "tz": "America\/New_York"
  },
  "3NY4": {
      "icao": "3NY4",
      "iata": "",
      "name": "Di Stefano Airpark",
      "city": "Fort Plain",
      "state": "New York",
      "country": "US",
      "elevation": 500,
      "lat": 42.922000885,
      "lon": -74.6268005371,
      "tz": "America\/New_York"
  },
  "3NY5": {
      "icao": "3NY5",
      "iata": "",
      "name": "Luke Airport",
      "city": "Binghamton",
      "state": "New York",
      "country": "US",
      "elevation": 1558,
      "lat": 42.0237007141,
      "lon": -75.9570999146,
      "tz": "America\/New_York"
  },
  "3NY6": {
      "icao": "3NY6",
      "iata": "",
      "name": "Six Ponds Airport",
      "city": "Duanesburg",
      "state": "New York",
      "country": "US",
      "elevation": 1125,
      "lat": 42.8412017822,
      "lon": -74.1787033081,
      "tz": "America\/New_York"
  },
  "3NY7": {
      "icao": "3NY7",
      "iata": "",
      "name": "Hiserts Airpark Inc Airport",
      "city": "Eparatha",
      "state": "New York",
      "country": "US",
      "elevation": 813,
      "lat": 42.9832992554,
      "lon": -74.5167007446,
      "tz": "America\/New_York"
  },
  "3NY9": {
      "icao": "3NY9",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Colden",
      "state": "New York",
      "country": "US",
      "elevation": 1385,
      "lat": 42.6643981934,
      "lon": -78.6731033325,
      "tz": "America\/New_York"
  },
  "3OA8": {
      "icao": "3OA8",
      "iata": "",
      "name": "Valley Vista Airport",
      "city": "Bainbridge",
      "state": "Ohio",
      "country": "US",
      "elevation": 720,
      "lat": 39.2375984192,
      "lon": -83.2606964111,
      "tz": "America\/New_York"
  },
  "3OG3": {
      "icao": "3OG3",
      "iata": "",
      "name": "Dillon Field Airport",
      "city": "Klamath Falls",
      "state": "Oregon",
      "country": "US",
      "elevation": 4092,
      "lat": 42.122222,
      "lon": -121.791667,
      "tz": "America\/Los_Angeles"
  },
  "3OH0": {
      "icao": "3OH0",
      "iata": "",
      "name": "Andy Barnhart Memorial Airport",
      "city": "New Carlisle",
      "state": "Ohio",
      "country": "US",
      "elevation": 895,
      "lat": 39.9309005737,
      "lon": -84.0134963989,
      "tz": "America\/New_York"
  },
  "3OH1": {
      "icao": "3OH1",
      "iata": "",
      "name": "Morningstar North Airport",
      "city": "West Alexandria",
      "state": "Ohio",
      "country": "US",
      "elevation": 982,
      "lat": 39.6972999573,
      "lon": -84.5427017212,
      "tz": "America\/New_York"
  },
  "3OH3": {
      "icao": "3OH3",
      "iata": "",
      "name": "Pelz Field",
      "city": "South Vienna",
      "state": "Ohio",
      "country": "US",
      "elevation": 990,
      "lat": 39.9838981628,
      "lon": -83.6132965088,
      "tz": "America\/New_York"
  },
  "3OH4": {
      "icao": "3OH4",
      "iata": "",
      "name": "Riceland Aerodrome",
      "city": "Andover",
      "state": "Ohio",
      "country": "US",
      "elevation": 1115,
      "lat": 41.6039009094,
      "lon": -80.5897979736,
      "tz": "America\/New_York"
  },
  "3OH5": {
      "icao": "3OH5",
      "iata": "",
      "name": "Stub's Field",
      "city": "Alliance",
      "state": "Ohio",
      "country": "US",
      "elevation": 1130,
      "lat": 40.9673995972,
      "lon": -81.2058029175,
      "tz": "America\/New_York"
  },
  "3OH6": {
      "icao": "3OH6",
      "iata": "",
      "name": "Youngpeter Airport",
      "city": "Delphos",
      "state": "Ohio",
      "country": "US",
      "elevation": 815,
      "lat": 40.7832984924,
      "lon": -84.3677978516,
      "tz": "America\/New_York"
  },
  "3OH7": {
      "icao": "3OH7",
      "iata": "",
      "name": "Creager Airport",
      "city": "Williamsburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 895,
      "lat": 39.0301017761,
      "lon": -84.0466003418,
      "tz": "America\/New_York"
  },
  "3OH8": {
      "icao": "3OH8",
      "iata": "",
      "name": "Gnadenhutten Airport",
      "city": "Gnadenhutten",
      "state": "Ohio",
      "country": "US",
      "elevation": 842,
      "lat": 40.3608016968,
      "lon": -81.4233016968,
      "tz": "America\/New_York"
  },
  "3OH9": {
      "icao": "3OH9",
      "iata": "",
      "name": "Merts Field",
      "city": "Wilmington",
      "state": "Ohio",
      "country": "US",
      "elevation": 1010,
      "lat": 39.5535011292,
      "lon": -83.8520965576,
      "tz": "America\/New_York"
  },
  "3OI6": {
      "icao": "3OI6",
      "iata": "",
      "name": "Vogel Airpark",
      "city": "Savannah",
      "state": "Ohio",
      "country": "US",
      "elevation": 1028,
      "lat": 40.9612007141,
      "lon": -82.3706970215,
      "tz": "America\/New_York"
  },
  "3OK0": {
      "icao": "3OK0",
      "iata": "",
      "name": "Valley Airport",
      "city": "Ada",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 780,
      "lat": 34.7050018311,
      "lon": -96.5766983032,
      "tz": "America\/Chicago"
  },
  "3OK1": {
      "icao": "3OK1",
      "iata": "",
      "name": "Okarche Airport",
      "city": "Okarche",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1239,
      "lat": 35.7299995422,
      "lon": -97.9670028687,
      "tz": "America\/Chicago"
  },
  "3OK2": {
      "icao": "3OK2",
      "iata": "",
      "name": "Hopcus Farms Airport",
      "city": "Jones",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1110,
      "lat": 35.591999054,
      "lon": -97.327796936,
      "tz": "America\/Chicago"
  },
  "3OK5": {
      "icao": "3OK5",
      "iata": "",
      "name": "Bearden Private Airport",
      "city": "Lindsay",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1180,
      "lat": 34.7285995483,
      "lon": -97.605796814,
      "tz": "America\/Chicago"
  },
  "3OK7": {
      "icao": "3OK7",
      "iata": "",
      "name": "Double W Airport",
      "city": "Sand Springs",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 790,
      "lat": 36.2417984009,
      "lon": -96.1342010498,
      "tz": "America\/Chicago"
  },
  "3OK8": {
      "icao": "3OK8",
      "iata": "",
      "name": "Flying G Ranch Airport",
      "city": "Sand Springs",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 890,
      "lat": 36.1166992188,
      "lon": -96.2335968018,
      "tz": "America\/Chicago"
  },
  "3OK9": {
      "icao": "3OK9",
      "iata": "",
      "name": "Jazz Ranch Airport",
      "city": "Shawnee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1080,
      "lat": 35.321786,
      "lon": -97.040083,
      "tz": "America\/Chicago"
  },
  "3OL8": {
      "icao": "3OL8",
      "iata": "",
      "name": "Harrison Airport",
      "city": "Welch",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 805,
      "lat": 36.8778991699,
      "lon": -95.1491012573,
      "tz": "America\/Chicago"
  },
  "3OR1": {
      "icao": "3OR1",
      "iata": "",
      "name": "Cubehole Airport",
      "city": "Brownsville",
      "state": "Oregon",
      "country": "US",
      "elevation": 355,
      "lat": 44.3646011353,
      "lon": -122.9580001831,
      "tz": "America\/Los_Angeles"
  },
  "3OR2": {
      "icao": "3OR2",
      "iata": "",
      "name": "Maxwell Private Airport",
      "city": "La Grande",
      "state": "Oregon",
      "country": "US",
      "elevation": 2710,
      "lat": 45.3124008179,
      "lon": -117.9589996338,
      "tz": "America\/Los_Angeles"
  },
  "3OR8": {
      "icao": "3OR8",
      "iata": "",
      "name": "Cline Falls Air Park",
      "city": "Redmond",
      "state": "Oregon",
      "country": "US",
      "elevation": 2920,
      "lat": 44.2831993103,
      "lon": -121.2689971924,
      "tz": "America\/Los_Angeles"
  },
  "3OR9": {
      "icao": "3OR9",
      "iata": "",
      "name": "Murphy Ranch Airport",
      "city": "Juntura",
      "state": "Oregon",
      "country": "US",
      "elevation": 3465,
      "lat": 43.9574012756,
      "lon": -118.1320037842,
      "tz": "America\/Boise"
  },
  "3PA0": {
      "icao": "3PA0",
      "iata": "",
      "name": "Horst Airport",
      "city": "Lebanon",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 450,
      "lat": 40.3833999634,
      "lon": -76.4664001465,
      "tz": "America\/New_York"
  },
  "3PA1": {
      "icao": "3PA1",
      "iata": "",
      "name": "Navarro Airport",
      "city": "Sellersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 540,
      "lat": 40.3722991943,
      "lon": -75.3376998901,
      "tz": "America\/New_York"
  },
  "3PA2": {
      "icao": "3PA2",
      "iata": "",
      "name": "Neeb Airport",
      "city": "Lehighton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 825,
      "lat": 40.8805999756,
      "lon": -75.6356964111,
      "tz": "America\/New_York"
  },
  "3PA3": {
      "icao": "3PA3",
      "iata": "",
      "name": "Lehman Airport",
      "city": "Lehman",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1300,
      "lat": 41.3166999817,
      "lon": -76.0495986938,
      "tz": "America\/New_York"
  },
  "3PA4": {
      "icao": "3PA4",
      "iata": "",
      "name": "Giffin Airport",
      "city": "Leraysville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 840,
      "lat": 41.7779006958,
      "lon": -76.1371994019,
      "tz": "America\/New_York"
  },
  "3PA6": {
      "icao": "3PA6",
      "iata": "",
      "name": "Fox Hollow Airport",
      "city": "Lewisburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 540,
      "lat": 40.9934005737,
      "lon": -76.9001998901,
      "tz": "America\/New_York"
  },
  "3PA8": {
      "icao": "3PA8",
      "iata": "",
      "name": "Harris Airport",
      "city": "Lincoln University",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 575,
      "lat": 39.820400238,
      "lon": -75.9257965088,
      "tz": "America\/New_York"
  },
  "3PA9": {
      "icao": "3PA9",
      "iata": "",
      "name": "Moyer Airport",
      "city": "Line Lexington",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 330,
      "lat": 40.2967987061,
      "lon": -75.2480010986,
      "tz": "America\/New_York"
  },
  "3PN0": {
      "icao": "3PN0",
      "iata": "",
      "name": "Schulteis Airport",
      "city": "Biglerville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 680,
      "lat": 39.9603996277,
      "lon": -77.2591018677,
      "tz": "America\/New_York"
  },
  "3PN1": {
      "icao": "3PN1",
      "iata": "",
      "name": "Ashlawn Airport",
      "city": "Clifford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1511,
      "lat": 41.6220016479,
      "lon": -75.5962982178,
      "tz": "America\/New_York"
  },
  "3PN2": {
      "icao": "3PN2",
      "iata": "",
      "name": "Karlindo Airport",
      "city": "Fairfield",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 530,
      "lat": 39.7589988708,
      "lon": -77.3475036621,
      "tz": "America\/New_York"
  },
  "3PN3": {
      "icao": "3PN3",
      "iata": "",
      "name": "Countryside Airport",
      "city": "Lehman",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1260,
      "lat": 41.2836990356,
      "lon": -76.0352020264,
      "tz": "America\/New_York"
  },
  "3PN6": {
      "icao": "3PN6",
      "iata": "",
      "name": "Gravel Run Airport",
      "city": "Cambridge Springs",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1240,
      "lat": 41.7500991821,
      "lon": -80.0830993652,
      "tz": "America\/New_York"
  },
  "3PN7": {
      "icao": "3PN7",
      "iata": "",
      "name": "Stahl's Mountain Airport",
      "city": "Union Dale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1950,
      "lat": 41.7478981018,
      "lon": -75.4973983765,
      "tz": "America\/New_York"
  },
  "3PN9": {
      "icao": "3PN9",
      "iata": "",
      "name": "Phil Cain Memorial Field",
      "city": "Export",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1210,
      "lat": 40.4417991638,
      "lon": -79.5501022339,
      "tz": "America\/New_York"
  },
  "3PS4": {
      "icao": "3PS4",
      "iata": "",
      "name": "Mountain Hide-Away Airport",
      "city": "Millcreek",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1604,
      "lat": 40.7235984802,
      "lon": -77.9873962402,
      "tz": "America\/New_York"
  },
  "3PS8": {
      "icao": "3PS8",
      "iata": "",
      "name": "Yingst Airport",
      "city": "Harrisburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 701,
      "lat": 40.3958015442,
      "lon": -76.7457962036,
      "tz": "America\/New_York"
  },
  "3PS9": {
      "icao": "3PS9",
      "iata": "",
      "name": "Risker Field",
      "city": "Bulger",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1100,
      "lat": 40.3783988953,
      "lon": -80.3495025635,
      "tz": "America\/New_York"
  },
  "3SC2": {
      "icao": "3SC2",
      "iata": "",
      "name": "Yahu Field",
      "city": "Summerton",
      "state": "South Carolina",
      "country": "US",
      "elevation": 140,
      "lat": 33.601944,
      "lon": -80.306111,
      "tz": "America\/New_York"
  },
  "3SC4": {
      "icao": "3SC4",
      "iata": "",
      "name": "Crooked Fence Farm Airport",
      "city": "Woodruff",
      "state": "South Carolina",
      "country": "US",
      "elevation": 645,
      "lat": 34.773601532,
      "lon": -81.9863967896,
      "tz": "America\/New_York"
  },
  "3SC7": {
      "icao": "3SC7",
      "iata": "",
      "name": "Jordan Airport",
      "city": "Andrews",
      "state": "South Carolina",
      "country": "US",
      "elevation": 35,
      "lat": 33.5396995544,
      "lon": -79.5318984985,
      "tz": "America\/New_York"
  },
  "3SD3": {
      "icao": "3SD3",
      "iata": "",
      "name": "Brown Field",
      "city": "Scenic",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2780,
      "lat": 43.8117980957,
      "lon": -102.7200012207,
      "tz": "America\/Denver"
  },
  "3SD4": {
      "icao": "3SD4",
      "iata": "",
      "name": "Chris Hofer Landing Strip",
      "city": "Tea",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1491,
      "lat": 43.4655990601,
      "lon": -96.8632965088,
      "tz": "America\/Chicago"
  },
  "3SD6": {
      "icao": "3SD6",
      "iata": "",
      "name": "Running Colors Airport",
      "city": "Rapid City",
      "state": "South Dakota",
      "country": "US",
      "elevation": 2920,
      "lat": 44.2022018433,
      "lon": -103.0790023804,
      "tz": "America\/Denver"
  },
  "3TA0": {
      "icao": "3TA0",
      "iata": "",
      "name": "Four Square Ranch Airport",
      "city": "Rocksprings",
      "state": "Texas",
      "country": "US",
      "elevation": 2250,
      "lat": 30.0969009399,
      "lon": -100.4049987793,
      "tz": "America\/Chicago"
  },
  "3TA1": {
      "icao": "3TA1",
      "iata": "",
      "name": "Diamond K Ranch Airport",
      "city": "Comfort",
      "state": "Texas",
      "country": "US",
      "elevation": 1600,
      "lat": 30.0366001129,
      "lon": -98.6975021362,
      "tz": "America\/Chicago"
  },
  "3TA2": {
      "icao": "3TA2",
      "iata": "",
      "name": "Clark Sky Ranch Airport",
      "city": "Sealy",
      "state": "Texas",
      "country": "US",
      "elevation": 145,
      "lat": 29.7366008759,
      "lon": -96.0641021729,
      "tz": "America\/Chicago"
  },
  "3TA4": {
      "icao": "3TA4",
      "iata": "",
      "name": "Tin Top Ranch Airport",
      "city": "Brownwood",
      "state": "Texas",
      "country": "US",
      "elevation": 1300,
      "lat": 31.4857997894,
      "lon": -98.9760971069,
      "tz": "America\/Chicago"
  },
  "3TA5": {
      "icao": "3TA5",
      "iata": "",
      "name": "Blanco Landing Airport",
      "city": "Blanco",
      "state": "Texas",
      "country": "US",
      "elevation": 1460,
      "lat": 30.122467,
      "lon": -98.374783,
      "tz": "America\/Chicago"
  },
  "3TA6": {
      "icao": "3TA6",
      "iata": "",
      "name": "Spring Ranch Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 1440,
      "lat": 29.5419006348,
      "lon": -100.2529983521,
      "tz": "America\/Chicago"
  },
  "3TA7": {
      "icao": "3TA7",
      "iata": "",
      "name": "Jim Sears Airport",
      "city": "Bridgeport",
      "state": "Texas",
      "country": "US",
      "elevation": 900,
      "lat": 33.143699646,
      "lon": -97.8044967651,
      "tz": "America\/Chicago"
  },
  "3TA8": {
      "icao": "3TA8",
      "iata": "",
      "name": "Picosa Ranch Airport",
      "city": "Crystal City",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 28.7196998596,
      "lon": -100.0550003052,
      "tz": "America\/Chicago"
  },
  "3TA9": {
      "icao": "3TA9",
      "iata": "",
      "name": "Chacon Creek Ranch Airport",
      "city": "Crystal City",
      "state": "Texas",
      "country": "US",
      "elevation": 595,
      "lat": 28.7085990906,
      "lon": -100.0070037842,
      "tz": "America\/Chicago"
  },
  "3TE0": {
      "icao": "3TE0",
      "iata": "",
      "name": "K Ranch Airport",
      "city": "Iola",
      "state": "Texas",
      "country": "US",
      "elevation": 285,
      "lat": 30.7315998077,
      "lon": -96.1311035156,
      "tz": "America\/Chicago"
  },
  "3TE1": {
      "icao": "3TE1",
      "iata": "",
      "name": "Gum Island Airport",
      "city": "Dayton",
      "state": "Texas",
      "country": "US",
      "elevation": 53,
      "lat": 29.9515991211,
      "lon": -94.9044036865,
      "tz": "America\/Chicago"
  },
  "3TE3": {
      "icao": "3TE3",
      "iata": "",
      "name": "Big Bend Ranch State Park Airport",
      "city": "Presidio",
      "state": "Texas",
      "country": "US",
      "elevation": 4250,
      "lat": 29.4696006775,
      "lon": -103.93699646,
      "tz": "America\/Chicago"
  },
  "3TE4": {
      "icao": "3TE4",
      "iata": "",
      "name": "Hot Springs Airport",
      "city": "Ruidosa",
      "state": "Texas",
      "country": "US",
      "elevation": 3071,
      "lat": 30.0088005066,
      "lon": -104.6650009155,
      "tz": "America\/Chicago"
  },
  "3TE5": {
      "icao": "3TE5",
      "iata": "",
      "name": "Stamps Field",
      "city": "Panhandle",
      "state": "Texas",
      "country": "US",
      "elevation": 3445,
      "lat": 35.3306007385,
      "lon": -101.3850021362,
      "tz": "America\/Chicago"
  },
  "3TE6": {
      "icao": "3TE6",
      "iata": "",
      "name": "Skellytown Airport",
      "city": "Skellytown",
      "state": "Texas",
      "country": "US",
      "elevation": 3280,
      "lat": 35.5699996948,
      "lon": -101.1689987183,
      "tz": "America\/Chicago"
  },
  "3TE7": {
      "icao": "3TE7",
      "iata": "",
      "name": "Mills Ranch Airport",
      "city": "Pandale",
      "state": "Texas",
      "country": "US",
      "elevation": 1965,
      "lat": 30.2418994904,
      "lon": -101.4169998169,
      "tz": "America\/Chicago"
  },
  "3TE8": {
      "icao": "3TE8",
      "iata": "",
      "name": "Terlingua Airport",
      "city": "Terlingua \/Alpine\/",
      "state": "Texas",
      "country": "US",
      "elevation": 2717,
      "lat": 29.3194007874,
      "lon": -103.5839996338,
      "tz": "America\/Chicago"
  },
  "3TE9": {
      "icao": "3TE9",
      "iata": "",
      "name": "Pinoak Airport",
      "city": "Dayton",
      "state": "Texas",
      "country": "US",
      "elevation": 91,
      "lat": 30.1058006287,
      "lon": -94.9588012695,
      "tz": "America\/Chicago"
  },
  "3TN0": {
      "icao": "3TN0",
      "iata": "",
      "name": "Indian Springs Airport",
      "city": "Kingsport",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1580,
      "lat": 36.535,
      "lon": -82.423889,
      "tz": "America\/New_York"
  },
  "3TN3": {
      "icao": "3TN3",
      "iata": "",
      "name": "Wayne's World Airport",
      "city": "Dresden",
      "state": "Tennessee",
      "country": "US",
      "elevation": 440,
      "lat": 36.2633018494,
      "lon": -88.7572021484,
      "tz": "America\/Chicago"
  },
  "3TN4": {
      "icao": "3TN4",
      "iata": "",
      "name": "Kenner Farm Airport",
      "city": "Seymour",
      "state": "Tennessee",
      "country": "US",
      "elevation": 940,
      "lat": 35.8809013367,
      "lon": -83.7270965576,
      "tz": "America\/New_York"
  },
  "3TN6": {
      "icao": "3TN6",
      "iata": "",
      "name": "Wilson Field",
      "city": "Memphis",
      "state": "Tennessee",
      "country": "US",
      "elevation": 435,
      "lat": 35.0968017578,
      "lon": -89.2806015015,
      "tz": "America\/Chicago"
  },
  "3TN8": {
      "icao": "3TN8",
      "iata": "",
      "name": "Massingale Airport",
      "city": "Lenoir City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 840,
      "lat": 35.763999939,
      "lon": -84.1874008179,
      "tz": "America\/New_York"
  },
  "3TN9": {
      "icao": "3TN9",
      "iata": "",
      "name": "Holenthawall Airport",
      "city": "Springfield",
      "state": "Tennessee",
      "country": "US",
      "elevation": 645,
      "lat": 36.6133003235,
      "lon": -86.8103027344,
      "tz": "America\/Chicago"
  },
  "3TS0": {
      "icao": "3TS0",
      "iata": "",
      "name": "East Side Airport",
      "city": "Longview",
      "state": "Texas",
      "country": "US",
      "elevation": 373,
      "lat": 32.5084991455,
      "lon": -94.7002029419,
      "tz": "America\/Chicago"
  },
  "3TS1": {
      "icao": "3TS1",
      "iata": "",
      "name": "White Wings Airport",
      "city": "Wimberley",
      "state": "Texas",
      "country": "US",
      "elevation": 1083,
      "lat": 30.0114002228,
      "lon": -98.0404968262,
      "tz": "America\/Chicago"
  },
  "3TS3": {
      "icao": "3TS3",
      "iata": "",
      "name": "J-D Ranch Airport",
      "city": "Alvin",
      "state": "Texas",
      "country": "US",
      "elevation": 34,
      "lat": 29.3747005463,
      "lon": -95.2904968262,
      "tz": "America\/Chicago"
  },
  "3TS5": {
      "icao": "3TS5",
      "iata": "",
      "name": "Purdy-Nielsen Memorial Airpark",
      "city": "Beasley",
      "state": "Texas",
      "country": "US",
      "elevation": 105,
      "lat": 29.4710998535,
      "lon": -95.8862991333,
      "tz": "America\/Chicago"
  },
  "3TS7": {
      "icao": "3TS7",
      "iata": "",
      "name": "Pavlat Airport",
      "city": "Dayton",
      "state": "Texas",
      "country": "US",
      "elevation": 70,
      "lat": 30.0210990906,
      "lon": -94.9459991455,
      "tz": "America\/Chicago"
  },
  "3TS9": {
      "icao": "3TS9",
      "iata": "",
      "name": "Oak Glen Ranch Airport",
      "city": "Cooper",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 33.3792991638,
      "lon": -95.6035995483,
      "tz": "America\/Chicago"
  },
  "3TX1": {
      "icao": "3TX1",
      "iata": "",
      "name": "Paradise Point Airport",
      "city": "Berryville",
      "state": "Texas",
      "country": "US",
      "elevation": 345,
      "lat": 32.0917015076,
      "lon": -95.4447021484,
      "tz": "America\/Chicago"
  },
  "3TX2": {
      "icao": "3TX2",
      "iata": "",
      "name": "Flying S Farm Airport",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 780,
      "lat": 33.0806999207,
      "lon": -97.3488998413,
      "tz": "America\/Chicago"
  },
  "3TX3": {
      "icao": "3TX3",
      "iata": "",
      "name": "Sitton Field",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 682,
      "lat": 33.1195983887,
      "lon": -97.3197021484,
      "tz": "America\/Chicago"
  },
  "3TX4": {
      "icao": "3TX4",
      "iata": "",
      "name": "Willow Run Airport",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 820,
      "lat": 33.0946006775,
      "lon": -97.3803024292,
      "tz": "America\/Chicago"
  },
  "3TX5": {
      "icao": "3TX5",
      "iata": "",
      "name": "Mc Creless Farm Airport",
      "city": "Adkins",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 29.371099472,
      "lon": -98.2316970825,
      "tz": "America\/Chicago"
  },
  "3TX6": {
      "icao": "3TX6",
      "iata": "",
      "name": "Lowell Smith Jr Airport",
      "city": "Rio Vista",
      "state": "Texas",
      "country": "US",
      "elevation": 740,
      "lat": 32.233481,
      "lon": -97.380849,
      "tz": "America\/Chicago"
  },
  "3TX7": {
      "icao": "3TX7",
      "iata": "",
      "name": "Flying P Airport",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 805,
      "lat": 33.1408004761,
      "lon": -97.3711013794,
      "tz": "America\/Chicago"
  },
  "3TX8": {
      "icao": "3TX8",
      "iata": "",
      "name": "Drop Field",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 33.1250991821,
      "lon": -97.3460998535,
      "tz": "America\/Chicago"
  },
  "3TX9": {
      "icao": "3TX9",
      "iata": "",
      "name": "Rafter J Airport",
      "city": "Burleson",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 32.5275993347,
      "lon": -97.2807998657,
      "tz": "America\/Chicago"
  },
  "3VA0": {
      "icao": "3VA0",
      "iata": "",
      "name": "Ayres-Aicp Airport",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 530,
      "lat": 38.7759017944,
      "lon": -77.9105987549,
      "tz": "America\/New_York"
  },
  "3VA1": {
      "icao": "3VA1",
      "iata": "",
      "name": "The Meadows Airport",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 584,
      "lat": 38.7742996216,
      "lon": -77.799697876,
      "tz": "America\/New_York"
  },
  "3VA2": {
      "icao": "3VA2",
      "iata": "",
      "name": "Aviacres Airport",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 435,
      "lat": 38.6242980957,
      "lon": -77.7861022949,
      "tz": "America\/New_York"
  },
  "3VA3": {
      "icao": "3VA3",
      "iata": "",
      "name": "Flying Circus Aerodrome",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 315,
      "lat": 38.5503997803,
      "lon": -77.7157974243,
      "tz": "America\/New_York"
  },
  "3VA4": {
      "icao": "3VA4",
      "iata": "",
      "name": "Shandy Hall Farm Airport",
      "city": "Warsaw",
      "state": "Virginia",
      "country": "US",
      "elevation": 10,
      "lat": 37.8576011658,
      "lon": -76.7358016968,
      "tz": "America\/New_York"
  },
  "3VA5": {
      "icao": "3VA5",
      "iata": "",
      "name": "Goose Hunt Farm Airport",
      "city": "Leesburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 340,
      "lat": 39.028889,
      "lon": -77.625,
      "tz": "America\/New_York"
  },
  "3VA7": {
      "icao": "3VA7",
      "iata": "",
      "name": "White Post Airport",
      "city": "White Post",
      "state": "Virginia",
      "country": "US",
      "elevation": 610,
      "lat": 39.0625991821,
      "lon": -78.0914001465,
      "tz": "America\/New_York"
  },
  "3VA8": {
      "icao": "3VA8",
      "iata": "",
      "name": "Garner Airport",
      "city": "Windsor",
      "state": "Virginia",
      "country": "US",
      "elevation": 80,
      "lat": 36.856300354,
      "lon": -76.6821975708,
      "tz": "America\/New_York"
  },
  "3VG2": {
      "icao": "3VG2",
      "iata": "",
      "name": "Machipongo International Airport",
      "city": "Machipongo",
      "state": "Virginia",
      "country": "US",
      "elevation": 36,
      "lat": 37.3883018494,
      "lon": -75.8925018311,
      "tz": "America\/New_York"
  },
  "3VG3": {
      "icao": "3VG3",
      "iata": "",
      "name": "Serenity Farm Airport",
      "city": "Burgess",
      "state": "Virginia",
      "country": "US",
      "elevation": 10,
      "lat": 37.9361000061,
      "lon": -76.3486022949,
      "tz": "America\/New_York"
  },
  "3VG4": {
      "icao": "3VG4",
      "iata": "",
      "name": "Knight'S Landing Llc",
      "city": "Virginia Beach",
      "state": "Virginia",
      "country": "US",
      "elevation": 11,
      "lat": 36.558333,
      "lon": -76.116389,
      "tz": "America\/New_York"
  },
  "3VG7": {
      "icao": "3VG7",
      "iata": "",
      "name": "Hartwood Airport",
      "city": "Somerville",
      "state": "Virginia",
      "country": "US",
      "elevation": 390,
      "lat": 38.4846000671,
      "lon": -77.6128005981,
      "tz": "America\/New_York"
  },
  "3VG9": {
      "icao": "3VG9",
      "iata": "",
      "name": "Birch Creek Plantation Airport",
      "city": "South Boston",
      "state": "Virginia",
      "country": "US",
      "elevation": 550,
      "lat": 36.701944,
      "lon": -79.133889,
      "tz": "America\/New_York"
  },
  "3WA0": {
      "icao": "3WA0",
      "iata": "",
      "name": "Taylor Airport",
      "city": "Rainier",
      "state": "Washington",
      "country": "US",
      "elevation": 470,
      "lat": 46.8975982666,
      "lon": -122.6699981689,
      "tz": "America\/Los_Angeles"
  },
  "3WA1": {
      "icao": "3WA1",
      "iata": "",
      "name": "Johnson's Landing Ultralightport",
      "city": "Leavenworth",
      "state": "Washington",
      "country": "US",
      "elevation": 1260,
      "lat": 47.6035995483,
      "lon": -120.6699981689,
      "tz": "America\/Los_Angeles"
  },
  "3WA2": {
      "icao": "3WA2",
      "iata": "",
      "name": "Hanes Airport",
      "city": "Harrington",
      "state": "Washington",
      "country": "US",
      "elevation": 2209,
      "lat": 47.4915008545,
      "lon": -118.2509994507,
      "tz": "America\/Los_Angeles"
  },
  "3WA3": {
      "icao": "3WA3",
      "iata": "",
      "name": "Angel Park Airport",
      "city": "Kahlotus",
      "state": "Washington",
      "country": "US",
      "elevation": 884,
      "lat": 46.6484985352,
      "lon": -118.5830001831,
      "tz": "America\/Los_Angeles"
  },
  "3WA4": {
      "icao": "3WA4",
      "iata": "",
      "name": "Watson Airport",
      "city": "Kahlotus",
      "state": "Washington",
      "country": "US",
      "elevation": 1349,
      "lat": 46.7164993286,
      "lon": -118.6179962158,
      "tz": "America\/Los_Angeles"
  },
  "3WA9": {
      "icao": "3WA9",
      "iata": "",
      "name": "Flying Carpet Airport",
      "city": "Lacey",
      "state": "Washington",
      "country": "US",
      "elevation": 255,
      "lat": 46.9628982544,
      "lon": -122.8000030518,
      "tz": "America\/Los_Angeles"
  },
  "3WI0": {
      "icao": "3WI0",
      "iata": "",
      "name": "Carnot Field",
      "city": "Algoma",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 655,
      "lat": 44.64220047,
      "lon": -87.4197998047,
      "tz": "America\/Chicago"
  },
  "3WI1": {
      "icao": "3WI1",
      "iata": "",
      "name": "Olson's Airport",
      "city": "Union Grove",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 825,
      "lat": 42.6570014954,
      "lon": -88.087600708,
      "tz": "America\/Chicago"
  },
  "3WI2": {
      "icao": "3WI2",
      "iata": "",
      "name": "Tesmer Airport",
      "city": "Waterloo",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 925,
      "lat": 43.1528015137,
      "lon": -88.9845962524,
      "tz": "America\/Chicago"
  },
  "3WI3": {
      "icao": "3WI3",
      "iata": "",
      "name": "Plover River Airfield",
      "city": "Bevent",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1185,
      "lat": 44.7652015686,
      "lon": -89.4073028564,
      "tz": "America\/Chicago"
  },
  "3WI4": {
      "icao": "3WI4",
      "iata": "",
      "name": "Flying 'O' Airport",
      "city": "Wausau",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1320,
      "lat": 44.9235992432,
      "lon": -89.4022979736,
      "tz": "America\/Chicago"
  },
  "3WI5": {
      "icao": "3WI5",
      "iata": "",
      "name": "Clover Valley Airport",
      "city": "Whitewater",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 42.8125,
      "lon": -88.7265014648,
      "tz": "America\/Chicago"
  },
  "3WI6": {
      "icao": "3WI6",
      "iata": "",
      "name": "Melin Farms Airport",
      "city": "Clinton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 885,
      "lat": 42.5900993347,
      "lon": -88.8746032715,
      "tz": "America\/Chicago"
  },
  "3WI7": {
      "icao": "3WI7",
      "iata": "",
      "name": "Bark River Airport",
      "city": "Dousman",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 875,
      "lat": 43.0332984924,
      "lon": -88.4581985474,
      "tz": "America\/Chicago"
  },
  "3WI8": {
      "icao": "3WI8",
      "iata": "",
      "name": "Plantation Pine Airport",
      "city": "Redgranite",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 825,
      "lat": 44.0532989502,
      "lon": -89.1218032837,
      "tz": "America\/Chicago"
  },
  "3WI9": {
      "icao": "3WI9",
      "iata": "",
      "name": "Rosenbaum Field",
      "city": "Chippewa Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 940,
      "lat": 44.9937591553,
      "lon": -91.3777542114,
      "tz": "America\/Chicago"
  },
  "3WN2": {
      "icao": "3WN2",
      "iata": "",
      "name": "Old Dairy Airport",
      "city": "Washburn",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 725,
      "lat": 46.6916007996,
      "lon": -90.8706970215,
      "tz": "America\/Chicago"
  },
  "3WN4": {
      "icao": "3WN4",
      "iata": "",
      "name": "Mark's Park & Airfield",
      "city": "New London",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 855,
      "lat": 44.3069000244,
      "lon": -88.7711029053,
      "tz": "America\/Chicago"
  },
  "3WN6": {
      "icao": "3WN6",
      "iata": "",
      "name": "Knutson Farms Airport",
      "city": "Chetek",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1035,
      "lat": 45.2389984131,
      "lon": -91.7118988037,
      "tz": "America\/Chicago"
  },
  "3WN7": {
      "icao": "3WN7",
      "iata": "",
      "name": "Woodland Airstrip",
      "city": "Manitowoc",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 610,
      "lat": 44.1500015259,
      "lon": -87.6177978516,
      "tz": "America\/Chicago"
  },
  "3WN8": {
      "icao": "3WN8",
      "iata": "",
      "name": "Blunt Field",
      "city": "Chippewa Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 930,
      "lat": 44.9356002808,
      "lon": -91.4307022095,
      "tz": "America\/Chicago"
  },
  "3WN9": {
      "icao": "3WN9",
      "iata": "",
      "name": "Brion Memorial Airport",
      "city": "Strum",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 900,
      "lat": 44.5569000244,
      "lon": -91.366897583,
      "tz": "America\/Chicago"
  },
  "3XA0": {
      "icao": "3XA0",
      "iata": "",
      "name": "Drennan Airport",
      "city": "Grandview",
      "state": "Texas",
      "country": "US",
      "elevation": 726,
      "lat": 32.313889,
      "lon": -97.231111,
      "tz": "America\/Chicago"
  },
  "3XA1": {
      "icao": "3XA1",
      "iata": "",
      "name": "Hardy Field",
      "city": "Valley View",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 33.47395,
      "lon": -97.169267,
      "tz": "America\/Chicago"
  },
  "3XA7": {
      "icao": "3XA7",
      "iata": "",
      "name": "Eagle Rock Ranch Airport",
      "city": "Pontotoc",
      "state": "Texas",
      "country": "US",
      "elevation": 1540,
      "lat": 30.890817,
      "lon": -99.006183,
      "tz": "America\/Chicago"
  },
  "3XA8": {
      "icao": "3XA8",
      "iata": "",
      "name": "Chicken Strip",
      "city": "Cresson",
      "state": "Texas",
      "country": "US",
      "elevation": 1055,
      "lat": 32.514167,
      "lon": -97.595278,
      "tz": "America\/Chicago"
  },
  "3XS0": {
      "icao": "3XS0",
      "iata": "",
      "name": "Hartlee Field",
      "city": "Denton",
      "state": "Texas",
      "country": "US",
      "elevation": 588,
      "lat": 33.2685012817,
      "lon": -97.0710983276,
      "tz": "America\/Chicago"
  },
  "3XS1": {
      "icao": "3XS1",
      "iata": "",
      "name": "Kubecka Aviation Airport",
      "city": "Palacios",
      "state": "Texas",
      "country": "US",
      "elevation": 20,
      "lat": 28.76720047,
      "lon": -96.3069000244,
      "tz": "America\/Chicago"
  },
  "3XS4": {
      "icao": "3XS4",
      "iata": "",
      "name": "Jenkins Airport",
      "city": "Vidor",
      "state": "Texas",
      "country": "US",
      "elevation": 15,
      "lat": 30.1343994141,
      "lon": -93.9359970093,
      "tz": "America\/Chicago"
  },
  "3XS5": {
      "icao": "3XS5",
      "iata": "",
      "name": "Star Smith Field",
      "city": "Von Ormy",
      "state": "Texas",
      "country": "US",
      "elevation": 662,
      "lat": 29.2980003357,
      "lon": -98.6766967773,
      "tz": "America\/Chicago"
  },
  "3XS8": {
      "icao": "3XS8",
      "iata": "",
      "name": "Ken Ada Ranch Airport",
      "city": "Waller",
      "state": "Texas",
      "country": "US",
      "elevation": 250,
      "lat": 30.1308002472,
      "lon": -95.9001998901,
      "tz": "America\/Chicago"
  },
  "40AK": {
      "icao": "40AK",
      "iata": "",
      "name": "Sterling Air Park",
      "city": "Sterling",
      "state": "Alaska",
      "country": "US",
      "elevation": 90,
      "lat": 60.5553016663,
      "lon": -150.8419952393,
      "tz": "America\/Anchorage"
  },
  "40AR": {
      "icao": "40AR",
      "iata": "",
      "name": "Lost Bridge Village Airport",
      "city": "Garfield",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1440,
      "lat": 36.3927993774,
      "lon": -93.912399292,
      "tz": "America\/Chicago"
  },
  "40AZ": {
      "icao": "40AZ",
      "iata": "",
      "name": "Boulais Ranch Airport",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1240,
      "lat": 33.0788993835,
      "lon": -112.1289978027,
      "tz": "America\/Phoenix"
  },
  "40FL": {
      "icao": "40FL",
      "iata": "",
      "name": "Fred Babcock Airport",
      "city": "Punta Gorda",
      "state": "Florida",
      "country": "US",
      "elevation": 38,
      "lat": 26.885099411,
      "lon": -81.7511978149,
      "tz": "America\/New_York"
  },
  "40II": {
      "icao": "40II",
      "iata": "",
      "name": "Keener Field",
      "city": "Butler",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 41.351398468,
      "lon": -84.8169021606,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "40IL": {
      "icao": "40IL",
      "iata": "",
      "name": "Antique Aerodrome",
      "city": "Cornell",
      "state": "Illinois",
      "country": "US",
      "elevation": 683,
      "lat": 41.0057983398,
      "lon": -88.6580963135,
      "tz": "America\/Chicago"
  },
  "40IN": {
      "icao": "40IN",
      "iata": "",
      "name": "Cherry Hill Airport",
      "city": "Freetown",
      "state": "Indiana",
      "country": "US",
      "elevation": 745,
      "lat": 39.0741996765,
      "lon": -86.1541976929,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "40KS": {
      "icao": "40KS",
      "iata": "",
      "name": "Chanay Airport",
      "city": "Williamsburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1110,
      "lat": 38.5400009155,
      "lon": -95.4507980347,
      "tz": "America\/Chicago"
  },
  "40KY": {
      "icao": "40KY",
      "iata": "",
      "name": "Rooster Field",
      "city": "Eminence",
      "state": "Kentucky",
      "country": "US",
      "elevation": 840,
      "lat": 38.2933998108,
      "lon": -85.2050018311,
      "tz": "America\/New_York"
  },
  "40LA": {
      "icao": "40LA",
      "iata": "",
      "name": "Woodsland Plantation Airport",
      "city": "Monroe",
      "state": "Louisiana",
      "country": "US",
      "elevation": 60,
      "lat": 32.3460006714,
      "lon": -91.9695968628,
      "tz": "America\/Chicago"
  },
  "40ME": {
      "icao": "40ME",
      "iata": "",
      "name": "Tib Field",
      "city": "Fryeburg",
      "state": "Maine",
      "country": "US",
      "elevation": 450,
      "lat": 44.105892,
      "lon": -70.955978,
      "tz": "America\/New_York"
  },
  "40MN": {
      "icao": "40MN",
      "iata": "",
      "name": "Turner Field",
      "city": "Bingham Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1410,
      "lat": 43.8666000366,
      "lon": -95.0141983032,
      "tz": "America\/Chicago"
  },
  "40MO": {
      "icao": "40MO",
      "iata": "",
      "name": "Louise's Strip",
      "city": "Weston",
      "state": "Missouri",
      "country": "US",
      "elevation": 945,
      "lat": 39.4227981567,
      "lon": -94.8376998901,
      "tz": "America\/Chicago"
  },
  "40NE": {
      "icao": "40NE",
      "iata": "",
      "name": "Landgren Ranch Airport",
      "city": "Bartlett",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2100,
      "lat": 41.9749984741,
      "lon": -98.5158996582,
      "tz": "America\/Chicago"
  },
  "40OH": {
      "icao": "40OH",
      "iata": "",
      "name": "Bucks Airport",
      "city": "Newbury",
      "state": "Ohio",
      "country": "US",
      "elevation": 1220,
      "lat": 41.4511985779,
      "lon": -81.2348022461,
      "tz": "America\/New_York"
  },
  "40OI": {
      "icao": "40OI",
      "iata": "",
      "name": "M.C.R. Airport",
      "city": "Lodi",
      "state": "Ohio",
      "country": "US",
      "elevation": 1155,
      "lat": 41.0616989136,
      "lon": -81.9835968018,
      "tz": "America\/New_York"
  },
  "40OK": {
      "icao": "40OK",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Oilton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 930,
      "lat": 36.1161003113,
      "lon": -96.5381011963,
      "tz": "America\/Chicago"
  },
  "40PN": {
      "icao": "40PN",
      "iata": "",
      "name": "Eagles Mere Field Airport",
      "city": "Eagles Mere",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1900,
      "lat": 41.3911556,
      "lon": -76.6114028,
      "tz": "America\/New_York"
  },
  "40TA": {
      "icao": "40TA",
      "iata": "",
      "name": "Miles Field",
      "city": "Jewett",
      "state": "Texas",
      "country": "US",
      "elevation": 460,
      "lat": 31.3129997253,
      "lon": -96.2204971313,
      "tz": "America\/Chicago"
  },
  "40TE": {
      "icao": "40TE",
      "iata": "",
      "name": "Sybert Farm Airport",
      "city": "Jarrell",
      "state": "Texas",
      "country": "US",
      "elevation": 841,
      "lat": 30.789100647,
      "lon": -97.6549987793,
      "tz": "America\/Chicago"
  },
  "40TS": {
      "icao": "40TS",
      "iata": "",
      "name": "Square K Airport",
      "city": "West",
      "state": "Texas",
      "country": "US",
      "elevation": 590,
      "lat": 31.7723999023,
      "lon": -97.1322021484,
      "tz": "America\/Chicago"
  },
  "40WI": {
      "icao": "40WI",
      "iata": "",
      "name": "Schubert Airstrip",
      "city": "Centerville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 750,
      "lat": 44.0583000183,
      "lon": -91.4307022095,
      "tz": "America\/Chicago"
  },
  "40XS": {
      "icao": "40XS",
      "iata": "",
      "name": "Breakaway Park Airport",
      "city": "Cedar Park",
      "state": "Texas",
      "country": "US",
      "elevation": 897,
      "lat": 30.517999649,
      "lon": -97.7806015015,
      "tz": "America\/Chicago"
  },
  "41AK": {
      "icao": "41AK",
      "iata": "",
      "name": "Settlers Bay Airstrip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 75,
      "lat": 61.5009002686,
      "lon": -149.639694214,
      "tz": "America\/Anchorage"
  },
  "41AR": {
      "icao": "41AR",
      "iata": "",
      "name": "Ashworth Airport",
      "city": "Arkadelphia",
      "state": "Arkansas",
      "country": "US",
      "elevation": 501,
      "lat": 34.123298645,
      "lon": -93.3497009277,
      "tz": "America\/Chicago"
  },
  "41AZ": {
      "icao": "41AZ",
      "iata": "",
      "name": "Ak Chin Community Airfield",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1210,
      "lat": 32.9861984253,
      "lon": -112.0260009766,
      "tz": "America\/Phoenix"
  },
  "41CA": {
      "icao": "41CA",
      "iata": "",
      "name": "Silver Creek Ranch Airport",
      "city": "Forest Glen",
      "state": "California",
      "country": "US",
      "elevation": 2511,
      "lat": 40.3176002502,
      "lon": -123.2539978027,
      "tz": "America\/Los_Angeles"
  },
  "41CO": {
      "icao": "41CO",
      "iata": "",
      "name": "Flying Dog Ranch Airstrip",
      "city": "Meeker",
      "state": "Colorado",
      "country": "US",
      "elevation": 7020,
      "lat": 39.971111,
      "lon": -107.611111,
      "tz": "America\/Denver"
  },
  "41ID": {
      "icao": "41ID",
      "iata": "",
      "name": "Black'S Airfield",
      "city": "Kuna",
      "state": "Idaho",
      "country": "US",
      "elevation": 2788,
      "lat": 43.471383,
      "lon": -116.326417,
      "tz": "America\/Boise"
  },
  "41IL": {
      "icao": "41IL",
      "iata": "",
      "name": "Alcock RLA Restricted Landing Area",
      "city": "Lindenwood",
      "state": "Illinois",
      "country": "US",
      "elevation": 780,
      "lat": 42.0694999695,
      "lon": -89.0334014893,
      "tz": "America\/Chicago"
  },
  "41IS": {
      "icao": "41IS",
      "iata": "",
      "name": "Rees Field",
      "city": "Grand Ridge",
      "state": "Illinois",
      "country": "US",
      "elevation": 675,
      "lat": 41.204498291,
      "lon": -88.8033981323,
      "tz": "America\/Chicago"
  },
  "41KS": {
      "icao": "41KS",
      "iata": "",
      "name": "Flying Z Ranch Airport",
      "city": "Spring Hill",
      "state": "Kansas",
      "country": "US",
      "elevation": 1075,
      "lat": 38.7355003357,
      "lon": -94.7973022461,
      "tz": "America\/Chicago"
  },
  "41MN": {
      "icao": "41MN",
      "iata": "",
      "name": "Shannon Field",
      "city": "Clinton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 45.4268989563,
      "lon": -96.5895004272,
      "tz": "America\/Chicago"
  },
  "41MO": {
      "icao": "41MO",
      "iata": "",
      "name": "Textor Airport",
      "city": "Willard",
      "state": "Missouri",
      "country": "US",
      "elevation": 1140,
      "lat": 37.3609008789,
      "lon": -93.4957962036,
      "tz": "America\/Chicago"
  },
  "41MT": {
      "icao": "41MT",
      "iata": "",
      "name": "R & R Field",
      "city": "Heron",
      "state": "Montana",
      "country": "US",
      "elevation": 2274,
      "lat": 48.08715,
      "lon": -116.046267,
      "tz": "America\/Denver"
  },
  "41NC": {
      "icao": "41NC",
      "iata": "",
      "name": "Sloop Airport",
      "city": "Kannapolis",
      "state": "North Carolina",
      "country": "US",
      "elevation": 794,
      "lat": 35.5218009949,
      "lon": -80.5220031738,
      "tz": "America\/New_York"
  },
  "41NE": {
      "icao": "41NE",
      "iata": "",
      "name": "Vandersnick Airport",
      "city": "Ewing",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1990,
      "lat": 42.1556015015,
      "lon": -98.5003967285,
      "tz": "America\/Chicago"
  },
  "41NK": {
      "icao": "41NK",
      "iata": "",
      "name": "Winchell Mountain Airport",
      "city": "Millerton",
      "state": "New York",
      "country": "US",
      "elevation": 1141,
      "lat": 41.947778,
      "lon": -73.518055,
      "tz": "America\/New_York"
  },
  "41NY": {
      "icao": "41NY",
      "iata": "",
      "name": "Bonebender Airport",
      "city": "Essex",
      "state": "New York",
      "country": "US",
      "elevation": 336,
      "lat": 44.3124008179,
      "lon": -73.3849029541,
      "tz": "America\/New_York"
  },
  "41OH": {
      "icao": "41OH",
      "iata": "",
      "name": "Sleepy Hollow Airport",
      "city": "New Franklin",
      "state": "Ohio",
      "country": "US",
      "elevation": 1050,
      "lat": 40.7836990356,
      "lon": -81.0989990234,
      "tz": "America\/New_York"
  },
  "41PA": {
      "icao": "41PA",
      "iata": "",
      "name": "Deitch Airport",
      "city": "New Kingstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 420,
      "lat": 40.257900238,
      "lon": -77.0813980103,
      "tz": "America\/New_York"
  },
  "41TA": {
      "icao": "41TA",
      "iata": "",
      "name": "Circle P Ranch Airport",
      "city": "Glen Rose",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 32.2375984192,
      "lon": -97.8780975342,
      "tz": "America\/Chicago"
  },
  "41TN": {
      "icao": "41TN",
      "iata": "",
      "name": "Stonewall Airpark",
      "city": "Milton",
      "state": "Tennessee",
      "country": "US",
      "elevation": 760,
      "lat": 35.9007987976,
      "lon": -86.2071990967,
      "tz": "America\/Chicago"
  },
  "41TS": {
      "icao": "41TS",
      "iata": "",
      "name": "Flying T Ranch Airport",
      "city": "Westminster",
      "state": "Texas",
      "country": "US",
      "elevation": 705,
      "lat": 33.3783988953,
      "lon": -96.4483032227,
      "tz": "America\/Chicago"
  },
  "41TX": {
      "icao": "41TX",
      "iata": "",
      "name": "Henington Airport",
      "city": "Wolfe City",
      "state": "Texas",
      "country": "US",
      "elevation": 645,
      "lat": 33.3748016357,
      "lon": -96.0821990967,
      "tz": "America\/Chicago"
  },
  "41WI": {
      "icao": "41WI",
      "iata": "",
      "name": "Paddock Field",
      "city": "Elkhorn",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 950,
      "lat": 42.7386016846,
      "lon": -88.5798034668,
      "tz": "America\/Chicago"
  },
  "41XS": {
      "icao": "41XS",
      "iata": "",
      "name": "Macho Grande Airport",
      "city": "Taylor",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 30.539100647,
      "lon": -97.4188995361,
      "tz": "America\/Chicago"
  },
  "42AK": {
      "icao": "42AK",
      "iata": "",
      "name": "Wicker Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 314,
      "lat": 61.568901062,
      "lon": -149.4850006104,
      "tz": "America\/Anchorage"
  },
  "42AZ": {
      "icao": "42AZ",
      "iata": "",
      "name": "Orme School Airport",
      "city": "Mayer",
      "state": "Arizona",
      "country": "US",
      "elevation": 3934,
      "lat": 34.4277992249,
      "lon": -112.0650024414,
      "tz": "America\/Phoenix"
  },
  "42CN": {
      "icao": "42CN",
      "iata": "",
      "name": "Peg Field",
      "city": "Reedley",
      "state": "California",
      "country": "US",
      "elevation": 460,
      "lat": 36.7090988159,
      "lon": -119.4049987793,
      "tz": "America\/Los_Angeles"
  },
  "42II": {
      "icao": "42II",
      "iata": "",
      "name": "Norm's Airpark",
      "city": "La Porte",
      "state": "Indiana",
      "country": "US",
      "elevation": 740,
      "lat": 41.6845016479,
      "lon": -86.7677993774,
      "tz": "America\/Chicago"
  },
  "42KY": {
      "icao": "42KY",
      "iata": "",
      "name": "Pirates Cove Airport",
      "city": "Benton",
      "state": "Kentucky",
      "country": "US",
      "elevation": 380,
      "lat": 36.7764015198,
      "lon": -88.2222976685,
      "tz": "America\/Chicago"
  },
  "42MD": {
      "icao": "42MD",
      "iata": "",
      "name": "Herrington Field",
      "city": "Oakland",
      "state": "Maryland",
      "country": "US",
      "elevation": 2470,
      "lat": 39.46829987,
      "lon": -79.4253006,
      "tz": "America\/New_York"
  },
  "42MI": {
      "icao": "42MI",
      "iata": "",
      "name": "Midway Airport",
      "city": "Albion",
      "state": "Michigan",
      "country": "US",
      "elevation": 963,
      "lat": 42.2153015137,
      "lon": -84.8069000244,
      "tz": "America\/Detroit"
  },
  "42MN": {
      "icao": "42MN",
      "iata": "",
      "name": "Kral's Personal Use Landing Field",
      "city": "Corcoran",
      "state": "Minnesota",
      "country": "US",
      "elevation": 990,
      "lat": 45.0840988159,
      "lon": -93.5643997192,
      "tz": "America\/Chicago"
  },
  "42MO": {
      "icao": "42MO",
      "iata": "",
      "name": "Aire Parque Airport",
      "city": "Festus",
      "state": "Missouri",
      "country": "US",
      "elevation": 710,
      "lat": 38.1231002808,
      "lon": -90.388999939,
      "tz": "America\/Chicago"
  },
  "42NC": {
      "icao": "42NC",
      "iata": "",
      "name": "Spring Paths Airport",
      "city": "Spring Hope",
      "state": "North Carolina",
      "country": "US",
      "elevation": 290,
      "lat": 35.9578018188,
      "lon": -78.1706008911,
      "tz": "America\/New_York"
  },
  "42NE": {
      "icao": "42NE",
      "iata": "",
      "name": "Packard Ranch Airport",
      "city": "Arthur",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3524,
      "lat": 41.4343986511,
      "lon": -101.6809997559,
      "tz": "America\/Denver"
  },
  "42NY": {
      "icao": "42NY",
      "iata": "",
      "name": "Walter's Field",
      "city": "East Homer",
      "state": "New York",
      "country": "US",
      "elevation": 1560,
      "lat": 42.6689987183,
      "lon": -76.0561981201,
      "tz": "America\/New_York"
  },
  "42OH": {
      "icao": "42OH",
      "iata": "",
      "name": "Dunn Field",
      "city": "Newton Falls",
      "state": "Ohio",
      "country": "US",
      "elevation": 955,
      "lat": 41.1558990479,
      "lon": -81.0039978027,
      "tz": "America\/New_York"
  },
  "42OR": {
      "icao": "42OR",
      "iata": "",
      "name": "Shotgun Ranch Airstrip",
      "city": "Post",
      "state": "Oregon",
      "country": "US",
      "elevation": 3430,
      "lat": 44.1342010498,
      "lon": -120.3229980469,
      "tz": "America\/Los_Angeles"
  },
  "42TE": {
      "icao": "42TE",
      "iata": "",
      "name": "Ball Airport",
      "city": "Victoria",
      "state": "Texas",
      "country": "US",
      "elevation": 124,
      "lat": 28.8878002167,
      "lon": -97.0211029053,
      "tz": "America\/Chicago"
  },
  "42TN": {
      "icao": "42TN",
      "iata": "",
      "name": "Oliver Landing Airport",
      "city": "Paris",
      "state": "Tennessee",
      "country": "US",
      "elevation": 475,
      "lat": 36.411944,
      "lon": -88.265556,
      "tz": "America\/Chicago"
  },
  "42TX": {
      "icao": "42TX",
      "iata": "",
      "name": "Magee Airport",
      "city": "Wolfe City",
      "state": "Texas",
      "country": "US",
      "elevation": 620,
      "lat": 33.3167991638,
      "lon": -96.0669021606,
      "tz": "America\/Chicago"
  },
  "42VA": {
      "icao": "42VA",
      "iata": "",
      "name": "Virginia Beach Airport",
      "city": "Virginia Beach",
      "state": "Virginia",
      "country": "US",
      "elevation": 15,
      "lat": 36.6781997681,
      "lon": -76.0329971313,
      "tz": "America\/New_York"
  },
  "42VT": {
      "icao": "42VT",
      "iata": "",
      "name": "Frogs End Airport",
      "city": "Shelburne",
      "state": "Vermont",
      "country": "US",
      "elevation": 320,
      "lat": 44.3669013977,
      "lon": -73.1501998901,
      "tz": "America\/New_York"
  },
  "42WA": {
      "icao": "42WA",
      "iata": "",
      "name": "Sheffels Ranch Airport",
      "city": "Wilbur",
      "state": "Washington",
      "country": "US",
      "elevation": 2222,
      "lat": 47.817199707,
      "lon": -118.8010025024,
      "tz": "America\/Los_Angeles"
  },
  "42XS": {
      "icao": "42XS",
      "iata": "",
      "name": "Temple Ranch Airport",
      "city": "Freer",
      "state": "Texas",
      "country": "US",
      "elevation": 490,
      "lat": 27.957444,
      "lon": -98.403889,
      "tz": "America\/Chicago"
  },
  "43AK": {
      "icao": "43AK",
      "iata": "",
      "name": "Kalmbach Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 445,
      "lat": 61.6007995605,
      "lon": -149.578994751,
      "tz": "America\/Anchorage"
  },
  "43AR": {
      "icao": "43AR",
      "iata": "",
      "name": "Haigwood Landing Strip",
      "city": "Newport",
      "state": "Arkansas",
      "country": "US",
      "elevation": 230,
      "lat": 35.6114006042,
      "lon": -91.2414016724,
      "tz": "America\/Chicago"
  },
  "43CA": {
      "icao": "43CA",
      "iata": "",
      "name": "Dick Taylor Airstrip",
      "city": "Kelso",
      "state": "California",
      "country": "US",
      "elevation": 2611,
      "lat": 34.8819007874,
      "lon": -115.733001709,
      "tz": "America\/Los_Angeles"
  },
  "43CL": {
      "icao": "43CL",
      "iata": "",
      "name": "Dick Dale Skyranch Airport",
      "city": "Twentynine Palms",
      "state": "California",
      "country": "US",
      "elevation": 1793,
      "lat": 34.2061004639,
      "lon": -115.9980010986,
      "tz": "America\/Los_Angeles"
  },
  "43CN": {
      "icao": "43CN",
      "iata": "",
      "name": "Mazza Airport",
      "city": "Petaluma",
      "state": "California",
      "country": "US",
      "elevation": 20,
      "lat": 38.1851997375,
      "lon": -122.6019973755,
      "tz": "America\/Los_Angeles"
  },
  "43CO": {
      "icao": "43CO",
      "iata": "",
      "name": "Kostroski Airport",
      "city": "Franktown",
      "state": "Colorado",
      "country": "US",
      "elevation": 6700,
      "lat": 39.3666992188,
      "lon": -104.6780014038,
      "tz": "America\/Denver"
  },
  "43FL": {
      "icao": "43FL",
      "iata": "",
      "name": "Fly'N R Ranch Airport",
      "city": "Umatilla",
      "state": "Florida",
      "country": "US",
      "elevation": 78,
      "lat": 28.9841995239,
      "lon": -81.8170013428,
      "tz": "America\/New_York"
  },
  "43IG": {
      "icao": "43IG",
      "iata": "",
      "name": "Grayland Intergalactic Airport",
      "city": "Grayland",
      "state": "Washington",
      "country": "US",
      "elevation": 280,
      "lat": 46.801528,
      "lon": -124.072944,
      "tz": "America\/Los_Angeles"
  },
  "43IN": {
      "icao": "43IN",
      "iata": "",
      "name": "Hepler Airport",
      "city": "St Wendel",
      "state": "Indiana",
      "country": "US",
      "elevation": 460,
      "lat": 38.1375007629,
      "lon": -87.676399231,
      "tz": "America\/Chicago"
  },
  "43KS": {
      "icao": "43KS",
      "iata": "",
      "name": "Smith Airport",
      "city": "Manhattan",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 39.1018981934,
      "lon": -96.4178009033,
      "tz": "America\/Chicago"
  },
  "43LA": {
      "icao": "43LA",
      "iata": "",
      "name": "Slay'S Airstrip",
      "city": "Belcher",
      "state": "Louisiana",
      "country": "US",
      "elevation": 177,
      "lat": 32.747643,
      "lon": -93.874708,
      "tz": "America\/Chicago"
  },
  "43MI": {
      "icao": "43MI",
      "iata": "",
      "name": "W Gladstone Airport",
      "city": "Gladstone",
      "state": "Michigan",
      "country": "US",
      "elevation": 720,
      "lat": 45.8591003418,
      "lon": -87.1167984009,
      "tz": "America\/Detroit"
  },
  "43MO": {
      "icao": "43MO",
      "iata": "",
      "name": "Riordan Airport",
      "city": "Pleasant Hill",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 38.7832984924,
      "lon": -94.3683013916,
      "tz": "America\/Chicago"
  },
  "43NC": {
      "icao": "43NC",
      "iata": "",
      "name": "Bahnson Airport",
      "city": "Mocksville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 810,
      "lat": 36.0208015442,
      "lon": -80.5164031982,
      "tz": "America\/New_York"
  },
  "43NE": {
      "icao": "43NE",
      "iata": "",
      "name": "Onion Crest Airpark",
      "city": "Riverdale",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2205,
      "lat": 40.7750015259,
      "lon": -99.1547012329,
      "tz": "America\/Chicago"
  },
  "43NY": {
      "icao": "43NY",
      "iata": "",
      "name": "Watercolor Airport",
      "city": "Cherry Valley",
      "state": "New York",
      "country": "US",
      "elevation": 1993,
      "lat": 42.7846984863,
      "lon": -74.8024978638,
      "tz": "America\/New_York"
  },
  "43OA": {
      "icao": "43OA",
      "iata": "",
      "name": "Anderson Airfield",
      "city": "Bellbrook",
      "state": "Ohio",
      "country": "US",
      "elevation": 940,
      "lat": 39.580972,
      "lon": -84.092194,
      "tz": "America\/New_York"
  },
  "43OI": {
      "icao": "43OI",
      "iata": "",
      "name": "Windswept Airport",
      "city": "North Baltimore",
      "state": "Ohio",
      "country": "US",
      "elevation": 710,
      "lat": 41.1986999512,
      "lon": -83.7007980347,
      "tz": "America\/New_York"
  },
  "43OK": {
      "icao": "43OK",
      "iata": "",
      "name": "Biggs Skypatch Airport",
      "city": "Wellston",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1000,
      "lat": 35.6666984558,
      "lon": -96.9835968018,
      "tz": "America\/Chicago"
  },
  "43OR": {
      "icao": "43OR",
      "iata": "",
      "name": "Inspiration Airport",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3400,
      "lat": 44.6225013733,
      "lon": -122.7519989014,
      "tz": "America\/Los_Angeles"
  },
  "43PA": {
      "icao": "43PA",
      "iata": "",
      "name": "Kolb Airport",
      "city": "Spring City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 290,
      "lat": 40.1600990295,
      "lon": -75.5463027954,
      "tz": "America\/New_York"
  },
  "43TA": {
      "icao": "43TA",
      "iata": "",
      "name": "Diamond Seven Ranch Airport",
      "city": "Morgan",
      "state": "Texas",
      "country": "US",
      "elevation": 797,
      "lat": 32.0251998901,
      "lon": -97.6264038086,
      "tz": "America\/Chicago"
  },
  "43TN": {
      "icao": "43TN",
      "iata": "",
      "name": "Roseanne Airport",
      "city": "Morrison",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1050,
      "lat": 35.618056,
      "lon": -86.0025,
      "tz": "America\/Chicago"
  },
  "43TS": {
      "icao": "43TS",
      "iata": "",
      "name": "Fraser Farm Airport",
      "city": "Lindale",
      "state": "Texas",
      "country": "US",
      "elevation": 570,
      "lat": 32.5662002563,
      "lon": -95.4682998657,
      "tz": "America\/Chicago"
  },
  "43TX": {
      "icao": "43TX",
      "iata": "",
      "name": "Mid-Valley Dusters Inc Airport",
      "city": "Alamo",
      "state": "Texas",
      "country": "US",
      "elevation": 91,
      "lat": 26.1530990601,
      "lon": -98.1350021362,
      "tz": "America\/Chicago"
  },
  "43WI": {
      "icao": "43WI",
      "iata": "",
      "name": "Eagle Ridge Ultralightport",
      "city": "Colfax",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1067,
      "lat": 45.031533,
      "lon": -91.7325,
      "tz": "America\/Chicago"
  },
  "44AK": {
      "icao": "44AK",
      "iata": "",
      "name": "West Papoose Lake Airpark",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 230,
      "lat": 61.5334014893,
      "lon": -150.1020050049,
      "tz": "America\/Anchorage"
  },
  "44AZ": {
      "icao": "44AZ",
      "iata": "",
      "name": "Sandhill Ranch Airport",
      "city": "Page",
      "state": "Arizona",
      "country": "US",
      "elevation": 5868,
      "lat": 36.9319000244,
      "lon": -111.9700012207,
      "tz": "America\/Phoenix"
  },
  "44CA": {
      "icao": "44CA",
      "iata": "",
      "name": "W R Byron Airport",
      "city": "Blythe",
      "state": "California",
      "country": "US",
      "elevation": 400,
      "lat": 33.6791992188,
      "lon": -114.6439971924,
      "tz": "America\/Los_Angeles"
  },
  "44CL": {
      "icao": "44CL",
      "iata": "",
      "name": "Cortopassi Airport",
      "city": "Linden",
      "state": "California",
      "country": "US",
      "elevation": 80,
      "lat": 38.0138015747,
      "lon": -121.1259994507,
      "tz": "America\/Los_Angeles"
  },
  "44CO": {
      "icao": "44CO",
      "iata": "",
      "name": "Redlands Airport",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 6100,
      "lat": 38.8367004395,
      "lon": -107.7929992676,
      "tz": "America\/Denver"
  },
  "44GA": {
      "icao": "44GA",
      "iata": "",
      "name": "S&S Flying Service Airport",
      "city": "Pineview",
      "state": "Georgia",
      "country": "US",
      "elevation": 260,
      "lat": 32.1043014526,
      "lon": -83.4829025269,
      "tz": "America\/New_York"
  },
  "44ID": {
      "icao": "44ID",
      "iata": "",
      "name": "Boulder Creek Airstrip",
      "city": "Donnelly",
      "state": "Idaho",
      "country": "US",
      "elevation": 4950,
      "lat": 44.736942,
      "lon": -116.074158,
      "tz": "America\/Boise"
  },
  "44II": {
      "icao": "44II",
      "iata": "",
      "name": "The Wolf Den Airport",
      "city": "Roanoke",
      "state": "Indiana",
      "country": "US",
      "elevation": 815,
      "lat": 40.9530982971,
      "lon": -85.3865966797,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "44IN": {
      "icao": "44IN",
      "iata": "",
      "name": "Rush STOLport",
      "city": "West Lafayette",
      "state": "Indiana",
      "country": "US",
      "elevation": 675,
      "lat": 40.4998016357,
      "lon": -86.9550018311,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "44IS": {
      "icao": "44IS",
      "iata": "",
      "name": "Potter Airport",
      "city": "Barry",
      "state": "Illinois",
      "country": "US",
      "elevation": 700,
      "lat": 39.7448005676,
      "lon": -91.0748977661,
      "tz": "America\/Chicago"
  },
  "44KS": {
      "icao": "44KS",
      "iata": "",
      "name": "Vankirk Airport",
      "city": "Wichita",
      "state": "Kansas",
      "country": "US",
      "elevation": 1350,
      "lat": 37.6102981567,
      "lon": -97.1624984741,
      "tz": "America\/Chicago"
  },
  "44KY": {
      "icao": "44KY",
      "iata": "",
      "name": "Duff Airport",
      "city": "Chavies",
      "state": "Kentucky",
      "country": "US",
      "elevation": 820,
      "lat": 37.3526000977,
      "lon": -83.3423995972,
      "tz": "America\/New_York"
  },
  "44LA": {
      "icao": "44LA",
      "iata": "",
      "name": "B T & K H Ranch Airport",
      "city": "Dry Creek",
      "state": "Louisiana",
      "country": "US",
      "elevation": 110,
      "lat": 30.6473999023,
      "lon": -93.0551986694,
      "tz": "America\/Chicago"
  },
  "44MI": {
      "icao": "44MI",
      "iata": "",
      "name": "Airbatco Field",
      "city": "Mulliken",
      "state": "Michigan",
      "country": "US",
      "elevation": 852,
      "lat": 42.773601532,
      "lon": -84.9000015259,
      "tz": "America\/Detroit"
  },
  "44MO": {
      "icao": "44MO",
      "iata": "",
      "name": "Butch's Strip STOLport",
      "city": "St Joseph",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.7097015381,
      "lon": -94.8022003174,
      "tz": "America\/Chicago"
  },
  "44MU": {
      "icao": "44MU",
      "iata": "",
      "name": "Lynch Field",
      "city": "Granby",
      "state": "Missouri",
      "country": "US",
      "elevation": 1170,
      "lat": 36.9081001282,
      "lon": -94.3099975586,
      "tz": "America\/Chicago"
  },
  "44NC": {
      "icao": "44NC",
      "iata": "",
      "name": "Broadway Airfield",
      "city": "Landis",
      "state": "North Carolina",
      "country": "US",
      "elevation": 650,
      "lat": 35.5433006287,
      "lon": -80.6519012451,
      "tz": "America\/New_York"
  },
  "44ND": {
      "icao": "44ND",
      "iata": "",
      "name": "Fredericks Ranch Airport",
      "city": "Halliday",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2225,
      "lat": 47.5278015137,
      "lon": -102.2409973145,
      "tz": "America\/Denver"
  },
  "44NY": {
      "icao": "44NY",
      "iata": "",
      "name": "Tomcat Airport",
      "city": "Fort Plain",
      "state": "New York",
      "country": "US",
      "elevation": 460,
      "lat": 42.934299469,
      "lon": -74.6523971558,
      "tz": "America\/New_York"
  },
  "44OK": {
      "icao": "44OK",
      "iata": "",
      "name": "Sky High Airport",
      "city": "Crescent",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 35.9542007446,
      "lon": -97.5492019653,
      "tz": "America\/Chicago"
  },
  "44OR": {
      "icao": "44OR",
      "iata": "",
      "name": "Compton Airport",
      "city": "Canby",
      "state": "Oregon",
      "country": "US",
      "elevation": 160,
      "lat": 45.2223014832,
      "lon": -122.7269973755,
      "tz": "America\/Los_Angeles"
  },
  "44PA": {
      "icao": "44PA",
      "iata": "",
      "name": "Pennfield Farm Airport",
      "city": "Spring Mills",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.8501014709,
      "lon": -77.6164016724,
      "tz": "America\/New_York"
  },
  "44TE": {
      "icao": "44TE",
      "iata": "",
      "name": "Shirley Williams Airport",
      "city": "Kingsland",
      "state": "Texas",
      "country": "US",
      "elevation": 880,
      "lat": 30.6835002899,
      "lon": -98.4169998169,
      "tz": "America\/Chicago"
  },
  "44TN": {
      "icao": "44TN",
      "iata": "",
      "name": "Stone Field",
      "city": "Louisville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 890,
      "lat": 35.84090042,
      "lon": -84.07569885,
      "tz": "America\/New_York"
  },
  "44TS": {
      "icao": "44TS",
      "iata": "",
      "name": "Mc David Ranch Airport",
      "city": "Llano",
      "state": "Texas",
      "country": "US",
      "elevation": 1407,
      "lat": 30.5776996613,
      "lon": -98.8337020874,
      "tz": "America\/Chicago"
  },
  "44TX": {
      "icao": "44TX",
      "iata": "",
      "name": "White Oak Airport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 1875,
      "lat": 30.2105007172,
      "lon": -99.0948028564,
      "tz": "America\/Chicago"
  },
  "44VA": {
      "icao": "44VA",
      "iata": "",
      "name": "Big Hill Airport",
      "city": "Eagle Rock",
      "state": "Virginia",
      "country": "US",
      "elevation": 1620,
      "lat": 37.7312011719,
      "lon": -79.8112030029,
      "tz": "America\/New_York"
  },
  "44WI": {
      "icao": "44WI",
      "iata": "",
      "name": "Stoiber Airport",
      "city": "Spencer",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1298,
      "lat": 44.772217,
      "lon": -90.385067,
      "tz": "America\/Chicago"
  },
  "44WY": {
      "icao": "44WY",
      "iata": "",
      "name": "Underwood Airport",
      "city": "Douglas",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4863,
      "lat": 42.8511,
      "lon": -105.496217,
      "tz": "America\/Denver"
  },
  "45AK": {
      "icao": "45AK",
      "iata": "",
      "name": "Cherokee Airport",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1300,
      "lat": 63.9617996216,
      "lon": -145.4700012207,
      "tz": "America\/Anchorage"
  },
  "45AR": {
      "icao": "45AR",
      "iata": "",
      "name": "Thunder Ridge Ranch",
      "city": "Flippin",
      "state": "Arkansas",
      "country": "US",
      "elevation": 789,
      "lat": 36.3671989441,
      "lon": -92.6401977539,
      "tz": "America\/Chicago"
  },
  "45AZ": {
      "icao": "45AZ",
      "iata": "",
      "name": "Pine Springs Airport",
      "city": "Pine Springs",
      "state": "Arizona",
      "country": "US",
      "elevation": 6930,
      "lat": 35.4009017944,
      "lon": -109.2760009766,
      "tz": "America\/Denver"
  },
  "45CL": {
      "icao": "45CL",
      "iata": "",
      "name": "Hell'er High Water Airport",
      "city": "Zenia",
      "state": "California",
      "country": "US",
      "elevation": 2493,
      "lat": 40.1431999207,
      "lon": -123.3980026245,
      "tz": "America\/Los_Angeles"
  },
  "45CN": {
      "icao": "45CN",
      "iata": "",
      "name": "Hermitage Airport",
      "city": "Groveland",
      "state": "California",
      "country": "US",
      "elevation": 2700,
      "lat": 37.8801994324,
      "lon": -120.1940002441,
      "tz": "America\/Los_Angeles"
  },
  "45FL": {
      "icao": "45FL",
      "iata": "",
      "name": "Moss Meadows Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 155,
      "lat": 30.2374000549,
      "lon": -82.90650177,
      "tz": "America\/New_York"
  },
  "45II": {
      "icao": "45II",
      "iata": "",
      "name": "Cummings Field",
      "city": "Rolling Prairie",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 41.6337013245,
      "lon": -86.585899353,
      "tz": "America\/Chicago"
  },
  "45IN": {
      "icao": "45IN",
      "iata": "",
      "name": "Aerobatic Practice Airport",
      "city": "North Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 681,
      "lat": 38.9963989258,
      "lon": -85.7480010986,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "45KS": {
      "icao": "45KS",
      "iata": "",
      "name": "Liebau Ranch Airport",
      "city": "Grenola",
      "state": "Kansas",
      "country": "US",
      "elevation": 1250,
      "lat": 37.4039001465,
      "lon": -96.4533004761,
      "tz": "America\/Chicago"
  },
  "45MI": {
      "icao": "45MI",
      "iata": "",
      "name": "Franklin's Airport",
      "city": "Barbeau",
      "state": "Michigan",
      "country": "US",
      "elevation": 630,
      "lat": 46.2639007568,
      "lon": -84.1264038086,
      "tz": "America\/Detroit"
  },
  "45MN": {
      "icao": "45MN",
      "iata": "",
      "name": "Kaml Airstrip",
      "city": "Euclid",
      "state": "Minnesota",
      "country": "US",
      "elevation": 898,
      "lat": 47.9261016846,
      "lon": -96.6141967773,
      "tz": "America\/Chicago"
  },
  "45MO": {
      "icao": "45MO",
      "iata": "",
      "name": "Waldmeister Farm Airport",
      "city": "St Peters",
      "state": "Missouri",
      "country": "US",
      "elevation": 424,
      "lat": 38.8473014832,
      "lon": -90.638999939,
      "tz": "America\/Chicago"
  },
  "45MT": {
      "icao": "45MT",
      "iata": "",
      "name": "Pester Airport",
      "city": "Hingham",
      "state": "Montana",
      "country": "US",
      "elevation": 3055,
      "lat": 48.628333,
      "lon": -110.469667,
      "tz": "America\/Denver"
  },
  "45NC": {
      "icao": "45NC",
      "iata": "",
      "name": "Glover Airport",
      "city": "Seaboard",
      "state": "North Carolina",
      "country": "US",
      "elevation": 120,
      "lat": 36.5280990601,
      "lon": -77.4546966553,
      "tz": "America\/New_York"
  },
  "45OG": {
      "icao": "45OG",
      "iata": "",
      "name": "Rugg Ranches Airport",
      "city": "Pilot Rock",
      "state": "Oregon",
      "country": "US",
      "elevation": 1890,
      "lat": 45.476389,
      "lon": -118.791667,
      "tz": "America\/Los_Angeles"
  },
  "45OH": {
      "icao": "45OH",
      "iata": "",
      "name": "Boober Airport",
      "city": "New Richmond",
      "state": "Ohio",
      "country": "US",
      "elevation": 854,
      "lat": 38.975994,
      "lon": -84.219511,
      "tz": "America\/New_York"
  },
  "45OI": {
      "icao": "45OI",
      "iata": "",
      "name": "Plane Country Airport",
      "city": "Strasburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 920,
      "lat": 40.5611991882,
      "lon": -81.5457000732,
      "tz": "America\/New_York"
  },
  "45OK": {
      "icao": "45OK",
      "iata": "",
      "name": "Belleview Landing Airport",
      "city": "South Coffeyville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 775,
      "lat": 36.9126014709,
      "lon": -95.6029968262,
      "tz": "America\/Chicago"
  },
  "45OL": {
      "icao": "45OL",
      "iata": "",
      "name": "Stilwell Airport",
      "city": "Stilwell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1125,
      "lat": 35.837600708,
      "lon": -94.6251983643,
      "tz": "America\/Chicago"
  },
  "45OR": {
      "icao": "45OR",
      "iata": "",
      "name": "Benham Airport",
      "city": "Coquille",
      "state": "Oregon",
      "country": "US",
      "elevation": 280,
      "lat": 43.154800415,
      "lon": -124.1709976196,
      "tz": "America\/Los_Angeles"
  },
  "45TE": {
      "icao": "45TE",
      "iata": "",
      "name": "Deussen Field",
      "city": "Ponder",
      "state": "Texas",
      "country": "US",
      "elevation": 790,
      "lat": 33.1917991638,
      "lon": -97.325302124,
      "tz": "America\/Chicago"
  },
  "45TN": {
      "icao": "45TN",
      "iata": "",
      "name": "Darnell's Landings Airport",
      "city": "Church Hill",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1150,
      "lat": 36.5066986084,
      "lon": -82.7117004395,
      "tz": "America\/New_York"
  },
  "45TX": {
      "icao": "45TX",
      "iata": "",
      "name": "West Airpark",
      "city": "West",
      "state": "Texas",
      "country": "US",
      "elevation": 563,
      "lat": 31.7523994446,
      "lon": -97.0982971191,
      "tz": "America\/Chicago"
  },
  "45VA": {
      "icao": "45VA",
      "iata": "",
      "name": "Frog Hollow Farm Airport",
      "city": "West Point",
      "state": "Virginia",
      "country": "US",
      "elevation": 30,
      "lat": 37.695098877,
      "lon": -76.9950027466,
      "tz": "America\/New_York"
  },
  "45WI": {
      "icao": "45WI",
      "iata": "",
      "name": "Windsong Farm Airport",
      "city": "Woodville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1250,
      "lat": 45,
      "lon": -92.2835006714,
      "tz": "America\/Chicago"
  },
  "45WY": {
      "icao": "45WY",
      "iata": "",
      "name": "American Falconry Airport",
      "city": "Casper",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5722,
      "lat": 42.889733,
      "lon": -106.674866,
      "tz": "America\/Denver"
  },
  "45XS": {
      "icao": "45XS",
      "iata": "",
      "name": "Ghost Apache Airport",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 330,
      "lat": 28.1403007507,
      "lon": -98.912902832,
      "tz": "America\/Chicago"
  },
  "46AK": {
      "icao": "46AK",
      "iata": "",
      "name": "Bear Cove Farm Airport",
      "city": "Homer",
      "state": "Alaska",
      "country": "US",
      "elevation": 115,
      "lat": 59.7383003235,
      "lon": -151.0299987793,
      "tz": "America\/Anchorage"
  },
  "46AR": {
      "icao": "46AR",
      "iata": "",
      "name": "Robert Chris Mc Intosh Airport",
      "city": "Sherwood",
      "state": "Arkansas",
      "country": "US",
      "elevation": 242,
      "lat": 34.8461990356,
      "lon": -92.1720962524,
      "tz": "America\/Chicago"
  },
  "46AZ": {
      "icao": "46AZ",
      "iata": "",
      "name": "Pinon Airport",
      "city": "Pinon",
      "state": "Arizona",
      "country": "US",
      "elevation": 6315,
      "lat": 36.0910987854,
      "lon": -110.233001709,
      "tz": "America\/Denver"
  },
  "46CA": {
      "icao": "46CA",
      "iata": "",
      "name": "Rancho Vallecito Airport",
      "city": "Julian",
      "state": "California",
      "country": "US",
      "elevation": 1680,
      "lat": 32.9709014893,
      "lon": -116.408996582,
      "tz": "America\/Los_Angeles"
  },
  "46CN": {
      "icao": "46CN",
      "iata": "",
      "name": "Crystal Airport",
      "city": "Llano",
      "state": "California",
      "country": "US",
      "elevation": 3420,
      "lat": 34.4846992493,
      "lon": -117.8259963989,
      "tz": "America\/Los_Angeles"
  },
  "46CO": {
      "icao": "46CO",
      "iata": "",
      "name": "Huerfano Agricultural Airport",
      "city": "Pueblo",
      "state": "Colorado",
      "country": "US",
      "elevation": 4790,
      "lat": 38.0999984741,
      "lon": -104.4260025024,
      "tz": "America\/Denver"
  },
  "46FD": {
      "icao": "46FD",
      "iata": "",
      "name": "Tater Farms Strip",
      "city": "Hastings",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 29.6679992676,
      "lon": -81.4972991943,
      "tz": "America\/New_York"
  },
  "46IA": {
      "icao": "46IA",
      "iata": "",
      "name": "Todd Field",
      "city": "Ankeny",
      "state": "Iowa",
      "country": "US",
      "elevation": 965,
      "lat": 41.7557983398,
      "lon": -93.5665969849,
      "tz": "America\/Chicago"
  },
  "46IN": {
      "icao": "46IN",
      "iata": "",
      "name": "Ames Field",
      "city": "Plymouth",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 41.3811988831,
      "lon": -86.2228012085,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "46KS": {
      "icao": "46KS",
      "iata": "",
      "name": "Hidden Valley Airport",
      "city": "Valley Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1345,
      "lat": 37.8316993713,
      "lon": -97.3630981445,
      "tz": "America\/Chicago"
  },
  "46LA": {
      "icao": "46LA",
      "iata": "",
      "name": "Davis Plantation Airport",
      "city": "Bastrop",
      "state": "Louisiana",
      "country": "US",
      "elevation": 150,
      "lat": 32.7965011597,
      "lon": -91.9468002319,
      "tz": "America\/Chicago"
  },
  "46LS": {
      "icao": "46LS",
      "iata": "",
      "name": "Venissat Airstrip",
      "city": "Gueydan",
      "state": "Louisiana",
      "country": "US",
      "elevation": 4,
      "lat": 30.086111,
      "lon": -92.532222,
      "tz": "America\/Chicago"
  },
  "46MI": {
      "icao": "46MI",
      "iata": "",
      "name": "Cloud Nine Field",
      "city": "Webberville",
      "state": "Michigan",
      "country": "US",
      "elevation": 910,
      "lat": 42.5461006165,
      "lon": -84.1277999878,
      "tz": "America\/Detroit"
  },
  "46MN": {
      "icao": "46MN",
      "iata": "",
      "name": "Elmer Airport",
      "city": "Evansville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1350,
      "lat": 46.0055007935,
      "lon": -95.6920013428,
      "tz": "America\/Chicago"
  },
  "46MO": {
      "icao": "46MO",
      "iata": "",
      "name": "Sontimer Airport",
      "city": "St. Peters",
      "state": "Missouri",
      "country": "US",
      "elevation": 443,
      "lat": 38.8083992004,
      "lon": -90.6126022339,
      "tz": "America\/Chicago"
  },
  "46MS": {
      "icao": "46MS",
      "iata": "",
      "name": "Circle Bar Ranch Airport",
      "city": "Foxworth",
      "state": "Mississippi",
      "country": "US",
      "elevation": 420,
      "lat": 31.2950000763,
      "lon": -89.9850006104,
      "tz": "America\/Chicago"
  },
  "46NC": {
      "icao": "46NC",
      "iata": "",
      "name": "Brown Field",
      "city": "Marshville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 400,
      "lat": 34.8927993774,
      "lon": -80.373298645,
      "tz": "America\/New_York"
  },
  "46NE": {
      "icao": "46NE",
      "iata": "",
      "name": "Jantzen Airport",
      "city": "Madrid",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3375,
      "lat": 40.8964004517,
      "lon": -101.5049972534,
      "tz": "America\/Denver"
  },
  "46NJ": {
      "icao": "46NJ",
      "iata": "",
      "name": "Perl Acres Airport",
      "city": "Clarksburg",
      "state": "New Jersey",
      "country": "US",
      "elevation": 150,
      "lat": 40.1529006958,
      "lon": -74.4552001953,
      "tz": "America\/New_York"
  },
  "46NY": {
      "icao": "46NY",
      "iata": "",
      "name": "Savannah Agri-Air Airport",
      "city": "Savannah",
      "state": "New York",
      "country": "US",
      "elevation": 465,
      "lat": 43.0187988281,
      "lon": -76.7610015869,
      "tz": "America\/New_York"
  },
  "46OH": {
      "icao": "46OH",
      "iata": "",
      "name": "Wetzl Airport",
      "city": "North Jackson",
      "state": "Ohio",
      "country": "US",
      "elevation": 997,
      "lat": 41.1036987305,
      "lon": -80.826499939,
      "tz": "America\/New_York"
  },
  "46OI": {
      "icao": "46OI",
      "iata": "",
      "name": "Kellers Strip",
      "city": "Sylvania",
      "state": "Ohio",
      "country": "US",
      "elevation": 700,
      "lat": 41.6833992004,
      "lon": -83.7666015625,
      "tz": "America\/New_York"
  },
  "46OK": {
      "icao": "46OK",
      "iata": "",
      "name": "Steinert Lakes Airport",
      "city": "Bison",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1120,
      "lat": 36.2210998535,
      "lon": -97.8444976807,
      "tz": "America\/Chicago"
  },
  "46SC": {
      "icao": "46SC",
      "iata": "",
      "name": "Gaffney Airport",
      "city": "Gaffney",
      "state": "South Carolina",
      "country": "US",
      "elevation": 840,
      "lat": 35.1391983032,
      "lon": -81.695602417,
      "tz": "America\/New_York"
  },
  "46TA": {
      "icao": "46TA",
      "iata": "",
      "name": "Gould Strip",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 287,
      "lat": 28.1261005402,
      "lon": -98.859703064,
      "tz": "America\/Chicago"
  },
  "46TE": {
      "icao": "46TE",
      "iata": "",
      "name": "02 Ranch Airport",
      "city": "Alpine",
      "state": "Texas",
      "country": "US",
      "elevation": 3799,
      "lat": 29.8749008179,
      "lon": -103.6969985962,
      "tz": "America\/Chicago"
  },
  "46TS": {
      "icao": "46TS",
      "iata": "",
      "name": "Lavon North Airport",
      "city": "Dallas",
      "state": "Texas",
      "country": "US",
      "elevation": 565,
      "lat": 33.128200531,
      "lon": -96.5096969604,
      "tz": "America\/Chicago"
  },
  "46TX": {
      "icao": "46TX",
      "iata": "",
      "name": "Phillips Corporation Airport",
      "city": "Angleton",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 29.150800705,
      "lon": -95.4952011108,
      "tz": "America\/Chicago"
  },
  "46VA": {
      "icao": "46VA",
      "iata": "",
      "name": "Byrd Creek Airport",
      "city": "Caledonia",
      "state": "Virginia",
      "country": "US",
      "elevation": 315,
      "lat": 37.8188018799,
      "lon": -78.047203064,
      "tz": "America\/New_York"
  },
  "46XS": {
      "icao": "46XS",
      "iata": "",
      "name": "Windy Hill Airport",
      "city": "Denton",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 33.3111991882,
      "lon": -97.1044998169,
      "tz": "America\/Chicago"
  },
  "47AK": {
      "icao": "47AK",
      "iata": "",
      "name": "Highland Airport",
      "city": "Eagle River",
      "state": "Alaska",
      "country": "US",
      "elevation": 650,
      "lat": 61.2924995422,
      "lon": -149.5330047607,
      "tz": "America\/Anchorage"
  },
  "47AR": {
      "icao": "47AR",
      "iata": "",
      "name": "Mabry Field",
      "city": "Melbourne",
      "state": "Arkansas",
      "country": "US",
      "elevation": 550,
      "lat": 36.043598175,
      "lon": -91.943901062,
      "tz": "America\/Chicago"
  },
  "47AZ": {
      "icao": "47AZ",
      "iata": "",
      "name": "Ray Schnepf Ranch Airport",
      "city": "Queen Creek",
      "state": "Arizona",
      "country": "US",
      "elevation": 1458,
      "lat": 33.2242012024,
      "lon": -111.59400177,
      "tz": "America\/Phoenix"
  },
  "47CO": {
      "icao": "47CO",
      "iata": "",
      "name": "Mile Hi Airport",
      "city": "New Raymer",
      "state": "Colorado",
      "country": "US",
      "elevation": 5270,
      "lat": 40.9000015259,
      "lon": -104.8339996338,
      "tz": "America\/Denver"
  },
  "47FD": {
      "icao": "47FD",
      "iata": "",
      "name": "Strayhorn Ranch Airport",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 26.6627998352,
      "lon": -81.7705993652,
      "tz": "America\/New_York"
  },
  "47FL": {
      "icao": "47FL",
      "iata": "",
      "name": "Flying Exotics Airport",
      "city": "Weirsdale",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 28.9641990662,
      "lon": -81.7789993286,
      "tz": "America\/New_York"
  },
  "47GA": {
      "icao": "47GA",
      "iata": "",
      "name": "Everidge Airport",
      "city": "Pinehurst",
      "state": "Georgia",
      "country": "US",
      "elevation": 450,
      "lat": 32.2196998596,
      "lon": -83.8553009033,
      "tz": "America\/New_York"
  },
  "47II": {
      "icao": "47II",
      "iata": "",
      "name": "Westrick Airport",
      "city": "Liberty Mills",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 41.0561981201,
      "lon": -85.6904983521,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "47IL": {
      "icao": "47IL",
      "iata": "",
      "name": "Godbee RLA Restricted Landing Area",
      "city": "Kempton",
      "state": "Illinois",
      "country": "US",
      "elevation": 694,
      "lat": 40.9506988525,
      "lon": -88.2705993652,
      "tz": "America\/Chicago"
  },
  "47IN": {
      "icao": "47IN",
      "iata": "",
      "name": "Mann Airport",
      "city": "Merom",
      "state": "Indiana",
      "country": "US",
      "elevation": 428,
      "lat": 38.9883995056,
      "lon": -87.5180969238,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "47IS": {
      "icao": "47IS",
      "iata": "",
      "name": "Flying Z Ranch Airport",
      "city": "Durand",
      "state": "Illinois",
      "country": "US",
      "elevation": 945,
      "lat": 42.4916992188,
      "lon": -89.3569030762,
      "tz": "America\/Chicago"
  },
  "47KS": {
      "icao": "47KS",
      "iata": "",
      "name": "Maxwell Airport",
      "city": "Baldwin City",
      "state": "Kansas",
      "country": "US",
      "elevation": 1011,
      "lat": 38.7705993652,
      "lon": -95.2743988037,
      "tz": "America\/Chicago"
  },
  "47MI": {
      "icao": "47MI",
      "iata": "",
      "name": "Kenneth Hayward Airport",
      "city": "Bay City",
      "state": "Michigan",
      "country": "US",
      "elevation": 590,
      "lat": 43.5334014893,
      "lon": -83.8541030884,
      "tz": "America\/Detroit"
  },
  "47MN": {
      "icao": "47MN",
      "iata": "",
      "name": "Sons Private-Commercial Airport",
      "city": "Norwood",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 44.7000007629,
      "lon": -93.866897583,
      "tz": "America\/Chicago"
  },
  "47MO": {
      "icao": "47MO",
      "iata": "",
      "name": "Cliff Scott Airport",
      "city": "Hamilton",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.7402992249,
      "lon": -93.9905014038,
      "tz": "America\/Chicago"
  },
  "47NC": {
      "icao": "47NC",
      "iata": "",
      "name": "Windemere Airport",
      "city": "Fayetteville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 98,
      "lat": 35.1380996704,
      "lon": -78.8227996826,
      "tz": "America\/New_York"
  },
  "47NY": {
      "icao": "47NY",
      "iata": "",
      "name": "Elk Creek Airport",
      "city": "Borden",
      "state": "New York",
      "country": "US",
      "elevation": 1540,
      "lat": 42.0449981689,
      "lon": -77.3499984741,
      "tz": "America\/New_York"
  },
  "47OH": {
      "icao": "47OH",
      "iata": "",
      "name": "Chapin Airport",
      "city": "Norwalk",
      "state": "Ohio",
      "country": "US",
      "elevation": 885,
      "lat": 41.2000999451,
      "lon": -82.5735015869,
      "tz": "America\/New_York"
  },
  "47OK": {
      "icao": "47OK",
      "iata": "",
      "name": "Harman Airport",
      "city": "Calumet",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1345,
      "lat": 35.5836982727,
      "lon": -98.0401000977,
      "tz": "America\/Chicago"
  },
  "47OR": {
      "icao": "47OR",
      "iata": "",
      "name": "Mc Ranch Airport",
      "city": "Adel",
      "state": "Oregon",
      "country": "US",
      "elevation": 4555,
      "lat": 42.1649017334,
      "lon": -119.904998779,
      "tz": "America\/Los_Angeles"
  },
  "47PA": {
      "icao": "47PA",
      "iata": "",
      "name": "Homan Airport",
      "city": "State College",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.7167015076,
      "lon": -77.9831008911,
      "tz": "America\/New_York"
  },
  "47TA": {
      "icao": "47TA",
      "iata": "",
      "name": "Pleasure Field",
      "city": "Prosper",
      "state": "Texas",
      "country": "US",
      "elevation": 604,
      "lat": 33.2560005188,
      "lon": -96.8852996826,
      "tz": "America\/Chicago"
  },
  "47TE": {
      "icao": "47TE",
      "iata": "",
      "name": "Whites Airport",
      "city": "La Ward",
      "state": "Texas",
      "country": "US",
      "elevation": 43,
      "lat": 28.8516998291,
      "lon": -96.4974975586,
      "tz": "America\/Chicago"
  },
  "47TS": {
      "icao": "47TS",
      "iata": "",
      "name": "Hensarling Airport",
      "city": "Madisonville",
      "state": "Texas",
      "country": "US",
      "elevation": 315,
      "lat": 31.0480003357,
      "lon": -95.9177017212,
      "tz": "America\/Chicago"
  },
  "47TX": {
      "icao": "47TX",
      "iata": "",
      "name": "Armstrong Ranch Airport",
      "city": "Armstrong",
      "state": "Texas",
      "country": "US",
      "elevation": 27,
      "lat": 26.9339008331,
      "lon": -97.7624969482,
      "tz": "America\/Chicago"
  },
  "47WI": {
      "icao": "47WI",
      "iata": "",
      "name": "Oakbrook Airport",
      "city": "Fort Atkinson",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 840,
      "lat": 42.8899993896,
      "lon": -88.8081970215,
      "tz": "America\/Chicago"
  },
  "47XS": {
      "icao": "47XS",
      "iata": "",
      "name": "Bartos Farm Airport",
      "city": "Lubbock",
      "state": "Texas",
      "country": "US",
      "elevation": 3153,
      "lat": 33.4724998474,
      "lon": -101.7850036621,
      "tz": "America\/Chicago"
  },
  "48AK": {
      "icao": "48AK",
      "iata": "",
      "name": "Castle Mountain Airstrip",
      "city": "Sutton\/Chickaloon",
      "state": "Alaska",
      "country": "US",
      "elevation": 1010,
      "lat": 61.7960412696,
      "lon": -148.494161367,
      "tz": "America\/Anchorage"
  },
  "48AZ": {
      "icao": "48AZ",
      "iata": "",
      "name": "Rimrock Airport",
      "city": "Rimrock",
      "state": "Arizona",
      "country": "US",
      "elevation": 3575,
      "lat": 34.6509017944,
      "lon": -111.7880020142,
      "tz": "America\/Phoenix"
  },
  "48CN": {
      "icao": "48CN",
      "iata": "",
      "name": "Sallaberry Ranch Strip",
      "city": "Madera",
      "state": "California",
      "country": "US",
      "elevation": 263,
      "lat": 37.0572013855,
      "lon": -120.1439971924,
      "tz": "America\/Los_Angeles"
  },
  "48CO": {
      "icao": "48CO",
      "iata": "",
      "name": "Shaull Farm Airstrip",
      "city": "Nunn",
      "state": "Colorado",
      "country": "US",
      "elevation": 5235,
      "lat": 40.7215995789,
      "lon": -104.7779998779,
      "tz": "America\/Denver"
  },
  "48FL": {
      "icao": "48FL",
      "iata": "",
      "name": "Mc Kinnon Airpark",
      "city": "Walnut Hill",
      "state": "Florida",
      "country": "US",
      "elevation": 227,
      "lat": 30.8141002655,
      "lon": -87.4749984741,
      "tz": "America\/Chicago"
  },
  "48II": {
      "icao": "48II",
      "iata": "",
      "name": "Cedar Creek Airport",
      "city": "Cedarville",
      "state": "Indiana",
      "country": "US",
      "elevation": 792,
      "lat": 41.1903991699,
      "lon": -85.0242004395,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "48IL": {
      "icao": "48IL",
      "iata": "",
      "name": "Lake West Wind Airport",
      "city": "Godfrey",
      "state": "Illinois",
      "country": "US",
      "elevation": 601,
      "lat": 38.9700012207,
      "lon": -90.2472000122,
      "tz": "America\/Chicago"
  },
  "48IS": {
      "icao": "48IS",
      "iata": "",
      "name": "P.J. Killian Airport",
      "city": "Lexington",
      "state": "Illinois",
      "country": "US",
      "elevation": 757,
      "lat": 40.6021995544,
      "lon": -88.8333969116,
      "tz": "America\/Chicago"
  },
  "48KS": {
      "icao": "48KS",
      "iata": "",
      "name": "R F Roesner Airport",
      "city": "Salina",
      "state": "Kansas",
      "country": "US",
      "elevation": 1230,
      "lat": 38.8111000061,
      "lon": -97.4313964844,
      "tz": "America\/Chicago"
  },
  "48LL": {
      "icao": "48LL",
      "iata": "",
      "name": "Rock Cut Farms Airport",
      "city": "Leaf River",
      "state": "Illinois",
      "country": "US",
      "elevation": 812,
      "lat": 42.1253013611,
      "lon": -89.4589996338,
      "tz": "America\/Chicago"
  },
  "48MD": {
      "icao": "48MD",
      "iata": "",
      "name": "Le Champ Airport",
      "city": "Princess Anne",
      "state": "Maryland",
      "country": "US",
      "elevation": 14,
      "lat": 38.149167,
      "lon": -75.662222,
      "tz": "America\/New_York"
  },
  "48NC": {
      "icao": "48NC",
      "iata": "",
      "name": "Morrison Field",
      "city": "Lexington",
      "state": "North Carolina",
      "country": "US",
      "elevation": 825,
      "lat": 35.7817001343,
      "lon": -80.4169006348,
      "tz": "America\/New_York"
  },
  "48ND": {
      "icao": "48ND",
      "iata": "",
      "name": "Schirmeister Private Airport",
      "city": "Hazelton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1650,
      "lat": 46.5125007629,
      "lon": -100.5500030518,
      "tz": "America\/Chicago"
  },
  "48OH": {
      "icao": "48OH",
      "iata": "",
      "name": "Dechant Farms Airport",
      "city": "Oberlin",
      "state": "Ohio",
      "country": "US",
      "elevation": 800,
      "lat": 41.2541999817,
      "lon": -82.1970977783,
      "tz": "America\/New_York"
  },
  "48OK": {
      "icao": "48OK",
      "iata": "",
      "name": "Rafter 'R' Ranch Airport",
      "city": "Checotah",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 620,
      "lat": 35.3181991577,
      "lon": -95.5183029175,
      "tz": "America\/Chicago"
  },
  "48OR": {
      "icao": "48OR",
      "iata": "",
      "name": "Lookingglass Airport",
      "city": "Roseburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 580,
      "lat": 43.1593017578,
      "lon": -123.5019989014,
      "tz": "America\/Los_Angeles"
  },
  "48TE": {
      "icao": "48TE",
      "iata": "",
      "name": "4M Ranch Airfield",
      "city": "Langtry",
      "state": "Texas",
      "country": "US",
      "elevation": 1824,
      "lat": 30.021,
      "lon": -101.573056,
      "tz": "America\/Chicago"
  },
  "48TS": {
      "icao": "48TS",
      "iata": "",
      "name": "Fox Airport",
      "city": "New Braunfels",
      "state": "Texas",
      "country": "US",
      "elevation": 650,
      "lat": 29.6676998138,
      "lon": -98.1988983154,
      "tz": "America\/Chicago"
  },
  "48TX": {
      "icao": "48TX",
      "iata": "",
      "name": "Tri-County Aerodrome",
      "city": "Trenton",
      "state": "Texas",
      "country": "US",
      "elevation": 790,
      "lat": 33.4542999268,
      "lon": -96.3815994263,
      "tz": "America\/Chicago"
  },
  "48VA": {
      "icao": "48VA",
      "iata": "",
      "name": "Al's Field",
      "city": "Gore",
      "state": "Virginia",
      "country": "US",
      "elevation": 1120,
      "lat": 39.2906990051,
      "lon": -78.3507995605,
      "tz": "America\/New_York"
  },
  "48WA": {
      "icao": "48WA",
      "iata": "",
      "name": "West Valley Airport",
      "city": "Yakima",
      "state": "Washington",
      "country": "US",
      "elevation": 1900,
      "lat": 46.5625,
      "lon": -120.7770004272,
      "tz": "America\/Los_Angeles"
  },
  "48WI": {
      "icao": "48WI",
      "iata": "",
      "name": "Circle A Ranch Airport",
      "city": "Hazelhurst",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1640,
      "lat": 45.793800354,
      "lon": -89.7301025391,
      "tz": "America\/Chicago"
  },
  "48XS": {
      "icao": "48XS",
      "iata": "",
      "name": "Maravillas Gap Ranch Airport",
      "city": "Marathon",
      "state": "Texas",
      "country": "US",
      "elevation": 3325,
      "lat": 29.9265995026,
      "lon": -103.2949981689,
      "tz": "America\/Chicago"
  },
  "49AK": {
      "icao": "49AK",
      "iata": "",
      "name": "Secluded Lake Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 62.0245018005,
      "lon": -149.9770050049,
      "tz": "America\/Anchorage"
  },
  "49AZ": {
      "icao": "49AZ",
      "iata": "",
      "name": "Rock Point Airport",
      "city": "Rock Point",
      "state": "Arizona",
      "country": "US",
      "elevation": 4999,
      "lat": 36.7318992615,
      "lon": -109.6190032959,
      "tz": "America\/Denver"
  },
  "49CL": {
      "icao": "49CL",
      "iata": "",
      "name": "El Peco Ranch Airport",
      "city": "Madera",
      "state": "California",
      "country": "US",
      "elevation": 213,
      "lat": 36.9054985046,
      "lon": -120.1770019531,
      "tz": "America\/Los_Angeles"
  },
  "49CN": {
      "icao": "49CN",
      "iata": "",
      "name": "Rancho Tehama Airport",
      "city": "Red Bluff",
      "state": "California",
      "country": "US",
      "elevation": 550,
      "lat": 40.0159988403,
      "lon": -122.3899993896,
      "tz": "America\/Los_Angeles"
  },
  "49CO": {
      "icao": "49CO",
      "iata": "",
      "name": "Air Dusters Inc Airport",
      "city": "Roggen",
      "state": "Colorado",
      "country": "US",
      "elevation": 4780,
      "lat": 40.0932998657,
      "lon": -104.375,
      "tz": "America\/Denver"
  },
  "49FD": {
      "icao": "49FD",
      "iata": "",
      "name": "Watson Farm Airport",
      "city": "Quincy",
      "state": "Florida",
      "country": "US",
      "elevation": 275,
      "lat": 30.611944,
      "lon": -84.641667,
      "tz": "America\/New_York"
  },
  "49FL": {
      "icao": "49FL",
      "iata": "",
      "name": "Mike's Ag Air Airport",
      "city": "Walnut Hill",
      "state": "Florida",
      "country": "US",
      "elevation": 210,
      "lat": 30.9771003723,
      "lon": -87.4950027466,
      "tz": "America\/Chicago"
  },
  "49II": {
      "icao": "49II",
      "iata": "",
      "name": "Reinoehl Field",
      "city": "Lena",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 39.6097984314,
      "lon": -87.015296936,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "49IL": {
      "icao": "49IL",
      "iata": "",
      "name": "Walnut Creek Airport",
      "city": "Somonauk",
      "state": "Illinois",
      "country": "US",
      "elevation": 666,
      "lat": 41.5932998657,
      "lon": -88.6917037964,
      "tz": "America\/Chicago"
  },
  "49IN": {
      "icao": "49IN",
      "iata": "",
      "name": "Drake Airport",
      "city": "Sullivan",
      "state": "Indiana",
      "country": "US",
      "elevation": 500,
      "lat": 39.1316986084,
      "lon": -87.3820037842,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "49KS": {
      "icao": "49KS",
      "iata": "",
      "name": "N & N Airport",
      "city": "Manhattan",
      "state": "Kansas",
      "country": "US",
      "elevation": 1230,
      "lat": 39.214099884,
      "lon": -96.6723022461,
      "tz": "America\/Chicago"
  },
  "49MN": {
      "icao": "49MN",
      "iata": "",
      "name": "Willow Ridge Airport",
      "city": "Hastings",
      "state": "Minnesota",
      "country": "US",
      "elevation": 839,
      "lat": 44.6719017029,
      "lon": -92.8263015747,
      "tz": "America\/Chicago"
  },
  "49NE": {
      "icao": "49NE",
      "iata": "",
      "name": "Harden Airstrip",
      "city": "North Platte",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3006,
      "lat": 41.0652999878,
      "lon": -100.7279968262,
      "tz": "America\/Chicago"
  },
  "49NY": {
      "icao": "49NY",
      "iata": "",
      "name": "Westmoreland Airport",
      "city": "Shelter Island",
      "state": "New York",
      "country": "US",
      "elevation": 24,
      "lat": 41.0667991638,
      "lon": -72.3495025635,
      "tz": "America\/New_York"
  },
  "49OH": {
      "icao": "49OH",
      "iata": "",
      "name": "Gilbert Airport",
      "city": "Olmsted Falls",
      "state": "Ohio",
      "country": "US",
      "elevation": 790,
      "lat": 41.3666992188,
      "lon": -81.9664993286,
      "tz": "America\/New_York"
  },
  "49OR": {
      "icao": "49OR",
      "iata": "",
      "name": "Land's Inn Ranch Airport",
      "city": "Dayville",
      "state": "Oregon",
      "country": "US",
      "elevation": 3880,
      "lat": 44.5937995911,
      "lon": -119.5640029907,
      "tz": "America\/Los_Angeles"
  },
  "49PA": {
      "icao": "49PA",
      "iata": "",
      "name": "Gap View Airport",
      "city": "Stone Church",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 780,
      "lat": 40.8592987061,
      "lon": -75.1337966919,
      "tz": "America\/New_York"
  },
  "49TA": {
      "icao": "49TA",
      "iata": "",
      "name": "Roeder Airport",
      "city": "Crosby",
      "state": "Texas",
      "country": "US",
      "elevation": 49,
      "lat": 29.8966007233,
      "lon": -95.0174026489,
      "tz": "America\/Chicago"
  },
  "49TE": {
      "icao": "49TE",
      "iata": "",
      "name": "Stowers Ranch Airport",
      "city": "Hunt",
      "state": "Texas",
      "country": "US",
      "elevation": 2002,
      "lat": 30.0576992035,
      "lon": -99.512802124,
      "tz": "America\/Chicago"
  },
  "49WA": {
      "icao": "49WA",
      "iata": "",
      "name": "Cougar Mountain Airfield",
      "city": "Yelm",
      "state": "Washington",
      "country": "US",
      "elevation": 470,
      "lat": 46.8470001221,
      "lon": -122.5220031738,
      "tz": "America\/Los_Angeles"
  },
  "49WI": {
      "icao": "49WI",
      "iata": "",
      "name": "Tri-Center Airport",
      "city": "Loganville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1225,
      "lat": 43.3791999817,
      "lon": -90.0154037476,
      "tz": "America\/Chicago"
  },
  "49XS": {
      "icao": "49XS",
      "iata": "",
      "name": "McCasland Ranch Airport",
      "city": "Star",
      "state": "Texas",
      "country": "US",
      "elevation": 1370,
      "lat": 31.448333,
      "lon": -98.321111,
      "tz": "America\/Chicago"
  },
  "4AK1": {
      "icao": "4AK1",
      "iata": "",
      "name": "Inigok Airport",
      "city": "Deadhorse",
      "state": "Alaska",
      "country": "US",
      "elevation": 192,
      "lat": 70.0037994385,
      "lon": -153.0780029297,
      "tz": "America\/Anchorage"
  },
  "4AK2": {
      "icao": "4AK2",
      "iata": "",
      "name": "St John Homestead Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 250,
      "lat": 61.5579986572,
      "lon": -149.4239959717,
      "tz": "America\/Anchorage"
  },
  "4AK3": {
      "icao": "4AK3",
      "iata": "",
      "name": "Long Lake Airport",
      "city": "Long Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 1500,
      "lat": 61.3778991699,
      "lon": -143.3260040283,
      "tz": "America\/Anchorage"
  },
  "4AK5": {
      "icao": "4AK5",
      "iata": "",
      "name": "Mankomen Lake Airport",
      "city": "Mankomen Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 3050,
      "lat": 62.9898986816,
      "lon": -144.4799957275,
      "tz": "America\/Anchorage"
  },
  "4AK6": {
      "icao": "4AK6",
      "iata": "",
      "name": "Wolf Lake Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 572,
      "lat": 61.6409988403,
      "lon": -149.2890014648,
      "tz": "America\/Anchorage"
  },
  "4AK7": {
      "icao": "4AK7",
      "iata": "",
      "name": "Aleknagik Mission Lodge Airport",
      "city": "Aleknagik",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 59.2808990479,
      "lon": -158.597000122,
      "tz": "America\/Anchorage"
  },
  "4AK8": {
      "icao": "4AK8",
      "iata": "",
      "name": "Napaimute Pioneer Airfield",
      "city": "Napaimute",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 61.5363006592,
      "lon": -158.7389984131,
      "tz": "America\/Anchorage"
  },
  "4AK9": {
      "icao": "4AK9",
      "iata": "",
      "name": "Tibbetts Airport",
      "city": "Naknek",
      "state": "Alaska",
      "country": "US",
      "elevation": 50,
      "lat": 58.7342987061,
      "lon": -157.0070037842,
      "tz": "America\/Anchorage"
  },
  "4AL2": {
      "icao": "4AL2",
      "iata": "",
      "name": "Irwin Farms Airport",
      "city": "Foley",
      "state": "Alabama",
      "country": "US",
      "elevation": 25,
      "lat": 30.3731994629,
      "lon": -87.7256011963,
      "tz": "America\/Chicago"
  },
  "4AL6": {
      "icao": "4AL6",
      "iata": "",
      "name": "Bonner Field",
      "city": "Summerdale",
      "state": "Alabama",
      "country": "US",
      "elevation": 105,
      "lat": 30.4668998718,
      "lon": -87.6832962036,
      "tz": "America\/Chicago"
  },
  "4AL7": {
      "icao": "4AL7",
      "iata": "",
      "name": "Leon's Landing Airport",
      "city": "Flat Rock",
      "state": "Alabama",
      "country": "US",
      "elevation": 1430,
      "lat": 34.7447013855,
      "lon": -85.6897964478,
      "tz": "America\/Chicago"
  },
  "4AL8": {
      "icao": "4AL8",
      "iata": "",
      "name": "Milton Airport",
      "city": "Huntsville",
      "state": "Alabama",
      "country": "US",
      "elevation": 639,
      "lat": 34.4906005859,
      "lon": -86.6733016968,
      "tz": "America\/Chicago"
  },
  "4AL9": {
      "icao": "4AL9",
      "iata": "",
      "name": "T W Spear Memorial Airport",
      "city": "Lapine",
      "state": "Alabama",
      "country": "US",
      "elevation": 438,
      "lat": 31.9747009277,
      "lon": -86.2938995361,
      "tz": "America\/Chicago"
  },
  "4AR1": {
      "icao": "4AR1",
      "iata": "",
      "name": "Shurley Field Airport",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 430,
      "lat": 35.0413017273,
      "lon": -92.1009979248,
      "tz": "America\/Chicago"
  },
  "4AR2": {
      "icao": "4AR2",
      "iata": "",
      "name": "Red Oak Airport",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 315,
      "lat": 34.9099998474,
      "lon": -92.0066986084,
      "tz": "America\/Chicago"
  },
  "4AR4": {
      "icao": "4AR4",
      "iata": "",
      "name": "Reed-Joseph Land Co. Airport",
      "city": "Garland",
      "state": "Arkansas",
      "country": "US",
      "elevation": 227,
      "lat": 33.3479003906,
      "lon": -93.7315979004,
      "tz": "America\/Chicago"
  },
  "4AR5": {
      "icao": "4AR5",
      "iata": "",
      "name": "Lawrence Field",
      "city": "Cherry Valley",
      "state": "Arkansas",
      "country": "US",
      "elevation": 284,
      "lat": 35.3708000183,
      "lon": -90.7506027222,
      "tz": "America\/Chicago"
  },
  "4AR6": {
      "icao": "4AR6",
      "iata": "",
      "name": "Williams Ranch Airport",
      "city": "Caddo Gap",
      "state": "Arkansas",
      "country": "US",
      "elevation": 750,
      "lat": 34.3792991638,
      "lon": -93.6377029419,
      "tz": "America\/Chicago"
  },
  "4AR7": {
      "icao": "4AR7",
      "iata": "",
      "name": "Worth James Ranch Airport",
      "city": "Booneville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 650,
      "lat": 35.0834007263,
      "lon": -94.116897583,
      "tz": "America\/Chicago"
  },
  "4AR9": {
      "icao": "4AR9",
      "iata": "",
      "name": "Scudder Airport",
      "city": "Jonesboro",
      "state": "Arkansas",
      "country": "US",
      "elevation": 238,
      "lat": 35.7761993408,
      "lon": -90.6467971802,
      "tz": "America\/Chicago"
  },
  "4AZ0": {
      "icao": "4AZ0",
      "iata": "",
      "name": "Palm Valley Tucson Airport",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 2139,
      "lat": 32.2989997864,
      "lon": -111.3170013428,
      "tz": "America\/Phoenix"
  },
  "4AZ2": {
      "icao": "4AZ2",
      "iata": "",
      "name": "Block Ranch Airport",
      "city": "Capitan",
      "state": "New Mexico",
      "country": "US",
      "elevation": 5966,
      "lat": 33.7019996643,
      "lon": -105.3840026855,
      "tz": "America\/Denver"
  },
  "4AZ6": {
      "icao": "4AZ6",
      "iata": "",
      "name": "Parsons Field",
      "city": "Kingman",
      "state": "Arizona",
      "country": "US",
      "elevation": 3760,
      "lat": 34.9981002808,
      "lon": -113.4619979858,
      "tz": "America\/Phoenix"
  },
  "4AZ7": {
      "icao": "4AZ7",
      "iata": "",
      "name": "San Carlos Airport",
      "city": "San Carlos",
      "state": "Arizona",
      "country": "US",
      "elevation": 2896,
      "lat": 33.3777999878,
      "lon": -110.4619979858,
      "tz": "America\/Phoenix"
  },
  "4AZ8": {
      "icao": "4AZ8",
      "iata": "",
      "name": "The Ultralight Strip Ultralightport",
      "city": "Marana",
      "state": "Arizona",
      "country": "US",
      "elevation": 2120,
      "lat": 32.2947006226,
      "lon": -111.34400177,
      "tz": "America\/Phoenix"
  },
  "4AZ9": {
      "icao": "4AZ9",
      "iata": "",
      "name": "Leroy Airport",
      "city": "Willcox",
      "state": "Arizona",
      "country": "US",
      "elevation": 4197,
      "lat": 32.1130981445,
      "lon": -109.7620010376,
      "tz": "America\/Phoenix"
  },
  "4CA2": {
      "icao": "4CA2",
      "iata": "",
      "name": "Funny Farm Airport",
      "city": "Brentwood",
      "state": "California",
      "country": "US",
      "elevation": 29,
      "lat": 37.9468994141,
      "lon": -121.6470031738,
      "tz": "America\/Los_Angeles"
  },
  "4CA7": {
      "icao": "4CA7",
      "iata": "",
      "name": "Johnsen Airport",
      "city": "Chico",
      "state": "California",
      "country": "US",
      "elevation": 190,
      "lat": 39.6707000732,
      "lon": -121.8860015869,
      "tz": "America\/Los_Angeles"
  },
  "4CA8": {
      "icao": "4CA8",
      "iata": "",
      "name": "Yandell Ranch Airport",
      "city": "Modesto",
      "state": "California",
      "country": "US",
      "elevation": 40,
      "lat": 37.640499115,
      "lon": -121.1620025635,
      "tz": "America\/Los_Angeles"
  },
  "4CL3": {
      "icao": "4CL3",
      "iata": "",
      "name": "Antelope Valley Ranch Airport",
      "city": "Williams",
      "state": "California",
      "country": "US",
      "elevation": 530,
      "lat": 39.1463012695,
      "lon": -122.3539962769,
      "tz": "America\/Los_Angeles"
  },
  "4CL4": {
      "icao": "4CL4",
      "iata": "",
      "name": "Rabbit Ranch Airport",
      "city": "Lucerne",
      "state": "California",
      "country": "US",
      "elevation": 2942,
      "lat": 34.4543991089,
      "lon": -117.0260009766,
      "tz": "America\/Los_Angeles"
  },
  "4CO0": {
      "icao": "4CO0",
      "iata": "",
      "name": "Glen-Aspen Airport",
      "city": "Carbondale",
      "state": "Colorado",
      "country": "US",
      "elevation": 6835,
      "lat": 39.3889007568,
      "lon": -107.1600036621,
      "tz": "America\/Denver"
  },
  "4CO2": {
      "icao": "4CO2",
      "iata": "",
      "name": "Owl Canyon Gliderport",
      "city": "Wellington",
      "state": "Colorado",
      "country": "US",
      "elevation": 5545,
      "lat": 40.8046989441,
      "lon": -104.9850006104,
      "tz": "America\/Denver"
  },
  "4CO3": {
      "icao": "4CO3",
      "iata": "",
      "name": "Griffin Field",
      "city": "Walsh",
      "state": "Colorado",
      "country": "US",
      "elevation": 3964,
      "lat": 37.3917007446,
      "lon": -102.2799987793,
      "tz": "America\/Denver"
  },
  "4CO7": {
      "icao": "4CO7",
      "iata": "",
      "name": "Ambrosich Field",
      "city": "Elbert",
      "state": "Colorado",
      "country": "US",
      "elevation": 7028,
      "lat": 39.2178001404,
      "lon": -104.6800003052,
      "tz": "America\/Denver"
  },
  "4CO8": {
      "icao": "4CO8",
      "iata": "",
      "name": "Kelgun Airport",
      "city": "Castle Rock",
      "state": "Colorado",
      "country": "US",
      "elevation": 6800,
      "lat": 39.3069000244,
      "lon": -104.8160018921,
      "tz": "America\/Denver"
  },
  "4FA3": {
      "icao": "4FA3",
      "iata": "",
      "name": "Tranquility Bay Strip",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 26.6434001923,
      "lon": -82.1178970337,
      "tz": "America\/New_York"
  },
  "4FD2": {
      "icao": "4FD2",
      "iata": "",
      "name": "Flying 'F' Farms Airport",
      "city": "O'Brien",
      "state": "Florida",
      "country": "US",
      "elevation": 40,
      "lat": 30.0538005829,
      "lon": -83.018699646,
      "tz": "America\/New_York"
  },
  "4FD3": {
      "icao": "4FD3",
      "iata": "",
      "name": "The Wright Place STOLport",
      "city": "Homestead",
      "state": "Florida",
      "country": "US",
      "elevation": 13,
      "lat": 25.5153007507,
      "lon": -80.5134963989,
      "tz": "America\/New_York"
  },
  "4FD7": {
      "icao": "4FD7",
      "iata": "",
      "name": "Flanders Field",
      "city": "Polk City",
      "state": "Florida",
      "country": "US",
      "elevation": 131,
      "lat": 28.2063999176,
      "lon": -81.8279037476,
      "tz": "America\/New_York"
  },
  "4FL0": {
      "icao": "4FL0",
      "iata": "",
      "name": "Turkey Scratch Plantation Airport",
      "city": "Lamont",
      "state": "Florida",
      "country": "US",
      "elevation": 115,
      "lat": 30.4036006927,
      "lon": -83.8099975586,
      "tz": "America\/New_York"
  },
  "4FL1": {
      "icao": "4FL1",
      "iata": "",
      "name": "Escape Ranch Airport",
      "city": "Kenansville",
      "state": "Florida",
      "country": "US",
      "elevation": 71,
      "lat": 27.8628005981,
      "lon": -80.9580993652,
      "tz": "America\/New_York"
  },
  "4FL2": {
      "icao": "4FL2",
      "iata": "",
      "name": "Ko-Kee Airport",
      "city": "Bushnell",
      "state": "Florida",
      "country": "US",
      "elevation": 72,
      "lat": 28.5949993134,
      "lon": -82.1498031616,
      "tz": "America\/New_York"
  },
  "4FL3": {
      "icao": "4FL3",
      "iata": "",
      "name": "Fellsmere Airport",
      "city": "Fellsmere",
      "state": "Florida",
      "country": "US",
      "elevation": 24,
      "lat": 27.7416992188,
      "lon": -80.6661987305,
      "tz": "America\/New_York"
  },
  "4FL4": {
      "icao": "4FL4",
      "iata": "",
      "name": "Darrah Air Park",
      "city": "Bell",
      "state": "Florida",
      "country": "US",
      "elevation": 54,
      "lat": 29.7782993317,
      "lon": -82.8904037476,
      "tz": "America\/New_York"
  },
  "4FL5": {
      "icao": "4FL5",
      "iata": "",
      "name": "Ridge Landing Airport",
      "city": "Frostproof",
      "state": "Florida",
      "country": "US",
      "elevation": 140,
      "lat": 27.7572002411,
      "lon": -81.6006011963,
      "tz": "America\/New_York"
  },
  "4FL6": {
      "icao": "4FL6",
      "iata": "",
      "name": "Royal Trails Airport",
      "city": "Cassia",
      "state": "Florida",
      "country": "US",
      "elevation": 49,
      "lat": 28.9377994537,
      "lon": -81.4737014771,
      "tz": "America\/New_York"
  },
  "4FL8": {
      "icao": "4FL8",
      "iata": "",
      "name": "Schwartz Farms Inc Airport",
      "city": "Sarasota",
      "state": "Florida",
      "country": "US",
      "elevation": 53,
      "lat": 27.3437004089,
      "lon": -82.3172988892,
      "tz": "America\/New_York"
  },
  "4FL9": {
      "icao": "4FL9",
      "iata": "",
      "name": "Gore Airport",
      "city": "Haines City",
      "state": "Florida",
      "country": "US",
      "elevation": 135,
      "lat": 28.1434001923,
      "lon": -81.6470031738,
      "tz": "America\/New_York"
  },
  "4GA0": {
      "icao": "4GA0",
      "iata": "",
      "name": "Pegasus Run Airport",
      "city": "Ball Ground",
      "state": "Georgia",
      "country": "US",
      "elevation": 900,
      "lat": 34.2801017761,
      "lon": -84.4213027954,
      "tz": "America\/New_York"
  },
  "4GA1": {
      "icao": "4GA1",
      "iata": "",
      "name": "Thacker Field",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 920,
      "lat": 33.0447998047,
      "lon": -84.3983001709,
      "tz": "America\/New_York"
  },
  "4GA4": {
      "icao": "4GA4",
      "iata": "",
      "name": "R.M. Harris Airport",
      "city": "Eton",
      "state": "Georgia",
      "country": "US",
      "elevation": 770,
      "lat": 34.846698761,
      "lon": -84.754699707,
      "tz": "America\/New_York"
  },
  "4GA6": {
      "icao": "4GA6",
      "iata": "",
      "name": "Chattahoochee Air Park",
      "city": "Douglasville",
      "state": "Georgia",
      "country": "US",
      "elevation": 750,
      "lat": 33.6621017456,
      "lon": -84.6804962158,
      "tz": "America\/New_York"
  },
  "4GA7": {
      "icao": "4GA7",
      "iata": "",
      "name": "Kitchens Field",
      "city": "Griffin",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.134601593,
      "lon": -84.2540969849,
      "tz": "America\/New_York"
  },
  "4GA8": {
      "icao": "4GA8",
      "iata": "",
      "name": "Andrews Airport",
      "city": "Arlington",
      "state": "Georgia",
      "country": "US",
      "elevation": 245,
      "lat": 31.4155006409,
      "lon": -84.7129974365,
      "tz": "America\/New_York"
  },
  "4GA9": {
      "icao": "4GA9",
      "iata": "",
      "name": "Ebeneezer Airport",
      "city": "Cumming",
      "state": "Georgia",
      "country": "US",
      "elevation": 1230,
      "lat": 34.2422981262,
      "lon": -84.0570983887,
      "tz": "America\/New_York"
  },
  "4GE0": {
      "icao": "4GE0",
      "iata": "",
      "name": "Millhaven Airport",
      "city": "Millhaven",
      "state": "Georgia",
      "country": "US",
      "elevation": 164,
      "lat": 32.936000824,
      "lon": -81.6621017456,
      "tz": "America\/New_York"
  },
  "4GE1": {
      "icao": "4GE1",
      "iata": "",
      "name": "Viola Farm Airport",
      "city": "Attapulgus",
      "state": "Georgia",
      "country": "US",
      "elevation": 285,
      "lat": 30.7236995697,
      "lon": -84.5410995483,
      "tz": "America\/New_York"
  },
  "4GE2": {
      "icao": "4GE2",
      "iata": "",
      "name": "Gorden E Bellah International Airport",
      "city": "Stockbridge",
      "state": "Georgia",
      "country": "US",
      "elevation": 780,
      "lat": 33.5500984192,
      "lon": -84.1666030884,
      "tz": "America\/New_York"
  },
  "4GE3": {
      "icao": "4GE3",
      "iata": "",
      "name": "Whitehall Airport",
      "city": "Montrose",
      "state": "Georgia",
      "country": "US",
      "elevation": 350,
      "lat": 32.498889,
      "lon": -83.191667,
      "tz": "America\/New_York"
  },
  "4IA2": {
      "icao": "4IA2",
      "iata": "",
      "name": "Walker Field",
      "city": "West Liberty",
      "state": "Iowa",
      "country": "US",
      "elevation": 715,
      "lat": 41.586101532,
      "lon": -91.220199585,
      "tz": "America\/Chicago"
  },
  "4IA4": {
      "icao": "4IA4",
      "iata": "",
      "name": "Mccoy Airport",
      "city": "Danville",
      "state": "Iowa",
      "country": "US",
      "elevation": 715,
      "lat": 40.8185997009,
      "lon": -91.3388977051,
      "tz": "America\/Chicago"
  },
  "4IA7": {
      "icao": "4IA7",
      "iata": "",
      "name": "Witcombe Field",
      "city": "Cedar Falls",
      "state": "Iowa",
      "country": "US",
      "elevation": 1000,
      "lat": 42.47190094,
      "lon": -92.49520111,
      "tz": "America\/Chicago"
  },
  "4IA8": {
      "icao": "4IA8",
      "iata": "",
      "name": "Weiss Airport",
      "city": "Williamsburg",
      "state": "Iowa",
      "country": "US",
      "elevation": 850,
      "lat": 41.6472015381,
      "lon": -91.959602356,
      "tz": "America\/Chicago"
  },
  "4IA9": {
      "icao": "4IA9",
      "iata": "",
      "name": "Triple J Airport",
      "city": "Dike",
      "state": "Iowa",
      "country": "US",
      "elevation": 1020,
      "lat": 42.4583015442,
      "lon": -92.7223968506,
      "tz": "America\/Chicago"
  },
  "4II0": {
      "icao": "4II0",
      "iata": "",
      "name": "De Ford Airport",
      "city": "Delphi",
      "state": "Indiana",
      "country": "US",
      "elevation": 640,
      "lat": 40.609500885,
      "lon": -86.7382965088,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4II2": {
      "icao": "4II2",
      "iata": "",
      "name": "Hangar Fly Ultralight Fly Club Ultralightport",
      "city": "Lawrence",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 39.8534011841,
      "lon": -85.9541015625,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4II3": {
      "icao": "4II3",
      "iata": "",
      "name": "Oleo Airport",
      "city": "Fillmore",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 39.7364006042,
      "lon": -86.7296981812,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4II4": {
      "icao": "4II4",
      "iata": "",
      "name": "Chesak Airport",
      "city": "North Judson",
      "state": "Indiana",
      "country": "US",
      "elevation": 670,
      "lat": 41.2555999756,
      "lon": -86.7881011963,
      "tz": "America\/Indiana\/Knox"
  },
  "4II6": {
      "icao": "4II6",
      "iata": "",
      "name": "Gettlefinger Field",
      "city": "Palmyra",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 38.3791999817,
      "lon": -86.0815963745,
      "tz": "America\/Kentucky\/Louisville"
  },
  "4II7": {
      "icao": "4II7",
      "iata": "",
      "name": "Sport Aircraft Flight Park Ultralightport",
      "city": "Thorntown",
      "state": "Indiana",
      "country": "US",
      "elevation": 900,
      "lat": 40.1058998108,
      "lon": -86.5218963623,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4II8": {
      "icao": "4II8",
      "iata": "",
      "name": "Willis Airport",
      "city": "Greenfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 39.7223014832,
      "lon": -85.7165985107,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4II9": {
      "icao": "4II9",
      "iata": "",
      "name": "Shultz \/Private\/ Airport",
      "city": "Brookston",
      "state": "Indiana",
      "country": "US",
      "elevation": 695,
      "lat": 40.6091995239,
      "lon": -86.8807983398,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4IL2": {
      "icao": "4IL2",
      "iata": "",
      "name": "Wayne Ziller Jr Airport",
      "city": "Fairbury",
      "state": "Illinois",
      "country": "US",
      "elevation": 661,
      "lat": 40.8083992004,
      "lon": -88.5417022705,
      "tz": "America\/Chicago"
  },
  "4IL3": {
      "icao": "4IL3",
      "iata": "",
      "name": "Thrifty Acres STOLport",
      "city": "Stillman Valley (Byron)",
      "state": "Illinois",
      "country": "US",
      "elevation": 725,
      "lat": 42.1427993774,
      "lon": -89.2284011841,
      "tz": "America\/Chicago"
  },
  "4IL4": {
      "icao": "4IL4",
      "iata": "",
      "name": "Kuebler RLA Restricted Landing Area",
      "city": "St Elmo",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 39.0564002991,
      "lon": -88.8161010742,
      "tz": "America\/Chicago"
  },
  "4IL5": {
      "icao": "4IL5",
      "iata": "",
      "name": "Frank's Flying Service Airport",
      "city": "Morrison",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 41.7249984741,
      "lon": -90.0050964355,
      "tz": "America\/Chicago"
  },
  "4IL8": {
      "icao": "4IL8",
      "iata": "",
      "name": "Rendleman Airport",
      "city": "Murphysboro",
      "state": "Illinois",
      "country": "US",
      "elevation": 500,
      "lat": 37.7563018799,
      "lon": -89.3833007813,
      "tz": "America\/Chicago"
  },
  "4IL9": {
      "icao": "4IL9",
      "iata": "",
      "name": "Unzicker Airport",
      "city": "Benson",
      "state": "Illinois",
      "country": "US",
      "elevation": 745,
      "lat": 40.8274993896,
      "lon": -89.1207962036,
      "tz": "America\/Chicago"
  },
  "4IN4": {
      "icao": "4IN4",
      "iata": "",
      "name": "Wilson Airport",
      "city": "Wallace",
      "state": "Indiana",
      "country": "US",
      "elevation": 710,
      "lat": 39.9752998352,
      "lon": -87.1802978516,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4IN5": {
      "icao": "4IN5",
      "iata": "",
      "name": "Ball Field",
      "city": "Bourbon",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 41.3442001343,
      "lon": -86.1483001709,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4IN7": {
      "icao": "4IN7",
      "iata": "",
      "name": "Riley Field",
      "city": "Attica",
      "state": "Indiana",
      "country": "US",
      "elevation": 608,
      "lat": 40.3008995056,
      "lon": -87.2292022705,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4IN8": {
      "icao": "4IN8",
      "iata": "",
      "name": "Culp Farms Airport",
      "city": "Rensselaer",
      "state": "Indiana",
      "country": "US",
      "elevation": 673,
      "lat": 40.8675003052,
      "lon": -87.0682983398,
      "tz": "America\/Chicago"
  },
  "4IN9": {
      "icao": "4IN9",
      "iata": "",
      "name": "Wawasee Airport",
      "city": "Syracuse",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 41.4188995361,
      "lon": -85.6917037964,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "4IS1": {
      "icao": "4IS1",
      "iata": "",
      "name": "Kamm Airport",
      "city": "Atwood",
      "state": "Illinois",
      "country": "US",
      "elevation": 672,
      "lat": 39.7731018066,
      "lon": -88.4486999512,
      "tz": "America\/Chicago"
  },
  "4IS5": {
      "icao": "4IS5",
      "iata": "",
      "name": "Smith Restricted Landing Area",
      "city": "Hecker",
      "state": "Illinois",
      "country": "US",
      "elevation": 420,
      "lat": 38.2583999634,
      "lon": -89.9381027222,
      "tz": "America\/Chicago"
  },
  "4IS6": {
      "icao": "4IS6",
      "iata": "",
      "name": "Hattan Farms Airport",
      "city": "Minonk",
      "state": "Illinois",
      "country": "US",
      "elevation": 710,
      "lat": 40.9305992126,
      "lon": -89.1205978394,
      "tz": "America\/Chicago"
  },
  "4IS7": {
      "icao": "4IS7",
      "iata": "",
      "name": "Litchfield RLA Restricted Landing Area",
      "city": "Seymour",
      "state": "Illinois",
      "country": "US",
      "elevation": 704,
      "lat": 40.0731010437,
      "lon": -88.3994979858,
      "tz": "America\/Chicago"
  },
  "4IS8": {
      "icao": "4IS8",
      "iata": "",
      "name": "Leigh Farm Airport",
      "city": "Sparland",
      "state": "Illinois",
      "country": "US",
      "elevation": 730,
      "lat": 40.0217018127,
      "lon": -89.5501022339,
      "tz": "America\/Chicago"
  },
  "4IS9": {
      "icao": "4IS9",
      "iata": "",
      "name": "Weidner Airport",
      "city": "Bunker Hill",
      "state": "Illinois",
      "country": "US",
      "elevation": 667,
      "lat": 39.085899353,
      "lon": -89.9589004517,
      "tz": "America\/Chicago"
  },
  "4KS1": {
      "icao": "4KS1",
      "iata": "",
      "name": "Amar Farms Airport",
      "city": "Wellsville",
      "state": "Kansas",
      "country": "US",
      "elevation": 1020,
      "lat": 38.6852989197,
      "lon": -95.0329971313,
      "tz": "America\/Chicago"
  },
  "4KS2": {
      "icao": "4KS2",
      "iata": "",
      "name": "Ingels Aerodrome",
      "city": "De Soto",
      "state": "Kansas",
      "country": "US",
      "elevation": 960,
      "lat": 39.0186004639,
      "lon": -94.9543991089,
      "tz": "America\/Chicago"
  },
  "4KS6": {
      "icao": "4KS6",
      "iata": "",
      "name": "Thomsen Field",
      "city": "Hartford",
      "state": "Kansas",
      "country": "US",
      "elevation": 1140,
      "lat": 38.2416992188,
      "lon": -95.9067001343,
      "tz": "America\/Chicago"
  },
  "4KS7": {
      "icao": "4KS7",
      "iata": "",
      "name": "Butler Airpark",
      "city": "Rose Hill",
      "state": "Kansas",
      "country": "US",
      "elevation": 1310,
      "lat": 37.5588989258,
      "lon": -97.0650024414,
      "tz": "America\/Chicago"
  },
  "4KS8": {
      "icao": "4KS8",
      "iata": "",
      "name": "Bursch Private Airport",
      "city": "Bird City",
      "state": "Kansas",
      "country": "US",
      "elevation": 3650,
      "lat": 39.5910987854,
      "lon": -101.5930023193,
      "tz": "America\/Chicago"
  },
  "4KY0": {
      "icao": "4KY0",
      "iata": "",
      "name": "J & C Antique Airfield",
      "city": "Hardin",
      "state": "Kentucky",
      "country": "US",
      "elevation": 540,
      "lat": 36.7619018555,
      "lon": -88.376701355,
      "tz": "America\/Chicago"
  },
  "4KY1": {
      "icao": "4KY1",
      "iata": "",
      "name": "Creek Side Landing Airport",
      "city": "Glasgow",
      "state": "Kentucky",
      "country": "US",
      "elevation": 727,
      "lat": 36.8950004578,
      "lon": -85.7727966309,
      "tz": "America\/Chicago"
  },
  "4KY4": {
      "icao": "4KY4",
      "iata": "",
      "name": "Moseley Field",
      "city": "Calhoun",
      "state": "Kentucky",
      "country": "US",
      "elevation": 395,
      "lat": 37.5924987793,
      "lon": -87.2810974121,
      "tz": "America\/Chicago"
  },
  "4KY5": {
      "icao": "4KY5",
      "iata": "",
      "name": "Weavers Run Airport",
      "city": "West Point",
      "state": "Kentucky",
      "country": "US",
      "elevation": 550,
      "lat": 38.0285987854,
      "lon": -83.8899993896,
      "tz": "America\/New_York"
  },
  "4KY7": {
      "icao": "4KY7",
      "iata": "",
      "name": "Mueller Farm Airport",
      "city": "Verona",
      "state": "Kentucky",
      "country": "US",
      "elevation": 890,
      "lat": 38.823299408,
      "lon": -84.6847000122,
      "tz": "America\/New_York"
  },
  "4KY8": {
      "icao": "4KY8",
      "iata": "",
      "name": "Shady Acres Airport",
      "city": "Shepherdsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 450,
      "lat": 37.9458007813,
      "lon": -85.6968994141,
      "tz": "America\/New_York"
  },
  "4LA3": {
      "icao": "4LA3",
      "iata": "",
      "name": "Jackson Airport",
      "city": "Jackson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 204,
      "lat": 30.807800293,
      "lon": -91.2133026123,
      "tz": "America\/Chicago"
  },
  "4LA8": {
      "icao": "4LA8",
      "iata": "",
      "name": "Overton Private Airport",
      "city": "Clinton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 110,
      "lat": 30.7077007294,
      "lon": -90.8742980957,
      "tz": "America\/Chicago"
  },
  "4LA9": {
      "icao": "4LA9",
      "iata": "",
      "name": "Blouin Flightpark Ultralightport",
      "city": "Raceland",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.7362995148,
      "lon": -90.6342010498,
      "tz": "America\/Chicago"
  },
  "4LL0": {
      "icao": "4LL0",
      "iata": "",
      "name": "Ranken Airport",
      "city": "Steward",
      "state": "Illinois",
      "country": "US",
      "elevation": 785,
      "lat": 41.835899353,
      "lon": -89.0933990479,
      "tz": "America\/Chicago"
  },
  "4LL1": {
      "icao": "4LL1",
      "iata": "",
      "name": "Staton Airport",
      "city": "Steward",
      "state": "Illinois",
      "country": "US",
      "elevation": 770,
      "lat": 41.8541984558,
      "lon": -89.087600708,
      "tz": "America\/Chicago"
  },
  "4LL3": {
      "icao": "4LL3",
      "iata": "",
      "name": "John L Coppernoll Airport",
      "city": "Stockton",
      "state": "Illinois",
      "country": "US",
      "elevation": 930,
      "lat": 42.3083000183,
      "lon": -89.9834976196,
      "tz": "America\/Chicago"
  },
  "4LL4": {
      "icao": "4LL4",
      "iata": "",
      "name": "Enjoy Field",
      "city": "Chebanse",
      "state": "Illinois",
      "country": "US",
      "elevation": 685,
      "lat": 40.9757995605,
      "lon": -87.9985961914,
      "tz": "America\/Chicago"
  },
  "4LL7": {
      "icao": "4LL7",
      "iata": "",
      "name": "Willadae Farms Airport",
      "city": "Sycamore",
      "state": "Illinois",
      "country": "US",
      "elevation": 850,
      "lat": 42,
      "lon": -88.6668014526,
      "tz": "America\/Chicago"
  },
  "4LL8": {
      "icao": "4LL8",
      "iata": "",
      "name": "Colonial Acres Airport",
      "city": "Sycamore",
      "state": "Illinois",
      "country": "US",
      "elevation": 840,
      "lat": 42.0027999878,
      "lon": -88.6458969116,
      "tz": "America\/Chicago"
  },
  "4LL9": {
      "icao": "4LL9",
      "iata": "",
      "name": "Alan B. Janssen Airport",
      "city": "Morrisonville",
      "state": "Illinois",
      "country": "US",
      "elevation": 642,
      "lat": 39.3437004089,
      "lon": -89.4236984253,
      "tz": "America\/Chicago"
  },
  "4MD0": {
      "icao": "4MD0",
      "iata": "",
      "name": "Mears Creek Airfield",
      "city": "Lusby",
      "state": "Maryland",
      "country": "US",
      "elevation": 27,
      "lat": 38.3742980957,
      "lon": -76.483001709,
      "tz": "America\/New_York"
  },
  "4MD1": {
      "icao": "4MD1",
      "iata": "",
      "name": "Bunting's Field",
      "city": "Berlin",
      "state": "Maryland",
      "country": "US",
      "elevation": 5,
      "lat": 38.3681983948,
      "lon": -75.2306976318,
      "tz": "America\/New_York"
  },
  "4MD2": {
      "icao": "4MD2",
      "iata": "",
      "name": "Ward's Airport",
      "city": "Oakland",
      "state": "Maryland",
      "country": "US",
      "elevation": 2500,
      "lat": 39.4297981262,
      "lon": -79.4539031982,
      "tz": "America\/New_York"
  },
  "4MD3": {
      "icao": "4MD3",
      "iata": "",
      "name": "Carey Field",
      "city": "Bishopville",
      "state": "Maryland",
      "country": "US",
      "elevation": 23,
      "lat": 38.4397010803,
      "lon": -75.1594009399,
      "tz": "America\/New_York"
  },
  "4MD4": {
      "icao": "4MD4",
      "iata": "",
      "name": "Clements Airport",
      "city": "Clements",
      "state": "Maryland",
      "country": "US",
      "elevation": 105,
      "lat": 38.3405990601,
      "lon": -76.737197876,
      "tz": "America\/New_York"
  },
  "4MD6": {
      "icao": "4MD6",
      "iata": "",
      "name": "Moxley's Airport",
      "city": "Dublin",
      "state": "Maryland",
      "country": "US",
      "elevation": 410,
      "lat": 39.625,
      "lon": -76.2624969482,
      "tz": "America\/New_York"
  },
  "4MD7": {
      "icao": "4MD7",
      "iata": "",
      "name": "Catoctin Crosswind Airport",
      "city": "Thurmont",
      "state": "Maryland",
      "country": "US",
      "elevation": 451,
      "lat": 39.646389,
      "lon": -77.366667,
      "tz": "America\/New_York"
  },
  "4MD8": {
      "icao": "4MD8",
      "iata": "",
      "name": "Ijamsville Airport",
      "city": "Frederick",
      "state": "Maryland",
      "country": "US",
      "elevation": 490,
      "lat": 39.3582992554,
      "lon": -77.3396987915,
      "tz": "America\/New_York"
  },
  "4MD9": {
      "icao": "4MD9",
      "iata": "",
      "name": "St John Airport",
      "city": "Owings Mills",
      "state": "Maryland",
      "country": "US",
      "elevation": 461,
      "lat": 39.4805984497,
      "lon": -76.760597229,
      "tz": "America\/New_York"
  },
  "4MI0": {
      "icao": "4MI0",
      "iata": "",
      "name": "Kriewall Strip",
      "city": "Romeo",
      "state": "Michigan",
      "country": "US",
      "elevation": 765,
      "lat": 42.8381004333,
      "lon": -82.9688034058,
      "tz": "America\/Detroit"
  },
  "4MI1": {
      "icao": "4MI1",
      "iata": "",
      "name": "Air Rahe Airport",
      "city": "Petersburg",
      "state": "Michigan",
      "country": "US",
      "elevation": 680,
      "lat": 41.8306007385,
      "lon": -83.6874008179,
      "tz": "America\/Detroit"
  },
  "4MI3": {
      "icao": "4MI3",
      "iata": "",
      "name": "Phil's Field",
      "city": "Overisel",
      "state": "Michigan",
      "country": "US",
      "elevation": 685,
      "lat": 42.7277984619,
      "lon": -86.0402984619,
      "tz": "America\/Detroit"
  },
  "4MI4": {
      "icao": "4MI4",
      "iata": "",
      "name": "Whitcomb Field",
      "city": "Schoolcraft",
      "state": "Michigan",
      "country": "US",
      "elevation": 915,
      "lat": 42.1781005859,
      "lon": -85.6707992554,
      "tz": "America\/Detroit"
  },
  "4MI6": {
      "icao": "4MI6",
      "iata": "",
      "name": "Benedick Airport",
      "city": "Three Oaks",
      "state": "Michigan",
      "country": "US",
      "elevation": 660,
      "lat": 41.85779953,
      "lon": -86.6145019531,
      "tz": "America\/Detroit"
  },
  "4MI7": {
      "icao": "4MI7",
      "iata": "",
      "name": "Witbeck Aerodrone Airport",
      "city": "Farwell",
      "state": "Michigan",
      "country": "US",
      "elevation": 890,
      "lat": 43.8134002686,
      "lon": -84.8217010498,
      "tz": "America\/Detroit"
  },
  "4MN0": {
      "icao": "4MN0",
      "iata": "",
      "name": "Skalicky Airstrip",
      "city": "Albany",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1300,
      "lat": 45.6618995667,
      "lon": -94.5755996704,
      "tz": "America\/Chicago"
  },
  "4MN2": {
      "icao": "4MN2",
      "iata": "",
      "name": "Coot Landing Airport",
      "city": "Barnsville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1325,
      "lat": 46.6637992859,
      "lon": -96.2417984009,
      "tz": "America\/Chicago"
  },
  "4MN4": {
      "icao": "4MN4",
      "iata": "",
      "name": "Mulder Field Inc Airport",
      "city": "Ivanhoe",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1669,
      "lat": 44.4557991028,
      "lon": -96.2305984497,
      "tz": "America\/Chicago"
  },
  "4MN5": {
      "icao": "4MN5",
      "iata": "",
      "name": "Kapaun-Wilson Field",
      "city": "Graceville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1122,
      "lat": 45.5504989624,
      "lon": -96.4514007568,
      "tz": "America\/Chicago"
  },
  "4MN7": {
      "icao": "4MN7",
      "iata": "",
      "name": "Burk Airport",
      "city": "Amboy",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1070,
      "lat": 43.8437995911,
      "lon": -94.2857971191,
      "tz": "America\/Chicago"
  },
  "4MN8": {
      "icao": "4MN8",
      "iata": "",
      "name": "Myers Field",
      "city": "Pequot Lakes",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1300,
      "lat": 46.6274986267,
      "lon": -94.3394012451,
      "tz": "America\/Chicago"
  },
  "4MO0": {
      "icao": "4MO0",
      "iata": "",
      "name": "Fender J H Airport",
      "city": "Kingsville",
      "state": "Missouri",
      "country": "US",
      "elevation": 865,
      "lat": 38.6995010376,
      "lon": -94.0579986572,
      "tz": "America\/Chicago"
  },
  "4MO2": {
      "icao": "4MO2",
      "iata": "",
      "name": "Newcomb Hereford Ranch Airport",
      "city": "Lancaster",
      "state": "Missouri",
      "country": "US",
      "elevation": 850,
      "lat": 40.5583992004,
      "lon": -92.4419021606,
      "tz": "America\/Chicago"
  },
  "4MO3": {
      "icao": "4MO3",
      "iata": "",
      "name": "Schneider Field",
      "city": "La Plata",
      "state": "Missouri",
      "country": "US",
      "elevation": 939,
      "lat": 39.9916992188,
      "lon": -92.491897583,
      "tz": "America\/Chicago"
  },
  "4MO4": {
      "icao": "4MO4",
      "iata": "",
      "name": "Liberty Landing Airport",
      "city": "Liberty",
      "state": "Missouri",
      "country": "US",
      "elevation": 720,
      "lat": 39.2167015076,
      "lon": -94.3336029053,
      "tz": "America\/Chicago"
  },
  "4MO5": {
      "icao": "4MO5",
      "iata": "",
      "name": "Boehne Field",
      "city": "Lockwood",
      "state": "Missouri",
      "country": "US",
      "elevation": 1120,
      "lat": 37.3666992188,
      "lon": -93.991897583,
      "tz": "America\/Chicago"
  },
  "4MO6": {
      "icao": "4MO6",
      "iata": "",
      "name": "Mark Twain Air Park",
      "city": "Louisiana",
      "state": "Missouri",
      "country": "US",
      "elevation": 840,
      "lat": 39.4388999939,
      "lon": -91.1182022095,
      "tz": "America\/Chicago"
  },
  "4MO7": {
      "icao": "4MO7",
      "iata": "",
      "name": "Crop Care Airport",
      "city": "Mound City",
      "state": "Missouri",
      "country": "US",
      "elevation": 875,
      "lat": 40.1413993835,
      "lon": -95.2407989502,
      "tz": "America\/Chicago"
  },
  "4MO8": {
      "icao": "4MO8",
      "iata": "",
      "name": "Martens Airport",
      "city": "Atherton",
      "state": "Missouri",
      "country": "US",
      "elevation": 720,
      "lat": 39.2066993713,
      "lon": -94.2696990967,
      "tz": "America\/Chicago"
  },
  "4MO9": {
      "icao": "4MO9",
      "iata": "",
      "name": "Friends Field",
      "city": "Lone Jack",
      "state": "Missouri",
      "country": "US",
      "elevation": 1100,
      "lat": 38.8925018311,
      "lon": -94.1085968018,
      "tz": "America\/Chicago"
  },
  "4MS0": {
      "icao": "4MS0",
      "iata": "",
      "name": "Payne Airport",
      "city": "Pelahatchie",
      "state": "Mississippi",
      "country": "US",
      "elevation": 359,
      "lat": 32.3003997803,
      "lon": -89.8590011597,
      "tz": "America\/Chicago"
  },
  "4MS2": {
      "icao": "4MS2",
      "iata": "",
      "name": "Dogwood Acres Airport",
      "city": "Raymond",
      "state": "Mississippi",
      "country": "US",
      "elevation": 250,
      "lat": 32.2121009827,
      "lon": -90.450302124,
      "tz": "America\/Chicago"
  },
  "4MS3": {
      "icao": "4MS3",
      "iata": "",
      "name": "Jeter Field",
      "city": "Sladen",
      "state": "Mississippi",
      "country": "US",
      "elevation": 520,
      "lat": 34.9560012817,
      "lon": -89.3939971924,
      "tz": "America\/Chicago"
  },
  "4MS4": {
      "icao": "4MS4",
      "iata": "",
      "name": "Spearman Field",
      "city": "Shannon",
      "state": "Mississippi",
      "country": "US",
      "elevation": 270,
      "lat": 34.157699585,
      "lon": -88.7602005005,
      "tz": "America\/Chicago"
  },
  "4MS5": {
      "icao": "4MS5",
      "iata": "",
      "name": "Bird Nest Airport",
      "city": "Lucedale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 311,
      "lat": 31.0013999939,
      "lon": -88.516998291,
      "tz": "America\/Chicago"
  },
  "4MS7": {
      "icao": "4MS7",
      "iata": "",
      "name": "Arnold Field",
      "city": "Tunica",
      "state": "Mississippi",
      "country": "US",
      "elevation": 195,
      "lat": 34.7484016418,
      "lon": -90.2807998657,
      "tz": "America\/Chicago"
  },
  "4MS9": {
      "icao": "4MS9",
      "iata": "",
      "name": "Cochran Airport",
      "city": "Canton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 240,
      "lat": 32.6660003662,
      "lon": -90.0675964355,
      "tz": "America\/Chicago"
  },
  "4MT1": {
      "icao": "4MT1",
      "iata": "",
      "name": "Kreikemeier Airport",
      "city": "Belgrade",
      "state": "Montana",
      "country": "US",
      "elevation": 4360,
      "lat": 45.8207015991,
      "lon": -111.1449966431,
      "tz": "America\/Denver"
  },
  "4NA0": {
      "icao": "4NA0",
      "iata": "",
      "name": "Lindvig Airstrip",
      "city": "Williston",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2180,
      "lat": 48.0083999634,
      "lon": -103.591003418,
      "tz": "America\/Chicago"
  },
  "4NA1": {
      "icao": "4NA1",
      "iata": "",
      "name": "Ring Rock Ranch Airport",
      "city": "Williston",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2256,
      "lat": 48.254699707,
      "lon": -103.672996521,
      "tz": "America\/Chicago"
  },
  "4NA3": {
      "icao": "4NA3",
      "iata": "",
      "name": "Peterson Airstrip",
      "city": "Antler",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1545,
      "lat": 48.8572006226,
      "lon": -101.3020019531,
      "tz": "America\/Chicago"
  },
  "4NA5": {
      "icao": "4NA5",
      "iata": "",
      "name": "Berg Strip",
      "city": "Berthold",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2250,
      "lat": 48.2882995605,
      "lon": -101.8280029297,
      "tz": "America\/Chicago"
  },
  "4NA6": {
      "icao": "4NA6",
      "iata": "",
      "name": "Nicks Landing Airport",
      "city": "Grenora",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2440,
      "lat": 48.4550018311,
      "lon": -103.8799972534,
      "tz": "America\/Chicago"
  },
  "4NC0": {
      "icao": "4NC0",
      "iata": "",
      "name": "Boyd's Hawks Creek Airport",
      "city": "Mocksville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 815,
      "lat": 35.8984985352,
      "lon": -80.6162033081,
      "tz": "America\/New_York"
  },
  "4NC2": {
      "icao": "4NC2",
      "iata": "",
      "name": "Bay Creek Airport",
      "city": "Bayboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 2,
      "lat": 35.207698822,
      "lon": -76.612197876,
      "tz": "America\/New_York"
  },
  "4NC3": {
      "icao": "4NC3",
      "iata": "",
      "name": "Taylorsville Airport",
      "city": "Taylorsville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1090,
      "lat": 35.9308013916,
      "lon": -81.1967010498,
      "tz": "America\/New_York"
  },
  "4NC5": {
      "icao": "4NC5",
      "iata": "",
      "name": "Triple M Airport",
      "city": "Cape Carteret",
      "state": "North Carolina",
      "country": "US",
      "elevation": 30,
      "lat": 34.7304000854,
      "lon": -77.0504989624,
      "tz": "America\/New_York"
  },
  "4NC6": {
      "icao": "4NC6",
      "iata": "",
      "name": "Cane Creek Airport",
      "city": "Fletcher",
      "state": "North Carolina",
      "country": "US",
      "elevation": 2100,
      "lat": 35.4445991516,
      "lon": -82.479598999,
      "tz": "America\/New_York"
  },
  "4NC7": {
      "icao": "4NC7",
      "iata": "",
      "name": "Peacock STOLport",
      "city": "Garner",
      "state": "North Carolina",
      "country": "US",
      "elevation": 390,
      "lat": 35.6963005066,
      "lon": -78.5374984741,
      "tz": "America\/New_York"
  },
  "4NC8": {
      "icao": "4NC8",
      "iata": "",
      "name": "Buffalo Creek Airport",
      "city": "Concord",
      "state": "North Carolina",
      "country": "US",
      "elevation": 600,
      "lat": 35.4224014282,
      "lon": -80.6205978394,
      "tz": "America\/New_York"
  },
  "4NC9": {
      "icao": "4NC9",
      "iata": "",
      "name": "Lz Carroll STOLport",
      "city": "Gold Hill",
      "state": "North Carolina",
      "country": "US",
      "elevation": 675,
      "lat": 35.4485015869,
      "lon": -80.3425979614,
      "tz": "America\/New_York"
  },
  "4ND1": {
      "icao": "4ND1",
      "iata": "",
      "name": "Knutson Airport",
      "city": "Thompson",
      "state": "North Dakota",
      "country": "US",
      "elevation": 880,
      "lat": 47.7928009033,
      "lon": -97.1537017822,
      "tz": "America\/Chicago"
  },
  "4ND4": {
      "icao": "4ND4",
      "iata": "",
      "name": "Pfau Private Airport",
      "city": "Upham",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1455,
      "lat": 48.5638999939,
      "lon": -100.8799972534,
      "tz": "America\/Chicago"
  },
  "4ND8": {
      "icao": "4ND8",
      "iata": "",
      "name": "Sanden Airport",
      "city": "Wyndmere",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1065,
      "lat": 46.3540992737,
      "lon": -97.1169967651,
      "tz": "America\/Chicago"
  },
  "4NE0": {
      "icao": "4NE0",
      "iata": "",
      "name": "Regier Brothers Airport",
      "city": "Madrid",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3363,
      "lat": 40.9160995483,
      "lon": -101.5599975586,
      "tz": "America\/Denver"
  },
  "4NE1": {
      "icao": "4NE1",
      "iata": "",
      "name": "Mc Millan Ranch Airport",
      "city": "Milburn",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2500,
      "lat": 41.7541999817,
      "lon": -99.7546005249,
      "tz": "America\/Chicago"
  },
  "4NE8": {
      "icao": "4NE8",
      "iata": "",
      "name": "Malone M-Bar Ranch Airport",
      "city": "Palisade",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3000,
      "lat": 40.3828010559,
      "lon": -101.1520004272,
      "tz": "America\/Chicago"
  },
  "4NE9": {
      "icao": "4NE9",
      "iata": "",
      "name": "Holzfaster's Airport",
      "city": "Paxton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3305,
      "lat": 40.9991989136,
      "lon": -101.4469985962,
      "tz": "America\/Denver"
  },
  "4NJ0": {
      "icao": "4NJ0",
      "iata": "",
      "name": "Windward Farms Airport",
      "city": "Rosemont",
      "state": "New Jersey",
      "country": "US",
      "elevation": 425,
      "lat": 40.4492988586,
      "lon": -75.0177001953,
      "tz": "America\/New_York"
  },
  "4NJ8": {
      "icao": "4NJ8",
      "iata": "",
      "name": "Peters Airport",
      "city": "Somerville",
      "state": "New Jersey",
      "country": "US",
      "elevation": 150,
      "lat": 40.6001014709,
      "lon": -74.7329025269,
      "tz": "America\/New_York"
  },
  "4NK1": {
      "icao": "4NK1",
      "iata": "",
      "name": "B-Ville Airpark",
      "city": "Baldwinsville",
      "state": "New York",
      "country": "US",
      "elevation": 375,
      "lat": 43.1447982788,
      "lon": -76.3113021851,
      "tz": "America\/New_York"
  },
  "4NK2": {
      "icao": "4NK2",
      "iata": "",
      "name": "Tracy Field",
      "city": "Greenwich",
      "state": "New York",
      "country": "US",
      "elevation": 455,
      "lat": 43.145233,
      "lon": -73.41965,
      "tz": "America\/New_York"
  },
  "4NK3": {
      "icao": "4NK3",
      "iata": "",
      "name": "Evans Airways Airport",
      "city": "Evans",
      "state": "New York",
      "country": "US",
      "elevation": 940,
      "lat": 42.6725006104,
      "lon": -78.9670028687,
      "tz": "America\/New_York"
  },
  "4NK4": {
      "icao": "4NK4",
      "iata": "",
      "name": "Woodford Airfield",
      "city": "Fabius",
      "state": "New York",
      "country": "US",
      "elevation": 1280,
      "lat": 42.8272018433,
      "lon": -76.0258026123,
      "tz": "America\/New_York"
  },
  "4NK5": {
      "icao": "4NK5",
      "iata": "",
      "name": "Harvs Airport",
      "city": "Macedon",
      "state": "New York",
      "country": "US",
      "elevation": 500,
      "lat": 43.1012001038,
      "lon": -77.3677978516,
      "tz": "America\/New_York"
  },
  "4NK6": {
      "icao": "4NK6",
      "iata": "",
      "name": "Ag-Alley Airport",
      "city": "Marion",
      "state": "New York",
      "country": "US",
      "elevation": 450,
      "lat": 43.1610984802,
      "lon": -77.1774978638,
      "tz": "America\/New_York"
  },
  "4NK8": {
      "icao": "4NK8",
      "iata": "",
      "name": "Longwell Airport",
      "city": "Amsterdam",
      "state": "New York",
      "country": "US",
      "elevation": 800,
      "lat": 43.0009002686,
      "lon": -74.1871032715,
      "tz": "America\/New_York"
  },
  "4NY0": {
      "icao": "4NY0",
      "iata": "",
      "name": "Mountain View Airpark",
      "city": "Beekmantown",
      "state": "New York",
      "country": "US",
      "elevation": 500,
      "lat": 44.6688995361,
      "lon": -73.5462036133,
      "tz": "America\/New_York"
  },
  "4NY1": {
      "icao": "4NY1",
      "iata": "",
      "name": "Orange Poultry Farm Airport",
      "city": "Chester",
      "state": "New York",
      "country": "US",
      "elevation": 420,
      "lat": 41.3278999329,
      "lon": -74.3246002197,
      "tz": "America\/New_York"
  },
  "4NY4": {
      "icao": "4NY4",
      "iata": "",
      "name": "Cross' Farm Airport",
      "city": "Delmar",
      "state": "New York",
      "country": "US",
      "elevation": 55,
      "lat": 42.5595016479,
      "lon": -73.772102356,
      "tz": "America\/New_York"
  },
  "4NY8": {
      "icao": "4NY8",
      "iata": "",
      "name": "Harris Hill Gliderport",
      "city": "Elmira",
      "state": "New York",
      "country": "US",
      "elevation": 1709,
      "lat": 42.1208992004,
      "lon": -76.9011001587,
      "tz": "America\/New_York"
  },
  "4OA8": {
      "icao": "4OA8",
      "iata": "",
      "name": "Bowman Airport",
      "city": "Piqua",
      "state": "Ohio",
      "country": "US",
      "elevation": 980,
      "lat": 40.1086006165,
      "lon": -84.299697876,
      "tz": "America\/New_York"
  },
  "4OH1": {
      "icao": "4OH1",
      "iata": "",
      "name": "Sky Haven Airport",
      "city": "Madison",
      "state": "Ohio",
      "country": "US",
      "elevation": 940,
      "lat": 41.716999054,
      "lon": -81.1067962646,
      "tz": "America\/New_York"
  },
  "4OH3": {
      "icao": "4OH3",
      "iata": "",
      "name": "Bieber Field",
      "city": "North Lima",
      "state": "Ohio",
      "country": "US",
      "elevation": 1035,
      "lat": 40.9831008911,
      "lon": -80.7011032104,
      "tz": "America\/New_York"
  },
  "4OH4": {
      "icao": "4OH4",
      "iata": "",
      "name": "Millertime Airport",
      "city": "Orient",
      "state": "Ohio",
      "country": "US",
      "elevation": 840,
      "lat": 39.7928009033,
      "lon": -83.1172027588,
      "tz": "America\/New_York"
  },
  "4OH8": {
      "icao": "4OH8",
      "iata": "",
      "name": "Huffman Farm Airport",
      "city": "Archbold",
      "state": "Ohio",
      "country": "US",
      "elevation": 741,
      "lat": 41.5,
      "lon": -84.2499008179,
      "tz": "America\/New_York"
  },
  "4OI7": {
      "icao": "4OI7",
      "iata": "",
      "name": "R & M Aviation Airport",
      "city": "Avon",
      "state": "Ohio",
      "country": "US",
      "elevation": 690,
      "lat": 41.4537010193,
      "lon": -81.9701004028,
      "tz": "America\/New_York"
  },
  "4OI8": {
      "icao": "4OI8",
      "iata": "",
      "name": "Brocker Field",
      "city": "Canfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1170,
      "lat": 41.057800293,
      "lon": -80.7545013428,
      "tz": "America\/New_York"
  },
  "4OI9": {
      "icao": "4OI9",
      "iata": "",
      "name": "Baisden Airport",
      "city": "Coalton",
      "state": "Ohio",
      "country": "US",
      "elevation": 730,
      "lat": 39.1021995544,
      "lon": -82.6107025146,
      "tz": "America\/New_York"
  },
  "4OK0": {
      "icao": "4OK0",
      "iata": "",
      "name": "Zevely Climbing Zz Ranch Airport",
      "city": "Seminole",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 980,
      "lat": 35.2251014709,
      "lon": -96.7228012085,
      "tz": "America\/Chicago"
  },
  "4OK1": {
      "icao": "4OK1",
      "iata": "",
      "name": "Hatton Ranch Airport",
      "city": "Talihina",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 830,
      "lat": 34.7168006897,
      "lon": -95.175201416,
      "tz": "America\/Chicago"
  },
  "4OK2": {
      "icao": "4OK2",
      "iata": "",
      "name": "Candy Lake Estate Airport",
      "city": "Avant",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 685,
      "lat": 36.4930992126,
      "lon": -96.0547027588,
      "tz": "America\/Chicago"
  },
  "4OK4": {
      "icao": "4OK4",
      "iata": "",
      "name": "Low Pass Airport",
      "city": "Tuttle",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1320,
      "lat": 35.2876014709,
      "lon": -97.6641998291,
      "tz": "America\/Chicago"
  },
  "4OK5": {
      "icao": "4OK5",
      "iata": "",
      "name": "Newman Farm Airport",
      "city": "Velma",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1140,
      "lat": 34.5500984192,
      "lon": -97.6003036499,
      "tz": "America\/Chicago"
  },
  "4OK6": {
      "icao": "4OK6",
      "iata": "",
      "name": "Pitts Field",
      "city": "Wanette",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1060,
      "lat": 34.9894981384,
      "lon": -97.0252990723,
      "tz": "America\/Chicago"
  },
  "4OK7": {
      "icao": "4OK7",
      "iata": "",
      "name": "Flying A Ranch Airport",
      "city": "Washington",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1165,
      "lat": 35.0912017822,
      "lon": -97.5009002686,
      "tz": "America\/Chicago"
  },
  "4OK8": {
      "icao": "4OK8",
      "iata": "",
      "name": "Austin Airport",
      "city": "Wilburton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 675,
      "lat": 34.9031982422,
      "lon": -95.3589019775,
      "tz": "America\/Chicago"
  },
  "4OL3": {
      "icao": "4OL3",
      "iata": "",
      "name": "Jerry-Wright Airfield",
      "city": "Lawton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 34.5167007446,
      "lon": -98.3337020874,
      "tz": "America\/Chicago"
  },
  "4OR0": {
      "icao": "4OR0",
      "iata": "",
      "name": "Springbrook Airport",
      "city": "Rogue River",
      "state": "Oregon",
      "country": "US",
      "elevation": 1560,
      "lat": 42.5550994873,
      "lon": -123.2040023804,
      "tz": "America\/Los_Angeles"
  },
  "4OR3": {
      "icao": "4OR3",
      "iata": "",
      "name": "Lambert Field",
      "city": "Albany",
      "state": "Oregon",
      "country": "US",
      "elevation": 195,
      "lat": 44.7304000854,
      "lon": -123.0759963989,
      "tz": "America\/Los_Angeles"
  },
  "4OR4": {
      "icao": "4OR4",
      "iata": "",
      "name": "Schrock Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 245,
      "lat": 44.4435997009,
      "lon": -123.2379989624,
      "tz": "America\/Los_Angeles"
  },
  "4OR5": {
      "icao": "4OR5",
      "iata": "",
      "name": "Fly 'N' W Airport",
      "city": "Salem",
      "state": "Oregon",
      "country": "US",
      "elevation": 206,
      "lat": 44.9678993225,
      "lon": -122.9410018921,
      "tz": "America\/Los_Angeles"
  },
  "4OR6": {
      "icao": "4OR6",
      "iata": "",
      "name": "Auberge Des Fleurs Airport",
      "city": "Sandy",
      "state": "Oregon",
      "country": "US",
      "elevation": 510,
      "lat": 45.449798584,
      "lon": -122.2539978027,
      "tz": "America\/Los_Angeles"
  },
  "4OR7": {
      "icao": "4OR7",
      "iata": "",
      "name": "Lusardi Field",
      "city": "Salem",
      "state": "Oregon",
      "country": "US",
      "elevation": 205,
      "lat": 44.98540115,
      "lon": -122.9459991,
      "tz": "America\/Los_Angeles"
  },
  "4OR8": {
      "icao": "4OR8",
      "iata": "",
      "name": "Wagoner Airport",
      "city": "Salem",
      "state": "Oregon",
      "country": "US",
      "elevation": 700,
      "lat": 44.8484992981,
      "lon": -123.0410003662,
      "tz": "America\/Los_Angeles"
  },
  "4PA0": {
      "icao": "4PA0",
      "iata": "",
      "name": "Millard Airport",
      "city": "Annville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 485,
      "lat": 40.317199707,
      "lon": -76.5363998413,
      "tz": "America\/New_York"
  },
  "4PA1": {
      "icao": "4PA1",
      "iata": "",
      "name": "Sauers-Haven Airport",
      "city": "New Berlin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 590,
      "lat": 40.8838996887,
      "lon": -76.9800033569,
      "tz": "America\/New_York"
  },
  "4PA3": {
      "icao": "4PA3",
      "iata": "",
      "name": "Frymoyer Airport",
      "city": "Liverpool",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 520,
      "lat": 40.6305999756,
      "lon": -77.0102996826,
      "tz": "America\/New_York"
  },
  "4PA5": {
      "icao": "4PA5",
      "iata": "",
      "name": "Ponderosa Airport",
      "city": "Lock Haven",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1820,
      "lat": 41.3083992004,
      "lon": -77.5246963501,
      "tz": "America\/New_York"
  },
  "4PA6": {
      "icao": "4PA6",
      "iata": "",
      "name": "Hiawatha Airport",
      "city": "Starlight",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1670,
      "lat": 41.9151000977,
      "lon": -75.364402771,
      "tz": "America\/New_York"
  },
  "4PA7": {
      "icao": "4PA7",
      "iata": "",
      "name": "Sheepshead Airport",
      "city": "Lykens",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 610,
      "lat": 40.5648002625,
      "lon": -76.74659729,
      "tz": "America\/New_York"
  },
  "4PA8": {
      "icao": "4PA8",
      "iata": "",
      "name": "Russo Airstrip",
      "city": "Pipersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 400,
      "lat": 40.4328994751,
      "lon": -75.149597168,
      "tz": "America\/New_York"
  },
  "4PN0": {
      "icao": "4PN0",
      "iata": "",
      "name": "Flying M Ranch Airport",
      "city": "Grove City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1348,
      "lat": 41.2164001465,
      "lon": -80.0936965942,
      "tz": "America\/New_York"
  },
  "4PN7": {
      "icao": "4PN7",
      "iata": "",
      "name": "Merritt Field",
      "city": "Eagles Mere",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2000,
      "lat": 41.4180984497,
      "lon": -76.5344009399,
      "tz": "America\/New_York"
  },
  "4PN8": {
      "icao": "4PN8",
      "iata": "",
      "name": "C.J.K. Airport",
      "city": "Tunkhannock",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1100,
      "lat": 41.5308990479,
      "lon": -75.8783035278,
      "tz": "America\/New_York"
  },
  "4PS3": {
      "icao": "4PS3",
      "iata": "",
      "name": "Paraport Airstrip",
      "city": "Warren",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1400,
      "lat": 41.788417,
      "lon": -79.518586,
      "tz": "America\/New_York"
  },
  "4PS4": {
      "icao": "4PS4",
      "iata": "",
      "name": "Mountain Top Airport",
      "city": "Chadds Ford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 350,
      "lat": 39.8543014526,
      "lon": -75.5727005005,
      "tz": "America\/New_York"
  },
  "4PS5": {
      "icao": "4PS5",
      "iata": "",
      "name": "Muddy Creek Airport",
      "city": "Carmicheal",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1080,
      "lat": 39.920501709,
      "lon": -79.9486999512,
      "tz": "America\/New_York"
  },
  "4PS6": {
      "icao": "4PS6",
      "iata": "",
      "name": "Krout Airport",
      "city": "Lewistown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 600,
      "lat": 40.6366996765,
      "lon": -77.4835968018,
      "tz": "America\/New_York"
  },
  "4PS7": {
      "icao": "4PS7",
      "iata": "",
      "name": "Cohen Airport",
      "city": "Oxford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 418,
      "lat": 39.7361984253,
      "lon": -75.9436035156,
      "tz": "America\/New_York"
  },
  "4SC4": {
      "icao": "4SC4",
      "iata": "",
      "name": "Davis Field",
      "city": "Liberty",
      "state": "South Carolina",
      "country": "US",
      "elevation": 940,
      "lat": 34.7653007507,
      "lon": -82.6911010742,
      "tz": "America\/New_York"
  },
  "4SC7": {
      "icao": "4SC7",
      "iata": "",
      "name": "Wexford Landing Airport",
      "city": "Aiken",
      "state": "South Carolina",
      "country": "US",
      "elevation": 350,
      "lat": 33.5783004761,
      "lon": -81.5117034912,
      "tz": "America\/New_York"
  },
  "4SD4": {
      "icao": "4SD4",
      "iata": "",
      "name": "Dan's Airport",
      "city": "Rapid City",
      "state": "South Dakota",
      "country": "US",
      "elevation": 3166,
      "lat": 44.0416984558,
      "lon": -103.0459976196,
      "tz": "America\/Denver"
  },
  "4TA0": {
      "icao": "4TA0",
      "iata": "",
      "name": "Massimiliano Memorial Field",
      "city": "Damon",
      "state": "Texas",
      "country": "US",
      "elevation": 65,
      "lat": 29.3554992676,
      "lon": -95.6638031006,
      "tz": "America\/Chicago"
  },
  "4TA1": {
      "icao": "4TA1",
      "iata": "",
      "name": "Warschun Ranch Airport",
      "city": "Denton",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 33.302898407,
      "lon": -97.1113967896,
      "tz": "America\/Chicago"
  },
  "4TA3": {
      "icao": "4TA3",
      "iata": "",
      "name": "Costello Island Inc Airport",
      "city": "Graham",
      "state": "Texas",
      "country": "US",
      "elevation": 1020,
      "lat": 32.8987007141,
      "lon": -98.4600982666,
      "tz": "America\/Chicago"
  },
  "4TA4": {
      "icao": "4TA4",
      "iata": "",
      "name": "Pea Patch Airport",
      "city": "Hempstead",
      "state": "Texas",
      "country": "US",
      "elevation": 174,
      "lat": 29.9391002655,
      "lon": -96.0597000122,
      "tz": "America\/Chicago"
  },
  "4TA5": {
      "icao": "4TA5",
      "iata": "",
      "name": "Lucky G Airport",
      "city": "Holliday",
      "state": "Texas",
      "country": "US",
      "elevation": 1068,
      "lat": 33.8526000977,
      "lon": -98.6462020874,
      "tz": "America\/Chicago"
  },
  "4TA6": {
      "icao": "4TA6",
      "iata": "",
      "name": "Comanche Caves Ranch Airport",
      "city": "Hunt",
      "state": "Texas",
      "country": "US",
      "elevation": 2000,
      "lat": 30.0223999023,
      "lon": -99.3836975098,
      "tz": "America\/Chicago"
  },
  "4TA8": {
      "icao": "4TA8",
      "iata": "",
      "name": "Perry Ranch Airport",
      "city": "Ozona",
      "state": "Texas",
      "country": "US",
      "elevation": 2250,
      "lat": 30.6168003082,
      "lon": -101.016998291,
      "tz": "America\/Chicago"
  },
  "4TE0": {
      "icao": "4TE0",
      "iata": "",
      "name": "Lone Star Steel Company Airport",
      "city": "Lone Star",
      "state": "Texas",
      "country": "US",
      "elevation": 285,
      "lat": 32.9334983826,
      "lon": -94.7334976196,
      "tz": "America\/Chicago"
  },
  "4TE1": {
      "icao": "4TE1",
      "iata": "",
      "name": "Figure 1 Ranch Airport",
      "city": "Booker",
      "state": "Texas",
      "country": "US",
      "elevation": 2631,
      "lat": 36.266998291,
      "lon": -100.5350036621,
      "tz": "America\/Chicago"
  },
  "4TE2": {
      "icao": "4TE2",
      "iata": "",
      "name": "Lewis Private Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1379,
      "lat": 29.5440998077,
      "lon": -100.672996521,
      "tz": "America\/Chicago"
  },
  "4TE3": {
      "icao": "4TE3",
      "iata": "",
      "name": "Finley Ranch Airport",
      "city": "Claude",
      "state": "Texas",
      "country": "US",
      "elevation": 3400,
      "lat": 35.1166992188,
      "lon": -101.4420013428,
      "tz": "America\/Chicago"
  },
  "4TE4": {
      "icao": "4TE4",
      "iata": "",
      "name": "Taurus Mesa Airport",
      "city": "Alpine",
      "state": "Texas",
      "country": "US",
      "elevation": 3576,
      "lat": 29.6210002899,
      "lon": -103.6790008545,
      "tz": "America\/Chicago"
  },
  "4TE6": {
      "icao": "4TE6",
      "iata": "",
      "name": "Jamison Airstrip",
      "city": "Dayton",
      "state": "Texas",
      "country": "US",
      "elevation": 85,
      "lat": 30.0627002716,
      "lon": -94.9474029541,
      "tz": "America\/Chicago"
  },
  "4TE7": {
      "icao": "4TE7",
      "iata": "",
      "name": "Devil's River Ranch Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1680,
      "lat": 29.7408008575,
      "lon": -100.9649963379,
      "tz": "America\/Chicago"
  },
  "4TE8": {
      "icao": "4TE8",
      "iata": "",
      "name": "Ben Bruce Memorial Airpark",
      "city": "Evadale",
      "state": "Texas",
      "country": "US",
      "elevation": 44,
      "lat": 30.3209991455,
      "lon": -94.0735015869,
      "tz": "America\/Chicago"
  },
  "4TE9": {
      "icao": "4TE9",
      "iata": "",
      "name": "Squirrel Creek Ranch Airport",
      "city": "D'Hanis",
      "state": "Texas",
      "country": "US",
      "elevation": 970,
      "lat": 29.2640991211,
      "lon": -99.3506011963,
      "tz": "America\/Chicago"
  },
  "4TN0": {
      "icao": "4TN0",
      "iata": "",
      "name": "Melton Field",
      "city": "Blaine",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1060,
      "lat": 36.1519012451,
      "lon": -83.7328033447,
      "tz": "America\/New_York"
  },
  "4TN2": {
      "icao": "4TN2",
      "iata": "",
      "name": "Buck Creek Ranch Airport",
      "city": "Crossville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1800,
      "lat": 35.8833999634,
      "lon": -84.9688034058,
      "tz": "America\/Chicago"
  },
  "4TN3": {
      "icao": "4TN3",
      "iata": "",
      "name": "Hawks Nest Airport",
      "city": "Moscow",
      "state": "Tennessee",
      "country": "US",
      "elevation": 410,
      "lat": 35.1183013916,
      "lon": -89.3677978516,
      "tz": "America\/Chicago"
  },
  "4TN4": {
      "icao": "4TN4",
      "iata": "",
      "name": "Cotton Field",
      "city": "Eads",
      "state": "Tennessee",
      "country": "US",
      "elevation": 345,
      "lat": 35.1899986267,
      "lon": -89.6386032104,
      "tz": "America\/Chicago"
  },
  "4TN9": {
      "icao": "4TN9",
      "iata": "",
      "name": "Southfork Airport",
      "city": "Jackson",
      "state": "Tennessee",
      "country": "US",
      "elevation": 387,
      "lat": 35.6108016968,
      "lon": -88.6947021484,
      "tz": "America\/Chicago"
  },
  "4TS5": {
      "icao": "4TS5",
      "iata": "",
      "name": "Goebel Field",
      "city": "Mountain Home",
      "state": "Texas",
      "country": "US",
      "elevation": 2189,
      "lat": 30.2219009399,
      "lon": -99.4983978271,
      "tz": "America\/Chicago"
  },
  "4TS7": {
      "icao": "4TS7",
      "iata": "",
      "name": "Allison Ranch Airport",
      "city": "Fort Stockton",
      "state": "Texas",
      "country": "US",
      "elevation": 4572,
      "lat": 30.4501991272,
      "lon": -102.9270019531,
      "tz": "America\/Chicago"
  },
  "4TS8": {
      "icao": "4TS8",
      "iata": "",
      "name": "Glasscock Field",
      "city": "D'Hanis",
      "state": "Texas",
      "country": "US",
      "elevation": 1089,
      "lat": 29.375,
      "lon": -99.3420028687,
      "tz": "America\/Chicago"
  },
  "4TX0": {
      "icao": "4TX0",
      "iata": "",
      "name": "Slack Airport",
      "city": "Mont Belvieu",
      "state": "Texas",
      "country": "US",
      "elevation": 35,
      "lat": 29.8635997772,
      "lon": -94.8343963623,
      "tz": "America\/Chicago"
  },
  "4TX2": {
      "icao": "4TX2",
      "iata": "",
      "name": "Stage Coach Hills Airport",
      "city": "Keller",
      "state": "Texas",
      "country": "US",
      "elevation": 684,
      "lat": 32.9668006897,
      "lon": -97.2335968018,
      "tz": "America\/Chicago"
  },
  "4TX3": {
      "icao": "4TX3",
      "iata": "",
      "name": "Forest Hill Airport",
      "city": "Petty",
      "state": "Texas",
      "country": "US",
      "elevation": 525,
      "lat": 33.6612014771,
      "lon": -95.8336029053,
      "tz": "America\/Chicago"
  },
  "4TX4": {
      "icao": "4TX4",
      "iata": "",
      "name": "Birk Airport",
      "city": "Kennedale",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.6278991699,
      "lon": -97.1920013428,
      "tz": "America\/Chicago"
  },
  "4TX5": {
      "icao": "4TX5",
      "iata": "",
      "name": "Hancock Airport",
      "city": "Kerens",
      "state": "Texas",
      "country": "US",
      "elevation": 305,
      "lat": 32.2299003601,
      "lon": -96.2596969604,
      "tz": "America\/Chicago"
  },
  "4TX6": {
      "icao": "4TX6",
      "iata": "",
      "name": "Kilgore Airport",
      "city": "Kilgore",
      "state": "Texas",
      "country": "US",
      "elevation": 365,
      "lat": 32.4127006531,
      "lon": -94.8182983398,
      "tz": "America\/Chicago"
  },
  "4TX7": {
      "icao": "4TX7",
      "iata": "",
      "name": "Oliver Airport",
      "city": "Knox City",
      "state": "Texas",
      "country": "US",
      "elevation": 1500,
      "lat": 33.4194984436,
      "lon": -99.8656005859,
      "tz": "America\/Chicago"
  },
  "4TX8": {
      "icao": "4TX8",
      "iata": "",
      "name": "Addington Field",
      "city": "Krum",
      "state": "Texas",
      "country": "US",
      "elevation": 705,
      "lat": 33.2486991882,
      "lon": -97.2238998413,
      "tz": "America\/Chicago"
  },
  "4VA0": {
      "icao": "4VA0",
      "iata": "",
      "name": "Wood Farm Airport",
      "city": "Wingina",
      "state": "Virginia",
      "country": "US",
      "elevation": 350,
      "lat": 37.6365013123,
      "lon": -78.7300033569,
      "tz": "America\/New_York"
  },
  "4VA1": {
      "icao": "4VA1",
      "iata": "",
      "name": "Davis Field",
      "city": "Martinsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 840,
      "lat": 36.6867980957,
      "lon": -79.6660995483,
      "tz": "America\/New_York"
  },
  "4VA3": {
      "icao": "4VA3",
      "iata": "",
      "name": "Flying W Airport",
      "city": "Richmond",
      "state": "Virginia",
      "country": "US",
      "elevation": 101,
      "lat": 37.429901123,
      "lon": -77.3944015503,
      "tz": "America\/New_York"
  },
  "4VA4": {
      "icao": "4VA4",
      "iata": "",
      "name": "Hepner Airport",
      "city": "Woodstock",
      "state": "Virginia",
      "country": "US",
      "elevation": 1150,
      "lat": 38.9347991943,
      "lon": -78.5400009155,
      "tz": "America\/New_York"
  },
  "4VA5": {
      "icao": "4VA5",
      "iata": "",
      "name": "Starr Airport",
      "city": "Arrington",
      "state": "Virginia",
      "country": "US",
      "elevation": 640,
      "lat": 37.6595993042,
      "lon": -78.922203064,
      "tz": "America\/New_York"
  },
  "4VA6": {
      "icao": "4VA6",
      "iata": "",
      "name": "Taylor Airport",
      "city": "Atlantic",
      "state": "Virginia",
      "country": "US",
      "elevation": 48,
      "lat": 37.8806991577,
      "lon": -75.5059967041,
      "tz": "America\/New_York"
  },
  "4VA8": {
      "icao": "4VA8",
      "iata": "",
      "name": "Christian's Airport",
      "city": "Richmond",
      "state": "Virginia",
      "country": "US",
      "elevation": 125,
      "lat": 37.6138000488,
      "lon": -77.2957992554,
      "tz": "America\/New_York"
  },
  "4VA9": {
      "icao": "4VA9",
      "iata": "",
      "name": "Pleasantdale Field",
      "city": "Rixeyville",
      "state": "Virginia",
      "country": "US",
      "elevation": 420,
      "lat": 38.5690002441,
      "lon": -77.920501709,
      "tz": "America\/New_York"
  },
  "4VG2": {
      "icao": "4VG2",
      "iata": "",
      "name": "Cool Water Airport",
      "city": "Ashland",
      "state": "Virginia",
      "country": "US",
      "elevation": 250,
      "lat": 37.858056,
      "lon": -77.547222,
      "tz": "America\/New_York"
  },
  "4WA0": {
      "icao": "4WA0",
      "iata": "",
      "name": "B & G Farms Airport",
      "city": "Royal City",
      "state": "Washington",
      "country": "US",
      "elevation": 1120,
      "lat": 46.9402999878,
      "lon": -119.737998962,
      "tz": "America\/Los_Angeles"
  },
  "4WA1": {
      "icao": "4WA1",
      "iata": "",
      "name": "Brown's Cape Horn Airport",
      "city": "Skamania",
      "state": "Washington",
      "country": "US",
      "elevation": 920,
      "lat": 45.6082992554,
      "lon": -122.1969985962,
      "tz": "America\/Los_Angeles"
  },
  "4WA2": {
      "icao": "4WA2",
      "iata": "",
      "name": "Cricket Field",
      "city": "Little Rock",
      "state": "Washington",
      "country": "US",
      "elevation": 184,
      "lat": 46.9272003174,
      "lon": -122.9680023193,
      "tz": "America\/Los_Angeles"
  },
  "4WA4": {
      "icao": "4WA4",
      "iata": "",
      "name": "Windsock Airport",
      "city": "Lopez",
      "state": "Washington",
      "country": "US",
      "elevation": 30,
      "lat": 48.5415000916,
      "lon": -122.8870010376,
      "tz": "America\/Los_Angeles"
  },
  "4WA6": {
      "icao": "4WA6",
      "iata": "",
      "name": "Hille-Kimp Airstrip",
      "city": "Kahlotus",
      "state": "Washington",
      "country": "US",
      "elevation": 1500,
      "lat": 46.6184997559,
      "lon": -118.5950012207,
      "tz": "America\/Los_Angeles"
  },
  "4WA8": {
      "icao": "4WA8",
      "iata": "",
      "name": "Riverside Airport",
      "city": "Deming",
      "state": "Washington",
      "country": "US",
      "elevation": 175,
      "lat": 48.8348007202,
      "lon": -122.263999939,
      "tz": "America\/Los_Angeles"
  },
  "4WA9": {
      "icao": "4WA9",
      "iata": "",
      "name": "Port Orchard Airport",
      "city": "Port Orchard",
      "state": "Washington",
      "country": "US",
      "elevation": 370,
      "lat": 47.4323005676,
      "lon": -122.6679992676,
      "tz": "America\/Los_Angeles"
  },
  "4WI0": {
      "icao": "4WI0",
      "iata": "",
      "name": "Wissota Airport",
      "city": "Chippewa Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 936,
      "lat": 44.9333000183,
      "lon": -91.2723999023,
      "tz": "America\/Chicago"
  },
  "4WI1": {
      "icao": "4WI1",
      "iata": "",
      "name": "Bancroft East Airport",
      "city": "Rio",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 840,
      "lat": 43.4832992554,
      "lon": -89.1834030151,
      "tz": "America\/Chicago"
  },
  "4WI2": {
      "icao": "4WI2",
      "iata": "",
      "name": "Island View Airport",
      "city": "Crandon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1640,
      "lat": 45.606098175,
      "lon": -89.0214996338,
      "tz": "America\/Chicago"
  },
  "4WI3": {
      "icao": "4WI3",
      "iata": "",
      "name": "Neveln Field",
      "city": "Crivitz",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 900,
      "lat": 45.3251991272,
      "lon": -88.137298584,
      "tz": "America\/Chicago"
  },
  "4WI4": {
      "icao": "4WI4",
      "iata": "",
      "name": "Turner Airport",
      "city": "Tomahawk",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1500,
      "lat": 45.5741004944,
      "lon": -89.7480010986,
      "tz": "America\/Chicago"
  },
  "4WI5": {
      "icao": "4WI5",
      "iata": "",
      "name": "Carlson Airport",
      "city": "Eau Claire",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 900,
      "lat": 44.7235984802,
      "lon": -91.4598999023,
      "tz": "America\/Chicago"
  },
  "4WI6": {
      "icao": "4WI6",
      "iata": "",
      "name": "Feldmann Field",
      "city": "Sheboygan Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 750,
      "lat": 43.8041992188,
      "lon": -87.8973007202,
      "tz": "America\/Chicago"
  },
  "4WI8": {
      "icao": "4WI8",
      "iata": "",
      "name": "Carhart Farms Airport",
      "city": "Galesville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 737,
      "lat": 44.0513000488,
      "lon": -91.3762969971,
      "tz": "America\/Chicago"
  },
  "4WI9": {
      "icao": "4WI9",
      "iata": "",
      "name": "Mar-O-Dae Farm Airport",
      "city": "Glenwood City",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1164,
      "lat": 45.0069007874,
      "lon": -92.1918029785,
      "tz": "America\/Chicago"
  },
  "4WN2": {
      "icao": "4WN2",
      "iata": "",
      "name": "Swensen Airport",
      "city": "Vesper",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1010,
      "lat": 44.4169998169,
      "lon": -89.9863967896,
      "tz": "America\/Chicago"
  },
  "4WN3": {
      "icao": "4WN3",
      "iata": "",
      "name": "Lakewood Airpark",
      "city": "Lakewood",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1300,
      "lat": 45.3320999146,
      "lon": -88.5360031128,
      "tz": "America\/Chicago"
  },
  "4WN4": {
      "icao": "4WN4",
      "iata": "",
      "name": "River Ridge Aero Estates Airport",
      "city": "Holcombe",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1050,
      "lat": 45.2941017151,
      "lon": -91.2329025269,
      "tz": "America\/Chicago"
  },
  "4WN5": {
      "icao": "4WN5",
      "iata": "",
      "name": "Romeo Airstrip",
      "city": "Balsam Lake",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1155,
      "lat": 45.4570007324,
      "lon": -92.3783035278,
      "tz": "America\/Chicago"
  },
  "4WN9": {
      "icao": "4WN9",
      "iata": "",
      "name": "Digger Dougs Airport",
      "city": "Salem",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 42.5975,
      "lon": -88.146389,
      "tz": "America\/Chicago"
  },
  "4WV4": {
      "icao": "4WV4",
      "iata": "",
      "name": "Heaven's Landing Airport",
      "city": "Medley",
      "state": "West Virginia",
      "country": "US",
      "elevation": 1220,
      "lat": 39.1699981689,
      "lon": -79.0616989136,
      "tz": "America\/New_York"
  },
  "4XA2": {
      "icao": "4XA2",
      "iata": "",
      "name": "Card Airfield",
      "city": "Kenedy",
      "state": "Texas",
      "country": "US",
      "elevation": 320,
      "lat": 28.789167,
      "lon": -97.763389,
      "tz": "America\/Chicago"
  },
  "4XA3": {
      "icao": "4XA3",
      "iata": "",
      "name": "Owen Field",
      "city": "Clyde",
      "state": "Texas",
      "country": "US",
      "elevation": 1971,
      "lat": 32.253611,
      "lon": -99.498611,
      "tz": "America\/Chicago"
  },
  "4XA4": {
      "icao": "4XA4",
      "iata": "",
      "name": "Apache Pass Airport",
      "city": "Rockdale",
      "state": "Texas",
      "country": "US",
      "elevation": 410,
      "lat": 30.734958,
      "lon": -97.143378,
      "tz": "America\/Chicago"
  },
  "4XA5": {
      "icao": "4XA5",
      "iata": "",
      "name": "4-Shipp Airport",
      "city": "Burkburnett",
      "state": "Texas",
      "country": "US",
      "elevation": 1004,
      "lat": 34.03775,
      "lon": -98.496,
      "tz": "America\/Chicago"
  },
  "4XS0": {
      "icao": "4XS0",
      "iata": "",
      "name": "Pfeffer & Son Farms Airport",
      "city": "Waller",
      "state": "Texas",
      "country": "US",
      "elevation": 210,
      "lat": 29.9297008514,
      "lon": -95.9641036987,
      "tz": "America\/Chicago"
  },
  "4XS1": {
      "icao": "4XS1",
      "iata": "",
      "name": "Coyote Field",
      "city": "Corsicana",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 32.1338996887,
      "lon": -96.5205993652,
      "tz": "America\/Chicago"
  },
  "4XS2": {
      "icao": "4XS2",
      "iata": "",
      "name": "Teate Field",
      "city": "Paradise",
      "state": "Texas",
      "country": "US",
      "elevation": 795,
      "lat": 33.1706008911,
      "lon": -97.7316970825,
      "tz": "America\/Chicago"
  },
  "4XS5": {
      "icao": "4XS5",
      "iata": "",
      "name": "Scrappin Valley Airport",
      "city": "Wiergate",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 31.1219005585,
      "lon": -93.8030014038,
      "tz": "America\/Chicago"
  },
  "4XS6": {
      "icao": "4XS6",
      "iata": "",
      "name": "H Young Ranch Airport",
      "city": "Willow City",
      "state": "Texas",
      "country": "US",
      "elevation": 1700,
      "lat": 30.3918991089,
      "lon": -98.6613998413,
      "tz": "America\/Chicago"
  },
  "4XS8": {
      "icao": "4XS8",
      "iata": "",
      "name": "Winn Ranch Airport",
      "city": "Wimberley",
      "state": "Texas",
      "country": "US",
      "elevation": 960,
      "lat": 29.9668998718,
      "lon": -98.1169967651,
      "tz": "America\/Chicago"
  },
  "4XS9": {
      "icao": "4XS9",
      "iata": "",
      "name": "Garnett Ranch Airport",
      "city": "Wimberley",
      "state": "Texas",
      "country": "US",
      "elevation": 1300,
      "lat": 30.0960006714,
      "lon": -98.1628036499,
      "tz": "America\/Chicago"
  },
  "50AK": {
      "icao": "50AK",
      "iata": "",
      "name": "Robin'S Landing",
      "city": "Point Mackenzie",
      "state": "Alaska",
      "country": "US",
      "elevation": 95,
      "lat": 61.380461,
      "lon": -150.11625,
      "tz": "America\/Anchorage"
  },
  "50AZ": {
      "icao": "50AZ",
      "iata": "",
      "name": "Rocky Ridge Airport",
      "city": "Rocky Ridge",
      "state": "Arizona",
      "country": "US",
      "elevation": 5946,
      "lat": 36.0597000122,
      "lon": -110.5879974365,
      "tz": "America\/Denver"
  },
  "50FD": {
      "icao": "50FD",
      "iata": "",
      "name": "Cattle Creek Ranch Airport",
      "city": "Altha",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 30.5181999207,
      "lon": -85.1987991333,
      "tz": "America\/Chicago"
  },
  "50FL": {
      "icao": "50FL",
      "iata": "",
      "name": "Odom's Flying Service Airport",
      "city": "Jay",
      "state": "Florida",
      "country": "US",
      "elevation": 245,
      "lat": 30.8533000946,
      "lon": -87.239402771,
      "tz": "America\/Chicago"
  },
  "50GA": {
      "icao": "50GA",
      "iata": "",
      "name": "Mallory Field",
      "city": "Valdosta",
      "state": "Georgia",
      "country": "US",
      "elevation": 202,
      "lat": 30.950799942,
      "lon": -83.2341995239,
      "tz": "America\/New_York"
  },
  "50II": {
      "icao": "50II",
      "iata": "",
      "name": "Way West Airport",
      "city": "Bainbridge",
      "state": "Indiana",
      "country": "US",
      "elevation": 890,
      "lat": 39.7737007141,
      "lon": -86.756401062,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "50IL": {
      "icao": "50IL",
      "iata": "",
      "name": "Midland Airport",
      "city": "Antioch",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 42.488899231,
      "lon": -88.1743011475,
      "tz": "America\/Chicago"
  },
  "50IN": {
      "icao": "50IN",
      "iata": "",
      "name": "Ropkey Field",
      "city": "Crawfordsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 40.058086,
      "lon": -86.795421,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "50KS": {
      "icao": "50KS",
      "iata": "",
      "name": "Grandpa's Farm Airport",
      "city": "Pratt",
      "state": "Kansas",
      "country": "US",
      "elevation": 1820,
      "lat": 37.665599823,
      "lon": -98.6197967529,
      "tz": "America\/Chicago"
  },
  "50KY": {
      "icao": "50KY",
      "iata": "",
      "name": "Zanzibar Farm Airport",
      "city": "Paris",
      "state": "Kentucky",
      "country": "US",
      "elevation": 915,
      "lat": 38.1297988892,
      "lon": -84.3302001953,
      "tz": "America\/New_York"
  },
  "50MN": {
      "icao": "50MN",
      "iata": "",
      "name": "Zimmerman Airport",
      "city": "Herman",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1045,
      "lat": 45.793598175,
      "lon": -96.3001022339,
      "tz": "America\/Chicago"
  },
  "50NC": {
      "icao": "50NC",
      "iata": "",
      "name": "Ervin Airfield",
      "city": "Kannapolis",
      "state": "North Carolina",
      "country": "US",
      "elevation": 905,
      "lat": 35.5449981689,
      "lon": -80.6686019897,
      "tz": "America\/New_York"
  },
  "50NE": {
      "icao": "50NE",
      "iata": "",
      "name": "Elge Field",
      "city": "Aurora",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1800,
      "lat": 40.9399986267,
      "lon": -98.0781021118,
      "tz": "America\/Chicago"
  },
  "50NM": {
      "icao": "50NM",
      "iata": "",
      "name": "Ghost Ranch Strip",
      "city": "Abiquiu",
      "state": "New Mexico",
      "country": "US",
      "elevation": 6440,
      "lat": 36.3027992249,
      "lon": -106.4879989624,
      "tz": "America\/Denver"
  },
  "50NY": {
      "icao": "50NY",
      "iata": "",
      "name": "Old Fort Farm Airport",
      "city": "Hemlock",
      "state": "New York",
      "country": "US",
      "elevation": 1050,
      "lat": 42.8144989014,
      "lon": -77.5733032227,
      "tz": "America\/New_York"
  },
  "50OH": {
      "icao": "50OH",
      "iata": "",
      "name": "Culver Field",
      "city": "Oregon",
      "state": "Ohio",
      "country": "US",
      "elevation": 620,
      "lat": 41.6631011963,
      "lon": -83.4154968262,
      "tz": "America\/New_York"
  },
  "50OI": {
      "icao": "50OI",
      "iata": "",
      "name": "Beckman Field",
      "city": "Ashville",
      "state": "Ohio",
      "country": "US",
      "elevation": 760,
      "lat": 39.7425994873,
      "lon": -83.0382003784,
      "tz": "America\/New_York"
  },
  "50OR": {
      "icao": "50OR",
      "iata": "",
      "name": "Goering Ranches Airport",
      "city": "Alfalfa",
      "state": "Oregon",
      "country": "US",
      "elevation": 3450,
      "lat": 44.0973014832,
      "lon": -120.9469985962,
      "tz": "America\/Los_Angeles"
  },
  "50PA": {
      "icao": "50PA",
      "iata": "",
      "name": "Pegasus Air Park",
      "city": "Stroudsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 620,
      "lat": 40.9333992004,
      "lon": -75.3496017456,
      "tz": "America\/New_York"
  },
  "50TA": {
      "icao": "50TA",
      "iata": "",
      "name": "Moltz Airport",
      "city": "Geronimo",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 29.6877002716,
      "lon": -97.9961013794,
      "tz": "America\/Chicago"
  },
  "50TE": {
      "icao": "50TE",
      "iata": "",
      "name": "Keller Ranch Airport",
      "city": "Johnson City",
      "state": "Texas",
      "country": "US",
      "elevation": 1550,
      "lat": 30.2304992676,
      "lon": -98.4753036499,
      "tz": "America\/Chicago"
  },
  "50TN": {
      "icao": "50TN",
      "iata": "",
      "name": "Paris Landing Airpark",
      "city": "Paris",
      "state": "Tennessee",
      "country": "US",
      "elevation": 400,
      "lat": 36.352339,
      "lon": -88.350492,
      "tz": "America\/Chicago"
  },
  "50TX": {
      "icao": "50TX",
      "iata": "",
      "name": "Kennedy Ranch Airport",
      "city": "Blanco",
      "state": "Texas",
      "country": "US",
      "elevation": 1598,
      "lat": 30.1375999451,
      "lon": -98.5253982544,
      "tz": "America\/Chicago"
  },
  "50WA": {
      "icao": "50WA",
      "iata": "",
      "name": "Hartley Airport",
      "city": "Chehalis",
      "state": "Washington",
      "country": "US",
      "elevation": 400,
      "lat": 46.6198005676,
      "lon": -122.9649963379,
      "tz": "America\/Los_Angeles"
  },
  "50WI": {
      "icao": "50WI",
      "iata": "",
      "name": "Earl's Air Park",
      "city": "Neenah",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 775,
      "lat": 44.113899231,
      "lon": -88.5270996094,
      "tz": "America\/Chicago"
  },
  "50XS": {
      "icao": "50XS",
      "iata": "",
      "name": "Hughes Ranch Airport",
      "city": "Quemado",
      "state": "Texas",
      "country": "US",
      "elevation": 905,
      "lat": 29.0433006287,
      "lon": -100.5849990845,
      "tz": "America\/Chicago"
  },
  "51AK": {
      "icao": "51AK",
      "iata": "",
      "name": "Birch Creek Landing",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 400,
      "lat": 62.242289,
      "lon": -150.065814,
      "tz": "America\/Anchorage"
  },
  "51CA": {
      "icao": "51CA",
      "iata": "",
      "name": "Kelly Airport",
      "city": "Lucerne Valley",
      "state": "California",
      "country": "US",
      "elevation": 2806,
      "lat": 34.4235992432,
      "lon": -116.6169967651,
      "tz": "America\/Los_Angeles"
  },
  "51CL": {
      "icao": "51CL",
      "iata": "",
      "name": "Vetters Sky Ranch Airport",
      "city": "Galt",
      "state": "California",
      "country": "US",
      "elevation": 80,
      "lat": 38.2304992676,
      "lon": -121.2220001221,
      "tz": "America\/Los_Angeles"
  },
  "51CO": {
      "icao": "51CO",
      "iata": "",
      "name": "Flying W No.2 Airport",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 7105,
      "lat": 38.8856010437,
      "lon": -107.8040008545,
      "tz": "America\/Denver"
  },
  "51FD": {
      "icao": "51FD",
      "iata": "",
      "name": "Tex Merritt Private Airstrip",
      "city": "Leesburg",
      "state": "Florida",
      "country": "US",
      "elevation": 95,
      "lat": 28.6678009033,
      "lon": -81.9180984497,
      "tz": "America\/New_York"
  },
  "51FL": {
      "icao": "51FL",
      "iata": "",
      "name": "Chiefland Sky Ranch Airport",
      "city": "Chiefland",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 29.39489937,
      "lon": -82.86979675,
      "tz": "America\/New_York"
  },
  "51GA": {
      "icao": "51GA",
      "iata": "",
      "name": "Smith Field",
      "city": "Tyrone",
      "state": "Georgia",
      "country": "US",
      "elevation": 920,
      "lat": 33.5080986023,
      "lon": -84.6005020142,
      "tz": "America\/New_York"
  },
  "51IL": {
      "icao": "51IL",
      "iata": "",
      "name": "Marvin D Bradd Airport",
      "city": "Mansfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 797,
      "lat": 40.2717018127,
      "lon": -88.4944992065,
      "tz": "America\/Chicago"
  },
  "51IN": {
      "icao": "51IN",
      "iata": "",
      "name": "Dahnke Airport",
      "city": "West Lafayette",
      "state": "Indiana",
      "country": "US",
      "elevation": 670,
      "lat": 40.4928016663,
      "lon": -86.9056015015,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "51IS": {
      "icao": "51IS",
      "iata": "",
      "name": "Curry Airport",
      "city": "Griggsville",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 39.659198761,
      "lon": -90.7001037598,
      "tz": "America\/Chicago"
  },
  "51KS": {
      "icao": "51KS",
      "iata": "",
      "name": "Barnard Airport",
      "city": "Elmo",
      "state": "Kansas",
      "country": "US",
      "elevation": 1420,
      "lat": 38.6370010376,
      "lon": -97.2136001587,
      "tz": "America\/Chicago"
  },
  "51LA": {
      "icao": "51LA",
      "iata": "",
      "name": "R T Leblanc Airport",
      "city": "White Castle",
      "state": "Louisiana",
      "country": "US",
      "elevation": 15,
      "lat": 30.1434993744,
      "lon": -91.1531982422,
      "tz": "America\/Chicago"
  },
  "51LS": {
      "icao": "51LS",
      "iata": "",
      "name": "Red Oak Landing",
      "city": "Sunset",
      "state": "Louisiana",
      "country": "US",
      "elevation": 55,
      "lat": 30.438053,
      "lon": -92.116653,
      "tz": "America\/Chicago"
  },
  "51NC": {
      "icao": "51NC",
      "iata": "",
      "name": "Field of Dreams Airport",
      "city": "Zebulon",
      "state": "North Carolina",
      "country": "US",
      "elevation": 355,
      "lat": 35.8619003296,
      "lon": -78.3486022949,
      "tz": "America\/New_York"
  },
  "51NE": {
      "icao": "51NE",
      "iata": "",
      "name": "Bil Lo Airport",
      "city": "Fort Calhoun",
      "state": "Nebraska",
      "country": "US",
      "elevation": 997,
      "lat": 41.4707984924,
      "lon": -96.0211029053,
      "tz": "America\/Chicago"
  },
  "51NY": {
      "icao": "51NY",
      "iata": "",
      "name": "Maxon Field",
      "city": "Holley",
      "state": "New York",
      "country": "US",
      "elevation": 615,
      "lat": 43.1795005798,
      "lon": -78.0397033691,
      "tz": "America\/New_York"
  },
  "51OH": {
      "icao": "51OH",
      "iata": "",
      "name": "Agner Airport",
      "city": "Ottawa",
      "state": "Ohio",
      "country": "US",
      "elevation": 740,
      "lat": 41.0703010559,
      "lon": -84.0863037109,
      "tz": "America\/New_York"
  },
  "51OI": {
      "icao": "51OI",
      "iata": "",
      "name": "Clay's Rv Airport",
      "city": "Canal Fulton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1035,
      "lat": 40.8550987244,
      "lon": -81.5989990234,
      "tz": "America\/New_York"
  },
  "51OK": {
      "icao": "51OK",
      "iata": "",
      "name": "Eagle Creek Airport",
      "city": "Beggs",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 740,
      "lat": 35.793611,
      "lon": -96.029722,
      "tz": "America\/Chicago"
  },
  "51PA": {
      "icao": "51PA",
      "iata": "",
      "name": "Lamberson Airport",
      "city": "New Oxford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 540,
      "lat": 39.8833999634,
      "lon": -77.0830001831,
      "tz": "America\/New_York"
  },
  "51SC": {
      "icao": "51SC",
      "iata": "",
      "name": "Moccasin Creek Airport",
      "city": "Ehrhardt",
      "state": "South Carolina",
      "country": "US",
      "elevation": 142,
      "lat": 33.14220047,
      "lon": -80.9555969238,
      "tz": "America\/New_York"
  },
  "51TE": {
      "icao": "51TE",
      "iata": "",
      "name": "Barstool Ranch Airport",
      "city": "Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 546,
      "lat": 32.470192,
      "lon": -97.787119,
      "tz": "America\/Chicago"
  },
  "51TN": {
      "icao": "51TN",
      "iata": "",
      "name": "Glendale Field",
      "city": "Columbia",
      "state": "Tennessee",
      "country": "US",
      "elevation": 750,
      "lat": 35.5331001282,
      "lon": -86.9768981934,
      "tz": "America\/Chicago"
  },
  "51TX": {
      "icao": "51TX",
      "iata": "",
      "name": "N D Ranch Airport",
      "city": "Van",
      "state": "Texas",
      "country": "US",
      "elevation": 540,
      "lat": 32.5396003723,
      "lon": -95.668296814,
      "tz": "America\/Chicago"
  },
  "51VA": {
      "icao": "51VA",
      "iata": "",
      "name": "Skyview Airport",
      "city": "Manassas",
      "state": "Virginia",
      "country": "US",
      "elevation": 340,
      "lat": 38.7162017822,
      "lon": -77.6339035034,
      "tz": "America\/New_York"
  },
  "51WA": {
      "icao": "51WA",
      "iata": "",
      "name": "Evergreen Sky Ranch Airport",
      "city": "Black Diamond",
      "state": "Washington",
      "country": "US",
      "elevation": 580,
      "lat": 47.2681999207,
      "lon": -122.0680007935,
      "tz": "America\/Los_Angeles"
  },
  "51WI": {
      "icao": "51WI",
      "iata": "",
      "name": "Gallinger Airport",
      "city": "Oshkosh",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 44.0628013611,
      "lon": -88.5572967529,
      "tz": "America\/Chicago"
  },
  "51WT": {
      "icao": "51WT",
      "iata": "",
      "name": "Whiterik Field",
      "city": "Fruitland",
      "state": "Washington",
      "country": "US",
      "elevation": 1895,
      "lat": 48.010417,
      "lon": -118.362028,
      "tz": "America\/Los_Angeles"
  },
  "52AK": {
      "icao": "52AK",
      "iata": "",
      "name": "Basquo Airport",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 63,
      "lat": 60.5057983398,
      "lon": -151.2460021973,
      "tz": "America\/Anchorage"
  },
  "52AR": {
      "icao": "52AR",
      "iata": "",
      "name": "Ira's Airstrip",
      "city": "Greenbrier",
      "state": "Arkansas",
      "country": "US",
      "elevation": 590,
      "lat": 35.2801017761,
      "lon": -92.2415008545,
      "tz": "America\/Chicago"
  },
  "52AZ": {
      "icao": "52AZ",
      "iata": "",
      "name": "X-1 Ranch Upper Headquarters Airport",
      "city": "Seligman",
      "state": "Arizona",
      "country": "US",
      "elevation": 5585,
      "lat": 35.3833007813,
      "lon": -113.3010025024,
      "tz": "America\/Phoenix"
  },
  "52CA": {
      "icao": "52CA",
      "iata": "",
      "name": "Lake Mathews Airport",
      "city": "Riverside",
      "state": "California",
      "country": "US",
      "elevation": 1385,
      "lat": 33.853099823,
      "lon": -117.4240036011,
      "tz": "America\/Los_Angeles"
  },
  "52CL": {
      "icao": "52CL",
      "iata": "",
      "name": "Adelanto Airport",
      "city": "Adelanto",
      "state": "California",
      "country": "US",
      "elevation": 3075,
      "lat": 34.5374984741,
      "lon": -117.4609985352,
      "tz": "America\/Los_Angeles"
  },
  "52CN": {
      "icao": "52CN",
      "iata": "",
      "name": "Ponderosa Sky Ranch Airport",
      "city": "Paynes Creek",
      "state": "California",
      "country": "US",
      "elevation": 3454,
      "lat": 40.3442993164,
      "lon": -121.7750015259,
      "tz": "America\/Los_Angeles"
  },
  "52CO": {
      "icao": "52CO",
      "iata": "",
      "name": "Mountain View Ranch Airport",
      "city": "Monument",
      "state": "Colorado",
      "country": "US",
      "elevation": 7500,
      "lat": 39.1096992493,
      "lon": -104.6539993286,
      "tz": "America\/Denver"
  },
  "52FA": {
      "icao": "52FA",
      "iata": "",
      "name": "Bent Willies Airport",
      "city": "Lake Wales",
      "state": "Florida",
      "country": "US",
      "elevation": 73,
      "lat": 27.939611,
      "lon": -81.432028,
      "tz": "America\/New_York"
  },
  "52FL": {
      "icao": "52FL",
      "iata": "",
      "name": "Lake Clinch Airpark",
      "city": "Frostproof",
      "state": "Florida",
      "country": "US",
      "elevation": 140,
      "lat": 27.7502994537,
      "lon": -81.5595016479,
      "tz": "America\/New_York"
  },
  "52IL": {
      "icao": "52IL",
      "iata": "",
      "name": "Hasselbring Airport",
      "city": "Paxton",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 40.4103012085,
      "lon": -88.0649032593,
      "tz": "America\/Chicago"
  },
  "52IN": {
      "icao": "52IN",
      "iata": "",
      "name": "Higginbotham Field",
      "city": "Prairieton",
      "state": "Indiana",
      "country": "US",
      "elevation": 452,
      "lat": 39.3414001465,
      "lon": -87.5314025879,
      "tz": "America\/Chicago"
  },
  "52KS": {
      "icao": "52KS",
      "iata": "",
      "name": "Bland Airport",
      "city": "Eudora",
      "state": "Kansas",
      "country": "US",
      "elevation": 935,
      "lat": 38.9028015137,
      "lon": -95.1322021484,
      "tz": "America\/Chicago"
  },
  "52KY": {
      "icao": "52KY",
      "iata": "",
      "name": "Air Castle Airport",
      "city": "Paris",
      "state": "Kentucky",
      "country": "US",
      "elevation": 900,
      "lat": 38.1180992126,
      "lon": -84.3023986816,
      "tz": "America\/New_York"
  },
  "52MN": {
      "icao": "52MN",
      "iata": "",
      "name": "Anderson Field",
      "city": "Kennedy",
      "state": "Minnesota",
      "country": "US",
      "elevation": 800,
      "lat": 48.6430015564,
      "lon": -96.8072967529,
      "tz": "America\/Chicago"
  },
  "52MO": {
      "icao": "52MO",
      "iata": "",
      "name": "Pleasant View Ultralightport",
      "city": "Zell",
      "state": "Missouri",
      "country": "US",
      "elevation": 700,
      "lat": 37.9622993469,
      "lon": -90.1443023682,
      "tz": "America\/Chicago"
  },
  "52NC": {
      "icao": "52NC",
      "iata": "",
      "name": "Epley Airport",
      "city": "Jacksonville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 43,
      "lat": 34.8446006775,
      "lon": -77.2667007446,
      "tz": "America\/New_York"
  },
  "52ND": {
      "icao": "52ND",
      "iata": "",
      "name": "Richtsmeier Airport",
      "city": "Hunter",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1047,
      "lat": 47.1893997192,
      "lon": -97.2894973755,
      "tz": "America\/Chicago"
  },
  "52NE": {
      "icao": "52NE",
      "iata": "",
      "name": "Svitak Airport",
      "city": "Morrill",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4183,
      "lat": 42.0449981689,
      "lon": -103.966003418,
      "tz": "America\/Denver"
  },
  "52NM": {
      "icao": "52NM",
      "iata": "",
      "name": "Timberon Airport",
      "city": "Alamogordo",
      "state": "New Mexico",
      "country": "US",
      "elevation": 6940,
      "lat": 32.6338996887,
      "lon": -105.6859970093,
      "tz": "America\/Denver"
  },
  "52NY": {
      "icao": "52NY",
      "iata": "",
      "name": "Bedson's Land Base Airport",
      "city": "Honeoye Falls",
      "state": "New York",
      "country": "US",
      "elevation": 804,
      "lat": 42.9305992126,
      "lon": -77.644203186,
      "tz": "America\/New_York"
  },
  "52OR": {
      "icao": "52OR",
      "iata": "",
      "name": "Chinook Ultralight Airpark",
      "city": "Scappoose",
      "state": "Oregon",
      "country": "US",
      "elevation": 40,
      "lat": 45.7453994751,
      "lon": -122.8460006714,
      "tz": "America\/Los_Angeles"
  },
  "52PA": {
      "icao": "52PA",
      "iata": "",
      "name": "Wildcat Airport",
      "city": "Tamaqua",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 980,
      "lat": 40.7630996704,
      "lon": -76.004699707,
      "tz": "America\/New_York"
  },
  "52TA": {
      "icao": "52TA",
      "iata": "",
      "name": "Red Wing Airport",
      "city": "Trenton",
      "state": "Texas",
      "country": "US",
      "elevation": 755,
      "lat": 33.4640007019,
      "lon": -96.3503036499,
      "tz": "America\/Chicago"
  },
  "52TE": {
      "icao": "52TE",
      "iata": "",
      "name": "Robinson Ranch Airport",
      "city": "Junction",
      "state": "Texas",
      "country": "US",
      "elevation": 2100,
      "lat": 30.5168991089,
      "lon": -99.516998291,
      "tz": "America\/Chicago"
  },
  "52TS": {
      "icao": "52TS",
      "iata": "",
      "name": "Fall Creek Air Ranch STOLport",
      "city": "Spicewood",
      "state": "Texas",
      "country": "US",
      "elevation": 915,
      "lat": 30.4185009003,
      "lon": -98.1628036499,
      "tz": "America\/Chicago"
  },
  "52WA": {
      "icao": "52WA",
      "iata": "",
      "name": "Honn Farm Airport",
      "city": "Benge",
      "state": "Washington",
      "country": "US",
      "elevation": 1620,
      "lat": 46.9320983887,
      "lon": -117.9759979248,
      "tz": "America\/Los_Angeles"
  },
  "52WI": {
      "icao": "52WI",
      "iata": "",
      "name": "John'S Field",
      "city": "Athens",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1450,
      "lat": 45.159644,
      "lon": -90.056883,
      "tz": "America\/Chicago"
  },
  "53AK": {
      "icao": "53AK",
      "iata": "",
      "name": "Lakewood Airstrip",
      "city": "Sterling",
      "state": "Alaska",
      "country": "US",
      "elevation": 110,
      "lat": 60.5345001221,
      "lon": -150.9389953613,
      "tz": "America\/Anchorage"
  },
  "53AZ": {
      "icao": "53AZ",
      "iata": "",
      "name": "Shonto Airport",
      "city": "Shonto",
      "state": "Arizona",
      "country": "US",
      "elevation": 6449,
      "lat": 36.5881004333,
      "lon": -110.6549987793,
      "tz": "America\/Denver"
  },
  "53CL": {
      "icao": "53CL",
      "iata": "",
      "name": "Lofty Redwoods Airport",
      "city": "Anchor Bay",
      "state": "California",
      "country": "US",
      "elevation": 1317,
      "lat": 38.8596000671,
      "lon": -123.591003418,
      "tz": "America\/Los_Angeles"
  },
  "53CN": {
      "icao": "53CN",
      "iata": "",
      "name": "Thayer Aviation Airport",
      "city": "Grimes",
      "state": "California",
      "country": "US",
      "elevation": 54,
      "lat": 39.0749015808,
      "lon": -121.9179992676,
      "tz": "America\/Los_Angeles"
  },
  "53CO": {
      "icao": "53CO",
      "iata": "",
      "name": "Rons Field",
      "city": "Two Buttes",
      "state": "Colorado",
      "country": "US",
      "elevation": 4096,
      "lat": 37.5531005859,
      "lon": -102.391998291,
      "tz": "America\/Denver"
  },
  "53FD": {
      "icao": "53FD",
      "iata": "",
      "name": "Charlotte's Field",
      "city": "Tallahassee",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 30.4775009155,
      "lon": -84.0346984863,
      "tz": "America\/New_York"
  },
  "53GA": {
      "icao": "53GA",
      "iata": "",
      "name": "Dawson Field",
      "city": "Cave Spring",
      "state": "Georgia",
      "country": "US",
      "elevation": 675,
      "lat": 34.1678009033,
      "lon": -85.2585983276,
      "tz": "America\/New_York"
  },
  "53IA": {
      "icao": "53IA",
      "iata": "",
      "name": "Tama Airport",
      "city": "Tama",
      "state": "Iowa",
      "country": "US",
      "elevation": 813,
      "lat": 41.9500007629,
      "lon": -92.5765991211,
      "tz": "America\/Chicago"
  },
  "53II": {
      "icao": "53II",
      "iata": "",
      "name": "Steinman Airport",
      "city": "Woodburn",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 41.0731010437,
      "lon": -84.8407974243,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "53IL": {
      "icao": "53IL",
      "iata": "",
      "name": "Clapper Airport",
      "city": "Mansfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 710,
      "lat": 40.1528015137,
      "lon": -88.5167007446,
      "tz": "America\/Chicago"
  },
  "53IN": {
      "icao": "53IN",
      "iata": "",
      "name": "Hartman Farms Field",
      "city": "Kokomo",
      "state": "Indiana",
      "country": "US",
      "elevation": 818,
      "lat": 40.5214004517,
      "lon": -86.2149963379,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "53KS": {
      "icao": "53KS",
      "iata": "",
      "name": "Weaver Ranch Airport",
      "city": "Hesston",
      "state": "Kansas",
      "country": "US",
      "elevation": 1460,
      "lat": 38.1082992554,
      "lon": -97.432800293,
      "tz": "America\/Chicago"
  },
  "53KY": {
      "icao": "53KY",
      "iata": "",
      "name": "Short Airport",
      "city": "Eubank",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1130,
      "lat": 37.2723007202,
      "lon": -84.5571975708,
      "tz": "America\/New_York"
  },
  "53MN": {
      "icao": "53MN",
      "iata": "",
      "name": "Radloff's Cedar View Farms Airport",
      "city": "Lyle",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1240,
      "lat": 43.5458984375,
      "lon": -93.0266036987,
      "tz": "America\/Chicago"
  },
  "53NC": {
      "icao": "53NC",
      "iata": "",
      "name": "Mynatt Field",
      "city": "Lumberton",
      "state": "North Carolina",
      "country": "US",
      "elevation": 95,
      "lat": 34.3781013489,
      "lon": -79.0805969238,
      "tz": "America\/New_York"
  },
  "53ND": {
      "icao": "53ND",
      "iata": "",
      "name": "R. Leep Strip",
      "city": "Hurdsfield",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1894,
      "lat": 47.5480995178,
      "lon": -99.9470977783,
      "tz": "America\/Chicago"
  },
  "53NE": {
      "icao": "53NE",
      "iata": "",
      "name": "Starns Brothers Airport",
      "city": "Ashland",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1090,
      "lat": 41.081401825,
      "lon": -96.4033966064,
      "tz": "America\/Chicago"
  },
  "53NY": {
      "icao": "53NY",
      "iata": "",
      "name": "Smiths Land Base Airport",
      "city": "Honeoye Falls",
      "state": "New York",
      "country": "US",
      "elevation": 627,
      "lat": 42.9584007263,
      "lon": -77.6157989502,
      "tz": "America\/New_York"
  },
  "53OH": {
      "icao": "53OH",
      "iata": "",
      "name": "Charloe Airport",
      "city": "Paulding",
      "state": "Ohio",
      "country": "US",
      "elevation": 721,
      "lat": 41.1344985962,
      "lon": -84.4507980347,
      "tz": "America\/New_York"
  },
  "53OK": {
      "icao": "53OK",
      "iata": "",
      "name": "Thompson Private Airport",
      "city": "Tuttle",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1280,
      "lat": 35.2500991821,
      "lon": -97.766998291,
      "tz": "America\/Chicago"
  },
  "53TA": {
      "icao": "53TA",
      "iata": "",
      "name": "Shivers Private Airport",
      "city": "Vernon",
      "state": "Texas",
      "country": "US",
      "elevation": 1215,
      "lat": 34.1543006897,
      "lon": -99.2641983032,
      "tz": "America\/Chicago"
  },
  "53TS": {
      "icao": "53TS",
      "iata": "",
      "name": "Bridges Field",
      "city": "Tioga",
      "state": "Texas",
      "country": "US",
      "elevation": 715,
      "lat": 33.4840011597,
      "lon": -96.8392028809,
      "tz": "America\/Chicago"
  },
  "53TX": {
      "icao": "53TX",
      "iata": "",
      "name": "Cannon Field",
      "city": "Somerset",
      "state": "Texas",
      "country": "US",
      "elevation": 610,
      "lat": 29.2161006927,
      "lon": -98.5494995117,
      "tz": "America\/Chicago"
  },
  "53VA": {
      "icao": "53VA",
      "iata": "",
      "name": "Horse Feathers Airport",
      "city": "Midland",
      "state": "Virginia",
      "country": "US",
      "elevation": 390,
      "lat": 38.6339988708,
      "lon": -77.7574996948,
      "tz": "America\/New_York"
  },
  "53WA": {
      "icao": "53WA",
      "iata": "",
      "name": "Columbia Agricultural Airport",
      "city": "Pasco",
      "state": "Washington",
      "country": "US",
      "elevation": 535,
      "lat": 46.2924003601,
      "lon": -118.983001709,
      "tz": "America\/Los_Angeles"
  },
  "53WI": {
      "icao": "53WI",
      "iata": "",
      "name": "Kelly Airport",
      "city": "Argyle",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1020,
      "lat": 42.6981010437,
      "lon": -89.7867965698,
      "tz": "America\/Chicago"
  },
  "53XS": {
      "icao": "53XS",
      "iata": "",
      "name": "Kornegay Private Airport",
      "city": "San Benito",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 26.148399353,
      "lon": -97.5916976929,
      "tz": "America\/Chicago"
  },
  "54AR": {
      "icao": "54AR",
      "iata": "",
      "name": "Winfield Airpark",
      "city": "Altus",
      "state": "Arkansas",
      "country": "US",
      "elevation": 390,
      "lat": 35.392856,
      "lon": -93.758706,
      "tz": "America\/Chicago"
  },
  "54AZ": {
      "icao": "54AZ",
      "iata": "",
      "name": "Somerton Airport",
      "city": "Somerton",
      "state": "Arizona",
      "country": "US",
      "elevation": 177,
      "lat": 32.6008987427,
      "lon": -114.6660003662,
      "tz": "America\/Phoenix"
  },
  "54CL": {
      "icao": "54CL",
      "iata": "",
      "name": "Lake Riverside Estates Airport",
      "city": "Anza",
      "state": "California",
      "country": "US",
      "elevation": 3410,
      "lat": 33.5209007263,
      "lon": -116.7969970703,
      "tz": "America\/Los_Angeles"
  },
  "54CN": {
      "icao": "54CN",
      "iata": "",
      "name": "Akin Airport",
      "city": "Placerville",
      "state": "California",
      "country": "US",
      "elevation": 1595,
      "lat": 38.7541007996,
      "lon": -120.8990020752,
      "tz": "America\/Los_Angeles"
  },
  "54GA": {
      "icao": "54GA",
      "iata": "",
      "name": "Deerfield Landing Airport",
      "city": "Hampton",
      "state": "Georgia",
      "country": "US",
      "elevation": 890,
      "lat": 33.4642982483,
      "lon": -84.2683029175,
      "tz": "America\/New_York"
  },
  "54II": {
      "icao": "54II",
      "iata": "",
      "name": "Caldwell Field",
      "city": "Bunker Hill",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 40.6245002747,
      "lon": -86.1552963257,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "54IL": {
      "icao": "54IL",
      "iata": "",
      "name": "Rothrock Airport",
      "city": "Streator",
      "state": "Illinois",
      "country": "US",
      "elevation": 653,
      "lat": 41.1286010742,
      "lon": -88.8020019531,
      "tz": "America\/Chicago"
  },
  "54IS": {
      "icao": "54IS",
      "iata": "",
      "name": "Zea Mays Field",
      "city": "Ohio",
      "state": "Illinois",
      "country": "US",
      "elevation": 766,
      "lat": 41.5266990662,
      "lon": -89.4180984497,
      "tz": "America\/Chicago"
  },
  "54KS": {
      "icao": "54KS",
      "iata": "",
      "name": "Michael Airport",
      "city": "Wellsville",
      "state": "Kansas",
      "country": "US",
      "elevation": 1020,
      "lat": 38.8027992249,
      "lon": -95.112701416,
      "tz": "America\/Chicago"
  },
  "54KY": {
      "icao": "54KY",
      "iata": "",
      "name": "Turner Field",
      "city": "Murray",
      "state": "Kentucky",
      "country": "US",
      "elevation": 350,
      "lat": 36.731098175,
      "lon": -88.2472000122,
      "tz": "America\/Chicago"
  },
  "54LA": {
      "icao": "54LA",
      "iata": "",
      "name": "Triangle J Airport",
      "city": "Baton Rouge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 69,
      "lat": 30.5627002716,
      "lon": -91.0383987427,
      "tz": "America\/Chicago"
  },
  "54ME": {
      "icao": "54ME",
      "iata": "",
      "name": "Cliff Dow Airport",
      "city": "New Gloucester",
      "state": "Maine",
      "country": "US",
      "elevation": 415,
      "lat": 43.949444,
      "lon": -70.320278,
      "tz": "America\/New_York"
  },
  "54MI": {
      "icao": "54MI",
      "iata": "",
      "name": "Pingston Aeroworks Airport",
      "city": "Bryon",
      "state": "Michigan",
      "country": "US",
      "elevation": 880,
      "lat": 42.8102989197,
      "lon": -84.0830993652,
      "tz": "America\/Detroit"
  },
  "54MN": {
      "icao": "54MN",
      "iata": "",
      "name": "Sell's Flying Field",
      "city": "Mayer",
      "state": "Minnesota",
      "country": "US",
      "elevation": 980,
      "lat": 44.8791007996,
      "lon": -93.8854980469,
      "tz": "America\/Chicago"
  },
  "54MO": {
      "icao": "54MO",
      "iata": "",
      "name": "Ozark's Flying Patch Airport",
      "city": "Sparta",
      "state": "Missouri",
      "country": "US",
      "elevation": 1530,
      "lat": 37.0013999939,
      "lon": -92.9404983521,
      "tz": "America\/Chicago"
  },
  "54NC": {
      "icao": "54NC",
      "iata": "",
      "name": "Dillard Airport",
      "city": "Winfall",
      "state": "North Carolina",
      "country": "US",
      "elevation": 15,
      "lat": 36.2393989563,
      "lon": -76.4171981812,
      "tz": "America\/New_York"
  },
  "54NY": {
      "icao": "54NY",
      "iata": "",
      "name": "Erb Acres Airport",
      "city": "Angelica",
      "state": "New York",
      "country": "US",
      "elevation": 1940,
      "lat": 42.3550987244,
      "lon": -78.0353012085,
      "tz": "America\/New_York"
  },
  "54OH": {
      "icao": "54OH",
      "iata": "",
      "name": "Buehler Airport",
      "city": "Paulding",
      "state": "Ohio",
      "country": "US",
      "elevation": 728,
      "lat": 41.1234016418,
      "lon": -84.6176986694,
      "tz": "America\/New_York"
  },
  "54OK": {
      "icao": "54OK",
      "iata": "",
      "name": "Ambassador Ultralightport",
      "city": "Mounds",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 687,
      "lat": 35.8694992065,
      "lon": -96.0350036621,
      "tz": "America\/Chicago"
  },
  "54TA": {
      "icao": "54TA",
      "iata": "",
      "name": "George P Shanks Airport",
      "city": "Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 610,
      "lat": 32.2997016907,
      "lon": -96.873298645,
      "tz": "America\/Chicago"
  },
  "54TE": {
      "icao": "54TE",
      "iata": "",
      "name": "Camelot Airport",
      "city": "Thrall",
      "state": "Texas",
      "country": "US",
      "elevation": 480,
      "lat": 30.5151996613,
      "lon": -97.2668991089,
      "tz": "America\/Chicago"
  },
  "54TS": {
      "icao": "54TS",
      "iata": "",
      "name": "J Bar Wc Ranch Airport",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 1235,
      "lat": 32.8111991882,
      "lon": -99.8517990112,
      "tz": "America\/Chicago"
  },
  "54TX": {
      "icao": "54TX",
      "iata": "",
      "name": "Flying H Ranch Airport",
      "city": "Kingsland",
      "state": "Texas",
      "country": "US",
      "elevation": 1000,
      "lat": 30.677400589,
      "lon": -98.5252990723,
      "tz": "America\/Chicago"
  },
  "54WI": {
      "icao": "54WI",
      "iata": "",
      "name": "Flyplassen Airport",
      "city": "Woodville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1300,
      "lat": 45.0083007813,
      "lon": -92.2751998901,
      "tz": "America\/Chicago"
  },
  "55AK": {
      "icao": "55AK",
      "iata": "",
      "name": "Lawrence Airstrip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 61.4958000183,
      "lon": -149.699005127,
      "tz": "America\/Anchorage"
  },
  "55AZ": {
      "icao": "55AZ",
      "iata": "",
      "name": "Potters Field",
      "city": "Stanfield",
      "state": "Arizona",
      "country": "US",
      "elevation": 1309,
      "lat": 32.8739013672,
      "lon": -111.9599990845,
      "tz": "America\/Phoenix"
  },
  "55CL": {
      "icao": "55CL",
      "iata": "",
      "name": "Costerisan Farms Airport",
      "city": "Bakersfield",
      "state": "California",
      "country": "US",
      "elevation": 346,
      "lat": 35.2633018494,
      "lon": -119.0459976196,
      "tz": "America\/Los_Angeles"
  },
  "55FD": {
      "icao": "55FD",
      "iata": "",
      "name": "Dotson Airport",
      "city": "Baker",
      "state": "Florida",
      "country": "US",
      "elevation": 251,
      "lat": 30.8185005188,
      "lon": -86.68800354,
      "tz": "America\/Chicago"
  },
  "55GE": {
      "icao": "55GE",
      "iata": "",
      "name": "Rostex Airport",
      "city": "Tennga",
      "state": "Georgia",
      "country": "US",
      "elevation": 887,
      "lat": 34.9805984497,
      "lon": -84.7416000366,
      "tz": "America\/New_York"
  },
  "55IL": {
      "icao": "55IL",
      "iata": "",
      "name": "Brandt Airport",
      "city": "Manteno",
      "state": "Illinois",
      "country": "US",
      "elevation": 680,
      "lat": 41.2778015137,
      "lon": -87.822303772,
      "tz": "America\/Chicago"
  },
  "55IN": {
      "icao": "55IN",
      "iata": "",
      "name": "Brenneke Airport",
      "city": "Woodburn",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 41.1446990967,
      "lon": -84.862197876,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "55IS": {
      "icao": "55IS",
      "iata": "",
      "name": "Glatthaar Airport",
      "city": "Grandview",
      "state": "Illinois",
      "country": "US",
      "elevation": 765,
      "lat": 39.5250015259,
      "lon": -87.8059005737,
      "tz": "America\/Chicago"
  },
  "55LL": {
      "icao": "55LL",
      "iata": "",
      "name": "Sky Soaring Airport",
      "city": "Union",
      "state": "Illinois",
      "country": "US",
      "elevation": 884,
      "lat": 42.1542015076,
      "lon": -88.5114974976,
      "tz": "America\/Chicago"
  },
  "55ME": {
      "icao": "55ME",
      "iata": "",
      "name": "HIghland View Field",
      "city": "Searsmont",
      "state": "Maine",
      "country": "US",
      "elevation": 600,
      "lat": 44.318056,
      "lon": -69.138333,
      "tz": "America\/New_York"
  },
  "55MN": {
      "icao": "55MN",
      "iata": "",
      "name": "Conley Field",
      "city": "Mora",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1004,
      "lat": 45.8352012634,
      "lon": -93.3355026245,
      "tz": "America\/Chicago"
  },
  "55MO": {
      "icao": "55MO",
      "iata": "",
      "name": "Tightsqueeze Field",
      "city": "Jonesburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 38.8722991943,
      "lon": -91.2602005005,
      "tz": "America\/Chicago"
  },
  "55ND": {
      "icao": "55ND",
      "iata": "",
      "name": "Pete's Port Airport",
      "city": "Killdeer",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2260,
      "lat": 47.3555984497,
      "lon": -102.7109985352,
      "tz": "America\/Denver"
  },
  "55NM": {
      "icao": "55NM",
      "iata": "",
      "name": "Burris Ranch Nr 1 Airport",
      "city": "Belen",
      "state": "New Mexico",
      "country": "US",
      "elevation": 5218,
      "lat": 34.4833984375,
      "lon": -106.6129989624,
      "tz": "America\/Denver"
  },
  "55NY": {
      "icao": "55NY",
      "iata": "",
      "name": "Ridgeview Airport",
      "city": "Jamestown",
      "state": "New York",
      "country": "US",
      "elevation": 1645,
      "lat": 42.0820007324,
      "lon": -79.3123016357,
      "tz": "America\/New_York"
  },
  "55OI": {
      "icao": "55OI",
      "iata": "",
      "name": "Aero Flight Center Airport",
      "city": "East Palestine",
      "state": "Ohio",
      "country": "US",
      "elevation": 1140,
      "lat": 40.8586997986,
      "lon": -80.5353012085,
      "tz": "America\/New_York"
  },
  "55OK": {
      "icao": "55OK",
      "iata": "",
      "name": "Gilstrap Field",
      "city": "Inola",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 575,
      "lat": 36.2239990234,
      "lon": -95.5783004761,
      "tz": "America\/Chicago"
  },
  "55OR": {
      "icao": "55OR",
      "iata": "",
      "name": "Muddy Creek Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 253,
      "lat": 44.436000824,
      "lon": -123.3079986572,
      "tz": "America\/Los_Angeles"
  },
  "55PA": {
      "icao": "55PA",
      "iata": "",
      "name": "Sency Airport",
      "city": "Treskow",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1350,
      "lat": 40.8955993652,
      "lon": -75.9440994263,
      "tz": "America\/New_York"
  },
  "55TE": {
      "icao": "55TE",
      "iata": "",
      "name": "Lebegue Lsa Landing",
      "city": "Elmendorf",
      "state": "Texas",
      "country": "US",
      "elevation": 419,
      "lat": 29.230278,
      "lon": -98.35,
      "tz": "America\/Chicago"
  },
  "55TX": {
      "icao": "55TX",
      "iata": "",
      "name": "Stonecipher Airport",
      "city": "Beaumont",
      "state": "Texas",
      "country": "US",
      "elevation": 39,
      "lat": 30.1121997833,
      "lon": -94.2563018799,
      "tz": "America\/Chicago"
  },
  "55WA": {
      "icao": "55WA",
      "iata": "",
      "name": "Wilkinson Ranch Airport",
      "city": "Yakima",
      "state": "Washington",
      "country": "US",
      "elevation": 1670,
      "lat": 46.5280990601,
      "lon": -120.7610015869,
      "tz": "America\/Los_Angeles"
  },
  "55WI": {
      "icao": "55WI",
      "iata": "",
      "name": "Cranmoor Airstrip",
      "city": "Nekoosa",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 985,
      "lat": 44.3180007935,
      "lon": -89.9686965942,
      "tz": "America\/Chicago"
  },
  "55XS": {
      "icao": "55XS",
      "iata": "",
      "name": "Frontier Airport",
      "city": "Spofford",
      "state": "Texas",
      "country": "US",
      "elevation": 1001,
      "lat": 29.1662998199,
      "lon": -100.4179992676,
      "tz": "America\/Chicago"
  },
  "56AK": {
      "icao": "56AK",
      "iata": "",
      "name": "Bechtol Field",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.5750007629,
      "lon": -149.6060028076,
      "tz": "America\/Anchorage"
  },
  "56AZ": {
      "icao": "56AZ",
      "iata": "",
      "name": "Mauldin Airstrip",
      "city": "Tonopah",
      "state": "Arizona",
      "country": "US",
      "elevation": 1193,
      "lat": 33.4819984436,
      "lon": -113.0019989014,
      "tz": "America\/Phoenix"
  },
  "56CL": {
      "icao": "56CL",
      "iata": "",
      "name": "Cyr Aviation Airport",
      "city": "Blythe",
      "state": "California",
      "country": "US",
      "elevation": 263,
      "lat": 33.591999054,
      "lon": -114.6060028076,
      "tz": "America\/Los_Angeles"
  },
  "56FD": {
      "icao": "56FD",
      "iata": "",
      "name": "North American Farms Airport",
      "city": "Malone",
      "state": "Florida",
      "country": "US",
      "elevation": 130,
      "lat": 30.9645996094,
      "lon": -85.0693969727,
      "tz": "America\/Chicago"
  },
  "56FL": {
      "icao": "56FL",
      "iata": "",
      "name": "Buchanan Airport",
      "city": "Jay",
      "state": "Florida",
      "country": "US",
      "elevation": 246,
      "lat": 30.8759994507,
      "lon": -87.2074966431,
      "tz": "America\/Chicago"
  },
  "56IL": {
      "icao": "56IL",
      "iata": "",
      "name": "Lee Creek Airport",
      "city": "Caledonia",
      "state": "Illinois",
      "country": "US",
      "elevation": 965,
      "lat": 42.4625015259,
      "lon": -88.875,
      "tz": "America\/Chicago"
  },
  "56IN": {
      "icao": "56IN",
      "iata": "",
      "name": "Casad Industrial Park Airport",
      "city": "New Haven",
      "state": "Indiana",
      "country": "US",
      "elevation": 766,
      "lat": 41.0792007446,
      "lon": -84.9427032471,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "56IS": {
      "icao": "56IS",
      "iata": "",
      "name": "Schmidt Airport",
      "city": "Thomasboro",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 40.2430992126,
      "lon": -88.0828018188,
      "tz": "America\/Chicago"
  },
  "56LA": {
      "icao": "56LA",
      "iata": "",
      "name": "Old Hickory Ranch Airport",
      "city": "Keithville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 180,
      "lat": 32.3452987671,
      "lon": -93.8182983398,
      "tz": "America\/Chicago"
  },
  "56LL": {
      "icao": "56LL",
      "iata": "",
      "name": "Wade Airport",
      "city": "Waterman",
      "state": "Illinois",
      "country": "US",
      "elevation": 810,
      "lat": 41.7550010681,
      "lon": -88.7712020874,
      "tz": "America\/Chicago"
  },
  "56MN": {
      "icao": "56MN",
      "iata": "",
      "name": "Lothert's Farm Strip",
      "city": "Morton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1015,
      "lat": 44.6455001831,
      "lon": -94.9614028931,
      "tz": "America\/Chicago"
  },
  "56MO": {
      "icao": "56MO",
      "iata": "",
      "name": "Mc Clurg Airport",
      "city": "Monett",
      "state": "Missouri",
      "country": "US",
      "elevation": 1376,
      "lat": 36.9709014893,
      "lon": -93.9241027832,
      "tz": "America\/Chicago"
  },
  "56NC": {
      "icao": "56NC",
      "iata": "",
      "name": "Wheat Field",
      "city": "Tryon",
      "state": "North Carolina",
      "country": "US",
      "elevation": 960,
      "lat": 35.2066993713,
      "lon": -82.0318984985,
      "tz": "America\/New_York"
  },
  "56NE": {
      "icao": "56NE",
      "iata": "",
      "name": "Noble Field",
      "city": "Ravenna",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1967,
      "lat": 41.0327987671,
      "lon": -98.81199646,
      "tz": "America\/Chicago"
  },
  "56NY": {
      "icao": "56NY",
      "iata": "",
      "name": "Maynard's Airport",
      "city": "Kendall",
      "state": "New York",
      "country": "US",
      "elevation": 342,
      "lat": 43.3252983093,
      "lon": -78.049697876,
      "tz": "America\/New_York"
  },
  "56OH": {
      "icao": "56OH",
      "iata": "",
      "name": "Richey Airport",
      "city": "Petersburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 1195,
      "lat": 40.9072990417,
      "lon": -80.5514984131,
      "tz": "America\/New_York"
  },
  "56OI": {
      "icao": "56OI",
      "iata": "",
      "name": "Double S Farms Airport",
      "city": "Kipton",
      "state": "Ohio",
      "country": "US",
      "elevation": 898,
      "lat": 41.2056007385,
      "lon": -82.3312988281,
      "tz": "America\/New_York"
  },
  "56OK": {
      "icao": "56OK",
      "iata": "",
      "name": "L D Airport",
      "city": "Mc Loud",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1080,
      "lat": 35.4584007263,
      "lon": -97.0670013428,
      "tz": "America\/Chicago"
  },
  "56PA": {
      "icao": "56PA",
      "iata": "",
      "name": "Hoge Farm Airport",
      "city": "Tinicum",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 430,
      "lat": 40.4695014954,
      "lon": -75.0820999146,
      "tz": "America\/New_York"
  },
  "56TE": {
      "icao": "56TE",
      "iata": "",
      "name": "Cardiff Brothers Airport",
      "city": "Katy",
      "state": "Texas",
      "country": "US",
      "elevation": 140,
      "lat": 29.7294006348,
      "lon": -95.8497009277,
      "tz": "America\/Chicago"
  },
  "56TS": {
      "icao": "56TS",
      "iata": "",
      "name": "Elgin Intracontinental Airport",
      "city": "Elgin",
      "state": "Texas",
      "country": "US",
      "elevation": 558,
      "lat": 30.3376998901,
      "lon": -97.3503036499,
      "tz": "America\/Chicago"
  },
  "56TX": {
      "icao": "56TX",
      "iata": "",
      "name": "Anchorage Farm Field",
      "city": "Warrenton",
      "state": "Texas",
      "country": "US",
      "elevation": 440,
      "lat": 30.0093994141,
      "lon": -96.7593994141,
      "tz": "America\/Chicago"
  },
  "56WI": {
      "icao": "56WI",
      "iata": "",
      "name": "Jaks Field",
      "city": "Mosinee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1190,
      "lat": 44.773399353,
      "lon": -89.5936965942,
      "tz": "America\/Chicago"
  },
  "57AL": {
      "icao": "57AL",
      "iata": "",
      "name": "Baswell Airport",
      "city": "Loxley",
      "state": "Alabama",
      "country": "US",
      "elevation": 190,
      "lat": 30.65320015,
      "lon": -87.72370148,
      "tz": "America\/Chicago"
  },
  "57AZ": {
      "icao": "57AZ",
      "iata": "",
      "name": "La Cholla Airpark",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 2940,
      "lat": 32.4467010498,
      "lon": -111.0029983521,
      "tz": "America\/Phoenix"
  },
  "57CL": {
      "icao": "57CL",
      "iata": "",
      "name": "Boron Airstrip",
      "city": "Boron",
      "state": "California",
      "country": "US",
      "elevation": 2499,
      "lat": 35.0036010742,
      "lon": -117.6070022583,
      "tz": "America\/Los_Angeles"
  },
  "57CN": {
      "icao": "57CN",
      "iata": "",
      "name": "Van Vleck Airport",
      "city": "Rancho Murieta",
      "state": "California",
      "country": "US",
      "elevation": 175,
      "lat": 38.4691009521,
      "lon": -121.0749969482,
      "tz": "America\/Los_Angeles"
  },
  "57FA": {
      "icao": "57FA",
      "iata": "",
      "name": "Lake X Airport",
      "city": "St Cloud",
      "state": "Florida",
      "country": "US",
      "elevation": 72,
      "lat": 28.2085990906,
      "lon": -81.1192016602,
      "tz": "America\/New_York"
  },
  "57FD": {
      "icao": "57FD",
      "iata": "",
      "name": "Sunset Strip Airpark",
      "city": "Brooksville",
      "state": "Florida",
      "country": "US",
      "elevation": 70,
      "lat": 28.5396995544,
      "lon": -82.2288970947,
      "tz": "America\/New_York"
  },
  "57GA": {
      "icao": "57GA",
      "iata": "",
      "name": "Blue Ridge Skyport Airport",
      "city": "Blue Ridge",
      "state": "Georgia",
      "country": "US",
      "elevation": 1946,
      "lat": 34.8630981445,
      "lon": -84.3937988281,
      "tz": "America\/New_York"
  },
  "57II": {
      "icao": "57II",
      "iata": "",
      "name": "Reimer Aerodrome",
      "city": "Jamestown",
      "state": "Indiana",
      "country": "US",
      "elevation": 930,
      "lat": 39.9641990662,
      "lon": -86.5674972534,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "57IS": {
      "icao": "57IS",
      "iata": "",
      "name": "Dozier Airport",
      "city": "Fairfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 420,
      "lat": 38.3166999817,
      "lon": -88.2723007202,
      "tz": "America\/Chicago"
  },
  "57KY": {
      "icao": "57KY",
      "iata": "",
      "name": "Belcher Regional Airport",
      "city": "Belcher",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1700,
      "lat": 37.3694000244,
      "lon": -82.3242034912,
      "tz": "America\/New_York"
  },
  "57ME": {
      "icao": "57ME",
      "iata": "",
      "name": "Dyer's Landing Airport",
      "city": "Fryeburg",
      "state": "Maine",
      "country": "US",
      "elevation": 441,
      "lat": 43.9178009033,
      "lon": -70.8544006348,
      "tz": "America\/New_York"
  },
  "57NC": {
      "icao": "57NC",
      "iata": "",
      "name": "Sossamon Field",
      "city": "Bryson City",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1940,
      "lat": 35.4264984131,
      "lon": -83.4581985474,
      "tz": "America\/New_York"
  },
  "57ND": {
      "icao": "57ND",
      "iata": "",
      "name": "Odegaard Airport",
      "city": "Kindred",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1020,
      "lat": 46.6082992554,
      "lon": -97.0595016479,
      "tz": "America\/Chicago"
  },
  "57NE": {
      "icao": "57NE",
      "iata": "",
      "name": "Cole Memorial Airport",
      "city": "Merriman",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3259,
      "lat": 42.9241981506,
      "lon": -101.6940002441,
      "tz": "America\/Denver"
  },
  "57NM": {
      "icao": "57NM",
      "iata": "",
      "name": "Monte Prieto Ranch Airport",
      "city": "Claunch",
      "state": "New Mexico",
      "country": "US",
      "elevation": 6164,
      "lat": 34.0886993408,
      "lon": -106.1190032959,
      "tz": "America\/Denver"
  },
  "57OH": {
      "icao": "57OH",
      "iata": "",
      "name": "Fillmans Farms Field",
      "city": "Port Washington",
      "state": "Ohio",
      "country": "US",
      "elevation": 820,
      "lat": 40.3362007141,
      "lon": -81.4900970459,
      "tz": "America\/New_York"
  },
  "57OI": {
      "icao": "57OI",
      "iata": "",
      "name": "Logan's Chance Airport",
      "city": "Lawrenceville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1100,
      "lat": 39.9967002869,
      "lon": -83.8546981812,
      "tz": "America\/New_York"
  },
  "57OR": {
      "icao": "57OR",
      "iata": "",
      "name": "Umpqua River Farm Airport",
      "city": "Sutherlin",
      "state": "Oregon",
      "country": "US",
      "elevation": 380,
      "lat": 43.3400001526,
      "lon": -123.4509963989,
      "tz": "America\/Los_Angeles"
  },
  "57PA": {
      "icao": "57PA",
      "iata": "",
      "name": "H&H Personal Use Airport",
      "city": "Tioga",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1680,
      "lat": 41.9029006958,
      "lon": -77.2303009033,
      "tz": "America\/New_York"
  },
  "57PN": {
      "icao": "57PN",
      "iata": "",
      "name": "Ransome STOLport",
      "city": "Solebury",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 360,
      "lat": 40.3975982666,
      "lon": -75.0106964111,
      "tz": "America\/New_York"
  },
  "57TE": {
      "icao": "57TE",
      "iata": "",
      "name": "Prade Ranch Airport",
      "city": "Leakey",
      "state": "Texas",
      "country": "US",
      "elevation": 2296,
      "lat": 29.9388008118,
      "lon": -99.7834014893,
      "tz": "America\/Chicago"
  },
  "57TX": {
      "icao": "57TX",
      "iata": "",
      "name": "Brown Ranch Airport",
      "city": "Beeville",
      "state": "Texas",
      "country": "US",
      "elevation": 325,
      "lat": 28.4419002533,
      "lon": -97.804397583,
      "tz": "America\/Chicago"
  },
  "57VA": {
      "icao": "57VA",
      "iata": "",
      "name": "Toga Airport",
      "city": "Buckingham Courthouse",
      "state": "Virginia",
      "country": "US",
      "elevation": 725,
      "lat": 37.4510002136,
      "lon": -78.6785964966,
      "tz": "America\/New_York"
  },
  "57WA": {
      "icao": "57WA",
      "iata": "",
      "name": "Kent Farms Airport",
      "city": "Othello",
      "state": "Washington",
      "country": "US",
      "elevation": 1155,
      "lat": 46.873500824,
      "lon": -119.1299972534,
      "tz": "America\/Los_Angeles"
  },
  "57XS": {
      "icao": "57XS",
      "iata": "",
      "name": "Hoopes Ranch Airport",
      "city": "Weir",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 30.6968994141,
      "lon": -97.5600967407,
      "tz": "America\/Chicago"
  },
  "58AK": {
      "icao": "58AK",
      "iata": "",
      "name": "Fairview East Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 61.5526008606,
      "lon": -149.3399963379,
      "tz": "America\/Anchorage"
  },
  "58AZ": {
      "icao": "58AZ",
      "iata": "",
      "name": "Chapman Ranch Airstrip",
      "city": "Young",
      "state": "Arizona",
      "country": "US",
      "elevation": 5115,
      "lat": 34.093611,
      "lon": -110.932778,
      "tz": "America\/Phoenix"
  },
  "58CL": {
      "icao": "58CL",
      "iata": "",
      "name": "Borrego Air Ranch Airport",
      "city": "Borrego Springs",
      "state": "California",
      "country": "US",
      "elevation": 575,
      "lat": 33.1916999817,
      "lon": -116.2760009766,
      "tz": "America\/Los_Angeles"
  },
  "58FD": {
      "icao": "58FD",
      "iata": "",
      "name": "Southerly Airport",
      "city": "Dundee",
      "state": "Florida",
      "country": "US",
      "elevation": 78,
      "lat": 28.0142002106,
      "lon": -81.5406036377,
      "tz": "America\/New_York"
  },
  "58II": {
      "icao": "58II",
      "iata": "",
      "name": "Henneman Airport",
      "city": "Boggstown",
      "state": "Indiana",
      "country": "US",
      "elevation": 770,
      "lat": 39.5209007263,
      "lon": -85.9000015259,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "58IL": {
      "icao": "58IL",
      "iata": "",
      "name": "Spangler Airport",
      "city": "Manteno",
      "state": "Illinois",
      "country": "US",
      "elevation": 675,
      "lat": 41.2383995056,
      "lon": -87.8594970703,
      "tz": "America\/Chicago"
  },
  "58IN": {
      "icao": "58IN",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Spencerville",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 41.2583999634,
      "lon": -84.863899231,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "58KS": {
      "icao": "58KS",
      "iata": "",
      "name": "Burger's Valley Airport",
      "city": "Salina",
      "state": "Kansas",
      "country": "US",
      "elevation": 1325,
      "lat": 38.9221992493,
      "lon": -97.7086029053,
      "tz": "America\/Chicago"
  },
  "58KY": {
      "icao": "58KY",
      "iata": "",
      "name": "Willow Island Airpark",
      "city": "Finchville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 740,
      "lat": 38.1208992004,
      "lon": -85.3319015503,
      "tz": "America\/New_York"
  },
  "58LA": {
      "icao": "58LA",
      "iata": "",
      "name": "Sydal Acres Airport",
      "city": "Wilson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 200,
      "lat": 30.9195995331,
      "lon": -91.1668014526,
      "tz": "America\/Chicago"
  },
  "58MN": {
      "icao": "58MN",
      "iata": "",
      "name": "Northwest Angle Airport",
      "city": "Warroad",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1071,
      "lat": 49.351398468,
      "lon": -95.0710983276,
      "tz": "America\/Chicago"
  },
  "58NC": {
      "icao": "58NC",
      "iata": "",
      "name": "Fox Haven Plantation STOLport",
      "city": "Rutherfordton",
      "state": "North Carolina",
      "country": "US",
      "elevation": 800,
      "lat": 35.3468017578,
      "lon": -82.0550994873,
      "tz": "America\/New_York"
  },
  "58OH": {
      "icao": "58OH",
      "iata": "",
      "name": "Rattlesnake Island Airport",
      "city": "Put In Bay",
      "state": "Ohio",
      "country": "US",
      "elevation": 586,
      "lat": 41.6791992188,
      "lon": -82.8470993042,
      "tz": "America\/New_York"
  },
  "58OR": {
      "icao": "58OR",
      "iata": "",
      "name": "Umpqua Airport",
      "city": "Roseburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 700,
      "lat": 43.3097000122,
      "lon": -123.1269989014,
      "tz": "America\/Los_Angeles"
  },
  "58PA": {
      "icao": "58PA",
      "iata": "",
      "name": "Tallman East Airport",
      "city": "Tower City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 842,
      "lat": 40.5918006897,
      "lon": -76.5143966675,
      "tz": "America\/New_York"
  },
  "58TE": {
      "icao": "58TE",
      "iata": "",
      "name": "Mc Nabb Farm Airport",
      "city": "Ropesville",
      "state": "Texas",
      "country": "US",
      "elevation": 3315,
      "lat": 33.40650177,
      "lon": -102.077003479,
      "tz": "America\/Chicago"
  },
  "58TX": {
      "icao": "58TX",
      "iata": "",
      "name": "Tailspin Estates Airport",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 830,
      "lat": 32.6511993408,
      "lon": -97.9336013794,
      "tz": "America\/Chicago"
  },
  "58VA": {
      "icao": "58VA",
      "iata": "",
      "name": "Walnut Hill Airport",
      "city": "Calverton",
      "state": "Virginia",
      "country": "US",
      "elevation": 285,
      "lat": 38.5951004028,
      "lon": -77.6461029053,
      "tz": "America\/New_York"
  },
  "58WA": {
      "icao": "58WA",
      "iata": "",
      "name": "Dye Seed Ranch Inc. Airport",
      "city": "Pomeroy",
      "state": "Washington",
      "country": "US",
      "elevation": 2500,
      "lat": 46.5074005127,
      "lon": -117.4499969482,
      "tz": "America\/Los_Angeles"
  },
  "58WI": {
      "icao": "58WI",
      "iata": "",
      "name": "Geo Jensen Airport",
      "city": "Frederic",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1350,
      "lat": 45.6450996399,
      "lon": -92.3664016724,
      "tz": "America\/Chicago"
  },
  "58XS": {
      "icao": "58XS",
      "iata": "",
      "name": "Mc Entire's Lazy V Ranch Airport",
      "city": "Sterling City",
      "state": "Texas",
      "country": "US",
      "elevation": 2360,
      "lat": 31.8306999207,
      "lon": -101.055999756,
      "tz": "America\/Chicago"
  },
  "59AK": {
      "icao": "59AK",
      "iata": "",
      "name": "Penderosa Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 520,
      "lat": 61.63180161,
      "lon": -149.3410034,
      "tz": "America\/Anchorage"
  },
  "59AS": {
      "icao": "59AS",
      "iata": "",
      "name": "Becker Field",
      "city": "Battle Ground",
      "state": "Washington",
      "country": "US",
      "elevation": 1720,
      "lat": 45.850669,
      "lon": -122.544514,
      "tz": "America\/Los_Angeles"
  },
  "59AZ": {
      "icao": "59AZ",
      "iata": "",
      "name": "Robin STOLport",
      "city": "Chino Valley",
      "state": "Arizona",
      "country": "US",
      "elevation": 4772,
      "lat": 34.7444992065,
      "lon": -112.4449996948,
      "tz": "America\/Phoenix"
  },
  "59CA": {
      "icao": "59CA",
      "iata": "",
      "name": "Little Hands STOLport",
      "city": "San Ramon",
      "state": "California",
      "country": "US",
      "elevation": 1008,
      "lat": 37.8032989502,
      "lon": -122.0289993286,
      "tz": "America\/Los_Angeles"
  },
  "59CL": {
      "icao": "59CL",
      "iata": "",
      "name": "O'Connell Brothers Airport",
      "city": "Brawley",
      "state": "California",
      "country": "US",
      "elevation": -99,
      "lat": 32.9625015259,
      "lon": -115.5419998169,
      "tz": "America\/Los_Angeles"
  },
  "59CN": {
      "icao": "59CN",
      "iata": "",
      "name": "James Brothers Airport",
      "city": "Pleasant Grove",
      "state": "California",
      "country": "US",
      "elevation": 25,
      "lat": 38.8348999023,
      "lon": -121.5299987793,
      "tz": "America\/Los_Angeles"
  },
  "59CO": {
      "icao": "59CO",
      "iata": "",
      "name": "Comanche Livestock Airport",
      "city": "Strasburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 5230,
      "lat": 39.8333015442,
      "lon": -104.3170013428,
      "tz": "America\/Denver"
  },
  "59FD": {
      "icao": "59FD",
      "iata": "",
      "name": "Big Cypress Airfield",
      "city": "Immokalee",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 26.3202991486,
      "lon": -80.9878005981,
      "tz": "America\/New_York"
  },
  "59II": {
      "icao": "59II",
      "iata": "",
      "name": "Posey Patch Ultralightport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 370,
      "lat": 37.9039001465,
      "lon": -87.7902984619,
      "tz": "America\/Chicago"
  },
  "59IL": {
      "icao": "59IL",
      "iata": "",
      "name": "Ruder Airport",
      "city": "Maple Park",
      "state": "Illinois",
      "country": "US",
      "elevation": 855,
      "lat": 41.9258003235,
      "lon": -88.6464004517,
      "tz": "America\/Chicago"
  },
  "59IN": {
      "icao": "59IN",
      "iata": "",
      "name": "Stuntz & Hochstetler Pines Airport",
      "city": "Walkerton",
      "state": "Indiana",
      "country": "US",
      "elevation": 739,
      "lat": 41.4737014771,
      "lon": -86.4507980347,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "59KS": {
      "icao": "59KS",
      "iata": "",
      "name": "Dunn Field",
      "city": "Beagle",
      "state": "Kansas",
      "country": "US",
      "elevation": 1060,
      "lat": 38.4084014893,
      "lon": -95.0190963745,
      "tz": "America\/Chicago"
  },
  "59KY": {
      "icao": "59KY",
      "iata": "",
      "name": "Samuels Springs Airport",
      "city": "Deatsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 690,
      "lat": 37.8978004456,
      "lon": -85.5551986694,
      "tz": "America\/New_York"
  },
  "59MN": {
      "icao": "59MN",
      "iata": "",
      "name": "Cox-Coyour Meml Air Field",
      "city": "Roosevelt",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1304,
      "lat": 48.5993995667,
      "lon": -95.1868972778,
      "tz": "America\/Chicago"
  },
  "59NC": {
      "icao": "59NC",
      "iata": "",
      "name": "Mclean Brothers Airport",
      "city": "Gibsonville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 696,
      "lat": 36.0974006653,
      "lon": -79.5552978516,
      "tz": "America\/New_York"
  },
  "59NE": {
      "icao": "59NE",
      "iata": "",
      "name": "Nelson Airport",
      "city": "Boelus",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1982,
      "lat": 41.0811004639,
      "lon": -98.6295013428,
      "tz": "America\/Chicago"
  },
  "59NM": {
      "icao": "59NM",
      "iata": "",
      "name": "Lockmiller & Sons Airport",
      "city": "Clovis",
      "state": "New Mexico",
      "country": "US",
      "elevation": 4559,
      "lat": 34.5472984314,
      "lon": -103.4639968872,
      "tz": "America\/Denver"
  },
  "59NY": {
      "icao": "59NY",
      "iata": "",
      "name": "Bent-Wing Airport",
      "city": "Lockport",
      "state": "New York",
      "country": "US",
      "elevation": 370,
      "lat": 43.2305984497,
      "lon": -78.6900024414,
      "tz": "America\/New_York"
  },
  "59OH": {
      "icao": "59OH",
      "iata": "",
      "name": "Kelch Airport",
      "city": "Bethel",
      "state": "Ohio",
      "country": "US",
      "elevation": 905,
      "lat": 38.9752998352,
      "lon": -84.0342025757,
      "tz": "America\/New_York"
  },
  "59PA": {
      "icao": "59PA",
      "iata": "",
      "name": "Erkes Airport",
      "city": "Upper Black Eddy",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 340,
      "lat": 40.5376014709,
      "lon": -75.087097168,
      "tz": "America\/New_York"
  },
  "59PN": {
      "icao": "59PN",
      "iata": "",
      "name": "Brookside Farms Airport",
      "city": "Hershey",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 489,
      "lat": 40.3158988953,
      "lon": -76.685798645,
      "tz": "America\/New_York"
  },
  "59TE": {
      "icao": "59TE",
      "iata": "",
      "name": "Hoffpauir Airport",
      "city": "Katy",
      "state": "Texas",
      "country": "US",
      "elevation": 133,
      "lat": 29.8057994843,
      "lon": -95.7529983521,
      "tz": "America\/Chicago"
  },
  "59TS": {
      "icao": "59TS",
      "iata": "",
      "name": "Rossler Ranch Airport",
      "city": "George West",
      "state": "Texas",
      "country": "US",
      "elevation": 250,
      "lat": 28.1431007385,
      "lon": -98.1583023071,
      "tz": "America\/Chicago"
  },
  "59TX": {
      "icao": "59TX",
      "iata": "",
      "name": "Benjamin Franklin Airport",
      "city": "Godley",
      "state": "Texas",
      "country": "US",
      "elevation": 990,
      "lat": 32.4872016907,
      "lon": -97.4903030396,
      "tz": "America\/Chicago"
  },
  "59WA": {
      "icao": "59WA",
      "iata": "",
      "name": "Sorrell Airport",
      "city": "Tenino",
      "state": "Washington",
      "country": "US",
      "elevation": 250,
      "lat": 46.8503990173,
      "lon": -122.9359970093,
      "tz": "America\/Los_Angeles"
  },
  "59WI": {
      "icao": "59WI",
      "iata": "",
      "name": "Little Wheel Field",
      "city": "Cottage Grove",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 900,
      "lat": 43.0339012146,
      "lon": -89.1925964355,
      "tz": "America\/Chicago"
  },
  "5AK0": {
      "icao": "5AK0",
      "iata": "",
      "name": "Trading Bay Production Airport",
      "city": "Trading Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 60.8154983521,
      "lon": -151.7989959717,
      "tz": "America\/Anchorage"
  },
  "5AK2": {
      "icao": "5AK2",
      "iata": "",
      "name": "Howards Airport",
      "city": "North Pole",
      "state": "Alaska",
      "country": "US",
      "elevation": 490,
      "lat": 64.7306976318,
      "lon": -147.3480072021,
      "tz": "America\/Anchorage"
  },
  "5AK3": {
      "icao": "5AK3",
      "iata": "",
      "name": "Airway Airport",
      "city": "North Pole",
      "state": "Alaska",
      "country": "US",
      "elevation": 480,
      "lat": 64.7732009888,
      "lon": -147.333999634,
      "tz": "America\/Anchorage"
  },
  "5AK5": {
      "icao": "5AK5",
      "iata": "",
      "name": "Todds Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 400,
      "lat": 61.6571006775,
      "lon": -149.4620056152,
      "tz": "America\/Anchorage"
  },
  "5AK6": {
      "icao": "5AK6",
      "iata": "",
      "name": "Colberg Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 125,
      "lat": 61.5569992065,
      "lon": -149.2680053711,
      "tz": "America\/Anchorage"
  },
  "5AK8": {
      "icao": "5AK8",
      "iata": "",
      "name": "Memory Lake Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 420,
      "lat": 61.631401062,
      "lon": -149.4320068359,
      "tz": "America\/Anchorage"
  },
  "5AK9": {
      "icao": "5AK9",
      "iata": "",
      "name": "Grandview Subdivision Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 61.548500061,
      "lon": -149.1900024414,
      "tz": "America\/Anchorage"
  },
  "5AL1": {
      "icao": "5AL1",
      "iata": "",
      "name": "Mc Gowin Field",
      "city": "Chapman",
      "state": "Alabama",
      "country": "US",
      "elevation": 400,
      "lat": 31.6807003021,
      "lon": -86.6819000244,
      "tz": "America\/Chicago"
  },
  "5AL3": {
      "icao": "5AL3",
      "iata": "",
      "name": "Flying X Ranch Airport",
      "city": "Montevallo",
      "state": "Alabama",
      "country": "US",
      "elevation": 466,
      "lat": 33.1529006958,
      "lon": -86.8514022827,
      "tz": "America\/Chicago"
  },
  "5AL4": {
      "icao": "5AL4",
      "iata": "",
      "name": "Blueberry Hill Airport",
      "city": "Dothan",
      "state": "Alabama",
      "country": "US",
      "elevation": 220,
      "lat": 31.1215991974,
      "lon": -85.3654022217,
      "tz": "America\/Chicago"
  },
  "5AL5": {
      "icao": "5AL5",
      "iata": "",
      "name": "Big River Airpark",
      "city": "Muscle Shoals",
      "state": "Alabama",
      "country": "US",
      "elevation": 505,
      "lat": 34.6911010742,
      "lon": -87.6528015137,
      "tz": "America\/Chicago"
  },
  "5AL7": {
      "icao": "5AL7",
      "iata": "",
      "name": "Hataway Field",
      "city": "Kinston",
      "state": "Alabama",
      "country": "US",
      "elevation": 300,
      "lat": 31.25,
      "lon": -86.1667022705,
      "tz": "America\/Chicago"
  },
  "5AL8": {
      "icao": "5AL8",
      "iata": "",
      "name": "Fairlane Airport",
      "city": "Samson",
      "state": "Alabama",
      "country": "US",
      "elevation": 225,
      "lat": 31.1049995422,
      "lon": -85.9931030273,
      "tz": "America\/Chicago"
  },
  "5AR1": {
      "icao": "5AR1",
      "iata": "",
      "name": "Tillar Airport",
      "city": "Tillar",
      "state": "Arkansas",
      "country": "US",
      "elevation": 150,
      "lat": 33.712600708,
      "lon": -91.4611968994,
      "tz": "America\/Chicago"
  },
  "5AR2": {
      "icao": "5AR2",
      "iata": "",
      "name": "Turrell Flying Service Airport",
      "city": "Turrell",
      "state": "Arkansas",
      "country": "US",
      "elevation": 226,
      "lat": 35.375,
      "lon": -90.2063980103,
      "tz": "America\/Chicago"
  },
  "5AR3": {
      "icao": "5AR3",
      "iata": "",
      "name": "Wabbaseka Flying Service Airport",
      "city": "Wabbaseka",
      "state": "Arkansas",
      "country": "US",
      "elevation": 200,
      "lat": 34.3501014709,
      "lon": -91.7835006714,
      "tz": "America\/Chicago"
  },
  "5AR7": {
      "icao": "5AR7",
      "iata": "",
      "name": "Keller Airfield",
      "city": "Weiner",
      "state": "Arkansas",
      "country": "US",
      "elevation": 249,
      "lat": 35.6866989136,
      "lon": -90.7936019897,
      "tz": "America\/Chicago"
  },
  "5AR8": {
      "icao": "5AR8",
      "iata": "",
      "name": "Pine Prairie Airport",
      "city": "Ashdown",
      "state": "Arkansas",
      "country": "US",
      "elevation": 321,
      "lat": 33.6167984009,
      "lon": -94.116897583,
      "tz": "America\/Chicago"
  },
  "5AZ3": {
      "icao": "5AZ3",
      "iata": "",
      "name": "Pegasus Airpark",
      "city": "Queen Creek",
      "state": "Arizona",
      "country": "US",
      "elevation": 1450,
      "lat": 33.2125015259,
      "lon": -111.6169967651,
      "tz": "America\/Phoenix"
  },
  "5AZ6": {
      "icao": "5AZ6",
      "iata": "",
      "name": "Motown Airport",
      "city": "Mobile",
      "state": "Arizona",
      "country": "US",
      "elevation": 80,
      "lat": 32.925,
      "lon": -112.259444,
      "tz": "America\/Phoenix"
  },
  "5AZ7": {
      "icao": "5AZ7",
      "iata": "",
      "name": "Carranza Farm Airstrip",
      "city": "Stanfield",
      "state": "Arizona",
      "country": "US",
      "elevation": 1300,
      "lat": 32.8471984863,
      "lon": -111.9499969482,
      "tz": "America\/Phoenix"
  },
  "5AZ9": {
      "icao": "5AZ9",
      "iata": "",
      "name": "Regeneration Airport",
      "city": "Fort Thomas",
      "state": "Arizona",
      "country": "US",
      "elevation": 2750,
      "lat": 33.0327987671,
      "lon": -109.9879989624,
      "tz": "America\/Phoenix"
  },
  "5CA4": {
      "icao": "5CA4",
      "iata": "",
      "name": "Ludlow Airport",
      "city": "Barstow",
      "state": "California",
      "country": "US",
      "elevation": 1700,
      "lat": 34.7532997131,
      "lon": -116.1539993286,
      "tz": "America\/Los_Angeles"
  },
  "5CA7": {
      "icao": "5CA7",
      "iata": "",
      "name": "Agro-West Airport",
      "city": "Five Points",
      "state": "California",
      "country": "US",
      "elevation": 335,
      "lat": 36.336101532,
      "lon": -120.247001648,
      "tz": "America\/Los_Angeles"
  },
  "5CA8": {
      "icao": "5CA8",
      "iata": "",
      "name": "Howard \/Private\/ Airport",
      "city": "Ione",
      "state": "California",
      "country": "US",
      "elevation": 480,
      "lat": 38.2563018799,
      "lon": -120.931999207,
      "tz": "America\/Los_Angeles"
  },
  "5CL0": {
      "icao": "5CL0",
      "iata": "",
      "name": "Grupe Ranch Airport",
      "city": "Mariposa",
      "state": "California",
      "country": "US",
      "elevation": 1625,
      "lat": 37.3404998779,
      "lon": -119.8619995117,
      "tz": "America\/Los_Angeles"
  },
  "5CL1": {
      "icao": "5CL1",
      "iata": "",
      "name": "Hart Ranch Airport",
      "city": "Santa Margarita",
      "state": "California",
      "country": "US",
      "elevation": 1380,
      "lat": 35.40470123,
      "lon": -120.5250015,
      "tz": "America\/Los_Angeles"
  },
  "5CL3": {
      "icao": "5CL3",
      "iata": "",
      "name": "Mapes Ranch Airport",
      "city": "Modesto",
      "state": "California",
      "country": "US",
      "elevation": 34,
      "lat": 37.6679992676,
      "lon": -121.1989974976,
      "tz": "America\/Los_Angeles"
  },
  "5CL7": {
      "icao": "5CL7",
      "iata": "",
      "name": "Gene Wash Reservoir Airport",
      "city": "Parker Dam",
      "state": "California",
      "country": "US",
      "elevation": 860,
      "lat": 34.3081016541,
      "lon": -114.18699646,
      "tz": "America\/Los_Angeles"
  },
  "5CL8": {
      "icao": "5CL8",
      "iata": "",
      "name": "Creekside Airport",
      "city": "Arvin",
      "state": "California",
      "country": "US",
      "elevation": 335,
      "lat": 35.1761016846,
      "lon": -118.922996521,
      "tz": "America\/Los_Angeles"
  },
  "5CL9": {
      "icao": "5CL9",
      "iata": "",
      "name": "Johnston Field",
      "city": "Auberry",
      "state": "California",
      "country": "US",
      "elevation": 1280,
      "lat": 37.1413002014,
      "lon": -119.5080032349,
      "tz": "America\/Los_Angeles"
  },
  "5CO0": {
      "icao": "5CO0",
      "iata": "",
      "name": "Cheyenne Wells Municipal Airport",
      "city": "Cheyenne Wells",
      "state": "Colorado",
      "country": "US",
      "elevation": 4184,
      "lat": 38.8166999817,
      "lon": -102.3059997559,
      "tz": "America\/Denver"
  },
  "5CO1": {
      "icao": "5CO1",
      "iata": "",
      "name": "Shipman Ranch Airport",
      "city": "Austin",
      "state": "Colorado",
      "country": "US",
      "elevation": 6200,
      "lat": 38.8333015442,
      "lon": -107.8590011597,
      "tz": "America\/Denver"
  },
  "5CO4": {
      "icao": "5CO4",
      "iata": "",
      "name": "Spickard Farm Airport",
      "city": "Byers",
      "state": "Colorado",
      "country": "US",
      "elevation": 5320,
      "lat": 39.7000007629,
      "lon": -104.2509994507,
      "tz": "America\/Denver"
  },
  "5CO6": {
      "icao": "5CO6",
      "iata": "",
      "name": "Meyer Ranch Airport",
      "city": "Conifer",
      "state": "Colorado",
      "country": "US",
      "elevation": 8000,
      "lat": 39.5499992371,
      "lon": -105.283996582,
      "tz": "America\/Denver"
  },
  "5CO7": {
      "icao": "5CO7",
      "iata": "",
      "name": "Mesa View Ranch Airport",
      "city": "Craig",
      "state": "Colorado",
      "country": "US",
      "elevation": 6978,
      "lat": 40.7750015259,
      "lon": -107.533996582,
      "tz": "America\/Denver"
  },
  "5CO8": {
      "icao": "5CO8",
      "iata": "",
      "name": "Pleasant Valley Airport",
      "city": "Oak Creek",
      "state": "Colorado",
      "country": "US",
      "elevation": 7120,
      "lat": 40.3414001465,
      "lon": -106.8489990234,
      "tz": "America\/Denver"
  },
  "5CT0": {
      "icao": "5CT0",
      "iata": "",
      "name": "Eastford Airport",
      "city": "Eastford",
      "state": "Connecticut",
      "country": "US",
      "elevation": 600,
      "lat": 41.9104003906,
      "lon": -72.0680999756,
      "tz": "America\/New_York"
  },
  "5CT5": {
      "icao": "5CT5",
      "iata": "",
      "name": "Thomson Field",
      "city": "Bethlehem",
      "state": "Connecticut",
      "country": "US",
      "elevation": 820,
      "lat": 41.6323013306,
      "lon": -73.2162017822,
      "tz": "America\/New_York"
  },
  "5CT6": {
      "icao": "5CT6",
      "iata": "",
      "name": "Buell Farm Ultralightport",
      "city": "Eastford",
      "state": "Connecticut",
      "country": "US",
      "elevation": 670,
      "lat": 41.9194984436,
      "lon": -72.1184005737,
      "tz": "America\/New_York"
  },
  "5CT7": {
      "icao": "5CT7",
      "iata": "",
      "name": "Mile Creek Airport",
      "city": "Old Lyme",
      "state": "Connecticut",
      "country": "US",
      "elevation": 30,
      "lat": 41.3022994995,
      "lon": -72.2962036133,
      "tz": "America\/New_York"
  },
  "5FA1": {
      "icao": "5FA1",
      "iata": "",
      "name": "Flying Palomino Ranch Airport",
      "city": "Fruitland Park",
      "state": "Florida",
      "country": "US",
      "elevation": 85,
      "lat": 28.8780002594,
      "lon": -81.94090271,
      "tz": "America\/New_York"
  },
  "5FD0": {
      "icao": "5FD0",
      "iata": "",
      "name": "Manatee Springs Airport",
      "city": "Old Town",
      "state": "Florida",
      "country": "US",
      "elevation": 17,
      "lat": 29.4927005768,
      "lon": -82.9912033081,
      "tz": "America\/New_York"
  },
  "5FD1": {
      "icao": "5FD1",
      "iata": "",
      "name": "Ron Wood Airport",
      "city": "Clarksville",
      "state": "Florida",
      "country": "US",
      "elevation": 140,
      "lat": 30.432100296,
      "lon": -85.2888031006,
      "tz": "America\/Chicago"
  },
  "5FD3": {
      "icao": "5FD3",
      "iata": "",
      "name": "County Line Airstrip",
      "city": "Altha",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.608499527,
      "lon": -85.0913009644,
      "tz": "America\/Chicago"
  },
  "5FD5": {
      "icao": "5FD5",
      "iata": "",
      "name": "Able Airpark",
      "city": "Blountstown",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 30.595500946,
      "lon": -84.981300354,
      "tz": "America\/Chicago"
  },
  "5FD7": {
      "icao": "5FD7",
      "iata": "",
      "name": "Chinsegut Airport",
      "city": "Brooksville",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.611700058,
      "lon": -82.3678970337,
      "tz": "America\/New_York"
  },
  "5FL0": {
      "icao": "5FL0",
      "iata": "",
      "name": "Dusty Airpatch Airport",
      "city": "Dade City",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 28.4130992889,
      "lon": -82.2233963013,
      "tz": "America\/New_York"
  },
  "5FL1": {
      "icao": "5FL1",
      "iata": "",
      "name": "Ezell Airport",
      "city": "Keaton Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 29.9032993317,
      "lon": -83.6079025269,
      "tz": "America\/New_York"
  },
  "5FL4": {
      "icao": "5FL4",
      "iata": "",
      "name": "Byrd Air Field",
      "city": "Elkton",
      "state": "Florida",
      "country": "US",
      "elevation": 21,
      "lat": 29.8008003235,
      "lon": -81.5009002686,
      "tz": "America\/New_York"
  },
  "5FL6": {
      "icao": "5FL6",
      "iata": "",
      "name": "Flat Creek Airport",
      "city": "Greensboro",
      "state": "Florida",
      "country": "US",
      "elevation": 295,
      "lat": 30.6044006348,
      "lon": -84.7907028198,
      "tz": "America\/New_York"
  },
  "5FL7": {
      "icao": "5FL7",
      "iata": "",
      "name": "Twelve Oaks Airport",
      "city": "Hernando",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 28.9780006409,
      "lon": -82.3637008667,
      "tz": "America\/New_York"
  },
  "5FL8": {
      "icao": "5FL8",
      "iata": "",
      "name": "Chipola Airpark",
      "city": "Greenwood",
      "state": "Florida",
      "country": "US",
      "elevation": 125,
      "lat": 30.8882007599,
      "lon": -85.1677017212,
      "tz": "America\/Chicago"
  },
  "5FL9": {
      "icao": "5FL9",
      "iata": "",
      "name": "Cedar Lane Airport",
      "city": "Defuniak Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 270,
      "lat": 30.7362995148,
      "lon": -86.2129974365,
      "tz": "America\/Chicago"
  },
  "5GA0": {
      "icao": "5GA0",
      "iata": "",
      "name": "Gable Branch Airport",
      "city": "Haralson",
      "state": "Georgia",
      "country": "US",
      "elevation": 840,
      "lat": 33.2276000977,
      "lon": -84.5444030762,
      "tz": "America\/New_York"
  },
  "5GA2": {
      "icao": "5GA2",
      "iata": "",
      "name": "Lyons Landing Airport",
      "city": "Whitesburg",
      "state": "Georgia",
      "country": "US",
      "elevation": 1080,
      "lat": 33.5732002258,
      "lon": -84.9096984863,
      "tz": "America\/New_York"
  },
  "5GA3": {
      "icao": "5GA3",
      "iata": "",
      "name": "Eagles Landing Airport",
      "city": "Williamson",
      "state": "Georgia",
      "country": "US",
      "elevation": 980,
      "lat": 33.1600990295,
      "lon": -84.365196228,
      "tz": "America\/New_York"
  },
  "5GA4": {
      "icao": "5GA4",
      "iata": "",
      "name": "Air Acres Airport",
      "city": "Woodstock",
      "state": "Georgia",
      "country": "US",
      "elevation": 918,
      "lat": 34.1058998108,
      "lon": -84.4485015869,
      "tz": "America\/New_York"
  },
  "5GA5": {
      "icao": "5GA5",
      "iata": "",
      "name": "Pinebrook Estates Airport",
      "city": "Griffin",
      "state": "Georgia",
      "country": "US",
      "elevation": 820,
      "lat": 33.2597999573,
      "lon": -84.3494033813,
      "tz": "America\/New_York"
  },
  "5GA6": {
      "icao": "5GA6",
      "iata": "",
      "name": "Roney Farms Airport",
      "city": "Lilly",
      "state": "Georgia",
      "country": "US",
      "elevation": 352,
      "lat": 32.1498985291,
      "lon": -83.8612976074,
      "tz": "America\/New_York"
  },
  "5GA7": {
      "icao": "5GA7",
      "iata": "",
      "name": "Panacea Airport",
      "city": "Franklin\/Glenn",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.2018013,
      "lon": -85.2304992676,
      "tz": "America\/Chicago"
  },
  "5GE1": {
      "icao": "5GE1",
      "iata": "",
      "name": "Peachtree Landings Airport",
      "city": "Fort Valley",
      "state": "Georgia",
      "country": "US",
      "elevation": 447,
      "lat": 32.5703010559,
      "lon": -83.7219009399,
      "tz": "America\/New_York"
  },
  "5IA3": {
      "icao": "5IA3",
      "iata": "",
      "name": "Watkins Private Airport",
      "city": "Humeston",
      "state": "Iowa",
      "country": "US",
      "elevation": 1095,
      "lat": 40.8438987732,
      "lon": -93.5427017212,
      "tz": "America\/Chicago"
  },
  "5IA5": {
      "icao": "5IA5",
      "iata": "",
      "name": "Folkerts Airport",
      "city": "Rudd",
      "state": "Iowa",
      "country": "US",
      "elevation": 1150,
      "lat": 43.1430015564,
      "lon": -92.8779983521,
      "tz": "America\/Chicago"
  },
  "5IA6": {
      "icao": "5IA6",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Renwick",
      "state": "Iowa",
      "country": "US",
      "elevation": 1145,
      "lat": 42.8305015564,
      "lon": -94.0115966797,
      "tz": "America\/Chicago"
  },
  "5IA7": {
      "icao": "5IA7",
      "iata": "",
      "name": "Murphy Field Private Airport",
      "city": "Wellman",
      "state": "Iowa",
      "country": "US",
      "elevation": 803,
      "lat": 41.4216995239,
      "lon": -91.8331985474,
      "tz": "America\/Chicago"
  },
  "5II1": {
      "icao": "5II1",
      "iata": "",
      "name": "Layne Field",
      "city": "New Winchester",
      "state": "Indiana",
      "country": "US",
      "elevation": 905,
      "lat": 39.7827987671,
      "lon": -86.6175003052,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5II2": {
      "icao": "5II2",
      "iata": "",
      "name": "Miles Field",
      "city": "New Whiteland",
      "state": "Indiana",
      "country": "US",
      "elevation": 795,
      "lat": 39.5597991943,
      "lon": -86.1350021362,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5II5": {
      "icao": "5II5",
      "iata": "",
      "name": "Koester Field",
      "city": "St. Wendel",
      "state": "Indiana",
      "country": "US",
      "elevation": 480,
      "lat": 38.1180144538,
      "lon": -87.7020978928,
      "tz": "America\/Chicago"
  },
  "5II8": {
      "icao": "5II8",
      "iata": "",
      "name": "Jacobi Airport",
      "city": "Palmyra",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 38.4100990295,
      "lon": -86.1288986206,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5II9": {
      "icao": "5II9",
      "iata": "",
      "name": "Aero Plaines Airport",
      "city": "Youngstown",
      "state": "Indiana",
      "country": "US",
      "elevation": 570,
      "lat": 39.3527984619,
      "lon": -87.3733978271,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5IL2": {
      "icao": "5IL2",
      "iata": "",
      "name": "Panther Field",
      "city": "Pinckneyville",
      "state": "Illinois",
      "country": "US",
      "elevation": 432,
      "lat": 38.0638999939,
      "lon": -89.387802124,
      "tz": "America\/Chicago"
  },
  "5IN3": {
      "icao": "5IN3",
      "iata": "",
      "name": "Tragesser Airport",
      "city": "Tipton",
      "state": "Indiana",
      "country": "US",
      "elevation": 875,
      "lat": 40.3003005981,
      "lon": -86.0503005981,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5IN4": {
      "icao": "5IN4",
      "iata": "",
      "name": "Wyckoff Airstrip",
      "city": "Valparaiso",
      "state": "Indiana",
      "country": "US",
      "elevation": 780,
      "lat": 41.4833984375,
      "lon": -86.9499969482,
      "tz": "America\/Chicago"
  },
  "5IN5": {
      "icao": "5IN5",
      "iata": "",
      "name": "Berkey Field",
      "city": "Warsaw",
      "state": "Indiana",
      "country": "US",
      "elevation": 858,
      "lat": 41.2877998352,
      "lon": -85.9005966187,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5IN6": {
      "icao": "5IN6",
      "iata": "",
      "name": "Orthodontic Strip",
      "city": "Westville",
      "state": "Indiana",
      "country": "US",
      "elevation": 805,
      "lat": 41.5875015259,
      "lon": -86.9028015137,
      "tz": "America\/Chicago"
  },
  "5IN9": {
      "icao": "5IN9",
      "iata": "",
      "name": "Stangland Airport",
      "city": "Wolflake",
      "state": "Indiana",
      "country": "US",
      "elevation": 924,
      "lat": 41.3162002563,
      "lon": -85.4649963379,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5IS3": {
      "icao": "5IS3",
      "iata": "",
      "name": "Riverveiw Airport",
      "city": "Evansville",
      "state": "Illinois",
      "country": "US",
      "elevation": 490,
      "lat": 38.0783996582,
      "lon": -89.979598999,
      "tz": "America\/Chicago"
  },
  "5IS4": {
      "icao": "5IS4",
      "iata": "",
      "name": "Corman Acres Airport",
      "city": "Lake City",
      "state": "Illinois",
      "country": "US",
      "elevation": 710,
      "lat": 39.7661018372,
      "lon": -88.7795028687,
      "tz": "America\/Chicago"
  },
  "5IS7": {
      "icao": "5IS7",
      "iata": "",
      "name": "Cast Airport",
      "city": "Fairmount",
      "state": "Illinois",
      "country": "US",
      "elevation": 670,
      "lat": 40.038898468,
      "lon": -87.7817001343,
      "tz": "America\/Chicago"
  },
  "5IS9": {
      "icao": "5IS9",
      "iata": "",
      "name": "Mc Pherson Airport",
      "city": "Carthage",
      "state": "Illinois",
      "country": "US",
      "elevation": 640,
      "lat": 40.3306007385,
      "lon": -91.0501022339,
      "tz": "America\/Chicago"
  },
  "5KS1": {
      "icao": "5KS1",
      "iata": "",
      "name": "Threshing Bee Airport",
      "city": "Mc Louth",
      "state": "Kansas",
      "country": "US",
      "elevation": 1150,
      "lat": 39.1938018799,
      "lon": -95.2163009644,
      "tz": "America\/Chicago"
  },
  "5KS2": {
      "icao": "5KS2",
      "iata": "",
      "name": "Fortmeyer Airport",
      "city": "Goodland",
      "state": "Kansas",
      "country": "US",
      "elevation": 3650,
      "lat": 39.3417015076,
      "lon": -101.6660003662,
      "tz": "America\/Denver"
  },
  "5KS3": {
      "icao": "5KS3",
      "iata": "",
      "name": "Deweze Airport",
      "city": "Harper",
      "state": "Kansas",
      "country": "US",
      "elevation": 1414,
      "lat": 37.279499054,
      "lon": -98.0180969238,
      "tz": "America\/Chicago"
  },
  "5KS4": {
      "icao": "5KS4",
      "iata": "",
      "name": "Hall Farms Airport",
      "city": "Kanorado",
      "state": "Kansas",
      "country": "US",
      "elevation": 3908,
      "lat": 39.2299995422,
      "lon": -101.983001709,
      "tz": "America\/Denver"
  },
  "5KS6": {
      "icao": "5KS6",
      "iata": "",
      "name": "Mollenkamp Field",
      "city": "Leoti",
      "state": "Kansas",
      "country": "US",
      "elevation": 3295,
      "lat": 38.7667007446,
      "lon": -101.2570037842,
      "tz": "America\/Chicago"
  },
  "5KS8": {
      "icao": "5KS8",
      "iata": "",
      "name": "Jack Poore Airport",
      "city": "Mc Donald",
      "state": "Kansas",
      "country": "US",
      "elevation": 3330,
      "lat": 39.8180999756,
      "lon": -101.3479995728,
      "tz": "America\/Chicago"
  },
  "5KS9": {
      "icao": "5KS9",
      "iata": "",
      "name": "Black Airport",
      "city": "Mc Donald",
      "state": "Kansas",
      "country": "US",
      "elevation": 3345,
      "lat": 39.8258018494,
      "lon": -101.3629989624,
      "tz": "America\/Chicago"
  },
  "5KY2": {
      "icao": "5KY2",
      "iata": "",
      "name": "Larkins Farm Airport",
      "city": "Bardwell",
      "state": "Kentucky",
      "country": "US",
      "elevation": 390,
      "lat": 36.8511009216,
      "lon": -88.926902771,
      "tz": "America\/Chicago"
  },
  "5KY3": {
      "icao": "5KY3",
      "iata": "",
      "name": "West Kentucky Airpark",
      "city": "Paducah",
      "state": "Kentucky",
      "country": "US",
      "elevation": 338,
      "lat": 36.9674987793,
      "lon": -88.5652999878,
      "tz": "America\/Chicago"
  },
  "5KY5": {
      "icao": "5KY5",
      "iata": "",
      "name": "Lowe Airport",
      "city": "Cadiz",
      "state": "Kentucky",
      "country": "US",
      "elevation": 498,
      "lat": 36.8624992371,
      "lon": -87.7902984619,
      "tz": "America\/Chicago"
  },
  "5KY6": {
      "icao": "5KY6",
      "iata": "",
      "name": "Jake'S Field",
      "city": "Smithfield",
      "state": "Kentucky",
      "country": "US",
      "elevation": 903,
      "lat": 38.420556,
      "lon": -85.257778,
      "tz": "America\/New_York"
  },
  "5KY7": {
      "icao": "5KY7",
      "iata": "",
      "name": "Dale Hollow Regional (Petro Field) Airport",
      "city": "Albany",
      "state": "Kentucky",
      "country": "US",
      "elevation": 980,
      "lat": 36.6377983093,
      "lon": -85.1638031006,
      "tz": "America\/Chicago"
  },
  "5KY8": {
      "icao": "5KY8",
      "iata": "",
      "name": "Harold Reynolds Airport",
      "city": "Harrodsburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 860,
      "lat": 37.889722,
      "lon": -84.927778,
      "tz": "America\/New_York"
  },
  "5KY9": {
      "icao": "5KY9",
      "iata": "",
      "name": "Nolin Aero Salvage Airport",
      "city": "Bee Spring",
      "state": "Kentucky",
      "country": "US",
      "elevation": 700,
      "lat": 37.3325004578,
      "lon": -86.2542037964,
      "tz": "America\/Chicago"
  },
  "5LA0": {
      "icao": "5LA0",
      "iata": "",
      "name": "Juneau Ultralightport",
      "city": "Shreveport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 200,
      "lat": 32.5918006897,
      "lon": -93.8280029297,
      "tz": "America\/Chicago"
  },
  "5LA1": {
      "icao": "5LA1",
      "iata": "",
      "name": "Kent's Flying Service Airport",
      "city": "Moreauville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 55,
      "lat": 31.0279998779,
      "lon": -91.9812011719,
      "tz": "America\/Chicago"
  },
  "5LL0": {
      "icao": "5LL0",
      "iata": "",
      "name": "Beckerman Field",
      "city": "Mount Carmel",
      "state": "Illinois",
      "country": "US",
      "elevation": 424,
      "lat": 38.4084014893,
      "lon": -87.8375015259,
      "tz": "America\/Chicago"
  },
  "5LL1": {
      "icao": "5LL1",
      "iata": "",
      "name": "Hilvety Airport",
      "city": "Moweaqua",
      "state": "Illinois",
      "country": "US",
      "elevation": 674,
      "lat": 39.6500015259,
      "lon": -88.9750976563,
      "tz": "America\/Chicago"
  },
  "5LL2": {
      "icao": "5LL2",
      "iata": "",
      "name": "Paul E. Kroenlein Airport",
      "city": "Moweaqua",
      "state": "Illinois",
      "country": "US",
      "elevation": 710,
      "lat": 39.6083984375,
      "lon": -88.8583984375,
      "tz": "America\/Chicago"
  },
  "5LL3": {
      "icao": "5LL3",
      "iata": "",
      "name": "Mayhall Airport",
      "city": "Murdock",
      "state": "Illinois",
      "country": "US",
      "elevation": 643,
      "lat": 39.7695007324,
      "lon": -88.0952987671,
      "tz": "America\/Chicago"
  },
  "5LL5": {
      "icao": "5LL5",
      "iata": "",
      "name": "Gordon Brown Airport",
      "city": "Trivoli",
      "state": "Illinois",
      "country": "US",
      "elevation": 745,
      "lat": 40.7014007568,
      "lon": -89.8840026855,
      "tz": "America\/Chicago"
  },
  "5LL7": {
      "icao": "5LL7",
      "iata": "",
      "name": "Hilbert's Funny Farm Airport",
      "city": "Union",
      "state": "Illinois",
      "country": "US",
      "elevation": 850,
      "lat": 42.2106018066,
      "lon": -88.5150985718,
      "tz": "America\/Chicago"
  },
  "5LL8": {
      "icao": "5LL8",
      "iata": "",
      "name": "Hugh Van Voorst Airport",
      "city": "Union Hill",
      "state": "Illinois",
      "country": "US",
      "elevation": 617,
      "lat": 41.1123008728,
      "lon": -88.1400985718,
      "tz": "America\/Chicago"
  },
  "5LS6": {
      "icao": "5LS6",
      "iata": "",
      "name": "Arkla Flyers Inc Airport",
      "city": "Mer Rouge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 90,
      "lat": 32.7650985718,
      "lon": -91.7962036133,
      "tz": "America\/Chicago"
  },
  "5LS9": {
      "icao": "5LS9",
      "iata": "",
      "name": "Ammons Airport",
      "city": "Zwolle",
      "state": "Louisiana",
      "country": "US",
      "elevation": 210,
      "lat": 31.5316009521,
      "lon": -93.7077026367,
      "tz": "America\/Chicago"
  },
  "5MA3": {
      "icao": "5MA3",
      "iata": "",
      "name": "Pasport Airport",
      "city": "West Brookfield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 1010,
      "lat": 42.1875991821,
      "lon": -72.1430969238,
      "tz": "America\/New_York"
  },
  "5MA9": {
      "icao": "5MA9",
      "iata": "",
      "name": "Allen's Pond Airport",
      "city": "Westport",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 60,
      "lat": 41.5111999512,
      "lon": -71.0280990601,
      "tz": "America\/New_York"
  },
  "5MD0": {
      "icao": "5MD0",
      "iata": "",
      "name": "Marble Head Farm Airport",
      "city": "Ridgely",
      "state": "Maryland",
      "country": "US",
      "elevation": 70,
      "lat": 38.9893989563,
      "lon": -75.8666992188,
      "tz": "America\/New_York"
  },
  "5MD2": {
      "icao": "5MD2",
      "iata": "",
      "name": "Marsh Field",
      "city": "Frostburg",
      "state": "Maryland",
      "country": "US",
      "elevation": 2600,
      "lat": 39.5766983032,
      "lon": -78.9186019897,
      "tz": "America\/New_York"
  },
  "5MD5": {
      "icao": "5MD5",
      "iata": "",
      "name": "Flying W Airport",
      "city": "Princess Anne",
      "state": "Maryland",
      "country": "US",
      "elevation": 18,
      "lat": 38.1960983276,
      "lon": -75.7093963623,
      "tz": "America\/New_York"
  },
  "5MD7": {
      "icao": "5MD7",
      "iata": "",
      "name": "Bluemel Field",
      "city": "Princess Anne",
      "state": "Maryland",
      "country": "US",
      "elevation": 3,
      "lat": 38.1335983276,
      "lon": -75.7630996704,
      "tz": "America\/New_York"
  },
  "5MD8": {
      "icao": "5MD8",
      "iata": "",
      "name": "Edelen Field",
      "city": "La Plata",
      "state": "Maryland",
      "country": "US",
      "elevation": 151,
      "lat": 38.4910011292,
      "lon": -77.0115966797,
      "tz": "America\/New_York"
  },
  "5MI1": {
      "icao": "5MI1",
      "iata": "",
      "name": "Wilds Field",
      "city": "Cedar Springs",
      "state": "Michigan",
      "country": "US",
      "elevation": 885,
      "lat": 43.2513999939,
      "lon": -85.5428009033,
      "tz": "America\/Detroit"
  },
  "5MI2": {
      "icao": "5MI2",
      "iata": "",
      "name": "Mc Question's Airport",
      "city": "Leroy",
      "state": "Michigan",
      "country": "US",
      "elevation": 1317,
      "lat": 44.0772018433,
      "lon": -85.4375991821,
      "tz": "America\/Detroit"
  },
  "5MI3": {
      "icao": "5MI3",
      "iata": "",
      "name": "Cridler Airport",
      "city": "Lowell",
      "state": "Michigan",
      "country": "US",
      "elevation": 870,
      "lat": 43.0511016846,
      "lon": -85.4199981689,
      "tz": "America\/Detroit"
  },
  "5MI4": {
      "icao": "5MI4",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Howard City",
      "state": "Michigan",
      "country": "US",
      "elevation": 902,
      "lat": 43.4283981323,
      "lon": -85.4548034668,
      "tz": "America\/Detroit"
  },
  "5MI7": {
      "icao": "5MI7",
      "iata": "",
      "name": "Williams Field",
      "city": "Jackson",
      "state": "Michigan",
      "country": "US",
      "elevation": 960,
      "lat": 42.3610992432,
      "lon": -84.4247970581,
      "tz": "America\/Detroit"
  },
  "5MI8": {
      "icao": "5MI8",
      "iata": "",
      "name": "Lakeside Airport",
      "city": "Holt",
      "state": "Michigan",
      "country": "US",
      "elevation": 900,
      "lat": 42.6716995239,
      "lon": -84.4882965088,
      "tz": "America\/Detroit"
  },
  "5MN0": {
      "icao": "5MN0",
      "iata": "",
      "name": "Scrabeck Airport",
      "city": "High Forest",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1280,
      "lat": 43.8666000366,
      "lon": -92.5610961914,
      "tz": "America\/Chicago"
  },
  "5MN1": {
      "icao": "5MN1",
      "iata": "",
      "name": "Budde Airport",
      "city": "Mankato",
      "state": "Minnesota",
      "country": "US",
      "elevation": 975,
      "lat": 44.2005004883,
      "lon": -94.1132965088,
      "tz": "America\/Chicago"
  },
  "5MN2": {
      "icao": "5MN2",
      "iata": "",
      "name": "Hoiland Field",
      "city": "Bagley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1535,
      "lat": 47.3958015442,
      "lon": -95.3675003052,
      "tz": "America\/Chicago"
  },
  "5MN4": {
      "icao": "5MN4",
      "iata": "",
      "name": "Seven Hills Airport",
      "city": "Clearwater",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1010,
      "lat": 45.4124984741,
      "lon": -94.0766983032,
      "tz": "America\/Chicago"
  },
  "5MN5": {
      "icao": "5MN5",
      "iata": "",
      "name": "Barnes Airport",
      "city": "Lindstrom",
      "state": "Minnesota",
      "country": "US",
      "elevation": 945,
      "lat": 45.4364013672,
      "lon": -92.8348999023,
      "tz": "America\/Chicago"
  },
  "5MN7": {
      "icao": "5MN7",
      "iata": "",
      "name": "Lindey's Landing Airport",
      "city": "Deerwood",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1295,
      "lat": 46.3871994019,
      "lon": -93.9319000244,
      "tz": "America\/Chicago"
  },
  "5MN8": {
      "icao": "5MN8",
      "iata": "",
      "name": "Rick Mathias Private Airport",
      "city": "Dumont",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1050,
      "lat": 45.7193984985,
      "lon": -96.3103027344,
      "tz": "America\/Chicago"
  },
  "5MN9": {
      "icao": "5MN9",
      "iata": "",
      "name": "Chanlin Field",
      "city": "Middle River",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1147,
      "lat": 48.4235992432,
      "lon": -96.1288986206,
      "tz": "America\/Chicago"
  },
  "5MO1": {
      "icao": "5MO1",
      "iata": "",
      "name": "Irons Airport",
      "city": "Moberly",
      "state": "Missouri",
      "country": "US",
      "elevation": 860,
      "lat": 39.3842010498,
      "lon": -92.3762969971,
      "tz": "America\/Chicago"
  },
  "5MO3": {
      "icao": "5MO3",
      "iata": "",
      "name": "Bil-Mitch Airport",
      "city": "Monett",
      "state": "Missouri",
      "country": "US",
      "elevation": 1342,
      "lat": 36.9406013489,
      "lon": -93.9573974609,
      "tz": "America\/Chicago"
  },
  "5MO9": {
      "icao": "5MO9",
      "iata": "",
      "name": "Oak Ridge Farms Airport",
      "city": "New Boston",
      "state": "Missouri",
      "country": "US",
      "elevation": 995,
      "lat": 39.9824981689,
      "lon": -92.864402771,
      "tz": "America\/Chicago"
  },
  "5MS1": {
      "icao": "5MS1",
      "iata": "",
      "name": "Rollang Field",
      "city": "Rolling Fork",
      "state": "Mississippi",
      "country": "US",
      "elevation": 106,
      "lat": 32.9542999268,
      "lon": -90.8459014893,
      "tz": "America\/Chicago"
  },
  "5MS2": {
      "icao": "5MS2",
      "iata": "",
      "name": "Turkey Bayou Airpark",
      "city": "Lakeshore",
      "state": "Mississippi",
      "country": "US",
      "elevation": 9,
      "lat": 30.2507991791,
      "lon": -89.4509963989,
      "tz": "America\/Chicago"
  },
  "5MS3": {
      "icao": "5MS3",
      "iata": "",
      "name": "Porter Airport",
      "city": "Columbia",
      "state": "Mississippi",
      "country": "US",
      "elevation": 158,
      "lat": 31.1749992371,
      "lon": -89.8031005859,
      "tz": "America\/Chicago"
  },
  "5MS4": {
      "icao": "5MS4",
      "iata": "",
      "name": "Walnut Creek Airport",
      "city": "Canton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 315,
      "lat": 32.5926017761,
      "lon": -89.925201416,
      "tz": "America\/Chicago"
  },
  "5MS5": {
      "icao": "5MS5",
      "iata": "",
      "name": "Mint Julep Airpark",
      "city": "Picayune",
      "state": "Mississippi",
      "country": "US",
      "elevation": 100,
      "lat": 30.5452003479,
      "lon": -89.4627990723,
      "tz": "America\/Chicago"
  },
  "5MS6": {
      "icao": "5MS6",
      "iata": "",
      "name": "Dee's Strip",
      "city": "Brandon",
      "state": "Mississippi",
      "country": "US",
      "elevation": 329,
      "lat": 32.4230003357,
      "lon": -90.0076980591,
      "tz": "America\/Chicago"
  },
  "5MS8": {
      "icao": "5MS8",
      "iata": "",
      "name": "Ball Airport",
      "city": "Okolona",
      "state": "Mississippi",
      "country": "US",
      "elevation": 298,
      "lat": 33.875,
      "lon": -88.723333,
      "tz": "America\/Chicago"
  },
  "5NA0": {
      "icao": "5NA0",
      "iata": "",
      "name": "Semchenko Airport",
      "city": "Max",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2065,
      "lat": 47.773601532,
      "lon": -101.2789993286,
      "tz": "America\/Chicago"
  },
  "5NA2": {
      "icao": "5NA2",
      "iata": "",
      "name": "Haugen Farm Airstrip",
      "city": "Portland",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1002,
      "lat": 47.5377998352,
      "lon": -97.3637008667,
      "tz": "America\/Chicago"
  },
  "5NC1": {
      "icao": "5NC1",
      "iata": "",
      "name": "Tara Airbase Airport",
      "city": "Mocksville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 756,
      "lat": 35.7932014465,
      "lon": -80.512298584,
      "tz": "America\/New_York"
  },
  "5NC2": {
      "icao": "5NC2",
      "iata": "",
      "name": "Lathan Strip",
      "city": "Monroe",
      "state": "North Carolina",
      "country": "US",
      "elevation": 575,
      "lat": 34.8763008118,
      "lon": -80.6192016602,
      "tz": "America\/New_York"
  },
  "5NC3": {
      "icao": "5NC3",
      "iata": "",
      "name": "Gilliam-Mc Connell Airfield",
      "city": "Carthage",
      "state": "North Carolina",
      "country": "US",
      "elevation": 445,
      "lat": 35.3417015076,
      "lon": -79.4368972778,
      "tz": "America\/New_York"
  },
  "5NC5": {
      "icao": "5NC5",
      "iata": "",
      "name": "The Duchy Airport",
      "city": "Chapel Hill",
      "state": "North Carolina",
      "country": "US",
      "elevation": 650,
      "lat": 35.9814987183,
      "lon": -79.2731018066,
      "tz": "America\/New_York"
  },
  "5ND0": {
      "icao": "5ND0",
      "iata": "",
      "name": "Stiehl Airport",
      "city": "Buford",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2277,
      "lat": 48.0764007568,
      "lon": -104.0319976807,
      "tz": "America\/Chicago"
  },
  "5ND3": {
      "icao": "5ND3",
      "iata": "",
      "name": "Craig Private Airport",
      "city": "Bathgate",
      "state": "North Dakota",
      "country": "US",
      "elevation": 800,
      "lat": 48.8554992676,
      "lon": -97.3219985962,
      "tz": "America\/Chicago"
  },
  "5ND4": {
      "icao": "5ND4",
      "iata": "",
      "name": "Tesch Strip",
      "city": "Lidgerwood",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1114,
      "lat": 46.1436004639,
      "lon": -97.2431030273,
      "tz": "America\/Chicago"
  },
  "5ND9": {
      "icao": "5ND9",
      "iata": "",
      "name": "Deck Airport",
      "city": "Hillsboro",
      "state": "North Dakota",
      "country": "US",
      "elevation": 916,
      "lat": 47.4846992493,
      "lon": -97.074798584,
      "tz": "America\/Chicago"
  },
  "5NE1": {
      "icao": "5NE1",
      "iata": "",
      "name": "Trumbull Ranch Airport",
      "city": "Ringgold",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3260,
      "lat": 41.5499992371,
      "lon": -100.8339996338,
      "tz": "America\/Chicago"
  },
  "5NE2": {
      "icao": "5NE2",
      "iata": "",
      "name": "Eickhoff Strip",
      "city": "Shubert",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1000,
      "lat": 40.1958007813,
      "lon": -95.6294021606,
      "tz": "America\/Chicago"
  },
  "5NE3": {
      "icao": "5NE3",
      "iata": "",
      "name": "Diamond Bar Jones Airport",
      "city": "Stapleton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2900,
      "lat": 41.4874992371,
      "lon": -100.4879989624,
      "tz": "America\/Chicago"
  },
  "5NE4": {
      "icao": "5NE4",
      "iata": "",
      "name": "Snyder Ranch Airport",
      "city": "Sutherland",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3330,
      "lat": 41.5722007751,
      "lon": -101.1719970703,
      "tz": "America\/Chicago"
  },
  "5NE5": {
      "icao": "5NE5",
      "iata": "",
      "name": "Trego Airport",
      "city": "Sutherland",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2957,
      "lat": 41.2066993713,
      "lon": -101.1429977417,
      "tz": "America\/Chicago"
  },
  "5NE6": {
      "icao": "5NE6",
      "iata": "",
      "name": "Bakers Acres Airport",
      "city": "Trenton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2950,
      "lat": 40.25,
      "lon": -101.0999984741,
      "tz": "America\/Chicago"
  },
  "5NE9": {
      "icao": "5NE9",
      "iata": "",
      "name": "Dodson Brothers Airport",
      "city": "Venango",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3575,
      "lat": 40.7332992554,
      "lon": -102.0500030518,
      "tz": "America\/Denver"
  },
  "5NJ2": {
      "icao": "5NJ2",
      "iata": "",
      "name": "Herr Mountain Airport",
      "city": "Stanton",
      "state": "New Jersey",
      "country": "US",
      "elevation": 560,
      "lat": 40.5918006897,
      "lon": -74.8413009644,
      "tz": "America\/New_York"
  },
  "5NK0": {
      "icao": "5NK0",
      "iata": "",
      "name": "Salubrious Point Airport",
      "city": "Chaumont",
      "state": "New York",
      "country": "US",
      "elevation": 295,
      "lat": 44.0442008972,
      "lon": -76.1436004639,
      "tz": "America\/New_York"
  },
  "5NK1": {
      "icao": "5NK1",
      "iata": "",
      "name": "Toggenburg Farms Airport",
      "city": "Newark",
      "state": "New York",
      "country": "US",
      "elevation": 435,
      "lat": 43.1111984253,
      "lon": -77.0539016724,
      "tz": "America\/New_York"
  },
  "5NK5": {
      "icao": "5NK5",
      "iata": "",
      "name": "Kingdom Field",
      "city": "Oswego",
      "state": "New York",
      "country": "US",
      "elevation": 380,
      "lat": 43.4086990356,
      "lon": -76.4412994385,
      "tz": "America\/New_York"
  },
  "5NK9": {
      "icao": "5NK9",
      "iata": "",
      "name": "Treichler Farm Airport",
      "city": "Wales",
      "state": "New York",
      "country": "US",
      "elevation": 1140,
      "lat": 42.7616996765,
      "lon": -78.4961013794,
      "tz": "America\/New_York"
  },
  "5NY1": {
      "icao": "5NY1",
      "iata": "",
      "name": "Tomahawk Hills Airport",
      "city": "Meridale",
      "state": "New York",
      "country": "US",
      "elevation": 2380,
      "lat": 42.3997993469,
      "lon": -74.9063034058,
      "tz": "America\/New_York"
  },
  "5NY3": {
      "icao": "5NY3",
      "iata": "",
      "name": "Keech Airport",
      "city": "Etna",
      "state": "New York",
      "country": "US",
      "elevation": 1070,
      "lat": 42.4929008484,
      "lon": -76.3869018555,
      "tz": "America\/New_York"
  },
  "5NY4": {
      "icao": "5NY4",
      "iata": "",
      "name": "Stanwix Heights Airport",
      "city": "Rome",
      "state": "New York",
      "country": "US",
      "elevation": 610,
      "lat": 43.1576004028,
      "lon": -75.4307022095,
      "tz": "America\/New_York"
  },
  "5NY5": {
      "icao": "5NY5",
      "iata": "",
      "name": "Gardiner Airport",
      "city": "Gardiner",
      "state": "New York",
      "country": "US",
      "elevation": 340,
      "lat": 41.6668014526,
      "lon": -74.149597168,
      "tz": "America\/New_York"
  },
  "5NY7": {
      "icao": "5NY7",
      "iata": "",
      "name": "Rolling Hills Airport",
      "city": "Goshen",
      "state": "New York",
      "country": "US",
      "elevation": 410,
      "lat": 41.3278999329,
      "lon": -74.4098968506,
      "tz": "America\/New_York"
  },
  "5OH0": {
      "icao": "5OH0",
      "iata": "",
      "name": "Alge Airport",
      "city": "Arlington",
      "state": "Ohio",
      "country": "US",
      "elevation": 857,
      "lat": 40.904800415,
      "lon": -83.6446990967,
      "tz": "America\/New_York"
  },
  "5OH4": {
      "icao": "5OH4",
      "iata": "",
      "name": "Armington Airport",
      "city": "Austinburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 820,
      "lat": 41.7291984558,
      "lon": -80.8789978027,
      "tz": "America\/New_York"
  },
  "5OH6": {
      "icao": "5OH6",
      "iata": "",
      "name": "Johnsons Field",
      "city": "Avon",
      "state": "Ohio",
      "country": "US",
      "elevation": 700,
      "lat": 41.4319992065,
      "lon": -81.974899292,
      "tz": "America\/New_York"
  },
  "5OH7": {
      "icao": "5OH7",
      "iata": "",
      "name": "Keller Airport",
      "city": "Avon",
      "state": "Ohio",
      "country": "US",
      "elevation": 692,
      "lat": 41.4467010498,
      "lon": -82.0071029663,
      "tz": "America\/New_York"
  },
  "5OH9": {
      "icao": "5OH9",
      "iata": "",
      "name": "Furey Airport",
      "city": "Malvern",
      "state": "Ohio",
      "country": "US",
      "elevation": 1050,
      "lat": 40.6972007751,
      "lon": -81.1791992188,
      "tz": "America\/New_York"
  },
  "5OI0": {
      "icao": "5OI0",
      "iata": "",
      "name": "Rohrer Airport",
      "city": "Loveland",
      "state": "Ohio",
      "country": "US",
      "elevation": 800,
      "lat": 39.284198761,
      "lon": -84.2113037109,
      "tz": "America\/New_York"
  },
  "5OI3": {
      "icao": "5OI3",
      "iata": "",
      "name": "Sheets Field",
      "city": "Kinsman",
      "state": "Ohio",
      "country": "US",
      "elevation": 1225,
      "lat": 41.4137001038,
      "lon": -80.5248031616,
      "tz": "America\/New_York"
  },
  "5OI5": {
      "icao": "5OI5",
      "iata": "",
      "name": "Hamrick Airport",
      "city": "Willshire",
      "state": "Ohio",
      "country": "US",
      "elevation": 810,
      "lat": 40.6934013367,
      "lon": -84.7805023193,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "5OI7": {
      "icao": "5OI7",
      "iata": "",
      "name": "Gruetter Airport",
      "city": "Curtice",
      "state": "Ohio",
      "country": "US",
      "elevation": 600,
      "lat": 41.5924987793,
      "lon": -83.37550354,
      "tz": "America\/New_York"
  },
  "5OI8": {
      "icao": "5OI8",
      "iata": "",
      "name": "Mc Knight Airport",
      "city": "Johnstown",
      "state": "Ohio",
      "country": "US",
      "elevation": 1172,
      "lat": 40.1902999878,
      "lon": -82.6546020508,
      "tz": "America\/New_York"
  },
  "5OI9": {
      "icao": "5OI9",
      "iata": "",
      "name": "Rogers Private Airport",
      "city": "Sherwood",
      "state": "Ohio",
      "country": "US",
      "elevation": 725,
      "lat": 41.3278007507,
      "lon": -84.5577011108,
      "tz": "America\/New_York"
  },
  "5OK2": {
      "icao": "5OK2",
      "iata": "",
      "name": "Christopher M. Rippee Memorial Airport",
      "city": "Non",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 820,
      "lat": 34.776667,
      "lon": -96.245278,
      "tz": "America\/Chicago"
  },
  "5OK3": {
      "icao": "5OK3",
      "iata": "",
      "name": "Stearmans Roost Airport",
      "city": "Vinita",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 702,
      "lat": 36.4883995056,
      "lon": -95.1438980103,
      "tz": "America\/Chicago"
  },
  "5OK4": {
      "icao": "5OK4",
      "iata": "",
      "name": "Pacer Field",
      "city": "Goldsby",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1140,
      "lat": 35.1170005798,
      "lon": -97.4505996704,
      "tz": "America\/Chicago"
  },
  "5OK6": {
      "icao": "5OK6",
      "iata": "",
      "name": "Woodleaf Aero Estates Airport",
      "city": "Edmond",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1201,
      "lat": 35.712600708,
      "lon": -97.3669967651,
      "tz": "America\/Chicago"
  },
  "5OK8": {
      "icao": "5OK8",
      "iata": "",
      "name": "Alford A. Bratcher Airport",
      "city": "Marlow",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 34.6750984192,
      "lon": -97.8559036255,
      "tz": "America\/Chicago"
  },
  "5OK9": {
      "icao": "5OK9",
      "iata": "",
      "name": "Night Hawk Airpatch Ultralightport",
      "city": "Inola",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 670,
      "lat": 36.189201355,
      "lon": -95.5402984619,
      "tz": "America\/Chicago"
  },
  "5OR0": {
      "icao": "5OR0",
      "iata": "",
      "name": "Backachers Ranch Airport",
      "city": "Selma",
      "state": "Oregon",
      "country": "US",
      "elevation": 1460,
      "lat": 42.2850990295,
      "lon": -123.5329971313,
      "tz": "America\/Los_Angeles"
  },
  "5OR2": {
      "icao": "5OR2",
      "iata": "",
      "name": "Bushnell Airport",
      "city": "Sheridan",
      "state": "Oregon",
      "country": "US",
      "elevation": 220,
      "lat": 45.1031990051,
      "lon": -123.425003052,
      "tz": "America\/Los_Angeles"
  },
  "5OR3": {
      "icao": "5OR3",
      "iata": "",
      "name": "Siletz Airport",
      "city": "Siletz",
      "state": "Oregon",
      "country": "US",
      "elevation": 100,
      "lat": 44.7304000854,
      "lon": -123.9150009155,
      "tz": "America\/Los_Angeles"
  },
  "5OR4": {
      "icao": "5OR4",
      "iata": "",
      "name": "Flying T Ranch Airport",
      "city": "Sprague River",
      "state": "Oregon",
      "country": "US",
      "elevation": 4340,
      "lat": 42.4348983765,
      "lon": -121.3499984741,
      "tz": "America\/Los_Angeles"
  },
  "5OR5": {
      "icao": "5OR5",
      "iata": "",
      "name": "Juniper Air Park",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3490,
      "lat": 44.0345993042,
      "lon": -121.1179962158,
      "tz": "America\/Los_Angeles"
  },
  "5OR8": {
      "icao": "5OR8",
      "iata": "",
      "name": "Hatch Airport",
      "city": "Stayton",
      "state": "Oregon",
      "country": "US",
      "elevation": 380,
      "lat": 44.7732009888,
      "lon": -122.8499984741,
      "tz": "America\/Los_Angeles"
  },
  "5OR9": {
      "icao": "5OR9",
      "iata": "",
      "name": "Lone Oaks Ranch Airport",
      "city": "Stayton",
      "state": "Oregon",
      "country": "US",
      "elevation": 630,
      "lat": 44.7767982483,
      "lon": -122.7600021362,
      "tz": "America\/Los_Angeles"
  },
  "5PA0": {
      "icao": "5PA0",
      "iata": "",
      "name": "Blomster Field Farm Airport",
      "city": "Carmichaels",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1050,
      "lat": 39.8728485107,
      "lon": -79.955329895,
      "tz": "America\/New_York"
  },
  "5PA1": {
      "icao": "5PA1",
      "iata": "",
      "name": "Broadt Personal Use Airport",
      "city": "Mainville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 920,
      "lat": 40.9972991943,
      "lon": -76.3837966919,
      "tz": "America\/New_York"
  },
  "5PA2": {
      "icao": "5PA2",
      "iata": "",
      "name": "Double D Skyranch Airport",
      "city": "St John's",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1140,
      "lat": 41.035900116,
      "lon": -76.0223999023,
      "tz": "America\/New_York"
  },
  "5PA3": {
      "icao": "5PA3",
      "iata": "",
      "name": "Metzler Airport",
      "city": "Manheim",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 420,
      "lat": 40.1668014526,
      "lon": -76.3996963501,
      "tz": "America\/New_York"
  },
  "5PA6": {
      "icao": "5PA6",
      "iata": "",
      "name": "Shield Farm Airport",
      "city": "Russell",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1240,
      "lat": 41.9311981201,
      "lon": -79.146697998,
      "tz": "America\/New_York"
  },
  "5PA8": {
      "icao": "5PA8",
      "iata": "",
      "name": "Chambers Airport",
      "city": "TunkHannock",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1021,
      "lat": 41.55009842,
      "lon": -76.04959869,
      "tz": "America\/New_York"
  },
  "5PA9": {
      "icao": "5PA9",
      "iata": "",
      "name": "Taylor Flight Park Ultralightport",
      "city": "Strattanville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1580,
      "lat": 41.1884002686,
      "lon": -79.2823028564,
      "tz": "America\/New_York"
  },
  "5PN5": {
      "icao": "5PN5",
      "iata": "",
      "name": "Bittner-Whitsel Airport",
      "city": "Rouzerville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 240,
      "lat": 39.7422981262,
      "lon": -77.5353012085,
      "tz": "America\/New_York"
  },
  "5PN7": {
      "icao": "5PN7",
      "iata": "",
      "name": "Jarrett Airport",
      "city": "Ivyland",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 210,
      "lat": 40.2332992554,
      "lon": -75.0749969482,
      "tz": "America\/New_York"
  },
  "5PN8": {
      "icao": "5PN8",
      "iata": "",
      "name": "Lincoln Farms Airport",
      "city": "Calvin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.3288993835,
      "lon": -78.0449981689,
      "tz": "America\/New_York"
  },
  "5PS4": {
      "icao": "5PS4",
      "iata": "",
      "name": "Hansen Airport",
      "city": "Sassamansville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 320,
      "lat": 40.3426017761,
      "lon": -75.5782012939,
      "tz": "America\/New_York"
  },
  "5PS5": {
      "icao": "5PS5",
      "iata": "",
      "name": "Chestnut Hill Airport",
      "city": "Duncannon",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 620,
      "lat": 40.3968009949,
      "lon": -77.0740966797,
      "tz": "America\/New_York"
  },
  "5PS9": {
      "icao": "5PS9",
      "iata": "",
      "name": "Tate Airport",
      "city": "West Rockhill",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 570,
      "lat": 40.3851013184,
      "lon": -75.3330001831,
      "tz": "America\/New_York"
  },
  "5TA0": {
      "icao": "5TA0",
      "iata": "",
      "name": "Hamilton Aircraft Inc Airport",
      "city": "Seminole",
      "state": "Texas",
      "country": "US",
      "elevation": 3520,
      "lat": 32.7319984436,
      "lon": -102.9440002441,
      "tz": "America\/Chicago"
  },
  "5TA1": {
      "icao": "5TA1",
      "iata": "",
      "name": "Charping Airport",
      "city": "Temple",
      "state": "Texas",
      "country": "US",
      "elevation": 683,
      "lat": 31.1935005188,
      "lon": -97.4072036743,
      "tz": "America\/Chicago"
  },
  "5TA2": {
      "icao": "5TA2",
      "iata": "",
      "name": "Rabbit Run Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 740,
      "lat": 31.4545993805,
      "lon": -97.2502975464,
      "tz": "America\/Chicago"
  },
  "5TA3": {
      "icao": "5TA3",
      "iata": "",
      "name": "Pearson Ranch Private Airport",
      "city": "Yoakum",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 29.3586006165,
      "lon": -97.1446990967,
      "tz": "America\/Chicago"
  },
  "5TA4": {
      "icao": "5TA4",
      "iata": "",
      "name": "Reed Airport",
      "city": "Claude",
      "state": "Texas",
      "country": "US",
      "elevation": 3369,
      "lat": 34.900100708,
      "lon": -101.3170013428,
      "tz": "America\/Chicago"
  },
  "5TA5": {
      "icao": "5TA5",
      "iata": "",
      "name": "Creasy Airport",
      "city": "Santa Fe",
      "state": "Texas",
      "country": "US",
      "elevation": 23,
      "lat": 29.3360996246,
      "lon": -95.115196228,
      "tz": "America\/Chicago"
  },
  "5TA6": {
      "icao": "5TA6",
      "iata": "",
      "name": "Knot 2 Shabby Airport",
      "city": "Winters",
      "state": "Texas",
      "country": "US",
      "elevation": 1820,
      "lat": 31.9344005585,
      "lon": -99.8943023682,
      "tz": "America\/Chicago"
  },
  "5TA7": {
      "icao": "5TA7",
      "iata": "",
      "name": "Kami-Kazi Airport",
      "city": "Santa Fe",
      "state": "Texas",
      "country": "US",
      "elevation": 25,
      "lat": 29.4207992554,
      "lon": -95.1591033936,
      "tz": "America\/Chicago"
  },
  "5TA8": {
      "icao": "5TA8",
      "iata": "",
      "name": "Deer Meadow Ranch Airport",
      "city": "Athens",
      "state": "Texas",
      "country": "US",
      "elevation": 520,
      "lat": 32.0133018494,
      "lon": -95.9282989502,
      "tz": "America\/Chicago"
  },
  "5TA9": {
      "icao": "5TA9",
      "iata": "",
      "name": "Seagoville Airport",
      "city": "Seagoville",
      "state": "Texas",
      "country": "US",
      "elevation": 415,
      "lat": 32.6099014282,
      "lon": -96.5269012451,
      "tz": "America\/Chicago"
  },
  "5TE0": {
      "icao": "5TE0",
      "iata": "",
      "name": "Comanche Ranch Airport",
      "city": "Eagle Pass",
      "state": "Texas",
      "country": "US",
      "elevation": 730,
      "lat": 28.6280002594,
      "lon": -100.158996582,
      "tz": "America\/Chicago"
  },
  "5TE1": {
      "icao": "5TE1",
      "iata": "",
      "name": "Rawls Ranch Airport",
      "city": "Marfa",
      "state": "Texas",
      "country": "US",
      "elevation": 4520,
      "lat": 29.6501998901,
      "lon": -103.9499969482,
      "tz": "America\/Chicago"
  },
  "5TE2": {
      "icao": "5TE2",
      "iata": "",
      "name": "Bleakley Ranch Airport",
      "city": "Dripping Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 1384,
      "lat": 30.2252006531,
      "lon": -98.141998291,
      "tz": "America\/Chicago"
  },
  "5TE3": {
      "icao": "5TE3",
      "iata": "",
      "name": "Alexander Ranch Airport",
      "city": "Dripping Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 1250,
      "lat": 30.271900177,
      "lon": -98.1169967651,
      "tz": "America\/Chicago"
  },
  "5TE4": {
      "icao": "5TE4",
      "iata": "",
      "name": "Perkins Prothro Cimarron Ranch Airport",
      "city": "Kerrick",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 3995,
      "lat": 36.5083999634,
      "lon": -102.391998291,
      "tz": "America\/Chicago"
  },
  "5TE5": {
      "icao": "5TE5",
      "iata": "",
      "name": "Iron Mountain Ranch Airport",
      "city": "Marathon",
      "state": "Texas",
      "country": "US",
      "elevation": 4313,
      "lat": 30.2738990784,
      "lon": -103.2369995117,
      "tz": "America\/Chicago"
  },
  "5TE6": {
      "icao": "5TE6",
      "iata": "",
      "name": "Keystone Ranch Airport",
      "city": "San Angelo",
      "state": "Texas",
      "country": "US",
      "elevation": 2023,
      "lat": 31.269167,
      "lon": -100.460833,
      "tz": "America\/Chicago"
  },
  "5TE7": {
      "icao": "5TE7",
      "iata": "",
      "name": "Renz Ranch Airport",
      "city": "Eagle Lake",
      "state": "Texas",
      "country": "US",
      "elevation": 220,
      "lat": 29.7194004059,
      "lon": -96.3094024658,
      "tz": "America\/Chicago"
  },
  "5TE8": {
      "icao": "5TE8",
      "iata": "",
      "name": "Willis N Clark Airport",
      "city": "Miami",
      "state": "Texas",
      "country": "US",
      "elevation": 3086,
      "lat": 35.7611999512,
      "lon": -100.7519989014,
      "tz": "America\/Chicago"
  },
  "5TN4": {
      "icao": "5TN4",
      "iata": "",
      "name": "Mcgraw's Backyard Airport",
      "city": "Greenback",
      "state": "Tennessee",
      "country": "US",
      "elevation": 920,
      "lat": 35.6706008911,
      "lon": -84.1157989502,
      "tz": "America\/New_York"
  },
  "5TN9": {
      "icao": "5TN9",
      "iata": "",
      "name": "One Grand Field",
      "city": "Cookeville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1000,
      "lat": 36.113899231,
      "lon": -85.5950012207,
      "tz": "America\/Chicago"
  },
  "5TS0": {
      "icao": "5TS0",
      "iata": "",
      "name": "Diehl Ranch Airport",
      "city": "Arcadia",
      "state": "Texas",
      "country": "US",
      "elevation": 26,
      "lat": 29.4125003815,
      "lon": -95.1223983765,
      "tz": "America\/Chicago"
  },
  "5TS1": {
      "icao": "5TS1",
      "iata": "",
      "name": "Uno Mas Ranch Airport",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 380,
      "lat": 28.2057991028,
      "lon": -99.1344985962,
      "tz": "America\/Chicago"
  },
  "5TS2": {
      "icao": "5TS2",
      "iata": "",
      "name": "Chan-C Airport",
      "city": "Coupland",
      "state": "Texas",
      "country": "US",
      "elevation": 536,
      "lat": 30.4794006348,
      "lon": -97.3191986084,
      "tz": "America\/Chicago"
  },
  "5TS3": {
      "icao": "5TS3",
      "iata": "",
      "name": "Knapp Pecan Orchard Airpark",
      "city": "Dawson",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 31.974199295,
      "lon": -96.6782989502,
      "tz": "America\/Chicago"
  },
  "5TS4": {
      "icao": "5TS4",
      "iata": "",
      "name": "Mc Entire Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 790,
      "lat": 33.1445999146,
      "lon": -97.6016998291,
      "tz": "America\/Chicago"
  },
  "5TS5": {
      "icao": "5TS5",
      "iata": "",
      "name": "Mc Donald Ranch Airport",
      "city": "Dilley",
      "state": "Texas",
      "country": "US",
      "elevation": 555,
      "lat": 28.6511001587,
      "lon": -99.2886962891,
      "tz": "America\/Chicago"
  },
  "5TS7": {
      "icao": "5TS7",
      "iata": "",
      "name": "R D Williams Airport",
      "city": "Garrison",
      "state": "Texas",
      "country": "US",
      "elevation": 360,
      "lat": 31.776599884,
      "lon": -94.485496521,
      "tz": "America\/Chicago"
  },
  "5TS8": {
      "icao": "5TS8",
      "iata": "",
      "name": "Bear Creek Ranch Airport",
      "city": "New Braunfels",
      "state": "Texas",
      "country": "US",
      "elevation": 1360,
      "lat": 29.808599472,
      "lon": -98.2724990845,
      "tz": "America\/Chicago"
  },
  "5TS9": {
      "icao": "5TS9",
      "iata": "",
      "name": "Big Duke's Place Airport",
      "city": "Indianola",
      "state": "Texas",
      "country": "US",
      "elevation": 5,
      "lat": 28.5303001404,
      "lon": -96.5274963379,
      "tz": "America\/Chicago"
  },
  "5TX0": {
      "icao": "5TX0",
      "iata": "",
      "name": "Hidden Valley Airpark",
      "city": "Lake Dallas",
      "state": "Texas",
      "country": "US",
      "elevation": 611,
      "lat": 33.173500061,
      "lon": -97.051399231,
      "tz": "America\/Chicago"
  },
  "5TX1": {
      "icao": "5TX1",
      "iata": "",
      "name": "Harley White Field",
      "city": "Lamkin",
      "state": "Texas",
      "country": "US",
      "elevation": 1120,
      "lat": 31.8612995148,
      "lon": -98.2594985962,
      "tz": "America\/Chicago"
  },
  "5TX2": {
      "icao": "5TX2",
      "iata": "",
      "name": "Grove Hill Airport",
      "city": "Leonard",
      "state": "Texas",
      "country": "US",
      "elevation": 721,
      "lat": 33.4222984314,
      "lon": -96.2169036865,
      "tz": "America\/Chicago"
  },
  "5TX4": {
      "icao": "5TX4",
      "iata": "",
      "name": "Black Mark Strip",
      "city": "Lewisville",
      "state": "Texas",
      "country": "US",
      "elevation": 635,
      "lat": 33.0335006714,
      "lon": -97.0670013428,
      "tz": "America\/Chicago"
  },
  "5TX6": {
      "icao": "5TX6",
      "iata": "",
      "name": "Hilliard Landing Area Airport",
      "city": "Lewisville",
      "state": "Texas",
      "country": "US",
      "elevation": 635,
      "lat": 33.0167999268,
      "lon": -97.0725021362,
      "tz": "America\/Chicago"
  },
  "5TX8": {
      "icao": "5TX8",
      "iata": "",
      "name": "Boon\/Lovelace Airport",
      "city": "Linden",
      "state": "Texas",
      "country": "US",
      "elevation": 429,
      "lat": 33.0321006775,
      "lon": -94.3794021606,
      "tz": "America\/Chicago"
  },
  "5TX9": {
      "icao": "5TX9",
      "iata": "",
      "name": "Utopia on the River Airport",
      "city": "Utopia",
      "state": "Texas",
      "country": "US",
      "elevation": 1320,
      "lat": 29.5835990906,
      "lon": -99.5261993408,
      "tz": "America\/Chicago"
  },
  "5VA5": {
      "icao": "5VA5",
      "iata": "",
      "name": "Chimney View Airport",
      "city": "Fredericksburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 25,
      "lat": 38.4034996033,
      "lon": -77.3158035278,
      "tz": "America\/New_York"
  },
  "5VA9": {
      "icao": "5VA9",
      "iata": "",
      "name": "Kilmarnock\/Tolbert Field",
      "city": "Kilmarnock",
      "state": "Virginia",
      "country": "US",
      "elevation": 7,
      "lat": 37.6926002502,
      "lon": -76.3182983398,
      "tz": "America\/New_York"
  },
  "5WA0": {
      "icao": "5WA0",
      "iata": "",
      "name": "Sourdough Airport",
      "city": "Tonasket",
      "state": "Washington",
      "country": "US",
      "elevation": 2640,
      "lat": 48.5956001282,
      "lon": -119.1009979248,
      "tz": "America\/Los_Angeles"
  },
  "5WA1": {
      "icao": "5WA1",
      "iata": "",
      "name": "Dorman Field",
      "city": "Mesa",
      "state": "Washington",
      "country": "US",
      "elevation": 700,
      "lat": 46.5214996338,
      "lon": -119.1790008545,
      "tz": "America\/Los_Angeles"
  },
  "5WA2": {
      "icao": "5WA2",
      "iata": "",
      "name": "B & M Ranch Airport",
      "city": "Monroe",
      "state": "Washington",
      "country": "US",
      "elevation": 370,
      "lat": 47.8665008545,
      "lon": -121.9010009766,
      "tz": "America\/Los_Angeles"
  },
  "5WA5": {
      "icao": "5WA5",
      "iata": "",
      "name": "Ross Private Strip",
      "city": "Orondo",
      "state": "Washington",
      "country": "US",
      "elevation": 920,
      "lat": 47.7631988525,
      "lon": -120.1520004272,
      "tz": "America\/Los_Angeles"
  },
  "5WA7": {
      "icao": "5WA7",
      "iata": "",
      "name": "Warwick Airport",
      "city": "Centerville",
      "state": "Washington",
      "country": "US",
      "elevation": 1620,
      "lat": 45.7164993286,
      "lon": -120.9850006104,
      "tz": "America\/Los_Angeles"
  },
  "5WA8": {
      "icao": "5WA8",
      "iata": "",
      "name": "Hogan's Corner Airport",
      "city": "Ocean Shores",
      "state": "Washington",
      "country": "US",
      "elevation": 50,
      "lat": 47.0429000854,
      "lon": -124.146003723,
      "tz": "America\/Los_Angeles"
  },
  "5WA9": {
      "icao": "5WA9",
      "iata": "",
      "name": "Brush Prairie Aerodrome",
      "city": "Brush Prairie",
      "state": "Washington",
      "country": "US",
      "elevation": 290,
      "lat": 45.7238998413,
      "lon": -122.5439987183,
      "tz": "America\/Los_Angeles"
  },
  "5WI0": {
      "icao": "5WI0",
      "iata": "",
      "name": "Grandpa's Farm Airport",
      "city": "Augusta",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 970,
      "lat": 44.7027015686,
      "lon": -91.1337966919,
      "tz": "America\/Chicago"
  },
  "5WI1": {
      "icao": "5WI1",
      "iata": "",
      "name": "Springbrook Airport",
      "city": "Springbrook",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1110,
      "lat": 45.9019012451,
      "lon": -91.6771011353,
      "tz": "America\/Chicago"
  },
  "5WI2": {
      "icao": "5WI2",
      "iata": "",
      "name": "Plainfield International Airport",
      "city": "Plainfield",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1110,
      "lat": 44.2224998474,
      "lon": -89.4953994751,
      "tz": "America\/Chicago"
  },
  "5WI3": {
      "icao": "5WI3",
      "iata": "",
      "name": "Der Schwarzwald Airport",
      "city": "Waterloo",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 925,
      "lat": 43.2078018188,
      "lon": -89.0170974731,
      "tz": "America\/Chicago"
  },
  "5WI4": {
      "icao": "5WI4",
      "iata": "",
      "name": "Larson Airport",
      "city": "Marinette",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 615,
      "lat": 45.081401825,
      "lon": -87.6792984009,
      "tz": "America\/Chicago"
  },
  "5WI5": {
      "icao": "5WI5",
      "iata": "",
      "name": "Haymeadow Airport",
      "city": "Merrill",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1500,
      "lat": 45.2750015259,
      "lon": -89.4917984009,
      "tz": "America\/Chicago"
  },
  "5WI6": {
      "icao": "5WI6",
      "iata": "",
      "name": "Independence Airport",
      "city": "Independence",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 804,
      "lat": 44.3669013977,
      "lon": -91.3957977295,
      "tz": "America\/Chicago"
  },
  "5WI8": {
      "icao": "5WI8",
      "iata": "",
      "name": "Ermis-Ridgeview Airport",
      "city": "Coleman",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 715,
      "lat": 45.0760993958,
      "lon": -88.0043029785,
      "tz": "America\/Chicago"
  },
  "5WI9": {
      "icao": "5WI9",
      "iata": "",
      "name": "Patz Airport",
      "city": "Pound",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 860,
      "lat": 45.129699707,
      "lon": -88.1648025513,
      "tz": "America\/Chicago"
  },
  "5WN2": {
      "icao": "5WN2",
      "iata": "",
      "name": "Eberle Ranch Airport",
      "city": "Dane",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1050,
      "lat": 43.2682991028,
      "lon": -89.4843978882,
      "tz": "America\/Chicago"
  },
  "5WN8": {
      "icao": "5WN8",
      "iata": "",
      "name": "Knight Sky Airport",
      "city": "Wausau",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1475,
      "lat": 45.0323982239,
      "lon": -89.401802063,
      "tz": "America\/Chicago"
  },
  "5WN9": {
      "icao": "5WN9",
      "iata": "",
      "name": "Ottman Landing Airport",
      "city": "Maiden Rock",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1150,
      "lat": 44.5943984985,
      "lon": -92.2584991455,
      "tz": "America\/Chicago"
  },
  "5XS0": {
      "icao": "5XS0",
      "iata": "",
      "name": "Tnt Ultralightport",
      "city": "Randolph",
      "state": "Texas",
      "country": "US",
      "elevation": 708,
      "lat": 33.4967002869,
      "lon": -96.2647018433,
      "tz": "America\/Chicago"
  },
  "5XS2": {
      "icao": "5XS2",
      "iata": "",
      "name": "Kimball Farm Service Inc Airport",
      "city": "Hereford",
      "state": "Texas",
      "country": "US",
      "elevation": 3700,
      "lat": 35.0334014893,
      "lon": -102.1999969482,
      "tz": "America\/Chicago"
  },
  "5XS3": {
      "icao": "5XS3",
      "iata": "",
      "name": "Wilber Farms Airport",
      "city": "Winnie",
      "state": "Texas",
      "country": "US",
      "elevation": 11,
      "lat": 29.7696990967,
      "lon": -94.2612991333,
      "tz": "America\/Chicago"
  },
  "5XS4": {
      "icao": "5XS4",
      "iata": "",
      "name": "Gary's Airport",
      "city": "Marion",
      "state": "Texas",
      "country": "US",
      "elevation": 655,
      "lat": 29.5862998962,
      "lon": -98.1196975708,
      "tz": "America\/Chicago"
  },
  "5XS5": {
      "icao": "5XS5",
      "iata": "",
      "name": "Wits End Ranch Airport",
      "city": "Van",
      "state": "Texas",
      "country": "US",
      "elevation": 545,
      "lat": 32.5427777778,
      "lon": -95.7086111111,
      "tz": "America\/Chicago"
  },
  "5XS6": {
      "icao": "5XS6",
      "iata": "",
      "name": "Old Reb Airport",
      "city": "Mercedes",
      "state": "Texas",
      "country": "US",
      "elevation": 60,
      "lat": 26.1753997803,
      "lon": -97.8752975464,
      "tz": "America\/Chicago"
  },
  "5XS7": {
      "icao": "5XS7",
      "iata": "",
      "name": "Outback Airport",
      "city": "Lockney",
      "state": "Texas",
      "country": "US",
      "elevation": 3286,
      "lat": 34.2296981812,
      "lon": -101.4639968872,
      "tz": "America\/Chicago"
  },
  "5XS8": {
      "icao": "5XS8",
      "iata": "",
      "name": "L Davis Ranch Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 1390,
      "lat": 29.5168991089,
      "lon": -100.3170013428,
      "tz": "America\/Chicago"
  },
  "5XS9": {
      "icao": "5XS9",
      "iata": "",
      "name": "Byrt Airport",
      "city": "Streetman",
      "state": "Texas",
      "country": "US",
      "elevation": 414,
      "lat": 31.9218006134,
      "lon": -96.2649993896,
      "tz": "America\/Chicago"
  },
  "60AZ": {
      "icao": "60AZ",
      "iata": "",
      "name": "Wood's Airstrip",
      "city": "Wenden",
      "state": "Arizona",
      "country": "US",
      "elevation": 1962,
      "lat": 33.8381004333,
      "lon": -113.4540023804,
      "tz": "America\/Phoenix"
  },
  "60CL": {
      "icao": "60CL",
      "iata": "",
      "name": "Chp Academy Airport",
      "city": "Bryte",
      "state": "California",
      "country": "US",
      "elevation": 20,
      "lat": 38.5988006592,
      "lon": -121.5609970093,
      "tz": "America\/Los_Angeles"
  },
  "60CO": {
      "icao": "60CO",
      "iata": "",
      "name": "Stevens Airport",
      "city": "Hudson",
      "state": "Colorado",
      "country": "US",
      "elevation": 5180,
      "lat": 39.9667015076,
      "lon": -104.6510009766,
      "tz": "America\/Denver"
  },
  "60IN": {
      "icao": "60IN",
      "iata": "",
      "name": "Fisher Farm Airport",
      "city": "Roanoke",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 40.9458999634,
      "lon": -85.3700027466,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "60IS": {
      "icao": "60IS",
      "iata": "",
      "name": "Nelson Private Airport",
      "city": "Browns",
      "state": "Illinois",
      "country": "US",
      "elevation": 420,
      "lat": 38.4006004333,
      "lon": -87.9886016846,
      "tz": "America\/Chicago"
  },
  "60KS": {
      "icao": "60KS",
      "iata": "",
      "name": "Alley Field",
      "city": "Douglass",
      "state": "Kansas",
      "country": "US",
      "elevation": 1260,
      "lat": 37.5139007568,
      "lon": -97.0002975464,
      "tz": "America\/Chicago"
  },
  "60MI": {
      "icao": "60MI",
      "iata": "",
      "name": "Maybee Airport",
      "city": "Maybee",
      "state": "Michigan",
      "country": "US",
      "elevation": 650,
      "lat": 42.0414009094,
      "lon": -83.5669021606,
      "tz": "America\/Detroit"
  },
  "60MN": {
      "icao": "60MN",
      "iata": "",
      "name": "Fuhr Flying Svc Airport",
      "city": "Seaforth",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1070,
      "lat": 44.4679985046,
      "lon": -95.2835998535,
      "tz": "America\/Chicago"
  },
  "60NC": {
      "icao": "60NC",
      "iata": "",
      "name": "Star Hill Golf Club Airport",
      "city": "Cape Carteret",
      "state": "North Carolina",
      "country": "US",
      "elevation": 40,
      "lat": 34.7052001953,
      "lon": -77.0511016846,
      "tz": "America\/New_York"
  },
  "60NJ": {
      "icao": "60NJ",
      "iata": "",
      "name": "O'Dwyer Airport",
      "city": "Flemington",
      "state": "New Jersey",
      "country": "US",
      "elevation": 200,
      "lat": 40.4668006897,
      "lon": -74.832901001,
      "tz": "America\/New_York"
  },
  "60OI": {
      "icao": "60OI",
      "iata": "",
      "name": "Zorn Acres Airport",
      "city": "Milan",
      "state": "Ohio",
      "country": "US",
      "elevation": 640,
      "lat": 41.3567008972,
      "lon": -82.6249008179,
      "tz": "America\/New_York"
  },
  "60OK": {
      "icao": "60OK",
      "iata": "",
      "name": "Carlin Lawrence Airport",
      "city": "Carnegie",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1340,
      "lat": 35.1222991943,
      "lon": -98.5197982788,
      "tz": "America\/Chicago"
  },
  "60OR": {
      "icao": "60OR",
      "iata": "",
      "name": "Whitaker Airport",
      "city": "Oakland",
      "state": "Oregon",
      "country": "US",
      "elevation": 625,
      "lat": 43.4264984131,
      "lon": -123.2799987793,
      "tz": "America\/Los_Angeles"
  },
  "60TA": {
      "icao": "60TA",
      "iata": "",
      "name": "Air Ranch Estates Airport",
      "city": "Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 32.3250999451,
      "lon": -96.9475021362,
      "tz": "America\/Chicago"
  },
  "60TE": {
      "icao": "60TE",
      "iata": "",
      "name": "Tierra Linda Ranch Airport",
      "city": "Kerrville",
      "state": "Texas",
      "country": "US",
      "elevation": 1990,
      "lat": 30.1334991455,
      "lon": -99.1502990723,
      "tz": "America\/Chicago"
  },
  "60TX": {
      "icao": "60TX",
      "iata": "",
      "name": "P-K Ranch Airport",
      "city": "Bellville",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 30.0335998535,
      "lon": -96.2636032104,
      "tz": "America\/Chicago"
  },
  "61AR": {
      "icao": "61AR",
      "iata": "",
      "name": "The Valley Airport",
      "city": "Cotter",
      "state": "Arkansas",
      "country": "US",
      "elevation": 450,
      "lat": 36.3083992,
      "lon": -92.53070068,
      "tz": "America\/Chicago"
  },
  "61AZ": {
      "icao": "61AZ",
      "iata": "",
      "name": "White Mountain Ultralightport",
      "city": "Show Low",
      "state": "Arizona",
      "country": "US",
      "elevation": 6546,
      "lat": 34.3697013855,
      "lon": -109.6969985962,
      "tz": "America\/Phoenix"
  },
  "61CA": {
      "icao": "61CA",
      "iata": "",
      "name": "Bauer Airport",
      "city": "Twentynine Palms",
      "state": "California",
      "country": "US",
      "elevation": 1950,
      "lat": 34.1747016907,
      "lon": -116.0670013428,
      "tz": "America\/Los_Angeles"
  },
  "61CL": {
      "icao": "61CL",
      "iata": "",
      "name": "Johnson Brothers Airport",
      "city": "Calexico",
      "state": "California",
      "country": "US",
      "elevation": -1,
      "lat": 32.6734008789,
      "lon": -115.5589981079,
      "tz": "America\/Los_Angeles"
  },
  "61GA": {
      "icao": "61GA",
      "iata": "",
      "name": "Pea Patch Aerodrome",
      "city": "Blythe",
      "state": "Georgia",
      "country": "US",
      "elevation": 434,
      "lat": 33.3017997742,
      "lon": -82.1723022461,
      "tz": "America\/New_York"
  },
  "61II": {
      "icao": "61II",
      "iata": "",
      "name": "Kay Field",
      "city": "Wolflake",
      "state": "Indiana",
      "country": "US",
      "elevation": 930,
      "lat": 41.3624992371,
      "lon": -85.4777984619,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "61IN": {
      "icao": "61IN",
      "iata": "",
      "name": "Wilkerson's Airport",
      "city": "Scottsburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 730,
      "lat": 38.7136993408,
      "lon": -85.6035995483,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "61IS": {
      "icao": "61IS",
      "iata": "",
      "name": "Jim & Peg RLA Restricted Landing Area",
      "city": "Dwight",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 41.0547981262,
      "lon": -88.387298584,
      "tz": "America\/Chicago"
  },
  "61KS": {
      "icao": "61KS",
      "iata": "",
      "name": "Masters Field",
      "city": "Troy",
      "state": "Kansas",
      "country": "US",
      "elevation": 1120,
      "lat": 39.7588996887,
      "lon": -95.0958023071,
      "tz": "America\/Chicago"
  },
  "61KY": {
      "icao": "61KY",
      "iata": "",
      "name": "Schroder Airport",
      "city": "Owenton",
      "state": "Kentucky",
      "country": "US",
      "elevation": 910,
      "lat": 38.5500984192,
      "lon": -84.7632980347,
      "tz": "America\/New_York"
  },
  "61LL": {
      "icao": "61LL",
      "iata": "",
      "name": "Smith Restricted Landing Area",
      "city": "Peirron",
      "state": "Illinois",
      "country": "US",
      "elevation": 530,
      "lat": 38.7731018066,
      "lon": -89.5935974121,
      "tz": "America\/Chicago"
  },
  "61MA": {
      "icao": "61MA",
      "iata": "",
      "name": "Shirley Airport",
      "city": "Shirley",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 400,
      "lat": 42.5270004272,
      "lon": -71.6644973755,
      "tz": "America\/New_York"
  },
  "61MN": {
      "icao": "61MN",
      "iata": "",
      "name": "Traverse Air Airport",
      "city": "Wheaton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1002,
      "lat": 45.9536018372,
      "lon": -96.4009017944,
      "tz": "America\/Chicago"
  },
  "61MO": {
      "icao": "61MO",
      "iata": "",
      "name": "Carl Ensor Airport",
      "city": "Grainville",
      "state": "Missouri",
      "country": "US",
      "elevation": 740,
      "lat": 39.5485992432,
      "lon": -92.0988006592,
      "tz": "America\/Chicago"
  },
  "61MU": {
      "icao": "61MU",
      "iata": "",
      "name": "Farris Strip",
      "city": "Faucett",
      "state": "Missouri",
      "country": "US",
      "elevation": 1054,
      "lat": 39.5881996155,
      "lon": -94.7837982178,
      "tz": "America\/Chicago"
  },
  "61NC": {
      "icao": "61NC",
      "iata": "",
      "name": "Southern Comforts Aerodrome",
      "city": "Parkton",
      "state": "North Carolina",
      "country": "US",
      "elevation": 175,
      "lat": 34.8712997437,
      "lon": -78.9655990601,
      "tz": "America\/New_York"
  },
  "61ND": {
      "icao": "61ND",
      "iata": "",
      "name": "Bakke Airport",
      "city": "Larimore",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1125,
      "lat": 47.9474983215,
      "lon": -97.6620025635,
      "tz": "America\/Chicago"
  },
  "61NJ": {
      "icao": "61NJ",
      "iata": "",
      "name": "Thomas Browne Airpark",
      "city": "Glassboro",
      "state": "New Jersey",
      "country": "US",
      "elevation": 145,
      "lat": 39.6915016174,
      "lon": -75.1432037354,
      "tz": "America\/New_York"
  },
  "61NK": {
      "icao": "61NK",
      "iata": "",
      "name": "Sunset Airport",
      "city": "Willsboro",
      "state": "New York",
      "country": "US",
      "elevation": 175,
      "lat": 44.329856,
      "lon": -73.361793,
      "tz": "America\/New_York"
  },
  "61NY": {
      "icao": "61NY",
      "iata": "",
      "name": "Bassett Field",
      "city": "Lockport",
      "state": "New York",
      "country": "US",
      "elevation": 629,
      "lat": 43.1687011719,
      "lon": -78.7823028564,
      "tz": "America\/New_York"
  },
  "61OH": {
      "icao": "61OH",
      "iata": "",
      "name": "Jetway Airport",
      "city": "Ravenna",
      "state": "Ohio",
      "country": "US",
      "elevation": 1130,
      "lat": 41.1981010437,
      "lon": -81.2095031738,
      "tz": "America\/New_York"
  },
  "61OK": {
      "icao": "61OK",
      "iata": "",
      "name": "The Highlands Airport",
      "city": "Cashion",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1160,
      "lat": 35.7999992371,
      "lon": -97.6087036133,
      "tz": "America\/Chicago"
  },
  "61OR": {
      "icao": "61OR",
      "iata": "",
      "name": "The Citadel Airport",
      "city": "Sisters",
      "state": "Oregon",
      "country": "US",
      "elevation": 3077,
      "lat": 44.3348007202,
      "lon": -121.3550033569,
      "tz": "America\/Los_Angeles"
  },
  "61PA": {
      "icao": "61PA",
      "iata": "",
      "name": "Hi Line Lodge Airport",
      "city": "Wellsboro",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2202,
      "lat": 41.6944999695,
      "lon": -77.1650009155,
      "tz": "America\/New_York"
  },
  "61PN": {
      "icao": "61PN",
      "iata": "",
      "name": "A G A Farms Airport",
      "city": "Perkasie",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 450,
      "lat": 40.4257011414,
      "lon": -75.2323989868,
      "tz": "America\/New_York"
  },
  "61TA": {
      "icao": "61TA",
      "iata": "",
      "name": "Eagle Landing Airport",
      "city": "Avinger",
      "state": "Texas",
      "country": "US",
      "elevation": 380,
      "lat": 32.8839988708,
      "lon": -94.6076965332,
      "tz": "America\/Chicago"
  },
  "61TE": {
      "icao": "61TE",
      "iata": "",
      "name": "Kezer Air Ranch Airport",
      "city": "Springtown",
      "state": "Texas",
      "country": "US",
      "elevation": 830,
      "lat": 32.9868011475,
      "lon": -97.6196975708,
      "tz": "America\/Chicago"
  },
  "61TX": {
      "icao": "61TX",
      "iata": "",
      "name": "Traylor Tick Farm Airport",
      "city": "Bellville",
      "state": "Texas",
      "country": "US",
      "elevation": 250,
      "lat": 29.9724006653,
      "lon": -96.3071975708,
      "tz": "America\/Chicago"
  },
  "61VA": {
      "icao": "61VA",
      "iata": "",
      "name": "High View Farm Airport",
      "city": "Berryville",
      "state": "Virginia",
      "country": "US",
      "elevation": 595,
      "lat": 39.2354011536,
      "lon": -78.0119018555,
      "tz": "America\/New_York"
  },
  "61WA": {
      "icao": "61WA",
      "iata": "",
      "name": "Burden Field-(Rabbit Run) Airport",
      "city": "Friday Harbor",
      "state": "Washington",
      "country": "US",
      "elevation": 50,
      "lat": 48.4720001221,
      "lon": -123.0179977417,
      "tz": "America\/Los_Angeles"
  },
  "61WI": {
      "icao": "61WI",
      "iata": "",
      "name": "Dinnerbell Airport",
      "city": "Eden",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1120,
      "lat": 43.7083015442,
      "lon": -88.285697937,
      "tz": "America\/Chicago"
  },
  "61XS": {
      "icao": "61XS",
      "iata": "",
      "name": "Shanks Agricultural Strip",
      "city": "Wharton",
      "state": "Texas",
      "country": "US",
      "elevation": 100,
      "lat": 29.3243999481,
      "lon": -96.0207977295,
      "tz": "America\/Chicago"
  },
  "62AL": {
      "icao": "62AL",
      "iata": "",
      "name": "Skywest Airpark",
      "city": "Mobile",
      "state": "Alabama",
      "country": "US",
      "elevation": 150,
      "lat": 30.6835002899,
      "lon": -88.3720016479,
      "tz": "America\/Chicago"
  },
  "62CL": {
      "icao": "62CL",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Caliente",
      "state": "California",
      "country": "US",
      "elevation": 3485,
      "lat": 35.4071998596,
      "lon": -118.5,
      "tz": "America\/Los_Angeles"
  },
  "62CO": {
      "icao": "62CO",
      "iata": "",
      "name": "The Farm Airport",
      "city": "Keenesburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 4820,
      "lat": 40.0983009338,
      "lon": -104.4250030518,
      "tz": "America\/Denver"
  },
  "62FL": {
      "icao": "62FL",
      "iata": "",
      "name": "David Wine's Airstrip",
      "city": "Lake Wales",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 27.8444004059,
      "lon": -81.4396972656,
      "tz": "America\/New_York"
  },
  "62GA": {
      "icao": "62GA",
      "iata": "",
      "name": "Seven Lakes Airport",
      "city": "Jackson",
      "state": "Georgia",
      "country": "US",
      "elevation": 630,
      "lat": 33.3246002197,
      "lon": -83.9162979126,
      "tz": "America\/New_York"
  },
  "62IA": {
      "icao": "62IA",
      "iata": "",
      "name": "Lerchs Airport",
      "city": "Martelle",
      "state": "Iowa",
      "country": "US",
      "elevation": 941,
      "lat": 42.0666999817,
      "lon": -91.3667984009,
      "tz": "America\/Chicago"
  },
  "62IL": {
      "icao": "62IL",
      "iata": "",
      "name": "Ferris Field",
      "city": "Marengo",
      "state": "Illinois",
      "country": "US",
      "elevation": 841,
      "lat": 42.1861000061,
      "lon": -88.6043014526,
      "tz": "America\/Chicago"
  },
  "62IN": {
      "icao": "62IN",
      "iata": "",
      "name": "Fowler Field \/Private\/ Airport",
      "city": "Greentown",
      "state": "Indiana",
      "country": "US",
      "elevation": 854,
      "lat": 40.4309005737,
      "lon": -85.978302002,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "62IS": {
      "icao": "62IS",
      "iata": "",
      "name": "Wilson Airport",
      "city": "Fithian",
      "state": "Illinois",
      "country": "US",
      "elevation": 670,
      "lat": 40.0875015259,
      "lon": -87.9075012207,
      "tz": "America\/Chicago"
  },
  "62LA": {
      "icao": "62LA",
      "iata": "",
      "name": "Ken Guidry Nr 4 Airport",
      "city": "Maurice",
      "state": "Louisiana",
      "country": "US",
      "elevation": 21,
      "lat": 30.0751991272,
      "lon": -92.2136993408,
      "tz": "America\/Chicago"
  },
  "62MD": {
      "icao": "62MD",
      "iata": "",
      "name": "Sandy Point Airport",
      "city": "Prince Frederick",
      "state": "Maryland",
      "country": "US",
      "elevation": 20,
      "lat": 38.488806,
      "lon": -76.643633,
      "tz": "America\/New_York"
  },
  "62MI": {
      "icao": "62MI",
      "iata": "",
      "name": "Combs Airport",
      "city": "Samaria",
      "state": "Michigan",
      "country": "US",
      "elevation": 640,
      "lat": 41.8306007385,
      "lon": -83.5652999878,
      "tz": "America\/Detroit"
  },
  "62MN": {
      "icao": "62MN",
      "iata": "",
      "name": "Taylors Falls Airport",
      "city": "Taylors Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 920,
      "lat": 45.381401062,
      "lon": -92.6824035645,
      "tz": "America\/Chicago"
  },
  "62MO": {
      "icao": "62MO",
      "iata": "",
      "name": "Washburn Farm Airport",
      "city": "Grain Valley",
      "state": "Missouri",
      "country": "US",
      "elevation": 855,
      "lat": 39.0606002808,
      "lon": -94.2033004761,
      "tz": "America\/Chicago"
  },
  "62NC": {
      "icao": "62NC",
      "iata": "",
      "name": "Hickory Hill Airport",
      "city": "Havelock",
      "state": "North Carolina",
      "country": "US",
      "elevation": 30,
      "lat": 34.9367980957,
      "lon": -76.9447021484,
      "tz": "America\/New_York"
  },
  "62ND": {
      "icao": "62ND",
      "iata": "",
      "name": "Morten Airport",
      "city": "Larimore",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1340,
      "lat": 47.7792015076,
      "lon": -97.7594985962,
      "tz": "America\/Chicago"
  },
  "62NE": {
      "icao": "62NE",
      "iata": "",
      "name": "Beebe Airport",
      "city": "Hayes Center",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2860,
      "lat": 40.4474983215,
      "lon": -100.7929992676,
      "tz": "America\/Chicago"
  },
  "62NJ": {
      "icao": "62NJ",
      "iata": "",
      "name": "Scheller Airport",
      "city": "Schooleys Mountain",
      "state": "New Jersey",
      "country": "US",
      "elevation": 1051,
      "lat": 40.808333,
      "lon": -74.808333,
      "tz": "America\/New_York"
  },
  "62NM": {
      "icao": "62NM",
      "iata": "",
      "name": "Seven Rivers Airport",
      "city": "Carlsbad",
      "state": "New Mexico",
      "country": "US",
      "elevation": 3360,
      "lat": 32.5987014771,
      "lon": -104.4260025024,
      "tz": "America\/Denver"
  },
  "62OH": {
      "icao": "62OH",
      "iata": "",
      "name": "Willard Field",
      "city": "Bremen",
      "state": "Ohio",
      "country": "US",
      "elevation": 820,
      "lat": 39.6843986511,
      "lon": -82.466796875,
      "tz": "America\/New_York"
  },
  "62OK": {
      "icao": "62OK",
      "iata": "",
      "name": "Lewis North Airport",
      "city": "Jay",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 960,
      "lat": 36.3391990662,
      "lon": -94.9204025269,
      "tz": "America\/Chicago"
  },
  "62PA": {
      "icao": "62PA",
      "iata": "",
      "name": "Shreveport North Airport",
      "city": "Wellsville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 40.0362014771,
      "lon": -76.9954986572,
      "tz": "America\/New_York"
  },
  "62TA": {
      "icao": "62TA",
      "iata": "",
      "name": "Hawken Air One Airport",
      "city": "Elgin",
      "state": "Texas",
      "country": "US",
      "elevation": 520,
      "lat": 30.3183002472,
      "lon": -97.3022003174,
      "tz": "America\/Chicago"
  },
  "62TX": {
      "icao": "62TX",
      "iata": "",
      "name": "Barge Ranch Airport",
      "city": "Belton",
      "state": "Texas",
      "country": "US",
      "elevation": 590,
      "lat": 31.0918006897,
      "lon": -97.4602966309,
      "tz": "America\/Chicago"
  },
  "62VA": {
      "icao": "62VA",
      "iata": "",
      "name": "Grasso Salvage Airport",
      "city": "Holland",
      "state": "Virginia",
      "country": "US",
      "elevation": 70,
      "lat": 36.6698989868,
      "lon": -76.7285995483,
      "tz": "America\/New_York"
  },
  "62XS": {
      "icao": "62XS",
      "iata": "",
      "name": "J F Ranch Airport",
      "city": "Clairette",
      "state": "Texas",
      "country": "US",
      "elevation": 1220,
      "lat": 32.0223999023,
      "lon": -98.1336975098,
      "tz": "America\/Chicago"
  },
  "63AK": {
      "icao": "63AK",
      "iata": "",
      "name": "Kucera Residence Airport",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 189,
      "lat": 61.5843009949,
      "lon": -149.93800354,
      "tz": "America\/Anchorage"
  },
  "63AR": {
      "icao": "63AR",
      "iata": "",
      "name": "Mc Neely Airport",
      "city": "Earle",
      "state": "Arkansas",
      "country": "US",
      "elevation": 212,
      "lat": 35.251499176,
      "lon": -90.4598007202,
      "tz": "America\/Chicago"
  },
  "63CA": {
      "icao": "63CA",
      "iata": "",
      "name": "Desert Air Sky Ranch Airport",
      "city": "North Shore",
      "state": "California",
      "country": "US",
      "elevation": 0,
      "lat": 33.481098175,
      "lon": -115.8740005493,
      "tz": "America\/Los_Angeles"
  },
  "63CL": {
      "icao": "63CL",
      "iata": "",
      "name": "G3 Ranch Airport",
      "city": "Capay",
      "state": "California",
      "country": "US",
      "elevation": 360,
      "lat": 38.7165985107,
      "lon": -122.138999939,
      "tz": "America\/Los_Angeles"
  },
  "63CN": {
      "icao": "63CN",
      "iata": "",
      "name": "Meadowlark Field",
      "city": "Livermore",
      "state": "California",
      "country": "US",
      "elevation": 730,
      "lat": 37.6613006592,
      "lon": -121.6940002441,
      "tz": "America\/Los_Angeles"
  },
  "63CO": {
      "icao": "63CO",
      "iata": "",
      "name": "Hendricks Field At West Creek Ranch Airport",
      "city": "Gateway",
      "state": "Colorado",
      "country": "US",
      "elevation": 4820,
      "lat": 38.7055015564,
      "lon": -108.93699646,
      "tz": "America\/Denver"
  },
  "63FD": {
      "icao": "63FD",
      "iata": "",
      "name": "Link Field",
      "city": "Alachua",
      "state": "Florida",
      "country": "US",
      "elevation": 108,
      "lat": 29.6980991364,
      "lon": -82.4914016724,
      "tz": "America\/New_York"
  },
  "63GA": {
      "icao": "63GA",
      "iata": "",
      "name": "Zips Airport",
      "city": "Mc Donough",
      "state": "Georgia",
      "country": "US",
      "elevation": 880,
      "lat": 33.4528999329,
      "lon": -84.095199585,
      "tz": "America\/New_York"
  },
  "63II": {
      "icao": "63II",
      "iata": "",
      "name": "Woods Field",
      "city": "Franklin",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 39.5051002502,
      "lon": -86.0302963257,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "63IL": {
      "icao": "63IL",
      "iata": "",
      "name": "Emerick Airport",
      "city": "Marengo",
      "state": "Illinois",
      "country": "US",
      "elevation": 840,
      "lat": 42.1833992004,
      "lon": -88.5944976807,
      "tz": "America\/Chicago"
  },
  "63IN": {
      "icao": "63IN",
      "iata": "",
      "name": "Ropkey Field",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 39.8995018005,
      "lon": -86.271697998,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "63IS": {
      "icao": "63IS",
      "iata": "",
      "name": "Corn Alley Airport",
      "city": "El Paso",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 40.7692985535,
      "lon": -88.9831008911,
      "tz": "America\/Chicago"
  },
  "63KS": {
      "icao": "63KS",
      "iata": "",
      "name": "5-D Ranch Airport",
      "city": "Clay Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1190,
      "lat": 39.3283004761,
      "lon": -97.0608978271,
      "tz": "America\/Chicago"
  },
  "63LA": {
      "icao": "63LA",
      "iata": "",
      "name": "Leonards Airfield & Indust Park Airport",
      "city": "Delcambre",
      "state": "Louisiana",
      "country": "US",
      "elevation": 23,
      "lat": 29.964099884,
      "lon": -91.9121017456,
      "tz": "America\/Chicago"
  },
  "63MN": {
      "icao": "63MN",
      "iata": "",
      "name": "Weideman International Airport",
      "city": "Two Harbors",
      "state": "Minnesota",
      "country": "US",
      "elevation": 930,
      "lat": 47.0943984985,
      "lon": -91.6001968384,
      "tz": "America\/Chicago"
  },
  "63NC": {
      "icao": "63NC",
      "iata": "",
      "name": "Wood Airport",
      "city": "Wilson",
      "state": "North Carolina",
      "country": "US",
      "elevation": 170,
      "lat": 35.8362998962,
      "lon": -77.9963989258,
      "tz": "America\/New_York"
  },
  "63ND": {
      "icao": "63ND",
      "iata": "",
      "name": "Moellenkamp Airport",
      "city": "Lisbon",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1240,
      "lat": 46.3652000427,
      "lon": -97.7282028198,
      "tz": "America\/Chicago"
  },
  "63NY": {
      "icao": "63NY",
      "iata": "",
      "name": "Shear Airport",
      "city": "Ransomville",
      "state": "New York",
      "country": "US",
      "elevation": 325,
      "lat": 43.2611999512,
      "lon": -78.9653015137,
      "tz": "America\/New_York"
  },
  "63OH": {
      "icao": "63OH",
      "iata": "",
      "name": "White's Airport",
      "city": "Darbyville",
      "state": "Ohio",
      "country": "US",
      "elevation": 815,
      "lat": 39.7028007507,
      "lon": -83.0939025879,
      "tz": "America\/New_York"
  },
  "63OI": {
      "icao": "63OI",
      "iata": "",
      "name": "Bowman Field",
      "city": "Delaware",
      "state": "Ohio",
      "country": "US",
      "elevation": 965,
      "lat": 40.3350982666,
      "lon": -83.1360015869,
      "tz": "America\/New_York"
  },
  "63OR": {
      "icao": "63OR",
      "iata": "",
      "name": "Mountaindale Airport",
      "city": "Mountaindale",
      "state": "Oregon",
      "country": "US",
      "elevation": 180,
      "lat": 45.6165008545,
      "lon": -123.0459976196,
      "tz": "America\/Los_Angeles"
  },
  "63PA": {
      "icao": "63PA",
      "iata": "",
      "name": "Boyer Airport",
      "city": "Wernersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 510,
      "lat": 40.3218002319,
      "lon": -76.1005020142,
      "tz": "America\/New_York"
  },
  "63TA": {
      "icao": "63TA",
      "iata": "",
      "name": "Barnett Airport",
      "city": "Chillicothe",
      "state": "Texas",
      "country": "US",
      "elevation": 1385,
      "lat": 34.2695007324,
      "lon": -99.5142974854,
      "tz": "America\/Chicago"
  },
  "63TE": {
      "icao": "63TE",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Kennard",
      "state": "Texas",
      "country": "US",
      "elevation": 370,
      "lat": 31.3535003662,
      "lon": -95.2058029175,
      "tz": "America\/Chicago"
  },
  "63TX": {
      "icao": "63TX",
      "iata": "",
      "name": "Grosser Airport",
      "city": "Bergheim",
      "state": "Texas",
      "country": "US",
      "elevation": 1354,
      "lat": 29.7751998901,
      "lon": -98.5781021118,
      "tz": "America\/Chicago"
  },
  "63WA": {
      "icao": "63WA",
      "iata": "",
      "name": "Boyle R & D Airport",
      "city": "Colbert",
      "state": "Washington",
      "country": "US",
      "elevation": 2350,
      "lat": 47.8333015442,
      "lon": -117.2740020752,
      "tz": "America\/Los_Angeles"
  },
  "63WI": {
      "icao": "63WI",
      "iata": "",
      "name": "Flying H Airport",
      "city": "Monroe",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1020,
      "lat": 42.6657981873,
      "lon": -89.7376022339,
      "tz": "America\/Chicago"
  },
  "63XS": {
      "icao": "63XS",
      "iata": "",
      "name": "Byram Ranch Airport",
      "city": "Henly",
      "state": "Texas",
      "country": "US",
      "elevation": 1120,
      "lat": 30.2241001129,
      "lon": -98.2885971069,
      "tz": "America\/Chicago"
  },
  "64AK": {
      "icao": "64AK",
      "iata": "",
      "name": "Carpentiers Strip",
      "city": "Point Mackenzie",
      "state": "Alaska",
      "country": "US",
      "elevation": 175,
      "lat": 61.4398994446,
      "lon": -150.0260009766,
      "tz": "America\/Anchorage"
  },
  "64AR": {
      "icao": "64AR",
      "iata": "",
      "name": "Lowrance Airport",
      "city": "Driver",
      "state": "Arkansas",
      "country": "US",
      "elevation": 232,
      "lat": 35.6125984192,
      "lon": -90.0208969116,
      "tz": "America\/Chicago"
  },
  "64CL": {
      "icao": "64CL",
      "iata": "",
      "name": "Goodyear Blimp Base Airport",
      "city": "Carson",
      "state": "California",
      "country": "US",
      "elevation": 21,
      "lat": 33.8563995361,
      "lon": -118.2770004272,
      "tz": "America\/Los_Angeles"
  },
  "64CN": {
      "icao": "64CN",
      "iata": "",
      "name": "Sunrise Dusters Airport",
      "city": "Knights Landing",
      "state": "California",
      "country": "US",
      "elevation": 25,
      "lat": 38.8166007996,
      "lon": -121.7009963989,
      "tz": "America\/Los_Angeles"
  },
  "64CO": {
      "icao": "64CO",
      "iata": "",
      "name": "Cholla Airport",
      "city": "Walsenburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 5950,
      "lat": 37.755556,
      "lon": -104.756389,
      "tz": "America\/Denver"
  },
  "64CT": {
      "icao": "64CT",
      "iata": "",
      "name": "Woodstock Airport",
      "city": "South Woodstock",
      "state": "Connecticut",
      "country": "US",
      "elevation": 465,
      "lat": 41.9215011597,
      "lon": -71.9529037476,
      "tz": "America\/New_York"
  },
  "64FA": {
      "icao": "64FA",
      "iata": "",
      "name": "Naked Lady Ranch Airport",
      "city": "Stuart",
      "state": "Florida",
      "country": "US",
      "elevation": 22,
      "lat": 27.1427993774,
      "lon": -80.3377990723,
      "tz": "America\/New_York"
  },
  "64GA": {
      "icao": "64GA",
      "iata": "",
      "name": "Big 'T' Airport",
      "city": "Senoia",
      "state": "Georgia",
      "country": "US",
      "elevation": 900,
      "lat": 33.2835006714,
      "lon": -84.5388031006,
      "tz": "America\/New_York"
  },
  "64IL": {
      "icao": "64IL",
      "iata": "",
      "name": "Walpole Airport",
      "city": "Marengo",
      "state": "Illinois",
      "country": "US",
      "elevation": 790,
      "lat": 42.2416992188,
      "lon": -88.6668014526,
      "tz": "America\/Chicago"
  },
  "64IN": {
      "icao": "64IN",
      "iata": "",
      "name": "Peacock Farms Airport",
      "city": "Fairmount",
      "state": "Indiana",
      "country": "US",
      "elevation": 875,
      "lat": 40.4347991943,
      "lon": -85.62550354,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "64KY": {
      "icao": "64KY",
      "iata": "",
      "name": "Hemp Ridge Airport",
      "city": "Shelbyville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 860,
      "lat": 38.1530990601,
      "lon": -85.1187973022,
      "tz": "America\/New_York"
  },
  "64MO": {
      "icao": "64MO",
      "iata": "",
      "name": "Booze Island Airport",
      "city": "St Joseph",
      "state": "Missouri",
      "country": "US",
      "elevation": 794,
      "lat": 39.6652984619,
      "lon": -95.0132980347,
      "tz": "America\/Chicago"
  },
  "64NC": {
      "icao": "64NC",
      "iata": "",
      "name": "Fields Airport",
      "city": "Pleasant Garden",
      "state": "North Carolina",
      "country": "US",
      "elevation": 820,
      "lat": 35.9019012451,
      "lon": -79.7724990845,
      "tz": "America\/New_York"
  },
  "64ND": {
      "icao": "64ND",
      "iata": "",
      "name": "Z. P. Field",
      "city": "Mandan",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1840,
      "lat": 46.8507995605,
      "lon": -101.0749969482,
      "tz": "America\/North_Dakota\/New_Salem"
  },
  "64NY": {
      "icao": "64NY",
      "iata": "",
      "name": "Hemlock Run Airport",
      "city": "Watkins Glen",
      "state": "New York",
      "country": "US",
      "elevation": 1300,
      "lat": 42.3672981262,
      "lon": -76.931098938,
      "tz": "America\/New_York"
  },
  "64OG": {
      "icao": "64OG",
      "iata": "",
      "name": "Antone Ranch Airport",
      "city": "Mitchell",
      "state": "Oregon",
      "country": "US",
      "elevation": 3908,
      "lat": 44.493436,
      "lon": -119.843686,
      "tz": "America\/Los_Angeles"
  },
  "64OH": {
      "icao": "64OH",
      "iata": "",
      "name": "O K Dies Airport",
      "city": "Salem",
      "state": "Ohio",
      "country": "US",
      "elevation": 1280,
      "lat": 40.8987007141,
      "lon": -80.9292984009,
      "tz": "America\/New_York"
  },
  "64OR": {
      "icao": "64OR",
      "iata": "",
      "name": "Plum Valley Airport",
      "city": "Amity",
      "state": "Oregon",
      "country": "US",
      "elevation": 230,
      "lat": 45.0336990356,
      "lon": -123.1719970703,
      "tz": "America\/Los_Angeles"
  },
  "64TE": {
      "icao": "64TE",
      "iata": "",
      "name": "Apache Springs Airport",
      "city": "Kerrville",
      "state": "Texas",
      "country": "US",
      "elevation": 2016,
      "lat": 30.1590995789,
      "lon": -99.3373031616,
      "tz": "America\/Chicago"
  },
  "64WI": {
      "icao": "64WI",
      "iata": "",
      "name": "Sky Diving Airport",
      "city": "Omro",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 760,
      "lat": 44.0414009094,
      "lon": -88.7001037598,
      "tz": "America\/Chicago"
  },
  "65AK": {
      "icao": "65AK",
      "iata": "",
      "name": "Mcdonald Ridge Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 285,
      "lat": 61.5605010986,
      "lon": -149.369003296,
      "tz": "America\/Anchorage"
  },
  "65AR": {
      "icao": "65AR",
      "iata": "",
      "name": "Bernard Manor Airport",
      "city": "Earle",
      "state": "Arkansas",
      "country": "US",
      "elevation": 210,
      "lat": 35.2265014648,
      "lon": -90.4654006958,
      "tz": "America\/Chicago"
  },
  "65CL": {
      "icao": "65CL",
      "iata": "",
      "name": "Al Divine Airport",
      "city": "Caruthers",
      "state": "California",
      "country": "US",
      "elevation": 252,
      "lat": 36.5144004822,
      "lon": -119.763999939,
      "tz": "America\/Los_Angeles"
  },
  "65CN": {
      "icao": "65CN",
      "iata": "",
      "name": "Bottimore Ranch Airport",
      "city": "Herald",
      "state": "California",
      "country": "US",
      "elevation": 70,
      "lat": 38.3041000366,
      "lon": -121.2509994507,
      "tz": "America\/Los_Angeles"
  },
  "65CO": {
      "icao": "65CO",
      "iata": "",
      "name": "Wkr Airport",
      "city": "Fort Collins",
      "state": "Colorado",
      "country": "US",
      "elevation": 4840,
      "lat": 40.5208015442,
      "lon": -104.9670028687,
      "tz": "America\/Denver"
  },
  "65IL": {
      "icao": "65IL",
      "iata": "",
      "name": "Far Field",
      "city": "Marengo",
      "state": "Illinois",
      "country": "US",
      "elevation": 830,
      "lat": 42.2009010315,
      "lon": -88.6222991943,
      "tz": "America\/Chicago"
  },
  "65IS": {
      "icao": "65IS",
      "iata": "",
      "name": "Frings Airport",
      "city": "Leeds",
      "state": "Illinois",
      "country": "US",
      "elevation": 675,
      "lat": 41.0238990784,
      "lon": -88.9837036133,
      "tz": "America\/Chicago"
  },
  "65KS": {
      "icao": "65KS",
      "iata": "",
      "name": "Griffith Field",
      "city": "Downs",
      "state": "Kansas",
      "country": "US",
      "elevation": 1500,
      "lat": 39.507833,
      "lon": -98.586167,
      "tz": "America\/Chicago"
  },
  "65LA": {
      "icao": "65LA",
      "iata": "BCS",
      "name": "Southern Seaplane Airport",
      "city": "Belle Chasse",
      "state": "Louisiana",
      "country": "US",
      "elevation": 0,
      "lat": 29.8661003113,
      "lon": -90.0222015381,
      "tz": "America\/Chicago"
  },
  "65MN": {
      "icao": "65MN",
      "iata": "",
      "name": "Ricks Field",
      "city": "Wendell",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 45.964099884,
      "lon": -96.1498031616,
      "tz": "America\/Chicago"
  },
  "65NM": {
      "icao": "65NM",
      "iata": "",
      "name": "Curtis and Curtis Airport",
      "city": "Forrest",
      "state": "New Mexico",
      "country": "US",
      "elevation": 4691,
      "lat": 34.7266998291,
      "lon": -103.6100006104,
      "tz": "America\/Denver"
  },
  "65NY": {
      "icao": "65NY",
      "iata": "",
      "name": "Chautauqua Lake Airpark",
      "city": "Mayville",
      "state": "New York",
      "country": "US",
      "elevation": 1655,
      "lat": 42.2839012146,
      "lon": -79.443901062,
      "tz": "America\/New_York"
  },
  "65OH": {
      "icao": "65OH",
      "iata": "",
      "name": "Wiita Farms Airport",
      "city": "Sharon Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 1220,
      "lat": 41.0717010498,
      "lon": -81.7511978149,
      "tz": "America\/New_York"
  },
  "65PA": {
      "icao": "65PA",
      "iata": "",
      "name": "Cherry Valley Airport",
      "city": "Saylorsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 670,
      "lat": 40.8959007263,
      "lon": -75.2963027954,
      "tz": "America\/New_York"
  },
  "65TA": {
      "icao": "65TA",
      "iata": "",
      "name": "Flying C Ranch Airport",
      "city": "Independence",
      "state": "Texas",
      "country": "US",
      "elevation": 250,
      "lat": 30.3432998657,
      "lon": -96.3071975708,
      "tz": "America\/Chicago"
  },
  "65TE": {
      "icao": "65TE",
      "iata": "",
      "name": "Windwood Farm Airport",
      "city": "Bells",
      "state": "Texas",
      "country": "US",
      "elevation": 778,
      "lat": 33.568167,
      "lon": -96.397167,
      "tz": "America\/Chicago"
  },
  "65TN": {
      "icao": "65TN",
      "iata": "",
      "name": "Roach Farm Airport",
      "city": "Fall Branch",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1750,
      "lat": 36.3342018127,
      "lon": -82.6616973877,
      "tz": "America\/New_York"
  },
  "65TS": {
      "icao": "65TS",
      "iata": "",
      "name": "Becker Airport",
      "city": "Alvord",
      "state": "Texas",
      "country": "US",
      "elevation": 940,
      "lat": 33.4023017883,
      "lon": -97.6708984375,
      "tz": "America\/Chicago"
  },
  "65TX": {
      "icao": "65TX",
      "iata": "",
      "name": "Flying Eagle Ranch Airport",
      "city": "Salt Flat",
      "state": "Texas",
      "country": "US",
      "elevation": 4300,
      "lat": 31.775133,
      "lon": -105.431317,
      "tz": "America\/Denver"
  },
  "65WA": {
      "icao": "65WA",
      "iata": "",
      "name": "Wissler's Airport",
      "city": "Tenino",
      "state": "Washington",
      "country": "US",
      "elevation": 255,
      "lat": 46.8404006958,
      "lon": -122.9160003662,
      "tz": "America\/Los_Angeles"
  },
  "65WI": {
      "icao": "65WI",
      "iata": "",
      "name": "Dulmes Field",
      "city": "Oostburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 720,
      "lat": 43.6192016602,
      "lon": -87.8219985962,
      "tz": "America\/Chicago"
  },
  "65WN": {
      "icao": "65WN",
      "iata": "",
      "name": "Whoopy Hollow Aerodrome",
      "city": "Wiota",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 925,
      "lat": 42.676399231,
      "lon": -89.93800354,
      "tz": "America\/Chicago"
  },
  "65XS": {
      "icao": "65XS",
      "iata": "",
      "name": "Birdnest Airport",
      "city": "Parker",
      "state": "Texas",
      "country": "US",
      "elevation": 815,
      "lat": 32.2232017517,
      "lon": -97.2817001343,
      "tz": "America\/Chicago"
  },
  "66CA": {
      "icao": "66CA",
      "iata": "",
      "name": "Rancho San Simeon Airport",
      "city": "Cambria",
      "state": "California",
      "country": "US",
      "elevation": 320,
      "lat": 35.6077003479,
      "lon": -121.1100006104,
      "tz": "America\/Los_Angeles"
  },
  "66CL": {
      "icao": "66CL",
      "iata": "",
      "name": "Triangle T Ranch Airport",
      "city": "Chowchilla",
      "state": "California",
      "country": "US",
      "elevation": 130,
      "lat": 36.9986000061,
      "lon": -120.4649963379,
      "tz": "America\/Los_Angeles"
  },
  "66FD": {
      "icao": "66FD",
      "iata": "",
      "name": "J.R.'s STOLport",
      "city": "Crystal River",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 29.0035991669,
      "lon": -82.5552978516,
      "tz": "America\/New_York"
  },
  "66GA": {
      "icao": "66GA",
      "iata": "",
      "name": "Sawyer Farm Airport",
      "city": "Blakely",
      "state": "Georgia",
      "country": "US",
      "elevation": 350,
      "lat": 31.474722,
      "lon": -85.002778,
      "tz": "America\/New_York"
  },
  "66II": {
      "icao": "66II",
      "iata": "",
      "name": "Pat Robinson Airport",
      "city": "Lapel",
      "state": "Indiana",
      "country": "US",
      "elevation": 865,
      "lat": 40.0421981812,
      "lon": -85.8653030396,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "66IL": {
      "icao": "66IL",
      "iata": "",
      "name": "Aerogrange Airport",
      "city": "Marengo",
      "state": "Illinois",
      "country": "US",
      "elevation": 820,
      "lat": 42.2691993713,
      "lon": -88.5711975098,
      "tz": "America\/Chicago"
  },
  "66IN": {
      "icao": "66IN",
      "iata": "",
      "name": "Oakes Field",
      "city": "Charlottesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 935,
      "lat": 39.7874984741,
      "lon": -85.6369018555,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "66KS": {
      "icao": "66KS",
      "iata": "",
      "name": "Patterson Farms Airport",
      "city": "Independence",
      "state": "Kansas",
      "country": "US",
      "elevation": 880,
      "lat": 37.163898468,
      "lon": -95.8722000122,
      "tz": "America\/Chicago"
  },
  "66KY": {
      "icao": "66KY",
      "iata": "",
      "name": "Mc Grew Airport",
      "city": "Clarkson",
      "state": "Kentucky",
      "country": "US",
      "elevation": 720,
      "lat": 37.507598877,
      "lon": -86.2054977417,
      "tz": "America\/Chicago"
  },
  "66LA": {
      "icao": "66LA",
      "iata": "",
      "name": "Schexnayder Airport",
      "city": "Erwinville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 15,
      "lat": 30.5104999542,
      "lon": -91.3923034668,
      "tz": "America\/Chicago"
  },
  "66MI": {
      "icao": "66MI",
      "iata": "",
      "name": "East Lake Airport",
      "city": "Kalamazoo",
      "state": "Michigan",
      "country": "US",
      "elevation": 860,
      "lat": 42.2284011841,
      "lon": -85.4843978882,
      "tz": "America\/Detroit"
  },
  "66MN": {
      "icao": "66MN",
      "iata": "",
      "name": "Howard's Airport",
      "city": "Clara City",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 44.9771995544,
      "lon": -95.4227981567,
      "tz": "America\/Chicago"
  },
  "66MO": {
      "icao": "66MO",
      "iata": "",
      "name": "Ahlers Acres Airport",
      "city": "Foristell",
      "state": "Missouri",
      "country": "US",
      "elevation": 660,
      "lat": 38.8320007324,
      "lon": -90.9512023926,
      "tz": "America\/Chicago"
  },
  "66NC": {
      "icao": "66NC",
      "iata": "",
      "name": "Hood Field",
      "city": "Dover",
      "state": "North Carolina",
      "country": "US",
      "elevation": 59,
      "lat": 35.1744003296,
      "lon": -77.4835968018,
      "tz": "America\/New_York"
  },
  "66NY": {
      "icao": "66NY",
      "iata": "",
      "name": "Maple Ridge Airport",
      "city": "Medina",
      "state": "New York",
      "country": "US",
      "elevation": 646,
      "lat": 43.2028007507,
      "lon": -78.3538970947,
      "tz": "America\/New_York"
  },
  "66OI": {
      "icao": "66OI",
      "iata": "",
      "name": "Gorman-Freeman Airport",
      "city": "Wooster",
      "state": "Ohio",
      "country": "US",
      "elevation": 1210,
      "lat": 40.7813987732,
      "lon": -82.0557022095,
      "tz": "America\/New_York"
  },
  "66OK": {
      "icao": "66OK",
      "iata": "",
      "name": "Mc Laughlin Farm Airport",
      "city": "Durant",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 695,
      "lat": 34.0890007019,
      "lon": -96.4216995239,
      "tz": "America\/Chicago"
  },
  "66TE": {
      "icao": "66TE",
      "iata": "",
      "name": "The Landings Airport",
      "city": "Granbury",
      "state": "Texas",
      "country": "US",
      "elevation": 684,
      "lat": 32.366111,
      "lon": -97.644444,
      "tz": "America\/Chicago"
  },
  "66TX": {
      "icao": "66TX",
      "iata": "",
      "name": "Loma de Cometa Airport",
      "city": "Cometa",
      "state": "Texas",
      "country": "US",
      "elevation": 615,
      "lat": 28.6821537,
      "lon": -100.0237885,
      "tz": "America\/Chicago"
  },
  "66WA": {
      "icao": "66WA",
      "iata": "",
      "name": "Trout Lake Airport",
      "city": "Trout Lake",
      "state": "Washington",
      "country": "US",
      "elevation": 1914,
      "lat": 46.0023002625,
      "lon": -121.5230026245,
      "tz": "America\/Los_Angeles"
  },
  "66WI": {
      "icao": "66WI",
      "iata": "",
      "name": "J-3 Cub Field",
      "city": "Watertown",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 860,
      "lat": 43.0774993896,
      "lon": -88.662902832,
      "tz": "America\/Chicago"
  },
  "66XS": {
      "icao": "66XS",
      "iata": "",
      "name": "Baylie Airport",
      "city": "Westminster",
      "state": "Texas",
      "country": "US",
      "elevation": 685,
      "lat": 33.3964996338,
      "lon": -96.4886016846,
      "tz": "America\/Chicago"
  },
  "67AK": {
      "icao": "67AK",
      "iata": "",
      "name": "South Hollywood Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 375,
      "lat": 61.5345993042,
      "lon": -149.6699981689,
      "tz": "America\/Anchorage"
  },
  "67AR": {
      "icao": "67AR",
      "iata": "",
      "name": "Wedington Woods Airport",
      "city": "Fayetteville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1090,
      "lat": 36.0978012085,
      "lon": -94.3075027466,
      "tz": "America\/Chicago"
  },
  "67CA": {
      "icao": "67CA",
      "iata": "",
      "name": "Chapman Farms Airport",
      "city": "Chowchilla",
      "state": "California",
      "country": "US",
      "elevation": 175,
      "lat": 37.0727005005,
      "lon": -120.3779983521,
      "tz": "America\/Los_Angeles"
  },
  "67CL": {
      "icao": "67CL",
      "iata": "",
      "name": "Davis Airport",
      "city": "Colusa",
      "state": "California",
      "country": "US",
      "elevation": 45,
      "lat": 39.2010002136,
      "lon": -122.0490036011,
      "tz": "America\/Los_Angeles"
  },
  "67CO": {
      "icao": "67CO",
      "iata": "",
      "name": "Highline Farm Airstrip",
      "city": "Bracewell",
      "state": "Colorado",
      "country": "US",
      "elevation": 4745,
      "lat": 40.4715003967,
      "lon": -104.81300354,
      "tz": "America\/Denver"
  },
  "67FL": {
      "icao": "67FL",
      "iata": "",
      "name": "Myakka Head Airport",
      "city": "Zolfo Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 27.4556007385,
      "lon": -82.1050033569,
      "tz": "America\/New_York"
  },
  "67GA": {
      "icao": "67GA",
      "iata": "",
      "name": "Apalachee Bluff Airpark",
      "city": "Monroe",
      "state": "Georgia",
      "country": "US",
      "elevation": 735,
      "lat": 33.8592987061,
      "lon": -83.5876998901,
      "tz": "America\/New_York"
  },
  "67II": {
      "icao": "67II",
      "iata": "",
      "name": "Schoettmer Farm Airport",
      "city": "Greensburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 875,
      "lat": 39.3713989258,
      "lon": -85.5922012329,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "67IL": {
      "icao": "67IL",
      "iata": "",
      "name": "Mitchell RLA Restricted Landing Area",
      "city": "Marseilles",
      "state": "Illinois",
      "country": "US",
      "elevation": 705,
      "lat": 41.3666992188,
      "lon": -88.650100708,
      "tz": "America\/Chicago"
  },
  "67IN": {
      "icao": "67IN",
      "iata": "",
      "name": "Smitty's Soaring Airport",
      "city": "Knox",
      "state": "Indiana",
      "country": "US",
      "elevation": 720,
      "lat": 41.2364006042,
      "lon": -86.5942001343,
      "tz": "America\/Indiana\/Knox"
  },
  "67KS": {
      "icao": "67KS",
      "iata": "",
      "name": "Montezuma Coop Airport",
      "city": "Montezuma",
      "state": "Kansas",
      "country": "US",
      "elevation": 2780,
      "lat": 37.5875015259,
      "lon": -100.4349975586,
      "tz": "America\/Chicago"
  },
  "67KY": {
      "icao": "67KY",
      "iata": "",
      "name": "Estes Airport",
      "city": "Florence",
      "state": "Kentucky",
      "country": "US",
      "elevation": 890,
      "lat": 38.9833984375,
      "lon": -84.6660995483,
      "tz": "America\/New_York"
  },
  "67LL": {
      "icao": "67LL",
      "iata": "",
      "name": "County Poor Farm Airport",
      "city": "Oregon",
      "state": "Illinois",
      "country": "US",
      "elevation": 695,
      "lat": 41.9902992249,
      "lon": -89.3314971924,
      "tz": "America\/Chicago"
  },
  "67MN": {
      "icao": "67MN",
      "iata": "",
      "name": "Pagel's Field",
      "city": "Atwater",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1170,
      "lat": 45.2019004822,
      "lon": -94.8011016846,
      "tz": "America\/Chicago"
  },
  "67MO": {
      "icao": "67MO",
      "iata": "",
      "name": "Ski Harbor Airport",
      "city": "Pittsburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 906,
      "lat": 37.8109016418,
      "lon": -93.3699035645,
      "tz": "America\/Chicago"
  },
  "67NC": {
      "icao": "67NC",
      "iata": "",
      "name": "Hightower Areo Plantation Airport",
      "city": "Wadesboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 395,
      "lat": 34.9700012207,
      "lon": -80.0289001465,
      "tz": "America\/New_York"
  },
  "67ND": {
      "icao": "67ND",
      "iata": "",
      "name": "Waldie Farms Airport",
      "city": "Marion",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1456,
      "lat": 46.5433006287,
      "lon": -98.3823013306,
      "tz": "America\/Chicago"
  },
  "67NE": {
      "icao": "67NE",
      "iata": "",
      "name": "L J Bose Airstrip",
      "city": "Orleans",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2000,
      "lat": 40.1370010376,
      "lon": -99.4983978271,
      "tz": "America\/Chicago"
  },
  "67NJ": {
      "icao": "67NJ",
      "iata": "",
      "name": "Mount Pleasant Landing Strip",
      "city": "Hainesburg",
      "state": "New Jersey",
      "country": "US",
      "elevation": 800,
      "lat": 40.9697990417,
      "lon": -75.0668029785,
      "tz": "America\/New_York"
  },
  "67NY": {
      "icao": "67NY",
      "iata": "",
      "name": "Ultralight Port Ultralightport",
      "city": "Little Valley",
      "state": "New York",
      "country": "US",
      "elevation": 1860,
      "lat": 42.2930984497,
      "lon": -78.7900009155,
      "tz": "America\/New_York"
  },
  "67OG": {
      "icao": "67OG",
      "iata": "",
      "name": "George Airport",
      "city": "Sheridan",
      "state": "Oregon",
      "country": "US",
      "elevation": 440,
      "lat": 45.031389,
      "lon": -123.406111,
      "tz": "America\/Los_Angeles"
  },
  "67OH": {
      "icao": "67OH",
      "iata": "",
      "name": "Harper Ridge Airport",
      "city": "Solon",
      "state": "Ohio",
      "country": "US",
      "elevation": 1235,
      "lat": 41.413898468,
      "lon": -81.4525985718,
      "tz": "America\/New_York"
  },
  "67OK": {
      "icao": "67OK",
      "iata": "",
      "name": "Flying J Ranch Airport",
      "city": "Peggs",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 990,
      "lat": 36.0331001282,
      "lon": -95.0780029297,
      "tz": "America\/Chicago"
  },
  "67OR": {
      "icao": "67OR",
      "iata": "",
      "name": "Mc Gee Airport",
      "city": "Donald",
      "state": "Oregon",
      "country": "US",
      "elevation": 175,
      "lat": 45.2336997986,
      "lon": -122.856002808,
      "tz": "America\/Los_Angeles"
  },
  "67PN": {
      "icao": "67PN",
      "iata": "",
      "name": "Masser Field",
      "city": "Sacramento",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 720,
      "lat": 40.6333999634,
      "lon": -76.5969009399,
      "tz": "America\/New_York"
  },
  "67TE": {
      "icao": "67TE",
      "iata": "",
      "name": "Fisher Ranch Airport",
      "city": "Killeen",
      "state": "Texas",
      "country": "US",
      "elevation": 870,
      "lat": 30.9496002197,
      "lon": -97.7966995239,
      "tz": "America\/Chicago"
  },
  "67TX": {
      "icao": "67TX",
      "iata": "",
      "name": "Old Hoppe Place Airport",
      "city": "Agua Dulce",
      "state": "Texas",
      "country": "US",
      "elevation": 96,
      "lat": 27.8003005981,
      "lon": -97.8511962891,
      "tz": "America\/Chicago"
  },
  "67VA": {
      "icao": "67VA",
      "iata": "",
      "name": "Karmy's Airport",
      "city": "Woodstock",
      "state": "Virginia",
      "country": "US",
      "elevation": 890,
      "lat": 38.837600708,
      "lon": -78.5195007324,
      "tz": "America\/New_York"
  },
  "67WA": {
      "icao": "67WA",
      "iata": "",
      "name": "Page Airport",
      "city": "Walla Walla",
      "state": "Washington",
      "country": "US",
      "elevation": 800,
      "lat": 46.0164985657,
      "lon": -118.371002197,
      "tz": "America\/Los_Angeles"
  },
  "67WI": {
      "icao": "67WI",
      "iata": "",
      "name": "Accurate Airport",
      "city": "Necedah",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 890,
      "lat": 43.9710998535,
      "lon": -89.9971008301,
      "tz": "America\/Chicago"
  },
  "68AZ": {
      "icao": "68AZ",
      "iata": "",
      "name": "Music Mountain Air Ranch Airport",
      "city": "Hackberry",
      "state": "Arizona",
      "country": "US",
      "elevation": 3450,
      "lat": 35.4751014709,
      "lon": -113.7689971924,
      "tz": "America\/Phoenix"
  },
  "68CA": {
      "icao": "68CA",
      "iata": "",
      "name": "Lake California Air Park",
      "city": "Cottonwood",
      "state": "California",
      "country": "US",
      "elevation": 615,
      "lat": 40.3610992432,
      "lon": -122.216003418,
      "tz": "America\/Los_Angeles"
  },
  "68CL": {
      "icao": "68CL",
      "iata": "",
      "name": "El Rico Airport",
      "city": "Corcoran",
      "state": "California",
      "country": "US",
      "elevation": 181,
      "lat": 36.0457992554,
      "lon": -119.6470031738,
      "tz": "America\/Los_Angeles"
  },
  "68CN": {
      "icao": "68CN",
      "iata": "",
      "name": "Porter Ranch Airport",
      "city": "Kennedy Meadows",
      "state": "California",
      "country": "US",
      "elevation": 6800,
      "lat": 36.0222015381,
      "lon": -118.0950012207,
      "tz": "America\/Los_Angeles"
  },
  "68CO": {
      "icao": "68CO",
      "iata": "",
      "name": "Singleton Ranch Airport",
      "city": "Byers",
      "state": "Colorado",
      "country": "US",
      "elevation": 4940,
      "lat": 39.8722000122,
      "lon": -104.1529998779,
      "tz": "America\/Denver"
  },
  "68FD": {
      "icao": "68FD",
      "iata": "",
      "name": "Maran Airport",
      "city": "Fountain",
      "state": "Florida",
      "country": "US",
      "elevation": 225,
      "lat": 30.5221996307,
      "lon": -85.376701355,
      "tz": "America\/Chicago"
  },
  "68ID": {
      "icao": "68ID",
      "iata": "",
      "name": "Rapoport Ranch Airport",
      "city": "Sandpoint",
      "state": "Idaho",
      "country": "US",
      "elevation": 2170,
      "lat": 48.25,
      "lon": -116.466667,
      "tz": "America\/Los_Angeles"
  },
  "68IL": {
      "icao": "68IL",
      "iata": "",
      "name": "Prairie Lake Hunt Club Airport",
      "city": "Marseilles",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 41.3950004578,
      "lon": -88.6828994751,
      "tz": "America\/Chicago"
  },
  "68IN": {
      "icao": "68IN",
      "iata": "",
      "name": "Alley Oop Airport",
      "city": "Wheatfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 715,
      "lat": 41.1213989258,
      "lon": -86.9974975586,
      "tz": "America\/Chicago"
  },
  "68IS": {
      "icao": "68IS",
      "iata": "",
      "name": "Casa De Aero Park Airport",
      "city": "Hampshire",
      "state": "Illinois",
      "country": "US",
      "elevation": 860,
      "lat": 42.148601532,
      "lon": -88.5597991943,
      "tz": "America\/Chicago"
  },
  "68KS": {
      "icao": "68KS",
      "iata": "",
      "name": "Cloud 9 Airport",
      "city": "Paola",
      "state": "Kansas",
      "country": "US",
      "elevation": 1050,
      "lat": 38.567199707,
      "lon": -94.7825012207,
      "tz": "America\/Chicago"
  },
  "68KY": {
      "icao": "68KY",
      "iata": "",
      "name": "Lee's Airpark",
      "city": "Somerset",
      "state": "Kentucky",
      "country": "US",
      "elevation": 970,
      "lat": 37.0233001709,
      "lon": -84.7296981812,
      "tz": "America\/New_York"
  },
  "68LA": {
      "icao": "68LA",
      "iata": "",
      "name": "Pilkinton Airstrip",
      "city": "Bossier City",
      "state": "Louisiana",
      "country": "US",
      "elevation": 148,
      "lat": 32.3306007385,
      "lon": -93.5171966553,
      "tz": "America\/Chicago"
  },
  "68MI": {
      "icao": "68MI",
      "iata": "",
      "name": "Saline Airport",
      "city": "Saline",
      "state": "Michigan",
      "country": "US",
      "elevation": 820,
      "lat": 42.1464004517,
      "lon": -83.7919006348,
      "tz": "America\/Detroit"
  },
  "68MN": {
      "icao": "68MN",
      "iata": "",
      "name": "Mathew Private Field",
      "city": "Barnesville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 965,
      "lat": 46.6712989807,
      "lon": -96.4508972168,
      "tz": "America\/Chicago"
  },
  "68NC": {
      "icao": "68NC",
      "iata": "",
      "name": "Winstead '76' Airport",
      "city": "Leasburg",
      "state": "North Carolina",
      "country": "US",
      "elevation": 615,
      "lat": 36.3996009827,
      "lon": -79.1280975342,
      "tz": "America\/New_York"
  },
  "68NE": {
      "icao": "68NE",
      "iata": "",
      "name": "Hall-Feld Airport",
      "city": "Davey",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1355,
      "lat": 40.9914016724,
      "lon": -96.6196975708,
      "tz": "America\/Chicago"
  },
  "68NM": {
      "icao": "68NM",
      "iata": "",
      "name": "Flying H Ranch Airport",
      "city": "Hope",
      "state": "New Mexico",
      "country": "US",
      "elevation": 5162,
      "lat": 33.0287017822,
      "lon": -105.1269989014,
      "tz": "America\/Denver"
  },
  "68NY": {
      "icao": "68NY",
      "iata": "",
      "name": "Merrimac Farms Airport",
      "city": "Mount Morris",
      "state": "New York",
      "country": "US",
      "elevation": 910,
      "lat": 42.7500991821,
      "lon": -77.7838973999,
      "tz": "America\/New_York"
  },
  "68OR": {
      "icao": "68OR",
      "iata": "",
      "name": "Lyda Ranch Airstrip",
      "city": "Dufur",
      "state": "Oregon",
      "country": "US",
      "elevation": 2450,
      "lat": 45.3911018372,
      "lon": -121.2760009766,
      "tz": "America\/Los_Angeles"
  },
  "68PA": {
      "icao": "68PA",
      "iata": "",
      "name": "Don's Place Airpark",
      "city": "Hamburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 510,
      "lat": 40.5181999207,
      "lon": -75.9162979126,
      "tz": "America\/New_York"
  },
  "68TE": {
      "icao": "68TE",
      "iata": "",
      "name": "Norris Raun Ranch Airport",
      "city": "El Campo",
      "state": "Texas",
      "country": "US",
      "elevation": 108,
      "lat": 29.196100235,
      "lon": -96.491897583,
      "tz": "America\/Chicago"
  },
  "68TS": {
      "icao": "68TS",
      "iata": "",
      "name": "Bishop Field",
      "city": "Royce City",
      "state": "Texas",
      "country": "US",
      "elevation": 550,
      "lat": 32.8750991821,
      "lon": -96.2878036499,
      "tz": "America\/Chicago"
  },
  "68TX": {
      "icao": "68TX",
      "iata": "",
      "name": "San Patricio International Airport",
      "city": "San Patricio",
      "state": "Texas",
      "country": "US",
      "elevation": 50,
      "lat": 27.9808006287,
      "lon": -97.7869033813,
      "tz": "America\/Chicago"
  },
  "68WA": {
      "icao": "68WA",
      "iata": "",
      "name": "Mc Mahon Field",
      "city": "Zillah",
      "state": "Washington",
      "country": "US",
      "elevation": 850,
      "lat": 46.393699646,
      "lon": -120.222000122,
      "tz": "America\/Los_Angeles"
  },
  "68XS": {
      "icao": "68XS",
      "iata": "",
      "name": "Margaritaville Airport",
      "city": "Whitesboro",
      "state": "Texas",
      "country": "US",
      "elevation": 750,
      "lat": 33.7212982178,
      "lon": -96.8697967529,
      "tz": "America\/Chicago"
  },
  "69CL": {
      "icao": "69CL",
      "iata": "",
      "name": "Medlock Field",
      "city": "Davis",
      "state": "California",
      "country": "US",
      "elevation": 48,
      "lat": 38.6155014038,
      "lon": -121.7470016479,
      "tz": "America\/Los_Angeles"
  },
  "69FD": {
      "icao": "69FD",
      "iata": "",
      "name": "Unicorn Place Airport",
      "city": "Defuniak Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 260,
      "lat": 30.8446998596,
      "lon": -86.2814025879,
      "tz": "America\/Chicago"
  },
  "69FL": {
      "icao": "69FL",
      "iata": "",
      "name": "Eagle's Landing Airport",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 26.6772994995,
      "lon": -82.1364974976,
      "tz": "America\/New_York"
  },
  "69GE": {
      "icao": "69GE",
      "iata": "",
      "name": "Great Oaks Airport",
      "city": "Tignall",
      "state": "Georgia",
      "country": "US",
      "elevation": 530,
      "lat": 33.9230003357,
      "lon": -82.6529006958,
      "tz": "America\/New_York"
  },
  "69II": {
      "icao": "69II",
      "iata": "",
      "name": "Greuter Field",
      "city": "Butler",
      "state": "Indiana",
      "country": "US",
      "elevation": 840,
      "lat": 41.3694000244,
      "lon": -84.8788986206,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "69IL": {
      "icao": "69IL",
      "iata": "",
      "name": "David Gillespie Airport",
      "city": "Marseilles",
      "state": "Illinois",
      "country": "US",
      "elevation": 730,
      "lat": 41.3652992249,
      "lon": -88.6791992188,
      "tz": "America\/Chicago"
  },
  "69IS": {
      "icao": "69IS",
      "iata": "",
      "name": "Sinele's Sunset Strip",
      "city": "Niota",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 40.579498291,
      "lon": -91.2918014526,
      "tz": "America\/Chicago"
  },
  "69KS": {
      "icao": "69KS",
      "iata": "",
      "name": "Chiles Airpark",
      "city": "Chiles",
      "state": "Kansas",
      "country": "US",
      "elevation": 1050,
      "lat": 38.6697006226,
      "lon": -94.7425003052,
      "tz": "America\/Chicago"
  },
  "69LL": {
      "icao": "69LL",
      "iata": "",
      "name": "Maas Airstrip",
      "city": "New Boston",
      "state": "Illinois",
      "country": "US",
      "elevation": 534,
      "lat": 41.2869987488,
      "lon": -91.0487976074,
      "tz": "America\/Chicago"
  },
  "69MI": {
      "icao": "69MI",
      "iata": "",
      "name": "Perry Aero Park",
      "city": "Perry",
      "state": "Michigan",
      "country": "US",
      "elevation": 855,
      "lat": 42.821389,
      "lon": -84.278889,
      "tz": "America\/Detroit"
  },
  "69MN": {
      "icao": "69MN",
      "iata": "",
      "name": "Yaggie Private Airport",
      "city": "Breckenridge",
      "state": "Minnesota",
      "country": "US",
      "elevation": 982,
      "lat": 46.2776985168,
      "lon": -96.4294967651,
      "tz": "America\/Chicago"
  },
  "69MO": {
      "icao": "69MO",
      "iata": "",
      "name": "Hess-Mckeown Airport",
      "city": "Higbee",
      "state": "Missouri",
      "country": "US",
      "elevation": 885,
      "lat": 39.3083992004,
      "lon": -92.4541015625,
      "tz": "America\/Chicago"
  },
  "69MY": {
      "icao": "69MY",
      "iata": "",
      "name": "Hall Airport",
      "city": "Farmington",
      "state": "Minnesota",
      "country": "US",
      "elevation": 875,
      "lat": 44.6610984802,
      "lon": -93.1001968384,
      "tz": "America\/Chicago"
  },
  "69NC": {
      "icao": "69NC",
      "iata": "",
      "name": "Clute's Hilltop Airport",
      "city": "Forest City",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1078,
      "lat": 35.4339981079,
      "lon": -81.8511962891,
      "tz": "America\/New_York"
  },
  "69ND": {
      "icao": "69ND",
      "iata": "",
      "name": "Tengesdal Airport",
      "city": "Maxbass",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1495,
      "lat": 48.7430992126,
      "lon": -101.0419998169,
      "tz": "America\/Chicago"
  },
  "69OH": {
      "icao": "69OH",
      "iata": "",
      "name": "Dwight Field",
      "city": "Homer",
      "state": "Ohio",
      "country": "US",
      "elevation": 1025,
      "lat": 40.2630996704,
      "lon": -82.5143966675,
      "tz": "America\/New_York"
  },
  "69OI": {
      "icao": "69OI",
      "iata": "",
      "name": "Schulze's Airport",
      "city": "Chatfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 40.9500999451,
      "lon": -82.9248962402,
      "tz": "America\/New_York"
  },
  "69OK": {
      "icao": "69OK",
      "iata": "",
      "name": "Green Country Airpark",
      "city": "Fairland",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 840,
      "lat": 36.7286987305,
      "lon": -94.8505020142,
      "tz": "America\/Chicago"
  },
  "69OR": {
      "icao": "69OR",
      "iata": "",
      "name": "Dick Fisher Airport",
      "city": "Gaston",
      "state": "Oregon",
      "country": "US",
      "elevation": 300,
      "lat": 45.4126014709,
      "lon": -123.1370010376,
      "tz": "America\/Los_Angeles"
  },
  "69PA": {
      "icao": "69PA",
      "iata": "",
      "name": "Hurst STOLport",
      "city": "Newmanstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 540,
      "lat": 40.3428993225,
      "lon": -76.2093963623,
      "tz": "America\/New_York"
  },
  "69TA": {
      "icao": "69TA",
      "iata": "",
      "name": "Dean Airport",
      "city": "Corpus Christi",
      "state": "Texas",
      "country": "US",
      "elevation": 45,
      "lat": 27.6550006866,
      "lon": -97.5252990723,
      "tz": "America\/Chicago"
  },
  "69TE": {
      "icao": "69TE",
      "iata": "",
      "name": "Deer Pasture Airport",
      "city": "Lampasas",
      "state": "Texas",
      "country": "US",
      "elevation": 1422,
      "lat": 30.9263000488,
      "lon": -98.2363967896,
      "tz": "America\/Chicago"
  },
  "69TS": {
      "icao": "69TS",
      "iata": "",
      "name": "White Airport",
      "city": "Killeen",
      "state": "Texas",
      "country": "US",
      "elevation": 890,
      "lat": 30.9988002777,
      "lon": -97.755897522,
      "tz": "America\/Chicago"
  },
  "69TX": {
      "icao": "69TX",
      "iata": "",
      "name": "Green Lake Ranch Airport",
      "city": "Bloomington",
      "state": "Texas",
      "country": "US",
      "elevation": 50,
      "lat": 28.5835990906,
      "lon": -96.8544006348,
      "tz": "America\/Chicago"
  },
  "69VA": {
      "icao": "69VA",
      "iata": "",
      "name": "Meadow Farm Airport",
      "city": "Ashland",
      "state": "Virginia",
      "country": "US",
      "elevation": 75,
      "lat": 37.8571014404,
      "lon": -77.420501709,
      "tz": "America\/New_York"
  },
  "69XA": {
      "icao": "69XA",
      "iata": "",
      "name": "Richey Airfield",
      "city": "Chico",
      "state": "Texas",
      "country": "US",
      "elevation": 950,
      "lat": 33.349956,
      "lon": -97.776206,
      "tz": "America\/Chicago"
  },
  "69XS": {
      "icao": "69XS",
      "iata": "",
      "name": "Brushy Creek Airport",
      "city": "Whitesboro",
      "state": "Texas",
      "country": "US",
      "elevation": 715,
      "lat": 33.7533988953,
      "lon": -96.8302001953,
      "tz": "America\/Chicago"
  },
  "6AK1": {
      "icao": "6AK1",
      "iata": "",
      "name": "Platinum Mine Airport",
      "city": "Platinum",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 58.9117012024,
      "lon": -161.7140045166,
      "tz": "America\/Anchorage"
  },
  "6AK2": {
      "icao": "6AK2",
      "iata": "",
      "name": "Sleepers Strip",
      "city": "Point Mackenzie",
      "state": "Alaska",
      "country": "US",
      "elevation": 125,
      "lat": 61.251499176,
      "lon": -149.9819946289,
      "tz": "America\/Anchorage"
  },
  "6AK3": {
      "icao": "6AK3",
      "iata": "",
      "name": "Butler Aviation Airport",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 185,
      "lat": 60.7294006348,
      "lon": -151.2779998779,
      "tz": "America\/Anchorage"
  },
  "6AK5": {
      "icao": "6AK5",
      "iata": "",
      "name": "Fire Island Airport",
      "city": "Anchorage",
      "state": "Alaska",
      "country": "US",
      "elevation": 55,
      "lat": 61.1683006287,
      "lon": -150.1609954834,
      "tz": "America\/Anchorage"
  },
  "6AK7": {
      "icao": "6AK7",
      "iata": "",
      "name": "Rainbow Heights Estates Airstrip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 270,
      "lat": 61.5746994019,
      "lon": -149.6349945068,
      "tz": "America\/Anchorage"
  },
  "6AK8": {
      "icao": "6AK8",
      "iata": "",
      "name": "Tulakes Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 350,
      "lat": 61.6180992126,
      "lon": -149.6690063477,
      "tz": "America\/Anchorage"
  },
  "6AK9": {
      "icao": "6AK9",
      "iata": "",
      "name": "Cizek North Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.6334991455,
      "lon": -149.6820068359,
      "tz": "America\/Anchorage"
  },
  "6AL3": {
      "icao": "6AL3",
      "iata": "",
      "name": "Sweet Home Airport",
      "city": "Henagar",
      "state": "Alabama",
      "country": "US",
      "elevation": 1340,
      "lat": 34.582047,
      "lon": -85.755572,
      "tz": "America\/Chicago"
  },
  "6AL6": {
      "icao": "6AL6",
      "iata": "",
      "name": "Hawthorn Pines Airport",
      "city": "Chatom",
      "state": "Alabama",
      "country": "US",
      "elevation": 150,
      "lat": 31.3885993958,
      "lon": -88.0925979614,
      "tz": "America\/Chicago"
  },
  "6AR0": {
      "icao": "6AR0",
      "iata": "",
      "name": "Cypress Creek Airpark",
      "city": "El Paso",
      "state": "Arkansas",
      "country": "US",
      "elevation": 270,
      "lat": 35.0542984009,
      "lon": -92.1171035767,
      "tz": "America\/Chicago"
  },
  "6AR1": {
      "icao": "6AR1",
      "iata": "",
      "name": "Elder Airstrip",
      "city": "Cave Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1300,
      "lat": 36.2779006958,
      "lon": -94.1966018677,
      "tz": "America\/Chicago"
  },
  "6AR2": {
      "icao": "6AR2",
      "iata": "",
      "name": "A J's Airport",
      "city": "Casa",
      "state": "Arkansas",
      "country": "US",
      "elevation": 370,
      "lat": 35.020401001,
      "lon": -93.0649032593,
      "tz": "America\/Chicago"
  },
  "6AR5": {
      "icao": "6AR5",
      "iata": "",
      "name": "Mc Donald's Airstrip",
      "city": "Calamine",
      "state": "Arkansas",
      "country": "US",
      "elevation": 375,
      "lat": 36.0346984863,
      "lon": -91.3707962036,
      "tz": "America\/Chicago"
  },
  "6AR6": {
      "icao": "6AR6",
      "iata": "",
      "name": "Two Rivers Airport",
      "city": "Little Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 265,
      "lat": 34.8405990601,
      "lon": -92.4417037964,
      "tz": "America\/Chicago"
  },
  "6AR8": {
      "icao": "6AR8",
      "iata": "",
      "name": "Flying W Airpark II Airport",
      "city": "Charleston",
      "state": "Arkansas",
      "country": "US",
      "elevation": 474,
      "lat": 35.3300018311,
      "lon": -94.0419006348,
      "tz": "America\/Chicago"
  },
  "6AR9": {
      "icao": "6AR9",
      "iata": "",
      "name": "Pine Mountain Airpark",
      "city": "Flippin",
      "state": "Arkansas",
      "country": "US",
      "elevation": 880,
      "lat": 36.4152984619,
      "lon": -92.6943969727,
      "tz": "America\/Chicago"
  },
  "6AZ2": {
      "icao": "6AZ2",
      "iata": "",
      "name": "Wisky Ranch\/Chevlon Airport",
      "city": "Heber",
      "state": "Arizona",
      "country": "US",
      "elevation": 6172,
      "lat": 34.6167984009,
      "lon": -110.6279983521,
      "tz": "America\/Phoenix"
  },
  "6AZ5": {
      "icao": "6AZ5",
      "iata": "",
      "name": "Ash Creek Airport",
      "city": "Pearce",
      "state": "Arizona",
      "country": "US",
      "elevation": 4680,
      "lat": 31.857483,
      "lon": -109.542317,
      "tz": "America\/Phoenix"
  },
  "6AZ8": {
      "icao": "6AZ8",
      "iata": "",
      "name": "Flying Diamond Airport",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 3800,
      "lat": 31.9410991669,
      "lon": -111.1200027466,
      "tz": "America\/Phoenix"
  },
  "6CA1": {
      "icao": "6CA1",
      "iata": "",
      "name": "Triple R Ranch Airport",
      "city": "Macdoel",
      "state": "California",
      "country": "US",
      "elevation": 4380,
      "lat": 41.7426986694,
      "lon": -121.8779983521,
      "tz": "America\/Los_Angeles"
  },
  "6CA5": {
      "icao": "6CA5",
      "iata": "",
      "name": "Valley Vista Airport",
      "city": "Yucca Valley",
      "state": "California",
      "country": "US",
      "elevation": 3370,
      "lat": 34.3372001648,
      "lon": -116.5800018311,
      "tz": "America\/Los_Angeles"
  },
  "6CA6": {
      "icao": "6CA6",
      "iata": "",
      "name": "Eagle Ridge Ranch Airport",
      "city": "Railroad Flat",
      "state": "California",
      "country": "US",
      "elevation": 2870,
      "lat": 38.3277015686,
      "lon": -120.4769973755,
      "tz": "America\/Los_Angeles"
  },
  "6CA7": {
      "icao": "6CA7",
      "iata": "",
      "name": "J&J Crop Dusters Inc Airport",
      "city": "Arvin",
      "state": "California",
      "country": "US",
      "elevation": 388,
      "lat": 35.1800003052,
      "lon": -118.8550033569,
      "tz": "America\/Los_Angeles"
  },
  "6CA8": {
      "icao": "6CA8",
      "iata": "",
      "name": "Depue Airport",
      "city": "Lenwood",
      "state": "California",
      "country": "US",
      "elevation": 2313,
      "lat": 34.8563995361,
      "lon": -117.1370010376,
      "tz": "America\/Los_Angeles"
  },
  "6CL0": {
      "icao": "6CL0",
      "iata": "",
      "name": "Majors Airport",
      "city": "Bakersfield",
      "state": "California",
      "country": "US",
      "elevation": 640,
      "lat": 35.5261001587,
      "lon": -119.1019973755,
      "tz": "America\/Los_Angeles"
  },
  "6CL4": {
      "icao": "6CL4",
      "iata": "",
      "name": "Manzanita Airport",
      "city": "Mariposa",
      "state": "California",
      "country": "US",
      "elevation": 3020,
      "lat": 37.459400177,
      "lon": -119.7419967651,
      "tz": "America\/Los_Angeles"
  },
  "6CL6": {
      "icao": "6CL6",
      "iata": "",
      "name": "Mark Franz Private Strip",
      "city": "O'Neals",
      "state": "California",
      "country": "US",
      "elevation": 2560,
      "lat": 37.1749000549,
      "lon": -119.6090011597,
      "tz": "America\/Los_Angeles"
  },
  "6CL8": {
      "icao": "6CL8",
      "iata": "",
      "name": "Harley Airport",
      "city": "Stockton",
      "state": "California",
      "country": "US",
      "elevation": 20,
      "lat": 37.9508018494,
      "lon": -121.2429962158,
      "tz": "America\/Los_Angeles"
  },
  "6CL9": {
      "icao": "6CL9",
      "iata": "",
      "name": "Peoria Airport",
      "city": "Jamestown",
      "state": "California",
      "country": "US",
      "elevation": 830,
      "lat": 37.9216995239,
      "lon": -120.5149993896,
      "tz": "America\/Los_Angeles"
  },
  "6CO0": {
      "icao": "6CO0",
      "iata": "",
      "name": "Doctors Mesa Airport",
      "city": "Eckert\/Orchard City",
      "state": "Colorado",
      "country": "US",
      "elevation": 5680,
      "lat": 38.8527984619,
      "lon": -108.016998291,
      "tz": "America\/Denver"
  },
  "6CO1": {
      "icao": "6CO1",
      "iata": "",
      "name": "Burnham Field",
      "city": "Wellington",
      "state": "Colorado",
      "country": "US",
      "elevation": 5615,
      "lat": 40.7813987732,
      "lon": -104.9169998169,
      "tz": "America\/Denver"
  },
  "6CO2": {
      "icao": "6CO2",
      "iata": "",
      "name": "Mesawood Airpark",
      "city": "Cedaredge",
      "state": "Colorado",
      "country": "US",
      "elevation": 7200,
      "lat": 38.961101532,
      "lon": -107.9580001831,
      "tz": "America\/Denver"
  },
  "6CO3": {
      "icao": "6CO3",
      "iata": "",
      "name": "Wine Glass International Airport",
      "city": "La Junta",
      "state": "Colorado",
      "country": "US",
      "elevation": 4708,
      "lat": 37.6363983154,
      "lon": -103.6579971313,
      "tz": "America\/Denver"
  },
  "6CO4": {
      "icao": "6CO4",
      "iata": "",
      "name": "Hat-Field STOLport",
      "city": "Fort Collins",
      "state": "Colorado",
      "country": "US",
      "elevation": 4885,
      "lat": 40.5102996826,
      "lon": -105.0009994507,
      "tz": "America\/Denver"
  },
  "6CO6": {
      "icao": "6CO6",
      "iata": "",
      "name": "Skywagon Ranch Airport",
      "city": "Berthoud",
      "state": "Colorado",
      "country": "US",
      "elevation": 5187,
      "lat": 40.311886,
      "lon": -105.145833,
      "tz": "America\/Denver"
  },
  "6FD0": {
      "icao": "6FD0",
      "iata": "",
      "name": "Windy Acres Airport",
      "city": "Chiefland",
      "state": "Florida",
      "country": "US",
      "elevation": 45,
      "lat": 29.5657997131,
      "lon": -82.8686981201,
      "tz": "America\/New_York"
  },
  "6FD2": {
      "icao": "6FD2",
      "iata": "",
      "name": "Big Oaks Ranch Airport",
      "city": "Chuluota",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 28.6669006348,
      "lon": -81.0845031738,
      "tz": "America\/New_York"
  },
  "6FD5": {
      "icao": "6FD5",
      "iata": "",
      "name": "Blanket Bay Airport",
      "city": "Yeehaw Junction",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 27.7786006927,
      "lon": -81.0998001099,
      "tz": "America\/New_York"
  },
  "6FD6": {
      "icao": "6FD6",
      "iata": "",
      "name": "Britt Brown & Porter Ranch Airport",
      "city": "Eustis",
      "state": "Florida",
      "country": "US",
      "elevation": 167,
      "lat": 28.9050006866,
      "lon": -81.4330978394,
      "tz": "America\/New_York"
  },
  "6FD7": {
      "icao": "6FD7",
      "iata": "",
      "name": "Thomson Airfield",
      "city": "Ellenton",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 27.5442008972,
      "lon": -82.5300979614,
      "tz": "America\/New_York"
  },
  "6FL0": {
      "icao": "6FL0",
      "iata": "",
      "name": "Seminole Lake Gliderport",
      "city": "Groveland",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 28.4057998657,
      "lon": -81.8378982544,
      "tz": "America\/New_York"
  },
  "6FL2": {
      "icao": "6FL2",
      "iata": "",
      "name": "Kilpatrick Farm Airport",
      "city": "Sneads",
      "state": "Florida",
      "country": "US",
      "elevation": 160,
      "lat": 30.727399826,
      "lon": -84.9177017212,
      "tz": "America\/Chicago"
  },
  "6FL3": {
      "icao": "6FL3",
      "iata": "",
      "name": "Payson Ranch Airport",
      "city": "Punta Gorda",
      "state": "Florida",
      "country": "US",
      "elevation": 62,
      "lat": 26.9372997284,
      "lon": -81.5670013428,
      "tz": "America\/New_York"
  },
  "6FL4": {
      "icao": "6FL4",
      "iata": "",
      "name": "Sylvanmir Farms Airport",
      "city": "Keaton Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 29.821100235,
      "lon": -83.5731964111,
      "tz": "America\/New_York"
  },
  "6FL8": {
      "icao": "6FL8",
      "iata": "",
      "name": "Ames Field",
      "city": "Trenton",
      "state": "Florida",
      "country": "US",
      "elevation": 64,
      "lat": 29.5874996185,
      "lon": -82.8712005615,
      "tz": "America\/New_York"
  },
  "6FL9": {
      "icao": "6FL9",
      "iata": "",
      "name": "Saw Whet Farms Airport",
      "city": "Bell",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 29.6900005341,
      "lon": -82.8712005615,
      "tz": "America\/New_York"
  },
  "6GA0": {
      "icao": "6GA0",
      "iata": "",
      "name": "Stoney Point Field",
      "city": "Cumming",
      "state": "Georgia",
      "country": "US",
      "elevation": 1150,
      "lat": 34.132598877,
      "lon": -84.1994018555,
      "tz": "America\/New_York"
  },
  "6GA1": {
      "icao": "6GA1",
      "iata": "",
      "name": "Fagundes Field",
      "city": "Haralson",
      "state": "Georgia",
      "country": "US",
      "elevation": 864,
      "lat": 33.1990013123,
      "lon": -84.5838012695,
      "tz": "America\/New_York"
  },
  "6GA4": {
      "icao": "6GA4",
      "iata": "",
      "name": "Spring Valley Farm Airport",
      "city": "Loganville",
      "state": "Georgia",
      "country": "US",
      "elevation": 920,
      "lat": 33.7971000671,
      "lon": -83.8513031006,
      "tz": "America\/New_York"
  },
  "6GA5": {
      "icao": "6GA5",
      "iata": "",
      "name": "Kolibri Airport",
      "city": "Whitesburg",
      "state": "Georgia",
      "country": "US",
      "elevation": 1100,
      "lat": 33.5782012939,
      "lon": -84.9068984985,
      "tz": "America\/New_York"
  },
  "6GA8": {
      "icao": "6GA8",
      "iata": "",
      "name": "Flying W Farms Airport",
      "city": "Carrollton",
      "state": "Georgia",
      "country": "US",
      "elevation": 1010,
      "lat": 33.5078010559,
      "lon": -85.1856002808,
      "tz": "America\/New_York"
  },
  "6GE2": {
      "icao": "6GE2",
      "iata": "",
      "name": "Rainbow Field",
      "city": "Woodland",
      "state": "Georgia",
      "country": "US",
      "elevation": 780,
      "lat": 32.8328018188,
      "lon": -84.5378036499,
      "tz": "America\/New_York"
  },
  "6IA5": {
      "icao": "6IA5",
      "iata": "",
      "name": "Davis Field",
      "city": "Dunkerton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1005,
      "lat": 42.6166992188,
      "lon": -92.2169036865,
      "tz": "America\/Chicago"
  },
  "6IA6": {
      "icao": "6IA6",
      "iata": "",
      "name": "Leise Airport",
      "city": "Albion",
      "state": "Iowa",
      "country": "US",
      "elevation": 1000,
      "lat": 42.1500015259,
      "lon": -92.9751968384,
      "tz": "America\/Chicago"
  },
  "6IA7": {
      "icao": "6IA7",
      "iata": "",
      "name": "R S Auto Airport",
      "city": "Lockridge",
      "state": "Iowa",
      "country": "US",
      "elevation": 770,
      "lat": 40.990398407,
      "lon": -91.7442016602,
      "tz": "America\/Chicago"
  },
  "6ID1": {
      "icao": "6ID1",
      "iata": "",
      "name": "Regan Ranch Airport",
      "city": "Coeur D Alene",
      "state": "Idaho",
      "country": "US",
      "elevation": 2720,
      "lat": 47.6436004639,
      "lon": -116.698997498,
      "tz": "America\/Los_Angeles"
  },
  "6II0": {
      "icao": "6II0",
      "iata": "",
      "name": "Turnpaugh Field",
      "city": "Walton",
      "state": "Indiana",
      "country": "US",
      "elevation": 757,
      "lat": 40.6453018188,
      "lon": -86.2928009033,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6II2": {
      "icao": "6II2",
      "iata": "",
      "name": "Willis Airport Site No. 2 Airport",
      "city": "Greenfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 881,
      "lat": 39.7333984375,
      "lon": -85.7232971191,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6II4": {
      "icao": "6II4",
      "iata": "",
      "name": "Cornell Airport",
      "city": "Boonville",
      "state": "Indiana",
      "country": "US",
      "elevation": 390,
      "lat": 37.9822006226,
      "lon": -87.2988967896,
      "tz": "America\/Chicago"
  },
  "6II6": {
      "icao": "6II6",
      "iata": "",
      "name": "David Beiswanger Ultralightport",
      "city": "South Milford",
      "state": "Indiana",
      "country": "US",
      "elevation": 1010,
      "lat": 41.5606002808,
      "lon": -85.2739028931,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6II7": {
      "icao": "6II7",
      "iata": "",
      "name": "Bergs Airport",
      "city": "Lizton",
      "state": "Indiana",
      "country": "US",
      "elevation": 975,
      "lat": 39.8861999512,
      "lon": -86.5813980103,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6II8": {
      "icao": "6II8",
      "iata": "",
      "name": "Godahavit Airport",
      "city": "Wheatland",
      "state": "Indiana",
      "country": "US",
      "elevation": 495,
      "lat": 38.654499054,
      "lon": -87.2827987671,
      "tz": "America\/Indiana\/Vincennes"
  },
  "6IL0": {
      "icao": "6IL0",
      "iata": "",
      "name": "Ramme Airport",
      "city": "Genoa",
      "state": "Illinois",
      "country": "US",
      "elevation": 850,
      "lat": 42.131401062,
      "lon": -88.699798584,
      "tz": "America\/Chicago"
  },
  "6IL2": {
      "icao": "6IL2",
      "iata": "",
      "name": "Phyllis Field",
      "city": "Huntley",
      "state": "Illinois",
      "country": "US",
      "elevation": 855,
      "lat": 42.2210998535,
      "lon": -88.4179000854,
      "tz": "America\/Chicago"
  },
  "6IL3": {
      "icao": "6IL3",
      "iata": "",
      "name": "Rummel Restricted Landing Area",
      "city": "German Valley",
      "state": "Illinois",
      "country": "US",
      "elevation": 850,
      "lat": 42.1666984558,
      "lon": -89.5000991821,
      "tz": "America\/Chicago"
  },
  "6IL4": {
      "icao": "6IL4",
      "iata": "",
      "name": "Foxfield Aerodrome",
      "city": "Lanark",
      "state": "Illinois",
      "country": "US",
      "elevation": 895,
      "lat": 42.1603012085,
      "lon": -89.8861999512,
      "tz": "America\/Chicago"
  },
  "6IL5": {
      "icao": "6IL5",
      "iata": "",
      "name": "Keil Airport",
      "city": "Dongola",
      "state": "Illinois",
      "country": "US",
      "elevation": 525,
      "lat": 37.3863983154,
      "lon": -89.1604003906,
      "tz": "America\/Chicago"
  },
  "6IL6": {
      "icao": "6IL6",
      "iata": "",
      "name": "Reid Restricted Landing Area",
      "city": "Gilberts",
      "state": "Illinois",
      "country": "US",
      "elevation": 920,
      "lat": 42.1184005737,
      "lon": -88.4078979492,
      "tz": "America\/Chicago"
  },
  "6IN2": {
      "icao": "6IN2",
      "iata": "",
      "name": "Whelen Airport",
      "city": "Waldron",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 39.493598938,
      "lon": -85.6485977173,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6IN3": {
      "icao": "6IN3",
      "iata": "",
      "name": "Wyandotte Airport",
      "city": "Lafayette",
      "state": "Indiana",
      "country": "US",
      "elevation": 700,
      "lat": 40.3492012024,
      "lon": -86.7639007568,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6IN4": {
      "icao": "6IN4",
      "iata": "",
      "name": "Fischer Field",
      "city": "Waldron",
      "state": "Indiana",
      "country": "US",
      "elevation": 835,
      "lat": 39.465801239,
      "lon": -85.698600769,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6IN5": {
      "icao": "6IN5",
      "iata": "",
      "name": "Foghorn Farms Airport",
      "city": "Pendleton",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 40.0327987671,
      "lon": -85.7835998535,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6IN6": {
      "icao": "6IN6",
      "iata": "",
      "name": "Mershon Airport",
      "city": "Monrovia",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 39.5489006042,
      "lon": -86.4766998291,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6IN8": {
      "icao": "6IN8",
      "iata": "",
      "name": "Mcminn Airport",
      "city": "Rushville",
      "state": "Indiana",
      "country": "US",
      "elevation": 1065,
      "lat": 39.5285987854,
      "lon": -85.3167037964,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "6IS0": {
      "icao": "6IS0",
      "iata": "",
      "name": "Day Aero-Place Airport",
      "city": "Urbana",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 40.0950012207,
      "lon": -88.1287002563,
      "tz": "America\/Chicago"
  },
  "6IS8": {
      "icao": "6IS8",
      "iata": "",
      "name": "Trisler Airport",
      "city": "Homer",
      "state": "Illinois",
      "country": "US",
      "elevation": 672,
      "lat": 39.9894981384,
      "lon": -87.9052963257,
      "tz": "America\/Chicago"
  },
  "6KS1": {
      "icao": "6KS1",
      "iata": "",
      "name": "Quinter Air Strip",
      "city": "Quinter",
      "state": "Kansas",
      "country": "US",
      "elevation": 2681,
      "lat": 39.0705986023,
      "lon": -100.25,
      "tz": "America\/Chicago"
  },
  "6KS2": {
      "icao": "6KS2",
      "iata": "",
      "name": "Stevenson Private Airport",
      "city": "Selden",
      "state": "Kansas",
      "country": "US",
      "elevation": 2940,
      "lat": 39.4416999817,
      "lon": -100.6299972534,
      "tz": "America\/Chicago"
  },
  "6KS3": {
      "icao": "6KS3",
      "iata": "",
      "name": "Walker Strip",
      "city": "Sharon Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 3670,
      "lat": 38.8456001282,
      "lon": -101.7480010986,
      "tz": "America\/Denver"
  },
  "6KS4": {
      "icao": "6KS4",
      "iata": "",
      "name": "Bussen Airport",
      "city": "Sharon Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 3475,
      "lat": 38.9053001404,
      "lon": -101.783996582,
      "tz": "America\/Denver"
  },
  "6KS5": {
      "icao": "6KS5",
      "iata": "",
      "name": "Yeamans Fox Nest Airport",
      "city": "Pleasanton",
      "state": "Kansas",
      "country": "US",
      "elevation": 782,
      "lat": 38.2249984741,
      "lon": -94.6644973755,
      "tz": "America\/Chicago"
  },
  "6KS6": {
      "icao": "6KS6",
      "iata": "",
      "name": "Kiowa Airport",
      "city": "Kiowa",
      "state": "Kansas",
      "country": "US",
      "elevation": 1333,
      "lat": 37.0153007507,
      "lon": -98.4959030151,
      "tz": "America\/Chicago"
  },
  "6KS7": {
      "icao": "6KS7",
      "iata": "",
      "name": "Shute Airport",
      "city": "Lebanon",
      "state": "Kansas",
      "country": "US",
      "elevation": 1960,
      "lat": 39.984500885,
      "lon": -98.5102996826,
      "tz": "America\/Chicago"
  },
  "6KS8": {
      "icao": "6KS8",
      "iata": "",
      "name": "Mary's Place Airport",
      "city": "Emporia",
      "state": "Kansas",
      "country": "US",
      "elevation": 1138,
      "lat": 38.3835983276,
      "lon": -96.1461029053,
      "tz": "America\/Chicago"
  },
  "6KY3": {
      "icao": "6KY3",
      "iata": "",
      "name": "Flying C Farms Airport",
      "city": "Shelbyville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 850,
      "lat": 38.328611,
      "lon": -85.214167,
      "tz": "America\/New_York"
  },
  "6KY4": {
      "icao": "6KY4",
      "iata": "",
      "name": "Adair Airport",
      "city": "Fountain Run",
      "state": "Kentucky",
      "country": "US",
      "elevation": 870,
      "lat": 36.753333,
      "lon": -85.953889,
      "tz": "America\/Chicago"
  },
  "6KY6": {
      "icao": "6KY6",
      "iata": "",
      "name": "Jeffries Farm Airport",
      "city": "Louisville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 803,
      "lat": 38.3605995178,
      "lon": -85.3630981445,
      "tz": "America\/New_York"
  },
  "6KY7": {
      "icao": "6KY7",
      "iata": "",
      "name": "Alexander Field",
      "city": "Hickman",
      "state": "Kentucky",
      "country": "US",
      "elevation": 445,
      "lat": 36.523,
      "lon": -89.051693,
      "tz": "America\/Chicago"
  },
  "6LA1": {
      "icao": "6LA1",
      "iata": "",
      "name": "Cottonwood Airport",
      "city": "Lake Providence",
      "state": "Louisiana",
      "country": "US",
      "elevation": 120,
      "lat": 32.9840011597,
      "lon": -91.2059020996,
      "tz": "America\/Chicago"
  },
  "6LA4": {
      "icao": "6LA4",
      "iata": "",
      "name": "Dyer Airport",
      "city": "Oakdale",
      "state": "Louisiana",
      "country": "US",
      "elevation": 118,
      "lat": 30.807100296,
      "lon": -92.591796875,
      "tz": "America\/Chicago"
  },
  "6LA5": {
      "icao": "6LA5",
      "iata": "",
      "name": "Church Point Flyers Airport",
      "city": "Church Point",
      "state": "Louisiana",
      "country": "US",
      "elevation": 45,
      "lat": 30.4547996521,
      "lon": -92.2406005859,
      "tz": "America\/Chicago"
  },
  "6LA6": {
      "icao": "6LA6",
      "iata": "",
      "name": "Williams Flying Service Airport",
      "city": "Winnsboro",
      "state": "Louisiana",
      "country": "US",
      "elevation": 79,
      "lat": 32.2182006836,
      "lon": -91.8682022095,
      "tz": "America\/Chicago"
  },
  "6LL0": {
      "icao": "6LL0",
      "iata": "",
      "name": "Williamson Airport",
      "city": "Neoga",
      "state": "Illinois",
      "country": "US",
      "elevation": 664,
      "lat": 39.318901062,
      "lon": -88.4250030518,
      "tz": "America\/Chicago"
  },
  "6LL1": {
      "icao": "6LL1",
      "iata": "",
      "name": "Cumberland Air Park RLA Restricted Landing Area",
      "city": "Neoga",
      "state": "Illinois",
      "country": "US",
      "elevation": 631,
      "lat": 39.3138999939,
      "lon": -88.3444976807,
      "tz": "America\/Chicago"
  },
  "6LL2": {
      "icao": "6LL2",
      "iata": "",
      "name": "Young Airport",
      "city": "Viola",
      "state": "Illinois",
      "country": "US",
      "elevation": 760,
      "lat": 41.1645011902,
      "lon": -90.5574035645,
      "tz": "America\/Chicago"
  },
  "6LL4": {
      "icao": "6LL4",
      "iata": "",
      "name": "Wildy Field",
      "city": "New Athens",
      "state": "Illinois",
      "country": "US",
      "elevation": 450,
      "lat": 38.2675018311,
      "lon": -89.8917999268,
      "tz": "America\/Chicago"
  },
  "6LL5": {
      "icao": "6LL5",
      "iata": "",
      "name": "Smith Restricted Landing Area",
      "city": "New Berlin",
      "state": "Illinois",
      "country": "US",
      "elevation": 632,
      "lat": 39.7895011902,
      "lon": -89.9912033081,
      "tz": "America\/Chicago"
  },
  "6LL6": {
      "icao": "6LL6",
      "iata": "",
      "name": "Fischer's RLA Restricted Landing Area",
      "city": "New Memphis",
      "state": "Illinois",
      "country": "US",
      "elevation": 440,
      "lat": 38.4916992188,
      "lon": -89.6633987427,
      "tz": "America\/Chicago"
  },
  "6LL8": {
      "icao": "6LL8",
      "iata": "",
      "name": "Songwood Inn Airport",
      "city": "Watseka",
      "state": "Illinois",
      "country": "US",
      "elevation": 629,
      "lat": 40.792301178,
      "lon": -87.7253036499,
      "tz": "America\/Chicago"
  },
  "6MA2": {
      "icao": "6MA2",
      "iata": "",
      "name": "Meadowbrook Airport",
      "city": "Amesbury",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 117,
      "lat": 42.8600997925,
      "lon": -70.99659729,
      "tz": "America\/New_York"
  },
  "6MD1": {
      "icao": "6MD1",
      "iata": "",
      "name": "Dileo Field",
      "city": "Denton",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 38.8363990784,
      "lon": -75.9064025879,
      "tz": "America\/New_York"
  },
  "6MD2": {
      "icao": "6MD2",
      "iata": "",
      "name": "Spring Landing Airport",
      "city": "Crumpton",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 39.2458000183,
      "lon": -75.8843994141,
      "tz": "America\/New_York"
  },
  "6MD3": {
      "icao": "6MD3",
      "iata": "",
      "name": "Harp Airport",
      "city": "Wolfsville",
      "state": "Maryland",
      "country": "US",
      "elevation": 1360,
      "lat": 39.5969009399,
      "lon": -77.5466995239,
      "tz": "America\/New_York"
  },
  "6MD4": {
      "icao": "6MD4",
      "iata": "",
      "name": "Pembroke Farm Airport",
      "city": "Conowingo",
      "state": "Maryland",
      "country": "US",
      "elevation": 380,
      "lat": 39.7193984985,
      "lon": -76.2083969116,
      "tz": "America\/New_York"
  },
  "6MD7": {
      "icao": "6MD7",
      "iata": "",
      "name": "D'Angelo Airport",
      "city": "Golts",
      "state": "Maryland",
      "country": "US",
      "elevation": 82,
      "lat": 39.3391990662,
      "lon": -75.7667007446,
      "tz": "America\/New_York"
  },
  "6MD8": {
      "icao": "6MD8",
      "iata": "",
      "name": "White's Airstrip",
      "city": "Pittsville",
      "state": "Maryland",
      "country": "US",
      "elevation": 55,
      "lat": 38.4422988892,
      "lon": -75.4032974243,
      "tz": "America\/New_York"
  },
  "6MI0": {
      "icao": "6MI0",
      "iata": "",
      "name": "King Trout Airport",
      "city": "Grayling",
      "state": "Michigan",
      "country": "US",
      "elevation": 1100,
      "lat": 44.6147003174,
      "lon": -84.952003479,
      "tz": "America\/Detroit"
  },
  "6MI1": {
      "icao": "6MI1",
      "iata": "",
      "name": "Jensen Field",
      "city": "Millington",
      "state": "Michigan",
      "country": "US",
      "elevation": 755,
      "lat": 43.2630996704,
      "lon": -83.5637969971,
      "tz": "America\/Detroit"
  },
  "6MI2": {
      "icao": "6MI2",
      "iata": "",
      "name": "Deyoung Airport",
      "city": "Allendale",
      "state": "Michigan",
      "country": "US",
      "elevation": 656,
      "lat": 42.9678001404,
      "lon": -85.9617004395,
      "tz": "America\/Detroit"
  },
  "6MI3": {
      "icao": "6MI3",
      "iata": "",
      "name": "Curt's Place Airport",
      "city": "Zeeland",
      "state": "Michigan",
      "country": "US",
      "elevation": 630,
      "lat": 42.7630996704,
      "lon": -85.9828033447,
      "tz": "America\/Detroit"
  },
  "6MI6": {
      "icao": "6MI6",
      "iata": "",
      "name": "Hancock Airport",
      "city": "Buchanan",
      "state": "Michigan",
      "country": "US",
      "elevation": 760,
      "lat": 41.8527984619,
      "lon": -86.4360961914,
      "tz": "America\/Detroit"
  },
  "6MI7": {
      "icao": "6MI7",
      "iata": "",
      "name": "J P's Field",
      "city": "Zeeland",
      "state": "Michigan",
      "country": "US",
      "elevation": 708,
      "lat": 42.806098938,
      "lon": -85.9341964722,
      "tz": "America\/Detroit"
  },
  "6MN0": {
      "icao": "6MN0",
      "iata": "",
      "name": "Rooney Airfield",
      "city": "Ponsford",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1620,
      "lat": 47.0555992126,
      "lon": -95.4231033325,
      "tz": "America\/Chicago"
  },
  "6MN2": {
      "icao": "6MN2",
      "iata": "",
      "name": "Brown's Private Airport",
      "city": "Hancock",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1175,
      "lat": 45.545501709,
      "lon": -95.7795028687,
      "tz": "America\/Chicago"
  },
  "6MN7": {
      "icao": "6MN7",
      "iata": "",
      "name": "Mankato Farmstrip Airport",
      "city": "Mankato",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1002,
      "lat": 44.0999984741,
      "lon": -93.9169006348,
      "tz": "America\/Chicago"
  },
  "6MN8": {
      "icao": "6MN8",
      "iata": "",
      "name": "Underland Airstrip",
      "city": "Medford",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1145,
      "lat": 44.1526985168,
      "lon": -93.2723999023,
      "tz": "America\/Chicago"
  },
  "6MN9": {
      "icao": "6MN9",
      "iata": "",
      "name": "Benson Airport",
      "city": "White Bear Township",
      "state": "Minnesota",
      "country": "US",
      "elevation": 942,
      "lat": 45.1166000366,
      "lon": -92.9960021973,
      "tz": "America\/Chicago"
  },
  "6MO0": {
      "icao": "6MO0",
      "iata": "",
      "name": "Blackhawk Airport",
      "city": "Old Monroe",
      "state": "Missouri",
      "country": "US",
      "elevation": 584,
      "lat": 38.9556007385,
      "lon": -90.8147964478,
      "tz": "America\/Chicago"
  },
  "6MO1": {
      "icao": "6MO1",
      "iata": "",
      "name": "Markt Air Strip",
      "city": "Oregon",
      "state": "Missouri",
      "country": "US",
      "elevation": 1140,
      "lat": 39.9667015076,
      "lon": -95.0669021606,
      "tz": "America\/Chicago"
  },
  "6MO2": {
      "icao": "6MO2",
      "iata": "",
      "name": "Ste Genevieve Flying Club Airport",
      "city": "Ste Genevieve",
      "state": "Missouri",
      "country": "US",
      "elevation": 380,
      "lat": 37.9861984253,
      "lon": -90.0334014893,
      "tz": "America\/Chicago"
  },
  "6MO3": {
      "icao": "6MO3",
      "iata": "",
      "name": "Flying 'E' Airport",
      "city": "Houston",
      "state": "Missouri",
      "country": "US",
      "elevation": 1510,
      "lat": 37.3492012024,
      "lon": -92.1283035278,
      "tz": "America\/Chicago"
  },
  "6MO4": {
      "icao": "6MO4",
      "iata": "",
      "name": "Wixted Airport",
      "city": "Osceola",
      "state": "Missouri",
      "country": "US",
      "elevation": 850,
      "lat": 38.0416984558,
      "lon": -93.7835006714,
      "tz": "America\/Chicago"
  },
  "6MO6": {
      "icao": "6MO6",
      "iata": "",
      "name": "Winter Field",
      "city": "Owensville",
      "state": "Missouri",
      "country": "US",
      "elevation": 960,
      "lat": 38.3334007263,
      "lon": -91.5167999268,
      "tz": "America\/Chicago"
  },
  "6MO7": {
      "icao": "6MO7",
      "iata": "",
      "name": "Riverside Landings Airport",
      "city": "Ozark",
      "state": "Missouri",
      "country": "US",
      "elevation": 1280,
      "lat": 37.0292015076,
      "lon": -93.1668014526,
      "tz": "America\/Chicago"
  },
  "6MO8": {
      "icao": "6MO8",
      "iata": "",
      "name": "Brazeale Farm Airport",
      "city": "Paris",
      "state": "Missouri",
      "country": "US",
      "elevation": 780,
      "lat": 39.4833984375,
      "lon": -92.0668029785,
      "tz": "America\/Chicago"
  },
  "6MO9": {
      "icao": "6MO9",
      "iata": "",
      "name": "West Aero Ranch Airport",
      "city": "Peculiar",
      "state": "Missouri",
      "country": "US",
      "elevation": 980,
      "lat": 38.7005996704,
      "lon": -94.4738006592,
      "tz": "America\/Chicago"
  },
  "6MS1": {
      "icao": "6MS1",
      "iata": "",
      "name": "Woodbridge Airport",
      "city": "Brandon",
      "state": "Mississippi",
      "country": "US",
      "elevation": 325,
      "lat": 32.4557991028,
      "lon": -89.9697036743,
      "tz": "America\/Chicago"
  },
  "6MS2": {
      "icao": "6MS2",
      "iata": "",
      "name": "Wells Farm Airport",
      "city": "Crystal Springs",
      "state": "Mississippi",
      "country": "US",
      "elevation": 455,
      "lat": 31.983861,
      "lon": -90.335361,
      "tz": "America\/Chicago"
  },
  "6MU4": {
      "icao": "6MU4",
      "iata": "",
      "name": "Flying Shamrock Airport",
      "city": "Mexico",
      "state": "Missouri",
      "country": "US",
      "elevation": 810,
      "lat": 39.0377998352,
      "lon": -91.725402832,
      "tz": "America\/Chicago"
  },
  "6MU9": {
      "icao": "6MU9",
      "iata": "",
      "name": "Craddock Field",
      "city": "Licking",
      "state": "Missouri",
      "country": "US",
      "elevation": 1199,
      "lat": 37.5348014832,
      "lon": -91.9652023315,
      "tz": "America\/Chicago"
  },
  "6NA0": {
      "icao": "6NA0",
      "iata": "",
      "name": "Strom Private Airport",
      "city": "Columbus",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1930,
      "lat": 48.9930992126,
      "lon": -102.7379989624,
      "tz": "America\/Chicago"
  },
  "6NA2": {
      "icao": "6NA2",
      "iata": "",
      "name": "South Hector Airstrip",
      "city": "Fargo",
      "state": "North Dakota",
      "country": "US",
      "elevation": 910,
      "lat": 46.7924995422,
      "lon": -96.8037033081,
      "tz": "America\/Chicago"
  },
  "6NA5": {
      "icao": "6NA5",
      "iata": "",
      "name": "Chase Airstrip",
      "city": "Hebron",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2140,
      "lat": 46.9127998352,
      "lon": -102.0090026855,
      "tz": "America\/North_Dakota\/New_Salem"
  },
  "6NA6": {
      "icao": "6NA6",
      "iata": "",
      "name": "Marsh Brothers Airstrip",
      "city": "Newburg",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1481,
      "lat": 48.6192016602,
      "lon": -100.9779968262,
      "tz": "America\/Chicago"
  },
  "6NA7": {
      "icao": "6NA7",
      "iata": "",
      "name": "Kersten Brothers Airport",
      "city": "Newburg",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1473,
      "lat": 48.7303009033,
      "lon": -100.9219970703,
      "tz": "America\/Chicago"
  },
  "6NC0": {
      "icao": "6NC0",
      "iata": "",
      "name": "Cox-Grantham Airfield",
      "city": "Grantham",
      "state": "North Carolina",
      "country": "US",
      "elevation": 157,
      "lat": 35.289100647,
      "lon": -78.1800003052,
      "tz": "America\/New_York"
  },
  "6NC1": {
      "icao": "6NC1",
      "iata": "",
      "name": "Little Mountain Airport",
      "city": "Denver",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1030,
      "lat": 35.5917015076,
      "lon": -81.0793991089,
      "tz": "America\/New_York"
  },
  "6NC2": {
      "icao": "6NC2",
      "iata": "",
      "name": "Wilhelm Airport",
      "city": "Kannapolis",
      "state": "North Carolina",
      "country": "US",
      "elevation": 750,
      "lat": 35.5111999512,
      "lon": -80.5580978394,
      "tz": "America\/New_York"
  },
  "6NC3": {
      "icao": "6NC3",
      "iata": "",
      "name": "Eastover Air Ranch Airport",
      "city": "Fayetteville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 160,
      "lat": 35.0863990784,
      "lon": -78.7722015381,
      "tz": "America\/New_York"
  },
  "6NC4": {
      "icao": "6NC4",
      "iata": "",
      "name": "Brown STOLport",
      "city": "Taylorsville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1170,
      "lat": 35.9057006836,
      "lon": -81.139503479,
      "tz": "America\/New_York"
  },
  "6NC5": {
      "icao": "6NC5",
      "iata": "",
      "name": "Thompson Farms Airport",
      "city": "Whitakers",
      "state": "North Carolina",
      "country": "US",
      "elevation": 100,
      "lat": 36.0178985596,
      "lon": -77.6619033813,
      "tz": "America\/New_York"
  },
  "6NC7": {
      "icao": "6NC7",
      "iata": "",
      "name": "Circle P Farm Airport",
      "city": "Newport",
      "state": "North Carolina",
      "country": "US",
      "elevation": 30,
      "lat": 34.7513999939,
      "lon": -76.9060974121,
      "tz": "America\/New_York"
  },
  "6NC8": {
      "icao": "6NC8",
      "iata": "",
      "name": "Marchmont Plantation Airpark",
      "city": "Advance",
      "state": "North Carolina",
      "country": "US",
      "elevation": 730,
      "lat": 35.9382019043,
      "lon": -80.3844985962,
      "tz": "America\/New_York"
  },
  "6NC9": {
      "icao": "6NC9",
      "iata": "",
      "name": "Moss Hill Airport",
      "city": "Kinston",
      "state": "North Carolina",
      "country": "US",
      "elevation": 30,
      "lat": 35.2020988464,
      "lon": -77.75050354,
      "tz": "America\/New_York"
  },
  "6ND2": {
      "icao": "6ND2",
      "iata": "",
      "name": "Larson Airport",
      "city": "Fullerton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1442,
      "lat": 46.2588996887,
      "lon": -98.3879013062,
      "tz": "America\/Chicago"
  },
  "6ND3": {
      "icao": "6ND3",
      "iata": "",
      "name": "Lisburg Airport",
      "city": "Argusville",
      "state": "North Dakota",
      "country": "US",
      "elevation": 885,
      "lat": 47.0696983337,
      "lon": -96.9405975342,
      "tz": "America\/Chicago"
  },
  "6ND6": {
      "icao": "6ND6",
      "iata": "",
      "name": "Fugleberg Farm Airport",
      "city": "Portland",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1090,
      "lat": 47.5167007446,
      "lon": -97.5503997803,
      "tz": "America\/Chicago"
  },
  "6ND9": {
      "icao": "6ND9",
      "iata": "",
      "name": "Skinningsrud Airport",
      "city": "Berthold",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2010,
      "lat": 48.3927993774,
      "lon": -101.763999939,
      "tz": "America\/Chicago"
  },
  "6NE0": {
      "icao": "6NE0",
      "iata": "",
      "name": "Van Boening Airport",
      "city": "Wallace",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3102,
      "lat": 40.7868995667,
      "lon": -101.1660003662,
      "tz": "America\/Chicago"
  },
  "6NE7": {
      "icao": "6NE7",
      "iata": "",
      "name": "Lee Field",
      "city": "Bartley",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2450,
      "lat": 40.2444000244,
      "lon": -100.3379974365,
      "tz": "America\/Chicago"
  },
  "6NE8": {
      "icao": "6NE8",
      "iata": "",
      "name": "Hoppy's Airport",
      "city": "Benkelman",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3227,
      "lat": 40.0868988037,
      "lon": -101.5690002441,
      "tz": "America\/Denver"
  },
  "6NE9": {
      "icao": "6NE9",
      "iata": "",
      "name": "Hoesel Airport",
      "city": "Callaway",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2600,
      "lat": 41.2681007385,
      "lon": -99.8879013062,
      "tz": "America\/Chicago"
  },
  "6NJ0": {
      "icao": "6NJ0",
      "iata": "",
      "name": "Lentine South Airport",
      "city": "Warren Grove",
      "state": "New Jersey",
      "country": "US",
      "elevation": 150,
      "lat": 39.7871017456,
      "lon": -74.3774032593,
      "tz": "America\/New_York"
  },
  "6NJ1": {
      "icao": "6NJ1",
      "iata": "",
      "name": "Vliet Airport",
      "city": "Washington",
      "state": "New Jersey",
      "country": "US",
      "elevation": 460,
      "lat": 40.740398407,
      "lon": -74.9568023682,
      "tz": "America\/New_York"
  },
  "6NJ8": {
      "icao": "6NJ8",
      "iata": "",
      "name": "Lance Airport",
      "city": "Whitehouse Station",
      "state": "New Jersey",
      "country": "US",
      "elevation": 275,
      "lat": 40.5918006897,
      "lon": -74.7912979126,
      "tz": "America\/New_York"
  },
  "6NJ9": {
      "icao": "6NJ9",
      "iata": "",
      "name": "Bridgeport-Cahill Field",
      "city": "Bridgeport",
      "state": "New Jersey",
      "country": "US",
      "elevation": 15,
      "lat": 39.7918014526,
      "lon": -75.3746032715,
      "tz": "America\/New_York"
  },
  "6NK0": {
      "icao": "6NK0",
      "iata": "",
      "name": "Knapp Airport",
      "city": "Schenevus",
      "state": "New York",
      "country": "US",
      "elevation": 1300,
      "lat": 42.6291999817,
      "lon": -74.8208007813,
      "tz": "America\/New_York"
  },
  "6NK1": {
      "icao": "6NK1",
      "iata": "",
      "name": "Casey's Airport",
      "city": "Warsaw",
      "state": "New York",
      "country": "US",
      "elevation": 1600,
      "lat": 42.756401062,
      "lon": -78.1755981445,
      "tz": "America\/New_York"
  },
  "6NK2": {
      "icao": "6NK2",
      "iata": "",
      "name": "Meerwarth Airport",
      "city": "White Creek",
      "state": "New York",
      "country": "US",
      "elevation": 640,
      "lat": 42.9505996704,
      "lon": -73.3347015381,
      "tz": "America\/New_York"
  },
  "6NK4": {
      "icao": "6NK4",
      "iata": "",
      "name": "Ttt Air Airport",
      "city": "North Collins",
      "state": "New York",
      "country": "US",
      "elevation": 891,
      "lat": 42.5978012085,
      "lon": -78.9689025879,
      "tz": "America\/New_York"
  },
  "6NK8": {
      "icao": "6NK8",
      "iata": "",
      "name": "Flying K Airport",
      "city": "Oneida",
      "state": "New York",
      "country": "US",
      "elevation": 1350,
      "lat": 43.0097999573,
      "lon": -76.6405029297,
      "tz": "America\/New_York"
  },
  "6NY1": {
      "icao": "6NY1",
      "iata": "",
      "name": "Old Port Royal Airport",
      "city": "Corning",
      "state": "New York",
      "country": "US",
      "elevation": 1700,
      "lat": 42.1128997803,
      "lon": -77.0894012451,
      "tz": "America\/New_York"
  },
  "6NY2": {
      "icao": "6NY2",
      "iata": "",
      "name": "Omni Airpark",
      "city": "Horseheads",
      "state": "New York",
      "country": "US",
      "elevation": 970,
      "lat": 42.2319984436,
      "lon": -76.8469009399,
      "tz": "America\/New_York"
  },
  "6NY3": {
      "icao": "6NY3",
      "iata": "",
      "name": "Airy-Acres Airport",
      "city": "Interlaken",
      "state": "New York",
      "country": "US",
      "elevation": 805,
      "lat": 42.6440010071,
      "lon": -76.7296981812,
      "tz": "America\/New_York"
  },
  "6NY4": {
      "icao": "6NY4",
      "iata": "",
      "name": "West Township Airport",
      "city": "Knox",
      "state": "New York",
      "country": "US",
      "elevation": 1260,
      "lat": 42.7004013062,
      "lon": -74.1550979614,
      "tz": "America\/New_York"
  },
  "6NY7": {
      "icao": "6NY7",
      "iata": "",
      "name": "Piolis Brookside Airport",
      "city": "Kerhonkson",
      "state": "New York",
      "country": "US",
      "elevation": 1060,
      "lat": 41.865398407,
      "lon": -74.332901001,
      "tz": "America\/New_York"
  },
  "6OG3": {
      "icao": "6OG3",
      "iata": "",
      "name": "Sky Wagon Ranch Airport",
      "city": "Klamath Falls",
      "state": "Oregon",
      "country": "US",
      "elevation": 4150,
      "lat": 42.130283,
      "lon": -121.531633,
      "tz": "America\/Los_Angeles"
  },
  "6OH1": {
      "icao": "6OH1",
      "iata": "",
      "name": "Missler-Bellevue Airport",
      "city": "Bellevue",
      "state": "Ohio",
      "country": "US",
      "elevation": 760,
      "lat": 41.2862014771,
      "lon": -82.8666000366,
      "tz": "America\/New_York"
  },
  "6OH3": {
      "icao": "6OH3",
      "iata": "",
      "name": "R C Ford Field",
      "city": "Berkey",
      "state": "Ohio",
      "country": "US",
      "elevation": 700,
      "lat": 41.7051010132,
      "lon": -83.8287963867,
      "tz": "America\/Detroit"
  },
  "6OH4": {
      "icao": "6OH4",
      "iata": "",
      "name": "Aring Field",
      "city": "Bowling Green",
      "state": "Ohio",
      "country": "US",
      "elevation": 650,
      "lat": 41.3583984375,
      "lon": -83.5582962036,
      "tz": "America\/New_York"
  },
  "6OH6": {
      "icao": "6OH6",
      "iata": "",
      "name": "Shelton Airport",
      "city": "Bowling Green",
      "state": "Ohio",
      "country": "US",
      "elevation": 675,
      "lat": 41.3644981384,
      "lon": -83.5474014282,
      "tz": "America\/New_York"
  },
  "6OH7": {
      "icao": "6OH7",
      "iata": "",
      "name": "Nietz Airport",
      "city": "Bowling Green",
      "state": "Ohio",
      "country": "US",
      "elevation": 676,
      "lat": 41.4126014709,
      "lon": -83.6797027588,
      "tz": "America\/New_York"
  },
  "6OH9": {
      "icao": "6OH9",
      "iata": "",
      "name": "Kemps Field of Dreams Airport",
      "city": "Covington",
      "state": "Ohio",
      "country": "US",
      "elevation": 974,
      "lat": 40.1058998108,
      "lon": -84.3999023438,
      "tz": "America\/New_York"
  },
  "6OI0": {
      "icao": "6OI0",
      "iata": "",
      "name": "Derecsky Airport",
      "city": "Auburn Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 1185,
      "lat": 41.3759002686,
      "lon": -81.2573013306,
      "tz": "America\/New_York"
  },
  "6OI5": {
      "icao": "6OI5",
      "iata": "",
      "name": "Mitchell Airport",
      "city": "Plain City",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 40.137298584,
      "lon": -83.2180023193,
      "tz": "America\/New_York"
  },
  "6OK0": {
      "icao": "6OK0",
      "iata": "",
      "name": "White Airport",
      "city": "Kingfisher",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1110,
      "lat": 35.8913993835,
      "lon": -97.9589004517,
      "tz": "America\/Chicago"
  },
  "6OK1": {
      "icao": "6OK1",
      "iata": "",
      "name": "John Reid Airport",
      "city": "Tahlequah",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 831,
      "lat": 36.001499176,
      "lon": -95.0029983521,
      "tz": "America\/Chicago"
  },
  "6OK2": {
      "icao": "6OK2",
      "iata": "",
      "name": "Redhills Airport",
      "city": "Chickasha",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1175,
      "lat": 35.0628013611,
      "lon": -97.8953018188,
      "tz": "America\/Chicago"
  },
  "6OK3": {
      "icao": "6OK3",
      "iata": "",
      "name": "Pinson's Cottonpatch Airport",
      "city": "Tipton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1297,
      "lat": 34.4748001099,
      "lon": -99.1315002441,
      "tz": "America\/Chicago"
  },
  "6OK5": {
      "icao": "6OK5",
      "iata": "",
      "name": "Cary Ranch Airport",
      "city": "Reydon",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2455,
      "lat": 35.6286010742,
      "lon": -99.929397583,
      "tz": "America\/Chicago"
  },
  "6OK6": {
      "icao": "6OK6",
      "iata": "BWL",
      "name": "Earl Henry Airport",
      "city": "Blackwell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1054,
      "lat": 36.7958984375,
      "lon": -97.3170013428,
      "tz": "America\/Chicago"
  },
  "6OK7": {
      "icao": "6OK7",
      "iata": "",
      "name": "Cochran Ranch Airport",
      "city": "Atoka",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 790,
      "lat": 34.5959014893,
      "lon": -95.8629989624,
      "tz": "America\/Chicago"
  },
  "6OK8": {
      "icao": "6OK8",
      "iata": "",
      "name": "Lasley Private Airport",
      "city": "Eakly",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1630,
      "lat": 35.359500885,
      "lon": -98.5711975098,
      "tz": "America\/Chicago"
  },
  "6OK9": {
      "icao": "6OK9",
      "iata": "",
      "name": "Mulberry Hill Airport",
      "city": "Stillwater",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1026,
      "lat": 36.14170074,
      "lon": -96.94229889,
      "tz": "America\/Chicago"
  },
  "6OR0": {
      "icao": "6OR0",
      "iata": "",
      "name": "Grells Airport",
      "city": "Tangent",
      "state": "Oregon",
      "country": "US",
      "elevation": 253,
      "lat": 44.5297012329,
      "lon": -123.091003418,
      "tz": "America\/Los_Angeles"
  },
  "6OR1": {
      "icao": "6OR1",
      "iata": "",
      "name": "Pointers Airport",
      "city": "The Dalles",
      "state": "Oregon",
      "country": "US",
      "elevation": 1140,
      "lat": 45.572101593,
      "lon": -121.2720031738,
      "tz": "America\/Los_Angeles"
  },
  "6OR2": {
      "icao": "6OR2",
      "iata": "",
      "name": "Chenoweth Airpark",
      "city": "The Dalles",
      "state": "Oregon",
      "country": "US",
      "elevation": 609,
      "lat": 45.611000061,
      "lon": -121.2710037231,
      "tz": "America\/Los_Angeles"
  },
  "6OR4": {
      "icao": "6OR4",
      "iata": "",
      "name": "Tailwheel Airport",
      "city": "Prineville",
      "state": "Oregon",
      "country": "US",
      "elevation": 3130,
      "lat": 44.2731825,
      "lon": -120.794741944,
      "tz": "America\/Los_Angeles"
  },
  "6OR6": {
      "icao": "6OR6",
      "iata": "",
      "name": "Wonder Airport",
      "city": "Wonder",
      "state": "Oregon",
      "country": "US",
      "elevation": 1250,
      "lat": 42.3914985657,
      "lon": -123.5350036621,
      "tz": "America\/Los_Angeles"
  },
  "6OR7": {
      "icao": "6OR7",
      "iata": "",
      "name": "Schmidt Airport",
      "city": "Boring",
      "state": "Oregon",
      "country": "US",
      "elevation": 650,
      "lat": 45.4528999329,
      "lon": -122.3209991455,
      "tz": "America\/Los_Angeles"
  },
  "6OR8": {
      "icao": "6OR8",
      "iata": "",
      "name": "Holce & Oblack Airport",
      "city": "Birkenfield",
      "state": "Oregon",
      "country": "US",
      "elevation": 500,
      "lat": 45.9715003967,
      "lon": -123.3499984741,
      "tz": "America\/Los_Angeles"
  },
  "6OR9": {
      "icao": "6OR9",
      "iata": "",
      "name": "Reds Wallowa Horse Ranch Airport",
      "city": "Enterprise",
      "state": "Oregon",
      "country": "US",
      "elevation": 3600,
      "lat": 45.3414993286,
      "lon": -117.6259994507,
      "tz": "America\/Los_Angeles"
  },
  "6PA0": {
      "icao": "6PA0",
      "iata": "",
      "name": "Myer Airport",
      "city": "Milford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1345,
      "lat": 41.3501014709,
      "lon": -74.9328994751,
      "tz": "America\/New_York"
  },
  "6PA1": {
      "icao": "6PA1",
      "iata": "",
      "name": "Tower Airfield",
      "city": "North Washington",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1300,
      "lat": 40.5102996826,
      "lon": -79.5798034668,
      "tz": "America\/New_York"
  },
  "6PA3": {
      "icao": "6PA3",
      "iata": "",
      "name": "Hackenburg-Penny Hill Airport",
      "city": "Montoursville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1000,
      "lat": 41.150100708,
      "lon": -76.8996963501,
      "tz": "America\/New_York"
  },
  "6PA4": {
      "icao": "6PA4",
      "iata": "",
      "name": "Scandia Air Park",
      "city": "Russell",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2050,
      "lat": 41.9305992126,
      "lon": -79.0342025757,
      "tz": "America\/New_York"
  },
  "6PA5": {
      "icao": "6PA5",
      "iata": "",
      "name": "Botsford Aerodrome",
      "city": "Shippensburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 615,
      "lat": 40.1133995056,
      "lon": -77.4542007446,
      "tz": "America\/New_York"
  },
  "6PA6": {
      "icao": "6PA6",
      "iata": "",
      "name": "Air Haven Airport",
      "city": "Moscow",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1550,
      "lat": 41.3501014709,
      "lon": -75.483001709,
      "tz": "America\/New_York"
  },
  "6PA7": {
      "icao": "6PA7",
      "iata": "",
      "name": "Berwick Airport",
      "city": "Berwick",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 610,
      "lat": 41.0694999695,
      "lon": -76.2163009644,
      "tz": "America\/New_York"
  },
  "6PA8": {
      "icao": "6PA8",
      "iata": "",
      "name": "Tall Pines Airfield",
      "city": "Lock Haven",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1500,
      "lat": 41.2014007568,
      "lon": -77.4408035278,
      "tz": "America\/New_York"
  },
  "6PA9": {
      "icao": "6PA9",
      "iata": "",
      "name": "Shangri La Airport",
      "city": "Burnham",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 760,
      "lat": 40.6856002808,
      "lon": -77.4747009277,
      "tz": "America\/New_York"
  },
  "6PN5": {
      "icao": "6PN5",
      "iata": "",
      "name": "Sam's Field",
      "city": "Bradford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1540,
      "lat": 41.9075012207,
      "lon": -78.6288986206,
      "tz": "America\/New_York"
  },
  "6PN9": {
      "icao": "6PN9",
      "iata": "",
      "name": "Mc Donald's Airport",
      "city": "Brandonville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.8861999512,
      "lon": -76.153503418,
      "tz": "America\/New_York"
  },
  "6PS3": {
      "icao": "6PS3",
      "iata": "",
      "name": "Champ Field",
      "city": "Knoxville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1220,
      "lat": 41.9589996338,
      "lon": -77.4261016846,
      "tz": "America\/New_York"
  },
  "6PS8": {
      "icao": "6PS8",
      "iata": "",
      "name": "Tyler Airport",
      "city": "New Milford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1401,
      "lat": 41.816719,
      "lon": -75.698783,
      "tz": "America\/New_York"
  },
  "6SC1": {
      "icao": "6SC1",
      "iata": "",
      "name": "Lesesne Airport",
      "city": "Cross",
      "state": "South Carolina",
      "country": "US",
      "elevation": 83,
      "lat": 33.3692016602,
      "lon": -80.2232971191,
      "tz": "America\/New_York"
  },
  "6TA0": {
      "icao": "6TA0",
      "iata": "",
      "name": "Rocking R Ranch Airport",
      "city": "Eldorado",
      "state": "Texas",
      "country": "US",
      "elevation": 2250,
      "lat": 30.858499527,
      "lon": -100.3229980469,
      "tz": "America\/Chicago"
  },
  "6TA2": {
      "icao": "6TA2",
      "iata": "",
      "name": "Rockys Place Airport",
      "city": "Leonard",
      "state": "Texas",
      "country": "US",
      "elevation": 685,
      "lat": 33.3722000122,
      "lon": -96.362197876,
      "tz": "America\/Chicago"
  },
  "6TA3": {
      "icao": "6TA3",
      "iata": "",
      "name": "Culp Airport",
      "city": "Lawrence",
      "state": "Texas",
      "country": "US",
      "elevation": 470,
      "lat": 32.7214012146,
      "lon": -96.7238998413,
      "tz": "America\/Chicago"
  },
  "6TA4": {
      "icao": "6TA4",
      "iata": "",
      "name": "Winn Exploration Co. Inc Airport",
      "city": "Eagle Pass",
      "state": "Texas",
      "country": "US",
      "elevation": 881,
      "lat": 28.7168998718,
      "lon": -100.408996582,
      "tz": "America\/Chicago"
  },
  "6TA7": {
      "icao": "6TA7",
      "iata": "",
      "name": "Flying K Airport",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 1150,
      "lat": 32.8284988403,
      "lon": -97.7795028687,
      "tz": "America\/Chicago"
  },
  "6TA9": {
      "icao": "6TA9",
      "iata": "",
      "name": "Los Ebanos Ranch Airport",
      "city": "Mathis",
      "state": "Texas",
      "country": "US",
      "elevation": 225,
      "lat": 28.1170005798,
      "lon": -98.0447006226,
      "tz": "America\/Chicago"
  },
  "6TE0": {
      "icao": "6TE0",
      "iata": "",
      "name": "Skalitsky Airport",
      "city": "Edcouch",
      "state": "Texas",
      "country": "US",
      "elevation": 60,
      "lat": 26.2903003693,
      "lon": -97.9494018555,
      "tz": "America\/Chicago"
  },
  "6TE1": {
      "icao": "6TE1",
      "iata": "",
      "name": "Norman & White Airport",
      "city": "Edinburg",
      "state": "Texas",
      "country": "US",
      "elevation": 90,
      "lat": 26.3614997864,
      "lon": -98.1524963379,
      "tz": "America\/Chicago"
  },
  "6TE2": {
      "icao": "6TE2",
      "iata": "",
      "name": "Zimmerle Airport",
      "city": "Abilene",
      "state": "Texas",
      "country": "US",
      "elevation": 2057,
      "lat": 32.270401001,
      "lon": -99.5979003906,
      "tz": "America\/Chicago"
  },
  "6TE3": {
      "icao": "6TE3",
      "iata": "",
      "name": "Hahns Airport",
      "city": "Edna",
      "state": "Texas",
      "country": "US",
      "elevation": 52,
      "lat": 28.9489002228,
      "lon": -96.549697876,
      "tz": "America\/Chicago"
  },
  "6TE4": {
      "icao": "6TE4",
      "iata": "",
      "name": "Coldwater Ranch Airport",
      "city": "Sunray",
      "state": "Texas",
      "country": "US",
      "elevation": 3446,
      "lat": 36.2333984375,
      "lon": -101.7340011597,
      "tz": "America\/Chicago"
  },
  "6TE5": {
      "icao": "6TE5",
      "iata": "",
      "name": "Kubecka Flying Service Inc. Airport",
      "city": "Edna",
      "state": "Texas",
      "country": "US",
      "elevation": 65,
      "lat": 28.9808006287,
      "lon": -96.6219024658,
      "tz": "America\/Chicago"
  },
  "6TE6": {
      "icao": "6TE6",
      "iata": "",
      "name": "6666 Ranch Airport",
      "city": "Guthrie",
      "state": "Texas",
      "country": "US",
      "elevation": 1775,
      "lat": 33.6412010193,
      "lon": -100.347999573,
      "tz": "America\/Chicago"
  },
  "6TE7": {
      "icao": "6TE7",
      "iata": "",
      "name": "Mc Neill Ranch Airport",
      "city": "Wayside",
      "state": "Texas",
      "country": "US",
      "elevation": 3413,
      "lat": 34.837600708,
      "lon": -101.57900238,
      "tz": "America\/Chicago"
  },
  "6TE8": {
      "icao": "6TE8",
      "iata": "",
      "name": "Douglas Flying Service Private Airport",
      "city": "Aspermont",
      "state": "Texas",
      "country": "US",
      "elevation": 1743,
      "lat": 33.1689987183,
      "lon": -100.2170028687,
      "tz": "America\/Chicago"
  },
  "6TE9": {
      "icao": "6TE9",
      "iata": "",
      "name": "Mc Kenzie Field",
      "city": "Avinger",
      "state": "Texas",
      "country": "US",
      "elevation": 360,
      "lat": 32.8167991638,
      "lon": -94.616897583,
      "tz": "America\/Chicago"
  },
  "6TN1": {
      "icao": "6TN1",
      "iata": "",
      "name": "Weakleys Field",
      "city": "Pleasantview",
      "state": "Tennessee",
      "country": "US",
      "elevation": 705,
      "lat": 36.40620041,
      "lon": -87.0821991,
      "tz": "America\/Chicago"
  },
  "6TN4": {
      "icao": "6TN4",
      "iata": "",
      "name": "The Aviation Valley Airport",
      "city": "Tullahoma",
      "state": "Tennessee",
      "country": "US",
      "elevation": 890,
      "lat": 35.4219017029,
      "lon": -86.2902984619,
      "tz": "America\/Chicago"
  },
  "6TN7": {
      "icao": "6TN7",
      "iata": "",
      "name": "St. Somewhere Airport",
      "city": "Grand Junction",
      "state": "Tennessee",
      "country": "US",
      "elevation": 550,
      "lat": 35.0238990784,
      "lon": -89.2050018311,
      "tz": "America\/Chicago"
  },
  "6TN9": {
      "icao": "6TN9",
      "iata": "",
      "name": "Dripping Springs Farm Airport",
      "city": "Burns",
      "state": "Tennessee",
      "country": "US",
      "elevation": 728,
      "lat": 36.0614013672,
      "lon": -87.2472000122,
      "tz": "America\/Chicago"
  },
  "6TS0": {
      "icao": "6TS0",
      "iata": "",
      "name": "True Airport",
      "city": "Plainview",
      "state": "Texas",
      "country": "US",
      "elevation": 3385,
      "lat": 34.0892219543,
      "lon": -101.7404632568,
      "tz": "America\/Chicago"
  },
  "6TS1": {
      "icao": "6TS1",
      "iata": "",
      "name": "Worrell Airport",
      "city": "Kempner",
      "state": "Texas",
      "country": "US",
      "elevation": 960,
      "lat": 31.1779003143,
      "lon": -98.073097229,
      "tz": "America\/Chicago"
  },
  "6TS2": {
      "icao": "6TS2",
      "iata": "",
      "name": "Dauenhauer Field Airport",
      "city": "Boyd",
      "state": "Texas",
      "country": "US",
      "elevation": 906,
      "lat": 29.0993995667,
      "lon": -98.46559906,
      "tz": "America\/Chicago"
  },
  "6TS3": {
      "icao": "6TS3",
      "iata": "",
      "name": "Dale Acres Airport",
      "city": "Milford",
      "state": "Texas",
      "country": "US",
      "elevation": 550,
      "lat": 32.1599006653,
      "lon": -96.9419021606,
      "tz": "America\/Chicago"
  },
  "6TS4": {
      "icao": "6TS4",
      "iata": "",
      "name": "Stampede Valley Airport",
      "city": "Moffat",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 31.1863002777,
      "lon": -97.3906021118,
      "tz": "America\/Chicago"
  },
  "6TS6": {
      "icao": "6TS6",
      "iata": "",
      "name": "Wood Crest Ranch Airport",
      "city": "Normangee",
      "state": "Texas",
      "country": "US",
      "elevation": 410,
      "lat": 31.0415992737,
      "lon": -96.2015991211,
      "tz": "America\/Chicago"
  },
  "6TS8": {
      "icao": "6TS8",
      "iata": "",
      "name": "Rabb And Nobra Airport",
      "city": "Industry",
      "state": "Texas",
      "country": "US",
      "elevation": 400,
      "lat": 30.003333,
      "lon": -96.581111,
      "tz": "America\/Chicago"
  },
  "6TX2": {
      "icao": "6TX2",
      "iata": "",
      "name": "Circle M Ranch Airport",
      "city": "Frelsburg",
      "state": "Texas",
      "country": "US",
      "elevation": 340,
      "lat": 29.8349990845,
      "lon": -96.5143966675,
      "tz": "America\/Chicago"
  },
  "6TX3": {
      "icao": "6TX3",
      "iata": "",
      "name": "Drewery Airport",
      "city": "Mc Kinney",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 33.2335014343,
      "lon": -96.7835998535,
      "tz": "America\/Chicago"
  },
  "6TX4": {
      "icao": "6TX4",
      "iata": "",
      "name": "Whitfield Airport",
      "city": "Kress",
      "state": "Texas",
      "country": "US",
      "elevation": 3330,
      "lat": 34.3433990479,
      "lon": -101.5439987183,
      "tz": "America\/Chicago"
  },
  "6TX6": {
      "icao": "6TX6",
      "iata": "",
      "name": "Jtj Ranch Airport",
      "city": "Corsicana",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 32.0825996399,
      "lon": -96.3566970825,
      "tz": "America\/Chicago"
  },
  "6TX7": {
      "icao": "6TX7",
      "iata": "",
      "name": "Flying L Airpark",
      "city": "Mansfield",
      "state": "Texas",
      "country": "US",
      "elevation": 680,
      "lat": 32.5376014709,
      "lon": -97.1307983398,
      "tz": "America\/Chicago"
  },
  "6TX8": {
      "icao": "6TX8",
      "iata": "",
      "name": "Hess Airport",
      "city": "Mansfield",
      "state": "Texas",
      "country": "US",
      "elevation": 673,
      "lat": 32.5559997559,
      "lon": -97.2063980103,
      "tz": "America\/Chicago"
  },
  "6TX9": {
      "icao": "6TX9",
      "iata": "",
      "name": "Stovall Ranch Nr 4 Airport",
      "city": "Marathon",
      "state": "Texas",
      "country": "US",
      "elevation": 3010,
      "lat": 29.8549003601,
      "lon": -102.7919998169,
      "tz": "America\/Chicago"
  },
  "6VA0": {
      "icao": "6VA0",
      "iata": "",
      "name": "Remo Private Airport",
      "city": "Reidville",
      "state": "Virginia",
      "country": "US",
      "elevation": 13,
      "lat": 37.8186988831,
      "lon": -76.3177032471,
      "tz": "America\/New_York"
  },
  "6VA2": {
      "icao": "6VA2",
      "iata": "",
      "name": "Loury Lester Airpark",
      "city": "Martinsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 1030,
      "lat": 36.7417984009,
      "lon": -79.8478012085,
      "tz": "America\/New_York"
  },
  "6VA4": {
      "icao": "6VA4",
      "iata": "",
      "name": "Trussmark Airport",
      "city": "Salem",
      "state": "Virginia",
      "country": "US",
      "elevation": 1030,
      "lat": 37.2849006653,
      "lon": -80.0698013306,
      "tz": "America\/New_York"
  },
  "6VA9": {
      "icao": "6VA9",
      "iata": "",
      "name": "Bush Airport",
      "city": "Woolwine",
      "state": "Virginia",
      "country": "US",
      "elevation": 1500,
      "lat": 36.8426017761,
      "lon": -80.2384033203,
      "tz": "America\/New_York"
  },
  "6WA2": {
      "icao": "6WA2",
      "iata": "",
      "name": "Gower Field",
      "city": "Olympia",
      "state": "Washington",
      "country": "US",
      "elevation": 130,
      "lat": 47.1114997864,
      "lon": -122.8209991455,
      "tz": "America\/Los_Angeles"
  },
  "6WA3": {
      "icao": "6WA3",
      "iata": "",
      "name": "Green Acres Airport",
      "city": "Basin City",
      "state": "Washington",
      "country": "US",
      "elevation": 1000,
      "lat": 46.6167984009,
      "lon": -119.2740020752,
      "tz": "America\/Los_Angeles"
  },
  "6WA4": {
      "icao": "6WA4",
      "iata": "",
      "name": "Ochoa Field",
      "city": "Othello",
      "state": "Washington",
      "country": "US",
      "elevation": 1149,
      "lat": 46.8628997803,
      "lon": -119.138999939,
      "tz": "America\/Los_Angeles"
  },
  "6WA5": {
      "icao": "6WA5",
      "iata": "",
      "name": "Wilding Farm Airport",
      "city": "Shaw Island",
      "state": "Washington",
      "country": "US",
      "elevation": 60,
      "lat": 48.5665016174,
      "lon": -122.9489974976,
      "tz": "America\/Los_Angeles"
  },
  "6WA6": {
      "icao": "6WA6",
      "iata": "",
      "name": "Carr Airport",
      "city": "Pasco",
      "state": "Washington",
      "country": "US",
      "elevation": 625,
      "lat": 46.2784996033,
      "lon": -118.9560012817,
      "tz": "America\/Los_Angeles"
  },
  "6WI0": {
      "icao": "6WI0",
      "iata": "",
      "name": "Cub Acres Airport",
      "city": "Prairie Farm",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 45.1805000305,
      "lon": -91.987701416,
      "tz": "America\/Chicago"
  },
  "6WI1": {
      "icao": "6WI1",
      "iata": "",
      "name": "Winch Airfield",
      "city": "Wisconsin Rapids",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1120,
      "lat": 44.4952011108,
      "lon": -89.9125976563,
      "tz": "America\/Chicago"
  },
  "6WI2": {
      "icao": "6WI2",
      "iata": "",
      "name": "St Croix Riviera Airport",
      "city": "River Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 895,
      "lat": 44.8438987732,
      "lon": -92.7557983398,
      "tz": "America\/Chicago"
  },
  "6WI3": {
      "icao": "6WI3",
      "iata": "",
      "name": "Will-Be-Gone Airport",
      "city": "Birchwood",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1290,
      "lat": 45.6772003174,
      "lon": -91.5625,
      "tz": "America\/Chicago"
  },
  "6WI4": {
      "icao": "6WI4",
      "iata": "",
      "name": "Saxon Airport",
      "city": "Saxon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 950,
      "lat": 46.515499115,
      "lon": -90.431602478,
      "tz": "America\/Chicago"
  },
  "6WI5": {
      "icao": "6WI5",
      "iata": "",
      "name": "Dolata Airport",
      "city": "Stiles",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 615,
      "lat": 44.8891983032,
      "lon": -88.0208969116,
      "tz": "America\/Chicago"
  },
  "6WI6": {
      "icao": "6WI6",
      "iata": "",
      "name": "Mick Shier Field",
      "city": "Rosiere",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 650,
      "lat": 44.6839981079,
      "lon": -87.6279983521,
      "tz": "America\/Chicago"
  },
  "6WI7": {
      "icao": "6WI7",
      "iata": "",
      "name": "Walnut Wash Airport",
      "city": "Orfordville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 900,
      "lat": 42.6104011536,
      "lon": -89.2761001587,
      "tz": "America\/Chicago"
  },
  "6WI9": {
      "icao": "6WI9",
      "iata": "",
      "name": "Rex Ranch Airport",
      "city": "Montello",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 790,
      "lat": 43.7966003418,
      "lon": -89.2678985596,
      "tz": "America\/Chicago"
  },
  "6WN5": {
      "icao": "6WN5",
      "iata": "",
      "name": "Airwolfe Airport",
      "city": "Stockholm",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1130,
      "lat": 44.4998016357,
      "lon": -92.1987991333,
      "tz": "America\/Chicago"
  },
  "6WN6": {
      "icao": "6WN6",
      "iata": "",
      "name": "Fountain Prairie Airport",
      "city": "Columbus",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 880,
      "lat": 43.3800010681,
      "lon": -89.0106964111,
      "tz": "America\/Chicago"
  },
  "6XA4": {
      "icao": "6XA4",
      "iata": "",
      "name": "Zadow Airstrip",
      "city": "",
      "state": "Texas",
      "country": "US",
      "elevation": 0,
      "lat": 29.9917385509,
      "lon": -95.954353809,
      "tz": "America\/Chicago"
  },
  "6XS2": {
      "icao": "6XS2",
      "iata": "",
      "name": "Luscombe Acres Airport",
      "city": "Alvarado",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.3460006714,
      "lon": -97.1992034912,
      "tz": "America\/Chicago"
  },
  "6XS3": {
      "icao": "6XS3",
      "iata": "",
      "name": "Mullins Landing Airport",
      "city": "Farmersville",
      "state": "Texas",
      "country": "US",
      "elevation": 550,
      "lat": 33.1385002136,
      "lon": -96.3854980469,
      "tz": "America\/Chicago"
  },
  "6XS4": {
      "icao": "6XS4",
      "iata": "",
      "name": "Noelke Ranch Airport",
      "city": "Mertzon",
      "state": "Texas",
      "country": "US",
      "elevation": 2500,
      "lat": 31.1667995453,
      "lon": -101,
      "tz": "America\/Chicago"
  },
  "6XS5": {
      "icao": "6XS5",
      "iata": "",
      "name": "Cannon Aviation Airport",
      "city": "Edcouch",
      "state": "Texas",
      "country": "US",
      "elevation": 65,
      "lat": 26.3087005615,
      "lon": -97.9543991089,
      "tz": "America\/Chicago"
  },
  "6XS7": {
      "icao": "6XS7",
      "iata": "",
      "name": "Eugene's Dream Airport",
      "city": "Springtown",
      "state": "Texas",
      "country": "US",
      "elevation": 905,
      "lat": 33.04529953,
      "lon": -97.6747970581,
      "tz": "America\/Chicago"
  },
  "6XS8": {
      "icao": "6XS8",
      "iata": "",
      "name": "Vultures Row Airport",
      "city": "Sanger",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 33.3400001526,
      "lon": -97.1031036377,
      "tz": "America\/Chicago"
  },
  "6XS9": {
      "icao": "6XS9",
      "iata": "",
      "name": "Harmony Field",
      "city": "Elmendorf",
      "state": "Texas",
      "country": "US",
      "elevation": 570,
      "lat": 29.2961006165,
      "lon": -98.3795013428,
      "tz": "America\/Chicago"
  },
  "70AK": {
      "icao": "70AK",
      "iata": "",
      "name": "Bangerter Field",
      "city": "Soldotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 250,
      "lat": 60.517778,
      "lon": -150.959722,
      "tz": "America\/Anchorage"
  },
  "70AR": {
      "icao": "70AR",
      "iata": "",
      "name": "Randal Field",
      "city": "Gosnell",
      "state": "Arkansas",
      "country": "US",
      "elevation": 242,
      "lat": 35.9944992065,
      "lon": -89.9972991943,
      "tz": "America\/Chicago"
  },
  "70CL": {
      "icao": "70CL",
      "iata": "",
      "name": "Amargosa Airport",
      "city": "Death Valley Junction",
      "state": "California",
      "country": "US",
      "elevation": 2037,
      "lat": 36.2957992554,
      "lon": -116.4260025024,
      "tz": "America\/Los_Angeles"
  },
  "70II": {
      "icao": "70II",
      "iata": "",
      "name": "Nasby Airport",
      "city": "Shelbyville",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 39.5217018127,
      "lon": -85.6864013672,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "70IL": {
      "icao": "70IL",
      "iata": "",
      "name": "Murphy Farms Airport",
      "city": "Farmersville",
      "state": "Illinois",
      "country": "US",
      "elevation": 645,
      "lat": 39.4235992432,
      "lon": -89.5682983398,
      "tz": "America\/Chicago"
  },
  "70IN": {
      "icao": "70IN",
      "iata": "",
      "name": "Lewis Airfield",
      "city": "Milan",
      "state": "Indiana",
      "country": "US",
      "elevation": 989,
      "lat": 39.1817016602,
      "lon": -85.1512985229,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "70KY": {
      "icao": "70KY",
      "iata": "",
      "name": "Vine Grove Airport",
      "city": "Vine Grove",
      "state": "Kentucky",
      "country": "US",
      "elevation": 680,
      "lat": 37.8158988953,
      "lon": -85.9646987915,
      "tz": "America\/New_York"
  },
  "70LA": {
      "icao": "70LA",
      "iata": "",
      "name": "Roland Airport",
      "city": "Hineston",
      "state": "Louisiana",
      "country": "US",
      "elevation": 220,
      "lat": 31.1690998077,
      "lon": -92.7563018799,
      "tz": "America\/Chicago"
  },
  "70LL": {
      "icao": "70LL",
      "iata": "",
      "name": "Adams Restricted Landing Area Number 1",
      "city": "Pontiac",
      "state": "Illinois",
      "country": "US",
      "elevation": 640,
      "lat": 40.9416999817,
      "lon": -88.7500991821,
      "tz": "America\/Chicago"
  },
  "70MD": {
      "icao": "70MD",
      "iata": "",
      "name": "Washington Field",
      "city": "Nanjemoy",
      "state": "Maryland",
      "country": "US",
      "elevation": 95,
      "lat": 38.458734,
      "lon": -77.23498,
      "tz": "America\/New_York"
  },
  "70MN": {
      "icao": "70MN",
      "iata": "",
      "name": "Harry-Walt Airport",
      "city": "Cedar",
      "state": "Minnesota",
      "country": "US",
      "elevation": 940,
      "lat": 45.3518981934,
      "lon": -93.2461013794,
      "tz": "America\/Chicago"
  },
  "70MO": {
      "icao": "70MO",
      "iata": "",
      "name": "Andrews Airport",
      "city": "Carthage",
      "state": "Missouri",
      "country": "US",
      "elevation": 1070,
      "lat": 37.2178001404,
      "lon": -94.1463012695,
      "tz": "America\/Chicago"
  },
  "70NC": {
      "icao": "70NC",
      "iata": "",
      "name": "Martindale Executive Airpark",
      "city": "Paschall",
      "state": "Virginia",
      "country": "US",
      "elevation": 300,
      "lat": 36.5446014404,
      "lon": -78.1480026245,
      "tz": "America\/New_York"
  },
  "70NJ": {
      "icao": "70NJ",
      "iata": "",
      "name": "Parker Airport",
      "city": "Asbury",
      "state": "New Jersey",
      "country": "US",
      "elevation": 307,
      "lat": 40.6769981384,
      "lon": -75.024597168,
      "tz": "America\/New_York"
  },
  "70OH": {
      "icao": "70OH",
      "iata": "",
      "name": "Shenandoah Airpark",
      "city": "Shenandoah",
      "state": "Ohio",
      "country": "US",
      "elevation": 1210,
      "lat": 40.9201011658,
      "lon": -82.4791030884,
      "tz": "America\/New_York"
  },
  "70OK": {
      "icao": "70OK",
      "iata": "",
      "name": "Old 66 Strip",
      "city": "Miami",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 853,
      "lat": 36.8336982727,
      "lon": -94.9040985107,
      "tz": "America\/Chicago"
  },
  "70OR": {
      "icao": "70OR",
      "iata": "",
      "name": "Goodin Creek Airport",
      "city": "Gaston",
      "state": "Oregon",
      "country": "US",
      "elevation": 245,
      "lat": 45.4095993042,
      "lon": -123.1490020752,
      "tz": "America\/Los_Angeles"
  },
  "70PN": {
      "icao": "70PN",
      "iata": "",
      "name": "Sanders Personal Use Airport",
      "city": "Sugar Grove",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1455,
      "lat": 41.989200592,
      "lon": -79.3011016846,
      "tz": "America\/New_York"
  },
  "70TE": {
      "icao": "70TE",
      "iata": "",
      "name": "Flying Heart Ranch Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 415,
      "lat": 31.5020999908,
      "lon": -97.0808029175,
      "tz": "America\/Chicago"
  },
  "70VA": {
      "icao": "70VA",
      "iata": "",
      "name": "Burnt Chimney Airport",
      "city": "Burnt Chimney",
      "state": "Virginia",
      "country": "US",
      "elevation": 1034,
      "lat": 37.0754013062,
      "lon": -79.8273010254,
      "tz": "America\/New_York"
  },
  "70WI": {
      "icao": "70WI",
      "iata": "",
      "name": "Kanten Field",
      "city": "Baldwin",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1160,
      "lat": 45.0321998596,
      "lon": -92.3544006348,
      "tz": "America\/Chicago"
  },
  "70XS": {
      "icao": "70XS",
      "iata": "",
      "name": "Restoration Ranch Airport",
      "city": "Wimberley",
      "state": "Texas",
      "country": "US",
      "elevation": 840,
      "lat": 29.9932994843,
      "lon": -98.043296814,
      "tz": "America\/Chicago"
  },
  "71AK": {
      "icao": "71AK",
      "iata": "",
      "name": "Bluff Park Farm Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 110,
      "lat": 61.5275993347,
      "lon": -149.4960021973,
      "tz": "America\/Anchorage"
  },
  "71AL": {
      "icao": "71AL",
      "iata": "",
      "name": "Sells Airport",
      "city": "Greenville",
      "state": "Alabama",
      "country": "US",
      "elevation": 380,
      "lat": 31.808786,
      "lon": -86.523525,
      "tz": "America\/Chicago"
  },
  "71CL": {
      "icao": "71CL",
      "iata": "",
      "name": "Gunnersfield Ranch Airport",
      "city": "Delevan",
      "state": "California",
      "country": "US",
      "elevation": 62,
      "lat": 39.3526992798,
      "lon": -122.09400177,
      "tz": "America\/Los_Angeles"
  },
  "71IN": {
      "icao": "71IN",
      "iata": "",
      "name": "Morgan Airfield",
      "city": "Salem",
      "state": "Indiana",
      "country": "US",
      "elevation": 922,
      "lat": 38.6659011841,
      "lon": -86.0057983398,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "71IS": {
      "icao": "71IS",
      "iata": "",
      "name": "Ted's Place Airport",
      "city": "Paxton",
      "state": "Illinois",
      "country": "US",
      "elevation": 780,
      "lat": 40.4255981445,
      "lon": -88.0006027222,
      "tz": "America\/Chicago"
  },
  "71KS": {
      "icao": "71KS",
      "iata": "",
      "name": "Stonehenge Airport",
      "city": "Williamstown",
      "state": "Kansas",
      "country": "US",
      "elevation": 1030,
      "lat": 39.1041984558,
      "lon": -95.2960968018,
      "tz": "America\/Chicago"
  },
  "71KY": {
      "icao": "71KY",
      "iata": "",
      "name": "Sunrise Acres Airport",
      "city": "Petersburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 465,
      "lat": 39.0348014832,
      "lon": -84.8727035522,
      "tz": "America\/New_York"
  },
  "71LA": {
      "icao": "71LA",
      "iata": "",
      "name": "Gustafson Airport",
      "city": "Tallulah",
      "state": "Louisiana",
      "country": "US",
      "elevation": 80,
      "lat": 32.4331016541,
      "lon": -91.3152008057,
      "tz": "America\/Chicago"
  },
  "71LL": {
      "icao": "71LL",
      "iata": "",
      "name": "Riverode Farms Airport",
      "city": "Pontiac",
      "state": "Illinois",
      "country": "US",
      "elevation": 651,
      "lat": 40.8424987793,
      "lon": -88.5597991943,
      "tz": "America\/Chicago"
  },
  "71ME": {
      "icao": "71ME",
      "iata": "",
      "name": "Twin Eagles Airport",
      "city": "Naples",
      "state": "Maine",
      "country": "US",
      "elevation": 280,
      "lat": 43.9668998718,
      "lon": -70.6010971069,
      "tz": "America\/New_York"
  },
  "71MI": {
      "icao": "71MI",
      "iata": "",
      "name": "Belleville (Johnston) Airport",
      "city": "Belleville",
      "state": "Michigan",
      "country": "US",
      "elevation": 643,
      "lat": 42.0843851004,
      "lon": -83.5170149803,
      "tz": "America\/Detroit"
  },
  "71MO": {
      "icao": "71MO",
      "iata": "",
      "name": "Famuliner Farms Airport",
      "city": "Wakenda",
      "state": "Missouri",
      "country": "US",
      "elevation": 655,
      "lat": 39.2919998169,
      "lon": -93.3841018677,
      "tz": "America\/Chicago"
  },
  "71NC": {
      "icao": "71NC",
      "iata": "",
      "name": "K & D Airport",
      "city": "Elon College",
      "state": "North Carolina",
      "country": "US",
      "elevation": 800,
      "lat": 36.2633018494,
      "lon": -79.4496994019,
      "tz": "America\/New_York"
  },
  "71NE": {
      "icao": "71NE",
      "iata": "",
      "name": "Neben Airport",
      "city": "Oconto",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2615,
      "lat": 41.0355987549,
      "lon": -99.8298034668,
      "tz": "America\/Chicago"
  },
  "71OH": {
      "icao": "71OH",
      "iata": "",
      "name": "Sattler Landing Strip",
      "city": "Swanton",
      "state": "Ohio",
      "country": "US",
      "elevation": 700,
      "lat": 41.6417007446,
      "lon": -83.8749008179,
      "tz": "America\/New_York"
  },
  "71OK": {
      "icao": "71OK",
      "iata": "",
      "name": "Neuwirth Airstrip",
      "city": "Lawton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1185,
      "lat": 34.5761985779,
      "lon": -98.483001709,
      "tz": "America\/Chicago"
  },
  "71OR": {
      "icao": "71OR",
      "iata": "",
      "name": "Cerny Airport",
      "city": "Seneca",
      "state": "Oregon",
      "country": "US",
      "elevation": 4765,
      "lat": 44.2445,
      "lon": -119.126242,
      "tz": "America\/Los_Angeles"
  },
  "71PN": {
      "icao": "71PN",
      "iata": "",
      "name": "Schulteis Field",
      "city": "New Oxford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 39.91815,
      "lon": -77.045533,
      "tz": "America\/New_York"
  },
  "71TA": {
      "icao": "71TA",
      "iata": "",
      "name": "Bates Field",
      "city": "Odessa",
      "state": "Texas",
      "country": "US",
      "elevation": 2820,
      "lat": 31.8334999084,
      "lon": -102.2340011597,
      "tz": "America\/Chicago"
  },
  "71TE": {
      "icao": "71TE",
      "iata": "",
      "name": "Moursund Ranch Airport",
      "city": "Round Mountain",
      "state": "Texas",
      "country": "US",
      "elevation": 1471,
      "lat": 30.3780002594,
      "lon": -98.3760986328,
      "tz": "America\/Chicago"
  },
  "71TS": {
      "icao": "71TS",
      "iata": "",
      "name": "Circle C Ranch Airport",
      "city": "Saginaw",
      "state": "Texas",
      "country": "US",
      "elevation": 675,
      "lat": 32.8959999084,
      "lon": -97.2878036499,
      "tz": "America\/Chicago"
  },
  "71TX": {
      "icao": "71TX",
      "iata": "",
      "name": "Grier Airport",
      "city": "Boerne",
      "state": "Texas",
      "country": "US",
      "elevation": 1450,
      "lat": 29.8726997375,
      "lon": -98.7322006226,
      "tz": "America\/Chicago"
  },
  "71XS": {
      "icao": "71XS",
      "iata": "",
      "name": "Flying V Airport",
      "city": "Sanger",
      "state": "Texas",
      "country": "US",
      "elevation": 680,
      "lat": 33.3465003967,
      "lon": -97.2058029175,
      "tz": "America\/Chicago"
  },
  "72CL": {
      "icao": "72CL",
      "iata": "",
      "name": "Iron Mountain Pumping Plant Airport",
      "city": "Desert Center",
      "state": "California",
      "country": "US",
      "elevation": 890,
      "lat": 34.1342010498,
      "lon": -115.1190032959,
      "tz": "America\/Los_Angeles"
  },
  "72CO": {
      "icao": "72CO",
      "iata": "",
      "name": "Idler Bro's Airport",
      "city": "Kirk",
      "state": "Colorado",
      "country": "US",
      "elevation": 4200,
      "lat": 39.6794013977,
      "lon": -102.591003418,
      "tz": "America\/Denver"
  },
  "72FL": {
      "icao": "72FL",
      "iata": "",
      "name": "Gator Airpark",
      "city": "St Cloud",
      "state": "Florida",
      "country": "US",
      "elevation": 68,
      "lat": 28.1575164795,
      "lon": -81.2347946167,
      "tz": "America\/New_York"
  },
  "72GA": {
      "icao": "72GA",
      "iata": "",
      "name": "Barbour Island Airport",
      "city": "Shellman Bluff",
      "state": "Georgia",
      "country": "US",
      "elevation": 10,
      "lat": 31.5835990906,
      "lon": -81.2332000732,
      "tz": "America\/New_York"
  },
  "72IN": {
      "icao": "72IN",
      "iata": "",
      "name": "Gutwein Airport",
      "city": "Monon",
      "state": "Indiana",
      "country": "US",
      "elevation": 680,
      "lat": 40.9119987488,
      "lon": -86.8739013672,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "72IS": {
      "icao": "72IS",
      "iata": "",
      "name": "Wiley Updike Airport",
      "city": "Plano",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 41.6253013611,
      "lon": -88.5745010376,
      "tz": "America\/Chicago"
  },
  "72KS": {
      "icao": "72KS",
      "iata": "",
      "name": "Mount Muncie Airport",
      "city": "Lansing",
      "state": "Kansas",
      "country": "US",
      "elevation": 750,
      "lat": 39.2733001709,
      "lon": -94.8802032471,
      "tz": "America\/Chicago"
  },
  "72KY": {
      "icao": "72KY",
      "iata": "",
      "name": "Carr Airport",
      "city": "Williamsburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1105,
      "lat": 36.7650985718,
      "lon": -84.1994018555,
      "tz": "America\/New_York"
  },
  "72LL": {
      "icao": "72LL",
      "iata": "",
      "name": "Foley Airport",
      "city": "Princeton",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 41.3736000061,
      "lon": -89.3444976807,
      "tz": "America\/Chicago"
  },
  "72ME": {
      "icao": "72ME",
      "iata": "",
      "name": "Philbrick Mountain Airport",
      "city": "Ellsworth",
      "state": "Maine",
      "country": "US",
      "elevation": 667,
      "lat": 44.635278,
      "lon": -68.6325,
      "tz": "America\/New_York"
  },
  "72MI": {
      "icao": "72MI",
      "iata": "",
      "name": "Northwoods Airport",
      "city": "Watersmeet",
      "state": "Michigan",
      "country": "US",
      "elevation": 1642,
      "lat": 46.285900116,
      "lon": -89.2774963379,
      "tz": "America\/Menominee"
  },
  "72MN": {
      "icao": "72MN",
      "iata": "",
      "name": "Bardwell Airstrip",
      "city": "Clearbrook",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1260,
      "lat": 47.7524986267,
      "lon": -95.3349990845,
      "tz": "America\/Chicago"
  },
  "72ND": {
      "icao": "72ND",
      "iata": "",
      "name": "Berg Field",
      "city": "Northwood",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1111,
      "lat": 47.6610984802,
      "lon": -97.5920028687,
      "tz": "America\/Chicago"
  },
  "72NE": {
      "icao": "72NE",
      "iata": "",
      "name": "J&J Airport",
      "city": "Springfield",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1135,
      "lat": 41.0792007446,
      "lon": -96.1530990601,
      "tz": "America\/Chicago"
  },
  "72NM": {
      "icao": "72NM",
      "iata": "",
      "name": "Otero Mill Airport",
      "city": "La Luz",
      "state": "New Mexico",
      "country": "US",
      "elevation": 4603,
      "lat": 32.9584007263,
      "lon": -105.9629974365,
      "tz": "America\/Denver"
  },
  "72OH": {
      "icao": "72OH",
      "iata": "",
      "name": "Zeigler Landing Strip",
      "city": "Swanton",
      "state": "Ohio",
      "country": "US",
      "elevation": 680,
      "lat": 41.6044998169,
      "lon": -83.8676986694,
      "tz": "America\/New_York"
  },
  "72OI": {
      "icao": "72OI",
      "iata": "",
      "name": "Tathams' STOLport",
      "city": "Findlay",
      "state": "Ohio",
      "country": "US",
      "elevation": 817,
      "lat": 41.0736999512,
      "lon": -83.6847000122,
      "tz": "America\/New_York"
  },
  "72OR": {
      "icao": "72OR",
      "iata": "",
      "name": "Ochs Private Airport",
      "city": "Madras",
      "state": "Oregon",
      "country": "US",
      "elevation": 1790,
      "lat": 44.7843017578,
      "lon": -120.9550018311,
      "tz": "America\/Los_Angeles"
  },
  "72TA": {
      "icao": "72TA",
      "iata": "",
      "name": "Pierce Field",
      "city": "Port O'Connor",
      "state": "Texas",
      "country": "US",
      "elevation": 5,
      "lat": 28.4585990906,
      "lon": -96.2919006348,
      "tz": "America\/Chicago"
  },
  "72TE": {
      "icao": "72TE",
      "iata": "",
      "name": "Chaparrosa Ranch Airport",
      "city": "La Pryor",
      "state": "Texas",
      "country": "US",
      "elevation": 680,
      "lat": 28.8794002533,
      "lon": -99.9906005859,
      "tz": "America\/Chicago"
  },
  "72TS": {
      "icao": "72TS",
      "iata": "",
      "name": "Galaxy Ranch Airport",
      "city": "Sheridan",
      "state": "Texas",
      "country": "US",
      "elevation": 178,
      "lat": 29.3607997894,
      "lon": -96.6224975586,
      "tz": "America\/Chicago"
  },
  "72WI": {
      "icao": "72WI",
      "iata": "",
      "name": "Windhaven Airport",
      "city": "Collins",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 910,
      "lat": 44.0461006165,
      "lon": -87.9928970337,
      "tz": "America\/Chicago"
  },
  "72XS": {
      "icao": "72XS",
      "iata": "",
      "name": "El Paisano Airport",
      "city": "Round Top",
      "state": "Texas",
      "country": "US",
      "elevation": 340,
      "lat": 30.0335998535,
      "lon": -96.6847000122,
      "tz": "America\/Chicago"
  },
  "73AR": {
      "icao": "73AR",
      "iata": "",
      "name": "Dogwood Airport",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 280,
      "lat": 35.048500061,
      "lon": -92.0537033081,
      "tz": "America\/Chicago"
  },
  "73AZ": {
      "icao": "73AZ",
      "iata": "",
      "name": "Dunton Ranch Airport",
      "city": "Kingman",
      "state": "Arizona",
      "country": "US",
      "elevation": 5115,
      "lat": 35.2853012085,
      "lon": -113.361000061,
      "tz": "America\/Phoenix"
  },
  "73CA": {
      "icao": "73CA",
      "iata": "",
      "name": "Bear Valley STOLport",
      "city": "Bear Valley",
      "state": "California",
      "country": "US",
      "elevation": 7073,
      "lat": 38.4588012695,
      "lon": -120.0400009155,
      "tz": "America\/Los_Angeles"
  },
  "73CL": {
      "icao": "73CL",
      "iata": "",
      "name": "Julian Hinds Pump Plant Airstrip",
      "city": "Desert Center",
      "state": "California",
      "country": "US",
      "elevation": 1335,
      "lat": 33.6988983154,
      "lon": -115.6370010376,
      "tz": "America\/Los_Angeles"
  },
  "73CO": {
      "icao": "73CO",
      "iata": "",
      "name": "Woods STOLport",
      "city": "Montrose",
      "state": "Colorado",
      "country": "US",
      "elevation": 6120,
      "lat": 38.3889007568,
      "lon": -107.8229980469,
      "tz": "America\/Denver"
  },
  "73FD": {
      "icao": "73FD",
      "iata": "",
      "name": "Brookins Air Strip",
      "city": "Chiefland",
      "state": "Florida",
      "country": "US",
      "elevation": 45,
      "lat": 29.510799408,
      "lon": -82.8600997925,
      "tz": "America\/New_York"
  },
  "73IA": {
      "icao": "73IA",
      "iata": "",
      "name": "Harris Field",
      "city": "Mason City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1180,
      "lat": 43.23559952,
      "lon": -93.20829773,
      "tz": "America\/Chicago"
  },
  "73II": {
      "icao": "73II",
      "iata": "",
      "name": "Nulltown Wingnuts Ultralightport",
      "city": "Connersville",
      "state": "Indiana",
      "country": "US",
      "elevation": 765,
      "lat": 39.5717010498,
      "lon": -85.1585998535,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "73IN": {
      "icao": "73IN",
      "iata": "",
      "name": "Benham Airport",
      "city": "Lyons",
      "state": "Indiana",
      "country": "US",
      "elevation": 500,
      "lat": 38.9766998291,
      "lon": -87.0224990845,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "73KS": {
      "icao": "73KS",
      "iata": "",
      "name": "Philip Ranch Airport",
      "city": "Hays",
      "state": "Kansas",
      "country": "US",
      "elevation": 1923,
      "lat": 38.7806015015,
      "lon": -99.2211990356,
      "tz": "America\/Chicago"
  },
  "73KY": {
      "icao": "73KY",
      "iata": "",
      "name": "Brooks Field",
      "city": "Brooks",
      "state": "Kentucky",
      "country": "US",
      "elevation": 480,
      "lat": 38.0332984924,
      "lon": -85.8007965088,
      "tz": "America\/Kentucky\/Louisville"
  },
  "73MN": {
      "icao": "73MN",
      "iata": "",
      "name": "Paynes Airport",
      "city": "DeGraff",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1035,
      "lat": 45.1353988647,
      "lon": -95.5167007446,
      "tz": "America\/Chicago"
  },
  "73MU": {
      "icao": "73MU",
      "iata": "",
      "name": "Sky-Go Farms Airport",
      "city": "Fulton",
      "state": "Missouri",
      "country": "US",
      "elevation": 878,
      "lat": 38.8856010437,
      "lon": -91.9691009521,
      "tz": "America\/Chicago"
  },
  "73ND": {
      "icao": "73ND",
      "iata": "",
      "name": "M Heart Ranch Airport",
      "city": "Nortonville",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1535,
      "lat": 46.5716018677,
      "lon": -98.6884002686,
      "tz": "America\/Chicago"
  },
  "73NE": {
      "icao": "73NE",
      "iata": "",
      "name": "Bornmann Field",
      "city": "Waverly",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1180,
      "lat": 41.0266990662,
      "lon": -96.5306015015,
      "tz": "America\/Chicago"
  },
  "73NY": {
      "icao": "73NY",
      "iata": "",
      "name": "Olmstead Landing Strip",
      "city": "Masonville",
      "state": "New York",
      "country": "US",
      "elevation": 1160,
      "lat": 42.265767,
      "lon": -75.310033,
      "tz": "America\/New_York"
  },
  "73OH": {
      "icao": "73OH",
      "iata": "",
      "name": "Thompson Drag Raceway Airport",
      "city": "Thompson",
      "state": "Ohio",
      "country": "US",
      "elevation": 1100,
      "lat": 41.6469993591,
      "lon": -81.0086975098,
      "tz": "America\/New_York"
  },
  "73OI": {
      "icao": "73OI",
      "iata": "",
      "name": "Rick's Airport",
      "city": "Montville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1265,
      "lat": 41.5745010376,
      "lon": -81.0756988525,
      "tz": "America\/New_York"
  },
  "73OK": {
      "icao": "73OK",
      "iata": "",
      "name": "Haxton Airport",
      "city": "Leedey",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2050,
      "lat": 35.8456001282,
      "lon": -99.3373031616,
      "tz": "America\/Chicago"
  },
  "73OR": {
      "icao": "73OR",
      "iata": "",
      "name": "Ribbon Ridge Airport",
      "city": "Newberg",
      "state": "Oregon",
      "country": "US",
      "elevation": 490,
      "lat": 45.3703994751,
      "lon": -123.0650024414,
      "tz": "America\/Los_Angeles"
  },
  "73PA": {
      "icao": "73PA",
      "iata": "",
      "name": "Gilbert Airport",
      "city": "York",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 530,
      "lat": 39.9295005798,
      "lon": -76.7707977295,
      "tz": "America\/New_York"
  },
  "73PN": {
      "icao": "73PN",
      "iata": "",
      "name": "Finkhaven Airport",
      "city": "Trout Run",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 640,
      "lat": 41.3684005737,
      "lon": -77.0710983276,
      "tz": "America\/New_York"
  },
  "73TA": {
      "icao": "73TA",
      "iata": "",
      "name": "Salado Airport",
      "city": "Salado",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 30.9207000732,
      "lon": -97.5397033691,
      "tz": "America\/Chicago"
  },
  "73TE": {
      "icao": "73TE",
      "iata": "",
      "name": "Moore Private Airport",
      "city": "Azle",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 32.9249992371,
      "lon": -97.5582962036,
      "tz": "America\/Chicago"
  },
  "73TX": {
      "icao": "73TX",
      "iata": "",
      "name": "Frerich Ranch Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 995,
      "lat": 29.1718997955,
      "lon": -100.641998291,
      "tz": "America\/Chicago"
  },
  "73WA": {
      "icao": "73WA",
      "iata": "",
      "name": "7 Bays Airport",
      "city": "Davenport",
      "state": "Washington",
      "country": "US",
      "elevation": 1580,
      "lat": 47.8510017395,
      "lon": -118.3300018311,
      "tz": "America\/Los_Angeles"
  },
  "73WI": {
      "icao": "73WI",
      "iata": "",
      "name": "Vetterkind Strip",
      "city": "New Auburn",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1080,
      "lat": 45.2405014038,
      "lon": -91.5087966919,
      "tz": "America\/Chicago"
  },
  "73WT": {
      "icao": "73WT",
      "iata": "",
      "name": "Mc Gregor Airport",
      "city": "Hooper",
      "state": "Washington",
      "country": "US",
      "elevation": 1800,
      "lat": 46.723333,
      "lon": -118.099444,
      "tz": "America\/Los_Angeles"
  },
  "73XS": {
      "icao": "73XS",
      "iata": "",
      "name": "Dearing Ranch Airport",
      "city": "Gordon",
      "state": "Texas",
      "country": "US",
      "elevation": 1055,
      "lat": 32.4459991455,
      "lon": -98.3712005615,
      "tz": "America\/Chicago"
  },
  "74AK": {
      "icao": "74AK",
      "iata": "",
      "name": "Puviaq Airport",
      "city": "Barrow",
      "state": "Alaska",
      "country": "US",
      "elevation": 23,
      "lat": 70.7155990601,
      "lon": -154.4069976807,
      "tz": "America\/Anchorage"
  },
  "74AZ": {
      "icao": "74AZ",
      "iata": "",
      "name": "Rancho San Marcos Airport",
      "city": "Tombstone",
      "state": "Arizona",
      "country": "US",
      "elevation": 4353,
      "lat": 31.619611,
      "lon": -110.047361,
      "tz": "America\/Phoenix"
  },
  "74CL": {
      "icao": "74CL",
      "iata": "",
      "name": "Di Giorgio Ranch Landing Strip",
      "city": "Di Giorgio",
      "state": "California",
      "country": "US",
      "elevation": 517,
      "lat": 35.2569007874,
      "lon": -118.8330001831,
      "tz": "America\/Los_Angeles"
  },
  "74CO": {
      "icao": "74CO",
      "iata": "",
      "name": "Westberg-Rosling Farms Airport",
      "city": "Roggen",
      "state": "Colorado",
      "country": "US",
      "elevation": 4857,
      "lat": 40.0471992493,
      "lon": -104.2089996338,
      "tz": "America\/Denver"
  },
  "74FD": {
      "icao": "74FD",
      "iata": "",
      "name": "Clarksville Airport",
      "city": "Clarksville",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 30.464099884,
      "lon": -85.1771011353,
      "tz": "America\/Chicago"
  },
  "74FL": {
      "icao": "74FL",
      "iata": "",
      "name": "Jefferson Landings Airport",
      "city": "Monticello",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 30.5846004486,
      "lon": -83.707901001,
      "tz": "America\/New_York"
  },
  "74GA": {
      "icao": "74GA",
      "iata": "",
      "name": "Bowens Mill Christian Center Airport",
      "city": "Fitzgerald",
      "state": "Georgia",
      "country": "US",
      "elevation": 362,
      "lat": 31.8346004486,
      "lon": -83.21849823,
      "tz": "America\/New_York"
  },
  "74IN": {
      "icao": "74IN",
      "iata": "",
      "name": "Tucker Farms Airport",
      "city": "Montpelier",
      "state": "Indiana",
      "country": "US",
      "elevation": 865,
      "lat": 40.5684013367,
      "lon": -85.3443984985,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "74KS": {
      "icao": "74KS",
      "iata": "",
      "name": "Ensminger Airport",
      "city": "Moran",
      "state": "Kansas",
      "country": "US",
      "elevation": 1070,
      "lat": 37.9333992004,
      "lon": -95.2172012329,
      "tz": "America\/Chicago"
  },
  "74KY": {
      "icao": "74KY",
      "iata": "",
      "name": "Terry Field",
      "city": "Cunningham",
      "state": "Kentucky",
      "country": "US",
      "elevation": 370,
      "lat": 36.8987007141,
      "lon": -88.8467025757,
      "tz": "America\/Chicago"
  },
  "74MN": {
      "icao": "74MN",
      "iata": "",
      "name": "Frisch Personal Airport",
      "city": "Dumont",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1046,
      "lat": 45.6972007751,
      "lon": -96.4313964844,
      "tz": "America\/Chicago"
  },
  "74MU": {
      "icao": "74MU",
      "iata": "",
      "name": "Koala-T-Field Airport",
      "city": "Hawk Point",
      "state": "Missouri",
      "country": "US",
      "elevation": 690,
      "lat": 39.0400009155,
      "lon": -91.1304016113,
      "tz": "America\/Chicago"
  },
  "74NC": {
      "icao": "74NC",
      "iata": "",
      "name": "Benton Farm Airport",
      "city": "Whartonville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 9,
      "lat": 35.0964012146,
      "lon": -76.629699707,
      "tz": "America\/New_York"
  },
  "74NY": {
      "icao": "74NY",
      "iata": "",
      "name": "New Salem Aerodrome",
      "city": "Farmington",
      "state": "New York",
      "country": "US",
      "elevation": 660,
      "lat": 43.0055999756,
      "lon": -77.2913970947,
      "tz": "America\/New_York"
  },
  "74OK": {
      "icao": "74OK",
      "iata": "",
      "name": "W.G. Anderson Memorial Airport",
      "city": "Marietta",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 825,
      "lat": 33.9500999451,
      "lon": -97.1502990723,
      "tz": "America\/Chicago"
  },
  "74OR": {
      "icao": "74OR",
      "iata": "",
      "name": "Stan Jost Airport",
      "city": "Newberg",
      "state": "Oregon",
      "country": "US",
      "elevation": 180,
      "lat": 45.3236999512,
      "lon": -123.0189971924,
      "tz": "America\/Los_Angeles"
  },
  "74PA": {
      "icao": "74PA",
      "iata": "",
      "name": "Shontz Airport",
      "city": "Zieglerville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 300,
      "lat": 40.2918014526,
      "lon": -75.4663009644,
      "tz": "America\/New_York"
  },
  "74TA": {
      "icao": "74TA",
      "iata": "",
      "name": "Glass Ranch Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 28.4503002167,
      "lon": -100.1500015259,
      "tz": "America\/Chicago"
  },
  "74TE": {
      "icao": "74TE",
      "iata": "",
      "name": "Flat Top Ranch Airport",
      "city": "Walnut Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 960,
      "lat": 32.05989838,
      "lon": -97.79470062,
      "tz": "America\/Chicago"
  },
  "74TX": {
      "icao": "74TX",
      "iata": "",
      "name": "Fort Clark Springs Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 1106,
      "lat": 29.2866001129,
      "lon": -100.4339981079,
      "tz": "America\/Chicago"
  },
  "74WA": {
      "icao": "74WA",
      "iata": "",
      "name": "Mattawa Air Strip",
      "city": "Mattawa",
      "state": "Washington",
      "country": "US",
      "elevation": 750,
      "lat": 46.7326011658,
      "lon": -119.702003479,
      "tz": "America\/Los_Angeles"
  },
  "74WT": {
      "icao": "74WT",
      "iata": "",
      "name": "Blowout Bench Airport",
      "city": "Ellensburg",
      "state": "Washington",
      "country": "US",
      "elevation": 2065,
      "lat": 47.111011,
      "lon": -120.710742,
      "tz": "America\/Los_Angeles"
  },
  "74XA": {
      "icao": "74XA",
      "iata": "",
      "name": "Gun Barrel City Airpark",
      "city": "",
      "state": "Texas",
      "country": "US",
      "elevation": 385,
      "lat": 32.3551499558,
      "lon": -96.1456650496,
      "tz": "America\/Chicago"
  },
  "74XS": {
      "icao": "74XS",
      "iata": "",
      "name": "Charles J Hughes Ranch Airport",
      "city": "Big Lake",
      "state": "Texas",
      "country": "US",
      "elevation": 2520,
      "lat": 31.418800354,
      "lon": -101.4820022583,
      "tz": "America\/Chicago"
  },
  "75AK": {
      "icao": "75AK",
      "iata": "",
      "name": "Henley Airport",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 60.593067,
      "lon": -151.327956,
      "tz": "America\/Anchorage"
  },
  "75AR": {
      "icao": "75AR",
      "iata": "",
      "name": "The Bluffs Airport",
      "city": "Henderson",
      "state": "Arkansas",
      "country": "US",
      "elevation": 782,
      "lat": 36.2961997986,
      "lon": -92.216003418,
      "tz": "America\/Chicago"
  },
  "75CL": {
      "icao": "75CL",
      "iata": "",
      "name": "Willis Ranch Airport",
      "city": "Dos Palos",
      "state": "California",
      "country": "US",
      "elevation": 115,
      "lat": 37.0654983521,
      "lon": -120.5670013428,
      "tz": "America\/Los_Angeles"
  },
  "75DC": {
      "icao": "75DC",
      "iata": "",
      "name": "Oakesdale Airport",
      "city": "Oakesdale",
      "state": "Washington",
      "country": "US",
      "elevation": 2680,
      "lat": 47.1416015625,
      "lon": -117.236000061,
      "tz": "America\/Los_Angeles"
  },
  "75FL": {
      "icao": "75FL",
      "iata": "",
      "name": "Sandy Creek Airpark",
      "city": "Panama City",
      "state": "Florida",
      "country": "US",
      "elevation": 13,
      "lat": 30.102399826,
      "lon": -85.4624023438,
      "tz": "America\/Chicago"
  },
  "75II": {
      "icao": "75II",
      "iata": "",
      "name": "Lowells Landing Airport",
      "city": "Hardinsburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 700,
      "lat": 38.4467010498,
      "lon": -86.2608032227,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "75IS": {
      "icao": "75IS",
      "iata": "",
      "name": "Reinke Airport",
      "city": "Rochelle",
      "state": "Illinois",
      "country": "US",
      "elevation": 790,
      "lat": 41.8992004395,
      "lon": -89.1704025269,
      "tz": "America\/Chicago"
  },
  "75KS": {
      "icao": "75KS",
      "iata": "",
      "name": "Esplund Farm Airport",
      "city": "Minneola",
      "state": "Kansas",
      "country": "US",
      "elevation": 2555,
      "lat": 37.3866996765,
      "lon": -99.8653030396,
      "tz": "America\/Chicago"
  },
  "75KY": {
      "icao": "75KY",
      "iata": "",
      "name": "Hisle Field",
      "city": "Winchester",
      "state": "Kentucky",
      "country": "US",
      "elevation": 770,
      "lat": 37.9441986084,
      "lon": -83.9978027344,
      "tz": "America\/New_York"
  },
  "75LL": {
      "icao": "75LL",
      "iata": "",
      "name": "Hausmann Airport",
      "city": "Wyoming",
      "state": "Illinois",
      "country": "US",
      "elevation": 748,
      "lat": 41.0835990906,
      "lon": -89.7582015991,
      "tz": "America\/Chicago"
  },
  "75MI": {
      "icao": "75MI",
      "iata": "",
      "name": "Norton Field",
      "city": "Riley",
      "state": "Michigan",
      "country": "US",
      "elevation": 795,
      "lat": 42.9714012146,
      "lon": -82.8478012085,
      "tz": "America\/Detroit"
  },
  "75MN": {
      "icao": "75MN",
      "iata": "",
      "name": "Galler's Airport",
      "city": "Elysian",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1070,
      "lat": 44.1865997314,
      "lon": -93.7141036987,
      "tz": "America\/Chicago"
  },
  "75NC": {
      "icao": "75NC",
      "iata": "",
      "name": "Jw Stone Airport",
      "city": "Middlesex",
      "state": "North Carolina",
      "country": "US",
      "elevation": 246,
      "lat": 35.8493003845,
      "lon": -78.1836013794,
      "tz": "America\/New_York"
  },
  "75ND": {
      "icao": "75ND",
      "iata": "",
      "name": "Jurgens Airstrip",
      "city": "Taylor",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2370,
      "lat": 46.9075012207,
      "lon": -102.4909973145,
      "tz": "America\/Denver"
  },
  "75NE": {
      "icao": "75NE",
      "iata": "",
      "name": "Heaton Airport",
      "city": "Fort Calhoun",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1000,
      "lat": 41.4458007813,
      "lon": -95.956703186,
      "tz": "America\/Chicago"
  },
  "75NY": {
      "icao": "75NY",
      "iata": "",
      "name": "Reiss Game Farm Airport",
      "city": "Olean",
      "state": "New York",
      "country": "US",
      "elevation": 1698,
      "lat": 42.1166992188,
      "lon": -78.3155975342,
      "tz": "America\/New_York"
  },
  "75OH": {
      "icao": "75OH",
      "iata": "",
      "name": "Howensting Airport",
      "city": "Hicksville",
      "state": "Ohio",
      "country": "US",
      "elevation": 740,
      "lat": 41.2592010498,
      "lon": -84.7936019897,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "75OI": {
      "icao": "75OI",
      "iata": "",
      "name": "Soltis Field",
      "city": "Marion",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 40.5037002563,
      "lon": -83.0902023315,
      "tz": "America\/New_York"
  },
  "75OK": {
      "icao": "75OK",
      "iata": "",
      "name": "Longhorn Field",
      "city": "Enid",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1105,
      "lat": 36.317199707,
      "lon": -97.8161010742,
      "tz": "America\/Chicago"
  },
  "75PA": {
      "icao": "75PA",
      "iata": "",
      "name": "Marther Field",
      "city": "Albion",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 950,
      "lat": 41.8731002808,
      "lon": -80.4589996338,
      "tz": "America\/New_York"
  },
  "75TA": {
      "icao": "75TA",
      "iata": "",
      "name": "Coleman Cattle Company Nr 1 Airport",
      "city": "Ackerly",
      "state": "Texas",
      "country": "US",
      "elevation": 2820,
      "lat": 32.5343017578,
      "lon": -101.7249984741,
      "tz": "America\/Chicago"
  },
  "75TE": {
      "icao": "75TE",
      "iata": "",
      "name": "Womack Farm Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 31.7474002838,
      "lon": -97.2461013794,
      "tz": "America\/Chicago"
  },
  "75TN": {
      "icao": "75TN",
      "iata": "",
      "name": "Srigley Field Airstrip",
      "city": "Greeneville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1145,
      "lat": 36.281389,
      "lon": -82.861389,
      "tz": "America\/New_York"
  },
  "75TS": {
      "icao": "75TS",
      "iata": "",
      "name": "Venus Airport",
      "city": "Venus",
      "state": "Texas",
      "country": "US",
      "elevation": 665,
      "lat": 32.4164009094,
      "lon": -97.0919036865,
      "tz": "America\/Chicago"
  },
  "75TX": {
      "icao": "75TX",
      "iata": "",
      "name": "Leona Ranch Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 1720,
      "lat": 29.5002002716,
      "lon": -100.3669967651,
      "tz": "America\/Chicago"
  },
  "75WA": {
      "icao": "75WA",
      "iata": "",
      "name": "Port Elsner Airport",
      "city": "White Salmon",
      "state": "Washington",
      "country": "US",
      "elevation": 610,
      "lat": 45.8021011353,
      "lon": -121.4800033569,
      "tz": "America\/Los_Angeles"
  },
  "75WI": {
      "icao": "75WI",
      "iata": "",
      "name": "Smies Airport",
      "city": "Cedar Grove",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 640,
      "lat": 43.5889015198,
      "lon": -87.7843017578,
      "tz": "America\/Chicago"
  },
  "75WT": {
      "icao": "75WT",
      "iata": "",
      "name": "Dickson Field",
      "city": "Oroville",
      "state": "Washington",
      "country": "US",
      "elevation": 3214,
      "lat": 48.984167,
      "lon": -119.298333,
      "tz": "America\/Los_Angeles"
  },
  "75WV": {
      "icao": "75WV",
      "iata": "",
      "name": "Nicholson Airport",
      "city": "West Union",
      "state": "West Virginia",
      "country": "US",
      "elevation": 1008,
      "lat": 39.1659,
      "lon": -80.650806,
      "tz": "America\/New_York"
  },
  "75XS": {
      "icao": "75XS",
      "iata": "",
      "name": "Triple R Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 510,
      "lat": 29.2493991852,
      "lon": -98.4760971069,
      "tz": "America\/Chicago"
  },
  "76AK": {
      "icao": "76AK",
      "iata": "",
      "name": "Pogo Mine Airstrip",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1350,
      "lat": 64.4674987793,
      "lon": -144.921005249,
      "tz": "America\/Anchorage"
  },
  "76CL": {
      "icao": "76CL",
      "iata": "",
      "name": "Emmett Field",
      "city": "Dos Palos",
      "state": "California",
      "country": "US",
      "elevation": 135,
      "lat": 37.0887985229,
      "lon": -120.5009994507,
      "tz": "America\/Los_Angeles"
  },
  "76CO": {
      "icao": "76CO",
      "iata": "",
      "name": "Hoy Airstrip",
      "city": "Bennett",
      "state": "Colorado",
      "country": "US",
      "elevation": 5310,
      "lat": 39.8358001709,
      "lon": -104.4219970703,
      "tz": "America\/Denver"
  },
  "76FD": {
      "icao": "76FD",
      "iata": "",
      "name": "Peavy Farms Airport",
      "city": "Havana",
      "state": "Florida",
      "country": "US",
      "elevation": 270,
      "lat": 30.6704998016,
      "lon": -84.414100647,
      "tz": "America\/New_York"
  },
  "76GA": {
      "icao": "76GA",
      "iata": "",
      "name": "Landings East Sylvania Airport",
      "city": "Sylvania",
      "state": "Georgia",
      "country": "US",
      "elevation": 155,
      "lat": 32.7447013855,
      "lon": -81.6130981445,
      "tz": "America\/New_York"
  },
  "76II": {
      "icao": "76II",
      "iata": "",
      "name": "Ries Airport",
      "city": "Laotto",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 41.2764015198,
      "lon": -85.2711029053,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "76IN": {
      "icao": "76IN",
      "iata": "",
      "name": "Krebs Airport",
      "city": "Boswell",
      "state": "Indiana",
      "country": "US",
      "elevation": 780,
      "lat": 40.5391998291,
      "lon": -87.4694976807,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "76KS": {
      "icao": "76KS",
      "iata": "",
      "name": "Prima Airport",
      "city": "Scott City",
      "state": "Kansas",
      "country": "US",
      "elevation": 2915,
      "lat": 38.2583999634,
      "lon": -100.8799972534,
      "tz": "America\/Chicago"
  },
  "76KY": {
      "icao": "76KY",
      "iata": "",
      "name": "Buzzard's Roost Airport",
      "city": "Lawrenceburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 770,
      "lat": 38.0148010254,
      "lon": -84.8415985107,
      "tz": "America\/New_York"
  },
  "76MI": {
      "icao": "76MI",
      "iata": "",
      "name": "Kozal Airfield",
      "city": "Sparta",
      "state": "Michigan",
      "country": "US",
      "elevation": 775,
      "lat": 43.1333007812,
      "lon": -85.7249984741,
      "tz": "America\/Detroit"
  },
  "76MN": {
      "icao": "76MN",
      "iata": "",
      "name": "Hemmingsen Private Airport",
      "city": "Erskine",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1246,
      "lat": 47.5964012146,
      "lon": -96.021697998,
      "tz": "America\/Chicago"
  },
  "76MO": {
      "icao": "76MO",
      "iata": "",
      "name": "Walnut Creek Airport",
      "city": "Elmer",
      "state": "Missouri",
      "country": "US",
      "elevation": 735,
      "lat": 39.9693984985,
      "lon": -92.6968994141,
      "tz": "America\/Chicago"
  },
  "76NC": {
      "icao": "76NC",
      "iata": "",
      "name": "Dunroamin Farms Airport",
      "city": "Enfield",
      "state": "North Carolina",
      "country": "US",
      "elevation": 100,
      "lat": 36.1599006653,
      "lon": -77.6247024536,
      "tz": "America\/New_York"
  },
  "76OI": {
      "icao": "76OI",
      "iata": "",
      "name": "Hull Airport",
      "city": "Bettsville",
      "state": "Ohio",
      "country": "US",
      "elevation": 687,
      "lat": 41.2573013306,
      "lon": -83.2060012817,
      "tz": "America\/New_York"
  },
  "76OK": {
      "icao": "76OK",
      "iata": "",
      "name": "Odom's Roost Airport",
      "city": "Newcastle",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1240,
      "lat": 35.2848014832,
      "lon": -97.6169967651,
      "tz": "America\/Chicago"
  },
  "76TA": {
      "icao": "76TA",
      "iata": "",
      "name": "Coleman Cattle Company Nr 2 Airport",
      "city": "Adrian",
      "state": "Texas",
      "country": "US",
      "elevation": 3820,
      "lat": 35.4016990662,
      "lon": -102.8079986572,
      "tz": "America\/Chicago"
  },
  "76TE": {
      "icao": "76TE",
      "iata": "",
      "name": "Big Tank Ranch Airport",
      "city": "Salt Flat",
      "state": "Texas",
      "country": "US",
      "elevation": 4800,
      "lat": 31.5000991821,
      "lon": -105.0839996338,
      "tz": "America\/Denver"
  },
  "76TX": {
      "icao": "76TX",
      "iata": "",
      "name": "Spanish Oaks Airport",
      "city": "Little Elm",
      "state": "Texas",
      "country": "US",
      "elevation": 580,
      "lat": 33.1473007202,
      "lon": -96.9757995605,
      "tz": "America\/Chicago"
  },
  "76WA": {
      "icao": "76WA",
      "iata": "",
      "name": "Heineck Farm Airport",
      "city": "Everett",
      "state": "Washington",
      "country": "US",
      "elevation": 10,
      "lat": 48.0051002502,
      "lon": -122.1340026855,
      "tz": "America\/Los_Angeles"
  },
  "77AK": {
      "icao": "77AK",
      "iata": "",
      "name": "Tidewater Bluffs Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 80,
      "lat": 61.5027008057,
      "lon": -149.595993042,
      "tz": "America\/Anchorage"
  },
  "77CA": {
      "icao": "77CA",
      "iata": "",
      "name": "Daulton Airport",
      "city": "Madera",
      "state": "California",
      "country": "US",
      "elevation": 425,
      "lat": 37.1208000183,
      "lon": -119.9879989624,
      "tz": "America\/Los_Angeles"
  },
  "77CL": {
      "icao": "77CL",
      "iata": "",
      "name": "Baker & Hall Airport",
      "city": "Dunlap",
      "state": "California",
      "country": "US",
      "elevation": 2150,
      "lat": 36.7177009583,
      "lon": -119.1370010376,
      "tz": "America\/Los_Angeles"
  },
  "77GA": {
      "icao": "77GA",
      "iata": "",
      "name": "Toland Airport",
      "city": "Milner",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.1640014648,
      "lon": -84.2135009766,
      "tz": "America\/New_York"
  },
  "77KS": {
      "icao": "77KS",
      "iata": "",
      "name": "Abel Ranch Airport",
      "city": "Wilmont",
      "state": "Kansas",
      "country": "US",
      "elevation": 1320,
      "lat": 37.3675003052,
      "lon": -96.87840271,
      "tz": "America\/Chicago"
  },
  "77KY": {
      "icao": "77KY",
      "iata": "",
      "name": "One Oak Airport",
      "city": "Mount Sterling\/Owingsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1000,
      "lat": 38.1111984253,
      "lon": -83.8734970093,
      "tz": "America\/New_York"
  },
  "77LL": {
      "icao": "77LL",
      "iata": "",
      "name": "Briggs Brothers Airfield",
      "city": "Galena",
      "state": "Illinois",
      "country": "US",
      "elevation": 770,
      "lat": 42.4235992432,
      "lon": -90.5043029785,
      "tz": "America\/Chicago"
  },
  "77MI": {
      "icao": "77MI",
      "iata": "",
      "name": "David's Airport",
      "city": "Athens",
      "state": "Michigan",
      "country": "US",
      "elevation": 887,
      "lat": 42.0652999878,
      "lon": -85.2416000366,
      "tz": "America\/Detroit"
  },
  "77MN": {
      "icao": "77MN",
      "iata": "",
      "name": "Klamar Field",
      "city": "Gatzke",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1160,
      "lat": 48.3713989258,
      "lon": -95.7881011963,
      "tz": "America\/Chicago"
  },
  "77MO": {
      "icao": "77MO",
      "iata": "",
      "name": "Springhill Airport",
      "city": "Mount Vernon",
      "state": "Missouri",
      "country": "US",
      "elevation": 1305,
      "lat": 37.0903015137,
      "lon": -93.7694015503,
      "tz": "America\/Chicago"
  },
  "77NC": {
      "icao": "77NC",
      "iata": "",
      "name": "Winnabow Airport",
      "city": "Winnabow",
      "state": "North Carolina",
      "country": "US",
      "elevation": 33,
      "lat": 34.1520996094,
      "lon": -78.1054992676,
      "tz": "America\/New_York"
  },
  "77NY": {
      "icao": "77NY",
      "iata": "",
      "name": "Pendleton Airpark",
      "city": "Pendleton",
      "state": "New York",
      "country": "US",
      "elevation": 582,
      "lat": 43.0820007324,
      "lon": -78.779800415,
      "tz": "America\/New_York"
  },
  "77OH": {
      "icao": "77OH",
      "iata": "",
      "name": "Collins-Flege Airpark",
      "city": "Mason",
      "state": "Ohio",
      "country": "US",
      "elevation": 860,
      "lat": 39.3628005981,
      "lon": -84.3413009644,
      "tz": "America\/New_York"
  },
  "77PA": {
      "icao": "77PA",
      "iata": "",
      "name": "Nardo Airport",
      "city": "Allison Park",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1140,
      "lat": 40.5834007263,
      "lon": -79.8998031616,
      "tz": "America\/New_York"
  },
  "77PN": {
      "icao": "77PN",
      "iata": "",
      "name": "Gilfert Airport",
      "city": "Benfer",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 630,
      "lat": 40.7840003967,
      "lon": -77.2065963745,
      "tz": "America\/New_York"
  },
  "77TA": {
      "icao": "77TA",
      "iata": "",
      "name": "Blue Skies Airport",
      "city": "Azle",
      "state": "Texas",
      "country": "US",
      "elevation": 960,
      "lat": 32.8740005493,
      "lon": -97.6613998413,
      "tz": "America\/Chicago"
  },
  "77TN": {
      "icao": "77TN",
      "iata": "",
      "name": "Darnell's Field",
      "city": "Church Hill",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1150,
      "lat": 36.5092010498,
      "lon": -82.6817016602,
      "tz": "America\/New_York"
  },
  "77TS": {
      "icao": "77TS",
      "iata": "",
      "name": "Flying R Ranch Airport",
      "city": "New Braunfels",
      "state": "Texas",
      "country": "US",
      "elevation": 850,
      "lat": 29.7994003296,
      "lon": -98.0849990845,
      "tz": "America\/Chicago"
  },
  "77TX": {
      "icao": "77TX",
      "iata": "",
      "name": "Woods Airport",
      "city": "Brookshire",
      "state": "Texas",
      "country": "US",
      "elevation": 160,
      "lat": 29.7816009521,
      "lon": -95.9147033691,
      "tz": "America\/Chicago"
  },
  "77VA": {
      "icao": "77VA",
      "iata": "",
      "name": "Camp Friendship Airfield",
      "city": "Palmyra",
      "state": "Virginia",
      "country": "US",
      "elevation": 345,
      "lat": 37.8881988525,
      "lon": -78.2857971191,
      "tz": "America\/New_York"
  },
  "77WI": {
      "icao": "77WI",
      "iata": "",
      "name": "Foxewood Airport",
      "city": "New Munster",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 760,
      "lat": 42.5717010498,
      "lon": -88.2031021118,
      "tz": "America\/Chicago"
  },
  "77XS": {
      "icao": "77XS",
      "iata": "",
      "name": "Garrett Ranch Airport",
      "city": "Danbury",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 29.2872009277,
      "lon": -95.359703064,
      "tz": "America\/Chicago"
  },
  "78AK": {
      "icao": "78AK",
      "iata": "",
      "name": "C.T.S. Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 160,
      "lat": 61.7331008911,
      "lon": -150.5260009766,
      "tz": "America\/Anchorage"
  },
  "78AR": {
      "icao": "78AR",
      "iata": "",
      "name": "Tucker Field",
      "city": "Hughes",
      "state": "Arkansas",
      "country": "US",
      "elevation": 205,
      "lat": 34.9375991821,
      "lon": -90.4709014893,
      "tz": "America\/Chicago"
  },
  "78FD": {
      "icao": "78FD",
      "iata": "",
      "name": "Mac's Field",
      "city": "Homestead",
      "state": "Florida",
      "country": "US",
      "elevation": 16,
      "lat": 25.5464992523,
      "lon": -80.4655990601,
      "tz": "America\/New_York"
  },
  "78FL": {
      "icao": "78FL",
      "iata": "",
      "name": "Pomona Landing Airport",
      "city": "Pomona Park",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 29.502199173,
      "lon": -81.5783996582,
      "tz": "America\/New_York"
  },
  "78GA": {
      "icao": "78GA",
      "iata": "",
      "name": "C&R Farm Airport",
      "city": "Carrollton",
      "state": "Georgia",
      "country": "US",
      "elevation": 1013,
      "lat": 33.5042991638,
      "lon": -85.0171966553,
      "tz": "America\/New_York"
  },
  "78II": {
      "icao": "78II",
      "iata": "",
      "name": "Fox Station Airport",
      "city": "La Fontaine",
      "state": "Indiana",
      "country": "US",
      "elevation": 835,
      "lat": 40.6361999512,
      "lon": -85.68800354,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "78KS": {
      "icao": "78KS",
      "iata": "",
      "name": "Walter A Swalley Airpark",
      "city": "Baxter Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 840,
      "lat": 37.0167007446,
      "lon": -94.800201416,
      "tz": "America\/Chicago"
  },
  "78LA": {
      "icao": "78LA",
      "iata": "",
      "name": "Heinsohn's Airfield",
      "city": "Shreveport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 230,
      "lat": 32.2667999268,
      "lon": -93.7251968384,
      "tz": "America\/Chicago"
  },
  "78ME": {
      "icao": "78ME",
      "iata": "",
      "name": "Eric's Field Airport",
      "city": "Falmouth",
      "state": "Maine",
      "country": "US",
      "elevation": 97,
      "lat": 43.753056,
      "lon": -70.259167,
      "tz": "America\/New_York"
  },
  "78MI": {
      "icao": "78MI",
      "iata": "",
      "name": "Carls Airport",
      "city": "South Rockwood",
      "state": "Michigan",
      "country": "US",
      "elevation": 598,
      "lat": 42.040599823,
      "lon": -83.3034973145,
      "tz": "America\/Detroit"
  },
  "78MN": {
      "icao": "78MN",
      "iata": "",
      "name": "Hagens Private Airport",
      "city": "Gatzke",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1155,
      "lat": 48.3788986206,
      "lon": -95.8116989136,
      "tz": "America\/Chicago"
  },
  "78MO": {
      "icao": "78MO",
      "iata": "",
      "name": "Staggs Airport",
      "city": "Ewing",
      "state": "Missouri",
      "country": "US",
      "elevation": 660,
      "lat": 39.995300293,
      "lon": -91.6890029907,
      "tz": "America\/Chicago"
  },
  "78MU": {
      "icao": "78MU",
      "iata": "",
      "name": "Stony Branch Airport",
      "city": "Jasper",
      "state": "Missouri",
      "country": "US",
      "elevation": 1025,
      "lat": 37.2963981628,
      "lon": -94.1951980591,
      "tz": "America\/Chicago"
  },
  "78NC": {
      "icao": "78NC",
      "iata": "",
      "name": "Fuquay\/Angier Field",
      "city": "Fuquay\/Varina",
      "state": "North Carolina",
      "country": "US",
      "elevation": 349,
      "lat": 35.5443000793,
      "lon": -78.7474975586,
      "tz": "America\/New_York"
  },
  "78ND": {
      "icao": "78ND",
      "iata": "",
      "name": "Folske Ranch Airport",
      "city": "Bowman",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2900,
      "lat": 46.2949981689,
      "lon": -103.4319992065,
      "tz": "America\/Denver"
  },
  "78NE": {
      "icao": "78NE",
      "iata": "",
      "name": "Stava Airport",
      "city": "Brainard",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1655,
      "lat": 41.1660995483,
      "lon": -97.0314025879,
      "tz": "America\/Chicago"
  },
  "78NY": {
      "icao": "78NY",
      "iata": "",
      "name": "Flying F Airport",
      "city": "Pendleton",
      "state": "New York",
      "country": "US",
      "elevation": 580,
      "lat": 43.0555992126,
      "lon": -78.8161010742,
      "tz": "America\/New_York"
  },
  "78OH": {
      "icao": "78OH",
      "iata": "",
      "name": "Valley City Flying Club Airport",
      "city": "Valley City",
      "state": "Ohio",
      "country": "US",
      "elevation": 850,
      "lat": 41.2569999695,
      "lon": -81.970703125,
      "tz": "America\/New_York"
  },
  "78OR": {
      "icao": "78OR",
      "iata": "",
      "name": "Crowley Ranch Airstrip",
      "city": "Crowley",
      "state": "Oregon",
      "country": "US",
      "elevation": 4128,
      "lat": 43.3078994751,
      "lon": -117.8939971924,
      "tz": "America\/Boise"
  },
  "78TA": {
      "icao": "78TA",
      "iata": "",
      "name": "Ranch-Aero Airport",
      "city": "Bigfoot",
      "state": "Texas",
      "country": "US",
      "elevation": 560,
      "lat": 29.0613994598,
      "lon": -98.8003005981,
      "tz": "America\/Chicago"
  },
  "78TE": {
      "icao": "78TE",
      "iata": "",
      "name": "Rocking A Airport",
      "city": "Whitney",
      "state": "Texas",
      "country": "US",
      "elevation": 635,
      "lat": 32.020401001,
      "lon": -97.3311004639,
      "tz": "America\/Chicago"
  },
  "78VA": {
      "icao": "78VA",
      "iata": "",
      "name": "Hilldale Airport",
      "city": "Stanardsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 490,
      "lat": 38.2463989258,
      "lon": -78.4753036499,
      "tz": "America\/New_York"
  },
  "78WA": {
      "icao": "78WA",
      "iata": "",
      "name": "Center Island Airport",
      "city": "Center Island",
      "state": "Washington",
      "country": "US",
      "elevation": 115,
      "lat": 48.4901008606,
      "lon": -122.8320007324,
      "tz": "America\/Los_Angeles"
  },
  "78XS": {
      "icao": "78XS",
      "iata": "",
      "name": "Smith Aviation Inc Airport",
      "city": "Danevang",
      "state": "Texas",
      "country": "US",
      "elevation": 75,
      "lat": 29.0529994965,
      "lon": -96.2621994019,
      "tz": "America\/Chicago"
  },
  "79AK": {
      "icao": "79AK",
      "iata": "",
      "name": "Huttunen Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 120,
      "lat": 61.481839,
      "lon": -149.744019,
      "tz": "America\/Anchorage"
  },
  "79AR": {
      "icao": "79AR",
      "iata": "",
      "name": "Perrys Airport",
      "city": "Jacksonville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 263,
      "lat": 34.8529014587,
      "lon": -92.0482025146,
      "tz": "America\/Chicago"
  },
  "79CL": {
      "icao": "79CL",
      "iata": "",
      "name": "Milhous Ranch Airport",
      "city": "North San Juan",
      "state": "California",
      "country": "US",
      "elevation": 2625,
      "lat": 39.3249015808,
      "lon": -121.0759963989,
      "tz": "America\/Los_Angeles"
  },
  "79FD": {
      "icao": "79FD",
      "iata": "",
      "name": "Midway Lake Airport",
      "city": "Plant City",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 28.0419998169,
      "lon": -82.092300415,
      "tz": "America\/New_York"
  },
  "79FL": {
      "icao": "79FL",
      "iata": "",
      "name": "Neal Field",
      "city": "Chiefland",
      "state": "Florida",
      "country": "US",
      "elevation": 45,
      "lat": 29.5527000427,
      "lon": -82.8686981201,
      "tz": "America\/New_York"
  },
  "79GA": {
      "icao": "79GA",
      "iata": "",
      "name": "Coates Airport",
      "city": "Fort Gaines",
      "state": "Georgia",
      "country": "US",
      "elevation": 310,
      "lat": 31.4913005829,
      "lon": -85.0166015625,
      "tz": "America\/New_York"
  },
  "79ID": {
      "icao": "79ID",
      "iata": "",
      "name": "Kooskia (Clear Creek Int) Airport",
      "city": "",
      "state": "Idaho",
      "country": "US",
      "elevation": 1800,
      "lat": 46.0488642914,
      "lon": -115.869691372,
      "tz": "America\/Los_Angeles"
  },
  "79II": {
      "icao": "79II",
      "iata": "",
      "name": "Sauer-Harter Airport",
      "city": "Greenfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 930,
      "lat": 39.8833999634,
      "lon": -85.7024993896,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "79IL": {
      "icao": "79IL",
      "iata": "",
      "name": "Miller Airport",
      "city": "Ashton",
      "state": "Illinois",
      "country": "US",
      "elevation": 820,
      "lat": 41.7966995239,
      "lon": -89.2303009033,
      "tz": "America\/Chicago"
  },
  "79IS": {
      "icao": "79IS",
      "iata": "",
      "name": "Hartwell Ranch RLA Restricted Landing Area",
      "city": "Hillview",
      "state": "Illinois",
      "country": "US",
      "elevation": 427,
      "lat": 39.4448013306,
      "lon": -90.5621032715,
      "tz": "America\/Chicago"
  },
  "79KY": {
      "icao": "79KY",
      "iata": "",
      "name": "Lucas Field",
      "city": "Nicholasville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 906,
      "lat": 37.8712997437,
      "lon": -84.610496521,
      "tz": "America\/New_York"
  },
  "79MI": {
      "icao": "79MI",
      "iata": "",
      "name": "Pike River Landing",
      "city": "Chassell",
      "state": "Michigan",
      "country": "US",
      "elevation": 902,
      "lat": 46.980278,
      "lon": -88.54,
      "tz": "America\/Detroit"
  },
  "79MN": {
      "icao": "79MN",
      "iata": "",
      "name": "Paul Field",
      "city": "Gatzke",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1165,
      "lat": 48.3777008057,
      "lon": -95.7544021606,
      "tz": "America\/Chicago"
  },
  "79MO": {
      "icao": "79MO",
      "iata": "",
      "name": "Faries Field",
      "city": "Williamsville",
      "state": "Missouri",
      "country": "US",
      "elevation": 450,
      "lat": 36.9866981506,
      "lon": -90.543296814,
      "tz": "America\/Chicago"
  },
  "79MU": {
      "icao": "79MU",
      "iata": "",
      "name": "Dunham Private Airport",
      "city": "Smithville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.3302993774,
      "lon": -94.556602478,
      "tz": "America\/Chicago"
  },
  "79NC": {
      "icao": "79NC",
      "iata": "",
      "name": "Ball Airport",
      "city": "Louisburg",
      "state": "North Carolina",
      "country": "US",
      "elevation": 366,
      "lat": 36.1343002319,
      "lon": -78.2818984985,
      "tz": "America\/New_York"
  },
  "79ND": {
      "icao": "79ND",
      "iata": "",
      "name": "Reimers Airport",
      "city": "Carrington",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1590,
      "lat": 47.323299408,
      "lon": -99.0011978149,
      "tz": "America\/Chicago"
  },
  "79NY": {
      "icao": "79NY",
      "iata": "",
      "name": "Smith Airport",
      "city": "Pendleton",
      "state": "New York",
      "country": "US",
      "elevation": 580,
      "lat": 43.0870018005,
      "lon": -78.8114013672,
      "tz": "America\/New_York"
  },
  "79OH": {
      "icao": "79OH",
      "iata": "",
      "name": "Smith-Stewart Field",
      "city": "Vienna",
      "state": "Ohio",
      "country": "US",
      "elevation": 1092,
      "lat": 41.2098007202,
      "lon": -80.6830978394,
      "tz": "America\/New_York"
  },
  "79OI": {
      "icao": "79OI",
      "iata": "",
      "name": "Flying J Airport",
      "city": "Pitchin",
      "state": "Ohio",
      "country": "US",
      "elevation": 1070,
      "lat": 39.8172988892,
      "lon": -83.7557983398,
      "tz": "America\/New_York"
  },
  "79OK": {
      "icao": "79OK",
      "iata": "",
      "name": "Little River Ranch Airport",
      "city": "Pickens",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 580,
      "lat": 35.3847999573,
      "lon": -95.176399231,
      "tz": "America\/Chicago"
  },
  "79PA": {
      "icao": "79PA",
      "iata": "",
      "name": "Little Britain Airport",
      "city": "Quarryville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 470,
      "lat": 39.7911987305,
      "lon": -76.1416015625,
      "tz": "America\/New_York"
  },
  "79TA": {
      "icao": "79TA",
      "iata": "",
      "name": "Morris Ranch Airport",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 346,
      "lat": 28.1697006226,
      "lon": -98.8899993896,
      "tz": "America\/Chicago"
  },
  "79TE": {
      "icao": "79TE",
      "iata": "",
      "name": "Arrowhead Airport",
      "city": "Lagarto",
      "state": "Texas",
      "country": "US",
      "elevation": 165,
      "lat": 28.1156005859,
      "lon": -97.9308013916,
      "tz": "America\/Chicago"
  },
  "79TS": {
      "icao": "79TS",
      "iata": "",
      "name": "Tallows Field",
      "city": "Celina",
      "state": "Texas",
      "country": "US",
      "elevation": 729,
      "lat": 33.3042984009,
      "lon": -96.7472000122,
      "tz": "America\/Chicago"
  },
  "79TX": {
      "icao": "79TX",
      "iata": "",
      "name": "Ag-Air Inc. Airport",
      "city": "Brownsville",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 26.0730991364,
      "lon": -97.5374984741,
      "tz": "America\/Chicago"
  },
  "79WA": {
      "icao": "79WA",
      "iata": "",
      "name": "Grigg Farm Airport",
      "city": "Quincy",
      "state": "Washington",
      "country": "US",
      "elevation": 1220,
      "lat": 47.173500061,
      "lon": -119.7470016479,
      "tz": "America\/Los_Angeles"
  },
  "79WT": {
      "icao": "79WT",
      "iata": "",
      "name": "Ellensburg (Rotor Ranch) Airport",
      "city": "",
      "state": "Washington",
      "country": "US",
      "elevation": 1962,
      "lat": 47.0914260595,
      "lon": -120.589778423,
      "tz": "America\/Los_Angeles"
  },
  "79XS": {
      "icao": "79XS",
      "iata": "",
      "name": "Silverton Municipal Airport",
      "city": "Silverton",
      "state": "Texas",
      "country": "US",
      "elevation": 3267,
      "lat": 34.4667015076,
      "lon": -101.3000030518,
      "tz": "America\/Chicago"
  },
  "7AK0": {
      "icao": "7AK0",
      "iata": "",
      "name": "Art Z Airport",
      "city": "Tanana",
      "state": "Alaska",
      "country": "US",
      "elevation": 193,
      "lat": 64.6473999023,
      "lon": -151.8099975586,
      "tz": "America\/Anchorage"
  },
  "7AK2": {
      "icao": "7AK2",
      "iata": "",
      "name": "Snettisham Airport",
      "city": "Snettisham",
      "state": "Alaska",
      "country": "US",
      "elevation": 14,
      "lat": 58.1343994141,
      "lon": -133.7299957275,
      "tz": "America\/Juneau"
  },
  "7AK3": {
      "icao": "7AK3",
      "iata": "",
      "name": "Gaede Airport",
      "city": "Soldotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 115,
      "lat": 60.476398468,
      "lon": -151.1660003662,
      "tz": "America\/Anchorage"
  },
  "7AK4": {
      "icao": "7AK4",
      "iata": "",
      "name": "Jack Fish Landing Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 61.5415000916,
      "lon": -149.4609985352,
      "tz": "America\/Anchorage"
  },
  "7AK5": {
      "icao": "7AK5",
      "iata": "",
      "name": "Dahler Homestead Airport",
      "city": "Soldotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 60.5036010742,
      "lon": -150.9360046387,
      "tz": "America\/Anchorage"
  },
  "7AK6": {
      "icao": "7AK6",
      "iata": "",
      "name": "Dan France Airport",
      "city": "Soldotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 260,
      "lat": 60.4777984619,
      "lon": -150.9290008545,
      "tz": "America\/Anchorage"
  },
  "7AK8": {
      "icao": "7AK8",
      "iata": "",
      "name": "Hess Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 355,
      "lat": 61.6212997437,
      "lon": -149.6150054932,
      "tz": "America\/Anchorage"
  },
  "7AK9": {
      "icao": "7AK9",
      "iata": "",
      "name": "Vinduska Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 700,
      "lat": 61.6468009949,
      "lon": -149.0489959717,
      "tz": "America\/Anchorage"
  },
  "7AL9": {
      "icao": "7AL9",
      "iata": "",
      "name": "Horak Airport",
      "city": "Elberta",
      "state": "Alabama",
      "country": "US",
      "elevation": 110,
      "lat": 30.4668998718,
      "lon": -87.5416030884,
      "tz": "America\/Chicago"
  },
  "7AR0": {
      "icao": "7AR0",
      "iata": "",
      "name": "Hammer Field Airport",
      "city": "Eureka Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1600,
      "lat": 36.4314002991,
      "lon": -93.6992034912,
      "tz": "America\/Chicago"
  },
  "7AR1": {
      "icao": "7AR1",
      "iata": "",
      "name": "Ward's Airport",
      "city": "Eudora",
      "state": "Arkansas",
      "country": "US",
      "elevation": 113,
      "lat": 33.0499992371,
      "lon": -91.3415985107,
      "tz": "America\/Chicago"
  },
  "7AR2": {
      "icao": "7AR2",
      "iata": "",
      "name": "Verser's Landing Airport",
      "city": "Greers Ferry",
      "state": "Arkansas",
      "country": "US",
      "elevation": 610,
      "lat": 35.5572013855,
      "lon": -92.1321029663,
      "tz": "America\/Chicago"
  },
  "7AR3": {
      "icao": "7AR3",
      "iata": "",
      "name": "Runsick Flying Service Airport",
      "city": "Colt",
      "state": "Arkansas",
      "country": "US",
      "elevation": 250,
      "lat": 35.1511001587,
      "lon": -90.8155975342,
      "tz": "America\/Chicago"
  },
  "7AR4": {
      "icao": "7AR4",
      "iata": "",
      "name": "Brown'S Airport",
      "city": "Searcy",
      "state": "Arkansas",
      "country": "US",
      "elevation": 367,
      "lat": 35.292222,
      "lon": -91.89,
      "tz": "America\/Chicago"
  },
  "7CA1": {
      "icao": "7CA1",
      "iata": "",
      "name": "Abraham Ranch Airport",
      "city": "Lucerne Valley",
      "state": "California",
      "country": "US",
      "elevation": 2850,
      "lat": 34.4146995544,
      "lon": -116.6230010986,
      "tz": "America\/Los_Angeles"
  },
  "7CA2": {
      "icao": "7CA2",
      "iata": "",
      "name": "Paradise Lakes Airport",
      "city": "Bakersfield",
      "state": "California",
      "country": "US",
      "elevation": 340,
      "lat": 35.176700592,
      "lon": -118.9270019531,
      "tz": "America\/Los_Angeles"
  },
  "7CA6": {
      "icao": "7CA6",
      "iata": "",
      "name": "West Side Field Station Airport",
      "city": "Five Points",
      "state": "California",
      "country": "US",
      "elevation": 280,
      "lat": 36.3387985229,
      "lon": -120.1100006104,
      "tz": "America\/Los_Angeles"
  },
  "7CL0": {
      "icao": "7CL0",
      "iata": "",
      "name": "Du Bois Ranch Airport",
      "city": "Kerman",
      "state": "California",
      "country": "US",
      "elevation": 195,
      "lat": 36.7036018372,
      "lon": -120.1350021362,
      "tz": "America\/Los_Angeles"
  },
  "7CL8": {
      "icao": "7CL8",
      "iata": "",
      "name": "Geyser Ranch Airport",
      "city": "Ely\/Pioche",
      "state": "Nevada",
      "country": "US",
      "elevation": 5977,
      "lat": 38.664100647,
      "lon": -114.6320037842,
      "tz": "America\/Los_Angeles"
  },
  "7CL9": {
      "icao": "7CL9",
      "iata": "",
      "name": "Perryman Airport",
      "city": "Placerville",
      "state": "California",
      "country": "US",
      "elevation": 2600,
      "lat": 38.6963005066,
      "lon": -120.7310028076,
      "tz": "America\/Los_Angeles"
  },
  "7CO0": {
      "icao": "7CO0",
      "iata": "",
      "name": "Parkland Airport",
      "city": "Erie",
      "state": "Colorado",
      "country": "US",
      "elevation": 5050,
      "lat": 40.0717010498,
      "lon": -105.033996582,
      "tz": "America\/Denver"
  },
  "7CO1": {
      "icao": "7CO1",
      "iata": "",
      "name": "Dave Nash Ranch Airport",
      "city": "Guffey",
      "state": "Colorado",
      "country": "US",
      "elevation": 8400,
      "lat": 38.7597007751,
      "lon": -105.3840026855,
      "tz": "America\/Denver"
  },
  "7CO2": {
      "icao": "7CO2",
      "iata": "",
      "name": "Hawkins Ranch Airport",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 6200,
      "lat": 38.8415985107,
      "lon": -107.8759994507,
      "tz": "America\/Denver"
  },
  "7CO3": {
      "icao": "7CO3",
      "iata": "",
      "name": "Lindys Airpark",
      "city": "Hudson",
      "state": "Colorado",
      "country": "US",
      "elevation": 5040,
      "lat": 40.038898468,
      "lon": -104.6240005493,
      "tz": "America\/Denver"
  },
  "7CO4": {
      "icao": "7CO4",
      "iata": "",
      "name": "Williams Ranch Airport",
      "city": "New Raymer",
      "state": "Colorado",
      "country": "US",
      "elevation": 4860,
      "lat": 40.6041984558,
      "lon": -103.9390029907,
      "tz": "America\/Denver"
  },
  "7CO5": {
      "icao": "7CO5",
      "iata": "",
      "name": "Yocam Ranch Airport",
      "city": "Orchard",
      "state": "Colorado",
      "country": "US",
      "elevation": 4461,
      "lat": 40.2999992371,
      "lon": -104.2300033569,
      "tz": "America\/Denver"
  },
  "7CO6": {
      "icao": "7CO6",
      "iata": "",
      "name": "Vantage View Airport",
      "city": "Rush",
      "state": "Colorado",
      "country": "US",
      "elevation": 5780,
      "lat": 38.760278,
      "lon": -104.101389,
      "tz": "America\/Denver"
  },
  "7CO8": {
      "icao": "7CO8",
      "iata": "",
      "name": "West Pueblo Airport",
      "city": "Pueblo West",
      "state": "Colorado",
      "country": "US",
      "elevation": 5135,
      "lat": 38.3633003235,
      "lon": -104.7379989624,
      "tz": "America\/Denver"
  },
  "7FA1": {
      "icao": "7FA1",
      "iata": "",
      "name": "Sugar Loaf Shores Airport",
      "city": "Key West",
      "state": "Florida",
      "country": "US",
      "elevation": 4,
      "lat": 24.6487998962,
      "lon": -81.5798034668,
      "tz": "America\/New_York"
  },
  "7FA8": {
      "icao": "7FA8",
      "iata": "",
      "name": "Berry Grove Airport",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 28,
      "lat": 26.6956005096,
      "lon": -81.4906005859,
      "tz": "America\/New_York"
  },
  "7FD2": {
      "icao": "7FD2",
      "iata": "",
      "name": "Drake Ranch Airport",
      "city": "Hernando",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 28.9902992249,
      "lon": -82.3303985596,
      "tz": "America\/New_York"
  },
  "7FD6": {
      "icao": "7FD6",
      "iata": "",
      "name": "Loxahatchee Airport",
      "city": "Lake Worth",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 26.6226005554,
      "lon": -80.3052978516,
      "tz": "America\/New_York"
  },
  "7FD9": {
      "icao": "7FD9",
      "iata": "",
      "name": "Estherbrook Aerodrome",
      "city": "Madison",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 30.6243991852,
      "lon": -83.2867965698,
      "tz": "America\/New_York"
  },
  "7FL2": {
      "icao": "7FL2",
      "iata": "",
      "name": "Wellborn STOLport",
      "city": "Wellborn",
      "state": "Florida",
      "country": "US",
      "elevation": 180,
      "lat": 30.2455005646,
      "lon": -82.778503418,
      "tz": "America\/New_York"
  },
  "7FL3": {
      "icao": "7FL3",
      "iata": "",
      "name": "Adams Ranch Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 27.4419994354,
      "lon": -80.584197998,
      "tz": "America\/New_York"
  },
  "7FL4": {
      "icao": "7FL4",
      "iata": "",
      "name": "Haller Airpark",
      "city": "Green Cove Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 29.9029998779,
      "lon": -81.6858978271,
      "tz": "America\/New_York"
  },
  "7FL6": {
      "icao": "7FL6",
      "iata": "",
      "name": "Spruce Creek Airport",
      "city": "Daytona Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 24,
      "lat": 29.0802001953,
      "lon": -81.0466995239,
      "tz": "America\/New_York"
  },
  "7FL7": {
      "icao": "7FL7",
      "iata": "",
      "name": "Lazy S Farm Airport",
      "city": "High Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 29.8686008453,
      "lon": -82.6345977783,
      "tz": "America\/New_York"
  },
  "7FL8": {
      "icao": "7FL8",
      "iata": "",
      "name": "Dragonfly Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 27.4438991547,
      "lon": -80.5651016235,
      "tz": "America\/New_York"
  },
  "7GA2": {
      "icao": "7GA2",
      "iata": "",
      "name": "Mcclellan Airport",
      "city": "Valdosta",
      "state": "Georgia",
      "country": "US",
      "elevation": 229,
      "lat": 30.7789001465,
      "lon": -83.2018966675,
      "tz": "America\/New_York"
  },
  "7GA3": {
      "icao": "7GA3",
      "iata": "",
      "name": "Hacienda De Gay Airstrip",
      "city": "Garfield",
      "state": "Georgia",
      "country": "US",
      "elevation": 328,
      "lat": 32.6632995605,
      "lon": -82.075302124,
      "tz": "America\/New_York"
  },
  "7GA4": {
      "icao": "7GA4",
      "iata": "",
      "name": "Danville Airpark",
      "city": "Danville",
      "state": "Georgia",
      "country": "US",
      "elevation": 459,
      "lat": 32.5810012817,
      "lon": -83.2677001953,
      "tz": "America\/New_York"
  },
  "7GA5": {
      "icao": "7GA5",
      "iata": "",
      "name": "Mount Cove STOLport",
      "city": "Kensington",
      "state": "Georgia",
      "country": "US",
      "elevation": 1000,
      "lat": 34.6323013306,
      "lon": -85.4471969604,
      "tz": "America\/New_York"
  },
  "7GA6": {
      "icao": "7GA6",
      "iata": "",
      "name": "Holder's Field",
      "city": "Locust Grove",
      "state": "Georgia",
      "country": "US",
      "elevation": 740,
      "lat": 33.3614997864,
      "lon": -84.1445999146,
      "tz": "America\/New_York"
  },
  "7GA9": {
      "icao": "7GA9",
      "iata": "",
      "name": "Smisson Field",
      "city": "Whitesburg",
      "state": "Georgia",
      "country": "US",
      "elevation": 1044,
      "lat": 33.5705986023,
      "lon": -84.8977966309,
      "tz": "America\/New_York"
  },
  "7GE1": {
      "icao": "7GE1",
      "iata": "",
      "name": "Davison Ranch Airport",
      "city": "Caldwell",
      "state": "Idaho",
      "country": "US",
      "elevation": 2370,
      "lat": 43.6581993103,
      "lon": -116.7509994507,
      "tz": "America\/Boise"
  },
  "7GE5": {
      "icao": "7GE5",
      "iata": "",
      "name": "Sunset Strip",
      "city": "Centralhatchee",
      "state": "Georgia",
      "country": "US",
      "elevation": 740,
      "lat": 33.3591995239,
      "lon": -85.1175003052,
      "tz": "America\/New_York"
  },
  "7IA1": {
      "icao": "7IA1",
      "iata": "",
      "name": "White Pigeon Airport",
      "city": "North English",
      "state": "Iowa",
      "country": "US",
      "elevation": 875,
      "lat": 41.4934997559,
      "lon": -92.1482009888,
      "tz": "America\/Chicago"
  },
  "7IA3": {
      "icao": "7IA3",
      "iata": "",
      "name": "Mc Bride Field",
      "city": "Marion",
      "state": "Iowa",
      "country": "US",
      "elevation": 881,
      "lat": 42.0638999939,
      "lon": -91.6343002319,
      "tz": "America\/Chicago"
  },
  "7IA7": {
      "icao": "7IA7",
      "iata": "",
      "name": "Mitchell Field",
      "city": "New Hampton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1160,
      "lat": 43.0835990906,
      "lon": -92.3099975586,
      "tz": "America\/Chicago"
  },
  "7II0": {
      "icao": "7II0",
      "iata": "",
      "name": "Porter Field",
      "city": "New Whiteland",
      "state": "Indiana",
      "country": "US",
      "elevation": 785,
      "lat": 39.5620002747,
      "lon": -86.1125030518,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "7II3": {
      "icao": "7II3",
      "iata": "",
      "name": "Schroeder Private Airport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 385,
      "lat": 38.0033988953,
      "lon": -87.756401062,
      "tz": "America\/Chicago"
  },
  "7II7": {
      "icao": "7II7",
      "iata": "",
      "name": "Rex's Ultralightport",
      "city": "Wolflake",
      "state": "Indiana",
      "country": "US",
      "elevation": 920,
      "lat": 41.2972984314,
      "lon": -85.4832992554,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "7II9": {
      "icao": "7II9",
      "iata": "",
      "name": "Thorn Field",
      "city": "Bargersville",
      "state": "Indiana",
      "country": "US",
      "elevation": 740,
      "lat": 39.5828018188,
      "lon": -86.1797027588,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "7IL0": {
      "icao": "7IL0",
      "iata": "",
      "name": "Mussman Airport",
      "city": "Grant Park",
      "state": "Illinois",
      "country": "US",
      "elevation": 705,
      "lat": 41.2709007263,
      "lon": -87.577796936,
      "tz": "America\/Chicago"
  },
  "7IL2": {
      "icao": "7IL2",
      "iata": "",
      "name": "O John Clark Restricted Landing Area",
      "city": "Grays Lake",
      "state": "Illinois",
      "country": "US",
      "elevation": 790,
      "lat": 42.3684005737,
      "lon": -87.9916992188,
      "tz": "America\/Chicago"
  },
  "7IL3": {
      "icao": "7IL3",
      "iata": "",
      "name": "Lutz RLA Restricted Landing Area",
      "city": "Greenvalley",
      "state": "Illinois",
      "country": "US",
      "elevation": 545,
      "lat": 40.4283981323,
      "lon": -89.6020965576,
      "tz": "America\/Chicago"
  },
  "7IL6": {
      "icao": "7IL6",
      "iata": "",
      "name": "Dr Joseph W Esser Airport",
      "city": "Hampshire",
      "state": "Illinois",
      "country": "US",
      "elevation": 979,
      "lat": 42.1305999756,
      "lon": -88.5028991699,
      "tz": "America\/Chicago"
  },
  "7IL7": {
      "icao": "7IL7",
      "iata": "",
      "name": "Edward Getzelman Airport",
      "city": "Hampshire",
      "state": "Illinois",
      "country": "US",
      "elevation": 870,
      "lat": 42.126701355,
      "lon": -88.5334014893,
      "tz": "America\/Chicago"
  },
  "7IL8": {
      "icao": "7IL8",
      "iata": "",
      "name": "Cody Port RLA Restricted Landing Area",
      "city": "Harding",
      "state": "Illinois",
      "country": "US",
      "elevation": 668,
      "lat": 41.518901825,
      "lon": -88.837600708,
      "tz": "America\/Chicago"
  },
  "7IL9": {
      "icao": "7IL9",
      "iata": "",
      "name": "Ambler - Cady Airport",
      "city": "Earlville",
      "state": "Illinois",
      "country": "US",
      "elevation": 700,
      "lat": 41.5438995361,
      "lon": -88.9934005737,
      "tz": "America\/Chicago"
  },
  "7IN2": {
      "icao": "7IN2",
      "iata": "",
      "name": "Flying Crown Airport",
      "city": "Hamilton",
      "state": "Indiana",
      "country": "US",
      "elevation": 920,
      "lat": 41.5013999939,
      "lon": -84.8783035278,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "7IN3": {
      "icao": "7IN3",
      "iata": "",
      "name": "Garrett Field",
      "city": "Poseyville",
      "state": "Indiana",
      "country": "US",
      "elevation": 400,
      "lat": 38.243598938,
      "lon": -87.7900009155,
      "tz": "America\/Chicago"
  },
  "7IN6": {
      "icao": "7IN6",
      "iata": "",
      "name": "Myers Farm Airport",
      "city": "Livonia",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 38.5819015503,
      "lon": -86.2536010742,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "7IN7": {
      "icao": "7IN7",
      "iata": "",
      "name": "Gary Johnson Field",
      "city": "West Lebanon",
      "state": "Indiana",
      "country": "US",
      "elevation": 570,
      "lat": 40.2358016968,
      "lon": -87.3507995605,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "7IN9": {
      "icao": "7IN9",
      "iata": "",
      "name": "The Last Resort Airport",
      "city": "Springport",
      "state": "Indiana",
      "country": "US",
      "elevation": 1070,
      "lat": 40.0374984741,
      "lon": -85.4105987549,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "7IS2": {
      "icao": "7IS2",
      "iata": "",
      "name": "Aero Estates Airport",
      "city": "Belleville",
      "state": "Illinois",
      "country": "US",
      "elevation": 565,
      "lat": 38.5245018005,
      "lon": -90.0569000244,
      "tz": "America\/Chicago"
  },
  "7IS3": {
      "icao": "7IS3",
      "iata": "",
      "name": "Hooterville Airport",
      "city": "Clinton",
      "state": "Illinois",
      "country": "US",
      "elevation": 730,
      "lat": 40.1433982849,
      "lon": -89.091796875,
      "tz": "America\/Chicago"
  },
  "7IS5": {
      "icao": "7IS5",
      "iata": "",
      "name": "Koppie Airport",
      "city": "Gilberts",
      "state": "Illinois",
      "country": "US",
      "elevation": 890,
      "lat": 42.1213989258,
      "lon": -88.3780975342,
      "tz": "America\/Chicago"
  },
  "7IS7": {
      "icao": "7IS7",
      "iata": "",
      "name": "Hammock Field",
      "city": "Alhambra",
      "state": "Illinois",
      "country": "US",
      "elevation": 595,
      "lat": 38.913898468,
      "lon": -89.6700973511,
      "tz": "America\/Chicago"
  },
  "7IS9": {
      "icao": "7IS9",
      "iata": "",
      "name": "King Airport",
      "city": "Columbia",
      "state": "Illinois",
      "country": "US",
      "elevation": 407,
      "lat": 38.476398468,
      "lon": -90.2361984253,
      "tz": "America\/Chicago"
  },
  "7KS0": {
      "icao": "7KS0",
      "iata": "",
      "name": "Flying T Airport",
      "city": "Oskaloosa",
      "state": "Kansas",
      "country": "US",
      "elevation": 1160,
      "lat": 39.2714004517,
      "lon": -95.3169021606,
      "tz": "America\/Chicago"
  },
  "7KS2": {
      "icao": "7KS2",
      "iata": "",
      "name": "Cloud Airport",
      "city": "Overbrook",
      "state": "Kansas",
      "country": "US",
      "elevation": 1020,
      "lat": 38.84999847,
      "lon": -95.46749878,
      "tz": "America\/Chicago"
  },
  "7KS3": {
      "icao": "7KS3",
      "iata": "",
      "name": "Youvan Airport",
      "city": "Arma",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 37.5292015076,
      "lon": -94.6960983276,
      "tz": "America\/Chicago"
  },
  "7KS4": {
      "icao": "7KS4",
      "iata": "",
      "name": "Bent Nail Ranch Airport",
      "city": "Horton",
      "state": "Kansas",
      "country": "US",
      "elevation": 1180,
      "lat": 39.6333007812,
      "lon": -95.439201355,
      "tz": "America\/Chicago"
  },
  "7KS5": {
      "icao": "7KS5",
      "iata": "",
      "name": "Croisant Airport",
      "city": "Humboldt",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 37.7961997986,
      "lon": -95.3419036865,
      "tz": "America\/Chicago"
  },
  "7KS6": {
      "icao": "7KS6",
      "iata": "",
      "name": "Linders Cow-Chip Airport",
      "city": "Louisburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 985,
      "lat": 38.6263999939,
      "lon": -94.7694015503,
      "tz": "America\/Chicago"
  },
  "7KS7": {
      "icao": "7KS7",
      "iata": "",
      "name": "Evans Airport",
      "city": "Oakley",
      "state": "Kansas",
      "country": "US",
      "elevation": 2950,
      "lat": 38.9583015442,
      "lon": -100.8000030518,
      "tz": "America\/Chicago"
  },
  "7KS8": {
      "icao": "7KS8",
      "iata": "",
      "name": "Shaw Aerial Spraying Airport",
      "city": "Oberlin",
      "state": "Kansas",
      "country": "US",
      "elevation": 2700,
      "lat": 39.8306007385,
      "lon": -100.581001282,
      "tz": "America\/Chicago"
  },
  "7KS9": {
      "icao": "7KS9",
      "iata": "",
      "name": "Eagle Field",
      "city": "Bentley",
      "state": "Kansas",
      "country": "US",
      "elevation": 1379,
      "lat": 37.8488998413,
      "lon": -97.5100021362,
      "tz": "America\/Chicago"
  },
  "7KY2": {
      "icao": "7KY2",
      "iata": "",
      "name": "Ryan Field",
      "city": "Verona",
      "state": "Kentucky",
      "country": "US",
      "elevation": 890,
      "lat": 38.8036003113,
      "lon": -84.6611022949,
      "tz": "America\/New_York"
  },
  "7KY3": {
      "icao": "7KY3",
      "iata": "",
      "name": "Little Mount International Airport",
      "city": "Tarylorsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 750,
      "lat": 38.0760993958,
      "lon": -85.2367019653,
      "tz": "America\/New_York"
  },
  "7LA0": {
      "icao": "7LA0",
      "iata": "",
      "name": "Valverda Strip",
      "city": "Maringouin",
      "state": "Louisiana",
      "country": "US",
      "elevation": 50,
      "lat": 30.525100708,
      "lon": -91.5319976807,
      "tz": "America\/Chicago"
  },
  "7LA1": {
      "icao": "7LA1",
      "iata": "",
      "name": "Birdwin Airport",
      "city": "Port Sulphur",
      "state": "Louisiana",
      "country": "US",
      "elevation": 2,
      "lat": 29.4582996368,
      "lon": -89.6759033203,
      "tz": "America\/Chicago"
  },
  "7LA3": {
      "icao": "7LA3",
      "iata": "",
      "name": "Habetz Airport",
      "city": "Ragley",
      "state": "Louisiana",
      "country": "US",
      "elevation": 79,
      "lat": 30.4829998016,
      "lon": -93.2287979126,
      "tz": "America\/Chicago"
  },
  "7LA4": {
      "icao": "7LA4",
      "iata": "",
      "name": "Gladney Airport",
      "city": "Rayne",
      "state": "Louisiana",
      "country": "US",
      "elevation": 23,
      "lat": 30.1252002716,
      "lon": -92.2195968628,
      "tz": "America\/Chicago"
  },
  "7LL3": {
      "icao": "7LL3",
      "iata": "",
      "name": "Jasper County Flying Club Airport",
      "city": "Newton",
      "state": "Illinois",
      "country": "US",
      "elevation": 575,
      "lat": 38.9317016602,
      "lon": -88.1569976807,
      "tz": "America\/Chicago"
  },
  "7LL4": {
      "icao": "7LL4",
      "iata": "",
      "name": "Bakers Strip",
      "city": "Weldon",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 40.1172981262,
      "lon": -88.7590026855,
      "tz": "America\/Chicago"
  },
  "7LL6": {
      "icao": "7LL6",
      "iata": "",
      "name": "Gehant Airport",
      "city": "West Brooklyn",
      "state": "Illinois",
      "country": "US",
      "elevation": 952,
      "lat": 41.6974983215,
      "lon": -89.1443023682,
      "tz": "America\/Chicago"
  },
  "7LL7": {
      "icao": "7LL7",
      "iata": "",
      "name": "Delhotal Airport",
      "city": "West Brooklyn",
      "state": "Illinois",
      "country": "US",
      "elevation": 760,
      "lat": 41.7360992432,
      "lon": -89.1723022461,
      "tz": "America\/Chicago"
  },
  "7LL8": {
      "icao": "7LL8",
      "iata": "",
      "name": "Dahler Airport",
      "city": "Nokomis",
      "state": "Illinois",
      "country": "US",
      "elevation": 659,
      "lat": 39.3525009155,
      "lon": -89.3009033203,
      "tz": "America\/Chicago"
  },
  "7LL9": {
      "icao": "7LL9",
      "iata": "",
      "name": "Brammeier Airport",
      "city": "Oakdale",
      "state": "Illinois",
      "country": "US",
      "elevation": 530,
      "lat": 38.2616996765,
      "lon": -89.4709014893,
      "tz": "America\/Chicago"
  },
  "7MD0": {
      "icao": "7MD0",
      "iata": "",
      "name": "Our Domain Airport",
      "city": "Marydel",
      "state": "Delaware",
      "country": "US",
      "elevation": 65,
      "lat": 39.0666999817,
      "lon": -75.7332992554,
      "tz": "America\/New_York"
  },
  "7MD1": {
      "icao": "7MD1",
      "iata": "",
      "name": "Magennis Farm Airport",
      "city": "Federalsburg",
      "state": "Maryland",
      "country": "US",
      "elevation": 40,
      "lat": 38.7033996582,
      "lon": -75.8509979248,
      "tz": "America\/New_York"
  },
  "7MD7": {
      "icao": "7MD7",
      "iata": "",
      "name": "Boomers Field",
      "city": "Pocomoke",
      "state": "Maryland",
      "country": "US",
      "elevation": 22,
      "lat": 38.0004005432,
      "lon": -75.5830001831,
      "tz": "America\/New_York"
  },
  "7MD8": {
      "icao": "7MD8",
      "iata": "",
      "name": "Kent Fort Manor Airport",
      "city": "Stevensville",
      "state": "Maryland",
      "country": "US",
      "elevation": 10,
      "lat": 38.8564987183,
      "lon": -76.3616027832,
      "tz": "America\/New_York"
  },
  "7MD9": {
      "icao": "7MD9",
      "iata": "",
      "name": "Tilghman Whipp Airport",
      "city": "Tilghman",
      "state": "Maryland",
      "country": "US",
      "elevation": 5,
      "lat": 38.7360992432,
      "lon": -76.3167037964,
      "tz": "America\/New_York"
  },
  "7MI2": {
      "icao": "7MI2",
      "iata": "",
      "name": "Sawyer \/ Southwest Lakes Airpark",
      "city": "",
      "state": "Michigan",
      "country": "US",
      "elevation": 650,
      "lat": 41.8910966258,
      "lon": -86.5867388248,
      "tz": "America\/Detroit"
  },
  "7MI3": {
      "icao": "7MI3",
      "iata": "",
      "name": "Thrall Lake Airport",
      "city": "Vicksburg",
      "state": "Michigan",
      "country": "US",
      "elevation": 850,
      "lat": 42.1166992188,
      "lon": -85.5167007446,
      "tz": "America\/Detroit"
  },
  "7MI4": {
      "icao": "7MI4",
      "iata": "",
      "name": "Ludington Airport",
      "city": "Port Hope",
      "state": "Michigan",
      "country": "US",
      "elevation": 690,
      "lat": 43.9221992493,
      "lon": -82.7485961914,
      "tz": "America\/Detroit"
  },
  "7MI5": {
      "icao": "7MI5",
      "iata": "",
      "name": "Lada Airport",
      "city": "Petersburg",
      "state": "Michigan",
      "country": "US",
      "elevation": 678,
      "lat": 41.8875007629,
      "lon": -83.6765975952,
      "tz": "America\/Detroit"
  },
  "7MI6": {
      "icao": "7MI6",
      "iata": "",
      "name": "Wightman Airport",
      "city": "Oakley",
      "state": "Michigan",
      "country": "US",
      "elevation": 725,
      "lat": 43.1102981567,
      "lon": -84.1264038086,
      "tz": "America\/Detroit"
  },
  "7MI7": {
      "icao": "7MI7",
      "iata": "",
      "name": "Walker Airport",
      "city": "Cooper",
      "state": "Michigan",
      "country": "US",
      "elevation": 750,
      "lat": 42.3805999756,
      "lon": -85.5774993896,
      "tz": "America\/Detroit"
  },
  "7MI8": {
      "icao": "7MI8",
      "iata": "",
      "name": "Letts Field",
      "city": "Vassar",
      "state": "Michigan",
      "country": "US",
      "elevation": 800,
      "lat": 43.3391990662,
      "lon": -83.4760971069,
      "tz": "America\/Detroit"
  },
  "7MN0": {
      "icao": "7MN0",
      "iata": "",
      "name": "Johnson Private Airport",
      "city": "Sacred Heart",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1020,
      "lat": 44.7999992371,
      "lon": -95.3953018188,
      "tz": "America\/Chicago"
  },
  "7MN3": {
      "icao": "7MN3",
      "iata": "",
      "name": "Caldbeck Field",
      "city": "Spring Valley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1340,
      "lat": 43.7052001953,
      "lon": -92.4607009888,
      "tz": "America\/Chicago"
  },
  "7MN4": {
      "icao": "7MN4",
      "iata": "",
      "name": "Mille Lacs Lake Resort Airport",
      "city": "Garrison",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1261,
      "lat": 46.3194007874,
      "lon": -93.8308029175,
      "tz": "America\/Chicago"
  },
  "7MN6": {
      "icao": "7MN6",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Argyle",
      "state": "Minnesota",
      "country": "US",
      "elevation": 820,
      "lat": 48.3428001404,
      "lon": -96.880897522,
      "tz": "America\/Chicago"
  },
  "7MN8": {
      "icao": "7MN8",
      "iata": "",
      "name": "Willis Airport",
      "city": "Thief River Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1135,
      "lat": 48.0699996948,
      "lon": -96.2789001465,
      "tz": "America\/Chicago"
  },
  "7MO1": {
      "icao": "7MO1",
      "iata": "",
      "name": "Wood Acres Airport",
      "city": "Perry",
      "state": "Missouri",
      "country": "US",
      "elevation": 720,
      "lat": 39.4723014832,
      "lon": -91.7113037109,
      "tz": "America\/Chicago"
  },
  "7MO2": {
      "icao": "7MO2",
      "iata": "",
      "name": "Bevill Airport",
      "city": "Philadelphia",
      "state": "Missouri",
      "country": "US",
      "elevation": 710,
      "lat": 39.8499984741,
      "lon": -91.7334976196,
      "tz": "America\/Chicago"
  },
  "7MO3": {
      "icao": "7MO3",
      "iata": "",
      "name": "B S Ranch Airport",
      "city": "Pineville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 36.6001014709,
      "lon": -94.3501968384,
      "tz": "America\/Chicago"
  },
  "7MO4": {
      "icao": "7MO4",
      "iata": "",
      "name": "Flintlock Field",
      "city": "Platte City",
      "state": "Missouri",
      "country": "US",
      "elevation": 930,
      "lat": 39.4319000244,
      "lon": -94.8030014038,
      "tz": "America\/Chicago"
  },
  "7MO5": {
      "icao": "7MO5",
      "iata": "",
      "name": "Elton Field",
      "city": "Platte City",
      "state": "Missouri",
      "country": "US",
      "elevation": 780,
      "lat": 39.3347015381,
      "lon": -94.8126983643,
      "tz": "America\/Chicago"
  },
  "7MO6": {
      "icao": "7MO6",
      "iata": "",
      "name": "Mac's Field",
      "city": "Plattsburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 1015,
      "lat": 39.4832992554,
      "lon": -94.4669036865,
      "tz": "America\/Chicago"
  },
  "7MO7": {
      "icao": "7MO7",
      "iata": "",
      "name": "Kimray Airport",
      "city": "Plattsburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 940,
      "lat": 39.5666999817,
      "lon": -94.4147033691,
      "tz": "America\/Chicago"
  },
  "7MO8": {
      "icao": "7MO8",
      "iata": "",
      "name": "Clark Airport",
      "city": "Plattsburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 1010,
      "lat": 39.5292015076,
      "lon": -94.4502029419,
      "tz": "America\/Chicago"
  },
  "7MS1": {
      "icao": "7MS1",
      "iata": "",
      "name": "Eagle Crest Estates Airport",
      "city": "Canton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 280,
      "lat": 32.6068992615,
      "lon": -89.9832992554,
      "tz": "America\/Chicago"
  },
  "7MY9": {
      "icao": "7MY9",
      "iata": "",
      "name": "Petes Airport",
      "city": "Dexter",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1337,
      "lat": 43.746717,
      "lon": -92.7245,
      "tz": "America\/Chicago"
  },
  "7NA0": {
      "icao": "7NA0",
      "iata": "",
      "name": "Downs Farm Private Airport",
      "city": "Hillsboro",
      "state": "North Dakota",
      "country": "US",
      "elevation": 900,
      "lat": 47.3574981689,
      "lon": -97.0280990601,
      "tz": "America\/Chicago"
  },
  "7NA2": {
      "icao": "7NA2",
      "iata": "",
      "name": "Undlin Airstrip",
      "city": "Lansford",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1575,
      "lat": 48.6878013611,
      "lon": -101.3499984741,
      "tz": "America\/Chicago"
  },
  "7NA3": {
      "icao": "7NA3",
      "iata": "",
      "name": "Hiam Private Airport",
      "city": "Lisbon",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1251,
      "lat": 46.3499984741,
      "lon": -97.6942977905,
      "tz": "America\/Chicago"
  },
  "7NA4": {
      "icao": "7NA4",
      "iata": "",
      "name": "Ingebretson Airspray Airport",
      "city": "Mayville",
      "state": "North Dakota",
      "country": "US",
      "elevation": 982,
      "lat": 47.51720047,
      "lon": -97.3337020874,
      "tz": "America\/Chicago"
  },
  "7NA9": {
      "icao": "7NA9",
      "iata": "",
      "name": "Sjule Private Airstrip",
      "city": "Souris",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1680,
      "lat": 48.9282989502,
      "lon": -100.5780029297,
      "tz": "America\/Chicago"
  },
  "7NC0": {
      "icao": "7NC0",
      "iata": "",
      "name": "Pamlico Airport",
      "city": "Bayboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 20,
      "lat": 35.1585006714,
      "lon": -76.7627029419,
      "tz": "America\/New_York"
  },
  "7NC1": {
      "icao": "7NC1",
      "iata": "",
      "name": "Stag Air Park",
      "city": "Burgaw",
      "state": "North Carolina",
      "country": "US",
      "elevation": 23,
      "lat": 34.5279006958,
      "lon": -77.8503036499,
      "tz": "America\/New_York"
  },
  "7NC2": {
      "icao": "7NC2",
      "iata": "DUF",
      "name": "Pine Island Airport",
      "city": "Corolla",
      "state": "North Carolina",
      "country": "US",
      "elevation": 16,
      "lat": 36.2535018921,
      "lon": -75.7884979248,
      "tz": "America\/New_York"
  },
  "7NC3": {
      "icao": "7NC3",
      "iata": "",
      "name": "Kenly Airport",
      "city": "Kenly",
      "state": "North Carolina",
      "country": "US",
      "elevation": 225,
      "lat": 35.596389,
      "lon": -78.098611,
      "tz": "America\/New_York"
  },
  "7NC5": {
      "icao": "7NC5",
      "iata": "",
      "name": "Crooked Creek Airport",
      "city": "Bunn",
      "state": "North Carolina",
      "country": "US",
      "elevation": 250,
      "lat": 35.9337997437,
      "lon": -78.2466964722,
      "tz": "America\/New_York"
  },
  "7NC6": {
      "icao": "7NC6",
      "iata": "",
      "name": "Kimrey Airport",
      "city": "Mebane",
      "state": "North Carolina",
      "country": "US",
      "elevation": 624,
      "lat": 36.0554008484,
      "lon": -79.3266983032,
      "tz": "America\/New_York"
  },
  "7NC7": {
      "icao": "7NC7",
      "iata": "",
      "name": "Lewis Airstrip",
      "city": "Walnut Cove",
      "state": "North Carolina",
      "country": "US",
      "elevation": 650,
      "lat": 36.3468017578,
      "lon": -80.1736984253,
      "tz": "America\/New_York"
  },
  "7NC8": {
      "icao": "7NC8",
      "iata": "",
      "name": "Fish Airpark",
      "city": "Angier",
      "state": "North Carolina",
      "country": "US",
      "elevation": 280,
      "lat": 35.5349006653,
      "lon": -78.6964035034,
      "tz": "America\/New_York"
  },
  "7NC9": {
      "icao": "7NC9",
      "iata": "",
      "name": "Spencer Field",
      "city": "Asheboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 460,
      "lat": 35.7274017334,
      "lon": -79.9111022949,
      "tz": "America\/New_York"
  },
  "7ND1": {
      "icao": "7ND1",
      "iata": "",
      "name": "Wolberg's Private Airport",
      "city": "Dickinson",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2540,
      "lat": 47.0360984802,
      "lon": -102.7779998779,
      "tz": "America\/Denver"
  },
  "7ND2": {
      "icao": "7ND2",
      "iata": "",
      "name": "Kelly's Field",
      "city": "Grafton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 810,
      "lat": 48.3727989197,
      "lon": -97.320098877,
      "tz": "America\/Chicago"
  },
  "7ND5": {
      "icao": "7ND5",
      "iata": "",
      "name": "Buchmiller Airport",
      "city": "Bowdon",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1850,
      "lat": 47.3596992493,
      "lon": -99.6225967407,
      "tz": "America\/Chicago"
  },
  "7ND7": {
      "icao": "7ND7",
      "iata": "",
      "name": "Haugen's Airport",
      "city": "Alexander",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2330,
      "lat": 47.6824989319,
      "lon": -103.7279968262,
      "tz": "America\/Chicago"
  },
  "7ND8": {
      "icao": "7ND8",
      "iata": "",
      "name": "Forest Airport",
      "city": "Langdon",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1570,
      "lat": 48.8521995544,
      "lon": -98.7481994629,
      "tz": "America\/Chicago"
  },
  "7NE2": {
      "icao": "7NE2",
      "iata": "",
      "name": "Plainsview Ranch Airport",
      "city": "Hemingford",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4650,
      "lat": 42.2583007813,
      "lon": -103.5299987793,
      "tz": "America\/Denver"
  },
  "7NE5": {
      "icao": "7NE5",
      "iata": "",
      "name": "Lierley Farms Airport",
      "city": "Paxton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3220,
      "lat": 41.0666999817,
      "lon": -101.3679962158,
      "tz": "America\/Denver"
  },
  "7NE6": {
      "icao": "7NE6",
      "iata": "",
      "name": "Merchant Homestead Airport",
      "city": "Polk",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1700,
      "lat": 41.011100769,
      "lon": -97.8114013672,
      "tz": "America\/Chicago"
  },
  "7NJ2": {
      "icao": "7NJ2",
      "iata": "",
      "name": "Stoe Creek Farm Airport",
      "city": "Canton",
      "state": "New Jersey",
      "country": "US",
      "elevation": 15,
      "lat": 39.481300354,
      "lon": -75.4052963257,
      "tz": "America\/New_York"
  },
  "7NJ7": {
      "icao": "7NJ7",
      "iata": "",
      "name": "Coombs Airport",
      "city": "Elmer",
      "state": "New Jersey",
      "country": "US",
      "elevation": 120,
      "lat": 39.5606002808,
      "lon": -75.2185974121,
      "tz": "America\/New_York"
  },
  "7NJ9": {
      "icao": "7NJ9",
      "iata": "",
      "name": "Dave's Aerodrome",
      "city": "Cedarville",
      "state": "New Jersey",
      "country": "US",
      "elevation": 8,
      "lat": 39.3181991577,
      "lon": -75.2062988281,
      "tz": "America\/New_York"
  },
  "7NK0": {
      "icao": "7NK0",
      "iata": "",
      "name": "Valley View Airport",
      "city": "Middleburgh",
      "state": "New York",
      "country": "US",
      "elevation": 1160,
      "lat": 42.6475982666,
      "lon": -74.3873977661,
      "tz": "America\/New_York"
  },
  "7NK1": {
      "icao": "7NK1",
      "iata": "",
      "name": "Deer Run Air Field",
      "city": "Nelson",
      "state": "New York",
      "country": "US",
      "elevation": 1725,
      "lat": 42.9430999756,
      "lon": -75.7536010742,
      "tz": "America\/New_York"
  },
  "7NK4": {
      "icao": "7NK4",
      "iata": "",
      "name": "Ridge Road West Airport",
      "city": "Spenceport",
      "state": "New York",
      "country": "US",
      "elevation": 400,
      "lat": 43.2225990295,
      "lon": -77.8106002808,
      "tz": "America\/New_York"
  },
  "7NK6": {
      "icao": "7NK6",
      "iata": "",
      "name": "Blueberry Field",
      "city": "Treadwell",
      "state": "New York",
      "country": "US",
      "elevation": 2100,
      "lat": 42.2916984558,
      "lon": -75.0667037964,
      "tz": "America\/New_York"
  },
  "7NK7": {
      "icao": "7NK7",
      "iata": "",
      "name": "Morin Airport",
      "city": "Sherburne",
      "state": "New York",
      "country": "US",
      "elevation": 1068,
      "lat": 42.7132987976,
      "lon": -75.8899993896,
      "tz": "America\/New_York"
  },
  "7NM1": {
      "icao": "7NM1",
      "iata": "",
      "name": "Mesa Verde Ranch Strip Airport",
      "city": "Alamogordo",
      "state": "New Mexico",
      "country": "US",
      "elevation": 4237,
      "lat": 32.935278,
      "lon": -106.045278,
      "tz": "America\/Denver"
  },
  "7NY0": {
      "icao": "7NY0",
      "iata": "",
      "name": "Campis Airport",
      "city": "Lake Huntington",
      "state": "New York",
      "country": "US",
      "elevation": 1200,
      "lat": 41.6833992004,
      "lon": -75.0162963867,
      "tz": "America\/New_York"
  },
  "7NY1": {
      "icao": "7NY1",
      "iata": "",
      "name": "Gar Field",
      "city": "Esperance",
      "state": "New York",
      "country": "US",
      "elevation": 640,
      "lat": 42.7634010315,
      "lon": -74.278503418,
      "tz": "America\/New_York"
  },
  "7NY2": {
      "icao": "7NY2",
      "iata": "",
      "name": "Butterville Airport",
      "city": "Adams",
      "state": "New York",
      "country": "US",
      "elevation": 510,
      "lat": 43.8433990479,
      "lon": -76.0947036743,
      "tz": "America\/New_York"
  },
  "7NY4": {
      "icao": "7NY4",
      "iata": "",
      "name": "Culver Airfield",
      "city": "Mecklenburg",
      "state": "New York",
      "country": "US",
      "elevation": 1455,
      "lat": 42.4250984192,
      "lon": -76.7149963379,
      "tz": "America\/New_York"
  },
  "7NY6": {
      "icao": "7NY6",
      "iata": "",
      "name": "Sherwood Farm Airport",
      "city": "Johnsonville",
      "state": "New York",
      "country": "US",
      "elevation": 530,
      "lat": 42.893699646,
      "lon": -73.5078964233,
      "tz": "America\/New_York"
  },
  "7NY8": {
      "icao": "7NY8",
      "iata": "",
      "name": "Shaw Field",
      "city": "Glen Falls",
      "state": "New York",
      "country": "US",
      "elevation": 190,
      "lat": 43.1922988892,
      "lon": -73.6297988892,
      "tz": "America\/New_York"
  },
  "7OA7": {
      "icao": "7OA7",
      "iata": "",
      "name": "Skydive Greene County Inc Airport",
      "city": "Xenia",
      "state": "Ohio",
      "country": "US",
      "elevation": 1055,
      "lat": 39.6791992188,
      "lon": -83.8707962036,
      "tz": "America\/New_York"
  },
  "7OH1": {
      "icao": "7OH1",
      "iata": "",
      "name": "Rauhaus Field",
      "city": "Valley City",
      "state": "Ohio",
      "country": "US",
      "elevation": 912,
      "lat": 41.2099990845,
      "lon": -81.9736022949,
      "tz": "America\/New_York"
  },
  "7OH2": {
      "icao": "7OH2",
      "iata": "",
      "name": "Canal Fulton Airport",
      "city": "Canal Fulton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1150,
      "lat": 40.9002990723,
      "lon": -81.5336990356,
      "tz": "America\/New_York"
  },
  "7OH3": {
      "icao": "7OH3",
      "iata": "",
      "name": "Obi One Airport",
      "city": "Delaware",
      "state": "Ohio",
      "country": "US",
      "elevation": 952,
      "lat": 40.3527984619,
      "lon": -83.0510025024,
      "tz": "America\/New_York"
  },
  "7OH5": {
      "icao": "7OH5",
      "iata": "",
      "name": "Mahoning County Joint Vocational School Airport",
      "city": "Canfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1130,
      "lat": 41.034198761,
      "lon": -80.7817001343,
      "tz": "America\/New_York"
  },
  "7OH7": {
      "icao": "7OH7",
      "iata": "",
      "name": "Hydebrook Airport",
      "city": "Yellow Springs",
      "state": "Ohio",
      "country": "US",
      "elevation": 960,
      "lat": 39.7801017761,
      "lon": -83.8999023438,
      "tz": "America\/New_York"
  },
  "7OH8": {
      "icao": "7OH8",
      "iata": "",
      "name": "Curtis Airport",
      "city": "Chardon",
      "state": "Ohio",
      "country": "US",
      "elevation": 1250,
      "lat": 41.6377983093,
      "lon": -81.1014022827,
      "tz": "America\/New_York"
  },
  "7OI0": {
      "icao": "7OI0",
      "iata": "",
      "name": "Christy's Airport",
      "city": "Napoleon",
      "state": "Ohio",
      "country": "US",
      "elevation": 683,
      "lat": 41.3342018127,
      "lon": -84.1635971069,
      "tz": "America\/New_York"
  },
  "7OI1": {
      "icao": "7OI1",
      "iata": "",
      "name": "Blevins Airport",
      "city": "Ayersville",
      "state": "Ohio",
      "country": "US",
      "elevation": 722,
      "lat": 41.2072982788,
      "lon": -84.2696990967,
      "tz": "America\/New_York"
  },
  "7OI5": {
      "icao": "7OI5",
      "iata": "",
      "name": "Bea-D Airport",
      "city": "Graytown",
      "state": "Ohio",
      "country": "US",
      "elevation": 605,
      "lat": 41.5205993652,
      "lon": -83.2751998901,
      "tz": "America\/New_York"
  },
  "7OI7": {
      "icao": "7OI7",
      "iata": "",
      "name": "Southcreek Airport",
      "city": "Clyde",
      "state": "Ohio",
      "country": "US",
      "elevation": 665,
      "lat": 41.2994995117,
      "lon": -83.0112991333,
      "tz": "America\/New_York"
  },
  "7OI9": {
      "icao": "7OI9",
      "iata": "",
      "name": "Massengill Airport",
      "city": "Nashport",
      "state": "Ohio",
      "country": "US",
      "elevation": 955,
      "lat": 40.0690002441,
      "lon": -82.1360015869,
      "tz": "America\/New_York"
  },
  "7OK0": {
      "icao": "7OK0",
      "iata": "",
      "name": "Dennis Ranch Airport",
      "city": "Grady",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 875,
      "lat": 34.0317993164,
      "lon": -97.6778030396,
      "tz": "America\/Chicago"
  },
  "7OK1": {
      "icao": "7OK1",
      "iata": "",
      "name": "Meadowlark Field",
      "city": "Boynton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 746,
      "lat": 35.6203994751,
      "lon": -95.7442016602,
      "tz": "America\/Chicago"
  },
  "7OK2": {
      "icao": "7OK2",
      "iata": "",
      "name": "Scott Airport",
      "city": "Mangum",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1650,
      "lat": 34.976398468,
      "lon": -99.4998016357,
      "tz": "America\/Chicago"
  },
  "7OK4": {
      "icao": "7OK4",
      "iata": "",
      "name": "Martin Farms Airport",
      "city": "Amber",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1200,
      "lat": 35.150100708,
      "lon": -97.8669967651,
      "tz": "America\/Chicago"
  },
  "7OK8": {
      "icao": "7OK8",
      "iata": "",
      "name": "Myers Airport",
      "city": "Crescent",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1060,
      "lat": 35.989200592,
      "lon": -97.573097229,
      "tz": "America\/Chicago"
  },
  "7OR0": {
      "icao": "7OR0",
      "iata": "",
      "name": "Minam Lodge Airport",
      "city": "Cove",
      "state": "Oregon",
      "country": "US",
      "elevation": 3589,
      "lat": 45.3582000732,
      "lon": -117.6340026855,
      "tz": "America\/Los_Angeles"
  },
  "7OR1": {
      "icao": "7OR1",
      "iata": "",
      "name": "Arnold Airstrip",
      "city": "Crane",
      "state": "Oregon",
      "country": "US",
      "elevation": 4140,
      "lat": 43.4193000793,
      "lon": -118.5759963989,
      "tz": "America\/Los_Angeles"
  },
  "7OR2": {
      "icao": "7OR2",
      "iata": "",
      "name": "Basl Hill Farms Airport",
      "city": "Mehama",
      "state": "Oregon",
      "country": "US",
      "elevation": 1160,
      "lat": 44.8228988647,
      "lon": -122.6800003052,
      "tz": "America\/Los_Angeles"
  },
  "7OR3": {
      "icao": "7OR3",
      "iata": "",
      "name": "Honald Ranch Airport",
      "city": "The Dalles",
      "state": "Oregon",
      "country": "US",
      "elevation": 245,
      "lat": 45.5904006958,
      "lon": -121.2139968872,
      "tz": "America\/Los_Angeles"
  },
  "7OR4": {
      "icao": "7OR4",
      "iata": "",
      "name": "Pineridge Ranch Airport",
      "city": "Sisters",
      "state": "Oregon",
      "country": "US",
      "elevation": 3070,
      "lat": 44.3096008301,
      "lon": -121.4430007935,
      "tz": "America\/Los_Angeles"
  },
  "7OR6": {
      "icao": "7OR6",
      "iata": "",
      "name": "Green Acres Air Park",
      "city": "Hood River",
      "state": "Oregon",
      "country": "US",
      "elevation": 800,
      "lat": 45.6367988586,
      "lon": -121.591003418,
      "tz": "America\/Los_Angeles"
  },
  "7OR7": {
      "icao": "7OR7",
      "iata": "",
      "name": "Hollin Airport",
      "city": "Brooks",
      "state": "Oregon",
      "country": "US",
      "elevation": 170,
      "lat": 45.0540008545,
      "lon": -122.9069976807,
      "tz": "America\/Los_Angeles"
  },
  "7OR8": {
      "icao": "7OR8",
      "iata": "",
      "name": "Inshallah International Airport",
      "city": "Seneca",
      "state": "Oregon",
      "country": "US",
      "elevation": 4560,
      "lat": 44.0904006958,
      "lon": -119.2900009155,
      "tz": "America\/Los_Angeles"
  },
  "7OR9": {
      "icao": "7OR9",
      "iata": "",
      "name": "Parson Landing Airport",
      "city": "Oregon City",
      "state": "Oregon",
      "country": "US",
      "elevation": 640,
      "lat": 45.3242988586,
      "lon": -122.472000122,
      "tz": "America\/Los_Angeles"
  },
  "7PA0": {
      "icao": "7PA0",
      "iata": "",
      "name": "Manor Knoll Personal Use Airport",
      "city": "Mountville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 440,
      "lat": 40.0209007263,
      "lon": -76.4238967896,
      "tz": "America\/New_York"
  },
  "7PA1": {
      "icao": "7PA1",
      "iata": "",
      "name": "Warren Airpark",
      "city": "Warren",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1790,
      "lat": 41.8658981323,
      "lon": -79.206199646,
      "tz": "America\/New_York"
  },
  "7PA3": {
      "icao": "7PA3",
      "iata": "",
      "name": "Charles G. Kalko Airport",
      "city": "Honesdale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1400,
      "lat": 41.7056007385,
      "lon": -75.2880020142,
      "tz": "America\/New_York"
  },
  "7PA4": {
      "icao": "7PA4",
      "iata": "",
      "name": "Orson Field",
      "city": "Orson",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2100,
      "lat": 41.842300415,
      "lon": -75.4363021851,
      "tz": "America\/New_York"
  },
  "7PA5": {
      "icao": "7PA5",
      "iata": "",
      "name": "Fauser Ultralightport",
      "city": "Turbotville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 565,
      "lat": 41.1029014587,
      "lon": -76.8054962158,
      "tz": "America\/New_York"
  },
  "7PA6": {
      "icao": "7PA6",
      "iata": "",
      "name": "Stott Private Airport",
      "city": "Newtown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 143,
      "lat": 40.2453994751,
      "lon": -74.9913024902,
      "tz": "America\/New_York"
  },
  "7PN0": {
      "icao": "7PN0",
      "iata": "",
      "name": "Tidmore Airport",
      "city": "Minersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 930,
      "lat": 40.7050018311,
      "lon": -76.2363967896,
      "tz": "America\/New_York"
  },
  "7PS4": {
      "icao": "7PS4",
      "iata": "",
      "name": "Sky Classics Field",
      "city": "Grantville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 500,
      "lat": 40.3978996277,
      "lon": -76.5988998413,
      "tz": "America\/New_York"
  },
  "7PS7": {
      "icao": "7PS7",
      "iata": "",
      "name": "Lenzner Farm Airport",
      "city": "Sewickley",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1250,
      "lat": 40.5387001038,
      "lon": -80.1173019409,
      "tz": "America\/New_York"
  },
  "7TA0": {
      "icao": "7TA0",
      "iata": "",
      "name": "Field's Field",
      "city": "Tomball",
      "state": "Texas",
      "country": "US",
      "elevation": 198,
      "lat": 30.0921993256,
      "lon": -95.6516036987,
      "tz": "America\/Chicago"
  },
  "7TA3": {
      "icao": "7TA3",
      "iata": "",
      "name": "Quarterway Airport",
      "city": "Plainview",
      "state": "Texas",
      "country": "US",
      "elevation": 3470,
      "lat": 34.1809005737,
      "lon": -101.8369979858,
      "tz": "America\/Chicago"
  },
  "7TA5": {
      "icao": "7TA5",
      "iata": "",
      "name": "Weber Ranch Airport",
      "city": "Caldwell",
      "state": "Texas",
      "country": "US",
      "elevation": 350,
      "lat": 30.5870990753,
      "lon": -96.6341018677,
      "tz": "America\/Chicago"
  },
  "7TA7": {
      "icao": "7TA7",
      "iata": "",
      "name": "Flying M Ranch Airport",
      "city": "Reklaw",
      "state": "Texas",
      "country": "US",
      "elevation": 310,
      "lat": 31.8398990631,
      "lon": -94.9613037109,
      "tz": "America\/Chicago"
  },
  "7TA8": {
      "icao": "7TA8",
      "iata": "",
      "name": "John Henry Key Airport",
      "city": "Boerne",
      "state": "Texas",
      "country": "US",
      "elevation": 1400,
      "lat": 29.8596992493,
      "lon": -98.7414016724,
      "tz": "America\/Chicago"
  },
  "7TE0": {
      "icao": "7TE0",
      "iata": "",
      "name": "Kelley Crop Service Airport",
      "city": "Fannett",
      "state": "Texas",
      "country": "US",
      "elevation": 21,
      "lat": 29.9137992859,
      "lon": -94.2081985474,
      "tz": "America\/Chicago"
  },
  "7TE1": {
      "icao": "7TE1",
      "iata": "",
      "name": "Zachry Ranch Airport",
      "city": "Escobas",
      "state": "Texas",
      "country": "US",
      "elevation": 592,
      "lat": 27.0725002289,
      "lon": -98.9385986328,
      "tz": "America\/Chicago"
  },
  "7TE2": {
      "icao": "7TE2",
      "iata": "",
      "name": "Cage Ranch Airport",
      "city": "Falfurrias",
      "state": "Texas",
      "country": "US",
      "elevation": 148,
      "lat": 27.1058998108,
      "lon": -98.2058029175,
      "tz": "America\/Chicago"
  },
  "7TE3": {
      "icao": "7TE3",
      "iata": "",
      "name": "Lometa Air Strip",
      "city": "Lometa",
      "state": "Texas",
      "country": "US",
      "elevation": 1400,
      "lat": 31.233499527,
      "lon": -98.4670028687,
      "tz": "America\/Chicago"
  },
  "7TE4": {
      "icao": "7TE4",
      "iata": "",
      "name": "Flying J Ranch Airport",
      "city": "Leakey",
      "state": "Texas",
      "country": "US",
      "elevation": 1620,
      "lat": 29.5969009399,
      "lon": -99.8452987671,
      "tz": "America\/Chicago"
  },
  "7TE5": {
      "icao": "7TE5",
      "iata": "",
      "name": "Carlisle Airport",
      "city": "Mullin",
      "state": "Texas",
      "country": "US",
      "elevation": 1790,
      "lat": 31.6791992188,
      "lon": -98.6635971069,
      "tz": "America\/Chicago"
  },
  "7TE6": {
      "icao": "7TE6",
      "iata": "",
      "name": "Stovall Ranch Nr 1 Airport",
      "city": "El Campo",
      "state": "Texas",
      "country": "US",
      "elevation": 108,
      "lat": 29.2003002167,
      "lon": -96.554397583,
      "tz": "America\/Chicago"
  },
  "7TE7": {
      "icao": "7TE7",
      "iata": "",
      "name": "Moore Field",
      "city": "Mission",
      "state": "Texas",
      "country": "US",
      "elevation": 219,
      "lat": 26.3836994171,
      "lon": -98.3336029053,
      "tz": "America\/Chicago"
  },
  "7TE8": {
      "icao": "7TE8",
      "iata": "",
      "name": "Trulock Ranch Field",
      "city": "Colorado City",
      "state": "Texas",
      "country": "US",
      "elevation": 2168,
      "lat": 32.4210014343,
      "lon": -100.8109970093,
      "tz": "America\/Chicago"
  },
  "7TE9": {
      "icao": "7TE9",
      "iata": "",
      "name": "Boening Brothers Airport",
      "city": "Floresville",
      "state": "Texas",
      "country": "US",
      "elevation": 407,
      "lat": 29.0272006989,
      "lon": -98.2866973877,
      "tz": "America\/Chicago"
  },
  "7TN0": {
      "icao": "7TN0",
      "iata": "",
      "name": "Blue Bird Field",
      "city": "Pleasantview",
      "state": "Tennessee",
      "country": "US",
      "elevation": 705,
      "lat": 36.4356002808,
      "lon": -87.0914001465,
      "tz": "America\/Chicago"
  },
  "7TN1": {
      "icao": "7TN1",
      "iata": "",
      "name": "Elk River Airfield",
      "city": "Pelham",
      "state": "Tennessee",
      "country": "US",
      "elevation": 995,
      "lat": 35.3205986023,
      "lon": -85.8535995483,
      "tz": "America\/Chicago"
  },
  "7TN3": {
      "icao": "7TN3",
      "iata": "",
      "name": "Stones River Airport",
      "city": "Murfreesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 537,
      "lat": 35.9707984924,
      "lon": -86.3930969238,
      "tz": "America\/Chicago"
  },
  "7TN4": {
      "icao": "7TN4",
      "iata": "",
      "name": "Pegasus Field",
      "city": "Oakland",
      "state": "Tennessee",
      "country": "US",
      "elevation": 380,
      "lat": 35.1727981567,
      "lon": -89.5625,
      "tz": "America\/Chicago"
  },
  "7TN5": {
      "icao": "7TN5",
      "iata": "",
      "name": "Flying D Airport",
      "city": "Hickory Valley",
      "state": "Tennessee",
      "country": "US",
      "elevation": 477,
      "lat": 35.133806,
      "lon": -89.186806,
      "tz": "America\/Chicago"
  },
  "7TS0": {
      "icao": "7TS0",
      "iata": "",
      "name": "Fairview Airport",
      "city": "Rhome",
      "state": "Texas",
      "country": "US",
      "elevation": 920,
      "lat": 33.0951004028,
      "lon": -97.4270019531,
      "tz": "America\/Chicago"
  },
  "7TS2": {
      "icao": "7TS2",
      "iata": "",
      "name": "Alison Air Park",
      "city": "San Marcos",
      "state": "Texas",
      "country": "US",
      "elevation": 630,
      "lat": 29.8313007355,
      "lon": -97.8891983032,
      "tz": "America\/Chicago"
  },
  "7TS3": {
      "icao": "7TS3",
      "iata": "",
      "name": "Wyatt Airport",
      "city": "San Marcos",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 29.9598999023,
      "lon": -97.9852981567,
      "tz": "America\/Chicago"
  },
  "7TS4": {
      "icao": "7TS4",
      "iata": "",
      "name": "Roma Airport",
      "city": "Ponder",
      "state": "Texas",
      "country": "US",
      "elevation": 725,
      "lat": 33.1832008362,
      "lon": -97.2545013428,
      "tz": "America\/Chicago"
  },
  "7TS7": {
      "icao": "7TS7",
      "iata": "",
      "name": "Broussard Farm Airport",
      "city": "Fannett",
      "state": "Texas",
      "country": "US",
      "elevation": 20,
      "lat": 29.9111003876,
      "lon": -94.3391036987,
      "tz": "America\/Chicago"
  },
  "7TS8": {
      "icao": "7TS8",
      "iata": "",
      "name": "Ott Farms Airport",
      "city": "Dimmitt",
      "state": "Texas",
      "country": "US",
      "elevation": 3817,
      "lat": 34.4038009644,
      "lon": -102.4540023804,
      "tz": "America\/Chicago"
  },
  "7TS9": {
      "icao": "7TS9",
      "iata": "",
      "name": "Ag Aviation Airport",
      "city": "Bay City",
      "state": "Texas",
      "country": "US",
      "elevation": 50,
      "lat": 29.0541000366,
      "lon": -95.9625015259,
      "tz": "America\/Chicago"
  },
  "7TX0": {
      "icao": "7TX0",
      "iata": "",
      "name": "Tom Danaher Airport",
      "city": "Wichita Falls",
      "state": "Texas",
      "country": "US",
      "elevation": 986,
      "lat": 33.8282012939,
      "lon": -98.5727005005,
      "tz": "America\/Chicago"
  },
  "7TX4": {
      "icao": "7TX4",
      "iata": "",
      "name": "Hillcrest Airport",
      "city": "Keller",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 32.9668006897,
      "lon": -97.2752990723,
      "tz": "America\/Chicago"
  },
  "7TX5": {
      "icao": "7TX5",
      "iata": "",
      "name": "Mabee Ranch Airport",
      "city": "Midland",
      "state": "Texas",
      "country": "US",
      "elevation": 2862,
      "lat": 32.2170982361,
      "lon": -102.1600036621,
      "tz": "America\/Chicago"
  },
  "7TX7": {
      "icao": "7TX7",
      "iata": "",
      "name": "Ryan Aerodrome",
      "city": "Midland",
      "state": "Texas",
      "country": "US",
      "elevation": 2740,
      "lat": 32.0834999084,
      "lon": -102.016998291,
      "tz": "America\/Chicago"
  },
  "7TX8": {
      "icao": "7TX8",
      "iata": "",
      "name": "Flying K Airport",
      "city": "Amarillo",
      "state": "Texas",
      "country": "US",
      "elevation": 3560,
      "lat": 35.1076011658,
      "lon": -102.0380020142,
      "tz": "America\/Chicago"
  },
  "7TX9": {
      "icao": "7TX9",
      "iata": "",
      "name": "Ashford Field",
      "city": "Old Boston",
      "state": "Texas",
      "country": "US",
      "elevation": 305,
      "lat": 33.3586997986,
      "lon": -94.4376983643,
      "tz": "America\/Chicago"
  },
  "7VA5": {
      "icao": "7VA5",
      "iata": "",
      "name": "Redhouse Airfield",
      "city": "Redhouse",
      "state": "Virginia",
      "country": "US",
      "elevation": 742,
      "lat": 37.188999176,
      "lon": -78.8296966553,
      "tz": "America\/New_York"
  },
  "7VA9": {
      "icao": "7VA9",
      "iata": "",
      "name": "Hannah Field",
      "city": "Monterey",
      "state": "Virginia",
      "country": "US",
      "elevation": 3049,
      "lat": 38.398333,
      "lon": -79.600278,
      "tz": "America\/New_York"
  },
  "7VG0": {
      "icao": "7VG0",
      "iata": "",
      "name": "Warrenton Air Park",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 442,
      "lat": 38.6557006836,
      "lon": -77.7872009277,
      "tz": "America\/New_York"
  },
  "7WA0": {
      "icao": "7WA0",
      "iata": "",
      "name": "Big Andy Airport",
      "city": "Port Angeles",
      "state": "Washington",
      "country": "US",
      "elevation": 830,
      "lat": 48.098400116,
      "lon": -123.6429977417,
      "tz": "America\/Los_Angeles"
  },
  "7WA3": {
      "icao": "7WA3",
      "iata": "",
      "name": "West Wind Airport",
      "city": "Everson",
      "state": "Washington",
      "country": "US",
      "elevation": 160,
      "lat": 48.886100769,
      "lon": -122.3290023804,
      "tz": "America\/Los_Angeles"
  },
  "7WA4": {
      "icao": "7WA4",
      "iata": "",
      "name": "Humbert Airport",
      "city": "Ford",
      "state": "Washington",
      "country": "US",
      "elevation": 1890,
      "lat": 47.8574981689,
      "lon": -117.7799987793,
      "tz": "America\/Los_Angeles"
  },
  "7WA5": {
      "icao": "7WA5",
      "iata": "",
      "name": "Stuart Island Airpark",
      "city": "Stuart Island",
      "state": "Washington",
      "country": "US",
      "elevation": 10,
      "lat": 48.6729011536,
      "lon": -123.1760025024,
      "tz": "America\/Los_Angeles"
  },
  "7WA7": {
      "icao": "7WA7",
      "iata": "",
      "name": "Mc Whorter Ranch Airport",
      "city": "Prosser",
      "state": "Washington",
      "country": "US",
      "elevation": 1356,
      "lat": 46.3207015991,
      "lon": -119.617996216,
      "tz": "America\/Los_Angeles"
  },
  "7WI0": {
      "icao": "7WI0",
      "iata": "",
      "name": "Turkey Bluff Airport",
      "city": "Ferryville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1110,
      "lat": 43.3730010986,
      "lon": -91.1099014282,
      "tz": "America\/Chicago"
  },
  "7WI2": {
      "icao": "7WI2",
      "iata": "",
      "name": "Higgins Airport",
      "city": "Rio",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 965,
      "lat": 43.4430007935,
      "lon": -89.1781997681,
      "tz": "America\/Chicago"
  },
  "7WI4": {
      "icao": "7WI4",
      "iata": "",
      "name": "Lewis Airport",
      "city": "Black River Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1041,
      "lat": 44.3004989624,
      "lon": -90.9878997803,
      "tz": "America\/Chicago"
  },
  "7WI5": {
      "icao": "7WI5",
      "iata": "",
      "name": "Syvrud Airport",
      "city": "Brooklyn",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 42.8824996948,
      "lon": -89.4007034302,
      "tz": "America\/Chicago"
  },
  "7WI6": {
      "icao": "7WI6",
      "iata": "",
      "name": "Weatherbee Field",
      "city": "Wyocena",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 960,
      "lat": 43.470500946,
      "lon": -89.3590011597,
      "tz": "America\/Chicago"
  },
  "7WI7": {
      "icao": "7WI7",
      "iata": "",
      "name": "Charlies Airport",
      "city": "Medford",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1490,
      "lat": 45.1589012146,
      "lon": -90.1492996216,
      "tz": "America\/Chicago"
  },
  "7WI8": {
      "icao": "7WI8",
      "iata": "",
      "name": "Crispy Cedars Airport",
      "city": "Brussels",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 700,
      "lat": 44.7806015015,
      "lon": -87.6024017334,
      "tz": "America\/Chicago"
  },
  "7WI9": {
      "icao": "7WI9",
      "iata": "",
      "name": "Nett Construction Airport",
      "city": "Fond Du Lac",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1040,
      "lat": 43.8305015564,
      "lon": -88.3254013062,
      "tz": "America\/Chicago"
  },
  "7XS0": {
      "icao": "7XS0",
      "iata": "",
      "name": "Polly Ranch Airport",
      "city": "Friendswood",
      "state": "Texas",
      "country": "US",
      "elevation": 24,
      "lat": 29.504699707,
      "lon": -95.1754989624,
      "tz": "America\/Chicago"
  },
  "7XS1": {
      "icao": "7XS1",
      "iata": "",
      "name": "Flying E Ranch Airport",
      "city": "Springtown",
      "state": "Texas",
      "country": "US",
      "elevation": 850,
      "lat": 32.9933013916,
      "lon": -97.676902771,
      "tz": "America\/Chicago"
  },
  "7XS2": {
      "icao": "7XS2",
      "iata": "",
      "name": "Kenley's Mustang Prairie Ranch Airport",
      "city": "Crockett",
      "state": "Texas",
      "country": "US",
      "elevation": 300,
      "lat": 31.2423992157,
      "lon": -95.6041030884,
      "tz": "America\/Chicago"
  },
  "7XS3": {
      "icao": "7XS3",
      "iata": "",
      "name": "W C Ranch Airport",
      "city": "Grapeland",
      "state": "Texas",
      "country": "US",
      "elevation": 337,
      "lat": 31.476600647,
      "lon": -95.6350021362,
      "tz": "America\/Chicago"
  },
  "7XS5": {
      "icao": "7XS5",
      "iata": "",
      "name": "Christian Ranch Airport",
      "city": "Eldorado",
      "state": "Texas",
      "country": "US",
      "elevation": 2425,
      "lat": 30.8603000641,
      "lon": -100.5670013428,
      "tz": "America\/Chicago"
  },
  "7XS6": {
      "icao": "7XS6",
      "iata": "",
      "name": "Moore Hx Ranch Airport",
      "city": "Fairfield",
      "state": "Texas",
      "country": "US",
      "elevation": 400,
      "lat": 31.7943992615,
      "lon": -96.2080001831,
      "tz": "America\/Chicago"
  },
  "7XS7": {
      "icao": "7XS7",
      "iata": "",
      "name": "Indian Springs Ranch Airport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 2030,
      "lat": 30.1147003174,
      "lon": -98.931098938,
      "tz": "America\/Chicago"
  },
  "80AR": {
      "icao": "80AR",
      "iata": "",
      "name": "Price Field",
      "city": "Joiner",
      "state": "Arkansas",
      "country": "US",
      "elevation": 236,
      "lat": 35.5000991821,
      "lon": -90.2583999634,
      "tz": "America\/Chicago"
  },
  "80CA": {
      "icao": "80CA",
      "iata": "",
      "name": "Bacchi Valley Industries Airport",
      "city": "Lotus",
      "state": "California",
      "country": "US",
      "elevation": 760,
      "lat": 38.817401886,
      "lon": -120.9219970703,
      "tz": "America\/Los_Angeles"
  },
  "80CO": {
      "icao": "80CO",
      "iata": "",
      "name": "Simonson Field",
      "city": "Pueblo",
      "state": "Colorado",
      "country": "US",
      "elevation": 5130,
      "lat": 38.1516990662,
      "lon": -104.7060012817,
      "tz": "America\/Denver"
  },
  "80FD": {
      "icao": "80FD",
      "iata": "",
      "name": "Brady Ranch Airport",
      "city": "Indiantown",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 27.1133995056,
      "lon": -80.6333999634,
      "tz": "America\/New_York"
  },
  "80GA": {
      "icao": "80GA",
      "iata": "",
      "name": "Murphy's Landing Airport",
      "city": "Grantville",
      "state": "Georgia",
      "country": "US",
      "elevation": 820,
      "lat": 33.2587013245,
      "lon": -84.8447036743,
      "tz": "America\/New_York"
  },
  "80IN": {
      "icao": "80IN",
      "iata": "",
      "name": "Gustin's \/Private\/ Airport",
      "city": "Harlan",
      "state": "Indiana",
      "country": "US",
      "elevation": 740,
      "lat": 41.2042007446,
      "lon": -84.8051986694,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "80IS": {
      "icao": "80IS",
      "iata": "",
      "name": "Martin Airport",
      "city": "Hillview",
      "state": "Illinois",
      "country": "US",
      "elevation": 425,
      "lat": 39.4169998169,
      "lon": -90.5859985352,
      "tz": "America\/Chicago"
  },
  "80KY": {
      "icao": "80KY",
      "iata": "",
      "name": "David Lowe Airport",
      "city": "Sacramento",
      "state": "Kentucky",
      "country": "US",
      "elevation": 465,
      "lat": 37.3961982727,
      "lon": -87.235496521,
      "tz": "America\/Chicago"
  },
  "80LA": {
      "icao": "80LA",
      "iata": "",
      "name": "Luscombe Lane 1 Airport",
      "city": "Eunice",
      "state": "Louisiana",
      "country": "US",
      "elevation": 42,
      "lat": 30.4263000488,
      "lon": -92.3181991577,
      "tz": "America\/Chicago"
  },
  "80MO": {
      "icao": "80MO",
      "iata": "",
      "name": "Seiferd Field",
      "city": "Carthage",
      "state": "Missouri",
      "country": "US",
      "elevation": 980,
      "lat": 37.276536,
      "lon": -94.351897,
      "tz": "America\/Chicago"
  },
  "80NC": {
      "icao": "80NC",
      "iata": "",
      "name": "May Airport",
      "city": "Greensboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 777,
      "lat": 35.9990005493,
      "lon": -79.6893005371,
      "tz": "America\/New_York"
  },
  "80ND": {
      "icao": "80ND",
      "iata": "",
      "name": "Troy Field",
      "city": "Crosby",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2080,
      "lat": 48.8786010742,
      "lon": -103.3700027466,
      "tz": "America\/Chicago"
  },
  "80NE": {
      "icao": "80NE",
      "iata": "",
      "name": "R & R Farms Airport",
      "city": "Davenport",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1620,
      "lat": 40.2817001343,
      "lon": -97.8542022705,
      "tz": "America\/Chicago"
  },
  "80OH": {
      "icao": "80OH",
      "iata": "",
      "name": "Sloas Airport",
      "city": "Warren",
      "state": "Ohio",
      "country": "US",
      "elevation": 885,
      "lat": 41.2667007446,
      "lon": -80.7665023804,
      "tz": "America\/New_York"
  },
  "80OK": {
      "icao": "80OK",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Reydon",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2440,
      "lat": 35.5766983032,
      "lon": -99.8861999512,
      "tz": "America\/Chicago"
  },
  "80OR": {
      "icao": "80OR",
      "iata": "",
      "name": "Wilderness Airport",
      "city": "Bly",
      "state": "Oregon",
      "country": "US",
      "elevation": 4540,
      "lat": 42.3642997742,
      "lon": -120.966003418,
      "tz": "America\/Los_Angeles"
  },
  "80PN": {
      "icao": "80PN",
      "iata": "",
      "name": "Hanny Beaver Airpark Inc Airport",
      "city": "Hookstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1220,
      "lat": 40.5416984558,
      "lon": -80.4525985718,
      "tz": "America\/New_York"
  },
  "80TN": {
      "icao": "80TN",
      "iata": "",
      "name": "Big T Airport",
      "city": "Lenoir City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 900,
      "lat": 35.8266983032,
      "lon": -84.3300018311,
      "tz": "America\/New_York"
  },
  "80TS": {
      "icao": "80TS",
      "iata": "",
      "name": "M Y Ranch Airport",
      "city": "Crockett",
      "state": "Texas",
      "country": "US",
      "elevation": 300,
      "lat": 31.3276996613,
      "lon": -95.5729980469,
      "tz": "America\/Chicago"
  },
  "80TX": {
      "icao": "80TX",
      "iata": "",
      "name": "Resaca Airstrip",
      "city": "Brownsville",
      "state": "Texas",
      "country": "US",
      "elevation": 20,
      "lat": 25.926700592,
      "lon": -97.4085998535,
      "tz": "America\/Chicago"
  },
  "80VA": {
      "icao": "80VA",
      "iata": "",
      "name": "Holly Springs Airport",
      "city": "Richmond",
      "state": "Virginia",
      "country": "US",
      "elevation": 250,
      "lat": 37.7913017273,
      "lon": -77.8246994019,
      "tz": "America\/New_York"
  },
  "80WA": {
      "icao": "80WA",
      "iata": "",
      "name": "Sfs Airpark",
      "city": "Hadlock",
      "state": "Washington",
      "country": "US",
      "elevation": 120,
      "lat": 48.0358009338,
      "lon": -122.7740020752,
      "tz": "America\/Los_Angeles"
  },
  "80WI": {
      "icao": "80WI",
      "iata": "",
      "name": "Spring Creek Airport",
      "city": "Spring Lake",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 44.0229988098,
      "lon": -89.1384963989,
      "tz": "America\/Chicago"
  },
  "80XS": {
      "icao": "80XS",
      "iata": "",
      "name": "Three Acres Airport",
      "city": "Woodbine",
      "state": "Texas",
      "country": "US",
      "elevation": 775,
      "lat": 33.6432991028,
      "lon": -97.0021972656,
      "tz": "America\/Chicago"
  },
  "81AK": {
      "icao": "81AK",
      "iata": "",
      "name": "Mc Kinley Country Airport",
      "city": "Trapper Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 600,
      "lat": 62.5676994324,
      "lon": -150.235992432,
      "tz": "America\/Anchorage"
  },
  "81AR": {
      "icao": "81AR",
      "iata": "",
      "name": "Smith's Strip",
      "city": "Lonoke",
      "state": "Arkansas",
      "country": "US",
      "elevation": 250,
      "lat": 34.7500991821,
      "lon": -91.8487014771,
      "tz": "America\/Chicago"
  },
  "81CA": {
      "icao": "81CA",
      "iata": "",
      "name": "7-M Ranch Airport",
      "city": "Middletown",
      "state": "California",
      "country": "US",
      "elevation": 1153,
      "lat": 38.7316017151,
      "lon": -122.56300354,
      "tz": "America\/Los_Angeles"
  },
  "81CO": {
      "icao": "81CO",
      "iata": "",
      "name": "Mesa 1 Airport",
      "city": "Steamboat Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 7000,
      "lat": 40.4061012268,
      "lon": -106.8209991455,
      "tz": "America\/Denver"
  },
  "81FL": {
      "icao": "81FL",
      "iata": "",
      "name": "Cox's Hammock Airport",
      "city": "Indiantown",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 27.0716991425,
      "lon": -80.5201034546,
      "tz": "America\/New_York"
  },
  "81GA": {
      "icao": "81GA",
      "iata": "",
      "name": "Lucky Lairds Landing Airport",
      "city": "Villa Rica",
      "state": "Georgia",
      "country": "US",
      "elevation": 1130,
      "lat": 33.6408996582,
      "lon": -84.9048995972,
      "tz": "America\/New_York"
  },
  "81IL": {
      "icao": "81IL",
      "iata": "",
      "name": "Illinois Valley Parachute Club Airport",
      "city": "Minier",
      "state": "Illinois",
      "country": "US",
      "elevation": 646,
      "lat": 40.4352989197,
      "lon": -89.3592987061,
      "tz": "America\/Chicago"
  },
  "81KS": {
      "icao": "81KS",
      "iata": "",
      "name": "Blackhawk Airport",
      "city": "Meriden",
      "state": "Kansas",
      "country": "US",
      "elevation": 1015,
      "lat": 39.1562004089,
      "lon": -95.5774993896,
      "tz": "America\/Chicago"
  },
  "81KY": {
      "icao": "81KY",
      "iata": "",
      "name": "Hi Rise Farm Airport",
      "city": "New Liberty",
      "state": "Kentucky",
      "country": "US",
      "elevation": 480,
      "lat": 38.5783004761,
      "lon": -85.0175018311,
      "tz": "America\/New_York"
  },
  "81LL": {
      "icao": "81LL",
      "iata": "",
      "name": "Otterbach Farm Airport",
      "city": "Mendota",
      "state": "Illinois",
      "country": "US",
      "elevation": 840,
      "lat": 41.5691986084,
      "lon": -89.1736984253,
      "tz": "America\/Chicago"
  },
  "81MN": {
      "icao": "81MN",
      "iata": "",
      "name": "Ewen Landing Field",
      "city": "Jeffers",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1483,
      "lat": 44.0304985046,
      "lon": -95.1955032349,
      "tz": "America\/Chicago"
  },
  "81NC": {
      "icao": "81NC",
      "iata": "",
      "name": "Cox Field",
      "city": "Tabor City",
      "state": "North Carolina",
      "country": "US",
      "elevation": 50,
      "lat": 34.0408325195,
      "lon": -78.7399978638,
      "tz": "America\/New_York"
  },
  "81ND": {
      "icao": "81ND",
      "iata": "",
      "name": "Millers Airstrip",
      "city": "Deering",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1545,
      "lat": 48.3885993958,
      "lon": -101.0449981689,
      "tz": "America\/Chicago"
  },
  "81NE": {
      "icao": "81NE",
      "iata": "",
      "name": "Bunger Field",
      "city": "Hildreth",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2170,
      "lat": 40.273601532,
      "lon": -99.0078964233,
      "tz": "America\/Chicago"
  },
  "81NM": {
      "icao": "81NM",
      "iata": "",
      "name": "Diamond A Ranch Airport",
      "city": "Picacho",
      "state": "New Mexico",
      "country": "US",
      "elevation": 5056,
      "lat": 33.3451004028,
      "lon": -105.1800003052,
      "tz": "America\/Denver"
  },
  "81OH": {
      "icao": "81OH",
      "iata": "",
      "name": "Elbel Airport",
      "city": "Hamersville",
      "state": "Ohio",
      "country": "US",
      "elevation": 975,
      "lat": 38.8917007446,
      "lon": -83.983001709,
      "tz": "America\/New_York"
  },
  "81OK": {
      "icao": "81OK",
      "iata": "",
      "name": "Twin Lakes Ranch Airport",
      "city": "Granite",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1630,
      "lat": 35.0264015198,
      "lon": -99.3394012451,
      "tz": "America\/Chicago"
  },
  "81OR": {
      "icao": "81OR",
      "iata": "",
      "name": "Wagontire Airport",
      "city": "Burns",
      "state": "Oregon",
      "country": "US",
      "elevation": 4725,
      "lat": 43.2499008179,
      "lon": -119.875,
      "tz": "America\/Los_Angeles"
  },
  "81TE": {
      "icao": "81TE",
      "iata": "",
      "name": "Horn Ranch Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1290,
      "lat": 29.5419006348,
      "lon": -100.6169967651,
      "tz": "America\/Chicago"
  },
  "81TS": {
      "icao": "81TS",
      "iata": "",
      "name": "Dibrell Airport",
      "city": "Crockett",
      "state": "Texas",
      "country": "US",
      "elevation": 385,
      "lat": 31.4437999725,
      "lon": -95.2754974365,
      "tz": "America\/Chicago"
  },
  "81TX": {
      "icao": "81TX",
      "iata": "",
      "name": "La Leona Airport",
      "city": "Terlingua",
      "state": "Texas",
      "country": "US",
      "elevation": 2590,
      "lat": 29.3432998657,
      "lon": -103.5650024414,
      "tz": "America\/Chicago"
  },
  "81VA": {
      "icao": "81VA",
      "iata": "",
      "name": "Ferguson Airport",
      "city": "Rocky Mount",
      "state": "Virginia",
      "country": "US",
      "elevation": 1050,
      "lat": 37.1180992126,
      "lon": -79.7885971069,
      "tz": "America\/New_York"
  },
  "81WI": {
      "icao": "81WI",
      "iata": "",
      "name": "Gunner Field",
      "city": "Athens",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1465,
      "lat": 45.0786018372,
      "lon": -90.1764984131,
      "tz": "America\/Chicago"
  },
  "82AK": {
      "icao": "82AK",
      "iata": "",
      "name": "Jim's Landing Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 425,
      "lat": 61.6166000366,
      "lon": -149.1909942627,
      "tz": "America\/Anchorage"
  },
  "82CL": {
      "icao": "82CL",
      "iata": "FOB",
      "name": "Fort Bragg Airport",
      "city": "Fort Bragg",
      "state": "California",
      "country": "US",
      "elevation": 71,
      "lat": 39.4743003845,
      "lon": -123.79599762,
      "tz": "America\/Los_Angeles"
  },
  "82FL": {
      "icao": "82FL",
      "iata": "",
      "name": "Br Ranch Airport",
      "city": "Jupiter",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 26.9141998291,
      "lon": -80.2052993774,
      "tz": "America\/New_York"
  },
  "82GA": {
      "icao": "82GA",
      "iata": "",
      "name": "Paces South Farms Airport",
      "city": "Wadley",
      "state": "Georgia",
      "country": "US",
      "elevation": 275,
      "lat": 32.8087997437,
      "lon": -82.3852996826,
      "tz": "America\/New_York"
  },
  "82IN": {
      "icao": "82IN",
      "iata": "",
      "name": "Hunter Airport",
      "city": "North Manchester",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 40.9812011719,
      "lon": -85.9291992188,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "82IS": {
      "icao": "82IS",
      "iata": "",
      "name": "Landings Condominium Airport",
      "city": "Huntley",
      "state": "Illinois",
      "country": "US",
      "elevation": 880,
      "lat": 42.1431007385,
      "lon": -88.4029006958,
      "tz": "America\/Chicago"
  },
  "82KY": {
      "icao": "82KY",
      "iata": "",
      "name": "Woodledge Farm Airport",
      "city": "Crestwood",
      "state": "Kentucky",
      "country": "US",
      "elevation": 680,
      "lat": 38.2834014893,
      "lon": -85.4819030762,
      "tz": "America\/Kentucky\/Louisville"
  },
  "82LA": {
      "icao": "82LA",
      "iata": "",
      "name": "Fisher's Field",
      "city": "Chalmette",
      "state": "Louisiana",
      "country": "US",
      "elevation": 5,
      "lat": 29.9323997498,
      "lon": -89.932800293,
      "tz": "America\/Chicago"
  },
  "82LL": {
      "icao": "82LL",
      "iata": "",
      "name": "Cheechako Airport",
      "city": "Monroe Center",
      "state": "Illinois",
      "country": "US",
      "elevation": 860,
      "lat": 42.1295013428,
      "lon": -88.9923019409,
      "tz": "America\/Chicago"
  },
  "82NC": {
      "icao": "82NC",
      "iata": "",
      "name": "Pineview Air Airport",
      "city": "Sanford",
      "state": "North Carolina",
      "country": "US",
      "elevation": 300,
      "lat": 35.3081016541,
      "lon": -79.0733032227,
      "tz": "America\/New_York"
  },
  "82NM": {
      "icao": "82NM",
      "iata": "",
      "name": "Skeen Ranch Airport",
      "city": "Picacho",
      "state": "New Mexico",
      "country": "US",
      "elevation": 5524,
      "lat": 33.1701011658,
      "lon": -105.1340026855,
      "tz": "America\/Denver"
  },
  "82NY": {
      "icao": "82NY",
      "iata": "",
      "name": "Silvernails Field",
      "city": "Gallatin",
      "state": "New York",
      "country": "US",
      "elevation": 571,
      "lat": 42.001389,
      "lon": -73.67111,
      "tz": "America\/New_York"
  },
  "82OH": {
      "icao": "82OH",
      "iata": "",
      "name": "Rocky Ridge Airpark",
      "city": "Waterville",
      "state": "Ohio",
      "country": "US",
      "elevation": 650,
      "lat": 41.5078010559,
      "lon": -83.7713012695,
      "tz": "America\/New_York"
  },
  "82OI": {
      "icao": "82OI",
      "iata": "",
      "name": "J-Em Farm Airport",
      "city": "Columbus",
      "state": "Ohio",
      "country": "US",
      "elevation": 1005,
      "lat": 40.1542015076,
      "lon": -83.2749023438,
      "tz": "America\/New_York"
  },
  "82OR": {
      "icao": "82OR",
      "iata": "",
      "name": "Lost Creek Airport",
      "city": "Dexter",
      "state": "Oregon",
      "country": "US",
      "elevation": 700,
      "lat": 43.8992996216,
      "lon": -122.8170013428,
      "tz": "America\/Los_Angeles"
  },
  "82PA": {
      "icao": "82PA",
      "iata": "",
      "name": "Frame Field",
      "city": "Candor",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1210,
      "lat": 40.3967018127,
      "lon": -80.3052978516,
      "tz": "America\/New_York"
  },
  "82TE": {
      "icao": "82TE",
      "iata": "",
      "name": "Tarry Bank Airport",
      "city": "Leon Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 1380,
      "lat": 29.6916007996,
      "lon": -98.7074966431,
      "tz": "America\/Chicago"
  },
  "82TS": {
      "icao": "82TS",
      "iata": "",
      "name": "Elmdale Airpark",
      "city": "Abilene",
      "state": "Texas",
      "country": "US",
      "elevation": 1775,
      "lat": 32.4500999451,
      "lon": -99.6503982544,
      "tz": "America\/Chicago"
  },
  "82VA": {
      "icao": "82VA",
      "iata": "",
      "name": "Root Field",
      "city": "Verona",
      "state": "Virginia",
      "country": "US",
      "elevation": 1290,
      "lat": 38.1731987,
      "lon": -78.9692001343,
      "tz": "America\/New_York"
  },
  "82WI": {
      "icao": "82WI",
      "iata": "",
      "name": "Triple S Ranch Airport",
      "city": "Whitelaw",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 855,
      "lat": 44.1492004395,
      "lon": -87.8143997192,
      "tz": "America\/Chicago"
  },
  "82XS": {
      "icao": "82XS",
      "iata": "",
      "name": "Circle P Ranch Airport",
      "city": "Floresville",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 29.1026992798,
      "lon": -98.2166976929,
      "tz": "America\/Chicago"
  },
  "83AK": {
      "icao": "83AK",
      "iata": "",
      "name": "Tolovana Hot Springs Airport",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 800,
      "lat": 65.2565002441,
      "lon": -148.8300018311,
      "tz": "America\/Anchorage"
  },
  "83FD": {
      "icao": "83FD",
      "iata": "",
      "name": "Lake Montaza Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 52,
      "lat": 27.5802993774,
      "lon": -80.8152999878,
      "tz": "America\/New_York"
  },
  "83FL": {
      "icao": "83FL",
      "iata": "",
      "name": "Nassau Airport",
      "city": "Yulee",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 30.6187992096,
      "lon": -81.5336990356,
      "tz": "America\/New_York"
  },
  "83GA": {
      "icao": "83GA",
      "iata": "",
      "name": "Brock Airpark",
      "city": "Bainbridge",
      "state": "Georgia",
      "country": "US",
      "elevation": 130,
      "lat": 30.9997005463,
      "lon": -84.5061035156,
      "tz": "America\/New_York"
  },
  "83IL": {
      "icao": "83IL",
      "iata": "",
      "name": "Oltman-Shuck Airport",
      "city": "Minonk",
      "state": "Illinois",
      "country": "US",
      "elevation": 736,
      "lat": 40.8927993774,
      "lon": -89.1039962769,
      "tz": "America\/Chicago"
  },
  "83IN": {
      "icao": "83IN",
      "iata": "",
      "name": "Goodenough Airport",
      "city": "Peru",
      "state": "Indiana",
      "country": "US",
      "elevation": 685,
      "lat": 40.7397994995,
      "lon": -86.00050354,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "83IS": {
      "icao": "83IS",
      "iata": "",
      "name": "Prairie Airport",
      "city": "Irvington",
      "state": "Illinois",
      "country": "US",
      "elevation": 550,
      "lat": 38.426399231,
      "lon": -89.1312026978,
      "tz": "America\/Chicago"
  },
  "83KS": {
      "icao": "83KS",
      "iata": "",
      "name": "Miller Aeroplane Field",
      "city": "Wamego",
      "state": "Kansas",
      "country": "US",
      "elevation": 1020,
      "lat": 39.2352981567,
      "lon": -96.299697876,
      "tz": "America\/Chicago"
  },
  "83LA": {
      "icao": "83LA",
      "iata": "",
      "name": "Ledet Airfield",
      "city": "Cut Off",
      "state": "Louisiana",
      "country": "US",
      "elevation": 0,
      "lat": 29.5555000305,
      "lon": -90.329498291,
      "tz": "America\/Chicago"
  },
  "83LL": {
      "icao": "83LL",
      "iata": "",
      "name": "Lindauer Airport",
      "city": "New Athens",
      "state": "Illinois",
      "country": "US",
      "elevation": 437,
      "lat": 38.3036003113,
      "lon": -89.8551025391,
      "tz": "America\/Chicago"
  },
  "83MU": {
      "icao": "83MU",
      "iata": "",
      "name": "Dalbom Ultralightport",
      "city": "Stella",
      "state": "Missouri",
      "country": "US",
      "elevation": 1130,
      "lat": 36.7690010071,
      "lon": -94.2124023438,
      "tz": "America\/Chicago"
  },
  "83NE": {
      "icao": "83NE",
      "iata": "",
      "name": "Abbott Airport",
      "city": "Long Pine",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2560,
      "lat": 42.3250007629,
      "lon": -99.7559967041,
      "tz": "America\/Chicago"
  },
  "83NM": {
      "icao": "83NM",
      "iata": "",
      "name": "King Ranch Airport",
      "city": "Pie Town",
      "state": "New Mexico",
      "country": "US",
      "elevation": 7143,
      "lat": 34.6481018066,
      "lon": -108.0059967041,
      "tz": "America\/Denver"
  },
  "83OH": {
      "icao": "83OH",
      "iata": "",
      "name": "Adams Strip",
      "city": "Pleasent Hill",
      "state": "Ohio",
      "country": "US",
      "elevation": 973,
      "lat": 40.0623016357,
      "lon": -84.3794021606,
      "tz": "America\/New_York"
  },
  "83OK": {
      "icao": "83OK",
      "iata": "",
      "name": "Sweetbriar Airport",
      "city": "Murry Spur",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 560,
      "lat": 35.2568016052,
      "lon": -94.5780029297,
      "tz": "America\/Chicago"
  },
  "83OR": {
      "icao": "83OR",
      "iata": "",
      "name": "Tamarack Springs Ranch Airport",
      "city": "Lostine",
      "state": "Oregon",
      "country": "US",
      "elevation": 3445,
      "lat": 45.5013008118,
      "lon": -117.4720001221,
      "tz": "America\/Los_Angeles"
  },
  "83TA": {
      "icao": "83TA",
      "iata": "",
      "name": "Rainbow Field",
      "city": "Purdon",
      "state": "Texas",
      "country": "US",
      "elevation": 365,
      "lat": 31.9002990723,
      "lon": -96.6003036499,
      "tz": "America\/Chicago"
  },
  "83TE": {
      "icao": "83TE",
      "iata": "",
      "name": "K Bar Ranch Airport",
      "city": "Sabinal",
      "state": "Texas",
      "country": "US",
      "elevation": 1208,
      "lat": 29.4969005585,
      "lon": -99.4938964844,
      "tz": "America\/Chicago"
  },
  "83TS": {
      "icao": "83TS",
      "iata": "",
      "name": "Moore Ranch Airport",
      "city": "Gordon",
      "state": "Texas",
      "country": "US",
      "elevation": 1200,
      "lat": 32.426700592,
      "lon": -98.4197006226,
      "tz": "America\/Chicago"
  },
  "83TX": {
      "icao": "83TX",
      "iata": "",
      "name": "Texas A And M Flight Test Station Airport",
      "city": "Bryan",
      "state": "Texas",
      "country": "US",
      "elevation": 264,
      "lat": 30.6334991455,
      "lon": -96.4835968018,
      "tz": "America\/Chicago"
  },
  "83WA": {
      "icao": "83WA",
      "iata": "",
      "name": "Darcy's Air Strip",
      "city": "Walla Walla",
      "state": "Washington",
      "country": "US",
      "elevation": 2430,
      "lat": 46.0275993347,
      "lon": -118.1679992676,
      "tz": "America\/Los_Angeles"
  },
  "83WI": {
      "icao": "83WI",
      "iata": "",
      "name": "Jones Airport",
      "city": "Dorchester",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1410,
      "lat": 44.970500946,
      "lon": -90.3461990356,
      "tz": "America\/Chicago"
  },
  "84AL": {
      "icao": "84AL",
      "iata": "",
      "name": "Blessings Landing Air Ranch",
      "city": "Pinckard",
      "state": "Alabama",
      "country": "US",
      "elevation": 400,
      "lat": 31.3031,
      "lon": -85.52705,
      "tz": "America\/Chicago"
  },
  "84IL": {
      "icao": "84IL",
      "iata": "",
      "name": "Rossi's Farm Airport",
      "city": "Minooka",
      "state": "Illinois",
      "country": "US",
      "elevation": 580,
      "lat": 41.5069999695,
      "lon": -88.2778015137,
      "tz": "America\/Chicago"
  },
  "84KY": {
      "icao": "84KY",
      "iata": "",
      "name": "Greene County Parachute Center Airport",
      "city": "Bardstown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 650,
      "lat": 37.8152999878,
      "lon": -85.5400009155,
      "tz": "America\/New_York"
  },
  "84LA": {
      "icao": "84LA",
      "iata": "",
      "name": "Touchstone Ridge Ultralightport",
      "city": "Haughton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 180,
      "lat": 32.552898407,
      "lon": -93.5932006836,
      "tz": "America\/Chicago"
  },
  "84MI": {
      "icao": "84MI",
      "iata": "",
      "name": "Timbers Sky Camp Airport",
      "city": "South Branch",
      "state": "Michigan",
      "country": "US",
      "elevation": 945,
      "lat": 44.4695014954,
      "lon": -83.8818969727,
      "tz": "America\/Detroit"
  },
  "84MN": {
      "icao": "84MN",
      "iata": "",
      "name": "Nielsville Airport",
      "city": "Nielsville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 869,
      "lat": 47.5332984924,
      "lon": -96.8309020996,
      "tz": "America\/Chicago"
  },
  "84MT": {
      "icao": "84MT",
      "iata": "",
      "name": "Shimmon Airport",
      "city": "Corvallis",
      "state": "Montana",
      "country": "US",
      "elevation": 3920,
      "lat": 46.280814,
      "lon": -114.050214,
      "tz": "America\/Denver"
  },
  "84NC": {
      "icao": "84NC",
      "iata": "",
      "name": "Rooster Field",
      "city": "Cameron",
      "state": "North Carolina",
      "country": "US",
      "elevation": 340,
      "lat": 35.273333,
      "lon": -79.248611,
      "tz": "America\/New_York"
  },
  "84ND": {
      "icao": "84ND",
      "iata": "",
      "name": "Kyllo Airport",
      "city": "Mc Canna",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1150,
      "lat": 48.0008010864,
      "lon": -97.7570037842,
      "tz": "America\/Chicago"
  },
  "84NE": {
      "icao": "84NE",
      "iata": "",
      "name": "Hewetts Airport",
      "city": "Beaver City",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2275,
      "lat": 40.1707992554,
      "lon": -99.8171005249,
      "tz": "America\/Chicago"
  },
  "84NM": {
      "icao": "84NM",
      "iata": "",
      "name": "Nalda Ranch Airport",
      "city": "Pie Town",
      "state": "New Mexico",
      "country": "US",
      "elevation": 7531,
      "lat": 34.3534011841,
      "lon": -108.1620025635,
      "tz": "America\/Denver"
  },
  "84NY": {
      "icao": "84NY",
      "iata": "",
      "name": "Skyview Airport",
      "city": "Rush",
      "state": "New York",
      "country": "US",
      "elevation": 642,
      "lat": 42.9861984253,
      "lon": -77.6125030518,
      "tz": "America\/New_York"
  },
  "84OH": {
      "icao": "84OH",
      "iata": "",
      "name": "Hanshell Flying Apple Airport",
      "city": "Octa",
      "state": "Ohio",
      "country": "US",
      "elevation": 1055,
      "lat": 39.581199646,
      "lon": -83.6510009766,
      "tz": "America\/New_York"
  },
  "84OK": {
      "icao": "84OK",
      "iata": "",
      "name": "Collier Farms Airport",
      "city": "Taloga",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1740,
      "lat": 35.9388999939,
      "lon": -99.0531997681,
      "tz": "America\/Chicago"
  },
  "84OL": {
      "icao": "84OL",
      "iata": "",
      "name": "Cotton Field",
      "city": "Broken Arrow",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 685,
      "lat": 36.1104011536,
      "lon": -95.7655029297,
      "tz": "America\/Chicago"
  },
  "84OR": {
      "icao": "84OR",
      "iata": "",
      "name": "B Bar Ranch Airport",
      "city": "Selma",
      "state": "Oregon",
      "country": "US",
      "elevation": 1400,
      "lat": 42.2476005554,
      "lon": -123.5699996948,
      "tz": "America\/Los_Angeles"
  },
  "84PN": {
      "icao": "84PN",
      "iata": "",
      "name": "Draco STOLport",
      "city": "Stewartstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 625,
      "lat": 39.7526016235,
      "lon": -76.5354995728,
      "tz": "America\/New_York"
  },
  "84TE": {
      "icao": "84TE",
      "iata": "",
      "name": "W4 Ranch Airport",
      "city": "Morgan",
      "state": "Texas",
      "country": "US",
      "elevation": 833,
      "lat": 31.988067,
      "lon": -97.543181,
      "tz": "America\/Chicago"
  },
  "84TX": {
      "icao": "84TX",
      "iata": "",
      "name": "Camp Longhorn Airport",
      "city": "Buchanan Dam",
      "state": "Texas",
      "country": "US",
      "elevation": 934,
      "lat": 30.7413005829,
      "lon": -98.3786010742,
      "tz": "America\/Chicago"
  },
  "84WA": {
      "icao": "84WA",
      "iata": "",
      "name": "D and B Airpark",
      "city": "Elma",
      "state": "Washington",
      "country": "US",
      "elevation": 55,
      "lat": 46.9729003906,
      "lon": -123.3840026855,
      "tz": "America\/Los_Angeles"
  },
  "84XS": {
      "icao": "84XS",
      "iata": "",
      "name": "Lang Ranch Airport",
      "city": "Mountain Home",
      "state": "Texas",
      "country": "US",
      "elevation": 2200,
      "lat": 30.255,
      "lon": -99.398889,
      "tz": "America\/Chicago"
  },
  "85FA": {
      "icao": "85FA",
      "iata": "",
      "name": "North Exuma Airport",
      "city": "Deland",
      "state": "Florida",
      "country": "US",
      "elevation": 62,
      "lat": 29.0949001312,
      "lon": -81.2776031494,
      "tz": "America\/New_York"
  },
  "85FL": {
      "icao": "85FL",
      "iata": "",
      "name": "Thomas Farms Airport",
      "city": "Jay",
      "state": "Florida",
      "country": "US",
      "elevation": 215,
      "lat": 30.9692993164,
      "lon": -87.0515975952,
      "tz": "America\/Chicago"
  },
  "85GA": {
      "icao": "85GA",
      "iata": "",
      "name": "Aiken Field",
      "city": "Talmo",
      "state": "Georgia",
      "country": "US",
      "elevation": 1020,
      "lat": 34.1722984314,
      "lon": -83.7310028076,
      "tz": "America\/New_York"
  },
  "85IA": {
      "icao": "85IA",
      "iata": "",
      "name": "Henry Airport",
      "city": "Floyd",
      "state": "Iowa",
      "country": "US",
      "elevation": 1125,
      "lat": 43.1786003113,
      "lon": -92.6974029541,
      "tz": "America\/Chicago"
  },
  "85IL": {
      "icao": "85IL",
      "iata": "",
      "name": "Durbin Airport",
      "city": "Sullivan",
      "state": "Illinois",
      "country": "US",
      "elevation": 664,
      "lat": 39.637298584,
      "lon": -88.6359024048,
      "tz": "America\/Chicago"
  },
  "85IN": {
      "icao": "85IN",
      "iata": "",
      "name": "Leak Airport",
      "city": "Tab",
      "state": "Indiana",
      "country": "US",
      "elevation": 720,
      "lat": 40.4338989258,
      "lon": -87.5049972534,
      "tz": "America\/Chicago"
  },
  "85KS": {
      "icao": "85KS",
      "iata": "",
      "name": "Sanders Airport",
      "city": "Winchester",
      "state": "Kansas",
      "country": "US",
      "elevation": 1070,
      "lat": 39.3009986877,
      "lon": -95.2145004272,
      "tz": "America\/Chicago"
  },
  "85KY": {
      "icao": "85KY",
      "iata": "",
      "name": "Deer Run Airpark",
      "city": "New Castle",
      "state": "Kentucky",
      "country": "US",
      "elevation": 890,
      "lat": 38.4702987671,
      "lon": -85.1386032104,
      "tz": "America\/New_York"
  },
  "85LL": {
      "icao": "85LL",
      "iata": "",
      "name": "Wormley Airport",
      "city": "Oswego",
      "state": "Illinois",
      "country": "US",
      "elevation": 680,
      "lat": 41.6838989258,
      "lon": -88.262298584,
      "tz": "America\/Chicago"
  },
  "85MI": {
      "icao": "85MI",
      "iata": "",
      "name": "Dewind Field",
      "city": "Zeeland",
      "state": "Michigan",
      "country": "US",
      "elevation": 640,
      "lat": 42.9323005676,
      "lon": -86.0138015747,
      "tz": "America\/Detroit"
  },
  "85MN": {
      "icao": "85MN",
      "iata": "",
      "name": "Christison Airport",
      "city": "Plainview",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1175,
      "lat": 44.1585998535,
      "lon": -92.2021026611,
      "tz": "America\/Chicago"
  },
  "85MO": {
      "icao": "85MO",
      "iata": "",
      "name": "Willhite Airport",
      "city": "Swedeborg",
      "state": "Missouri",
      "country": "US",
      "elevation": 1022,
      "lat": 37.875,
      "lon": -92.3224029541,
      "tz": "America\/Chicago"
  },
  "85NC": {
      "icao": "85NC",
      "iata": "",
      "name": "Reagans Roost Ultralightport",
      "city": "Whittier",
      "state": "North Carolina",
      "country": "US",
      "elevation": 2080,
      "lat": 35.4291992188,
      "lon": -83.1668014526,
      "tz": "America\/New_York"
  },
  "85NE": {
      "icao": "85NE",
      "iata": "",
      "name": "Meyers Freedom Flight Hardy Airport",
      "city": "Hardy",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1624,
      "lat": 40.0208015442,
      "lon": -97.9420013428,
      "tz": "America\/Chicago"
  },
  "85NY": {
      "icao": "85NY",
      "iata": "",
      "name": "High Acres Airport",
      "city": "Sardinia",
      "state": "New York",
      "country": "US",
      "elevation": 1540,
      "lat": 42.5783996582,
      "lon": -78.5199966431,
      "tz": "America\/New_York"
  },
  "85OH": {
      "icao": "85OH",
      "iata": "",
      "name": "Tounshendeaux Airport",
      "city": "West Farmington",
      "state": "Ohio",
      "country": "US",
      "elevation": 838,
      "lat": 41.4137001038,
      "lon": -80.9469985962,
      "tz": "America\/New_York"
  },
  "85OL": {
      "icao": "85OL",
      "iata": "",
      "name": "Huscher Field",
      "city": "Cache",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1242,
      "lat": 34.6194992065,
      "lon": -98.5955963135,
      "tz": "America\/Chicago"
  },
  "85OR": {
      "icao": "85OR",
      "iata": "",
      "name": "Allen's Airstrip",
      "city": "Bly",
      "state": "Oregon",
      "country": "US",
      "elevation": 4410,
      "lat": 42.4045982361,
      "lon": -121.0490036011,
      "tz": "America\/Los_Angeles"
  },
  "85PA": {
      "icao": "85PA",
      "iata": "",
      "name": "Krumenacker Airport",
      "city": "Carrolltown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2040,
      "lat": 40.5951004028,
      "lon": -78.7783966064,
      "tz": "America\/New_York"
  },
  "85PN": {
      "icao": "85PN",
      "iata": "",
      "name": "Kiski Airport",
      "city": "Vandergrift",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1280,
      "lat": 40.5792007446,
      "lon": -79.6053009033,
      "tz": "America\/New_York"
  },
  "85TA": {
      "icao": "85TA",
      "iata": "",
      "name": "J-Bar Ranch Airport",
      "city": "Collinsville",
      "state": "Texas",
      "country": "US",
      "elevation": 718,
      "lat": 33.5798988342,
      "lon": -96.8124008179,
      "tz": "America\/Chicago"
  },
  "85TE": {
      "icao": "85TE",
      "iata": "",
      "name": "Old Kingsbury Aerodrome",
      "city": "Kingsbury",
      "state": "Texas",
      "country": "US",
      "elevation": 560,
      "lat": 29.6341991425,
      "lon": -97.8116989136,
      "tz": "America\/Chicago"
  },
  "85TX": {
      "icao": "85TX",
      "iata": "",
      "name": "Rutherford Ranch Airport",
      "city": "Buda",
      "state": "Texas",
      "country": "US",
      "elevation": 983,
      "lat": 30.0776996613,
      "lon": -97.9655990601,
      "tz": "America\/Chicago"
  },
  "85WA": {
      "icao": "85WA",
      "iata": "",
      "name": "Scott Seed Farm Airport",
      "city": "Pomeroy",
      "state": "Washington",
      "country": "US",
      "elevation": 1866,
      "lat": 46.5265007019,
      "lon": -117.7649993896,
      "tz": "America\/Los_Angeles"
  },
  "85WI": {
      "icao": "85WI",
      "iata": "",
      "name": "Cub Bear Airport",
      "city": "Emerald",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1240,
      "lat": 45.0443992615,
      "lon": -92.2928009033,
      "tz": "America\/Chicago"
  },
  "86AR": {
      "icao": "86AR",
      "iata": "",
      "name": "Goacher Airport",
      "city": "Lonoke",
      "state": "Arkansas",
      "country": "US",
      "elevation": 235,
      "lat": 34.8292999268,
      "lon": -91.9542999268,
      "tz": "America\/Chicago"
  },
  "86CL": {
      "icao": "86CL",
      "iata": "",
      "name": "Ernst Field",
      "city": "Hemet",
      "state": "California",
      "country": "US",
      "elevation": 2860,
      "lat": 33.5971984863,
      "lon": -116.8830032349,
      "tz": "America\/Los_Angeles"
  },
  "86CO": {
      "icao": "86CO",
      "iata": "",
      "name": "Lazy W Airport",
      "city": "Berthoud",
      "state": "Colorado",
      "country": "US",
      "elevation": 5200,
      "lat": 40.3135986328,
      "lon": -105.1449966431,
      "tz": "America\/Denver"
  },
  "86FD": {
      "icao": "86FD",
      "iata": "",
      "name": "Country Landings Airport",
      "city": "Bell",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 29.7646999359,
      "lon": -82.8964004517,
      "tz": "America\/New_York"
  },
  "86GA": {
      "icao": "86GA",
      "iata": "",
      "name": "Flying G Ranch Airport",
      "city": "Tunnel Hill",
      "state": "Georgia",
      "country": "US",
      "elevation": 845,
      "lat": 34.8679008484,
      "lon": -85.0610961914,
      "tz": "America\/New_York"
  },
  "86IL": {
      "icao": "86IL",
      "iata": "",
      "name": "Sharp Airport",
      "city": "Bethany",
      "state": "Illinois",
      "country": "US",
      "elevation": 674,
      "lat": 39.681667,
      "lon": -88.764444,
      "tz": "America\/Chicago"
  },
  "86IS": {
      "icao": "86IS",
      "iata": "",
      "name": "Michael Pfister Airport",
      "city": "Roanoke",
      "state": "Illinois",
      "country": "US",
      "elevation": 805,
      "lat": 40.7793998718,
      "lon": -89.2577972412,
      "tz": "America\/Chicago"
  },
  "86KS": {
      "icao": "86KS",
      "iata": "",
      "name": "Coberly Airport",
      "city": "Gove",
      "state": "Kansas",
      "country": "US",
      "elevation": 2540,
      "lat": 38.786889,
      "lon": -100.419843,
      "tz": "America\/Chicago"
  },
  "86KY": {
      "icao": "86KY",
      "iata": "",
      "name": "Hornback Airport",
      "city": "Hodgenville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 760,
      "lat": 37.6213989258,
      "lon": -85.7921981812,
      "tz": "America\/New_York"
  },
  "86LA": {
      "icao": "86LA",
      "iata": "",
      "name": "Spring Airport",
      "city": "Kentwood",
      "state": "Louisiana",
      "country": "US",
      "elevation": 400,
      "lat": 30.9738006592,
      "lon": -90.6657028198,
      "tz": "America\/Chicago"
  },
  "86MI": {
      "icao": "86MI",
      "iata": "",
      "name": "Downwind Acres Airport",
      "city": "Willis",
      "state": "Michigan",
      "country": "US",
      "elevation": 683,
      "lat": 42.1528015137,
      "lon": -83.5673980713,
      "tz": "America\/Detroit"
  },
  "86NC": {
      "icao": "86NC",
      "iata": "",
      "name": "Craig Craft Airport",
      "city": "Hertford",
      "state": "North Carolina",
      "country": "US",
      "elevation": 14,
      "lat": 36.2635993958,
      "lon": -76.4933013916,
      "tz": "America\/New_York"
  },
  "86NE": {
      "icao": "86NE",
      "iata": "",
      "name": "Orum Aerodrome",
      "city": "Blair",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1320,
      "lat": 41.5416984558,
      "lon": -96.2739028931,
      "tz": "America\/Chicago"
  },
  "86OH": {
      "icao": "86OH",
      "iata": "",
      "name": "Westfield Airport",
      "city": "Westfield Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 1044,
      "lat": 41.0127983093,
      "lon": -81.9200973511,
      "tz": "America\/New_York"
  },
  "86PN": {
      "icao": "86PN",
      "iata": "",
      "name": "Seesholtz Airport",
      "city": "Bloomsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 730,
      "lat": 41.0444984436,
      "lon": -76.3552017212,
      "tz": "America\/New_York"
  },
  "86TA": {
      "icao": "86TA",
      "iata": "",
      "name": "Slaughter Ranch Airport",
      "city": "Marble Falls",
      "state": "Texas",
      "country": "US",
      "elevation": 890,
      "lat": 30.6126995087,
      "lon": -98.2975006104,
      "tz": "America\/Chicago"
  },
  "86TE": {
      "icao": "86TE",
      "iata": "",
      "name": "Matthews Ranch Airport",
      "city": "Santo",
      "state": "Texas",
      "country": "US",
      "elevation": 835,
      "lat": 32.6498985291,
      "lon": -98.1473007202,
      "tz": "America\/Chicago"
  },
  "86TS": {
      "icao": "86TS",
      "iata": "",
      "name": "Fairway Farm Airport",
      "city": "San Augustine",
      "state": "Texas",
      "country": "US",
      "elevation": 360,
      "lat": 31.483499527,
      "lon": -94.0335006714,
      "tz": "America\/Chicago"
  },
  "86TX": {
      "icao": "86TX",
      "iata": "",
      "name": "Flying J Airport",
      "city": "Bulverde",
      "state": "Texas",
      "country": "US",
      "elevation": 1055,
      "lat": 29.7371997833,
      "lon": -98.3788986206,
      "tz": "America\/Chicago"
  },
  "86WA": {
      "icao": "86WA",
      "iata": "",
      "name": "Kapowsin Field",
      "city": "Kapowsin",
      "state": "Washington",
      "country": "US",
      "elevation": 720,
      "lat": 47.0040016174,
      "lon": -122.236000061,
      "tz": "America\/Los_Angeles"
  },
  "86WI": {
      "icao": "86WI",
      "iata": "",
      "name": "Bakers Field",
      "city": "Blanchardville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 875,
      "lat": 42.8252983093,
      "lon": -89.8701019287,
      "tz": "America\/Chicago"
  },
  "86XS": {
      "icao": "86XS",
      "iata": "",
      "name": "Gesin Ranches Airport",
      "city": "Sweetwater",
      "state": "Texas",
      "country": "US",
      "elevation": 2200,
      "lat": 32.400100708,
      "lon": -100.3669967651,
      "tz": "America\/Chicago"
  },
  "87AK": {
      "icao": "87AK",
      "iata": "",
      "name": "Soloy Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 545,
      "lat": 61.6515007019,
      "lon": -149.2879943848,
      "tz": "America\/Anchorage"
  },
  "87CA": {
      "icao": "87CA",
      "iata": "",
      "name": "Swanson Ranch Nr 1 Airport",
      "city": "Hanford",
      "state": "California",
      "country": "US",
      "elevation": 248,
      "lat": 36.3998985291,
      "lon": -119.6179962158,
      "tz": "America\/Los_Angeles"
  },
  "87CO": {
      "icao": "87CO",
      "iata": "",
      "name": "Young's Strip",
      "city": "Bennett",
      "state": "Colorado",
      "country": "US",
      "elevation": 5650,
      "lat": 39.6911010742,
      "lon": -104.4160003662,
      "tz": "America\/Denver"
  },
  "87IL": {
      "icao": "87IL",
      "iata": "",
      "name": "Bushby RLA Restricted Landing Area",
      "city": "Minooka",
      "state": "Illinois",
      "country": "US",
      "elevation": 580,
      "lat": 41.5167007446,
      "lon": -88.2667007446,
      "tz": "America\/Chicago"
  },
  "87IN": {
      "icao": "87IN",
      "iata": "",
      "name": "May's Strip",
      "city": "Walkerton",
      "state": "Indiana",
      "country": "US",
      "elevation": 715,
      "lat": 41.452999115,
      "lon": -86.4697036743,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "87IS": {
      "icao": "87IS",
      "iata": "",
      "name": "Seigfried Halfpap Airport",
      "city": "Quincy",
      "state": "Illinois",
      "country": "US",
      "elevation": 550,
      "lat": 39.8861999512,
      "lon": -91.3207015991,
      "tz": "America\/Chicago"
  },
  "87KS": {
      "icao": "87KS",
      "iata": "",
      "name": "Holyrood Municipal Airport",
      "city": "Holyrood",
      "state": "Kansas",
      "country": "US",
      "elevation": 1805,
      "lat": 38.5875015259,
      "lon": -98.404800415,
      "tz": "America\/Chicago"
  },
  "87MN": {
      "icao": "87MN",
      "iata": "",
      "name": "Erickson Airport",
      "city": "Roosevelt",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1155,
      "lat": 48.8102989197,
      "lon": -95.2015991211,
      "tz": "America\/Chicago"
  },
  "87MO": {
      "icao": "87MO",
      "iata": "",
      "name": "Richters Airport",
      "city": "East Lynne",
      "state": "Missouri",
      "country": "US",
      "elevation": 915,
      "lat": 38.6960983276,
      "lon": -94.2580032349,
      "tz": "America\/Chicago"
  },
  "87NC": {
      "icao": "87NC",
      "iata": "",
      "name": "Brocks Airport",
      "city": "Roseboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 120,
      "lat": 34.916111,
      "lon": -78.600277,
      "tz": "America\/New_York"
  },
  "87NE": {
      "icao": "87NE",
      "iata": "",
      "name": "Knox Landing Airport",
      "city": "York",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1638,
      "lat": 40.8092002869,
      "lon": -97.5877990723,
      "tz": "America\/Chicago"
  },
  "87OH": {
      "icao": "87OH",
      "iata": "",
      "name": "Mundron Field",
      "city": "Fayette",
      "state": "Ohio",
      "country": "US",
      "elevation": 840,
      "lat": 41.7056007385,
      "lon": -84.3735961914,
      "tz": "America\/Detroit"
  },
  "87OI": {
      "icao": "87OI",
      "iata": "",
      "name": "Lake Air Ranch Airport",
      "city": "Norwalk",
      "state": "Ohio",
      "country": "US",
      "elevation": 690,
      "lat": 41.2723007202,
      "lon": -82.6230010986,
      "tz": "America\/New_York"
  },
  "87OR": {
      "icao": "87OR",
      "iata": "",
      "name": "Moondance Ranch Airport",
      "city": "Beatty",
      "state": "Oregon",
      "country": "US",
      "elevation": 4980,
      "lat": 42.6517982483,
      "lon": -121.3290023804,
      "tz": "America\/Los_Angeles"
  },
  "87PA": {
      "icao": "87PA",
      "iata": "",
      "name": "Waltman Airport",
      "city": "Chicora",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1360,
      "lat": 40.9264984131,
      "lon": -79.7387008667,
      "tz": "America\/New_York"
  },
  "87TA": {
      "icao": "87TA",
      "iata": "",
      "name": "Cielo Grande Ranch Airport",
      "city": "Mountain Home",
      "state": "Texas",
      "country": "US",
      "elevation": 2180,
      "lat": 30.1891002655,
      "lon": -99.2697982788,
      "tz": "America\/Chicago"
  },
  "87TE": {
      "icao": "87TE",
      "iata": "",
      "name": "Rose Field Airport",
      "city": "Needville",
      "state": "Texas",
      "country": "US",
      "elevation": 100,
      "lat": 29.4519004822,
      "lon": -95.8655014038,
      "tz": "America\/Chicago"
  },
  "87TX": {
      "icao": "87TX",
      "iata": "",
      "name": "Porter Ranch Airport",
      "city": "Burnell",
      "state": "Texas",
      "country": "US",
      "elevation": 418,
      "lat": 28.6900005341,
      "lon": -97.7602996826,
      "tz": "America\/Chicago"
  },
  "87VA": {
      "icao": "87VA",
      "iata": "",
      "name": "Cottonwood Farm Airport",
      "city": "Crozet",
      "state": "Virginia",
      "country": "US",
      "elevation": 690,
      "lat": 38.0811004639,
      "lon": -78.695602417,
      "tz": "America\/New_York"
  },
  "87WI": {
      "icao": "87WI",
      "iata": "",
      "name": "Quale Airport",
      "city": "Cottage Grove",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 955,
      "lat": 43.0194015503,
      "lon": -89.1854019165,
      "tz": "America\/Chicago"
  },
  "87XS": {
      "icao": "87XS",
      "iata": "",
      "name": "Cinco B Ranch Airport",
      "city": "Medina",
      "state": "Texas",
      "country": "US",
      "elevation": 1457,
      "lat": 29.7877006531,
      "lon": -99.262802124,
      "tz": "America\/Chicago"
  },
  "88AK": {
      "icao": "88AK",
      "iata": "",
      "name": "Dutch Landing Strip",
      "city": "Sterling",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 60.5402984619,
      "lon": -150.8679962158,
      "tz": "America\/Anchorage"
  },
  "88CO": {
      "icao": "88CO",
      "iata": "",
      "name": "Tranquila Airport",
      "city": "Yoder",
      "state": "Colorado",
      "country": "US",
      "elevation": 6320,
      "lat": 38.886100769,
      "lon": -104.2490005493,
      "tz": "America\/Denver"
  },
  "88GA": {
      "icao": "88GA",
      "iata": "",
      "name": "Beaver Creek Airport",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 810,
      "lat": 33.1119003296,
      "lon": -84.4447021484,
      "tz": "America\/New_York"
  },
  "88IL": {
      "icao": "88IL",
      "iata": "",
      "name": "Mary's Landing Airport",
      "city": "Capron",
      "state": "Illinois",
      "country": "US",
      "elevation": 910,
      "lat": 42.4131011963,
      "lon": -88.7332992554,
      "tz": "America\/Chicago"
  },
  "88IN": {
      "icao": "88IN",
      "iata": "",
      "name": "Gardner Airport",
      "city": "Boggstown",
      "state": "Indiana",
      "country": "US",
      "elevation": 740,
      "lat": 39.5797996521,
      "lon": -85.9321975708,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "88IS": {
      "icao": "88IS",
      "iata": "",
      "name": "Blickhan Lndg Area Airport",
      "city": "Quincy",
      "state": "Illinois",
      "country": "US",
      "elevation": 635,
      "lat": 40.0083999634,
      "lon": -91.3821029663,
      "tz": "America\/Chicago"
  },
  "88LA": {
      "icao": "88LA",
      "iata": "",
      "name": "Freebird Field",
      "city": "Lafayette",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.1294002533,
      "lon": -92.1351013184,
      "tz": "America\/Chicago"
  },
  "88LL": {
      "icao": "88LL",
      "iata": "",
      "name": "Block Airport",
      "city": "Pearl City",
      "state": "Illinois",
      "country": "US",
      "elevation": 917,
      "lat": 42.1916999817,
      "lon": -89.8382034302,
      "tz": "America\/Chicago"
  },
  "88MN": {
      "icao": "88MN",
      "iata": "",
      "name": "Fox Field",
      "city": "Young America",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 44.801700592,
      "lon": -93.950302124,
      "tz": "America\/Chicago"
  },
  "88MO": {
      "icao": "88MO",
      "iata": "",
      "name": "Howell Valley Airport",
      "city": "West Plains",
      "state": "Missouri",
      "country": "US",
      "elevation": 950,
      "lat": 36.7016983032,
      "lon": -91.802696228,
      "tz": "America\/Chicago"
  },
  "88NC": {
      "icao": "88NC",
      "iata": "",
      "name": "Corriher Field",
      "city": "Salisbury",
      "state": "North Carolina",
      "country": "US",
      "elevation": 840,
      "lat": 35.6324996948,
      "lon": -80.6853027344,
      "tz": "America\/New_York"
  },
  "88ND": {
      "icao": "88ND",
      "iata": "",
      "name": "Goodman Strip",
      "city": "Milton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1560,
      "lat": 48.7089004517,
      "lon": -97.9940032959,
      "tz": "America\/Chicago"
  },
  "88NE": {
      "icao": "88NE",
      "iata": "",
      "name": "Ensor Field",
      "city": "Johnson",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1170,
      "lat": 40.4039001465,
      "lon": -96.0171966553,
      "tz": "America\/Chicago"
  },
  "88NV": {
      "icao": "88NV",
      "iata": "",
      "name": "Black Rock City Airport",
      "city": "Gerlach",
      "state": "Nevada",
      "country": "US",
      "elevation": 3904,
      "lat": 40.757333,
      "lon": -119.212861,
      "tz": "America\/Los_Angeles"
  },
  "88NY": {
      "icao": "88NY",
      "iata": "",
      "name": "Zelazny Airport",
      "city": "Shelby",
      "state": "New York",
      "country": "US",
      "elevation": 635,
      "lat": 43.1577987671,
      "lon": -78.3583984375,
      "tz": "America\/New_York"
  },
  "88OH": {
      "icao": "88OH",
      "iata": "",
      "name": "Chippewa Field",
      "city": "Williston",
      "state": "Ohio",
      "country": "US",
      "elevation": 596,
      "lat": 41.5999984741,
      "lon": -83.3165969849,
      "tz": "America\/New_York"
  },
  "88OK": {
      "icao": "88OK",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Stillwater",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1070,
      "lat": 36.1934013367,
      "lon": -97.0906982422,
      "tz": "America\/Chicago"
  },
  "88OL": {
      "icao": "88OL",
      "iata": "",
      "name": "Pace Field",
      "city": "Calvin",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 825,
      "lat": 34.9645004272,
      "lon": -96.2711029053,
      "tz": "America\/Chicago"
  },
  "88OR": {
      "icao": "88OR",
      "iata": "",
      "name": "Tallman Airport",
      "city": "Lebanon",
      "state": "Oregon",
      "country": "US",
      "elevation": 303,
      "lat": 44.5682983398,
      "lon": -122.9710006714,
      "tz": "America\/Los_Angeles"
  },
  "88PA": {
      "icao": "88PA",
      "iata": "",
      "name": "Mc Coy Airport",
      "city": "Clinton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.5391998291,
      "lon": -80.4131011963,
      "tz": "America\/New_York"
  },
  "88TA": {
      "icao": "88TA",
      "iata": "",
      "name": "Figure 2 Ranch Airport",
      "city": "Van Horn",
      "state": "Texas",
      "country": "US",
      "elevation": 3700,
      "lat": 31.4559993744,
      "lon": -104.84400177,
      "tz": "America\/Chicago"
  },
  "88TE": {
      "icao": "88TE",
      "iata": "",
      "name": "Thunderbird Southwest Airport",
      "city": "La Grange",
      "state": "Texas",
      "country": "US",
      "elevation": 385,
      "lat": 29.9016990662,
      "lon": -96.7992019653,
      "tz": "America\/Chicago"
  },
  "88TN": {
      "icao": "88TN",
      "iata": "",
      "name": "Piney Creek Airport",
      "city": "Altamont",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1960,
      "lat": 35.4245,
      "lon": -85.779333,
      "tz": "America\/Chicago"
  },
  "88VA": {
      "icao": "88VA",
      "iata": "",
      "name": "Belmont Farm Airport",
      "city": "Culpeper",
      "state": "Virginia",
      "country": "US",
      "elevation": 305,
      "lat": 38.3741989136,
      "lon": -77.991897583,
      "tz": "America\/New_York"
  },
  "88VT": {
      "icao": "88VT",
      "iata": "",
      "name": "Hawk's Nest Airport",
      "city": "Isle Lamotte",
      "state": "Vermont",
      "country": "US",
      "elevation": 140,
      "lat": 44.8782997131,
      "lon": -73.3503036499,
      "tz": "America\/New_York"
  },
  "88WI": {
      "icao": "88WI",
      "iata": "",
      "name": "Nicolet Airport",
      "city": "Green Bay",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 790,
      "lat": 44.5083007813,
      "lon": -87.8607025146,
      "tz": "America\/Chicago"
  },
  "88XS": {
      "icao": "88XS",
      "iata": "",
      "name": "Bogan & Fontenot Airport",
      "city": "Nome",
      "state": "Texas",
      "country": "US",
      "elevation": 35,
      "lat": 30.0191001892,
      "lon": -94.3877029419,
      "tz": "America\/Chicago"
  },
  "89AK": {
      "icao": "89AK",
      "iata": "",
      "name": "Lincoln Village Airpark",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 250,
      "lat": 61.5593986511,
      "lon": -149.7050018311,
      "tz": "America\/Anchorage"
  },
  "89CA": {
      "icao": "89CA",
      "iata": "",
      "name": "Swanson Ranch Nr 2 Airport",
      "city": "Riverdale",
      "state": "California",
      "country": "US",
      "elevation": 237,
      "lat": 36.4665985107,
      "lon": -119.8840026855,
      "tz": "America\/Los_Angeles"
  },
  "89CO": {
      "icao": "89CO",
      "iata": "",
      "name": "Vallery Airport",
      "city": "Wiggins",
      "state": "Colorado",
      "country": "US",
      "elevation": 4480,
      "lat": 40.238899231,
      "lon": -103.9919967651,
      "tz": "America\/Denver"
  },
  "89GE": {
      "icao": "89GE",
      "iata": "",
      "name": "South Fulton Airport",
      "city": "Palmetto",
      "state": "Georgia",
      "country": "US",
      "elevation": 1025,
      "lat": 33.5376014709,
      "lon": -84.6388015747,
      "tz": "America\/New_York"
  },
  "89IA": {
      "icao": "89IA",
      "iata": "",
      "name": "Chain Lakes Airpark",
      "city": "Cedar Rapids",
      "state": "Iowa",
      "country": "US",
      "elevation": 825,
      "lat": 42.051399231,
      "lon": -91.716796875,
      "tz": "America\/Chicago"
  },
  "89IN": {
      "icao": "89IN",
      "iata": "",
      "name": "Milhon Airport",
      "city": "Martinsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 615,
      "lat": 39.5069999695,
      "lon": -86.3897018433,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "89IS": {
      "icao": "89IS",
      "iata": "",
      "name": "Voges Airstrip",
      "city": "Red Bud",
      "state": "Illinois",
      "country": "US",
      "elevation": 424,
      "lat": 38.2167015076,
      "lon": -89.9750976563,
      "tz": "America\/Chicago"
  },
  "89LL": {
      "icao": "89LL",
      "iata": "",
      "name": "Norman Airport",
      "city": "Peotone",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 41.3583984375,
      "lon": -87.7249984741,
      "tz": "America\/Chicago"
  },
  "89MN": {
      "icao": "89MN",
      "iata": "",
      "name": "Beskar Airport",
      "city": "Taylors Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 900,
      "lat": 45.3876991272,
      "lon": -92.6968994141,
      "tz": "America\/Chicago"
  },
  "89MO": {
      "icao": "89MO",
      "iata": "",
      "name": "Bishop's Landing Airport",
      "city": "Westline",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 38.5833015442,
      "lon": -94.5796966553,
      "tz": "America\/Chicago"
  },
  "89NC": {
      "icao": "89NC",
      "iata": "",
      "name": "Strickland Field",
      "city": "Fair Bluff",
      "state": "North Carolina",
      "country": "US",
      "elevation": 98,
      "lat": 34.2663993835,
      "lon": -79.0235977173,
      "tz": "America\/New_York"
  },
  "89ND": {
      "icao": "89ND",
      "iata": "",
      "name": "Poleschook Airport",
      "city": "Minot",
      "state": "North Dakota",
      "country": "US",
      "elevation": 2245,
      "lat": 47.9113998413,
      "lon": -101.2490005493,
      "tz": "America\/Chicago"
  },
  "89NE": {
      "icao": "89NE",
      "iata": "",
      "name": "George Airport",
      "city": "Hershey",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2850,
      "lat": 41.17029953,
      "lon": -101.0009994507,
      "tz": "America\/Chicago"
  },
  "89NJ": {
      "icao": "89NJ",
      "iata": "",
      "name": "Strawberry Fields Airport",
      "city": "Mays Landing",
      "state": "New Jersey",
      "country": "US",
      "elevation": 45,
      "lat": 39.489200592,
      "lon": -74.7236022949,
      "tz": "America\/New_York"
  },
  "89NY": {
      "icao": "89NY",
      "iata": "",
      "name": "Maxson Airfield",
      "city": "Alexandria Bay",
      "state": "New York",
      "country": "US",
      "elevation": 340,
      "lat": 44.3166999817,
      "lon": -75.8996963501,
      "tz": "America\/New_York"
  },
  "89OI": {
      "icao": "89OI",
      "iata": "",
      "name": "Circle C Airport",
      "city": "Berlin Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 1042,
      "lat": 41.038898468,
      "lon": -80.9840011597,
      "tz": "America\/New_York"
  },
  "89OK": {
      "icao": "89OK",
      "iata": "",
      "name": "Neversweat Too Airport",
      "city": "Sallisaw",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 565,
      "lat": 35.4430999756,
      "lon": -94.8974990845,
      "tz": "America\/Chicago"
  },
  "89OR": {
      "icao": "89OR",
      "iata": "",
      "name": "Mucky Flat Airport",
      "city": "Eagle Point",
      "state": "Oregon",
      "country": "US",
      "elevation": 2000,
      "lat": 42.5979003906,
      "lon": -122.7129974365,
      "tz": "America\/Los_Angeles"
  },
  "89TA": {
      "icao": "89TA",
      "iata": "",
      "name": "Soaring Club of Houston Gliderport",
      "city": "Waller",
      "state": "Texas",
      "country": "US",
      "elevation": 308,
      "lat": 30.1926994324,
      "lon": -95.9633026123,
      "tz": "America\/Chicago"
  },
  "89TE": {
      "icao": "89TE",
      "iata": "",
      "name": "Lajitas International Airport",
      "city": "Lajitas",
      "state": "Texas",
      "country": "US",
      "elevation": 2630,
      "lat": 29.27799988,
      "lon": -103.6869965,
      "tz": "America\/Chicago"
  },
  "89TS": {
      "icao": "89TS",
      "iata": "",
      "name": "Carter Ranch Airport",
      "city": "Oakwood",
      "state": "Texas",
      "country": "US",
      "elevation": 215,
      "lat": 31.5667991638,
      "lon": -95.7666015625,
      "tz": "America\/Chicago"
  },
  "89TX": {
      "icao": "89TX",
      "iata": "",
      "name": "Aero-Bee Ranch Airstrip",
      "city": "Burnet",
      "state": "Texas",
      "country": "US",
      "elevation": 1242,
      "lat": 30.8792991638,
      "lon": -98.4169998169,
      "tz": "America\/Chicago"
  },
  "89VA": {
      "icao": "89VA",
      "iata": "",
      "name": "Hidden River Airport",
      "city": "Edinburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 780,
      "lat": 38.5223007202,
      "lon": -78.5242004395,
      "tz": "America\/New_York"
  },
  "89WA": {
      "icao": "89WA",
      "iata": "",
      "name": "Williams Airpatch Airport",
      "city": "Arlington",
      "state": "Washington",
      "country": "US",
      "elevation": 130,
      "lat": 48.2706985474,
      "lon": -122.0189971924,
      "tz": "America\/Los_Angeles"
  },
  "89WI": {
      "icao": "89WI",
      "iata": "",
      "name": "Tuschen Airport",
      "city": "Jonesdale",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1060,
      "lat": 42.9068984985,
      "lon": -90.0320968628,
      "tz": "America\/Chicago"
  },
  "8AK0": {
      "icao": "8AK0",
      "iata": "",
      "name": "Diamond Nn Cannery Airport",
      "city": "South Naknek",
      "state": "Alaska",
      "country": "US",
      "elevation": 60,
      "lat": 58.710899353,
      "lon": -156.9949951172,
      "tz": "America\/Anchorage"
  },
  "8AK1": {
      "icao": "8AK1",
      "iata": "",
      "name": "Jacobus Field",
      "city": "Gulkana",
      "state": "Alaska",
      "country": "US",
      "elevation": 1670,
      "lat": 62.2803001404,
      "lon": -145.3630065918,
      "tz": "America\/Anchorage"
  },
  "8AK2": {
      "icao": "8AK2",
      "iata": "",
      "name": "Carty's Airstrip",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 60.7024993896,
      "lon": -151.2940063477,
      "tz": "America\/Anchorage"
  },
  "8AK3": {
      "icao": "8AK3",
      "iata": "",
      "name": "Roland Norton Memorial Airstrip",
      "city": "Selawik",
      "state": "Alaska",
      "country": "US",
      "elevation": 360,
      "lat": 66.7659988403,
      "lon": -160.1529998779,
      "tz": "America\/Anchorage"
  },
  "8AK6": {
      "icao": "8AK6",
      "iata": "",
      "name": "Little Susitna Airport",
      "city": "Susitna Station",
      "state": "Alaska",
      "country": "US",
      "elevation": 70,
      "lat": 61.3718986511,
      "lon": -150.2619934082,
      "tz": "America\/Anchorage"
  },
  "8AK8": {
      "icao": "8AK8",
      "iata": "",
      "name": "North Cubs Strip Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 350,
      "lat": 61.6300010681,
      "lon": -149.682998657,
      "tz": "America\/Anchorage"
  },
  "8AK9": {
      "icao": "8AK9",
      "iata": "",
      "name": "Tok 2 Airport",
      "city": "Tok",
      "state": "Alaska",
      "country": "US",
      "elevation": 1630,
      "lat": 63.308904791,
      "lon": -143.016929626,
      "tz": "America\/Anchorage"
  },
  "8AN6": {
      "icao": "8AN6",
      "iata": "",
      "name": "Isaacson Airport",
      "city": "Medical Lake",
      "state": "Washington",
      "country": "US",
      "elevation": 2410,
      "lat": 47.659198761,
      "lon": -117.6819992065,
      "tz": "America\/Los_Angeles"
  },
  "8AR0": {
      "icao": "8AR0",
      "iata": "",
      "name": "Hargrove Airport",
      "city": "Stuttgart",
      "state": "Arkansas",
      "country": "US",
      "elevation": 211,
      "lat": 34.5125999451,
      "lon": -91.5640029907,
      "tz": "America\/Chicago"
  },
  "8AR1": {
      "icao": "8AR1",
      "iata": "",
      "name": "Totty Field Airport",
      "city": "Oakland",
      "state": "Arkansas",
      "country": "US",
      "elevation": 780,
      "lat": 36.4893989563,
      "lon": -92.6421966553,
      "tz": "America\/Chicago"
  },
  "8AZ5": {
      "icao": "8AZ5",
      "iata": "",
      "name": "Lizzy Lizard Airport",
      "city": "Portal",
      "state": "Arizona",
      "country": "US",
      "elevation": 4200,
      "lat": 31.897944,
      "lon": -109.056917,
      "tz": "America\/Denver"
  },
  "8CA0": {
      "icao": "8CA0",
      "iata": "",
      "name": "Osborne Airport",
      "city": "Victorville",
      "state": "California",
      "country": "US",
      "elevation": 2900,
      "lat": 34.5839004517,
      "lon": -117.266998291,
      "tz": "America\/Los_Angeles"
  },
  "8CA5": {
      "icao": "8CA5",
      "iata": "",
      "name": "Pines Airpark",
      "city": "Winchester",
      "state": "California",
      "country": "US",
      "elevation": 1420,
      "lat": 33.6455993652,
      "lon": -117.1100006104,
      "tz": "America\/Los_Angeles"
  },
  "8CA8": {
      "icao": "8CA8",
      "iata": "",
      "name": "Wallom Field",
      "city": "Lodi",
      "state": "California",
      "country": "US",
      "elevation": 56,
      "lat": 38.067401886,
      "lon": -121.2080001831,
      "tz": "America\/Los_Angeles"
  },
  "8CL0": {
      "icao": "8CL0",
      "iata": "",
      "name": "Nichols Farms Airport",
      "city": "Palmdale",
      "state": "California",
      "country": "US",
      "elevation": 2700,
      "lat": 34.5889015198,
      "lon": -117.8259963989,
      "tz": "America\/Los_Angeles"
  },
  "8CL1": {
      "icao": "8CL1",
      "iata": "",
      "name": "Lake Wohlford Resort Airport",
      "city": "Escondido",
      "state": "California",
      "country": "US",
      "elevation": 1643,
      "lat": 33.1753473206,
      "lon": -117.002334595,
      "tz": "America\/Los_Angeles"
  },
  "8CL2": {
      "icao": "8CL2",
      "iata": "",
      "name": "Lucchetti Ranch Airport",
      "city": "Elk Grove",
      "state": "California",
      "country": "US",
      "elevation": 96,
      "lat": 38.423500061,
      "lon": -121.2269973755,
      "tz": "America\/Los_Angeles"
  },
  "8CL6": {
      "icao": "8CL6",
      "iata": "",
      "name": "Moller Airport",
      "city": "Maxwell",
      "state": "California",
      "country": "US",
      "elevation": 85,
      "lat": 39.2885017395,
      "lon": -122.1890029907,
      "tz": "America\/Los_Angeles"
  },
  "8CL9": {
      "icao": "8CL9",
      "iata": "",
      "name": "Bland Field",
      "city": "Kerman",
      "state": "California",
      "country": "US",
      "elevation": 222,
      "lat": 36.7123985291,
      "lon": -120.0299987793,
      "tz": "America\/Los_Angeles"
  },
  "8CO0": {
      "icao": "8CO0",
      "iata": "",
      "name": "Kent Airport",
      "city": "Wiggins",
      "state": "Colorado",
      "country": "US",
      "elevation": 4480,
      "lat": 40.2527999878,
      "lon": -104.0029983521,
      "tz": "America\/Denver"
  },
  "8CO2": {
      "icao": "8CO2",
      "iata": "",
      "name": "William Leon Schawo Airport",
      "city": "Briggsdale",
      "state": "Colorado",
      "country": "US",
      "elevation": 4950,
      "lat": 40.6707992554,
      "lon": -104.2080001831,
      "tz": "America\/Denver"
  },
  "8CO4": {
      "icao": "8CO4",
      "iata": "",
      "name": "East Moore Field",
      "city": "Byers",
      "state": "Colorado",
      "country": "US",
      "elevation": 5250,
      "lat": 39.670501709,
      "lon": -104.1360015869,
      "tz": "America\/Denver"
  },
  "8CO5": {
      "icao": "8CO5",
      "iata": "",
      "name": "Widner Airport",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 6020,
      "lat": 38.8339004517,
      "lon": -107.891998291,
      "tz": "America\/Denver"
  },
  "8CO7": {
      "icao": "8CO7",
      "iata": "",
      "name": "Colorado Antique Field",
      "city": "Niwot",
      "state": "Colorado",
      "country": "US",
      "elevation": 5069,
      "lat": 40.1225013733,
      "lon": -105.1719970703,
      "tz": "America\/Denver"
  },
  "8CO8": {
      "icao": "8CO8",
      "iata": "",
      "name": "Koenig Airport",
      "city": "Yuma",
      "state": "Colorado",
      "country": "US",
      "elevation": 4150,
      "lat": 40.1082992554,
      "lon": -102.7279968262,
      "tz": "America\/Denver"
  },
  "8CO9": {
      "icao": "8CO9",
      "iata": "",
      "name": "Pine View Airport",
      "city": "Elizabeth",
      "state": "Colorado",
      "country": "US",
      "elevation": 6572,
      "lat": 39.3443984985,
      "lon": -104.5810012817,
      "tz": "America\/Denver"
  },
  "8FA4": {
      "icao": "8FA4",
      "iata": "",
      "name": "Samsula \/ Coe Field",
      "city": "",
      "state": "Florida",
      "country": "US",
      "elevation": 40,
      "lat": 29.0102045831,
      "lon": -81.1328315735,
      "tz": "America\/New_York"
  },
  "8FD1": {
      "icao": "8FD1",
      "iata": "",
      "name": "Buckner Airport",
      "city": "Mc Alpin",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 30.0988006592,
      "lon": -82.9275970459,
      "tz": "America\/New_York"
  },
  "8FD2": {
      "icao": "8FD2",
      "iata": "",
      "name": "The Flying Horseman Airport",
      "city": "Melrose",
      "state": "Florida",
      "country": "US",
      "elevation": 192,
      "lat": 29.7152004242,
      "lon": -82.0942993164,
      "tz": "America\/New_York"
  },
  "8FD3": {
      "icao": "8FD3",
      "iata": "",
      "name": "Blackwater Airfield",
      "city": "Munson",
      "state": "Florida",
      "country": "US",
      "elevation": 200,
      "lat": 30.8777008057,
      "lon": -86.8544006348,
      "tz": "America\/Chicago"
  },
  "8FD5": {
      "icao": "8FD5",
      "iata": "",
      "name": "Lykes Brighton Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 27.1888999939,
      "lon": -81.0869979858,
      "tz": "America\/New_York"
  },
  "8FL0": {
      "icao": "8FL0",
      "iata": "",
      "name": "Hutson Airfield",
      "city": "Hastings",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 29.7280006409,
      "lon": -81.4397964478,
      "tz": "America\/New_York"
  },
  "8FL1": {
      "icao": "8FL1",
      "iata": "",
      "name": "Mc Donald's Field",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 54,
      "lat": 27.1970005035,
      "lon": -81.9156036377,
      "tz": "America\/New_York"
  },
  "8FL2": {
      "icao": "8FL2",
      "iata": "",
      "name": "Norton Airport",
      "city": "Belleview",
      "state": "Florida",
      "country": "US",
      "elevation": 67,
      "lat": 29.0011005402,
      "lon": -82.1006011963,
      "tz": "America\/New_York"
  },
  "8FL3": {
      "icao": "8FL3",
      "iata": "",
      "name": "Back Achers Airport",
      "city": "Belleview",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 29.0729999542,
      "lon": -81.99949646,
      "tz": "America\/New_York"
  },
  "8FL6": {
      "icao": "8FL6",
      "iata": "",
      "name": "George T Mc Cutchan Airport",
      "city": "Harold",
      "state": "Florida",
      "country": "US",
      "elevation": 160,
      "lat": 30.673500061,
      "lon": -86.8518981934,
      "tz": "America\/Chicago"
  },
  "8GA1": {
      "icao": "8GA1",
      "iata": "",
      "name": "Gum Creek Airport",
      "city": "Roopville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1070,
      "lat": 33.4211997986,
      "lon": -85.1619033813,
      "tz": "America\/New_York"
  },
  "8GA3": {
      "icao": "8GA3",
      "iata": "",
      "name": "Leesburg Spraying Airport",
      "city": "Leesburg",
      "state": "Georgia",
      "country": "US",
      "elevation": 260,
      "lat": 31.7751998901,
      "lon": -84.1249008179,
      "tz": "America\/New_York"
  },
  "8GA6": {
      "icao": "8GA6",
      "iata": "",
      "name": "S & S Landing Strip",
      "city": "Griffin",
      "state": "Georgia",
      "country": "US",
      "elevation": 900,
      "lat": 33.16070175,
      "lon": -84.3184967,
      "tz": "America\/New_York"
  },
  "8GA7": {
      "icao": "8GA7",
      "iata": "",
      "name": "Etowah Bend Gliderport",
      "city": "Kingston",
      "state": "Georgia",
      "country": "US",
      "elevation": 620,
      "lat": 34.2019996643,
      "lon": -84.9772033691,
      "tz": "America\/New_York"
  },
  "8GA8": {
      "icao": "8GA8",
      "iata": "",
      "name": "Falcons Aerie Airport",
      "city": "Carrollton",
      "state": "Georgia",
      "country": "US",
      "elevation": 1180,
      "lat": 33.5773010254,
      "lon": -85.0029983521,
      "tz": "America\/New_York"
  },
  "8GA9": {
      "icao": "8GA9",
      "iata": "",
      "name": "Brook Bridge Aerodrome",
      "city": "Vaughn",
      "state": "Georgia",
      "country": "US",
      "elevation": 820,
      "lat": 33.2767982483,
      "lon": -84.4107971191,
      "tz": "America\/New_York"
  },
  "8IA2": {
      "icao": "8IA2",
      "iata": "",
      "name": "Port Paradise Farms Airport",
      "city": "Winfield",
      "state": "Iowa",
      "country": "US",
      "elevation": 700,
      "lat": 41.1739006042,
      "lon": -91.3618011475,
      "tz": "America\/Chicago"
  },
  "8IA9": {
      "icao": "8IA9",
      "iata": "",
      "name": "Shields Airport",
      "city": "Red Oak",
      "state": "Iowa",
      "country": "US",
      "elevation": 1245,
      "lat": 41.15610123,
      "lon": -95.24420166,
      "tz": "America\/Chicago"
  },
  "8ID8": {
      "icao": "8ID8",
      "iata": "",
      "name": "Lewis And Clark Airstrip",
      "city": "Salmon",
      "state": "Idaho",
      "country": "US",
      "elevation": 4530,
      "lat": 45.353333,
      "lon": -113.865,
      "tz": "America\/Boise"
  },
  "8II0": {
      "icao": "8II0",
      "iata": "",
      "name": "Allen and Gloss Airport",
      "city": "Bennington",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 38.8569984436,
      "lon": -85.1013031006,
      "tz": "America\/Indiana\/Vevay"
  },
  "8II1": {
      "icao": "8II1",
      "iata": "",
      "name": "Careferre Acres Airport",
      "city": "Jasonville",
      "state": "Indiana",
      "country": "US",
      "elevation": 520,
      "lat": 39.1831016541,
      "lon": -87.1260986328,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "8II2": {
      "icao": "8II2",
      "iata": "",
      "name": "Norris Field",
      "city": "Liberty",
      "state": "Indiana",
      "country": "US",
      "elevation": 1085,
      "lat": 39.6530990601,
      "lon": -84.8660964966,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "8II3": {
      "icao": "8II3",
      "iata": "",
      "name": "Thrust Industries Airport",
      "city": "Haubstadt",
      "state": "Indiana",
      "country": "US",
      "elevation": 460,
      "lat": 38.2125015259,
      "lon": -87.579498291,
      "tz": "America\/Chicago"
  },
  "8II5": {
      "icao": "8II5",
      "iata": "",
      "name": "Holt Field",
      "city": "Hoagland",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 40.8995018005,
      "lon": -85,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "8IL0": {
      "icao": "8IL0",
      "iata": "",
      "name": "Adkins RLA Restricted Landing Area",
      "city": "Harvard",
      "state": "Illinois",
      "country": "US",
      "elevation": 853,
      "lat": 42.3527984619,
      "lon": -88.634803772,
      "tz": "America\/Chicago"
  },
  "8IL1": {
      "icao": "8IL1",
      "iata": "",
      "name": "Twin Gardens Airport",
      "city": "Harvard",
      "state": "Illinois",
      "country": "US",
      "elevation": 900,
      "lat": 42.4084014893,
      "lon": -88.6529006958,
      "tz": "America\/Chicago"
  },
  "8IL2": {
      "icao": "8IL2",
      "iata": "",
      "name": "Kirkpatrick Airport",
      "city": "Harvard",
      "state": "Illinois",
      "country": "US",
      "elevation": 950,
      "lat": 42.4846992493,
      "lon": -88.6000976563,
      "tz": "America\/Chicago"
  },
  "8IL3": {
      "icao": "8IL3",
      "iata": "",
      "name": "Butler Hill RLA Restricted Landing Area",
      "city": "West Brooklyn",
      "state": "Illinois",
      "country": "US",
      "elevation": 765,
      "lat": 41.7380981445,
      "lon": -89.1624984741,
      "tz": "America\/Chicago"
  },
  "8IL5": {
      "icao": "8IL5",
      "iata": "",
      "name": "O'Connor Field",
      "city": "Pecatonica",
      "state": "Illinois",
      "country": "US",
      "elevation": 900,
      "lat": 42.3680992126,
      "lon": -89.3542022705,
      "tz": "America\/Chicago"
  },
  "8IL6": {
      "icao": "8IL6",
      "iata": "",
      "name": "Robertson's Roost Airport",
      "city": "Edgewood",
      "state": "Illinois",
      "country": "US",
      "elevation": 542,
      "lat": 38.8594017029,
      "lon": -88.6585998535,
      "tz": "America\/Chicago"
  },
  "8IN2": {
      "icao": "8IN2",
      "iata": "",
      "name": "Robinson Field",
      "city": "Greenwood",
      "state": "Indiana",
      "country": "US",
      "elevation": 840,
      "lat": 39.623298645,
      "lon": -86.0314025879,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "8IN7": {
      "icao": "8IN7",
      "iata": "",
      "name": "Brush Creek Airport",
      "city": "Butlerville",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 39.04529953,
      "lon": -85.515296936,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "8IN9": {
      "icao": "8IN9",
      "iata": "",
      "name": "Marcidale Airport",
      "city": "Amo",
      "state": "Indiana",
      "country": "US",
      "elevation": 855,
      "lat": 39.68560028,
      "lon": -86.60220337,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "8IS1": {
      "icao": "8IS1",
      "iata": "",
      "name": "Nelson Airport",
      "city": "Carlinville",
      "state": "Illinois",
      "country": "US",
      "elevation": 615,
      "lat": 39.307800293,
      "lon": -89.9932022095,
      "tz": "America\/Chicago"
  },
  "8IS2": {
      "icao": "8IS2",
      "iata": "",
      "name": "Kilsoquah Farm Airport",
      "city": "Roanoke",
      "state": "Indiana",
      "country": "US",
      "elevation": 840,
      "lat": 40.9977989197,
      "lon": -85.3889007568,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "8IS5": {
      "icao": "8IS5",
      "iata": "",
      "name": "Raymond Classen Memorial Airport",
      "city": "Danforth",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 40.8166999817,
      "lon": -87.9916992188,
      "tz": "America\/Chicago"
  },
  "8KS0": {
      "icao": "8KS0",
      "iata": "",
      "name": "Morgan Farms Airport",
      "city": "Ulysses",
      "state": "Kansas",
      "country": "US",
      "elevation": 3190,
      "lat": 37.8125,
      "lon": -101.4589996338,
      "tz": "America\/Chicago"
  },
  "8KS1": {
      "icao": "8KS1",
      "iata": "",
      "name": "Neu Field",
      "city": "Basehor",
      "state": "Kansas",
      "country": "US",
      "elevation": 920,
      "lat": 39.1719017029,
      "lon": -94.980796814,
      "tz": "America\/Chicago"
  },
  "8KS2": {
      "icao": "8KS2",
      "iata": "",
      "name": "King Ranch Airport",
      "city": "Hamilton",
      "state": "Kansas",
      "country": "US",
      "elevation": 1160,
      "lat": 37.9388999939,
      "lon": -96.230796814,
      "tz": "America\/Chicago"
  },
  "8KS3": {
      "icao": "8KS3",
      "iata": "",
      "name": "Davis Airfield",
      "city": "Hiawatha",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 39.8527984619,
      "lon": -95.4804992676,
      "tz": "America\/Chicago"
  },
  "8KS4": {
      "icao": "8KS4",
      "iata": "",
      "name": "Rans Airport",
      "city": "Hays",
      "state": "Kansas",
      "country": "US",
      "elevation": 2100,
      "lat": 38.9049987793,
      "lon": -99.3508987427,
      "tz": "America\/Chicago"
  },
  "8KS5": {
      "icao": "8KS5",
      "iata": "",
      "name": "Blue Sky Airport",
      "city": "Parsons",
      "state": "Kansas",
      "country": "US",
      "elevation": 890,
      "lat": 37.3166999817,
      "lon": -95.2802963257,
      "tz": "America\/Chicago"
  },
  "8KS7": {
      "icao": "8KS7",
      "iata": "",
      "name": "Pine Sod Ranch Airport",
      "city": "Stilwell",
      "state": "Kansas",
      "country": "US",
      "elevation": 1072,
      "lat": 38.7331008911,
      "lon": -94.6452026367,
      "tz": "America\/Chicago"
  },
  "8KS8": {
      "icao": "8KS8",
      "iata": "",
      "name": "Prairie Cottage Airport",
      "city": "Chapman",
      "state": "Kansas",
      "country": "US",
      "elevation": 1222,
      "lat": 38.9178009033,
      "lon": -97.0077972412,
      "tz": "America\/Chicago"
  },
  "8KS9": {
      "icao": "8KS9",
      "iata": "",
      "name": "The Wilderness Airport",
      "city": "Williamsburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1050,
      "lat": 38.4444999695,
      "lon": -95.4253005981,
      "tz": "America\/Chicago"
  },
  "8KY0": {
      "icao": "8KY0",
      "iata": "",
      "name": "Alberta Ultralightport",
      "city": "Alberta",
      "state": "Kentucky",
      "country": "US",
      "elevation": 980,
      "lat": 38.3967018127,
      "lon": -84.4636001587,
      "tz": "America\/New_York"
  },
  "8KY3": {
      "icao": "8KY3",
      "iata": "",
      "name": "Battle Field",
      "city": "Perryville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 950,
      "lat": 37.661111,
      "lon": -84.9925,
      "tz": "America\/New_York"
  },
  "8KY6": {
      "icao": "8KY6",
      "iata": "",
      "name": "Caintuckee Airport",
      "city": "Independence",
      "state": "Kentucky",
      "country": "US",
      "elevation": 930,
      "lat": 38.935528,
      "lon": -84.568278,
      "tz": "America\/New_York"
  },
  "8LA9": {
      "icao": "8LA9",
      "iata": "",
      "name": "Koll Airport",
      "city": "Welsh",
      "state": "Louisiana",
      "country": "US",
      "elevation": 40,
      "lat": 30.3570995331,
      "lon": -92.7445983887,
      "tz": "America\/Chicago"
  },
  "8LL0": {
      "icao": "8LL0",
      "iata": "",
      "name": "Nance Airport",
      "city": "Old Ripley",
      "state": "Illinois",
      "country": "US",
      "elevation": 570,
      "lat": 38.8875007629,
      "lon": -89.5572967529,
      "tz": "America\/Chicago"
  },
  "8LL1": {
      "icao": "8LL1",
      "iata": "",
      "name": "Hunter Airport",
      "city": "Woodstock",
      "state": "Illinois",
      "country": "US",
      "elevation": 914,
      "lat": 42.3567008972,
      "lon": -88.5062026978,
      "tz": "America\/Chicago"
  },
  "8LL2": {
      "icao": "8LL2",
      "iata": "",
      "name": "Eckberg Airport",
      "city": "Wyanet",
      "state": "Illinois",
      "country": "US",
      "elevation": 670,
      "lat": 41.3694992065,
      "lon": -89.5597991943,
      "tz": "America\/Chicago"
  },
  "8LL3": {
      "icao": "8LL3",
      "iata": "",
      "name": "Hundley Airport",
      "city": "Rochester",
      "state": "Illinois",
      "country": "US",
      "elevation": 590,
      "lat": 39.7092005795,
      "lon": -89.4898438454,
      "tz": "America\/Chicago"
  },
  "8LL7": {
      "icao": "8LL7",
      "iata": "",
      "name": "Donald A. Hamilton Airport",
      "city": "Pawnee",
      "state": "Illinois",
      "country": "US",
      "elevation": 605,
      "lat": 39.5945014954,
      "lon": -89.5944976807,
      "tz": "America\/Chicago"
  },
  "8MD0": {
      "icao": "8MD0",
      "iata": "",
      "name": "Suzie Field",
      "city": "Woodbine",
      "state": "Maryland",
      "country": "US",
      "elevation": 560,
      "lat": 39.3833007813,
      "lon": -77.0175018311,
      "tz": "America\/New_York"
  },
  "8MD4": {
      "icao": "8MD4",
      "iata": "",
      "name": "Spiering Airport",
      "city": "Greensboro",
      "state": "Maryland",
      "country": "US",
      "elevation": 55,
      "lat": 38.9805984497,
      "lon": -75.7778015137,
      "tz": "America\/New_York"
  },
  "8MD5": {
      "icao": "8MD5",
      "iata": "",
      "name": "Harrison Farm Airport",
      "city": "Libertytown",
      "state": "Maryland",
      "country": "US",
      "elevation": 610,
      "lat": 39.498298645,
      "lon": -77.2217025757,
      "tz": "America\/New_York"
  },
  "8MD6": {
      "icao": "8MD6",
      "iata": "",
      "name": "Burgess Field",
      "city": "Riverside",
      "state": "Maryland",
      "country": "US",
      "elevation": 35,
      "lat": 38.3970985413,
      "lon": -77.1468963623,
      "tz": "America\/New_York"
  },
  "8MD7": {
      "icao": "8MD7",
      "iata": "",
      "name": "Deerfield Airport",
      "city": "Leonardtown",
      "state": "Maryland",
      "country": "US",
      "elevation": 25,
      "lat": 38.2386016846,
      "lon": -76.6596984863,
      "tz": "America\/New_York"
  },
  "8MI0": {
      "icao": "8MI0",
      "iata": "",
      "name": "Trowbridge Farms Airport",
      "city": "South Haven",
      "state": "Michigan",
      "country": "US",
      "elevation": 640,
      "lat": 42.4119987488,
      "lon": -86.1708984375,
      "tz": "America\/Detroit"
  },
  "8MI3": {
      "icao": "8MI3",
      "iata": "",
      "name": "Mattawan Airpark",
      "city": "Mattawan",
      "state": "Michigan",
      "country": "US",
      "elevation": 830,
      "lat": 42.1958999634,
      "lon": -85.8056030273,
      "tz": "America\/Detroit"
  },
  "8MI4": {
      "icao": "8MI4",
      "iata": "",
      "name": "Howe Airport",
      "city": "Morley",
      "state": "Michigan",
      "country": "US",
      "elevation": 871,
      "lat": 43.4958992004,
      "lon": -85.5736999512,
      "tz": "America\/Detroit"
  },
  "8MI7": {
      "icao": "8MI7",
      "iata": "",
      "name": "Bass Lake Airport",
      "city": "Steuben",
      "state": "Michigan",
      "country": "US",
      "elevation": 805,
      "lat": 46.1618995667,
      "lon": -86.4842987061,
      "tz": "America\/Detroit"
  },
  "8MI8": {
      "icao": "8MI8",
      "iata": "",
      "name": "Cupp\/Sjvs Landing Strip",
      "city": "Mendon",
      "state": "Michigan",
      "country": "US",
      "elevation": 850,
      "lat": 42.9947013855,
      "lon": -85.4542007446,
      "tz": "America\/Detroit"
  },
  "8MI9": {
      "icao": "8MI9",
      "iata": "",
      "name": "Winters Field",
      "city": "Chelsea",
      "state": "Michigan",
      "country": "US",
      "elevation": 1000,
      "lat": 42.3050003052,
      "lon": -84.1066970825,
      "tz": "America\/Detroit"
  },
  "8MN1": {
      "icao": "8MN1",
      "iata": "",
      "name": "Shimpa Airstrip",
      "city": "Euclid",
      "state": "Minnesota",
      "country": "US",
      "elevation": 885,
      "lat": 47.9667812491,
      "lon": -96.640226841,
      "tz": "America\/Chicago"
  },
  "8MN3": {
      "icao": "8MN3",
      "iata": "",
      "name": "Breezy Point Airport",
      "city": "Pequot Lakes",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1255,
      "lat": 46.5957984924,
      "lon": -94.2200012207,
      "tz": "America\/Chicago"
  },
  "8MN4": {
      "icao": "8MN4",
      "iata": "",
      "name": "Sethney Personal STOLport",
      "city": "Deerwood",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1289,
      "lat": 46.379699707,
      "lon": -93.8135986328,
      "tz": "America\/Chicago"
  },
  "8MN5": {
      "icao": "8MN5",
      "iata": "",
      "name": "Vieira Airport",
      "city": "Lino Lakes",
      "state": "Minnesota",
      "country": "US",
      "elevation": 900,
      "lat": 45.2083015442,
      "lon": -93.1335983276,
      "tz": "America\/Chicago"
  },
  "8MN8": {
      "icao": "8MN8",
      "iata": "",
      "name": "Porter Airport",
      "city": "Jacobson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1125,
      "lat": 47.0126991272,
      "lon": -93.3937988281,
      "tz": "America\/Chicago"
  },
  "8MN9": {
      "icao": "8MN9",
      "iata": "",
      "name": "Mavencamp Airport",
      "city": "Maple Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1027,
      "lat": 45.2569007874,
      "lon": -94.039100647,
      "tz": "America\/Chicago"
  },
  "8MO3": {
      "icao": "8MO3",
      "iata": "",
      "name": "Curtis Field",
      "city": "Richmond",
      "state": "Missouri",
      "country": "US",
      "elevation": 760,
      "lat": 39.3246994019,
      "lon": -93.9415969849,
      "tz": "America\/Chicago"
  },
  "8MO4": {
      "icao": "8MO4",
      "iata": "",
      "name": "Joan Lake Airport",
      "city": "Richwoods",
      "state": "Missouri",
      "country": "US",
      "elevation": 800,
      "lat": 38.2084007263,
      "lon": -90.8667984009,
      "tz": "America\/Chicago"
  },
  "8MO5": {
      "icao": "8MO5",
      "iata": "",
      "name": "Cayton Pony Express Airport",
      "city": "Maysville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1043,
      "lat": 39.8227996826,
      "lon": -94.3841018677,
      "tz": "America\/Chicago"
  },
  "8MO7": {
      "icao": "8MO7",
      "iata": "",
      "name": "Bean Lake Airport",
      "city": "Rushville",
      "state": "Missouri",
      "country": "US",
      "elevation": 780,
      "lat": 39.4916992188,
      "lon": -95.0065994263,
      "tz": "America\/Chicago"
  },
  "8MO8": {
      "icao": "8MO8",
      "iata": "",
      "name": "Frazier Airport",
      "city": "Monett",
      "state": "Missouri",
      "country": "US",
      "elevation": 1400,
      "lat": 36.9112014771,
      "lon": -93.866897583,
      "tz": "America\/Chicago"
  },
  "8NA0": {
      "icao": "8NA0",
      "iata": "",
      "name": "Tappen Airstrip",
      "city": "Tappen",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1780,
      "lat": 46.8810997009,
      "lon": -99.630897522,
      "tz": "America\/Chicago"
  },
  "8NA5": {
      "icao": "8NA5",
      "iata": "",
      "name": "Liechty Farm Airport",
      "city": "Montpelier",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1505,
      "lat": 46.6629981995,
      "lon": -98.6304016113,
      "tz": "America\/Chicago"
  },
  "8NA9": {
      "icao": "8NA9",
      "iata": "",
      "name": "Hashbarger Farm Airstrip",
      "city": "Hope",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1195,
      "lat": 47.327999115,
      "lon": -97.6790008545,
      "tz": "America\/Chicago"
  },
  "8NC1": {
      "icao": "8NC1",
      "iata": "",
      "name": "Dean Wings Past Airport",
      "city": "Cameron",
      "state": "North Carolina",
      "country": "US",
      "elevation": 480,
      "lat": 35.3763008118,
      "lon": -79.2408981323,
      "tz": "America\/New_York"
  },
  "8NC2": {
      "icao": "8NC2",
      "iata": "",
      "name": "Summey Airpark",
      "city": "Caroleen",
      "state": "North Carolina",
      "country": "US",
      "elevation": 1000,
      "lat": 35.2892990112,
      "lon": -81.7851028442,
      "tz": "America\/New_York"
  },
  "8NC3": {
      "icao": "8NC3",
      "iata": "",
      "name": "Winding Creek Airport",
      "city": "Sneads Ferry",
      "state": "North Carolina",
      "country": "US",
      "elevation": 53,
      "lat": 34.5661010742,
      "lon": -77.4405975342,
      "tz": "America\/New_York"
  },
  "8NC4": {
      "icao": "8NC4",
      "iata": "",
      "name": "Dead Dog Airport",
      "city": "Pittsboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 525,
      "lat": 35.7299003601,
      "lon": -79.3127975464,
      "tz": "America\/New_York"
  },
  "8NC5": {
      "icao": "8NC5",
      "iata": "",
      "name": "Adams Airport",
      "city": "Rowland",
      "state": "North Carolina",
      "country": "US",
      "elevation": 143,
      "lat": 34.5760993958,
      "lon": -79.2488021851,
      "tz": "America\/New_York"
  },
  "8NC6": {
      "icao": "8NC6",
      "iata": "",
      "name": "Brooks Field",
      "city": "Siler City",
      "state": "North Carolina",
      "country": "US",
      "elevation": 620,
      "lat": 35.6896018982,
      "lon": -79.4069976807,
      "tz": "America\/New_York"
  },
  "8NC7": {
      "icao": "8NC7",
      "iata": "",
      "name": "Parker Field",
      "city": "Maysville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 40,
      "lat": 34.9364013672,
      "lon": -77.2767028809,
      "tz": "America\/New_York"
  },
  "8NC8": {
      "icao": "8NC8",
      "iata": "",
      "name": "Lake Ridge Aero Park Airport",
      "city": "Durham",
      "state": "North Carolina",
      "country": "US",
      "elevation": 309,
      "lat": 36.0601005554,
      "lon": -78.7833023071,
      "tz": "America\/New_York"
  },
  "8NC9": {
      "icao": "8NC9",
      "iata": "",
      "name": "W.N.C. Air Museum Airport",
      "city": "Hendersonville",
      "state": "North Carolina",
      "country": "US",
      "elevation": 2083,
      "lat": 35.3072013855,
      "lon": -82.4336013794,
      "tz": "America\/New_York"
  },
  "8ND0": {
      "icao": "8ND0",
      "iata": "",
      "name": "Amble-Tiger North Farms Airport",
      "city": "Sarles",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1590,
      "lat": 48.9014015198,
      "lon": -99.0167999268,
      "tz": "America\/Chicago"
  },
  "8ND4": {
      "icao": "8ND4",
      "iata": "",
      "name": "Heyde Airport",
      "city": "Manvel",
      "state": "North Dakota",
      "country": "US",
      "elevation": 820,
      "lat": 48.073600769,
      "lon": -97.1862030029,
      "tz": "America\/Chicago"
  },
  "8ND5": {
      "icao": "8ND5",
      "iata": "",
      "name": "Ausk Strip",
      "city": "Chaffee",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1020,
      "lat": 46.7639007568,
      "lon": -97.4054031372,
      "tz": "America\/Chicago"
  },
  "8ND6": {
      "icao": "8ND6",
      "iata": "",
      "name": "J Vining Airport",
      "city": "Chaffee",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1025,
      "lat": 46.7126998901,
      "lon": -97.3908996582,
      "tz": "America\/Chicago"
  },
  "8NE2": {
      "icao": "8NE2",
      "iata": "",
      "name": "P & R Airport",
      "city": "Cedar Creek",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1100,
      "lat": 41.0416984558,
      "lon": -96.0335998535,
      "tz": "America\/Chicago"
  },
  "8NE3": {
      "icao": "8NE3",
      "iata": "",
      "name": "Smith Field",
      "city": "Champion",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3380,
      "lat": 40.4818992615,
      "lon": -101.81300354,
      "tz": "America\/Denver"
  },
  "8NE4": {
      "icao": "8NE4",
      "iata": "",
      "name": "Bornemeier Airstrip",
      "city": "Elmwood",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1292,
      "lat": 40.8708000183,
      "lon": -96.2919998169,
      "tz": "America\/Chicago"
  },
  "8NE5": {
      "icao": "8NE5",
      "iata": "",
      "name": "X1 Ranch Airport",
      "city": "Ericson",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2070,
      "lat": 41.790599823,
      "lon": -98.7297973633,
      "tz": "America\/Chicago"
  },
  "8NE6": {
      "icao": "8NE6",
      "iata": "",
      "name": "Franklin's Plainview Airport",
      "city": "Franklin",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2000,
      "lat": 40.1208000183,
      "lon": -98.9253997803,
      "tz": "America\/Chicago"
  },
  "8NE7": {
      "icao": "8NE7",
      "iata": "",
      "name": "Smith Airport",
      "city": "Franklin",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1948,
      "lat": 40.0999984741,
      "lon": -99.0003967285,
      "tz": "America\/Chicago"
  },
  "8NE9": {
      "icao": "8NE9",
      "iata": "",
      "name": "C A M P Airport",
      "city": "Fullerton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1675,
      "lat": 41.3903007507,
      "lon": -97.9645004272,
      "tz": "America\/Chicago"
  },
  "8NJ0": {
      "icao": "8NJ0",
      "iata": "",
      "name": "Winchelsea Airport",
      "city": "Port Republic",
      "state": "New Jersey",
      "country": "US",
      "elevation": 30,
      "lat": 39.5178985596,
      "lon": -74.5090026855,
      "tz": "America\/New_York"
  },
  "8NK3": {
      "icao": "8NK3",
      "iata": "",
      "name": "Harris Airport",
      "city": "Fort Ann",
      "state": "Vermont",
      "country": "US",
      "elevation": 261,
      "lat": 43.5,
      "lon": -73.066667,
      "tz": "America\/New_York"
  },
  "8NK4": {
      "icao": "8NK4",
      "iata": "",
      "name": "Bethany Airpark",
      "city": "Bethany Center",
      "state": "New York",
      "country": "US",
      "elevation": 1000,
      "lat": 42.9432983398,
      "lon": -78.1382980347,
      "tz": "America\/New_York"
  },
  "8NK6": {
      "icao": "8NK6",
      "iata": "",
      "name": "Suntime Airport",
      "city": "West Burlington",
      "state": "New York",
      "country": "US",
      "elevation": 1650,
      "lat": 42.713056,
      "lon": -75.168333,
      "tz": "America\/New_York"
  },
  "8NY3": {
      "icao": "8NY3",
      "iata": "",
      "name": "North Fork Airport",
      "city": "Afton",
      "state": "New York",
      "country": "US",
      "elevation": 1455,
      "lat": 42.268699646,
      "lon": -75.5597000122,
      "tz": "America\/New_York"
  },
  "8NY5": {
      "icao": "8NY5",
      "iata": "",
      "name": "Mariaville Aerodrome",
      "city": "Duanesburg",
      "state": "New York",
      "country": "US",
      "elevation": 1260,
      "lat": 42.820400238,
      "lon": -74.1482009888,
      "tz": "America\/New_York"
  },
  "8NY7": {
      "icao": "8NY7",
      "iata": "",
      "name": "Sha-Wan-Ga Valley Airport",
      "city": "Bloomingburg",
      "state": "New York",
      "country": "US",
      "elevation": 437,
      "lat": 41.5714988708,
      "lon": -74.4023971558,
      "tz": "America\/New_York"
  },
  "8OA5": {
      "icao": "8OA5",
      "iata": "",
      "name": "Camp Crook Municipal Airport",
      "city": "Camp Crook",
      "state": "South Dakota",
      "country": "US",
      "elevation": 3140,
      "lat": 45.5666999817,
      "lon": -103.9840011597,
      "tz": "America\/Denver"
  },
  "8OA6": {
      "icao": "8OA6",
      "iata": "",
      "name": "Warner Airstrip",
      "city": "Vickery",
      "state": "Ohio",
      "country": "US",
      "elevation": 611,
      "lat": 41.3861999512,
      "lon": -82.897102356,
      "tz": "America\/New_York"
  },
  "8OA7": {
      "icao": "8OA7",
      "iata": "",
      "name": "Bald Eagle Field",
      "city": "Stockport",
      "state": "Ohio",
      "country": "US",
      "elevation": 680,
      "lat": 39.5564994812,
      "lon": -81.78099823,
      "tz": "America\/New_York"
  },
  "8OA9": {
      "icao": "8OA9",
      "iata": "",
      "name": "Margos Sky Ranch Airport",
      "city": "Seville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1045,
      "lat": 41.0444984436,
      "lon": -81.8156967163,
      "tz": "America\/New_York"
  },
  "8OH3": {
      "icao": "8OH3",
      "iata": "",
      "name": "J and B Sky Ranch Airport",
      "city": "Coitsville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1125,
      "lat": 41.1172981262,
      "lon": -80.5225982666,
      "tz": "America\/New_York"
  },
  "8OH4": {
      "icao": "8OH4",
      "iata": "",
      "name": "York Aerodrome",
      "city": "Columbiana",
      "state": "Ohio",
      "country": "US",
      "elevation": 1260,
      "lat": 40.8438987732,
      "lon": -80.7025985718,
      "tz": "America\/New_York"
  },
  "8OH5": {
      "icao": "8OH5",
      "iata": "",
      "name": "Urban Airport",
      "city": "Cortland",
      "state": "Ohio",
      "country": "US",
      "elevation": 1045,
      "lat": 41.3819999695,
      "lon": -80.7123031616,
      "tz": "America\/New_York"
  },
  "8OH7": {
      "icao": "8OH7",
      "iata": "",
      "name": "Morris Field",
      "city": "East Palestine",
      "state": "Ohio",
      "country": "US",
      "elevation": 1250,
      "lat": 40.8823013306,
      "lon": -80.6003036499,
      "tz": "America\/New_York"
  },
  "8OH8": {
      "icao": "8OH8",
      "iata": "",
      "name": "Allen Airport",
      "city": "Dorset",
      "state": "Ohio",
      "country": "US",
      "elevation": 985,
      "lat": 41.6680984497,
      "lon": -80.6856002808,
      "tz": "America\/New_York"
  },
  "8OI0": {
      "icao": "8OI0",
      "iata": "",
      "name": "Brannon Field",
      "city": "Newbury",
      "state": "Ohio",
      "country": "US",
      "elevation": 1250,
      "lat": 41.4542007446,
      "lon": -81.2453994751,
      "tz": "America\/New_York"
  },
  "8OI3": {
      "icao": "8OI3",
      "iata": "",
      "name": "Allen Airport",
      "city": "Cortland",
      "state": "Ohio",
      "country": "US",
      "elevation": 1105,
      "lat": 41.3734016418,
      "lon": -80.6689987183,
      "tz": "America\/New_York"
  },
  "8OI5": {
      "icao": "8OI5",
      "iata": "",
      "name": "Clum Airport",
      "city": "Thornville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1115,
      "lat": 39.8816986084,
      "lon": -82.4145965576,
      "tz": "America\/New_York"
  },
  "8OI9": {
      "icao": "8OI9",
      "iata": "",
      "name": "Morkassel Field",
      "city": "Idaho",
      "state": "Ohio",
      "country": "US",
      "elevation": 850,
      "lat": 39.0992012024,
      "lon": -83.1166000366,
      "tz": "America\/New_York"
  },
  "8OK0": {
      "icao": "8OK0",
      "iata": "",
      "name": "Lamle Airport",
      "city": "Okeene",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1212,
      "lat": 36.0682983398,
      "lon": -98.2891998291,
      "tz": "America\/Chicago"
  },
  "8OK1": {
      "icao": "8OK1",
      "iata": "",
      "name": "Warbonnet Airport",
      "city": "Blanchard",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1335,
      "lat": 35.1683006287,
      "lon": -97.7108001709,
      "tz": "America\/Chicago"
  },
  "8OK2": {
      "icao": "8OK2",
      "iata": "",
      "name": "Baker Airstrip",
      "city": "El Reno",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1370,
      "lat": 35.4751014709,
      "lon": -97.9289016724,
      "tz": "America\/Chicago"
  },
  "8OK4": {
      "icao": "8OK4",
      "iata": "",
      "name": "Brandley Airport",
      "city": "El Reno",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1400,
      "lat": 35.5041999817,
      "lon": -98.0406036377,
      "tz": "America\/Chicago"
  },
  "8OK6": {
      "icao": "8OK6",
      "iata": "",
      "name": "Barry Dotson Ranch Airport",
      "city": "Marble City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 880,
      "lat": 35.5667991638,
      "lon": -94.7501983643,
      "tz": "America\/Chicago"
  },
  "8OK7": {
      "icao": "8OK7",
      "iata": "",
      "name": "Cc & M Airport",
      "city": "Medford",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1085,
      "lat": 36.7972984314,
      "lon": -97.7183990479,
      "tz": "America\/Chicago"
  },
  "8OL1": {
      "icao": "8OL1",
      "iata": "",
      "name": "Petes Airpark",
      "city": "Wetumka",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 771,
      "lat": 35.2279014587,
      "lon": -96.2294998169,
      "tz": "America\/Chicago"
  },
  "8OR2": {
      "icao": "8OR2",
      "iata": "",
      "name": "Kingston Airpark",
      "city": "Stayton",
      "state": "Oregon",
      "country": "US",
      "elevation": 871,
      "lat": 44.7784996033,
      "lon": -122.7300033569,
      "tz": "America\/Los_Angeles"
  },
  "8OR3": {
      "icao": "8OR3",
      "iata": "",
      "name": "Riverview Ranch Airport",
      "city": "Days Creek",
      "state": "Oregon",
      "country": "US",
      "elevation": 840,
      "lat": 42.9337005615,
      "lon": -123.1210021973,
      "tz": "America\/Los_Angeles"
  },
  "8OR5": {
      "icao": "8OR5",
      "iata": "",
      "name": "Pilot Butte Airport",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3675,
      "lat": 44.0472984314,
      "lon": -121.2760009766,
      "tz": "America\/Los_Angeles"
  },
  "8OR6": {
      "icao": "8OR6",
      "iata": "",
      "name": "Grabhorn's Airport",
      "city": "Scappoose",
      "state": "Oregon",
      "country": "US",
      "elevation": 255,
      "lat": 45.7822990417,
      "lon": -122.8939971924,
      "tz": "America\/Los_Angeles"
  },
  "8OR7": {
      "icao": "8OR7",
      "iata": "",
      "name": "Gates Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 220,
      "lat": 44.6271019,
      "lon": -123.2249985,
      "tz": "America\/Los_Angeles"
  },
  "8PA0": {
      "icao": "8PA0",
      "iata": "",
      "name": "Numidia Airport",
      "city": "Numidia",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1070,
      "lat": 40.8661994934,
      "lon": -76.3973999023,
      "tz": "America\/New_York"
  },
  "8PA1": {
      "icao": "8PA1",
      "iata": "",
      "name": "Dee Jay Airport",
      "city": "Ono",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 505,
      "lat": 40.4084014893,
      "lon": -76.5038986206,
      "tz": "America\/New_York"
  },
  "8PA3": {
      "icao": "8PA3",
      "iata": "",
      "name": "Deer Meadows Airstrip",
      "city": "Weatherly",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1210,
      "lat": 40.9958992004,
      "lon": -75.7405014038,
      "tz": "America\/New_York"
  },
  "8PA4": {
      "icao": "8PA4",
      "iata": "",
      "name": "J F T Airport",
      "city": "Mifflinburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 660,
      "lat": 40.9444999695,
      "lon": -77.0457992554,
      "tz": "America\/New_York"
  },
  "8PA6": {
      "icao": "8PA6",
      "iata": "",
      "name": "Turtle Rock Airstrip",
      "city": "Shermansdale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 626,
      "lat": 40.329306,
      "lon": -77.167639,
      "tz": "America\/New_York"
  },
  "8PA8": {
      "icao": "8PA8",
      "iata": "",
      "name": "Sunny Rest Airport",
      "city": "Palmerton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 980,
      "lat": 40.8167991638,
      "lon": -75.6662979126,
      "tz": "America\/New_York"
  },
  "8PN0": {
      "icao": "8PN0",
      "iata": "",
      "name": "Lost Acres Airport",
      "city": "Chambersburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 730,
      "lat": 39.9383010864,
      "lon": -77.6164016724,
      "tz": "America\/New_York"
  },
  "8PN2": {
      "icao": "8PN2",
      "iata": "",
      "name": "Hallett's Airport",
      "city": "Bangor",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 700,
      "lat": 40.9104995728,
      "lon": -75.1651000977,
      "tz": "America\/New_York"
  },
  "8PN3": {
      "icao": "8PN3",
      "iata": "",
      "name": "Mc Cauley'S Airport",
      "city": "Venus",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1571,
      "lat": 41.383333,
      "lon": -79.5,
      "tz": "America\/New_York"
  },
  "8PN9": {
      "icao": "8PN9",
      "iata": "",
      "name": "Marsh Creek Airport",
      "city": "Gettysburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 530,
      "lat": 39.8219985962,
      "lon": -77.2919006348,
      "tz": "America\/New_York"
  },
  "8PS0": {
      "icao": "8PS0",
      "iata": "",
      "name": "Middlebury Airport",
      "city": "Middlebury Center",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1170,
      "lat": 41.8479003906,
      "lon": -77.2814025879,
      "tz": "America\/New_York"
  },
  "8PS2": {
      "icao": "8PS2",
      "iata": "",
      "name": "Still Meadow Farm Airport",
      "city": "Jackson Center",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1370,
      "lat": 41.3106002808,
      "lon": -80.1628036499,
      "tz": "America\/New_York"
  },
  "8TA0": {
      "icao": "8TA0",
      "iata": "",
      "name": "John B Connally Ranch Airport",
      "city": "Floresville",
      "state": "Texas",
      "country": "US",
      "elevation": 530,
      "lat": 29.135799408,
      "lon": -98.2763977051,
      "tz": "America\/Chicago"
  },
  "8TA1": {
      "icao": "8TA1",
      "iata": "",
      "name": "Whatley Flying Service Airport",
      "city": "Seadrift",
      "state": "Texas",
      "country": "US",
      "elevation": 20,
      "lat": 28.478099823,
      "lon": -96.7619018555,
      "tz": "America\/Chicago"
  },
  "8TA3": {
      "icao": "8TA3",
      "iata": "",
      "name": "Flying X River Ranch Airport",
      "city": "Spicewood",
      "state": "Texas",
      "country": "US",
      "elevation": 820,
      "lat": 30.5168991089,
      "lon": -98.1742019653,
      "tz": "America\/Chicago"
  },
  "8TA4": {
      "icao": "8TA4",
      "iata": "",
      "name": "Laseair Airport",
      "city": "Texas City",
      "state": "Texas",
      "country": "US",
      "elevation": 12,
      "lat": 29.4454994202,
      "lon": -95.0076980591,
      "tz": "America\/Chicago"
  },
  "8TA5": {
      "icao": "8TA5",
      "iata": "",
      "name": "Short Stop Airport",
      "city": "Farmersville",
      "state": "Texas",
      "country": "US",
      "elevation": 645,
      "lat": 33.1632003784,
      "lon": -96.3221969604,
      "tz": "America\/Chicago"
  },
  "8TA7": {
      "icao": "8TA7",
      "iata": "",
      "name": "Stark Field",
      "city": "Granbury",
      "state": "Texas",
      "country": "US",
      "elevation": 900,
      "lat": 32.5695991516,
      "lon": -97.7530975342,
      "tz": "America\/Chicago"
  },
  "8TA8": {
      "icao": "8TA8",
      "iata": "",
      "name": "Bufords Field",
      "city": "Huntington",
      "state": "Texas",
      "country": "US",
      "elevation": 200,
      "lat": 31.1690998077,
      "lon": -94.5324020386,
      "tz": "America\/Chicago"
  },
  "8TA9": {
      "icao": "8TA9",
      "iata": "",
      "name": "Star Dusters Airport",
      "city": "Washington",
      "state": "Louisiana",
      "country": "US",
      "elevation": 37,
      "lat": 30.635799408,
      "lon": -92.0584030151,
      "tz": "America\/Chicago"
  },
  "8TE0": {
      "icao": "8TE0",
      "iata": "",
      "name": "Gillingham Airport",
      "city": "Floresville",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 29.1390991211,
      "lon": -98.1132965088,
      "tz": "America\/Chicago"
  },
  "8TE1": {
      "icao": "8TE1",
      "iata": "",
      "name": "Wall Flying Service Airport",
      "city": "Floresville",
      "state": "Texas",
      "country": "US",
      "elevation": 440,
      "lat": 29.017999649,
      "lon": -98.206703186,
      "tz": "America\/Chicago"
  },
  "8TE2": {
      "icao": "8TE2",
      "iata": "",
      "name": "J-Bar Ranch Airport",
      "city": "Crane",
      "state": "Texas",
      "country": "US",
      "elevation": 2667,
      "lat": 31.5279006958,
      "lon": -102.5279998779,
      "tz": "America\/Chicago"
  },
  "8TE3": {
      "icao": "8TE3",
      "iata": "",
      "name": "Lopez Ranch Airport",
      "city": "Fort Mc Kavett",
      "state": "Texas",
      "country": "US",
      "elevation": 2318,
      "lat": 30.7182006836,
      "lon": -100.0810012817,
      "tz": "America\/Chicago"
  },
  "8TE4": {
      "icao": "8TE4",
      "iata": "",
      "name": "H & F Properties Airport",
      "city": "Crystal City",
      "state": "Texas",
      "country": "US",
      "elevation": 640,
      "lat": 28.8150005341,
      "lon": -99.758102417,
      "tz": "America\/Chicago"
  },
  "8TE6": {
      "icao": "8TE6",
      "iata": "",
      "name": "Faith Cattle Company Longfellow Ranch Airport",
      "city": "Fort Stockton",
      "state": "Texas",
      "country": "US",
      "elevation": 3900,
      "lat": 30.3001995087,
      "lon": -102.717002869,
      "tz": "America\/Chicago"
  },
  "8TE7": {
      "icao": "8TE7",
      "iata": "",
      "name": "Carter Ranch STOLport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 1840,
      "lat": 30.3208007813,
      "lon": -98.9128036499,
      "tz": "America\/Chicago"
  },
  "8TE8": {
      "icao": "8TE8",
      "iata": "",
      "name": "Tradewind Agricultural Airport",
      "city": "El Campo",
      "state": "Texas",
      "country": "US",
      "elevation": 95,
      "lat": 29.1627998352,
      "lon": -96.2251968384,
      "tz": "America\/Chicago"
  },
  "8TN1": {
      "icao": "8TN1",
      "iata": "",
      "name": "Parker Airport",
      "city": "Eads",
      "state": "Tennessee",
      "country": "US",
      "elevation": 375,
      "lat": 35.1963996887,
      "lon": -89.6268997192,
      "tz": "America\/Chicago"
  },
  "8TN2": {
      "icao": "8TN2",
      "iata": "",
      "name": "Pleasant Grove Airpark",
      "city": "Shelbyville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 850,
      "lat": 35.3988990784,
      "lon": -86.5539016724,
      "tz": "America\/Chicago"
  },
  "8TN3": {
      "icao": "8TN3",
      "iata": "",
      "name": "Raby Airpark",
      "city": "Farragut",
      "state": "Tennessee",
      "country": "US",
      "elevation": 830,
      "lat": 35.8417015076,
      "lon": -84.181098938,
      "tz": "America\/New_York"
  },
  "8TN4": {
      "icao": "8TN4",
      "iata": "",
      "name": "Flaglor Airport",
      "city": "Mosheim",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1135,
      "lat": 36.2447013855,
      "lon": -82.9689025879,
      "tz": "America\/New_York"
  },
  "8TN5": {
      "icao": "8TN5",
      "iata": "",
      "name": "Nobuzzn Airport",
      "city": "Springfield",
      "state": "Tennessee",
      "country": "US",
      "elevation": 650,
      "lat": 36.4864006042,
      "lon": -86.9111022949,
      "tz": "America\/Chicago"
  },
  "8TN6": {
      "icao": "8TN6",
      "iata": "",
      "name": "Baker Field",
      "city": "Murfreesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 560,
      "lat": 35.9211006165,
      "lon": -86.3447036743,
      "tz": "America\/Chicago"
  },
  "8TN7": {
      "icao": "8TN7",
      "iata": "",
      "name": "Short Creek Airport",
      "city": "Dover",
      "state": "Tennessee",
      "country": "US",
      "elevation": 390,
      "lat": 36.4007987976,
      "lon": -87.9916992188,
      "tz": "America\/Chicago"
  },
  "8TN8": {
      "icao": "8TN8",
      "iata": "",
      "name": "Field of Dreams Ultralightport",
      "city": "Burlison",
      "state": "Tennessee",
      "country": "US",
      "elevation": 250,
      "lat": 35.5416984558,
      "lon": -89.8713989258,
      "tz": "America\/Chicago"
  },
  "8TN9": {
      "icao": "8TN9",
      "iata": "",
      "name": "Bull Run Airport",
      "city": "Covington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 376,
      "lat": 35.5155982971,
      "lon": -89.6778030396,
      "tz": "America\/Chicago"
  },
  "8TS0": {
      "icao": "8TS0",
      "iata": "",
      "name": "Hamilton Ranch Airport",
      "city": "Bruni",
      "state": "Texas",
      "country": "US",
      "elevation": 698,
      "lat": 27.5531005859,
      "lon": -98.7322006226,
      "tz": "America\/Chicago"
  },
  "8TS1": {
      "icao": "8TS1",
      "iata": "",
      "name": "Retta Airport",
      "city": "Burleson",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.5418014526,
      "lon": -97.2419967651,
      "tz": "America\/Chicago"
  },
  "8TS2": {
      "icao": "8TS2",
      "iata": "",
      "name": "Henrietta Airport",
      "city": "Henrietta",
      "state": "Texas",
      "country": "US",
      "elevation": 932,
      "lat": 33.7834014893,
      "lon": -98.2170028687,
      "tz": "America\/Chicago"
  },
  "8TS3": {
      "icao": "8TS3",
      "iata": "",
      "name": "Bruner Airport",
      "city": "Latexo",
      "state": "Texas",
      "country": "US",
      "elevation": 387,
      "lat": 31.3901996613,
      "lon": -95.4732971191,
      "tz": "America\/Chicago"
  },
  "8TS5": {
      "icao": "8TS5",
      "iata": "",
      "name": "Stol Field",
      "city": "Joshua",
      "state": "Texas",
      "country": "US",
      "elevation": 908,
      "lat": 32.4678993225,
      "lon": -97.3683013916,
      "tz": "America\/Chicago"
  },
  "8TS6": {
      "icao": "8TS6",
      "iata": "",
      "name": "Moore Airport",
      "city": "Kaufman",
      "state": "Texas",
      "country": "US",
      "elevation": 418,
      "lat": 32.5331993103,
      "lon": -96.3225021362,
      "tz": "America\/Chicago"
  },
  "8TS7": {
      "icao": "8TS7",
      "iata": "",
      "name": "Wyatt 3-Rivers Airport",
      "city": "Glen Rose",
      "state": "Texas",
      "country": "US",
      "elevation": 615,
      "lat": 32.2448997498,
      "lon": -97.7260971069,
      "tz": "America\/Chicago"
  },
  "8TS8": {
      "icao": "8TS8",
      "iata": "",
      "name": "Rnk Ranch Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1945,
      "lat": 29.9174003601,
      "lon": -100.9130020142,
      "tz": "America\/Chicago"
  },
  "8TS9": {
      "icao": "8TS9",
      "iata": "",
      "name": "Strait Ranch Airport",
      "city": "Encinal",
      "state": "Texas",
      "country": "US",
      "elevation": 620,
      "lat": 28.1399993896,
      "lon": -99.5702972412,
      "tz": "America\/Chicago"
  },
  "8TX0": {
      "icao": "8TX0",
      "iata": "",
      "name": "Hub Field",
      "city": "Jewett",
      "state": "Texas",
      "country": "US",
      "elevation": 400,
      "lat": 31.429599762,
      "lon": -96.1302032471,
      "tz": "America\/Chicago"
  },
  "8TX2": {
      "icao": "8TX2",
      "iata": "",
      "name": "Freeman Ranch Airport",
      "city": "Rocksprings",
      "state": "Texas",
      "country": "US",
      "elevation": 2380,
      "lat": 29.9785003662,
      "lon": -100.202003479,
      "tz": "America\/Chicago"
  },
  "8TX3": {
      "icao": "8TX3",
      "iata": "",
      "name": "Edwards Airport",
      "city": "Goldthwaite",
      "state": "Texas",
      "country": "US",
      "elevation": 1300,
      "lat": 31.3428993225,
      "lon": -98.6156005859,
      "tz": "America\/Chicago"
  },
  "8TX6": {
      "icao": "8TX6",
      "iata": "",
      "name": "Harper Airport",
      "city": "Palmer",
      "state": "Texas",
      "country": "US",
      "elevation": 510,
      "lat": 32.4276008606,
      "lon": -96.7169036865,
      "tz": "America\/Chicago"
  },
  "8TX7": {
      "icao": "8TX7",
      "iata": "",
      "name": "Skyhaven Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 40,
      "lat": 29.8335990906,
      "lon": -95.1484985352,
      "tz": "America\/Chicago"
  },
  "8TX8": {
      "icao": "8TX8",
      "iata": "",
      "name": "Weeks Airport",
      "city": "Premont",
      "state": "Texas",
      "country": "US",
      "elevation": 190,
      "lat": 27.3609008789,
      "lon": -98.1794967651,
      "tz": "America\/Chicago"
  },
  "8VA1": {
      "icao": "8VA1",
      "iata": "",
      "name": "Grand Pre Farm Airport",
      "city": "Brightwood",
      "state": "Virginia",
      "country": "US",
      "elevation": 500,
      "lat": 38.4542999268,
      "lon": -78.1864013672,
      "tz": "America\/New_York"
  },
  "8VA2": {
      "icao": "8VA2",
      "iata": "",
      "name": "Shivok Airport",
      "city": "Callao",
      "state": "Virginia",
      "country": "US",
      "elevation": 22,
      "lat": 38.0089988708,
      "lon": -76.56300354,
      "tz": "America\/New_York"
  },
  "8VA4": {
      "icao": "8VA4",
      "iata": "",
      "name": "Lotus International Airport",
      "city": "Buckinham",
      "state": "Virginia",
      "country": "US",
      "elevation": 340,
      "lat": 37.6729011536,
      "lon": -78.7033004761,
      "tz": "America\/New_York"
  },
  "8VA6": {
      "icao": "8VA6",
      "iata": "",
      "name": "Flatwoods Airport",
      "city": "Lovingston",
      "state": "Virginia",
      "country": "US",
      "elevation": 840,
      "lat": 37.7382011414,
      "lon": -78.9621963501,
      "tz": "America\/New_York"
  },
  "8WA0": {
      "icao": "8WA0",
      "iata": "",
      "name": "Flying B Airport",
      "city": "Rainier",
      "state": "Washington",
      "country": "US",
      "elevation": 445,
      "lat": 46.8776016235,
      "lon": -122.6009979248,
      "tz": "America\/Los_Angeles"
  },
  "8WA5": {
      "icao": "8WA5",
      "iata": "",
      "name": "Tree Heart Ranch Airport",
      "city": "Ritzville",
      "state": "Washington",
      "country": "US",
      "elevation": 1420,
      "lat": 47.1414985657,
      "lon": -118.8010025024,
      "tz": "America\/Los_Angeles"
  },
  "8WA6": {
      "icao": "8WA6",
      "iata": "",
      "name": "Christensen Field",
      "city": "Royal City",
      "state": "Washington",
      "country": "US",
      "elevation": 1150,
      "lat": 46.9207000732,
      "lon": -119.5899963379,
      "tz": "America\/Los_Angeles"
  },
  "8WA7": {
      "icao": "8WA7",
      "iata": "",
      "name": "Gossard Field",
      "city": "St John",
      "state": "Washington",
      "country": "US",
      "elevation": 1973,
      "lat": 47.1152000427,
      "lon": -117.5739974976,
      "tz": "America\/Los_Angeles"
  },
  "8WI0": {
      "icao": "8WI0",
      "iata": "",
      "name": "Wood Airport",
      "city": "Wisconsin Dells",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 935,
      "lat": 43.7439002991,
      "lon": -89.8143005371,
      "tz": "America\/Chicago"
  },
  "8WI1": {
      "icao": "8WI1",
      "iata": "",
      "name": "Dillenburg's Airport",
      "city": "Shawano",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 915,
      "lat": 44.7536010742,
      "lon": -88.7282028198,
      "tz": "America\/Chicago"
  },
  "8WI2": {
      "icao": "8WI2",
      "iata": "",
      "name": "Runway Leasing Inc Nr 1 Airport",
      "city": "Plainfield",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1100,
      "lat": 44.2247009277,
      "lon": -89.53099823,
      "tz": "America\/Chicago"
  },
  "8WI3": {
      "icao": "8WI3",
      "iata": "",
      "name": "Rwnway Leasing Inc Nr 2 Airport",
      "city": "Plover",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1070,
      "lat": 44.4207992554,
      "lon": -89.5553970337,
      "tz": "America\/Chicago"
  },
  "8WI5": {
      "icao": "8WI5",
      "iata": "",
      "name": "Wolf River Landing Strip",
      "city": "Black Creek",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 670,
      "lat": 44.515496,
      "lon": -88.54164,
      "tz": "America\/Chicago"
  },
  "8WI6": {
      "icao": "8WI6",
      "iata": "",
      "name": "Funk Aerodrome",
      "city": "Luxemburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 831,
      "lat": 44.6096992493,
      "lon": -87.6529006958,
      "tz": "America\/Chicago"
  },
  "8WI7": {
      "icao": "8WI7",
      "iata": "",
      "name": "Dunbar Airport",
      "city": "Dunbar",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1196,
      "lat": 45.6511001587,
      "lon": -88.1820983887,
      "tz": "America\/Chicago"
  },
  "8WI8": {
      "icao": "8WI8",
      "iata": "",
      "name": "Stupek Farms Airport",
      "city": "Fennimore",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1210,
      "lat": 42.9766998291,
      "lon": -90.6482009888,
      "tz": "America\/Chicago"
  },
  "8WN8": {
      "icao": "8WN8",
      "iata": "",
      "name": "Mave's Lakeview Road Airport",
      "city": "Ellison Bay",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 700,
      "lat": 45.2350997925,
      "lon": -87.0674972534,
      "tz": "America\/Chicago"
  },
  "8XS0": {
      "icao": "8XS0",
      "iata": "",
      "name": "Tivydale Ranch Airport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 1910,
      "lat": 30.2544002533,
      "lon": -99.0997009277,
      "tz": "America\/Chicago"
  },
  "8XS1": {
      "icao": "8XS1",
      "iata": "",
      "name": "Harold Freeman Farm Airport",
      "city": "Katy",
      "state": "Texas",
      "country": "US",
      "elevation": 160,
      "lat": 29.8826999664,
      "lon": -95.8118972778,
      "tz": "America\/Chicago"
  },
  "8XS2": {
      "icao": "8XS2",
      "iata": "",
      "name": "Rachal Airport",
      "city": "Laredo",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 27.9297008514,
      "lon": -99.8511962891,
      "tz": "America\/Chicago"
  },
  "8XS3": {
      "icao": "8XS3",
      "iata": "",
      "name": "Pegasus Place Airstrip",
      "city": "Leander",
      "state": "Texas",
      "country": "US",
      "elevation": 965,
      "lat": 30.5669002533,
      "lon": -97.829498291,
      "tz": "America\/Chicago"
  },
  "8XS6": {
      "icao": "8XS6",
      "iata": "",
      "name": "Mc Manus Field",
      "city": "Tioga",
      "state": "Texas",
      "country": "US",
      "elevation": 695,
      "lat": 33.4522018433,
      "lon": -96.8328018188,
      "tz": "America\/Chicago"
  },
  "8XS8": {
      "icao": "8XS8",
      "iata": "",
      "name": "Reese Airpark",
      "city": "Lubbock",
      "state": "Texas",
      "country": "US",
      "elevation": 3338,
      "lat": 33.5903015137,
      "lon": -102.0370025635,
      "tz": "America\/Chicago"
  },
  "8XS9": {
      "icao": "8XS9",
      "iata": "",
      "name": "Rust Field",
      "city": "Waring",
      "state": "Texas",
      "country": "US",
      "elevation": 1331,
      "lat": 29.9571990967,
      "lon": -98.7906036377,
      "tz": "America\/Chicago"
  },
  "90AR": {
      "icao": "90AR",
      "iata": "",
      "name": "Reedville Airport",
      "city": "Dumas",
      "state": "Arkansas",
      "country": "US",
      "elevation": 162,
      "lat": 33.9250984192,
      "lon": -91.5111999512,
      "tz": "America\/Chicago"
  },
  "90CA": {
      "icao": "90CA",
      "iata": "",
      "name": "Fowler's Airport",
      "city": "Linden",
      "state": "California",
      "country": "US",
      "elevation": 77,
      "lat": 38.0035018921,
      "lon": -121.1190032959,
      "tz": "America\/Los_Angeles"
  },
  "90CL": {
      "icao": "90CL",
      "iata": "",
      "name": "Diamond M Ranch Airport",
      "city": "Elk Creek",
      "state": "California",
      "country": "US",
      "elevation": 1296,
      "lat": 39.572101593,
      "lon": -122.6090011597,
      "tz": "America\/Los_Angeles"
  },
  "90FD": {
      "icao": "90FD",
      "iata": "",
      "name": "Blue Head Ranch Airport",
      "city": "Lake Placid",
      "state": "Florida",
      "country": "US",
      "elevation": 77,
      "lat": 27.1625003815,
      "lon": -81.5429000854,
      "tz": "America\/New_York"
  },
  "90FL": {
      "icao": "90FL",
      "iata": "",
      "name": "Paxton Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 66,
      "lat": 27.3612003326,
      "lon": -80.7609024048,
      "tz": "America\/New_York"
  },
  "90IA": {
      "icao": "90IA",
      "iata": "",
      "name": "Missouri Valley Airport",
      "city": "Missouri Valley",
      "state": "Iowa",
      "country": "US",
      "elevation": 1000,
      "lat": 41.5402984619,
      "lon": -95.887802124,
      "tz": "America\/Chicago"
  },
  "90IL": {
      "icao": "90IL",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Momence",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 41.1981010437,
      "lon": -87.5588989258,
      "tz": "America\/Chicago"
  },
  "90IN": {
      "icao": "90IN",
      "iata": "",
      "name": "Mc Daniel's Field",
      "city": "Martinsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 600,
      "lat": 39.4089012146,
      "lon": -86.4441986084,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "90KS": {
      "icao": "90KS",
      "iata": "",
      "name": "Sunset Strip Airpark",
      "city": "Topeka",
      "state": "Kansas",
      "country": "US",
      "elevation": 1075,
      "lat": 38.9824981689,
      "lon": -95.5950012207,
      "tz": "America\/Chicago"
  },
  "90KY": {
      "icao": "90KY",
      "iata": "",
      "name": "Williams Airport",
      "city": "Hodgenville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 760,
      "lat": 37.5825996399,
      "lon": -85.7332992554,
      "tz": "America\/New_York"
  },
  "90LA": {
      "icao": "90LA",
      "iata": "",
      "name": "Sharp Field",
      "city": "Minden",
      "state": "Louisiana",
      "country": "US",
      "elevation": 165,
      "lat": 32.613899231,
      "lon": -93.323600769,
      "tz": "America\/Chicago"
  },
  "90ME": {
      "icao": "90ME",
      "iata": "",
      "name": "Zinck Airport",
      "city": "Rumford",
      "state": "Maine",
      "country": "US",
      "elevation": 620,
      "lat": 44.523056,
      "lon": -70.545556,
      "tz": "America\/New_York"
  },
  "90MN": {
      "icao": "90MN",
      "iata": "",
      "name": "Schmidt Private Airport",
      "city": "Vesta",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1060,
      "lat": 44.5032997131,
      "lon": -95.4539031982,
      "tz": "America\/Chicago"
  },
  "90NY": {
      "icao": "90NY",
      "iata": "",
      "name": "Hopewell Airpark",
      "city": "Canandaigua",
      "state": "New York",
      "country": "US",
      "elevation": 730,
      "lat": 42.919508,
      "lon": -77.242778,
      "tz": "America\/New_York"
  },
  "90OI": {
      "icao": "90OI",
      "iata": "",
      "name": "Joe Cimprich Airport",
      "city": "Camden",
      "state": "Ohio",
      "country": "US",
      "elevation": 1010,
      "lat": 39.6041984558,
      "lon": -84.6350021362,
      "tz": "America\/New_York"
  },
  "90OK": {
      "icao": "90OK",
      "iata": "",
      "name": "Stewart Farms Airport",
      "city": "Aline",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1460,
      "lat": 36.5005989075,
      "lon": -98.5019989014,
      "tz": "America\/Chicago"
  },
  "90PA": {
      "icao": "90PA",
      "iata": "",
      "name": "Adams Airport",
      "city": "Coneville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1780,
      "lat": 41.8791999817,
      "lon": -78.1149978638,
      "tz": "America\/New_York"
  },
  "90PN": {
      "icao": "90PN",
      "iata": "",
      "name": "Baney's Airport",
      "city": "Dover",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 470,
      "lat": 40.0172996521,
      "lon": -76.8197021484,
      "tz": "America\/New_York"
  },
  "90TE": {
      "icao": "90TE",
      "iata": "",
      "name": "The Homestead Airport",
      "city": "Corsicana",
      "state": "Texas",
      "country": "US",
      "elevation": 398,
      "lat": 32.1957015991,
      "lon": -96.4385986328,
      "tz": "America\/Chicago"
  },
  "90TS": {
      "icao": "90TS",
      "iata": "",
      "name": "Jordan Ranch Airport",
      "city": "Bedias",
      "state": "Texas",
      "country": "US",
      "elevation": 265,
      "lat": 30.7791996002,
      "lon": -95.797203064,
      "tz": "America\/Chicago"
  },
  "90TX": {
      "icao": "90TX",
      "iata": "",
      "name": "Callaghan Ranch Airport",
      "city": "Callaghan",
      "state": "Texas",
      "country": "US",
      "elevation": 635,
      "lat": 27.8794002533,
      "lon": -99.396697998,
      "tz": "America\/Chicago"
  },
  "90VA": {
      "icao": "90VA",
      "iata": "",
      "name": "Hen & Bacon Airport",
      "city": "Orange",
      "state": "Virginia",
      "country": "US",
      "elevation": 430,
      "lat": 38.2181015015,
      "lon": -78.2118988037,
      "tz": "America\/New_York"
  },
  "90WA": {
      "icao": "90WA",
      "iata": "",
      "name": "Waldronaire Airport",
      "city": "East Sound",
      "state": "Washington",
      "country": "US",
      "elevation": 140,
      "lat": 48.7117996216,
      "lon": -123.0179977417,
      "tz": "America\/Los_Angeles"
  },
  "90WI": {
      "icao": "90WI",
      "iata": "",
      "name": "Heritage Acres Airport",
      "city": "Laona",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1608,
      "lat": 45.5750007629,
      "lon": -88.7083969116,
      "tz": "America\/Chicago"
  },
  "91CL": {
      "icao": "91CL",
      "iata": "",
      "name": "Sacatar Meadows Airport",
      "city": "Kennedy Meadows",
      "state": "California",
      "country": "US",
      "elevation": 6427,
      "lat": 35.9898986816,
      "lon": -118.0800018311,
      "tz": "America\/Los_Angeles"
  },
  "91GA": {
      "icao": "91GA",
      "iata": "",
      "name": "Dbaks Airport",
      "city": "Moreland",
      "state": "Georgia",
      "country": "US",
      "elevation": 910,
      "lat": 33.273601532,
      "lon": -84.793296814,
      "tz": "America\/New_York"
  },
  "91IN": {
      "icao": "91IN",
      "iata": "",
      "name": "Strietelmeier Flying Field",
      "city": "Columbus",
      "state": "Indiana",
      "country": "US",
      "elevation": 625,
      "lat": 39.2080993652,
      "lon": -85.9747009277,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "91IS": {
      "icao": "91IS",
      "iata": "",
      "name": "Stanton Airport",
      "city": "Riverton",
      "state": "Illinois",
      "country": "US",
      "elevation": 580,
      "lat": 39.8236999512,
      "lon": -89.5039978027,
      "tz": "America\/Chicago"
  },
  "91KS": {
      "icao": "91KS",
      "iata": "",
      "name": "St. Joseph'S Landing",
      "city": "St. Mary'S",
      "state": "Kansas",
      "country": "US",
      "elevation": 1136,
      "lat": 39.243803,
      "lon": -96.033039,
      "tz": "America\/Chicago"
  },
  "91LA": {
      "icao": "91LA",
      "iata": "",
      "name": "Koch Airport",
      "city": "Eunice",
      "state": "Louisiana",
      "country": "US",
      "elevation": 51,
      "lat": 30.5345993042,
      "lon": -92.367401123,
      "tz": "America\/Chicago"
  },
  "91LS": {
      "icao": "91LS",
      "iata": "",
      "name": "Duclos RLA Restricted Landing Area",
      "city": "Red Bud",
      "state": "Illinois",
      "country": "US",
      "elevation": 520,
      "lat": 38.2089004517,
      "lon": -90.0372009277,
      "tz": "America\/Chicago"
  },
  "91NY": {
      "icao": "91NY",
      "iata": "",
      "name": "Manitou Field",
      "city": "Spencerport",
      "state": "New York",
      "country": "US",
      "elevation": 470,
      "lat": 43.1955986023,
      "lon": -77.7481002808,
      "tz": "America\/New_York"
  },
  "91OH": {
      "icao": "91OH",
      "iata": "",
      "name": "Stine Field",
      "city": "Wooster",
      "state": "Ohio",
      "country": "US",
      "elevation": 1172,
      "lat": 40.7333984375,
      "lon": -81.8928985596,
      "tz": "America\/New_York"
  },
  "91OI": {
      "icao": "91OI",
      "iata": "",
      "name": "Cross Airport",
      "city": "Canton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1125,
      "lat": 40.7773017883,
      "lon": -81.4587020874,
      "tz": "America\/New_York"
  },
  "91OK": {
      "icao": "91OK",
      "iata": "",
      "name": "Stewart Airport",
      "city": "Altus",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1365,
      "lat": 34.641998291,
      "lon": -99.3712005615,
      "tz": "America\/Chicago"
  },
  "91OR": {
      "icao": "91OR",
      "iata": "",
      "name": "Abba's Airport",
      "city": "Sheridan",
      "state": "Oregon",
      "country": "US",
      "elevation": 215,
      "lat": 45.1026000977,
      "lon": -123.418998718,
      "tz": "America\/Los_Angeles"
  },
  "91TA": {
      "icao": "91TA",
      "iata": "",
      "name": "Rhines Roost Airport",
      "city": "Canton",
      "state": "Texas",
      "country": "US",
      "elevation": 540,
      "lat": 32.4459991455,
      "lon": -95.9188995361,
      "tz": "America\/Chicago"
  },
  "91TE": {
      "icao": "91TE",
      "iata": "",
      "name": "Britts Crosswind Airport",
      "city": "Liberty Hill",
      "state": "Texas",
      "country": "US",
      "elevation": 1000,
      "lat": 30.7247009277,
      "lon": -97.8619995117,
      "tz": "America\/Chicago"
  },
  "91TS": {
      "icao": "91TS",
      "iata": "",
      "name": "Songbird Ranch Airport",
      "city": "Rosharon",
      "state": "Texas",
      "country": "US",
      "elevation": 34,
      "lat": 29.3686008453,
      "lon": -95.3398971558,
      "tz": "America\/Chicago"
  },
  "91TX": {
      "icao": "91TX",
      "iata": "",
      "name": "Paisano Ranch Airport",
      "city": "Calliham",
      "state": "Texas",
      "country": "US",
      "elevation": 235,
      "lat": 28.396900177,
      "lon": -98.3628005981,
      "tz": "America\/Chicago"
  },
  "91VA": {
      "icao": "91VA",
      "iata": "",
      "name": "Miller Airport",
      "city": "Bedford",
      "state": "Virginia",
      "country": "US",
      "elevation": 760,
      "lat": 37.3275985718,
      "lon": -79.404800415,
      "tz": "America\/New_York"
  },
  "91WA": {
      "icao": "91WA",
      "iata": "",
      "name": "Christensen Bros Wahluke Strip",
      "city": "Mattawa",
      "state": "Washington",
      "country": "US",
      "elevation": 840,
      "lat": 46.7081985474,
      "lon": -119.8010025024,
      "tz": "America\/Los_Angeles"
  },
  "91WI": {
      "icao": "91WI",
      "iata": "",
      "name": "Gottschalk Field",
      "city": "Nekoosa",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 976,
      "lat": 44.2938995361,
      "lon": -90.0320968628,
      "tz": "America\/Chicago"
  },
  "92CA": {
      "icao": "92CA",
      "iata": "",
      "name": "Westlake Farms Airport",
      "city": "Stratford",
      "state": "California",
      "country": "US",
      "elevation": 192,
      "lat": 36.1208000183,
      "lon": -119.8880004883,
      "tz": "America\/Los_Angeles"
  },
  "92CL": {
      "icao": "92CL",
      "iata": "",
      "name": "Moronis Airport",
      "city": "Meridian",
      "state": "California",
      "country": "US",
      "elevation": 35,
      "lat": 39.1031990051,
      "lon": -121.8499984741,
      "tz": "America\/Los_Angeles"
  },
  "92CO": {
      "icao": "92CO",
      "iata": "",
      "name": "Lake Creek Ranch Airport",
      "city": "Hillside",
      "state": "Colorado",
      "country": "US",
      "elevation": 7200,
      "lat": 38.2916984558,
      "lon": -105.611000061,
      "tz": "America\/Denver"
  },
  "92FL": {
      "icao": "92FL",
      "iata": "",
      "name": "Carlstrom Field",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 27.1205997467,
      "lon": -81.8498001099,
      "tz": "America\/New_York"
  },
  "92GE": {
      "icao": "92GE",
      "iata": "",
      "name": "Midville International Airport",
      "city": "Midville",
      "state": "Georgia",
      "country": "US",
      "elevation": 265,
      "lat": 32.8452987671,
      "lon": -82.2707977295,
      "tz": "America\/New_York"
  },
  "92IL": {
      "icao": "92IL",
      "iata": "",
      "name": "Hunter Raffety Elevators Inc Airport",
      "city": "Cairo",
      "state": "Illinois",
      "country": "US",
      "elevation": 323,
      "lat": 37.038898468,
      "lon": -89.3208999634,
      "tz": "America\/Chicago"
  },
  "92IN": {
      "icao": "92IN",
      "iata": "",
      "name": "H R Weisser Airport",
      "city": "Milford",
      "state": "Indiana",
      "country": "US",
      "elevation": 832,
      "lat": 41.4234008789,
      "lon": -85.8475036621,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "92IS": {
      "icao": "92IS",
      "iata": "",
      "name": "Taft Airport",
      "city": "Rochester",
      "state": "Illinois",
      "country": "US",
      "elevation": 586,
      "lat": 39.7420005798,
      "lon": -89.4784011841,
      "tz": "America\/Chicago"
  },
  "92KY": {
      "icao": "92KY",
      "iata": "",
      "name": "Timmons Field",
      "city": "Pewee Valley",
      "state": "Kentucky",
      "country": "US",
      "elevation": 690,
      "lat": 38.284198761,
      "lon": -85.4726028442,
      "tz": "America\/Kentucky\/Louisville"
  },
  "92MI": {
      "icao": "92MI",
      "iata": "",
      "name": "Zischke Airport",
      "city": "Dewitt",
      "state": "Michigan",
      "country": "US",
      "elevation": 863,
      "lat": 42.861667,
      "lon": -84.619167,
      "tz": "America\/Detroit"
  },
  "92MN": {
      "icao": "92MN",
      "iata": "",
      "name": "Brutlag Farms Airport",
      "city": "Wendell",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1127,
      "lat": 46.0144004822,
      "lon": -96.1016998291,
      "tz": "America\/Chicago"
  },
  "92NJ": {
      "icao": "92NJ",
      "iata": "",
      "name": "Fly-N-D Landing Strip",
      "city": "Kingwood Township",
      "state": "New Jersey",
      "country": "US",
      "elevation": 530,
      "lat": 40.4683990479,
      "lon": -75.0024032593,
      "tz": "America\/New_York"
  },
  "92NY": {
      "icao": "92NY",
      "iata": "",
      "name": "Bloecher Farm Airport",
      "city": "Strykersville",
      "state": "New York",
      "country": "US",
      "elevation": 1430,
      "lat": 42.7519989014,
      "lon": -78.4614028931,
      "tz": "America\/New_York"
  },
  "92OK": {
      "icao": "92OK",
      "iata": "",
      "name": "Flying W Ranch Airport",
      "city": "Atoka",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 618,
      "lat": 34.382900238,
      "lon": -96.2919006348,
      "tz": "America\/Chicago"
  },
  "92PA": {
      "icao": "92PA",
      "iata": "",
      "name": "Hawkins Field",
      "city": "Cooperstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1150,
      "lat": 40.7042007446,
      "lon": -79.952003479,
      "tz": "America\/New_York"
  },
  "92TA": {
      "icao": "92TA",
      "iata": "",
      "name": "Drennan Farm Airport",
      "city": "Los Fresnos",
      "state": "Texas",
      "country": "US",
      "elevation": 27,
      "lat": 26.1212005615,
      "lon": -97.4280014038,
      "tz": "America\/Chicago"
  },
  "92TE": {
      "icao": "92TE",
      "iata": "",
      "name": "Chaney San Francisco Ranch Airport",
      "city": "Marathon",
      "state": "Texas",
      "country": "US",
      "elevation": 3250,
      "lat": 29.9668998718,
      "lon": -102.9339981079,
      "tz": "America\/Chicago"
  },
  "92VA": {
      "icao": "92VA",
      "iata": "",
      "name": "New Quarter Farm Airport",
      "city": "Gloucester",
      "state": "Virginia",
      "country": "US",
      "elevation": 38,
      "lat": 37.3446998596,
      "lon": -76.5643997192,
      "tz": "America\/New_York"
  },
  "92WI": {
      "icao": "92WI",
      "iata": "",
      "name": "Knight Aire Airport",
      "city": "Merrill",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1605,
      "lat": 45.2961006165,
      "lon": -89.6410980225,
      "tz": "America\/Chicago"
  },
  "92XS": {
      "icao": "92XS",
      "iata": "",
      "name": "T.R. Funk Inc Airport",
      "city": "Raymondville",
      "state": "Texas",
      "country": "US",
      "elevation": 24,
      "lat": 26.4790000916,
      "lon": -97.6872024536,
      "tz": "America\/Chicago"
  },
  "93AK": {
      "icao": "93AK",
      "iata": "",
      "name": "The Queens Airport",
      "city": "Queens",
      "state": "Alaska",
      "country": "US",
      "elevation": 25,
      "lat": 58.8720016479,
      "lon": -158.4720001221,
      "tz": "America\/Anchorage"
  },
  "93AR": {
      "icao": "93AR",
      "iata": "",
      "name": "Ohlendorf Airport",
      "city": "Osceola",
      "state": "Arkansas",
      "country": "US",
      "elevation": 232,
      "lat": 35.6208992004,
      "lon": -89.9861984253,
      "tz": "America\/Chicago"
  },
  "93CO": {
      "icao": "93CO",
      "iata": "",
      "name": "Antelope Airpark",
      "city": "Lake George",
      "state": "Colorado",
      "country": "US",
      "elevation": 8700,
      "lat": 39.0233192444,
      "lon": -105.614997864,
      "tz": "America\/Denver"
  },
  "93FD": {
      "icao": "93FD",
      "iata": "",
      "name": "Chumuckla 20-20 Airport",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 205,
      "lat": 30.7546005249,
      "lon": -87.1774978638,
      "tz": "America\/Chicago"
  },
  "93FL": {
      "icao": "93FL",
      "iata": "",
      "name": "Pine Lakes Farm Airport",
      "city": "Deland",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 28.9447002411,
      "lon": -81.4080963135,
      "tz": "America\/New_York"
  },
  "93IA": {
      "icao": "93IA",
      "iata": "",
      "name": "Lund Airport",
      "city": "Jewell",
      "state": "Iowa",
      "country": "US",
      "elevation": 1063,
      "lat": 42.2602996826,
      "lon": -93.6496963501,
      "tz": "America\/Chicago"
  },
  "93IL": {
      "icao": "93IL",
      "iata": "",
      "name": "Bauer Airport",
      "city": "Lindenwood",
      "state": "Illinois",
      "country": "US",
      "elevation": 780,
      "lat": 42.0675010681,
      "lon": -89.0231018066,
      "tz": "America\/Chicago"
  },
  "93IN": {
      "icao": "93IN",
      "iata": "",
      "name": "Foltz Farm Airport",
      "city": "Shelbyville",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 39.5886001587,
      "lon": -85.7360992432,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "93KY": {
      "icao": "93KY",
      "iata": "",
      "name": "Baggett Farms Airport",
      "city": "Sacramento",
      "state": "Kentucky",
      "country": "US",
      "elevation": 405,
      "lat": 37.4707984924,
      "lon": -87.2533035278,
      "tz": "America\/Chicago"
  },
  "93LL": {
      "icao": "93LL",
      "iata": "",
      "name": "Hemmingsen Airport",
      "city": "Sandwich",
      "state": "Illinois",
      "country": "US",
      "elevation": 640,
      "lat": 41.5945014954,
      "lon": -88.6055984497,
      "tz": "America\/Chicago"
  },
  "93MD": {
      "icao": "93MD",
      "iata": "",
      "name": "Knollwood Farm Airport",
      "city": "Elkton",
      "state": "Maryland",
      "country": "US",
      "elevation": 100,
      "lat": 39.561833,
      "lon": -75.834833,
      "tz": "America\/New_York"
  },
  "93MN": {
      "icao": "93MN",
      "iata": "",
      "name": "Hoppe Sky Ranch Airport",
      "city": "Mankato",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1010,
      "lat": 44.0321006775,
      "lon": -93.9484024048,
      "tz": "America\/Chicago"
  },
  "93MO": {
      "icao": "93MO",
      "iata": "",
      "name": "Kathy's Patch Airport",
      "city": "Prosperity",
      "state": "Missouri",
      "country": "US",
      "elevation": 1030,
      "lat": 37.1088981628,
      "lon": -94.4055023193,
      "tz": "America\/Chicago"
  },
  "93MS": {
      "icao": "93MS",
      "iata": "",
      "name": "Shelby Air Service Airport",
      "city": "Shelby",
      "state": "Mississippi",
      "country": "US",
      "elevation": 154,
      "lat": 33.9715995789,
      "lon": -90.7682037354,
      "tz": "America\/Chicago"
  },
  "93MT": {
      "icao": "93MT",
      "iata": "",
      "name": "Masonry Field",
      "city": "Bigfork",
      "state": "Montana",
      "country": "US",
      "elevation": 3049,
      "lat": 48.100833,
      "lon": -114.089167,
      "tz": "America\/Denver"
  },
  "93OH": {
      "icao": "93OH",
      "iata": "",
      "name": "Gay Airport",
      "city": "Atwater",
      "state": "Ohio",
      "country": "US",
      "elevation": 1200,
      "lat": 41.0444984436,
      "lon": -81.1858978271,
      "tz": "America\/New_York"
  },
  "93OK": {
      "icao": "93OK",
      "iata": "",
      "name": "Jantzen Airport",
      "city": "Coweta",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 670,
      "lat": 35.9345016479,
      "lon": -95.5914001465,
      "tz": "America\/Chicago"
  },
  "93PA": {
      "icao": "93PA",
      "iata": "",
      "name": "Maple Cave Park Airport",
      "city": "Cooperstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1180,
      "lat": 41.5033988953,
      "lon": -79.881401062,
      "tz": "America\/New_York"
  },
  "93TE": {
      "icao": "93TE",
      "iata": "",
      "name": "Mayhew Ranch Nr 1 Airport",
      "city": "Marathon",
      "state": "Texas",
      "country": "US",
      "elevation": 2783,
      "lat": 29.8096008301,
      "lon": -102.9079971313,
      "tz": "America\/Chicago"
  },
  "93TS": {
      "icao": "93TS",
      "iata": "",
      "name": "Longbird Airport",
      "city": "Sealy",
      "state": "Texas",
      "country": "US",
      "elevation": 150,
      "lat": 29.7483005524,
      "lon": -96.1147003174,
      "tz": "America\/Chicago"
  },
  "93TX": {
      "icao": "93TX",
      "iata": "",
      "name": "Putz Aero Inc Airport",
      "city": "Calvert",
      "state": "Texas",
      "country": "US",
      "elevation": 279,
      "lat": 30.914899826,
      "lon": -96.6855010986,
      "tz": "America\/Chicago"
  },
  "93VA": {
      "icao": "93VA",
      "iata": "",
      "name": "Timberdoodle Airport",
      "city": "Amherst",
      "state": "Virginia",
      "country": "US",
      "elevation": 810,
      "lat": 37.5363006592,
      "lon": -79.023399353,
      "tz": "America\/New_York"
  },
  "93WA": {
      "icao": "93WA",
      "iata": "",
      "name": "Harris Airport",
      "city": "Tyler",
      "state": "Washington",
      "country": "US",
      "elevation": 2375,
      "lat": 47.4478988647,
      "lon": -117.8059997559,
      "tz": "America\/Los_Angeles"
  },
  "93WI": {
      "icao": "93WI",
      "iata": "",
      "name": "Arrowhead Springs Airport",
      "city": "Richfield",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 980,
      "lat": 43.2536010742,
      "lon": -88.2095031738,
      "tz": "America\/Chicago"
  },
  "93XS": {
      "icao": "93XS",
      "iata": "",
      "name": "Joseph Ross Scherdin Airport",
      "city": "Richwood",
      "state": "Texas",
      "country": "US",
      "elevation": 10,
      "lat": 29.0811004639,
      "lon": -95.3826980591,
      "tz": "America\/Chicago"
  },
  "94FL": {
      "icao": "94FL",
      "iata": "",
      "name": "Pine Shadows Airpark",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 26.73279953,
      "lon": -81.8973007202,
      "tz": "America\/New_York"
  },
  "94GA": {
      "icao": "94GA",
      "iata": "",
      "name": "Snow Hill Airstrip",
      "city": "Unadilla",
      "state": "Georgia",
      "country": "US",
      "elevation": 465,
      "lat": 32.2629013062,
      "lon": -83.7871017456,
      "tz": "America\/New_York"
  },
  "94IL": {
      "icao": "94IL",
      "iata": "",
      "name": "Lutz Airport",
      "city": "Mulberry Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 526,
      "lat": 38.917301178,
      "lon": -89.2256011963,
      "tz": "America\/Chicago"
  },
  "94IS": {
      "icao": "94IS",
      "iata": "",
      "name": "Adams Private Airport",
      "city": "Royalton",
      "state": "Illinois",
      "country": "US",
      "elevation": 410,
      "lat": 37.9028015137,
      "lon": -89.1376037598,
      "tz": "America\/Chicago"
  },
  "94KS": {
      "icao": "94KS",
      "iata": "",
      "name": "Smith Field",
      "city": "Macksville",
      "state": "Kansas",
      "country": "US",
      "elevation": 2023,
      "lat": 37.9749984741,
      "lon": -98.9656982422,
      "tz": "America\/Chicago"
  },
  "94KY": {
      "icao": "94KY",
      "iata": "",
      "name": "Woosley Airport",
      "city": "Rumsey",
      "state": "Kentucky",
      "country": "US",
      "elevation": 400,
      "lat": 37.5005989075,
      "lon": -87.3293991089,
      "tz": "America\/Chicago"
  },
  "94MN": {
      "icao": "94MN",
      "iata": "",
      "name": "Ag Spray Inc Airport",
      "city": "Barnesville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 960,
      "lat": 46.5765991211,
      "lon": -96.5120010376,
      "tz": "America\/Chicago"
  },
  "94MO": {
      "icao": "94MO",
      "iata": "",
      "name": "Ray's Roost Airport",
      "city": "West Plains",
      "state": "Missouri",
      "country": "US",
      "elevation": 1170,
      "lat": 36.8053016663,
      "lon": -91.8813018799,
      "tz": "America\/Chicago"
  },
  "94NC": {
      "icao": "94NC",
      "iata": "",
      "name": "Viking Airport",
      "city": "Raeford",
      "state": "North Carolina",
      "country": "US",
      "elevation": 295,
      "lat": 35.009601593,
      "lon": -79.2600021362,
      "tz": "America\/New_York"
  },
  "94ND": {
      "icao": "94ND",
      "iata": "",
      "name": "Michael Zurcher Farm Strip",
      "city": "Newburg",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1470,
      "lat": 48.6659011841,
      "lon": -100.9179992676,
      "tz": "America\/Chicago"
  },
  "94NY": {
      "icao": "94NY",
      "iata": "",
      "name": "St Bernard Field",
      "city": "Union Springs",
      "state": "New York",
      "country": "US",
      "elevation": 540,
      "lat": 42.8250999451,
      "lon": -76.6912994385,
      "tz": "America\/New_York"
  },
  "94OR": {
      "icao": "94OR",
      "iata": "",
      "name": "Farm Yard Field",
      "city": "Elkton",
      "state": "Oregon",
      "country": "US",
      "elevation": 230,
      "lat": 43.5307006836,
      "lon": -123.5490036011,
      "tz": "America\/Los_Angeles"
  },
  "94TA": {
      "icao": "94TA",
      "iata": "",
      "name": "Reece Field",
      "city": "San Angelo",
      "state": "Texas",
      "country": "US",
      "elevation": 1960,
      "lat": 31.3057003021,
      "lon": -100.4589996338,
      "tz": "America\/Chicago"
  },
  "94TX": {
      "icao": "94TX",
      "iata": "",
      "name": "River Bend Ranch Airport",
      "city": "Brady",
      "state": "Texas",
      "country": "US",
      "elevation": 1500,
      "lat": 31.4377002716,
      "lon": -99.3155975342,
      "tz": "America\/Chicago"
  },
  "94VA": {
      "icao": "94VA",
      "iata": "",
      "name": "Highview Farms Airport",
      "city": "Appomattox",
      "state": "Virginia",
      "country": "US",
      "elevation": 765,
      "lat": 37.2630996704,
      "lon": -78.8494033813,
      "tz": "America\/New_York"
  },
  "94WA": {
      "icao": "94WA",
      "iata": "",
      "name": "Wishkah River Ranch Airport",
      "city": "Aberdeen",
      "state": "Washington",
      "country": "US",
      "elevation": 36,
      "lat": 47.0848007202,
      "lon": -123.7740020752,
      "tz": "America\/Los_Angeles"
  },
  "94XS": {
      "icao": "94XS",
      "iata": "",
      "name": "Heritage Ranch Airport",
      "city": "Richmond",
      "state": "Texas",
      "country": "US",
      "elevation": 103,
      "lat": 29.6543998718,
      "lon": -95.8293991089,
      "tz": "America\/Chicago"
  },
  "95CA": {
      "icao": "95CA",
      "iata": "",
      "name": "Bonel Airport",
      "city": "Whitley Garden",
      "state": "California",
      "country": "US",
      "elevation": 1030,
      "lat": 35.6691017151,
      "lon": -120.5490036011,
      "tz": "America\/Los_Angeles"
  },
  "95CO": {
      "icao": "95CO",
      "iata": "",
      "name": "Mann Ranch Airport",
      "city": "Rye",
      "state": "Colorado",
      "country": "US",
      "elevation": 6000,
      "lat": 37.8684997559,
      "lon": -104.8359985352,
      "tz": "America\/Denver"
  },
  "95FD": {
      "icao": "95FD",
      "iata": "",
      "name": "South Point Airport",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 22,
      "lat": 26.7682991028,
      "lon": -81.5389022827,
      "tz": "America\/New_York"
  },
  "95GA": {
      "icao": "95GA",
      "iata": "",
      "name": "Mcintosh Field",
      "city": "Whitesburg",
      "state": "Georgia",
      "country": "US",
      "elevation": 771,
      "lat": 33.4558982849,
      "lon": -84.9430007935,
      "tz": "America\/New_York"
  },
  "95IL": {
      "icao": "95IL",
      "iata": "",
      "name": "Henderson Airport",
      "city": "Rosiclare",
      "state": "Illinois",
      "country": "US",
      "elevation": 485,
      "lat": 37.4861984253,
      "lon": -88.4000015259,
      "tz": "America\/Chicago"
  },
  "95IN": {
      "icao": "95IN",
      "iata": "",
      "name": "Stewart Field",
      "city": "Seymour",
      "state": "Indiana",
      "country": "US",
      "elevation": 550,
      "lat": 38.8875007629,
      "lon": -85.8264007568,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "95IS": {
      "icao": "95IS",
      "iata": "",
      "name": "Jan Knipe Airport",
      "city": "Warsaw",
      "state": "Illinois",
      "country": "US",
      "elevation": 645,
      "lat": 40.3706016541,
      "lon": -91.3889007568,
      "tz": "America\/Chicago"
  },
  "95KY": {
      "icao": "95KY",
      "iata": "",
      "name": "Crawford Ultralightport",
      "city": "Brodhead",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1227,
      "lat": 37.42029953,
      "lon": -84.3692016602,
      "tz": "America\/New_York"
  },
  "95LL": {
      "icao": "95LL",
      "iata": "",
      "name": "Warren Airport",
      "city": "Somonauk",
      "state": "Illinois",
      "country": "US",
      "elevation": 694,
      "lat": 41.6478004456,
      "lon": -88.6943969727,
      "tz": "America\/Chicago"
  },
  "95MI": {
      "icao": "95MI",
      "iata": "",
      "name": "Guy Vander Jagt Airfield",
      "city": "Luther",
      "state": "Michigan",
      "country": "US",
      "elevation": 970,
      "lat": 44.0894012451,
      "lon": -85.7230987549,
      "tz": "America\/Detroit"
  },
  "95MO": {
      "icao": "95MO",
      "iata": "",
      "name": "Schlemmer Airport",
      "city": "Laddonia",
      "state": "Missouri",
      "country": "US",
      "elevation": 780,
      "lat": 39.2594985962,
      "lon": -91.6501998901,
      "tz": "America\/Chicago"
  },
  "95NE": {
      "icao": "95NE",
      "iata": "",
      "name": "W Meeks Ranch Airport",
      "city": "Taylor",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2335,
      "lat": 41.9928016663,
      "lon": -99.4553985596,
      "tz": "America\/Chicago"
  },
  "95NY": {
      "icao": "95NY",
      "iata": "",
      "name": "Fisher Airport",
      "city": "Wales Center\/Cowlesville\/",
      "state": "New York",
      "country": "US",
      "elevation": 1280,
      "lat": 42.7891998291,
      "lon": -78.4764022827,
      "tz": "America\/New_York"
  },
  "95OH": {
      "icao": "95OH",
      "iata": "",
      "name": "Tong Farm Airport",
      "city": "Carey",
      "state": "Ohio",
      "country": "US",
      "elevation": 840,
      "lat": 40.9709014893,
      "lon": -83.4582977295,
      "tz": "America\/New_York"
  },
  "95OK": {
      "icao": "95OK",
      "iata": "",
      "name": "Barcus Field",
      "city": "Claremore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 660,
      "lat": 36.2676010132,
      "lon": -95.6306991577,
      "tz": "America\/Chicago"
  },
  "95OR": {
      "icao": "95OR",
      "iata": "",
      "name": "Flournoy Valley Airport",
      "city": "Roseburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 790,
      "lat": 43.2075996399,
      "lon": -123.5329971313,
      "tz": "America\/Los_Angeles"
  },
  "95TA": {
      "icao": "95TA",
      "iata": "",
      "name": "Thunder Creek Airport",
      "city": "Utopia",
      "state": "Texas",
      "country": "US",
      "elevation": 1500,
      "lat": 29.6348991394,
      "lon": -99.4822998047,
      "tz": "America\/Chicago"
  },
  "95TS": {
      "icao": "95TS",
      "iata": "",
      "name": "Rob Airport",
      "city": "Loving",
      "state": "Texas",
      "country": "US",
      "elevation": 1310,
      "lat": 33.2308006287,
      "lon": -98.4897003174,
      "tz": "America\/Chicago"
  },
  "95TX": {
      "icao": "95TX",
      "iata": "",
      "name": "Chupadera Ranch Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 651,
      "lat": 28.1924991608,
      "lon": -100.0719985962,
      "tz": "America\/Chicago"
  },
  "95VA": {
      "icao": "95VA",
      "iata": "",
      "name": "Buck Hollar Airport",
      "city": "Danville",
      "state": "Virginia",
      "country": "US",
      "elevation": 450,
      "lat": 36.6231994629,
      "lon": -79.3559036255,
      "tz": "America\/New_York"
  },
  "95WA": {
      "icao": "95WA",
      "iata": "",
      "name": "Black Diamond Airport",
      "city": "Black Diamond",
      "state": "Washington",
      "country": "US",
      "elevation": 595,
      "lat": 47.315700531,
      "lon": -122.0100021362,
      "tz": "America\/Los_Angeles"
  },
  "95WI": {
      "icao": "95WI",
      "iata": "",
      "name": "Wisersky Airport",
      "city": "Stoughton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 965,
      "lat": 42.9361000061,
      "lon": -89.129699707,
      "tz": "America\/Chicago"
  },
  "96CO": {
      "icao": "96CO",
      "iata": "",
      "name": "Logan Airport",
      "city": "Bennet",
      "state": "Colorado",
      "country": "US",
      "elevation": 5300,
      "lat": 39.8563995361,
      "lon": -104.3960037231,
      "tz": "America\/Denver"
  },
  "96FD": {
      "icao": "96FD",
      "iata": "",
      "name": "Citrus Hedging Ranch Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 27.3334007263,
      "lon": -80.7361984253,
      "tz": "America\/New_York"
  },
  "96FL": {
      "icao": "96FL",
      "iata": "",
      "name": "Wings Field",
      "city": "Williston",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 29.2425003052,
      "lon": -82.5454025269,
      "tz": "America\/New_York"
  },
  "96GA": {
      "icao": "96GA",
      "iata": "",
      "name": "3-M's Airport",
      "city": "Williamson",
      "state": "Georgia",
      "country": "US",
      "elevation": 890,
      "lat": 33.185798645,
      "lon": -84.3681030273,
      "tz": "America\/New_York"
  },
  "96ID": {
      "icao": "96ID",
      "iata": "",
      "name": "Flying H Ranch Airport",
      "city": "Priest River",
      "state": "Idaho",
      "country": "US",
      "elevation": 2230,
      "lat": 48.2355995178,
      "lon": -116.875,
      "tz": "America\/Los_Angeles"
  },
  "96IL": {
      "icao": "96IL",
      "iata": "",
      "name": "Kramer Airport",
      "city": "Lena",
      "state": "Illinois",
      "country": "US",
      "elevation": 930,
      "lat": 42.375,
      "lon": -89.8460006714,
      "tz": "America\/Chicago"
  },
  "96IS": {
      "icao": "96IS",
      "iata": "",
      "name": "Hildreth Air Park",
      "city": "Sidell",
      "state": "Illinois",
      "country": "US",
      "elevation": 730,
      "lat": 39.875,
      "lon": -87.8450012207,
      "tz": "America\/Chicago"
  },
  "96KY": {
      "icao": "96KY",
      "iata": "",
      "name": "A & L Airport",
      "city": "Calhoun",
      "state": "Kentucky",
      "country": "US",
      "elevation": 465,
      "lat": 37.5522003174,
      "lon": -87.2714004517,
      "tz": "America\/Chicago"
  },
  "96LL": {
      "icao": "96LL",
      "iata": "",
      "name": "Wichman Airport",
      "city": "Stockland",
      "state": "Illinois",
      "country": "US",
      "elevation": 681,
      "lat": 40.6161003113,
      "lon": -87.6141967773,
      "tz": "America\/Chicago"
  },
  "96MN": {
      "icao": "96MN",
      "iata": "",
      "name": "Air-Ag Airport",
      "city": "Glenville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1240,
      "lat": 43.5680007935,
      "lon": -93.2544021606,
      "tz": "America\/Chicago"
  },
  "96MU": {
      "icao": "96MU",
      "iata": "",
      "name": "Green Airfield",
      "city": "St. James",
      "state": "Missouri",
      "country": "US",
      "elevation": 1068,
      "lat": 37.9434013367,
      "lon": -91.6020965576,
      "tz": "America\/Chicago"
  },
  "96OK": {
      "icao": "96OK",
      "iata": "",
      "name": "Mike's Place Airport",
      "city": "Buffalo",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1787,
      "lat": 36.8488998413,
      "lon": -99.456703186,
      "tz": "America\/Chicago"
  },
  "96OR": {
      "icao": "96OR",
      "iata": "",
      "name": "Cable Creek Ranch Airport",
      "city": "Ukiah",
      "state": "Oregon",
      "country": "US",
      "elevation": 4060,
      "lat": 45.1006011963,
      "lon": -118.8170013428,
      "tz": "America\/Los_Angeles"
  },
  "96PA": {
      "icao": "96PA",
      "iata": "",
      "name": "Franklin Center Airport",
      "city": "Cranesville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1225,
      "lat": 41.9208984375,
      "lon": -80.2498016357,
      "tz": "America\/New_York"
  },
  "96TE": {
      "icao": "96TE",
      "iata": "",
      "name": "Yorktown Airport",
      "city": "Yorktown",
      "state": "Texas",
      "country": "US",
      "elevation": 240,
      "lat": 28.9477996826,
      "lon": -97.4599990845,
      "tz": "America\/Chicago"
  },
  "96TS": {
      "icao": "96TS",
      "iata": "",
      "name": "Nuttall Airport",
      "city": "Whitehouse",
      "state": "Texas",
      "country": "US",
      "elevation": 425,
      "lat": 32.2246017456,
      "lon": -95.1958007813,
      "tz": "America\/Chicago"
  },
  "96TX": {
      "icao": "96TX",
      "iata": "",
      "name": "Brown Field",
      "city": "Christoval",
      "state": "Texas",
      "country": "US",
      "elevation": 2125,
      "lat": 31.1653995514,
      "lon": -100.4759979248,
      "tz": "America\/Chicago"
  },
  "96VE": {
      "icao": "96VE",
      "iata": "",
      "name": "Beiter Airport",
      "city": "Ellensburg",
      "state": "Washington",
      "country": "US",
      "elevation": 1910,
      "lat": 46.906667,
      "lon": -120.433611,
      "tz": "America\/Los_Angeles"
  },
  "96WA": {
      "icao": "96WA",
      "iata": "",
      "name": "Jim & Julie's Airport",
      "city": "Everett",
      "state": "Washington",
      "country": "US",
      "elevation": 15,
      "lat": 47.8981018066,
      "lon": -122.15599823,
      "tz": "America\/Los_Angeles"
  },
  "96WY": {
      "icao": "96WY",
      "iata": "",
      "name": "Cedar Creek Ranch Airport",
      "city": "Saratoga",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7760,
      "lat": 41.409198761,
      "lon": -106.5920028687,
      "tz": "America\/Denver"
  },
  "97AK": {
      "icao": "97AK",
      "iata": "",
      "name": "High Ridge Association Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 720,
      "lat": 61.6640014648,
      "lon": -149.2539978027,
      "tz": "America\/Anchorage"
  },
  "97CA": {
      "icao": "97CA",
      "iata": "",
      "name": "Tejon Agricultural Airport",
      "city": "Lebec",
      "state": "California",
      "country": "US",
      "elevation": 1065,
      "lat": 34.9891014099,
      "lon": -118.9150009155,
      "tz": "America\/Los_Angeles"
  },
  "97CL": {
      "icao": "97CL",
      "iata": "",
      "name": "Siller Bros Inc Airport",
      "city": "Oroville",
      "state": "California",
      "country": "US",
      "elevation": 235,
      "lat": 39.4602012634,
      "lon": -121.5810012817,
      "tz": "America\/Los_Angeles"
  },
  "97CO": {
      "icao": "97CO",
      "iata": "",
      "name": "Peakview Airport",
      "city": "Yoder",
      "state": "Colorado",
      "country": "US",
      "elevation": 6370,
      "lat": 38.9249992371,
      "lon": -104.158996582,
      "tz": "America\/Denver"
  },
  "97FL": {
      "icao": "97FL",
      "iata": "",
      "name": "Love Field",
      "city": "Weirsdale",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.9619007111,
      "lon": -81.8915023804,
      "tz": "America\/New_York"
  },
  "97GA": {
      "icao": "97GA",
      "iata": "",
      "name": "B Tree Farms Airport",
      "city": "Gillsville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1000,
      "lat": 34.2681999207,
      "lon": -83.6720962524,
      "tz": "America\/New_York"
  },
  "97IA": {
      "icao": "97IA",
      "iata": "",
      "name": "Volkens Field",
      "city": "Carson",
      "state": "Iowa",
      "country": "US",
      "elevation": 1275,
      "lat": 41.2557983398,
      "lon": -95.4766998291,
      "tz": "America\/Chicago"
  },
  "97KS": {
      "icao": "97KS",
      "iata": "",
      "name": "Clear View Farm Airport",
      "city": "Olathe",
      "state": "Kansas",
      "country": "US",
      "elevation": 1060,
      "lat": 38.8101005554,
      "lon": -94.7556991577,
      "tz": "America\/Chicago"
  },
  "97LA": {
      "icao": "97LA",
      "iata": "",
      "name": "Greene Air Park",
      "city": "Milton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 50,
      "lat": 30.0338001251,
      "lon": -92.0551986694,
      "tz": "America\/Chicago"
  },
  "97MO": {
      "icao": "97MO",
      "iata": "",
      "name": "Gary's Airport",
      "city": "Buffalo",
      "state": "Missouri",
      "country": "US",
      "elevation": 1200,
      "lat": 37.7566986084,
      "lon": -93.0162963867,
      "tz": "America\/Chicago"
  },
  "97MT": {
      "icao": "97MT",
      "iata": "",
      "name": "Cabin Creek Landing Airport",
      "city": "Marion",
      "state": "Montana",
      "country": "US",
      "elevation": 3999,
      "lat": 48.0749015808,
      "lon": -114.6760025024,
      "tz": "America\/Denver"
  },
  "97ND": {
      "icao": "97ND",
      "iata": "",
      "name": "Walser Strip",
      "city": "Park River",
      "state": "North Dakota",
      "country": "US",
      "elevation": 960,
      "lat": 48.4308013916,
      "lon": -97.7080993652,
      "tz": "America\/Chicago"
  },
  "97NY": {
      "icao": "97NY",
      "iata": "",
      "name": "Hunter Mountain Airport",
      "city": "Hunter",
      "state": "New York",
      "country": "US",
      "elevation": 2000,
      "lat": 42.2342987061,
      "lon": -74.2376022339,
      "tz": "America\/New_York"
  },
  "97OG": {
      "icao": "97OG",
      "iata": "",
      "name": "Bybee Field",
      "city": "Nyssa",
      "state": "Oregon",
      "country": "US",
      "elevation": 2150,
      "lat": 43.897828,
      "lon": -116.989964,
      "tz": "America\/Boise"
  },
  "97OR": {
      "icao": "97OR",
      "iata": "",
      "name": "Hi Country No 2 Airport",
      "city": "Prairie City",
      "state": "Oregon",
      "country": "US",
      "elevation": 3840,
      "lat": 44.4351997375,
      "lon": -118.6620025635,
      "tz": "America\/Los_Angeles"
  },
  "97PA": {
      "icao": "97PA",
      "iata": "",
      "name": "Willows Airport",
      "city": "Cranesville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1225,
      "lat": 41.9084014893,
      "lon": -80.258102417,
      "tz": "America\/New_York"
  },
  "97PN": {
      "icao": "97PN",
      "iata": "",
      "name": "Gold Mine Field",
      "city": "Sellersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 40.3750991821,
      "lon": -75.3496017456,
      "tz": "America\/New_York"
  },
  "97TN": {
      "icao": "97TN",
      "iata": "",
      "name": "Kite Field",
      "city": "Jonesborough",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1450,
      "lat": 36.2785568902,
      "lon": -82.5857627392,
      "tz": "America\/New_York"
  },
  "97TS": {
      "icao": "97TS",
      "iata": "",
      "name": "Gdap Air Ranch Airport",
      "city": "Willis",
      "state": "Texas",
      "country": "US",
      "elevation": 300,
      "lat": 30.4801998138,
      "lon": -95.5399017334,
      "tz": "America\/Chicago"
  },
  "97TX": {
      "icao": "97TX",
      "iata": "",
      "name": "San Pedro Ranch Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 28.2835998535,
      "lon": -100.0670013428,
      "tz": "America\/Chicago"
  },
  "97VA": {
      "icao": "97VA",
      "iata": "",
      "name": "Singleton Airport",
      "city": "Warm Springs",
      "state": "Virginia",
      "country": "US",
      "elevation": 2060,
      "lat": 38.2107009888,
      "lon": -79.7134017944,
      "tz": "America\/New_York"
  },
  "97WA": {
      "icao": "97WA",
      "iata": "",
      "name": "Basin City Airfield",
      "city": "Mesa",
      "state": "Washington",
      "country": "US",
      "elevation": 722,
      "lat": 46.5857009888,
      "lon": -119.1539993286,
      "tz": "America\/Los_Angeles"
  },
  "97WI": {
      "icao": "97WI",
      "iata": "",
      "name": "North Fork Airport",
      "city": "Thorp",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1145,
      "lat": 44.9632987976,
      "lon": -90.8602981567,
      "tz": "America\/Chicago"
  },
  "97XS": {
      "icao": "97XS",
      "iata": "",
      "name": "Tilghman Airport",
      "city": "Van Alstyne",
      "state": "Texas",
      "country": "US",
      "elevation": 741,
      "lat": 33.432833,
      "lon": -96.479467,
      "tz": "America\/Chicago"
  },
  "98AK": {
      "icao": "98AK",
      "iata": "",
      "name": "Eastland Airport",
      "city": "Homer",
      "state": "Alaska",
      "country": "US",
      "elevation": 1190,
      "lat": 59.7757987976,
      "lon": -151.1820068359,
      "tz": "America\/Anchorage"
  },
  "98CL": {
      "icao": "98CL",
      "iata": "",
      "name": "Noltas Airport",
      "city": "Willows",
      "state": "California",
      "country": "US",
      "elevation": 145,
      "lat": 39.5789985657,
      "lon": -122.2009963989,
      "tz": "America\/Los_Angeles"
  },
  "98GA": {
      "icao": "98GA",
      "iata": "",
      "name": "Circle T Airport",
      "city": "Indian Springs",
      "state": "Georgia",
      "country": "US",
      "elevation": 545,
      "lat": 33.2056999207,
      "lon": -83.912399292,
      "tz": "America\/New_York"
  },
  "98IN": {
      "icao": "98IN",
      "iata": "",
      "name": "B & V Flying Ranch Airport",
      "city": "Albion",
      "state": "Indiana",
      "country": "US",
      "elevation": 950,
      "lat": 41.363899231,
      "lon": -85.3886032104,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "98KS": {
      "icao": "98KS",
      "iata": "",
      "name": "Rexford Airport",
      "city": "Montezuma",
      "state": "Kansas",
      "country": "US",
      "elevation": 2775,
      "lat": 37.4458999634,
      "lon": -100.4919967651,
      "tz": "America\/Chicago"
  },
  "98LL": {
      "icao": "98LL",
      "iata": "",
      "name": "Braden Farms Airport",
      "city": "Watseka",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 40.7991981506,
      "lon": -87.7835998535,
      "tz": "America\/Chicago"
  },
  "98ME": {
      "icao": "98ME",
      "iata": "",
      "name": "Greaton Airfield",
      "city": "Saco",
      "state": "Maine",
      "country": "US",
      "elevation": 170,
      "lat": 43.575717,
      "lon": -70.518858,
      "tz": "America\/New_York"
  },
  "98MN": {
      "icao": "98MN",
      "iata": "",
      "name": "Up Yonder Airport",
      "city": "Bemidji",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1446,
      "lat": 47.29349899,
      "lon": -94.87490082,
      "tz": "America\/Chicago"
  },
  "98MO": {
      "icao": "98MO",
      "iata": "",
      "name": "Woodliff Airpark",
      "city": "Foristell",
      "state": "Missouri",
      "country": "US",
      "elevation": 750,
      "lat": 38.7862014771,
      "lon": -90.9423980713,
      "tz": "America\/Chicago"
  },
  "98MT": {
      "icao": "98MT",
      "iata": "",
      "name": "Rosemont Airport",
      "city": "Stevensville",
      "state": "Montana",
      "country": "US",
      "elevation": 4172,
      "lat": 46.4427986145,
      "lon": -114.0029983521,
      "tz": "America\/Denver"
  },
  "98ND": {
      "icao": "98ND",
      "iata": "",
      "name": "Sobolik Airport",
      "city": "Pisek",
      "state": "North Dakota",
      "country": "US",
      "elevation": 920,
      "lat": 48.2633018494,
      "lon": -97.6222991943,
      "tz": "America\/Chicago"
  },
  "98NE": {
      "icao": "98NE",
      "iata": "",
      "name": "Munsterman Airport",
      "city": "Glenvil",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1860,
      "lat": 40.4542007446,
      "lon": -98.3044967651,
      "tz": "America\/Chicago"
  },
  "98NM": {
      "icao": "98NM",
      "iata": "",
      "name": "S & S Ranch Airport",
      "city": "Wagon Mound",
      "state": "New Mexico",
      "country": "US",
      "elevation": 6310,
      "lat": 36.0766983032,
      "lon": -104.7180023193,
      "tz": "America\/Denver"
  },
  "98NY": {
      "icao": "98NY",
      "iata": "",
      "name": "Krenzers Airport",
      "city": "West Bloomfield",
      "state": "New York",
      "country": "US",
      "elevation": 1035,
      "lat": 42.8833999634,
      "lon": -77.5330963135,
      "tz": "America\/New_York"
  },
  "98OH": {
      "icao": "98OH",
      "iata": "",
      "name": "Paine's Airport",
      "city": "Geneva",
      "state": "Ohio",
      "country": "US",
      "elevation": 660,
      "lat": 41.8250999451,
      "lon": -80.883102417,
      "tz": "America\/New_York"
  },
  "98OK": {
      "icao": "98OK",
      "iata": "",
      "name": "Candy Lake Estate Airport",
      "city": "Avant",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 685,
      "lat": 36.489200592,
      "lon": -96.0528030396,
      "tz": "America\/Chicago"
  },
  "98OL": {
      "icao": "98OL",
      "iata": "",
      "name": "Copland Airport",
      "city": "Duncan",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1120,
      "lat": 34.5000991821,
      "lon": -97.9947967529,
      "tz": "America\/Chicago"
  },
  "98OR": {
      "icao": "98OR",
      "iata": "",
      "name": "Mach-O Acres Airport",
      "city": "Sheridan",
      "state": "Oregon",
      "country": "US",
      "elevation": 435,
      "lat": 45.1990013123,
      "lon": -123.3700027466,
      "tz": "America\/Los_Angeles"
  },
  "98PA": {
      "icao": "98PA",
      "iata": "",
      "name": "Pleasant Hill Airport",
      "city": "Portersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1180,
      "lat": 40.8944015503,
      "lon": -80.181098938,
      "tz": "America\/New_York"
  },
  "98TE": {
      "icao": "98TE",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Glide",
      "state": "Oregon",
      "country": "US",
      "elevation": 1320,
      "lat": 43.322101593,
      "lon": -123.1770019531,
      "tz": "America\/Los_Angeles"
  },
  "98TN": {
      "icao": "98TN",
      "iata": "",
      "name": "Landing At River'S Edge",
      "city": "Blaine",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1019,
      "lat": 36.1425,
      "lon": -83.604167,
      "tz": "America\/New_York"
  },
  "98TS": {
      "icao": "98TS",
      "iata": "",
      "name": "Trap Travelstead Field",
      "city": "Winona",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 32.4182014465,
      "lon": -95.0001983643,
      "tz": "America\/Chicago"
  },
  "98VA": {
      "icao": "98VA",
      "iata": "",
      "name": "Glascock Airport",
      "city": "Arcola",
      "state": "Virginia",
      "country": "US",
      "elevation": 330,
      "lat": 38.9422988892,
      "lon": -77.5421981812,
      "tz": "America\/New_York"
  },
  "99AK": {
      "icao": "99AK",
      "iata": "",
      "name": "Moore Creek Airport",
      "city": "Moore Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 980,
      "lat": 62.5989990234,
      "lon": -157.1519927979,
      "tz": "America\/Anchorage"
  },
  "99AZ": {
      "icao": "99AZ",
      "iata": "",
      "name": "Eagletail Ranch Airport",
      "city": "Tonopah",
      "state": "Arizona",
      "country": "US",
      "elevation": 1203,
      "lat": 33.3955993652,
      "lon": -113.2229995728,
      "tz": "America\/Phoenix"
  },
  "99CL": {
      "icao": "99CL",
      "iata": "",
      "name": "El Mirage Field Adelanto Airport",
      "city": "El Mirage",
      "state": "California",
      "country": "US",
      "elevation": 2865,
      "lat": 34.625,
      "lon": -117.6050033569,
      "tz": "America\/Los_Angeles"
  },
  "99CO": {
      "icao": "99CO",
      "iata": "",
      "name": "Rock Creek Airport",
      "city": "Pueblo",
      "state": "Colorado",
      "country": "US",
      "elevation": 5660,
      "lat": 38.1363983154,
      "lon": -104.8150024414,
      "tz": "America\/Denver"
  },
  "99FL": {
      "icao": "99FL",
      "iata": "",
      "name": "Madison County Airport",
      "city": "Lee",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.4473991394,
      "lon": -83.3109970093,
      "tz": "America\/New_York"
  },
  "99IA": {
      "icao": "99IA",
      "iata": "",
      "name": "Courtney's Landing Airport",
      "city": "Eddyville",
      "state": "Iowa",
      "country": "US",
      "elevation": 835,
      "lat": 41.2165985107,
      "lon": -92.7779998779,
      "tz": "America\/Chicago"
  },
  "99II": {
      "icao": "99II",
      "iata": "",
      "name": "Cooper Field",
      "city": "Reelsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 39.5835,
      "lon": -86.952527,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "99IL": {
      "icao": "99IL",
      "iata": "",
      "name": "Providence Place Field",
      "city": "Stockton",
      "state": "Illinois",
      "country": "US",
      "elevation": 959,
      "lat": 42.2867012024,
      "lon": -89.9574966431,
      "tz": "America\/Chicago"
  },
  "99IN": {
      "icao": "99IN",
      "iata": "",
      "name": "Bee-Acre Farm Strip",
      "city": "Atlanta",
      "state": "Indiana",
      "country": "US",
      "elevation": 885,
      "lat": 40.2061004639,
      "lon": -86.1005020142,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "99KS": {
      "icao": "99KS",
      "iata": "",
      "name": "Elm Creek Farms Airport",
      "city": "Medicine Lodge",
      "state": "Kansas",
      "country": "US",
      "elevation": 1600,
      "lat": 37.4093017578,
      "lon": -98.6493988037,
      "tz": "America\/Chicago"
  },
  "99KY": {
      "icao": "99KY",
      "iata": "",
      "name": "Gravity Zero Airport",
      "city": "Danville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 860,
      "lat": 37.6556015015,
      "lon": -84.6902999878,
      "tz": "America\/New_York"
  },
  "99LA": {
      "icao": "99LA",
      "iata": "",
      "name": "Lawson Field",
      "city": "Crowley",
      "state": "Louisiana",
      "country": "US",
      "elevation": 25,
      "lat": 30.2894001007,
      "lon": -92.388999939,
      "tz": "America\/Chicago"
  },
  "99MT": {
      "icao": "99MT",
      "iata": "",
      "name": "Fox Field",
      "city": "Hamilton",
      "state": "Montana",
      "country": "US",
      "elevation": 3750,
      "lat": 46.0974998474,
      "lon": -114.1780014038,
      "tz": "America\/Denver"
  },
  "99NY": {
      "icao": "99NY",
      "iata": "",
      "name": "Hibbard's Airport",
      "city": "Wilson",
      "state": "New York",
      "country": "US",
      "elevation": 410,
      "lat": 43.2333984375,
      "lon": -78.7873001099,
      "tz": "America\/New_York"
  },
  "99OR": {
      "icao": "99OR",
      "iata": "",
      "name": "Lazy F Ranch Airport",
      "city": "Wallowa",
      "state": "Oregon",
      "country": "US",
      "elevation": 3710,
      "lat": 45.6506996155,
      "lon": -117.669998169,
      "tz": "America\/Los_Angeles"
  },
  "99PA": {
      "icao": "99PA",
      "iata": "",
      "name": "Nielsen Airport",
      "city": "Tioga",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1040,
      "lat": 41.919796,
      "lon": -77.14136,
      "tz": "America\/New_York"
  },
  "99SC": {
      "icao": "99SC",
      "iata": "",
      "name": "St. Mathews Airport",
      "city": "St. Mathews",
      "state": "South Carolina",
      "country": "US",
      "elevation": 278,
      "lat": 33.689167,
      "lon": -80.688611,
      "tz": "America\/New_York"
  },
  "99TE": {
      "icao": "99TE",
      "iata": "",
      "name": "Pritchard Airport",
      "city": "Bells",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 33.6058006287,
      "lon": -96.4156036377,
      "tz": "America\/Chicago"
  },
  "99TX": {
      "icao": "99TX",
      "iata": "",
      "name": "Briggs Ranch Airport",
      "city": "Catarina",
      "state": "Texas",
      "country": "US",
      "elevation": 520,
      "lat": 28.3003005981,
      "lon": -99.5670013428,
      "tz": "America\/Chicago"
  },
  "99UT": {
      "icao": "99UT",
      "iata": "",
      "name": "Sundog Airport",
      "city": "Jensen",
      "state": "Utah",
      "country": "US",
      "elevation": 4900,
      "lat": 40.3506011963,
      "lon": -109.4010009766,
      "tz": "America\/Denver"
  },
  "99VA": {
      "icao": "99VA",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Scottsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 450,
      "lat": 37.7999992371,
      "lon": -78.4666976929,
      "tz": "America\/New_York"
  },
  "99WI": {
      "icao": "99WI",
      "iata": "",
      "name": "Foscoro Airport",
      "city": "Algoma",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 680,
      "lat": 44.6808013916,
      "lon": -87.3805999756,
      "tz": "America\/Chicago"
  },
  "99WY": {
      "icao": "99WY",
      "iata": "",
      "name": "Xingu Airstrip",
      "city": "Dayton",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4340,
      "lat": 44.8457984924,
      "lon": -107.2770004272,
      "tz": "America\/Denver"
  },
  "99XA": {
      "icao": "99XA",
      "iata": "",
      "name": "Briggs \/ Skotz Airfield",
      "city": "",
      "state": "Texas",
      "country": "US",
      "elevation": 1200,
      "lat": 30.8639760767,
      "lon": -98.0037117004,
      "tz": "America\/Chicago"
  },
  "99XS": {
      "icao": "99XS",
      "iata": "",
      "name": "Sam Little International Airport",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 900,
      "lat": 32.6834983826,
      "lon": -97.8056030273,
      "tz": "America\/Chicago"
  },
  "9AK0": {
      "icao": "9AK0",
      "iata": "",
      "name": "Sports Mans Paradise Airport",
      "city": "Tok",
      "state": "Alaska",
      "country": "US",
      "elevation": 3120,
      "lat": 62.5121002197,
      "lon": -143.2350006104,
      "tz": "America\/Anchorage"
  },
  "9AK2": {
      "icao": "9AK2",
      "iata": "",
      "name": "Kako Airport",
      "city": "Russian Mission",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.89889908,
      "lon": -161.4380035,
      "tz": "America\/Anchorage"
  },
  "9AK3": {
      "icao": "9AK3",
      "iata": "",
      "name": "Nikolai Creek Airport",
      "city": "Tyonek",
      "state": "Alaska",
      "country": "US",
      "elevation": 30,
      "lat": 61.0139007568,
      "lon": -151.449005127,
      "tz": "America\/Anchorage"
  },
  "9AK4": {
      "icao": "9AK4",
      "iata": "",
      "name": "Arctic Angel Airport",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1009,
      "lat": 64.1373977661,
      "lon": -145.8300018311,
      "tz": "America\/Anchorage"
  },
  "9AK5": {
      "icao": "9AK5",
      "iata": "",
      "name": "Sky Harbor Airport",
      "city": "Anchorage",
      "state": "Alaska",
      "country": "US",
      "elevation": 340,
      "lat": 61.1165008545,
      "lon": -149.8190002441,
      "tz": "America\/Anchorage"
  },
  "9AK6": {
      "icao": "9AK6",
      "iata": "",
      "name": "Leisurewood Airstrip Owners Assoc Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 366,
      "lat": 61.6240997314,
      "lon": -149.6479949951,
      "tz": "America\/Anchorage"
  },
  "9AK7": {
      "icao": "9AK7",
      "iata": "",
      "name": "Cubdivision Airport",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 250,
      "lat": 61.5898017883,
      "lon": -149.8170013428,
      "tz": "America\/Anchorage"
  },
  "9AK8": {
      "icao": "9AK8",
      "iata": "",
      "name": "Wolf Track Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 90,
      "lat": 61.6246986389,
      "lon": -149.6820068359,
      "tz": "America\/Anchorage"
  },
  "9AR0": {
      "icao": "9AR0",
      "iata": "",
      "name": "Robertson Airport",
      "city": "Mc Crory",
      "state": "Arkansas",
      "country": "US",
      "elevation": 207,
      "lat": 35.188999176,
      "lon": -91.2064971924,
      "tz": "America\/Chicago"
  },
  "9AR9": {
      "icao": "9AR9",
      "iata": "",
      "name": "Henry Field",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 250,
      "lat": 34.887389,
      "lon": -91.989486,
      "tz": "America\/Chicago"
  },
  "9CA0": {
      "icao": "9CA0",
      "iata": "",
      "name": "Psk Ranch Airport",
      "city": "Tehachapi",
      "state": "California",
      "country": "US",
      "elevation": 3840,
      "lat": 35.113899231,
      "lon": -118.5979995728,
      "tz": "America\/Los_Angeles"
  },
  "9CA6": {
      "icao": "9CA6",
      "iata": "",
      "name": "North Valley Airport",
      "city": "Bishop",
      "state": "Nevada",
      "country": "US",
      "elevation": 4960,
      "lat": 37.8690986633,
      "lon": -118.0950012207,
      "tz": "America\/Los_Angeles"
  },
  "9CA7": {
      "icao": "9CA7",
      "iata": "",
      "name": "Harris River Ranch Airport",
      "city": "Sanger",
      "state": "California",
      "country": "US",
      "elevation": 450,
      "lat": 36.76720047,
      "lon": -119.4359970093,
      "tz": "America\/Los_Angeles"
  },
  "9CL0": {
      "icao": "9CL0",
      "iata": "",
      "name": "Turlock Airpark",
      "city": "Turlock",
      "state": "California",
      "country": "US",
      "elevation": 100,
      "lat": 37.4707984924,
      "lon": -120.84400177,
      "tz": "America\/Los_Angeles"
  },
  "9CL1": {
      "icao": "9CL1",
      "iata": "",
      "name": "Johnson Ranch Airport",
      "city": "Le Grand",
      "state": "California",
      "country": "US",
      "elevation": 240,
      "lat": 37.1911010742,
      "lon": -120.2760009766,
      "tz": "America\/Los_Angeles"
  },
  "9CL2": {
      "icao": "9CL2",
      "iata": "",
      "name": "Christensen Ranch Airport",
      "city": "Hollister",
      "state": "California",
      "country": "US",
      "elevation": 380,
      "lat": 36.8716011047,
      "lon": -121.3369979858,
      "tz": "America\/Los_Angeles"
  },
  "9CL3": {
      "icao": "9CL3",
      "iata": "",
      "name": "Likely Airport",
      "city": "Likely",
      "state": "California",
      "country": "US",
      "elevation": 4420,
      "lat": 41.2402000427,
      "lon": -120.5260009766,
      "tz": "America\/Los_Angeles"
  },
  "9CL4": {
      "icao": "9CL4",
      "iata": "",
      "name": "Hart Mine Airport",
      "city": "Ivanpah",
      "state": "California",
      "country": "US",
      "elevation": 4620,
      "lat": 35.2832984924,
      "lon": -115.1920013428,
      "tz": "America\/Los_Angeles"
  },
  "9CL5": {
      "icao": "9CL5",
      "iata": "",
      "name": "Blair Strip",
      "city": "Hanford",
      "state": "California",
      "country": "US",
      "elevation": 231,
      "lat": 36.2751998901,
      "lon": -119.6409988403,
      "tz": "America\/Los_Angeles"
  },
  "9CL7": {
      "icao": "9CL7",
      "iata": "",
      "name": "Old Aerodrome",
      "city": "Marysville",
      "state": "California",
      "country": "US",
      "elevation": 700,
      "lat": 39.3249015808,
      "lon": -121.3590011597,
      "tz": "America\/Los_Angeles"
  },
  "9CL9": {
      "icao": "9CL9",
      "iata": "",
      "name": "Spezia Airport",
      "city": "Walnut Grove",
      "state": "California",
      "country": "US",
      "elevation": 0,
      "lat": 38.2165985107,
      "lon": -121.533996582,
      "tz": "America\/Los_Angeles"
  },
  "9CO2": {
      "icao": "9CO2",
      "iata": "",
      "name": "Van Slyke Field",
      "city": "Watkins",
      "state": "Colorado",
      "country": "US",
      "elevation": 5400,
      "lat": 39.69609833,
      "lon": -104.4759979,
      "tz": "America\/Denver"
  },
  "9CO3": {
      "icao": "9CO3",
      "iata": "",
      "name": "Hubbard Airport",
      "city": "Gateway",
      "state": "Colorado",
      "country": "US",
      "elevation": 4670,
      "lat": 38.6833000183,
      "lon": -108.9840011597,
      "tz": "America\/Denver"
  },
  "9CO5": {
      "icao": "9CO5",
      "iata": "",
      "name": "Scherler Private Airstrip",
      "city": "Brandon",
      "state": "Colorado",
      "country": "US",
      "elevation": 4126,
      "lat": 38.5766983032,
      "lon": -102.43699646,
      "tz": "America\/Denver"
  },
  "9CO6": {
      "icao": "9CO6",
      "iata": "",
      "name": "D Bar D Airport",
      "city": "Elizabeth",
      "state": "Colorado",
      "country": "US",
      "elevation": 6752,
      "lat": 39.2812995911,
      "lon": -104.5729980469,
      "tz": "America\/Denver"
  },
  "9CO7": {
      "icao": "9CO7",
      "iata": "",
      "name": "Beaugh Airport",
      "city": "Kersey",
      "state": "Colorado",
      "country": "US",
      "elevation": 4770,
      "lat": 40.3405990601,
      "lon": -104.5729980469,
      "tz": "America\/Denver"
  },
  "9CO8": {
      "icao": "9CO8",
      "iata": "",
      "name": "Herrick Airport",
      "city": "Kiowa",
      "state": "Colorado",
      "country": "US",
      "elevation": 6000,
      "lat": 39.2793,
      "lon": -104.34315,
      "tz": "America\/Denver"
  },
  "9CO9": {
      "icao": "9CO9",
      "iata": "",
      "name": "Phylcon Ranch Airport",
      "city": "Mesa",
      "state": "Colorado",
      "country": "US",
      "elevation": 6585,
      "lat": 39.1291007996,
      "lon": -108.141998291,
      "tz": "America\/Denver"
  },
  "9FD2": {
      "icao": "9FD2",
      "iata": "",
      "name": "Blackwater Creek Ultralightport",
      "city": "Plant City",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.1369991302,
      "lon": -82.1464996338,
      "tz": "America\/New_York"
  },
  "9FD5": {
      "icao": "9FD5",
      "iata": "",
      "name": "Thompson's Goinbroke Aero Ranch Airport",
      "city": "Citra",
      "state": "Florida",
      "country": "US",
      "elevation": 85,
      "lat": 29.3866004944,
      "lon": -82.1428985596,
      "tz": "America\/New_York"
  },
  "9FD7": {
      "icao": "9FD7",
      "iata": "",
      "name": "Fort Atkinson Plantation Airpark",
      "city": "Day",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 30.196225,
      "lon": -83.309044,
      "tz": "America\/New_York"
  },
  "9FD9": {
      "icao": "9FD9",
      "iata": "",
      "name": "Buddys Ag Service Airport",
      "city": "Mc Alpin",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 30.1224002838,
      "lon": -82.9679031372,
      "tz": "America\/New_York"
  },
  "9FL0": {
      "icao": "9FL0",
      "iata": "",
      "name": "Suwannee Belle Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 30.0923995972,
      "lon": -83.0854034424,
      "tz": "America\/New_York"
  },
  "9FL1": {
      "icao": "9FL1",
      "iata": "",
      "name": "Flying W Airranch Airport",
      "city": "Bushnell",
      "state": "Florida",
      "country": "US",
      "elevation": 58,
      "lat": 28.683599472,
      "lon": -82.1576004028,
      "tz": "America\/New_York"
  },
  "9FL2": {
      "icao": "9FL2",
      "iata": "",
      "name": "Saranac Farm Airport",
      "city": "Havana",
      "state": "Florida",
      "country": "US",
      "elevation": 230,
      "lat": 30.6525001526,
      "lon": -84.4747009277,
      "tz": "America\/New_York"
  },
  "9FL5": {
      "icao": "9FL5",
      "iata": "",
      "name": "Shady Bend Airport",
      "city": "Bell",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 29.8141002655,
      "lon": -82.9256973267,
      "tz": "America\/New_York"
  },
  "9FL7": {
      "icao": "9FL7",
      "iata": "",
      "name": "Oasis Ranger Station-U.S. Government Airport",
      "city": "Everglades City",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 25.8572998047,
      "lon": -81.0345001221,
      "tz": "America\/New_York"
  },
  "9FL8": {
      "icao": "9FL8",
      "iata": "",
      "name": "Finlayson Farm Airport",
      "city": "Greenville",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.6166000366,
      "lon": -83.6523971558,
      "tz": "America\/New_York"
  },
  "9GA0": {
      "icao": "9GA0",
      "iata": "",
      "name": "Brown Field",
      "city": "Zebulon",
      "state": "Georgia",
      "country": "US",
      "elevation": 864,
      "lat": 33.0570983887,
      "lon": -84.3423995972,
      "tz": "America\/New_York"
  },
  "9GA1": {
      "icao": "9GA1",
      "iata": "",
      "name": "Briar Patch Airport",
      "city": "Clyo",
      "state": "Georgia",
      "country": "US",
      "elevation": 100,
      "lat": 32.4488983154,
      "lon": -81.3368988037,
      "tz": "America\/New_York"
  },
  "9GA2": {
      "icao": "9GA2",
      "iata": "",
      "name": "Berg Park Aerodrome",
      "city": "Midway",
      "state": "Georgia",
      "country": "US",
      "elevation": 15,
      "lat": 31.8025,
      "lon": -81.3975,
      "tz": "America\/New_York"
  },
  "9GA3": {
      "icao": "9GA3",
      "iata": "",
      "name": "Young Airport",
      "city": "Dawson",
      "state": "Georgia",
      "country": "US",
      "elevation": 401,
      "lat": 31.8395996094,
      "lon": -84.4815979004,
      "tz": "America\/New_York"
  },
  "9GA5": {
      "icao": "9GA5",
      "iata": "",
      "name": "Lowell Field",
      "city": "Americus",
      "state": "Georgia",
      "country": "US",
      "elevation": 475,
      "lat": 32.0582008362,
      "lon": -84.2901992798,
      "tz": "America\/New_York"
  },
  "9GA6": {
      "icao": "9GA6",
      "iata": "",
      "name": "Brookline - Meadowmere Airport",
      "city": "Milledgeville",
      "state": "Georgia",
      "country": "US",
      "elevation": 500,
      "lat": 33.0978012085,
      "lon": -83.4047012329,
      "tz": "America\/New_York"
  },
  "9GA8": {
      "icao": "9GA8",
      "iata": "",
      "name": "Chinaberry Ranch Airport",
      "city": "East Dublin",
      "state": "Georgia",
      "country": "US",
      "elevation": 295,
      "lat": 32.6935997009,
      "lon": -82.8989028931,
      "tz": "America\/New_York"
  },
  "9GA9": {
      "icao": "9GA9",
      "iata": "",
      "name": "Deerfield Landing Airport",
      "city": "Eatonton",
      "state": "Georgia",
      "country": "US",
      "elevation": 445,
      "lat": 33.2181015015,
      "lon": -83.317199707,
      "tz": "America\/New_York"
  },
  "9GE7": {
      "icao": "9GE7",
      "iata": "",
      "name": "Neely Farms Airport",
      "city": "Covington",
      "state": "Georgia",
      "country": "US",
      "elevation": 720,
      "lat": 33.531389,
      "lon": -83.791944,
      "tz": "America\/New_York"
  },
  "9II0": {
      "icao": "9II0",
      "iata": "",
      "name": "Shaffer Airport",
      "city": "Merriam",
      "state": "Indiana",
      "country": "US",
      "elevation": 940,
      "lat": 41.2825012207,
      "lon": -85.4385986328,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9II1": {
      "icao": "9II1",
      "iata": "",
      "name": "Short Stop Airport",
      "city": "Hatfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 395,
      "lat": 37.8997001648,
      "lon": -87.2177963257,
      "tz": "America\/Chicago"
  },
  "9II2": {
      "icao": "9II2",
      "iata": "",
      "name": "Nelund Field",
      "city": "Mishawaka",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 41.6875,
      "lon": -86.1549987793,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9II3": {
      "icao": "9II3",
      "iata": "",
      "name": "Dillon Airport",
      "city": "North Liberty",
      "state": "Indiana",
      "country": "US",
      "elevation": 700,
      "lat": 41.5625,
      "lon": -86.4721984863,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9II4": {
      "icao": "9II4",
      "iata": "",
      "name": "Cruzan Field",
      "city": "Arcadia",
      "state": "Indiana",
      "country": "US",
      "elevation": 845,
      "lat": 40.1725006104,
      "lon": -85.9447021484,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9II8": {
      "icao": "9II8",
      "iata": "",
      "name": "Francis Airport",
      "city": "Holton",
      "state": "Indiana",
      "country": "US",
      "elevation": 920,
      "lat": 39.086101532,
      "lon": -85.3711013794,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9IL0": {
      "icao": "9IL0",
      "iata": "",
      "name": "Villiger Restricted Landing Area",
      "city": "Henry",
      "state": "Illinois",
      "country": "US",
      "elevation": 500,
      "lat": 41.0945014954,
      "lon": -89.3861999512,
      "tz": "America\/Chicago"
  },
  "9IL2": {
      "icao": "9IL2",
      "iata": "",
      "name": "Routh Airport",
      "city": "St Joseph",
      "state": "Illinois",
      "country": "US",
      "elevation": 668,
      "lat": 40.0649986267,
      "lon": -88.0263977051,
      "tz": "America\/Chicago"
  },
  "9IL3": {
      "icao": "9IL3",
      "iata": "",
      "name": "Wm Quinton Restricted Landing Area",
      "city": "Heyworth",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 40.2820014954,
      "lon": -89.029296875,
      "tz": "America\/Chicago"
  },
  "9IL4": {
      "icao": "9IL4",
      "iata": "",
      "name": "Thompson Airport",
      "city": "Steward",
      "state": "Illinois",
      "country": "US",
      "elevation": 790,
      "lat": 41.8072013855,
      "lon": -89.0481033325,
      "tz": "America\/Chicago"
  },
  "9IL6": {
      "icao": "9IL6",
      "iata": "",
      "name": "Weishaupt Airport",
      "city": "Mackinaw",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 40.5130996704,
      "lon": -89.3673019409,
      "tz": "America\/Chicago"
  },
  "9IL7": {
      "icao": "9IL7",
      "iata": "",
      "name": "Black Airport",
      "city": "Hillsdale",
      "state": "Illinois",
      "country": "US",
      "elevation": 580,
      "lat": 41.5097999573,
      "lon": -90.1820983887,
      "tz": "America\/Chicago"
  },
  "9IN2": {
      "icao": "9IN2",
      "iata": "",
      "name": "New Liberty Field",
      "city": "New Liberty",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 38.5583000183,
      "lon": -85.8653030396,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9IN3": {
      "icao": "9IN3",
      "iata": "",
      "name": "Johnsons Strawberry Farm Airport",
      "city": "Hobart",
      "state": "Indiana",
      "country": "US",
      "elevation": 620,
      "lat": 41.5569000244,
      "lon": -87.2264022827,
      "tz": "America\/Chicago"
  },
  "9IN4": {
      "icao": "9IN4",
      "iata": "",
      "name": "Bandmill Field",
      "city": "Decker",
      "state": "Indiana",
      "country": "US",
      "elevation": 400,
      "lat": 38.534198761,
      "lon": -87.5919036865,
      "tz": "America\/Chicago"
  },
  "9IN6": {
      "icao": "9IN6",
      "iata": "",
      "name": "Garrard Airport",
      "city": "Montezuma",
      "state": "Indiana",
      "country": "US",
      "elevation": 550,
      "lat": 39.840801239,
      "lon": -87.3394012451,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9IN7": {
      "icao": "9IN7",
      "iata": "",
      "name": "Zupancic Field",
      "city": "Morgantown",
      "state": "Indiana",
      "country": "US",
      "elevation": 660,
      "lat": 39.3546981812,
      "lon": -86.3069000244,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9IN8": {
      "icao": "9IN8",
      "iata": "",
      "name": "Green Field",
      "city": "Churubusco",
      "state": "Indiana",
      "country": "US",
      "elevation": 890,
      "lat": 41.2291984558,
      "lon": -85.3581008911,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "9IN9": {
      "icao": "9IN9",
      "iata": "",
      "name": "Gessie Airport",
      "city": "Gessie",
      "state": "Indiana",
      "country": "US",
      "elevation": 635,
      "lat": 40.0778007507,
      "lon": -87.515296936,
      "tz": "America\/Chicago"
  },
  "9IS0": {
      "icao": "9IS0",
      "iata": "",
      "name": "Cedar Ridge Airport",
      "city": "Nauvoo",
      "state": "Illinois",
      "country": "US",
      "elevation": 680,
      "lat": 40.5430984497,
      "lon": -91.3307037354,
      "tz": "America\/Chicago"
  },
  "9IS2": {
      "icao": "9IS2",
      "iata": "",
      "name": "Schertz Field",
      "city": "Gibson City",
      "state": "Illinois",
      "country": "US",
      "elevation": 758,
      "lat": 40.4822006226,
      "lon": -88.2676010132,
      "tz": "America\/Chicago"
  },
  "9IS5": {
      "icao": "9IS5",
      "iata": "",
      "name": "J & Y Ultralightport",
      "city": "Dixon",
      "state": "Illinois",
      "country": "US",
      "elevation": 786,
      "lat": 41.8581008911,
      "lon": -89.4073028564,
      "tz": "America\/Chicago"
  },
  "9IS9": {
      "icao": "9IS9",
      "iata": "",
      "name": "Lytleville Orchard Airport",
      "city": "Heyworth",
      "state": "Illinois",
      "country": "US",
      "elevation": 785,
      "lat": 40.3344993591,
      "lon": -88.9553985596,
      "tz": "America\/Chicago"
  },
  "9KS1": {
      "icao": "9KS1",
      "iata": "",
      "name": "Hartland Airport",
      "city": "Wellsville",
      "state": "Kansas",
      "country": "US",
      "elevation": 993,
      "lat": 38.6594444,
      "lon": -95.0922222,
      "tz": "America\/Chicago"
  },
  "9KS4": {
      "icao": "9KS4",
      "iata": "",
      "name": "Rose Port Airport",
      "city": "Ionia",
      "state": "Kansas",
      "country": "US",
      "elevation": 1500,
      "lat": 39.6363983154,
      "lon": -98.3598022461,
      "tz": "America\/Chicago"
  },
  "9KS5": {
      "icao": "9KS5",
      "iata": "",
      "name": "Rush Field",
      "city": "Geneseo",
      "state": "Kansas",
      "country": "US",
      "elevation": 1675,
      "lat": 38.5778007507,
      "lon": -98.1003036499,
      "tz": "America\/Chicago"
  },
  "9KS6": {
      "icao": "9KS6",
      "iata": "",
      "name": "Lundgren Hereford Ranch Airport",
      "city": "Gove",
      "state": "Kansas",
      "country": "US",
      "elevation": 2600,
      "lat": 38.8130989075,
      "lon": -100.5709991455,
      "tz": "America\/Chicago"
  },
  "9KS7": {
      "icao": "9KS7",
      "iata": "",
      "name": "Miller Airport",
      "city": "Harris",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 38.2778015137,
      "lon": -95.4432983398,
      "tz": "America\/Chicago"
  },
  "9KS9": {
      "icao": "9KS9",
      "iata": "",
      "name": "Dmh Airport",
      "city": "Salina",
      "state": "Kansas",
      "country": "US",
      "elevation": 1272,
      "lat": 38.7907981873,
      "lon": -97.6183013916,
      "tz": "America\/Chicago"
  },
  "9KY9": {
      "icao": "9KY9",
      "iata": "",
      "name": "Paintsville-Prestonsburg-Combs Field",
      "city": "Paintsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 624,
      "lat": 37.7458992004,
      "lon": -82.7790985107,
      "tz": "America\/New_York"
  },
  "9LA1": {
      "icao": "9LA1",
      "iata": "",
      "name": "Turkey Creek Lake Airport",
      "city": "Wisner",
      "state": "Louisiana",
      "country": "US",
      "elevation": 67,
      "lat": 31.9067993164,
      "lon": -91.7593002319,
      "tz": "America\/Chicago"
  },
  "9LA2": {
      "icao": "9LA2",
      "iata": "",
      "name": "Norris Airstrip",
      "city": "Newellton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 70,
      "lat": 32.1403999329,
      "lon": -91.4112014771,
      "tz": "America\/Chicago"
  },
  "9LA6": {
      "icao": "9LA6",
      "iata": "",
      "name": "Chandler Airport",
      "city": "Alexandria",
      "state": "Louisiana",
      "country": "US",
      "elevation": 82,
      "lat": 31.3068008423,
      "lon": -92.6115036011,
      "tz": "America\/Chicago"
  },
  "9LA9": {
      "icao": "9LA9",
      "iata": "",
      "name": "Skyline Airpark",
      "city": "Amite",
      "state": "Louisiana",
      "country": "US",
      "elevation": 200,
      "lat": 30.733499527,
      "lon": -90.4501037598,
      "tz": "America\/Chicago"
  },
  "9LL0": {
      "icao": "9LL0",
      "iata": "",
      "name": "Krutmeier Airport",
      "city": "Payson",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 39.8199996948,
      "lon": -91.2210006714,
      "tz": "America\/Chicago"
  },
  "9LL2": {
      "icao": "9LL2",
      "iata": "",
      "name": "Hepp Airport",
      "city": "Cutler",
      "state": "Illinois",
      "country": "US",
      "elevation": 505,
      "lat": 38.0336990356,
      "lon": -89.5792999268,
      "tz": "America\/Chicago"
  },
  "9LL3": {
      "icao": "9LL3",
      "iata": "",
      "name": "John R Reed Airport",
      "city": "Ashmore",
      "state": "Illinois",
      "country": "US",
      "elevation": 699,
      "lat": 39.5491981506,
      "lon": -88.0323028564,
      "tz": "America\/Chicago"
  },
  "9LL5": {
      "icao": "9LL5",
      "iata": "",
      "name": "Tommy's Airpark",
      "city": "Edinburg",
      "state": "Illinois",
      "country": "US",
      "elevation": 595,
      "lat": 39.6511001587,
      "lon": -89.4586029053,
      "tz": "America\/Chicago"
  },
  "9LL9": {
      "icao": "9LL9",
      "iata": "",
      "name": "Townley Farms Airport",
      "city": "Mattoon",
      "state": "Illinois",
      "country": "US",
      "elevation": 700,
      "lat": 39.4846992493,
      "lon": -88.4693984985,
      "tz": "America\/Chicago"
  },
  "9LS1": {
      "icao": "9LS1",
      "iata": "",
      "name": "Bayou Meadows Airport",
      "city": "Darnell",
      "state": "Louisiana",
      "country": "US",
      "elevation": 85,
      "lat": 32.7000999451,
      "lon": -91.3833999634,
      "tz": "America\/Chicago"
  },
  "9LS5": {
      "icao": "9LS5",
      "iata": "",
      "name": "Red Beard Dusting Service Airport",
      "city": "Tallulah",
      "state": "Louisiana",
      "country": "US",
      "elevation": 86,
      "lat": 32.2835006714,
      "lon": -91.1278991699,
      "tz": "America\/Chicago"
  },
  "9LS9": {
      "icao": "9LS9",
      "iata": "",
      "name": "4B Ranch Airport",
      "city": "Tendal",
      "state": "Louisiana",
      "country": "US",
      "elevation": 80,
      "lat": 32.4099006653,
      "lon": -91.3487014771,
      "tz": "America\/Chicago"
  },
  "9MD9": {
      "icao": "9MD9",
      "iata": "",
      "name": "G W Farm Airport",
      "city": "Denton",
      "state": "Delaware",
      "country": "US",
      "elevation": 50,
      "lat": 38.8520011902,
      "lon": -75.6893005371,
      "tz": "America\/New_York"
  },
  "9MI2": {
      "icao": "9MI2",
      "iata": "",
      "name": "Newport Woods Airport",
      "city": "Newport",
      "state": "Michigan",
      "country": "US",
      "elevation": 593,
      "lat": 41.9878997803,
      "lon": -83.3076019287,
      "tz": "America\/Detroit"
  },
  "9MI5": {
      "icao": "9MI5",
      "iata": "",
      "name": "Grass Roots STOLport",
      "city": "Millington",
      "state": "Michigan",
      "country": "US",
      "elevation": 780,
      "lat": 43.2378005981,
      "lon": -83.5243988037,
      "tz": "America\/Detroit"
  },
  "9MI6": {
      "icao": "9MI6",
      "iata": "",
      "name": "Trollman's Field",
      "city": "Fenton",
      "state": "Michigan",
      "country": "US",
      "elevation": 990,
      "lat": 42.739200592,
      "lon": -83.7549972534,
      "tz": "America\/Detroit"
  },
  "9MN1": {
      "icao": "9MN1",
      "iata": "",
      "name": "Troll Farm Airport",
      "city": "Cambridge",
      "state": "Minnesota",
      "country": "US",
      "elevation": 960,
      "lat": 45.6115989685,
      "lon": -93.1974029541,
      "tz": "America\/Chicago"
  },
  "9MN3": {
      "icao": "9MN3",
      "iata": "",
      "name": "Barnesville Municipal Airport",
      "city": "Barnesville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 980,
      "lat": 46.6651992798,
      "lon": -96.4412002563,
      "tz": "America\/Chicago"
  },
  "9MN5": {
      "icao": "9MN5",
      "iata": "",
      "name": "Janssen Airport",
      "city": "Barnesville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 46.7118988037,
      "lon": -96.3515014648,
      "tz": "America\/Chicago"
  },
  "9MN6": {
      "icao": "9MN6",
      "iata": "",
      "name": "Merill L Harris Field",
      "city": "Jordan",
      "state": "Minnesota",
      "country": "US",
      "elevation": 800,
      "lat": 44.6433982849,
      "lon": -93.6996994019,
      "tz": "America\/Chicago"
  },
  "9MN9": {
      "icao": "9MN9",
      "iata": "",
      "name": "Tofte Airport",
      "city": "Tofte",
      "state": "Minnesota",
      "country": "US",
      "elevation": 790,
      "lat": 47.5880012512,
      "lon": -90.8235015869,
      "tz": "America\/Chicago"
  },
  "9MO3": {
      "icao": "9MO3",
      "iata": "",
      "name": "Lawlor-Justus Airport",
      "city": "Faucett",
      "state": "Missouri",
      "country": "US",
      "elevation": 1100,
      "lat": 39.6417007446,
      "lon": -94.8135986328,
      "tz": "America\/Chicago"
  },
  "9MO4": {
      "icao": "9MO4",
      "iata": "",
      "name": "Worth Airport",
      "city": "Savannah",
      "state": "Missouri",
      "country": "US",
      "elevation": 1130,
      "lat": 39.9791984558,
      "lon": -94.8739013672,
      "tz": "America\/Chicago"
  },
  "9MO5": {
      "icao": "9MO5",
      "iata": "",
      "name": "Hindman Airport",
      "city": "Sparta",
      "state": "Missouri",
      "country": "US",
      "elevation": 1380,
      "lat": 37.06119919,
      "lon": -93.09929657,
      "tz": "America\/Chicago"
  },
  "9MO6": {
      "icao": "9MO6",
      "iata": "",
      "name": "Ivy Bend Airport",
      "city": "Stover",
      "state": "Missouri",
      "country": "US",
      "elevation": 690,
      "lat": 38.1930999756,
      "lon": -92.992401123,
      "tz": "America\/Chicago"
  },
  "9MO9": {
      "icao": "9MO9",
      "iata": "",
      "name": "Eagle Lodge Airport",
      "city": "Sumner",
      "state": "Missouri",
      "country": "US",
      "elevation": 650,
      "lat": 39.5499992371,
      "lon": -93.2501983643,
      "tz": "America\/Chicago"
  },
  "9MT8": {
      "icao": "9MT8",
      "iata": "",
      "name": "Monger Airport",
      "city": "Bozeman",
      "state": "Montana",
      "country": "US",
      "elevation": 4550,
      "lat": 45.7580986023,
      "lon": -111.0830001831,
      "tz": "America\/Denver"
  },
  "9MT9": {
      "icao": "9MT9",
      "iata": "",
      "name": "Mc Kenna Airport",
      "city": "Belgrade",
      "state": "Montana",
      "country": "US",
      "elevation": 4450,
      "lat": 45.7907981873,
      "lon": -111.125,
      "tz": "America\/Denver"
  },
  "9NA4": {
      "icao": "9NA4",
      "iata": "",
      "name": "Bodmer Airport",
      "city": "Kenmare",
      "state": "North Dakota",
      "country": "US",
      "elevation": 1885,
      "lat": 48.6660995483,
      "lon": -101.8909988403,
      "tz": "America\/Chicago"
  },
  "9NC0": {
      "icao": "9NC0",
      "iata": "",
      "name": "Raleigh East Airport",
      "city": "Knightdale",
      "state": "North Carolina",
      "country": "US",
      "elevation": 313,
      "lat": 35.7976989746,
      "lon": -78.43699646,
      "tz": "America\/New_York"
  },
  "9NC2": {
      "icao": "9NC2",
      "iata": "",
      "name": "Flyers Airpark",
      "city": "Linden",
      "state": "North Carolina",
      "country": "US",
      "elevation": 115,
      "lat": 35.2182006836,
      "lon": -78.7964019775,
      "tz": "America\/New_York"
  },
  "9NC3": {
      "icao": "9NC3",
      "iata": "",
      "name": "Edwards Airport",
      "city": "Monroe",
      "state": "North Carolina",
      "country": "US",
      "elevation": 640,
      "lat": 35.0708007813,
      "lon": -80.5663986206,
      "tz": "America\/New_York"
  },
  "9NC4": {
      "icao": "9NC4",
      "iata": "",
      "name": "Jiles Field",
      "city": "Gates",
      "state": "North Carolina",
      "country": "US",
      "elevation": 75,
      "lat": 36.5093994141,
      "lon": -76.6641998291,
      "tz": "America\/New_York"
  },
  "9NC6": {
      "icao": "9NC6",
      "iata": "",
      "name": "Sandy Run Acres Airport",
      "city": "Maco",
      "state": "North Carolina",
      "country": "US",
      "elevation": 60,
      "lat": 34.2748985291,
      "lon": -78.1607971191,
      "tz": "America\/New_York"
  },
  "9NC7": {
      "icao": "9NC7",
      "iata": "",
      "name": "Willow Creek Airport",
      "city": "Mt Pleasant",
      "state": "North Carolina",
      "country": "US",
      "elevation": 572,
      "lat": 35.3697013855,
      "lon": -80.4400024414,
      "tz": "America\/New_York"
  },
  "9NC8": {
      "icao": "9NC8",
      "iata": "",
      "name": "Eagles Landing Airport",
      "city": "Pittsboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 430,
      "lat": 35.6869010925,
      "lon": -79.2188034058,
      "tz": "America\/New_York"
  },
  "9NC9": {
      "icao": "9NC9",
      "iata": "",
      "name": "Meylor Field",
      "city": "Greensboro",
      "state": "North Carolina",
      "country": "US",
      "elevation": 910,
      "lat": 36.2100982666,
      "lon": -79.8949966431,
      "tz": "America\/New_York"
  },
  "9ND1": {
      "icao": "9ND1",
      "iata": "",
      "name": "Elliott Farms Airport",
      "city": "Drayton",
      "state": "North Dakota",
      "country": "US",
      "elevation": 808,
      "lat": 48.6479988098,
      "lon": -97.2684020996,
      "tz": "America\/Chicago"
  },
  "9ND8": {
      "icao": "9ND8",
      "iata": "",
      "name": "Hinkle Airport",
      "city": "Cavalier",
      "state": "North Dakota",
      "country": "US",
      "elevation": 908,
      "lat": 48.7854995728,
      "lon": -97.6723022461,
      "tz": "America\/Chicago"
  },
  "9NE1": {
      "icao": "9NE1",
      "iata": "",
      "name": "El-Co Airport",
      "city": "Brule",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3650,
      "lat": 41.1444015503,
      "lon": -101.9270019531,
      "tz": "America\/Denver"
  },
  "9NE2": {
      "icao": "9NE2",
      "iata": "",
      "name": "Folkerts Airport",
      "city": "Carleton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1610,
      "lat": 40.2916984558,
      "lon": -97.7225036621,
      "tz": "America\/Chicago"
  },
  "9NE3": {
      "icao": "9NE3",
      "iata": "",
      "name": "Davis Ranch Airport",
      "city": "Hyannis",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3768,
      "lat": 42.1875,
      "lon": -101.7890014648,
      "tz": "America\/Denver"
  },
  "9NE4": {
      "icao": "9NE4",
      "iata": "",
      "name": "Johnston Field",
      "city": "Lindsay",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1800,
      "lat": 41.7033004761,
      "lon": -97.7444992065,
      "tz": "America\/Chicago"
  },
  "9NE6": {
      "icao": "9NE6",
      "iata": "",
      "name": "Bates Airpark",
      "city": "Omaha",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1250,
      "lat": 41.3382987976,
      "lon": -96.0880966187,
      "tz": "America\/Chicago"
  },
  "9NE7": {
      "icao": "9NE7",
      "iata": "",
      "name": "Bay Field",
      "city": "Stapleton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3000,
      "lat": 41.4333000183,
      "lon": -100.4840011597,
      "tz": "America\/Chicago"
  },
  "9NE8": {
      "icao": "9NE8",
      "iata": "",
      "name": "Brosius Field",
      "city": "Stapleton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3010,
      "lat": 41.4016990662,
      "lon": -100.4789962769,
      "tz": "America\/Chicago"
  },
  "9NE9": {
      "icao": "9NE9",
      "iata": "",
      "name": "Dog Leg Airport",
      "city": "Hebron",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1500,
      "lat": 40.1944007874,
      "lon": -97.5670013428,
      "tz": "America\/Chicago"
  },
  "9NJ5": {
      "icao": "9NJ5",
      "iata": "",
      "name": "Stallone Airport",
      "city": "Harrisonville",
      "state": "New Jersey",
      "country": "US",
      "elevation": 80,
      "lat": 39.6800994873,
      "lon": -75.2524032593,
      "tz": "America\/New_York"
  },
  "9NJ6": {
      "icao": "9NJ6",
      "iata": "",
      "name": "Halka Nurseries Airport",
      "city": "Fairton",
      "state": "New Jersey",
      "country": "US",
      "elevation": 10,
      "lat": 39.3664016724,
      "lon": -75.2683029175,
      "tz": "America\/New_York"
  },
  "9NJ8": {
      "icao": "9NJ8",
      "iata": "",
      "name": "High Meadow Farms Airport",
      "city": "Hope",
      "state": "New Jersey",
      "country": "US",
      "elevation": 630,
      "lat": 40.8959007263,
      "lon": -74.9996032715,
      "tz": "America\/New_York"
  },
  "9NR7": {
      "icao": "9NR7",
      "iata": "",
      "name": "Triple F Airpark",
      "city": "Salemburg",
      "state": "North Carolina",
      "country": "US",
      "elevation": 148,
      "lat": 35.018333,
      "lon": -78.565833,
      "tz": "America\/New_York"
  },
  "9NR8": {
      "icao": "9NR8",
      "iata": "",
      "name": "Buie Field",
      "city": "Red Springs",
      "state": "North Carolina",
      "country": "US",
      "elevation": 203,
      "lat": 34.793333,
      "lon": -79.193611,
      "tz": "America\/New_York"
  },
  "9NY4": {
      "icao": "9NY4",
      "iata": "",
      "name": "Kennedy Airfield",
      "city": "Kennedy",
      "state": "New York",
      "country": "US",
      "elevation": 1760,
      "lat": 42.1007995605,
      "lon": -79.0818023682,
      "tz": "America\/New_York"
  },
  "9NY6": {
      "icao": "9NY6",
      "iata": "",
      "name": "Mason Airway Airport",
      "city": "Treadwell",
      "state": "New York",
      "country": "US",
      "elevation": 2170,
      "lat": 42.324798584,
      "lon": -74.9862976074,
      "tz": "America\/New_York"
  },
  "9NY7": {
      "icao": "9NY7",
      "iata": "",
      "name": "Hart Airport",
      "city": "Perth",
      "state": "New York",
      "country": "US",
      "elevation": 925,
      "lat": 43.0329017639,
      "lon": -74.1510009766,
      "tz": "America\/New_York"
  },
  "9OA3": {
      "icao": "9OA3",
      "iata": "",
      "name": "Bellville Dam Landing Strip",
      "city": "Long Bottom",
      "state": "Ohio",
      "country": "US",
      "elevation": 591,
      "lat": 39.0834007263,
      "lon": -81.7776031494,
      "tz": "America\/New_York"
  },
  "9OA4": {
      "icao": "9OA4",
      "iata": "",
      "name": "Gilmer Airport",
      "city": "Lewisburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 1045,
      "lat": 39.8302993774,
      "lon": -84.5096969604,
      "tz": "America\/New_York"
  },
  "9OA5": {
      "icao": "9OA5",
      "iata": "",
      "name": "Buckeye Executive Airport",
      "city": "Hebron",
      "state": "Ohio",
      "country": "US",
      "elevation": 914,
      "lat": 39.9623184204,
      "lon": -82.5499801636,
      "tz": "America\/New_York"
  },
  "9OA7": {
      "icao": "9OA7",
      "iata": "",
      "name": "Jenkins Airport",
      "city": "Fremont",
      "state": "Ohio",
      "country": "US",
      "elevation": 655,
      "lat": 41.3097991943,
      "lon": -83.1159973145,
      "tz": "America\/New_York"
  },
  "9OA8": {
      "icao": "9OA8",
      "iata": "",
      "name": "Crocker Airport",
      "city": "Columbia Station",
      "state": "Ohio",
      "country": "US",
      "elevation": 830,
      "lat": 41.2822990417,
      "lon": -81.9489974976,
      "tz": "America\/New_York"
  },
  "9OA9": {
      "icao": "9OA9",
      "iata": "",
      "name": "Bender Airport",
      "city": "Bellville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1140,
      "lat": 40.6213989258,
      "lon": -82.5337982178,
      "tz": "America\/New_York"
  },
  "9OH1": {
      "icao": "9OH1",
      "iata": "",
      "name": "Haar Airport",
      "city": "Elmore",
      "state": "Ohio",
      "country": "US",
      "elevation": 617,
      "lat": 41.4542007446,
      "lon": -83.2651977539,
      "tz": "America\/New_York"
  },
  "9OH2": {
      "icao": "9OH2",
      "iata": "",
      "name": "Toussaint Airpark",
      "city": "Elmore",
      "state": "Ohio",
      "country": "US",
      "elevation": 610,
      "lat": 41.5155982971,
      "lon": -83.3058013916,
      "tz": "America\/New_York"
  },
  "9OH4": {
      "icao": "9OH4",
      "iata": "",
      "name": "Richards Airport",
      "city": "Elyria",
      "state": "Ohio",
      "country": "US",
      "elevation": 690,
      "lat": 41.4039001465,
      "lon": -82.1064987183,
      "tz": "America\/New_York"
  },
  "9OH6": {
      "icao": "9OH6",
      "iata": "",
      "name": "Weaver Airport",
      "city": "Findlay",
      "state": "Ohio",
      "country": "US",
      "elevation": 795,
      "lat": 41.0353012085,
      "lon": -83.5690002441,
      "tz": "America\/New_York"
  },
  "9OH7": {
      "icao": "9OH7",
      "iata": "",
      "name": "Lutz Airport",
      "city": "Findlay",
      "state": "Ohio",
      "country": "US",
      "elevation": 808,
      "lat": 40.9617004395,
      "lon": -83.595199585,
      "tz": "America\/New_York"
  },
  "9OH8": {
      "icao": "9OH8",
      "iata": "",
      "name": "Ferrell Airport",
      "city": "Findlay",
      "state": "Ohio",
      "country": "US",
      "elevation": 815,
      "lat": 40.976398468,
      "lon": -83.6416015625,
      "tz": "America\/New_York"
  },
  "9OH9": {
      "icao": "9OH9",
      "iata": "",
      "name": "Forest Field",
      "city": "Forest",
      "state": "Ohio",
      "country": "US",
      "elevation": 890,
      "lat": 40.8287010193,
      "lon": -83.5138015747,
      "tz": "America\/New_York"
  },
  "9OI3": {
      "icao": "9OI3",
      "iata": "",
      "name": "Johns Landing Airport",
      "city": "Zanesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 39.898601532,
      "lon": -82.1102981567,
      "tz": "America\/New_York"
  },
  "9OI5": {
      "icao": "9OI5",
      "iata": "",
      "name": "Transportation Research Center of Ohio Airport",
      "city": "East Liberty",
      "state": "Ohio",
      "country": "US",
      "elevation": 1100,
      "lat": 40.3081016541,
      "lon": -83.5417022705,
      "tz": "America\/New_York"
  },
  "9OI6": {
      "icao": "9OI6",
      "iata": "",
      "name": "Nesta Airport",
      "city": "Loudenville",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 40.6328010559,
      "lon": -82.1768035889,
      "tz": "America\/New_York"
  },
  "9OI7": {
      "icao": "9OI7",
      "iata": "",
      "name": "Bayes Airport",
      "city": "Magnetic Springs",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 40.3400993347,
      "lon": -83.2841033936,
      "tz": "America\/New_York"
  },
  "9OI8": {
      "icao": "9OI8",
      "iata": "",
      "name": "Brothers Aviation Airport",
      "city": "Old Washington",
      "state": "Ohio",
      "country": "US",
      "elevation": 1040,
      "lat": 40.0322990417,
      "lon": -81.4651031494,
      "tz": "America\/New_York"
  },
  "9OK3": {
      "icao": "9OK3",
      "iata": "",
      "name": "Flying Eagle Estates Airport",
      "city": "Bartlesville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 690,
      "lat": 36.7037010193,
      "lon": -95.8404998779,
      "tz": "America\/Chicago"
  },
  "9OK5": {
      "icao": "9OK5",
      "iata": "",
      "name": "Diamond C Ranch Airport",
      "city": "Stratford",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1140,
      "lat": 34.778301239,
      "lon": -96.9274978638,
      "tz": "America\/Chicago"
  },
  "9OK7": {
      "icao": "9OK7",
      "iata": "",
      "name": "Cimarron Strip",
      "city": "Jennings",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 920,
      "lat": 36.1847991943,
      "lon": -96.5503005981,
      "tz": "America\/Chicago"
  },
  "9OK9": {
      "icao": "9OK9",
      "iata": "",
      "name": "Strang Airpark",
      "city": "Short",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 690,
      "lat": 35.5444984436,
      "lon": -94.4862976074,
      "tz": "America\/Chicago"
  },
  "9OR0": {
      "icao": "9OR0",
      "iata": "",
      "name": "Lafferty Field",
      "city": "Brownsville",
      "state": "Oregon",
      "country": "US",
      "elevation": 360,
      "lat": 44.3151016235,
      "lon": -123.0250015259,
      "tz": "America\/Los_Angeles"
  },
  "9OR1": {
      "icao": "9OR1",
      "iata": "",
      "name": "Shaniko Ranch Airport",
      "city": "Shaniko",
      "state": "Oregon",
      "country": "US",
      "elevation": 3300,
      "lat": 45.0028991699,
      "lon": -120.7399978638,
      "tz": "America\/Los_Angeles"
  },
  "9OR4": {
      "icao": "9OR4",
      "iata": "",
      "name": "King's Airport",
      "city": "Milton\/Freewater",
      "state": "Oregon",
      "country": "US",
      "elevation": 950,
      "lat": 45.9337005615,
      "lon": -118.4479980469,
      "tz": "America\/Los_Angeles"
  },
  "9OR8": {
      "icao": "9OR8",
      "iata": "",
      "name": "Wooldridge Agstrip Airport",
      "city": "Albany",
      "state": "Oregon",
      "country": "US",
      "elevation": 205,
      "lat": 44.7326011658,
      "lon": -123.0559997559,
      "tz": "America\/Los_Angeles"
  },
  "9PA0": {
      "icao": "9PA0",
      "iata": "",
      "name": "Lance Airport",
      "city": "Parker Ford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 275,
      "lat": 40.1873016357,
      "lon": -75.5902023315,
      "tz": "America\/New_York"
  },
  "9PA1": {
      "icao": "9PA1",
      "iata": "",
      "name": "Carbondale-Clifford Airport",
      "city": "Carbondale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1400,
      "lat": 41.6542015076,
      "lon": -75.5787963867,
      "tz": "America\/New_York"
  },
  "9PA2": {
      "icao": "9PA2",
      "iata": "",
      "name": "Old Plains Airport",
      "city": "Pennsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 660,
      "lat": 40.3945007324,
      "lon": -75.4412994385,
      "tz": "America\/New_York"
  },
  "9PA3": {
      "icao": "9PA3",
      "iata": "",
      "name": "Meadow Strip Ultralightport",
      "city": "Richlandtown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 499,
      "lat": 40.4757003784,
      "lon": -75.3096008301,
      "tz": "America\/New_York"
  },
  "9PA5": {
      "icao": "9PA5",
      "iata": "",
      "name": "Solt Field",
      "city": "Leighton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 520,
      "lat": 40.7801017761,
      "lon": -75.7163009644,
      "tz": "America\/New_York"
  },
  "9PA7": {
      "icao": "9PA7",
      "iata": "",
      "name": "Keystone Airport",
      "city": "Fort Hill",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2531,
      "lat": 39.7728004456,
      "lon": -79.2110977173,
      "tz": "America\/New_York"
  },
  "9PN1": {
      "icao": "9PN1",
      "iata": "",
      "name": "Seitz Field",
      "city": "Perryopolis",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1240,
      "lat": 40.0390014648,
      "lon": -79.7900009155,
      "tz": "America\/New_York"
  },
  "9PN7": {
      "icao": "9PN7",
      "iata": "",
      "name": "Veit Airport",
      "city": "Wysox",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1360,
      "lat": 41.8180999756,
      "lon": -76.4126968384,
      "tz": "America\/New_York"
  },
  "9PN8": {
      "icao": "9PN8",
      "iata": "",
      "name": "Malinchak Private Airport",
      "city": "Carbondale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1700,
      "lat": 41.6250991821,
      "lon": -75.5329971313,
      "tz": "America\/New_York"
  },
  "9PS2": {
      "icao": "9PS2",
      "iata": "",
      "name": "J T Willie Airport",
      "city": "Worthington",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.8087005615,
      "lon": -79.6697998047,
      "tz": "America\/New_York"
  },
  "9PS8": {
      "icao": "9PS8",
      "iata": "",
      "name": "Manor Landing Airport",
      "city": "Gettysburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 560,
      "lat": 39.8130989075,
      "lon": -77.2949981689,
      "tz": "America\/New_York"
  },
  "9SD7": {
      "icao": "9SD7",
      "iata": "",
      "name": "Beaman Airport",
      "city": "Selby",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1960,
      "lat": 45.4749984741,
      "lon": -100.0350036621,
      "tz": "America\/Chicago"
  },
  "9SD8": {
      "icao": "9SD8",
      "iata": "",
      "name": "Oakleaf Airport",
      "city": "Hartford",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1625,
      "lat": 43.5833015442,
      "lon": -96.950302124,
      "tz": "America\/Chicago"
  },
  "9SD9": {
      "icao": "9SD9",
      "iata": "",
      "name": "Weelborg Airport",
      "city": "Dell Rapids",
      "state": "South Dakota",
      "country": "US",
      "elevation": 1553,
      "lat": 43.8065986633,
      "lon": -96.7089004517,
      "tz": "America\/Chicago"
  },
  "9TA1": {
      "icao": "9TA1",
      "iata": "",
      "name": "Hilltop Ranch Airport",
      "city": "New Braunfels",
      "state": "Texas",
      "country": "US",
      "elevation": 762,
      "lat": 29.6585998535,
      "lon": -98.137802124,
      "tz": "America\/Chicago"
  },
  "9TA2": {
      "icao": "9TA2",
      "iata": "",
      "name": "Urschel Ranch Airport",
      "city": "Canadian",
      "state": "Texas",
      "country": "US",
      "elevation": 2340,
      "lat": 35.9289016724,
      "lon": -100.3069992065,
      "tz": "America\/Chicago"
  },
  "9TA3": {
      "icao": "9TA3",
      "iata": "",
      "name": "Rogers Airport",
      "city": "Crosby",
      "state": "Texas",
      "country": "US",
      "elevation": 38,
      "lat": 29.8729991913,
      "lon": -94.9896011353,
      "tz": "America\/Chicago"
  },
  "9TA8": {
      "icao": "9TA8",
      "iata": "",
      "name": "Taylor Ranch Airport",
      "city": "Alto",
      "state": "Texas",
      "country": "US",
      "elevation": 270,
      "lat": 31.6840991974,
      "lon": -94.9772033691,
      "tz": "America\/Chicago"
  },
  "9TE0": {
      "icao": "9TE0",
      "iata": "",
      "name": "Twin Acres Airport",
      "city": "Scurry",
      "state": "Texas",
      "country": "US",
      "elevation": 340,
      "lat": 32.3992004395,
      "lon": -96.3835983276,
      "tz": "America\/Chicago"
  },
  "9TE2": {
      "icao": "9TE2",
      "iata": "",
      "name": "Wallace Ranch Airport",
      "city": "Comfort",
      "state": "Texas",
      "country": "US",
      "elevation": 1510,
      "lat": 30.0168991089,
      "lon": -98.8503036499,
      "tz": "America\/Chicago"
  },
  "9TE3": {
      "icao": "9TE3",
      "iata": "",
      "name": "Thorp Airport",
      "city": "Lamesa",
      "state": "Texas",
      "country": "US",
      "elevation": 2900,
      "lat": 32.5778999329,
      "lon": -101.9309997559,
      "tz": "America\/Chicago"
  },
  "9TE4": {
      "icao": "9TE4",
      "iata": "",
      "name": "Tanner's Airport",
      "city": "Port Lavaca",
      "state": "Texas",
      "country": "US",
      "elevation": 15,
      "lat": 28.5697002411,
      "lon": -96.629699707,
      "tz": "America\/Chicago"
  },
  "9TE5": {
      "icao": "9TE5",
      "iata": "",
      "name": "Kalt Ranch Airport",
      "city": "Fulton",
      "state": "Texas",
      "country": "US",
      "elevation": 15,
      "lat": 28.154499054,
      "lon": -96.9768981934,
      "tz": "America\/Chicago"
  },
  "9TE6": {
      "icao": "9TE6",
      "iata": "",
      "name": "Rocky Creek Ranch Airport",
      "city": "La Grange",
      "state": "Texas",
      "country": "US",
      "elevation": 390,
      "lat": 29.9169006348,
      "lon": -96.8003005981,
      "tz": "America\/Chicago"
  },
  "9TE7": {
      "icao": "9TE7",
      "iata": "",
      "name": "Flying G H Ranch Airport",
      "city": "Paige",
      "state": "Texas",
      "country": "US",
      "elevation": 455,
      "lat": 30.245278,
      "lon": -97.082778,
      "tz": "America\/Chicago"
  },
  "9TN1": {
      "icao": "9TN1",
      "iata": "",
      "name": "Versailles Aerodrome",
      "city": "Eagleville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 855,
      "lat": 35.7299995422,
      "lon": -86.5378036499,
      "tz": "America\/Chicago"
  },
  "9TN2": {
      "icao": "9TN2",
      "iata": "",
      "name": "Meadowlark Airport",
      "city": "Camden",
      "state": "Tennessee",
      "country": "US",
      "elevation": 460,
      "lat": 36.163898468,
      "lon": -88.2052993774,
      "tz": "America\/Chicago"
  },
  "9TN3": {
      "icao": "9TN3",
      "iata": "",
      "name": "Mehrhoff Field",
      "city": "Rossville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 426,
      "lat": 35.1136016846,
      "lon": -89.5243988037,
      "tz": "America\/Chicago"
  },
  "9TN4": {
      "icao": "9TN4",
      "iata": "",
      "name": "Foreman Field",
      "city": "Springfield",
      "state": "Tennessee",
      "country": "US",
      "elevation": 705,
      "lat": 36.457698822,
      "lon": -86.9459991455,
      "tz": "America\/Chicago"
  },
  "9TN5": {
      "icao": "9TN5",
      "iata": "",
      "name": "Askey Field",
      "city": "Culleoka",
      "state": "Tennessee",
      "country": "US",
      "elevation": 810,
      "lat": 35.4583015442,
      "lon": -86.9569015503,
      "tz": "America\/Chicago"
  },
  "9TN7": {
      "icao": "9TN7",
      "iata": "",
      "name": "Baskin Airport",
      "city": "Covington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 400,
      "lat": 35.5344009399,
      "lon": -89.7350006104,
      "tz": "America\/Chicago"
  },
  "9TN9": {
      "icao": "9TN9",
      "iata": "",
      "name": "Toy Box Airport",
      "city": "Lebanon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 630,
      "lat": 36.0194015503,
      "lon": -86.2881011963,
      "tz": "America\/Chicago"
  },
  "9TS3": {
      "icao": "9TS3",
      "iata": "",
      "name": "Simaron Ranch Airport",
      "city": "Waller",
      "state": "Texas",
      "country": "US",
      "elevation": 310,
      "lat": 30.1483001709,
      "lon": -95.9669036865,
      "tz": "America\/Chicago"
  },
  "9TS6": {
      "icao": "9TS6",
      "iata": "",
      "name": "Goodlett Field",
      "city": "Alvarado",
      "state": "Texas",
      "country": "US",
      "elevation": 690,
      "lat": 32.3788986206,
      "lon": -97.1782989502,
      "tz": "America\/Chicago"
  },
  "9TX1": {
      "icao": "9TX1",
      "iata": "",
      "name": "Bar S Ranch Airport",
      "city": "Giddings",
      "state": "Texas",
      "country": "US",
      "elevation": 465,
      "lat": 30.1432991028,
      "lon": -96.8561019897,
      "tz": "America\/Chicago"
  },
  "9TX2": {
      "icao": "9TX2",
      "iata": "",
      "name": "Bennetts Airport",
      "city": "Crandall",
      "state": "Texas",
      "country": "US",
      "elevation": 445,
      "lat": 32.6371002197,
      "lon": -96.4083023071,
      "tz": "America\/Chicago"
  },
  "9TX3": {
      "icao": "9TX3",
      "iata": "",
      "name": "Horan Airport",
      "city": "Plainview",
      "state": "Texas",
      "country": "US",
      "elevation": 3339,
      "lat": 34.1689987183,
      "lon": -101.6350021362,
      "tz": "America\/Chicago"
  },
  "9TX4": {
      "icao": "9TX4",
      "iata": "",
      "name": "Flying H Ranch Airport",
      "city": "Liberty Hill",
      "state": "Texas",
      "country": "US",
      "elevation": 1000,
      "lat": 30.6595993042,
      "lon": -97.9111022949,
      "tz": "America\/Chicago"
  },
  "9TX5": {
      "icao": "9TX5",
      "iata": "",
      "name": "Camp Bullis Als (Cals) Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 1158,
      "lat": 29.74469948,
      "lon": -98.53720093,
      "tz": "America\/Chicago"
  },
  "9TX6": {
      "icao": "9TX6",
      "iata": "",
      "name": "Beggs Ranch Airport",
      "city": "Post",
      "state": "Texas",
      "country": "US",
      "elevation": 2355,
      "lat": 33.1750984192,
      "lon": -101.0240020752,
      "tz": "America\/Chicago"
  },
  "9TX7": {
      "icao": "9TX7",
      "iata": "",
      "name": "Hitex Private Airport",
      "city": "Pottsboro",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 33.8418006897,
      "lon": -96.6697006226,
      "tz": "America\/Chicago"
  },
  "9TX9": {
      "icao": "9TX9",
      "iata": "",
      "name": "Canon Ranch Airport",
      "city": "Sheffield",
      "state": "Texas",
      "country": "US",
      "elevation": 2380,
      "lat": 30.7481994629,
      "lon": -101.9649963379,
      "tz": "America\/Chicago"
  },
  "9VA0": {
      "icao": "9VA0",
      "iata": "",
      "name": "Bath Alum Airport",
      "city": "Warm Springs",
      "state": "Virginia",
      "country": "US",
      "elevation": 1779,
      "lat": 38.0526008606,
      "lon": -79.7288970947,
      "tz": "America\/New_York"
  },
  "9VA1": {
      "icao": "9VA1",
      "iata": "",
      "name": "Holly Point Airport",
      "city": "Mathews",
      "state": "Virginia",
      "country": "US",
      "elevation": 12,
      "lat": 37.4053993225,
      "lon": -76.3882980347,
      "tz": "America\/New_York"
  },
  "9VA3": {
      "icao": "9VA3",
      "iata": "",
      "name": "Crippen Creek Farm Airport",
      "city": "Parksley",
      "state": "Virginia",
      "country": "US",
      "elevation": 6,
      "lat": 37.7629013062,
      "lon": -75.5718994141,
      "tz": "America\/New_York"
  },
  "9VA4": {
      "icao": "9VA4",
      "iata": "",
      "name": "Franwood Farms Inc. Airport",
      "city": "New Market",
      "state": "Virginia",
      "country": "US",
      "elevation": 990,
      "lat": 38.6908988953,
      "lon": -78.6295013428,
      "tz": "America\/New_York"
  },
  "9VG9": {
      "icao": "9VG9",
      "iata": "",
      "name": "Jucapa Farms Airport",
      "city": "Winchester",
      "state": "Virginia",
      "country": "US",
      "elevation": 1160,
      "lat": 39.3721008301,
      "lon": -78.3044967651,
      "tz": "America\/New_York"
  },
  "9WA3": {
      "icao": "9WA3",
      "iata": "",
      "name": "River Bend Airport",
      "city": "River Bend Lodge",
      "state": "Washington",
      "country": "US",
      "elevation": 2040,
      "lat": 48.4696006775,
      "lon": -117.2900009155,
      "tz": "America\/Los_Angeles"
  },
  "9WA4": {
      "icao": "9WA4",
      "iata": "",
      "name": "Piper Canyon Airport",
      "city": "Goldendale",
      "state": "Washington",
      "country": "US",
      "elevation": 1610,
      "lat": 45.8027992249,
      "lon": -120.9560012817,
      "tz": "America\/Los_Angeles"
  },
  "9WA7": {
      "icao": "9WA7",
      "iata": "",
      "name": "Albritton Airport",
      "city": "Buckley",
      "state": "Washington",
      "country": "US",
      "elevation": 530,
      "lat": 47.2155990601,
      "lon": -122.1330032349,
      "tz": "America\/Los_Angeles"
  },
  "9WI1": {
      "icao": "9WI1",
      "iata": "",
      "name": "Black Otter Airport",
      "city": "Hortonville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 805,
      "lat": 44.3293991089,
      "lon": -88.6235961914,
      "tz": "America\/Chicago"
  },
  "9WI2": {
      "icao": "9WI2",
      "iata": "",
      "name": "Flying Dollar Ranch Airport",
      "city": "Maribel",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 840,
      "lat": 44.2874984741,
      "lon": -87.7958984375,
      "tz": "America\/Chicago"
  },
  "9WI3": {
      "icao": "9WI3",
      "iata": "",
      "name": "Buchholz Farm Airport",
      "city": "Morrison",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 955,
      "lat": 44.2854995728,
      "lon": -87.9868011475,
      "tz": "America\/Chicago"
  },
  "9WI4": {
      "icao": "9WI4",
      "iata": "",
      "name": "Faken Airport",
      "city": "New Berlin",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 904,
      "lat": 42.9785995483,
      "lon": -88.134803772,
      "tz": "America\/Chicago"
  },
  "9WI5": {
      "icao": "9WI5",
      "iata": "",
      "name": "Tamarack Airport",
      "city": "Palmyra",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 42.8283004761,
      "lon": -88.5745010376,
      "tz": "America\/Chicago"
  },
  "9WI6": {
      "icao": "9WI6",
      "iata": "",
      "name": "Kitty Hawk Estates Airport",
      "city": "Polar",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1500,
      "lat": 45.1040992737,
      "lon": -89.0167999268,
      "tz": "America\/Chicago"
  },
  "9WI8": {
      "icao": "9WI8",
      "iata": "",
      "name": "Fletcher Airport",
      "city": "Spring Prairie",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 980,
      "lat": 42.693901062,
      "lon": -88.3793029785,
      "tz": "America\/Chicago"
  },
  "9WI9": {
      "icao": "9WI9",
      "iata": "",
      "name": "Carlson Airport",
      "city": "Superior",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 680,
      "lat": 46.6208000183,
      "lon": -92.0819015503,
      "tz": "America\/Chicago"
  },
  "9WN1": {
      "icao": "9WN1",
      "iata": "",
      "name": "Courtney Plummer Airport",
      "city": "Winneconne",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 758,
      "lat": 44.1068992615,
      "lon": -88.6839981079,
      "tz": "America\/Chicago"
  },
  "9WN2": {
      "icao": "9WN2",
      "iata": "",
      "name": "Voyager Village Airstrip",
      "city": "Webster",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1020,
      "lat": 45.9665985107,
      "lon": -92.1460037231,
      "tz": "America\/Chicago"
  },
  "9WN3": {
      "icao": "9WN3",
      "iata": "",
      "name": "Ozaukee Airport",
      "city": "Port Washington",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 43.4213981628,
      "lon": -87.888999939,
      "tz": "America\/Chicago"
  },
  "9WN4": {
      "icao": "9WN4",
      "iata": "",
      "name": "Rag Wing Airport",
      "city": "Antigo",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1500,
      "lat": 45.0839996338,
      "lon": -89.029800415,
      "tz": "America\/Chicago"
  },
  "9WN5": {
      "icao": "9WN5",
      "iata": "",
      "name": "Lodi Lakeland Airport",
      "city": "Lodi",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 844,
      "lat": 43.3333015442,
      "lon": -89.5210037231,
      "tz": "America\/Chicago"
  },
  "9WN9": {
      "icao": "9WN9",
      "iata": "",
      "name": "Coloma Municipal Airport",
      "city": "Coloma",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1100,
      "lat": 44.0600013733,
      "lon": -89.5668029785,
      "tz": "America\/Chicago"
  },
  "9WS2": {
      "icao": "9WS2",
      "iata": "",
      "name": "Antique Aerodrome",
      "city": "De Pere",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 655,
      "lat": 44.367639,
      "lon": -88.158678,
      "tz": "America\/Chicago"
  },
  "9XS0": {
      "icao": "9XS0",
      "iata": "",
      "name": "Dobbs Ranch Airport",
      "city": "Yoakum",
      "state": "Texas",
      "country": "US",
      "elevation": 170,
      "lat": 29.0979995728,
      "lon": -96.9552993774,
      "tz": "America\/Chicago"
  },
  "9XS1": {
      "icao": "9XS1",
      "iata": "",
      "name": "The Landing Airport",
      "city": "Hill",
      "state": "Texas",
      "country": "US",
      "elevation": 570,
      "lat": 31.8355998993,
      "lon": -96.952796936,
      "tz": "America\/Chicago"
  },
  "9XS2": {
      "icao": "9XS2",
      "iata": "",
      "name": "Beaver Creek Airport",
      "city": "Sherman",
      "state": "Texas",
      "country": "US",
      "elevation": 708,
      "lat": 33.6542626352,
      "lon": -96.7763596773,
      "tz": "America\/Chicago"
  },
  "9XS3": {
      "icao": "9XS3",
      "iata": "",
      "name": "Macy Ranch Airport",
      "city": "Post",
      "state": "Texas",
      "country": "US",
      "elevation": 2870,
      "lat": 33.1034011841,
      "lon": -101.4649963379,
      "tz": "America\/Chicago"
  },
  "9XS4": {
      "icao": "9XS4",
      "iata": "",
      "name": "Mc Keon Aviation Airport",
      "city": "Denison",
      "state": "Texas",
      "country": "US",
      "elevation": 750,
      "lat": 33.7342987061,
      "lon": -96.626701355,
      "tz": "America\/Chicago"
  },
  "9XS5": {
      "icao": "9XS5",
      "iata": "",
      "name": "Kitten Farm Private Airport",
      "city": "Slaton",
      "state": "Texas",
      "country": "US",
      "elevation": 3050,
      "lat": 33.3501014709,
      "lon": -101.641998291,
      "tz": "America\/Chicago"
  },
  "9XS6": {
      "icao": "9XS6",
      "iata": "",
      "name": "Sudan Airport",
      "city": "Sudan",
      "state": "Texas",
      "country": "US",
      "elevation": 3769,
      "lat": 34.0531005859,
      "lon": -102.5220031738,
      "tz": "America\/Chicago"
  },
  "9XS7": {
      "icao": "9XS7",
      "iata": "",
      "name": "Reeder Airport",
      "city": "Lone Oak",
      "state": "Texas",
      "country": "US",
      "elevation": 530,
      "lat": 32.947101593,
      "lon": -95.9586029053,
      "tz": "America\/Chicago"
  },
  "9XS9": {
      "icao": "9XS9",
      "iata": "",
      "name": "Rancho Verde Airport",
      "city": "Brenham",
      "state": "Texas",
      "country": "US",
      "elevation": 275,
      "lat": 30.2833003998,
      "lon": -96.4475021362,
      "tz": "America\/Chicago"
  },
  "AA00": {
      "icao": "AA00",
      "iata": "",
      "name": "Shump Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 1670,
      "lat": 62.51015,
      "lon": -149.926717,
      "tz": "America\/Anchorage"
  },
  "AA05": {
      "icao": "AA05",
      "iata": "",
      "name": "Shelby Strip",
      "city": "Anchorage",
      "state": "Alaska",
      "country": "US",
      "elevation": 40,
      "lat": 61.507811,
      "lon": -149.078481,
      "tz": "America\/Anchorage"
  },
  "AA06": {
      "icao": "AA06",
      "iata": "",
      "name": "Sixmile Lake Airport",
      "city": "Anchorage",
      "state": "Alaska",
      "country": "US",
      "elevation": 85,
      "lat": 61.289722,
      "lon": -149.806111,
      "tz": "America\/Anchorage"
  },
  "AA22": {
      "icao": "AA22",
      "iata": "",
      "name": "Delta Daves Airport",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1070,
      "lat": 64.132858,
      "lon": -145.804494,
      "tz": "America\/Anchorage"
  },
  "AA30": {
      "icao": "AA30",
      "iata": "",
      "name": "Trio Estates Airport",
      "city": "Houston",
      "state": "Alaska",
      "country": "US",
      "elevation": 315,
      "lat": 61.648189,
      "lon": -149.870831,
      "tz": "America\/Anchorage"
  },
  "AA33": {
      "icao": "AA33",
      "iata": "",
      "name": "Gus Landing",
      "city": "Houston",
      "state": "Alaska",
      "country": "US",
      "elevation": 120,
      "lat": 61.60415,
      "lon": -149.770119,
      "tz": "America\/Anchorage"
  },
  "AA50": {
      "icao": "AA50",
      "iata": "",
      "name": "Point Thomson Sea Ice Airstrip",
      "city": "Deadhorse",
      "state": "Alaska",
      "country": "US",
      "elevation": 0,
      "lat": 70.181144,
      "lon": -146.35035,
      "tz": "America\/Anchorage"
  },
  "AA76": {
      "icao": "AA76",
      "iata": "",
      "name": "Timber Trails Airport",
      "city": "North Pole",
      "state": "Alaska",
      "country": "US",
      "elevation": 480,
      "lat": 64.769444,
      "lon": -147.329444,
      "tz": "America\/Anchorage"
  },
  "AAXX": {
      "icao": "AAXX",
      "iata": "",
      "name": "Rothera Point Airport",
      "city": "Rothera Point",
      "state": "",
      "country": "AQ",
      "elevation": 0,
      "lat": -67.5669411575,
      "lon": -68.1269931793,
      "tz": "Antarctica\/Rothera"
  },
  "ADDE": {
      "icao": "ADDE",
      "iata": "ALX",
      "name": "Thomas C Russell Field",
      "city": "Alexander City",
      "state": "Alabama",
      "country": "US",
      "elevation": 686,
      "lat": 32.9146995544,
      "lon": -85.9629974365,
      "tz": "America\/Chicago"
  },
  "AGAF": {
      "icao": "AGAF",
      "iata": "AFT",
      "name": "Afutara Aerodrome",
      "city": "Bila",
      "state": "Malaita",
      "country": "SB",
      "elevation": 23,
      "lat": -9.1913888889,
      "lon": 160.948611111,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGAR": {
      "icao": "AGAR",
      "iata": "RNA",
      "name": "Ulawa Airport",
      "city": "Arona",
      "state": "",
      "country": "SB",
      "elevation": 40,
      "lat": -9.8605435826,
      "lon": 161.979546547,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGAT": {
      "icao": "AGAT",
      "iata": "ATD",
      "name": "Uru Harbour Airport",
      "city": "Atoifi",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -8.8733301163,
      "lon": 161.0110015869,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGBA": {
      "icao": "AGBA",
      "iata": "VEV",
      "name": "Barakoma Airport",
      "city": "Barakoma",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -7.912779808,
      "lon": 156.7059936523,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGBT": {
      "icao": "AGBT",
      "iata": "BPF",
      "name": "Batuna Aerodrome",
      "city": "Batuna Mission Station",
      "state": "Western Province",
      "country": "SB",
      "elevation": 24,
      "lat": -8.5620277778,
      "lon": 158.119305556,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGEV": {
      "icao": "AGEV",
      "iata": "GEF",
      "name": "Geva Airport",
      "city": "Liangia",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -7.5758299828,
      "lon": 156.5970001221,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGA": {
      "icao": "AGGA",
      "iata": "AKS",
      "name": "Auki Airport",
      "city": "Auki",
      "state": "",
      "country": "SB",
      "elevation": 5,
      "lat": -8.7025699616,
      "lon": 160.682006836,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGB": {
      "icao": "AGGB",
      "iata": "BNY",
      "name": "Bellona\/Anua Airport",
      "city": "Anua",
      "state": "",
      "country": "SB",
      "elevation": 60,
      "lat": -11.3022222222,
      "lon": 159.798333333,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGC": {
      "icao": "AGGC",
      "iata": "CHY",
      "name": "Choiseul Bay Airport",
      "city": "",
      "state": "Choiseul",
      "country": "SB",
      "elevation": 0,
      "lat": -6.711944,
      "lon": 156.396111,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGE": {
      "icao": "AGGE",
      "iata": "BAS",
      "name": "Ballalae Airport",
      "city": "Ballalae",
      "state": "",
      "country": "SB",
      "elevation": 5,
      "lat": -6.990745,
      "lon": 155.886656,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGF": {
      "icao": "AGGF",
      "iata": "FRE",
      "name": "Fera\/Maringe Airport",
      "city": "Fera Island",
      "state": "Isabel",
      "country": "SB",
      "elevation": 0,
      "lat": -8.1075000763,
      "lon": 159.5769958496,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGH": {
      "icao": "AGGH",
      "iata": "HIR",
      "name": "Honiara International Airport",
      "city": "Honiara",
      "state": "Guadalcanal",
      "country": "SB",
      "elevation": 28,
      "lat": -9.4280004501,
      "lon": 160.0549926758,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGI": {
      "icao": "AGGI",
      "iata": "MBU",
      "name": "Babanakira Airport",
      "city": "Mbambanakira",
      "state": "Guadalcanal",
      "country": "SB",
      "elevation": 0,
      "lat": -9.7475004196,
      "lon": 159.8390045166,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGJ": {
      "icao": "AGGJ",
      "iata": "AVU",
      "name": "Avu Avu Airport",
      "city": "",
      "state": "Guadalcanal",
      "country": "SB",
      "elevation": 25,
      "lat": -9.8683333333,
      "lon": 160.410555556,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGK": {
      "icao": "AGGK",
      "iata": "IRA",
      "name": "Ngorangora Airport",
      "city": "Kirakira",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -10.4497003555,
      "lon": 161.8979949951,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGL": {
      "icao": "AGGL",
      "iata": "SCZ",
      "name": "Santa Cruz\/Graciosa Bay\/Luova Airport",
      "city": "Santa Cruz\/Graciosa Bay\/Luova",
      "state": "Temotu",
      "country": "SB",
      "elevation": 18,
      "lat": -10.7202997208,
      "lon": 165.7949981689,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGM": {
      "icao": "AGGM",
      "iata": "MUA",
      "name": "Munda Airport",
      "city": "",
      "state": "",
      "country": "SB",
      "elevation": 10,
      "lat": -8.3279695511,
      "lon": 157.2630004883,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGN": {
      "icao": "AGGN",
      "iata": "GZO",
      "name": "Nusatupe Airport",
      "city": "Gizo",
      "state": "",
      "country": "SB",
      "elevation": 13,
      "lat": -8.0977802277,
      "lon": 156.863998413,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGO": {
      "icao": "AGGO",
      "iata": "MNY",
      "name": "Mono Airport",
      "city": "Stirling Island",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -7.4169402122,
      "lon": 155.5650024414,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGP": {
      "icao": "AGGP",
      "iata": "PRS",
      "name": "Parasi Airport",
      "city": "Parasi",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -9.6416702271,
      "lon": 161.4250030518,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGR": {
      "icao": "AGGR",
      "iata": "RNL",
      "name": "Rennell\/Tingoa Airport",
      "city": "Rennell Island",
      "state": "Rennell and Bellona",
      "country": "SB",
      "elevation": 0,
      "lat": -11.5339002609,
      "lon": 160.06300354,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGS": {
      "icao": "AGGS",
      "iata": "EGM",
      "name": "Sege Airport",
      "city": "Sege",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -8.5788898468,
      "lon": 157.8760070801,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGT": {
      "icao": "AGGT",
      "iata": "NNB",
      "name": "Santa Ana Airport",
      "city": "Santa Ana Island",
      "state": "",
      "country": "SB",
      "elevation": 3,
      "lat": -10.847994,
      "lon": 162.454108,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGU": {
      "icao": "AGGU",
      "iata": "RUS",
      "name": "Marau Airport",
      "city": "Marau",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -9.8616695404,
      "lon": 160.824996948,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGV": {
      "icao": "AGGV",
      "iata": "VAO",
      "name": "Suavanao Airport",
      "city": "Suavanao",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -7.585559845,
      "lon": 158.7310028076,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGGY": {
      "icao": "AGGY",
      "iata": "XYA",
      "name": "Yandina Airport",
      "city": "Yandina",
      "state": "",
      "country": "SB",
      "elevation": 60,
      "lat": -9.092816,
      "lon": 159.21841,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGKG": {
      "icao": "AGKG",
      "iata": "KGE",
      "name": "Kagau Island Airport",
      "city": "Kagau Island",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -7.3330001831,
      "lon": 157.5829925537,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGKW": {
      "icao": "AGKW",
      "iata": "KWS",
      "name": "Kwailabesi Airport",
      "city": "Kwailabesi",
      "state": "",
      "country": "SB",
      "elevation": 26,
      "lat": -8.360508,
      "lon": 160.775127,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGOK": {
      "icao": "AGOK",
      "iata": "GTA",
      "name": "Gatokae Airport",
      "city": "Gatokae",
      "state": "",
      "country": "SB",
      "elevation": 70,
      "lat": -8.739167,
      "lon": 158.203056,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGRC": {
      "icao": "AGRC",
      "iata": "RIN",
      "name": "Ringi Cove Airport",
      "city": "Ringi Cove",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -8.1263904572,
      "lon": 157.1430053711,
      "tz": "Pacific\/Guadalcanal"
  },
  "AGRM": {
      "icao": "AGRM",
      "iata": "RBV",
      "name": "Ramata Airport",
      "city": "Ramata",
      "state": "",
      "country": "SB",
      "elevation": 0,
      "lat": -8.1680603027,
      "lon": 157.6430053711,
      "tz": "Pacific\/Guadalcanal"
  },
  "AK00": {
      "icao": "AK00",
      "iata": "",
      "name": "Anchor River Airpark",
      "city": "Anchor Point",
      "state": "Alaska",
      "country": "US",
      "elevation": 120,
      "lat": 59.7966995239,
      "lon": -151.8630065918,
      "tz": "America\/Anchorage"
  },
  "AK01": {
      "icao": "AK01",
      "iata": "",
      "name": "Alaska Airpark",
      "city": "Sterling",
      "state": "Alaska",
      "country": "US",
      "elevation": 320,
      "lat": 60.528983,
      "lon": -150.625133,
      "tz": "America\/Anchorage"
  },
  "AK02": {
      "icao": "AK02",
      "iata": "",
      "name": "Bear Creek 1 Airport",
      "city": "Bear Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 575,
      "lat": 65.5399017334,
      "lon": -161.06199646,
      "tz": "America\/Anchorage"
  },
  "AK05": {
      "icao": "AK05",
      "iata": "",
      "name": "Breeden Airport",
      "city": "Sterling",
      "state": "Alaska",
      "country": "US",
      "elevation": 365,
      "lat": 60.54105,
      "lon": -150.599217,
      "tz": "America\/Anchorage"
  },
  "AK06": {
      "icao": "AK06",
      "iata": "",
      "name": "Denali Airport",
      "city": "Mckinley Park",
      "state": "Alaska",
      "country": "US",
      "elevation": 2050,
      "lat": 63.6450996399,
      "lon": -148.7980041504,
      "tz": "America\/Anchorage"
  },
  "AK07": {
      "icao": "AK07",
      "iata": "",
      "name": "Dog Fish Bay Airport",
      "city": "Nanwalek",
      "state": "Alaska",
      "country": "US",
      "elevation": 10,
      "lat": 59.2410011292,
      "lon": -151.8769989014,
      "tz": "America\/Anchorage"
  },
  "AK08": {
      "icao": "AK08",
      "iata": "",
      "name": "Thomas Strip",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 190,
      "lat": 61.7899017334,
      "lon": -150.1060028076,
      "tz": "America\/Anchorage"
  },
  "AK09": {
      "icao": "AK09",
      "iata": "",
      "name": "Wingsong Estates Airport",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1100,
      "lat": 64.0495986938,
      "lon": -145.5019989014,
      "tz": "America\/Anchorage"
  },
  "AK10": {
      "icao": "AK10",
      "iata": "",
      "name": "Ernies Airstrip",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 210,
      "lat": 61.7172012329,
      "lon": -150.10899353,
      "tz": "America\/Anchorage"
  },
  "AK11": {
      "icao": "AK11",
      "iata": "",
      "name": "Point Mcintyre Airport",
      "city": "Point Mcintyre",
      "state": "Alaska",
      "country": "US",
      "elevation": 15,
      "lat": 70.4017028809,
      "lon": -148.6820068359,
      "tz": "America\/Anchorage"
  },
  "AK12": {
      "icao": "AK12",
      "iata": "",
      "name": "Flying Crown Airport",
      "city": "Anchorage",
      "state": "Alaska",
      "country": "US",
      "elevation": 47,
      "lat": 61.1066017151,
      "lon": -149.8639984131,
      "tz": "America\/Anchorage"
  },
  "AK13": {
      "icao": "AK13",
      "iata": "CEX",
      "name": "Chena Hot Springs Airport",
      "city": "Chena Hot Springs",
      "state": "Alaska",
      "country": "US",
      "elevation": 1195,
      "lat": 65.0518035889,
      "lon": -146.04699707,
      "tz": "America\/Anchorage"
  },
  "AK14": {
      "icao": "AK14",
      "iata": "",
      "name": "Turinsky Airstrip",
      "city": "Point Mackenzie",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 61.3512992859,
      "lon": -150.0890045166,
      "tz": "America\/Anchorage"
  },
  "AK16": {
      "icao": "AK16",
      "iata": "",
      "name": "Sunset Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 303,
      "lat": 61.52339935,
      "lon": -149.7149963,
      "tz": "America\/Anchorage"
  },
  "AK17": {
      "icao": "AK17",
      "iata": "",
      "name": "Glacierview Strip",
      "city": "Homer",
      "state": "Alaska",
      "country": "US",
      "elevation": 1600,
      "lat": 59.7635993958,
      "lon": -151.2230072021,
      "tz": "America\/Anchorage"
  },
  "AK18": {
      "icao": "AK18",
      "iata": "",
      "name": "Camp Point Airport",
      "city": "Homer",
      "state": "Alaska",
      "country": "US",
      "elevation": 8,
      "lat": 59.8284988403,
      "lon": -153.0809936523,
      "tz": "America\/Anchorage"
  },
  "AK19": {
      "icao": "AK19",
      "iata": "",
      "name": "Carl's Landing Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 62.1739006042,
      "lon": -150.0639953613,
      "tz": "America\/Anchorage"
  },
  "AK20": {
      "icao": "AK20",
      "iata": "",
      "name": "Cd-3 Airstrip",
      "city": "Nuiqsut",
      "state": "Alaska",
      "country": "US",
      "elevation": 7,
      "lat": 70.420622,
      "lon": -150.886667,
      "tz": "America\/Anchorage"
  },
  "AK21": {
      "icao": "AK21",
      "iata": "",
      "name": "Nushagak Airport",
      "city": "Dillingham",
      "state": "Alaska",
      "country": "US",
      "elevation": 40,
      "lat": 59.132598877,
      "lon": -157.7769927979,
      "tz": "America\/Anchorage"
  },
  "AK22": {
      "icao": "AK22",
      "iata": "",
      "name": "Lakloey Air Park",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 475,
      "lat": 64.8217010498,
      "lon": -147.5220031738,
      "tz": "America\/Anchorage"
  },
  "AK24": {
      "icao": "AK24",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Chugiak",
      "state": "Alaska",
      "country": "US",
      "elevation": 420,
      "lat": 61.41820145,
      "lon": -149.4389954,
      "tz": "America\/Anchorage"
  },
  "AK25": {
      "icao": "AK25",
      "iata": "",
      "name": "Piper Landing Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 350,
      "lat": 61.6175003052,
      "lon": -149.6150054932,
      "tz": "America\/Anchorage"
  },
  "AK26": {
      "icao": "AK26",
      "iata": "",
      "name": "Solomon State Field",
      "city": "Solomon",
      "state": "Alaska",
      "country": "US",
      "elevation": 25,
      "lat": 64.5606002808,
      "lon": -164.4420013428,
      "tz": "America\/Nome"
  },
  "AK27": {
      "icao": "AK27",
      "iata": "",
      "name": "Valley Flying Crown Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 400,
      "lat": 61.6426010132,
      "lon": -149.6239929199,
      "tz": "America\/Anchorage"
  },
  "AK28": {
      "icao": "AK28",
      "iata": "",
      "name": "Chena Marina Airport",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 427,
      "lat": 64.8140029907,
      "lon": -147.9190063477,
      "tz": "America\/Anchorage"
  },
  "AK29": {
      "icao": "AK29",
      "iata": "",
      "name": "Reids Landing Airport",
      "city": "Houston",
      "state": "Alaska",
      "country": "US",
      "elevation": 246,
      "lat": 61.6125984192,
      "lon": -149.8520050049,
      "tz": "America\/Anchorage"
  },
  "AK30": {
      "icao": "AK30",
      "iata": "",
      "name": "Strawberry Point Airport",
      "city": "Cordova",
      "state": "Alaska",
      "country": "US",
      "elevation": 50,
      "lat": 60.391153,
      "lon": -146.097464,
      "tz": "America\/Anchorage"
  },
  "AK31": {
      "icao": "AK31",
      "iata": "",
      "name": "Swift Creek Airport",
      "city": "Mccarthy",
      "state": "Alaska",
      "country": "US",
      "elevation": 1225,
      "lat": 61.4112014771,
      "lon": -143.0010070801,
      "tz": "America\/Anchorage"
  },
  "AK33": {
      "icao": "AK33",
      "iata": "HED",
      "name": "Herendeen Bay Airport",
      "city": "Herendeen Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 20,
      "lat": 55.801399231,
      "lon": -160.899002075,
      "tz": "America\/Anchorage"
  },
  "AK36": {
      "icao": "AK36",
      "iata": "",
      "name": "Point Mac Airport",
      "city": "Point Mackenzie",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.436000824,
      "lon": -150.1219940186,
      "tz": "America\/Anchorage"
  },
  "AK39": {
      "icao": "AK39",
      "iata": "",
      "name": "South Gasline Airport",
      "city": "Sterling",
      "state": "Alaska",
      "country": "US",
      "elevation": 381,
      "lat": 60.6972007751,
      "lon": -150.1799926758,
      "tz": "America\/Anchorage"
  },
  "AK40": {
      "icao": "AK40",
      "iata": "",
      "name": "Nixon Fork Mine Airport",
      "city": "McGrath",
      "state": "Alaska",
      "country": "US",
      "elevation": 1510,
      "lat": 63.2291984558,
      "lon": -154.759994507,
      "tz": "America\/Anchorage"
  },
  "AK41": {
      "icao": "AK41",
      "iata": "",
      "name": "Greg'n Sage Airport",
      "city": "North Pole",
      "state": "Alaska",
      "country": "US",
      "elevation": 925,
      "lat": 64.5438995361,
      "lon": -146.8439941406,
      "tz": "America\/Anchorage"
  },
  "AK44": {
      "icao": "AK44",
      "iata": "",
      "name": "Talkeetna Village Strip",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 346,
      "lat": 62.318901062,
      "lon": -150.1159973145,
      "tz": "America\/Anchorage"
  },
  "AK45": {
      "icao": "AK45",
      "iata": "",
      "name": "Lost River 2 Airport",
      "city": "Lost River",
      "state": "Alaska",
      "country": "US",
      "elevation": 220,
      "lat": 65.456199646,
      "lon": -167.1750030518,
      "tz": "America\/Nome"
  },
  "AK46": {
      "icao": "AK46",
      "iata": "",
      "name": "Abi Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 750,
      "lat": 61.6287994385,
      "lon": -149.0429992676,
      "tz": "America\/Anchorage"
  },
  "AK47": {
      "icao": "AK47",
      "iata": "",
      "name": "Shawn Field",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.5857009888,
      "lon": -149.56300354,
      "tz": "America\/Anchorage"
  },
  "AK49": {
      "icao": "AK49",
      "iata": "TWE",
      "name": "Taylor Airport",
      "city": "Taylor",
      "state": "Alaska",
      "country": "US",
      "elevation": 440,
      "lat": 65.6792984009,
      "lon": -164.798995972,
      "tz": "America\/Nome"
  },
  "AK50": {
      "icao": "AK50",
      "iata": "",
      "name": "Sky Ranch At Pioneer Peak Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 120,
      "lat": 61.554599762,
      "lon": -149.1439971924,
      "tz": "America\/Anchorage"
  },
  "AK51": {
      "icao": "AK51",
      "iata": "",
      "name": "Wilder\/Natwick Llc Airport",
      "city": "Port Alsworth",
      "state": "Alaska",
      "country": "US",
      "elevation": 288,
      "lat": 60.198600769,
      "lon": -154.3249969482,
      "tz": "America\/Anchorage"
  },
  "AK52": {
      "icao": "AK52",
      "iata": "",
      "name": "Moen's Ranch Airport",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 582,
      "lat": 64.8889007568,
      "lon": -147.5359954834,
      "tz": "America\/Anchorage"
  },
  "AK53": {
      "icao": "AK53",
      "iata": "",
      "name": "Downwind Landing Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 220,
      "lat": 61.5822982788,
      "lon": -148.9969940186,
      "tz": "America\/Anchorage"
  },
  "AK54": {
      "icao": "AK54",
      "iata": "",
      "name": "Stro's Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 340,
      "lat": 61.6571006775,
      "lon": -149.3260040283,
      "tz": "America\/Anchorage"
  },
  "AK55": {
      "icao": "AK55",
      "iata": "",
      "name": "Moose Run Airstrip",
      "city": "Soldotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 290,
      "lat": 60.489200592,
      "lon": -150.8020019531,
      "tz": "America\/Anchorage"
  },
  "AK58": {
      "icao": "AK58",
      "iata": "",
      "name": "Fairview West Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 73,
      "lat": 61.5400009155,
      "lon": -149.5489959717,
      "tz": "America\/Anchorage"
  },
  "AK59": {
      "icao": "AK59",
      "iata": "",
      "name": "King Ranch Airport",
      "city": "Sutton",
      "state": "Alaska",
      "country": "US",
      "elevation": 1350,
      "lat": 61.7956008911,
      "lon": -148.3549957275,
      "tz": "America\/Anchorage"
  },
  "AK60": {
      "icao": "AK60",
      "iata": "",
      "name": "Fort Jensen Airport",
      "city": "Jensens",
      "state": "Alaska",
      "country": "US",
      "elevation": 240,
      "lat": 57.8851013184,
      "lon": -157.0970001221,
      "tz": "America\/Anchorage"
  },
  "AK61": {
      "icao": "AK61",
      "iata": "",
      "name": "Stephan Lake Lodge Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 1900,
      "lat": 62.6996002197,
      "lon": -148.9069976807,
      "tz": "America\/Anchorage"
  },
  "AK63": {
      "icao": "AK63",
      "iata": "",
      "name": "Pat-Mar Strip",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 246,
      "lat": 61.5886001587,
      "lon": -149.1430053711,
      "tz": "America\/Anchorage"
  },
  "AK64": {
      "icao": "AK64",
      "iata": "",
      "name": "Taylor Mountain Airport",
      "city": "Taylor Mountain Mine",
      "state": "Alaska",
      "country": "US",
      "elevation": 1000,
      "lat": 60.8678016663,
      "lon": -157.391998291,
      "tz": "America\/Anchorage"
  },
  "AK65": {
      "icao": "AK65",
      "iata": "",
      "name": "Green's Strip",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 300,
      "lat": 61.5979003906,
      "lon": -149.3509979248,
      "tz": "America\/Anchorage"
  },
  "AK66": {
      "icao": "AK66",
      "iata": "",
      "name": "Hunter Creek Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 61.4631004333,
      "lon": -148.8040008545,
      "tz": "America\/Anchorage"
  },
  "AK67": {
      "icao": "AK67",
      "iata": "",
      "name": "Skid Marks Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 64,
      "lat": 61.7386016846,
      "lon": -150.0729980469,
      "tz": "America\/Anchorage"
  },
  "AK68": {
      "icao": "AK68",
      "iata": "",
      "name": "Minuteman Strip",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 290,
      "lat": 61.7202987671,
      "lon": -150.0540008545,
      "tz": "America\/Anchorage"
  },
  "AK69": {
      "icao": "AK69",
      "iata": "",
      "name": "Long Lake Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 200,
      "lat": 61.7293014526,
      "lon": -150.0980072021,
      "tz": "America\/Anchorage"
  },
  "AK72": {
      "icao": "AK72",
      "iata": "",
      "name": "Jewell Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 113,
      "lat": 61.70470047,
      "lon": -150.6009979248,
      "tz": "America\/Anchorage"
  },
  "AK73": {
      "icao": "AK73",
      "iata": "",
      "name": "Mcgahan Industrial Airpark",
      "city": "Nikishka",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 60.7252998352,
      "lon": -151.3020019531,
      "tz": "America\/Anchorage"
  },
  "AK75": {
      "icao": "AK75",
      "iata": "CDL",
      "name": "Candle 2 Airport",
      "city": "Candle",
      "state": "Alaska",
      "country": "US",
      "elevation": 15,
      "lat": 65.907699585,
      "lon": -161.925994873,
      "tz": "America\/Anchorage"
  },
  "AK76": {
      "icao": "AK76",
      "iata": "",
      "name": "East Alsek River Airport",
      "city": "Yakutat",
      "state": "Alaska",
      "country": "US",
      "elevation": 20,
      "lat": 59.1260986328,
      "lon": -138.4069976807,
      "tz": "America\/Yakutat"
  },
  "AK77": {
      "icao": "AK77",
      "iata": "",
      "name": "All West Airport",
      "city": "Delta Junction",
      "state": "Alaska",
      "country": "US",
      "elevation": 1275,
      "lat": 63.9413986206,
      "lon": -145.4219970703,
      "tz": "America\/Anchorage"
  },
  "AK79": {
      "icao": "AK79",
      "iata": "",
      "name": "Jolly Field",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 61.59355,
      "lon": -149.5665,
      "tz": "America\/Anchorage"
  },
  "AK80": {
      "icao": "AK80",
      "iata": "",
      "name": "American Creek Airport",
      "city": "American Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 500,
      "lat": 65.1016998291,
      "lon": -151.1770019531,
      "tz": "America\/Anchorage"
  },
  "AK83": {
      "icao": "AK83",
      "iata": "",
      "name": "Gannon's Landing Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 380,
      "lat": 61.6273002625,
      "lon": -149.6089935303,
      "tz": "America\/Anchorage"
  },
  "AK84": {
      "icao": "AK84",
      "iata": "",
      "name": "Scooter's Landing Strip",
      "city": "Sterling",
      "state": "Alaska",
      "country": "US",
      "elevation": 259,
      "lat": 60.5293998718,
      "lon": -150.8309936523,
      "tz": "America\/Anchorage"
  },
  "AK85": {
      "icao": "AK85",
      "iata": "",
      "name": "Birchwater Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 110,
      "lat": 61.482333,
      "lon": -149.763986,
      "tz": "America\/Anchorage"
  },
  "AK86": {
      "icao": "AK86",
      "iata": "",
      "name": "Kramer Airport",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 150,
      "lat": 61.573539,
      "lon": -149.91135,
      "tz": "America\/Anchorage"
  },
  "AK88": {
      "icao": "AK88",
      "iata": "",
      "name": "Eagle Nest Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 416,
      "lat": 61.6046981812,
      "lon": -149.0399932861,
      "tz": "America\/Anchorage"
  },
  "AK89": {
      "icao": "AK89",
      "iata": "",
      "name": "Black Spruce Airport",
      "city": "Houston",
      "state": "Alaska",
      "country": "US",
      "elevation": 350,
      "lat": 61.6570014954,
      "lon": -149.8370056152,
      "tz": "America\/Anchorage"
  },
  "AK90": {
      "icao": "AK90",
      "iata": "",
      "name": "Shirley Lake Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 185,
      "lat": 61.7447013855,
      "lon": -150.1150054932,
      "tz": "America\/Anchorage"
  },
  "AK92": {
      "icao": "AK92",
      "iata": "",
      "name": "Martin Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 820,
      "lat": 61.63800049,
      "lon": -149.029007,
      "tz": "America\/Anchorage"
  },
  "AK93": {
      "icao": "AK93",
      "iata": "",
      "name": "Grouse Ridge Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 535,
      "lat": 61.6552009583,
      "lon": -149.2729949951,
      "tz": "America\/Anchorage"
  },
  "AK95": {
      "icao": "AK95",
      "iata": "",
      "name": "Twin Lake Airport",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 185,
      "lat": 61.5698013306,
      "lon": -149.779006958,
      "tz": "America\/Anchorage"
  },
  "AK96": {
      "icao": "AK96",
      "iata": "BSZ",
      "name": "Bartletts Airport",
      "city": "Egegik",
      "state": "Alaska",
      "country": "US",
      "elevation": 50,
      "lat": 58.2162017822,
      "lon": -157.352005005,
      "tz": "America\/Anchorage"
  },
  "AK97": {
      "icao": "AK97",
      "iata": "BSW",
      "name": "Boswell Bay Airport",
      "city": "Boswell Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 230,
      "lat": 60.4230995178,
      "lon": -146.145996094,
      "tz": "America\/Anchorage"
  },
  "AK98": {
      "icao": "AK98",
      "iata": "",
      "name": "Buck Creek Airport",
      "city": "Buck Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 560,
      "lat": 65.6387023926,
      "lon": -167.4859924316,
      "tz": "America\/Nome"
  },
  "AK99": {
      "icao": "AK99",
      "iata": "",
      "name": "Grand Home Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 85,
      "lat": 61.446667,
      "lon": -149.761111,
      "tz": "America\/Anchorage"
  },
  "AL00": {
      "icao": "AL00",
      "iata": "",
      "name": "Charlie Wilkes Airport",
      "city": "Fayette",
      "state": "Alabama",
      "country": "US",
      "elevation": 340,
      "lat": 33.7444000244,
      "lon": -87.7863998413,
      "tz": "America\/Chicago"
  },
  "AL01": {
      "icao": "AL01",
      "iata": "",
      "name": "Bedsole Farm Airport",
      "city": "Claiborne",
      "state": "Alabama",
      "country": "US",
      "elevation": 180,
      "lat": 31.5585002899,
      "lon": -87.5333023071,
      "tz": "America\/Chicago"
  },
  "AL02": {
      "icao": "AL02",
      "iata": "",
      "name": "Rainey Field",
      "city": "Cottondale",
      "state": "Alabama",
      "country": "US",
      "elevation": 300,
      "lat": 33.150100708,
      "lon": -87.4417037964,
      "tz": "America\/Chicago"
  },
  "AL03": {
      "icao": "AL03",
      "iata": "",
      "name": "Strickland\/Smalley Field",
      "city": "Akron",
      "state": "Tennessee",
      "country": "US",
      "elevation": 369,
      "lat": 35.853889,
      "lon": -87.723889,
      "tz": "America\/Chicago"
  },
  "AL04": {
      "icao": "AL04",
      "iata": "",
      "name": "Pleasant View Farm Airport",
      "city": "Elberta",
      "state": "Alabama",
      "country": "US",
      "elevation": 105,
      "lat": 30.4626998901,
      "lon": -87.5250015259,
      "tz": "America\/Chicago"
  },
  "AL05": {
      "icao": "AL05",
      "iata": "",
      "name": "Sehoy Airport",
      "city": "Hurtsboro",
      "state": "Alabama",
      "country": "US",
      "elevation": 331,
      "lat": 32.2201004028,
      "lon": -85.4680023193,
      "tz": "America\/Chicago"
  },
  "AL08": {
      "icao": "AL08",
      "iata": "",
      "name": "Perdido Winds Airpark",
      "city": "Elberta",
      "state": "Alabama",
      "country": "US",
      "elevation": 75,
      "lat": 30.4244003296,
      "lon": -87.5353012085,
      "tz": "America\/Chicago"
  },
  "AL09": {
      "icao": "AL09",
      "iata": "",
      "name": "Cloudmont Airpark",
      "city": "Mentone",
      "state": "Alabama",
      "country": "US",
      "elevation": 1692,
      "lat": 34.5334014893,
      "lon": -85.5902023315,
      "tz": "America\/Chicago"
  },
  "AL10": {
      "icao": "AL10",
      "iata": "",
      "name": "Frerichs Airport",
      "city": "Gurley",
      "state": "Alabama",
      "country": "US",
      "elevation": 650,
      "lat": 34.6511993408,
      "lon": -86.4169006348,
      "tz": "America\/Chicago"
  },
  "AL12": {
      "icao": "AL12",
      "iata": "",
      "name": "Kershaw Airport",
      "city": "Montgomery",
      "state": "Alabama",
      "country": "US",
      "elevation": 225,
      "lat": 32.3585014343,
      "lon": -86.125,
      "tz": "America\/Chicago"
  },
  "AL15": {
      "icao": "AL15",
      "iata": "",
      "name": "Styron Airport",
      "city": "Foley",
      "state": "Alabama",
      "country": "US",
      "elevation": 120,
      "lat": 30.4029998779,
      "lon": -87.7241973877,
      "tz": "America\/Chicago"
  },
  "AL16": {
      "icao": "AL16",
      "iata": "",
      "name": "Turkey Creek Airport",
      "city": "Pinson",
      "state": "Alabama",
      "country": "US",
      "elevation": 640,
      "lat": 33.7267990112,
      "lon": -86.7236022949,
      "tz": "America\/Chicago"
  },
  "AL17": {
      "icao": "AL17",
      "iata": "",
      "name": "Lazy Eight Airpark Llc Airport",
      "city": "Wilsonville",
      "state": "Alabama",
      "country": "US",
      "elevation": 450,
      "lat": 33.2340011597,
      "lon": -86.5130004883,
      "tz": "America\/Chicago"
  },
  "AL18": {
      "icao": "AL18",
      "iata": "",
      "name": "Parker Field",
      "city": "Gardendale",
      "state": "Alabama",
      "country": "US",
      "elevation": 590,
      "lat": 33.6762008667,
      "lon": -86.8675003052,
      "tz": "America\/Chicago"
  },
  "AL25": {
      "icao": "AL25",
      "iata": "",
      "name": "Spratling Field",
      "city": "Roba",
      "state": "Alabama",
      "country": "US",
      "elevation": 368,
      "lat": 32.242401123,
      "lon": -85.6010971069,
      "tz": "America\/Chicago"
  },
  "AL27": {
      "icao": "AL27",
      "iata": "",
      "name": "Headquarters Airport",
      "city": "Centre",
      "state": "Alabama",
      "country": "US",
      "elevation": 610,
      "lat": 34.1057014465,
      "lon": -85.5658035278,
      "tz": "America\/Chicago"
  },
  "AL29": {
      "icao": "AL29",
      "iata": "",
      "name": "New Horizon Airport",
      "city": "Blountsville",
      "state": "Alabama",
      "country": "US",
      "elevation": 750,
      "lat": 34.1292991638,
      "lon": -86.6333007813,
      "tz": "America\/Chicago"
  },
  "AL32": {
      "icao": "AL32",
      "iata": "",
      "name": "Flying M Ranch Airport",
      "city": "Cedar Bluff",
      "state": "Alabama",
      "country": "US",
      "elevation": 670,
      "lat": 34.3226013184,
      "lon": -85.643699646,
      "tz": "America\/Chicago"
  },
  "AL33": {
      "icao": "AL33",
      "iata": "",
      "name": "Sturdy Oak Farm Airport",
      "city": "Perdido",
      "state": "Alabama",
      "country": "US",
      "elevation": 230,
      "lat": 31.0251998901,
      "lon": -87.5858001709,
      "tz": "America\/Chicago"
  },
  "AL34": {
      "icao": "AL34",
      "iata": "",
      "name": "Henson Field",
      "city": "Moulton",
      "state": "Alabama",
      "country": "US",
      "elevation": 685,
      "lat": 34.4469985962,
      "lon": -87.386100769,
      "tz": "America\/Chicago"
  },
  "AL35": {
      "icao": "AL35",
      "iata": "",
      "name": "Mc Ginnis Airport",
      "city": "Loxley",
      "state": "Alabama",
      "country": "US",
      "elevation": 125,
      "lat": 30.619600296,
      "lon": -87.7043991089,
      "tz": "America\/Chicago"
  },
  "AL37": {
      "icao": "AL37",
      "iata": "",
      "name": "Benedick Airport",
      "city": "Jack",
      "state": "Alabama",
      "country": "US",
      "elevation": 335,
      "lat": 31.555028,
      "lon": -85.977278,
      "tz": "America\/Chicago"
  },
  "AL40": {
      "icao": "AL40",
      "iata": "",
      "name": "Bonham Airport",
      "city": "Birmingham",
      "state": "Alabama",
      "country": "US",
      "elevation": 600,
      "lat": 33.5432014465,
      "lon": -86.9858016968,
      "tz": "America\/Chicago"
  },
  "AL41": {
      "icao": "AL41",
      "iata": "",
      "name": "Freedom Field",
      "city": "Slocomb",
      "state": "Alabama",
      "country": "US",
      "elevation": 285,
      "lat": 31.1159992218,
      "lon": -85.62550354,
      "tz": "America\/Chicago"
  },
  "AL43": {
      "icao": "AL43",
      "iata": "",
      "name": "Henley Ranch Airport",
      "city": "Geiger",
      "state": "Alabama",
      "country": "US",
      "elevation": 200,
      "lat": 32.9085006714,
      "lon": -88.3333969116,
      "tz": "America\/Chicago"
  },
  "AL51": {
      "icao": "AL51",
      "iata": "",
      "name": "Flying C's Plantation Airport",
      "city": "Crawford",
      "state": "Alabama",
      "country": "US",
      "elevation": 395,
      "lat": 32.4160995483,
      "lon": -85.2992019653,
      "tz": "America\/Chicago"
  },
  "AL54": {
      "icao": "AL54",
      "iata": "",
      "name": "Bird Nest Airport",
      "city": "Winfield",
      "state": "Alabama",
      "country": "US",
      "elevation": 420,
      "lat": 33.8633003235,
      "lon": -87.8452987671,
      "tz": "America\/Chicago"
  },
  "AL55": {
      "icao": "AL55",
      "iata": "",
      "name": "Shields Airport",
      "city": "Elberta",
      "state": "Alabama",
      "country": "US",
      "elevation": 95,
      "lat": 30.4419002533,
      "lon": -87.4638977051,
      "tz": "America\/Chicago"
  },
  "AL56": {
      "icao": "AL56",
      "iata": "",
      "name": "Jones Light Aviation Airport",
      "city": "Smiths",
      "state": "Alabama",
      "country": "US",
      "elevation": 440,
      "lat": 32.5000991821,
      "lon": -85.0832977295,
      "tz": "America\/New_York"
  },
  "AL60": {
      "icao": "AL60",
      "iata": "",
      "name": "Dugger's Field",
      "city": "Ashville",
      "state": "Alabama",
      "country": "US",
      "elevation": 560,
      "lat": 33.8386993408,
      "lon": -86.2114028931,
      "tz": "America\/Chicago"
  },
  "AL61": {
      "icao": "AL61",
      "iata": "",
      "name": "Belforest Airport",
      "city": "Daphne",
      "state": "Alabama",
      "country": "US",
      "elevation": 140,
      "lat": 30.6168994904,
      "lon": -87.8582992554,
      "tz": "America\/Chicago"
  },
  "AL62": {
      "icao": "AL62",
      "iata": "",
      "name": "Striplin Airfield",
      "city": "Hartford",
      "state": "Alabama",
      "country": "US",
      "elevation": 255,
      "lat": 31.0830001831,
      "lon": -85.6835021973,
      "tz": "America\/Chicago"
  },
  "AL68": {
      "icao": "AL68",
      "iata": "",
      "name": "Willis Airport",
      "city": "Piedmont",
      "state": "Alabama",
      "country": "US",
      "elevation": 790,
      "lat": 33.8512001038,
      "lon": -85.6630020142,
      "tz": "America\/Chicago"
  },
  "AL69": {
      "icao": "AL69",
      "iata": "",
      "name": "Vaughn Private Airport",
      "city": "Summerdale",
      "state": "Alabama",
      "country": "US",
      "elevation": 30,
      "lat": 30.5084991455,
      "lon": -87.4916000366,
      "tz": "America\/Chicago"
  },
  "AL71": {
      "icao": "AL71",
      "iata": "",
      "name": "Willow Point Airport",
      "city": "Our Town",
      "state": "Alabama",
      "country": "US",
      "elevation": 551,
      "lat": 32.8036994934,
      "lon": -85.9804992676,
      "tz": "America\/Chicago"
  },
  "AL72": {
      "icao": "AL72",
      "iata": "",
      "name": "Palmer Field",
      "city": "Madison",
      "state": "Alabama",
      "country": "US",
      "elevation": 680,
      "lat": 34.695400238,
      "lon": -86.7861022949,
      "tz": "America\/Chicago"
  },
  "AL73": {
      "icao": "AL73",
      "iata": "",
      "name": "Sharpe Field",
      "city": "Tuskegee",
      "state": "Alabama",
      "country": "US",
      "elevation": 253,
      "lat": 32.4919013977,
      "lon": -85.7755966187,
      "tz": "America\/Chicago"
  },
  "AL74": {
      "icao": "AL74",
      "iata": "",
      "name": "Grimes Field",
      "city": "Semmes",
      "state": "Alabama",
      "country": "US",
      "elevation": 215,
      "lat": 30.7644004822,
      "lon": -88.3058013916,
      "tz": "America\/Chicago"
  },
  "AL76": {
      "icao": "AL76",
      "iata": "",
      "name": "Mayfield (Private) Airport",
      "city": "Maplesville",
      "state": "Alabama",
      "country": "US",
      "elevation": 495,
      "lat": 32.8487014771,
      "lon": -86.8700027466,
      "tz": "America\/Chicago"
  },
  "AL77": {
      "icao": "AL77",
      "iata": "",
      "name": "Smart Road Airport",
      "city": "Troy",
      "state": "Alabama",
      "country": "US",
      "elevation": 425,
      "lat": 31.7406005859,
      "lon": -86.0070037842,
      "tz": "America\/Chicago"
  },
  "AL78": {
      "icao": "AL78",
      "iata": "",
      "name": "Klumpp Airport",
      "city": "Fairhope",
      "state": "Alabama",
      "country": "US",
      "elevation": 111,
      "lat": 30.5268993378,
      "lon": -87.8542022705,
      "tz": "America\/Chicago"
  },
  "AL79": {
      "icao": "AL79",
      "iata": "",
      "name": "Bartlett Ranch Airport",
      "city": "Pike Road",
      "state": "Alabama",
      "country": "US",
      "elevation": 305,
      "lat": 32.2695999146,
      "lon": -86.0988006592,
      "tz": "America\/Chicago"
  },
  "AL81": {
      "icao": "AL81",
      "iata": "",
      "name": "Wallace Field",
      "city": "Robertsdale",
      "state": "Alabama",
      "country": "US",
      "elevation": 125,
      "lat": 30.5501995087,
      "lon": -87.5832977295,
      "tz": "America\/Chicago"
  },
  "AL84": {
      "icao": "AL84",
      "iata": "",
      "name": "Pecan Ponds Farm Airport",
      "city": "Robertsdale",
      "state": "Alabama",
      "country": "US",
      "elevation": 125,
      "lat": 30.5585002899,
      "lon": -87.6041030884,
      "tz": "America\/Chicago"
  },
  "AL88": {
      "icao": "AL88",
      "iata": "",
      "name": "Ban Farm Airport",
      "city": "Lillian",
      "state": "Alabama",
      "country": "US",
      "elevation": 80,
      "lat": 30.4265995026,
      "lon": -87.4708023071,
      "tz": "America\/Chicago"
  },
  "AL89": {
      "icao": "AL89",
      "iata": "",
      "name": "Sommerset Strip",
      "city": "Opelika",
      "state": "Alabama",
      "country": "US",
      "elevation": 820,
      "lat": 32.7187004089,
      "lon": -85.366897583,
      "tz": "America\/Chicago"
  },
  "AL92": {
      "icao": "AL92",
      "iata": "",
      "name": "Hawk Field",
      "city": "Harpersville",
      "state": "Alabama",
      "country": "US",
      "elevation": 420,
      "lat": 33.3073005676,
      "lon": -86.4036026001,
      "tz": "America\/Chicago"
  },
  "AL93": {
      "icao": "AL93",
      "iata": "",
      "name": "Big Sky Airport",
      "city": "Huntsville",
      "state": "Alabama",
      "country": "US",
      "elevation": 830,
      "lat": 34.8856010437,
      "lon": -86.7099990845,
      "tz": "America\/Chicago"
  },
  "AL94": {
      "icao": "AL94",
      "iata": "",
      "name": "Evans Field",
      "city": "Wilmer",
      "state": "Alabama",
      "country": "US",
      "elevation": 212,
      "lat": 30.8127002716,
      "lon": -88.3771972656,
      "tz": "America\/Chicago"
  },
  "AL97": {
      "icao": "AL97",
      "iata": "",
      "name": "Williamson Farm Airport",
      "city": "Loxley",
      "state": "Alabama",
      "country": "US",
      "elevation": 162,
      "lat": 30.6446990967,
      "lon": -87.6694030762,
      "tz": "America\/Chicago"
  },
  "AN01": {
      "icao": "AN01",
      "iata": "",
      "name": "Av Ranch Airport",
      "city": "Klondyke",
      "state": "Arizona",
      "country": "US",
      "elevation": 3500,
      "lat": 32.8166999817,
      "lon": -110.3170013428,
      "tz": "America\/Phoenix"
  },
  "ANYN": {
      "icao": "ANYN",
      "iata": "INU",
      "name": "Nauru International Airport",
      "city": "Yaren District",
      "state": "Yaren",
      "country": "NR",
      "elevation": 22,
      "lat": -0.547458,
      "lon": 166.919006,
      "tz": "Pacific\/Nauru"
  },
  "AR01": {
      "icao": "AR01",
      "iata": "",
      "name": "Baker Flying Service Airport",
      "city": "Eudora",
      "state": "Arkansas",
      "country": "US",
      "elevation": 116,
      "lat": 33.1085014343,
      "lon": -91.2500991821,
      "tz": "America\/Chicago"
  },
  "AR03": {
      "icao": "AR03",
      "iata": "",
      "name": "Centerville Airstrip",
      "city": "Centerville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 350,
      "lat": 35.1186981201,
      "lon": -93.2015991211,
      "tz": "America\/Chicago"
  },
  "AR04": {
      "icao": "AR04",
      "iata": "",
      "name": "Gunem Field",
      "city": "Cave Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1214,
      "lat": 36.2616996765,
      "lon": -94.248298645,
      "tz": "America\/Chicago"
  },
  "AR05": {
      "icao": "AR05",
      "iata": "",
      "name": "John Harris Field",
      "city": "Chickala",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1770,
      "lat": 35.2159004211,
      "lon": -93.304901123,
      "tz": "America\/Chicago"
  },
  "AR06": {
      "icao": "AR06",
      "iata": "",
      "name": "Cantrell Farms Airport",
      "city": "Conway",
      "state": "Arkansas",
      "country": "US",
      "elevation": 325,
      "lat": 35.2324981689,
      "lon": -92.3432998657,
      "tz": "America\/Chicago"
  },
  "AR08": {
      "icao": "AR08",
      "iata": "",
      "name": "Flying Machines Airstrip",
      "city": "Eureka Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1150,
      "lat": 36.3152999878,
      "lon": -93.6666030884,
      "tz": "America\/Chicago"
  },
  "AR09": {
      "icao": "AR09",
      "iata": "",
      "name": "Bella Vista Field",
      "city": "Gravette",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1200,
      "lat": 36.413898468,
      "lon": -94.4389038086,
      "tz": "America\/Chicago"
  },
  "AR10": {
      "icao": "AR10",
      "iata": "",
      "name": "Greenwalt Company Airport",
      "city": "Hazen",
      "state": "Arkansas",
      "country": "US",
      "elevation": 232,
      "lat": 34.8067016602,
      "lon": -91.6082992554,
      "tz": "America\/Chicago"
  },
  "AR11": {
      "icao": "AR11",
      "iata": "",
      "name": "Ozark Aerodrome",
      "city": "Rogers",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1270,
      "lat": 36.28889847,
      "lon": -94.09030151,
      "tz": "America\/Chicago"
  },
  "AR12": {
      "icao": "AR12",
      "iata": "",
      "name": "Mc Donald's Strip",
      "city": "Rose Bud",
      "state": "Arkansas",
      "country": "US",
      "elevation": 700,
      "lat": 35.3205986023,
      "lon": -92.018699646,
      "tz": "America\/Chicago"
  },
  "AR13": {
      "icao": "AR13",
      "iata": "",
      "name": "Pearson Field",
      "city": "Bigelow",
      "state": "Arkansas",
      "country": "US",
      "elevation": 300,
      "lat": 34.9861984253,
      "lon": -92.6334991455,
      "tz": "America\/Chicago"
  },
  "AR17": {
      "icao": "AR17",
      "iata": "",
      "name": "Burns Aerodrome",
      "city": "Palestine",
      "state": "Arkansas",
      "country": "US",
      "elevation": 207,
      "lat": 34.9833984375,
      "lon": -90.9151000977,
      "tz": "America\/Chicago"
  },
  "AR18": {
      "icao": "AR18",
      "iata": "",
      "name": "Gerrard Airport",
      "city": "Marianna",
      "state": "Arkansas",
      "country": "US",
      "elevation": 205,
      "lat": 34.7714996338,
      "lon": -90.8404006958,
      "tz": "America\/Chicago"
  },
  "AR19": {
      "icao": "AR19",
      "iata": "",
      "name": "Wesson-Davis Field",
      "city": "Paris",
      "state": "Arkansas",
      "country": "US",
      "elevation": 570,
      "lat": 35.2128982544,
      "lon": -93.7520980835,
      "tz": "America\/Chicago"
  },
  "AR22": {
      "icao": "AR22",
      "iata": "",
      "name": "Tommy's Flying Service Inc Airport",
      "city": "Sherrill",
      "state": "Arkansas",
      "country": "US",
      "elevation": 215,
      "lat": 34.3815002441,
      "lon": -91.9585037231,
      "tz": "America\/Chicago"
  },
  "AR23": {
      "icao": "AR23",
      "iata": "",
      "name": "Cedar Creek Ranch Airport",
      "city": "Belleville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 420,
      "lat": 35.1333999634,
      "lon": -93.4751968384,
      "tz": "America\/Chicago"
  },
  "AR25": {
      "icao": "AR25",
      "iata": "",
      "name": "Vilonia Airport",
      "city": "Vilonia",
      "state": "Arkansas",
      "country": "US",
      "elevation": 590,
      "lat": 35.1186981201,
      "lon": -92.1893005371,
      "tz": "America\/Chicago"
  },
  "AR27": {
      "icao": "AR27",
      "iata": "",
      "name": "Lollars Creek Farm Airport",
      "city": "Wesley",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1430,
      "lat": 35.938999176,
      "lon": -93.845703125,
      "tz": "America\/Chicago"
  },
  "AR28": {
      "icao": "AR28",
      "iata": "",
      "name": "Odom Field",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 280,
      "lat": 34.9393005371,
      "lon": -92.024597168,
      "tz": "America\/Chicago"
  },
  "AR34": {
      "icao": "AR34",
      "iata": "",
      "name": "Pine Village Airport",
      "city": "Mayflower",
      "state": "Arkansas",
      "country": "US",
      "elevation": 610,
      "lat": 34.9819984436,
      "lon": -92.4451980591,
      "tz": "America\/Chicago"
  },
  "AR35": {
      "icao": "AR35",
      "iata": "",
      "name": "Henson Farm Airport",
      "city": "Fayetteville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1420,
      "lat": 36.0867004395,
      "lon": -94.1052017212,
      "tz": "America\/Chicago"
  },
  "AR43": {
      "icao": "AR43",
      "iata": "",
      "name": "Brickey Private Airport",
      "city": "Morrilton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 320,
      "lat": 35.1001014709,
      "lon": -92.7835006714,
      "tz": "America\/Chicago"
  },
  "AR44": {
      "icao": "AR44",
      "iata": "",
      "name": "Flying W Airport",
      "city": "Texarkana",
      "state": "Arkansas",
      "country": "US",
      "elevation": 265,
      "lat": 33.5125999451,
      "lon": -94.0134963989,
      "tz": "America\/Chicago"
  },
  "AR46": {
      "icao": "AR46",
      "iata": "",
      "name": "Penrose Airport",
      "city": "Mc Crory",
      "state": "Arkansas",
      "country": "US",
      "elevation": 220,
      "lat": 35.1893005371,
      "lon": -91.048500061,
      "tz": "America\/Chicago"
  },
  "AR50": {
      "icao": "AR50",
      "iata": "",
      "name": "Hess Strip",
      "city": "Wynne",
      "state": "Arkansas",
      "country": "US",
      "elevation": 180,
      "lat": 35.2667999268,
      "lon": -90.9834976196,
      "tz": "America\/Chicago"
  },
  "AR54": {
      "icao": "AR54",
      "iata": "",
      "name": "Ralph Fulmer Field",
      "city": "Cabot",
      "state": "Arkansas",
      "country": "US",
      "elevation": 245,
      "lat": 34.9187011719,
      "lon": -91.9151000977,
      "tz": "America\/Chicago"
  },
  "AR55": {
      "icao": "AR55",
      "iata": "",
      "name": "R.V. Stewart Field",
      "city": "North Little Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 251,
      "lat": 34.7397994995,
      "lon": -92.0901031494,
      "tz": "America\/Chicago"
  },
  "AR56": {
      "icao": "AR56",
      "iata": "",
      "name": "Amos Airport",
      "city": "Oil Trough",
      "state": "Arkansas",
      "country": "US",
      "elevation": 240,
      "lat": 35.6001014709,
      "lon": -91.4335021973,
      "tz": "America\/Chicago"
  },
  "AR64": {
      "icao": "AR64",
      "iata": "",
      "name": "White River Airport",
      "city": "Melbourne",
      "state": "Arkansas",
      "country": "US",
      "elevation": 310,
      "lat": 35.9211997986,
      "lon": -92.0050964355,
      "tz": "America\/Chicago"
  },
  "AR79": {
      "icao": "AR79",
      "iata": "",
      "name": "Flying G Ranch Airport",
      "city": "Tillar",
      "state": "Arkansas",
      "country": "US",
      "elevation": 150,
      "lat": 33.6931991577,
      "lon": -91.3796005249,
      "tz": "America\/Chicago"
  },
  "AR81": {
      "icao": "AR81",
      "iata": "",
      "name": "Katheryn's Landing Airport",
      "city": "Leola",
      "state": "Arkansas",
      "country": "US",
      "elevation": 250,
      "lat": 34.1623001099,
      "lon": -92.6037979126,
      "tz": "America\/Chicago"
  },
  "AR82": {
      "icao": "AR82",
      "iata": "",
      "name": "Gillespie Strip",
      "city": "Leola",
      "state": "Arkansas",
      "country": "US",
      "elevation": 492,
      "lat": 34.1711997986,
      "lon": -92.6959991455,
      "tz": "America\/Chicago"
  },
  "AR83": {
      "icao": "AR83",
      "iata": "",
      "name": "Heard Airport",
      "city": "Leola",
      "state": "Arkansas",
      "country": "US",
      "elevation": 500,
      "lat": 34.1762008667,
      "lon": -92.7009963989,
      "tz": "America\/Chicago"
  },
  "AR84": {
      "icao": "AR84",
      "iata": "",
      "name": "Bobwhite Hill Ranch Airport",
      "city": "Mayflower",
      "state": "Arkansas",
      "country": "US",
      "elevation": 350,
      "lat": 34.9664993286,
      "lon": -92.2993011475,
      "tz": "America\/Chicago"
  },
  "AR88": {
      "icao": "AR88",
      "iata": "",
      "name": "Poe's Airport",
      "city": "Conway",
      "state": "Arkansas",
      "country": "US",
      "elevation": 295,
      "lat": 35.0547981262,
      "lon": -92.3539962769,
      "tz": "America\/Chicago"
  },
  "AR89": {
      "icao": "AR89",
      "iata": "",
      "name": "Landers Loop Airport",
      "city": "Dover",
      "state": "Arkansas",
      "country": "US",
      "elevation": 730,
      "lat": 35.400100708,
      "lon": -93.0660018921,
      "tz": "America\/Chicago"
  },
  "AR91": {
      "icao": "AR91",
      "iata": "",
      "name": "Circle S Farms Airport",
      "city": "Lowell",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1200,
      "lat": 36.2258987427,
      "lon": -94.0320968628,
      "tz": "America\/Chicago"
  },
  "AR93": {
      "icao": "AR93",
      "iata": "",
      "name": "Ark-Mo Airport",
      "city": "Pollard",
      "state": "Arkansas",
      "country": "US",
      "elevation": 308,
      "lat": 36.4855995178,
      "lon": -90.2804031372,
      "tz": "America\/Chicago"
  },
  "AR94": {
      "icao": "AR94",
      "iata": "",
      "name": "Squirrel Run Airport",
      "city": "Dyer",
      "state": "Arkansas",
      "country": "US",
      "elevation": 415,
      "lat": 35.4854011536,
      "lon": -94.1382980347,
      "tz": "America\/Chicago"
  },
  "AR97": {
      "icao": "AR97",
      "iata": "",
      "name": "Chael Airport",
      "city": "Wooster",
      "state": "Arkansas",
      "country": "US",
      "elevation": 320,
      "lat": 35.2201004028,
      "lon": -92.4964981079,
      "tz": "America\/Chicago"
  },
  "AR98": {
      "icao": "AR98",
      "iata": "",
      "name": "Johnson Field",
      "city": "Mc Crory",
      "state": "Arkansas",
      "country": "US",
      "elevation": 208,
      "lat": 35.2644996643,
      "lon": -91.1890029907,
      "tz": "America\/Chicago"
  },
  "AYAI": {
      "icao": "AYAI",
      "iata": "ATP",
      "name": "Aitape Airport",
      "city": "Aitape",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 10,
      "lat": -3.1436111111,
      "lon": 142.346805556,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYAM": {
      "icao": "AYAM",
      "iata": "AMU",
      "name": "Amanab Airport",
      "city": "Amanab",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 1307,
      "lat": -3.586,
      "lon": 141.214333333,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYAN": {
      "icao": "AYAN",
      "iata": "ADC",
      "name": "Andakombe Airport",
      "city": "Andekombe",
      "state": "Gulf",
      "country": "PG",
      "elevation": 3600,
      "lat": -7.1372222222,
      "lon": 145.744722222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYAO": {
      "icao": "AYAO",
      "iata": "AIE",
      "name": "Aiome Airport",
      "city": "Aiome",
      "state": "Madang",
      "country": "PG",
      "elevation": 350,
      "lat": -5.145699978,
      "lon": 144.7307003,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYAQ": {
      "icao": "AYAQ",
      "iata": "KPM",
      "name": "Kompiam Airport",
      "city": "",
      "state": "Enga",
      "country": "PG",
      "elevation": 5100,
      "lat": -5.3816666667,
      "lon": 143.924722222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYAT": {
      "icao": "AYAT",
      "iata": "AUJ",
      "name": "Ambunti Airport",
      "city": "Ambunti",
      "state": "East Sepik",
      "country": "PG",
      "elevation": 50,
      "lat": -4.2156666667,
      "lon": 142.823166667,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYAW": {
      "icao": "AYAW",
      "iata": "AWB",
      "name": "Awaba Airport",
      "city": "Awaba",
      "state": "Western Province",
      "country": "PG",
      "elevation": 32,
      "lat": -8.0061101914,
      "lon": 142.748352051,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYAY": {
      "icao": "AYAY",
      "iata": "AYU",
      "name": "Aiyura Airport",
      "city": "Aiyura Valley",
      "state": "Eastern Highlands",
      "country": "PG",
      "elevation": 5355,
      "lat": -6.3380555556,
      "lon": 145.904166667,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYBA": {
      "icao": "AYBA",
      "iata": "VMU",
      "name": "Baimuru Airport",
      "city": "Baimuru",
      "state": "Gulf",
      "country": "PG",
      "elevation": 27,
      "lat": -7.4968600273,
      "lon": 144.819900513,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYBG": {
      "icao": "AYBG",
      "iata": "BDZ",
      "name": "Baindoung Airport",
      "city": "",
      "state": "Morobe",
      "country": "PG",
      "elevation": 4400,
      "lat": -6.3472222222,
      "lon": 146.942222222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYBK": {
      "icao": "AYBK",
      "iata": "BUA",
      "name": "Buka Airport",
      "city": "Buka Island",
      "state": "Bougainville",
      "country": "PG",
      "elevation": 11,
      "lat": -5.4223198891,
      "lon": 154.6730041504,
      "tz": "Pacific\/Bougainville"
  },
  "AYBM": {
      "icao": "AYBM",
      "iata": "OPU",
      "name": "Balimo Airport",
      "city": "Balimo",
      "state": "Western Province",
      "country": "PG",
      "elevation": 51,
      "lat": -8.0500001907,
      "lon": 142.932998657,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYBU": {
      "icao": "AYBU",
      "iata": "BUL",
      "name": "Bulolo Airport",
      "city": "Bulolo",
      "state": "Morobe",
      "country": "PG",
      "elevation": 2240,
      "lat": -7.2162866714,
      "lon": 146.649541855,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYBW": {
      "icao": "AYBW",
      "iata": "",
      "name": "Blackwara Airport",
      "city": "",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 58,
      "lat": -2.618211,
      "lon": 141.091971,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYCH": {
      "icao": "AYCH",
      "iata": "CMU",
      "name": "Chimbu Airport",
      "city": "Kundiawa",
      "state": "Chimbu",
      "country": "PG",
      "elevation": 4974,
      "lat": -6.0242900848,
      "lon": 144.970993042,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYDE": {
      "icao": "AYDE",
      "iata": "DER",
      "name": "Derim Airport",
      "city": "Derim",
      "state": "Morobe",
      "country": "PG",
      "elevation": 4850,
      "lat": -6.1447222222,
      "lon": 147.107222222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYDS": {
      "icao": "AYDS",
      "iata": "",
      "name": "Dusin Airport",
      "city": "Dusin",
      "state": "East Sepik",
      "country": "PG",
      "elevation": 6300,
      "lat": -5.184135,
      "lon": 144.411965,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYDU": {
      "icao": "AYDU",
      "iata": "DAU",
      "name": "Daru Airport",
      "city": "Daru",
      "state": "Western Province",
      "country": "PG",
      "elevation": 20,
      "lat": -9.0867595673,
      "lon": 143.207992554,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYED": {
      "icao": "AYED",
      "iata": "XYR",
      "name": "Edwaki Airport",
      "city": "Yellow River Mission",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 190,
      "lat": -3.883986,
      "lon": 141.792234,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYFA": {
      "icao": "AYFA",
      "iata": "FNE",
      "name": "Fane Airport",
      "city": "Fane Mission",
      "state": "Central Province",
      "country": "PG",
      "elevation": 4500,
      "lat": -8.5492777778,
      "lon": 147.085833333,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYFI": {
      "icao": "AYFI",
      "iata": "FIN",
      "name": "Finschhafen Airport",
      "city": "Buki",
      "state": "Morobe",
      "country": "PG",
      "elevation": 60,
      "lat": -6.621750109,
      "lon": 147.85405,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYFR": {
      "icao": "AYFR",
      "iata": "FAQ",
      "name": "Frieda River Airport",
      "city": "Frieda River",
      "state": "East Sepik",
      "country": "PG",
      "elevation": 217,
      "lat": -4.610468,
      "lon": 141.960138,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGA": {
      "icao": "AYGA",
      "iata": "GKA",
      "name": "Goroka Airport",
      "city": "Goronka",
      "state": "Eastern Highlands",
      "country": "PG",
      "elevation": 5282,
      "lat": -6.0816898346,
      "lon": 145.391998291,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGB": {
      "icao": "AYGB",
      "iata": "",
      "name": "Gobe Airport",
      "city": "Gobe",
      "state": "Gulf",
      "country": "PG",
      "elevation": 0,
      "lat": -6.8775170598,
      "lon": 143.721942902,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGG": {
      "icao": "AYGG",
      "iata": "GRL",
      "name": "Garasa Airport",
      "city": "Au",
      "state": "Morobe",
      "country": "PG",
      "elevation": 2539,
      "lat": -7.980713,
      "lon": 147.213461,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGI": {
      "icao": "AYGI",
      "iata": "GAR",
      "name": "Garaina Airport",
      "city": "Garaina",
      "state": "Morobe",
      "country": "PG",
      "elevation": 2497,
      "lat": -7.8756250275,
      "lon": 147.141265869,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGN": {
      "icao": "AYGN",
      "iata": "GUR",
      "name": "Gurney Airport",
      "city": "Gurney",
      "state": "Milne Bay",
      "country": "PG",
      "elevation": 88,
      "lat": -10.3114995956,
      "lon": 150.333999634,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGP": {
      "icao": "AYGP",
      "iata": "GAP",
      "name": "Gusap Airport",
      "city": "Gusap",
      "state": "Morobe",
      "country": "PG",
      "elevation": 1504,
      "lat": -6.053196,
      "lon": 145.959047,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGR": {
      "icao": "AYGR",
      "iata": "PNP",
      "name": "Girua Airport",
      "city": "Popondetta",
      "state": "Northern Province",
      "country": "PG",
      "elevation": 311,
      "lat": -8.8045396805,
      "lon": 148.309005737,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGT": {
      "icao": "AYGT",
      "iata": "GMI",
      "name": "Gasmata Island Airport",
      "city": "Gasmata Island",
      "state": "West New Britain",
      "country": "PG",
      "elevation": 23,
      "lat": -6.2711100578,
      "lon": 150.330993652,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGV": {
      "icao": "AYGV",
      "iata": "GVI",
      "name": "Green River Airport",
      "city": "Green River",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 281,
      "lat": -3.9022428989,
      "lon": 141.170516968,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYGW": {
      "icao": "AYGW",
      "iata": "",
      "name": "Guwasa Airport",
      "city": "Guwasa",
      "state": "Eastern Highlands",
      "country": "PG",
      "elevation": 4800,
      "lat": -6.465838,
      "lon": 145.083968,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYHA": {
      "icao": "AYHA",
      "iata": "",
      "name": "Haia Airport",
      "city": "",
      "state": "Chimbu",
      "country": "PG",
      "elevation": 2434,
      "lat": -6.707421,
      "lon": 144.997398,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYHK": {
      "icao": "AYHK",
      "iata": "HKN",
      "name": "Kimbe Airport",
      "city": "Hoskins",
      "state": "West New Britain",
      "country": "PG",
      "elevation": 66,
      "lat": -5.4621701241,
      "lon": 150.4049987793,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYIQ": {
      "icao": "AYIQ",
      "iata": "KIE",
      "name": "Aropa Airport",
      "city": "Kieta",
      "state": "Bougainville Island",
      "country": "PG",
      "elevation": 10,
      "lat": -6.305,
      "lon": 155.727778,
      "tz": "Pacific\/Bougainville"
  },
  "AYKA": {
      "icao": "AYKA",
      "iata": "LSA",
      "name": "Losuia Airport",
      "city": "Losuia",
      "state": "Milne Bay",
      "country": "PG",
      "elevation": 27,
      "lat": -8.5058202744,
      "lon": 151.0809936523,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKB": {
      "icao": "AYKB",
      "iata": "KBM",
      "name": "Kabwum",
      "city": "",
      "state": "Morobe",
      "country": "PG",
      "elevation": 4450,
      "lat": -6.1554722222,
      "lon": 147.191472222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKC": {
      "icao": "AYKC",
      "iata": "KDR",
      "name": "Kandrian Airport",
      "city": "Kandrian",
      "state": "West New Britain",
      "country": "PG",
      "elevation": 280,
      "lat": -6.2659,
      "lon": 149.5581,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKI": {
      "icao": "AYKI",
      "iata": "UNG",
      "name": "Kiunga Airport",
      "city": "Kiunga",
      "state": "Western Province",
      "country": "PG",
      "elevation": 88,
      "lat": -6.1257100105,
      "lon": 141.2819976807,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKK": {
      "icao": "AYKK",
      "iata": "KRI",
      "name": "Kikori Airport",
      "city": "Kikori",
      "state": "Gulf",
      "country": "PG",
      "elevation": 50,
      "lat": -7.4243798256,
      "lon": 144.2500762939,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKM": {
      "icao": "AYKM",
      "iata": "KMA",
      "name": "Kerema Airport",
      "city": "Kerema",
      "state": "Gulf",
      "country": "PG",
      "elevation": 10,
      "lat": -7.9636101723,
      "lon": 145.770996094,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKO": {
      "icao": "AYKO",
      "iata": "KKD",
      "name": "Kokoda Airport",
      "city": "Kokoda",
      "state": "Northern Province",
      "country": "PG",
      "elevation": 1240,
      "lat": -8.8846788406,
      "lon": 147.730957031,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKT": {
      "icao": "AYKT",
      "iata": "KZF",
      "name": "Kaintiba Airport",
      "city": "Kaintiba",
      "state": "Gulf",
      "country": "PG",
      "elevation": 2050,
      "lat": -7.50025,
      "lon": 146.033833333,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKU": {
      "icao": "AYKU",
      "iata": "KUQ",
      "name": "Kuri Airport",
      "city": "Kuri",
      "state": "Gulf",
      "country": "PG",
      "elevation": 35,
      "lat": -7.135083,
      "lon": 143.276111,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKV": {
      "icao": "AYKV",
      "iata": "KVG",
      "name": "Kavieng Airport",
      "city": "Kavieng",
      "state": "New Ireland",
      "country": "PG",
      "elevation": 7,
      "lat": -2.5794000626,
      "lon": 150.807998657,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKW": {
      "icao": "AYKW",
      "iata": "KWO",
      "name": "Kawito Airport",
      "city": "Kawito",
      "state": "Western Province",
      "country": "PG",
      "elevation": 72,
      "lat": -7.9770069122,
      "lon": 142.823577881,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYKY": {
      "icao": "AYKY",
      "iata": "LNV",
      "name": "Londolovit Airport",
      "city": "Londolovit",
      "state": "New Ireland",
      "country": "PG",
      "elevation": 167,
      "lat": -3.043610096,
      "lon": 152.628997803,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYLM": {
      "icao": "AYLM",
      "iata": "LMY",
      "name": "Lake Murray Airport",
      "city": "Lake Murray",
      "state": "Western Province",
      "country": "PG",
      "elevation": 52,
      "lat": -7.0099201202,
      "lon": 141.494003296,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYLU": {
      "icao": "AYLU",
      "iata": "LMI",
      "name": "Lumi Airport",
      "city": "Lumi",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 1750,
      "lat": -3.4768395113,
      "lon": 142.041292191,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMC": {
      "icao": "AYMC",
      "iata": "MYX",
      "name": "Menyamya Airport",
      "city": "Menyamya",
      "state": "Morobe",
      "country": "PG",
      "elevation": 3880,
      "lat": -7.2116666667,
      "lon": 146.019361111,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMD": {
      "icao": "AYMD",
      "iata": "MAG",
      "name": "Madang Airport",
      "city": "Madang",
      "state": "Madang",
      "country": "PG",
      "elevation": 20,
      "lat": -5.2070798874,
      "lon": 145.789001465,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMH": {
      "icao": "AYMH",
      "iata": "HGU",
      "name": "Mount Hagen Kagamuga Airport",
      "city": "Mount Hagen",
      "state": "Western Highlands",
      "country": "PG",
      "elevation": 5388,
      "lat": -5.826789856,
      "lon": 144.296005249,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMI": {
      "icao": "AYMI",
      "iata": "MXK",
      "name": "Mindik Airport",
      "city": "Mindik",
      "state": "Morobe",
      "country": "PG",
      "elevation": 4200,
      "lat": -6.4716666667,
      "lon": 147.441138889,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMK": {
      "icao": "AYMK",
      "iata": "",
      "name": "Mok Airport",
      "city": "Mok",
      "state": "West New Britain",
      "country": "PG",
      "elevation": 1050,
      "lat": -5.730238,
      "lon": 149.056583,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYML": {
      "icao": "AYML",
      "iata": "GUV",
      "name": "Mougulu Airport",
      "city": "Mougulu",
      "state": "Western Province",
      "country": "PG",
      "elevation": 825,
      "lat": -6.280859,
      "lon": 142.420775,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMN": {
      "icao": "AYMN",
      "iata": "MDU",
      "name": "Mendi Airport",
      "city": "",
      "state": "Southern Highlands",
      "country": "PG",
      "elevation": 5680,
      "lat": -6.1477398872,
      "lon": 143.6569976807,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMO": {
      "icao": "AYMO",
      "iata": "MAS",
      "name": "Momote Airport",
      "city": "",
      "state": "Manus",
      "country": "PG",
      "elevation": 12,
      "lat": -2.0618898869,
      "lon": 147.4239959717,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMR": {
      "icao": "AYMR",
      "iata": "MXH",
      "name": "Moro Airport",
      "city": "Moro",
      "state": "Southern Highlands",
      "country": "PG",
      "elevation": 2740,
      "lat": -6.3633298874,
      "lon": 143.238006592,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYMS": {
      "icao": "AYMS",
      "iata": "MIS",
      "name": "Misima Island Airport",
      "city": "Misima Island",
      "state": "Milne Bay",
      "country": "PG",
      "elevation": 26,
      "lat": -10.6892004013,
      "lon": 152.837997437,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYNE": {
      "icao": "AYNE",
      "iata": "GBF",
      "name": "Negarbo(Negabo) Airport",
      "city": "Negarbo",
      "state": "Chimbu",
      "country": "PG",
      "elevation": 4500,
      "lat": -6.567778,
      "lon": 144.703058,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYNG": {
      "icao": "AYNG",
      "iata": "MFO",
      "name": "Manguna Airport",
      "city": "Manguna",
      "state": "East New Britain",
      "country": "PG",
      "elevation": 187,
      "lat": -5.577778,
      "lon": 151.792333,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYNU": {
      "icao": "AYNU",
      "iata": "UKU",
      "name": "Nuku Airport",
      "city": "Nuku",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 870,
      "lat": -3.676651,
      "lon": 142.484334,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYNZ": {
      "icao": "AYNZ",
      "iata": "LAE",
      "name": "Lae Nadzab Airport",
      "city": "Nadzab",
      "state": "Morobe",
      "country": "PG",
      "elevation": 239,
      "lat": -6.5698299408,
      "lon": 146.725997925,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYOG": {
      "icao": "AYOG",
      "iata": "OGE",
      "name": "Ogeranang Airport",
      "city": "",
      "state": "Morobe",
      "country": "PG",
      "elevation": 5850,
      "lat": -6.46675,
      "lon": 147.364166667,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYOM": {
      "icao": "AYOM",
      "iata": "OSE",
      "name": "Omora Airport",
      "city": "Omora",
      "state": "Morobe",
      "country": "PG",
      "elevation": 2540,
      "lat": -7.8277777778,
      "lon": 147.080638889,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYPD": {
      "icao": "AYPD",
      "iata": "PDI",
      "name": "Pindiu Airport",
      "city": "Pindiu",
      "state": "Morobe",
      "country": "PG",
      "elevation": 2995,
      "lat": -6.4451388889,
      "lon": 147.515833333,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYPU": {
      "icao": "AYPU",
      "iata": "",
      "name": "Puri Airport",
      "city": "Puri",
      "state": "Southern Highlands",
      "country": "PG",
      "elevation": 6050,
      "lat": -6.4751611111,
      "lon": 143.987222222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYPW": {
      "icao": "AYPW",
      "iata": "",
      "name": "Panakawa Airport",
      "city": "",
      "state": "Western Province",
      "country": "PG",
      "elevation": 42,
      "lat": -7.6720555556,
      "lon": 143.124722222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYPY": {
      "icao": "AYPY",
      "iata": "POM",
      "name": "Port Moresby Jacksons International Airport",
      "city": "Port Moresby",
      "state": "National Capital",
      "country": "PG",
      "elevation": 146,
      "lat": -9.4433803558,
      "lon": 147.2200012207,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYQA": {
      "icao": "AYQA",
      "iata": "KRJ",
      "name": "Karawari Airstrip",
      "city": "",
      "state": "East Sepik",
      "country": "PG",
      "elevation": 70,
      "lat": -4.5966666667,
      "lon": 143.5225,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYRG": {
      "icao": "AYRG",
      "iata": "RMN",
      "name": "Rumginae Airport",
      "city": "",
      "state": "Western Province",
      "country": "PG",
      "elevation": 160,
      "lat": -5.8972222222,
      "lon": 141.271666667,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYRI": {
      "icao": "AYRI",
      "iata": "KMR",
      "name": "Karimui Airport",
      "city": "Karimui",
      "state": "Chimbu",
      "country": "PG",
      "elevation": 3640,
      "lat": -6.4921,
      "lon": 144.823,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYSA": {
      "icao": "AYSA",
      "iata": "SBE",
      "name": "Suabi Airport",
      "city": "",
      "state": "Western Province",
      "country": "PG",
      "elevation": 465,
      "lat": -6.1044444444,
      "lon": 142.278333333,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYSE": {
      "icao": "AYSE",
      "iata": "NIS",
      "name": "Simberi Airport",
      "city": "Simberi Island",
      "state": "New Ireland",
      "country": "PG",
      "elevation": 10,
      "lat": -2.6622222222,
      "lon": 151.997777778,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYSG": {
      "icao": "AYSG",
      "iata": "SIL",
      "name": "Sila Airport",
      "city": "Sila Mission",
      "state": "Northern Province",
      "country": "PG",
      "elevation": 2230,
      "lat": -9.0735555556,
      "lon": 148.38925,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYSJ": {
      "icao": "AYSJ",
      "iata": "SIM",
      "name": "Simbai Airport",
      "city": "Simbai",
      "state": "Western Highlands",
      "country": "PG",
      "elevation": 5804,
      "lat": -5.2786111111,
      "lon": 144.544722222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYST": {
      "icao": "AYST",
      "iata": "",
      "name": "Sturt Island Airport",
      "city": "",
      "state": "Western Province",
      "country": "PG",
      "elevation": 40,
      "lat": -8.1525,
      "lon": 142.268444444,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYSU": {
      "icao": "AYSU",
      "iata": "SKC",
      "name": "Suki Airport",
      "city": "Suki",
      "state": "Western Province",
      "country": "PG",
      "elevation": 24,
      "lat": -8.0466,
      "lon": 141.7222,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTA": {
      "icao": "AYTA",
      "iata": "TIZ",
      "name": "Tari Airport",
      "city": "Tari",
      "state": "Southern Highlands",
      "country": "PG",
      "elevation": 5500,
      "lat": -5.8449997902,
      "lon": 142.947998047,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTB": {
      "icao": "AYTB",
      "iata": "TBG",
      "name": "Tabubil Airport",
      "city": "Tabubil",
      "state": "Western Province",
      "country": "PG",
      "elevation": 1570,
      "lat": -5.2786102295,
      "lon": 141.2259979248,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTE": {
      "icao": "AYTE",
      "iata": "TFM",
      "name": "Telefomin Airport",
      "city": "Telefomin",
      "state": "Western Province",
      "country": "PG",
      "elevation": 4950,
      "lat": -5.1260804642,
      "lon": 141.641921997,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTI": {
      "icao": "AYTI",
      "iata": "TPI",
      "name": "Tapini Airport",
      "city": "Tapini",
      "state": "Central Province",
      "country": "PG",
      "elevation": 3100,
      "lat": -8.3566666667,
      "lon": 146.989166667,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTJ": {
      "icao": "AYTJ",
      "iata": "TAJ",
      "name": "Tadji Airport",
      "city": "Aitape",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 33,
      "lat": -3.1981918035,
      "lon": 142.430963516,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTK": {
      "icao": "AYTK",
      "iata": "RAB",
      "name": "Tokua Airport",
      "city": "Tokua",
      "state": "East New Britain",
      "country": "PG",
      "elevation": 32,
      "lat": -4.3404598236,
      "lon": 152.380004883,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTN": {
      "icao": "AYTN",
      "iata": "TKW",
      "name": "Tekin Airport",
      "city": "Tekin",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 5785,
      "lat": -5.2436666667,
      "lon": 142.165194444,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYTU": {
      "icao": "AYTU",
      "iata": "TFI",
      "name": "Tufi Airport",
      "city": "Tufi",
      "state": "Northern Province",
      "country": "PG",
      "elevation": 85,
      "lat": -9.0759544373,
      "lon": 149.319839478,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYVN": {
      "icao": "AYVN",
      "iata": "VAI",
      "name": "Vanimo Airport",
      "city": "",
      "state": "Sandaun",
      "country": "PG",
      "elevation": 10,
      "lat": -2.6971700191,
      "lon": 141.3020019531,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWB": {
      "icao": "AYWB",
      "iata": "WAO",
      "name": "Wabo Airport",
      "city": "Wabo",
      "state": "Gulf",
      "country": "PG",
      "elevation": 132,
      "lat": -6.9894444444,
      "lon": 145.075111111,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWD": {
      "icao": "AYWD",
      "iata": "WBM",
      "name": "Wapenamanda Airport",
      "city": "",
      "state": "Enga",
      "country": "PG",
      "elevation": 5889,
      "lat": -5.6433000565,
      "lon": 143.8950042725,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWG": {
      "icao": "AYWG",
      "iata": "AGL",
      "name": "Wanigela Airport",
      "city": "",
      "state": "Northern Province",
      "country": "PG",
      "elevation": 53,
      "lat": -9.3375,
      "lon": 149.155555556,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWK": {
      "icao": "AYWK",
      "iata": "WWK",
      "name": "Wewak International Airport",
      "city": "Wewak",
      "state": "East Sepik",
      "country": "PG",
      "elevation": 19,
      "lat": -3.5838301182,
      "lon": 143.669006348,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWO": {
      "icao": "AYWO",
      "iata": "WOA",
      "name": "Wonenara Airport",
      "city": "Wonenara",
      "state": "Eastern Highlands",
      "country": "PG",
      "elevation": 5028,
      "lat": -6.7968611111,
      "lon": 145.891944444,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWS": {
      "icao": "AYWS",
      "iata": "WSU",
      "name": "Wasu Airport",
      "city": "Wasu",
      "state": "Morobe",
      "country": "PG",
      "elevation": 34,
      "lat": -5.9617094492,
      "lon": 147.19822526,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWT": {
      "icao": "AYWT",
      "iata": "WTP",
      "name": "Woitape Airport",
      "city": "Fatima Mission",
      "state": "Central Province",
      "country": "PG",
      "elevation": 5200,
      "lat": -8.5458333333,
      "lon": 147.2525,
      "tz": "Pacific\/Port_Moresby"
  },
  "AYWU": {
      "icao": "AYWU",
      "iata": "WUG",
      "name": "Wau Airport",
      "city": "Wau",
      "state": "Morobe",
      "country": "PG",
      "elevation": 3600,
      "lat": -7.3455555556,
      "lon": 146.718611111,
      "tz": "Pacific\/Port_Moresby"
  },
  "AZ00": {
      "icao": "AZ00",
      "iata": "",
      "name": "Morton Field",
      "city": "Dateland",
      "state": "Arizona",
      "country": "US",
      "elevation": 475,
      "lat": 32.8058013916,
      "lon": -113.5070037842,
      "tz": "America\/Phoenix"
  },
  "AZ01": {
      "icao": "AZ01",
      "iata": "",
      "name": "Outback Ranch Airstrip",
      "city": "Wenden",
      "state": "Arizona",
      "country": "US",
      "elevation": 1980,
      "lat": 33.8477783203,
      "lon": -113.570274353,
      "tz": "America\/Phoenix"
  },
  "AZ03": {
      "icao": "AZ03",
      "iata": "",
      "name": "Cliff Dwellers Lodge Airport",
      "city": "Marble Canyon",
      "state": "Arizona",
      "country": "US",
      "elevation": 4217,
      "lat": 36.7344017029,
      "lon": -111.7529983521,
      "tz": "America\/Phoenix"
  },
  "AZ04": {
      "icao": "AZ04",
      "iata": "",
      "name": "Hillair Dirt Strip",
      "city": "Aguila",
      "state": "Arizona",
      "country": "US",
      "elevation": 2210,
      "lat": 33.9192008972,
      "lon": -113.1900024414,
      "tz": "America\/Phoenix"
  },
  "AZ05": {
      "icao": "AZ05",
      "iata": "",
      "name": "Lakeside Airpark",
      "city": "Buckeye",
      "state": "Arizona",
      "country": "US",
      "elevation": 763,
      "lat": 33.1124992371,
      "lon": -112.6620025635,
      "tz": "America\/Phoenix"
  },
  "AZ06": {
      "icao": "AZ06",
      "iata": "",
      "name": "Dateland Airfield",
      "city": "Dateland",
      "state": "Arizona",
      "country": "US",
      "elevation": 450,
      "lat": 32.817199707,
      "lon": -113.5270004272,
      "tz": "America\/Phoenix"
  },
  "AZ09": {
      "icao": "AZ09",
      "iata": "",
      "name": "Sergio Private Airport",
      "city": "Lake Havasu City",
      "state": "Arizona",
      "country": "US",
      "elevation": 1900,
      "lat": 34.7999992371,
      "lon": -114.1129989624,
      "tz": "America\/Phoenix"
  },
  "AZ13": {
      "icao": "AZ13",
      "iata": "",
      "name": "Schu Ranch Airport",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1410,
      "lat": 33.016998291,
      "lon": -112.28099823,
      "tz": "America\/Phoenix"
  },
  "AZ14": {
      "icao": "AZ14",
      "iata": "",
      "name": "Ammon Airport",
      "city": "Willcox",
      "state": "Arizona",
      "country": "US",
      "elevation": 4520,
      "lat": 32.02470016,
      "lon": -109.9570007,
      "tz": "America\/Phoenix"
  },
  "AZ17": {
      "icao": "AZ17",
      "iata": "",
      "name": "Circle H Ranch Airport",
      "city": "Douglas",
      "state": "Arizona",
      "country": "US",
      "elevation": 4680,
      "lat": 31.502199173,
      "lon": -109.3909988403,
      "tz": "America\/Phoenix"
  },
  "AZ19": {
      "icao": "AZ19",
      "iata": "",
      "name": "C & L Ranch Ultralightport",
      "city": "Sonoita",
      "state": "Arizona",
      "country": "US",
      "elevation": 4600,
      "lat": 31.7378005981,
      "lon": -110.6259994507,
      "tz": "America\/Phoenix"
  },
  "AZ21": {
      "icao": "AZ21",
      "iata": "",
      "name": "Four Pillars Airport",
      "city": "Huachuca City\/Tombstone",
      "state": "Arizona",
      "country": "US",
      "elevation": 4100,
      "lat": 31.7458000183,
      "lon": -110.2480010986,
      "tz": "America\/Phoenix"
  },
  "AZ25": {
      "icao": "AZ25",
      "iata": "",
      "name": "Goldfield Ranch Airport",
      "city": "Fountain Hills",
      "state": "Arizona",
      "country": "US",
      "elevation": 1720,
      "lat": 33.5999984741,
      "lon": -111.6050033569,
      "tz": "America\/Phoenix"
  },
  "AZ26": {
      "icao": "AZ26",
      "iata": "",
      "name": "Evelyn Field",
      "city": "Palominas",
      "state": "Arizona",
      "country": "US",
      "elevation": 4242,
      "lat": 31.3691997528,
      "lon": -110.125,
      "tz": "America\/Hermosillo"
  },
  "AZ27": {
      "icao": "AZ27",
      "iata": "",
      "name": "Big Springs Ranch Airport",
      "city": "Prescott",
      "state": "Arizona",
      "country": "US",
      "elevation": 4421,
      "lat": 34.9099998474,
      "lon": -112.533996582,
      "tz": "America\/Phoenix"
  },
  "AZ28": {
      "icao": "AZ28",
      "iata": "",
      "name": "Thunder Ridge Airpark",
      "city": "Morristown",
      "state": "Arizona",
      "country": "US",
      "elevation": 1915,
      "lat": 33.8214988708,
      "lon": -112.5729980469,
      "tz": "America\/Phoenix"
  },
  "AZ31": {
      "icao": "AZ31",
      "iata": "",
      "name": "Serene Field",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1456,
      "lat": 32.9967002869,
      "lon": -112.2720031738,
      "tz": "America\/Phoenix"
  },
  "AZ32": {
      "icao": "AZ32",
      "iata": "",
      "name": "Roesner Ranch Airport",
      "city": "Morristown",
      "state": "Arizona",
      "country": "US",
      "elevation": 2060,
      "lat": 33.8474998474,
      "lon": -112.5879974365,
      "tz": "America\/Phoenix"
  },
  "AZ34": {
      "icao": "AZ34",
      "iata": "",
      "name": "Massey Farm Airport",
      "city": "Yucca",
      "state": "Arizona",
      "country": "US",
      "elevation": 1630,
      "lat": 34.691428,
      "lon": -114.097739,
      "tz": "America\/Phoenix"
  },
  "AZ40": {
      "icao": "AZ40",
      "iata": "",
      "name": "Sheepy Hollow Ranch Airfield",
      "city": "Salome",
      "state": "Arizona",
      "country": "US",
      "elevation": 1930,
      "lat": 33.7874183655,
      "lon": -113.642417908,
      "tz": "America\/Phoenix"
  },
  "AZ43": {
      "icao": "AZ43",
      "iata": "",
      "name": "Hidden Valley Airport",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1245,
      "lat": 33.0638999939,
      "lon": -112.1640014648,
      "tz": "America\/Phoenix"
  },
  "AZ45": {
      "icao": "AZ45",
      "iata": "",
      "name": "China Peak Observatory Airport",
      "city": "Klondyke",
      "state": "Arizona",
      "country": "US",
      "elevation": 4800,
      "lat": 32.7291984558,
      "lon": -110.2959976196,
      "tz": "America\/Phoenix"
  },
  "AZ46": {
      "icao": "AZ46",
      "iata": "",
      "name": "H&H Ranch Airstrip",
      "city": "Chino Valley",
      "state": "Arizona",
      "country": "US",
      "elevation": 5025,
      "lat": 34.7663993835,
      "lon": -112.5490036011,
      "tz": "America\/Phoenix"
  },
  "AZ49": {
      "icao": "AZ49",
      "iata": "",
      "name": "Walter Ranch Airport",
      "city": "Stanfield",
      "state": "Arizona",
      "country": "US",
      "elevation": 1615,
      "lat": 32.8955993652,
      "lon": -112.2539978027,
      "tz": "America\/Phoenix"
  },
  "AZ50": {
      "icao": "AZ50",
      "iata": "",
      "name": "Triangle Airpark",
      "city": "White Hills",
      "state": "Arizona",
      "country": "US",
      "elevation": 2419,
      "lat": 35.7146987915,
      "lon": -114.4810028076,
      "tz": "America\/Phoenix"
  },
  "AZ57": {
      "icao": "AZ57",
      "iata": "",
      "name": "Pilots Rest Airport",
      "city": "Paulden",
      "state": "Arizona",
      "country": "US",
      "elevation": 4482,
      "lat": 34.9308013916,
      "lon": -112.5120010376,
      "tz": "America\/Phoenix"
  },
  "AZ60": {
      "icao": "AZ60",
      "iata": "",
      "name": "Coyner Airstrip",
      "city": "Litchfield Park",
      "state": "Arizona",
      "country": "US",
      "elevation": 1170,
      "lat": 33.5050010681,
      "lon": -112.4729995728,
      "tz": "America\/Phoenix"
  },
  "AZ61": {
      "icao": "AZ61",
      "iata": "",
      "name": "G.M. Ranch Airport",
      "city": "Stanfield",
      "state": "Arizona",
      "country": "US",
      "elevation": 1616,
      "lat": 32.9016990662,
      "lon": -112.2379989624,
      "tz": "America\/Phoenix"
  },
  "AZ63": {
      "icao": "AZ63",
      "iata": "",
      "name": "Twin Hawks Airpark",
      "city": "Redrock",
      "state": "Arizona",
      "country": "US",
      "elevation": 2290,
      "lat": 32.5278015137,
      "lon": -111.1959991455,
      "tz": "America\/Phoenix"
  },
  "AZ64": {
      "icao": "AZ64",
      "iata": "",
      "name": "Rio Vista Hills Airport",
      "city": "Wickenburg",
      "state": "Arizona",
      "country": "US",
      "elevation": 2225,
      "lat": 33.9523010254,
      "lon": -112.68699646,
      "tz": "America\/Phoenix"
  },
  "AZ67": {
      "icao": "AZ67",
      "iata": "",
      "name": "El Tiro Gliderport",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 2100,
      "lat": 32.4269981384,
      "lon": -111.3899993896,
      "tz": "America\/Phoenix"
  },
  "AZ71": {
      "icao": "AZ71",
      "iata": "",
      "name": "Cooper Ranch Airport",
      "city": "Yarnell",
      "state": "Arizona",
      "country": "US",
      "elevation": 3440,
      "lat": 34.2153015137,
      "lon": -112.5360031128,
      "tz": "America\/Phoenix"
  },
  "AZ74": {
      "icao": "AZ74",
      "iata": "",
      "name": "Lone Mountain International Airport",
      "city": "Patagonia",
      "state": "Arizona",
      "country": "US",
      "elevation": 5350,
      "lat": 31.3551006317,
      "lon": -110.3740005493,
      "tz": "America\/Hermosillo"
  },
  "AZ77": {
      "icao": "AZ77",
      "iata": "",
      "name": "Sunrise Ranch Airport",
      "city": "Williams",
      "state": "Arizona",
      "country": "US",
      "elevation": 6958,
      "lat": 35.2383003235,
      "lon": -111.9359970093,
      "tz": "America\/Phoenix"
  },
  "AZ78": {
      "icao": "AZ78",
      "iata": "",
      "name": "Mel's Ranch Airport",
      "city": "Maricopa",
      "state": "Arizona",
      "country": "US",
      "elevation": 1420,
      "lat": 33.011100769,
      "lon": -112.2819976807,
      "tz": "America\/Phoenix"
  },
  "AZ79": {
      "icao": "AZ79",
      "iata": "",
      "name": "Airscrew Performance Flightpark Ultralightport",
      "city": "Glendale",
      "state": "Arizona",
      "country": "US",
      "elevation": 1112,
      "lat": 33.5299987793,
      "lon": -112.2170028687,
      "tz": "America\/Phoenix"
  },
  "AZ82": {
      "icao": "AZ82",
      "iata": "",
      "name": "Mogollon Airpark",
      "city": "Overgaard",
      "state": "Arizona",
      "country": "US",
      "elevation": 6657,
      "lat": 34.3973007202,
      "lon": -110.5299987793,
      "tz": "America\/Phoenix"
  },
  "AZ85": {
      "icao": "AZ85",
      "iata": "",
      "name": "Tonopah Airport",
      "city": "Tonopah",
      "state": "Arizona",
      "country": "US",
      "elevation": 1248,
      "lat": 33.5339012146,
      "lon": -112.9599990845,
      "tz": "America\/Phoenix"
  },
  "AZ86": {
      "icao": "AZ86",
      "iata": "",
      "name": "Coyote Run Gliderport",
      "city": "Coyote Springs",
      "state": "Arizona",
      "country": "US",
      "elevation": 4978,
      "lat": 34.6842002869,
      "lon": -112.2919998169,
      "tz": "America\/Phoenix"
  },
  "AZ90": {
      "icao": "AZ90",
      "iata": "",
      "name": "Hangar Haciendas Airport",
      "city": "Laveen",
      "state": "Arizona",
      "country": "US",
      "elevation": 1225,
      "lat": 33.3534011841,
      "lon": -112.1269989014,
      "tz": "America\/Phoenix"
  },
  "AZ97": {
      "icao": "AZ97",
      "iata": "",
      "name": "X Bar 1 Ranch (Lower) Airport",
      "city": "Kingman",
      "state": "Arizona",
      "country": "US",
      "elevation": 3710,
      "lat": 35.3483009338,
      "lon": -113.6890029907,
      "tz": "America\/Phoenix"
  },
  "BGAA": {
      "icao": "BGAA",
      "iata": "JEG",
      "name": "Aasiaat Airport",
      "city": "Aasiaat",
      "state": "Qaasuitsup",
      "country": "GL",
      "elevation": 74,
      "lat": 68.7218017578,
      "lon": -52.7846984863,
      "tz": "America\/Nuuk"
  },
  "BGBW": {
      "icao": "BGBW",
      "iata": "UAK",
      "name": "Narsarsuaq Airport",
      "city": "Narsarsuaq",
      "state": "Kujalleq",
      "country": "GL",
      "elevation": 112,
      "lat": 61.1604995728,
      "lon": -45.4259986877,
      "tz": "America\/Nuuk"
  },
  "BGCO": {
      "icao": "BGCO",
      "iata": "CNP",
      "name": "Neerlerit Inaat Airport",
      "city": "Neerlerit Inaat",
      "state": "Sermersooq",
      "country": "GL",
      "elevation": 45,
      "lat": 70.7431030273,
      "lon": -22.6504993439,
      "tz": "America\/Scoresbysund"
  },
  "BGDH": {
      "icao": "BGDH",
      "iata": "",
      "name": "Danmarkshavn Landing Strip",
      "city": "Danmarkshavn",
      "state": "",
      "country": "GL",
      "elevation": 0,
      "lat": 76.7666702271,
      "lon": -18.6666679382,
      "tz": "America\/Nuuk"
  },
  "BGGH": {
      "icao": "BGGH",
      "iata": "GOH",
      "name": "Godthaab \/ Nuuk Airport",
      "city": "Nuuk",
      "state": "",
      "country": "GL",
      "elevation": 283,
      "lat": 64.19090271,
      "lon": -51.6781005859,
      "tz": "America\/Nuuk"
  },
  "BGJN": {
      "icao": "BGJN",
      "iata": "JAV",
      "name": "Ilulissat Airport",
      "city": "Ilulissat",
      "state": "",
      "country": "GL",
      "elevation": 95,
      "lat": 69.2432022095,
      "lon": -51.0570983887,
      "tz": "America\/Nuuk"
  },
  "BGKK": {
      "icao": "BGKK",
      "iata": "KUS",
      "name": "Kulusuk Airport",
      "city": "Kulusuk",
      "state": "Sermersooq",
      "country": "GL",
      "elevation": 117,
      "lat": 65.573600769,
      "lon": -37.1236000061,
      "tz": "America\/Nuuk"
  },
  "BGMI": {
      "icao": "BGMI",
      "iata": "",
      "name": "Station Nord Landing Strip",
      "city": "",
      "state": "",
      "country": "GL",
      "elevation": 35,
      "lat": 81.6,
      "lon": -16.6666666667,
      "tz": "America\/Nuuk"
  },
  "BGMQ": {
      "icao": "BGMQ",
      "iata": "JSU",
      "name": "Maniitsoq Airport",
      "city": "Maniitsoq",
      "state": "",
      "country": "GL",
      "elevation": 91,
      "lat": 65.4124984741,
      "lon": -52.9393997192,
      "tz": "America\/Nuuk"
  },
  "BGMV": {
      "icao": "BGMV",
      "iata": "",
      "name": "Mestersvig Airport",
      "city": "Mestersvig",
      "state": "",
      "country": "GL",
      "elevation": 52,
      "lat": 72.2369003296,
      "lon": -23.9319000244,
      "tz": "America\/Scoresbysund"
  },
  "BGPT": {
      "icao": "BGPT",
      "iata": "JFR",
      "name": "Paamiut Airport",
      "city": "Paamiut",
      "state": "",
      "country": "GL",
      "elevation": 120,
      "lat": 62.0147361755,
      "lon": -49.6709365845,
      "tz": "America\/Nuuk"
  },
  "BGQQ": {
      "icao": "BGQQ",
      "iata": "NAQ",
      "name": "Qaanaaq Airport",
      "city": "Qaanaaq",
      "state": "",
      "country": "GL",
      "elevation": 51,
      "lat": 77.4886016846,
      "lon": -69.3887023926,
      "tz": "America\/Thule"
  },
  "BGSF": {
      "icao": "BGSF",
      "iata": "SFJ",
      "name": "Kangerlussuaq Airport",
      "city": "Kangerlussuaq",
      "state": "Qeqqata",
      "country": "GL",
      "elevation": 165,
      "lat": 67.0122218992,
      "lon": -50.7116031647,
      "tz": "America\/Nuuk"
  },
  "BGSS": {
      "icao": "BGSS",
      "iata": "JHS",
      "name": "Sisimiut Airport",
      "city": "Sisimiut",
      "state": "",
      "country": "GL",
      "elevation": 33,
      "lat": 66.9513015747,
      "lon": -53.7293014526,
      "tz": "America\/Nuuk"
  },
  "BGTL": {
      "icao": "BGTL",
      "iata": "THU",
      "name": "Thule Air Base",
      "city": "Thule",
      "state": "Qaasuitsup",
      "country": "GL",
      "elevation": 251,
      "lat": 76.5311965942,
      "lon": -68.7032012939,
      "tz": "America\/Thule"
  },
  "BGUK": {
      "icao": "BGUK",
      "iata": "JUV",
      "name": "Upernavik Airport",
      "city": "Upernavik",
      "state": "",
      "country": "GL",
      "elevation": 414,
      "lat": 72.7901992798,
      "lon": -56.1305999756,
      "tz": "America\/Nuuk"
  },
  "BGUQ": {
      "icao": "BGUQ",
      "iata": "JQA",
      "name": "Qaarsut Airport",
      "city": "Uummannaq",
      "state": "",
      "country": "GL",
      "elevation": 289,
      "lat": 70.7341995239,
      "lon": -52.6962013245,
      "tz": "America\/Nuuk"
  },
  "BIAE": {
      "icao": "BIAE",
      "iata": "",
      "name": "Arngerdareyri Airport",
      "city": "Arngerdareyri",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 20,
      "lat": 65.9047012329,
      "lon": -22.3633003235,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIAL": {
      "icao": "BIAL",
      "iata": "",
      "name": "Alftaver Airport",
      "city": "Alftaver",
      "state": "South",
      "country": "IS",
      "elevation": 160,
      "lat": 63.5400009155,
      "lon": -18.4493999481,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIAR": {
      "icao": "BIAR",
      "iata": "AEY",
      "name": "Akureyri Airport",
      "city": "Akureyri",
      "state": "Northeast",
      "country": "IS",
      "elevation": 6,
      "lat": 65.6600036621,
      "lon": -18.0727005005,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBA": {
      "icao": "BIBA",
      "iata": "",
      "name": "Bakki Airport",
      "city": "Bakki",
      "state": "South",
      "country": "IS",
      "elevation": 45,
      "lat": 63.556098938,
      "lon": -20.1375007629,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBD": {
      "icao": "BIBD",
      "iata": "BIU",
      "name": "Bildudalur Airport",
      "city": "Bildudalur",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 18,
      "lat": 65.6412963867,
      "lon": -23.5461997986,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBF": {
      "icao": "BIBF",
      "iata": "BGJ",
      "name": "Borgarfjordur eystri Airport",
      "city": "Borgarfjordur eystri",
      "state": "East",
      "country": "IS",
      "elevation": 80,
      "lat": 65.5164031982,
      "lon": -13.8050003052,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBI": {
      "icao": "BIBI",
      "iata": "",
      "name": "Baeir Airport",
      "city": "Baeir",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 60,
      "lat": 66.0999984741,
      "lon": -22.5669994354,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBK": {
      "icao": "BIBK",
      "iata": "BJD",
      "name": "Bakkafjordur Airport",
      "city": "Bakkafjordur",
      "state": "Northeast",
      "country": "IS",
      "elevation": 14,
      "lat": 66.0218963623,
      "lon": -14.8243999481,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBL": {
      "icao": "BIBL",
      "iata": "BLO",
      "name": "Hjaltabakki Airport",
      "city": "Blonduos",
      "state": "Northwest",
      "country": "IS",
      "elevation": 131,
      "lat": 65.6449966431,
      "lon": -20.2875003815,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBR": {
      "icao": "BIBR",
      "iata": "BQD",
      "name": "Budardalur Airport",
      "city": "Budardalur",
      "state": "West",
      "country": "IS",
      "elevation": 131,
      "lat": 65.075302124,
      "lon": -21.8003005981,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIBV": {
      "icao": "BIBV",
      "iata": "BXV",
      "name": "Breiddalsvik Airport",
      "city": "Breiddalsvik",
      "state": "East",
      "country": "IS",
      "elevation": 8,
      "lat": 64.7900009155,
      "lon": -14.0228004456,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIDA": {
      "icao": "BIDA",
      "iata": "",
      "name": "Dagverdara Airport",
      "city": "Dagverdara",
      "state": "West",
      "country": "IS",
      "elevation": 130,
      "lat": 64.7450027466,
      "lon": -23.7271995544,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIDV": {
      "icao": "BIDV",
      "iata": "DJU",
      "name": "Djupivogur Airport",
      "city": "Djupivogur",
      "state": "East",
      "country": "IS",
      "elevation": 9,
      "lat": 64.644203186,
      "lon": -14.2827997208,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIEG": {
      "icao": "BIEG",
      "iata": "EGS",
      "name": "Egilsstadir Airport",
      "city": "Egilsstadir",
      "state": "East",
      "country": "IS",
      "elevation": 76,
      "lat": 65.2833023071,
      "lon": -14.4013996124,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIEH": {
      "icao": "BIEH",
      "iata": "",
      "name": "Einholtsmelar Airport",
      "city": "Einholtsmelar",
      "state": "South",
      "country": "IS",
      "elevation": 361,
      "lat": 64.25,
      "lon": -20.2999992371,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIFF": {
      "icao": "BIFF",
      "iata": "FAS",
      "name": "Faskrudsfjordur Airport",
      "city": "Faskrudsfjordur",
      "state": "East",
      "country": "IS",
      "elevation": 15,
      "lat": 64.9317016602,
      "lon": -14.0606002808,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIFL": {
      "icao": "BIFL",
      "iata": "",
      "name": "Fludir Airport",
      "city": "Fludir",
      "state": "South",
      "country": "IS",
      "elevation": 270,
      "lat": 64.1427993774,
      "lon": -20.3260993958,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIFM": {
      "icao": "BIFM",
      "iata": "FAG",
      "name": "Fagurholsmyri Airport",
      "city": "Fagurholsmyri",
      "state": "East",
      "country": "IS",
      "elevation": 56,
      "lat": 63.8746986389,
      "lon": -16.6410999298,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIFZ": {
      "icao": "BIFZ",
      "iata": "",
      "name": "Forsaeti Airport",
      "city": "Forsaeti",
      "state": "South",
      "country": "IS",
      "elevation": 32,
      "lat": 63.8499984741,
      "lon": -20.716999054,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIGE": {
      "icao": "BIGE",
      "iata": "",
      "name": "Geysir Airport",
      "city": "Geysir",
      "state": "South",
      "country": "IS",
      "elevation": 350,
      "lat": 64.310798645,
      "lon": -20.2705993652,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIGF": {
      "icao": "BIGF",
      "iata": "GUU",
      "name": "Grundarfjordur Airport",
      "city": "Grundarfjordur",
      "state": "West",
      "country": "IS",
      "elevation": 17,
      "lat": 64.9914016724,
      "lon": -23.2247009277,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIGH": {
      "icao": "BIGH",
      "iata": "",
      "name": "Gunnarsholt Airport",
      "city": "Gunnarsholt",
      "state": "South",
      "country": "IS",
      "elevation": 200,
      "lat": 63.8532981873,
      "lon": -20.2628002167,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIGJ": {
      "icao": "BIGJ",
      "iata": "GJR",
      "name": "Gjogur Airport",
      "city": "Gjogur",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 83,
      "lat": 65.995300293,
      "lon": -21.3269004822,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIGR": {
      "icao": "BIGR",
      "iata": "GRY",
      "name": "Grimsey Airport",
      "city": "Grimsey",
      "state": "Northeast",
      "country": "IS",
      "elevation": 66,
      "lat": 66.5458,
      "lon": -18.0173,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIGS": {
      "icao": "BIGS",
      "iata": "",
      "name": "Grimsstadir Airport",
      "city": "Grimsstadir",
      "state": "Northeast",
      "country": "IS",
      "elevation": 1279,
      "lat": 65.6324996948,
      "lon": -16.1483001709,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHE": {
      "icao": "BIHE",
      "iata": "",
      "name": "Herdubreidarlindir Airport",
      "city": "Herdubreidarlindir",
      "state": "Northeast",
      "country": "IS",
      "elevation": 1500,
      "lat": 65.1885986328,
      "lon": -16.1947002411,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHI": {
      "icao": "BIHI",
      "iata": "",
      "name": "Hveravellir Airport",
      "city": "Hveravellir",
      "state": "Northwest",
      "country": "IS",
      "elevation": 2000,
      "lat": 64.886100769,
      "lon": -19.4925003052,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHK": {
      "icao": "BIHK",
      "iata": "HVK",
      "name": "Holmavik Airport",
      "city": "Holmavik",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 90,
      "lat": 65.7046966553,
      "lon": -21.6963996887,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHL": {
      "icao": "BIHL",
      "iata": "",
      "name": "Hella Airport",
      "city": "Hella",
      "state": "South",
      "country": "IS",
      "elevation": 66,
      "lat": 63.8358001709,
      "lon": -20.3775005341,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHN": {
      "icao": "BIHN",
      "iata": "HFN",
      "name": "Hornafjordur Airport",
      "city": "Hornafjordur",
      "state": "East",
      "country": "IS",
      "elevation": 24,
      "lat": 64.2956008911,
      "lon": -15.2271995544,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHR": {
      "icao": "BIHR",
      "iata": "",
      "name": "Hvolsvollur Airport",
      "city": "Hvolsvollur",
      "state": "South",
      "country": "IS",
      "elevation": 109,
      "lat": 63.7538986206,
      "lon": -20.2441997528,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHS": {
      "icao": "BIHS",
      "iata": "",
      "name": "Hrafnseyri Airport",
      "city": "Hrafnseyri",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 30,
      "lat": 65.75,
      "lon": -23.466999054,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHT": {
      "icao": "BIHT",
      "iata": "FLI",
      "name": "Holt Airport",
      "city": "Flateyri",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 10,
      "lat": 66.0141804294,
      "lon": -23.4416913986,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHU": {
      "icao": "BIHU",
      "iata": "HZK",
      "name": "Husavik Airport",
      "city": "Husavik",
      "state": "Northeast",
      "country": "IS",
      "elevation": 48,
      "lat": 65.9523010254,
      "lon": -17.4260005951,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHV": {
      "icao": "BIHV",
      "iata": "HVM",
      "name": "Krokstadarmelar Airport",
      "city": "Hvammstangi",
      "state": "Northwest",
      "country": "IS",
      "elevation": 164,
      "lat": 65.2664031982,
      "lon": -20.8469009399,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHX": {
      "icao": "BIHX",
      "iata": "",
      "name": "Hrauneyjarfoss Airport",
      "city": "Hrauneyjarfoss",
      "state": "South",
      "country": "IS",
      "elevation": 1200,
      "lat": 64.1999969482,
      "lon": -19.2833003998,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHY": {
      "icao": "BIHY",
      "iata": "",
      "name": "Hrisey Airport",
      "city": "Hrisey",
      "state": "Northeast",
      "country": "IS",
      "elevation": 15,
      "lat": 66,
      "lon": -18.3999996185,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIHZ": {
      "icao": "BIHZ",
      "iata": "",
      "name": "Husafell Airport",
      "city": "Husafell",
      "state": "West",
      "country": "IS",
      "elevation": 380,
      "lat": 64.6996994019,
      "lon": -20.883600235,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIID": {
      "icao": "BIID",
      "iata": "HLO",
      "name": "Ingjaldssandur Airport",
      "city": "Onundarfjordur",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 70,
      "lat": 66.0500030518,
      "lon": -23.696100235,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIIS": {
      "icao": "BIIS",
      "iata": "IFJ",
      "name": "Isafjordur Airport",
      "city": "Isafjordur",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 8,
      "lat": 66.0580978394,
      "lon": -23.1352996826,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIKA": {
      "icao": "BIKA",
      "iata": "",
      "name": "Kaldarmelar Airport",
      "city": "Kaldarmelar",
      "state": "West",
      "country": "IS",
      "elevation": 160,
      "lat": 64.7789001465,
      "lon": -22.2569007874,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIKE": {
      "icao": "BIKE",
      "iata": "",
      "name": "Kerlingafjoll Airport",
      "city": "Kerlingafjoll",
      "state": "South",
      "country": "IS",
      "elevation": 2100,
      "lat": 64.7050018311,
      "lon": -19.4106006622,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIKF": {
      "icao": "BIKF",
      "iata": "KEF",
      "name": "Keflavik International Airport",
      "city": "Reykjavik",
      "state": "Southern Peninsula",
      "country": "IS",
      "elevation": 171,
      "lat": 63.9850006104,
      "lon": -22.6056003571,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIKJ": {
      "icao": "BIKJ",
      "iata": "",
      "name": "Kroksfjard-Arnes Airport",
      "city": "Kroksfjard-arnes",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 49,
      "lat": 65.4670028687,
      "lon": -21.9500007629,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIKL": {
      "icao": "BIKL",
      "iata": "",
      "name": "Kirkjubaejarklaustur Airport",
      "city": "Kirkjubaejarklaustur",
      "state": "South",
      "country": "IS",
      "elevation": 66,
      "lat": 63.7907981873,
      "lon": -18.0013999939,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIKP": {
      "icao": "BIKP",
      "iata": "OPA",
      "name": "Kopasker Airport",
      "city": "Kopasker",
      "state": "Northeast",
      "country": "IS",
      "elevation": 20,
      "lat": 66.310798645,
      "lon": -16.4666996002,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIKR": {
      "icao": "BIKR",
      "iata": "SAK",
      "name": "Saudarkrokur Airport",
      "city": "Saudarkrokur",
      "state": "Northwest",
      "country": "IS",
      "elevation": 8,
      "lat": 65.7316970825,
      "lon": -19.5727996826,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIMK": {
      "icao": "BIMK",
      "iata": "",
      "name": "Mulakot Airport",
      "city": "Mulakot",
      "state": "South",
      "country": "IS",
      "elevation": 272,
      "lat": 63.7141990662,
      "lon": -19.8791999817,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIMM": {
      "icao": "BIMM",
      "iata": "",
      "name": "Melgerdismelar Airport",
      "city": "Melgerdismelar",
      "state": "Northeast",
      "country": "IS",
      "elevation": 35,
      "lat": 65.4832992554,
      "lon": -18.1667003632,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIMN": {
      "icao": "BIMN",
      "iata": "",
      "name": "Melanes Airport",
      "city": "Melanes",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 148,
      "lat": 65.516998291,
      "lon": -22.3999996185,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIMS": {
      "icao": "BIMS",
      "iata": "",
      "name": "Tungubakkar Airport",
      "city": "Tungubakkar",
      "state": "Capital Region",
      "country": "IS",
      "elevation": 5,
      "lat": 64.181098938,
      "lon": -21.7077999115,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIND": {
      "icao": "BIND",
      "iata": "",
      "name": "Nyjidalur Airport",
      "city": "Nyjidalur",
      "state": "South",
      "country": "IS",
      "elevation": 2625,
      "lat": 64.7205963135,
      "lon": -18.0666999817,
      "tz": "Atlantic\/Reykjavik"
  },
  "BINF": {
      "icao": "BINF",
      "iata": "NOR",
      "name": "Nordfjordur Airport",
      "city": "Nordfjordur",
      "state": "East",
      "country": "IS",
      "elevation": 13,
      "lat": 65.1318969727,
      "lon": -13.7463998795,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIOF": {
      "icao": "BIOF",
      "iata": "OFJ",
      "name": "Olafsfjordur Airport",
      "city": "Olafsfjordur",
      "state": "Northeast",
      "country": "IS",
      "elevation": 32,
      "lat": 66.0832977295,
      "lon": -18.6667003632,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIPA": {
      "icao": "BIPA",
      "iata": "PFJ",
      "name": "Patreksfjordur Airport",
      "city": "Patreksfjordur",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 11,
      "lat": 65.5558013916,
      "lon": -23.9650001526,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIRE": {
      "icao": "BIRE",
      "iata": "RHA",
      "name": "Reykholar Airport",
      "city": "Reykholar",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 60,
      "lat": 65.4526290894,
      "lon": -22.2061157227,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIRF": {
      "icao": "BIRF",
      "iata": "OLI",
      "name": "Rif Airport",
      "city": "Rif",
      "state": "West",
      "country": "IS",
      "elevation": 18,
      "lat": 64.9113998413,
      "lon": -23.8230991364,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIRG": {
      "icao": "BIRG",
      "iata": "RFN",
      "name": "Raufarhofn Airport",
      "city": "Raufarhofn",
      "state": "Northeast",
      "country": "IS",
      "elevation": 39,
      "lat": 66.4064025879,
      "lon": -15.918299675,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIRK": {
      "icao": "BIRK",
      "iata": "RKV",
      "name": "Reykjavik Airport",
      "city": "Reykjavik",
      "state": "Capital Region",
      "country": "IS",
      "elevation": 48,
      "lat": 64.1299972534,
      "lon": -21.9405994415,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIRL": {
      "icao": "BIRL",
      "iata": "MVA",
      "name": "Reykjahlid Airport",
      "city": "Myvatn",
      "state": "Northeast",
      "country": "IS",
      "elevation": 1030,
      "lat": 65.6557998657,
      "lon": -16.9181003571,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIRS": {
      "icao": "BIRS",
      "iata": "",
      "name": "Reykjanes Airport",
      "city": "Reykjanes",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 10,
      "lat": 65.9141998291,
      "lon": -22.4214000702,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISA": {
      "icao": "BISA",
      "iata": "",
      "name": "Sanda Airport",
      "city": "Sanda",
      "state": "Northwest",
      "country": "IS",
      "elevation": 1580,
      "lat": 65.1343994141,
      "lon": -19.6630992889,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISF": {
      "icao": "BISF",
      "iata": "",
      "name": "Selfoss Airport",
      "city": "Selfoss",
      "state": "South",
      "country": "IS",
      "elevation": 45,
      "lat": 63.9291992188,
      "lon": -21.0377998352,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISG": {
      "icao": "BISG",
      "iata": "",
      "name": "Steinasandur Airport",
      "city": "Steinasandur",
      "state": "East",
      "country": "IS",
      "elevation": 30,
      "lat": 64.1669998169,
      "lon": -15.8999996185,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISH": {
      "icao": "BISH",
      "iata": "",
      "name": "Stora-Holt Airport",
      "city": "Stora-Holt",
      "state": "West",
      "country": "IS",
      "elevation": 100,
      "lat": 65.4000015259,
      "lon": -21.9330005646,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISI": {
      "icao": "BISI",
      "iata": "SIJ",
      "name": "Siglufjordur Airport",
      "city": "Siglufjordur",
      "state": "Northeast",
      "country": "IS",
      "elevation": 10,
      "lat": 66.1333007813,
      "lon": -18.9167003632,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISK": {
      "icao": "BISK",
      "iata": "",
      "name": "Skogasandur Airport",
      "city": "Skogasandur",
      "state": "South",
      "country": "IS",
      "elevation": 130,
      "lat": 63.51720047,
      "lon": -19.489200592,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISN": {
      "icao": "BISN",
      "iata": "",
      "name": "Svinafell Airport",
      "city": "Svinafell",
      "state": "East",
      "country": "IS",
      "elevation": 60,
      "lat": 64.3833007813,
      "lon": -15.3832998276,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISP": {
      "icao": "BISP",
      "iata": "",
      "name": "Sprengisandur Airport",
      "city": "Sprengisandur",
      "state": "South",
      "country": "IS",
      "elevation": 2050,
      "lat": 64.6541976929,
      "lon": -18.4969005585,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISR": {
      "icao": "BISR",
      "iata": "",
      "name": "Storikroppur Airport",
      "city": "Storikroppur",
      "state": "West",
      "country": "IS",
      "elevation": 165,
      "lat": 64.6343994141,
      "lon": -21.4874992371,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISS": {
      "icao": "BISS",
      "iata": "",
      "name": "Sandskeid Airport",
      "city": "Sandskeid",
      "state": "Capital Region",
      "country": "IS",
      "elevation": 600,
      "lat": 64.060798645,
      "lon": -21.5746994019,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIST": {
      "icao": "BIST",
      "iata": "SYK",
      "name": "Stykkisholmur Airport",
      "city": "Stykkisholmur",
      "state": "West",
      "country": "IS",
      "elevation": 42,
      "lat": 65.0580978394,
      "lon": -22.7942008972,
      "tz": "Atlantic\/Reykjavik"
  },
  "BISV": {
      "icao": "BISV",
      "iata": "",
      "name": "Skalavatn Airport",
      "city": "Skalavatn",
      "state": "South",
      "country": "IS",
      "elevation": 2000,
      "lat": 64.1157989502,
      "lon": -18.7833003998,
      "tz": "Atlantic\/Reykjavik"
  },
  "BITE": {
      "icao": "BITE",
      "iata": "TEY",
      "name": "Tingeyri Airport",
      "city": "Tingeyri",
      "state": "Westfjords",
      "country": "IS",
      "elevation": 65,
      "lat": 65.870300293,
      "lon": -23.5599994659,
      "tz": "Atlantic\/Reykjavik"
  },
  "BITM": {
      "icao": "BITM",
      "iata": "",
      "name": "Torsmork Airport",
      "city": "Torsmork",
      "state": "South",
      "country": "IS",
      "elevation": 600,
      "lat": 63.6899986267,
      "lon": -19.5631008148,
      "tz": "Atlantic\/Reykjavik"
  },
  "BITN": {
      "icao": "BITN",
      "iata": "THO",
      "name": "Thorshofn Airport",
      "city": "Thorshofn",
      "state": "Northeast",
      "country": "IS",
      "elevation": 65,
      "lat": 66.21849823,
      "lon": -15.3355998993,
      "tz": "Atlantic\/Reykjavik"
  },
  "BITO": {
      "icao": "BITO",
      "iata": "",
      "name": "Thorisos Airport",
      "city": "Thorisos",
      "state": "South",
      "country": "IS",
      "elevation": 1840,
      "lat": 64.3499984741,
      "lon": -18.8500003815,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIVA": {
      "icao": "BIVA",
      "iata": "",
      "name": "Vatnsnes Airport",
      "city": "Vatnsnes",
      "state": "South",
      "country": "IS",
      "elevation": 0,
      "lat": 64.0330963135,
      "lon": -20.6511001587,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIVH": {
      "icao": "BIVH",
      "iata": "",
      "name": "Varmahlid Airport",
      "city": "Varmahlid",
      "state": "Northwest",
      "country": "IS",
      "elevation": 27,
      "lat": 65.5569458008,
      "lon": -19.4280567169,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIVI": {
      "icao": "BIVI",
      "iata": "",
      "name": "Vik Airport",
      "city": "Vik",
      "state": "South",
      "country": "IS",
      "elevation": 100,
      "lat": 63.4216995239,
      "lon": -18.888299942,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIVM": {
      "icao": "BIVM",
      "iata": "VEY",
      "name": "Vestmannaeyjar Airport",
      "city": "Vestmannaeyjar",
      "state": "South",
      "country": "IS",
      "elevation": 326,
      "lat": 63.4243011475,
      "lon": -20.2789001465,
      "tz": "Atlantic\/Reykjavik"
  },
  "BIVO": {
      "icao": "BIVO",
      "iata": "VPN",
      "name": "Vopnafjordur Airport",
      "city": "Vopnafjordur",
      "state": "East",
      "country": "IS",
      "elevation": 16,
      "lat": 65.7205963135,
      "lon": -14.8506002426,
      "tz": "Atlantic\/Reykjavik"
  },
  "BKPR": {
      "icao": "BKPR",
      "iata": "PRN",
      "name": "Pristina International Airport",
      "city": "Prishtina",
      "state": "Pristina",
      "country": "KS",
      "elevation": 1789,
      "lat": 42.5727996826,
      "lon": 21.0358009338,
      "tz": "Europe\/Belgrade"
  },
  "CA02": {
      "icao": "CA02",
      "iata": "",
      "name": "Patterson Airport",
      "city": "Patterson",
      "state": "California",
      "country": "US",
      "elevation": 168,
      "lat": 37.46849823,
      "lon": -121.1689987183,
      "tz": "America\/Los_Angeles"
  },
  "CA03": {
      "icao": "CA03",
      "iata": "",
      "name": "John Myers Airport",
      "city": "Planada",
      "state": "California",
      "country": "US",
      "elevation": 280,
      "lat": 37.349899292,
      "lon": -120.2939987183,
      "tz": "America\/Los_Angeles"
  },
  "CA04": {
      "icao": "CA04",
      "iata": "",
      "name": "Flying N Ranch Airport",
      "city": "Cottonwood",
      "state": "California",
      "country": "US",
      "elevation": 530,
      "lat": 40.3462982178,
      "lon": -122.3389968872,
      "tz": "America\/Los_Angeles"
  },
  "CA05": {
      "icao": "CA05",
      "iata": "",
      "name": "Machado Dusters Airport",
      "city": "Stratford",
      "state": "California",
      "country": "US",
      "elevation": 192,
      "lat": 36.1601982117,
      "lon": -119.81300354,
      "tz": "America\/Los_Angeles"
  },
  "CA06": {
      "icao": "CA06",
      "iata": "",
      "name": "Bates Field",
      "city": "Alturas",
      "state": "California",
      "country": "US",
      "elevation": 4400,
      "lat": 41.4178009033,
      "lon": -120.5690002441,
      "tz": "America\/Los_Angeles"
  },
  "CA07": {
      "icao": "CA07",
      "iata": "",
      "name": "Scheidel Ranch Airport",
      "city": "Pleasant Grove",
      "state": "California",
      "country": "US",
      "elevation": 28,
      "lat": 38.8401985168,
      "lon": -121.56199646,
      "tz": "America\/Los_Angeles"
  },
  "CA09": {
      "icao": "CA09",
      "iata": "",
      "name": "Round Mountain Airport",
      "city": "Klamath River",
      "state": "California",
      "country": "US",
      "elevation": 3500,
      "lat": 41.8861999512,
      "lon": -122.84400177,
      "tz": "America\/Los_Angeles"
  },
  "CA10": {
      "icao": "CA10",
      "iata": "",
      "name": "Coonrod Ranch Airport",
      "city": "Little Shasta",
      "state": "California",
      "country": "US",
      "elevation": 3010,
      "lat": 41.617099762,
      "lon": -122.3190002441,
      "tz": "America\/Los_Angeles"
  },
  "CA11": {
      "icao": "CA11",
      "iata": "",
      "name": "Mc Cloud Airstrip",
      "city": "Mc Cloud",
      "state": "California",
      "country": "US",
      "elevation": 3540,
      "lat": 41.2732009888,
      "lon": -122.122001648,
      "tz": "America\/Los_Angeles"
  },
  "CA13": {
      "icao": "CA13",
      "iata": "",
      "name": "Kings River Community College Airport",
      "city": "Reedley",
      "state": "California",
      "country": "US",
      "elevation": 350,
      "lat": 36.6068992615,
      "lon": -119.4629974365,
      "tz": "America\/Los_Angeles"
  },
  "CA16": {
      "icao": "CA16",
      "iata": "",
      "name": "Holtsmans Airport",
      "city": "Rio Linda",
      "state": "California",
      "country": "US",
      "elevation": 120,
      "lat": 38.7290992737,
      "lon": -121.388999939,
      "tz": "America\/Los_Angeles"
  },
  "CA17": {
      "icao": "CA17",
      "iata": "",
      "name": "Peterson Airport",
      "city": "Riverbank",
      "state": "California",
      "country": "US",
      "elevation": 158,
      "lat": 37.7234992981,
      "lon": -120.8899993896,
      "tz": "America\/Los_Angeles"
  },
  "CA18": {
      "icao": "CA18",
      "iata": "",
      "name": "Wagner Aviation Airport",
      "city": "Robbins",
      "state": "California",
      "country": "US",
      "elevation": 24,
      "lat": 38.8721008301,
      "lon": -121.7099990845,
      "tz": "America\/Los_Angeles"
  },
  "CA19": {
      "icao": "CA19",
      "iata": "",
      "name": "Camanche Skypark Airport",
      "city": "Ione",
      "state": "California",
      "country": "US",
      "elevation": 520,
      "lat": 38.2638015747,
      "lon": -120.922996521,
      "tz": "America\/Los_Angeles"
  },
  "CA20": {
      "icao": "CA20",
      "iata": "",
      "name": "Eagle's Nest Airport",
      "city": "Ione",
      "state": "California",
      "country": "US",
      "elevation": 220,
      "lat": 38.4305000305,
      "lon": -121.01499939,
      "tz": "America\/Los_Angeles"
  },
  "CA21": {
      "icao": "CA21",
      "iata": "",
      "name": "Limberlost Ranch Airport",
      "city": "Rough and Ready",
      "state": "California",
      "country": "US",
      "elevation": 1650,
      "lat": 39.2206993103,
      "lon": -121.2149963379,
      "tz": "America\/Los_Angeles"
  },
  "CA22": {
      "icao": "CA22",
      "iata": "",
      "name": "Lauppes Strip",
      "city": "Sacramento",
      "state": "California",
      "country": "US",
      "elevation": 23,
      "lat": 38.7593002319,
      "lon": -121.5849990845,
      "tz": "America\/Los_Angeles"
  },
  "CA32": {
      "icao": "CA32",
      "iata": "",
      "name": "San Joaquin Airport",
      "city": "San Joaquin",
      "state": "California",
      "country": "US",
      "elevation": 165,
      "lat": 36.5833015442,
      "lon": -120.2509994507,
      "tz": "America\/Los_Angeles"
  },
  "CA35": {
      "icao": "CA35",
      "iata": "",
      "name": "San Rafael Airport",
      "city": "San Rafael",
      "state": "California",
      "country": "US",
      "elevation": 5,
      "lat": 38.0168991089,
      "lon": -122.5210037231,
      "tz": "America\/Los_Angeles"
  },
  "CA38": {
      "icao": "CA38",
      "iata": "",
      "name": "Totem Pole Ranch Airport",
      "city": "Calpine",
      "state": "California",
      "country": "US",
      "elevation": 4986,
      "lat": 39.6512985229,
      "lon": -120.4390029907,
      "tz": "America\/Los_Angeles"
  },
  "CA39": {
      "icao": "CA39",
      "iata": "",
      "name": "Graywood Ranch Airport",
      "city": "Santa Rosa",
      "state": "California",
      "country": "US",
      "elevation": 450,
      "lat": 38.4346008301,
      "lon": -122.5640029907,
      "tz": "America\/Los_Angeles"
  },
  "CA40": {
      "icao": "CA40",
      "iata": "",
      "name": "Central Valley Aviation Inc Airport",
      "city": "Selma",
      "state": "California",
      "country": "US",
      "elevation": 276,
      "lat": 36.5213012695,
      "lon": -119.6699981689,
      "tz": "America\/Los_Angeles"
  },
  "CA41": {
      "icao": "CA41",
      "iata": "",
      "name": "Quinn Airport",
      "city": "Selma",
      "state": "California",
      "country": "US",
      "elevation": 310,
      "lat": 36.5833015442,
      "lon": -119.6399993896,
      "tz": "America\/Los_Angeles"
  },
  "CA42": {
      "icao": "CA42",
      "iata": "",
      "name": "Mc Conville Airstrip",
      "city": "Lake Elsinore",
      "state": "California",
      "country": "US",
      "elevation": 2600,
      "lat": 33.6436004639,
      "lon": -117.429000854,
      "tz": "America\/Los_Angeles"
  },
  "CA44": {
      "icao": "CA44",
      "iata": "",
      "name": "Sequoia Ranch Airport",
      "city": "Springville",
      "state": "California",
      "country": "US",
      "elevation": 1153,
      "lat": 36.1498985291,
      "lon": -118.8020019531,
      "tz": "America\/Los_Angeles"
  },
  "CA45": {
      "icao": "CA45",
      "iata": "",
      "name": "Stevinson Strip",
      "city": "Stevinson",
      "state": "California",
      "country": "US",
      "elevation": 79,
      "lat": 37.3249015808,
      "lon": -120.8679962158,
      "tz": "America\/Los_Angeles"
  },
  "CA49": {
      "icao": "CA49",
      "iata": "",
      "name": "Jones Farms Airport",
      "city": "Stratford",
      "state": "California",
      "country": "US",
      "elevation": 199,
      "lat": 36.2041015625,
      "lon": -119.841003418,
      "tz": "America\/Los_Angeles"
  },
  "CA51": {
      "icao": "CA51",
      "iata": "",
      "name": "The Sea Ranch Airport",
      "city": "The Sea Ranch",
      "state": "California",
      "country": "US",
      "elevation": 360,
      "lat": 38.7046012878,
      "lon": -123.4329986572,
      "tz": "America\/Los_Angeles"
  },
  "CA53": {
      "icao": "CA53",
      "iata": "",
      "name": "Tews Field",
      "city": "Redding",
      "state": "California",
      "country": "US",
      "elevation": 740,
      "lat": 40.6725006104,
      "lon": -122.3420028687,
      "tz": "America\/Los_Angeles"
  },
  "CA54": {
      "icao": "CA54",
      "iata": "",
      "name": "33 Strip",
      "city": "Tracy",
      "state": "California",
      "country": "US",
      "elevation": 81,
      "lat": 37.670501709,
      "lon": -121.3280029297,
      "tz": "America\/Los_Angeles"
  },
  "CA57": {
      "icao": "CA57",
      "iata": "",
      "name": "Blake Sky Park Airport",
      "city": "Vacaville",
      "state": "California",
      "country": "US",
      "elevation": 310,
      "lat": 38.4581985474,
      "lon": -122.0459976196,
      "tz": "America\/Los_Angeles"
  },
  "CA59": {
      "icao": "CA59",
      "iata": "",
      "name": "Vestal Strip",
      "city": "Verona",
      "state": "California",
      "country": "US",
      "elevation": 23,
      "lat": 38.7915992737,
      "lon": -121.5839996338,
      "tz": "America\/Los_Angeles"
  },
  "CA60": {
      "icao": "CA60",
      "iata": "",
      "name": "Deer Creek Ranch Airport",
      "city": "Vina",
      "state": "California",
      "country": "US",
      "elevation": 280,
      "lat": 39.9499015808,
      "lon": -121.9970016479,
      "tz": "America\/Los_Angeles"
  },
  "CA62": {
      "icao": "CA62",
      "iata": "",
      "name": "Mc Millan Assault Strip",
      "city": "Camp Roberts",
      "state": "California",
      "country": "US",
      "elevation": 920,
      "lat": 35.71910095,
      "lon": -120.7679977,
      "tz": "America\/Los_Angeles"
  },
  "CA64": {
      "icao": "CA64",
      "iata": "",
      "name": "Gilbreath Bros Duck Club Airport",
      "city": "Wasco",
      "state": "California",
      "country": "US",
      "elevation": 220,
      "lat": 35.6869010925,
      "lon": -119.5999984741,
      "tz": "America\/Los_Angeles"
  },
  "CA66": {
      "icao": "CA66",
      "iata": "",
      "name": "Monterey Bay Academy Airport",
      "city": "Watsonville",
      "state": "California",
      "country": "US",
      "elevation": 70,
      "lat": 36.9061012268,
      "lon": -121.8430023193,
      "tz": "America\/Los_Angeles"
  },
  "CA67": {
      "icao": "CA67",
      "iata": "",
      "name": "Westley Airport",
      "city": "Westley",
      "state": "California",
      "country": "US",
      "elevation": 96,
      "lat": 37.5480003357,
      "lon": -121.2050018311,
      "tz": "America\/Los_Angeles"
  },
  "CA69": {
      "icao": "CA69",
      "iata": "",
      "name": "Avenal Airport",
      "city": "Avenal",
      "state": "California",
      "country": "US",
      "elevation": 790,
      "lat": 36.0041007996,
      "lon": -120.141998291,
      "tz": "America\/Los_Angeles"
  },
  "CA70": {
      "icao": "CA70",
      "iata": "",
      "name": "Sun Hill Ranch Airport",
      "city": "Helendale",
      "state": "California",
      "country": "US",
      "elevation": 2984,
      "lat": 34.7579994202,
      "lon": -117.4970016479,
      "tz": "America\/Los_Angeles"
  },
  "CA71": {
      "icao": "CA71",
      "iata": "",
      "name": "Horse Shoe A Ranch Airport",
      "city": "Drytown",
      "state": "California",
      "country": "US",
      "elevation": 750,
      "lat": 38.4399986267,
      "lon": -120.8870010376,
      "tz": "America\/Los_Angeles"
  },
  "CA73": {
      "icao": "CA73",
      "iata": "",
      "name": "Vanderford Ranch Company Airport",
      "city": "Yuba City",
      "state": "California",
      "country": "US",
      "elevation": 42,
      "lat": 39.0956993103,
      "lon": -121.716003418,
      "tz": "America\/Los_Angeles"
  },
  "CA75": {
      "icao": "CA75",
      "iata": "",
      "name": "Reider Ranch Airport",
      "city": "Potrero",
      "state": "California",
      "country": "US",
      "elevation": 2655,
      "lat": 32.6380996704,
      "lon": -116.638999939,
      "tz": "America\/Los_Angeles"
  },
  "CA76": {
      "icao": "CA76",
      "iata": "",
      "name": "Flying T Ranch Airport",
      "city": "Ramona",
      "state": "California",
      "country": "US",
      "elevation": 2500,
      "lat": 33.0703010559,
      "lon": -116.7519989014,
      "tz": "America\/Los_Angeles"
  },
  "CA84": {
      "icao": "CA84",
      "iata": "",
      "name": "Torrey Pines Gliderport",
      "city": "San Diego",
      "state": "California",
      "country": "US",
      "elevation": 372,
      "lat": 32.8897018433,
      "lon": -117.2470016479,
      "tz": "America\/Los_Angeles"
  },
  "CA88": {
      "icao": "CA88",
      "iata": "",
      "name": "San Ardo Field",
      "city": "San Ardo",
      "state": "California",
      "country": "US",
      "elevation": 441,
      "lat": 36.0261001587,
      "lon": -120.9079971313,
      "tz": "America\/Los_Angeles"
  },
  "CA89": {
      "icao": "CA89",
      "iata": "",
      "name": "Skylark Field",
      "city": "Lake Elsinore",
      "state": "California",
      "country": "US",
      "elevation": 1253,
      "lat": 33.6300010681,
      "lon": -117.3020019531,
      "tz": "America\/Los_Angeles"
  },
  "CA90": {
      "icao": "CA90",
      "iata": "",
      "name": "Cadiz Airstrip",
      "city": "Cadiz",
      "state": "California",
      "country": "US",
      "elevation": 780,
      "lat": 34.5139007568,
      "lon": -115.5189971924,
      "tz": "America\/Los_Angeles"
  },
  "CA92": {
      "icao": "CA92",
      "iata": "",
      "name": "Paradise Skypark Airport",
      "city": "Paradise",
      "state": "California",
      "country": "US",
      "elevation": 1300,
      "lat": 39.7106018066,
      "lon": -121.616996765,
      "tz": "America\/Los_Angeles"
  },
  "CA97": {
      "icao": "CA97",
      "iata": "",
      "name": "Christy Airstrip",
      "city": "Santa Barbara",
      "state": "California",
      "country": "US",
      "elevation": 250,
      "lat": 34.0196990967,
      "lon": -119.8529968262,
      "tz": "America\/Los_Angeles"
  },
  "CAA2": {
      "icao": "CAA2",
      "iata": "",
      "name": "St. Andre-Avellin",
      "city": "St. Andre Avellin",
      "state": "Quebec",
      "country": "CA",
      "elevation": 550,
      "lat": 45.7463888889,
      "lon": -75.0758333333,
      "tz": "America\/Toronto"
  },
  "CAA8": {
      "icao": "CAA8",
      "iata": "",
      "name": "Invermere Airport",
      "city": "Invermere",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2820,
      "lat": 50.5167007446,
      "lon": -116,
      "tz": "America\/Edmonton"
  },
  "CAD4": {
      "icao": "CAD4",
      "iata": "YZZ",
      "name": "Trail Airport",
      "city": "Trail",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1427,
      "lat": 49.0555992126,
      "lon": -117.60900116,
      "tz": "America\/Vancouver"
  },
  "CAD5": {
      "icao": "CAD5",
      "iata": "",
      "name": "Merritt Airport",
      "city": "Merritt",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2080,
      "lat": 50.1227989197,
      "lon": -120.7470016479,
      "tz": "America\/Vancouver"
  },
  "CAE4": {
      "icao": "CAE4",
      "iata": "",
      "name": "Tsacha Lake Airport",
      "city": "Tsacha Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3450,
      "lat": 53.0167007446,
      "lon": -124.8330001831,
      "tz": "America\/Vancouver"
  },
  "CAF4": {
      "icao": "CAF4",
      "iata": "",
      "name": "Tsuniah Lake Lodge Airport",
      "city": "Tsuniah Lake Lodge",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 4000,
      "lat": 51.5332984924,
      "lon": -124.1669998169,
      "tz": "America\/Vancouver"
  },
  "CAG2": {
      "icao": "CAG2",
      "iata": "",
      "name": "Regina \/ Aerogate",
      "city": "Brora",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1980,
      "lat": 50.5977310536,
      "lon": -104.601312876,
      "tz": "America\/Regina"
  },
  "CAG3": {
      "icao": "CAG3",
      "iata": "",
      "name": "Chilko Lake (Tsylos Park Lodge) Airport",
      "city": "Chilko Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3850,
      "lat": 51.6260986328,
      "lon": -124.141998291,
      "tz": "America\/Vancouver"
  },
  "CAH3": {
      "icao": "CAH3",
      "iata": "",
      "name": "Courtenay Airpark",
      "city": "Courtenay",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 9,
      "lat": 49.6794013977,
      "lon": -124.9820022583,
      "tz": "America\/Vancouver"
  },
  "CAH4": {
      "icao": "CAH4",
      "iata": "",
      "name": "Valemount Airport",
      "city": "Valemount",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2615,
      "lat": 52.8532981873,
      "lon": -119.3339996338,
      "tz": "America\/Vancouver"
  },
  "CAJ2": {
      "icao": "CAJ2",
      "iata": "",
      "name": "Wiley Airport",
      "city": "Eagle Plains",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2365,
      "lat": 66.4910964966,
      "lon": -136.5729980469,
      "tz": "America\/Whitehorse"
  },
  "CAJ3": {
      "icao": "CAJ3",
      "iata": "",
      "name": "Art Sutcliffe Field",
      "city": "Creston",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2070,
      "lat": 49.0368995667,
      "lon": -116.4980010986,
      "tz": "America\/Creston"
  },
  "CAJ4": {
      "icao": "CAJ4",
      "iata": "YAA",
      "name": "Anahim Lake Airport",
      "city": "Anahim Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3635,
      "lat": 52.4524993896,
      "lon": -125.3030014038,
      "tz": "America\/Vancouver"
  },
  "CAJ7": {
      "icao": "CAJ7",
      "iata": "",
      "name": "Cayley A J Flying Ranch Airport",
      "city": "Cayley",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3450,
      "lat": 50.4500007629,
      "lon": -113.75,
      "tz": "America\/Edmonton"
  },
  "CAJ9": {
      "icao": "CAJ9",
      "iata": "",
      "name": "Fort Ware Airport",
      "city": "Fort Ware",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2450,
      "lat": 57.4272003174,
      "lon": -125.6500015259,
      "tz": "America\/Vancouver"
  },
  "CAK3": {
      "icao": "CAK3",
      "iata": "",
      "name": "Delta Heritage Air Park",
      "city": "Delta",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 10,
      "lat": 49.0774002075,
      "lon": -122.9410018921,
      "tz": "America\/Vancouver"
  },
  "CAL3": {
      "icao": "CAL3",
      "iata": "DGF",
      "name": "Douglas Lake Airport",
      "city": "Douglas Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2770,
      "lat": 50.1654600485,
      "lon": -120.171273351,
      "tz": "America\/Vancouver"
  },
  "CAL4": {
      "icao": "CAL4",
      "iata": "JHL",
      "name": "Fort MacKay\/Albian Aerodrome",
      "city": "Albian Village",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1048,
      "lat": 57.2238998413,
      "lon": -111.418998718,
      "tz": "America\/Edmonton"
  },
  "CAM3": {
      "icao": "CAM3",
      "iata": "DUQ",
      "name": "Duncan Airport",
      "city": "Duncan",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 300,
      "lat": 48.7545336204,
      "lon": -123.709702492,
      "tz": "America\/Vancouver"
  },
  "CAM4": {
      "icao": "CAM4",
      "iata": "",
      "name": "Alhambra \/ Ahlstrom",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3212,
      "lat": 52.3461940388,
      "lon": -114.667525291,
      "tz": "America\/Edmonton"
  },
  "CAM5": {
      "icao": "CAM5",
      "iata": "",
      "name": "Houston Airport",
      "city": "Houston",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2150,
      "lat": 54.4392009821,
      "lon": -126.778879166,
      "tz": "America\/Vancouver"
  },
  "CAP3": {
      "icao": "CAP3",
      "iata": "",
      "name": "Sechelt-Gibsons Airport",
      "city": "Sechelt-Gibsons",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 250,
      "lat": 49.4606018066,
      "lon": -123.71900177,
      "tz": "America\/Vancouver"
  },
  "CAP6": {
      "icao": "CAP6",
      "iata": "",
      "name": "Ingenika Airport",
      "city": "Ingenika",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2230,
      "lat": 56.790599823,
      "lon": -124.8970031738,
      "tz": "America\/Vancouver"
  },
  "CAQ4": {
      "icao": "CAQ4",
      "iata": "",
      "name": "Springhouse Airpark",
      "city": "Springhouse",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3250,
      "lat": 51.9556007385,
      "lon": -122.138999939,
      "tz": "America\/Vancouver"
  },
  "CAQ5": {
      "icao": "CAQ5",
      "iata": "",
      "name": "Nakusp Airport",
      "city": "Nakusp",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1689,
      "lat": 50.2663993835,
      "lon": -117.81300354,
      "tz": "America\/Vancouver"
  },
  "CAR2": {
      "icao": "CAR2",
      "iata": "",
      "name": "Crawford Bay Airport",
      "city": "Crawford Bay",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1760,
      "lat": 49.6666984558,
      "lon": -116.8170013428,
      "tz": "America\/Creston"
  },
  "CAR5": {
      "icao": "CAR5",
      "iata": "",
      "name": "Arthur (Arthur South)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1530,
      "lat": 43.7827292694,
      "lon": -80.4329252243,
      "tz": "America\/Toronto"
  },
  "CAS2": {
      "icao": "CAS2",
      "iata": "",
      "name": "Moose Lake (Lodge) Airport",
      "city": "Moose Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3500,
      "lat": 53.073299408,
      "lon": -125.408996582,
      "tz": "America\/Vancouver"
  },
  "CAS3": {
      "icao": "CAS3",
      "iata": "",
      "name": "Barkerville Airport",
      "city": "Barkerville",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 4060,
      "lat": 53.0881004333,
      "lon": -121.5149993896,
      "tz": "America\/Vancouver"
  },
  "CAT1": {
      "icao": "CAT1",
      "iata": "",
      "name": "Atwood \/ Coghlin Airport",
      "city": "Atwood",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1215,
      "lat": 43.6833000183,
      "lon": -81.0044021606,
      "tz": "America\/Toronto"
  },
  "CAT4": {
      "icao": "CAT4",
      "iata": "",
      "name": "Qualicum Beach Airport",
      "city": "Qualicum Beach",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 191,
      "lat": 49.3372001648,
      "lon": -124.3939971924,
      "tz": "America\/Vancouver"
  },
  "CAT5": {
      "icao": "CAT5",
      "iata": "",
      "name": "Port Mcneill Airport",
      "city": "Port Mcneill",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 225,
      "lat": 50.5755996704,
      "lon": -127.0289993286,
      "tz": "America\/Vancouver"
  },
  "CAU3": {
      "icao": "CAU3",
      "iata": "",
      "name": "Oliver Airport",
      "city": "Oliver",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1015,
      "lat": 49.1733016968,
      "lon": -119.5510025024,
      "tz": "America\/Vancouver"
  },
  "CAU4": {
      "icao": "CAU4",
      "iata": "",
      "name": "Vanderhoof Airport",
      "city": "Vanderhoof",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2225,
      "lat": 54.0461006165,
      "lon": -124.0120010376,
      "tz": "America\/Vancouver"
  },
  "CAV3": {
      "icao": "CAV3",
      "iata": "",
      "name": "One Hundred Mile House Airport",
      "city": "One Hundred Mile House",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3055,
      "lat": 51.6425018311,
      "lon": -121.3069992065,
      "tz": "America\/Vancouver"
  },
  "CAV4": {
      "icao": "CAV4",
      "iata": "",
      "name": "Mcbride Airport",
      "city": "Mcbride",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2350,
      "lat": 53.3149986267,
      "lon": -120.1709976196,
      "tz": "America\/Vancouver"
  },
  "CAV6": {
      "icao": "CAV6",
      "iata": "",
      "name": "Beausejour\/Av-Ranch Airpark",
      "city": "",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 800,
      "lat": 50.041111,
      "lon": -96.585833,
      "tz": "America\/Winnipeg"
  },
  "CAV9": {
      "icao": "CAV9",
      "iata": "",
      "name": "Oak Hammock Air Park",
      "city": "Oak Hammock",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 755,
      "lat": 50.1377983093,
      "lon": -97.0614013672,
      "tz": "America\/Winnipeg"
  },
  "CAW3": {
      "icao": "CAW3",
      "iata": "",
      "name": "Scum Lake Airport",
      "city": "Scum Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3950,
      "lat": 51.7999992371,
      "lon": -123.5830001831,
      "tz": "America\/Vancouver"
  },
  "CAX2": {
      "icao": "CAX2",
      "iata": "",
      "name": "Axe Lake",
      "city": "",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1758,
      "lat": 57.2691650391,
      "lon": -109.8475036621,
      "tz": "America\/Regina"
  },
  "CAX5": {
      "icao": "CAX5",
      "iata": "",
      "name": "Likely Airport",
      "city": "Likely",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3225,
      "lat": 52.6166992188,
      "lon": -121.5,
      "tz": "America\/Vancouver"
  },
  "CAY2": {
      "icao": "CAY2",
      "iata": "",
      "name": "Gang Ranch Airport",
      "city": "Gang Ranch",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2150,
      "lat": 51.551700592,
      "lon": -122.327003479,
      "tz": "America\/Vancouver"
  },
  "CAZ5": {
      "icao": "CAZ5",
      "iata": "",
      "name": "Cache Creek-Ashcroft Regional Airport",
      "city": "Cache Creek",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2034,
      "lat": 50.7751091109,
      "lon": -121.321227551,
      "tz": "America\/Vancouver"
  },
  "CBA8": {
      "icao": "CBA8",
      "iata": "",
      "name": "Beaverley Airport",
      "city": "Beaverley",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2420,
      "lat": 53.8555984497,
      "lon": -122.9079971313,
      "tz": "America\/Vancouver"
  },
  "CBA9": {
      "icao": "CBA9",
      "iata": "",
      "name": "Ospika Airport",
      "city": "Ospika",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2300,
      "lat": 56.2750015259,
      "lon": -124.0520019531,
      "tz": "America\/Vancouver"
  },
  "CBB7": {
      "icao": "CBB7",
      "iata": "",
      "name": "Tipella Airport",
      "city": "Tipella",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 55,
      "lat": 49.7430992126,
      "lon": -122.1630020142,
      "tz": "America\/Vancouver"
  },
  "CBB9": {
      "icao": "CBB9",
      "iata": "",
      "name": "Osoyoos Airport",
      "city": "Osoyoos",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1100,
      "lat": 49.0372009277,
      "lon": -119.488998413,
      "tz": "America\/Los_Angeles"
  },
  "CBBC": {
      "icao": "CBBC",
      "iata": "ZEL",
      "name": "Bella Bella (Campbell Island) Airport",
      "city": "Bella Bella",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 141,
      "lat": 52.1850013733,
      "lon": -128.1569976807,
      "tz": "America\/Vancouver"
  },
  "CBC2": {
      "icao": "CBC2",
      "iata": "",
      "name": "Ford Bay Airport",
      "city": "Ford Bay",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 673,
      "lat": 66.0374984741,
      "lon": -124.7149963379,
      "tz": "America\/Inuvik"
  },
  "CBD6": {
      "icao": "CBD6",
      "iata": "",
      "name": "Nahanni Butte Airport",
      "city": "Nahanni Butte",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 600,
      "lat": 61.0297012329,
      "lon": -123.388999939,
      "tz": "America\/Inuvik"
  },
  "CBE2": {
      "icao": "CBE2",
      "iata": "",
      "name": "Elko Airport",
      "city": "Elko",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2850,
      "lat": 49.2832984924,
      "lon": -115.1500015259,
      "tz": "America\/Edmonton"
  },
  "CBF2": {
      "icao": "CBF2",
      "iata": "",
      "name": "Belwood (Baird Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1410,
      "lat": 43.808333333,
      "lon": -80.3119444444,
      "tz": "America\/Toronto"
  },
  "CBF9": {
      "icao": "CBF9",
      "iata": "",
      "name": "Mabel Lake Airport",
      "city": "Mabel Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1410,
      "lat": 50.6088981628,
      "lon": -118.7310028076,
      "tz": "America\/Vancouver"
  },
  "CBG2": {
      "icao": "CBG2",
      "iata": "",
      "name": "Green Lake Airport",
      "city": "Green Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3550,
      "lat": 51.4294013977,
      "lon": -121.2099990845,
      "tz": "America\/Vancouver"
  },
  "CBH2": {
      "icao": "CBH2",
      "iata": "",
      "name": "Helmet Airport",
      "city": "Helmet",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1930,
      "lat": 59.4258003235,
      "lon": -120.797996521,
      "tz": "America\/Fort_Nelson"
  },
  "CBH4": {
      "icao": "CBH4",
      "iata": "",
      "name": "Prairie Creek Airport",
      "city": "Prairie Creek",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 2950,
      "lat": 61.5647010803,
      "lon": -124.8150024414,
      "tz": "America\/Inuvik"
  },
  "CBJ4": {
      "icao": "CBJ4",
      "iata": "",
      "name": "Echo Valley Airport",
      "city": "Echo Valley",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3650,
      "lat": 51.2416992188,
      "lon": -121.9940032959,
      "tz": "America\/Vancouver"
  },
  "CBK6": {
      "icao": "CBK6",
      "iata": "",
      "name": "Quesnel Lake Airport",
      "city": "Quesnel Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2500,
      "lat": 52.5149993896,
      "lon": -121.0449981689,
      "tz": "America\/Vancouver"
  },
  "CBK7": {
      "icao": "CBK7",
      "iata": "",
      "name": "Mile 422 (Alaska Highway) Airport",
      "city": "Toad River",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2400,
      "lat": 58.8493995667,
      "lon": -125.2399978638,
      "tz": "America\/Fort_Nelson"
  },
  "CBL3": {
      "icao": "CBL3",
      "iata": "",
      "name": "Gordon Field",
      "city": "Fort Nelson",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1625,
      "lat": 58.8166999817,
      "lon": -122.7829971313,
      "tz": "America\/Fort_Nelson"
  },
  "CBL6": {
      "icao": "CBL6",
      "iata": "",
      "name": "Radium Hot Springs Airport",
      "city": "Radium Hot Springs",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2650,
      "lat": 50.6333007813,
      "lon": -116.0999984741,
      "tz": "America\/Edmonton"
  },
  "CBL9": {
      "icao": "CBL9",
      "iata": "",
      "name": "Elkin Creek Guest Ranch Airport",
      "city": "Elkin Creek",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 4080,
      "lat": 51.5127983093,
      "lon": -123.8040008545,
      "tz": "America\/Vancouver"
  },
  "CBM5": {
      "icao": "CBM5",
      "iata": "",
      "name": "Telegraph Creek Airport",
      "city": "Telegraph Creek",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1100,
      "lat": 57.9166984558,
      "lon": -131.1170043945,
      "tz": "America\/Vancouver"
  },
  "CBM6": {
      "icao": "CBM6",
      "iata": "",
      "name": "Midway Airport",
      "city": "Midway",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1896,
      "lat": 49.0099983215,
      "lon": -118.7900009155,
      "tz": "America\/Los_Angeles"
  },
  "CBN9": {
      "icao": "CBN9",
      "iata": "",
      "name": "Tsay Keh Airport",
      "city": "Tsay Keh",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2280,
      "lat": 56.9061012268,
      "lon": -124.9649963379,
      "tz": "America\/Vancouver"
  },
  "CBQ2": {
      "icao": "CBQ2",
      "iata": "",
      "name": "Fort Langley Airport",
      "city": "Fort Langley",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 30,
      "lat": 49.1674995422,
      "lon": -122.5550003052,
      "tz": "America\/Vancouver"
  },
  "CBQ7": {
      "icao": "CBQ7",
      "iata": "",
      "name": "Kemess Creek Airport",
      "city": "Kemess Creek",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 4191,
      "lat": 56.9743995667,
      "lon": -126.7409973145,
      "tz": "America\/Vancouver"
  },
  "CBQ8": {
      "icao": "CBQ8",
      "iata": "",
      "name": "Woodcock Airport",
      "city": "Woodcock",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 537,
      "lat": 55.0666999817,
      "lon": -128.233001709,
      "tz": "America\/Vancouver"
  },
  "CBR2": {
      "icao": "CBR2",
      "iata": "",
      "name": "Kaslo Airport",
      "city": "Kaslo",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2354,
      "lat": 49.9035987854,
      "lon": -116.9349975586,
      "tz": "America\/Vancouver"
  },
  "CBR4": {
      "icao": "CBR4",
      "iata": "",
      "name": "Clinton \/ Bleibler Ranch",
      "city": "Clinton",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3695,
      "lat": 51.2668021597,
      "lon": -121.685771942,
      "tz": "America\/Vancouver"
  },
  "CBS4": {
      "icao": "CBS4",
      "iata": "",
      "name": "Mule Creek Airport",
      "city": "Mule Creek",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2900,
      "lat": 59.7855987549,
      "lon": -136.5829925537,
      "tz": "America\/Vancouver"
  },
  "CBS7": {
      "icao": "CBS7",
      "iata": "",
      "name": "Briercrest South Airport",
      "city": "Briercrest South",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2120,
      "lat": 50.0663986206,
      "lon": -105.3010025024,
      "tz": "America\/Regina"
  },
  "CBS8": {
      "icao": "CBS8",
      "iata": "",
      "name": "Port Alberni Airport",
      "city": "Port Alberni",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 250,
      "lat": 49.3218994141,
      "lon": -124.9309997559,
      "tz": "America\/Vancouver"
  },
  "CBT3": {
      "icao": "CBT3",
      "iata": "",
      "name": "Tsetzi Lake (Pan Phillips) Airport",
      "city": "Tsetzi Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3550,
      "lat": 52.9719009399,
      "lon": -125.0270004272,
      "tz": "America\/Vancouver"
  },
  "CBT6": {
      "icao": "CBT6",
      "iata": "",
      "name": "Quilchena Airport",
      "city": "Quilchena",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2100,
      "lat": 50.1618895996,
      "lon": -120.507745743,
      "tz": "America\/Vancouver"
  },
  "CBU2": {
      "icao": "CBU2",
      "iata": "",
      "name": "Eddontenajon \/ Iskut Village Airport",
      "city": "Eddontenajon",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3100,
      "lat": 57.847994155,
      "lon": -129.983968735,
      "tz": "America\/Vancouver"
  },
  "CBW2": {
      "icao": "CBW2",
      "iata": "",
      "name": "Kitimat Airport",
      "city": "Kitimat",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 250,
      "lat": 54.163898468,
      "lon": -128.5809936523,
      "tz": "America\/Vancouver"
  },
  "CBW3": {
      "icao": "CBW3",
      "iata": "",
      "name": "Fort Grahame Airport",
      "city": "Fort Graham",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2230,
      "lat": 56.5213877877,
      "lon": -124.470291138,
      "tz": "America\/Vancouver"
  },
  "CBW4": {
      "icao": "CBW4",
      "iata": "",
      "name": "Bob Quinn Lake Airport",
      "city": "Bob Quinn Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2000,
      "lat": 56.9667015076,
      "lon": -130.25,
      "tz": "America\/Vancouver"
  },
  "CBW6": {
      "icao": "CBW6",
      "iata": "",
      "name": "Belwood (Wright Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1490,
      "lat": 43.793888889,
      "lon": -80.4025,
      "tz": "America\/Toronto"
  },
  "CBW8": {
      "icao": "CBW8",
      "iata": "",
      "name": "Prince George (North Cariboo Air Park) Airport",
      "city": "Prince George",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2500,
      "lat": 54.0080986023,
      "lon": -123.0230026245,
      "tz": "America\/Vancouver"
  },
  "CBX5": {
      "icao": "CBX5",
      "iata": "",
      "name": "Tungsten (Cantung) Airport",
      "city": "Tungsten",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 3500,
      "lat": 61.9569015503,
      "lon": -128.2030029297,
      "tz": "America\/Inuvik"
  },
  "CBX7": {
      "icao": "CBX7",
      "iata": "",
      "name": "Tumbler Ridge Airport",
      "city": "Tumbler Ridge",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3075,
      "lat": 55.0250015259,
      "lon": -120.9349975586,
      "tz": "America\/Dawson_Creek"
  },
  "CBZ9": {
      "icao": "CBZ9",
      "iata": "",
      "name": "Fraser Lake Airport",
      "city": "Fraser Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2690,
      "lat": 54.0133018494,
      "lon": -124.7679977417,
      "tz": "America\/Vancouver"
  },
  "CCA3": {
      "icao": "CCA3",
      "iata": "",
      "name": "Cable Head Airpark",
      "city": "Cable Head",
      "state": "Prince Edward Island",
      "country": "CA",
      "elevation": 94,
      "lat": 46.4432983398,
      "lon": -62.5928001404,
      "tz": "America\/Halifax"
  },
  "CCA6": {
      "icao": "CCA6",
      "iata": "YWM",
      "name": "Williams Harbour Airport",
      "city": "Williams Harbour",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 70,
      "lat": 52.5668983459,
      "lon": -55.7846984863,
      "tz": "America\/St_Johns"
  },
  "CCA7": {
      "icao": "CCA7",
      "iata": "",
      "name": "Apple River Airport",
      "city": "Apple River",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 135,
      "lat": 45.4618988037,
      "lon": -64.8214035034,
      "tz": "America\/Halifax"
  },
  "CCC2": {
      "icao": "CCC2",
      "iata": "",
      "name": "Winterland Airport",
      "city": "Winterland",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 156,
      "lat": 47.1369018555,
      "lon": -55.3292007446,
      "tz": "America\/St_Johns"
  },
  "CCD2": {
      "icao": "CCD2",
      "iata": "",
      "name": "Springdale Airport",
      "city": "Springdale",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 250,
      "lat": 49.4789009094,
      "lon": -56.1781005859,
      "tz": "America\/St_Johns"
  },
  "CCD3": {
      "icao": "CCD3",
      "iata": "",
      "name": "Woodstock Airport",
      "city": "Woodstock",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 481,
      "lat": 46.1522484697,
      "lon": -67.5452327728,
      "tz": "America\/Moncton"
  },
  "CCD4": {
      "icao": "CCD4",
      "iata": "",
      "name": "Postville Airport",
      "city": "Postville",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 193,
      "lat": 54.9105987549,
      "lon": -59.7867012024,
      "tz": "America\/Goose_Bay"
  },
  "CCE2": {
      "icao": "CCE2",
      "iata": "",
      "name": "Mount Pleasant Airport",
      "city": "Mount Pleasant",
      "state": "Prince Edward Island",
      "country": "CA",
      "elevation": 0,
      "lat": 46.5999984741,
      "lon": -64,
      "tz": "America\/Halifax"
  },
  "CCE3": {
      "icao": "CCE3",
      "iata": "",
      "name": "Juniper Airport",
      "city": "Juniper",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 837,
      "lat": 46.5628013611,
      "lon": -67.168296814,
      "tz": "America\/Moncton"
  },
  "CCE4": {
      "icao": "CCE4",
      "iata": "",
      "name": "Black Tickle Airport",
      "city": "Black Tickle",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 57,
      "lat": 53.4693984985,
      "lon": -55.7849998474,
      "tz": "America\/Goose_Bay"
  },
  "CCF4": {
      "icao": "CCF4",
      "iata": "",
      "name": "Porters Lake Airport",
      "city": "Porters Lake",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 20,
      "lat": 44.7099936874,
      "lon": -63.2996821404,
      "tz": "America\/Halifax"
  },
  "CCF6": {
      "icao": "CCF6",
      "iata": "",
      "name": "Edmonton\/Morinville (Currie Field)",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2374,
      "lat": 53.819722,
      "lon": -113.760833,
      "tz": "America\/Edmonton"
  },
  "CCF9": {
      "icao": "CCF9",
      "iata": "",
      "name": "Scottsfield Airpark",
      "city": "Scottsfield",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 600,
      "lat": 45.9603004456,
      "lon": -67.0952987671,
      "tz": "America\/Moncton"
  },
  "CCG3": {
      "icao": "CCG3",
      "iata": "",
      "name": "Weyman Airpark",
      "city": "Burtt's Corner",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 140,
      "lat": 46.0374984741,
      "lon": -66.8589019775,
      "tz": "America\/Moncton"
  },
  "CCG4": {
      "icao": "CCG4",
      "iata": "",
      "name": "Moncton \/ McEwen Airport",
      "city": "Moncton",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 214,
      "lat": 46.1539001465,
      "lon": -64.7686004639,
      "tz": "America\/Moncton"
  },
  "CCH2": {
      "icao": "CCH2",
      "iata": "",
      "name": "Upper Kent Airport",
      "city": "",
      "state": "Maine",
      "country": "CA",
      "elevation": 246,
      "lat": 46.587137287,
      "lon": -67.7195549011,
      "tz": "America\/Moncton"
  },
  "CCH4": {
      "icao": "CCH4",
      "iata": "",
      "name": "Charlottetown Airport",
      "city": "Charlottetown",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 209,
      "lat": 52.7649993896,
      "lon": -56.1156005859,
      "tz": "America\/St_Johns"
  },
  "CCI9": {
      "icao": "CCI9",
      "iata": "",
      "name": "Cortes Island (Hansen Airfield) Airport",
      "city": "Cortes Island",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 164,
      "lat": 50.0203018188,
      "lon": -124.9840011597,
      "tz": "America\/Vancouver"
  },
  "CCJ3": {
      "icao": "CCJ3",
      "iata": "",
      "name": "Boston Brook Airport",
      "city": "Boston Brook",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 958,
      "lat": 47.448600769,
      "lon": -67.6247024536,
      "tz": "America\/Moncton"
  },
  "CCK3": {
      "icao": "CCK3",
      "iata": "",
      "name": "Grand Falls Airport",
      "city": "Grand Falls",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 712,
      "lat": 47.0750007629,
      "lon": -67.6849975586,
      "tz": "America\/Moncton"
  },
  "CCK4": {
      "icao": "CCK4",
      "iata": "YFX",
      "name": "St. Lewis (Fox Harbour) Airport",
      "city": "St. Lewis",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 74,
      "lat": 52.3727989197,
      "lon": -55.6739006042,
      "tz": "America\/St_Johns"
  },
  "CCK5": {
      "icao": "CCK5",
      "iata": "",
      "name": "Owen Sound (Cook Field)",
      "city": "Owen Sound",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1040,
      "lat": 44.6449639588,
      "lon": -80.7456064224,
      "tz": "America\/Toronto"
  },
  "CCL2": {
      "icao": "CCL2",
      "iata": "",
      "name": "Candle Lake Airpark",
      "city": "Candle Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1650,
      "lat": 53.7682991028,
      "lon": -105.3079986572,
      "tz": "America\/Regina"
  },
  "CCL3": {
      "icao": "CCL3",
      "iata": "",
      "name": "Christina Lake",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1905,
      "lat": 55.6283836075,
      "lon": -110.751113892,
      "tz": "America\/Edmonton"
  },
  "CCM3": {
      "icao": "CCM3",
      "iata": "",
      "name": "Sevogle Airport",
      "city": "Sevogle",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 1350,
      "lat": 47.1910552979,
      "lon": -66.1606140137,
      "tz": "America\/Moncton"
  },
  "CCM4": {
      "icao": "CCM4",
      "iata": "",
      "name": "Port Au Choix Airport",
      "city": "Port Au Choix",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 90,
      "lat": 50.6888999939,
      "lon": -57.331401825,
      "tz": "America\/St_Johns"
  },
  "CCN2": {
      "icao": "CCN2",
      "iata": "",
      "name": "Grand Manan Airport",
      "city": "Grand Manan",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 238,
      "lat": 44.7132987976,
      "lon": -66.7964019775,
      "tz": "America\/Moncton"
  },
  "CCP2": {
      "icao": "CCP2",
      "iata": "",
      "name": "Exploits Valley Botwood Airport",
      "city": "Exploits Valley",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 365,
      "lat": 49.0559997559,
      "lon": -55.4477005005,
      "tz": "America\/St_Johns"
  },
  "CCP3": {
      "icao": "CCP3",
      "iata": "",
      "name": "Chute-St-Philippe Airport",
      "city": "Chute-St-Philippe",
      "state": "Quebec",
      "country": "CA",
      "elevation": 860,
      "lat": 46.6610984802,
      "lon": -75.2450027466,
      "tz": "America\/Toronto"
  },
  "CCP4": {
      "icao": "CCP4",
      "iata": "YHA",
      "name": "Port Hope Simpson Airport",
      "city": "Port Hope Simpson",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 347,
      "lat": 52.5280990601,
      "lon": -56.2860984802,
      "tz": "America\/St_Johns"
  },
  "CCP6": {
      "icao": "CCP6",
      "iata": "",
      "name": "Caniapiscau Airport",
      "city": "Caniapiscau",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1672,
      "lat": 54.8377990723,
      "lon": -69.8927993774,
      "tz": "America\/Toronto"
  },
  "CCQ3": {
      "icao": "CCQ3",
      "iata": "",
      "name": "Debert Airport",
      "city": "Debert",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 142,
      "lat": 45.418598175,
      "lon": -63.4606018066,
      "tz": "America\/Halifax"
  },
  "CCR3": {
      "icao": "CCR3",
      "iata": "",
      "name": "Florenceville Airport",
      "city": "Florenceville",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 508,
      "lat": 46.4261016846,
      "lon": -67.6280975342,
      "tz": "America\/Moncton"
  },
  "CCS3": {
      "icao": "CCS3",
      "iata": "",
      "name": "St. Stephen Airport",
      "city": "St. Stephen",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 96,
      "lat": 45.2075004578,
      "lon": -67.2506027222,
      "tz": "America\/Moncton"
  },
  "CCS4": {
      "icao": "CCS4",
      "iata": "",
      "name": "Chipman Airport",
      "city": "Chipman",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 65,
      "lat": 46.148601532,
      "lon": -65.9041976929,
      "tz": "America\/Moncton"
  },
  "CCS5": {
      "icao": "CCS5",
      "iata": "",
      "name": "Havelock Airport",
      "city": "Havelock",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 425,
      "lat": 45.9864006042,
      "lon": -65.301902771,
      "tz": "America\/Moncton"
  },
  "CCS6": {
      "icao": "CCS6",
      "iata": "",
      "name": "Courtenay (Smit Field) Airport",
      "city": "Courtenay",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 500,
      "lat": 49.6824989319,
      "lon": -125.1009979248,
      "tz": "America\/Vancouver"
  },
  "CCT2": {
      "icao": "CCT2",
      "iata": "",
      "name": "Cookstown Airport",
      "city": "Cookstown",
      "state": "Ontario",
      "country": "CA",
      "elevation": 750,
      "lat": 44.238899231,
      "lon": -79.6389007568,
      "tz": "America\/Toronto"
  },
  "CCV4": {
      "icao": "CCV4",
      "iata": "",
      "name": "Bell Island Airport",
      "city": "Bell Island",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 150,
      "lat": 47.6333007813,
      "lon": -52.9832992554,
      "tz": "America\/St_Johns"
  },
  "CCW3": {
      "icao": "CCW3",
      "iata": "",
      "name": "Waterville \/ Kings County Municipal Airport",
      "city": "Waterville",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 119,
      "lat": 45.0518989563,
      "lon": -64.6517028809,
      "tz": "America\/Halifax"
  },
  "CCW4": {
      "icao": "CCW4",
      "iata": "",
      "name": "Stanley Airport",
      "city": "Stanley",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 95,
      "lat": 45.1006011963,
      "lon": -63.9206008911,
      "tz": "America\/Halifax"
  },
  "CCX3": {
      "icao": "CCX3",
      "iata": "",
      "name": "Brockway Airport",
      "city": "Brockway",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 300,
      "lat": 45.5666999817,
      "lon": -67.0999984741,
      "tz": "America\/Moncton"
  },
  "CCY3": {
      "icao": "CCY3",
      "iata": "",
      "name": "Sussex Airport",
      "city": "Sussex",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 467,
      "lat": 45.6899986267,
      "lon": -65.5400009155,
      "tz": "America\/Moncton"
  },
  "CCY4": {
      "icao": "CCY4",
      "iata": "",
      "name": "East Gore Eco Airpark",
      "city": "East Gore",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 615,
      "lat": 45.1175003052,
      "lon": -63.7028007507,
      "tz": "America\/Halifax"
  },
  "CCZ2": {
      "icao": "CCZ2",
      "iata": "YRG",
      "name": "Rigolet Airport",
      "city": "Rigolet",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 180,
      "lat": 54.1796989441,
      "lon": -58.4575004578,
      "tz": "America\/Goose_Bay"
  },
  "CCZ3": {
      "icao": "CCZ3",
      "iata": "",
      "name": "Clarenville Airport",
      "city": "Clarenville",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 199,
      "lat": 48.2747001648,
      "lon": -53.9239006042,
      "tz": "America\/St_Johns"
  },
  "CCZ4": {
      "icao": "CCZ4",
      "iata": "",
      "name": "Margaree Airport",
      "city": "Margaree",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 181,
      "lat": 46.3410987854,
      "lon": -60.9796981812,
      "tz": "America\/Halifax"
  },
  "CCZ5": {
      "icao": "CCZ5",
      "iata": "",
      "name": "Thorburn Airport",
      "city": "Thorburn",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 120,
      "lat": 45.5606002808,
      "lon": -62.5946998596,
      "tz": "America\/Halifax"
  },
  "CD01": {
      "icao": "CD01",
      "iata": "",
      "name": "Lowe Airstrip",
      "city": "Cotopaxi",
      "state": "Colorado",
      "country": "US",
      "elevation": 6200,
      "lat": 38.3983001709,
      "lon": -105.6200027466,
      "tz": "America\/Denver"
  },
  "CD02": {
      "icao": "CD02",
      "iata": "",
      "name": "Skyote Airport",
      "city": "Steamboat Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 8200,
      "lat": 40.7579994202,
      "lon": -106.9720001221,
      "tz": "America\/Denver"
  },
  "CD03": {
      "icao": "CD03",
      "iata": "",
      "name": "Tinnes Airport",
      "city": "Lamar",
      "state": "Colorado",
      "country": "US",
      "elevation": 3850,
      "lat": 38.1990013123,
      "lon": -102.5729980469,
      "tz": "America\/Denver"
  },
  "CD05": {
      "icao": "CD05",
      "iata": "",
      "name": "Jackson Airfield",
      "city": "Yuma",
      "state": "Colorado",
      "country": "US",
      "elevation": 4075,
      "lat": 40.1944007874,
      "lon": -102.697998047,
      "tz": "America\/Denver"
  },
  "CD09": {
      "icao": "CD09",
      "iata": "",
      "name": "Yoder Airstrip",
      "city": "Bennett",
      "state": "Colorado",
      "country": "US",
      "elevation": 5345,
      "lat": 39.8196983337,
      "lon": -104.408996582,
      "tz": "America\/Denver"
  },
  "CD10": {
      "icao": "CD10",
      "iata": "",
      "name": "Chapman Field",
      "city": "South Fork",
      "state": "Colorado",
      "country": "US",
      "elevation": 8100,
      "lat": 37.6749992371,
      "lon": -106.5559997559,
      "tz": "America\/Denver"
  },
  "CD13": {
      "icao": "CD13",
      "iata": "",
      "name": "Morris Airport",
      "city": "Deer Trail",
      "state": "Colorado",
      "country": "US",
      "elevation": 4750,
      "lat": 39.8350982666,
      "lon": -103.736000061,
      "tz": "America\/Denver"
  },
  "CD14": {
      "icao": "CD14",
      "iata": "",
      "name": "J & S Airport",
      "city": "Bennett",
      "state": "Colorado",
      "country": "US",
      "elevation": 5320,
      "lat": 39.8288993835,
      "lon": -104.43699646,
      "tz": "America\/Denver"
  },
  "CD15": {
      "icao": "CD15",
      "iata": "",
      "name": "Schantz Airstrip",
      "city": "Simla",
      "state": "Colorado",
      "country": "US",
      "elevation": 5870,
      "lat": 39.2963981628,
      "lon": -104.125,
      "tz": "America\/Denver"
  },
  "CD17": {
      "icao": "CD17",
      "iata": "",
      "name": "Bijou Basin Airport",
      "city": "Byers",
      "state": "Colorado",
      "country": "US",
      "elevation": 4885,
      "lat": 39.9082984924,
      "lon": -104.1259994507,
      "tz": "America\/Denver"
  },
  "CD20": {
      "icao": "CD20",
      "iata": "",
      "name": "Sprague Airport",
      "city": "Loveland",
      "state": "Colorado",
      "country": "US",
      "elevation": 5603,
      "lat": 40.4749984741,
      "lon": -105.2200012207,
      "tz": "America\/Denver"
  },
  "CD23": {
      "icao": "CD23",
      "iata": "",
      "name": "Aero Bear Field",
      "city": "Calhan",
      "state": "Colorado",
      "country": "US",
      "elevation": 6300,
      "lat": 39.198299408,
      "lon": -104.3290023804,
      "tz": "America\/Denver"
  },
  "CD28": {
      "icao": "CD28",
      "iata": "",
      "name": "Tall Timber Airport",
      "city": "Indian Hills",
      "state": "Colorado",
      "country": "US",
      "elevation": 7360,
      "lat": 39.643901825,
      "lon": -105.2679977417,
      "tz": "America\/Denver"
  },
  "CD32": {
      "icao": "CD32",
      "iata": "",
      "name": "Castle Lakes Airport",
      "city": "Lake City",
      "state": "Colorado",
      "country": "US",
      "elevation": 9300,
      "lat": 37.909198761,
      "lon": -107.3499984741,
      "tz": "America\/Denver"
  },
  "CD39": {
      "icao": "CD39",
      "iata": "",
      "name": "Pond's Field",
      "city": "Berthoud",
      "state": "Colorado",
      "country": "US",
      "elevation": 5050,
      "lat": 40.2649993896,
      "lon": -105.1370010376,
      "tz": "America\/Denver"
  },
  "CD45": {
      "icao": "CD45",
      "iata": "",
      "name": "Flyin' B Ranch Airport",
      "city": "Elizabeth",
      "state": "Colorado",
      "country": "US",
      "elevation": 6720,
      "lat": 39.323299408,
      "lon": -104.5729980469,
      "tz": "America\/Denver"
  },
  "CD48": {
      "icao": "CD48",
      "iata": "",
      "name": "Cuchara Ranch Airport",
      "city": "Walsenburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 5827,
      "lat": 37.7863998413,
      "lon": -104.59400177,
      "tz": "America\/Denver"
  },
  "CD69": {
      "icao": "CD69",
      "iata": "",
      "name": "Morning Shadows Ranch Airport",
      "city": "South Fork",
      "state": "Colorado",
      "country": "US",
      "elevation": 8300,
      "lat": 37.7402992249,
      "lon": -106.5260009766,
      "tz": "America\/Denver"
  },
  "CD82": {
      "icao": "CD82",
      "iata": "",
      "name": "Val Air Airport",
      "city": "Durango",
      "state": "Colorado",
      "country": "US",
      "elevation": 6548,
      "lat": 37.3377990723,
      "lon": -107.8519973755,
      "tz": "America\/Denver"
  },
  "CD97": {
      "icao": "CD97",
      "iata": "",
      "name": "Montemadeira Ii Airport",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 5750,
      "lat": 38.8055992126,
      "lon": -107.7740020752,
      "tz": "America\/Denver"
  },
  "CD99": {
      "icao": "CD99",
      "iata": "",
      "name": "Lucky L Ranch Airport",
      "city": "Steamboat Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 7000,
      "lat": 40.3582992554,
      "lon": -106.8300018311,
      "tz": "America\/Denver"
  },
  "CDA3": {
      "icao": "CDA3",
      "iata": "",
      "name": "Valley Airport",
      "city": "Valley",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 175,
      "lat": 45.3967018127,
      "lon": -63.2155990601,
      "tz": "America\/Halifax"
  },
  "CDA4": {
      "icao": "CDA4",
      "iata": "",
      "name": "Pokemouche Airport",
      "city": "Pokemouche",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 68,
      "lat": 47.7164001465,
      "lon": -64.8824996948,
      "tz": "America\/Moncton"
  },
  "CDA5": {
      "icao": "CDA5",
      "iata": "",
      "name": "St. Andrews Codroy Valley Airport",
      "city": "St. Andrews",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 65,
      "lat": 47.773601532,
      "lon": -59.3130989075,
      "tz": "America\/St_Johns"
  },
  "CDA6": {
      "icao": "CDA6",
      "iata": "",
      "name": "Bristol Airport",
      "city": "Bristol",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 574,
      "lat": 46.459400177,
      "lon": -67.5646972656,
      "tz": "America\/Moncton"
  },
  "CDC3": {
      "icao": "CDC3",
      "iata": "",
      "name": "Dawson Creek (Flying L Ranch) Airport",
      "city": "Dawson Creek",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2680,
      "lat": 55.8194885254,
      "lon": -120.4602813721,
      "tz": "America\/Dawson_Creek"
  },
  "CDC4": {
      "icao": "CDC4",
      "iata": "",
      "name": "St-Quentin Airport",
      "city": "St-Quentin",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 875,
      "lat": 47.5210990906,
      "lon": -67.4210968018,
      "tz": "America\/Moncton"
  },
  "CDD3": {
      "icao": "CDD3",
      "iata": "",
      "name": "Ste-Agnes-de-Dundee Airport",
      "city": "Ste-Agnes-de-Dundee",
      "state": "Quebec",
      "country": "CA",
      "elevation": 160,
      "lat": 45.0475006104,
      "lon": -74.3432998657,
      "tz": "America\/Toronto"
  },
  "CDF3": {
      "icao": "CDF3",
      "iata": "",
      "name": "Englehart (Dave's Field)",
      "city": "Englehart",
      "state": "Ontario",
      "country": "CA",
      "elevation": 700,
      "lat": 47.809722223,
      "lon": -79.8111111111,
      "tz": "America\/Nipigon"
  },
  "CDG3": {
      "icao": "CDG3",
      "iata": "",
      "name": "Dungannon",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 870,
      "lat": 43.836389,
      "lon": -81.606667,
      "tz": "America\/Toronto"
  },
  "CDH1": {
      "icao": "CDH1",
      "iata": "",
      "name": "Deerhurst Resort Airport",
      "city": "Huntsville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1029,
      "lat": 45.3541984558,
      "lon": -79.1513977051,
      "tz": "America\/Toronto"
  },
  "CDH3": {
      "icao": "CDH3",
      "iata": "",
      "name": "Finlay Air Park",
      "city": "Finlay",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 145,
      "lat": 43.9622001648,
      "lon": -65.9942016602,
      "tz": "America\/Halifax"
  },
  "CDJ4": {
      "icao": "CDJ4",
      "iata": "",
      "name": "Clearwater Airport",
      "city": "Clearwater",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 1330,
      "lat": 46.7132987976,
      "lon": -66.8283004761,
      "tz": "America\/Moncton"
  },
  "CDJ5": {
      "icao": "CDJ5",
      "iata": "",
      "name": "Strathmore (D.J. Murray) Airport",
      "city": "Strathmore",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3150,
      "lat": 51.1319007874,
      "lon": -113.5599975586,
      "tz": "America\/Edmonton"
  },
  "CDK2": {
      "icao": "CDK2",
      "iata": "DVK",
      "name": "Diavik Airport",
      "city": "Diavik",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 1413,
      "lat": 64.5113983154,
      "lon": -110.289001465,
      "tz": "America\/Yellowknife"
  },
  "CDO2": {
      "icao": "CDO2",
      "iata": "",
      "name": "Ostergard's Airport",
      "city": "Drumheller",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2900,
      "lat": 51.2943992615,
      "lon": -112.6139984131,
      "tz": "America\/Edmonton"
  },
  "CDT5": {
      "icao": "CDT5",
      "iata": "",
      "name": "Buctouche Airport",
      "city": "Buctouche",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 57,
      "lat": 46.5093994141,
      "lon": -64.693901062,
      "tz": "America\/Moncton"
  },
  "CDU6": {
      "icao": "CDU6",
      "iata": "",
      "name": "Doaktown Airport",
      "city": "Doaktown",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 326,
      "lat": 46.5525016785,
      "lon": -66.0939025879,
      "tz": "America\/Moncton"
  },
  "CDU9": {
      "icao": "CDU9",
      "iata": "",
      "name": "Dunnville Airport",
      "city": "Dunnville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 600,
      "lat": 42.8722000122,
      "lon": -79.5958023071,
      "tz": "America\/Toronto"
  },
  "CDV2": {
      "icao": "CDV2",
      "iata": "",
      "name": "Downs Gulch Airport",
      "city": "Downs Gulch",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 883,
      "lat": 47.7536010742,
      "lon": -67.4261016846,
      "tz": "America\/Moncton"
  },
  "CDW2": {
      "icao": "CDW2",
      "iata": "",
      "name": "Baddeck (Crown Jewel) Airport",
      "city": "Baddeck",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 290,
      "lat": 46.1650009155,
      "lon": -60.7839012146,
      "tz": "America\/Halifax"
  },
  "CDY3": {
      "icao": "CDY3",
      "iata": "",
      "name": "Fogo Airport",
      "city": "Fogo",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 80,
      "lat": 49.6575012207,
      "lon": -54.2374992371,
      "tz": "America\/St_Johns"
  },
  "CDY6": {
      "icao": "CDY6",
      "iata": "",
      "name": "Bridgewater \/ Dayspring Airpark",
      "city": "Bridgewater",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 150,
      "lat": 44.3819007874,
      "lon": -64.456703186,
      "tz": "America\/Halifax"
  },
  "CEA3": {
      "icao": "CEA3",
      "iata": "",
      "name": "Olds-Didsbury Airport",
      "city": "Olds-Didsbury",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3360,
      "lat": 51.7118988037,
      "lon": -114.107002258,
      "tz": "America\/Edmonton"
  },
  "CEA5": {
      "icao": "CEA5",
      "iata": "",
      "name": "Hardisty Airport",
      "city": "Hardisty",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2326,
      "lat": 52.646900177,
      "lon": -111.3840026855,
      "tz": "America\/Edmonton"
  },
  "CEA6": {
      "icao": "CEA6",
      "iata": "",
      "name": "Cardston Airport",
      "city": "Cardston",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3887,
      "lat": 49.1624984741,
      "lon": -113.2409973145,
      "tz": "America\/Edmonton"
  },
  "CEB3": {
      "icao": "CEB3",
      "iata": "YCK",
      "name": "Colville Lake Airport",
      "city": "Colville Lake",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 850,
      "lat": 67.0333023071,
      "lon": -126.0830001831,
      "tz": "America\/Inuvik"
  },
  "CEB5": {
      "icao": "CEB5",
      "iata": "",
      "name": "Fairview Airport",
      "city": "Fairview",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2166,
      "lat": 56.081401825,
      "lon": -118.4349975586,
      "tz": "America\/Edmonton"
  },
  "CEC3": {
      "icao": "CEC3",
      "iata": "",
      "name": "Fox Lake Airport",
      "city": "Fox Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 850,
      "lat": 58.4733009338,
      "lon": -114.5429992676,
      "tz": "America\/Edmonton"
  },
  "CEC4": {
      "icao": "CEC4",
      "iata": "",
      "name": "Hinton \/ Jasper-Hinton Airport",
      "city": "Hinton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 4006,
      "lat": 53.3191986084,
      "lon": -117.752998352,
      "tz": "America\/Edmonton"
  },
  "CED3": {
      "icao": "CED3",
      "iata": "",
      "name": "Oyen Municipal Airport",
      "city": "Oyen",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2498,
      "lat": 51.3342018127,
      "lon": -110.4909973145,
      "tz": "America\/Edmonton"
  },
  "CED4": {
      "icao": "CED4",
      "iata": "",
      "name": "Fox Creek Airport",
      "city": "Fox Creek",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2842,
      "lat": 54.3800010681,
      "lon": -116.7659988403,
      "tz": "America\/Edmonton"
  },
  "CED5": {
      "icao": "CED5",
      "iata": "",
      "name": "Taber Airport",
      "city": "Taber",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2648,
      "lat": 49.8266983032,
      "lon": -112.1849975586,
      "tz": "America\/Edmonton"
  },
  "CED6": {
      "icao": "CED6",
      "iata": "",
      "name": "Highwood Airport",
      "city": "De Winton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3450,
      "lat": 50.8018989563,
      "lon": -113.888999939,
      "tz": "America\/Edmonton"
  },
  "CEE4": {
      "icao": "CEE4",
      "iata": "",
      "name": "Hinton \/ Entrance Airport",
      "city": "Hinton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3450,
      "lat": 53.381401062,
      "lon": -117.700996399,
      "tz": "America\/Edmonton"
  },
  "CEE5": {
      "icao": "CEE5",
      "iata": "",
      "name": "Wabasca Airport",
      "city": "Wabasca",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1827,
      "lat": 55.9618988037,
      "lon": -113.819000244,
      "tz": "America\/Edmonton"
  },
  "CEE6": {
      "icao": "CEE6",
      "iata": "",
      "name": "Edmonton \/ Twin Island Airpark",
      "city": "Edmonton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2435,
      "lat": 53.4710998535,
      "lon": -113.153999329,
      "tz": "America\/Edmonton"
  },
  "CEE8": {
      "icao": "CEE8",
      "iata": "",
      "name": "Viking Airport",
      "city": "Viking",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2260,
      "lat": 53.1089685346,
      "lon": -111.864938736,
      "tz": "America\/Edmonton"
  },
  "CEF2": {
      "icao": "CEF2",
      "iata": "",
      "name": "Belwood (Ellen Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1540,
      "lat": 43.8372354306,
      "lon": -80.3694534302,
      "tz": "America\/Toronto"
  },
  "CEF3": {
      "icao": "CEF3",
      "iata": "",
      "name": "Bow Island Airport",
      "city": "Bow Island",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2634,
      "lat": 49.8833007813,
      "lon": -111.3330001831,
      "tz": "America\/Edmonton"
  },
  "CEF4": {
      "icao": "CEF4",
      "iata": "",
      "name": "Airdrie Airport",
      "city": "Airdrie",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3648,
      "lat": 51.2639007568,
      "lon": -113.9339981079,
      "tz": "America\/Edmonton"
  },
  "CEF6": {
      "icao": "CEF6",
      "iata": "",
      "name": "Forestburg Airport",
      "city": "Forestburg",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2334,
      "lat": 52.5746994019,
      "lon": -112.0839996338,
      "tz": "America\/Edmonton"
  },
  "CEG2": {
      "icao": "CEG2",
      "iata": "",
      "name": "Acme Airport",
      "city": "Acme",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3035,
      "lat": 51.456401825,
      "lon": -113.5149993896,
      "tz": "America\/Edmonton"
  },
  "CEG3": {
      "icao": "CEG3",
      "iata": "",
      "name": "Lacombe Airport",
      "city": "Lacombe",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2783,
      "lat": 52.4883003235,
      "lon": -113.7119979858,
      "tz": "America\/Edmonton"
  },
  "CEG4": {
      "icao": "CEG4",
      "iata": "",
      "name": "Drumheller Municipal Airport",
      "city": "Drumheller",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2597,
      "lat": 51.4963989258,
      "lon": -112.7490005493,
      "tz": "America\/Edmonton"
  },
  "CEG5": {
      "icao": "CEG5",
      "iata": "",
      "name": "Chipewyan Lake Airport",
      "city": "Chipewyan Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1800,
      "lat": 56.9524993896,
      "lon": -113.4960021973,
      "tz": "America\/Edmonton"
  },
  "CEG8": {
      "icao": "CEG8",
      "iata": "",
      "name": "North Seal River Airport",
      "city": "North Seal River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 980,
      "lat": 58.9693984985,
      "lon": -99.9749984741,
      "tz": "America\/Winnipeg"
  },
  "CEH2": {
      "icao": "CEH2",
      "iata": "",
      "name": "Cu Nim Airport",
      "city": "Black Diamond",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3700,
      "lat": 50.7224998474,
      "lon": -114.1790008545,
      "tz": "America\/Edmonton"
  },
  "CEH3": {
      "icao": "CEH3",
      "iata": "",
      "name": "Ponoka Industrial Airport",
      "city": "Ponoka Industrial",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2669,
      "lat": 52.6516990662,
      "lon": -113.6060028076,
      "tz": "America\/Edmonton"
  },
  "CEH4": {
      "icao": "CEH4",
      "iata": "",
      "name": "De Winton South Calgary Airport",
      "city": "De Winton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3355,
      "lat": 50.8218994141,
      "lon": -113.8239974976,
      "tz": "America\/Edmonton"
  },
  "CEH5": {
      "icao": "CEH5",
      "iata": "",
      "name": "Red Earth Creek Airport",
      "city": "Red Earth Creek",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1790,
      "lat": 56.5463981628,
      "lon": -115.2740020752,
      "tz": "America\/Edmonton"
  },
  "CEH6": {
      "icao": "CEH6",
      "iata": "",
      "name": "Provost Airport",
      "city": "Provost",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2197,
      "lat": 52.3381004333,
      "lon": -110.2789993286,
      "tz": "America\/Edmonton"
  },
  "CEJ3": {
      "icao": "CEJ3",
      "iata": "",
      "name": "Stettler Airport",
      "city": "Stettler",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2686,
      "lat": 52.3100013733,
      "lon": -112.7539978027,
      "tz": "America\/Edmonton"
  },
  "CEJ4": {
      "icao": "CEJ4",
      "iata": "",
      "name": "Claresholm Industrial Airport",
      "city": "Claresholm",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3325,
      "lat": 50.004699707,
      "lon": -113.6299972534,
      "tz": "America\/Edmonton"
  },
  "CEJ6": {
      "icao": "CEJ6",
      "iata": "",
      "name": "Elk Point Airport",
      "city": "Elk Point",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1981,
      "lat": 53.8931007385,
      "lon": -110.7720031738,
      "tz": "America\/Edmonton"
  },
  "CEK2": {
      "icao": "CEK2",
      "iata": "",
      "name": "Braeburn Airport",
      "city": "Braeburn",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2400,
      "lat": 61.4844017029,
      "lon": -135.7760009766,
      "tz": "America\/Whitehorse"
  },
  "CEK6": {
      "icao": "CEK6",
      "iata": "",
      "name": "Killam-Sedgewick Airport",
      "city": "Killam",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2182,
      "lat": 52.7999992371,
      "lon": -111.766998291,
      "tz": "America\/Edmonton"
  },
  "CEL3": {
      "icao": "CEL3",
      "iata": "",
      "name": "East Linton (Kerr Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 750,
      "lat": 44.6610679784,
      "lon": -80.9440255165,
      "tz": "America\/Toronto"
  },
  "CEL4": {
      "icao": "CEL4",
      "iata": "",
      "name": "Hanna Airport",
      "city": "Hanna",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2738,
      "lat": 51.631401062,
      "lon": -111.9039993286,
      "tz": "America\/Edmonton"
  },
  "CEL5": {
      "icao": "CEL5",
      "iata": "",
      "name": "Valleyview Airport",
      "city": "Valleyview",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2435,
      "lat": 55.0325012207,
      "lon": -117.2959976196,
      "tz": "America\/Edmonton"
  },
  "CEL6": {
      "icao": "CEL6",
      "iata": "",
      "name": "Two Hills Airport",
      "city": "Two Hills",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2010,
      "lat": 53.7000007629,
      "lon": -111.7829971313,
      "tz": "America\/Edmonton"
  },
  "CEL8": {
      "icao": "CEL8",
      "iata": "",
      "name": "Eleonore",
      "city": "",
      "state": "Quebec",
      "country": "CA",
      "elevation": 750,
      "lat": 52.718306623,
      "lon": -76.0798072815,
      "tz": "America\/Toronto"
  },
  "CEM3": {
      "icao": "CEM3",
      "iata": "YLE",
      "name": "Whati Airport",
      "city": "Whati",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 882,
      "lat": 63.1316986084,
      "lon": -117.2460021973,
      "tz": "America\/Yellowknife"
  },
  "CEM4": {
      "icao": "CEM4",
      "iata": "",
      "name": "Innisfail Airport",
      "city": "Innisfail",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3017,
      "lat": 52.0786018372,
      "lon": -114.0270004272,
      "tz": "America\/Edmonton"
  },
  "CEM5": {
      "icao": "CEM5",
      "iata": "",
      "name": "Swan Hills Airport",
      "city": "Swan Hills",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3473,
      "lat": 54.6707992554,
      "lon": -115.4150009155,
      "tz": "America\/Edmonton"
  },
  "CEN2": {
      "icao": "CEN2",
      "iata": "",
      "name": "Bassano Airport",
      "city": "Bassano",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2613,
      "lat": 50.7999992371,
      "lon": -112.4670028687,
      "tz": "America\/Edmonton"
  },
  "CEN3": {
      "icao": "CEN3",
      "iata": "",
      "name": "Three Hills Airport",
      "city": "Three Hills",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2975,
      "lat": 51.6967010498,
      "lon": -113.2089996338,
      "tz": "America\/Edmonton"
  },
  "CEN4": {
      "icao": "CEN4",
      "iata": "",
      "name": "High River Airport",
      "city": "High River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3431,
      "lat": 50.5332984924,
      "lon": -113.8330001831,
      "tz": "America\/Edmonton"
  },
  "CEN5": {
      "icao": "CEN5",
      "iata": "",
      "name": "Cold Lake Regional Airport",
      "city": "Cold Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1786,
      "lat": 54.4775009155,
      "lon": -110.2689971924,
      "tz": "America\/Edmonton"
  },
  "CEN6": {
      "icao": "CEN6",
      "iata": "",
      "name": "Vauxhall Airport",
      "city": "Vauxhall",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2579,
      "lat": 50.0332984924,
      "lon": -112.0830001831,
      "tz": "America\/Edmonton"
  },
  "CEP3": {
      "icao": "CEP3",
      "iata": "",
      "name": "Barrhead Airport",
      "city": "Barrhead",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2121,
      "lat": 54.0999984741,
      "lon": -114.4329986572,
      "tz": "America\/Edmonton"
  },
  "CEP4": {
      "icao": "CEP4",
      "iata": "",
      "name": "Ross International Airport",
      "city": "Coutts",
      "state": "Montana",
      "country": "CA",
      "elevation": 3550,
      "lat": 49,
      "lon": -111.9670028687,
      "tz": "America\/Edmonton"
  },
  "CEP5": {
      "icao": "CEP5",
      "iata": "",
      "name": "Janvier Airport",
      "city": "Janvier",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1575,
      "lat": 55.9000015259,
      "lon": -110.733001709,
      "tz": "America\/Edmonton"
  },
  "CEP6": {
      "icao": "CEP6",
      "iata": "",
      "name": "Warner Airport",
      "city": "Warner",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3319,
      "lat": 49.2933006287,
      "lon": -112.1890029907,
      "tz": "America\/Edmonton"
  },
  "CEQ3": {
      "icao": "CEQ3",
      "iata": "",
      "name": "Camrose Airport",
      "city": "Camrose",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2426,
      "lat": 53.0402984619,
      "lon": -112.8160018921,
      "tz": "America\/Edmonton"
  },
  "CEQ4": {
      "icao": "CEQ4",
      "iata": "",
      "name": "Del Bonita \/ Whetstone International Airport",
      "city": "Del Bonita",
      "state": "Montana",
      "country": "CA",
      "elevation": 4335,
      "lat": 48.9986000061,
      "lon": -112.776000977,
      "tz": "America\/Denver"
  },
  "CEQ5": {
      "icao": "CEQ5",
      "iata": "",
      "name": "Grande Cache Airport",
      "city": "Grande Cache",
      "state": "Alberta",
      "country": "CA",
      "elevation": 4117,
      "lat": 53.9169006348,
      "lon": -118.8740005493,
      "tz": "America\/Edmonton"
  },
  "CER2": {
      "icao": "CER2",
      "iata": "",
      "name": "Castor Airport",
      "city": "Castor",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2704,
      "lat": 52.2209249248,
      "lon": -111.926887035,
      "tz": "America\/Edmonton"
  },
  "CER3": {
      "icao": "CER3",
      "iata": "",
      "name": "Drayton Valley Industrial Airport",
      "city": "Drayton Valley",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2776,
      "lat": 53.2658004761,
      "lon": -114.9599990845,
      "tz": "America\/Edmonton"
  },
  "CER4": {
      "icao": "CER4",
      "iata": "NML",
      "name": "Fort McMurray \/ Mildred Lake Airport",
      "city": "Fort McMurray",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1046,
      "lat": 57.0555992126,
      "lon": -111.573997498,
      "tz": "America\/Edmonton"
  },
  "CES2": {
      "icao": "CES2",
      "iata": "",
      "name": "St-Esprit",
      "city": "",
      "state": "Quebec",
      "country": "CA",
      "elevation": 192,
      "lat": 45.914399704,
      "lon": -73.6723959446,
      "tz": "America\/Toronto"
  },
  "CES4": {
      "icao": "CES4",
      "iata": "",
      "name": "Westlock Airport",
      "city": "Westlock",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2214,
      "lat": 54.14220047,
      "lon": -113.7409973145,
      "tz": "America\/Edmonton"
  },
  "CET2": {
      "icao": "CET2",
      "iata": "",
      "name": "Conklin (Leismer) Airport",
      "city": "Conklin",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1930,
      "lat": 55.6953010559,
      "lon": -111.2789993286,
      "tz": "America\/Edmonton"
  },
  "CET4": {
      "icao": "CET4",
      "iata": "",
      "name": "Fort Simpson Island Airport",
      "city": "Fort Simpson Island",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 405,
      "lat": 61.8666992188,
      "lon": -121.3659973145,
      "tz": "America\/Inuvik"
  },
  "CET9": {
      "icao": "CET9",
      "iata": "",
      "name": "Jean Marie River Airport",
      "city": "Jean Marie River",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 470,
      "lat": 61.5167007446,
      "lon": -120.6169967651,
      "tz": "America\/Inuvik"
  },
  "CEU2": {
      "icao": "CEU2",
      "iata": "",
      "name": "Beaverlodge Airport",
      "city": "Beaverlodge",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2289,
      "lat": 55.1842002869,
      "lon": -119.4479980469,
      "tz": "America\/Edmonton"
  },
  "CEU9": {
      "icao": "CEU9",
      "iata": "",
      "name": "Trout Lake Airport",
      "city": "Trout Lake",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 1635,
      "lat": 60.4393997192,
      "lon": -121.2369995117,
      "tz": "America\/Inuvik"
  },
  "CEV2": {
      "icao": "CEV2",
      "iata": "",
      "name": "Edra Airport",
      "city": "Edra",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2625,
      "lat": 57.8499984741,
      "lon": -113.25,
      "tz": "America\/Edmonton"
  },
  "CEV3": {
      "icao": "CEV3",
      "iata": "",
      "name": "Vegreville Airport",
      "city": "Vegreville",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2072,
      "lat": 53.5144004822,
      "lon": -112.0270004272,
      "tz": "America\/Edmonton"
  },
  "CEV5": {
      "icao": "CEV5",
      "iata": "",
      "name": "Mayerthorpe Airport",
      "city": "Mayerthorpe",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2432,
      "lat": 53.9375,
      "lon": -115.1790008545,
      "tz": "America\/Edmonton"
  },
  "CEV7": {
      "icao": "CEV7",
      "iata": "",
      "name": "Tofield Airport",
      "city": "Tofield",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2311,
      "lat": 53.3711013794,
      "lon": -112.6969985962,
      "tz": "America\/Edmonton"
  },
  "CEV9": {
      "icao": "CEV9",
      "iata": "",
      "name": "Snare River Airport",
      "city": "Snare River",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 700,
      "lat": 63.4333000183,
      "lon": -116.1829986572,
      "tz": "America\/Yellowknife"
  },
  "CEW3": {
      "icao": "CEW3",
      "iata": "ZSP",
      "name": "St. Paul Airport",
      "city": "St. Paul",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2147,
      "lat": 53.9933013916,
      "lon": -111.379997253,
      "tz": "America\/Edmonton"
  },
  "CEW5": {
      "icao": "CEW5",
      "iata": "",
      "name": "Milk River Airport",
      "city": "Milk River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3449,
      "lat": 49.1333007813,
      "lon": -112.0500030518,
      "tz": "America\/Edmonton"
  },
  "CEX3": {
      "icao": "CEX3",
      "iata": "",
      "name": "Wetaskiwin Regional Airport",
      "city": "Wetaskiwin",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2509,
      "lat": 52.9650001526,
      "lon": -113.411003113,
      "tz": "America\/Edmonton"
  },
  "CEX4": {
      "icao": "CEX4",
      "iata": "",
      "name": "Carmacks Airport",
      "city": "Carmacks",
      "state": "Yukon",
      "country": "CA",
      "elevation": 1770,
      "lat": 62.1081008911,
      "lon": -136.1799926758,
      "tz": "America\/Whitehorse"
  },
  "CEX9": {
      "icao": "CEX9",
      "iata": "",
      "name": "Brant (Dixon Farm) Airport",
      "city": "Brant",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3342,
      "lat": 50.4193992615,
      "lon": -113.5410003662,
      "tz": "America\/Edmonton"
  },
  "CEY3": {
      "icao": "CEY3",
      "iata": "",
      "name": "Fort Macleod Airport",
      "city": "Fort Macleod",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3138,
      "lat": 49.7000007629,
      "lon": -113.4169998169,
      "tz": "America\/Edmonton"
  },
  "CEZ2": {
      "icao": "CEZ2",
      "iata": "",
      "name": "Chapman Lake Airport",
      "city": "Chapman Lake",
      "state": "Yukon",
      "country": "CA",
      "elevation": 3100,
      "lat": 64.9000015259,
      "lon": -138.266998291,
      "tz": "America\/Dawson"
  },
  "CEZ3": {
      "icao": "CEZ3",
      "iata": "",
      "name": "Edmonton \/ Cooking Lake Airport",
      "city": "Edmonton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2437,
      "lat": 53.4275016785,
      "lon": -113.115997314,
      "tz": "America\/Edmonton"
  },
  "CEZ4": {
      "icao": "CEZ4",
      "iata": "",
      "name": "Fort Vermilion Airport",
      "city": "Fort Vermilion",
      "state": "Alberta",
      "country": "CA",
      "elevation": 836,
      "lat": 58.4042015076,
      "lon": -115.9509963989,
      "tz": "America\/Edmonton"
  },
  "CFA4": {
      "icao": "CFA4",
      "iata": "",
      "name": "Carcross Airport",
      "city": "Carcross",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2161,
      "lat": 60.1741981506,
      "lon": -134.6979980469,
      "tz": "America\/Whitehorse"
  },
  "CFA5": {
      "icao": "CFA5",
      "iata": "",
      "name": "Grande Airport",
      "city": "Grande",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1750,
      "lat": 56.2999992371,
      "lon": -112.233001709,
      "tz": "America\/Edmonton"
  },
  "CFA7": {
      "icao": "CFA7",
      "iata": "",
      "name": "Taltheilei Narrows Airport",
      "city": "Taltheilei Narrows",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 570,
      "lat": 62.5980987549,
      "lon": -111.5429992676,
      "tz": "America\/Yellowknife"
  },
  "CFB3": {
      "icao": "CFB3",
      "iata": "",
      "name": "Hespero Airport",
      "city": "Hespero",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3175,
      "lat": 52.2999992371,
      "lon": -114.4639968872,
      "tz": "America\/Edmonton"
  },
  "CFB4": {
      "icao": "CFB4",
      "iata": "",
      "name": "Trout Lake Airport",
      "city": "Trout Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2290,
      "lat": 56.5,
      "lon": -114.5500030518,
      "tz": "America\/Edmonton"
  },
  "CFB5": {
      "icao": "CFB5",
      "iata": "",
      "name": "Namur Lake Airport",
      "city": "Namur Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2560,
      "lat": 57.3833007813,
      "lon": -112.8000030518,
      "tz": "America\/Edmonton"
  },
  "CFB6": {
      "icao": "CFB6",
      "iata": "",
      "name": "Edmonton \/ Josephburg Airport",
      "city": "Edmonton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2069,
      "lat": 53.727998032,
      "lon": -113.086566925,
      "tz": "America\/Edmonton"
  },
  "CFB7": {
      "icao": "CFB7",
      "iata": "",
      "name": "Steen River Airport",
      "city": "Steen River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 996,
      "lat": 59.6333007813,
      "lon": -117.1669998169,
      "tz": "America\/Edmonton"
  },
  "CFC4": {
      "icao": "CFC4",
      "iata": "",
      "name": "Macmillan Pass Airport",
      "city": "Macmillan Pass",
      "state": "Yukon",
      "country": "CA",
      "elevation": 3810,
      "lat": 63.181098938,
      "lon": -130.2019958496,
      "tz": "America\/Whitehorse"
  },
  "CFC6": {
      "icao": "CFC6",
      "iata": "",
      "name": "Rockyford Airport",
      "city": "Rockyford",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2860,
      "lat": 51.2667007446,
      "lon": -113.1169967651,
      "tz": "America\/Edmonton"
  },
  "CFC7": {
      "icao": "CFC7",
      "iata": "",
      "name": "Rimbey Airport",
      "city": "Rimbey",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2963,
      "lat": 52.6819000244,
      "lon": -114.2379989624,
      "tz": "America\/Edmonton"
  },
  "CFD4": {
      "icao": "CFD4",
      "iata": "",
      "name": "Foremost Airport",
      "city": "Foremost",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2909,
      "lat": 49.4831008911,
      "lon": -111.4940032959,
      "tz": "America\/Edmonton"
  },
  "CFD5": {
      "icao": "CFD5",
      "iata": "",
      "name": "Grimshaw Airport",
      "city": "Grimshaw",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2050,
      "lat": 56.19611,
      "lon": -117.625278,
      "tz": "America\/Edmonton"
  },
  "CFD9": {
      "icao": "CFD9",
      "iata": "",
      "name": "Bjorgum Farm Airport",
      "city": "Bjorgum Farm",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2415,
      "lat": 53.0833015442,
      "lon": -112.8030014038,
      "tz": "America\/Edmonton"
  },
  "CFF2": {
      "icao": "CFF2",
      "iata": "",
      "name": "Christina Basin Airport",
      "city": "Christina Basin",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2365,
      "lat": 55.5900001526,
      "lon": -111.8229980469,
      "tz": "America\/Edmonton"
  },
  "CFF3": {
      "icao": "CFF3",
      "iata": "",
      "name": "Jean Lake Airport",
      "city": "Jean Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2370,
      "lat": 57.4832992554,
      "lon": -113.8830032349,
      "tz": "America\/Edmonton"
  },
  "CFF4": {
      "icao": "CFF4",
      "iata": "DAS",
      "name": "Great Bear Lake Airport",
      "city": "Great Bear Lake",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 562,
      "lat": 66.7031021118,
      "lon": -119.707000732,
      "tz": "America\/Yellowknife"
  },
  "CFF9": {
      "icao": "CFF9",
      "iata": "",
      "name": "Marek Farms Airport",
      "city": "Marek Farms",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2449,
      "lat": 53.0313987732,
      "lon": -112.7779998779,
      "tz": "America\/Edmonton"
  },
  "CFG3": {
      "icao": "CFG3",
      "iata": "",
      "name": "Consort Airport",
      "city": "Consort",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2499,
      "lat": 52.0167007446,
      "lon": -110.75,
      "tz": "America\/Edmonton"
  },
  "CFG4": {
      "icao": "CFG4",
      "iata": "",
      "name": "Debolt Airport",
      "city": "Debolt",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2120,
      "lat": 55.2332992554,
      "lon": -118.0329971313,
      "tz": "America\/Edmonton"
  },
  "CFG5": {
      "icao": "CFG5",
      "iata": "",
      "name": "John D'Or Prairie Airport",
      "city": "John D'Or Prairie",
      "state": "Alberta",
      "country": "CA",
      "elevation": 950,
      "lat": 58.4914016724,
      "lon": -115.1380004883,
      "tz": "America\/Edmonton"
  },
  "CFG6": {
      "icao": "CFG6",
      "iata": "YFI",
      "name": "Fort Mackay \/ Firebag",
      "city": "Suncor Energy Site",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1762,
      "lat": 57.2758333344,
      "lon": -110.976666667,
      "tz": "America\/Edmonton"
  },
  "CFG7": {
      "icao": "CFG7",
      "iata": "",
      "name": "Steen Tower Airport",
      "city": "Steen Tower",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2320,
      "lat": 59.6333007813,
      "lon": -117.7829971313,
      "tz": "America\/Edmonton"
  },
  "CFH4": {
      "icao": "CFH4",
      "iata": "",
      "name": "Fox Harbour Airport",
      "city": "Fox Harbour",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 62,
      "lat": 45.8699989319,
      "lon": -63.461101532,
      "tz": "America\/Halifax"
  },
  "CFH8": {
      "icao": "CFH8",
      "iata": "",
      "name": "Warburg \/ Zajes Airport",
      "city": "Warburg",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2530,
      "lat": 53.2177775261,
      "lon": -114.336605072,
      "tz": "America\/Edmonton"
  },
  "CFK2": {
      "icao": "CFK2",
      "iata": "",
      "name": "Bashaw Airport",
      "city": "Bashaw",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2610,
      "lat": 52.5574989319,
      "lon": -112.9649963379,
      "tz": "America\/Edmonton"
  },
  "CFK3": {
      "icao": "CFK3",
      "iata": "",
      "name": "Fontas Airport",
      "city": "Fontas",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2950,
      "lat": 57.7999992371,
      "lon": -119.4499969482,
      "tz": "America\/Edmonton"
  },
  "CFK4": {
      "icao": "CFK4",
      "iata": "",
      "name": "Calling Lake Airport",
      "city": "Calling Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2092,
      "lat": 55.3166999817,
      "lon": -113.25,
      "tz": "America\/Edmonton"
  },
  "CFK6": {
      "icao": "CFK6",
      "iata": "",
      "name": "Olds (Netook) Airport",
      "city": "Olds",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3330,
      "lat": 51.8499984741,
      "lon": -114.0670013428,
      "tz": "America\/Edmonton"
  },
  "CFL2": {
      "icao": "CFL2",
      "iata": "",
      "name": "Empress McNeill Spectra Energy Airport",
      "city": "Empress",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2395,
      "lat": 50.6824989319,
      "lon": -110.041999817,
      "tz": "America\/Edmonton"
  },
  "CFL9": {
      "icao": "CFL9",
      "iata": "",
      "name": "Johnson Lake Airport",
      "city": "Johnson Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2000,
      "lat": 57.5666999817,
      "lon": -110.3170013428,
      "tz": "America\/Edmonton"
  },
  "CFM2": {
      "icao": "CFM2",
      "iata": "",
      "name": "Birch Mountain Airport",
      "city": "Birch Mountain",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2600,
      "lat": 57.7000007629,
      "lon": -111.8330001831,
      "tz": "America\/Edmonton"
  },
  "CFM4": {
      "icao": "CFM4",
      "iata": "",
      "name": "Donnelly Airport",
      "city": "Donnelly",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1949,
      "lat": 55.709400177,
      "lon": -117.09400177,
      "tz": "America\/Edmonton"
  },
  "CFM5": {
      "icao": "CFM5",
      "iata": "",
      "name": "Hamburg Airport",
      "city": "Apache",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2540,
      "lat": 57.3552844682,
      "lon": -119.765310287,
      "tz": "America\/Edmonton"
  },
  "CFM6": {
      "icao": "CFM6",
      "iata": "",
      "name": "Teepee Airport",
      "city": "Teepee",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2565,
      "lat": 56.459400177,
      "lon": -114.1190032959,
      "tz": "America\/Edmonton"
  },
  "CFM7": {
      "icao": "CFM7",
      "iata": "",
      "name": "Boyle Airport",
      "city": "Boyle",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2154,
      "lat": 54.573299408,
      "lon": -112.8199996948,
      "tz": "America\/Edmonton"
  },
  "CFM8": {
      "icao": "CFM8",
      "iata": "",
      "name": "Fort Macleod (Alcock Farm) Airport",
      "city": "Fort Macleod",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3340,
      "lat": 49.5833015442,
      "lon": -113.3000030518,
      "tz": "America\/Edmonton"
  },
  "CFN5": {
      "icao": "CFN5",
      "iata": "",
      "name": "La Crete Airport",
      "city": "La Crete",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1046,
      "lat": 58.1728751982,
      "lon": -116.337919235,
      "tz": "America\/Edmonton"
  },
  "CFN7": {
      "icao": "CFN7",
      "iata": "",
      "name": "Sundre Airport",
      "city": "Sundre",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3656,
      "lat": 51.7742004395,
      "lon": -114.6770019531,
      "tz": "America\/Edmonton"
  },
  "CFP4": {
      "icao": "CFP4",
      "iata": "",
      "name": "Mcquesten Airport",
      "city": "Mcquesten",
      "state": "Yukon",
      "country": "CA",
      "elevation": 1500,
      "lat": 63.5999984741,
      "lon": -137.5670013428,
      "tz": "America\/Whitehorse"
  },
  "CFP5": {
      "icao": "CFP5",
      "iata": "",
      "name": "Glendon Airport",
      "city": "Glendon",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1835,
      "lat": 54.2708015442,
      "lon": -111.0670013428,
      "tz": "America\/Edmonton"
  },
  "CFP6": {
      "icao": "CFP6",
      "iata": "",
      "name": "La Biche River Airport",
      "city": "La Biche River",
      "state": "Yukon",
      "country": "CA",
      "elevation": 1356,
      "lat": 60.1291999817,
      "lon": -124.0490036011,
      "tz": "America\/Inuvik"
  },
  "CFP7": {
      "icao": "CFP7",
      "iata": "",
      "name": "Wainwright Field 21 Airport",
      "city": "Wainwright",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2260,
      "lat": 52.8306007385,
      "lon": -111.100997925,
      "tz": "America\/Edmonton"
  },
  "CFP8": {
      "icao": "CFP8",
      "iata": "",
      "name": "Whitehorse \/ Cousins Airport",
      "city": "Whitehorse",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2200,
      "lat": 60.8117343999,
      "lon": -135.182132721,
      "tz": "America\/Whitehorse"
  },
  "CFQ3": {
      "icao": "CFQ3",
      "iata": "",
      "name": "Milk River (Madge) Airport",
      "city": "Milk River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3400,
      "lat": 49.1446990967,
      "lon": -112.0839996338,
      "tz": "America\/Edmonton"
  },
  "CFQ4": {
      "icao": "CFQ4",
      "iata": "",
      "name": "Cheadle Airport",
      "city": "Cheadle",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3300,
      "lat": 51.0574989319,
      "lon": -113.6240005493,
      "tz": "America\/Edmonton"
  },
  "CFQ5": {
      "icao": "CFQ5",
      "iata": "",
      "name": "Silver City Airport",
      "city": "Silver City",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2570,
      "lat": 61.0289001465,
      "lon": -138.4080047607,
      "tz": "America\/Dawson"
  },
  "CFQ6": {
      "icao": "CFQ6",
      "iata": "",
      "name": "Pelly Crossing Airport",
      "city": "Pelly Crossing",
      "state": "Yukon",
      "country": "CA",
      "elevation": 1870,
      "lat": 62.8372001648,
      "lon": -136.5350036621,
      "tz": "America\/Whitehorse"
  },
  "CFQ7": {
      "icao": "CFQ7",
      "iata": "",
      "name": "Edmonton \/ Gartner Airport",
      "city": "Edmonton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2390,
      "lat": 53.2817001343,
      "lon": -113.455001831,
      "tz": "America\/Edmonton"
  },
  "CFR2": {
      "icao": "CFR2",
      "iata": "",
      "name": "Bawlf (Blackwells) Airport",
      "city": "Bawlf",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2325,
      "lat": 52.9000015259,
      "lon": -112.5500030518,
      "tz": "America\/Edmonton"
  },
  "CFR5": {
      "icao": "CFR5",
      "iata": "",
      "name": "Alban Airport",
      "city": "French River",
      "state": "Ontario",
      "country": "CA",
      "elevation": 700,
      "lat": 46.0896987915,
      "lon": -80.6044006348,
      "tz": "America\/Toronto"
  },
  "CFR7": {
      "icao": "CFR7",
      "iata": "",
      "name": "Red Deer Forestry Airport",
      "city": "Red Deer Forestry",
      "state": "Alberta",
      "country": "CA",
      "elevation": 4646,
      "lat": 51.6514015198,
      "lon": -115.2389984131,
      "tz": "America\/Edmonton"
  },
  "CFS4": {
      "icao": "CFS4",
      "iata": "",
      "name": "Ogilvie River Airport",
      "city": "Ogilvie River",
      "state": "Yukon",
      "country": "CA",
      "elevation": 1640,
      "lat": 65.6667022705,
      "lon": -138.1170043945,
      "tz": "America\/Dawson"
  },
  "CFS5": {
      "icao": "CFS5",
      "iata": "",
      "name": "Spirit River Airport",
      "city": "Spirit River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2044,
      "lat": 55.7835998535,
      "lon": -118.8399963379,
      "tz": "America\/Edmonton"
  },
  "CFS6": {
      "icao": "CFS6",
      "iata": "",
      "name": "Loon River Airport",
      "city": "Loon River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1550,
      "lat": 57.1418991089,
      "lon": -115.0749969482,
      "tz": "America\/Edmonton"
  },
  "CFS7": {
      "icao": "CFS7",
      "iata": "",
      "name": "Twin Creeks Airport",
      "city": "Twin Creeks",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2913,
      "lat": 62.6194000244,
      "lon": -131.279006958,
      "tz": "America\/Whitehorse"
  },
  "CFS8": {
      "icao": "CFS8",
      "iata": "",
      "name": "Clearwater River Airport",
      "city": "Clearwater River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 4100,
      "lat": 51.9880981445,
      "lon": -115.2279968262,
      "tz": "America\/Edmonton"
  },
  "CFT2": {
      "icao": "CFT2",
      "iata": "",
      "name": "Wilderman Farm Airport",
      "city": "Blackie",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3350,
      "lat": 50.552434748,
      "lon": -113.583183289,
      "tz": "America\/Edmonton"
  },
  "CFT3": {
      "icao": "CFT3",
      "iata": "",
      "name": "Finlayson Lake Airport",
      "city": "Finlayson Lake",
      "state": "Yukon",
      "country": "CA",
      "elevation": 3094,
      "lat": 61.6913986206,
      "lon": -130.774002075,
      "tz": "America\/Whitehorse"
  },
  "CFT5": {
      "icao": "CFT5",
      "iata": "",
      "name": "Hyland Airport",
      "city": "Hyland",
      "state": "Yukon",
      "country": "CA",
      "elevation": 3000,
      "lat": 61.5238990784,
      "lon": -128.2689971924,
      "tz": "America\/Whitehorse"
  },
  "CFT8": {
      "icao": "CFT8",
      "iata": "",
      "name": "Pelican Airport",
      "city": "Pelican",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2059,
      "lat": 56.1608009338,
      "lon": -113.4739990234,
      "tz": "America\/Edmonton"
  },
  "CFT9": {
      "icao": "CFT9",
      "iata": "",
      "name": "Zama Lake Airport",
      "city": "Zama Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1242,
      "lat": 59.0638999939,
      "lon": -118.8899993896,
      "tz": "America\/Edmonton"
  },
  "CFU3": {
      "icao": "CFU3",
      "iata": "",
      "name": "Chipman Airport",
      "city": "Chipman",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2195,
      "lat": 53.7167015076,
      "lon": -112.6330032349,
      "tz": "America\/Edmonton"
  },
  "CFU4": {
      "icao": "CFU4",
      "iata": "",
      "name": "Garden River Airport",
      "city": "Garden River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 790,
      "lat": 58.7139015198,
      "lon": -113.8759994507,
      "tz": "America\/Edmonton"
  },
  "CFU8": {
      "icao": "CFU8",
      "iata": "",
      "name": "Irma Airport",
      "city": "Irma",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2230,
      "lat": 52.9122009277,
      "lon": -111.1740036011,
      "tz": "America\/Edmonton"
  },
  "CFV2": {
      "icao": "CFV2",
      "iata": "",
      "name": "Beiseker Airport",
      "city": "Beiseker",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3036,
      "lat": 51.3932991028,
      "lon": -113.4720001221,
      "tz": "America\/Edmonton"
  },
  "CFV3": {
      "icao": "CFV3",
      "iata": "",
      "name": "Mobil Bistcho Airport",
      "city": "Mobil Bistcho",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2000,
      "lat": 59.4747326918,
      "lon": -119.006137848,
      "tz": "America\/Edmonton"
  },
  "CFV6": {
      "icao": "CFV6",
      "iata": "",
      "name": "Margaret Lake Airport",
      "city": "Margaret Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2750,
      "lat": 58.9458023034,
      "lon": -115.256538391,
      "tz": "America\/Edmonton"
  },
  "CFW2": {
      "icao": "CFW2",
      "iata": "",
      "name": "Gordon Lake Airport",
      "city": "Gordon Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1600,
      "lat": 56.6166992188,
      "lon": -110.5,
      "tz": "America\/Edmonton"
  },
  "CFW4": {
      "icao": "CFW4",
      "iata": "",
      "name": "Muskeg Tower Airport",
      "city": "Muskeg Tower",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2020,
      "lat": 57.1375007629,
      "lon": -110.8929977417,
      "tz": "America\/Edmonton"
  },
  "CFW5": {
      "icao": "CFW5",
      "iata": "",
      "name": "Taltson River Airport",
      "city": "Taltson River",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 875,
      "lat": 60.3833007813,
      "lon": -111.3499984741,
      "tz": "America\/Yellowknife"
  },
  "CFX2": {
      "icao": "CFX2",
      "iata": "",
      "name": "Calgary \/ Okotoks Air Park",
      "city": "Calgary",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3601,
      "lat": 50.7352981567,
      "lon": -113.934997559,
      "tz": "America\/Edmonton"
  },
  "CFX3": {
      "icao": "CFX3",
      "iata": "",
      "name": "Doig Airport",
      "city": "Doig",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3500,
      "lat": 56.9500007629,
      "lon": -119.516998291,
      "tz": "America\/Edmonton"
  },
  "CFX4": {
      "icao": "CFX4",
      "iata": "",
      "name": "Manning Airport",
      "city": "Manning",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1612,
      "lat": 56.9508018494,
      "lon": -117.6439971924,
      "tz": "America\/Edmonton"
  },
  "CFX6": {
      "icao": "CFX6",
      "iata": "",
      "name": "Vulcan Airport",
      "city": "Vulcan",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3437,
      "lat": 50.4047012329,
      "lon": -113.2829971313,
      "tz": "America\/Edmonton"
  },
  "CFX8": {
      "icao": "CFX8",
      "iata": "",
      "name": "Chestermere (Kirkby Field) Airport",
      "city": "Chestermere",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3339,
      "lat": 51.0416984558,
      "lon": -113.7519989014,
      "tz": "America\/Edmonton"
  },
  "CFY2": {
      "icao": "CFY2",
      "iata": "",
      "name": "Grist Lake Airport",
      "city": "Grist Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2000,
      "lat": 55.4000015259,
      "lon": -110.483001709,
      "tz": "America\/Edmonton"
  },
  "CFY4": {
      "icao": "CFY4",
      "iata": "",
      "name": "Winters Aire Park Airport",
      "city": "Indus",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3370,
      "lat": 50.9048190147,
      "lon": -113.796215057,
      "tz": "America\/Edmonton"
  },
  "CFY5": {
      "icao": "CFY5",
      "iata": "",
      "name": "Pine Lake",
      "city": "Pine Lake",
      "state": "Yukon",
      "country": "CA",
      "elevation": 3250,
      "lat": 60.103099823,
      "lon": -130.9340057373,
      "tz": "America\/Whitehorse"
  },
  "CFY6": {
      "icao": "CFY6",
      "iata": "",
      "name": "Turner Valley Bar N Ranch Airport",
      "city": "Turner Valley Bar N Ranch",
      "state": "Alberta",
      "country": "CA",
      "elevation": 4210,
      "lat": 50.6543998718,
      "lon": -114.34400177,
      "tz": "America\/Edmonton"
  },
  "CFZ3": {
      "icao": "CFZ3",
      "iata": "",
      "name": "Schlenker Airport",
      "city": "Medicine Hat",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2365,
      "lat": 49.9667015076,
      "lon": -110.7170028687,
      "tz": "America\/Edmonton"
  },
  "CFZ5": {
      "icao": "CFZ5",
      "iata": "",
      "name": "Sundre \/ Goodwins Farm Airport",
      "city": "Sundre",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3700,
      "lat": 51.7398057147,
      "lon": -114.662600756,
      "tz": "America\/Edmonton"
  },
  "CGB2": {
      "icao": "CGB2",
      "iata": "",
      "name": "Bishell's Airport",
      "city": "Carstairs",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3400,
      "lat": 51.5828018188,
      "lon": -114.0520019531,
      "tz": "America\/Edmonton"
  },
  "CGF2": {
      "icao": "CGF2",
      "iata": "",
      "name": "Edmonton \/ Goyer Field",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2400,
      "lat": 53.5762892197,
      "lon": -112.982025146,
      "tz": "America\/Edmonton"
  },
  "CGK2": {
      "icao": "CGK2",
      "iata": "",
      "name": "Gahcho Kue",
      "city": "",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 1371,
      "lat": 63.4330558777,
      "lon": -109.19972229,
      "tz": "America\/Yellowknife"
  },
  "CGL2": {
      "icao": "CGL2",
      "iata": "",
      "name": "Harrow Airport",
      "city": "Harrow",
      "state": "Ontario",
      "country": "CA",
      "elevation": 610,
      "lat": 42.0593986511,
      "lon": -82.8407974243,
      "tz": "America\/Toronto"
  },
  "CGL4": {
      "icao": "CGL4",
      "iata": "",
      "name": "Eaglesham (South) Airport",
      "city": "Eaglesham",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1920,
      "lat": 55.6711006165,
      "lon": -117.9359970093,
      "tz": "America\/Edmonton"
  },
  "CGR3": {
      "icao": "CGR3",
      "iata": "",
      "name": "George Lake Aerodrome",
      "city": "",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 1150,
      "lat": 65.928056,
      "lon": -102.462222,
      "tz": "America\/Cambridge_Bay"
  },
  "CGS2": {
      "icao": "CGS2",
      "iata": "",
      "name": "Goose Lake Aerodrome",
      "city": "",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 907,
      "lat": 65.552778,
      "lon": -106.433611,
      "tz": "America\/Cambridge_Bay"
  },
  "CGV2": {
      "icao": "CGV2",
      "iata": "",
      "name": "Grand Valley \/ Luther Field",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1602,
      "lat": 43.976388889,
      "lon": -80.38527778,
      "tz": "America\/Toronto"
  },
  "CGV5": {
      "icao": "CGV5",
      "iata": "",
      "name": "Grand Valley (Black Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1580,
      "lat": 43.8600915245,
      "lon": -80.2886867523,
      "tz": "America\/Toronto"
  },
  "CGV6": {
      "icao": "CGV6",
      "iata": "",
      "name": "Grand Valley (Martin Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1550,
      "lat": 43.8748961118,
      "lon": -80.2824425697,
      "tz": "America\/Toronto"
  },
  "CHC5": {
      "icao": "CHC5",
      "iata": "",
      "name": "Hayes Camp Aerodrome",
      "city": "",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 780,
      "lat": 66.655556,
      "lon": -91.545,
      "tz": "America\/Rankin_Inlet"
  },
  "CHF2": {
      "icao": "CHF2",
      "iata": "",
      "name": "Ottawa \/ Manotick (Hope Field)",
      "city": "Manotick",
      "state": "Ontario",
      "country": "CA",
      "elevation": 315,
      "lat": 45.1906013489,
      "lon": -75.7086029053,
      "tz": "America\/Toronto"
  },
  "CHG2": {
      "icao": "CHG2",
      "iata": "",
      "name": "Harbour Grace Airport",
      "city": "Harbour Grace",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 325,
      "lat": 47.6856002808,
      "lon": -53.2538986206,
      "tz": "America\/St_Johns"
  },
  "CHM2": {
      "icao": "CHM2",
      "iata": "",
      "name": "Spiritwood \/ H & M Fast Farms",
      "city": "",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2250,
      "lat": 53.275,
      "lon": -107.57027778,
      "tz": "America\/Swift_Current"
  },
  "CHS3": {
      "icao": "CHS3",
      "iata": "",
      "name": "Highgate (South)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 740,
      "lat": 42.4736111111,
      "lon": -81.8219444444,
      "tz": "America\/Toronto"
  },
  "CJA3": {
      "icao": "CJA3",
      "iata": "",
      "name": "Morden Airport",
      "city": "Morden",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 950,
      "lat": 49.2103004456,
      "lon": -98.0591964722,
      "tz": "America\/Winnipeg"
  },
  "CJA5": {
      "icao": "CJA5",
      "iata": "",
      "name": "Nestor Falls Airport",
      "city": "Nestor Falls",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1195,
      "lat": 49.1403007507,
      "lon": -93.9169006348,
      "tz": "America\/Rainy_River"
  },
  "CJA7": {
      "icao": "CJA7",
      "iata": "",
      "name": "Arcola Airport",
      "city": "Arcola",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1985,
      "lat": 49.6333007813,
      "lon": -102.483001709,
      "tz": "America\/Regina"
  },
  "CJB2": {
      "icao": "CJB2",
      "iata": "",
      "name": "Friendship Field",
      "city": "Carman",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 882,
      "lat": 49.4914016724,
      "lon": -98.0186004639,
      "tz": "America\/Winnipeg"
  },
  "CJB3": {
      "icao": "CJB3",
      "iata": "",
      "name": "Steinbach Airport",
      "city": "Steinbach",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 850,
      "lat": 49.5494003296,
      "lon": -96.679397583,
      "tz": "America\/Winnipeg"
  },
  "CJB5": {
      "icao": "CJB5",
      "iata": "",
      "name": "Moosomin Airport",
      "city": "Moosomin",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1853,
      "lat": 50.168598175,
      "lon": -101.6439971924,
      "tz": "America\/Regina"
  },
  "CJB6": {
      "icao": "CJB6",
      "iata": "",
      "name": "Gods Lake Airport",
      "city": "Gods Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 605,
      "lat": 54.7792015076,
      "lon": -93.7169036865,
      "tz": "America\/Winnipeg"
  },
  "CJB8": {
      "icao": "CJB8",
      "iata": "",
      "name": "Kyle Airport",
      "city": "Kyle",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2175,
      "lat": 50.8333015442,
      "lon": -108.0699996948,
      "tz": "America\/Swift_Current"
  },
  "CJC2": {
      "icao": "CJC2",
      "iata": "",
      "name": "Craik Airport",
      "city": "Craik",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1950,
      "lat": 51.056098938,
      "lon": -105.8379974365,
      "tz": "America\/Regina"
  },
  "CJC3": {
      "icao": "CJC3",
      "iata": "",
      "name": "Davidson Municipal Airport",
      "city": "Davidson",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2025,
      "lat": 51.2477989197,
      "lon": -105.9759979248,
      "tz": "America\/Regina"
  },
  "CJC4": {
      "icao": "CJC4",
      "iata": "",
      "name": "Central Butte Airport",
      "city": "Central Butte",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2030,
      "lat": 50.801399231,
      "lon": -106.4879989624,
      "tz": "America\/Swift_Current"
  },
  "CJC5": {
      "icao": "CJC5",
      "iata": "",
      "name": "Shaunavon Airport",
      "city": "Shaunavon",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 3028,
      "lat": 49.6581001282,
      "lon": -108.40599823,
      "tz": "America\/Swift_Current"
  },
  "CJC6": {
      "icao": "CJC6",
      "iata": "",
      "name": "Hafford Airport",
      "city": "Hafford",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1935,
      "lat": 52.7322006226,
      "lon": -107.3740005493,
      "tz": "America\/Swift_Current"
  },
  "CJC8": {
      "icao": "CJC8",
      "iata": "",
      "name": "Laurie River Airport",
      "city": "Laurie River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1200,
      "lat": 56.2486000061,
      "lon": -101.3040008545,
      "tz": "America\/Winnipeg"
  },
  "CJD2": {
      "icao": "CJD2",
      "iata": "",
      "name": "Cudworth Municipal Airport",
      "city": "Cudworth",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1878,
      "lat": 52.4832992554,
      "lon": -105.7170028687,
      "tz": "America\/Regina"
  },
  "CJD3": {
      "icao": "CJD3",
      "iata": "",
      "name": "Birch Hills Airport",
      "city": "Birch Hills",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1500,
      "lat": 52.9907989502,
      "lon": -105.4449996948,
      "tz": "America\/Regina"
  },
  "CJD5": {
      "icao": "CJD5",
      "iata": "",
      "name": "Leader Airport",
      "city": "Leader",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2201,
      "lat": 50.8772010803,
      "lon": -109.5009994507,
      "tz": "America\/Swift_Current"
  },
  "CJE2": {
      "icao": "CJE2",
      "iata": "",
      "name": "Dore Lake Airport",
      "city": "Dore Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1565,
      "lat": 54.6166992188,
      "lon": -107.3830032349,
      "tz": "America\/Regina"
  },
  "CJE3": {
      "icao": "CJE3",
      "iata": "",
      "name": "Weyburn Airport",
      "city": "Weyburn",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1934,
      "lat": 49.6974983215,
      "lon": -103.8010025024,
      "tz": "America\/Regina"
  },
  "CJE4": {
      "icao": "CJE4",
      "iata": "",
      "name": "Snow Lake Airport",
      "city": "Snow Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 993,
      "lat": 54.8972015381,
      "lon": -99.818901062,
      "tz": "America\/Winnipeg"
  },
  "CJE5": {
      "icao": "CJE5",
      "iata": "",
      "name": "Glaslyn Airport",
      "city": "Glaslyn",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2250,
      "lat": 53.3772010803,
      "lon": -108.3420028687,
      "tz": "America\/Swift_Current"
  },
  "CJE6": {
      "icao": "CJE6",
      "iata": "",
      "name": "Paradise Hill Airport",
      "city": "Paradise Hill",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1927,
      "lat": 53.5353012085,
      "lon": -109.4339981079,
      "tz": "America\/Swift_Current"
  },
  "CJE7": {
      "icao": "CJE7",
      "iata": "",
      "name": "Ashern Airport",
      "city": "Ashern",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 976,
      "lat": 51.1585998535,
      "lon": -98.3319015503,
      "tz": "America\/Winnipeg"
  },
  "CJF3": {
      "icao": "CJF3",
      "iata": "",
      "name": "Ile-a-la-Crosse Airport",
      "city": "Ile-a-la-Crosse",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1394,
      "lat": 55.4897003174,
      "lon": -107.9300003052,
      "tz": "America\/Regina"
  },
  "CJF5": {
      "icao": "CJF5",
      "iata": "",
      "name": "West Poplar Airport",
      "city": "West Poplar",
      "state": "Montana",
      "country": "CA",
      "elevation": 2885,
      "lat": 49.0031013489,
      "lon": -106.3870010376,
      "tz": "America\/Denver"
  },
  "CJF8": {
      "icao": "CJF8",
      "iata": "",
      "name": "Biggar Airport",
      "city": "Biggar",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2130,
      "lat": 52.0511016846,
      "lon": -107.9879989624,
      "tz": "America\/Swift_Current"
  },
  "CJG2": {
      "icao": "CJG2",
      "iata": "",
      "name": "Eatonia (Elvie Smith) Municipal Airport",
      "city": "Eatonia",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2365,
      "lat": 51.2188987732,
      "lon": -109.3929977417,
      "tz": "America\/Swift_Current"
  },
  "CJG4": {
      "icao": "CJG4",
      "iata": "",
      "name": "Wrong Lake Airport",
      "city": "",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 840,
      "lat": 52.6154522074,
      "lon": -96.184015274,
      "tz": "America\/Winnipeg"
  },
  "CJH2": {
      "icao": "CJH2",
      "iata": "",
      "name": "Gilbert Plains Airport",
      "city": "Gilbert Plains",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1310,
      "lat": 51.1333007813,
      "lon": -100.5,
      "tz": "America\/Winnipeg"
  },
  "CJH3": {
      "icao": "CJH3",
      "iata": "",
      "name": "Maidstone Airport",
      "city": "Maidstone",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1975,
      "lat": 53.0943984985,
      "lon": -109.3249969482,
      "tz": "America\/Edmonton"
  },
  "CJH4": {
      "icao": "CJH4",
      "iata": "",
      "name": "Ferland Airport",
      "city": "Ferland",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2550,
      "lat": 49.4449996948,
      "lon": -106.9319992065,
      "tz": "America\/Swift_Current"
  },
  "CJH8": {
      "icao": "CJH8",
      "iata": "",
      "name": "Leask Airport",
      "city": "Leask",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1715,
      "lat": 53.0167007446,
      "lon": -106.75,
      "tz": "America\/Swift_Current"
  },
  "CJJ2": {
      "icao": "CJJ2",
      "iata": "",
      "name": "Glenboro Airport",
      "city": "Glenboro",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1240,
      "lat": 49.5499992371,
      "lon": -99.3332977295,
      "tz": "America\/Winnipeg"
  },
  "CJJ3": {
      "icao": "CJJ3",
      "iata": "",
      "name": "Wildwood \/ Loche Mist Farms Airstrip",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2880,
      "lat": 53.546529605,
      "lon": -115.260658264,
      "tz": "America\/Edmonton"
  },
  "CJJ4": {
      "icao": "CJJ4",
      "iata": "",
      "name": "Deloraine Airport",
      "city": "Deloraine",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1680,
      "lat": 49.1506004333,
      "lon": -100.5009994507,
      "tz": "America\/Winnipeg"
  },
  "CJJ5": {
      "icao": "CJJ5",
      "iata": "",
      "name": "Cabri Airport",
      "city": "Cabri",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2175,
      "lat": 50.6194000244,
      "lon": -108.466003418,
      "tz": "America\/Swift_Current"
  },
  "CJJ8": {
      "icao": "CJJ8",
      "iata": "",
      "name": "Macklin Airport",
      "city": "Macklin",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2280,
      "lat": 52.3428001404,
      "lon": -109.9189987183,
      "tz": "America\/Swift_Current"
  },
  "CJK2": {
      "icao": "CJK2",
      "iata": "",
      "name": "Gunisao Lake Airport",
      "city": "Gunisao Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 886,
      "lat": 53.5200004578,
      "lon": -96.3711013794,
      "tz": "America\/Winnipeg"
  },
  "CJK3": {
      "icao": "CJK3",
      "iata": "",
      "name": "Beauval Airport",
      "city": "Beauval",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1436,
      "lat": 55.1102981567,
      "lon": -107.716003418,
      "tz": "America\/Regina"
  },
  "CJK4": {
      "icao": "CJK4",
      "iata": "",
      "name": "Esterhazy Airport",
      "city": "Esterhazy",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1700,
      "lat": 50.6427993774,
      "lon": -102.0999984741,
      "tz": "America\/Regina"
  },
  "CJK5": {
      "icao": "CJK5",
      "iata": "",
      "name": "Gull Lake Airport",
      "city": "Gull Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2665,
      "lat": 50.057800293,
      "lon": -108.4899978638,
      "tz": "America\/Swift_Current"
  },
  "CJK9": {
      "icao": "CJK9",
      "iata": "",
      "name": "Preeceville Airport",
      "city": "Preeceville",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1750,
      "lat": 51.9500007629,
      "lon": -102.6500015259,
      "tz": "America\/Regina"
  },
  "CJL2": {
      "icao": "CJL2",
      "iata": "",
      "name": "Hatchet Lake Airport",
      "city": "Hatchet Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1362,
      "lat": 58.6624984741,
      "lon": -103.5380020142,
      "tz": "America\/Regina"
  },
  "CJL4": {
      "icao": "CJL4",
      "iata": "",
      "name": "La Loche Airport",
      "city": "La Loche",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1501,
      "lat": 56.4733009338,
      "lon": -109.4039993286,
      "tz": "America\/Regina"
  },
  "CJL5": {
      "icao": "CJL5",
      "iata": "",
      "name": "Winnipeg \/ Lyncrest Airport",
      "city": "Winnipeg",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 775,
      "lat": 49.8525009155,
      "lon": -96.9736022949,
      "tz": "America\/Winnipeg"
  },
  "CJL6": {
      "icao": "CJL6",
      "iata": "",
      "name": "Altona Municipal Airport",
      "city": "Altona",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 806,
      "lat": 49.0943984985,
      "lon": -97.5338973999,
      "tz": "America\/Winnipeg"
  },
  "CJL8": {
      "icao": "CJL8",
      "iata": "",
      "name": "Kasba Lake Airport",
      "city": "Kasba Lake",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 1131,
      "lat": 60.2919006348,
      "lon": -102.5019989014,
      "tz": "America\/Yellowknife"
  },
  "CJL9": {
      "icao": "CJL9",
      "iata": "",
      "name": "Radisson Airport",
      "city": "Radisson",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1675,
      "lat": 52.4631004333,
      "lon": -107.3769989014,
      "tz": "America\/Swift_Current"
  },
  "CJM2": {
      "icao": "CJM2",
      "iata": "",
      "name": "Ituna Airport",
      "city": "Ituna",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2200,
      "lat": 51.1460990906,
      "lon": -103.4260025024,
      "tz": "America\/Regina"
  },
  "CJM4": {
      "icao": "CJM4",
      "iata": "",
      "name": "Gravelbourg Airport",
      "city": "Gravelbourg",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2296,
      "lat": 49.8662071228,
      "lon": -106.5710754395,
      "tz": "America\/Swift_Current"
  },
  "CJM5": {
      "icao": "CJM5",
      "iata": "",
      "name": "Frontier Airport",
      "city": "Frontier",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2960,
      "lat": 49.1666984558,
      "lon": -108.5670013428,
      "tz": "America\/Swift_Current"
  },
  "CJM6": {
      "icao": "CJM6",
      "iata": "",
      "name": "Arborfield Airport",
      "city": "Arborfield",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1183,
      "lat": 53.10779953,
      "lon": -103.6510009766,
      "tz": "America\/Regina"
  },
  "CJN2": {
      "icao": "CJN2",
      "iata": "",
      "name": "Kamsack Airport",
      "city": "Kamsack",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1510,
      "lat": 51.5597000122,
      "lon": -101.8789978027,
      "tz": "America\/Regina"
  },
  "CJN4": {
      "icao": "CJN4",
      "iata": "",
      "name": "Assiniboia Airport",
      "city": "Assiniboia",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2370,
      "lat": 49.7346992493,
      "lon": -105.9469985962,
      "tz": "America\/Regina"
  },
  "CJN5": {
      "icao": "CJN5",
      "iata": "",
      "name": "Corman Air Park",
      "city": "Saskatoon",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1690,
      "lat": 52.0005989075,
      "lon": -106.4629974365,
      "tz": "America\/Regina"
  },
  "CJN7": {
      "icao": "CJN7",
      "iata": "",
      "name": "Little Churchill River \/ Dunlop's Fly-In Lodge Airport",
      "city": "Little Churchill River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 700,
      "lat": 56.5800018311,
      "lon": -96.2474975586,
      "tz": "America\/Winnipeg"
  },
  "CJP2": {
      "icao": "CJP2",
      "iata": "",
      "name": "Kerrobert Airport",
      "city": "Kerrobert",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2208,
      "lat": 51.9268989563,
      "lon": -109.1289978027,
      "tz": "America\/Swift_Current"
  },
  "CJP6": {
      "icao": "CJP6",
      "iata": "",
      "name": "Camsell Portage Airport",
      "city": "Camsell Portage",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 750,
      "lat": 59.6100006104,
      "lon": -109.266998291,
      "tz": "America\/Regina"
  },
  "CJP7": {
      "icao": "CJP7",
      "iata": "",
      "name": "Bird River (Lac Du Bonnet) Airport",
      "city": "Bird River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 950,
      "lat": 50.3967018127,
      "lon": -95.7350006104,
      "tz": "America\/Winnipeg"
  },
  "CJP9": {
      "icao": "CJP9",
      "iata": "",
      "name": "Charlot River Airport",
      "city": "Charlot River",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 700,
      "lat": 59.6016998291,
      "lon": -109.1370010376,
      "tz": "America\/Regina"
  },
  "CJQ2": {
      "icao": "CJQ2",
      "iata": "",
      "name": "Lampman Airport",
      "city": "Lampman",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1950,
      "lat": 49.3666992188,
      "lon": -102.766998291,
      "tz": "America\/Regina"
  },
  "CJQ3": {
      "icao": "CJQ3",
      "iata": "",
      "name": "Carlyle Airport",
      "city": "Carlyle",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2074,
      "lat": 49.6444015503,
      "lon": -102.2870025635,
      "tz": "America\/Regina"
  },
  "CJQ4": {
      "icao": "CJQ4",
      "iata": "",
      "name": "Maple Creek Airport",
      "city": "Maple Creek",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2517,
      "lat": 49.8958015442,
      "lon": -109.4749984741,
      "tz": "America\/Swift_Current"
  },
  "CJQ5": {
      "icao": "CJQ5",
      "iata": "",
      "name": "Arnes Airport",
      "city": "Arnes",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 725,
      "lat": 50.836101532,
      "lon": -96.9571990967,
      "tz": "America\/Winnipeg"
  },
  "CJQ6": {
      "icao": "CJQ6",
      "iata": "",
      "name": "Tanquary Fiord Airport",
      "city": "Tanquary Fiord",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 50,
      "lat": 81.4094009399,
      "lon": -76.8816986084,
      "tz": "America\/Iqaluit"
  },
  "CJQ9": {
      "icao": "CJQ9",
      "iata": "",
      "name": "Big Sand Lake Airport",
      "city": "Big Sand Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1050,
      "lat": 57.6194000244,
      "lon": -99.8752975464,
      "tz": "America\/Winnipeg"
  },
  "CJR2": {
      "icao": "CJR2",
      "iata": "",
      "name": "Luseland Airport",
      "city": "Luseland",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2300,
      "lat": 52.0699996948,
      "lon": -109.3740005493,
      "tz": "America\/Swift_Current"
  },
  "CJR3": {
      "icao": "CJR3",
      "iata": "",
      "name": "The Pas \/ Grace Lake Airport",
      "city": "The Pas",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 873,
      "lat": 53.8264007568,
      "lon": -101.205001831,
      "tz": "America\/Winnipeg"
  },
  "CJR4": {
      "icao": "CJR4",
      "iata": "",
      "name": "Eston Airport",
      "city": "Eston",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2225,
      "lat": 51.1441993713,
      "lon": -108.7630004883,
      "tz": "America\/Swift_Current"
  },
  "CJR5": {
      "icao": "CJR5",
      "iata": "",
      "name": "Gladstone Airport",
      "city": "Gladstone",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 900,
      "lat": 50.1636009216,
      "lon": -98.9424972534,
      "tz": "America\/Winnipeg"
  },
  "CJR7": {
      "icao": "CJR7",
      "iata": "",
      "name": "Canora Airport",
      "city": "Canora",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1603,
      "lat": 51.6288986206,
      "lon": -102.4499969482,
      "tz": "America\/Regina"
  },
  "CJR8": {
      "icao": "CJR8",
      "iata": "",
      "name": "Mccreary Airport",
      "city": "Mccreary",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1000,
      "lat": 50.7647018433,
      "lon": -99.4966964722,
      "tz": "America\/Winnipeg"
  },
  "CJR9": {
      "icao": "CJR9",
      "iata": "",
      "name": "Naicam Airport",
      "city": "Naicam",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1780,
      "lat": 52.4113998413,
      "lon": -104.4840011597,
      "tz": "America\/Regina"
  },
  "CJS2": {
      "icao": "CJS2",
      "iata": "",
      "name": "Malcolm Island Airport",
      "city": "Malcolm Island",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1120,
      "lat": 56.9494018555,
      "lon": -102.2389984131,
      "tz": "America\/Regina"
  },
  "CJS3": {
      "icao": "CJS3",
      "iata": "",
      "name": "Cluff Lake Airport",
      "city": "Cluff Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1112,
      "lat": 58.3911018372,
      "lon": -109.5159988403,
      "tz": "America\/Regina"
  },
  "CJS4": {
      "icao": "CJS4",
      "iata": "",
      "name": "Moose Jaw Municipal Airport",
      "city": "Moose Jaw",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1896,
      "lat": 50.4342002869,
      "lon": -105.3870010376,
      "tz": "America\/Regina"
  },
  "CJS5": {
      "icao": "CJS5",
      "iata": "",
      "name": "Killarney Municipal Airport",
      "city": "Killarney",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1680,
      "lat": 49.1516990662,
      "lon": -99.6902999878,
      "tz": "America\/Winnipeg"
  },
  "CJS7": {
      "icao": "CJS7",
      "iata": "",
      "name": "Carman (South) Airport",
      "city": "Carman",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 870,
      "lat": 49.4803009033,
      "lon": -98.0149993896,
      "tz": "America\/Winnipeg"
  },
  "CJT2": {
      "icao": "CJT2",
      "iata": "",
      "name": "Matheson Island Airport",
      "city": "Matheson Island",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 725,
      "lat": 51.7322006226,
      "lon": -96.9344024658,
      "tz": "America\/Winnipeg"
  },
  "CJT3": {
      "icao": "CJT3",
      "iata": "",
      "name": "Knee Lake Airport",
      "city": "Knee Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 625,
      "lat": 54.9152984619,
      "lon": -94.7981033325,
      "tz": "America\/Winnipeg"
  },
  "CJT4": {
      "icao": "CJT4",
      "iata": "",
      "name": "Cumberland House Airport",
      "city": "Cumberland House",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 877,
      "lat": 53.9561004639,
      "lon": -102.297996521,
      "tz": "America\/Regina"
  },
  "CJT5": {
      "icao": "CJT5",
      "iata": "",
      "name": "Melita Airport",
      "city": "Melita",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1475,
      "lat": 49.2616996765,
      "lon": -101.013999939,
      "tz": "America\/Winnipeg"
  },
  "CJT8": {
      "icao": "CJT8",
      "iata": "",
      "name": "Homewood Airport",
      "city": "Homewood",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 815,
      "lat": 49.5092010498,
      "lon": -97.8506011963,
      "tz": "America\/Winnipeg"
  },
  "CJT9": {
      "icao": "CJT9",
      "iata": "",
      "name": "Leoville Airport",
      "city": "Leoville",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1960,
      "lat": 53.6206016541,
      "lon": -107.6090011597,
      "tz": "America\/Swift_Current"
  },
  "CJU3": {
      "icao": "CJU3",
      "iata": "",
      "name": "Macdonald Airport",
      "city": "Macdonald",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 840,
      "lat": 50.0964012146,
      "lon": -98.5008010864,
      "tz": "America\/Winnipeg"
  },
  "CJU4": {
      "icao": "CJU4",
      "iata": "",
      "name": "Humboldt Airport",
      "city": "Humboldt",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1865,
      "lat": 52.1761016846,
      "lon": -105.1269989014,
      "tz": "America\/Regina"
  },
  "CJU5": {
      "icao": "CJU5",
      "iata": "",
      "name": "Minnedosa Airport",
      "city": "Minnedosa",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1840,
      "lat": 50.271900177,
      "lon": -99.7630996704,
      "tz": "America\/Winnipeg"
  },
  "CJU6": {
      "icao": "CJU6",
      "iata": "",
      "name": "Arborg Airport",
      "city": "Arborg",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 765,
      "lat": 50.9127998352,
      "lon": -97.304397583,
      "tz": "America\/Winnipeg"
  },
  "CJU7": {
      "icao": "CJU7",
      "iata": "",
      "name": "Edam Airport",
      "city": "Edam",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1790,
      "lat": 53.1850013733,
      "lon": -108.7890014648,
      "tz": "America\/Swift_Current"
  },
  "CJV2": {
      "icao": "CJV2",
      "iata": "",
      "name": "Neilburg Airport",
      "city": "Neilburg",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2222,
      "lat": 52.831401825,
      "lon": -109.6399993896,
      "tz": "America\/Edmonton"
  },
  "CJV4": {
      "icao": "CJV4",
      "iata": "",
      "name": "Otter Lake Airport",
      "city": "Otter Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1300,
      "lat": 55.5811004639,
      "lon": -104.7850036621,
      "tz": "America\/Regina"
  },
  "CJV5": {
      "icao": "CJV5",
      "iata": "",
      "name": "Neepawa Airport",
      "city": "Neepawa",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1277,
      "lat": 50.23279953,
      "lon": -99.510597229,
      "tz": "America\/Winnipeg"
  },
  "CJV7": {
      "icao": "CJV7",
      "iata": "SUR",
      "name": "Summer Beaver Airport",
      "city": "Summer Beaver",
      "state": "Ontario",
      "country": "CA",
      "elevation": 832,
      "lat": 52.7085990906,
      "lon": -88.5419006348,
      "tz": "America\/Nipigon"
  },
  "CJV8": {
      "icao": "CJV8",
      "iata": "",
      "name": "Grand Rapids Airport",
      "city": "Grand Rapids",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 860,
      "lat": 53.1725006104,
      "lon": -99.323097229,
      "tz": "America\/Winnipeg"
  },
  "CJV9": {
      "icao": "CJV9",
      "iata": "",
      "name": "Melville Municipal Airport",
      "city": "Melville",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1800,
      "lat": 50.9413986206,
      "lon": -102.7379989624,
      "tz": "America\/Regina"
  },
  "CJW2": {
      "icao": "CJW2",
      "iata": "",
      "name": "Oxbow Airport",
      "city": "Oxbow",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1850,
      "lat": 49.2332992554,
      "lon": -102.1500015259,
      "tz": "America\/Regina"
  },
  "CJW3": {
      "icao": "CJW3",
      "iata": "",
      "name": "Loon Lake Airport",
      "city": "Loon Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1790,
      "lat": 54.0182991028,
      "lon": -109.1350021362,
      "tz": "America\/Swift_Current"
  },
  "CJW4": {
      "icao": "CJW4",
      "iata": "",
      "name": "Pelican Narrows Airport",
      "city": "Pelican Narrows",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1242,
      "lat": 55.2868995667,
      "lon": -102.75,
      "tz": "America\/Regina"
  },
  "CJW5": {
      "icao": "CJW5",
      "iata": "",
      "name": "Russell Airport",
      "city": "Russell",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1825,
      "lat": 50.7658004761,
      "lon": -101.2949981689,
      "tz": "America\/Winnipeg"
  },
  "CJW7": {
      "icao": "CJW7",
      "iata": "",
      "name": "Cigar Lake Airport",
      "city": "Cigar Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1562,
      "lat": 58.0531005859,
      "lon": -104.4840011597,
      "tz": "America\/Regina"
  },
  "CJX4": {
      "icao": "CJX4",
      "iata": "",
      "name": "Rosetown Airport",
      "city": "Rosetown",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1920,
      "lat": 51.5643997192,
      "lon": -107.9179992676,
      "tz": "America\/Swift_Current"
  },
  "CJX5": {
      "icao": "CJX5",
      "iata": "",
      "name": "Souris Glenwood Industrial Air Park",
      "city": "Souris Glenwood",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1480,
      "lat": 49.6333007813,
      "lon": -100.1999969482,
      "tz": "America\/Winnipeg"
  },
  "CJY3": {
      "icao": "CJY3",
      "iata": "",
      "name": "Tisdale Airport",
      "city": "Tisdale",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1525,
      "lat": 52.8367004395,
      "lon": -104.0670013428,
      "tz": "America\/Regina"
  },
  "CJY4": {
      "icao": "CJY4",
      "iata": "",
      "name": "Sandy Bay Airport",
      "city": "Sandy Bay",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1001,
      "lat": 55.5456008911,
      "lon": -102.2720031738,
      "tz": "America\/Regina"
  },
  "CJY5": {
      "icao": "CJY5",
      "iata": "",
      "name": "Strathclair Airport",
      "city": "Strathclair",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1875,
      "lat": 50.3960990906,
      "lon": -100.4250030518,
      "tz": "America\/Winnipeg"
  },
  "CJZ2": {
      "icao": "CJZ2",
      "iata": "",
      "name": "Portage La Prairie (North) Airport",
      "city": "Portage La Prairie",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 860,
      "lat": 49.9925003052,
      "lon": -98.3031005859,
      "tz": "America\/Winnipeg"
  },
  "CJZ3": {
      "icao": "CJZ3",
      "iata": "",
      "name": "Melfort Airport",
      "city": "Melfort",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1495,
      "lat": 52.8666992188,
      "lon": -104.6999969482,
      "tz": "America\/Regina"
  },
  "CJZ4": {
      "icao": "CJZ4",
      "iata": "",
      "name": "Shellbrook Airport",
      "city": "Shellbrook",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1640,
      "lat": 53.2282981873,
      "lon": -106.3629989624,
      "tz": "America\/Swift_Current"
  },
  "CKA4": {
      "icao": "CKA4",
      "iata": "",
      "name": "Zhoda Airport",
      "city": "Zhoda",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 950,
      "lat": 49.2807998657,
      "lon": -96.5010986328,
      "tz": "America\/Winnipeg"
  },
  "CKA8": {
      "icao": "CKA8",
      "iata": "",
      "name": "St. Francois Xavier Airport",
      "city": "St. Francois Xavier",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 798,
      "lat": 49.9244003296,
      "lon": -97.5488967896,
      "tz": "America\/Winnipeg"
  },
  "CKA9": {
      "icao": "CKA9",
      "iata": "",
      "name": "Southend Airport",
      "city": "Southend",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1120,
      "lat": 56.3372001648,
      "lon": -103.2929992676,
      "tz": "America\/Regina"
  },
  "CKB2": {
      "icao": "CKB2",
      "iata": "",
      "name": "Patuanak Airport",
      "city": "Patuanak",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1426,
      "lat": 55.9000015259,
      "lon": -107.7210006714,
      "tz": "America\/Regina"
  },
  "CKB6": {
      "icao": "CKB6",
      "iata": "YAX",
      "name": "Wapekeka Airport",
      "city": "Angling Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 712,
      "lat": 53.8492012024,
      "lon": -89.5793991089,
      "tz": "America\/Rainy_River"
  },
  "CKB7": {
      "icao": "CKB7",
      "iata": "",
      "name": "Roblin Airport",
      "city": "Roblin",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1821,
      "lat": 51.2344017029,
      "lon": -101.3929977417,
      "tz": "America\/Winnipeg"
  },
  "CKB8": {
      "icao": "CKB8",
      "iata": "",
      "name": "Silver Falls Airport",
      "city": "Silver Falls",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 775,
      "lat": 50.5,
      "lon": -96.0980987549,
      "tz": "America\/Winnipeg"
  },
  "CKC6": {
      "icao": "CKC6",
      "iata": "",
      "name": "Lanigan Airport",
      "city": "Lanigan",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1750,
      "lat": 51.8452987671,
      "lon": -104.9919967651,
      "tz": "America\/Regina"
  },
  "CKC7": {
      "icao": "CKC7",
      "iata": "",
      "name": "Rockglen Airport",
      "city": "Rockglen",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2700,
      "lat": 49.1666984558,
      "lon": -105.9329986572,
      "tz": "America\/Regina"
  },
  "CKC8": {
      "icao": "CKC8",
      "iata": "",
      "name": "Somerset Airport",
      "city": "Somerset",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1563,
      "lat": 49.4002990723,
      "lon": -98.6924972534,
      "tz": "America\/Winnipeg"
  },
  "CKC9": {
      "icao": "CKC9",
      "iata": "",
      "name": "Pangman Airport",
      "city": "Pangman",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2300,
      "lat": 49.6464004517,
      "lon": -104.6660003662,
      "tz": "America\/Regina"
  },
  "CKD2": {
      "icao": "CKD2",
      "iata": "",
      "name": "Porcupine Plain Airport",
      "city": "Porcupine Plain",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1635,
      "lat": 52.6197013855,
      "lon": -103.2480010986,
      "tz": "America\/Regina"
  },
  "CKD5": {
      "icao": "CKD5",
      "iata": "",
      "name": "Kipling Airport",
      "city": "Kipling",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2157,
      "lat": 50.0992012024,
      "lon": -102.6070022583,
      "tz": "America\/Regina"
  },
  "CKD7": {
      "icao": "CKD7",
      "iata": "",
      "name": "Roland (Graham Field) Airport",
      "city": "Roland",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 865,
      "lat": 49.4082984924,
      "lon": -97.9906005859,
      "tz": "America\/Winnipeg"
  },
  "CKD8": {
      "icao": "CKD8",
      "iata": "",
      "name": "Kirkfield \/ Balsam Lake Airfield",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 864,
      "lat": 44.5399876549,
      "lon": -78.8892602921,
      "tz": "America\/Toronto"
  },
  "CKD9": {
      "icao": "CKD9",
      "iata": "",
      "name": "Slate Falls Airport",
      "city": "Slate Falls",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1355,
      "lat": 51.1300010681,
      "lon": -91.6656036377,
      "tz": "America\/Rainy_River"
  },
  "CKE2": {
      "icao": "CKE2",
      "iata": "",
      "name": "Quill Lake Airport",
      "city": "Quill Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1750,
      "lat": 52.0666999817,
      "lon": -104.266998291,
      "tz": "America\/Regina"
  },
  "CKE8": {
      "icao": "CKE8",
      "iata": "",
      "name": "Unity Airport",
      "city": "Unity",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2090,
      "lat": 52.4458007813,
      "lon": -109.1849975586,
      "tz": "America\/Swift_Current"
  },
  "CKF2": {
      "icao": "CKF2",
      "iata": "",
      "name": "Radville Airport",
      "city": "Radville",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2067,
      "lat": 49.4606018066,
      "lon": -104.2720031738,
      "tz": "America\/Regina"
  },
  "CKF4": {
      "icao": "CKF4",
      "iata": "",
      "name": "Goodsoil Airport",
      "city": "Goodsoil",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1750,
      "lat": 54.4166984558,
      "lon": -109.233001709,
      "tz": "America\/Swift_Current"
  },
  "CKF6": {
      "icao": "CKF6",
      "iata": "",
      "name": "Macgregor Airport",
      "city": "Macgregor",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 950,
      "lat": 49.9667015076,
      "lon": -98.75,
      "tz": "America\/Winnipeg"
  },
  "CKF9": {
      "icao": "CKF9",
      "iata": "",
      "name": "De Lesseps Lake Airport",
      "city": "De Lesseps Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1312,
      "lat": 50.7174987793,
      "lon": -90.6836013794,
      "tz": "America\/Rainy_River"
  },
  "CKG2": {
      "icao": "CKG2",
      "iata": "",
      "name": "Riverton Airport",
      "city": "Riverton",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 725,
      "lat": 50.971698761,
      "lon": -97.011100769,
      "tz": "America\/Winnipeg"
  },
  "CKG5": {
      "icao": "CKG5",
      "iata": "",
      "name": "Manitou Airport",
      "city": "Manitou",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1592,
      "lat": 49.25,
      "lon": -98.5333023071,
      "tz": "America\/Winnipeg"
  },
  "CKG8": {
      "icao": "CKG8",
      "iata": "",
      "name": "Kakabeka Falls Airport",
      "city": "Kakabeka Falls",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1000,
      "lat": 48.4183006287,
      "lon": -89.6018981934,
      "tz": "America\/Thunder_Bay"
  },
  "CKH2": {
      "icao": "CKH2",
      "iata": "",
      "name": "Rocanville Airport",
      "city": "Rocanville",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1585,
      "lat": 50.4646987915,
      "lon": -101.5540008545,
      "tz": "America\/Regina"
  },
  "CKH3": {
      "icao": "CKH3",
      "iata": "",
      "name": "Debden Airport",
      "city": "Debden",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1700,
      "lat": 53.5318984985,
      "lon": -106.8840026855,
      "tz": "America\/Swift_Current"
  },
  "CKH7": {
      "icao": "CKH7",
      "iata": "",
      "name": "Spiritwood Airport",
      "city": "Spiritwood",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1915,
      "lat": 53.3633003235,
      "lon": -107.547996521,
      "tz": "America\/Swift_Current"
  },
  "CKH8": {
      "icao": "CKH8",
      "iata": "",
      "name": "Lumsden (Colhoun) Airport",
      "city": "Lumsden",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1900,
      "lat": 50.6674995422,
      "lon": -104.7890014648,
      "tz": "America\/Regina"
  },
  "CKJ2": {
      "icao": "CKJ2",
      "iata": "",
      "name": "Rosenort Airport",
      "city": "Rosenort",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 779,
      "lat": 49.4530982971,
      "lon": -97.4225006104,
      "tz": "America\/Winnipeg"
  },
  "CKJ4": {
      "icao": "CKJ4",
      "iata": "",
      "name": "Hanley Airport",
      "city": "Hanley",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1925,
      "lat": 51.6241989136,
      "lon": -106.4420013428,
      "tz": "America\/Regina"
  },
  "CKJ7": {
      "icao": "CKJ7",
      "iata": "",
      "name": "Starbuck Airport",
      "city": "Starbuck",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 785,
      "lat": 49.7167015076,
      "lon": -97.6832962036,
      "tz": "America\/Winnipeg"
  },
  "CKJ8": {
      "icao": "CKJ8",
      "iata": "",
      "name": "Molson Lake Airport",
      "city": "Molson Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 750,
      "lat": 54.2580986023,
      "lon": -97.011100769,
      "tz": "America\/Winnipeg"
  },
  "CKJ9": {
      "icao": "CKJ9",
      "iata": "",
      "name": "Lemberg Airport",
      "city": "Lemberg",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2050,
      "lat": 50.7089004517,
      "lon": -103.1969985962,
      "tz": "America\/Regina"
  },
  "CKK2": {
      "icao": "CKK2",
      "iata": "",
      "name": "St. Brieux Airport",
      "city": "St. Brieux",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1780,
      "lat": 52.6500015259,
      "lon": -104.8669967651,
      "tz": "America\/Regina"
  },
  "CKK3": {
      "icao": "CKK3",
      "iata": "",
      "name": "Scobey Border Station Airport",
      "city": "Coronach",
      "state": "Montana",
      "country": "CA",
      "elevation": 2501,
      "lat": 48.9994659424,
      "lon": -105.3993377686,
      "tz": "America\/Denver"
  },
  "CKK4": {
      "icao": "CKK4",
      "iata": "",
      "name": "Estevan (South) Airport",
      "city": "Estevan",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1900,
      "lat": 49.038898468,
      "lon": -102.9759979248,
      "tz": "America\/Chicago"
  },
  "CKK5": {
      "icao": "CKK5",
      "iata": "",
      "name": "Eastend Airport",
      "city": "Eastend",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 3040,
      "lat": 49.5499992371,
      "lon": -108.8000030518,
      "tz": "America\/Swift_Current"
  },
  "CKK7": {
      "icao": "CKK7",
      "iata": "",
      "name": "Steinbach (South) Airport",
      "city": "Steinbach",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 888,
      "lat": 49.4939002991,
      "lon": -96.6988983154,
      "tz": "America\/Winnipeg"
  },
  "CKL2": {
      "icao": "CKL2",
      "iata": "",
      "name": "Selkirk Airport",
      "city": "Selkirk",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 747,
      "lat": 50.1721992493,
      "lon": -96.8722000122,
      "tz": "America\/Winnipeg"
  },
  "CKL3": {
      "icao": "CKL3",
      "iata": "WNN",
      "name": "Wunnumin Lake Airport",
      "city": "Wunnumin Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 819,
      "lat": 52.893901825,
      "lon": -89.2891998291,
      "tz": "America\/Rainy_River"
  },
  "CKL5": {
      "icao": "CKL5",
      "iata": "",
      "name": "Shoal Lake Airport",
      "city": "Shoal Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1836,
      "lat": 50.4575004578,
      "lon": -100.6090011597,
      "tz": "America\/Winnipeg"
  },
  "CKL6": {
      "icao": "CKL6",
      "iata": "",
      "name": "Little Bear Lake Airport",
      "city": "Little Bear Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2100,
      "lat": 54.2919006348,
      "lon": -104.6709976196,
      "tz": "America\/Regina"
  },
  "CKL9": {
      "icao": "CKL9",
      "iata": "",
      "name": "Regina Beach Airport",
      "city": "Regina Beach",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1850,
      "lat": 50.7527999878,
      "lon": -104.9789962769,
      "tz": "America\/Regina"
  },
  "CKM2": {
      "icao": "CKM2",
      "iata": "",
      "name": "Sioux Narrows Airport",
      "city": "Sioux Narrows",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1100,
      "lat": 49.3899993896,
      "lon": -93.995300293,
      "tz": "America\/Rainy_River"
  },
  "CKM4": {
      "icao": "CKM4",
      "iata": "",
      "name": "Jan Lake Airport",
      "city": "Jan Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1135,
      "lat": 54.8307991028,
      "lon": -102.7890014648,
      "tz": "America\/Regina"
  },
  "CKM6": {
      "icao": "CKM6",
      "iata": "",
      "name": "Easterville Airport",
      "city": "Easterville",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 850,
      "lat": 53.1166992188,
      "lon": -99.8000030518,
      "tz": "America\/Winnipeg"
  },
  "CKM8": {
      "icao": "CKM8",
      "iata": "",
      "name": "Opapimiskan Lake Airport",
      "city": "Opapimiskan Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1023,
      "lat": 52.6067008972,
      "lon": -90.3768997192,
      "tz": "America\/Rainy_River"
  },
  "CKN5": {
      "icao": "CKN5",
      "iata": "",
      "name": "Fillmore Airport",
      "city": "Fillmore",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2000,
      "lat": 49.8689002991,
      "lon": -103.4449996948,
      "tz": "America\/Regina"
  },
  "CKN8": {
      "icao": "CKN8",
      "iata": "",
      "name": "Nekweaga Bay Airport",
      "city": "Nekweaga Bay",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1320,
      "lat": 57.7425003052,
      "lon": -103.9449996948,
      "tz": "America\/Regina"
  },
  "CKP2": {
      "icao": "CKP2",
      "iata": "",
      "name": "Spring Valley (North) Airport",
      "city": "Spring Valley",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2200,
      "lat": 50.0600013733,
      "lon": -105.4020004272,
      "tz": "America\/Regina"
  },
  "CKQ2": {
      "icao": "CKQ2",
      "iata": "",
      "name": "Squaw Rapids Airport",
      "city": "Squaw Rapids",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1040,
      "lat": 53.6786003113,
      "lon": -103.3499984741,
      "tz": "America\/Regina"
  },
  "CKQ3": {
      "icao": "CKQ3",
      "iata": "YNO",
      "name": "North Spirit Lake Airport",
      "city": "North Spirit Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1082,
      "lat": 52.4900016785,
      "lon": -92.9710998535,
      "tz": "America\/Rainy_River"
  },
  "CKQ5": {
      "icao": "CKQ5",
      "iata": "",
      "name": "Lucky Lake Airport",
      "city": "Lucky Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2084,
      "lat": 50.9942016602,
      "lon": -107.1309967041,
      "tz": "America\/Swift_Current"
  },
  "CKQ6": {
      "icao": "CKQ6",
      "iata": "",
      "name": "Erickson Municipal Airport",
      "city": "Erickson",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 2114,
      "lat": 50.4994010925,
      "lon": -99.8977966309,
      "tz": "America\/Winnipeg"
  },
  "CKQ7": {
      "icao": "CKQ7",
      "iata": "",
      "name": "Vermilion Bay Airport",
      "city": "Vermilion Bay",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1284,
      "lat": 49.879699707,
      "lon": -93.4364013672,
      "tz": "America\/Rainy_River"
  },
  "CKQ8": {
      "icao": "CKQ8",
      "iata": "",
      "name": "McArthur River Airport",
      "city": "Mcarthur River",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1718,
      "lat": 57.7675018311,
      "lon": -105.0240020752,
      "tz": "America\/Regina"
  },
  "CKQ9": {
      "icao": "CKQ9",
      "iata": "",
      "name": "Pine Dock Airport",
      "city": "Pine Dock",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 750,
      "lat": 51.6199989319,
      "lon": -96.810798645,
      "tz": "America\/Winnipeg"
  },
  "CKR3": {
      "icao": "CKR3",
      "iata": "",
      "name": "Ste. Rose Du Lac Airport",
      "city": "Ste. Rose Du Lac",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 900,
      "lat": 51.0407981873,
      "lon": -99.495300293,
      "tz": "America\/Winnipeg"
  },
  "CKR4": {
      "icao": "CKR4",
      "iata": "",
      "name": "Lundar Airport",
      "city": "Lundar",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 830,
      "lat": 50.7033004761,
      "lon": -98.0567016602,
      "tz": "America\/Winnipeg"
  },
  "CKR7": {
      "icao": "CKR7",
      "iata": "",
      "name": "Virden (Gabrielle Farm) Airport",
      "city": "Virden",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1440,
      "lat": 49.7846984863,
      "lon": -100.9560012817,
      "tz": "America\/Winnipeg"
  },
  "CKR9": {
      "icao": "CKR9",
      "iata": "",
      "name": "Outlook Airport",
      "city": "Outlook",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1771,
      "lat": 51.4824981689,
      "lon": -107.0370025635,
      "tz": "America\/Swift_Current"
  },
  "CKS3": {
      "icao": "CKS3",
      "iata": "",
      "name": "Cudworth Airport",
      "city": "Cudworth",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1875,
      "lat": 52.4880981445,
      "lon": -105.763999939,
      "tz": "America\/Regina"
  },
  "CKS6": {
      "icao": "CKS6",
      "iata": "",
      "name": "Bryant Airport",
      "city": "Estevan",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1970,
      "lat": 49.4099998474,
      "lon": -103.1439971924,
      "tz": "America\/Regina"
  },
  "CKS7": {
      "icao": "CKS7",
      "iata": "",
      "name": "Wadena Airport",
      "city": "Wadena",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1740,
      "lat": 51.9294480759,
      "lon": -103.833761215,
      "tz": "America\/Regina"
  },
  "CKS8": {
      "icao": "CKS8",
      "iata": "",
      "name": "Cree Lake (Crystal Lodge) Airport",
      "city": "Cree Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1615,
      "lat": 57.4641990662,
      "lon": -106.7480010986,
      "tz": "America\/Regina"
  },
  "CKS9": {
      "icao": "CKS9",
      "iata": "",
      "name": "Shepherd's Landing Airport",
      "city": "Kincardine",
      "state": "Ontario",
      "country": "CA",
      "elevation": 675,
      "lat": 44.1175003052,
      "lon": -81.6983032227,
      "tz": "America\/Toronto"
  },
  "CKT5": {
      "icao": "CKT5",
      "iata": "",
      "name": "Hartney Airport",
      "city": "Hartney",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1450,
      "lat": 49.4500007629,
      "lon": -100.5500030518,
      "tz": "America\/Winnipeg"
  },
  "CKT7": {
      "icao": "CKT7",
      "iata": "",
      "name": "Wakaw Airport",
      "city": "Wakaw",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1660,
      "lat": 52.6490522523,
      "lon": -105.763063431,
      "tz": "America\/Regina"
  },
  "CKU2": {
      "icao": "CKU2",
      "iata": "",
      "name": "Treherne Airport",
      "city": "Treherne",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1200,
      "lat": 49.6310997009,
      "lon": -98.6663970947,
      "tz": "America\/Winnipeg"
  },
  "CKU4": {
      "icao": "CKU4",
      "iata": "",
      "name": "Cut Knife Airport",
      "city": "Cut Knife",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2100,
      "lat": 52.7332992554,
      "lon": -109.016998291,
      "tz": "America\/Swift_Current"
  },
  "CKU5": {
      "icao": "CKU5",
      "iata": "",
      "name": "Imperial Airport",
      "city": "Imperial",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1665,
      "lat": 51.3499984741,
      "lon": -105.4000015259,
      "tz": "America\/Regina"
  },
  "CKU6": {
      "icao": "CKU6",
      "iata": "",
      "name": "Grenfell Airport",
      "city": "Grenfell",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1964,
      "lat": 50.4192008972,
      "lon": -102.9349975586,
      "tz": "America\/Regina"
  },
  "CKU7": {
      "icao": "CKU7",
      "iata": "",
      "name": "Watrous Airport",
      "city": "Watrous",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1700,
      "lat": 51.68713736,
      "lon": -105.362319946,
      "tz": "America\/Regina"
  },
  "CKV2": {
      "icao": "CKV2",
      "iata": "",
      "name": "Kelvington Airport",
      "city": "Kelvington",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1860,
      "lat": 52.1333007813,
      "lon": -103.5350036621,
      "tz": "America\/Regina"
  },
  "CKV4": {
      "icao": "CKV4",
      "iata": "",
      "name": "North of Sixty Airport",
      "city": "Obre Lake",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 1195,
      "lat": 60.3163986206,
      "lon": -103.1289978027,
      "tz": "America\/Yellowknife"
  },
  "CKV6": {
      "icao": "CKV6",
      "iata": "",
      "name": "Churchbridge Airport",
      "city": "Churchbridge",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1745,
      "lat": 51.0167007446,
      "lon": -101.8170013428,
      "tz": "America\/Regina"
  },
  "CKV7": {
      "icao": "CKV7",
      "iata": "",
      "name": "Wawota Airport",
      "city": "Wawota",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2168,
      "lat": 49.8944823433,
      "lon": -102.03045845,
      "tz": "America\/Regina"
  },
  "CKW6": {
      "icao": "CKW6",
      "iata": "",
      "name": "Davin Lake Airport",
      "city": "Davin Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1200,
      "lat": 56.8833007813,
      "lon": -103.5830001831,
      "tz": "America\/Regina"
  },
  "CKX2": {
      "icao": "CKX2",
      "iata": "",
      "name": "Warren \/ Woodlands Airport",
      "city": "Warren",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 817,
      "lat": 50.1594009399,
      "lon": -97.5911026001,
      "tz": "America\/Winnipeg"
  },
  "CKX4": {
      "icao": "CKX4",
      "iata": "",
      "name": "Fisher Branch Airport",
      "city": "Fisher Branch",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 825,
      "lat": 51.0833015442,
      "lon": -97.4832992554,
      "tz": "America\/Winnipeg"
  },
  "CKX5": {
      "icao": "CKX5",
      "iata": "",
      "name": "Dinsmore Airport",
      "city": "Dinsmore",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1900,
      "lat": 51.3306007385,
      "lon": -107.43800354,
      "tz": "America\/Swift_Current"
  },
  "CKX8": {
      "icao": "CKX8",
      "iata": "",
      "name": "Big River Airport",
      "city": "Big River",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1592,
      "lat": 53.836101532,
      "lon": -107.0090026855,
      "tz": "America\/Swift_Current"
  },
  "CKY2": {
      "icao": "CKY2",
      "iata": "",
      "name": "Whitewood Airport",
      "city": "Whitewood",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2000,
      "lat": 50.3347783814,
      "lon": -102.274475098,
      "tz": "America\/Regina"
  },
  "CKY8": {
      "icao": "CKY8",
      "iata": "",
      "name": "Arkayla Springs Airport",
      "city": "Cochrane",
      "state": "Alberta",
      "country": "CA",
      "elevation": 4000,
      "lat": 51.20470047,
      "lon": -114.7740020752,
      "tz": "America\/Edmonton"
  },
  "CKZ2": {
      "icao": "CKZ2",
      "iata": "",
      "name": "Willow Bunch Airport",
      "city": "Willow Bunch",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2427,
      "lat": 49.4000015259,
      "lon": -105.6669998169,
      "tz": "America\/Regina"
  },
  "CKZ3": {
      "icao": "CKZ3",
      "iata": "",
      "name": "Elk Island Airport",
      "city": "Elk Island",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 625,
      "lat": 54.6713981628,
      "lon": -94.1453018188,
      "tz": "America\/Winnipeg"
  },
  "CKZ6": {
      "icao": "CKZ6",
      "iata": "",
      "name": "Louise Municipal Airport",
      "city": "Crystal City-Pilot Mound",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1525,
      "lat": 49.1472015381,
      "lon": -98.8807983398,
      "tz": "America\/Winnipeg"
  },
  "CKZ7": {
      "icao": "CKZ7",
      "iata": "",
      "name": "Winkler Airport",
      "city": "Winkler",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 850,
      "lat": 49.1669006348,
      "lon": -97.9203033447,
      "tz": "America\/Winnipeg"
  },
  "CL01": {
      "icao": "CL01",
      "iata": "",
      "name": "Eagle Field",
      "city": "Dos Palos",
      "state": "California",
      "country": "US",
      "elevation": 153,
      "lat": 36.8998985291,
      "lon": -120.6679992676,
      "tz": "America\/Los_Angeles"
  },
  "CL04": {
      "icao": "CL04",
      "iata": "",
      "name": "Sky Way Estates Airport",
      "city": "Elk Grove",
      "state": "California",
      "country": "US",
      "elevation": 92,
      "lat": 38.4107017517,
      "lon": -121.2350006104,
      "tz": "America\/Los_Angeles"
  },
  "CL11": {
      "icao": "CL11",
      "iata": "",
      "name": "Ferdun Ranch Airport",
      "city": "Lodi",
      "state": "California",
      "country": "US",
      "elevation": 64,
      "lat": 38.134601593,
      "lon": -121.2200012207,
      "tz": "America\/Los_Angeles"
  },
  "CL13": {
      "icao": "CL13",
      "iata": "",
      "name": "Brian Ranch Airport",
      "city": "Palmdale",
      "state": "California",
      "country": "US",
      "elevation": 3230,
      "lat": 34.5153007507,
      "lon": -117.7620010376,
      "tz": "America\/Los_Angeles"
  },
  "CL18": {
      "icao": "CL18",
      "iata": "",
      "name": "Danby Airstrip",
      "city": "Danby",
      "state": "California",
      "country": "US",
      "elevation": 1400,
      "lat": 34.5550003052,
      "lon": -115.3560028076,
      "tz": "America\/Los_Angeles"
  },
  "CL23": {
      "icao": "CL23",
      "iata": "",
      "name": "Jones\/Ag-Viation Airport",
      "city": "Biggs",
      "state": "California",
      "country": "US",
      "elevation": 109,
      "lat": 39.4595985413,
      "lon": -121.6959991455,
      "tz": "America\/Los_Angeles"
  },
  "CL24": {
      "icao": "CL24",
      "iata": "",
      "name": "Kindsvater Ranch Airport",
      "city": "Clovis",
      "state": "California",
      "country": "US",
      "elevation": 529,
      "lat": 36.849899292,
      "lon": -119.5100021362,
      "tz": "America\/Los_Angeles"
  },
  "CL25": {
      "icao": "CL25",
      "iata": "",
      "name": "7R Ranch Airport",
      "city": "Cuyama",
      "state": "California",
      "country": "US",
      "elevation": 3176,
      "lat": 34.9355010986,
      "lon": -119.452003479,
      "tz": "America\/Los_Angeles"
  },
  "CL29": {
      "icao": "CL29",
      "iata": "",
      "name": "Camino Airstrip",
      "city": "Goffs",
      "state": "California",
      "country": "US",
      "elevation": 2079,
      "lat": 34.8349990845,
      "lon": -114.9570007324,
      "tz": "America\/Los_Angeles"
  },
  "CL33": {
      "icao": "CL33",
      "iata": "",
      "name": "Pauma Valley Air Park",
      "city": "Pauma Valley",
      "state": "California",
      "country": "US",
      "elevation": 756,
      "lat": 33.3106002808,
      "lon": -116.9980010986,
      "tz": "America\/Los_Angeles"
  },
  "CL35": {
      "icao": "CL35",
      "iata": "",
      "name": "Warner Springs Gliderport Airport",
      "city": "Warner Springs",
      "state": "California",
      "country": "US",
      "elevation": 2885,
      "lat": 33.2845001221,
      "lon": -116.6750030518,
      "tz": "America\/Los_Angeles"
  },
  "CL36": {
      "icao": "CL36",
      "iata": "",
      "name": "Allan Ranch Flight Park Ultralightport",
      "city": "Windsor",
      "state": "California",
      "country": "US",
      "elevation": 160,
      "lat": 38.5694007874,
      "lon": -122.8040008545,
      "tz": "America\/Los_Angeles"
  },
  "CL46": {
      "icao": "CL46",
      "iata": "",
      "name": "Quail Lake Sky Park Airport",
      "city": "Gorman\/Lancaster",
      "state": "California",
      "country": "US",
      "elevation": 3370,
      "lat": 34.7677993774,
      "lon": -118.7320022583,
      "tz": "America\/Los_Angeles"
  },
  "CL56": {
      "icao": "CL56",
      "iata": "",
      "name": "Ranchaero Airport",
      "city": "Chico",
      "state": "California",
      "country": "US",
      "elevation": 173,
      "lat": 39.7196006775,
      "lon": -121.871002197,
      "tz": "America\/Los_Angeles"
  },
  "CL74": {
      "icao": "CL74",
      "iata": "",
      "name": "Skyotee Ranch Airport",
      "city": "Rosamond",
      "state": "California",
      "country": "US",
      "elevation": 2600,
      "lat": 34.8319015503,
      "lon": -118.4010009766,
      "tz": "America\/Los_Angeles"
  },
  "CL77": {
      "icao": "CL77",
      "iata": "",
      "name": "Bonny Doon Village Airport",
      "city": "Santa Cruz",
      "state": "California",
      "country": "US",
      "elevation": 2020,
      "lat": 37.0704994202,
      "lon": -122.1269989014,
      "tz": "America\/Los_Angeles"
  },
  "CL84": {
      "icao": "CL84",
      "iata": "",
      "name": "Ahlem Farms Airport",
      "city": "Hilmar",
      "state": "California",
      "country": "US",
      "elevation": 73,
      "lat": 37.3883018494,
      "lon": -120.9270019531,
      "tz": "America\/Los_Angeles"
  },
  "CL88": {
      "icao": "CL88",
      "iata": "",
      "name": "Wesinger Ranch Airport",
      "city": "Alturas",
      "state": "California",
      "country": "US",
      "elevation": 4300,
      "lat": 41.447101593,
      "lon": -120.5839996338,
      "tz": "America\/Los_Angeles"
  },
  "CL90": {
      "icao": "CL90",
      "iata": "",
      "name": "Butte Creek Hog Ranch Airport",
      "city": "Chico",
      "state": "California",
      "country": "US",
      "elevation": 200,
      "lat": 39.6888008118,
      "lon": -121.783996582,
      "tz": "America\/Los_Angeles"
  },
  "CLA2": {
      "icao": "CLA2",
      "iata": "",
      "name": "L'Assomption Airport",
      "city": "L'Assomption",
      "state": "Quebec",
      "country": "CA",
      "elevation": 52,
      "lat": 45.8231010437,
      "lon": -73.4606018066,
      "tz": "America\/Toronto"
  },
  "CLA4": {
      "icao": "CLA4",
      "iata": "",
      "name": "Holland Landing Airpark",
      "city": "Holland Landing",
      "state": "Ontario",
      "country": "CA",
      "elevation": 855,
      "lat": 44.0894012451,
      "lon": -79.4950027466,
      "tz": "America\/Toronto"
  },
  "CLA5": {
      "icao": "CLA5",
      "iata": "",
      "name": "Lethbridge \/ Anderson Field",
      "city": "Lethbridge",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3020,
      "lat": 49.6569444445,
      "lon": -112.772777778,
      "tz": "America\/Edmonton"
  },
  "CLA6": {
      "icao": "CLA6",
      "iata": "",
      "name": "Lancaster Airpark",
      "city": "Bainsville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 145,
      "lat": 45.2004013062,
      "lon": -74.361000061,
      "tz": "America\/Toronto"
  },
  "CLB2": {
      "icao": "CLB2",
      "iata": "",
      "name": "Plattsville (Lubitz Flying Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 980,
      "lat": 43.3058333,
      "lon": -80.5486111,
      "tz": "America\/Toronto"
  },
  "CLC2": {
      "icao": "CLC2",
      "iata": "",
      "name": "London \/ Chapeskie Field",
      "city": "London",
      "state": "Ontario",
      "country": "CA",
      "elevation": 930,
      "lat": 43.0682983398,
      "lon": -81.1256027222,
      "tz": "America\/Toronto"
  },
  "CLE4": {
      "icao": "CLE4",
      "iata": "",
      "name": "Lower East Pubnico (La Field) Airport",
      "city": "Lower East Pubnico",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 107,
      "lat": 43.6222000122,
      "lon": -65.7619018555,
      "tz": "America\/Halifax"
  },
  "CLG3": {
      "icao": "CLG3",
      "iata": "",
      "name": "Liege \/ CNRL Airport",
      "city": "Liege",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1728,
      "lat": 57.0018997192,
      "lon": -113.194000244,
      "tz": "America\/Edmonton"
  },
  "CLG7": {
      "icao": "CLG7",
      "iata": "",
      "name": "Fort McMurray \/ Legend Airfield",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2061,
      "lat": 57.19694444,
      "lon": -112.895556,
      "tz": "America\/Edmonton"
  },
  "CLJ3": {
      "icao": "CLJ3",
      "iata": "",
      "name": "Lethbridge \/ J3 Airfield",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2910,
      "lat": 49.7416214711,
      "lon": -112.738330364,
      "tz": "America\/Edmonton"
  },
  "CLK3": {
      "icao": "CLK3",
      "iata": "",
      "name": "Lucknow Airpark",
      "city": "Lucknow",
      "state": "Ontario",
      "country": "CA",
      "elevation": 960,
      "lat": 43.9678001404,
      "lon": -81.4933013916,
      "tz": "America\/Toronto"
  },
  "CLM2": {
      "icao": "CLM2",
      "iata": "",
      "name": "Leamington Airport",
      "city": "Leamington",
      "state": "Ontario",
      "country": "CA",
      "elevation": 576,
      "lat": 42.0250015259,
      "lon": -82.5250015259,
      "tz": "America\/Toronto"
  },
  "CLN4": {
      "icao": "CLN4",
      "iata": "",
      "name": "Beaverlodge \/ Clanachan Field",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2694,
      "lat": 55.2344927355,
      "lon": -119.841592312,
      "tz": "America\/Edmonton"
  },
  "CLS3": {
      "icao": "CLS3",
      "iata": "",
      "name": "Fort McMurray \/ South Liege Airfield",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1707,
      "lat": 56.8323528324,
      "lon": -113.098840714,
      "tz": "America\/Edmonton"
  },
  "CLV2": {
      "icao": "CLV2",
      "iata": "",
      "name": "Stayner (Clearview Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 870,
      "lat": 44.404637674,
      "lon": -80.1479780674,
      "tz": "America\/Toronto"
  },
  "CLW2": {
      "icao": "CLW2",
      "iata": "",
      "name": "Lewvan (Farr Strip) Airport",
      "city": "Lewvan",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1904,
      "lat": 49.9850006104,
      "lon": -104.1149978638,
      "tz": "America\/Regina"
  },
  "CLW3": {
      "icao": "CLW3",
      "iata": "",
      "name": "Laurel \/ Whittington",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1550,
      "lat": 43.9791679382,
      "lon": -80.1772232056,
      "tz": "America\/Toronto"
  },
  "CMA2": {
      "icao": "CMA2",
      "iata": "",
      "name": "Mattawa Airport",
      "city": "Mattawa",
      "state": "Ontario",
      "country": "CA",
      "elevation": 600,
      "lat": 46.2997016907,
      "lon": -78.7478027344,
      "tz": "America\/Toronto"
  },
  "CMB2": {
      "icao": "CMB2",
      "iata": "",
      "name": "Meadowbank Airport",
      "city": "Meadowbank Gold Mine",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 475,
      "lat": 65.0246615457,
      "lon": -96.0686588287,
      "tz": "America\/Rankin_Inlet"
  },
  "CMF2": {
      "icao": "CMF2",
      "iata": "",
      "name": "Edmonton \/ Calmar (Maplelane Farm)",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2520,
      "lat": 53.1846546068,
      "lon": -113.738236427,
      "tz": "America\/Edmonton"
  },
  "CMF3": {
      "icao": "CMF3",
      "iata": "",
      "name": "Lethbridge (Mercer Field)",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3085,
      "lat": 49.5548112877,
      "lon": -112.561540604,
      "tz": "America\/Edmonton"
  },
  "CML2": {
      "icao": "CML2",
      "iata": "",
      "name": "Quamichan Lake (Raven Field) Airport",
      "city": "Quamichan Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 130,
      "lat": 48.8119010925,
      "lon": -123.6510009766,
      "tz": "America\/Vancouver"
  },
  "CMN4": {
      "icao": "CMN4",
      "iata": "",
      "name": "Minto",
      "city": "",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2969,
      "lat": 62.6047222222,
      "lon": -137.221944444,
      "tz": "America\/Whitehorse"
  },
  "CMN5": {
      "icao": "CMN5",
      "iata": "",
      "name": "Manic-5",
      "city": "",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1332,
      "lat": 50.6577319114,
      "lon": -68.8310194016,
      "tz": "America\/Toronto"
  },
  "CMN6": {
      "icao": "CMN6",
      "iata": "",
      "name": "Edmonton\/Morinville (Mike's Field)",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2302,
      "lat": 53.8369444444,
      "lon": -113.563333333,
      "tz": "America\/Edmonton"
  },
  "CMW3": {
      "icao": "CMW3",
      "iata": "",
      "name": "Matawatchan",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 990,
      "lat": 45.1643383253,
      "lon": -77.0955276489,
      "tz": "America\/Toronto"
  },
  "CN12": {
      "icao": "CN12",
      "iata": "",
      "name": "Williams Gliderport",
      "city": "Williams",
      "state": "California",
      "country": "US",
      "elevation": 68,
      "lat": 39.1635017395,
      "lon": -122.1320037842,
      "tz": "America\/Los_Angeles"
  },
  "CN13": {
      "icao": "CN13",
      "iata": "",
      "name": "Borges - Clarksburg Airport",
      "city": "Clarksburg",
      "state": "California",
      "country": "US",
      "elevation": 12,
      "lat": 38.4431991577,
      "lon": -121.509002686,
      "tz": "America\/Los_Angeles"
  },
  "CN15": {
      "icao": "CN15",
      "iata": "",
      "name": "Vineyard Oaks Farm Airport",
      "city": "Lemore",
      "state": "California",
      "country": "US",
      "elevation": 210,
      "lat": 36.3692016602,
      "lon": -119.7860031128,
      "tz": "America\/Los_Angeles"
  },
  "CN19": {
      "icao": "CN19",
      "iata": "",
      "name": "Las Serpientes Airport",
      "city": "Knightsen",
      "state": "California",
      "country": "US",
      "elevation": 10,
      "lat": 37.9331016541,
      "lon": -121.6129989624,
      "tz": "America\/Los_Angeles"
  },
  "CN23": {
      "icao": "CN23",
      "iata": "",
      "name": "Harvard Airport",
      "city": "Yermo",
      "state": "California",
      "country": "US",
      "elevation": 1825,
      "lat": 34.9617004395,
      "lon": -116.6750030518,
      "tz": "America\/Los_Angeles"
  },
  "CN24": {
      "icao": "CN24",
      "iata": "",
      "name": "Flying R Airport",
      "city": "Galt",
      "state": "California",
      "country": "US",
      "elevation": 70,
      "lat": 38.283000946,
      "lon": -121.2549972534,
      "tz": "America\/Los_Angeles"
  },
  "CN29": {
      "icao": "CN29",
      "iata": "",
      "name": "J-B Airport",
      "city": "Farmington",
      "state": "California",
      "country": "US",
      "elevation": 103,
      "lat": 37.9351997375,
      "lon": -121.0149993896,
      "tz": "America\/Los_Angeles"
  },
  "CN37": {
      "icao": "CN37",
      "iata": "",
      "name": "Kelso Valley Airport",
      "city": "Mojave",
      "state": "California",
      "country": "US",
      "elevation": 4047,
      "lat": 35.38130188,
      "lon": -118.2310028,
      "tz": "America\/Los_Angeles"
  },
  "CN38": {
      "icao": "CN38",
      "iata": "",
      "name": "Flying B Ranch Airport",
      "city": "Franklin",
      "state": "California",
      "country": "US",
      "elevation": 19,
      "lat": 38.3413009644,
      "lon": -121.43699646,
      "tz": "America\/Los_Angeles"
  },
  "CN42": {
      "icao": "CN42",
      "iata": "",
      "name": "Double Creek Ranch Airport",
      "city": "Manton",
      "state": "California",
      "country": "US",
      "elevation": 2030,
      "lat": 40.4457015991,
      "lon": -121.8820037842,
      "tz": "America\/Los_Angeles"
  },
  "CN44": {
      "icao": "CN44",
      "iata": "",
      "name": "Flying Bull Airport",
      "city": "Modesto",
      "state": "California",
      "country": "US",
      "elevation": 40,
      "lat": 37.6204986572,
      "lon": -121.1630020142,
      "tz": "America\/Los_Angeles"
  },
  "CN98": {
      "icao": "CN98",
      "iata": "",
      "name": "Walter'S Camp Airport",
      "city": "Palo Verde",
      "state": "California",
      "country": "US",
      "elevation": 309,
      "lat": 33.245556,
      "lon": -114.710278,
      "tz": "America\/Los_Angeles"
  },
  "CNA2": {
      "icao": "CNA2",
      "iata": "",
      "name": "Highgate Airport",
      "city": "Highgate",
      "state": "Ontario",
      "country": "CA",
      "elevation": 710,
      "lat": 42.5544013977,
      "lon": -81.8031005859,
      "tz": "America\/Toronto"
  },
  "CNA3": {
      "icao": "CNA3",
      "iata": "",
      "name": "Springwater Barrie Airpark",
      "city": "Springwater",
      "state": "Ontario",
      "country": "CA",
      "elevation": 963,
      "lat": 44.4063987732,
      "lon": -79.7316970825,
      "tz": "America\/Toronto"
  },
  "CNA4": {
      "icao": "CNA4",
      "iata": "",
      "name": "Emsdale Airport",
      "city": "Emsdale",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1150,
      "lat": 45.5499992371,
      "lon": -79.3499984741,
      "tz": "America\/Nipigon"
  },
  "CNA9": {
      "icao": "CNA9",
      "iata": "",
      "name": "Tomvale Airport",
      "city": "Plevna",
      "state": "Ontario",
      "country": "CA",
      "elevation": 890,
      "lat": 44.9160995483,
      "lon": -76.935798645,
      "tz": "America\/Toronto"
  },
  "CNC3": {
      "icao": "CNC3",
      "iata": "",
      "name": "Brampton Airport",
      "city": "Brampton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 935,
      "lat": 43.7602996826,
      "lon": -79.875,
      "tz": "America\/Toronto"
  },
  "CNC4": {
      "icao": "CNC4",
      "iata": "",
      "name": "Guelph Airport",
      "city": "Guelph",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1100,
      "lat": 43.5638999939,
      "lon": -80.1960983276,
      "tz": "America\/Toronto"
  },
  "CND4": {
      "icao": "CND4",
      "iata": "",
      "name": "Stanhope Municipal Airport",
      "city": "Haliburton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1066,
      "lat": 45.1108333333,
      "lon": -78.64,
      "tz": "America\/Toronto"
  },
  "CNE3": {
      "icao": "CNE3",
      "iata": "XBE",
      "name": "Bearskin Lake Airport",
      "city": "Bearskin Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 800,
      "lat": 53.9655990601,
      "lon": -91.0271987915,
      "tz": "America\/Rainy_River"
  },
  "CNE4": {
      "icao": "CNE4",
      "iata": "",
      "name": "Iroquois Falls Airport",
      "city": "Iroquois Falls",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1008,
      "lat": 48.7416992188,
      "lon": -80.793296814,
      "tz": "America\/Nipigon"
  },
  "CNE9": {
      "icao": "CNE9",
      "iata": "",
      "name": "Essex Airport",
      "city": "Essex",
      "state": "Ontario",
      "country": "CA",
      "elevation": 620,
      "lat": 42.0957984924,
      "lon": -82.8794021606,
      "tz": "America\/Toronto"
  },
  "CNF3": {
      "icao": "CNF3",
      "iata": "",
      "name": "Pendleton Airport",
      "city": "Pendleton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 260,
      "lat": 45.4860992432,
      "lon": -75.0960998535,
      "tz": "America\/Toronto"
  },
  "CNF4": {
      "icao": "CNF4",
      "iata": "",
      "name": "Lindsay Airport",
      "city": "Lindsay",
      "state": "Ontario",
      "country": "CA",
      "elevation": 882,
      "lat": 44.3647003174,
      "lon": -78.7838973999,
      "tz": "America\/Toronto"
  },
  "CNF8": {
      "icao": "CNF8",
      "iata": "",
      "name": "Dwight Airport",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1100,
      "lat": 45.3144454956,
      "lon": -78.9725036621,
      "tz": "America\/Toronto"
  },
  "CNF9": {
      "icao": "CNF9",
      "iata": "",
      "name": "Niagara South Airport",
      "city": "Niagara Falls",
      "state": "Ontario",
      "country": "CA",
      "elevation": 576,
      "lat": 42.9994010925,
      "lon": -79.0635986328,
      "tz": "America\/Toronto"
  },
  "CNH2": {
      "icao": "CNH2",
      "iata": "",
      "name": "Natuashish Airport",
      "city": "Natuashish",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 30,
      "lat": 55.913898468,
      "lon": -61.1843986511,
      "tz": "America\/Goose_Bay"
  },
  "CNJ3": {
      "icao": "CNJ3",
      "iata": "",
      "name": "Fort Erie Airport",
      "city": "Fort Erie",
      "state": "Ontario",
      "country": "CA",
      "elevation": 625,
      "lat": 42.9172121601,
      "lon": -78.957452774,
      "tz": "America\/Toronto"
  },
  "CNJ4": {
      "icao": "CNJ4",
      "iata": "",
      "name": "Orillia Airport",
      "city": "Orillia",
      "state": "Ontario",
      "country": "CA",
      "elevation": 725,
      "lat": 44.6776557839,
      "lon": -79.3102169037,
      "tz": "America\/Toronto"
  },
  "CNK4": {
      "icao": "CNK4",
      "iata": "",
      "name": "Parry Sound Area Municipal Airport",
      "city": "Parry Sound",
      "state": "Ontario",
      "country": "CA",
      "elevation": 832,
      "lat": 45.2574996948,
      "lon": -79.8296966553,
      "tz": "America\/Nipigon"
  },
  "CNL2": {
      "icao": "CNL2",
      "iata": "",
      "name": "Fort McMurray \/ North Liege Airfield",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2236,
      "lat": 57.1360995926,
      "lon": -113.290071487,
      "tz": "America\/Edmonton"
  },
  "CNL3": {
      "icao": "CNL3",
      "iata": "",
      "name": "Brockville - Thousand Islands Regional Tackaberry Airport",
      "city": "Brockville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 402,
      "lat": 44.6394004822,
      "lon": -75.7502975464,
      "tz": "America\/Toronto"
  },
  "CNL4": {
      "icao": "CNL4",
      "iata": "",
      "name": "Port Elgin Airport",
      "city": "Port Elgin",
      "state": "Ontario",
      "country": "CA",
      "elevation": 650,
      "lat": 44.4152984619,
      "lon": -81.4143981934,
      "tz": "America\/Toronto"
  },
  "CNL7": {
      "icao": "CNL7",
      "iata": "",
      "name": "Nobel \/ Lumsden Air Park",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 720,
      "lat": 45.4083333,
      "lon": -80.0780555,
      "tz": "America\/Nipigon"
  },
  "CNL8": {
      "icao": "CNL8",
      "iata": "",
      "name": "Wyevale (Boker Field) Airport",
      "city": "Wyevale",
      "state": "Ontario",
      "country": "CA",
      "elevation": 705,
      "lat": 44.6553001404,
      "lon": -79.8788986206,
      "tz": "America\/Toronto"
  },
  "CNL9": {
      "icao": "CNL9",
      "iata": "",
      "name": "Nueltin Lake Airport",
      "city": "Nueltin Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 971,
      "lat": 59.7080993652,
      "lon": -100.1269989014,
      "tz": "America\/Winnipeg"
  },
  "CNM2": {
      "icao": "CNM2",
      "iata": "",
      "name": "Melbourne Airport",
      "city": "Melbourne",
      "state": "Ontario",
      "country": "CA",
      "elevation": 735,
      "lat": 42.8241996765,
      "lon": -81.5485992432,
      "tz": "America\/Toronto"
  },
  "CNM5": {
      "icao": "CNM5",
      "iata": "KIF",
      "name": "Kingfisher Lake Airport",
      "city": "Kingfisher Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 866,
      "lat": 53.0125007629,
      "lon": -89.8553009033,
      "tz": "America\/Rainy_River"
  },
  "CNN3": {
      "icao": "CNN3",
      "iata": "",
      "name": "Shelburne \/ Fisher Field",
      "city": "Shelburne",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1675,
      "lat": 44.0278929436,
      "lon": -80.2059459686,
      "tz": "America\/Toronto"
  },
  "CNN8": {
      "icao": "CNN8",
      "iata": "",
      "name": "Gananoque Airport",
      "city": "Gananoque",
      "state": "Ontario",
      "country": "CA",
      "elevation": 395,
      "lat": 44.4019012451,
      "lon": -76.2442016602,
      "tz": "America\/Toronto"
  },
  "CNP3": {
      "icao": "CNP3",
      "iata": "",
      "name": "Arnprior Airport",
      "city": "Arnprior",
      "state": "Ontario",
      "country": "CA",
      "elevation": 358,
      "lat": 45.4136009216,
      "lon": -76.3657989502,
      "tz": "America\/Toronto"
  },
  "CNP6": {
      "icao": "CNP6",
      "iata": "",
      "name": "Nampa \/ Hockey",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2050,
      "lat": 55.914900566,
      "lon": -117.136155367,
      "tz": "America\/Edmonton"
  },
  "CNP7": {
      "icao": "CNP7",
      "iata": "",
      "name": "Iroquois Airport",
      "city": "Iroquois",
      "state": "Ontario",
      "country": "CA",
      "elevation": 246,
      "lat": 44.8421783447,
      "lon": -75.3114089966,
      "tz": "America\/New_York"
  },
  "CNP8": {
      "icao": "CNP8",
      "iata": "",
      "name": "Greenbank Airport",
      "city": "Greenbank",
      "state": "Ontario",
      "country": "CA",
      "elevation": 975,
      "lat": 44.1336968141,
      "lon": -79.0130281448,
      "tz": "America\/Toronto"
  },
  "CNQ3": {
      "icao": "CNQ3",
      "iata": "",
      "name": "Welland \/ Niagara Central Airport",
      "city": "Welland",
      "state": "Ontario",
      "country": "CA",
      "elevation": 585,
      "lat": 42.9791984558,
      "lon": -79.3246994019,
      "tz": "America\/Toronto"
  },
  "CNR4": {
      "icao": "CNR4",
      "iata": "",
      "name": "Tobermory Airport",
      "city": "Tobermory",
      "state": "Ontario",
      "country": "CA",
      "elevation": 700,
      "lat": 45.2214680842,
      "lon": -81.6275596619,
      "tz": "America\/Toronto"
  },
  "CNR6": {
      "icao": "CNR6",
      "iata": "",
      "name": "Carleton Place Airport",
      "city": "Carleton Place",
      "state": "Ontario",
      "country": "CA",
      "elevation": 450,
      "lat": 45.1039009094,
      "lon": -76.123298645,
      "tz": "America\/Toronto"
  },
  "CNR9": {
      "icao": "CNR9",
      "iata": "",
      "name": "Arnstein Airport",
      "city": "Arnstein",
      "state": "Ontario",
      "country": "CA",
      "elevation": 745,
      "lat": 45.9255981445,
      "lon": -79.9278030396,
      "tz": "America\/Nipigon"
  },
  "CNS4": {
      "icao": "CNS4",
      "iata": "",
      "name": "Alexandria Aerodrome",
      "city": "Alexandria",
      "state": "Ontario",
      "country": "CA",
      "elevation": 260,
      "lat": 45.3306999207,
      "lon": -74.6257247925,
      "tz": "America\/Toronto"
  },
  "CNS6": {
      "icao": "CNS6",
      "iata": "",
      "name": "Straffordville Airport",
      "city": "Straffordville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 732,
      "lat": 42.7383003235,
      "lon": -80.8167037964,
      "tz": "America\/Toronto"
  },
  "CNS7": {
      "icao": "CNS7",
      "iata": "",
      "name": "Kincardine Airport",
      "city": "Kincardine",
      "state": "Ontario",
      "country": "CA",
      "elevation": 772,
      "lat": 44.2014007568,
      "lon": -81.6066970825,
      "tz": "America\/Toronto"
  },
  "CNS8": {
      "icao": "CNS8",
      "iata": "",
      "name": "Morrisburg Airport",
      "city": "Morrisburg",
      "state": "Ontario",
      "country": "CA",
      "elevation": 254,
      "lat": 44.9469985962,
      "lon": -75.0774002075,
      "tz": "America\/New_York"
  },
  "CNT3": {
      "icao": "CNT3",
      "iata": "YOG",
      "name": "Ogoki Post Airport",
      "city": "Ogoki Post",
      "state": "Ontario",
      "country": "CA",
      "elevation": 594,
      "lat": 51.6585998535,
      "lon": -85.9017028809,
      "tz": "America\/Nipigon"
  },
  "CNT6": {
      "icao": "CNT6",
      "iata": "",
      "name": "Elmira Airport",
      "city": "Elmira",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1250,
      "lat": 43.5847015381,
      "lon": -80.6031036377,
      "tz": "America\/Toronto"
  },
  "CNT7": {
      "icao": "CNT7",
      "iata": "",
      "name": "Picton Airport",
      "city": "Picton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 465,
      "lat": 43.989200592,
      "lon": -77.1391983032,
      "tz": "America\/Toronto"
  },
  "CNU4": {
      "icao": "CNU4",
      "iata": "",
      "name": "Belleville Airport",
      "city": "Belleville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 320,
      "lat": 44.192199707,
      "lon": -77.3094024658,
      "tz": "America\/Toronto"
  },
  "CNU8": {
      "icao": "CNU8",
      "iata": "",
      "name": "Markham Airport",
      "city": "Toronto",
      "state": "Ontario",
      "country": "CA",
      "elevation": 807,
      "lat": 43.935798645,
      "lon": -79.2621994019,
      "tz": "America\/Toronto"
  },
  "CNV4": {
      "icao": "CNV4",
      "iata": "",
      "name": "Hawkesbury Airport",
      "city": "Hawkesbury",
      "state": "Ontario",
      "country": "CA",
      "elevation": 167,
      "lat": 45.6166992188,
      "lon": -74.6500015259,
      "tz": "America\/Toronto"
  },
  "CNV8": {
      "icao": "CNV8",
      "iata": "",
      "name": "Edenvale Aerodrome",
      "city": "Edenvale",
      "state": "Ontario",
      "country": "CA",
      "elevation": 718,
      "lat": 44.4411010742,
      "lon": -79.9627990723,
      "tz": "America\/Toronto"
  },
  "CNV9": {
      "icao": "CNV9",
      "iata": "",
      "name": "Quebec \/ Neuville Airport",
      "city": "Neuville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 314,
      "lat": 46.7214759694,
      "lon": -71.5854978561,
      "tz": "America\/Toronto"
  },
  "CNW2": {
      "icao": "CNW2",
      "iata": "",
      "name": "Windermere Airport",
      "city": "Windermere",
      "state": "Ontario",
      "country": "CA",
      "elevation": 746,
      "lat": 45.1624984741,
      "lon": -79.5353012085,
      "tz": "America\/Toronto"
  },
  "CNW3": {
      "icao": "CNW3",
      "iata": "",
      "name": "Bancroft Airport",
      "city": "Bancroft",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1085,
      "lat": 45.0731010437,
      "lon": -77.8805999756,
      "tz": "America\/Toronto"
  },
  "CNX3": {
      "icao": "CNX3",
      "iata": "",
      "name": "Carey Lake Airport",
      "city": "Carey Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 837,
      "lat": 49.75,
      "lon": -84.0333023071,
      "tz": "America\/Nipigon"
  },
  "CNX8": {
      "icao": "CNX8",
      "iata": "",
      "name": "Nixon Airport",
      "city": "Nixon",
      "state": "Ontario",
      "country": "CA",
      "elevation": 780,
      "lat": 42.8474998474,
      "lon": -80.3953018188,
      "tz": "America\/Toronto"
  },
  "CNY3": {
      "icao": "CNY3",
      "iata": "",
      "name": "Collingwood Airport",
      "city": "Collingwood",
      "state": "Ontario",
      "country": "CA",
      "elevation": 730,
      "lat": 44.4491996765,
      "lon": -80.1583023071,
      "tz": "America\/Toronto"
  },
  "CNY4": {
      "icao": "CNY4",
      "iata": "",
      "name": "Alliston Airport",
      "city": "Alliston",
      "state": "Ontario",
      "country": "CA",
      "elevation": 720,
      "lat": 44.1833000183,
      "lon": -79.837097168,
      "tz": "America\/Toronto"
  },
  "CNZ4": {
      "icao": "CNZ4",
      "iata": "",
      "name": "Barry's Bay \/ Madawaska Valley Airpark",
      "city": "Barry's Bay",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1000,
      "lat": 45.5047227536,
      "lon": -77.6440930367,
      "tz": "America\/Toronto"
  },
  "CNZ8": {
      "icao": "CNZ8",
      "iata": "",
      "name": "Grimsby Airpark",
      "city": "Grimsby",
      "state": "Ontario",
      "country": "CA",
      "elevation": 640,
      "lat": 43.1599998474,
      "lon": -79.5991973877,
      "tz": "America\/Toronto"
  },
  "CO00": {
      "icao": "CO00",
      "iata": "",
      "name": "Flagler Aerial Spraying Inc Airport",
      "city": "Flagler",
      "state": "Colorado",
      "country": "US",
      "elevation": 4945,
      "lat": 39.2799987793,
      "lon": -103.0670013428,
      "tz": "America\/Denver"
  },
  "CO02": {
      "icao": "CO02",
      "iata": "",
      "name": "Harrington Ranch Airport",
      "city": "Bennet",
      "state": "Colorado",
      "country": "US",
      "elevation": 5975,
      "lat": 39.629699707,
      "lon": -104.5189971924,
      "tz": "America\/Denver"
  },
  "CO03": {
      "icao": "CO03",
      "iata": "",
      "name": "Aspen Gliderport",
      "city": "Aspen",
      "state": "Colorado",
      "country": "US",
      "elevation": 8440,
      "lat": 39.2599983215,
      "lon": -106.9120025635,
      "tz": "America\/Denver"
  },
  "CO06": {
      "icao": "CO06",
      "iata": "",
      "name": "Wirth Field",
      "city": "New Raymer",
      "state": "Colorado",
      "country": "US",
      "elevation": 4881,
      "lat": 40.5135993958,
      "lon": -103.9120025635,
      "tz": "America\/Denver"
  },
  "CO07": {
      "icao": "CO07",
      "iata": "",
      "name": "Athanasiou Valley Airport",
      "city": "Blackhawk",
      "state": "Colorado",
      "country": "US",
      "elevation": 8900,
      "lat": 39.8541984558,
      "lon": -105.4710006714,
      "tz": "America\/Denver"
  },
  "CO09": {
      "icao": "CO09",
      "iata": "",
      "name": "Youtsey Airport",
      "city": "Boone",
      "state": "Colorado",
      "country": "US",
      "elevation": 4500,
      "lat": 38.2667007446,
      "lon": -104.283996582,
      "tz": "America\/Denver"
  },
  "CO10": {
      "icao": "CO10",
      "iata": "",
      "name": "Lemons Private Strip",
      "city": "Boulder",
      "state": "Colorado",
      "country": "US",
      "elevation": 5230,
      "lat": 39.9947013855,
      "lon": -105.2239990234,
      "tz": "America\/Denver"
  },
  "CO11": {
      "icao": "CO11",
      "iata": "",
      "name": "Granite Mountain Lodge Airport",
      "city": "Salida",
      "state": "Colorado",
      "country": "US",
      "elevation": 9000,
      "lat": 38.4160995483,
      "lon": -106.0579986572,
      "tz": "America\/Denver"
  },
  "CO12": {
      "icao": "CO12",
      "iata": "",
      "name": "Brighton Van-Aire Estates Airport",
      "city": "Brighton",
      "state": "Colorado",
      "country": "US",
      "elevation": 5055,
      "lat": 39.983001709,
      "lon": -104.705001831,
      "tz": "America\/Denver"
  },
  "CO13": {
      "icao": "CO13",
      "iata": "",
      "name": "Heckendorf Ranches Airport",
      "city": "Brighton",
      "state": "Colorado",
      "country": "US",
      "elevation": 5060,
      "lat": 39.9632987976,
      "lon": -104.7490005493,
      "tz": "America\/Denver"
  },
  "CO14": {
      "icao": "CO14",
      "iata": "",
      "name": "Reasoner Airport",
      "city": "Brighton",
      "state": "Colorado",
      "country": "US",
      "elevation": 5100,
      "lat": 39.9166984558,
      "lon": -104.8050003052,
      "tz": "America\/Denver"
  },
  "CO15": {
      "icao": "CO15",
      "iata": "",
      "name": "Kelly Air Park",
      "city": "Elbert",
      "state": "Colorado",
      "country": "US",
      "elevation": 7040,
      "lat": 39.2247009277,
      "lon": -104.63999939,
      "tz": "America\/Denver"
  },
  "CO17": {
      "icao": "CO17",
      "iata": "",
      "name": "Sky Haven Airport",
      "city": "Byers",
      "state": "Colorado",
      "country": "US",
      "elevation": 5215,
      "lat": 39.7136001587,
      "lon": -104.1940002441,
      "tz": "America\/Denver"
  },
  "CO18": {
      "icao": "CO18",
      "iata": "",
      "name": "Chaparral Airport",
      "city": "Kiowa",
      "state": "Colorado",
      "country": "US",
      "elevation": 5930,
      "lat": 39.4640007019,
      "lon": -104.2580032349,
      "tz": "America\/Denver"
  },
  "CO19": {
      "icao": "CO19",
      "iata": "",
      "name": "Tezak Airport",
      "city": "Canon City",
      "state": "Colorado",
      "country": "US",
      "elevation": 6280,
      "lat": 38.4108009338,
      "lon": -105.6039962769,
      "tz": "America\/Denver"
  },
  "CO20": {
      "icao": "CO20",
      "iata": "",
      "name": "Flying W Ranch Airport",
      "city": "Cedaredge",
      "state": "Colorado",
      "country": "US",
      "elevation": 6885,
      "lat": 38.8624992371,
      "lon": -107.8209991455,
      "tz": "America\/Denver"
  },
  "CO22": {
      "icao": "CO22",
      "iata": "",
      "name": "Hildebrandt Airport",
      "city": "Bethune",
      "state": "Colorado",
      "country": "US",
      "elevation": 4300,
      "lat": 39.2791671753,
      "lon": -102.4749984741,
      "tz": "America\/Denver"
  },
  "CO25": {
      "icao": "CO25",
      "iata": "",
      "name": "Metrogro Farm Airport",
      "city": "Agate",
      "state": "Colorado",
      "country": "US",
      "elevation": 5240,
      "lat": 39.4961013794,
      "lon": -103.7239990234,
      "tz": "America\/Denver"
  },
  "CO27": {
      "icao": "CO27",
      "iata": "",
      "name": "Tanner Field",
      "city": "Cortez",
      "state": "Colorado",
      "country": "US",
      "elevation": 6640,
      "lat": 37.4678001404,
      "lon": -108.6650009155,
      "tz": "America\/Denver"
  },
  "CO38": {
      "icao": "CO38",
      "iata": "",
      "name": "Comanche Airfield Llc Airport",
      "city": "Strasburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 5350,
      "lat": 39.7472000122,
      "lon": -104.31300354,
      "tz": "America\/Denver"
  },
  "CO42": {
      "icao": "CO42",
      "iata": "",
      "name": "Circle 8 Ranch Airport",
      "city": "Elizabeth",
      "state": "Colorado",
      "country": "US",
      "elevation": 6650,
      "lat": 39.3347015381,
      "lon": -104.5459976196,
      "tz": "America\/Denver"
  },
  "CO43": {
      "icao": "CO43",
      "iata": "",
      "name": "Pinyon Airport",
      "city": "Glade Park",
      "state": "Colorado",
      "country": "US",
      "elevation": 6980,
      "lat": 39.0027999878,
      "lon": -108.7099990845,
      "tz": "America\/Denver"
  },
  "CO47": {
      "icao": "CO47",
      "iata": "",
      "name": "Gypsum Creek Ranch Airport",
      "city": "Gypsum",
      "state": "Colorado",
      "country": "US",
      "elevation": 6720,
      "lat": 39.5999984741,
      "lon": -106.9739990234,
      "tz": "America\/Denver"
  },
  "CO48": {
      "icao": "CO48",
      "iata": "",
      "name": "Crop Air Inc Airport",
      "city": "Eaton",
      "state": "Colorado",
      "country": "US",
      "elevation": 4800,
      "lat": 40.5125007629,
      "lon": -104.6520004272,
      "tz": "America\/Denver"
  },
  "CO49": {
      "icao": "CO49",
      "iata": "",
      "name": "Flying Lazy D Ranch Airport",
      "city": "Elbert",
      "state": "Colorado",
      "country": "US",
      "elevation": 6910,
      "lat": 39.1624984741,
      "lon": -104.5350036621,
      "tz": "America\/Denver"
  },
  "CO52": {
      "icao": "CO52",
      "iata": "",
      "name": "Marshdale STOLport",
      "city": "Evergreen",
      "state": "Colorado",
      "country": "US",
      "elevation": 7700,
      "lat": 39.5833015442,
      "lon": -105.3030014038,
      "tz": "America\/Denver"
  },
  "CO53": {
      "icao": "CO53",
      "iata": "",
      "name": "Yankee Field",
      "city": "Fort Collins",
      "state": "Colorado",
      "country": "US",
      "elevation": 5050,
      "lat": 40.6347007751,
      "lon": -104.9909973145,
      "tz": "America\/Denver"
  },
  "CO54": {
      "icao": "CO54",
      "iata": "",
      "name": "G W Flanders Ranch Strip",
      "city": "Falcon",
      "state": "Colorado",
      "country": "US",
      "elevation": 7260,
      "lat": 38.9832992554,
      "lon": -104.6340026855,
      "tz": "America\/Denver"
  },
  "CO55": {
      "icao": "CO55",
      "iata": "",
      "name": "Christman Field",
      "city": "Fort Collins",
      "state": "Colorado",
      "country": "US",
      "elevation": 5160,
      "lat": 40.5971984863,
      "lon": -105.1439971924,
      "tz": "America\/Denver"
  },
  "CO56": {
      "icao": "CO56",
      "iata": "",
      "name": "Jjs Airport",
      "city": "Keenesburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 5075,
      "lat": 39.964099884,
      "lon": -104.4629974365,
      "tz": "America\/Denver"
  },
  "CO58": {
      "icao": "CO58",
      "iata": "",
      "name": "Wings N Things Airpark & Museum Airport",
      "city": "Firestone",
      "state": "Colorado",
      "country": "US",
      "elevation": 5000,
      "lat": 40.1058006287,
      "lon": -104.9550018311,
      "tz": "America\/Denver"
  },
  "CO59": {
      "icao": "CO59",
      "iata": "",
      "name": "Hay Fever Farm Airport",
      "city": "Galeton",
      "state": "Colorado",
      "country": "US",
      "elevation": 4840,
      "lat": 40.5457992554,
      "lon": -104.5640029907,
      "tz": "America\/Denver"
  },
  "CO60": {
      "icao": "CO60",
      "iata": "",
      "name": "Horseshoe Landings Airport",
      "city": "Keenesburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 4800,
      "lat": 40.1082992554,
      "lon": -104.4309997559,
      "tz": "America\/Denver"
  },
  "CO61": {
      "icao": "CO61",
      "iata": "",
      "name": "Golden Field (Yellow Hat) Airport",
      "city": "Gardner",
      "state": "Colorado",
      "country": "US",
      "elevation": 7710,
      "lat": 37.8422012329,
      "lon": -105.2139968872,
      "tz": "America\/Denver"
  },
  "CO65": {
      "icao": "CO65",
      "iata": "",
      "name": "Geary Ranch Airport",
      "city": "Westcliffe",
      "state": "Colorado",
      "country": "US",
      "elevation": 8300,
      "lat": 38.0457992554,
      "lon": -105.4710006714,
      "tz": "America\/Denver"
  },
  "CO70": {
      "icao": "CO70",
      "iata": "",
      "name": "Uhrich Airport",
      "city": "Greeley",
      "state": "Colorado",
      "country": "US",
      "elevation": 4715,
      "lat": 40.4604988098,
      "lon": -104.6460037231,
      "tz": "America\/Denver"
  },
  "CO73": {
      "icao": "CO73",
      "iata": "",
      "name": "Air-Sprayers Nr 2 Airport",
      "city": "Hartman",
      "state": "Colorado",
      "country": "US",
      "elevation": 3610,
      "lat": 38.0999984741,
      "lon": -102.2170028687,
      "tz": "America\/Denver"
  },
  "CO76": {
      "icao": "CO76",
      "iata": "",
      "name": "Eden Ranch Airport",
      "city": "Hotchkiss",
      "state": "Colorado",
      "country": "US",
      "elevation": 6150,
      "lat": 38.8363990784,
      "lon": -107.8659973145,
      "tz": "America\/Denver"
  },
  "CO77": {
      "icao": "CO77",
      "iata": "",
      "name": "Horth Strip",
      "city": "Hudson",
      "state": "Colorado",
      "country": "US",
      "elevation": 5128,
      "lat": 39.9785995483,
      "lon": -104.59400177,
      "tz": "America\/Denver"
  },
  "CO80": {
      "icao": "CO80",
      "iata": "",
      "name": "Fowler Airport",
      "city": "Fowler",
      "state": "Colorado",
      "country": "US",
      "elevation": 4428,
      "lat": 38.0724983215,
      "lon": -104.0459976196,
      "tz": "America\/Denver"
  },
  "CO81": {
      "icao": "CO81",
      "iata": "",
      "name": "Hill Airport",
      "city": "Joes",
      "state": "Colorado",
      "country": "US",
      "elevation": 4345,
      "lat": 39.6208000183,
      "lon": -102.7440032959,
      "tz": "America\/Denver"
  },
  "CO82": {
      "icao": "CO82",
      "iata": "",
      "name": "Land Airport",
      "city": "Keenesburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 5000,
      "lat": 40.0957984924,
      "lon": -104.5889968872,
      "tz": "America\/Denver"
  },
  "CO84": {
      "icao": "CO84",
      "iata": "",
      "name": "Idlers Field",
      "city": "Kirk",
      "state": "Colorado",
      "country": "US",
      "elevation": 4159,
      "lat": 39.6082992554,
      "lon": -102.5410003662,
      "tz": "America\/Denver"
  },
  "CO86": {
      "icao": "CO86",
      "iata": "",
      "name": "Granby Sports Park Ultralightport",
      "city": "Granby",
      "state": "Colorado",
      "country": "US",
      "elevation": 8110,
      "lat": 40.0485992432,
      "lon": -105.93800354,
      "tz": "America\/Denver"
  },
  "CO89": {
      "icao": "CO89",
      "iata": "",
      "name": "Barber Field",
      "city": "Delta",
      "state": "Colorado",
      "country": "US",
      "elevation": 5050,
      "lat": 38.7728004456,
      "lon": -108.0439987183,
      "tz": "America\/Denver"
  },
  "CO90": {
      "icao": "CO90",
      "iata": "",
      "name": "Usaf Academy Bullseye Aux Airstrip",
      "city": "Ellicott",
      "state": "Colorado",
      "country": "US",
      "elevation": 6036,
      "lat": 38.7583007813,
      "lon": -104.3069992065,
      "tz": "America\/Denver"
  },
  "CO92": {
      "icao": "CO92",
      "iata": "",
      "name": "Frasier Ranch Airport",
      "city": "Last Chance",
      "state": "Colorado",
      "country": "US",
      "elevation": 5000,
      "lat": 39.6417007446,
      "lon": -103.6449966431,
      "tz": "America\/Denver"
  },
  "CO93": {
      "icao": "CO93",
      "iata": "",
      "name": "Perry Park Airport",
      "city": "Larkspur",
      "state": "Colorado",
      "country": "US",
      "elevation": 6700,
      "lat": 39.25,
      "lon": -104.8909988403,
      "tz": "America\/Denver"
  },
  "CO94": {
      "icao": "CO94",
      "iata": "",
      "name": "Decker Farms Airport",
      "city": "Lindon",
      "state": "Colorado",
      "country": "US",
      "elevation": 5050,
      "lat": 39.7069015503,
      "lon": -103.3519973755,
      "tz": "America\/Denver"
  },
  "CO95": {
      "icao": "CO95",
      "iata": "",
      "name": "Wilson Ranch Airport",
      "city": "Telluride",
      "state": "Colorado",
      "country": "US",
      "elevation": 9580,
      "lat": 38.0527992249,
      "lon": -107.9800033569,
      "tz": "America\/Denver"
  },
  "CO96": {
      "icao": "CO96",
      "iata": "",
      "name": "Reed Hollow Ranch Airport",
      "city": "Franktown",
      "state": "Colorado",
      "country": "US",
      "elevation": 6200,
      "lat": 39.3717002869,
      "lon": -104.7429962158,
      "tz": "America\/Denver"
  },
  "CO97": {
      "icao": "CO97",
      "iata": "",
      "name": "Comanche Springs Ranch Airport",
      "city": "Strasburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 5435,
      "lat": 39.7207984924,
      "lon": -104.3229980469,
      "tz": "America\/Denver"
  },
  "CO98": {
      "icao": "CO98",
      "iata": "",
      "name": "Bowen Farms Nr 1 Airport",
      "city": "Littleton",
      "state": "Colorado",
      "country": "US",
      "elevation": 5450,
      "lat": 39.5499992371,
      "lon": -105.033996582,
      "tz": "America\/Denver"
  },
  "COB4": {
      "icao": "COB4",
      "iata": "",
      "name": "Orangeville \/ Brundle Field",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1630,
      "lat": 43.8745094139,
      "lon": -80.1798963547,
      "tz": "America\/Toronto"
  },
  "COL2": {
      "icao": "COL2",
      "iata": "",
      "name": "Orangeville \/ Laurel Airstrip",
      "city": "Orangeville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1620,
      "lat": 43.9169006348,
      "lon": -80.2058029175,
      "tz": "America\/Toronto"
  },
  "CPA4": {
      "icao": "CPA4",
      "iata": "",
      "name": "Simcoe (Dennison Field) Airport",
      "city": "Simcoe",
      "state": "Ontario",
      "country": "CA",
      "elevation": 722,
      "lat": 42.8193524977,
      "lon": -80.265212059,
      "tz": "America\/Toronto"
  },
  "CPB5": {
      "icao": "CPB5",
      "iata": "",
      "name": "Pilot Butte Airport",
      "city": "Pilot Butte",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2000,
      "lat": 50.461101532,
      "lon": -104.4309997559,
      "tz": "America\/Regina"
  },
  "CPB8": {
      "icao": "CPB8",
      "iata": "",
      "name": "Paramount Bistcho Airport",
      "city": "Paramount Bistcho",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1969,
      "lat": 59.6380996704,
      "lon": -118.3330001831,
      "tz": "America\/Edmonton"
  },
  "CPB9": {
      "icao": "CPB9",
      "iata": "",
      "name": "Baldwin Airport",
      "city": "Baldwin",
      "state": "Ontario",
      "country": "CA",
      "elevation": 750,
      "lat": 44.26720047,
      "lon": -79.3405990601,
      "tz": "America\/Toronto"
  },
  "CPC3": {
      "icao": "CPC3",
      "iata": "",
      "name": "Arthur (Walter's Field)",
      "city": "Arthur East",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1550,
      "lat": 43.840578507,
      "lon": -80.4428815842,
      "tz": "America\/Toronto"
  },
  "CPC6": {
      "icao": "CPC6",
      "iata": "",
      "name": "Teeswater (Thompson Field) Airport",
      "city": "Teeswater",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1000,
      "lat": 43.948299408,
      "lon": -81.2718963623,
      "tz": "America\/Toronto"
  },
  "CPD2": {
      "icao": "CPD2",
      "iata": "",
      "name": "Ethel Airport",
      "city": "Ethel",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1170,
      "lat": 43.7449989319,
      "lon": -81.1766967773,
      "tz": "America\/Toronto"
  },
  "CPD4": {
      "icao": "CPD4",
      "iata": "",
      "name": "Brussels (Armstrong Field) Airport",
      "city": "Brussels",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1100,
      "lat": 43.75,
      "lon": -81.2332992554,
      "tz": "America\/Toronto"
  },
  "CPD8": {
      "icao": "CPD8",
      "iata": "",
      "name": "Hawkesbury (Windover Field) Airport",
      "city": "Hawkesbury",
      "state": "Ontario",
      "country": "CA",
      "elevation": 165,
      "lat": 45.5643997192,
      "lon": -74.8097000122,
      "tz": "America\/Toronto"
  },
  "CPE3": {
      "icao": "CPE3",
      "iata": "",
      "name": "Elk Lake Airport",
      "city": "Elk Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 950,
      "lat": 47.7285995483,
      "lon": -80.3158035278,
      "tz": "America\/Nipigon"
  },
  "CPE4": {
      "icao": "CPE4",
      "iata": "",
      "name": "Cambridge \/ Reid's Field",
      "city": "Cambridge",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1050,
      "lat": 43.4427306923,
      "lon": -80.2325105667,
      "tz": "America\/Toronto"
  },
  "CPE5": {
      "icao": "CPE5",
      "iata": "",
      "name": "Port Colborne Airport",
      "city": "Port Colborne",
      "state": "Ontario",
      "country": "CA",
      "elevation": 600,
      "lat": 42.8774986267,
      "lon": -79.3527984619,
      "tz": "America\/Toronto"
  },
  "CPE6": {
      "icao": "CPE6",
      "iata": "",
      "name": "South River\/Sundridge Airport & Float Plane Base",
      "city": "South River - Sundridge",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1160,
      "lat": 45.8210983276,
      "lon": -79.3233032227,
      "tz": "America\/Nipigon"
  },
  "CPF2": {
      "icao": "CPF2",
      "iata": "YEB",
      "name": "Bar River Airport",
      "city": "Bar River",
      "state": "Ontario",
      "country": "CA",
      "elevation": 591,
      "lat": 46.42029953,
      "lon": -84.0922012329,
      "tz": "America\/Nipigon"
  },
  "CPF4": {
      "icao": "CPF4",
      "iata": "",
      "name": "Bruce McPhail Memorial Airport",
      "city": "Cobden",
      "state": "Ontario",
      "country": "CA",
      "elevation": 500,
      "lat": 45.5950012207,
      "lon": -76.8332977295,
      "tz": "America\/Toronto"
  },
  "CPF6": {
      "icao": "CPF6",
      "iata": "",
      "name": "Stoney Creek Airport",
      "city": "Stoney Creek",
      "state": "Ontario",
      "country": "CA",
      "elevation": 680,
      "lat": 43.1696925259,
      "lon": -79.7094154358,
      "tz": "America\/Toronto"
  },
  "CPF7": {
      "icao": "CPF7",
      "iata": "",
      "name": "Southampton Airport",
      "city": "Southampton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 685,
      "lat": 44.4939002991,
      "lon": -81.3352966309,
      "tz": "America\/Toronto"
  },
  "CPG4": {
      "icao": "CPG4",
      "iata": "",
      "name": "Elmira (East) Airport",
      "city": "Elmira",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1150,
      "lat": 43.5918998718,
      "lon": -80.5121994019,
      "tz": "America\/Toronto"
  },
  "CPG5": {
      "icao": "CPG5",
      "iata": "",
      "name": "Hawkesbury (East) Airport",
      "city": "Hawkesbury",
      "state": "Ontario",
      "country": "CA",
      "elevation": 200,
      "lat": 45.5828018188,
      "lon": -74.5488967896,
      "tz": "America\/Toronto"
  },
  "CPG6": {
      "icao": "CPG6",
      "iata": "",
      "name": "Port Elgin (Pryde Field) Airport",
      "city": "Port Elgin",
      "state": "Ontario",
      "country": "CA",
      "elevation": 661,
      "lat": 44.4589004517,
      "lon": -81.379699707,
      "tz": "America\/Toronto"
  },
  "CPG7": {
      "icao": "CPG7",
      "iata": "",
      "name": "Fergus (Juergensen Field) Airport",
      "city": "Fergus",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1450,
      "lat": 43.7350006104,
      "lon": -80.4471969604,
      "tz": "America\/Toronto"
  },
  "CPH2": {
      "icao": "CPH2",
      "iata": "",
      "name": "Rolph Airport",
      "city": "Deep River",
      "state": "Ontario",
      "country": "CA",
      "elevation": 490,
      "lat": 46.1166992188,
      "lon": -77.5333023071,
      "tz": "America\/Toronto"
  },
  "CPH3": {
      "icao": "CPH3",
      "iata": "",
      "name": "Port Hope (Peter's Field)",
      "city": "Port Hope",
      "state": "Ontario",
      "country": "CA",
      "elevation": 560,
      "lat": 44.0248843879,
      "lon": -78.4269762039,
      "tz": "America\/Toronto"
  },
  "CPH9": {
      "icao": "CPH9",
      "iata": "",
      "name": "Fordwich Airport",
      "city": "Fordwich",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1250,
      "lat": 43.8880996704,
      "lon": -80.995300293,
      "tz": "America\/Toronto"
  },
  "CPJ5": {
      "icao": "CPJ5",
      "iata": "",
      "name": "Stirling Airport",
      "city": "Stirling",
      "state": "Ontario",
      "country": "CA",
      "elevation": 625,
      "lat": 44.2414016724,
      "lon": -77.5616989136,
      "tz": "America\/Toronto"
  },
  "CPK2": {
      "icao": "CPK2",
      "iata": "",
      "name": "Strathroy (Blue Yonder) Airport",
      "city": "Strathroy",
      "state": "Ontario",
      "country": "CA",
      "elevation": 780,
      "lat": 42.965801239,
      "lon": -81.5916976929,
      "tz": "America\/Toronto"
  },
  "CPK9": {
      "icao": "CPK9",
      "iata": "",
      "name": "Arthur (Peskett Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1555,
      "lat": 43.7715431648,
      "lon": -80.4835224152,
      "tz": "America\/Toronto"
  },
  "CPL3": {
      "icao": "CPL3",
      "iata": "",
      "name": "Kars \/ Rideau Valley Air Park",
      "city": "Kars",
      "state": "Ontario",
      "country": "CA",
      "elevation": 286,
      "lat": 45.1030620653,
      "lon": -75.6281661987,
      "tz": "America\/Toronto"
  },
  "CPL4": {
      "icao": "CPL4",
      "iata": "",
      "name": "Grand Bend Airport",
      "city": "Grand Bend",
      "state": "Ontario",
      "country": "CA",
      "elevation": 642,
      "lat": 43.2868995667,
      "lon": -81.7142028809,
      "tz": "America\/Toronto"
  },
  "CPL5": {
      "icao": "CPL5",
      "iata": "",
      "name": "Thessalon Municipal Airport",
      "city": "Thessalon",
      "state": "Ontario",
      "country": "CA",
      "elevation": 750,
      "lat": 46.3166999817,
      "lon": -83.5333023071,
      "tz": "America\/Nipigon"
  },
  "CPM3": {
      "icao": "CPM3",
      "iata": "",
      "name": "POURVOIRIE MIRAGE AERODROME",
      "city": "",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1300,
      "lat": 53.8025,
      "lon": -72.841389,
      "tz": "America\/Toronto"
  },
  "CPM5": {
      "icao": "CPM5",
      "iata": "",
      "name": "Volk Airport",
      "city": "Tottenham",
      "state": "Ontario",
      "country": "CA",
      "elevation": 980,
      "lat": 43.9913325082,
      "lon": -79.7811698914,
      "tz": "America\/Toronto"
  },
  "CPM7": {
      "icao": "CPM7",
      "iata": "",
      "name": "Bradford Airport",
      "city": "Bradford",
      "state": "Ontario",
      "country": "CA",
      "elevation": 973,
      "lat": 44.136100769,
      "lon": -79.6288986206,
      "tz": "America\/Toronto"
  },
  "CPN2": {
      "icao": "CPN2",
      "iata": "",
      "name": "Pontiac Airpark",
      "city": "Pontiac",
      "state": "Quebec",
      "country": "CA",
      "elevation": 216,
      "lat": 45.5297264228,
      "lon": -76.1673545837,
      "tz": "America\/Toronto"
  },
  "CPN5": {
      "icao": "CPN5",
      "iata": "",
      "name": "Listowel Airport",
      "city": "Listowel",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1232,
      "lat": 43.7428016663,
      "lon": -80.9914016724,
      "tz": "America\/Toronto"
  },
  "CPP6": {
      "icao": "CPP6",
      "iata": "",
      "name": "York Airport",
      "city": "York",
      "state": "Ontario",
      "country": "CA",
      "elevation": 670,
      "lat": 43.0374984741,
      "lon": -79.8525009155,
      "tz": "America\/Toronto"
  },
  "CPQ4": {
      "icao": "CPQ4",
      "iata": "",
      "name": "Lefroy Airport",
      "city": "Lefroy",
      "state": "Ontario",
      "country": "CA",
      "elevation": 724,
      "lat": 44.2919095924,
      "lon": -79.5472812653,
      "tz": "America\/Toronto"
  },
  "CPR2": {
      "icao": "CPR2",
      "iata": "",
      "name": "Ottawa \/ Embrun Airport",
      "city": "Embrun",
      "state": "Ontario",
      "country": "CA",
      "elevation": 230,
      "lat": 45.2411003113,
      "lon": -75.2985992432,
      "tz": "America\/Toronto"
  },
  "CPR3": {
      "icao": "CPR3",
      "iata": "",
      "name": "Palmerston Airport",
      "city": "Palmerston",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1360,
      "lat": 43.8535995483,
      "lon": -80.7802963257,
      "tz": "America\/Toronto"
  },
  "CPR5": {
      "icao": "CPR5",
      "iata": "",
      "name": "Woodstock Airport",
      "city": "Woodstock",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1040,
      "lat": 43.1082677518,
      "lon": -80.8218669891,
      "tz": "America\/Toronto"
  },
  "CPR7": {
      "icao": "CPR7",
      "iata": "",
      "name": "Wingham \/ Richard W. LeVan Airport",
      "city": "Wingham",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1075,
      "lat": 43.8675003052,
      "lon": -81.2985992432,
      "tz": "America\/Toronto"
  },
  "CPR9": {
      "icao": "CPR9",
      "iata": "",
      "name": "Fergus (Royland Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1400,
      "lat": 43.7417549969,
      "lon": -80.3862011433,
      "tz": "America\/Toronto"
  },
  "CPS2": {
      "icao": "CPS2",
      "iata": "",
      "name": "Elmhirst's Resort Airport",
      "city": "Keene",
      "state": "Ontario",
      "country": "CA",
      "elevation": 650,
      "lat": 44.251470223,
      "lon": -78.1072568893,
      "tz": "America\/Toronto"
  },
  "CPS4": {
      "icao": "CPS4",
      "iata": "",
      "name": "Lucan Airport",
      "city": "Lucan",
      "state": "Ontario",
      "country": "CA",
      "elevation": 960,
      "lat": 43.1632995605,
      "lon": -81.4124984741,
      "tz": "America\/Toronto"
  },
  "CPS5": {
      "icao": "CPS5",
      "iata": "",
      "name": "Miminiska Airport",
      "city": "Miminiska",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1000,
      "lat": 51.6044006348,
      "lon": -88.5821990967,
      "tz": "America\/Nipigon"
  },
  "CPS7": {
      "icao": "CPS7",
      "iata": "",
      "name": "Orton \/ Smith Field",
      "city": "Orton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1450,
      "lat": 43.7753857192,
      "lon": -80.2280688286,
      "tz": "America\/Toronto"
  },
  "CPT2": {
      "icao": "CPT2",
      "iata": "",
      "name": "Killarney Airport",
      "city": "Killarney",
      "state": "Ontario",
      "country": "CA",
      "elevation": 608,
      "lat": 45.9775009155,
      "lon": -81.4946975708,
      "tz": "America\/Toronto"
  },
  "CPT3": {
      "icao": "CPT3",
      "iata": "",
      "name": "Rockton Airport",
      "city": "Rockton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 846,
      "lat": 43.3222007751,
      "lon": -80.176399231,
      "tz": "America\/Toronto"
  },
  "CPU3": {
      "icao": "CPU3",
      "iata": "",
      "name": "Rodney (New Glasgow) Airport",
      "city": "Rodney",
      "state": "Ontario",
      "country": "CA",
      "elevation": 670,
      "lat": 42.5313987732,
      "lon": -81.6069030762,
      "tz": "America\/Toronto"
  },
  "CPU6": {
      "icao": "CPU6",
      "iata": "",
      "name": "Tyendinaga Mohawk Airport",
      "city": "Tyendinaga",
      "state": "Ontario",
      "country": "CA",
      "elevation": 260,
      "lat": 44.1847000122,
      "lon": -77.1078033447,
      "tz": "America\/Toronto"
  },
  "CPV2": {
      "icao": "CPV2",
      "iata": "",
      "name": "Orangeville \/ Murray Wesley Kot Field",
      "city": "Orangeville",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1550,
      "lat": 43.9585990906,
      "lon": -80.1549987793,
      "tz": "America\/Toronto"
  },
  "CPV3": {
      "icao": "CPV3",
      "iata": "",
      "name": "St. Joseph Island Airport",
      "city": "St. Joseph Island",
      "state": "Ontario",
      "country": "CA",
      "elevation": 600,
      "lat": 46.2832984924,
      "lon": -83.9499969482,
      "tz": "America\/Nipigon"
  },
  "CPV4": {
      "icao": "CPV4",
      "iata": "",
      "name": "Mansfield Airport",
      "city": "Mansfield",
      "state": "Ontario",
      "country": "CA",
      "elevation": 995,
      "lat": 44.1439218832,
      "lon": -80.0136208534,
      "tz": "America\/Toronto"
  },
  "CPV7": {
      "icao": "CPV7",
      "iata": "YHP",
      "name": "Poplar Hill Airport",
      "city": "Poplar Hill",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1095,
      "lat": 52.1133003235,
      "lon": -94.2555999756,
      "tz": "America\/Rainy_River"
  },
  "CPV8": {
      "icao": "CPV8",
      "iata": "",
      "name": "Keewaywin Airport",
      "city": "Keewaywin",
      "state": "Ontario",
      "country": "CA",
      "elevation": 988,
      "lat": 52.9911003113,
      "lon": -92.8364028931,
      "tz": "America\/Rainy_River"
  },
  "CPY9": {
      "icao": "CPY9",
      "iata": "",
      "name": "Fergus (Holyoake Airfield)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1380,
      "lat": 43.728611111,
      "lon": -80.2830555556,
      "tz": "America\/Toronto"
  },
  "CPZ3": {
      "icao": "CPZ3",
      "iata": "",
      "name": "Trenton \/ Mountain View Airport",
      "city": "Mountain View",
      "state": "Ontario",
      "country": "CA",
      "elevation": 362,
      "lat": 44.0694007874,
      "lon": -77.3380966187,
      "tz": "America\/Toronto"
  },
  "CRB2": {
      "icao": "CRB2",
      "iata": "",
      "name": "Cottam Airport",
      "city": "Cottam",
      "state": "Ontario",
      "country": "CA",
      "elevation": 635,
      "lat": 42.1399993896,
      "lon": -82.6528015137,
      "tz": "America\/Toronto"
  },
  "CRB4": {
      "icao": "CRB4",
      "iata": "",
      "name": "Riviere Bonnard Airport",
      "city": "Riviere Bonnard",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1585,
      "lat": 50.7043991089,
      "lon": -71.1624984741,
      "tz": "America\/Toronto"
  },
  "CRB5": {
      "icao": "CRB5",
      "iata": "",
      "name": "Riviere Bell Airport",
      "city": "Riviere Bell",
      "state": "Quebec",
      "country": "CA",
      "elevation": 89,
      "lat": 49.0788993835,
      "lon": -62.2369003296,
      "tz": "America\/Toronto"
  },
  "CRD4": {
      "icao": "CRD4",
      "iata": "",
      "name": "Red Deer \/ South 40 Airstrip",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3040,
      "lat": 52.261944,
      "lon": -113.951389,
      "tz": "America\/Edmonton"
  },
  "CRD5": {
      "icao": "CRD5",
      "iata": "",
      "name": "Red Deer \/ Truant Airfield",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3100,
      "lat": 52.2870428404,
      "lon": -113.913180828,
      "tz": "America\/Edmonton"
  },
  "CRE2": {
      "icao": "CRE2",
      "iata": "",
      "name": "Edzo Airport",
      "city": "Rae",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 587,
      "lat": 62.7667007446,
      "lon": -116.0839996338,
      "tz": "America\/Yellowknife"
  },
  "CRE3": {
      "icao": "CRE3",
      "iata": "",
      "name": "Curries (Rand Private Airfield)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1060,
      "lat": 43.0643730519,
      "lon": -80.6983566284,
      "tz": "America\/Toronto"
  },
  "CRF3": {
      "icao": "CRF3",
      "iata": "",
      "name": "Edmonton \/ Villeneuve (Rose Field)",
      "city": "Villeneuve",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2190,
      "lat": 53.645555556,
      "lon": -113.802777778,
      "tz": "America\/Edmonton"
  },
  "CRF4": {
      "icao": "CRF4",
      "iata": "",
      "name": "Calgary \/ Okotoks (Rowland Field)",
      "city": "Calgary",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3800,
      "lat": 50.764722223,
      "lon": -113.958055556,
      "tz": "America\/Edmonton"
  },
  "CRJ2": {
      "icao": "CRJ2",
      "iata": "",
      "name": "Stoney Point (Trepanier)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 630,
      "lat": 42.2888870239,
      "lon": -82.5983352661,
      "tz": "America\/Toronto"
  },
  "CRL2": {
      "icao": "CRL2",
      "iata": "",
      "name": "Westport \/ Rideau Lakes Airport",
      "city": "Westport",
      "state": "Ontario",
      "country": "CA",
      "elevation": 500,
      "lat": 44.6666651766,
      "lon": -76.3966673613,
      "tz": "America\/Toronto"
  },
  "CRL4": {
      "icao": "CRL4",
      "iata": "",
      "name": "Kirby Lake Airport",
      "city": "Kirby Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2260,
      "lat": 55.3555984497,
      "lon": -110.6370010376,
      "tz": "America\/Edmonton"
  },
  "CRL7": {
      "icao": "CRL7",
      "iata": "",
      "name": "Reindeer Lake \/ Lindbergh Lodge Airstrip",
      "city": "",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1128,
      "lat": 57.28805555,
      "lon": -102.525,
      "tz": "America\/Regina"
  },
  "CRM2": {
      "icao": "CRM2",
      "iata": "",
      "name": "Riding Mountain Airport",
      "city": "Riding Mountain",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1016,
      "lat": 50.573600769,
      "lon": -99.3613967896,
      "tz": "America\/Winnipeg"
  },
  "CRML": {
      "icao": "CRML",
      "iata": "",
      "name": "Stoney Point (Le Cunff) Airport",
      "city": "Stoney Point",
      "state": "Ontario",
      "country": "CA",
      "elevation": 625,
      "lat": 42.2956008911,
      "lon": -82.5350036621,
      "tz": "America\/Toronto"
  },
  "CRP2": {
      "icao": "CRP2",
      "iata": "",
      "name": "R.M. of Pipestone Airport",
      "city": "Reston",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1488,
      "lat": 49.5806007385,
      "lon": -101.0530014038,
      "tz": "America\/Winnipeg"
  },
  "CRS3": {
      "icao": "CRS3",
      "iata": "",
      "name": "Calgary \/ Christiansen Field",
      "city": "Calgary",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3820,
      "lat": 50.7994796471,
      "lon": -114.051260948,
      "tz": "America\/Edmonton"
  },
  "CRS7": {
      "icao": "CRS7",
      "iata": "",
      "name": "Matoush Airport",
      "city": "",
      "state": "Quebec",
      "country": "CA",
      "elevation": 2608,
      "lat": 51.905,
      "lon": -72.12388888,
      "tz": "America\/Toronto"
  },
  "CRT2": {
      "icao": "CRT2",
      "iata": "",
      "name": "Riviere Temiscamie (Air Roberval Ltee) Airport",
      "city": "",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1300,
      "lat": 51.0092455225,
      "lon": -72.9835939407,
      "tz": "America\/Toronto"
  },
  "CSA2": {
      "icao": "CSA2",
      "iata": "",
      "name": "Lac Agile (Mascouche) Airport",
      "city": "Lac Agile",
      "state": "Quebec",
      "country": "CA",
      "elevation": 160,
      "lat": 45.7919006348,
      "lon": -73.6380996704,
      "tz": "America\/Toronto"
  },
  "CSB2": {
      "icao": "CSB2",
      "iata": "",
      "name": "Sable Island Landing Strip",
      "city": "Sable Island",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 0,
      "lat": 43.9303535212,
      "lon": -59.9597740173,
      "tz": "America\/Halifax"
  },
  "CSB3": {
      "icao": "CSB3",
      "iata": "",
      "name": "St-Mathieu-De-Beloeil Airport",
      "city": "St-Mathieu-De-Beloeil",
      "state": "Quebec",
      "country": "CA",
      "elevation": 47,
      "lat": 45.5900001526,
      "lon": -73.2386016846,
      "tz": "America\/Toronto"
  },
  "CSB5": {
      "icao": "CSB5",
      "iata": "",
      "name": "Shediac Bridge Airport",
      "city": "Shediac Bridge",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 25,
      "lat": 46.254699707,
      "lon": -64.5766983032,
      "tz": "America\/Moncton"
  },
  "CSC3": {
      "icao": "CSC3",
      "iata": "",
      "name": "Drummondville Airport",
      "city": "Drummondville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 365,
      "lat": 45.8452987671,
      "lon": -72.3946990967,
      "tz": "America\/Toronto"
  },
  "CSC5": {
      "icao": "CSC5",
      "iata": "",
      "name": "Lac Etchemin Airport",
      "city": "Lac Etchemin",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1500,
      "lat": 46.4071998596,
      "lon": -70.5014038086,
      "tz": "America\/Toronto"
  },
  "CSC9": {
      "icao": "CSC9",
      "iata": "",
      "name": "Sudbury \/ Coniston Airport",
      "city": "Sudbury",
      "state": "Ontario",
      "country": "CA",
      "elevation": 886,
      "lat": 46.4822006226,
      "lon": -80.8339004517,
      "tz": "America\/Toronto"
  },
  "CSD3": {
      "icao": "CSD3",
      "iata": "",
      "name": "Valleyfield Airport",
      "city": "Valleyfield",
      "state": "Quebec",
      "country": "CA",
      "elevation": 155,
      "lat": 45.2083015442,
      "lon": -74.1414031982,
      "tz": "America\/Toronto"
  },
  "CSD4": {
      "icao": "CSD4",
      "iata": "",
      "name": "Mont Laurier Airport",
      "city": "Mont-Laurier",
      "state": "Quebec",
      "country": "CA",
      "elevation": 815,
      "lat": 46.5643997192,
      "lon": -75.577796936,
      "tz": "America\/Toronto"
  },
  "CSE3": {
      "icao": "CSE3",
      "iata": "",
      "name": "Lourdes-De-Joliette Airport",
      "city": "Lourdes-De-Joliette",
      "state": "Quebec",
      "country": "CA",
      "elevation": 350,
      "lat": 46.1094017029,
      "lon": -73.452796936,
      "tz": "America\/Toronto"
  },
  "CSE4": {
      "icao": "CSE4",
      "iata": "",
      "name": "Lachute Airport",
      "city": "Lachute",
      "state": "Quebec",
      "country": "CA",
      "elevation": 221,
      "lat": 45.6394004822,
      "lon": -74.3705978394,
      "tz": "America\/Toronto"
  },
  "CSE5": {
      "icao": "CSE5",
      "iata": "",
      "name": "Montmagny Airport",
      "city": "Montmagny",
      "state": "Quebec",
      "country": "CA",
      "elevation": 37,
      "lat": 47.0013999939,
      "lon": -70.5158004761,
      "tz": "America\/Toronto"
  },
  "CSF3": {
      "icao": "CSF3",
      "iata": "",
      "name": "Poste Montagnais (Mile 134) Airport",
      "city": "Poste Montagnais",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1987,
      "lat": 51.8885993958,
      "lon": -65.7149963379,
      "tz": "America\/Toronto"
  },
  "CSF7": {
      "icao": "CSF7",
      "iata": "",
      "name": "Ottawa \/ Casselman (Shea Field)",
      "city": "Ottawa",
      "state": "Ontario",
      "country": "CA",
      "elevation": 216,
      "lat": 45.299675051,
      "lon": -75.1715040207,
      "tz": "America\/Toronto"
  },
  "CSG3": {
      "icao": "CSG3",
      "iata": "",
      "name": "Joliette Airport",
      "city": "Joliette",
      "state": "Quebec",
      "country": "CA",
      "elevation": 225,
      "lat": 46.0449981689,
      "lon": -73.501701355,
      "tz": "America\/Toronto"
  },
  "CSG5": {
      "icao": "CSG5",
      "iata": "",
      "name": "St Jean Chrysostome Airport",
      "city": "St-Jean Chrysostome",
      "state": "Quebec",
      "country": "CA",
      "elevation": 325,
      "lat": 46.6852989197,
      "lon": -71.1517028809,
      "tz": "America\/Toronto"
  },
  "CSH2": {
      "icao": "CSH2",
      "iata": "",
      "name": "Ile-aux-Grues Airport",
      "city": "Ile-aux-Grues",
      "state": "Quebec",
      "country": "CA",
      "elevation": 60,
      "lat": 47.0710983276,
      "lon": -70.5327987671,
      "tz": "America\/Toronto"
  },
  "CSH4": {
      "icao": "CSH4",
      "iata": "",
      "name": "Lebel-sur-Quevillon Airport",
      "city": "Lebel-sur-Quevillon",
      "state": "Quebec",
      "country": "CA",
      "elevation": 960,
      "lat": 49.0303001404,
      "lon": -77.0171966553,
      "tz": "America\/Toronto"
  },
  "CSH5": {
      "icao": "CSH5",
      "iata": "",
      "name": "St-Ferdinand Airport",
      "city": "St-Ferdinand",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1050,
      "lat": 46.1260986328,
      "lon": -71.5361022949,
      "tz": "America\/Toronto"
  },
  "CSJ2": {
      "icao": "CSJ2",
      "iata": "",
      "name": "Kanawata Aeroparc Airport",
      "city": "Kanawata",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1350,
      "lat": 47.5946998596,
      "lon": -74.1335983276,
      "tz": "America\/Toronto"
  },
  "CSJ4": {
      "icao": "CSJ4",
      "iata": "",
      "name": "Louiseville Airport",
      "city": "Louiseville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 40,
      "lat": 46.2461013794,
      "lon": -72.9044036865,
      "tz": "America\/Toronto"
  },
  "CSJ5": {
      "icao": "CSJ5",
      "iata": "",
      "name": "St-Louis-De-France Airport",
      "city": "St-Louis-De-France",
      "state": "Quebec",
      "country": "CA",
      "elevation": 225,
      "lat": 46.4366989136,
      "lon": -72.6303024292,
      "tz": "America\/Toronto"
  },
  "CSK3": {
      "icao": "CSK3",
      "iata": "",
      "name": "Montreal \/ Mascouche Airport",
      "city": "Montreal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 75,
      "lat": 45.7186012268,
      "lon": -73.5980987549,
      "tz": "America\/Toronto"
  },
  "CSK4": {
      "icao": "CSK4",
      "iata": "",
      "name": "Mansonville Airport",
      "city": "Mansonville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 575,
      "lat": 45.0264015198,
      "lon": -72.4019012451,
      "tz": "America\/New_York"
  },
  "CSK5": {
      "icao": "CSK5",
      "iata": "",
      "name": "Paquet Airport",
      "city": "St-Raymond",
      "state": "Quebec",
      "country": "CA",
      "elevation": 582,
      "lat": 46.8950004578,
      "lon": -71.7857971191,
      "tz": "America\/Toronto"
  },
  "CSK6": {
      "icao": "CSK6",
      "iata": "YNX",
      "name": "Snap Lake Airport",
      "city": "Snap Lake",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 1557,
      "lat": 63.5936012268,
      "lon": -110.90599823,
      "tz": "America\/Yellowknife"
  },
  "CSK8": {
      "icao": "CSK8",
      "iata": "",
      "name": "King George Airpark",
      "city": "Surrey",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 5,
      "lat": 49.0950012207,
      "lon": -122.8190002441,
      "tz": "America\/Vancouver"
  },
  "CSL3": {
      "icao": "CSL3",
      "iata": "",
      "name": "Lac a la Tortue Airport",
      "city": "Lac-a-la-Tortue",
      "state": "Quebec",
      "country": "CA",
      "elevation": 434,
      "lat": 46.6217002869,
      "lon": -72.6303024292,
      "tz": "America\/Toronto"
  },
  "CSL5": {
      "icao": "CSL5",
      "iata": "",
      "name": "St-Victor-De-Beauce Airport",
      "city": "St-Victor-De-Beauce",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1100,
      "lat": 46.1183013916,
      "lon": -70.8891983032,
      "tz": "America\/Toronto"
  },
  "CSL7": {
      "icao": "CSL7",
      "iata": "",
      "name": "Odessa \/ Strawberry Lakes Airstrip",
      "city": "Qu'Appelle",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2160,
      "lat": 50.3645177502,
      "lon": -103.74080658,
      "tz": "America\/Regina"
  },
  "CSL9": {
      "icao": "CSL9",
      "iata": "",
      "name": "Baie-Comeau (Manic 1) Airport",
      "city": "Baie-Comeau",
      "state": "Quebec",
      "country": "CA",
      "elevation": 250,
      "lat": 49.1843986511,
      "lon": -68.3625030518,
      "tz": "America\/Toronto"
  },
  "CSM3": {
      "icao": "CSM3",
      "iata": "",
      "name": "Thetford Mines Airport",
      "city": "Thetford Mines",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1408,
      "lat": 46.051700592,
      "lon": -71.2572021484,
      "tz": "America\/Toronto"
  },
  "CSM4": {
      "icao": "CSM4",
      "iata": "",
      "name": "Ste-Julienne Airport",
      "city": "Ste-Julienne",
      "state": "Quebec",
      "country": "CA",
      "elevation": 280,
      "lat": 45.9407997131,
      "lon": -73.7238998413,
      "tz": "America\/Toronto"
  },
  "CSM5": {
      "icao": "CSM5",
      "iata": "",
      "name": "St-Michel-Des-Saints Airport",
      "city": "St-Michel-Des-Saints",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1372,
      "lat": 46.6808013916,
      "lon": -73.993598938,
      "tz": "America\/Toronto"
  },
  "CSN3": {
      "icao": "CSN3",
      "iata": "",
      "name": "St-Jerome Airport",
      "city": "St-Jerome",
      "state": "Quebec",
      "country": "CA",
      "elevation": 550,
      "lat": 45.7803001404,
      "lon": -74.0618972778,
      "tz": "America\/Toronto"
  },
  "CSN5": {
      "icao": "CSN5",
      "iata": "",
      "name": "Stanhope Airport",
      "city": "Stanhope",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1450,
      "lat": 45.0155982971,
      "lon": -71.7944030762,
      "tz": "America\/New_York"
  },
  "CSN7": {
      "icao": "CSN7",
      "iata": "",
      "name": "Farnham Airport",
      "city": "Farnham",
      "state": "Quebec",
      "country": "CA",
      "elevation": 180,
      "lat": 45.2855987549,
      "lon": -73.0077972412,
      "tz": "America\/Toronto"
  },
  "CSP3": {
      "icao": "CSP3",
      "iata": "",
      "name": "Stony Plain (Lichtner Farms) Airport",
      "city": "Stony Plain",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2415,
      "lat": 53.5374984741,
      "lon": -114.0680007935,
      "tz": "America\/Edmonton"
  },
  "CSP5": {
      "icao": "CSP5",
      "iata": "",
      "name": "St-Mathias Airport",
      "city": "St-Mathias",
      "state": "Quebec",
      "country": "CA",
      "elevation": 50,
      "lat": 45.5008010864,
      "lon": -73.2416992188,
      "tz": "America\/Toronto"
  },
  "CSP6": {
      "icao": "CSP6",
      "iata": "",
      "name": "Montreal \/ Aeroparc Ile Perrot",
      "city": "Montreal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 100,
      "lat": 45.3760986328,
      "lon": -73.9072036743,
      "tz": "America\/Toronto"
  },
  "CSQ2": {
      "icao": "CSQ2",
      "iata": "",
      "name": "Shuswap (Skwlax Field) Airport",
      "city": "",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1170,
      "lat": 50.8786689434,
      "lon": -119.590816498,
      "tz": "America\/Vancouver"
  },
  "CSQ3": {
      "icao": "CSQ3",
      "iata": "",
      "name": "Valcourt Airport",
      "city": "Valcourt",
      "state": "Quebec",
      "country": "CA",
      "elevation": 740,
      "lat": 45.481098175,
      "lon": -72.3103027344,
      "tz": "America\/Toronto"
  },
  "CSR3": {
      "icao": "CSR3",
      "iata": "",
      "name": "Victoriaville Airport",
      "city": "Victoriaville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 486,
      "lat": 46.1128005981,
      "lon": -71.9289016724,
      "tz": "America\/Toronto"
  },
  "CSR8": {
      "icao": "CSR8",
      "iata": "",
      "name": "La Sarre Airport",
      "city": "La Sarre",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1048,
      "lat": 48.9171981812,
      "lon": -79.1785964966,
      "tz": "America\/Toronto"
  },
  "CSS3": {
      "icao": "CSS3",
      "iata": "",
      "name": "Montreal \/ Les Cedres Airport",
      "city": "Montreal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 160,
      "lat": 45.3474998474,
      "lon": -74.0766983032,
      "tz": "America\/Toronto"
  },
  "CSS4": {
      "icao": "CSS4",
      "iata": "",
      "name": "St-Dominique Airport",
      "city": "St-Dominique",
      "state": "Quebec",
      "country": "CA",
      "elevation": 250,
      "lat": 45.6324996948,
      "lon": -72.818901062,
      "tz": "America\/Toronto"
  },
  "CST3": {
      "icao": "CST3",
      "iata": "",
      "name": "Montreal \/ St-Lazare Airport",
      "city": "Montreal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 175,
      "lat": 45.3903282887,
      "lon": -74.1340041161,
      "tz": "America\/Toronto"
  },
  "CST7": {
      "icao": "CST7",
      "iata": "",
      "name": "St-Lambert-De-Lauzon Airport",
      "city": "St-Lambert-De-Lauzon",
      "state": "Quebec",
      "country": "CA",
      "elevation": 475,
      "lat": 46.5602989197,
      "lon": -71.1825027466,
      "tz": "America\/Toronto"
  },
  "CSU2": {
      "icao": "CSU2",
      "iata": "YKU",
      "name": "Chisasibi Airport",
      "city": "Chisasibi",
      "state": "Quebec",
      "country": "CA",
      "elevation": 43,
      "lat": 53.8055992126,
      "lon": -78.9169006348,
      "tz": "America\/Toronto"
  },
  "CSU3": {
      "icao": "CSU3",
      "iata": "",
      "name": "St Hyacinthe Airport",
      "city": "St-Hyacinthe",
      "state": "Quebec",
      "country": "CA",
      "elevation": 115,
      "lat": 45.6049995422,
      "lon": -73.0141983032,
      "tz": "America\/Toronto"
  },
  "CSU4": {
      "icao": "CSU4",
      "iata": "",
      "name": "Ste-Lucie-De-Beauregard Airport",
      "city": "Ste-Lucie-De-Beauregard",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1212,
      "lat": 46.7407989502,
      "lon": -70.0325012207,
      "tz": "America\/Toronto"
  },
  "CSU5": {
      "icao": "CSU5",
      "iata": "",
      "name": "Weymontachie Airport",
      "city": "Weymontachie",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1171,
      "lat": 47.9369010925,
      "lon": -73.817199707,
      "tz": "America\/Toronto"
  },
  "CSU7": {
      "icao": "CSU7",
      "iata": "",
      "name": "Lac-a-la-Tortue (Water Aerodrome) Airport",
      "city": "Lac-a-la-Tortue",
      "state": "Quebec",
      "country": "CA",
      "elevation": 427,
      "lat": 46.6166992188,
      "lon": -72.6258010864,
      "tz": "America\/Toronto"
  },
  "CSV8": {
      "icao": "CSV8",
      "iata": "",
      "name": "Schomberg (Sloan Valley View Farm Airfield)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 890,
      "lat": 43.9861452438,
      "lon": -79.7272896767,
      "tz": "America\/Toronto"
  },
  "CSX3": {
      "icao": "CSX3",
      "iata": "",
      "name": "Richelieu",
      "city": "Richelieu",
      "state": "Quebec",
      "country": "CA",
      "elevation": 115,
      "lat": 45.4470727938,
      "lon": -73.2326853275,
      "tz": "America\/Toronto"
  },
  "CSX5": {
      "icao": "CSX5",
      "iata": "",
      "name": "Grant Airport",
      "city": "St-Mathias",
      "state": "Quebec",
      "country": "CA",
      "elevation": 110,
      "lat": 45.4719009399,
      "lon": -73.1994018555,
      "tz": "America\/Toronto"
  },
  "CSX7": {
      "icao": "CSX7",
      "iata": "",
      "name": "Exeter Airport",
      "city": "Sexsmith",
      "state": "Ontario",
      "country": "CA",
      "elevation": 856,
      "lat": 43.3772010803,
      "lon": -81.5042037964,
      "tz": "America\/Toronto"
  },
  "CSY3": {
      "icao": "CSY3",
      "iata": "",
      "name": "Sorel Airport",
      "city": "Sorel",
      "state": "Quebec",
      "country": "CA",
      "elevation": 76,
      "lat": 45.9803009033,
      "lon": -73.0421981812,
      "tz": "America\/Toronto"
  },
  "CSY4": {
      "icao": "CSY4",
      "iata": "",
      "name": "St-Donat Airport",
      "city": "St-Donat",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1270,
      "lat": 46.3058013916,
      "lon": -74.1813964844,
      "tz": "America\/Toronto"
  },
  "CSZ3": {
      "icao": "CSZ3",
      "iata": "",
      "name": "St-Jovite Airport",
      "city": "Mont-Tremblant",
      "state": "Quebec",
      "country": "CA",
      "elevation": 790,
      "lat": 46.1542015076,
      "lon": -74.5836029053,
      "tz": "America\/Toronto"
  },
  "CSZ4": {
      "icao": "CSZ4",
      "iata": "",
      "name": "St-Frederic Airport",
      "city": "St-Frederic",
      "state": "Quebec",
      "country": "CA",
      "elevation": 991,
      "lat": 46.331401825,
      "lon": -70.9633026123,
      "tz": "America\/Toronto"
  },
  "CT01": {
      "icao": "CT01",
      "iata": "",
      "name": "Whelan Farms Airport",
      "city": "Bethlehem",
      "state": "Connecticut",
      "country": "US",
      "elevation": 1020,
      "lat": 41.6661987305,
      "lon": -73.1890029907,
      "tz": "America\/New_York"
  },
  "CT04": {
      "icao": "CT04",
      "iata": "",
      "name": "Grass Land Air Field",
      "city": "North Canaan",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 705,
      "lat": 42.0390014648,
      "lon": -73.2871017456,
      "tz": "America\/New_York"
  },
  "CT07": {
      "icao": "CT07",
      "iata": "",
      "name": "Skis Landing Area Airport",
      "city": "Colchester",
      "state": "Connecticut",
      "country": "US",
      "elevation": 510,
      "lat": 41.5736999512,
      "lon": -72.3031005859,
      "tz": "America\/New_York"
  },
  "CT08": {
      "icao": "CT08",
      "iata": "",
      "name": "Gardner Lake Airport",
      "city": "Colchester",
      "state": "Connecticut",
      "country": "US",
      "elevation": 485,
      "lat": 41.5082015991,
      "lon": -72.2436981201,
      "tz": "America\/New_York"
  },
  "CT09": {
      "icao": "CT09",
      "iata": "",
      "name": "Heckler Field",
      "city": "Coventry",
      "state": "Connecticut",
      "country": "US",
      "elevation": 793,
      "lat": 41.802898407,
      "lon": -72.3917007446,
      "tz": "America\/New_York"
  },
  "CT11": {
      "icao": "CT11",
      "iata": "",
      "name": "Devils Hopyard Field",
      "city": "East Haddam",
      "state": "Connecticut",
      "country": "US",
      "elevation": 125,
      "lat": 41.4440002441,
      "lon": -72.3389968872,
      "tz": "America\/New_York"
  },
  "CT13": {
      "icao": "CT13",
      "iata": "",
      "name": "Yankee Airstrip",
      "city": "East Killingly",
      "state": "Connecticut",
      "country": "US",
      "elevation": 720,
      "lat": 41.8833999634,
      "lon": -71.8162002563,
      "tz": "America\/New_York"
  },
  "CT14": {
      "icao": "CT14",
      "iata": "",
      "name": "Bancroft Airport",
      "city": "East Windsor Hill",
      "state": "Connecticut",
      "country": "US",
      "elevation": 52,
      "lat": 41.8667984009,
      "lon": -72.6162033081,
      "tz": "America\/New_York"
  },
  "CT15": {
      "icao": "CT15",
      "iata": "",
      "name": "Wysocki Field",
      "city": "Ellington",
      "state": "Connecticut",
      "country": "US",
      "elevation": 390,
      "lat": 41.945400238,
      "lon": -72.4776000977,
      "tz": "America\/New_York"
  },
  "CT19": {
      "icao": "CT19",
      "iata": "",
      "name": "Laurie Field",
      "city": "Hazardville",
      "state": "Connecticut",
      "country": "US",
      "elevation": 129,
      "lat": 41.9682006836,
      "lon": -72.5398025513,
      "tz": "America\/New_York"
  },
  "CT20": {
      "icao": "CT20",
      "iata": "",
      "name": "Rankl Field",
      "city": "Marlborough",
      "state": "Connecticut",
      "country": "US",
      "elevation": 350,
      "lat": 41.6001014709,
      "lon": -72.4328994751,
      "tz": "America\/New_York"
  },
  "CT24": {
      "icao": "CT24",
      "iata": "",
      "name": "North Canaan Aviation Facilities Inc Airport",
      "city": "North Canaan",
      "state": "Connecticut",
      "country": "US",
      "elevation": 658,
      "lat": 42.0453987122,
      "lon": -73.3386993408,
      "tz": "America\/New_York"
  },
  "CT29": {
      "icao": "CT29",
      "iata": "",
      "name": "Valley Farms Airport",
      "city": "Somers",
      "state": "Connecticut",
      "country": "US",
      "elevation": 221,
      "lat": 42.0136985779,
      "lon": -72.4729003906,
      "tz": "America\/New_York"
  },
  "CT31": {
      "icao": "CT31",
      "iata": "",
      "name": "Swift Airport",
      "city": "Stafford Springs",
      "state": "Connecticut",
      "country": "US",
      "elevation": 515,
      "lat": 41.9650993347,
      "lon": -72.332901001,
      "tz": "America\/New_York"
  },
  "CT32": {
      "icao": "CT32",
      "iata": "",
      "name": "Gallup Farm Airport",
      "city": "Voluntown",
      "state": "Connecticut",
      "country": "US",
      "elevation": 440,
      "lat": 41.5354003906,
      "lon": -71.8345031738,
      "tz": "America\/New_York"
  },
  "CT36": {
      "icao": "CT36",
      "iata": "",
      "name": "Gager Field",
      "city": "Bozrah",
      "state": "Connecticut",
      "country": "US",
      "elevation": 465,
      "lat": 41.5644989014,
      "lon": -72.197303772,
      "tz": "America\/New_York"
  },
  "CT39": {
      "icao": "CT39",
      "iata": "",
      "name": "Maplewood Farm Airport",
      "city": "Durham",
      "state": "Connecticut",
      "country": "US",
      "elevation": 280,
      "lat": 41.4683990479,
      "lon": -72.7083969116,
      "tz": "America\/New_York"
  },
  "CT42": {
      "icao": "CT42",
      "iata": "",
      "name": "Wings Ago Airstrip",
      "city": "Goshen",
      "state": "Connecticut",
      "country": "US",
      "elevation": 1585,
      "lat": 41.8501014709,
      "lon": -73.2661972046,
      "tz": "America\/New_York"
  },
  "CT43": {
      "icao": "CT43",
      "iata": "",
      "name": "Spruce Airport",
      "city": "Jewett City",
      "state": "Connecticut",
      "country": "US",
      "elevation": 200,
      "lat": 41.5937004089,
      "lon": -71.9311981201,
      "tz": "America\/New_York"
  },
  "CT44": {
      "icao": "CT44",
      "iata": "",
      "name": "Ripley Field",
      "city": "Litchfield",
      "state": "Connecticut",
      "country": "US",
      "elevation": 1330,
      "lat": 41.7857017517,
      "lon": -73.3087005615,
      "tz": "America\/New_York"
  },
  "CT48": {
      "icao": "CT48",
      "iata": "",
      "name": "Wychwood Field",
      "city": "Mystic",
      "state": "Connecticut",
      "country": "US",
      "elevation": 316,
      "lat": 41.4367980957,
      "lon": -71.9253997803,
      "tz": "America\/New_York"
  },
  "CT51": {
      "icao": "CT51",
      "iata": "",
      "name": "Docktors Field",
      "city": "New Milford",
      "state": "Connecticut",
      "country": "US",
      "elevation": 300,
      "lat": 41.5334014893,
      "lon": -73.4161987305,
      "tz": "America\/New_York"
  },
  "CT52": {
      "icao": "CT52",
      "iata": "",
      "name": "Flying Ridge Airstrip",
      "city": "Newtown",
      "state": "Connecticut",
      "country": "US",
      "elevation": 610,
      "lat": 41.3681983948,
      "lon": -73.2911987305,
      "tz": "America\/New_York"
  },
  "CT59": {
      "icao": "CT59",
      "iata": "",
      "name": "Good Hill Farm Airport",
      "city": "Roxbury",
      "state": "Connecticut",
      "country": "US",
      "elevation": 949,
      "lat": 41.5500984192,
      "lon": -73.2661972046,
      "tz": "America\/New_York"
  },
  "CT66": {
      "icao": "CT66",
      "iata": "",
      "name": "Long View Landing Airport",
      "city": "Washington",
      "state": "Connecticut",
      "country": "US",
      "elevation": 940,
      "lat": 41.6668014526,
      "lon": -73.2828979492,
      "tz": "America\/New_York"
  },
  "CT74": {
      "icao": "CT74",
      "iata": "",
      "name": "Westford Airstrip",
      "city": "Ashford\/Westford",
      "state": "Connecticut",
      "country": "US",
      "elevation": 900,
      "lat": 41.9506988525,
      "lon": -72.1815032959,
      "tz": "America\/New_York"
  },
  "CT80": {
      "icao": "CT80",
      "iata": "",
      "name": "Stonington Airpark",
      "city": "Stonington",
      "state": "Connecticut",
      "country": "US",
      "elevation": 7,
      "lat": 41.3473014832,
      "lon": -71.8859024048,
      "tz": "America\/New_York"
  },
  "CT85": {
      "icao": "CT85",
      "iata": "",
      "name": "Roberts Farm Airport",
      "city": "East Windsor",
      "state": "Connecticut",
      "country": "US",
      "elevation": 37,
      "lat": 41.8898010254,
      "lon": -72.6150970459,
      "tz": "America\/New_York"
  },
  "CT96": {
      "icao": "CT96",
      "iata": "",
      "name": "Green Acres Airport",
      "city": "Bristol",
      "state": "Connecticut",
      "country": "US",
      "elevation": 950,
      "lat": 41.7084007263,
      "lon": -72.9711990356,
      "tz": "America\/New_York"
  },
  "CTA3": {
      "icao": "CTA3",
      "iata": "",
      "name": "Ile aux Coudres Airport",
      "city": "Ile aux Coudres",
      "state": "Quebec",
      "country": "CA",
      "elevation": 110,
      "lat": 47.3894004822,
      "lon": -70.3843994141,
      "tz": "America\/Toronto"
  },
  "CTA4": {
      "icao": "CTA4",
      "iata": "",
      "name": "St Bruno-de-Guigues Airport",
      "city": "St-Bruno-de-Guigues",
      "state": "Quebec",
      "country": "CA",
      "elevation": 820,
      "lat": 47.4491996765,
      "lon": -79.4180984497,
      "tz": "America\/Toronto"
  },
  "CTB6": {
      "icao": "CTB6",
      "iata": "ZTB",
      "name": "Tete-a-la-Baleine Airport",
      "city": "Tete-a-la-Baleine",
      "state": "Quebec",
      "country": "CA",
      "elevation": 107,
      "lat": 50.6744003296,
      "lon": -59.3835983276,
      "tz": "America\/Blanc-Sablon"
  },
  "CTE5": {
      "icao": "CTE5",
      "iata": "",
      "name": "Lac a la Perchaude Airport",
      "city": "Lac a la Perchaude",
      "state": "Quebec",
      "country": "CA",
      "elevation": 550,
      "lat": 46.6225013733,
      "lon": -72.8482971191,
      "tz": "America\/Toronto"
  },
  "CTF3": {
      "icao": "CTF3",
      "iata": "",
      "name": "Causapscal Airport",
      "city": "Causapscal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 340,
      "lat": 48.3114013672,
      "lon": -67.2514038086,
      "tz": "America\/Toronto"
  },
  "CTF4": {
      "icao": "CTF4",
      "iata": "",
      "name": "Dundalk (Tripp Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1700,
      "lat": 44.1743863972,
      "lon": -80.3079128265,
      "tz": "America\/Toronto"
  },
  "CTG3": {
      "icao": "CTG3",
      "iata": "",
      "name": "Du Rocher-Perce (Pabok) Airport",
      "city": "Du Rocher-Perce",
      "state": "Quebec",
      "country": "CA",
      "elevation": 87,
      "lat": 48.3833007813,
      "lon": -64.5643997192,
      "tz": "America\/Toronto"
  },
  "CTH2": {
      "icao": "CTH2",
      "iata": "",
      "name": "Thor Lake Airport",
      "city": "",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 809,
      "lat": 62.1007902302,
      "lon": -112.624111176,
      "tz": "America\/Yellowknife"
  },
  "CTH3": {
      "icao": "CTH3",
      "iata": "",
      "name": "Grandes-Bergeronnes Airport",
      "city": "Grandes-Bergeronnes",
      "state": "Quebec",
      "country": "CA",
      "elevation": 69,
      "lat": 48.2322006226,
      "lon": -69.5428009033,
      "tz": "America\/Toronto"
  },
  "CTK2": {
      "icao": "CTK2",
      "iata": "",
      "name": "Senneterre Airport",
      "city": "Senneterre",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1106,
      "lat": 48.3405990601,
      "lon": -77.181098938,
      "tz": "America\/Toronto"
  },
  "CTK6": {
      "icao": "CTK6",
      "iata": "",
      "name": "Kegaska Airport",
      "city": "Kegaska",
      "state": "Quebec",
      "country": "CA",
      "elevation": 32,
      "lat": 50.1958007813,
      "lon": -61.2658004761,
      "tz": "America\/Toronto"
  },
  "CTK7": {
      "icao": "CTK7",
      "iata": "",
      "name": "Selkirk \/ Kindy Airstrip",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 686,
      "lat": 42.8511123657,
      "lon": -79.8791656494,
      "tz": "America\/Toronto"
  },
  "CTN6": {
      "icao": "CTN6",
      "iata": "",
      "name": "Treherne (South Norfolk Airpark) Airport",
      "city": "Treherne",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1190,
      "lat": 49.6596984863,
      "lon": -98.6652984619,
      "tz": "America\/Winnipeg"
  },
  "CTP9": {
      "icao": "CTP9",
      "iata": "YAU",
      "name": "Donaldson Airport",
      "city": "Kattiniq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1902,
      "lat": 61.6622009277,
      "lon": -73.3214035034,
      "tz": "America\/Toronto"
  },
  "CTQ2": {
      "icao": "CTQ2",
      "iata": "",
      "name": "Weller Airport",
      "city": "Stanstead",
      "state": "Vermont",
      "country": "CA",
      "elevation": 1250,
      "lat": 45.0344009399,
      "lon": -72.0350036621,
      "tz": "America\/New_York"
  },
  "CTQ6": {
      "icao": "CTQ6",
      "iata": "",
      "name": "St-Anselme Airport",
      "city": "St-Anselme",
      "state": "Quebec",
      "country": "CA",
      "elevation": 560,
      "lat": 46.6218986511,
      "lon": -70.9550018311,
      "tz": "America\/Toronto"
  },
  "CTR6": {
      "icao": "CTR6",
      "iata": "",
      "name": "St-Basile (Marcotte) Airport",
      "city": "St-Basile",
      "state": "Quebec",
      "country": "CA",
      "elevation": 300,
      "lat": 46.7835998535,
      "lon": -71.8264007568,
      "tz": "America\/Toronto"
  },
  "CTT5": {
      "icao": "CTT5",
      "iata": "",
      "name": "La Romaine Airport",
      "city": "La Romaine",
      "state": "Quebec",
      "country": "CA",
      "elevation": 90,
      "lat": 50.2597007751,
      "lon": -60.6794013977,
      "tz": "America\/Blanc-Sablon"
  },
  "CTU2": {
      "icao": "CTU2",
      "iata": "",
      "name": "Fontanges Airport",
      "city": "Fontanges",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1550,
      "lat": 54.5539016724,
      "lon": -71.1733016968,
      "tz": "America\/Toronto"
  },
  "CTU5": {
      "icao": "CTU5",
      "iata": "ZLT",
      "name": "La Tabatiere Airport",
      "city": "La Tabatiere",
      "state": "Quebec",
      "country": "CA",
      "elevation": 102,
      "lat": 50.8307991028,
      "lon": -58.9756011963,
      "tz": "America\/Blanc-Sablon"
  },
  "CTY4": {
      "icao": "CTY4",
      "iata": "",
      "name": "Olds \/ North 40 Ranch Airstrip",
      "city": "",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3410,
      "lat": 51.9001705129,
      "lon": -114.147820473,
      "tz": "America\/Edmonton"
  },
  "CTY5": {
      "icao": "CTY5",
      "iata": "",
      "name": "Rougemont Airport",
      "city": "Rougemont",
      "state": "Quebec",
      "country": "CA",
      "elevation": 80,
      "lat": 45.4383010864,
      "lon": -73.0378036499,
      "tz": "America\/Toronto"
  },
  "CVB2": {
      "icao": "CVB2",
      "iata": "",
      "name": "Voisey's Bay",
      "city": "",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 0,
      "lat": 56.3447222222,
      "lon": -62.0880555556,
      "tz": "America\/Goose_Bay"
  },
  "CVF2": {
      "icao": "CVF2",
      "iata": "",
      "name": "Fergus (Vodarek Field)",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1380,
      "lat": 43.7232733033,
      "lon": -80.2893733978,
      "tz": "America\/Toronto"
  },
  "CVS2": {
      "icao": "CVS2",
      "iata": "",
      "name": "Viking (South) Airport",
      "city": "Viking",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2242,
      "lat": 53.0256004333,
      "lon": -111.9489974976,
      "tz": "America\/Edmonton"
  },
  "CWC1": {
      "icao": "CWC1",
      "iata": "",
      "name": "White City (Radomsky) Airport",
      "city": "White City",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2020,
      "lat": 50.4397010803,
      "lon": -104.3059997559,
      "tz": "America\/Regina"
  },
  "CWF2": {
      "icao": "CWF2",
      "iata": "",
      "name": "Walter's Falls (Piper Way) Airfield",
      "city": "",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1400,
      "lat": 44.463879951,
      "lon": -80.6214737892,
      "tz": "America\/Toronto"
  },
  "CWJC": {
      "icao": "CWJC",
      "iata": "",
      "name": "Ennadai Lake Airport",
      "city": "Ennadai",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 1021,
      "lat": 61.1333333333,
      "lon": -100.9,
      "tz": "America\/Rankin_Inlet"
  },
  "CYAB": {
      "icao": "CYAB",
      "iata": "YAB",
      "name": "Arctic Bay Airport",
      "city": "",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 72,
      "lat": 73.0057668174,
      "lon": -85.0425052643,
      "tz": "America\/Rankin_Inlet"
  },
  "CYAC": {
      "icao": "CYAC",
      "iata": "YAC",
      "name": "Cat Lake Airport",
      "city": "Cat Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1344,
      "lat": 51.7271995544,
      "lon": -91.8244018555,
      "tz": "America\/Rainy_River"
  },
  "CYAD": {
      "icao": "CYAD",
      "iata": "YAR",
      "name": "La Grande-3 Airport",
      "city": "La Grande-3",
      "state": "Quebec",
      "country": "CA",
      "elevation": 775,
      "lat": 53.5717010498,
      "lon": -76.1964035034,
      "tz": "America\/Toronto"
  },
  "CYAG": {
      "icao": "CYAG",
      "iata": "YAG",
      "name": "Fort Frances Municipal Airport",
      "city": "Fort Frances",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1125,
      "lat": 48.6542015076,
      "lon": -93.4396972656,
      "tz": "America\/Rainy_River"
  },
  "CYAH": {
      "icao": "CYAH",
      "iata": "YAH",
      "name": "La Grande-4 Airport",
      "city": "La Grande-4",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1005,
      "lat": 53.754699707,
      "lon": -73.6753005981,
      "tz": "America\/Toronto"
  },
  "CYAL": {
      "icao": "CYAL",
      "iata": "YAL",
      "name": "Alert Bay Airport",
      "city": "Alert Bay",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 240,
      "lat": 50.5821990967,
      "lon": -126.9160003662,
      "tz": "America\/Vancouver"
  },
  "CYAM": {
      "icao": "CYAM",
      "iata": "YAM",
      "name": "Sault Ste Marie Airport",
      "city": "Sault Ste Marie",
      "state": "Ontario",
      "country": "CA",
      "elevation": 630,
      "lat": 46.4850006104,
      "lon": -84.5093994141,
      "tz": "America\/Detroit"
  },
  "CYAQ": {
      "icao": "CYAQ",
      "iata": "XKS",
      "name": "Kasabonika Airport",
      "city": "Kasabonika",
      "state": "Ontario",
      "country": "CA",
      "elevation": 672,
      "lat": 53.5247001648,
      "lon": -88.6427993774,
      "tz": "America\/Rainy_River"
  },
  "CYAS": {
      "icao": "CYAS",
      "iata": "YKG",
      "name": "Kangirsuk Airport",
      "city": "Kangirsuk",
      "state": "Quebec",
      "country": "CA",
      "elevation": 403,
      "lat": 60.0271987915,
      "lon": -69.9991989136,
      "tz": "America\/Toronto"
  },
  "CYAT": {
      "icao": "CYAT",
      "iata": "YAT",
      "name": "Attawapiskat Airport",
      "city": "Attawapiskat",
      "state": "Ontario",
      "country": "CA",
      "elevation": 31,
      "lat": 52.9275016785,
      "lon": -82.4319000244,
      "tz": "America\/Nipigon"
  },
  "CYAU": {
      "icao": "CYAU",
      "iata": "",
      "name": "Liverpool South Shore Regional Airport",
      "city": "Liverpool",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 321,
      "lat": 44.2303009033,
      "lon": -64.8561019897,
      "tz": "America\/Halifax"
  },
  "CYAV": {
      "icao": "CYAV",
      "iata": "",
      "name": "Winnipeg \/ St. Andrews Airport",
      "city": "Winnipeg",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 760,
      "lat": 50.0564002991,
      "lon": -97.0325012207,
      "tz": "America\/Winnipeg"
  },
  "CYAW": {
      "icao": "CYAW",
      "iata": "YAW",
      "name": "Shearwater Airport",
      "city": "Halifax",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 144,
      "lat": 44.637222,
      "lon": -63.502222,
      "tz": "America\/Halifax"
  },
  "CYAX": {
      "icao": "CYAX",
      "iata": "",
      "name": "Lac Du Bonnet Airport",
      "city": "Lac Du Bonnet",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 850,
      "lat": 50.2943992615,
      "lon": -96.0100021362,
      "tz": "America\/Winnipeg"
  },
  "CYAY": {
      "icao": "CYAY",
      "iata": "YAY",
      "name": "St. Anthony Airport",
      "city": "St. Anthony",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 108,
      "lat": 51.3918991089,
      "lon": -56.0830993652,
      "tz": "America\/St_Johns"
  },
  "CYAZ": {
      "icao": "CYAZ",
      "iata": "YAZ",
      "name": "Tofino \/ Long Beach Airport",
      "city": "Tofino",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 80,
      "lat": 49.0798255464,
      "lon": -125.775604248,
      "tz": "America\/Vancouver"
  },
  "CYBA": {
      "icao": "CYBA",
      "iata": "YBA",
      "name": "Banff Airport",
      "city": "Banff",
      "state": "Alberta",
      "country": "CA",
      "elevation": 4583,
      "lat": 51.2073404699,
      "lon": -115.541861057,
      "tz": "America\/Edmonton"
  },
  "CYBB": {
      "icao": "CYBB",
      "iata": "YBB",
      "name": "Kugaaruk Airport",
      "city": "Kugaaruk",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 56,
      "lat": 68.5344009399,
      "lon": -89.8080978394,
      "tz": "America\/Cambridge_Bay"
  },
  "CYBC": {
      "icao": "CYBC",
      "iata": "YBC",
      "name": "Baie Comeau Airport",
      "city": "Baie-Comeau",
      "state": "Quebec",
      "country": "CA",
      "elevation": 71,
      "lat": 49.1324996948,
      "lon": -68.2043991089,
      "tz": "America\/Toronto"
  },
  "CYBD": {
      "icao": "CYBD",
      "iata": "QBC",
      "name": "Bella Coola Airport",
      "city": "Bella Coola",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 117,
      "lat": 52.3875007629,
      "lon": -126.5960006714,
      "tz": "America\/Vancouver"
  },
  "CYBE": {
      "icao": "CYBE",
      "iata": "YBE",
      "name": "Uranium City Airport",
      "city": "Uranium City",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1044,
      "lat": 59.5614013672,
      "lon": -108.4810028076,
      "tz": "America\/Regina"
  },
  "CYBF": {
      "icao": "CYBF",
      "iata": "YBY",
      "name": "Bonnyville Airport",
      "city": "Bonnyville",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1836,
      "lat": 54.3041992188,
      "lon": -110.7440032959,
      "tz": "America\/Edmonton"
  },
  "CYBG": {
      "icao": "CYBG",
      "iata": "YBG",
      "name": "CFB Bagotville",
      "city": "Bagotville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 522,
      "lat": 48.3306007385,
      "lon": -70.9963989258,
      "tz": "America\/Toronto"
  },
  "CYBK": {
      "icao": "CYBK",
      "iata": "YBK",
      "name": "Baker Lake Airport",
      "city": "Baker Lake",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 59,
      "lat": 64.2988967896,
      "lon": -96.077796936,
      "tz": "America\/Rankin_Inlet"
  },
  "CYBL": {
      "icao": "CYBL",
      "iata": "YBL",
      "name": "Campbell River Airport",
      "city": "Campbell River",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 346,
      "lat": 49.9508018494,
      "lon": -125.2710037231,
      "tz": "America\/Vancouver"
  },
  "CYBP": {
      "icao": "CYBP",
      "iata": "",
      "name": "Brooks Regional",
      "city": "Brooks",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2490,
      "lat": 50.6335983276,
      "lon": -111.9260025024,
      "tz": "America\/Edmonton"
  },
  "CYBQ": {
      "icao": "CYBQ",
      "iata": "XTL",
      "name": "Tadoule Lake Airport",
      "city": "Tadoule Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 923,
      "lat": 58.7061004639,
      "lon": -98.5121994019,
      "tz": "America\/Winnipeg"
  },
  "CYBR": {
      "icao": "CYBR",
      "iata": "YBR",
      "name": "Brandon Municipal Airport",
      "city": "Brandon",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1343,
      "lat": 49.9099998474,
      "lon": -99.9518966675,
      "tz": "America\/Winnipeg"
  },
  "CYBT": {
      "icao": "CYBT",
      "iata": "YBT",
      "name": "Brochet Airport",
      "city": "Brochet",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1136,
      "lat": 57.8894004822,
      "lon": -101.6790008545,
      "tz": "America\/Winnipeg"
  },
  "CYBU": {
      "icao": "CYBU",
      "iata": "",
      "name": "Nipawin Airport",
      "city": "Nipawin",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1220,
      "lat": 53.3325004578,
      "lon": -104.0080032349,
      "tz": "America\/Regina"
  },
  "CYBV": {
      "icao": "CYBV",
      "iata": "YBV",
      "name": "Berens River Airport",
      "city": "Berens River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 728,
      "lat": 52.3588981628,
      "lon": -97.0183029175,
      "tz": "America\/Winnipeg"
  },
  "CYBW": {
      "icao": "CYBW",
      "iata": "",
      "name": "Calgary \/ Springbank Airport",
      "city": "Calgary",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3940,
      "lat": 51.103099823,
      "lon": -114.374000549,
      "tz": "America\/Edmonton"
  },
  "CYBX": {
      "icao": "CYBX",
      "iata": "YBX",
      "name": "Lourdes de Blanc Sablon Airport",
      "city": "Lourdes-De-Blanc-Sablon",
      "state": "Quebec",
      "country": "CA",
      "elevation": 121,
      "lat": 51.4435997009,
      "lon": -57.1852989197,
      "tz": "America\/Blanc-Sablon"
  },
  "CYCA": {
      "icao": "CYCA",
      "iata": "YRF",
      "name": "Cartwright Airport",
      "city": "Cartwright",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 40,
      "lat": 53.682800293,
      "lon": -57.0419006348,
      "tz": "America\/Goose_Bay"
  },
  "CYCB": {
      "icao": "CYCB",
      "iata": "YCB",
      "name": "Cambridge Bay Airport",
      "city": "Cambridge Bay",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 90,
      "lat": 69.1081008911,
      "lon": -105.138000488,
      "tz": "America\/Cambridge_Bay"
  },
  "CYCC": {
      "icao": "CYCC",
      "iata": "YCC",
      "name": "Cornwall Regional Airport",
      "city": "Cornwall",
      "state": "Quebec",
      "country": "CA",
      "elevation": 175,
      "lat": 45.0928001404,
      "lon": -74.5633010864,
      "tz": "America\/Toronto"
  },
  "CYCD": {
      "icao": "CYCD",
      "iata": "YCD",
      "name": "Nanaimo Airport",
      "city": "Nanaimo",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 93,
      "lat": 49.0522994995,
      "lon": -123.8700027466,
      "tz": "America\/Vancouver"
  },
  "CYCE": {
      "icao": "CYCE",
      "iata": "YCE",
      "name": "James T. Field Memorial Aerodrome",
      "city": "Centralia",
      "state": "Ontario",
      "country": "CA",
      "elevation": 824,
      "lat": 43.2855987549,
      "lon": -81.5083007813,
      "tz": "America\/Toronto"
  },
  "CYCG": {
      "icao": "CYCG",
      "iata": "YCG",
      "name": "Castlegar\/West Kootenay Regional Airport",
      "city": "Castlegar",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1624,
      "lat": 49.2963981628,
      "lon": -117.632003784,
      "tz": "America\/Vancouver"
  },
  "CYCH": {
      "icao": "CYCH",
      "iata": "YCH",
      "name": "Miramichi Airport",
      "city": "Miramichi",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 108,
      "lat": 47.0078010559,
      "lon": -65.4492034912,
      "tz": "America\/Moncton"
  },
  "CYCK": {
      "icao": "CYCK",
      "iata": "XCM",
      "name": "Chatham Kent Airport",
      "city": "Chatham-Kent",
      "state": "Ontario",
      "country": "CA",
      "elevation": 650,
      "lat": 42.3064002991,
      "lon": -82.0819015503,
      "tz": "America\/Toronto"
  },
  "CYCL": {
      "icao": "CYCL",
      "iata": "YCL",
      "name": "Charlo Airport",
      "city": "Charlo",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 132,
      "lat": 47.9907989502,
      "lon": -66.3302993774,
      "tz": "America\/Moncton"
  },
  "CYCN": {
      "icao": "CYCN",
      "iata": "YCN",
      "name": "Cochrane Airport",
      "city": "Cochrane",
      "state": "Ontario",
      "country": "CA",
      "elevation": 861,
      "lat": 49.1055984497,
      "lon": -81.0136032104,
      "tz": "America\/Nipigon"
  },
  "CYCO": {
      "icao": "CYCO",
      "iata": "YCO",
      "name": "Kugluktuk Airport",
      "city": "Kugluktuk",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 74,
      "lat": 67.8167037964,
      "lon": -115.143997192,
      "tz": "America\/Cambridge_Bay"
  },
  "CYCP": {
      "icao": "CYCP",
      "iata": "",
      "name": "Blue River Airport",
      "city": "Blue River",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2240,
      "lat": 52.1166992188,
      "lon": -119.2829971313,
      "tz": "America\/Vancouver"
  },
  "CYCQ": {
      "icao": "CYCQ",
      "iata": "YCQ",
      "name": "Chetwynd Airport",
      "city": "Chetwynd",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2000,
      "lat": 55.6871986389,
      "lon": -121.6269989014,
      "tz": "America\/Dawson_Creek"
  },
  "CYCR": {
      "icao": "CYCR",
      "iata": "YCR",
      "name": "Cross Lake (Charlie Sinclair Memorial) Airport",
      "city": "Cross Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 709,
      "lat": 54.6105995178,
      "lon": -97.7608032227,
      "tz": "America\/Winnipeg"
  },
  "CYCS": {
      "icao": "CYCS",
      "iata": "YCS",
      "name": "Chesterfield Inlet Airport",
      "city": "Chesterfield Inlet",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 32,
      "lat": 63.3469009399,
      "lon": -90.7311019897,
      "tz": "America\/Rankin_Inlet"
  },
  "CYCT": {
      "icao": "CYCT",
      "iata": "YCT",
      "name": "Coronation Airport",
      "city": "Coronation",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2595,
      "lat": 52.0750007629,
      "lon": -111.4449996948,
      "tz": "America\/Edmonton"
  },
  "CYCW": {
      "icao": "CYCW",
      "iata": "YCW",
      "name": "Chilliwack Airport",
      "city": "Chilliwack",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 32,
      "lat": 49.1528015137,
      "lon": -121.9390029907,
      "tz": "America\/Vancouver"
  },
  "CYCY": {
      "icao": "CYCY",
      "iata": "YCY",
      "name": "Clyde River Airport",
      "city": "Clyde River",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 87,
      "lat": 70.4860992432,
      "lon": -68.5167007446,
      "tz": "America\/Iqaluit"
  },
  "CYCZ": {
      "icao": "CYCZ",
      "iata": "YCZ",
      "name": "Fairmont Hot Springs Airport",
      "city": "Fairmont Hot Springs",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2661,
      "lat": 50.3302993774,
      "lon": -115.873001099,
      "tz": "America\/Edmonton"
  },
  "CYDA": {
      "icao": "CYDA",
      "iata": "YDA",
      "name": "Dawson City Airport",
      "city": "Dawson City",
      "state": "Yukon",
      "country": "CA",
      "elevation": 1215,
      "lat": 64.0430984497,
      "lon": -139.1280059814,
      "tz": "America\/Dawson"
  },
  "CYDB": {
      "icao": "CYDB",
      "iata": "YDB",
      "name": "Burwash Airport",
      "city": "Burwash",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2647,
      "lat": 61.3711013794,
      "lon": -139.0410003662,
      "tz": "America\/Dawson"
  },
  "CYDC": {
      "icao": "CYDC",
      "iata": "",
      "name": "Princeton Airport",
      "city": "Town of Princeton",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2298,
      "lat": 49.4681015015,
      "lon": -120.511001587,
      "tz": "America\/Vancouver"
  },
  "CYDF": {
      "icao": "CYDF",
      "iata": "YDF",
      "name": "Deer Lake Airport",
      "city": "Deer Lake",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 72,
      "lat": 49.2108001709,
      "lon": -57.3913993835,
      "tz": "America\/St_Johns"
  },
  "CYDL": {
      "icao": "CYDL",
      "iata": "YDL",
      "name": "Dease Lake Airport",
      "city": "Dease Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2600,
      "lat": 58.4221992493,
      "lon": -130.0319976807,
      "tz": "America\/Vancouver"
  },
  "CYDM": {
      "icao": "CYDM",
      "iata": "YDM",
      "name": "Ross River Airport",
      "city": "Ross River",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2314,
      "lat": 61.9706001282,
      "lon": -132.4230041504,
      "tz": "America\/Whitehorse"
  },
  "CYDN": {
      "icao": "CYDN",
      "iata": "YDN",
      "name": "Dauphin Barker Airport",
      "city": "Dauphin",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 999,
      "lat": 51.1007995605,
      "lon": -100.0520019531,
      "tz": "America\/Winnipeg"
  },
  "CYDO": {
      "icao": "CYDO",
      "iata": "YDO",
      "name": "Dolbeau St Felicien Airport",
      "city": "Dolbeau-St-Felicien",
      "state": "Quebec",
      "country": "CA",
      "elevation": 372,
      "lat": 48.7784996033,
      "lon": -72.375,
      "tz": "America\/Toronto"
  },
  "CYDP": {
      "icao": "CYDP",
      "iata": "YDP",
      "name": "Nain Airport",
      "city": "Nain",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 22,
      "lat": 56.5491981506,
      "lon": -61.6803016663,
      "tz": "America\/Goose_Bay"
  },
  "CYDQ": {
      "icao": "CYDQ",
      "iata": "YDQ",
      "name": "Dawson Creek Airport",
      "city": "Dawson Creek",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2148,
      "lat": 55.7422981262,
      "lon": -120.1829986572,
      "tz": "America\/Dawson_Creek"
  },
  "CYEA": {
      "icao": "CYEA",
      "iata": "",
      "name": "Empress Airport",
      "city": "Empress",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2211,
      "lat": 50.9333000183,
      "lon": -110.0130004883,
      "tz": "America\/Edmonton"
  },
  "CYEE": {
      "icao": "CYEE",
      "iata": "",
      "name": "Huronia Airport",
      "city": "Midland",
      "state": "Ontario",
      "country": "CA",
      "elevation": 770,
      "lat": 44.6833000183,
      "lon": -79.9282989502,
      "tz": "America\/Toronto"
  },
  "CYEG": {
      "icao": "CYEG",
      "iata": "YEG",
      "name": "Edmonton International Airport",
      "city": "Edmonton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2373,
      "lat": 53.3097000122,
      "lon": -113.580001831,
      "tz": "America\/Edmonton"
  },
  "CYEK": {
      "icao": "CYEK",
      "iata": "YEK",
      "name": "Arviat Airport",
      "city": "Arviat",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 32,
      "lat": 61.0942001343,
      "lon": -94.0708007812,
      "tz": "America\/Rankin_Inlet"
  },
  "CYEL": {
      "icao": "CYEL",
      "iata": "YEL",
      "name": "Elliot Lake Municipal Airport",
      "city": "Elliot Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1087,
      "lat": 46.351398468,
      "lon": -82.5614013672,
      "tz": "America\/Nipigon"
  },
  "CYEM": {
      "icao": "CYEM",
      "iata": "YEM",
      "name": "Manitoulin East Municipal Airport",
      "city": "Manitowaning",
      "state": "Ontario",
      "country": "CA",
      "elevation": 869,
      "lat": 45.8428001404,
      "lon": -81.8581008911,
      "tz": "America\/Nipigon"
  },
  "CYEN": {
      "icao": "CYEN",
      "iata": "YEN",
      "name": "Estevan Airport",
      "city": "Estevan",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1905,
      "lat": 49.2103004456,
      "lon": -102.966003418,
      "tz": "America\/Regina"
  },
  "CYER": {
      "icao": "CYER",
      "iata": "YER",
      "name": "Fort Severn Airport",
      "city": "Fort Severn",
      "state": "Ontario",
      "country": "CA",
      "elevation": 48,
      "lat": 56.018901825,
      "lon": -87.6761016846,
      "tz": "America\/Nipigon"
  },
  "CYES": {
      "icao": "CYES",
      "iata": "",
      "name": "Edmundston Airport",
      "city": "Edmundston",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 490,
      "lat": 47.4906005859,
      "lon": -68.4819030762,
      "tz": "America\/Moncton"
  },
  "CYET": {
      "icao": "CYET",
      "iata": "YET",
      "name": "Edson Airport",
      "city": "Edson",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3043,
      "lat": 53.5788993835,
      "lon": -116.464996338,
      "tz": "America\/Edmonton"
  },
  "CYEU": {
      "icao": "CYEU",
      "iata": "YEU",
      "name": "Eureka Airport",
      "city": "Eureka",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 256,
      "lat": 79.9946975708,
      "lon": -85.814201355,
      "tz": "America\/Rankin_Inlet"
  },
  "CYEV": {
      "icao": "CYEV",
      "iata": "YEV",
      "name": "Inuvik Mike Zubko Airport",
      "city": "Inuvik",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 224,
      "lat": 68.3041992188,
      "lon": -133.483001709,
      "tz": "America\/Inuvik"
  },
  "CYEY": {
      "icao": "CYEY",
      "iata": "YEY",
      "name": "Amos Magny Airport",
      "city": "Amos",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1068,
      "lat": 48.5638999939,
      "lon": -78.2497024536,
      "tz": "America\/Toronto"
  },
  "CYFA": {
      "icao": "CYFA",
      "iata": "YFA",
      "name": "Fort Albany Airport",
      "city": "Fort Albany",
      "state": "Ontario",
      "country": "CA",
      "elevation": 48,
      "lat": 52.2014007568,
      "lon": -81.6968994141,
      "tz": "America\/Nipigon"
  },
  "CYFB": {
      "icao": "CYFB",
      "iata": "YFB",
      "name": "Iqaluit Airport",
      "city": "Iqaluit",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 110,
      "lat": 63.756401062,
      "lon": -68.5558013916,
      "tz": "America\/Iqaluit"
  },
  "CYFC": {
      "icao": "CYFC",
      "iata": "YFC",
      "name": "Fredericton Airport",
      "city": "Fredericton",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 68,
      "lat": 45.8689002991,
      "lon": -66.5372009277,
      "tz": "America\/Moncton"
  },
  "CYFD": {
      "icao": "CYFD",
      "iata": "",
      "name": "Brantford Municipal Airport",
      "city": "Brantford",
      "state": "Ontario",
      "country": "CA",
      "elevation": 815,
      "lat": 43.131401062,
      "lon": -80.3424987793,
      "tz": "America\/Toronto"
  },
  "CYFE": {
      "icao": "CYFE",
      "iata": "YFE",
      "name": "Forestville Airport",
      "city": "Forestville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 293,
      "lat": 48.7461013794,
      "lon": -69.0971984863,
      "tz": "America\/Toronto"
  },
  "CYFH": {
      "icao": "CYFH",
      "iata": "YFH",
      "name": "Fort Hope Airport",
      "city": "Fort Hope",
      "state": "Ontario",
      "country": "CA",
      "elevation": 899,
      "lat": 51.5619010925,
      "lon": -87.9077987671,
      "tz": "America\/Nipigon"
  },
  "CYFJ": {
      "icao": "CYFJ",
      "iata": "YTM",
      "name": "La Macaza \/ Mont-Tremblant International Inc Airport",
      "city": "Riviere Rouge",
      "state": "Quebec",
      "country": "CA",
      "elevation": 827,
      "lat": 46.4094009399,
      "lon": -74.7799987793,
      "tz": "America\/Toronto"
  },
  "CYFO": {
      "icao": "CYFO",
      "iata": "YFO",
      "name": "Flin Flon Airport",
      "city": "Flin Flon",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 997,
      "lat": 54.6781005859,
      "lon": -101.6819992065,
      "tz": "America\/Winnipeg"
  },
  "CYFR": {
      "icao": "CYFR",
      "iata": "YFR",
      "name": "Fort Resolution Airport",
      "city": "Fort Resolution",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 526,
      "lat": 61.1808013916,
      "lon": -113.690002441,
      "tz": "America\/Yellowknife"
  },
  "CYFS": {
      "icao": "CYFS",
      "iata": "YFS",
      "name": "Fort Simpson Airport",
      "city": "Fort Simpson",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 555,
      "lat": 61.7602005005,
      "lon": -121.2369995117,
      "tz": "America\/Inuvik"
  },
  "CYFT": {
      "icao": "CYFT",
      "iata": "YMN",
      "name": "Makkovik Airport",
      "city": "Makkovik",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 234,
      "lat": 55.0769004822,
      "lon": -59.1864013672,
      "tz": "America\/Goose_Bay"
  },
  "CYGB": {
      "icao": "CYGB",
      "iata": "YGB",
      "name": "Texada Gillies Bay Airport",
      "city": "Texada",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 326,
      "lat": 49.6941986084,
      "lon": -124.5179977417,
      "tz": "America\/Vancouver"
  },
  "CYGD": {
      "icao": "CYGD",
      "iata": "YGD",
      "name": "Goderich Airport",
      "city": "Goderich",
      "state": "Ontario",
      "country": "CA",
      "elevation": 712,
      "lat": 43.7668991089,
      "lon": -81.7106018066,
      "tz": "America\/Toronto"
  },
  "CYGE": {
      "icao": "CYGE",
      "iata": "",
      "name": "Golden Airport",
      "city": "Golden",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2575,
      "lat": 51.2991981506,
      "lon": -116.9820022583,
      "tz": "America\/Edmonton"
  },
  "CYGH": {
      "icao": "CYGH",
      "iata": "YGH",
      "name": "Fort Good Hope Airport",
      "city": "Fort Good Hope",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 268,
      "lat": 66.2407989502,
      "lon": -128.6510009766,
      "tz": "America\/Inuvik"
  },
  "CYGK": {
      "icao": "CYGK",
      "iata": "YGK",
      "name": "Kingston Norman Rogers Airport",
      "city": "Kingston",
      "state": "Ontario",
      "country": "CA",
      "elevation": 305,
      "lat": 44.2252998352,
      "lon": -76.5969009399,
      "tz": "America\/Toronto"
  },
  "CYGL": {
      "icao": "CYGL",
      "iata": "YGL",
      "name": "La Grande Riviere Airport",
      "city": "La Grande Riviere",
      "state": "Quebec",
      "country": "CA",
      "elevation": 639,
      "lat": 53.6253013611,
      "lon": -77.7042007446,
      "tz": "America\/Toronto"
  },
  "CYGM": {
      "icao": "CYGM",
      "iata": "YGM",
      "name": "Gimli Industrial Park Airport",
      "city": "Gimli",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 753,
      "lat": 50.6281013489,
      "lon": -97.043296814,
      "tz": "America\/Winnipeg"
  },
  "CYGO": {
      "icao": "CYGO",
      "iata": "YGO",
      "name": "Gods Lake Narrows Airport",
      "city": "Gods Lake Narrows",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 617,
      "lat": 54.5588989258,
      "lon": -94.4914016724,
      "tz": "America\/Winnipeg"
  },
  "CYGP": {
      "icao": "CYGP",
      "iata": "YGP",
      "name": "Gaspe (Michel-Pouliot) Airport",
      "city": "Gaspe",
      "state": "Quebec",
      "country": "CA",
      "elevation": 112,
      "lat": 48.7752990723,
      "lon": -64.4785995483,
      "tz": "America\/Toronto"
  },
  "CYGQ": {
      "icao": "CYGQ",
      "iata": "YGQ",
      "name": "Geraldton Greenstone Regional Airport",
      "city": "Geraldton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1144,
      "lat": 49.778301239,
      "lon": -86.9393997192,
      "tz": "America\/Nipigon"
  },
  "CYGR": {
      "icao": "CYGR",
      "iata": "YGR",
      "name": "Iles-de-la-Madeleine Airport",
      "city": "Iles-de-la-Madeleine",
      "state": "Quebec",
      "country": "CA",
      "elevation": 35,
      "lat": 47.4247016907,
      "lon": -61.7780990601,
      "tz": "America\/Halifax"
  },
  "CYGT": {
      "icao": "CYGT",
      "iata": "YGT",
      "name": "Igloolik Airport",
      "city": "Igloolik",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 174,
      "lat": 69.3647003174,
      "lon": -81.8161010742,
      "tz": "America\/Iqaluit"
  },
  "CYGV": {
      "icao": "CYGV",
      "iata": "YGV",
      "name": "Havre St Pierre Airport",
      "city": "Havre St-Pierre",
      "state": "Quebec",
      "country": "CA",
      "elevation": 124,
      "lat": 50.2818984985,
      "lon": -63.6114006042,
      "tz": "America\/Toronto"
  },
  "CYGW": {
      "icao": "CYGW",
      "iata": "YGW",
      "name": "Kuujjuarapik Airport",
      "city": "Kuujjuarapik",
      "state": "Quebec",
      "country": "CA",
      "elevation": 34,
      "lat": 55.2818984985,
      "lon": -77.765296936,
      "tz": "America\/Iqaluit"
  },
  "CYGX": {
      "icao": "CYGX",
      "iata": "YGX",
      "name": "Gillam Airport",
      "city": "Gillam",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 476,
      "lat": 56.3574981689,
      "lon": -94.7106018066,
      "tz": "America\/Winnipeg"
  },
  "CYGZ": {
      "icao": "CYGZ",
      "iata": "YGZ",
      "name": "Grise Fiord Airport",
      "city": "Grise Fiord",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 146,
      "lat": 76.4261016846,
      "lon": -82.9092025757,
      "tz": "America\/Iqaluit"
  },
  "CYHA": {
      "icao": "CYHA",
      "iata": "YQC",
      "name": "Quaqtaq Airport",
      "city": "Quaqtaq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 103,
      "lat": 61.0463981628,
      "lon": -69.6177978516,
      "tz": "America\/Iqaluit"
  },
  "CYHB": {
      "icao": "CYHB",
      "iata": "YHB",
      "name": "Hudson Bay Airport",
      "city": "Hudson Bay",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1175,
      "lat": 52.8166999817,
      "lon": -102.3109970093,
      "tz": "America\/Regina"
  },
  "CYHD": {
      "icao": "CYHD",
      "iata": "YHD",
      "name": "Dryden Regional Airport",
      "city": "Dryden",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1354,
      "lat": 49.8316993713,
      "lon": -92.7442016602,
      "tz": "America\/Rainy_River"
  },
  "CYHE": {
      "icao": "CYHE",
      "iata": "YHE",
      "name": "Hope Airport",
      "city": "Hope",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 128,
      "lat": 49.3683013916,
      "lon": -121.498001099,
      "tz": "America\/Vancouver"
  },
  "CYHF": {
      "icao": "CYHF",
      "iata": "YHF",
      "name": "Hearst Rene Fontaine Municipal Airport",
      "city": "Hearst",
      "state": "Ontario",
      "country": "CA",
      "elevation": 827,
      "lat": 49.7141990662,
      "lon": -83.6860961914,
      "tz": "America\/Nipigon"
  },
  "CYHH": {
      "icao": "CYHH",
      "iata": "YNS",
      "name": "Nemiscau Airport",
      "city": "Nemiscau",
      "state": "Quebec",
      "country": "CA",
      "elevation": 802,
      "lat": 51.6911010742,
      "lon": -76.135597229,
      "tz": "America\/Toronto"
  },
  "CYHI": {
      "icao": "CYHI",
      "iata": "YHI",
      "name": "Ulukhaktok Holman Airport",
      "city": "Ulukhaktok",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 117,
      "lat": 70.762802124,
      "lon": -117.8059997559,
      "tz": "America\/Yellowknife"
  },
  "CYHK": {
      "icao": "CYHK",
      "iata": "YHK",
      "name": "Gjoa Haven Airport",
      "city": "Gjoa Haven",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 152,
      "lat": 68.635597229,
      "lon": -95.8497009277,
      "tz": "America\/Cambridge_Bay"
  },
  "CYHM": {
      "icao": "CYHM",
      "iata": "YHM",
      "name": "John C. Munro Hamilton International Airport",
      "city": "Hamilton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 780,
      "lat": 43.1735992432,
      "lon": -79.9349975586,
      "tz": "America\/Toronto"
  },
  "CYHN": {
      "icao": "CYHN",
      "iata": "YHN",
      "name": "Hornepayne Municipal Airport",
      "city": "Hornepayne",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1099,
      "lat": 49.1930999756,
      "lon": -84.7589035034,
      "tz": "America\/Nipigon"
  },
  "CYHO": {
      "icao": "CYHO",
      "iata": "YHO",
      "name": "Hopedale Airport",
      "city": "Hopedale",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 39,
      "lat": 55.448299408,
      "lon": -60.2285995483,
      "tz": "America\/Goose_Bay"
  },
  "CYHR": {
      "icao": "CYHR",
      "iata": "YHR",
      "name": "Chevery Airport",
      "city": "Chevery",
      "state": "Quebec",
      "country": "CA",
      "elevation": 39,
      "lat": 50.4688987732,
      "lon": -59.6366996765,
      "tz": "America\/Blanc-Sablon"
  },
  "CYHS": {
      "icao": "CYHS",
      "iata": "",
      "name": "Hanover \/ Saugeen Municipal Airport",
      "city": "Hanover",
      "state": "Ontario",
      "country": "CA",
      "elevation": 939,
      "lat": 44.1582984924,
      "lon": -81.0627975464,
      "tz": "America\/Toronto"
  },
  "CYHT": {
      "icao": "CYHT",
      "iata": "YHT",
      "name": "Haines Junction Airport",
      "city": "Haines Junction",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2150,
      "lat": 60.7891998291,
      "lon": -137.546005249,
      "tz": "America\/Whitehorse"
  },
  "CYHU": {
      "icao": "CYHU",
      "iata": "YHU",
      "name": "Montreal \/ Saint-Hubert Airport",
      "city": "Montreal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 90,
      "lat": 45.5175018311,
      "lon": -73.4169006348,
      "tz": "America\/Toronto"
  },
  "CYHY": {
      "icao": "CYHY",
      "iata": "YHY",
      "name": "Hay River \/ Merlyn Carter Airport",
      "city": "Hay River",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 541,
      "lat": 60.8396987915,
      "lon": -115.782997131,
      "tz": "America\/Yellowknife"
  },
  "CYHZ": {
      "icao": "CYHZ",
      "iata": "YHZ",
      "name": "Halifax \/ Stanfield International Airport",
      "city": "Halifax",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 477,
      "lat": 44.8807983398,
      "lon": -63.5085983276,
      "tz": "America\/Halifax"
  },
  "CYIB": {
      "icao": "CYIB",
      "iata": "YIB",
      "name": "Atikokan Municipal Airport",
      "city": "Atikokan",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1408,
      "lat": 48.7738990784,
      "lon": -91.6386032104,
      "tz": "America\/Atikokan"
  },
  "CYID": {
      "icao": "CYID",
      "iata": "YDG",
      "name": "Digby \/ Annapolis Regional Airport",
      "city": "Digby",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 499,
      "lat": 44.5458450365,
      "lon": -65.7854247093,
      "tz": "America\/Halifax"
  },
  "CYIF": {
      "icao": "CYIF",
      "iata": "YIF",
      "name": "St Augustin Airport",
      "city": "St-Augustin",
      "state": "Quebec",
      "country": "CA",
      "elevation": 20,
      "lat": 51.2117004395,
      "lon": -58.6582984924,
      "tz": "America\/Blanc-Sablon"
  },
  "CYIK": {
      "icao": "CYIK",
      "iata": "YIK",
      "name": "Ivujivik Airport",
      "city": "Ivujivik",
      "state": "Quebec",
      "country": "CA",
      "elevation": 126,
      "lat": 62.417301178,
      "lon": -77.9253005981,
      "tz": "America\/Iqaluit"
  },
  "CYIO": {
      "icao": "CYIO",
      "iata": "YIO",
      "name": "Pond Inlet Airport",
      "city": "Pond Inlet",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 181,
      "lat": 72.6832962036,
      "lon": -77.9666976929,
      "tz": "America\/Iqaluit"
  },
  "CYIV": {
      "icao": "CYIV",
      "iata": "YIV",
      "name": "Island Lake Airport",
      "city": "Island Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 770,
      "lat": 53.8572006226,
      "lon": -94.6536026001,
      "tz": "America\/Winnipeg"
  },
  "CYJA": {
      "icao": "CYJA",
      "iata": "YJA",
      "name": "Jasper Airport",
      "city": "Jasper",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3350,
      "lat": 52.9967002869,
      "lon": -118.0589981079,
      "tz": "America\/Edmonton"
  },
  "CYJF": {
      "icao": "CYJF",
      "iata": "YJF",
      "name": "Fort Liard Airport",
      "city": "Fort Liard",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 708,
      "lat": 60.2358016968,
      "lon": -123.46900177,
      "tz": "America\/Inuvik"
  },
  "CYJM": {
      "icao": "CYJM",
      "iata": "",
      "name": "Fort St James Airport",
      "city": "Fort St. James",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2364,
      "lat": 54.3972015381,
      "lon": -124.2630004883,
      "tz": "America\/Vancouver"
  },
  "CYJN": {
      "icao": "CYJN",
      "iata": "YJN",
      "name": "St Jean Airport",
      "city": "St Jean",
      "state": "Quebec",
      "country": "CA",
      "elevation": 136,
      "lat": 45.2943992615,
      "lon": -73.2810974121,
      "tz": "America\/Toronto"
  },
  "CYJP": {
      "icao": "CYJP",
      "iata": "",
      "name": "Fort Providence Airport",
      "city": "Fort Providence",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 524,
      "lat": 61.3194007874,
      "lon": -117.6060028076,
      "tz": "America\/Yellowknife"
  },
  "CYJQ": {
      "icao": "CYJQ",
      "iata": "",
      "name": "Denny Island Airport",
      "city": "Bella Bella",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 162,
      "lat": 52.1397018433,
      "lon": -128.063995361,
      "tz": "America\/Vancouver"
  },
  "CYJT": {
      "icao": "CYJT",
      "iata": "YJT",
      "name": "Stephenville Airport",
      "city": "Stephenville",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 84,
      "lat": 48.5442008972,
      "lon": -58.5499992371,
      "tz": "America\/St_Johns"
  },
  "CYKA": {
      "icao": "CYKA",
      "iata": "YKA",
      "name": "Kamloops Airport",
      "city": "Kamloops",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1133,
      "lat": 50.7022018433,
      "lon": -120.4440002441,
      "tz": "America\/Vancouver"
  },
  "CYKC": {
      "icao": "CYKC",
      "iata": "YKC",
      "name": "Collins Bay Airport",
      "city": "Collins Bay",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1341,
      "lat": 58.2360992432,
      "lon": -103.6780014038,
      "tz": "America\/Regina"
  },
  "CYKD": {
      "icao": "CYKD",
      "iata": "LAK",
      "name": "Aklavik Airport",
      "city": "Aklavik",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 23,
      "lat": 68.2232971191,
      "lon": -135.0059967041,
      "tz": "America\/Inuvik"
  },
  "CYKF": {
      "icao": "CYKF",
      "iata": "YKF",
      "name": "Waterloo Airport",
      "city": "Kitchener",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1055,
      "lat": 43.4608001709,
      "lon": -80.3786010742,
      "tz": "America\/Toronto"
  },
  "CYKG": {
      "icao": "CYKG",
      "iata": "YWB",
      "name": "Kangiqsujuaq (Wakeham Bay) Airport",
      "city": "Kangiqsujuaq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 501,
      "lat": 61.5886001587,
      "lon": -71.929397583,
      "tz": "America\/Toronto"
  },
  "CYKJ": {
      "icao": "CYKJ",
      "iata": "YKJ",
      "name": "Key Lake Airport",
      "city": "Key Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1679,
      "lat": 57.2560997009,
      "lon": -105.6179962158,
      "tz": "America\/Regina"
  },
  "CYKL": {
      "icao": "CYKL",
      "iata": "YKL",
      "name": "Schefferville Airport",
      "city": "Schefferville",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1709,
      "lat": 54.8053016663,
      "lon": -66.8052978516,
      "tz": "America\/Toronto"
  },
  "CYKO": {
      "icao": "CYKO",
      "iata": "AKV",
      "name": "Akulivik Airport",
      "city": "Akulivik",
      "state": "Quebec",
      "country": "CA",
      "elevation": 75,
      "lat": 60.8185997009,
      "lon": -78.1485977173,
      "tz": "America\/Iqaluit"
  },
  "CYKQ": {
      "icao": "CYKQ",
      "iata": "YKQ",
      "name": "Waskaganish Airport",
      "city": "Waskaganish",
      "state": "Quebec",
      "country": "CA",
      "elevation": 80,
      "lat": 51.4733009338,
      "lon": -78.7583007813,
      "tz": "America\/Toronto"
  },
  "CYKX": {
      "icao": "CYKX",
      "iata": "YKX",
      "name": "Kirkland Lake Airport",
      "city": "Kirkland Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1157,
      "lat": 48.2103004456,
      "lon": -79.9813995361,
      "tz": "America\/Toronto"
  },
  "CYKY": {
      "icao": "CYKY",
      "iata": "YKY",
      "name": "Kindersley Airport",
      "city": "Kindersley",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2277,
      "lat": 51.5175018311,
      "lon": -109.180999756,
      "tz": "America\/Swift_Current"
  },
  "CYKZ": {
      "icao": "CYKZ",
      "iata": "YKZ",
      "name": "Buttonville Municipal Airport",
      "city": "Toronto",
      "state": "Ontario",
      "country": "CA",
      "elevation": 650,
      "lat": 43.8622016907,
      "lon": -79.3700027466,
      "tz": "America\/Toronto"
  },
  "CYLA": {
      "icao": "CYLA",
      "iata": "YPJ",
      "name": "Aupaluk Airport",
      "city": "Aupaluk",
      "state": "Quebec",
      "country": "CA",
      "elevation": 119,
      "lat": 59.2966995239,
      "lon": -69.5997009277,
      "tz": "America\/Toronto"
  },
  "CYLB": {
      "icao": "CYLB",
      "iata": "YLB",
      "name": "Lac La Biche Airport",
      "city": "Lac La Biche",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1884,
      "lat": 54.7703018188,
      "lon": -112.0319976807,
      "tz": "America\/Edmonton"
  },
  "CYLC": {
      "icao": "CYLC",
      "iata": "YLC",
      "name": "Kimmirut Airport",
      "city": "Kimmirut",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 175,
      "lat": 62.8499984741,
      "lon": -69.8833007812,
      "tz": "America\/Iqaluit"
  },
  "CYLD": {
      "icao": "CYLD",
      "iata": "YLD",
      "name": "Chapleau Airport",
      "city": "Chapleau",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1470,
      "lat": 47.8199996948,
      "lon": -83.3467025757,
      "tz": "America\/Toronto"
  },
  "CYLH": {
      "icao": "CYLH",
      "iata": "YLH",
      "name": "Lansdowne House Airport",
      "city": "Lansdowne House",
      "state": "Ontario",
      "country": "CA",
      "elevation": 834,
      "lat": 52.1955986023,
      "lon": -87.9341964722,
      "tz": "America\/Nipigon"
  },
  "CYLI": {
      "icao": "CYLI",
      "iata": "",
      "name": "Lillooet Airport",
      "city": "Lillooet",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1320,
      "lat": 50.6747016907,
      "lon": -121.893997192,
      "tz": "America\/Vancouver"
  },
  "CYLJ": {
      "icao": "CYLJ",
      "iata": "YLJ",
      "name": "Meadow Lake Airport",
      "city": "Meadow Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1576,
      "lat": 54.1253013611,
      "lon": -108.5230026245,
      "tz": "America\/Swift_Current"
  },
  "CYLK": {
      "icao": "CYLK",
      "iata": "YSG",
      "name": "Lutselk'e Airport",
      "city": "Lutselk'e",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 596,
      "lat": 62.4183006287,
      "lon": -110.6819992065,
      "tz": "America\/Yellowknife"
  },
  "CYLL": {
      "icao": "CYLL",
      "iata": "YLL",
      "name": "Lloydminster Airport",
      "city": "Lloydminster",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2193,
      "lat": 53.3092002869,
      "lon": -110.0729980469,
      "tz": "America\/Edmonton"
  },
  "CYLQ": {
      "icao": "CYLQ",
      "iata": "YLQ",
      "name": "La Tuque Airport",
      "city": "La Tuque",
      "state": "Quebec",
      "country": "CA",
      "elevation": 548,
      "lat": 47.4096984863,
      "lon": -72.7889022827,
      "tz": "America\/Toronto"
  },
  "CYLR": {
      "icao": "CYLR",
      "iata": "YLR",
      "name": "Leaf Rapids Airport",
      "city": "Leaf Rapids",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 959,
      "lat": 56.5133018494,
      "lon": -99.9852981567,
      "tz": "America\/Winnipeg"
  },
  "CYLS": {
      "icao": "CYLS",
      "iata": "YLK",
      "name": "Barrie-Orillia (Lake Simcoe Regional Airport)",
      "city": "Barrie-Orillia",
      "state": "Ontario",
      "country": "CA",
      "elevation": 972,
      "lat": 44.4852981567,
      "lon": -79.5556030273,
      "tz": "America\/Toronto"
  },
  "CYLT": {
      "icao": "CYLT",
      "iata": "YLT",
      "name": "Alert Airport",
      "city": "Alert",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 100,
      "lat": 82.5177993774,
      "lon": -62.2806015015,
      "tz": "America\/Pangnirtung"
  },
  "CYLU": {
      "icao": "CYLU",
      "iata": "XGR",
      "name": "Kangiqsualujjuaq (Georges River) Airport",
      "city": "Kangiqsualujjuaq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 215,
      "lat": 58.7113990784,
      "lon": -65.9927978516,
      "tz": "America\/Toronto"
  },
  "CYLW": {
      "icao": "CYLW",
      "iata": "YLW",
      "name": "Kelowna Airport",
      "city": "Kelowna",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1409,
      "lat": 49.9561004639,
      "lon": -119.3779983521,
      "tz": "America\/Vancouver"
  },
  "CYMA": {
      "icao": "CYMA",
      "iata": "YMA",
      "name": "Mayo Airport",
      "city": "Mayo",
      "state": "Yukon",
      "country": "CA",
      "elevation": 1653,
      "lat": 63.6164016724,
      "lon": -135.8679962158,
      "tz": "America\/Whitehorse"
  },
  "CYME": {
      "icao": "CYME",
      "iata": "YME",
      "name": "Matane Airport",
      "city": "Matane",
      "state": "Quebec",
      "country": "CA",
      "elevation": 102,
      "lat": 48.8568992615,
      "lon": -67.4533004761,
      "tz": "America\/Toronto"
  },
  "CYMG": {
      "icao": "CYMG",
      "iata": "YMG",
      "name": "Manitouwadge Airport",
      "city": "Manitouwadge",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1198,
      "lat": 49.0839004517,
      "lon": -85.8606033325,
      "tz": "America\/Nipigon"
  },
  "CYMH": {
      "icao": "CYMH",
      "iata": "YMH",
      "name": "Mary's Harbour Airport",
      "city": "Mary's Harbour",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 38,
      "lat": 52.3027992249,
      "lon": -55.8471984863,
      "tz": "America\/St_Johns"
  },
  "CYMJ": {
      "icao": "CYMJ",
      "iata": "YMJ",
      "name": "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
      "city": "Moose Jaw",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1892,
      "lat": 50.3302993774,
      "lon": -105.5589981079,
      "tz": "America\/Regina"
  },
  "CYML": {
      "icao": "CYML",
      "iata": "YML",
      "name": "Charlevoix Airport",
      "city": "Charlevoix",
      "state": "Quebec",
      "country": "CA",
      "elevation": 977,
      "lat": 47.5974998474,
      "lon": -70.2238998413,
      "tz": "America\/Toronto"
  },
  "CYMM": {
      "icao": "CYMM",
      "iata": "YMM",
      "name": "Fort McMurray Airport",
      "city": "Fort McMurray",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1211,
      "lat": 56.653301239,
      "lon": -111.222000122,
      "tz": "America\/Edmonton"
  },
  "CYMO": {
      "icao": "CYMO",
      "iata": "YMO",
      "name": "Moosonee Airport",
      "city": "Moosonee",
      "state": "Ontario",
      "country": "CA",
      "elevation": 30,
      "lat": 51.2910995483,
      "lon": -80.6078033447,
      "tz": "America\/Nipigon"
  },
  "CYMT": {
      "icao": "CYMT",
      "iata": "YMT",
      "name": "Chapais Airport",
      "city": "Chibougamau",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1270,
      "lat": 49.771900177,
      "lon": -74.5280990601,
      "tz": "America\/Toronto"
  },
  "CYMU": {
      "icao": "CYMU",
      "iata": "YUD",
      "name": "Umiujaq Airport",
      "city": "Umiujaq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 250,
      "lat": 56.5360984802,
      "lon": -76.5183029175,
      "tz": "America\/Iqaluit"
  },
  "CYMW": {
      "icao": "CYMW",
      "iata": "YMW",
      "name": "Maniwaki Airport",
      "city": "Maniwaki",
      "state": "Quebec",
      "country": "CA",
      "elevation": 656,
      "lat": 46.2728004456,
      "lon": -75.9906005859,
      "tz": "America\/Toronto"
  },
  "CYMX": {
      "icao": "CYMX",
      "iata": "YMX",
      "name": "Montreal International (Mirabel) Airport",
      "city": "Montreal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 270,
      "lat": 45.6795005798,
      "lon": -74.0386962891,
      "tz": "America\/Toronto"
  },
  "CYMY": {
      "icao": "CYMY",
      "iata": "",
      "name": "Ear Falls Airport",
      "city": "Ear Falls",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1269,
      "lat": 50.7178001404,
      "lon": -93.3835983276,
      "tz": "America\/Rainy_River"
  },
  "CYNA": {
      "icao": "CYNA",
      "iata": "YNA",
      "name": "Natashquan Airport",
      "city": "Natashquan",
      "state": "Quebec",
      "country": "CA",
      "elevation": 39,
      "lat": 50.1899986267,
      "lon": -61.7891998291,
      "tz": "America\/Toronto"
  },
  "CYNC": {
      "icao": "CYNC",
      "iata": "YNC",
      "name": "Wemindji Airport",
      "city": "Wemindji",
      "state": "Quebec",
      "country": "CA",
      "elevation": 66,
      "lat": 53.0106010437,
      "lon": -78.8311004639,
      "tz": "America\/Toronto"
  },
  "CYND": {
      "icao": "CYND",
      "iata": "YND",
      "name": "Ottawa \/ Gatineau Airport",
      "city": "Gatineau",
      "state": "Quebec",
      "country": "CA",
      "elevation": 211,
      "lat": 45.5217018127,
      "lon": -75.5635986328,
      "tz": "America\/Toronto"
  },
  "CYNE": {
      "icao": "CYNE",
      "iata": "YNE",
      "name": "Norway House Airport",
      "city": "Norway House",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 734,
      "lat": 53.9583015442,
      "lon": -97.8442001343,
      "tz": "America\/Winnipeg"
  },
  "CYNH": {
      "icao": "CYNH",
      "iata": "YNH",
      "name": "Hudsons Hope Airport",
      "city": "Hudson's Hope",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2220,
      "lat": 56.0355987549,
      "lon": -121.9759979248,
      "tz": "America\/Dawson_Creek"
  },
  "CYNJ": {
      "icao": "CYNJ",
      "iata": "YLY",
      "name": "Langley Airport",
      "city": "Langley",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 34,
      "lat": 49.1007995605,
      "lon": -122.6309967041,
      "tz": "America\/Vancouver"
  },
  "CYNL": {
      "icao": "CYNL",
      "iata": "YNL",
      "name": "Points North Landing Airport",
      "city": "Points North Landing",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1605,
      "lat": 58.2766990662,
      "lon": -104.0820007324,
      "tz": "America\/Regina"
  },
  "CYNM": {
      "icao": "CYNM",
      "iata": "YNM",
      "name": "Matagami Airport",
      "city": "Matagami",
      "state": "Quebec",
      "country": "CA",
      "elevation": 918,
      "lat": 49.7616996765,
      "lon": -77.8028030396,
      "tz": "America\/Toronto"
  },
  "CYNN": {
      "icao": "CYNN",
      "iata": "YNN",
      "name": "Nejanilini Lake Airport",
      "city": "Nejanilini Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1000,
      "lat": 59.4874992371,
      "lon": -97.7802963257,
      "tz": "America\/Winnipeg"
  },
  "CYNR": {
      "icao": "CYNR",
      "iata": "HZP",
      "name": "Fort Mackay \/ Horizon Airport",
      "city": "Fort Mackay",
      "state": "Alberta",
      "country": "CA",
      "elevation": 916,
      "lat": 57.3816986084,
      "lon": -111.700996399,
      "tz": "America\/Edmonton"
  },
  "CYOA": {
      "icao": "CYOA",
      "iata": "YOA",
      "name": "Ekati Airport",
      "city": "Ekati",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 1536,
      "lat": 64.6988983154,
      "lon": -110.614997864,
      "tz": "America\/Yellowknife"
  },
  "CYOC": {
      "icao": "CYOC",
      "iata": "YOC",
      "name": "Old Crow Airport",
      "city": "Old Crow",
      "state": "Yukon",
      "country": "CA",
      "elevation": 824,
      "lat": 67.570602417,
      "lon": -139.8390045166,
      "tz": "America\/Dawson"
  },
  "CYOD": {
      "icao": "CYOD",
      "iata": "YOD",
      "name": "CFB Cold Lake",
      "city": "Cold Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1775,
      "lat": 54.4049987793,
      "lon": -110.2789993286,
      "tz": "America\/Edmonton"
  },
  "CYOH": {
      "icao": "CYOH",
      "iata": "YOH",
      "name": "Oxford House Airport",
      "city": "Oxford House",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 663,
      "lat": 54.9333000183,
      "lon": -95.2789001465,
      "tz": "America\/Winnipeg"
  },
  "CYOJ": {
      "icao": "CYOJ",
      "iata": "YOJ",
      "name": "High Level Airport",
      "city": "High Level",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1110,
      "lat": 58.6213989258,
      "lon": -117.1650009155,
      "tz": "America\/Edmonton"
  },
  "CYOO": {
      "icao": "CYOO",
      "iata": "YOO",
      "name": "Oshawa Airport",
      "city": "Oshawa",
      "state": "Ontario",
      "country": "CA",
      "elevation": 460,
      "lat": 43.9227981567,
      "lon": -78.8949966431,
      "tz": "America\/Toronto"
  },
  "CYOP": {
      "icao": "CYOP",
      "iata": "YOP",
      "name": "Rainbow Lake Airport",
      "city": "Rainbow Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1759,
      "lat": 58.4914016724,
      "lon": -119.4079971313,
      "tz": "America\/Edmonton"
  },
  "CYOS": {
      "icao": "CYOS",
      "iata": "YOS",
      "name": "Owen Sound \/ Billy Bishop Regional Airport",
      "city": "Owen Sound",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1007,
      "lat": 44.5903015137,
      "lon": -80.8375015259,
      "tz": "America\/Toronto"
  },
  "CYOW": {
      "icao": "CYOW",
      "iata": "YOW",
      "name": "Ottawa Macdonald-Cartier International Airport",
      "city": "Ottawa",
      "state": "Ontario",
      "country": "CA",
      "elevation": 374,
      "lat": 45.3224983215,
      "lon": -75.6691970825,
      "tz": "America\/Toronto"
  },
  "CYPA": {
      "icao": "CYPA",
      "iata": "YPA",
      "name": "Prince Albert Glass Field",
      "city": "Prince Albert",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1405,
      "lat": 53.2141990662,
      "lon": -105.672996521,
      "tz": "America\/Regina"
  },
  "CYPC": {
      "icao": "CYPC",
      "iata": "YPC",
      "name": "Paulatuk (Nora Aliqatchialuk Ruben) Airport",
      "city": "Paulatuk",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 15,
      "lat": 69.3608381154,
      "lon": -124.075469971,
      "tz": "America\/Inuvik"
  },
  "CYPD": {
      "icao": "CYPD",
      "iata": "YPS",
      "name": "Port Hawkesbury Airport",
      "city": "Port Hawkesbury",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 377,
      "lat": 45.6567001343,
      "lon": -61.3680992126,
      "tz": "America\/Halifax"
  },
  "CYPE": {
      "icao": "CYPE",
      "iata": "YPE",
      "name": "Peace River Airport",
      "city": "Peace River",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1873,
      "lat": 56.2268981934,
      "lon": -117.4469985962,
      "tz": "America\/Edmonton"
  },
  "CYPG": {
      "icao": "CYPG",
      "iata": "YPG",
      "name": "Southport Airport",
      "city": "Portage",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 885,
      "lat": 49.9030990601,
      "lon": -98.2739028931,
      "tz": "America\/Winnipeg"
  },
  "CYPH": {
      "icao": "CYPH",
      "iata": "YPH",
      "name": "Inukjuak Airport",
      "city": "Inukjuak",
      "state": "Quebec",
      "country": "CA",
      "elevation": 83,
      "lat": 58.4719009399,
      "lon": -78.0768966675,
      "tz": "America\/Toronto"
  },
  "CYPK": {
      "icao": "CYPK",
      "iata": "",
      "name": "Pitt Meadows Airport",
      "city": "Pitt Meadows",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 11,
      "lat": 49.2160987854,
      "lon": -122.7099990845,
      "tz": "America\/Vancouver"
  },
  "CYPL": {
      "icao": "CYPL",
      "iata": "YPL",
      "name": "Pickle Lake Airport",
      "city": "Pickle Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1267,
      "lat": 51.4463996887,
      "lon": -90.2142028809,
      "tz": "America\/Atikokan"
  },
  "CYPM": {
      "icao": "CYPM",
      "iata": "YPM",
      "name": "Pikangikum Airport",
      "city": "Pikangikum",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1114,
      "lat": 51.8196983337,
      "lon": -93.9732971191,
      "tz": "America\/Rainy_River"
  },
  "CYPN": {
      "icao": "CYPN",
      "iata": "YPN",
      "name": "Port Menier Airport",
      "city": "Port-Menier",
      "state": "Quebec",
      "country": "CA",
      "elevation": 167,
      "lat": 49.8363990784,
      "lon": -64.2885971069,
      "tz": "America\/Toronto"
  },
  "CYPO": {
      "icao": "CYPO",
      "iata": "YPO",
      "name": "Peawanuck Airport",
      "city": "Peawanuck",
      "state": "Ontario",
      "country": "CA",
      "elevation": 173,
      "lat": 54.9880981445,
      "lon": -85.4432983398,
      "tz": "America\/Nipigon"
  },
  "CYPP": {
      "icao": "CYPP",
      "iata": "",
      "name": "Parent Airport",
      "city": "Parent",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1400,
      "lat": 47.9319000244,
      "lon": -74.6081008911,
      "tz": "America\/Toronto"
  },
  "CYPQ": {
      "icao": "CYPQ",
      "iata": "YPQ",
      "name": "Peterborough Airport",
      "city": "Peterborough",
      "state": "Ontario",
      "country": "CA",
      "elevation": 628,
      "lat": 44.2299995422,
      "lon": -78.3632965088,
      "tz": "America\/Toronto"
  },
  "CYPR": {
      "icao": "CYPR",
      "iata": "YPR",
      "name": "Prince Rupert Airport",
      "city": "Prince Rupert",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 116,
      "lat": 54.2860984802,
      "lon": -130.4450073242,
      "tz": "America\/Vancouver"
  },
  "CYPS": {
      "icao": "CYPS",
      "iata": "",
      "name": "Pemberton Airport",
      "city": "Pemberton",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 670,
      "lat": 50.3025016785,
      "lon": -122.7379989624,
      "tz": "America\/Vancouver"
  },
  "CYPT": {
      "icao": "CYPT",
      "iata": "",
      "name": "Pelee Island Airport",
      "city": "Pelee Island",
      "state": "Ontario",
      "country": "CA",
      "elevation": 572,
      "lat": 41.7803993225,
      "lon": -82.6780014038,
      "tz": "America\/Toronto"
  },
  "CYPU": {
      "icao": "CYPU",
      "iata": "",
      "name": "Puntzi Mountain Airport",
      "city": "Puntzi Mountain",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2985,
      "lat": 52.1128005981,
      "lon": -124.1449966431,
      "tz": "America\/Vancouver"
  },
  "CYPW": {
      "icao": "CYPW",
      "iata": "YPW",
      "name": "Powell River Airport",
      "city": "Powell River",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 425,
      "lat": 49.8342018127,
      "lon": -124.5,
      "tz": "America\/Vancouver"
  },
  "CYPX": {
      "icao": "CYPX",
      "iata": "YPX",
      "name": "Puvirnituq Airport",
      "city": "Puvirnituq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 74,
      "lat": 60.0505981445,
      "lon": -77.2869033813,
      "tz": "America\/Toronto"
  },
  "CYPY": {
      "icao": "CYPY",
      "iata": "YPY",
      "name": "Fort Chipewyan Airport",
      "city": "Fort Chipewyan",
      "state": "Alberta",
      "country": "CA",
      "elevation": 761,
      "lat": 58.76720047,
      "lon": -111.1169967651,
      "tz": "America\/Edmonton"
  },
  "CYPZ": {
      "icao": "CYPZ",
      "iata": "YPZ",
      "name": "Burns Lake Airport",
      "city": "Burns Lake",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2343,
      "lat": 54.3763999939,
      "lon": -125.9509963989,
      "tz": "America\/Vancouver"
  },
  "CYQA": {
      "icao": "CYQA",
      "iata": "YQA",
      "name": "Muskoka Airport",
      "city": "Muskoka",
      "state": "Ontario",
      "country": "CA",
      "elevation": 925,
      "lat": 44.9747009277,
      "lon": -79.3032989502,
      "tz": "America\/Toronto"
  },
  "CYQB": {
      "icao": "CYQB",
      "iata": "YQB",
      "name": "Quebec Jean Lesage International Airport",
      "city": "Quebec",
      "state": "Quebec",
      "country": "CA",
      "elevation": 244,
      "lat": 46.7910995483,
      "lon": -71.3933029175,
      "tz": "America\/Toronto"
  },
  "CYQD": {
      "icao": "CYQD",
      "iata": "YQD",
      "name": "The Pas Airport",
      "city": "The Pas",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 887,
      "lat": 53.9714012146,
      "lon": -101.091003418,
      "tz": "America\/Winnipeg"
  },
  "CYQF": {
      "icao": "CYQF",
      "iata": "YQF",
      "name": "Red Deer Regional Airport",
      "city": "Red Deer",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2968,
      "lat": 52.1822013855,
      "lon": -113.8939971924,
      "tz": "America\/Edmonton"
  },
  "CYQG": {
      "icao": "CYQG",
      "iata": "YQG",
      "name": "Windsor Airport",
      "city": "Windsor",
      "state": "Ontario",
      "country": "CA",
      "elevation": 622,
      "lat": 42.2756004333,
      "lon": -82.9555969238,
      "tz": "America\/Toronto"
  },
  "CYQH": {
      "icao": "CYQH",
      "iata": "YQH",
      "name": "Watson Lake Airport",
      "city": "Watson Lake",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2255,
      "lat": 60.1164016724,
      "lon": -128.8220062256,
      "tz": "America\/Whitehorse"
  },
  "CYQI": {
      "icao": "CYQI",
      "iata": "YQI",
      "name": "Yarmouth Airport",
      "city": "Yarmouth",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 141,
      "lat": 43.8269004822,
      "lon": -66.0880966187,
      "tz": "America\/Halifax"
  },
  "CYQK": {
      "icao": "CYQK",
      "iata": "YQK",
      "name": "Kenora Airport",
      "city": "Kenora",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1332,
      "lat": 49.7882995605,
      "lon": -94.3630981445,
      "tz": "America\/Rainy_River"
  },
  "CYQL": {
      "icao": "CYQL",
      "iata": "YQL",
      "name": "Lethbridge County Airport",
      "city": "Lethbridge",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3048,
      "lat": 49.6302986145,
      "lon": -112.800003052,
      "tz": "America\/Edmonton"
  },
  "CYQM": {
      "icao": "CYQM",
      "iata": "YQM",
      "name": "Greater Moncton International Airport",
      "city": "Moncton",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 232,
      "lat": 46.1122016907,
      "lon": -64.6785964966,
      "tz": "America\/Moncton"
  },
  "CYQN": {
      "icao": "CYQN",
      "iata": "YQN",
      "name": "Nakina Airport",
      "city": "Nakina",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1057,
      "lat": 50.182800293,
      "lon": -86.6964035034,
      "tz": "America\/Nipigon"
  },
  "CYQQ": {
      "icao": "CYQQ",
      "iata": "YQQ",
      "name": "Comox Airport",
      "city": "Comox",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 84,
      "lat": 49.7108001709,
      "lon": -124.8870010376,
      "tz": "America\/Vancouver"
  },
  "CYQR": {
      "icao": "CYQR",
      "iata": "YQR",
      "name": "Regina International Airport",
      "city": "Regina",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1894,
      "lat": 50.4319000244,
      "lon": -104.6660003662,
      "tz": "America\/Regina"
  },
  "CYQS": {
      "icao": "CYQS",
      "iata": "YQS",
      "name": "St Thomas Municipal Airport",
      "city": "St Thomas",
      "state": "Ontario",
      "country": "CA",
      "elevation": 778,
      "lat": 42.7700004578,
      "lon": -81.1108016968,
      "tz": "America\/Toronto"
  },
  "CYQT": {
      "icao": "CYQT",
      "iata": "YQT",
      "name": "Thunder Bay Airport",
      "city": "Thunder Bay",
      "state": "Ontario",
      "country": "CA",
      "elevation": 653,
      "lat": 48.3718986511,
      "lon": -89.3238983154,
      "tz": "America\/Thunder_Bay"
  },
  "CYQU": {
      "icao": "CYQU",
      "iata": "YQU",
      "name": "Grande Prairie Airport",
      "city": "Grande Prairie",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2195,
      "lat": 55.1796989441,
      "lon": -118.885002136,
      "tz": "America\/Edmonton"
  },
  "CYQV": {
      "icao": "CYQV",
      "iata": "YQV",
      "name": "Yorkton Municipal Airport",
      "city": "Yorkton",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1635,
      "lat": 51.2647018433,
      "lon": -102.4619979858,
      "tz": "America\/Regina"
  },
  "CYQW": {
      "icao": "CYQW",
      "iata": "YQW",
      "name": "North Battleford Airport",
      "city": "North Battleford",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1799,
      "lat": 52.7691993713,
      "lon": -108.2440032959,
      "tz": "America\/Swift_Current"
  },
  "CYQX": {
      "icao": "CYQX",
      "iata": "YQX",
      "name": "Gander International Airport",
      "city": "Gander",
      "state": "Newfoundland and Labrador",
      "country": "CA",
      "elevation": 496,
      "lat": 48.9369010925,
      "lon": -54.5680999756,
      "tz": "America\/St_Johns"
  },
  "CYQY": {
      "icao": "CYQY",
      "iata": "YQY",
      "name": "Sydney \/ J.A. Douglas McCurdy Airport",
      "city": "Sydney",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 203,
      "lat": 46.1613998413,
      "lon": -60.0477981567,
      "tz": "America\/Glace_Bay"
  },
  "CYQZ": {
      "icao": "CYQZ",
      "iata": "YQZ",
      "name": "Quesnel Airport",
      "city": "Quesnel",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1789,
      "lat": 53.0261001587,
      "lon": -122.5100021362,
      "tz": "America\/Vancouver"
  },
  "CYRA": {
      "icao": "CYRA",
      "iata": "YRA",
      "name": "Rae Lakes Airport",
      "city": "Gameti",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 723,
      "lat": 64.1160964966,
      "lon": -117.3099975586,
      "tz": "America\/Yellowknife"
  },
  "CYRB": {
      "icao": "CYRB",
      "iata": "YRB",
      "name": "Resolute Bay Airport",
      "city": "Resolute Bay",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 215,
      "lat": 74.7169036865,
      "lon": -94.9693984985,
      "tz": "America\/Resolute"
  },
  "CYRC": {
      "icao": "CYRC",
      "iata": "",
      "name": "Chicoutimi St Honore Airport",
      "city": "Chicoutimi",
      "state": "Quebec",
      "country": "CA",
      "elevation": 543,
      "lat": 48.5208015442,
      "lon": -71.0505981445,
      "tz": "America\/Toronto"
  },
  "CYRI": {
      "icao": "CYRI",
      "iata": "YRI",
      "name": "Riviere-du-Loup Airport",
      "city": "Riviere-du-Loup",
      "state": "Quebec",
      "country": "CA",
      "elevation": 427,
      "lat": 47.7644004822,
      "lon": -69.5847015381,
      "tz": "America\/Toronto"
  },
  "CYRJ": {
      "icao": "CYRJ",
      "iata": "YRJ",
      "name": "Roberval Airport",
      "city": "Roberval",
      "state": "Quebec",
      "country": "CA",
      "elevation": 586,
      "lat": 48.5200004578,
      "lon": -72.2656021118,
      "tz": "America\/Toronto"
  },
  "CYRL": {
      "icao": "CYRL",
      "iata": "YRL",
      "name": "Red Lake Airport",
      "city": "Red Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1265,
      "lat": 51.0668983459,
      "lon": -93.7930984497,
      "tz": "America\/Rainy_River"
  },
  "CYRM": {
      "icao": "CYRM",
      "iata": "YRM",
      "name": "Rocky Mountain House Airport",
      "city": "Rocky Mountain House",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3244,
      "lat": 52.4296989441,
      "lon": -114.9039993286,
      "tz": "America\/Edmonton"
  },
  "CYRO": {
      "icao": "CYRO",
      "iata": "YRO",
      "name": "Ottawa \/ Rockcliffe Airport",
      "city": "Ottawa",
      "state": "Ontario",
      "country": "CA",
      "elevation": 188,
      "lat": 45.4603004456,
      "lon": -75.6461029053,
      "tz": "America\/Toronto"
  },
  "CYRP": {
      "icao": "CYRP",
      "iata": "YRP",
      "name": "Ottawa \/ Carp Airport",
      "city": "Carp",
      "state": "Ontario",
      "country": "CA",
      "elevation": 382,
      "lat": 45.3191986084,
      "lon": -76.0222015381,
      "tz": "America\/Toronto"
  },
  "CYRQ": {
      "icao": "CYRQ",
      "iata": "YRQ",
      "name": "Trois-Rivieres Airport",
      "city": "Trois-Rivieres",
      "state": "Quebec",
      "country": "CA",
      "elevation": 199,
      "lat": 46.3527984619,
      "lon": -72.679397583,
      "tz": "America\/Toronto"
  },
  "CYRS": {
      "icao": "CYRS",
      "iata": "YRS",
      "name": "Red Sucker Lake Airport",
      "city": "Red Sucker Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 729,
      "lat": 54.1671981812,
      "lon": -93.5571975708,
      "tz": "America\/Winnipeg"
  },
  "CYRT": {
      "icao": "CYRT",
      "iata": "YRT",
      "name": "Rankin Inlet Airport",
      "city": "Rankin Inlet",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 94,
      "lat": 62.8114013672,
      "lon": -92.1157989502,
      "tz": "America\/Rankin_Inlet"
  },
  "CYRV": {
      "icao": "CYRV",
      "iata": "YRV",
      "name": "Revelstoke Airport",
      "city": "Revelstoke",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1459,
      "lat": 50.9667015076,
      "lon": -118.182998657,
      "tz": "America\/Vancouver"
  },
  "CYSA": {
      "icao": "CYSA",
      "iata": "",
      "name": "Stratford Municipal Airport",
      "city": "Stratford",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1215,
      "lat": 43.415599823,
      "lon": -80.9344024658,
      "tz": "America\/Toronto"
  },
  "CYSB": {
      "icao": "CYSB",
      "iata": "YSB",
      "name": "Sudbury Airport",
      "city": "Sudbury",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1141,
      "lat": 46.625,
      "lon": -80.7988967896,
      "tz": "America\/Toronto"
  },
  "CYSC": {
      "icao": "CYSC",
      "iata": "YSC",
      "name": "Sherbrooke Airport",
      "city": "Sherbrooke",
      "state": "Quebec",
      "country": "CA",
      "elevation": 792,
      "lat": 45.4385986328,
      "lon": -71.6913986206,
      "tz": "America\/Toronto"
  },
  "CYSE": {
      "icao": "CYSE",
      "iata": "YSE",
      "name": "Squamish Airport",
      "city": "Squamish",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 171,
      "lat": 49.7817001343,
      "lon": -123.1620025635,
      "tz": "America\/Vancouver"
  },
  "CYSF": {
      "icao": "CYSF",
      "iata": "YSF",
      "name": "Stony Rapids Airport",
      "city": "Stony Rapids",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 805,
      "lat": 59.2503013611,
      "lon": -105.841003418,
      "tz": "America\/Regina"
  },
  "CYSG": {
      "icao": "CYSG",
      "iata": "",
      "name": "St Georges Airport",
      "city": "St Georges",
      "state": "Quebec",
      "country": "CA",
      "elevation": 893,
      "lat": 46.0964012146,
      "lon": -70.7146987915,
      "tz": "America\/Toronto"
  },
  "CYSH": {
      "icao": "CYSH",
      "iata": "YSH",
      "name": "Smiths Falls-Montague (Russ Beach) Airport",
      "city": "Smiths Falls",
      "state": "Ontario",
      "country": "CA",
      "elevation": 416,
      "lat": 44.9458007813,
      "lon": -75.9405975342,
      "tz": "America\/Toronto"
  },
  "CYSJ": {
      "icao": "CYSJ",
      "iata": "YSJ",
      "name": "Saint John Airport",
      "city": "Saint John",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 357,
      "lat": 45.3161010742,
      "lon": -65.890296936,
      "tz": "America\/Moncton"
  },
  "CYSK": {
      "icao": "CYSK",
      "iata": "YSK",
      "name": "Sanikiluaq Airport",
      "city": "Sanikiluaq",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 104,
      "lat": 56.5377998352,
      "lon": -79.2466964722,
      "tz": "America\/Iqaluit"
  },
  "CYSL": {
      "icao": "CYSL",
      "iata": "YSL",
      "name": "St Leonard Airport",
      "city": "St Leonard",
      "state": "New Brunswick",
      "country": "CA",
      "elevation": 793,
      "lat": 47.1575012207,
      "lon": -67.8347015381,
      "tz": "America\/Moncton"
  },
  "CYSM": {
      "icao": "CYSM",
      "iata": "YSM",
      "name": "Fort Smith Airport",
      "city": "Fort Smith",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 671,
      "lat": 60.0203018188,
      "lon": -111.9619979858,
      "tz": "America\/Edmonton"
  },
  "CYSN": {
      "icao": "CYSN",
      "iata": "YCM",
      "name": "Niagara District Airport",
      "city": "St Catharines",
      "state": "Ontario",
      "country": "CA",
      "elevation": 321,
      "lat": 43.1916999817,
      "lon": -79.1716995239,
      "tz": "America\/Toronto"
  },
  "CYSP": {
      "icao": "CYSP",
      "iata": "YSP",
      "name": "Marathon Airport",
      "city": "Marathon",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1035,
      "lat": 48.7552986145,
      "lon": -86.3443984985,
      "tz": "America\/Nipigon"
  },
  "CYSQ": {
      "icao": "CYSQ",
      "iata": "",
      "name": "Atlin Airport",
      "city": "Atlin",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 2348,
      "lat": 59.5766983032,
      "lon": -133.6690063477,
      "tz": "America\/Vancouver"
  },
  "CYST": {
      "icao": "CYST",
      "iata": "YST",
      "name": "St. Theresa Point Airport",
      "city": "St. Theresa Point",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 773,
      "lat": 53.8456001282,
      "lon": -94.8518981934,
      "tz": "America\/Winnipeg"
  },
  "CYSU": {
      "icao": "CYSU",
      "iata": "YSU",
      "name": "Summerside Airport",
      "city": "Summerside",
      "state": "Prince Edward Island",
      "country": "CA",
      "elevation": 56,
      "lat": 46.4406013489,
      "lon": -63.8335990906,
      "tz": "America\/Halifax"
  },
  "CYSW": {
      "icao": "CYSW",
      "iata": "",
      "name": "Sparwood Elk Valley Airport",
      "city": "Sparwood",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 3800,
      "lat": 49.8333015442,
      "lon": -114.8830032349,
      "tz": "America\/Edmonton"
  },
  "CYSY": {
      "icao": "CYSY",
      "iata": "YSY",
      "name": "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
      "city": "Sachs Harbour",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 282,
      "lat": 71.9938964844,
      "lon": -125.242996216,
      "tz": "America\/Inuvik"
  },
  "CYSZ": {
      "icao": "CYSZ",
      "iata": "",
      "name": "Ste Anne Des Monts Airport",
      "city": "Ste-Anne-Des-Monts",
      "state": "Quebec",
      "country": "CA",
      "elevation": 71,
      "lat": 49.120300293,
      "lon": -66.5291976929,
      "tz": "America\/Toronto"
  },
  "CYTA": {
      "icao": "CYTA",
      "iata": "YTA",
      "name": "Pembroke Airport",
      "city": "Pembroke",
      "state": "Ontario",
      "country": "CA",
      "elevation": 529,
      "lat": 45.8643989563,
      "lon": -77.251701355,
      "tz": "America\/Toronto"
  },
  "CYTB": {
      "icao": "CYTB",
      "iata": "",
      "name": "Tillsonburg Airport",
      "city": "Tillsonburg",
      "state": "Ontario",
      "country": "CA",
      "elevation": 894,
      "lat": 42.9275,
      "lon": -80.7469444444,
      "tz": "America\/Toronto"
  },
  "CYTE": {
      "icao": "CYTE",
      "iata": "YTE",
      "name": "Cape Dorset Airport",
      "city": "Cape Dorset",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 164,
      "lat": 64.2300033569,
      "lon": -76.5267028809,
      "tz": "America\/Iqaluit"
  },
  "CYTF": {
      "icao": "CYTF",
      "iata": "YTF",
      "name": "Alma Airport",
      "city": "Alma",
      "state": "Quebec",
      "country": "CA",
      "elevation": 445,
      "lat": 48.5088996887,
      "lon": -71.6418991089,
      "tz": "America\/Toronto"
  },
  "CYTH": {
      "icao": "CYTH",
      "iata": "YTH",
      "name": "Thompson Airport",
      "city": "Thompson",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 729,
      "lat": 55.8011016846,
      "lon": -97.8641967773,
      "tz": "America\/Winnipeg"
  },
  "CYTL": {
      "icao": "CYTL",
      "iata": "YTL",
      "name": "Big Trout Lake Airport",
      "city": "Big Trout Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 729,
      "lat": 53.8177986145,
      "lon": -89.8968963623,
      "tz": "America\/Rainy_River"
  },
  "CYTN": {
      "icao": "CYTN",
      "iata": "",
      "name": "Trenton Airport",
      "city": "Trenton",
      "state": "Nova Scotia",
      "country": "CA",
      "elevation": 319,
      "lat": 45.6119003296,
      "lon": -62.6211013794,
      "tz": "America\/Halifax"
  },
  "CYTQ": {
      "icao": "CYTQ",
      "iata": "YTQ",
      "name": "Tasiujaq Airport",
      "city": "Tasiujaq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 122,
      "lat": 58.6678009033,
      "lon": -69.9558029175,
      "tz": "America\/Toronto"
  },
  "CYTR": {
      "icao": "CYTR",
      "iata": "YTR",
      "name": "CFB Trenton",
      "city": "Trenton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 283,
      "lat": 44.1189002991,
      "lon": -77.5280990601,
      "tz": "America\/Toronto"
  },
  "CYTS": {
      "icao": "CYTS",
      "iata": "YTS",
      "name": "Timmins\/Victor M. Power",
      "city": "Timmins",
      "state": "Ontario",
      "country": "CA",
      "elevation": 967,
      "lat": 48.5696983337,
      "lon": -81.376701355,
      "tz": "America\/Toronto"
  },
  "CYTZ": {
      "icao": "CYTZ",
      "iata": "YTZ",
      "name": "Billy Bishop Toronto City Centre Airport",
      "city": "Toronto",
      "state": "Ontario",
      "country": "CA",
      "elevation": 252,
      "lat": 43.6274986267,
      "lon": -79.3962020874,
      "tz": "America\/Toronto"
  },
  "CYUB": {
      "icao": "CYUB",
      "iata": "YUB",
      "name": "Tuktoyaktuk Airport",
      "city": "Tuktoyaktuk",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 15,
      "lat": 69.4332962036,
      "lon": -133.0260009766,
      "tz": "America\/Inuvik"
  },
  "CYUL": {
      "icao": "CYUL",
      "iata": "YUL",
      "name": "Montreal \/ Pierre Elliott Trudeau International Airport",
      "city": "Montreal",
      "state": "Quebec",
      "country": "CA",
      "elevation": 118,
      "lat": 45.4706001282,
      "lon": -73.7407989502,
      "tz": "America\/Toronto"
  },
  "CYUT": {
      "icao": "CYUT",
      "iata": "YUT",
      "name": "Repulse Bay Airport",
      "city": "Repulse Bay",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 80,
      "lat": 66.5214004517,
      "lon": -86.2247009277,
      "tz": "America\/Rankin_Inlet"
  },
  "CYUX": {
      "icao": "CYUX",
      "iata": "YUX",
      "name": "Hall Beach Airport",
      "city": "Hall Beach",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 27,
      "lat": 68.7761001587,
      "lon": -81.243598938,
      "tz": "America\/Iqaluit"
  },
  "CYUY": {
      "icao": "CYUY",
      "iata": "YUY",
      "name": "Rouyn Noranda Airport",
      "city": "Rouyn-Noranda",
      "state": "Quebec",
      "country": "CA",
      "elevation": 988,
      "lat": 48.2061004639,
      "lon": -78.8356018066,
      "tz": "America\/Toronto"
  },
  "CYVB": {
      "icao": "CYVB",
      "iata": "YVB",
      "name": "Bonaventure Airport",
      "city": "Bonaventure",
      "state": "Quebec",
      "country": "CA",
      "elevation": 123,
      "lat": 48.0710983276,
      "lon": -65.4602966309,
      "tz": "America\/Toronto"
  },
  "CYVC": {
      "icao": "CYVC",
      "iata": "YVC",
      "name": "La Ronge Airport",
      "city": "La Ronge",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1242,
      "lat": 55.1514015198,
      "lon": -105.2620010376,
      "tz": "America\/Regina"
  },
  "CYVD": {
      "icao": "CYVD",
      "iata": "",
      "name": "Virden\/R.J. (Bob) Andrew Field Regional Aerodrome",
      "city": "Virden",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1454,
      "lat": 49.8782997131,
      "lon": -100.917999268,
      "tz": "America\/Winnipeg"
  },
  "CYVG": {
      "icao": "CYVG",
      "iata": "YVG",
      "name": "Vermilion Airport",
      "city": "Vermilion",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2025,
      "lat": 53.3558006287,
      "lon": -110.8239974976,
      "tz": "America\/Edmonton"
  },
  "CYVK": {
      "icao": "CYVK",
      "iata": "YVE",
      "name": "Vernon Airport",
      "city": "Vernon",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 1140,
      "lat": 50.2481002808,
      "lon": -119.3310012817,
      "tz": "America\/Vancouver"
  },
  "CYVM": {
      "icao": "CYVM",
      "iata": "YVM",
      "name": "Qikiqtarjuaq Airport",
      "city": "Qikiqtarjuaq",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 21,
      "lat": 67.5457992554,
      "lon": -64.0314025879,
      "tz": "America\/Pangnirtung"
  },
  "CYVO": {
      "icao": "CYVO",
      "iata": "YVO",
      "name": "Val-d'Or Airport",
      "city": "Val-d'Or",
      "state": "Quebec",
      "country": "CA",
      "elevation": 1107,
      "lat": 48.0532989502,
      "lon": -77.7827987671,
      "tz": "America\/Toronto"
  },
  "CYVP": {
      "icao": "CYVP",
      "iata": "YVP",
      "name": "Kuujjuaq Airport",
      "city": "Kuujjuaq",
      "state": "Quebec",
      "country": "CA",
      "elevation": 129,
      "lat": 58.0960998535,
      "lon": -68.426902771,
      "tz": "America\/Toronto"
  },
  "CYVQ": {
      "icao": "CYVQ",
      "iata": "YVQ",
      "name": "Norman Wells Airport",
      "city": "Norman Wells",
      "state": "Northwest Territories",
      "country": "CA",
      "elevation": 238,
      "lat": 65.2816009521,
      "lon": -126.797996521,
      "tz": "America\/Inuvik"
  },
  "CYVR": {
      "icao": "CYVR",
      "iata": "YVR",
      "name": "Vancouver International Airport",
      "city": "Vancouver",
      "state": "British Columbia",
      "country": "CA",
      "elevation": 14,
      "lat": 49.193901062,
      "lon": -123.1839981079,
      "tz": "America\/Vancouver"
  },
  "CYVT": {
      "icao": "CYVT",
      "iata": "YVT",
      "name": "Buffalo Narrows Airport",
      "city": "Buffalo Narrows",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1423,
      "lat": 55.8418998718,
      "lon": -108.417999268,
      "tz": "America\/Regina"
  },
  "CYVV": {
      "icao": "CYVV",
      "iata": "YVV",
      "name": "Wiarton Airport",
      "city": "Wiarton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 729,
      "lat": 44.7458000183,
      "lon": -81.1072006226,
      "tz": "America\/Toronto"
  },
  "CYVZ": {
      "icao": "CYVZ",
      "iata": "YVZ",
      "name": "Deer Lake Airport",
      "city": "Deer Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1092,
      "lat": 52.6557998657,
      "lon": -94.0614013672,
      "tz": "America\/Rainy_River"
  },
  "CYWA": {
      "icao": "CYWA",
      "iata": "YWA",
      "name": "Petawawa Airport",
      "city": "Petawawa",
      "state": "Ontario",
      "country": "CA",
      "elevation": 427,
      "lat": 45.9522018433,
      "lon": -77.3191986084,
      "tz": "America\/Toronto"
  },
  "CYWE": {
      "icao": "CYWE",
      "iata": "YFJ",
      "name": "Wekweeti Airport",
      "city": "Wekweeti",
      "state": "Northwest-Territories",
      "country": "CA",
      "elevation": 1208,
      "lat": 64.1908035278,
      "lon": -114.077003479,
      "tz": "America\/Yellowknife"
  },
  "CYWG": {
      "icao": "CYWG",
      "iata": "YWG",
      "name": "Winnipeg \/ James Armstrong Richardson International Airport",
      "city": "Winnipeg",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 783,
      "lat": 49.9099998474,
      "lon": -97.2398986816,
      "tz": "America\/Winnipeg"
  },
  "CYWJ": {
      "icao": "CYWJ",
      "iata": "YWJ",
      "name": "Deline Airport",
      "city": "Deline",
      "state": "Northwest-Territories",
      "country": "CA",
      "elevation": 703,
      "lat": 65.2110977173,
      "lon": -123.4359970093,
      "tz": "America\/Inuvik"
  },
  "CYWK": {
      "icao": "CYWK",
      "iata": "YWK",
      "name": "Wabush Airport",
      "city": "Wabush",
      "state": "Newfoundland-and-Labrador",
      "country": "CA",
      "elevation": 1808,
      "lat": 52.9219017029,
      "lon": -66.864402771,
      "tz": "America\/Goose_Bay"
  },
  "CYWL": {
      "icao": "CYWL",
      "iata": "YWL",
      "name": "Williams Lake Airport",
      "city": "Williams Lake",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 3085,
      "lat": 52.1831016541,
      "lon": -122.0540008545,
      "tz": "America\/Vancouver"
  },
  "CYWM": {
      "icao": "CYWM",
      "iata": "",
      "name": "Athabasca Airport",
      "city": "Athabasca",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1971,
      "lat": 54.7430992126,
      "lon": -113.2050018311,
      "tz": "America\/Edmonton"
  },
  "CYWP": {
      "icao": "CYWP",
      "iata": "YWP",
      "name": "Webequie Airport",
      "city": "Webequie",
      "state": "Ontario",
      "country": "CA",
      "elevation": 685,
      "lat": 52.9593933975,
      "lon": -87.3748683929,
      "tz": "America\/Nipigon"
  },
  "CYWV": {
      "icao": "CYWV",
      "iata": "",
      "name": "Wainwright Airport",
      "city": "Wainwright",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2230,
      "lat": 52.7957992554,
      "lon": -110.8570022583,
      "tz": "America\/Edmonton"
  },
  "CYWY": {
      "icao": "CYWY",
      "iata": "YWY",
      "name": "Wrigley Airport",
      "city": "Wrigley",
      "state": "Northwest-Territories",
      "country": "CA",
      "elevation": 489,
      "lat": 63.209400177,
      "lon": -123.43699646,
      "tz": "America\/Inuvik"
  },
  "CYXC": {
      "icao": "CYXC",
      "iata": "YXC",
      "name": "Cranbrook Airport",
      "city": "Cranbrook",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 3082,
      "lat": 49.6108016968,
      "lon": -115.7819976807,
      "tz": "America\/Edmonton"
  },
  "CYXD": {
      "icao": "CYXD",
      "iata": "YXD",
      "name": "Edmonton City Centre (Blatchford Field) Airport",
      "city": "Edmonton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2202,
      "lat": 53.5724983215,
      "lon": -113.521003723,
      "tz": "America\/Edmonton"
  },
  "CYXE": {
      "icao": "CYXE",
      "iata": "YXE",
      "name": "Saskatoon John G. Diefenbaker International Airport",
      "city": "Saskatoon",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1653,
      "lat": 52.1707992554,
      "lon": -106.6999969482,
      "tz": "America\/Regina"
  },
  "CYXH": {
      "icao": "CYXH",
      "iata": "YXH",
      "name": "Medicine Hat Airport",
      "city": "Medicine Hat",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2352,
      "lat": 50.018901825,
      "lon": -110.7210006714,
      "tz": "America\/Edmonton"
  },
  "CYXJ": {
      "icao": "CYXJ",
      "iata": "YXJ",
      "name": "Fort St John Airport",
      "city": "Fort St.John",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 2280,
      "lat": 56.2380981445,
      "lon": -120.7399978638,
      "tz": "America\/Dawson_Creek"
  },
  "CYXK": {
      "icao": "CYXK",
      "iata": "YXK",
      "name": "Rimouski Airport",
      "city": "Rimouski",
      "state": "Quebec",
      "country": "CA",
      "elevation": 82,
      "lat": 48.478099823,
      "lon": -68.4969024658,
      "tz": "America\/Toronto"
  },
  "CYXL": {
      "icao": "CYXL",
      "iata": "YXL",
      "name": "Sioux Lookout Airport",
      "city": "Sioux Lookout",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1258,
      "lat": 50.113899231,
      "lon": -91.9052963257,
      "tz": "America\/Rainy_River"
  },
  "CYXN": {
      "icao": "CYXN",
      "iata": "YXN",
      "name": "Whale Cove Airport",
      "city": "Whale Cove",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 40,
      "lat": 62.2400016785,
      "lon": -92.5980987549,
      "tz": "America\/Rankin_Inlet"
  },
  "CYXP": {
      "icao": "CYXP",
      "iata": "YXP",
      "name": "Pangnirtung Airport",
      "city": "Pangnirtung",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 75,
      "lat": 66.1449966431,
      "lon": -65.7136001587,
      "tz": "America\/Pangnirtung"
  },
  "CYXQ": {
      "icao": "CYXQ",
      "iata": "YXQ",
      "name": "Beaver Creek Airport",
      "city": "Beaver Creek",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2131,
      "lat": 62.4103012085,
      "lon": -140.8670043945,
      "tz": "America\/Dawson"
  },
  "CYXR": {
      "icao": "CYXR",
      "iata": "YXR",
      "name": "Earlton (Timiskaming Regional) Airport",
      "city": "Earlton",
      "state": "Ontario",
      "country": "CA",
      "elevation": 800,
      "lat": 47.6974006546,
      "lon": -79.8473453522,
      "tz": "America\/Nipigon"
  },
  "CYXS": {
      "icao": "CYXS",
      "iata": "YXS",
      "name": "Prince George Airport",
      "city": "Prince George",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 2267,
      "lat": 53.8894004822,
      "lon": -122.6790008545,
      "tz": "America\/Vancouver"
  },
  "CYXT": {
      "icao": "CYXT",
      "iata": "YXT",
      "name": "Terrace Airport",
      "city": "Terrace",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 713,
      "lat": 54.46849823,
      "lon": -128.5760040283,
      "tz": "America\/Vancouver"
  },
  "CYXU": {
      "icao": "CYXU",
      "iata": "YXU",
      "name": "London Airport",
      "city": "London",
      "state": "Ontario",
      "country": "CA",
      "elevation": 912,
      "lat": 43.0355987549,
      "lon": -81.1539001465,
      "tz": "America\/Toronto"
  },
  "CYXX": {
      "icao": "CYXX",
      "iata": "YXX",
      "name": "Abbotsford Airport",
      "city": "Abbotsford",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 195,
      "lat": 49.0252990723,
      "lon": -122.361000061,
      "tz": "America\/Los_Angeles"
  },
  "CYXY": {
      "icao": "CYXY",
      "iata": "YXY",
      "name": "Whitehorse \/ Erik Nielsen International Airport",
      "city": "Whitehorse",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2317,
      "lat": 60.7095985413,
      "lon": -135.067001343,
      "tz": "America\/Whitehorse"
  },
  "CYXZ": {
      "icao": "CYXZ",
      "iata": "YXZ",
      "name": "Wawa Airport",
      "city": "Wawa",
      "state": "Ontario",
      "country": "CA",
      "elevation": 942,
      "lat": 47.9667015076,
      "lon": -84.7866973877,
      "tz": "America\/Nipigon"
  },
  "CYYB": {
      "icao": "CYYB",
      "iata": "YYB",
      "name": "North Bay Airport",
      "city": "North Bay",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1215,
      "lat": 46.3636016846,
      "lon": -79.4227981567,
      "tz": "America\/Toronto"
  },
  "CYYC": {
      "icao": "CYYC",
      "iata": "YYC",
      "name": "Calgary International Airport",
      "city": "Calgary",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3557,
      "lat": 51.113899231,
      "lon": -114.0199966431,
      "tz": "America\/Edmonton"
  },
  "CYYD": {
      "icao": "CYYD",
      "iata": "YYD",
      "name": "Smithers Airport",
      "city": "Smithers",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 1712,
      "lat": 54.8246994019,
      "lon": -127.1829986572,
      "tz": "America\/Vancouver"
  },
  "CYYE": {
      "icao": "CYYE",
      "iata": "YYE",
      "name": "Fort Nelson Airport",
      "city": "Fort Nelson",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 1253,
      "lat": 58.8363990784,
      "lon": -122.5970001221,
      "tz": "America\/Fort_Nelson"
  },
  "CYYF": {
      "icao": "CYYF",
      "iata": "YYF",
      "name": "Penticton Airport",
      "city": "Penticton",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 1129,
      "lat": 49.4631004333,
      "lon": -119.6019973755,
      "tz": "America\/Vancouver"
  },
  "CYYG": {
      "icao": "CYYG",
      "iata": "YYG",
      "name": "Charlottetown Airport",
      "city": "Charlottetown",
      "state": "Newfoundland-and-Labrador",
      "country": "CA",
      "elevation": 160,
      "lat": 46.2900009155,
      "lon": -63.1211013794,
      "tz": "America\/Halifax"
  },
  "CYYH": {
      "icao": "CYYH",
      "iata": "YYH",
      "name": "Taloyoak Airport",
      "city": "Taloyoak",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 92,
      "lat": 69.5466995239,
      "lon": -93.5766983032,
      "tz": "America\/Cambridge_Bay"
  },
  "CYYJ": {
      "icao": "CYYJ",
      "iata": "YYJ",
      "name": "Victoria International Airport",
      "city": "Victoria",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 63,
      "lat": 48.646900177,
      "lon": -123.4260025024,
      "tz": "America\/Vancouver"
  },
  "CYYL": {
      "icao": "CYYL",
      "iata": "YYL",
      "name": "Lynn Lake Airport",
      "city": "Lynn Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1170,
      "lat": 56.863899231,
      "lon": -101.0759963989,
      "tz": "America\/Winnipeg"
  },
  "CYYM": {
      "icao": "CYYM",
      "iata": "YYM",
      "name": "Cowley Airport",
      "city": "Cowley",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3876,
      "lat": 49.6363983154,
      "lon": -114.09400177,
      "tz": "America\/Edmonton"
  },
  "CYYN": {
      "icao": "CYYN",
      "iata": "YYN",
      "name": "Swift Current Airport",
      "city": "Swift Current",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 2680,
      "lat": 50.2919006348,
      "lon": -107.691001892,
      "tz": "America\/Swift_Current"
  },
  "CYYO": {
      "icao": "CYYO",
      "iata": "",
      "name": "Wynyard \/ W.B. Needham Field",
      "city": "Wynyard",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1740,
      "lat": 51.8098883726,
      "lon": -104.169788361,
      "tz": "America\/Regina"
  },
  "CYYQ": {
      "icao": "CYYQ",
      "iata": "YYQ",
      "name": "Churchill Airport",
      "city": "Churchill",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 94,
      "lat": 58.739200592,
      "lon": -94.0650024414,
      "tz": "America\/Winnipeg"
  },
  "CYYR": {
      "icao": "CYYR",
      "iata": "YYR",
      "name": "Goose Bay Airport",
      "city": "Goose Bay",
      "state": "Newfoundland-and-Labrador",
      "country": "CA",
      "elevation": 160,
      "lat": 53.3191986084,
      "lon": -60.4258003235,
      "tz": "America\/Goose_Bay"
  },
  "CYYT": {
      "icao": "CYYT",
      "iata": "YYT",
      "name": "St. John's International Airport",
      "city": "St. John's",
      "state": "Newfoundland-and-Labrador",
      "country": "CA",
      "elevation": 461,
      "lat": 47.618598938,
      "lon": -52.7518997192,
      "tz": "America\/St_Johns"
  },
  "CYYU": {
      "icao": "CYYU",
      "iata": "YYU",
      "name": "Kapuskasing Airport",
      "city": "Kapuskasing",
      "state": "Ontario",
      "country": "CA",
      "elevation": 743,
      "lat": 49.413898468,
      "lon": -82.4674987793,
      "tz": "America\/Nipigon"
  },
  "CYYW": {
      "icao": "CYYW",
      "iata": "YYW",
      "name": "Armstrong Airport",
      "city": "Armstrong",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1058,
      "lat": 50.2902984619,
      "lon": -88.9096984863,
      "tz": "America\/Nipigon"
  },
  "CYYY": {
      "icao": "CYYY",
      "iata": "YYY",
      "name": "Mont Joli Airport",
      "city": "Mont-Joli",
      "state": "Quebec",
      "country": "CA",
      "elevation": 172,
      "lat": 48.6086006165,
      "lon": -68.2080993652,
      "tz": "America\/Toronto"
  },
  "CYYZ": {
      "icao": "CYYZ",
      "iata": "YYZ",
      "name": "Lester B. Pearson International Airport",
      "city": "Toronto",
      "state": "Ontario",
      "country": "CA",
      "elevation": 569,
      "lat": 43.6772003174,
      "lon": -79.6305999756,
      "tz": "America\/Toronto"
  },
  "CYZD": {
      "icao": "CYZD",
      "iata": "YZD",
      "name": "Downsview Airport",
      "city": "Toronto",
      "state": "Ontario",
      "country": "CA",
      "elevation": 652,
      "lat": 43.7425003052,
      "lon": -79.4655990601,
      "tz": "America\/Toronto"
  },
  "CYZE": {
      "icao": "CYZE",
      "iata": "YZE",
      "name": "Gore Bay Manitoulin Airport",
      "city": "Gore Bay",
      "state": "Ontario",
      "country": "CA",
      "elevation": 635,
      "lat": 45.8852996826,
      "lon": -82.5678024292,
      "tz": "America\/Nipigon"
  },
  "CYZF": {
      "icao": "CYZF",
      "iata": "YZF",
      "name": "Yellowknife Airport",
      "city": "Yellowknife",
      "state": "Northwest-Territories",
      "country": "CA",
      "elevation": 675,
      "lat": 62.4627990723,
      "lon": -114.4400024414,
      "tz": "America\/Yellowknife"
  },
  "CYZG": {
      "icao": "CYZG",
      "iata": "YZG",
      "name": "Salluit Airport",
      "city": "Salluit",
      "state": "Quebec",
      "country": "CA",
      "elevation": 743,
      "lat": 62.1794013977,
      "lon": -75.6671981812,
      "tz": "America\/Toronto"
  },
  "CYZH": {
      "icao": "CYZH",
      "iata": "YZH",
      "name": "Slave Lake Airport",
      "city": "Slave Lake",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1912,
      "lat": 55.2930984497,
      "lon": -114.777000427,
      "tz": "America\/Edmonton"
  },
  "CYZP": {
      "icao": "CYZP",
      "iata": "YZP",
      "name": "Sandspit Airport",
      "city": "Sandspit",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 21,
      "lat": 53.2542991638,
      "lon": -131.813995361,
      "tz": "America\/Vancouver"
  },
  "CYZR": {
      "icao": "CYZR",
      "iata": "YZR",
      "name": "Chris Hadfield Airport",
      "city": "Sarnia",
      "state": "Ontario",
      "country": "CA",
      "elevation": 594,
      "lat": 42.9994010925,
      "lon": -82.3088989258,
      "tz": "America\/Toronto"
  },
  "CYZS": {
      "icao": "CYZS",
      "iata": "YZS",
      "name": "Coral Harbour Airport",
      "city": "Coral Harbour",
      "state": "Nunavut",
      "country": "CA",
      "elevation": 210,
      "lat": 64.1932983398,
      "lon": -83.3593978882,
      "tz": "America\/Atikokan"
  },
  "CYZT": {
      "icao": "CYZT",
      "iata": "YZT",
      "name": "Port Hardy Airport",
      "city": "Port Hardy",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 71,
      "lat": 50.6805992126,
      "lon": -127.3669967651,
      "tz": "America\/Vancouver"
  },
  "CYZU": {
      "icao": "CYZU",
      "iata": "YZU",
      "name": "Whitecourt Airport",
      "city": "Whitecourt",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2567,
      "lat": 54.143901825,
      "lon": -115.7870025635,
      "tz": "America\/Edmonton"
  },
  "CYZV": {
      "icao": "CYZV",
      "iata": "YZV",
      "name": "Sept-Iles Airport",
      "city": "Sept-Iles",
      "state": "Quebec",
      "country": "CA",
      "elevation": 180,
      "lat": 50.2233009338,
      "lon": -66.2656021118,
      "tz": "America\/Toronto"
  },
  "CYZW": {
      "icao": "CYZW",
      "iata": "YZW",
      "name": "Teslin Airport",
      "city": "Teslin",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2313,
      "lat": 60.1727981567,
      "lon": -132.7429962158,
      "tz": "America\/Whitehorse"
  },
  "CYZX": {
      "icao": "CYZX",
      "iata": "YZX",
      "name": "CFB Greenwood",
      "city": "Greenwood",
      "state": "Nova-Scotia",
      "country": "CA",
      "elevation": 92,
      "lat": 44.9844017029,
      "lon": -64.9169006348,
      "tz": "America\/Halifax"
  },
  "CYZY": {
      "icao": "CYZY",
      "iata": "YZY",
      "name": "Mackenzie Airport",
      "city": "Mackenzie",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 2264,
      "lat": 55.3044013977,
      "lon": -123.132003784,
      "tz": "America\/Vancouver"
  },
  "CZAC": {
      "icao": "CZAC",
      "iata": "ZAC",
      "name": "York Landing Airport",
      "city": "York Landing",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 621,
      "lat": 56.0894012451,
      "lon": -96.0892028809,
      "tz": "America\/Winnipeg"
  },
  "CZAM": {
      "icao": "CZAM",
      "iata": "YSN",
      "name": "Salmon Arm Airport",
      "city": "Salmon Arm",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 1751,
      "lat": 50.682800293,
      "lon": -119.2289962769,
      "tz": "America\/Vancouver"
  },
  "CZBA": {
      "icao": "CZBA",
      "iata": "YDT",
      "name": "Burlington Executive",
      "city": "Burlington",
      "state": "Ontario",
      "country": "CA",
      "elevation": 602,
      "lat": 43.4414531515,
      "lon": -79.850102663,
      "tz": "America\/Toronto"
  },
  "CZBB": {
      "icao": "CZBB",
      "iata": "ZBD",
      "name": "Vancouver \/ Boundary Bay Airport",
      "city": "Boundary Bay",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 6,
      "lat": 49.0741996765,
      "lon": -123.012001038,
      "tz": "America\/Vancouver"
  },
  "CZBD": {
      "icao": "CZBD",
      "iata": "ILF",
      "name": "Ilford Airport",
      "city": "Ilford",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 642,
      "lat": 56.0614013672,
      "lon": -95.613899231,
      "tz": "America\/Winnipeg"
  },
  "CZBF": {
      "icao": "CZBF",
      "iata": "ZBF",
      "name": "Bathurst Airport",
      "city": "Bathurst",
      "state": "New-Brunswick",
      "country": "CA",
      "elevation": 193,
      "lat": 47.629699707,
      "lon": -65.738899231,
      "tz": "America\/Moncton"
  },
  "CZBM": {
      "icao": "CZBM",
      "iata": "ZBM",
      "name": "Bromont (Roland Desourdy) Airport",
      "city": "Bromont",
      "state": "Quebec",
      "country": "CA",
      "elevation": 375,
      "lat": 45.2907981873,
      "lon": -72.7414016724,
      "tz": "America\/Toronto"
  },
  "CZEE": {
      "icao": "CZEE",
      "iata": "ZEE",
      "name": "Kelsey Airport",
      "city": "Kelsey",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 600,
      "lat": 56.0374984741,
      "lon": -96.5096969604,
      "tz": "America\/Winnipeg"
  },
  "CZEM": {
      "icao": "CZEM",
      "iata": "ZEM",
      "name": "Eastmain River Airport",
      "city": "Eastmain River",
      "state": "Quebec",
      "country": "CA",
      "elevation": 24,
      "lat": 52.226398468,
      "lon": -78.5224990845,
      "tz": "America\/Toronto"
  },
  "CZFA": {
      "icao": "CZFA",
      "iata": "ZFA",
      "name": "Faro Airport",
      "city": "Faro",
      "state": "Yukon",
      "country": "CA",
      "elevation": 2351,
      "lat": 62.2075004578,
      "lon": -133.3760070801,
      "tz": "America\/Whitehorse"
  },
  "CZFD": {
      "icao": "CZFD",
      "iata": "ZFD",
      "name": "Fond-Du-Lac Airport",
      "city": "Fond-Du-Lac",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 814,
      "lat": 59.334400177,
      "lon": -107.1819992065,
      "tz": "America\/Regina"
  },
  "CZFG": {
      "icao": "CZFG",
      "iata": "ZFG",
      "name": "Pukatawagan Airport",
      "city": "Pukatawagan",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 958,
      "lat": 55.7491989136,
      "lon": -101.2659988403,
      "tz": "America\/Winnipeg"
  },
  "CZFM": {
      "icao": "CZFM",
      "iata": "ZFM",
      "name": "Fort Mcpherson Airport",
      "city": "Fort Mcpherson",
      "state": "Northwest-Territories",
      "country": "CA",
      "elevation": 116,
      "lat": 67.4075012207,
      "lon": -134.8609924316,
      "tz": "America\/Inuvik"
  },
  "CZFN": {
      "icao": "CZFN",
      "iata": "ZFN",
      "name": "Tulita Airport",
      "city": "Tulita",
      "state": "Northwest-Territories",
      "country": "CA",
      "elevation": 332,
      "lat": 64.9096984863,
      "lon": -125.5729980469,
      "tz": "America\/Inuvik"
  },
  "CZGF": {
      "icao": "CZGF",
      "iata": "ZGF",
      "name": "Grand Forks Airport",
      "city": "Grand Forks",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 1720,
      "lat": 49.0155982971,
      "lon": -118.4309997559,
      "tz": "America\/Los_Angeles"
  },
  "CZGI": {
      "icao": "CZGI",
      "iata": "ZGI",
      "name": "Gods River Airport",
      "city": "Gods River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 627,
      "lat": 54.8396987915,
      "lon": -94.0785980225,
      "tz": "America\/Winnipeg"
  },
  "CZGR": {
      "icao": "CZGR",
      "iata": "ZGR",
      "name": "Little Grand Rapids Airport",
      "city": "Little Grand Rapids",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1005,
      "lat": 52.0456008911,
      "lon": -95.4657974243,
      "tz": "America\/Winnipeg"
  },
  "CZHP": {
      "icao": "CZHP",
      "iata": "ZHP",
      "name": "High Prairie Airport",
      "city": "High Prairie",
      "state": "Alberta",
      "country": "CA",
      "elevation": 1974,
      "lat": 55.3936004639,
      "lon": -116.4749984741,
      "tz": "America\/Edmonton"
  },
  "CZJG": {
      "icao": "CZJG",
      "iata": "ZJG",
      "name": "Jenpeg Airport",
      "city": "Jenpeg",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 729,
      "lat": 54.518901825,
      "lon": -98.0460968018,
      "tz": "America\/Winnipeg"
  },
  "CZJN": {
      "icao": "CZJN",
      "iata": "ZJN",
      "name": "Swan River Airport",
      "city": "Swan River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1100,
      "lat": 52.1206016541,
      "lon": -101.236000061,
      "tz": "America\/Winnipeg"
  },
  "CZKE": {
      "icao": "CZKE",
      "iata": "ZKE",
      "name": "Kashechewan Airport",
      "city": "Kashechewan",
      "state": "Ontario",
      "country": "CA",
      "elevation": 35,
      "lat": 52.2825012207,
      "lon": -81.6778030396,
      "tz": "America\/Nipigon"
  },
  "CZLQ": {
      "icao": "CZLQ",
      "iata": "YTD",
      "name": "Thicket Portage Airport",
      "city": "Thicket Portage",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 678,
      "lat": 55.318901062,
      "lon": -97.7078018188,
      "tz": "America\/Winnipeg"
  },
  "CZMD": {
      "icao": "CZMD",
      "iata": "MSA",
      "name": "Muskrat Dam Airport",
      "city": "Muskrat Dam",
      "state": "Ontario",
      "country": "CA",
      "elevation": 911,
      "lat": 53.4413986206,
      "lon": -91.762802124,
      "tz": "America\/Rainy_River"
  },
  "CZML": {
      "icao": "CZML",
      "iata": "ZMH",
      "name": "South Cariboo Region \/ 108 Mile Airport",
      "city": "108 Mile",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 3126,
      "lat": 51.7360992432,
      "lon": -121.333000183,
      "tz": "America\/Vancouver"
  },
  "CZMN": {
      "icao": "CZMN",
      "iata": "PIW",
      "name": "Pikwitonei Airport",
      "city": "Pikwitonei",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 630,
      "lat": 55.5889015198,
      "lon": -97.1641998291,
      "tz": "America\/Winnipeg"
  },
  "CZMT": {
      "icao": "CZMT",
      "iata": "ZMT",
      "name": "Masset Airport",
      "city": "Masset",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 25,
      "lat": 54.0275001526,
      "lon": -132.125,
      "tz": "America\/Vancouver"
  },
  "CZNG": {
      "icao": "CZNG",
      "iata": "ZNG",
      "name": "Poplar River Airport",
      "city": "Poplar River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 728,
      "lat": 53,
      "lon": -97.2667007446,
      "tz": "America\/Winnipeg"
  },
  "CZNL": {
      "icao": "CZNL",
      "iata": "",
      "name": "Nelson Airport",
      "city": "Nelson",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 1755,
      "lat": 49.4942016602,
      "lon": -117.3010025024,
      "tz": "America\/Vancouver"
  },
  "CZPB": {
      "icao": "CZPB",
      "iata": "ZPB",
      "name": "Sachigo Lake Airport",
      "city": "Sachigo Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 876,
      "lat": 53.8911018372,
      "lon": -92.1964035034,
      "tz": "America\/Rainy_River"
  },
  "CZPC": {
      "icao": "CZPC",
      "iata": "WPC",
      "name": "Pincher Creek Airport",
      "city": "Pincher Creek",
      "state": "Alberta",
      "country": "CA",
      "elevation": 3903,
      "lat": 49.5205993652,
      "lon": -113.9970016479,
      "tz": "America\/Edmonton"
  },
  "CZPO": {
      "icao": "CZPO",
      "iata": "ZPO",
      "name": "Pinehouse Lake Airport",
      "city": "Pinehouse Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1278,
      "lat": 55.5280990601,
      "lon": -106.582000732,
      "tz": "America\/Regina"
  },
  "CZRJ": {
      "icao": "CZRJ",
      "iata": "ZRJ",
      "name": "Round Lake (Weagamow Lake) Airport",
      "city": "Round Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 974,
      "lat": 52.9435997009,
      "lon": -91.3127975464,
      "tz": "America\/Rainy_River"
  },
  "CZSJ": {
      "icao": "CZSJ",
      "iata": "ZSJ",
      "name": "Sandy Lake Airport",
      "city": "Sandy Lake",
      "state": "Ontario",
      "country": "CA",
      "elevation": 951,
      "lat": 53.064201355,
      "lon": -93.3443984985,
      "tz": "America\/Rainy_River"
  },
  "CZSN": {
      "icao": "CZSN",
      "iata": "ZSN",
      "name": "South Indian Lake Airport",
      "city": "South Indian Lake",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 951,
      "lat": 56.7928009033,
      "lon": -98.9072036743,
      "tz": "America\/Winnipeg"
  },
  "CZST": {
      "icao": "CZST",
      "iata": "ZST",
      "name": "Stewart Airport",
      "city": "Stewart",
      "state": "British-Columbia",
      "country": "CA",
      "elevation": 24,
      "lat": 55.935410448,
      "lon": -129.982434511,
      "tz": "America\/Vancouver"
  },
  "CZTA": {
      "icao": "CZTA",
      "iata": "YDV",
      "name": "Bloodvein River Airport",
      "city": "Bloodvein River",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 721,
      "lat": 51.7832984924,
      "lon": -96.6999969482,
      "tz": "America\/Winnipeg"
  },
  "CZTM": {
      "icao": "CZTM",
      "iata": "ZTM",
      "name": "Shamattawa Airport",
      "city": "Shamattawa",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 289,
      "lat": 55.8656005859,
      "lon": -92.0813980103,
      "tz": "America\/Winnipeg"
  },
  "CZUC": {
      "icao": "CZUC",
      "iata": "ZUC",
      "name": "Ignace Municipal Airport",
      "city": "Ignace",
      "state": "Ontario",
      "country": "CA",
      "elevation": 1435,
      "lat": 49.4296989441,
      "lon": -91.7177963257,
      "tz": "America\/Rainy_River"
  },
  "CZUM": {
      "icao": "CZUM",
      "iata": "ZUM",
      "name": "Churchill Falls Airport",
      "city": "Churchill Falls",
      "state": "Newfoundland-and-Labrador",
      "country": "CA",
      "elevation": 1442,
      "lat": 53.5619010925,
      "lon": -64.1063995361,
      "tz": "America\/Goose_Bay"
  },
  "CZVL": {
      "icao": "CZVL",
      "iata": "",
      "name": "Edmonton \/ Villeneuve Airport",
      "city": "Edmonton",
      "state": "Alberta",
      "country": "CA",
      "elevation": 2256,
      "lat": 53.6674995422,
      "lon": -113.853996277,
      "tz": "America\/Edmonton"
  },
  "CZWH": {
      "icao": "CZWH",
      "iata": "ZWH",
      "name": "Lac Brochet Airport",
      "city": "Lac Brochet",
      "state": "Manitoba",
      "country": "CA",
      "elevation": 1211,
      "lat": 58.6175003052,
      "lon": -101.46900177,
      "tz": "America\/Winnipeg"
  },
  "CZWL": {
      "icao": "CZWL",
      "iata": "ZWL",
      "name": "Wollaston Lake Airport",
      "city": "Wollaston Lake",
      "state": "Saskatchewan",
      "country": "CA",
      "elevation": 1360,
      "lat": 58.1068992615,
      "lon": -103.1719970703,
      "tz": "America\/Regina"
  },
  "DA10": {
      "icao": "DA10",
      "iata": "",
      "name": "Illizi Airport",
      "city": "",
      "state": "Illizi",
      "country": "DZ",
      "elevation": 1778,
      "lat": 26.573600769,
      "lon": 8.4836597443,
      "tz": "Africa\/Algiers"
  },
  "DA11": {
      "icao": "DA11",
      "iata": "",
      "name": "Hamaguir Airport",
      "city": "",
      "state": "Bechar",
      "country": "DZ",
      "elevation": 2454,
      "lat": 30.8789997101,
      "lon": -3.0677499771,
      "tz": "Africa\/Algiers"
  },
  "DA12": {
      "icao": "DA12",
      "iata": "",
      "name": "El Abiodh Sidi Cheikh Airport",
      "city": "",
      "state": "El-Bayadh",
      "country": "DZ",
      "elevation": 2965,
      "lat": 32.8984985352,
      "lon": 0.5246940255,
      "tz": "Africa\/Algiers"
  },
  "DA13": {
      "icao": "DA13",
      "iata": "",
      "name": "Tinfouchy Airport",
      "city": "",
      "state": "Tindouf",
      "country": "DZ",
      "elevation": 1804,
      "lat": 28.8792991638,
      "lon": -5.8228998184,
      "tz": "Africa\/Algiers"
  },
  "DA14": {
      "icao": "DA14",
      "iata": "",
      "name": "Mostaganem Airport",
      "city": "",
      "state": "Mostaganem",
      "country": "DZ",
      "elevation": 732,
      "lat": 35.9087982178,
      "lon": 0.1493829936,
      "tz": "Africa\/Algiers"
  },
  "DA15": {
      "icao": "DA15",
      "iata": "",
      "name": "Saida Airport",
      "city": "",
      "state": "Saida",
      "country": "DZ",
      "elevation": 2444,
      "lat": 34.8972015381,
      "lon": 0.1516939998,
      "tz": "Africa\/Algiers"
  },
  "DA16": {
      "icao": "DA16",
      "iata": "",
      "name": "Tindouf East Airport",
      "city": "",
      "state": "Tindouf",
      "country": "DZ",
      "elevation": 1425,
      "lat": 27.585899353,
      "lon": -7.5001101494,
      "tz": "Africa\/Algiers"
  },
  "DAAB": {
      "icao": "DAAB",
      "iata": "QLD",
      "name": "Blida Airport",
      "city": "",
      "state": "Blida",
      "country": "DZ",
      "elevation": 535,
      "lat": 36.5036010742,
      "lon": 2.8141698837,
      "tz": "Africa\/Algiers"
  },
  "DAAD": {
      "icao": "DAAD",
      "iata": "BUJ",
      "name": "Bou Saada Airport",
      "city": "",
      "state": "M'Sila",
      "country": "DZ",
      "elevation": 1506,
      "lat": 35.3325004578,
      "lon": 4.206389904,
      "tz": "Africa\/Algiers"
  },
  "DAAE": {
      "icao": "DAAE",
      "iata": "BJA",
      "name": "Soummam Airport",
      "city": "Bejaia",
      "state": "Bejaia",
      "country": "DZ",
      "elevation": 20,
      "lat": 36.7120018005,
      "lon": 5.069920063,
      "tz": "Africa\/Algiers"
  },
  "DAAG": {
      "icao": "DAAG",
      "iata": "ALG",
      "name": "Houari Boumediene Airport",
      "city": "Algiers",
      "state": "Algiers",
      "country": "DZ",
      "elevation": 82,
      "lat": 36.6910018921,
      "lon": 3.2154099941,
      "tz": "Africa\/Algiers"
  },
  "DAAJ": {
      "icao": "DAAJ",
      "iata": "DJG",
      "name": "Djanet Inedbirene Airport",
      "city": "Djanet",
      "state": "Illizi",
      "country": "DZ",
      "elevation": 3176,
      "lat": 24.2928009033,
      "lon": 9.4524402618,
      "tz": "Africa\/Algiers"
  },
  "DAAK": {
      "icao": "DAAK",
      "iata": "QFD",
      "name": "Boufarik Airport",
      "city": "",
      "state": "Blida",
      "country": "DZ",
      "elevation": 335,
      "lat": 36.5457992554,
      "lon": 2.8761100769,
      "tz": "Africa\/Algiers"
  },
  "DAAM": {
      "icao": "DAAM",
      "iata": "",
      "name": "Telerghma Airport",
      "city": "Telerghma",
      "state": "Mila",
      "country": "DZ",
      "elevation": 2484,
      "lat": 36.1086997986,
      "lon": 6.3646001816,
      "tz": "Africa\/Algiers"
  },
  "DAAN": {
      "icao": "DAAN",
      "iata": "",
      "name": "Reggane Airport",
      "city": "",
      "state": "Adrar",
      "country": "DZ",
      "elevation": 955,
      "lat": 26.710100174,
      "lon": 0.2856470048,
      "tz": "Africa\/Algiers"
  },
  "DAAP": {
      "icao": "DAAP",
      "iata": "VVZ",
      "name": "Illizi Takhamalt Airport",
      "city": "Illizi",
      "state": "Illizi",
      "country": "DZ",
      "elevation": 1778,
      "lat": 26.7234992981,
      "lon": 8.6226501465,
      "tz": "Africa\/Algiers"
  },
  "DAAQ": {
      "icao": "DAAQ",
      "iata": "",
      "name": "Ain Oussera Airport",
      "city": "",
      "state": "Djelfa",
      "country": "DZ",
      "elevation": 2132,
      "lat": 35.5253982544,
      "lon": 2.8787100315,
      "tz": "Africa\/Algiers"
  },
  "DAAS": {
      "icao": "DAAS",
      "iata": "QSF",
      "name": "Ain Arnat Airport",
      "city": "Setif",
      "state": "Setif",
      "country": "DZ",
      "elevation": 3360,
      "lat": 36.1781005859,
      "lon": 5.3244900703,
      "tz": "Africa\/Algiers"
  },
  "DAAT": {
      "icao": "DAAT",
      "iata": "TMR",
      "name": "Aguenar \u2013 Hadj Bey Akhamok Airport",
      "city": "Tamanrasset",
      "state": "Tamanrasset",
      "country": "DZ",
      "elevation": 4518,
      "lat": 22.8115005493,
      "lon": 5.4510798454,
      "tz": "Africa\/Algiers"
  },
  "DAAV": {
      "icao": "DAAV",
      "iata": "GJL",
      "name": "Jijel Ferhat Abbas Airport",
      "city": "Jijel",
      "state": "Jijel",
      "country": "DZ",
      "elevation": 36,
      "lat": 36.7951011658,
      "lon": 5.8736100197,
      "tz": "Africa\/Algiers"
  },
  "DAAW": {
      "icao": "DAAW",
      "iata": "",
      "name": "Bordj Omar Driss Airport",
      "city": "Bordj Omar Driss",
      "state": "Illizi",
      "country": "DZ",
      "elevation": 1207,
      "lat": 28.1313991547,
      "lon": 6.8336100578,
      "tz": "Africa\/Algiers"
  },
  "DAAY": {
      "icao": "DAAY",
      "iata": "MZW",
      "name": "Mecheria Airport",
      "city": "Mecheria",
      "state": "Naama",
      "country": "DZ",
      "elevation": 3855,
      "lat": 33.535900116,
      "lon": -0.2423530072,
      "tz": "Africa\/Algiers"
  },
  "DAAZ": {
      "icao": "DAAZ",
      "iata": "QZN",
      "name": "Relizane Airport",
      "city": "",
      "state": "Relizane",
      "country": "DZ",
      "elevation": 282,
      "lat": 35.7522010803,
      "lon": 0.6262720227,
      "tz": "Africa\/Algiers"
  },
  "DABB": {
      "icao": "DABB",
      "iata": "AAE",
      "name": "Annaba Airport",
      "city": "Annabah",
      "state": "Annaba",
      "country": "DZ",
      "elevation": 16,
      "lat": 36.8222007751,
      "lon": 7.8091697693,
      "tz": "Africa\/Algiers"
  },
  "DABC": {
      "icao": "DABC",
      "iata": "CZL",
      "name": "Mohamed Boudiaf International Airport",
      "city": "Constantine",
      "state": "Constantine",
      "country": "DZ",
      "elevation": 2265,
      "lat": 36.2760009766,
      "lon": 6.6203899384,
      "tz": "Africa\/Algiers"
  },
  "DABO": {
      "icao": "DABO",
      "iata": "",
      "name": "Oum el Bouaghi airport",
      "city": "Oum El Bouaghi",
      "state": "Oum-el-Bouaghi",
      "country": "DZ",
      "elevation": 0,
      "lat": 35.879699707,
      "lon": 7.2708001137,
      "tz": "Africa\/Algiers"
  },
  "DABS": {
      "icao": "DABS",
      "iata": "TEE",
      "name": "Cheikh Larbi Tebessi Airport",
      "city": "Tebessi",
      "state": "Tebessa",
      "country": "DZ",
      "elevation": 2661,
      "lat": 35.4315986633,
      "lon": 8.1207199097,
      "tz": "Africa\/Algiers"
  },
  "DABT": {
      "icao": "DABT",
      "iata": "BLJ",
      "name": "Batna Airport",
      "city": "Batna",
      "state": "Batna",
      "country": "DZ",
      "elevation": 2697,
      "lat": 35.7521018982,
      "lon": 6.3085899353,
      "tz": "Africa\/Algiers"
  },
  "DAFH": {
      "icao": "DAFH",
      "iata": "HRM",
      "name": "Hassi R'Mel Airport",
      "city": "",
      "state": "Laghouat",
      "country": "DZ",
      "elevation": 2540,
      "lat": 32.9304008484,
      "lon": 3.3115398884,
      "tz": "Africa\/Algiers"
  },
  "DAFI": {
      "icao": "DAFI",
      "iata": "QDJ",
      "name": "Tsletsi Airport",
      "city": "",
      "state": "Djelfa",
      "country": "DZ",
      "elevation": 0,
      "lat": 34.6657,
      "lon": 3.351,
      "tz": "Africa\/Algiers"
  },
  "DALH": {
      "icao": "DALH",
      "iata": "",
      "name": "Dalhousie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.4290008545,
      "lon": 135.5059967041,
      "tz": "Australia\/Adelaide"
  },
  "DAOB": {
      "icao": "DAOB",
      "iata": "TID",
      "name": "Bou Chekif Airport",
      "city": "Tiaret",
      "state": "Tiaret",
      "country": "DZ",
      "elevation": 3245,
      "lat": 35.3410987854,
      "lon": 1.4631500244,
      "tz": "Africa\/Algiers"
  },
  "DAOC": {
      "icao": "DAOC",
      "iata": "",
      "name": "Ouakda Airport",
      "city": "Bechar",
      "state": "Bechar",
      "country": "DZ",
      "elevation": 2660,
      "lat": 31.6424999237,
      "lon": -2.1838901043,
      "tz": "Africa\/Algiers"
  },
  "DAOE": {
      "icao": "DAOE",
      "iata": "",
      "name": "Bou Sfer Airport",
      "city": "",
      "state": "Oran",
      "country": "DZ",
      "elevation": 187,
      "lat": 35.7354011536,
      "lon": -0.8053889871,
      "tz": "Africa\/Algiers"
  },
  "DAOF": {
      "icao": "DAOF",
      "iata": "TIN",
      "name": "Tindouf Airport",
      "city": "Tindouf",
      "state": "Tindouf",
      "country": "DZ",
      "elevation": 1453,
      "lat": 27.7003993988,
      "lon": -8.1670999527,
      "tz": "Africa\/Algiers"
  },
  "DAOI": {
      "icao": "DAOI",
      "iata": "QAS",
      "name": "Ech Cheliff Airport",
      "city": "",
      "state": "Chlef",
      "country": "DZ",
      "elevation": 463,
      "lat": 36.2126998901,
      "lon": 1.3317699432,
      "tz": "Africa\/Algiers"
  },
  "DAOL": {
      "icao": "DAOL",
      "iata": "TAF",
      "name": "Tafaraoui Airport",
      "city": "",
      "state": "Oran",
      "country": "DZ",
      "elevation": 367,
      "lat": 35.5424003601,
      "lon": -0.5322780013,
      "tz": "Africa\/Algiers"
  },
  "DAON": {
      "icao": "DAON",
      "iata": "TLM",
      "name": "Zenata \u2013 Messali El Hadj Airport",
      "city": "Tlemcen",
      "state": "Tlemcen",
      "country": "DZ",
      "elevation": 814,
      "lat": 35.0167007446,
      "lon": -1.4500000477,
      "tz": "Africa\/Algiers"
  },
  "DAOO": {
      "icao": "DAOO",
      "iata": "ORN",
      "name": "Es Senia Airport",
      "city": "Oran",
      "state": "Oran",
      "country": "DZ",
      "elevation": 295,
      "lat": 35.6239013672,
      "lon": -0.6211829782,
      "tz": "Africa\/Algiers"
  },
  "DAOR": {
      "icao": "DAOR",
      "iata": "CBH",
      "name": "Bechar Boudghene Ben Ali Lotfi Airport",
      "city": "Bechar",
      "state": "Bechar",
      "country": "DZ",
      "elevation": 2661,
      "lat": 31.6457004547,
      "lon": -2.2698600292,
      "tz": "Africa\/Algiers"
  },
  "DAOS": {
      "icao": "DAOS",
      "iata": "BFW",
      "name": "Sidi Bel Abbes Airport",
      "city": "",
      "state": "Sidi-Bel-Abbes",
      "country": "DZ",
      "elevation": 1614,
      "lat": 35.1717987061,
      "lon": -0.5932750106,
      "tz": "Africa\/Algiers"
  },
  "DAOV": {
      "icao": "DAOV",
      "iata": "MUW",
      "name": "Ghriss Airport",
      "city": "",
      "state": "Mascara",
      "country": "DZ",
      "elevation": 1686,
      "lat": 35.207698822,
      "lon": 0.147141993,
      "tz": "Africa\/Algiers"
  },
  "DAOY": {
      "icao": "DAOY",
      "iata": "EBH",
      "name": "El Bayadh Airport",
      "city": "El Bayadh",
      "state": "El-Bayadh",
      "country": "DZ",
      "elevation": 4493,
      "lat": 33.7216666667,
      "lon": 1.0925,
      "tz": "Africa\/Algiers"
  },
  "DATG": {
      "icao": "DATG",
      "iata": "INF",
      "name": "In Guezzam Airport",
      "city": "In Guezzam",
      "state": "Tamanrasset",
      "country": "DZ",
      "elevation": 1312,
      "lat": 19.5669994354,
      "lon": 5.75,
      "tz": "Africa\/Algiers"
  },
  "DATM": {
      "icao": "DATM",
      "iata": "BMW",
      "name": "Bordj Badji Mokhtar Airport",
      "city": "Bordj Badji Mokhtar",
      "state": "Adrar",
      "country": "DZ",
      "elevation": 1303,
      "lat": 21.375,
      "lon": 0.9238889813,
      "tz": "Africa\/Algiers"
  },
  "DAUA": {
      "icao": "DAUA",
      "iata": "AZR",
      "name": "Touat Cheikh Sidi Mohamed Belkebir Airport",
      "city": "",
      "state": "Adrar",
      "country": "DZ",
      "elevation": 919,
      "lat": 27.837600708,
      "lon": -0.1864140034,
      "tz": "Africa\/Algiers"
  },
  "DAUB": {
      "icao": "DAUB",
      "iata": "BSK",
      "name": "Biskra Airport",
      "city": "Biskra",
      "state": "Biskra",
      "country": "DZ",
      "elevation": 289,
      "lat": 34.7933006287,
      "lon": 5.7382302284,
      "tz": "Africa\/Algiers"
  },
  "DAUE": {
      "icao": "DAUE",
      "iata": "ELG",
      "name": "El Golea Airport",
      "city": "",
      "state": "Ghardaia",
      "country": "DZ",
      "elevation": 1306,
      "lat": 30.5713005066,
      "lon": 2.8595900536,
      "tz": "Africa\/Algiers"
  },
  "DAUG": {
      "icao": "DAUG",
      "iata": "GHA",
      "name": "Noumerat - Moufdi Zakaria Airport",
      "city": "Ghardaia",
      "state": "Ghardaia",
      "country": "DZ",
      "elevation": 1512,
      "lat": 32.3841018677,
      "lon": 3.7941100597,
      "tz": "Africa\/Algiers"
  },
  "DAUH": {
      "icao": "DAUH",
      "iata": "HME",
      "name": "Oued Irara Airport",
      "city": "Hassi Messaoud",
      "state": "Ouargla",
      "country": "DZ",
      "elevation": 463,
      "lat": 31.6730003357,
      "lon": 6.1404399872,
      "tz": "Africa\/Algiers"
  },
  "DAUI": {
      "icao": "DAUI",
      "iata": "INZ",
      "name": "In Salah Airport",
      "city": "In Salah",
      "state": "Tamanrasset",
      "country": "DZ",
      "elevation": 896,
      "lat": 27.2509994507,
      "lon": 2.5120201111,
      "tz": "Africa\/Algiers"
  },
  "DAUK": {
      "icao": "DAUK",
      "iata": "TGR",
      "name": "Touggourt Sidi Madhi Airport",
      "city": "Touggourt",
      "state": "Ouargla",
      "country": "DZ",
      "elevation": 279,
      "lat": 33.0677986145,
      "lon": 6.0886697769,
      "tz": "Africa\/Algiers"
  },
  "DAUL": {
      "icao": "DAUL",
      "iata": "LOO",
      "name": "Laghouat Airport",
      "city": "Laghouat",
      "state": "Laghouat",
      "country": "DZ",
      "elevation": 2510,
      "lat": 33.7644004822,
      "lon": 2.9283399582,
      "tz": "Africa\/Algiers"
  },
  "DAUO": {
      "icao": "DAUO",
      "iata": "ELU",
      "name": "Guemar Airport",
      "city": "Guemar",
      "state": "El-Oued",
      "country": "DZ",
      "elevation": 203,
      "lat": 33.5113983154,
      "lon": 6.7767901421,
      "tz": "Africa\/Algiers"
  },
  "DAUT": {
      "icao": "DAUT",
      "iata": "TMX",
      "name": "Timimoun Airport",
      "city": "Timimoun",
      "state": "Adrar",
      "country": "DZ",
      "elevation": 1027,
      "lat": 29.2371006012,
      "lon": 0.2760330141,
      "tz": "Africa\/Algiers"
  },
  "DAUU": {
      "icao": "DAUU",
      "iata": "OGX",
      "name": "Ain el Beida Airport",
      "city": "Ouargla",
      "state": "Ouargla",
      "country": "DZ",
      "elevation": 492,
      "lat": 31.9172000885,
      "lon": 5.412779808,
      "tz": "Africa\/Algiers"
  },
  "DAUZ": {
      "icao": "DAUZ",
      "iata": "IAM",
      "name": "In Amenas Airport",
      "city": "Amenas",
      "state": "Illizi",
      "country": "DZ",
      "elevation": 1847,
      "lat": 28.0515003204,
      "lon": 9.6429100037,
      "tz": "Africa\/Algiers"
  },
  "DBBB": {
      "icao": "DBBB",
      "iata": "COO",
      "name": "Cadjehoun Airport",
      "city": "Cotonou",
      "state": "Atlantique",
      "country": "BJ",
      "elevation": 19,
      "lat": 6.3572301865,
      "lon": 2.3843500614,
      "tz": "Africa\/Porto-Novo"
  },
  "DBBC": {
      "icao": "DBBC",
      "iata": "",
      "name": "Cana Airport",
      "city": "Bohicon",
      "state": "Zou",
      "country": "BJ",
      "elevation": 548,
      "lat": 7.1669998169,
      "lon": 2.0669999123,
      "tz": "Africa\/Porto-Novo"
  },
  "DBBD": {
      "icao": "DBBD",
      "iata": "DJA",
      "name": "Djougou Airport",
      "city": "Djougou",
      "state": "Atakora",
      "country": "BJ",
      "elevation": 1480,
      "lat": 9.6920833333,
      "lon": 1.6377777778,
      "tz": "Africa\/Porto-Novo"
  },
  "DBBK": {
      "icao": "DBBK",
      "iata": "KDC",
      "name": "Kandi Airport",
      "city": "Kandi",
      "state": "Alibori",
      "country": "BJ",
      "elevation": 951,
      "lat": 11.1330003738,
      "lon": 2.9330000877,
      "tz": "Africa\/Porto-Novo"
  },
  "DBBN": {
      "icao": "DBBN",
      "iata": "NAE",
      "name": "Natitingou Airport",
      "city": "Natitingou",
      "state": "",
      "country": "BJ",
      "elevation": 1411,
      "lat": 10.3170003891,
      "lon": 1.3830000162,
      "tz": "Africa\/Porto-Novo"
  },
  "DBBO": {
      "icao": "DBBO",
      "iata": "",
      "name": "Porga Airport",
      "city": "Porga",
      "state": "Atakora",
      "country": "BJ",
      "elevation": 531,
      "lat": 11.0329999924,
      "lon": 0.9829999804,
      "tz": "Africa\/Ouagadougou"
  },
  "DBBP": {
      "icao": "DBBP",
      "iata": "PKO",
      "name": "Parakou Airport",
      "city": "Parakou",
      "state": "Borgou",
      "country": "BJ",
      "elevation": 1266,
      "lat": 9.3576898575,
      "lon": 2.6096799374,
      "tz": "Africa\/Porto-Novo"
  },
  "DBBR": {
      "icao": "DBBR",
      "iata": "",
      "name": "Bembereke Airport",
      "city": "Bembereke",
      "state": "Borgou",
      "country": "BJ",
      "elevation": 1188,
      "lat": 10.2829999924,
      "lon": 2.7000000477,
      "tz": "Africa\/Porto-Novo"
  },
  "DBBS": {
      "icao": "DBBS",
      "iata": "SVF",
      "name": "Save Airport",
      "city": "Save",
      "state": "Zou",
      "country": "BJ",
      "elevation": 597,
      "lat": 8.0181703568,
      "lon": 2.4645800591,
      "tz": "Africa\/Porto-Novo"
  },
  "DE00": {
      "icao": "DE00",
      "iata": "",
      "name": "Doyle's Airport",
      "city": "Felton",
      "state": "Delaware",
      "country": "US",
      "elevation": 51,
      "lat": 39.0206985474,
      "lon": -75.5771026611,
      "tz": "America\/New_York"
  },
  "DE04": {
      "icao": "DE04",
      "iata": "",
      "name": "Newberg Airport",
      "city": "Smyrna",
      "state": "Delaware",
      "country": "US",
      "elevation": 20,
      "lat": 39.2916984558,
      "lon": -75.5749969482,
      "tz": "America\/New_York"
  },
  "DE07": {
      "icao": "DE07",
      "iata": "",
      "name": "Flying C Airport",
      "city": "Hartly",
      "state": "Delaware",
      "country": "US",
      "elevation": 70,
      "lat": 39.1442985535,
      "lon": -75.6912994385,
      "tz": "America\/New_York"
  },
  "DE09": {
      "icao": "DE09",
      "iata": "",
      "name": "Johnsons Airport",
      "city": "Magnolia",
      "state": "Delaware",
      "country": "US",
      "elevation": 43,
      "lat": 39.0681991577,
      "lon": -75.4885025024,
      "tz": "America\/New_York"
  },
  "DE10": {
      "icao": "DE10",
      "iata": "",
      "name": "Kimbowrosa Farm Airport",
      "city": "Milford",
      "state": "Delaware",
      "country": "US",
      "elevation": 40,
      "lat": 38.9668006897,
      "lon": -75.4663009644,
      "tz": "America\/New_York"
  },
  "DE11": {
      "icao": "DE11",
      "iata": "",
      "name": "Drummond Airport",
      "city": "Milford",
      "state": "Delaware",
      "country": "US",
      "elevation": 33,
      "lat": 38.9057006836,
      "lon": -75.4010009766,
      "tz": "America\/New_York"
  },
  "DE12": {
      "icao": "DE12",
      "iata": "",
      "name": "Owens Field",
      "city": "Ellendale",
      "state": "Delaware",
      "country": "US",
      "elevation": 50,
      "lat": 38.8056983948,
      "lon": -75.4126968384,
      "tz": "America\/New_York"
  },
  "DE14": {
      "icao": "DE14",
      "iata": "",
      "name": "Huey Airport",
      "city": "Bridgeville",
      "state": "Delaware",
      "country": "US",
      "elevation": 45,
      "lat": 38.7439994812,
      "lon": -75.5348968506,
      "tz": "America\/New_York"
  },
  "DE15": {
      "icao": "DE15",
      "iata": "",
      "name": "Pevey Airport",
      "city": "Seaford",
      "state": "Delaware",
      "country": "US",
      "elevation": 40,
      "lat": 38.6125984192,
      "lon": -75.6996994019,
      "tz": "America\/New_York"
  },
  "DE17": {
      "icao": "DE17",
      "iata": "",
      "name": "Sugar Hill Airport",
      "city": "Greenwood",
      "state": "Delaware",
      "country": "US",
      "elevation": 45,
      "lat": 38.7779006958,
      "lon": -75.5832977295,
      "tz": "America\/New_York"
  },
  "DE19": {
      "icao": "DE19",
      "iata": "",
      "name": "Duffy's Airport",
      "city": "Townsend",
      "state": "Delaware",
      "country": "US",
      "elevation": 69,
      "lat": 39.3258018494,
      "lon": -75.7416000366,
      "tz": "America\/New_York"
  },
  "DE20": {
      "icao": "DE20",
      "iata": "",
      "name": "Spirit Airpark",
      "city": "Townsend",
      "state": "Delaware",
      "country": "US",
      "elevation": 80,
      "lat": 39.3736000061,
      "lon": -75.7457962036,
      "tz": "America\/New_York"
  },
  "DE21": {
      "icao": "DE21",
      "iata": "",
      "name": "West Private Airport",
      "city": "Roxana",
      "state": "Delaware",
      "country": "US",
      "elevation": 21,
      "lat": 38.5172996521,
      "lon": -75.1654968262,
      "tz": "America\/New_York"
  },
  "DE23": {
      "icao": "DE23",
      "iata": "",
      "name": "Ockel Farms Airport",
      "city": "Milton",
      "state": "Delaware",
      "country": "US",
      "elevation": 47,
      "lat": 38.7503013611,
      "lon": -75.3600006104,
      "tz": "America\/New_York"
  },
  "DE25": {
      "icao": "DE25",
      "iata": "",
      "name": "Eagle Crest-Hudson Airport",
      "city": "Milton",
      "state": "Delaware",
      "country": "US",
      "elevation": 28,
      "lat": 38.7761001587,
      "lon": -75.2335968018,
      "tz": "America\/New_York"
  },
  "DE27": {
      "icao": "DE27",
      "iata": "",
      "name": "Warrington Field",
      "city": "Selbyville",
      "state": "Delaware",
      "country": "US",
      "elevation": 26,
      "lat": 38.462600708,
      "lon": -75.1748962402,
      "tz": "America\/New_York"
  },
  "DE29": {
      "icao": "DE29",
      "iata": "",
      "name": "Scotty's Place Airport",
      "city": "Smyrna",
      "state": "Delaware",
      "country": "US",
      "elevation": 40,
      "lat": 39.3750991821,
      "lon": -75.6440963745,
      "tz": "America\/New_York"
  },
  "DE32": {
      "icao": "DE32",
      "iata": "",
      "name": "Belfair Airport",
      "city": "Felton",
      "state": "Delaware",
      "country": "US",
      "elevation": 64,
      "lat": 38.9749984741,
      "lon": -75.6166992188,
      "tz": "America\/New_York"
  },
  "DE33": {
      "icao": "DE33",
      "iata": "",
      "name": "Okolona Plantation Airport",
      "city": "Middletown",
      "state": "Delaware",
      "country": "US",
      "elevation": 61,
      "lat": 39.48279953,
      "lon": -75.6975021362,
      "tz": "America\/New_York"
  },
  "DE34": {
      "icao": "DE34",
      "iata": "",
      "name": "Townsend A Airport",
      "city": "Townsend",
      "state": "Delaware",
      "country": "US",
      "elevation": 65,
      "lat": 39.4142990112,
      "lon": -75.6630020142,
      "tz": "America\/New_York"
  },
  "DE49": {
      "icao": "DE49",
      "iata": "",
      "name": "Josephs Airport",
      "city": "Georgetown",
      "state": "Delaware",
      "country": "US",
      "elevation": 42,
      "lat": 38.6375999451,
      "lon": -75.3552017212,
      "tz": "America\/New_York"
  },
  "DFCA": {
      "icao": "DFCA",
      "iata": "XKY",
      "name": "Kaya Airport",
      "city": "Kaya",
      "state": "",
      "country": "BF",
      "elevation": 984,
      "lat": 13.0670003891,
      "lon": -1.1000000238,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCB": {
      "icao": "DFCB",
      "iata": "",
      "name": "Barsalogho Airport",
      "city": "Barsalogho",
      "state": "",
      "country": "BF",
      "elevation": 1083,
      "lat": 13.3999996185,
      "lon": -1.0670000315,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCC": {
      "icao": "DFCC",
      "iata": "OUG",
      "name": "Ouahigouya Airport",
      "city": "Ouahigouya",
      "state": "",
      "country": "BF",
      "elevation": 1106,
      "lat": 13.5670003891,
      "lon": -2.4170000553,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCD": {
      "icao": "DFCD",
      "iata": "",
      "name": "Didyr Airport",
      "city": "Didyr",
      "state": "Centre-Ouest",
      "country": "BF",
      "elevation": 958,
      "lat": 12.5500001907,
      "lon": -2.617000103,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCE": {
      "icao": "DFCE",
      "iata": "",
      "name": "Batie Airport",
      "city": "Batie",
      "state": "Sud-Ouest",
      "country": "BF",
      "elevation": 984,
      "lat": 9.8830003738,
      "lon": -2.9170000553,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCG": {
      "icao": "DFCG",
      "iata": "",
      "name": "Kongoussi Airport",
      "city": "Kongoussi",
      "state": "Centre-Nord",
      "country": "BF",
      "elevation": 1056,
      "lat": 13.3170003891,
      "lon": -1.5329999924,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCI": {
      "icao": "DFCI",
      "iata": "",
      "name": "Titao Airport",
      "city": "Titao",
      "state": "Nord",
      "country": "BF",
      "elevation": 1047,
      "lat": 13.7670001984,
      "lon": -2.0829999447,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCJ": {
      "icao": "DFCJ",
      "iata": "XDJ",
      "name": "Djibo Airport",
      "city": "Djibo",
      "state": "Sahel",
      "country": "BF",
      "elevation": 1001,
      "lat": 14.1000003815,
      "lon": -1.6330000162,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCK": {
      "icao": "DFCK",
      "iata": "",
      "name": "Koudougou Airport",
      "city": "Koudougou",
      "state": "Centre-Ouest",
      "country": "BF",
      "elevation": 1001,
      "lat": 12.2670001984,
      "lon": -2.4000000954,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCL": {
      "icao": "DFCL",
      "iata": "XLU",
      "name": "Leo Airport",
      "city": "Leo",
      "state": "",
      "country": "BF",
      "elevation": 1181,
      "lat": 11.1000003815,
      "lon": -2.0999999046,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCM": {
      "icao": "DFCM",
      "iata": "",
      "name": "Manga Airport",
      "city": "Manga",
      "state": "Centre-Sud",
      "country": "BF",
      "elevation": 820,
      "lat": 11.6669998169,
      "lon": -1.0670000315,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCP": {
      "icao": "DFCP",
      "iata": "PUP",
      "name": "Po Airport",
      "city": "Po",
      "state": "Centre-Sud",
      "country": "BF",
      "elevation": 1050,
      "lat": 11.1499996185,
      "lon": -1.1499999762,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCR": {
      "icao": "DFCR",
      "iata": "",
      "name": "Poura Airport",
      "city": "Poura",
      "state": "Boucle-du-Mouhoun",
      "country": "BF",
      "elevation": 853,
      "lat": 11.6169996262,
      "lon": -2.75,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCS": {
      "icao": "DFCS",
      "iata": "",
      "name": "Seguenega Airport",
      "city": "Seguenega",
      "state": "Nord",
      "country": "BF",
      "elevation": 1139,
      "lat": 13.4329996109,
      "lon": -2,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCT": {
      "icao": "DFCT",
      "iata": "",
      "name": "Tenado Airport",
      "city": "Tenado",
      "state": "Centre-Ouest",
      "country": "BF",
      "elevation": 951,
      "lat": 12.1999998093,
      "lon": -2.5829999447,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCU": {
      "icao": "DFCU",
      "iata": "",
      "name": "Gourcy Airport",
      "city": "Gourcy",
      "state": "Nord",
      "country": "BF",
      "elevation": 1050,
      "lat": 13.1999998093,
      "lon": -2.367000103,
      "tz": "Africa\/Ouagadougou"
  },
  "DFCY": {
      "icao": "DFCY",
      "iata": "",
      "name": "Yako Airport",
      "city": "Yako",
      "state": "Nord",
      "country": "BF",
      "elevation": 984,
      "lat": 12.9499998093,
      "lon": -2.2829999924,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEA": {
      "icao": "DFEA",
      "iata": "XBO",
      "name": "Boulsa Airport",
      "city": "Boulsa",
      "state": "",
      "country": "BF",
      "elevation": 984,
      "lat": 12.6499996185,
      "lon": -0.5669999719,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEB": {
      "icao": "DFEB",
      "iata": "XBG",
      "name": "Bogande Airport",
      "city": "Bogande",
      "state": "",
      "country": "BF",
      "elevation": 984,
      "lat": 12.9829998016,
      "lon": -0.1669999957,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEC": {
      "icao": "DFEC",
      "iata": "",
      "name": "Komin-Yanga Airport",
      "city": "Komin-Yanga",
      "state": "Centre-Est",
      "country": "BF",
      "elevation": 1116,
      "lat": 11.6999998093,
      "lon": 0.150000006,
      "tz": "Africa\/Ouagadougou"
  },
  "DFED": {
      "icao": "DFED",
      "iata": "DIP",
      "name": "Diapaga Airport",
      "city": "Diapaga",
      "state": "",
      "country": "BF",
      "elevation": 951,
      "lat": 12.0500001907,
      "lon": 1.7829999924,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEE": {
      "icao": "DFEE",
      "iata": "DOR",
      "name": "Dori Airport",
      "city": "Dori",
      "state": "Sahel",
      "country": "BF",
      "elevation": 919,
      "lat": 14.0329999924,
      "lon": -0.0329999998,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEF": {
      "icao": "DFEF",
      "iata": "FNG",
      "name": "Fada N'gourma Airport",
      "city": "Fada N'gourma",
      "state": "Est",
      "country": "BF",
      "elevation": 1011,
      "lat": 12.0329999924,
      "lon": 0.349999994,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEG": {
      "icao": "DFEG",
      "iata": "XGG",
      "name": "Gorom-Gorom Airport",
      "city": "Gorom-Gorom",
      "state": "Sahel",
      "country": "BF",
      "elevation": 935,
      "lat": 14.4499998093,
      "lon": -0.2329999954,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEK": {
      "icao": "DFEK",
      "iata": "",
      "name": "Koupela Airport",
      "city": "Koupela",
      "state": "Centre-Est",
      "country": "BF",
      "elevation": 902,
      "lat": 12.1669998169,
      "lon": -0.3000000119,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEL": {
      "icao": "DFEL",
      "iata": "XKA",
      "name": "Kantchari Airport",
      "city": "Kantchari",
      "state": "",
      "country": "BF",
      "elevation": 879,
      "lat": 12.4670000076,
      "lon": 1.5,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEM": {
      "icao": "DFEM",
      "iata": "TMQ",
      "name": "Tambao Airport",
      "city": "Tambao",
      "state": "Sahel",
      "country": "BF",
      "elevation": 820,
      "lat": 14.8000001907,
      "lon": 0.0500000007,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEO": {
      "icao": "DFEO",
      "iata": "",
      "name": "Zorgo Airport",
      "city": "Zorgo",
      "state": "Plateau-Central",
      "country": "BF",
      "elevation": 978,
      "lat": 12.25,
      "lon": -0.6169999838,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEP": {
      "icao": "DFEP",
      "iata": "XPA",
      "name": "Pama Airport",
      "city": "Pama",
      "state": "",
      "country": "BF",
      "elevation": 699,
      "lat": 11.25,
      "lon": 0.6999999881,
      "tz": "Africa\/Ouagadougou"
  },
  "DFER": {
      "icao": "DFER",
      "iata": "ARL",
      "name": "Arly Airport",
      "city": "Arly",
      "state": "",
      "country": "BF",
      "elevation": 761,
      "lat": 11.5970001221,
      "lon": 1.4830000401,
      "tz": "Africa\/Ouagadougou"
  },
  "DFES": {
      "icao": "DFES",
      "iata": "XSE",
      "name": "Sebba Airport",
      "city": "Sebba",
      "state": "Sahel",
      "country": "BF",
      "elevation": 886,
      "lat": 13.4499998093,
      "lon": 0.5170000196,
      "tz": "Africa\/Ouagadougou"
  },
  "DFET": {
      "icao": "DFET",
      "iata": "TEG",
      "name": "Tenkodogo Airport",
      "city": "Tenkodogo",
      "state": "",
      "country": "BF",
      "elevation": 1017,
      "lat": 11.8000001907,
      "lon": -0.3670000136,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEY": {
      "icao": "DFEY",
      "iata": "",
      "name": "Ouargaye Airport",
      "city": "Ouargaye",
      "state": "Centre-Est",
      "country": "BF",
      "elevation": 951,
      "lat": 11.5170001984,
      "lon": 0.0500000007,
      "tz": "Africa\/Ouagadougou"
  },
  "DFEZ": {
      "icao": "DFEZ",
      "iata": "XZA",
      "name": "Zabre Airport",
      "city": "Zabre",
      "state": "",
      "country": "BF",
      "elevation": 886,
      "lat": 11.1669998169,
      "lon": -0.6169999838,
      "tz": "Africa\/Ouagadougou"
  },
  "DFFD": {
      "icao": "DFFD",
      "iata": "OUA",
      "name": "Ouagadougou Airport",
      "city": "Ouagadougou",
      "state": "",
      "country": "BF",
      "elevation": 1037,
      "lat": 12.3531999588,
      "lon": -1.5124200583,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOA": {
      "icao": "DFOA",
      "iata": "",
      "name": "Dano Airport",
      "city": "Dano",
      "state": "Sud-Ouest",
      "country": "BF",
      "elevation": 935,
      "lat": 11.1330003738,
      "lon": -3.0669999123,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOB": {
      "icao": "DFOB",
      "iata": "BNR",
      "name": "Banfora Airport",
      "city": "Banfora",
      "state": "Cascades",
      "country": "BF",
      "elevation": 984,
      "lat": 10.6829996109,
      "lon": -4.7170000076,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOD": {
      "icao": "DFOD",
      "iata": "DGU",
      "name": "Dedougou Airport",
      "city": "Dedougou",
      "state": "",
      "country": "BF",
      "elevation": 984,
      "lat": 12.4589996338,
      "lon": -3.4900000095,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOF": {
      "icao": "DFOF",
      "iata": "",
      "name": "Safane Airport",
      "city": "Safane",
      "state": "Boucle-du-Mouhoun",
      "country": "BF",
      "elevation": 1017,
      "lat": 12.1499996185,
      "lon": -3.1830000877,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOG": {
      "icao": "DFOG",
      "iata": "XGA",
      "name": "Gaoua Airport",
      "city": "Gaoua",
      "state": "Sud-Ouest",
      "country": "BF",
      "elevation": 912,
      "lat": 10.299200058,
      "lon": -3.2508299351,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOH": {
      "icao": "DFOH",
      "iata": "",
      "name": "Hounde Airport",
      "city": "Hounde",
      "state": "Hauts-Bassins",
      "country": "BF",
      "elevation": 1063,
      "lat": 11.4829998016,
      "lon": -3.5169999599,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOL": {
      "icao": "DFOL",
      "iata": "",
      "name": "Loumana Airport",
      "city": "Loumana",
      "state": "Cascades",
      "country": "BF",
      "elevation": 1148,
      "lat": 10.5670003891,
      "lon": -5.3499999046,
      "tz": "Africa\/Ouagadougou"
  },
  "DFON": {
      "icao": "DFON",
      "iata": "XNU",
      "name": "Nouna Airport",
      "city": "Nouna",
      "state": "",
      "country": "BF",
      "elevation": 886,
      "lat": 12.75,
      "lon": -3.867000103,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOO": {
      "icao": "DFOO",
      "iata": "BOY",
      "name": "Bobo Dioulasso Airport",
      "city": "Bobo Dioulasso",
      "state": "Hauts-Bassins",
      "country": "BF",
      "elevation": 1511,
      "lat": 11.1600999832,
      "lon": -4.3309698105,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOR": {
      "icao": "DFOR",
      "iata": "",
      "name": "Orodara Airport",
      "city": "Orodara",
      "state": "",
      "country": "BF",
      "elevation": 1706,
      "lat": 10.9829998016,
      "lon": -4.9330000877,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOS": {
      "icao": "DFOS",
      "iata": "",
      "name": "Sideradougou Airport",
      "city": "Sideradougou",
      "state": "Cascades",
      "country": "BF",
      "elevation": 1047,
      "lat": 10.6669998169,
      "lon": -4.2670001984,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOT": {
      "icao": "DFOT",
      "iata": "TUQ",
      "name": "Tougan Airport",
      "city": "Tougan",
      "state": "Boucle-du-Mouhoun",
      "country": "BF",
      "elevation": 984,
      "lat": 13.0670003891,
      "lon": -3.0669999123,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOU": {
      "icao": "DFOU",
      "iata": "XDE",
      "name": "Diebougou Airport",
      "city": "Diebougou",
      "state": "",
      "country": "BF",
      "elevation": 984,
      "lat": 10.9499998093,
      "lon": -3.25,
      "tz": "Africa\/Ouagadougou"
  },
  "DFOY": {
      "icao": "DFOY",
      "iata": "XAR",
      "name": "Aribinda Airport",
      "city": "Aribinda",
      "state": "Sahel",
      "country": "BF",
      "elevation": 1122,
      "lat": 14.2170000076,
      "lon": -0.8830000162,
      "tz": "Africa\/Ouagadougou"
  },
  "DGAA": {
      "icao": "DGAA",
      "iata": "ACC",
      "name": "Kotoka International Airport",
      "city": "Accra",
      "state": "",
      "country": "GH",
      "elevation": 205,
      "lat": 5.6051898003,
      "lon": -0.1667860001,
      "tz": "Africa\/Accra"
  },
  "DGLE": {
      "icao": "DGLE",
      "iata": "TML",
      "name": "Tamale Airport",
      "city": "Tamale",
      "state": "",
      "country": "GH",
      "elevation": 553,
      "lat": 9.5571899414,
      "lon": -0.863214016,
      "tz": "Africa\/Accra"
  },
  "DGLN": {
      "icao": "DGLN",
      "iata": "",
      "name": "Navrongo Airport",
      "city": "Navrongo",
      "state": "",
      "country": "GH",
      "elevation": 2221,
      "lat": 10.8999996185,
      "lon": -1.1000000238,
      "tz": "Africa\/Accra"
  },
  "DGLW": {
      "icao": "DGLW",
      "iata": "",
      "name": "Wa Airport",
      "city": "Wa",
      "state": "",
      "country": "GH",
      "elevation": 1060,
      "lat": 10.0826997757,
      "lon": -2.5076899529,
      "tz": "Africa\/Accra"
  },
  "DGLY": {
      "icao": "DGLY",
      "iata": "",
      "name": "Yendi Airport",
      "city": "Yendi",
      "state": "Northern",
      "country": "GH",
      "elevation": 2408,
      "lat": 9.4250001907,
      "lon": -0.0047220001,
      "tz": "Africa\/Accra"
  },
  "DGSI": {
      "icao": "DGSI",
      "iata": "KMS",
      "name": "Kumasi Airport",
      "city": "Kumasi",
      "state": "",
      "country": "GH",
      "elevation": 942,
      "lat": 6.7145600319,
      "lon": -1.5908199549,
      "tz": "Africa\/Accra"
  },
  "DGSN": {
      "icao": "DGSN",
      "iata": "NYI",
      "name": "Sunyani Airport",
      "city": "Sunyani",
      "state": "Brong-Ahafo",
      "country": "GH",
      "elevation": 1014,
      "lat": 7.3618302345,
      "lon": -2.3287599087,
      "tz": "Africa\/Accra"
  },
  "DGTK": {
      "icao": "DGTK",
      "iata": "TKD",
      "name": "Takoradi Airport",
      "city": "Sekondi-Takoradi",
      "state": "Western",
      "country": "GH",
      "elevation": 21,
      "lat": 4.8960599899,
      "lon": -1.7747600079,
      "tz": "Africa\/Accra"
  },
  "DIAP": {
      "icao": "DIAP",
      "iata": "ABJ",
      "name": "Port Bouet Airport",
      "city": "Abidjan",
      "state": "Lagunes",
      "country": "CI",
      "elevation": 21,
      "lat": 5.2613902092,
      "lon": -3.9262900352,
      "tz": "Africa\/Abidjan"
  },
  "DIAU": {
      "icao": "DIAU",
      "iata": "OGO",
      "name": "Abengourou Airport",
      "city": "Abengourou",
      "state": "",
      "country": "CI",
      "elevation": 676,
      "lat": 6.7155599594,
      "lon": -3.470279932,
      "tz": "Africa\/Abidjan"
  },
  "DIBC": {
      "icao": "DIBC",
      "iata": "",
      "name": "Bocanda Airport",
      "city": "Bocanda",
      "state": "",
      "country": "CI",
      "elevation": 427,
      "lat": 7.0329999924,
      "lon": -4.5329999924,
      "tz": "Africa\/Abidjan"
  },
  "DIBI": {
      "icao": "DIBI",
      "iata": "BXI",
      "name": "Boundiali Airport",
      "city": "Boundiali",
      "state": "",
      "country": "CI",
      "elevation": 1286,
      "lat": 9.5329999924,
      "lon": -6.4670000076,
      "tz": "Africa\/Abidjan"
  },
  "DIBK": {
      "icao": "DIBK",
      "iata": "BYK",
      "name": "Bouake Airport",
      "city": "",
      "state": "Vallee-du-Bandama",
      "country": "CI",
      "elevation": 1230,
      "lat": 7.7388000488,
      "lon": -5.0736699104,
      "tz": "Africa\/Abidjan"
  },
  "DIBN": {
      "icao": "DIBN",
      "iata": "BQO",
      "name": "Bouna Airport",
      "city": "Bouna",
      "state": "",
      "country": "CI",
      "elevation": 1148,
      "lat": 9.2775001526,
      "lon": -3.0252799988,
      "tz": "Africa\/Abidjan"
  },
  "DIBU": {
      "icao": "DIBU",
      "iata": "BDK",
      "name": "Soko Airport",
      "city": "Bondoukou",
      "state": "",
      "country": "CI",
      "elevation": 1247,
      "lat": 8.0172195435,
      "lon": -2.7619400024,
      "tz": "Africa\/Abidjan"
  },
  "DIDB": {
      "icao": "DIDB",
      "iata": "",
      "name": "Dabou Airport",
      "city": "Dabou",
      "state": "",
      "country": "CI",
      "elevation": 141,
      "lat": 5.3513851166,
      "lon": -4.4034576416,
      "tz": "Africa\/Abidjan"
  },
  "DIDK": {
      "icao": "DIDK",
      "iata": "DIM",
      "name": "Dimbokro Airport",
      "city": "Dimbokro",
      "state": "Lacs",
      "country": "CI",
      "elevation": 344,
      "lat": 6.6516699791,
      "lon": -4.6405601501,
      "tz": "Africa\/Abidjan"
  },
  "DIDL": {
      "icao": "DIDL",
      "iata": "DJO",
      "name": "Daloa Airport",
      "city": "",
      "state": "Sassandra-Marahoue",
      "country": "CI",
      "elevation": 823,
      "lat": 6.7928099632,
      "lon": -6.4731898308,
      "tz": "Africa\/Abidjan"
  },
  "DIFK": {
      "icao": "DIFK",
      "iata": "FEK",
      "name": "Ferkessedougou Airport",
      "city": "Ferkessedougou",
      "state": "",
      "country": "CI",
      "elevation": 1102,
      "lat": 9.6000003815,
      "lon": -5.1999998093,
      "tz": "Africa\/Abidjan"
  },
  "DIGA": {
      "icao": "DIGA",
      "iata": "GGN",
      "name": "Gagnoa Airport",
      "city": "Gagnoa",
      "state": "Goh-Djiboua",
      "country": "CI",
      "elevation": 732,
      "lat": 6.132999897,
      "lon": -5.9499998093,
      "tz": "Africa\/Abidjan"
  },
  "DIGL": {
      "icao": "DIGL",
      "iata": "GGO",
      "name": "Guiglo Airport",
      "city": "Guiglo",
      "state": "",
      "country": "CI",
      "elevation": 722,
      "lat": 6.5249900818,
      "lon": -7.4786100388,
      "tz": "Africa\/Abidjan"
  },
  "DIGN": {
      "icao": "DIGN",
      "iata": "BBV",
      "name": "Nero-Mer Airport",
      "city": "Grand-Bereby",
      "state": "Bas-Sassandra",
      "country": "CI",
      "elevation": 20,
      "lat": 4.6434130669,
      "lon": -6.9239616394,
      "tz": "Africa\/Abidjan"
  },
  "DIKO": {
      "icao": "DIKO",
      "iata": "HGO",
      "name": "Korhogo Airport",
      "city": "",
      "state": "Savanes",
      "country": "CI",
      "elevation": 1214,
      "lat": 9.3871803284,
      "lon": -5.5566601753,
      "tz": "Africa\/Abidjan"
  },
  "DIMN": {
      "icao": "DIMN",
      "iata": "MJC",
      "name": "Man Airport",
      "city": "",
      "state": "Montagnes",
      "country": "CI",
      "elevation": 1089,
      "lat": 7.272069931,
      "lon": -7.5873599052,
      "tz": "Africa\/Abidjan"
  },
  "DIOD": {
      "icao": "DIOD",
      "iata": "KEO",
      "name": "Odienne Airport",
      "city": "Odienne",
      "state": "Denguele",
      "country": "CI",
      "elevation": 1365,
      "lat": 9.5,
      "lon": -7.5669999123,
      "tz": "Africa\/Abidjan"
  },
  "DIOF": {
      "icao": "DIOF",
      "iata": "OFI",
      "name": "Ouango Fitini Airport",
      "city": "Ouango Fitini",
      "state": "",
      "country": "CI",
      "elevation": 974,
      "lat": 9.6000003815,
      "lon": -4.0500001907,
      "tz": "Africa\/Abidjan"
  },
  "DISG": {
      "icao": "DISG",
      "iata": "SEO",
      "name": "Seguela Airport",
      "city": "Seguela",
      "state": "Woroba",
      "country": "CI",
      "elevation": 1056,
      "lat": 7.9683299065,
      "lon": -6.7108302116,
      "tz": "Africa\/Abidjan"
  },
  "DISP": {
      "icao": "DISP",
      "iata": "SPY",
      "name": "San Pedro Airport",
      "city": "",
      "state": "Bas-Sassandra",
      "country": "CI",
      "elevation": 26,
      "lat": 4.7467198372,
      "lon": -6.6608200073,
      "tz": "Africa\/Abidjan"
  },
  "DISS": {
      "icao": "DISS",
      "iata": "ZSS",
      "name": "Sassandra Airport",
      "city": "Sassandra",
      "state": "",
      "country": "CI",
      "elevation": 203,
      "lat": 4.9283299446,
      "lon": -6.1327800751,
      "tz": "Africa\/Abidjan"
  },
  "DITB": {
      "icao": "DITB",
      "iata": "TXU",
      "name": "Tabou Airport",
      "city": "Tabou",
      "state": "",
      "country": "CI",
      "elevation": 39,
      "lat": 4.4378094673,
      "lon": -7.3627281189,
      "tz": "Africa\/Abidjan"
  },
  "DIYO": {
      "icao": "DIYO",
      "iata": "ASK",
      "name": "Yamoussoukro Airport",
      "city": "Yamoussoukro",
      "state": "Yamoussoukro-Autonomous-District",
      "country": "CI",
      "elevation": 699,
      "lat": 6.9031701088,
      "lon": -5.3655800819,
      "tz": "Africa\/Abidjan"
  },
  "DN50": {
      "icao": "DN50",
      "iata": "",
      "name": "Shiroro Airport",
      "city": "",
      "state": "Niger",
      "country": "NG",
      "elevation": 1305,
      "lat": 9.8846502304,
      "lon": 6.8186302185,
      "tz": "Africa\/Lagos"
  },
  "DN51": {
      "icao": "DN51",
      "iata": "",
      "name": "Ajaokuta Airport",
      "city": "",
      "state": "Kogi",
      "country": "NG",
      "elevation": 620,
      "lat": 7.4572401047,
      "lon": 6.4611301422,
      "tz": "Africa\/Lagos"
  },
  "DN53": {
      "icao": "DN53",
      "iata": "",
      "name": "Kaduna Old Airport",
      "city": "",
      "state": "Kaduna",
      "country": "NG",
      "elevation": 2126,
      "lat": 10.5988998413,
      "lon": 7.4487099648,
      "tz": "Africa\/Lagos"
  },
  "DN54": {
      "icao": "DN54",
      "iata": "",
      "name": "Bajoga Northeast Airport",
      "city": "Bajoga",
      "state": "Gombe",
      "country": "NG",
      "elevation": 1000,
      "lat": 10.9198999405,
      "lon": 11.5011997223,
      "tz": "Africa\/Lagos"
  },
  "DN55": {
      "icao": "DN55",
      "iata": "",
      "name": "Eket Airport",
      "city": "",
      "state": "Akwa-Ibom",
      "country": "NG",
      "elevation": 42,
      "lat": 4.642179966,
      "lon": 7.9490399361,
      "tz": "Africa\/Lagos"
  },
  "DN56": {
      "icao": "DN56",
      "iata": "",
      "name": "Escravos Airport",
      "city": "",
      "state": "Delta",
      "country": "NG",
      "elevation": 6,
      "lat": 5.6253199577,
      "lon": 5.1923799515,
      "tz": "Africa\/Lagos"
  },
  "DNAA": {
      "icao": "DNAA",
      "iata": "ABV",
      "name": "Nnamdi Azikiwe International Airport",
      "city": "Abuja",
      "state": "FCT",
      "country": "NG",
      "elevation": 1123,
      "lat": 9.0067901611,
      "lon": 7.2631697655,
      "tz": "Africa\/Lagos"
  },
  "DNAK": {
      "icao": "DNAK",
      "iata": "AKR",
      "name": "Akure Airport",
      "city": "Akure",
      "state": "Ondo",
      "country": "NG",
      "elevation": 1100,
      "lat": 7.2467398643,
      "lon": 5.3010101318,
      "tz": "Africa\/Lagos"
  },
  "DNAS": {
      "icao": "DNAS",
      "iata": "ABB",
      "name": "Asaba International Airport",
      "city": "Asaba",
      "state": "Delta",
      "country": "NG",
      "elevation": 305,
      "lat": 6.2033333333,
      "lon": 6.6588888889,
      "tz": "Africa\/Lagos"
  },
  "DNBE": {
      "icao": "DNBE",
      "iata": "BNI",
      "name": "Benin Airport",
      "city": "Benin",
      "state": "Edo",
      "country": "NG",
      "elevation": 258,
      "lat": 6.3169798851,
      "lon": 5.5995001793,
      "tz": "Africa\/Lagos"
  },
  "DNBI": {
      "icao": "DNBI",
      "iata": "",
      "name": "Bida Airport",
      "city": "Bida",
      "state": "Niger",
      "country": "NG",
      "elevation": 450,
      "lat": 9.1000003815,
      "lon": 6.0170001984,
      "tz": "Africa\/Lagos"
  },
  "DNCA": {
      "icao": "DNCA",
      "iata": "CBQ",
      "name": "Margaret Ekpo International Airport",
      "city": "Calabar",
      "state": "Cross-River",
      "country": "NG",
      "elevation": 210,
      "lat": 4.9760198593,
      "lon": 8.3472003937,
      "tz": "Africa\/Lagos"
  },
  "DNEN": {
      "icao": "DNEN",
      "iata": "ENU",
      "name": "Akanu Ibiam International Airport",
      "city": "Enegu",
      "state": "Enugu",
      "country": "NG",
      "elevation": 466,
      "lat": 6.4742698669,
      "lon": 7.5619602203,
      "tz": "Africa\/Lagos"
  },
  "DNGO": {
      "icao": "DNGO",
      "iata": "GMO",
      "name": "Gombe Lawanti International Airport",
      "city": "Gombe",
      "state": "Bauchi",
      "country": "NG",
      "elevation": 1590,
      "lat": 10.2983333333,
      "lon": 10.8963888889,
      "tz": "Africa\/Lagos"
  },
  "DNGU": {
      "icao": "DNGU",
      "iata": "QUS",
      "name": "Gusau Airport",
      "city": "Gusau",
      "state": "Zamfara",
      "country": "NG",
      "elevation": 1520,
      "lat": 12.1716995239,
      "lon": 6.6961097717,
      "tz": "Africa\/Lagos"
  },
  "DNIB": {
      "icao": "DNIB",
      "iata": "IBA",
      "name": "Ibadan Airport",
      "city": "Ibadan",
      "state": "Oyo",
      "country": "NG",
      "elevation": 725,
      "lat": 7.3624601364,
      "lon": 3.9783298969,
      "tz": "Africa\/Lagos"
  },
  "DNIL": {
      "icao": "DNIL",
      "iata": "ILR",
      "name": "Ilorin International Airport",
      "city": "Ilorin",
      "state": "Kwara",
      "country": "NG",
      "elevation": 1126,
      "lat": 8.4402103424,
      "lon": 4.4939198494,
      "tz": "Africa\/Lagos"
  },
  "DNIM": {
      "icao": "DNIM",
      "iata": "QOW",
      "name": "Sam Mbakwe International Airport",
      "city": "Owerri",
      "state": "Imo",
      "country": "NG",
      "elevation": 373,
      "lat": 5.4270601273,
      "lon": 7.206029892,
      "tz": "Africa\/Lagos"
  },
  "DNJO": {
      "icao": "DNJO",
      "iata": "JOS",
      "name": "Yakubu Gowon Airport",
      "city": "Jos",
      "state": "Plateau",
      "country": "NG",
      "elevation": 4232,
      "lat": 9.6398296356,
      "lon": 8.8690500259,
      "tz": "Africa\/Lagos"
  },
  "DNKA": {
      "icao": "DNKA",
      "iata": "KAD",
      "name": "Kaduna Airport",
      "city": "Kaduna",
      "state": "Kaduna",
      "country": "NG",
      "elevation": 2073,
      "lat": 10.6960000992,
      "lon": 7.3201098442,
      "tz": "Africa\/Lagos"
  },
  "DNKN": {
      "icao": "DNKN",
      "iata": "KAN",
      "name": "Mallam Aminu International Airport",
      "city": "Kano",
      "state": "Kano",
      "country": "NG",
      "elevation": 1562,
      "lat": 12.0475997925,
      "lon": 8.5246200562,
      "tz": "Africa\/Lagos"
  },
  "DNMA": {
      "icao": "DNMA",
      "iata": "MIU",
      "name": "Maiduguri International Airport",
      "city": "Maiduguri",
      "state": "Borno",
      "country": "NG",
      "elevation": 1099,
      "lat": 11.8552999496,
      "lon": 13.0809001923,
      "tz": "Africa\/Lagos"
  },
  "DNMK": {
      "icao": "DNMK",
      "iata": "MDI",
      "name": "Makurdi Airport",
      "city": "Makurdi",
      "state": "Benue",
      "country": "NG",
      "elevation": 371,
      "lat": 7.7038798332,
      "lon": 8.613940239,
      "tz": "Africa\/Lagos"
  },
  "DNMM": {
      "icao": "DNMM",
      "iata": "LOS",
      "name": "Murtala Muhammed International Airport",
      "city": "Lagos",
      "state": "Lagos",
      "country": "NG",
      "elevation": 135,
      "lat": 6.5773701668,
      "lon": 3.321160078,
      "tz": "Africa\/Lagos"
  },
  "DNMN": {
      "icao": "DNMN",
      "iata": "MXJ",
      "name": "Minna Airport",
      "city": "Minna",
      "state": "Niger",
      "country": "NG",
      "elevation": 834,
      "lat": 9.6521701813,
      "lon": 6.4622597694,
      "tz": "Africa\/Lagos"
  },
  "DNOS": {
      "icao": "DNOS",
      "iata": "",
      "name": "Oshogbo Airport",
      "city": "Oshogbo",
      "state": "Osun",
      "country": "NG",
      "elevation": 997,
      "lat": 7.7829999924,
      "lon": 4.4829998016,
      "tz": "Africa\/Lagos"
  },
  "DNPO": {
      "icao": "DNPO",
      "iata": "PHC",
      "name": "Port Harcourt International Airport",
      "city": "Port Harcourt",
      "state": "Rivers",
      "country": "NG",
      "elevation": 87,
      "lat": 5.0154900551,
      "lon": 6.9495902061,
      "tz": "Africa\/Lagos"
  },
  "DNSO": {
      "icao": "DNSO",
      "iata": "SKO",
      "name": "Sadiq Abubakar III International Airport",
      "city": "Sokoto",
      "state": "Sokoto",
      "country": "NG",
      "elevation": 1010,
      "lat": 12.9162998199,
      "lon": 5.2071900368,
      "tz": "Africa\/Lagos"
  },
  "DNTZ": {
      "icao": "DNTZ",
      "iata": "NBE",
      "name": "Enfidha Zine El Abidine Ben Ali International Airport",
      "city": "Enfidha",
      "state": "Susah",
      "country": "TN",
      "elevation": 21,
      "lat": 36.075833,
      "lon": 10.438611,
      "tz": "Africa\/Tunis"
  },
  "DNYO": {
      "icao": "DNYO",
      "iata": "YOL",
      "name": "Yola Airport",
      "city": "Yola",
      "state": "Adamawa",
      "country": "NG",
      "elevation": 599,
      "lat": 9.2575502396,
      "lon": 12.4303998947,
      "tz": "Africa\/Lagos"
  },
  "DNZA": {
      "icao": "DNZA",
      "iata": "ZAR",
      "name": "Zaria Airport",
      "city": "Zaria",
      "state": "Kaduna",
      "country": "NG",
      "elevation": 2170,
      "lat": 11.130200386,
      "lon": 7.6858100891,
      "tz": "Africa\/Lagos"
  },
  "DRRA": {
      "icao": "DRRA",
      "iata": "",
      "name": "Tessaoua Airport",
      "city": "Tessaoua",
      "state": "Maradi",
      "country": "NE",
      "elevation": 1358,
      "lat": 13.7670001984,
      "lon": 8.0170001984,
      "tz": "Africa\/Niamey"
  },
  "DRRB": {
      "icao": "DRRB",
      "iata": "",
      "name": "Birni-N'Konni Airport",
      "city": "Birni-N'Konni",
      "state": "Tahoua",
      "country": "NE",
      "elevation": 846,
      "lat": 13.8015003204,
      "lon": 5.2423601151,
      "tz": "Africa\/Niamey"
  },
  "DRRC": {
      "icao": "DRRC",
      "iata": "",
      "name": "Dogondoutchi Airport",
      "city": "Dogondoutchi",
      "state": "Dosso",
      "country": "NE",
      "elevation": 961,
      "lat": 13.6669998169,
      "lon": 4.0999999046,
      "tz": "Africa\/Niamey"
  },
  "DRRD": {
      "icao": "DRRD",
      "iata": "",
      "name": "Dosso Airport",
      "city": "Dosso",
      "state": "Dosso",
      "country": "NE",
      "elevation": 738,
      "lat": 13.0481247896,
      "lon": 3.2209682465,
      "tz": "Africa\/Niamey"
  },
  "DRRE": {
      "icao": "DRRE",
      "iata": "",
      "name": "Tera Airport",
      "city": "Tera",
      "state": "Niamey",
      "country": "NE",
      "elevation": 850,
      "lat": 13.9499998093,
      "lon": 0.7329999804,
      "tz": "Africa\/Niamey"
  },
  "DRRG": {
      "icao": "DRRG",
      "iata": "",
      "name": "Gaya Airport",
      "city": "Gaya",
      "state": "Dosso",
      "country": "NE",
      "elevation": 662,
      "lat": 11.8830003738,
      "lon": 3.4330000877,
      "tz": "Africa\/Niamey"
  },
  "DRRI": {
      "icao": "DRRI",
      "iata": "",
      "name": "Bilma Airport",
      "city": "Bilma",
      "state": "Agadez",
      "country": "NE",
      "elevation": 1715,
      "lat": 18.6867008209,
      "lon": 12.9191999435,
      "tz": "Africa\/Niamey"
  },
  "DRRL": {
      "icao": "DRRL",
      "iata": "",
      "name": "Tillabery Airport",
      "city": "Tillabery",
      "state": "Niamey",
      "country": "NE",
      "elevation": 692,
      "lat": 14.1999998093,
      "lon": 1.4670000076,
      "tz": "Africa\/Niamey"
  },
  "DRRM": {
      "icao": "DRRM",
      "iata": "MFQ",
      "name": "Maradi Airport",
      "city": "Maradi",
      "state": "Maradi",
      "country": "NE",
      "elevation": 1240,
      "lat": 13.5024995804,
      "lon": 7.1267499924,
      "tz": "Africa\/Niamey"
  },
  "DRRN": {
      "icao": "DRRN",
      "iata": "NIM",
      "name": "Diori Hamani International Airport",
      "city": "Niamey",
      "state": "Niamey",
      "country": "NE",
      "elevation": 732,
      "lat": 13.4814996719,
      "lon": 2.1836099625,
      "tz": "Africa\/Niamey"
  },
  "DRRP": {
      "icao": "DRRP",
      "iata": "",
      "name": "La Tapoa Airport",
      "city": "La Tapoa",
      "state": "Niamey",
      "country": "NE",
      "elevation": 722,
      "lat": 12.4829998016,
      "lon": 2.4000000954,
      "tz": "Africa\/Niamey"
  },
  "DRRT": {
      "icao": "DRRT",
      "iata": "THZ",
      "name": "Tahoua Airport",
      "city": "Tahoua",
      "state": "Tahoua",
      "country": "NE",
      "elevation": 1266,
      "lat": 14.8756999969,
      "lon": 5.2653598785,
      "tz": "Africa\/Niamey"
  },
  "DRRU": {
      "icao": "DRRU",
      "iata": "",
      "name": "Ouallam Airport",
      "city": "Ouallam",
      "state": "Tillaberi",
      "country": "NE",
      "elevation": 892,
      "lat": 14.3330001831,
      "lon": 2.0829999447,
      "tz": "Africa\/Niamey"
  },
  "DRZA": {
      "icao": "DRZA",
      "iata": "AJY",
      "name": "Mano Dayak International Airport",
      "city": "Agadez",
      "state": "Agadez",
      "country": "NE",
      "elevation": 1657,
      "lat": 16.9659996033,
      "lon": 8.0001096725,
      "tz": "Africa\/Niamey"
  },
  "DRZD": {
      "icao": "DRZD",
      "iata": "",
      "name": "Dirkou Airport",
      "city": "",
      "state": "Agadez",
      "country": "NE",
      "elevation": 1273,
      "lat": 18.9687004089,
      "lon": 12.8687000275,
      "tz": "Africa\/Niamey"
  },
  "DRZF": {
      "icao": "DRZF",
      "iata": "",
      "name": "Diffa Airport",
      "city": "Diffa",
      "state": "Diffa",
      "country": "NE",
      "elevation": 994,
      "lat": 13.3729000092,
      "lon": 12.6267004013,
      "tz": "Africa\/Niamey"
  },
  "DRZG": {
      "icao": "DRZG",
      "iata": "",
      "name": "Goure Airport",
      "city": "Goure",
      "state": "Zinder",
      "country": "NE",
      "elevation": 1499,
      "lat": 13.9835996628,
      "lon": 10.2749004364,
      "tz": "Africa\/Niamey"
  },
  "DRZI": {
      "icao": "DRZI",
      "iata": "",
      "name": "Iferouane Airport",
      "city": "Iferouane",
      "state": "Agadez",
      "country": "NE",
      "elevation": 2162,
      "lat": 19.0669994354,
      "lon": 8.3669996262,
      "tz": "Africa\/Niamey"
  },
  "DRZL": {
      "icao": "DRZL",
      "iata": "RLT",
      "name": "Arlit Airport",
      "city": "Arlit",
      "state": "Agadez",
      "country": "NE",
      "elevation": 1443,
      "lat": 18.7903995514,
      "lon": 7.3659501076,
      "tz": "Africa\/Niamey"
  },
  "DRZM": {
      "icao": "DRZM",
      "iata": "",
      "name": "Maine-Soroa Airport",
      "city": "Maine-Soroa",
      "state": "Diffa",
      "country": "NE",
      "elevation": 944,
      "lat": 13.205499649,
      "lon": 12.0322999954,
      "tz": "Africa\/Niamey"
  },
  "DRZN": {
      "icao": "DRZN",
      "iata": "",
      "name": "N'Guigmi Airport",
      "city": "N'Guigmi",
      "state": "Diffa",
      "country": "NE",
      "elevation": 977,
      "lat": 14.2510004044,
      "lon": 13.1289997101,
      "tz": "Africa\/Niamey"
  },
  "DRZR": {
      "icao": "DRZR",
      "iata": "ZND",
      "name": "Zinder Airport",
      "city": "Zinder",
      "state": "Zinder",
      "country": "NE",
      "elevation": 1516,
      "lat": 13.7790002823,
      "lon": 8.9837598801,
      "tz": "Africa\/Niamey"
  },
  "DRZT": {
      "icao": "DRZT",
      "iata": "",
      "name": "Tanout Airport",
      "city": "Tanout",
      "state": "Zinder",
      "country": "NE",
      "elevation": 1751,
      "lat": 14.9702997208,
      "lon": 8.8916702271,
      "tz": "Africa\/Niamey"
  },
  "DT70": {
      "icao": "DT70",
      "iata": "",
      "name": "Medenine Airport",
      "city": "Medenine",
      "state": "Madanin",
      "country": "TN",
      "elevation": 427,
      "lat": 33.3502006531,
      "lon": 10.4440002441,
      "tz": "Africa\/Tunis"
  },
  "DTKA": {
      "icao": "DTKA",
      "iata": "TBJ",
      "name": "Tabarka 7 Novembre Airport",
      "city": "Tabarka",
      "state": "Jundubah",
      "country": "TN",
      "elevation": 230,
      "lat": 36.9799995422,
      "lon": 8.8769397736,
      "tz": "Africa\/Tunis"
  },
  "DTMB": {
      "icao": "DTMB",
      "iata": "MIR",
      "name": "Monastir Habib Bourguiba International Airport",
      "city": "Monastir",
      "state": "Al-Munastir",
      "country": "TN",
      "elevation": 9,
      "lat": 35.7580986023,
      "lon": 10.754699707,
      "tz": "Africa\/Tunis"
  },
  "DTTA": {
      "icao": "DTTA",
      "iata": "TUN",
      "name": "Tunis Carthage International Airport",
      "city": "Tunis",
      "state": "Tunis",
      "country": "TN",
      "elevation": 22,
      "lat": 36.8510017395,
      "lon": 10.2271995544,
      "tz": "Africa\/Tunis"
  },
  "DTTB": {
      "icao": "DTTB",
      "iata": "OIZ",
      "name": "Sidi Ahmed Air Base",
      "city": "Sidi Ahmed",
      "state": "Banzart",
      "country": "TN",
      "elevation": 20,
      "lat": 37.2453994751,
      "lon": 9.7914495468,
      "tz": "Africa\/Tunis"
  },
  "DTTD": {
      "icao": "DTTD",
      "iata": "",
      "name": "Remada Air Base",
      "city": "Remada",
      "state": "Tataouine",
      "country": "TN",
      "elevation": 1004,
      "lat": 32.3061981201,
      "lon": 10.3821001053,
      "tz": "Africa\/Tunis"
  },
  "DTTF": {
      "icao": "DTTF",
      "iata": "GAF",
      "name": "Gafsa Ksar International Airport",
      "city": "Gafsa",
      "state": "Gafsa",
      "country": "TN",
      "elevation": 1060,
      "lat": 34.422000885,
      "lon": 8.8225002289,
      "tz": "Africa\/Tunis"
  },
  "DTTG": {
      "icao": "DTTG",
      "iata": "GAE",
      "name": "Gabes Matmata International Airport",
      "city": "Gabes",
      "state": "Qabis",
      "country": "TN",
      "elevation": 26,
      "lat": 33.8768997192,
      "lon": 10.1033000946,
      "tz": "Africa\/Tunis"
  },
  "DTTI": {
      "icao": "DTTI",
      "iata": "",
      "name": "Borj El Amri Airport",
      "city": "Borj El Amri",
      "state": "Ariana",
      "country": "TN",
      "elevation": 110,
      "lat": 36.7212982178,
      "lon": 9.9431495667,
      "tz": "Africa\/Tunis"
  },
  "DTTJ": {
      "icao": "DTTJ",
      "iata": "DJE",
      "name": "Djerba Zarzis International Airport",
      "city": "Djerba",
      "state": "Madanin",
      "country": "TN",
      "elevation": 19,
      "lat": 33.875,
      "lon": 10.7755002975,
      "tz": "Africa\/Tunis"
  },
  "DTTR": {
      "icao": "DTTR",
      "iata": "EBM",
      "name": "El Borma Airport",
      "city": "El Borma",
      "state": "Tataouine",
      "country": "TN",
      "elevation": 827,
      "lat": 31.7042999268,
      "lon": 9.2546195984,
      "tz": "Africa\/Tunis"
  },
  "DTTX": {
      "icao": "DTTX",
      "iata": "SFA",
      "name": "Sfax Thyna International Airport",
      "city": "Sfax",
      "state": "Safaqis",
      "country": "TN",
      "elevation": 85,
      "lat": 34.7179985046,
      "lon": 10.6909999847,
      "tz": "Africa\/Tunis"
  },
  "DTTZ": {
      "icao": "DTTZ",
      "iata": "TOE",
      "name": "Tozeur Nefta International Airport",
      "city": "Tozeur",
      "state": "Tawzar",
      "country": "TN",
      "elevation": 287,
      "lat": 33.9397010803,
      "lon": 8.1105604172,
      "tz": "Africa\/Tunis"
  },
  "DXAK": {
      "icao": "DXAK",
      "iata": "",
      "name": "Akpaka Airport",
      "city": "Atakpame",
      "state": "",
      "country": "TG",
      "elevation": 689,
      "lat": 7.5830001831,
      "lon": 1.1169999838,
      "tz": "Africa\/Lome"
  },
  "DXDP": {
      "icao": "DXDP",
      "iata": "",
      "name": "Djangou Airport",
      "city": "Djangou",
      "state": "",
      "country": "TG",
      "elevation": 919,
      "lat": 10.8000001907,
      "lon": 0.25,
      "tz": "Africa\/Lome"
  },
  "DXKP": {
      "icao": "DXKP",
      "iata": "",
      "name": "Kolokope Airport",
      "city": "Anie",
      "state": "",
      "country": "TG",
      "elevation": 591,
      "lat": 7.8000001907,
      "lon": 1.2829999924,
      "tz": "Africa\/Lome"
  },
  "DXMG": {
      "icao": "DXMG",
      "iata": "",
      "name": "Sansanne-Mango Airport",
      "city": "Mango",
      "state": "",
      "country": "TG",
      "elevation": 460,
      "lat": 10.3730106354,
      "lon": 0.4713821411,
      "tz": "Africa\/Lome"
  },
  "DXNG": {
      "icao": "DXNG",
      "iata": "LRL",
      "name": "Niamtougou International Airport",
      "city": "Niamtougou",
      "state": "Kara",
      "country": "TG",
      "elevation": 1515,
      "lat": 9.7673301697,
      "lon": 1.0912499428,
      "tz": "Africa\/Lome"
  },
  "DXSK": {
      "icao": "DXSK",
      "iata": "",
      "name": "Sokode Airport",
      "city": "Sokode",
      "state": "Centrale",
      "country": "TG",
      "elevation": 1214,
      "lat": 8.9829998016,
      "lon": 1.1499999762,
      "tz": "Africa\/Lome"
  },
  "DXXX": {
      "icao": "DXXX",
      "iata": "LFW",
      "name": "Lome-Tokoin Airport",
      "city": "Lome",
      "state": "Maritime",
      "country": "TG",
      "elevation": 72,
      "lat": 6.1656098366,
      "lon": 1.2545100451,
      "tz": "Africa\/Lome"
  },
  "EBAM": {
      "icao": "EBAM",
      "iata": "",
      "name": "Amougies Airport",
      "city": "Mont-de-l'Enclus",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 49,
      "lat": 50.7401008606,
      "lon": 3.4848999977,
      "tz": "Europe\/Brussels"
  },
  "EBAR": {
      "icao": "EBAR",
      "iata": "",
      "name": "Sterpenich Airport",
      "city": "Arlon",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1138,
      "lat": 49.6627998352,
      "lon": 5.8869400024,
      "tz": "Europe\/Brussels"
  },
  "EBAV": {
      "icao": "EBAV",
      "iata": "",
      "name": "Avernas-le-Bauduin Airport",
      "city": "Hannut",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 394,
      "lat": 50.7066993713,
      "lon": 5.0680599213,
      "tz": "Europe\/Brussels"
  },
  "EBAW": {
      "icao": "EBAW",
      "iata": "ANR",
      "name": "Antwerp International Airport (Deurne)",
      "city": "Antwerp",
      "state": "Flanders",
      "country": "BE",
      "elevation": 39,
      "lat": 51.1893997192,
      "lon": 4.4602799416,
      "tz": "Europe\/Brussels"
  },
  "EBBE": {
      "icao": "EBBE",
      "iata": "",
      "name": "Beauvechain Air Base",
      "city": "Beauvechain",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 370,
      "lat": 50.7585983276,
      "lon": 4.7683300972,
      "tz": "Europe\/Brussels"
  },
  "EBBG": {
      "icao": "EBBG",
      "iata": "",
      "name": "Michamps Airport",
      "city": "Bastogne",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1770,
      "lat": 50.0527992249,
      "lon": 5.7986102104,
      "tz": "Europe\/Brussels"
  },
  "EBBL": {
      "icao": "EBBL",
      "iata": "",
      "name": "Kleine Brogel Air Base",
      "city": "Kleine Brogel",
      "state": "Flanders",
      "country": "BE",
      "elevation": 200,
      "lat": 51.1683006287,
      "lon": 5.4699997902,
      "tz": "Europe\/Brussels"
  },
  "EBBN": {
      "icao": "EBBN",
      "iata": "",
      "name": "Bullingen Airport",
      "city": "Bullingen",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 2067,
      "lat": 50.4150009155,
      "lon": 6.2763900757,
      "tz": "Europe\/Brussels"
  },
  "EBBO": {
      "icao": "EBBO",
      "iata": "",
      "name": "Mogimont Airport",
      "city": "Bouillon",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1394,
      "lat": 49.8664016724,
      "lon": 5.0680599213,
      "tz": "Europe\/Brussels"
  },
  "EBBR": {
      "icao": "EBBR",
      "iata": "BRU",
      "name": "Brussels Airport",
      "city": "Brussels",
      "state": "Flanders",
      "country": "BE",
      "elevation": 184,
      "lat": 50.9014015198,
      "lon": 4.4844398499,
      "tz": "Europe\/Brussels"
  },
  "EBBT": {
      "icao": "EBBT",
      "iata": "",
      "name": "Brasschaat Air Base",
      "city": "Brasschaat",
      "state": "Flanders",
      "country": "BE",
      "elevation": 76,
      "lat": 51.340801239,
      "lon": 4.5043997765,
      "tz": "Europe\/Brussels"
  },
  "EBBW": {
      "icao": "EBBW",
      "iata": "",
      "name": "Wonck Airport",
      "city": "Bassenge",
      "state": "Flanders",
      "country": "BE",
      "elevation": 367,
      "lat": 50.778301239,
      "lon": 5.6166701317,
      "tz": "Europe\/Brussels"
  },
  "EBBX": {
      "icao": "EBBX",
      "iata": "",
      "name": "Jehonville Air Base",
      "city": "Bertrix",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1514,
      "lat": 49.8917007446,
      "lon": 5.2238898277,
      "tz": "Europe\/Brussels"
  },
  "EBBY": {
      "icao": "EBBY",
      "iata": "",
      "name": "Baisy-Thy Airport",
      "city": "Genappe",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 525,
      "lat": 50.568611145,
      "lon": 4.4347219467,
      "tz": "Europe\/Brussels"
  },
  "EBBZ": {
      "icao": "EBBZ",
      "iata": "",
      "name": "Buzet Airstrip",
      "city": "Pont-A-Celles",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 522,
      "lat": 50.5416984558,
      "lon": 4.3811101913,
      "tz": "Europe\/Brussels"
  },
  "EBCF": {
      "icao": "EBCF",
      "iata": "",
      "name": "Cerfontaine Nouv Airport",
      "city": "Cerfontaine",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 961,
      "lat": 50.1528015137,
      "lon": 4.3872199059,
      "tz": "Europe\/Brussels"
  },
  "EBCI": {
      "icao": "EBCI",
      "iata": "CRL",
      "name": "Brussels South Charleroi Airport",
      "city": "Brussels",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 614,
      "lat": 50.4592018127,
      "lon": 4.4538202286,
      "tz": "Europe\/Brussels"
  },
  "EBCR": {
      "icao": "EBCR",
      "iata": "",
      "name": "Ronvaux Airport",
      "city": "Ciney",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1076,
      "lat": 50.2472229004,
      "lon": 5.1138892174,
      "tz": "Europe\/Brussels"
  },
  "EBCS": {
      "icao": "EBCS",
      "iata": "",
      "name": "Saint-Remy Airport",
      "city": "Chimay",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 820,
      "lat": 50.0583000183,
      "lon": 4.2819399834,
      "tz": "Europe\/Brussels"
  },
  "EBCV": {
      "icao": "EBCV",
      "iata": "",
      "name": "Chievres Air Base",
      "city": "Chievres",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 194,
      "lat": 50.5758018494,
      "lon": 3.8310000896,
      "tz": "Europe\/Brussels"
  },
  "EBDT": {
      "icao": "EBDT",
      "iata": "",
      "name": "Schaffen Airport",
      "city": "Diest",
      "state": "Flanders",
      "country": "BE",
      "elevation": 100,
      "lat": 50.9991989136,
      "lon": 5.065559864,
      "tz": "Europe\/Brussels"
  },
  "EBFN": {
      "icao": "EBFN",
      "iata": "",
      "name": "Koksijde Air Base",
      "city": "Koksijde",
      "state": "Flanders",
      "country": "BE",
      "elevation": 20,
      "lat": 51.0903015137,
      "lon": 2.652780056,
      "tz": "Europe\/Brussels"
  },
  "EBFS": {
      "icao": "EBFS",
      "iata": "",
      "name": "Florennes Air Base",
      "city": "Florennes",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 935,
      "lat": 50.2433013916,
      "lon": 4.6458301544,
      "tz": "Europe\/Brussels"
  },
  "EBGB": {
      "icao": "EBGB",
      "iata": "",
      "name": "Grimbergen Airport",
      "city": "Grimbergen",
      "state": "Flanders",
      "country": "BE",
      "elevation": 70,
      "lat": 50.9494018555,
      "lon": 4.3933300972,
      "tz": "Europe\/Brussels"
  },
  "EBGG": {
      "icao": "EBGG",
      "iata": "",
      "name": "Overboelare Airport",
      "city": "Geraardsbergen",
      "state": "Flanders",
      "country": "BE",
      "elevation": 56,
      "lat": 50.7555999756,
      "lon": 3.8638899326,
      "tz": "Europe\/Brussels"
  },
  "EBHE": {
      "icao": "EBHE",
      "iata": "",
      "name": "Couthuin Airport",
      "city": "Heron",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 607,
      "lat": 50.538898468,
      "lon": 5.10916996,
      "tz": "Europe\/Brussels"
  },
  "EBHN": {
      "icao": "EBHN",
      "iata": "",
      "name": "Hoevenen Airport",
      "city": "Hoevenen",
      "state": "Flanders",
      "country": "BE",
      "elevation": 10,
      "lat": 51.306098938,
      "lon": 4.3872199059,
      "tz": "Europe\/Brussels"
  },
  "EBIS": {
      "icao": "EBIS",
      "iata": "",
      "name": "Ath\/Isieres Airport",
      "city": "Isieres",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 98,
      "lat": 50.6641998291,
      "lon": 3.8044400215,
      "tz": "Europe\/Brussels"
  },
  "EBKH": {
      "icao": "EBKH",
      "iata": "",
      "name": "Balekeiheuvel Airport",
      "city": "Balen",
      "state": "Flanders",
      "country": "BE",
      "elevation": 131,
      "lat": 51.1782989502,
      "lon": 5.2209200859,
      "tz": "Europe\/Brussels"
  },
  "EBKT": {
      "icao": "EBKT",
      "iata": "KJK",
      "name": "Wevelgem Airport",
      "city": "Wevelgem",
      "state": "Flanders",
      "country": "BE",
      "elevation": 64,
      "lat": 50.817199707,
      "lon": 3.2047200203,
      "tz": "Europe\/Brussels"
  },
  "EBLB": {
      "icao": "EBLB",
      "iata": "",
      "name": "Elsenborn Airport",
      "city": "Butgenbach",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1830,
      "lat": 50.4817008972,
      "lon": 6.1819400787,
      "tz": "Europe\/Brussels"
  },
  "EBLE": {
      "icao": "EBLE",
      "iata": "",
      "name": "Beverlo Air Base",
      "city": "Leopoldsburg",
      "state": "Flanders",
      "country": "BE",
      "elevation": 207,
      "lat": 51.1194000244,
      "lon": 5.3008298874,
      "tz": "Europe\/Brussels"
  },
  "EBLG": {
      "icao": "EBLG",
      "iata": "LGG",
      "name": "Liege Airport",
      "city": "Liege",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 659,
      "lat": 50.6374015808,
      "lon": 5.4432201385,
      "tz": "Europe\/Brussels"
  },
  "EBLI": {
      "icao": "EBLI",
      "iata": "",
      "name": "Lierneux Airport",
      "city": "Lierneux",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1411,
      "lat": 50.2867012024,
      "lon": 5.8363900185,
      "tz": "Europe\/Brussels"
  },
  "EBLN": {
      "icao": "EBLN",
      "iata": "",
      "name": "Liernu Airport",
      "city": "Eghezee",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 564,
      "lat": 50.5806007385,
      "lon": 4.7925000191,
      "tz": "Europe\/Brussels"
  },
  "EBMB": {
      "icao": "EBMB",
      "iata": "",
      "name": "Melsbroek Air Base",
      "city": "Brussels",
      "state": "Flanders",
      "country": "BE",
      "elevation": 184,
      "lat": 50.901389,
      "lon": 4.484444,
      "tz": "Europe\/Brussels"
  },
  "EBMG": {
      "icao": "EBMG",
      "iata": "",
      "name": "Matagne-la-Petite Airport",
      "city": "Doische",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 787,
      "lat": 50.1046981812,
      "lon": 4.6380600929,
      "tz": "Europe\/Brussels"
  },
  "EBML": {
      "icao": "EBML",
      "iata": "",
      "name": "Maillen Airport",
      "city": "Assesse",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 886,
      "lat": 50.3741989136,
      "lon": 4.9277801514,
      "tz": "Europe\/Brussels"
  },
  "EBMO": {
      "icao": "EBMO",
      "iata": "",
      "name": "Moorsele Airport",
      "city": "Moorsele",
      "state": "Flanders",
      "country": "BE",
      "elevation": 66,
      "lat": 50.8527984619,
      "lon": 3.1473100185,
      "tz": "Europe\/Brussels"
  },
  "EBNE": {
      "icao": "EBNE",
      "iata": "",
      "name": "Neerpelt Airport",
      "city": "Neerpelt",
      "state": "Flanders",
      "country": "BE",
      "elevation": 148,
      "lat": 51.2119445801,
      "lon": 5.4786109924,
      "tz": "Europe\/Brussels"
  },
  "EBNM": {
      "icao": "EBNM",
      "iata": "QNM",
      "name": "Suarlee Airport",
      "city": "Namur",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 594,
      "lat": 50.4879989624,
      "lon": 4.7689199448,
      "tz": "Europe\/Brussels"
  },
  "EBNO": {
      "icao": "EBNO",
      "iata": "",
      "name": "Outer Airport",
      "city": "Ninove",
      "state": "Flanders",
      "country": "BE",
      "elevation": 151,
      "lat": 50.8544006348,
      "lon": 3.9852800369,
      "tz": "Europe\/Brussels"
  },
  "EBOR": {
      "icao": "EBOR",
      "iata": "",
      "name": "Orchimont Airport",
      "city": "Vresse-Sur-Semois",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1312,
      "lat": 49.9071998596,
      "lon": 4.9363899231,
      "tz": "Europe\/Brussels"
  },
  "EBOS": {
      "icao": "EBOS",
      "iata": "OST",
      "name": "Ostend-Bruges International Airport",
      "city": "Ostend",
      "state": "Flanders",
      "country": "BE",
      "elevation": 13,
      "lat": 51.1988983154,
      "lon": 2.8622200489,
      "tz": "Europe\/Brussels"
  },
  "EBSG": {
      "icao": "EBSG",
      "iata": "",
      "name": "Saint-Ghislain Airport",
      "city": "Saint Ghislain",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 75,
      "lat": 50.4575004578,
      "lon": 3.8202800751,
      "tz": "Europe\/Brussels"
  },
  "EBSH": {
      "icao": "EBSH",
      "iata": "",
      "name": "St Hubert Airport",
      "city": "Saint Hubert",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1930,
      "lat": 50.035900116,
      "lon": 5.404250145,
      "tz": "Europe\/Brussels"
  },
  "EBSL": {
      "icao": "EBSL",
      "iata": "",
      "name": "Zutendaal Air Base",
      "city": "Zutendaal",
      "state": "Flanders",
      "country": "BE",
      "elevation": 312,
      "lat": 50.9474983215,
      "lon": 5.5905599594,
      "tz": "Europe\/Brussels"
  },
  "EBSP": {
      "icao": "EBSP",
      "iata": "",
      "name": "Spa (la Sauveniere) Airport",
      "city": "Spa",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1581,
      "lat": 50.4824981689,
      "lon": 5.910299778,
      "tz": "Europe\/Brussels"
  },
  "EBST": {
      "icao": "EBST",
      "iata": "",
      "name": "Sint Truiden Airport",
      "city": "Sint Truiden",
      "state": "Flanders",
      "country": "BE",
      "elevation": 246,
      "lat": 50.7919006348,
      "lon": 5.2016701698,
      "tz": "Europe\/Brussels"
  },
  "EBSU": {
      "icao": "EBSU",
      "iata": "",
      "name": "Saint Hubert Air Base",
      "city": "Saint Hubert",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1930,
      "lat": 50.0344009399,
      "lon": 5.4408102036,
      "tz": "Europe\/Brussels"
  },
  "EBTN": {
      "icao": "EBTN",
      "iata": "",
      "name": "Goetsenhoven Air Base",
      "city": "Tienen",
      "state": "Flanders",
      "country": "BE",
      "elevation": 246,
      "lat": 50.7817001343,
      "lon": 4.9577798843,
      "tz": "Europe\/Brussels"
  },
  "EBTX": {
      "icao": "EBTX",
      "iata": "",
      "name": "Theux (Verviers) Airport",
      "city": "Verviers",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 1099,
      "lat": 50.5526008606,
      "lon": 5.8550300598,
      "tz": "Europe\/Brussels"
  },
  "EBTY": {
      "icao": "EBTY",
      "iata": "",
      "name": "Tournai\/Maubray Airport",
      "city": "Tournai",
      "state": "Wallonia",
      "country": "BE",
      "elevation": 161,
      "lat": 50.5297012329,
      "lon": 3.4945499897,
      "tz": "Europe\/Brussels"
  },
  "EBUL": {
      "icao": "EBUL",
      "iata": "",
      "name": "Ursel Air Base",
      "city": "Ursel",
      "state": "Flanders",
      "country": "BE",
      "elevation": 95,
      "lat": 51.1441993713,
      "lon": 3.4755599499,
      "tz": "Europe\/Brussels"
  },
  "EBWE": {
      "icao": "EBWE",
      "iata": "",
      "name": "Weelde Air Base",
      "city": "Weelde",
      "state": "Flanders",
      "country": "BE",
      "elevation": 105,
      "lat": 51.3947982788,
      "lon": 4.9601898193,
      "tz": "Europe\/Amsterdam"
  },
  "EBZH": {
      "icao": "EBZH",
      "iata": "QHA",
      "name": "Hasselt Airport",
      "city": "Hasselt",
      "state": "Flanders",
      "country": "BE",
      "elevation": 141,
      "lat": 50.9700012207,
      "lon": 5.3750700951,
      "tz": "Europe\/Brussels"
  },
  "EBZR": {
      "icao": "EBZR",
      "iata": "OBL",
      "name": "Oostmalle Air Base",
      "city": "Zoersel",
      "state": "Flanders",
      "country": "BE",
      "elevation": 53,
      "lat": 51.2647018433,
      "lon": 4.7533302307,
      "tz": "Europe\/Brussels"
  },
  "EBZU": {
      "icao": "EBZU",
      "iata": "",
      "name": "Zuienkerke Airport",
      "city": "Zuinkerke",
      "state": "Flanders",
      "country": "BE",
      "elevation": 16,
      "lat": 51.2566986084,
      "lon": 3.1405599117,
      "tz": "Europe\/Brussels"
  },
  "EBZW": {
      "icao": "EBZW",
      "iata": "",
      "name": "Genk Zwartberg Airport",
      "city": "Genk",
      "state": "Flanders",
      "country": "BE",
      "elevation": 278,
      "lat": 51.0153999329,
      "lon": 5.5264701843,
      "tz": "Europe\/Brussels"
  },
  "EDAB": {
      "icao": "EDAB",
      "iata": "",
      "name": "Bautzen Airport",
      "city": "Bautzen",
      "state": "Saxony",
      "country": "DE",
      "elevation": 568,
      "lat": 51.193611145,
      "lon": 14.5197219849,
      "tz": "Europe\/Berlin"
  },
  "EDAC": {
      "icao": "EDAC",
      "iata": "AOC",
      "name": "Altenburg-Nobitz Airport",
      "city": "Altenburg",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 640,
      "lat": 50.9819450378,
      "lon": 12.5063886642,
      "tz": "Europe\/Berlin"
  },
  "EDAD": {
      "icao": "EDAD",
      "iata": "",
      "name": "Dessau Airport",
      "city": "Dessau",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 187,
      "lat": 51.831693541,
      "lon": 12.1909618378,
      "tz": "Europe\/Berlin"
  },
  "EDAE": {
      "icao": "EDAE",
      "iata": "",
      "name": "Eisenhuttenstadt Airport",
      "city": "Eisenhuttenstadt",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 144,
      "lat": 52.1972236633,
      "lon": 14.5855560303,
      "tz": "Europe\/Berlin"
  },
  "EDAG": {
      "icao": "EDAG",
      "iata": "",
      "name": "Grosruckerswalde Airport",
      "city": "Marienberg",
      "state": "Saxony",
      "country": "DE",
      "elevation": 2198,
      "lat": 50.6441688538,
      "lon": 13.1263885498,
      "tz": "Europe\/Berlin"
  },
  "EDAH": {
      "icao": "EDAH",
      "iata": "HDF",
      "name": "Heringsdorf Airport",
      "city": "Heringsdorf",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 93,
      "lat": 53.8787002563,
      "lon": 14.152299881,
      "tz": "Europe\/Berlin"
  },
  "EDAI": {
      "icao": "EDAI",
      "iata": "",
      "name": "Segeletz Airport",
      "city": "Neustadt",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 141,
      "lat": 52.8266677856,
      "lon": 12.5419435501,
      "tz": "Europe\/Berlin"
  },
  "EDAJ": {
      "icao": "EDAJ",
      "iata": "",
      "name": "Gera-Leumnitz Airport",
      "city": "Gera",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1014,
      "lat": 50.8816680908,
      "lon": 12.1358327866,
      "tz": "Europe\/Berlin"
  },
  "EDAK": {
      "icao": "EDAK",
      "iata": "",
      "name": "Grosenhain Airport",
      "city": "Grosenhain",
      "state": "Saxony",
      "country": "DE",
      "elevation": 417,
      "lat": 51.3080558777,
      "lon": 13.5555562973,
      "tz": "Europe\/Berlin"
  },
  "EDAM": {
      "icao": "EDAM",
      "iata": "",
      "name": "Merseburg Airport",
      "city": "Merseburg",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 341,
      "lat": 51.363609314,
      "lon": 11.9483327866,
      "tz": "Europe\/Berlin"
  },
  "EDAN": {
      "icao": "EDAN",
      "iata": "",
      "name": "Neustadt-Glewe Airport",
      "city": "Neustadt-Glewe",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 115,
      "lat": 53.3597221375,
      "lon": 11.615278244,
      "tz": "Europe\/Berlin"
  },
  "EDAO": {
      "icao": "EDAO",
      "iata": "",
      "name": "Nordhausen Airport",
      "city": "Nordhausen",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 689,
      "lat": 51.493057251,
      "lon": 10.8333330154,
      "tz": "Europe\/Berlin"
  },
  "EDAP": {
      "icao": "EDAP",
      "iata": "",
      "name": "Neuhausen Airport",
      "city": "Cottbus",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 279,
      "lat": 51.6847229004,
      "lon": 14.4230556488,
      "tz": "Europe\/Berlin"
  },
  "EDAQ": {
      "icao": "EDAQ",
      "iata": "ZHZ",
      "name": "Halle-Oppin Airport",
      "city": "Oppin",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 348,
      "lat": 51.5522232056,
      "lon": 12.0538892746,
      "tz": "Europe\/Berlin"
  },
  "EDAR": {
      "icao": "EDAR",
      "iata": "",
      "name": "Pirna-Pratzschwitz Airport",
      "city": "Pirna",
      "state": "Saxony",
      "country": "DE",
      "elevation": 400,
      "lat": 50.9791679382,
      "lon": 13.9097223282,
      "tz": "Europe\/Berlin"
  },
  "EDAS": {
      "icao": "EDAS",
      "iata": "",
      "name": "Finsterwalde\/Heinrichsruh Airport",
      "city": "Finsterwalde",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 384,
      "lat": 51.6344451904,
      "lon": 13.6755561829,
      "tz": "Europe\/Berlin"
  },
  "EDAT": {
      "icao": "EDAT",
      "iata": "",
      "name": "Nardt Airport",
      "city": "Hoyerswerda",
      "state": "Saxony",
      "country": "DE",
      "elevation": 384,
      "lat": 51.4511108398,
      "lon": 14.1994438171,
      "tz": "Europe\/Berlin"
  },
  "EDAU": {
      "icao": "EDAU",
      "iata": "IES",
      "name": "Riesa-Gohlis Airport",
      "city": "Riesa",
      "state": "Saxony",
      "country": "DE",
      "elevation": 322,
      "lat": 51.2936096191,
      "lon": 13.3561105728,
      "tz": "Europe\/Berlin"
  },
  "EDAV": {
      "icao": "EDAV",
      "iata": "",
      "name": "Eberswalde-Finow Airport",
      "city": "Eberswalde",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 121,
      "lat": 52.8272209167,
      "lon": 13.693611145,
      "tz": "Europe\/Berlin"
  },
  "EDAW": {
      "icao": "EDAW",
      "iata": "",
      "name": "Roitzschjora Airport",
      "city": "Roitzschjora",
      "state": "Saxony",
      "country": "DE",
      "elevation": 289,
      "lat": 51.5777778625,
      "lon": 12.4944438934,
      "tz": "Europe\/Berlin"
  },
  "EDAX": {
      "icao": "EDAX",
      "iata": "REB",
      "name": "Rechlin-Larz Airport",
      "city": "Larz",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 220,
      "lat": 53.306388855,
      "lon": 12.7522220612,
      "tz": "Europe\/Berlin"
  },
  "EDAY": {
      "icao": "EDAY",
      "iata": "",
      "name": "Strausberg Airport",
      "city": "Strausberg",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 262,
      "lat": 52.5805549622,
      "lon": 13.9166669846,
      "tz": "Europe\/Berlin"
  },
  "EDAZ": {
      "icao": "EDAZ",
      "iata": "",
      "name": "Schonhagen Airport",
      "city": "Trebbin",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 131,
      "lat": 52.2036094666,
      "lon": 13.1563892365,
      "tz": "Europe\/Berlin"
  },
  "EDBA": {
      "icao": "EDBA",
      "iata": "",
      "name": "Arnstadt-Alkersleben Airport",
      "city": "Wulfershausen",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1145,
      "lat": 50.8416671753,
      "lon": 11.0694437027,
      "tz": "Europe\/Berlin"
  },
  "EDDB": {
      "icao": "EDDB",
      "iata": "BER",
      "name": "Berlin Brandenburg Airport",
      "city": "Berlin",
      "state": "",
      "country": "DE",
      "elevation": 156,
      "lat": 52.366667,
      "lon": 13.503333,
      "tz": "Europe\/Berlin"
  },
  "EDBC": {
      "icao": "EDBC",
      "iata": "CSO",
      "name": "Cochstedt Airport",
      "city": "Magdeburg",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 594,
      "lat": 51.8563995361,
      "lon": 11.42029953,
      "tz": "Europe\/Berlin"
  },
  "EDBD": {
      "icao": "EDBD",
      "iata": "",
      "name": "Emmel Airfield Dedelow Airport",
      "city": "Prenzlau",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 213,
      "lat": 53.356388092,
      "lon": 13.7836112976,
      "tz": "Europe\/Berlin"
  },
  "EDBE": {
      "icao": "EDBE",
      "iata": "",
      "name": "Brandenburg-Muhlenfeld Airport",
      "city": "Brandenburg an der Havel",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 98,
      "lat": 52.4375,
      "lon": 12.5900001526,
      "tz": "Europe\/Berlin"
  },
  "EDBF": {
      "icao": "EDBF",
      "iata": "",
      "name": "Ruppiner Land Airport",
      "city": "Fehrbellin",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 138,
      "lat": 52.7933311462,
      "lon": 12.7602777481,
      "tz": "Europe\/Berlin"
  },
  "EDBG": {
      "icao": "EDBG",
      "iata": "",
      "name": "Burg Airport",
      "city": "Burg",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 174,
      "lat": 52.2416687012,
      "lon": 11.8561105728,
      "tz": "Europe\/Berlin"
  },
  "EDBH": {
      "icao": "EDBH",
      "iata": "BBH",
      "name": "Barth Airport",
      "city": "",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 23,
      "lat": 54.3375015259,
      "lon": 12.6997003555,
      "tz": "Europe\/Berlin"
  },
  "EDBI": {
      "icao": "EDBI",
      "iata": "",
      "name": "Zwickau Airport",
      "city": "Zwickau",
      "state": "Saxony",
      "country": "DE",
      "elevation": 1050,
      "lat": 50.7016677856,
      "lon": 12.4538888931,
      "tz": "Europe\/Berlin"
  },
  "EDBJ": {
      "icao": "EDBJ",
      "iata": "",
      "name": "Jena-Schongleina Airport",
      "city": "Jena",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1247,
      "lat": 50.9152793884,
      "lon": 11.7144441605,
      "tz": "Europe\/Berlin"
  },
  "EDBK": {
      "icao": "EDBK",
      "iata": "",
      "name": "Kyritz Airport",
      "city": "Kyritz",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 131,
      "lat": 52.918888092,
      "lon": 12.42527771,
      "tz": "Europe\/Berlin"
  },
  "EDBL": {
      "icao": "EDBL",
      "iata": "",
      "name": "Laucha Airport",
      "city": "Laucha an der Unstrut",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 738,
      "lat": 51.2458343506,
      "lon": 11.6933326721,
      "tz": "Europe\/Berlin"
  },
  "EDBM": {
      "icao": "EDBM",
      "iata": "ZMG",
      "name": "Magdeburg Airport",
      "city": "Magdeburg",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 259,
      "lat": 52.0736122131,
      "lon": 11.6263885498,
      "tz": "Europe\/Berlin"
  },
  "EDBO": {
      "icao": "EDBO",
      "iata": "",
      "name": "Oehna Airport",
      "city": "Zellendorf",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 289,
      "lat": 51.899723053,
      "lon": 13.0522222519,
      "tz": "Europe\/Berlin"
  },
  "EDBP": {
      "icao": "EDBP",
      "iata": "",
      "name": "Pinnow Airport",
      "city": "Pinnow",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 144,
      "lat": 53.6152763367,
      "lon": 11.5611114502,
      "tz": "Europe\/Berlin"
  },
  "EDBQ": {
      "icao": "EDBQ",
      "iata": "",
      "name": "Bronkow Airport",
      "city": "Bronkow",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 423,
      "lat": 51.6705551147,
      "lon": 13.9602775574,
      "tz": "Europe\/Berlin"
  },
  "EDBR": {
      "icao": "EDBR",
      "iata": "",
      "name": "Rothenburg\/Gorlitz Airport",
      "city": "Rothenburg\/Oberlausitz",
      "state": "Saxony",
      "country": "DE",
      "elevation": 518,
      "lat": 51.3633346558,
      "lon": 14.9499998093,
      "tz": "Europe\/Berlin"
  },
  "EDBS": {
      "icao": "EDBS",
      "iata": "",
      "name": "Sommerda-Dermsdorf Airport",
      "city": "Kolleda",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 453,
      "lat": 51.1991653442,
      "lon": 11.1925001144,
      "tz": "Europe\/Berlin"
  },
  "EDBT": {
      "icao": "EDBT",
      "iata": "",
      "name": "Allstedt Airport",
      "city": "Allstedt",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 932,
      "lat": 51.3805541992,
      "lon": 11.4466667175,
      "tz": "Europe\/Berlin"
  },
  "EDBU": {
      "icao": "EDBU",
      "iata": "",
      "name": "Pritzwalk-Sommersberg Airport",
      "city": "Pritzwalk",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 289,
      "lat": 53.1802787781,
      "lon": 12.1855564117,
      "tz": "Europe\/Berlin"
  },
  "EDBV": {
      "icao": "EDBV",
      "iata": "",
      "name": "Stralsund Airport",
      "city": "Stralsund",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 49,
      "lat": 54.3358345032,
      "lon": 13.0433330536,
      "tz": "Europe\/Berlin"
  },
  "EDBW": {
      "icao": "EDBW",
      "iata": "",
      "name": "Werneuchen Airport",
      "city": "Werneuchen",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 262,
      "lat": 52.6316680908,
      "lon": 13.7669439316,
      "tz": "Europe\/Berlin"
  },
  "EDBX": {
      "icao": "EDBX",
      "iata": "",
      "name": "Gorlitz Airport",
      "city": "Gorlitz",
      "state": "Saxony",
      "country": "DE",
      "elevation": 778,
      "lat": 51.1588897705,
      "lon": 14.9502782822,
      "tz": "Europe\/Berlin"
  },
  "EDBY": {
      "icao": "EDBY",
      "iata": "",
      "name": "Schmoldow Airport",
      "city": "Schmoldow",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 105,
      "lat": 53.9722213745,
      "lon": 13.3436107635,
      "tz": "Europe\/Berlin"
  },
  "EDBZ": {
      "icao": "EDBZ",
      "iata": "",
      "name": "Schwarzheide\/Schipkau Airport",
      "city": "Schwarzheide",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 331,
      "lat": 51.4897232056,
      "lon": 13.8794441223,
      "tz": "Europe\/Berlin"
  },
  "EDCA": {
      "icao": "EDCA",
      "iata": "",
      "name": "Anklam Airport",
      "city": "Anklam",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 16,
      "lat": 53.8327789307,
      "lon": 13.6686105728,
      "tz": "Europe\/Berlin"
  },
  "EDCB": {
      "icao": "EDCB",
      "iata": "",
      "name": "Ballenstedt Airport",
      "city": "Ballenstedt",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 535,
      "lat": 51.7458343506,
      "lon": 11.229722023,
      "tz": "Europe\/Berlin"
  },
  "EDCD": {
      "icao": "EDCD",
      "iata": "CBU",
      "name": "Cottbus-Drewitz Airport",
      "city": "Cottbus",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 272,
      "lat": 51.8894424438,
      "lon": 14.5319442749,
      "tz": "Europe\/Berlin"
  },
  "EDCE": {
      "icao": "EDCE",
      "iata": "",
      "name": "Eggersdorf Airport",
      "city": "Eggersdorf",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 223,
      "lat": 52.4816665649,
      "lon": 14.0874996185,
      "tz": "Europe\/Berlin"
  },
  "EDCF": {
      "icao": "EDCF",
      "iata": "",
      "name": "Friedersdorf Airport",
      "city": "",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 115,
      "lat": 52.2832984924,
      "lon": 13.8069000244,
      "tz": "Europe\/Berlin"
  },
  "EDCG": {
      "icao": "EDCG",
      "iata": "GTI",
      "name": "Rugen Airport",
      "city": "Rugen",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 69,
      "lat": 54.3833312988,
      "lon": 13.3255558014,
      "tz": "Europe\/Berlin"
  },
  "EDCH": {
      "icao": "EDCH",
      "iata": "",
      "name": "Sprossen Airport",
      "city": "Rehmsdorf",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 673,
      "lat": 51.043056488,
      "lon": 12.233332634,
      "tz": "Europe\/Berlin"
  },
  "EDCI": {
      "icao": "EDCI",
      "iata": "",
      "name": "Klix Airport",
      "city": "Bautzen",
      "state": "Saxony",
      "country": "DE",
      "elevation": 486,
      "lat": 51.2738876343,
      "lon": 14.5063886642,
      "tz": "Europe\/Berlin"
  },
  "EDCJ": {
      "icao": "EDCJ",
      "iata": "",
      "name": "Chemnitz\/Jahnsdorf Airport",
      "city": "Chemnitz",
      "state": "Saxony",
      "country": "DE",
      "elevation": 1198,
      "lat": 50.7475013733,
      "lon": 12.8374996185,
      "tz": "Europe\/Berlin"
  },
  "EDCK": {
      "icao": "EDCK",
      "iata": "KOQ",
      "name": "Kothen Airport",
      "city": "Kothen",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 305,
      "lat": 51.7211112976,
      "lon": 11.9527778625,
      "tz": "Europe\/Berlin"
  },
  "EDCL": {
      "icao": "EDCL",
      "iata": "",
      "name": "Klietz\/Scharlibbe Airport",
      "city": "Scharlibbe",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 95,
      "lat": 52.7094421387,
      "lon": 12.0733327866,
      "tz": "Europe\/Berlin"
  },
  "EDCM": {
      "icao": "EDCM",
      "iata": "",
      "name": "Kamenz Airport",
      "city": "Kamenz",
      "state": "Saxony",
      "country": "DE",
      "elevation": 495,
      "lat": 51.2969436646,
      "lon": 14.1274995804,
      "tz": "Europe\/Berlin"
  },
  "EDCN": {
      "icao": "EDCN",
      "iata": "",
      "name": "Nauen Airport",
      "city": "",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 112,
      "lat": 52.6277999878,
      "lon": 12.9139003754,
      "tz": "Europe\/Berlin"
  },
  "EDCO": {
      "icao": "EDCO",
      "iata": "",
      "name": "Obermehler-Schlotheim Airport",
      "city": "Obermehler",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 909,
      "lat": 51.2677764893,
      "lon": 10.634721756,
      "tz": "Europe\/Berlin"
  },
  "EDCP": {
      "icao": "EDCP",
      "iata": "PEF",
      "name": "Peenemunde Airport",
      "city": "Peenemunde",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 7,
      "lat": 54.1577796936,
      "lon": 13.7744436264,
      "tz": "Europe\/Berlin"
  },
  "EDCQ": {
      "icao": "EDCQ",
      "iata": "",
      "name": "Aschersleben Airport",
      "city": "Aschersleben",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 525,
      "lat": 51.7669448853,
      "lon": 11.4983329773,
      "tz": "Europe\/Berlin"
  },
  "EDCR": {
      "icao": "EDCR",
      "iata": "",
      "name": "Rerik-Zweedorf Airport",
      "city": "Rerik",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 30,
      "lat": 54.081943512,
      "lon": 11.6491670609,
      "tz": "Europe\/Berlin"
  },
  "EDCS": {
      "icao": "EDCS",
      "iata": "",
      "name": "Saarmund Airport",
      "city": "Saarmund",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 174,
      "lat": 52.3083343506,
      "lon": 13.1005563736,
      "tz": "Europe\/Berlin"
  },
  "EDCT": {
      "icao": "EDCT",
      "iata": "",
      "name": "Taucha Airport",
      "city": "Taucha",
      "state": "Saxony",
      "country": "DE",
      "elevation": 492,
      "lat": 51.3950004578,
      "lon": 12.5369443893,
      "tz": "Europe\/Berlin"
  },
  "EDCU": {
      "icao": "EDCU",
      "iata": "",
      "name": "Gustrow Airport",
      "city": "Gustrow",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 46,
      "lat": 53.8052787781,
      "lon": 12.2305555344,
      "tz": "Europe\/Berlin"
  },
  "EDCV": {
      "icao": "EDCV",
      "iata": "",
      "name": "Pasewalk Airport",
      "city": "Pasewalk",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 72,
      "lat": 53.505279541,
      "lon": 13.9483327866,
      "tz": "Europe\/Berlin"
  },
  "EDCW": {
      "icao": "EDCW",
      "iata": "",
      "name": "Wismar Airport",
      "city": "Wismar",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 43,
      "lat": 53.9144439697,
      "lon": 11.4994440079,
      "tz": "Europe\/Berlin"
  },
  "EDCX": {
      "icao": "EDCX",
      "iata": "",
      "name": "Purkshof Airport",
      "city": "Rostock",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 66,
      "lat": 54.1616668701,
      "lon": 12.2486114502,
      "tz": "Europe\/Berlin"
  },
  "EDCY": {
      "icao": "EDCY",
      "iata": "",
      "name": "Spremberg-Welzow Airport",
      "city": "Welzow",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 374,
      "lat": 51.5755577087,
      "lon": 14.1369438171,
      "tz": "Europe\/Berlin"
  },
  "EDDC": {
      "icao": "EDDC",
      "iata": "DRS",
      "name": "Dresden Airport",
      "city": "Dresden",
      "state": "Saxony",
      "country": "DE",
      "elevation": 755,
      "lat": 51.1328010559,
      "lon": 13.76720047,
      "tz": "Europe\/Berlin"
  },
  "EDDE": {
      "icao": "EDDE",
      "iata": "ERF",
      "name": "Erfurt Airport",
      "city": "Erfurt",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1036,
      "lat": 50.979801178,
      "lon": 10.9581003189,
      "tz": "Europe\/Berlin"
  },
  "EDDF": {
      "icao": "EDDF",
      "iata": "FRA",
      "name": "Frankfurt am Main International Airport",
      "city": "Frankfurt-am-Main",
      "state": "Hesse",
      "country": "DE",
      "elevation": 364,
      "lat": 50.0264015198,
      "lon": 8.543129921,
      "tz": "Europe\/Berlin"
  },
  "EDDG": {
      "icao": "EDDG",
      "iata": "FMO",
      "name": "Munster Osnabruck Airport",
      "city": "Munster",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 160,
      "lat": 52.134601593,
      "lon": 7.6848301888,
      "tz": "Europe\/Berlin"
  },
  "EDDH": {
      "icao": "EDDH",
      "iata": "HAM",
      "name": "Hamburg Airport",
      "city": "Hamburg",
      "state": "Hamburg",
      "country": "DE",
      "elevation": 53,
      "lat": 53.6304016113,
      "lon": 9.9882297516,
      "tz": "Europe\/Berlin"
  },
  "EDDI": {
      "icao": "EDDI",
      "iata": "THF",
      "name": "Berlin Tempelhof Airport",
      "city": "Berlin",
      "state": "Berlin",
      "country": "DE",
      "elevation": 164,
      "lat": 52.473611,
      "lon": 13.401667,
      "tz": "Europe\/Berlin"
  },
  "EDDK": {
      "icao": "EDDK",
      "iata": "CGN",
      "name": "Cologne Bonn Airport",
      "city": "Cologne",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 302,
      "lat": 50.8658981323,
      "lon": 7.1427397728,
      "tz": "Europe\/Berlin"
  },
  "EDDL": {
      "icao": "EDDL",
      "iata": "DUS",
      "name": "Dusseldorf International Airport",
      "city": "Dusseldorf",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 147,
      "lat": 51.2895011902,
      "lon": 6.7667798996,
      "tz": "Europe\/Berlin"
  },
  "EDDM": {
      "icao": "EDDM",
      "iata": "MUC",
      "name": "Munich International Airport",
      "city": "Munich",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1487,
      "lat": 48.3538017273,
      "lon": 11.7861003876,
      "tz": "Europe\/Berlin"
  },
  "EDDN": {
      "icao": "EDDN",
      "iata": "NUE",
      "name": "Nuremberg Airport",
      "city": "Nuremberg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1046,
      "lat": 49.4986991882,
      "lon": 11.0669002533,
      "tz": "Europe\/Berlin"
  },
  "EDDP": {
      "icao": "EDDP",
      "iata": "LEJ",
      "name": "Leipzig Halle Airport",
      "city": "Leipzig",
      "state": "Saxony",
      "country": "DE",
      "elevation": 465,
      "lat": 51.4323997498,
      "lon": 12.2416000366,
      "tz": "Europe\/Berlin"
  },
  "EDDR": {
      "icao": "EDDR",
      "iata": "SCN",
      "name": "Saarbrucken Airport",
      "city": "Saarbrucken",
      "state": "Saarland",
      "country": "DE",
      "elevation": 1058,
      "lat": 49.2145996094,
      "lon": 7.1095099449,
      "tz": "Europe\/Berlin"
  },
  "EDDS": {
      "icao": "EDDS",
      "iata": "STR",
      "name": "Stuttgart Airport",
      "city": "Stuttgart",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1276,
      "lat": 48.6898994446,
      "lon": 9.2219600677,
      "tz": "Europe\/Berlin"
  },
  "EDDT": {
      "icao": "EDDT",
      "iata": "TXL",
      "name": "Berlin-Tegel International Airport",
      "city": "Berlin",
      "state": "Berlin",
      "country": "DE",
      "elevation": 122,
      "lat": 52.5597000122,
      "lon": 13.2876996994,
      "tz": "Europe\/Berlin"
  },
  "EDDV": {
      "icao": "EDDV",
      "iata": "HAJ",
      "name": "Hannover Airport",
      "city": "Hannover",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 183,
      "lat": 52.461101532,
      "lon": 9.6850795746,
      "tz": "Europe\/Berlin"
  },
  "EDDW": {
      "icao": "EDDW",
      "iata": "BRE",
      "name": "Bremen Airport",
      "city": "Bremen",
      "state": "Bremen",
      "country": "DE",
      "elevation": 14,
      "lat": 53.0475006104,
      "lon": 8.7866697311,
      "tz": "Europe\/Berlin"
  },
  "EDEB": {
      "icao": "EDEB",
      "iata": "",
      "name": "Bad Langensalza Airport",
      "city": "Bad Langensalza",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 650,
      "lat": 51.1291656494,
      "lon": 10.6222219467,
      "tz": "Europe\/Berlin"
  },
  "EDEF": {
      "icao": "EDEF",
      "iata": "",
      "name": "Babenhausen Airport",
      "city": "Babenhausen",
      "state": "Hesse",
      "country": "DE",
      "elevation": 928,
      "lat": 49.9524993896,
      "lon": 8.9680557251,
      "tz": "Europe\/Berlin"
  },
  "EDEG": {
      "icao": "EDEG",
      "iata": "",
      "name": "Gotha-Ost Airport",
      "city": "Gotha",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 991,
      "lat": 50.9700012207,
      "lon": 10.7277784348,
      "tz": "Europe\/Berlin"
  },
  "EDEH": {
      "icao": "EDEH",
      "iata": "",
      "name": "Herrenteich Airport",
      "city": "Hockenheim",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 308,
      "lat": 49.3450012207,
      "lon": 8.48777771,
      "tz": "Europe\/Berlin"
  },
  "EDEL": {
      "icao": "EDEL",
      "iata": "",
      "name": "Langenlonsheim Airport",
      "city": "Langenlonsheim",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 295,
      "lat": 49.9083328247,
      "lon": 7.9077777863,
      "tz": "Europe\/Berlin"
  },
  "EDEM": {
      "icao": "EDEM",
      "iata": "",
      "name": "Mosenberg Airport",
      "city": "Homburg",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1296,
      "lat": 51.0627784729,
      "lon": 9.4222221375,
      "tz": "Europe\/Berlin"
  },
  "EDEP": {
      "icao": "EDEP",
      "iata": "",
      "name": "Heppenheim Airport",
      "city": "Heppenheim",
      "state": "Hesse",
      "country": "DE",
      "elevation": 361,
      "lat": 49.6219444275,
      "lon": 8.6244440079,
      "tz": "Europe\/Berlin"
  },
  "EDEQ": {
      "icao": "EDEQ",
      "iata": "",
      "name": "Muhlhausen Airport",
      "city": "Muhlhausen",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 814,
      "lat": 51.213054657,
      "lon": 10.5486106873,
      "tz": "Europe\/Berlin"
  },
  "EDER": {
      "icao": "EDER",
      "iata": "",
      "name": "Wasserkuppe Airport",
      "city": "Gersfeld",
      "state": "Hesse",
      "country": "DE",
      "elevation": 2956,
      "lat": 50.4988899231,
      "lon": 9.9538888931,
      "tz": "Europe\/Berlin"
  },
  "EDEW": {
      "icao": "EDEW",
      "iata": "",
      "name": "Walldurn Airport",
      "city": "Walldurn",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1312,
      "lat": 49.5816688538,
      "lon": 9.4022216797,
      "tz": "Europe\/Berlin"
  },
  "EDFA": {
      "icao": "EDFA",
      "iata": "",
      "name": "Anspach\/Taunus Airport",
      "city": "Anspach",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1102,
      "lat": 50.2883338928,
      "lon": 8.5338888168,
      "tz": "Europe\/Berlin"
  },
  "EDFB": {
      "icao": "EDFB",
      "iata": "",
      "name": "Reichelsheim Airport",
      "city": "Reichelsheim",
      "state": "Hesse",
      "country": "DE",
      "elevation": 397,
      "lat": 50.3358345032,
      "lon": 8.8780555725,
      "tz": "Europe\/Berlin"
  },
  "EDFC": {
      "icao": "EDFC",
      "iata": "",
      "name": "Aschaffenburg Airport",
      "city": "Aschaffenburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 410,
      "lat": 49.9388885498,
      "lon": 9.0638885498,
      "tz": "Europe\/Berlin"
  },
  "EDFD": {
      "icao": "EDFD",
      "iata": "",
      "name": "Bad Neustadt\/Saale-Grasberg Airport",
      "city": "Bad Neustadt a.d.Saale",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 997,
      "lat": 50.3061103821,
      "lon": 10.2266674042,
      "tz": "Europe\/Berlin"
  },
  "EDFE": {
      "icao": "EDFE",
      "iata": "QEF",
      "name": "Frankfurt-Egelsbach Airport",
      "city": "Egelsbach",
      "state": "Hesse",
      "country": "DE",
      "elevation": 384,
      "lat": 49.9599990845,
      "lon": 8.6458330154,
      "tz": "Europe\/Berlin"
  },
  "EDFG": {
      "icao": "EDFG",
      "iata": "",
      "name": "Gelnhausen Airport",
      "city": "Gelnhausen",
      "state": "Hesse",
      "country": "DE",
      "elevation": 446,
      "lat": 50.1972236633,
      "lon": 9.1700000763,
      "tz": "Europe\/Berlin"
  },
  "EDFH": {
      "icao": "EDFH",
      "iata": "HHN",
      "name": "Frankfurt-Hahn Airport",
      "city": "Hahn",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1649,
      "lat": 49.9486999512,
      "lon": 7.2638897896,
      "tz": "Europe\/Berlin"
  },
  "EDFI": {
      "icao": "EDFI",
      "iata": "",
      "name": "Hirzenhain Airport",
      "city": "Hirzenhain",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1706,
      "lat": 50.787776947,
      "lon": 8.3927783966,
      "tz": "Europe\/Berlin"
  },
  "EDFJ": {
      "icao": "EDFJ",
      "iata": "",
      "name": "Lager Hammelburg Airport",
      "city": "Hammelburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1132,
      "lat": 50.0986099243,
      "lon": 9.8836107254,
      "tz": "Europe\/Berlin"
  },
  "EDFK": {
      "icao": "EDFK",
      "iata": "",
      "name": "Bad Kissingen Airport",
      "city": "Bad Kissingen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 653,
      "lat": 50.2105560303,
      "lon": 10.0688886642,
      "tz": "Europe\/Berlin"
  },
  "EDFL": {
      "icao": "EDFL",
      "iata": "",
      "name": "Giesen-Lutzellinden Airport",
      "city": "Giesen",
      "state": "Hesse",
      "country": "DE",
      "elevation": 755,
      "lat": 50.543888092,
      "lon": 8.5902776718,
      "tz": "Europe\/Berlin"
  },
  "EDFM": {
      "icao": "EDFM",
      "iata": "MHG",
      "name": "Mannheim-City Airport",
      "city": "Mannheim",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 308,
      "lat": 49.4730567932,
      "lon": 8.514166832,
      "tz": "Europe\/Berlin"
  },
  "EDFN": {
      "icao": "EDFN",
      "iata": "",
      "name": "Marburg-Schonstadt Airport",
      "city": "Marburg",
      "state": "Hesse",
      "country": "DE",
      "elevation": 833,
      "lat": 50.8744430542,
      "lon": 8.8149995804,
      "tz": "Europe\/Berlin"
  },
  "EDFO": {
      "icao": "EDFO",
      "iata": "",
      "name": "Michelstadt\/Odenwald Airport",
      "city": "Michelstadt",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1142,
      "lat": 49.6786117554,
      "lon": 8.9719438553,
      "tz": "Europe\/Berlin"
  },
  "EDFP": {
      "icao": "EDFP",
      "iata": "",
      "name": "Ober-Morlen Airport",
      "city": "Ober-Morlen",
      "state": "Hesse",
      "country": "DE",
      "elevation": 814,
      "lat": 50.3619422913,
      "lon": 8.7111110687,
      "tz": "Europe\/Berlin"
  },
  "EDFQ": {
      "icao": "EDFQ",
      "iata": "",
      "name": "Allendorf\/Eder Airport",
      "city": "Allendorf",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1158,
      "lat": 51.0349998474,
      "lon": 8.6808328629,
      "tz": "Europe\/Berlin"
  },
  "EDFR": {
      "icao": "EDFR",
      "iata": "",
      "name": "Rothenburg o. d. T. Airport",
      "city": "Rothenburg ob der Tauber",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1309,
      "lat": 49.3883323669,
      "lon": 10.2180557251,
      "tz": "Europe\/Berlin"
  },
  "EDFS": {
      "icao": "EDFS",
      "iata": "",
      "name": "Schweinfurt-Sud Airport",
      "city": "Schweinfurt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 686,
      "lat": 50.0108337402,
      "lon": 10.2511110306,
      "tz": "Europe\/Berlin"
  },
  "EDFT": {
      "icao": "EDFT",
      "iata": "",
      "name": "Lauterbach Airport",
      "city": "Lauterbach",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1211,
      "lat": 50.6833343506,
      "lon": 9.4108333588,
      "tz": "Europe\/Berlin"
  },
  "EDFU": {
      "icao": "EDFU",
      "iata": "",
      "name": "Mainbullau Airport",
      "city": "Miltenberg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1503,
      "lat": 49.6949996948,
      "lon": 9.1824998856,
      "tz": "Europe\/Berlin"
  },
  "EDFV": {
      "icao": "EDFV",
      "iata": "",
      "name": "Worms Airport",
      "city": "Worms",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 295,
      "lat": 49.6069450378,
      "lon": 8.3683328629,
      "tz": "Europe\/Berlin"
  },
  "EDFW": {
      "icao": "EDFW",
      "iata": "",
      "name": "Wurzburg-Schenkenturm Airport",
      "city": "Wurzburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 991,
      "lat": 49.817779541,
      "lon": 9.8975000381,
      "tz": "Europe\/Berlin"
  },
  "EDFX": {
      "icao": "EDFX",
      "iata": "",
      "name": "Hockenheim Airport",
      "city": "Hockenheim",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 315,
      "lat": 49.3252792358,
      "lon": 8.5286111832,
      "tz": "Europe\/Berlin"
  },
  "EDFY": {
      "icao": "EDFY",
      "iata": "",
      "name": "Elz Airport",
      "city": "Limburg an der Lahn",
      "state": "Hesse",
      "country": "DE",
      "elevation": 699,
      "lat": 50.4269447327,
      "lon": 8.0111112595,
      "tz": "Europe\/Berlin"
  },
  "EDFZ": {
      "icao": "EDFZ",
      "iata": "QMZ",
      "name": "Mainz-Finthen Airport",
      "city": "Mainz",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 761,
      "lat": 49.9674987793,
      "lon": 8.1472215652,
      "tz": "Europe\/Berlin"
  },
  "EDGA": {
      "icao": "EDGA",
      "iata": "",
      "name": "Ailertchen Airport",
      "city": "Ailertchen",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1542,
      "lat": 50.5930557251,
      "lon": 7.9450001717,
      "tz": "Europe\/Berlin"
  },
  "EDGB": {
      "icao": "EDGB",
      "iata": "",
      "name": "Breitscheid Airport",
      "city": "Breitscheid",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1834,
      "lat": 50.6763877869,
      "lon": 8.1694440842,
      "tz": "Europe\/Berlin"
  },
  "EDGE": {
      "icao": "EDGE",
      "iata": "EIB",
      "name": "Eisenach-Kindel Airport",
      "city": "Eisenach",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1112,
      "lat": 50.9927787781,
      "lon": 10.4727783203,
      "tz": "Europe\/Berlin"
  },
  "EDGF": {
      "icao": "EDGF",
      "iata": "",
      "name": "Fulda-Jossa Airport",
      "city": "Fulda",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1558,
      "lat": 50.4755554199,
      "lon": 9.4425001144,
      "tz": "Europe\/Berlin"
  },
  "EDGH": {
      "icao": "EDGH",
      "iata": "",
      "name": "Hettstadt Airport",
      "city": "Hettstadt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1050,
      "lat": 49.7986106873,
      "lon": 9.8366670609,
      "tz": "Europe\/Berlin"
  },
  "EDGI": {
      "icao": "EDGI",
      "iata": "",
      "name": "Ingelfingen-Buhlhof Airport",
      "city": "Ingelfingen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1375,
      "lat": 49.3216667175,
      "lon": 9.6630563736,
      "tz": "Europe\/Berlin"
  },
  "EDGJ": {
      "icao": "EDGJ",
      "iata": "",
      "name": "Ochsenfurt Airport",
      "city": "Ochsenfurt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 814,
      "lat": 49.6736106873,
      "lon": 10.0713891983,
      "tz": "Europe\/Berlin"
  },
  "EDGK": {
      "icao": "EDGK",
      "iata": "",
      "name": "Korbach Airport",
      "city": "Korbach",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1280,
      "lat": 51.2522201538,
      "lon": 8.8736114502,
      "tz": "Europe\/Berlin"
  },
  "EDGL": {
      "icao": "EDGL",
      "iata": "",
      "name": "Ludwigshafen Airfield",
      "city": "Dannstadt",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 318,
      "lat": 49.4131,
      "lon": 8.3518,
      "tz": "Europe\/Berlin"
  },
  "EDGM": {
      "icao": "EDGM",
      "iata": "",
      "name": "Mosbach-Lohrbach Airport",
      "city": "Mosbach",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1818,
      "lat": 49.3988876343,
      "lon": 9.1238889694,
      "tz": "Europe\/Berlin"
  },
  "EDGP": {
      "icao": "EDGP",
      "iata": "",
      "name": "Oppenheim Airport",
      "city": "Oppenheim",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 279,
      "lat": 49.8416671753,
      "lon": 8.3766670227,
      "tz": "Europe\/Berlin"
  },
  "EDGQ": {
      "icao": "EDGQ",
      "iata": "",
      "name": "Schameder Airport",
      "city": "Schameder",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1788,
      "lat": 51.0002784729,
      "lon": 8.3077783585,
      "tz": "Europe\/Berlin"
  },
  "EDGR": {
      "icao": "EDGR",
      "iata": "",
      "name": "Giesen-Reiskirchen Airport",
      "city": "Giesen",
      "state": "Hesse",
      "country": "DE",
      "elevation": 702,
      "lat": 50.5669441223,
      "lon": 8.8697223663,
      "tz": "Europe\/Berlin"
  },
  "EDGS": {
      "icao": "EDGS",
      "iata": "SGE",
      "name": "Siegerland Airport",
      "city": "",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1966,
      "lat": 50.707698822,
      "lon": 8.0829696655,
      "tz": "Europe\/Berlin"
  },
  "EDGT": {
      "icao": "EDGT",
      "iata": "",
      "name": "Bottenhorn Airport",
      "city": "Bottenhorn",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1657,
      "lat": 50.7952766418,
      "lon": 8.4583330154,
      "tz": "Europe\/Berlin"
  },
  "EDGU": {
      "icao": "EDGU",
      "iata": "",
      "name": "Unterschupf Airport",
      "city": "Unterschupf",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1155,
      "lat": 49.5158348083,
      "lon": 9.6694440842,
      "tz": "Europe\/Berlin"
  },
  "EDGW": {
      "icao": "EDGW",
      "iata": "",
      "name": "Wolfhagen \u201eGraner Berg\u201c Airport",
      "city": "Wolfhagen",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1027,
      "lat": 51.307220459,
      "lon": 9.17527771,
      "tz": "Europe\/Berlin"
  },
  "EDGX": {
      "icao": "EDGX",
      "iata": "",
      "name": "Walldorf Airport",
      "city": "Walldorf",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 344,
      "lat": 49.3030548096,
      "lon": 8.6588888168,
      "tz": "Europe\/Berlin"
  },
  "EDGZ": {
      "icao": "EDGZ",
      "iata": "",
      "name": "Weinheim\/Bergstrase Airport",
      "city": "Weinheim",
      "state": "Hesse",
      "country": "DE",
      "elevation": 318,
      "lat": 49.5675010681,
      "lon": 8.6105556488,
      "tz": "Europe\/Berlin"
  },
  "EDHB": {
      "icao": "EDHB",
      "iata": "",
      "name": "Grube Airport",
      "city": "Grube",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 7,
      "lat": 54.2444458008,
      "lon": 11.0247220993,
      "tz": "Europe\/Berlin"
  },
  "EDHC": {
      "icao": "EDHC",
      "iata": "",
      "name": "Luchow-Rehbeck Airport",
      "city": "Luchow",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 49,
      "lat": 53.0161094666,
      "lon": 11.1444444656,
      "tz": "Europe\/Berlin"
  },
  "EDHE": {
      "icao": "EDHE",
      "iata": "",
      "name": "Uetersen\/Heist Airport",
      "city": "Uetersen",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 23,
      "lat": 53.6463890076,
      "lon": 9.704167366,
      "tz": "Europe\/Berlin"
  },
  "EDHF": {
      "icao": "EDHF",
      "iata": "",
      "name": "Itzehoe\/Hungriger Wolf Airport",
      "city": "Itzehoe",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 89,
      "lat": 53.9944458008,
      "lon": 9.5786113739,
      "tz": "Europe\/Berlin"
  },
  "EDHG": {
      "icao": "EDHG",
      "iata": "",
      "name": "Luneburg Airport",
      "city": "Luneburg",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 161,
      "lat": 53.2488899231,
      "lon": 10.4616670609,
      "tz": "Europe\/Berlin"
  },
  "EDHI": {
      "icao": "EDHI",
      "iata": "XFW",
      "name": "Hamburg-Finkenwerder Airport",
      "city": "Hamburg",
      "state": "Hamburg",
      "country": "DE",
      "elevation": 23,
      "lat": 53.5352783203,
      "lon": 9.8355560303,
      "tz": "Europe\/Berlin"
  },
  "EDHK": {
      "icao": "EDHK",
      "iata": "KEL",
      "name": "Kiel-Holtenau Airport",
      "city": "Kiel",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 102,
      "lat": 54.3794441223,
      "lon": 10.145277977,
      "tz": "Europe\/Berlin"
  },
  "EDHL": {
      "icao": "EDHL",
      "iata": "LBC",
      "name": "Lubeck Blankensee Airport",
      "city": "Lubeck",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 53,
      "lat": 53.8054008484,
      "lon": 10.7192001343,
      "tz": "Europe\/Berlin"
  },
  "EDHM": {
      "icao": "EDHM",
      "iata": "",
      "name": "Hartenholm Airport",
      "city": "Hasenmoor",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 108,
      "lat": 53.9150009155,
      "lon": 10.0355558395,
      "tz": "Europe\/Berlin"
  },
  "EDHN": {
      "icao": "EDHN",
      "iata": "EUM",
      "name": "Neumunster Airport",
      "city": "Neumunster",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 72,
      "lat": 54.0794448853,
      "lon": 9.9413890839,
      "tz": "Europe\/Berlin"
  },
  "EDHO": {
      "icao": "EDHO",
      "iata": "",
      "name": "Ahrenlohe Airport",
      "city": "Tornesch",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 33,
      "lat": 53.69972229,
      "lon": 9.7405557632,
      "tz": "Europe\/Berlin"
  },
  "EDHP": {
      "icao": "EDHP",
      "iata": "",
      "name": "Pellworm Airport",
      "city": "Pellworm",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 3,
      "lat": 54.5363883972,
      "lon": 8.6800003052,
      "tz": "Europe\/Berlin"
  },
  "EDHS": {
      "icao": "EDHS",
      "iata": "",
      "name": "Stade Airport",
      "city": "Stade",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 62,
      "lat": 53.5611114502,
      "lon": 9.4991674423,
      "tz": "Europe\/Berlin"
  },
  "EDHU": {
      "icao": "EDHU",
      "iata": "",
      "name": "Lauenbruck Airport",
      "city": "Lauenbruck",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 98,
      "lat": 53.2075004578,
      "lon": 9.5733327866,
      "tz": "Europe\/Berlin"
  },
  "EDHW": {
      "icao": "EDHW",
      "iata": "",
      "name": "Wahlstedt Airport",
      "city": "Wahlstedt",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 128,
      "lat": 53.9694442749,
      "lon": 10.2216672897,
      "tz": "Europe\/Berlin"
  },
  "EDJA": {
      "icao": "EDJA",
      "iata": "FMM",
      "name": "Memmingen Allgau Airport",
      "city": "Memmingen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 2077,
      "lat": 47.9888000488,
      "lon": 10.2395000458,
      "tz": "Europe\/Berlin"
  },
  "EDKA": {
      "icao": "EDKA",
      "iata": "AAH",
      "name": "Aachen-Merzbruck Airport",
      "city": "Aachen",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 623,
      "lat": 50.8230552673,
      "lon": 6.1863889694,
      "tz": "Europe\/Berlin"
  },
  "EDKB": {
      "icao": "EDKB",
      "iata": "BNJ",
      "name": "Bonn-Hangelar Airport",
      "city": "Bonn",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 197,
      "lat": 50.7688903809,
      "lon": 7.1633329391,
      "tz": "Europe\/Berlin"
  },
  "EDKD": {
      "icao": "EDKD",
      "iata": "",
      "name": "Altena-Hegenscheid Airport",
      "city": "Altena",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1552,
      "lat": 51.3130569458,
      "lon": 7.7041668892,
      "tz": "Europe\/Berlin"
  },
  "EDKF": {
      "icao": "EDKF",
      "iata": "",
      "name": "Bergneustadt\/Auf dem Dumpel Airport",
      "city": "Bergneustadt",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1604,
      "lat": 51.0522232056,
      "lon": 7.7072219849,
      "tz": "Europe\/Berlin"
  },
  "EDKH": {
      "icao": "EDKH",
      "iata": "",
      "name": "Hunsborn Airport",
      "city": "Freudenberg",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1306,
      "lat": 50.9286117554,
      "lon": 7.8991670609,
      "tz": "Europe\/Berlin"
  },
  "EDKI": {
      "icao": "EDKI",
      "iata": "",
      "name": "Betzdorf-Kirchen Airport",
      "city": "Betzdorf",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1125,
      "lat": 50.8172225952,
      "lon": 7.8305559158,
      "tz": "Europe\/Berlin"
  },
  "EDKL": {
      "icao": "EDKL",
      "iata": "",
      "name": "Leverkusen Airport",
      "city": "Leverkusen",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 157,
      "lat": 51.0152778625,
      "lon": 7.0055561066,
      "tz": "Europe\/Berlin"
  },
  "EDKM": {
      "icao": "EDKM",
      "iata": "",
      "name": "Meschede-Schuren Airport",
      "city": "Meschede",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1434,
      "lat": 51.3027763367,
      "lon": 8.2391672134,
      "tz": "Europe\/Berlin"
  },
  "EDKN": {
      "icao": "EDKN",
      "iata": "",
      "name": "Wipperfurth-Neye Airport",
      "city": "Wipperfurth",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 863,
      "lat": 51.124168396,
      "lon": 7.3736109734,
      "tz": "Europe\/Berlin"
  },
  "EDKO": {
      "icao": "EDKO",
      "iata": "",
      "name": "Brilon\/Hochsauerlandkreis Airport",
      "city": "Brilon",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1509,
      "lat": 51.4025001526,
      "lon": 8.641667366,
      "tz": "Europe\/Berlin"
  },
  "EDKP": {
      "icao": "EDKP",
      "iata": "",
      "name": "Plettenberg-Huinghausen Airport",
      "city": "Plettenberg",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 981,
      "lat": 51.1919441223,
      "lon": 7.7911109924,
      "tz": "Europe\/Berlin"
  },
  "EDKR": {
      "icao": "EDKR",
      "iata": "",
      "name": "Schmallenberg-Rennefeld Airport",
      "city": "Schmallenberg",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1529,
      "lat": 51.1616668701,
      "lon": 8.26222229,
      "tz": "Europe\/Berlin"
  },
  "EDKU": {
      "icao": "EDKU",
      "iata": "",
      "name": "Attendorn-Finnentrop Airport",
      "city": "Attendorn",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1040,
      "lat": 51.1458320618,
      "lon": 7.9366669655,
      "tz": "Europe\/Berlin"
  },
  "EDKV": {
      "icao": "EDKV",
      "iata": "",
      "name": "Dahlemer Binz Airport",
      "city": "Dahlheim",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1896,
      "lat": 50.4055557251,
      "lon": 6.5288891792,
      "tz": "Europe\/Berlin"
  },
  "EDKW": {
      "icao": "EDKW",
      "iata": "",
      "name": "Werdohl-Kuntrop Airport",
      "city": "Werdohl",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1037,
      "lat": 51.2972221375,
      "lon": 7.818333149,
      "tz": "Europe\/Berlin"
  },
  "EDKZ": {
      "icao": "EDKZ",
      "iata": "",
      "name": "Meinerzhagen Airport",
      "city": "Meinerzhagen",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1549,
      "lat": 51.0999984741,
      "lon": 7.5999999046,
      "tz": "Europe\/Berlin"
  },
  "EDLA": {
      "icao": "EDLA",
      "iata": "",
      "name": "Arnsberg-Menden Airport",
      "city": "Neheim-Husten",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 794,
      "lat": 51.4838905334,
      "lon": 7.8983330727,
      "tz": "Europe\/Berlin"
  },
  "EDLB": {
      "icao": "EDLB",
      "iata": "",
      "name": "Borkenberge Airport",
      "city": "Dulmen",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 157,
      "lat": 51.7799987793,
      "lon": 7.2880558968,
      "tz": "Europe\/Berlin"
  },
  "EDLC": {
      "icao": "EDLC",
      "iata": "",
      "name": "Kamp-Lintfort Airport",
      "city": "Rheinberg",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 85,
      "lat": 51.5291671753,
      "lon": 6.536110878,
      "tz": "Europe\/Berlin"
  },
  "EDLD": {
      "icao": "EDLD",
      "iata": "",
      "name": "Dinslaken\/Schwarze Heide Airport",
      "city": "Bottrop-Kirchhellen",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 217,
      "lat": 51.6161117554,
      "lon": 6.8652777672,
      "tz": "Europe\/Berlin"
  },
  "EDLE": {
      "icao": "EDLE",
      "iata": "ESS",
      "name": "Essen Mulheim Airport",
      "city": "",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 424,
      "lat": 51.4023017883,
      "lon": 6.9373297691,
      "tz": "Europe\/Berlin"
  },
  "EDLF": {
      "icao": "EDLF",
      "iata": "",
      "name": "Grefrath-Niershorst Airport",
      "city": "Grefrath",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 105,
      "lat": 51.3338890076,
      "lon": 6.3594441414,
      "tz": "Europe\/Berlin"
  },
  "EDLG": {
      "icao": "EDLG",
      "iata": "",
      "name": "Goch-Asperden Airport",
      "city": "Goch",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 52,
      "lat": 51.6908340454,
      "lon": 6.1041669846,
      "tz": "Europe\/Berlin"
  },
  "EDLH": {
      "icao": "EDLH",
      "iata": "",
      "name": "Hamm-Lippewiesen Airport",
      "city": "Hamm",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 190,
      "lat": 51.6897201538,
      "lon": 7.8161110878,
      "tz": "Europe\/Berlin"
  },
  "EDLI": {
      "icao": "EDLI",
      "iata": "BFE",
      "name": "Bielefeld Airport",
      "city": "Bielefeld",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 433,
      "lat": 51.9647216797,
      "lon": 8.5444440842,
      "tz": "Europe\/Berlin"
  },
  "EDLJ": {
      "icao": "EDLJ",
      "iata": "",
      "name": "Detmold Airport",
      "city": "Detmold",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 650,
      "lat": 51.9408340454,
      "lon": 8.9047222137,
      "tz": "Europe\/Berlin"
  },
  "EDLK": {
      "icao": "EDLK",
      "iata": "",
      "name": "Krefeld-Egelsberg Airport",
      "city": "Krefeld",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 141,
      "lat": 51.3849983215,
      "lon": 6.5877780914,
      "tz": "Europe\/Berlin"
  },
  "EDLM": {
      "icao": "EDLM",
      "iata": "ZOJ",
      "name": "Marl-Loemuhle Airport",
      "city": "Marl",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 240,
      "lat": 51.6472015381,
      "lon": 7.1633300781,
      "tz": "Europe\/Berlin"
  },
  "EDLN": {
      "icao": "EDLN",
      "iata": "MGL",
      "name": "Monchengladbach Airport",
      "city": "Monchengladbach",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 125,
      "lat": 51.2302780151,
      "lon": 6.5044441223,
      "tz": "Europe\/Berlin"
  },
  "EDLO": {
      "icao": "EDLO",
      "iata": "",
      "name": "Oerlinghausen Airport",
      "city": "Oerlinghausen",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 558,
      "lat": 51.932220459,
      "lon": 8.6616668701,
      "tz": "Europe\/Berlin"
  },
  "EDLP": {
      "icao": "EDLP",
      "iata": "PAD",
      "name": "Paderborn Lippstadt Airport",
      "city": "Paderborn",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 699,
      "lat": 51.6141014099,
      "lon": 8.6163196564,
      "tz": "Europe\/Berlin"
  },
  "EDLR": {
      "icao": "EDLR",
      "iata": "",
      "name": "Paderborn-Haxterberg Airport",
      "city": "Paderborn",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 801,
      "lat": 51.688331604,
      "lon": 8.7752780914,
      "tz": "Europe\/Berlin"
  },
  "EDLS": {
      "icao": "EDLS",
      "iata": "",
      "name": "Stadtlohn-Vreden Airport",
      "city": "Stadtlohn",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 157,
      "lat": 51.9958343506,
      "lon": 6.8405561447,
      "tz": "Europe\/Amsterdam"
  },
  "EDLT": {
      "icao": "EDLT",
      "iata": "",
      "name": "Munster-Telgte Airport",
      "city": "Munster",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 177,
      "lat": 51.944442749,
      "lon": 7.7738890648,
      "tz": "Europe\/Berlin"
  },
  "EDLU": {
      "icao": "EDLU",
      "iata": "",
      "name": "Oelde Bergeler Airport",
      "city": "",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 269,
      "lat": 51.8306007385,
      "lon": 8.1747198105,
      "tz": "Europe\/Berlin"
  },
  "EDLV": {
      "icao": "EDLV",
      "iata": "NRN",
      "name": "Niederrhein Airport",
      "city": "Weeze",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 106,
      "lat": 51.6024017334,
      "lon": 6.1421699524,
      "tz": "Europe\/Amsterdam"
  },
  "EDLW": {
      "icao": "EDLW",
      "iata": "DTM",
      "name": "Dortmund Airport",
      "city": "Dortmund",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 425,
      "lat": 51.5182991028,
      "lon": 7.6122398377,
      "tz": "Europe\/Berlin"
  },
  "EDLX": {
      "icao": "EDLX",
      "iata": "",
      "name": "Wesel-Romerwardt Airport",
      "city": "Wesel",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 72,
      "lat": 51.662776947,
      "lon": 6.5958328247,
      "tz": "Europe\/Berlin"
  },
  "EDLY": {
      "icao": "EDLY",
      "iata": "",
      "name": "Borken-Hoxfeld Airport",
      "city": "Borken",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 157,
      "lat": 51.8533325195,
      "lon": 6.8147220612,
      "tz": "Europe\/Berlin"
  },
  "EDLZ": {
      "icao": "EDLZ",
      "iata": "",
      "name": "Soest\/Bad Sassendorf Airport",
      "city": "Bad Sassendorf",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 394,
      "lat": 51.5780563354,
      "lon": 8.2147216797,
      "tz": "Europe\/Berlin"
  },
  "EDMA": {
      "icao": "EDMA",
      "iata": "AGB",
      "name": "Augsburg Airport",
      "city": "Augsburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1516,
      "lat": 48.42527771,
      "lon": 10.9316673279,
      "tz": "Europe\/Berlin"
  },
  "EDMB": {
      "icao": "EDMB",
      "iata": "",
      "name": "Biberach a.d. Ris Airport",
      "city": "Biberach an der Ris",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1903,
      "lat": 48.1111106873,
      "lon": 9.7627782822,
      "tz": "Europe\/Berlin"
  },
  "EDMC": {
      "icao": "EDMC",
      "iata": "",
      "name": "Blaubeuren Airport",
      "city": "Blaubeuren",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2218,
      "lat": 48.4197235107,
      "lon": 9.798333168,
      "tz": "Europe\/Berlin"
  },
  "EDMD": {
      "icao": "EDMD",
      "iata": "",
      "name": "Dachau-Grobenried Airport",
      "city": "Dachau",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1608,
      "lat": 48.2283325195,
      "lon": 11.4230556488,
      "tz": "Europe\/Berlin"
  },
  "EDME": {
      "icao": "EDME",
      "iata": "",
      "name": "Eggenfelden Airport",
      "city": "Eggenfelden",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1342,
      "lat": 48.3961105347,
      "lon": 12.723610878,
      "tz": "Europe\/Berlin"
  },
  "EDMF": {
      "icao": "EDMF",
      "iata": "",
      "name": "Furstenzell Airport",
      "city": "Furstenzell",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1345,
      "lat": 48.5180549622,
      "lon": 13.3458328247,
      "tz": "Europe\/Berlin"
  },
  "EDMG": {
      "icao": "EDMG",
      "iata": "",
      "name": "Gunzburg-Donauried Airport",
      "city": "Gunzburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1457,
      "lat": 48.4866676331,
      "lon": 10.2833328247,
      "tz": "Europe\/Berlin"
  },
  "EDMH": {
      "icao": "EDMH",
      "iata": "",
      "name": "Gunzenhausen-Reutberg Airport",
      "city": "Gunzenhausen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1591,
      "lat": 49.1119422913,
      "lon": 10.7808332443,
      "tz": "Europe\/Berlin"
  },
  "EDMI": {
      "icao": "EDMI",
      "iata": "",
      "name": "Illertissen Airport",
      "city": "Illertissen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1680,
      "lat": 48.2350006104,
      "lon": 10.1374998093,
      "tz": "Europe\/Berlin"
  },
  "EDMJ": {
      "icao": "EDMJ",
      "iata": "",
      "name": "Jesenwang Airport",
      "city": "Jesenwang",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1860,
      "lat": 48.1741676331,
      "lon": 11.125,
      "tz": "Europe\/Berlin"
  },
  "EDMK": {
      "icao": "EDMK",
      "iata": "",
      "name": "Kempten-Durach Airport",
      "city": "Kempten",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 2339,
      "lat": 47.6919441223,
      "lon": 10.3380556107,
      "tz": "Europe\/Berlin"
  },
  "EDML": {
      "icao": "EDML",
      "iata": "",
      "name": "Landshut Airport",
      "city": "Landshut",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1312,
      "lat": 48.5116653442,
      "lon": 12.0333328247,
      "tz": "Europe\/Berlin"
  },
  "EDMN": {
      "icao": "EDMN",
      "iata": "",
      "name": "Mindelheim-Mattsies Airport",
      "city": "Mindelheim",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1857,
      "lat": 48.1069450378,
      "lon": 10.5249996185,
      "tz": "Europe\/Berlin"
  },
  "EDMO": {
      "icao": "EDMO",
      "iata": "OBF",
      "name": "Oberpfaffenhofen Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1947,
      "lat": 48.081401825,
      "lon": 11.2831001282,
      "tz": "Europe\/Berlin"
  },
  "EDMP": {
      "icao": "EDMP",
      "iata": "",
      "name": "Vilsbiburg Airport",
      "city": "Vilsbiburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1450,
      "lat": 48.426109314,
      "lon": 12.3452777863,
      "tz": "Europe\/Berlin"
  },
  "EDMQ": {
      "icao": "EDMQ",
      "iata": "",
      "name": "Donauworth-Genderkingen Airport",
      "city": "Donauworth",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1312,
      "lat": 48.7027778625,
      "lon": 10.851111412,
      "tz": "Europe\/Berlin"
  },
  "EDMS": {
      "icao": "EDMS",
      "iata": "RBM",
      "name": "Straubing Airport",
      "city": "Straubing",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1047,
      "lat": 48.9008331299,
      "lon": 12.516667366,
      "tz": "Europe\/Berlin"
  },
  "EDMT": {
      "icao": "EDMT",
      "iata": "",
      "name": "Tannheim Airport",
      "city": "Tannheim",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1903,
      "lat": 48.0099983215,
      "lon": 10.098610878,
      "tz": "Europe\/Berlin"
  },
  "EDMU": {
      "icao": "EDMU",
      "iata": "",
      "name": "Gundelfingen Airport",
      "city": "Gundelfingen a.d.Donau",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1447,
      "lat": 48.569442749,
      "lon": 10.3588886261,
      "tz": "Europe\/Berlin"
  },
  "EDMV": {
      "icao": "EDMV",
      "iata": "",
      "name": "Vilshofen Airport",
      "city": "Vilshofen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 991,
      "lat": 48.6349983215,
      "lon": 13.195555687,
      "tz": "Europe\/Berlin"
  },
  "EDMW": {
      "icao": "EDMW",
      "iata": "",
      "name": "Deggendorf Airport",
      "city": "Deggendorf",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1030,
      "lat": 48.8302764893,
      "lon": 12.8797216415,
      "tz": "Europe\/Berlin"
  },
  "EDMY": {
      "icao": "EDMY",
      "iata": "",
      "name": "Muhldorf Airport",
      "city": "Muhldorf am Inn",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1325,
      "lat": 48.2794456482,
      "lon": 12.5005559921,
      "tz": "Europe\/Berlin"
  },
  "EDNA": {
      "icao": "EDNA",
      "iata": "",
      "name": "Ampfing-Waldkraiburg Airport",
      "city": "Ampfing",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1362,
      "lat": 48.2636108398,
      "lon": 12.4116668701,
      "tz": "Europe\/Berlin"
  },
  "EDNB": {
      "icao": "EDNB",
      "iata": "",
      "name": "Arnbruck Airport",
      "city": "Arnbruck",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1716,
      "lat": 49.1247215271,
      "lon": 12.9855556488,
      "tz": "Europe\/Berlin"
  },
  "EDNC": {
      "icao": "EDNC",
      "iata": "",
      "name": "Beilngries Airport",
      "city": "Beilngries",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1211,
      "lat": 49.0213890076,
      "lon": 11.4847221375,
      "tz": "Europe\/Berlin"
  },
  "EDND": {
      "icao": "EDND",
      "iata": "",
      "name": "Dinkelsbuhl-Sinbronn Airport",
      "city": "Dinkelsbuhl",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1598,
      "lat": 49.0649986267,
      "lon": 10.4011106491,
      "tz": "Europe\/Berlin"
  },
  "EDNE": {
      "icao": "EDNE",
      "iata": "",
      "name": "Erbach Airport",
      "city": "Erbach",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1558,
      "lat": 48.3422203064,
      "lon": 9.9161109924,
      "tz": "Europe\/Berlin"
  },
  "EDNF": {
      "icao": "EDNF",
      "iata": "",
      "name": "Elsenthal Grafe Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1417,
      "lat": 48.8224983215,
      "lon": 13.3675003052,
      "tz": "Europe\/Berlin"
  },
  "EDNG": {
      "icao": "EDNG",
      "iata": "",
      "name": "Giengen\/Brenz Airport",
      "city": "Giengen an der Brenz",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1696,
      "lat": 48.6341667175,
      "lon": 10.2163887024,
      "tz": "Europe\/Berlin"
  },
  "EDNH": {
      "icao": "EDNH",
      "iata": "",
      "name": "Bad Worishofen-Nord Airport",
      "city": "Bad Worishofen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1220,
      "lat": 48.0163879395,
      "lon": 10.6161108017,
      "tz": "Europe\/Berlin"
  },
  "EDNI": {
      "icao": "EDNI",
      "iata": "",
      "name": "Berching Airport",
      "city": "Berching",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1266,
      "lat": 49.1305541992,
      "lon": 11.4430561066,
      "tz": "Europe\/Berlin"
  },
  "EDNJ": {
      "icao": "EDNJ",
      "iata": "",
      "name": "Neuburg-Egweil Airport",
      "city": "Neuburg an der Donau",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1345,
      "lat": 48.7819442749,
      "lon": 11.2152776718,
      "tz": "Europe\/Berlin"
  },
  "EDNK": {
      "icao": "EDNK",
      "iata": "",
      "name": "Kirchdorf\/Inn Airport",
      "city": "Kirchdorf am Inn",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1138,
      "lat": 48.2383346558,
      "lon": 12.9766674042,
      "tz": "Europe\/Berlin"
  },
  "EDNL": {
      "icao": "EDNL",
      "iata": "",
      "name": "Leutkirch-Unterzeil Airport",
      "city": "Leutkirch",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2100,
      "lat": 47.8588905334,
      "lon": 10.014166832,
      "tz": "Europe\/Berlin"
  },
  "EDNM": {
      "icao": "EDNM",
      "iata": "",
      "name": "Nittenau-Bruck Airport",
      "city": "Nittenau",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1161,
      "lat": 49.2224998474,
      "lon": 12.296667099,
      "tz": "Europe\/Berlin"
  },
  "EDNO": {
      "icao": "EDNO",
      "iata": "",
      "name": "Nordlingen Airport",
      "city": "Nordlingen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1385,
      "lat": 48.8705558777,
      "lon": 10.5050001144,
      "tz": "Europe\/Berlin"
  },
  "EDNP": {
      "icao": "EDNP",
      "iata": "",
      "name": "Pfarrkirchen Airport",
      "city": "Pfarrkirchen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1266,
      "lat": 48.4202766418,
      "lon": 12.8647222519,
      "tz": "Europe\/Berlin"
  },
  "EDNQ": {
      "icao": "EDNQ",
      "iata": "",
      "name": "Bopfingen Airport",
      "city": "Bopfingen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2028,
      "lat": 48.8480567932,
      "lon": 10.3338890076,
      "tz": "Europe\/Berlin"
  },
  "EDNR": {
      "icao": "EDNR",
      "iata": "",
      "name": "Regensburg-Oberhub Airport",
      "city": "Regensburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1299,
      "lat": 49.1419448853,
      "lon": 12.0819444656,
      "tz": "Europe\/Berlin"
  },
  "EDNS": {
      "icao": "EDNS",
      "iata": "",
      "name": "Schwabmunchen Airport",
      "city": "Schwabmunchen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1804,
      "lat": 48.1791687012,
      "lon": 10.7027778625,
      "tz": "Europe\/Berlin"
  },
  "EDNT": {
      "icao": "EDNT",
      "iata": "",
      "name": "Treuchtlingen-Bubenheim Airport",
      "city": "Treuchtlingen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1345,
      "lat": 48.9961128235,
      "lon": 10.8841667175,
      "tz": "Europe\/Berlin"
  },
  "EDNU": {
      "icao": "EDNU",
      "iata": "",
      "name": "Thannhausen Airport",
      "city": "Thannhausen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1611,
      "lat": 48.2900009155,
      "lon": 10.4419441223,
      "tz": "Europe\/Berlin"
  },
  "EDNV": {
      "icao": "EDNV",
      "iata": "",
      "name": "Vogtareuth Airport",
      "city": "Vogtareuth",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1535,
      "lat": 47.9461097717,
      "lon": 12.2047224045,
      "tz": "Europe\/Berlin"
  },
  "EDNW": {
      "icao": "EDNW",
      "iata": "",
      "name": "Weisenhorn Airport",
      "city": "Weisenhorn",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1644,
      "lat": 48.2894439697,
      "lon": 10.1402778625,
      "tz": "Europe\/Berlin"
  },
  "EDNX": {
      "icao": "EDNX",
      "iata": "",
      "name": "Oberschleisheim Airfield",
      "city": "Oberschleisheim",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1594,
      "lat": 48.2394447327,
      "lon": 11.5613889694,
      "tz": "Europe\/Berlin"
  },
  "EDNY": {
      "icao": "EDNY",
      "iata": "FDH",
      "name": "Friedrichshafen Airport",
      "city": "Friedrichshafen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1367,
      "lat": 47.6712989807,
      "lon": 9.5114898682,
      "tz": "Europe\/Berlin"
  },
  "EDNZ": {
      "icao": "EDNZ",
      "iata": "",
      "name": "Zell-Haidberg Airport",
      "city": "Zell im Fichtelgebirge",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 2083,
      "lat": 50.1369438171,
      "lon": 11.7944440842,
      "tz": "Europe\/Berlin"
  },
  "EDOA": {
      "icao": "EDOA",
      "iata": "",
      "name": "Auerbach Airport",
      "city": "Auerbach\/Vogtl.",
      "state": "Saxony",
      "country": "DE",
      "elevation": 1880,
      "lat": 50.4988899231,
      "lon": 12.3777780533,
      "tz": "Europe\/Berlin"
  },
  "EDOB": {
      "icao": "EDOB",
      "iata": "",
      "name": "Bad Berka Airport",
      "city": "Bad Berka",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1001,
      "lat": 50.9044456482,
      "lon": 11.2602777481,
      "tz": "Europe\/Berlin"
  },
  "EDOC": {
      "icao": "EDOC",
      "iata": "",
      "name": "Gardelegen Airport",
      "city": "Gardelegen",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 230,
      "lat": 52.5272216797,
      "lon": 11.3513889313,
      "tz": "Europe\/Berlin"
  },
  "EDOD": {
      "icao": "EDOD",
      "iata": "",
      "name": "Reinsdorf Airport",
      "city": "Reinsdorf",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 335,
      "lat": 51.9008331299,
      "lon": 13.1944437027,
      "tz": "Europe\/Berlin"
  },
  "EDOE": {
      "icao": "EDOE",
      "iata": "",
      "name": "Bohlen Airport",
      "city": "Bohlen",
      "state": "Saxony",
      "country": "DE",
      "elevation": 430,
      "lat": 51.2138900757,
      "lon": 12.3636112213,
      "tz": "Europe\/Berlin"
  },
  "EDOF": {
      "icao": "EDOF",
      "iata": "",
      "name": "Bad Frankenhausen Airport",
      "city": "Bad Frankenhausen",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 761,
      "lat": 51.3725013733,
      "lon": 11.1413888931,
      "tz": "Europe\/Berlin"
  },
  "EDOG": {
      "icao": "EDOG",
      "iata": "",
      "name": "Torgau-Beilrode Airport",
      "city": "Torgau",
      "state": "Saxony",
      "country": "DE",
      "elevation": 269,
      "lat": 51.5711669922,
      "lon": 13.0521669388,
      "tz": "Europe\/Berlin"
  },
  "EDOH": {
      "icao": "EDOH",
      "iata": "",
      "name": "Langhennersdorf Airport",
      "city": "Freiberg",
      "state": "Saxony",
      "country": "DE",
      "elevation": 1266,
      "lat": 50.9483337402,
      "lon": 13.2616672516,
      "tz": "Europe\/Berlin"
  },
  "EDOI": {
      "icao": "EDOI",
      "iata": "",
      "name": "Bienenfarm Airport",
      "city": "Nauen",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 131,
      "lat": 52.6616668701,
      "lon": 12.7458333969,
      "tz": "Europe\/Berlin"
  },
  "EDOJ": {
      "icao": "EDOJ",
      "iata": "",
      "name": "Lusse Airport",
      "city": "Lusse",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 217,
      "lat": 52.1411094666,
      "lon": 12.6647224426,
      "tz": "Europe\/Berlin"
  },
  "EDOK": {
      "icao": "EDOK",
      "iata": "",
      "name": "Rudolstadt-Groschwitz Airport",
      "city": "Rudolstadt",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1535,
      "lat": 50.7327766418,
      "lon": 11.2361106873,
      "tz": "Europe\/Berlin"
  },
  "EDOL": {
      "icao": "EDOL",
      "iata": "FRF",
      "name": "Oschersleben Airport",
      "city": "Oschersleben",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 344,
      "lat": 52.0380554199,
      "lon": 11.2055559158,
      "tz": "Europe\/Berlin"
  },
  "EDOM": {
      "icao": "EDOM",
      "iata": "",
      "name": "Klein Muhlingen Airport",
      "city": "Calbe",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 171,
      "lat": 51.9474983215,
      "lon": 11.7697219849,
      "tz": "Europe\/Berlin"
  },
  "EDON": {
      "icao": "EDON",
      "iata": "",
      "name": "Neuhardenberg Airport",
      "city": "Neuhardenberg",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 39,
      "lat": 52.6130561829,
      "lon": 14.2427778244,
      "tz": "Europe\/Berlin"
  },
  "EDOP": {
      "icao": "EDOP",
      "iata": "SZW",
      "name": "Schwerin Parchim Airport",
      "city": "",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 166,
      "lat": 53.4269981384,
      "lon": 11.7833995819,
      "tz": "Europe\/Berlin"
  },
  "EDOQ": {
      "icao": "EDOQ",
      "iata": "",
      "name": "Oschatz Airport",
      "city": "Oschatz",
      "state": "Saxony",
      "country": "DE",
      "elevation": 502,
      "lat": 51.2966651917,
      "lon": 13.078332901,
      "tz": "Europe\/Berlin"
  },
  "EDOR": {
      "icao": "EDOR",
      "iata": "",
      "name": "Stolln-Rhinow Airport",
      "city": "Rhinow",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 138,
      "lat": 52.7408332825,
      "lon": 12.3900003433,
      "tz": "Europe\/Berlin"
  },
  "EDOS": {
      "icao": "EDOS",
      "iata": "",
      "name": "Pennewitz Airport",
      "city": "Pennewitz",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1506,
      "lat": 50.6694450378,
      "lon": 11.0505561829,
      "tz": "Europe\/Berlin"
  },
  "EDOT": {
      "icao": "EDOT",
      "iata": "",
      "name": "Greiz-Obergrochlitz Airport",
      "city": "Greiz",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1266,
      "lat": 50.644443512,
      "lon": 12.1763887405,
      "tz": "Europe\/Berlin"
  },
  "EDOU": {
      "icao": "EDOU",
      "iata": "",
      "name": "Weimar-Umpferstedt Airport",
      "city": "Weimar",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 984,
      "lat": 50.9647216797,
      "lon": 11.4002780914,
      "tz": "Europe\/Berlin"
  },
  "EDOV": {
      "icao": "EDOV",
      "iata": "",
      "name": "Stendal-Borstel Airport",
      "city": "Stendal",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 184,
      "lat": 52.6288871765,
      "lon": 11.818611145,
      "tz": "Europe\/Berlin"
  },
  "EDOW": {
      "icao": "EDOW",
      "iata": "",
      "name": "Waren-Vielist Airport",
      "city": "Waren",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 282,
      "lat": 53.5683326721,
      "lon": 12.6527776718,
      "tz": "Europe\/Berlin"
  },
  "EDOX": {
      "icao": "EDOX",
      "iata": "",
      "name": "Renneritz Airport",
      "city": "Sandersdorf",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 308,
      "lat": 51.594165802,
      "lon": 12.2372217178,
      "tz": "Europe\/Berlin"
  },
  "EDOZ": {
      "icao": "EDOZ",
      "iata": "",
      "name": "Schonebeck-Zackmunde Airport",
      "city": "Schonebeck",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 167,
      "lat": 51.9966659546,
      "lon": 11.7908334732,
      "tz": "Europe\/Berlin"
  },
  "EDPA": {
      "icao": "EDPA",
      "iata": "",
      "name": "Aalen-Heidenheim\/Elchingen Airport",
      "city": "Aalen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1916,
      "lat": 48.7777786255,
      "lon": 10.2647218704,
      "tz": "Europe\/Berlin"
  },
  "EDPB": {
      "icao": "EDPB",
      "iata": "",
      "name": "Bad Ditzenbach Airport",
      "city": "Bad Ditzenbach",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2362,
      "lat": 48.5627784729,
      "lon": 9.7288894653,
      "tz": "Europe\/Berlin"
  },
  "EDPC": {
      "icao": "EDPC",
      "iata": "",
      "name": "Bad Endorf Joll Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1690,
      "lat": 47.9268989563,
      "lon": 12.2871999741,
      "tz": "Europe\/Berlin"
  },
  "EDPD": {
      "icao": "EDPD",
      "iata": "",
      "name": "Dingolfing Airport",
      "city": "Dingolfing",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1165,
      "lat": 48.6569442749,
      "lon": 12.5005559921,
      "tz": "Europe\/Berlin"
  },
  "EDPE": {
      "icao": "EDPE",
      "iata": "",
      "name": "Eichstatt Airport",
      "city": "Eichstatt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1713,
      "lat": 48.8769454956,
      "lon": 11.1822223663,
      "tz": "Europe\/Berlin"
  },
  "EDPF": {
      "icao": "EDPF",
      "iata": "",
      "name": "Schwandorf Airport",
      "city": "Schwandorf",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1270,
      "lat": 49.3397216797,
      "lon": 12.1880559921,
      "tz": "Europe\/Berlin"
  },
  "EDPG": {
      "icao": "EDPG",
      "iata": "",
      "name": "Griesau Airport",
      "city": "Pfatter",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1060,
      "lat": 48.9538879395,
      "lon": 12.4213886261,
      "tz": "Europe\/Berlin"
  },
  "EDPH": {
      "icao": "EDPH",
      "iata": "",
      "name": "Schwabach-Heidenberg Airport",
      "city": "Schwabach",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1181,
      "lat": 49.268611908,
      "lon": 11.009721756,
      "tz": "Europe\/Berlin"
  },
  "EDPJ": {
      "icao": "EDPJ",
      "iata": "",
      "name": "Laichingen Airport",
      "city": "Laichingen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2434,
      "lat": 48.4969444275,
      "lon": 9.6402778625,
      "tz": "Europe\/Berlin"
  },
  "EDPK": {
      "icao": "EDPK",
      "iata": "",
      "name": "Schonberg Airport",
      "city": "Seebruck",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1946,
      "lat": 48.0477790833,
      "lon": 12.5005559921,
      "tz": "Europe\/Berlin"
  },
  "EDPM": {
      "icao": "EDPM",
      "iata": "",
      "name": "Donzdorf Airport",
      "city": "Donzdorf",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2274,
      "lat": 48.6780548096,
      "lon": 9.8436107635,
      "tz": "Europe\/Berlin"
  },
  "EDPO": {
      "icao": "EDPO",
      "iata": "",
      "name": "Neumarkt\/Obf. Airport",
      "city": "Neumarkt in der Oberpfalz",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1394,
      "lat": 49.2855567932,
      "lon": 11.4430561066,
      "tz": "Europe\/Berlin"
  },
  "EDPQ": {
      "icao": "EDPQ",
      "iata": "",
      "name": "Schmidgaden Airport",
      "city": "Schmidgaden",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1247,
      "lat": 49.4294433594,
      "lon": 12.0980558395,
      "tz": "Europe\/Berlin"
  },
  "EDPS": {
      "icao": "EDPS",
      "iata": "",
      "name": "Sonnen Airport",
      "city": "Waldkirchen",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 2674,
      "lat": 48.6827774048,
      "lon": 13.6947221756,
      "tz": "Europe\/Berlin"
  },
  "EDPT": {
      "icao": "EDPT",
      "iata": "",
      "name": "Gerstetten Airport",
      "city": "Gerstetten",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1975,
      "lat": 48.6202774048,
      "lon": 10.0583333969,
      "tz": "Europe\/Berlin"
  },
  "EDPU": {
      "icao": "EDPU",
      "iata": "",
      "name": "Bartholoma-Amalienhof Airport",
      "city": "Bartholoma",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 984,
      "lat": 48.7466659546,
      "lon": 10.0050001144,
      "tz": "Europe\/Berlin"
  },
  "EDPW": {
      "icao": "EDPW",
      "iata": "",
      "name": "Thalmassing-Waizenhofen Airport",
      "city": "Thalmassing",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1893,
      "lat": 49.0641670227,
      "lon": 11.2091665268,
      "tz": "Europe\/Berlin"
  },
  "EDPY": {
      "icao": "EDPY",
      "iata": "",
      "name": "Ellwangen Airport",
      "city": "Ellwangen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1650,
      "lat": 48.9611091614,
      "lon": 10.2363891602,
      "tz": "Europe\/Berlin"
  },
  "EDQB": {
      "icao": "EDQB",
      "iata": "",
      "name": "Bad Windsheim Airport",
      "city": "Bad Windsheim",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1220,
      "lat": 49.5099983215,
      "lon": 10.3663892746,
      "tz": "Europe\/Berlin"
  },
  "EDQC": {
      "icao": "EDQC",
      "iata": "",
      "name": "Coburg-Brandensteinsebene Airport",
      "city": "Coburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1490,
      "lat": 50.2625007629,
      "lon": 10.9955558777,
      "tz": "Europe\/Berlin"
  },
  "EDQD": {
      "icao": "EDQD",
      "iata": "BYU",
      "name": "Bayreuth Airport",
      "city": "Bayreuth",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1601,
      "lat": 49.9850006104,
      "lon": 11.6400003433,
      "tz": "Europe\/Berlin"
  },
  "EDQE": {
      "icao": "EDQE",
      "iata": "URD",
      "name": "Burg Feuerstein Airport",
      "city": "Ebermannstadt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1673,
      "lat": 49.7941665649,
      "lon": 11.1336107254,
      "tz": "Europe\/Berlin"
  },
  "EDQF": {
      "icao": "EDQF",
      "iata": "QOB",
      "name": "Ansbach-Petersdorf Airport",
      "city": "Ansbach",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1371,
      "lat": 49.3611106873,
      "lon": 10.6694440842,
      "tz": "Europe\/Berlin"
  },
  "EDQG": {
      "icao": "EDQG",
      "iata": "",
      "name": "Giebelstadt Airport",
      "city": "Giebelstadt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 981,
      "lat": 49.6480560303,
      "lon": 9.9663887024,
      "tz": "Europe\/Berlin"
  },
  "EDQH": {
      "icao": "EDQH",
      "iata": "",
      "name": "Herzogenaurach Airport",
      "city": "Herzogenaurach",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1070,
      "lat": 49.5825004578,
      "lon": 10.8780555725,
      "tz": "Europe\/Berlin"
  },
  "EDQI": {
      "icao": "EDQI",
      "iata": "",
      "name": "Lauf-Lillinghof Airport",
      "city": "Lauf an der Pegnitz",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1788,
      "lat": 49.6052780151,
      "lon": 11.2838888168,
      "tz": "Europe\/Berlin"
  },
  "EDQK": {
      "icao": "EDQK",
      "iata": "",
      "name": "Kulmbach Airport",
      "city": "Kulmbach",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1660,
      "lat": 50.1349983215,
      "lon": 11.4597215652,
      "tz": "Europe\/Berlin"
  },
  "EDQL": {
      "icao": "EDQL",
      "iata": "",
      "name": "Lichtenfels Airport",
      "city": "Lichtenfels",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 853,
      "lat": 50.1486091614,
      "lon": 11.0480556488,
      "tz": "Europe\/Berlin"
  },
  "EDQM": {
      "icao": "EDQM",
      "iata": "HOQ",
      "name": "Hof-Plauen Airport",
      "city": "Hof",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1959,
      "lat": 50.2886123657,
      "lon": 11.8563890457,
      "tz": "Europe\/Berlin"
  },
  "EDQN": {
      "icao": "EDQN",
      "iata": "",
      "name": "Neustadt\/Aisch Airport",
      "city": "Neustadt an der Aisch",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1198,
      "lat": 49.5875015259,
      "lon": 10.5775003433,
      "tz": "Europe\/Berlin"
  },
  "EDQO": {
      "icao": "EDQO",
      "iata": "",
      "name": "Ottengruner Heide Airport",
      "city": "Helmbrechts",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1880,
      "lat": 50.2258338928,
      "lon": 11.7316665649,
      "tz": "Europe\/Berlin"
  },
  "EDQP": {
      "icao": "EDQP",
      "iata": "",
      "name": "Rosenthal-Field Plossen Airport",
      "city": "Speichersdorf",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1496,
      "lat": 49.8633346558,
      "lon": 11.7877779007,
      "tz": "Europe\/Berlin"
  },
  "EDQR": {
      "icao": "EDQR",
      "iata": "",
      "name": "Ebern-Sendelbach Airport",
      "city": "Ebern",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 827,
      "lat": 50.0394439697,
      "lon": 10.8227777481,
      "tz": "Europe\/Berlin"
  },
  "EDQS": {
      "icao": "EDQS",
      "iata": "",
      "name": "Suhl-Goldlauter Airport",
      "city": "Goldlauter-Heidersbach",
      "state": "Thuringia",
      "country": "DE",
      "elevation": 1923,
      "lat": 50.631942749,
      "lon": 10.7274999619,
      "tz": "Europe\/Berlin"
  },
  "EDQT": {
      "icao": "EDQT",
      "iata": "",
      "name": "Hasfurt-Schweinfurt Airport",
      "city": "Hasfurt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 719,
      "lat": 50.0180549622,
      "lon": 10.5294437408,
      "tz": "Europe\/Berlin"
  },
  "EDQW": {
      "icao": "EDQW",
      "iata": "",
      "name": "Weiden in der Oberpfalz Airport",
      "city": "Weiden in der Oberpfalz",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1329,
      "lat": 49.6789016724,
      "lon": 12.116399765,
      "tz": "Europe\/Berlin"
  },
  "EDQX": {
      "icao": "EDQX",
      "iata": "",
      "name": "Hetzleser Berg Airport",
      "city": "Neunkirchen am Brand",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1765,
      "lat": 49.6422233582,
      "lon": 11.1622219086,
      "tz": "Europe\/Berlin"
  },
  "EDQY": {
      "icao": "EDQY",
      "iata": "",
      "name": "Coburg-Steinrucken Airport",
      "city": "Coburg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1184,
      "lat": 50.230556488,
      "lon": 10.9958333969,
      "tz": "Europe\/Berlin"
  },
  "EDQZ": {
      "icao": "EDQZ",
      "iata": "",
      "name": "Pegnitz-Zipser Berg Airport",
      "city": "Pegnitz",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1791,
      "lat": 49.76222229,
      "lon": 11.57472229,
      "tz": "Europe\/Berlin"
  },
  "EDRA": {
      "icao": "EDRA",
      "iata": "",
      "name": "Bad Neuenahr-Ahrweiler Airport",
      "city": "Bad Neuenahr",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 673,
      "lat": 50.5577774048,
      "lon": 7.1363887787,
      "tz": "Europe\/Berlin"
  },
  "EDRB": {
      "icao": "EDRB",
      "iata": "BBJ",
      "name": "Bitburg Airport",
      "city": "Bitburg",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1220,
      "lat": 49.9452781677,
      "lon": 6.5650000572,
      "tz": "Europe\/Berlin"
  },
  "EDRD": {
      "icao": "EDRD",
      "iata": "",
      "name": "Neumagen-Dhron Airport",
      "city": "Neumagen",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 879,
      "lat": 49.8430557251,
      "lon": 6.9161109924,
      "tz": "Europe\/Berlin"
  },
  "EDRF": {
      "icao": "EDRF",
      "iata": "",
      "name": "Bad Durkheim Airport",
      "city": "Bad Durkheim",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 351,
      "lat": 49.4730567932,
      "lon": 8.1963891983,
      "tz": "Europe\/Berlin"
  },
  "EDRG": {
      "icao": "EDRG",
      "iata": "",
      "name": "Idar-Oberstein\/Gottschied Airport",
      "city": "Idar-Oberstein",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1575,
      "lat": 49.7322235107,
      "lon": 7.3361110687,
      "tz": "Europe\/Berlin"
  },
  "EDRH": {
      "icao": "EDRH",
      "iata": "",
      "name": "Hoppstadten-Weiersbach Airport",
      "city": "Hoppstadten",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1093,
      "lat": 49.6105575562,
      "lon": 7.1866669655,
      "tz": "Europe\/Berlin"
  },
  "EDRI": {
      "icao": "EDRI",
      "iata": "",
      "name": "Linkenheim Airport",
      "city": "Linkenheim",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 325,
      "lat": 49.1416664124,
      "lon": 8.3947219849,
      "tz": "Europe\/Berlin"
  },
  "EDRJ": {
      "icao": "EDRJ",
      "iata": "",
      "name": "Saarlouis-Duren Airport",
      "city": "Saarlouis",
      "state": "Saarland",
      "country": "DE",
      "elevation": 1119,
      "lat": 49.3125,
      "lon": 6.6744441986,
      "tz": "Europe\/Berlin"
  },
  "EDRK": {
      "icao": "EDRK",
      "iata": "",
      "name": "Koblenz-Winningen Airport",
      "city": "Koblenz",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 640,
      "lat": 50.3255577087,
      "lon": 7.5286111832,
      "tz": "Europe\/Berlin"
  },
  "EDRL": {
      "icao": "EDRL",
      "iata": "",
      "name": "Lachen-Speyerdorf Airport",
      "city": "Neustadt an der Weinstrase",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 394,
      "lat": 49.3308334351,
      "lon": 8.2097215652,
      "tz": "Europe\/Berlin"
  },
  "EDRM": {
      "icao": "EDRM",
      "iata": "",
      "name": "Traben-Trarbach\/Mont Royal Airport",
      "city": "Traben-Trarbach",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 919,
      "lat": 49.9677772522,
      "lon": 7.1119441986,
      "tz": "Europe\/Berlin"
  },
  "EDRN": {
      "icao": "EDRN",
      "iata": "",
      "name": "Nannhausen Airport",
      "city": "Simmern",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1224,
      "lat": 49.9702796936,
      "lon": 7.4791669846,
      "tz": "Europe\/Berlin"
  },
  "EDRO": {
      "icao": "EDRO",
      "iata": "",
      "name": "Schweighofen Airport",
      "city": "Schweighofen",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 492,
      "lat": 49.031665802,
      "lon": 7.9899997711,
      "tz": "Europe\/Berlin"
  },
  "EDRP": {
      "icao": "EDRP",
      "iata": "",
      "name": "Pirmasens Airport",
      "city": "Pirmasens",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1247,
      "lat": 49.2644424438,
      "lon": 7.4883332253,
      "tz": "Europe\/Berlin"
  },
  "EDRS": {
      "icao": "EDRS",
      "iata": "",
      "name": "Bad Sobernheim-Domberg Airport",
      "city": "Bad Sobernheim",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 810,
      "lat": 49.7908325195,
      "lon": 7.6661109924,
      "tz": "Europe\/Berlin"
  },
  "EDRT": {
      "icao": "EDRT",
      "iata": "",
      "name": "Trier-Fohren Airport",
      "city": "Trier",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 666,
      "lat": 49.8638877869,
      "lon": 6.7874999046,
      "tz": "Europe\/Berlin"
  },
  "EDRV": {
      "icao": "EDRV",
      "iata": "",
      "name": "Wershofen\/Eifel Airport",
      "city": "Aremberg",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1581,
      "lat": 50.4513893127,
      "lon": 6.7833328247,
      "tz": "Europe\/Berlin"
  },
  "EDRW": {
      "icao": "EDRW",
      "iata": "",
      "name": "Dierdorf-Wienau Airport",
      "city": "Dierdorf",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 951,
      "lat": 50.5661125183,
      "lon": 7.6533331871,
      "tz": "Europe\/Berlin"
  },
  "EDRY": {
      "icao": "EDRY",
      "iata": "",
      "name": "Speyer Airport",
      "city": "Speyer",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 312,
      "lat": 49.3047218323,
      "lon": 8.4513893127,
      "tz": "Europe\/Berlin"
  },
  "EDRZ": {
      "icao": "EDRZ",
      "iata": "ZQW",
      "name": "Zweibrucken Airport",
      "city": "Zweibrucken",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1132,
      "lat": 49.209400177,
      "lon": 7.4005599022,
      "tz": "Europe\/Berlin"
  },
  "EDSA": {
      "icao": "EDSA",
      "iata": "",
      "name": "Albstadt-Degerfeld Airport",
      "city": "Albstadt",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2881,
      "lat": 48.2497215271,
      "lon": 9.0625,
      "tz": "Europe\/Berlin"
  },
  "EDSB": {
      "icao": "EDSB",
      "iata": "FKB",
      "name": "Karlsruhe Baden-Baden Airport",
      "city": "Baden-Baden",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 408,
      "lat": 48.7793998718,
      "lon": 8.0804996491,
      "tz": "Europe\/Berlin"
  },
  "EDSD": {
      "icao": "EDSD",
      "iata": "",
      "name": "Leipheim Air Base",
      "city": "Leipheim",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1604,
      "lat": 48.4399986267,
      "lon": 10.2356004715,
      "tz": "Europe\/Berlin"
  },
  "EDSG": {
      "icao": "EDSG",
      "iata": "",
      "name": "Grabenstetten Airport",
      "city": "Grabenstetten",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2329,
      "lat": 48.5361099243,
      "lon": 9.4369440079,
      "tz": "Europe\/Berlin"
  },
  "EDSH": {
      "icao": "EDSH",
      "iata": "",
      "name": "Backnang-Heiningen Airport",
      "city": "Backnang",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 965,
      "lat": 48.9197235107,
      "lon": 9.4552783966,
      "tz": "Europe\/Berlin"
  },
  "EDSI": {
      "icao": "EDSI",
      "iata": "",
      "name": "Binningen Airport",
      "city": "Binningen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1594,
      "lat": 47.7991676331,
      "lon": 8.720000267,
      "tz": "Europe\/Berlin"
  },
  "EDSK": {
      "icao": "EDSK",
      "iata": "",
      "name": "Kehl-Sundheim Airport",
      "city": "Kehl",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 453,
      "lat": 48.5611114502,
      "lon": 7.8433327675,
      "tz": "Europe\/Berlin"
  },
  "EDSL": {
      "icao": "EDSL",
      "iata": "",
      "name": "Blumberg Airport",
      "city": "Blumberg",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2290,
      "lat": 47.844165802,
      "lon": 8.5649995804,
      "tz": "Europe\/Berlin"
  },
  "EDSN": {
      "icao": "EDSN",
      "iata": "",
      "name": "Neuhausen ob Eck Airport",
      "city": "Neuhausen ob Eck",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2638,
      "lat": 47.9766654968,
      "lon": 8.9038887024,
      "tz": "Europe\/Berlin"
  },
  "EDSW": {
      "icao": "EDSW",
      "iata": "",
      "name": "Altdorf-Wallburg Airport",
      "city": "Altdorf",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 623,
      "lat": 48.2700004578,
      "lon": 7.8419442177,
      "tz": "Europe\/Berlin"
  },
  "EDSZ": {
      "icao": "EDSZ",
      "iata": "",
      "name": "Rottweil-Zepfenhahn Airport",
      "city": "Rottweil",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2444,
      "lat": 48.1863899231,
      "lon": 8.7211112976,
      "tz": "Europe\/Berlin"
  },
  "EDTB": {
      "icao": "EDTB",
      "iata": "",
      "name": "Baden-Oos Airport",
      "city": "Baden-Baden",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 404,
      "lat": 48.7919425964,
      "lon": 8.1858329773,
      "tz": "Europe\/Berlin"
  },
  "EDTC": {
      "icao": "EDTC",
      "iata": "",
      "name": "Bruchsal Airport",
      "city": "Bruchsal",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 364,
      "lat": 49.1349983215,
      "lon": 8.5636110306,
      "tz": "Europe\/Berlin"
  },
  "EDTD": {
      "icao": "EDTD",
      "iata": "ZQL",
      "name": "Donaueschingen-Villingen Airport",
      "city": "Donaueschingen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2231,
      "lat": 47.9733314514,
      "lon": 8.5222215653,
      "tz": "Europe\/Berlin"
  },
  "EDTF": {
      "icao": "EDTF",
      "iata": "",
      "name": "Freiburg i. Br. Airport",
      "city": "Freiburg im Breisgau",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 801,
      "lat": 48.0227775574,
      "lon": 7.8324999809,
      "tz": "Europe\/Berlin"
  },
  "EDTG": {
      "icao": "EDTG",
      "iata": "",
      "name": "Bremgarten Airport",
      "city": "Bremgarten",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 696,
      "lat": 47.9027786255,
      "lon": 7.6177778244,
      "tz": "Europe\/Berlin"
  },
  "EDTH": {
      "icao": "EDTH",
      "iata": "",
      "name": "Heubach Airport",
      "city": "Heubach",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1424,
      "lat": 48.8027763367,
      "lon": 9.9274997711,
      "tz": "Europe\/Berlin"
  },
  "EDTK": {
      "icao": "EDTK",
      "iata": "",
      "name": "Karlsruhe-Forchheim Airport",
      "city": "",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 381,
      "lat": 48.9759368896,
      "lon": 8.3423995972,
      "tz": "Europe\/Berlin"
  },
  "EDTL": {
      "icao": "EDTL",
      "iata": "LHA",
      "name": "Lahr Airport",
      "city": "",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 511,
      "lat": 48.3693008423,
      "lon": 7.8277201653,
      "tz": "Europe\/Berlin"
  },
  "EDTM": {
      "icao": "EDTM",
      "iata": "",
      "name": "Mengen-Hohentengen Airport",
      "city": "Mengen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1818,
      "lat": 48.0538902283,
      "lon": 9.3727779388,
      "tz": "Europe\/Berlin"
  },
  "EDTN": {
      "icao": "EDTN",
      "iata": "",
      "name": "Nabern\/Teck Airport",
      "city": "Nabern",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1214,
      "lat": 48.61277771,
      "lon": 9.4772224426,
      "tz": "Europe\/Berlin"
  },
  "EDTO": {
      "icao": "EDTO",
      "iata": "",
      "name": "Offenburg Airport",
      "city": "Offenburg",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 509,
      "lat": 48.4500007629,
      "lon": 7.9247221947,
      "tz": "Europe\/Berlin"
  },
  "EDTP": {
      "icao": "EDTP",
      "iata": "",
      "name": "Pfullendorf Airport",
      "city": "Pfullendorf",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2303,
      "lat": 47.9088897705,
      "lon": 9.2505559921,
      "tz": "Europe\/Berlin"
  },
  "EDTQ": {
      "icao": "EDTQ",
      "iata": "",
      "name": "Pattonville Airport",
      "city": "Pattonville",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 919,
      "lat": 48.8641662598,
      "lon": 9.2247219086,
      "tz": "Europe\/Berlin"
  },
  "EDTR": {
      "icao": "EDTR",
      "iata": "",
      "name": "Herten-Rheinfelden Airport",
      "city": "Rheinfelden",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 925,
      "lat": 47.5602798462,
      "lon": 7.7483329773,
      "tz": "Europe\/Berlin"
  },
  "EDTS": {
      "icao": "EDTS",
      "iata": "",
      "name": "Schwenningen am Neckar Airport",
      "city": "Villingen-Schwenningen",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2169,
      "lat": 48.0658340454,
      "lon": 8.5713891983,
      "tz": "Europe\/Berlin"
  },
  "EDTU": {
      "icao": "EDTU",
      "iata": "",
      "name": "Saulgau Airport",
      "city": "Bad Saulgau",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1903,
      "lat": 48.0294456482,
      "lon": 9.5072221756,
      "tz": "Europe\/Berlin"
  },
  "EDTW": {
      "icao": "EDTW",
      "iata": "",
      "name": "Winzeln-Schramberg Airport",
      "city": "Schramberg",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 2310,
      "lat": 48.2791671753,
      "lon": 8.4283332825,
      "tz": "Europe\/Berlin"
  },
  "EDTX": {
      "icao": "EDTX",
      "iata": "",
      "name": "Schwabisch Hall-Weckrieden Airport",
      "city": "Schwabisch Hall",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1299,
      "lat": 49.1244430542,
      "lon": 9.7811107635,
      "tz": "Europe\/Berlin"
  },
  "EDTY": {
      "icao": "EDTY",
      "iata": "",
      "name": "Adolf Wurth Airport",
      "city": "Schwabisch Hall",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1299,
      "lat": 49.1183319092,
      "lon": 9.7838888168,
      "tz": "Europe\/Berlin"
  },
  "EDTZ": {
      "icao": "EDTZ",
      "iata": "",
      "name": "Konstanz Airport",
      "city": "Konstanz",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1302,
      "lat": 47.6819458008,
      "lon": 9.13722229,
      "tz": "Europe\/Berlin"
  },
  "EDUA": {
      "icao": "EDUA",
      "iata": "",
      "name": "Stechow-Ferchesar Airport",
      "city": "Stechow-Ferchesar",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 148,
      "lat": 52.650554657,
      "lon": 12.48777771,
      "tz": "Europe\/Berlin"
  },
  "EDUB": {
      "icao": "EDUB",
      "iata": "",
      "name": "Brandenburg-Briest Airport",
      "city": "Brandenburg an der Havel",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 102,
      "lat": 52.4386100769,
      "lon": 12.4583330154,
      "tz": "Europe\/Berlin"
  },
  "EDUC": {
      "icao": "EDUC",
      "iata": "",
      "name": "Briesen Brand Airport",
      "city": "",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 230,
      "lat": 52.0327987671,
      "lon": 13.7491998672,
      "tz": "Europe\/Berlin"
  },
  "EDUF": {
      "icao": "EDUF",
      "iata": "",
      "name": "Falkenberg-Lonnewitz Airport",
      "city": "Uebigau-Wahrenbruck",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 312,
      "lat": 51.5477790833,
      "lon": 13.228055954,
      "tz": "Europe\/Berlin"
  },
  "EDUO": {
      "icao": "EDUO",
      "iata": "",
      "name": "Oberrissdorf Airport",
      "city": "Lutherstadt Eisleben",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 738,
      "lat": 51.5424995422,
      "lon": 11.5958328247,
      "tz": "Europe\/Berlin"
  },
  "EDUS": {
      "icao": "EDUS",
      "iata": "",
      "name": "Finsterwalde\/Schacksdorf Airport",
      "city": "Finsterwalde",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 384,
      "lat": 51.6074981689,
      "lon": 13.7436113358,
      "tz": "Europe\/Berlin"
  },
  "EDUW": {
      "icao": "EDUW",
      "iata": "",
      "name": "Tutow Airport",
      "city": "Demmin",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 26,
      "lat": 53.9219436646,
      "lon": 13.2188892365,
      "tz": "Europe\/Berlin"
  },
  "EDUY": {
      "icao": "EDUY",
      "iata": "",
      "name": "Welzow-Sedlitzer See Airport",
      "city": "Welzow",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 361,
      "lat": 51.5577774048,
      "lon": 14.1049995422,
      "tz": "Europe\/Berlin"
  },
  "EDUZ": {
      "icao": "EDUZ",
      "iata": "",
      "name": "Zerbst Airport",
      "city": "Zerbst\/Anhalt",
      "state": "Saxony-Anhalt",
      "country": "DE",
      "elevation": 289,
      "lat": 52.000831604,
      "lon": 12.1486110687,
      "tz": "Europe\/Berlin"
  },
  "EDVA": {
      "icao": "EDVA",
      "iata": "",
      "name": "Bad Gandersheim Airport",
      "city": "Bad Gandersheim",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 791,
      "lat": 51.8541679382,
      "lon": 10.0255556107,
      "tz": "Europe\/Berlin"
  },
  "EDVC": {
      "icao": "EDVC",
      "iata": "",
      "name": "Celle-Arloh Airport",
      "city": "Celle",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 207,
      "lat": 52.6872215271,
      "lon": 10.1113891602,
      "tz": "Europe\/Berlin"
  },
  "EDVE": {
      "icao": "EDVE",
      "iata": "BWE",
      "name": "Braunschweig Wolfsburg Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 295,
      "lat": 52.3191986084,
      "lon": 10.5560998917,
      "tz": "Europe\/Berlin"
  },
  "EDVF": {
      "icao": "EDVF",
      "iata": "",
      "name": "Blomberg-Borkhausen Airport",
      "city": "Blomberg",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 535,
      "lat": 51.9172210693,
      "lon": 9.1116666794,
      "tz": "Europe\/Berlin"
  },
  "EDVG": {
      "icao": "EDVG",
      "iata": "",
      "name": "Mengeringhausen Airport",
      "city": "Mengeringhausen",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1191,
      "lat": 51.3763885498,
      "lon": 8.9811105728,
      "tz": "Europe\/Berlin"
  },
  "EDVH": {
      "icao": "EDVH",
      "iata": "",
      "name": "Hodenhagen Airport",
      "city": "Hodenhagen",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 79,
      "lat": 52.7611122131,
      "lon": 9.6055555344,
      "tz": "Europe\/Berlin"
  },
  "EDVI": {
      "icao": "EDVI",
      "iata": "",
      "name": "Hoxter-Holzminden Airport",
      "city": "Hoxter",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 1263,
      "lat": 51.8066673279,
      "lon": 9.3783330917,
      "tz": "Europe\/Berlin"
  },
  "EDVJ": {
      "icao": "EDVJ",
      "iata": "",
      "name": "Salzgitter-Schaferstuhl Airport",
      "city": "Salzgitter",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 741,
      "lat": 52.0302772522,
      "lon": 10.364443779,
      "tz": "Europe\/Berlin"
  },
  "EDVK": {
      "icao": "EDVK",
      "iata": "KSF",
      "name": "Kassel-Calden Airport",
      "city": "Kassel",
      "state": "Hesse",
      "country": "DE",
      "elevation": 909,
      "lat": 51.4083328247,
      "lon": 9.3774995804,
      "tz": "Europe\/Berlin"
  },
  "EDVL": {
      "icao": "EDVL",
      "iata": "",
      "name": "Holleberg Airport",
      "city": "Trendelburg",
      "state": "Hesse",
      "country": "DE",
      "elevation": 837,
      "lat": 51.6108322144,
      "lon": 9.3975000381,
      "tz": "Europe\/Berlin"
  },
  "EDVM": {
      "icao": "EDVM",
      "iata": "",
      "name": "Hildesheim Airport",
      "city": "Hildesheim",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 292,
      "lat": 52.181388855,
      "lon": 9.9463891983,
      "tz": "Europe\/Berlin"
  },
  "EDVN": {
      "icao": "EDVN",
      "iata": "",
      "name": "Northeim Airport",
      "city": "Northeim",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 404,
      "lat": 51.7063903809,
      "lon": 10.0397224426,
      "tz": "Europe\/Berlin"
  },
  "EDVR": {
      "icao": "EDVR",
      "iata": "",
      "name": "Rinteln Airport",
      "city": "Rinteln",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 180,
      "lat": 52.17527771,
      "lon": 9.0533332825,
      "tz": "Europe\/Berlin"
  },
  "EDVS": {
      "icao": "EDVS",
      "iata": "",
      "name": "Salzgitter-Drutte Airport",
      "city": "Salzgitter",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 328,
      "lat": 52.1544456482,
      "lon": 10.4266672134,
      "tz": "Europe\/Berlin"
  },
  "EDVU": {
      "icao": "EDVU",
      "iata": "",
      "name": "Uelzen Airport",
      "city": "Uelzen",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 246,
      "lat": 52.9838905334,
      "lon": 10.4650001526,
      "tz": "Europe\/Berlin"
  },
  "EDVW": {
      "icao": "EDVW",
      "iata": "",
      "name": "Hameln-Pyrmont Airport",
      "city": "Bad Pyrmont",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 1178,
      "lat": 51.9666671753,
      "lon": 9.2916669846,
      "tz": "Europe\/Berlin"
  },
  "EDVY": {
      "icao": "EDVY",
      "iata": "",
      "name": "Porta Westfalica Airport",
      "city": "Bad Oeynhausen",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 148,
      "lat": 52.2208328247,
      "lon": 8.859167099,
      "tz": "Europe\/Berlin"
  },
  "EDWA": {
      "icao": "EDWA",
      "iata": "",
      "name": "Bordelum Airport",
      "city": "Bredstedt",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 3,
      "lat": 54.6274986267,
      "lon": 8.9302778244,
      "tz": "Europe\/Berlin"
  },
  "EDWB": {
      "icao": "EDWB",
      "iata": "BRV",
      "name": "Bremerhaven Airport",
      "city": "Bremerhaven",
      "state": "Bremen",
      "country": "DE",
      "elevation": 10,
      "lat": 53.506942749,
      "lon": 8.5727777481,
      "tz": "Europe\/Berlin"
  },
  "EDWC": {
      "icao": "EDWC",
      "iata": "",
      "name": "Damme Airport",
      "city": "Damme",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 151,
      "lat": 52.4874992371,
      "lon": 8.1855564117,
      "tz": "Europe\/Berlin"
  },
  "EDWD": {
      "icao": "EDWD",
      "iata": "XLW",
      "name": "Lemwerder Airport",
      "city": "Lemwerder",
      "state": "Bremen",
      "country": "DE",
      "elevation": 20,
      "lat": 53.1446990967,
      "lon": 8.6244401932,
      "tz": "Europe\/Berlin"
  },
  "EDWE": {
      "icao": "EDWE",
      "iata": "EME",
      "name": "Emden Airport",
      "city": "Emden",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 3,
      "lat": 53.3911094666,
      "lon": 7.2274999619,
      "tz": "Europe\/Berlin"
  },
  "EDWF": {
      "icao": "EDWF",
      "iata": "",
      "name": "Leer-Papenburg Airport",
      "city": "Leer",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 3,
      "lat": 53.2719421387,
      "lon": 7.4416670799,
      "tz": "Europe\/Berlin"
  },
  "EDWG": {
      "icao": "EDWG",
      "iata": "AGE",
      "name": "Wangerooge Airport",
      "city": "Wangerooge",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 7,
      "lat": 53.7827796936,
      "lon": 7.9138889313,
      "tz": "Europe\/Berlin"
  },
  "EDWH": {
      "icao": "EDWH",
      "iata": "",
      "name": "Oldenburg-Hatten Airport",
      "city": "Oldenburg",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 26,
      "lat": 53.0688896179,
      "lon": 8.3136110306,
      "tz": "Europe\/Berlin"
  },
  "EDWI": {
      "icao": "EDWI",
      "iata": "WVN",
      "name": "Wilhelmshaven-Mariensiel Airport",
      "city": "Wilhelmshaven",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 16,
      "lat": 53.5022201538,
      "lon": 8.0522222519,
      "tz": "Europe\/Berlin"
  },
  "EDWJ": {
      "icao": "EDWJ",
      "iata": "JUI",
      "name": "Juist Airport",
      "city": "Juist",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 7,
      "lat": 53.6811103821,
      "lon": 7.0558328629,
      "tz": "Europe\/Berlin"
  },
  "EDWK": {
      "icao": "EDWK",
      "iata": "",
      "name": "Karlshofen Airport",
      "city": "Karlshofen",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 20,
      "lat": 53.3327789307,
      "lon": 9.0283327103,
      "tz": "Europe\/Berlin"
  },
  "EDWL": {
      "icao": "EDWL",
      "iata": "LGO",
      "name": "Langeoog Airport",
      "city": "Langeoog",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 7,
      "lat": 53.7425003052,
      "lon": 7.4977779388,
      "tz": "Europe\/Berlin"
  },
  "EDWM": {
      "icao": "EDWM",
      "iata": "",
      "name": "Weser-Wumme Airport",
      "city": "Rotenburg (Wumme)",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 59,
      "lat": 53.0538902283,
      "lon": 9.2086105347,
      "tz": "Europe\/Berlin"
  },
  "EDWN": {
      "icao": "EDWN",
      "iata": "ZOW",
      "name": "Nordhorn-Lingen Airport",
      "city": "Klausheide",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 89,
      "lat": 52.4575004578,
      "lon": 7.1822218895,
      "tz": "Europe\/Berlin"
  },
  "EDWO": {
      "icao": "EDWO",
      "iata": "",
      "name": "Osnabruck-Atterheide Airport",
      "city": "Osnabruck",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 285,
      "lat": 52.2863883972,
      "lon": 7.9697217941,
      "tz": "Europe\/Berlin"
  },
  "EDWP": {
      "icao": "EDWP",
      "iata": "",
      "name": "Wiefelstede\/Conneforde Airport",
      "city": "Wiefelstede",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 33,
      "lat": 53.3213882446,
      "lon": 8.0733327866,
      "tz": "Europe\/Berlin"
  },
  "EDWQ": {
      "icao": "EDWQ",
      "iata": "",
      "name": "Ganderkesee Atlas Airfield Airport",
      "city": "Ganderkesee",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 95,
      "lat": 53.0361099243,
      "lon": 8.5055561066,
      "tz": "Europe\/Berlin"
  },
  "EDWR": {
      "icao": "EDWR",
      "iata": "BMK",
      "name": "Borkum Airport",
      "city": "Borkum",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 3,
      "lat": 53.5963897705,
      "lon": 6.7091670036,
      "tz": "Europe\/Berlin"
  },
  "EDWS": {
      "icao": "EDWS",
      "iata": "NOD",
      "name": "Norden-Norddeich Airport",
      "city": "Norddeich",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 3,
      "lat": 53.6330566406,
      "lon": 7.1902780533,
      "tz": "Europe\/Berlin"
  },
  "EDWU": {
      "icao": "EDWU",
      "iata": "VAC",
      "name": "Varrelbusch Airport",
      "city": "Cloppenburg",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 128,
      "lat": 52.9083328247,
      "lon": 8.040555954,
      "tz": "Europe\/Berlin"
  },
  "EDWV": {
      "icao": "EDWV",
      "iata": "",
      "name": "Verden-Scharnhorst Airport",
      "city": "Verden (Aller)",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 144,
      "lat": 52.9652786255,
      "lon": 9.2827777863,
      "tz": "Europe\/Berlin"
  },
  "EDWX": {
      "icao": "EDWX",
      "iata": "",
      "name": "Westerstede-Felde Airport",
      "city": "Westerstede",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 30,
      "lat": 53.2886123657,
      "lon": 7.9305558205,
      "tz": "Europe\/Berlin"
  },
  "EDWY": {
      "icao": "EDWY",
      "iata": "NRD",
      "name": "Norderney Airport",
      "city": "Norderney",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 7,
      "lat": 53.706943512,
      "lon": 7.2300000191,
      "tz": "Europe\/Berlin"
  },
  "EDWZ": {
      "icao": "EDWZ",
      "iata": "BMR",
      "name": "Baltrum Airport",
      "city": "Baltrum",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 7,
      "lat": 53.7247238159,
      "lon": 7.3733329773,
      "tz": "Europe\/Berlin"
  },
  "EDXA": {
      "icao": "EDXA",
      "iata": "",
      "name": "Achmer Airport",
      "city": "Bramsche",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 177,
      "lat": 52.3772201538,
      "lon": 7.9133329391,
      "tz": "Europe\/Berlin"
  },
  "EDXB": {
      "icao": "EDXB",
      "iata": "HEI",
      "name": "Heide-Busum Airport",
      "city": "Busum",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 7,
      "lat": 54.1533317566,
      "lon": 8.9016666412,
      "tz": "Europe\/Berlin"
  },
  "EDXC": {
      "icao": "EDXC",
      "iata": "",
      "name": "Schleswig-Kropp Airport",
      "city": "Schleswig",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 52,
      "lat": 54.42527771,
      "lon": 9.5416669846,
      "tz": "Europe\/Berlin"
  },
  "EDXD": {
      "icao": "EDXD",
      "iata": "",
      "name": "Bohmte-Bad Essen Airport",
      "city": "Bohmte",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 148,
      "lat": 52.3513908386,
      "lon": 8.328332901,
      "tz": "Europe\/Berlin"
  },
  "EDXE": {
      "icao": "EDXE",
      "iata": "",
      "name": "Rheine-Eschendorf Airport",
      "city": "Rheine",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 131,
      "lat": 52.276389,
      "lon": 7.492778,
      "tz": "Europe\/Berlin"
  },
  "EDXF": {
      "icao": "EDXF",
      "iata": "FLF",
      "name": "Flensburg-Schaeferhaus Airport",
      "city": "Flensburg",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 131,
      "lat": 54.7733345032,
      "lon": 9.3788890839,
      "tz": "Europe\/Berlin"
  },
  "EDXG": {
      "icao": "EDXG",
      "iata": "",
      "name": "Melle-Gronegau Airport",
      "city": "Melle",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 236,
      "lat": 52.2008323669,
      "lon": 8.3805561066,
      "tz": "Europe\/Berlin"
  },
  "EDXH": {
      "icao": "EDXH",
      "iata": "HGL",
      "name": "Helgoland-Dune Airport",
      "city": "Helgoland",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 8,
      "lat": 54.1852798462,
      "lon": 7.9158329964,
      "tz": "Europe\/Berlin"
  },
  "EDXI": {
      "icao": "EDXI",
      "iata": "",
      "name": "Nienburg-Holzbalge Airport",
      "city": "Nienburg\/Weser",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 82,
      "lat": 52.7097206116,
      "lon": 9.1625003815,
      "tz": "Europe\/Berlin"
  },
  "EDXJ": {
      "icao": "EDXJ",
      "iata": "QHU",
      "name": "Husum-Schwesing Airport",
      "city": "Husum",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 62,
      "lat": 54.5099983215,
      "lon": 9.1383333206,
      "tz": "Europe\/Berlin"
  },
  "EDXK": {
      "icao": "EDXK",
      "iata": "",
      "name": "Leck Airport",
      "city": "Leck",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 23,
      "lat": 54.7900009155,
      "lon": 8.961388588,
      "tz": "Europe\/Berlin"
  },
  "EDXL": {
      "icao": "EDXL",
      "iata": "",
      "name": "Barsel Airport",
      "city": "Barsel",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 10,
      "lat": 53.1644439697,
      "lon": 7.7938890457,
      "tz": "Europe\/Berlin"
  },
  "EDXM": {
      "icao": "EDXM",
      "iata": "",
      "name": "St. Michaelisdonn Airport",
      "city": "Sankt Michaelisdonn",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 125,
      "lat": 53.9780578613,
      "lon": 9.1447219849,
      "tz": "Europe\/Berlin"
  },
  "EDXN": {
      "icao": "EDXN",
      "iata": "",
      "name": "Nordholz-Spieka Airport",
      "city": "Cuxhaven",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 72,
      "lat": 53.7672233582,
      "lon": 8.6436109543,
      "tz": "Europe\/Berlin"
  },
  "EDXO": {
      "icao": "EDXO",
      "iata": "PSH",
      "name": "St. Peter-Ording Airport",
      "city": "Sankt Peter-Ording",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 7,
      "lat": 54.3088874817,
      "lon": 8.6869440079,
      "tz": "Europe\/Berlin"
  },
  "EDXP": {
      "icao": "EDXP",
      "iata": "",
      "name": "Harle Airport",
      "city": "Harlesiel",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 7,
      "lat": 53.7066688538,
      "lon": 7.8202781677,
      "tz": "Europe\/Berlin"
  },
  "EDXQ": {
      "icao": "EDXQ",
      "iata": "",
      "name": "Rotenburg (Wumme) Airport",
      "city": "Rotenburg (Wumme)",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 98,
      "lat": 53.1283340454,
      "lon": 9.348610878,
      "tz": "Europe\/Berlin"
  },
  "EDXR": {
      "icao": "EDXR",
      "iata": "",
      "name": "Rendsburg-Schachtholm Airport",
      "city": "Rendsburg",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 23,
      "lat": 54.2200012207,
      "lon": 9.5994443893,
      "tz": "Europe\/Berlin"
  },
  "EDXS": {
      "icao": "EDXS",
      "iata": "",
      "name": "Seedorf Airport",
      "city": "Seedorf",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 72,
      "lat": 53.3355560303,
      "lon": 9.2594442368,
      "tz": "Europe\/Berlin"
  },
  "EDXT": {
      "icao": "EDXT",
      "iata": "",
      "name": "Sierksdorf\/Hof Altona Airport",
      "city": "Sierksdorf",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 79,
      "lat": 54.067779541,
      "lon": 10.7430562973,
      "tz": "Europe\/Berlin"
  },
  "EDXU": {
      "icao": "EDXU",
      "iata": "",
      "name": "Huttenbusch Airport",
      "city": "Huttenbusch",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 10,
      "lat": 53.2866668701,
      "lon": 8.947221756,
      "tz": "Europe\/Berlin"
  },
  "EDXW": {
      "icao": "EDXW",
      "iata": "GWT",
      "name": "Westerland Sylt Airport",
      "city": "Westerland",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 51,
      "lat": 54.9132003784,
      "lon": 8.340470314,
      "tz": "Europe\/Berlin"
  },
  "EDXY": {
      "icao": "EDXY",
      "iata": "OHR",
      "name": "Wyk auf Fohr Airport",
      "city": "Wyk auf Fohr",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 26,
      "lat": 54.6844444275,
      "lon": 8.5283327103,
      "tz": "Europe\/Berlin"
  },
  "EDXZ": {
      "icao": "EDXZ",
      "iata": "",
      "name": "Kuhrstedt-Bederkesa Airport",
      "city": "Kuhrstedt",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 26,
      "lat": 53.5680541992,
      "lon": 8.7894439697,
      "tz": "Europe\/Berlin"
  },
  "EEEI": {
      "icao": "EEEI",
      "iata": "",
      "name": "Amari Air Base",
      "city": "Keila",
      "state": "Harjumaa",
      "country": "EE",
      "elevation": 65,
      "lat": 59.2602996826,
      "lon": 24.2084999084,
      "tz": "Europe\/Tallinn"
  },
  "EEJI": {
      "icao": "EEJI",
      "iata": "",
      "name": "Johvi Airfield",
      "city": "Johvi",
      "state": "Ida-Virumaa",
      "country": "EE",
      "elevation": 246,
      "lat": 59.3330001831,
      "lon": 27.3829994202,
      "tz": "Europe\/Tallinn"
  },
  "EEKA": {
      "icao": "EEKA",
      "iata": "KDL",
      "name": "Kardla Airport",
      "city": "Kardla",
      "state": "Hiiumaa",
      "country": "EE",
      "elevation": 18,
      "lat": 58.9907989502,
      "lon": 22.8306999207,
      "tz": "Europe\/Tallinn"
  },
  "EEKE": {
      "icao": "EEKE",
      "iata": "URE",
      "name": "Kuressaare Airport",
      "city": "Kuressaare",
      "state": "Saare",
      "country": "EE",
      "elevation": 14,
      "lat": 58.2299003601,
      "lon": 22.5095005035,
      "tz": "Europe\/Tallinn"
  },
  "EEKU": {
      "icao": "EEKU",
      "iata": "",
      "name": "Kihnu Airfield",
      "city": "Saarekula",
      "state": "Paernumaa",
      "country": "EE",
      "elevation": 10,
      "lat": 58.1483001709,
      "lon": 24.0025005341,
      "tz": "Europe\/Tallinn"
  },
  "EENA": {
      "icao": "EENA",
      "iata": "",
      "name": "Narva Estonia Airport",
      "city": "Narva",
      "state": "Ida-Virumaa",
      "country": "EE",
      "elevation": 92,
      "lat": 59.3885993958,
      "lon": 28.1135997772,
      "tz": "Europe\/Tallinn"
  },
  "EENI": {
      "icao": "EENI",
      "iata": "",
      "name": "Nurmsi Airport",
      "city": "Nurmsi",
      "state": "Jaervamaa",
      "country": "EE",
      "elevation": 233,
      "lat": 58.8650016785,
      "lon": 25.7299995422,
      "tz": "Europe\/Tallinn"
  },
  "EEPU": {
      "icao": "EEPU",
      "iata": "EPU",
      "name": "Parnu Airport",
      "city": "Parnu",
      "state": "Paernumaa",
      "country": "EE",
      "elevation": 47,
      "lat": 58.4189987183,
      "lon": 24.4727993011,
      "tz": "Europe\/Tallinn"
  },
  "EERA": {
      "icao": "EERA",
      "iata": "",
      "name": "Rapla Airfield",
      "city": "Rapla",
      "state": "Raplamaa",
      "country": "EE",
      "elevation": 220,
      "lat": 58.9864006042,
      "lon": 24.7243995667,
      "tz": "Europe\/Tallinn"
  },
  "EERI": {
      "icao": "EERI",
      "iata": "",
      "name": "Ridali Airfield",
      "city": "Suurkula",
      "state": "Polvamaa",
      "country": "EE",
      "elevation": 302,
      "lat": 57.9399986267,
      "lon": 26.9790000916,
      "tz": "Europe\/Tallinn"
  },
  "EERU": {
      "icao": "EERU",
      "iata": "",
      "name": "Ruhnu Airfield",
      "city": "Ringsu",
      "state": "Saare",
      "country": "EE",
      "elevation": 10,
      "lat": 57.7839012146,
      "lon": 23.2660999298,
      "tz": "Europe\/Tallinn"
  },
  "EETA": {
      "icao": "EETA",
      "iata": "",
      "name": "Tapa Airfield",
      "city": "Tapa",
      "state": "Laeaene-Virumaa",
      "country": "EE",
      "elevation": 331,
      "lat": 59.2407989502,
      "lon": 25.9622001648,
      "tz": "Europe\/Tallinn"
  },
  "EETN": {
      "icao": "EETN",
      "iata": "TLL",
      "name": "Tallinn Airport",
      "city": "Tallinn",
      "state": "Harjumaa",
      "country": "EE",
      "elevation": 131,
      "lat": 59.4132995605,
      "lon": 24.8327999115,
      "tz": "Europe\/Tallinn"
  },
  "EETU": {
      "icao": "EETU",
      "iata": "TAY",
      "name": "Tartu Airport",
      "city": "Tartu",
      "state": "Tartu",
      "country": "EE",
      "elevation": 219,
      "lat": 58.3074989319,
      "lon": 26.6903991699,
      "tz": "Europe\/Tallinn"
  },
  "EEVI": {
      "icao": "EEVI",
      "iata": "",
      "name": "Viljandi Airfield",
      "city": "Viljandi",
      "state": "Viljandimaa",
      "country": "EE",
      "elevation": 249,
      "lat": 58.3499984741,
      "lon": 25.4930992126,
      "tz": "Europe\/Tallinn"
  },
  "EFAA": {
      "icao": "EFAA",
      "iata": "",
      "name": "Aavahelukka Airport",
      "city": "",
      "state": "Lapland",
      "country": "FI",
      "elevation": 738,
      "lat": 67.6035995483,
      "lon": 23.9717006683,
      "tz": "Europe\/Helsinki"
  },
  "EFAH": {
      "icao": "EFAH",
      "iata": "",
      "name": "Ahmosuo Airport",
      "city": "Oulu",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 95,
      "lat": 64.8953018188,
      "lon": 25.752199173,
      "tz": "Europe\/Helsinki"
  },
  "EFAL": {
      "icao": "EFAL",
      "iata": "",
      "name": "Alavus Airport",
      "city": "Alavus",
      "state": "Southern-Ostrobothnia",
      "country": "FI",
      "elevation": 407,
      "lat": 62.5546989441,
      "lon": 23.573299408,
      "tz": "Europe\/Helsinki"
  },
  "EFET": {
      "icao": "EFET",
      "iata": "ENF",
      "name": "Enontekio Airport",
      "city": "Enontekio",
      "state": "Lapland",
      "country": "FI",
      "elevation": 1005,
      "lat": 68.3626022339,
      "lon": 23.4242992401,
      "tz": "Europe\/Helsinki"
  },
  "EFEU": {
      "icao": "EFEU",
      "iata": "",
      "name": "Eura Airport",
      "city": "",
      "state": "Satakunta",
      "country": "FI",
      "elevation": 259,
      "lat": 61.1161003113,
      "lon": 22.2014007568,
      "tz": "Europe\/Helsinki"
  },
  "EFFO": {
      "icao": "EFFO",
      "iata": "QVE",
      "name": "Forssa Airport",
      "city": "Forssa",
      "state": "Tavastia-Proper",
      "country": "FI",
      "elevation": 325,
      "lat": 60.8018989563,
      "lon": 23.6455993652,
      "tz": "Europe\/Helsinki"
  },
  "EFGE": {
      "icao": "EFGE",
      "iata": "",
      "name": "Genbole Airport",
      "city": "Genbole",
      "state": "Finland-Proper",
      "country": "FI",
      "elevation": 46,
      "lat": 60.0868988037,
      "lon": 22.521900177,
      "tz": "Europe\/Helsinki"
  },
  "EFHA": {
      "icao": "EFHA",
      "iata": "KEV",
      "name": "Halli Airport",
      "city": "Halli \/ Kuorevesi",
      "state": "Central-Finland",
      "country": "FI",
      "elevation": 479,
      "lat": 61.8559989929,
      "lon": 24.7866001129,
      "tz": "Europe\/Helsinki"
  },
  "EFHF": {
      "icao": "EFHF",
      "iata": "HEM",
      "name": "Helsinki Malmi Airport",
      "city": "Helsinki",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 57,
      "lat": 60.2546005249,
      "lon": 25.0428009033,
      "tz": "Europe\/Helsinki"
  },
  "EFHI": {
      "icao": "EFHI",
      "iata": "",
      "name": "Haapamaki Airport",
      "city": "",
      "state": "Central-Finland",
      "country": "FI",
      "elevation": 531,
      "lat": 62.2578010559,
      "lon": 24.3341999054,
      "tz": "Europe\/Helsinki"
  },
  "EFHJ": {
      "icao": "EFHJ",
      "iata": "",
      "name": "Haapajarvi Airport",
      "city": "Haapajarvi",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 351,
      "lat": 63.7122001648,
      "lon": 25.3950004578,
      "tz": "Europe\/Helsinki"
  },
  "EFHK": {
      "icao": "EFHK",
      "iata": "HEL",
      "name": "Helsinki Vantaa Airport",
      "city": "Helsinki",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 179,
      "lat": 60.317199707,
      "lon": 24.963300705,
      "tz": "Europe\/Helsinki"
  },
  "EFHL": {
      "icao": "EFHL",
      "iata": "",
      "name": "Hailuoto Airport",
      "city": "",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 7,
      "lat": 64.9669036865,
      "lon": 24.7043991089,
      "tz": "Europe\/Helsinki"
  },
  "EFHM": {
      "icao": "EFHM",
      "iata": "",
      "name": "Hameenkyro Airport",
      "city": "",
      "state": "Pirkanmaa",
      "country": "FI",
      "elevation": 449,
      "lat": 61.6897010803,
      "lon": 23.0736999512,
      "tz": "Europe\/Helsinki"
  },
  "EFHN": {
      "icao": "EFHN",
      "iata": "",
      "name": "Hanko Airport",
      "city": "",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 20,
      "lat": 59.8488998413,
      "lon": 23.0835990906,
      "tz": "Europe\/Helsinki"
  },
  "EFHP": {
      "icao": "EFHP",
      "iata": "",
      "name": "Haapavesi Airport",
      "city": "Haapavesi",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 315,
      "lat": 64.1130981445,
      "lon": 25.5041999817,
      "tz": "Europe\/Helsinki"
  },
  "EFHV": {
      "icao": "EFHV",
      "iata": "HYV",
      "name": "Hyvinkaa Airport",
      "city": "",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 430,
      "lat": 60.6543998718,
      "lon": 24.8810997009,
      "tz": "Europe\/Helsinki"
  },
  "EFII": {
      "icao": "EFII",
      "iata": "",
      "name": "Iisalmi Airport",
      "city": "Iisalmi",
      "state": "Northern-Savo",
      "country": "FI",
      "elevation": 384,
      "lat": 63.6319007874,
      "lon": 27.1222000122,
      "tz": "Europe\/Helsinki"
  },
  "EFIK": {
      "icao": "EFIK",
      "iata": "",
      "name": "Kiikala Airport",
      "city": "",
      "state": "Finland-Proper",
      "country": "FI",
      "elevation": 381,
      "lat": 60.4625015259,
      "lon": 23.6525001526,
      "tz": "Europe\/Helsinki"
  },
  "EFIM": {
      "icao": "EFIM",
      "iata": "",
      "name": "Immola Airport",
      "city": "",
      "state": "South-Karelia",
      "country": "FI",
      "elevation": 338,
      "lat": 61.2491989136,
      "lon": 28.9036998749,
      "tz": "Europe\/Helsinki"
  },
  "EFIT": {
      "icao": "EFIT",
      "iata": "KTQ",
      "name": "Kitee Airport",
      "city": "",
      "state": "North-Karelia",
      "country": "FI",
      "elevation": 364,
      "lat": 62.1660995483,
      "lon": 30.073600769,
      "tz": "Europe\/Helsinki"
  },
  "EFIV": {
      "icao": "EFIV",
      "iata": "IVL",
      "name": "Ivalo Airport",
      "city": "Ivalo",
      "state": "Lapland",
      "country": "FI",
      "elevation": 481,
      "lat": 68.6072998047,
      "lon": 27.4053001404,
      "tz": "Europe\/Helsinki"
  },
  "EFJM": {
      "icao": "EFJM",
      "iata": "",
      "name": "Jamijarvi Airport",
      "city": "Jamijarvi",
      "state": "Satakunta",
      "country": "FI",
      "elevation": 505,
      "lat": 61.7785987854,
      "lon": 22.7161006927,
      "tz": "Europe\/Helsinki"
  },
  "EFJO": {
      "icao": "EFJO",
      "iata": "JOE",
      "name": "Joensuu Airport",
      "city": "Joensuu \/ Liperi",
      "state": "North-Karelia",
      "country": "FI",
      "elevation": 398,
      "lat": 62.6628990173,
      "lon": 29.6075000763,
      "tz": "Europe\/Helsinki"
  },
  "EFJP": {
      "icao": "EFJP",
      "iata": "",
      "name": "Jakalapaa Airport",
      "city": "",
      "state": "Lapland",
      "country": "FI",
      "elevation": 0,
      "lat": 68.7114028931,
      "lon": 25.7527999878,
      "tz": "Europe\/Helsinki"
  },
  "EFJY": {
      "icao": "EFJY",
      "iata": "JYV",
      "name": "Jyvaskyla Airport",
      "city": "Jyvaskylan Maalaiskunta",
      "state": "Central-Finland",
      "country": "FI",
      "elevation": 459,
      "lat": 62.3995018005,
      "lon": 25.6783008575,
      "tz": "Europe\/Helsinki"
  },
  "EFKA": {
      "icao": "EFKA",
      "iata": "KAU",
      "name": "Kauhava Airport",
      "city": "Kauhava",
      "state": "Southern-Ostrobothnia",
      "country": "FI",
      "elevation": 151,
      "lat": 63.1271018982,
      "lon": 23.051399231,
      "tz": "Europe\/Helsinki"
  },
  "EFKE": {
      "icao": "EFKE",
      "iata": "KEM",
      "name": "Kemi-Tornio Airport",
      "city": "Kemi \/ Tornio",
      "state": "Lapland",
      "country": "FI",
      "elevation": 61,
      "lat": 65.7787017822,
      "lon": 24.5820999146,
      "tz": "Europe\/Helsinki"
  },
  "EFKG": {
      "icao": "EFKG",
      "iata": "",
      "name": "Kumlinge Airport",
      "city": "",
      "state": "",
      "country": "FI",
      "elevation": 7,
      "lat": 60.2468986511,
      "lon": 20.8047008514,
      "tz": "Europe\/Mariehamn"
  },
  "EFKH": {
      "icao": "EFKH",
      "iata": "",
      "name": "Kuhmo Airport",
      "city": "",
      "state": "Kainuu",
      "country": "FI",
      "elevation": 571,
      "lat": 64.1125030518,
      "lon": 29.4386005402,
      "tz": "Europe\/Helsinki"
  },
  "EFKI": {
      "icao": "EFKI",
      "iata": "KAJ",
      "name": "Kajaani Airport",
      "city": "Kajaani",
      "state": "Kainuu",
      "country": "FI",
      "elevation": 483,
      "lat": 64.2854995728,
      "lon": 27.6923999786,
      "tz": "Europe\/Helsinki"
  },
  "EFKJ": {
      "icao": "EFKJ",
      "iata": "KHJ",
      "name": "Kauhajoki Airport",
      "city": "",
      "state": "Southern-Ostrobothnia",
      "country": "FI",
      "elevation": 407,
      "lat": 62.4625015259,
      "lon": 22.3931007385,
      "tz": "Europe\/Helsinki"
  },
  "EFKK": {
      "icao": "EFKK",
      "iata": "KOK",
      "name": "Kruunupyy Airport",
      "city": "Kokkola \/ Kruunupyy",
      "state": "Ostrobothnia",
      "country": "FI",
      "elevation": 84,
      "lat": 63.7211990356,
      "lon": 23.1431007385,
      "tz": "Europe\/Helsinki"
  },
  "EFKM": {
      "icao": "EFKM",
      "iata": "",
      "name": "Kemijarvi Airport",
      "city": "",
      "state": "Lapland",
      "country": "FI",
      "elevation": 692,
      "lat": 66.7128982544,
      "lon": 27.1567993164,
      "tz": "Europe\/Helsinki"
  },
  "EFKN": {
      "icao": "EFKN",
      "iata": "",
      "name": "Kannus Airport",
      "city": "",
      "state": "Central-Ostrobothnia",
      "country": "FI",
      "elevation": 338,
      "lat": 63.9206008911,
      "lon": 24.0867004395,
      "tz": "Europe\/Helsinki"
  },
  "EFKO": {
      "icao": "EFKO",
      "iata": "",
      "name": "Kalajoki Airport",
      "city": "",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 43,
      "lat": 64.2285995483,
      "lon": 23.8264007568,
      "tz": "Europe\/Helsinki"
  },
  "EFKR": {
      "icao": "EFKR",
      "iata": "",
      "name": "Karsamaki Airport",
      "city": "Karsamaki",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 348,
      "lat": 63.989200592,
      "lon": 25.7436008453,
      "tz": "Europe\/Helsinki"
  },
  "EFKS": {
      "icao": "EFKS",
      "iata": "KAO",
      "name": "Kuusamo Airport",
      "city": "Kuusamo",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 866,
      "lat": 65.9876022339,
      "lon": 29.2394008636,
      "tz": "Europe\/Helsinki"
  },
  "EFKT": {
      "icao": "EFKT",
      "iata": "KTT",
      "name": "Kittila Airport",
      "city": "Kittila",
      "state": "Lapland",
      "country": "FI",
      "elevation": 644,
      "lat": 67.7009963989,
      "lon": 24.8467998505,
      "tz": "Europe\/Helsinki"
  },
  "EFKU": {
      "icao": "EFKU",
      "iata": "KUO",
      "name": "Kuopio Airport",
      "city": "Kuopio \/ Siilinjarvi",
      "state": "Northern-Savo",
      "country": "FI",
      "elevation": 323,
      "lat": 63.0070991516,
      "lon": 27.7978000641,
      "tz": "Europe\/Helsinki"
  },
  "EFKV": {
      "icao": "EFKV",
      "iata": "",
      "name": "Kivijarvi Airport",
      "city": "",
      "state": "Central-Finland",
      "country": "FI",
      "elevation": 502,
      "lat": 63.1253013611,
      "lon": 25.1242008209,
      "tz": "Europe\/Helsinki"
  },
  "EFKY": {
      "icao": "EFKY",
      "iata": "",
      "name": "Kymi Airport",
      "city": "Peippola",
      "state": "Central-Finland",
      "country": "FI",
      "elevation": 223,
      "lat": 60.5713996887,
      "lon": 26.8960990906,
      "tz": "Europe\/Helsinki"
  },
  "EFLA": {
      "icao": "EFLA",
      "iata": "QLF",
      "name": "Lahti Vesivehmaa Airport",
      "city": "",
      "state": "Paeijaenne-Tavastia",
      "country": "FI",
      "elevation": 502,
      "lat": 61.1441993713,
      "lon": 25.6935005188,
      "tz": "Europe\/Helsinki"
  },
  "EFLL": {
      "icao": "EFLL",
      "iata": "",
      "name": "Lapinlahti Airport",
      "city": "Lammake",
      "state": "Northern-Savo",
      "country": "FI",
      "elevation": 328,
      "lat": 63.3993988037,
      "lon": 27.4789009094,
      "tz": "Europe\/Helsinki"
  },
  "EFLN": {
      "icao": "EFLN",
      "iata": "",
      "name": "Lieksa Nurmes Airport",
      "city": "",
      "state": "North-Karelia",
      "country": "FI",
      "elevation": 374,
      "lat": 63.5119018555,
      "lon": 29.6291999817,
      "tz": "Europe\/Helsinki"
  },
  "EFLP": {
      "icao": "EFLP",
      "iata": "LPP",
      "name": "Lappeenranta Airport",
      "city": "Lappeenranta",
      "state": "South-Karelia",
      "country": "FI",
      "elevation": 349,
      "lat": 61.0446014404,
      "lon": 28.1443996429,
      "tz": "Europe\/Helsinki"
  },
  "EFMA": {
      "icao": "EFMA",
      "iata": "MHQ",
      "name": "Mariehamn Airport",
      "city": "",
      "state": "Mariehamns-stad",
      "country": "FI",
      "elevation": 17,
      "lat": 60.1222000122,
      "lon": 19.8981990814,
      "tz": "Europe\/Mariehamn"
  },
  "EFME": {
      "icao": "EFME",
      "iata": "",
      "name": "Menkijarvi Airport",
      "city": "",
      "state": "Southern-Ostrobothnia",
      "country": "FI",
      "elevation": 331,
      "lat": 62.9467010498,
      "lon": 23.5188999176,
      "tz": "Europe\/Helsinki"
  },
  "EFMI": {
      "icao": "EFMI",
      "iata": "MIK",
      "name": "Mikkeli Airport",
      "city": "Mikkeli",
      "state": "Southern-Savonia",
      "country": "FI",
      "elevation": 329,
      "lat": 61.6865997314,
      "lon": 27.2017993927,
      "tz": "Europe\/Helsinki"
  },
  "EFMN": {
      "icao": "EFMN",
      "iata": "",
      "name": "Mantsala Airport",
      "city": "Mantsala",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 135,
      "lat": 60.5724983215,
      "lon": 25.5088996887,
      "tz": "Europe\/Helsinki"
  },
  "EFMP": {
      "icao": "EFMP",
      "iata": "",
      "name": "Martiniiskonpalo Airport",
      "city": "",
      "state": "Lapland",
      "country": "FI",
      "elevation": 0,
      "lat": 68.6603012085,
      "lon": 25.7028999329,
      "tz": "Europe\/Helsinki"
  },
  "EFNS": {
      "icao": "EFNS",
      "iata": "",
      "name": "Savikko Airport",
      "city": "",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 233,
      "lat": 60.5200004578,
      "lon": 24.8316993713,
      "tz": "Europe\/Helsinki"
  },
  "EFNU": {
      "icao": "EFNU",
      "iata": "",
      "name": "Nummela Airport",
      "city": "",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 367,
      "lat": 60.3339004517,
      "lon": 24.2964000702,
      "tz": "Europe\/Helsinki"
  },
  "EFOP": {
      "icao": "EFOP",
      "iata": "",
      "name": "Oripaa Airport",
      "city": "Oripaa",
      "state": "Finland-Proper",
      "country": "FI",
      "elevation": 331,
      "lat": 60.8763999939,
      "lon": 22.7446994781,
      "tz": "Europe\/Helsinki"
  },
  "EFOU": {
      "icao": "EFOU",
      "iata": "OUL",
      "name": "Oulu Airport",
      "city": "Oulu \/ Oulunsalo",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 47,
      "lat": 64.9300994873,
      "lon": 25.3546009064,
      "tz": "Europe\/Helsinki"
  },
  "EFPA": {
      "icao": "EFPA",
      "iata": "",
      "name": "Pokka Airport",
      "city": "",
      "state": "Lapland",
      "country": "FI",
      "elevation": 853,
      "lat": 68.1502227783,
      "lon": 25.829372406,
      "tz": "Europe\/Helsinki"
  },
  "EFPH": {
      "icao": "EFPH",
      "iata": "",
      "name": "Pyhoselka Airport",
      "city": "",
      "state": "North-Karelia",
      "country": "FI",
      "elevation": 322,
      "lat": 62.4646987915,
      "lon": 30.0352993011,
      "tz": "Europe\/Helsinki"
  },
  "EFPI": {
      "icao": "EFPI",
      "iata": "",
      "name": "Piikajarvi Airport",
      "city": "",
      "state": "Satakunta",
      "country": "FI",
      "elevation": 148,
      "lat": 61.2456016541,
      "lon": 22.1933994293,
      "tz": "Europe\/Helsinki"
  },
  "EFPK": {
      "icao": "EFPK",
      "iata": "",
      "name": "Pieksamaki Airport",
      "city": "Pieksamaki",
      "state": "Southern-Savonia",
      "country": "FI",
      "elevation": 390,
      "lat": 62.2647018433,
      "lon": 27.0027999878,
      "tz": "Europe\/Helsinki"
  },
  "EFPN": {
      "icao": "EFPN",
      "iata": "",
      "name": "Punkaharju Airport",
      "city": "",
      "state": "Southern-Savonia",
      "country": "FI",
      "elevation": 253,
      "lat": 61.7289009094,
      "lon": 29.3936004639,
      "tz": "Europe\/Helsinki"
  },
  "EFPO": {
      "icao": "EFPO",
      "iata": "POR",
      "name": "Pori Airport",
      "city": "Pori",
      "state": "Satakunta",
      "country": "FI",
      "elevation": 44,
      "lat": 61.4617004395,
      "lon": 21.7999992371,
      "tz": "Europe\/Helsinki"
  },
  "EFPU": {
      "icao": "EFPU",
      "iata": "",
      "name": "Pudasjarvi Airport",
      "city": "",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 397,
      "lat": 65.4021987915,
      "lon": 26.9468994141,
      "tz": "Europe\/Helsinki"
  },
  "EFPY": {
      "icao": "EFPY",
      "iata": "",
      "name": "Pyhasalmi Airport",
      "city": "Pyhajarvi",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 528,
      "lat": 63.7318992615,
      "lon": 25.9263000488,
      "tz": "Europe\/Helsinki"
  },
  "EFRA": {
      "icao": "EFRA",
      "iata": "",
      "name": "Rautavaara Airport",
      "city": "",
      "state": "Northern-Savo",
      "country": "FI",
      "elevation": 476,
      "lat": 63.4241981506,
      "lon": 28.1242008209,
      "tz": "Europe\/Helsinki"
  },
  "EFRH": {
      "icao": "EFRH",
      "iata": "",
      "name": "Raahe Pattijoki Airport",
      "city": "",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 118,
      "lat": 64.6881027222,
      "lon": 24.6958007812,
      "tz": "Europe\/Helsinki"
  },
  "EFRN": {
      "icao": "EFRN",
      "iata": "",
      "name": "Rantasalmi Airport",
      "city": "",
      "state": "Southern-Savonia",
      "country": "FI",
      "elevation": 292,
      "lat": 62.0654983521,
      "lon": 28.3565006256,
      "tz": "Europe\/Helsinki"
  },
  "EFRO": {
      "icao": "EFRO",
      "iata": "RVN",
      "name": "Rovaniemi Airport",
      "city": "Rovaniemi",
      "state": "Lapland",
      "country": "FI",
      "elevation": 642,
      "lat": 66.5647964478,
      "lon": 25.8304004669,
      "tz": "Europe\/Helsinki"
  },
  "EFRU": {
      "icao": "EFRU",
      "iata": "",
      "name": "Ranua Airport",
      "city": "",
      "state": "Lapland",
      "country": "FI",
      "elevation": 541,
      "lat": 65.9730987549,
      "lon": 26.3652992249,
      "tz": "Europe\/Helsinki"
  },
  "EFRV": {
      "icao": "EFRV",
      "iata": "",
      "name": "Kiuruvesi Airport",
      "city": "",
      "state": "Northern-Savo",
      "country": "FI",
      "elevation": 515,
      "lat": 63.7056007385,
      "lon": 26.616399765,
      "tz": "Europe\/Helsinki"
  },
  "EFRY": {
      "icao": "EFRY",
      "iata": "",
      "name": "Rayskala Airport",
      "city": "",
      "state": "Tavastia-Proper",
      "country": "FI",
      "elevation": 407,
      "lat": 60.7447013855,
      "lon": 24.10779953,
      "tz": "Europe\/Helsinki"
  },
  "EFSA": {
      "icao": "EFSA",
      "iata": "SVL",
      "name": "Savonlinna Airport",
      "city": "Savonlinna",
      "state": "Southern-Savonia",
      "country": "FI",
      "elevation": 311,
      "lat": 61.9430999756,
      "lon": 28.9451007843,
      "tz": "Europe\/Helsinki"
  },
  "EFSE": {
      "icao": "EFSE",
      "iata": "",
      "name": "Selanpaa Airport",
      "city": "",
      "state": "Kymenlaakso",
      "country": "FI",
      "elevation": 417,
      "lat": 61.0624008179,
      "lon": 26.7989006042,
      "tz": "Europe\/Helsinki"
  },
  "EFSI": {
      "icao": "EFSI",
      "iata": "SJY",
      "name": "Seinajoki Airport",
      "city": "Seinajoki \/ Ilmajoki",
      "state": "Southern-Ostrobothnia",
      "country": "FI",
      "elevation": 302,
      "lat": 62.6921005249,
      "lon": 22.8323001862,
      "tz": "Europe\/Helsinki"
  },
  "EFSJ": {
      "icao": "EFSJ",
      "iata": "",
      "name": "Sonkajarvi Jyrkk Airport",
      "city": "",
      "state": "Northern-Savo",
      "country": "FI",
      "elevation": 600,
      "lat": 63.8194007874,
      "lon": 27.7693996429,
      "tz": "Europe\/Helsinki"
  },
  "EFSO": {
      "icao": "EFSO",
      "iata": "SOT",
      "name": "Sodankyla Airport",
      "city": "Sodankyla",
      "state": "Lapland",
      "country": "FI",
      "elevation": 602,
      "lat": 67.3949966431,
      "lon": 26.6191005707,
      "tz": "Europe\/Helsinki"
  },
  "EFSU": {
      "icao": "EFSU",
      "iata": "",
      "name": "Suomussalmi Airport",
      "city": "",
      "state": "Kainuu",
      "country": "FI",
      "elevation": 541,
      "lat": 64.8218994141,
      "lon": 28.7103004456,
      "tz": "Europe\/Helsinki"
  },
  "EFTO": {
      "icao": "EFTO",
      "iata": "",
      "name": "Torbacka Airport",
      "city": "",
      "state": "Uusimaa",
      "country": "FI",
      "elevation": 16,
      "lat": 60.0792007446,
      "lon": 24.1721992493,
      "tz": "Europe\/Helsinki"
  },
  "EFTP": {
      "icao": "EFTP",
      "iata": "TMP",
      "name": "Tampere-Pirkkala Airport",
      "city": "Tampere \/ Pirkkala",
      "state": "Pirkanmaa",
      "country": "FI",
      "elevation": 390,
      "lat": 61.414100647,
      "lon": 23.6044006348,
      "tz": "Europe\/Helsinki"
  },
  "EFTS": {
      "icao": "EFTS",
      "iata": "",
      "name": "Teisko Airport",
      "city": "Tampere",
      "state": "Pirkanmaa",
      "country": "FI",
      "elevation": 515,
      "lat": 61.7733001709,
      "lon": 24.0270004272,
      "tz": "Europe\/Helsinki"
  },
  "EFTU": {
      "icao": "EFTU",
      "iata": "TKU",
      "name": "Turku Airport",
      "city": "Turku",
      "state": "Finland-Proper",
      "country": "FI",
      "elevation": 161,
      "lat": 60.5140991211,
      "lon": 22.2628002167,
      "tz": "Europe\/Helsinki"
  },
  "EFUT": {
      "icao": "EFUT",
      "iata": "UTI",
      "name": "Utti Air Base",
      "city": "Utti \/ Valkeala",
      "state": "Kymenlaakso",
      "country": "FI",
      "elevation": 339,
      "lat": 60.8964004517,
      "lon": 26.9384002686,
      "tz": "Europe\/Helsinki"
  },
  "EFVA": {
      "icao": "EFVA",
      "iata": "VAA",
      "name": "Vaasa Airport",
      "city": "Vaasa",
      "state": "Ostrobothnia",
      "country": "FI",
      "elevation": 19,
      "lat": 63.0507011414,
      "lon": 21.7621994019,
      "tz": "Europe\/Helsinki"
  },
  "EFVI": {
      "icao": "EFVI",
      "iata": "",
      "name": "Viitasaari Airport",
      "city": "",
      "state": "Central-Finland",
      "country": "FI",
      "elevation": 361,
      "lat": 63.1225013733,
      "lon": 25.8160991669,
      "tz": "Europe\/Helsinki"
  },
  "EFVL": {
      "icao": "EFVL",
      "iata": "",
      "name": "Vaala Airport",
      "city": "",
      "state": "Kainuu",
      "country": "FI",
      "elevation": 443,
      "lat": 64.5018997192,
      "lon": 26.7600002289,
      "tz": "Europe\/Helsinki"
  },
  "EFVP": {
      "icao": "EFVP",
      "iata": "",
      "name": "Vampula Airport",
      "city": "",
      "state": "Satakunta",
      "country": "FI",
      "elevation": 295,
      "lat": 61.0396995544,
      "lon": 22.5916996002,
      "tz": "Europe\/Helsinki"
  },
  "EFVR": {
      "icao": "EFVR",
      "iata": "VRK",
      "name": "Varkaus Airport",
      "city": "Varkaus \/ Joroinen",
      "state": "Southern-Savonia",
      "country": "FI",
      "elevation": 286,
      "lat": 62.1711006165,
      "lon": 27.8686008453,
      "tz": "Europe\/Helsinki"
  },
  "EFVT": {
      "icao": "EFVT",
      "iata": "",
      "name": "Sulkaharju Airport",
      "city": "",
      "state": "Central-Ostrobothnia",
      "country": "FI",
      "elevation": 400,
      "lat": 63.3978004456,
      "lon": 24.0305995941,
      "tz": "Europe\/Helsinki"
  },
  "EFVU": {
      "icao": "EFVU",
      "iata": "",
      "name": "Vuotso Airport",
      "city": "Vuotso",
      "state": "Lapland",
      "country": "FI",
      "elevation": 0,
      "lat": 68.0871963501,
      "lon": 27.1238994598,
      "tz": "Europe\/Helsinki"
  },
  "EFWB": {
      "icao": "EFWB",
      "iata": "",
      "name": "Wredeby Airport",
      "city": "",
      "state": "Kymenlaakso",
      "country": "FI",
      "elevation": 82,
      "lat": 60.6636009216,
      "lon": 26.7458000183,
      "tz": "Europe\/Helsinki"
  },
  "EFYL": {
      "icao": "EFYL",
      "iata": "YLI",
      "name": "Ylivieska Airport",
      "city": "",
      "state": "Northern-Ostrobothnia",
      "country": "FI",
      "elevation": 252,
      "lat": 64.0605010986,
      "lon": 24.7159996033,
      "tz": "Europe\/Helsinki"
  },
  "EG02": {
      "icao": "EG02",
      "iata": "",
      "name": "Audley End Airfield",
      "city": "Safron Waldon",
      "state": "England",
      "country": "GB",
      "elevation": 283,
      "lat": 52.0091365357,
      "lon": 0.224275589,
      "tz": "Europe\/London"
  },
  "EG03": {
      "icao": "EG03",
      "iata": "",
      "name": "Badminton Airfield",
      "city": "Badminton",
      "state": "",
      "country": "GB",
      "elevation": 495,
      "lat": 51.5484166846,
      "lon": -2.3031806946,
      "tz": "Europe\/London"
  },
  "EG10": {
      "icao": "EG10",
      "iata": "",
      "name": "Breighton Airfield",
      "city": "Breighton",
      "state": "England",
      "country": "GB",
      "elevation": 163,
      "lat": 53.8023996399,
      "lon": -0.9159636498,
      "tz": "Europe\/London"
  },
  "EG11": {
      "icao": "EG11",
      "iata": "",
      "name": "Canterbury Airfield",
      "city": "Canterbury",
      "state": "England",
      "country": "GB",
      "elevation": 300,
      "lat": 51.2918749964,
      "lon": 0.999584198,
      "tz": "Europe\/London"
  },
  "EG12": {
      "icao": "EG12",
      "iata": "",
      "name": "Cromer\/Northrepps Aerodrome",
      "city": "Cromer",
      "state": "England",
      "country": "GB",
      "elevation": 181,
      "lat": 52.893807,
      "lon": 1.32106,
      "tz": "Europe\/London"
  },
  "EG18": {
      "icao": "EG18",
      "iata": "",
      "name": "Eshott Airfield",
      "city": "Eshott",
      "state": "England",
      "country": "GB",
      "elevation": 197,
      "lat": 55.2804841148,
      "lon": -1.7148971558,
      "tz": "Europe\/London"
  },
  "EG19": {
      "icao": "EG19",
      "iata": "",
      "name": "Fadmoor Airfield",
      "city": "Kirbymoorside",
      "state": "England",
      "country": "GB",
      "elevation": 0,
      "lat": 54.3080863149,
      "lon": -0.9831047058,
      "tz": "Europe\/London"
  },
  "EG34": {
      "icao": "EG34",
      "iata": "",
      "name": "Ledbury Airport",
      "city": "Ledbury",
      "state": "England",
      "country": "GB",
      "elevation": 250,
      "lat": 52.0018997192,
      "lon": -2.474159956,
      "tz": "Europe\/London"
  },
  "EG3L": {
      "icao": "EG3L",
      "iata": "",
      "name": "Millfield",
      "city": "",
      "state": "England",
      "country": "GB",
      "elevation": 0,
      "lat": 55.5916364278,
      "lon": -2.0872306824,
      "tz": "Europe\/London"
  },
  "EG64": {
      "icao": "EG64",
      "iata": "",
      "name": "RAF Rufforth",
      "city": "York",
      "state": "England",
      "country": "GB",
      "elevation": 65,
      "lat": 53.94829,
      "lon": -1.185575,
      "tz": "Europe\/London"
  },
  "EG73": {
      "icao": "EG73",
      "iata": "",
      "name": "Fearn Airport",
      "city": "Fearn",
      "state": "Scotland",
      "country": "GB",
      "elevation": 25,
      "lat": 57.7579994202,
      "lon": -3.9430000782,
      "tz": "Europe\/London"
  },
  "EG74": {
      "icao": "EG74",
      "iata": "LHB",
      "name": "Bruntingthorpe Airport",
      "city": "Bruntingthorpe",
      "state": "",
      "country": "GB",
      "elevation": 467,
      "lat": 52.492971862,
      "lon": -1.1250901222,
      "tz": "Europe\/London"
  },
  "EG78": {
      "icao": "EG78",
      "iata": "",
      "name": "Out Skerries",
      "city": "Out Skerries",
      "state": "Scotland",
      "country": "GB",
      "elevation": 20,
      "lat": 60.425583,
      "lon": -0.7466,
      "tz": "Europe\/London"
  },
  "EGAA": {
      "icao": "EGAA",
      "iata": "BFS",
      "name": "Belfast International Airport",
      "city": "Belfast",
      "state": "Northern-Ireland",
      "country": "GB",
      "elevation": 268,
      "lat": 54.6575012207,
      "lon": -6.2158298492,
      "tz": "Europe\/London"
  },
  "EGAB": {
      "icao": "EGAB",
      "iata": "ENK",
      "name": "St Angelo Airport",
      "city": "Enniskillen",
      "state": "Northern-Ireland",
      "country": "GB",
      "elevation": 155,
      "lat": 54.3988990784,
      "lon": -7.6516699791,
      "tz": "Europe\/London"
  },
  "EGAC": {
      "icao": "EGAC",
      "iata": "BHD",
      "name": "George Best Belfast City Airport",
      "city": "Belfast",
      "state": "Northern-Ireland",
      "country": "GB",
      "elevation": 15,
      "lat": 54.6180992126,
      "lon": -5.8724999428,
      "tz": "Europe\/London"
  },
  "EGAD": {
      "icao": "EGAD",
      "iata": "",
      "name": "Newtownards Airport",
      "city": "Newtownards",
      "state": "Northern-Ireland",
      "country": "GB",
      "elevation": 9,
      "lat": 54.5811004639,
      "lon": -5.6919398308,
      "tz": "Europe\/London"
  },
  "EGAE": {
      "icao": "EGAE",
      "iata": "LDY",
      "name": "City of Derry Airport",
      "city": "Derry",
      "state": "Northern-Ireland",
      "country": "GB",
      "elevation": 22,
      "lat": 55.0428009033,
      "lon": -7.1611099243,
      "tz": "Europe\/London"
  },
  "EGAH": {
      "icao": "EGAH",
      "iata": "",
      "name": "Halley Research Station",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 108,
      "lat": -75.5830555556,
      "lon": -26.5669444444,
      "tz": "Antarctica\/Rothera"
  },
  "EGAL": {
      "icao": "EGAL",
      "iata": "",
      "name": "Langford Lodge Airport",
      "city": "Belfast",
      "state": "",
      "country": "GB",
      "elevation": 0,
      "lat": 54.6231002808,
      "lon": -6.3000001907,
      "tz": "Europe\/London"
  },
  "EGAR": {
      "icao": "EGAR",
      "iata": "",
      "name": "Rothera Research Station",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 9,
      "lat": -67.5675,
      "lon": -68.1274027778,
      "tz": "Antarctica\/Rothera"
  },
  "EGBB": {
      "icao": "EGBB",
      "iata": "BHX",
      "name": "Birmingham International Airport",
      "city": "Birmingham",
      "state": "England",
      "country": "GB",
      "elevation": 327,
      "lat": 52.4538993835,
      "lon": -1.7480299473,
      "tz": "Europe\/London"
  },
  "EGBD": {
      "icao": "EGBD",
      "iata": "",
      "name": "Derby Airfield",
      "city": "Derby",
      "state": "England",
      "country": "GB",
      "elevation": 175,
      "lat": 52.8596992493,
      "lon": -1.6174999476,
      "tz": "Europe\/London"
  },
  "EGBE": {
      "icao": "EGBE",
      "iata": "CVT",
      "name": "Coventry Airport",
      "city": "Coventry",
      "state": "England",
      "country": "GB",
      "elevation": 267,
      "lat": 52.3697013855,
      "lon": -1.4797199965,
      "tz": "Europe\/London"
  },
  "EGBF": {
      "icao": "EGBF",
      "iata": "",
      "name": "Bedford Aerodrome",
      "city": "Bedford",
      "state": "England",
      "country": "GB",
      "elevation": 273,
      "lat": 52.2308485669,
      "lon": -0.4624557495,
      "tz": "Europe\/London"
  },
  "EGBG": {
      "icao": "EGBG",
      "iata": "",
      "name": "Leicester Airport",
      "city": "Leicester",
      "state": "England",
      "country": "GB",
      "elevation": 469,
      "lat": 52.60779953,
      "lon": -1.0319399834,
      "tz": "Europe\/London"
  },
  "EGBJ": {
      "icao": "EGBJ",
      "iata": "GLO",
      "name": "Gloucestershire Airport",
      "city": "Staverton",
      "state": "England",
      "country": "GB",
      "elevation": 101,
      "lat": 51.8941993713,
      "lon": -2.1672201157,
      "tz": "Europe\/London"
  },
  "EGBK": {
      "icao": "EGBK",
      "iata": "ORM",
      "name": "Sywell Aerodrome",
      "city": "Northampton",
      "state": "England",
      "country": "GB",
      "elevation": 429,
      "lat": 52.3053016663,
      "lon": -0.7930560112,
      "tz": "Europe\/London"
  },
  "EGBL": {
      "icao": "EGBL",
      "iata": "",
      "name": "Long Marston Airfield",
      "city": "Long Marston",
      "state": "England",
      "country": "GB",
      "elevation": 154,
      "lat": 52.1403007507,
      "lon": -1.7536100149,
      "tz": "Europe\/London"
  },
  "EGBM": {
      "icao": "EGBM",
      "iata": "",
      "name": "Tatenhill Airfield",
      "city": "Burton upon Trent",
      "state": "England",
      "country": "GB",
      "elevation": 439,
      "lat": 52.8147010803,
      "lon": -1.7611099482,
      "tz": "Europe\/London"
  },
  "EGBN": {
      "icao": "EGBN",
      "iata": "NQT",
      "name": "Nottingham Airport",
      "city": "Nottingham",
      "state": "England",
      "country": "GB",
      "elevation": 138,
      "lat": 52.9199981689,
      "lon": -1.0791699886,
      "tz": "Europe\/London"
  },
  "EGBO": {
      "icao": "EGBO",
      "iata": "",
      "name": "Wolverhampton Halfpenny Green Airport",
      "city": "Wolverhampton",
      "state": "England",
      "country": "GB",
      "elevation": 283,
      "lat": 52.5175018311,
      "lon": -2.2594399452,
      "tz": "Europe\/London"
  },
  "EGBP": {
      "icao": "EGBP",
      "iata": "GBA",
      "name": "Kemble Airport",
      "city": "Kemble",
      "state": "England",
      "country": "GB",
      "elevation": 433,
      "lat": 51.6680984497,
      "lon": -2.0569400787,
      "tz": "Europe\/London"
  },
  "EGBR": {
      "icao": "EGBR",
      "iata": "",
      "name": "Breighton Airfield",
      "city": "Breighton",
      "state": "England",
      "country": "GB",
      "elevation": 163,
      "lat": 53.8023996399,
      "lon": -0.9159636498,
      "tz": "Europe\/London"
  },
  "EGBS": {
      "icao": "EGBS",
      "iata": "",
      "name": "Shobdon Aerodrome",
      "city": "Shobdon",
      "state": "England",
      "country": "GB",
      "elevation": 318,
      "lat": 52.2416992188,
      "lon": -2.8811099529,
      "tz": "Europe\/London"
  },
  "EGBT": {
      "icao": "EGBT",
      "iata": "",
      "name": "Turweston Airport",
      "city": "Turweston",
      "state": "",
      "country": "GB",
      "elevation": 448,
      "lat": 52.0407981873,
      "lon": -1.0955599546,
      "tz": "Europe\/London"
  },
  "EGBW": {
      "icao": "EGBW",
      "iata": "",
      "name": "Wellesbourne Mountford Airport",
      "city": "Stratford-on-Avon",
      "state": "England",
      "country": "GB",
      "elevation": 159,
      "lat": 52.192199707,
      "lon": -1.6144399643,
      "tz": "Europe\/London"
  },
  "EGCB": {
      "icao": "EGCB",
      "iata": "",
      "name": "City Airport Manchester",
      "city": "Manchester \/ Salford",
      "state": "England",
      "country": "GB",
      "elevation": 73,
      "lat": 53.471698761,
      "lon": -2.3897199631,
      "tz": "Europe\/London"
  },
  "EGCC": {
      "icao": "EGCC",
      "iata": "MAN",
      "name": "Manchester Airport",
      "city": "Manchester",
      "state": "England",
      "country": "GB",
      "elevation": 257,
      "lat": 53.3536987305,
      "lon": -2.2749500275,
      "tz": "Europe\/London"
  },
  "EGCD": {
      "icao": "EGCD",
      "iata": "",
      "name": "Manchester Woodford Airport",
      "city": "Manchester",
      "state": "England",
      "country": "GB",
      "elevation": 295,
      "lat": 53.3381004333,
      "lon": -2.1488900185,
      "tz": "Europe\/London"
  },
  "EGCF": {
      "icao": "EGCF",
      "iata": "",
      "name": "Sandtoft Airfield",
      "city": "Belton",
      "state": "",
      "country": "GB",
      "elevation": 11,
      "lat": 53.5597000122,
      "lon": -0.8583329916,
      "tz": "Europe\/London"
  },
  "EGCJ": {
      "icao": "EGCJ",
      "iata": "",
      "name": "Sherburn-In-Elmet Airfield",
      "city": "Selby",
      "state": "England",
      "country": "GB",
      "elevation": 26,
      "lat": 53.7844009399,
      "lon": -1.2172199488,
      "tz": "Europe\/London"
  },
  "EGCK": {
      "icao": "EGCK",
      "iata": "",
      "name": "Caernarfon Airport",
      "city": "Caernarfon",
      "state": "Wales",
      "country": "GB",
      "elevation": 1,
      "lat": 53.1041984558,
      "lon": -4.340280056,
      "tz": "Europe\/London"
  },
  "EGCL": {
      "icao": "EGCL",
      "iata": "",
      "name": "Fenland Airfield",
      "city": "Spalding",
      "state": "",
      "country": "GB",
      "elevation": 8,
      "lat": 52.739200592,
      "lon": -0.0297219995,
      "tz": "Europe\/London"
  },
  "EGCN": {
      "icao": "EGCN",
      "iata": "DSA",
      "name": "Robin Hood Doncaster Sheffield Airport",
      "city": "Doncaster",
      "state": "England",
      "country": "GB",
      "elevation": 55,
      "lat": 53.4805378105,
      "lon": -1.0106563568,
      "tz": "Europe\/London"
  },
  "EGCO": {
      "icao": "EGCO",
      "iata": "",
      "name": "Birkdale Sands Airport",
      "city": "Southport",
      "state": "England",
      "country": "GB",
      "elevation": 3,
      "lat": 53.6453018188,
      "lon": -3.0286099911,
      "tz": "Europe\/London"
  },
  "EGCP": {
      "icao": "EGCP",
      "iata": "",
      "name": "Thorne Airport",
      "city": "Thorne",
      "state": "England",
      "country": "GB",
      "elevation": 16,
      "lat": 53.6222000122,
      "lon": -0.9280560017,
      "tz": "Europe\/London"
  },
  "EGCR": {
      "icao": "EGCR",
      "iata": "",
      "name": "Ashcroft Airfield",
      "city": "Winsford",
      "state": "",
      "country": "GB",
      "elevation": 150,
      "lat": 53.1644238046,
      "lon": -2.5721740723,
      "tz": "Europe\/London"
  },
  "EGCS": {
      "icao": "EGCS",
      "iata": "",
      "name": "Sturgate Airfield",
      "city": "Lincoln",
      "state": "",
      "country": "GB",
      "elevation": 58,
      "lat": 53.3810997009,
      "lon": -0.6852779984,
      "tz": "Europe\/London"
  },
  "EGCT": {
      "icao": "EGCT",
      "iata": "",
      "name": "Tilstock Airfield",
      "city": "Whitchurch",
      "state": "England",
      "country": "GB",
      "elevation": 301,
      "lat": 52.9308013916,
      "lon": -2.6463899612,
      "tz": "Europe\/London"
  },
  "EGCV": {
      "icao": "EGCV",
      "iata": "",
      "name": "Sleap Airport",
      "city": "Shrewsbury",
      "state": "England",
      "country": "GB",
      "elevation": 275,
      "lat": 52.8339004517,
      "lon": -2.7716701031,
      "tz": "Europe\/London"
  },
  "EGCW": {
      "icao": "EGCW",
      "iata": "",
      "name": "Welshpool Airport",
      "city": "Welshpool",
      "state": "Wales",
      "country": "GB",
      "elevation": 233,
      "lat": 52.6286010742,
      "lon": -3.1533300877,
      "tz": "Europe\/London"
  },
  "EGDC": {
      "icao": "EGDC",
      "iata": "",
      "name": "Royal Marines Base Chivenor Airport",
      "city": "Chivenor",
      "state": "England",
      "country": "GB",
      "elevation": 27,
      "lat": 51.0872001648,
      "lon": -4.1503400803,
      "tz": "Europe\/London"
  },
  "EGDD": {
      "icao": "EGDD",
      "iata": "",
      "name": "Bicester Airfield",
      "city": "Bicester",
      "state": "",
      "country": "GB",
      "elevation": 266,
      "lat": 51.9166984558,
      "lon": -1.1319400072,
      "tz": "Europe\/London"
  },
  "EGDJ": {
      "icao": "EGDJ",
      "iata": "UPV",
      "name": "Upavon Aerodrome",
      "city": "Upavon",
      "state": "England",
      "country": "GB",
      "elevation": 574,
      "lat": 51.2862014771,
      "lon": -1.7820199728,
      "tz": "Europe\/London"
  },
  "EGDL": {
      "icao": "EGDL",
      "iata": "LYE",
      "name": "RAF Lyneham",
      "city": "Lyneham",
      "state": "England",
      "country": "GB",
      "elevation": 513,
      "lat": 51.5051002502,
      "lon": -1.9934300184,
      "tz": "Europe\/London"
  },
  "EGDM": {
      "icao": "EGDM",
      "iata": "",
      "name": "MoD Boscombe Down Airport",
      "city": "Amesbury",
      "state": "England",
      "country": "GB",
      "elevation": 407,
      "lat": 51.1521987915,
      "lon": -1.747410059,
      "tz": "Europe\/London"
  },
  "EGDN": {
      "icao": "EGDN",
      "iata": "",
      "name": "Netheravon Airfield",
      "city": "Netheravon",
      "state": "",
      "country": "GB",
      "elevation": 456,
      "lat": 51.2472000122,
      "lon": -1.7542500496,
      "tz": "Europe\/London"
  },
  "EGDO": {
      "icao": "EGDO",
      "iata": "",
      "name": "Predannack Airport",
      "city": "Predannack Wollas",
      "state": "England",
      "country": "GB",
      "elevation": 295,
      "lat": 50.0012016296,
      "lon": -5.2308301926,
      "tz": "Europe\/London"
  },
  "EGDR": {
      "icao": "EGDR",
      "iata": "",
      "name": "RNAS Culdrose",
      "city": "Helston",
      "state": "England",
      "country": "GB",
      "elevation": 267,
      "lat": 50.086101532,
      "lon": -5.255710125,
      "tz": "Europe\/London"
  },
  "EGDT": {
      "icao": "EGDT",
      "iata": "",
      "name": "Wroughton Airfield",
      "city": "Wroughton",
      "state": "England",
      "country": "GB",
      "elevation": 656,
      "lat": 51.5060997009,
      "lon": -1.8019399643,
      "tz": "Europe\/London"
  },
  "EGDV": {
      "icao": "EGDV",
      "iata": "",
      "name": "Hullavington Airfield",
      "city": "Hullavington",
      "state": "",
      "country": "GB",
      "elevation": 327,
      "lat": 51.526732,
      "lon": -2.141555,
      "tz": "Europe\/London"
  },
  "EGDW": {
      "icao": "EGDW",
      "iata": "",
      "name": "Merryfield Airfield",
      "city": "Ilminster",
      "state": "England",
      "country": "GB",
      "elevation": 151,
      "lat": 50.9625015259,
      "lon": -2.935559988,
      "tz": "Europe\/London"
  },
  "EGDX": {
      "icao": "EGDX",
      "iata": "DGX",
      "name": "MOD St. Athan",
      "city": "St. Athan",
      "state": "Wales",
      "country": "GB",
      "elevation": 163,
      "lat": 51.404800415,
      "lon": -3.4357500076,
      "tz": "Europe\/London"
  },
  "EGDY": {
      "icao": "EGDY",
      "iata": "YEO",
      "name": "RNAS Yeovilton",
      "city": "Yeovil",
      "state": "England",
      "country": "GB",
      "elevation": 75,
      "lat": 51.0093994141,
      "lon": -2.6388199329,
      "tz": "Europe\/London"
  },
  "EGEC": {
      "icao": "EGEC",
      "iata": "CAL",
      "name": "Campbeltown Airport",
      "city": "Campbeltown",
      "state": "Scotland",
      "country": "GB",
      "elevation": 42,
      "lat": 55.4371986389,
      "lon": -5.6863899231,
      "tz": "Europe\/London"
  },
  "EGED": {
      "icao": "EGED",
      "iata": "EOI",
      "name": "Eday Airport",
      "city": "Eday",
      "state": "Scotland",
      "country": "GB",
      "elevation": 10,
      "lat": 59.1906013489,
      "lon": -2.7722198963,
      "tz": "Europe\/London"
  },
  "EGEF": {
      "icao": "EGEF",
      "iata": "FIE",
      "name": "Fair Isle Airport",
      "city": "Fair Isle",
      "state": "Scotland",
      "country": "GB",
      "elevation": 223,
      "lat": 59.5358009338,
      "lon": -1.6280599833,
      "tz": "Europe\/London"
  },
  "EGEH": {
      "icao": "EGEH",
      "iata": "WHS",
      "name": "Whalsay Airport",
      "city": "Whalsay",
      "state": "Scotland",
      "country": "GB",
      "elevation": 100,
      "lat": 60.3774986267,
      "lon": -0.925556004,
      "tz": "Europe\/London"
  },
  "EGEL": {
      "icao": "EGEL",
      "iata": "COL",
      "name": "Coll Airport",
      "city": "Coll Island",
      "state": "Scotland",
      "country": "GB",
      "elevation": 21,
      "lat": 56.6018981934,
      "lon": -6.6177802086,
      "tz": "Europe\/London"
  },
  "EGEN": {
      "icao": "EGEN",
      "iata": "NRL",
      "name": "North Ronaldsay Airport",
      "city": "North Ronaldsay",
      "state": "Scotland",
      "country": "GB",
      "elevation": 40,
      "lat": 59.3675003052,
      "lon": -2.4344398975,
      "tz": "Europe\/London"
  },
  "EGEO": {
      "icao": "EGEO",
      "iata": "OBN",
      "name": "Oban Airport",
      "city": "North Connel",
      "state": "Scotland",
      "country": "GB",
      "elevation": 20,
      "lat": 56.4635009766,
      "lon": -5.3996701241,
      "tz": "Europe\/London"
  },
  "EGEP": {
      "icao": "EGEP",
      "iata": "PPW",
      "name": "Papa Westray Airport",
      "city": "Papa Westray",
      "state": "Scotland",
      "country": "GB",
      "elevation": 91,
      "lat": 59.3516998291,
      "lon": -2.9002799988,
      "tz": "Europe\/London"
  },
  "EGER": {
      "icao": "EGER",
      "iata": "SOY",
      "name": "Stronsay Airport",
      "city": "Stronsay",
      "state": "Scotland",
      "country": "GB",
      "elevation": 39,
      "lat": 59.1553001404,
      "lon": -2.6413900852,
      "tz": "Europe\/London"
  },
  "EGES": {
      "icao": "EGES",
      "iata": "NDY",
      "name": "Sanday Airport",
      "city": "Sanday",
      "state": "Scotland",
      "country": "GB",
      "elevation": 68,
      "lat": 59.2503013611,
      "lon": -2.5766699314,
      "tz": "Europe\/London"
  },
  "EGET": {
      "icao": "EGET",
      "iata": "LWK",
      "name": "Lerwick \/ Tingwall Airport",
      "city": "Lerwick",
      "state": "Scotland",
      "country": "GB",
      "elevation": 43,
      "lat": 60.192199707,
      "lon": -1.2436100245,
      "tz": "Europe\/London"
  },
  "EGEW": {
      "icao": "EGEW",
      "iata": "WRY",
      "name": "Westray Airport",
      "city": "Westray",
      "state": "Scotland",
      "country": "GB",
      "elevation": 29,
      "lat": 59.3502998352,
      "lon": -2.9500000477,
      "tz": "Europe\/London"
  },
  "EGEY": {
      "icao": "EGEY",
      "iata": "CSA",
      "name": "Colonsay Airstrip",
      "city": "Colonsay",
      "state": "Scotland",
      "country": "GB",
      "elevation": 44,
      "lat": 56.0574989319,
      "lon": -6.243060112,
      "tz": "Europe\/London"
  },
  "EGFA": {
      "icao": "EGFA",
      "iata": "",
      "name": "Aberporth Airport",
      "city": "Aberporth",
      "state": "Wales",
      "country": "GB",
      "elevation": 428,
      "lat": 52.1152992249,
      "lon": -4.5569400787,
      "tz": "Europe\/London"
  },
  "EGFE": {
      "icao": "EGFE",
      "iata": "HAW",
      "name": "Haverfordwest Airport",
      "city": "Haverfordwest",
      "state": "Wales",
      "country": "GB",
      "elevation": 159,
      "lat": 51.8330993652,
      "lon": -4.9611101151,
      "tz": "Europe\/London"
  },
  "EGFF": {
      "icao": "EGFF",
      "iata": "CWL",
      "name": "Cardiff International Airport",
      "city": "Cardiff",
      "state": "Wales",
      "country": "GB",
      "elevation": 220,
      "lat": 51.3967018127,
      "lon": -3.3433299065,
      "tz": "Europe\/London"
  },
  "EGFH": {
      "icao": "EGFH",
      "iata": "SWS",
      "name": "Swansea Airport",
      "city": "Swansea",
      "state": "Wales",
      "country": "GB",
      "elevation": 299,
      "lat": 51.6053009033,
      "lon": -4.0678300858,
      "tz": "Europe\/London"
  },
  "EGFP": {
      "icao": "EGFP",
      "iata": "",
      "name": "Pembrey Airport",
      "city": "Pembrey",
      "state": "Wales",
      "country": "GB",
      "elevation": 15,
      "lat": 51.7139015198,
      "lon": -4.3122200966,
      "tz": "Europe\/London"
  },
  "EGGD": {
      "icao": "EGGD",
      "iata": "BRS",
      "name": "Bristol International Airport",
      "city": "Bristol",
      "state": "England",
      "country": "GB",
      "elevation": 622,
      "lat": 51.3827018738,
      "lon": -2.7190899849,
      "tz": "Europe\/London"
  },
  "EGGP": {
      "icao": "EGGP",
      "iata": "LPL",
      "name": "Liverpool John Lennon Airport",
      "city": "Liverpool",
      "state": "England",
      "country": "GB",
      "elevation": 80,
      "lat": 53.3335990906,
      "lon": -2.8497200012,
      "tz": "Europe\/London"
  },
  "EGGW": {
      "icao": "EGGW",
      "iata": "LTN",
      "name": "London Luton Airport",
      "city": "London",
      "state": "England",
      "country": "GB",
      "elevation": 526,
      "lat": 51.8746986389,
      "lon": -0.3683330119,
      "tz": "Europe\/London"
  },
  "EGHA": {
      "icao": "EGHA",
      "iata": "",
      "name": "Compton Abbas Aerodrome",
      "city": "Shaftesbury",
      "state": "England",
      "country": "GB",
      "elevation": 811,
      "lat": 50.9672012329,
      "lon": -2.1536099911,
      "tz": "Europe\/London"
  },
  "EGHB": {
      "icao": "EGHB",
      "iata": "",
      "name": "Maypole Airport",
      "city": "Maypole",
      "state": "England",
      "country": "GB",
      "elevation": 110,
      "lat": 51.3389015198,
      "lon": 1.1530599594,
      "tz": "Europe\/London"
  },
  "EGHC": {
      "icao": "EGHC",
      "iata": "LEQ",
      "name": "Land's End Airport",
      "city": "Land's End",
      "state": "England",
      "country": "GB",
      "elevation": 401,
      "lat": 50.1027984619,
      "lon": -5.6705598831,
      "tz": "Europe\/London"
  },
  "EGHD": {
      "icao": "EGHD",
      "iata": "PLH",
      "name": "Plymouth City Airport",
      "city": "Plymouth",
      "state": "England",
      "country": "GB",
      "elevation": 476,
      "lat": 50.4227981567,
      "lon": -4.1058301926,
      "tz": "Europe\/London"
  },
  "EGHE": {
      "icao": "EGHE",
      "iata": "ISC",
      "name": "St. Mary's Airport",
      "city": "St. Mary's",
      "state": "England",
      "country": "GB",
      "elevation": 116,
      "lat": 49.9132995605,
      "lon": -6.2916698456,
      "tz": "Europe\/London"
  },
  "EGHF": {
      "icao": "EGHF",
      "iata": "",
      "name": "RNAS Lee-On-Solent",
      "city": "Lee-on-Solent",
      "state": "England",
      "country": "GB",
      "elevation": 32,
      "lat": 50.814201355,
      "lon": -1.20333004,
      "tz": "Europe\/London"
  },
  "EGHG": {
      "icao": "EGHG",
      "iata": "",
      "name": "Yeovil\/Westland Aerodrome",
      "city": "Yeovil",
      "state": "England",
      "country": "GB",
      "elevation": 202,
      "lat": 50.9399986267,
      "lon": -2.6586101055,
      "tz": "Europe\/London"
  },
  "EGHH": {
      "icao": "EGHH",
      "iata": "BOH",
      "name": "Bournemouth Airport",
      "city": "Bournemouth",
      "state": "England",
      "country": "GB",
      "elevation": 38,
      "lat": 50.7799987793,
      "lon": -1.8424999714,
      "tz": "Europe\/London"
  },
  "EGHI": {
      "icao": "EGHI",
      "iata": "SOU",
      "name": "Southampton Airport",
      "city": "Southampton",
      "state": "England",
      "country": "GB",
      "elevation": 44,
      "lat": 50.9502983093,
      "lon": -1.3567999601,
      "tz": "Europe\/London"
  },
  "EGHJ": {
      "icao": "EGHJ",
      "iata": "BBP",
      "name": "Bembridge Airport",
      "city": "Bembridge",
      "state": "England",
      "country": "GB",
      "elevation": 53,
      "lat": 50.6781005859,
      "lon": -1.1094399691,
      "tz": "Europe\/London"
  },
  "EGHL": {
      "icao": "EGHL",
      "iata": "QLA",
      "name": "Lasham Airport",
      "city": "Lasham",
      "state": "England",
      "country": "GB",
      "elevation": 618,
      "lat": 51.1871986389,
      "lon": -1.0334999561,
      "tz": "Europe\/London"
  },
  "EGHN": {
      "icao": "EGHN",
      "iata": "",
      "name": "Isle of Wight \/ Sandown Airport",
      "city": "Sandown",
      "state": "England",
      "country": "GB",
      "elevation": 55,
      "lat": 50.6530990601,
      "lon": -1.1822199821,
      "tz": "Europe\/London"
  },
  "EGHO": {
      "icao": "EGHO",
      "iata": "",
      "name": "Thruxton Aerodrome",
      "city": "Andover",
      "state": "England",
      "country": "GB",
      "elevation": 319,
      "lat": 51.2106018066,
      "lon": -1.6000000238,
      "tz": "Europe\/London"
  },
  "EGHP": {
      "icao": "EGHP",
      "iata": "",
      "name": "Popham Airport",
      "city": "Basingstoke",
      "state": "England",
      "country": "GB",
      "elevation": 550,
      "lat": 51.193901062,
      "lon": -1.2347199917,
      "tz": "Europe\/London"
  },
  "EGHQ": {
      "icao": "EGHQ",
      "iata": "NQY",
      "name": "Newquay Cornwall Airport",
      "city": "Newquay",
      "state": "England",
      "country": "GB",
      "elevation": 390,
      "lat": 50.4406013489,
      "lon": -4.9954099655,
      "tz": "Europe\/London"
  },
  "EGHR": {
      "icao": "EGHR",
      "iata": "QUG",
      "name": "Chichester\/Goodwood Airport",
      "city": "Chichester",
      "state": "England",
      "country": "GB",
      "elevation": 98,
      "lat": 50.8594017029,
      "lon": -0.7591670156,
      "tz": "Europe\/London"
  },
  "EGHS": {
      "icao": "EGHS",
      "iata": "",
      "name": "Henstridge Airfield",
      "city": "Henstridge",
      "state": "England",
      "country": "GB",
      "elevation": 184,
      "lat": 50.9850006104,
      "lon": -2.3572199345,
      "tz": "Europe\/London"
  },
  "EGHU": {
      "icao": "EGHU",
      "iata": "",
      "name": "Eaglescott Airfield",
      "city": "Burrington",
      "state": "England",
      "country": "GB",
      "elevation": 655,
      "lat": 50.9286003113,
      "lon": -3.9894399643,
      "tz": "Europe\/London"
  },
  "EGHY": {
      "icao": "EGHY",
      "iata": "",
      "name": "Truro Airport",
      "city": "Truro",
      "state": "England",
      "country": "GB",
      "elevation": 127,
      "lat": 50.2784004211,
      "lon": -5.1422901154,
      "tz": "Europe\/London"
  },
  "EGJA": {
      "icao": "EGJA",
      "iata": "ACI",
      "name": "Alderney Airport",
      "city": "Saint Anne",
      "state": "Alderney",
      "country": "GG",
      "elevation": 290,
      "lat": 49.7061004639,
      "lon": -2.2147200108,
      "tz": "Europe\/Guernsey"
  },
  "EGJB": {
      "icao": "EGJB",
      "iata": "GCI",
      "name": "Guernsey Airport",
      "city": "Saint Peter Port",
      "state": "Forest",
      "country": "GG",
      "elevation": 336,
      "lat": 49.4350013733,
      "lon": -2.6019699574,
      "tz": "Europe\/Guernsey"
  },
  "EGJJ": {
      "icao": "EGJJ",
      "iata": "JER",
      "name": "Jersey Airport",
      "city": "Saint Helier",
      "state": "St-Peter",
      "country": "JE",
      "elevation": 277,
      "lat": 49.207901001,
      "lon": -2.1955099106,
      "tz": "Europe\/Jersey"
  },
  "EGKA": {
      "icao": "EGKA",
      "iata": "ESH",
      "name": "Shoreham Airport",
      "city": "Brighton",
      "state": "England",
      "country": "GB",
      "elevation": 7,
      "lat": 50.8356018066,
      "lon": -0.2972219884,
      "tz": "Europe\/London"
  },
  "EGKB": {
      "icao": "EGKB",
      "iata": "BQH",
      "name": "London Biggin Hill Airport",
      "city": "London",
      "state": "England",
      "country": "GB",
      "elevation": 598,
      "lat": 51.3307991028,
      "lon": 0.0324999988,
      "tz": "Europe\/London"
  },
  "EGKE": {
      "icao": "EGKE",
      "iata": "",
      "name": "Challock Airfield",
      "city": "Challock",
      "state": "England",
      "country": "GB",
      "elevation": 600,
      "lat": 51.2083015442,
      "lon": 0.8291670084,
      "tz": "Europe\/London"
  },
  "EGKH": {
      "icao": "EGKH",
      "iata": "",
      "name": "Lashenden (Headcorn) Airfield",
      "city": "Maidstone",
      "state": "England",
      "country": "GB",
      "elevation": 72,
      "lat": 51.1568984985,
      "lon": 0.6416670084,
      "tz": "Europe\/London"
  },
  "EGKK": {
      "icao": "EGKK",
      "iata": "LGW",
      "name": "London Gatwick Airport",
      "city": "London",
      "state": "England",
      "country": "GB",
      "elevation": 202,
      "lat": 51.1481018066,
      "lon": -0.1902779937,
      "tz": "Europe\/London"
  },
  "EGKL": {
      "icao": "EGKL",
      "iata": "",
      "name": "Deanland Lewes Airport",
      "city": "Lewes",
      "state": "England",
      "country": "GB",
      "elevation": 69,
      "lat": 50.8788986206,
      "lon": 0.156388998,
      "tz": "Europe\/London"
  },
  "EGKR": {
      "icao": "EGKR",
      "iata": "KRH",
      "name": "Redhill Aerodrome",
      "city": "Redhill",
      "state": "England",
      "country": "GB",
      "elevation": 222,
      "lat": 51.2136001587,
      "lon": -0.1386110038,
      "tz": "Europe\/London"
  },
  "EGLA": {
      "icao": "EGLA",
      "iata": "",
      "name": "Bodmin Airfield",
      "city": "Bodmin",
      "state": "England",
      "country": "GB",
      "elevation": 650,
      "lat": 50.4996986389,
      "lon": -4.6658301353,
      "tz": "Europe\/London"
  },
  "EGLC": {
      "icao": "EGLC",
      "iata": "LCY",
      "name": "London City Airport",
      "city": "London",
      "state": "England",
      "country": "GB",
      "elevation": 19,
      "lat": 51.5052986145,
      "lon": 0.0552779995,
      "tz": "Europe\/London"
  },
  "EGLD": {
      "icao": "EGLD",
      "iata": "",
      "name": "Denham Aerodrome",
      "city": "Gerrards Cross",
      "state": "",
      "country": "GB",
      "elevation": 24,
      "lat": 51.5882987976,
      "lon": -0.5130559802,
      "tz": "Europe\/London"
  },
  "EGLF": {
      "icao": "EGLF",
      "iata": "FAB",
      "name": "Farnborough Airport",
      "city": "Farnborough",
      "state": "England",
      "country": "GB",
      "elevation": 238,
      "lat": 51.2757987976,
      "lon": -0.7763329744,
      "tz": "Europe\/London"
  },
  "EGLG": {
      "icao": "EGLG",
      "iata": "",
      "name": "Panshanger Aerodrome",
      "city": "Hertford",
      "state": "",
      "country": "GB",
      "elevation": 249,
      "lat": 51.8025016785,
      "lon": -0.1580560058,
      "tz": "Europe\/London"
  },
  "EGLJ": {
      "icao": "EGLJ",
      "iata": "",
      "name": "Chalgrove Airport",
      "city": "Chalgrove",
      "state": "",
      "country": "GB",
      "elevation": 240,
      "lat": 51.6761016846,
      "lon": -1.080829978,
      "tz": "Europe\/London"
  },
  "EGLK": {
      "icao": "EGLK",
      "iata": "BBS",
      "name": "Blackbushe Airport",
      "city": "Yateley",
      "state": "England",
      "country": "GB",
      "elevation": 325,
      "lat": 51.3238983154,
      "lon": -0.8475000262,
      "tz": "Europe\/London"
  },
  "EGLL": {
      "icao": "EGLL",
      "iata": "LHR",
      "name": "London Heathrow Airport",
      "city": "London",
      "state": "England",
      "country": "GB",
      "elevation": 83,
      "lat": 51.4706001282,
      "lon": -0.4619410038,
      "tz": "Europe\/London"
  },
  "EGLM": {
      "icao": "EGLM",
      "iata": "",
      "name": "White Waltham Airfield",
      "city": "Maidenhead",
      "state": "",
      "country": "GB",
      "elevation": 131,
      "lat": 51.5008010864,
      "lon": -0.774443984,
      "tz": "Europe\/London"
  },
  "EGLP": {
      "icao": "EGLP",
      "iata": "",
      "name": "Brimpton Airfield",
      "city": "",
      "state": "",
      "country": "GB",
      "elevation": 210,
      "lat": 51.383889,
      "lon": -1.169167,
      "tz": "Europe\/London"
  },
  "EGLS": {
      "icao": "EGLS",
      "iata": "",
      "name": "Old Sarum Airfield",
      "city": "Salisbury",
      "state": "",
      "country": "GB",
      "elevation": 285,
      "lat": 51.0988998413,
      "lon": -1.7841700315,
      "tz": "Europe\/London"
  },
  "EGMA": {
      "icao": "EGMA",
      "iata": "",
      "name": "Fowlmere Airfield",
      "city": "Cambridge",
      "state": "",
      "country": "GB",
      "elevation": 124,
      "lat": 52.0774993896,
      "lon": 0.061666999,
      "tz": "Europe\/London"
  },
  "EGMC": {
      "icao": "EGMC",
      "iata": "SEN",
      "name": "Southend Airport",
      "city": "Southend",
      "state": "England",
      "country": "GB",
      "elevation": 49,
      "lat": 51.5713996887,
      "lon": 0.695555985,
      "tz": "Europe\/London"
  },
  "EGMD": {
      "icao": "EGMD",
      "iata": "LYX",
      "name": "Lydd Airport",
      "city": "Lydd",
      "state": "England",
      "country": "GB",
      "elevation": 13,
      "lat": 50.9561004639,
      "lon": 0.9391670227,
      "tz": "Europe\/London"
  },
  "EGMF": {
      "icao": "EGMF",
      "iata": "",
      "name": "Farthing Corner\/Stoneacre Farm Airport",
      "city": "Bredhurst",
      "state": "England",
      "country": "GB",
      "elevation": 420,
      "lat": 51.3306007385,
      "lon": 0.6011109948,
      "tz": "Europe\/London"
  },
  "EGMH": {
      "icao": "EGMH",
      "iata": "MSE",
      "name": "Kent International Airport",
      "city": "Manston",
      "state": "England",
      "country": "GB",
      "elevation": 178,
      "lat": 51.3422012329,
      "lon": 1.3461099863,
      "tz": "Europe\/London"
  },
  "EGMJ": {
      "icao": "EGMJ",
      "iata": "",
      "name": "Little Gransden Airfield",
      "city": "St. Neot's",
      "state": "",
      "country": "GB",
      "elevation": 250,
      "lat": 52.1666984558,
      "lon": -0.1538890004,
      "tz": "Europe\/London"
  },
  "EGML": {
      "icao": "EGML",
      "iata": "",
      "name": "Damyns Hall Aerodrome",
      "city": "Upminster",
      "state": "",
      "country": "GB",
      "elevation": 59,
      "lat": 51.5285987854,
      "lon": 0.2455559969,
      "tz": "Europe\/London"
  },
  "EGNA": {
      "icao": "EGNA",
      "iata": "",
      "name": "Hucknall Airfield",
      "city": "Nottingham",
      "state": "",
      "country": "GB",
      "elevation": 279,
      "lat": 53.0144004822,
      "lon": -1.2183300257,
      "tz": "Europe\/London"
  },
  "EGNB": {
      "icao": "EGNB",
      "iata": "",
      "name": "Brough Airport",
      "city": "Brough",
      "state": "England",
      "country": "GB",
      "elevation": 12,
      "lat": 53.7196998596,
      "lon": -0.5663329959,
      "tz": "Europe\/London"
  },
  "EGNC": {
      "icao": "EGNC",
      "iata": "CAX",
      "name": "Carlisle Airport",
      "city": "Carlisle",
      "state": "England",
      "country": "GB",
      "elevation": 190,
      "lat": 54.9375,
      "lon": -2.8091700077,
      "tz": "Europe\/London"
  },
  "EGND": {
      "icao": "EGND",
      "iata": "",
      "name": "HUDDERSFIELD (Crosland Moor)",
      "city": "Huddersfield",
      "state": "England",
      "country": "GB",
      "elevation": 825,
      "lat": 53.6216666667,
      "lon": -1.8302777778,
      "tz": "Europe\/London"
  },
  "EGNE": {
      "icao": "EGNE",
      "iata": "",
      "name": "Retford Gamston Airport",
      "city": "Gamston",
      "state": "England",
      "country": "GB",
      "elevation": 91,
      "lat": 53.2806015015,
      "lon": -0.9513890147,
      "tz": "Europe\/London"
  },
  "EGNF": {
      "icao": "EGNF",
      "iata": "",
      "name": "Netherthorpe Airfield",
      "city": "Worksop",
      "state": "",
      "country": "GB",
      "elevation": 250,
      "lat": 53.3168983459,
      "lon": -1.1963900328,
      "tz": "Europe\/London"
  },
  "EGNG": {
      "icao": "EGNG",
      "iata": "",
      "name": "Bagby Thirsk Prv Airport",
      "city": "Bagby",
      "state": "England",
      "country": "GB",
      "elevation": 161,
      "lat": 54.211101532,
      "lon": -1.2899999619,
      "tz": "Europe\/London"
  },
  "EGNH": {
      "icao": "EGNH",
      "iata": "BLK",
      "name": "Blackpool International Airport",
      "city": "Blackpool",
      "state": "England",
      "country": "GB",
      "elevation": 34,
      "lat": 53.7717018127,
      "lon": -3.0286099911,
      "tz": "Europe\/London"
  },
  "EGNI": {
      "icao": "EGNI",
      "iata": "",
      "name": "Skegness (Ingoldmells) Aerodrome",
      "city": "Skegness",
      "state": "",
      "country": "GB",
      "elevation": 8,
      "lat": 53.1716003418,
      "lon": 0.3293749988,
      "tz": "Europe\/London"
  },
  "EGNJ": {
      "icao": "EGNJ",
      "iata": "HUY",
      "name": "Humberside Airport",
      "city": "Grimsby",
      "state": "England",
      "country": "GB",
      "elevation": 121,
      "lat": 53.5744018555,
      "lon": -0.3508329988,
      "tz": "Europe\/London"
  },
  "EGNL": {
      "icao": "EGNL",
      "iata": "BWF",
      "name": "Barrow Walney Island Airport",
      "city": "Barrow-in-Furness",
      "state": "England",
      "country": "GB",
      "elevation": 173,
      "lat": 54.1311988831,
      "lon": -3.2636699677,
      "tz": "Europe\/London"
  },
  "EGNM": {
      "icao": "EGNM",
      "iata": "LBA",
      "name": "Leeds Bradford Airport",
      "city": "Leeds",
      "state": "England",
      "country": "GB",
      "elevation": 681,
      "lat": 53.8658981323,
      "lon": -1.6605700254,
      "tz": "Europe\/London"
  },
  "EGNO": {
      "icao": "EGNO",
      "iata": "WRT",
      "name": "Warton Airport",
      "city": "Warton",
      "state": "England",
      "country": "GB",
      "elevation": 55,
      "lat": 53.745098114,
      "lon": -2.8830599785,
      "tz": "Europe\/London"
  },
  "EGNR": {
      "icao": "EGNR",
      "iata": "CEG",
      "name": "Hawarden Airport",
      "city": "Hawarden",
      "state": "Wales",
      "country": "GB",
      "elevation": 45,
      "lat": 53.1781005859,
      "lon": -2.9777801037,
      "tz": "Europe\/London"
  },
  "EGNS": {
      "icao": "EGNS",
      "iata": "IOM",
      "name": "Isle of Man Airport",
      "city": "Castletown",
      "state": "Castletown",
      "country": "IM",
      "elevation": 52,
      "lat": 54.0833015442,
      "lon": -4.6238899231,
      "tz": "Europe\/Isle_of_Man"
  },
  "EGNT": {
      "icao": "EGNT",
      "iata": "NCL",
      "name": "Newcastle Airport",
      "city": "Newcastle",
      "state": "England",
      "country": "GB",
      "elevation": 266,
      "lat": 55.0374984741,
      "lon": -1.6916699409,
      "tz": "Europe\/London"
  },
  "EGNV": {
      "icao": "EGNV",
      "iata": "MME",
      "name": "Durham Tees Valley Airport",
      "city": "Durham",
      "state": "England",
      "country": "GB",
      "elevation": 120,
      "lat": 54.5092010498,
      "lon": -1.4294099808,
      "tz": "Europe\/London"
  },
  "EGNW": {
      "icao": "EGNW",
      "iata": "",
      "name": "Wickenby Aerodrome",
      "city": "Lincoln",
      "state": "England",
      "country": "GB",
      "elevation": 63,
      "lat": 53.3166999817,
      "lon": -0.349721998,
      "tz": "Europe\/London"
  },
  "EGNX": {
      "icao": "EGNX",
      "iata": "EMA",
      "name": "East Midlands Airport",
      "city": "Nottingham",
      "state": "England",
      "country": "GB",
      "elevation": 306,
      "lat": 52.8311004639,
      "lon": -1.3280600309,
      "tz": "Europe\/London"
  },
  "EGNY": {
      "icao": "EGNY",
      "iata": "",
      "name": "Beverley\/Linley Hill Airport",
      "city": "Beverley",
      "state": "England",
      "country": "GB",
      "elevation": 3,
      "lat": 53.8983001709,
      "lon": -0.3613890111,
      "tz": "Europe\/London"
  },
  "EGOD": {
      "icao": "EGOD",
      "iata": "",
      "name": "Llanbedr Airport",
      "city": "Llanbedr",
      "state": "Wales",
      "country": "GB",
      "elevation": 30,
      "lat": 52.8116989136,
      "lon": -4.1235799789,
      "tz": "Europe\/London"
  },
  "EGOE": {
      "icao": "EGOE",
      "iata": "",
      "name": "RAF Ternhill",
      "city": "Ternhill",
      "state": "",
      "country": "GB",
      "elevation": 272,
      "lat": 52.8712005615,
      "lon": -2.5335600376,
      "tz": "Europe\/London"
  },
  "EGOM": {
      "icao": "EGOM",
      "iata": "",
      "name": "RAF Spadeadam",
      "city": "Spadeadam",
      "state": "England",
      "country": "GB",
      "elevation": 1066,
      "lat": 55.0499992371,
      "lon": -2.5499999523,
      "tz": "Europe\/London"
  },
  "EGOQ": {
      "icao": "EGOQ",
      "iata": "",
      "name": "RAF Mona",
      "city": "Holyhead Island",
      "state": "Wales",
      "country": "GB",
      "elevation": 202,
      "lat": 53.2585983276,
      "lon": -4.3735499382,
      "tz": "Europe\/London"
  },
  "EGOS": {
      "icao": "EGOS",
      "iata": "",
      "name": "RAF Shawbury",
      "city": "Shawbury",
      "state": "England",
      "country": "GB",
      "elevation": 249,
      "lat": 52.7981987,
      "lon": -2.6680400372,
      "tz": "Europe\/London"
  },
  "EGOV": {
      "icao": "EGOV",
      "iata": "HLY",
      "name": "Anglesey Airport",
      "city": "Angelsey",
      "state": "Wales",
      "country": "GB",
      "elevation": 37,
      "lat": 53.2481002808,
      "lon": -4.5353398323,
      "tz": "Europe\/London"
  },
  "EGOW": {
      "icao": "EGOW",
      "iata": "",
      "name": "RAF Woodvale",
      "city": "Southport",
      "state": "England",
      "country": "GB",
      "elevation": 37,
      "lat": 53.5816001892,
      "lon": -3.0555200577,
      "tz": "Europe\/London"
  },
  "EGOY": {
      "icao": "EGOY",
      "iata": "",
      "name": "RAF West Freugh",
      "city": "Wigtownshire",
      "state": "Scotland",
      "country": "GB",
      "elevation": 55,
      "lat": 54.8511009216,
      "lon": -4.9477801323,
      "tz": "Europe\/London"
  },
  "EGPA": {
      "icao": "EGPA",
      "iata": "KOI",
      "name": "Kirkwall Airport",
      "city": "Orkney Islands",
      "state": "Scotland",
      "country": "GB",
      "elevation": 50,
      "lat": 58.9578018188,
      "lon": -2.9049999714,
      "tz": "Europe\/London"
  },
  "EGPB": {
      "icao": "EGPB",
      "iata": "LSI",
      "name": "Sumburgh Airport",
      "city": "Lerwick",
      "state": "Scotland",
      "country": "GB",
      "elevation": 20,
      "lat": 59.8788986206,
      "lon": -1.2955600023,
      "tz": "Europe\/London"
  },
  "EGPC": {
      "icao": "EGPC",
      "iata": "WIC",
      "name": "Wick Airport",
      "city": "Wick",
      "state": "Scotland",
      "country": "GB",
      "elevation": 126,
      "lat": 58.4589004517,
      "lon": -3.0930600166,
      "tz": "Europe\/London"
  },
  "EGPD": {
      "icao": "EGPD",
      "iata": "ABZ",
      "name": "Aberdeen Dyce Airport",
      "city": "Aberdeen",
      "state": "Scotland",
      "country": "GB",
      "elevation": 215,
      "lat": 57.2019004822,
      "lon": -2.1977798939,
      "tz": "Europe\/London"
  },
  "EGPE": {
      "icao": "EGPE",
      "iata": "INV",
      "name": "Inverness Airport",
      "city": "Inverness",
      "state": "Scotland",
      "country": "GB",
      "elevation": 31,
      "lat": 57.5424995422,
      "lon": -4.0475001335,
      "tz": "Europe\/London"
  },
  "EGPF": {
      "icao": "EGPF",
      "iata": "GLA",
      "name": "Glasgow International Airport",
      "city": "Glasgow",
      "state": "Scotland",
      "country": "GB",
      "elevation": 26,
      "lat": 55.8718986511,
      "lon": -4.4330601692,
      "tz": "Europe\/London"
  },
  "EGPG": {
      "icao": "EGPG",
      "iata": "",
      "name": "Cumbernauld Airport",
      "city": "Cumbernauld",
      "state": "Scotland",
      "country": "GB",
      "elevation": 350,
      "lat": 55.9747009277,
      "lon": -3.9755599499,
      "tz": "Europe\/London"
  },
  "EGPH": {
      "icao": "EGPH",
      "iata": "EDI",
      "name": "Edinburgh Airport",
      "city": "Edinburgh",
      "state": "Scotland",
      "country": "GB",
      "elevation": 135,
      "lat": 55.9500007629,
      "lon": -3.3724999428,
      "tz": "Europe\/London"
  },
  "EGPI": {
      "icao": "EGPI",
      "iata": "ILY",
      "name": "Islay Airport",
      "city": "Port Ellen",
      "state": "Scotland",
      "country": "GB",
      "elevation": 56,
      "lat": 55.6819000244,
      "lon": -6.2566699982,
      "tz": "Europe\/London"
  },
  "EGPJ": {
      "icao": "EGPJ",
      "iata": "",
      "name": "Fife Airport",
      "city": "",
      "state": "Scotland",
      "country": "GB",
      "elevation": 339,
      "lat": 56.1833000183,
      "lon": -3.220279932,
      "tz": "Europe\/London"
  },
  "EGPK": {
      "icao": "EGPK",
      "iata": "PIK",
      "name": "Glasgow Prestwick Airport",
      "city": "Glasgow",
      "state": "Scotland",
      "country": "GB",
      "elevation": 65,
      "lat": 55.5093994141,
      "lon": -4.5866699219,
      "tz": "Europe\/London"
  },
  "EGPL": {
      "icao": "EGPL",
      "iata": "BEB",
      "name": "Benbecula Airport",
      "city": "Balivanich",
      "state": "Scotland",
      "country": "GB",
      "elevation": 19,
      "lat": 57.481098175,
      "lon": -7.3627800941,
      "tz": "Europe\/London"
  },
  "EGPM": {
      "icao": "EGPM",
      "iata": "SCS",
      "name": "Scatsta Airport",
      "city": "Shetland Islands",
      "state": "Scotland",
      "country": "GB",
      "elevation": 81,
      "lat": 60.432800293,
      "lon": -1.296110034,
      "tz": "Europe\/London"
  },
  "EGPN": {
      "icao": "EGPN",
      "iata": "DND",
      "name": "Dundee Airport",
      "city": "Dundee",
      "state": "Scotland",
      "country": "GB",
      "elevation": 17,
      "lat": 56.4524993896,
      "lon": -3.0258300304,
      "tz": "Europe\/London"
  },
  "EGPO": {
      "icao": "EGPO",
      "iata": "SYY",
      "name": "Stornoway Airport",
      "city": "Stornoway",
      "state": "Scotland",
      "country": "GB",
      "elevation": 26,
      "lat": 58.2155990601,
      "lon": -6.3311100006,
      "tz": "Europe\/London"
  },
  "EGPR": {
      "icao": "EGPR",
      "iata": "BRR",
      "name": "Barra Airport",
      "city": "Eoligarry",
      "state": "Scotland",
      "country": "GB",
      "elevation": 5,
      "lat": 57.0228004456,
      "lon": -7.4430599213,
      "tz": "Europe\/London"
  },
  "EGPT": {
      "icao": "EGPT",
      "iata": "PSL",
      "name": "Perth\/Scone Airport",
      "city": "Perth",
      "state": "Scotland",
      "country": "GB",
      "elevation": 397,
      "lat": 56.439201355,
      "lon": -3.3722200394,
      "tz": "Europe\/London"
  },
  "EGPU": {
      "icao": "EGPU",
      "iata": "TRE",
      "name": "Tiree Airport",
      "city": "Balemartine",
      "state": "Scotland",
      "country": "GB",
      "elevation": 38,
      "lat": 56.4991989136,
      "lon": -6.8691701889,
      "tz": "Europe\/London"
  },
  "EGPW": {
      "icao": "EGPW",
      "iata": "UNT",
      "name": "Unst Airport",
      "city": "Shetland Islands",
      "state": "Scotland",
      "country": "GB",
      "elevation": 0,
      "lat": 60.7472000122,
      "lon": -0.8538500071,
      "tz": "Europe\/London"
  },
  "EGPY": {
      "icao": "EGPY",
      "iata": "",
      "name": "Dounreay\/Thurso Airport",
      "city": "Thurso",
      "state": "Scotland",
      "country": "GB",
      "elevation": 22,
      "lat": 58.5839004517,
      "lon": -3.7269399166,
      "tz": "Europe\/London"
  },
  "EGQB": {
      "icao": "EGQB",
      "iata": "BOL",
      "name": "Ballykelly Airport",
      "city": "Ballykelly",
      "state": "Northern-Ireland",
      "country": "GB",
      "elevation": 85,
      "lat": 55.0602989197,
      "lon": -7.0202798843,
      "tz": "Europe\/London"
  },
  "EGQK": {
      "icao": "EGQK",
      "iata": "FSS",
      "name": "RAF Kinloss",
      "city": "Kinloss",
      "state": "Scotland",
      "country": "GB",
      "elevation": 22,
      "lat": 57.6493988037,
      "lon": -3.5606400967,
      "tz": "Europe\/London"
  },
  "EGQL": {
      "icao": "EGQL",
      "iata": "ADX",
      "name": "RAF Leuchars",
      "city": "St. Andrews",
      "state": "Scotland",
      "country": "GB",
      "elevation": 38,
      "lat": 56.3728981018,
      "lon": -2.8684399128,
      "tz": "Europe\/London"
  },
  "EGQS": {
      "icao": "EGQS",
      "iata": "LMO",
      "name": "RAF Lossiemouth",
      "city": "Lossiemouth",
      "state": "Scotland",
      "country": "GB",
      "elevation": 42,
      "lat": 57.7052001953,
      "lon": -3.3391699791,
      "tz": "Europe\/London"
  },
  "EGSA": {
      "icao": "EGSA",
      "iata": "",
      "name": "Shipdham Airfield",
      "city": "East Dereham",
      "state": "England",
      "country": "GB",
      "elevation": 210,
      "lat": 52.6293983459,
      "lon": 0.9280560017,
      "tz": "Europe\/London"
  },
  "EGSC": {
      "icao": "EGSC",
      "iata": "CBG",
      "name": "Cambridge Airport",
      "city": "Cambridge",
      "state": "England",
      "country": "GB",
      "elevation": 47,
      "lat": 52.2050018311,
      "lon": 0.174999997,
      "tz": "Europe\/London"
  },
  "EGSF": {
      "icao": "EGSF",
      "iata": "",
      "name": "Peterborough Business Airport",
      "city": "Conington",
      "state": "England",
      "country": "GB",
      "elevation": 26,
      "lat": 52.4681015015,
      "lon": -0.2511110008,
      "tz": "Europe\/London"
  },
  "EGSG": {
      "icao": "EGSG",
      "iata": "",
      "name": "Stapleford Aerodrome",
      "city": "Stapleford Tawney",
      "state": "England",
      "country": "GB",
      "elevation": 183,
      "lat": 51.6525001526,
      "lon": 0.1558330059,
      "tz": "Europe\/London"
  },
  "EGSH": {
      "icao": "EGSH",
      "iata": "NWI",
      "name": "Norwich International Airport",
      "city": "Norwich",
      "state": "England",
      "country": "GB",
      "elevation": 117,
      "lat": 52.6758003235,
      "lon": 1.2827800512,
      "tz": "Europe\/London"
  },
  "EGSI": {
      "icao": "EGSI",
      "iata": "",
      "name": "Mashland Airfield",
      "city": "Wisbech",
      "state": "England",
      "country": "GB",
      "elevation": -6,
      "lat": 52.6404853896,
      "lon": 0.2918350697,
      "tz": "Europe\/London"
  },
  "EGSJ": {
      "icao": "EGSJ",
      "iata": "",
      "name": "Seething Airfield",
      "city": "Seething",
      "state": "England",
      "country": "GB",
      "elevation": 130,
      "lat": 52.511100769,
      "lon": 1.4172199965,
      "tz": "Europe\/London"
  },
  "EGSL": {
      "icao": "EGSL",
      "iata": "",
      "name": "Andrewsfield Airport",
      "city": "Braintree",
      "state": "England",
      "country": "GB",
      "elevation": 286,
      "lat": 51.8950004578,
      "lon": 0.4491670132,
      "tz": "Europe\/London"
  },
  "EGSM": {
      "icao": "EGSM",
      "iata": "",
      "name": "Beccles Airport",
      "city": "Beccles",
      "state": "England",
      "country": "GB",
      "elevation": 80,
      "lat": 52.4352989197,
      "lon": 1.6183300018,
      "tz": "Europe\/London"
  },
  "EGSN": {
      "icao": "EGSN",
      "iata": "",
      "name": "Bourn Airport",
      "city": "Cambridge",
      "state": "",
      "country": "GB",
      "elevation": 226,
      "lat": 52.2106018066,
      "lon": -0.0425000004,
      "tz": "Europe\/London"
  },
  "EGSO": {
      "icao": "EGSO",
      "iata": "",
      "name": "Crowfield Airfield",
      "city": "Stowmarket",
      "state": "England",
      "country": "GB",
      "elevation": 201,
      "lat": 52.1711006165,
      "lon": 1.111109972,
      "tz": "Europe\/London"
  },
  "EGSP": {
      "icao": "EGSP",
      "iata": "",
      "name": "Peterborough\/Sibson Airport",
      "city": "Peterborough",
      "state": "England",
      "country": "GB",
      "elevation": 130,
      "lat": 52.5558013916,
      "lon": -0.3863889873,
      "tz": "Europe\/London"
  },
  "EGSQ": {
      "icao": "EGSQ",
      "iata": "",
      "name": "Clacton Airport",
      "city": "Clacton-on-Sea",
      "state": "England",
      "country": "GB",
      "elevation": 37,
      "lat": 51.7849998474,
      "lon": 1.1299999952,
      "tz": "Europe\/London"
  },
  "EGSR": {
      "icao": "EGSR",
      "iata": "",
      "name": "Earls Colne Airfield",
      "city": "Earles Colne",
      "state": "England",
      "country": "GB",
      "elevation": 226,
      "lat": 51.9143981934,
      "lon": 0.6825000048,
      "tz": "Europe\/London"
  },
  "EGSS": {
      "icao": "EGSS",
      "iata": "STN",
      "name": "London Stansted Airport",
      "city": "London",
      "state": "England",
      "country": "GB",
      "elevation": 348,
      "lat": 51.8849983215,
      "lon": 0.2349999994,
      "tz": "Europe\/London"
  },
  "EGST": {
      "icao": "EGST",
      "iata": "",
      "name": "Elmsett Airport",
      "city": "Hadleigh",
      "state": "England",
      "country": "GB",
      "elevation": 246,
      "lat": 52.0772018433,
      "lon": 0.9775000215,
      "tz": "Europe\/London"
  },
  "EGSU": {
      "icao": "EGSU",
      "iata": "QFO",
      "name": "Duxford Airport",
      "city": "Duxford",
      "state": "England",
      "country": "GB",
      "elevation": 125,
      "lat": 52.090801239,
      "lon": 0.1319440007,
      "tz": "Europe\/London"
  },
  "EGSV": {
      "icao": "EGSV",
      "iata": "",
      "name": "Old Buckenham Airport",
      "city": "Old Buckenham",
      "state": "England",
      "country": "GB",
      "elevation": 185,
      "lat": 52.4975013733,
      "lon": 1.0519399643,
      "tz": "Europe\/London"
  },
  "EGSW": {
      "icao": "EGSW",
      "iata": "",
      "name": "Newmarket Heath Airfield",
      "city": "Newmarket",
      "state": "",
      "country": "GB",
      "elevation": 100,
      "lat": 52.2419013977,
      "lon": 0.3705559969,
      "tz": "Europe\/London"
  },
  "EGSX": {
      "icao": "EGSX",
      "iata": "",
      "name": "North Weald Airport",
      "city": "North Weald Bassett",
      "state": "England",
      "country": "GB",
      "elevation": 321,
      "lat": 51.721698761,
      "lon": 0.1541669965,
      "tz": "Europe\/London"
  },
  "EGTA": {
      "icao": "EGTA",
      "iata": "",
      "name": "Aylesbury\/Thame Airport",
      "city": "Aylesbury",
      "state": "",
      "country": "GB",
      "elevation": 289,
      "lat": 51.7757987976,
      "lon": -0.9402779937,
      "tz": "Europe\/London"
  },
  "EGTB": {
      "icao": "EGTB",
      "iata": "HYC",
      "name": "Wycombe Air Park",
      "city": "High Wycombe",
      "state": "England",
      "country": "GB",
      "elevation": 515,
      "lat": 51.6116981506,
      "lon": -0.8083329797,
      "tz": "Europe\/London"
  },
  "EGTC": {
      "icao": "EGTC",
      "iata": "",
      "name": "Cranfield Airport",
      "city": "Cranfield",
      "state": "England",
      "country": "GB",
      "elevation": 358,
      "lat": 52.0722007751,
      "lon": -0.6166669726,
      "tz": "Europe\/London"
  },
  "EGTD": {
      "icao": "EGTD",
      "iata": "",
      "name": "Dunsfold Aerodrome",
      "city": "Dunsfold",
      "state": "England",
      "country": "GB",
      "elevation": 172,
      "lat": 51.1171989441,
      "lon": -0.5358330011,
      "tz": "Europe\/London"
  },
  "EGTE": {
      "icao": "EGTE",
      "iata": "EXT",
      "name": "Exeter International Airport",
      "city": "Exeter",
      "state": "England",
      "country": "GB",
      "elevation": 102,
      "lat": 50.7344017029,
      "lon": -3.4138898849,
      "tz": "Europe\/London"
  },
  "EGTF": {
      "icao": "EGTF",
      "iata": "",
      "name": "Fairoaks Airport",
      "city": "Woking",
      "state": "England",
      "country": "GB",
      "elevation": 80,
      "lat": 51.3480987549,
      "lon": -0.5588889718,
      "tz": "Europe\/London"
  },
  "EGTG": {
      "icao": "EGTG",
      "iata": "FZO",
      "name": "Bristol Filton Airport",
      "city": "Bristol",
      "state": "England",
      "country": "GB",
      "elevation": 226,
      "lat": 51.5194015503,
      "lon": -2.5908300877,
      "tz": "Europe\/London"
  },
  "EGTH": {
      "icao": "EGTH",
      "iata": "",
      "name": "Old Warden Airfield",
      "city": "Old Warden",
      "state": "",
      "country": "GB",
      "elevation": 110,
      "lat": 52.0834007263,
      "lon": -0.3166669905,
      "tz": "Europe\/London"
  },
  "EGTK": {
      "icao": "EGTK",
      "iata": "OXF",
      "name": "Oxford (Kidlington) Airport",
      "city": "Kidlington",
      "state": "England",
      "country": "GB",
      "elevation": 270,
      "lat": 51.8368988037,
      "lon": -1.3200000525,
      "tz": "Europe\/London"
  },
  "EGTN": {
      "icao": "EGTN",
      "iata": "",
      "name": "Enstone Aerodrome",
      "city": "Oxfordshire",
      "state": "England",
      "country": "GB",
      "elevation": 550,
      "lat": 51.9281666667,
      "lon": -1.4285,
      "tz": "Europe\/London"
  },
  "EGTO": {
      "icao": "EGTO",
      "iata": "RCS",
      "name": "Rochester Airport",
      "city": "Rochester",
      "state": "England",
      "country": "GB",
      "elevation": 436,
      "lat": 51.3518981934,
      "lon": 0.5033329725,
      "tz": "Europe\/London"
  },
  "EGTP": {
      "icao": "EGTP",
      "iata": "",
      "name": "Perranporth Airfield",
      "city": "Perranporth",
      "state": "England",
      "country": "GB",
      "elevation": 330,
      "lat": 50.3316993713,
      "lon": -5.1774997711,
      "tz": "Europe\/London"
  },
  "EGTR": {
      "icao": "EGTR",
      "iata": "",
      "name": "Elstree Airfield",
      "city": "Watford",
      "state": "England",
      "country": "GB",
      "elevation": 332,
      "lat": 51.6557998657,
      "lon": -0.3258329928,
      "tz": "Europe\/London"
  },
  "EGTU": {
      "icao": "EGTU",
      "iata": "",
      "name": "Dunkeswell Airport",
      "city": "Dunkeswell",
      "state": "England",
      "country": "GB",
      "elevation": 850,
      "lat": 50.8600006104,
      "lon": -3.2347199917,
      "tz": "Europe\/London"
  },
  "EGTW": {
      "icao": "EGTW",
      "iata": "",
      "name": "Oaksey Park Airport",
      "city": "Malmesbury",
      "state": "",
      "country": "GB",
      "elevation": 250,
      "lat": 51.6321983337,
      "lon": -2.0147199631,
      "tz": "Europe\/London"
  },
  "EGUB": {
      "icao": "EGUB",
      "iata": "BEX",
      "name": "RAF Benson",
      "city": "Benson",
      "state": "England",
      "country": "GB",
      "elevation": 226,
      "lat": 51.6164016724,
      "lon": -1.0958299637,
      "tz": "Europe\/London"
  },
  "EGUD": {
      "icao": "EGUD",
      "iata": "",
      "name": "RAF Abingdon",
      "city": "Abingdon",
      "state": "England",
      "country": "GB",
      "elevation": 259,
      "lat": 51.6902999878,
      "lon": -1.316669941,
      "tz": "Europe\/London"
  },
  "EGUK": {
      "icao": "EGUK",
      "iata": "",
      "name": "Waterbeach Airfield",
      "city": "Waterbeach",
      "state": "",
      "country": "GB",
      "elevation": 19,
      "lat": 52.281742,
      "lon": 0.184532,
      "tz": "Europe\/London"
  },
  "EGUL": {
      "icao": "EGUL",
      "iata": "LKZ",
      "name": "RAF Lakenheath",
      "city": "Lakenheath",
      "state": "England",
      "country": "GB",
      "elevation": 32,
      "lat": 52.4093017578,
      "lon": 0.5609999895,
      "tz": "Europe\/London"
  },
  "EGUN": {
      "icao": "EGUN",
      "iata": "MHZ",
      "name": "RAF Mildenhall",
      "city": "Mildenhall",
      "state": "England",
      "country": "GB",
      "elevation": 33,
      "lat": 52.3619003296,
      "lon": 0.4864059985,
      "tz": "Europe\/London"
  },
  "EGUO": {
      "icao": "EGUO",
      "iata": "",
      "name": "Colerne Airport",
      "city": "Colerne",
      "state": "England",
      "country": "GB",
      "elevation": 593,
      "lat": 51.4390983582,
      "lon": -2.2863900662,
      "tz": "Europe\/London"
  },
  "EGUP": {
      "icao": "EGUP",
      "iata": "",
      "name": "RAF Sculthorpe",
      "city": "Fakeham",
      "state": "England",
      "country": "GB",
      "elevation": 213,
      "lat": 52.846698761,
      "lon": 0.7663890123,
      "tz": "Europe\/London"
  },
  "EGUW": {
      "icao": "EGUW",
      "iata": "",
      "name": "RAF Wattisham",
      "city": "Stowmarket",
      "state": "England",
      "country": "GB",
      "elevation": 284,
      "lat": 52.1273002625,
      "lon": 0.956264019,
      "tz": "Europe\/London"
  },
  "EGUY": {
      "icao": "EGUY",
      "iata": "QUY",
      "name": "RAF Wyton",
      "city": "St. Ives",
      "state": "England",
      "country": "GB",
      "elevation": 135,
      "lat": 52.3572006226,
      "lon": -0.107832998,
      "tz": "Europe\/London"
  },
  "EGVA": {
      "icao": "EGVA",
      "iata": "FFD",
      "name": "RAF Fairford",
      "city": "Fairford",
      "state": "England",
      "country": "GB",
      "elevation": 286,
      "lat": 51.6822013855,
      "lon": -1.7900300026,
      "tz": "Europe\/London"
  },
  "EGVN": {
      "icao": "EGVN",
      "iata": "BZZ",
      "name": "RAF Brize Norton",
      "city": "Brize Norton",
      "state": "England",
      "country": "GB",
      "elevation": 288,
      "lat": 51.75,
      "lon": -1.5836199522,
      "tz": "Europe\/London"
  },
  "EGVO": {
      "icao": "EGVO",
      "iata": "ODH",
      "name": "RAF Odiham",
      "city": "Odiham",
      "state": "England",
      "country": "GB",
      "elevation": 405,
      "lat": 51.2341003418,
      "lon": -0.9428250194,
      "tz": "Europe\/London"
  },
  "EGVP": {
      "icao": "EGVP",
      "iata": "",
      "name": "Middle Wallop Airfield",
      "city": "Middle Wallop",
      "state": "England",
      "country": "GB",
      "elevation": 297,
      "lat": 51.1394004822,
      "lon": -1.5686099529,
      "tz": "Europe\/London"
  },
  "EGVT": {
      "icao": "EGVT",
      "iata": "WXF",
      "name": "Wethersfield Airport",
      "city": "Wethersfield",
      "state": "England",
      "country": "GB",
      "elevation": 322,
      "lat": 51.9719009399,
      "lon": 0.5094439983,
      "tz": "Europe\/London"
  },
  "EGWC": {
      "icao": "EGWC",
      "iata": "",
      "name": "DCAE Cosford Airport",
      "city": "Cosford",
      "state": "England",
      "country": "GB",
      "elevation": 272,
      "lat": 52.6399993896,
      "lon": -2.3055799007,
      "tz": "Europe\/London"
  },
  "EGWE": {
      "icao": "EGWE",
      "iata": "",
      "name": "RAF Henlow",
      "city": "Henlow",
      "state": "",
      "country": "GB",
      "elevation": 206,
      "lat": 52.0181999207,
      "lon": -0.3038499951,
      "tz": "Europe\/London"
  },
  "EGWN": {
      "icao": "EGWN",
      "iata": "",
      "name": "RAF Halton",
      "city": "Halton",
      "state": "",
      "country": "GB",
      "elevation": 370,
      "lat": 51.7906990051,
      "lon": -0.7379720211,
      "tz": "Europe\/London"
  },
  "EGWU": {
      "icao": "EGWU",
      "iata": "NHT",
      "name": "RAF Northolt",
      "city": "London",
      "state": "England",
      "country": "GB",
      "elevation": 124,
      "lat": 51.5530014038,
      "lon": -0.418166995,
      "tz": "Europe\/London"
  },
  "EGXC": {
      "icao": "EGXC",
      "iata": "QCY",
      "name": "RAF Coningsby",
      "city": "Coningsby",
      "state": "England",
      "country": "GB",
      "elevation": 25,
      "lat": 53.0929985046,
      "lon": -0.1660140008,
      "tz": "Europe\/London"
  },
  "EGXD": {
      "icao": "EGXD",
      "iata": "",
      "name": "RAF Dishforth",
      "city": "Dishforth",
      "state": "England",
      "country": "GB",
      "elevation": 117,
      "lat": 54.1371994019,
      "lon": -1.4202500582,
      "tz": "Europe\/London"
  },
  "EGXE": {
      "icao": "EGXE",
      "iata": "",
      "name": "Leeming Airport",
      "city": "Leeming",
      "state": "England",
      "country": "GB",
      "elevation": 132,
      "lat": 54.2924003601,
      "lon": -1.535400033,
      "tz": "Europe\/London"
  },
  "EGXG": {
      "icao": "EGXG",
      "iata": "",
      "name": "RAF Church Fenton",
      "city": "Church Fenton",
      "state": "England",
      "country": "GB",
      "elevation": 29,
      "lat": 53.8343009949,
      "lon": -1.1955000162,
      "tz": "Europe\/London"
  },
  "EGXH": {
      "icao": "EGXH",
      "iata": "BEQ",
      "name": "RAF Honington",
      "city": "Thetford",
      "state": "England",
      "country": "GB",
      "elevation": 174,
      "lat": 52.3426017761,
      "lon": 0.7729390264,
      "tz": "Europe\/London"
  },
  "EGXJ": {
      "icao": "EGXJ",
      "iata": "OKH",
      "name": "RAF Cottesmore",
      "city": "Cottesmore",
      "state": "England",
      "country": "GB",
      "elevation": 461,
      "lat": 52.7356987,
      "lon": -0.648769021,
      "tz": "Europe\/London"
  },
  "EGXP": {
      "icao": "EGXP",
      "iata": "SQZ",
      "name": "RAF Scampton",
      "city": "Scampton",
      "state": "England",
      "country": "GB",
      "elevation": 202,
      "lat": 53.307800293,
      "lon": -0.5508329868,
      "tz": "Europe\/London"
  },
  "EGXT": {
      "icao": "EGXT",
      "iata": "",
      "name": "RAF Wittering",
      "city": "Wittering",
      "state": "England",
      "country": "GB",
      "elevation": 273,
      "lat": 52.6125984192,
      "lon": -0.4764530063,
      "tz": "Europe\/London"
  },
  "EGXU": {
      "icao": "EGXU",
      "iata": "HRT",
      "name": "RAF Linton-On-Ouse",
      "city": "Linton-On-Ouse",
      "state": "England",
      "country": "GB",
      "elevation": 53,
      "lat": 54.0489006042,
      "lon": -1.2527500391,
      "tz": "Europe\/London"
  },
  "EGXV": {
      "icao": "EGXV",
      "iata": "",
      "name": "RAF Leconfield",
      "city": "Leconfield",
      "state": "England",
      "country": "GB",
      "elevation": 36,
      "lat": 53.8758010864,
      "lon": -0.4350000024,
      "tz": "Europe\/London"
  },
  "EGXW": {
      "icao": "EGXW",
      "iata": "WTN",
      "name": "RAF Waddington",
      "city": "Waddington",
      "state": "England",
      "country": "GB",
      "elevation": 231,
      "lat": 53.1661987305,
      "lon": -0.5238109827,
      "tz": "Europe\/London"
  },
  "EGXY": {
      "icao": "EGXY",
      "iata": "",
      "name": "RAF Syerston",
      "city": "Newark",
      "state": "",
      "country": "GB",
      "elevation": 228,
      "lat": 53.0228004456,
      "lon": -0.9111109972,
      "tz": "Europe\/London"
  },
  "EGXZ": {
      "icao": "EGXZ",
      "iata": "",
      "name": "RAF Topcliffe",
      "city": "Topcliffe",
      "state": "England",
      "country": "GB",
      "elevation": 92,
      "lat": 54.2055015564,
      "lon": -1.3820899725,
      "tz": "Europe\/London"
  },
  "EGYD": {
      "icao": "EGYD",
      "iata": "",
      "name": "RAF Cranwell",
      "city": "Cranwell",
      "state": "England",
      "country": "GB",
      "elevation": 218,
      "lat": 53.0303001404,
      "lon": -0.4832420051,
      "tz": "Europe\/London"
  },
  "EGYE": {
      "icao": "EGYE",
      "iata": "",
      "name": "RAF Barkston Heath",
      "city": "Grantham",
      "state": "England",
      "country": "GB",
      "elevation": 367,
      "lat": 52.9622001648,
      "lon": -0.5616250038,
      "tz": "Europe\/London"
  },
  "EGYK": {
      "icao": "EGYK",
      "iata": "",
      "name": "Elvington Airfield",
      "city": "York",
      "state": "England",
      "country": "GB",
      "elevation": 48,
      "lat": 53.9231444281,
      "lon": -0.9913444519,
      "tz": "Europe\/London"
  },
  "EGYM": {
      "icao": "EGYM",
      "iata": "MRH",
      "name": "RAF Marham",
      "city": "Marham",
      "state": "England",
      "country": "GB",
      "elevation": 75,
      "lat": 52.648399353,
      "lon": 0.5506920218,
      "tz": "Europe\/London"
  },
  "EGYP": {
      "icao": "EGYP",
      "iata": "MPN",
      "name": "Mount Pleasant Airport",
      "city": "Mount Pleasant",
      "state": "Tierra-del-Fuego",
      "country": "FK",
      "elevation": 244,
      "lat": -51.8227996826,
      "lon": -58.4472007751,
      "tz": "Atlantic\/Stanley"
  },
  "EHAL": {
      "icao": "EHAL",
      "iata": "",
      "name": "Ameland Airport",
      "city": "Ameland",
      "state": "Friesland",
      "country": "NL",
      "elevation": 11,
      "lat": 53.4516983032,
      "lon": 5.6772198677,
      "tz": "Europe\/Amsterdam"
  },
  "EHAM": {
      "icao": "EHAM",
      "iata": "AMS",
      "name": "Amsterdam Airport Schiphol",
      "city": "Amsterdam",
      "state": "North-Holland",
      "country": "NL",
      "elevation": -11,
      "lat": 52.3086013794,
      "lon": 4.7638897896,
      "tz": "Europe\/Amsterdam"
  },
  "EHBD": {
      "icao": "EHBD",
      "iata": "",
      "name": "Budel Airport",
      "city": "Weert",
      "state": "North-Brabant",
      "country": "NL",
      "elevation": 114,
      "lat": 51.2552986145,
      "lon": 5.601389885,
      "tz": "Europe\/Amsterdam"
  },
  "EHBK": {
      "icao": "EHBK",
      "iata": "MST",
      "name": "Maastricht Aachen Airport",
      "city": "Maastricht",
      "state": "Limburg",
      "country": "NL",
      "elevation": 375,
      "lat": 50.9117012024,
      "lon": 5.7701401711,
      "tz": "Europe\/Amsterdam"
  },
  "EHDL": {
      "icao": "EHDL",
      "iata": "QAR",
      "name": "Deelen Air Base",
      "city": "Arnhem",
      "state": "Gelderland",
      "country": "NL",
      "elevation": 158,
      "lat": 52.0606002808,
      "lon": 5.8730602264,
      "tz": "Europe\/Amsterdam"
  },
  "EHDP": {
      "icao": "EHDP",
      "iata": "",
      "name": "De Peel Air Base",
      "city": "Venray",
      "state": "North-Brabant",
      "country": "NL",
      "elevation": 98,
      "lat": 51.5172996521,
      "lon": 5.8557200432,
      "tz": "Europe\/Amsterdam"
  },
  "EHDR": {
      "icao": "EHDR",
      "iata": "",
      "name": "Drachten Airport",
      "city": "Drachten",
      "state": "Friesland",
      "country": "NL",
      "elevation": 14,
      "lat": 53.1192016602,
      "lon": 6.129720211,
      "tz": "Europe\/Amsterdam"
  },
  "EHDS": {
      "icao": "EHDS",
      "iata": "",
      "name": "Aero Club Salland",
      "city": "Lemelerveld",
      "state": "Overijssel",
      "country": "NL",
      "elevation": 10,
      "lat": 52.4683333333,
      "lon": 6.3333333333,
      "tz": "Europe\/Amsterdam"
  },
  "EHEH": {
      "icao": "EHEH",
      "iata": "EIN",
      "name": "Eindhoven Airport",
      "city": "Eindhoven",
      "state": "North-Brabant",
      "country": "NL",
      "elevation": 74,
      "lat": 51.4500999451,
      "lon": 5.3745298386,
      "tz": "Europe\/Amsterdam"
  },
  "EHGG": {
      "icao": "EHGG",
      "iata": "GRQ",
      "name": "Eelde Airport",
      "city": "Groningen",
      "state": "Drenthe",
      "country": "NL",
      "elevation": 17,
      "lat": 53.1197013855,
      "lon": 6.5794401169,
      "tz": "Europe\/Amsterdam"
  },
  "EHGR": {
      "icao": "EHGR",
      "iata": "GLZ",
      "name": "Gilze Rijen Air Base",
      "city": "Breda",
      "state": "North-Brabant",
      "country": "NL",
      "elevation": 49,
      "lat": 51.567401886,
      "lon": 4.9318299294,
      "tz": "Europe\/Amsterdam"
  },
  "EHHO": {
      "icao": "EHHO",
      "iata": "",
      "name": "Hoogeveen Airport",
      "city": "Hoogeveen",
      "state": "Drenthe",
      "country": "NL",
      "elevation": 40,
      "lat": 52.7308006287,
      "lon": 6.5161099434,
      "tz": "Europe\/Amsterdam"
  },
  "EHHV": {
      "icao": "EHHV",
      "iata": "",
      "name": "Hilversum Airport",
      "city": "Hilversum",
      "state": "North-Holland",
      "country": "NL",
      "elevation": 3,
      "lat": 52.1918983459,
      "lon": 5.1469402313,
      "tz": "Europe\/Amsterdam"
  },
  "EHKD": {
      "icao": "EHKD",
      "iata": "DHR",
      "name": "De Kooy Airport",
      "city": "Den Helder",
      "state": "North-Holland",
      "country": "NL",
      "elevation": 3,
      "lat": 52.9234008789,
      "lon": 4.7806200981,
      "tz": "Europe\/Amsterdam"
  },
  "EHLE": {
      "icao": "EHLE",
      "iata": "LEY",
      "name": "Lelystad Airport",
      "city": "Lelystad",
      "state": "Flevoland",
      "country": "NL",
      "elevation": -13,
      "lat": 52.4603004456,
      "lon": 5.5272197723,
      "tz": "Europe\/Amsterdam"
  },
  "EHLW": {
      "icao": "EHLW",
      "iata": "LWR",
      "name": "Leeuwarden Air Base",
      "city": "Leeuwarden",
      "state": "Friesland",
      "country": "NL",
      "elevation": 3,
      "lat": 53.2285995483,
      "lon": 5.7605600357,
      "tz": "Europe\/Amsterdam"
  },
  "EHMZ": {
      "icao": "EHMZ",
      "iata": "",
      "name": "Midden-Zeeland Airport",
      "city": "Middelburg",
      "state": "Zeeland",
      "country": "NL",
      "elevation": 6,
      "lat": 51.5121994019,
      "lon": 3.731110096,
      "tz": "Europe\/Amsterdam"
  },
  "EHOW": {
      "icao": "EHOW",
      "iata": "---",
      "name": "Oostwold Airport",
      "city": "",
      "state": "Groningen",
      "country": "NL",
      "elevation": 3,
      "lat": 53.2097583333,
      "lon": 7.0365194444,
      "tz": "Europe\/Amsterdam"
  },
  "EHRD": {
      "icao": "EHRD",
      "iata": "RTM",
      "name": "Rotterdam Airport",
      "city": "Rotterdam",
      "state": "South-Holland",
      "country": "NL",
      "elevation": -15,
      "lat": 51.9569015503,
      "lon": 4.4372200966,
      "tz": "Europe\/Amsterdam"
  },
  "EHSE": {
      "icao": "EHSE",
      "iata": "",
      "name": "Seppe Airport",
      "city": "Hoeven",
      "state": "North-Brabant",
      "country": "NL",
      "elevation": 30,
      "lat": 51.5546989441,
      "lon": 4.5524997711,
      "tz": "Europe\/Amsterdam"
  },
  "EHST": {
      "icao": "EHST",
      "iata": "",
      "name": "Stadskanaal Airport",
      "city": "Stadskanaal",
      "state": "Groningen",
      "country": "NL",
      "elevation": 14,
      "lat": 52.9986000061,
      "lon": 7.0227799416,
      "tz": "Europe\/Amsterdam"
  },
  "EHTE": {
      "icao": "EHTE",
      "iata": "",
      "name": "Teuge Airport",
      "city": "Deventer",
      "state": "Gelderland",
      "country": "NL",
      "elevation": 17,
      "lat": 52.2447013855,
      "lon": 6.04666996,
      "tz": "Europe\/Amsterdam"
  },
  "EHTL": {
      "icao": "EHTL",
      "iata": "",
      "name": "Terlet Airport",
      "city": "Terlet",
      "state": "Gelderland",
      "country": "NL",
      "elevation": 276,
      "lat": 52.0572013855,
      "lon": 5.9244399071,
      "tz": "Europe\/Amsterdam"
  },
  "EHTW": {
      "icao": "EHTW",
      "iata": "ENS",
      "name": "Twenthe Airport",
      "city": "Enschede",
      "state": "Overijssel",
      "country": "NL",
      "elevation": 114,
      "lat": 52.2700004578,
      "lon": 6.8741698265,
      "tz": "Europe\/Amsterdam"
  },
  "EHTX": {
      "icao": "EHTX",
      "iata": "",
      "name": "Texel Airport",
      "city": "Texel",
      "state": "North-Holland",
      "country": "NL",
      "elevation": 2,
      "lat": 53.1152992249,
      "lon": 4.8336100578,
      "tz": "Europe\/Amsterdam"
  },
  "EHVK": {
      "icao": "EHVK",
      "iata": "UDE",
      "name": "Volkel Air Base",
      "city": "Uden",
      "state": "North-Brabant",
      "country": "NL",
      "elevation": 72,
      "lat": 51.6563987732,
      "lon": 5.7086100578,
      "tz": "Europe\/Amsterdam"
  },
  "EHWO": {
      "icao": "EHWO",
      "iata": "WOE",
      "name": "Woensdrecht Air Base",
      "city": "Bergen Op Zoom",
      "state": "North-Brabant",
      "country": "NL",
      "elevation": 63,
      "lat": 51.4491004944,
      "lon": 4.3420300484,
      "tz": "Europe\/Amsterdam"
  },
  "EIAB": {
      "icao": "EIAB",
      "iata": "",
      "name": "Abbeyshrule Aerodrome",
      "city": "Abbeyshrule",
      "state": "Leinster",
      "country": "IE",
      "elevation": 195,
      "lat": 53.5917015076,
      "lon": -7.6455597878,
      "tz": "Europe\/Dublin"
  },
  "EIBA": {
      "icao": "EIBA",
      "iata": "",
      "name": "Ballyboughal Aerodrome",
      "city": "Fingal",
      "state": "Leinster",
      "country": "IE",
      "elevation": 180,
      "lat": 53.5336990356,
      "lon": -6.2460699081,
      "tz": "Europe\/Dublin"
  },
  "EIBB": {
      "icao": "EIBB",
      "iata": "",
      "name": "Brittas Bay Airport",
      "city": "Brittas Bay",
      "state": "Leinster",
      "country": "IE",
      "elevation": 50,
      "lat": 52.8511009216,
      "lon": -6.0766401291,
      "tz": "Europe\/Dublin"
  },
  "EIBN": {
      "icao": "EIBN",
      "iata": "BYT",
      "name": "Bantry Aerodrome",
      "city": "Bantry",
      "state": "Munster",
      "country": "IE",
      "elevation": 7,
      "lat": 51.668598175,
      "lon": -9.48416996,
      "tz": "Europe\/Dublin"
  },
  "EIBR": {
      "icao": "EIBR",
      "iata": "",
      "name": "Birr Aerodrome",
      "city": "Birr",
      "state": "Leinster",
      "country": "IE",
      "elevation": 250,
      "lat": 53.0705986023,
      "lon": -7.8983302116,
      "tz": "Europe\/Dublin"
  },
  "EIBT": {
      "icao": "EIBT",
      "iata": "BLY",
      "name": "Belmullet Aerodrome",
      "city": "Belmullet",
      "state": "Connaught",
      "country": "IE",
      "elevation": 150,
      "lat": 54.2228012085,
      "lon": -10.0307998657,
      "tz": "Europe\/Dublin"
  },
  "EICA": {
      "icao": "EICA",
      "iata": "NNR",
      "name": "Connemara Regional Airport",
      "city": "Inverin",
      "state": "Connaught",
      "country": "IE",
      "elevation": 70,
      "lat": 53.2303009033,
      "lon": -9.4677801132,
      "tz": "Europe\/Dublin"
  },
  "EICB": {
      "icao": "EICB",
      "iata": "CLB",
      "name": "Castlebar Airport",
      "city": "Castlebar",
      "state": "Connaught",
      "country": "IE",
      "elevation": 0,
      "lat": 53.848400116,
      "lon": -9.2803697586,
      "tz": "Europe\/Dublin"
  },
  "EICD": {
      "icao": "EICD",
      "iata": "WEX",
      "name": "Castlebridge Airport",
      "city": "Wexford",
      "state": "Leinster",
      "country": "IE",
      "elevation": 79,
      "lat": 52.3333320618,
      "lon": -6.4666671753,
      "tz": "Europe\/Dublin"
  },
  "EICK": {
      "icao": "EICK",
      "iata": "ORK",
      "name": "Cork Airport",
      "city": "Cork",
      "state": "Munster",
      "country": "IE",
      "elevation": 502,
      "lat": 51.8413009644,
      "lon": -8.491109848,
      "tz": "Europe\/Dublin"
  },
  "EICL": {
      "icao": "EICL",
      "iata": "",
      "name": "Clonbullogue Aerodrome",
      "city": "Clonbullogue",
      "state": "Leinster",
      "country": "IE",
      "elevation": 240,
      "lat": 53.2496566772,
      "lon": -7.1227455139,
      "tz": "Europe\/Dublin"
  },
  "EICM": {
      "icao": "EICM",
      "iata": "GWY",
      "name": "Galway Airport",
      "city": "Galway",
      "state": "Connaught",
      "country": "IE",
      "elevation": 81,
      "lat": 53.300201416,
      "lon": -8.9415903091,
      "tz": "Europe\/Dublin"
  },
  "EICN": {
      "icao": "EICN",
      "iata": "",
      "name": "Coonagh Aerodrome",
      "city": "Limerick",
      "state": "Munster",
      "country": "IE",
      "elevation": 6,
      "lat": 52.6665000916,
      "lon": -8.6818304062,
      "tz": "Europe\/Dublin"
  },
  "EICS": {
      "icao": "EICS",
      "iata": "",
      "name": "Castleforbes Airport",
      "city": "Castleforbes",
      "state": "Leinster",
      "country": "IE",
      "elevation": 150,
      "lat": 53.778301239,
      "lon": -7.8352799416,
      "tz": "Europe\/Dublin"
  },
  "EIDL": {
      "icao": "EIDL",
      "iata": "CFN",
      "name": "Donegal Airport",
      "city": "Donegal",
      "state": "Ulster",
      "country": "IE",
      "elevation": 30,
      "lat": 55.0442008972,
      "lon": -8.3409996033,
      "tz": "Europe\/Dublin"
  },
  "EIDW": {
      "icao": "EIDW",
      "iata": "DUB",
      "name": "Dublin Airport",
      "city": "Dublin",
      "state": "Leinster",
      "country": "IE",
      "elevation": 242,
      "lat": 53.4212989807,
      "lon": -6.270070076,
      "tz": "Europe\/Dublin"
  },
  "EIER": {
      "icao": "EIER",
      "iata": "",
      "name": "Erinagh Airport",
      "city": "Nenagh",
      "state": "Munster",
      "country": "IE",
      "elevation": 155,
      "lat": 52.8125,
      "lon": -8.2822217941,
      "tz": "Europe\/Dublin"
  },
  "EIGN": {
      "icao": "EIGN",
      "iata": "",
      "name": "Gowran Grange Aerodrome",
      "city": "Swordlestown",
      "state": "Leinster",
      "country": "IE",
      "elevation": 489,
      "lat": 53.1786003113,
      "lon": -6.6352801323,
      "tz": "Europe\/Dublin"
  },
  "EIHN": {
      "icao": "EIHN",
      "iata": "",
      "name": "Hacketstown Aerodrome",
      "city": "Hacketstown",
      "state": "Leinster",
      "country": "IE",
      "elevation": 610,
      "lat": 52.8549995422,
      "lon": -6.5472202301,
      "tz": "Europe\/Dublin"
  },
  "EIIM": {
      "icao": "EIIM",
      "iata": "IOR",
      "name": "Inishmore Aerodrome",
      "city": "Inis Mor",
      "state": "Connaught",
      "country": "IE",
      "elevation": 24,
      "lat": 53.1067008972,
      "lon": -9.6536102295,
      "tz": "Europe\/Dublin"
  },
  "EIIR": {
      "icao": "EIIR",
      "iata": "INQ",
      "name": "Inisheer Aerodrome",
      "city": "Inis Oirr",
      "state": "Connaught",
      "country": "IE",
      "elevation": 40,
      "lat": 53.0647010803,
      "lon": -9.5108995438,
      "tz": "Europe\/Dublin"
  },
  "EIKH": {
      "icao": "EIKH",
      "iata": "",
      "name": "Kildare Aerodrome",
      "city": "Kilrush",
      "state": "Leinster",
      "country": "IE",
      "elevation": 260,
      "lat": 53.0635986328,
      "lon": -6.8552799225,
      "tz": "Europe\/Dublin"
  },
  "EIKI": {
      "icao": "EIKI",
      "iata": "",
      "name": "Killenaule Airport",
      "city": "Killenaule",
      "state": "Munster",
      "country": "IE",
      "elevation": 680,
      "lat": 52.6329994202,
      "lon": -7.632999897,
      "tz": "Europe\/Dublin"
  },
  "EIKK": {
      "icao": "EIKK",
      "iata": "KKY",
      "name": "Kilkenny Airport",
      "city": "Kilkenny",
      "state": "Leinster",
      "country": "IE",
      "elevation": 319,
      "lat": 52.6507987976,
      "lon": -7.2961101532,
      "tz": "Europe\/Dublin"
  },
  "EIKN": {
      "icao": "EIKN",
      "iata": "NOC",
      "name": "Ireland West Knock Airport",
      "city": "Charleston",
      "state": "Connaught",
      "country": "IE",
      "elevation": 665,
      "lat": 53.9103012085,
      "lon": -8.8184900284,
      "tz": "Europe\/Dublin"
  },
  "EIKY": {
      "icao": "EIKY",
      "iata": "KIR",
      "name": "Kerry Airport",
      "city": "Killarney",
      "state": "Munster",
      "country": "IE",
      "elevation": 112,
      "lat": 52.1809005737,
      "lon": -9.5237798691,
      "tz": "Europe\/Dublin"
  },
  "EILT": {
      "icao": "EILT",
      "iata": "LTR",
      "name": "Letterkenny Airport",
      "city": "Letterkenny",
      "state": "Ulster",
      "country": "IE",
      "elevation": 20,
      "lat": 54.9513015747,
      "lon": -7.6728301048,
      "tz": "Europe\/Dublin"
  },
  "EIME": {
      "icao": "EIME",
      "iata": "",
      "name": "Casement Air Base",
      "city": "Baldonnel",
      "state": "Leinster",
      "country": "IE",
      "elevation": 319,
      "lat": 53.301700592,
      "lon": -6.4513301849,
      "tz": "Europe\/Dublin"
  },
  "EIMG": {
      "icao": "EIMG",
      "iata": "",
      "name": "Moneygall Aerodrome",
      "city": "Moneygall",
      "state": "Munster",
      "country": "IE",
      "elevation": 450,
      "lat": 52.8499984741,
      "lon": -7.9829998016,
      "tz": "Europe\/Dublin"
  },
  "EIMH": {
      "icao": "EIMH",
      "iata": "",
      "name": "Athboy Airport",
      "city": "Athboy",
      "state": "Leinster",
      "country": "IE",
      "elevation": 230,
      "lat": 53.6377792358,
      "lon": -6.8786110878,
      "tz": "Europe\/Dublin"
  },
  "EIMN": {
      "icao": "EIMN",
      "iata": "IIA",
      "name": "Inishmaan Aerodrome",
      "city": "Inis Meain",
      "state": "Connaught",
      "country": "IE",
      "elevation": 15,
      "lat": 53.0929985046,
      "lon": -9.5680599213,
      "tz": "Europe\/Dublin"
  },
  "EIMY": {
      "icao": "EIMY",
      "iata": "",
      "name": "Moyne Aerodrome",
      "city": "Thurles",
      "state": "Munster",
      "country": "IE",
      "elevation": 400,
      "lat": 52.7030982971,
      "lon": -7.7052798271,
      "tz": "Europe\/Dublin"
  },
  "EINC": {
      "icao": "EINC",
      "iata": "",
      "name": "Newcastle Aerodrome",
      "city": "Newcastle",
      "state": "Leinster",
      "country": "IE",
      "elevation": 1,
      "lat": 53.0710983276,
      "lon": -6.0452799797,
      "tz": "Europe\/Dublin"
  },
  "EINN": {
      "icao": "EINN",
      "iata": "SNN",
      "name": "Shannon Airport",
      "city": "Shannon",
      "state": "Munster",
      "country": "IE",
      "elevation": 46,
      "lat": 52.7019996643,
      "lon": -8.9248199463,
      "tz": "Europe\/Dublin"
  },
  "EIPT": {
      "icao": "EIPT",
      "iata": "",
      "name": "Powerscourt Airfield",
      "city": "Powerscourt",
      "state": "Leinster",
      "country": "IE",
      "elevation": 470,
      "lat": 53.178301,
      "lon": -6.196495,
      "tz": "Europe\/Dublin"
  },
  "EIRT": {
      "icao": "EIRT",
      "iata": "",
      "name": "Rathcoole Aerodrome",
      "city": "Rathcoole",
      "state": "Munster",
      "country": "IE",
      "elevation": 281,
      "lat": 52.1055984497,
      "lon": -8.9833297729,
      "tz": "Europe\/Dublin"
  },
  "EISG": {
      "icao": "EISG",
      "iata": "SXL",
      "name": "Sligo Airport",
      "city": "Sligo",
      "state": "Connaught",
      "country": "IE",
      "elevation": 11,
      "lat": 54.2802009583,
      "lon": -8.5992097855,
      "tz": "Europe\/Dublin"
  },
  "EISP": {
      "icao": "EISP",
      "iata": "",
      "name": "Spanish Point Airport",
      "city": "Spanish Point",
      "state": "Munster",
      "country": "IE",
      "elevation": 0,
      "lat": 52.8497009277,
      "lon": -9.4169998169,
      "tz": "Europe\/Dublin"
  },
  "EITM": {
      "icao": "EITM",
      "iata": "",
      "name": "Trim Aerodrome",
      "city": "Trim",
      "state": "Leinster",
      "country": "IE",
      "elevation": 200,
      "lat": 53.5746994019,
      "lon": -6.7386097908,
      "tz": "Europe\/Dublin"
  },
  "EITT": {
      "icao": "EITT",
      "iata": "",
      "name": "Trevet Airfield",
      "city": "Dunshaughlin",
      "state": "Leinster",
      "country": "IE",
      "elevation": 385,
      "lat": 53.5486106873,
      "lon": -6.5169439316,
      "tz": "Europe\/Dublin"
  },
  "EIWF": {
      "icao": "EIWF",
      "iata": "WAT",
      "name": "Waterford Airport",
      "city": "Waterford",
      "state": "Munster",
      "country": "IE",
      "elevation": 119,
      "lat": 52.1871986389,
      "lon": -7.0869598389,
      "tz": "Europe\/Dublin"
  },
  "EIWT": {
      "icao": "EIWT",
      "iata": "",
      "name": "Weston Airport",
      "city": "Leixlip",
      "state": "Leinster",
      "country": "IE",
      "elevation": 150,
      "lat": 53.3521995544,
      "lon": -6.4861102104,
      "tz": "Europe\/Dublin"
  },
  "EK_2": {
      "icao": "EK_2",
      "iata": "",
      "name": "Femo Airfield",
      "city": "Femo",
      "state": "Zealand",
      "country": "DK",
      "elevation": 2,
      "lat": 54.9841151907,
      "lon": 11.5376615524,
      "tz": "Europe\/Copenhagen"
  },
  "EK_4": {
      "icao": "EK_4",
      "iata": "0",
      "name": "Vejro",
      "city": "",
      "state": "Zealand",
      "country": "DK",
      "elevation": 0,
      "lat": 55.035,
      "lon": 11.375,
      "tz": "Europe\/Copenhagen"
  },
  "EKAE": {
      "icao": "EKAE",
      "iata": "",
      "name": "AEro Airport",
      "city": "AEro",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 3,
      "lat": 54.8527984619,
      "lon": 10.4563999176,
      "tz": "Europe\/Copenhagen"
  },
  "EKAH": {
      "icao": "EKAH",
      "iata": "AAR",
      "name": "Aarhus Airport",
      "city": "Aarhus",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 82,
      "lat": 56.2999992371,
      "lon": 10.6190004349,
      "tz": "Europe\/Copenhagen"
  },
  "EKAT": {
      "icao": "EKAT",
      "iata": "",
      "name": "Anholt Airport",
      "city": "Anholt",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 23,
      "lat": 56.6988983154,
      "lon": 11.5558996201,
      "tz": "Europe\/Copenhagen"
  },
  "EKAV": {
      "icao": "EKAV",
      "iata": "",
      "name": "Avno Air Base",
      "city": "Avno",
      "state": "Zealand",
      "country": "DK",
      "elevation": 13,
      "lat": 55.0830001831,
      "lon": 11.7829999924,
      "tz": "Europe\/Copenhagen"
  },
  "EKBI": {
      "icao": "EKBI",
      "iata": "BLL",
      "name": "Billund Airport",
      "city": "Billund",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 247,
      "lat": 55.7402992249,
      "lon": 9.1517801285,
      "tz": "Europe\/Copenhagen"
  },
  "EKCH": {
      "icao": "EKCH",
      "iata": "CPH",
      "name": "Copenhagen Kastrup Airport",
      "city": "Copenhagen",
      "state": "Capital-Region",
      "country": "DK",
      "elevation": 17,
      "lat": 55.6179008484,
      "lon": 12.6560001373,
      "tz": "Europe\/Copenhagen"
  },
  "EKEB": {
      "icao": "EKEB",
      "iata": "EBJ",
      "name": "Esbjerg Airport",
      "city": "Esbjerg",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 97,
      "lat": 55.5259017944,
      "lon": 8.5534000397,
      "tz": "Europe\/Copenhagen"
  },
  "EKEL": {
      "icao": "EKEL",
      "iata": "",
      "name": "Endelave West Airport",
      "city": "Endelave",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 15,
      "lat": 55.7565002441,
      "lon": 10.2484998703,
      "tz": "Europe\/Copenhagen"
  },
  "EKFU": {
      "icao": "EKFU",
      "iata": "",
      "name": "Fur Airport",
      "city": "Fur",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 30,
      "lat": 56.8244018555,
      "lon": 8.9861097336,
      "tz": "Europe\/Copenhagen"
  },
  "EKGH": {
      "icao": "EKGH",
      "iata": "",
      "name": "Gronholt Hillerod Airport",
      "city": "Gronholt",
      "state": "Capital-Region",
      "country": "DK",
      "elevation": 97,
      "lat": 55.9413986206,
      "lon": 12.3822002411,
      "tz": "Europe\/Copenhagen"
  },
  "EKGR": {
      "icao": "EKGR",
      "iata": "",
      "name": "Grenaa Airport",
      "city": "Grenaa",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 20,
      "lat": 56.4425010681,
      "lon": 10.9306001663,
      "tz": "Europe\/Copenhagen"
  },
  "EKHG": {
      "icao": "EKHG",
      "iata": "",
      "name": "Herning Airport",
      "city": "Herning",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 167,
      "lat": 56.1847000122,
      "lon": 9.0444498062,
      "tz": "Europe\/Copenhagen"
  },
  "EKHK": {
      "icao": "EKHK",
      "iata": "",
      "name": "Holbaek (Ny Hagested) Airport",
      "city": "Holbaek",
      "state": "Zealand",
      "country": "DK",
      "elevation": 2,
      "lat": 55.73279953,
      "lon": 11.6028003693,
      "tz": "Europe\/Copenhagen"
  },
  "EKHO": {
      "icao": "EKHO",
      "iata": "",
      "name": "Lindtorp Airport",
      "city": "Holstebro",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 49,
      "lat": 56.3967018127,
      "lon": 8.4419403076,
      "tz": "Europe\/Copenhagen"
  },
  "EKHS": {
      "icao": "EKHS",
      "iata": "",
      "name": "Hadsund Airport",
      "city": "Hadsund",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 8,
      "lat": 56.7559242249,
      "lon": 10.2288293839,
      "tz": "Europe\/Copenhagen"
  },
  "EKHV": {
      "icao": "EKHV",
      "iata": "",
      "name": "Haderslev Airport",
      "city": "Haderslev",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 81,
      "lat": 55.3022003174,
      "lon": 9.5225000381,
      "tz": "Europe\/Copenhagen"
  },
  "EKKA": {
      "icao": "EKKA",
      "iata": "KRP",
      "name": "Karup Airport",
      "city": "Karup",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 170,
      "lat": 56.2975006104,
      "lon": 9.1246299744,
      "tz": "Europe\/Copenhagen"
  },
  "EKKL": {
      "icao": "EKKL",
      "iata": "",
      "name": "Kalundborg Airport",
      "city": "Kalundborg",
      "state": "Zealand",
      "country": "DK",
      "elevation": 1,
      "lat": 55.7002983093,
      "lon": 11.25,
      "tz": "Europe\/Copenhagen"
  },
  "EKKO": {
      "icao": "EKKO",
      "iata": "",
      "name": "Korsor Airport",
      "city": "Korsor",
      "state": "Zealand",
      "country": "DK",
      "elevation": 39,
      "lat": 55.3368988037,
      "lon": 11.2417001724,
      "tz": "Europe\/Copenhagen"
  },
  "EKLS": {
      "icao": "EKLS",
      "iata": "BYR",
      "name": "Laeso Airport",
      "city": "Laeso",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 25,
      "lat": 57.2771987915,
      "lon": 11.0001001358,
      "tz": "Europe\/Copenhagen"
  },
  "EKLV": {
      "icao": "EKLV",
      "iata": "",
      "name": "Lemvig Airport",
      "city": "Lemvig",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 97,
      "lat": 56.5029983521,
      "lon": 8.3113298416,
      "tz": "Europe\/Copenhagen"
  },
  "EKMB": {
      "icao": "EKMB",
      "iata": "MRW",
      "name": "Lolland Falster Maribo Airport",
      "city": "Lolland Falster \/ Maribo",
      "state": "Zealand",
      "country": "DK",
      "elevation": 16,
      "lat": 54.6992988586,
      "lon": 11.4400997162,
      "tz": "Europe\/Copenhagen"
  },
  "EKMN": {
      "icao": "EKMN",
      "iata": "",
      "name": "Kostervig Mon Airport",
      "city": "Koster",
      "state": "Zealand",
      "country": "DK",
      "elevation": 3,
      "lat": 54.9646987915,
      "lon": 12.1943998337,
      "tz": "Europe\/Copenhagen"
  },
  "EKNM": {
      "icao": "EKNM",
      "iata": "",
      "name": "Morso Airport Todso",
      "city": "Morso",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 53,
      "lat": 56.8244018555,
      "lon": 8.7866697311,
      "tz": "Europe\/Copenhagen"
  },
  "EKNS": {
      "icao": "EKNS",
      "iata": "",
      "name": "Nakskov Airport",
      "city": "Naksnov",
      "state": "Zealand",
      "country": "DK",
      "elevation": 3,
      "lat": 54.817199707,
      "lon": 11.1308002472,
      "tz": "Europe\/Copenhagen"
  },
  "EKOD": {
      "icao": "EKOD",
      "iata": "ODE",
      "name": "Odense Airport",
      "city": "Odense",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 56,
      "lat": 55.4766998291,
      "lon": 10.3309001923,
      "tz": "Europe\/Copenhagen"
  },
  "EKPB": {
      "icao": "EKPB",
      "iata": "",
      "name": "Krusa-Padborg Airport",
      "city": "Krusa \/ Padborg",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 88,
      "lat": 54.870300293,
      "lon": 9.279009819,
      "tz": "Europe\/Copenhagen"
  },
  "EKRA": {
      "icao": "EKRA",
      "iata": "",
      "name": "Rarup Airport",
      "city": "Rarup",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 131,
      "lat": 55.7785987854,
      "lon": 9.9388904572,
      "tz": "Europe\/Copenhagen"
  },
  "EKRD": {
      "icao": "EKRD",
      "iata": "",
      "name": "Randers Airport",
      "city": "Randers",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 139,
      "lat": 56.5065994263,
      "lon": 10.0363998413,
      "tz": "Europe\/Copenhagen"
  },
  "EKRK": {
      "icao": "EKRK",
      "iata": "RKE",
      "name": "Copenhagen Roskilde Airport",
      "city": "Copenhagen",
      "state": "Zealand",
      "country": "DK",
      "elevation": 146,
      "lat": 55.5856018066,
      "lon": 12.1314001083,
      "tz": "Europe\/Copenhagen"
  },
  "EKRN": {
      "icao": "EKRN",
      "iata": "RNN",
      "name": "Bornholm Airport",
      "city": "Ronne",
      "state": "Capital-Region",
      "country": "DK",
      "elevation": 52,
      "lat": 55.0633010864,
      "lon": 14.7595996857,
      "tz": "Europe\/Copenhagen"
  },
  "EKRS": {
      "icao": "EKRS",
      "iata": "",
      "name": "Ringsted Airport",
      "city": "Ringsted",
      "state": "Zealand",
      "country": "DK",
      "elevation": 113,
      "lat": 55.4258003235,
      "lon": 11.8066997528,
      "tz": "Europe\/Copenhagen"
  },
  "EKSA": {
      "icao": "EKSA",
      "iata": "",
      "name": "Saeby (Ottestrup) Airport",
      "city": "Saeby",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 110,
      "lat": 57.346698761,
      "lon": 10.406999588,
      "tz": "Europe\/Copenhagen"
  },
  "EKSB": {
      "icao": "EKSB",
      "iata": "SGD",
      "name": "Sonderborg Airport",
      "city": "Sonderborg",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 24,
      "lat": 54.9644012451,
      "lon": 9.7917299271,
      "tz": "Europe\/Copenhagen"
  },
  "EKSD": {
      "icao": "EKSD",
      "iata": "",
      "name": "Spjald Airport",
      "city": "Spjald",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 249,
      "lat": 56.1026992798,
      "lon": 8.5142297745,
      "tz": "Europe\/Copenhagen"
  },
  "EKSN": {
      "icao": "EKSN",
      "iata": "CNL",
      "name": "Sindal Airport",
      "city": "Sindal",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 92,
      "lat": 57.5035018921,
      "lon": 10.2293996811,
      "tz": "Europe\/Copenhagen"
  },
  "EKSP": {
      "icao": "EKSP",
      "iata": "SKS",
      "name": "Vojens Skrydstrup Airport",
      "city": "Vojens",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 141,
      "lat": 55.2256011963,
      "lon": 9.2639303207,
      "tz": "Europe\/Copenhagen"
  },
  "EKSS": {
      "icao": "EKSS",
      "iata": "",
      "name": "Samso Airport",
      "city": "Samso",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 1,
      "lat": 55.8894996643,
      "lon": 10.613699913,
      "tz": "Europe\/Copenhagen"
  },
  "EKST": {
      "icao": "EKST",
      "iata": "",
      "name": "Sydfy (Tasinge) Airport",
      "city": "Tasinge",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 7,
      "lat": 55.0166015625,
      "lon": 10.5633001328,
      "tz": "Europe\/Copenhagen"
  },
  "EKSV": {
      "icao": "EKSV",
      "iata": "SQW",
      "name": "Skive Airport",
      "city": "Skive",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 74,
      "lat": 56.550201416,
      "lon": 9.1729803085,
      "tz": "Europe\/Copenhagen"
  },
  "EKTD": {
      "icao": "EKTD",
      "iata": "",
      "name": "Tonder Airport",
      "city": "Tonder",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 1,
      "lat": 54.9296989441,
      "lon": 8.8405704498,
      "tz": "Europe\/Berlin"
  },
  "EKTS": {
      "icao": "EKTS",
      "iata": "TED",
      "name": "Thisted Airport",
      "city": "Thisted",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 23,
      "lat": 57.0688018799,
      "lon": 8.7052202225,
      "tz": "Europe\/Copenhagen"
  },
  "EKVA": {
      "icao": "EKVA",
      "iata": "",
      "name": "Vandel Air Base",
      "city": "Vandel",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 250,
      "lat": 55.7010002136,
      "lon": 9.2135801315,
      "tz": "Europe\/Copenhagen"
  },
  "EKVB": {
      "icao": "EKVB",
      "iata": "",
      "name": "Viborg Airport",
      "city": "Viborg",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 75,
      "lat": 56.4099998474,
      "lon": 9.4090995789,
      "tz": "Europe\/Copenhagen"
  },
  "EKVD": {
      "icao": "EKVD",
      "iata": "",
      "name": "Kolding Vamdrup Airport",
      "city": "Kolding \/ Vamdrup",
      "state": "South-Denmark",
      "country": "DK",
      "elevation": 143,
      "lat": 55.4362983704,
      "lon": 9.3309202194,
      "tz": "Europe\/Copenhagen"
  },
  "EKVG": {
      "icao": "EKVG",
      "iata": "FAE",
      "name": "Vagar Airport",
      "city": "Vagar",
      "state": "Vagar",
      "country": "FO",
      "elevation": 280,
      "lat": 62.0635986328,
      "lon": -7.2772197723,
      "tz": "Atlantic\/Faroe"
  },
  "EKVH": {
      "icao": "EKVH",
      "iata": "",
      "name": "Aars Airport",
      "city": "Aars",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 119,
      "lat": 56.8469009399,
      "lon": 9.458609581,
      "tz": "Europe\/Copenhagen"
  },
  "EKVJ": {
      "icao": "EKVJ",
      "iata": "STA",
      "name": "Stauning Airport",
      "city": "Skjern \/ Ringkobing",
      "state": "Central-Jutland",
      "country": "DK",
      "elevation": 17,
      "lat": 55.9901008606,
      "lon": 8.3539104462,
      "tz": "Europe\/Copenhagen"
  },
  "EKYT": {
      "icao": "EKYT",
      "iata": "AAL",
      "name": "Aalborg Airport",
      "city": "Aalborg",
      "state": "North-Denmark",
      "country": "DK",
      "elevation": 10,
      "lat": 57.0927589138,
      "lon": 9.8492431641,
      "tz": "Europe\/Copenhagen"
  },
  "ELLX": {
      "icao": "ELLX",
      "iata": "LUX",
      "name": "Luxembourg-Findel International Airport",
      "city": "Luxembourg",
      "state": "Luxembourg",
      "country": "LU",
      "elevation": 1234,
      "lat": 49.6265983582,
      "lon": 6.211520195,
      "tz": "Europe\/Luxembourg"
  },
  "ELNT": {
      "icao": "ELNT",
      "iata": "",
      "name": "Noertrange Airport",
      "city": "Winseler",
      "state": "Wiltz",
      "country": "LU",
      "elevation": 1522,
      "lat": 49.981098175,
      "lon": 5.9177799225,
      "tz": "Europe\/Luxembourg"
  },
  "ELUS": {
      "icao": "ELUS",
      "iata": "",
      "name": "Useldange Airfield",
      "city": "Useldange",
      "state": "Redange",
      "country": "LU",
      "elevation": 928,
      "lat": 49.7686004639,
      "lon": 5.9655599594,
      "tz": "Europe\/Luxembourg"
  },
  "ENAE": {
      "icao": "ENAE",
      "iata": "",
      "name": "AEra Airfield",
      "city": "Amot",
      "state": "Hedmark",
      "country": "NO",
      "elevation": 1621,
      "lat": 61.2574005127,
      "lon": 11.6688995361,
      "tz": "Europe\/Oslo"
  },
  "ENAL": {
      "icao": "ENAL",
      "iata": "AES",
      "name": "Alesund Airport",
      "city": "Alesund",
      "state": "More-og-Romsdal",
      "country": "NO",
      "elevation": 69,
      "lat": 62.5625,
      "lon": 6.119699955,
      "tz": "Europe\/Oslo"
  },
  "ENAN": {
      "icao": "ENAN",
      "iata": "ANX",
      "name": "Andoya Airport",
      "city": "Andenes",
      "state": "Nordland",
      "country": "NO",
      "elevation": 43,
      "lat": 69.2925033569,
      "lon": 16.1441993713,
      "tz": "Europe\/Oslo"
  },
  "ENAS": {
      "icao": "ENAS",
      "iata": "",
      "name": "Ny-Alesund Airport Hamnerabben",
      "city": "Ny-Alesund",
      "state": "Svalbard",
      "country": "NO",
      "elevation": 50,
      "lat": 78.9274978638,
      "lon": 11.8743000031,
      "tz": "Arctic\/Longyearbyen"
  },
  "ENAT": {
      "icao": "ENAT",
      "iata": "ALF",
      "name": "Alta Airport",
      "city": "Alta",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 9,
      "lat": 69.9760971069,
      "lon": 23.3717002869,
      "tz": "Europe\/Oslo"
  },
  "ENBL": {
      "icao": "ENBL",
      "iata": "FDE",
      "name": "Bringeland Airport",
      "city": "Forde",
      "state": "Sogn-og-Fjordane",
      "country": "NO",
      "elevation": 1046,
      "lat": 61.3911018372,
      "lon": 5.756939888,
      "tz": "Europe\/Oslo"
  },
  "ENBM": {
      "icao": "ENBM",
      "iata": "",
      "name": "Bomoen Airport",
      "city": "Tjukkebygdi",
      "state": "Hordaland",
      "country": "NO",
      "elevation": 300,
      "lat": 60.6389007568,
      "lon": 6.5015001297,
      "tz": "Europe\/Oslo"
  },
  "ENBN": {
      "icao": "ENBN",
      "iata": "BNN",
      "name": "Bronnoysund Airport",
      "city": "Bronnoy",
      "state": "Nordland",
      "country": "NO",
      "elevation": 25,
      "lat": 65.4610977173,
      "lon": 12.217499733,
      "tz": "Europe\/Oslo"
  },
  "ENBO": {
      "icao": "ENBO",
      "iata": "BOO",
      "name": "Bodo Airport",
      "city": "Bodo",
      "state": "Nordland",
      "country": "NO",
      "elevation": 42,
      "lat": 67.269203186,
      "lon": 14.3653001785,
      "tz": "Europe\/Oslo"
  },
  "ENBR": {
      "icao": "ENBR",
      "iata": "BGO",
      "name": "Bergen Airport Flesland",
      "city": "Bergen",
      "state": "Hordaland",
      "country": "NO",
      "elevation": 170,
      "lat": 60.2933998108,
      "lon": 5.2181401253,
      "tz": "Europe\/Oslo"
  },
  "ENBS": {
      "icao": "ENBS",
      "iata": "BJF",
      "name": "Batsfjord Airport",
      "city": "Batsfjord",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 490,
      "lat": 70.6005020142,
      "lon": 29.691400528,
      "tz": "Europe\/Oslo"
  },
  "ENBV": {
      "icao": "ENBV",
      "iata": "BVG",
      "name": "Berlevag Airport",
      "city": "Berlevag",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 42,
      "lat": 70.8713989258,
      "lon": 29.0342006683,
      "tz": "Europe\/Oslo"
  },
  "ENCN": {
      "icao": "ENCN",
      "iata": "KRS",
      "name": "Kristiansand Airport",
      "city": "Kjevik",
      "state": "Vest-Agder",
      "country": "NO",
      "elevation": 57,
      "lat": 58.2042007446,
      "lon": 8.0853700638,
      "tz": "Europe\/Oslo"
  },
  "ENDI": {
      "icao": "ENDI",
      "iata": "DLD",
      "name": "Geilo Airport Dagali",
      "city": "Dagali",
      "state": "Buskerud",
      "country": "NO",
      "elevation": 2618,
      "lat": 60.417301178,
      "lon": 8.5183496475,
      "tz": "Europe\/Oslo"
  },
  "ENDO": {
      "icao": "ENDO",
      "iata": "",
      "name": "Dokka Thomlevold Airport",
      "city": "Dokka",
      "state": "Oppland",
      "country": "NO",
      "elevation": 512,
      "lat": 60.8333015442,
      "lon": 9.9166698456,
      "tz": "Europe\/Oslo"
  },
  "ENDU": {
      "icao": "ENDU",
      "iata": "BDU",
      "name": "Bardufoss Airport",
      "city": "Malselv",
      "state": "Troms",
      "country": "NO",
      "elevation": 252,
      "lat": 69.0558013916,
      "lon": 18.5403995514,
      "tz": "Europe\/Oslo"
  },
  "ENEG": {
      "icao": "ENEG",
      "iata": "",
      "name": "Eggemoen Airport",
      "city": "Honefoss",
      "state": "Buskerud",
      "country": "NO",
      "elevation": 656,
      "lat": 60.217300415,
      "lon": 10.3239002228,
      "tz": "Europe\/Oslo"
  },
  "ENEV": {
      "icao": "ENEV",
      "iata": "EVE",
      "name": "Harstad\/Narvik Airport Evenes",
      "city": "Evenes",
      "state": "Nordland",
      "country": "NO",
      "elevation": 84,
      "lat": 68.4913024902,
      "lon": 16.6781005859,
      "tz": "Europe\/Oslo"
  },
  "ENFA": {
      "icao": "ENFA",
      "iata": "",
      "name": "Flatval Airport",
      "city": "Froya",
      "state": "Sor-Trondelag",
      "country": "NO",
      "elevation": 152,
      "lat": 63.7005996704,
      "lon": 8.7605695724,
      "tz": "Europe\/Oslo"
  },
  "ENFG": {
      "icao": "ENFG",
      "iata": "VDB",
      "name": "Leirin Airport",
      "city": "",
      "state": "Oppland",
      "country": "NO",
      "elevation": 2697,
      "lat": 61.0155982971,
      "lon": 9.2880601883,
      "tz": "Europe\/Oslo"
  },
  "ENFL": {
      "icao": "ENFL",
      "iata": "FRO",
      "name": "Floro Airport",
      "city": "Floro",
      "state": "Sogn-og-Fjordane",
      "country": "NO",
      "elevation": 37,
      "lat": 61.5835990906,
      "lon": 5.024720192,
      "tz": "Europe\/Oslo"
  },
  "ENFY": {
      "icao": "ENFY",
      "iata": "",
      "name": "Fyresdal Airfield",
      "city": "Fyresdal",
      "state": "Telemark",
      "country": "NO",
      "elevation": 986,
      "lat": 59.2000007629,
      "lon": 8.0866699219,
      "tz": "Europe\/Oslo"
  },
  "ENGK": {
      "icao": "ENGK",
      "iata": "",
      "name": "Gullknapp Flpl Airport",
      "city": "",
      "state": "Aust-Agder",
      "country": "NO",
      "elevation": 390,
      "lat": 58.5167007446,
      "lon": 8.6999998093,
      "tz": "Europe\/Oslo"
  },
  "ENGM": {
      "icao": "ENGM",
      "iata": "OSL",
      "name": "Oslo Gardermoen Airport",
      "city": "Oslo",
      "state": "Akershus",
      "country": "NO",
      "elevation": 681,
      "lat": 60.193901062,
      "lon": 11.100399971,
      "tz": "Europe\/Oslo"
  },
  "ENGN": {
      "icao": "ENGN",
      "iata": "",
      "name": "Folldal Grimsmoe Airport",
      "city": "Folldal",
      "state": "Hedmark",
      "country": "NO",
      "elevation": 2260,
      "lat": 62.1175003052,
      "lon": 10.1107997894,
      "tz": "Europe\/Oslo"
  },
  "ENGS": {
      "icao": "ENGS",
      "iata": "",
      "name": "Snasa Airfield Gronora",
      "city": "Snasa",
      "state": "Nord-Trondelag",
      "country": "NO",
      "elevation": 89,
      "lat": 64.1835861206,
      "lon": 12.1714782715,
      "tz": "Europe\/Oslo"
  },
  "ENHA": {
      "icao": "ENHA",
      "iata": "HMR",
      "name": "Stafsberg Airport",
      "city": "Hamar",
      "state": "Hedmark",
      "country": "NO",
      "elevation": 713,
      "lat": 60.8180999756,
      "lon": 11.0679998398,
      "tz": "Europe\/Oslo"
  },
  "ENHD": {
      "icao": "ENHD",
      "iata": "HAU",
      "name": "Haugesund Airport",
      "city": "Karmoy",
      "state": "Rogaland",
      "country": "NO",
      "elevation": 86,
      "lat": 59.3452987671,
      "lon": 5.2083601952,
      "tz": "Europe\/Oslo"
  },
  "ENHF": {
      "icao": "ENHF",
      "iata": "HFT",
      "name": "Hammerfest Airport",
      "city": "Hammerfest",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 266,
      "lat": 70.6797027588,
      "lon": 23.6686000824,
      "tz": "Europe\/Oslo"
  },
  "ENHK": {
      "icao": "ENHK",
      "iata": "HAA",
      "name": "Hasvik Airport",
      "city": "Hasvik",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 21,
      "lat": 70.4867019653,
      "lon": 22.1396999359,
      "tz": "Europe\/Oslo"
  },
  "ENHN": {
      "icao": "ENHN",
      "iata": "",
      "name": "Elverum Starmoen Airport",
      "city": "",
      "state": "Hedmark",
      "country": "NO",
      "elevation": 659,
      "lat": 60.8800010681,
      "lon": 11.6731004715,
      "tz": "Europe\/Oslo"
  },
  "ENHS": {
      "icao": "ENHS",
      "iata": "",
      "name": "Hokksund Airfield",
      "city": "",
      "state": "Buskerud",
      "country": "NO",
      "elevation": 30,
      "lat": 59.7668991089,
      "lon": 9.9113903046,
      "tz": "Europe\/Oslo"
  },
  "ENHT": {
      "icao": "ENHT",
      "iata": "",
      "name": "Hattfjelldal Airport",
      "city": "Hattfjelldal",
      "state": "Nordland",
      "country": "NO",
      "elevation": 689,
      "lat": 65.5947036743,
      "lon": 13.9891996384,
      "tz": "Europe\/Oslo"
  },
  "ENHV": {
      "icao": "ENHV",
      "iata": "HVG",
      "name": "Valan Airport",
      "city": "Honningsvag",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 44,
      "lat": 71.0096969604,
      "lon": 25.9836006165,
      "tz": "Europe\/Oslo"
  },
  "ENJA": {
      "icao": "ENJA",
      "iata": "",
      "name": "Jan Mayen Jan Mayensfield",
      "city": "",
      "state": "Jan-Mayen",
      "country": "NO",
      "elevation": 39,
      "lat": 70.9611111111,
      "lon": -8.5758333333,
      "tz": "Arctic\/Longyearbyen"
  },
  "ENJB": {
      "icao": "ENJB",
      "iata": "",
      "name": "Jarlsberg Airfield",
      "city": "Tonsberg",
      "state": "Vestfold",
      "country": "NO",
      "elevation": 92,
      "lat": 59.2999992371,
      "lon": 10.3667001724,
      "tz": "Europe\/Oslo"
  },
  "ENKA": {
      "icao": "ENKA",
      "iata": "QKX",
      "name": "Kautokeino Air Base",
      "city": "",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 1165,
      "lat": 69.0402984619,
      "lon": 23.0340003967,
      "tz": "Europe\/Oslo"
  },
  "ENKB": {
      "icao": "ENKB",
      "iata": "KSU",
      "name": "Kristiansund Airport Kvernberget",
      "city": "Kvernberget",
      "state": "More-og-Romsdal",
      "country": "NO",
      "elevation": 204,
      "lat": 63.1118011475,
      "lon": 7.8245201111,
      "tz": "Europe\/Oslo"
  },
  "ENKJ": {
      "icao": "ENKJ",
      "iata": "",
      "name": "Kjeller Airport",
      "city": "Kjeller",
      "state": "Akershus",
      "country": "NO",
      "elevation": 354,
      "lat": 59.9692993164,
      "lon": 11.0361003876,
      "tz": "Europe\/Oslo"
  },
  "ENKL": {
      "icao": "ENKL",
      "iata": "GLL",
      "name": "Gol Airport",
      "city": "Klanten",
      "state": "Buskerud",
      "country": "NO",
      "elevation": 2720,
      "lat": 60.7914009094,
      "lon": 9.0505599976,
      "tz": "Europe\/Oslo"
  },
  "ENKR": {
      "icao": "ENKR",
      "iata": "KKN",
      "name": "Kirkenes Airport Hoybuktmoen",
      "city": "Kirkenes",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 283,
      "lat": 69.7257995605,
      "lon": 29.8913002014,
      "tz": "Europe\/Oslo"
  },
  "ENLI": {
      "icao": "ENLI",
      "iata": "FAN",
      "name": "Lista Airport",
      "city": "Farsund",
      "state": "Vest-Agder",
      "country": "NO",
      "elevation": 29,
      "lat": 58.0994987488,
      "lon": 6.6260499954,
      "tz": "Europe\/Oslo"
  },
  "ENLK": {
      "icao": "ENLK",
      "iata": "LKN",
      "name": "Leknes Airport",
      "city": "Leknes",
      "state": "Nordland",
      "country": "NO",
      "elevation": 78,
      "lat": 68.1524963379,
      "lon": 13.6093997955,
      "tz": "Europe\/Oslo"
  },
  "ENLU": {
      "icao": "ENLU",
      "iata": "",
      "name": "Lunde Nome Airfield",
      "city": "Lunde Nome",
      "state": "Telemark",
      "country": "NO",
      "elevation": 190,
      "lat": 59.2980995178,
      "lon": 9.1327295303,
      "tz": "Europe\/Oslo"
  },
  "ENMH": {
      "icao": "ENMH",
      "iata": "MEH",
      "name": "Mehamn Airport",
      "city": "Mehamn",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 39,
      "lat": 71.0297012329,
      "lon": 27.8267002106,
      "tz": "Europe\/Oslo"
  },
  "ENML": {
      "icao": "ENML",
      "iata": "MOL",
      "name": "Molde Airport",
      "city": "Aro",
      "state": "More-og-Romsdal",
      "country": "NO",
      "elevation": 10,
      "lat": 62.7447013855,
      "lon": 7.2624998093,
      "tz": "Europe\/Oslo"
  },
  "ENMS": {
      "icao": "ENMS",
      "iata": "MJF",
      "name": "Mosjoen Airport Kjaerstad",
      "city": "",
      "state": "Nordland",
      "country": "NO",
      "elevation": 237,
      "lat": 65.783996582,
      "lon": 13.2149000168,
      "tz": "Europe\/Oslo"
  },
  "ENNA": {
      "icao": "ENNA",
      "iata": "LKL",
      "name": "Banak Airport",
      "city": "Lakselv",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 25,
      "lat": 70.0688018799,
      "lon": 24.9734992981,
      "tz": "Europe\/Oslo"
  },
  "ENNK": {
      "icao": "ENNK",
      "iata": "NVK",
      "name": "Narvik Framnes Airport",
      "city": "Narvik",
      "state": "Nordland",
      "country": "NO",
      "elevation": 95,
      "lat": 68.4368972778,
      "lon": 17.3866996765,
      "tz": "Europe\/Oslo"
  },
  "ENNM": {
      "icao": "ENNM",
      "iata": "OSY",
      "name": "Namsos Hoknesora Airport",
      "city": "Namsos",
      "state": "Nord-Trondelag",
      "country": "NO",
      "elevation": 7,
      "lat": 64.4721984863,
      "lon": 11.5785999298,
      "tz": "Europe\/Oslo"
  },
  "ENNO": {
      "icao": "ENNO",
      "iata": "NTB",
      "name": "Notodden Airport",
      "city": "",
      "state": "Telemark",
      "country": "NO",
      "elevation": 63,
      "lat": 59.565700531,
      "lon": 9.212220192,
      "tz": "Europe\/Oslo"
  },
  "ENOL": {
      "icao": "ENOL",
      "iata": "OLA",
      "name": "Orland Airport",
      "city": "Orland",
      "state": "Sor-Trondelag",
      "country": "NO",
      "elevation": 28,
      "lat": 63.6988983154,
      "lon": 9.6040000916,
      "tz": "Europe\/Oslo"
  },
  "ENOP": {
      "icao": "ENOP",
      "iata": "",
      "name": "Fagerhaug Airport",
      "city": "",
      "state": "Sor-Trondelag",
      "country": "NO",
      "elevation": 1830,
      "lat": 62.6498985291,
      "lon": 9.846739769,
      "tz": "Europe\/Oslo"
  },
  "ENOV": {
      "icao": "ENOV",
      "iata": "HOV",
      "name": "Orsta-Volda Airport Hovden",
      "city": "Orsta",
      "state": "More-og-Romsdal",
      "country": "NO",
      "elevation": 243,
      "lat": 62.1800003052,
      "lon": 6.0741000175,
      "tz": "Europe\/Oslo"
  },
  "ENRA": {
      "icao": "ENRA",
      "iata": "MQN",
      "name": "Mo i Rana Airport Rossvoll",
      "city": "Mo i Rana",
      "state": "Nordland",
      "country": "NO",
      "elevation": 229,
      "lat": 66.363899231,
      "lon": 14.3014001846,
      "tz": "Europe\/Oslo"
  },
  "ENRG": {
      "icao": "ENRG",
      "iata": "",
      "name": "Rognan Airport",
      "city": "Saltdal",
      "state": "Nordland",
      "country": "NO",
      "elevation": 20,
      "lat": 67.0982971191,
      "lon": 15.4111003876,
      "tz": "Europe\/Oslo"
  },
  "ENRI": {
      "icao": "ENRI",
      "iata": "",
      "name": "Ringebu Airfield Frya",
      "city": "Frya",
      "state": "Oppland",
      "country": "NO",
      "elevation": 571,
      "lat": 61.5454406738,
      "lon": 10.0615882874,
      "tz": "Europe\/Oslo"
  },
  "ENRK": {
      "icao": "ENRK",
      "iata": "",
      "name": "Rakkestad Astorp Airport",
      "city": "",
      "state": "Ostfold",
      "country": "NO",
      "elevation": 381,
      "lat": 59.3974990845,
      "lon": 11.3468999863,
      "tz": "Europe\/Oslo"
  },
  "ENRM": {
      "icao": "ENRM",
      "iata": "RVK",
      "name": "Rorvik Airport Ryum",
      "city": "Rorvik",
      "state": "Nord-Trondelag",
      "country": "NO",
      "elevation": 14,
      "lat": 64.8383026123,
      "lon": 11.1461000443,
      "tz": "Europe\/Oslo"
  },
  "ENRO": {
      "icao": "ENRO",
      "iata": "RRS",
      "name": "Roros Airport",
      "city": "Roros",
      "state": "Sor-Trondelag",
      "country": "NO",
      "elevation": 2054,
      "lat": 62.5783996582,
      "lon": 11.342300415,
      "tz": "Europe\/Oslo"
  },
  "ENRS": {
      "icao": "ENRS",
      "iata": "RET",
      "name": "Rost Airport",
      "city": "",
      "state": "Nordland",
      "country": "NO",
      "elevation": 7,
      "lat": 67.5278015137,
      "lon": 12.1033000946,
      "tz": "Europe\/Oslo"
  },
  "ENRV": {
      "icao": "ENRV",
      "iata": "",
      "name": "Reinsvoll Airport",
      "city": "Reinsvoll",
      "state": "Oppland",
      "country": "NO",
      "elevation": 1381,
      "lat": 60.6721992493,
      "lon": 10.5675001144,
      "tz": "Europe\/Oslo"
  },
  "ENRY": {
      "icao": "ENRY",
      "iata": "RYG",
      "name": "Moss Airport Rygge",
      "city": "Rygge",
      "state": "Ostfold",
      "country": "NO",
      "elevation": 174,
      "lat": 59.3788986206,
      "lon": 10.7855997086,
      "tz": "Europe\/Oslo"
  },
  "ENSA": {
      "icao": "ENSA",
      "iata": "SRP",
      "name": "Svea Airport",
      "city": "Svea",
      "state": "Svalbard",
      "country": "NO",
      "elevation": 29,
      "lat": 77.9000015259,
      "lon": 16.6833000183,
      "tz": "Arctic\/Longyearbyen"
  },
  "ENSB": {
      "icao": "ENSB",
      "iata": "LYR",
      "name": "Svalbard Airport Longyear",
      "city": "Longyearbyen",
      "state": "Svalbard",
      "country": "NO",
      "elevation": 88,
      "lat": 78.2461013794,
      "lon": 15.4656000137,
      "tz": "Arctic\/Longyearbyen"
  },
  "ENSD": {
      "icao": "ENSD",
      "iata": "SDN",
      "name": "Sandane Airport Anda",
      "city": "Sandane",
      "state": "Sogn-og-Fjordane",
      "country": "NO",
      "elevation": 196,
      "lat": 61.8300018311,
      "lon": 6.1058301926,
      "tz": "Europe\/Oslo"
  },
  "ENSG": {
      "icao": "ENSG",
      "iata": "SOG",
      "name": "Sogndal Airport",
      "city": "Sogndal",
      "state": "Sogn-og-Fjordane",
      "country": "NO",
      "elevation": 0,
      "lat": 61.1561012268,
      "lon": 7.1377801895,
      "tz": "Europe\/Oslo"
  },
  "ENSH": {
      "icao": "ENSH",
      "iata": "SVJ",
      "name": "Svolvaer Helle Airport",
      "city": "Svolvaer",
      "state": "Nordland",
      "country": "NO",
      "elevation": 27,
      "lat": 68.2433013916,
      "lon": 14.6691999435,
      "tz": "Europe\/Oslo"
  },
  "ENSI": {
      "icao": "ENSI",
      "iata": "",
      "name": "Ski Airfield",
      "city": "Ski",
      "state": "Akershus",
      "country": "NO",
      "elevation": 350,
      "lat": 59.7063903809,
      "lon": 10.8819446564,
      "tz": "Europe\/Oslo"
  },
  "ENSK": {
      "icao": "ENSK",
      "iata": "SKN",
      "name": "Stokmarknes Skagen Airport",
      "city": "Hadsel",
      "state": "Nordland",
      "country": "NO",
      "elevation": 11,
      "lat": 68.5788269043,
      "lon": 15.033416748,
      "tz": "Europe\/Oslo"
  },
  "ENSN": {
      "icao": "ENSN",
      "iata": "SKE",
      "name": "Skien Airport",
      "city": "Geiteryggen",
      "state": "Telemark",
      "country": "NO",
      "elevation": 463,
      "lat": 59.1850013733,
      "lon": 9.5669403076,
      "tz": "Europe\/Oslo"
  },
  "ENSO": {
      "icao": "ENSO",
      "iata": "SRP",
      "name": "Stord Airport",
      "city": "Leirvik",
      "state": "Hordaland",
      "country": "NO",
      "elevation": 160,
      "lat": 59.7919006348,
      "lon": 5.3408498764,
      "tz": "Europe\/Oslo"
  },
  "ENSR": {
      "icao": "ENSR",
      "iata": "SOJ",
      "name": "Sorkjosen Airport",
      "city": "Sorkjosen",
      "state": "Troms",
      "country": "NO",
      "elevation": 16,
      "lat": 69.7867965698,
      "lon": 20.959400177,
      "tz": "Europe\/Oslo"
  },
  "ENSS": {
      "icao": "ENSS",
      "iata": "VAW",
      "name": "Vardo Airport Svartnes",
      "city": "Vardo",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 42,
      "lat": 70.3554000854,
      "lon": 31.0449008942,
      "tz": "Europe\/Oslo"
  },
  "ENST": {
      "icao": "ENST",
      "iata": "SSJ",
      "name": "Sandnessjoen Airport Stokka",
      "city": "Alstahaug",
      "state": "Nordland",
      "country": "NO",
      "elevation": 56,
      "lat": 65.9568023682,
      "lon": 12.4688997269,
      "tz": "Europe\/Oslo"
  },
  "ENSU": {
      "icao": "ENSU",
      "iata": "",
      "name": "Vinnu Airport",
      "city": "Sunndalsora",
      "state": "More-og-Romsdal",
      "country": "NO",
      "elevation": 249,
      "lat": 62.6582984924,
      "lon": 8.6649999619,
      "tz": "Europe\/Oslo"
  },
  "ENTC": {
      "icao": "ENTC",
      "iata": "TOS",
      "name": "Tromso Airport",
      "city": "Tromso",
      "state": "Troms",
      "country": "NO",
      "elevation": 31,
      "lat": 69.6832962036,
      "lon": 18.9188995361,
      "tz": "Europe\/Oslo"
  },
  "ENTO": {
      "icao": "ENTO",
      "iata": "TRF",
      "name": "Sandefjord Airport Torp",
      "city": "Torp",
      "state": "Vestfold",
      "country": "NO",
      "elevation": 286,
      "lat": 59.1866989136,
      "lon": 10.258600235,
      "tz": "Europe\/Oslo"
  },
  "ENTS": {
      "icao": "ENTS",
      "iata": "",
      "name": "Trysil Saeterasen Airport",
      "city": "",
      "state": "Hedmark",
      "country": "NO",
      "elevation": 1900,
      "lat": 61.2292785645,
      "lon": 12.2664070129,
      "tz": "Europe\/Oslo"
  },
  "ENTY": {
      "icao": "ENTY",
      "iata": "",
      "name": "Tynset Airport",
      "city": "Tynset",
      "state": "Hedmark",
      "country": "NO",
      "elevation": 1581,
      "lat": 62.2569007874,
      "lon": 10.6694002151,
      "tz": "Europe\/Oslo"
  },
  "ENUL": {
      "icao": "ENUL",
      "iata": "",
      "name": "Os Vaksinen Ulve Airport",
      "city": "Ulven",
      "state": "Hordaland",
      "country": "NO",
      "elevation": 180,
      "lat": 60.192199707,
      "lon": 5.4225001335,
      "tz": "Europe\/Oslo"
  },
  "ENVA": {
      "icao": "ENVA",
      "iata": "TRD",
      "name": "Trondheim Airport Vaernes",
      "city": "Trondheim",
      "state": "Nord-Trondelag",
      "country": "NO",
      "elevation": 56,
      "lat": 63.4578018188,
      "lon": 10.9239997864,
      "tz": "Europe\/Oslo"
  },
  "ENVD": {
      "icao": "ENVD",
      "iata": "VDS",
      "name": "Vadso Airport",
      "city": "Vadso",
      "state": "Finnmark",
      "country": "NO",
      "elevation": 127,
      "lat": 70.0652999878,
      "lon": 29.8446998596,
      "tz": "Europe\/Oslo"
  },
  "ENVE": {
      "icao": "ENVE",
      "iata": "",
      "name": "Valle Airfield Araksoyne",
      "city": "",
      "state": "Aust-Agder",
      "country": "NO",
      "elevation": 771,
      "lat": 59.0317230225,
      "lon": 7.5549030304,
      "tz": "Europe\/Oslo"
  },
  "ENZV": {
      "icao": "ENZV",
      "iata": "SVG",
      "name": "Stavanger Airport Sola",
      "city": "Stavanger",
      "state": "Rogaland",
      "country": "NO",
      "elevation": 29,
      "lat": 58.876701355,
      "lon": 5.6377801895,
      "tz": "Europe\/Oslo"
  },
  "EPAR": {
      "icao": "EPAR",
      "iata": "",
      "name": "Arlamow Airport",
      "city": "Bircza",
      "state": "Subcarpathian-Voivodeship",
      "country": "PL",
      "elevation": 426,
      "lat": 49.6575012207,
      "lon": 22.5142993927,
      "tz": "Europe\/Warsaw"
  },
  "EPBA": {
      "icao": "EPBA",
      "iata": "",
      "name": "Bielsko Biala Airport",
      "city": "",
      "state": "Silesia",
      "country": "PL",
      "elevation": 1319,
      "lat": 49.8050003052,
      "lon": 19.0018997192,
      "tz": "Europe\/Warsaw"
  },
  "EPBC": {
      "icao": "EPBC",
      "iata": "",
      "name": "Babice Airport",
      "city": "",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 352,
      "lat": 52.2685012817,
      "lon": 20.9109992981,
      "tz": "Europe\/Warsaw"
  },
  "EPBD": {
      "icao": "EPBD",
      "iata": "",
      "name": "Bydgoszcz-Biedaszkowo Airport",
      "city": "Bydgoszcz",
      "state": "Kujawsko-Pomorskie",
      "country": "PL",
      "elevation": 230,
      "lat": 53.103099823,
      "lon": 17.9556007385,
      "tz": "Europe\/Warsaw"
  },
  "EPBK": {
      "icao": "EPBK",
      "iata": "QYY",
      "name": "Bialystok-Krywlany Airport",
      "city": "Bialystok",
      "state": "Podlasie",
      "country": "PL",
      "elevation": 502,
      "lat": 53.101398468,
      "lon": 23.1706008911,
      "tz": "Europe\/Warsaw"
  },
  "EPBP": {
      "icao": "EPBP",
      "iata": "BXP",
      "name": "Biala Podlaska Airport",
      "city": "Biala Podlaska",
      "state": "Lublin",
      "country": "PL",
      "elevation": 495,
      "lat": 52.0057983398,
      "lon": 23.1436004639,
      "tz": "Europe\/Warsaw"
  },
  "EPBS": {
      "icao": "EPBS",
      "iata": "",
      "name": "Borne Sulinowo Airport",
      "city": "Borne Sulinowo",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 469,
      "lat": 53.5750007629,
      "lon": 16.5242004395,
      "tz": "Europe\/Warsaw"
  },
  "EPBY": {
      "icao": "EPBY",
      "iata": "BZG",
      "name": "Bydgoszcz Ignacy Jan Paderewski Airport",
      "city": "Bydgoszcz",
      "state": "Kujawsko-Pomorskie",
      "country": "PL",
      "elevation": 235,
      "lat": 53.0968017578,
      "lon": 17.9776992798,
      "tz": "Europe\/Warsaw"
  },
  "EPCE": {
      "icao": "EPCE",
      "iata": "",
      "name": "Cewice Military Airport",
      "city": "Lebork",
      "state": "Pomerania",
      "country": "PL",
      "elevation": 495,
      "lat": 54.4160003662,
      "lon": 17.763299942,
      "tz": "Europe\/Warsaw"
  },
  "EPCH": {
      "icao": "EPCH",
      "iata": "CZW",
      "name": "Czestochowa-Rudniki",
      "city": "Czestochowa",
      "state": "Silesia",
      "country": "PL",
      "elevation": 860,
      "lat": 50.8849983215,
      "lon": 19.20470047,
      "tz": "Europe\/Warsaw"
  },
  "EPDA": {
      "icao": "EPDA",
      "iata": "",
      "name": "Darlowek Naval Air Base",
      "city": "Darlowo",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 10,
      "lat": 54.4047012329,
      "lon": 16.353099823,
      "tz": "Europe\/Warsaw"
  },
  "EPDE": {
      "icao": "EPDE",
      "iata": "",
      "name": "Deblin Military Air Base",
      "city": "Deblin",
      "state": "Lublin",
      "country": "PL",
      "elevation": 392,
      "lat": 51.551399231,
      "lon": 21.8936004639,
      "tz": "Europe\/Warsaw"
  },
  "EPDR": {
      "icao": "EPDR",
      "iata": "",
      "name": "Ziemsko Airport",
      "city": "Drawsko Pomorskie",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 377,
      "lat": 53.4777984619,
      "lon": 15.730799675,
      "tz": "Europe\/Warsaw"
  },
  "EPEK": {
      "icao": "EPEK",
      "iata": "",
      "name": "Elk-Makosieje Airport",
      "city": "Elk",
      "state": "Warmia-Masuria",
      "country": "PL",
      "elevation": 416,
      "lat": 53.8072222,
      "lon": 22.5408333,
      "tz": "Europe\/Warsaw"
  },
  "EPEL": {
      "icao": "EPEL",
      "iata": "",
      "name": "Elblag Airport",
      "city": "",
      "state": "Warmia-Masuria",
      "country": "PL",
      "elevation": 10,
      "lat": 54.1408004761,
      "lon": 19.4232997894,
      "tz": "Europe\/Warsaw"
  },
  "EPGD": {
      "icao": "EPGD",
      "iata": "GDN",
      "name": "Gdansk Lech Walesa Airport",
      "city": "Gdansk",
      "state": "Pomerania",
      "country": "PL",
      "elevation": 489,
      "lat": 54.3776016235,
      "lon": 18.4661998749,
      "tz": "Europe\/Warsaw"
  },
  "EPGI": {
      "icao": "EPGI",
      "iata": "",
      "name": "Grudziadz Lisie Airport",
      "city": "",
      "state": "Kujawsko-Pomorskie",
      "country": "PL",
      "elevation": 121,
      "lat": 53.5243988037,
      "lon": 18.849199295,
      "tz": "Europe\/Warsaw"
  },
  "EPGL": {
      "icao": "EPGL",
      "iata": "QLC",
      "name": "Gliwice Glider Airport",
      "city": "",
      "state": "Silesia",
      "country": "PL",
      "elevation": 830,
      "lat": 50.2694015503,
      "lon": 18.6728000641,
      "tz": "Europe\/Warsaw"
  },
  "EPGM": {
      "icao": "EPGM",
      "iata": "",
      "name": "Gizycko-Mazury Residence",
      "city": "Gizycko",
      "state": "Warmia-Masuria",
      "country": "PL",
      "elevation": 410,
      "lat": 54.00645,
      "lon": 21.81824,
      "tz": "Europe\/Warsaw"
  },
  "EPGO": {
      "icao": "EPGO",
      "iata": "",
      "name": "Goraszka Airport",
      "city": "Sulejowek",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 361,
      "lat": 52.1844406128,
      "lon": 21.2811107635,
      "tz": "Europe\/Warsaw"
  },
  "EPIN": {
      "icao": "EPIN",
      "iata": "",
      "name": "Inowroclaw Glider Airport",
      "city": "",
      "state": "Kujawsko-Pomorskie",
      "country": "PL",
      "elevation": 279,
      "lat": 52.8064002991,
      "lon": 18.2858009338,
      "tz": "Europe\/Warsaw"
  },
  "EPIR": {
      "icao": "EPIR",
      "iata": "",
      "name": "Inowroclaw Military Air Base",
      "city": "",
      "state": "Kujawsko-Pomorskie",
      "country": "PL",
      "elevation": 259,
      "lat": 52.8293991089,
      "lon": 18.3306007385,
      "tz": "Europe\/Warsaw"
  },
  "EPIW": {
      "icao": "EPIW",
      "iata": "",
      "name": "Iwonicz Airport",
      "city": "Krosno",
      "state": "Subcarpathian-Voivodeship",
      "country": "PL",
      "elevation": 971,
      "lat": 49.6577987671,
      "lon": 21.8174991608,
      "tz": "Europe\/Warsaw"
  },
  "EPJA": {
      "icao": "EPJA",
      "iata": "",
      "name": "Jastarnia Airport",
      "city": "Jastarnia",
      "state": "Pomerania",
      "country": "PL",
      "elevation": 3,
      "lat": 54.7102813721,
      "lon": 18.645280838,
      "tz": "Europe\/Warsaw"
  },
  "EPJG": {
      "icao": "EPJG",
      "iata": "",
      "name": "Jelenia Gora Glider Airport",
      "city": "Jelenia Gora",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 1119,
      "lat": 50.8988990784,
      "lon": 15.7855997086,
      "tz": "Europe\/Warsaw"
  },
  "EPJS": {
      "icao": "EPJS",
      "iata": "",
      "name": "Jezow Sudecki Airport",
      "city": "Jelenia Gora",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 1834,
      "lat": 50.9283294678,
      "lon": 15.7597198486,
      "tz": "Europe\/Warsaw"
  },
  "EPKA": {
      "icao": "EPKA",
      "iata": "",
      "name": "Kielce Maslow Airport",
      "city": "Kielce",
      "state": "Swietokrzyskie",
      "country": "PL",
      "elevation": 1010,
      "lat": 50.8967018127,
      "lon": 20.7317008972,
      "tz": "Europe\/Warsaw"
  },
  "EPKB": {
      "icao": "EPKB",
      "iata": "",
      "name": "Kazimierz Biskup Airport",
      "city": "",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 361,
      "lat": 52.3199996948,
      "lon": 18.1700000763,
      "tz": "Europe\/Warsaw"
  },
  "EPKE": {
      "icao": "EPKE",
      "iata": "",
      "name": "Ketrzyn-Wilamowo Airport",
      "city": "Ketrzyn",
      "state": "Warmia-Masuria",
      "country": "PL",
      "elevation": 417,
      "lat": 54.043598175,
      "lon": 21.4321994781,
      "tz": "Europe\/Warsaw"
  },
  "EPKK": {
      "icao": "EPKK",
      "iata": "KRK",
      "name": "John Paul II International Airport Krakow-Balice Airport",
      "city": "Krakow",
      "state": "Lesser-Poland-Voivodeship",
      "country": "PL",
      "elevation": 791,
      "lat": 50.0777015686,
      "lon": 19.7847995758,
      "tz": "Europe\/Warsaw"
  },
  "EPKM": {
      "icao": "EPKM",
      "iata": "",
      "name": "Muchowiec Airport",
      "city": "",
      "state": "Silesia",
      "country": "PL",
      "elevation": 909,
      "lat": 50.2380981445,
      "lon": 19.0342006683,
      "tz": "Europe\/Warsaw"
  },
  "EPKN": {
      "icao": "EPKN",
      "iata": "",
      "name": "Kamien Slaski Airport",
      "city": "",
      "state": "Opole-Voivodeship",
      "country": "PL",
      "elevation": 683,
      "lat": 50.5292015076,
      "lon": 18.0848999023,
      "tz": "Europe\/Warsaw"
  },
  "EPKO": {
      "icao": "EPKO",
      "iata": "OSZ",
      "name": "Koszalin Zegrze Airport",
      "city": "",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 249,
      "lat": 54.0424995422,
      "lon": 16.2656002045,
      "tz": "Europe\/Warsaw"
  },
  "EPKP": {
      "icao": "EPKP",
      "iata": "",
      "name": "Pobiednik Wielki Airport",
      "city": "Krakow",
      "state": "Lesser-Poland-Voivodeship",
      "country": "PL",
      "elevation": 650,
      "lat": 50.0896987915,
      "lon": 20.2017002106,
      "tz": "Europe\/Warsaw"
  },
  "EPKR": {
      "icao": "EPKR",
      "iata": "",
      "name": "Krosno Airport",
      "city": "Krosno",
      "state": "Subcarpathian-Voivodeship",
      "country": "PL",
      "elevation": 922,
      "lat": 49.681098938,
      "lon": 21.7371997833,
      "tz": "Europe\/Warsaw"
  },
  "EPKS": {
      "icao": "EPKS",
      "iata": "",
      "name": "Krzesiny Military Air Base",
      "city": "",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 265,
      "lat": 52.3316993713,
      "lon": 16.9664001465,
      "tz": "Europe\/Warsaw"
  },
  "EPKT": {
      "icao": "EPKT",
      "iata": "KTW",
      "name": "Katowice International Airport",
      "city": "Katowice",
      "state": "Silesia",
      "country": "PL",
      "elevation": 995,
      "lat": 50.4743003845,
      "lon": 19.0799999237,
      "tz": "Europe\/Warsaw"
  },
  "EPKW": {
      "icao": "EPKW",
      "iata": "QEO",
      "name": "Bielsko-Bialo Kaniow Airfield",
      "city": "Czechowice-Dziedzice",
      "state": "Silesia",
      "country": "PL",
      "elevation": 1316,
      "lat": 49.9399986267,
      "lon": 19.0219993591,
      "tz": "Europe\/Warsaw"
  },
  "EPLB": {
      "icao": "EPLB",
      "iata": "LUZ",
      "name": "Lublin Airport",
      "city": "Lublin",
      "state": "Lublin",
      "country": "PL",
      "elevation": 633,
      "lat": 51.240278,
      "lon": 22.713611,
      "tz": "Europe\/Warsaw"
  },
  "EPLE": {
      "icao": "EPLE",
      "iata": "",
      "name": "Legnica Airport",
      "city": "",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 407,
      "lat": 51.182800293,
      "lon": 16.1781997681,
      "tz": "Europe\/Warsaw"
  },
  "EPLK": {
      "icao": "EPLK",
      "iata": "",
      "name": "Lask Military Air Base",
      "city": "",
      "state": "Kodz-Voivodeship",
      "country": "PL",
      "elevation": 633,
      "lat": 51.551700592,
      "lon": 19.1791000366,
      "tz": "Europe\/Warsaw"
  },
  "EPLL": {
      "icao": "EPLL",
      "iata": "LCJ",
      "name": "Lodz Wladyslaw Reymont Airport",
      "city": "Lodz",
      "state": "Kodz-Voivodeship",
      "country": "PL",
      "elevation": 604,
      "lat": 51.7219009399,
      "lon": 19.3980998993,
      "tz": "Europe\/Warsaw"
  },
  "EPLN": {
      "icao": "EPLN",
      "iata": "",
      "name": "Lansk \/ Gryzliny Airport",
      "city": "Olsztynek",
      "state": "Warmia-Masuria",
      "country": "PL",
      "elevation": 538,
      "lat": 53.6080589294,
      "lon": 20.3444404602,
      "tz": "Europe\/Warsaw"
  },
  "EPLR": {
      "icao": "EPLR",
      "iata": "QLU",
      "name": "Lublin Radwiec Airport",
      "city": "",
      "state": "Lublin",
      "country": "PL",
      "elevation": 791,
      "lat": 51.2219009399,
      "lon": 22.3946990967,
      "tz": "Europe\/Warsaw"
  },
  "EPLS": {
      "icao": "EPLS",
      "iata": "",
      "name": "Leszno Strzyzewi Airport",
      "city": "Leszno",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 312,
      "lat": 51.8349990845,
      "lon": 16.521900177,
      "tz": "Europe\/Warsaw"
  },
  "EPLU": {
      "icao": "EPLU",
      "iata": "",
      "name": "Lubin Airport",
      "city": "",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 512,
      "lat": 51.4230003357,
      "lon": 16.1961994171,
      "tz": "Europe\/Warsaw"
  },
  "EPLY": {
      "icao": "EPLY",
      "iata": "",
      "name": "Leczyca Military Air Base",
      "city": "Leczyca",
      "state": "Kodz-Voivodeship",
      "country": "PL",
      "elevation": 377,
      "lat": 52.004699707,
      "lon": 19.1455993652,
      "tz": "Europe\/Warsaw"
  },
  "EPMB": {
      "icao": "EPMB",
      "iata": "",
      "name": "Malbork Military Air Base",
      "city": "",
      "state": "Pomerania",
      "country": "PL",
      "elevation": 16,
      "lat": 54.0269012451,
      "lon": 19.1341991425,
      "tz": "Europe\/Warsaw"
  },
  "EPMI": {
      "icao": "EPMI",
      "iata": "",
      "name": "Miroslawiec Military Air Base",
      "city": "Miroslawiec",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 459,
      "lat": 53.3950996399,
      "lon": 16.0827999115,
      "tz": "Europe\/Warsaw"
  },
  "EPMJ": {
      "icao": "EPMJ",
      "iata": "",
      "name": "Mikolajki Airport",
      "city": "Mikolajki",
      "state": "Warmia-Masuria",
      "country": "PL",
      "elevation": 423,
      "lat": 53.8077812195,
      "lon": 21.5558300018,
      "tz": "Europe\/Warsaw"
  },
  "EPML": {
      "icao": "EPML",
      "iata": "",
      "name": "Mielec Airport",
      "city": "",
      "state": "Subcarpathian-Voivodeship",
      "country": "PL",
      "elevation": 548,
      "lat": 50.3222999573,
      "lon": 21.4620990753,
      "tz": "Europe\/Warsaw"
  },
  "EPMM": {
      "icao": "EPMM",
      "iata": "",
      "name": "Minsk Mazowiecki Military Air Base",
      "city": "",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 604,
      "lat": 52.1954994202,
      "lon": 21.6558990479,
      "tz": "Europe\/Warsaw"
  },
  "EPMO": {
      "icao": "EPMO",
      "iata": "WMI",
      "name": "Warsaw Modlin Airport",
      "city": "Warsaw",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 341,
      "lat": 52.4510993958,
      "lon": 20.6518001556,
      "tz": "Europe\/Warsaw"
  },
  "EPMR": {
      "icao": "EPMR",
      "iata": "",
      "name": "Miroslawice Private Airport",
      "city": "Sobotka",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 495,
      "lat": 50.9578018188,
      "lon": 16.7702999115,
      "tz": "Europe\/Warsaw"
  },
  "EPNL": {
      "icao": "EPNL",
      "iata": "",
      "name": "Nowy Sacz-Lososina Dolna Airport",
      "city": "Nowy Sacz",
      "state": "Lesser-Poland-Voivodeship",
      "country": "PL",
      "elevation": 830,
      "lat": 49.7456016541,
      "lon": 20.6236000061,
      "tz": "Europe\/Warsaw"
  },
  "EPNM": {
      "icao": "EPNM",
      "iata": "",
      "name": "Nowe Miasto nad Pilica Airfield",
      "city": "Nowe Miasto nad Pilica",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 512,
      "lat": 51.625,
      "lon": 20.5349998474,
      "tz": "Europe\/Warsaw"
  },
  "EPNT": {
      "icao": "EPNT",
      "iata": "QWS",
      "name": "Nowy Targ Airport",
      "city": "Nowy Targ",
      "state": "Lesser-Poland-Voivodeship",
      "country": "PL",
      "elevation": 2060,
      "lat": 49.4627990723,
      "lon": 20.0503005981,
      "tz": "Europe\/Warsaw"
  },
  "EPOD": {
      "icao": "EPOD",
      "iata": "",
      "name": "Olsztyn Dajtki Airport",
      "city": "",
      "state": "Warmia-Masuria",
      "country": "PL",
      "elevation": 440,
      "lat": 53.7731018066,
      "lon": 20.4150009155,
      "tz": "Europe\/Warsaw"
  },
  "EPOK": {
      "icao": "EPOK",
      "iata": "QYD",
      "name": "Oksywie Military Air Base",
      "city": "Gdynia",
      "state": "Pomerania",
      "country": "PL",
      "elevation": 144,
      "lat": 54.57970047,
      "lon": 18.51720047,
      "tz": "Europe\/Warsaw"
  },
  "EPOM": {
      "icao": "EPOM",
      "iata": "",
      "name": "Ostrow Airport",
      "city": "Ostrow",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 469,
      "lat": 51.7010993958,
      "lon": 17.8456001282,
      "tz": "Europe\/Warsaw"
  },
  "EPOP": {
      "icao": "EPOP",
      "iata": "QPM",
      "name": "Opole-Polska Nowa Wies Airport",
      "city": "Opole",
      "state": "Opole-Voivodeship",
      "country": "PL",
      "elevation": 620,
      "lat": 50.6333007812,
      "lon": 17.7817001343,
      "tz": "Europe\/Warsaw"
  },
  "EPPB": {
      "icao": "EPPB",
      "iata": "",
      "name": "Bednary Airfield",
      "city": "Pobiedziska",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 341,
      "lat": 52.5344009399,
      "lon": 17.2187995911,
      "tz": "Europe\/Warsaw"
  },
  "EPPC": {
      "icao": "EPPC",
      "iata": "",
      "name": "Pinczow Airport",
      "city": "Pinczow",
      "state": "Swietokrzyskie",
      "country": "PL",
      "elevation": 610,
      "lat": 50.5182991028,
      "lon": 20.5149993896,
      "tz": "Europe\/Warsaw"
  },
  "EPPI": {
      "icao": "EPPI",
      "iata": "",
      "name": "Pila Airport",
      "city": "",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 259,
      "lat": 53.1699981689,
      "lon": 16.7124996185,
      "tz": "Europe\/Warsaw"
  },
  "EPPK": {
      "icao": "EPPK",
      "iata": "",
      "name": "Poznan-Kobylnica Airport",
      "city": "Poznan",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 279,
      "lat": 52.4338989258,
      "lon": 17.0442008972,
      "tz": "Europe\/Warsaw"
  },
  "EPPL": {
      "icao": "EPPL",
      "iata": "",
      "name": "Plock Airport",
      "city": "",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 331,
      "lat": 52.5621986389,
      "lon": 19.7213993073,
      "tz": "Europe\/Warsaw"
  },
  "EPPO": {
      "icao": "EPPO",
      "iata": "POZ",
      "name": "Poznan-Lawica Airport",
      "city": "Poznan",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 308,
      "lat": 52.4210014343,
      "lon": 16.8262996674,
      "tz": "Europe\/Warsaw"
  },
  "EPPR": {
      "icao": "EPPR",
      "iata": "",
      "name": "Pruszcz Gdanski Airport",
      "city": "",
      "state": "Pomerania",
      "country": "PL",
      "elevation": 21,
      "lat": 54.2480010986,
      "lon": 18.6716003418,
      "tz": "Europe\/Warsaw"
  },
  "EPPT": {
      "icao": "EPPT",
      "iata": "",
      "name": "Piotrkow Trybunalski-Bujny Airport",
      "city": "Piotrkow Trybunalski",
      "state": "Kodz-Voivodeship",
      "country": "PL",
      "elevation": 673,
      "lat": 51.3830986023,
      "lon": 19.6882991791,
      "tz": "Europe\/Warsaw"
  },
  "EPPW": {
      "icao": "EPPW",
      "iata": "",
      "name": "Powidz Military Air Base",
      "city": "",
      "state": "Greater-Poland",
      "country": "PL",
      "elevation": 371,
      "lat": 52.3793983459,
      "lon": 17.8539009094,
      "tz": "Europe\/Warsaw"
  },
  "EPRA": {
      "icao": "EPRA",
      "iata": "RDO",
      "name": "Warsaw-Radom Airport",
      "city": "Radom",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 623,
      "lat": 51.3891983032,
      "lon": 21.213300705,
      "tz": "Europe\/Warsaw"
  },
  "EPRG": {
      "icao": "EPRG",
      "iata": "",
      "name": "Rybnik-Gotarowice Glider Airport",
      "city": "Rybnik",
      "state": "Silesia",
      "country": "PL",
      "elevation": 840,
      "lat": 50.0708007813,
      "lon": 18.6282997131,
      "tz": "Europe\/Warsaw"
  },
  "EPRJ": {
      "icao": "EPRJ",
      "iata": "",
      "name": "Rzeszow Airport",
      "city": "Rzeszow",
      "state": "Subcarpathian-Voivodeship",
      "country": "PL",
      "elevation": 659,
      "lat": 50.1053009033,
      "lon": 22.0480995178,
      "tz": "Europe\/Warsaw"
  },
  "EPRP": {
      "icao": "EPRP",
      "iata": "",
      "name": "Radom-Piastrow Glider Airport",
      "city": "Radom",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 479,
      "lat": 51.4789009094,
      "lon": 21.1100006104,
      "tz": "Europe\/Warsaw"
  },
  "EPRU": {
      "icao": "EPRU",
      "iata": "CZW",
      "name": "Czestochowa-Rudniki Airport",
      "city": "Czestochowa",
      "state": "Silesia",
      "country": "PL",
      "elevation": 860,
      "lat": 50.886133,
      "lon": 19.202472,
      "tz": "Europe\/Warsaw"
  },
  "EPRZ": {
      "icao": "EPRZ",
      "iata": "RZE",
      "name": "Rzeszow-Jasionka Airport",
      "city": "Rzeszow",
      "state": "Subcarpathian-Voivodeship",
      "country": "PL",
      "elevation": 675,
      "lat": 50.1100006104,
      "lon": 22.0189990997,
      "tz": "Europe\/Warsaw"
  },
  "EPSC": {
      "icao": "EPSC",
      "iata": "SZZ",
      "name": "Szczecin-Goleniow Solidarnosc Airport",
      "city": "Goleniow",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 154,
      "lat": 53.5847015381,
      "lon": 14.9021997452,
      "tz": "Europe\/Warsaw"
  },
  "EPSD": {
      "icao": "EPSD",
      "iata": "",
      "name": "Szczecin-Dabie Airport",
      "city": "Szczecin",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 3,
      "lat": 53.3921012878,
      "lon": 14.6337995529,
      "tz": "Europe\/Warsaw"
  },
  "EPSN": {
      "icao": "EPSN",
      "iata": "",
      "name": "Swidwin Military Air Base",
      "city": "",
      "state": "West-Pomerania",
      "country": "PL",
      "elevation": 385,
      "lat": 53.790599823,
      "lon": 15.8262996674,
      "tz": "Europe\/Warsaw"
  },
  "EPSO": {
      "icao": "EPSO",
      "iata": "",
      "name": "Sochaczew Airport",
      "city": "",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 259,
      "lat": 52.198600769,
      "lon": 20.2928009033,
      "tz": "Europe\/Warsaw"
  },
  "EPSR": {
      "icao": "EPSR",
      "iata": "",
      "name": "Slupsk-Krepa Airport",
      "city": "Slupsk",
      "state": "Pomerania",
      "country": "PL",
      "elevation": 249,
      "lat": 54.4082984924,
      "lon": 17.0956001282,
      "tz": "Europe\/Warsaw"
  },
  "EPST": {
      "icao": "EPST",
      "iata": "",
      "name": "Stalowa Wola-Turbia Airport",
      "city": "Stalowa Wola",
      "state": "Subcarpathian-Voivodeship",
      "country": "PL",
      "elevation": 489,
      "lat": 50.6274986267,
      "lon": 21.9983005524,
      "tz": "Europe\/Warsaw"
  },
  "EPSU": {
      "icao": "EPSU",
      "iata": "ZWK",
      "name": "Suwalki Airport",
      "city": "Suwalki",
      "state": "Podlasie",
      "country": "PL",
      "elevation": 581,
      "lat": 54.0727996826,
      "lon": 22.8992004395,
      "tz": "Europe\/Warsaw"
  },
  "EPSY": {
      "icao": "EPSY",
      "iata": "SZY",
      "name": "Olsztyn-Mazury Airport",
      "city": "Szymany",
      "state": "Warmia-Mazury",
      "country": "PL",
      "elevation": 464,
      "lat": 53.481944,
      "lon": 20.937778,
      "tz": "Europe\/Warsaw"
  },
  "EPSW": {
      "icao": "EPSW",
      "iata": "",
      "name": "Swidnik Lotnisko Airport",
      "city": "Swidnik",
      "state": "Lublin",
      "country": "PL",
      "elevation": 659,
      "lat": 51.2318992615,
      "lon": 22.6902999878,
      "tz": "Europe\/Warsaw"
  },
  "EPTM": {
      "icao": "EPTM",
      "iata": "",
      "name": "Tomaszow Mazowiecki Military Air Base",
      "city": "Tomaszow Mazowiecki",
      "state": "Kodz-Voivodeship",
      "country": "PL",
      "elevation": 571,
      "lat": 51.584400177,
      "lon": 20.0977993011,
      "tz": "Europe\/Warsaw"
  },
  "EPTO": {
      "icao": "EPTO",
      "iata": "",
      "name": "Torun Airport",
      "city": "",
      "state": "Kujawsko-Pomorskie",
      "country": "PL",
      "elevation": 164,
      "lat": 53.0292015076,
      "lon": 18.5459003448,
      "tz": "Europe\/Warsaw"
  },
  "EPWA": {
      "icao": "EPWA",
      "iata": "WAW",
      "name": "Warsaw Chopin Airport",
      "city": "Warsaw",
      "state": "Mazovia",
      "country": "PL",
      "elevation": 362,
      "lat": 52.1656990051,
      "lon": 20.9671001434,
      "tz": "Europe\/Warsaw"
  },
  "EPWC": {
      "icao": "EPWC",
      "iata": "",
      "name": "Swiebodzice Airfield",
      "city": "Swiebodzice",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 876,
      "lat": 50.8829994202,
      "lon": 16.3199996948,
      "tz": "Europe\/Warsaw"
  },
  "EPWK": {
      "icao": "EPWK",
      "iata": "",
      "name": "Wloclawek-Kruszyn Airport",
      "city": "Wloclawek",
      "state": "Kujawsko-Pomorskie",
      "country": "PL",
      "elevation": 220,
      "lat": 52.5847015381,
      "lon": 19.0156002045,
      "tz": "Europe\/Warsaw"
  },
  "EPWR": {
      "icao": "EPWR",
      "iata": "WRO",
      "name": "Copernicus Wroclaw Airport",
      "city": "Wroclaw",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 404,
      "lat": 51.1026992798,
      "lon": 16.885799408,
      "tz": "Europe\/Warsaw"
  },
  "EPWS": {
      "icao": "EPWS",
      "iata": "",
      "name": "Wroclaw-Szymanow Airport",
      "city": "Wroclaw",
      "state": "Lower-Silesia",
      "country": "PL",
      "elevation": 390,
      "lat": 51.2061004639,
      "lon": 16.9986000061,
      "tz": "Europe\/Warsaw"
  },
  "EPWT": {
      "icao": "EPWT",
      "iata": "",
      "name": "Watorowo Airport",
      "city": "",
      "state": "Kuyavian-Pomerania",
      "country": "PL",
      "elevation": 299,
      "lat": 53.298567500000004,
      "lon": 18.414187352797445,
      "tz": "Europe\/Warsaw"
  },
  "EPZA": {
      "icao": "EPZA",
      "iata": "",
      "name": "Zamosc-Mokre Airport",
      "city": "Zamosc",
      "state": "Lublin",
      "country": "PL",
      "elevation": 751,
      "lat": 50.7016983032,
      "lon": 23.2043991089,
      "tz": "Europe\/Warsaw"
  },
  "EPZG": {
      "icao": "EPZG",
      "iata": "IEG",
      "name": "Zielona Gora-Babimost Airport",
      "city": "Babimost",
      "state": "Lubusz",
      "country": "PL",
      "elevation": 194,
      "lat": 52.1385002136,
      "lon": 15.7986001968,
      "tz": "Europe\/Warsaw"
  },
  "EPZN": {
      "icao": "EPZN",
      "iata": "",
      "name": "Tomaszowo Airport",
      "city": "Zagan",
      "state": "Lubusz",
      "country": "PL",
      "elevation": 459,
      "lat": 51.6274986267,
      "lon": 15.4083003998,
      "tz": "Europe\/Warsaw"
  },
  "EPZP": {
      "icao": "EPZP",
      "iata": "",
      "name": "Zielona Gora-Przylep Airport",
      "city": "Zielona Gora",
      "state": "Lubusz",
      "country": "PL",
      "elevation": 249,
      "lat": 51.9789009094,
      "lon": 15.4638996124,
      "tz": "Europe\/Warsaw"
  },
  "EPZR": {
      "icao": "EPZR",
      "iata": "",
      "name": "Zar Airport",
      "city": "Miedzybrodzie Zywieckie",
      "state": "Silesia",
      "country": "PL",
      "elevation": 1260,
      "lat": 49.7710990906,
      "lon": 19.2180995941,
      "tz": "Europe\/Warsaw"
  },
  "ESCF": {
      "icao": "ESCF",
      "iata": "",
      "name": "Malmen Air Base",
      "city": "Linkoping",
      "state": "Oestergotland",
      "country": "SE",
      "elevation": 308,
      "lat": 58.4023017883,
      "lon": 15.5256996155,
      "tz": "Europe\/Stockholm"
  },
  "ESCM": {
      "icao": "ESCM",
      "iata": "",
      "name": "Uppsala Airport",
      "city": "",
      "state": "Uppsala",
      "country": "SE",
      "elevation": 68,
      "lat": 59.8973007202,
      "lon": 17.5886001587,
      "tz": "Europe\/Stockholm"
  },
  "ESDF": {
      "icao": "ESDF",
      "iata": "RNB",
      "name": "Ronneby Airport",
      "city": "",
      "state": "Blekinge",
      "country": "SE",
      "elevation": 191,
      "lat": 56.2667007446,
      "lon": 15.2650003433,
      "tz": "Europe\/Stockholm"
  },
  "ESFA": {
      "icao": "ESFA",
      "iata": "XWP",
      "name": "Hassleholm Bokeberg Airport",
      "city": "Hassleholm",
      "state": "Skane",
      "country": "SE",
      "elevation": 105,
      "lat": 56.1344069918,
      "lon": 13.8756465912,
      "tz": "Europe\/Stockholm"
  },
  "ESFH": {
      "icao": "ESFH",
      "iata": "",
      "name": "Hasslosa Air Base",
      "city": "Hasslosa",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.4096984863,
      "lon": 13.2630996704,
      "tz": "Europe\/Stockholm"
  },
  "ESFM": {
      "icao": "ESFM",
      "iata": "",
      "name": "Moholm Air Base",
      "city": "Moholm",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.5974998474,
      "lon": 14.1108999252,
      "tz": "Europe\/Stockholm"
  },
  "ESFQ": {
      "icao": "ESFQ",
      "iata": "",
      "name": "Kosta Air Base",
      "city": "Kosta",
      "state": "Kronoberg",
      "country": "SE",
      "elevation": 0,
      "lat": 56.8445014954,
      "lon": 15.4527997971,
      "tz": "Europe\/Stockholm"
  },
  "ESFR": {
      "icao": "ESFR",
      "iata": "",
      "name": "Rada Air Base",
      "city": "Rada",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 230,
      "lat": 58.4981002808,
      "lon": 13.0531997681,
      "tz": "Europe\/Stockholm"
  },
  "ESFS": {
      "icao": "ESFS",
      "iata": "",
      "name": "Sandvik Airport",
      "city": "Sandvik",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 0,
      "lat": 57.0680999756,
      "lon": 16.8612995148,
      "tz": "Europe\/Stockholm"
  },
  "ESGA": {
      "icao": "ESGA",
      "iata": "",
      "name": "Backamo Airport",
      "city": "Uddevalla",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.1772003174,
      "lon": 11.9735002518,
      "tz": "Europe\/Stockholm"
  },
  "ESGC": {
      "icao": "ESGC",
      "iata": "",
      "name": "Alleberg Airport",
      "city": "Alleberg",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.1344985962,
      "lon": 13.6026000977,
      "tz": "Europe\/Stockholm"
  },
  "ESGD": {
      "icao": "ESGD",
      "iata": "",
      "name": "Bammelshed Airport",
      "city": "Tidaholm",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.1917991638,
      "lon": 13.9956998825,
      "tz": "Europe\/Stockholm"
  },
  "ESGE": {
      "icao": "ESGE",
      "iata": "",
      "name": "Viared Airport",
      "city": "Boras",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 57.6955986023,
      "lon": 12.8428001404,
      "tz": "Europe\/Stockholm"
  },
  "ESGF": {
      "icao": "ESGF",
      "iata": "",
      "name": "Morup Airport",
      "city": "Falkenberg",
      "state": "Halland",
      "country": "SE",
      "elevation": 0,
      "lat": 56.9712982178,
      "lon": 12.3891000748,
      "tz": "Europe\/Stockholm"
  },
  "ESGG": {
      "icao": "ESGG",
      "iata": "GOT",
      "name": "Gothenburg-Landvetter Airport",
      "city": "Gothenburg",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 506,
      "lat": 57.6627998352,
      "lon": 12.279800415,
      "tz": "Europe\/Stockholm"
  },
  "ESGH": {
      "icao": "ESGH",
      "iata": "",
      "name": "Herrljunga Airport",
      "city": "Herrljunga",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.0293006897,
      "lon": 13.1082000732,
      "tz": "Europe\/Stockholm"
  },
  "ESGI": {
      "icao": "ESGI",
      "iata": "",
      "name": "Alingsas Airport",
      "city": "Alingsas",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 57.949798584,
      "lon": 12.5780000687,
      "tz": "Europe\/Stockholm"
  },
  "ESGJ": {
      "icao": "ESGJ",
      "iata": "JKG",
      "name": "Jonkoping Airport",
      "city": "Jonkoping",
      "state": "Jonkoping",
      "country": "SE",
      "elevation": 741,
      "lat": 57.757598877,
      "lon": 14.0686998367,
      "tz": "Europe\/Stockholm"
  },
  "ESGK": {
      "icao": "ESGK",
      "iata": "",
      "name": "Falkoping Airport",
      "city": "Falkoping",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 785,
      "lat": 58.1697998047,
      "lon": 13.5878000259,
      "tz": "Europe\/Stockholm"
  },
  "ESGL": {
      "icao": "ESGL",
      "iata": "LDK",
      "name": "Lidkoping-Hovby Airport",
      "city": "Lidkoping",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 200,
      "lat": 58.4654998779,
      "lon": 13.1744003296,
      "tz": "Europe\/Stockholm"
  },
  "ESGM": {
      "icao": "ESGM",
      "iata": "",
      "name": "Oresten Airport",
      "city": "Oresten",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 57.4453010559,
      "lon": 12.6490001678,
      "tz": "Europe\/Stockholm"
  },
  "ESGN": {
      "icao": "ESGN",
      "iata": "",
      "name": "Brannebrona Airport",
      "city": "Gotene",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 259,
      "lat": 58.5786018372,
      "lon": 13.6106004715,
      "tz": "Europe\/Stockholm"
  },
  "ESGO": {
      "icao": "ESGO",
      "iata": "",
      "name": "Vargarda Airport",
      "city": "Vargarda",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 328,
      "lat": 58.0393981934,
      "lon": 12.7889003754,
      "tz": "Europe\/Stockholm"
  },
  "ESGP": {
      "icao": "ESGP",
      "iata": "GSE",
      "name": "Gothenburg City Airport",
      "city": "Gothenburg",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 59,
      "lat": 57.7747001648,
      "lon": 11.8704004288,
      "tz": "Europe\/Stockholm"
  },
  "ESGR": {
      "icao": "ESGR",
      "iata": "KVB",
      "name": "Skovde Airport",
      "city": "Skovde",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 324,
      "lat": 58.456401825,
      "lon": 13.972700119,
      "tz": "Europe\/Stockholm"
  },
  "ESGS": {
      "icao": "ESGS",
      "iata": "",
      "name": "Nasinge Airport",
      "city": "Stromstad",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 115,
      "lat": 59.0167999268,
      "lon": 11.3437004089,
      "tz": "Europe\/Stockholm"
  },
  "ESGT": {
      "icao": "ESGT",
      "iata": "THN",
      "name": "Trollhattan-Vanersborg Airport",
      "city": "Trollhattan",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 137,
      "lat": 58.3180999756,
      "lon": 12.345000267,
      "tz": "Europe\/Stockholm"
  },
  "ESGU": {
      "icao": "ESGU",
      "iata": "",
      "name": "Rorkarr Airport",
      "city": "Uddevalla",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.3675994873,
      "lon": 11.7754001617,
      "tz": "Europe\/Stockholm"
  },
  "ESGV": {
      "icao": "ESGV",
      "iata": "",
      "name": "Varberg Getteron airfield",
      "city": "Varberg",
      "state": "Halland",
      "country": "SE",
      "elevation": 5,
      "lat": 57.1245994568,
      "lon": 12.2283000946,
      "tz": "Europe\/Stockholm"
  },
  "ESGY": {
      "icao": "ESGY",
      "iata": "",
      "name": "Saffle Airport",
      "city": "Saffle",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.0912017822,
      "lon": 12.9586000443,
      "tz": "Europe\/Stockholm"
  },
  "ESIA": {
      "icao": "ESIA",
      "iata": "",
      "name": "Karlsborg Air Base",
      "city": "Karlsborg",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 308,
      "lat": 58.5138015747,
      "lon": 14.5071001053,
      "tz": "Europe\/Stockholm"
  },
  "ESIB": {
      "icao": "ESIB",
      "iata": "",
      "name": "Satenas Air Base",
      "city": "Satenas",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 181,
      "lat": 58.426399231,
      "lon": 12.7144002914,
      "tz": "Europe\/Stockholm"
  },
  "ESKA": {
      "icao": "ESKA",
      "iata": "",
      "name": "Gimo Air Base",
      "city": "Gimo",
      "state": "Uppsala",
      "country": "SE",
      "elevation": 0,
      "lat": 60.1328010559,
      "lon": 18.1049003601,
      "tz": "Europe\/Stockholm"
  },
  "ESKC": {
      "icao": "ESKC",
      "iata": "",
      "name": "Sundbro Airport",
      "city": "",
      "state": "Uppsala",
      "country": "SE",
      "elevation": 0,
      "lat": 59.9226989746,
      "lon": 17.5368003845,
      "tz": "Europe\/Stockholm"
  },
  "ESKD": {
      "icao": "ESKD",
      "iata": "",
      "name": "Dala Jarna Airport",
      "city": "Dala Jarna",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 0,
      "lat": 60.556098938,
      "lon": 14.3770999908,
      "tz": "Europe\/Stockholm"
  },
  "ESKG": {
      "icao": "ESKG",
      "iata": "",
      "name": "Gryttjom Airport",
      "city": "Gryttjom",
      "state": "Uppsala",
      "country": "SE",
      "elevation": 0,
      "lat": 60.2869987488,
      "lon": 17.4216995239,
      "tz": "Europe\/Stockholm"
  },
  "ESKH": {
      "icao": "ESKH",
      "iata": "",
      "name": "Eksharad Airport",
      "city": "Eksharad",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 0,
      "lat": 60.154800415,
      "lon": 13.5285997391,
      "tz": "Europe\/Stockholm"
  },
  "ESKK": {
      "icao": "ESKK",
      "iata": "KSK",
      "name": "Karlskoga Airport",
      "city": "",
      "state": "Oerebro",
      "country": "SE",
      "elevation": 400,
      "lat": 59.3459014893,
      "lon": 14.4959001541,
      "tz": "Europe\/Stockholm"
  },
  "ESKM": {
      "icao": "ESKM",
      "iata": "MXX",
      "name": "Mora Airport",
      "city": "",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 634,
      "lat": 60.957901001,
      "lon": 14.5114002228,
      "tz": "Europe\/Stockholm"
  },
  "ESKN": {
      "icao": "ESKN",
      "iata": "NYO",
      "name": "Stockholm Skavsta Airport",
      "city": "Stockholm \/ Nykoping",
      "state": "Sodermanland",
      "country": "SE",
      "elevation": 140,
      "lat": 58.7886009216,
      "lon": 16.9122009277,
      "tz": "Europe\/Stockholm"
  },
  "ESKO": {
      "icao": "ESKO",
      "iata": "",
      "name": "Munkfors Airport",
      "city": "Munkfors",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.7988014221,
      "lon": 13.4906997681,
      "tz": "Europe\/Stockholm"
  },
  "ESKT": {
      "icao": "ESKT",
      "iata": "",
      "name": "Tierp Air Base",
      "city": "Tierp",
      "state": "Uppsala",
      "country": "SE",
      "elevation": 0,
      "lat": 60.3450012207,
      "lon": 17.4218997955,
      "tz": "Europe\/Stockholm"
  },
  "ESKU": {
      "icao": "ESKU",
      "iata": "",
      "name": "Sunne Airport",
      "city": "",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.8601989746,
      "lon": 13.1128997803,
      "tz": "Europe\/Stockholm"
  },
  "ESKV": {
      "icao": "ESKV",
      "iata": "",
      "name": "Arvika Airport",
      "city": "",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 237,
      "lat": 59.6758995056,
      "lon": 12.6393995285,
      "tz": "Europe\/Stockholm"
  },
  "ESKX": {
      "icao": "ESKX",
      "iata": "",
      "name": "Bjorkvik Air Base",
      "city": "Bjorkvik",
      "state": "Sodermanland",
      "country": "SE",
      "elevation": 0,
      "lat": 58.7907981873,
      "lon": 16.5711994171,
      "tz": "Europe\/Stockholm"
  },
  "ESMA": {
      "icao": "ESMA",
      "iata": "",
      "name": "Emmaboda Airport",
      "city": "Emmaboda",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 442,
      "lat": 56.6108016968,
      "lon": 15.6048002243,
      "tz": "Europe\/Stockholm"
  },
  "ESMB": {
      "icao": "ESMB",
      "iata": "",
      "name": "Borglanda Airport",
      "city": "Borglanda",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 118,
      "lat": 56.8629989624,
      "lon": 16.6560993195,
      "tz": "Europe\/Stockholm"
  },
  "ESMC": {
      "icao": "ESMC",
      "iata": "",
      "name": "Ranneslatt Airport",
      "city": "Eksjo",
      "state": "Jonkoping",
      "country": "SE",
      "elevation": 720,
      "lat": 57.6706008911,
      "lon": 14.9429998398,
      "tz": "Europe\/Stockholm"
  },
  "ESME": {
      "icao": "ESME",
      "iata": "",
      "name": "Eslov Airport",
      "city": "Eslov",
      "state": "Skane",
      "country": "SE",
      "elevation": 296,
      "lat": 55.8483009338,
      "lon": 13.3283004761,
      "tz": "Europe\/Stockholm"
  },
  "ESMF": {
      "icao": "ESMF",
      "iata": "",
      "name": "Fagerhult Airport",
      "city": "Fagerhult",
      "state": "Skane",
      "country": "SE",
      "elevation": 378,
      "lat": 56.3879013062,
      "lon": 13.4706001282,
      "tz": "Europe\/Stockholm"
  },
  "ESMG": {
      "icao": "ESMG",
      "iata": "",
      "name": "Feringe Airport",
      "city": "Ljungby",
      "state": "Kronoberg",
      "country": "SE",
      "elevation": 538,
      "lat": 56.9502983093,
      "lon": 13.9216995239,
      "tz": "Europe\/Stockholm"
  },
  "ESMH": {
      "icao": "ESMH",
      "iata": "",
      "name": "Hoganas Airport",
      "city": "Hoganas",
      "state": "Skane",
      "country": "SE",
      "elevation": 21,
      "lat": 56.1847991943,
      "lon": 12.5761003494,
      "tz": "Europe\/Stockholm"
  },
  "ESMI": {
      "icao": "ESMI",
      "iata": "",
      "name": "Sjobo\/Sovde Airport",
      "city": "Sovdeborg",
      "state": "Skane",
      "country": "SE",
      "elevation": 118,
      "lat": 55.5982017517,
      "lon": 13.6746997833,
      "tz": "Europe\/Stockholm"
  },
  "ESMJ": {
      "icao": "ESMJ",
      "iata": "",
      "name": "Kagerod Airport",
      "city": "Kagerod",
      "state": "Skane",
      "country": "SE",
      "elevation": 276,
      "lat": 55.9947013855,
      "lon": 13.0509004593,
      "tz": "Europe\/Stockholm"
  },
  "ESMK": {
      "icao": "ESMK",
      "iata": "KID",
      "name": "Kristianstad Airport",
      "city": "Kristianstad",
      "state": "Skane",
      "country": "SE",
      "elevation": 76,
      "lat": 55.9216995239,
      "lon": 14.0854997635,
      "tz": "Europe\/Stockholm"
  },
  "ESML": {
      "icao": "ESML",
      "iata": "",
      "name": "Landskrona Airport",
      "city": "Landskrona",
      "state": "Skane",
      "country": "SE",
      "elevation": 194,
      "lat": 55.9459991455,
      "lon": 12.8699998856,
      "tz": "Europe\/Stockholm"
  },
  "ESMO": {
      "icao": "ESMO",
      "iata": "OSK",
      "name": "Oskarshamn Airport",
      "city": "",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 96,
      "lat": 57.3504981995,
      "lon": 16.4979991913,
      "tz": "Europe\/Stockholm"
  },
  "ESMP": {
      "icao": "ESMP",
      "iata": "",
      "name": "Anderstorp Airport",
      "city": "Anderstorp",
      "state": "Jonkoping",
      "country": "SE",
      "elevation": 507,
      "lat": 57.2641983032,
      "lon": 13.5993995667,
      "tz": "Europe\/Stockholm"
  },
  "ESMQ": {
      "icao": "ESMQ",
      "iata": "KLR",
      "name": "Kalmar Airport",
      "city": "",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 17,
      "lat": 56.6855010986,
      "lon": 16.2875995636,
      "tz": "Europe\/Stockholm"
  },
  "ESMS": {
      "icao": "ESMS",
      "iata": "MMX",
      "name": "Malmo Sturup Airport",
      "city": "Malmo",
      "state": "Skane",
      "country": "SE",
      "elevation": 236,
      "lat": 55.536305364,
      "lon": 13.3761978149,
      "tz": "Europe\/Stockholm"
  },
  "ESMT": {
      "icao": "ESMT",
      "iata": "HAD",
      "name": "Halmstad Airport",
      "city": "Halmstad",
      "state": "Halland",
      "country": "SE",
      "elevation": 101,
      "lat": 56.6911010742,
      "lon": 12.8201999664,
      "tz": "Europe\/Stockholm"
  },
  "ESMU": {
      "icao": "ESMU",
      "iata": "",
      "name": "Mockeln Airport",
      "city": "Almhult",
      "state": "Kronoberg",
      "country": "SE",
      "elevation": 480,
      "lat": 56.5707015991,
      "lon": 14.1665000916,
      "tz": "Europe\/Stockholm"
  },
  "ESMV": {
      "icao": "ESMV",
      "iata": "",
      "name": "Hagshult Airport",
      "city": "",
      "state": "Jonkoping",
      "country": "SE",
      "elevation": 556,
      "lat": 57.2921981812,
      "lon": 14.1372003555,
      "tz": "Europe\/Stockholm"
  },
  "ESMX": {
      "icao": "ESMX",
      "iata": "VXO",
      "name": "Vaxjo Kronoberg Airport",
      "city": "Vaxjo",
      "state": "Kronoberg",
      "country": "SE",
      "elevation": 610,
      "lat": 56.9291000366,
      "lon": 14.7279996872,
      "tz": "Europe\/Stockholm"
  },
  "ESMY": {
      "icao": "ESMY",
      "iata": "",
      "name": "Smalandsstenar Smalanda Airport",
      "city": "Smalandsstenar",
      "state": "Jonkoping",
      "country": "SE",
      "elevation": 540,
      "lat": 57.168598175,
      "lon": 13.440199852,
      "tz": "Europe\/Stockholm"
  },
  "ESMZ": {
      "icao": "ESMZ",
      "iata": "",
      "name": "Olanda Airport",
      "city": "Olanda",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 27,
      "lat": 57.3282356084,
      "lon": 17.0505237579,
      "tz": "Europe\/Stockholm"
  },
  "ESNA": {
      "icao": "ESNA",
      "iata": "",
      "name": "Hallviken Airport",
      "city": "",
      "state": "Jaemtland",
      "country": "SE",
      "elevation": 1119,
      "lat": 63.7383003235,
      "lon": 15.4582996368,
      "tz": "Europe\/Stockholm"
  },
  "ESNB": {
      "icao": "ESNB",
      "iata": "",
      "name": "Solleftea Airport",
      "city": "Solleftea",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 0,
      "lat": 63.1711997986,
      "lon": 16.985200882,
      "tz": "Europe\/Stockholm"
  },
  "ESNC": {
      "icao": "ESNC",
      "iata": "",
      "name": "Hedlanda Airport",
      "city": "Hede",
      "state": "Jaemtland",
      "country": "SE",
      "elevation": 1460,
      "lat": 62.4089012146,
      "lon": 13.7472000122,
      "tz": "Europe\/Stockholm"
  },
  "ESND": {
      "icao": "ESND",
      "iata": "EVG",
      "name": "Sveg Airport",
      "city": "",
      "state": "Jaemtland",
      "country": "SE",
      "elevation": 1178,
      "lat": 62.0477981567,
      "lon": 14.4229001999,
      "tz": "Europe\/Stockholm"
  },
  "ESNE": {
      "icao": "ESNE",
      "iata": "",
      "name": "Overkalix Airport",
      "city": "Overkalix",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 66.5287017822,
      "lon": 22.3500003815,
      "tz": "Europe\/Stockholm"
  },
  "ESNF": {
      "icao": "ESNF",
      "iata": "",
      "name": "Farila Air Base",
      "city": "Farila",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 0,
      "lat": 61.8979988098,
      "lon": 15.7053003311,
      "tz": "Europe\/Stockholm"
  },
  "ESNG": {
      "icao": "ESNG",
      "iata": "GEV",
      "name": "Gallivare Airport",
      "city": "Gallivare",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 1027,
      "lat": 67.1324005127,
      "lon": 20.8145999908,
      "tz": "Europe\/Stockholm"
  },
  "ESNI": {
      "icao": "ESNI",
      "iata": "",
      "name": "Kubbe Air Base",
      "city": "Kubbe",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 0,
      "lat": 63.6324996948,
      "lon": 17.936000824,
      "tz": "Europe\/Stockholm"
  },
  "ESNJ": {
      "icao": "ESNJ",
      "iata": "",
      "name": "Jokkmokk Airport",
      "city": "",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 904,
      "lat": 66.4962005615,
      "lon": 20.1471996307,
      "tz": "Europe\/Stockholm"
  },
  "ESNK": {
      "icao": "ESNK",
      "iata": "KRF",
      "name": "Kramfors Solleftea Airport",
      "city": "Kramfors \/ Solleftea",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 34,
      "lat": 63.0485992432,
      "lon": 17.7688999176,
      "tz": "Europe\/Stockholm"
  },
  "ESNL": {
      "icao": "ESNL",
      "iata": "LYC",
      "name": "Lycksele Airport",
      "city": "",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 705,
      "lat": 64.5483016968,
      "lon": 18.7161998749,
      "tz": "Europe\/Stockholm"
  },
  "ESNM": {
      "icao": "ESNM",
      "iata": "",
      "name": "Optand Airport",
      "city": "",
      "state": "Jaemtland",
      "country": "SE",
      "elevation": 1236,
      "lat": 63.1286010742,
      "lon": 14.8028001785,
      "tz": "Europe\/Stockholm"
  },
  "ESNN": {
      "icao": "ESNN",
      "iata": "SDL",
      "name": "Sundsvall-Harnosand Airport",
      "city": "Sundsvall\/ Harnosand",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 16,
      "lat": 62.5280990601,
      "lon": 17.4438991547,
      "tz": "Europe\/Stockholm"
  },
  "ESNO": {
      "icao": "ESNO",
      "iata": "OER",
      "name": "Ornskoldsvik Airport",
      "city": "Ornskoldsvik",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 354,
      "lat": 63.4082984924,
      "lon": 18.9899997711,
      "tz": "Europe\/Stockholm"
  },
  "ESNP": {
      "icao": "ESNP",
      "iata": "",
      "name": "Pitea Airport",
      "city": "Pitea",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 43,
      "lat": 65.3983001709,
      "lon": 21.260799408,
      "tz": "Europe\/Stockholm"
  },
  "ESNQ": {
      "icao": "ESNQ",
      "iata": "KRN",
      "name": "Kiruna Airport",
      "city": "",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 1508,
      "lat": 67.8219985962,
      "lon": 20.3367996216,
      "tz": "Europe\/Stockholm"
  },
  "ESNR": {
      "icao": "ESNR",
      "iata": "",
      "name": "Orsa Airport",
      "city": "",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 683,
      "lat": 61.1899986267,
      "lon": 14.7125997543,
      "tz": "Europe\/Stockholm"
  },
  "ESNS": {
      "icao": "ESNS",
      "iata": "SFT",
      "name": "Skelleftea Airport",
      "city": "Skelleftea",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 157,
      "lat": 64.6248016357,
      "lon": 21.0769004822,
      "tz": "Europe\/Stockholm"
  },
  "ESNT": {
      "icao": "ESNT",
      "iata": "",
      "name": "Sattna Airport",
      "city": "",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 886,
      "lat": 62.4813995361,
      "lon": 17.0028991699,
      "tz": "Europe\/Stockholm"
  },
  "ESNU": {
      "icao": "ESNU",
      "iata": "UME",
      "name": "Umea Airport",
      "city": "Umea",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 24,
      "lat": 63.7918014526,
      "lon": 20.2828006744,
      "tz": "Europe\/Stockholm"
  },
  "ESNV": {
      "icao": "ESNV",
      "iata": "VHM",
      "name": "Vilhelmina Airport",
      "city": "",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 1140,
      "lat": 64.5791015625,
      "lon": 16.8335990906,
      "tz": "Europe\/Stockholm"
  },
  "ESNX": {
      "icao": "ESNX",
      "iata": "AJR",
      "name": "Arvidsjaur Airport",
      "city": "Arvidsjaur",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 1245,
      "lat": 65.5903015137,
      "lon": 19.2819004059,
      "tz": "Europe\/Stockholm"
  },
  "ESNY": {
      "icao": "ESNY",
      "iata": "SOO",
      "name": "Soderhamn Airport",
      "city": "Soderhamn",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 88,
      "lat": 61.2615013123,
      "lon": 17.0991001129,
      "tz": "Europe\/Stockholm"
  },
  "ESNZ": {
      "icao": "ESNZ",
      "iata": "OSD",
      "name": "Ostersund Airport",
      "city": "Ostersund",
      "state": "Jaemtland",
      "country": "SE",
      "elevation": 1233,
      "lat": 63.1944007874,
      "lon": 14.5003004074,
      "tz": "Europe\/Stockholm"
  },
  "ESOE": {
      "icao": "ESOE",
      "iata": "ORB",
      "name": "Orebro Airport",
      "city": "Orebro",
      "state": "Oerebro",
      "country": "SE",
      "elevation": 188,
      "lat": 59.2237014771,
      "lon": 15.0380001068,
      "tz": "Europe\/Stockholm"
  },
  "ESOH": {
      "icao": "ESOH",
      "iata": "HFS",
      "name": "Hagfors Airport",
      "city": "",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 474,
      "lat": 60.0200996399,
      "lon": 13.5789003372,
      "tz": "Europe\/Stockholm"
  },
  "ESOK": {
      "icao": "ESOK",
      "iata": "KSD",
      "name": "Karlstad Airport",
      "city": "Karlstad",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 352,
      "lat": 59.4446983337,
      "lon": 13.3374004364,
      "tz": "Europe\/Stockholm"
  },
  "ESOL": {
      "icao": "ESOL",
      "iata": "",
      "name": "Lemstanas Airport",
      "city": "Storvik",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 0,
      "lat": 60.5878982544,
      "lon": 16.5867996216,
      "tz": "Europe\/Stockholm"
  },
  "ESOW": {
      "icao": "ESOW",
      "iata": "VST",
      "name": "Stockholm Vasteras Airport",
      "city": "Stockholm \/ Vasteras",
      "state": "Vaestmanland",
      "country": "SE",
      "elevation": 21,
      "lat": 59.5894012451,
      "lon": 16.633600235,
      "tz": "Europe\/Stockholm"
  },
  "ESPA": {
      "icao": "ESPA",
      "iata": "LLA",
      "name": "Lulea Airport",
      "city": "Lulea",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 65,
      "lat": 65.543800354,
      "lon": 22.1219997406,
      "tz": "Europe\/Stockholm"
  },
  "ESPE": {
      "icao": "ESPE",
      "iata": "",
      "name": "Vidsel Air Base",
      "city": "Vidsel",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 597,
      "lat": 65.8752975464,
      "lon": 20.1499004364,
      "tz": "Europe\/Stockholm"
  },
  "ESPG": {
      "icao": "ESPG",
      "iata": "",
      "name": "Boden Army Air Base",
      "city": "Boden",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 65.8103027344,
      "lon": 21.6912994385,
      "tz": "Europe\/Stockholm"
  },
  "ESPJ": {
      "icao": "ESPJ",
      "iata": "",
      "name": "Hede Air Base",
      "city": "",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 65.8383026123,
      "lon": 21.4687995911,
      "tz": "Europe\/Stockholm"
  },
  "ESQO": {
      "icao": "ESQO",
      "iata": "",
      "name": "Arboga Airport",
      "city": "",
      "state": "Vaestmanland",
      "country": "SE",
      "elevation": 33,
      "lat": 59.3866004944,
      "lon": 15.9240999222,
      "tz": "Europe\/Stockholm"
  },
  "ESSA": {
      "icao": "ESSA",
      "iata": "ARN",
      "name": "Stockholm-Arlanda Airport",
      "city": "Stockholm",
      "state": "Stockholm",
      "country": "SE",
      "elevation": 137,
      "lat": 59.6519012451,
      "lon": 17.9186000824,
      "tz": "Europe\/Stockholm"
  },
  "ESSB": {
      "icao": "ESSB",
      "iata": "BMA",
      "name": "Stockholm-Bromma Airport",
      "city": "Stockholm",
      "state": "Stockholm",
      "country": "SE",
      "elevation": 47,
      "lat": 59.3544006348,
      "lon": 17.9416999817,
      "tz": "Europe\/Stockholm"
  },
  "ESSC": {
      "icao": "ESSC",
      "iata": "",
      "name": "Ekeby Airport",
      "city": "Eskilstuna",
      "state": "Sodermanland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.3838996887,
      "lon": 16.4419002533,
      "tz": "Europe\/Stockholm"
  },
  "ESSD": {
      "icao": "ESSD",
      "iata": "BLE",
      "name": "Borlange Airport",
      "city": "",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 503,
      "lat": 60.422000885,
      "lon": 15.5151996613,
      "tz": "Europe\/Stockholm"
  },
  "ESSE": {
      "icao": "ESSE",
      "iata": "",
      "name": "Ska-Edeby Airport",
      "city": "Stockholm",
      "state": "Stockholm",
      "country": "SE",
      "elevation": 0,
      "lat": 59.3451004028,
      "lon": 17.7404994965,
      "tz": "Europe\/Stockholm"
  },
  "ESSF": {
      "icao": "ESSF",
      "iata": "HLF",
      "name": "Hultsfred Airport",
      "city": "",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 366,
      "lat": 57.5257987976,
      "lon": 15.8233003616,
      "tz": "Europe\/Stockholm"
  },
  "ESSG": {
      "icao": "ESSG",
      "iata": "",
      "name": "Ludvika Airport",
      "city": "Ludvika",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 0,
      "lat": 60.0882987976,
      "lon": 15.0964002609,
      "tz": "Europe\/Stockholm"
  },
  "ESSH": {
      "icao": "ESSH",
      "iata": "",
      "name": "Laxa Airport",
      "city": "Laxa",
      "state": "Oerebro",
      "country": "SE",
      "elevation": 0,
      "lat": 58.9786987305,
      "lon": 14.6661996841,
      "tz": "Europe\/Stockholm"
  },
  "ESSI": {
      "icao": "ESSI",
      "iata": "",
      "name": "Visingso Airport",
      "city": "Visingso",
      "state": "Jonkoping",
      "country": "SE",
      "elevation": 0,
      "lat": 58.098400116,
      "lon": 14.4026002884,
      "tz": "Europe\/Stockholm"
  },
  "ESSK": {
      "icao": "ESSK",
      "iata": "GVX",
      "name": "Gavle Sandviken Airport",
      "city": "Gavle \/ Sandviken",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 224,
      "lat": 60.5932998657,
      "lon": 16.9514007568,
      "tz": "Europe\/Stockholm"
  },
  "ESSL": {
      "icao": "ESSL",
      "iata": "LPI",
      "name": "Linkoping SAAB Airport",
      "city": "Linkoping",
      "state": "Oestergotland",
      "country": "SE",
      "elevation": 172,
      "lat": 58.4062004089,
      "lon": 15.6805000305,
      "tz": "Europe\/Stockholm"
  },
  "ESSM": {
      "icao": "ESSM",
      "iata": "",
      "name": "Brattforshede Airport",
      "city": "Lindfors",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.6083984375,
      "lon": 13.9123001099,
      "tz": "Europe\/Stockholm"
  },
  "ESSN": {
      "icao": "ESSN",
      "iata": "",
      "name": "Norrtalje Airport",
      "city": "Norrtalje",
      "state": "Stockholm",
      "country": "SE",
      "elevation": 39,
      "lat": 59.73279953,
      "lon": 18.6963996887,
      "tz": "Europe\/Stockholm"
  },
  "ESSP": {
      "icao": "ESSP",
      "iata": "NRK",
      "name": "Norrkoping Airport",
      "city": "Norrkoping",
      "state": "Oestergotland",
      "country": "SE",
      "elevation": 32,
      "lat": 58.5862998962,
      "lon": 16.2506008148,
      "tz": "Europe\/Stockholm"
  },
  "ESST": {
      "icao": "ESST",
      "iata": "TYF",
      "name": "Torsby Airport",
      "city": "",
      "state": "Vaermland",
      "country": "SE",
      "elevation": 393,
      "lat": 60.1576004028,
      "lon": 12.9912996292,
      "tz": "Europe\/Stockholm"
  },
  "ESSU": {
      "icao": "ESSU",
      "iata": "EKT",
      "name": "Eskilstuna Airport",
      "city": "Eskilstuna",
      "state": "Sodermanland",
      "country": "SE",
      "elevation": 139,
      "lat": 59.3511009216,
      "lon": 16.7084007263,
      "tz": "Europe\/Stockholm"
  },
  "ESSV": {
      "icao": "ESSV",
      "iata": "VBY",
      "name": "Visby Airport",
      "city": "Visby",
      "state": "Gotland",
      "country": "SE",
      "elevation": 164,
      "lat": 57.6627998352,
      "lon": 18.346200943,
      "tz": "Europe\/Stockholm"
  },
  "ESSW": {
      "icao": "ESSW",
      "iata": "VVK",
      "name": "Vastervik Airport",
      "city": "Vastervik",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 0,
      "lat": 57.7799987793,
      "lon": 16.5235996246,
      "tz": "Europe\/Stockholm"
  },
  "ESSX": {
      "icao": "ESSX",
      "iata": "",
      "name": "Johannisberg Airport",
      "city": "Vasteras",
      "state": "Vaestmanland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.5758018494,
      "lon": 16.503200531,
      "tz": "Europe\/Stockholm"
  },
  "ESSZ": {
      "icao": "ESSZ",
      "iata": "",
      "name": "Vangso Airport",
      "city": "Vangso",
      "state": "Sodermanland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.1011009216,
      "lon": 17.2110996246,
      "tz": "Europe\/Stockholm"
  },
  "ESTA": {
      "icao": "ESTA",
      "iata": "AGH",
      "name": "Angelholm-Helsingborg Airport",
      "city": "Angelholm",
      "state": "Skane",
      "country": "SE",
      "elevation": 68,
      "lat": 56.2961006165,
      "lon": 12.8471002579,
      "tz": "Europe\/Stockholm"
  },
  "ESTF": {
      "icao": "ESTF",
      "iata": "",
      "name": "Fjallbacka Anra Airport",
      "city": "Fjallbacka",
      "state": "Vaestra-Gotaland",
      "country": "SE",
      "elevation": 10,
      "lat": 58.6300086975,
      "lon": 11.314458847,
      "tz": "Europe\/Stockholm"
  },
  "ESTG": {
      "icao": "ESTG",
      "iata": "",
      "name": "Gronhogen Airport",
      "city": "Gronhogen",
      "state": "Kalmar",
      "country": "SE",
      "elevation": 10,
      "lat": 56.2750015259,
      "lon": 16.4200000763,
      "tz": "Europe\/Stockholm"
  },
  "ESTL": {
      "icao": "ESTL",
      "iata": "",
      "name": "Ljungbyhed Airport",
      "city": "Ljungbyhed",
      "state": "Skane",
      "country": "SE",
      "elevation": 140,
      "lat": 56.0828018188,
      "lon": 13.2124996185,
      "tz": "Europe\/Stockholm"
  },
  "ESTT": {
      "icao": "ESTT",
      "iata": "",
      "name": "Vellinge Airfield",
      "city": "Vellinge",
      "state": "Skane",
      "country": "SE",
      "elevation": 12,
      "lat": 55.3912021148,
      "lon": 13.0221033096,
      "tz": "Europe\/Stockholm"
  },
  "ESUA": {
      "icao": "ESUA",
      "iata": "",
      "name": "Amsele Air Base",
      "city": "Amsele",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 64.570602417,
      "lon": 19.3141002655,
      "tz": "Europe\/Stockholm"
  },
  "ESUB": {
      "icao": "ESUB",
      "iata": "",
      "name": "Arbra Airport",
      "city": "Arbra",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 0,
      "lat": 61.5125007629,
      "lon": 16.3724994659,
      "tz": "Europe\/Stockholm"
  },
  "ESUD": {
      "icao": "ESUD",
      "iata": "SQO",
      "name": "Storuman Airport",
      "city": "",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 915,
      "lat": 64.960899353,
      "lon": 17.6965999603,
      "tz": "Europe\/Stockholm"
  },
  "ESUE": {
      "icao": "ESUE",
      "iata": "IDB",
      "name": "Idre Airport",
      "city": "Idre",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 0,
      "lat": 61.8697013855,
      "lon": 12.6893997192,
      "tz": "Europe\/Stockholm"
  },
  "ESUF": {
      "icao": "ESUF",
      "iata": "",
      "name": "Fallfors Air Base",
      "city": "Fallfors",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 65.1075973511,
      "lon": 20.761100769,
      "tz": "Europe\/Stockholm"
  },
  "ESUG": {
      "icao": "ESUG",
      "iata": "",
      "name": "Gargnas Airport",
      "city": "Gargnas",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 65.3052978516,
      "lon": 17.9755001068,
      "tz": "Europe\/Stockholm"
  },
  "ESUH": {
      "icao": "ESUH",
      "iata": "",
      "name": "Myran Airport",
      "city": "Harnosand",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 0,
      "lat": 62.6324691772,
      "lon": 17.9817008972,
      "tz": "Europe\/Stockholm"
  },
  "ESUI": {
      "icao": "ESUI",
      "iata": "",
      "name": "Mellansel Airport",
      "city": "Mellansel",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 269,
      "lat": 63.3983001709,
      "lon": 18.3283004761,
      "tz": "Europe\/Stockholm"
  },
  "ESUJ": {
      "icao": "ESUJ",
      "iata": "",
      "name": "Talje Airport",
      "city": "Ange",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 0,
      "lat": 62.5652999878,
      "lon": 15.8346996307,
      "tz": "Europe\/Stockholm"
  },
  "ESUK": {
      "icao": "ESUK",
      "iata": "",
      "name": "Kalixfors Airport",
      "city": "",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 1549,
      "lat": 67.7648010254,
      "lon": 20.2572002411,
      "tz": "Europe\/Stockholm"
  },
  "ESUL": {
      "icao": "ESUL",
      "iata": "",
      "name": "Ljusdal Airport",
      "city": "Ljusdal",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 0,
      "lat": 61.8170013428,
      "lon": 16.0042991638,
      "tz": "Europe\/Stockholm"
  },
  "ESUM": {
      "icao": "ESUM",
      "iata": "",
      "name": "Mohed Airport",
      "city": "Mohed",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 0,
      "lat": 61.2911987305,
      "lon": 16.8463001251,
      "tz": "Europe\/Stockholm"
  },
  "ESUO": {
      "icao": "ESUO",
      "iata": "",
      "name": "Graftavallen Airport",
      "city": "Graftavallen",
      "state": "Jaemtland",
      "country": "SE",
      "elevation": 1640,
      "lat": 63.0416984558,
      "lon": 14.0013999939,
      "tz": "Europe\/Stockholm"
  },
  "ESUP": {
      "icao": "ESUP",
      "iata": "PJA",
      "name": "Pajala Airport",
      "city": "",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 542,
      "lat": 67.2455978394,
      "lon": 23.0688991547,
      "tz": "Europe\/Stockholm"
  },
  "ESUR": {
      "icao": "ESUR",
      "iata": "",
      "name": "Ramsele Airport",
      "city": "Solleftea",
      "state": "Vaesternorrland",
      "country": "SE",
      "elevation": 0,
      "lat": 63.4902992249,
      "lon": 16.4834003448,
      "tz": "Europe\/Stockholm"
  },
  "ESUS": {
      "icao": "ESUS",
      "iata": "",
      "name": "Asele Airport",
      "city": "Asele",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 64.1591033936,
      "lon": 17.2742996216,
      "tz": "Europe\/Stockholm"
  },
  "ESUT": {
      "icao": "ESUT",
      "iata": "HMV",
      "name": "Hemavan Airport",
      "city": "",
      "state": "Vaesterbotten",
      "country": "SE",
      "elevation": 1503,
      "lat": 65.806098938,
      "lon": 15.0827999115,
      "tz": "Europe\/Stockholm"
  },
  "ESUV": {
      "icao": "ESUV",
      "iata": "",
      "name": "Alvsbyn Airport",
      "city": "Alvsbyn",
      "state": "Norrbotten",
      "country": "SE",
      "elevation": 0,
      "lat": 65.6456985474,
      "lon": 21.0611000061,
      "tz": "Europe\/Stockholm"
  },
  "ESUY": {
      "icao": "ESUY",
      "iata": "",
      "name": "Edsby Airport",
      "city": "Edsbyn",
      "state": "Gaevleborg",
      "country": "SE",
      "elevation": 0,
      "lat": 61.3870010376,
      "lon": 15.8333997726,
      "tz": "Europe\/Stockholm"
  },
  "ESVA": {
      "icao": "ESVA",
      "iata": "",
      "name": "Avesta Airport",
      "city": "Avesta",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 0,
      "lat": 60.1804008484,
      "lon": 16.122800827,
      "tz": "Europe\/Stockholm"
  },
  "ESVB": {
      "icao": "ESVB",
      "iata": "",
      "name": "Bunge Private Airport",
      "city": "Bunge",
      "state": "Gotland",
      "country": "SE",
      "elevation": 69,
      "lat": 57.8497009277,
      "lon": 19.0349998474,
      "tz": "Europe\/Stockholm"
  },
  "ESVG": {
      "icao": "ESVG",
      "iata": "",
      "name": "Gagnef Airport",
      "city": "Djuras",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 0,
      "lat": 60.551700592,
      "lon": 15.0816001892,
      "tz": "Europe\/Stockholm"
  },
  "ESVH": {
      "icao": "ESVH",
      "iata": "",
      "name": "Hallefors Airport",
      "city": "Hallefors",
      "state": "",
      "country": "SE",
      "elevation": 0,
      "lat": 59.867401123,
      "lon": 14.4238996506,
      "tz": "Europe\/Stockholm"
  },
  "ESVK": {
      "icao": "ESVK",
      "iata": "",
      "name": "Katrineholm Airport",
      "city": "Katrineholm",
      "state": "Sodermanland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.0223007202,
      "lon": 16.2203006744,
      "tz": "Europe\/Stockholm"
  },
  "ESVM": {
      "icao": "ESVM",
      "iata": "",
      "name": "Skinnlanda Airport",
      "city": "Malung",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 0,
      "lat": 60.6587982178,
      "lon": 13.7265996933,
      "tz": "Europe\/Stockholm"
  },
  "ESVQ": {
      "icao": "ESVQ",
      "iata": "",
      "name": "Koping Airport",
      "city": "Koping",
      "state": "Vaestmanland",
      "country": "SE",
      "elevation": 0,
      "lat": 59.5275001526,
      "lon": 15.9696998596,
      "tz": "Europe\/Stockholm"
  },
  "ESVS": {
      "icao": "ESVS",
      "iata": "",
      "name": "Siljansnas Airport",
      "city": "Siljansnas",
      "state": "Dalarna",
      "country": "SE",
      "elevation": 611,
      "lat": 60.7850990295,
      "lon": 14.8271999359,
      "tz": "Europe\/Stockholm"
  },
  "ETAD": {
      "icao": "ETAD",
      "iata": "SPM",
      "name": "Spangdahlem Air Base",
      "city": "Trier",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1197,
      "lat": 49.9726982117,
      "lon": 6.6925001144,
      "tz": "Europe\/Berlin"
  },
  "ETAR": {
      "icao": "ETAR",
      "iata": "RMS",
      "name": "Ramstein Air Base",
      "city": "Ramstein",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 776,
      "lat": 49.4369010925,
      "lon": 7.600279808,
      "tz": "Europe\/Berlin"
  },
  "ETEJ": {
      "icao": "ETEJ",
      "iata": "ZCD",
      "name": "Bamberg-Breitenau Airport",
      "city": "Bamberg",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 837,
      "lat": 49.9205551147,
      "lon": 10.9141674042,
      "tz": "Europe\/Berlin"
  },
  "ETEK": {
      "icao": "ETEK",
      "iata": "",
      "name": "Baumholder Army Air Field",
      "city": "",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 0,
      "lat": 49.6502990723,
      "lon": 7.300069809,
      "tz": "Europe\/Berlin"
  },
  "ETEU": {
      "icao": "ETEU",
      "iata": "GHF",
      "name": "Giebelstadt Army Air Field",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 980,
      "lat": 49.6481018066,
      "lon": 9.9664897919,
      "tz": "Europe\/Berlin"
  },
  "ETHA": {
      "icao": "ETHA",
      "iata": "",
      "name": "Altenstadt Army Airfield",
      "city": "Altenstadt",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 2425,
      "lat": 47.8354988098,
      "lon": 10.8711996078,
      "tz": "Europe\/Berlin"
  },
  "ETHB": {
      "icao": "ETHB",
      "iata": "",
      "name": "Buckeburg Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 230,
      "lat": 52.2784996033,
      "lon": 9.0821695328,
      "tz": "Europe\/Berlin"
  },
  "ETHC": {
      "icao": "ETHC",
      "iata": "ZCN",
      "name": "Celle Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 129,
      "lat": 52.5912017822,
      "lon": 10.0221004486,
      "tz": "Europe\/Berlin"
  },
  "ETHE": {
      "icao": "ETHE",
      "iata": "ZPQ",
      "name": "Rheine Bentlage Airport",
      "city": "",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 129,
      "lat": 52.2911987305,
      "lon": 7.3870000839,
      "tz": "Europe\/Berlin"
  },
  "ETHF": {
      "icao": "ETHF",
      "iata": "FRZ",
      "name": "Fritzlar Airport",
      "city": "Fritzlar",
      "state": "Hesse",
      "country": "DE",
      "elevation": 1345,
      "lat": 51.1146,
      "lon": 9.286,
      "tz": "Europe\/Berlin"
  },
  "ETHL": {
      "icao": "ETHL",
      "iata": "",
      "name": "Laupheim Airport",
      "city": "",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1766,
      "lat": 48.2202987671,
      "lon": 9.9100198746,
      "tz": "Europe\/Berlin"
  },
  "ETHM": {
      "icao": "ETHM",
      "iata": "",
      "name": "Mendig Airport",
      "city": "",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 597,
      "lat": 50.3660011292,
      "lon": 7.3153300285,
      "tz": "Europe\/Berlin"
  },
  "ETHN": {
      "icao": "ETHN",
      "iata": "",
      "name": "Niederstetten Airport",
      "city": "Niederstetten",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 1339,
      "lat": 49.3919448853,
      "lon": 9.9588890076,
      "tz": "Europe\/Berlin"
  },
  "ETHR": {
      "icao": "ETHR",
      "iata": "",
      "name": "Roth Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1268,
      "lat": 49.2174987793,
      "lon": 11.1001996994,
      "tz": "Europe\/Berlin"
  },
  "ETHS": {
      "icao": "ETHS",
      "iata": "",
      "name": "Fassberg Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 245,
      "lat": 52.9193992615,
      "lon": 10.1975002289,
      "tz": "Europe\/Berlin"
  },
  "ETHT": {
      "icao": "ETHT",
      "iata": "",
      "name": "Cottbus (Cottbus Army) Airport",
      "city": "",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 0,
      "lat": 51.7678985596,
      "lon": 14.2919998169,
      "tz": "Europe\/Berlin"
  },
  "ETIC": {
      "icao": "ETIC",
      "iata": "",
      "name": "Grafenwohr Army Air Field",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1363,
      "lat": 49.6986999512,
      "lon": 11.940199852,
      "tz": "Europe\/Berlin"
  },
  "ETID": {
      "icao": "ETID",
      "iata": "ZNF",
      "name": "Hanau Army Air Field",
      "city": "",
      "state": "Hesse",
      "country": "DE",
      "elevation": 368,
      "lat": 50.1692008972,
      "lon": 8.9615898132,
      "tz": "Europe\/Berlin"
  },
  "ETIH": {
      "icao": "ETIH",
      "iata": "",
      "name": "Hohenfels Army Air Field",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1455,
      "lat": 49.2181015015,
      "lon": 11.8360996246,
      "tz": "Europe\/Berlin"
  },
  "ETIK": {
      "icao": "ETIK",
      "iata": "ILH",
      "name": "Illesheim Air Base",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1079,
      "lat": 49.4738998413,
      "lon": 10.3880996704,
      "tz": "Europe\/Berlin"
  },
  "ETIN": {
      "icao": "ETIN",
      "iata": "KZG",
      "name": "Kitzingen Army Air Field",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 689,
      "lat": 49.7430992126,
      "lon": 10.2005996704,
      "tz": "Europe\/Berlin"
  },
  "ETME": {
      "icao": "ETME",
      "iata": "",
      "name": "Eggebeck Air Base",
      "city": "",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 65,
      "lat": 54.6248016357,
      "lon": 9.3459997177,
      "tz": "Europe\/Berlin"
  },
  "ETMN": {
      "icao": "ETMN",
      "iata": "NDZ",
      "name": "Nordholz Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 74,
      "lat": 53.7677001953,
      "lon": 8.6584997177,
      "tz": "Europe\/Berlin"
  },
  "ETNA": {
      "icao": "ETNA",
      "iata": "",
      "name": "Ahlhorn Air Force Base",
      "city": "Ahlhorn",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 162,
      "lat": 52.8829994202,
      "lon": 8.2329998016,
      "tz": "Europe\/Berlin"
  },
  "ETND": {
      "icao": "ETND",
      "iata": "",
      "name": "Diepholz Airport",
      "city": "Diepholz",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 128,
      "lat": 52.5855560303,
      "lon": 8.3422222137,
      "tz": "Europe\/Berlin"
  },
  "ETNG": {
      "icao": "ETNG",
      "iata": "GKE",
      "name": "Geilenkirchen Airport",
      "city": "",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 296,
      "lat": 50.9608001709,
      "lon": 6.0424199104,
      "tz": "Europe\/Amsterdam"
  },
  "ETNH": {
      "icao": "ETNH",
      "iata": "",
      "name": "Hohn Airport",
      "city": "",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 39,
      "lat": 54.3121986389,
      "lon": 9.5381698608,
      "tz": "Europe\/Berlin"
  },
  "ETNJ": {
      "icao": "ETNJ",
      "iata": "",
      "name": "Jever Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 24,
      "lat": 53.5335006714,
      "lon": 7.8886699677,
      "tz": "Europe\/Berlin"
  },
  "ETNL": {
      "icao": "ETNL",
      "iata": "RLG",
      "name": "Rostock-Laage Airport",
      "city": "Rostock",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 138,
      "lat": 53.9182014465,
      "lon": 12.2783002853,
      "tz": "Europe\/Berlin"
  },
  "ETNN": {
      "icao": "ETNN",
      "iata": "QOE",
      "name": "Norvenich Airport",
      "city": "",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 386,
      "lat": 50.831199646,
      "lon": 6.6581702232,
      "tz": "Europe\/Berlin"
  },
  "ETNS": {
      "icao": "ETNS",
      "iata": "WBG",
      "name": "Schleswig Airport",
      "city": "",
      "state": "Schleswig-Holstein",
      "country": "DE",
      "elevation": 70,
      "lat": 54.4593009949,
      "lon": 9.5163297653,
      "tz": "Europe\/Berlin"
  },
  "ETNT": {
      "icao": "ETNT",
      "iata": "",
      "name": "Wittmundhafen Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 26,
      "lat": 53.5477981567,
      "lon": 7.6673297882,
      "tz": "Europe\/Berlin"
  },
  "ETNU": {
      "icao": "ETNU",
      "iata": "FNB",
      "name": "Neubrandenburg Airport",
      "city": "",
      "state": "Mecklenburg-Vorpommern",
      "country": "DE",
      "elevation": 228,
      "lat": 53.6021995544,
      "lon": 13.3059997559,
      "tz": "Europe\/Berlin"
  },
  "ETNW": {
      "icao": "ETNW",
      "iata": "",
      "name": "Wunstorf Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 187,
      "lat": 52.4572982788,
      "lon": 9.4271697998,
      "tz": "Europe\/Berlin"
  },
  "ETOI": {
      "icao": "ETOI",
      "iata": "",
      "name": "Vilseck Army Air Field",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1353,
      "lat": 49.6335983276,
      "lon": 11.76720047,
      "tz": "Europe\/Berlin"
  },
  "ETOR": {
      "icao": "ETOR",
      "iata": "",
      "name": "Coleman Army Air Field",
      "city": "",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 309,
      "lat": 49.5635986328,
      "lon": 8.4633903503,
      "tz": "Europe\/Berlin"
  },
  "ETOU": {
      "icao": "ETOU",
      "iata": "WIE",
      "name": "Wiesbaden Army Airfield",
      "city": "Wiesbaden",
      "state": "Hesse",
      "country": "DE",
      "elevation": 461,
      "lat": 50.0498008728,
      "lon": 8.3254003525,
      "tz": "Europe\/Berlin"
  },
  "ETSA": {
      "icao": "ETSA",
      "iata": "",
      "name": "Landsberg Lech Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 2044,
      "lat": 48.0705986023,
      "lon": 10.9060001373,
      "tz": "Europe\/Berlin"
  },
  "ETSB": {
      "icao": "ETSB",
      "iata": "",
      "name": "Buchel Airport",
      "city": "",
      "state": "Rheinland-Pfalz",
      "country": "DE",
      "elevation": 1568,
      "lat": 50.1738014221,
      "lon": 7.0633301735,
      "tz": "Europe\/Berlin"
  },
  "ETSE": {
      "icao": "ETSE",
      "iata": "",
      "name": "Erding Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1515,
      "lat": 48.3222999573,
      "lon": 11.9486999512,
      "tz": "Europe\/Berlin"
  },
  "ETSF": {
      "icao": "ETSF",
      "iata": "FEL",
      "name": "Furstenfeldbruck Airport",
      "city": "Furstenfeldbruck",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1703,
      "lat": 48.2055549622,
      "lon": 11.2669439316,
      "tz": "Europe\/Berlin"
  },
  "ETSH": {
      "icao": "ETSH",
      "iata": "",
      "name": "Holzdorf Airport",
      "city": "",
      "state": "Brandenburg",
      "country": "DE",
      "elevation": 265,
      "lat": 51.7677993774,
      "lon": 13.1676998138,
      "tz": "Europe\/Berlin"
  },
  "ETSI": {
      "icao": "ETSI",
      "iata": "IGS",
      "name": "Ingolstadt Manching Airport",
      "city": "Manching",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1202,
      "lat": 48.7156982422,
      "lon": 11.5340003967,
      "tz": "Europe\/Berlin"
  },
  "ETSL": {
      "icao": "ETSL",
      "iata": "",
      "name": "Lechfeld Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1822,
      "lat": 48.1855010986,
      "lon": 10.8612003326,
      "tz": "Europe\/Berlin"
  },
  "ETSN": {
      "icao": "ETSN",
      "iata": "",
      "name": "Neuburg Airport",
      "city": "",
      "state": "Bavaria",
      "country": "DE",
      "elevation": 1249,
      "lat": 48.7109985352,
      "lon": 11.2115001678,
      "tz": "Europe\/Berlin"
  },
  "ETUO": {
      "icao": "ETUO",
      "iata": "GUT",
      "name": "Gutersloh Airport",
      "city": "Gutersloh",
      "state": "North-Rhine-Westphalia",
      "country": "DE",
      "elevation": 236,
      "lat": 51.9227981567,
      "lon": 8.3063297272,
      "tz": "Europe\/Berlin"
  },
  "ETWM": {
      "icao": "ETWM",
      "iata": "",
      "name": "Meppe Airport",
      "city": "",
      "state": "Lower-Saxony",
      "country": "DE",
      "elevation": 0,
      "lat": 52.7232017517,
      "lon": 7.3263301849,
      "tz": "Europe\/Berlin"
  },
  "EVAD": {
      "icao": "EVAD",
      "iata": "",
      "name": "Adazi Airfield",
      "city": "Adazi",
      "state": "Adazi",
      "country": "LV",
      "elevation": 8,
      "lat": 57.0985984802,
      "lon": 24.2658004761,
      "tz": "Europe\/Riga"
  },
  "EVCA": {
      "icao": "EVCA",
      "iata": "",
      "name": "Cesis Airport",
      "city": "Cesis",
      "state": "Priekuli",
      "country": "LV",
      "elevation": 367,
      "lat": 57.3244438171,
      "lon": 25.3236103058,
      "tz": "Europe\/Riga"
  },
  "EVDA": {
      "icao": "EVDA",
      "iata": "DGP",
      "name": "Daugavpils Intrenational Airport",
      "city": "Daugavpils",
      "state": "Daugavpils-municipality",
      "country": "LV",
      "elevation": 398,
      "lat": 55.9447212219,
      "lon": 26.6650009155,
      "tz": "Europe\/Riga"
  },
  "EVEA": {
      "icao": "EVEA",
      "iata": "",
      "name": "Yelgava Airport",
      "city": "Yelgava",
      "state": "Jelgava",
      "country": "LV",
      "elevation": 20,
      "lat": 56.6727981567,
      "lon": 23.6791992188,
      "tz": "Europe\/Riga"
  },
  "EVFA": {
      "icao": "EVFA",
      "iata": "",
      "name": "Vainode Airport",
      "city": "Vainode",
      "state": "Vainode",
      "country": "LV",
      "elevation": 518,
      "lat": 56.4056015015,
      "lon": 21.8868999481,
      "tz": "Europe\/Riga"
  },
  "EVGA": {
      "icao": "EVGA",
      "iata": "",
      "name": "Lielvarde Airport",
      "city": "Lielvarde",
      "state": "Kegums",
      "country": "LV",
      "elevation": 200,
      "lat": 56.778301239,
      "lon": 24.8539009094,
      "tz": "Europe\/Riga"
  },
  "EVJA": {
      "icao": "EVJA",
      "iata": "",
      "name": "Jurmala Airport",
      "city": "Jurmala",
      "state": "Engure",
      "country": "LV",
      "elevation": 233,
      "lat": 56.941667,
      "lon": 23.223333,
      "tz": "Europe\/Riga"
  },
  "EVKA": {
      "icao": "EVKA",
      "iata": "",
      "name": "Jekabpils Airport",
      "city": "Jekabpils",
      "state": "Krustpils",
      "country": "LV",
      "elevation": 289,
      "lat": 56.5346984863,
      "lon": 25.8924999237,
      "tz": "Europe\/Riga"
  },
  "EVLA": {
      "icao": "EVLA",
      "iata": "LPX",
      "name": "Liepaja International Airport",
      "city": "Liepaja",
      "state": "Grobina",
      "country": "LV",
      "elevation": 16,
      "lat": 56.5175018311,
      "lon": 21.0969009399,
      "tz": "Europe\/Riga"
  },
  "EVPA": {
      "icao": "EVPA",
      "iata": "",
      "name": "Ikshkile Airport",
      "city": "Ikshkile",
      "state": "Ikskile",
      "country": "LV",
      "elevation": 64,
      "lat": 56.8149986267,
      "lon": 24.5277996063,
      "tz": "Europe\/Riga"
  },
  "EVRA": {
      "icao": "EVRA",
      "iata": "RIX",
      "name": "Riga International Airport",
      "city": "Riga",
      "state": "Marupe",
      "country": "LV",
      "elevation": 36,
      "lat": 56.9235992432,
      "lon": 23.9710998535,
      "tz": "Europe\/Riga"
  },
  "EVRC": {
      "icao": "EVRC",
      "iata": "",
      "name": "Rumbula Air Base",
      "city": "Riga",
      "state": "Riga",
      "country": "LV",
      "elevation": 26,
      "lat": 56.8833007812,
      "lon": 24.2266998291,
      "tz": "Europe\/Riga"
  },
  "EVRS": {
      "icao": "EVRS",
      "iata": "",
      "name": "Spilve Aerodrome",
      "city": "Riga",
      "state": "Riga",
      "country": "LV",
      "elevation": 3,
      "lat": 56.9911003113,
      "lon": 24.0750007629,
      "tz": "Europe\/Riga"
  },
  "EVTA": {
      "icao": "EVTA",
      "iata": "",
      "name": "Tukums Air Base",
      "city": "Tukums",
      "state": "Engure",
      "country": "LV",
      "elevation": 220,
      "lat": 56.942199707,
      "lon": 23.2238998413,
      "tz": "Europe\/Riga"
  },
  "EVTE": {
      "icao": "EVTE",
      "iata": "",
      "name": "Talsi Airfield",
      "city": "Talsi",
      "state": "Talsi-Municipality",
      "country": "LV",
      "elevation": 0,
      "lat": 57.2543984491,
      "lon": 22.5662183762,
      "tz": "Europe\/Riga"
  },
  "EVVA": {
      "icao": "EVVA",
      "iata": "VNT",
      "name": "Ventspils International Airport",
      "city": "Ventspils",
      "state": "Ventspils",
      "country": "LV",
      "elevation": 19,
      "lat": 57.35779953,
      "lon": 21.5442008972,
      "tz": "Europe\/Riga"
  },
  "EYAL": {
      "icao": "EYAL",
      "iata": "",
      "name": "Alytus Airport",
      "city": "Alytus",
      "state": "Alytus",
      "country": "LT",
      "elevation": 269,
      "lat": 54.412525177,
      "lon": 24.059928894,
      "tz": "Europe\/Vilnius"
  },
  "EYBI": {
      "icao": "EYBI",
      "iata": "",
      "name": "Birzai Airport",
      "city": "Birzai",
      "state": "Panevezys",
      "country": "LT",
      "elevation": 190,
      "lat": 56.1758003235,
      "lon": 24.7602996826,
      "tz": "Europe\/Vilnius"
  },
  "EYDR": {
      "icao": "EYDR",
      "iata": "",
      "name": "Druskininkai Airport",
      "city": "Druskininkai",
      "state": "Alytus",
      "country": "LT",
      "elevation": 302,
      "lat": 54.0153007507,
      "lon": 23.9430999756,
      "tz": "Europe\/Vilnius"
  },
  "EYJB": {
      "icao": "EYJB",
      "iata": "",
      "name": "Jubarkas Airport",
      "city": "Jubarkas",
      "state": "Taurage-County",
      "country": "LT",
      "elevation": 0,
      "lat": 55.1180496216,
      "lon": 22.7648735046,
      "tz": "Europe\/Vilnius"
  },
  "EYKA": {
      "icao": "EYKA",
      "iata": "KUN",
      "name": "Kaunas International Airport",
      "city": "Kaunas",
      "state": "",
      "country": "LT",
      "elevation": 256,
      "lat": 54.9639015198,
      "lon": 24.0848007202,
      "tz": "Europe\/Vilnius"
  },
  "EYKD": {
      "icao": "EYKD",
      "iata": "",
      "name": "Kedainiai Air Base",
      "city": "Kedainiai",
      "state": "Kaunas",
      "country": "LT",
      "elevation": 171,
      "lat": 55.3116989136,
      "lon": 23.9563999176,
      "tz": "Europe\/Vilnius"
  },
  "EYKG": {
      "icao": "EYKG",
      "iata": "",
      "name": "Kaunas Gamykla Airport",
      "city": "Kaunas",
      "state": "Kaunas",
      "country": "LT",
      "elevation": 249,
      "lat": 54.8800010681,
      "lon": 23.9071998596,
      "tz": "Europe\/Vilnius"
  },
  "EYKL": {
      "icao": "EYKL",
      "iata": "KLJ",
      "name": "Klaipeda Airport",
      "city": "Klaipeda",
      "state": "",
      "country": "LT",
      "elevation": 59,
      "lat": 55.7118988037,
      "lon": 21.2427997589,
      "tz": "Europe\/Vilnius"
  },
  "EYKR": {
      "icao": "EYKR",
      "iata": "",
      "name": "Kazlu Ruda Air Base",
      "city": "Kazlu Ruda",
      "state": "Marijampole-County",
      "country": "LT",
      "elevation": 243,
      "lat": 54.8055992126,
      "lon": 23.5331001282,
      "tz": "Europe\/Vilnius"
  },
  "EYKS": {
      "icao": "EYKS",
      "iata": "",
      "name": "S. Darius and S. Girenas Airport",
      "city": "Kaunas",
      "state": "Kaunas",
      "country": "LT",
      "elevation": 246,
      "lat": 54.8797988892,
      "lon": 23.8815002441,
      "tz": "Europe\/Vilnius"
  },
  "EYKT": {
      "icao": "EYKT",
      "iata": "",
      "name": "Kartena Airport",
      "city": "Kartena",
      "state": "Klaipeda-County",
      "country": "LT",
      "elevation": 259,
      "lat": 55.92029953,
      "lon": 21.567199707,
      "tz": "Europe\/Vilnius"
  },
  "EYLN": {
      "icao": "EYLN",
      "iata": "",
      "name": "Valenciunai Airport",
      "city": "Valenciunai",
      "state": "Marijampole-County",
      "country": "LT",
      "elevation": 171,
      "lat": 55.0214004517,
      "lon": 22.9738998413,
      "tz": "Europe\/Vilnius"
  },
  "EYMA": {
      "icao": "EYMA",
      "iata": "",
      "name": "Tirksliai Airport",
      "city": "Tirksliai \/ Mazeikiai",
      "state": "Telsiai",
      "country": "LT",
      "elevation": 259,
      "lat": 56.2305984497,
      "lon": 22.2593994141,
      "tz": "Europe\/Vilnius"
  },
  "EYMM": {
      "icao": "EYMM",
      "iata": "",
      "name": "Sasnava Airport",
      "city": "Sasnava",
      "state": "Marijampole-County",
      "country": "LT",
      "elevation": 240,
      "lat": 54.6631011963,
      "lon": 23.4538993835,
      "tz": "Europe\/Vilnius"
  },
  "EYMO": {
      "icao": "EYMO",
      "iata": "",
      "name": "Moletai Airport",
      "city": "Moletai",
      "state": "Utena",
      "country": "LT",
      "elevation": 650,
      "lat": 55.1128005981,
      "lon": 25.3363990784,
      "tz": "Europe\/Vilnius"
  },
  "EYNA": {
      "icao": "EYNA",
      "iata": "",
      "name": "Akmene Airport",
      "city": "Akmene",
      "state": "Siauliai",
      "country": "LT",
      "elevation": 259,
      "lat": 56.2421989441,
      "lon": 22.7331008911,
      "tz": "Europe\/Vilnius"
  },
  "EYND": {
      "icao": "EYND",
      "iata": "",
      "name": "Nida Airport",
      "city": "Nida",
      "state": "",
      "country": "LT",
      "elevation": 0,
      "lat": 55.3277778625,
      "lon": 21.0458335876,
      "tz": "Europe\/Vilnius"
  },
  "EYPA": {
      "icao": "EYPA",
      "iata": "PLQ",
      "name": "Palanga International Airport",
      "city": "Palanga",
      "state": "",
      "country": "LT",
      "elevation": 33,
      "lat": 55.9732017517,
      "lon": 21.0939006805,
      "tz": "Europe\/Vilnius"
  },
  "EYPI": {
      "icao": "EYPI",
      "iata": "",
      "name": "Panevezys Istra Airport",
      "city": "Panevezys \/ \u00a0Istra",
      "state": "Panevezys",
      "country": "LT",
      "elevation": 171,
      "lat": 55.8274993896,
      "lon": 24.3582992554,
      "tz": "Europe\/Vilnius"
  },
  "EYPN": {
      "icao": "EYPN",
      "iata": "",
      "name": "Panevezys Airport",
      "city": "Panevezys",
      "state": "Panevezys",
      "country": "LT",
      "elevation": 171,
      "lat": 55.7057418823,
      "lon": 24.3392658234,
      "tz": "Europe\/Vilnius"
  },
  "EYPP": {
      "icao": "EYPP",
      "iata": "PNV",
      "name": "Panevezys Air Base",
      "city": "Panevezys",
      "state": "Panevezys",
      "country": "LT",
      "elevation": 197,
      "lat": 55.7294006348,
      "lon": 24.4608001709,
      "tz": "Europe\/Vilnius"
  },
  "EYPR": {
      "icao": "EYPR",
      "iata": "",
      "name": "Pociunai Airport",
      "city": "Pociunai",
      "state": "",
      "country": "LT",
      "elevation": 200,
      "lat": 54.6493988037,
      "lon": 24.0618991852,
      "tz": "Europe\/Vilnius"
  },
  "EYRD": {
      "icao": "EYRD",
      "iata": "",
      "name": "Rudiskes Airport",
      "city": "Rudiskes",
      "state": "Vilnius",
      "country": "LT",
      "elevation": 522,
      "lat": 54.4956016541,
      "lon": 24.7175006866,
      "tz": "Europe\/Vilnius"
  },
  "EYRK": {
      "icao": "EYRK",
      "iata": "",
      "name": "Rokiskis Airport",
      "city": "Rokiskis",
      "state": "Panevezys",
      "country": "LT",
      "elevation": 459,
      "lat": 55.9719238281,
      "lon": 25.6042385101,
      "tz": "Europe\/Vilnius"
  },
  "EYRO": {
      "icao": "EYRO",
      "iata": "",
      "name": "Rojunai Airport",
      "city": "Rojunai",
      "state": "Panevezys",
      "country": "LT",
      "elevation": 180,
      "lat": 55.6108016968,
      "lon": 24.2208003998,
      "tz": "Europe\/Vilnius"
  },
  "EYRU": {
      "icao": "EYRU",
      "iata": "",
      "name": "Jonava Airport",
      "city": "Jonava",
      "state": "",
      "country": "LT",
      "elevation": 246,
      "lat": 55.0099983215,
      "lon": 24.3633003235,
      "tz": "Europe\/Vilnius"
  },
  "EYSA": {
      "icao": "EYSA",
      "iata": "SQQ",
      "name": "Siauliai International Airport",
      "city": "Siauliai",
      "state": "",
      "country": "LT",
      "elevation": 443,
      "lat": 55.893901825,
      "lon": 23.3950004578,
      "tz": "Europe\/Vilnius"
  },
  "EYSB": {
      "icao": "EYSB",
      "iata": "HLJ",
      "name": "Barysiai Airport",
      "city": "Barysiai",
      "state": "Siauliai",
      "country": "LT",
      "elevation": 270,
      "lat": 56.0705986023,
      "lon": 23.5580997467,
      "tz": "Europe\/Vilnius"
  },
  "EYSE": {
      "icao": "EYSE",
      "iata": "",
      "name": "Seduva Airport",
      "city": "Seduva \/ Siauliai",
      "state": "Siauliai",
      "country": "LT",
      "elevation": 259,
      "lat": 55.7463989258,
      "lon": 23.8047008514,
      "tz": "Europe\/Vilnius"
  },
  "EYSI": {
      "icao": "EYSI",
      "iata": "",
      "name": "Silute Air Base",
      "city": "Silute",
      "state": "Klaipeda-County",
      "country": "LT",
      "elevation": 59,
      "lat": 55.3368988037,
      "lon": 21.5305995941,
      "tz": "Europe\/Vilnius"
  },
  "EYTL": {
      "icao": "EYTL",
      "iata": "",
      "name": "Telsiai Airport",
      "city": "Telsiai",
      "state": "Telsiai",
      "country": "LT",
      "elevation": 410,
      "lat": 55.9864006042,
      "lon": 22.2877998352,
      "tz": "Europe\/Vilnius"
  },
  "EYUT": {
      "icao": "EYUT",
      "iata": "",
      "name": "Utena Airport",
      "city": "Utena",
      "state": "Utena",
      "country": "LT",
      "elevation": 620,
      "lat": 55.4900016785,
      "lon": 25.7168998718,
      "tz": "Europe\/Vilnius"
  },
  "EYVI": {
      "icao": "EYVI",
      "iata": "VNO",
      "name": "Vilnius International Airport",
      "city": "Vilnius",
      "state": "Vilnius",
      "country": "LT",
      "elevation": 646,
      "lat": 54.6341018677,
      "lon": 25.2858009338,
      "tz": "Europe\/Vilnius"
  },
  "EYVK": {
      "icao": "EYVK",
      "iata": "",
      "name": "Kyviskes Airfield",
      "city": "Kyviskes",
      "state": "Vilnius",
      "country": "LT",
      "elevation": 541,
      "lat": 54.6683006287,
      "lon": 25.5158004761,
      "tz": "Europe\/Vilnius"
  },
  "EYVP": {
      "icao": "EYVP",
      "iata": "",
      "name": "Paluknys Airport",
      "city": "Paluknys",
      "state": "Vilnius",
      "country": "LT",
      "elevation": 400,
      "lat": 54.4848480225,
      "lon": 24.9873733521,
      "tz": "Europe\/Vilnius"
  },
  "EYZA": {
      "icao": "EYZA",
      "iata": "",
      "name": "Zarasai Airport",
      "city": "Zarasai",
      "state": "Utena",
      "country": "LT",
      "elevation": 541,
      "lat": 55.7524986267,
      "lon": 26.2569007874,
      "tz": "Europe\/Riga"
  },
  "FA03": {
      "icao": "FA03",
      "iata": "",
      "name": "Southfork Airport",
      "city": "Duette",
      "state": "Florida",
      "country": "US",
      "elevation": 109,
      "lat": 27.6039009094,
      "lon": -82.1929016113,
      "tz": "America\/New_York"
  },
  "FA08": {
      "icao": "FA08",
      "iata": "",
      "name": "Orlampa Inc Airport",
      "city": "Polk City",
      "state": "Florida",
      "country": "US",
      "elevation": 139,
      "lat": 28.1669998169,
      "lon": -81.8080978394,
      "tz": "America\/New_York"
  },
  "FA09": {
      "icao": "FA09",
      "iata": "",
      "name": "Arliss M Airport",
      "city": "Fairfield",
      "state": "Florida",
      "country": "US",
      "elevation": 200,
      "lat": 29.3407993317,
      "lon": -82.2637023926,
      "tz": "America\/New_York"
  },
  "FA11": {
      "icao": "FA11",
      "iata": "",
      "name": "Bird Field",
      "city": "Jennings",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.6224002838,
      "lon": -83.2593002319,
      "tz": "America\/New_York"
  },
  "FA18": {
      "icao": "FA18",
      "iata": "",
      "name": "Devils Garden Strip",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 28.4510993958,
      "lon": -81.0002975464,
      "tz": "America\/New_York"
  },
  "FA24": {
      "icao": "FA24",
      "iata": "",
      "name": "Horseshoe Acres Airpark",
      "city": "Indiantown",
      "state": "Florida",
      "country": "US",
      "elevation": 9,
      "lat": 27.0932998657,
      "lon": -80.612197876,
      "tz": "America\/New_York"
  },
  "FA25": {
      "icao": "FA25",
      "iata": "",
      "name": "Black Creek Pass Airport",
      "city": "Tallahasse",
      "state": "Florida",
      "country": "US",
      "elevation": 95,
      "lat": 30.50095,
      "lon": -84.0781,
      "tz": "America\/New_York"
  },
  "FA26": {
      "icao": "FA26",
      "iata": "",
      "name": "Dogwood Farm Airport",
      "city": "Havana",
      "state": "Florida",
      "country": "US",
      "elevation": 200,
      "lat": 30.5372009277,
      "lon": -84.4518966675,
      "tz": "America\/New_York"
  },
  "FA27": {
      "icao": "FA27",
      "iata": "",
      "name": "Ellsworth Field",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 27.2056007385,
      "lon": -81.7771987915,
      "tz": "America\/New_York"
  },
  "FA29": {
      "icao": "FA29",
      "iata": "",
      "name": "Lumar Field",
      "city": "Perry",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 30.1308002472,
      "lon": -83.5494003296,
      "tz": "America\/New_York"
  },
  "FA30": {
      "icao": "FA30",
      "iata": "",
      "name": "Redtail Airstrip",
      "city": "Morriston",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 29.2791996002,
      "lon": -82.5,
      "tz": "America\/New_York"
  },
  "FA32": {
      "icao": "FA32",
      "iata": "",
      "name": "Circle P Airport",
      "city": "Perry",
      "state": "Florida",
      "country": "US",
      "elevation": 83,
      "lat": 30.1033000946,
      "lon": -83.450302124,
      "tz": "America\/New_York"
  },
  "FA35": {
      "icao": "FA35",
      "iata": "",
      "name": "Lindbergh's Landing Airport",
      "city": "Miami",
      "state": "Florida",
      "country": "US",
      "elevation": 9,
      "lat": 25.6194992065,
      "lon": -80.4887008667,
      "tz": "America\/New_York"
  },
  "FA36": {
      "icao": "FA36",
      "iata": "",
      "name": "White Farms Airport",
      "city": "Chiefland",
      "state": "Florida",
      "country": "US",
      "elevation": 46,
      "lat": 29.5126991272,
      "lon": -82.8748016357,
      "tz": "America\/New_York"
  },
  "FA37": {
      "icao": "FA37",
      "iata": "",
      "name": "Wing South Airpark",
      "city": "Naples",
      "state": "Florida",
      "country": "US",
      "elevation": 9,
      "lat": 26.1168003082,
      "lon": -81.7031021118,
      "tz": "America\/New_York"
  },
  "FA38": {
      "icao": "FA38",
      "iata": "",
      "name": "Woods and Lakes Airpark",
      "city": "Oklawaha",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 29.1236000061,
      "lon": -81.8870010376,
      "tz": "America\/New_York"
  },
  "FA40": {
      "icao": "FA40",
      "iata": "",
      "name": "Hidden Lake Airport",
      "city": "New Port Richey",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 28.2824993134,
      "lon": -82.6456985474,
      "tz": "America\/New_York"
  },
  "FA42": {
      "icao": "FA42",
      "iata": "",
      "name": "Sheets Airport",
      "city": "Groveland",
      "state": "Florida",
      "country": "US",
      "elevation": 118,
      "lat": 28.5328998566,
      "lon": -81.8467025757,
      "tz": "America\/New_York"
  },
  "FA43": {
      "icao": "FA43",
      "iata": "",
      "name": "Dog Island Airport",
      "city": "Carrabelle",
      "state": "Florida",
      "country": "US",
      "elevation": 4,
      "lat": 29.8085002899,
      "lon": -84.595703125,
      "tz": "America\/New_York"
  },
  "FA44": {
      "icao": "FA44",
      "iata": "",
      "name": "Willis Gliderport",
      "city": "Boynton Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 26.5480995178,
      "lon": -80.2147979736,
      "tz": "America\/New_York"
  },
  "FA46": {
      "icao": "FA46",
      "iata": "",
      "name": "Henderson Field",
      "city": "Minneola",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 28.633600235,
      "lon": -81.7080993652,
      "tz": "America\/New_York"
  },
  "FA49": {
      "icao": "FA49",
      "iata": "",
      "name": "Shady International Airport",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 102,
      "lat": 29.0960998535,
      "lon": -82.1790008545,
      "tz": "America\/New_York"
  },
  "FA50": {
      "icao": "FA50",
      "iata": "",
      "name": "Wings-N-Wheels Airport",
      "city": "Reddick",
      "state": "Florida",
      "country": "US",
      "elevation": 82,
      "lat": 29.3614006042,
      "lon": -82.1498031616,
      "tz": "America\/New_York"
  },
  "FA51": {
      "icao": "FA51",
      "iata": "",
      "name": "William's Sky Manor Airport",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 15,
      "lat": 26.7390003204,
      "lon": -81.5062026978,
      "tz": "America\/New_York"
  },
  "FA54": {
      "icao": "FA54",
      "iata": "",
      "name": "Coral Creek Airport",
      "city": "Placida",
      "state": "Florida",
      "country": "US",
      "elevation": 4,
      "lat": 26.8544998169,
      "lon": -82.2511978149,
      "tz": "America\/New_York"
  },
  "FA55": {
      "icao": "FA55",
      "iata": "",
      "name": "Garnair Airport",
      "city": "Bonifay",
      "state": "Florida",
      "country": "US",
      "elevation": 105,
      "lat": 30.7796993256,
      "lon": -85.7683029175,
      "tz": "America\/Chicago"
  },
  "FA60": {
      "icao": "FA60",
      "iata": "",
      "name": "Vince's Condominium Association Airport",
      "city": "Lake Placid",
      "state": "Florida",
      "country": "US",
      "elevation": 115,
      "lat": 27.238899231,
      "lon": -81.3859024048,
      "tz": "America\/New_York"
  },
  "FA62": {
      "icao": "FA62",
      "iata": "",
      "name": "Santa Fe River Ranch Airport",
      "city": "Alachua",
      "state": "Florida",
      "country": "US",
      "elevation": 145,
      "lat": 29.9169006348,
      "lon": -82.4832000732,
      "tz": "America\/New_York"
  },
  "FA63": {
      "icao": "FA63",
      "iata": "",
      "name": "Leffler Airport",
      "city": "Alamana",
      "state": "Florida",
      "country": "US",
      "elevation": 43,
      "lat": 28.9636001587,
      "lon": -81.1213989258,
      "tz": "America\/New_York"
  },
  "FA64": {
      "icao": "FA64",
      "iata": "",
      "name": "Montgomery Ranch Airport",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 27.0744991302,
      "lon": -81.5865020752,
      "tz": "America\/New_York"
  },
  "FA69": {
      "icao": "FA69",
      "iata": "",
      "name": "Duda Airstrip",
      "city": "Belle Glade",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 26.5877990723,
      "lon": -80.637298584,
      "tz": "America\/New_York"
  },
  "FA71": {
      "icao": "FA71",
      "iata": "",
      "name": "The Cedars Airfield",
      "city": "Cedar Key",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 29.2280006409,
      "lon": -82.9437026978,
      "tz": "America\/New_York"
  },
  "FA72": {
      "icao": "FA72",
      "iata": "",
      "name": "Acres of Diamonds Airpark",
      "city": "Greenwood",
      "state": "Florida",
      "country": "US",
      "elevation": 134,
      "lat": 30.8862991333,
      "lon": -85.1638031006,
      "tz": "America\/Chicago"
  },
  "FA74": {
      "icao": "FA74",
      "iata": "",
      "name": "Romor Ranch Airport",
      "city": "Ochopee",
      "state": "Florida",
      "country": "US",
      "elevation": 12,
      "lat": 25.9808998108,
      "lon": -81.0567016602,
      "tz": "America\/New_York"
  },
  "FA77": {
      "icao": "FA77",
      "iata": "",
      "name": "Lowe's Airport",
      "city": "Sarasota",
      "state": "Florida",
      "country": "US",
      "elevation": 54,
      "lat": 27.3309001923,
      "lon": -82.2906036377,
      "tz": "America\/New_York"
  },
  "FA80": {
      "icao": "FA80",
      "iata": "",
      "name": "Lost Horn Ranch Airport",
      "city": "Ochopee",
      "state": "Florida",
      "country": "US",
      "elevation": 12,
      "lat": 25.9680995941,
      "lon": -81.0814971924,
      "tz": "America\/New_York"
  },
  "FA81": {
      "icao": "FA81",
      "iata": "",
      "name": "Tavernaero Park Airport",
      "city": "Tavernier",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 25.0086994171,
      "lon": -80.5330963135,
      "tz": "America\/New_York"
  },
  "FA83": {
      "icao": "FA83",
      "iata": "",
      "name": "Orlando North Airpark",
      "city": "Zellwood",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.7264003754,
      "lon": -81.6509017944,
      "tz": "America\/New_York"
  },
  "FA88": {
      "icao": "FA88",
      "iata": "",
      "name": "Pittman Oaks Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 83,
      "lat": 30.425042,
      "lon": -83.048469,
      "tz": "America\/New_York"
  },
  "FA99": {
      "icao": "FA99",
      "iata": "",
      "name": "Fantasy Field Airstrip",
      "city": "Chehalis",
      "state": "Washington",
      "country": "US",
      "elevation": 300,
      "lat": 46.587833,
      "lon": -122.865778,
      "tz": "America\/Los_Angeles"
  },
  "FAAB": {
      "icao": "FAAB",
      "iata": "ALJ",
      "name": "Alexander Bay Airport",
      "city": "Alexander Bay",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 98,
      "lat": -28.5750007629,
      "lon": 16.5333003998,
      "tz": "Africa\/Johannesburg"
  },
  "FAAD": {
      "icao": "FAAD",
      "iata": "",
      "name": "Adelaide Airport",
      "city": "Adelaide",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1955,
      "lat": -32.6831016541,
      "lon": 26.2942008972,
      "tz": "Africa\/Johannesburg"
  },
  "FAAE": {
      "icao": "FAAE",
      "iata": "",
      "name": "Aberdeen Airport",
      "city": "Aberdeen",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2461,
      "lat": -32.4667015076,
      "lon": 24.0666999817,
      "tz": "Africa\/Johannesburg"
  },
  "FAAF": {
      "icao": "FAAF",
      "iata": "",
      "name": "Andrew's Field Airport",
      "city": "Struisbaai",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 30,
      "lat": -34.7628898621,
      "lon": 20.0349159241,
      "tz": "Africa\/Johannesburg"
  },
  "FAAG": {
      "icao": "FAAG",
      "iata": "AGZ",
      "name": "Aggeneys Airport",
      "city": "Aggeneys",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2648,
      "lat": -29.2817993164,
      "lon": 18.8138999939,
      "tz": "Africa\/Johannesburg"
  },
  "FAAK": {
      "icao": "FAAK",
      "iata": "",
      "name": "Askham Airport",
      "city": "Askham",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2801,
      "lat": -26.9869441986,
      "lon": 20.7772216797,
      "tz": "Africa\/Johannesburg"
  },
  "FAAL": {
      "icao": "FAAL",
      "iata": "ADY",
      "name": "Alldays Airport",
      "city": "Alldays",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 2600,
      "lat": -22.6790008545,
      "lon": 29.0555000305,
      "tz": "Africa\/Johannesburg"
  },
  "FAAM": {
      "icao": "FAAM",
      "iata": "",
      "name": "Amsterdam Airport",
      "city": "Amsterdam",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 4310,
      "lat": -26.6333332062,
      "lon": 30.6000003815,
      "tz": "Africa\/Johannesburg"
  },
  "FAAN": {
      "icao": "FAAN",
      "iata": "",
      "name": "Aliwal North Airport",
      "city": "Aliwal North",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4405,
      "lat": -30.6800003052,
      "lon": 26.7299995422,
      "tz": "Africa\/Johannesburg"
  },
  "FAAP": {
      "icao": "FAAP",
      "iata": "",
      "name": "Aviators Paradise Field",
      "city": "",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3800,
      "lat": -25.69389,
      "lon": 27.7825073972,
      "tz": "Africa\/Johannesburg"
  },
  "FAAS": {
      "icao": "FAAS",
      "iata": "",
      "name": "Ashton Airport",
      "city": "Ashton",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 525,
      "lat": -33.8166656494,
      "lon": 20.0666675568,
      "tz": "Africa\/Johannesburg"
  },
  "FABA": {
      "icao": "FABA",
      "iata": "",
      "name": "Microland Flight Park",
      "city": "Bapsfontein",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5476,
      "lat": -25.9762557716,
      "lon": 28.3890151978,
      "tz": "Africa\/Johannesburg"
  },
  "FABB": {
      "icao": "FABB",
      "iata": "",
      "name": "Brakpan Airport",
      "city": "Brakpan",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5300,
      "lat": -26.2385997772,
      "lon": 28.3017997742,
      "tz": "Africa\/Johannesburg"
  },
  "FABD": {
      "icao": "FABD",
      "iata": "",
      "name": "Burghersdorp Airport",
      "city": "Burgersdorp",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 4734,
      "lat": -30.9775009155,
      "lon": 26.3080997467,
      "tz": "Africa\/Johannesburg"
  },
  "FABE": {
      "icao": "FABE",
      "iata": "BIY",
      "name": "Bisho Airport",
      "city": "Bisho",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1950,
      "lat": -32.8970985413,
      "lon": 27.2791004181,
      "tz": "Africa\/Johannesburg"
  },
  "FABF": {
      "icao": "FABF",
      "iata": "",
      "name": "Barkley East Airport",
      "city": "Barkley East",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 5940,
      "lat": -30.9666671753,
      "lon": 27.6041660309,
      "tz": "Africa\/Johannesburg"
  },
  "FABG": {
      "icao": "FABG",
      "iata": "",
      "name": "Buffelshoek Airport",
      "city": "",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1347,
      "lat": -24.702464,
      "lon": 31.587875,
      "tz": "Africa\/Johannesburg"
  },
  "FABH": {
      "icao": "FABH",
      "iata": "",
      "name": "Belfast Aerodrome",
      "city": "Belfast",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 6250,
      "lat": -25.6587417378,
      "lon": 30.041577816,
      "tz": "Africa\/Johannesburg"
  },
  "FABK": {
      "icao": "FABK",
      "iata": "",
      "name": "Bushmans Kloof Airport",
      "city": "Bushman's Kloof",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 1050,
      "lat": -32.035,
      "lon": 19.0435,
      "tz": "Africa\/Johannesburg"
  },
  "FABL": {
      "icao": "FABL",
      "iata": "BFN",
      "name": "J B M Hertzog International Airport",
      "city": "Bloemfontain",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4458,
      "lat": -29.0926990509,
      "lon": 26.302400589,
      "tz": "Africa\/Johannesburg"
  },
  "FABM": {
      "icao": "FABM",
      "iata": "",
      "name": "Bethlehem Airport",
      "city": "Bethlehem",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 5561,
      "lat": -28.2483997345,
      "lon": 28.3360996246,
      "tz": "Africa\/Johannesburg"
  },
  "FABO": {
      "icao": "FABO",
      "iata": "",
      "name": "Hendrik Potgieter Airport",
      "city": "Bothaville",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4236,
      "lat": -27.3668003082,
      "lon": 26.6291999817,
      "tz": "Africa\/Johannesburg"
  },
  "FABP": {
      "icao": "FABP",
      "iata": "",
      "name": "Black Rock Airport",
      "city": "Santoy",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3450,
      "lat": -27.129724,
      "lon": 22.846255,
      "tz": "Africa\/Johannesburg"
  },
  "FABR": {
      "icao": "FABR",
      "iata": "",
      "name": "Barberton Airport",
      "city": "Umjindi (Barberton)",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 2250,
      "lat": -25.7168687423,
      "lon": 30.9751796722,
      "tz": "Africa\/Johannesburg"
  },
  "FABS": {
      "icao": "FABS",
      "iata": "",
      "name": "Brits Airport",
      "city": "Brits",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3756,
      "lat": -25.5323009491,
      "lon": 27.7758998871,
      "tz": "Africa\/Johannesburg"
  },
  "FABT": {
      "icao": "FABT",
      "iata": "",
      "name": "Bethesda Road Airport",
      "city": "Bethesda",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 4630,
      "lat": -31.8731994629,
      "lon": 24.7968997955,
      "tz": "Africa\/Johannesburg"
  },
  "FABU": {
      "icao": "FABU",
      "iata": "UTE",
      "name": "Bultfontein Airport",
      "city": "Bultfontein",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4400,
      "lat": -28.273369,
      "lon": 26.135835,
      "tz": "Africa\/Johannesburg"
  },
  "FABV": {
      "icao": "FABV",
      "iata": "",
      "name": "Brandviei Airport",
      "city": "Brandviei",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2999,
      "lat": -30.4666996002,
      "lon": 20.4666996002,
      "tz": "Africa\/Johannesburg"
  },
  "FABW": {
      "icao": "FABW",
      "iata": "",
      "name": "Beaufort West Airport",
      "city": "Beaufort West",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 2941,
      "lat": -32.2999992371,
      "lon": 22.6700000763,
      "tz": "Africa\/Johannesburg"
  },
  "FABX": {
      "icao": "FABX",
      "iata": "",
      "name": "Beatrix Airport",
      "city": "Virginia",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4518,
      "lat": -28.2450008392,
      "lon": 26.771900177,
      "tz": "Africa\/Johannesburg"
  },
  "FABZ": {
      "icao": "FABZ",
      "iata": "",
      "name": "Bizana Airport",
      "city": "Bizana",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2740,
      "lat": -30.8603000641,
      "lon": 29.863899231,
      "tz": "Africa\/Johannesburg"
  },
  "FACA": {
      "icao": "FACA",
      "iata": "",
      "name": "Monte Carlo Airport",
      "city": "Monte Carlo",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4995,
      "lat": -28.7999992371,
      "lon": 27.4169998169,
      "tz": "Africa\/Johannesburg"
  },
  "FACB": {
      "icao": "FACB",
      "iata": "",
      "name": "Colesberg Airport",
      "city": "Colesberg",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4639,
      "lat": -30.7339000702,
      "lon": 25.0650005341,
      "tz": "Africa\/Johannesburg"
  },
  "FACC": {
      "icao": "FACC",
      "iata": "",
      "name": "Arathusa Safari Lodge Airport",
      "city": "Arathusa",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1200,
      "lat": -24.7441673279,
      "lon": 31.5224990845,
      "tz": "Africa\/Johannesburg"
  },
  "FACD": {
      "icao": "FACD",
      "iata": "CDO",
      "name": "Cradock Airport",
      "city": "Cradock",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 3110,
      "lat": -32.1567001343,
      "lon": 25.6455993652,
      "tz": "Africa\/Johannesburg"
  },
  "FACE": {
      "icao": "FACE",
      "iata": "",
      "name": "Ceres Airport",
      "city": "Ceres",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 1519,
      "lat": -33.3530006409,
      "lon": 19.3113994598,
      "tz": "Africa\/Johannesburg"
  },
  "FACF": {
      "icao": "FACF",
      "iata": "",
      "name": "Cap St Francis Airport",
      "city": "St Francis",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 131,
      "lat": -34.1869010925,
      "lon": 24.8349990845,
      "tz": "Africa\/Johannesburg"
  },
  "FACH": {
      "icao": "FACH",
      "iata": "",
      "name": "Cookhouse Airport",
      "city": "Cookhouse",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1893,
      "lat": -32.7447013855,
      "lon": 25.7963008881,
      "tz": "Africa\/Johannesburg"
  },
  "FACI": {
      "icao": "FACI",
      "iata": "",
      "name": "Citrusdal Airport",
      "city": "Citrusdal",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 532,
      "lat": -32.6176986694,
      "lon": 19.005399704,
      "tz": "Africa\/Johannesburg"
  },
  "FACK": {
      "icao": "FACK",
      "iata": "",
      "name": "Christiana Airport",
      "city": "Christiana",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3978,
      "lat": -27.8777008057,
      "lon": 25.2017993927,
      "tz": "Africa\/Johannesburg"
  },
  "FACL": {
      "icao": "FACL",
      "iata": "",
      "name": "Carolina Airport",
      "city": "Carolina",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5420,
      "lat": -26.0781002045,
      "lon": 30.0925006866,
      "tz": "Africa\/Johannesburg"
  },
  "FACN": {
      "icao": "FACN",
      "iata": "",
      "name": "Carnarvon Airport",
      "city": "Carnarvon",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4160,
      "lat": -30.9885997772,
      "lon": 22.1317005157,
      "tz": "Africa\/Johannesburg"
  },
  "FACO": {
      "icao": "FACO",
      "iata": "",
      "name": "Alkantpan Copper Airport",
      "city": "Cooperton",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3589,
      "lat": -29.9064006805,
      "lon": 22.3166999817,
      "tz": "Africa\/Johannesburg"
  },
  "FACR": {
      "icao": "FACR",
      "iata": "",
      "name": "Carltonville Airport",
      "city": "Carltonville",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5000,
      "lat": -26.3666667938,
      "lon": 27.3466663361,
      "tz": "Africa\/Johannesburg"
  },
  "FACT": {
      "icao": "FACT",
      "iata": "CPT",
      "name": "Cape Town International Airport",
      "city": "Cape Town",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 151,
      "lat": -33.9648017883,
      "lon": 18.6016998291,
      "tz": "Africa\/Johannesburg"
  },
  "FACV": {
      "icao": "FACV",
      "iata": "",
      "name": "Calvinia Airport",
      "city": "Calvinia",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3250,
      "lat": -31.5002994537,
      "lon": 19.72590065,
      "tz": "Africa\/Johannesburg"
  },
  "FACW": {
      "icao": "FACW",
      "iata": "",
      "name": "Clanwilliam Airport",
      "city": "Clanwilliam",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 650,
      "lat": -32.1831016541,
      "lon": 18.8885993958,
      "tz": "Africa\/Johannesburg"
  },
  "FACX": {
      "icao": "FACX",
      "iata": "",
      "name": "Cathcart Airport",
      "city": "Cathcart",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 3959,
      "lat": -32.2871017456,
      "lon": 27.1387996674,
      "tz": "Africa\/Johannesburg"
  },
  "FACY": {
      "icao": "FACY",
      "iata": "",
      "name": "Stilbaai Airport",
      "city": "Stilbaai",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 266,
      "lat": -34.3517990112,
      "lon": 21.4248008728,
      "tz": "Africa\/Johannesburg"
  },
  "FADA": {
      "icao": "FADA",
      "iata": "",
      "name": "De Aar Airport",
      "city": "De Aar",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4154,
      "lat": -30.692199707,
      "lon": 24.0205993652,
      "tz": "Africa\/Johannesburg"
  },
  "FADB": {
      "icao": "FADB",
      "iata": "",
      "name": "Dwaalboom Airport",
      "city": "Dwaalboom",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3652,
      "lat": -24.8071994781,
      "lon": 26.82970047,
      "tz": "Africa\/Johannesburg"
  },
  "FADD": {
      "icao": "FADD",
      "iata": "",
      "name": "Dundee Airport",
      "city": "Dundee",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4219,
      "lat": -28.1830997467,
      "lon": 30.2224998474,
      "tz": "Africa\/Johannesburg"
  },
  "FADE": {
      "icao": "FADE",
      "iata": "",
      "name": "Delmas Airport",
      "city": "Delmas",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5075,
      "lat": -26.1416664124,
      "lon": 28.6916675568,
      "tz": "Africa\/Johannesburg"
  },
  "FADG": {
      "icao": "FADG",
      "iata": "",
      "name": "Dordrecht Airport",
      "city": "Dordrecht",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 5200,
      "lat": -31.3833332062,
      "lon": 27.0333328247,
      "tz": "Africa\/Johannesburg"
  },
  "FADH": {
      "icao": "FADH",
      "iata": "",
      "name": "Durnacol Airport",
      "city": "Durnacol",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4317,
      "lat": -28.0461006165,
      "lon": 30.0063991547,
      "tz": "Africa\/Johannesburg"
  },
  "FADK": {
      "icao": "FADK",
      "iata": "DUK",
      "name": "Mubatuba Airport",
      "city": "Mubatuba",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 210,
      "lat": -28.3684005737,
      "lon": 32.2481002808,
      "tz": "Africa\/Johannesburg"
  },
  "FADL": {
      "icao": "FADL",
      "iata": "",
      "name": "Delareyville Airport",
      "city": "Delareyville",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4469,
      "lat": -26.6791992188,
      "lon": 25.4747009277,
      "tz": "Africa\/Johannesburg"
  },
  "FADM": {
      "icao": "FADM",
      "iata": "",
      "name": "Kokstad Airport",
      "city": "Kokstad",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4084,
      "lat": -30.5564994812,
      "lon": 29.4081993103,
      "tz": "Africa\/Johannesburg"
  },
  "FADN": {
      "icao": "FADN",
      "iata": "",
      "name": "Durban International Airport",
      "city": "Durban",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 33,
      "lat": -29.9701004028,
      "lon": 30.9505004883,
      "tz": "Africa\/Johannesburg"
  },
  "FADO": {
      "icao": "FADO",
      "iata": "",
      "name": "Dendron Airport",
      "city": "Dendron",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3399,
      "lat": -23.3805999756,
      "lon": 29.3208007813,
      "tz": "Africa\/Johannesburg"
  },
  "FADP": {
      "icao": "FADP",
      "iata": "",
      "name": "Darlington Dam Lodge Airport",
      "city": "Port Elizabeth",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 847,
      "lat": -33.1832008362,
      "lon": 25.1931991577,
      "tz": "Africa\/Johannesburg"
  },
  "FADQ": {
      "icao": "FADQ",
      "iata": "PZL",
      "name": "Zulu Inyala Airport",
      "city": "Phinda",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 160,
      "lat": -27.8493995667,
      "lon": 32.3097000122,
      "tz": "Africa\/Johannesburg"
  },
  "FADR": {
      "icao": "FADR",
      "iata": "",
      "name": "Dunnottar Airport",
      "city": "Dunnottar",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5241,
      "lat": -26.3497009277,
      "lon": 28.4419002533,
      "tz": "Africa\/Johannesburg"
  },
  "FADS": {
      "icao": "FADS",
      "iata": "",
      "name": "De Doorns Airport",
      "city": "De Doorns",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 1626,
      "lat": -33.455557,
      "lon": 19.684493,
      "tz": "Africa\/Johannesburg"
  },
  "FADU": {
      "icao": "FADU",
      "iata": "",
      "name": "Walkersons Field",
      "city": "",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 6041,
      "lat": -25.36278,
      "lon": 30.18778,
      "tz": "Africa\/Johannesburg"
  },
  "FADV": {
      "icao": "FADV",
      "iata": "",
      "name": "Devon Airport",
      "city": "Devon",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5460,
      "lat": -26.3589992523,
      "lon": 28.7896995544,
      "tz": "Africa\/Johannesburg"
  },
  "FADX": {
      "icao": "FADX",
      "iata": "",
      "name": "Delta 200 Airstrip",
      "city": "Koeberg",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 220,
      "lat": -33.6494935368,
      "lon": 18.4718799591,
      "tz": "Africa\/Johannesburg"
  },
  "FADY": {
      "icao": "FADY",
      "iata": "",
      "name": "De Aar Military Airport",
      "city": "De Aar",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4037,
      "lat": -30.6365089417,
      "lon": 23.9197254181,
      "tz": "Africa\/Johannesburg"
  },
  "FADZ": {
      "icao": "FADZ",
      "iata": "",
      "name": "Drakensberg Gardens Airport",
      "city": "Drakensberg Gardens",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 5948,
      "lat": -29.75,
      "lon": 29.25,
      "tz": "Africa\/Johannesburg"
  },
  "FAEC": {
      "icao": "FAEC",
      "iata": "",
      "name": "Estcourt Airport",
      "city": "Estcourt",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4262,
      "lat": -29.0471992493,
      "lon": 29.9106006622,
      "tz": "Africa\/Johannesburg"
  },
  "FAED": {
      "icao": "FAED",
      "iata": "",
      "name": "Edenburg Airport",
      "city": "Edenburg",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4509,
      "lat": -29.75,
      "lon": 25.8999996185,
      "tz": "Africa\/Johannesburg"
  },
  "FAEG": {
      "icao": "FAEG",
      "iata": "",
      "name": "Egnep Airport",
      "city": "Egnep",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 2299,
      "lat": -24.5669994354,
      "lon": 30.3169994354,
      "tz": "Africa\/Johannesburg"
  },
  "FAEL": {
      "icao": "FAEL",
      "iata": "ELS",
      "name": "Ben Schoeman Airport",
      "city": "East London",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 435,
      "lat": -33.0355987549,
      "lon": 27.8258991241,
      "tz": "Africa\/Johannesburg"
  },
  "FAEM": {
      "icao": "FAEM",
      "iata": "EMG",
      "name": "Empangeni Airport",
      "city": "Empangeni",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 251,
      "lat": -28.7199993134,
      "lon": 31.8899993896,
      "tz": "Africa\/Johannesburg"
  },
  "FAEO": {
      "icao": "FAEO",
      "iata": "",
      "name": "Ermelo Airport",
      "city": "Ermelo",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5700,
      "lat": -26.4955997467,
      "lon": 29.9797992706,
      "tz": "Africa\/Johannesburg"
  },
  "FAER": {
      "icao": "FAER",
      "iata": "ELL",
      "name": "Ellisras Matimba Airport",
      "city": "Ellisras",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 2799,
      "lat": -23.7266998291,
      "lon": 27.6882991791,
      "tz": "Africa\/Johannesburg"
  },
  "FAES": {
      "icao": "FAES",
      "iata": "",
      "name": "Eshowe Airport",
      "city": "Eshowe",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 1601,
      "lat": -28.8801994324,
      "lon": 31.4549007416,
      "tz": "Africa\/Johannesburg"
  },
  "FAET": {
      "icao": "FAET",
      "iata": "",
      "name": "Elliot Airport",
      "city": "Elliot",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 5028,
      "lat": -31.3069000244,
      "lon": 27.8495006561,
      "tz": "Africa\/Johannesburg"
  },
  "FAFB": {
      "icao": "FAFB",
      "iata": "FCB",
      "name": "Ficksburg Sentraoes Airport",
      "city": "Ficksburg",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 5315,
      "lat": -28.8230991364,
      "lon": 27.9088993073,
      "tz": "Africa\/Johannesburg"
  },
  "FAFF": {
      "icao": "FAFF",
      "iata": "",
      "name": "Frankfort Airport",
      "city": "Frankfort",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 5176,
      "lat": -27.2854003906,
      "lon": 28.5153007507,
      "tz": "Africa\/Johannesburg"
  },
  "FAFG": {
      "icao": "FAFG",
      "iata": "",
      "name": "Flamingo Vlei Airport",
      "city": "Flamingo Vlei",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3248,
      "lat": -30.4909992218,
      "lon": 21.1217002869,
      "tz": "Africa\/Johannesburg"
  },
  "FAFK": {
      "icao": "FAFK",
      "iata": "",
      "name": "Fisantekraal Airport",
      "city": "Fisantekraal",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 410,
      "lat": -33.7700004578,
      "lon": 18.7399997711,
      "tz": "Africa\/Johannesburg"
  },
  "FAFO": {
      "icao": "FAFO",
      "iata": "",
      "name": "Fort Beaufort Airport",
      "city": "Fort Beaufort",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1581,
      "lat": -32.7905006409,
      "lon": 26.5776996613,
      "tz": "Africa\/Johannesburg"
  },
  "FAFR": {
      "icao": "FAFR",
      "iata": "",
      "name": "Fraserburg Airport",
      "city": "Fraseburg",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4180,
      "lat": -31.9339008331,
      "lon": 21.5025005341,
      "tz": "Africa\/Johannesburg"
  },
  "FAFU": {
      "icao": "FAFU",
      "iata": "",
      "name": "Fraaiuitzicht Airport",
      "city": "Fraaiuitzicht",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4841,
      "lat": -27.3169994354,
      "lon": 27.25,
      "tz": "Africa\/Johannesburg"
  },
  "FAFW": {
      "icao": "FAFW",
      "iata": "",
      "name": "Freeway Airport",
      "city": "Kromdraai",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 3819,
      "lat": -25.4771995544,
      "lon": 28.2900009155,
      "tz": "Africa\/Johannesburg"
  },
  "FAGA": {
      "icao": "FAGA",
      "iata": "",
      "name": "Grange Airport",
      "city": "Umzimkulu",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2600,
      "lat": -30.2670001984,
      "lon": 29.966999054,
      "tz": "Africa\/Johannesburg"
  },
  "FAGC": {
      "icao": "FAGC",
      "iata": "GCJ",
      "name": "Grand Central Airport",
      "city": "Midrand",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5325,
      "lat": -25.9862995148,
      "lon": 28.1401004791,
      "tz": "Africa\/Johannesburg"
  },
  "FAGG": {
      "icao": "FAGG",
      "iata": "GRJ",
      "name": "George Airport",
      "city": "George",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 648,
      "lat": -34.0055999756,
      "lon": 22.378900528,
      "tz": "Africa\/Johannesburg"
  },
  "FAGH": {
      "icao": "FAGH",
      "iata": "",
      "name": "Glen Grey Airport",
      "city": "Emmaus",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 3500,
      "lat": -28.8333339691,
      "lon": 29.4666671753,
      "tz": "Africa\/Johannesburg"
  },
  "FAGI": {
      "icao": "FAGI",
      "iata": "GIY",
      "name": "Giyani Airport",
      "city": "Giyani",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1584,
      "lat": -23.2833003998,
      "lon": 30.6499996185,
      "tz": "Africa\/Johannesburg"
  },
  "FAGJ": {
      "icao": "FAGJ",
      "iata": "",
      "name": "Gifvlei Airport",
      "city": "Gifvlei",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3199,
      "lat": -30.3833007813,
      "lon": 20.0333003998,
      "tz": "Africa\/Johannesburg"
  },
  "FAGL": {
      "icao": "FAGL",
      "iata": "",
      "name": "Groblersdal Kob Airport",
      "city": "Groblersdal",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 3051,
      "lat": -25.1868991852,
      "lon": 29.4032993317,
      "tz": "Africa\/Johannesburg"
  },
  "FAGM": {
      "icao": "FAGM",
      "iata": "QRA",
      "name": "Rand Airport",
      "city": "Johannesburg",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5483,
      "lat": -26.2425003052,
      "lon": 28.1511993408,
      "tz": "Africa\/Johannesburg"
  },
  "FAGO": {
      "icao": "FAGO",
      "iata": "",
      "name": "Gowrie Airport",
      "city": "",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1200,
      "lat": -24.7371653036,
      "lon": 31.5602874756,
      "tz": "Africa\/Johannesburg"
  },
  "FAGR": {
      "icao": "FAGR",
      "iata": "",
      "name": "Graaff Reinet Airport",
      "city": "Graaff-Reinet",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2604,
      "lat": -32.1935997009,
      "lon": 24.5414009094,
      "tz": "Africa\/Johannesburg"
  },
  "FAGS": {
      "icao": "FAGS",
      "iata": "",
      "name": "Giants Castle Airport",
      "city": "Giant'S Castle Game Reserve",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 6086,
      "lat": -29.2696990967,
      "lon": 29.5660991669,
      "tz": "Africa\/Johannesburg"
  },
  "FAGT": {
      "icao": "FAGT",
      "iata": "",
      "name": "Grahamstown Airport",
      "city": "Grahamstown",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2135,
      "lat": -33.2846984863,
      "lon": 26.4981002808,
      "tz": "Africa\/Johannesburg"
  },
  "FAGV": {
      "icao": "FAGV",
      "iata": "",
      "name": "Gravelotte Airport",
      "city": "Gravelotte",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1650,
      "lat": -23.9167003632,
      "lon": 30.7000007629,
      "tz": "Africa\/Johannesburg"
  },
  "FAGW": {
      "icao": "FAGW",
      "iata": "",
      "name": "Magwa Airport",
      "city": "Magwa",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1823,
      "lat": -31.3939418793,
      "lon": 29.6934700012,
      "tz": "Africa\/Johannesburg"
  },
  "FAGY": {
      "icao": "FAGY",
      "iata": "",
      "name": "Greytown Airport",
      "city": "Greytown",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 3531,
      "lat": -29.1219997406,
      "lon": 30.5867004395,
      "tz": "Africa\/Johannesburg"
  },
  "FAHA": {
      "icao": "FAHA",
      "iata": "",
      "name": "Harmony Airport",
      "city": "Virginia",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4399,
      "lat": -28.0786991119,
      "lon": 26.8612003326,
      "tz": "Africa\/Johannesburg"
  },
  "FAHB": {
      "icao": "FAHB",
      "iata": "",
      "name": "Hartebeespoortdam Airport",
      "city": "Hartebeespoort",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3740,
      "lat": -24.5023994446,
      "lon": 28.0790996552,
      "tz": "Africa\/Johannesburg"
  },
  "FAHC": {
      "icao": "FAHC",
      "iata": "",
      "name": "Howick Airport",
      "city": "Howick",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 3619,
      "lat": -29.5527992249,
      "lon": 30.2110996246,
      "tz": "Africa\/Johannesburg"
  },
  "FAHD": {
      "icao": "FAHD",
      "iata": "",
      "name": "Humansdorp Airport",
      "city": "Humansdorp",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 345,
      "lat": -34.0365982056,
      "lon": 24.7889003754,
      "tz": "Africa\/Johannesburg"
  },
  "FAHE": {
      "icao": "FAHE",
      "iata": "",
      "name": "Pullenshope Hendrina Airport",
      "city": "Hendrina",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5171,
      "lat": -25.9778003693,
      "lon": 29.6189002991,
      "tz": "Africa\/Johannesburg"
  },
  "FAHF": {
      "icao": "FAHF",
      "iata": "",
      "name": "Henrys Flats Airport",
      "city": "Henry'S Flats",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1709,
      "lat": -33.0461006165,
      "lon": 25.7168998718,
      "tz": "Africa\/Johannesburg"
  },
  "FAHG": {
      "icao": "FAHG",
      "iata": "",
      "name": "Heidelburg Airport",
      "city": "Heidelburg",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5089,
      "lat": -26.5100002289,
      "lon": 28.3899993896,
      "tz": "Africa\/Johannesburg"
  },
  "FAHH": {
      "icao": "FAHH",
      "iata": "",
      "name": "Hibberdene Airport",
      "city": "Hibberdene",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 89,
      "lat": -30.6299991608,
      "lon": 30.5200004578,
      "tz": "Africa\/Johannesburg"
  },
  "FAHI": {
      "icao": "FAHI",
      "iata": "",
      "name": "Halfweg Airport",
      "city": "Halfweg",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3159,
      "lat": -30.013299942,
      "lon": 20.1392002106,
      "tz": "Africa\/Johannesburg"
  },
  "FAHJ": {
      "icao": "FAHJ",
      "iata": "",
      "name": "Harding Airport",
      "city": "Harding",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 2890,
      "lat": -30.5666999817,
      "lon": 29.8833007813,
      "tz": "Africa\/Johannesburg"
  },
  "FAHK": {
      "icao": "FAHK",
      "iata": "",
      "name": "Haakdoornboom Airport",
      "city": "Haakdoornboom",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4055,
      "lat": -25.5872001648,
      "lon": 28.1158008575,
      "tz": "Africa\/Johannesburg"
  },
  "FAHL": {
      "icao": "FAHL",
      "iata": "HLW",
      "name": "Hluhluwe Airport",
      "city": "Hluhluwe",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 249,
      "lat": -28.0166049887,
      "lon": 32.2751712799,
      "tz": "Africa\/Johannesburg"
  },
  "FAHO": {
      "icao": "FAHO",
      "iata": "",
      "name": "Heilbron Airport",
      "city": "Heilbron",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 5200,
      "lat": -27.2786006927,
      "lon": 27.9955997467,
      "tz": "Africa\/Johannesburg"
  },
  "FAHP": {
      "icao": "FAHP",
      "iata": "",
      "name": "Hoopstad Airport",
      "city": "Hoopstad",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4131,
      "lat": -27.8216991425,
      "lon": 25.9169006348,
      "tz": "Africa\/Johannesburg"
  },
  "FAHR": {
      "icao": "FAHR",
      "iata": "HRS",
      "name": "Harrismith Airport",
      "city": "Harrismith",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 5585,
      "lat": -28.2350997925,
      "lon": 29.1061992645,
      "tz": "Africa\/Johannesburg"
  },
  "FAHS": {
      "icao": "FAHS",
      "iata": "HDS",
      "name": "Hoedspruit Air Force Base Airport",
      "city": "Hoedspruit",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1743,
      "lat": -24.3686008453,
      "lon": 31.0487003326,
      "tz": "Africa\/Johannesburg"
  },
  "FAHT": {
      "icao": "FAHT",
      "iata": "",
      "name": "Hoedspruit Airport",
      "city": "Hoedspruit",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1801,
      "lat": -24.3516998291,
      "lon": 30.9493999481,
      "tz": "Africa\/Johannesburg"
  },
  "FAHU": {
      "icao": "FAHU",
      "iata": "",
      "name": "H M S Bastard Memorial Airport",
      "city": "H.M.S.Bastard Memorial",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4701,
      "lat": -30.5424995422,
      "lon": 29.4990005493,
      "tz": "Africa\/Johannesburg"
  },
  "FAHV": {
      "icao": "FAHV",
      "iata": "",
      "name": "Gariep Dam Airport",
      "city": "Hendrik Verwoerd dam",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4176,
      "lat": -30.5622005463,
      "lon": 25.5282993317,
      "tz": "Africa\/Johannesburg"
  },
  "FAIA": {
      "icao": "FAIA",
      "iata": "",
      "name": "Itala Airport",
      "city": "Itala",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 2665,
      "lat": -27.486700058,
      "lon": 31.1711006165,
      "tz": "Africa\/Johannesburg"
  },
  "FAID": {
      "icao": "FAID",
      "iata": "",
      "name": "Idutywa Airport",
      "city": "Idutywa",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2530,
      "lat": -32.0999984741,
      "lon": 28.3166999817,
      "tz": "Africa\/Johannesburg"
  },
  "FAIO": {
      "icao": "FAIO",
      "iata": "",
      "name": "Odi Airport",
      "city": "Lerulaneng",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3862,
      "lat": -25.5499992371,
      "lon": 27.9330005646,
      "tz": "Africa\/Johannesburg"
  },
  "FAIS": {
      "icao": "FAIS",
      "iata": "",
      "name": "Isithebe Airport",
      "city": "Nyoni",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 313,
      "lat": -29.105556488,
      "lon": 31.4074993134,
      "tz": "Africa\/Johannesburg"
  },
  "FAIV": {
      "icao": "FAIV",
      "iata": "",
      "name": "Ingwavuma Airport",
      "city": "Ingwavuma",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 2100,
      "lat": -27.117099762,
      "lon": 32.0087013245,
      "tz": "Africa\/Johannesburg"
  },
  "FAIW": {
      "icao": "FAIW",
      "iata": "",
      "name": "Indwe Airport",
      "city": "Indwe",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 4167,
      "lat": -31.4833335876,
      "lon": 27.3500003815,
      "tz": "Africa\/Johannesburg"
  },
  "FAJF": {
      "icao": "FAJF",
      "iata": "",
      "name": "Jagersfontain Airport",
      "city": "Jagersfontain",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4714,
      "lat": -29.7625007629,
      "lon": 25.4330005646,
      "tz": "Africa\/Johannesburg"
  },
  "FAJP": {
      "icao": "FAJP",
      "iata": "",
      "name": "Joubertina Airport",
      "city": "Joubertina",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2007,
      "lat": -33.831199646,
      "lon": 23.8300991058,
      "tz": "Africa\/Johannesburg"
  },
  "FAJV": {
      "icao": "FAJV",
      "iata": "",
      "name": "Jansenville Airport",
      "city": "Jansenville",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1430,
      "lat": -32.9390983582,
      "lon": 24.6707000732,
      "tz": "Africa\/Johannesburg"
  },
  "FAKA": {
      "icao": "FAKA",
      "iata": "",
      "name": "Kagga Kamma Airport",
      "city": "Kagga Kamma Private Game Reserve",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 3494,
      "lat": -32.7469940186,
      "lon": 19.5620994568,
      "tz": "Africa\/Johannesburg"
  },
  "FAKB": {
      "icao": "FAKB",
      "iata": "",
      "name": "Kosibaai Airport",
      "city": "Kosi Bay",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 140,
      "lat": -27.0333328247,
      "lon": 32.75,
      "tz": "Africa\/Johannesburg"
  },
  "FAKD": {
      "icao": "FAKD",
      "iata": "KXE",
      "name": "P C Pelser Airport",
      "city": "Klerksdorp",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4444,
      "lat": -26.871099472,
      "lon": 26.718000412,
      "tz": "Africa\/Johannesburg"
  },
  "FAKE": {
      "icao": "FAKE",
      "iata": "",
      "name": "Keimouth Airport",
      "city": "Keimouth",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 222,
      "lat": -32.7000007629,
      "lon": 28.3500003815,
      "tz": "Africa\/Johannesburg"
  },
  "FAKF": {
      "icao": "FAKF",
      "iata": "",
      "name": "Koffee Bay Airport",
      "city": "Koffee Bay",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 10,
      "lat": -31.9638996124,
      "lon": 29.14909935,
      "tz": "Africa\/Johannesburg"
  },
  "FAKG": {
      "icao": "FAKG",
      "iata": "",
      "name": "Komati Power Station Airport",
      "city": "Komati Power Station",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5289,
      "lat": -26.0932998657,
      "lon": 29.4556007385,
      "tz": "Africa\/Johannesburg"
  },
  "FAKH": {
      "icao": "FAKH",
      "iata": "",
      "name": "Kenhardt Airport",
      "city": "Kenhardt",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2723,
      "lat": -29.3267002106,
      "lon": 21.1886005402,
      "tz": "Africa\/Johannesburg"
  },
  "FAKI": {
      "icao": "FAKI",
      "iata": "",
      "name": "Kobb Inn Airport",
      "city": "Kobb Inn",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 147,
      "lat": -32.433799,
      "lon": 28.683467,
      "tz": "Africa\/Johannesburg"
  },
  "FAKK": {
      "icao": "FAKK",
      "iata": "",
      "name": "Kakamas Airport",
      "city": "Kakamas",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2322,
      "lat": -28.7999992371,
      "lon": 20.6329994202,
      "tz": "Africa\/Johannesburg"
  },
  "FAKL": {
      "icao": "FAKL",
      "iata": "",
      "name": "Kriel Airport",
      "city": "Kriel",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5262,
      "lat": -26.2511005402,
      "lon": 29.1947002411,
      "tz": "Africa\/Johannesburg"
  },
  "FAKM": {
      "icao": "FAKM",
      "iata": "KIM",
      "name": "Kimberley Airport",
      "city": "Kimberley",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3950,
      "lat": -28.8027992249,
      "lon": 24.7651996613,
      "tz": "Africa\/Johannesburg"
  },
  "FAKN": {
      "icao": "FAKN",
      "iata": "MQP",
      "name": "Kruger Mpumalanga International Airport",
      "city": "Mpumalanga",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 2829,
      "lat": -25.3831996918,
      "lon": 31.1056003571,
      "tz": "Africa\/Johannesburg"
  },
  "FAKO": {
      "icao": "FAKO",
      "iata": "",
      "name": "Komga Airport",
      "city": "Komga",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2178,
      "lat": -32.5928001404,
      "lon": 27.8757991791,
      "tz": "Africa\/Johannesburg"
  },
  "FAKP": {
      "icao": "FAKP",
      "iata": "KOF",
      "name": "Komatipoort Airport",
      "city": "Komatipoort",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 541,
      "lat": -25.4402999878,
      "lon": 31.9300003052,
      "tz": "Africa\/Johannesburg"
  },
  "FAKR": {
      "icao": "FAKR",
      "iata": "",
      "name": "Krugersdorp Airport",
      "city": "Krugersdorp",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5499,
      "lat": -26.0809993744,
      "lon": 27.7257003784,
      "tz": "Africa\/Johannesburg"
  },
  "FAKS": {
      "icao": "FAKS",
      "iata": "",
      "name": "Kroonstad Airport",
      "city": "Kroonstad",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4700,
      "lat": -27.6606006622,
      "lon": 27.3157997131,
      "tz": "Africa\/Johannesburg"
  },
  "FAKT": {
      "icao": "FAKT",
      "iata": "",
      "name": "Kitty Hawk Airport",
      "city": "Boschkop",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4619,
      "lat": -25.8600006104,
      "lon": 28.4500007629,
      "tz": "Africa\/Johannesburg"
  },
  "FAKU": {
      "icao": "FAKU",
      "iata": "KMH",
      "name": "Johan Pienaar Airport",
      "city": "Kuruman",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4382,
      "lat": -27.4566993713,
      "lon": 23.4113998413,
      "tz": "Africa\/Johannesburg"
  },
  "FAKV": {
      "icao": "FAKV",
      "iata": "",
      "name": "Koffyfontein Min Airport",
      "city": "Koffyfontein",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 3996,
      "lat": -29.4393997192,
      "lon": 24.9885997772,
      "tz": "Africa\/Johannesburg"
  },
  "FAKW": {
      "icao": "FAKW",
      "iata": "",
      "name": "Kareedouw Airport",
      "city": "Kareedouw",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1050,
      "lat": -33.9666671753,
      "lon": 24.2999992371,
      "tz": "Africa\/Johannesburg"
  },
  "FAKX": {
      "icao": "FAKX",
      "iata": "",
      "name": "Kenton on Sea Airport",
      "city": "Kenton On Sea",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 172,
      "lat": -33.6693000793,
      "lon": 26.6308994293,
      "tz": "Africa\/Johannesburg"
  },
  "FAKZ": {
      "icao": "FAKZ",
      "iata": "KLZ",
      "name": "Kleinsee Airport",
      "city": "Kleinsee",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 270,
      "lat": -29.6884002686,
      "lon": 17.0939998627,
      "tz": "Africa\/Johannesburg"
  },
  "FALA": {
      "icao": "FALA",
      "iata": "HLA",
      "name": "Lanseria Airport",
      "city": "Johannesburg",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4517,
      "lat": -25.9384994507,
      "lon": 27.9260997772,
      "tz": "Africa\/Johannesburg"
  },
  "FALB": {
      "icao": "FALB",
      "iata": "",
      "name": "Ladybrand Af Airport",
      "city": "Ladybrand",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 5180,
      "lat": -29.1811008453,
      "lon": 27.4535999298,
      "tz": "Africa\/Johannesburg"
  },
  "FALC": {
      "icao": "FALC",
      "iata": "LMR",
      "name": "Lime Acres Finsch Mine Airport",
      "city": "Lime Acres",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4900,
      "lat": -28.3600997925,
      "lon": 23.4391002655,
      "tz": "Africa\/Johannesburg"
  },
  "FALD": {
      "icao": "FALD",
      "iata": "LDZ",
      "name": "Londolozi Airport",
      "city": "Londolozi",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1300,
      "lat": -24.7502002716,
      "lon": 31.4757003784,
      "tz": "Africa\/Johannesburg"
  },
  "FALE": {
      "icao": "FALE",
      "iata": "DUR",
      "name": "King Shaka International Airport",
      "city": "Durban",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 295,
      "lat": -29.6144444444,
      "lon": 31.1197222222,
      "tz": "Africa\/Johannesburg"
  },
  "FALF": {
      "icao": "FALF",
      "iata": "",
      "name": "Loeriesfontein Airport",
      "city": "Loeriesfontein",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2997,
      "lat": -30.9064006805,
      "lon": 19.4253005981,
      "tz": "Africa\/Johannesburg"
  },
  "FALH": {
      "icao": "FALH",
      "iata": "",
      "name": "Lohathla Military Airport",
      "city": "Lohathla",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4500,
      "lat": -28.0368003845,
      "lon": 23.0986995697,
      "tz": "Africa\/Johannesburg"
  },
  "FALI": {
      "icao": "FALI",
      "iata": "",
      "name": "Lichtenburg Airport",
      "city": "Lichtenburg",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4875,
      "lat": -26.175699234,
      "lon": 26.1846008301,
      "tz": "Africa\/Johannesburg"
  },
  "FALK": {
      "icao": "FALK",
      "iata": "LUJ",
      "name": "Lusikisiki Airport",
      "city": "Lusikisiki",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1831,
      "lat": -31.3666992188,
      "lon": 29.5832996368,
      "tz": "Africa\/Johannesburg"
  },
  "FALL": {
      "icao": "FALL",
      "iata": "",
      "name": "Lydenburg Airport",
      "city": "Lydenburg",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 4882,
      "lat": -25.1033000946,
      "lon": 30.4141998291,
      "tz": "Africa\/Johannesburg"
  },
  "FALM": {
      "icao": "FALM",
      "iata": "",
      "name": "Makhado Air Force Base Airport",
      "city": "Louis Trichardt Southwest",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3069,
      "lat": -23.1599006653,
      "lon": 29.6965007782,
      "tz": "Africa\/Johannesburg"
  },
  "FALO": {
      "icao": "FALO",
      "iata": "LCD",
      "name": "Louis Trichardt Airport",
      "city": "Louis Trichardt",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3025,
      "lat": -23.0618991852,
      "lon": 29.8647003174,
      "tz": "Africa\/Johannesburg"
  },
  "FALQ": {
      "icao": "FALQ",
      "iata": "",
      "name": "El Mirador Airport",
      "city": "Ardmore",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 3897,
      "lat": -28.9861106873,
      "lon": 29.4797229767,
      "tz": "Africa\/Johannesburg"
  },
  "FALR": {
      "icao": "FALR",
      "iata": "",
      "name": "Steytlerville Airport",
      "city": "Steytlerville",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1450,
      "lat": -33.3370018005,
      "lon": 24.3213996887,
      "tz": "Africa\/Johannesburg"
  },
  "FALS": {
      "icao": "FALS",
      "iata": "",
      "name": "Somersveld Airport",
      "city": "Somersveld",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 196,
      "lat": -33.2462272644,
      "lon": 18.4799976349,
      "tz": "Africa\/Johannesburg"
  },
  "FALW": {
      "icao": "FALW",
      "iata": "SDB",
      "name": "Langebaanweg Airport",
      "city": "Langebaanweg",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 108,
      "lat": -32.9688987732,
      "lon": 18.1602993011,
      "tz": "Africa\/Johannesburg"
  },
  "FALY": {
      "icao": "FALY",
      "iata": "LAY",
      "name": "Ladysmith Airport",
      "city": "Ladysmith",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 3548,
      "lat": -28.5816993713,
      "lon": 29.7497005463,
      "tz": "Africa\/Johannesburg"
  },
  "FAMA": {
      "icao": "FAMA",
      "iata": "",
      "name": "Matatiele Airport",
      "city": "Matatiele",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4762,
      "lat": -30.3222007751,
      "lon": 28.7942008972,
      "tz": "Africa\/Johannesburg"
  },
  "FAMB": {
      "icao": "FAMB",
      "iata": "",
      "name": "Middelburg Airport",
      "city": "Middelburg",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 4886,
      "lat": -25.6847991943,
      "lon": 29.4402008057,
      "tz": "Africa\/Johannesburg"
  },
  "FAMC": {
      "icao": "FAMC",
      "iata": "",
      "name": "Middelburg 2 Airport",
      "city": "Cape Town",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 4022,
      "lat": -31.5471992493,
      "lon": 25.0293998718,
      "tz": "Africa\/Johannesburg"
  },
  "FAMD": {
      "icao": "FAMD",
      "iata": "AAM",
      "name": "Malamala Airport",
      "city": "Malamala",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1124,
      "lat": -24.8180999756,
      "lon": 31.5445995331,
      "tz": "Africa\/Johannesburg"
  },
  "FAMF": {
      "icao": "FAMF",
      "iata": "",
      "name": "Malabar Airport",
      "city": "Malabar",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3348,
      "lat": -29.0118999481,
      "lon": 23.8474006653,
      "tz": "Africa\/Johannesburg"
  },
  "FAMG": {
      "icao": "FAMG",
      "iata": "MGH",
      "name": "Margate Airport",
      "city": "Margate",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 495,
      "lat": -30.8574008942,
      "lon": 30.343000412,
      "tz": "Africa\/Johannesburg"
  },
  "FAMH": {
      "icao": "FAMH",
      "iata": "MEZ",
      "name": "Musina(Messina) Airport",
      "city": "Musina",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1904,
      "lat": -22.3560009003,
      "lon": 29.9862003326,
      "tz": "Africa\/Johannesburg"
  },
  "FAMI": {
      "icao": "FAMI",
      "iata": "",
      "name": "Marble Hall Airport",
      "city": "Marble Hall",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 2980,
      "lat": -24.9890995026,
      "lon": 29.2831001282,
      "tz": "Africa\/Johannesburg"
  },
  "FAMJ": {
      "icao": "FAMJ",
      "iata": "",
      "name": "Majuba Power Station Airport",
      "city": "Amerspoort",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5600,
      "lat": -27.0792999268,
      "lon": 29.7784996033,
      "tz": "Africa\/Johannesburg"
  },
  "FAMK": {
      "icao": "FAMK",
      "iata": "",
      "name": "Mafeking Airport",
      "city": "Mafeking",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4201,
      "lat": -25.8166675568,
      "lon": 25.6166667938,
      "tz": "Africa\/Johannesburg"
  },
  "FAML": {
      "icao": "FAML",
      "iata": "",
      "name": "Manyani Game Lodge Airport",
      "city": "Mafeking",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4383,
      "lat": -25.8166999817,
      "lon": 25.7166996002,
      "tz": "Africa\/Johannesburg"
  },
  "FAMM": {
      "icao": "FAMM",
      "iata": "MBD",
      "name": "Mmabatho International Airport",
      "city": "Mafeking",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4181,
      "lat": -25.7984008789,
      "lon": 25.5480003357,
      "tz": "Africa\/Johannesburg"
  },
  "FAMN": {
      "icao": "FAMN",
      "iata": "LLE",
      "name": "Riverside Airport",
      "city": "Malelane",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1024,
      "lat": -25.4300003052,
      "lon": 31.5767002106,
      "tz": "Africa\/Johannesburg"
  },
  "FAMO": {
      "icao": "FAMO",
      "iata": "MZY",
      "name": "Mossel Bay Airport",
      "city": "Mossel Bay",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 531,
      "lat": -34.1582984924,
      "lon": 22.058599472,
      "tz": "Africa\/Johannesburg"
  },
  "FAMP": {
      "icao": "FAMP",
      "iata": "",
      "name": "Madimbo Airport",
      "city": "Matshakatini",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 2116,
      "lat": -22.3747005463,
      "lon": 30.8815994263,
      "tz": "Africa\/Johannesburg"
  },
  "FAMQ": {
      "icao": "FAMQ",
      "iata": "",
      "name": "Maclear Airport",
      "city": "Maclear",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 4106,
      "lat": -31.0832996368,
      "lon": 28.3763999939,
      "tz": "Africa\/Johannesburg"
  },
  "FAMS": {
      "icao": "FAMS",
      "iata": "MEZ",
      "name": "Morningside Farm Airport",
      "city": "Silveroaks",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4251,
      "lat": -25.7045001984,
      "lon": 26.9090003967,
      "tz": "Africa\/Johannesburg"
  },
  "FAMT": {
      "icao": "FAMT",
      "iata": "",
      "name": "Molteno Airport",
      "city": "Molteno",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 5254,
      "lat": -31.3896007538,
      "lon": 26.348400116,
      "tz": "Africa\/Johannesburg"
  },
  "FAMU": {
      "icao": "FAMU",
      "iata": "MZQ",
      "name": "Mkuze Airport",
      "city": "Mkuze",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 400,
      "lat": -27.6261005402,
      "lon": 32.0443000793,
      "tz": "Africa\/Johannesburg"
  },
  "FAMV": {
      "icao": "FAMV",
      "iata": "",
      "name": "Montrose Airport",
      "city": "Montrose",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 2690,
      "lat": -24.625,
      "lon": 30.1861114502,
      "tz": "Africa\/Johannesburg"
  },
  "FAMX": {
      "icao": "FAMX",
      "iata": "",
      "name": "Mbazwana Airport",
      "city": "Mbazwana",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 195,
      "lat": -27.4813995361,
      "lon": 32.5938987732,
      "tz": "Africa\/Johannesburg"
  },
  "FAMY": {
      "icao": "FAMY",
      "iata": "",
      "name": "Malmesbury Airport",
      "city": "Mammesbury",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 971,
      "lat": -33.4618988037,
      "lon": 18.700799942,
      "tz": "Africa\/Johannesburg"
  },
  "FAMZ": {
      "icao": "FAMZ",
      "iata": "",
      "name": "Msauli Airport",
      "city": "Msauli",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 2548,
      "lat": -26.0499992371,
      "lon": 31.0170001984,
      "tz": "Africa\/Johannesburg"
  },
  "FANA": {
      "icao": "FANA",
      "iata": "",
      "name": "Nongoma Airport",
      "city": "Nongoma",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 2596,
      "lat": -27.9139995575,
      "lon": 31.6562004089,
      "tz": "Africa\/Johannesburg"
  },
  "FANC": {
      "icao": "FANC",
      "iata": "NCS",
      "name": "Newcastle Airport",
      "city": "Newcastle",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4074,
      "lat": -27.7705993652,
      "lon": 29.9769001007,
      "tz": "Africa\/Johannesburg"
  },
  "FANG": {
      "icao": "FANG",
      "iata": "NGL",
      "name": "Ngala Airport",
      "city": "Ngala",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 3357,
      "lat": -24.3878002167,
      "lon": 31.325799942,
      "tz": "Africa\/Johannesburg"
  },
  "FANH": {
      "icao": "FANH",
      "iata": "",
      "name": "New Hanover Airport",
      "city": "New Hanover",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 2490,
      "lat": -29.3560009003,
      "lon": 30.5184001923,
      "tz": "Africa\/Johannesburg"
  },
  "FANL": {
      "icao": "FANL",
      "iata": "",
      "name": "New Largo Airport",
      "city": "Witbank",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5038,
      "lat": -25.9785003662,
      "lon": 28.9843997955,
      "tz": "Africa\/Johannesburg"
  },
  "FANS": {
      "icao": "FANS",
      "iata": "NLP",
      "name": "Nelspruit Airport",
      "city": "Nelspruit",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 2875,
      "lat": -25.5,
      "lon": 30.9137992859,
      "tz": "Africa\/Johannesburg"
  },
  "FANV": {
      "icao": "FANV",
      "iata": "",
      "name": "Nieuwoudtville Airfield",
      "city": "Nieuwoudtville",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 1890,
      "lat": -31.3500003815,
      "lon": 19.1166667938,
      "tz": "Africa\/Johannesburg"
  },
  "FANY": {
      "icao": "FANY",
      "iata": "",
      "name": "Nylstroom Airfield",
      "city": "Modimolle",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3900,
      "lat": -24.6861000061,
      "lon": 28.4349002838,
      "tz": "Africa\/Johannesburg"
  },
  "FAOB": {
      "icao": "FAOB",
      "iata": "OVG",
      "name": "Overberg Airport",
      "city": "Overberg",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 52,
      "lat": -34.554901123,
      "lon": 20.2506999969,
      "tz": "Africa\/Johannesburg"
  },
  "FAOD": {
      "icao": "FAOD",
      "iata": "",
      "name": "Odendaalsrus Airport",
      "city": "Odendaalsrus",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 1340,
      "lat": -27.8710002899,
      "lon": 26.6933994293,
      "tz": "Africa\/Johannesburg"
  },
  "FAOF": {
      "icao": "FAOF",
      "iata": "",
      "name": "Jack Duvenhage Airport",
      "city": "Olifantshoek",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4216,
      "lat": -27.9617004395,
      "lon": 22.7166004181,
      "tz": "Africa\/Johannesburg"
  },
  "FAOH": {
      "icao": "FAOH",
      "iata": "OUH",
      "name": "Oudtshoorn Airport",
      "city": "Oudtshoorn",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 1063,
      "lat": -33.6069984436,
      "lon": 22.188999176,
      "tz": "Africa\/Johannesburg"
  },
  "FAOI": {
      "icao": "FAOI",
      "iata": "",
      "name": "Orient Glider Airport",
      "city": "Orient",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5102,
      "lat": -26.0394001007,
      "lon": 27.5956001282,
      "tz": "Africa\/Johannesburg"
  },
  "FAOL": {
      "icao": "FAOL",
      "iata": "",
      "name": "Othawa Airport",
      "city": "Otthawa",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1098,
      "lat": -24.7329998016,
      "lon": 31.3999996185,
      "tz": "Africa\/Johannesburg"
  },
  "FAON": {
      "icao": "FAON",
      "iata": "",
      "name": "Ornate Lake St L Airport",
      "city": "Hluhluwe",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 59,
      "lat": -28.0449008942,
      "lon": 32.4253997803,
      "tz": "Africa\/Johannesburg"
  },
  "FAOR": {
      "icao": "FAOR",
      "iata": "JNB",
      "name": "O. R. Tambo International Airport",
      "city": "Johannesburg",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5558,
      "lat": -26.133333,
      "lon": 28.25,
      "tz": "Africa\/Johannesburg"
  },
  "FAOT": {
      "icao": "FAOT",
      "iata": "",
      "name": "Ottosdal Airport",
      "city": "Ottosdal",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4860,
      "lat": -26.7982997894,
      "lon": 26.0002994537,
      "tz": "Africa\/Johannesburg"
  },
  "FAOY": {
      "icao": "FAOY",
      "iata": "",
      "name": "Orkney Airport",
      "city": "Orkney",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4265,
      "lat": -26.9839000702,
      "lon": 26.6513996124,
      "tz": "Africa\/Johannesburg"
  },
  "FAPA": {
      "icao": "FAPA",
      "iata": "AFD",
      "name": "Port Alfred Airport",
      "city": "Port Alfred",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 307,
      "lat": -33.5541992188,
      "lon": 26.8777008057,
      "tz": "Africa\/Johannesburg"
  },
  "FAPC": {
      "icao": "FAPC",
      "iata": "",
      "name": "Prince Albert Airport",
      "city": "Prince Albert",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 2001,
      "lat": -33.2024993896,
      "lon": 22.0321998596,
      "tz": "Africa\/Johannesburg"
  },
  "FAPD": {
      "icao": "FAPD",
      "iata": "",
      "name": "Pofadder Airport",
      "city": "Pofadder",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3241,
      "lat": -29.1417007446,
      "lon": 19.4130992889,
      "tz": "Africa\/Johannesburg"
  },
  "FAPE": {
      "icao": "FAPE",
      "iata": "PLZ",
      "name": "Port Elizabeth Airport",
      "city": "Port Elizabeth",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 226,
      "lat": -33.9849014282,
      "lon": 25.6173000336,
      "tz": "Africa\/Johannesburg"
  },
  "FAPF": {
      "icao": "FAPF",
      "iata": "",
      "name": "Piet Retief Airport",
      "city": "Piet Retief",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 4423,
      "lat": -26.9969005585,
      "lon": 30.8407993317,
      "tz": "Africa\/Johannesburg"
  },
  "FAPG": {
      "icao": "FAPG",
      "iata": "PBZ",
      "name": "Plettenberg Bay Airport",
      "city": "Plettenberg Bay",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 465,
      "lat": -34.0903015137,
      "lon": 23.3278007507,
      "tz": "Africa\/Johannesburg"
  },
  "FAPH": {
      "icao": "FAPH",
      "iata": "PHW",
      "name": "Hendrik Van Eck Airport",
      "city": "Phalaborwa",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1432,
      "lat": -23.9372005463,
      "lon": 31.1553993225,
      "tz": "Africa\/Johannesburg"
  },
  "FAPI": {
      "icao": "FAPI",
      "iata": "",
      "name": "Pietersburg Municipal Airport",
      "city": "Polokwane",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 4354,
      "lat": -23.9260997772,
      "lon": 29.4843997955,
      "tz": "Africa\/Johannesburg"
  },
  "FAPJ": {
      "icao": "FAPJ",
      "iata": "JOH",
      "name": "Port St Johns Airport",
      "city": "Port St Johns",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 1227,
      "lat": -31.6058998108,
      "lon": 29.5198001862,
      "tz": "Africa\/Johannesburg"
  },
  "FAPK": {
      "icao": "FAPK",
      "iata": "PRK",
      "name": "Prieska Airport",
      "city": "Prieska",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3100,
      "lat": -29.683599472,
      "lon": 22.7705993652,
      "tz": "Africa\/Johannesburg"
  },
  "FAPL": {
      "icao": "FAPL",
      "iata": "",
      "name": "Pongola Airport",
      "city": "Pongola",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 942,
      "lat": -27.3621997833,
      "lon": 31.6067008972,
      "tz": "Africa\/Johannesburg"
  },
  "FAPM": {
      "icao": "FAPM",
      "iata": "PZB",
      "name": "Pietermaritzburg Airport",
      "city": "Pietermaritzburg",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 2423,
      "lat": -29.6490001678,
      "lon": 30.3987007141,
      "tz": "Africa\/Johannesburg"
  },
  "FAPN": {
      "icao": "FAPN",
      "iata": "NTY",
      "name": "Pilanesberg International Airport",
      "city": "Pilanesberg",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3412,
      "lat": -25.3337993622,
      "lon": 27.1734008789,
      "tz": "Africa\/Johannesburg"
  },
  "FAPO": {
      "icao": "FAPO",
      "iata": "",
      "name": "Pilgrims Rest Airport",
      "city": "Mankolehlotlo",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 3802,
      "lat": -24.7847003937,
      "lon": 30.7922000885,
      "tz": "Africa\/Johannesburg"
  },
  "FAPP": {
      "icao": "FAPP",
      "iata": "PTG",
      "name": "Polokwane International Airport",
      "city": "Potgietersrus",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 4076,
      "lat": -23.8453006744,
      "lon": 29.4585990906,
      "tz": "Africa\/Johannesburg"
  },
  "FAPQ": {
      "icao": "FAPQ",
      "iata": "",
      "name": "Punda Maria(Malia) Airport",
      "city": "Kruger National Park",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1500,
      "lat": -22.77,
      "lon": 31.0108,
      "tz": "Africa\/Johannesburg"
  },
  "FAPS": {
      "icao": "FAPS",
      "iata": "PCF",
      "name": "Potchefstroom Airport",
      "city": "Potchefstroom",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4520,
      "lat": -26.670999527,
      "lon": 27.0818996429,
      "tz": "Africa\/Johannesburg"
  },
  "FAPT": {
      "icao": "FAPT",
      "iata": "",
      "name": "Posmasburg Soil Airport",
      "city": "Postmasburg",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4301,
      "lat": -28.3386001587,
      "lon": 23.07970047,
      "tz": "Africa\/Johannesburg"
  },
  "FAPU": {
      "icao": "FAPU",
      "iata": "",
      "name": "Paarl East Airport",
      "city": "Paarl",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 596,
      "lat": -33.7107666667,
      "lon": 19.0243888889,
      "tz": "Africa\/Johannesburg"
  },
  "FAPV": {
      "icao": "FAPV",
      "iata": "",
      "name": "Petrusville Airport",
      "city": "Petrusville",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4116,
      "lat": -30.0818004608,
      "lon": 24.679599762,
      "tz": "Africa\/Johannesburg"
  },
  "FAPW": {
      "icao": "FAPW",
      "iata": "",
      "name": "Pietersrus Airport",
      "city": "Pietersrus",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5472,
      "lat": -26.6061000824,
      "lon": 29.3292007446,
      "tz": "Africa\/Johannesburg"
  },
  "FAPX": {
      "icao": "FAPX",
      "iata": "",
      "name": "Paradise Beach Airport",
      "city": "Jeffreysbaal",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 15,
      "lat": -34.1027793884,
      "lon": 24.8827781677,
      "tz": "Africa\/Johannesburg"
  },
  "FAPY": {
      "icao": "FAPY",
      "iata": "",
      "name": "Parys Airport",
      "city": "Parys",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4740,
      "lat": -26.8892993927,
      "lon": 27.5034008026,
      "tz": "Africa\/Johannesburg"
  },
  "FAPZ": {
      "icao": "FAPZ",
      "iata": "",
      "name": "Progress Airport",
      "city": "Sunlands",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 748,
      "lat": -33.9300003052,
      "lon": 25.3700008392,
      "tz": "Africa\/Johannesburg"
  },
  "FAQF": {
      "icao": "FAQF",
      "iata": "",
      "name": "Pomfret Airport",
      "city": "Pomfret",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3817,
      "lat": -25.847700119,
      "lon": 23.5373001099,
      "tz": "Africa\/Johannesburg"
  },
  "FAQR": {
      "icao": "FAQR",
      "iata": "",
      "name": "Potgietersrus Airport",
      "city": "Potgietersus",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3500,
      "lat": -24.2333335876,
      "lon": 28.9833335876,
      "tz": "Africa\/Johannesburg"
  },
  "FAQT": {
      "icao": "FAQT",
      "iata": "UTW",
      "name": "Queenstown Airport",
      "city": "Queenstown",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 3637,
      "lat": -31.9202003479,
      "lon": 26.8822002411,
      "tz": "Africa\/Johannesburg"
  },
  "FARA": {
      "icao": "FARA",
      "iata": "",
      "name": "Petit Airport",
      "city": "Daveyton",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5460,
      "lat": -26.0816669464,
      "lon": 28.3999996185,
      "tz": "Africa\/Johannesburg"
  },
  "FARB": {
      "icao": "FARB",
      "iata": "RCB",
      "name": "Richards Bay Airport",
      "city": "Richards Bay",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 109,
      "lat": -28.7409992218,
      "lon": 32.0920982361,
      "tz": "Africa\/Johannesburg"
  },
  "FARD": {
      "icao": "FARD",
      "iata": "",
      "name": "Riversdale Airport",
      "city": "Riversdale",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 591,
      "lat": -34.1116981506,
      "lon": 21.2628002167,
      "tz": "Africa\/Johannesburg"
  },
  "FARG": {
      "icao": "FARG",
      "iata": "",
      "name": "Rustenburg Airport",
      "city": "Rustenburg",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3700,
      "lat": -25.6443004608,
      "lon": 27.2710990906,
      "tz": "Africa\/Johannesburg"
  },
  "FARI": {
      "icao": "FARI",
      "iata": "RVO",
      "name": "Reivilo Airport",
      "city": "Reivilo",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4715,
      "lat": -27.5471992493,
      "lon": 24.1725006104,
      "tz": "Africa\/Johannesburg"
  },
  "FARM": {
      "icao": "FARM",
      "iata": "",
      "name": "Richmond Airport",
      "city": "Richmond",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4731,
      "lat": -31.423500061,
      "lon": 23.9782009125,
      "tz": "Africa\/Johannesburg"
  },
  "FARO": {
      "icao": "FARO",
      "iata": "",
      "name": "Rooiberg Airport",
      "city": "Rooiberg",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3811,
      "lat": -24.7666664124,
      "lon": 27.75,
      "tz": "Africa\/Johannesburg"
  },
  "FARS": {
      "icao": "FARS",
      "iata": "ROD",
      "name": "Robertson Airport",
      "city": "Robertson",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 640,
      "lat": -33.8121986389,
      "lon": 19.9027996063,
      "tz": "Africa\/Johannesburg"
  },
  "FARZ": {
      "icao": "FARZ",
      "iata": "",
      "name": "Reitz Airport",
      "city": "Maasstroom",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 5331,
      "lat": -22.7842006683,
      "lon": 28.4302997589,
      "tz": "Africa\/Johannesburg"
  },
  "FASA": {
      "icao": "FASA",
      "iata": "",
      "name": "Sani Pass Airport",
      "city": "Sani Pass",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 5933,
      "lat": -29.6571998596,
      "lon": 29.4477005005,
      "tz": "Africa\/Johannesburg"
  },
  "FASB": {
      "icao": "FASB",
      "iata": "SBU",
      "name": "Springbok Airport",
      "city": "Springbok",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2690,
      "lat": -29.6893005371,
      "lon": 17.9395999908,
      "tz": "Africa\/Johannesburg"
  },
  "FASC": {
      "icao": "FASC",
      "iata": "ZEC",
      "name": "Secunda Airport",
      "city": "Secunda",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5250,
      "lat": -26.52409935,
      "lon": 29.1700992584,
      "tz": "Africa\/Johannesburg"
  },
  "FASD": {
      "icao": "FASD",
      "iata": "",
      "name": "Saldanha \/Vredenburg Airport",
      "city": "Saldanha-Vredenburg",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 50,
      "lat": -32.964099884,
      "lon": 17.9692993164,
      "tz": "Africa\/Johannesburg"
  },
  "FASE": {
      "icao": "FASE",
      "iata": "GSS",
      "name": "Sabi Sabi Airport",
      "city": "Belfast",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1276,
      "lat": -24.9414997101,
      "lon": 31.4445991516,
      "tz": "Africa\/Johannesburg"
  },
  "FASG": {
      "icao": "FASG",
      "iata": "",
      "name": "Schweizer Reneke Airport",
      "city": "Schweizer Reneke",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4373,
      "lat": -27.1630992889,
      "lon": 25.2861003876,
      "tz": "Africa\/Johannesburg"
  },
  "FASH": {
      "icao": "FASH",
      "iata": "",
      "name": "Stellenbosch Airport",
      "city": "Stellenbosch",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 295,
      "lat": -33.9799995422,
      "lon": 18.8199996948,
      "tz": "Africa\/Johannesburg"
  },
  "FASI": {
      "icao": "FASI",
      "iata": "",
      "name": "Springs Airfield",
      "city": "Springs",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5340,
      "lat": -26.2483997345,
      "lon": 28.3974990845,
      "tz": "Africa\/Johannesburg"
  },
  "FASJ": {
      "icao": "FASJ",
      "iata": "",
      "name": "Saffier Airport",
      "city": "Saffier",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4700,
      "lat": -26.7600002289,
      "lon": 27.7600002289,
      "tz": "Africa\/Johannesburg"
  },
  "FASK": {
      "icao": "FASK",
      "iata": "",
      "name": "Swartkop Air Force Base",
      "city": "Pretoria",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4780,
      "lat": -25.8097000122,
      "lon": 28.1646003723,
      "tz": "Africa\/Johannesburg"
  },
  "FASL": {
      "icao": "FASL",
      "iata": "",
      "name": "Sutherland Airport",
      "city": "Sutherland",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 5247,
      "lat": -32.4882011414,
      "lon": 20.6970996857,
      "tz": "Africa\/Johannesburg"
  },
  "FASM": {
      "icao": "FASM",
      "iata": "",
      "name": "Siteka Airport",
      "city": "Siteka",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4400,
      "lat": -29.3250007629,
      "lon": 30.1492004395,
      "tz": "Africa\/Johannesburg"
  },
  "FASN": {
      "icao": "FASN",
      "iata": "",
      "name": "Senekal Airport",
      "city": "Senekal",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4849,
      "lat": -28.3122005463,
      "lon": 27.6460990906,
      "tz": "Africa\/Johannesburg"
  },
  "FASR": {
      "icao": "FASR",
      "iata": "",
      "name": "Standerton Airport",
      "city": "Standerton",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5200,
      "lat": -26.9270000458,
      "lon": 29.2099990845,
      "tz": "Africa\/Johannesburg"
  },
  "FASS": {
      "icao": "FASS",
      "iata": "SIS",
      "name": "Sishen Airport",
      "city": "Sishen",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3848,
      "lat": -27.6485996246,
      "lon": 22.9993000031,
      "tz": "Africa\/Johannesburg"
  },
  "FAST": {
      "icao": "FAST",
      "iata": "",
      "name": "Somerset East Airport",
      "city": "Somerset East",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2349,
      "lat": -32.7501983643,
      "lon": 25.5949993134,
      "tz": "Africa\/Johannesburg"
  },
  "FASU": {
      "icao": "FASU",
      "iata": "",
      "name": "Sace Airport",
      "city": "Sace",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5151,
      "lat": -25.9568996429,
      "lon": 29.2117004395,
      "tz": "Africa\/Johannesburg"
  },
  "FASW": {
      "icao": "FASW",
      "iata": "",
      "name": "Slurry Airport",
      "city": "Slurry",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4692,
      "lat": -25.8155994415,
      "lon": 25.8864002228,
      "tz": "Africa\/Johannesburg"
  },
  "FASX": {
      "icao": "FASX",
      "iata": "",
      "name": "Hendrik Swellengrebel Airport",
      "city": "Swellendam",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 407,
      "lat": -34.0481987,
      "lon": 20.4745998383,
      "tz": "Africa\/Johannesburg"
  },
  "FASY": {
      "icao": "FASY",
      "iata": "",
      "name": "Syferfontein Airport",
      "city": "Hiltonia",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5420,
      "lat": -26.349199295,
      "lon": 27.7793998718,
      "tz": "Africa\/Johannesburg"
  },
  "FASZ": {
      "icao": "FASZ",
      "iata": "SZK",
      "name": "Skukuza Airport",
      "city": "Skukuza",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1020,
      "lat": -24.960899353,
      "lon": 31.5886993408,
      "tz": "Africa\/Johannesburg"
  },
  "FATA": {
      "icao": "FATA",
      "iata": "",
      "name": "Tedderfield Air Park",
      "city": "Tedderfield",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 5198,
      "lat": -26.3519992828,
      "lon": 27.9689998627,
      "tz": "Africa\/Johannesburg"
  },
  "FATB": {
      "icao": "FATB",
      "iata": "",
      "name": "Thorny Bush Game Lodge Airport",
      "city": "Hoedspruit",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1900,
      "lat": -24.4147224426,
      "lon": 31.1650009155,
      "tz": "Africa\/Johannesburg"
  },
  "FATD": {
      "icao": "FATD",
      "iata": "TDT",
      "name": "Tanda Tula Airport",
      "city": "Welverdiend",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1555,
      "lat": -24.5335998535,
      "lon": 31.2999992371,
      "tz": "Africa\/Johannesburg"
  },
  "FATF": {
      "icao": "FATF",
      "iata": "",
      "name": "Tommys Field Airport",
      "city": "Beeshoek",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4360,
      "lat": -28.2600002289,
      "lon": 22.9932003021,
      "tz": "Africa\/Johannesburg"
  },
  "FATH": {
      "icao": "FATH",
      "iata": "THY",
      "name": "Thohoyandou Airport",
      "city": "Thohoyandou",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 2021,
      "lat": -23.0769004822,
      "lon": 30.383600235,
      "tz": "Africa\/Johannesburg"
  },
  "FATI": {
      "icao": "FATI",
      "iata": "",
      "name": "Thabazimbi Airport",
      "city": "Thabazimbi",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3225,
      "lat": -24.5760850299,
      "lon": 27.4196863174,
      "tz": "Africa\/Johannesburg"
  },
  "FATK": {
      "icao": "FATK",
      "iata": "",
      "name": "Tsitsikama Fly Airport",
      "city": "Tsitsikama",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 381,
      "lat": -34.0847015381,
      "lon": 24.2896995544,
      "tz": "Africa\/Johannesburg"
  },
  "FATM": {
      "icao": "FATM",
      "iata": "",
      "name": "Stutterheim Airport",
      "city": "Stutterheim",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2675,
      "lat": -32.5666656494,
      "lon": 27.4500007629,
      "tz": "Africa\/Johannesburg"
  },
  "FATN": {
      "icao": "FATN",
      "iata": "TCU",
      "name": "Thaba Nchu Tar Airport",
      "city": "Homeward",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4941,
      "lat": -29.3178005219,
      "lon": 26.8227996826,
      "tz": "Africa\/Johannesburg"
  },
  "FATP": {
      "icao": "FATP",
      "iata": "",
      "name": "New Tempe Airport",
      "city": "Bain's Vlei",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4547,
      "lat": -29.0328998566,
      "lon": 26.1576004028,
      "tz": "Africa\/Johannesburg"
  },
  "FATR": {
      "icao": "FATR",
      "iata": "",
      "name": "Trennery's Airport",
      "city": "Qolora",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 114,
      "lat": -32.6385993958,
      "lon": 28.4242992401,
      "tz": "Africa\/Johannesburg"
  },
  "FATT": {
      "icao": "FATT",
      "iata": "",
      "name": "Tutuka Power Station Airport",
      "city": "Standerton",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5313,
      "lat": -26.776599884,
      "lon": 29.3388004303,
      "tz": "Africa\/Johannesburg"
  },
  "FATW": {
      "icao": "FATW",
      "iata": "",
      "name": "Witberg Tswalu Airport",
      "city": "Tswalo Game Reserve",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3921,
      "lat": -27.2049999237,
      "lon": 22.4818992615,
      "tz": "Africa\/Johannesburg"
  },
  "FATZ": {
      "icao": "FATZ",
      "iata": "LTA",
      "name": "Tzaneen Airport",
      "city": "Tzaneen",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 1914,
      "lat": -23.8243999481,
      "lon": 30.3292999268,
      "tz": "Africa\/Johannesburg"
  },
  "FAUB": {
      "icao": "FAUB",
      "iata": "",
      "name": "Underberg Airport",
      "city": "Underberg",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 5057,
      "lat": -29.78840065,
      "lon": 29.4983005524,
      "tz": "Africa\/Johannesburg"
  },
  "FAUC": {
      "icao": "FAUC",
      "iata": "",
      "name": "Ulco Airport",
      "city": "Ulco",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3512,
      "lat": -28.3544998169,
      "lon": 24.2290992737,
      "tz": "Africa\/Johannesburg"
  },
  "FAUG": {
      "icao": "FAUG",
      "iata": "",
      "name": "Ugie Airport",
      "city": "Ugie",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 4580,
      "lat": -31.2199993134,
      "lon": 28.2099990845,
      "tz": "Africa\/Johannesburg"
  },
  "FAUH": {
      "icao": "FAUH",
      "iata": "",
      "name": "Uitenhage Airport",
      "city": "Uitenhage",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 289,
      "lat": -33.7853012085,
      "lon": 25.3833007813,
      "tz": "Africa\/Johannesburg"
  },
  "FAUL": {
      "icao": "FAUL",
      "iata": "ULD",
      "name": "Prince Mangosuthu Buthelezi Airport",
      "city": "Ulundi",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 1720,
      "lat": -28.3206005096,
      "lon": 31.4165000916,
      "tz": "Africa\/Johannesburg"
  },
  "FAUP": {
      "icao": "FAUP",
      "iata": "UTN",
      "name": "Pierre Van Ryneveld Airport",
      "city": "Upington",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 2782,
      "lat": -28.39909935,
      "lon": 21.2602005005,
      "tz": "Africa\/Johannesburg"
  },
  "FAUR": {
      "icao": "FAUR",
      "iata": "",
      "name": "Utrecht Airport",
      "city": "Utrecht",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 4101,
      "lat": -27.6805000305,
      "lon": 30.3167991638,
      "tz": "Africa\/Johannesburg"
  },
  "FAUS": {
      "icao": "FAUS",
      "iata": "ULX",
      "name": "Ulusaba Airport",
      "city": "Ulusaba",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 1263,
      "lat": -24.7854003906,
      "lon": 31.3549003601,
      "tz": "Africa\/Johannesburg"
  },
  "FAUT": {
      "icao": "FAUT",
      "iata": "UTT",
      "name": "K. D. Matanzima Airport",
      "city": "Mthatha",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2400,
      "lat": -31.5478992462,
      "lon": 28.6742992401,
      "tz": "Africa\/Johannesburg"
  },
  "FAVA": {
      "icao": "FAVA",
      "iata": "",
      "name": "Vaalputs Airport",
      "city": "Vaalputs",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3340,
      "lat": -30.1341991425,
      "lon": 18.5266990662,
      "tz": "Africa\/Johannesburg"
  },
  "FAVB": {
      "icao": "FAVB",
      "iata": "VRU",
      "name": "Vryburg Airport",
      "city": "Vyrburg",
      "state": "North-West",
      "country": "ZA",
      "elevation": 3920,
      "lat": -26.9824008942,
      "lon": 24.7287998199,
      "tz": "Africa\/Johannesburg"
  },
  "FAVD": {
      "icao": "FAVD",
      "iata": "",
      "name": "Vrede Airport",
      "city": "Vrede",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 5499,
      "lat": -27.4402999878,
      "lon": 29.1530990601,
      "tz": "Africa\/Johannesburg"
  },
  "FAVE": {
      "icao": "FAVE",
      "iata": "",
      "name": "Ventersdorp Airport",
      "city": "Ventersdorp",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4917,
      "lat": -26.3008995056,
      "lon": 26.8141994476,
      "tz": "Africa\/Johannesburg"
  },
  "FAVF": {
      "icao": "FAVF",
      "iata": "",
      "name": "Verborgenfontei Airport",
      "city": "Verborgenfontein",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4360,
      "lat": -31.1658000946,
      "lon": 23.8094005585,
      "tz": "Africa\/Johannesburg"
  },
  "FAVG": {
      "icao": "FAVG",
      "iata": "VIR",
      "name": "Virginia Airport",
      "city": "Durban",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 20,
      "lat": -29.7705993652,
      "lon": 31.0583992004,
      "tz": "Africa\/Johannesburg"
  },
  "FAVI": {
      "icao": "FAVI",
      "iata": "",
      "name": "Von Abo's Villa Airport",
      "city": "Von Abo's Villa",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4304,
      "lat": -27.6105995178,
      "lon": 26.6835002899,
      "tz": "Africa\/Johannesburg"
  },
  "FAVM": {
      "icao": "FAVM",
      "iata": "",
      "name": "Venetia Airport",
      "city": "Venetia Mine",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 2333,
      "lat": -22.4480991364,
      "lon": 29.3376998901,
      "tz": "Africa\/Johannesburg"
  },
  "FAVP": {
      "icao": "FAVP",
      "iata": "",
      "name": "Vanderbijlpark Airport",
      "city": "Vanderbijlpark",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4861,
      "lat": -26.6902999878,
      "lon": 27.7770996094,
      "tz": "Africa\/Johannesburg"
  },
  "FAVR": {
      "icao": "FAVR",
      "iata": "VRE",
      "name": "Vredendal Airport",
      "city": "Vredendal",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 330,
      "lat": -31.6410007477,
      "lon": 18.5447998047,
      "tz": "Africa\/Johannesburg"
  },
  "FAVS": {
      "icao": "FAVS",
      "iata": "",
      "name": "Vastrap Airport",
      "city": "Upington",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3245,
      "lat": -27.8413887024,
      "lon": 21.6341667175,
      "tz": "Africa\/Johannesburg"
  },
  "FAVU": {
      "icao": "FAVU",
      "iata": "",
      "name": "Volksrust Airport",
      "city": "Volksrust",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 5610,
      "lat": -27.3768997192,
      "lon": 29.8600006104,
      "tz": "Africa\/Johannesburg"
  },
  "FAVV": {
      "icao": "FAVV",
      "iata": "",
      "name": "Vereeniging Airport",
      "city": "Vereeniging",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4846,
      "lat": -26.566400528,
      "lon": 27.9608001709,
      "tz": "Africa\/Johannesburg"
  },
  "FAVW": {
      "icao": "FAVW",
      "iata": "",
      "name": "Victoria West Airport",
      "city": "Victoria West",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 4124,
      "lat": -31.3994007111,
      "lon": 23.1564006805,
      "tz": "Africa\/Johannesburg"
  },
  "FAVY": {
      "icao": "FAVY",
      "iata": "VYD",
      "name": "Vryheid Airport",
      "city": "Vryheid",
      "state": "KwaZulu-Natal",
      "country": "ZA",
      "elevation": 3799,
      "lat": -27.7868995667,
      "lon": 30.7964000702,
      "tz": "Africa\/Johannesburg"
  },
  "FAWA": {
      "icao": "FAWA",
      "iata": "",
      "name": "Warmbaths Airport",
      "city": "Warmbaths",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3655,
      "lat": -24.9099998474,
      "lon": 28.2999992371,
      "tz": "Africa\/Johannesburg"
  },
  "FAWB": {
      "icao": "FAWB",
      "iata": "PRY",
      "name": "Wonderboom Airport",
      "city": "Pretoria",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4095,
      "lat": -25.6539001465,
      "lon": 28.224199295,
      "tz": "Africa\/Johannesburg"
  },
  "FAWC": {
      "icao": "FAWC",
      "iata": "",
      "name": "Worcester Glider Airport",
      "city": "Worcester",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 673,
      "lat": -33.6631011963,
      "lon": 19.4153003693,
      "tz": "Africa\/Johannesburg"
  },
  "FAWD": {
      "icao": "FAWD",
      "iata": "",
      "name": "Wolmeransstad Airport",
      "city": "Wolmeransstad",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4513,
      "lat": -27.1700000763,
      "lon": 25.9799995422,
      "tz": "Africa\/Johannesburg"
  },
  "FAWI": {
      "icao": "FAWI",
      "iata": "",
      "name": "Witbank Airport",
      "city": "Witbank",
      "state": "Mpumalanga",
      "country": "ZA",
      "elevation": 5078,
      "lat": -25.8323001862,
      "lon": 29.1919994354,
      "tz": "Africa\/Johannesburg"
  },
  "FAWK": {
      "icao": "FAWK",
      "iata": "WKF",
      "name": "Waterkloof Air Force Base",
      "city": "Pretoria",
      "state": "Gauteng",
      "country": "ZA",
      "elevation": 4940,
      "lat": -25.8299999237,
      "lon": 28.2224998474,
      "tz": "Africa\/Johannesburg"
  },
  "FAWL": {
      "icao": "FAWL",
      "iata": "",
      "name": "Williston Airport",
      "city": "Williston",
      "state": "Northern-Cape",
      "country": "ZA",
      "elevation": 3484,
      "lat": -31.383600235,
      "lon": 20.932800293,
      "tz": "Africa\/Johannesburg"
  },
  "FAWM": {
      "icao": "FAWM",
      "iata": "WEL",
      "name": "Welkom Airport",
      "city": "Welkom",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4399,
      "lat": -27.9979991913,
      "lon": 26.6695995331,
      "tz": "Africa\/Johannesburg"
  },
  "FAWN": {
      "icao": "FAWN",
      "iata": "",
      "name": "Winburg Airport",
      "city": "Winburg",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4800,
      "lat": -28.5,
      "lon": 27.0166664124,
      "tz": "Africa\/Johannesburg"
  },
  "FAWO": {
      "icao": "FAWO",
      "iata": "",
      "name": "Willowmore Airport",
      "city": "Willowmore",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 2608,
      "lat": -33.2633018494,
      "lon": 23.4906005859,
      "tz": "Africa\/Johannesburg"
  },
  "FAWP": {
      "icao": "FAWP",
      "iata": "",
      "name": "Wepener Airport",
      "city": "Wepener",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4755,
      "lat": -29.7423992157,
      "lon": 27.022600174,
      "tz": "Africa\/Johannesburg"
  },
  "FAWR": {
      "icao": "FAWR",
      "iata": "",
      "name": "Wavecrest Airport",
      "city": "Wavecrest",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 89,
      "lat": -32.5896987915,
      "lon": 28.5191993713,
      "tz": "Africa\/Johannesburg"
  },
  "FAWS": {
      "icao": "FAWS",
      "iata": "",
      "name": "Wesselbronn Af Airport",
      "city": "Wesselsbron",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4301,
      "lat": -27.8467006683,
      "lon": 26.3505992889,
      "tz": "Africa\/Johannesburg"
  },
  "FAWT": {
      "icao": "FAWT",
      "iata": "",
      "name": "Winterveldt Mine Airport",
      "city": "Doornbosch",
      "state": "Limpopo",
      "country": "ZA",
      "elevation": 3000,
      "lat": -24.665,
      "lon": 30.18528,
      "tz": "Africa\/Johannesburg"
  },
  "FAYP": {
      "icao": "FAYP",
      "iata": "",
      "name": "Ysterplaat Air Force Base",
      "city": "Cape Town",
      "state": "Western-Cape",
      "country": "ZA",
      "elevation": 52,
      "lat": -33.9001998901,
      "lon": 18.4983005524,
      "tz": "Africa\/Johannesburg"
  },
  "FAZA": {
      "icao": "FAZA",
      "iata": "",
      "name": "Zastron Airport",
      "city": "Zastron",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4265,
      "lat": -30.2999992371,
      "lon": 27.1170005798,
      "tz": "Africa\/Johannesburg"
  },
  "FAZP": {
      "icao": "FAZP",
      "iata": "",
      "name": "Mazeppa Bay Airport",
      "city": "Mazeppa Bay",
      "state": "Eastern-Cape",
      "country": "ZA",
      "elevation": 13,
      "lat": -32.4719009399,
      "lon": 28.6527996063,
      "tz": "Africa\/Johannesburg"
  },
  "FAZQ": {
      "icao": "FAZQ",
      "iata": "",
      "name": "Star Airport",
      "city": "Star",
      "state": "Orange-Free-State",
      "country": "ZA",
      "elevation": 4754,
      "lat": -26.8931007385,
      "lon": 27.6900005341,
      "tz": "Africa\/Johannesburg"
  },
  "FAZR": {
      "icao": "FAZR",
      "iata": "",
      "name": "Zeerust Airport",
      "city": "Zeerust",
      "state": "North-West",
      "country": "ZA",
      "elevation": 4258,
      "lat": -25.5990009308,
      "lon": 26.0422992706,
      "tz": "Africa\/Johannesburg"
  },
  "FBCO": {
      "icao": "FBCO",
      "iata": "",
      "name": "Camp Okavango Airport",
      "city": "Camp Okavango",
      "state": "North-West",
      "country": "BW",
      "elevation": 3150,
      "lat": -19.1170005798,
      "lon": 23.1000003815,
      "tz": "Africa\/Gaborone"
  },
  "FBFT": {
      "icao": "FBFT",
      "iata": "FRW",
      "name": "Francistown Airport",
      "city": "Francistown",
      "state": "North-East",
      "country": "BW",
      "elevation": 3283,
      "lat": -21.1595993042,
      "lon": 27.4745006561,
      "tz": "Africa\/Gaborone"
  },
  "FBGM": {
      "icao": "FBGM",
      "iata": "",
      "name": "Gumare Airport",
      "city": "Gumare",
      "state": "North-West",
      "country": "BW",
      "elevation": 3100,
      "lat": -19.3666305542,
      "lon": 22.1666660309,
      "tz": "Africa\/Gaborone"
  },
  "FBGZ": {
      "icao": "FBGZ",
      "iata": "GNZ",
      "name": "Ghanzi Airport",
      "city": "Ghanzi",
      "state": "Ghanzi",
      "country": "BW",
      "elevation": 3730,
      "lat": -21.6924991608,
      "lon": 21.6581001282,
      "tz": "Africa\/Gaborone"
  },
  "FBJW": {
      "icao": "FBJW",
      "iata": "JWA",
      "name": "Jwaneng Airport",
      "city": "",
      "state": "Ngwaketsi",
      "country": "BW",
      "elevation": 3900,
      "lat": -24.6023006439,
      "lon": 24.6909999847,
      "tz": "Africa\/Gaborone"
  },
  "FBKE": {
      "icao": "FBKE",
      "iata": "BBK",
      "name": "Kasane Airport",
      "city": "Kasane",
      "state": "North-West",
      "country": "BW",
      "elevation": 3289,
      "lat": -17.8328990936,
      "lon": 25.162399292,
      "tz": "Africa\/Gaborone"
  },
  "FBKG": {
      "icao": "FBKG",
      "iata": "",
      "name": "Kang Airport",
      "city": "",
      "state": "Kgalagadi",
      "country": "BW",
      "elevation": 3520,
      "lat": -23.6833000183,
      "lon": 22.8166999817,
      "tz": "Africa\/Gaborone"
  },
  "FBKR": {
      "icao": "FBKR",
      "iata": "KHW",
      "name": "Khwai River Lodge Airport",
      "city": "Khwai River Lodge",
      "state": "North-West",
      "country": "BW",
      "elevation": 3000,
      "lat": -19.1499996185,
      "lon": 23.783000946,
      "tz": "Africa\/Gaborone"
  },
  "FBKY": {
      "icao": "FBKY",
      "iata": "",
      "name": "Kanye Airport",
      "city": "Kanye",
      "state": "Ngwaketsi",
      "country": "BW",
      "elevation": 4199,
      "lat": -25.0499992371,
      "lon": 25.3166999817,
      "tz": "Africa\/Gaborone"
  },
  "FBMG": {
      "icao": "FBMG",
      "iata": "",
      "name": "Machaneng Airport",
      "city": "Machaneng",
      "state": "Central",
      "country": "BW",
      "elevation": 2900,
      "lat": -23.1830005646,
      "lon": 27.466999054,
      "tz": "Africa\/Gaborone"
  },
  "FBML": {
      "icao": "FBML",
      "iata": "",
      "name": "Molepolole Airport",
      "city": "Molepolole",
      "state": "Kweneng",
      "country": "BW",
      "elevation": 3790,
      "lat": -24.389444444,
      "lon": 25.498611111,
      "tz": "Africa\/Gaborone"
  },
  "FBMM": {
      "icao": "FBMM",
      "iata": "",
      "name": "Makalamabedi Airport",
      "city": "Makalamabedi",
      "state": "Central",
      "country": "BW",
      "elevation": 3100,
      "lat": -20.3330001831,
      "lon": 23.8829994202,
      "tz": "Africa\/Gaborone"
  },
  "FBMN": {
      "icao": "FBMN",
      "iata": "MUB",
      "name": "Maun Airport",
      "city": "Maun",
      "state": "North-West",
      "country": "BW",
      "elevation": 3093,
      "lat": -19.9726009369,
      "lon": 23.4311008453,
      "tz": "Africa\/Gaborone"
  },
  "FBNN": {
      "icao": "FBNN",
      "iata": "",
      "name": "Nokaneng Airport",
      "city": "Nokaneng",
      "state": "North-West",
      "country": "BW",
      "elevation": 3200,
      "lat": -19.3386001587,
      "lon": 22.153799057,
      "tz": "Africa\/Gaborone"
  },
  "FBNT": {
      "icao": "FBNT",
      "iata": "",
      "name": "Nata Airport",
      "city": "Nata",
      "state": "Central",
      "country": "BW",
      "elevation": 2690,
      "lat": -20.2161006927,
      "lon": 26.1588993073,
      "tz": "Africa\/Gaborone"
  },
  "FBNW": {
      "icao": "FBNW",
      "iata": "",
      "name": "Gaborone Notwane Airport",
      "city": "Gaborone Notwane",
      "state": "South-East",
      "country": "BW",
      "elevation": 3218,
      "lat": -24.6830005646,
      "lon": 25.9330005646,
      "tz": "Africa\/Gaborone"
  },
  "FBOK": {
      "icao": "FBOK",
      "iata": "",
      "name": "Okwa Airport",
      "city": "Okwa",
      "state": "Ghanzi",
      "country": "BW",
      "elevation": 3500,
      "lat": -23.0830001831,
      "lon": 21.8829994202,
      "tz": "Africa\/Gaborone"
  },
  "FBOR": {
      "icao": "FBOR",
      "iata": "ORP",
      "name": "Orapa Airport",
      "city": "",
      "state": "Central",
      "country": "BW",
      "elevation": 3100,
      "lat": -21.2667007446,
      "lon": 25.3166999817,
      "tz": "Africa\/Gaborone"
  },
  "FBPY": {
      "icao": "FBPY",
      "iata": "QPH",
      "name": "Palapye Airport",
      "city": "Palapye",
      "state": "Central",
      "country": "BW",
      "elevation": 3000,
      "lat": -22.5669994354,
      "lon": 27.1499996185,
      "tz": "Africa\/Gaborone"
  },
  "FBRK": {
      "icao": "FBRK",
      "iata": "",
      "name": "Rakops Airport",
      "city": "Rakops",
      "state": "Central",
      "country": "BW",
      "elevation": 3000,
      "lat": -21,
      "lon": 24.3330001831,
      "tz": "Africa\/Gaborone"
  },
  "FBSK": {
      "icao": "FBSK",
      "iata": "GBE",
      "name": "Sir Seretse Khama International Airport",
      "city": "Gaborone",
      "state": "South-East",
      "country": "BW",
      "elevation": 3299,
      "lat": -24.5552005768,
      "lon": 25.9181995392,
      "tz": "Africa\/Gaborone"
  },
  "FBSN": {
      "icao": "FBSN",
      "iata": "SXN",
      "name": "Sua Pan Airport",
      "city": "",
      "state": "Central",
      "country": "BW",
      "elevation": 2985,
      "lat": -20.5534000397,
      "lon": 26.1158008575,
      "tz": "Africa\/Gaborone"
  },
  "FBSP": {
      "icao": "FBSP",
      "iata": "PKW",
      "name": "Selebi Phikwe Airport",
      "city": "",
      "state": "",
      "country": "BW",
      "elevation": 2925,
      "lat": -22.0583000183,
      "lon": 27.8288002014,
      "tz": "Africa\/Gaborone"
  },
  "FBSR": {
      "icao": "FBSR",
      "iata": "",
      "name": "Serowe Airport",
      "city": "Serowe",
      "state": "Central",
      "country": "BW",
      "elevation": 3800,
      "lat": -22.4232997894,
      "lon": 26.7567005157,
      "tz": "Africa\/Gaborone"
  },
  "FBSV": {
      "icao": "FBSV",
      "iata": "SVT",
      "name": "Savuti Airport",
      "city": "Savuti",
      "state": "North-West",
      "country": "BW",
      "elevation": 3150,
      "lat": -18.5205993652,
      "lon": 24.0767002106,
      "tz": "Africa\/Gaborone"
  },
  "FBSW": {
      "icao": "FBSW",
      "iata": "SWX",
      "name": "Shakawe Airport",
      "city": "Shakawe",
      "state": "North-West",
      "country": "BW",
      "elevation": 3379,
      "lat": -18.3738994598,
      "lon": 21.8325996399,
      "tz": "Africa\/Gaborone"
  },
  "FBTE": {
      "icao": "FBTE",
      "iata": "",
      "name": "Tshane Airport",
      "city": "Tshane",
      "state": "Kgalagadi",
      "country": "BW",
      "elevation": 3700,
      "lat": -24.0170001984,
      "lon": 21.8829994202,
      "tz": "Africa\/Gaborone"
  },
  "FBTL": {
      "icao": "FBTL",
      "iata": "TLD",
      "name": "Limpopo Valley Airport",
      "city": "Tuli Lodge",
      "state": "Central",
      "country": "BW",
      "elevation": 1772,
      "lat": -22.1891994476,
      "lon": 29.1268997192,
      "tz": "Africa\/Gaborone"
  },
  "FBTP": {
      "icao": "FBTP",
      "iata": "",
      "name": "Thebephatshwa Airport",
      "city": "",
      "state": "Kweneng",
      "country": "BW",
      "elevation": 3750,
      "lat": -24.2210998535,
      "lon": 25.3472995758,
      "tz": "Africa\/Gaborone"
  },
  "FBTS": {
      "icao": "FBTS",
      "iata": "TBY",
      "name": "Tshabong Airport",
      "city": "Tshabong",
      "state": "Kgalagadi",
      "country": "BW",
      "elevation": 3179,
      "lat": -26.0333003998,
      "lon": 22.3999996185,
      "tz": "Africa\/Gaborone"
  },
  "FBXB": {
      "icao": "FBXB",
      "iata": "",
      "name": "Xaxaba Airport",
      "city": "Xaxaba",
      "state": "",
      "country": "BW",
      "elevation": 3100,
      "lat": -19.5505008698,
      "lon": 23.0548000336,
      "tz": "Africa\/Gaborone"
  },
  "FBXG": {
      "icao": "FBXG",
      "iata": "",
      "name": "Xugana Airport",
      "city": "",
      "state": "North-West",
      "country": "BW",
      "elevation": 0,
      "lat": -19.0499992371,
      "lon": 23.0900001526,
      "tz": "Africa\/Gaborone"
  },
  "FCBA": {
      "icao": "FCBA",
      "iata": "",
      "name": "La Louila Airport",
      "city": "La Louila",
      "state": "Pool",
      "country": "CG",
      "elevation": 984,
      "lat": -4.0830001831,
      "lon": 14.2329998016,
      "tz": "Africa\/Brazzaville"
  },
  "FCBB": {
      "icao": "FCBB",
      "iata": "BZV",
      "name": "Maya-Maya Airport",
      "city": "Brazzaville",
      "state": "Brazzaville",
      "country": "CG",
      "elevation": 1048,
      "lat": -4.2516999245,
      "lon": 15.2530002594,
      "tz": "Africa\/Brazzaville"
  },
  "FCBD": {
      "icao": "FCBD",
      "iata": "DJM",
      "name": "Djambala Airport",
      "city": "Djambala",
      "state": "Plateaux",
      "country": "CG",
      "elevation": 2595,
      "lat": -2.5329999924,
      "lon": 14.75,
      "tz": "Africa\/Brazzaville"
  },
  "FCBK": {
      "icao": "FCBK",
      "iata": "KNJ",
      "name": "Kindamba Airport",
      "city": "Kindamba",
      "state": "Pool",
      "country": "CG",
      "elevation": 1460,
      "lat": -3.9500000477,
      "lon": 14.5170001984,
      "tz": "Africa\/Brazzaville"
  },
  "FCBL": {
      "icao": "FCBL",
      "iata": "LCO",
      "name": "Lague Airport",
      "city": "Lague",
      "state": "Plateaux",
      "country": "CG",
      "elevation": 2756,
      "lat": -2.4500000477,
      "lon": 14.5329999924,
      "tz": "Africa\/Brazzaville"
  },
  "FCBM": {
      "icao": "FCBM",
      "iata": "MUY",
      "name": "Mouyondzi Airport",
      "city": "Mouyondzi",
      "state": "Bouenza",
      "country": "CG",
      "elevation": 1670,
      "lat": -4.0148739815,
      "lon": 13.9661121368,
      "tz": "Africa\/Brazzaville"
  },
  "FCBP": {
      "icao": "FCBP",
      "iata": "",
      "name": "M'passa Airport",
      "city": "M'passa",
      "state": "Pool",
      "country": "CG",
      "elevation": 1115,
      "lat": -4.367000103,
      "lon": 14.1499996185,
      "tz": "Africa\/Brazzaville"
  },
  "FCBS": {
      "icao": "FCBS",
      "iata": "SIB",
      "name": "Sibiti Airport",
      "city": "Sibiti",
      "state": "Lekoumou",
      "country": "CG",
      "elevation": 1883,
      "lat": -3.6830000877,
      "lon": 13.3500003815,
      "tz": "Africa\/Brazzaville"
  },
  "FCBT": {
      "icao": "FCBT",
      "iata": "",
      "name": "Loutete Airport",
      "city": "Loutete",
      "state": "Bouenza",
      "country": "CG",
      "elevation": 656,
      "lat": -4.2829999924,
      "lon": 13.8669996262,
      "tz": "Africa\/Brazzaville"
  },
  "FCBU": {
      "icao": "FCBU",
      "iata": "",
      "name": "Aubeville Airport",
      "city": "Aubeville",
      "state": "Bouenza",
      "country": "CG",
      "elevation": 1148,
      "lat": -4.2670001984,
      "lon": 13.5329999924,
      "tz": "Africa\/Brazzaville"
  },
  "FCBY": {
      "icao": "FCBY",
      "iata": "NKY",
      "name": "Yokangassi Airport",
      "city": "Nkayi",
      "state": "Bouenza",
      "country": "CG",
      "elevation": 541,
      "lat": -4.2170000076,
      "lon": 13.2829999924,
      "tz": "Africa\/Brazzaville"
  },
  "FCBZ": {
      "icao": "FCBZ",
      "iata": "ANJ",
      "name": "Zanaga Airport",
      "city": "Zanaga",
      "state": "Lekoumou",
      "country": "CG",
      "elevation": 1870,
      "lat": -2.8499999046,
      "lon": 13.8170003891,
      "tz": "Africa\/Brazzaville"
  },
  "FCMA": {
      "icao": "FCMA",
      "iata": "",
      "name": "Mavinza Airport",
      "city": "Mavinza",
      "state": "Niari",
      "country": "CG",
      "elevation": 509,
      "lat": -2.4500000477,
      "lon": 11.6499996185,
      "tz": "Africa\/Brazzaville"
  },
  "FCMB": {
      "icao": "FCMB",
      "iata": "",
      "name": "N'Ziba Airport",
      "city": "N'Ziba",
      "state": "Niari",
      "country": "CG",
      "elevation": 2149,
      "lat": -2.7170000076,
      "lon": 12.5329999924,
      "tz": "Africa\/Brazzaville"
  },
  "FCMD": {
      "icao": "FCMD",
      "iata": "",
      "name": "Sidetra Airport",
      "city": "Sidetra",
      "state": "Niari",
      "country": "CG",
      "elevation": 2133,
      "lat": -2.867000103,
      "lon": 12.8669996262,
      "tz": "Africa\/Brazzaville"
  },
  "FCMF": {
      "icao": "FCMF",
      "iata": "",
      "name": "Loufoula Airport",
      "city": "Loufoula",
      "state": "Niari",
      "country": "CG",
      "elevation": 650,
      "lat": -3,
      "lon": 12,
      "tz": "Africa\/Brazzaville"
  },
  "FCMG": {
      "icao": "FCMG",
      "iata": "",
      "name": "Gokango Airport",
      "city": "Gokango",
      "state": "Niari",
      "country": "CG",
      "elevation": 820,
      "lat": -3.0329999924,
      "lon": 12.1330003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCMI": {
      "icao": "FCMI",
      "iata": "",
      "name": "Irogo Airport",
      "city": "Irogo",
      "state": "Niari",
      "country": "CG",
      "elevation": 919,
      "lat": -2.7170000076,
      "lon": 11.8830003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCMK": {
      "icao": "FCMK",
      "iata": "",
      "name": "Kele Kibangou Airport",
      "city": "Kele Kibangou",
      "state": "Niari",
      "country": "CG",
      "elevation": 902,
      "lat": -3.3169999123,
      "lon": 12.6169996262,
      "tz": "Africa\/Brazzaville"
  },
  "FCML": {
      "icao": "FCML",
      "iata": "",
      "name": "Leboulou Airport",
      "city": "Leboulou",
      "state": "Niari",
      "country": "CG",
      "elevation": 886,
      "lat": -2.9000000954,
      "lon": 12.3669996262,
      "tz": "Africa\/Brazzaville"
  },
  "FCMM": {
      "icao": "FCMM",
      "iata": "MSX",
      "name": "Mossendjo Airport",
      "city": "Mossendjo",
      "state": "Niari",
      "country": "CG",
      "elevation": 1519,
      "lat": -2.9500000477,
      "lon": 12.6999998093,
      "tz": "Africa\/Brazzaville"
  },
  "FCMN": {
      "icao": "FCMN",
      "iata": "",
      "name": "N'gongo Airport",
      "city": "N'gongo",
      "state": "Niari",
      "country": "CG",
      "elevation": 906,
      "lat": -2.9830000401,
      "lon": 12.1999998093,
      "tz": "Africa\/Brazzaville"
  },
  "FCMO": {
      "icao": "FCMO",
      "iata": "",
      "name": "Mandoro Airport",
      "city": "Mandoro",
      "state": "Niari",
      "country": "CG",
      "elevation": 1772,
      "lat": -2.6500000954,
      "lon": 12.8830003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCMR": {
      "icao": "FCMR",
      "iata": "",
      "name": "Marala Airport",
      "city": "Marala",
      "state": "Niari",
      "country": "CG",
      "elevation": 1535,
      "lat": -2.9000000954,
      "lon": 12.6330003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCMS": {
      "icao": "FCMS",
      "iata": "",
      "name": "Nyanga Airport",
      "city": "Nyanga",
      "state": "Niari",
      "country": "CG",
      "elevation": 558,
      "lat": -2.867000103,
      "lon": 11.9499998093,
      "tz": "Africa\/Brazzaville"
  },
  "FCMY": {
      "icao": "FCMY",
      "iata": "",
      "name": "Legala Airport",
      "city": "Legala",
      "state": "Niari",
      "country": "CG",
      "elevation": 2152,
      "lat": -2.2170000076,
      "lon": 12.8330001831,
      "tz": "Africa\/Brazzaville"
  },
  "FCMZ": {
      "icao": "FCMZ",
      "iata": "",
      "name": "N'Zabi Airport",
      "city": "N'Zabi",
      "state": "Niari",
      "country": "CG",
      "elevation": 1640,
      "lat": -3.1670000553,
      "lon": 12.8669996262,
      "tz": "Africa\/Brazzaville"
  },
  "FCOB": {
      "icao": "FCOB",
      "iata": "BOE",
      "name": "Boundji Airport",
      "city": "Boundji",
      "state": "Cuvette",
      "country": "CG",
      "elevation": 1247,
      "lat": -1.0329999924,
      "lon": 15.3830003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCOE": {
      "icao": "FCOE",
      "iata": "EWO",
      "name": "Ewo Airport",
      "city": "Ewo",
      "state": "Cuvette-Ouest",
      "country": "CG",
      "elevation": 1503,
      "lat": -0.8830000162,
      "lon": 14.8000001907,
      "tz": "Africa\/Brazzaville"
  },
  "FCOG": {
      "icao": "FCOG",
      "iata": "GMM",
      "name": "Gamboma Airport",
      "city": "Gamboma",
      "state": "Plateaux",
      "country": "CG",
      "elevation": 1509,
      "lat": -1.829403,
      "lon": 15.885237,
      "tz": "Africa\/Brazzaville"
  },
  "FCOI": {
      "icao": "FCOI",
      "iata": "ION",
      "name": "Impfondo Airport",
      "city": "Impfondo",
      "state": "Likouala",
      "country": "CG",
      "elevation": 1099,
      "lat": 1.6169999838,
      "lon": 18.0669994354,
      "tz": "Africa\/Brazzaville"
  },
  "FCOK": {
      "icao": "FCOK",
      "iata": "KEE",
      "name": "Kelle Airport",
      "city": "Kelle",
      "state": "Cuvette-Ouest",
      "country": "CG",
      "elevation": 1526,
      "lat": -0.0829999968,
      "lon": 14.5329999924,
      "tz": "Africa\/Brazzaville"
  },
  "FCOM": {
      "icao": "FCOM",
      "iata": "MKJ",
      "name": "Makoua Airport",
      "city": "Makoua",
      "state": "Cuvette",
      "country": "CG",
      "elevation": 1293,
      "lat": -0.0170000009,
      "lon": 15.5830001831,
      "tz": "Africa\/Brazzaville"
  },
  "FCOO": {
      "icao": "FCOO",
      "iata": "FTX",
      "name": "Owando Airport",
      "city": "Owando",
      "state": "Cuvette",
      "country": "CG",
      "elevation": 1214,
      "lat": -0.5313500166,
      "lon": 15.9500999451,
      "tz": "Africa\/Brazzaville"
  },
  "FCOS": {
      "icao": "FCOS",
      "iata": "SOE",
      "name": "Souanke Airport",
      "city": "Souanke",
      "state": "Sangha",
      "country": "CG",
      "elevation": 1722,
      "lat": 2.0669999123,
      "lon": 14.1330003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCOT": {
      "icao": "FCOT",
      "iata": "BTB",
      "name": "Betou Airport",
      "city": "Betou",
      "state": "Likouala",
      "country": "CG",
      "elevation": 1168,
      "lat": 3.0499999523,
      "lon": 18.5,
      "tz": "Africa\/Brazzaville"
  },
  "FCOU": {
      "icao": "FCOU",
      "iata": "OUE",
      "name": "Ouesso Airport",
      "city": "",
      "state": "Sangha",
      "country": "CG",
      "elevation": 1158,
      "lat": 1.6159900427,
      "lon": 16.0379009247,
      "tz": "Africa\/Brazzaville"
  },
  "FCPA": {
      "icao": "FCPA",
      "iata": "KMK",
      "name": "Makabana Airport",
      "city": "Makabana",
      "state": "Niari",
      "country": "CG",
      "elevation": 495,
      "lat": -3.4830000401,
      "lon": 12.6169996262,
      "tz": "Africa\/Brazzaville"
  },
  "FCPB": {
      "icao": "FCPB",
      "iata": "",
      "name": "Bangamba Airport",
      "city": "Bangamba",
      "state": "Lekoumou",
      "country": "CG",
      "elevation": 1312,
      "lat": -3.6830000877,
      "lon": 13.1999998093,
      "tz": "Africa\/Brazzaville"
  },
  "FCPD": {
      "icao": "FCPD",
      "iata": "DIS",
      "name": "Ngot Nzoungou Airport",
      "city": "Dolisie",
      "state": "Niari",
      "country": "CG",
      "elevation": 1079,
      "lat": -4.20635,
      "lon": 12.6599,
      "tz": "Africa\/Brazzaville"
  },
  "FCPE": {
      "icao": "FCPE",
      "iata": "",
      "name": "Leganda Airport",
      "city": "Leganda",
      "state": "Lekoumou",
      "country": "CG",
      "elevation": 1198,
      "lat": -3.4330000877,
      "lon": 12.9329996109,
      "tz": "Africa\/Brazzaville"
  },
  "FCPG": {
      "icao": "FCPG",
      "iata": "",
      "name": "Kibangou Airport",
      "city": "Kibangou",
      "state": "Niari",
      "country": "CG",
      "elevation": 427,
      "lat": -3.4830000401,
      "lon": 12.3000001907,
      "tz": "Africa\/Brazzaville"
  },
  "FCPI": {
      "icao": "FCPI",
      "iata": "",
      "name": "Loubetsi Airport",
      "city": "Loubetsi",
      "state": "Niari",
      "country": "CG",
      "elevation": 328,
      "lat": -3.7000000477,
      "lon": 12.1330003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCPK": {
      "icao": "FCPK",
      "iata": "",
      "name": "N'komo Airport",
      "city": "N'komo",
      "state": "Kouilou",
      "country": "CG",
      "elevation": 98,
      "lat": -3.9170000553,
      "lon": 11.3830003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCPN": {
      "icao": "FCPN",
      "iata": "",
      "name": "Noumbi Airport",
      "city": "Noumbi",
      "state": "Kouilou",
      "country": "CG",
      "elevation": 39,
      "lat": -4.1500000954,
      "lon": 11.3830003738,
      "tz": "Africa\/Brazzaville"
  },
  "FCPO": {
      "icao": "FCPO",
      "iata": "",
      "name": "Pemo Airport",
      "city": "Pemo",
      "state": "Niari",
      "country": "CG",
      "elevation": 1686,
      "lat": -2.867000103,
      "lon": 12.5329999924,
      "tz": "Africa\/Brazzaville"
  },
  "FCPP": {
      "icao": "FCPP",
      "iata": "PNR",
      "name": "Pointe Noire Airport",
      "city": "Pointe Noire",
      "state": "Kouilou",
      "country": "CG",
      "elevation": 55,
      "lat": -4.8160300255,
      "lon": 11.8865995407,
      "tz": "Africa\/Brazzaville"
  },
  "FCPY": {
      "icao": "FCPY",
      "iata": "",
      "name": "Loukanyi Airport",
      "city": "Loukanyi",
      "state": "Kouilou",
      "country": "CG",
      "elevation": 295,
      "lat": -4.0329999924,
      "lon": 11.5170001984,
      "tz": "Africa\/Brazzaville"
  },
  "FD01": {
      "icao": "FD01",
      "iata": "",
      "name": "Hidden Acres Airpark",
      "city": "Monticello",
      "state": "Florida",
      "country": "US",
      "elevation": 34,
      "lat": 30.2796001434,
      "lon": -84.0487976074,
      "tz": "America\/New_York"
  },
  "FD02": {
      "icao": "FD02",
      "iata": "",
      "name": "85th Avenue Airstrip",
      "city": "Orange Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 29.4850006104,
      "lon": -82.0220031738,
      "tz": "America\/New_York"
  },
  "FD03": {
      "icao": "FD03",
      "iata": "",
      "name": "The Funny Farm Airport",
      "city": "Crestview",
      "state": "Florida",
      "country": "US",
      "elevation": 200,
      "lat": 30.8001995087,
      "lon": -86.4355010986,
      "tz": "America\/Chicago"
  },
  "FD04": {
      "icao": "FD04",
      "iata": "",
      "name": "Leeward Air Ranch Airport",
      "city": "Ocala\/Belleview",
      "state": "Florida",
      "country": "US",
      "elevation": 86,
      "lat": 29.0841007233,
      "lon": -82.0323028564,
      "tz": "America\/New_York"
  },
  "FD08": {
      "icao": "FD08",
      "iata": "",
      "name": "Antiquers Aerodrome",
      "city": "Delray Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 23,
      "lat": 26.4654006958,
      "lon": -80.1498031616,
      "tz": "America\/New_York"
  },
  "FD09": {
      "icao": "FD09",
      "iata": "",
      "name": "Rlm Farms Airport",
      "city": "Kenansville",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 27.8099994659,
      "lon": -80.8589019775,
      "tz": "America\/New_York"
  },
  "FD14": {
      "icao": "FD14",
      "iata": "",
      "name": "Paniola Air Ranch Airport",
      "city": "Citra",
      "state": "Florida",
      "country": "US",
      "elevation": 140,
      "lat": 29.3752994537,
      "lon": -82.0580978394,
      "tz": "America\/New_York"
  },
  "FD15": {
      "icao": "FD15",
      "iata": "",
      "name": "Tailwinds Airport",
      "city": "Jupiter",
      "state": "Florida",
      "country": "US",
      "elevation": 19,
      "lat": 26.9794998169,
      "lon": -80.2186965942,
      "tz": "America\/New_York"
  },
  "FD16": {
      "icao": "FD16",
      "iata": "",
      "name": "Flying C Farm Airport",
      "city": "Branford",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 29.9657993317,
      "lon": -82.8728027344,
      "tz": "America\/New_York"
  },
  "FD20": {
      "icao": "FD20",
      "iata": "",
      "name": "Sundance Farms Airport",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 26.8003005981,
      "lon": -81.4850997925,
      "tz": "America\/New_York"
  },
  "FD22": {
      "icao": "FD22",
      "iata": "",
      "name": "Melrose Landing Airport",
      "city": "Melrose",
      "state": "Florida",
      "country": "US",
      "elevation": 159,
      "lat": 29.6669006348,
      "lon": -81.9551010132,
      "tz": "America\/New_York"
  },
  "FD24": {
      "icao": "FD24",
      "iata": "",
      "name": "Southern Fruit Groves Airport",
      "city": "Port St. Lucie",
      "state": "Florida",
      "country": "US",
      "elevation": 26,
      "lat": 27.221200943,
      "lon": -80.5205993652,
      "tz": "America\/New_York"
  },
  "FD25": {
      "icao": "FD25",
      "iata": "",
      "name": "Fly In Ranches Airport",
      "city": "Vero Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 27.5625,
      "lon": -80.4998016357,
      "tz": "America\/New_York"
  },
  "FD26": {
      "icao": "FD26",
      "iata": "",
      "name": "Kirkland Airport",
      "city": "Graceville",
      "state": "Florida",
      "country": "US",
      "elevation": 180,
      "lat": 30.9818000793,
      "lon": -85.492401123,
      "tz": "America\/Chicago"
  },
  "FD27": {
      "icao": "FD27",
      "iata": "",
      "name": "Cuyler Field",
      "city": "Glen St Mary",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 30.3626995087,
      "lon": -82.2261962891,
      "tz": "America\/New_York"
  },
  "FD29": {
      "icao": "FD29",
      "iata": "",
      "name": "Flying Dutchman Ranch Airport",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 29.1308002472,
      "lon": -82.1193008423,
      "tz": "America\/New_York"
  },
  "FD30": {
      "icao": "FD30",
      "iata": "",
      "name": "Southeastern Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 23,
      "lat": 27.4113998413,
      "lon": -80.5261993408,
      "tz": "America\/New_York"
  },
  "FD31": {
      "icao": "FD31",
      "iata": "",
      "name": "Bradley Airport",
      "city": "Fort White",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 29.9279994965,
      "lon": -82.7012023926,
      "tz": "America\/New_York"
  },
  "FD33": {
      "icao": "FD33",
      "iata": "",
      "name": "Green Swamp Aerodrome",
      "city": "Lakeland",
      "state": "Florida",
      "country": "US",
      "elevation": 115,
      "lat": 28.2117004395,
      "lon": -81.9987030029,
      "tz": "America\/New_York"
  },
  "FD35": {
      "icao": "FD35",
      "iata": "",
      "name": "Redhead Airport",
      "city": "Ebro",
      "state": "Florida",
      "country": "US",
      "elevation": 102,
      "lat": 30.4904994965,
      "lon": -85.8394012451,
      "tz": "America\/Chicago"
  },
  "FD37": {
      "icao": "FD37",
      "iata": "",
      "name": "Gentry Airport",
      "city": "St Cloud",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.1371994019,
      "lon": -81.2633972168,
      "tz": "America\/New_York"
  },
  "FD38": {
      "icao": "FD38",
      "iata": "",
      "name": "Wellington Aero Club Airport",
      "city": "West Palm Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 19,
      "lat": 26.6464996338,
      "lon": -80.2941970825,
      "tz": "America\/New_York"
  },
  "FD39": {
      "icao": "FD39",
      "iata": "",
      "name": "Baggett Airpark",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 19,
      "lat": 27.1555995941,
      "lon": -80.6917037964,
      "tz": "America\/New_York"
  },
  "FD40": {
      "icao": "FD40",
      "iata": "",
      "name": "Gardner Airport",
      "city": "Gardner",
      "state": "Florida",
      "country": "US",
      "elevation": 76,
      "lat": 27.344499588,
      "lon": -81.7922973633,
      "tz": "America\/New_York"
  },
  "FD42": {
      "icao": "FD42",
      "iata": "",
      "name": "Cooksey Brothers Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 30.21159935,
      "lon": -83.0546035767,
      "tz": "America\/New_York"
  },
  "FD44": {
      "icao": "FD44",
      "iata": "",
      "name": "Eagles Nest Aerodrome",
      "city": "Crescent City",
      "state": "Florida",
      "country": "US",
      "elevation": 63,
      "lat": 29.4269444,
      "lon": -81.6041667,
      "tz": "America\/New_York"
  },
  "FD48": {
      "icao": "FD48",
      "iata": "",
      "name": "Deep Forest Airport",
      "city": "Jacksonville",
      "state": "Florida",
      "country": "US",
      "elevation": 24,
      "lat": 30.2418994904,
      "lon": -81.449798584,
      "tz": "America\/New_York"
  },
  "FD49": {
      "icao": "FD49",
      "iata": "",
      "name": "Montgomery's Flying M Ranch Airport",
      "city": "Lake Panasoffkee",
      "state": "Florida",
      "country": "US",
      "elevation": 70,
      "lat": 28.7735996246,
      "lon": -82.1304016113,
      "tz": "America\/New_York"
  },
  "FD50": {
      "icao": "FD50",
      "iata": "",
      "name": "The Trails Airport",
      "city": "Mayo",
      "state": "Florida",
      "country": "US",
      "elevation": 45,
      "lat": 30.1091003418,
      "lon": -83.1886978149,
      "tz": "America\/New_York"
  },
  "FD51": {
      "icao": "FD51",
      "iata": "",
      "name": "Summerland Key Cove Airport",
      "city": "Summerland Key",
      "state": "Florida",
      "country": "US",
      "elevation": 4,
      "lat": 24.6588001251,
      "lon": -81.4414978027,
      "tz": "America\/New_York"
  },
  "FD53": {
      "icao": "FD53",
      "iata": "",
      "name": "Cub Haven STOLport",
      "city": "Dade City",
      "state": "Florida",
      "country": "US",
      "elevation": 70,
      "lat": 28.4533004761,
      "lon": -82.2149963379,
      "tz": "America\/New_York"
  },
  "FD55": {
      "icao": "FD55",
      "iata": "",
      "name": "Rutten Dusting Strip",
      "city": "Havana",
      "state": "Florida",
      "country": "US",
      "elevation": 260,
      "lat": 30.6793994904,
      "lon": -84.369102478,
      "tz": "America\/New_York"
  },
  "FD57": {
      "icao": "FD57",
      "iata": "",
      "name": "Baggett STOLport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 27.4698009491,
      "lon": -80.4163970947,
      "tz": "America\/New_York"
  },
  "FD59": {
      "icao": "FD59",
      "iata": "",
      "name": "Babcock H.Q. Airport",
      "city": "Punta Gorda",
      "state": "Florida",
      "country": "US",
      "elevation": 34,
      "lat": 26.8694992065,
      "lon": -81.7175979614,
      "tz": "America\/New_York"
  },
  "FD61": {
      "icao": "FD61",
      "iata": "",
      "name": "Wright Farms Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 104,
      "lat": 30.1765995026,
      "lon": -82.9979019165,
      "tz": "America\/New_York"
  },
  "FD63": {
      "icao": "FD63",
      "iata": "",
      "name": "Squires Aviation Ranch Airport",
      "city": "Jasper",
      "state": "Florida",
      "country": "US",
      "elevation": 110,
      "lat": 30.5128002167,
      "lon": -83.1983032227,
      "tz": "America\/New_York"
  },
  "FD68": {
      "icao": "FD68",
      "iata": "",
      "name": "Wakulla Club Airport",
      "city": "St. Marks",
      "state": "Florida",
      "country": "US",
      "elevation": 15,
      "lat": 30.1979999542,
      "lon": -84.2535018921,
      "tz": "America\/New_York"
  },
  "FD70": {
      "icao": "FD70",
      "iata": "",
      "name": "River Acres Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 27.3253002167,
      "lon": -81.0287017822,
      "tz": "America\/New_York"
  },
  "FD71": {
      "icao": "FD71",
      "iata": "",
      "name": "O'Brien Airpark East\/West Airport",
      "city": "Branford",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 30.04829979,
      "lon": -82.98390198,
      "tz": "America\/New_York"
  },
  "FD72": {
      "icao": "FD72",
      "iata": "",
      "name": "Kings Port Airport",
      "city": "Lake Placid",
      "state": "Florida",
      "country": "US",
      "elevation": 148,
      "lat": 27.182800293,
      "lon": -81.3906021118,
      "tz": "America\/New_York"
  },
  "FD74": {
      "icao": "FD74",
      "iata": "",
      "name": "Gamebird Groves Airstrip",
      "city": "West Melbourne",
      "state": "Florida",
      "country": "US",
      "elevation": 36,
      "lat": 28.0659008026,
      "lon": -80.9022979736,
      "tz": "America\/New_York"
  },
  "FD77": {
      "icao": "FD77",
      "iata": "",
      "name": "Wimauma Air Park",
      "city": "Wimauma",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 27.7119998932,
      "lon": -82.2828979492,
      "tz": "America\/New_York"
  },
  "FD81": {
      "icao": "FD81",
      "iata": "",
      "name": "Gleim Field",
      "city": "Gainesville",
      "state": "Florida",
      "country": "US",
      "elevation": 170,
      "lat": 29.7026996613,
      "lon": -82.4256973267,
      "tz": "America\/New_York"
  },
  "FD82": {
      "icao": "FD82",
      "iata": "",
      "name": "Porter STOLport",
      "city": "Jay",
      "state": "Florida",
      "country": "US",
      "elevation": 180,
      "lat": 30.8404998779,
      "lon": -87.105796814,
      "tz": "America\/Chicago"
  },
  "FD83": {
      "icao": "FD83",
      "iata": "",
      "name": "Stout Airport",
      "city": "Kissimmee",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 28.258600235,
      "lon": -81.3840026855,
      "tz": "America\/New_York"
  },
  "FD84": {
      "icao": "FD84",
      "iata": "",
      "name": "Delta Airport",
      "city": "Lake City",
      "state": "Florida",
      "country": "US",
      "elevation": 200,
      "lat": 30.1252002716,
      "lon": -82.6636962891,
      "tz": "America\/New_York"
  },
  "FD86": {
      "icao": "FD86",
      "iata": "",
      "name": "Deep Woods Ranch Airport",
      "city": "Deland",
      "state": "Florida",
      "country": "US",
      "elevation": 45,
      "lat": 29.0303001404,
      "lon": -81.4469985962,
      "tz": "America\/New_York"
  },
  "FD88": {
      "icao": "FD88",
      "iata": "",
      "name": "Aero Acres Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 27.341999054,
      "lon": -80.5220031738,
      "tz": "America\/New_York"
  },
  "FD89": {
      "icao": "FD89",
      "iata": "",
      "name": "Collier\/Pine Barren Airpark",
      "city": "Century",
      "state": "Florida",
      "country": "US",
      "elevation": 220,
      "lat": 30.8271007538,
      "lon": -87.3582992554,
      "tz": "America\/Chicago"
  },
  "FD90": {
      "icao": "FD90",
      "iata": "",
      "name": "Lafayette Landings Airport",
      "city": "De Leon Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 29.1686000824,
      "lon": -81.2648010254,
      "tz": "America\/New_York"
  },
  "FD92": {
      "icao": "FD92",
      "iata": "",
      "name": "Southerland Strip",
      "city": "Geneva",
      "state": "Florida",
      "country": "US",
      "elevation": 15,
      "lat": 28.73279953,
      "lon": -81.0786972046,
      "tz": "America\/New_York"
  },
  "FD93": {
      "icao": "FD93",
      "iata": "",
      "name": "Yellow River Airstrip",
      "city": "Holt",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.6854991913,
      "lon": -86.7472000122,
      "tz": "America\/Chicago"
  },
  "FD94": {
      "icao": "FD94",
      "iata": "",
      "name": "Hartzog Field",
      "city": "Chipley",
      "state": "Florida",
      "country": "US",
      "elevation": 125,
      "lat": 30.7189006805,
      "lon": -85.6066970825,
      "tz": "America\/Chicago"
  },
  "FD96": {
      "icao": "FD96",
      "iata": "",
      "name": "Hilliard's Private Airport",
      "city": "Moore Haven",
      "state": "Florida",
      "country": "US",
      "elevation": 13,
      "lat": 26.8325996399,
      "lon": -81.0801010132,
      "tz": "America\/New_York"
  },
  "FDBM": {
      "icao": "FDBM",
      "iata": "",
      "name": "Matata Airport",
      "city": "Big Bend",
      "state": "",
      "country": "SZ",
      "elevation": 500,
      "lat": -26.8670005798,
      "lon": 31.9330005646,
      "tz": "Africa\/Mbabane"
  },
  "FDBS": {
      "icao": "FDBS",
      "iata": "",
      "name": "Big Bend Sugar E Airport",
      "city": "Big Bend",
      "state": "Lubombo",
      "country": "SZ",
      "elevation": 541,
      "lat": -26.7999992371,
      "lon": 31.8999996185,
      "tz": "Africa\/Mbabane"
  },
  "FDBT": {
      "icao": "FDBT",
      "iata": "",
      "name": "Tambuti Airport",
      "city": "Big Bend",
      "state": "",
      "country": "SZ",
      "elevation": 595,
      "lat": -26.735200882,
      "lon": 31.7758998871,
      "tz": "Africa\/Mbabane"
  },
  "FDGL": {
      "icao": "FDGL",
      "iata": "",
      "name": "Lavumisa Airport",
      "city": "Lavumisa",
      "state": "KwaZulu-Natal",
      "country": "SZ",
      "elevation": 563,
      "lat": -27.3201007843,
      "lon": 31.889799118,
      "tz": "Africa\/Johannesburg"
  },
  "FDKB": {
      "icao": "FDKB",
      "iata": "",
      "name": "Kubuta Airport",
      "city": "Kubuta B",
      "state": "Shiselweni",
      "country": "SZ",
      "elevation": 1555,
      "lat": -26.8666667938,
      "lon": 31.4833335876,
      "tz": "Africa\/Mbabane"
  },
  "FDMH": {
      "icao": "FDMH",
      "iata": "",
      "name": "Mhlume Airport",
      "city": "Mhlume",
      "state": "Lubombo",
      "country": "SZ",
      "elevation": 926,
      "lat": -26.0242004395,
      "lon": 31.8099994659,
      "tz": "Africa\/Mbabane"
  },
  "FDMS": {
      "icao": "FDMS",
      "iata": "MTS",
      "name": "Matsapha Airport",
      "city": "Manzini",
      "state": "Manzini",
      "country": "SZ",
      "elevation": 2075,
      "lat": -26.5289993286,
      "lon": 31.3075008392,
      "tz": "Africa\/Mbabane"
  },
  "FDNG": {
      "icao": "FDNG",
      "iata": "",
      "name": "Piggs Peak Airport",
      "city": "Ngonini",
      "state": "Hhohho",
      "country": "SZ",
      "elevation": 1410,
      "lat": -25.7666664124,
      "lon": 31.3666667938,
      "tz": "Africa\/Mbabane"
  },
  "FDNH": {
      "icao": "FDNH",
      "iata": "",
      "name": "Nhlangano Airport",
      "city": "Nhlangano",
      "state": "Shiselweni",
      "country": "SZ",
      "elevation": 3522,
      "lat": -27.1166667938,
      "lon": 31.2166671753,
      "tz": "Africa\/Mbabane"
  },
  "FDNS": {
      "icao": "FDNS",
      "iata": "",
      "name": "Nsoko Airport",
      "city": "Nsoko",
      "state": "",
      "country": "SZ",
      "elevation": 614,
      "lat": -27.0333003998,
      "lon": 31.9333324432,
      "tz": "Africa\/Mbabane"
  },
  "FDSM": {
      "icao": "FDSM",
      "iata": "",
      "name": "Simunye Airport",
      "city": "Simunye",
      "state": "Lubombo",
      "country": "SZ",
      "elevation": 673,
      "lat": -26.2000007629,
      "lon": 31.9333324432,
      "tz": "Africa\/Mbabane"
  },
  "FDST": {
      "icao": "FDST",
      "iata": "",
      "name": "Siteki Airport",
      "city": "Siteki",
      "state": "Lubombo",
      "country": "SZ",
      "elevation": 2220,
      "lat": -26.4500007629,
      "lon": 31.9500007629,
      "tz": "Africa\/Mbabane"
  },
  "FDTM": {
      "icao": "FDTM",
      "iata": "",
      "name": "Tambankulu Airport",
      "city": "Tambankulu",
      "state": "",
      "country": "SZ",
      "elevation": 811,
      "lat": -26.1000003815,
      "lon": 31.9666805267,
      "tz": "Africa\/Mbabane"
  },
  "FDTS": {
      "icao": "FDTS",
      "iata": "",
      "name": "Tshaneni Airport",
      "city": "Tshaneni",
      "state": "Hhohho",
      "country": "SZ",
      "elevation": 1020,
      "lat": -25.9833335876,
      "lon": 31.2000007629,
      "tz": "Africa\/Mbabane"
  },
  "FDUB": {
      "icao": "FDUB",
      "iata": "",
      "name": "Ubombo Ranches Airport",
      "city": "Big Bend",
      "state": "",
      "country": "SZ",
      "elevation": 0,
      "lat": -26.7707996368,
      "lon": 31.9363994598,
      "tz": "Africa\/Mbabane"
  },
  "FEFA": {
      "icao": "FEFA",
      "iata": "",
      "name": "Alindao Airport",
      "city": "Alindao",
      "state": "Basse-Kotto",
      "country": "CF",
      "elevation": 1470,
      "lat": 5.0163898468,
      "lon": 21.1996994019,
      "tz": "Africa\/Bangui"
  },
  "FEFB": {
      "icao": "FEFB",
      "iata": "",
      "name": "Poste Airport",
      "city": "Obo",
      "state": "Haut-Mbomou",
      "country": "CF",
      "elevation": 0,
      "lat": 5.403888889,
      "lon": 26.487222222,
      "tz": "Africa\/Bangui"
  },
  "FEFC": {
      "icao": "FEFC",
      "iata": "CRF",
      "name": "Carnot Airport",
      "city": "Carnot",
      "state": "Mambere-Kadei",
      "country": "CF",
      "elevation": 1985,
      "lat": 4.9369997978,
      "lon": 15.8940000534,
      "tz": "Africa\/Bangui"
  },
  "FEFD": {
      "icao": "FEFD",
      "iata": "",
      "name": "Damara Airport",
      "city": "Damara",
      "state": "Ombella-M'Poko",
      "country": "CF",
      "elevation": 1427,
      "lat": 4.9499998093,
      "lon": 18.7000007629,
      "tz": "Africa\/Bangui"
  },
  "FEFE": {
      "icao": "FEFE",
      "iata": "",
      "name": "Mobaye Mbanga Airport",
      "city": "Mobaye Mbanga",
      "state": "Basse-Kotto",
      "country": "CF",
      "elevation": 1332,
      "lat": 4.3746500015,
      "lon": 21.1319007874,
      "tz": "Africa\/Bangui"
  },
  "FEFF": {
      "icao": "FEFF",
      "iata": "BGF",
      "name": "Bangui M'Poko International Airport",
      "city": "Bangui",
      "state": "Ombella-M'Poko",
      "country": "CF",
      "elevation": 1208,
      "lat": 4.3984799385,
      "lon": 18.5188007355,
      "tz": "Africa\/Bangui"
  },
  "FEFG": {
      "icao": "FEFG",
      "iata": "BGU",
      "name": "Bangassou Airport",
      "city": "Bangassou",
      "state": "",
      "country": "CF",
      "elevation": 1706,
      "lat": 4.7849998474,
      "lon": 22.7810001373,
      "tz": "Africa\/Bangui"
  },
  "FEFI": {
      "icao": "FEFI",
      "iata": "IRO",
      "name": "Birao Airport",
      "city": "Birao",
      "state": "Vakaga",
      "country": "CF",
      "elevation": 1696,
      "lat": 10.2363996506,
      "lon": 22.7168998718,
      "tz": "Africa\/Bangui"
  },
  "FEFK": {
      "icao": "FEFK",
      "iata": "",
      "name": "Kembe Airport",
      "city": "Kembe",
      "state": "Basse-Kotto",
      "country": "CF",
      "elevation": 1913,
      "lat": 4.5999999046,
      "lon": 21.8670005798,
      "tz": "Africa\/Bangui"
  },
  "FEFL": {
      "icao": "FEFL",
      "iata": "BEM",
      "name": "Bossembele Airport",
      "city": "Bossembele",
      "state": "Ombella-M'Poko",
      "country": "CF",
      "elevation": 2231,
      "lat": 5.2670001984,
      "lon": 17.6329994202,
      "tz": "Africa\/Bangui"
  },
  "FEFM": {
      "icao": "FEFM",
      "iata": "BBY",
      "name": "Bambari Airport",
      "city": "Bambari",
      "state": "Ouaka",
      "country": "CF",
      "elevation": 1549,
      "lat": 5.8469400406,
      "lon": 20.6474990845,
      "tz": "Africa\/Bangui"
  },
  "FEFN": {
      "icao": "FEFN",
      "iata": "NDL",
      "name": "N'Dele Airport",
      "city": "N'Dele",
      "state": "Bamingui-Bangoran",
      "country": "CF",
      "elevation": 1631,
      "lat": 8.4272060394,
      "lon": 20.6351566315,
      "tz": "Africa\/Bangui"
  },
  "FEFO": {
      "icao": "FEFO",
      "iata": "BOP",
      "name": "Bouar Airport",
      "city": "Bouar",
      "state": "Nana-Mambere",
      "country": "CF",
      "elevation": 3360,
      "lat": 5.9580001831,
      "lon": 15.6370000839,
      "tz": "Africa\/Bangui"
  },
  "FEFP": {
      "icao": "FEFP",
      "iata": "",
      "name": "Paoua Airport",
      "city": "Paoua",
      "state": "Ouham-Pende",
      "country": "CF",
      "elevation": 2037,
      "lat": 7.25,
      "lon": 16.4330005646,
      "tz": "Africa\/Bangui"
  },
  "FEFQ": {
      "icao": "FEFQ",
      "iata": "",
      "name": "Kaga-Bandoro Airport",
      "city": "Kaga-Bandoro",
      "state": "Nana-Grebizi",
      "country": "CF",
      "elevation": 1476,
      "lat": 6.9829998016,
      "lon": 19.1830005646,
      "tz": "Africa\/Bangui"
  },
  "FEFR": {
      "icao": "FEFR",
      "iata": "BIV",
      "name": "Bria Airport",
      "city": "Bria",
      "state": "Haute-Kotto",
      "country": "CF",
      "elevation": 1975,
      "lat": 6.527780056,
      "lon": 21.9894008636,
      "tz": "Africa\/Bangui"
  },
  "FEFS": {
      "icao": "FEFS",
      "iata": "BSN",
      "name": "Bossangoa Airport",
      "city": "Bossangoa",
      "state": "Ouham",
      "country": "CF",
      "elevation": 1637,
      "lat": 6.492000103,
      "lon": 17.4290008545,
      "tz": "Africa\/Bangui"
  },
  "FEFT": {
      "icao": "FEFT",
      "iata": "BBT",
      "name": "Berberati Airport",
      "city": "Berberati",
      "state": "Mambere-Kadei",
      "country": "CF",
      "elevation": 1929,
      "lat": 4.2215800285,
      "lon": 15.7863998413,
      "tz": "Africa\/Bangui"
  },
  "FEFU": {
      "icao": "FEFU",
      "iata": "",
      "name": "Sibut Airport",
      "city": "Sibut",
      "state": "Kemo",
      "country": "CF",
      "elevation": 1411,
      "lat": 5.7329998016,
      "lon": 19.0830001831,
      "tz": "Africa\/Bangui"
  },
  "FEFW": {
      "icao": "FEFW",
      "iata": "ODA",
      "name": "Ouadda Airport",
      "city": "Ouadda",
      "state": "Haute-Kotto",
      "country": "CF",
      "elevation": 2461,
      "lat": 8.0105600357,
      "lon": 22.3985996246,
      "tz": "Africa\/Bangui"
  },
  "FEFY": {
      "icao": "FEFY",
      "iata": "AIG",
      "name": "Yalinga Airport",
      "city": "Yalinga",
      "state": "Haute-Kotto",
      "country": "CF",
      "elevation": 1975,
      "lat": 6.5199999809,
      "lon": 23.2600002289,
      "tz": "Africa\/Bangui"
  },
  "FEFZ": {
      "icao": "FEFZ",
      "iata": "IMO",
      "name": "Zemio Airport",
      "city": "Zemio",
      "state": "Haut-Mbomou",
      "country": "CF",
      "elevation": 1995,
      "lat": 5.0500001907,
      "lon": 25.1499996185,
      "tz": "Africa\/Bangui"
  },
  "FEGB": {
      "icao": "FEGB",
      "iata": "",
      "name": "Bambouli Airport",
      "city": "Bambouli",
      "state": "Haut-Mbomou",
      "country": "CF",
      "elevation": 2264,
      "lat": 5.4330000877,
      "lon": 27.216999054,
      "tz": "Africa\/Bangui"
  },
  "FEGC": {
      "icao": "FEGC",
      "iata": "",
      "name": "Bocaranga Airport",
      "city": "Bocaranga",
      "state": "Ouham-Pende",
      "country": "CF",
      "elevation": 3464,
      "lat": 6.9169998169,
      "lon": 15.6169996262,
      "tz": "Africa\/Bangui"
  },
  "FEGD": {
      "icao": "FEGD",
      "iata": "",
      "name": "Dekoa Airport",
      "city": "Dekoa",
      "state": "Kemo",
      "country": "CF",
      "elevation": 1804,
      "lat": 6.3000001907,
      "lon": 19.0830001831,
      "tz": "Africa\/Bangui"
  },
  "FEGE": {
      "icao": "FEGE",
      "iata": "MKI",
      "name": "M'Boki Airport",
      "city": "Mboki",
      "state": "Haut-Mbomou",
      "country": "CF",
      "elevation": 1969,
      "lat": 5.3330101967,
      "lon": 25.9319000244,
      "tz": "Africa\/Bangui"
  },
  "FEGF": {
      "icao": "FEGF",
      "iata": "BTG",
      "name": "Batangafo Airport",
      "city": "Batangafo",
      "state": "Ouham",
      "country": "CF",
      "elevation": 1378,
      "lat": 7.3141098022,
      "lon": 18.3087997437,
      "tz": "Africa\/Bangui"
  },
  "FEGG": {
      "icao": "FEGG",
      "iata": "",
      "name": "Gamboula Airport",
      "city": "Gamboula",
      "state": "",
      "country": "CF",
      "elevation": 2001,
      "lat": 4.132999897,
      "lon": 15.1499996185,
      "tz": "Africa\/Bangui"
  },
  "FEGI": {
      "icao": "FEGI",
      "iata": "",
      "name": "Grimari Airport",
      "city": "Grimari",
      "state": "Ouaka",
      "country": "CF",
      "elevation": 1312,
      "lat": 5.6830000877,
      "lon": 20.0499992371,
      "tz": "Africa\/Bangui"
  },
  "FEGL": {
      "icao": "FEGL",
      "iata": "GDI",
      "name": "Gordil Airport",
      "city": "Melle",
      "state": "Vakaga",
      "country": "CF",
      "elevation": 1427,
      "lat": 9.5830001831,
      "lon": 21.7329998016,
      "tz": "Africa\/Bangui"
  },
  "FEGM": {
      "icao": "FEGM",
      "iata": "BMF",
      "name": "Bakouma Airport",
      "city": "Bakouma",
      "state": "Mbomou",
      "country": "CF",
      "elevation": 1640,
      "lat": 5.6939997673,
      "lon": 22.8010005951,
      "tz": "Africa\/Bangui"
  },
  "FEGO": {
      "icao": "FEGO",
      "iata": "ODJ",
      "name": "Ouanda Djalle Airport",
      "city": "Ouanda Djalle",
      "state": "Vakaga",
      "country": "CF",
      "elevation": 1985,
      "lat": 8.8999996185,
      "lon": 22.783000946,
      "tz": "Africa\/Bangui"
  },
  "FEGR": {
      "icao": "FEGR",
      "iata": "RFA",
      "name": "Rafai Airport",
      "city": "Rafai",
      "state": "Mbomou",
      "country": "CF",
      "elevation": 1759,
      "lat": 4.9886097908,
      "lon": 23.9277992249,
      "tz": "Africa\/Bangui"
  },
  "FEGU": {
      "icao": "FEGU",
      "iata": "BCF",
      "name": "Bouca Airport",
      "city": "Bouca",
      "state": "Ouham",
      "country": "CF",
      "elevation": 1532,
      "lat": 6.5170001984,
      "lon": 18.2670001984,
      "tz": "Africa\/Bangui"
  },
  "FEGZ": {
      "icao": "FEGZ",
      "iata": "BOZ",
      "name": "Bozoum Airport",
      "city": "Bozoum",
      "state": "Ouham-Pende",
      "country": "CF",
      "elevation": 2215,
      "lat": 6.3441700935,
      "lon": 16.3218994141,
      "tz": "Africa\/Bangui"
  },
  "FGBT": {
      "icao": "FGBT",
      "iata": "BSG",
      "name": "Bata Airport",
      "city": "",
      "state": "Litoral",
      "country": "GQ",
      "elevation": 13,
      "lat": 1.9054700136,
      "lon": 9.805680275,
      "tz": "Africa\/Malabo"
  },
  "FGSL": {
      "icao": "FGSL",
      "iata": "SSG",
      "name": "Malabo Airport",
      "city": "Malabo",
      "state": "",
      "country": "GQ",
      "elevation": 76,
      "lat": 3.7552700043,
      "lon": 8.7087202072,
      "tz": "Africa\/Malabo"
  },
  "FHAW": {
      "icao": "FHAW",
      "iata": "ASI",
      "name": "RAF Ascension Island",
      "city": "Ascension Island",
      "state": "Ascension",
      "country": "SH",
      "elevation": 278,
      "lat": -7.9696002007,
      "lon": -14.393699646,
      "tz": "Atlantic\/St_Helena"
  },
  "FIMA": {
      "icao": "FIMA",
      "iata": "",
      "name": "Agalega Island Airstrip",
      "city": "Vingt Cinq",
      "state": "Agalega-Islands",
      "country": "MU",
      "elevation": 4,
      "lat": -10.3731002808,
      "lon": 56.6097984314,
      "tz": "Indian\/Mauritius"
  },
  "FIMP": {
      "icao": "FIMP",
      "iata": "MRU",
      "name": "Sir Seewoosagur Ramgoolam International Airport",
      "city": "Port Louis",
      "state": "Grand-Port",
      "country": "MU",
      "elevation": 186,
      "lat": -20.4302005768,
      "lon": 57.6836013794,
      "tz": "Indian\/Mauritius"
  },
  "FIMR": {
      "icao": "FIMR",
      "iata": "RRG",
      "name": "Sir Charles Gaetan Duval Airport",
      "city": "Port Mathurin",
      "state": "Rodrigues",
      "country": "MU",
      "elevation": 95,
      "lat": -19.7576999664,
      "lon": 63.361000061,
      "tz": "Indian\/Mauritius"
  },
  "FJDG": {
      "icao": "FJDG",
      "iata": "NKW",
      "name": "Diego Garcia Naval Support Facility",
      "city": "Diego Garcia",
      "state": "",
      "country": "IO",
      "elevation": 9,
      "lat": -7.313270092,
      "lon": 72.4111022949,
      "tz": "Indian\/Chagos"
  },
  "FKAB": {
      "icao": "FKAB",
      "iata": "",
      "name": "Banyo Airport",
      "city": "Banyo",
      "state": "Adamaoua",
      "country": "CM",
      "elevation": 3642,
      "lat": 6.7749900818,
      "lon": 11.8070001602,
      "tz": "Africa\/Douala"
  },
  "FKAF": {
      "icao": "FKAF",
      "iata": "",
      "name": "Bafia Airport",
      "city": "Bafia",
      "state": "Centre",
      "country": "CM",
      "elevation": 1591,
      "lat": 4.7670001984,
      "lon": 11.2170000076,
      "tz": "Africa\/Douala"
  },
  "FKAG": {
      "icao": "FKAG",
      "iata": "",
      "name": "Abong M'bang Airport",
      "city": "Abong M'bang",
      "state": "East",
      "country": "CM",
      "elevation": 2297,
      "lat": 3.9670000076,
      "lon": 13.1999998093,
      "tz": "Africa\/Douala"
  },
  "FKAN": {
      "icao": "FKAN",
      "iata": "NKS",
      "name": "Nkongsamba Airport",
      "city": "Nkongsamba",
      "state": "Littoral",
      "country": "CM",
      "elevation": 2641,
      "lat": 4.9499998093,
      "lon": 9.9329996109,
      "tz": "Africa\/Douala"
  },
  "FKAO": {
      "icao": "FKAO",
      "iata": "",
      "name": "Betare Oya Airport",
      "city": "Betare Oya",
      "state": "East",
      "country": "CM",
      "elevation": 3002,
      "lat": 5.5041699409,
      "lon": 14.0982999802,
      "tz": "Africa\/Douala"
  },
  "FKKB": {
      "icao": "FKKB",
      "iata": "KBI",
      "name": "Kribi Airport",
      "city": "Kribi",
      "state": "South",
      "country": "CM",
      "elevation": 148,
      "lat": 2.8738899231,
      "lon": 9.9777803421,
      "tz": "Africa\/Douala"
  },
  "FKKC": {
      "icao": "FKKC",
      "iata": "TKC",
      "name": "Tiko Airport",
      "city": "Tiko",
      "state": "South-West",
      "country": "CM",
      "elevation": 151,
      "lat": 4.0891900063,
      "lon": 9.3605298996,
      "tz": "Africa\/Douala"
  },
  "FKKD": {
      "icao": "FKKD",
      "iata": "DLA",
      "name": "Douala International Airport",
      "city": "Douala",
      "state": "Littoral",
      "country": "CM",
      "elevation": 33,
      "lat": 4.0060801506,
      "lon": 9.7194795609,
      "tz": "Africa\/Douala"
  },
  "FKKE": {
      "icao": "FKKE",
      "iata": "",
      "name": "Eseka Airport",
      "city": "Eseka",
      "state": "Centre",
      "country": "CM",
      "elevation": 738,
      "lat": 3.6500000954,
      "lon": 10.7829999924,
      "tz": "Africa\/Douala"
  },
  "FKKF": {
      "icao": "FKKF",
      "iata": "MMF",
      "name": "Mamfe Airport",
      "city": "Mamfe",
      "state": "South-West",
      "country": "CM",
      "elevation": 413,
      "lat": 5.7041702271,
      "lon": 9.3063898087,
      "tz": "Africa\/Douala"
  },
  "FKKG": {
      "icao": "FKKG",
      "iata": "BLC",
      "name": "Bali Airport",
      "city": "Bali",
      "state": "North-West",
      "country": "CM",
      "elevation": 4465,
      "lat": 5.8952777778,
      "lon": 10.0338888889,
      "tz": "Africa\/Douala"
  },
  "FKKH": {
      "icao": "FKKH",
      "iata": "KLE",
      "name": "Kaele Airport",
      "city": "Kaele",
      "state": "Far-North",
      "country": "CM",
      "elevation": 1276,
      "lat": 10.092499733,
      "lon": 14.445599556,
      "tz": "Africa\/Douala"
  },
  "FKKI": {
      "icao": "FKKI",
      "iata": "OUR",
      "name": "Batouri Airport",
      "city": "Batouri",
      "state": "East",
      "country": "CM",
      "elevation": 2152,
      "lat": 4.4749999046,
      "lon": 14.3625001907,
      "tz": "Africa\/Douala"
  },
  "FKKJ": {
      "icao": "FKKJ",
      "iata": "GXX",
      "name": "Yagoua Airport",
      "city": "Yagoua",
      "state": "Far-North",
      "country": "CM",
      "elevation": 0,
      "lat": 10.3561000824,
      "lon": 15.2371997833,
      "tz": "Africa\/Douala"
  },
  "FKKL": {
      "icao": "FKKL",
      "iata": "MVR",
      "name": "Salak Airport",
      "city": "Maroua",
      "state": "Far-North",
      "country": "CM",
      "elevation": 1390,
      "lat": 10.4513998032,
      "lon": 14.257399559,
      "tz": "Africa\/Douala"
  },
  "FKKM": {
      "icao": "FKKM",
      "iata": "FOM",
      "name": "Foumban Nkounja Airport",
      "city": "Foumban",
      "state": "West",
      "country": "CM",
      "elevation": 3963,
      "lat": 5.6369199753,
      "lon": 10.7508001328,
      "tz": "Africa\/Douala"
  },
  "FKKN": {
      "icao": "FKKN",
      "iata": "NGE",
      "name": "N'Gaoundere Airport",
      "city": "N'Gaoundere",
      "state": "Adamaoua",
      "country": "CM",
      "elevation": 3655,
      "lat": 7.3570098877,
      "lon": 13.5592002869,
      "tz": "Africa\/Douala"
  },
  "FKKO": {
      "icao": "FKKO",
      "iata": "BTA",
      "name": "Bertoua Airport",
      "city": "Bertoua",
      "state": "East",
      "country": "CM",
      "elevation": 2133,
      "lat": 4.5486102104,
      "lon": 13.726099968,
      "tz": "Africa\/Douala"
  },
  "FKKR": {
      "icao": "FKKR",
      "iata": "GOU",
      "name": "Garoua International Airport",
      "city": "Garoua",
      "state": "North",
      "country": "CM",
      "elevation": 794,
      "lat": 9.3358898163,
      "lon": 13.3701000214,
      "tz": "Africa\/Douala"
  },
  "FKKS": {
      "icao": "FKKS",
      "iata": "DSC",
      "name": "Dschang Airport",
      "city": "Dschang",
      "state": "West",
      "country": "CM",
      "elevation": 4593,
      "lat": 5.4499998093,
      "lon": 10.0670003891,
      "tz": "Africa\/Douala"
  },
  "FKKT": {
      "icao": "FKKT",
      "iata": "",
      "name": "Tibati Airport",
      "city": "Tibati",
      "state": "Adamaoua",
      "country": "CM",
      "elevation": 2854,
      "lat": 6.4829998016,
      "lon": 12.6330003738,
      "tz": "Africa\/Douala"
  },
  "FKKU": {
      "icao": "FKKU",
      "iata": "BFX",
      "name": "Bafoussam Airport",
      "city": "Bafoussam",
      "state": "West",
      "country": "CM",
      "elevation": 4347,
      "lat": 5.5369200707,
      "lon": 10.3545999527,
      "tz": "Africa\/Douala"
  },
  "FKKV": {
      "icao": "FKKV",
      "iata": "BPC",
      "name": "Bamenda Airport",
      "city": "Bamenda",
      "state": "North-West",
      "country": "CM",
      "elevation": 4065,
      "lat": 6.0392398834,
      "lon": 10.1225996017,
      "tz": "Africa\/Douala"
  },
  "FKKW": {
      "icao": "FKKW",
      "iata": "EBW",
      "name": "Ebolowa Airport",
      "city": "Ebolowa",
      "state": "South",
      "country": "CM",
      "elevation": 1975,
      "lat": 2.8759999275,
      "lon": 11.1850004196,
      "tz": "Africa\/Douala"
  },
  "FKKY": {
      "icao": "FKKY",
      "iata": "YAO",
      "name": "Yaounde Airport",
      "city": "Yaounde",
      "state": "Centre",
      "country": "CM",
      "elevation": 2464,
      "lat": 3.83604002,
      "lon": 11.5235004425,
      "tz": "Africa\/Douala"
  },
  "FKYS": {
      "icao": "FKYS",
      "iata": "NSI",
      "name": "Yaounde Nsimalen International Airport",
      "city": "Yaounde",
      "state": "Centre",
      "country": "CM",
      "elevation": 2278,
      "lat": 3.7225599289,
      "lon": 11.5532999039,
      "tz": "Africa\/Douala"
  },
  "FL00": {
      "icao": "FL00",
      "iata": "",
      "name": "Griffins Peace River Ranch Airport",
      "city": "Zolfo Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 27.4188995361,
      "lon": -81.8326034546,
      "tz": "America\/New_York"
  },
  "FL01": {
      "icao": "FL01",
      "iata": "",
      "name": "Crews Homestead Ranch Airport",
      "city": "Avon Park",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 27.621099472,
      "lon": -81.6106033325,
      "tz": "America\/New_York"
  },
  "FL02": {
      "icao": "FL02",
      "iata": "",
      "name": "Fox Field",
      "city": "Branford",
      "state": "Florida",
      "country": "US",
      "elevation": 72,
      "lat": 29.8894004822,
      "lon": -82.7986984253,
      "tz": "America\/New_York"
  },
  "FL03": {
      "icao": "FL03",
      "iata": "",
      "name": "Department of Corrections Field",
      "city": "Lake Butler",
      "state": "Florida",
      "country": "US",
      "elevation": 131,
      "lat": 29.9918994904,
      "lon": -82.3665008545,
      "tz": "America\/New_York"
  },
  "FL05": {
      "icao": "FL05",
      "iata": "",
      "name": "Hill Landing Strip",
      "city": "Lake City",
      "state": "Florida",
      "country": "US",
      "elevation": 160,
      "lat": 30.1755008698,
      "lon": -82.6064987183,
      "tz": "America\/New_York"
  },
  "FL06": {
      "icao": "FL06",
      "iata": "",
      "name": "Golden Harvest Flying Svc Inc Airport",
      "city": "Jay",
      "state": "Florida",
      "country": "US",
      "elevation": 255,
      "lat": 30.9029998779,
      "lon": -87.0438995361,
      "tz": "America\/Chicago"
  },
  "FL07": {
      "icao": "FL07",
      "iata": "",
      "name": "Wings N Sunsets LLC Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 130,
      "lat": 30.2502002716,
      "lon": -82.9240036011,
      "tz": "America\/New_York"
  },
  "FL08": {
      "icao": "FL08",
      "iata": "",
      "name": "Florida Sheriffs Boys Ranch Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 93,
      "lat": 30.4155006409,
      "lon": -83.0165023804,
      "tz": "America\/New_York"
  },
  "FL09": {
      "icao": "FL09",
      "iata": "",
      "name": "Kittyhawk Estates Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 30.3388004303,
      "lon": -83.1443023682,
      "tz": "America\/New_York"
  },
  "FL10": {
      "icao": "FL10",
      "iata": "",
      "name": "Little River Airport",
      "city": "Mc Alpin",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 30.1149997711,
      "lon": -82.908996582,
      "tz": "America\/New_York"
  },
  "FL11": {
      "icao": "FL11",
      "iata": "",
      "name": "Thrifts Airport",
      "city": "Macclenny",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 30.3493995667,
      "lon": -82.1209030151,
      "tz": "America\/New_York"
  },
  "FL12": {
      "icao": "FL12",
      "iata": "",
      "name": "Ingalls Field",
      "city": "Miccosukee",
      "state": "Florida",
      "country": "US",
      "elevation": 183,
      "lat": 30.5904998779,
      "lon": -84.0304031372,
      "tz": "America\/New_York"
  },
  "FL13": {
      "icao": "FL13",
      "iata": "",
      "name": "Spencer's Airpark",
      "city": "Middleburg",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.0685997009,
      "lon": -81.9607009888,
      "tz": "America\/New_York"
  },
  "FL14": {
      "icao": "FL14",
      "iata": "",
      "name": "Vosika's Airport",
      "city": "Chipley",
      "state": "Florida",
      "country": "US",
      "elevation": 69,
      "lat": 30.7798995972,
      "lon": -85.5979995728,
      "tz": "America\/Chicago"
  },
  "FL15": {
      "icao": "FL15",
      "iata": "",
      "name": "Steep Head Farm Airport",
      "city": "Clarksville",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 30.4202003479,
      "lon": -85.3004989624,
      "tz": "America\/Chicago"
  },
  "FL16": {
      "icao": "FL16",
      "iata": "",
      "name": "Market World Airport",
      "city": "Auburndale",
      "state": "Florida",
      "country": "US",
      "elevation": 125,
      "lat": 28.058599472,
      "lon": -81.8164978027,
      "tz": "America\/New_York"
  },
  "FL17": {
      "icao": "FL17",
      "iata": "",
      "name": "Ruckel Airport",
      "city": "Niceville",
      "state": "Florida",
      "country": "US",
      "elevation": 67,
      "lat": 30.5195999146,
      "lon": -86.4383010864,
      "tz": "America\/Chicago"
  },
  "FL18": {
      "icao": "FL18",
      "iata": "",
      "name": "Suwannee Farms Airport",
      "city": "O'Brien",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 30.0904998779,
      "lon": -83.0546035767,
      "tz": "America\/New_York"
  },
  "FL19": {
      "icao": "FL19",
      "iata": "",
      "name": "Crosswind Farm Airport",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 74,
      "lat": 29.245300293,
      "lon": -82.3536987305,
      "tz": "America\/New_York"
  },
  "FL23": {
      "icao": "FL23",
      "iata": "",
      "name": "Strazzulla Groves Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 27,
      "lat": 27.4974994659,
      "lon": -80.529800415,
      "tz": "America\/New_York"
  },
  "FL27": {
      "icao": "FL27",
      "iata": "",
      "name": "Lake City Airpark",
      "city": "Lake City",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 30.043800354,
      "lon": -82.6039962769,
      "tz": "America\/New_York"
  },
  "FL28": {
      "icao": "FL28",
      "iata": "",
      "name": "State Prison Field",
      "city": "Raiford",
      "state": "Florida",
      "country": "US",
      "elevation": 123,
      "lat": 30.0557994843,
      "lon": -82.1723022461,
      "tz": "America\/New_York"
  },
  "FL29": {
      "icao": "FL29",
      "iata": "",
      "name": "Hales 700 Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 26,
      "lat": 27.3612003326,
      "lon": -80.5708999634,
      "tz": "America\/New_York"
  },
  "FL31": {
      "icao": "FL31",
      "iata": "",
      "name": "Mjd STOLport",
      "city": "Miami",
      "state": "Florida",
      "country": "US",
      "elevation": 6,
      "lat": 25.554599762,
      "lon": -80.5539016724,
      "tz": "America\/New_York"
  },
  "FL33": {
      "icao": "FL33",
      "iata": "",
      "name": "Watson Flight Strip",
      "city": "Trenton",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 29.6683006287,
      "lon": -82.8262023926,
      "tz": "America\/New_York"
  },
  "FL34": {
      "icao": "FL34",
      "iata": "",
      "name": "Eglin Test Site B6 Airport",
      "city": "Valparaiso",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 30.6319007874,
      "lon": -86.7472000122,
      "tz": "America\/Chicago"
  },
  "FL35": {
      "icao": "FL35",
      "iata": "",
      "name": "Geraci Airpark",
      "city": "Lutz",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 28.1749992371,
      "lon": -82.48777771,
      "tz": "America\/New_York"
  },
  "FL36": {
      "icao": "FL36",
      "iata": "",
      "name": "Tocoi Airport",
      "city": "St. Augustine",
      "state": "Florida",
      "country": "US",
      "elevation": 35,
      "lat": 29.867472,
      "lon": -81.367361,
      "tz": "America\/New_York"
  },
  "FL37": {
      "icao": "FL37",
      "iata": "",
      "name": "Treasure Coast Airpark",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 27.2383995056,
      "lon": -80.4914016724,
      "tz": "America\/New_York"
  },
  "FL40": {
      "icao": "FL40",
      "iata": "",
      "name": "Graham Landing Strip - Moore Haven Airport",
      "city": "Moore Haven",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 26.8409004211,
      "lon": -81.1517028809,
      "tz": "America\/New_York"
  },
  "FL41": {
      "icao": "FL41",
      "iata": "",
      "name": "Okeelanta Airport",
      "city": "South Bay",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 26.581199646,
      "lon": -80.755897522,
      "tz": "America\/New_York"
  },
  "FL43": {
      "icao": "FL43",
      "iata": "",
      "name": "Burntwood Ranch Airport",
      "city": "Lakeland",
      "state": "Florida",
      "country": "US",
      "elevation": 119,
      "lat": 28.2581005096,
      "lon": -81.9306030273,
      "tz": "America\/New_York"
  },
  "FL46": {
      "icao": "FL46",
      "iata": "",
      "name": "Bartram Farms Air Park",
      "city": "Saint Augustine",
      "state": "Florida",
      "country": "US",
      "elevation": 17,
      "lat": 29.821178,
      "lon": -81.497058,
      "tz": "America\/New_York"
  },
  "FL47": {
      "icao": "FL47",
      "iata": "",
      "name": "Ashley Field",
      "city": "Eustis",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.8924999237,
      "lon": -81.5569000244,
      "tz": "America\/New_York"
  },
  "FL48": {
      "icao": "FL48",
      "iata": "",
      "name": "Sierra Airpark",
      "city": "Worthington Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 115,
      "lat": 28.8822002411,
      "lon": -82.4297027588,
      "tz": "America\/New_York"
  },
  "FL50": {
      "icao": "FL50",
      "iata": "",
      "name": "Rossi Field",
      "city": "Lake City",
      "state": "Florida",
      "country": "US",
      "elevation": 183,
      "lat": 30.1466007233,
      "lon": -82.572303772,
      "tz": "America\/New_York"
  },
  "FL52": {
      "icao": "FL52",
      "iata": "",
      "name": "Angel's Field",
      "city": "Tallahassee",
      "state": "Florida",
      "country": "US",
      "elevation": 160,
      "lat": 30.4810009,
      "lon": -84.08679962,
      "tz": "America\/New_York"
  },
  "FL54": {
      "icao": "FL54",
      "iata": "",
      "name": "Flying Tiger Field",
      "city": "Worthington",
      "state": "Florida",
      "country": "US",
      "elevation": 115,
      "lat": 29.9354991913,
      "lon": -82.410697937,
      "tz": "America\/New_York"
  },
  "FL56": {
      "icao": "FL56",
      "iata": "",
      "name": "Williams Hawgwild Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 27.4088993073,
      "lon": -80.4278030396,
      "tz": "America\/New_York"
  },
  "FL57": {
      "icao": "FL57",
      "iata": "",
      "name": "Carter Airport",
      "city": "Apopka",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.6189002991,
      "lon": -81.5086975098,
      "tz": "America\/New_York"
  },
  "FL58": {
      "icao": "FL58",
      "iata": "",
      "name": "Johary Airport",
      "city": "Belleview",
      "state": "Florida",
      "country": "US",
      "elevation": 72,
      "lat": 29.0263996124,
      "lon": -81.9878997803,
      "tz": "America\/New_York"
  },
  "FL59": {
      "icao": "FL59",
      "iata": "",
      "name": "Buckingham Field",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 23,
      "lat": 26.6434001923,
      "lon": -81.7104034424,
      "tz": "America\/New_York"
  },
  "FL60": {
      "icao": "FL60",
      "iata": "",
      "name": "Reynolds Airpark",
      "city": "Green Cove Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 21,
      "lat": 29.9724998474,
      "lon": -81.6608963013,
      "tz": "America\/New_York"
  },
  "FL61": {
      "icao": "FL61",
      "iata": "",
      "name": "Mc Ginley Airport",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 84,
      "lat": 29.0261001587,
      "lon": -82.2123031616,
      "tz": "America\/New_York"
  },
  "FL62": {
      "icao": "FL62",
      "iata": "",
      "name": "Bradshaw Farm Airport",
      "city": "Deland",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 28.948600769,
      "lon": -81.4131011963,
      "tz": "America\/New_York"
  },
  "FL63": {
      "icao": "FL63",
      "iata": "",
      "name": "Idle Wild Airport",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 29.289100647,
      "lon": -82.322303772,
      "tz": "America\/New_York"
  },
  "FL65": {
      "icao": "FL65",
      "iata": "",
      "name": "Mac-Ivor & Friends Airstrip",
      "city": "Hollywood",
      "state": "Florida",
      "country": "US",
      "elevation": 4,
      "lat": 26.0447998047,
      "lon": -80.3733978271,
      "tz": "America\/New_York"
  },
  "FL66": {
      "icao": "FL66",
      "iata": "",
      "name": "Peach Orchard Airport",
      "city": "Archer",
      "state": "Florida",
      "country": "US",
      "elevation": 75,
      "lat": 29.5597000122,
      "lon": -82.49949646,
      "tz": "America\/New_York"
  },
  "FL67": {
      "icao": "FL67",
      "iata": "",
      "name": "Evans Properties Inc Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 24,
      "lat": 27.2777996063,
      "lon": -80.585899353,
      "tz": "America\/New_York"
  },
  "FL73": {
      "icao": "FL73",
      "iata": "",
      "name": "Lykes Palmdale Airport",
      "city": "Palmdale",
      "state": "Florida",
      "country": "US",
      "elevation": 42,
      "lat": 26.9477996826,
      "lon": -81.3187026978,
      "tz": "America\/New_York"
  },
  "FL74": {
      "icao": "FL74",
      "iata": "",
      "name": "Indian River Aerodrome",
      "city": "Vero Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 27.5986003876,
      "lon": -80.5019989014,
      "tz": "America\/New_York"
  },
  "FL75": {
      "icao": "FL75",
      "iata": "",
      "name": "Indian Hammock Airport",
      "city": "Fort Drum",
      "state": "Florida",
      "country": "US",
      "elevation": 65,
      "lat": 27.5419998169,
      "lon": -80.8330993652,
      "tz": "America\/New_York"
  },
  "FL76": {
      "icao": "FL76",
      "iata": "",
      "name": "Seven Springs Ranch Airport",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 50,
      "lat": 28.9638996124,
      "lon": -82.2194976807,
      "tz": "America\/New_York"
  },
  "FL77": {
      "icao": "FL77",
      "iata": "",
      "name": "Calusa Ranch Airport",
      "city": "Miles City",
      "state": "Florida",
      "country": "US",
      "elevation": 15,
      "lat": 26.0545005798,
      "lon": -81.0678024292,
      "tz": "America\/New_York"
  },
  "FL78": {
      "icao": "FL78",
      "iata": "",
      "name": "Lewis Airport",
      "city": "Brandon",
      "state": "Florida",
      "country": "US",
      "elevation": 45,
      "lat": 27.8985996246,
      "lon": -82.1826019287,
      "tz": "America\/New_York"
  },
  "FL80": {
      "icao": "FL80",
      "iata": "",
      "name": "Lee Farms Airport",
      "city": "Lowell",
      "state": "Florida",
      "country": "US",
      "elevation": 100,
      "lat": 29.3346996307,
      "lon": -82.1668014526,
      "tz": "America\/New_York"
  },
  "FL81": {
      "icao": "FL81",
      "iata": "",
      "name": "Triple B Airpark",
      "city": "Crestview",
      "state": "Florida",
      "country": "US",
      "elevation": 210,
      "lat": 30.7819004059,
      "lon": -86.4672012329,
      "tz": "America\/Chicago"
  },
  "FL82": {
      "icao": "FL82",
      "iata": "",
      "name": "Oak Ridge Airport",
      "city": "Interlachen",
      "state": "Florida",
      "country": "US",
      "elevation": 72,
      "lat": 29.580536,
      "lon": -81.861181,
      "tz": "America\/New_York"
  },
  "FL84": {
      "icao": "FL84",
      "iata": "",
      "name": "Flying-A-Ranch Airport",
      "city": "Fernandina Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 25,
      "lat": 30.6011009216,
      "lon": -81.5703964233,
      "tz": "America\/New_York"
  },
  "FL86": {
      "icao": "FL86",
      "iata": "",
      "name": "Woodstock Airport",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 5,
      "lat": 26.5825996399,
      "lon": -82.0983963013,
      "tz": "America\/New_York"
  },
  "FL88": {
      "icao": "FL88",
      "iata": "",
      "name": "Bob Paul Airport",
      "city": "La Belle",
      "state": "Florida",
      "country": "US",
      "elevation": 28,
      "lat": 26.6837005615,
      "lon": -81.449798584,
      "tz": "America\/New_York"
  },
  "FL89": {
      "icao": "FL89",
      "iata": "",
      "name": "Triple M Airport",
      "city": "Vero Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 27.5541992188,
      "lon": -80.4397964478,
      "tz": "America\/New_York"
  },
  "FL90": {
      "icao": "FL90",
      "iata": "",
      "name": "Salty Approach Airport",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 6,
      "lat": 26.6033992767,
      "lon": -82.2201004028,
      "tz": "America\/New_York"
  },
  "FL91": {
      "icao": "FL91",
      "iata": "",
      "name": "Hendry Correctional Institution Airport",
      "city": "Sunniland",
      "state": "Florida",
      "country": "US",
      "elevation": 22,
      "lat": 26.3097991943,
      "lon": -81.2273025513,
      "tz": "America\/New_York"
  },
  "FL95": {
      "icao": "FL95",
      "iata": "",
      "name": "Broocke Air Patch Airport",
      "city": "Winter Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 22,
      "lat": 27.7085990906,
      "lon": -80.452796936,
      "tz": "America\/New_York"
  },
  "FL96": {
      "icao": "FL96",
      "iata": "",
      "name": "Jay Airport",
      "city": "Jay",
      "state": "Florida",
      "country": "US",
      "elevation": 254,
      "lat": 30.9556999207,
      "lon": -87.1305007935,
      "tz": "America\/Chicago"
  },
  "FL97": {
      "icao": "FL97",
      "iata": "",
      "name": "Tangerine Airport",
      "city": "Zellwood",
      "state": "Florida",
      "country": "US",
      "elevation": 125,
      "lat": 28.7602996826,
      "lon": -81.6059036255,
      "tz": "America\/New_York"
  },
  "FL99": {
      "icao": "FL99",
      "iata": "",
      "name": "Flying-H Airport",
      "city": "Fruitland Park",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.8761005402,
      "lon": -81.888999939,
      "tz": "America\/New_York"
  },
  "FLAT": {
      "icao": "FLAT",
      "iata": "",
      "name": "Katete Airport",
      "city": "Katete",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 3520,
      "lat": -14.120300293,
      "lon": 32.0643997192,
      "tz": "Africa\/Lusaka"
  },
  "FLBA": {
      "icao": "FLBA",
      "iata": "MMQ",
      "name": "Mbala Airport",
      "city": "Mbala",
      "state": "Northern",
      "country": "ZM",
      "elevation": 5454,
      "lat": -8.85916996,
      "lon": 31.3363990784,
      "tz": "Africa\/Lusaka"
  },
  "FLCC": {
      "icao": "FLCC",
      "iata": "",
      "name": "Chocha Airport",
      "city": "Chocha",
      "state": "Northern",
      "country": "ZM",
      "elevation": 3270,
      "lat": -8.428730011,
      "lon": 29.8059005737,
      "tz": "Africa\/Lusaka"
  },
  "FLCH": {
      "icao": "FLCH",
      "iata": "",
      "name": "Choma Airport",
      "city": "Choma",
      "state": "Southern",
      "country": "ZM",
      "elevation": 4300,
      "lat": -16.783000946,
      "lon": 27,
      "tz": "Africa\/Lusaka"
  },
  "FLCP": {
      "icao": "FLCP",
      "iata": "CIP",
      "name": "Chipata Airport",
      "city": "Chipata",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 3360,
      "lat": -13.5583000183,
      "lon": 32.5872001648,
      "tz": "Africa\/Lusaka"
  },
  "FLCS": {
      "icao": "FLCS",
      "iata": "",
      "name": "Chinsali Airport",
      "city": "Chinsali",
      "state": "Muchinga",
      "country": "ZM",
      "elevation": 4350,
      "lat": -10.5329999924,
      "lon": 32.0670013428,
      "tz": "Africa\/Lusaka"
  },
  "FLEA": {
      "icao": "FLEA",
      "iata": "",
      "name": "East One Airport",
      "city": "Bwambwa",
      "state": "Luapula",
      "country": "ZM",
      "elevation": 3900,
      "lat": -11.6357002258,
      "lon": 29.7359008789,
      "tz": "Africa\/Lusaka"
  },
  "FLEB": {
      "icao": "FLEB",
      "iata": "",
      "name": "East Two Airport",
      "city": "Mofu",
      "state": "Northern",
      "country": "ZM",
      "elevation": 3900,
      "lat": -11.1034002304,
      "lon": 30.3381996155,
      "tz": "Africa\/Lusaka"
  },
  "FLEC": {
      "icao": "FLEC",
      "iata": "",
      "name": "East Three Airport",
      "city": "Luano",
      "state": "Central",
      "country": "ZM",
      "elevation": 2300,
      "lat": -14.1134996414,
      "lon": 30.1294994354,
      "tz": "Africa\/Lusaka"
  },
  "FLED": {
      "icao": "FLED",
      "iata": "",
      "name": "East Four Airport",
      "city": "Mululowera",
      "state": "Lusaka",
      "country": "ZM",
      "elevation": 1880,
      "lat": -14.8330001831,
      "lon": 30.0830001831,
      "tz": "Africa\/Lusaka"
  },
  "FLEE": {
      "icao": "FLEE",
      "iata": "",
      "name": "East Five Airport",
      "city": "Chidulka",
      "state": "Muchinga",
      "country": "ZM",
      "elevation": 4200,
      "lat": -10.5356998444,
      "lon": 33.4071998596,
      "tz": "Africa\/Lusaka"
  },
  "FLEF": {
      "icao": "FLEF",
      "iata": "",
      "name": "East Six Airport",
      "city": "Chiwanangala",
      "state": "Northern",
      "country": "ZM",
      "elevation": 3800,
      "lat": -11.1342000961,
      "lon": 30.01720047,
      "tz": "Africa\/Lusaka"
  },
  "FLEG": {
      "icao": "FLEG",
      "iata": "",
      "name": "East Seven Airport",
      "city": "Kanshela",
      "state": "Haut-Katanga",
      "country": "ZM",
      "elevation": 4000,
      "lat": -12.4499998093,
      "lon": 29.4829998016,
      "tz": "Africa\/Lubumbashi"
  },
  "FLEH": {
      "icao": "FLEH",
      "iata": "",
      "name": "East Eight Airport",
      "city": "Kapu",
      "state": "Luapula",
      "country": "ZM",
      "elevation": 4000,
      "lat": -12.1169996262,
      "lon": 29.4500007629,
      "tz": "Africa\/Lusaka"
  },
  "FLIK": {
      "icao": "FLIK",
      "iata": "",
      "name": "Isoka Airport",
      "city": "Isoka",
      "state": "Muchinga",
      "country": "ZM",
      "elevation": 4462,
      "lat": -10.1169996262,
      "lon": 32.6329994202,
      "tz": "Africa\/Lusaka"
  },
  "FLJK": {
      "icao": "FLJK",
      "iata": "JEK",
      "name": "Jeki Airstrip",
      "city": "Lower Zambezi National Park",
      "state": "Muchinga",
      "country": "ZM",
      "elevation": 4462,
      "lat": -15.633332,
      "lon": 29.603333,
      "tz": "Africa\/Harare"
  },
  "FLKB": {
      "icao": "FLKB",
      "iata": "",
      "name": "Kawambwa Airport",
      "city": "Kawambwa",
      "state": "Luapula",
      "country": "ZM",
      "elevation": 4640,
      "lat": -9.7980604172,
      "lon": 29.0916996002,
      "tz": "Africa\/Lusaka"
  },
  "FLKE": {
      "icao": "FLKE",
      "iata": "ZKP",
      "name": "Kasompe Airport",
      "city": "Kasompe",
      "state": "Copperbelt",
      "country": "ZM",
      "elevation": 4636,
      "lat": -12.5727996826,
      "lon": 27.8938999176,
      "tz": "Africa\/Lusaka"
  },
  "FLKG": {
      "icao": "FLKG",
      "iata": "",
      "name": "Kalengwa Airport",
      "city": "Kalengwa",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 4093,
      "lat": -13.4741001129,
      "lon": 25.0140991211,
      "tz": "Africa\/Lusaka"
  },
  "FLKJ": {
      "icao": "FLKJ",
      "iata": "",
      "name": "Kanja Airport",
      "city": "Kanja",
      "state": "Western",
      "country": "ZM",
      "elevation": 3370,
      "lat": -16.4500007629,
      "lon": 23.3670005798,
      "tz": "Africa\/Lusaka"
  },
  "FLKK": {
      "icao": "FLKK",
      "iata": "LUN",
      "name": "Kenneth Kaunda International Airport",
      "city": "Lusaka",
      "state": "Lusaka",
      "country": "ZM",
      "elevation": 3779,
      "lat": -15.3308000565,
      "lon": 28.4526004791,
      "tz": "Africa\/Lusaka"
  },
  "FLKL": {
      "icao": "FLKL",
      "iata": "KLB",
      "name": "Kalabo Airport",
      "city": "Kalabo",
      "state": "Western",
      "country": "ZM",
      "elevation": 3450,
      "lat": -14.9982995987,
      "lon": 22.6453990936,
      "tz": "Africa\/Lusaka"
  },
  "FLKO": {
      "icao": "FLKO",
      "iata": "KMZ",
      "name": "Kaoma Airport",
      "city": "Kaoma",
      "state": "Western",
      "country": "ZM",
      "elevation": 3670,
      "lat": -14.8000001907,
      "lon": 24.783000946,
      "tz": "Africa\/Lusaka"
  },
  "FLKS": {
      "icao": "FLKS",
      "iata": "KAA",
      "name": "Kasama Airport",
      "city": "Kasama",
      "state": "Northern",
      "country": "ZM",
      "elevation": 4541,
      "lat": -10.2166996002,
      "lon": 31.1333007813,
      "tz": "Africa\/Lusaka"
  },
  "FLKU": {
      "icao": "FLKU",
      "iata": "",
      "name": "Kanyau Airport",
      "city": "Kanyau",
      "state": "Western",
      "country": "ZM",
      "elevation": 3448,
      "lat": -16.5,
      "lon": 22.4169998169,
      "tz": "Africa\/Lusaka"
  },
  "FLKW": {
      "icao": "FLKW",
      "iata": "",
      "name": "Milliken Airport",
      "city": "Kabwe",
      "state": "Central",
      "country": "ZM",
      "elevation": 3920,
      "lat": -14.4505996704,
      "lon": 28.3791999817,
      "tz": "Africa\/Lusaka"
  },
  "FLKY": {
      "icao": "FLKY",
      "iata": "ZKB",
      "name": "Kasaba Bay Airport",
      "city": "Kasaba Bay",
      "state": "Northern",
      "country": "ZM",
      "elevation": 2780,
      "lat": -8.5249996185,
      "lon": 30.6630001068,
      "tz": "Africa\/Lusaka"
  },
  "FLKZ": {
      "icao": "FLKZ",
      "iata": "",
      "name": "Lukuzi Airport",
      "city": "Nansolo",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 1801,
      "lat": -12.8119001389,
      "lon": 32.0648994446,
      "tz": "Africa\/Lusaka"
  },
  "FLLA": {
      "icao": "FLLA",
      "iata": "",
      "name": "Luanshya Zambia Airport",
      "city": "Luanshya",
      "state": "Copperbelt",
      "country": "ZM",
      "elevation": 4101,
      "lat": -13.141699791,
      "lon": 28.4249992371,
      "tz": "Africa\/Lusaka"
  },
  "FLLC": {
      "icao": "FLLC",
      "iata": "",
      "name": "Lusaka City Airport",
      "city": "Lusaka",
      "state": "Lusaka",
      "country": "ZM",
      "elevation": 4200,
      "lat": -15.4138002396,
      "lon": 28.3306999207,
      "tz": "Africa\/Lusaka"
  },
  "FLLD": {
      "icao": "FLLD",
      "iata": "",
      "name": "Lundazi Airport",
      "city": "Lundazi",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 3750,
      "lat": -12.2860002518,
      "lon": 33.186000824,
      "tz": "Africa\/Lusaka"
  },
  "FLLG": {
      "icao": "FLLG",
      "iata": "",
      "name": "Luwingu Airport",
      "city": "Luwingu",
      "state": "",
      "country": "ZM",
      "elevation": 4650,
      "lat": -10.245300293,
      "lon": 29.9139995575,
      "tz": "Africa\/Lusaka"
  },
  "FLLI": {
      "icao": "FLLI",
      "iata": "LVI",
      "name": "Livingstone Airport",
      "city": "Livingstone",
      "state": "Southern",
      "country": "ZM",
      "elevation": 3302,
      "lat": -17.8218002319,
      "lon": 25.8227005005,
      "tz": "Africa\/Lusaka"
  },
  "FLLK": {
      "icao": "FLLK",
      "iata": "LXU",
      "name": "Lukulu Airport",
      "city": "Lukulu",
      "state": "Western",
      "country": "ZM",
      "elevation": 3480,
      "lat": -14.3747997284,
      "lon": 23.2495002747,
      "tz": "Africa\/Lusaka"
  },
  "FLLO": {
      "icao": "FLLO",
      "iata": "",
      "name": "Kalomo Airport",
      "city": "Kalomo",
      "state": "Southern",
      "country": "ZM",
      "elevation": 4100,
      "lat": -16.9829998016,
      "lon": 26.4829998016,
      "tz": "Africa\/Lusaka"
  },
  "FLLS": {
      "icao": "FLLS",
      "iata": "LUN",
      "name": "Lusaka International Airport",
      "city": "Lusaka",
      "state": "Lusaka",
      "country": "ZM",
      "elevation": 3779,
      "lat": -15.3308000565,
      "lon": 28.4526004791,
      "tz": "Africa\/Lusaka"
  },
  "FLMA": {
      "icao": "FLMA",
      "iata": "MNS",
      "name": "Mansa Airport",
      "city": "Mansa",
      "state": "Luapula",
      "country": "ZM",
      "elevation": 4101,
      "lat": -11.1370000839,
      "lon": 28.8726005554,
      "tz": "Africa\/Lusaka"
  },
  "FLMB": {
      "icao": "FLMB",
      "iata": "",
      "name": "Maamba Airport",
      "city": "Maamba",
      "state": "Southern",
      "country": "ZM",
      "elevation": 2050,
      "lat": -17.3670005798,
      "lon": 27.1830005646,
      "tz": "Africa\/Lusaka"
  },
  "FLMF": {
      "icao": "FLMF",
      "iata": "MFU",
      "name": "Mfuwe Airport",
      "city": "Mfuwe",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 1853,
      "lat": -13.2588996887,
      "lon": 31.9365997314,
      "tz": "Africa\/Lusaka"
  },
  "FLMG": {
      "icao": "FLMG",
      "iata": "MNR",
      "name": "Mongu Airport",
      "city": "Mongu",
      "state": "Western",
      "country": "ZM",
      "elevation": 3488,
      "lat": -15.2545003891,
      "lon": 23.1623001099,
      "tz": "Africa\/Lusaka"
  },
  "FLMK": {
      "icao": "FLMK",
      "iata": "",
      "name": "Mkushi Airport",
      "city": "Mkushi",
      "state": "Central",
      "country": "ZM",
      "elevation": 4195,
      "lat": -13.6169996262,
      "lon": 29.3829994202,
      "tz": "Africa\/Lusaka"
  },
  "FLML": {
      "icao": "FLML",
      "iata": "",
      "name": "Mufulira Airport",
      "city": "Mufulira",
      "state": "Copperbelt",
      "country": "ZM",
      "elevation": 4350,
      "lat": -12.565199852,
      "lon": 28.2943000793,
      "tz": "Africa\/Lusaka"
  },
  "FLMO": {
      "icao": "FLMO",
      "iata": "",
      "name": "Monze Airport",
      "city": "Monze",
      "state": "Southern",
      "country": "ZM",
      "elevation": 3700,
      "lat": -16.283000946,
      "lon": 27.5170001984,
      "tz": "Africa\/Lusaka"
  },
  "FLMP": {
      "icao": "FLMP",
      "iata": "",
      "name": "Mpika Airport",
      "city": "Mpika",
      "state": "Northern",
      "country": "ZM",
      "elevation": 4600,
      "lat": -11.8992004395,
      "lon": 31.4347000122,
      "tz": "Africa\/Lusaka"
  },
  "FLMU": {
      "icao": "FLMU",
      "iata": "",
      "name": "Mulobezi Airport",
      "city": "Mulobezi",
      "state": "Western",
      "country": "ZM",
      "elevation": 3175,
      "lat": -16.7670001984,
      "lon": 25.1830005646,
      "tz": "Africa\/Lusaka"
  },
  "FLMW": {
      "icao": "FLMW",
      "iata": "",
      "name": "Mwinilunga Airport",
      "city": "Mwinilunga",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 4524,
      "lat": -11.6540002823,
      "lon": 24.4309997559,
      "tz": "Africa\/Lusaka"
  },
  "FLMZ": {
      "icao": "FLMZ",
      "iata": "",
      "name": "Mazabuka Airport",
      "city": "Mazabuka",
      "state": "Southern",
      "country": "ZM",
      "elevation": 3450,
      "lat": -15.8500003815,
      "lon": 27.7416992188,
      "tz": "Africa\/Lusaka"
  },
  "FLNA": {
      "icao": "FLNA",
      "iata": "ZGM",
      "name": "Ngoma Airport",
      "city": "Ngoma",
      "state": "Southern",
      "country": "ZM",
      "elevation": 3400,
      "lat": -15.9658002853,
      "lon": 25.9333000183,
      "tz": "Africa\/Lusaka"
  },
  "FLND": {
      "icao": "FLND",
      "iata": "NLA",
      "name": "Ndola Airport",
      "city": "Ndola",
      "state": "Copperbelt",
      "country": "ZM",
      "elevation": 4167,
      "lat": -12.9981002808,
      "lon": 28.664899826,
      "tz": "Africa\/Lusaka"
  },
  "FLNL": {
      "icao": "FLNL",
      "iata": "",
      "name": "Namwala Airport",
      "city": "Namwala",
      "state": "Southern",
      "country": "ZM",
      "elevation": 3200,
      "lat": -15.7650003433,
      "lon": 26.4319000244,
      "tz": "Africa\/Lusaka"
  },
  "FLNY": {
      "icao": "FLNY",
      "iata": "",
      "name": "Nyimba Airport",
      "city": "Nyimba",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 2600,
      "lat": -14.5685997009,
      "lon": 30.8341007233,
      "tz": "Africa\/Lusaka"
  },
  "FLPA": {
      "icao": "FLPA",
      "iata": "",
      "name": "Kasempa Airport",
      "city": "Kasempa",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 4150,
      "lat": -13.4399995804,
      "lon": 25.7859992981,
      "tz": "Africa\/Lusaka"
  },
  "FLPE": {
      "icao": "FLPE",
      "iata": "",
      "name": "Petauke Airport",
      "city": "Petauke",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 3300,
      "lat": -14.1999998093,
      "lon": 31.216999054,
      "tz": "Africa\/Lusaka"
  },
  "FLPK": {
      "icao": "FLPK",
      "iata": "",
      "name": "Mporokoso Airport",
      "city": "Mporokoso",
      "state": "Northern",
      "country": "ZM",
      "elevation": 4570,
      "lat": -9.3666601181,
      "lon": 30.1389007568,
      "tz": "Africa\/Lusaka"
  },
  "FLPO": {
      "icao": "FLPO",
      "iata": "",
      "name": "Kabompo Airport",
      "city": "Kabompo",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 3535,
      "lat": -13.5760002136,
      "lon": 24.2299995422,
      "tz": "Africa\/Lusaka"
  },
  "FLRO": {
      "icao": "FLRO",
      "iata": "",
      "name": "Rosa Airport",
      "city": "Rosa",
      "state": "Northern",
      "country": "ZM",
      "elevation": 5000,
      "lat": -9.5268297195,
      "lon": 31.2374992371,
      "tz": "Africa\/Lusaka"
  },
  "FLRU": {
      "icao": "FLRU",
      "iata": "",
      "name": "Rufansa Airport",
      "city": "Rufansa",
      "state": "Lusaka",
      "country": "ZM",
      "elevation": 3000,
      "lat": -15.0830001831,
      "lon": 29.6329994202,
      "tz": "Africa\/Lusaka"
  },
  "FLSE": {
      "icao": "FLSE",
      "iata": "",
      "name": "Serenje Airport",
      "city": "Serenje",
      "state": "Central",
      "country": "ZM",
      "elevation": 4650,
      "lat": -13.1829996109,
      "lon": 30.2329998016,
      "tz": "Africa\/Lusaka"
  },
  "FLSH": {
      "icao": "FLSH",
      "iata": "",
      "name": "Shiwa n'gandu Airport",
      "city": "Shiwa n'gandu",
      "state": "Muchinga",
      "country": "ZM",
      "elevation": 4600,
      "lat": -11.2009000778,
      "lon": 31.7481994629,
      "tz": "Africa\/Lusaka"
  },
  "FLSJ": {
      "icao": "FLSJ",
      "iata": "",
      "name": "Sakeji Airport",
      "city": "Sakeji",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 4500,
      "lat": -11.2327003479,
      "lon": 24.316400528,
      "tz": "Africa\/Lusaka"
  },
  "FLSN": {
      "icao": "FLSN",
      "iata": "SXG",
      "name": "Senanga Airport",
      "city": "Senanga",
      "state": "Western",
      "country": "ZM",
      "elevation": 3370,
      "lat": -16.1000003815,
      "lon": 23.2670001984,
      "tz": "Africa\/Lusaka"
  },
  "FLSO": {
      "icao": "FLSO",
      "iata": "KIW",
      "name": "Southdowns Airport",
      "city": "Kitwe",
      "state": "Copperbelt",
      "country": "ZM",
      "elevation": 4145,
      "lat": -12.9005002975,
      "lon": 28.1499004364,
      "tz": "Africa\/Lusaka"
  },
  "FLSS": {
      "icao": "FLSS",
      "iata": "SJQ",
      "name": "Sesheke Airport",
      "city": "Sesheke",
      "state": "Western",
      "country": "ZM",
      "elevation": 3100,
      "lat": -17.4762992859,
      "lon": 24.3047008514,
      "tz": "Africa\/Lusaka"
  },
  "FLSW": {
      "icao": "FLSW",
      "iata": "SLI",
      "name": "Solwesi Airport",
      "city": "Solwesi",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 4551,
      "lat": -12.1737003326,
      "lon": 26.3651008606,
      "tz": "Africa\/Lusaka"
  },
  "FLWA": {
      "icao": "FLWA",
      "iata": "",
      "name": "West One Airport",
      "city": "Chinka",
      "state": "Copperbelt",
      "country": "ZM",
      "elevation": 4000,
      "lat": -12.8549003601,
      "lon": 27.0722007751,
      "tz": "Africa\/Lusaka"
  },
  "FLWB": {
      "icao": "FLWB",
      "iata": "",
      "name": "West Two Airport",
      "city": "Metamba",
      "state": "Central",
      "country": "ZM",
      "elevation": 3760,
      "lat": -13.7664003372,
      "lon": 27.5172996521,
      "tz": "Africa\/Lusaka"
  },
  "FLWC": {
      "icao": "FLWC",
      "iata": "",
      "name": "West Three Airport",
      "city": "Nyoka",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 3650,
      "lat": -13.3552999496,
      "lon": 26.6005992889,
      "tz": "Africa\/Lusaka"
  },
  "FLWD": {
      "icao": "FLWD",
      "iata": "",
      "name": "West Four Airport",
      "city": "West Four",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 3400,
      "lat": -13.6454000473,
      "lon": 25.4218997955,
      "tz": "Africa\/Lusaka"
  },
  "FLWE": {
      "icao": "FLWE",
      "iata": "",
      "name": "West Five Airport",
      "city": "Lipanda",
      "state": "Western",
      "country": "ZM",
      "elevation": 3550,
      "lat": -13.9642000198,
      "lon": 24.4605998993,
      "tz": "Africa\/Lusaka"
  },
  "FLWF": {
      "icao": "FLWF",
      "iata": "",
      "name": "West Six Airport",
      "city": "Kauni",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 4000,
      "lat": -12.2172002792,
      "lon": 27.4904994965,
      "tz": "Africa\/Lusaka"
  },
  "FLWG": {
      "icao": "FLWG",
      "iata": "",
      "name": "West Seven Airport",
      "city": "Chanika",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 4000,
      "lat": -11.9126996994,
      "lon": 25.6844005585,
      "tz": "Africa\/Lusaka"
  },
  "FLWW": {
      "icao": "FLWW",
      "iata": "",
      "name": "Waka Waka Airport",
      "city": "Waka Waka",
      "state": "Eastern",
      "country": "ZM",
      "elevation": 1800,
      "lat": -12.4504003525,
      "lon": 32.2569999695,
      "tz": "Africa\/Lusaka"
  },
  "FLYA": {
      "icao": "FLYA",
      "iata": "",
      "name": "Samfya Airport",
      "city": "Samfya",
      "state": "Luapula",
      "country": "ZM",
      "elevation": 3800,
      "lat": -11.3653001785,
      "lon": 29.5480995178,
      "tz": "Africa\/Lusaka"
  },
  "FLZB": {
      "icao": "FLZB",
      "iata": "BBZ",
      "name": "Zambezi Airport",
      "city": "Zambezi",
      "state": "North-Western",
      "country": "ZM",
      "elevation": 3538,
      "lat": -13.5361003876,
      "lon": 23.108499527,
      "tz": "Africa\/Lusaka"
  },
  "FM43": {
      "icao": "FM43",
      "iata": "",
      "name": "Antsiranana Andrakaka Airport",
      "city": "",
      "state": "Diana",
      "country": "MG",
      "elevation": 200,
      "lat": -12.2560997009,
      "lon": 49.2541999817,
      "tz": "Indian\/Antananarivo"
  },
  "FMCH": {
      "icao": "FMCH",
      "iata": "HAH",
      "name": "Prince Said Ibrahim International Airport",
      "city": "Moroni",
      "state": "Grande-Comore",
      "country": "KM",
      "elevation": 93,
      "lat": -11.5336999893,
      "lon": 43.271900177,
      "tz": "Indian\/Comoro"
  },
  "FMCI": {
      "icao": "FMCI",
      "iata": "NWA",
      "name": "Moheli Bandar Es Eslam Airport",
      "city": "",
      "state": "Moheli",
      "country": "KM",
      "elevation": 46,
      "lat": -12.2981004715,
      "lon": 43.7663993835,
      "tz": "Indian\/Comoro"
  },
  "FMCN": {
      "icao": "FMCN",
      "iata": "YVA",
      "name": "Iconi Airport",
      "city": "Moroni",
      "state": "Grande-Comore",
      "country": "KM",
      "elevation": 33,
      "lat": -11.7108001709,
      "lon": 43.2439002991,
      "tz": "Indian\/Comoro"
  },
  "FMCV": {
      "icao": "FMCV",
      "iata": "AJN",
      "name": "Ouani Airport",
      "city": "Ouani",
      "state": "Anjouan",
      "country": "KM",
      "elevation": 62,
      "lat": -12.1316995621,
      "lon": 44.4303016663,
      "tz": "Indian\/Comoro"
  },
  "FMCZ": {
      "icao": "FMCZ",
      "iata": "DZA",
      "name": "Dzaoudzi Pamandzi International Airport",
      "city": "Dzaoudzi",
      "state": "Pamandzi",
      "country": "YT",
      "elevation": 23,
      "lat": -12.8046998978,
      "lon": 45.2811012268,
      "tz": "Indian\/Mayotte"
  },
  "FMEE": {
      "icao": "FMEE",
      "iata": "RUN",
      "name": "Roland Garros Airport",
      "city": "St Denis",
      "state": "Reunion",
      "country": "RE",
      "elevation": 66,
      "lat": -20.8871002197,
      "lon": 55.5102996826,
      "tz": "Indian\/Reunion"
  },
  "FMEP": {
      "icao": "FMEP",
      "iata": "ZSE",
      "name": "Pierrefonds Airport",
      "city": "St Pierre",
      "state": "Reunion",
      "country": "RE",
      "elevation": 59,
      "lat": -21.3208999634,
      "lon": 55.4249992371,
      "tz": "Indian\/Reunion"
  },
  "FMFE": {
      "icao": "FMFE",
      "iata": "OHB",
      "name": "Moramanga Aerodrome",
      "city": "Moramanga",
      "state": "",
      "country": "MG",
      "elevation": 2960,
      "lat": -18.9175,
      "lon": 48.2181944444,
      "tz": "Indian\/Antananarivo"
  },
  "FMFZ": {
      "icao": "FMFZ",
      "iata": "",
      "name": "Betainomby Airport",
      "city": "Betainomby",
      "state": "",
      "country": "MG",
      "elevation": 40,
      "lat": -18.1958013864,
      "lon": 49.348526001,
      "tz": "Indian\/Antananarivo"
  },
  "FMMA": {
      "icao": "FMMA",
      "iata": "",
      "name": "Antananarivo Arivonimamo Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 4757,
      "lat": -19.0289993286,
      "lon": 47.1717987061,
      "tz": "Indian\/Antananarivo"
  },
  "FMMC": {
      "icao": "FMMC",
      "iata": "WML",
      "name": "Malaimbandy Airport",
      "city": "Malaimbandy",
      "state": "",
      "country": "MG",
      "elevation": 597,
      "lat": -20.3553902921,
      "lon": 45.5433726311,
      "tz": "Indian\/Antananarivo"
  },
  "FMME": {
      "icao": "FMME",
      "iata": "ATJ",
      "name": "Antsirabe Airport",
      "city": "Antsirabe",
      "state": "",
      "country": "MG",
      "elevation": 4997,
      "lat": -19.8392214824,
      "lon": 47.0637130737,
      "tz": "Indian\/Antananarivo"
  },
  "FMMG": {
      "icao": "FMMG",
      "iata": "WAQ",
      "name": "Antsalova Airport",
      "city": "Antsalova",
      "state": "",
      "country": "MG",
      "elevation": 551,
      "lat": -18.7012732424,
      "lon": 44.6149206161,
      "tz": "Indian\/Antananarivo"
  },
  "FMMH": {
      "icao": "FMMH",
      "iata": "VVB",
      "name": "Mahanoro Airport",
      "city": "Mahanoro",
      "state": "",
      "country": "MG",
      "elevation": 16,
      "lat": -19.8330001831,
      "lon": 48.7999992371,
      "tz": "Indian\/Antananarivo"
  },
  "FMMI": {
      "icao": "FMMI",
      "iata": "TNR",
      "name": "Ivato Airport",
      "city": "Antananarivo",
      "state": "",
      "country": "MG",
      "elevation": 4198,
      "lat": -18.7968997955,
      "lon": 47.4788017273,
      "tz": "Indian\/Antananarivo"
  },
  "FMMJ": {
      "icao": "FMMJ",
      "iata": "",
      "name": "Ambohijanahary Airport",
      "city": "Ambohijanahary",
      "state": "",
      "country": "MG",
      "elevation": 2496,
      "lat": -17.4644789611,
      "lon": 48.3267116547,
      "tz": "Indian\/Antananarivo"
  },
  "FMMK": {
      "icao": "FMMK",
      "iata": "JVA",
      "name": "Ankavandra Airport",
      "city": "Ankavandra",
      "state": "",
      "country": "MG",
      "elevation": 427,
      "lat": -18.8050095209,
      "lon": 45.2734673023,
      "tz": "Indian\/Antananarivo"
  },
  "FMML": {
      "icao": "FMML",
      "iata": "BMD",
      "name": "Belo sur Tsiribihina Airport",
      "city": "Belo sur Tsiribihina",
      "state": "Menabe",
      "country": "MG",
      "elevation": 154,
      "lat": -19.6867008209,
      "lon": 44.5419006348,
      "tz": "Indian\/Antananarivo"
  },
  "FMMN": {
      "icao": "FMMN",
      "iata": "ZVA",
      "name": "Miandrivazo Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 203,
      "lat": -19.5627994537,
      "lon": 45.4508018494,
      "tz": "Indian\/Antananarivo"
  },
  "FMMO": {
      "icao": "FMMO",
      "iata": "MXT",
      "name": "Maintirano Airport",
      "city": "Maintirano",
      "state": "Melaky",
      "country": "MG",
      "elevation": 95,
      "lat": -18.0499992371,
      "lon": 44.033000946,
      "tz": "Indian\/Antananarivo"
  },
  "FMMP": {
      "icao": "FMMP",
      "iata": "",
      "name": "Amparafaravola Airport",
      "city": "Amparafaravola",
      "state": "",
      "country": "MG",
      "elevation": 2576,
      "lat": -17.6545730219,
      "lon": 48.2144451141,
      "tz": "Indian\/Antananarivo"
  },
  "FMMQ": {
      "icao": "FMMQ",
      "iata": "ILK",
      "name": "Atsinanana Airport",
      "city": "Ilaka",
      "state": "",
      "country": "MG",
      "elevation": 33,
      "lat": -19.5830001831,
      "lon": 48.8030014038,
      "tz": "Indian\/Antananarivo"
  },
  "FMMR": {
      "icao": "FMMR",
      "iata": "TVA",
      "name": "Morafenobe Airport",
      "city": "Morafenobe",
      "state": "",
      "country": "MG",
      "elevation": 748,
      "lat": -17.850083459,
      "lon": 44.9204671383,
      "tz": "Indian\/Antananarivo"
  },
  "FMMS": {
      "icao": "FMMS",
      "iata": "SMS",
      "name": "Sainte Marie Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 7,
      "lat": -17.0939006805,
      "lon": 49.8157997131,
      "tz": "Indian\/Antananarivo"
  },
  "FMMT": {
      "icao": "FMMT",
      "iata": "TMM",
      "name": "Toamasina Airport",
      "city": "",
      "state": "Atsinanana",
      "country": "MG",
      "elevation": 22,
      "lat": -18.109500885,
      "lon": 49.3925018311,
      "tz": "Indian\/Antananarivo"
  },
  "FMMU": {
      "icao": "FMMU",
      "iata": "WTA",
      "name": "Tambohorano Airport",
      "city": "Tambohorano",
      "state": "",
      "country": "MG",
      "elevation": 23,
      "lat": -17.4761009216,
      "lon": 43.9728012085,
      "tz": "Indian\/Antananarivo"
  },
  "FMMV": {
      "icao": "FMMV",
      "iata": "MOQ",
      "name": "Morondava Airport",
      "city": "",
      "state": "Menabe",
      "country": "MG",
      "elevation": 30,
      "lat": -20.2847003937,
      "lon": 44.3176002502,
      "tz": "Indian\/Antananarivo"
  },
  "FMMX": {
      "icao": "FMMX",
      "iata": "WTS",
      "name": "Tsiroanomandidy Airport",
      "city": "Tsiroanomandidy",
      "state": "",
      "country": "MG",
      "elevation": 2776,
      "lat": -18.7596765564,
      "lon": 46.0540652275,
      "tz": "Indian\/Antananarivo"
  },
  "FMMY": {
      "icao": "FMMY",
      "iata": "VAT",
      "name": "Vatomandry Airport",
      "city": "Vatomandry",
      "state": "",
      "country": "MG",
      "elevation": 39,
      "lat": -19.383333,
      "lon": 48.95,
      "tz": "Indian\/Antananarivo"
  },
  "FMMZ": {
      "icao": "FMMZ",
      "iata": "WAM",
      "name": "Ambatondrazaka Airport",
      "city": "Ambatondrazaka",
      "state": "",
      "country": "MG",
      "elevation": 2513,
      "lat": -17.7953776085,
      "lon": 48.4425830841,
      "tz": "Indian\/Antananarivo"
  },
  "FMNA": {
      "icao": "FMNA",
      "iata": "DIE",
      "name": "Arrachart Airport",
      "city": "",
      "state": "Diana",
      "country": "MG",
      "elevation": 374,
      "lat": -12.3493995667,
      "lon": 49.2916984558,
      "tz": "Indian\/Antananarivo"
  },
  "FMNC": {
      "icao": "FMNC",
      "iata": "WMR",
      "name": "Mananara Nord Airport",
      "city": "Mananara Nord",
      "state": "Analanjirofo",
      "country": "MG",
      "elevation": 9,
      "lat": -16.1639003754,
      "lon": 49.7737998962,
      "tz": "Indian\/Antananarivo"
  },
  "FMND": {
      "icao": "FMND",
      "iata": "ZWA",
      "name": "Andapa Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 1552,
      "lat": -14.6517000198,
      "lon": 49.6206016541,
      "tz": "Indian\/Antananarivo"
  },
  "FMNE": {
      "icao": "FMNE",
      "iata": "AMB",
      "name": "Ambilobe Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 72,
      "lat": -13.1884002686,
      "lon": 48.9879989624,
      "tz": "Indian\/Antananarivo"
  },
  "FMNF": {
      "icao": "FMNF",
      "iata": "WBD",
      "name": "Avaratra Airport",
      "city": "Befandriana",
      "state": "",
      "country": "MG",
      "elevation": 820,
      "lat": -15.1999998093,
      "lon": 48.483001709,
      "tz": "Indian\/Antananarivo"
  },
  "FMNG": {
      "icao": "FMNG",
      "iata": "WPB",
      "name": "Port Berge Airport",
      "city": "Port Berge",
      "state": "",
      "country": "MG",
      "elevation": 213,
      "lat": -15.5842864741,
      "lon": 47.6235866547,
      "tz": "Indian\/Antananarivo"
  },
  "FMNH": {
      "icao": "FMNH",
      "iata": "ANM",
      "name": "Antsirabato Airport",
      "city": "",
      "state": "Sava",
      "country": "MG",
      "elevation": 20,
      "lat": -14.9994001389,
      "lon": 50.3202018738,
      "tz": "Indian\/Antananarivo"
  },
  "FMNJ": {
      "icao": "FMNJ",
      "iata": "IVA",
      "name": "Ambanja Airport",
      "city": "Ambanja",
      "state": "",
      "country": "MG",
      "elevation": 36,
      "lat": -13.6457053132,
      "lon": 48.4594273567,
      "tz": "Indian\/Antananarivo"
  },
  "FMNL": {
      "icao": "FMNL",
      "iata": "HVA",
      "name": "Analalava Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 345,
      "lat": -14.629699707,
      "lon": 47.7638015747,
      "tz": "Indian\/Antananarivo"
  },
  "FMNM": {
      "icao": "FMNM",
      "iata": "MJN",
      "name": "Amborovy Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 87,
      "lat": -15.6668417421,
      "lon": 46.3512325287,
      "tz": "Indian\/Antananarivo"
  },
  "FMNN": {
      "icao": "FMNN",
      "iata": "NOS",
      "name": "Fascene Airport",
      "city": "Nosy Be",
      "state": "Diana",
      "country": "MG",
      "elevation": 36,
      "lat": -13.3121004105,
      "lon": 48.3148002625,
      "tz": "Indian\/Antananarivo"
  },
  "FMNO": {
      "icao": "FMNO",
      "iata": "DWB",
      "name": "Soalala Airport",
      "city": "Soalala",
      "state": "",
      "country": "MG",
      "elevation": 141,
      "lat": -16.1016904207,
      "lon": 45.3588366508,
      "tz": "Indian\/Antananarivo"
  },
  "FMNP": {
      "icao": "FMNP",
      "iata": "WMP",
      "name": "Mampikony Airport",
      "city": "Mampikony",
      "state": "",
      "country": "MG",
      "elevation": 0,
      "lat": -16.0722693402,
      "lon": 47.6441645622,
      "tz": "Indian\/Antananarivo"
  },
  "FMNQ": {
      "icao": "FMNQ",
      "iata": "BPY",
      "name": "Besalampy Airport",
      "city": "",
      "state": "Melaky",
      "country": "MG",
      "elevation": 125,
      "lat": -16.7445302965,
      "lon": 44.4824838638,
      "tz": "Indian\/Antananarivo"
  },
  "FMNR": {
      "icao": "FMNR",
      "iata": "WMN",
      "name": "Maroantsetra Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 13,
      "lat": -15.4366998672,
      "lon": 49.6883010864,
      "tz": "Indian\/Antananarivo"
  },
  "FMNS": {
      "icao": "FMNS",
      "iata": "SVB",
      "name": "Sambava Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 20,
      "lat": -14.2785997391,
      "lon": 50.1747016907,
      "tz": "Indian\/Antananarivo"
  },
  "FMNT": {
      "icao": "FMNT",
      "iata": "TTS",
      "name": "Tsaratanana Airport",
      "city": "Tsaratanana",
      "state": "",
      "country": "MG",
      "elevation": 1073,
      "lat": -16.7510643983,
      "lon": 47.6190161705,
      "tz": "Indian\/Antananarivo"
  },
  "FMNV": {
      "icao": "FMNV",
      "iata": "VOH",
      "name": "Vohimarina Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 19,
      "lat": -13.3758001328,
      "lon": 50.0027999878,
      "tz": "Indian\/Antananarivo"
  },
  "FMNW": {
      "icao": "FMNW",
      "iata": "WAI",
      "name": "Ambalabe Airport",
      "city": "Antsohihy",
      "state": "",
      "country": "MG",
      "elevation": 92,
      "lat": -14.8987998962,
      "lon": 47.9939002991,
      "tz": "Indian\/Antananarivo"
  },
  "FMNX": {
      "icao": "FMNX",
      "iata": "WMA",
      "name": "Mandritsara Airport",
      "city": "Mandritsara",
      "state": "",
      "country": "MG",
      "elevation": 1007,
      "lat": -15.8330494086,
      "lon": 48.8332843781,
      "tz": "Indian\/Antananarivo"
  },
  "FMNZ": {
      "icao": "FMNZ",
      "iata": "",
      "name": "Ampampamena Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 49,
      "lat": -13.4848003387,
      "lon": 48.6327018738,
      "tz": "Indian\/Antananarivo"
  },
  "FMSA": {
      "icao": "FMSA",
      "iata": "",
      "name": "Ambalavao Airport",
      "city": "Ambalavao",
      "state": "",
      "country": "MG",
      "elevation": 3189,
      "lat": -21.8148910188,
      "lon": 46.914024353,
      "tz": "Indian\/Antananarivo"
  },
  "FMSB": {
      "icao": "FMSB",
      "iata": "WBO",
      "name": "Antsoa Airport",
      "city": "Beroroha",
      "state": "",
      "country": "MG",
      "elevation": 820,
      "lat": -21.6069837647,
      "lon": 45.1360201836,
      "tz": "Indian\/Antananarivo"
  },
  "FMSC": {
      "icao": "FMSC",
      "iata": "WMD",
      "name": "Mandabe Airport",
      "city": "Mandabe",
      "state": "",
      "country": "MG",
      "elevation": 951,
      "lat": -21.0463049303,
      "lon": 44.9404120445,
      "tz": "Indian\/Antananarivo"
  },
  "FMSD": {
      "icao": "FMSD",
      "iata": "FTU",
      "name": "Tolanaro Airport",
      "city": "Tolanaro",
      "state": "",
      "country": "MG",
      "elevation": 29,
      "lat": -25.0380992889,
      "lon": 46.9561004639,
      "tz": "Indian\/Antananarivo"
  },
  "FMSE": {
      "icao": "FMSE",
      "iata": "",
      "name": "Betroka Airport",
      "city": "Betroka",
      "state": "",
      "country": "MG",
      "elevation": 2841,
      "lat": -23.2743078483,
      "lon": 46.1249828339,
      "tz": "Indian\/Antananarivo"
  },
  "FMSF": {
      "icao": "FMSF",
      "iata": "WFI",
      "name": "Fianarantsoa Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 3658,
      "lat": -21.4416007996,
      "lon": 47.1116981506,
      "tz": "Indian\/Antananarivo"
  },
  "FMSG": {
      "icao": "FMSG",
      "iata": "RVA",
      "name": "Farafangana Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 26,
      "lat": -22.8052997589,
      "lon": 47.8205986023,
      "tz": "Indian\/Antananarivo"
  },
  "FMSI": {
      "icao": "FMSI",
      "iata": "IHO",
      "name": "Ihosy Airport",
      "city": "Ihosy",
      "state": "Ihorombe",
      "country": "MG",
      "elevation": 2500,
      "lat": -22.4047202024,
      "lon": 46.1649370193,
      "tz": "Indian\/Antananarivo"
  },
  "FMSJ": {
      "icao": "FMSJ",
      "iata": "MJA",
      "name": "Manja Airport",
      "city": "Manja",
      "state": "",
      "country": "MG",
      "elevation": 787,
      "lat": -21.4261052506,
      "lon": 44.31650877,
      "tz": "Indian\/Antananarivo"
  },
  "FMSK": {
      "icao": "FMSK",
      "iata": "WVK",
      "name": "Manakara Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 33,
      "lat": -22.1196994781,
      "lon": 48.0217018127,
      "tz": "Indian\/Antananarivo"
  },
  "FMSL": {
      "icao": "FMSL",
      "iata": "OVA",
      "name": "Bekily Airport",
      "city": "Bekily",
      "state": "",
      "country": "MG",
      "elevation": 1270,
      "lat": -24.2356947547,
      "lon": 45.3045272827,
      "tz": "Indian\/Antananarivo"
  },
  "FMSM": {
      "icao": "FMSM",
      "iata": "MNJ",
      "name": "Mananjary Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 20,
      "lat": -21.2017993927,
      "lon": 48.3582992554,
      "tz": "Indian\/Antananarivo"
  },
  "FMSN": {
      "icao": "FMSN",
      "iata": "TDV",
      "name": "Samangoky Airport",
      "city": "Tanandava",
      "state": "",
      "country": "MG",
      "elevation": 89,
      "lat": -21.7000007629,
      "lon": 43.733001709,
      "tz": "Indian\/Antananarivo"
  },
  "FMSR": {
      "icao": "FMSR",
      "iata": "MXM",
      "name": "Morombe Airport",
      "city": "",
      "state": "Atsimo-Andrefana",
      "country": "MG",
      "elevation": 16,
      "lat": -21.753900528,
      "lon": 43.3754997253,
      "tz": "Indian\/Antananarivo"
  },
  "FMST": {
      "icao": "FMST",
      "iata": "TLE",
      "name": "Toliara Airport",
      "city": "",
      "state": "",
      "country": "MG",
      "elevation": 29,
      "lat": -23.3833999634,
      "lon": 43.7285003662,
      "tz": "Indian\/Antananarivo"
  },
  "FMSU": {
      "icao": "FMSU",
      "iata": "VND",
      "name": "Vangaindrano Airport",
      "city": "Vangaindrano",
      "state": "Atsimo-Atsinanana",
      "country": "MG",
      "elevation": 45,
      "lat": -23.3507665915,
      "lon": 47.5817012787,
      "tz": "Indian\/Antananarivo"
  },
  "FMSV": {
      "icao": "FMSV",
      "iata": "BKU",
      "name": "Betioky Airport",
      "city": "Betioky",
      "state": "Atsimo-Andrefana",
      "country": "MG",
      "elevation": 919,
      "lat": -23.7329998016,
      "lon": 44.388999939,
      "tz": "Indian\/Antananarivo"
  },
  "FMSY": {
      "icao": "FMSY",
      "iata": "AMP",
      "name": "Ampanihy Airport",
      "city": "Ampanihy",
      "state": "Atsimo-Andrefana",
      "country": "MG",
      "elevation": 771,
      "lat": -24.6996994019,
      "lon": 44.7341995239,
      "tz": "Indian\/Antananarivo"
  },
  "FMSZ": {
      "icao": "FMSZ",
      "iata": "WAK",
      "name": "Ankazoabo Airport",
      "city": "Ankazoabo",
      "state": "",
      "country": "MG",
      "elevation": 1411,
      "lat": -22.2964423522,
      "lon": 44.5315361023,
      "tz": "Indian\/Antananarivo"
  },
  "FN17": {
      "icao": "FN17",
      "iata": "",
      "name": "Cahama Airport",
      "city": "Cahama",
      "state": "Cunene",
      "country": "AO",
      "elevation": 3970,
      "lat": -16.2383995056,
      "lon": 14.3260002136,
      "tz": "Africa\/Luanda"
  },
  "FN18": {
      "icao": "FN18",
      "iata": "",
      "name": "Matala Airport",
      "city": "Matala",
      "state": "Huila",
      "country": "AO",
      "elevation": 4120,
      "lat": -14.7274999619,
      "lon": 15.013999939,
      "tz": "Africa\/Luanda"
  },
  "FN19": {
      "icao": "FN19",
      "iata": "",
      "name": "Cabo Ledo Airport",
      "city": "Cabo Ledo",
      "state": "Bengo",
      "country": "AO",
      "elevation": 360,
      "lat": -9.6530504227,
      "lon": 13.26060009,
      "tz": "Africa\/Luanda"
  },
  "FNAM": {
      "icao": "FNAM",
      "iata": "AZZ",
      "name": "Ambriz Airport",
      "city": "Ambriz",
      "state": "Bengo",
      "country": "AO",
      "elevation": 144,
      "lat": -7.8622198105,
      "lon": 13.1161003113,
      "tz": "Africa\/Luanda"
  },
  "FNBC": {
      "icao": "FNBC",
      "iata": "SSY",
      "name": "Mbanza Congo Airport",
      "city": "Mbanza Congo",
      "state": "Zaire",
      "country": "AO",
      "elevation": 1860,
      "lat": -6.2698998451,
      "lon": 14.2469997406,
      "tz": "Africa\/Luanda"
  },
  "FNBG": {
      "icao": "FNBG",
      "iata": "BUG",
      "name": "Benguela Airport",
      "city": "Benguela",
      "state": "Benguela",
      "country": "AO",
      "elevation": 118,
      "lat": -12.609000206,
      "lon": 13.4036998749,
      "tz": "Africa\/Luanda"
  },
  "FNCA": {
      "icao": "FNCA",
      "iata": "CAB",
      "name": "Cabinda Airport",
      "city": "Cabinda",
      "state": "Cabinda",
      "country": "AO",
      "elevation": 66,
      "lat": -5.5969901085,
      "lon": 12.1884002686,
      "tz": "Africa\/Luanda"
  },
  "FNCB": {
      "icao": "FNCB",
      "iata": "",
      "name": "Camembe Airport",
      "city": "Camembe",
      "state": "Bengo",
      "country": "AO",
      "elevation": 2264,
      "lat": -8.1330003738,
      "lon": 14.5,
      "tz": "Africa\/Luanda"
  },
  "FNCC": {
      "icao": "FNCC",
      "iata": "",
      "name": "Cacolo Airport",
      "city": "Cacolo",
      "state": "Lunda-Sul",
      "country": "AO",
      "elevation": 3937,
      "lat": -10.2829999924,
      "lon": 19.2670001984,
      "tz": "Africa\/Luanda"
  },
  "FNCF": {
      "icao": "FNCF",
      "iata": "CFF",
      "name": "Cafunfo Airport",
      "city": "Cafunfo",
      "state": "Luanda-Norte",
      "country": "AO",
      "elevation": 0,
      "lat": -8.7836103439,
      "lon": 17.9897003174,
      "tz": "Africa\/Luanda"
  },
  "FNCH": {
      "icao": "FNCH",
      "iata": "PGI",
      "name": "Chitato Airport",
      "city": "Chitato",
      "state": "Luanda-Norte",
      "country": "AO",
      "elevation": 2500,
      "lat": -7.3588900566,
      "lon": 20.8047008514,
      "tz": "Africa\/Luanda"
  },
  "FNCM": {
      "icao": "FNCM",
      "iata": "",
      "name": "Camabatela Airport",
      "city": "Camabatela",
      "state": "Cuanza-Norte",
      "country": "AO",
      "elevation": 410,
      "lat": -8.2170000076,
      "lon": 15.3669996262,
      "tz": "Africa\/Luanda"
  },
  "FNCT": {
      "icao": "FNCT",
      "iata": "CBT",
      "name": "Catumbela Airport",
      "city": "Catumbela",
      "state": "Benguela",
      "country": "AO",
      "elevation": 0,
      "lat": -12.4792003632,
      "lon": 13.4869003296,
      "tz": "Africa\/Luanda"
  },
  "FNCV": {
      "icao": "FNCV",
      "iata": "CTI",
      "name": "Cuito Cuanavale Airport",
      "city": "Cuito Cuanavale",
      "state": "Cuando-Cobango",
      "country": "AO",
      "elevation": 0,
      "lat": -15.1603002548,
      "lon": 19.1560993195,
      "tz": "Africa\/Luanda"
  },
  "FNCX": {
      "icao": "FNCX",
      "iata": "CXM",
      "name": "Camaxilo Airport",
      "city": "Camaxilo",
      "state": "Luanda-Norte",
      "country": "AO",
      "elevation": 3957,
      "lat": -8.3736095428,
      "lon": 18.9235992432,
      "tz": "Africa\/Luanda"
  },
  "FNCZ": {
      "icao": "FNCZ",
      "iata": "CAV",
      "name": "Cazombo Airport",
      "city": "Cazombo",
      "state": "Moxico",
      "country": "AO",
      "elevation": 3700,
      "lat": -11.8930997849,
      "lon": 22.9164009094,
      "tz": "Africa\/Luanda"
  },
  "FNDB": {
      "icao": "FNDB",
      "iata": "",
      "name": "Damba Airport",
      "city": "Damba",
      "state": "Uige",
      "country": "AO",
      "elevation": 3648,
      "lat": -6.9000000954,
      "lon": 15.1330003738,
      "tz": "Africa\/Luanda"
  },
  "FNDU": {
      "icao": "FNDU",
      "iata": "DUE",
      "name": "Dundo Airport",
      "city": "Chitato",
      "state": "Luanda-Norte",
      "country": "AO",
      "elevation": 2451,
      "lat": -7.4008898735,
      "lon": 20.8185005188,
      "tz": "Africa\/Luanda"
  },
  "FNGI": {
      "icao": "FNGI",
      "iata": "VPE",
      "name": "Ngjiva Pereira Airport",
      "city": "Ngiva",
      "state": "Cunene",
      "country": "AO",
      "elevation": 3566,
      "lat": -17.0435009003,
      "lon": 15.6837997437,
      "tz": "Africa\/Luanda"
  },
  "FNHU": {
      "icao": "FNHU",
      "iata": "NOV",
      "name": "Nova Lisboa Airport",
      "city": "Huambo",
      "state": "Huambo",
      "country": "AO",
      "elevation": 5587,
      "lat": -12.8088998795,
      "lon": 15.7604999542,
      "tz": "Africa\/Luanda"
  },
  "FNKU": {
      "icao": "FNKU",
      "iata": "SVP",
      "name": "Kuito Airport",
      "city": "Kuito",
      "state": "Bie",
      "country": "AO",
      "elevation": 5618,
      "lat": -12.4046001434,
      "lon": 16.9473991394,
      "tz": "Africa\/Luanda"
  },
  "FNLB": {
      "icao": "FNLB",
      "iata": "LLT",
      "name": "Lobito Airport",
      "city": "Lobito",
      "state": "Benguela",
      "country": "AO",
      "elevation": 10,
      "lat": -12.3711996078,
      "lon": 13.5366001129,
      "tz": "Africa\/Luanda"
  },
  "FNLK": {
      "icao": "FNLK",
      "iata": "LBZ",
      "name": "Lucapa Airport",
      "city": "Lucapa",
      "state": "Luanda-Norte",
      "country": "AO",
      "elevation": 3029,
      "lat": -8.4457273483,
      "lon": 20.7320861816,
      "tz": "Africa\/Luanda"
  },
  "FNLU": {
      "icao": "FNLU",
      "iata": "LAD",
      "name": "Quatro De Fevereiro Airport",
      "city": "Luanda",
      "state": "Luanda",
      "country": "AO",
      "elevation": 243,
      "lat": -8.8583698273,
      "lon": 13.2312002182,
      "tz": "Africa\/Luanda"
  },
  "FNLZ": {
      "icao": "FNLZ",
      "iata": "LZM",
      "name": "Luzamba Airport",
      "city": "Luzamba",
      "state": "Luanda-Norte",
      "country": "AO",
      "elevation": 2904,
      "lat": -9.1159601212,
      "lon": 18.0492992401,
      "tz": "Africa\/Luanda"
  },
  "FNMA": {
      "icao": "FNMA",
      "iata": "MEG",
      "name": "Malanje Airport",
      "city": "Malanje",
      "state": "Malanje",
      "country": "AO",
      "elevation": 3868,
      "lat": -9.5250902176,
      "lon": 16.3124008179,
      "tz": "Africa\/Luanda"
  },
  "FNME": {
      "icao": "FNME",
      "iata": "SPP",
      "name": "Menongue Airport",
      "city": "Menongue",
      "state": "Cuando-Cobango",
      "country": "AO",
      "elevation": 4469,
      "lat": -14.6576004028,
      "lon": 17.7198009491,
      "tz": "Africa\/Luanda"
  },
  "FNMO": {
      "icao": "FNMO",
      "iata": "MSZ",
      "name": "Namibe Airport",
      "city": "Namibe",
      "state": "Namibe",
      "country": "AO",
      "elevation": 210,
      "lat": -15.2611999512,
      "lon": 12.1468000412,
      "tz": "Africa\/Luanda"
  },
  "FNMQ": {
      "icao": "FNMQ",
      "iata": "",
      "name": "Maquela do Zombo Airport",
      "city": "Maquela do Zombo",
      "state": "Uige",
      "country": "AO",
      "elevation": 3051,
      "lat": -6.0302801132,
      "lon": 15.1368999481,
      "tz": "Africa\/Luanda"
  },
  "FNNG": {
      "icao": "FNNG",
      "iata": "GXG",
      "name": "Negage Airport",
      "city": "Negage",
      "state": "Uige",
      "country": "AO",
      "elevation": 4105,
      "lat": -7.7545099258,
      "lon": 15.2876996994,
      "tz": "Africa\/Luanda"
  },
  "FNPA": {
      "icao": "FNPA",
      "iata": "PBN",
      "name": "Porto Amboim Airport",
      "city": "Port Amboim",
      "state": "Kwanza-Sul",
      "country": "AO",
      "elevation": 16,
      "lat": -10.7220001221,
      "lon": 13.7655000687,
      "tz": "Africa\/Luanda"
  },
  "FNPB": {
      "icao": "FNPB",
      "iata": "",
      "name": "Sanza Pombo Airport",
      "city": "Sanza Pombo",
      "state": "Uige",
      "country": "AO",
      "elevation": 3251,
      "lat": -7.3000001907,
      "lon": 15.9329996109,
      "tz": "Africa\/Luanda"
  },
  "FNSA": {
      "icao": "FNSA",
      "iata": "VHC",
      "name": "Saurimo Airport",
      "city": "Saurimo",
      "state": "Lunda-Sul",
      "country": "AO",
      "elevation": 3584,
      "lat": -9.6890697479,
      "lon": 20.4319000244,
      "tz": "Africa\/Luanda"
  },
  "FNSO": {
      "icao": "FNSO",
      "iata": "SZA",
      "name": "Soyo Airport",
      "city": "Soyo",
      "state": "Zaire",
      "country": "AO",
      "elevation": 15,
      "lat": -6.1410899162,
      "lon": 12.3718004227,
      "tz": "Africa\/Luanda"
  },
  "FNSU": {
      "icao": "FNSU",
      "iata": "NDD",
      "name": "Sumbe Airport",
      "city": "Sumbe",
      "state": "Kwanza-Sul",
      "country": "AO",
      "elevation": 36,
      "lat": -11.1679000854,
      "lon": 13.8474998474,
      "tz": "Africa\/Luanda"
  },
  "FNTO": {
      "icao": "FNTO",
      "iata": "",
      "name": "Toto Airport",
      "city": "Toto",
      "state": "Uige",
      "country": "AO",
      "elevation": 1775,
      "lat": -7.1463899612,
      "lon": 14.2486000061,
      "tz": "Africa\/Luanda"
  },
  "FNUA": {
      "icao": "FNUA",
      "iata": "UAL",
      "name": "Luau Airport",
      "city": "Luau",
      "state": "Moxico",
      "country": "AO",
      "elevation": 3609,
      "lat": -10.7158002853,
      "lon": 22.2311000824,
      "tz": "Africa\/Luanda"
  },
  "FNUB": {
      "icao": "FNUB",
      "iata": "SDD",
      "name": "Lubango Airport",
      "city": "Lubango",
      "state": "Huila",
      "country": "AO",
      "elevation": 5778,
      "lat": -14.9246997833,
      "lon": 13.5749998093,
      "tz": "Africa\/Luanda"
  },
  "FNUE": {
      "icao": "FNUE",
      "iata": "LUO",
      "name": "Luena Airport",
      "city": "Luena",
      "state": "Moxico",
      "country": "AO",
      "elevation": 4360,
      "lat": -11.7680997849,
      "lon": 19.8976993561,
      "tz": "Africa\/Luanda"
  },
  "FNUG": {
      "icao": "FNUG",
      "iata": "UGO",
      "name": "Uige Airport",
      "city": "Uige",
      "state": "Uige",
      "country": "AO",
      "elevation": 2720,
      "lat": -7.6030697823,
      "lon": 15.0277996063,
      "tz": "Africa\/Luanda"
  },
  "FNWK": {
      "icao": "FNWK",
      "iata": "CEO",
      "name": "Waco Kungo Airport",
      "city": "Waco Kungo",
      "state": "Benguela",
      "country": "AO",
      "elevation": 4324,
      "lat": -11.4264001846,
      "lon": 15.1014003754,
      "tz": "Africa\/Luanda"
  },
  "FNXA": {
      "icao": "FNXA",
      "iata": "XGN",
      "name": "Xangongo Airport",
      "city": "Xangongo",
      "state": "Cunene",
      "country": "AO",
      "elevation": 3635,
      "lat": -16.755399704,
      "lon": 14.9652996063,
      "tz": "Africa\/Luanda"
  },
  "FNZE": {
      "icao": "FNZE",
      "iata": "ARZ",
      "name": "N'zeto Airport",
      "city": "N'zeto",
      "state": "Zaire",
      "country": "AO",
      "elevation": 69,
      "lat": -7.2594399452,
      "lon": 12.8631000519,
      "tz": "Africa\/Luanda"
  },
  "FNZG": {
      "icao": "FNZG",
      "iata": "NZA",
      "name": "Nzagi Airport",
      "city": "Nzagi",
      "state": "Luanda-Norte",
      "country": "AO",
      "elevation": 2431,
      "lat": -7.7169399262,
      "lon": 21.3582000732,
      "tz": "Africa\/Luanda"
  },
  "FOGB": {
      "icao": "FOGB",
      "iata": "BGB",
      "name": "Booue Airport",
      "city": "Booue",
      "state": "Ogooue-Ivindo",
      "country": "GA",
      "elevation": 604,
      "lat": -0.1000000015,
      "lon": 11.9499998093,
      "tz": "Africa\/Libreville"
  },
  "FOGE": {
      "icao": "FOGE",
      "iata": "KDN",
      "name": "Ndende Airport",
      "city": "Ndende",
      "state": "Ngouni",
      "country": "GA",
      "elevation": 417,
      "lat": -2.4000000954,
      "lon": 11.3669996262,
      "tz": "Africa\/Libreville"
  },
  "FOGF": {
      "icao": "FOGF",
      "iata": "FOU",
      "name": "Fougamou Airport",
      "city": "Fougamou",
      "state": "Ngouni",
      "country": "GA",
      "elevation": 263,
      "lat": -1.2829999924,
      "lon": 10.6169996262,
      "tz": "Africa\/Libreville"
  },
  "FOGG": {
      "icao": "FOGG",
      "iata": "MBC",
      "name": "M'Bigou Airport",
      "city": "M'Bigou",
      "state": "Ngouni",
      "country": "GA",
      "elevation": 2346,
      "lat": -1.8830000162,
      "lon": 11.9329996109,
      "tz": "Africa\/Libreville"
  },
  "FOGI": {
      "icao": "FOGI",
      "iata": "MGX",
      "name": "Moabi Airport",
      "city": "Moabi",
      "state": "Nyanga",
      "country": "GA",
      "elevation": 787,
      "lat": -2.4330000877,
      "lon": 11,
      "tz": "Africa\/Libreville"
  },
  "FOGJ": {
      "icao": "FOGJ",
      "iata": "KDJ",
      "name": "Ville Airport",
      "city": "N'Djole",
      "state": "Moyen-Ogooue",
      "country": "GA",
      "elevation": 164,
      "lat": -0.1829999983,
      "lon": 10.75,
      "tz": "Africa\/Libreville"
  },
  "FOGK": {
      "icao": "FOGK",
      "iata": "KOU",
      "name": "Koulamoutou Airport",
      "city": "Koulamoutou",
      "state": "Ogooue-Lolo",
      "country": "GA",
      "elevation": 1070,
      "lat": -1.1846100092,
      "lon": 12.4413003922,
      "tz": "Africa\/Libreville"
  },
  "FOGM": {
      "icao": "FOGM",
      "iata": "MJL",
      "name": "Mouilla Ville Airport",
      "city": "Mouila",
      "state": "Ngouni",
      "country": "GA",
      "elevation": 295,
      "lat": -1.8451399803,
      "lon": 11.0566997528,
      "tz": "Africa\/Libreville"
  },
  "FOGO": {
      "icao": "FOGO",
      "iata": "OYE",
      "name": "Oyem Airport",
      "city": "Oyem",
      "state": "Woleu-Ntem",
      "country": "GA",
      "elevation": 2158,
      "lat": 1.543110013,
      "lon": 11.5813999176,
      "tz": "Africa\/Libreville"
  },
  "FOGQ": {
      "icao": "FOGQ",
      "iata": "OKN",
      "name": "Okondja Airport",
      "city": "Okondja",
      "state": "Haut-Ogooue",
      "country": "GA",
      "elevation": 1325,
      "lat": -0.6652140021,
      "lon": 13.6731004715,
      "tz": "Africa\/Libreville"
  },
  "FOGR": {
      "icao": "FOGR",
      "iata": "LBQ",
      "name": "Lambarene Airport",
      "city": "Lambarene",
      "state": "Moyen-Ogooue",
      "country": "GA",
      "elevation": 82,
      "lat": -0.7043889761,
      "lon": 10.2456998825,
      "tz": "Africa\/Libreville"
  },
  "FOGV": {
      "icao": "FOGV",
      "iata": "MVX",
      "name": "Minvoul Airport",
      "city": "Minvoul",
      "state": "Woleu-Ntem",
      "country": "GA",
      "elevation": 1969,
      "lat": 2.1500000954,
      "lon": 12.1330003738,
      "tz": "Africa\/Libreville"
  },
  "FOOB": {
      "icao": "FOOB",
      "iata": "BMM",
      "name": "Bitam Airport",
      "city": "Bitam",
      "state": "Woleu-Ntem",
      "country": "GA",
      "elevation": 1969,
      "lat": 2.0756399632,
      "lon": 11.4932003021,
      "tz": "Africa\/Libreville"
  },
  "FOOC": {
      "icao": "FOOC",
      "iata": "",
      "name": "Cocobeach Airport",
      "city": "Cocobeach",
      "state": "Estuaire",
      "country": "GA",
      "elevation": 69,
      "lat": 1,
      "lon": 9.5830001831,
      "tz": "Africa\/Libreville"
  },
  "FOOD": {
      "icao": "FOOD",
      "iata": "MFF",
      "name": "Moanda Airport",
      "city": "Moanda",
      "state": "Haut-Ogooue",
      "country": "GA",
      "elevation": 1877,
      "lat": -1.5329999924,
      "lon": 13.2670001984,
      "tz": "Africa\/Libreville"
  },
  "FOOE": {
      "icao": "FOOE",
      "iata": "MKB",
      "name": "Mekambo Airport",
      "city": "Mekambo",
      "state": "Ogooue-Ivindo",
      "country": "GA",
      "elevation": 1686,
      "lat": 1.0169999599,
      "lon": 13.9329996109,
      "tz": "Africa\/Libreville"
  },
  "FOOG": {
      "icao": "FOOG",
      "iata": "POG",
      "name": "Port Gentil Airport",
      "city": "Port Gentil",
      "state": "Ogooue-Maritime",
      "country": "GA",
      "elevation": 13,
      "lat": -0.7117390037,
      "lon": 8.7543802261,
      "tz": "Africa\/Libreville"
  },
  "FOOH": {
      "icao": "FOOH",
      "iata": "OMB",
      "name": "Omboue Hopital Airport",
      "city": "Omboue",
      "state": "Ogooue-Maritime",
      "country": "GA",
      "elevation": 33,
      "lat": -1.5747300386,
      "lon": 9.2626895905,
      "tz": "Africa\/Libreville"
  },
  "FOOK": {
      "icao": "FOOK",
      "iata": "MKU",
      "name": "Makokou Airport",
      "city": "Makokou",
      "state": "Ogooue-Ivindo",
      "country": "GA",
      "elevation": 1726,
      "lat": 0.5792109966,
      "lon": 12.8908996582,
      "tz": "Africa\/Libreville"
  },
  "FOOL": {
      "icao": "FOOL",
      "iata": "LBV",
      "name": "Leon M Ba Airport",
      "city": "Libreville",
      "state": "Estuaire",
      "country": "GA",
      "elevation": 39,
      "lat": 0.4586000144,
      "lon": 9.4122800827,
      "tz": "Africa\/Libreville"
  },
  "FOOM": {
      "icao": "FOOM",
      "iata": "MZC",
      "name": "Mitzic Airport",
      "city": "Mitzic",
      "state": "Woleu-Ntem",
      "country": "GA",
      "elevation": 1913,
      "lat": 0.7829999924,
      "lon": 11.5500001907,
      "tz": "Africa\/Libreville"
  },
  "FOON": {
      "icao": "FOON",
      "iata": "MVB",
      "name": "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
      "city": "Franceville",
      "state": "Haut-Ogooue",
      "country": "GA",
      "elevation": 1450,
      "lat": -1.656159997,
      "lon": 13.4379997253,
      "tz": "Africa\/Libreville"
  },
  "FOOR": {
      "icao": "FOOR",
      "iata": "LTL",
      "name": "Lastourville Airport",
      "city": "Lastourville",
      "state": "Ogooue-Lolo",
      "country": "GA",
      "elevation": 1585,
      "lat": -0.8266670108,
      "lon": 12.7486000061,
      "tz": "Africa\/Libreville"
  },
  "FOOS": {
      "icao": "FOOS",
      "iata": "ZKM",
      "name": "Sette Cama Airport",
      "city": "Sette Cama",
      "state": "Ogooue-Maritime",
      "country": "GA",
      "elevation": 10,
      "lat": -2.5329999924,
      "lon": 9.7670001984,
      "tz": "Africa\/Libreville"
  },
  "FOOT": {
      "icao": "FOOT",
      "iata": "TCH",
      "name": "Tchibanga Airport",
      "city": "Tchibanga",
      "state": "Nyanga",
      "country": "GA",
      "elevation": 269,
      "lat": -2.8499999046,
      "lon": 11.0170001984,
      "tz": "Africa\/Libreville"
  },
  "FOOY": {
      "icao": "FOOY",
      "iata": "MYB",
      "name": "Mayumba Airport",
      "city": "Mayumba",
      "state": "Nyanga",
      "country": "GA",
      "elevation": 13,
      "lat": -3.4584197998,
      "lon": 10.6740760803,
      "tz": "Africa\/Libreville"
  },
  "FPPR": {
      "icao": "FPPR",
      "iata": "PCP",
      "name": "Principe Airport",
      "city": "",
      "state": "Principe",
      "country": "ST",
      "elevation": 591,
      "lat": 1.6629400253,
      "lon": 7.4117398262,
      "tz": "Africa\/Sao_Tome"
  },
  "FPST": {
      "icao": "FPST",
      "iata": "TMS",
      "name": "Sao Tome International Airport",
      "city": "Sao Tome",
      "state": "Sao-Tome-Island",
      "country": "ST",
      "elevation": 33,
      "lat": 0.3781749904,
      "lon": 6.7121500969,
      "tz": "Africa\/Sao_Tome"
  },
  "FQ49": {
      "icao": "FQ49",
      "iata": "",
      "name": "Vila Franca do Save Airport",
      "city": "Vila Franca do Save",
      "state": "Inhambane",
      "country": "MZ",
      "elevation": 98,
      "lat": -21.1644992828,
      "lon": 34.5606994629,
      "tz": "Africa\/Maputo"
  },
  "FQAG": {
      "icao": "FQAG",
      "iata": "ANO",
      "name": "Angoche Airport",
      "city": "Angoche",
      "state": "Nampula",
      "country": "MZ",
      "elevation": 121,
      "lat": -16.1818695068,
      "lon": 39.9452171326,
      "tz": "Africa\/Maputo"
  },
  "FQBI": {
      "icao": "FQBI",
      "iata": "",
      "name": "Bilene Airport",
      "city": "Bilene",
      "state": "Gaza",
      "country": "MZ",
      "elevation": 118,
      "lat": -25.2659854889,
      "lon": 33.2385978699,
      "tz": "Africa\/Maputo"
  },
  "FQBR": {
      "icao": "FQBR",
      "iata": "BEW",
      "name": "Beira Airport",
      "city": "Beira",
      "state": "Sofala",
      "country": "MZ",
      "elevation": 33,
      "lat": -19.7964000702,
      "lon": 34.9076004028,
      "tz": "Africa\/Maputo"
  },
  "FQCB": {
      "icao": "FQCB",
      "iata": "FXO",
      "name": "Cuamba Airport",
      "city": "Cuamba",
      "state": "Niassa",
      "country": "MZ",
      "elevation": 1919,
      "lat": -14.8149995804,
      "lon": 36.5299987793,
      "tz": "Africa\/Maputo"
  },
  "FQCH": {
      "icao": "FQCH",
      "iata": "VPY",
      "name": "Chimoio Airport",
      "city": "Chimoio",
      "state": "Manica",
      "country": "MZ",
      "elevation": 2287,
      "lat": -19.1513004303,
      "lon": 33.4290008545,
      "tz": "Africa\/Maputo"
  },
  "FQFU": {
      "icao": "FQFU",
      "iata": "",
      "name": "Furancungo Airport",
      "city": "Furancungo",
      "state": "Tete",
      "country": "MZ",
      "elevation": 0,
      "lat": -14.9186000824,
      "lon": 33.6282997131,
      "tz": "Africa\/Maputo"
  },
  "FQIA": {
      "icao": "FQIA",
      "iata": "IHC",
      "name": "Inhaca Airport",
      "city": "Inhaca",
      "state": "Maputo",
      "country": "MZ",
      "elevation": 10,
      "lat": -25.9971446991,
      "lon": 32.9293518066,
      "tz": "Africa\/Maputo"
  },
  "FQIN": {
      "icao": "FQIN",
      "iata": "INH",
      "name": "Inhambane Airport",
      "city": "Inhambabe",
      "state": "Inhambane",
      "country": "MZ",
      "elevation": 30,
      "lat": -23.8763999939,
      "lon": 35.4085006714,
      "tz": "Africa\/Maputo"
  },
  "FQLC": {
      "icao": "FQLC",
      "iata": "VXC",
      "name": "Lichinga Airport",
      "city": "Lichinga",
      "state": "Niassa",
      "country": "MZ",
      "elevation": 4505,
      "lat": -13.2740001678,
      "lon": 35.2663002014,
      "tz": "Africa\/Maputo"
  },
  "FQLU": {
      "icao": "FQLU",
      "iata": "LFB",
      "name": "Lumbo Airport",
      "city": "Lumbo",
      "state": "Nampula",
      "country": "MZ",
      "elevation": 33,
      "lat": -15.0331001282,
      "lon": 40.6716995239,
      "tz": "Africa\/Maputo"
  },
  "FQMA": {
      "icao": "FQMA",
      "iata": "MPM",
      "name": "Maputo Airport",
      "city": "Maputo",
      "state": "Maputo",
      "country": "MZ",
      "elevation": 145,
      "lat": -25.9207992554,
      "lon": 32.5726013184,
      "tz": "Africa\/Maputo"
  },
  "FQMD": {
      "icao": "FQMD",
      "iata": "MUD",
      "name": "Mueda Airport",
      "city": "Mueda",
      "state": "Cabo-Delgado",
      "country": "MZ",
      "elevation": 2789,
      "lat": -11.6729001999,
      "lon": 39.5630989075,
      "tz": "Africa\/Maputo"
  },
  "FQMP": {
      "icao": "FQMP",
      "iata": "MZB",
      "name": "Mocimboa da Praia Airport",
      "city": "Mocimboa da Praia",
      "state": "Cabo-Delgado",
      "country": "MZ",
      "elevation": 89,
      "lat": -11.3618001938,
      "lon": 40.3549003601,
      "tz": "Africa\/Maputo"
  },
  "FQMR": {
      "icao": "FQMR",
      "iata": "",
      "name": "Marrupa Airport",
      "city": "Marrupa",
      "state": "Niassa",
      "country": "MZ",
      "elevation": 2480,
      "lat": -13.2250995636,
      "lon": 37.5521011353,
      "tz": "Africa\/Maputo"
  },
  "FQNC": {
      "icao": "FQNC",
      "iata": "MNC",
      "name": "Nacala Airport",
      "city": "Nacala",
      "state": "Nampula",
      "country": "MZ",
      "elevation": 410,
      "lat": -14.4882001877,
      "lon": 40.7122001648,
      "tz": "Africa\/Maputo"
  },
  "FQNP": {
      "icao": "FQNP",
      "iata": "APL",
      "name": "Nampula Airport",
      "city": "Nampula",
      "state": "Nampula",
      "country": "MZ",
      "elevation": 1444,
      "lat": -15.1056003571,
      "lon": 39.2817993164,
      "tz": "Africa\/Maputo"
  },
  "FQPB": {
      "icao": "FQPB",
      "iata": "POL",
      "name": "Pemba Airport",
      "city": "Pemba \/ Porto Amelia",
      "state": "Cabo-Delgado",
      "country": "MZ",
      "elevation": 331,
      "lat": -12.9917621613,
      "lon": 40.5240135193,
      "tz": "Africa\/Maputo"
  },
  "FQPO": {
      "icao": "FQPO",
      "iata": "PDD",
      "name": "Ponta do Ouro Airport",
      "city": "Ponta do Ouro",
      "state": "Maputo",
      "country": "MZ",
      "elevation": 92,
      "lat": -26.8285522461,
      "lon": 32.8377075195,
      "tz": "Africa\/Maputo"
  },
  "FQQL": {
      "icao": "FQQL",
      "iata": "UEL",
      "name": "Quelimane Airport",
      "city": "Quelimane",
      "state": "Zambezia",
      "country": "MZ",
      "elevation": 36,
      "lat": -17.8554992676,
      "lon": 36.8690986633,
      "tz": "Africa\/Maputo"
  },
  "FQSG": {
      "icao": "FQSG",
      "iata": "",
      "name": "Songo Airport",
      "city": "Songo",
      "state": "Tete",
      "country": "MZ",
      "elevation": 2904,
      "lat": -15.6027002335,
      "lon": 32.7732009888,
      "tz": "Africa\/Maputo"
  },
  "FQTT": {
      "icao": "FQTT",
      "iata": "TET",
      "name": "Chingozi Airport",
      "city": "Tete",
      "state": "Tete",
      "country": "MZ",
      "elevation": 525,
      "lat": -16.1047992706,
      "lon": 33.6402015686,
      "tz": "Africa\/Maputo"
  },
  "FQUG": {
      "icao": "FQUG",
      "iata": "",
      "name": "Ulongwe Airport",
      "city": "Ulongwe",
      "state": "Tete",
      "country": "MZ",
      "elevation": 4265,
      "lat": -14.7046003342,
      "lon": 34.3524017334,
      "tz": "Africa\/Maputo"
  },
  "FQVL": {
      "icao": "FQVL",
      "iata": "VNX",
      "name": "Vilankulo Airport",
      "city": "Vilanculo",
      "state": "Inhambane",
      "country": "MZ",
      "elevation": 46,
      "lat": -22.0184001923,
      "lon": 35.3133010864,
      "tz": "Africa\/Maputo"
  },
  "FQXA": {
      "icao": "FQXA",
      "iata": "VJB",
      "name": "Xai-Xai Airport",
      "city": "Xai-Xai",
      "state": "Gaza",
      "country": "MZ",
      "elevation": 0,
      "lat": -25.0377998352,
      "lon": 33.6273994446,
      "tz": "Africa\/Maputo"
  },
  "FSAL": {
      "icao": "FSAL",
      "iata": "",
      "name": "Alphonse Airport",
      "city": "Alphonse Island",
      "state": "",
      "country": "SC",
      "elevation": 10,
      "lat": -7.00478,
      "lon": 52.7262,
      "tz": "Indian\/Mahe"
  },
  "FSAS": {
      "icao": "FSAS",
      "iata": "",
      "name": "Assumption Island Airport",
      "city": "Assumption Island",
      "state": "",
      "country": "SC",
      "elevation": 10,
      "lat": -9.74222,
      "lon": 46.506802,
      "tz": "Indian\/Mahe"
  },
  "FSDA": {
      "icao": "FSDA",
      "iata": "",
      "name": "Darros Airport",
      "city": "Darros Island",
      "state": "",
      "country": "SC",
      "elevation": 10,
      "lat": -5.417,
      "lon": 53.299999,
      "tz": "Indian\/Mahe"
  },
  "FSDR": {
      "icao": "FSDR",
      "iata": "DES",
      "name": "Desroches Airport",
      "city": "Desroches Island",
      "state": "",
      "country": "SC",
      "elevation": 10,
      "lat": -5.6967,
      "lon": 53.6558,
      "tz": "Indian\/Mahe"
  },
  "FSFA": {
      "icao": "FSFA",
      "iata": "",
      "name": "Farquhar Airport",
      "city": "Farquhar Group",
      "state": "",
      "country": "SC",
      "elevation": 10,
      "lat": -10.1096,
      "lon": 51.176102,
      "tz": "Indian\/Mahe"
  },
  "FSIA": {
      "icao": "FSIA",
      "iata": "SEZ",
      "name": "Seychelles International Airport",
      "city": "Mahe Island",
      "state": "Pointe-Larue",
      "country": "SC",
      "elevation": 10,
      "lat": -4.6743397713,
      "lon": 55.5218009949,
      "tz": "Indian\/Mahe"
  },
  "FSMA": {
      "icao": "FSMA",
      "iata": "",
      "name": "Marie-Louise Airport",
      "city": "Marie-Louise Island",
      "state": "",
      "country": "SC",
      "elevation": 9,
      "lat": -6.17416,
      "lon": 53.144402,
      "tz": "Indian\/Mahe"
  },
  "FSPL": {
      "icao": "FSPL",
      "iata": "",
      "name": "Platte Airport",
      "city": "Platte Island",
      "state": "",
      "country": "SC",
      "elevation": 9,
      "lat": -5.85916,
      "lon": 55.383301,
      "tz": "Indian\/Mahe"
  },
  "FSPP": {
      "icao": "FSPP",
      "iata": "PRI",
      "name": "Praslin Airport",
      "city": "Praslin Island",
      "state": "Grand-Anse-Praslin",
      "country": "SC",
      "elevation": 10,
      "lat": -4.3192901611,
      "lon": 55.6913986206,
      "tz": "Indian\/Mahe"
  },
  "FSSB": {
      "icao": "FSSB",
      "iata": "BDI",
      "name": "Bird Island Airport",
      "city": "Bird Island",
      "state": "",
      "country": "SC",
      "elevation": 6,
      "lat": -3.72472,
      "lon": 55.205299,
      "tz": "Indian\/Mahe"
  },
  "FSSC": {
      "icao": "FSSC",
      "iata": "",
      "name": "Coetivy Airport",
      "city": "Coetivy Island",
      "state": "",
      "country": "SC",
      "elevation": 10,
      "lat": -7.13457,
      "lon": 56.278198,
      "tz": "Indian\/Mahe"
  },
  "FSSD": {
      "icao": "FSSD",
      "iata": "DEI",
      "name": "Denis Island Airport",
      "city": "Denis Island",
      "state": "",
      "country": "SC",
      "elevation": 10,
      "lat": -3.80222,
      "lon": 55.666901,
      "tz": "Indian\/Mahe"
  },
  "FSSF": {
      "icao": "FSSF",
      "iata": "FRK",
      "name": "Fregate Island Airport",
      "city": "Fregate Island",
      "state": "Inner-Islands",
      "country": "SC",
      "elevation": 610,
      "lat": -4.583,
      "lon": 55.950001,
      "tz": "Indian\/Mahe"
  },
  "FSSR": {
      "icao": "FSSR",
      "iata": "",
      "name": "Remire Island Airport",
      "city": "Remire Island",
      "state": "",
      "country": "SC",
      "elevation": 9,
      "lat": -5.11722,
      "lon": 53.312199,
      "tz": "Indian\/Mahe"
  },
  "FTTA": {
      "icao": "FTTA",
      "iata": "SRH",
      "name": "Sarh Airport",
      "city": "Sarh",
      "state": "Moyen-Chari",
      "country": "TD",
      "elevation": 1198,
      "lat": 9.1444396973,
      "lon": 18.3743991852,
      "tz": "Africa\/Ndjamena"
  },
  "FTTB": {
      "icao": "FTTB",
      "iata": "OGR",
      "name": "Bongor Airport",
      "city": "Bongor",
      "state": "Mayo-Kebbi-Est",
      "country": "TD",
      "elevation": 1076,
      "lat": 10.2881002426,
      "lon": 15.3810997009,
      "tz": "Africa\/Ndjamena"
  },
  "FTTC": {
      "icao": "FTTC",
      "iata": "AEH",
      "name": "Abeche Airport",
      "city": "",
      "state": "Ouadai",
      "country": "TD",
      "elevation": 1788,
      "lat": 13.8470001221,
      "lon": 20.8442993164,
      "tz": "Africa\/Ndjamena"
  },
  "FTTD": {
      "icao": "FTTD",
      "iata": "MQQ",
      "name": "Moundou Airport",
      "city": "",
      "state": "Logone-Occidental",
      "country": "TD",
      "elevation": 1407,
      "lat": 8.6244096756,
      "lon": 16.0713996887,
      "tz": "Africa\/Ndjamena"
  },
  "FTTE": {
      "icao": "FTTE",
      "iata": "",
      "name": "Biltine Airport",
      "city": "Biltine",
      "state": "Wadi-Fira",
      "country": "TD",
      "elevation": 1680,
      "lat": 14.5170001984,
      "lon": 20.8999996185,
      "tz": "Africa\/Ndjamena"
  },
  "FTTF": {
      "icao": "FTTF",
      "iata": "",
      "name": "Fada Airport",
      "city": "Fada",
      "state": "Ennedi-Ouest",
      "country": "TD",
      "elevation": 1788,
      "lat": 17.1669998169,
      "lon": 21.5669994354,
      "tz": "Africa\/Ndjamena"
  },
  "FTTG": {
      "icao": "FTTG",
      "iata": "",
      "name": "Goz-Beida Airport",
      "city": "Goz-Beida",
      "state": "Ouadai",
      "country": "TD",
      "elevation": 1765,
      "lat": 12.1999998093,
      "lon": 21.466999054,
      "tz": "Africa\/Ndjamena"
  },
  "FTTH": {
      "icao": "FTTH",
      "iata": "LTC",
      "name": "Lai Airport",
      "city": "Lai",
      "state": "Tandjile",
      "country": "TD",
      "elevation": 1171,
      "lat": 9.3999996185,
      "lon": 16.2999992371,
      "tz": "Africa\/Ndjamena"
  },
  "FTTI": {
      "icao": "FTTI",
      "iata": "ATV",
      "name": "Ati Airport",
      "city": "Ati",
      "state": "Batha",
      "country": "TD",
      "elevation": 1089,
      "lat": 13.2389001846,
      "lon": 18.3132991791,
      "tz": "Africa\/Ndjamena"
  },
  "FTTJ": {
      "icao": "FTTJ",
      "iata": "NDJ",
      "name": "N'Djamena International Airport",
      "city": "N'Djamena",
      "state": "Chari-Baguirmi",
      "country": "TD",
      "elevation": 968,
      "lat": 12.1337003708,
      "lon": 15.0340003967,
      "tz": "Africa\/Ndjamena"
  },
  "FTTK": {
      "icao": "FTTK",
      "iata": "BKR",
      "name": "Bokoro Airport",
      "city": "Bokoro",
      "state": "Hadjer-Lamis",
      "country": "TD",
      "elevation": 984,
      "lat": 12.3830003738,
      "lon": 17.0669994354,
      "tz": "Africa\/Ndjamena"
  },
  "FTTL": {
      "icao": "FTTL",
      "iata": "OTC",
      "name": "Bol Airport",
      "city": "Bol",
      "state": "Lac",
      "country": "TD",
      "elevation": 955,
      "lat": 13.4433002472,
      "lon": 14.73939991,
      "tz": "Africa\/Ndjamena"
  },
  "FTTM": {
      "icao": "FTTM",
      "iata": "MVO",
      "name": "Mongo Airport",
      "city": "Mongo",
      "state": "Guera",
      "country": "TD",
      "elevation": 1414,
      "lat": 12.1669998169,
      "lon": 18.6749992371,
      "tz": "Africa\/Ndjamena"
  },
  "FTTN": {
      "icao": "FTTN",
      "iata": "AMC",
      "name": "Am Timan Airport",
      "city": "Am Timan",
      "state": "Salamat",
      "country": "TD",
      "elevation": 1420,
      "lat": 11.0340003967,
      "lon": 20.2740001678,
      "tz": "Africa\/Ndjamena"
  },
  "FTTP": {
      "icao": "FTTP",
      "iata": "PLF",
      "name": "Pala Airport",
      "city": "Pala",
      "state": "Mayo-Kebbi-Ouest",
      "country": "TD",
      "elevation": 1532,
      "lat": 9.3780603409,
      "lon": 14.9250001907,
      "tz": "Africa\/Ndjamena"
  },
  "FTTR": {
      "icao": "FTTR",
      "iata": "",
      "name": "Zouar Airport",
      "city": "Zouar",
      "state": "",
      "country": "TD",
      "elevation": 2655,
      "lat": 20.4500007629,
      "lon": 16.5830001831,
      "tz": "Africa\/Ndjamena"
  },
  "FTTS": {
      "icao": "FTTS",
      "iata": "OUT",
      "name": "Bousso Airport",
      "city": "Bousso",
      "state": "Chari-Baguirmi",
      "country": "TD",
      "elevation": 1099,
      "lat": 10.4829998016,
      "lon": 16.716999054,
      "tz": "Africa\/Ndjamena"
  },
  "FTTU": {
      "icao": "FTTU",
      "iata": "AMO",
      "name": "Mao Airport",
      "city": "Mao",
      "state": "Kanem",
      "country": "TD",
      "elevation": 1072,
      "lat": 14.1456003189,
      "lon": 15.3143997192,
      "tz": "Africa\/Ndjamena"
  },
  "FTTY": {
      "icao": "FTTY",
      "iata": "FYT",
      "name": "Faya Largeau Airport",
      "city": "",
      "state": "Borkou",
      "country": "TD",
      "elevation": 771,
      "lat": 17.9171009064,
      "lon": 19.1110992432,
      "tz": "Africa\/Ndjamena"
  },
  "FTTZ": {
      "icao": "FTTZ",
      "iata": "",
      "name": "Bardai Zougra Airport",
      "city": "Bardai Zougra",
      "state": "",
      "country": "TD",
      "elevation": 3524,
      "lat": 21.4500007629,
      "lon": 17.0499992371,
      "tz": "Africa\/Ndjamena"
  },
  "FV76": {
      "icao": "FV76",
      "iata": "",
      "name": "Kwekwe East Airport",
      "city": "",
      "state": "Midlands",
      "country": "ZW",
      "elevation": 4025,
      "lat": -19.010799408,
      "lon": 30.0251998901,
      "tz": "Africa\/Harare"
  },
  "FV77": {
      "icao": "FV77",
      "iata": "",
      "name": "Mhangura Airport",
      "city": "",
      "state": "",
      "country": "ZW",
      "elevation": 4078,
      "lat": -16.9101009369,
      "lon": 30.2423000336,
      "tz": "Africa\/Harare"
  },
  "FVAB": {
      "icao": "FVAB",
      "iata": "",
      "name": "Aberdeen Airport",
      "city": "Aberdeen",
      "state": "Manicaland",
      "country": "ZW",
      "elevation": 4495,
      "lat": -18.1669998169,
      "lon": 32.6669998169,
      "tz": "Africa\/Harare"
  },
  "FVAE": {
      "icao": "FVAE",
      "iata": "",
      "name": "Braebourne Airport",
      "city": "Braebourne",
      "state": "",
      "country": "ZW",
      "elevation": 3600,
      "lat": -16.6669998169,
      "lon": 31.2329998016,
      "tz": "Africa\/Harare"
  },
  "FVBB": {
      "icao": "FVBB",
      "iata": "",
      "name": "Beit Bridge Airport",
      "city": "",
      "state": "",
      "country": "ZW",
      "elevation": 1509,
      "lat": -22.2000007629,
      "lon": 30.0167007446,
      "tz": "Africa\/Harare"
  },
  "FVBI": {
      "icao": "FVBI",
      "iata": "",
      "name": "Binga Airport",
      "city": "Binga",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 1950,
      "lat": -17.6480998993,
      "lon": 27.3174991608,
      "tz": "Africa\/Harare"
  },
  "FVBL": {
      "icao": "FVBL",
      "iata": "",
      "name": "Mabalauta Airport",
      "city": "Mabalauta",
      "state": "",
      "country": "ZW",
      "elevation": 950,
      "lat": -21.9169998169,
      "lon": 31.466999054,
      "tz": "Africa\/Harare"
  },
  "FVBM": {
      "icao": "FVBM",
      "iata": "",
      "name": "Bumi Airport",
      "city": "Bumi",
      "state": "",
      "country": "ZW",
      "elevation": 1650,
      "lat": -16.8169994354,
      "lon": 28.3500003815,
      "tz": "Africa\/Harare"
  },
  "FVBO": {
      "icao": "FVBO",
      "iata": "",
      "name": "Bosbury Airport",
      "city": "Bosbury",
      "state": "Mashonaland-West",
      "country": "ZW",
      "elevation": 3940,
      "lat": -18.216999054,
      "lon": 30.1329994202,
      "tz": "Africa\/Harare"
  },
  "FVBU": {
      "icao": "FVBU",
      "iata": "BUQ",
      "name": "Joshua Mqabuko Nkomo International Airport",
      "city": "Bulawayo",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 4359,
      "lat": -20.0174007416,
      "lon": 28.6179008484,
      "tz": "Africa\/Harare"
  },
  "FVCC": {
      "icao": "FVCC",
      "iata": "",
      "name": "C.C. Strip",
      "city": "C.C. Strip",
      "state": "",
      "country": "ZW",
      "elevation": 4050,
      "lat": -17.4330005646,
      "lon": 30.2999992371,
      "tz": "Africa\/Harare"
  },
  "FVCD": {
      "icao": "FVCD",
      "iata": "",
      "name": "Chirundu Airport",
      "city": "Chirundu",
      "state": "Mashonaland-West",
      "country": "ZW",
      "elevation": 1310,
      "lat": -16,
      "lon": 28.8999996185,
      "tz": "Africa\/Harare"
  },
  "FVCE": {
      "icao": "FVCE",
      "iata": "",
      "name": "Celina Airport",
      "city": "Celina",
      "state": "",
      "country": "ZW",
      "elevation": 4058,
      "lat": -17.5170001984,
      "lon": 30.8500003815,
      "tz": "Africa\/Harare"
  },
  "FVCH": {
      "icao": "FVCH",
      "iata": "CHJ",
      "name": "Chipinge Airport",
      "city": "Chipinge",
      "state": "Manicaland",
      "country": "ZW",
      "elevation": 3720,
      "lat": -20.2066993713,
      "lon": 32.6282997131,
      "tz": "Africa\/Harare"
  },
  "FVCM": {
      "icao": "FVCM",
      "iata": "",
      "name": "Cam+Motor Airport",
      "city": "Cam+Motor",
      "state": "Mashonaland-West",
      "country": "ZW",
      "elevation": 3720,
      "lat": -18.2999992371,
      "lon": 29.966999054,
      "tz": "Africa\/Harare"
  },
  "FVCN": {
      "icao": "FVCN",
      "iata": "",
      "name": "Centenary Airport",
      "city": "",
      "state": "Mashonaland-Central",
      "country": "ZW",
      "elevation": 4058,
      "lat": -16.7341003418,
      "lon": 31.1219997406,
      "tz": "Africa\/Harare"
  },
  "FVCP": {
      "icao": "FVCP",
      "iata": "",
      "name": "Charles Prince Airport",
      "city": "Harare",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 4845,
      "lat": -17.7516002655,
      "lon": 30.9246997833,
      "tz": "Africa\/Harare"
  },
  "FVCR": {
      "icao": "FVCR",
      "iata": "",
      "name": "Chizarira Airport",
      "city": "Chizarira",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 3280,
      "lat": -17.6830005646,
      "lon": 27.8999996185,
      "tz": "Africa\/Harare"
  },
  "FVCV": {
      "icao": "FVCV",
      "iata": "",
      "name": "Chivu Airport",
      "city": "Chivu",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 4790,
      "lat": -19.033000946,
      "lon": 30.8999996185,
      "tz": "Africa\/Harare"
  },
  "FVCZ": {
      "icao": "FVCZ",
      "iata": "BFO",
      "name": "Buffalo Range Airport",
      "city": "Chiredzi",
      "state": "Masvingo",
      "country": "ZW",
      "elevation": 1421,
      "lat": -21.0081005096,
      "lon": 31.5785999298,
      "tz": "Africa\/Harare"
  },
  "FVDA": {
      "icao": "FVDA",
      "iata": "",
      "name": "Dawsons Airport",
      "city": "Dawsons",
      "state": "",
      "country": "ZW",
      "elevation": 4800,
      "lat": -17.0170001984,
      "lon": 30.8999996185,
      "tz": "Africa\/Harare"
  },
  "FVDE": {
      "icao": "FVDE",
      "iata": "",
      "name": "Deka Airport",
      "city": "Deka",
      "state": "",
      "country": "ZW",
      "elevation": 1750,
      "lat": -18.1000003815,
      "lon": 26.716999054,
      "tz": "Africa\/Harare"
  },
  "FVDU": {
      "icao": "FVDU",
      "iata": "",
      "name": "Dudley Airport",
      "city": "Dudley",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 4986,
      "lat": -18.283000946,
      "lon": 31.4829998016,
      "tz": "Africa\/Harare"
  },
  "FVED": {
      "icao": "FVED",
      "iata": "",
      "name": "Eduan Airport",
      "city": "Eduan",
      "state": "Midlands",
      "country": "ZW",
      "elevation": 3900,
      "lat": -18.7670001984,
      "lon": 29.783000946,
      "tz": "Africa\/Harare"
  },
  "FVFA": {
      "icao": "FVFA",
      "iata": "VFA",
      "name": "Victoria Falls International Airport",
      "city": "Victoria Falls",
      "state": "",
      "country": "ZW",
      "elevation": 3490,
      "lat": -18.0958995819,
      "lon": 25.8390007019,
      "tz": "Africa\/Harare"
  },
  "FVFG": {
      "icao": "FVFG",
      "iata": "",
      "name": "Fothergill Airport",
      "city": "Fothergill",
      "state": "",
      "country": "ZW",
      "elevation": 1630,
      "lat": -16.7000007629,
      "lon": 28.6669998169,
      "tz": "Africa\/Harare"
  },
  "FVFI": {
      "icao": "FVFI",
      "iata": "",
      "name": "Filabusi Airport",
      "city": "Filabusi",
      "state": "Matabeleland-South",
      "country": "ZW",
      "elevation": 3525,
      "lat": -20.533000946,
      "lon": 29.2670001984,
      "tz": "Africa\/Harare"
  },
  "FVGD": {
      "icao": "FVGD",
      "iata": "",
      "name": "Gwanda Airport",
      "city": "Gwanda",
      "state": "Matabeleland-South",
      "country": "ZW",
      "elevation": 3328,
      "lat": -20.8999996185,
      "lon": 29,
      "tz": "Africa\/Harare"
  },
  "FVGO": {
      "icao": "FVGO",
      "iata": "",
      "name": "Gokwe Airport",
      "city": "",
      "state": "Midlands",
      "country": "ZW",
      "elevation": 4199,
      "lat": -18.2000007629,
      "lon": 28.9167003632,
      "tz": "Africa\/Harare"
  },
  "FVGR": {
      "icao": "FVGR",
      "iata": "",
      "name": "Mutara Grand Ref Airport",
      "city": "",
      "state": "",
      "country": "ZW",
      "elevation": 3333,
      "lat": -18.9775009155,
      "lon": 32.4508018494,
      "tz": "Africa\/Harare"
  },
  "FVGT": {
      "icao": "FVGT",
      "iata": "",
      "name": "Gaths Mine Airport",
      "city": "Gaths Mine",
      "state": "Masvingo",
      "country": "ZW",
      "elevation": 3454,
      "lat": -19.9829998016,
      "lon": 30.5,
      "tz": "Africa\/Harare"
  },
  "FVGW": {
      "icao": "FVGW",
      "iata": "",
      "name": "Gweru Airport",
      "city": "Gweru",
      "state": "",
      "country": "ZW",
      "elevation": 4660,
      "lat": -19.5499992371,
      "lon": 29.7329998016,
      "tz": "Africa\/Harare"
  },
  "FVHA": {
      "icao": "FVHA",
      "iata": "HRE",
      "name": "Harare International Airport",
      "city": "Harare",
      "state": "Harare",
      "country": "ZW",
      "elevation": 4887,
      "lat": -17.9318008423,
      "lon": 31.0928001404,
      "tz": "Africa\/Harare"
  },
  "FVHP": {
      "icao": "FVHP",
      "iata": "",
      "name": "Home Park Airport",
      "city": "Home Park",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 5150,
      "lat": -18.2000007629,
      "lon": 31.7329998016,
      "tz": "Africa\/Harare"
  },
  "FVHY": {
      "icao": "FVHY",
      "iata": "",
      "name": "Hippo Valley Airport",
      "city": "Chiredzi",
      "state": "",
      "country": "ZW",
      "elevation": 1420,
      "lat": -21.0499992371,
      "lon": 31.6669998169,
      "tz": "Africa\/Harare"
  },
  "FVIN": {
      "icao": "FVIN",
      "iata": "",
      "name": "Induna Airport",
      "city": "Induna",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 4489,
      "lat": -20.0830001831,
      "lon": 28.7000007629,
      "tz": "Africa\/Harare"
  },
  "FVIT": {
      "icao": "FVIT",
      "iata": "",
      "name": "Itafa Airport",
      "city": "Itafa",
      "state": "Mashonaland-West",
      "country": "ZW",
      "elevation": 3800,
      "lat": -18.2999992371,
      "lon": 29.8829994202,
      "tz": "Africa\/Harare"
  },
  "FVKB": {
      "icao": "FVKB",
      "iata": "KAB",
      "name": "Kariba International Airport",
      "city": "Kariba",
      "state": "",
      "country": "ZW",
      "elevation": 1706,
      "lat": -16.5198001862,
      "lon": 28.8850002289,
      "tz": "Africa\/Harare"
  },
  "FVKK": {
      "icao": "FVKK",
      "iata": "",
      "name": "Kwekwe Airport",
      "city": "Kwekwe",
      "state": "",
      "country": "ZW",
      "elevation": 4000,
      "lat": -18.9333000183,
      "lon": 29.8428001404,
      "tz": "Africa\/Harare"
  },
  "FVKW": {
      "icao": "FVKW",
      "iata": "",
      "name": "Mkwasine Airport",
      "city": "Mkwasine",
      "state": "Masvingo",
      "country": "ZW",
      "elevation": 1620,
      "lat": -20.7999992371,
      "lon": 31.8169994354,
      "tz": "Africa\/Harare"
  },
  "FVKZ": {
      "icao": "FVKZ",
      "iata": "",
      "name": "Kezi Airport",
      "city": "Kezi",
      "state": "",
      "country": "ZW",
      "elevation": 3300,
      "lat": -20.9169998169,
      "lon": 28.4829998016,
      "tz": "Africa\/Harare"
  },
  "FVLA": {
      "icao": "FVLA",
      "iata": "",
      "name": "Langford Airport",
      "city": "Langford",
      "state": "",
      "country": "ZW",
      "elevation": 4600,
      "lat": -17.9330005646,
      "lon": 30.9500007629,
      "tz": "Africa\/Harare"
  },
  "FVLG": {
      "icao": "FVLG",
      "iata": "",
      "name": "Longuiel Airport",
      "city": "Longuiel",
      "state": "Mashonaland-West",
      "country": "ZW",
      "elevation": 4200,
      "lat": -17,
      "lon": 29.6329994202,
      "tz": "Africa\/Harare"
  },
  "FVLU": {
      "icao": "FVLU",
      "iata": "",
      "name": "Lusulu Airport",
      "city": "Lusulu",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 3240,
      "lat": -18.0830001831,
      "lon": 27.8670005798,
      "tz": "Africa\/Harare"
  },
  "FVMA": {
      "icao": "FVMA",
      "iata": "",
      "name": "Marondera Airport",
      "city": "Marondera",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 5370,
      "lat": -18.1830005646,
      "lon": 31.466999054,
      "tz": "Africa\/Harare"
  },
  "FVMB": {
      "icao": "FVMB",
      "iata": "",
      "name": "Mashumbi Airport",
      "city": "Mashumbi",
      "state": "Mashonaland-Central",
      "country": "ZW",
      "elevation": 1240,
      "lat": -16.1669998169,
      "lon": 30.5669994354,
      "tz": "Africa\/Harare"
  },
  "FVMD": {
      "icao": "FVMD",
      "iata": "",
      "name": "Mount Darwin Airport",
      "city": "",
      "state": "Mashonaland-Central",
      "country": "ZW",
      "elevation": 3240,
      "lat": -16.7691993713,
      "lon": 31.5597991943,
      "tz": "Africa\/Harare"
  },
  "FVMF": {
      "icao": "FVMF",
      "iata": "",
      "name": "Mabikwa Airport",
      "city": "Mabikwa",
      "state": "",
      "country": "ZW",
      "elevation": 3450,
      "lat": -18.7329998016,
      "lon": 27.533000946,
      "tz": "Africa\/Harare"
  },
  "FVMK": {
      "icao": "FVMK",
      "iata": "",
      "name": "Mkonono Airport",
      "city": "Mkonono",
      "state": "",
      "country": "ZW",
      "elevation": 4650,
      "lat": -17.716999054,
      "lon": 30.4169998169,
      "tz": "Africa\/Harare"
  },
  "FVMN": {
      "icao": "FVMN",
      "iata": "",
      "name": "Mana Pools Airport",
      "city": "Mana Pools",
      "state": "Mashonaland-West",
      "country": "ZW",
      "elevation": 1300,
      "lat": -15.7670001984,
      "lon": 29.3829994202,
      "tz": "Africa\/Harare"
  },
  "FVMS": {
      "icao": "FVMS",
      "iata": "",
      "name": "Middle Sabi Airport",
      "city": "Middle Sabi",
      "state": "Manicaland",
      "country": "ZW",
      "elevation": 1510,
      "lat": -20.216999054,
      "lon": 32.3670005798,
      "tz": "Africa\/Harare"
  },
  "FVMT": {
      "icao": "FVMT",
      "iata": "",
      "name": "Mutoko Airport",
      "city": "Mutoko",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 3950,
      "lat": -17.4319000244,
      "lon": 32.1845016479,
      "tz": "Africa\/Harare"
  },
  "FVMU": {
      "icao": "FVMU",
      "iata": "UTA",
      "name": "Mutare Airport",
      "city": "",
      "state": "Manicaland",
      "country": "ZW",
      "elevation": 3410,
      "lat": -18.9974994659,
      "lon": 32.6272010803,
      "tz": "Africa\/Harare"
  },
  "FVMV": {
      "icao": "FVMV",
      "iata": "MVZ",
      "name": "Masvingo International Airport",
      "city": "Masvingo",
      "state": "Masvingo",
      "country": "ZW",
      "elevation": 3595,
      "lat": -20.0552997589,
      "lon": 30.8591003418,
      "tz": "Africa\/Harare"
  },
  "FVMW": {
      "icao": "FVMW",
      "iata": "",
      "name": "Murewa Airport",
      "city": "Murewa",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 4579,
      "lat": -17.6499996185,
      "lon": 31.7999992371,
      "tz": "Africa\/Harare"
  },
  "FVNY": {
      "icao": "FVNY",
      "iata": "",
      "name": "Nyanyadzi Airport",
      "city": "Nyanyadzi",
      "state": "",
      "country": "ZW",
      "elevation": 1850,
      "lat": -19.7329998016,
      "lon": 32.4329986572,
      "tz": "Africa\/Harare"
  },
  "FVOT": {
      "icao": "FVOT",
      "iata": "",
      "name": "Kotwa Airport",
      "city": "",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 2450,
      "lat": -16.9848003387,
      "lon": 32.6726989746,
      "tz": "Africa\/Harare"
  },
  "FVPL": {
      "icao": "FVPL",
      "iata": "",
      "name": "Plumtree Airport",
      "city": "Plumtree",
      "state": "",
      "country": "ZW",
      "elevation": 4527,
      "lat": -20.4829998016,
      "lon": 27.783000946,
      "tz": "Africa\/Harare"
  },
  "FVRA": {
      "icao": "FVRA",
      "iata": "",
      "name": "Ratelshoek Airport",
      "city": "Ratelshoek",
      "state": "Manicaland",
      "country": "ZW",
      "elevation": 3250,
      "lat": -20.1669998169,
      "lon": 32.8330001831,
      "tz": "Africa\/Harare"
  },
  "FVRE": {
      "icao": "FVRE",
      "iata": "",
      "name": "Renroc Airport",
      "city": "Renroc",
      "state": "Mashonaland-West",
      "country": "ZW",
      "elevation": 4200,
      "lat": -16.966999054,
      "lon": 29.5669994354,
      "tz": "Africa\/Harare"
  },
  "FVRT": {
      "icao": "FVRT",
      "iata": "",
      "name": "Rutenga Airport",
      "city": "Rutenga",
      "state": "Masvingo",
      "country": "ZW",
      "elevation": 1825,
      "lat": -21.2329998016,
      "lon": 30.7329998016,
      "tz": "Africa\/Harare"
  },
  "FVRU": {
      "icao": "FVRU",
      "iata": "",
      "name": "Rusape Airport",
      "city": "Rusape",
      "state": "Manicaland",
      "country": "ZW",
      "elevation": 4560,
      "lat": -18.533000946,
      "lon": 32.1329994202,
      "tz": "Africa\/Harare"
  },
  "FVSC": {
      "icao": "FVSC",
      "iata": "",
      "name": "Zisco Airport",
      "city": "",
      "state": "",
      "country": "ZW",
      "elevation": 3990,
      "lat": -19.0291996002,
      "lon": 29.7219009399,
      "tz": "Africa\/Harare"
  },
  "FVSE": {
      "icao": "FVSE",
      "iata": "",
      "name": "Sanyati Estate Airport",
      "city": "Sanyati Estate",
      "state": "",
      "country": "ZW",
      "elevation": 2790,
      "lat": -17.966999054,
      "lon": 29.216999054,
      "tz": "Africa\/Harare"
  },
  "FVSH": {
      "icao": "FVSH",
      "iata": "",
      "name": "Zvishavane Airport",
      "city": "",
      "state": "Midlands",
      "country": "ZW",
      "elevation": 3012,
      "lat": -20.2894992828,
      "lon": 30.0881996155,
      "tz": "Africa\/Harare"
  },
  "FVSN": {
      "icao": "FVSN",
      "iata": "",
      "name": "Sun Yet Sen Airport",
      "city": "Sun Yet Sen",
      "state": "",
      "country": "ZW",
      "elevation": 2891,
      "lat": -21.3330001831,
      "lon": 28.5170001984,
      "tz": "Africa\/Harare"
  },
  "FVSV": {
      "icao": "FVSV",
      "iata": "",
      "name": "Spray View Airport",
      "city": "Spray View",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 3210,
      "lat": -17.9169998169,
      "lon": 25.8169994354,
      "tz": "Africa\/Harare"
  },
  "FVSX": {
      "icao": "FVSX",
      "iata": "",
      "name": "Sengwa Gorge Airport",
      "city": "Sengwa Gorge",
      "state": "",
      "country": "ZW",
      "elevation": 2900,
      "lat": -18.1669998169,
      "lon": 28.8500003815,
      "tz": "Africa\/Harare"
  },
  "FVSY": {
      "icao": "FVSY",
      "iata": "",
      "name": "Siyalima Airport",
      "city": "Siyalima",
      "state": "Mashonaland-Central",
      "country": "ZW",
      "elevation": 4540,
      "lat": -16.6329994202,
      "lon": 30.6499996185,
      "tz": "Africa\/Harare"
  },
  "FVTA": {
      "icao": "FVTA",
      "iata": "",
      "name": "Tashinga Airport",
      "city": "Tashinga",
      "state": "",
      "country": "ZW",
      "elevation": 1630,
      "lat": -16.8169994354,
      "lon": 28.4330005646,
      "tz": "Africa\/Harare"
  },
  "FVTD": {
      "icao": "FVTD",
      "iata": "",
      "name": "Tinfields Airport",
      "city": "Tinfields",
      "state": "Masvingo",
      "country": "ZW",
      "elevation": 3600,
      "lat": -19.9500007629,
      "lon": 31.4330005646,
      "tz": "Africa\/Harare"
  },
  "FVTE": {
      "icao": "FVTE",
      "iata": "",
      "name": "Tengwe Airport",
      "city": "Tengwe",
      "state": "",
      "country": "ZW",
      "elevation": 4000,
      "lat": -17.1170005798,
      "lon": 29.6170005798,
      "tz": "Africa\/Harare"
  },
  "FVTJ": {
      "icao": "FVTJ",
      "iata": "",
      "name": "Tonje Airport",
      "city": "Tonje",
      "state": "Manicaland",
      "country": "ZW",
      "elevation": 1500,
      "lat": -20.3169994354,
      "lon": 32.3499984741,
      "tz": "Africa\/Harare"
  },
  "FVTL": {
      "icao": "FVTL",
      "iata": "GWE",
      "name": "Thornhill Air Base",
      "city": "Gweru",
      "state": "",
      "country": "ZW",
      "elevation": 4680,
      "lat": -19.4363994598,
      "lon": 29.8619003296,
      "tz": "Africa\/Harare"
  },
  "FVTS": {
      "icao": "FVTS",
      "iata": "",
      "name": "Tsholothso Airport",
      "city": "Tsholothso",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 3580,
      "lat": -19.7670001984,
      "lon": 27.7670001984,
      "tz": "Africa\/Harare"
  },
  "FVTU": {
      "icao": "FVTU",
      "iata": "",
      "name": "Tuli Airport",
      "city": "Tuli",
      "state": "Matabeleland-South",
      "country": "ZW",
      "elevation": 1940,
      "lat": -21.9349250793,
      "lon": 29.1963386536,
      "tz": "Africa\/Harare"
  },
  "FVWD": {
      "icao": "FVWD",
      "iata": "",
      "name": "Wedza Airport",
      "city": "Wedza",
      "state": "Mashonaland-East",
      "country": "ZW",
      "elevation": 4600,
      "lat": -18.6170005798,
      "lon": 31.5830001831,
      "tz": "Africa\/Harare"
  },
  "FVWN": {
      "icao": "FVWN",
      "iata": "HWN",
      "name": "Hwange National Park Airport",
      "city": "Hwange",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 3543,
      "lat": -18.6298999786,
      "lon": 27.0209999084,
      "tz": "Africa\/Harare"
  },
  "FVWT": {
      "icao": "FVWT",
      "iata": "WKI",
      "name": "Hwange Airport",
      "city": "Hwange",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 2500,
      "lat": -18.3624992371,
      "lon": 26.5167007446,
      "tz": "Africa\/Harare"
  },
  "FVYT": {
      "icao": "FVYT",
      "iata": "",
      "name": "Inyati Airport",
      "city": "Inyati",
      "state": "Matabeleland-North",
      "country": "ZW",
      "elevation": 4400,
      "lat": -19.7000007629,
      "lon": 28.8500003815,
      "tz": "Africa\/Harare"
  },
  "FVZC": {
      "icao": "FVZC",
      "iata": "",
      "name": "Zisco Airport",
      "city": "Zisco",
      "state": "",
      "country": "ZW",
      "elevation": 3975,
      "lat": -19.033000946,
      "lon": 29.716999054,
      "tz": "Africa\/Harare"
  },
  "FVZK": {
      "icao": "FVZK",
      "iata": "",
      "name": "Zaka Airport",
      "city": "Zaka",
      "state": "Masvingo",
      "country": "ZW",
      "elevation": 2550,
      "lat": -20.3500003815,
      "lon": 31.4169998169,
      "tz": "Africa\/Harare"
  },
  "FWBG": {
      "icao": "FWBG",
      "iata": "",
      "name": "Bangula Airport",
      "city": "Bangula",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 302,
      "lat": -16.5832996368,
      "lon": 35.1166992188,
      "tz": "Africa\/Blantyre"
  },
  "FWCB": {
      "icao": "FWCB",
      "iata": "",
      "name": "Chilumba Prv Airport",
      "city": "Chilumba",
      "state": "Northern-Region",
      "country": "MW",
      "elevation": 1611,
      "lat": -10.4333000183,
      "lon": 34.25,
      "tz": "Africa\/Blantyre"
  },
  "FWCD": {
      "icao": "FWCD",
      "iata": "CEH",
      "name": "Chelinda Malawi Airport",
      "city": "",
      "state": "Northern-Region",
      "country": "MW",
      "elevation": 7759,
      "lat": -10.5500001907,
      "lon": 33.7999992371,
      "tz": "Africa\/Blantyre"
  },
  "FWCL": {
      "icao": "FWCL",
      "iata": "BLZ",
      "name": "Chileka International Airport",
      "city": "Blantyre",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 2555,
      "lat": -15.6791000366,
      "lon": 34.9739990234,
      "tz": "Africa\/Blantyre"
  },
  "FWCM": {
      "icao": "FWCM",
      "iata": "CMK",
      "name": "Club Makokola Airport",
      "city": "Club Makokola",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 1587,
      "lat": -14.3069000244,
      "lon": 35.1324996948,
      "tz": "Africa\/Blantyre"
  },
  "FWCS": {
      "icao": "FWCS",
      "iata": "",
      "name": "Ntchisi Airport",
      "city": "Ntchisi",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 4301,
      "lat": -13.3758001328,
      "lon": 33.8644981384,
      "tz": "Africa\/Blantyre"
  },
  "FWCT": {
      "icao": "FWCT",
      "iata": "",
      "name": "Chitipa Airport",
      "city": "Chitipa",
      "state": "Northern-Region",
      "country": "MW",
      "elevation": 4270,
      "lat": -9.6999998093,
      "lon": 33.266998291,
      "tz": "Africa\/Blantyre"
  },
  "FWDW": {
      "icao": "FWDW",
      "iata": "DWA",
      "name": "Dwangwa Airport",
      "city": "Dwangwa",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 1605,
      "lat": -12.5183000565,
      "lon": 34.1319007874,
      "tz": "Africa\/Blantyre"
  },
  "FWDZ": {
      "icao": "FWDZ",
      "iata": "",
      "name": "Dedza Airport",
      "city": "Dedza",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 5240,
      "lat": -14.3830003738,
      "lon": 34.3170013428,
      "tz": "Africa\/Blantyre"
  },
  "FWKA": {
      "icao": "FWKA",
      "iata": "KGJ",
      "name": "Karonga Airport",
      "city": "Karonga",
      "state": "",
      "country": "MW",
      "elevation": 1765,
      "lat": -9.9535703659,
      "lon": 33.8930015564,
      "tz": "Africa\/Blantyre"
  },
  "FWKB": {
      "icao": "FWKB",
      "iata": "",
      "name": "Katumbi Airport",
      "city": "Katumbi",
      "state": "Northern-Region",
      "country": "MW",
      "elevation": 3986,
      "lat": -10.8170003891,
      "lon": 33.516998291,
      "tz": "Africa\/Blantyre"
  },
  "FWKG": {
      "icao": "FWKG",
      "iata": "KBQ",
      "name": "Kasungu Airport",
      "city": "Kasungu",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 3470,
      "lat": -13.0145998001,
      "lon": 33.4686012268,
      "tz": "Africa\/Blantyre"
  },
  "FWKI": {
      "icao": "FWKI",
      "iata": "LLW",
      "name": "Lilongwe International Airport",
      "city": "Lilongwe",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 4035,
      "lat": -13.7894001007,
      "lon": 33.78099823,
      "tz": "Africa\/Blantyre"
  },
  "FWKK": {
      "icao": "FWKK",
      "iata": "",
      "name": "Nkhota Kota Airport",
      "city": "Nkhota Kota",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 1720,
      "lat": -12.9169998169,
      "lon": 34.266998291,
      "tz": "Africa\/Blantyre"
  },
  "FWLE": {
      "icao": "FWLE",
      "iata": "",
      "name": "Lilongwe Airport",
      "city": "Lilongwe",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 3722,
      "lat": -13.9659996033,
      "lon": 33.7018013,
      "tz": "Africa\/Blantyre"
  },
  "FWLK": {
      "icao": "FWLK",
      "iata": "LIX",
      "name": "Likoma Island Airport",
      "city": "Likoma Island",
      "state": "Northern-Region",
      "country": "MW",
      "elevation": 1600,
      "lat": -12.0830001831,
      "lon": 34.733001709,
      "tz": "Africa\/Blantyre"
  },
  "FWLP": {
      "icao": "FWLP",
      "iata": "",
      "name": "Lifupa Airport",
      "city": "Lifupa",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 3326,
      "lat": -13.0500001907,
      "lon": 33.1500015259,
      "tz": "Africa\/Blantyre"
  },
  "FWMC": {
      "icao": "FWMC",
      "iata": "",
      "name": "Mchinji Airport",
      "city": "Mchinji",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 3901,
      "lat": -13.8000001907,
      "lon": 32.9000015259,
      "tz": "Africa\/Blantyre"
  },
  "FWMG": {
      "icao": "FWMG",
      "iata": "MAI",
      "name": "Mangochi Airport",
      "city": "Mangochi",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 1580,
      "lat": -14.4829998016,
      "lon": 35.266998291,
      "tz": "Africa\/Blantyre"
  },
  "FWMY": {
      "icao": "FWMY",
      "iata": "MYZ",
      "name": "Monkey Bay Airport",
      "city": "Monkey Bay",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 1580,
      "lat": -14.0836000443,
      "lon": 34.9197006226,
      "tz": "Africa\/Blantyre"
  },
  "FWMZ": {
      "icao": "FWMZ",
      "iata": "",
      "name": "Mzimba Airport",
      "city": "Mzimba",
      "state": "Northern-Region",
      "country": "MW",
      "elevation": 4440,
      "lat": -11.8830003738,
      "lon": 33.6170005798,
      "tz": "Africa\/Blantyre"
  },
  "FWNB": {
      "icao": "FWNB",
      "iata": "",
      "name": "Ngabu Airport",
      "city": "Ngabu",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 450,
      "lat": -16.4829998016,
      "lon": 34.8499984741,
      "tz": "Africa\/Blantyre"
  },
  "FWSJ": {
      "icao": "FWSJ",
      "iata": "",
      "name": "Nsanje Airport",
      "city": "Nsanje",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 200,
      "lat": -16.9169998169,
      "lon": 35.25,
      "tz": "Africa\/Blantyre"
  },
  "FWSM": {
      "icao": "FWSM",
      "iata": "LMB",
      "name": "Salima Airport",
      "city": "Salima",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 1688,
      "lat": -13.7558927025,
      "lon": 34.5841884613,
      "tz": "Africa\/Blantyre"
  },
  "FWSU": {
      "icao": "FWSU",
      "iata": "",
      "name": "Nchalo Sucoma Airport",
      "city": "",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 230,
      "lat": -16.2667007446,
      "lon": 34.9166984558,
      "tz": "Africa\/Blantyre"
  },
  "FWTK": {
      "icao": "FWTK",
      "iata": "",
      "name": "Mtakatata Airport",
      "city": "Mtakatata",
      "state": "Central-Region",
      "country": "MW",
      "elevation": 1725,
      "lat": -14.2170000076,
      "lon": 34.533000946,
      "tz": "Africa\/Blantyre"
  },
  "FWUU": {
      "icao": "FWUU",
      "iata": "ZZU",
      "name": "Mzuzu Airport",
      "city": "Mzuzu",
      "state": "Northern-Region",
      "country": "MW",
      "elevation": 4115,
      "lat": -11.4447002411,
      "lon": 34.0117988586,
      "tz": "Africa\/Blantyre"
  },
  "FWZA": {
      "icao": "FWZA",
      "iata": "",
      "name": "Zomba Airport",
      "city": "Zomba",
      "state": "Southern-Region",
      "country": "MW",
      "elevation": 2650,
      "lat": -15.3853998184,
      "lon": 35.3844985962,
      "tz": "Africa\/Blantyre"
  },
  "FXBB": {
      "icao": "FXBB",
      "iata": "",
      "name": "Bobete Airport",
      "city": "Bobete",
      "state": "",
      "country": "LS",
      "elevation": 7100,
      "lat": -29.4249992371,
      "lon": 28.6669998169,
      "tz": "Africa\/Maseru"
  },
  "FXKA": {
      "icao": "FXKA",
      "iata": "",
      "name": "Katse Airport",
      "city": "Katse",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 7000,
      "lat": -29.3637008667,
      "lon": 28.527299881,
      "tz": "Africa\/Maseru"
  },
  "FXKB": {
      "icao": "FXKB",
      "iata": "",
      "name": "Kolberg Airport",
      "city": "Kolberg",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 7000,
      "lat": -29.3803005219,
      "lon": 28.4906997681,
      "tz": "Africa\/Maseru"
  },
  "FXLK": {
      "icao": "FXLK",
      "iata": "LEF",
      "name": "Lebakeng Airport",
      "city": "Lebakeng",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 6000,
      "lat": -29.8908004761,
      "lon": 28.6555995941,
      "tz": "Africa\/Maseru"
  },
  "FXLR": {
      "icao": "FXLR",
      "iata": "LRB",
      "name": "Leribe Airport",
      "city": "Leribe",
      "state": "Leribe",
      "country": "LS",
      "elevation": 5350,
      "lat": -28.8556003571,
      "lon": 28.0527992249,
      "tz": "Africa\/Johannesburg"
  },
  "FXLS": {
      "icao": "FXLS",
      "iata": "LES",
      "name": "Lesobeng Airport",
      "city": "Lesobeng",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 7130,
      "lat": -29.7828998566,
      "lon": 28.3166999817,
      "tz": "Africa\/Maseru"
  },
  "FXLT": {
      "icao": "FXLT",
      "iata": "",
      "name": "Letseng Airport",
      "city": "Letseng",
      "state": "Mokhotlong",
      "country": "LS",
      "elevation": 10400,
      "lat": -29.0093002319,
      "lon": 28.8547000885,
      "tz": "Africa\/Maseru"
  },
  "FXMA": {
      "icao": "FXMA",
      "iata": "MSG",
      "name": "Matsaile Airport",
      "city": "Matsaile",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 6200,
      "lat": -29.8405990601,
      "lon": 28.7763996124,
      "tz": "Africa\/Maseru"
  },
  "FXMF": {
      "icao": "FXMF",
      "iata": "MFC",
      "name": "Mafeteng Airport",
      "city": "Mafeteng",
      "state": "Mafeteng",
      "country": "LS",
      "elevation": 5350,
      "lat": -29.8010997772,
      "lon": 27.2436008453,
      "tz": "Africa\/Maseru"
  },
  "FXMH": {
      "icao": "FXMH",
      "iata": "",
      "name": "Mohale's Hoek Airport",
      "city": "Mohale's Hoek",
      "state": "",
      "country": "LS",
      "elevation": 5146,
      "lat": -30.1445007324,
      "lon": 27.4708003998,
      "tz": "Africa\/Maseru"
  },
  "FXMK": {
      "icao": "FXMK",
      "iata": "MKH",
      "name": "Mokhotlong Airport",
      "city": "Mokhotlong",
      "state": "Mokhotlong",
      "country": "LS",
      "elevation": 7200,
      "lat": -29.2817993164,
      "lon": 29.0727996826,
      "tz": "Africa\/Maseru"
  },
  "FXML": {
      "icao": "FXML",
      "iata": "",
      "name": "Malefiloane Airstrip",
      "city": "Malefiloane",
      "state": "",
      "country": "LS",
      "elevation": 8267,
      "lat": -29.3355007172,
      "lon": 29.185300827,
      "tz": "Africa\/Maseru"
  },
  "FXMM": {
      "icao": "FXMM",
      "iata": "MSU",
      "name": "Moshoeshoe I International Airport",
      "city": "Maseru",
      "state": "Maseru",
      "country": "LS",
      "elevation": 5348,
      "lat": -29.4622993469,
      "lon": 27.5524997711,
      "tz": "Africa\/Maseru"
  },
  "FXMN": {
      "icao": "FXMN",
      "iata": "",
      "name": "Mantsonyane Airport",
      "city": "Mantsonyane",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 7100,
      "lat": -29.5461006165,
      "lon": 28.2709999084,
      "tz": "Africa\/Maseru"
  },
  "FXMP": {
      "icao": "FXMP",
      "iata": "",
      "name": "Mohlanapeng Airport",
      "city": "Mohlanapeng",
      "state": "",
      "country": "LS",
      "elevation": 7294,
      "lat": -29.5830001831,
      "lon": 28.6830005646,
      "tz": "Africa\/Maseru"
  },
  "FXMS": {
      "icao": "FXMS",
      "iata": "",
      "name": "Mashai Store Airport",
      "city": "Mashai Store",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 7300,
      "lat": -29.6830005646,
      "lon": 28.7999992371,
      "tz": "Africa\/Maseru"
  },
  "FXMT": {
      "icao": "FXMT",
      "iata": "",
      "name": "Matabeng Store Airport",
      "city": "Matabeng Store",
      "state": "",
      "country": "LS",
      "elevation": 6300,
      "lat": -29.783000946,
      "lon": 28.7670001984,
      "tz": "Africa\/Maseru"
  },
  "FXMU": {
      "icao": "FXMU",
      "iata": "",
      "name": "Mejametalana Airbase",
      "city": "Maseru",
      "state": "Maseru",
      "country": "LS",
      "elevation": 5105,
      "lat": -29.3041000366,
      "lon": 27.5034999847,
      "tz": "Africa\/Johannesburg"
  },
  "FXMV": {
      "icao": "FXMV",
      "iata": "",
      "name": "Matabeng Village Airport",
      "city": "Matabeng Village",
      "state": "",
      "country": "LS",
      "elevation": 6150,
      "lat": -29.814699173,
      "lon": 28.8082008362,
      "tz": "Africa\/Maseru"
  },
  "FXNH": {
      "icao": "FXNH",
      "iata": "",
      "name": "Nohanas Airport",
      "city": "Nohanas",
      "state": "",
      "country": "LS",
      "elevation": 5400,
      "lat": -30.0669994354,
      "lon": 27.8670005798,
      "tz": "Africa\/Maseru"
  },
  "FXNK": {
      "icao": "FXNK",
      "iata": "NKU",
      "name": "Nkaus Airport",
      "city": "Nkaus",
      "state": "Mohale\u02bcs-Hoek",
      "country": "LS",
      "elevation": 5621,
      "lat": -30.0216999054,
      "lon": 28.1968994141,
      "tz": "Africa\/Maseru"
  },
  "FXPG": {
      "icao": "FXPG",
      "iata": "PEL",
      "name": "Pelaneng Airport",
      "city": "Pelaneng",
      "state": "Leribe",
      "country": "LS",
      "elevation": 7200,
      "lat": -29.1205997467,
      "lon": 28.5053005219,
      "tz": "Africa\/Maseru"
  },
  "FXQG": {
      "icao": "FXQG",
      "iata": "UTG",
      "name": "Quthing Airport",
      "city": "Quthing",
      "state": "Quthing",
      "country": "LS",
      "elevation": 5350,
      "lat": -30.4074993134,
      "lon": 27.6933002472,
      "tz": "Africa\/Maseru"
  },
  "FXQN": {
      "icao": "FXQN",
      "iata": "UNE",
      "name": "Qacha's Nek Airport",
      "city": "Qacha's Nek",
      "state": "Qacha\u02bcs-Nek",
      "country": "LS",
      "elevation": 6100,
      "lat": -30.111700058,
      "lon": 28.6718997955,
      "tz": "Africa\/Johannesburg"
  },
  "FXSE": {
      "icao": "FXSE",
      "iata": "",
      "name": "Sehlabathebe Airport",
      "city": "Sehlabathebe",
      "state": "",
      "country": "LS",
      "elevation": 7300,
      "lat": -29.9158000946,
      "lon": 29.0387992859,
      "tz": "Africa\/Maseru"
  },
  "FXSH": {
      "icao": "FXSH",
      "iata": "SHK",
      "name": "Sehonghong Airport",
      "city": "Sehonghong",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 6500,
      "lat": -29.7308998108,
      "lon": 28.7688999176,
      "tz": "Africa\/Maseru"
  },
  "FXSK": {
      "icao": "FXSK",
      "iata": "SKQ",
      "name": "Sekakes Airport",
      "city": "Sekakes",
      "state": "Mohale\u02bcs-Hoek",
      "country": "LS",
      "elevation": 5700,
      "lat": -30.0389003754,
      "lon": 28.370300293,
      "tz": "Africa\/Maseru"
  },
  "FXSM": {
      "icao": "FXSM",
      "iata": "SOK",
      "name": "Semonkong Airport",
      "city": "Semonkong",
      "state": "Maseru",
      "country": "LS",
      "elevation": 7200,
      "lat": -29.8386001587,
      "lon": 28.0599994659,
      "tz": "Africa\/Maseru"
  },
  "FXSS": {
      "icao": "FXSS",
      "iata": "SHZ",
      "name": "Seshutes Airport",
      "city": "Seshutes",
      "state": "Leribe",
      "country": "LS",
      "elevation": 7000,
      "lat": -29.2675991058,
      "lon": 28.5522994995,
      "tz": "Africa\/Maseru"
  },
  "FXST": {
      "icao": "FXST",
      "iata": "",
      "name": "St. Theresa Airport",
      "city": "St. Theresa",
      "state": "",
      "country": "LS",
      "elevation": 6800,
      "lat": -29.6170005798,
      "lon": 28.783000946,
      "tz": "Africa\/Maseru"
  },
  "FXTA": {
      "icao": "FXTA",
      "iata": "THB",
      "name": "Thaba-Tseka Airport",
      "city": "Thaba-Tseka",
      "state": "Thaba-Tseka",
      "country": "LS",
      "elevation": 7500,
      "lat": -29.5228004456,
      "lon": 28.6158008575,
      "tz": "Africa\/Maseru"
  },
  "FXTB": {
      "icao": "FXTB",
      "iata": "",
      "name": "Tebellong Airport",
      "city": "Tebellong",
      "state": "",
      "country": "LS",
      "elevation": 5600,
      "lat": -30.0499992371,
      "lon": 28.4330005646,
      "tz": "Africa\/Maseru"
  },
  "FXTK": {
      "icao": "FXTK",
      "iata": "TKO",
      "name": "Tlokoeng Airport",
      "city": "Tlokoeng",
      "state": "Mokhotlong",
      "country": "LS",
      "elevation": 7000,
      "lat": -29.2329998016,
      "lon": 28.8829994202,
      "tz": "Africa\/Maseru"
  },
  "FYAB": {
      "icao": "FYAB",
      "iata": "",
      "name": "Aroab B Airport",
      "city": "Aroab",
      "state": "Karas",
      "country": "NA",
      "elevation": 3235,
      "lat": -26.7761001587,
      "lon": 19.6331005096,
      "tz": "Africa\/Windhoek"
  },
  "FYAM": {
      "icao": "FYAM",
      "iata": "",
      "name": "Aminuis Airstrip",
      "city": "Aminuis",
      "state": "Omaheke",
      "country": "NA",
      "elevation": 4012,
      "lat": -23.6557998657,
      "lon": 19.3516998291,
      "tz": "Africa\/Windhoek"
  },
  "FYAR": {
      "icao": "FYAR",
      "iata": "ADI",
      "name": "Arandis Airport",
      "city": "Arandis",
      "state": "",
      "country": "NA",
      "elevation": 1905,
      "lat": -22.4622001648,
      "lon": 14.9799995422,
      "tz": "Africa\/Windhoek"
  },
  "FYAS": {
      "icao": "FYAS",
      "iata": "",
      "name": "Aus Airport",
      "city": "Aus",
      "state": "Karas",
      "country": "NA",
      "elevation": 4856,
      "lat": -26.6938991547,
      "lon": 16.3188991547,
      "tz": "Africa\/Windhoek"
  },
  "FYAV": {
      "icao": "FYAV",
      "iata": "",
      "name": "Ariamsvley Airport",
      "city": "Ariamsvley",
      "state": "Karas",
      "country": "NA",
      "elevation": 2549,
      "lat": -28.1189002991,
      "lon": 19.8339004517,
      "tz": "Africa\/Windhoek"
  },
  "FYBC": {
      "icao": "FYBC",
      "iata": "",
      "name": "Bethanien Airport",
      "city": "Bethanien",
      "state": "Karas",
      "country": "NA",
      "elevation": 3222,
      "lat": -26.5897006989,
      "lon": 17.1674995422,
      "tz": "Africa\/Windhoek"
  },
  "FYBJ": {
      "icao": "FYBJ",
      "iata": "",
      "name": "Bitterwasser Lodge & Flying Club Airfield",
      "city": "Bitterwasser",
      "state": "Hardap",
      "country": "NA",
      "elevation": 4167,
      "lat": -23.875,
      "lon": 17.9911003113,
      "tz": "Africa\/Windhoek"
  },
  "FYEK": {
      "icao": "FYEK",
      "iata": "",
      "name": "Epukiro Airport",
      "city": "Epukiro",
      "state": "Omaheke",
      "country": "NA",
      "elevation": 4892,
      "lat": -21.786699295,
      "lon": 19.1061000824,
      "tz": "Africa\/Windhoek"
  },
  "FYEN": {
      "icao": "FYEN",
      "iata": "",
      "name": "Eenhana Airport",
      "city": "Eenhana",
      "state": "",
      "country": "NA",
      "elevation": 3660,
      "lat": -17.4829998016,
      "lon": 16.3220005035,
      "tz": "Africa\/Windhoek"
  },
  "FYGB": {
      "icao": "FYGB",
      "iata": "GOG",
      "name": "Gobabis Airport",
      "city": "Gobabis",
      "state": "",
      "country": "NA",
      "elevation": 4731,
      "lat": -22.5044002533,
      "lon": 18.9731006622,
      "tz": "Africa\/Windhoek"
  },
  "FYGC": {
      "icao": "FYGC",
      "iata": "",
      "name": "Gochas Airstrip",
      "city": "Gochas",
      "state": "Hardap",
      "country": "NA",
      "elevation": 3714,
      "lat": -24.8563995361,
      "lon": 18.8178005219,
      "tz": "Africa\/Windhoek"
  },
  "FYGF": {
      "icao": "FYGF",
      "iata": "GFY",
      "name": "Grootfontein Airport",
      "city": "Grootfontein",
      "state": "Otjozondjupa",
      "country": "NA",
      "elevation": 4636,
      "lat": -19.6021995544,
      "lon": 18.1226997375,
      "tz": "Africa\/Windhoek"
  },
  "FYGK": {
      "icao": "FYGK",
      "iata": "",
      "name": "Geluk Kuala Lodge Airport",
      "city": "",
      "state": "Hardap",
      "country": "NA",
      "elevation": 857,
      "lat": -24.6299991608,
      "lon": 15.9392004013,
      "tz": "Africa\/Windhoek"
  },
  "FYGL": {
      "icao": "FYGL",
      "iata": "",
      "name": "Omaruru Game Lodge Airport",
      "city": "Omaruru",
      "state": "Erongo",
      "country": "NA",
      "elevation": 4226,
      "lat": -21.3260993958,
      "lon": 16.0893993378,
      "tz": "Africa\/Windhoek"
  },
  "FYGV": {
      "icao": "FYGV",
      "iata": "",
      "name": "Gravenstein Prv Airport",
      "city": "",
      "state": "Khomas",
      "country": "NA",
      "elevation": 4370,
      "lat": -23.4589004517,
      "lon": 17.497800827,
      "tz": "Africa\/Windhoek"
  },
  "FYHH": {
      "icao": "FYHH",
      "iata": "",
      "name": "Helmeringhausen Airport",
      "city": "Helmeringhausen",
      "state": "Karas",
      "country": "NA",
      "elevation": 4603,
      "lat": -25.8633003235,
      "lon": 16.8099994659,
      "tz": "Africa\/Windhoek"
  },
  "FYHS": {
      "icao": "FYHS",
      "iata": "",
      "name": "Hobas Airport",
      "city": "Hobas",
      "state": "Karas",
      "country": "NA",
      "elevation": 2313,
      "lat": -27.6243991852,
      "lon": 17.6933002472,
      "tz": "Africa\/Windhoek"
  },
  "FYIA": {
      "icao": "FYIA",
      "iata": "",
      "name": "Intu Africa Pan Airport",
      "city": "",
      "state": "Hardap",
      "country": "NA",
      "elevation": 4012,
      "lat": -24.088300705,
      "lon": 17.9517002106,
      "tz": "Africa\/Windhoek"
  },
  "FYKA": {
      "icao": "FYKA",
      "iata": "",
      "name": "Karibib Airport",
      "city": "Karibib",
      "state": "Erongo",
      "country": "NA",
      "elevation": 3829,
      "lat": -21.8477993011,
      "lon": 15.9027996063,
      "tz": "Africa\/Windhoek"
  },
  "FYKD": {
      "icao": "FYKD",
      "iata": "",
      "name": "Kalkfeld Airport",
      "city": "Kalkfeld",
      "state": "Otjozondjupa",
      "country": "NA",
      "elevation": 5059,
      "lat": -20.9013996124,
      "lon": 16.2077999115,
      "tz": "Africa\/Windhoek"
  },
  "FYKE": {
      "icao": "FYKE",
      "iata": "",
      "name": "Kalahari Game Lodge Airport",
      "city": "",
      "state": "Karas",
      "country": "NA",
      "elevation": 3182,
      "lat": -25.6511001587,
      "lon": 19.8780994415,
      "tz": "Africa\/Windhoek"
  },
  "FYKJ": {
      "icao": "FYKJ",
      "iata": "",
      "name": "Kamanjab Airport",
      "city": "Kamanjab",
      "state": "Kunene",
      "country": "NA",
      "elevation": 4259,
      "lat": -19.5205993652,
      "lon": 14.8233003616,
      "tz": "Africa\/Windhoek"
  },
  "FYKM": {
      "icao": "FYKM",
      "iata": "MPA",
      "name": "Katima Mulilo Airport",
      "city": "Mpacha",
      "state": "Zambezi",
      "country": "NA",
      "elevation": 3144,
      "lat": -17.6343994141,
      "lon": 24.176700592,
      "tz": "Africa\/Windhoek"
  },
  "FYKT": {
      "icao": "FYKT",
      "iata": "KMP",
      "name": "Keetmanshoop Airport",
      "city": "Keetmanshoop",
      "state": "Karas",
      "country": "NA",
      "elevation": 3506,
      "lat": -26.5398006439,
      "lon": 18.1114006042,
      "tz": "Africa\/Windhoek"
  },
  "FYLS": {
      "icao": "FYLS",
      "iata": "LHU",
      "name": "Lianshulu Airport",
      "city": "Muneambuanas",
      "state": "",
      "country": "NA",
      "elevation": 3143,
      "lat": -18.1166992188,
      "lon": 23.3932991028,
      "tz": "Africa\/Gaborone"
  },
  "FYLZ": {
      "icao": "FYLZ",
      "iata": "LUD",
      "name": "Luderitz Airport",
      "city": "Luderitz",
      "state": "Karas",
      "country": "NA",
      "elevation": 457,
      "lat": -26.6874008179,
      "lon": 15.2428998947,
      "tz": "Africa\/Windhoek"
  },
  "FYMB": {
      "icao": "FYMB",
      "iata": "",
      "name": "Meob Bay Landing Site",
      "city": "Meob Bay",
      "state": "Hardap",
      "country": "NA",
      "elevation": 20,
      "lat": -24.6170005798,
      "lon": 14.6829996109,
      "tz": "Africa\/Windhoek"
  },
  "FYME": {
      "icao": "FYME",
      "iata": "MJO",
      "name": "Mount Etjo Airport",
      "city": "",
      "state": "Otjozondjupa",
      "country": "NA",
      "elevation": 5000,
      "lat": -21.0233001709,
      "lon": 16.4528007507,
      "tz": "Africa\/Windhoek"
  },
  "FYMH": {
      "icao": "FYMH",
      "iata": "",
      "name": "Maltahoehe Airstrip",
      "city": "Maltahoehe",
      "state": "Hardap",
      "country": "NA",
      "elevation": 4511,
      "lat": -24.771900177,
      "lon": 16.9794006348,
      "tz": "Africa\/Windhoek"
  },
  "FYML": {
      "icao": "FYML",
      "iata": "",
      "name": "Mariental Airport",
      "city": "Mariental",
      "state": "Hardap",
      "country": "NA",
      "elevation": 3650,
      "lat": -24.6054000854,
      "lon": 17.9253997803,
      "tz": "Africa\/Windhoek"
  },
  "FYMO": {
      "icao": "FYMO",
      "iata": "OKU",
      "name": "Mokuti Lodge Airport",
      "city": "Mokuti Lodge",
      "state": "",
      "country": "NA",
      "elevation": 3665,
      "lat": -18.8127994537,
      "lon": 17.0594005585,
      "tz": "Africa\/Windhoek"
  },
  "FYNA": {
      "icao": "FYNA",
      "iata": "NNI",
      "name": "Namutoni Airport",
      "city": "Namutoni",
      "state": "",
      "country": "NA",
      "elevation": 3579,
      "lat": -18.8064002991,
      "lon": 16.9272003174,
      "tz": "Africa\/Windhoek"
  },
  "FYOA": {
      "icao": "FYOA",
      "iata": "OND",
      "name": "Ondangwa Airport",
      "city": "Ondangwa",
      "state": "",
      "country": "NA",
      "elevation": 3599,
      "lat": -17.878200531,
      "lon": 15.9525995255,
      "tz": "Africa\/Windhoek"
  },
  "FYOE": {
      "icao": "FYOE",
      "iata": "OMG",
      "name": "Omega Airport",
      "city": "Omega",
      "state": "",
      "country": "NA",
      "elevation": 3346,
      "lat": -18.0303001404,
      "lon": 22.189699173,
      "tz": "Africa\/Windhoek"
  },
  "FYOG": {
      "icao": "FYOG",
      "iata": "OMD",
      "name": "Oranjemund Airport",
      "city": "Oranjemund",
      "state": "Karas",
      "country": "NA",
      "elevation": 14,
      "lat": -28.5846996307,
      "lon": 16.4466991425,
      "tz": "Africa\/Johannesburg"
  },
  "FYOJ": {
      "icao": "FYOJ",
      "iata": "",
      "name": "Outjo Airport",
      "city": "Outjo",
      "state": "Kunene",
      "country": "NA",
      "elevation": 4334,
      "lat": -20.0753002167,
      "lon": 16.1247005463,
      "tz": "Africa\/Windhoek"
  },
  "FYOM": {
      "icao": "FYOM",
      "iata": "",
      "name": "Omaruru Airport",
      "city": "Omaruru",
      "state": "Erongo",
      "country": "NA",
      "elevation": 3993,
      "lat": -21.4150009155,
      "lon": 15.9380998611,
      "tz": "Africa\/Windhoek"
  },
  "FYON": {
      "icao": "FYON",
      "iata": "",
      "name": "Okahandja Airstrip",
      "city": "Okahandja",
      "state": "Otjozondjupa",
      "country": "NA",
      "elevation": 4321,
      "lat": -22.0139007568,
      "lon": 16.8974990845,
      "tz": "Africa\/Windhoek"
  },
  "FYOO": {
      "icao": "FYOO",
      "iata": "OKF",
      "name": "Okaukuejo Airport",
      "city": "Okaukuejo",
      "state": "Kunene",
      "country": "NA",
      "elevation": 3911,
      "lat": -19.1492004395,
      "lon": 15.9118995667,
      "tz": "Africa\/Windhoek"
  },
  "FYOU": {
      "icao": "FYOU",
      "iata": "",
      "name": "Operet Airport",
      "city": "Operet",
      "state": "Oshikoto",
      "country": "NA",
      "elevation": 3625,
      "lat": -18.6093997955,
      "lon": 17.1497001648,
      "tz": "Africa\/Windhoek"
  },
  "FYOW": {
      "icao": "FYOW",
      "iata": "OTJ",
      "name": "Otjiwarongo Airport",
      "city": "Otjiwarongo",
      "state": "",
      "country": "NA",
      "elevation": 4859,
      "lat": -20.4347000122,
      "lon": 16.6608009338,
      "tz": "Africa\/Windhoek"
  },
  "FYPO": {
      "icao": "FYPO",
      "iata": "",
      "name": "Pokweni Glider Airport",
      "city": "",
      "state": "Hardap",
      "country": "NA",
      "elevation": 4177,
      "lat": -23.6499996185,
      "lon": 17.7299995422,
      "tz": "Africa\/Windhoek"
  },
  "FYRC": {
      "icao": "FYRC",
      "iata": "",
      "name": "Ruacana Airport",
      "city": "Ruacana",
      "state": "Kunene",
      "country": "NA",
      "elevation": 3765,
      "lat": -17.4206008911,
      "lon": 14.3717002869,
      "tz": "Africa\/Windhoek"
  },
  "FYRP": {
      "icao": "FYRP",
      "iata": "",
      "name": "Rosh Pinah Airport",
      "city": "Rosh Pinah",
      "state": "Karas",
      "country": "NA",
      "elevation": 1274,
      "lat": -27.9643001556,
      "lon": 16.753900528,
      "tz": "Africa\/Windhoek"
  },
  "FYRR": {
      "icao": "FYRR",
      "iata": "",
      "name": "Rag Rock Airport",
      "city": "",
      "state": "Kunene",
      "country": "NA",
      "elevation": 2100,
      "lat": -20.5380992889,
      "lon": 14.4330997467,
      "tz": "Africa\/Windhoek"
  },
  "FYRU": {
      "icao": "FYRU",
      "iata": "NDU",
      "name": "Rundu Airport",
      "city": "Rundu",
      "state": "",
      "country": "NA",
      "elevation": 3627,
      "lat": -17.9564990997,
      "lon": 19.7194004059,
      "tz": "Africa\/Windhoek"
  },
  "FYSA": {
      "icao": "FYSA",
      "iata": "RHN",
      "name": "Skorpion Mine Airport",
      "city": "Rosh Pinah",
      "state": "",
      "country": "NA",
      "elevation": 1870,
      "lat": -27.8763999939,
      "lon": 16.6478004456,
      "tz": "Africa\/Windhoek"
  },
  "FYSL": {
      "icao": "FYSL",
      "iata": "",
      "name": "Sossusvlei Moun Airport",
      "city": "",
      "state": "Hardap",
      "country": "NA",
      "elevation": 2844,
      "lat": -24.8033008575,
      "lon": 15.8910999298,
      "tz": "Africa\/Windhoek"
  },
  "FYSM": {
      "icao": "FYSM",
      "iata": "SWP",
      "name": "Swakopmund Airport",
      "city": "Swakopmund",
      "state": "Erongo",
      "country": "NA",
      "elevation": 207,
      "lat": -22.6618995667,
      "lon": 14.5680999756,
      "tz": "Africa\/Windhoek"
  },
  "FYSN": {
      "icao": "FYSN",
      "iata": "",
      "name": "Osona Airstrip",
      "city": "Osona",
      "state": "Otjozondjupa",
      "country": "NA",
      "elevation": 4449,
      "lat": -22.10779953,
      "lon": 16.9797000885,
      "tz": "Africa\/Windhoek"
  },
  "FYSO": {
      "icao": "FYSO",
      "iata": "",
      "name": "Solitaire Airport",
      "city": "Solitaire",
      "state": "Khomas",
      "country": "NA",
      "elevation": 3488,
      "lat": -23.900800705,
      "lon": 16.004699707,
      "tz": "Africa\/Windhoek"
  },
  "FYSP": {
      "icao": "FYSP",
      "iata": "",
      "name": "Stampriet Pan Airport",
      "city": "Stampriet",
      "state": "Hardap",
      "country": "NA",
      "elevation": 3819,
      "lat": -24.3521995544,
      "lon": 18.4333000183,
      "tz": "Africa\/Windhoek"
  },
  "FYSS": {
      "icao": "FYSS",
      "iata": "SZM",
      "name": "Sesriem Airstrip",
      "city": "",
      "state": "Hardap",
      "country": "NA",
      "elevation": 2454,
      "lat": -24.5128002167,
      "lon": 15.7467002869,
      "tz": "Africa\/Windhoek"
  },
  "FYST": {
      "icao": "FYST",
      "iata": "",
      "name": "Strate Airport",
      "city": "",
      "state": "Hardap",
      "country": "NA",
      "elevation": 4019,
      "lat": -23.9678001404,
      "lon": 18.5489006042,
      "tz": "Africa\/Windhoek"
  },
  "FYTK": {
      "icao": "FYTK",
      "iata": "",
      "name": "Tsumkwe Airport",
      "city": "Tsumkwe",
      "state": "Otjozondjupa",
      "country": "NA",
      "elevation": 3780,
      "lat": -19.5849990845,
      "lon": 20.4528007507,
      "tz": "Africa\/Windhoek"
  },
  "FYTM": {
      "icao": "FYTM",
      "iata": "TSB",
      "name": "Tsumeb Airport",
      "city": "Tsumeb",
      "state": "",
      "country": "NA",
      "elevation": 4353,
      "lat": -19.2618999481,
      "lon": 17.7325000763,
      "tz": "Africa\/Windhoek"
  },
  "FYUS": {
      "icao": "FYUS",
      "iata": "",
      "name": "Uis Mine Airport",
      "city": "Uis Mine",
      "state": "Erongo",
      "country": "NA",
      "elevation": 2644,
      "lat": -21.2299995422,
      "lon": 14.8671998978,
      "tz": "Africa\/Windhoek"
  },
  "FYWB": {
      "icao": "FYWB",
      "iata": "WVB",
      "name": "Walvis Bay Airport",
      "city": "Walvis Bay",
      "state": "Erongo",
      "country": "NA",
      "elevation": 299,
      "lat": -22.9799003601,
      "lon": 14.6452999115,
      "tz": "Africa\/Windhoek"
  },
  "FYWD": {
      "icao": "FYWD",
      "iata": "",
      "name": "Wolwedans Airport",
      "city": "",
      "state": "Hardap",
      "country": "NA",
      "elevation": 3271,
      "lat": -25.1168994904,
      "lon": 16.0006008148,
      "tz": "Africa\/Windhoek"
  },
  "FYWE": {
      "icao": "FYWE",
      "iata": "ERS",
      "name": "Eros Airport",
      "city": "Windhoek",
      "state": "Khomas",
      "country": "NA",
      "elevation": 5575,
      "lat": -22.6121997833,
      "lon": 17.0804004669,
      "tz": "Africa\/Windhoek"
  },
  "FYWH": {
      "icao": "FYWH",
      "iata": "WDH",
      "name": "Hosea Kutako International Airport",
      "city": "Windhoek",
      "state": "Khomas",
      "country": "NA",
      "elevation": 5640,
      "lat": -22.4799003601,
      "lon": 17.4708995819,
      "tz": "Africa\/Windhoek"
  },
  "FYWI": {
      "icao": "FYWI",
      "iata": "",
      "name": "Witvlei Airport",
      "city": "Witvlei",
      "state": "Omaheke",
      "country": "NA",
      "elevation": 4800,
      "lat": -22.4053001404,
      "lon": 18.4591999054,
      "tz": "Africa\/Windhoek"
  },
  "FYXX": {
      "icao": "FYXX",
      "iata": "",
      "name": "Canon Lodge Airport",
      "city": "",
      "state": "Karas",
      "country": "NA",
      "elevation": 3058,
      "lat": -27.6597003937,
      "lon": 17.8377990723,
      "tz": "Africa\/Windhoek"
  },
  "FZAA": {
      "icao": "FZAA",
      "iata": "FIH",
      "name": "Ndjili International Airport",
      "city": "Kinshasa",
      "state": "",
      "country": "CD",
      "elevation": 1027,
      "lat": -4.3857498169,
      "lon": 15.4446001053,
      "tz": "Africa\/Kinshasa"
  },
  "FZAB": {
      "icao": "FZAB",
      "iata": "NLO",
      "name": "Ndolo Airport",
      "city": "",
      "state": "Kinshasa",
      "country": "CD",
      "elevation": 915,
      "lat": -4.3266601563,
      "lon": 15.3275003433,
      "tz": "Africa\/Kinshasa"
  },
  "FZAD": {
      "icao": "FZAD",
      "iata": "",
      "name": "Celo Zongo Airport",
      "city": "Celo Zongo",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1660,
      "lat": -4.7829999924,
      "lon": 14.9169998169,
      "tz": "Africa\/Kinshasa"
  },
  "FZAE": {
      "icao": "FZAE",
      "iata": "",
      "name": "Kimpoko Airport",
      "city": "Kimpoko",
      "state": "Kinshasa",
      "country": "CD",
      "elevation": 1017,
      "lat": -4.2170000076,
      "lon": 15.5670003891,
      "tz": "Africa\/Kinshasa"
  },
  "FZAF": {
      "icao": "FZAF",
      "iata": "",
      "name": "Nsangi Airport",
      "city": "Nsangi",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 2297,
      "lat": -5.5999999046,
      "lon": 15.3170003891,
      "tz": "Africa\/Kinshasa"
  },
  "FZAG": {
      "icao": "FZAG",
      "iata": "MNB",
      "name": "Muanda Airport",
      "city": "",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 89,
      "lat": -5.9308600426,
      "lon": 12.3517999649,
      "tz": "Africa\/Kinshasa"
  },
  "FZAH": {
      "icao": "FZAH",
      "iata": "",
      "name": "Tshela Airport",
      "city": "Tshela",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 361,
      "lat": -4.9829998016,
      "lon": 12.9329996109,
      "tz": "Africa\/Kinshasa"
  },
  "FZAI": {
      "icao": "FZAI",
      "iata": "",
      "name": "Kitona Base Airport",
      "city": "",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 394,
      "lat": -5.9180598259,
      "lon": 12.4476995468,
      "tz": "Africa\/Kinshasa"
  },
  "FZAJ": {
      "icao": "FZAJ",
      "iata": "BOA",
      "name": "Boma Airport",
      "city": "Boma",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 26,
      "lat": -5.8540000916,
      "lon": 13.0640001297,
      "tz": "Africa\/Kinshasa"
  },
  "FZAL": {
      "icao": "FZAL",
      "iata": "LZI",
      "name": "Luozi Airport",
      "city": "Luozi",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 722,
      "lat": -4.9499998093,
      "lon": 14.1330003738,
      "tz": "Africa\/Kinshasa"
  },
  "FZAM": {
      "icao": "FZAM",
      "iata": "MAT",
      "name": "Tshimpi Airport",
      "city": "Matadi",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1115,
      "lat": -5.7996101379,
      "lon": 13.4404001236,
      "tz": "Africa\/Kinshasa"
  },
  "FZAN": {
      "icao": "FZAN",
      "iata": "",
      "name": "Inga Airport",
      "city": "Inga",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 741,
      "lat": -5.5316700935,
      "lon": 13.5794000626,
      "tz": "Africa\/Kinshasa"
  },
  "FZAP": {
      "icao": "FZAP",
      "iata": "",
      "name": "Lukala Airport",
      "city": "Lukala",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1312,
      "lat": -5.5170001984,
      "lon": 14.5,
      "tz": "Africa\/Kinshasa"
  },
  "FZAR": {
      "icao": "FZAR",
      "iata": "NKL",
      "name": "Nkolo Fuma Airport",
      "city": "Nkolo Fuma",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1476,
      "lat": -5.4499998093,
      "lon": 14.8330001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZAS": {
      "icao": "FZAS",
      "iata": "",
      "name": "Inkisi Airport",
      "city": "Inkisi",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1968,
      "lat": -5.1669998169,
      "lon": 15,
      "tz": "Africa\/Kinshasa"
  },
  "FZAU": {
      "icao": "FZAU",
      "iata": "",
      "name": "Konde Airport",
      "city": "Konde",
      "state": "",
      "country": "CD",
      "elevation": 7,
      "lat": -5.75,
      "lon": 12.25,
      "tz": "Africa\/Kinshasa"
  },
  "FZAW": {
      "icao": "FZAW",
      "iata": "",
      "name": "Kwilu-Ngongo Airport",
      "city": "Kwilu-Ngongo",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1296,
      "lat": -5.5,
      "lon": 14.6999998093,
      "tz": "Africa\/Kinshasa"
  },
  "FZAX": {
      "icao": "FZAX",
      "iata": "",
      "name": "Luheki Airport",
      "city": "Luheki",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 984,
      "lat": -4.8499999046,
      "lon": 13.7670001984,
      "tz": "Africa\/Kinshasa"
  },
  "FZAY": {
      "icao": "FZAY",
      "iata": "",
      "name": "Mvula Sanda Airport",
      "city": "Mvula Sanda",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1148,
      "lat": -5.632999897,
      "lon": 13.4169998169,
      "tz": "Africa\/Kinshasa"
  },
  "FZBA": {
      "icao": "FZBA",
      "iata": "INO",
      "name": "Inongo Airport",
      "city": "Inongo",
      "state": "",
      "country": "CD",
      "elevation": 1040,
      "lat": -1.9472199678,
      "lon": 18.2858009338,
      "tz": "Africa\/Kinshasa"
  },
  "FZBB": {
      "icao": "FZBB",
      "iata": "",
      "name": "Bongimba Airport",
      "city": "Bongimba",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1476,
      "lat": -3.382999897,
      "lon": 20,
      "tz": "Africa\/Kinshasa"
  },
  "FZBC": {
      "icao": "FZBC",
      "iata": "",
      "name": "Bikoro Airport",
      "city": "Bikoro",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1312,
      "lat": -0.7329999804,
      "lon": 18.1329994202,
      "tz": "Africa\/Kinshasa"
  },
  "FZBD": {
      "icao": "FZBD",
      "iata": "",
      "name": "Oshwe Airport",
      "city": "Oshwe",
      "state": "",
      "country": "CD",
      "elevation": 1542,
      "lat": -3.382999897,
      "lon": 19.5,
      "tz": "Africa\/Kinshasa"
  },
  "FZBE": {
      "icao": "FZBE",
      "iata": "",
      "name": "Beno Airport",
      "city": "Beno",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1345,
      "lat": -3.5999999046,
      "lon": 17.783000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZBF": {
      "icao": "FZBF",
      "iata": "",
      "name": "Bonkita Airport",
      "city": "Bonkita",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 931,
      "lat": -3.0999999046,
      "lon": 18.7329998016,
      "tz": "Africa\/Kinshasa"
  },
  "FZBG": {
      "icao": "FZBG",
      "iata": "",
      "name": "Kempa Airport",
      "city": "Kempa",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1148,
      "lat": -2.9330000877,
      "lon": 18.3999996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZBH": {
      "icao": "FZBH",
      "iata": "",
      "name": "Isongo Airport",
      "city": "Isongo",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1020,
      "lat": -1.3999999762,
      "lon": 18.3999996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZBI": {
      "icao": "FZBI",
      "iata": "NIO",
      "name": "Nioki Airport",
      "city": "Nioki",
      "state": "",
      "country": "CD",
      "elevation": 1043,
      "lat": -2.7174999714,
      "lon": 17.6847000122,
      "tz": "Africa\/Kinshasa"
  },
  "FZBJ": {
      "icao": "FZBJ",
      "iata": "",
      "name": "Mushie Airport",
      "city": "Mushie",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1214,
      "lat": -3,
      "lon": 16.9330005646,
      "tz": "Africa\/Kinshasa"
  },
  "FZBK": {
      "icao": "FZBK",
      "iata": "",
      "name": "Boshwe Airport",
      "city": "Boshwe",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1181,
      "lat": -3.0666599274,
      "lon": 18.6417007446,
      "tz": "Africa\/Kinshasa"
  },
  "FZBL": {
      "icao": "FZBL",
      "iata": "",
      "name": "Djokele Airport",
      "city": "Djokele",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1007,
      "lat": -3.0499999523,
      "lon": 17.1000003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZBN": {
      "icao": "FZBN",
      "iata": "",
      "name": "Malebo Airport",
      "city": "Malebo",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1411,
      "lat": -2.4670000076,
      "lon": 16.5499992371,
      "tz": "Africa\/Kinshasa"
  },
  "FZBO": {
      "icao": "FZBO",
      "iata": "FDU",
      "name": "Bandundu Airport",
      "city": "",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1063,
      "lat": -3.3113200665,
      "lon": 17.3817005157,
      "tz": "Africa\/Kinshasa"
  },
  "FZBP": {
      "icao": "FZBP",
      "iata": "",
      "name": "Bolongonkele Airport",
      "city": "Bolongonkele",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1158,
      "lat": -2.7999899387,
      "lon": 19.9083003998,
      "tz": "Africa\/Kinshasa"
  },
  "FZBQ": {
      "icao": "FZBQ",
      "iata": "",
      "name": "Bindja Airport",
      "city": "Bindja",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1201,
      "lat": -3.3833301067,
      "lon": 19.6597003937,
      "tz": "Africa\/Kinshasa"
  },
  "FZBS": {
      "icao": "FZBS",
      "iata": "",
      "name": "Semendua Airport",
      "city": "Semendua",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1148,
      "lat": -3.1830000877,
      "lon": 18.0830001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZBT": {
      "icao": "FZBT",
      "iata": "KRZ",
      "name": "Basango Mboliasa Airport",
      "city": "Kiri",
      "state": "",
      "country": "CD",
      "elevation": 1013,
      "lat": -1.4349999428,
      "lon": 19.0240001678,
      "tz": "Africa\/Kinshasa"
  },
  "FZBU": {
      "icao": "FZBU",
      "iata": "",
      "name": "Ipeke Airport",
      "city": "Ipeke",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1017,
      "lat": -2.4830000401,
      "lon": 18.25,
      "tz": "Africa\/Kinshasa"
  },
  "FZBV": {
      "icao": "FZBV",
      "iata": "",
      "name": "Kempile Airport",
      "city": "Kempile",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 984,
      "lat": -2.7170000076,
      "lon": 18.1329994202,
      "tz": "Africa\/Kinshasa"
  },
  "FZBW": {
      "icao": "FZBW",
      "iata": "",
      "name": "Basengele Airport",
      "city": "Basengele",
      "state": "Mai-Ndombe",
      "country": "CD",
      "elevation": 1309,
      "lat": -1.9170000553,
      "lon": 17.9169998169,
      "tz": "Africa\/Kinshasa"
  },
  "FZCA": {
      "icao": "FZCA",
      "iata": "KKW",
      "name": "Kikwit Airport",
      "city": "",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1572,
      "lat": -5.0357699394,
      "lon": 18.7856006622,
      "tz": "Africa\/Kinshasa"
  },
  "FZCB": {
      "icao": "FZCB",
      "iata": "IDF",
      "name": "Idiofa Airport",
      "city": "Idiofa",
      "state": "",
      "country": "CD",
      "elevation": 2299,
      "lat": -5,
      "lon": 19.6000003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZCD": {
      "icao": "FZCD",
      "iata": "",
      "name": "Vanga Airport",
      "city": "Vanga",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1312,
      "lat": -4.4000000954,
      "lon": 18.466999054,
      "tz": "Africa\/Kinshasa"
  },
  "FZCE": {
      "icao": "FZCE",
      "iata": "LUS",
      "name": "Lusanga Airport",
      "city": "Lusanga",
      "state": "",
      "country": "CD",
      "elevation": 1365,
      "lat": -4.8000001907,
      "lon": 18.716999054,
      "tz": "Africa\/Kinshasa"
  },
  "FZCF": {
      "icao": "FZCF",
      "iata": "",
      "name": "Kahemba Airport",
      "city": "Kahemba",
      "state": "",
      "country": "CD",
      "elevation": 3425,
      "lat": -7.3330001831,
      "lon": 19.0170001984,
      "tz": "Africa\/Kinshasa"
  },
  "FZCI": {
      "icao": "FZCI",
      "iata": "",
      "name": "Banga Airport",
      "city": "Banga",
      "state": "",
      "country": "CD",
      "elevation": 2493,
      "lat": -5.4499998093,
      "lon": 20.4500007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZCK": {
      "icao": "FZCK",
      "iata": "",
      "name": "Kajiji Airport",
      "city": "Kajiji",
      "state": "Kwango",
      "country": "CD",
      "elevation": 3510,
      "lat": -7.617000103,
      "lon": 18.5499992371,
      "tz": "Africa\/Kinshasa"
  },
  "FZCL": {
      "icao": "FZCL",
      "iata": "",
      "name": "Banza Lute Airport",
      "city": "Banza Lute",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1476,
      "lat": -4.1999998093,
      "lon": 17.8330001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZCM": {
      "icao": "FZCM",
      "iata": "",
      "name": "Mangai Ii Airport",
      "city": "Mangai Ii",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1410,
      "lat": -4.0830001831,
      "lon": 19.5,
      "tz": "Africa\/Kinshasa"
  },
  "FZCO": {
      "icao": "FZCO",
      "iata": "",
      "name": "Boko Airport",
      "city": "Boko",
      "state": "Kwango",
      "country": "CD",
      "elevation": 2297,
      "lat": -5.3000001907,
      "lon": 16.8500003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZCP": {
      "icao": "FZCP",
      "iata": "",
      "name": "Popokabaka Airport",
      "city": "Popokabaka",
      "state": "",
      "country": "CD",
      "elevation": 1575,
      "lat": -5.6999998093,
      "lon": 16.5830001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZCR": {
      "icao": "FZCR",
      "iata": "",
      "name": "Busala Airport",
      "city": "Busala",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1312,
      "lat": -4.132999897,
      "lon": 18.716999054,
      "tz": "Africa\/Kinshasa"
  },
  "FZCS": {
      "icao": "FZCS",
      "iata": "",
      "name": "Kenge Airport",
      "city": "Kenge",
      "state": "Kwango",
      "country": "CD",
      "elevation": 1808,
      "lat": -4.8388900757,
      "lon": 17.0291996002,
      "tz": "Africa\/Kinshasa"
  },
  "FZCT": {
      "icao": "FZCT",
      "iata": "",
      "name": "Fatundu Airport",
      "city": "Fatundu",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1526,
      "lat": -4.132999897,
      "lon": 17.2999992371,
      "tz": "Africa\/Kinshasa"
  },
  "FZCU": {
      "icao": "FZCU",
      "iata": "",
      "name": "Ito Airport",
      "city": "Ito",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1148,
      "lat": -3.3329999447,
      "lon": 17.466999054,
      "tz": "Africa\/Kinshasa"
  },
  "FZCV": {
      "icao": "FZCV",
      "iata": "MSM",
      "name": "Masi Manimba Airport",
      "city": "Masi Manimba",
      "state": "",
      "country": "CD",
      "elevation": 1952,
      "lat": -4.7829999924,
      "lon": 17.8500003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZCW": {
      "icao": "FZCW",
      "iata": "",
      "name": "Kikongo Sur Wamba Airport",
      "city": "Kikongo Sur Wamba",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1312,
      "lat": -4.2670001984,
      "lon": 17.2670001984,
      "tz": "Africa\/Kinshasa"
  },
  "FZCX": {
      "icao": "FZCX",
      "iata": "",
      "name": "Kimafu Airport",
      "city": "Kimafu",
      "state": "Kwango",
      "country": "CD",
      "elevation": 1312,
      "lat": -4.5999999046,
      "lon": 17.5830001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZCY": {
      "icao": "FZCY",
      "iata": "",
      "name": "Yuki Airport",
      "city": "Yuki",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1398,
      "lat": -4.0830001831,
      "lon": 19.4330005646,
      "tz": "Africa\/Kinshasa"
  },
  "FZDA": {
      "icao": "FZDA",
      "iata": "",
      "name": "Malanga Airport",
      "city": "Malanga",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 1247,
      "lat": -5.5329999924,
      "lon": 14.8500003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZDB": {
      "icao": "FZDB",
      "iata": "",
      "name": "Kimbau Airport",
      "city": "Kimbau",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1640,
      "lat": -5.617000103,
      "lon": 17.6000003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZDD": {
      "icao": "FZDD",
      "iata": "",
      "name": "Wamba Luadi Airport",
      "city": "Wamba Luadi",
      "state": "Kwango",
      "country": "CD",
      "elevation": 2297,
      "lat": -6.5500001907,
      "lon": 17.3999996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZDE": {
      "icao": "FZDE",
      "iata": "",
      "name": "Tono Airport",
      "city": "Tono",
      "state": "Kwango",
      "country": "CD",
      "elevation": 2461,
      "lat": -6.5500001907,
      "lon": 18.2000007629,
      "tz": "Africa\/Kinshasa"
  },
  "FZDF": {
      "icao": "FZDF",
      "iata": "",
      "name": "Nzamba Airport",
      "city": "Nzamba",
      "state": "Kwango",
      "country": "CD",
      "elevation": 2953,
      "lat": -6.8330001831,
      "lon": 17.6669998169,
      "tz": "Africa\/Kinshasa"
  },
  "FZDG": {
      "icao": "FZDG",
      "iata": "",
      "name": "Nyanga Airport",
      "city": "Nyanga",
      "state": "Kasai",
      "country": "CD",
      "elevation": 2231,
      "lat": -5.9670000076,
      "lon": 20.4169998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZDH": {
      "icao": "FZDH",
      "iata": "",
      "name": "Ngi Airport",
      "city": "Ngi",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1509,
      "lat": -4.4169998169,
      "lon": 17.1669998169,
      "tz": "Africa\/Kinshasa"
  },
  "FZDJ": {
      "icao": "FZDJ",
      "iata": "",
      "name": "Mutena Airport",
      "city": "Mutena",
      "state": "Kasai",
      "country": "CD",
      "elevation": 2395,
      "lat": -6.7670001984,
      "lon": 21.1499996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZDK": {
      "icao": "FZDK",
      "iata": "",
      "name": "Kipata Katika Airport",
      "city": "Kipata Katika",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 2133,
      "lat": -5.0329999924,
      "lon": 17.6499996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZDL": {
      "icao": "FZDL",
      "iata": "",
      "name": "Kolokoso Airport",
      "city": "Kolokoso",
      "state": "Kwango",
      "country": "CD",
      "elevation": 1312,
      "lat": -4.4499998093,
      "lon": 17.4169998169,
      "tz": "Africa\/Kinshasa"
  },
  "FZDM": {
      "icao": "FZDM",
      "iata": "",
      "name": "Masamuna Airport",
      "city": "Masamuna",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1476,
      "lat": -4.8000001907,
      "lon": 17.5830001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZDN": {
      "icao": "FZDN",
      "iata": "",
      "name": "Mongo Wa Kenda Airport",
      "city": "Mongo Wa Kenda",
      "state": "Kwango",
      "country": "CD",
      "elevation": 1804,
      "lat": -6.9000000954,
      "lon": 16.9500007629,
      "tz": "Africa\/Kinshasa"
  },
  "FZDO": {
      "icao": "FZDO",
      "iata": "",
      "name": "Moanza Airport",
      "city": "Moanza",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 2297,
      "lat": -5.5329999924,
      "lon": 17.6170005798,
      "tz": "Africa\/Kinshasa"
  },
  "FZDP": {
      "icao": "FZDP",
      "iata": "",
      "name": "Mukedi Airport",
      "city": "Mukedi",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1804,
      "lat": -5.6999998093,
      "lon": 19.7670001984,
      "tz": "Africa\/Kinshasa"
  },
  "FZDQ": {
      "icao": "FZDQ",
      "iata": "",
      "name": "Mazelele Airport",
      "city": "Mazelele",
      "state": "Kwango",
      "country": "CD",
      "elevation": 1650,
      "lat": -7.2829999924,
      "lon": 17.033000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZDR": {
      "icao": "FZDR",
      "iata": "",
      "name": "Bokela Airport",
      "city": "Bokela",
      "state": "Tshuapa",
      "country": "CD",
      "elevation": 1210,
      "lat": -1.1499999762,
      "lon": 21.8999996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZDS": {
      "icao": "FZDS",
      "iata": "",
      "name": "Yasa Bongo Airport",
      "city": "Yasa Bongo",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 2008,
      "lat": -4.4499998093,
      "lon": 17.783000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZDT": {
      "icao": "FZDT",
      "iata": "",
      "name": "Matari Airport",
      "city": "Matari",
      "state": "Kwango",
      "country": "CD",
      "elevation": 2559,
      "lat": -6.1999998093,
      "lon": 17.6499996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZDU": {
      "icao": "FZDU",
      "iata": "",
      "name": "Kimpangu Airport",
      "city": "Kimpangu",
      "state": "Bas-Congo",
      "country": "CD",
      "elevation": 2133,
      "lat": -5.8330001831,
      "lon": 15,
      "tz": "Africa\/Kinshasa"
  },
  "FZDY": {
      "icao": "FZDY",
      "iata": "",
      "name": "Missayi Airport",
      "city": "Missayi",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1280,
      "lat": -3.882999897,
      "lon": 17.3500003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZEA": {
      "icao": "FZEA",
      "iata": "MDK",
      "name": "Mbandaka Airport",
      "city": "Mbandaka",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1040,
      "lat": 0.0226000007,
      "lon": 18.2887001038,
      "tz": "Africa\/Kinshasa"
  },
  "FZEB": {
      "icao": "FZEB",
      "iata": "",
      "name": "Monieka Airport",
      "city": "Monieka",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1253,
      "lat": -0.0670000017,
      "lon": 19.9829998016,
      "tz": "Africa\/Kinshasa"
  },
  "FZEI": {
      "icao": "FZEI",
      "iata": "",
      "name": "Ingende Airport",
      "city": "Ingende",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1246,
      "lat": 0.25,
      "lon": 18.9330005646,
      "tz": "Africa\/Kinshasa"
  },
  "FZEM": {
      "icao": "FZEM",
      "iata": "",
      "name": "Yembe Moke Airport",
      "city": "Yembe Moke",
      "state": "Kwilu",
      "country": "CD",
      "elevation": 1220,
      "lat": -4.6830000877,
      "lon": 18.216999054,
      "tz": "Africa\/Kinshasa"
  },
  "FZEN": {
      "icao": "FZEN",
      "iata": "BSU",
      "name": "Basankusu Airport",
      "city": "Basankusu",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1217,
      "lat": 1.2247200012,
      "lon": 19.7889003754,
      "tz": "Africa\/Kinshasa"
  },
  "FZEO": {
      "icao": "FZEO",
      "iata": "",
      "name": "Beongo Airport",
      "city": "Beongo",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1279,
      "lat": 1.0169999599,
      "lon": 20.6000003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZEP": {
      "icao": "FZEP",
      "iata": "",
      "name": "Mentole Airport",
      "city": "Mentole",
      "state": "Mongala",
      "country": "CD",
      "elevation": 1295,
      "lat": 1.3170000315,
      "lon": 20.7000007629,
      "tz": "Africa\/Kinshasa"
  },
  "FZER": {
      "icao": "FZER",
      "iata": "",
      "name": "Kodoro Airport",
      "city": "Kodoro",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1312,
      "lat": 1.2829999924,
      "lon": 20.3330001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZES": {
      "icao": "FZES",
      "iata": "",
      "name": "Ngumu Airport",
      "city": "Ngumu",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1227,
      "lat": 1.4670000076,
      "lon": 20.2999992371,
      "tz": "Africa\/Kinshasa"
  },
  "FZFA": {
      "icao": "FZFA",
      "iata": "LIE",
      "name": "Libenge Airport",
      "city": "Libenge",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1125,
      "lat": 3.632999897,
      "lon": 18.6329994202,
      "tz": "Africa\/Kinshasa"
  },
  "FZFB": {
      "icao": "FZFB",
      "iata": "",
      "name": "Imesse Airport",
      "city": "Imesse",
      "state": "Sud-Ubangi",
      "country": "CD",
      "elevation": 1110,
      "lat": 2.5169999599,
      "lon": 18.283000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZFC": {
      "icao": "FZFC",
      "iata": "",
      "name": "Engengele Airport",
      "city": "Engengele",
      "state": "Mongala",
      "country": "CD",
      "elevation": 1279,
      "lat": 2.0999999046,
      "lon": 22.6972007751,
      "tz": "Africa\/Kinshasa"
  },
  "FZFD": {
      "icao": "FZFD",
      "iata": "BDT",
      "name": "Gbadolite Airport",
      "city": "",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1509,
      "lat": 4.2532100677,
      "lon": 20.9752998352,
      "tz": "Africa\/Kinshasa"
  },
  "FZFE": {
      "icao": "FZFE",
      "iata": "",
      "name": "Abumumbazi Airport",
      "city": "Abumumbazi",
      "state": "Nord-Ubangi",
      "country": "CD",
      "elevation": 1499,
      "lat": 3.6830000877,
      "lon": 22.1499996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZFF": {
      "icao": "FZFF",
      "iata": "",
      "name": "Bau Airport",
      "city": "Bau",
      "state": "Sud-Ubangi",
      "country": "CD",
      "elevation": 1640,
      "lat": 3.7330000401,
      "lon": 19.0830001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZFG": {
      "icao": "FZFG",
      "iata": "",
      "name": "Bokada Airport",
      "city": "Bokada",
      "state": "Nord-Ubangi",
      "country": "CD",
      "elevation": 1647,
      "lat": 4.117000103,
      "lon": 19.4169998169,
      "tz": "Africa\/Kinshasa"
  },
  "FZFH": {
      "icao": "FZFH",
      "iata": "",
      "name": "Mokaria-Yamoleka Airport",
      "city": "Mokaria-Yamoleka",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 1378,
      "lat": 2.117000103,
      "lon": 23.283000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZFJ": {
      "icao": "FZFJ",
      "iata": "",
      "name": "Goyongo Airport",
      "city": "Goyongo",
      "state": "Nord-Ubangi",
      "country": "CD",
      "elevation": 1640,
      "lat": 4.1830000877,
      "lon": 19.783000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZFK": {
      "icao": "FZFK",
      "iata": "GMA",
      "name": "Gemena Airport",
      "city": "Gemena",
      "state": "Sud-Ubangi",
      "country": "CD",
      "elevation": 1378,
      "lat": 3.2353699207,
      "lon": 19.7712993622,
      "tz": "Africa\/Kinshasa"
  },
  "FZFL": {
      "icao": "FZFL",
      "iata": "",
      "name": "Kala Airport",
      "city": "Kala",
      "state": "Sud-Ubangi",
      "country": "CD",
      "elevation": 1640,
      "lat": 3.383611111,
      "lon": 18.654722222,
      "tz": "Africa\/Brazzaville"
  },
  "FZFN": {
      "icao": "FZFN",
      "iata": "",
      "name": "Lombo Airport",
      "city": "Lombo",
      "state": "Equateur",
      "country": "CD",
      "elevation": 2331,
      "lat": 4.4388899803,
      "lon": 19.5389003754,
      "tz": "Africa\/Kinshasa"
  },
  "FZFP": {
      "icao": "FZFP",
      "iata": "KLI",
      "name": "Kotakoli Airport",
      "city": "",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1801,
      "lat": 4.1576399803,
      "lon": 21.6508998871,
      "tz": "Africa\/Kinshasa"
  },
  "FZFQ": {
      "icao": "FZFQ",
      "iata": "",
      "name": "Mpaka Airport",
      "city": "Mpaka",
      "state": "Sud-Ubangi",
      "country": "CD",
      "elevation": 1969,
      "lat": 4.117000103,
      "lon": 19.216999054,
      "tz": "Africa\/Kinshasa"
  },
  "FZFR": {
      "icao": "FZFR",
      "iata": "",
      "name": "Mombongo Airport",
      "city": "Mombongo",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 1476,
      "lat": 1.6499999762,
      "lon": 23.1499996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZFS": {
      "icao": "FZFS",
      "iata": "",
      "name": "Karawa Airport",
      "city": "Karawa",
      "state": "Nord-Ubangi",
      "country": "CD",
      "elevation": 1640,
      "lat": 3.367000103,
      "lon": 20.2999992371,
      "tz": "Africa\/Kinshasa"
  },
  "FZFT": {
      "icao": "FZFT",
      "iata": "",
      "name": "Tandala Airport",
      "city": "Tandala",
      "state": "Sud-Ubangi",
      "country": "CD",
      "elevation": 1640,
      "lat": 2.9775845186,
      "lon": 19.3514084816,
      "tz": "Africa\/Kinshasa"
  },
  "FZFU": {
      "icao": "FZFU",
      "iata": "BMB",
      "name": "Bumbar Airport",
      "city": "Bumbar",
      "state": "Equateur",
      "country": "CD",
      "elevation": 0,
      "lat": 2.1827800274,
      "lon": 22.4817008972,
      "tz": "Africa\/Kinshasa"
  },
  "FZFV": {
      "icao": "FZFV",
      "iata": "",
      "name": "Gbado Airport",
      "city": "Gbado",
      "state": "Nord-Ubangi",
      "country": "CD",
      "elevation": 1476,
      "lat": 3.882999897,
      "lon": 20.783000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZFW": {
      "icao": "FZFW",
      "iata": "",
      "name": "Gwaka Airport",
      "city": "Gwaka",
      "state": "Sud-Ubangi",
      "country": "CD",
      "elevation": 1476,
      "lat": 2.4670000076,
      "lon": 20.1000003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZGA": {
      "icao": "FZGA",
      "iata": "LIQ",
      "name": "Lisala Airport",
      "city": "",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1509,
      "lat": 2.1706600189,
      "lon": 21.4969005585,
      "tz": "Africa\/Kinshasa"
  },
  "FZGB": {
      "icao": "FZGB",
      "iata": "",
      "name": "Bosondjo Airport",
      "city": "Bosondjo",
      "state": "Mongala",
      "country": "CD",
      "elevation": 1312,
      "lat": 1.8669999838,
      "lon": 21.783000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZGC": {
      "icao": "FZGC",
      "iata": "",
      "name": "Bolila Airport",
      "city": "Bolila",
      "state": "",
      "country": "CD",
      "elevation": 1279,
      "lat": 1.8500000238,
      "lon": 23.1170005798,
      "tz": "Africa\/Lubumbashi"
  },
  "FZGE": {
      "icao": "FZGE",
      "iata": "",
      "name": "Binga Airport",
      "city": "Binga",
      "state": "Mongala",
      "country": "CD",
      "elevation": 1476,
      "lat": 2.4330000877,
      "lon": 20.5,
      "tz": "Africa\/Kinshasa"
  },
  "FZGF": {
      "icao": "FZGF",
      "iata": "",
      "name": "Bokungu Airport",
      "city": "Bokungu",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1214,
      "lat": -0.6830000281,
      "lon": 22.3330001831,
      "tz": "Africa\/Kinshasa"
  },
  "FZGG": {
      "icao": "FZGG",
      "iata": "",
      "name": "Mondombe Airport",
      "city": "Mondombe",
      "state": "Tshuapa",
      "country": "CD",
      "elevation": 1457,
      "lat": -0.8830000162,
      "lon": 22.8169994354,
      "tz": "Africa\/Kinshasa"
  },
  "FZGH": {
      "icao": "FZGH",
      "iata": "",
      "name": "Wema Airport",
      "city": "Wema",
      "state": "Tshuapa",
      "country": "CD",
      "elevation": 1368,
      "lat": -0.4329999983,
      "lon": 21.6499996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZGI": {
      "icao": "FZGI",
      "iata": "",
      "name": "Yalingimba Airport",
      "city": "Yalingimba",
      "state": "Mongala",
      "country": "CD",
      "elevation": 1427,
      "lat": 2.2829999924,
      "lon": 22.8500003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZGN": {
      "icao": "FZGN",
      "iata": "BNB",
      "name": "Boende Airport",
      "city": "Boende",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1168,
      "lat": -0.2169999927,
      "lon": 20.8500003815,
      "tz": "Africa\/Kinshasa"
  },
  "FZGT": {
      "icao": "FZGT",
      "iata": "",
      "name": "Boteka Airport",
      "city": "Boteka",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1247,
      "lat": -0.3170000017,
      "lon": 19.0669994354,
      "tz": "Africa\/Kinshasa"
  },
  "FZGV": {
      "icao": "FZGV",
      "iata": "IKL",
      "name": "Ikela Airport",
      "city": "Ikela",
      "state": "Equateur",
      "country": "CD",
      "elevation": 1283,
      "lat": -1.2000000477,
      "lon": 23.283000946,
      "tz": "Africa\/Kinshasa"
  },
  "FZGX": {
      "icao": "FZGX",
      "iata": "",
      "name": "Monkoto Airport",
      "city": "Monkoto",
      "state": "Tshuapa",
      "country": "CD",
      "elevation": 1282,
      "lat": -1.6000000238,
      "lon": 20.6499996185,
      "tz": "Africa\/Kinshasa"
  },
  "FZGY": {
      "icao": "FZGY",
      "iata": "",
      "name": "Yemo Airport",
      "city": "Yemo",
      "state": "Tshuapa",
      "country": "CD",
      "elevation": 1525,
      "lat": -0.4670000076,
      "lon": 21.9330005646,
      "tz": "Africa\/Kinshasa"
  },
  "FZIA": {
      "icao": "FZIA",
      "iata": "",
      "name": "Kisangani Simisini Airport",
      "city": "",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 1289,
      "lat": 0.5174999833,
      "lon": 25.1550006866,
      "tz": "Africa\/Lubumbashi"
  },
  "FZIC": {
      "icao": "FZIC",
      "iata": "FKI",
      "name": "Bangoka International Airport",
      "city": "Kisangani",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 1417,
      "lat": 0.4816389978,
      "lon": 25.3379993439,
      "tz": "Africa\/Lubumbashi"
  },
  "FZIG": {
      "icao": "FZIG",
      "iata": "",
      "name": "KM 95 CFL Airport",
      "city": "KM 95 CFL",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 1591,
      "lat": -0.150000006,
      "lon": 25.3500003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZIK": {
      "icao": "FZIK",
      "iata": "",
      "name": "Katende Airport",
      "city": "Katende",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 1476,
      "lat": 0.3330000043,
      "lon": 25.5,
      "tz": "Africa\/Lubumbashi"
  },
  "FZIR": {
      "icao": "FZIR",
      "iata": "YAN",
      "name": "Yangambi Airport",
      "city": "Yangambi",
      "state": "",
      "country": "CD",
      "elevation": 1378,
      "lat": 0.7829999924,
      "lon": 24.466999054,
      "tz": "Africa\/Lubumbashi"
  },
  "FZIZ": {
      "icao": "FZIZ",
      "iata": "",
      "name": "Lokutu Airport",
      "city": "Lokutu",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 1214,
      "lat": 1.1169999838,
      "lon": 23.6170005798,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJB": {
      "icao": "FZJB",
      "iata": "",
      "name": "Doko Airport",
      "city": "Doko",
      "state": "Haut-Uele",
      "country": "CD",
      "elevation": 2874,
      "lat": 3.2330000401,
      "lon": 29.5669994354,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJC": {
      "icao": "FZJC",
      "iata": "",
      "name": "Dungu-Uye Airport",
      "city": "Dungu-Uye",
      "state": "Haut-Uele",
      "country": "CD",
      "elevation": 2378,
      "lat": 3.5999999046,
      "lon": 28.8999996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJD": {
      "icao": "FZJD",
      "iata": "",
      "name": "Doruma Airport",
      "city": "Doruma",
      "state": "Haut-Uele",
      "country": "CD",
      "elevation": 2378,
      "lat": 4.7329998016,
      "lon": 27.6830005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJF": {
      "icao": "FZJF",
      "iata": "",
      "name": "Aba Airport",
      "city": "Aba",
      "state": "Haut-Uele",
      "country": "CD",
      "elevation": 3051,
      "lat": 3.9000000954,
      "lon": 30.25,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJH": {
      "icao": "FZJH",
      "iata": "IRP",
      "name": "Matari Airport",
      "city": "",
      "state": "",
      "country": "CD",
      "elevation": 2438,
      "lat": 2.8276100159,
      "lon": 27.588300705,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJI": {
      "icao": "FZJI",
      "iata": "",
      "name": "Watsa Airport",
      "city": "Watsa",
      "state": "Haut-Uele",
      "country": "CD",
      "elevation": 3199,
      "lat": 3.0169401169,
      "lon": 29.5592002869,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJK": {
      "icao": "FZJK",
      "iata": "",
      "name": "Faradje Airport",
      "city": "Faradje",
      "state": "Haut-Uele",
      "country": "CD",
      "elevation": 2690,
      "lat": 3.7330000401,
      "lon": 29.7000007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJN": {
      "icao": "FZJN",
      "iata": "",
      "name": "Luniemu Airport",
      "city": "Luniemu",
      "state": "Haut-Lomani",
      "country": "CD",
      "elevation": 3272,
      "lat": -8.1999998093,
      "lon": 24.7329998016,
      "tz": "Africa\/Lubumbashi"
  },
  "FZJR": {
      "icao": "FZJR",
      "iata": "",
      "name": "Kere Kere Airport",
      "city": "Kere Kere",
      "state": "Ituri",
      "country": "CD",
      "elevation": 4429,
      "lat": 2.7330000401,
      "lon": 30.533000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKA": {
      "icao": "FZKA",
      "iata": "BUX",
      "name": "Bunia Airport",
      "city": "",
      "state": "",
      "country": "CD",
      "elevation": 4045,
      "lat": 1.5657199621,
      "lon": 30.2208003998,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKB": {
      "icao": "FZKB",
      "iata": "",
      "name": "Bambili-Dingila Airport",
      "city": "Bambili-Dingila",
      "state": "Bas-Uele",
      "country": "CD",
      "elevation": 2050,
      "lat": 3.6500000954,
      "lon": 26.1170005798,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKC": {
      "icao": "FZKC",
      "iata": "",
      "name": "Mahagi Airport",
      "city": "Mahagi",
      "state": "Ituri",
      "country": "CD",
      "elevation": 2555,
      "lat": 2.1670000553,
      "lon": 31.1499996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKF": {
      "icao": "FZKF",
      "iata": "",
      "name": "Kilomines Airport",
      "city": "Kilomines",
      "state": "Ituri",
      "country": "CD",
      "elevation": 4593,
      "lat": 1.8170000315,
      "lon": 30.2329998016,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKI": {
      "icao": "FZKI",
      "iata": "",
      "name": "Yedi Airport",
      "city": "Yedi",
      "state": "Tshopo",
      "country": "CD",
      "elevation": 3642,
      "lat": 2.0329999924,
      "lon": 24.7999992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKJ": {
      "icao": "FZKJ",
      "iata": "BZU",
      "name": "Buta Zega Airport",
      "city": "",
      "state": "",
      "country": "CD",
      "elevation": 1378,
      "lat": 2.8183500767,
      "lon": 24.7936992645,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKN": {
      "icao": "FZKN",
      "iata": "",
      "name": "Aketi Airport",
      "city": "Aketi",
      "state": "Bas-Uele",
      "country": "CD",
      "elevation": 1230,
      "lat": 2.7000000477,
      "lon": 23.8330001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZKO": {
      "icao": "FZKO",
      "iata": "",
      "name": "Ango Airport",
      "city": "Ango",
      "state": "",
      "country": "CD",
      "elevation": 2133,
      "lat": 4.0289998055,
      "lon": 25.8619995117,
      "tz": "Africa\/Lubumbashi"
  },
  "FZMA": {
      "icao": "FZMA",
      "iata": "BKY",
      "name": "Bukavu Kavumu Airport",
      "city": "",
      "state": "",
      "country": "CD",
      "elevation": 5643,
      "lat": -2.3089799881,
      "lon": 28.8087997437,
      "tz": "Africa\/Lubumbashi"
  },
  "FZMB": {
      "icao": "FZMB",
      "iata": "RUE",
      "name": "Rughenda Airfield",
      "city": "Butembo",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 5757,
      "lat": 0.117142,
      "lon": 29.312992,
      "tz": "Africa\/Lubumbashi"
  },
  "FZMC": {
      "icao": "FZMC",
      "iata": "",
      "name": "Mulungu Airport",
      "city": "Mulungu",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 2400,
      "lat": -2.9830000401,
      "lon": 27.8500003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZMD": {
      "icao": "FZMD",
      "iata": "",
      "name": "Nzovu Airport",
      "city": "Nzovu",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 1970,
      "lat": -2.5829999447,
      "lon": 27.9829998016,
      "tz": "Africa\/Lubumbashi"
  },
  "FZMK": {
      "icao": "FZMK",
      "iata": "",
      "name": "Bulongo Kigogo Airport",
      "city": "Bulongo Kigogo",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 5249,
      "lat": -2.6670000553,
      "lon": 28.7999992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZMP": {
      "icao": "FZMP",
      "iata": "",
      "name": "Kimano Ii Airport",
      "city": "Kimano Ii",
      "state": "Maniema",
      "country": "CD",
      "elevation": 2461,
      "lat": -4.382999897,
      "lon": 28.3500003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZMW": {
      "icao": "FZMW",
      "iata": "",
      "name": "Shabunda Airport",
      "city": "Shabunda",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 1837,
      "lat": -2.6830000877,
      "lon": 27.3330001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZNA": {
      "icao": "FZNA",
      "iata": "GOM",
      "name": "Goma International Airport",
      "city": "Goma",
      "state": "",
      "country": "CD",
      "elevation": 5089,
      "lat": -1.6708099842,
      "lon": 29.2385005951,
      "tz": "Africa\/Kigali"
  },
  "FZNB": {
      "icao": "FZNB",
      "iata": "",
      "name": "Katale Airport",
      "city": "Katale",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 4589,
      "lat": -1.3047200441,
      "lon": 29.3738994598,
      "tz": "Africa\/Lubumbashi"
  },
  "FZNC": {
      "icao": "FZNC",
      "iata": "",
      "name": "Rutshuru Airport",
      "city": "Rutshuru",
      "state": "",
      "country": "CD",
      "elevation": 3707,
      "lat": -1.1670000553,
      "lon": 29.4169998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZNF": {
      "icao": "FZNF",
      "iata": "",
      "name": "Lubero Airport",
      "city": "Lubero",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 5906,
      "lat": -0.1330000013,
      "lon": 29.25,
      "tz": "Africa\/Lubumbashi"
  },
  "FZNI": {
      "icao": "FZNI",
      "iata": "",
      "name": "Ishasha Airport",
      "city": "Ishasha",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 820,
      "lat": -0.75,
      "lon": 29.6329994202,
      "tz": "Africa\/Kampala"
  },
  "FZNK": {
      "icao": "FZNK",
      "iata": "",
      "name": "Katanda Rusthuru Airport",
      "city": "Katanda Rusthuru",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 2297,
      "lat": -0.8000000119,
      "lon": 29.3670005798,
      "tz": "Africa\/Lubumbashi"
  },
  "FZNP": {
      "icao": "FZNP",
      "iata": "BNC",
      "name": "Beni Airport",
      "city": "Beni",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 3517,
      "lat": 0.5749999881,
      "lon": 29.4738998413,
      "tz": "Africa\/Lubumbashi"
  },
  "FZNQ": {
      "icao": "FZNQ",
      "iata": "",
      "name": "Obaye Airport",
      "city": "Obaye",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 2000,
      "lat": -1.3329999447,
      "lon": 27.7329998016,
      "tz": "Africa\/Lubumbashi"
  },
  "FZNR": {
      "icao": "FZNR",
      "iata": "",
      "name": "Rwindi Airport",
      "city": "Rwindi",
      "state": "Nord-Kivu",
      "country": "CD",
      "elevation": 3412,
      "lat": -0.7902780175,
      "lon": 29.2749996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOA": {
      "icao": "FZOA",
      "iata": "KND",
      "name": "Kindu Airport",
      "city": "Kindu",
      "state": "Maniema",
      "country": "CD",
      "elevation": 1630,
      "lat": -2.9191799164,
      "lon": 25.9153995514,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOB": {
      "icao": "FZOB",
      "iata": "",
      "name": "Tingi-Tingi Airport",
      "city": "Tingi-Tingi",
      "state": "Maniema",
      "country": "CD",
      "elevation": 862,
      "lat": -0.7624999881,
      "lon": 26.6096992493,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOC": {
      "icao": "FZOC",
      "iata": "",
      "name": "Kamisuku Airport",
      "city": "Kalima",
      "state": "Maniema",
      "country": "CD",
      "elevation": 0,
      "lat": -2.5499999523,
      "lon": 26.6166667938,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOD": {
      "icao": "FZOD",
      "iata": "KLY",
      "name": "Kinkungwa Airport",
      "city": "Kalima",
      "state": "",
      "country": "CD",
      "elevation": 1808,
      "lat": -2.5780000687,
      "lon": 26.7339992523,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOE": {
      "icao": "FZOE",
      "iata": "",
      "name": "Kampene Airport",
      "city": "Kampene",
      "state": "Maniema",
      "country": "CD",
      "elevation": 2034,
      "lat": -3.5829999447,
      "lon": 26.7000007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOF": {
      "icao": "FZOF",
      "iata": "",
      "name": "Kiapupe Airport",
      "city": "Kiapupe",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 3281,
      "lat": -2.9000000954,
      "lon": 27.2999992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOG": {
      "icao": "FZOG",
      "iata": "",
      "name": "Lulingu Tshionka Airport",
      "city": "Lulingu Tshionka",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 1968,
      "lat": -2.3169999123,
      "lon": 27.5499992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOH": {
      "icao": "FZOH",
      "iata": "",
      "name": "Moga Airport",
      "city": "Moga",
      "state": "Maniema",
      "country": "CD",
      "elevation": 2018,
      "lat": -2.4670000076,
      "lon": 26.7999992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOJ": {
      "icao": "FZOJ",
      "iata": "",
      "name": "Obokote Airport",
      "city": "Obokote",
      "state": "Maniema",
      "country": "CD",
      "elevation": 1378,
      "lat": -0.8500000238,
      "lon": 26.3330001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOK": {
      "icao": "FZOK",
      "iata": "KGN",
      "name": "Kasongo Airport",
      "city": "Kasongo",
      "state": "Maniema",
      "country": "CD",
      "elevation": 1785,
      "lat": -4.5329999924,
      "lon": 26.6170005798,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOO": {
      "icao": "FZOO",
      "iata": "",
      "name": "Kailo Airport",
      "city": "Kailo",
      "state": "Maniema",
      "country": "CD",
      "elevation": 1804,
      "lat": -2.632999897,
      "lon": 26.1000003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOP": {
      "icao": "FZOP",
      "iata": "PUN",
      "name": "Punia Airport",
      "city": "Punia",
      "state": "",
      "country": "CD",
      "elevation": 1742,
      "lat": -1.3669999838,
      "lon": 26.3330001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOQ": {
      "icao": "FZOQ",
      "iata": "",
      "name": "Punia-Basenge Airport",
      "city": "Punia-Basenge",
      "state": "Maniema",
      "country": "CD",
      "elevation": 1738,
      "lat": -1.4670000076,
      "lon": 26.4330005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOR": {
      "icao": "FZOR",
      "iata": "",
      "name": "Saulia Airport",
      "city": "Saulia",
      "state": "Maniema",
      "country": "CD",
      "elevation": 1870,
      "lat": -1.5329999924,
      "lon": 26.533000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOS": {
      "icao": "FZOS",
      "iata": "",
      "name": "Kasese Airport",
      "city": "Kasese",
      "state": "Maniema",
      "country": "CD",
      "elevation": 1863,
      "lat": -1.6330000162,
      "lon": 27.0499992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZOT": {
      "icao": "FZOT",
      "iata": "",
      "name": "Phibraki Airport",
      "city": "Phibraki",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 2100,
      "lat": -2.9330000877,
      "lon": 27.533000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZPB": {
      "icao": "FZPB",
      "iata": "",
      "name": "Kamituga Airport",
      "city": "Kamituga",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 3871,
      "lat": -3.0329999924,
      "lon": 28.1170005798,
      "tz": "Africa\/Lubumbashi"
  },
  "FZPC": {
      "icao": "FZPC",
      "iata": "",
      "name": "Lugushwa Airport",
      "city": "Lugushwa",
      "state": "South-Kivu",
      "country": "CD",
      "elevation": 2300,
      "lat": -3.3169999123,
      "lon": 27.8829994202,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQA": {
      "icao": "FZQA",
      "iata": "FBM",
      "name": "Lubumbashi International Airport",
      "city": "Lubumbashi",
      "state": "",
      "country": "CD",
      "elevation": 4295,
      "lat": -11.5913000107,
      "lon": 27.5308990479,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQC": {
      "icao": "FZQC",
      "iata": "PWO",
      "name": "Pweto Airport",
      "city": "Pweto",
      "state": "",
      "country": "CD",
      "elevation": 3425,
      "lat": -8.4670000076,
      "lon": 28.8829994202,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQD": {
      "icao": "FZQD",
      "iata": "",
      "name": "Mulungwishi Airport",
      "city": "Mulungwishi",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 3500,
      "lat": -10.75,
      "lon": 26.6329994202,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQF": {
      "icao": "FZQF",
      "iata": "",
      "name": "Fungurume Airport",
      "city": "Fungurume",
      "state": "Lualaba",
      "country": "CD",
      "elevation": 3855,
      "lat": -10.5333003998,
      "lon": 26.3250007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQG": {
      "icao": "FZQG",
      "iata": "KEC",
      "name": "Kasenga Airport",
      "city": "Kasenga",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 3146,
      "lat": -10.3500003815,
      "lon": 28.6329994202,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQH": {
      "icao": "FZQH",
      "iata": "",
      "name": "Katwe Airport",
      "city": "Katwe",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 5577,
      "lat": -10.5500001907,
      "lon": 27.8500003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQI": {
      "icao": "FZQI",
      "iata": "",
      "name": "Kamatanda Airport",
      "city": "Kamatanda",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 4261,
      "lat": -10.8330001831,
      "lon": 26.75,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQJ": {
      "icao": "FZQJ",
      "iata": "",
      "name": "Mwadingusha Airport",
      "city": "Mwadingusha",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 3707,
      "lat": -10.75,
      "lon": 27.2000007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQM": {
      "icao": "FZQM",
      "iata": "KWZ",
      "name": "Kolwezi Airport",
      "city": "",
      "state": "Lualaba",
      "country": "CD",
      "elevation": 5007,
      "lat": -10.7658996582,
      "lon": 25.5056991577,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQN": {
      "icao": "FZQN",
      "iata": "",
      "name": "Mutshatsha Airport",
      "city": "Mutshatsha",
      "state": "Lualaba",
      "country": "CD",
      "elevation": 3806,
      "lat": -10.5670003891,
      "lon": 24.3999996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQP": {
      "icao": "FZQP",
      "iata": "",
      "name": "Kisenge Airport",
      "city": "Kisenge",
      "state": "Lualaba",
      "country": "CD",
      "elevation": 3412,
      "lat": -10.6669998169,
      "lon": 23.1669998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQU": {
      "icao": "FZQU",
      "iata": "",
      "name": "Lubudi Airport",
      "city": "Lubudi",
      "state": "",
      "country": "CD",
      "elevation": 4541,
      "lat": -9.9329996109,
      "lon": 26,
      "tz": "Africa\/Lubumbashi"
  },
  "FZQV": {
      "icao": "FZQV",
      "iata": "",
      "name": "Mitwaba Airport",
      "city": "Mitwaba",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 5240,
      "lat": -8.6450004578,
      "lon": 27.3449993134,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRA": {
      "icao": "FZRA",
      "iata": "MNO",
      "name": "Manono Airport",
      "city": "Manono",
      "state": "Tanganika",
      "country": "CD",
      "elevation": 2077,
      "lat": -7.2888898849,
      "lon": 27.3943996429,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRB": {
      "icao": "FZRB",
      "iata": "BDV",
      "name": "Moba Airport",
      "city": "Moba",
      "state": "",
      "country": "CD",
      "elevation": 2953,
      "lat": -7.0669999123,
      "lon": 29.783000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRC": {
      "icao": "FZRC",
      "iata": "",
      "name": "Mukoy Airport",
      "city": "Mukoy",
      "state": "Tanganika",
      "country": "CD",
      "elevation": 5249,
      "lat": -7.5329999924,
      "lon": 28.7000007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRD": {
      "icao": "FZRD",
      "iata": "",
      "name": "Kabombo Airport",
      "city": "Kabombo",
      "state": "Tanganika",
      "country": "CD",
      "elevation": 1969,
      "lat": -7.3499999046,
      "lon": 28.033000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRE": {
      "icao": "FZRE",
      "iata": "",
      "name": "Bukena Airport",
      "city": "Bukena",
      "state": "Haut-Lomani",
      "country": "CD",
      "elevation": 3868,
      "lat": -7.75,
      "lon": 27.2000007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRF": {
      "icao": "FZRF",
      "iata": "FMI",
      "name": "Kalemie Airport",
      "city": "",
      "state": "Tanganika",
      "country": "CD",
      "elevation": 2569,
      "lat": -5.8755598068,
      "lon": 29.25,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRG": {
      "icao": "FZRG",
      "iata": "",
      "name": "Sominka Airport",
      "city": "Sominka",
      "state": "Tanganika",
      "country": "CD",
      "elevation": 2066,
      "lat": -7.4169998169,
      "lon": 27.1499996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRJ": {
      "icao": "FZRJ",
      "iata": "",
      "name": "Pepa Airport",
      "city": "Pepa",
      "state": "",
      "country": "CD",
      "elevation": 6562,
      "lat": -7.7170000076,
      "lon": 29.7999992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRK": {
      "icao": "FZRK",
      "iata": "",
      "name": "Kansimba Airport",
      "city": "Kansimba",
      "state": "Tanganika",
      "country": "CD",
      "elevation": 5413,
      "lat": -7.3169999123,
      "lon": 29.1669998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRL": {
      "icao": "FZRL",
      "iata": "",
      "name": "Lusinga Airport",
      "city": "Lusinga",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 5840,
      "lat": -8.9329996109,
      "lon": 27.1830005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRM": {
      "icao": "FZRM",
      "iata": "KBO",
      "name": "Kabalo Airport",
      "city": "Kabalo",
      "state": "",
      "country": "CD",
      "elevation": 1840,
      "lat": -6.0830001831,
      "lon": 26.9169998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRN": {
      "icao": "FZRN",
      "iata": "",
      "name": "Nyunzu Airport",
      "city": "Nyunzu",
      "state": "",
      "country": "CD",
      "elevation": 2297,
      "lat": -5.9330000877,
      "lon": 28,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRO": {
      "icao": "FZRO",
      "iata": "",
      "name": "Luvua Airport",
      "city": "Luvua",
      "state": "Haut-Katanga",
      "country": "CD",
      "elevation": 4298,
      "lat": -7.9330000877,
      "lon": 28.533000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZRQ": {
      "icao": "FZRQ",
      "iata": "KOO",
      "name": "Kongolo Airport",
      "city": "Kongolo",
      "state": "",
      "country": "CD",
      "elevation": 1850,
      "lat": -5.3944401741,
      "lon": 26.9899997711,
      "tz": "Africa\/Lubumbashi"
  },
  "FZSA": {
      "icao": "FZSA",
      "iata": "KMN",
      "name": "Kamina Base Airport",
      "city": "",
      "state": "",
      "country": "CD",
      "elevation": 3543,
      "lat": -8.6420202255,
      "lon": 25.2528991699,
      "tz": "Africa\/Lubumbashi"
  },
  "FZSB": {
      "icao": "FZSB",
      "iata": "KMN",
      "name": "Ville Airport",
      "city": "Kamina",
      "state": "",
      "country": "CD",
      "elevation": 3475,
      "lat": -8.7286100388,
      "lon": 24.991399765,
      "tz": "Africa\/Lubumbashi"
  },
  "FZSC": {
      "icao": "FZSC",
      "iata": "",
      "name": "Songa Airport",
      "city": "Songa",
      "state": "Haut-Lomani",
      "country": "CD",
      "elevation": 3526,
      "lat": -8.1000003815,
      "lon": 25.033000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZSD": {
      "icao": "FZSD",
      "iata": "",
      "name": "Sandoa Airport",
      "city": "Sandoa",
      "state": "Lualaba",
      "country": "CD",
      "elevation": 3022,
      "lat": -9.6330003738,
      "lon": 22.8500003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZSE": {
      "icao": "FZSE",
      "iata": "",
      "name": "Kanene Airport",
      "city": "Kanene",
      "state": "Haut-Lomani",
      "country": "CD",
      "elevation": 3707,
      "lat": -9.3000001907,
      "lon": 24.6669998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZSI": {
      "icao": "FZSI",
      "iata": "",
      "name": "Dilolo Airport",
      "city": "Dilolo",
      "state": "Lualaba",
      "country": "CD",
      "elevation": 3378,
      "lat": -10.7170000076,
      "lon": 22.3500003815,
      "tz": "Africa\/Luanda"
  },
  "FZSJ": {
      "icao": "FZSJ",
      "iata": "",
      "name": "Kasaji Airport",
      "city": "Kasaji",
      "state": "Lualaba",
      "country": "CD",
      "elevation": 3297,
      "lat": -10.3500003815,
      "lon": 23.4169998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZSK": {
      "icao": "FZSK",
      "iata": "KAP",
      "name": "Kapanga Airport",
      "city": "Kapanga",
      "state": "",
      "country": "CD",
      "elevation": 3025,
      "lat": -8.3500003815,
      "lon": 22.5830001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZTK": {
      "icao": "FZTK",
      "iata": "KNM",
      "name": "Kaniama Airport",
      "city": "Kaniama",
      "state": "Haut-Lomani",
      "country": "CD",
      "elevation": 2772,
      "lat": -7.5830001831,
      "lon": 24.1499996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZTL": {
      "icao": "FZTL",
      "iata": "",
      "name": "Luena Airport",
      "city": "Luena",
      "state": "Haut-Lomani",
      "country": "CD",
      "elevation": 2349,
      "lat": -9.4670000076,
      "lon": 25.75,
      "tz": "Africa\/Lubumbashi"
  },
  "FZTS": {
      "icao": "FZTS",
      "iata": "",
      "name": "Kaniama Airport",
      "city": "Kaniama",
      "state": "Haut-Lomani",
      "country": "CD",
      "elevation": 2871,
      "lat": -7.6999998093,
      "lon": 24.0499992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUA": {
      "icao": "FZUA",
      "iata": "KGA",
      "name": "Kananga Airport",
      "city": "Kananga",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2139,
      "lat": -5.9000501633,
      "lon": 22.4692001343,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUE": {
      "icao": "FZUE",
      "iata": "",
      "name": "Lubondaie Airport",
      "city": "Lubondaie",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2657,
      "lat": -6.5830001831,
      "lon": 22.5,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUF": {
      "icao": "FZUF",
      "iata": "",
      "name": "Kasonga Airport",
      "city": "Kasonga",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2707,
      "lat": -6.6500000954,
      "lon": 22.3829994202,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUG": {
      "icao": "FZUG",
      "iata": "LZA",
      "name": "Luiza Airport",
      "city": "Luiza",
      "state": "",
      "country": "CD",
      "elevation": 2890,
      "lat": -7.1830000877,
      "lon": 22.3999996185,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUH": {
      "icao": "FZUH",
      "iata": "",
      "name": "Moma Airport",
      "city": "Moma",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2789,
      "lat": -7.2329998016,
      "lon": 22.6000003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUI": {
      "icao": "FZUI",
      "iata": "",
      "name": "Mboi Airport",
      "city": "Mboi",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2789,
      "lat": -6.8330001831,
      "lon": 22,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUJ": {
      "icao": "FZUJ",
      "iata": "",
      "name": "Muambi Airport",
      "city": "Muambi",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2493,
      "lat": -6.6830000877,
      "lon": 22.533000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUK": {
      "icao": "FZUK",
      "iata": "TSH",
      "name": "Tshikapa Airport",
      "city": "Tshikapa",
      "state": "",
      "country": "CD",
      "elevation": 1595,
      "lat": -6.4383301735,
      "lon": 20.7947006226,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUL": {
      "icao": "FZUL",
      "iata": "",
      "name": "Bulape Airport",
      "city": "Bulape",
      "state": "Kasai",
      "country": "CD",
      "elevation": 1640,
      "lat": -4.617000103,
      "lon": 21.6000003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUM": {
      "icao": "FZUM",
      "iata": "",
      "name": "Mutoto Airport",
      "city": "Mutoto",
      "state": "Kasai-Oriental",
      "country": "CD",
      "elevation": 2297,
      "lat": -5.6999998093,
      "lon": 23.716999054,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUN": {
      "icao": "FZUN",
      "iata": "",
      "name": "Luebo Airport",
      "city": "Luebo",
      "state": "Kasai",
      "country": "CD",
      "elevation": 1640,
      "lat": -5.3499999046,
      "lon": 21.3330001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUO": {
      "icao": "FZUO",
      "iata": "",
      "name": "Musese Airport",
      "city": "Musese",
      "state": "Kasai",
      "country": "CD",
      "elevation": 1870,
      "lat": -5.5,
      "lon": 21.4330005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUP": {
      "icao": "FZUP",
      "iata": "",
      "name": "Diboko Airport",
      "city": "Diboko",
      "state": "Kasai",
      "country": "CD",
      "elevation": 2431,
      "lat": -7.0036101341,
      "lon": 21.2441997528,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUR": {
      "icao": "FZUR",
      "iata": "",
      "name": "Tshibala Airport",
      "city": "Tshibala",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2287,
      "lat": -6.9330000877,
      "lon": 22,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUS": {
      "icao": "FZUS",
      "iata": "",
      "name": "Tshikaji Airport",
      "city": "Tshikaji",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2287,
      "lat": -5.9670000076,
      "lon": 22.4169998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUT": {
      "icao": "FZUT",
      "iata": "",
      "name": "Katubwe Airport",
      "city": "Katubwe",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2461,
      "lat": -6.0500001907,
      "lon": 22.6000003815,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUU": {
      "icao": "FZUU",
      "iata": "",
      "name": "Lutshatsha Airport",
      "city": "Lutshatsha",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 2329,
      "lat": -6.2170000076,
      "lon": 22.0830001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZUV": {
      "icao": "FZUV",
      "iata": "",
      "name": "Kalonda Airport",
      "city": "Kalonda",
      "state": "Kasai",
      "country": "CD",
      "elevation": 1873,
      "lat": -6.4670000076,
      "lon": 20.7999992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVA": {
      "icao": "FZVA",
      "iata": "LJA",
      "name": "Lodja Airport",
      "city": "Lodja",
      "state": "Kasai-Oriental",
      "country": "CD",
      "elevation": 1647,
      "lat": -3.4170000553,
      "lon": 23.4500007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVC": {
      "icao": "FZVC",
      "iata": "",
      "name": "Kole Sur Lukenie Airport",
      "city": "Kole Sur Lukenie",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1542,
      "lat": -3.4000000954,
      "lon": 22.533000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVD": {
      "icao": "FZVD",
      "iata": "",
      "name": "Dingele Airport",
      "city": "Dingele",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1985,
      "lat": -3.5999999046,
      "lon": 24.5830001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVE": {
      "icao": "FZVE",
      "iata": "",
      "name": "Lomela Airport",
      "city": "Lomela",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1434,
      "lat": -2.2999999523,
      "lon": 23.2670001984,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVF": {
      "icao": "FZVF",
      "iata": "",
      "name": "Kutusongo Airport",
      "city": "Kutusongo",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1722,
      "lat": -2.6670000553,
      "lon": 23.1669998169,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVG": {
      "icao": "FZVG",
      "iata": "",
      "name": "Katako'kombe Airport",
      "city": "Katako'kombe",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1978,
      "lat": -3.4670000076,
      "lon": 24.4330005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVH": {
      "icao": "FZVH",
      "iata": "",
      "name": "Shongamba Airport",
      "city": "Shongamba",
      "state": "Kasai",
      "country": "CD",
      "elevation": 2133,
      "lat": -4.3499999046,
      "lon": 21.283000946,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVI": {
      "icao": "FZVI",
      "iata": "LBO",
      "name": "Lusambo Airport",
      "city": "Lusambo",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1407,
      "lat": -4.9616699219,
      "lon": 23.3782997131,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVJ": {
      "icao": "FZVJ",
      "iata": "",
      "name": "Tshumbe Airport",
      "city": "Tshumbe",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1804,
      "lat": -4.0999999046,
      "lon": 24.3670005798,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVK": {
      "icao": "FZVK",
      "iata": "",
      "name": "Lukombe-Batwa Airport",
      "city": "Lukombe-Batwa",
      "state": "Kasai",
      "country": "CD",
      "elevation": 1640,
      "lat": -4.3330001831,
      "lon": 22.0830001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVL": {
      "icao": "FZVL",
      "iata": "",
      "name": "Wasolo Airport",
      "city": "Wasolo",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1673,
      "lat": -3.9500000477,
      "lon": 22.5170001984,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVM": {
      "icao": "FZVM",
      "iata": "MEW",
      "name": "Mweka Airport",
      "city": "Mweka",
      "state": "",
      "country": "CD",
      "elevation": 1968,
      "lat": -4.8499999046,
      "lon": 21.5499992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVN": {
      "icao": "FZVN",
      "iata": "",
      "name": "Wembo-Nyama Airport",
      "city": "Wembo-Nyama",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1801,
      "lat": -4.4829998016,
      "lon": 24.4500007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVO": {
      "icao": "FZVO",
      "iata": "",
      "name": "Beni-Dibele Airport",
      "city": "Beni-Dibele",
      "state": "Kasai-Oriental",
      "country": "CD",
      "elevation": 1738,
      "lat": -4.0830001831,
      "lon": 22.8330001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVP": {
      "icao": "FZVP",
      "iata": "",
      "name": "Dikungu Airport",
      "city": "Dikungu",
      "state": "Sankuru",
      "country": "CD",
      "elevation": 1833,
      "lat": -4.0329999924,
      "lon": 24.466999054,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVR": {
      "icao": "FZVR",
      "iata": "BAN",
      "name": "Basongo Airport",
      "city": "Basongo",
      "state": "",
      "country": "CD",
      "elevation": 1640,
      "lat": -4.3169999123,
      "lon": 20.4330005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVS": {
      "icao": "FZVS",
      "iata": "PFR",
      "name": "Ilebo Airport",
      "city": "Ilebo",
      "state": "Kasai",
      "country": "CD",
      "elevation": 1450,
      "lat": -4.3330001831,
      "lon": 20.5830001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVT": {
      "icao": "FZVT",
      "iata": "",
      "name": "Dekese Airport",
      "city": "Dekese",
      "state": "",
      "country": "CD",
      "elevation": 1279,
      "lat": -3.4670000076,
      "lon": 21.4330005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZVU": {
      "icao": "FZVU",
      "iata": "",
      "name": "Idumbe Airport",
      "city": "Idumbe",
      "state": "Kasai",
      "country": "CD",
      "elevation": 1847,
      "lat": -3.9170000553,
      "lon": 21.5830001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWA": {
      "icao": "FZWA",
      "iata": "MJM",
      "name": "Mbuji Mayi Airport",
      "city": "Mbuji Mayi",
      "state": "Kasai-Oriental",
      "country": "CD",
      "elevation": 2221,
      "lat": -6.121240139,
      "lon": 23.5690002441,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWB": {
      "icao": "FZWB",
      "iata": "",
      "name": "Bibanga Airport",
      "city": "Bibanga",
      "state": "Kasai-Oriental",
      "country": "CD",
      "elevation": 2953,
      "lat": -6.25,
      "lon": 23.9829998016,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWC": {
      "icao": "FZWC",
      "iata": "GDJ",
      "name": "Gandajika Airport",
      "city": "Gandajika",
      "state": "Lomami",
      "country": "CD",
      "elevation": 2618,
      "lat": -6.7329998016,
      "lon": 23.9500007629,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWE": {
      "icao": "FZWE",
      "iata": "",
      "name": "Mwene-Ditu Airport",
      "city": "Mwene-Ditu",
      "state": "Kasai-Central",
      "country": "CD",
      "elevation": 3198,
      "lat": -6.9829998016,
      "lon": 23.0830001831,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWF": {
      "icao": "FZWF",
      "iata": "",
      "name": "Kipushi Airport",
      "city": "Kipushi",
      "state": "Lomami",
      "country": "CD",
      "elevation": 2953,
      "lat": -6.1669998169,
      "lon": 25.1830005646,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWI": {
      "icao": "FZWI",
      "iata": "",
      "name": "Kashia Airport",
      "city": "Kashia",
      "state": "Lomami",
      "country": "CD",
      "elevation": 2887,
      "lat": -7.2170000076,
      "lon": 23.75,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWL": {
      "icao": "FZWL",
      "iata": "",
      "name": "Munkamba Airport",
      "city": "Munkamba",
      "state": "",
      "country": "CD",
      "elevation": 2230,
      "lat": -5.7670001984,
      "lon": 23.0499992371,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWR": {
      "icao": "FZWR",
      "iata": "",
      "name": "Kisengwa Airport",
      "city": "Kisengwa",
      "state": "Lomami",
      "country": "CD",
      "elevation": 2428,
      "lat": -6.0170001984,
      "lon": 25.8829994202,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWS": {
      "icao": "FZWS",
      "iata": "",
      "name": "Lubao Airport",
      "city": "Lubao",
      "state": "Lomami",
      "country": "CD",
      "elevation": 2625,
      "lat": -5.3000001907,
      "lon": 25.7329998016,
      "tz": "Africa\/Lubumbashi"
  },
  "FZWT": {
      "icao": "FZWT",
      "iata": "KBN",
      "name": "Tunta Airport",
      "city": "Kabinda",
      "state": "Kasai-Oriental",
      "country": "CD",
      "elevation": 2766,
      "lat": -6.132999897,
      "lon": 24.4829998016,
      "tz": "Africa\/Lubumbashi"
  },
  "GA00": {
      "icao": "GA00",
      "iata": "",
      "name": "Kintail Farm Airport",
      "city": "Monroe",
      "state": "Georgia",
      "country": "US",
      "elevation": 810,
      "lat": 33.8255996704,
      "lon": -83.6397018433,
      "tz": "America\/New_York"
  },
  "GA01": {
      "icao": "GA01",
      "iata": "",
      "name": "Flying H Ranch Inc Airport",
      "city": "Jackson",
      "state": "Georgia",
      "country": "US",
      "elevation": 722,
      "lat": 33.1977996826,
      "lon": -84.0843963623,
      "tz": "America\/New_York"
  },
  "GA02": {
      "icao": "GA02",
      "iata": "",
      "name": "Howard Private Airport",
      "city": "Jackson",
      "state": "Georgia",
      "country": "US",
      "elevation": 720,
      "lat": 33.3515014648,
      "lon": -83.9637985229,
      "tz": "America\/New_York"
  },
  "GA03": {
      "icao": "GA03",
      "iata": "",
      "name": "Wilson Airport",
      "city": "Ringgold",
      "state": "Georgia",
      "country": "US",
      "elevation": 795,
      "lat": 34.8694992065,
      "lon": -85.1996994019,
      "tz": "America\/New_York"
  },
  "GA04": {
      "icao": "GA04",
      "iata": "",
      "name": "Mallards Landing Airport",
      "city": "Locust Grove",
      "state": "Georgia",
      "country": "US",
      "elevation": 837,
      "lat": 33.3656997681,
      "lon": -84.1651992798,
      "tz": "America\/New_York"
  },
  "GA06": {
      "icao": "GA06",
      "iata": "",
      "name": "Monticello Sky Ranch Airport",
      "city": "Monticello",
      "state": "Georgia",
      "country": "US",
      "elevation": 690,
      "lat": 33.3325004578,
      "lon": -83.7268981934,
      "tz": "America\/New_York"
  },
  "GA07": {
      "icao": "GA07",
      "iata": "",
      "name": "Crawford Hendrix Farm Airport",
      "city": "Statesboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 100,
      "lat": 32.2372016907,
      "lon": -81.6449966431,
      "tz": "America\/New_York"
  },
  "GA08": {
      "icao": "GA08",
      "iata": "",
      "name": "Jumpin J Airport",
      "city": "Talbotton",
      "state": "Georgia",
      "country": "US",
      "elevation": 659,
      "lat": 32.6917991638,
      "lon": -84.4971008301,
      "tz": "America\/New_York"
  },
  "GA09": {
      "icao": "GA09",
      "iata": "",
      "name": "Fly-N-S Ranch Airport",
      "city": "Clermont",
      "state": "Georgia",
      "country": "US",
      "elevation": 1330,
      "lat": 34.4516983032,
      "lon": -83.7885971069,
      "tz": "America\/New_York"
  },
  "GA10": {
      "icao": "GA10",
      "iata": "",
      "name": "Ridgeview Farm Airport",
      "city": "Zebulon",
      "state": "Georgia",
      "country": "US",
      "elevation": 854,
      "lat": 33.0834999084,
      "lon": -84.3582992554,
      "tz": "America\/New_York"
  },
  "GA12": {
      "icao": "GA12",
      "iata": "",
      "name": "Tallassee Plantation Airport",
      "city": "Albany",
      "state": "Georgia",
      "country": "US",
      "elevation": 240,
      "lat": 31.5834999084,
      "lon": -84.3905029297,
      "tz": "America\/New_York"
  },
  "GA13": {
      "icao": "GA13",
      "iata": "",
      "name": "Double 'O' Farm Airport",
      "city": "Albany",
      "state": "Georgia",
      "country": "US",
      "elevation": 297,
      "lat": 31.5349006653,
      "lon": -84.0020980835,
      "tz": "America\/New_York"
  },
  "GA14": {
      "icao": "GA14",
      "iata": "",
      "name": "Pinebloom Plantation Airport",
      "city": "Albany",
      "state": "Georgia",
      "country": "US",
      "elevation": 170,
      "lat": 31.4029998779,
      "lon": -84.3227005005,
      "tz": "America\/New_York"
  },
  "GA15": {
      "icao": "GA15",
      "iata": "",
      "name": "Klockner Airport",
      "city": "Bostwick",
      "state": "Georgia",
      "country": "US",
      "elevation": 740,
      "lat": 33.7086982727,
      "lon": -83.5791015625,
      "tz": "America\/New_York"
  },
  "GA16": {
      "icao": "GA16",
      "iata": "",
      "name": "Taylor Field",
      "city": "Bostwick",
      "state": "Georgia",
      "country": "US",
      "elevation": 710,
      "lat": 33.7243003845,
      "lon": -83.5668029785,
      "tz": "America\/New_York"
  },
  "GA17": {
      "icao": "GA17",
      "iata": "",
      "name": "Flint River Nursery Airport",
      "city": "Byromville",
      "state": "Georgia",
      "country": "US",
      "elevation": 320,
      "lat": 32.168800354,
      "lon": -83.9735031128,
      "tz": "America\/New_York"
  },
  "GA18": {
      "icao": "GA18",
      "iata": "",
      "name": "Big Creek Flying Ranch Airport",
      "city": "Clayton",
      "state": "Georgia",
      "country": "US",
      "elevation": 1650,
      "lat": 34.832901001,
      "lon": -83.4095993042,
      "tz": "America\/New_York"
  },
  "GA19": {
      "icao": "GA19",
      "iata": "",
      "name": "Hearn Airport",
      "city": "Claxton",
      "state": "Georgia",
      "country": "US",
      "elevation": 185,
      "lat": 32.1571006775,
      "lon": -81.8887023926,
      "tz": "America\/New_York"
  },
  "GA1A": {
      "icao": "GA1A",
      "iata": "",
      "name": "Kayes Airport",
      "city": "",
      "state": "Kayes",
      "country": "ML",
      "elevation": 161,
      "lat": 14.4313001633,
      "lon": -11.4397001266,
      "tz": "Africa\/Bamako"
  },
  "GA20": {
      "icao": "GA20",
      "iata": "",
      "name": "Stafford Airport",
      "city": "Cumberland Island",
      "state": "Georgia",
      "country": "US",
      "elevation": 28,
      "lat": 30.8111000061,
      "lon": -81.4627990723,
      "tz": "America\/New_York"
  },
  "GA21": {
      "icao": "GA21",
      "iata": "",
      "name": "Patterson Island Airport",
      "city": "Darien",
      "state": "Georgia",
      "country": "US",
      "elevation": 10,
      "lat": 31.4612998962,
      "lon": -81.3386993408,
      "tz": "America\/New_York"
  },
  "GA22": {
      "icao": "GA22",
      "iata": "",
      "name": "Jordans Airport",
      "city": "Arlington",
      "state": "Georgia",
      "country": "US",
      "elevation": 250,
      "lat": 31.4384994507,
      "lon": -84.7082977295,
      "tz": "America\/New_York"
  },
  "GA23": {
      "icao": "GA23",
      "iata": "",
      "name": "Wyatt Airport",
      "city": "Summerville",
      "state": "Georgia",
      "country": "US",
      "elevation": 720,
      "lat": 34.5783004761,
      "lon": -85.3839035034,
      "tz": "America\/New_York"
  },
  "GA25": {
      "icao": "GA25",
      "iata": "",
      "name": "Fargo Airport",
      "city": "Fargo",
      "state": "Georgia",
      "country": "US",
      "elevation": 118,
      "lat": 30.6912994385,
      "lon": -82.5675964355,
      "tz": "America\/New_York"
  },
  "GA27": {
      "icao": "GA27",
      "iata": "",
      "name": "Mathis Airport",
      "city": "Cumming",
      "state": "Georgia",
      "country": "US",
      "elevation": 1170,
      "lat": 34.1012001038,
      "lon": -84.1610031128,
      "tz": "America\/New_York"
  },
  "GA29": {
      "icao": "GA29",
      "iata": "",
      "name": "B & L Strip",
      "city": "Hollonville",
      "state": "Georgia",
      "country": "US",
      "elevation": 843,
      "lat": 33.1562004089,
      "lon": -84.4309997559,
      "tz": "America\/New_York"
  },
  "GA30": {
      "icao": "GA30",
      "iata": "",
      "name": "Eliott Barrow Airport",
      "city": "Matthews",
      "state": "Georgia",
      "country": "US",
      "elevation": 400,
      "lat": 33.2000999451,
      "lon": -82.2665023804,
      "tz": "America\/New_York"
  },
  "GA31": {
      "icao": "GA31",
      "iata": "",
      "name": "Two Rocks Airport",
      "city": "Palmetto",
      "state": "Georgia",
      "country": "US",
      "elevation": 964,
      "lat": 33.4735984802,
      "lon": -84.6735992432,
      "tz": "America\/New_York"
  },
  "GA34": {
      "icao": "GA34",
      "iata": "",
      "name": "Tootle Airport",
      "city": "Reidsville",
      "state": "Georgia",
      "country": "US",
      "elevation": 202,
      "lat": 32.0321006775,
      "lon": -82.0414962769,
      "tz": "America\/New_York"
  },
  "GA35": {
      "icao": "GA35",
      "iata": "",
      "name": "Cypress Lakes Airport",
      "city": "Savannah",
      "state": "Georgia",
      "country": "US",
      "elevation": 30,
      "lat": 32.1575012207,
      "lon": -81.396697998,
      "tz": "America\/New_York"
  },
  "GA36": {
      "icao": "GA36",
      "iata": "",
      "name": "Foothills-Holcomb Airport",
      "city": "Marble Hill",
      "state": "Georgia",
      "country": "US",
      "elevation": 1425,
      "lat": 34.3917007446,
      "lon": -84.2416992188,
      "tz": "America\/New_York"
  },
  "GA39": {
      "icao": "GA39",
      "iata": "",
      "name": "Hodges Airpark",
      "city": "Savannah",
      "state": "Georgia",
      "country": "US",
      "elevation": 10,
      "lat": 31.9843997955,
      "lon": -81.2462005615,
      "tz": "America\/New_York"
  },
  "GA41": {
      "icao": "GA41",
      "iata": "",
      "name": "Daniels Airport",
      "city": "Swainsboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 288,
      "lat": 32.6141014099,
      "lon": -82.2970962524,
      "tz": "America\/New_York"
  },
  "GA42": {
      "icao": "GA42",
      "iata": "",
      "name": "Crowe Airport",
      "city": "Sylvester",
      "state": "Georgia",
      "country": "US",
      "elevation": 330,
      "lat": 31.6168003082,
      "lon": -83.8832015991,
      "tz": "America\/New_York"
  },
  "GA43": {
      "icao": "GA43",
      "iata": "",
      "name": "Briggs Field",
      "city": "Guyton",
      "state": "Georgia",
      "country": "US",
      "elevation": 78,
      "lat": 32.3213996887,
      "lon": -81.4266967773,
      "tz": "America\/New_York"
  },
  "GA44": {
      "icao": "GA44",
      "iata": "",
      "name": "Richards Airport",
      "city": "Lagrange",
      "state": "Georgia",
      "country": "US",
      "elevation": 650,
      "lat": 33.0792999268,
      "lon": -85.0316009521,
      "tz": "America\/New_York"
  },
  "GA45": {
      "icao": "GA45",
      "iata": "",
      "name": "Townsend Air Strip",
      "city": "Townsend",
      "state": "Georgia",
      "country": "US",
      "elevation": 26,
      "lat": 31.5433006287,
      "lon": -81.5347976685,
      "tz": "America\/New_York"
  },
  "GA47": {
      "icao": "GA47",
      "iata": "",
      "name": "Bivins Airport",
      "city": "Waycross",
      "state": "Georgia",
      "country": "US",
      "elevation": 134,
      "lat": 31.1851997375,
      "lon": -82.2734985352,
      "tz": "America\/New_York"
  },
  "GA48": {
      "icao": "GA48",
      "iata": "",
      "name": "Mclendon Airport",
      "city": "Edison",
      "state": "Georgia",
      "country": "US",
      "elevation": 360,
      "lat": 31.586111,
      "lon": -84.790556,
      "tz": "America\/New_York"
  },
  "GA49": {
      "icao": "GA49",
      "iata": "",
      "name": "Thistle Field",
      "city": "Yatesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 730,
      "lat": 32.927898407,
      "lon": -84.1412963867,
      "tz": "America\/New_York"
  },
  "GA50": {
      "icao": "GA50",
      "iata": "",
      "name": "Dream Team Airport",
      "city": "Madison",
      "state": "Georgia",
      "country": "US",
      "elevation": 604,
      "lat": 33.5974998474,
      "lon": -83.5177993774,
      "tz": "America\/New_York"
  },
  "GA53": {
      "icao": "GA53",
      "iata": "",
      "name": "Rollins STOLport",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 810,
      "lat": 33.8151016235,
      "lon": -84.3719024658,
      "tz": "America\/New_York"
  },
  "GA59": {
      "icao": "GA59",
      "iata": "",
      "name": "Antique Acres Airport",
      "city": "Barnesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.1001014709,
      "lon": -84.0585021973,
      "tz": "America\/New_York"
  },
  "GA61": {
      "icao": "GA61",
      "iata": "",
      "name": "Kenley Field",
      "city": "Brooks",
      "state": "Georgia",
      "country": "US",
      "elevation": 780,
      "lat": 33.2690010071,
      "lon": -84.4971008301,
      "tz": "America\/New_York"
  },
  "GA62": {
      "icao": "GA62",
      "iata": "",
      "name": "Cedar Ridge Airport",
      "city": "Griffin",
      "state": "Georgia",
      "country": "US",
      "elevation": 820,
      "lat": 33.2551002502,
      "lon": -84.4001998901,
      "tz": "America\/New_York"
  },
  "GA63": {
      "icao": "GA63",
      "iata": "",
      "name": "Byromville Aerodrome",
      "city": "Byromville",
      "state": "Georgia",
      "country": "US",
      "elevation": 354,
      "lat": 32.1792984009,
      "lon": -83.8999023438,
      "tz": "America\/New_York"
  },
  "GA65": {
      "icao": "GA65",
      "iata": "",
      "name": "Mercer Airfield",
      "city": "Calhoun",
      "state": "Georgia",
      "country": "US",
      "elevation": 660,
      "lat": 34.4370002747,
      "lon": -84.9197006226,
      "tz": "America\/New_York"
  },
  "GA67": {
      "icao": "GA67",
      "iata": "",
      "name": "King Sky Ranch Airport",
      "city": "Clarkesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1840,
      "lat": 34.7681999207,
      "lon": -83.5929031372,
      "tz": "America\/New_York"
  },
  "GA68": {
      "icao": "GA68",
      "iata": "",
      "name": "Flying 'H' Ranch Airport",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.1147994995,
      "lon": -84.4490966797,
      "tz": "America\/New_York"
  },
  "GA72": {
      "icao": "GA72",
      "iata": "",
      "name": "Pratermill Flight Park Airport",
      "city": "Dalton",
      "state": "Georgia",
      "country": "US",
      "elevation": 780,
      "lat": 34.8816986084,
      "lon": -84.8897018433,
      "tz": "America\/New_York"
  },
  "GA73": {
      "icao": "GA73",
      "iata": "",
      "name": "Shade Tree Airport",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 850,
      "lat": 33.1618003845,
      "lon": -84.4533004761,
      "tz": "America\/New_York"
  },
  "GA74": {
      "icao": "GA74",
      "iata": "",
      "name": "Takle Field",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 850,
      "lat": 33.0418014526,
      "lon": -84.412399292,
      "tz": "America\/New_York"
  },
  "GA75": {
      "icao": "GA75",
      "iata": "",
      "name": "Meadowlark Airport",
      "city": "Concord",
      "state": "Georgia",
      "country": "US",
      "elevation": 784,
      "lat": 33.0872993469,
      "lon": -84.4051971436,
      "tz": "America\/New_York"
  },
  "GA76": {
      "icao": "GA76",
      "iata": "",
      "name": "Broken Ranch Airport",
      "city": "Forsyth",
      "state": "Georgia",
      "country": "US",
      "elevation": 620,
      "lat": 33.1548995972,
      "lon": -83.8988037109,
      "tz": "America\/New_York"
  },
  "GA77": {
      "icao": "GA77",
      "iata": "",
      "name": "Wallace Field",
      "city": "Rome",
      "state": "Georgia",
      "country": "US",
      "elevation": 810,
      "lat": 34.135799408,
      "lon": -85.112197876,
      "tz": "America\/New_York"
  },
  "GA79": {
      "icao": "GA79",
      "iata": "",
      "name": "Dresden Airport",
      "city": "Newnan",
      "state": "Georgia",
      "country": "US",
      "elevation": 800,
      "lat": 33.3447990417,
      "lon": -84.9113006592,
      "tz": "America\/New_York"
  },
  "GA80": {
      "icao": "GA80",
      "iata": "",
      "name": "Whispering Pines Airport",
      "city": "Conyers",
      "state": "Georgia",
      "country": "US",
      "elevation": 940,
      "lat": 33.7822990417,
      "lon": -83.9888000488,
      "tz": "America\/New_York"
  },
  "GA81": {
      "icao": "GA81",
      "iata": "",
      "name": "Cameron Field",
      "city": "Ft Valley",
      "state": "Georgia",
      "country": "US",
      "elevation": 380,
      "lat": 32.5477981567,
      "lon": -83.8268966675,
      "tz": "America\/New_York"
  },
  "GA82": {
      "icao": "GA82",
      "iata": "",
      "name": "Morgan Farm Field",
      "city": "Covington",
      "state": "Georgia",
      "country": "US",
      "elevation": 700,
      "lat": 33.5959014893,
      "lon": -83.8041000366,
      "tz": "America\/New_York"
  },
  "GA83": {
      "icao": "GA83",
      "iata": "",
      "name": "Windy Hill Airport",
      "city": "Covington",
      "state": "Georgia",
      "country": "US",
      "elevation": 740,
      "lat": 33.5467987061,
      "lon": -83.8054962158,
      "tz": "America\/New_York"
  },
  "GA86": {
      "icao": "GA86",
      "iata": "",
      "name": "Little Tobesofkee Creek Ranch Airport",
      "city": "Barnesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 755,
      "lat": 32.9838981628,
      "lon": -84.0955963135,
      "tz": "America\/New_York"
  },
  "GA87": {
      "icao": "GA87",
      "iata": "",
      "name": "High Valley Airpark",
      "city": "Suches",
      "state": "Georgia",
      "country": "US",
      "elevation": 2800,
      "lat": 34.6981010437,
      "lon": -84.0127029419,
      "tz": "America\/New_York"
  },
  "GA88": {
      "icao": "GA88",
      "iata": "",
      "name": "Wade Plantation Airport",
      "city": "Sylvania",
      "state": "Georgia",
      "country": "US",
      "elevation": 146,
      "lat": 32.9771003723,
      "lon": -81.5362014771,
      "tz": "America\/New_York"
  },
  "GA89": {
      "icao": "GA89",
      "iata": "",
      "name": "Diamond S Airport",
      "city": "Lovejoy",
      "state": "Georgia",
      "country": "US",
      "elevation": 910,
      "lat": 33.4757995605,
      "lon": -84.2769012451,
      "tz": "America\/New_York"
  },
  "GA90": {
      "icao": "GA90",
      "iata": "",
      "name": "Walker Field",
      "city": "Fayetteville",
      "state": "Georgia",
      "country": "US",
      "elevation": 875,
      "lat": 33.4520988464,
      "lon": -84.4094009399,
      "tz": "America\/New_York"
  },
  "GA91": {
      "icao": "GA91",
      "iata": "",
      "name": "Adams Airport",
      "city": "Fayetteville",
      "state": "Georgia",
      "country": "US",
      "elevation": 885,
      "lat": 33.3970985413,
      "lon": -84.4601974487,
      "tz": "America\/New_York"
  },
  "GA92": {
      "icao": "GA92",
      "iata": "",
      "name": "Beck Field",
      "city": "Fayetteville",
      "state": "Georgia",
      "country": "US",
      "elevation": 990,
      "lat": 33.5194015503,
      "lon": -84.5143966675,
      "tz": "America\/New_York"
  },
  "GA93": {
      "icao": "GA93",
      "iata": "",
      "name": "Bishops Airport",
      "city": "Fayetteville",
      "state": "Georgia",
      "country": "US",
      "elevation": 870,
      "lat": 33.4453010559,
      "lon": -84.4067001343,
      "tz": "America\/New_York"
  },
  "GA94": {
      "icao": "GA94",
      "iata": "",
      "name": "Mc Lendon Airport",
      "city": "Fayetteville",
      "state": "Georgia",
      "country": "US",
      "elevation": 900,
      "lat": 33.4915008545,
      "lon": -84.489402771,
      "tz": "America\/New_York"
  },
  "GA95": {
      "icao": "GA95",
      "iata": "",
      "name": "Coleman Field",
      "city": "Fayetteville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1010,
      "lat": 33.5261993408,
      "lon": -84.5021972656,
      "tz": "America\/New_York"
  },
  "GA98": {
      "icao": "GA98",
      "iata": "",
      "name": "Grayhill Airport",
      "city": "LaGrange",
      "state": "Georgia",
      "country": "US",
      "elevation": 750,
      "lat": 32.9489021301,
      "lon": -85.05052948,
      "tz": "America\/New_York"
  },
  "GA99": {
      "icao": "GA99",
      "iata": "",
      "name": "Miami Valley Farm Airport",
      "city": "Fort Valley",
      "state": "Georgia",
      "country": "US",
      "elevation": 468,
      "lat": 32.5488014221,
      "lon": -83.8018035889,
      "tz": "America\/New_York"
  },
  "GAAO": {
      "icao": "GAAO",
      "iata": "",
      "name": "Ansongo Airport",
      "city": "Ansongo",
      "state": "Gao",
      "country": "ML",
      "elevation": 853,
      "lat": 15.6999998093,
      "lon": 0.5,
      "tz": "Africa\/Bamako"
  },
  "GABD": {
      "icao": "GABD",
      "iata": "",
      "name": "Bandiagara Airport",
      "city": "Bandiagara",
      "state": "Mopti",
      "country": "ML",
      "elevation": 1312,
      "lat": 14.3330001831,
      "lon": -3.5999999046,
      "tz": "Africa\/Bamako"
  },
  "GABF": {
      "icao": "GABF",
      "iata": "",
      "name": "Bafoulabe Airport",
      "city": "Bafoulabe",
      "state": "Kayes",
      "country": "ML",
      "elevation": 380,
      "lat": 13.8000001907,
      "lon": -10.8500003815,
      "tz": "Africa\/Bamako"
  },
  "GABG": {
      "icao": "GABG",
      "iata": "",
      "name": "Bougouni Airport",
      "city": "Bougouni",
      "state": "Sikasso",
      "country": "ML",
      "elevation": 1139,
      "lat": 11.4499998093,
      "lon": -7.5170001984,
      "tz": "Africa\/Bamako"
  },
  "GABR": {
      "icao": "GABR",
      "iata": "",
      "name": "Bourem Airport",
      "city": "Bourem",
      "state": "Gao",
      "country": "ML",
      "elevation": 941,
      "lat": 17.033000946,
      "lon": -0.400000006,
      "tz": "Africa\/Bamako"
  },
  "GABS": {
      "icao": "GABS",
      "iata": "BKO",
      "name": "Senou Airport",
      "city": "Senou",
      "state": "Bamako",
      "country": "ML",
      "elevation": 1247,
      "lat": 12.5334997177,
      "lon": -7.9499402046,
      "tz": "Africa\/Bamako"
  },
  "GADA": {
      "icao": "GADA",
      "iata": "",
      "name": "Dioila Airport",
      "city": "Dioila",
      "state": "Koulikoro",
      "country": "ML",
      "elevation": 1050,
      "lat": 12.5,
      "lon": -6.8000001907,
      "tz": "Africa\/Bamako"
  },
  "GADZ": {
      "icao": "GADZ",
      "iata": "",
      "name": "Douentza Airport",
      "city": "Douentza",
      "state": "Mopti",
      "country": "ML",
      "elevation": 984,
      "lat": 15,
      "lon": -2.9170000553,
      "tz": "Africa\/Bamako"
  },
  "GAFD": {
      "icao": "GAFD",
      "iata": "",
      "name": "Faladie Airport",
      "city": "Faladie",
      "state": "Koulikoro",
      "country": "ML",
      "elevation": 1115,
      "lat": 13.1499996185,
      "lon": -8.3330001831,
      "tz": "Africa\/Bamako"
  },
  "GAGM": {
      "icao": "GAGM",
      "iata": "GUD",
      "name": "Goundam Airport",
      "city": "Goundam",
      "state": "Gao",
      "country": "ML",
      "elevation": 866,
      "lat": 16.3614006042,
      "lon": -3.5997200012,
      "tz": "Africa\/Bamako"
  },
  "GAGO": {
      "icao": "GAGO",
      "iata": "GAQ",
      "name": "Gao Airport",
      "city": "",
      "state": "Gao",
      "country": "ML",
      "elevation": 870,
      "lat": 16.2483997345,
      "lon": -0.0054560001,
      "tz": "Africa\/Bamako"
  },
  "GAKA": {
      "icao": "GAKA",
      "iata": "KNZ",
      "name": "Kenieba Airport",
      "city": "Kenieba",
      "state": "Kayes",
      "country": "ML",
      "elevation": 449,
      "lat": 12.8330001831,
      "lon": -11.25,
      "tz": "Africa\/Bamako"
  },
  "GAKL": {
      "icao": "GAKL",
      "iata": "",
      "name": "Kidal Airport",
      "city": "Kidal",
      "state": "Gao",
      "country": "ML",
      "elevation": 1496,
      "lat": 18.4330005646,
      "lon": 1.4170000553,
      "tz": "Africa\/Bamako"
  },
  "GAKM": {
      "icao": "GAKM",
      "iata": "",
      "name": "Ke-Macina Airport",
      "city": "Ke-Macina",
      "state": "Segou",
      "country": "ML",
      "elevation": 902,
      "lat": 13.9670000076,
      "lon": -5.382999897,
      "tz": "Africa\/Bamako"
  },
  "GAKN": {
      "icao": "GAKN",
      "iata": "",
      "name": "Kolokani Airport",
      "city": "Kolokani",
      "state": "Bamako",
      "country": "ML",
      "elevation": 1457,
      "lat": 13.5329999924,
      "lon": -8.0500001907,
      "tz": "Africa\/Bamako"
  },
  "GAKO": {
      "icao": "GAKO",
      "iata": "KTX",
      "name": "Koutiala Airport",
      "city": "Koutiala",
      "state": "Sikasso",
      "country": "ML",
      "elevation": 1240,
      "lat": 12.3830003738,
      "lon": -5.4670000076,
      "tz": "Africa\/Bamako"
  },
  "GAKT": {
      "icao": "GAKT",
      "iata": "",
      "name": "Kita Airport",
      "city": "Kita",
      "state": "Kayes",
      "country": "ML",
      "elevation": 1122,
      "lat": 13.0670003891,
      "lon": -9.4829998016,
      "tz": "Africa\/Bamako"
  },
  "GAKY": {
      "icao": "GAKY",
      "iata": "KYS",
      "name": "Kayes Dag Dag Airport",
      "city": "",
      "state": "Kayes",
      "country": "ML",
      "elevation": 164,
      "lat": 14.4812002182,
      "lon": -11.4043998718,
      "tz": "Africa\/Bamako"
  },
  "GAMA": {
      "icao": "GAMA",
      "iata": "",
      "name": "Markala Airport",
      "city": "Markala",
      "state": "Segou",
      "country": "ML",
      "elevation": 1251,
      "lat": 13.6999998093,
      "lon": -6.0669999123,
      "tz": "Africa\/Bamako"
  },
  "GAMB": {
      "icao": "GAMB",
      "iata": "MZI",
      "name": "Ambodedjo Airport",
      "city": "",
      "state": "Mopti",
      "country": "ML",
      "elevation": 906,
      "lat": 14.5128002167,
      "lon": -4.079559803,
      "tz": "Africa\/Bamako"
  },
  "GAMK": {
      "icao": "GAMK",
      "iata": "",
      "name": "Menaka Airport",
      "city": "Menaka",
      "state": "Gao",
      "country": "ML",
      "elevation": 899,
      "lat": 15.8500003815,
      "lon": 2.4330000877,
      "tz": "Africa\/Bamako"
  },
  "GANF": {
      "icao": "GANF",
      "iata": "",
      "name": "Niafunke Airport",
      "city": "Niafunke",
      "state": "Mopti",
      "country": "ML",
      "elevation": 866,
      "lat": 15.9329996109,
      "lon": -4.0170001984,
      "tz": "Africa\/Bamako"
  },
  "GANK": {
      "icao": "GANK",
      "iata": "NRM",
      "name": "Nara Airport",
      "city": "Nara",
      "state": "Koulikoro",
      "country": "ML",
      "elevation": 889,
      "lat": 15.2170000076,
      "lon": -7.2670001984,
      "tz": "Africa\/Bamako"
  },
  "GANR": {
      "icao": "GANR",
      "iata": "NIX",
      "name": "Nioro du Sahel Airport",
      "city": "Nioro du Sahel",
      "state": "Kayes",
      "country": "ML",
      "elevation": 778,
      "lat": 15.2381000519,
      "lon": -9.5761098862,
      "tz": "Africa\/Bamako"
  },
  "GASK": {
      "icao": "GASK",
      "iata": "KSS",
      "name": "Sikasso Airport",
      "city": "Sikasso",
      "state": "Sikasso",
      "country": "ML",
      "elevation": 1378,
      "lat": 11.3330001831,
      "lon": -5.6999998093,
      "tz": "Africa\/Bamako"
  },
  "GASO": {
      "icao": "GASO",
      "iata": "",
      "name": "Dignangan Airport",
      "city": "",
      "state": "Sikasso",
      "country": "ML",
      "elevation": 1301,
      "lat": 11.5979995728,
      "lon": -5.7997097969,
      "tz": "Africa\/Bamako"
  },
  "GATB": {
      "icao": "GATB",
      "iata": "TOM",
      "name": "Timbuktu Airport",
      "city": "Timbuktu",
      "state": "Tombouctou",
      "country": "ML",
      "elevation": 863,
      "lat": 16.7304992676,
      "lon": -3.0075800419,
      "tz": "Africa\/Bamako"
  },
  "GATS": {
      "icao": "GATS",
      "iata": "",
      "name": "Tessalit Airport",
      "city": "",
      "state": "Kidal",
      "country": "ML",
      "elevation": 1621,
      "lat": 20.2430000305,
      "lon": 0.9773079753,
      "tz": "Africa\/Bamako"
  },
  "GAYE": {
      "icao": "GAYE",
      "iata": "EYL",
      "name": "Yelimane Airport",
      "city": "Yelimane",
      "state": "Kayes",
      "country": "ML",
      "elevation": 325,
      "lat": 15.1330003738,
      "lon": -10.5670003891,
      "tz": "Africa\/Bamako"
  },
  "GBYD": {
      "icao": "GBYD",
      "iata": "BJL",
      "name": "Banjul International Airport",
      "city": "Banjul",
      "state": "Western",
      "country": "GM",
      "elevation": 95,
      "lat": 13.3380002975,
      "lon": -16.6522006989,
      "tz": "Africa\/Banjul"
  },
  "GCFV": {
      "icao": "GCFV",
      "iata": "FUE",
      "name": "Fuerteventura Airport",
      "city": "Fuerteventura Island",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 85,
      "lat": 28.4526996613,
      "lon": -13.8638000488,
      "tz": "Atlantic\/Canary"
  },
  "GCGM": {
      "icao": "GCGM",
      "iata": "GMZ",
      "name": "La Gomera Airport",
      "city": "Alajero",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 716,
      "lat": 28.0296001434,
      "lon": -17.2145996094,
      "tz": "Atlantic\/Canary"
  },
  "GCHI": {
      "icao": "GCHI",
      "iata": "VDE",
      "name": "Hierro Airport",
      "city": "El Hierro Island",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 103,
      "lat": 27.8148002625,
      "lon": -17.8871002197,
      "tz": "Atlantic\/Canary"
  },
  "GCLA": {
      "icao": "GCLA",
      "iata": "SPC",
      "name": "La Palma Airport",
      "city": "Sta Cruz de la Palma",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 107,
      "lat": 28.626499176,
      "lon": -17.7555999756,
      "tz": "Atlantic\/Canary"
  },
  "GCLB": {
      "icao": "GCLB",
      "iata": "",
      "name": "El Berriel Aeroc Airport",
      "city": "Gran Canaria Island",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 20,
      "lat": 27.7824993134,
      "lon": -15.5072002411,
      "tz": "Atlantic\/Canary"
  },
  "GCLP": {
      "icao": "GCLP",
      "iata": "LPA",
      "name": "Gran Canaria Airport",
      "city": "Gran Canaria Island",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 78,
      "lat": 27.9319000244,
      "lon": -15.3865995407,
      "tz": "Atlantic\/Canary"
  },
  "GCRR": {
      "icao": "GCRR",
      "iata": "ACE",
      "name": "Lanzarote Airport",
      "city": "Lanzarote Island",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 46,
      "lat": 28.9454994202,
      "lon": -13.6051998138,
      "tz": "Atlantic\/Canary"
  },
  "GCTS": {
      "icao": "GCTS",
      "iata": "TFS",
      "name": "Tenerife South Airport",
      "city": "Tenerife Island",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 209,
      "lat": 28.044500351,
      "lon": -16.5725002289,
      "tz": "Atlantic\/Canary"
  },
  "GCXO": {
      "icao": "GCXO",
      "iata": "TFN",
      "name": "Tenerife Norte Airport",
      "city": "Tenerife Island",
      "state": "Canary-Islands",
      "country": "ES",
      "elevation": 2076,
      "lat": 28.4827003479,
      "lon": -16.3414993286,
      "tz": "Atlantic\/Canary"
  },
  "GE01": {
      "icao": "GE01",
      "iata": "",
      "name": "Christians Folly Airport",
      "city": "Naylor",
      "state": "Georgia",
      "country": "US",
      "elevation": 208,
      "lat": 30.9190006256,
      "lon": -83.0688018799,
      "tz": "America\/New_York"
  },
  "GE04": {
      "icao": "GE04",
      "iata": "",
      "name": "Mclendon Field",
      "city": "Mulberry Grove",
      "state": "Georgia",
      "country": "US",
      "elevation": 650,
      "lat": 32.666944,
      "lon": -84.965556,
      "tz": "America\/New_York"
  },
  "GE05": {
      "icao": "GE05",
      "iata": "",
      "name": "Gibson Field",
      "city": "Lafayette",
      "state": "Georgia",
      "country": "US",
      "elevation": 750,
      "lat": 34.6156005859,
      "lon": -85.3431015015,
      "tz": "America\/New_York"
  },
  "GE11": {
      "icao": "GE11",
      "iata": "",
      "name": "Hogjowl Airport",
      "city": "Layfayette",
      "state": "Georgia",
      "country": "US",
      "elevation": 1790,
      "lat": 34.6058006287,
      "lon": -85.4618988037,
      "tz": "America\/New_York"
  },
  "GE12": {
      "icao": "GE12",
      "iata": "",
      "name": "Richter Airpark",
      "city": "Ashburn",
      "state": "Georgia",
      "country": "US",
      "elevation": 357,
      "lat": 31.7486000061,
      "lon": -83.573600769,
      "tz": "America\/New_York"
  },
  "GE23": {
      "icao": "GE23",
      "iata": "",
      "name": "Winge Farms Airport",
      "city": "Lyons",
      "state": "Georgia",
      "country": "US",
      "elevation": 195,
      "lat": 32.1935997009,
      "lon": -82.2906036377,
      "tz": "America\/New_York"
  },
  "GE25": {
      "icao": "GE25",
      "iata": "",
      "name": "Hutto Farm Airport",
      "city": "Fort Valley",
      "state": "Georgia",
      "country": "US",
      "elevation": 620,
      "lat": 32.6833000183,
      "lon": -83.8569030762,
      "tz": "America\/New_York"
  },
  "GE26": {
      "icao": "GE26",
      "iata": "",
      "name": "Duke Strip 2",
      "city": "Dublin",
      "state": "Georgia",
      "country": "US",
      "elevation": 340,
      "lat": 32.381667,
      "lon": -83.059722,
      "tz": "America\/New_York"
  },
  "GE27": {
      "icao": "GE27",
      "iata": "",
      "name": "Smith Field",
      "city": "Byron",
      "state": "Georgia",
      "country": "US",
      "elevation": 475,
      "lat": 32.6021995544,
      "lon": -83.7542037964,
      "tz": "America\/New_York"
  },
  "GE30": {
      "icao": "GE30",
      "iata": "",
      "name": "Sandy Hill Airport",
      "city": "Waynesboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 310,
      "lat": 33.2210998535,
      "lon": -81.995300293,
      "tz": "America\/New_York"
  },
  "GE33": {
      "icao": "GE33",
      "iata": "",
      "name": "River Bend Airport",
      "city": "Locust Grove",
      "state": "Georgia",
      "country": "US",
      "elevation": 718,
      "lat": 33.304444,
      "lon": -84.136667,
      "tz": "America\/New_York"
  },
  "GE35": {
      "icao": "GE35",
      "iata": "",
      "name": "Southern Oaks Airport",
      "city": "Bostwick",
      "state": "Georgia",
      "country": "US",
      "elevation": 121,
      "lat": 33.731389,
      "lon": -83.469722,
      "tz": "America\/New_York"
  },
  "GE99": {
      "icao": "GE99",
      "iata": "",
      "name": "Heaven's Landing Airport",
      "city": "Clayton",
      "state": "Georgia",
      "country": "US",
      "elevation": 2724,
      "lat": 34.9143981934,
      "lon": -83.4597015381,
      "tz": "America\/New_York"
  },
  "GEML": {
      "icao": "GEML",
      "iata": "MLN",
      "name": "Melilla Airport",
      "city": "Melilla Island",
      "state": "Melilla",
      "country": "ES",
      "elevation": 156,
      "lat": 35.279800415,
      "lon": -2.9562599659,
      "tz": "Africa\/Casablanca"
  },
  "GFBN": {
      "icao": "GFBN",
      "iata": "BTE",
      "name": "Sherbro International Airport",
      "city": "Bonthe",
      "state": "Southern-Province",
      "country": "SL",
      "elevation": 14,
      "lat": 7.5324201584,
      "lon": -12.5188999176,
      "tz": "Africa\/Freetown"
  },
  "GFBO": {
      "icao": "GFBO",
      "iata": "KBS",
      "name": "Bo Airport",
      "city": "Bo",
      "state": "Southern-Province",
      "country": "SL",
      "elevation": 328,
      "lat": 7.9443998337,
      "lon": -11.7609996796,
      "tz": "Africa\/Freetown"
  },
  "GFGK": {
      "icao": "GFGK",
      "iata": "GBK",
      "name": "Gbangbatok Airport",
      "city": "Gbangbatok",
      "state": "Southern-Province",
      "country": "SL",
      "elevation": 75,
      "lat": 7.7670001984,
      "lon": -12.3830003738,
      "tz": "Africa\/Freetown"
  },
  "GFHA": {
      "icao": "GFHA",
      "iata": "HGS",
      "name": "Hastings Airport",
      "city": "Freetown",
      "state": "Western-Area",
      "country": "SL",
      "elevation": 60,
      "lat": 8.3971300125,
      "lon": -13.1290998459,
      "tz": "Africa\/Freetown"
  },
  "GFKB": {
      "icao": "GFKB",
      "iata": "KBA",
      "name": "Kabala Airport",
      "city": "Kabala",
      "state": "Northern-Province",
      "country": "SL",
      "elevation": 1012,
      "lat": 9.6383229134,
      "lon": -11.5155601501,
      "tz": "Africa\/Freetown"
  },
  "GFKE": {
      "icao": "GFKE",
      "iata": "KEN",
      "name": "Kenema Airport",
      "city": "Kenema",
      "state": "Eastern-Province",
      "country": "SL",
      "elevation": 485,
      "lat": 7.8912901878,
      "lon": -11.1766004562,
      "tz": "Africa\/Freetown"
  },
  "GFLL": {
      "icao": "GFLL",
      "iata": "FNA",
      "name": "Lungi International Airport",
      "city": "Freetown",
      "state": "Northern-Province",
      "country": "SL",
      "elevation": 84,
      "lat": 8.6164398193,
      "lon": -13.1955003738,
      "tz": "Africa\/Freetown"
  },
  "GFTO": {
      "icao": "GFTO",
      "iata": "",
      "name": "Tongo Airport",
      "city": "Tongo",
      "state": "Eastern-Province",
      "country": "SL",
      "elevation": 750,
      "lat": 8.2170000076,
      "lon": -11.0670003891,
      "tz": "Africa\/Freetown"
  },
  "GFYE": {
      "icao": "GFYE",
      "iata": "WYE",
      "name": "Yengema Airport",
      "city": "Yengema",
      "state": "Eastern-Province",
      "country": "SL",
      "elevation": 1300,
      "lat": 8.6104698181,
      "lon": -11.0453996658,
      "tz": "Africa\/Freetown"
  },
  "GG64": {
      "icao": "GG64",
      "iata": "",
      "name": "Quebo Airport",
      "city": "Quebo",
      "state": "",
      "country": "GW",
      "elevation": 165,
      "lat": 11.5355997086,
      "lon": -14.7622995377,
      "tz": "Africa\/Bissau"
  },
  "GGBF": {
      "icao": "GGBF",
      "iata": "",
      "name": "Bafata Airport",
      "city": "Bafata",
      "state": "",
      "country": "GW",
      "elevation": 0,
      "lat": 12.1666669846,
      "lon": -14.6666669846,
      "tz": "Africa\/Bissau"
  },
  "GGBU": {
      "icao": "GGBU",
      "iata": "BQE",
      "name": "Bubaque Airport",
      "city": "Bubaque",
      "state": "Bolama-and-Bijagos",
      "country": "GW",
      "elevation": 0,
      "lat": 11.2973556519,
      "lon": -15.8380794525,
      "tz": "Africa\/Bissau"
  },
  "GGCF": {
      "icao": "GGCF",
      "iata": "",
      "name": "Cufar Airport",
      "city": "Cufar",
      "state": "",
      "country": "GW",
      "elevation": 65,
      "lat": 11.287899971,
      "lon": -15.1805000305,
      "tz": "Africa\/Bissau"
  },
  "GGOV": {
      "icao": "GGOV",
      "iata": "OXB",
      "name": "Osvaldo Vieira International Airport",
      "city": "Bissau",
      "state": "",
      "country": "GW",
      "elevation": 129,
      "lat": 11.8948001862,
      "lon": -15.6536998749,
      "tz": "Africa\/Bissau"
  },
  "GLBU": {
      "icao": "GLBU",
      "iata": "UCN",
      "name": "Buchanan Airport",
      "city": "Buchanan",
      "state": "Sinoe",
      "country": "LR",
      "elevation": 41,
      "lat": 5.1676502228,
      "lon": -9.2834701538,
      "tz": "Africa\/Monrovia"
  },
  "GLCM": {
      "icao": "GLCM",
      "iata": "",
      "name": "Robertsport\/Cape Mount Airport",
      "city": "Robertsport\/Cape Mount",
      "state": "Grand-Cape-Mount",
      "country": "LR",
      "elevation": 10,
      "lat": 6.8000001907,
      "lon": -11.3669996262,
      "tz": "Africa\/Monrovia"
  },
  "GLCP": {
      "icao": "GLCP",
      "iata": "CPA",
      "name": "Cape Palmas Airport",
      "city": "Harper",
      "state": "Maryland",
      "country": "LR",
      "elevation": 20,
      "lat": 4.3790202141,
      "lon": -7.6969499588,
      "tz": "Africa\/Monrovia"
  },
  "GLGE": {
      "icao": "GLGE",
      "iata": "SNI",
      "name": "Greenville Sinoe Airport",
      "city": "Greenville",
      "state": "Sinoe",
      "country": "LR",
      "elevation": 10,
      "lat": 5.034309864,
      "lon": -9.0668001175,
      "tz": "Africa\/Monrovia"
  },
  "GLLB": {
      "icao": "GLLB",
      "iata": "",
      "name": "Lamco Airport",
      "city": "Buchanan",
      "state": "Grand-Bassa",
      "country": "LR",
      "elevation": 30,
      "lat": 5.867000103,
      "lon": -10.0500001907,
      "tz": "Africa\/Monrovia"
  },
  "GLMR": {
      "icao": "GLMR",
      "iata": "MLW",
      "name": "Spriggs Payne Airport",
      "city": "Monrovia",
      "state": "Montserrado",
      "country": "LR",
      "elevation": 25,
      "lat": 6.2890601158,
      "lon": -10.7587003708,
      "tz": "Africa\/Monrovia"
  },
  "GLNA": {
      "icao": "GLNA",
      "iata": "NIA",
      "name": "Nimba Airport",
      "city": "Nimba",
      "state": "Nimba",
      "country": "LR",
      "elevation": 1632,
      "lat": 7.5,
      "lon": -8.6000003815,
      "tz": "Africa\/Monrovia"
  },
  "GLRB": {
      "icao": "GLRB",
      "iata": "ROB",
      "name": "Roberts International Airport",
      "city": "Monrovia",
      "state": "Margibi",
      "country": "LR",
      "elevation": 31,
      "lat": 6.2337899208,
      "lon": -10.3622999191,
      "tz": "Africa\/Monrovia"
  },
  "GLST": {
      "icao": "GLST",
      "iata": "SAZ",
      "name": "Sasstown Airport",
      "city": "Sasstown",
      "state": "Sinoe",
      "country": "LR",
      "elevation": 6,
      "lat": 4.6666669846,
      "lon": -8.4333333969,
      "tz": "Africa\/Monrovia"
  },
  "GLTN": {
      "icao": "GLTN",
      "iata": "THC",
      "name": "Tchien Airport",
      "city": "Tchien",
      "state": "Grand-Gedeh",
      "country": "LR",
      "elevation": 790,
      "lat": 6.0456504822,
      "lon": -8.1387233734,
      "tz": "Africa\/Monrovia"
  },
  "GLVA": {
      "icao": "GLVA",
      "iata": "VOI",
      "name": "Voinjama Airport",
      "city": "Voinjama",
      "state": "Lofa",
      "country": "LR",
      "elevation": 1400,
      "lat": 8.3999996185,
      "lon": -9.7670001984,
      "tz": "Africa\/Monrovia"
  },
  "GMAA": {
      "icao": "GMAA",
      "iata": "",
      "name": "Inezgane Airport",
      "city": "",
      "state": "Souss-Massa",
      "country": "MA",
      "elevation": 89,
      "lat": 30.3813991547,
      "lon": -9.5463104248,
      "tz": "Africa\/Casablanca"
  },
  "GMAD": {
      "icao": "GMAD",
      "iata": "AGA",
      "name": "Al Massira Airport",
      "city": "Agadir",
      "state": "Souss-Massa",
      "country": "MA",
      "elevation": 250,
      "lat": 30.3250007629,
      "lon": -9.413069725,
      "tz": "Africa\/Casablanca"
  },
  "GMAT": {
      "icao": "GMAT",
      "iata": "TTA",
      "name": "Tan Tan Airport",
      "city": "Tan Tan",
      "state": "Guelmim-Oued-Noun",
      "country": "MA",
      "elevation": 653,
      "lat": 28.4482002258,
      "lon": -11.1612997055,
      "tz": "Africa\/Casablanca"
  },
  "GMAZ": {
      "icao": "GMAZ",
      "iata": "OZG",
      "name": "Zagora Airport",
      "city": "Zagora",
      "state": "Draa-Tafilalet",
      "country": "MA",
      "elevation": 2631,
      "lat": 30.3202991486,
      "lon": -5.8666701317,
      "tz": "Africa\/Casablanca"
  },
  "GMFA": {
      "icao": "GMFA",
      "iata": "",
      "name": "Ouezzane Airport",
      "city": "Beni Malek",
      "state": "Tanger-Tetouan-Al-Hoceima",
      "country": "MA",
      "elevation": 571,
      "lat": 34.7832984924,
      "lon": -5.6333298683,
      "tz": "Africa\/Casablanca"
  },
  "GMFB": {
      "icao": "GMFB",
      "iata": "UAR",
      "name": "Bouarfa Airport",
      "city": "Bouarfa",
      "state": "",
      "country": "MA",
      "elevation": 3630,
      "lat": 32.5143055556,
      "lon": -1.9830555556,
      "tz": "Africa\/Casablanca"
  },
  "GMFF": {
      "icao": "GMFF",
      "iata": "FEZ",
      "name": "Saiss Airport",
      "city": "Fes",
      "state": "Fes-Meknes",
      "country": "MA",
      "elevation": 1900,
      "lat": 33.9272994995,
      "lon": -4.9779601097,
      "tz": "Africa\/Casablanca"
  },
  "GMFI": {
      "icao": "GMFI",
      "iata": "",
      "name": "Ifrane Airport",
      "city": "",
      "state": "Fes-Meknes",
      "country": "MA",
      "elevation": 5459,
      "lat": 33.5052986145,
      "lon": -5.152900219,
      "tz": "Africa\/Casablanca"
  },
  "GMFK": {
      "icao": "GMFK",
      "iata": "ERH",
      "name": "Moulay Ali Cherif Airport",
      "city": "Errachidia",
      "state": "",
      "country": "MA",
      "elevation": 3428,
      "lat": 31.9475002289,
      "lon": -4.3983302116,
      "tz": "Africa\/Casablanca"
  },
  "GMFM": {
      "icao": "GMFM",
      "iata": "MEK",
      "name": "Bassatine Airport",
      "city": "Meknes",
      "state": "Fes-Meknes",
      "country": "MA",
      "elevation": 1890,
      "lat": 33.8791007996,
      "lon": -5.5151200294,
      "tz": "Africa\/Casablanca"
  },
  "GMFN": {
      "icao": "GMFN",
      "iata": "",
      "name": "Taouima Nador Airport",
      "city": "Nador",
      "state": "Oriental",
      "country": "MA",
      "elevation": 10,
      "lat": 35.1500015259,
      "lon": -2.9170000553,
      "tz": "Africa\/Casablanca"
  },
  "GMFO": {
      "icao": "GMFO",
      "iata": "OUD",
      "name": "Angads Airport",
      "city": "Oujda",
      "state": "Oriental",
      "country": "MA",
      "elevation": 1535,
      "lat": 34.7872009277,
      "lon": -1.9239900112,
      "tz": "Africa\/Casablanca"
  },
  "GMFU": {
      "icao": "GMFU",
      "iata": "",
      "name": "Fes Sefrou Airport",
      "city": "Fes",
      "state": "Fes-Meknes",
      "country": "MA",
      "elevation": 1539,
      "lat": 34.0080986023,
      "lon": -4.9655599594,
      "tz": "Africa\/Casablanca"
  },
  "GMFZ": {
      "icao": "GMFZ",
      "iata": "",
      "name": "Taza Airport",
      "city": "Taza",
      "state": "Fes-Meknes",
      "country": "MA",
      "elevation": 1890,
      "lat": 34.2332992554,
      "lon": -3.9500000477,
      "tz": "Africa\/Casablanca"
  },
  "GMMA": {
      "icao": "GMMA",
      "iata": "SMW",
      "name": "Smara Airport",
      "city": "Smara",
      "state": "Laayoune-Sakia-El-Hamra",
      "country": "EH",
      "elevation": 350,
      "lat": 26.7318000793,
      "lon": -11.6847000122,
      "tz": "Africa\/El_Aaiun"
  },
  "GMMB": {
      "icao": "GMMB",
      "iata": "GMD",
      "name": "Ben Slimane Airport",
      "city": "Ben Slimane",
      "state": "Casablanca-Settat",
      "country": "MA",
      "elevation": 627,
      "lat": 33.6553993225,
      "lon": -7.221449852,
      "tz": "Africa\/Casablanca"
  },
  "GMMC": {
      "icao": "GMMC",
      "iata": "CAS",
      "name": "Anfa Airport",
      "city": "Casablanca",
      "state": "Casablanca-Settat",
      "country": "MA",
      "elevation": 200,
      "lat": 33.5532989502,
      "lon": -7.6613898277,
      "tz": "Africa\/Casablanca"
  },
  "GMMD": {
      "icao": "GMMD",
      "iata": "BEM",
      "name": "Beni Mellal Airport",
      "city": "",
      "state": "Beni-Mellal-Khenifra",
      "country": "MA",
      "elevation": 1670,
      "lat": 32.4000015259,
      "lon": -6.3333301544,
      "tz": "Africa\/Casablanca"
  },
  "GMME": {
      "icao": "GMME",
      "iata": "RBA",
      "name": "Rabat-Sale Airport",
      "city": "Rabat",
      "state": "",
      "country": "MA",
      "elevation": 276,
      "lat": 34.0514984131,
      "lon": -6.7515201569,
      "tz": "Africa\/Casablanca"
  },
  "GMMF": {
      "icao": "GMMF",
      "iata": "SII",
      "name": "Sidi Ifni Xx Airport",
      "city": "Sidi Ifni",
      "state": "Guelmim-Oued-Noun",
      "country": "MA",
      "elevation": 190,
      "lat": 29.3666992188,
      "lon": -10.1878004074,
      "tz": "Africa\/Casablanca"
  },
  "GMMH": {
      "icao": "GMMH",
      "iata": "VIL",
      "name": "Dakhla Airport",
      "city": "Dakhla",
      "state": "Dakhla-Oued-Ed-Dahab",
      "country": "EH",
      "elevation": 36,
      "lat": 23.7182998657,
      "lon": -15.9320001602,
      "tz": "Africa\/El_Aaiun"
  },
  "GMMI": {
      "icao": "GMMI",
      "iata": "ESU",
      "name": "Mogador Airport",
      "city": "Essaouira",
      "state": "",
      "country": "MA",
      "elevation": 384,
      "lat": 31.3974990845,
      "lon": -9.6816701889,
      "tz": "Africa\/Casablanca"
  },
  "GMMJ": {
      "icao": "GMMJ",
      "iata": "",
      "name": "El Jadida Airport",
      "city": "El Jadida",
      "state": "Casablanca-Settat",
      "country": "MA",
      "elevation": 92,
      "lat": 33.2332992554,
      "lon": -8.5166702271,
      "tz": "Africa\/Casablanca"
  },
  "GMML": {
      "icao": "GMML",
      "iata": "EUN",
      "name": "Hassan I Airport",
      "city": "El Aaiun",
      "state": "Laayoune-Sakia-El-Hamra",
      "country": "EH",
      "elevation": 207,
      "lat": 27.1516990662,
      "lon": -13.2192001343,
      "tz": "Africa\/El_Aaiun"
  },
  "GMMN": {
      "icao": "GMMN",
      "iata": "CMN",
      "name": "Mohammed V International Airport",
      "city": "Casablanca",
      "state": "",
      "country": "MA",
      "elevation": 656,
      "lat": 33.3675003052,
      "lon": -7.5899701118,
      "tz": "Africa\/Casablanca"
  },
  "GMMO": {
      "icao": "GMMO",
      "iata": "",
      "name": "Taroudant Airport",
      "city": "Taroudant",
      "state": "Souss-Massa",
      "country": "MA",
      "elevation": 869,
      "lat": 30.5016994476,
      "lon": -8.8275003433,
      "tz": "Africa\/Casablanca"
  },
  "GMMS": {
      "icao": "GMMS",
      "iata": "SFI",
      "name": "Safi Airport",
      "city": "Safi",
      "state": "Marrakesh-Safi",
      "country": "MA",
      "elevation": 171,
      "lat": 32.2832984924,
      "lon": -9.2333297729,
      "tz": "Africa\/Casablanca"
  },
  "GMMT": {
      "icao": "GMMT",
      "iata": "",
      "name": "Tit Mellil Airport",
      "city": "Casablanca",
      "state": "Casablanca-Settat",
      "country": "MA",
      "elevation": 322,
      "lat": 33.5974998474,
      "lon": -7.4650001526,
      "tz": "Africa\/Casablanca"
  },
  "GMMW": {
      "icao": "GMMW",
      "iata": "NDR",
      "name": "Nador International Airport",
      "city": "Nador",
      "state": "Oriental",
      "country": "MA",
      "elevation": 574,
      "lat": 34.9888000488,
      "lon": -3.0282099247,
      "tz": "Africa\/Casablanca"
  },
  "GMMX": {
      "icao": "GMMX",
      "iata": "RAK",
      "name": "Menara Airport",
      "city": "Marrakech",
      "state": "Marrakesh-Safi",
      "country": "MA",
      "elevation": 1545,
      "lat": 31.6068992615,
      "lon": -8.0362997055,
      "tz": "Africa\/Casablanca"
  },
  "GMMY": {
      "icao": "GMMY",
      "iata": "NNA",
      "name": "Kenitra Airport",
      "city": "",
      "state": "",
      "country": "MA",
      "elevation": 16,
      "lat": 34.2989006042,
      "lon": -6.5958800316,
      "tz": "Africa\/Casablanca"
  },
  "GMMZ": {
      "icao": "GMMZ",
      "iata": "OZZ",
      "name": "Ouarzazate Airport",
      "city": "Ouarzazate",
      "state": "Draa-Tafilalet",
      "country": "MA",
      "elevation": 3782,
      "lat": 30.9391002655,
      "lon": -6.909430027,
      "tz": "Africa\/Casablanca"
  },
  "GMSL": {
      "icao": "GMSL",
      "iata": "",
      "name": "Sidi Slimane Airport",
      "city": "Sidi Slimane",
      "state": "Rabat-Sale-Kenitra",
      "country": "MA",
      "elevation": 179,
      "lat": 34.2305984497,
      "lon": -6.050139904,
      "tz": "Africa\/Casablanca"
  },
  "GMTA": {
      "icao": "GMTA",
      "iata": "AHU",
      "name": "Cherif Al Idrissi Airport",
      "city": "Al Hoceima",
      "state": "Tanger-Tetouan-Al-Hoceima",
      "country": "MA",
      "elevation": 95,
      "lat": 35.1771011353,
      "lon": -3.8395199776,
      "tz": "Africa\/Casablanca"
  },
  "GMTN": {
      "icao": "GMTN",
      "iata": "TTU",
      "name": "Saniat Rmel Airport",
      "city": "",
      "state": "",
      "country": "MA",
      "elevation": 10,
      "lat": 35.5942993164,
      "lon": -5.3200201988,
      "tz": "Africa\/Casablanca"
  },
  "GMTT": {
      "icao": "GMTT",
      "iata": "TNG",
      "name": "Ibn Batouta Airport",
      "city": "Tangier",
      "state": "",
      "country": "MA",
      "elevation": 62,
      "lat": 35.7268981934,
      "lon": -5.9168901444,
      "tz": "Africa\/Casablanca"
  },
  "GO66": {
      "icao": "GO66",
      "iata": "",
      "name": "Dodji Airport",
      "city": "Dodji",
      "state": "Louga",
      "country": "SN",
      "elevation": 66,
      "lat": 15.5438995361,
      "lon": -14.9582996368,
      "tz": "Africa\/Dakar"
  },
  "GOBD": {
      "icao": "GOBD",
      "iata": "DSS",
      "name": "Blaise Diagne International Airport",
      "city": "Diass",
      "state": "M'bour",
      "country": "SN",
      "elevation": 289,
      "lat": 14.671111,
      "lon": -17.066944,
      "tz": "Africa\/Dakar"
  },
  "GODK": {
      "icao": "GODK",
      "iata": "KDA",
      "name": "Kolda North Airport",
      "city": "Kolda",
      "state": "Kolda",
      "country": "SN",
      "elevation": 33,
      "lat": 12.8985004425,
      "lon": -14.9680995941,
      "tz": "Africa\/Dakar"
  },
  "GOGG": {
      "icao": "GOGG",
      "iata": "ZIG",
      "name": "Ziguinchor Airport",
      "city": "Ziguinchor",
      "state": "Ziguinchor",
      "country": "SN",
      "elevation": 75,
      "lat": 12.5556001663,
      "lon": -16.2817993164,
      "tz": "Africa\/Dakar"
  },
  "GOGS": {
      "icao": "GOGS",
      "iata": "CSK",
      "name": "Cap Skirring Airport",
      "city": "Cap Skirring",
      "state": "Ziguinchor",
      "country": "SN",
      "elevation": 52,
      "lat": 12.410200119,
      "lon": -16.746099472,
      "tz": "Africa\/Dakar"
  },
  "GOOK": {
      "icao": "GOOK",
      "iata": "KLC",
      "name": "Kaolack Airport",
      "city": "Kaolack",
      "state": "Kaolack",
      "country": "SN",
      "elevation": 26,
      "lat": 14.146900177,
      "lon": -16.0513000488,
      "tz": "Africa\/Dakar"
  },
  "GOOY": {
      "icao": "GOOY",
      "iata": "DKR",
      "name": "Leopold Sedar Senghor International Airport",
      "city": "Dakar",
      "state": "Dakar",
      "country": "SN",
      "elevation": 85,
      "lat": 14.7397003174,
      "lon": -17.4902000427,
      "tz": "Africa\/Dakar"
  },
  "GOSM": {
      "icao": "GOSM",
      "iata": "MAX",
      "name": "Ouro Sogui Airport",
      "city": "Matam",
      "state": "Saint-Louis",
      "country": "SN",
      "elevation": 85,
      "lat": 15.5936002731,
      "lon": -13.3227996826,
      "tz": "Africa\/Dakar"
  },
  "GOSP": {
      "icao": "GOSP",
      "iata": "POD",
      "name": "Podor Airport",
      "city": "Podor",
      "state": "Saint-Louis",
      "country": "SN",
      "elevation": 20,
      "lat": 16.6830005646,
      "lon": -14.9670000076,
      "tz": "Africa\/Dakar"
  },
  "GOSR": {
      "icao": "GOSR",
      "iata": "RDT",
      "name": "Richard Toll Airport",
      "city": "Richard Toll",
      "state": "Saint-Louis",
      "country": "SN",
      "elevation": 20,
      "lat": 16.4330005646,
      "lon": -15.6499996185,
      "tz": "Africa\/Dakar"
  },
  "GOSS": {
      "icao": "GOSS",
      "iata": "XLS",
      "name": "Saint Louis Airport",
      "city": "Saint Louis",
      "state": "Saint-Louis",
      "country": "SN",
      "elevation": 9,
      "lat": 16.0508003235,
      "lon": -16.4631996155,
      "tz": "Africa\/Dakar"
  },
  "GOTB": {
      "icao": "GOTB",
      "iata": "BXE",
      "name": "Bakel Airport",
      "city": "Bakel",
      "state": "Tambacounda",
      "country": "SN",
      "elevation": 98,
      "lat": 14.8472995758,
      "lon": -12.4682998657,
      "tz": "Africa\/Dakar"
  },
  "GOTK": {
      "icao": "GOTK",
      "iata": "KGG",
      "name": "Kedougou Airport",
      "city": "Kedougou",
      "state": "Kedougou",
      "country": "SN",
      "elevation": 584,
      "lat": 12.5722999573,
      "lon": -12.2202997208,
      "tz": "Africa\/Dakar"
  },
  "GOTS": {
      "icao": "GOTS",
      "iata": "SMY",
      "name": "Simenti Airport",
      "city": "Simenti",
      "state": "Tambacounda",
      "country": "SN",
      "elevation": 171,
      "lat": 13.0467996597,
      "lon": -13.2953996658,
      "tz": "Africa\/Dakar"
  },
  "GOTT": {
      "icao": "GOTT",
      "iata": "TUD",
      "name": "Tambacounda Airport",
      "city": "Tambacounda",
      "state": "Tambacounda",
      "country": "SN",
      "elevation": 161,
      "lat": 13.7368001938,
      "lon": -13.6531000137,
      "tz": "Africa\/Dakar"
  },
  "GQNA": {
      "icao": "GQNA",
      "iata": "AEO",
      "name": "Aioun el Atrouss Airport",
      "city": "Aioun El Atrouss",
      "state": "",
      "country": "MR",
      "elevation": 951,
      "lat": 16.7112998962,
      "lon": -9.6378803253,
      "tz": "Africa\/Nouakchott"
  },
  "GQNB": {
      "icao": "GQNB",
      "iata": "OTL",
      "name": "Boutilimit Airport",
      "city": "Boutilimit",
      "state": "Trarza",
      "country": "MR",
      "elevation": 121,
      "lat": 17.533000946,
      "lon": -14.6829996109,
      "tz": "Africa\/Nouakchott"
  },
  "GQNC": {
      "icao": "GQNC",
      "iata": "THI",
      "name": "Tichitt Airport",
      "city": "Tichitt",
      "state": "Tagant",
      "country": "MR",
      "elevation": 561,
      "lat": 18.4500007629,
      "lon": -9.5170001984,
      "tz": "Africa\/Nouakchott"
  },
  "GQND": {
      "icao": "GQND",
      "iata": "TIY",
      "name": "Tidjikja Airport",
      "city": "Tidjikja",
      "state": "Tagant",
      "country": "MR",
      "elevation": 1363,
      "lat": 18.5701007843,
      "lon": -11.423500061,
      "tz": "Africa\/Nouakchott"
  },
  "GQNE": {
      "icao": "GQNE",
      "iata": "BGH",
      "name": "Abbaye Airport",
      "city": "Boghe",
      "state": "Brakna",
      "country": "MR",
      "elevation": 66,
      "lat": 16.6333141327,
      "lon": -14.2000007629,
      "tz": "Africa\/Nouakchott"
  },
  "GQNF": {
      "icao": "GQNF",
      "iata": "KFA",
      "name": "Kiffa Airport",
      "city": "Kiffa",
      "state": "Assaba",
      "country": "MR",
      "elevation": 424,
      "lat": 16.5900001526,
      "lon": -11.4062004089,
      "tz": "Africa\/Nouakchott"
  },
  "GQNH": {
      "icao": "GQNH",
      "iata": "TMD",
      "name": "Timbedra Airport",
      "city": "Timbedra",
      "state": "Hodh-ech-Chargui",
      "country": "MR",
      "elevation": 692,
      "lat": 16.2329998016,
      "lon": -8.1669998169,
      "tz": "Africa\/Nouakchott"
  },
  "GQNI": {
      "icao": "GQNI",
      "iata": "EMN",
      "name": "Nema Airport",
      "city": "Nema",
      "state": "Hodh-ech-Chargui",
      "country": "MR",
      "elevation": 751,
      "lat": 16.6219997406,
      "lon": -7.3165998459,
      "tz": "Africa\/Nouakchott"
  },
  "GQNJ": {
      "icao": "GQNJ",
      "iata": "AJJ",
      "name": "Akjoujt Airport",
      "city": "Akjoujt",
      "state": "",
      "country": "MR",
      "elevation": 403,
      "lat": 19.7330169678,
      "lon": -14.3832492828,
      "tz": "Africa\/Nouakchott"
  },
  "GQNK": {
      "icao": "GQNK",
      "iata": "KED",
      "name": "Kaedi Airport",
      "city": "Kaedi",
      "state": "Gorgol",
      "country": "MR",
      "elevation": 66,
      "lat": 16.1595001221,
      "lon": -13.5075998306,
      "tz": "Africa\/Dakar"
  },
  "GQNL": {
      "icao": "GQNL",
      "iata": "MOM",
      "name": "Letfotar Airport",
      "city": "Moudjeria",
      "state": "Brakna",
      "country": "MR",
      "elevation": 256,
      "lat": 17.75,
      "lon": -12.5,
      "tz": "Africa\/Nouakchott"
  },
  "GQNM": {
      "icao": "GQNM",
      "iata": "",
      "name": "Dahara Airport",
      "city": "Timbreda",
      "state": "Hodh-ech-Chargui",
      "country": "MR",
      "elevation": 722,
      "lat": 16.2999992371,
      "lon": -8.0500001907,
      "tz": "Africa\/Nouakchott"
  },
  "GQNN": {
      "icao": "GQNN",
      "iata": "NKC",
      "name": "Nouakchott International Airport",
      "city": "Nouakchott",
      "state": "Trarza",
      "country": "MR",
      "elevation": 13,
      "lat": 18.0981998444,
      "lon": -15.9484996796,
      "tz": "Africa\/Nouakchott"
  },
  "GQNS": {
      "icao": "GQNS",
      "iata": "SEY",
      "name": "Selibaby Airport",
      "city": "Selibaby",
      "state": "Guidimaka",
      "country": "MR",
      "elevation": 219,
      "lat": 15.1796998978,
      "lon": -12.2073001862,
      "tz": "Africa\/Nouakchott"
  },
  "GQNT": {
      "icao": "GQNT",
      "iata": "THT",
      "name": "Tamchakett Airport",
      "city": "Tamchakett",
      "state": "Hodh-El-Gharbi",
      "country": "MR",
      "elevation": 620,
      "lat": 17.2329998016,
      "lon": -10.8170003891,
      "tz": "Africa\/Nouakchott"
  },
  "GQPA": {
      "icao": "GQPA",
      "iata": "ATR",
      "name": "Atar International Airport",
      "city": "Atar",
      "state": "Adrar",
      "country": "MR",
      "elevation": 734,
      "lat": 20.5067996979,
      "lon": -13.0431995392,
      "tz": "Africa\/Nouakchott"
  },
  "GQPF": {
      "icao": "GQPF",
      "iata": "FGD",
      "name": "Fderik Airport",
      "city": "Fderik",
      "state": "Tiris-Zemmour",
      "country": "MR",
      "elevation": 961,
      "lat": 22.6669998169,
      "lon": -12.7329998016,
      "tz": "Africa\/Nouakchott"
  },
  "GQPP": {
      "icao": "GQPP",
      "iata": "NDB",
      "name": "Nouadhibou International Airport",
      "city": "Nouadhibou",
      "state": "Dakhlet-Nouadhibou",
      "country": "MR",
      "elevation": 24,
      "lat": 20.9330997467,
      "lon": -17.0300006866,
      "tz": "Africa\/El_Aaiun"
  },
  "GQPT": {
      "icao": "GQPT",
      "iata": "",
      "name": "Bir Moghrein Airport",
      "city": "Bir Moghrein",
      "state": "Tiris-Zemmour",
      "country": "MR",
      "elevation": 1206,
      "lat": 25.236700058,
      "lon": -11.5887002945,
      "tz": "Africa\/Nouakchott"
  },
  "GQPZ": {
      "icao": "GQPZ",
      "iata": "OUZ",
      "name": "Tazadit Airport",
      "city": "Zouerate",
      "state": "Tiris-Zemmour",
      "country": "MR",
      "elevation": 1129,
      "lat": 22.7563991547,
      "lon": -12.4835996628,
      "tz": "Africa\/Nouakchott"
  },
  "GUCY": {
      "icao": "GUCY",
      "iata": "CKY",
      "name": "Conakry Airport",
      "city": "Conakry",
      "state": "Conakry",
      "country": "GN",
      "elevation": 72,
      "lat": 9.57689,
      "lon": -13.612,
      "tz": "Africa\/Conakry"
  },
  "GUFA": {
      "icao": "GUFA",
      "iata": "FIG",
      "name": "Fria Airport",
      "city": "",
      "state": "Boke",
      "country": "GN",
      "elevation": 499,
      "lat": 10.3506002426,
      "lon": -13.5691995621,
      "tz": "Africa\/Conakry"
  },
  "GUFH": {
      "icao": "GUFH",
      "iata": "FAA",
      "name": "Faranah Airport",
      "city": "",
      "state": "Faranah",
      "country": "GN",
      "elevation": 1476,
      "lat": 10.0354995728,
      "lon": -10.7698001862,
      "tz": "Africa\/Conakry"
  },
  "GUGO": {
      "icao": "GUGO",
      "iata": "",
      "name": "Gbenko Airport",
      "city": "Banankoro",
      "state": "Faranah",
      "country": "GN",
      "elevation": 2133,
      "lat": 9.2460298538,
      "lon": -9.2951402664,
      "tz": "Africa\/Conakry"
  },
  "GUKR": {
      "icao": "GUKR",
      "iata": "",
      "name": "Kawass Airport",
      "city": "Kawass",
      "state": "Boke",
      "country": "GN",
      "elevation": 36,
      "lat": 10.6510000229,
      "lon": -14.5335998535,
      "tz": "Africa\/Conakry"
  },
  "GUKU": {
      "icao": "GUKU",
      "iata": "KSI",
      "name": "Kissidougou Airport",
      "city": "Kissidougou",
      "state": "Faranah",
      "country": "GN",
      "elevation": 1808,
      "lat": 9.1605596542,
      "lon": -10.1244001389,
      "tz": "Africa\/Conakry"
  },
  "GULB": {
      "icao": "GULB",
      "iata": "LEK",
      "name": "Labe Airport",
      "city": "",
      "state": "Labe",
      "country": "GN",
      "elevation": 3396,
      "lat": 11.3261003494,
      "lon": -12.2868003845,
      "tz": "Africa\/Conakry"
  },
  "GUMA": {
      "icao": "GUMA",
      "iata": "MCA",
      "name": "Macenta Airport",
      "city": "Macenta",
      "state": "Nzerekore",
      "country": "GN",
      "elevation": 1690,
      "lat": 8.5329999924,
      "lon": -9.4670000076,
      "tz": "Africa\/Conakry"
  },
  "GUNZ": {
      "icao": "GUNZ",
      "iata": "NZE",
      "name": "Nzerekore Airport",
      "city": "Nzerekore",
      "state": "Nzerekore",
      "country": "GN",
      "elevation": 1657,
      "lat": 7.8060193062,
      "lon": -8.7017974854,
      "tz": "Africa\/Conakry"
  },
  "GUOK": {
      "icao": "GUOK",
      "iata": "BKJ",
      "name": "Boke Airport",
      "city": "Boke",
      "state": "Boke",
      "country": "GN",
      "elevation": 164,
      "lat": 10.9658002853,
      "lon": -14.2811002731,
      "tz": "Africa\/Conakry"
  },
  "GUSA": {
      "icao": "GUSA",
      "iata": "",
      "name": "Sangaredi Airport",
      "city": "Sangaredi",
      "state": "Boke",
      "country": "GN",
      "elevation": 686,
      "lat": 11.1169996262,
      "lon": -13.8330001831,
      "tz": "Africa\/Conakry"
  },
  "GUSB": {
      "icao": "GUSB",
      "iata": "SBI",
      "name": "Sambailo Airport",
      "city": "Koundara",
      "state": "Boke",
      "country": "GN",
      "elevation": 295,
      "lat": 12.5726995468,
      "lon": -13.358499527,
      "tz": "Africa\/Conakry"
  },
  "GUSI": {
      "icao": "GUSI",
      "iata": "GII",
      "name": "Siguiri Airport",
      "city": "Siguiri",
      "state": "Kankan",
      "country": "GN",
      "elevation": 1296,
      "lat": 11.4329996109,
      "lon": -9.1669998169,
      "tz": "Africa\/Conakry"
  },
  "GUXN": {
      "icao": "GUXN",
      "iata": "KNN",
      "name": "Kankan Airport",
      "city": "Kankan",
      "state": "Kankan",
      "country": "GN",
      "elevation": 1234,
      "lat": 10.3940000534,
      "lon": -9.3037996292,
      "tz": "Africa\/Conakry"
  },
  "GVAC": {
      "icao": "GVAC",
      "iata": "SID",
      "name": "Amilcar Cabral International Airport",
      "city": "Espargos",
      "state": "Sal",
      "country": "CV",
      "elevation": 177,
      "lat": 16.741399765,
      "lon": -22.9493999481,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVAN": {
      "icao": "GVAN",
      "iata": "NTO",
      "name": "Agostinho Neto Airport",
      "city": "Ponta do Sol",
      "state": "Ribeira-Grande",
      "country": "CV",
      "elevation": 32,
      "lat": 17.2028007507,
      "lon": -25.0905990601,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVBA": {
      "icao": "GVBA",
      "iata": "BVC",
      "name": "Rabil Airport",
      "city": "Rabil",
      "state": "Boa-Vista",
      "country": "CV",
      "elevation": 69,
      "lat": 16.1364994049,
      "lon": -22.8889007568,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVBR": {
      "icao": "GVBR",
      "iata": "BVR",
      "name": "Esperadinha Airport",
      "city": "Brava Island",
      "state": "Brava",
      "country": "CV",
      "elevation": 64,
      "lat": 14.8643055556,
      "lon": -24.746,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVMA": {
      "icao": "GVMA",
      "iata": "MMO",
      "name": "Maio Airport",
      "city": "Vila do Maio",
      "state": "Maio",
      "country": "CV",
      "elevation": 36,
      "lat": 15.1559000015,
      "lon": -23.2136993408,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVMT": {
      "icao": "GVMT",
      "iata": "MTI",
      "name": "Mosteiros Airport",
      "city": "Vila do Mosteiros",
      "state": "Mosteiros",
      "country": "CV",
      "elevation": 66,
      "lat": 15.0450000763,
      "lon": -24.3391990662,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVNP": {
      "icao": "GVNP",
      "iata": "RAI",
      "name": "Praia International Airport",
      "city": "Praia",
      "state": "Praia",
      "country": "CV",
      "elevation": 230,
      "lat": 14.9245004654,
      "lon": -23.4934997559,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVSF": {
      "icao": "GVSF",
      "iata": "SFL",
      "name": "Sao Filipe Airport",
      "city": "Sao Filipe",
      "state": "Sao-Filipe",
      "country": "CV",
      "elevation": 617,
      "lat": 14.8850002289,
      "lon": -24.4799995422,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVSN": {
      "icao": "GVSN",
      "iata": "SNE",
      "name": "Preguica Airport",
      "city": "Preguica",
      "state": "Ribeira-Brava",
      "country": "CV",
      "elevation": 669,
      "lat": 16.5883998871,
      "lon": -24.2847003937,
      "tz": "Atlantic\/Cape_Verde"
  },
  "GVSV": {
      "icao": "GVSV",
      "iata": "VXE",
      "name": "Sao Pedro Airport",
      "city": "Sao Pedro",
      "state": "Sao-Vicente",
      "country": "CV",
      "elevation": 66,
      "lat": 16.8332004547,
      "lon": -25.0552997589,
      "tz": "Atlantic\/Cape_Verde"
  },
  "HAAB": {
      "icao": "HAAB",
      "iata": "ADD",
      "name": "Bole International Airport",
      "city": "Addis Ababa",
      "state": "Addis-Ababa",
      "country": "ET",
      "elevation": 7630,
      "lat": 8.9778900147,
      "lon": 38.7993011475,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAAD": {
      "icao": "HAAD",
      "iata": "",
      "name": "Adaba Airport",
      "city": "Adaba",
      "state": "Oromiya",
      "country": "ET",
      "elevation": 7896,
      "lat": 7.0500001907,
      "lon": 39.4000015259,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAAL": {
      "icao": "HAAL",
      "iata": "",
      "name": "Lideta Army Airport",
      "city": "Addis Ababa",
      "state": "",
      "country": "ET",
      "elevation": 7749,
      "lat": 9.0036802292,
      "lon": 38.7260017395,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAAM": {
      "icao": "HAAM",
      "iata": "AMH",
      "name": "Arba Minch Airport",
      "city": "",
      "state": "SNNPR",
      "country": "ET",
      "elevation": 3901,
      "lat": 6.0393900871,
      "lon": 37.5904998779,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAAX": {
      "icao": "HAAX",
      "iata": "AXU",
      "name": "Axum Airport",
      "city": "",
      "state": "Tigray",
      "country": "ET",
      "elevation": 6959,
      "lat": 14.1468000412,
      "lon": 38.7728004456,
      "tz": "Africa\/Addis_Ababa"
  },
  "HABC": {
      "icao": "HABC",
      "iata": "BCO",
      "name": "Baco Airport",
      "city": "Baco",
      "state": "",
      "country": "ET",
      "elevation": 0,
      "lat": 5.7828698158,
      "lon": 36.5620002747,
      "tz": "Africa\/Addis_Ababa"
  },
  "HABD": {
      "icao": "HABD",
      "iata": "BJR",
      "name": "Bahir Dar Airport",
      "city": "Bahir Dar",
      "state": "Amhara",
      "country": "ET",
      "elevation": 5978,
      "lat": 11.6080999374,
      "lon": 37.3216018677,
      "tz": "Africa\/Addis_Ababa"
  },
  "HABE": {
      "icao": "HABE",
      "iata": "BEI",
      "name": "Beica Airport",
      "city": "Beica",
      "state": "",
      "country": "ET",
      "elevation": 5410,
      "lat": 9.3863897324,
      "lon": 34.521900177,
      "tz": "Africa\/Addis_Ababa"
  },
  "HADC": {
      "icao": "HADC",
      "iata": "DSE",
      "name": "Combolcha Airport",
      "city": "Dessie",
      "state": "",
      "country": "ET",
      "elevation": 6117,
      "lat": 11.0825004578,
      "lon": 39.7113990784,
      "tz": "Africa\/Addis_Ababa"
  },
  "HADD": {
      "icao": "HADD",
      "iata": "DEM",
      "name": "Dembidollo Airport",
      "city": "Dembidollo",
      "state": "",
      "country": "ET",
      "elevation": 5200,
      "lat": 8.5539999008,
      "lon": 34.858001709,
      "tz": "Africa\/Addis_Ababa"
  },
  "HADM": {
      "icao": "HADM",
      "iata": "DBM",
      "name": "Debra Marcos Airport",
      "city": "Debra Marcos",
      "state": "Amhara",
      "country": "ET",
      "elevation": 8136,
      "lat": 10.3500003815,
      "lon": 37.716999054,
      "tz": "Africa\/Addis_Ababa"
  },
  "HADO": {
      "icao": "HADO",
      "iata": "",
      "name": "Dodola Airport",
      "city": "Dodola",
      "state": "Oromiya",
      "country": "ET",
      "elevation": 8234,
      "lat": 7.0205597878,
      "lon": 39.0511016846,
      "tz": "Africa\/Addis_Ababa"
  },
  "HADR": {
      "icao": "HADR",
      "iata": "DIR",
      "name": "Aba Tenna Dejazmach Yilma International Airport",
      "city": "Dire Dawa",
      "state": "Dire-Dawa",
      "country": "ET",
      "elevation": 3827,
      "lat": 9.6246995926,
      "lon": 41.8541984558,
      "tz": "Africa\/Addis_Ababa"
  },
  "HADT": {
      "icao": "HADT",
      "iata": "DBT",
      "name": "Debre Tabor Airport",
      "city": "Debre Tabor",
      "state": "",
      "country": "ET",
      "elevation": 8490,
      "lat": 11.9670000076,
      "lon": 38,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAFN": {
      "icao": "HAFN",
      "iata": "FNH",
      "name": "Fincha Airport",
      "city": "Fincha",
      "state": "",
      "country": "ET",
      "elevation": 7600,
      "lat": 9.5830001831,
      "lon": 37.3499984741,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAGB": {
      "icao": "HAGB",
      "iata": "GOB",
      "name": "Robe Airport",
      "city": "Goba",
      "state": "Oromiya",
      "country": "ET",
      "elevation": 7892,
      "lat": 7.0170001984,
      "lon": 40,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAGH": {
      "icao": "HAGH",
      "iata": "GNN",
      "name": "Ghinnir Airport",
      "city": "Ghinnir",
      "state": "Oromiya",
      "country": "ET",
      "elevation": 6499,
      "lat": 7.1500000954,
      "lon": 40.716999054,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAGM": {
      "icao": "HAGM",
      "iata": "GMB",
      "name": "Gambella Airport",
      "city": "Gambela",
      "state": "",
      "country": "ET",
      "elevation": 1614,
      "lat": 8.1287603378,
      "lon": 34.5630989075,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAGN": {
      "icao": "HAGN",
      "iata": "GDQ",
      "name": "Gonder Airport",
      "city": "Gondar",
      "state": "Amhara",
      "country": "ET",
      "elevation": 6449,
      "lat": 12.519900322,
      "lon": 37.4339981079,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAGO": {
      "icao": "HAGO",
      "iata": "GDE",
      "name": "Gode Airport",
      "city": "Gode",
      "state": "Somali",
      "country": "ET",
      "elevation": 834,
      "lat": 5.9351301193,
      "lon": 43.5786018372,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAGR": {
      "icao": "HAGR",
      "iata": "GOR",
      "name": "Gore Airport",
      "city": "Gore",
      "state": "",
      "country": "ET",
      "elevation": 6580,
      "lat": 8.1614,
      "lon": 35.5529,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAHM": {
      "icao": "HAHM",
      "iata": "QHR",
      "name": "Harar Meda Airport",
      "city": "Debre Zeyit",
      "state": "",
      "country": "ET",
      "elevation": 0,
      "lat": 8.7163,
      "lon": 39.0059,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAHU": {
      "icao": "HAHU",
      "iata": "HUE",
      "name": "Humera Airport",
      "city": "Humera",
      "state": "",
      "country": "ET",
      "elevation": 1930,
      "lat": 14.25,
      "lon": 36.5830001831,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAJJ": {
      "icao": "HAJJ",
      "iata": "JIJ",
      "name": "Jijiga Airport",
      "city": "Jijiga",
      "state": "Somali",
      "country": "ET",
      "elevation": 5413,
      "lat": 9.330833,
      "lon": 42.911111,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAJM": {
      "icao": "HAJM",
      "iata": "JIM",
      "name": "Jimma Airport",
      "city": "Jimma",
      "state": "Oromiya",
      "country": "ET",
      "elevation": 5500,
      "lat": 7.6660900116,
      "lon": 36.8166007996,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAKD": {
      "icao": "HAKD",
      "iata": "ABK",
      "name": "Kabri Dehar Airport",
      "city": "Kabri Dehar",
      "state": "",
      "country": "ET",
      "elevation": 1800,
      "lat": 6.734000206,
      "lon": 44.2529983521,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAKL": {
      "icao": "HAKL",
      "iata": "LFO",
      "name": "Kelafo East Airport",
      "city": "Kelafo",
      "state": "",
      "country": "ET",
      "elevation": 1730,
      "lat": 5.6570000648,
      "lon": 44.3499984741,
      "tz": "Africa\/Addis_Ababa"
  },
  "HALA": {
      "icao": "HALA",
      "iata": "AWA",
      "name": "Awassa Airport",
      "city": "Awassa",
      "state": "SNNPR",
      "country": "ET",
      "elevation": 5450,
      "lat": 7.0669999123,
      "lon": 38.5,
      "tz": "Africa\/Addis_Ababa"
  },
  "HALL": {
      "icao": "HALL",
      "iata": "LLI",
      "name": "Lalibella Airport",
      "city": "Lalibela",
      "state": "Amhara",
      "country": "ET",
      "elevation": 6506,
      "lat": 11.9750003815,
      "lon": 38.9799995422,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAMJ": {
      "icao": "HAMJ",
      "iata": "TUJ",
      "name": "Tume Airport",
      "city": "Maji",
      "state": "SNNPR",
      "country": "ET",
      "elevation": 2500,
      "lat": 5.8330001831,
      "lon": 35.533000946,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAMK": {
      "icao": "HAMK",
      "iata": "MQX",
      "name": "Mekele Airport",
      "city": "",
      "state": "Tigray",
      "country": "ET",
      "elevation": 7396,
      "lat": 13.4673995972,
      "lon": 39.5335006714,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAML": {
      "icao": "HAML",
      "iata": "MZX",
      "name": "Masslo Airport",
      "city": "Masslo",
      "state": "Oromiya",
      "country": "ET",
      "elevation": 4180,
      "lat": 6.4080600739,
      "lon": 39.7238998413,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAMM": {
      "icao": "HAMM",
      "iata": "ETE",
      "name": "Metema Airport",
      "city": "Metema",
      "state": "Amhara",
      "country": "ET",
      "elevation": 2650,
      "lat": 12.9329996109,
      "lon": 36.1669998169,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAMN": {
      "icao": "HAMN",
      "iata": "NDM",
      "name": "Mendi Airport",
      "city": "Mendi",
      "state": "",
      "country": "ET",
      "elevation": 5500,
      "lat": 9.7670001984,
      "lon": 35.0999984741,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAMT": {
      "icao": "HAMT",
      "iata": "MTF",
      "name": "Mizan Teferi Airport",
      "city": "Mizan Teferi",
      "state": "",
      "country": "ET",
      "elevation": 4396,
      "lat": 6.9571,
      "lon": 35.5547,
      "tz": "Africa\/Addis_Ababa"
  },
  "HANJ": {
      "icao": "HANJ",
      "iata": "NEJ",
      "name": "Nejjo Airport",
      "city": "Nejjo",
      "state": "",
      "country": "ET",
      "elevation": 6150,
      "lat": 9.5500001907,
      "lon": 35.466999054,
      "tz": "Africa\/Addis_Ababa"
  },
  "HANK": {
      "icao": "HANK",
      "iata": "NEK",
      "name": "Nekemte Airport",
      "city": "Nekemte",
      "state": "",
      "country": "ET",
      "elevation": 6500,
      "lat": 9.0500001907,
      "lon": 36.5999984741,
      "tz": "Africa\/Addis_Ababa"
  },
  "HASC": {
      "icao": "HASC",
      "iata": "SHC",
      "name": "Shire Airport",
      "city": "Shire",
      "state": "Tigray",
      "country": "ET",
      "elevation": 6207,
      "lat": 14.079444,
      "lon": 38.270833,
      "tz": "Africa\/Addis_Ababa"
  },
  "HASD": {
      "icao": "HASD",
      "iata": "SXU",
      "name": "Soddu Airport",
      "city": "Soddu",
      "state": "",
      "country": "ET",
      "elevation": 6400,
      "lat": 6.8169999123,
      "lon": 37.75,
      "tz": "Africa\/Addis_Ababa"
  },
  "HASH": {
      "icao": "HASH",
      "iata": "",
      "name": "Sheik Hussein Airport",
      "city": "Sheik Hussein",
      "state": "",
      "country": "ET",
      "elevation": 6500,
      "lat": 7.1500000954,
      "lon": 40.5670013428,
      "tz": "Africa\/Addis_Ababa"
  },
  "HASM": {
      "icao": "HASM",
      "iata": "SZE",
      "name": "Semera Airport",
      "city": "Semera",
      "state": "Afar",
      "country": "ET",
      "elevation": 1390,
      "lat": 11.7875,
      "lon": 40.991667,
      "tz": "Africa\/Addis_Ababa"
  },
  "HASO": {
      "icao": "HASO",
      "iata": "ASO",
      "name": "Asosa Airport",
      "city": "Asosa",
      "state": "Binshangul-Gumuz",
      "country": "ET",
      "elevation": 5100,
      "lat": 10.0185003281,
      "lon": 34.5862998962,
      "tz": "Africa\/Addis_Ababa"
  },
  "HATP": {
      "icao": "HATP",
      "iata": "TIE",
      "name": "Tippi Airport",
      "city": "Tippi",
      "state": "",
      "country": "ET",
      "elevation": 1100,
      "lat": 7.2024,
      "lon": 35.415,
      "tz": "Africa\/Addis_Ababa"
  },
  "HAWC": {
      "icao": "HAWC",
      "iata": "WAC",
      "name": "Waca Airport",
      "city": "Waca",
      "state": "",
      "country": "ET",
      "elevation": 4200,
      "lat": 7.1669998169,
      "lon": 37.1669998169,
      "tz": "Africa\/Addis_Ababa"
  },
  "HBBA": {
      "icao": "HBBA",
      "iata": "BJM",
      "name": "Bujumbura International Airport",
      "city": "Bujumbura",
      "state": "Bujumbura-Mairie",
      "country": "BI",
      "elevation": 2582,
      "lat": -3.3240199089,
      "lon": 29.3185005188,
      "tz": "Africa\/Bujumbura"
  },
  "HBBE": {
      "icao": "HBBE",
      "iata": "GID",
      "name": "Gitega Airport",
      "city": "Gitega",
      "state": "Gitega",
      "country": "BI",
      "elevation": 5741,
      "lat": -3.4172093868,
      "lon": 29.9113082886,
      "tz": "Africa\/Bujumbura"
  },
  "HBBO": {
      "icao": "HBBO",
      "iata": "KRE",
      "name": "Kirundo Airport",
      "city": "Kirundo",
      "state": "Kirundo",
      "country": "BI",
      "elevation": 4511,
      "lat": -2.544772,
      "lon": 30.094575,
      "tz": "Africa\/Bujumbura"
  },
  "HCMA": {
      "icao": "HCMA",
      "iata": "ALU",
      "name": "Alula Airport",
      "city": "Alula",
      "state": "Bari",
      "country": "SO",
      "elevation": 6,
      "lat": 11.9582,
      "lon": 50.748,
      "tz": "Africa\/Mogadishu"
  },
  "HCMB": {
      "icao": "HCMB",
      "iata": "BIB",
      "name": "Baidoa Airport",
      "city": "Baidoa",
      "state": "Bay",
      "country": "SO",
      "elevation": 1820,
      "lat": 3.1022200584,
      "lon": 43.6286010742,
      "tz": "Africa\/Mogadishu"
  },
  "HCMC": {
      "icao": "HCMC",
      "iata": "CXN",
      "name": "Candala Airport",
      "city": "Candala",
      "state": "Bari",
      "country": "SO",
      "elevation": 9,
      "lat": 11.5,
      "lon": 49.9169998169,
      "tz": "Africa\/Mogadishu"
  },
  "HCMD": {
      "icao": "HCMD",
      "iata": "BSY",
      "name": "Bardera Airport",
      "city": "",
      "state": "Gedo",
      "country": "SO",
      "elevation": 4200,
      "lat": 2.336111,
      "lon": 42.307778,
      "tz": "Africa\/Mogadishu"
  },
  "HCME": {
      "icao": "HCME",
      "iata": "HCM",
      "name": "Eil Airport",
      "city": "Eil",
      "state": "Nugaal",
      "country": "SO",
      "elevation": 812,
      "lat": 7.9169998169,
      "lon": 49.7999992371,
      "tz": "Africa\/Mogadishu"
  },
  "HCMF": {
      "icao": "HCMF",
      "iata": "BSA",
      "name": "Bosaso Airport",
      "city": "Bosaso",
      "state": "Bari",
      "country": "SO",
      "elevation": 3,
      "lat": 11.2753000259,
      "lon": 49.1493988037,
      "tz": "Africa\/Mogadishu"
  },
  "HCMG": {
      "icao": "HCMG",
      "iata": "GSR",
      "name": "Gardo Airport",
      "city": "Gardo",
      "state": "Bari",
      "country": "SO",
      "elevation": 2632,
      "lat": 9.5170001984,
      "lon": 49.0830001831,
      "tz": "Africa\/Mogadishu"
  },
  "HCMH": {
      "icao": "HCMH",
      "iata": "HGA",
      "name": "Egal International Airport",
      "city": "Hargeisa",
      "state": "Woqooyi-Galbeed",
      "country": "SO",
      "elevation": 4423,
      "lat": 9.5181703568,
      "lon": 44.0887985229,
      "tz": "Africa\/Mogadishu"
  },
  "HCMI": {
      "icao": "HCMI",
      "iata": "BBO",
      "name": "Berbera Airport",
      "city": "Berbera",
      "state": "Woqooyi-Galbeed",
      "country": "SO",
      "elevation": 30,
      "lat": 10.3892002106,
      "lon": 44.9411010742,
      "tz": "Africa\/Mogadishu"
  },
  "HCMK": {
      "icao": "HCMK",
      "iata": "KMU",
      "name": "Kisimayu Airport",
      "city": "",
      "state": "Lower-Juba",
      "country": "SO",
      "elevation": 49,
      "lat": -0.3773530126,
      "lon": 42.4592018127,
      "tz": "Africa\/Mogadishu"
  },
  "HCMM": {
      "icao": "HCMM",
      "iata": "MGQ",
      "name": "Aden Adde International Airport",
      "city": "Mogadishu",
      "state": "Banaadir",
      "country": "SO",
      "elevation": 29,
      "lat": 2.0144400597,
      "lon": 45.3046989441,
      "tz": "Africa\/Mogadishu"
  },
  "HCMN": {
      "icao": "HCMN",
      "iata": "",
      "name": "Belet Uen Airport",
      "city": "Belet Uen",
      "state": "Hiiraan",
      "country": "SO",
      "elevation": 559,
      "lat": 4.6999998093,
      "lon": 45.216999054,
      "tz": "Africa\/Mogadishu"
  },
  "HCMO": {
      "icao": "HCMO",
      "iata": "CMO",
      "name": "Obbia Airport",
      "city": "Obbia",
      "state": "Mudug",
      "country": "SO",
      "elevation": 65,
      "lat": 5.3666667938,
      "lon": 48.5166664124,
      "tz": "Africa\/Mogadishu"
  },
  "HCMR": {
      "icao": "HCMR",
      "iata": "GLK",
      "name": "Galcaio Airport",
      "city": "Galcaio",
      "state": "Mudug",
      "country": "SO",
      "elevation": 975,
      "lat": 6.7808299065,
      "lon": 47.45470047,
      "tz": "Africa\/Mogadishu"
  },
  "HCMS": {
      "icao": "HCMS",
      "iata": "CMS",
      "name": "Scusciuban Airport",
      "city": "Scusciuban",
      "state": "Bari",
      "country": "SO",
      "elevation": 1121,
      "lat": 10.3000001907,
      "lon": 50.233001709,
      "tz": "Africa\/Mogadishu"
  },
  "HCMU": {
      "icao": "HCMU",
      "iata": "ERA",
      "name": "Erigavo Airport",
      "city": "Erigavo",
      "state": "Sanaag",
      "country": "SO",
      "elevation": 5720,
      "lat": 10.642050549,
      "lon": 47.3879814148,
      "tz": "Africa\/Mogadishu"
  },
  "HCMV": {
      "icao": "HCMV",
      "iata": "BUO",
      "name": "Burao Airport",
      "city": "Burao",
      "state": "Togdheer",
      "country": "SO",
      "elevation": 3400,
      "lat": 9.5275,
      "lon": 45.5549,
      "tz": "Africa\/Mogadishu"
  },
  "HDAG": {
      "icao": "HDAG",
      "iata": "",
      "name": "Assa-Gueyla Airport",
      "city": "Assa-Gueyla",
      "state": "Tadjourah",
      "country": "DJ",
      "elevation": 0,
      "lat": 12.1999998093,
      "lon": 42.6333007813,
      "tz": "Africa\/Djibouti"
  },
  "HDAM": {
      "icao": "HDAM",
      "iata": "JIB",
      "name": "Djibouti-Ambouli Airport",
      "city": "Djibouti City",
      "state": "Djibouti",
      "country": "DJ",
      "elevation": 49,
      "lat": 11.5473003387,
      "lon": 43.1595001221,
      "tz": "Africa\/Djibouti"
  },
  "HDAS": {
      "icao": "HDAS",
      "iata": "AII",
      "name": "Ali-Sabieh Airport",
      "city": "Ali-Sabieh",
      "state": "Ali-Sabieh",
      "country": "DJ",
      "elevation": 2320,
      "lat": 11.1468888889,
      "lon": 42.72,
      "tz": "Africa\/Djibouti"
  },
  "HDCH": {
      "icao": "HDCH",
      "iata": "",
      "name": "Chabelley Airport",
      "city": "Chabelley",
      "state": "Ali-Sabieh",
      "country": "DJ",
      "elevation": 279,
      "lat": 11.5167999268,
      "lon": 43.0614013672,
      "tz": "Africa\/Djibouti"
  },
  "HDDK": {
      "icao": "HDDK",
      "iata": "",
      "name": "Dikhil Airport",
      "city": "Dikhil",
      "state": "",
      "country": "DJ",
      "elevation": 0,
      "lat": 11.1000003815,
      "lon": 42.3499984741,
      "tz": "Africa\/Djibouti"
  },
  "HDHE": {
      "icao": "HDHE",
      "iata": "",
      "name": "Herkale Airport",
      "city": "Herkale",
      "state": "Obock",
      "country": "DJ",
      "elevation": 0,
      "lat": 12.4333333969,
      "lon": 43.2999992371,
      "tz": "Africa\/Djibouti"
  },
  "HDMO": {
      "icao": "HDMO",
      "iata": "MHI",
      "name": "Moucha Airport",
      "city": "Moucha Island",
      "state": "Djibouti",
      "country": "DJ",
      "elevation": 0,
      "lat": 11.7166671753,
      "lon": 43.2000007629,
      "tz": "Africa\/Djibouti"
  },
  "HDOB": {
      "icao": "HDOB",
      "iata": "OBC",
      "name": "Obock Airport",
      "city": "Obock",
      "state": "Obock",
      "country": "DJ",
      "elevation": 69,
      "lat": 11.9670000076,
      "lon": 43.266998291,
      "tz": "Africa\/Djibouti"
  },
  "HDTJ": {
      "icao": "HDTJ",
      "iata": "TDJ",
      "name": "Tadjoura Airport",
      "city": "Tadjoura",
      "state": "Tadjourah",
      "country": "DJ",
      "elevation": 246,
      "lat": 11.7829999924,
      "lon": 42.9169998169,
      "tz": "Africa\/Djibouti"
  },
  "HE15": {
      "icao": "HE15",
      "iata": "",
      "name": "Hulwan",
      "city": "",
      "state": "Cairo",
      "country": "EG",
      "elevation": 0,
      "lat": 29.8225002289,
      "lon": 31.3309001923,
      "tz": "Africa\/Cairo"
  },
  "HE16": {
      "icao": "HE16",
      "iata": "",
      "name": "Birma",
      "city": "",
      "state": "Gharbia",
      "country": "EG",
      "elevation": 0,
      "lat": 30.83659935,
      "lon": 30.936000824,
      "tz": "Africa\/Cairo"
  },
  "HE24": {
      "icao": "HE24",
      "iata": "SEW",
      "name": "Siwa Oasis North Airport",
      "city": "Siwa",
      "state": "Matruh",
      "country": "EG",
      "elevation": 330,
      "lat": 29.345500946,
      "lon": 25.5067005157,
      "tz": "Africa\/Cairo"
  },
  "HE26": {
      "icao": "HE26",
      "iata": "",
      "name": "Wadi Abu Shihat",
      "city": "",
      "state": "Red-Sea",
      "country": "EG",
      "elevation": 0,
      "lat": 26.5572185516,
      "lon": 33.1219291687,
      "tz": "Africa\/Cairo"
  },
  "HE45": {
      "icao": "HE45",
      "iata": "",
      "name": "Ras Sudr",
      "city": "",
      "state": "South-Sinai",
      "country": "EG",
      "elevation": 0,
      "lat": 29.6030006409,
      "lon": 32.6896018982,
      "tz": "Africa\/Cairo"
  },
  "HEAL": {
      "icao": "HEAL",
      "iata": "DBB",
      "name": "El Alamein International Airport",
      "city": "El Alamein",
      "state": "Matruh",
      "country": "EG",
      "elevation": 143,
      "lat": 30.9244995117,
      "lon": 28.4613990784,
      "tz": "Africa\/Cairo"
  },
  "HEAR": {
      "icao": "HEAR",
      "iata": "AAC",
      "name": "El Arish International Airport",
      "city": "El Arish",
      "state": "North-Sinai",
      "country": "EG",
      "elevation": 121,
      "lat": 31.073299408,
      "lon": 33.8358001709,
      "tz": "Africa\/Cairo"
  },
  "HEAT": {
      "icao": "HEAT",
      "iata": "ATZ",
      "name": "Assiut International Airport",
      "city": "Assiut",
      "state": "Asyut",
      "country": "EG",
      "elevation": 772,
      "lat": 27.0464992523,
      "lon": 31.0119991302,
      "tz": "Africa\/Cairo"
  },
  "HEAX": {
      "icao": "HEAX",
      "iata": "ALY",
      "name": "El Nouzha Airport",
      "city": "Alexandria",
      "state": "",
      "country": "EG",
      "elevation": -6,
      "lat": 31.1839008331,
      "lon": 29.9489002228,
      "tz": "Africa\/Cairo"
  },
  "HEAZ": {
      "icao": "HEAZ",
      "iata": "",
      "name": "Almaza Air Force Base",
      "city": "Cairo",
      "state": "Cairo",
      "country": "EG",
      "elevation": 300,
      "lat": 30.0918006897,
      "lon": 31.3600006104,
      "tz": "Africa\/Cairo"
  },
  "HEBA": {
      "icao": "HEBA",
      "iata": "HBE",
      "name": "Borg El Arab International Airport",
      "city": "Alexandria",
      "state": "Alexandria",
      "country": "EG",
      "elevation": 177,
      "lat": 30.9176998138,
      "lon": 29.6963996887,
      "tz": "Africa\/Cairo"
  },
  "HEBL": {
      "icao": "HEBL",
      "iata": "ABS",
      "name": "Abu Simbel Airport",
      "city": "Abu Simbel",
      "state": "Aswan",
      "country": "EG",
      "elevation": 616,
      "lat": 22.3759994507,
      "lon": 31.611700058,
      "tz": "Africa\/Cairo"
  },
  "HEBS": {
      "icao": "HEBS",
      "iata": "",
      "name": "Beni Suef Air Base",
      "city": "Beni Suef",
      "state": "Faiyum",
      "country": "EG",
      "elevation": 108,
      "lat": 29.2082996368,
      "lon": 31.0165996552,
      "tz": "Africa\/Cairo"
  },
  "HECA": {
      "icao": "HECA",
      "iata": "CAI",
      "name": "Cairo International Airport",
      "city": "Cairo",
      "state": "Cairo",
      "country": "EG",
      "elevation": 382,
      "lat": 30.1219005585,
      "lon": 31.4055995941,
      "tz": "Africa\/Cairo"
  },
  "HECW": {
      "icao": "HECW",
      "iata": "CWE",
      "name": "Cairo West Airport",
      "city": "El Cairo",
      "state": "Giza",
      "country": "EG",
      "elevation": 550,
      "lat": 30.116399765,
      "lon": 30.9153995514,
      "tz": "Africa\/Cairo"
  },
  "HEDK": {
      "icao": "HEDK",
      "iata": "DAK",
      "name": "Dakhla Airport",
      "city": "",
      "state": "New-Valley",
      "country": "EG",
      "elevation": 613,
      "lat": 25.4116001129,
      "lon": 29.0030994415,
      "tz": "Africa\/Cairo"
  },
  "HEEM": {
      "icao": "HEEM",
      "iata": "",
      "name": "Giza Embaba Airport",
      "city": "Cairo",
      "state": "Giza",
      "country": "EG",
      "elevation": 59,
      "lat": 30.0746994019,
      "lon": 31.191400528,
      "tz": "Africa\/Cairo"
  },
  "HEGN": {
      "icao": "HEGN",
      "iata": "HRG",
      "name": "Hurghada International Airport",
      "city": "Hurghada",
      "state": "Red-Sea",
      "country": "EG",
      "elevation": 52,
      "lat": 27.1783008575,
      "lon": 33.7994003296,
      "tz": "Africa\/Cairo"
  },
  "HEGO": {
      "icao": "HEGO",
      "iata": "",
      "name": "El Gouna Airport",
      "city": "El Gouna",
      "state": "Red-Sea",
      "country": "EG",
      "elevation": 56,
      "lat": 27.3668994904,
      "lon": 33.6682014465,
      "tz": "Africa\/Cairo"
  },
  "HEGR": {
      "icao": "HEGR",
      "iata": "EGH",
      "name": "El Gora Airport",
      "city": "",
      "state": "North-Sinai",
      "country": "EG",
      "elevation": 324,
      "lat": 31.0690002441,
      "lon": 34.1291999817,
      "tz": "Africa\/Cairo"
  },
  "HEGS": {
      "icao": "HEGS",
      "iata": "",
      "name": "Jiyanklis Air Base",
      "city": "Jiyanklis",
      "state": "Beheira",
      "country": "EG",
      "elevation": 49,
      "lat": 30.8197994232,
      "lon": 30.1912002563,
      "tz": "Africa\/Cairo"
  },
  "HEKG": {
      "icao": "HEKG",
      "iata": "UVL",
      "name": "El Kharga Airport",
      "city": "",
      "state": "New-Valley",
      "country": "EG",
      "elevation": 192,
      "lat": 25.4736003876,
      "lon": 30.5907001495,
      "tz": "Africa\/Cairo"
  },
  "HELX": {
      "icao": "HELX",
      "iata": "LXR",
      "name": "Luxor International Airport",
      "city": "Luxor",
      "state": "Qena",
      "country": "EG",
      "elevation": 294,
      "lat": 25.670999527,
      "lon": 32.7066001892,
      "tz": "Africa\/Cairo"
  },
  "HEMA": {
      "icao": "HEMA",
      "iata": "RMF",
      "name": "Marsa Alam International Airport",
      "city": "Marsa Alam",
      "state": "Red-Sea",
      "country": "EG",
      "elevation": 251,
      "lat": 25.557100296,
      "lon": 34.5836982727,
      "tz": "Africa\/Cairo"
  },
  "HEMK": {
      "icao": "HEMK",
      "iata": "HMB",
      "name": "Sohag International Airport",
      "city": "Sohag",
      "state": "New-Valley",
      "country": "EG",
      "elevation": 310,
      "lat": 26.3427777778,
      "lon": 31.7427777778,
      "tz": "Africa\/Cairo"
  },
  "HEMM": {
      "icao": "HEMM",
      "iata": "MUH",
      "name": "Mersa Matruh Airport",
      "city": "Mersa Matruh",
      "state": "Matruh",
      "country": "EG",
      "elevation": 94,
      "lat": 31.3253993988,
      "lon": 27.2217006683,
      "tz": "Africa\/Cairo"
  },
  "HEOC": {
      "icao": "HEOC",
      "iata": "",
      "name": "October Airport",
      "city": "",
      "state": "Faiyum",
      "country": "EG",
      "elevation": 807,
      "lat": 29.8120994568,
      "lon": 30.8234004974,
      "tz": "Africa\/Cairo"
  },
  "HEOW": {
      "icao": "HEOW",
      "iata": "GSQ",
      "name": "Shark El Oweinat International Airport",
      "city": "",
      "state": "New-Valley",
      "country": "EG",
      "elevation": 859,
      "lat": 22.5856990814,
      "lon": 28.7166004181,
      "tz": "Africa\/Cairo"
  },
  "HEPS": {
      "icao": "HEPS",
      "iata": "PSD",
      "name": "Port Said Airport",
      "city": "Port Said",
      "state": "Port-Said",
      "country": "EG",
      "elevation": 8,
      "lat": 31.2793998718,
      "lon": 32.2400016785,
      "tz": "Africa\/Cairo"
  },
  "HESC": {
      "icao": "HESC",
      "iata": "SKV",
      "name": "St Catherine International Airport",
      "city": "",
      "state": "South-Sinai",
      "country": "EG",
      "elevation": 4368,
      "lat": 28.685300827,
      "lon": 34.0625,
      "tz": "Africa\/Cairo"
  },
  "HESH": {
      "icao": "HESH",
      "iata": "SSH",
      "name": "Sharm El Sheikh International Airport",
      "city": "Sharm el-Sheikh",
      "state": "South-Sinai",
      "country": "EG",
      "elevation": 143,
      "lat": 27.9773006439,
      "lon": 34.3950004578,
      "tz": "Africa\/Cairo"
  },
  "HESN": {
      "icao": "HESN",
      "iata": "ASW",
      "name": "Aswan International Airport",
      "city": "Aswan",
      "state": "Aswan",
      "country": "EG",
      "elevation": 662,
      "lat": 23.9643993378,
      "lon": 32.8199996948,
      "tz": "Africa\/Cairo"
  },
  "HESX": {
      "icao": "HESX",
      "iata": "SPX",
      "name": "Sphinx International Airport",
      "city": "Giza",
      "state": "Giza ",
      "country": "EG",
      "elevation": 553,
      "lat": 30.1147222,
      "lon": 30.8933333,
      "tz": "Africa\/Cairo"
  },
  "HETB": {
      "icao": "HETB",
      "iata": "TCP",
      "name": "Taba International Airport",
      "city": "Taba",
      "state": "South-Sinai",
      "country": "EG",
      "elevation": 2415,
      "lat": 29.5877990723,
      "lon": 34.7780990601,
      "tz": "Africa\/Cairo"
  },
  "HETR": {
      "icao": "HETR",
      "iata": "ELT",
      "name": "El Tor Airport",
      "city": "",
      "state": "South-Sinai",
      "country": "EG",
      "elevation": 115,
      "lat": 28.2089996338,
      "lon": 33.6455001831,
      "tz": "Africa\/Cairo"
  },
  "HHAS": {
      "icao": "HHAS",
      "iata": "ASM",
      "name": "Asmara International Airport",
      "city": "Asmara",
      "state": "Maekel",
      "country": "ER",
      "elevation": 7661,
      "lat": 15.2918996811,
      "lon": 38.9107017517,
      "tz": "Africa\/Asmara"
  },
  "HHMS": {
      "icao": "HHMS",
      "iata": "MSW",
      "name": "Massawa International Airport",
      "city": "Massawa",
      "state": "Northern-Red-Sea",
      "country": "ER",
      "elevation": 194,
      "lat": 15.6700000763,
      "lon": 39.370098114,
      "tz": "Africa\/Asmara"
  },
  "HHSB": {
      "icao": "HHSB",
      "iata": "ASA",
      "name": "Assab International Airport",
      "city": "Asab",
      "state": "",
      "country": "ER",
      "elevation": 46,
      "lat": 13.0718002319,
      "lon": 42.6450004578,
      "tz": "Africa\/Asmara"
  },
  "HHTS": {
      "icao": "HHTS",
      "iata": "TES",
      "name": "Tessenei Airport",
      "city": "Tessenei",
      "state": "",
      "country": "ER",
      "elevation": 2018,
      "lat": 15.1169996262,
      "lon": 36.6829986572,
      "tz": "Africa\/Asmara"
  },
  "HI01": {
      "icao": "HI01",
      "iata": "",
      "name": "Princeville Airport",
      "city": "Hanalei",
      "state": "Hawaii",
      "country": "US",
      "elevation": 344,
      "lat": 22.2091999054,
      "lon": -159.4459991455,
      "tz": "Pacific\/Honolulu"
  },
  "HI02": {
      "icao": "HI02",
      "iata": "",
      "name": "Peleau Airport",
      "city": "Hakalau",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1088,
      "lat": 19.9022006989,
      "lon": -155.1660003662,
      "tz": "Pacific\/Honolulu"
  },
  "HI03": {
      "icao": "HI03",
      "iata": "",
      "name": "Hanamaulu Airstrip",
      "city": "Hanamaulu",
      "state": "Hawaii",
      "country": "US",
      "elevation": 404,
      "lat": 22.0412998199,
      "lon": -159.3899993896,
      "tz": "Pacific\/Honolulu"
  },
  "HI05": {
      "icao": "HI05",
      "iata": "",
      "name": "Honokaa Airstrip",
      "city": "Honokaa",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1440,
      "lat": 20.0835990906,
      "lon": -155.5019989014,
      "tz": "Pacific\/Honolulu"
  },
  "HI13": {
      "icao": "HI13",
      "iata": "",
      "name": "Puu Waa Waa Ranch Airport",
      "city": "Kailua\/Kona",
      "state": "Hawaii",
      "country": "US",
      "elevation": 2250,
      "lat": 19.7886009216,
      "lon": -155.8500061035,
      "tz": "Pacific\/Honolulu"
  },
  "HI23": {
      "icao": "HI23",
      "iata": "",
      "name": "Mountain View Airstrip",
      "city": "Mountain View",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1500,
      "lat": 19.5475006104,
      "lon": -155.108001709,
      "tz": "Pacific\/Honolulu"
  },
  "HI25": {
      "icao": "HI25",
      "iata": "",
      "name": "Kaalaiki Airstrip",
      "city": "Naalehu",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1964,
      "lat": 19.122800827,
      "lon": -155.5780029297,
      "tz": "Pacific\/Honolulu"
  },
  "HI27": {
      "icao": "HI27",
      "iata": "",
      "name": "Upper Paauilo Airstrip",
      "city": "Paauilo",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1520,
      "lat": 20.0282993317,
      "lon": -155.3880004883,
      "tz": "Pacific\/Honolulu"
  },
  "HI28": {
      "icao": "HI28",
      "iata": "",
      "name": "Pahala Airstrip",
      "city": "Pahala",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1195,
      "lat": 19.2161998749,
      "lon": -155.4689941406,
      "tz": "Pacific\/Honolulu"
  },
  "HI29": {
      "icao": "HI29",
      "iata": "",
      "name": "Upper Paauau Airport",
      "city": "Pahala",
      "state": "Hawaii",
      "country": "US",
      "elevation": 2600,
      "lat": 19.2275009155,
      "lon": -155.5090026855,
      "tz": "Pacific\/Honolulu"
  },
  "HI31": {
      "icao": "HI31",
      "iata": "",
      "name": "Mauna Kea-Honolii Airport",
      "city": "Papaikou",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1400,
      "lat": 19.7639007568,
      "lon": -155.1380004883,
      "tz": "Pacific\/Honolulu"
  },
  "HI32": {
      "icao": "HI32",
      "iata": "",
      "name": "Pepeekeo Airstrip",
      "city": "Pepeekeo",
      "state": "Hawaii",
      "country": "US",
      "elevation": 675,
      "lat": 19.8446998596,
      "lon": -155.1130065918,
      "tz": "Pacific\/Honolulu"
  },
  "HI33": {
      "icao": "HI33",
      "iata": "",
      "name": "Haiku Airstrip",
      "city": "Puhi",
      "state": "Hawaii",
      "country": "US",
      "elevation": 385,
      "lat": 21.9423999786,
      "lon": -159.4400024414,
      "tz": "Pacific\/Honolulu"
  },
  "HI46": {
      "icao": "HI46",
      "iata": "",
      "name": "Hi 23 Airstrip",
      "city": "Puhi",
      "state": "Hawaii",
      "country": "US",
      "elevation": 480,
      "lat": 21.9657993317,
      "lon": -159.4270019531,
      "tz": "Pacific\/Honolulu"
  },
  "HI49": {
      "icao": "HI49",
      "iata": "",
      "name": "Panda Airport",
      "city": "Kaunakakai",
      "state": "Hawaii",
      "country": "US",
      "elevation": 250,
      "lat": 21.1478996277,
      "lon": -157.2590026855,
      "tz": "Pacific\/Honolulu"
  },
  "HKAM": {
      "icao": "HKAM",
      "iata": "ASV",
      "name": "Amboseli Airport",
      "city": "Amboseli National Park",
      "state": "Kajiado",
      "country": "KE",
      "elevation": 3755,
      "lat": -2.6450500488,
      "lon": 37.2531013489,
      "tz": "Africa\/Nairobi"
  },
  "HKBR": {
      "icao": "HKBR",
      "iata": "",
      "name": "Bura East Airport",
      "city": "Bura",
      "state": "Tana-River",
      "country": "KE",
      "elevation": 345,
      "lat": -1.1000000238,
      "lon": 39.9500007629,
      "tz": "Africa\/Nairobi"
  },
  "HKEL": {
      "icao": "HKEL",
      "iata": "EDL",
      "name": "Eldoret International Airport",
      "city": "Eldoret",
      "state": "Uasin-Gishu",
      "country": "KE",
      "elevation": 6941,
      "lat": 0.4044579864,
      "lon": 35.238899231,
      "tz": "Africa\/Nairobi"
  },
  "HKEM": {
      "icao": "HKEM",
      "iata": "",
      "name": "Embu Airport",
      "city": "Embu",
      "state": "Embu",
      "country": "KE",
      "elevation": 4150,
      "lat": -0.5669999719,
      "lon": 37.483001709,
      "tz": "Africa\/Nairobi"
  },
  "HKES": {
      "icao": "HKES",
      "iata": "EYS",
      "name": "Eliye Springs Airport",
      "city": "Eliye Springs",
      "state": "",
      "country": "KE",
      "elevation": 1395,
      "lat": 3.2166700363,
      "lon": 35.9667015076,
      "tz": "Africa\/Nairobi"
  },
  "HKEW": {
      "icao": "HKEW",
      "iata": "",
      "name": "El Wak Airport",
      "city": "El Wak",
      "state": "Mandera",
      "country": "KE",
      "elevation": 1295,
      "lat": 2.7330000401,
      "lon": 40.9329986572,
      "tz": "Africa\/Nairobi"
  },
  "HKFG": {
      "icao": "HKFG",
      "iata": "KLK",
      "name": "Kalokol Airport",
      "city": "Kalokol",
      "state": "Turkana",
      "country": "KE",
      "elevation": 1245,
      "lat": 3.4830000401,
      "lon": 35.8330001831,
      "tz": "Africa\/Nairobi"
  },
  "HKGA": {
      "icao": "HKGA",
      "iata": "GAS",
      "name": "Garissa Airport",
      "city": "Garissa",
      "state": "Garissa",
      "country": "KE",
      "elevation": 475,
      "lat": -0.4635080099,
      "lon": 39.6483001709,
      "tz": "Africa\/Nairobi"
  },
  "HKGT": {
      "icao": "HKGT",
      "iata": "",
      "name": "Garba Tula Airport",
      "city": "Garba Tula",
      "state": "Isiolo",
      "country": "KE",
      "elevation": 2000,
      "lat": 0.5329999924,
      "lon": 38.516998291,
      "tz": "Africa\/Nairobi"
  },
  "HKHB": {
      "icao": "HKHB",
      "iata": "",
      "name": "Homa Bay Airport",
      "city": "Homa Bay",
      "state": "Homa-Bay",
      "country": "KE",
      "elevation": 4280,
      "lat": -0.6000000238,
      "lon": 34.466999054,
      "tz": "Africa\/Nairobi"
  },
  "HKHO": {
      "icao": "HKHO",
      "iata": "HOA",
      "name": "Hola Airport",
      "city": "Hola",
      "state": "Tana-River",
      "country": "KE",
      "elevation": 195,
      "lat": -1.5219999552,
      "lon": 40.0040016174,
      "tz": "Africa\/Nairobi"
  },
  "HKIS": {
      "icao": "HKIS",
      "iata": "",
      "name": "Isiolo Airport",
      "city": "Isiolo",
      "state": "Isiolo",
      "country": "KE",
      "elevation": 3495,
      "lat": 0.3381709456,
      "lon": 37.5916938782,
      "tz": "Africa\/Nairobi"
  },
  "HKJK": {
      "icao": "HKJK",
      "iata": "NBO",
      "name": "Jomo Kenyatta International Airport",
      "city": "Nairobi",
      "state": "Nairobi-Area",
      "country": "KE",
      "elevation": 5330,
      "lat": -1.319239974,
      "lon": 36.9277992249,
      "tz": "Africa\/Nairobi"
  },
  "HKKB": {
      "icao": "HKKB",
      "iata": "",
      "name": "Kiambere Airport",
      "city": "Kiambere",
      "state": "Kitui",
      "country": "KE",
      "elevation": 2450,
      "lat": -0.6330000162,
      "lon": 37.8829994202,
      "tz": "Africa\/Nairobi"
  },
  "HKKE": {
      "icao": "HKKE",
      "iata": "KEU",
      "name": "Keekorok Airport",
      "city": "Keekorok",
      "state": "Narok",
      "country": "KE",
      "elevation": 5800,
      "lat": -1.5829999447,
      "lon": 35.25,
      "tz": "Africa\/Nairobi"
  },
  "HKKG": {
      "icao": "HKKG",
      "iata": "GGM",
      "name": "Kakamega Airport",
      "city": "Kakamega",
      "state": "Kakamega",
      "country": "KE",
      "elevation": 5020,
      "lat": 0.2713420093,
      "lon": 34.7873001099,
      "tz": "Africa\/Nairobi"
  },
  "HKKI": {
      "icao": "HKKI",
      "iata": "KIS",
      "name": "Kisumu Airport",
      "city": "Kisumu",
      "state": "Kisumu",
      "country": "KE",
      "elevation": 3734,
      "lat": -0.086139001,
      "lon": 34.7289009094,
      "tz": "Africa\/Nairobi"
  },
  "HKKL": {
      "icao": "HKKL",
      "iata": "ILU",
      "name": "Kilaguni Airport",
      "city": "Kilaguni",
      "state": "Taita-Taveta",
      "country": "KE",
      "elevation": 2750,
      "lat": -2.9106099606,
      "lon": 38.0652008057,
      "tz": "Africa\/Nairobi"
  },
  "HKKR": {
      "icao": "HKKR",
      "iata": "KEY",
      "name": "Kericho Airport",
      "city": "Kericho",
      "state": "Kericho",
      "country": "KE",
      "elevation": 6562,
      "lat": -0.4169999957,
      "lon": 35.25,
      "tz": "Africa\/Nairobi"
  },
  "HKKS": {
      "icao": "HKKS",
      "iata": "",
      "name": "Kisii Airport",
      "city": "Kisii",
      "state": "Kisii",
      "country": "KE",
      "elevation": 4905,
      "lat": -0.6669999957,
      "lon": 34.7000007629,
      "tz": "Africa\/Nairobi"
  },
  "HKKT": {
      "icao": "HKKT",
      "iata": "KTL",
      "name": "Kitale Airport",
      "city": "Kitale",
      "state": "Trans-Nzoia",
      "country": "KE",
      "elevation": 6070,
      "lat": 0.971988976,
      "lon": 34.9585990906,
      "tz": "Africa\/Nairobi"
  },
  "HKKU": {
      "icao": "HKKU",
      "iata": "",
      "name": "Kitui Airport",
      "city": "Kitui",
      "state": "Kitui",
      "country": "KE",
      "elevation": 3790,
      "lat": -1.372789979,
      "lon": 37.978099823,
      "tz": "Africa\/Nairobi"
  },
  "HKLG": {
      "icao": "HKLG",
      "iata": "",
      "name": "Lokitaung Airport",
      "city": "Lokitaung",
      "state": "Turkana",
      "country": "KE",
      "elevation": 1805,
      "lat": 4.3789000511,
      "lon": 35.6589012146,
      "tz": "Africa\/Nairobi"
  },
  "HKLK": {
      "icao": "HKLK",
      "iata": "LKG",
      "name": "Lokichoggio Airport",
      "city": "Lokichoggio",
      "state": "Turkana",
      "country": "KE",
      "elevation": 2074,
      "lat": 4.2041201591,
      "lon": 34.3482017517,
      "tz": "Africa\/Nairobi"
  },
  "HKLO": {
      "icao": "HKLO",
      "iata": "LOK",
      "name": "Lodwar Airport",
      "city": "Lodwar",
      "state": "Turkana",
      "country": "KE",
      "elevation": 1715,
      "lat": 3.1219699383,
      "lon": 35.6086997986,
      "tz": "Africa\/Nairobi"
  },
  "HKLU": {
      "icao": "HKLU",
      "iata": "LAU",
      "name": "Manda Airstrip",
      "city": "Lamu",
      "state": "Lamu",
      "country": "KE",
      "elevation": 20,
      "lat": -2.2524199486,
      "lon": 40.9131011963,
      "tz": "Africa\/Nairobi"
  },
  "HKLY": {
      "icao": "HKLY",
      "iata": "LOY",
      "name": "Loyengalani Airport",
      "city": "Loyengalani",
      "state": "Marsabit",
      "country": "KE",
      "elevation": 1195,
      "lat": 2.75,
      "lon": 36.716999054,
      "tz": "Africa\/Nairobi"
  },
  "HKMA": {
      "icao": "HKMA",
      "iata": "NDE",
      "name": "Mandera Airport",
      "city": "Mandera",
      "state": "Mandera",
      "country": "KE",
      "elevation": 805,
      "lat": 3.9330000877,
      "lon": 41.8499984741,
      "tz": "Africa\/Addis_Ababa"
  },
  "HKMB": {
      "icao": "HKMB",
      "iata": "RBT",
      "name": "Segel Airport",
      "city": "Marsabit",
      "state": "Marsabit",
      "country": "KE",
      "elevation": 4395,
      "lat": 2.3499999046,
      "lon": 37.983001709,
      "tz": "Africa\/Nairobi"
  },
  "HKMI": {
      "icao": "HKMI",
      "iata": "",
      "name": "Maralal (Kisima) Airport",
      "city": "Maralal",
      "state": "Samburu",
      "country": "KE",
      "elevation": 5940,
      "lat": 0.9499999881,
      "lon": 36.7999992371,
      "tz": "Africa\/Nairobi"
  },
  "HKMK": {
      "icao": "HKMK",
      "iata": "JJM",
      "name": "Mulika Lodge Airport",
      "city": "Meru-Kinna",
      "state": "Isiolo",
      "country": "KE",
      "elevation": 2000,
      "lat": 0.165083006,
      "lon": 38.1951408386,
      "tz": "Africa\/Nairobi"
  },
  "HKML": {
      "icao": "HKML",
      "iata": "MYD",
      "name": "Malindi Airport",
      "city": "Malindi",
      "state": "Kilifi",
      "country": "KE",
      "elevation": 80,
      "lat": -3.2293100357,
      "lon": 40.1016998291,
      "tz": "Africa\/Nairobi"
  },
  "HKMM": {
      "icao": "HKMM",
      "iata": "",
      "name": "Migori Airport",
      "city": "Migori",
      "state": "Migori",
      "country": "KE",
      "elevation": 4575,
      "lat": -1.1169999838,
      "lon": 34.483001709,
      "tz": "Africa\/Nairobi"
  },
  "HKMO": {
      "icao": "HKMO",
      "iata": "MBA",
      "name": "Mombasa Moi International Airport",
      "city": "Mombasa",
      "state": "Mombasa",
      "country": "KE",
      "elevation": 200,
      "lat": -4.0348300934,
      "lon": 39.5942001343,
      "tz": "Africa\/Nairobi"
  },
  "HKMS": {
      "icao": "HKMS",
      "iata": "MRE",
      "name": "Mara Serena Lodge Airstrip",
      "city": "Masai Mara",
      "state": "Narok",
      "country": "KE",
      "elevation": 5200,
      "lat": -1.406111002,
      "lon": 35.0080566406,
      "tz": "Africa\/Nairobi"
  },
  "HKMY": {
      "icao": "HKMY",
      "iata": "OYL",
      "name": "Moyale Airport",
      "city": "Moyale (Lower)",
      "state": "Marsabit",
      "country": "KE",
      "elevation": 2790,
      "lat": 3.4697198868,
      "lon": 39.101398468,
      "tz": "Africa\/Nairobi"
  },
  "HKNI": {
      "icao": "HKNI",
      "iata": "NYE",
      "name": "Nyeri Airport",
      "city": "Nyeri",
      "state": "Nyeri",
      "country": "KE",
      "elevation": 5830,
      "lat": -0.3644140065,
      "lon": 36.9784851074,
      "tz": "Africa\/Nairobi"
  },
  "HKNK": {
      "icao": "HKNK",
      "iata": "NUU",
      "name": "Nakuru Airport",
      "city": "Nakuru",
      "state": "Nakuru",
      "country": "KE",
      "elevation": 6200,
      "lat": -0.2980670035,
      "lon": 36.1593017578,
      "tz": "Africa\/Nairobi"
  },
  "HKNO": {
      "icao": "HKNO",
      "iata": "",
      "name": "Narok Airport",
      "city": "Narok",
      "state": "Narok",
      "country": "KE",
      "elevation": 6070,
      "lat": -1.1499999762,
      "lon": 35.766998291,
      "tz": "Africa\/Nairobi"
  },
  "HKNV": {
      "icao": "HKNV",
      "iata": "",
      "name": "Naivasha Airport",
      "city": "Naivasha",
      "state": "Nakuru",
      "country": "KE",
      "elevation": 6380,
      "lat": -0.7879530191,
      "lon": 36.4334983826,
      "tz": "Africa\/Nairobi"
  },
  "HKNW": {
      "icao": "HKNW",
      "iata": "WIL",
      "name": "Nairobi Wilson Airport",
      "city": "Nairobi",
      "state": "Nairobi-Area",
      "country": "KE",
      "elevation": 5536,
      "lat": -1.3217200041,
      "lon": 36.8148002625,
      "tz": "Africa\/Nairobi"
  },
  "HKNY": {
      "icao": "HKNY",
      "iata": "NYK",
      "name": "Nanyuki Airport",
      "city": "Nanyuki",
      "state": "Nyeri",
      "country": "KE",
      "elevation": 6250,
      "lat": -0.0623988993,
      "lon": 37.0410079956,
      "tz": "Africa\/Nairobi"
  },
  "HKRE": {
      "icao": "HKRE",
      "iata": "",
      "name": "Moi Air Base",
      "city": "Nairobi",
      "state": "Nairobi-Area",
      "country": "KE",
      "elevation": 5336,
      "lat": -1.2772699594,
      "lon": 36.8623008728,
      "tz": "Africa\/Nairobi"
  },
  "HKSB": {
      "icao": "HKSB",
      "iata": "UAS",
      "name": "Samburu South Airport",
      "city": "Samburu South",
      "state": "",
      "country": "KE",
      "elevation": 3295,
      "lat": 0.530583024,
      "lon": 37.5341949463,
      "tz": "Africa\/Nairobi"
  },
  "HKUK": {
      "icao": "HKUK",
      "iata": "UKA",
      "name": "Ukunda Airstrip",
      "city": "Ukunda",
      "state": "",
      "country": "KE",
      "elevation": 98,
      "lat": -4.2933301926,
      "lon": 39.5710983276,
      "tz": "Africa\/Nairobi"
  },
  "HKVO": {
      "icao": "HKVO",
      "iata": "",
      "name": "Voi Airport",
      "city": "Voi",
      "state": "Taita-Taveta",
      "country": "KE",
      "elevation": 1900,
      "lat": -3.3745388985,
      "lon": 38.5347480774,
      "tz": "Africa\/Nairobi"
  },
  "HKWE": {
      "icao": "HKWE",
      "iata": "",
      "name": "Webuye Airport",
      "city": "Webuye",
      "state": "Bungoma",
      "country": "KE",
      "elevation": 4300,
      "lat": 0.6169999838,
      "lon": 34.783000946,
      "tz": "Africa\/Nairobi"
  },
  "HKWJ": {
      "icao": "HKWJ",
      "iata": "WJR",
      "name": "Wajir Airport",
      "city": "Wajir",
      "state": "Wajir",
      "country": "KE",
      "elevation": 770,
      "lat": 1.7332400084,
      "lon": 40.0915985107,
      "tz": "Africa\/Nairobi"
  },
  "HL50": {
      "icao": "HL50",
      "iata": "",
      "name": "Zelten Sw New Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 550,
      "lat": 28.5870990753,
      "lon": 19.3034000397,
      "tz": "Africa\/Tripoli"
  },
  "HL54": {
      "icao": "HL54",
      "iata": "",
      "name": "Beni Walid Airport",
      "city": "",
      "state": "Mi\u015fratah",
      "country": "LY",
      "elevation": 985,
      "lat": 31.739200592,
      "lon": 13.954000473,
      "tz": "Africa\/Tripoli"
  },
  "HL56": {
      "icao": "HL56",
      "iata": "",
      "name": "Matan al-Sarra Air Base",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 1722,
      "lat": 21.6877002716,
      "lon": 21.8309001923,
      "tz": "Africa\/Tripoli"
  },
  "HL57": {
      "icao": "HL57",
      "iata": "",
      "name": "Al Wigh Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 1558,
      "lat": 24.1858997345,
      "lon": 14.5327997208,
      "tz": "Africa\/Tripoli"
  },
  "HL58": {
      "icao": "HL58",
      "iata": "",
      "name": "Agedabia Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 50,
      "lat": 30.7651004791,
      "lon": 20.191400528,
      "tz": "Africa\/Tripoli"
  },
  "HL59": {
      "icao": "HL59",
      "iata": "",
      "name": "Al Khadim Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 800,
      "lat": 31.998500824,
      "lon": 21.1917991638,
      "tz": "Africa\/Tripoli"
  },
  "HL62": {
      "icao": "HL62",
      "iata": "",
      "name": "Zuetina Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 16,
      "lat": 30.8701992035,
      "lon": 20.0755004883,
      "tz": "Africa\/Tripoli"
  },
  "HL64": {
      "icao": "HL64",
      "iata": "",
      "name": "Qaryat Al Karmal Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 85,
      "lat": 31.9757003784,
      "lon": 20.0268993378,
      "tz": "Africa\/Tripoli"
  },
  "HL66": {
      "icao": "HL66",
      "iata": "",
      "name": "Taminhint Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 1325,
      "lat": 27.2401008606,
      "lon": 14.6562995911,
      "tz": "Africa\/Tripoli"
  },
  "HL69": {
      "icao": "HL69",
      "iata": "",
      "name": "Al Jufra Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 846,
      "lat": 29.1980991364,
      "lon": 16.0009994507,
      "tz": "Africa\/Tripoli"
  },
  "HL70": {
      "icao": "HL70",
      "iata": "",
      "name": "Al Khuwaymat Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 500,
      "lat": 27.2572994232,
      "lon": 21.6180992126,
      "tz": "Africa\/Tripoli"
  },
  "HL71": {
      "icao": "HL71",
      "iata": "",
      "name": "Bir Umran Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 1400,
      "lat": 26.3323993683,
      "lon": 13.4221000671,
      "tz": "Africa\/Tripoli"
  },
  "HL72": {
      "icao": "HL72",
      "iata": "",
      "name": "Waddan Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 910,
      "lat": 29.1392002106,
      "lon": 16.1602993011,
      "tz": "Africa\/Tripoli"
  },
  "HL78": {
      "icao": "HL78",
      "iata": "",
      "name": "Habit Awlad Muhammad Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 2000,
      "lat": 30.7019004822,
      "lon": 12.484000206,
      "tz": "Africa\/Tripoli"
  },
  "HL79": {
      "icao": "HL79",
      "iata": "",
      "name": "Waw Al Kabir Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 1465,
      "lat": 25.3568000793,
      "lon": 16.8099994659,
      "tz": "Africa\/Tripoli"
  },
  "HL80": {
      "icao": "HL80",
      "iata": "",
      "name": "Matratin Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 100,
      "lat": 30.642999649,
      "lon": 18.3208007813,
      "tz": "Africa\/Tripoli"
  },
  "HL81": {
      "icao": "HL81",
      "iata": "",
      "name": "Al Hamada Con 66 East Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 2090,
      "lat": 29.5310993195,
      "lon": 12.9436998367,
      "tz": "Africa\/Tripoli"
  },
  "HL82": {
      "icao": "HL82",
      "iata": "",
      "name": "Wadi Buzanad Sw Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 381,
      "lat": 28.9619007111,
      "lon": 17.5881004333,
      "tz": "Africa\/Tripoli"
  },
  "HL83": {
      "icao": "HL83",
      "iata": "",
      "name": "Nanur Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 185,
      "lat": 31.7052001953,
      "lon": 14.9116001129,
      "tz": "Africa\/Tripoli"
  },
  "HL84": {
      "icao": "HL84",
      "iata": "",
      "name": "Sarir Nw Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 330,
      "lat": 27.9757003784,
      "lon": 22.3574008942,
      "tz": "Africa\/Tripoli"
  },
  "HLAM": {
      "icao": "HLAM",
      "iata": "",
      "name": "Amal V12 Airport",
      "city": "Amal V12",
      "state": "",
      "country": "LY",
      "elevation": 145,
      "lat": 29.4794998169,
      "lon": 21.1224002838,
      "tz": "Africa\/Tripoli"
  },
  "HLBD": {
      "icao": "HLBD",
      "iata": "",
      "name": "Beda (M-3) Airport",
      "city": "Beda M3",
      "state": "",
      "country": "LY",
      "elevation": 499,
      "lat": 28.5032997131,
      "lon": 19.0027999878,
      "tz": "Africa\/Tripoli"
  },
  "HLFL": {
      "icao": "HLFL",
      "iata": "",
      "name": "Bu Attifel Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 161,
      "lat": 28.7954006195,
      "lon": 22.0809001923,
      "tz": "Africa\/Tripoli"
  },
  "HLGD": {
      "icao": "HLGD",
      "iata": "SRX",
      "name": "Gardabya Airport",
      "city": "Sirt",
      "state": "",
      "country": "LY",
      "elevation": 267,
      "lat": 31.0634994507,
      "lon": 16.5949993134,
      "tz": "Africa\/Tripoli"
  },
  "HLGL": {
      "icao": "HLGL",
      "iata": "",
      "name": "Warehouse 59e Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 325,
      "lat": 28.6385002136,
      "lon": 21.4379997253,
      "tz": "Africa\/Tripoli"
  },
  "HLGN": {
      "icao": "HLGN",
      "iata": "TOB",
      "name": "Gamal Abdel Nasser Airport",
      "city": "Tobruk",
      "state": "",
      "country": "LY",
      "elevation": 519,
      "lat": 31.861000061,
      "lon": 23.906999588,
      "tz": "Africa\/Tripoli"
  },
  "HLGT": {
      "icao": "HLGT",
      "iata": "GHT",
      "name": "Ghat Airport",
      "city": "Ghat",
      "state": "Sha\u2018biyat-Ghat",
      "country": "LY",
      "elevation": 2296,
      "lat": 25.1455993652,
      "lon": 10.1426000595,
      "tz": "Africa\/Tripoli"
  },
  "HLKF": {
      "icao": "HLKF",
      "iata": "AKF",
      "name": "Kufra Airport",
      "city": "Kufra",
      "state": "Al-Kufrah",
      "country": "LY",
      "elevation": 1367,
      "lat": 24.1786994934,
      "lon": 23.313999176,
      "tz": "Africa\/Tripoli"
  },
  "HLLB": {
      "icao": "HLLB",
      "iata": "BEN",
      "name": "Benina International Airport",
      "city": "Benghazi",
      "state": "Banghazi",
      "country": "LY",
      "elevation": 433,
      "lat": 32.0968017578,
      "lon": 20.2695007324,
      "tz": "Africa\/Tripoli"
  },
  "HLLM": {
      "icao": "HLLM",
      "iata": "MJI",
      "name": "Mitiga Airport",
      "city": "Tripoli",
      "state": "Tripoli",
      "country": "LY",
      "elevation": 36,
      "lat": 32.8941001892,
      "lon": 13.2760000229,
      "tz": "Africa\/Tripoli"
  },
  "HLLQ": {
      "icao": "HLLQ",
      "iata": "LAQ",
      "name": "La Abraq Airport",
      "city": "Al Bayda'",
      "state": "",
      "country": "LY",
      "elevation": 2157,
      "lat": 32.7887001038,
      "lon": 21.9643001556,
      "tz": "Africa\/Tripoli"
  },
  "HLLS": {
      "icao": "HLLS",
      "iata": "SEB",
      "name": "Sabha Airport",
      "city": "Sabha",
      "state": "",
      "country": "LY",
      "elevation": 1427,
      "lat": 26.9869995117,
      "lon": 14.4724998474,
      "tz": "Africa\/Tripoli"
  },
  "HLLT": {
      "icao": "HLLT",
      "iata": "TIP",
      "name": "Tripoli International Airport",
      "city": "Tripoli",
      "state": "",
      "country": "LY",
      "elevation": 263,
      "lat": 32.6635017395,
      "lon": 13.1590003967,
      "tz": "Africa\/Tripoli"
  },
  "HLMB": {
      "icao": "HLMB",
      "iata": "LMQ",
      "name": "Marsa Brega Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 50,
      "lat": 30.3780994415,
      "lon": 19.5764007568,
      "tz": "Africa\/Tripoli"
  },
  "HLNF": {
      "icao": "HLNF",
      "iata": "",
      "name": "Ras Lanuf Oil Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 42,
      "lat": 30.5,
      "lon": 18.5272006989,
      "tz": "Africa\/Tripoli"
  },
  "HLNR": {
      "icao": "HLNR",
      "iata": "NFR",
      "name": "Nafurah 1 Airport",
      "city": "Nafurah 1",
      "state": "",
      "country": "LY",
      "elevation": 122,
      "lat": 29.2131996155,
      "lon": 21.5923995972,
      "tz": "Africa\/Tripoli"
  },
  "HLON": {
      "icao": "HLON",
      "iata": "HUQ",
      "name": "Hon Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 919,
      "lat": 29.1100997925,
      "lon": 15.9656000137,
      "tz": "Africa\/Tripoli"
  },
  "HLRA": {
      "icao": "HLRA",
      "iata": "",
      "name": "Dahra Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 1050,
      "lat": 29.4726009369,
      "lon": 17.9349002838,
      "tz": "Africa\/Tripoli"
  },
  "HLSA": {
      "icao": "HLSA",
      "iata": "",
      "name": "Sarir (C-4) Airport",
      "city": "Sarir",
      "state": "",
      "country": "LY",
      "elevation": 400,
      "lat": 27.662399292,
      "lon": 22.508600235,
      "tz": "Africa\/Tripoli"
  },
  "HLSD": {
      "icao": "HLSD",
      "iata": "",
      "name": "Essider Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 20,
      "lat": 30.625,
      "lon": 18.3516998291,
      "tz": "Africa\/Tripoli"
  },
  "HLTD": {
      "icao": "HLTD",
      "iata": "LTD",
      "name": "Ghadames East Airport",
      "city": "Ghadames",
      "state": "",
      "country": "LY",
      "elevation": 1122,
      "lat": 30.1516990662,
      "lon": 9.7153100967,
      "tz": "Africa\/Tripoli"
  },
  "HLWA": {
      "icao": "HLWA",
      "iata": "",
      "name": "Warehouse 59A Airport",
      "city": "Warehouse 59A",
      "state": "",
      "country": "LY",
      "elevation": 488,
      "lat": 28.3223991394,
      "lon": 19.9300003052,
      "tz": "Africa\/Tripoli"
  },
  "HLZA": {
      "icao": "HLZA",
      "iata": "",
      "name": "Zella 74 Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 1085,
      "lat": 28.5898990631,
      "lon": 17.2938995361,
      "tz": "Africa\/Tripoli"
  },
  "HLZG": {
      "icao": "HLZG",
      "iata": "",
      "name": "Oxy A 103 Airport",
      "city": "Oxy A 103",
      "state": "",
      "country": "LY",
      "elevation": 318,
      "lat": 29.0062999725,
      "lon": 20.7861003876,
      "tz": "Africa\/Tripoli"
  },
  "HLZU": {
      "icao": "HLZU",
      "iata": "",
      "name": "Zueitina Airport",
      "city": "",
      "state": "",
      "country": "LY",
      "elevation": 20,
      "lat": 30.875,
      "lon": 20.0699996948,
      "tz": "Africa\/Tripoli"
  },
  "HLZW": {
      "icao": "HLZW",
      "iata": "WAX",
      "name": "Zwara Airport",
      "city": "Zuwara",
      "state": "",
      "country": "LY",
      "elevation": 0,
      "lat": 32.9523010254,
      "lon": 12.0155000687,
      "tz": "Africa\/Tripoli"
  },
  "HRYG": {
      "icao": "HRYG",
      "iata": "GYI",
      "name": "Gisenyi Airport",
      "city": "Gisenyi",
      "state": "Western-Province",
      "country": "RW",
      "elevation": 5082,
      "lat": -1.6771999598,
      "lon": 29.2588996887,
      "tz": "Africa\/Kigali"
  },
  "HRYI": {
      "icao": "HRYI",
      "iata": "BTQ",
      "name": "Butare Airport",
      "city": "Butare",
      "state": "Southern-Province",
      "country": "RW",
      "elevation": 5801,
      "lat": -2.5958299637,
      "lon": 29.736700058,
      "tz": "Africa\/Kigali"
  },
  "HRYN": {
      "icao": "HRYN",
      "iata": "",
      "name": "Nemba Airport",
      "city": "Nemba",
      "state": "Eastern-Province",
      "country": "RW",
      "elevation": 4905,
      "lat": -2.3329999447,
      "lon": 30.2000007629,
      "tz": "Africa\/Bujumbura"
  },
  "HRYO": {
      "icao": "HRYO",
      "iata": "",
      "name": "Gabiro Airport",
      "city": "Gabiro",
      "state": "Eastern-Province",
      "country": "RW",
      "elevation": 4806,
      "lat": -1.5499999523,
      "lon": 30.3829994202,
      "tz": "Africa\/Kigali"
  },
  "HRYR": {
      "icao": "HRYR",
      "iata": "KGL",
      "name": "Kigali International Airport",
      "city": "Kigali",
      "state": "Kigali",
      "country": "RW",
      "elevation": 4859,
      "lat": -1.9686299563,
      "lon": 30.1394996643,
      "tz": "Africa\/Kigali"
  },
  "HRYU": {
      "icao": "HRYU",
      "iata": "RHG",
      "name": "Ruhengeri Airport",
      "city": "Ruhengeri",
      "state": "Northern-Province",
      "country": "RW",
      "elevation": 6102,
      "lat": -1.5,
      "lon": 29.6329994202,
      "tz": "Africa\/Kigali"
  },
  "HRZA": {
      "icao": "HRZA",
      "iata": "KME",
      "name": "Kamembe Airport",
      "city": "Kamembe",
      "state": "Western-Province",
      "country": "RW",
      "elevation": 5192,
      "lat": -2.4622399807,
      "lon": 28.9078998566,
      "tz": "Africa\/Kigali"
  },
  "HSAK": {
      "icao": "HSAK",
      "iata": "",
      "name": "Akobo Airport",
      "city": "Akobo",
      "state": "",
      "country": "SS",
      "elevation": 0,
      "lat": 7.7833299637,
      "lon": 33,
      "tz": "Africa\/Juba"
  },
  "HSAT": {
      "icao": "HSAT",
      "iata": "ATB",
      "name": "Atbara Airport",
      "city": "Atbara",
      "state": "",
      "country": "SD",
      "elevation": 1181,
      "lat": 17.7103443146,
      "lon": 34.05701828,
      "tz": "Africa\/Khartoum"
  },
  "HSAW": {
      "icao": "HSAW",
      "iata": "",
      "name": "Aweil Airport",
      "city": "Aweil",
      "state": "",
      "country": "SS",
      "elevation": 1394,
      "lat": 8.7670001984,
      "lon": 27.3999996185,
      "tz": "Africa\/Juba"
  },
  "HSBR": {
      "icao": "HSBR",
      "iata": "",
      "name": "Bor Airport",
      "city": "Bor",
      "state": "",
      "country": "SS",
      "elevation": 1394,
      "lat": 6.1999998093,
      "lon": 31.5669994354,
      "tz": "Africa\/Juba"
  },
  "HSBT": {
      "icao": "HSBT",
      "iata": "",
      "name": "Bentu Airport",
      "city": "Bentu",
      "state": "",
      "country": "SS",
      "elevation": 2000,
      "lat": 9.2170000076,
      "lon": 29.8169994354,
      "tz": "Africa\/Juba"
  },
  "HSCG": {
      "icao": "HSCG",
      "iata": "",
      "name": "Carthago Airport",
      "city": "Carthago",
      "state": "Red-Sea",
      "country": "SD",
      "elevation": 3230,
      "lat": 18.75,
      "lon": 37,
      "tz": "Africa\/Khartoum"
  },
  "HSDB": {
      "icao": "HSDB",
      "iata": "EDB",
      "name": "El Debba Airport",
      "city": "El Debba",
      "state": "",
      "country": "SD",
      "elevation": 843,
      "lat": 18.0249996185,
      "lon": 30.9582996368,
      "tz": "Africa\/Khartoum"
  },
  "HSDL": {
      "icao": "HSDL",
      "iata": "",
      "name": "Dilling Airport",
      "city": "Dilling",
      "state": "",
      "country": "SD",
      "elevation": 2206,
      "lat": 11.9905996323,
      "lon": 29.6732006073,
      "tz": "Africa\/Khartoum"
  },
  "HSDN": {
      "icao": "HSDN",
      "iata": "DOG",
      "name": "Dongola Airport",
      "city": "Dongola",
      "state": "Northern-State",
      "country": "SD",
      "elevation": 772,
      "lat": 19.1539001465,
      "lon": 30.4300994873,
      "tz": "Africa\/Khartoum"
  },
  "HSDZ": {
      "icao": "HSDZ",
      "iata": "RSS",
      "name": "Damazin Airport",
      "city": "Ad Damazin",
      "state": "Blue-Nile",
      "country": "SD",
      "elevation": 1582,
      "lat": 11.785900116,
      "lon": 34.3367004395,
      "tz": "Africa\/Khartoum"
  },
  "HSFS": {
      "icao": "HSFS",
      "iata": "ELF",
      "name": "El Fasher Airport",
      "city": "El Fasher",
      "state": "Northern-Darfur",
      "country": "SD",
      "elevation": 2393,
      "lat": 13.6148996353,
      "lon": 25.3246002197,
      "tz": "Africa\/Khartoum"
  },
  "HSGF": {
      "icao": "HSGF",
      "iata": "GSU",
      "name": "Azaza Airport",
      "city": "Gedaref",
      "state": "",
      "country": "SD",
      "elevation": 1640,
      "lat": 14.1330003738,
      "lon": 35.3170013428,
      "tz": "Africa\/Khartoum"
  },
  "HSGG": {
      "icao": "HSGG",
      "iata": "DNX",
      "name": "Galegu Airport",
      "city": "Dinder",
      "state": "",
      "country": "SD",
      "elevation": 1640,
      "lat": 12.5329999924,
      "lon": 35.0670013428,
      "tz": "Africa\/Khartoum"
  },
  "HSGN": {
      "icao": "HSGN",
      "iata": "EGN",
      "name": "Geneina Airport",
      "city": "Geneina",
      "state": "Western-Darfur",
      "country": "SD",
      "elevation": 2650,
      "lat": 13.4816999435,
      "lon": 22.4671993256,
      "tz": "Africa\/Khartoum"
  },
  "HSGO": {
      "icao": "HSGO",
      "iata": "",
      "name": "Gogrial Airport",
      "city": "Gogrial",
      "state": "Northern-State",
      "country": "SD",
      "elevation": 1640,
      "lat": 18.8670005798,
      "lon": 28.1170005798,
      "tz": "Africa\/Khartoum"
  },
  "HSHG": {
      "icao": "HSHG",
      "iata": "HEG",
      "name": "Heglig Airport",
      "city": "Heglig Oilfield",
      "state": "",
      "country": "SD",
      "elevation": 1325,
      "lat": 9.994933,
      "lon": 29.397718,
      "tz": "Africa\/Khartoum"
  },
  "HSKA": {
      "icao": "HSKA",
      "iata": "KSL",
      "name": "Kassala Airport",
      "city": "Kassala",
      "state": "Kassala",
      "country": "SD",
      "elevation": 1671,
      "lat": 15.3874998093,
      "lon": 36.3288002014,
      "tz": "Africa\/Khartoum"
  },
  "HSKG": {
      "icao": "HSKG",
      "iata": "GBU",
      "name": "Khashm El Girba Airport",
      "city": "Khashm El Girba",
      "state": "",
      "country": "SD",
      "elevation": 1560,
      "lat": 14.9250001907,
      "lon": 35.8779983521,
      "tz": "Africa\/Khartoum"
  },
  "HSKI": {
      "icao": "HSKI",
      "iata": "KST",
      "name": "Kosti Airport",
      "city": "Kosti",
      "state": "White-Nile",
      "country": "SD",
      "elevation": 1289,
      "lat": 13.1829996109,
      "lon": 32.733001709,
      "tz": "Africa\/Khartoum"
  },
  "HSKJ": {
      "icao": "HSKJ",
      "iata": "",
      "name": "Kago Kaju Airport",
      "city": "Kago Kaju",
      "state": "",
      "country": "SS",
      "elevation": 2500,
      "lat": 3.9170000553,
      "lon": 31.6669998169,
      "tz": "Africa\/Juba"
  },
  "HSKP": {
      "icao": "HSKP",
      "iata": "",
      "name": "Kapoeta Airport",
      "city": "Kapoeta",
      "state": "",
      "country": "SS",
      "elevation": 2220,
      "lat": 4.7829999924,
      "lon": 33.5830001831,
      "tz": "Africa\/Juba"
  },
  "HSLI": {
      "icao": "HSLI",
      "iata": "KDX",
      "name": "Kadugli Airport",
      "city": "Kadugli",
      "state": "Southern-Kordofan",
      "country": "SD",
      "elevation": 1848,
      "lat": 11.1379995346,
      "lon": 29.7010993958,
      "tz": "Africa\/Khartoum"
  },
  "HSMD": {
      "icao": "HSMD",
      "iata": "",
      "name": "Marida Airport",
      "city": "Marida",
      "state": "",
      "country": "SS",
      "elevation": 1901,
      "lat": 4.9169998169,
      "lon": 29.466999054,
      "tz": "Africa\/Juba"
  },
  "HSMK": {
      "icao": "HSMK",
      "iata": "RBX",
      "name": "Rumbek Airport",
      "city": "Rumbek",
      "state": "",
      "country": "SS",
      "elevation": 1378,
      "lat": 6.8249998093,
      "lon": 29.6690006256,
      "tz": "Africa\/Juba"
  },
  "HSMN": {
      "icao": "HSMN",
      "iata": "",
      "name": "Merowe (Merowe New)",
      "city": "",
      "state": "Northern-State",
      "country": "SD",
      "elevation": 897,
      "lat": 18.4433333333,
      "lon": 31.8433333333,
      "tz": "Africa\/Khartoum"
  },
  "HSMR": {
      "icao": "HSMR",
      "iata": "MWE",
      "name": "Merowe Airport",
      "city": "Merowe",
      "state": "Northern-State",
      "country": "SD",
      "elevation": 846,
      "lat": 18.4500007629,
      "lon": 31.8330001831,
      "tz": "Africa\/Khartoum"
  },
  "HSND": {
      "icao": "HSND",
      "iata": "",
      "name": "Shendi Airport",
      "city": "Shendi",
      "state": "",
      "country": "SD",
      "elevation": 1181,
      "lat": 16.7000007629,
      "lon": 33.4329986572,
      "tz": "Africa\/Khartoum"
  },
  "HSNH": {
      "icao": "HSNH",
      "iata": "NUD",
      "name": "En Nahud Airport",
      "city": "En Nahud",
      "state": "West-Kordofan-State",
      "country": "SD",
      "elevation": 1955,
      "lat": 12.6669998169,
      "lon": 28.3330001831,
      "tz": "Africa\/Khartoum"
  },
  "HSNM": {
      "icao": "HSNM",
      "iata": "",
      "name": "Nimuli Airport",
      "city": "Nimuli",
      "state": "",
      "country": "SS",
      "elevation": 2059,
      "lat": 3.617000103,
      "lon": 32.1329994202,
      "tz": "Africa\/Juba"
  },
  "HSNN": {
      "icao": "HSNN",
      "iata": "UYL",
      "name": "Nyala Airport",
      "city": "Nyala",
      "state": "Southern-Darfur",
      "country": "SD",
      "elevation": 2106,
      "lat": 12.0535001755,
      "lon": 24.956199646,
      "tz": "Africa\/Khartoum"
  },
  "HSNW": {
      "icao": "HSNW",
      "iata": "NHF",
      "name": "New Halfa Airport",
      "city": "New Halfa",
      "state": "Kassala",
      "country": "SD",
      "elevation": 1480,
      "lat": 15.3556003571,
      "lon": 35.7277984619,
      "tz": "Africa\/Khartoum"
  },
  "HSOB": {
      "icao": "HSOB",
      "iata": "EBD",
      "name": "El Obeid Airport",
      "city": "Al-Ubayyid",
      "state": "North-Kordofan",
      "country": "SD",
      "elevation": 1927,
      "lat": 13.1532001495,
      "lon": 30.2327003479,
      "tz": "Africa\/Khartoum"
  },
  "HSPA": {
      "icao": "HSPA",
      "iata": "",
      "name": "Pachella Airport",
      "city": "Pachella",
      "state": "",
      "country": "SS",
      "elevation": 2000,
      "lat": 7.178563118,
      "lon": 34.0973587036,
      "tz": "Africa\/Addis_Ababa"
  },
  "HSPI": {
      "icao": "HSPI",
      "iata": "",
      "name": "Pibor Airport",
      "city": "Pibor Post",
      "state": "",
      "country": "SS",
      "elevation": 1377,
      "lat": 6.7933333333,
      "lon": 33.1305555556,
      "tz": "Africa\/Juba"
  },
  "HSPN": {
      "icao": "HSPN",
      "iata": "PZU",
      "name": "Port Sudan New International Airport",
      "city": "Port Sudan",
      "state": "Red-Sea",
      "country": "SD",
      "elevation": 135,
      "lat": 19.433599472,
      "lon": 37.2341003418,
      "tz": "Africa\/Khartoum"
  },
  "HSRJ": {
      "icao": "HSRJ",
      "iata": "",
      "name": "Raga Airport",
      "city": "Raga",
      "state": "",
      "country": "SS",
      "elevation": 1788,
      "lat": 8.4611101151,
      "lon": 25.6811008453,
      "tz": "Africa\/Juba"
  },
  "HSRN": {
      "icao": "HSRN",
      "iata": "",
      "name": "Renk Airport",
      "city": "Renk",
      "state": "",
      "country": "SS",
      "elevation": 1278,
      "lat": 11.8324692723,
      "lon": 32.8104972839,
      "tz": "Africa\/Juba"
  },
  "HSSJ": {
      "icao": "HSSJ",
      "iata": "JUB",
      "name": "Juba Airport",
      "city": "Juba",
      "state": "",
      "country": "SS",
      "elevation": 1513,
      "lat": 4.872010231,
      "lon": 31.6011009216,
      "tz": "Africa\/Juba"
  },
  "HSSM": {
      "icao": "HSSM",
      "iata": "MAK",
      "name": "Malakal Airport",
      "city": "Malakal",
      "state": "",
      "country": "SS",
      "elevation": 1291,
      "lat": 9.5589704514,
      "lon": 31.6522006989,
      "tz": "Africa\/Juba"
  },
  "HSSP": {
      "icao": "HSSP",
      "iata": "",
      "name": "Port Sudan Air Base",
      "city": "Port Sudan",
      "state": "Red-Sea",
      "country": "SD",
      "elevation": 10,
      "lat": 19.576499939,
      "lon": 37.2159004211,
      "tz": "Africa\/Khartoum"
  },
  "HSSS": {
      "icao": "HSSS",
      "iata": "KRT",
      "name": "Khartoum International Airport",
      "city": "Khartoum",
      "state": "Khartoum",
      "country": "SD",
      "elevation": 1265,
      "lat": 15.5895004272,
      "lon": 32.5531997681,
      "tz": "Africa\/Khartoum"
  },
  "HSSW": {
      "icao": "HSSW",
      "iata": "WHF",
      "name": "Wadi Halfa Airport",
      "city": "Wadi Halfa",
      "state": "Northern-State",
      "country": "SD",
      "elevation": 961,
      "lat": 21.8026981354,
      "lon": 31.5215778351,
      "tz": "Africa\/Khartoum"
  },
  "HSTO": {
      "icao": "HSTO",
      "iata": "",
      "name": "Tong Airport",
      "city": "Tong",
      "state": "",
      "country": "SS",
      "elevation": 1413,
      "lat": 7.2670001984,
      "lon": 28.9829998016,
      "tz": "Africa\/Juba"
  },
  "HSTR": {
      "icao": "HSTR",
      "iata": "",
      "name": "Torit Airport",
      "city": "Torit",
      "state": "",
      "country": "SS",
      "elevation": 2050,
      "lat": 4.4000000954,
      "lon": 32.5830001831,
      "tz": "Africa\/Juba"
  },
  "HSTU": {
      "icao": "HSTU",
      "iata": "",
      "name": "Tumbura Airport",
      "city": "Tumbura",
      "state": "",
      "country": "SS",
      "elevation": 2230,
      "lat": 5.5999999046,
      "lon": 27.4500007629,
      "tz": "Africa\/Juba"
  },
  "HSWW": {
      "icao": "HSWW",
      "iata": "WUU",
      "name": "Wau Airport",
      "city": "Wau",
      "state": "",
      "country": "SS",
      "elevation": 1529,
      "lat": 7.7258300781,
      "lon": 27.9750003815,
      "tz": "Africa\/Juba"
  },
  "HSYA": {
      "icao": "HSYA",
      "iata": "",
      "name": "Yambio Airport",
      "city": "Yambio",
      "state": "",
      "country": "SS",
      "elevation": 2375,
      "lat": 4.567220211,
      "lon": 28.4239006042,
      "tz": "Africa\/Juba"
  },
  "HSYE": {
      "icao": "HSYE",
      "iata": "",
      "name": "Yei Airport",
      "city": "Yei",
      "state": "",
      "country": "SS",
      "elevation": 2000,
      "lat": 4.0830001831,
      "lon": 30.6499996185,
      "tz": "Africa\/Juba"
  },
  "HSYL": {
      "icao": "HSYL",
      "iata": "",
      "name": "Yirol Airport",
      "city": "Yirol",
      "state": "",
      "country": "SS",
      "elevation": 1424,
      "lat": 6.5669999123,
      "lon": 30.5,
      "tz": "Africa\/Juba"
  },
  "HSZA": {
      "icao": "HSZA",
      "iata": "ZLX",
      "name": "Zalingei Airport",
      "city": "Zalingei",
      "state": "Central-Darfur",
      "country": "SD",
      "elevation": 2953,
      "lat": 12.8999996185,
      "lon": 23.4829998016,
      "tz": "Africa\/Khartoum"
  },
  "HTAG": {
      "icao": "HTAG",
      "iata": "",
      "name": "Amani Gomvu Airport",
      "city": "Kutani",
      "state": "Dar-es-Salaam",
      "country": "TZ",
      "elevation": 114,
      "lat": -6.935353,
      "lon": 39.493046,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTAR": {
      "icao": "HTAR",
      "iata": "ARK",
      "name": "Arusha Airport",
      "city": "Arusha",
      "state": "Arusha",
      "country": "TZ",
      "elevation": 4550,
      "lat": -3.3677899837,
      "lon": 36.6333007813,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTBB": {
      "icao": "HTBB",
      "iata": "",
      "name": "Kibambawe Airstrip",
      "city": "Selous Game Reserve",
      "state": "Pwani",
      "country": "TZ",
      "elevation": 485,
      "lat": -7.748241,
      "lon": 38.001833,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTBS": {
      "icao": "HTBS",
      "iata": "",
      "name": "Mbesa Airport",
      "city": "Mbesa Mission",
      "state": "Ruvuma",
      "country": "TZ",
      "elevation": 1980,
      "lat": -11.331675,
      "lon": 37.067816,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTBU": {
      "icao": "HTBU",
      "iata": "BKZ",
      "name": "Bukoba Airport",
      "city": "Bukoba",
      "state": "Kagera",
      "country": "TZ",
      "elevation": 3745,
      "lat": -1.332,
      "lon": 31.8212,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTCH": {
      "icao": "HTCH",
      "iata": "",
      "name": "Chunya Airport",
      "city": "Chunya",
      "state": "Mbeya",
      "country": "TZ",
      "elevation": 5000,
      "lat": -8.5500001907,
      "lon": 33.4329986572,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTDA": {
      "icao": "HTDA",
      "iata": "DAR",
      "name": "Mwalimu Julius K. Nyerere International Airport",
      "city": "Dar es Salaam",
      "state": "Dar-es-Salaam",
      "country": "TZ",
      "elevation": 182,
      "lat": -6.8781099319,
      "lon": 39.2025985718,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTDO": {
      "icao": "HTDO",
      "iata": "DOD",
      "name": "Dodoma Airport",
      "city": "Dodoma",
      "state": "Dodoma",
      "country": "TZ",
      "elevation": 3673,
      "lat": -6.170440197,
      "lon": 35.7526016235,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTIR": {
      "icao": "HTIR",
      "iata": "IRI",
      "name": "Iringa Airport",
      "city": "Nduli",
      "state": "Iringa",
      "country": "TZ",
      "elevation": 4678,
      "lat": -7.6686301231,
      "lon": 35.7521018982,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTIY": {
      "icao": "HTIY",
      "iata": "",
      "name": "Inyonga Airport",
      "city": "Inyonga",
      "state": "Katavi",
      "country": "TZ",
      "elevation": 3790,
      "lat": -6.7166700363,
      "lon": 32.0999984741,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKA": {
      "icao": "HTKA",
      "iata": "TKQ",
      "name": "Kigoma Airport",
      "city": "Kigoma",
      "state": "Kigoma",
      "country": "TZ",
      "elevation": 2700,
      "lat": -4.8862,
      "lon": 29.6709,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKB": {
      "icao": "HTKB",
      "iata": "",
      "name": "Kibondo Airport",
      "city": "Kibondo",
      "state": "Kigoma",
      "country": "TZ",
      "elevation": 4262,
      "lat": -3.5259799957,
      "lon": 30.6504001617,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKD": {
      "icao": "HTKD",
      "iata": "",
      "name": "Kondoa Airport",
      "city": "Kondoa",
      "state": "Dodoma",
      "country": "TZ",
      "elevation": 4578,
      "lat": -4.8937101364,
      "lon": 35.7682991028,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKI": {
      "icao": "HTKI",
      "iata": "KIY",
      "name": "Kilwa Masoko Airport",
      "city": "Kilwa Masoko",
      "state": "Lindi",
      "country": "TZ",
      "elevation": 50,
      "lat": -8.9329996109,
      "lon": 39.516998291,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKJ": {
      "icao": "HTKJ",
      "iata": "JRO",
      "name": "Kilimanjaro International Airport",
      "city": "Arusha",
      "state": "Kilimanjaro",
      "country": "TZ",
      "elevation": 2932,
      "lat": -3.4294099808,
      "lon": 37.0745010376,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKL": {
      "icao": "HTKL",
      "iata": "",
      "name": "Kirondatal Airport",
      "city": "Kirondatal",
      "state": "Singida",
      "country": "TZ",
      "elevation": 5033,
      "lat": -4.3143720627,
      "lon": 34.3333206177,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKO": {
      "icao": "HTKO",
      "iata": "",
      "name": "Kongwa Airport",
      "city": "Kongwa",
      "state": "Dodoma",
      "country": "TZ",
      "elevation": 3300,
      "lat": -6.1500000954,
      "lon": 36.4169998169,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKS": {
      "icao": "HTKS",
      "iata": "",
      "name": "Kilosa Airport",
      "city": "Kilosa",
      "state": "Morogoro",
      "country": "TZ",
      "elevation": 1567,
      "lat": -6.9000000954,
      "lon": 37.0166664124,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTKT": {
      "icao": "HTKT",
      "iata": "",
      "name": "Kilimatinde Airport",
      "city": "Kilimatinde",
      "state": "Singida",
      "country": "TZ",
      "elevation": 3608,
      "lat": -5.8330001831,
      "lon": 34.9329986572,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTLD": {
      "icao": "HTLD",
      "iata": "",
      "name": "Loliondo Airport",
      "city": "Loliondo",
      "state": "Arusha",
      "country": "TZ",
      "elevation": 6620,
      "lat": -2.0696899891,
      "lon": 35.5457000732,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTLI": {
      "icao": "HTLI",
      "iata": "LDI",
      "name": "Kikwetu Airport",
      "city": "Lindi",
      "state": "Lindi",
      "country": "TZ",
      "elevation": 100,
      "lat": -9.8511104584,
      "lon": 39.7578010559,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTLL": {
      "icao": "HTLL",
      "iata": "",
      "name": "Liuli Airport",
      "city": "Liuli",
      "state": "Ruvuma",
      "country": "TZ",
      "elevation": 1667,
      "lat": -11.116399765,
      "lon": 34.6521987915,
      "tz": "Africa\/Blantyre"
  },
  "HTLM": {
      "icao": "HTLM",
      "iata": "LKY",
      "name": "Lake Manyara Airport",
      "city": "Lake Manyara National Park",
      "state": "Arusha",
      "country": "TZ",
      "elevation": 4150,
      "lat": -3.3763101101,
      "lon": 35.8182983398,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMA": {
      "icao": "HTMA",
      "iata": "MFA",
      "name": "Mafia Island Airport",
      "city": "Mafia Island",
      "state": "Pwani",
      "country": "TZ",
      "elevation": 60,
      "lat": -7.9169998169,
      "lon": 39.6669998169,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMB": {
      "icao": "HTMB",
      "iata": "MBI",
      "name": "Mbeya Airport",
      "city": "Mbeya",
      "state": "Mbeya",
      "country": "TZ",
      "elevation": 5600,
      "lat": -8.9169998169,
      "lon": 33.466999054,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMD": {
      "icao": "HTMD",
      "iata": "MWN",
      "name": "Mwadui Airport",
      "city": "Mwadui",
      "state": "Shinyanga",
      "country": "TZ",
      "elevation": 3970,
      "lat": -3.5,
      "lon": 33.6170005798,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMF": {
      "icao": "HTMF",
      "iata": "",
      "name": "Mufindi Airport",
      "city": "Mufindi",
      "state": "Iringa",
      "country": "TZ",
      "elevation": 4175,
      "lat": -8.7320404053,
      "lon": 35.3026008606,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMG": {
      "icao": "HTMG",
      "iata": "",
      "name": "Morogoro Airport",
      "city": "Morogoro",
      "state": "Morogoro",
      "country": "TZ",
      "elevation": 1676,
      "lat": -6.7972202301,
      "lon": 37.6530990601,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMI": {
      "icao": "HTMI",
      "iata": "XMI",
      "name": "Masasi Airport",
      "city": "Masasi",
      "state": "Mtwara",
      "country": "TZ",
      "elevation": 1700,
      "lat": -10.7329998016,
      "lon": 38.766998291,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMK": {
      "icao": "HTMK",
      "iata": "",
      "name": "Mikumi Airport",
      "city": "Mikumi",
      "state": "Morogoro",
      "country": "TZ",
      "elevation": 1737,
      "lat": -7.333427906,
      "lon": 37.1163597107,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTML": {
      "icao": "HTML",
      "iata": "",
      "name": "Malya Airport",
      "city": "Malya",
      "state": "Shinyanga",
      "country": "TZ",
      "elevation": 4079,
      "lat": -2.9778599739,
      "lon": 33.5163002014,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMO": {
      "icao": "HTMO",
      "iata": "",
      "name": "Mombo Airport",
      "city": "Mombo",
      "state": "Tanga",
      "country": "TZ",
      "elevation": 1350,
      "lat": -4.882999897,
      "lon": 38.283000946,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMP": {
      "icao": "HTMP",
      "iata": "",
      "name": "Mpanda Airport",
      "city": "Mpanda",
      "state": "Rukwa",
      "country": "TZ",
      "elevation": 3520,
      "lat": -6.3499999046,
      "lon": 31.0830001831,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMS": {
      "icao": "HTMS",
      "iata": "QSI",
      "name": "Moshi Airport",
      "city": "Moshi",
      "state": "Kilimanjaro",
      "country": "TZ",
      "elevation": 2801,
      "lat": -3.3633298874,
      "lon": 37.3269004822,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMT": {
      "icao": "HTMT",
      "iata": "MYW",
      "name": "Mtwara Airport",
      "city": "Mtwara",
      "state": "Mtwara",
      "country": "TZ",
      "elevation": 371,
      "lat": -10.339099884,
      "lon": 40.1818008423,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMU": {
      "icao": "HTMU",
      "iata": "MUZ",
      "name": "Musoma Airport",
      "city": "Musoma",
      "state": "Mara",
      "country": "TZ",
      "elevation": 3806,
      "lat": -1.503,
      "lon": 33.8021,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMW": {
      "icao": "HTMW",
      "iata": "MWZ",
      "name": "Mwanza Airport",
      "city": "Mwanza",
      "state": "Mwanza",
      "country": "TZ",
      "elevation": 3763,
      "lat": -2.4444899559,
      "lon": 32.9327011108,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTMX": {
      "icao": "HTMX",
      "iata": "",
      "name": "Mpwapwa Airport",
      "city": "Mpwapwa",
      "state": "Dodoma",
      "country": "TZ",
      "elevation": 3240,
      "lat": -6.3499999046,
      "lon": 36.483001709,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTNA": {
      "icao": "HTNA",
      "iata": "NCH",
      "name": "Nachingwea Airport",
      "city": "Nachingwea",
      "state": "Lindi",
      "country": "TZ",
      "elevation": 1400,
      "lat": -10.3575000763,
      "lon": 38.7792015076,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTNJ": {
      "icao": "HTNJ",
      "iata": "JOM",
      "name": "Njombe Airport",
      "city": "Njombe",
      "state": "Njombe",
      "country": "TZ",
      "elevation": 6400,
      "lat": -9.3500003815,
      "lon": 34.7999992371,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTNR": {
      "icao": "HTNR",
      "iata": "",
      "name": "Ngara Airport",
      "city": "Ngara",
      "state": "Kagera",
      "country": "TZ",
      "elevation": 5030,
      "lat": -2.5417900085,
      "lon": 30.7031002045,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTOL": {
      "icao": "HTOL",
      "iata": "",
      "name": "Oltipesi Airport",
      "city": "",
      "state": "Manyara",
      "country": "TZ",
      "elevation": 4668,
      "lat": -4.287732,
      "lon": 36.616115,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTPE": {
      "icao": "HTPE",
      "iata": "PMA",
      "name": "Pemba Airport",
      "city": "Chake",
      "state": "Pemba-South",
      "country": "TZ",
      "elevation": 80,
      "lat": -5.2572598457,
      "lon": 39.8114013672,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTSD": {
      "icao": "HTSD",
      "iata": "",
      "name": "Singida Airport",
      "city": "Singida",
      "state": "Singida",
      "country": "TZ",
      "elevation": 5000,
      "lat": -4.8169999123,
      "lon": 34.733001709,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTSE": {
      "icao": "HTSE",
      "iata": "",
      "name": "Same Airport",
      "city": "Same",
      "state": "Kilimanjaro",
      "country": "TZ",
      "elevation": 3000,
      "lat": -4.0830001831,
      "lon": 37.716999054,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTSH": {
      "icao": "HTSH",
      "iata": "",
      "name": "Mafinga Airport",
      "city": "Mafinga",
      "state": "Iringa",
      "country": "TZ",
      "elevation": 6300,
      "lat": -8.3500003815,
      "lon": 35.2999992371,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTSN": {
      "icao": "HTSN",
      "iata": "SEU",
      "name": "Seronera Airport",
      "city": "Seronera",
      "state": "Mara",
      "country": "TZ",
      "elevation": 5080,
      "lat": -2.4580600262,
      "lon": 34.8224983215,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTSO": {
      "icao": "HTSO",
      "iata": "SGX",
      "name": "Songea Airport",
      "city": "Songea",
      "state": "Ruvuma",
      "country": "TZ",
      "elevation": 3445,
      "lat": -10.6829996109,
      "lon": 35.5830001831,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTSU": {
      "icao": "HTSU",
      "iata": "SUT",
      "name": "Sumbawanga Airport",
      "city": "Sumbawanga",
      "state": "Rukwa",
      "country": "TZ",
      "elevation": 5920,
      "lat": -7.9670000076,
      "lon": 31.6669998169,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTSY": {
      "icao": "HTSY",
      "iata": "SHY",
      "name": "Shinyanga Airport",
      "city": "Shinyanga",
      "state": "Shinyanga",
      "country": "TZ",
      "elevation": 3800,
      "lat": -3.6093,
      "lon": 33.5035,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTTB": {
      "icao": "HTTB",
      "iata": "TBO",
      "name": "Tabora Airport",
      "city": "Tabora",
      "state": "Tabora",
      "country": "TZ",
      "elevation": 3868,
      "lat": -5.0763897896,
      "lon": 32.8333015442,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTTG": {
      "icao": "HTTG",
      "iata": "TGT",
      "name": "Tanga Airport",
      "city": "Tanga",
      "state": "Tanga",
      "country": "TZ",
      "elevation": 129,
      "lat": -5.0923600197,
      "lon": 39.0712013245,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTTU": {
      "icao": "HTTU",
      "iata": "",
      "name": "Tunduru Airport",
      "city": "Tunduru",
      "state": "Ruvuma",
      "country": "TZ",
      "elevation": 2200,
      "lat": -11.1000003815,
      "lon": 37.3670005798,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTUK": {
      "icao": "HTUK",
      "iata": "",
      "name": "Nansio Airport",
      "city": "Ukerewe",
      "state": "Mara",
      "country": "TZ",
      "elevation": 4010,
      "lat": -2.0435299873,
      "lon": 33.0643997192,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTUR": {
      "icao": "HTUR",
      "iata": "",
      "name": "Urambo Airport",
      "city": "Urambo",
      "state": "Tabora",
      "country": "TZ",
      "elevation": 3600,
      "lat": -5.0669999123,
      "lon": 32.0499992371,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTUT": {
      "icao": "HTUT",
      "iata": "",
      "name": "Utete Airport",
      "city": "Utete",
      "state": "Pwani",
      "country": "TZ",
      "elevation": 208,
      "lat": -8.002579689,
      "lon": 38.7616996765,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTUV": {
      "icao": "HTUV",
      "iata": "",
      "name": "Uvinza Airport",
      "city": "Uvinza",
      "state": "Kigoma",
      "country": "TZ",
      "elevation": 563,
      "lat": -5.0879502296,
      "lon": 30.4060001373,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTWK": {
      "icao": "HTWK",
      "iata": "",
      "name": "West Kilimanjaro Airport",
      "city": "West Kilimanjaro",
      "state": "Kilimanjaro",
      "country": "TZ",
      "elevation": 4300,
      "lat": -3.0499999523,
      "lon": 37,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HTZA": {
      "icao": "HTZA",
      "iata": "ZNZ",
      "name": "Zanzibar Airport",
      "city": "Kiembi Samaki",
      "state": "Zanzibar-Urban\/West",
      "country": "TZ",
      "elevation": 54,
      "lat": -6.2220201492,
      "lon": 39.224899292,
      "tz": "Africa\/Dar_es_Salaam"
  },
  "HUAJ": {
      "icao": "HUAJ",
      "iata": "",
      "name": "Adjumani Airport",
      "city": "Adjumani",
      "state": "Northern-Region",
      "country": "UG",
      "elevation": 2611,
      "lat": 3.3392300606,
      "lon": 31.7646999359,
      "tz": "Africa\/Kampala"
  },
  "HUAR": {
      "icao": "HUAR",
      "iata": "RUA",
      "name": "Arua Airport",
      "city": "Arua",
      "state": "Northern-Region",
      "country": "UG",
      "elevation": 3951,
      "lat": 3.0499999523,
      "lon": 30.9169998169,
      "tz": "Africa\/Kampala"
  },
  "HUEN": {
      "icao": "HUEN",
      "iata": "EBB",
      "name": "Entebbe International Airport",
      "city": "Kampala",
      "state": "Central-Region",
      "country": "UG",
      "elevation": 3782,
      "lat": 0.0423859991,
      "lon": 32.4435005188,
      "tz": "Africa\/Kampala"
  },
  "HUGU": {
      "icao": "HUGU",
      "iata": "ULU",
      "name": "Gulu Airport",
      "city": "Gulu",
      "state": "Northern-Region",
      "country": "UG",
      "elevation": 3510,
      "lat": 2.805560112,
      "lon": 32.2718009949,
      "tz": "Africa\/Kampala"
  },
  "HUJI": {
      "icao": "HUJI",
      "iata": "JIN",
      "name": "Jinja Airport",
      "city": "Jinja",
      "state": "Eastern-Region",
      "country": "UG",
      "elevation": 3855,
      "lat": 0.4499999881,
      "lon": 33.2000007629,
      "tz": "Africa\/Kampala"
  },
  "HUKB": {
      "icao": "HUKB",
      "iata": "",
      "name": "Kabale Airport",
      "city": "",
      "state": "Western-Region",
      "country": "UG",
      "elevation": 6000,
      "lat": -1.226111,
      "lon": 29.96,
      "tz": "Africa\/Kampala"
  },
  "HUKF": {
      "icao": "HUKF",
      "iata": "KBG",
      "name": "Kabalega Falls Airport",
      "city": "Kabalega Falls",
      "state": "Northern-Region",
      "country": "UG",
      "elevation": 2365,
      "lat": 2.326390028,
      "lon": 31.497800827,
      "tz": "Africa\/Kampala"
  },
  "HUKS": {
      "icao": "HUKS",
      "iata": "KSE",
      "name": "Kasese Airport",
      "city": "Kasese",
      "state": "Western-Region",
      "country": "UG",
      "elevation": 3146,
      "lat": 0.1829999983,
      "lon": 30.1000003815,
      "tz": "Africa\/Kampala"
  },
  "HULI": {
      "icao": "HULI",
      "iata": "",
      "name": "Lira Airport",
      "city": "Lira",
      "state": "Northern-Region",
      "country": "UG",
      "elevation": 3580,
      "lat": 2.25,
      "lon": 32.9169998169,
      "tz": "Africa\/Kampala"
  },
  "HUMA": {
      "icao": "HUMA",
      "iata": "MBQ",
      "name": "Mbarara Airport",
      "city": "Mbarara",
      "state": "Western-Region",
      "country": "UG",
      "elevation": 4600,
      "lat": -0.5552780032,
      "lon": 30.5993995667,
      "tz": "Africa\/Kampala"
  },
  "HUMI": {
      "icao": "HUMI",
      "iata": "KCU",
      "name": "Masindi Airport",
      "city": "Masindi",
      "state": "Western-Region",
      "country": "UG",
      "elevation": 3850,
      "lat": 1.7580599785,
      "lon": 31.736700058,
      "tz": "Africa\/Kampala"
  },
  "HUPA": {
      "icao": "HUPA",
      "iata": "PAF",
      "name": "Pakuba Airport",
      "city": "",
      "state": "Western-Region",
      "country": "UG",
      "elevation": 0,
      "lat": 2.202222,
      "lon": 31.554444,
      "tz": "Africa\/Kampala"
  },
  "HUSO": {
      "icao": "HUSO",
      "iata": "SRT",
      "name": "Soroti Airport",
      "city": "Soroti",
      "state": "Eastern-Region",
      "country": "UG",
      "elevation": 3697,
      "lat": 1.7276899815,
      "lon": 33.6227989197,
      "tz": "Africa\/Kampala"
  },
  "HUTO": {
      "icao": "HUTO",
      "iata": "TRY",
      "name": "Tororo Airport",
      "city": "Tororo",
      "state": "Eastern-Region",
      "country": "UG",
      "elevation": 3840,
      "lat": 0.6830000281,
      "lon": 34.1669998169,
      "tz": "Africa\/Kampala"
  },
  "IA00": {
      "icao": "IA00",
      "iata": "",
      "name": "Jukam's Landing Airport",
      "city": "Menlo",
      "state": "Iowa",
      "country": "US",
      "elevation": 1230,
      "lat": 41.5200004578,
      "lon": -94.362701416,
      "tz": "America\/Chicago"
  },
  "IA01": {
      "icao": "IA01",
      "iata": "",
      "name": "Ridge Airport",
      "city": "Oakland",
      "state": "Iowa",
      "country": "US",
      "elevation": 1300,
      "lat": 41.351398468,
      "lon": -95.4664001465,
      "tz": "America\/Chicago"
  },
  "IA02": {
      "icao": "IA02",
      "iata": "",
      "name": "Hoff Airport",
      "city": "Bellevue",
      "state": "Iowa",
      "country": "US",
      "elevation": 1025,
      "lat": 42.2620010376,
      "lon": -90.5801010132,
      "tz": "America\/Chicago"
  },
  "IA03": {
      "icao": "IA03",
      "iata": "",
      "name": "Lemons Airport",
      "city": "Cedar Falls",
      "state": "Iowa",
      "country": "US",
      "elevation": 970,
      "lat": 42.5222015381,
      "lon": -92.4945983887,
      "tz": "America\/Chicago"
  },
  "IA05": {
      "icao": "IA05",
      "iata": "",
      "name": "Rathbun Lake Airport",
      "city": "Centerville",
      "state": "Iowa",
      "country": "US",
      "elevation": 1200,
      "lat": 40.8516998291,
      "lon": -92.8584976196,
      "tz": "America\/Chicago"
  },
  "IA06": {
      "icao": "IA06",
      "iata": "",
      "name": "Sig-Nor Airport",
      "city": "Sigourney",
      "state": "Iowa",
      "country": "US",
      "elevation": 818,
      "lat": 41.3833007813,
      "lon": -92.1835021973,
      "tz": "America\/Chicago"
  },
  "IA07": {
      "icao": "IA07",
      "iata": "",
      "name": "Grismore Airport",
      "city": "Corydon",
      "state": "Iowa",
      "country": "US",
      "elevation": 1100,
      "lat": 40.7667007446,
      "lon": -93.300201416,
      "tz": "America\/Chicago"
  },
  "IA08": {
      "icao": "IA08",
      "iata": "",
      "name": "Riedesel Private Airport",
      "city": "Lohrville",
      "state": "Iowa",
      "country": "US",
      "elevation": 1150,
      "lat": 42.2780990601,
      "lon": -94.5516967773,
      "tz": "America\/Chicago"
  },
  "IA11": {
      "icao": "IA11",
      "iata": "",
      "name": "Too Short Airport",
      "city": "Indianola",
      "state": "Iowa",
      "country": "US",
      "elevation": 980,
      "lat": 41.2938995361,
      "lon": -93.6654968262,
      "tz": "America\/Chicago"
  },
  "IA12": {
      "icao": "IA12",
      "iata": "",
      "name": "Lloyd's Field",
      "city": "Clear Lake",
      "state": "Iowa",
      "country": "US",
      "elevation": 1220,
      "lat": 43.0971984863,
      "lon": -93.4021987915,
      "tz": "America\/Chicago"
  },
  "IA15": {
      "icao": "IA15",
      "iata": "",
      "name": "Hawk Field",
      "city": "Hawkeye",
      "state": "Iowa",
      "country": "US",
      "elevation": 1115,
      "lat": 42.8869018555,
      "lon": -91.9360961914,
      "tz": "America\/Chicago"
  },
  "IA16": {
      "icao": "IA16",
      "iata": "",
      "name": "Picayune Airport",
      "city": "Iowa City",
      "state": "Iowa",
      "country": "US",
      "elevation": 800,
      "lat": 41.7083015442,
      "lon": -91.5001983643,
      "tz": "America\/Chicago"
  },
  "IA17": {
      "icao": "IA17",
      "iata": "",
      "name": "Bluebird Airport",
      "city": "Janesville",
      "state": "Iowa",
      "country": "US",
      "elevation": 924,
      "lat": 42.6230010986,
      "lon": -92.5012969971,
      "tz": "America\/Chicago"
  },
  "IA18": {
      "icao": "IA18",
      "iata": "",
      "name": "Poyner Airport",
      "city": "Jesup",
      "state": "Iowa",
      "country": "US",
      "elevation": 960,
      "lat": 42.4707984924,
      "lon": -92.0885009766,
      "tz": "America\/Chicago"
  },
  "IA21": {
      "icao": "IA21",
      "iata": "",
      "name": "Schurr Airport",
      "city": "Leclaire",
      "state": "Iowa",
      "country": "US",
      "elevation": 720,
      "lat": 41.5999984741,
      "lon": -90.3834991455,
      "tz": "America\/Chicago"
  },
  "IA22": {
      "icao": "IA22",
      "iata": "",
      "name": "Orr-Port Airport",
      "city": "New London",
      "state": "Iowa",
      "country": "US",
      "elevation": 790,
      "lat": 40.9384002686,
      "lon": -91.403503418,
      "tz": "America\/Chicago"
  },
  "IA23": {
      "icao": "IA23",
      "iata": "",
      "name": "Gaa Private Airport",
      "city": "Guttenberg",
      "state": "Iowa",
      "country": "US",
      "elevation": 620,
      "lat": 42.8088989258,
      "lon": -91.098197937,
      "tz": "America\/Chicago"
  },
  "IA24": {
      "icao": "IA24",
      "iata": "",
      "name": "Green Castle Airport",
      "city": "Oxford",
      "state": "Iowa",
      "country": "US",
      "elevation": 750,
      "lat": 41.7550010681,
      "lon": -91.7276992798,
      "tz": "America\/Chicago"
  },
  "IA25": {
      "icao": "IA25",
      "iata": "",
      "name": "Bartlett Field",
      "city": "Solon",
      "state": "Iowa",
      "country": "US",
      "elevation": 820,
      "lat": 41.8005981445,
      "lon": -91.4399032593,
      "tz": "America\/Chicago"
  },
  "IA26": {
      "icao": "IA26",
      "iata": "",
      "name": "Carter Field",
      "city": "Stockport",
      "state": "Iowa",
      "country": "US",
      "elevation": 750,
      "lat": 40.8083992004,
      "lon": -91.8085021973,
      "tz": "America\/Chicago"
  },
  "IA27": {
      "icao": "IA27",
      "iata": "",
      "name": "Antique Airfield",
      "city": "Blakesburg",
      "state": "Iowa",
      "country": "US",
      "elevation": 890,
      "lat": 40.9777984619,
      "lon": -92.5876998901,
      "tz": "America\/Chicago"
  },
  "IA28": {
      "icao": "IA28",
      "iata": "",
      "name": "Sherman Airport",
      "city": "Hiawatha",
      "state": "Iowa",
      "country": "US",
      "elevation": 860,
      "lat": 42.0583000183,
      "lon": -91.6902008057,
      "tz": "America\/Chicago"
  },
  "IA30": {
      "icao": "IA30",
      "iata": "",
      "name": "Kleis Airport",
      "city": "Zwingle",
      "state": "Iowa",
      "country": "US",
      "elevation": 1050,
      "lat": 42.2999992371,
      "lon": -90.7500991821,
      "tz": "America\/Chicago"
  },
  "IA31": {
      "icao": "IA31",
      "iata": "",
      "name": "Beeds Lake Airport",
      "city": "Hampton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1148,
      "lat": 42.7666015625,
      "lon": -93.2460021973,
      "tz": "America\/Chicago"
  },
  "IA32": {
      "icao": "IA32",
      "iata": "",
      "name": "Pierson Field",
      "city": "Oskaloosa",
      "state": "Iowa",
      "country": "US",
      "elevation": 845,
      "lat": 41.3250007629,
      "lon": -92.7171020508,
      "tz": "America\/Chicago"
  },
  "IA33": {
      "icao": "IA33",
      "iata": "",
      "name": "Dad's Field",
      "city": "Rockwell",
      "state": "Iowa",
      "country": "US",
      "elevation": 1200,
      "lat": 43.0119018555,
      "lon": -93.242401123,
      "tz": "America\/Chicago"
  },
  "IA35": {
      "icao": "IA35",
      "iata": "",
      "name": "Ruckl Airport",
      "city": "Council Bluffs",
      "state": "Iowa",
      "country": "US",
      "elevation": 970,
      "lat": 41.1871986389,
      "lon": -95.9041976929,
      "tz": "America\/Chicago"
  },
  "IA36": {
      "icao": "IA36",
      "iata": "",
      "name": "Mercer Field",
      "city": "Creston",
      "state": "Iowa",
      "country": "US",
      "elevation": 1320,
      "lat": 41.0750007629,
      "lon": -94.426902771,
      "tz": "America\/Chicago"
  },
  "IA38": {
      "icao": "IA38",
      "iata": "",
      "name": "De Louis Field",
      "city": "Des Moines",
      "state": "Iowa",
      "country": "US",
      "elevation": 1250,
      "lat": 41.5480995178,
      "lon": -93.4291992188,
      "tz": "America\/Chicago"
  },
  "IA41": {
      "icao": "IA41",
      "iata": "",
      "name": "Laverty Field",
      "city": "Indianola",
      "state": "Iowa",
      "country": "US",
      "elevation": 950,
      "lat": 41.4152984619,
      "lon": -93.5613021851,
      "tz": "America\/Chicago"
  },
  "IA44": {
      "icao": "IA44",
      "iata": "",
      "name": "Solly's Strip Ultralightport",
      "city": "Lamoni",
      "state": "Iowa",
      "country": "US",
      "elevation": 1150,
      "lat": 40.6514015198,
      "lon": -93.9608001709,
      "tz": "America\/Chicago"
  },
  "IA45": {
      "icao": "IA45",
      "iata": "",
      "name": "Willie's Bomber Patch Airport",
      "city": "Marcus",
      "state": "Iowa",
      "country": "US",
      "elevation": 1446,
      "lat": 42.7750015259,
      "lon": -95.8460998535,
      "tz": "America\/Chicago"
  },
  "IA47": {
      "icao": "IA47",
      "iata": "",
      "name": "Day Field",
      "city": "Grimes",
      "state": "Iowa",
      "country": "US",
      "elevation": 980,
      "lat": 41.6402015686,
      "lon": -93.8087997437,
      "tz": "America\/Chicago"
  },
  "IA48": {
      "icao": "IA48",
      "iata": "",
      "name": "Kern Field",
      "city": "Polk City",
      "state": "Iowa",
      "country": "US",
      "elevation": 900,
      "lat": 41.7416992188,
      "lon": -93.7251968384,
      "tz": "America\/Chicago"
  },
  "IA49": {
      "icao": "IA49",
      "iata": "",
      "name": "Jirak Airport",
      "city": "Fort Atkinson",
      "state": "Iowa",
      "country": "US",
      "elevation": 1180,
      "lat": 43.1450004578,
      "lon": -92.0534973145,
      "tz": "America\/Chicago"
  },
  "IA50": {
      "icao": "IA50",
      "iata": "",
      "name": "Sigourney Iowa Airport",
      "city": "Sigourney",
      "state": "Iowa",
      "country": "US",
      "elevation": 697,
      "lat": 41.2907981873,
      "lon": -92.18800354,
      "tz": "America\/Chicago"
  },
  "IA51": {
      "icao": "IA51",
      "iata": "",
      "name": "De Soto Airport",
      "city": "De Soto",
      "state": "Iowa",
      "country": "US",
      "elevation": 1000,
      "lat": 41.5416984558,
      "lon": -94.0085983276,
      "tz": "America\/Chicago"
  },
  "IA52": {
      "icao": "IA52",
      "iata": "",
      "name": "Freedom Field",
      "city": "Estherville",
      "state": "Iowa",
      "country": "US",
      "elevation": 1350,
      "lat": 43.4430007935,
      "lon": -94.8479995728,
      "tz": "America\/Chicago"
  },
  "IA54": {
      "icao": "IA54",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Dubuque",
      "state": "Iowa",
      "country": "US",
      "elevation": 1135,
      "lat": 42.5022010803,
      "lon": -90.8432006836,
      "tz": "America\/Chicago"
  },
  "IA56": {
      "icao": "IA56",
      "iata": "",
      "name": "Farrar Airport",
      "city": "Farrar",
      "state": "Iowa",
      "country": "US",
      "elevation": 916,
      "lat": 41.7804985046,
      "lon": -93.3794021606,
      "tz": "America\/Chicago"
  },
  "IA58": {
      "icao": "IA58",
      "iata": "",
      "name": "B-8 Farms Airport",
      "city": "Whiting",
      "state": "Iowa",
      "country": "US",
      "elevation": 1063,
      "lat": 42.1150016785,
      "lon": -96.1719970703,
      "tz": "America\/Chicago"
  },
  "IA59": {
      "icao": "IA59",
      "iata": "",
      "name": "Bickel STOLport",
      "city": "Keokuk",
      "state": "Iowa",
      "country": "US",
      "elevation": 680,
      "lat": 40.3917007446,
      "lon": -91.4168014526,
      "tz": "America\/Chicago"
  },
  "IA61": {
      "icao": "IA61",
      "iata": "",
      "name": "Estle Field",
      "city": "Fairfield",
      "state": "Iowa",
      "country": "US",
      "elevation": 800,
      "lat": 41.0802993774,
      "lon": -91.9466018677,
      "tz": "America\/Chicago"
  },
  "IA62": {
      "icao": "IA62",
      "iata": "",
      "name": "Wheatley Farms Airport",
      "city": "Elkhorn",
      "state": "Iowa",
      "country": "US",
      "elevation": 1310,
      "lat": 41.5332984924,
      "lon": -95.075302124,
      "tz": "America\/Chicago"
  },
  "IA63": {
      "icao": "IA63",
      "iata": "",
      "name": "Edgren Airport",
      "city": "Oskaloosa",
      "state": "Iowa",
      "country": "US",
      "elevation": 840,
      "lat": 41.2583007813,
      "lon": -92.5515975952,
      "tz": "America\/Chicago"
  },
  "IA65": {
      "icao": "IA65",
      "iata": "",
      "name": "Donnellson Airport",
      "city": "Donnellson",
      "state": "Iowa",
      "country": "US",
      "elevation": 700,
      "lat": 40.6208992004,
      "lon": -91.5418014526,
      "tz": "America\/Chicago"
  },
  "IA66": {
      "icao": "IA66",
      "iata": "",
      "name": "Nash Field Indianola Airport",
      "city": "Indianola",
      "state": "Iowa",
      "country": "US",
      "elevation": 991,
      "lat": 41.3039016724,
      "lon": -93.5677032471,
      "tz": "America\/Chicago"
  },
  "IA68": {
      "icao": "IA68",
      "iata": "",
      "name": "Freedom Field",
      "city": "La Motte",
      "state": "Iowa",
      "country": "US",
      "elevation": 1055,
      "lat": 42.2667007446,
      "lon": -90.6007003784,
      "tz": "America\/Chicago"
  },
  "IA69": {
      "icao": "IA69",
      "iata": "",
      "name": "Sand Field Airport",
      "city": "Marcus",
      "state": "Iowa",
      "country": "US",
      "elevation": 1520,
      "lat": 42.8736000061,
      "lon": -95.8031005859,
      "tz": "America\/Chicago"
  },
  "IA70": {
      "icao": "IA70",
      "iata": "",
      "name": "Friesenborg & Larson Airport",
      "city": "Buffalo Center",
      "state": "Iowa",
      "country": "US",
      "elevation": 1220,
      "lat": 43.4258003235,
      "lon": -93.8358001709,
      "tz": "America\/Chicago"
  },
  "IA76": {
      "icao": "IA76",
      "iata": "",
      "name": "Cobb Farm Airport",
      "city": "Corydon",
      "state": "Iowa",
      "country": "US",
      "elevation": 1100,
      "lat": 40.728099823,
      "lon": -93.3432998657,
      "tz": "America\/Chicago"
  },
  "IA77": {
      "icao": "IA77",
      "iata": "",
      "name": "Keitzer Field",
      "city": "Mediapolis",
      "state": "Iowa",
      "country": "US",
      "elevation": 755,
      "lat": 41.0264015198,
      "lon": -91.1417999268,
      "tz": "America\/Chicago"
  },
  "IA80": {
      "icao": "IA80",
      "iata": "",
      "name": "Dyersville Area Airport",
      "city": "Dyersville",
      "state": "Iowa",
      "country": "US",
      "elevation": 980,
      "lat": 42.4961013794,
      "lon": -91.179901123,
      "tz": "America\/Chicago"
  },
  "IA83": {
      "icao": "IA83",
      "iata": "",
      "name": "Kohlhaas Airport",
      "city": "Livermore",
      "state": "Iowa",
      "country": "US",
      "elevation": 1153,
      "lat": 42.9538993835,
      "lon": -94.1677017212,
      "tz": "America\/Chicago"
  },
  "IA84": {
      "icao": "IA84",
      "iata": "",
      "name": "Lawton Airport",
      "city": "Lawton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1235,
      "lat": 42.4832992554,
      "lon": -96.200302124,
      "tz": "America\/Chicago"
  },
  "IA85": {
      "icao": "IA85",
      "iata": "",
      "name": "Tuinstra Airfield",
      "city": "Indianola",
      "state": "Iowa",
      "country": "US",
      "elevation": 900,
      "lat": 41.4351005554,
      "lon": -93.5404968262,
      "tz": "America\/Chicago"
  },
  "IA86": {
      "icao": "IA86",
      "iata": "",
      "name": "Hedgewood Landing Airport",
      "city": "Indianola",
      "state": "Iowa",
      "country": "US",
      "elevation": 990,
      "lat": 41.2667007446,
      "lon": -93.5093994141,
      "tz": "America\/Chicago"
  },
  "IA87": {
      "icao": "IA87",
      "iata": "",
      "name": "Robel Field",
      "city": "Grimes",
      "state": "Iowa",
      "country": "US",
      "elevation": 965,
      "lat": 41.6575012207,
      "lon": -93.8210983276,
      "tz": "America\/Chicago"
  },
  "IA93": {
      "icao": "IA93",
      "iata": "",
      "name": "Olsen Airport",
      "city": "Missouri Valley",
      "state": "Iowa",
      "country": "US",
      "elevation": 1030,
      "lat": 41.6128005981,
      "lon": -95.8955993652,
      "tz": "America\/Chicago"
  },
  "IA94": {
      "icao": "IA94",
      "iata": "",
      "name": "Nesler Field",
      "city": "Moorland",
      "state": "Iowa",
      "country": "US",
      "elevation": 1147,
      "lat": 42.4333000183,
      "lon": -94.2835998535,
      "tz": "America\/Chicago"
  },
  "IA97": {
      "icao": "IA97",
      "iata": "",
      "name": "Nichols Airport",
      "city": "La Porte City",
      "state": "Iowa",
      "country": "US",
      "elevation": 950,
      "lat": 42.3166999817,
      "lon": -92.2835006714,
      "tz": "America\/Chicago"
  },
  "ID00": {
      "icao": "ID00",
      "iata": "",
      "name": "Hubler Field",
      "city": "Caldwell",
      "state": "Idaho",
      "country": "US",
      "elevation": 2385,
      "lat": 43.6957015991,
      "lon": -116.6380004883,
      "tz": "America\/Boise"
  },
  "ID01": {
      "icao": "ID01",
      "iata": "",
      "name": "Cx Ranch Airport",
      "city": "Clark Fork",
      "state": "Idaho",
      "country": "US",
      "elevation": 2071,
      "lat": 48.1380004883,
      "lon": -116.1979980469,
      "tz": "America\/Los_Angeles"
  },
  "ID04": {
      "icao": "ID04",
      "iata": "",
      "name": "J-Lazy-M Ranch Airport",
      "city": "Fish Haven",
      "state": "Idaho",
      "country": "US",
      "elevation": 6190,
      "lat": 42.0290985107,
      "lon": -111.422996521,
      "tz": "America\/Boise"
  },
  "ID05": {
      "icao": "ID05",
      "iata": "",
      "name": "Hackney Airpark",
      "city": "Athol",
      "state": "Idaho",
      "country": "US",
      "elevation": 2445,
      "lat": 47.9567985535,
      "lon": -116.6770019531,
      "tz": "America\/Los_Angeles"
  },
  "ID06": {
      "icao": "ID06",
      "iata": "",
      "name": "Ranch Aero Airport",
      "city": "Hayden Lake",
      "state": "Idaho",
      "country": "US",
      "elevation": 2315,
      "lat": 47.8288002014,
      "lon": -116.783996582,
      "tz": "America\/Los_Angeles"
  },
  "ID07": {
      "icao": "ID07",
      "iata": "",
      "name": "Nichols Ranch Airport",
      "city": "Post Falls",
      "state": "Idaho",
      "country": "US",
      "elevation": 2430,
      "lat": 47.6781005859,
      "lon": -117.0250015259,
      "tz": "America\/Los_Angeles"
  },
  "ID09": {
      "icao": "ID09",
      "iata": "",
      "name": "Otterson Ranch Airport",
      "city": "Post Falls",
      "state": "Idaho",
      "country": "US",
      "elevation": 2150,
      "lat": 47.7402000427,
      "lon": -116.9980010986,
      "tz": "America\/Los_Angeles"
  },
  "ID10": {
      "icao": "ID10",
      "iata": "",
      "name": "Anderson-Plummer Airport",
      "city": "Potlatch",
      "state": "Idaho",
      "country": "US",
      "elevation": 2580,
      "lat": 46.9243011475,
      "lon": -116.9580001831,
      "tz": "America\/Los_Angeles"
  },
  "ID11": {
      "icao": "ID11",
      "iata": "",
      "name": "Wood Brothers Ranch Airport",
      "city": "Reubens",
      "state": "Idaho",
      "country": "US",
      "elevation": 3400,
      "lat": 46.3820991516,
      "lon": -116.5130004883,
      "tz": "America\/Los_Angeles"
  },
  "ID12": {
      "icao": "ID12",
      "iata": "",
      "name": "Russell W Anderson Strip",
      "city": "Riverside",
      "state": "Idaho",
      "country": "US",
      "elevation": 4450,
      "lat": 43.1843986511,
      "lon": -112.466003418,
      "tz": "America\/Boise"
  },
  "ID13": {
      "icao": "ID13",
      "iata": "",
      "name": "Sky Island Ranch Airport",
      "city": "St Maries",
      "state": "Idaho",
      "country": "US",
      "elevation": 2880,
      "lat": 47.322101593,
      "lon": -116.6399993896,
      "tz": "America\/Los_Angeles"
  },
  "ID16": {
      "icao": "ID16",
      "iata": "",
      "name": "Sluder Airstrip",
      "city": "Bellevue",
      "state": "Idaho",
      "country": "US",
      "elevation": 5017,
      "lat": 43.4034996033,
      "lon": -114.2730026245,
      "tz": "America\/Boise"
  },
  "ID17": {
      "icao": "ID17",
      "iata": "",
      "name": "Seven Devils Airport",
      "city": "Council",
      "state": "Idaho",
      "country": "US",
      "elevation": 4487,
      "lat": 45.0110015869,
      "lon": -116.6890029907,
      "tz": "America\/Boise"
  },
  "ID19": {
      "icao": "ID19",
      "iata": "",
      "name": "Bird Nr 2 Airport",
      "city": "Sandpoint",
      "state": "Idaho",
      "country": "US",
      "elevation": 2192,
      "lat": 48.2309989929,
      "lon": -116.388999939,
      "tz": "America\/Los_Angeles"
  },
  "ID21": {
      "icao": "ID21",
      "iata": "",
      "name": "Smith Ranch Airport",
      "city": "Hauser Lake",
      "state": "Idaho",
      "country": "US",
      "elevation": 2370,
      "lat": 47.7563018799,
      "lon": -117.0240020752,
      "tz": "America\/Los_Angeles"
  },
  "ID22": {
      "icao": "ID22",
      "iata": "",
      "name": "Treeport Airport",
      "city": "Spirit Lake",
      "state": "Idaho",
      "country": "US",
      "elevation": 2500,
      "lat": 47.9780006409,
      "lon": -116.7929992676,
      "tz": "America\/Los_Angeles"
  },
  "ID23": {
      "icao": "ID23",
      "iata": "",
      "name": "Rock Creek Farm Airport",
      "city": "Worley",
      "state": "Idaho",
      "country": "US",
      "elevation": 2500,
      "lat": 47.3923988342,
      "lon": -116.8730010986,
      "tz": "America\/Los_Angeles"
  },
  "ID24": {
      "icao": "ID24",
      "iata": "",
      "name": "Timber Basin Airpark Inc Airport",
      "city": "Sagle",
      "state": "Idaho",
      "country": "US",
      "elevation": 2220,
      "lat": 48.2234992981,
      "lon": -116.4390029907,
      "tz": "America\/Los_Angeles"
  },
  "ID25": {
      "icao": "ID25",
      "iata": "",
      "name": "Olmstead Sky Ranch Airport",
      "city": "Sandpoint",
      "state": "Idaho",
      "country": "US",
      "elevation": 2140,
      "lat": 48.3507995605,
      "lon": -116.5540008545,
      "tz": "America\/Los_Angeles"
  },
  "ID26": {
      "icao": "ID26",
      "iata": "",
      "name": "P And R Field",
      "city": "Mayfield",
      "state": "Idaho",
      "country": "US",
      "elevation": 3125,
      "lat": 43.224167,
      "lon": -115.966944,
      "tz": "America\/Boise"
  },
  "ID27": {
      "icao": "ID27",
      "iata": "",
      "name": "Hawk Haven Airport",
      "city": "Coeur d Alene",
      "state": "Idaho",
      "country": "US",
      "elevation": 2333,
      "lat": 47.75550079,
      "lon": -116.8590012,
      "tz": "America\/Los_Angeles"
  },
  "ID28": {
      "icao": "ID28",
      "iata": "",
      "name": "Mackay Bar Airport",
      "city": "Dixie",
      "state": "Idaho",
      "country": "US",
      "elevation": 2172,
      "lat": 45.3791007996,
      "lon": -115.5049972534,
      "tz": "America\/Boise"
  },
  "ID29": {
      "icao": "ID29",
      "iata": "",
      "name": "Big Island Airport",
      "city": "Orofino",
      "state": "Idaho",
      "country": "US",
      "elevation": 2249,
      "lat": 46.6973991394,
      "lon": -115.9840011597,
      "tz": "America\/Los_Angeles"
  },
  "ID32": {
      "icao": "ID32",
      "iata": "",
      "name": "Tuka STOLport",
      "city": "Clark Fork",
      "state": "Idaho",
      "country": "US",
      "elevation": 2086,
      "lat": 48.1054992676,
      "lon": -116.1370010376,
      "tz": "America\/Los_Angeles"
  },
  "ID33": {
      "icao": "ID33",
      "iata": "",
      "name": "Stocking Meadows Airport",
      "city": "Clarkia",
      "state": "Idaho",
      "country": "US",
      "elevation": 3850,
      "lat": 46.936000824,
      "lon": -115.8649978638,
      "tz": "America\/Los_Angeles"
  },
  "ID34": {
      "icao": "ID34",
      "iata": "",
      "name": "Granite Airport",
      "city": "Athol",
      "state": "Idaho",
      "country": "US",
      "elevation": 2260,
      "lat": 47.9846000671,
      "lon": -116.6829986572,
      "tz": "America\/Los_Angeles"
  },
  "ID35": {
      "icao": "ID35",
      "iata": "",
      "name": "High Valley Swanson Airport",
      "city": "Cascade",
      "state": "Idaho",
      "country": "US",
      "elevation": 4883,
      "lat": 44.2391014099,
      "lon": -116.1429977417,
      "tz": "America\/Boise"
  },
  "ID36": {
      "icao": "ID36",
      "iata": "",
      "name": "King Mountain Glider Park",
      "city": "Moore",
      "state": "Idaho",
      "country": "US",
      "elevation": 5500,
      "lat": 43.7630556,
      "lon": -113.3438889,
      "tz": "America\/Boise"
  },
  "ID39": {
      "icao": "ID39",
      "iata": "",
      "name": "Owen Ranches Inc Airport",
      "city": "Bruneau",
      "state": "Idaho",
      "country": "US",
      "elevation": 2620,
      "lat": 42.7957000732,
      "lon": -115.7340011597,
      "tz": "America\/Boise"
  },
  "ID40": {
      "icao": "ID40",
      "iata": "",
      "name": "Sunrise Skypark Airport",
      "city": "Marsing",
      "state": "Idaho",
      "country": "US",
      "elevation": 2240,
      "lat": 43.4178009033,
      "lon": -116.7060012817,
      "tz": "America\/Boise"
  },
  "ID41": {
      "icao": "ID41",
      "iata": "",
      "name": "Stibnite Airport",
      "city": "Yellow Pine",
      "state": "Idaho",
      "country": "US",
      "elevation": 6539,
      "lat": 44.8998985291,
      "lon": -115.3330001831,
      "tz": "America\/Boise"
  },
  "ID43": {
      "icao": "ID43",
      "iata": "",
      "name": "Carlin Bay Airport",
      "city": "Coeur D'Alene",
      "state": "Idaho",
      "country": "US",
      "elevation": 2702,
      "lat": 47.5513000488,
      "lon": -116.763999939,
      "tz": "America\/Los_Angeles"
  },
  "ID44": {
      "icao": "ID44",
      "iata": "",
      "name": "Hidden Lakes Airport",
      "city": "Cascade",
      "state": "Idaho",
      "country": "US",
      "elevation": 4845,
      "lat": 44.224899292,
      "lon": -116.1800003052,
      "tz": "America\/Boise"
  },
  "ID48": {
      "icao": "ID48",
      "iata": "",
      "name": "Western Spur Airport",
      "city": "Athol",
      "state": "Idaho",
      "country": "US",
      "elevation": 2350,
      "lat": 47.9287986755,
      "lon": -116.7109985352,
      "tz": "America\/Los_Angeles"
  },
  "ID50": {
      "icao": "ID50",
      "iata": "",
      "name": "Q.B. One Airport",
      "city": "Idaho Falls",
      "state": "Idaho",
      "country": "US",
      "elevation": 4875,
      "lat": 43.6012992859,
      "lon": -112.2429962158,
      "tz": "America\/Boise"
  },
  "ID52": {
      "icao": "ID52",
      "iata": "",
      "name": "Bowman Field",
      "city": "Chester",
      "state": "Idaho",
      "country": "US",
      "elevation": 5100,
      "lat": 43.9930000305,
      "lon": -111.5510025024,
      "tz": "America\/Boise"
  },
  "ID56": {
      "icao": "ID56",
      "iata": "",
      "name": "Star 's' Ranch Airport",
      "city": "Mackay",
      "state": "Idaho",
      "country": "US",
      "elevation": 6660,
      "lat": 43.9818992615,
      "lon": -114.0439987183,
      "tz": "America\/Boise"
  },
  "ID59": {
      "icao": "ID59",
      "iata": "",
      "name": "Flying A Ranch Airport",
      "city": "Lake Fork",
      "state": "Idaho",
      "country": "US",
      "elevation": 4918,
      "lat": 44.8176002502,
      "lon": -116.0680007935,
      "tz": "America\/Boise"
  },
  "ID60": {
      "icao": "ID60",
      "iata": "",
      "name": "Fountains Airport",
      "city": "Moscow",
      "state": "Idaho",
      "country": "US",
      "elevation": 2550,
      "lat": 46.7131996155,
      "lon": -116.9970016479,
      "tz": "America\/Los_Angeles"
  },
  "ID61": {
      "icao": "ID61",
      "iata": "",
      "name": "Valenov Ranch Airport",
      "city": "Priest River",
      "state": "Idaho",
      "country": "US",
      "elevation": 2425,
      "lat": 48.2966003418,
      "lon": -117.0149993896,
      "tz": "America\/Los_Angeles"
  },
  "ID62": {
      "icao": "ID62",
      "iata": "",
      "name": "Simpson Airport",
      "city": "Grace",
      "state": "Idaho",
      "country": "US",
      "elevation": 5598,
      "lat": 42.6082992554,
      "lon": -111.7289962769,
      "tz": "America\/Boise"
  },
  "ID63": {
      "icao": "ID63",
      "iata": "",
      "name": "Richards Airport",
      "city": "Homedale",
      "state": "Idaho",
      "country": "US",
      "elevation": 2290,
      "lat": 43.6710014343,
      "lon": -117.0250015259,
      "tz": "America\/Boise"
  },
  "ID65": {
      "icao": "ID65",
      "iata": "",
      "name": "Pisch's Place Airport",
      "city": "Coeur D'Alene",
      "state": "Idaho",
      "country": "US",
      "elevation": 2775,
      "lat": 47.5810012817,
      "lon": -116.7720031738,
      "tz": "America\/Los_Angeles"
  },
  "ID67": {
      "icao": "ID67",
      "iata": "",
      "name": "Lower Loon Creek Airport",
      "city": "Challis",
      "state": "Idaho",
      "country": "US",
      "elevation": 4200,
      "lat": 44.8082008362,
      "lon": -114.8089981079,
      "tz": "America\/Boise"
  },
  "ID68": {
      "icao": "ID68",
      "iata": "",
      "name": "Green Acres Airport",
      "city": "Kuna",
      "state": "Idaho",
      "country": "US",
      "elevation": 2865,
      "lat": 43.4846000671,
      "lon": -116.4589996338,
      "tz": "America\/Boise"
  },
  "ID72": {
      "icao": "ID72",
      "iata": "",
      "name": "Huskey Airport",
      "city": "Irwin",
      "state": "Idaho",
      "country": "US",
      "elevation": 5200,
      "lat": 43.4174003601,
      "lon": -111.2850036621,
      "tz": "America\/Boise"
  },
  "ID74": {
      "icao": "ID74",
      "iata": "",
      "name": "Sulphur Creek Ranch Airport",
      "city": "Cascade",
      "state": "Idaho",
      "country": "US",
      "elevation": 5835,
      "lat": 44.5365982056,
      "lon": -115.3509979248,
      "tz": "America\/Boise"
  },
  "ID75": {
      "icao": "ID75",
      "iata": "",
      "name": "Riverlake Airport",
      "city": "Clark Fork",
      "state": "Idaho",
      "country": "US",
      "elevation": 2076,
      "lat": 48.1259994507,
      "lon": -116.1650009155,
      "tz": "America\/Los_Angeles"
  },
  "ID76": {
      "icao": "ID76",
      "iata": "",
      "name": "Wilson Bar US Forest Service Airport",
      "city": "Dixie",
      "state": "Idaho",
      "country": "US",
      "elevation": 2250,
      "lat": 45.3967018127,
      "lon": -115.483001709,
      "tz": "America\/Boise"
  },
  "ID77": {
      "icao": "ID77",
      "iata": "",
      "name": "Cuddy Meadows Airport",
      "city": "Cambridge",
      "state": "Idaho",
      "country": "US",
      "elevation": 4580,
      "lat": 44.7052993774,
      "lon": -116.8069992065,
      "tz": "America\/Boise"
  },
  "ID78": {
      "icao": "ID78",
      "iata": "",
      "name": "Cx Ranch Nr 2 Airport",
      "city": "Clark Fork",
      "state": "Idaho",
      "country": "US",
      "elevation": 2070,
      "lat": 48.1416015625,
      "lon": -116.1819992065,
      "tz": "America\/Los_Angeles"
  },
  "ID79": {
      "icao": "ID79",
      "iata": "",
      "name": "Sky Ranch South Airport",
      "city": "Nampa",
      "state": "Idaho",
      "country": "US",
      "elevation": 2750,
      "lat": 43.5083007812,
      "lon": -116.667999268,
      "tz": "America\/Boise"
  },
  "ID82": {
      "icao": "ID82",
      "iata": "",
      "name": "Picabo Airport",
      "city": "Picabo",
      "state": "Idaho",
      "country": "US",
      "elevation": 4828,
      "lat": 43.3082008362,
      "lon": -114.06300354,
      "tz": "America\/Boise"
  },
  "ID84": {
      "icao": "ID84",
      "iata": "",
      "name": "Cptpa Headquarters Airport",
      "city": "Headquarters",
      "state": "Idaho",
      "country": "US",
      "elevation": 3314,
      "lat": 46.617099762,
      "lon": -115.8000030518,
      "tz": "America\/Los_Angeles"
  },
  "ID85": {
      "icao": "ID85",
      "iata": "",
      "name": "Elk River Airport",
      "city": "Elk River",
      "state": "Idaho",
      "country": "US",
      "elevation": 2827,
      "lat": 46.787399292,
      "lon": -116.1679992676,
      "tz": "America\/Los_Angeles"
  },
  "ID86": {
      "icao": "ID86",
      "iata": "",
      "name": "Deadwood Dam Airstrip",
      "city": "Cascade",
      "state": "Idaho",
      "country": "US",
      "elevation": 5489,
      "lat": 44.2977981567,
      "lon": -115.6409988403,
      "tz": "America\/Boise"
  },
  "ID87": {
      "icao": "ID87",
      "iata": "",
      "name": "Rainbow Ranch Airport",
      "city": "Idaho Falls",
      "state": "Idaho",
      "country": "US",
      "elevation": 4750,
      "lat": 43.4068984985,
      "lon": -111.9739990234,
      "tz": "America\/Boise"
  },
  "ID88": {
      "icao": "ID88",
      "iata": "",
      "name": "Tracy Ranch Airport",
      "city": "Hill City",
      "state": "Idaho",
      "country": "US",
      "elevation": 5071,
      "lat": 43.287399292,
      "lon": -115.0800018311,
      "tz": "America\/Boise"
  },
  "ID90": {
      "icao": "ID90",
      "iata": "",
      "name": "Spencer Ranch Landing Strip",
      "city": "Keuterville",
      "state": "Idaho",
      "country": "US",
      "elevation": 4284,
      "lat": 45.8387985229,
      "lon": -116.658996582,
      "tz": "America\/Boise"
  },
  "ID92": {
      "icao": "ID92",
      "iata": "",
      "name": "Snake River Skydiving Airport",
      "city": "Star",
      "state": "Idaho",
      "country": "US",
      "elevation": 2550,
      "lat": 43.7288017273,
      "lon": -116.5230026245,
      "tz": "America\/Boise"
  },
  "ID93": {
      "icao": "ID93",
      "iata": "",
      "name": "Reed Ranch Airport",
      "city": "Yellow Pine",
      "state": "Idaho",
      "country": "US",
      "elevation": 4153,
      "lat": 44.8942985535,
      "lon": -115.7129974365,
      "tz": "America\/Boise"
  },
  "ID94": {
      "icao": "ID94",
      "iata": "",
      "name": "Friendly Persuasion Farm Airport",
      "city": "Troy",
      "state": "Idaho",
      "country": "US",
      "elevation": 2860,
      "lat": 46.7766990662,
      "lon": -116.6910018921,
      "tz": "America\/Los_Angeles"
  },
  "ID95": {
      "icao": "ID95",
      "iata": "",
      "name": "Loomis Airport",
      "city": "Horseshoe Bend",
      "state": "Idaho",
      "country": "US",
      "elevation": 4180,
      "lat": 43.861000061,
      "lon": -116.2409973145,
      "tz": "America\/Boise"
  },
  "ID96": {
      "icao": "ID96",
      "iata": "",
      "name": "Bear Air Airport",
      "city": "Donnelly",
      "state": "Idaho",
      "country": "US",
      "elevation": 4904,
      "lat": 44.7851982117,
      "lon": -116.0670013428,
      "tz": "America\/Boise"
  },
  "ID97": {
      "icao": "ID97",
      "iata": "",
      "name": "Hangman Creek Ranch Airport",
      "city": "De Smet",
      "state": "Idaho",
      "country": "US",
      "elevation": 2635,
      "lat": 47.1071014404,
      "lon": -116.8109970093,
      "tz": "America\/Los_Angeles"
  },
  "IG02": {
      "icao": "IG02",
      "iata": "",
      "name": "Ratcliff Airport",
      "city": "Lafayette",
      "state": "Indiana",
      "country": "US",
      "elevation": 730,
      "lat": 40.2731018066,
      "lon": -86.9075012207,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IG03": {
      "icao": "IG03",
      "iata": "",
      "name": "Bluebird Airport",
      "city": "Paragon",
      "state": "Indiana",
      "country": "US",
      "elevation": 580,
      "lat": 39.3917007446,
      "lon": -86.5999984741,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IG04": {
      "icao": "IG04",
      "iata": "",
      "name": "Litzinger Ultralightport",
      "city": "Versailles",
      "state": "Indiana",
      "country": "US",
      "elevation": 970,
      "lat": 39.0461006165,
      "lon": -85.1567001343,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IG05": {
      "icao": "IG05",
      "iata": "",
      "name": "Wheeler Airport",
      "city": "Knox",
      "state": "Indiana",
      "country": "US",
      "elevation": 720,
      "lat": 41.189763,
      "lon": -86.606956,
      "tz": "America\/Indiana\/Knox"
  },
  "IG07": {
      "icao": "IG07",
      "iata": "",
      "name": "Josephs Field",
      "city": "Moores Hill",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 39.0569000244,
      "lon": -85.0355987549,
      "tz": "America\/New_York"
  },
  "II01": {
      "icao": "II01",
      "iata": "",
      "name": "Grandlienard-Hogg Airport",
      "city": "Bluffton",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 40.7561988831,
      "lon": -85.25050354,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II02": {
      "icao": "II02",
      "iata": "",
      "name": "Kline Field",
      "city": "Butler",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 41.3856010437,
      "lon": -84.8268966675,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II03": {
      "icao": "II03",
      "iata": "",
      "name": "Buell Airport",
      "city": "Milan",
      "state": "Indiana",
      "country": "US",
      "elevation": 990,
      "lat": 39.198600769,
      "lon": -85.1363983154,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II04": {
      "icao": "II04",
      "iata": "",
      "name": "North West Indiana Air Airport",
      "city": "Brook",
      "state": "Indiana",
      "country": "US",
      "elevation": 660,
      "lat": 40.8838996887,
      "lon": -87.3692016602,
      "tz": "America\/Chicago"
  },
  "II05": {
      "icao": "II05",
      "iata": "",
      "name": "Woodcock Airport",
      "city": "Akron",
      "state": "Indiana",
      "country": "US",
      "elevation": 858,
      "lat": 41.0741996765,
      "lon": -86.064201355,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II06": {
      "icao": "II06",
      "iata": "",
      "name": "Ferrell Airport",
      "city": "Cadiz",
      "state": "Indiana",
      "country": "US",
      "elevation": 1070,
      "lat": 39.9700012207,
      "lon": -85.4708023071,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II07": {
      "icao": "II07",
      "iata": "",
      "name": "Clover Knoll Airport",
      "city": "Cloverdale",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 39.5322990417,
      "lon": -86.8321990967,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II08": {
      "icao": "II08",
      "iata": "",
      "name": "Rheude Airport",
      "city": "Earl Park",
      "state": "Indiana",
      "country": "US",
      "elevation": 745,
      "lat": 40.660900116,
      "lon": -87.4396972656,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II09": {
      "icao": "II09",
      "iata": "",
      "name": "Patrum Field",
      "city": "Eminence",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 39.5736999512,
      "lon": -86.6181030273,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II10": {
      "icao": "II10",
      "iata": "",
      "name": "Rockey's Air Strip",
      "city": "Galveston",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 40.5820007324,
      "lon": -86.1675033569,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II11": {
      "icao": "II11",
      "iata": "",
      "name": "Pelz Port Airport",
      "city": "Grabill",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 41.2106018066,
      "lon": -84.9860992432,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II12": {
      "icao": "II12",
      "iata": "",
      "name": "Dague Strip",
      "city": "Grass Creek",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 40.9645004272,
      "lon": -86.3719024658,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II13": {
      "icao": "II13",
      "iata": "",
      "name": "Sugar Creek Air Park",
      "city": "Markleville",
      "state": "Indiana",
      "country": "US",
      "elevation": 1000,
      "lat": 39.920278,
      "lon": -85.598611,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II14": {
      "icao": "II14",
      "iata": "",
      "name": "Beck Private Airport",
      "city": "Huntington",
      "state": "Indiana",
      "country": "US",
      "elevation": 840,
      "lat": 40.9612007141,
      "lon": -85.5625,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II15": {
      "icao": "II15",
      "iata": "",
      "name": "Friedrich Airport",
      "city": "Kewanna",
      "state": "Indiana",
      "country": "US",
      "elevation": 770,
      "lat": 40.9936981201,
      "lon": -86.3416976929,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II16": {
      "icao": "II16",
      "iata": "",
      "name": "Pigeon Airport",
      "city": "Angola",
      "state": "Indiana",
      "country": "US",
      "elevation": 1000,
      "lat": 41.6370010376,
      "lon": -84.9494018555,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II17": {
      "icao": "II17",
      "iata": "",
      "name": "Bickel's Cow Patch Airport",
      "city": "Knox",
      "state": "Indiana",
      "country": "US",
      "elevation": 720,
      "lat": 41.2681007385,
      "lon": -86.5595016479,
      "tz": "America\/Indiana\/Knox"
  },
  "II18": {
      "icao": "II18",
      "iata": "",
      "name": "Lou Abbett Farms Airport",
      "city": "La Crosse",
      "state": "Indiana",
      "country": "US",
      "elevation": 685,
      "lat": 41.3356018066,
      "lon": -86.9285964966,
      "tz": "America\/Chicago"
  },
  "II19": {
      "icao": "II19",
      "iata": "",
      "name": "Etter Airport",
      "city": "Lafayette",
      "state": "Indiana",
      "country": "US",
      "elevation": 660,
      "lat": 40.4061012268,
      "lon": -86.7536010742,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II20": {
      "icao": "II20",
      "iata": "",
      "name": "Mossburg Airport",
      "city": "Liberty Center",
      "state": "Indiana",
      "country": "US",
      "elevation": 845,
      "lat": 40.6736984253,
      "lon": -85.2632980347,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II21": {
      "icao": "II21",
      "iata": "",
      "name": "Zollinger Strip",
      "city": "Ligonier",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 41.4752998352,
      "lon": -85.6947021484,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II22": {
      "icao": "II22",
      "iata": "",
      "name": "Antonian Airport",
      "city": "Medaryville",
      "state": "Indiana",
      "country": "US",
      "elevation": 695,
      "lat": 41.1020011902,
      "lon": -86.9321975708,
      "tz": "America\/Chicago"
  },
  "II25": {
      "icao": "II25",
      "iata": "",
      "name": "Timber Trails Airport",
      "city": "Spencer",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 39.3372993469,
      "lon": -86.7797012329,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II26": {
      "icao": "II26",
      "iata": "",
      "name": "Ashby Airport",
      "city": "Remington",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 40.7038993835,
      "lon": -87.1877975464,
      "tz": "America\/Chicago"
  },
  "II27": {
      "icao": "II27",
      "iata": "",
      "name": "Ward Airport",
      "city": "Arcadia",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 40.1666984558,
      "lon": -85.9408035278,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II28": {
      "icao": "II28",
      "iata": "",
      "name": "Stettler Strip",
      "city": "Leo",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 41.2106018066,
      "lon": -85.0635986328,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II29": {
      "icao": "II29",
      "iata": "",
      "name": "Owens Field",
      "city": "Greencastle",
      "state": "Indiana",
      "country": "US",
      "elevation": 840,
      "lat": 39.6109008789,
      "lon": -86.7561035156,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II30": {
      "icao": "II30",
      "iata": "",
      "name": "Ridgway Flying Service Airport",
      "city": "Carlisle",
      "state": "Indiana",
      "country": "US",
      "elevation": 510,
      "lat": 38.9067001343,
      "lon": -87.4017028809,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II31": {
      "icao": "II31",
      "iata": "",
      "name": "Jurassic Landings Ultralightport",
      "city": "Essex",
      "state": "Illinois",
      "country": "US",
      "elevation": 585,
      "lat": 41.1879005432,
      "lon": -88.167098999,
      "tz": "America\/Chicago"
  },
  "II32": {
      "icao": "II32",
      "iata": "",
      "name": "Raceway Airport",
      "city": "Chandler",
      "state": "Indiana",
      "country": "US",
      "elevation": 400,
      "lat": 38.0491981506,
      "lon": -87.381401062,
      "tz": "America\/Chicago"
  },
  "II33": {
      "icao": "II33",
      "iata": "",
      "name": "Eickholtz Airport",
      "city": "Charleston",
      "state": "Indiana",
      "country": "US",
      "elevation": 620,
      "lat": 38.460899353,
      "lon": -87.6549987793,
      "tz": "America\/Chicago"
  },
  "II34": {
      "icao": "II34",
      "iata": "",
      "name": "Booe Airport",
      "city": "Clay City",
      "state": "Indiana",
      "country": "US",
      "elevation": 600,
      "lat": 39.2731018066,
      "lon": -87.1222000122,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II35": {
      "icao": "II35",
      "iata": "",
      "name": "Lindley Private Airport",
      "city": "Danville",
      "state": "Indiana",
      "country": "US",
      "elevation": 925,
      "lat": 39.8087005615,
      "lon": -86.4955978394,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II36": {
      "icao": "II36",
      "iata": "",
      "name": "Mc Neil Field",
      "city": "Fountaintown",
      "state": "Indiana",
      "country": "US",
      "elevation": 862,
      "lat": 39.6652984619,
      "lon": -85.8167037964,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II37": {
      "icao": "II37",
      "iata": "",
      "name": "Arthur Airport",
      "city": "Greenfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 905,
      "lat": 39.7463989258,
      "lon": -85.7746963501,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II38": {
      "icao": "II38",
      "iata": "",
      "name": "Sherk Field",
      "city": "Lapaz",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 41.426399231,
      "lon": -86.3163986206,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II39": {
      "icao": "II39",
      "iata": "",
      "name": "Hollingsworth Airport",
      "city": "Francisco",
      "state": "Indiana",
      "country": "US",
      "elevation": 480,
      "lat": 38.3362007141,
      "lon": -87.4677963257,
      "tz": "America\/Chicago"
  },
  "II40": {
      "icao": "II40",
      "iata": "",
      "name": "Hopkins Farms Airport",
      "city": "Francisco",
      "state": "Indiana",
      "country": "US",
      "elevation": 460,
      "lat": 38.3083992004,
      "lon": -87.4085998535,
      "tz": "America\/Chicago"
  },
  "II42": {
      "icao": "II42",
      "iata": "",
      "name": "Creekside Farm Airport",
      "city": "Noblesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 40.0685997009,
      "lon": -85.922203064,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II43": {
      "icao": "II43",
      "iata": "",
      "name": "C. V. Airport",
      "city": "Granger",
      "state": "Indiana",
      "country": "US",
      "elevation": 780,
      "lat": 41.75,
      "lon": -86.192199707,
      "tz": "America\/Detroit"
  },
  "II45": {
      "icao": "II45",
      "iata": "",
      "name": "Renshaw Airport",
      "city": "Hatfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 380,
      "lat": 37.8925018311,
      "lon": -87.1900024414,
      "tz": "America\/Chicago"
  },
  "II49": {
      "icao": "II49",
      "iata": "",
      "name": "Foertsch Airport",
      "city": "Lamar",
      "state": "Indiana",
      "country": "US",
      "elevation": 476,
      "lat": 38.0369987488,
      "lon": -86.9111022949,
      "tz": "America\/Chicago"
  },
  "II50": {
      "icao": "II50",
      "iata": "",
      "name": "Jack Oak Airport",
      "city": "Lewisville",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 39.48279953,
      "lon": -86.676902771,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II51": {
      "icao": "II51",
      "iata": "",
      "name": "Hull Airport",
      "city": "Ligonier",
      "state": "Indiana",
      "country": "US",
      "elevation": 930,
      "lat": 41.4847984314,
      "lon": -85.5930023193,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II52": {
      "icao": "II52",
      "iata": "",
      "name": "Haffner Airport",
      "city": "Lizton",
      "state": "Indiana",
      "country": "US",
      "elevation": 960,
      "lat": 39.8774986267,
      "lon": -86.5278015137,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II53": {
      "icao": "II53",
      "iata": "",
      "name": "Burke's Airport",
      "city": "Madison",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 38.7703018188,
      "lon": -85.484703064,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II54": {
      "icao": "II54",
      "iata": "",
      "name": "Giltner Airport",
      "city": "Madison",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 38.8158988953,
      "lon": -85.4464035034,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II55": {
      "icao": "II55",
      "iata": "",
      "name": "Hodges Airport",
      "city": "Martinsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 625,
      "lat": 39.5270004272,
      "lon": -86.3003005981,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II59": {
      "icao": "II59",
      "iata": "",
      "name": "Strip Airport",
      "city": "Newport",
      "state": "Indiana",
      "country": "US",
      "elevation": 650,
      "lat": 39.8400001526,
      "lon": -87.3936004639,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II62": {
      "icao": "II62",
      "iata": "",
      "name": "Berger Airport",
      "city": "Plymouth",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 41.3877983093,
      "lon": -86.2585983276,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II65": {
      "icao": "II65",
      "iata": "",
      "name": "Gilmore Airport",
      "city": "Rensselaer",
      "state": "Indiana",
      "country": "US",
      "elevation": 680,
      "lat": 40.8875007629,
      "lon": -87.1235961914,
      "tz": "America\/Chicago"
  },
  "II67": {
      "icao": "II67",
      "iata": "",
      "name": "Hickory Hills Airport",
      "city": "Hartford City",
      "state": "Indiana",
      "country": "US",
      "elevation": 900,
      "lat": 40.4277992249,
      "lon": -85.3734970093,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II68": {
      "icao": "II68",
      "iata": "",
      "name": "Durham Airport",
      "city": "Russellville",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 39.85499954,
      "lon": -86.959198,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II69": {
      "icao": "II69",
      "iata": "",
      "name": "Harrod\/Rose Airport",
      "city": "Scottsburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 580,
      "lat": 38.7181015015,
      "lon": -85.7602005005,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II70": {
      "icao": "II70",
      "iata": "",
      "name": "Salsbery Airport",
      "city": "Sharpsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 40.3844985962,
      "lon": -86.0633010864,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II71": {
      "icao": "II71",
      "iata": "",
      "name": "Smith Airport",
      "city": "St. Paul",
      "state": "Indiana",
      "country": "US",
      "elevation": 900,
      "lat": 39.3863983154,
      "lon": -85.6172027588,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II72": {
      "icao": "II72",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Sunman",
      "state": "Indiana",
      "country": "US",
      "elevation": 965,
      "lat": 39.1958007813,
      "lon": -85.2055969238,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II73": {
      "icao": "II73",
      "iata": "",
      "name": "Good Earth Farm Strip",
      "city": "Union City",
      "state": "Indiana",
      "country": "US",
      "elevation": 1080,
      "lat": 40.2262001038,
      "lon": -84.8349990845,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II74": {
      "icao": "II74",
      "iata": "",
      "name": "Eby Field",
      "city": "Wakarusa",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 41.5597991943,
      "lon": -86.0531005859,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II75": {
      "icao": "II75",
      "iata": "",
      "name": "Daugherty Field",
      "city": "Warren",
      "state": "Indiana",
      "country": "US",
      "elevation": 839,
      "lat": 40.6791992188,
      "lon": -85.4152984619,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II76": {
      "icao": "II76",
      "iata": "",
      "name": "Clay Hill Farms Airport",
      "city": "Medora",
      "state": "Indiana",
      "country": "US",
      "elevation": 528,
      "lat": 38.8507003784,
      "lon": -86.1368026733,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II77": {
      "icao": "II77",
      "iata": "",
      "name": "Finney's Airpark",
      "city": "Albany",
      "state": "Indiana",
      "country": "US",
      "elevation": 965,
      "lat": 40.2594985962,
      "lon": -85.2604980469,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II78": {
      "icao": "II78",
      "iata": "",
      "name": "Wallace Field",
      "city": "Wilkinson",
      "state": "Indiana",
      "country": "US",
      "elevation": 995,
      "lat": 39.8583984375,
      "lon": -85.601600647,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II79": {
      "icao": "II79",
      "iata": "",
      "name": "Sommers Airport",
      "city": "Winamac",
      "state": "Indiana",
      "country": "US",
      "elevation": 710,
      "lat": 41.0769996643,
      "lon": -86.7245025635,
      "tz": "America\/Indiana\/Winamac"
  },
  "II81": {
      "icao": "II81",
      "iata": "",
      "name": "Richardson Field",
      "city": "Winslow",
      "state": "Indiana",
      "country": "US",
      "elevation": 425,
      "lat": 38.3805999756,
      "lon": -87.2205963135,
      "tz": "America\/Indiana\/Petersburg"
  },
  "II82": {
      "icao": "II82",
      "iata": "",
      "name": "Meadors Field",
      "city": "Danville",
      "state": "Indiana",
      "country": "US",
      "elevation": 1000,
      "lat": 39.7942008972,
      "lon": -86.577796936,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II83": {
      "icao": "II83",
      "iata": "",
      "name": "Bramble Airport",
      "city": "Brownsburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 39.8342018127,
      "lon": -86.4341964722,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II84": {
      "icao": "II84",
      "iata": "",
      "name": "Hilakos Airport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 381,
      "lat": 37.9319992065,
      "lon": -87.7714004517,
      "tz": "America\/Chicago"
  },
  "II85": {
      "icao": "II85",
      "iata": "",
      "name": "Blomenberg Airport",
      "city": "Decatur",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 40.8961982727,
      "lon": -85.0491027832,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II86": {
      "icao": "II86",
      "iata": "",
      "name": "Poole Airport",
      "city": "Noblesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 40.0155982971,
      "lon": -85.8874969482,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II87": {
      "icao": "II87",
      "iata": "",
      "name": "Twelve Oaks Airport",
      "city": "Martinsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 715,
      "lat": 39.4514007568,
      "lon": -86.3283004761,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II89": {
      "icao": "II89",
      "iata": "",
      "name": "Yelverton Airport",
      "city": "St Wendel",
      "state": "Indiana",
      "country": "US",
      "elevation": 525,
      "lat": 38.0870018005,
      "lon": -87.6817016602,
      "tz": "America\/Chicago"
  },
  "II90": {
      "icao": "II90",
      "iata": "",
      "name": "Crawford Field",
      "city": "North Judson",
      "state": "Indiana",
      "country": "US",
      "elevation": 705,
      "lat": 41.199798584,
      "lon": -86.8364028931,
      "tz": "America\/Indiana\/Knox"
  },
  "II91": {
      "icao": "II91",
      "iata": "",
      "name": "Dunbar Field",
      "city": "Pittsboro",
      "state": "Indiana",
      "country": "US",
      "elevation": 940,
      "lat": 39.8438987732,
      "lon": -86.5053024292,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II93": {
      "icao": "II93",
      "iata": "",
      "name": "Bronson Airport",
      "city": "Bargersville",
      "state": "Indiana",
      "country": "US",
      "elevation": 780,
      "lat": 39.4838981628,
      "lon": -86.2268981934,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II94": {
      "icao": "II94",
      "iata": "",
      "name": "Irion Airport",
      "city": "Noblesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 40.1283988953,
      "lon": -85.886100769,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II95": {
      "icao": "II95",
      "iata": "",
      "name": "Rust's Landing Airport",
      "city": "Bourbon",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 41.3644981384,
      "lon": -86.0633010864,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II96": {
      "icao": "II96",
      "iata": "",
      "name": "Buchta Airport",
      "city": "Haysville",
      "state": "Indiana",
      "country": "US",
      "elevation": 520,
      "lat": 38.4928016663,
      "lon": -86.9330978394,
      "tz": "America\/Indiana\/Vincennes"
  },
  "II97": {
      "icao": "II97",
      "iata": "",
      "name": "Solenberg Airport",
      "city": "Shelbyville",
      "state": "Indiana",
      "country": "US",
      "elevation": 720,
      "lat": 39.4777984619,
      "lon": -85.8833007813,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II98": {
      "icao": "II98",
      "iata": "",
      "name": "Shrum Field",
      "city": "Sullivan",
      "state": "Indiana",
      "country": "US",
      "elevation": 480,
      "lat": 39.0438995361,
      "lon": -87.4219970703,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "II99": {
      "icao": "II99",
      "iata": "",
      "name": "Drake Field",
      "city": "Walkerton",
      "state": "Indiana",
      "country": "US",
      "elevation": 730,
      "lat": 41.4364013672,
      "lon": -86.4708023071,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IL01": {
      "icao": "IL01",
      "iata": "",
      "name": "Wolford's Airport",
      "city": "Abingdon",
      "state": "Illinois",
      "country": "US",
      "elevation": 720,
      "lat": 40.7924995422,
      "lon": -90.4382019043,
      "tz": "America\/Chicago"
  },
  "IL02": {
      "icao": "IL02",
      "iata": "",
      "name": "Herbert C. Maas Airport",
      "city": "Zion",
      "state": "Illinois",
      "country": "US",
      "elevation": 719,
      "lat": 42.4721984863,
      "lon": -87.9084014893,
      "tz": "America\/Chicago"
  },
  "IL05": {
      "icao": "IL05",
      "iata": "",
      "name": "Bingham Airport",
      "city": "Alden",
      "state": "Illinois",
      "country": "US",
      "elevation": 980,
      "lat": 42.4402999878,
      "lon": -88.4889984131,
      "tz": "America\/Chicago"
  },
  "IL07": {
      "icao": "IL07",
      "iata": "",
      "name": "Taylor Airport",
      "city": "Amboy",
      "state": "Illinois",
      "country": "US",
      "elevation": 760,
      "lat": 41.7235984802,
      "lon": -89.2918014526,
      "tz": "America\/Chicago"
  },
  "IL09": {
      "icao": "IL09",
      "iata": "",
      "name": "Albrecht \/2\/ Airport",
      "city": "Amboy",
      "state": "Illinois",
      "country": "US",
      "elevation": 802,
      "lat": 41.631401062,
      "lon": -89.4050979614,
      "tz": "America\/Chicago"
  },
  "IL11": {
      "icao": "IL11",
      "iata": "",
      "name": "Donald Alfred Gade Airport",
      "city": "Antioch",
      "state": "Illinois",
      "country": "US",
      "elevation": 805,
      "lat": 42.4653015137,
      "lon": -88.040397644,
      "tz": "America\/Chicago"
  },
  "IL12": {
      "icao": "IL12",
      "iata": "",
      "name": "Kinsey RLA Restricted Landing Area",
      "city": "Arenzville",
      "state": "Illinois",
      "country": "US",
      "elevation": 455,
      "lat": 39.8800010681,
      "lon": -90.4651031494,
      "tz": "America\/Chicago"
  },
  "IL13": {
      "icao": "IL13",
      "iata": "",
      "name": "Funfsinn Airport",
      "city": "Arlington",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 41.4707984924,
      "lon": -89.1808013916,
      "tz": "America\/Chicago"
  },
  "IL15": {
      "icao": "IL15",
      "iata": "",
      "name": "Runyan Aviation Airport",
      "city": "Bushnell",
      "state": "Illinois",
      "country": "US",
      "elevation": 660,
      "lat": 40.5694007874,
      "lon": -90.5486984253,
      "tz": "America\/Chicago"
  },
  "IL16": {
      "icao": "IL16",
      "iata": "",
      "name": "Houseman Airport",
      "city": "Cobden",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 37.5401000977,
      "lon": -89.1225967407,
      "tz": "America\/Chicago"
  },
  "IL18": {
      "icao": "IL18",
      "iata": "",
      "name": "Crook Restricted Landing Area",
      "city": "Columbia",
      "state": "Illinois",
      "country": "US",
      "elevation": 580,
      "lat": 38.3870010376,
      "lon": -90.1918029785,
      "tz": "America\/Chicago"
  },
  "IL22": {
      "icao": "IL22",
      "iata": "",
      "name": "Heeg Airport",
      "city": "Oquawka",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 41.0070991516,
      "lon": -90.8828964233,
      "tz": "America\/Chicago"
  },
  "IL23": {
      "icao": "IL23",
      "iata": "",
      "name": "Ellis Airport",
      "city": "Davis",
      "state": "Illinois",
      "country": "US",
      "elevation": 900,
      "lat": 42.4425010681,
      "lon": -89.4501037598,
      "tz": "America\/Chicago"
  },
  "IL24": {
      "icao": "IL24",
      "iata": "",
      "name": "Richardson Airport",
      "city": "New Berlin",
      "state": "Illinois",
      "country": "US",
      "elevation": 653,
      "lat": 39.7075004578,
      "lon": -89.883102417,
      "tz": "America\/Chicago"
  },
  "IL29": {
      "icao": "IL29",
      "iata": "",
      "name": "Von Alvens Airview Airport",
      "city": "Beecher",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 41.2999992371,
      "lon": -87.6500015259,
      "tz": "America\/Chicago"
  },
  "IL31": {
      "icao": "IL31",
      "iata": "",
      "name": "Cooch Landing Area Airport",
      "city": "Atwood",
      "state": "Illinois",
      "country": "US",
      "elevation": 695,
      "lat": 39.8432998657,
      "lon": -88.4533004761,
      "tz": "America\/Chicago"
  },
  "IL32": {
      "icao": "IL32",
      "iata": "",
      "name": "Adkisson Airport",
      "city": "Hammond",
      "state": "Illinois",
      "country": "US",
      "elevation": 669,
      "lat": 39.7832984924,
      "lon": -88.6213989258,
      "tz": "America\/Chicago"
  },
  "IL34": {
      "icao": "IL34",
      "iata": "",
      "name": "Henderson Airport",
      "city": "Belvidere",
      "state": "Illinois",
      "country": "US",
      "elevation": 815,
      "lat": 42.2145004272,
      "lon": -88.7570037842,
      "tz": "America\/Chicago"
  },
  "IL35": {
      "icao": "IL35",
      "iata": "",
      "name": "Redpath Restricted Landing Area",
      "city": "Baldwin",
      "state": "Illinois",
      "country": "US",
      "elevation": 470,
      "lat": 38.1819992065,
      "lon": -89.8084030151,
      "tz": "America\/Chicago"
  },
  "IL36": {
      "icao": "IL36",
      "iata": "",
      "name": "Bob Walberg Field",
      "city": "Belvidere",
      "state": "Illinois",
      "country": "US",
      "elevation": 840,
      "lat": 42.3208999634,
      "lon": -88.7583999634,
      "tz": "America\/Chicago"
  },
  "IL38": {
      "icao": "IL38",
      "iata": "",
      "name": "J Maddock Airport",
      "city": "Big Rock",
      "state": "Illinois",
      "country": "US",
      "elevation": 705,
      "lat": 41.766998291,
      "lon": -88.5319976807,
      "tz": "America\/Chicago"
  },
  "IL45": {
      "icao": "IL45",
      "iata": "",
      "name": "Busboom Airport",
      "city": "Gifford",
      "state": "Illinois",
      "country": "US",
      "elevation": 780,
      "lat": 40.3111000061,
      "lon": -88.015296936,
      "tz": "America\/Chicago"
  },
  "IL46": {
      "icao": "IL46",
      "iata": "",
      "name": "Brooks Ranch Airport",
      "city": "Burnside",
      "state": "Illinois",
      "country": "US",
      "elevation": 700,
      "lat": 40.4667015076,
      "lon": -91.0335006714,
      "tz": "America\/Chicago"
  },
  "IL51": {
      "icao": "IL51",
      "iata": "",
      "name": "Aero Acres Airport",
      "city": "Campus",
      "state": "Illinois",
      "country": "US",
      "elevation": 645,
      "lat": 41.0381011963,
      "lon": -88.3375015259,
      "tz": "America\/Chicago"
  },
  "IL52": {
      "icao": "IL52",
      "iata": "",
      "name": "Williams Airport",
      "city": "Carthage",
      "state": "Illinois",
      "country": "US",
      "elevation": 679,
      "lat": 40.439201355,
      "lon": -91.1439971924,
      "tz": "America\/Chicago"
  },
  "IL53": {
      "icao": "IL53",
      "iata": "",
      "name": "Lung RLA Restricted Landing Area",
      "city": "Carthage",
      "state": "Illinois",
      "country": "US",
      "elevation": 617,
      "lat": 40.4612007141,
      "lon": -91.0737991333,
      "tz": "America\/Chicago"
  },
  "IL54": {
      "icao": "IL54",
      "iata": "",
      "name": "Richmond Airport",
      "city": "Table Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 680,
      "lat": 40.3442001343,
      "lon": -90.4428024292,
      "tz": "America\/Chicago"
  },
  "IL55": {
      "icao": "IL55",
      "iata": "",
      "name": "Andrew RLA Restricted Landing Area Airport",
      "city": "Champaign",
      "state": "Illinois",
      "country": "US",
      "elevation": 810,
      "lat": 40.1603012085,
      "lon": -88.3044967651,
      "tz": "America\/Chicago"
  },
  "IL56": {
      "icao": "IL56",
      "iata": "",
      "name": "Mc Culley Airport",
      "city": "Champaign",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 40.2202987671,
      "lon": -88.2537002563,
      "tz": "America\/Chicago"
  },
  "IL57": {
      "icao": "IL57",
      "iata": "",
      "name": "Cottonwood Airport",
      "city": "Bloomington",
      "state": "Illinois",
      "country": "US",
      "elevation": 765,
      "lat": 40.422000885,
      "lon": -89.0205993652,
      "tz": "America\/Chicago"
  },
  "IL58": {
      "icao": "IL58",
      "iata": "",
      "name": "Aero Four Airport",
      "city": "Channahon",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 41.4361000061,
      "lon": -88.2487030029,
      "tz": "America\/Chicago"
  },
  "IL59": {
      "icao": "IL59",
      "iata": "",
      "name": "Chicago Glider Club Gliderport",
      "city": "Channahon",
      "state": "Illinois",
      "country": "US",
      "elevation": 590,
      "lat": 41.43199921,
      "lon": -88.24729919,
      "tz": "America\/Chicago"
  },
  "IL60": {
      "icao": "IL60",
      "iata": "",
      "name": "Chatsworth Restricted Landing Area",
      "city": "Chatsworth",
      "state": "Illinois",
      "country": "US",
      "elevation": 742,
      "lat": 40.7430992126,
      "lon": -88.2911987305,
      "tz": "America\/Chicago"
  },
  "IL63": {
      "icao": "IL63",
      "iata": "",
      "name": "Mays Aviation Airport",
      "city": "Chenoa",
      "state": "Illinois",
      "country": "US",
      "elevation": 720,
      "lat": 40.7000007629,
      "lon": -88.6751022339,
      "tz": "America\/Chicago"
  },
  "IL64": {
      "icao": "IL64",
      "iata": "",
      "name": "Martin Airport",
      "city": "Lincoln",
      "state": "Illinois",
      "country": "US",
      "elevation": 558,
      "lat": 40.1805992126,
      "lon": -89.5000991821,
      "tz": "America\/Chicago"
  },
  "IL65": {
      "icao": "IL65",
      "iata": "",
      "name": "M.A.M Trail Airport",
      "city": "Little York",
      "state": "Illinois",
      "country": "US",
      "elevation": 605,
      "lat": 41.0050010681,
      "lon": -90.7085037231,
      "tz": "America\/Chicago"
  },
  "IL68": {
      "icao": "IL68",
      "iata": "",
      "name": "Mill Rose Farm Restricted Landing Area",
      "city": "Chicago\/Barrington",
      "state": "Illinois",
      "country": "US",
      "elevation": 850,
      "lat": 42.07720184,
      "lon": -88.15979767,
      "tz": "America\/Chicago"
  },
  "IL71": {
      "icao": "IL71",
      "iata": "",
      "name": "Dury Estates Airport",
      "city": "Hurst",
      "state": "Illinois",
      "country": "US",
      "elevation": 395,
      "lat": 37.81919861,
      "lon": -89.15219879,
      "tz": "America\/Chicago"
  },
  "IL72": {
      "icao": "IL72",
      "iata": "",
      "name": "Entwistle Airport",
      "city": "Lostant",
      "state": "Illinois",
      "country": "US",
      "elevation": 700,
      "lat": 41.1156005859,
      "lon": -89.0447998047,
      "tz": "America\/Chicago"
  },
  "IL78": {
      "icao": "IL78",
      "iata": "",
      "name": "Benoit Airport",
      "city": "Kankakee",
      "state": "Illinois",
      "country": "US",
      "elevation": 660,
      "lat": 41.1310997009,
      "lon": -87.7922973633,
      "tz": "America\/Chicago"
  },
  "IL81": {
      "icao": "IL81",
      "iata": "",
      "name": "Berns Airport",
      "city": "Clifton",
      "state": "Illinois",
      "country": "US",
      "elevation": 658,
      "lat": 40.9361000061,
      "lon": -88.0417022705,
      "tz": "America\/Chicago"
  },
  "IL82": {
      "icao": "IL82",
      "iata": "",
      "name": "Martin RLA Restricted Landing Area",
      "city": "Clinton",
      "state": "Illinois",
      "country": "US",
      "elevation": 725,
      "lat": 40.109500885,
      "lon": -88.8286972046,
      "tz": "America\/Chicago"
  },
  "IL84": {
      "icao": "IL84",
      "iata": "",
      "name": "Douglas Airport",
      "city": "Colusa",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 40.5764007568,
      "lon": -91.102897644,
      "tz": "America\/Chicago"
  },
  "IL86": {
      "icao": "IL86",
      "iata": "",
      "name": "Krohe Airport",
      "city": "Beardstown",
      "state": "Illinois",
      "country": "US",
      "elevation": 450,
      "lat": 39.9361991882,
      "lon": -90.466796875,
      "tz": "America\/Chicago"
  },
  "IL87": {
      "icao": "IL87",
      "iata": "",
      "name": "W Davis Airport",
      "city": "Compton",
      "state": "Illinois",
      "country": "US",
      "elevation": 930,
      "lat": 41.711101532,
      "lon": -89.1122970581,
      "tz": "America\/Chicago"
  },
  "IL88": {
      "icao": "IL88",
      "iata": "",
      "name": "Earl Barnickel Airport",
      "city": "Compton",
      "state": "Illinois",
      "country": "US",
      "elevation": 929,
      "lat": 41.6749992371,
      "lon": -89.1167984009,
      "tz": "America\/Chicago"
  },
  "IL91": {
      "icao": "IL91",
      "iata": "",
      "name": "Testoni Farms Airport",
      "city": "Cornell",
      "state": "Illinois",
      "country": "US",
      "elevation": 688,
      "lat": 41.0209007263,
      "lon": -88.6533966064,
      "tz": "America\/Chicago"
  },
  "IL93": {
      "icao": "IL93",
      "iata": "",
      "name": "Hendrickson Flying Service Airport",
      "city": "Creston",
      "state": "Illinois",
      "country": "US",
      "elevation": 889,
      "lat": 41.9453010559,
      "lon": -88.9240036011,
      "tz": "America\/Chicago"
  },
  "IL94": {
      "icao": "IL94",
      "iata": "",
      "name": "Hoblit Farms Airport",
      "city": "Atlanta",
      "state": "Illinois",
      "country": "US",
      "elevation": 623,
      "lat": 40.2262992859,
      "lon": -89.2428970337,
      "tz": "America\/Chicago"
  },
  "IL95": {
      "icao": "IL95",
      "iata": "",
      "name": "Corn Field",
      "city": "Custer Park",
      "state": "Illinois",
      "country": "US",
      "elevation": 585,
      "lat": 41.2230987549,
      "lon": -88.1453018188,
      "tz": "America\/Chicago"
  },
  "IL97": {
      "icao": "IL97",
      "iata": "",
      "name": "Springfield Southwest Airpark",
      "city": "New Berlin",
      "state": "Illinois",
      "country": "US",
      "elevation": 635,
      "lat": 39.7369995117,
      "lon": -89.8097991943,
      "tz": "America\/Chicago"
  },
  "IL99": {
      "icao": "IL99",
      "iata": "",
      "name": "B & C Airport",
      "city": "Earlville",
      "state": "Illinois",
      "country": "US",
      "elevation": 715,
      "lat": 41.5928001404,
      "lon": -88.967300415,
      "tz": "America\/Chicago"
  },
  "IN00": {
      "icao": "IN00",
      "iata": "",
      "name": "Sutton Airport",
      "city": "Montmorenci",
      "state": "Indiana",
      "country": "US",
      "elevation": 740,
      "lat": 40.54529953,
      "lon": -87.0522003174,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN01": {
      "icao": "IN01",
      "iata": "",
      "name": "Best Lock Corp Airstrip",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 815,
      "lat": 39.8880996704,
      "lon": -86.0661010742,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN03": {
      "icao": "IN03",
      "iata": "",
      "name": "Alford Airpark",
      "city": "Petersburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 500,
      "lat": 38.4639015198,
      "lon": -87.25,
      "tz": "America\/Indiana\/Petersburg"
  },
  "IN04": {
      "icao": "IN04",
      "iata": "",
      "name": "N'Meier Airport",
      "city": "Westphalia",
      "state": "Indiana",
      "country": "US",
      "elevation": 495,
      "lat": 38.8464012146,
      "lon": -87.2403030396,
      "tz": "America\/Indiana\/Vincennes"
  },
  "IN05": {
      "icao": "IN05",
      "iata": "",
      "name": "Stevens Farms Airport",
      "city": "Rushville",
      "state": "Indiana",
      "country": "US",
      "elevation": 930,
      "lat": 39.6605987549,
      "lon": -85.5483016968,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN08": {
      "icao": "IN08",
      "iata": "",
      "name": "Shakamak Airport",
      "city": "Jasonville",
      "state": "Indiana",
      "country": "US",
      "elevation": 613,
      "lat": 39.1694984436,
      "lon": -87.1902999878,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN09": {
      "icao": "IN09",
      "iata": "",
      "name": "Clifton Airport",
      "city": "Boswell",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 40.5242004395,
      "lon": -87.4119033813,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN10": {
      "icao": "IN10",
      "iata": "",
      "name": "Plummer Airport",
      "city": "Kewanna",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 40.9758987427,
      "lon": -86.4199981689,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN11": {
      "icao": "IN11",
      "iata": "",
      "name": "Arrowhead Farm Airport",
      "city": "Bourbon",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 41.2928009033,
      "lon": -86.0710983276,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN13": {
      "icao": "IN13",
      "iata": "",
      "name": "Lanesville Skyways Airport",
      "city": "Lanesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 885,
      "lat": 38.2237014771,
      "lon": -85.9749984741,
      "tz": "America\/Kentucky\/Louisville"
  },
  "IN14": {
      "icao": "IN14",
      "iata": "",
      "name": "Greenridge RLA Restricted Landing Area",
      "city": "Lanesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 858,
      "lat": 38.2389984131,
      "lon": -86.0194015503,
      "tz": "America\/Kentucky\/Louisville"
  },
  "IN15": {
      "icao": "IN15",
      "iata": "",
      "name": "T & T Airport",
      "city": "Vincennes",
      "state": "Indiana",
      "country": "US",
      "elevation": 550,
      "lat": 38.604801178,
      "lon": -87.4630966187,
      "tz": "America\/Indiana\/Vincennes"
  },
  "IN18": {
      "icao": "IN18",
      "iata": "",
      "name": "Hook Field",
      "city": "Harlan",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 41.2745018005,
      "lon": -84.8050003052,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN19": {
      "icao": "IN19",
      "iata": "",
      "name": "Marshall Field",
      "city": "Acton",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 39.6431007385,
      "lon": -85.9816970825,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN20": {
      "icao": "IN20",
      "iata": "",
      "name": "Jungclaus Airport",
      "city": "Martinsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 765,
      "lat": 39.4537010193,
      "lon": -86.2802963257,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN21": {
      "icao": "IN21",
      "iata": "",
      "name": "Starkey's Airport",
      "city": "Mooreland",
      "state": "Indiana",
      "country": "US",
      "elevation": 1110,
      "lat": 40.0430984497,
      "lon": -85.2604980469,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN23": {
      "icao": "IN23",
      "iata": "",
      "name": "Zeller Elev County Airport",
      "city": "Mount Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 378,
      "lat": 37.9084014893,
      "lon": -87.7583999634,
      "tz": "America\/Chicago"
  },
  "IN25": {
      "icao": "IN25",
      "iata": "",
      "name": "Hood Field",
      "city": "Jamestown",
      "state": "Indiana",
      "country": "US",
      "elevation": 930,
      "lat": 39.9981002808,
      "lon": -86.5772018433,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN26": {
      "icao": "IN26",
      "iata": "",
      "name": "Archangels Landing Airport",
      "city": "San Pierre",
      "state": "Indiana",
      "country": "US",
      "elevation": 669,
      "lat": 41.2613983154,
      "lon": -86.8724975586,
      "tz": "America\/Chicago"
  },
  "IN27": {
      "icao": "IN27",
      "iata": "",
      "name": "Skyridge Airport",
      "city": "Arcadia",
      "state": "Indiana",
      "country": "US",
      "elevation": 895,
      "lat": 40.1791992188,
      "lon": -86.1157989502,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN29": {
      "icao": "IN29",
      "iata": "",
      "name": "Durflinger Airport",
      "city": "Otterbein",
      "state": "Indiana",
      "country": "US",
      "elevation": 747,
      "lat": 40.573600769,
      "lon": -87.1035995483,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN30": {
      "icao": "IN30",
      "iata": "",
      "name": "Hull Airport",
      "city": "Patoka",
      "state": "Indiana",
      "country": "US",
      "elevation": 423,
      "lat": 38.4023017883,
      "lon": -87.5995025635,
      "tz": "America\/Chicago"
  },
  "IN31": {
      "icao": "IN31",
      "iata": "",
      "name": "North Lakeland Airport",
      "city": "Pendleton",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 40.0223007202,
      "lon": -85.7536010742,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN32": {
      "icao": "IN32",
      "iata": "",
      "name": "Stephenson Airport",
      "city": "Pendleton",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 39.9772987366,
      "lon": -85.7444000244,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN33": {
      "icao": "IN33",
      "iata": "",
      "name": "Robison Airport",
      "city": "Peru",
      "state": "Indiana",
      "country": "US",
      "elevation": 735,
      "lat": 40.7361984253,
      "lon": -86.1153030396,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN34": {
      "icao": "IN34",
      "iata": "",
      "name": "Rush Strip",
      "city": "Peru",
      "state": "Indiana",
      "country": "US",
      "elevation": 770,
      "lat": 40.8344993591,
      "lon": -86.0522003174,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN35": {
      "icao": "IN35",
      "iata": "",
      "name": "Shinn Bone Lane Airport",
      "city": "Peru",
      "state": "Indiana",
      "country": "US",
      "elevation": 809,
      "lat": 40.6542015076,
      "lon": -86.0227966309,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN36": {
      "icao": "IN36",
      "iata": "",
      "name": "Plugger Airport",
      "city": "Evansville",
      "state": "Indiana",
      "country": "US",
      "elevation": 426,
      "lat": 38.0355987549,
      "lon": -87.7240982056,
      "tz": "America\/Chicago"
  },
  "IN38": {
      "icao": "IN38",
      "iata": "",
      "name": "Stewarts Green Acres Airport",
      "city": "North Liberty",
      "state": "Indiana",
      "country": "US",
      "elevation": 725,
      "lat": 41.54529953,
      "lon": -86.452796936,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN39": {
      "icao": "IN39",
      "iata": "",
      "name": "Klopfenstein Airport",
      "city": "Reynolds",
      "state": "Indiana",
      "country": "US",
      "elevation": 685,
      "lat": 40.7672996521,
      "lon": -86.9207992554,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN40": {
      "icao": "IN40",
      "iata": "",
      "name": "Brauns Airport",
      "city": "Richland",
      "state": "Indiana",
      "country": "US",
      "elevation": 400,
      "lat": 37.9099998474,
      "lon": -87.182800293,
      "tz": "America\/Chicago"
  },
  "IN41": {
      "icao": "IN41",
      "iata": "",
      "name": "Nuckols Airport",
      "city": "Redkey",
      "state": "Indiana",
      "country": "US",
      "elevation": 1025,
      "lat": 40.354801178,
      "lon": -85.0491027832,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN42": {
      "icao": "IN42",
      "iata": "",
      "name": "Hackbarth Airport",
      "city": "Bremen",
      "state": "Indiana",
      "country": "US",
      "elevation": 835,
      "lat": 41.4902992249,
      "lon": -86.1994018555,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN43": {
      "icao": "IN43",
      "iata": "",
      "name": "Spring Lake Airport",
      "city": "Pekin",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 38.4751014709,
      "lon": -85.9972000122,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN44": {
      "icao": "IN44",
      "iata": "",
      "name": "Hardin Airport",
      "city": "Salem",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 38.564201355,
      "lon": -86.0575027466,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN45": {
      "icao": "IN45",
      "iata": "",
      "name": "4 Winds Aerodrome",
      "city": "Roachdale",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 39.8250007629,
      "lon": -86.8833007813,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN46": {
      "icao": "IN46",
      "iata": "",
      "name": "Butler Field",
      "city": "Rockville",
      "state": "Indiana",
      "country": "US",
      "elevation": 687,
      "lat": 39.738899231,
      "lon": -87.2194976807,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN47": {
      "icao": "IN47",
      "iata": "",
      "name": "White Airport",
      "city": "Richmond",
      "state": "Indiana",
      "country": "US",
      "elevation": 1140,
      "lat": 39.773601532,
      "lon": -84.9227981567,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN49": {
      "icao": "IN49",
      "iata": "",
      "name": "Pherigo Airport",
      "city": "Shelbyville",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 39.4874992371,
      "lon": -85.8052978516,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN50": {
      "icao": "IN50",
      "iata": "",
      "name": "Siefert Airport",
      "city": "Shelbyville",
      "state": "Indiana",
      "country": "US",
      "elevation": 750,
      "lat": 39.4402999878,
      "lon": -85.765296936,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN51": {
      "icao": "IN51",
      "iata": "",
      "name": "Windy Knoll Airport",
      "city": "Sheridan",
      "state": "Indiana",
      "country": "US",
      "elevation": 920,
      "lat": 40.1399993896,
      "lon": -86.1524963379,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN53": {
      "icao": "IN53",
      "iata": "",
      "name": "Miller Airport",
      "city": "Spencer",
      "state": "Indiana",
      "country": "US",
      "elevation": 710,
      "lat": 39.2916984558,
      "lon": -86.823600769,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN54": {
      "icao": "IN54",
      "iata": "",
      "name": "I & C Field",
      "city": "Culver",
      "state": "Indiana",
      "country": "US",
      "elevation": 745,
      "lat": 41.2836990356,
      "lon": -86.4627990723,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN55": {
      "icao": "IN55",
      "iata": "",
      "name": "Songer Airport",
      "city": "Veedersburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 652,
      "lat": 40.0792007446,
      "lon": -87.261100769,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN56": {
      "icao": "IN56",
      "iata": "",
      "name": "Carroll's Airpark",
      "city": "Muncie",
      "state": "Indiana",
      "country": "US",
      "elevation": 940,
      "lat": 40.1268997192,
      "lon": -85.4319000244,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN57": {
      "icao": "IN57",
      "iata": "",
      "name": "Shure Airport",
      "city": "Farmersburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 550,
      "lat": 39.2613983154,
      "lon": -87.4208984375,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN58": {
      "icao": "IN58",
      "iata": "",
      "name": "Sealscott Airport",
      "city": "Monroeville",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 40.9037017822,
      "lon": -84.8475036621,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN60": {
      "icao": "IN60",
      "iata": "",
      "name": "Wilderness Field",
      "city": "Westfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 908,
      "lat": 40.0177993774,
      "lon": -86.1603012085,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN61": {
      "icao": "IN61",
      "iata": "",
      "name": "Flying J Airport",
      "city": "Wheatfield",
      "state": "Indiana",
      "country": "US",
      "elevation": 653,
      "lat": 41.2641983032,
      "lon": -87.1343994141,
      "tz": "America\/Chicago"
  },
  "IN62": {
      "icao": "IN62",
      "iata": "",
      "name": "Tropria Airport",
      "city": "Churubusco",
      "state": "Indiana",
      "country": "US",
      "elevation": 905,
      "lat": 41.2448005676,
      "lon": -85.2975006104,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN63": {
      "icao": "IN63",
      "iata": "",
      "name": "Horizon Field",
      "city": "Eaton",
      "state": "Indiana",
      "country": "US",
      "elevation": 940,
      "lat": 40.3666992188,
      "lon": -85.3386001587,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN64": {
      "icao": "IN64",
      "iata": "",
      "name": "Beck Airport",
      "city": "Bargersville",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 39.5609016418,
      "lon": -86.2040023804,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN65": {
      "icao": "IN65",
      "iata": "",
      "name": "Wolfe Field",
      "city": "Shipshewana",
      "state": "Indiana",
      "country": "US",
      "elevation": 896,
      "lat": 41.6913986206,
      "lon": -85.5954971313,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN67": {
      "icao": "IN67",
      "iata": "",
      "name": "Greener Pastures Airport",
      "city": "North Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 618,
      "lat": 38.9373016357,
      "lon": -85.7375030518,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN68": {
      "icao": "IN68",
      "iata": "",
      "name": "Stewart Airport",
      "city": "Elizabeth",
      "state": "Indiana",
      "country": "US",
      "elevation": 700,
      "lat": 38.1355018616,
      "lon": -86.0186004639,
      "tz": "America\/Kentucky\/Louisville"
  },
  "IN69": {
      "icao": "IN69",
      "iata": "",
      "name": "Hatfield Airport",
      "city": "Bristol",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 41.665599823,
      "lon": -85.8321990967,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN70": {
      "icao": "IN70",
      "iata": "",
      "name": "Bodin Airport",
      "city": "Chesterton",
      "state": "Indiana",
      "country": "US",
      "elevation": 675,
      "lat": 41.6335983276,
      "lon": -87.0270004272,
      "tz": "America\/Chicago"
  },
  "IN71": {
      "icao": "IN71",
      "iata": "",
      "name": "Plew Airport",
      "city": "Columbia City",
      "state": "Indiana",
      "country": "US",
      "elevation": 910,
      "lat": 41.1856002808,
      "lon": -85.5215988159,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN72": {
      "icao": "IN72",
      "iata": "",
      "name": "Mayer Airport",
      "city": "Union Dale",
      "state": "Indiana",
      "country": "US",
      "elevation": 820,
      "lat": 40.785900116,
      "lon": -85.2442016602,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN73": {
      "icao": "IN73",
      "iata": "",
      "name": "Fleet Field",
      "city": "Culver",
      "state": "Indiana",
      "country": "US",
      "elevation": 821,
      "lat": 41.2223014832,
      "lon": -86.386100769,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN74": {
      "icao": "IN74",
      "iata": "",
      "name": "H.J.Umbaugh Airport",
      "city": "Culver",
      "state": "Indiana",
      "country": "US",
      "elevation": 755,
      "lat": 41.3069992065,
      "lon": -86.4375,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN76": {
      "icao": "IN76",
      "iata": "",
      "name": "Podell Airport",
      "city": "Denham",
      "state": "Indiana",
      "country": "US",
      "elevation": 710,
      "lat": 41.1263999939,
      "lon": -86.6932983398,
      "tz": "America\/Indiana\/Winamac"
  },
  "IN80": {
      "icao": "IN80",
      "iata": "",
      "name": "Roberson Airport",
      "city": "English",
      "state": "Indiana",
      "country": "US",
      "elevation": 690,
      "lat": 38.3213996887,
      "lon": -86.4907989502,
      "tz": "America\/Indiana\/Marengo"
  },
  "IN81": {
      "icao": "IN81",
      "iata": "",
      "name": "Small Field",
      "city": "Carthage",
      "state": "Indiana",
      "country": "US",
      "elevation": 920,
      "lat": 39.7518997192,
      "lon": -85.5575027466,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN82": {
      "icao": "IN82",
      "iata": "",
      "name": "Foos Field",
      "city": "Granger",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 41.7495002747,
      "lon": -86.0880966187,
      "tz": "America\/Detroit"
  },
  "IN83": {
      "icao": "IN83",
      "iata": "",
      "name": "Lautzenhiser Airpark",
      "city": "Hamilton",
      "state": "Indiana",
      "country": "US",
      "elevation": 928,
      "lat": 41.5023002625,
      "lon": -84.9301986694,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN84": {
      "icao": "IN84",
      "iata": "",
      "name": "Shamrock Airport",
      "city": "Hanna",
      "state": "Indiana",
      "country": "US",
      "elevation": 680,
      "lat": 41.4081001282,
      "lon": -86.7472000122,
      "tz": "America\/Chicago"
  },
  "IN85": {
      "icao": "IN85",
      "iata": "",
      "name": "Bowlin Airport",
      "city": "Huntington",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 40.8372993469,
      "lon": -85.4835968018,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN86": {
      "icao": "IN86",
      "iata": "",
      "name": "Wilson Airport",
      "city": "Knox",
      "state": "Indiana",
      "country": "US",
      "elevation": 714,
      "lat": 41.2803001404,
      "lon": -86.6320037842,
      "tz": "America\/Indiana\/Knox"
  },
  "IN87": {
      "icao": "IN87",
      "iata": "",
      "name": "Singleton's Landing Strip",
      "city": "Knox",
      "state": "Indiana",
      "country": "US",
      "elevation": 685,
      "lat": 41.351398468,
      "lon": -86.6264038086,
      "tz": "America\/Indiana\/Knox"
  },
  "IN88": {
      "icao": "IN88",
      "iata": "",
      "name": "Sanders Gyroport Airport",
      "city": "Cloverdale",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 39.5134010315,
      "lon": -86.7639007568,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN89": {
      "icao": "IN89",
      "iata": "",
      "name": "Ligonier Airport",
      "city": "Ligonier",
      "state": "Indiana",
      "country": "US",
      "elevation": 920,
      "lat": 41.4500999451,
      "lon": -85.6054992676,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN90": {
      "icao": "IN90",
      "iata": "",
      "name": "Wietbrock Airport",
      "city": "Lowell",
      "state": "Indiana",
      "country": "US",
      "elevation": 695,
      "lat": 41.2597999573,
      "lon": -87.4813995361,
      "tz": "America\/Chicago"
  },
  "IN91": {
      "icao": "IN91",
      "iata": "",
      "name": "Valhalla Airport",
      "city": "Maples",
      "state": "Indiana",
      "country": "US",
      "elevation": 810,
      "lat": 41.0023002625,
      "lon": -84.991897583,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN92": {
      "icao": "IN92",
      "iata": "",
      "name": "Klein Airport",
      "city": "Vincennes",
      "state": "Indiana",
      "country": "US",
      "elevation": 412,
      "lat": 38.6528015137,
      "lon": -87.5635986328,
      "tz": "America\/Indiana\/Vincennes"
  },
  "IN93": {
      "icao": "IN93",
      "iata": "",
      "name": "Hustons Airport",
      "city": "New Carlisle",
      "state": "Indiana",
      "country": "US",
      "elevation": 760,
      "lat": 41.7120018005,
      "lon": -86.432800293,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN95": {
      "icao": "IN95",
      "iata": "",
      "name": "Long Airport",
      "city": "North Judson",
      "state": "Indiana",
      "country": "US",
      "elevation": 700,
      "lat": 41.2275009155,
      "lon": -86.7902984619,
      "tz": "America\/Indiana\/Knox"
  },
  "IN98": {
      "icao": "IN98",
      "iata": "",
      "name": "Farm Strip",
      "city": "Finly",
      "state": "Indiana",
      "country": "US",
      "elevation": 825,
      "lat": 39.6831016541,
      "lon": -85.8442001343,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IN99": {
      "icao": "IN99",
      "iata": "",
      "name": "Donica Field",
      "city": "Morgantown",
      "state": "Indiana",
      "country": "US",
      "elevation": 870,
      "lat": 39.4067001343,
      "lon": -86.3054962158,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "IS00": {
      "icao": "IS00",
      "iata": "",
      "name": "Jay Schertz Farm Airport",
      "city": "Lowpoint",
      "state": "Illinois",
      "country": "US",
      "elevation": 780,
      "lat": 40.8417015076,
      "lon": -89.2744979858,
      "tz": "America\/Chicago"
  },
  "IS01": {
      "icao": "IS01",
      "iata": "",
      "name": "Denby Airport",
      "city": "Carlinville",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 39.268901825,
      "lon": -89.9039993286,
      "tz": "America\/Chicago"
  },
  "IS02": {
      "icao": "IS02",
      "iata": "",
      "name": "Dietchweiler Airport",
      "city": "Watseka",
      "state": "Illinois",
      "country": "US",
      "elevation": 640,
      "lat": 40.7784004211,
      "lon": -87.7917022705,
      "tz": "America\/Chicago"
  },
  "IS08": {
      "icao": "IS08",
      "iata": "",
      "name": "Curless Airport",
      "city": "Summum",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 40.2627983093,
      "lon": -90.2368011475,
      "tz": "America\/Chicago"
  },
  "IS13": {
      "icao": "IS13",
      "iata": "",
      "name": "Bussart Airport",
      "city": "Dudley",
      "state": "Illinois",
      "country": "US",
      "elevation": 713,
      "lat": 39.5792007446,
      "lon": -87.8486022949,
      "tz": "America\/Chicago"
  },
  "IS15": {
      "icao": "IS15",
      "iata": "",
      "name": "Vodden Airport",
      "city": "Esmond",
      "state": "Illinois",
      "country": "US",
      "elevation": 850,
      "lat": 42.0325012207,
      "lon": -88.9026031494,
      "tz": "America\/Chicago"
  },
  "IS16": {
      "icao": "IS16",
      "iata": "",
      "name": "Hartline Air Strip STOLport",
      "city": "Anna",
      "state": "Illinois",
      "country": "US",
      "elevation": 645,
      "lat": 37.4667015076,
      "lon": -89.2284011841,
      "tz": "America\/Chicago"
  },
  "IS19": {
      "icao": "IS19",
      "iata": "",
      "name": "Carlson RLA Restricted Landing Area",
      "city": "Pecatonica",
      "state": "Illinois",
      "country": "US",
      "elevation": 740,
      "lat": 42.3353004456,
      "lon": -89.323600769,
      "tz": "America\/Chicago"
  },
  "IS24": {
      "icao": "IS24",
      "iata": "",
      "name": "Harris Airport",
      "city": "Ramsey",
      "state": "Illinois",
      "country": "US",
      "elevation": 551,
      "lat": 39.077778,
      "lon": -89.081667,
      "tz": "America\/Chicago"
  },
  "IS26": {
      "icao": "IS26",
      "iata": "",
      "name": "Niklaus RLA Restricted Landing Area",
      "city": "Farmer City",
      "state": "Illinois",
      "country": "US",
      "elevation": 725,
      "lat": 40.1833992004,
      "lon": -88.6153030396,
      "tz": "America\/Chicago"
  },
  "IS33": {
      "icao": "IS33",
      "iata": "",
      "name": "Willhoit Airport",
      "city": "Staunton",
      "state": "Illinois",
      "country": "US",
      "elevation": 614,
      "lat": 39.0456008911,
      "lon": -89.8406982422,
      "tz": "America\/Chicago"
  },
  "IS34": {
      "icao": "IS34",
      "iata": "",
      "name": "Piper's Landing Airport",
      "city": "Lawrenceville",
      "state": "Illinois",
      "country": "US",
      "elevation": 425,
      "lat": 38.7066993713,
      "lon": -87.6333999634,
      "tz": "America\/Chicago"
  },
  "IS37": {
      "icao": "IS37",
      "iata": "",
      "name": "Stockton Airport",
      "city": "Stockton",
      "state": "Illinois",
      "country": "US",
      "elevation": 970,
      "lat": 42.3238983154,
      "lon": -89.9928970337,
      "tz": "America\/Chicago"
  },
  "IS40": {
      "icao": "IS40",
      "iata": "",
      "name": "Eudy Airport",
      "city": "Monroe Center",
      "state": "Illinois",
      "country": "US",
      "elevation": 785,
      "lat": 42.0943984985,
      "lon": -89.0271987915,
      "tz": "America\/Chicago"
  },
  "IS41": {
      "icao": "IS41",
      "iata": "",
      "name": "Vogen Restricted Landing Area",
      "city": "Newark",
      "state": "Illinois",
      "country": "US",
      "elevation": 740,
      "lat": 41.460899353,
      "lon": -88.5920028687,
      "tz": "America\/Chicago"
  },
  "IS43": {
      "icao": "IS43",
      "iata": "",
      "name": "Riggins Farms Airport",
      "city": "Villa Glove",
      "state": "Illinois",
      "country": "US",
      "elevation": 699,
      "lat": 39.8699989319,
      "lon": -88.0749969482,
      "tz": "America\/Chicago"
  },
  "IS45": {
      "icao": "IS45",
      "iata": "",
      "name": "Mast Field",
      "city": "Quincy",
      "state": "Illinois",
      "country": "US",
      "elevation": 738,
      "lat": 39.9766998291,
      "lon": -91.3339996338,
      "tz": "America\/Chicago"
  },
  "IS47": {
      "icao": "IS47",
      "iata": "",
      "name": "Willis Airport",
      "city": "Sycamore",
      "state": "Illinois",
      "country": "US",
      "elevation": 856,
      "lat": 42.0306015015,
      "lon": -88.7017974854,
      "tz": "America\/Chicago"
  },
  "IS51": {
      "icao": "IS51",
      "iata": "",
      "name": "Schilson Field",
      "city": "Carthage",
      "state": "Illinois",
      "country": "US",
      "elevation": 705,
      "lat": 40.4514007568,
      "lon": -91.2037963867,
      "tz": "America\/Chicago"
  },
  "IS52": {
      "icao": "IS52",
      "iata": "",
      "name": "Russell Airport",
      "city": "Donovan",
      "state": "Illinois",
      "country": "US",
      "elevation": 710,
      "lat": 40.9277992249,
      "lon": -87.6224975586,
      "tz": "America\/Chicago"
  },
  "IS54": {
      "icao": "IS54",
      "iata": "",
      "name": "Mc Christy Airport",
      "city": "Stonington",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 39.6431007385,
      "lon": -89.1445007324,
      "tz": "America\/Chicago"
  },
  "IS55": {
      "icao": "IS55",
      "iata": "",
      "name": "Morton Airport",
      "city": "La Harpe",
      "state": "Illinois",
      "country": "US",
      "elevation": 705,
      "lat": 40.5909004211,
      "lon": -90.9423980713,
      "tz": "America\/Chicago"
  },
  "IS56": {
      "icao": "IS56",
      "iata": "",
      "name": "Herren Airport",
      "city": "Timewell",
      "state": "Illinois",
      "country": "US",
      "elevation": 654,
      "lat": 40.0881004333,
      "lon": -90.8853988647,
      "tz": "America\/Chicago"
  },
  "IS57": {
      "icao": "IS57",
      "iata": "",
      "name": "Wind Rose Farm Airport",
      "city": "Marengo",
      "state": "Illinois",
      "country": "US",
      "elevation": 815,
      "lat": 42.1796989441,
      "lon": -88.6327972412,
      "tz": "America\/Chicago"
  },
  "IS59": {
      "icao": "IS59",
      "iata": "",
      "name": "Rotstein Airport",
      "city": "Kirkland",
      "state": "Illinois",
      "country": "US",
      "elevation": 760,
      "lat": 42.1338996887,
      "lon": -88.9242019653,
      "tz": "America\/Chicago"
  },
  "IS60": {
      "icao": "IS60",
      "iata": "",
      "name": "Nixon Restricted Landing Area",
      "city": "Medora",
      "state": "Illinois",
      "country": "US",
      "elevation": 610,
      "lat": 39.19419861,
      "lon": -90.15609741,
      "tz": "America\/Chicago"
  },
  "IS62": {
      "icao": "IS62",
      "iata": "",
      "name": "John D Rennick Airport",
      "city": "Spring Bay",
      "state": "Illinois",
      "country": "US",
      "elevation": 450,
      "lat": 40.8672981262,
      "lon": -89.4531021118,
      "tz": "America\/Chicago"
  },
  "IS63": {
      "icao": "IS63",
      "iata": "",
      "name": "Hoffman Airport",
      "city": "De Kalb",
      "state": "Illinois",
      "country": "US",
      "elevation": 865,
      "lat": 41.9702987671,
      "lon": -88.7509002686,
      "tz": "America\/Chicago"
  },
  "IS64": {
      "icao": "IS64",
      "iata": "",
      "name": "Kuntz Field",
      "city": "Gridley",
      "state": "Illinois",
      "country": "US",
      "elevation": 735,
      "lat": 40.7230987549,
      "lon": -88.8666992188,
      "tz": "America\/Chicago"
  },
  "IS65": {
      "icao": "IS65",
      "iata": "",
      "name": "Woodlake Landing Airport",
      "city": "Sandwich",
      "state": "Illinois",
      "country": "US",
      "elevation": 677,
      "lat": 41.6384010315,
      "lon": -88.6445007324,
      "tz": "America\/Chicago"
  },
  "IS66": {
      "icao": "IS66",
      "iata": "",
      "name": "Rhea Restricted Landing Area",
      "city": "Pontoosuc",
      "state": "Illinois",
      "country": "US",
      "elevation": 684,
      "lat": 40.592300415,
      "lon": -91.201499939,
      "tz": "America\/Chicago"
  },
  "IS69": {
      "icao": "IS69",
      "iata": "",
      "name": "Kloker Airport",
      "city": "Meredosia",
      "state": "Illinois",
      "country": "US",
      "elevation": 440,
      "lat": 39.9188995361,
      "lon": -90.5110015869,
      "tz": "America\/Chicago"
  },
  "IS71": {
      "icao": "IS71",
      "iata": "",
      "name": "Koch Airport",
      "city": "Shirland",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 42.4583015442,
      "lon": -89.1949996948,
      "tz": "America\/Chicago"
  },
  "IS73": {
      "icao": "IS73",
      "iata": "",
      "name": "Woodley Aerial Spray Airport",
      "city": "New Bedford",
      "state": "Illinois",
      "country": "US",
      "elevation": 635,
      "lat": 41.5325012207,
      "lon": -89.7018966675,
      "tz": "America\/Chicago"
  },
  "IS77": {
      "icao": "IS77",
      "iata": "",
      "name": "Hoehn RLA Restricted Landing Area",
      "city": "Witt",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 39.2207984924,
      "lon": -89.3328018188,
      "tz": "America\/Chicago"
  },
  "IS78": {
      "icao": "IS78",
      "iata": "",
      "name": "Early - Merkel Field",
      "city": "Pecatonica",
      "state": "Illinois",
      "country": "US",
      "elevation": 794,
      "lat": 42.3666992188,
      "lon": -89.2319030762,
      "tz": "America\/Chicago"
  },
  "IS80": {
      "icao": "IS80",
      "iata": "",
      "name": "Uncle Chuck's Airport",
      "city": "De Kalb",
      "state": "Illinois",
      "country": "US",
      "elevation": 880,
      "lat": 41.9178009033,
      "lon": -88.7892990112,
      "tz": "America\/Chicago"
  },
  "IS82": {
      "icao": "IS82",
      "iata": "",
      "name": "Arras RLA Restricted Landing Area",
      "city": "Greenfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 598,
      "lat": 39.3381004333,
      "lon": -90.1781005859,
      "tz": "America\/Chicago"
  },
  "IS83": {
      "icao": "IS83",
      "iata": "",
      "name": "Untied Acres Airport",
      "city": "Belvidere",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 42.2275009155,
      "lon": -88.7671966553,
      "tz": "America\/Chicago"
  },
  "IS85": {
      "icao": "IS85",
      "iata": "",
      "name": "Newton Airport",
      "city": "Patterson",
      "state": "Illinois",
      "country": "US",
      "elevation": 625,
      "lat": 39.5047988892,
      "lon": -90.5054016113,
      "tz": "America\/Chicago"
  },
  "IS86": {
      "icao": "IS86",
      "iata": "",
      "name": "Hendrix Airport",
      "city": "Buckingham",
      "state": "Illinois",
      "country": "US",
      "elevation": 656,
      "lat": 41.0416984558,
      "lon": -88.1917037964,
      "tz": "America\/Chicago"
  },
  "IS88": {
      "icao": "IS88",
      "iata": "",
      "name": "Melody Field",
      "city": "Danville",
      "state": "Illinois",
      "country": "US",
      "elevation": 660,
      "lat": 40.148601532,
      "lon": -87.6945037842,
      "tz": "America\/Chicago"
  },
  "IS93": {
      "icao": "IS93",
      "iata": "",
      "name": "Sue Rock International Airport",
      "city": "Rock City",
      "state": "Illinois",
      "country": "US",
      "elevation": 950,
      "lat": 42.4425010681,
      "lon": -89.4992980957,
      "tz": "America\/Chicago"
  },
  "IS94": {
      "icao": "IS94",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Sandwich",
      "state": "Illinois",
      "country": "US",
      "elevation": 667,
      "lat": 41.6055984497,
      "lon": -88.6612014771,
      "tz": "America\/Chicago"
  },
  "IS95": {
      "icao": "IS95",
      "iata": "",
      "name": "Harrington Farms Airport",
      "city": "Sheridan",
      "state": "Illinois",
      "country": "US",
      "elevation": 640,
      "lat": 41.5586013794,
      "lon": -88.6644973755,
      "tz": "America\/Chicago"
  },
  "JB01": {
      "icao": "JB01",
      "iata": "",
      "name": "Clearwater Aero Estates Airport",
      "city": "Wisconsin Dells",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 944,
      "lat": 43.7571983337,
      "lon": -89.6526031494,
      "tz": "America\/Chicago"
  },
  "JY04": {
      "icao": "JY04",
      "iata": "",
      "name": "Paramount Air Airport",
      "city": "Green Creek",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 8,
      "lat": 39.0651016235,
      "lon": -74.9095993042,
      "tz": "America\/New_York"
  },
  "JY07": {
      "icao": "JY07",
      "iata": "",
      "name": "Air-List-Ads Airport",
      "city": "Stewartsville",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 360,
      "lat": 40.6957015991,
      "lon": -75.1324005127,
      "tz": "America\/New_York"
  },
  "JY08": {
      "icao": "JY08",
      "iata": "",
      "name": "Var-Sky Airport",
      "city": "Woodstown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 135,
      "lat": 39.650100708,
      "lon": -75.2841033936,
      "tz": "America\/New_York"
  },
  "JY17": {
      "icao": "JY17",
      "iata": "",
      "name": "Woodcrest Farms Airstrip",
      "city": "Shiloh",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 90,
      "lat": 39.4721984863,
      "lon": -75.2774963379,
      "tz": "America\/New_York"
  },
  "JY24": {
      "icao": "JY24",
      "iata": "",
      "name": "Weiss Farm Airport",
      "city": "Allamuchy",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 532,
      "lat": 40.9236984253,
      "lon": -74.8703994751,
      "tz": "America\/New_York"
  },
  "JY31": {
      "icao": "JY31",
      "iata": "",
      "name": "Wide Sky Airpark",
      "city": "Bridgeton",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 100,
      "lat": 39.4468002319,
      "lon": -75.3132019043,
      "tz": "America\/New_York"
  },
  "JY39": {
      "icao": "JY39",
      "iata": "",
      "name": "Rainbow'S End Airport",
      "city": "Salem",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 11,
      "lat": 39.661111,
      "lon": -75.465278,
      "tz": "America\/New_York"
  },
  "JY43": {
      "icao": "JY43",
      "iata": "",
      "name": "Hill Top Airport",
      "city": "West Milford",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 921,
      "lat": 41.08322144,
      "lon": -74.33855438,
      "tz": "America\/New_York"
  },
  "K00C": {
      "icao": "K00C",
      "iata": "AMK",
      "name": "Animas Air Park",
      "city": "Durango",
      "state": "Colorado",
      "country": "US",
      "elevation": 6684,
      "lat": 37.2032012939,
      "lon": -107.869003296,
      "tz": "America\/Denver"
  },
  "K00F": {
      "icao": "K00F",
      "iata": "BDX",
      "name": "Broadus Airport",
      "city": "Broadus",
      "state": "Montana",
      "country": "US",
      "elevation": 3280,
      "lat": 45.47249985,
      "lon": -105.4540024,
      "tz": "America\/Denver"
  },
  "K00M": {
      "icao": "K00M",
      "iata": "",
      "name": "Thigpen Field",
      "city": "Bay Springs",
      "state": "Mississippi",
      "country": "US",
      "elevation": 351,
      "lat": 31.9538002014,
      "lon": -89.2344970703,
      "tz": "America\/Chicago"
  },
  "K00R": {
      "icao": "K00R",
      "iata": "",
      "name": "Livingston Municipal Airport",
      "city": "Livingston",
      "state": "Texas",
      "country": "US",
      "elevation": 151,
      "lat": 30.6858997345,
      "lon": -95.0178985596,
      "tz": "America\/Chicago"
  },
  "K01G": {
      "icao": "K01G",
      "iata": "",
      "name": "Perry-Warsaw Airport",
      "city": "Perry",
      "state": "New-York",
      "country": "US",
      "elevation": 1559,
      "lat": 42.7412986755,
      "lon": -78.0521011353,
      "tz": "America\/New_York"
  },
  "K01M": {
      "icao": "K01M",
      "iata": "",
      "name": "Tishomingo County Airport",
      "city": "Belmont",
      "state": "Mississippi",
      "country": "US",
      "elevation": 578,
      "lat": 34.4916992188,
      "lon": -88.2011032104,
      "tz": "America\/Chicago"
  },
  "K02A": {
      "icao": "K02A",
      "iata": "",
      "name": "Gragg-Wade Field",
      "city": "Clanton",
      "state": "Alabama",
      "country": "US",
      "elevation": 585,
      "lat": 32.8504981995,
      "lon": -86.6113967896,
      "tz": "America\/Chicago"
  },
  "K02C": {
      "icao": "K02C",
      "iata": "",
      "name": "Capitol Airport",
      "city": "Brookfield",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 43.0902222222,
      "lon": -88.1781602778,
      "tz": "America\/Chicago"
  },
  "K02G": {
      "icao": "K02G",
      "iata": "",
      "name": "Columbiana County Airport",
      "city": "East Liverpool",
      "state": "Ohio",
      "country": "US",
      "elevation": 1160,
      "lat": 40.6733016968,
      "lon": -80.6414031982,
      "tz": "America\/New_York"
  },
  "K03B": {
      "icao": "K03B",
      "iata": "",
      "name": "Mansfield Municipal Airport",
      "city": "Mansfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1500,
      "lat": 37.1277999878,
      "lon": -92.6210021973,
      "tz": "America\/Chicago"
  },
  "K03D": {
      "icao": "K03D",
      "iata": "",
      "name": "Memphis Memorial Airport",
      "city": "Memphis",
      "state": "Missouri",
      "country": "US",
      "elevation": 813,
      "lat": 40.4472999573,
      "lon": -92.2269973755,
      "tz": "America\/Chicago"
  },
  "K04A": {
      "icao": "K04A",
      "iata": "",
      "name": "Frank Sikes Airport",
      "city": "Luverne",
      "state": "Alabama",
      "country": "US",
      "elevation": 300,
      "lat": 31.7362995148,
      "lon": -86.2624969482,
      "tz": "America\/Chicago"
  },
  "K04G": {
      "icao": "K04G",
      "iata": "",
      "name": "Lansdowne Airport",
      "city": "Youngstown",
      "state": "Ohio",
      "country": "US",
      "elevation": 1044,
      "lat": 41.1305007935,
      "lon": -80.6195983887,
      "tz": "America\/New_York"
  },
  "K04M": {
      "icao": "K04M",
      "iata": "",
      "name": "Calhoun County Airport",
      "city": "Pittsboro",
      "state": "Mississippi",
      "country": "US",
      "elevation": 383,
      "lat": 33.9300994873,
      "lon": -89.3427963257,
      "tz": "America\/Chicago"
  },
  "K04Y": {
      "icao": "K04Y",
      "iata": "",
      "name": "Hawley Municipal Airport",
      "city": "Hawley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1208,
      "lat": 46.8838005066,
      "lon": -96.3503036499,
      "tz": "America\/Chicago"
  },
  "K05C": {
      "icao": "K05C",
      "iata": "",
      "name": "Griffith-Merrillville Airport",
      "city": "Griffith",
      "state": "Indiana",
      "country": "US",
      "elevation": 634,
      "lat": 41.5197982788,
      "lon": -87.3994979858,
      "tz": "America\/Chicago"
  },
  "K05D": {
      "icao": "K05D",
      "iata": "",
      "name": "New Town Municipal Airport",
      "city": "New Town",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1925,
      "lat": 47.966999054,
      "lon": -102.4779968262,
      "tz": "America\/Chicago"
  },
  "K05U": {
      "icao": "K05U",
      "iata": "",
      "name": "Eureka Airport",
      "city": "Eureka",
      "state": "Nevada",
      "country": "US",
      "elevation": 5954,
      "lat": 39.6041984558,
      "lon": -116.0049972534,
      "tz": "America\/Los_Angeles"
  },
  "K06A": {
      "icao": "K06A",
      "iata": "",
      "name": "Moton Field Municipal Airport",
      "city": "Tuskegee",
      "state": "Alabama",
      "country": "US",
      "elevation": 264,
      "lat": 32.4604988098,
      "lon": -85.6800003052,
      "tz": "America\/Chicago"
  },
  "K06C": {
      "icao": "K06C",
      "iata": "",
      "name": "Schaumburg Regional Airport",
      "city": "Chicago\/Schaumburg",
      "state": "Illinois",
      "country": "US",
      "elevation": 801,
      "lat": 41.9892997742,
      "lon": -88.1011962891,
      "tz": "America\/Chicago"
  },
  "K06D": {
      "icao": "K06D",
      "iata": "",
      "name": "Rolla Municipal Airport",
      "city": "Rolla",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1823,
      "lat": 48.88430023,
      "lon": -99.62090302,
      "tz": "America\/Chicago"
  },
  "K06M": {
      "icao": "K06M",
      "iata": "",
      "name": "Eupora Airport",
      "city": "Eupora",
      "state": "Mississippi",
      "country": "US",
      "elevation": 450,
      "lat": 33.5345993042,
      "lon": -89.3125991821,
      "tz": "America\/Chicago"
  },
  "K06U": {
      "icao": "K06U",
      "iata": "",
      "name": "Jackpot\/Hayden Field",
      "city": "Jackpot",
      "state": "Nevada",
      "country": "US",
      "elevation": 5213,
      "lat": 41.9760017395,
      "lon": -114.657997131,
      "tz": "America\/Boise"
  },
  "K07A": {
      "icao": "K07A",
      "iata": "",
      "name": "Franklin Field",
      "city": "Union Springs",
      "state": "Alabama",
      "country": "US",
      "elevation": 300,
      "lat": 32.1668014526,
      "lon": -85.8097000122,
      "tz": "America\/Chicago"
  },
  "K07F": {
      "icao": "K07F",
      "iata": "",
      "name": "Gladewater Municipal Airport",
      "city": "Gladewater",
      "state": "Texas",
      "country": "US",
      "elevation": 296,
      "lat": 32.5288009644,
      "lon": -94.9717025757,
      "tz": "America\/Chicago"
  },
  "K07R": {
      "icao": "K07R",
      "iata": "",
      "name": "Bishop Municipal Airport",
      "city": "Bishop",
      "state": "Texas",
      "country": "US",
      "elevation": 55,
      "lat": 27.6103000641,
      "lon": -97.7518997192,
      "tz": "America\/Chicago"
  },
  "K07V": {
      "icao": "K07V",
      "iata": "",
      "name": "Cuchara Valley At La Veta Airport",
      "city": "La Veta",
      "state": "Colorado",
      "country": "US",
      "elevation": 7153,
      "lat": 37.5237998962,
      "lon": -105.0090026855,
      "tz": "America\/Denver"
  },
  "K08C": {
      "icao": "K08C",
      "iata": "",
      "name": "Riverview Airport",
      "city": "Jenison",
      "state": "Michigan",
      "country": "US",
      "elevation": 603,
      "lat": 42.9359016418,
      "lon": -85.8050003052,
      "tz": "America\/Detroit"
  },
  "K08D": {
      "icao": "K08D",
      "iata": "",
      "name": "Stanley Municipal Airport",
      "city": "Stanley",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2245,
      "lat": 48.3008003235,
      "lon": -102.40599823,
      "tz": "America\/Chicago"
  },
  "K08K": {
      "icao": "K08K",
      "iata": "",
      "name": "Harvard State Airport",
      "city": "Harvard",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1815,
      "lat": 40.6514015198,
      "lon": -98.0798034668,
      "tz": "America\/Chicago"
  },
  "K08M": {
      "icao": "K08M",
      "iata": "",
      "name": "Carthage-Leake County Airport",
      "city": "Carthage",
      "state": "Mississippi",
      "country": "US",
      "elevation": 454,
      "lat": 32.7611999512,
      "lon": -89.5300979614,
      "tz": "America\/Chicago"
  },
  "K09A": {
      "icao": "K09A",
      "iata": "",
      "name": "Butler-Choctaw County Airport",
      "city": "Butler",
      "state": "Alabama",
      "country": "US",
      "elevation": 134,
      "lat": 32.1193008423,
      "lon": -88.1275024414,
      "tz": "America\/Chicago"
  },
  "K09K": {
      "icao": "K09K",
      "iata": "",
      "name": "Sargent Municipal Airport",
      "city": "Sargent",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2313,
      "lat": 41.6370010376,
      "lon": -99.3404006958,
      "tz": "America\/Chicago"
  },
  "K09M": {
      "icao": "K09M",
      "iata": "",
      "name": "Charleston Municipal Airport",
      "city": "Charleston",
      "state": "Mississippi",
      "country": "US",
      "elevation": 175,
      "lat": 33.9915008545,
      "lon": -90.0781021118,
      "tz": "America\/Chicago"
  },
  "K09R": {
      "icao": "K09R",
      "iata": "",
      "name": "Tyler County Airport",
      "city": "Woodville",
      "state": "Texas",
      "country": "US",
      "elevation": 388,
      "lat": 30.7751998901,
      "lon": -94.4585037231,
      "tz": "America\/Chicago"
  },
  "K0A2": {
      "icao": "K0A2",
      "iata": "",
      "name": "Hester Memorial Airport",
      "city": "Calhoun Falls",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 517,
      "lat": 34.0904006958,
      "lon": -82.5679016113,
      "tz": "America\/New_York"
  },
  "K0A3": {
      "icao": "K0A3",
      "iata": "",
      "name": "Smithville Municipal Airport",
      "city": "Smithville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1084,
      "lat": 35.9852981567,
      "lon": -85.8093032837,
      "tz": "America\/Chicago"
  },
  "K0A4": {
      "icao": "K0A4",
      "iata": "",
      "name": "Johnson City Stolport Airport",
      "city": "Johnson City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1550,
      "lat": 36.3637008667,
      "lon": -82.3089981079,
      "tz": "America\/New_York"
  },
  "K0A7": {
      "icao": "K0A7",
      "iata": "",
      "name": "Hendersonville Airport",
      "city": "Hendersonville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2084,
      "lat": 35.307598114,
      "lon": -82.4331970215,
      "tz": "America\/New_York"
  },
  "K0A8": {
      "icao": "K0A8",
      "iata": "",
      "name": "Bibb County Airport",
      "city": "Centreville",
      "state": "Alabama",
      "country": "US",
      "elevation": 251,
      "lat": 32.9367980957,
      "lon": -87.0888977051,
      "tz": "America\/Chicago"
  },
  "K0A9": {
      "icao": "K0A9",
      "iata": "",
      "name": "Elizabethton Municipal Airport",
      "city": "Elizabethton",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1593,
      "lat": 36.3712005615,
      "lon": -82.1733016968,
      "tz": "America\/New_York"
  },
  "K0B1": {
      "icao": "K0B1",
      "iata": "",
      "name": "Bethel Regional Airport",
      "city": "Bethel",
      "state": "Maine",
      "country": "US",
      "elevation": 654,
      "lat": 44.425201416,
      "lon": -70.8098983765,
      "tz": "America\/New_York"
  },
  "K0B4": {
      "icao": "K0B4",
      "iata": "",
      "name": "Hartington Municipal Airport",
      "city": "Hartington",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1387,
      "lat": 42.6035995483,
      "lon": -97.2526016235,
      "tz": "America\/Chicago"
  },
  "K0B5": {
      "icao": "K0B5",
      "iata": "",
      "name": "Turners Falls Airport",
      "city": "Montague",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 356,
      "lat": 42.5914001465,
      "lon": -72.5227966309,
      "tz": "America\/New_York"
  },
  "K0B8": {
      "icao": "K0B8",
      "iata": "FID",
      "name": "Elizabeth Field",
      "city": "Fishers Island",
      "state": "New-York",
      "country": "US",
      "elevation": 9,
      "lat": 41.2513008118,
      "lon": -72.0316009521,
      "tz": "America\/New_York"
  },
  "K0C0": {
      "icao": "K0C0",
      "iata": "",
      "name": "Dacy Airport",
      "city": "Harvard",
      "state": "Illinois",
      "country": "US",
      "elevation": 913,
      "lat": 42.4025001526,
      "lon": -88.6324005127,
      "tz": "America\/Chicago"
  },
  "K0C4": {
      "icao": "K0C4",
      "iata": "",
      "name": "Pender Municipal Airport",
      "city": "Pender",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1345,
      "lat": 42.11389923,
      "lon": -96.72889709,
      "tz": "America\/Chicago"
  },
  "K0D8": {
      "icao": "K0D8",
      "iata": "",
      "name": "Gettysburg Municipal Airport",
      "city": "Gettysburg",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2062,
      "lat": 44.98669815,
      "lon": -99.95279694,
      "tz": "America\/Chicago"
  },
  "K0F2": {
      "icao": "K0F2",
      "iata": "",
      "name": "Bowie Municipal Airport",
      "city": "Bowie",
      "state": "Texas",
      "country": "US",
      "elevation": 1101,
      "lat": 33.6016998291,
      "lon": -97.7755966187,
      "tz": "America\/Chicago"
  },
  "K0F4": {
      "icao": "K0F4",
      "iata": "",
      "name": "Loup City Municipal Airport",
      "city": "Loup City",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2071,
      "lat": 41.2902984619,
      "lon": -98.9906005859,
      "tz": "America\/Chicago"
  },
  "K0F7": {
      "icao": "K0F7",
      "iata": "",
      "name": "Fountainhead Lodge Airpark",
      "city": "Eufaula",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 670,
      "lat": 35.3886985779,
      "lon": -95.5999984741,
      "tz": "America\/Chicago"
  },
  "K0F9": {
      "icao": "K0F9",
      "iata": "",
      "name": "Tishomingo Airpark",
      "city": "Tishomingo",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 647,
      "lat": 34.1985015869,
      "lon": -96.6744995117,
      "tz": "America\/Chicago"
  },
  "K0G3": {
      "icao": "K0G3",
      "iata": "",
      "name": "Tecumseh Municipal Airport",
      "city": "Tecumseh",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1306,
      "lat": 40.3993988,
      "lon": -96.17140198,
      "tz": "America\/Chicago"
  },
  "K0G6": {
      "icao": "K0G6",
      "iata": "",
      "name": "Williams County Airport",
      "city": "Bryan",
      "state": "Ohio",
      "country": "US",
      "elevation": 730,
      "lat": 41.4673995972,
      "lon": -84.5065994263,
      "tz": "America\/New_York"
  },
  "K0G7": {
      "icao": "K0G7",
      "iata": "",
      "name": "Finger Lakes Regional Airport",
      "city": "Seneca Falls",
      "state": "New-York",
      "country": "US",
      "elevation": 492,
      "lat": 42.8835983276,
      "lon": -76.7810974121,
      "tz": "America\/New_York"
  },
  "K0H1": {
      "icao": "K0H1",
      "iata": "",
      "name": "Trego Wakeeney Airport",
      "city": "Wakeeney",
      "state": "Kansas",
      "country": "US",
      "elevation": 2435,
      "lat": 39.0045013428,
      "lon": -99.8928985596,
      "tz": "America\/Chicago"
  },
  "K0I8": {
      "icao": "K0I8",
      "iata": "",
      "name": "Cynthiana-Harrison County Airport",
      "city": "Cynthiana",
      "state": "Kentucky",
      "country": "US",
      "elevation": 721,
      "lat": 38.3661994934,
      "lon": -84.2833023071,
      "tz": "America\/New_York"
  },
  "K0J4": {
      "icao": "K0J4",
      "iata": "",
      "name": "Florala Municipal Airport",
      "city": "Florala",
      "state": "Alabama",
      "country": "US",
      "elevation": 314,
      "lat": 31.0424995422,
      "lon": -86.3115997314,
      "tz": "America\/Chicago"
  },
  "K0J6": {
      "icao": "K0J6",
      "iata": "",
      "name": "Headland Municipal Airport",
      "city": "Headland",
      "state": "Alabama",
      "country": "US",
      "elevation": 359,
      "lat": 31.36429977,
      "lon": -85.3117981,
      "tz": "America\/Chicago"
  },
  "K0J9": {
      "icao": "K0J9",
      "iata": "",
      "name": "Flying V Airport",
      "city": "Utica",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1585,
      "lat": 40.8678016663,
      "lon": -97.3542022705,
      "tz": "America\/Chicago"
  },
  "K0K7": {
      "icao": "K0K7",
      "iata": "",
      "name": "Humboldt Municipal Airport",
      "city": "Humboldt",
      "state": "Iowa",
      "country": "US",
      "elevation": 1093,
      "lat": 42.7360992432,
      "lon": -94.2452011108,
      "tz": "America\/Chicago"
  },
  "K0L7": {
      "icao": "K0L7",
      "iata": "",
      "name": "Jean Airport",
      "city": "Jean",
      "state": "Nevada",
      "country": "US",
      "elevation": 2832,
      "lat": 35.7682991028,
      "lon": -115.3300018311,
      "tz": "America\/Los_Angeles"
  },
  "K0L9": {
      "icao": "K0L9",
      "iata": "",
      "name": "Echo Bay Airport",
      "city": "Overton",
      "state": "Nevada",
      "country": "US",
      "elevation": 1535,
      "lat": 36.3111000061,
      "lon": -114.4639968872,
      "tz": "America\/Los_Angeles"
  },
  "K0M0": {
      "icao": "K0M0",
      "iata": "",
      "name": "Billy Free Municipal Airport",
      "city": "Dumas",
      "state": "Arkansas",
      "country": "US",
      "elevation": 163,
      "lat": 33.8847999573,
      "lon": -91.5345001221,
      "tz": "America\/Chicago"
  },
  "K0M1": {
      "icao": "K0M1",
      "iata": "",
      "name": "Scott Field",
      "city": "Parsons",
      "state": "Tennessee",
      "country": "US",
      "elevation": 520,
      "lat": 35.6377983093,
      "lon": -88.1279983521,
      "tz": "America\/Chicago"
  },
  "K0M2": {
      "icao": "K0M2",
      "iata": "",
      "name": "Reelfoot Lake Airport",
      "city": "Tiptonville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 289,
      "lat": 36.4752998352,
      "lon": -89.3461990356,
      "tz": "America\/Chicago"
  },
  "K0M3": {
      "icao": "K0M3",
      "iata": "",
      "name": "John A Baker Field",
      "city": "Hohenwald",
      "state": "Tennessee",
      "country": "US",
      "elevation": 972,
      "lat": 35.5460014343,
      "lon": -87.5972976685,
      "tz": "America\/Chicago"
  },
  "K0M4": {
      "icao": "K0M4",
      "iata": "",
      "name": "Benton County Airport",
      "city": "Camden",
      "state": "Tennessee",
      "country": "US",
      "elevation": 468,
      "lat": 36.011100769,
      "lon": -88.123298645,
      "tz": "America\/Chicago"
  },
  "K0M5": {
      "icao": "K0M5",
      "iata": "",
      "name": "Humphreys County Airport",
      "city": "Waverly",
      "state": "Tennessee",
      "country": "US",
      "elevation": 756,
      "lat": 36.1166000366,
      "lon": -87.7381973267,
      "tz": "America\/Chicago"
  },
  "K0M8": {
      "icao": "K0M8",
      "iata": "",
      "name": "Byerley Airport",
      "city": "Lake Providence",
      "state": "Louisiana",
      "country": "US",
      "elevation": 106,
      "lat": 32.8259010315,
      "lon": -91.1876983643,
      "tz": "America\/Chicago"
  },
  "K0M9": {
      "icao": "K0M9",
      "iata": "",
      "name": "Delhi Municipal Airport",
      "city": "Delhi",
      "state": "Louisiana",
      "country": "US",
      "elevation": 91,
      "lat": 32.4107017517,
      "lon": -91.4987030029,
      "tz": "America\/Chicago"
  },
  "K0O2": {
      "icao": "K0O2",
      "iata": "",
      "name": "Baker Airport",
      "city": "Baker",
      "state": "California",
      "country": "US",
      "elevation": 922,
      "lat": 35.2854995728,
      "lon": -116.0830001831,
      "tz": "America\/Los_Angeles"
  },
  "K0Q5": {
      "icao": "K0Q5",
      "iata": "",
      "name": "Shelter Cove Airport",
      "city": "Shelter Cove",
      "state": "California",
      "country": "US",
      "elevation": 69,
      "lat": 40.0275993347,
      "lon": -124.0729980469,
      "tz": "America\/Los_Angeles"
  },
  "K0R0": {
      "icao": "K0R0",
      "iata": "",
      "name": "Columbia-Marion County Airport",
      "city": "Columbia",
      "state": "Mississippi",
      "country": "US",
      "elevation": 265,
      "lat": 31.297000885,
      "lon": -89.8127975464,
      "tz": "America\/Chicago"
  },
  "K0R1": {
      "icao": "K0R1",
      "iata": "",
      "name": "Atmore Municipal Airport",
      "city": "Atmore",
      "state": "Alabama",
      "country": "US",
      "elevation": 287,
      "lat": 31.0161991119,
      "lon": -87.4468002319,
      "tz": "America\/Chicago"
  },
  "K0R3": {
      "icao": "K0R3",
      "iata": "",
      "name": "Abbeville Chris Crusta Memorial Airport",
      "city": "Abbeville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 16,
      "lat": 29.97579956,
      "lon": -92.084198,
      "tz": "America\/Chicago"
  },
  "K0R4": {
      "icao": "K0R4",
      "iata": "",
      "name": "Concordia Parish Airport",
      "city": "Vidalia",
      "state": "Louisiana",
      "country": "US",
      "elevation": 54,
      "lat": 31.5620002747,
      "lon": -91.5065002441,
      "tz": "America\/Chicago"
  },
  "K0R5": {
      "icao": "K0R5",
      "iata": "",
      "name": "David G Joyce Airport",
      "city": "Winnfield",
      "state": "Louisiana",
      "country": "US",
      "elevation": 146,
      "lat": 31.9636993408,
      "lon": -92.6603012085,
      "tz": "America\/Chicago"
  },
  "K0R6": {
      "icao": "K0R6",
      "iata": "",
      "name": "Hampton Municipal Airport",
      "city": "Hampton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 178,
      "lat": 33.5225982666,
      "lon": -92.4602966309,
      "tz": "America\/Chicago"
  },
  "K0S0": {
      "icao": "K0S0",
      "iata": "",
      "name": "Lind Airport",
      "city": "Lind",
      "state": "Washington",
      "country": "US",
      "elevation": 1507,
      "lat": 46.9767990112,
      "lon": -118.5869979858,
      "tz": "America\/Los_Angeles"
  },
  "K0S7": {
      "icao": "K0S7",
      "iata": "",
      "name": "Dorothy Scott Airport",
      "city": "Oroville",
      "state": "Washington",
      "country": "US",
      "elevation": 1064,
      "lat": 48.9589996338,
      "lon": -119.4120025635,
      "tz": "America\/Los_Angeles"
  },
  "K0S9": {
      "icao": "K0S9",
      "iata": "",
      "name": "Jefferson County International Airport",
      "city": "Port Townsend",
      "state": "Washington",
      "country": "US",
      "elevation": 108,
      "lat": 48.0537986755,
      "lon": -122.8109970093,
      "tz": "America\/Los_Angeles"
  },
  "K0V3": {
      "icao": "K0V3",
      "iata": "",
      "name": "Pioneer Village Field",
      "city": "Minden",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2160,
      "lat": 40.5149002075,
      "lon": -98.945602417,
      "tz": "America\/Chicago"
  },
  "K0V4": {
      "icao": "K0V4",
      "iata": "",
      "name": "Brookneal\/Campbell County Airport",
      "city": "Brookneal",
      "state": "Virginia",
      "country": "US",
      "elevation": 596,
      "lat": 37.1417007446,
      "lon": -79.0164031982,
      "tz": "America\/New_York"
  },
  "K0V6": {
      "icao": "K0V6",
      "iata": "",
      "name": "Mission Sioux Airport",
      "city": "Mission",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2605,
      "lat": 43.3069000244,
      "lon": -100.6279983521,
      "tz": "America\/Chicago"
  },
  "K0V7": {
      "icao": "K0V7",
      "iata": "",
      "name": "Kayenta Airport",
      "city": "Kayenta",
      "state": "Arizona",
      "country": "US",
      "elevation": 5710,
      "lat": 36.7097015381,
      "lon": -110.2369995117,
      "tz": "America\/Denver"
  },
  "K0VG": {
      "icao": "K0VG",
      "iata": "",
      "name": "Lee County Airport",
      "city": "Jonesville",
      "state": "Virginia",
      "country": "US",
      "elevation": 1411,
      "lat": 36.6540985107,
      "lon": -83.2177963257,
      "tz": "America\/New_York"
  },
  "K0W3": {
      "icao": "K0W3",
      "iata": "",
      "name": "Harford County Airport",
      "city": "Churchville",
      "state": "Maryland",
      "country": "US",
      "elevation": 409,
      "lat": 39.5667991638,
      "lon": -76.2024002075,
      "tz": "America\/New_York"
  },
  "K10C": {
      "icao": "K10C",
      "iata": "",
      "name": "Galt Field",
      "city": "Greenwood\/Wonder Lake",
      "state": "Illinois",
      "country": "US",
      "elevation": 875,
      "lat": 42.4029006958,
      "lon": -88.3750991821,
      "tz": "America\/Chicago"
  },
  "K10G": {
      "icao": "K10G",
      "iata": "",
      "name": "Holmes County Airport",
      "city": "Millersburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 1218,
      "lat": 40.5372009277,
      "lon": -81.9543991089,
      "tz": "America\/New_York"
  },
  "K10U": {
      "icao": "K10U",
      "iata": "",
      "name": "Owyhee Airport",
      "city": "Owyhee",
      "state": "Nevada",
      "country": "US",
      "elevation": 5374,
      "lat": 41.9532012939,
      "lon": -116.18800354,
      "tz": "America\/Los_Angeles"
  },
  "K11A": {
      "icao": "K11A",
      "iata": "",
      "name": "Clayton Municipal Airport",
      "city": "Clayton",
      "state": "Alabama",
      "country": "US",
      "elevation": 435,
      "lat": 31.8833007813,
      "lon": -85.4849014282,
      "tz": "America\/Chicago"
  },
  "K11R": {
      "icao": "K11R",
      "iata": "",
      "name": "Brenham Municipal Airport",
      "city": "Brenham",
      "state": "Texas",
      "country": "US",
      "elevation": 307,
      "lat": 30.21899986,
      "lon": -96.3742981,
      "tz": "America\/Chicago"
  },
  "K11V": {
      "icao": "K11V",
      "iata": "",
      "name": "Easton\/Valley View Airport",
      "city": "Greeley",
      "state": "Colorado",
      "country": "US",
      "elevation": 4820,
      "lat": 40.3305015564,
      "lon": -104.60900116,
      "tz": "America\/Denver"
  },
  "K12D": {
      "icao": "K12D",
      "iata": "",
      "name": "Tower Municipal Airport",
      "city": "Tower",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1369,
      "lat": 47.8182983398,
      "lon": -92.2917022705,
      "tz": "America\/Chicago"
  },
  "K12G": {
      "icao": "K12G",
      "iata": "",
      "name": "Shelby Community Airport",
      "city": "Shelby",
      "state": "Ohio",
      "country": "US",
      "elevation": 1120,
      "lat": 40.8728981018,
      "lon": -82.6974029541,
      "tz": "America\/New_York"
  },
  "K12J": {
      "icao": "K12J",
      "iata": "",
      "name": "Brewton Municipal Airport",
      "city": "Brewton",
      "state": "Alabama",
      "country": "US",
      "elevation": 99,
      "lat": 31.0506000519,
      "lon": -87.0655975342,
      "tz": "America\/Chicago"
  },
  "K12K": {
      "icao": "K12K",
      "iata": "",
      "name": "Superior Municipal Airport",
      "city": "Superior",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1691,
      "lat": 40.0463981628,
      "lon": -98.0600967407,
      "tz": "America\/Chicago"
  },
  "K12N": {
      "icao": "K12N",
      "iata": "",
      "name": "Aeroflex-Andover Airport",
      "city": "Andover",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 583,
      "lat": 41.0085983276,
      "lon": -74.7379989624,
      "tz": "America\/New_York"
  },
  "K12V": {
      "icao": "K12V",
      "iata": "",
      "name": "Ona Airpark",
      "city": "Milton",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 572,
      "lat": 38.4408988953,
      "lon": -82.2007980347,
      "tz": "America\/New_York"
  },
  "K12Y": {
      "icao": "K12Y",
      "iata": "",
      "name": "Le Sueur Municipal Airport",
      "city": "Le Sueur",
      "state": "Minnesota",
      "country": "US",
      "elevation": 868,
      "lat": 44.4375,
      "lon": -93.9126968384,
      "tz": "America\/Chicago"
  },
  "K13C": {
      "icao": "K13C",
      "iata": "",
      "name": "Lakeview-Airport-Griffith Field",
      "city": "Lakeview",
      "state": "Michigan",
      "country": "US",
      "elevation": 969,
      "lat": 43.4520988464,
      "lon": -85.2648010254,
      "tz": "America\/Detroit"
  },
  "K13K": {
      "icao": "K13K",
      "iata": "",
      "name": "Eureka Municipal Airport",
      "city": "Eureka",
      "state": "Kansas",
      "country": "US",
      "elevation": 1206,
      "lat": 37.851600647,
      "lon": -96.2917022705,
      "tz": "America\/Chicago"
  },
  "K14A": {
      "icao": "K14A",
      "iata": "",
      "name": "Lake Norman Airpark",
      "city": "Mooresville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 839,
      "lat": 35.6129989624,
      "lon": -80.8993988037,
      "tz": "America\/New_York"
  },
  "K14F": {
      "icao": "K14F",
      "iata": "",
      "name": "Hamlin Municipal Airport",
      "city": "Hamlin",
      "state": "Texas",
      "country": "US",
      "elevation": 1753,
      "lat": 32.8501014709,
      "lon": -100.1380004883,
      "tz": "America\/Chicago"
  },
  "K14G": {
      "icao": "K14G",
      "iata": "",
      "name": "Fremont Airport",
      "city": "Fremont",
      "state": "Ohio",
      "country": "US",
      "elevation": 663,
      "lat": 41.3330993652,
      "lon": -83.1612014771,
      "tz": "America\/New_York"
  },
  "K14J": {
      "icao": "K14J",
      "iata": "",
      "name": "Carl Folsom Airport",
      "city": "Elba",
      "state": "Alabama",
      "country": "US",
      "elevation": 258,
      "lat": 31.4099998474,
      "lon": -86.0903015137,
      "tz": "America\/Chicago"
  },
  "K14M": {
      "icao": "K14M",
      "iata": "",
      "name": "Hollandale Municipal Airport",
      "city": "Hollandale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 114,
      "lat": 33.182598114,
      "lon": -90.8307037354,
      "tz": "America\/Chicago"
  },
  "K14Y": {
      "icao": "K14Y",
      "iata": "",
      "name": "Todd Field",
      "city": "Long Prairie",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1333,
      "lat": 45.898601532,
      "lon": -94.8739013672,
      "tz": "America\/Chicago"
  },
  "K15": {
      "icao": "K15",
      "iata": "",
      "name": "Grand Glaize-Osage Beach Airport",
      "city": "Osage Beach, Missouri",
      "state": "Missouri",
      "country": "US",
      "elevation": 876,
      "lat": 38.1104444,
      "lon": -92.6805556,
      "tz": "America\/Chicago"
  },
  "K15F": {
      "icao": "K15F",
      "iata": "",
      "name": "Haskell Municipal Airport",
      "city": "Haskell",
      "state": "Texas",
      "country": "US",
      "elevation": 1625,
      "lat": 33.1915016174,
      "lon": -99.7179031372,
      "tz": "America\/Chicago"
  },
  "K15J": {
      "icao": "K15J",
      "iata": "",
      "name": "Cook County Airport",
      "city": "Adel",
      "state": "Georgia",
      "country": "US",
      "elevation": 236,
      "lat": 31.1378002167,
      "lon": -83.4533004761,
      "tz": "America\/New_York"
  },
  "K15M": {
      "icao": "K15M",
      "iata": "",
      "name": "Iuka Airport",
      "city": "Iuka",
      "state": "Mississippi",
      "country": "US",
      "elevation": 630,
      "lat": 34.7723007202,
      "lon": -88.1659011841,
      "tz": "America\/Chicago"
  },
  "K16D": {
      "icao": "K16D",
      "iata": "",
      "name": "Perham Municipal Airport",
      "city": "Perham",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1371,
      "lat": 46.6040992737,
      "lon": -95.6044998169,
      "tz": "America\/Chicago"
  },
  "K16G": {
      "icao": "K16G",
      "iata": "",
      "name": "Seneca County Airport",
      "city": "Tiffin",
      "state": "Ohio",
      "country": "US",
      "elevation": 786,
      "lat": 41.0941009521,
      "lon": -83.2125015259,
      "tz": "America\/New_York"
  },
  "K16J": {
      "icao": "K16J",
      "iata": "",
      "name": "Dawson Municipal Airport",
      "city": "Dawson",
      "state": "Georgia",
      "country": "US",
      "elevation": 333,
      "lat": 31.7432994843,
      "lon": -84.4192962646,
      "tz": "America\/New_York"
  },
  "K17G": {
      "icao": "K17G",
      "iata": "",
      "name": "Port-Bucyrus-Crawford County Airport",
      "city": "Bucyrus",
      "state": "Ohio",
      "country": "US",
      "elevation": 1003,
      "lat": 40.7816009521,
      "lon": -82.9748001099,
      "tz": "America\/New_York"
  },
  "K17J": {
      "icao": "K17J",
      "iata": "",
      "name": "Donalsonville Municipal Airport",
      "city": "Donalsonville",
      "state": "Georgia",
      "country": "US",
      "elevation": 148,
      "lat": 31.00690079,
      "lon": -84.87760162,
      "tz": "America\/New_York"
  },
  "K17K": {
      "icao": "K17K",
      "iata": "",
      "name": "Boise City Airport",
      "city": "Boise City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 4178,
      "lat": 36.7742996216,
      "lon": -102.5100021362,
      "tz": "America\/Chicago"
  },
  "K17M": {
      "icao": "K17M",
      "iata": "",
      "name": "Magee Municipal Airport",
      "city": "Magee",
      "state": "Mississippi",
      "country": "US",
      "elevation": 555,
      "lat": 31.8626995087,
      "lon": -89.8005981445,
      "tz": "America\/Chicago"
  },
  "K17N": {
      "icao": "K17N",
      "iata": "",
      "name": "Cross Keys Airport",
      "city": "Cross Keys",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 162,
      "lat": 39.7055015564,
      "lon": -75.0329971313,
      "tz": "America\/New_York"
  },
  "K17V": {
      "icao": "K17V",
      "iata": "",
      "name": "Haxtun Municipal Airport",
      "city": "Haxtun",
      "state": "Colorado",
      "country": "US",
      "elevation": 4035,
      "lat": 40.625,
      "lon": -102.6029968262,
      "tz": "America\/Denver"
  },
  "K18A": {
      "icao": "K18A",
      "iata": "",
      "name": "Franklin County Airport",
      "city": "Canon",
      "state": "Georgia",
      "country": "US",
      "elevation": 890,
      "lat": 34.34009933,
      "lon": -83.13349915,
      "tz": "America\/New_York"
  },
  "K18I": {
      "icao": "K18I",
      "iata": "",
      "name": "Mc Creary County Airport",
      "city": "Pine Knot",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1370,
      "lat": 36.6958999634,
      "lon": -84.3916015625,
      "tz": "America\/New_York"
  },
  "K19M": {
      "icao": "K19M",
      "iata": "",
      "name": "C. A. Moore Airport",
      "city": "Lexington",
      "state": "Mississippi",
      "country": "US",
      "elevation": 340,
      "lat": 33.1254997253,
      "lon": -90.0255966187,
      "tz": "America\/Chicago"
  },
  "K19N": {
      "icao": "K19N",
      "iata": "",
      "name": "Camden County Airport",
      "city": "Berlin",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 149,
      "lat": 39.77840042,
      "lon": -74.94779968,
      "tz": "America\/New_York"
  },
  "K19S": {
      "icao": "K19S",
      "iata": "",
      "name": "Sublette Flying Club Airport",
      "city": "Sublette",
      "state": "Kansas",
      "country": "US",
      "elevation": 2908,
      "lat": 37.49140167,
      "lon": -100.8300018,
      "tz": "America\/Chicago"
  },
  "K1A0": {
      "icao": "K1A0",
      "iata": "",
      "name": "Dallas Bay Sky Park Airport",
      "city": "Chattanooga",
      "state": "Tennessee",
      "country": "US",
      "elevation": 690,
      "lat": 35.1875991821,
      "lon": -85.177696228,
      "tz": "America\/New_York"
  },
  "K1A3": {
      "icao": "K1A3",
      "iata": "",
      "name": "Martin Campbell Field",
      "city": "Copperhill",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1790,
      "lat": 35.01620102,
      "lon": -84.34629822,
      "tz": "America\/New_York"
  },
  "K1A4": {
      "icao": "K1A4",
      "iata": "",
      "name": "Logan Field",
      "city": "Samson",
      "state": "Alabama",
      "country": "US",
      "elevation": 200,
      "lat": 31.1028003693,
      "lon": -86.0622024536,
      "tz": "America\/Chicago"
  },
  "K1A5": {
      "icao": "K1A5",
      "iata": "",
      "name": "Macon County Airport",
      "city": "Franklin",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2020,
      "lat": 35.2225990295,
      "lon": -83.4189987183,
      "tz": "America\/New_York"
  },
  "K1A6": {
      "icao": "K1A6",
      "iata": "",
      "name": "Middlesboro-Bell County Airport",
      "city": "Middlesboro",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1154,
      "lat": 36.6105995178,
      "lon": -83.7373962402,
      "tz": "America\/New_York"
  },
  "K1A7": {
      "icao": "K1A7",
      "iata": "",
      "name": "Jackson County Airport",
      "city": "Gainesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 515,
      "lat": 36.4002990723,
      "lon": -85.6417007446,
      "tz": "America\/Chicago"
  },
  "K1A9": {
      "icao": "K1A9",
      "iata": "",
      "name": "Prattville - Grouby Field",
      "city": "Prattville",
      "state": "Alabama",
      "country": "US",
      "elevation": 225,
      "lat": 32.4387016296,
      "lon": -86.5127029419,
      "tz": "America\/Chicago"
  },
  "K1B0": {
      "icao": "K1B0",
      "iata": "",
      "name": "Dexter Regional Airport",
      "city": "Dexter",
      "state": "Maine",
      "country": "US",
      "elevation": 533,
      "lat": 45.0041007996,
      "lon": -69.2369995117,
      "tz": "America\/New_York"
  },
  "K1B6": {
      "icao": "K1B6",
      "iata": "",
      "name": "Hopedale Industrial Park Airport",
      "city": "Hopedale",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 269,
      "lat": 42.1064987183,
      "lon": -71.5101013184,
      "tz": "America\/New_York"
  },
  "K1B9": {
      "icao": "K1B9",
      "iata": "",
      "name": "Mansfield Municipal Airport",
      "city": "Mansfield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 122,
      "lat": 42.0000991821,
      "lon": -71.1968002319,
      "tz": "America\/New_York"
  },
  "K1C1": {
      "icao": "K1C1",
      "iata": "",
      "name": "Paxton Airport",
      "city": "Paxton",
      "state": "Illinois",
      "country": "US",
      "elevation": 779,
      "lat": 40.4490013123,
      "lon": -88.1277008057,
      "tz": "America\/Chicago"
  },
  "K1C5": {
      "icao": "K1C5",
      "iata": "",
      "name": "Bolingbrook's Clow International Airport",
      "city": "Bolingbrook",
      "state": "Illinois",
      "country": "US",
      "elevation": 670,
      "lat": 41.6959991455,
      "lon": -88.1292037964,
      "tz": "America\/Chicago"
  },
  "K1D1": {
      "icao": "K1D1",
      "iata": "",
      "name": "Milbank Municipal Airport",
      "city": "Milbank",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1118,
      "lat": 45.23049927,
      "lon": -96.56600189,
      "tz": "America\/Chicago"
  },
  "K1D3": {
      "icao": "K1D3",
      "iata": "",
      "name": "Platte Municipal Airport",
      "city": "Platte",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1618,
      "lat": 43.403301239,
      "lon": -98.829498291,
      "tz": "America\/Chicago"
  },
  "K1D7": {
      "icao": "K1D7",
      "iata": "",
      "name": "The Sigurd Anderson Airport",
      "city": "Webster",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1854,
      "lat": 45.2925987244,
      "lon": -97.5138015747,
      "tz": "America\/Chicago"
  },
  "K1D8": {
      "icao": "K1D8",
      "iata": "",
      "name": "Redfield Municipal Airport",
      "city": "Redfield",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1307,
      "lat": 44.8624992371,
      "lon": -98.5295028687,
      "tz": "America\/Chicago"
  },
  "K1F0": {
      "icao": "K1F0",
      "iata": "",
      "name": "Ardmore Downtown Executive Airport",
      "city": "Ardmore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 845,
      "lat": 34.1469722,
      "lon": -97.1226667,
      "tz": "America\/Chicago"
  },
  "K1F4": {
      "icao": "K1F4",
      "iata": "",
      "name": "Madill Municipal Airport",
      "city": "Madill",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 890,
      "lat": 34.1403999329,
      "lon": -96.81199646,
      "tz": "America\/Chicago"
  },
  "K1F5": {
      "icao": "K1F5",
      "iata": "",
      "name": "Hoxie-Sheridan County Airport",
      "city": "Hoxie",
      "state": "Kansas",
      "country": "US",
      "elevation": 2733,
      "lat": 39.3648986816,
      "lon": -100.439002991,
      "tz": "America\/Chicago"
  },
  "K1G0": {
      "icao": "K1G0",
      "iata": "",
      "name": "Wood County Airport",
      "city": "Bowling Green",
      "state": "Ohio",
      "country": "US",
      "elevation": 673,
      "lat": 41.3909988403,
      "lon": -83.6300964355,
      "tz": "America\/New_York"
  },
  "K1G1": {
      "icao": "K1G1",
      "iata": "",
      "name": "Elyria Airport",
      "city": "Elyria",
      "state": "Ohio",
      "country": "US",
      "elevation": 758,
      "lat": 41.3315010071,
      "lon": -82.1003036499,
      "tz": "America\/New_York"
  },
  "K1G3": {
      "icao": "K1G3",
      "iata": "",
      "name": "Kent State University Airport",
      "city": "Kent",
      "state": "Ohio",
      "country": "US",
      "elevation": 1134,
      "lat": 41.15140152,
      "lon": -81.4151001,
      "tz": "America\/New_York"
  },
  "K1G4": {
      "icao": "K1G4",
      "iata": "GCW",
      "name": "Grand Canyon West Airport",
      "city": "Peach Springs",
      "state": "Arizona",
      "country": "US",
      "elevation": 4825,
      "lat": 35.990398407,
      "lon": -113.816001892,
      "tz": "America\/Phoenix"
  },
  "K1G5": {
      "icao": "K1G5",
      "iata": "",
      "name": "Medina Municipal Airport",
      "city": "Medina",
      "state": "Ohio",
      "country": "US",
      "elevation": 1190,
      "lat": 41.131401062,
      "lon": -81.7649002075,
      "tz": "America\/New_York"
  },
  "K1H0": {
      "icao": "K1H0",
      "iata": "",
      "name": "Creve Coeur Airport",
      "city": "St Louis",
      "state": "Missouri",
      "country": "US",
      "elevation": 462,
      "lat": 38.7273333,
      "lon": -90.5084167,
      "tz": "America\/Chicago"
  },
  "K1H2": {
      "icao": "K1H2",
      "iata": "",
      "name": "Effingham County Memorial Airport",
      "city": "Effingham",
      "state": "Illinois",
      "country": "US",
      "elevation": 587,
      "lat": 39.070400238,
      "lon": -88.5335006714,
      "tz": "America\/Chicago"
  },
  "K1H3": {
      "icao": "K1H3",
      "iata": "",
      "name": "Linn State Technical College Airport",
      "city": "Linn",
      "state": "Missouri",
      "country": "US",
      "elevation": 952,
      "lat": 38.4715003967,
      "lon": -91.8152999878,
      "tz": "America\/Chicago"
  },
  "K1H5": {
      "icao": "K1H5",
      "iata": "",
      "name": "Willow Springs Memorial Airport",
      "city": "Willow Springs",
      "state": "Missouri",
      "country": "US",
      "elevation": 1247,
      "lat": 36.9896011353,
      "lon": -91.9541015625,
      "tz": "America\/Chicago"
  },
  "K1H8": {
      "icao": "K1H8",
      "iata": "",
      "name": "Casey Municipal Airport",
      "city": "Casey",
      "state": "Illinois",
      "country": "US",
      "elevation": 654,
      "lat": 39.3025016785,
      "lon": -88.0040969849,
      "tz": "America\/Chicago"
  },
  "K1I5": {
      "icao": "K1I5",
      "iata": "",
      "name": "Freehold Airport",
      "city": "Freehold",
      "state": "New-York",
      "country": "US",
      "elevation": 440,
      "lat": 42.3642997742,
      "lon": -74.0660018921,
      "tz": "America\/New_York"
  },
  "K1I7": {
      "icao": "K1I7",
      "iata": "",
      "name": "Clinton Airport",
      "city": "Clinton",
      "state": "Indiana",
      "country": "US",
      "elevation": 526,
      "lat": 39.7125015259,
      "lon": -87.4013977051,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K1J0": {
      "icao": "K1J0",
      "iata": "",
      "name": "Tri-County Airport",
      "city": "Bonifay",
      "state": "Florida",
      "country": "US",
      "elevation": 85,
      "lat": 30.8458003998,
      "lon": -85.6014022827,
      "tz": "America\/Chicago"
  },
  "K1J6": {
      "icao": "K1J6",
      "iata": "",
      "name": "Bob Lee Flight Strip",
      "city": "Deland",
      "state": "Florida",
      "country": "US",
      "elevation": 94,
      "lat": 29.1044006348,
      "lon": -81.3136978149,
      "tz": "America\/New_York"
  },
  "K1K1": {
      "icao": "K1K1",
      "iata": "",
      "name": "Lloyd Stearman Field Airport",
      "city": "Benton",
      "state": "Kansas",
      "country": "US",
      "elevation": 1364,
      "lat": 37.7744444,
      "lon": -97.1132222,
      "tz": "America\/Chicago"
  },
  "K1K2": {
      "icao": "K1K2",
      "iata": "",
      "name": "Lindsay Municipal Airport",
      "city": "Lindsay",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 968,
      "lat": 34.8493003845,
      "lon": -97.5852966309,
      "tz": "America\/Chicago"
  },
  "K1K4": {
      "icao": "K1K4",
      "iata": "",
      "name": "David Jay Perry Airport",
      "city": "Goldsby",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1168,
      "lat": 35.1551017761,
      "lon": -97.4703979492,
      "tz": "America\/Chicago"
  },
  "K1K7": {
      "icao": "K1K7",
      "iata": "",
      "name": "Fredonia Airport",
      "city": "Fredonia",
      "state": "Kansas",
      "country": "US",
      "elevation": 880,
      "lat": 37.5792007446,
      "lon": -95.8377990723,
      "tz": "America\/Chicago"
  },
  "K1K9": {
      "icao": "K1K9",
      "iata": "",
      "name": "Satanta Municipal Airport",
      "city": "Satanta",
      "state": "Kansas",
      "country": "US",
      "elevation": 2976,
      "lat": 37.456401825,
      "lon": -100.9840011597,
      "tz": "America\/Chicago"
  },
  "K1L0": {
      "icao": "K1L0",
      "iata": "",
      "name": "St John The Baptist Parish Airport",
      "city": "Reserve",
      "state": "Louisiana",
      "country": "US",
      "elevation": 7,
      "lat": 30.0869998932,
      "lon": -90.5826034546,
      "tz": "America\/Chicago"
  },
  "K1L3": {
      "icao": "K1L3",
      "iata": "",
      "name": "Searchlight Airport",
      "city": "Searchlight",
      "state": "Nevada",
      "country": "US",
      "elevation": 3410,
      "lat": 35.4444007874,
      "lon": -114.908996582,
      "tz": "America\/Los_Angeles"
  },
  "K1L7": {
      "icao": "K1L7",
      "iata": "",
      "name": "Escalante Municipal Airport",
      "city": "Escalante",
      "state": "Utah",
      "country": "US",
      "elevation": 5733,
      "lat": 37.745300293,
      "lon": -111.5699996948,
      "tz": "America\/Denver"
  },
  "K1L8": {
      "icao": "K1L8",
      "iata": "",
      "name": "General Dick Stout Field",
      "city": "Hurricane",
      "state": "Utah",
      "country": "US",
      "elevation": 3347,
      "lat": 37.1389007568,
      "lon": -113.3059997559,
      "tz": "America\/Denver"
  },
  "K1L9": {
      "icao": "K1L9",
      "iata": "",
      "name": "Parowan Airport",
      "city": "Parowan",
      "state": "Utah",
      "country": "US",
      "elevation": 5930,
      "lat": 37.8596992493,
      "lon": -112.8160018921,
      "tz": "America\/Denver"
  },
  "K1M2": {
      "icao": "K1M2",
      "iata": "",
      "name": "Belzoni Municipal Airport",
      "city": "Belzoni",
      "state": "Mississippi",
      "country": "US",
      "elevation": 110,
      "lat": 33.145198822,
      "lon": -90.515296936,
      "tz": "America\/Chicago"
  },
  "K1M4": {
      "icao": "K1M4",
      "iata": "",
      "name": "Posey Field",
      "city": "Haleyville",
      "state": "Alabama",
      "country": "US",
      "elevation": 930,
      "lat": 34.2803001404,
      "lon": -87.600402832,
      "tz": "America\/Chicago"
  },
  "K1M5": {
      "icao": "K1M5",
      "iata": "",
      "name": "Portland Municipal Airport",
      "city": "Portland",
      "state": "Tennessee",
      "country": "US",
      "elevation": 818,
      "lat": 36.5928001404,
      "lon": -86.4766998291,
      "tz": "America\/Chicago"
  },
  "K1M9": {
      "icao": "K1M9",
      "iata": "",
      "name": "Lake Barkley State Park Airport",
      "city": "Cadiz",
      "state": "Kentucky",
      "country": "US",
      "elevation": 570,
      "lat": 36.8177986145,
      "lon": -87.9075012207,
      "tz": "America\/Chicago"
  },
  "K1MO": {
      "icao": "K1MO",
      "iata": "",
      "name": "Mountain Grove Memorial Airport",
      "city": "Mountain Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 1476,
      "lat": 37.1207008362,
      "lon": -92.3112030029,
      "tz": "America\/Chicago"
  },
  "K1N1": {
      "icao": "K1N1",
      "iata": "",
      "name": "Sandia Airpark Estates East Airport",
      "city": "Edgewood",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6550,
      "lat": 35.0945014954,
      "lon": -106.1669998169,
      "tz": "America\/Denver"
  },
  "K1O1": {
      "icao": "K1O1",
      "iata": "",
      "name": "Grandfield Municipal Airport",
      "city": "Grandfield",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1128,
      "lat": 34.2375984192,
      "lon": -98.7419967651,
      "tz": "America\/Chicago"
  },
  "K1O2": {
      "icao": "K1O2",
      "iata": "",
      "name": "Lampson Field",
      "city": "Lakeport",
      "state": "California",
      "country": "US",
      "elevation": 1379,
      "lat": 38.9906005859,
      "lon": -122.901000977,
      "tz": "America\/Los_Angeles"
  },
  "K1O3": {
      "icao": "K1O3",
      "iata": "",
      "name": "Lodi Airport",
      "city": "Lodi",
      "state": "California",
      "country": "US",
      "elevation": 60,
      "lat": 38.20240021,
      "lon": -121.2679977,
      "tz": "America\/Los_Angeles"
  },
  "K1O4": {
      "icao": "K1O4",
      "iata": "",
      "name": "Thomas Municipal Airport",
      "city": "Thomas",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1731,
      "lat": 35.7344017029,
      "lon": -98.7302017212,
      "tz": "America\/Chicago"
  },
  "K1O5": {
      "icao": "K1O5",
      "iata": "RKC",
      "name": "Montague-Yreka Rohrer Field",
      "city": "Montague",
      "state": "California",
      "country": "US",
      "elevation": 2527,
      "lat": 41.7304000854,
      "lon": -122.54599762,
      "tz": "America\/Los_Angeles"
  },
  "K1O8": {
      "icao": "K1O8",
      "iata": "",
      "name": "Tipton Municipal Airport",
      "city": "Tipton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1248,
      "lat": 34.4585990906,
      "lon": -99.1713027954,
      "tz": "America\/Chicago"
  },
  "K1Q2": {
      "icao": "K1Q2",
      "iata": "",
      "name": "Spaulding Airport",
      "city": "Susanville",
      "state": "California",
      "country": "US",
      "elevation": 5116,
      "lat": 40.6502990723,
      "lon": -120.7689971924,
      "tz": "America\/Los_Angeles"
  },
  "K1Q4": {
      "icao": "K1Q4",
      "iata": "",
      "name": "New Jerusalem Airport",
      "city": "Tracy",
      "state": "California",
      "country": "US",
      "elevation": 62,
      "lat": 37.6791000366,
      "lon": -121.3000030518,
      "tz": "America\/Los_Angeles"
  },
  "K1R1": {
      "icao": "K1R1",
      "iata": "",
      "name": "Jena Airport",
      "city": "Jena",
      "state": "Louisiana",
      "country": "US",
      "elevation": 212,
      "lat": 31.6569004059,
      "lon": -92.1575012207,
      "tz": "America\/Chicago"
  },
  "K1R4": {
      "icao": "K1R4",
      "iata": "",
      "name": "Woodworth Airport",
      "city": "Woodworth",
      "state": "Louisiana",
      "country": "US",
      "elevation": 140,
      "lat": 31.1263999939,
      "lon": -92.5014038086,
      "tz": "America\/Chicago"
  },
  "K1R7": {
      "icao": "K1R7",
      "iata": "",
      "name": "Brookhaven-Lincoln County Airport",
      "city": "Brookhaven",
      "state": "Mississippi",
      "country": "US",
      "elevation": 489,
      "lat": 31.6058006287,
      "lon": -90.4093017578,
      "tz": "America\/Chicago"
  },
  "K1R8": {
      "icao": "K1R8",
      "iata": "",
      "name": "Bay Minette Municipal Airport",
      "city": "Bay Minette",
      "state": "Alabama",
      "country": "US",
      "elevation": 248,
      "lat": 30.8703994751,
      "lon": -87.8192977905,
      "tz": "America\/Chicago"
  },
  "K1S3": {
      "icao": "K1S3",
      "iata": "",
      "name": "Tillitt Field",
      "city": "Forsyth",
      "state": "Montana",
      "country": "US",
      "elevation": 2727,
      "lat": 46.2710990906,
      "lon": -106.6240005493,
      "tz": "America\/Denver"
  },
  "K1S5": {
      "icao": "K1S5",
      "iata": "",
      "name": "Sunnyside Municipal Airport",
      "city": "Sunnyside",
      "state": "Washington",
      "country": "US",
      "elevation": 768,
      "lat": 46.32709885,
      "lon": -119.9700012,
      "tz": "America\/Los_Angeles"
  },
  "K1S9": {
      "icao": "K1S9",
      "iata": "",
      "name": "Sand Canyon Airport",
      "city": "Chewelah",
      "state": "Washington",
      "country": "US",
      "elevation": 2084,
      "lat": 48.3140983582,
      "lon": -117.7429962158,
      "tz": "America\/Los_Angeles"
  },
  "K1T7": {
      "icao": "K1T7",
      "iata": "",
      "name": "Kestrel Airpark",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 1250,
      "lat": 29.8127002716,
      "lon": -98.4253005981,
      "tz": "America\/Chicago"
  },
  "K1U2": {
      "icao": "K1U2",
      "iata": "",
      "name": "Mud Lake\/West Jefferson County\/ Airport",
      "city": "Mud Lake",
      "state": "Idaho",
      "country": "US",
      "elevation": 4787,
      "lat": 43.8482017517,
      "lon": -112.499000549,
      "tz": "America\/Boise"
  },
  "K1U7": {
      "icao": "K1U7",
      "iata": "",
      "name": "Bear Lake County Airport",
      "city": "Paris",
      "state": "Idaho",
      "country": "US",
      "elevation": 5928,
      "lat": 42.2472000122,
      "lon": -111.3379974365,
      "tz": "America\/Boise"
  },
  "K1V0": {
      "icao": "K1V0",
      "iata": "",
      "name": "Navajo Lake Airport",
      "city": "Navajo Dam",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6475,
      "lat": 36.8083000183,
      "lon": -107.6510009766,
      "tz": "America\/Denver"
  },
  "K1V2": {
      "icao": "K1V2",
      "iata": "",
      "name": "Grant County Airport",
      "city": "Hyannis",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3710,
      "lat": 42.0093994141,
      "lon": -101.7689971924,
      "tz": "America\/Denver"
  },
  "K1V6": {
      "icao": "K1V6",
      "iata": "",
      "name": "Fremont County Airport",
      "city": "Canon City",
      "state": "Colorado",
      "country": "US",
      "elevation": 5439,
      "lat": 38.4280014038,
      "lon": -105.1060028076,
      "tz": "America\/Denver"
  },
  "K1V8": {
      "icao": "K1V8",
      "iata": "",
      "name": "Leach Airport",
      "city": "Center",
      "state": "Colorado",
      "country": "US",
      "elevation": 7598,
      "lat": 37.7846984863,
      "lon": -106.0380020142,
      "tz": "America\/Denver"
  },
  "K1X1": {
      "icao": "K1X1",
      "iata": "",
      "name": "Higgins-Lipscomb County Airport",
      "city": "Higgins",
      "state": "Texas",
      "country": "US",
      "elevation": 2566,
      "lat": 36.1058998108,
      "lon": -100.026000977,
      "tz": "America\/Chicago"
  },
  "K20A": {
      "icao": "K20A",
      "iata": "",
      "name": "Robbins Field",
      "city": "Oneonta",
      "state": "Alabama",
      "country": "US",
      "elevation": 1140,
      "lat": 33.9723014832,
      "lon": -86.3794021606,
      "tz": "America\/Chicago"
  },
  "K20M": {
      "icao": "K20M",
      "iata": "",
      "name": "Macon Municipal Airport",
      "city": "Macon",
      "state": "Mississippi",
      "country": "US",
      "elevation": 238,
      "lat": 33.1334991455,
      "lon": -88.5355987549,
      "tz": "America\/Chicago"
  },
  "K20N": {
      "icao": "K20N",
      "iata": "",
      "name": "Kingston-Ulster Airport",
      "city": "Kingston",
      "state": "New-York",
      "country": "US",
      "elevation": 149,
      "lat": 41.9852981567,
      "lon": -73.9641036987,
      "tz": "America\/New_York"
  },
  "K20R": {
      "icao": "K20R",
      "iata": "",
      "name": "Crystal City Municipal Airport",
      "city": "Crystal City",
      "state": "Texas",
      "country": "US",
      "elevation": 608,
      "lat": 28.6977996826,
      "lon": -99.8178024292,
      "tz": "America\/Chicago"
  },
  "K20U": {
      "icao": "K20U",
      "iata": "",
      "name": "Beach Airport",
      "city": "Beach",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2756,
      "lat": 46.925201416,
      "lon": -103.9820022583,
      "tz": "America\/Denver"
  },
  "K20V": {
      "icao": "K20V",
      "iata": "",
      "name": "Mc Elroy Airfield",
      "city": "Kremmling",
      "state": "Colorado",
      "country": "US",
      "elevation": 7411,
      "lat": 40.0536994934,
      "lon": -106.369003296,
      "tz": "America\/Denver"
  },
  "K21D": {
      "icao": "K21D",
      "iata": "",
      "name": "Lake Elmo Airport",
      "city": "St Paul",
      "state": "Minnesota",
      "country": "US",
      "elevation": 933,
      "lat": 44.99750137,
      "lon": -92.85569763,
      "tz": "America\/Chicago"
  },
  "K21F": {
      "icao": "K21F",
      "iata": "",
      "name": "Jacksboro Municipal Airport",
      "city": "Jacksboro",
      "state": "Texas",
      "country": "US",
      "elevation": 1055,
      "lat": 33.2286987305,
      "lon": -98.146697998,
      "tz": "America\/Chicago"
  },
  "K22F": {
      "icao": "K22F",
      "iata": "",
      "name": "Kent County Airport",
      "city": "Jayton",
      "state": "Texas",
      "country": "US",
      "elevation": 2006,
      "lat": 33.2293014526,
      "lon": -100.569000244,
      "tz": "America\/Chicago"
  },
  "K22I": {
      "icao": "K22I",
      "iata": "",
      "name": "Vinton County Airport",
      "city": "Mc Arthur",
      "state": "Ohio",
      "country": "US",
      "elevation": 958,
      "lat": 39.3280982971,
      "lon": -82.4418029785,
      "tz": "America\/New_York"
  },
  "K22M": {
      "icao": "K22M",
      "iata": "",
      "name": "Pontotoc County Airport",
      "city": "Pontotoc",
      "state": "Mississippi",
      "country": "US",
      "elevation": 440,
      "lat": 34.2759017944,
      "lon": -89.0383987427,
      "tz": "America\/Chicago"
  },
  "K22N": {
      "icao": "K22N",
      "iata": "",
      "name": "Jake Arner Memorial Airport",
      "city": "Lehighton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 534,
      "lat": 40.8095016479,
      "lon": -75.7614974976,
      "tz": "America\/New_York"
  },
  "K22S": {
      "icao": "K22S",
      "iata": "",
      "name": "Paisley Airport",
      "city": "Paisley",
      "state": "Oregon",
      "country": "US",
      "elevation": 4395,
      "lat": 42.7178993225,
      "lon": -120.56300354,
      "tz": "America\/Los_Angeles"
  },
  "K22W": {
      "icao": "K22W",
      "iata": "",
      "name": "Transylvania County Airport",
      "city": "Brevard",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2110,
      "lat": 35.2703018188,
      "lon": -82.644203186,
      "tz": "America\/New_York"
  },
  "K23R": {
      "icao": "K23R",
      "iata": "",
      "name": "Devine Municipal Airport",
      "city": "Devine",
      "state": "Texas",
      "country": "US",
      "elevation": 703,
      "lat": 29.1383991241,
      "lon": -98.9419021606,
      "tz": "America\/Chicago"
  },
  "K24A": {
      "icao": "K24A",
      "iata": "",
      "name": "Jackson County Airport",
      "city": "Sylva",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2857,
      "lat": 35.317401886,
      "lon": -83.2098999023,
      "tz": "America\/New_York"
  },
  "K24F": {
      "icao": "K24F",
      "iata": "",
      "name": "Cypress River Airport",
      "city": "Jefferson",
      "state": "Texas",
      "country": "US",
      "elevation": 221,
      "lat": 32.7459983826,
      "lon": -94.304397583,
      "tz": "America\/Chicago"
  },
  "K24J": {
      "icao": "K24J",
      "iata": "",
      "name": "Suwannee County Airport",
      "city": "Live Oak",
      "state": "Florida",
      "country": "US",
      "elevation": 104,
      "lat": 30.3001003265,
      "lon": -83.0246963501,
      "tz": "America\/New_York"
  },
  "K24N": {
      "icao": "K24N",
      "iata": "",
      "name": "Jicarilla Apache Nation Airport",
      "city": "Dulce",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7618,
      "lat": 36.8284988403,
      "lon": -106.8840026855,
      "tz": "America\/Denver"
  },
  "K24R": {
      "icao": "K24R",
      "iata": "",
      "name": "Dilley Airpark",
      "city": "Dilley",
      "state": "Texas",
      "country": "US",
      "elevation": 542,
      "lat": 28.6854991913,
      "lon": -99.189201355,
      "tz": "America\/Chicago"
  },
  "K25D": {
      "icao": "K25D",
      "iata": "",
      "name": "Forest Lake Airport",
      "city": "Forest Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 925,
      "lat": 45.2476997375,
      "lon": -92.9944000244,
      "tz": "America\/Chicago"
  },
  "K25J": {
      "icao": "K25J",
      "iata": "",
      "name": "Cuthbert-Randolph Airport",
      "city": "Cuthbert",
      "state": "Georgia",
      "country": "US",
      "elevation": 457,
      "lat": 31.7001991272,
      "lon": -84.8248977661,
      "tz": "America\/New_York"
  },
  "K25M": {
      "icao": "K25M",
      "iata": "",
      "name": "Ripley Airport",
      "city": "Ripley",
      "state": "Mississippi",
      "country": "US",
      "elevation": 465,
      "lat": 34.7223014832,
      "lon": -89.0150985718,
      "tz": "America\/Chicago"
  },
  "K26A": {
      "icao": "K26A",
      "iata": "",
      "name": "Ashland\/Lineville Airport",
      "city": "Ashland\/Lineville",
      "state": "Alabama",
      "country": "US",
      "elevation": 1065,
      "lat": 33.2876014709,
      "lon": -85.8041000366,
      "tz": "America\/Chicago"
  },
  "K26R": {
      "icao": "K26R",
      "iata": "",
      "name": "Jackson County Airport",
      "city": "Edna",
      "state": "Texas",
      "country": "US",
      "elevation": 61,
      "lat": 29.0009994507,
      "lon": -96.5820007324,
      "tz": "America\/Chicago"
  },
  "K26U": {
      "icao": "K26U",
      "iata": "",
      "name": "Mc Dermitt State Airport",
      "city": "Mc Dermitt",
      "state": "Oregon",
      "country": "US",
      "elevation": 4478,
      "lat": 42.0021018982,
      "lon": -117.722999573,
      "tz": "America\/Los_Angeles"
  },
  "K27A": {
      "icao": "K27A",
      "iata": "",
      "name": "Elbert-County-Patz Field",
      "city": "Elberton",
      "state": "Georgia",
      "country": "US",
      "elevation": 603,
      "lat": 34.095199585,
      "lon": -82.81590271,
      "tz": "America\/New_York"
  },
  "K27K": {
      "icao": "K27K",
      "iata": "",
      "name": "Georgetown Scott County - Marshall Field",
      "city": "Georgetown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 947,
      "lat": 38.2344017029,
      "lon": -84.4347000122,
      "tz": "America\/New_York"
  },
  "K27R": {
      "icao": "K27R",
      "iata": "",
      "name": "Eldorado Airport",
      "city": "Eldorado",
      "state": "Texas",
      "country": "US",
      "elevation": 2448,
      "lat": 30.8621997833,
      "lon": -100.611000061,
      "tz": "America\/Chicago"
  },
  "K28J": {
      "icao": "K28J",
      "iata": "",
      "name": "Palatka Municipal - Lt. Kay Larkin Field",
      "city": "Palatka",
      "state": "Florida",
      "country": "US",
      "elevation": 48,
      "lat": 29.65859985,
      "lon": -81.68890381,
      "tz": "America\/New_York"
  },
  "K29D": {
      "icao": "K29D",
      "iata": "",
      "name": "Grove City Airport",
      "city": "Grove City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1371,
      "lat": 41.1459999084,
      "lon": -80.1677017212,
      "tz": "America\/New_York"
  },
  "K29G": {
      "icao": "K29G",
      "iata": "",
      "name": "Portage County Airport",
      "city": "Ravenna",
      "state": "Ohio",
      "country": "US",
      "elevation": 1197,
      "lat": 41.2102012634,
      "lon": -81.2516021729,
      "tz": "America\/New_York"
  },
  "K29S": {
      "icao": "K29S",
      "iata": "",
      "name": "Gardiner Airport",
      "city": "Gardiner",
      "state": "Montana",
      "country": "US",
      "elevation": 5286,
      "lat": 45.0499000549,
      "lon": -110.7470016479,
      "tz": "America\/Denver"
  },
  "K2A0": {
      "icao": "K2A0",
      "iata": "",
      "name": "Mark Anton Airport",
      "city": "Dayton",
      "state": "Tennessee",
      "country": "US",
      "elevation": 719,
      "lat": 35.4861984253,
      "lon": -84.931098938,
      "tz": "America\/New_York"
  },
  "K2A1": {
      "icao": "K2A1",
      "iata": "",
      "name": "Jamestown Municipal Airport",
      "city": "Jamestown",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1694,
      "lat": 36.3497009277,
      "lon": -84.9467010498,
      "tz": "America\/Chicago"
  },
  "K2A2": {
      "icao": "K2A2",
      "iata": "",
      "name": "Holley Mountain Airpark",
      "city": "Clinton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1269,
      "lat": 35.6506996155,
      "lon": -92.4038009644,
      "tz": "America\/Chicago"
  },
  "K2A5": {
      "icao": "K2A5",
      "iata": "",
      "name": "Causey Airport",
      "city": "Liberty",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 723,
      "lat": 35.9118003845,
      "lon": -79.6175994873,
      "tz": "America\/New_York"
  },
  "K2A6": {
      "icao": "K2A6",
      "iata": "",
      "name": "Holly Grove Municipal Airport",
      "city": "Holly Grove",
      "state": "Arkansas",
      "country": "US",
      "elevation": 176,
      "lat": 34.5824012756,
      "lon": -91.1651992798,
      "tz": "America\/Chicago"
  },
  "K2B3": {
      "icao": "K2B3",
      "iata": "",
      "name": "Parlin Field",
      "city": "Newport",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 784,
      "lat": 43.3880996704,
      "lon": -72.1893005371,
      "tz": "America\/New_York"
  },
  "K2B7": {
      "icao": "K2B7",
      "iata": "",
      "name": "Pittsfield Municipal Airport",
      "city": "Pittsfield",
      "state": "Maine",
      "country": "US",
      "elevation": 198,
      "lat": 44.7685012817,
      "lon": -69.3743972778,
      "tz": "America\/New_York"
  },
  "K2C8": {
      "icao": "K2C8",
      "iata": "",
      "name": "Cavalier Municipal Airport",
      "city": "Cavalier",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 892,
      "lat": 48.7837982178,
      "lon": -97.6318969727,
      "tz": "America\/Chicago"
  },
  "K2CB": {
      "icao": "K2CB",
      "iata": "",
      "name": "Camp Blanding Army Air Field\/NG Airfield",
      "city": "Camp Blanding Mil Res(Starke)",
      "state": "Florida",
      "country": "US",
      "elevation": 196,
      "lat": 29.9524993896,
      "lon": -81.9796981812,
      "tz": "America\/New_York"
  },
  "K2D5": {
      "icao": "K2D5",
      "iata": "",
      "name": "Oakes Municipal Airport",
      "city": "Oakes",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1335,
      "lat": 46.1730003357,
      "lon": -98.0799026489,
      "tz": "America\/Chicago"
  },
  "K2E3": {
      "icao": "K2E3",
      "iata": "",
      "name": "Cluck Ranch Airport",
      "city": "Gruver",
      "state": "Texas",
      "country": "US",
      "elevation": 3423,
      "lat": 36.1772003174,
      "lon": -101.6940002441,
      "tz": "America\/Chicago"
  },
  "K2E5": {
      "icao": "K2E5",
      "iata": "",
      "name": "Dell City Municipal Airport",
      "city": "Dell City",
      "state": "Texas",
      "country": "US",
      "elevation": 3701,
      "lat": 31.947599411,
      "lon": -105.1920013428,
      "tz": "America\/Denver"
  },
  "K2E7": {
      "icao": "K2E7",
      "iata": "",
      "name": "Mc Lean Gray County Airport",
      "city": "Mc Lean",
      "state": "Texas",
      "country": "US",
      "elevation": 2835,
      "lat": 35.2470016479,
      "lon": -100.543998718,
      "tz": "America\/Chicago"
  },
  "K2F0": {
      "icao": "K2F0",
      "iata": "",
      "name": "Iraan Municipal Airport",
      "city": "Iraan",
      "state": "Texas",
      "country": "US",
      "elevation": 2200,
      "lat": 30.9057006836,
      "lon": -101.891998291,
      "tz": "America\/Chicago"
  },
  "K2F1": {
      "icao": "K2F1",
      "iata": "",
      "name": "Shamrock Municipal Airport",
      "city": "Shamrock",
      "state": "Texas",
      "country": "US",
      "elevation": 2369,
      "lat": 35.2361984253,
      "lon": -100.1849975586,
      "tz": "America\/Chicago"
  },
  "K2F4": {
      "icao": "K2F4",
      "iata": "",
      "name": "T Bar Airport",
      "city": "Tahoka",
      "state": "Texas",
      "country": "US",
      "elevation": 3126,
      "lat": 33.1769981384,
      "lon": -101.8199996948,
      "tz": "America\/Chicago"
  },
  "K2F5": {
      "icao": "K2F5",
      "iata": "",
      "name": "Lamesa Municipal Airport",
      "city": "Lamesa",
      "state": "Texas",
      "country": "US",
      "elevation": 2999,
      "lat": 32.7563018799,
      "lon": -101.9199981689,
      "tz": "America\/Chicago"
  },
  "K2F7": {
      "icao": "K2F7",
      "iata": "",
      "name": "Commerce Municipal Airport",
      "city": "Commerce",
      "state": "Texas",
      "country": "US",
      "elevation": 515,
      "lat": 33.2929000854,
      "lon": -95.8964004517,
      "tz": "America\/Chicago"
  },
  "K2G1": {
      "icao": "K2G1",
      "iata": "",
      "name": "Concord Airpark",
      "city": "Painesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 999,
      "lat": 41.6669998169,
      "lon": -81.1971969604,
      "tz": "America\/New_York"
  },
  "K2G2": {
      "icao": "K2G2",
      "iata": "",
      "name": "Jefferson County Airpark",
      "city": "Steubenville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1196,
      "lat": 40.3594017029,
      "lon": -80.7001037598,
      "tz": "America\/New_York"
  },
  "K2G4": {
      "icao": "K2G4",
      "iata": "",
      "name": "Garrett County Airport",
      "city": "Oakland",
      "state": "Maryland",
      "country": "US",
      "elevation": 2933,
      "lat": 39.5802993774,
      "lon": -79.3394012451,
      "tz": "America\/New_York"
  },
  "K2G9": {
      "icao": "K2G9",
      "iata": "",
      "name": "Somerset County Airport",
      "city": "Somerset",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2275,
      "lat": 40.03910065,
      "lon": -79.01460266,
      "tz": "America\/New_York"
  },
  "K2GC": {
      "icao": "K2GC",
      "iata": "",
      "name": "Grays Creek Airport",
      "city": "Fayetteville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 160,
      "lat": 34.893699646,
      "lon": -78.84349823,
      "tz": "America\/New_York"
  },
  "K2H0": {
      "icao": "K2H0",
      "iata": "",
      "name": "Shelby County Airport",
      "city": "Shelbyville",
      "state": "Alabama",
      "country": "US",
      "elevation": 618,
      "lat": 39.4104003906,
      "lon": -88.8453979492,
      "tz": "America\/Chicago"
  },
  "K2H2": {
      "icao": "K2H2",
      "iata": "",
      "name": "Jerry Sumners Sr Aurora Municipal Airport",
      "city": "Aurora",
      "state": "Missouri",
      "country": "US",
      "elevation": 1434,
      "lat": 36.9622993469,
      "lon": -93.6952972412,
      "tz": "America\/Chicago"
  },
  "K2H9": {
      "icao": "K2H9",
      "iata": "",
      "name": "Rolette Airport",
      "city": "Rolette",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1620,
      "lat": 48.6652984619,
      "lon": -99.8532028198,
      "tz": "America\/Chicago"
  },
  "K2I0": {
      "icao": "K2I0",
      "iata": "",
      "name": "Madisonville Municipal Airport",
      "city": "Madisonville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 439,
      "lat": 37.3549995422,
      "lon": -87.399597168,
      "tz": "America\/Chicago"
  },
  "K2I3": {
      "icao": "K2I3",
      "iata": "",
      "name": "Rough River State Park Airport",
      "city": "Falls-Of-Rough",
      "state": "Kentucky",
      "country": "US",
      "elevation": 577,
      "lat": 37.6097984314,
      "lon": -86.5068969727,
      "tz": "America\/Chicago"
  },
  "K2IS": {
      "icao": "K2IS",
      "iata": "",
      "name": "Airglades Airport",
      "city": "Clewiston",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 26.7420005798,
      "lon": -81.0497970581,
      "tz": "America\/New_York"
  },
  "K2J2": {
      "icao": "K2J2",
      "iata": "",
      "name": "Liberty County Airport",
      "city": "Hinesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 98,
      "lat": 31.7845993042,
      "lon": -81.6411972046,
      "tz": "America\/New_York"
  },
  "K2J3": {
      "icao": "K2J3",
      "iata": "",
      "name": "Louisville Municipal Airport",
      "city": "Louisville",
      "state": "Georgia",
      "country": "US",
      "elevation": 328,
      "lat": 32.9864997864,
      "lon": -82.3856964111,
      "tz": "America\/New_York"
  },
  "K2J5": {
      "icao": "K2J5",
      "iata": "",
      "name": "Millen Airport",
      "city": "Millen",
      "state": "Georgia",
      "country": "US",
      "elevation": 237,
      "lat": 32.8936004639,
      "lon": -81.9654006958,
      "tz": "America\/New_York"
  },
  "K2K3": {
      "icao": "K2K3",
      "iata": "",
      "name": "Stanton County Municipal Airport",
      "city": "Johnson",
      "state": "Kansas",
      "country": "US",
      "elevation": 3324,
      "lat": 37.582698822,
      "lon": -101.733001709,
      "tz": "America\/Chicago"
  },
  "K2K4": {
      "icao": "K2K4",
      "iata": "",
      "name": "Scott Field",
      "city": "Mangum",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1643,
      "lat": 34.8926010132,
      "lon": -99.5281982422,
      "tz": "America\/Chicago"
  },
  "K2K7": {
      "icao": "K2K7",
      "iata": "",
      "name": "Neodesha Municipal Airport",
      "city": "Neodesha",
      "state": "Kansas",
      "country": "US",
      "elevation": 841,
      "lat": 37.4353981018,
      "lon": -95.6461029053,
      "tz": "America\/Chicago"
  },
  "K2K9": {
      "icao": "K2K9",
      "iata": "",
      "name": "Haskell Airport",
      "city": "Haskell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 588,
      "lat": 35.832901001,
      "lon": -95.6673965454,
      "tz": "America\/Chicago"
  },
  "K2L0": {
      "icao": "K2L0",
      "iata": "",
      "name": "Pineville Municipal Airport",
      "city": "Pineville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 100,
      "lat": 31.3460998535,
      "lon": -92.4436035156,
      "tz": "America\/Chicago"
  },
  "K2M0": {
      "icao": "K2M0",
      "iata": "",
      "name": "Princeton Caldwell County Airport",
      "city": "Princeton",
      "state": "Kentucky",
      "country": "US",
      "elevation": 584,
      "lat": 37.1151008606,
      "lon": -87.8571014404,
      "tz": "America\/Chicago"
  },
  "K2M2": {
      "icao": "K2M2",
      "iata": "",
      "name": "Lawrenceburg Lawrence County Airport",
      "city": "Lawrenceburg",
      "state": "Tennessee",
      "country": "US",
      "elevation": 936,
      "lat": 35.2342987061,
      "lon": -87.2578964233,
      "tz": "America\/Chicago"
  },
  "K2M4": {
      "icao": "K2M4",
      "iata": "",
      "name": "G. V. Montgomery Airport",
      "city": "Forest",
      "state": "Mississippi",
      "country": "US",
      "elevation": 517,
      "lat": 32.3535003662,
      "lon": -89.4887008667,
      "tz": "America\/Chicago"
  },
  "K2M8": {
      "icao": "K2M8",
      "iata": "",
      "name": "Charles W. Baker Airport",
      "city": "Millington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 247,
      "lat": 35.2789993286,
      "lon": -89.9315032959,
      "tz": "America\/Chicago"
  },
  "K2MO": {
      "icao": "K2MO",
      "iata": "",
      "name": "Mount Vernon Municipal Airport",
      "city": "Mount Vernon",
      "state": "Missouri",
      "country": "US",
      "elevation": 1244,
      "lat": 37.0684013367,
      "lon": -93.8849029541,
      "tz": "America\/Chicago"
  },
  "K2O1": {
      "icao": "K2O1",
      "iata": "GNF",
      "name": "Gansner Field",
      "city": "Quincy",
      "state": "California",
      "country": "US",
      "elevation": 3415,
      "lat": 39.943901062,
      "lon": -120.944999695,
      "tz": "America\/Los_Angeles"
  },
  "K2O3": {
      "icao": "K2O3",
      "iata": "",
      "name": "Angwin Parrett Field",
      "city": "Angwin",
      "state": "California",
      "country": "US",
      "elevation": 1848,
      "lat": 38.5784988403,
      "lon": -122.4349975586,
      "tz": "America\/Los_Angeles"
  },
  "K2O6": {
      "icao": "K2O6",
      "iata": "",
      "name": "Chowchilla Airport",
      "city": "Chowchilla",
      "state": "California",
      "country": "US",
      "elevation": 242,
      "lat": 37.1124000549,
      "lon": -120.2470016479,
      "tz": "America\/Los_Angeles"
  },
  "K2O7": {
      "icao": "K2O7",
      "iata": "",
      "name": "Independence Airport",
      "city": "Independence",
      "state": "California",
      "country": "US",
      "elevation": 3908,
      "lat": 36.81380081,
      "lon": -118.2050018,
      "tz": "America\/Los_Angeles"
  },
  "K2O8": {
      "icao": "K2O8",
      "iata": "",
      "name": "Hinton Municipal Airport",
      "city": "Hinton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1587,
      "lat": 35.50590134,
      "lon": -98.3423996,
      "tz": "America\/Chicago"
  },
  "K2R0": {
      "icao": "K2R0",
      "iata": "",
      "name": "Waynesboro Municipal Airport",
      "city": "Waynesboro",
      "state": "Mississippi",
      "country": "US",
      "elevation": 164,
      "lat": 31.6459999084,
      "lon": -88.634803772,
      "tz": "America\/Chicago"
  },
  "K2R1": {
      "icao": "K2R1",
      "iata": "",
      "name": "Le Maire Memorial Airport",
      "city": "Jeanerette",
      "state": "Louisiana",
      "country": "US",
      "elevation": 14,
      "lat": 29.89909935,
      "lon": -91.6660003662,
      "tz": "America\/Chicago"
  },
  "K2R2": {
      "icao": "K2R2",
      "iata": "",
      "name": "Hendricks County Gordon Graham Field",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 897,
      "lat": 39.74810028,
      "lon": -86.47380066,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K2R4": {
      "icao": "K2R4",
      "iata": "",
      "name": "Peter Prince Field",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 82,
      "lat": 30.6375999451,
      "lon": -86.9936981201,
      "tz": "America\/Chicago"
  },
  "K2R5": {
      "icao": "K2R5",
      "iata": "",
      "name": "St Elmo Airport",
      "city": "St Elmo",
      "state": "Alabama",
      "country": "US",
      "elevation": 132,
      "lat": 30.50189972,
      "lon": -88.27510071,
      "tz": "America\/Chicago"
  },
  "K2R6": {
      "icao": "K2R6",
      "iata": "",
      "name": "Bunkie Municipal Airport",
      "city": "Bunkie",
      "state": "Louisiana",
      "country": "US",
      "elevation": 62,
      "lat": 30.9566993713,
      "lon": -92.2341003418,
      "tz": "America\/Chicago"
  },
  "K2R7": {
      "icao": "K2R7",
      "iata": "",
      "name": "Franklinton Airport",
      "city": "Franklinton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 175,
      "lat": 30.8194007874,
      "lon": -90.1125030518,
      "tz": "America\/Chicago"
  },
  "K2R9": {
      "icao": "K2R9",
      "iata": "",
      "name": "Karnes County Airport",
      "city": "Kenedy",
      "state": "Texas",
      "country": "US",
      "elevation": 289,
      "lat": 28.8250007629,
      "lon": -97.8656005859,
      "tz": "America\/Chicago"
  },
  "K2RR": {
      "icao": "K2RR",
      "iata": "",
      "name": "River Ranch Resort Airport",
      "city": "River Ranch",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 27.7821998596,
      "lon": -81.2052993774,
      "tz": "America\/New_York"
  },
  "K2S4": {
      "icao": "K2S4",
      "iata": "",
      "name": "Warden Airport",
      "city": "Warden",
      "state": "Washington",
      "country": "US",
      "elevation": 1276,
      "lat": 46.9654006958,
      "lon": -119.066001892,
      "tz": "America\/Los_Angeles"
  },
  "K2S7": {
      "icao": "K2S7",
      "iata": "",
      "name": "Chiloquin State Airport",
      "city": "Chiloquin",
      "state": "Oregon",
      "country": "US",
      "elevation": 4217,
      "lat": 42.579440493,
      "lon": -121.879062653,
      "tz": "America\/Los_Angeles"
  },
  "K2S8": {
      "icao": "K2S8",
      "iata": "",
      "name": "Wilbur Airport",
      "city": "Wilbur",
      "state": "Washington",
      "country": "US",
      "elevation": 2182,
      "lat": 47.7541999817,
      "lon": -118.7429962158,
      "tz": "America\/Los_Angeles"
  },
  "K2S9": {
      "icao": "K2S9",
      "iata": "",
      "name": "Willapa Harbor Airport",
      "city": "South Bend\/Raymond\/",
      "state": "Washington",
      "country": "US",
      "elevation": 13,
      "lat": 46.6976013184,
      "lon": -123.8229980469,
      "tz": "America\/Los_Angeles"
  },
  "K2T1": {
      "icao": "K2T1",
      "iata": "",
      "name": "Muleshoe Municipal Airport",
      "city": "Muleshoe",
      "state": "Texas",
      "country": "US",
      "elevation": 3779,
      "lat": 34.1851005554,
      "lon": -102.6409988403,
      "tz": "America\/Chicago"
  },
  "K2V5": {
      "icao": "K2V5",
      "iata": "",
      "name": "Wray Municipal Airport",
      "city": "Wray",
      "state": "Colorado",
      "country": "US",
      "elevation": 3667,
      "lat": 40.10029984,
      "lon": -102.2409973,
      "tz": "America\/Denver"
  },
  "K2V6": {
      "icao": "K2V6",
      "iata": "",
      "name": "Yuma Municipal Airport",
      "city": "Yuma",
      "state": "Colorado",
      "country": "US",
      "elevation": 4138,
      "lat": 40.1057105,
      "lon": -102.7144377,
      "tz": "America\/Denver"
  },
  "K2W5": {
      "icao": "K2W5",
      "iata": "",
      "name": "Maryland Airport",
      "city": "Indian Head",
      "state": "Maryland",
      "country": "US",
      "elevation": 170,
      "lat": 38.6004981995,
      "lon": -77.0729980469,
      "tz": "America\/New_York"
  },
  "K2W6": {
      "icao": "K2W6",
      "iata": "",
      "name": "St. Mary's County Regional Airport",
      "city": "Leonardtown",
      "state": "Maryland",
      "country": "US",
      "elevation": 142,
      "lat": 38.3153991699,
      "lon": -76.5501022339,
      "tz": "America\/New_York"
  },
  "K2Y4": {
      "icao": "K2Y4",
      "iata": "",
      "name": "Rockwell City Municipal Airport",
      "city": "Rockwell City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1217,
      "lat": 42.3875007629,
      "lon": -94.6179962158,
      "tz": "America\/Chicago"
  },
  "K30K": {
      "icao": "K30K",
      "iata": "",
      "name": "Ingalls Municipal Airport",
      "city": "Ingalls",
      "state": "Kansas",
      "country": "US",
      "elevation": 2814,
      "lat": 37.9070014954,
      "lon": -100.53099823,
      "tz": "America\/Chicago"
  },
  "K31E": {
      "icao": "K31E",
      "iata": "",
      "name": "Eagles Nest Airport",
      "city": "West Creek",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 35,
      "lat": 39.6654014587,
      "lon": -74.3078994751,
      "tz": "America\/New_York"
  },
  "K32A": {
      "icao": "K32A",
      "iata": "",
      "name": "Danville Municipal Airport",
      "city": "Danville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 387,
      "lat": 35.0870018005,
      "lon": -93.4274978638,
      "tz": "America\/Chicago"
  },
  "K32S": {
      "icao": "K32S",
      "iata": "",
      "name": "Stevensville Airport",
      "city": "Stevensville",
      "state": "Montana",
      "country": "US",
      "elevation": 3610,
      "lat": 46.525100708,
      "lon": -114.0530014038,
      "tz": "America\/Denver"
  },
  "K33J": {
      "icao": "K33J",
      "iata": "",
      "name": "Geneva Municipal Airport",
      "city": "Geneva",
      "state": "Alabama",
      "country": "US",
      "elevation": 101,
      "lat": 31.0552997589,
      "lon": -85.8803024292,
      "tz": "America\/Chicago"
  },
  "K33K": {
      "icao": "K33K",
      "iata": "",
      "name": "Kinsley Municipal Airport",
      "city": "Kinsley",
      "state": "Kansas",
      "country": "US",
      "elevation": 2171,
      "lat": 37.9090003967,
      "lon": -99.4030990601,
      "tz": "America\/Chicago"
  },
  "K33M": {
      "icao": "K33M",
      "iata": "",
      "name": "Water Valley Municipal Airport",
      "city": "Water Valley",
      "state": "Mississippi",
      "country": "US",
      "elevation": 270,
      "lat": 34.1668014526,
      "lon": -89.6862030029,
      "tz": "America\/Chicago"
  },
  "K33R": {
      "icao": "K33R",
      "iata": "",
      "name": "Groveton Trinity County Airport",
      "city": "Groveton",
      "state": "Texas",
      "country": "US",
      "elevation": 340,
      "lat": 31.0848999023,
      "lon": -95.164100647,
      "tz": "America\/Chicago"
  },
  "K33S": {
      "icao": "K33S",
      "iata": "",
      "name": "Pru Field",
      "city": "Ritzville",
      "state": "Washington",
      "country": "US",
      "elevation": 1801,
      "lat": 47.1231994629,
      "lon": -118.3899993896,
      "tz": "America\/Los_Angeles"
  },
  "K33U": {
      "icao": "K33U",
      "iata": "",
      "name": "Dutch John Airport",
      "city": "Dutch John",
      "state": "Utah",
      "country": "US",
      "elevation": 6561,
      "lat": 40.9177017212,
      "lon": -109.3909988403,
      "tz": "America\/Denver"
  },
  "K33V": {
      "icao": "K33V",
      "iata": "",
      "name": "Walden Jackson County Airport",
      "city": "Walden",
      "state": "Colorado",
      "country": "US",
      "elevation": 8153,
      "lat": 40.7504005432,
      "lon": -106.2710037231,
      "tz": "America\/Denver"
  },
  "K34M": {
      "icao": "K34M",
      "iata": "",
      "name": "Campbell Municipal Airport",
      "city": "Campbell",
      "state": "Missouri",
      "country": "US",
      "elevation": 284,
      "lat": 36.4861984253,
      "lon": -90.0083999634,
      "tz": "America\/Chicago"
  },
  "K34R": {
      "icao": "K34R",
      "iata": "",
      "name": "Hallettsville Municipal Airport",
      "city": "Hallettsville",
      "state": "Texas",
      "country": "US",
      "elevation": 278,
      "lat": 29.3899993896,
      "lon": -96.9561004639,
      "tz": "America\/Chicago"
  },
  "K35A": {
      "icao": "K35A",
      "iata": "",
      "name": "Union County Troy Shelton Field",
      "city": "Union",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 610,
      "lat": 34.6870002747,
      "lon": -81.6411972046,
      "tz": "America\/New_York"
  },
  "K35D": {
      "icao": "K35D",
      "iata": "",
      "name": "Padgham Field",
      "city": "Allegan",
      "state": "Michigan",
      "country": "US",
      "elevation": 706,
      "lat": 42.53099823,
      "lon": -85.82510376,
      "tz": "America\/Detroit"
  },
  "K35S": {
      "icao": "K35S",
      "iata": "",
      "name": "Wasco State Airport",
      "city": "Wasco",
      "state": "Oregon",
      "country": "US",
      "elevation": 1503,
      "lat": 45.5894012451,
      "lon": -120.6740036011,
      "tz": "America\/Los_Angeles"
  },
  "K36K": {
      "icao": "K36K",
      "iata": "",
      "name": "Lakin Airport",
      "city": "Lakin",
      "state": "Kansas",
      "country": "US",
      "elevation": 3077,
      "lat": 37.9695014954,
      "lon": -101.2549972534,
      "tz": "America\/Chicago"
  },
  "K36S": {
      "icao": "K36S",
      "iata": "",
      "name": "Happy Camp Airport",
      "city": "Happy Camp",
      "state": "California",
      "country": "US",
      "elevation": 1209,
      "lat": 41.7906990051,
      "lon": -123.388999939,
      "tz": "America\/Los_Angeles"
  },
  "K36U": {
      "icao": "K36U",
      "iata": "",
      "name": "Heber City Municipal Russ Mcdonald Field",
      "city": "Heber",
      "state": "Utah",
      "country": "US",
      "elevation": 5637,
      "lat": 40.4818000793,
      "lon": -111.4290008545,
      "tz": "America\/Denver"
  },
  "K37F": {
      "icao": "K37F",
      "iata": "",
      "name": "Munday Municipal Airport",
      "city": "Munday",
      "state": "Texas",
      "country": "US",
      "elevation": 1473,
      "lat": 33.4847984314,
      "lon": -99.5861968994,
      "tz": "America\/Chicago"
  },
  "K37K": {
      "icao": "K37K",
      "iata": "",
      "name": "Falconhead Airport",
      "city": "Burneyville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 690,
      "lat": 33.9259986877,
      "lon": -97.2855987549,
      "tz": "America\/Chicago"
  },
  "K37T": {
      "icao": "K37T",
      "iata": "",
      "name": "Calico Rock Izard County Airport",
      "city": "Calico Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 733,
      "lat": 36.1645011902,
      "lon": -92.1445007324,
      "tz": "America\/Chicago"
  },
  "K38D": {
      "icao": "K38D",
      "iata": "",
      "name": "Salem Airpark Inc Airport",
      "city": "Salem",
      "state": "Ohio",
      "country": "US",
      "elevation": 1162,
      "lat": 40.9481010437,
      "lon": -80.8620986938,
      "tz": "America\/New_York"
  },
  "K38J": {
      "icao": "K38J",
      "iata": "",
      "name": "Hemingway Stuckey Airport",
      "city": "Hemingway",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 54,
      "lat": 33.7285995483,
      "lon": -79.5159988403,
      "tz": "America\/New_York"
  },
  "K38S": {
      "icao": "K38S",
      "iata": "",
      "name": "Deer Lodge City County Airport",
      "city": "Deer Lodge",
      "state": "Montana",
      "country": "US",
      "elevation": 4693,
      "lat": 46.38819885,
      "lon": -112.7659988,
      "tz": "America\/Denver"
  },
  "K38U": {
      "icao": "K38U",
      "iata": "",
      "name": "Wayne Wonderland Airport",
      "city": "Loa",
      "state": "Utah",
      "country": "US",
      "elevation": 7023,
      "lat": 38.3624992371,
      "lon": -111.5960006714,
      "tz": "America\/Denver"
  },
  "K3A1": {
      "icao": "K3A1",
      "iata": "",
      "name": "Folsom Field",
      "city": "Cullman",
      "state": "Alabama",
      "country": "US",
      "elevation": 969,
      "lat": 34.268699646,
      "lon": -86.858001709,
      "tz": "America\/Chicago"
  },
  "K3A2": {
      "icao": "K3A2",
      "iata": "",
      "name": "New Tazewell Municipal Airport",
      "city": "Tazewell",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1179,
      "lat": 36.4100990295,
      "lon": -83.5554962158,
      "tz": "America\/New_York"
  },
  "K3A4": {
      "icao": "K3A4",
      "iata": "",
      "name": "Southeast Greensboro Airport",
      "city": "Greensboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 737,
      "lat": 35.9420013428,
      "lon": -79.6855010986,
      "tz": "America\/New_York"
  },
  "K3A7": {
      "icao": "K3A7",
      "iata": "",
      "name": "Eutaw Municipal Airport",
      "city": "Eutaw",
      "state": "Alabama",
      "country": "US",
      "elevation": 170,
      "lat": 32.8209991455,
      "lon": -87.8625030518,
      "tz": "America\/Chicago"
  },
  "K3AU": {
      "icao": "K3AU",
      "iata": "",
      "name": "Augusta Municipal Airport",
      "city": "Augusta",
      "state": "Kansas",
      "country": "US",
      "elevation": 1328,
      "lat": 37.6716003418,
      "lon": -97.0779037476,
      "tz": "America\/Chicago"
  },
  "K3B0": {
      "icao": "K3B0",
      "iata": "",
      "name": "Southbridge Municipal Airport",
      "city": "Southbridge",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 699,
      "lat": 42.1008987427,
      "lon": -72.0383987427,
      "tz": "America\/New_York"
  },
  "K3B1": {
      "icao": "K3B1",
      "iata": "",
      "name": "Greenville Municipal Airport",
      "city": "Greenville",
      "state": "Maine",
      "country": "US",
      "elevation": 1401,
      "lat": 45.4630012512,
      "lon": -69.5515975952,
      "tz": "America\/New_York"
  },
  "K3B4": {
      "icao": "K3B4",
      "iata": "",
      "name": "Littlebrook Air Park",
      "city": "Eliot",
      "state": "Maine",
      "country": "US",
      "elevation": 130,
      "lat": 43.1431007385,
      "lon": -70.7723007202,
      "tz": "America\/New_York"
  },
  "K3B5": {
      "icao": "K3B5",
      "iata": "",
      "name": "Twitchell Airport",
      "city": "Turner",
      "state": "Maine",
      "country": "US",
      "elevation": 356,
      "lat": 44.1888999939,
      "lon": -70.2329025269,
      "tz": "America\/New_York"
  },
  "K3C8": {
      "icao": "K3C8",
      "iata": "",
      "name": "Calverton Executive Airpark",
      "city": "Calverton",
      "state": "New-York",
      "country": "US",
      "elevation": 75,
      "lat": 40.9151000977,
      "lon": -72.7919006348,
      "tz": "America\/New_York"
  },
  "K3CK": {
      "icao": "K3CK",
      "iata": "",
      "name": "Lake in the Hills Airport",
      "city": "Chicago\/Lake in the Hills",
      "state": "Illinois",
      "country": "US",
      "elevation": 888,
      "lat": 42.2067985535,
      "lon": -88.3229980469,
      "tz": "America\/Chicago"
  },
  "K3CM": {
      "icao": "K3CM",
      "iata": "",
      "name": "James Clements Municipal Airport",
      "city": "Bay City",
      "state": "Michigan",
      "country": "US",
      "elevation": 585,
      "lat": 43.5469017,
      "lon": -83.89550018,
      "tz": "America\/Detroit"
  },
  "K3CU": {
      "icao": "K3CU",
      "iata": "",
      "name": "Cable Union Airport",
      "city": "Cable",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1360,
      "lat": 46.1941986084,
      "lon": -91.2463989258,
      "tz": "America\/Chicago"
  },
  "K3DW": {
      "icao": "K3DW",
      "iata": "",
      "name": "Downtown Airport",
      "city": "Springfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1374,
      "lat": 37.221698761,
      "lon": -93.2477035522,
      "tz": "America\/Chicago"
  },
  "K3E0": {
      "icao": "K3E0",
      "iata": "",
      "name": "Miami Roberts County Airport",
      "city": "Miami",
      "state": "Texas",
      "country": "US",
      "elevation": 2720,
      "lat": 35.7139015198,
      "lon": -100.6029968262,
      "tz": "America\/Chicago"
  },
  "K3F2": {
      "icao": "K3F2",
      "iata": "",
      "name": "Cisco Municipal Airport",
      "city": "Cisco",
      "state": "Texas",
      "country": "US",
      "elevation": 1612,
      "lat": 32.4168014526,
      "lon": -99.0002975464,
      "tz": "America\/Chicago"
  },
  "K3F3": {
      "icao": "K3F3",
      "iata": "",
      "name": "C E 'Rusty' Williams Airport",
      "city": "Mansfield",
      "state": "Louisiana",
      "country": "US",
      "elevation": 324,
      "lat": 32.0735015869,
      "lon": -93.7655029297,
      "tz": "America\/Chicago"
  },
  "K3F4": {
      "icao": "K3F4",
      "iata": "",
      "name": "Vivian Airport",
      "city": "Vivian",
      "state": "Louisiana",
      "country": "US",
      "elevation": 260,
      "lat": 32.8613014221,
      "lon": -94.0102005005,
      "tz": "America\/Chicago"
  },
  "K3F6": {
      "icao": "K3F6",
      "iata": "",
      "name": "Dan E Richards Municipal Airport",
      "city": "Paducah",
      "state": "Texas",
      "country": "US",
      "elevation": 1860,
      "lat": 34.0275993347,
      "lon": -100.2819976807,
      "tz": "America\/Chicago"
  },
  "K3F7": {
      "icao": "K3F7",
      "iata": "",
      "name": "Jones Memorial Airport",
      "city": "Bristow",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 851,
      "lat": 35.8069000244,
      "lon": -96.4218978882,
      "tz": "America\/Chicago"
  },
  "K3F9": {
      "icao": "K3F9",
      "iata": "",
      "name": "Mineola Wisener Field",
      "city": "Mineola",
      "state": "Texas",
      "country": "US",
      "elevation": 429,
      "lat": 32.676700592,
      "lon": -95.5108032227,
      "tz": "America\/Chicago"
  },
  "K3FU": {
      "icao": "K3FU",
      "iata": "",
      "name": "Faulkton Municipal Airport",
      "city": "Faulkton",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1569,
      "lat": 45.0318984985,
      "lon": -99.1156997681,
      "tz": "America\/Chicago"
  },
  "K3G1": {
      "icao": "K3G1",
      "iata": "",
      "name": "Erie County Airport",
      "city": "Wattsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1450,
      "lat": 42.0444984436,
      "lon": -79.8538970947,
      "tz": "America\/New_York"
  },
  "K3G2": {
      "icao": "K3G2",
      "iata": "",
      "name": "Grygla Municipal Mel Wilkens Field",
      "city": "Grygla",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1177,
      "lat": 48.2966995239,
      "lon": -95.6271972656,
      "tz": "America\/Chicago"
  },
  "K3G3": {
      "icao": "K3G3",
      "iata": "",
      "name": "Wadsworth Municipal Airport",
      "city": "Wadsworth",
      "state": "Ohio",
      "country": "US",
      "elevation": 974,
      "lat": 41.0031013489,
      "lon": -81.7565002441,
      "tz": "America\/New_York"
  },
  "K3G4": {
      "icao": "K3G4",
      "iata": "",
      "name": "Ashland County Airport",
      "city": "Ashland",
      "state": "Ohio",
      "country": "US",
      "elevation": 1206,
      "lat": 40.9029998779,
      "lon": -82.2555999756,
      "tz": "America\/New_York"
  },
  "K3G5": {
      "icao": "K3G5",
      "iata": "",
      "name": "Dawson Army Air Field",
      "city": "Camp Dawson (Kingwood)",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1265,
      "lat": 39.4500999451,
      "lon": -79.6663970947,
      "tz": "America\/New_York"
  },
  "K3G6": {
      "icao": "K3G6",
      "iata": "",
      "name": "Tri City Airport",
      "city": "Sebring",
      "state": "Ohio",
      "country": "US",
      "elevation": 1188,
      "lat": 40.90599823,
      "lon": -81,
      "tz": "America\/New_York"
  },
  "K3GM": {
      "icao": "K3GM",
      "iata": "",
      "name": "Grand Haven Memorial Airpark",
      "city": "Grand Haven",
      "state": "Michigan",
      "country": "US",
      "elevation": 604,
      "lat": 43.0340003967,
      "lon": -86.1981964111,
      "tz": "America\/Detroit"
  },
  "K3GV": {
      "icao": "K3GV",
      "iata": "",
      "name": "East Kansas City Airport",
      "city": "Grain Valley",
      "state": "Missouri",
      "country": "US",
      "elevation": 835,
      "lat": 39.0155982971,
      "lon": -94.2133026123,
      "tz": "America\/Chicago"
  },
  "K3H4": {
      "icao": "K3H4",
      "iata": "",
      "name": "Hillsboro Municipal Airport",
      "city": "Hillsboro",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 905,
      "lat": 47.3594017029,
      "lon": -97.0604019165,
      "tz": "America\/Chicago"
  },
  "K3HO": {
      "icao": "K3HO",
      "iata": "",
      "name": "Hobart Sky Ranch Airport",
      "city": "Hobart",
      "state": "Indiana",
      "country": "US",
      "elevation": 644,
      "lat": 41.5541992188,
      "lon": -87.2624969482,
      "tz": "America\/Chicago"
  },
  "K3I2": {
      "icao": "K3I2",
      "iata": "",
      "name": "Mason County Airport",
      "city": "Point Pleasant",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 643,
      "lat": 38.9146003723,
      "lon": -82.0986022949,
      "tz": "America\/New_York"
  },
  "K3I3": {
      "icao": "K3I3",
      "iata": "",
      "name": "Sky King Airport",
      "city": "Terre Haute",
      "state": "Indiana",
      "country": "US",
      "elevation": 496,
      "lat": 39.5477981567,
      "lon": -87.3772964478,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K3I4": {
      "icao": "K3I4",
      "iata": "",
      "name": "Richwood Municipal Airport",
      "city": "Richwood",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2486,
      "lat": 38.2583999634,
      "lon": -80.6507034302,
      "tz": "America\/New_York"
  },
  "K3I7": {
      "icao": "K3I7",
      "iata": "",
      "name": "Phillipsburg Airport",
      "city": "Phillipsburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 1031,
      "lat": 39.9132995605,
      "lon": -84.4003982544,
      "tz": "America\/New_York"
  },
  "K3J0": {
      "icao": "K3J0",
      "iata": "",
      "name": "Hampton Varnville Airport",
      "city": "Hampton",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 113,
      "lat": 32.8678016663,
      "lon": -81.0831985474,
      "tz": "America\/New_York"
  },
  "K3J1": {
      "icao": "K3J1",
      "iata": "",
      "name": "Ridgeland Airport",
      "city": "Ridgeland",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 79,
      "lat": 32.4926986694,
      "lon": -80.9923019409,
      "tz": "America\/New_York"
  },
  "K3J7": {
      "icao": "K3J7",
      "iata": "",
      "name": "Greene County Regional Airport",
      "city": "Greensboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 677,
      "lat": 33.5976982117,
      "lon": -83.138999939,
      "tz": "America\/New_York"
  },
  "K3JC": {
      "icao": "K3JC",
      "iata": "",
      "name": "Freeman Field",
      "city": "Junction City",
      "state": "Kansas",
      "country": "US",
      "elevation": 1101,
      "lat": 39.0433006287,
      "lon": -96.8432998657,
      "tz": "America\/Chicago"
  },
  "K3K3": {
      "icao": "K3K3",
      "iata": "",
      "name": "Syracuse Hamilton County Municipal Airport",
      "city": "Syracuse",
      "state": "Kansas",
      "country": "US",
      "elevation": 3322,
      "lat": 37.9916992188,
      "lon": -101.7460021973,
      "tz": "America\/Denver"
  },
  "K3K6": {
      "icao": "K3K6",
      "iata": "",
      "name": "St Louis Metro East Shafer Field",
      "city": "St Jacob",
      "state": "Illinois",
      "country": "US",
      "elevation": 477,
      "lat": 38.7328987122,
      "lon": -89.806602478,
      "tz": "America\/Chicago"
  },
  "K3K7": {
      "icao": "K3K7",
      "iata": "",
      "name": "Mark Hoard Memorial Airport",
      "city": "Leoti",
      "state": "Kansas",
      "country": "US",
      "elevation": 3303,
      "lat": 38.4570007324,
      "lon": -101.3529968262,
      "tz": "America\/Chicago"
  },
  "K3K8": {
      "icao": "K3K8",
      "iata": "",
      "name": "Comanche County Airport",
      "city": "Coldwater",
      "state": "Kansas",
      "country": "US",
      "elevation": 2085,
      "lat": 37.228099823,
      "lon": -99.3309020996,
      "tz": "America\/Chicago"
  },
  "K3L2": {
      "icao": "K3L2",
      "iata": "",
      "name": "Sky Ranch Airport",
      "city": "Sandy Valley",
      "state": "Nevada",
      "country": "US",
      "elevation": 2599,
      "lat": 35.79529953,
      "lon": -115.6269989014,
      "tz": "America\/Los_Angeles"
  },
  "K3LF": {
      "icao": "K3LF",
      "iata": "",
      "name": "Litchfield Municipal Airport",
      "city": "Litchfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 39.1624984741,
      "lon": -89.6745986938,
      "tz": "America\/Chicago"
  },
  "K3M0": {
      "icao": "K3M0",
      "iata": "",
      "name": "Gastons Airport",
      "city": "Lakeview, Arkansas",
      "state": "Arkansas",
      "country": "US",
      "elevation": 479,
      "lat": 36.3490556,
      "lon": -92.5558056,
      "tz": "America\/Chicago"
  },
  "K3M2": {
      "icao": "K3M2",
      "iata": "",
      "name": "Double Springs Winston County Airport",
      "city": "Double Springs",
      "state": "Alabama",
      "country": "US",
      "elevation": 753,
      "lat": 34.1445007324,
      "lon": -87.327796936,
      "tz": "America\/Chicago"
  },
  "K3M3": {
      "icao": "K3M3",
      "iata": "",
      "name": "Collegedale Municipal Airport",
      "city": "Collegedale",
      "state": "Tennessee",
      "country": "US",
      "elevation": 860,
      "lat": 35.0430984497,
      "lon": -85.0218963623,
      "tz": "America\/New_York"
  },
  "K3M7": {
      "icao": "K3M7",
      "iata": "",
      "name": "Lafayette Municipal Airport",
      "city": "Lafayette",
      "state": "Tennessee",
      "country": "US",
      "elevation": 969,
      "lat": 36.51839828,
      "lon": -86.0582962,
      "tz": "America\/Chicago"
  },
  "K3M8": {
      "icao": "K3M8",
      "iata": "",
      "name": "North Pickens Airport",
      "city": "Reform",
      "state": "Alabama",
      "country": "US",
      "elevation": 237,
      "lat": 33.3867988586,
      "lon": -88.0065994263,
      "tz": "America\/Chicago"
  },
  "K3M9": {
      "icao": "K3M9",
      "iata": "",
      "name": "Warren Municipal Airport",
      "city": "Warren",
      "state": "Arkansas",
      "country": "US",
      "elevation": 235,
      "lat": 33.5603981018,
      "lon": -92.0854034424,
      "tz": "America\/Chicago"
  },
  "K3MY": {
      "icao": "K3MY",
      "iata": "",
      "name": "Mount Hawley Auxiliary Airport",
      "city": "Peoria",
      "state": "Illinois",
      "country": "US",
      "elevation": 786,
      "lat": 40.79529953,
      "lon": -89.61340332,
      "tz": "America\/Chicago"
  },
  "K3N6": {
      "icao": "K3N6",
      "iata": "",
      "name": "Old Bridge Airport",
      "city": "Old Bridge",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 87,
      "lat": 40.3298988342,
      "lon": -74.3468017578,
      "tz": "America\/New_York"
  },
  "K3O1": {
      "icao": "K3O1",
      "iata": "",
      "name": "Gustine Airport",
      "city": "Gustine",
      "state": "California",
      "country": "US",
      "elevation": 75,
      "lat": 37.2626991272,
      "lon": -120.9629974365,
      "tz": "America\/Los_Angeles"
  },
  "K3O3": {
      "icao": "K3O3",
      "iata": "",
      "name": "Purcell Municipal - Steven E. Shephard field",
      "city": "Purcell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1143,
      "lat": 34.98450089,
      "lon": -97.38279724,
      "tz": "America\/Chicago"
  },
  "K3O4": {
      "icao": "K3O4",
      "iata": "",
      "name": "Sayre Municipal Airport",
      "city": "Sayre",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1937,
      "lat": 35.1675987244,
      "lon": -99.6578979492,
      "tz": "America\/Chicago"
  },
  "K3O9": {
      "icao": "K3O9",
      "iata": "",
      "name": "Grand Lake Regional Airport",
      "city": "Afton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 792,
      "lat": 36.5775985718,
      "lon": -94.8619003296,
      "tz": "America\/Chicago"
  },
  "K3P3": {
      "icao": "K3P3",
      "iata": "",
      "name": "Mott Municipal Airport",
      "city": "Mott",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2411,
      "lat": 46.3596992493,
      "lon": -102.3229980469,
      "tz": "America\/Denver"
  },
  "K3R2": {
      "icao": "K3R2",
      "iata": "",
      "name": "Le Gros Memorial Airport",
      "city": "Crowley",
      "state": "Louisiana",
      "country": "US",
      "elevation": 17,
      "lat": 30.1609992981,
      "lon": -92.4849014282,
      "tz": "America\/Chicago"
  },
  "K3R4": {
      "icao": "K3R4",
      "iata": "",
      "name": "Hart Airport",
      "city": "Many",
      "state": "Louisiana",
      "country": "US",
      "elevation": 319,
      "lat": 31.54490089,
      "lon": -93.4865036,
      "tz": "America\/Chicago"
  },
  "K3R7": {
      "icao": "K3R7",
      "iata": "",
      "name": "Jennings Airport",
      "city": "Jennings",
      "state": "Louisiana",
      "country": "US",
      "elevation": 23,
      "lat": 30.2427005768,
      "lon": -92.6734008789,
      "tz": "America\/Chicago"
  },
  "K3R9": {
      "icao": "K3R9",
      "iata": "",
      "name": "Lakeway Airpark",
      "city": "Austin",
      "state": "Texas",
      "country": "US",
      "elevation": 909,
      "lat": 30.3575000763,
      "lon": -97.9944992065,
      "tz": "America\/Chicago"
  },
  "K3RC": {
      "icao": "K3RC",
      "iata": "",
      "name": "Roscommon Conservation Airport",
      "city": "Roscommon",
      "state": "Michigan",
      "country": "US",
      "elevation": 1156,
      "lat": 44.4749984741,
      "lon": -84.5667037964,
      "tz": "America\/Detroit"
  },
  "K3S4": {
      "icao": "K3S4",
      "iata": "",
      "name": "Illinois Valley Airport",
      "city": "Cave Junction",
      "state": "Oregon",
      "country": "US",
      "elevation": 1394,
      "lat": 42.1036987305,
      "lon": -123.6819992065,
      "tz": "America\/Los_Angeles"
  },
  "K3S8": {
      "icao": "K3S8",
      "iata": "GTP",
      "name": "Grants Pass Airport",
      "city": "Grants Pass",
      "state": "Oregon",
      "country": "US",
      "elevation": 1126,
      "lat": 42.5101013184,
      "lon": -123.388000488,
      "tz": "America\/Los_Angeles"
  },
  "K3S9": {
      "icao": "K3S9",
      "iata": "",
      "name": "Condon State Pauling Field",
      "city": "Condon",
      "state": "Oregon",
      "country": "US",
      "elevation": 2911,
      "lat": 45.2466011047,
      "lon": -120.1660003662,
      "tz": "America\/Los_Angeles"
  },
  "K3SQ": {
      "icao": "K3SQ",
      "iata": "",
      "name": "St Charles Airport",
      "city": "St Charles",
      "state": "Missouri",
      "country": "US",
      "elevation": 442,
      "lat": 38.8487014771,
      "lon": -90.5000991821,
      "tz": "America\/Chicago"
  },
  "K3T3": {
      "icao": "K3T3",
      "iata": "",
      "name": "Boyceville Municipal Airport",
      "city": "Boyceville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 967,
      "lat": 45.0439987183,
      "lon": -92.020401001,
      "tz": "America\/Chicago"
  },
  "K3T5": {
      "icao": "K3T5",
      "iata": "",
      "name": "Fayette Regional Air Center Airport",
      "city": "La Grange",
      "state": "Texas",
      "country": "US",
      "elevation": 324,
      "lat": 29.908000946,
      "lon": -96.9499969482,
      "tz": "America\/Chicago"
  },
  "K3TR": {
      "icao": "K3TR",
      "iata": "",
      "name": "Jerry Tyler Memorial Airport",
      "city": "Niles",
      "state": "Michigan",
      "country": "US",
      "elevation": 750,
      "lat": 41.835899353,
      "lon": -86.2251968384,
      "tz": "America\/Detroit"
  },
  "K3U3": {
      "icao": "K3U3",
      "iata": "",
      "name": "Bowman Field",
      "city": "Anaconda",
      "state": "Montana",
      "country": "US",
      "elevation": 5034,
      "lat": 46.1530990601,
      "lon": -112.8679962158,
      "tz": "America\/Denver"
  },
  "K3U4": {
      "icao": "K3U4",
      "iata": "",
      "name": "St Labre Mission Airport",
      "city": "Ashland",
      "state": "Montana",
      "country": "US",
      "elevation": 2909,
      "lat": 45.5999984741,
      "lon": -106.266998291,
      "tz": "America\/Denver"
  },
  "K3U7": {
      "icao": "K3U7",
      "iata": "",
      "name": "Benchmark Airport",
      "city": "Benchmark",
      "state": "Montana",
      "country": "US",
      "elevation": 5434,
      "lat": 47.481300354,
      "lon": -112.8700027466,
      "tz": "America\/Denver"
  },
  "K3U8": {
      "icao": "K3U8",
      "iata": "",
      "name": "Big Sandy Airport",
      "city": "Big Sandy",
      "state": "Montana",
      "country": "US",
      "elevation": 2700,
      "lat": 48.1624984741,
      "lon": -110.1129989624,
      "tz": "America\/Denver"
  },
  "K3V0": {
      "icao": "K3V0",
      "iata": "",
      "name": "Custer State Park Airport",
      "city": "Fairburn",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3980,
      "lat": 43.7249984741,
      "lon": -103.3499984741,
      "tz": "America\/Denver"
  },
  "K3V7": {
      "icao": "K3V7",
      "iata": "",
      "name": "Belle Creek Airport",
      "city": "Belle Creek",
      "state": "Montana",
      "country": "US",
      "elevation": 3678,
      "lat": 45.125,
      "lon": -105.0920028687,
      "tz": "America\/Denver"
  },
  "K3W7": {
      "icao": "K3W7",
      "iata": "GCD",
      "name": "Grand Coulee Dam Airport",
      "city": "Electric City",
      "state": "Washington",
      "country": "US",
      "elevation": 1588,
      "lat": 47.922000885,
      "lon": -119.083000183,
      "tz": "America\/Los_Angeles"
  },
  "K3W8": {
      "icao": "K3W8",
      "iata": "",
      "name": "Eureka Municipal Airport",
      "city": "Eureka",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1935,
      "lat": 45.7999992371,
      "lon": -99.6420974731,
      "tz": "America\/Chicago"
  },
  "K3Y2": {
      "icao": "K3Y2",
      "iata": "",
      "name": "George L Scott Municipal Airport",
      "city": "West Union",
      "state": "Iowa",
      "country": "US",
      "elevation": 1232,
      "lat": 42.9850997925,
      "lon": -91.7906036377,
      "tz": "America\/Chicago"
  },
  "K3Y3": {
      "icao": "K3Y3",
      "iata": "",
      "name": "Winterset Madison County Airport",
      "city": "Winterset",
      "state": "Iowa",
      "country": "US",
      "elevation": 1110,
      "lat": 41.3628005981,
      "lon": -94.0211029053,
      "tz": "America\/Chicago"
  },
  "K40G": {
      "icao": "K40G",
      "iata": "",
      "name": "Valle Airport",
      "city": "Grand Canyon",
      "state": "Arizona",
      "country": "US",
      "elevation": 5999,
      "lat": 35.6506004333,
      "lon": -112.1480026245,
      "tz": "America\/Phoenix"
  },
  "K40J": {
      "icao": "K40J",
      "iata": "FPY",
      "name": "Perry Foley Airport",
      "city": "Perry",
      "state": "Florida",
      "country": "US",
      "elevation": 44,
      "lat": 30.0692997,
      "lon": -83.58059692,
      "tz": "America\/New_York"
  },
  "K40U": {
      "icao": "K40U",
      "iata": "",
      "name": "Manila Airport",
      "city": "Manila",
      "state": "Utah",
      "country": "US",
      "elevation": 6175,
      "lat": 40.9860992432,
      "lon": -109.6780014038,
      "tz": "America\/Denver"
  },
  "K41A": {
      "icao": "K41A",
      "iata": "",
      "name": "Reeves Airport",
      "city": "Tallassee",
      "state": "Alabama",
      "country": "US",
      "elevation": 326,
      "lat": 32.5147018433,
      "lon": -85.8759994507,
      "tz": "America\/Chicago"
  },
  "K41F": {
      "icao": "K41F",
      "iata": "",
      "name": "Floydada Municipal Airport",
      "city": "Floydada",
      "state": "Texas",
      "country": "US",
      "elevation": 3187,
      "lat": 34.00230026,
      "lon": -101.3300018,
      "tz": "America\/Chicago"
  },
  "K41N": {
      "icao": "K41N",
      "iata": "",
      "name": "Braceville Airport",
      "city": "Newton Falls",
      "state": "Ohio",
      "country": "US",
      "elevation": 900,
      "lat": 41.2112007141,
      "lon": -80.9692993164,
      "tz": "America\/New_York"
  },
  "K41U": {
      "icao": "K41U",
      "iata": "",
      "name": "Manti Ephraim Airport",
      "city": "Manti",
      "state": "Utah",
      "country": "US",
      "elevation": 5500,
      "lat": 39.32910156,
      "lon": -111.6149979,
      "tz": "America\/Denver"
  },
  "K42A": {
      "icao": "K42A",
      "iata": "",
      "name": "Melbourne Municipal John E Miller Field",
      "city": "Melbourne",
      "state": "Arkansas",
      "country": "US",
      "elevation": 735,
      "lat": 36.07099915,
      "lon": -91.83010101,
      "tz": "America\/Chicago"
  },
  "K42I": {
      "icao": "K42I",
      "iata": "",
      "name": "Parr Airport",
      "city": "Zanesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 790,
      "lat": 40.0069999695,
      "lon": -82.0123977661,
      "tz": "America\/New_York"
  },
  "K42M": {
      "icao": "K42M",
      "iata": "",
      "name": "Thayer Memorial Airport",
      "city": "Thayer",
      "state": "Missouri",
      "country": "US",
      "elevation": 790,
      "lat": 36.5223007202,
      "lon": -91.5719985962,
      "tz": "America\/Chicago"
  },
  "K42S": {
      "icao": "K42S",
      "iata": "",
      "name": "Poplar Airport",
      "city": "Poplar",
      "state": "Montana",
      "country": "US",
      "elevation": 2005,
      "lat": 48.1160011292,
      "lon": -105.1819992065,
      "tz": "America\/Denver"
  },
  "K42U": {
      "icao": "K42U",
      "iata": "",
      "name": "Morgan County Airport",
      "city": "Morgan",
      "state": "Utah",
      "country": "US",
      "elevation": 5020,
      "lat": 41.1487998962,
      "lon": -111.766998291,
      "tz": "America\/Denver"
  },
  "K42V": {
      "icao": "K42V",
      "iata": "",
      "name": "Jones Airport",
      "city": "Benkelman",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3126,
      "lat": 40.0579986572,
      "lon": -101.5469970703,
      "tz": "America\/Denver"
  },
  "K43A": {
      "icao": "K43A",
      "iata": "",
      "name": "Montgomery County Airport",
      "city": "Star",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 628,
      "lat": 35.3881988525,
      "lon": -79.7928009033,
      "tz": "America\/New_York"
  },
  "K43B": {
      "icao": "K43B",
      "iata": "",
      "name": "Deblois Flight Strip",
      "city": "Deblois",
      "state": "Maine",
      "country": "US",
      "elevation": 217,
      "lat": 44.726398468,
      "lon": -67.9906997681,
      "tz": "America\/New_York"
  },
  "K43D": {
      "icao": "K43D",
      "iata": "",
      "name": "Odessa Municipal Airport",
      "city": "Odessa",
      "state": "Washington",
      "country": "US",
      "elevation": 1737,
      "lat": 47.3475990295,
      "lon": -118.6770019531,
      "tz": "America\/Los_Angeles"
  },
  "K43U": {
      "icao": "K43U",
      "iata": "",
      "name": "Mount Pleasant Airport",
      "city": "Mount Pleasant",
      "state": "Utah",
      "country": "US",
      "elevation": 5830,
      "lat": 39.52470016,
      "lon": -111.4749985,
      "tz": "America\/Denver"
  },
  "K44C": {
      "icao": "K44C",
      "iata": "",
      "name": "Beloit Airport",
      "city": "Beloit",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 817,
      "lat": 42.4977989197,
      "lon": -88.9675979614,
      "tz": "America\/Chicago"
  },
  "K44U": {
      "icao": "K44U",
      "iata": "",
      "name": "Salina Gunnison Airport",
      "city": "Salina",
      "state": "Utah",
      "country": "US",
      "elevation": 5159,
      "lat": 39.0290985107,
      "lon": -111.8379974365,
      "tz": "America\/Denver"
  },
  "K45G": {
      "icao": "K45G",
      "iata": "",
      "name": "Brighton Airport",
      "city": "Brighton",
      "state": "Michigan",
      "country": "US",
      "elevation": 973,
      "lat": 42.5698013306,
      "lon": -83.778503418,
      "tz": "America\/Detroit"
  },
  "K45K": {
      "icao": "K45K",
      "iata": "",
      "name": "Minneapolis City County Airport",
      "city": "Minneapolis",
      "state": "Kansas",
      "country": "US",
      "elevation": 1245,
      "lat": 39.0946998596,
      "lon": -97.7205963135,
      "tz": "America\/Chicago"
  },
  "K45R": {
      "icao": "K45R",
      "iata": "",
      "name": "Hawthorne Field",
      "city": "Kountze\/Silsbee",
      "state": "Texas",
      "country": "US",
      "elevation": 71,
      "lat": 30.3362998962,
      "lon": -94.2574996948,
      "tz": "America\/Chicago"
  },
  "K46D": {
      "icao": "K46D",
      "iata": "",
      "name": "Carrington Municipal Airport",
      "city": "Carrington",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1607,
      "lat": 47.4510994,
      "lon": -99.15110016,
      "tz": "America\/Chicago"
  },
  "K46U": {
      "icao": "K46U",
      "iata": "",
      "name": "Alpine Airport",
      "city": "Alpine",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5634,
      "lat": 43.1846008301,
      "lon": -111.0419998169,
      "tz": "America\/Boise"
  },
  "K47A": {
      "icao": "K47A",
      "iata": "",
      "name": "Cherokee County Airport",
      "city": "Canton",
      "state": "Georgia",
      "country": "US",
      "elevation": 1219,
      "lat": 34.3106002808,
      "lon": -84.4238967896,
      "tz": "America\/New_York"
  },
  "K47K": {
      "icao": "K47K",
      "iata": "",
      "name": "Moundridge Municipal Airport",
      "city": "Moundridge",
      "state": "Kansas",
      "country": "US",
      "elevation": 1489,
      "lat": 38.2090988159,
      "lon": -97.5027008057,
      "tz": "America\/Chicago"
  },
  "K47V": {
      "icao": "K47V",
      "iata": "",
      "name": "Curtis Municipal Airport",
      "city": "Curtis",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2678,
      "lat": 40.63750076,
      "lon": -100.4710007,
      "tz": "America\/Chicago"
  },
  "K48A": {
      "icao": "K48A",
      "iata": "",
      "name": "Cochran Airport",
      "city": "Cochran",
      "state": "Georgia",
      "country": "US",
      "elevation": 377,
      "lat": 32.3993988,
      "lon": -83.27590179,
      "tz": "America\/New_York"
  },
  "K48D": {
      "icao": "K48D",
      "iata": "",
      "name": "Clare Municipal Airport",
      "city": "Clare",
      "state": "Michigan",
      "country": "US",
      "elevation": 857,
      "lat": 43.8344993591,
      "lon": -84.740196228,
      "tz": "America\/Detroit"
  },
  "K48I": {
      "icao": "K48I",
      "iata": "",
      "name": "Braxton County Airport",
      "city": "Sutton",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1276,
      "lat": 38.68700027,
      "lon": -80.65180206,
      "tz": "America\/New_York"
  },
  "K48K": {
      "icao": "K48K",
      "iata": "",
      "name": "Ness City Municipal Airport",
      "city": "Ness City",
      "state": "Kansas",
      "country": "US",
      "elevation": 2308,
      "lat": 38.4710998535,
      "lon": -99.9080963135,
      "tz": "America\/Chicago"
  },
  "K48S": {
      "icao": "K48S",
      "iata": "",
      "name": "Harlem Airport",
      "city": "Harlem",
      "state": "Montana",
      "country": "US",
      "elevation": 2643,
      "lat": 48.5661010742,
      "lon": -108.7730026245,
      "tz": "America\/Denver"
  },
  "K49A": {
      "icao": "K49A",
      "iata": "",
      "name": "Gilmer County Airport",
      "city": "Ellijay",
      "state": "Georgia",
      "country": "US",
      "elevation": 1486,
      "lat": 34.6278991699,
      "lon": -84.5249023438,
      "tz": "America\/New_York"
  },
  "K49B": {
      "icao": "K49B",
      "iata": "",
      "name": "Sturgis Municipal Airport",
      "city": "Sturgis",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3243,
      "lat": 44.4179992676,
      "lon": -103.375,
      "tz": "America\/Denver"
  },
  "K49R": {
      "icao": "K49R",
      "iata": "",
      "name": "Real County Airport",
      "city": "Leakey",
      "state": "Texas",
      "country": "US",
      "elevation": 1640,
      "lat": 29.7455005646,
      "lon": -99.7609024048,
      "tz": "America\/Chicago"
  },
  "K49X": {
      "icao": "K49X",
      "iata": "",
      "name": "Chemehuevi Valley Airport",
      "city": "Chemehuevi Valley",
      "state": "California",
      "country": "US",
      "elevation": 631,
      "lat": 34.5275001526,
      "lon": -114.4309997559,
      "tz": "America\/Los_Angeles"
  },
  "K4A0": {
      "icao": "K4A0",
      "iata": "",
      "name": "Berry Hill Airport",
      "city": "Stockbridge",
      "state": "Georgia",
      "country": "US",
      "elevation": 770,
      "lat": 33.5357017517,
      "lon": -84.1791000366,
      "tz": "America\/New_York"
  },
  "K4A4": {
      "icao": "K4A4",
      "iata": "",
      "name": "Polk County Airport- Cornelius Moore Field",
      "city": "Cedartown",
      "state": "Georgia",
      "country": "US",
      "elevation": 974,
      "lat": 34.018699646,
      "lon": -85.1464996338,
      "tz": "America\/New_York"
  },
  "K4A5": {
      "icao": "K4A5",
      "iata": "",
      "name": "Searcy County Airport",
      "city": "Marshall",
      "state": "Arkansas",
      "country": "US",
      "elevation": 963,
      "lat": 35.8969993591,
      "lon": -92.658996582,
      "tz": "America\/Chicago"
  },
  "K4A6": {
      "icao": "K4A6",
      "iata": "",
      "name": "Scottsboro Municipal Word Field",
      "city": "Scottsboro",
      "state": "Alabama",
      "country": "US",
      "elevation": 650,
      "lat": 34.68870163,
      "lon": -86.00589752,
      "tz": "America\/Chicago"
  },
  "K4A7": {
      "icao": "K4A7",
      "iata": "",
      "name": "Clayton County Tara Field",
      "city": "Hampton",
      "state": "Georgia",
      "country": "US",
      "elevation": 874,
      "lat": 33.3890991211,
      "lon": -84.3323974609,
      "tz": "America\/New_York"
  },
  "K4A9": {
      "icao": "K4A9",
      "iata": "",
      "name": "Isbell Field",
      "city": "Fort Payne",
      "state": "Alabama",
      "country": "US",
      "elevation": 877,
      "lat": 34.4737014771,
      "lon": -85.7213973999,
      "tz": "America\/Chicago"
  },
  "K4B6": {
      "icao": "K4B6",
      "iata": "",
      "name": "Ticonderoga Municipal Airport",
      "city": "Ticonderoga",
      "state": "New-York",
      "country": "US",
      "elevation": 273,
      "lat": 43.87730026,
      "lon": -73.4131012,
      "tz": "America\/New_York"
  },
  "K4B7": {
      "icao": "K4B7",
      "iata": "",
      "name": "Schroon Lake Airport",
      "city": "Schroon Lake",
      "state": "New-York",
      "country": "US",
      "elevation": 830,
      "lat": 43.8625984192,
      "lon": -73.7425994873,
      "tz": "America\/New_York"
  },
  "K4B8": {
      "icao": "K4B8",
      "iata": "",
      "name": "Robertson Field",
      "city": "Plainville",
      "state": "Connecticut",
      "country": "US",
      "elevation": 200,
      "lat": 41.6903991699,
      "lon": -72.8647994995,
      "tz": "America\/New_York"
  },
  "K4C8": {
      "icao": "K4C8",
      "iata": "",
      "name": "Albia Municipal Airport",
      "city": "Albia",
      "state": "Iowa",
      "country": "US",
      "elevation": 963,
      "lat": 40.9944992065,
      "lon": -92.7630004883,
      "tz": "America\/Chicago"
  },
  "K4D0": {
      "icao": "K4D0",
      "iata": "",
      "name": "Abrams Municipal Airport",
      "city": "Grand Ledge",
      "state": "Michigan",
      "country": "US",
      "elevation": 842,
      "lat": 42.7742004395,
      "lon": -84.7331008911,
      "tz": "America\/Detroit"
  },
  "K4E7": {
      "icao": "K4E7",
      "iata": "",
      "name": "Ellendale Municipal Airport",
      "city": "Ellendale",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1455,
      "lat": 46.0125007629,
      "lon": -98.5129013062,
      "tz": "America\/Chicago"
  },
  "K4F2": {
      "icao": "K4F2",
      "iata": "",
      "name": "Panola County Sharpe Field",
      "city": "Carthage",
      "state": "Texas",
      "country": "US",
      "elevation": 248,
      "lat": 32.1761016846,
      "lon": -94.2987976074,
      "tz": "America\/Chicago"
  },
  "K4F7": {
      "icao": "K4F7",
      "iata": "",
      "name": "Kizer Field",
      "city": "Prescott",
      "state": "Arkansas",
      "country": "US",
      "elevation": 319,
      "lat": 33.8039016724,
      "lon": -93.362197876,
      "tz": "America\/Chicago"
  },
  "K4F8": {
      "icao": "K4F8",
      "iata": "",
      "name": "Wilson Airport",
      "city": "Stephens",
      "state": "Arkansas",
      "country": "US",
      "elevation": 230,
      "lat": 33.4435997009,
      "lon": -93.0541000366,
      "tz": "America\/Chicago"
  },
  "K4F9": {
      "icao": "K4F9",
      "iata": "",
      "name": "La Moure Rott Municipal Airport",
      "city": "La Moure",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1310,
      "lat": 46.3465995789,
      "lon": -98.2836990356,
      "tz": "America\/Chicago"
  },
  "K4G4": {
      "icao": "K4G4",
      "iata": "",
      "name": "Youngstown Elser Metro Airport",
      "city": "Youngstown",
      "state": "Ohio",
      "country": "US",
      "elevation": 1070,
      "lat": 40.9617996216,
      "lon": -80.6772994995,
      "tz": "America\/New_York"
  },
  "K4G5": {
      "icao": "K4G5",
      "iata": "",
      "name": "Monroe County Airport",
      "city": "Woodsfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1197,
      "lat": 39.7789993286,
      "lon": -81.1027984619,
      "tz": "America\/New_York"
  },
  "K4G6": {
      "icao": "K4G6",
      "iata": "",
      "name": "Hornell Municipal Airport",
      "city": "Hornell",
      "state": "New-York",
      "country": "US",
      "elevation": 1220,
      "lat": 42.3820991516,
      "lon": -77.6820983887,
      "tz": "America\/New_York"
  },
  "K4G8": {
      "icao": "K4G8",
      "iata": "",
      "name": "Columbia Airport",
      "city": "Columbia Station",
      "state": "Ohio",
      "country": "US",
      "elevation": 813,
      "lat": 41.3188018799,
      "lon": -81.9601974487,
      "tz": "America\/New_York"
  },
  "K4I0": {
      "icao": "K4I0",
      "iata": "",
      "name": "Mingo County Airport",
      "city": "Williamson",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1575,
      "lat": 37.6875991821,
      "lon": -82.2610015869,
      "tz": "America\/New_York"
  },
  "K4I3": {
      "icao": "K4I3",
      "iata": "",
      "name": "Knox County Airport",
      "city": "Mount Vernon",
      "state": "Ohio",
      "country": "US",
      "elevation": 1191,
      "lat": 40.32870102,
      "lon": -82.52380371,
      "tz": "America\/New_York"
  },
  "K4I7": {
      "icao": "K4I7",
      "iata": "",
      "name": "Putnam County Airport",
      "city": "Greencastle",
      "state": "Indiana",
      "country": "US",
      "elevation": 842,
      "lat": 39.6302986145,
      "lon": -86.8139038086,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K4I9": {
      "icao": "K4I9",
      "iata": "",
      "name": "Morrow County Airport",
      "city": "Mount Gilead",
      "state": "Ohio",
      "country": "US",
      "elevation": 1085,
      "lat": 40.5245018005,
      "lon": -82.8500976563,
      "tz": "America\/New_York"
  },
  "K4J1": {
      "icao": "K4J1",
      "iata": "",
      "name": "Brantley County Airport",
      "city": "Nahunta",
      "state": "Georgia",
      "country": "US",
      "elevation": 83,
      "lat": 31.2073993683,
      "lon": -81.9057998657,
      "tz": "America\/New_York"
  },
  "K4J2": {
      "icao": "K4J2",
      "iata": "",
      "name": "Berrien County Airport",
      "city": "Nashville",
      "state": "Georgia",
      "country": "US",
      "elevation": 240,
      "lat": 31.212600708,
      "lon": -83.2263031006,
      "tz": "America\/New_York"
  },
  "K4J5": {
      "icao": "K4J5",
      "iata": "",
      "name": "Quitman Brooks County Airport",
      "city": "Quitman",
      "state": "Georgia",
      "country": "US",
      "elevation": 185,
      "lat": 30.8057994843,
      "lon": -83.5865020752,
      "tz": "America\/New_York"
  },
  "K4J6": {
      "icao": "K4J6",
      "iata": "",
      "name": "St Marys Airport",
      "city": "St Marys",
      "state": "Georgia",
      "country": "US",
      "elevation": 23,
      "lat": 30.75300026,
      "lon": -81.55879974,
      "tz": "America\/New_York"
  },
  "K4J8": {
      "icao": "K4J8",
      "iata": "",
      "name": "Treutlen County Airport",
      "city": "Soperton",
      "state": "Georgia",
      "country": "US",
      "elevation": 345,
      "lat": 32.3876991272,
      "lon": -82.5636978149,
      "tz": "America\/New_York"
  },
  "K4K3": {
      "icao": "K4K3",
      "iata": "",
      "name": "Lexington Municipal Airport",
      "city": "Lexington",
      "state": "Missouri",
      "country": "US",
      "elevation": 691,
      "lat": 39.2098007202,
      "lon": -93.9280014038,
      "tz": "America\/Chicago"
  },
  "K4K6": {
      "icao": "K4K6",
      "iata": "",
      "name": "Bloomfield Municipal Airport",
      "city": "Bloomfield",
      "state": "Iowa",
      "country": "US",
      "elevation": 888,
      "lat": 40.7321014404,
      "lon": -92.4282989502,
      "tz": "America\/Chicago"
  },
  "K4M1": {
      "icao": "K4M1",
      "iata": "",
      "name": "Carroll County Airport",
      "city": "Berryville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1205,
      "lat": 36.38130188,
      "lon": -93.62460327,
      "tz": "America\/Chicago"
  },
  "K4M2": {
      "icao": "K4M2",
      "iata": "",
      "name": "Booneville Municipal Airport",
      "city": "Booneville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 465,
      "lat": 35.1495018005,
      "lon": -93.8620986938,
      "tz": "America\/Chicago"
  },
  "K4M3": {
      "icao": "K4M3",
      "iata": "",
      "name": "Carlisle Municipal Airport",
      "city": "Carlisle",
      "state": "Arkansas",
      "country": "US",
      "elevation": 241,
      "lat": 34.80820084,
      "lon": -91.71209717,
      "tz": "America\/Chicago"
  },
  "K4M7": {
      "icao": "K4M7",
      "iata": "",
      "name": "Russellville Logan County Airport",
      "city": "Russellville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 689,
      "lat": 36.7999000549,
      "lon": -86.8102035522,
      "tz": "America\/Chicago"
  },
  "K4M9": {
      "icao": "K4M9",
      "iata": "",
      "name": "Corning Municipal Airport",
      "city": "Corning",
      "state": "Arkansas",
      "country": "US",
      "elevation": 293,
      "lat": 36.4042015076,
      "lon": -90.6479034424,
      "tz": "America\/Chicago"
  },
  "K4O4": {
      "icao": "K4O4",
      "iata": "",
      "name": "Mc Curtain County Regional Airport",
      "city": "Idabel",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 472,
      "lat": 33.9094009399,
      "lon": -94.8593978882,
      "tz": "America\/Chicago"
  },
  "K4O5": {
      "icao": "K4O5",
      "iata": "",
      "name": "Cherokee Municipal Airport",
      "city": "Cherokee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1177,
      "lat": 36.7879981995,
      "lon": -98.3584976196,
      "tz": "America\/Chicago"
  },
  "K4P3": {
      "icao": "K4P3",
      "iata": "",
      "name": "Flandreau Municipal Airport",
      "city": "Flandreau",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1645,
      "lat": 44.0038986206,
      "lon": -96.5931015015,
      "tz": "America\/Chicago"
  },
  "K4R1": {
      "icao": "K4R1",
      "iata": "",
      "name": "I H Bass Jr Memorial Airport",
      "city": "Lumberton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 310,
      "lat": 31.015499115,
      "lon": -89.4825973511,
      "tz": "America\/Chicago"
  },
  "K4R3": {
      "icao": "K4R3",
      "iata": "",
      "name": "Jackson Municipal Airport",
      "city": "Jackson",
      "state": "Alabama",
      "country": "US",
      "elevation": 62,
      "lat": 31.4720993,
      "lon": -87.8946991,
      "tz": "America\/Chicago"
  },
  "K4R4": {
      "icao": "K4R4",
      "iata": "",
      "name": "H L Sonny Callahan Airport",
      "city": "Fairhope",
      "state": "Alabama",
      "country": "US",
      "elevation": 91,
      "lat": 30.46050072,
      "lon": -87.8769989,
      "tz": "America\/Chicago"
  },
  "K4R5": {
      "icao": "K4R5",
      "iata": "",
      "name": "Madeline Island Airport",
      "city": "La Pointe",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 649,
      "lat": 46.7887001038,
      "lon": -90.7586975098,
      "tz": "America\/Chicago"
  },
  "K4R7": {
      "icao": "K4R7",
      "iata": "",
      "name": "Eunice Airport",
      "city": "Eunice",
      "state": "Louisiana",
      "country": "US",
      "elevation": 42,
      "lat": 30.466299057,
      "lon": -92.4237976074,
      "tz": "America\/Chicago"
  },
  "K4R9": {
      "icao": "K4R9",
      "iata": "",
      "name": "Dauphin Island Airport",
      "city": "Dauphin Island",
      "state": "Alabama",
      "country": "US",
      "elevation": 5,
      "lat": 30.2604999542,
      "lon": -88.1275024414,
      "tz": "America\/Chicago"
  },
  "K4S1": {
      "icao": "K4S1",
      "iata": "",
      "name": "Gold Beach Municipal Airport",
      "city": "Gold Beach",
      "state": "Oregon",
      "country": "US",
      "elevation": 20,
      "lat": 42.41339874,
      "lon": -124.4240036,
      "tz": "America\/Los_Angeles"
  },
  "K4S2": {
      "icao": "K4S2",
      "iata": "",
      "name": "Ken Jernstedt Airfield",
      "city": "Hood River",
      "state": "Oregon",
      "country": "US",
      "elevation": 631,
      "lat": 45.67259979,
      "lon": -121.5360031,
      "tz": "America\/Los_Angeles"
  },
  "K4S9": {
      "icao": "K4S9",
      "iata": "",
      "name": "Portland Mulino Airport",
      "city": "Portland-Mulino",
      "state": "Oregon",
      "country": "US",
      "elevation": 260,
      "lat": 45.2163009644,
      "lon": -122.5899963379,
      "tz": "America\/Los_Angeles"
  },
  "K4T2": {
      "icao": "K4T2",
      "iata": "",
      "name": "Kenneth Copeland Airport",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 688,
      "lat": 32.9772987366,
      "lon": -97.4884033203,
      "tz": "America\/Chicago"
  },
  "K4U6": {
      "icao": "K4U6",
      "iata": "",
      "name": "Circle Town County Airport",
      "city": "Circle",
      "state": "Montana",
      "country": "US",
      "elevation": 2426,
      "lat": 47.418598175,
      "lon": -105.56199646,
      "tz": "America\/Denver"
  },
  "K4U9": {
      "icao": "K4U9",
      "iata": "",
      "name": "Dell Flight Strip",
      "city": "Dell",
      "state": "Montana",
      "country": "US",
      "elevation": 6007,
      "lat": 44.7356987,
      "lon": -112.720001221,
      "tz": "America\/Denver"
  },
  "K4V1": {
      "icao": "K4V1",
      "iata": "",
      "name": "Spanish Peaks Airfield",
      "city": "Walsenburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 6047,
      "lat": 37.69660187,
      "lon": -104.7839966,
      "tz": "America\/Denver"
  },
  "K4V4": {
      "icao": "K4V4",
      "iata": "",
      "name": "Northwood Municipal Vince Field",
      "city": "Northwood",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1117,
      "lat": 47.7242012,
      "lon": -97.5904007,
      "tz": "America\/Chicago"
  },
  "K4V9": {
      "icao": "K4V9",
      "iata": "",
      "name": "Antelope County Airport",
      "city": "Neligh",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1774,
      "lat": 42.11220169,
      "lon": -98.03869629,
      "tz": "America\/Chicago"
  },
  "K4X4": {
      "icao": "K4X4",
      "iata": "",
      "name": "Wessington Springs Airport",
      "city": "Wessington Springs",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1546,
      "lat": 44.061000824,
      "lon": -98.5308990479,
      "tz": "America\/Chicago"
  },
  "K4Y4": {
      "icao": "K4Y4",
      "iata": "",
      "name": "Lakes of the North Airport",
      "city": "Gaylord",
      "state": "Michigan",
      "country": "US",
      "elevation": 1286,
      "lat": 44.9124984741,
      "lon": -84.8764038086,
      "tz": "America\/Detroit"
  },
  "K50D": {
      "icao": "K50D",
      "iata": "",
      "name": "Iron County Airport",
      "city": "Crystal Falls",
      "state": "Michigan",
      "country": "US",
      "elevation": 1340,
      "lat": 46.0091018677,
      "lon": -88.2739028931,
      "tz": "America\/Menominee"
  },
  "K50F": {
      "icao": "K50F",
      "iata": "",
      "name": "Bourland Field",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 873,
      "lat": 32.5817985535,
      "lon": -97.5907974243,
      "tz": "America\/Chicago"
  },
  "K50I": {
      "icao": "K50I",
      "iata": "",
      "name": "Kentland Municipal Airport",
      "city": "Kentland",
      "state": "Indiana",
      "country": "US",
      "elevation": 698,
      "lat": 40.7587013245,
      "lon": -87.4281997681,
      "tz": "America\/Chicago"
  },
  "K50R": {
      "icao": "K50R",
      "iata": "",
      "name": "Lockhart Municipal Airport",
      "city": "Lockhart",
      "state": "Texas",
      "country": "US",
      "elevation": 532,
      "lat": 29.8502998352,
      "lon": -97.6724014282,
      "tz": "America\/Chicago"
  },
  "K51A": {
      "icao": "K51A",
      "iata": "",
      "name": "Hawkinsville Pulaski County Airport",
      "city": "Hawkinsville",
      "state": "Georgia",
      "country": "US",
      "elevation": 270,
      "lat": 32.2835006714,
      "lon": -83.4384994507,
      "tz": "America\/New_York"
  },
  "K51D": {
      "icao": "K51D",
      "iata": "",
      "name": "Edgeley Municipal Airport",
      "city": "Edgeley",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1601,
      "lat": 46.34859848,
      "lon": -98.73560333,
      "tz": "America\/Chicago"
  },
  "K51J": {
      "icao": "K51J",
      "iata": "",
      "name": "Lake City Municipal CJ Evans Field",
      "city": "Lake City",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 80,
      "lat": 33.8535995483,
      "lon": -79.7680969238,
      "tz": "America\/New_York"
  },
  "K51R": {
      "icao": "K51R",
      "iata": "",
      "name": "Madisonville Municipal Airport",
      "city": "Madisonville",
      "state": "Texas",
      "country": "US",
      "elevation": 287,
      "lat": 30.9127998352,
      "lon": -95.952003479,
      "tz": "America\/Chicago"
  },
  "K52F": {
      "icao": "K52F",
      "iata": "",
      "name": "Northwest Regional Airport",
      "city": "Roanoke",
      "state": "Texas",
      "country": "US",
      "elevation": 643,
      "lat": 33.0498008728,
      "lon": -97.2322006226,
      "tz": "America\/Chicago"
  },
  "K52J": {
      "icao": "K52J",
      "iata": "",
      "name": "Lee County Butters Field",
      "city": "Bishopville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 211,
      "lat": 34.2444992065,
      "lon": -80.236000061,
      "tz": "America\/New_York"
  },
  "K53A": {
      "icao": "K53A",
      "iata": "",
      "name": "Dr. C P Savage Sr. Airport",
      "city": "Montezuma",
      "state": "Georgia",
      "country": "US",
      "elevation": 337,
      "lat": 32.3019981384,
      "lon": -84.0074996948,
      "tz": "America\/New_York"
  },
  "K54F": {
      "icao": "K54F",
      "iata": "",
      "name": "Robert Lee Airport",
      "city": "Robert Lee",
      "state": "Texas",
      "country": "US",
      "elevation": 1922,
      "lat": 31.8817996979,
      "lon": -100.5400009155,
      "tz": "America\/Chicago"
  },
  "K54J": {
      "icao": "K54J",
      "iata": "",
      "name": "Defuniak Springs Airport",
      "city": "Defuniak Springs",
      "state": "Florida",
      "country": "US",
      "elevation": 289,
      "lat": 30.731300354,
      "lon": -86.1516036987,
      "tz": "America\/Chicago"
  },
  "K54T": {
      "icao": "K54T",
      "iata": "",
      "name": "RWJ Airpark",
      "city": "Baytown",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 29.7616996765,
      "lon": -94.846496582,
      "tz": "America\/Chicago"
  },
  "K55K": {
      "icao": "K55K",
      "iata": "",
      "name": "Oxford Municipal Airport",
      "city": "Oxford",
      "state": "Kansas",
      "country": "US",
      "elevation": 1189,
      "lat": 37.2696990967,
      "lon": -97.0914001465,
      "tz": "America\/Chicago"
  },
  "K55M": {
      "icao": "K55M",
      "iata": "",
      "name": "Star City Municipal Airport",
      "city": "Star City",
      "state": "Arkansas",
      "country": "US",
      "elevation": 398,
      "lat": 33.9261016846,
      "lon": -91.8774032593,
      "tz": "America\/Chicago"
  },
  "K55Y": {
      "icao": "K55Y",
      "iata": "",
      "name": "Rushford Municipal Airport",
      "city": "Rushford",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1211,
      "lat": 43.8157997131,
      "lon": -91.8301010132,
      "tz": "America\/Chicago"
  },
  "K56D": {
      "icao": "K56D",
      "iata": "",
      "name": "Wyandot County Airport",
      "city": "Upper Sandusky",
      "state": "Ohio",
      "country": "US",
      "elevation": 830,
      "lat": 40.8833999634,
      "lon": -83.3144989014,
      "tz": "America\/New_York"
  },
  "K56F": {
      "icao": "K56F",
      "iata": "",
      "name": "Fisher County Airport",
      "city": "Rotan\/Roby",
      "state": "Texas",
      "country": "US",
      "elevation": 1941,
      "lat": 32.8256988525,
      "lon": -100.4150009155,
      "tz": "America\/Chicago"
  },
  "K57C": {
      "icao": "K57C",
      "iata": "",
      "name": "East Troy Municipal Airport",
      "city": "East Troy",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 860,
      "lat": 42.7971992493,
      "lon": -88.3725967407,
      "tz": "America\/Chicago"
  },
  "K57S": {
      "icao": "K57S",
      "iata": "",
      "name": "Troy Airport",
      "city": "Troy",
      "state": "Montana",
      "country": "US",
      "elevation": 2017,
      "lat": 48.4802017212,
      "lon": -115.9039993286,
      "tz": "America\/Denver"
  },
  "K58M": {
      "icao": "K58M",
      "iata": "",
      "name": "Cecil County Airport",
      "city": "Elkton",
      "state": "Maryland",
      "country": "US",
      "elevation": 106,
      "lat": 39.5741996765,
      "lon": -75.8697967529,
      "tz": "America\/New_York"
  },
  "K5A1": {
      "icao": "K5A1",
      "iata": "",
      "name": "Norwalk Huron County Airport",
      "city": "Norwalk",
      "state": "Ohio",
      "country": "US",
      "elevation": 852,
      "lat": 41.2448005676,
      "lon": -82.5512008667,
      "tz": "America\/New_York"
  },
  "K5A4": {
      "icao": "K5A4",
      "iata": "",
      "name": "Okolona Municipal-Richard Stovall Field",
      "city": "Okolona",
      "state": "Mississippi",
      "country": "US",
      "elevation": 335,
      "lat": 34.0158004761,
      "lon": -88.7261962891,
      "tz": "America\/Chicago"
  },
  "K5A6": {
      "icao": "K5A6",
      "iata": "",
      "name": "Winona Montgomery County Airport",
      "city": "Winona",
      "state": "Mississippi",
      "country": "US",
      "elevation": 364,
      "lat": 33.4654007,
      "lon": -89.72920227,
      "tz": "America\/Chicago"
  },
  "K5A9": {
      "icao": "K5A9",
      "iata": "",
      "name": "Roosevelt Memorial Airport",
      "city": "Warm Springs",
      "state": "Georgia",
      "country": "US",
      "elevation": 880,
      "lat": 32.9334983826,
      "lon": -84.6887969971,
      "tz": "America\/New_York"
  },
  "K5B2": {
      "icao": "K5B2",
      "iata": "",
      "name": "Saratoga County Airport",
      "city": "Saratoga Springs",
      "state": "New-York",
      "country": "US",
      "elevation": 434,
      "lat": 43.05130005,
      "lon": -73.86119843,
      "tz": "America\/New_York"
  },
  "K5B5": {
      "icao": "K5B5",
      "iata": "",
      "name": "Napoleon Municipal Airport",
      "city": "Napoleon",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1983,
      "lat": 46.4944000244,
      "lon": -99.7601013184,
      "tz": "America\/Chicago"
  },
  "K5C1": {
      "icao": "K5C1",
      "iata": "",
      "name": "Boerne Stage Field",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 1385,
      "lat": 29.7238998413,
      "lon": -98.6946029663,
      "tz": "America\/Chicago"
  },
  "K5C3": {
      "icao": "K5C3",
      "iata": "",
      "name": "Nary National Shefland Field",
      "city": "Nary",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1389,
      "lat": 47.3760986328,
      "lon": -94.797996521,
      "tz": "America\/Chicago"
  },
  "K5C8": {
      "icao": "K5C8",
      "iata": "",
      "name": "Washburn Municipal Airport",
      "city": "Washburn",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1905,
      "lat": 47.353099823,
      "lon": -101.0270004272,
      "tz": "America\/Chicago"
  },
  "K5F0": {
      "icao": "K5F0",
      "iata": "",
      "name": "Arcadia Bienville Parish Airport",
      "city": "Arcadia",
      "state": "Louisiana",
      "country": "US",
      "elevation": 440,
      "lat": 32.5307006836,
      "lon": -92.9540023804,
      "tz": "America\/Chicago"
  },
  "K5F1": {
      "icao": "K5F1",
      "iata": "",
      "name": "Post Garza County Municipal Airport",
      "city": "Post",
      "state": "Texas",
      "country": "US",
      "elevation": 2545,
      "lat": 33.2037010193,
      "lon": -101.3399963379,
      "tz": "America\/Chicago"
  },
  "K5F4": {
      "icao": "K5F4",
      "iata": "",
      "name": "Homer Municipal Airport",
      "city": "Homer",
      "state": "Louisiana",
      "country": "US",
      "elevation": 244,
      "lat": 32.7885017395,
      "lon": -93.0037002563,
      "tz": "America\/Chicago"
  },
  "K5G6": {
      "icao": "K5G6",
      "iata": "",
      "name": "Cherry Springs Airport",
      "city": "Galeton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2330,
      "lat": 41.6646995544,
      "lon": -77.8182983398,
      "tz": "America\/New_York"
  },
  "K5G7": {
      "icao": "K5G7",
      "iata": "",
      "name": "Bluffton Airport",
      "city": "Bluffton",
      "state": "Ohio",
      "country": "US",
      "elevation": 851,
      "lat": 40.8853988647,
      "lon": -83.868598938,
      "tz": "America\/New_York"
  },
  "K5H4": {
      "icao": "K5H4",
      "iata": "",
      "name": "Harvey Municipal Airport",
      "city": "Harvey",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1607,
      "lat": 47.79119873,
      "lon": -99.93170166,
      "tz": "America\/Chicago"
  },
  "K5I4": {
      "icao": "K5I4",
      "iata": "",
      "name": "Sheridan Airport",
      "city": "Sheridan",
      "state": "Indiana",
      "country": "US",
      "elevation": 936,
      "lat": 40.177898407,
      "lon": -86.217300415,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K5J9": {
      "icao": "K5J9",
      "iata": "",
      "name": "Twin City Airport",
      "city": "Loris",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 99,
      "lat": 34.0884017944,
      "lon": -78.8648986816,
      "tz": "America\/New_York"
  },
  "K5K1": {
      "icao": "K5K1",
      "iata": "",
      "name": "Zelmer Memorial Airpark Inc Airport",
      "city": "Palmyra",
      "state": "Illinois",
      "country": "US",
      "elevation": 663,
      "lat": 39.4199981689,
      "lon": -89.9912033081,
      "tz": "America\/Chicago"
  },
  "K5L0": {
      "icao": "K5L0",
      "iata": "",
      "name": "Lakota Municipal Airport",
      "city": "Lakota",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1512,
      "lat": 48.0293998718,
      "lon": -98.325302124,
      "tz": "America\/Chicago"
  },
  "K5M3": {
      "icao": "K5M3",
      "iata": "",
      "name": "Moultonboro Airport",
      "city": "Moultonboro",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 571,
      "lat": 43.7683982849,
      "lon": -71.3867034912,
      "tz": "America\/New_York"
  },
  "K5M4": {
      "icao": "K5M4",
      "iata": "",
      "name": "Fordyce Municipal Airport",
      "city": "Fordyce",
      "state": "Arkansas",
      "country": "US",
      "elevation": 193,
      "lat": 33.8457984924,
      "lon": -92.3655014038,
      "tz": "America\/Chicago"
  },
  "K5M5": {
      "icao": "K5M5",
      "iata": "",
      "name": "Crystal Lake Airport",
      "city": "Decatur",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1180,
      "lat": 36.3436012268,
      "lon": -94.4448013306,
      "tz": "America\/Chicago"
  },
  "K5M8": {
      "icao": "K5M8",
      "iata": "",
      "name": "Gurdon Lowe Field",
      "city": "Gurdon",
      "state": "Arkansas",
      "country": "US",
      "elevation": 229,
      "lat": 33.9239006042,
      "lon": -93.1687011719,
      "tz": "America\/Chicago"
  },
  "K5M9": {
      "icao": "K5M9",
      "iata": "",
      "name": "Marion Crittenden County Airport",
      "city": "Marion",
      "state": "Kentucky",
      "country": "US",
      "elevation": 650,
      "lat": 37.33430099,
      "lon": -88.11509705,
      "tz": "America\/Chicago"
  },
  "K5N2": {
      "icao": "K5N2",
      "iata": "",
      "name": "Prentice Airport",
      "city": "Prentice",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1578,
      "lat": 45.5429992676,
      "lon": -90.279296875,
      "tz": "America\/Chicago"
  },
  "K5N8": {
      "icao": "K5N8",
      "iata": "",
      "name": "Casselton Robert Miller Regional Airport",
      "city": "Casselton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 933,
      "lat": 46.8540000916,
      "lon": -97.2087020874,
      "tz": "America\/Chicago"
  },
  "K5P2": {
      "icao": "K5P2",
      "iata": "",
      "name": "Mc Laughlin Municipal Airport",
      "city": "Mc Laughlin",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2006,
      "lat": 45.7967987061,
      "lon": -100.783996582,
      "tz": "America\/Denver"
  },
  "K5R1": {
      "icao": "K5R1",
      "iata": "",
      "name": "Roy Wilcox Airport",
      "city": "Chatom",
      "state": "Alabama",
      "country": "US",
      "elevation": 165,
      "lat": 31.4517993927,
      "lon": -88.1945037842,
      "tz": "America\/Chicago"
  },
  "K5R2": {
      "icao": "K5R2",
      "iata": "",
      "name": "Ocean Springs Airport",
      "city": "Ocean Springs",
      "state": "Mississippi",
      "country": "US",
      "elevation": 20,
      "lat": 30.3894004822,
      "lon": -88.75340271,
      "tz": "America\/Chicago"
  },
  "K5R4": {
      "icao": "K5R4",
      "iata": "",
      "name": "Foley Municipal Airport",
      "city": "Foley",
      "state": "Alabama",
      "country": "US",
      "elevation": 74,
      "lat": 30.4277000427,
      "lon": -87.7007980347,
      "tz": "America\/Chicago"
  },
  "K5R8": {
      "icao": "K5R8",
      "iata": "",
      "name": "De Quincy Industrial Airpark",
      "city": "De Quincy",
      "state": "Louisiana",
      "country": "US",
      "elevation": 81,
      "lat": 30.4412002563,
      "lon": -93.4735031128,
      "tz": "America\/Chicago"
  },
  "K5S0": {
      "icao": "K5S0",
      "iata": "",
      "name": "Oakridge State Airport",
      "city": "Oakridge",
      "state": "Oregon",
      "country": "US",
      "elevation": 1393,
      "lat": 43.7526016235,
      "lon": -122.5029983521,
      "tz": "America\/Los_Angeles"
  },
  "K5S2": {
      "icao": "K5S2",
      "iata": "",
      "name": "Crescent Lake State Airport",
      "city": "Crescent Lake",
      "state": "Oregon",
      "country": "US",
      "elevation": 4810,
      "lat": 43.5326004028,
      "lon": -121.9499969482,
      "tz": "America\/Los_Angeles"
  },
  "K5S6": {
      "icao": "K5S6",
      "iata": "",
      "name": "Cape Blanco State Airport",
      "city": "Denmark",
      "state": "Oregon",
      "country": "US",
      "elevation": 214,
      "lat": 42.8578987122,
      "lon": -124.5179977417,
      "tz": "America\/Los_Angeles"
  },
  "K5S9": {
      "icao": "K5S9",
      "iata": "",
      "name": "Valley View Airport",
      "city": "Estacada",
      "state": "Oregon",
      "country": "US",
      "elevation": 735,
      "lat": 45.3082008362,
      "lon": -122.3190002441,
      "tz": "America\/Los_Angeles"
  },
  "K5T6": {
      "icao": "K5T6",
      "iata": "",
      "name": "Dona Ana County At Santa Teresa Airport",
      "city": "Santa Teresa",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4112,
      "lat": 31.8810005188,
      "lon": -106.7050018311,
      "tz": "America\/Denver"
  },
  "K5T9": {
      "icao": "K5T9",
      "iata": "",
      "name": "Maverick County Memorial International Airport",
      "city": "Eagle Pass",
      "state": "Texas",
      "country": "US",
      "elevation": 887,
      "lat": 28.85720062,
      "lon": -100.512001,
      "tz": "America\/Chicago"
  },
  "K5U1": {
      "icao": "K5U1",
      "iata": "",
      "name": "Dutton Airport",
      "city": "Dutton",
      "state": "Montana",
      "country": "US",
      "elevation": 3699,
      "lat": 47.8471984863,
      "lon": -111.6979980469,
      "tz": "America\/Denver"
  },
  "K5U5": {
      "icao": "K5U5",
      "iata": "",
      "name": "Fairfield Airport",
      "city": "Fairfield",
      "state": "Montana",
      "country": "US",
      "elevation": 3989,
      "lat": 47.6291007996,
      "lon": -111.9800033569,
      "tz": "America\/Denver"
  },
  "K5U7": {
      "icao": "K5U7",
      "iata": "",
      "name": "Fort Smith Landing Strip",
      "city": "Fort Smith",
      "state": "Wyoming",
      "country": "US",
      "elevation": 3242,
      "lat": 45.3208007813,
      "lon": -107.9309997559,
      "tz": "America\/Denver"
  },
  "K5V5": {
      "icao": "K5V5",
      "iata": "",
      "name": "Shiprock Airstrip",
      "city": "Shiprock",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5270,
      "lat": 36.6977996826,
      "lon": -108.7009963989,
      "tz": "America\/Denver"
  },
  "K5W1": {
      "icao": "K5W1",
      "iata": "",
      "name": "Wilson Creek Airport",
      "city": "Wilson Creek",
      "state": "Washington",
      "country": "US",
      "elevation": 1440,
      "lat": 47.4249000549,
      "lon": -119.1149978638,
      "tz": "America\/Los_Angeles"
  },
  "K5W4": {
      "icao": "K5W4",
      "iata": "",
      "name": "P K Airpark",
      "city": "Raeford",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 304,
      "lat": 35.0199012756,
      "lon": -79.1910018921,
      "tz": "America\/New_York"
  },
  "K5W5": {
      "icao": "K5W5",
      "iata": "",
      "name": "Triple W Airport",
      "city": "Raleigh",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 244,
      "lat": 35.620300293,
      "lon": -78.700302124,
      "tz": "America\/New_York"
  },
  "K5W8": {
      "icao": "K5W8",
      "iata": "",
      "name": "Siler City Municipal Airport",
      "city": "Siler City",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 614,
      "lat": 35.7042999268,
      "lon": -79.5042037964,
      "tz": "America\/New_York"
  },
  "K5Y1": {
      "icao": "K5Y1",
      "iata": "",
      "name": "Albert J Lindberg Airport",
      "city": "Hessel",
      "state": "Michigan",
      "country": "US",
      "elevation": 760,
      "lat": 46.035900116,
      "lon": -84.4197998047,
      "tz": "America\/Detroit"
  },
  "K60C": {
      "icao": "K60C",
      "iata": "",
      "name": "Elroy Municipal Airport",
      "city": "Elroy",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 944,
      "lat": 43.706401825,
      "lon": -90.257598877,
      "tz": "America\/Chicago"
  },
  "K60F": {
      "icao": "K60F",
      "iata": "",
      "name": "Seymour Municipal Airport",
      "city": "Seymour",
      "state": "Texas",
      "country": "US",
      "elevation": 1344,
      "lat": 33.6487007141,
      "lon": -99.260597229,
      "tz": "America\/Chicago"
  },
  "K60J": {
      "icao": "K60J",
      "iata": "",
      "name": "Odell Williamson Municipal Airport",
      "city": "Ocean Isle Beach",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 32,
      "lat": 33.9085006714,
      "lon": -78.4366989136,
      "tz": "America\/New_York"
  },
  "K60R": {
      "icao": "K60R",
      "iata": "",
      "name": "Navasota Municipal Airport",
      "city": "Navasota",
      "state": "Texas",
      "country": "US",
      "elevation": 228,
      "lat": 30.3719005585,
      "lon": -96.1132965088,
      "tz": "America\/Chicago"
  },
  "K61A": {
      "icao": "K61A",
      "iata": "",
      "name": "Camden Municipal Airport",
      "city": "Camden",
      "state": "Alabama",
      "country": "US",
      "elevation": 143,
      "lat": 31.9797000885,
      "lon": -87.3391036987,
      "tz": "America\/Chicago"
  },
  "K61C": {
      "icao": "K61C",
      "iata": "",
      "name": "Fort Atkinson Municipal Airport",
      "city": "Fort Atkinson",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 42.9631996155,
      "lon": -88.8175964355,
      "tz": "America\/Chicago"
  },
  "K61R": {
      "icao": "K61R",
      "iata": "",
      "name": "Newton Municipal Airport",
      "city": "Newton",
      "state": "Texas",
      "country": "US",
      "elevation": 320,
      "lat": 30.8843994141,
      "lon": -93.7417984009,
      "tz": "America\/Chicago"
  },
  "K61S": {
      "icao": "K61S",
      "iata": "",
      "name": "Cottage Grove State Airport",
      "city": "Cottage Grove",
      "state": "Oregon",
      "country": "US",
      "elevation": 641,
      "lat": 43.7998008728,
      "lon": -123.0289993286,
      "tz": "America\/Los_Angeles"
  },
  "K62D": {
      "icao": "K62D",
      "iata": "",
      "name": "Warren Airport",
      "city": "Warren",
      "state": "Ohio",
      "country": "US",
      "elevation": 905,
      "lat": 41.266998291,
      "lon": -80.9273986816,
      "tz": "America\/New_York"
  },
  "K62S": {
      "icao": "K62S",
      "iata": "",
      "name": "Christmas Valley Airport",
      "city": "Christmas Valley",
      "state": "Oregon",
      "country": "US",
      "elevation": 4317,
      "lat": 43.2364997864,
      "lon": -120.6660003662,
      "tz": "America\/Los_Angeles"
  },
  "K63B": {
      "icao": "K63B",
      "iata": "",
      "name": "Limington Harmon Airport",
      "city": "Limington",
      "state": "Maine",
      "country": "US",
      "elevation": 291,
      "lat": 43.7630004883,
      "lon": -70.6725006104,
      "tz": "America\/New_York"
  },
  "K63C": {
      "icao": "K63C",
      "iata": "",
      "name": "Adams County Legion Field",
      "city": "Friendship (Adams)",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 976,
      "lat": 43.9612007141,
      "lon": -89.7880020142,
      "tz": "America\/Chicago"
  },
  "K63F": {
      "icao": "K63F",
      "iata": "",
      "name": "Stanton Municipal Airport",
      "city": "Stanton",
      "state": "Texas",
      "country": "US",
      "elevation": 2731,
      "lat": 32.1735992432,
      "lon": -101.8219985962,
      "tz": "America\/Chicago"
  },
  "K63M": {
      "icao": "K63M",
      "iata": "",
      "name": "Hermann Municipal Airport",
      "city": "Hermann",
      "state": "Missouri",
      "country": "US",
      "elevation": 507,
      "lat": 38.7052001953,
      "lon": -91.4906005859,
      "tz": "America\/Chicago"
  },
  "K64S": {
      "icao": "K64S",
      "iata": "",
      "name": "Prospect State Airport",
      "city": "Prospect",
      "state": "Oregon",
      "country": "US",
      "elevation": 2578,
      "lat": 42.7431983948,
      "lon": -122.4879989624,
      "tz": "America\/Los_Angeles"
  },
  "K65J": {
      "icao": "K65J",
      "iata": "",
      "name": "Wrens Memorial Airport",
      "city": "Wrens",
      "state": "Georgia",
      "country": "US",
      "elevation": 450,
      "lat": 33.2225990295,
      "lon": -82.3836975098,
      "tz": "America\/New_York"
  },
  "K65S": {
      "icao": "K65S",
      "iata": "",
      "name": "Boundary County Airport",
      "city": "Bonners Ferry",
      "state": "Idaho",
      "country": "US",
      "elevation": 2337,
      "lat": 48.72579956,
      "lon": -116.2949982,
      "tz": "America\/Los_Angeles"
  },
  "K66R": {
      "icao": "K66R",
      "iata": "",
      "name": "Robert R Wells Jr Airport",
      "city": "Columbus",
      "state": "Texas",
      "country": "US",
      "elevation": 242,
      "lat": 29.6410999298,
      "lon": -96.5158004761,
      "tz": "America\/Chicago"
  },
  "K66V": {
      "icao": "K66V",
      "iata": "",
      "name": "Bluff Airport",
      "city": "Bluff",
      "state": "Utah",
      "country": "US",
      "elevation": 4476,
      "lat": 37.25,
      "lon": -109.6340026855,
      "tz": "America\/Denver"
  },
  "K66Y": {
      "icao": "K66Y",
      "iata": "",
      "name": "Diamondhead Airport",
      "city": "Diamondhead",
      "state": "Mississippi",
      "country": "US",
      "elevation": 14,
      "lat": 30.3630008698,
      "lon": -89.3877029419,
      "tz": "America\/Chicago"
  },
  "K67A": {
      "icao": "K67A",
      "iata": "",
      "name": "Fort Deposit Lowndes County Airport",
      "city": "Fort Deposit",
      "state": "Alabama",
      "country": "US",
      "elevation": 490,
      "lat": 31.9724006653,
      "lon": -86.5915985107,
      "tz": "America\/Chicago"
  },
  "K67L": {
      "icao": "K67L",
      "iata": "",
      "name": "Mesquite Airport",
      "city": "Mesquite",
      "state": "Nevada",
      "country": "US",
      "elevation": 1978,
      "lat": 36.83499908,
      "lon": -114.0550003,
      "tz": "America\/Los_Angeles"
  },
  "K67R": {
      "icao": "K67R",
      "iata": "",
      "name": "Rio Grande City Municipal Airport",
      "city": "Rio Grande City",
      "state": "Texas",
      "country": "US",
      "elevation": 290,
      "lat": 26.4242992401,
      "lon": -98.8460998535,
      "tz": "America\/Chicago"
  },
  "K68F": {
      "icao": "K68F",
      "iata": "",
      "name": "Teague Municipal Airport",
      "city": "Teague",
      "state": "Texas",
      "country": "US",
      "elevation": 525,
      "lat": 31.6613006592,
      "lon": -96.3099975586,
      "tz": "America\/Chicago"
  },
  "K68J": {
      "icao": "K68J",
      "iata": "",
      "name": "Tallahassee Commercial Airport",
      "city": "Tallahassee \/Havana\/",
      "state": "Florida",
      "country": "US",
      "elevation": 155,
      "lat": 30.5473003387,
      "lon": -84.3738021851,
      "tz": "America\/New_York"
  },
  "K68S": {
      "icao": "K68S",
      "iata": "",
      "name": "Davenport Airport",
      "city": "Davenport",
      "state": "Washington",
      "country": "US",
      "elevation": 2421,
      "lat": 47.6535987854,
      "lon": -118.1679992676,
      "tz": "America\/Los_Angeles"
  },
  "K69K": {
      "icao": "K69K",
      "iata": "",
      "name": "Wamego Municipal Airport",
      "city": "Wamego",
      "state": "Kansas",
      "country": "US",
      "elevation": 966,
      "lat": 39.1972007751,
      "lon": -96.2589035034,
      "tz": "America\/Chicago"
  },
  "K69V": {
      "icao": "K69V",
      "iata": "",
      "name": "Huntington Municipal Airport",
      "city": "Huntington",
      "state": "Utah",
      "country": "US",
      "elevation": 5915,
      "lat": 39.3611984253,
      "lon": -110.9169998169,
      "tz": "America\/Denver"
  },
  "K6A1": {
      "icao": "K6A1",
      "iata": "",
      "name": "Butler Municipal Airport",
      "city": "Butler",
      "state": "Georgia",
      "country": "US",
      "elevation": 667,
      "lat": 32.5676002502,
      "lon": -84.2507019043,
      "tz": "America\/New_York"
  },
  "K6A2": {
      "icao": "K6A2",
      "iata": "",
      "name": "Griffin Spalding County Airport",
      "city": "Griffin",
      "state": "Georgia",
      "country": "US",
      "elevation": 958,
      "lat": 33.2270011902,
      "lon": -84.2749023438,
      "tz": "America\/New_York"
  },
  "K6A4": {
      "icao": "K6A4",
      "iata": "",
      "name": "Johnson County Airport",
      "city": "Mountain City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 2240,
      "lat": 36.4178009033,
      "lon": -81.8251037598,
      "tz": "America\/New_York"
  },
  "K6B9": {
      "icao": "K6B9",
      "iata": "",
      "name": "Skaneateles Aero Drome Airport",
      "city": "Skaneateles",
      "state": "New-York",
      "country": "US",
      "elevation": 1038,
      "lat": 42.9140014648,
      "lon": -76.4408035278,
      "tz": "America\/New_York"
  },
  "K6D1": {
      "icao": "K6D1",
      "iata": "",
      "name": "Brooten Municipal Airport",
      "city": "Brooten",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1305,
      "lat": 45.5,
      "lon": -95.1128005981,
      "tz": "America\/Chicago"
  },
  "K6D6": {
      "icao": "K6D6",
      "iata": "",
      "name": "Greenville Municipal Airport",
      "city": "Greenville",
      "state": "Michigan",
      "country": "US",
      "elevation": 855,
      "lat": 43.1422996521,
      "lon": -85.2537994385,
      "tz": "America\/Detroit"
  },
  "K6D8": {
      "icao": "K6D8",
      "iata": "",
      "name": "Barnes County Municipal Airport",
      "city": "Valley City",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1402,
      "lat": 46.94100189,
      "lon": -98.01760101,
      "tz": "America\/Chicago"
  },
  "K6D9": {
      "icao": "K6D9",
      "iata": "",
      "name": "Iosco County Airport",
      "city": "East Tawas",
      "state": "Michigan",
      "country": "US",
      "elevation": 606,
      "lat": 44.3128013611,
      "lon": -83.4223022461,
      "tz": "America\/Detroit"
  },
  "K6E5": {
      "icao": "K6E5",
      "iata": "",
      "name": "Wilder Airport",
      "city": "Desmet",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1729,
      "lat": 44.4308013916,
      "lon": -97.5612030029,
      "tz": "America\/Chicago"
  },
  "K6G0": {
      "icao": "K6G0",
      "iata": "",
      "name": "Athelone Williams Memorial Airport",
      "city": "Davison",
      "state": "Michigan",
      "country": "US",
      "elevation": 780,
      "lat": 43.0290985107,
      "lon": -83.5297012329,
      "tz": "America\/Detroit"
  },
  "K6G1": {
      "icao": "K6G1",
      "iata": "",
      "name": "Titusville Airport",
      "city": "Titusville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1600,
      "lat": 41.6087989807,
      "lon": -79.7413024902,
      "tz": "America\/New_York"
  },
  "K6G4": {
      "icao": "K6G4",
      "iata": "",
      "name": "Wynkoop Airport",
      "city": "Mount Vernon",
      "state": "Ohio",
      "country": "US",
      "elevation": 1041,
      "lat": 40.3651008606,
      "lon": -82.4956970215,
      "tz": "America\/New_York"
  },
  "K6G5": {
      "icao": "K6G5",
      "iata": "",
      "name": "Barnesville Bradfield Airport",
      "city": "Barnesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1312,
      "lat": 40.0023994446,
      "lon": -81.1918029785,
      "tz": "America\/New_York"
  },
  "K6I2": {
      "icao": "K6I2",
      "iata": "",
      "name": "Lebanon Springfield Airport",
      "city": "Springfield",
      "state": "Kentucky",
      "country": "US",
      "elevation": 866,
      "lat": 37.6334991455,
      "lon": -85.2417984009,
      "tz": "America\/New_York"
  },
  "K6I4": {
      "icao": "K6I4",
      "iata": "",
      "name": "Boone County Airport",
      "city": "Lebanon",
      "state": "Indiana",
      "country": "US",
      "elevation": 959,
      "lat": 40.0073013306,
      "lon": -86.4405975342,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K6I6": {
      "icao": "K6I6",
      "iata": "",
      "name": "Darby Dan Airport",
      "city": "Columbus",
      "state": "Ohio",
      "country": "US",
      "elevation": 928,
      "lat": 39.9420013428,
      "lon": -83.2049026489,
      "tz": "America\/New_York"
  },
  "K6J0": {
      "icao": "K6J0",
      "iata": "",
      "name": "Lexington County At Pelion Airport",
      "city": "Pelion",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 452,
      "lat": 33.7946014404,
      "lon": -81.2459030151,
      "tz": "America\/New_York"
  },
  "K6J2": {
      "icao": "K6J2",
      "iata": "",
      "name": "St George Airport",
      "city": "St George",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 85,
      "lat": 33.19549942,
      "lon": -80.50849915,
      "tz": "America\/New_York"
  },
  "K6J4": {
      "icao": "K6J4",
      "iata": "",
      "name": "Saluda County Airport",
      "city": "Saluda",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 555,
      "lat": 33.9267997742,
      "lon": -81.7946014404,
      "tz": "America\/New_York"
  },
  "K6J7": {
      "icao": "K6J7",
      "iata": "",
      "name": "Branhams Airport",
      "city": "Darlington",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 165,
      "lat": 34.2821006775,
      "lon": -79.9287033081,
      "tz": "America\/New_York"
  },
  "K6K3": {
      "icao": "K6K3",
      "iata": "",
      "name": "Creighton Municipal Airport",
      "city": "Creighton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1654,
      "lat": 42.47079849,
      "lon": -97.88369751,
      "tz": "America\/Chicago"
  },
  "K6K4": {
      "icao": "K6K4",
      "iata": "",
      "name": "Fairview Municipal Airport",
      "city": "Fairview",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1272,
      "lat": 36.2901000977,
      "lon": -98.4757995605,
      "tz": "America\/Chicago"
  },
  "K6K5": {
      "icao": "K6K5",
      "iata": "",
      "name": "Sisters Eagle Air Airport",
      "city": "Sisters",
      "state": "Oregon",
      "country": "US",
      "elevation": 3168,
      "lat": 44.304599762,
      "lon": -121.5390014648,
      "tz": "America\/Los_Angeles"
  },
  "K6L3": {
      "icao": "K6L3",
      "iata": "",
      "name": "Lisbon Municipal Airport",
      "city": "Lisbon",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1232,
      "lat": 46.44660187,
      "lon": -97.72730255,
      "tz": "America\/Chicago"
  },
  "K6L5": {
      "icao": "K6L5",
      "iata": "",
      "name": "Wishek Municipal Airport",
      "city": "Wishek",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2035,
      "lat": 46.2463989258,
      "lon": -99.537902832,
      "tz": "America\/Chicago"
  },
  "K6M0": {
      "icao": "K6M0",
      "iata": "",
      "name": "Hazen Municipal Airport",
      "city": "Hazen",
      "state": "Arkansas",
      "country": "US",
      "elevation": 230,
      "lat": 34.7593994141,
      "lon": -91.6380996704,
      "tz": "America\/Chicago"
  },
  "K6M2": {
      "icao": "K6M2",
      "iata": "",
      "name": "Horseshoe Bend Airport",
      "city": "Horseshoe Bend",
      "state": "Arkansas",
      "country": "US",
      "elevation": 782,
      "lat": 36.2214012146,
      "lon": -91.7555007935,
      "tz": "America\/Chicago"
  },
  "K6M6": {
      "icao": "K6M6",
      "iata": "",
      "name": "Lewis County Regional Airport",
      "city": "Monticello",
      "state": "Missouri",
      "country": "US",
      "elevation": 675,
      "lat": 40.12919998,
      "lon": -91.67829895,
      "tz": "America\/Chicago"
  },
  "K6M7": {
      "icao": "K6M7",
      "iata": "",
      "name": "Marianna Lee County Steve Edwards Field",
      "city": "Marianna",
      "state": "Arkansas",
      "country": "US",
      "elevation": 219,
      "lat": 34.78030014,
      "lon": -90.81060028,
      "tz": "America\/Chicago"
  },
  "K6R3": {
      "icao": "K6R3",
      "iata": "",
      "name": "Cleveland Municipal Airport",
      "city": "Cleveland",
      "state": "Texas",
      "country": "US",
      "elevation": 150,
      "lat": 30.3563995361,
      "lon": -95.0080032349,
      "tz": "America\/Chicago"
  },
  "K6R6": {
      "icao": "K6R6",
      "iata": "",
      "name": "Terrell County Airport",
      "city": "Dryden",
      "state": "Texas",
      "country": "US",
      "elevation": 2322,
      "lat": 30.045999527,
      "lon": -102.2129974365,
      "tz": "America\/Chicago"
  },
  "K6S0": {
      "icao": "K6S0",
      "iata": "",
      "name": "Big Timber Airport",
      "city": "Big Timber",
      "state": "Montana",
      "country": "US",
      "elevation": 4492,
      "lat": 45.8064002991,
      "lon": -109.9810028076,
      "tz": "America\/Denver"
  },
  "K6S1": {
      "icao": "K6S1",
      "iata": "",
      "name": "Bridger Municipal Airport",
      "city": "Bridger",
      "state": "Montana",
      "country": "US",
      "elevation": 3720,
      "lat": 45.2915992737,
      "lon": -108.9260025024,
      "tz": "America\/Denver"
  },
  "K6S2": {
      "icao": "K6S2",
      "iata": "FMU",
      "name": "Florence Municipal Airport",
      "city": "Florence",
      "state": "Oregon",
      "country": "US",
      "elevation": 51,
      "lat": 43.98279953,
      "lon": -124.111000061,
      "tz": "America\/Los_Angeles"
  },
  "K6S3": {
      "icao": "K6S3",
      "iata": "",
      "name": "Woltermann Memorial Airport",
      "city": "Columbus",
      "state": "Montana",
      "country": "US",
      "elevation": 3575,
      "lat": 45.6305007935,
      "lon": -109.238998413,
      "tz": "America\/Denver"
  },
  "K6S5": {
      "icao": "K6S5",
      "iata": "",
      "name": "Ravalli County Airport",
      "city": "Hamilton",
      "state": "Montana",
      "country": "US",
      "elevation": 3642,
      "lat": 46.251499176,
      "lon": -114.1259994507,
      "tz": "America\/Denver"
  },
  "K6S8": {
      "icao": "K6S8",
      "iata": "",
      "name": "Laurel Municipal Airport",
      "city": "Laurel",
      "state": "Montana",
      "country": "US",
      "elevation": 3517,
      "lat": 45.7030982971,
      "lon": -108.7610015869,
      "tz": "America\/Denver"
  },
  "K6U7": {
      "icao": "K6U7",
      "iata": "",
      "name": "Hysham Airport",
      "city": "Hysham",
      "state": "Montana",
      "country": "US",
      "elevation": 2624,
      "lat": 46.2893981934,
      "lon": -107.1959991455,
      "tz": "America\/Denver"
  },
  "K6V4": {
      "icao": "K6V4",
      "iata": "",
      "name": "Wall Municipal Airport",
      "city": "Wall",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2813,
      "lat": 43.9995002747,
      "lon": -102.2549972534,
      "tz": "America\/Denver"
  },
  "K6V5": {
      "icao": "K6V5",
      "iata": "",
      "name": "Bison Municipal Airport",
      "city": "Bison",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2785,
      "lat": 45.5186004639,
      "lon": -102.4670028687,
      "tz": "America\/Denver"
  },
  "K6Y1": {
      "icao": "K6Y1",
      "iata": "",
      "name": "Bois Blanc Airport",
      "city": "Bois Blanc Island",
      "state": "Michigan",
      "country": "US",
      "elevation": 664,
      "lat": 45.7663002,
      "lon": -84.50379944,
      "tz": "America\/Detroit"
  },
  "K70A": {
      "icao": "K70A",
      "iata": "",
      "name": "Freddie Jones Field",
      "city": "Linden",
      "state": "Alabama",
      "country": "US",
      "elevation": 161,
      "lat": 32.2667999268,
      "lon": -87.7181015015,
      "tz": "America\/Chicago"
  },
  "K70J": {
      "icao": "K70J",
      "iata": "",
      "name": "Cairo Grady County Airport",
      "city": "Cairo",
      "state": "Georgia",
      "country": "US",
      "elevation": 265,
      "lat": 30.8880004883,
      "lon": -84.1547012329,
      "tz": "America\/New_York"
  },
  "K71A": {
      "icao": "K71A",
      "iata": "",
      "name": "Pine Hill Municipal Airport",
      "city": "Pine Hill",
      "state": "Alabama",
      "country": "US",
      "elevation": 123,
      "lat": 31.9668006897,
      "lon": -87.5832977295,
      "tz": "America\/Chicago"
  },
  "K71J": {
      "icao": "K71J",
      "iata": "",
      "name": "Blackwell Field",
      "city": "Ozark",
      "state": "Alabama",
      "country": "US",
      "elevation": 356,
      "lat": 31.4311008453,
      "lon": -85.6192016602,
      "tz": "America\/Chicago"
  },
  "K71N": {
      "icao": "K71N",
      "iata": "",
      "name": "Sunbury Airport",
      "city": "Sunbury",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 450,
      "lat": 40.8917999268,
      "lon": -76.7789001465,
      "tz": "America\/New_York"
  },
  "K72F": {
      "icao": "K72F",
      "iata": "",
      "name": "Throckmorton Municipal Airport",
      "city": "Throckmorton",
      "state": "Texas",
      "country": "US",
      "elevation": 1273,
      "lat": 33.1792984009,
      "lon": -99.1498031616,
      "tz": "America\/Chicago"
  },
  "K73C": {
      "icao": "K73C",
      "iata": "",
      "name": "Lancaster Municipal Airport",
      "city": "Lancaster",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1008,
      "lat": 42.7804985046,
      "lon": -90.6809997559,
      "tz": "America\/Chicago"
  },
  "K74S": {
      "icao": "K74S",
      "iata": "",
      "name": "Anacortes Airport",
      "city": "Anacortes",
      "state": "Washington",
      "country": "US",
      "elevation": 241,
      "lat": 48.4990005493,
      "lon": -122.6620025635,
      "tz": "America\/Los_Angeles"
  },
  "K74V": {
      "icao": "K74V",
      "iata": "",
      "name": "Roosevelt Municipal Airport",
      "city": "Roosevelt",
      "state": "Utah",
      "country": "US",
      "elevation": 5172,
      "lat": 40.278301239,
      "lon": -110.0510025024,
      "tz": "America\/Denver"
  },
  "K75J": {
      "icao": "K75J",
      "iata": "",
      "name": "Turner County Airport",
      "city": "Ashburn",
      "state": "Georgia",
      "country": "US",
      "elevation": 389,
      "lat": 31.6854991913,
      "lon": -83.6321029663,
      "tz": "America\/New_York"
  },
  "K76F": {
      "icao": "K76F",
      "iata": "",
      "name": "Van Zandt County Regional Airport",
      "city": "Wills Point",
      "state": "Texas",
      "country": "US",
      "elevation": 518,
      "lat": 32.6814994812,
      "lon": -95.9841003418,
      "tz": "America\/Chicago"
  },
  "K76G": {
      "icao": "K76G",
      "iata": "",
      "name": "Marine City Airport",
      "city": "Marine City",
      "state": "Michigan",
      "country": "US",
      "elevation": 613,
      "lat": 42.7209014893,
      "lon": -82.595703125,
      "tz": "America\/Detroit"
  },
  "K77F": {
      "icao": "K77F",
      "iata": "",
      "name": "Winters Municipal Airport",
      "city": "Winters",
      "state": "Texas",
      "country": "US",
      "elevation": 1871,
      "lat": 31.9472007751,
      "lon": -99.9858016968,
      "tz": "America\/Chicago"
  },
  "K77G": {
      "icao": "K77G",
      "iata": "",
      "name": "Marlette Airport",
      "city": "Marlette",
      "state": "Michigan",
      "country": "US",
      "elevation": 895,
      "lat": 43.3117980957,
      "lon": -83.0908966064,
      "tz": "America\/Detroit"
  },
  "K77S": {
      "icao": "K77S",
      "iata": "",
      "name": "Hobby Field",
      "city": "Creswell",
      "state": "Oregon",
      "country": "US",
      "elevation": 538,
      "lat": 43.9308013916,
      "lon": -123.0070037842,
      "tz": "America\/Los_Angeles"
  },
  "K78R": {
      "icao": "K78R",
      "iata": "",
      "name": "San Augustine County Airport",
      "city": "San Augustine",
      "state": "Texas",
      "country": "US",
      "elevation": 443,
      "lat": 31.5396003723,
      "lon": -94.170501709,
      "tz": "America\/Chicago"
  },
  "K78Y": {
      "icao": "K78Y",
      "iata": "",
      "name": "Rankin Airport",
      "city": "Maryville",
      "state": "Missouri",
      "country": "US",
      "elevation": 976,
      "lat": 40.3333015442,
      "lon": -94.8336029053,
      "tz": "America\/Chicago"
  },
  "K79D": {
      "icao": "K79D",
      "iata": "",
      "name": "Philippi Barbour County Regional Airport",
      "city": "Philippi",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1755,
      "lat": 39.16619873,
      "lon": -80.06259918,
      "tz": "America\/New_York"
  },
  "K79J": {
      "icao": "K79J",
      "iata": "",
      "name": "South Alabama Regional At Bill Benton Field Airport",
      "city": "Andalusia\/Opp",
      "state": "Alabama",
      "country": "US",
      "elevation": 310,
      "lat": 31.3087997437,
      "lon": -86.3937988281,
      "tz": "America\/Chicago"
  },
  "K79S": {
      "icao": "K79S",
      "iata": "",
      "name": "Fort Benton Airport",
      "city": "Fort Benton",
      "state": "Montana",
      "country": "US",
      "elevation": 2869,
      "lat": 47.8451004028,
      "lon": -110.6360015869,
      "tz": "America\/Denver"
  },
  "K7A0": {
      "icao": "K7A0",
      "iata": "",
      "name": "Greensboro Municipal Airport",
      "city": "Greensboro",
      "state": "Alabama",
      "country": "US",
      "elevation": 180,
      "lat": 32.6814994812,
      "lon": -87.6621017456,
      "tz": "America\/Chicago"
  },
  "K7A3": {
      "icao": "K7A3",
      "iata": "",
      "name": "Lanett Municipal Airport",
      "city": "Lanett",
      "state": "Alabama",
      "country": "US",
      "elevation": 624,
      "lat": 32.8120002747,
      "lon": -85.229598999,
      "tz": "America\/Chicago"
  },
  "K7A4": {
      "icao": "K7A4",
      "iata": "",
      "name": "Foster Field",
      "city": "Apple River",
      "state": "Illinois",
      "country": "US",
      "elevation": 990,
      "lat": 42.4664001465,
      "lon": -90.1694030762,
      "tz": "America\/Chicago"
  },
  "K7A5": {
      "icao": "K7A5",
      "iata": "",
      "name": "Roanoke Municipal Airport",
      "city": "Roanoke",
      "state": "Alabama",
      "country": "US",
      "elevation": 907,
      "lat": 33.1292991638,
      "lon": -85.3666000366,
      "tz": "America\/Chicago"
  },
  "K7A6": {
      "icao": "K7A6",
      "iata": "",
      "name": "Stevenson Airport",
      "city": "Stevenson",
      "state": "Alabama",
      "country": "US",
      "elevation": 644,
      "lat": 34.8862991333,
      "lon": -85.8032989502,
      "tz": "America\/Chicago"
  },
  "K7A8": {
      "icao": "K7A8",
      "iata": "",
      "name": "Avery County Morrison Field",
      "city": "Spruce Pine",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2750,
      "lat": 35.9445991516,
      "lon": -81.9956970215,
      "tz": "America\/New_York"
  },
  "K7B2": {
      "icao": "K7B2",
      "iata": "",
      "name": "Northampton Airport",
      "city": "Northampton",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 121,
      "lat": 42.3280983,
      "lon": -72.61139679,
      "tz": "America\/New_York"
  },
  "K7B6": {
      "icao": "K7B6",
      "iata": "",
      "name": "Skylark Airpark",
      "city": "Warehouse Point",
      "state": "Connecticut",
      "country": "US",
      "elevation": 120,
      "lat": 41.9292984009,
      "lon": -72.5745010376,
      "tz": "America\/New_York"
  },
  "K7D3": {
      "icao": "K7D3",
      "iata": "",
      "name": "Baldwin Municipal Airport",
      "city": "Baldwin",
      "state": "Michigan",
      "country": "US",
      "elevation": 828,
      "lat": 43.8754997253,
      "lon": -85.8421020508,
      "tz": "America\/Detroit"
  },
  "K7D9": {
      "icao": "K7D9",
      "iata": "",
      "name": "Germack Airport",
      "city": "Geneva",
      "state": "Ohio",
      "country": "US",
      "elevation": 820,
      "lat": 41.7778015137,
      "lon": -80.9039993286,
      "tz": "America\/New_York"
  },
  "K7F3": {
      "icao": "K7F3",
      "iata": "",
      "name": "Caddo Mills Municipal Airport",
      "city": "Caddo Mills",
      "state": "Texas",
      "country": "US",
      "elevation": 542,
      "lat": 33.0362014771,
      "lon": -96.2431030273,
      "tz": "America\/Chicago"
  },
  "K7F5": {
      "icao": "K7F5",
      "iata": "",
      "name": "Canton Hackney Airport",
      "city": "Canton",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 32.5862007141,
      "lon": -95.862701416,
      "tz": "America\/Chicago"
  },
  "K7F7": {
      "icao": "K7F7",
      "iata": "",
      "name": "Clifton Municipal Isenhower Field",
      "city": "Clifton",
      "state": "Texas",
      "country": "US",
      "elevation": 760,
      "lat": 31.81679916,
      "lon": -97.56700134,
      "tz": "America\/Chicago"
  },
  "K7G0": {
      "icao": "K7G0",
      "iata": "",
      "name": "Ledgedale Airpark",
      "city": "Brockport",
      "state": "New-York",
      "country": "US",
      "elevation": 665,
      "lat": 43.18109894,
      "lon": -77.915802,
      "tz": "America\/New_York"
  },
  "K7G8": {
      "icao": "K7G8",
      "iata": "",
      "name": "Geauga County Airport",
      "city": "Middlefield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1174,
      "lat": 41.4496002197,
      "lon": -81.0628967285,
      "tz": "America\/New_York"
  },
  "K7G9": {
      "icao": "K7G9",
      "iata": "",
      "name": "Canton Municipal Airport",
      "city": "Canton",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1290,
      "lat": 43.3088989258,
      "lon": -96.5709991455,
      "tz": "America\/Chicago"
  },
  "K7I4": {
      "icao": "K7I4",
      "iata": "",
      "name": "Orleans Airport",
      "city": "Orleans",
      "state": "Indiana",
      "country": "US",
      "elevation": 655,
      "lat": 38.6584014893,
      "lon": -86.4430007935,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K7K4": {
      "icao": "K7K4",
      "iata": "",
      "name": "Ohio County Airport",
      "city": "Hartford",
      "state": "Kentucky",
      "country": "US",
      "elevation": 535,
      "lat": 37.4583015442,
      "lon": -86.8499984741,
      "tz": "America\/Chicago"
  },
  "K7K5": {
      "icao": "K7K5",
      "iata": "",
      "name": "Kenmare Municipal Airport",
      "city": "Kenmare",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1962,
      "lat": 48.6675987244,
      "lon": -102.047996521,
      "tz": "America\/Chicago"
  },
  "K7K8": {
      "icao": "K7K8",
      "iata": "",
      "name": "Martin Field",
      "city": "So Sioux City",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1100,
      "lat": 42.4542007446,
      "lon": -96.4725036621,
      "tz": "America\/Chicago"
  },
  "K7L2": {
      "icao": "K7L2",
      "iata": "",
      "name": "Linton Municipal Airport",
      "city": "Linton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1779,
      "lat": 46.21829987,
      "lon": -100.2450027,
      "tz": "America\/Chicago"
  },
  "K7L8": {
      "icao": "K7L8",
      "iata": "",
      "name": "Post Air Airport",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 861,
      "lat": 39.75,
      "lon": -86.0139007568,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "K7M1": {
      "icao": "K7M1",
      "iata": "",
      "name": "Mc Gehee Municipal Airport",
      "city": "Mc Gehee",
      "state": "Arkansas",
      "country": "US",
      "elevation": 141,
      "lat": 33.62020111,
      "lon": -91.3647995,
      "tz": "America\/Chicago"
  },
  "K7M2": {
      "icao": "K7M2",
      "iata": "",
      "name": "Mountain View Wilcox Memorial Field",
      "city": "Mountain View",
      "state": "Arkansas",
      "country": "US",
      "elevation": 805,
      "lat": 35.8644981384,
      "lon": -92.0903015137,
      "tz": "America\/Chicago"
  },
  "K7M3": {
      "icao": "K7M3",
      "iata": "",
      "name": "Bearce Airport",
      "city": "Mount Ida",
      "state": "Arkansas",
      "country": "US",
      "elevation": 644,
      "lat": 34.52930069,
      "lon": -93.52709961,
      "tz": "America\/Chicago"
  },
  "K7M4": {
      "icao": "K7M4",
      "iata": "",
      "name": "Osceola Municipal Airport",
      "city": "Osceola",
      "state": "Arkansas",
      "country": "US",
      "elevation": 234,
      "lat": 35.6911010742,
      "lon": -90.0101013184,
      "tz": "America\/Chicago"
  },
  "K7M5": {
      "icao": "K7M5",
      "iata": "",
      "name": "Ozark Franklin County Airport",
      "city": "Ozark",
      "state": "Arkansas",
      "country": "US",
      "elevation": 648,
      "lat": 35.5107002258,
      "lon": -93.839302063,
      "tz": "America\/Chicago"
  },
  "K7M8": {
      "icao": "K7M8",
      "iata": "",
      "name": "Rector Airport",
      "city": "Rector",
      "state": "Arkansas",
      "country": "US",
      "elevation": 281,
      "lat": 36.2500991821,
      "lon": -90.3195037842,
      "tz": "America\/Chicago"
  },
  "K7M9": {
      "icao": "K7M9",
      "iata": "",
      "name": "Salem Airport",
      "city": "Salem",
      "state": "Arkansas",
      "country": "US",
      "elevation": 787,
      "lat": 36.3558998108,
      "lon": -91.8310012817,
      "tz": "America\/Chicago"
  },
  "K7N1": {
      "icao": "K7N1",
      "iata": "",
      "name": "Corning Painted Post Airport",
      "city": "Corning",
      "state": "New-York",
      "country": "US",
      "elevation": 962,
      "lat": 42.17589951,
      "lon": -77.11219788,
      "tz": "America\/New_York"
  },
  "K7S0": {
      "icao": "K7S0",
      "iata": "",
      "name": "Ronan Airport",
      "city": "Ronan",
      "state": "Montana",
      "country": "US",
      "elevation": 3086,
      "lat": 47.567199707,
      "lon": -114.1009979248,
      "tz": "America\/Denver"
  },
  "K7S1": {
      "icao": "K7S1",
      "iata": "",
      "name": "Twin Bridges Airport",
      "city": "Twin Bridges",
      "state": "Montana",
      "country": "US",
      "elevation": 4777,
      "lat": 45.5337982178,
      "lon": -112.3030014038,
      "tz": "America\/Denver"
  },
  "K7S5": {
      "icao": "K7S5",
      "iata": "",
      "name": "Independence State Airport",
      "city": "Independence",
      "state": "Oregon",
      "country": "US",
      "elevation": 180,
      "lat": 44.8670005798,
      "lon": -123.1979980469,
      "tz": "America\/Los_Angeles"
  },
  "K7S6": {
      "icao": "K7S6",
      "iata": "",
      "name": "White Sulphur Springs Airport",
      "city": "White Sulphur Springs",
      "state": "Montana",
      "country": "US",
      "elevation": 5061,
      "lat": 46.5041007996,
      "lon": -110.9130020142,
      "tz": "America\/Denver"
  },
  "K7S7": {
      "icao": "K7S7",
      "iata": "",
      "name": "Valier Airport",
      "city": "Valier",
      "state": "Montana",
      "country": "US",
      "elevation": 3820,
      "lat": 48.2999992371,
      "lon": -112.2509994507,
      "tz": "America\/Denver"
  },
  "K7S9": {
      "icao": "K7S9",
      "iata": "",
      "name": "Lenhardt Airpark",
      "city": "Hubbard",
      "state": "Oregon",
      "country": "US",
      "elevation": 165,
      "lat": 45.1804008484,
      "lon": -122.7429962158,
      "tz": "America\/Los_Angeles"
  },
  "K7SP": {
      "icao": "K7SP",
      "iata": "",
      "name": "Seven Springs Airport",
      "city": "Seven Springs Borough",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2907,
      "lat": 40.0099983215,
      "lon": -79.3218994141,
      "tz": "America\/New_York"
  },
  "K7T7": {
      "icao": "K7T7",
      "iata": "",
      "name": "Skywest Inc Airport",
      "city": "Midland",
      "state": "Texas",
      "country": "US",
      "elevation": 2800,
      "lat": 31.858499527,
      "lon": -102.0739974976,
      "tz": "America\/Chicago"
  },
  "K7V2": {
      "icao": "K7V2",
      "iata": "",
      "name": "North Fork Valley Airport",
      "city": "Paonia",
      "state": "Colorado",
      "country": "US",
      "elevation": 5798,
      "lat": 38.8316993713,
      "lon": -107.6460037231,
      "tz": "America\/Denver"
  },
  "K7V3": {
      "icao": "K7V3",
      "iata": "",
      "name": "Big Foot Airfield",
      "city": "Walworth",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 951,
      "lat": 42.5256996155,
      "lon": -88.6529998779,
      "tz": "America\/Chicago"
  },
  "K7V6": {
      "icao": "K7V6",
      "iata": "",
      "name": "Camp Guernsey Airport",
      "city": "Guernsey",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4400,
      "lat": 42.259601593,
      "lon": -104.7279968262,
      "tz": "America\/Denver"
  },
  "K7V7": {
      "icao": "K7V7",
      "iata": "",
      "name": "Red Cloud Municipal Airport",
      "city": "Red Cloud",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1744,
      "lat": 40.08470154,
      "lon": -98.54060364,
      "tz": "America\/Chicago"
  },
  "K7V8": {
      "icao": "K7V8",
      "iata": "",
      "name": "Julesburg Municipal Airport",
      "city": "Julesburg",
      "state": "Colorado",
      "country": "US",
      "elevation": 3495,
      "lat": 40.97079849,
      "lon": -102.3150024,
      "tz": "America\/Denver"
  },
  "K7V9": {
      "icao": "K7V9",
      "iata": "",
      "name": "City of Las Animas Bent County Airport",
      "city": "Las Animas",
      "state": "Colorado",
      "country": "US",
      "elevation": 3915,
      "lat": 38.0527992249,
      "lon": -103.2369995117,
      "tz": "America\/Denver"
  },
  "K7W5": {
      "icao": "K7W5",
      "iata": "",
      "name": "Henry County Airport",
      "city": "Napoleon",
      "state": "Ohio",
      "country": "US",
      "elevation": 683,
      "lat": 41.3742980957,
      "lon": -84.0679016113,
      "tz": "America\/New_York"
  },
  "K7W6": {
      "icao": "K7W6",
      "iata": "",
      "name": "Hyde County Airport",
      "city": "Engelhard",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 8,
      "lat": 35.5624008179,
      "lon": -75.9552001953,
      "tz": "America\/New_York"
  },
  "K7Y4": {
      "icao": "K7Y4",
      "iata": "",
      "name": "Bagley Municipal Airport",
      "city": "Bagley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1500,
      "lat": 47.5250015259,
      "lon": -95.3608016968,
      "tz": "America\/Chicago"
  },
  "K80D": {
      "icao": "K80D",
      "iata": "",
      "name": "Clare County Airport",
      "city": "Harrison",
      "state": "Michigan",
      "country": "US",
      "elevation": 1142,
      "lat": 44.0527992249,
      "lon": -84.8125,
      "tz": "America\/Detroit"
  },
  "K80F": {
      "icao": "K80F",
      "iata": "ATE",
      "name": "Antlers Municipal Airport",
      "city": "Antlers",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 575,
      "lat": 34.1926002502,
      "lon": -95.6499023438,
      "tz": "America\/Chicago"
  },
  "K80T": {
      "icao": "K80T",
      "iata": "",
      "name": "Quincy Municipal Airport",
      "city": "Quincy",
      "state": "Washington",
      "country": "US",
      "elevation": 1271,
      "lat": 47.2116012573,
      "lon": -119.8399963379,
      "tz": "America\/Los_Angeles"
  },
  "K81B": {
      "icao": "K81B",
      "iata": "",
      "name": "Oxford County Regional Airport",
      "city": "Oxford",
      "state": "Maine",
      "country": "US",
      "elevation": 346,
      "lat": 44.1573982239,
      "lon": -70.481300354,
      "tz": "America\/New_York"
  },
  "K81R": {
      "icao": "K81R",
      "iata": "",
      "name": "San Saba County Municipal Airport",
      "city": "San Saba",
      "state": "Texas",
      "country": "US",
      "elevation": 1249,
      "lat": 31.235200882,
      "lon": -98.7170028687,
      "tz": "America\/Chicago"
  },
  "K82A": {
      "icao": "K82A",
      "iata": "",
      "name": "Marion County Airport",
      "city": "Buena Vista",
      "state": "Georgia",
      "country": "US",
      "elevation": 682,
      "lat": 32.2823982239,
      "lon": -84.5035018921,
      "tz": "America\/New_York"
  },
  "K82C": {
      "icao": "K82C",
      "iata": "",
      "name": "Mauston New Lisbon Union Airport",
      "city": "New Lisbon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 906,
      "lat": 43.8386993408,
      "lon": -90.1377029419,
      "tz": "America\/Chicago"
  },
  "K82J": {
      "icao": "K82J",
      "iata": "",
      "name": "Ferguson Airport",
      "city": "Pensacola",
      "state": "Florida",
      "country": "US",
      "elevation": 27,
      "lat": 30.3987998962,
      "lon": -87.3486022949,
      "tz": "America\/Chicago"
  },
  "K83D": {
      "icao": "K83D",
      "iata": "",
      "name": "Mackinac County Airport",
      "city": "St Ignace",
      "state": "Michigan",
      "country": "US",
      "elevation": 624,
      "lat": 45.89170074,
      "lon": -84.73809814,
      "tz": "America\/Detroit"
  },
  "K84D": {
      "icao": "K84D",
      "iata": "",
      "name": "Cheyenne Eagle Butte Airport",
      "city": "Eagle Butte",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2448,
      "lat": 44.9844017,
      "lon": -101.2509995,
      "tz": "America\/Denver"
  },
  "K84R": {
      "icao": "K84R",
      "iata": "",
      "name": "Smithville Crawford Municipal Airport",
      "city": "Smithville",
      "state": "Texas",
      "country": "US",
      "elevation": 323,
      "lat": 30.030500412,
      "lon": -97.1669006348,
      "tz": "America\/Chicago"
  },
  "K86F": {
      "icao": "K86F",
      "iata": "",
      "name": "Carnegie Municipal Airport",
      "city": "Carnegie",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1354,
      "lat": 35.1236000061,
      "lon": -98.5752029419,
      "tz": "America\/Chicago"
  },
  "K87I": {
      "icao": "K87I",
      "iata": "",
      "name": "Yazoo County Airport",
      "city": "Yazoo City",
      "state": "Mississippi",
      "country": "US",
      "elevation": 104,
      "lat": 32.8832015991,
      "lon": -90.4636001587,
      "tz": "America\/Chicago"
  },
  "K87K": {
      "icao": "K87K",
      "iata": "",
      "name": "El Dorado Springs Memorial Airport",
      "city": "El Dorado Springs",
      "state": "Missouri",
      "country": "US",
      "elevation": 931,
      "lat": 37.8567008972,
      "lon": -93.9990997314,
      "tz": "America\/Chicago"
  },
  "K87Y": {
      "icao": "K87Y",
      "iata": "",
      "name": "Blackhawk Airfield",
      "city": "Madison",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 920,
      "lat": 43.10490036,
      "lon": -89.1855011,
      "tz": "America\/Chicago"
  },
  "K88J": {
      "icao": "K88J",
      "iata": "",
      "name": "Allendale County Airport",
      "city": "Allendale",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 161,
      "lat": 32.995098114,
      "lon": -81.2702026367,
      "tz": "America\/New_York"
  },
  "K88M": {
      "icao": "K88M",
      "iata": "",
      "name": "Eureka Airport",
      "city": "Eureka",
      "state": "Montana",
      "country": "US",
      "elevation": 2668,
      "lat": 48.9738006592,
      "lon": -115.0759963989,
      "tz": "America\/Denver"
  },
  "K88R": {
      "icao": "K88R",
      "iata": "",
      "name": "Spicewood Airport",
      "city": "Spicewood",
      "state": "Texas",
      "country": "US",
      "elevation": 830,
      "lat": 30.4780006409,
      "lon": -98.1211013794,
      "tz": "America\/Chicago"
  },
  "K8A0": {
      "icao": "K8A0",
      "iata": "",
      "name": "Albertville Regional Thomas J Brumlik Field",
      "city": "Albertville",
      "state": "Alabama",
      "country": "US",
      "elevation": 1032,
      "lat": 34.22909927,
      "lon": -86.25579834,
      "tz": "America\/Chicago"
  },
  "K8A1": {
      "icao": "K8A1",
      "iata": "",
      "name": "Guntersville Municipal Joe Starnes Field",
      "city": "Guntersville",
      "state": "Alabama",
      "country": "US",
      "elevation": 615,
      "lat": 34.3993988037,
      "lon": -86.2702026367,
      "tz": "America\/Chicago"
  },
  "K8A3": {
      "icao": "K8A3",
      "iata": "",
      "name": "Livingston Municipal Airport",
      "city": "Livingston",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1372,
      "lat": 36.4121017456,
      "lon": -85.3115997314,
      "tz": "America\/Chicago"
  },
  "K8A6": {
      "icao": "K8A6",
      "iata": "",
      "name": "Wilgrove Air Park",
      "city": "Charlotte",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 799,
      "lat": 35.2137985229,
      "lon": -80.6700973511,
      "tz": "America\/New_York"
  },
  "K8B0": {
      "icao": "K8B0",
      "iata": "",
      "name": "Steven A. Bean Municipal Airport",
      "city": "Rangeley",
      "state": "Maine",
      "country": "US",
      "elevation": 1825,
      "lat": 44.9919014,
      "lon": -70.66459656,
      "tz": "America\/New_York"
  },
  "K8B1": {
      "icao": "K8B1",
      "iata": "",
      "name": "Hawthorne Feather Airpark",
      "city": "Hillsboro",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 600,
      "lat": 43.0666999817,
      "lon": -71.8994979858,
      "tz": "America\/New_York"
  },
  "K8B5": {
      "icao": "K8B5",
      "iata": "",
      "name": "Tanner Hiller Airport",
      "city": "Barre\/Barre Plains",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 584,
      "lat": 42.3563995361,
      "lon": -72.1300964355,
      "tz": "America\/New_York"
  },
  "K8C4": {
      "icao": "K8C4",
      "iata": "",
      "name": "Mathews Memorial Airport",
      "city": "Tipton",
      "state": "Iowa",
      "country": "US",
      "elevation": 840,
      "lat": 41.7634010315,
      "lon": -91.1529006958,
      "tz": "America\/Chicago"
  },
  "K8D1": {
      "icao": "K8D1",
      "iata": "",
      "name": "New Holstein Municipal Airport",
      "city": "New Holstein",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 992,
      "lat": 43.9441986084,
      "lon": -88.1135025024,
      "tz": "America\/Chicago"
  },
  "K8D3": {
      "icao": "K8D3",
      "iata": "",
      "name": "Sisseton Municipal Airport",
      "city": "Sisseton",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1161,
      "lat": 45.6707992554,
      "lon": -96.9962005615,
      "tz": "America\/Chicago"
  },
  "K8D7": {
      "icao": "K8D7",
      "iata": "",
      "name": "Clark County Airport",
      "city": "Clark",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1792,
      "lat": 44.8950004578,
      "lon": -97.7108001709,
      "tz": "America\/Chicago"
  },
  "K8F3": {
      "icao": "K8F3",
      "iata": "",
      "name": "Crosbyton Municipal Airport",
      "city": "Crosbyton",
      "state": "Texas",
      "country": "US",
      "elevation": 3018,
      "lat": 33.6237983704,
      "lon": -101.2409973145,
      "tz": "America\/Chicago"
  },
  "K8F5": {
      "icao": "K8F5",
      "iata": "",
      "name": "Greater Morris County Airport",
      "city": "Daingerfield",
      "state": "Texas",
      "country": "US",
      "elevation": 402,
      "lat": 33.1268005371,
      "lon": -94.6987991333,
      "tz": "America\/Chicago"
  },
  "K8F6": {
      "icao": "K8F6",
      "iata": "",
      "name": "Murdo Municipal Airport",
      "city": "Murdo",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2263,
      "lat": 43.8516998291,
      "lon": -100.7119979858,
      "tz": "America\/Chicago"
  },
  "K8G1": {
      "icao": "K8G1",
      "iata": "",
      "name": "Willard Airport",
      "city": "Willard",
      "state": "Ohio",
      "country": "US",
      "elevation": 967,
      "lat": 41.0387001038,
      "lon": -82.7246017456,
      "tz": "America\/New_York"
  },
  "K8G2": {
      "icao": "K8G2",
      "iata": "",
      "name": "Corry Lawrence Airport",
      "city": "Corry",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1766,
      "lat": 41.9076004028,
      "lon": -79.6410980225,
      "tz": "America\/New_York"
  },
  "K8G6": {
      "icao": "K8G6",
      "iata": "",
      "name": "Harrison County Airport",
      "city": "Cadiz",
      "state": "Ohio",
      "country": "US",
      "elevation": 1174,
      "lat": 40.2383995056,
      "lon": -81.0129013062,
      "tz": "America\/New_York"
  },
  "K8J7": {
      "icao": "K8J7",
      "iata": "",
      "name": "Tomlinson Field",
      "city": "New Rockford",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1533,
      "lat": 47.6963996887,
      "lon": -99.1312026978,
      "tz": "America\/Chicago"
  },
  "K8K2": {
      "icao": "K8K2",
      "iata": "",
      "name": "Harper Municipal Airport",
      "city": "Harper",
      "state": "Kansas",
      "country": "US",
      "elevation": 1427,
      "lat": 37.2781982422,
      "lon": -98.0435028076,
      "tz": "America\/Chicago"
  },
  "K8M1": {
      "icao": "K8M1",
      "iata": "",
      "name": "Booneville Baldwyn Airport",
      "city": "Booneville\/Baldwyn",
      "state": "Mississippi",
      "country": "US",
      "elevation": 384,
      "lat": 34.590801239,
      "lon": -88.6475982666,
      "tz": "America\/Chicago"
  },
  "K8M8": {
      "icao": "K8M8",
      "iata": "",
      "name": "Garland Airport",
      "city": "Lewiston",
      "state": "Michigan",
      "country": "US",
      "elevation": 1218,
      "lat": 44.8064994812,
      "lon": -84.2761993408,
      "tz": "America\/Detroit"
  },
  "K8M9": {
      "icao": "K8M9",
      "iata": "",
      "name": "Providence Webster County Airport",
      "city": "Providence",
      "state": "Kentucky",
      "country": "US",
      "elevation": 393,
      "lat": 37.4248008728,
      "lon": -87.7360992432,
      "tz": "America\/Chicago"
  },
  "K8N2": {
      "icao": "K8N2",
      "iata": "",
      "name": "Skydive Chicago Airport",
      "city": "Ottawa",
      "state": "Illinois",
      "country": "US",
      "elevation": 616,
      "lat": 41.3997993469,
      "lon": -88.7938995361,
      "tz": "America\/Chicago"
  },
  "K8N8": {
      "icao": "K8N8",
      "iata": "",
      "name": "Danville Airport",
      "city": "Danville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 559,
      "lat": 40.951499939,
      "lon": -76.6427001953,
      "tz": "America\/New_York"
  },
  "K8S0": {
      "icao": "K8S0",
      "iata": "",
      "name": "Starr Browning Airstrip",
      "city": "Browning",
      "state": "Montana",
      "country": "US",
      "elevation": 4655,
      "lat": 48.601398468,
      "lon": -113.1149978638,
      "tz": "America\/Denver"
  },
  "K8S1": {
      "icao": "K8S1",
      "iata": "",
      "name": "Polson Airport",
      "city": "Polson",
      "state": "Montana",
      "country": "US",
      "elevation": 2941,
      "lat": 47.695400238,
      "lon": -114.1849975586,
      "tz": "America\/Denver"
  },
  "K8T6": {
      "icao": "K8T6",
      "iata": "",
      "name": "Live Oak County Airport",
      "city": "George West",
      "state": "Texas",
      "country": "US",
      "elevation": 129,
      "lat": 28.3628005981,
      "lon": -98.1165008545,
      "tz": "America\/Chicago"
  },
  "K8T8": {
      "icao": "K8T8",
      "iata": "",
      "name": "San Geronimo Airpark",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 1040,
      "lat": 29.5104999542,
      "lon": -98.7984008789,
      "tz": "America\/Chicago"
  },
  "K8U6": {
      "icao": "K8U6",
      "iata": "",
      "name": "Terry Airport",
      "city": "Terry",
      "state": "Montana",
      "country": "US",
      "elevation": 2283,
      "lat": 46.7770996094,
      "lon": -105.31300354,
      "tz": "America\/Denver"
  },
  "K8U8": {
      "icao": "K8U8",
      "iata": "",
      "name": "Townsend Airport",
      "city": "Townsend",
      "state": "Montana",
      "country": "US",
      "elevation": 3893,
      "lat": 46.331199646,
      "lon": -111.483001709,
      "tz": "America\/Denver"
  },
  "K8V1": {
      "icao": "K8V1",
      "iata": "",
      "name": "Del Norte Municipal & County Airport",
      "city": "Del Norte",
      "state": "Colorado",
      "country": "US",
      "elevation": 7949,
      "lat": 37.71360016,
      "lon": -106.3539963,
      "tz": "America\/Denver"
  },
  "K8V2": {
      "icao": "K8V2",
      "iata": "",
      "name": "Stuart Atkinson Municipal Airport",
      "city": "Atkinson",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2130,
      "lat": 42.5625,
      "lon": -99.037902832,
      "tz": "America\/Chicago"
  },
  "K8V3": {
      "icao": "K8V3",
      "iata": "",
      "name": "Parkston Municipal Airport",
      "city": "Parkston",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1415,
      "lat": 43.3791999817,
      "lon": -97.9711990356,
      "tz": "America\/Chicago"
  },
  "K8V7": {
      "icao": "K8V7",
      "iata": "",
      "name": "Springfield Municipal Airport",
      "city": "Springfield",
      "state": "Colorado",
      "country": "US",
      "elevation": 4390,
      "lat": 37.4586982727,
      "lon": -102.6179962158,
      "tz": "America\/Denver"
  },
  "K8W2": {
      "icao": "K8W2",
      "iata": "",
      "name": "New Market Airport",
      "city": "New Market",
      "state": "Virginia",
      "country": "US",
      "elevation": 975,
      "lat": 38.6557006836,
      "lon": -78.7080993652,
      "tz": "America\/New_York"
  },
  "K8WC": {
      "icao": "K8WC",
      "iata": "",
      "name": "Washington County Airport",
      "city": "Potosi",
      "state": "Missouri",
      "country": "US",
      "elevation": 959,
      "lat": 37.9291992188,
      "lon": -90.7314987183,
      "tz": "America\/Chicago"
  },
  "K8Y6": {
      "icao": "K8Y6",
      "iata": "",
      "name": "Leaders Clear Lake Airport",
      "city": "Clear Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 990,
      "lat": 45.4444007874,
      "lon": -93.9710998535,
      "tz": "America\/Chicago"
  },
  "K90F": {
      "icao": "K90F",
      "iata": "",
      "name": "Broken Bow Airport",
      "city": "Broken Bow",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 404,
      "lat": 34.013999939,
      "lon": -94.7585983276,
      "tz": "America\/Chicago"
  },
  "K91F": {
      "icao": "K91F",
      "iata": "",
      "name": "Arrowhead Airport",
      "city": "Canadian",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 851,
      "lat": 35.1562995911,
      "lon": -95.6212997437,
      "tz": "America\/Chicago"
  },
  "K92F": {
      "icao": "K92F",
      "iata": "",
      "name": "Chattanooga Sky Harbor Airport",
      "city": "Chattanooga",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1135,
      "lat": 34.3686981201,
      "lon": -98.6820983887,
      "tz": "America\/Chicago"
  },
  "K93C": {
      "icao": "K93C",
      "iata": "",
      "name": "Richland Airport",
      "city": "Richland Center",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 742,
      "lat": 43.2834014893,
      "lon": -90.2983016968,
      "tz": "America\/Chicago"
  },
  "K93F": {
      "icao": "K93F",
      "iata": "",
      "name": "Mignon Laird Municipal Airport",
      "city": "Cheyenne",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2084,
      "lat": 35.6074981689,
      "lon": -99.7046966553,
      "tz": "America\/Chicago"
  },
  "K93Y": {
      "icao": "K93Y",
      "iata": "",
      "name": "David City Municipal Airport",
      "city": "David City",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1617,
      "lat": 41.23089981,
      "lon": -97.12290192,
      "tz": "America\/Chicago"
  },
  "K94E": {
      "icao": "K94E",
      "iata": "",
      "name": "Whiskey Creek Airport",
      "city": "Silver City",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6126,
      "lat": 32.7620010376,
      "lon": -108.2080001831,
      "tz": "America\/Denver"
  },
  "K94K": {
      "icao": "K94K",
      "iata": "",
      "name": "Cassville Municipal Airport",
      "city": "Cassville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1482,
      "lat": 36.6973991394,
      "lon": -93.9004974365,
      "tz": "America\/Chicago"
  },
  "K94R": {
      "icao": "K94R",
      "iata": "",
      "name": "Lackey Aviation Airport",
      "city": "Wharton",
      "state": "Texas",
      "country": "US",
      "elevation": 90,
      "lat": 29.2660999298,
      "lon": -96.0076980591,
      "tz": "America\/Chicago"
  },
  "K95D": {
      "icao": "K95D",
      "iata": "",
      "name": "Beulah Airport",
      "city": "Beulah",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1791,
      "lat": 47.2508010864,
      "lon": -101.8140029907,
      "tz": "America\/North_Dakota\/Beulah"
  },
  "K95E": {
      "icao": "K95E",
      "iata": "",
      "name": "Stallion Army Air Field",
      "city": "Socorro",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4925,
      "lat": 33.81900024,
      "lon": -106.6449966,
      "tz": "America\/Denver"
  },
  "K95F": {
      "icao": "K95F",
      "iata": "",
      "name": "Cleveland Municipal Airport",
      "city": "Cleveland",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 912,
      "lat": 36.2837982178,
      "lon": -96.4633026123,
      "tz": "America\/Chicago"
  },
  "K96D": {
      "icao": "K96D",
      "iata": "",
      "name": "Walhalla Municipal Airport",
      "city": "Walhalla",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 953,
      "lat": 48.9406013489,
      "lon": -97.9028015137,
      "tz": "America\/Chicago"
  },
  "K97M": {
      "icao": "K97M",
      "iata": "",
      "name": "Ekalaka Airport",
      "city": "Ekalaka",
      "state": "Montana",
      "country": "US",
      "elevation": 3503,
      "lat": 45.8777999878,
      "lon": -104.5370025635,
      "tz": "America\/Denver"
  },
  "K98D": {
      "icao": "K98D",
      "iata": "",
      "name": "Onida Municipal Airport",
      "city": "Onida",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1874,
      "lat": 44.7008018494,
      "lon": -100.1009979248,
      "tz": "America\/Chicago"
  },
  "K99N": {
      "icao": "K99N",
      "iata": "",
      "name": "Bamberg County Airport",
      "city": "Bamberg",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 231,
      "lat": 33.3045005798,
      "lon": -81.1083984375,
      "tz": "America\/New_York"
  },
  "K99V": {
      "icao": "K99V",
      "iata": "",
      "name": "Crawford Airport",
      "city": "Crawford",
      "state": "Colorado",
      "country": "US",
      "elevation": 6470,
      "lat": 38.7042007446,
      "lon": -107.6439971924,
      "tz": "America\/Denver"
  },
  "K9A0": {
      "icao": "K9A0",
      "iata": "",
      "name": "Lumpkin County Wimpys Airport",
      "city": "Dahlonega",
      "state": "Georgia",
      "country": "US",
      "elevation": 1311,
      "lat": 34.5792999268,
      "lon": -84.0206985474,
      "tz": "America\/New_York"
  },
  "K9A4": {
      "icao": "K9A4",
      "iata": "",
      "name": "Lawrence County Airport",
      "city": "Courtland",
      "state": "Alabama",
      "country": "US",
      "elevation": 588,
      "lat": 34.6594009399,
      "lon": -87.3488006592,
      "tz": "America\/Chicago"
  },
  "K9A5": {
      "icao": "K9A5",
      "iata": "",
      "name": "Barwick Lafayette Airport",
      "city": "Lafayette",
      "state": "Georgia",
      "country": "US",
      "elevation": 777,
      "lat": 34.6885986328,
      "lon": -85.290397644,
      "tz": "America\/New_York"
  },
  "K9C8": {
      "icao": "K9C8",
      "iata": "",
      "name": "Evart Municipal Airport",
      "city": "Evart",
      "state": "Michigan",
      "country": "US",
      "elevation": 1018,
      "lat": 43.8959007263,
      "lon": -85.2791976929,
      "tz": "America\/Detroit"
  },
  "K9D0": {
      "icao": "K9D0",
      "iata": "",
      "name": "Highmore Municipal Airport",
      "city": "Highmore",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1854,
      "lat": 44.5415992737,
      "lon": -99.4461975098,
      "tz": "America\/Chicago"
  },
  "K9D1": {
      "icao": "K9D1",
      "iata": "",
      "name": "Gregory Municipal Flynn Field",
      "city": "Gregory",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2168,
      "lat": 43.22169876,
      "lon": -99.40329742,
      "tz": "America\/Chicago"
  },
  "K9D2": {
      "icao": "K9D2",
      "iata": "",
      "name": "Harding County Airport",
      "city": "Buffalo",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2889,
      "lat": 45.5806007385,
      "lon": -103.5299987793,
      "tz": "America\/Denver"
  },
  "K9D4": {
      "icao": "K9D4",
      "iata": "",
      "name": "Deck Airport",
      "city": "Myerstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 523,
      "lat": 40.35179901,
      "lon": -76.32959747,
      "tz": "America\/New_York"
  },
  "K9D7": {
      "icao": "K9D7",
      "iata": "",
      "name": "Cando Municipal Airport",
      "city": "Cando",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1481,
      "lat": 48.47999954,
      "lon": -99.23590088,
      "tz": "America\/Chicago"
  },
  "K9D9": {
      "icao": "K9D9",
      "iata": "",
      "name": "Hastings Airport",
      "city": "Hastings",
      "state": "Michigan",
      "country": "US",
      "elevation": 801,
      "lat": 42.6636009216,
      "lon": -85.3462982178,
      "tz": "America\/Detroit"
  },
  "K9F0": {
      "icao": "K9F0",
      "iata": "",
      "name": "Dublin Municipal Airport",
      "city": "Dublin",
      "state": "Texas",
      "country": "US",
      "elevation": 1495,
      "lat": 32.0681991577,
      "lon": -98.325302124,
      "tz": "America\/Chicago"
  },
  "K9F8": {
      "icao": "K9F8",
      "iata": "",
      "name": "Hoven Municipal Airport",
      "city": "Hoven",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1884,
      "lat": 45.257598877,
      "lon": -99.7977981567,
      "tz": "America\/Chicago"
  },
  "K9F9": {
      "icao": "K9F9",
      "iata": "",
      "name": "Sycamore Strip",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 760,
      "lat": 32.6285018921,
      "lon": -97.3535995483,
      "tz": "America\/Chicago"
  },
  "K9G2": {
      "icao": "K9G2",
      "iata": "",
      "name": "Prices Airport",
      "city": "Linden",
      "state": "Michigan",
      "country": "US",
      "elevation": 920,
      "lat": 42.807598114,
      "lon": -83.7698974609,
      "tz": "America\/Detroit"
  },
  "K9G3": {
      "icao": "K9G3",
      "iata": "",
      "name": "Akron Airport",
      "city": "Akron",
      "state": "New-York",
      "country": "US",
      "elevation": 840,
      "lat": 43.0210990906,
      "lon": -78.4824981689,
      "tz": "America\/New_York"
  },
  "K9G8": {
      "icao": "K9G8",
      "iata": "",
      "name": "Ebensburg Airport",
      "city": "Ebensburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2099,
      "lat": 40.4612007141,
      "lon": -78.7751998901,
      "tz": "America\/New_York"
  },
  "K9K7": {
      "icao": "K9K7",
      "iata": "",
      "name": "Ellsworth Municipal Airport",
      "city": "Ellsworth",
      "state": "Kansas",
      "country": "US",
      "elevation": 1615,
      "lat": 38.7504005432,
      "lon": -98.2293014526,
      "tz": "America\/Chicago"
  },
  "K9K8": {
      "icao": "K9K8",
      "iata": "",
      "name": "Kingman Airport Clyde Cessna Field",
      "city": "Kingman",
      "state": "Kansas",
      "country": "US",
      "elevation": 1607,
      "lat": 37.66899872,
      "lon": -98.12390137,
      "tz": "America\/Chicago"
  },
  "K9L2": {
      "icao": "K9L2",
      "iata": "",
      "name": "Edwards Af Aux North Base Airport",
      "city": "Edwards",
      "state": "California",
      "country": "US",
      "elevation": 2299,
      "lat": 34.9907989502,
      "lon": -117.8629989624,
      "tz": "America\/Los_Angeles"
  },
  "K9M4": {
      "icao": "K9M4",
      "iata": "",
      "name": "Ackerman Choctaw County Airport",
      "city": "Ackerman",
      "state": "Mississippi",
      "country": "US",
      "elevation": 552,
      "lat": 33.3035011292,
      "lon": -89.2284011841,
      "tz": "America\/Chicago"
  },
  "K9M6": {
      "icao": "K9M6",
      "iata": "",
      "name": "Kelly Airport",
      "city": "Oak Grove",
      "state": "Louisiana",
      "country": "US",
      "elevation": 112,
      "lat": 32.8492012024,
      "lon": -91.4039001465,
      "tz": "America\/Chicago"
  },
  "K9M8": {
      "icao": "K9M8",
      "iata": "",
      "name": "Sheridan Municipal Airport",
      "city": "Sheridan",
      "state": "Arkansas",
      "country": "US",
      "elevation": 232,
      "lat": 34.3283996582,
      "lon": -92.3509979248,
      "tz": "America\/Chicago"
  },
  "K9S2": {
      "icao": "K9S2",
      "iata": "",
      "name": "Scobey Airport",
      "city": "Scobey",
      "state": "Montana",
      "country": "US",
      "elevation": 2432,
      "lat": 48.8077011108,
      "lon": -105.4390029907,
      "tz": "America\/Denver"
  },
  "K9S4": {
      "icao": "K9S4",
      "iata": "",
      "name": "Mineral County Airport",
      "city": "Superior",
      "state": "Montana",
      "country": "US",
      "elevation": 2787,
      "lat": 47.1683006287,
      "lon": -114.8539962769,
      "tz": "America\/Denver"
  },
  "K9S5": {
      "icao": "K9S5",
      "iata": "",
      "name": "Three Forks Airport",
      "city": "Three Forks",
      "state": "Montana",
      "country": "US",
      "elevation": 4089,
      "lat": 45.8781013489,
      "lon": -111.5690002441,
      "tz": "America\/Denver"
  },
  "K9S9": {
      "icao": "K9S9",
      "iata": "",
      "name": "Lexington Airport",
      "city": "Lexington",
      "state": "Oregon",
      "country": "US",
      "elevation": 1634,
      "lat": 45.4541015625,
      "lon": -119.6900024414,
      "tz": "America\/Los_Angeles"
  },
  "K9U0": {
      "icao": "K9U0",
      "iata": "",
      "name": "Turner Airport",
      "city": "Turner",
      "state": "Montana",
      "country": "US",
      "elevation": 3049,
      "lat": 48.8541984558,
      "lon": -108.408996582,
      "tz": "America\/Denver"
  },
  "K9U4": {
      "icao": "K9U4",
      "iata": "",
      "name": "Dixon Airport",
      "city": "Dixon",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6520,
      "lat": 41.0382995605,
      "lon": -107.4970016479,
      "tz": "America\/Denver"
  },
  "K9U7": {
      "icao": "K9U7",
      "iata": "",
      "name": "Currant Ranch Airport",
      "city": "Currant",
      "state": "Nevada",
      "country": "US",
      "elevation": 5181,
      "lat": 38.736000061,
      "lon": -115.4800033569,
      "tz": "America\/Los_Angeles"
  },
  "K9V5": {
      "icao": "K9V5",
      "iata": "",
      "name": "Modisett Airport",
      "city": "Rushville",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3751,
      "lat": 42.73749924,
      "lon": -102.4449997,
      "tz": "America\/Denver"
  },
  "K9V6": {
      "icao": "K9V6",
      "iata": "",
      "name": "Martin Municipal Airport",
      "city": "Martin",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3293,
      "lat": 43.165599823,
      "lon": -101.7129974365,
      "tz": "America\/Denver"
  },
  "K9V7": {
      "icao": "K9V7",
      "iata": "",
      "name": "Eads Municipal Airport",
      "city": "Eads",
      "state": "Colorado",
      "country": "US",
      "elevation": 4245,
      "lat": 38.4751014709,
      "lon": -102.8109970093,
      "tz": "America\/Denver"
  },
  "K9V9": {
      "icao": "K9V9",
      "iata": "",
      "name": "Chamberlain Municipal Airport",
      "city": "Chamberlain",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1695,
      "lat": 43.7661018372,
      "lon": -99.3212966919,
      "tz": "America\/Chicago"
  },
  "K9X1": {
      "icao": "K9X1",
      "iata": "",
      "name": "Williams Airport",
      "city": "Porter",
      "state": "Texas",
      "country": "US",
      "elevation": 122,
      "lat": 30.1534004211,
      "lon": -95.3219985962,
      "tz": "America\/Chicago"
  },
  "K9Y1": {
      "icao": "K9Y1",
      "iata": "",
      "name": "Weydahl Field",
      "city": "Killdeer",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2256,
      "lat": 47.3927993774,
      "lon": -102.7710037231,
      "tz": "America\/Denver"
  },
  "KA08": {
      "icao": "KA08",
      "iata": "",
      "name": "Vaiden Field",
      "city": "Marion",
      "state": "Alabama",
      "country": "US",
      "elevation": 225,
      "lat": 32.5124015808,
      "lon": -87.385597229,
      "tz": "America\/Chicago"
  },
  "KA09": {
      "icao": "KA09",
      "iata": "",
      "name": "Eagle Airpark",
      "city": "Bullhead City",
      "state": "Arizona",
      "country": "US",
      "elevation": 485,
      "lat": 34.8875007629,
      "lon": -114.6159973145,
      "tz": "America\/Los_Angeles"
  },
  "KA20": {
      "icao": "KA20",
      "iata": "",
      "name": "Sun Valley Airport",
      "city": "Bullhead City",
      "state": "Arizona",
      "country": "US",
      "elevation": 725,
      "lat": 35.0125007629,
      "lon": -114.56300354,
      "tz": "America\/Phoenix"
  },
  "KA24": {
      "icao": "KA24",
      "iata": "",
      "name": "California Pines Airport",
      "city": "Alturas",
      "state": "California",
      "country": "US",
      "elevation": 4398,
      "lat": 41.412399292,
      "lon": -120.6839981079,
      "tz": "America\/Los_Angeles"
  },
  "KA30": {
      "icao": "KA30",
      "iata": "",
      "name": "Scott Valley Airport",
      "city": "Fort Jones",
      "state": "California",
      "country": "US",
      "elevation": 2728,
      "lat": 41.5582008362,
      "lon": -122.8550033569,
      "tz": "America\/Los_Angeles"
  },
  "KA32": {
      "icao": "KA32",
      "iata": "",
      "name": "Butte Valley Airport",
      "city": "Dorris",
      "state": "California",
      "country": "US",
      "elevation": 4243,
      "lat": 41.8871994019,
      "lon": -121.9759979248,
      "tz": "America\/Los_Angeles"
  },
  "KA34": {
      "icao": "KA34",
      "iata": "",
      "name": "Dayton Valley Airpark",
      "city": "Dayton\/Carson City",
      "state": "Nevada",
      "country": "US",
      "elevation": 4414,
      "lat": 39.2383995056,
      "lon": -119.5550003052,
      "tz": "America\/Los_Angeles"
  },
  "KA39": {
      "icao": "KA39",
      "iata": "",
      "name": "Phoenix Regional Airport",
      "city": "Phoenix",
      "state": "Arizona",
      "country": "US",
      "elevation": 1300,
      "lat": 32.9916992188,
      "lon": -111.9209976196,
      "tz": "America\/Phoenix"
  },
  "KA50": {
      "icao": "KA50",
      "iata": "",
      "name": "Colorado Springs East Airport",
      "city": "Ellicott",
      "state": "Colorado",
      "country": "US",
      "elevation": 6145,
      "lat": 38.8744010925,
      "lon": -104.4100036621,
      "tz": "America\/Denver"
  },
  "KAAA": {
      "icao": "KAAA",
      "iata": "",
      "name": "Logan County Airport",
      "city": "Lincoln",
      "state": "Illinois",
      "country": "US",
      "elevation": 597,
      "lat": 40.1586990356,
      "lon": -89.3349990845,
      "tz": "America\/Chicago"
  },
  "KAAF": {
      "icao": "KAAF",
      "iata": "AAF",
      "name": "Apalachicola Regional Airport",
      "city": "Apalachicola",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 29.72750092,
      "lon": -85.02749634,
      "tz": "America\/New_York"
  },
  "KAAO": {
      "icao": "KAAO",
      "iata": "",
      "name": "Colonel James Jabara Airport",
      "city": "Wichita",
      "state": "Kansas",
      "country": "US",
      "elevation": 1421,
      "lat": 37.74760056,
      "lon": -97.22109985,
      "tz": "America\/Chicago"
  },
  "KAAS": {
      "icao": "KAAS",
      "iata": "",
      "name": "Taylor County Airport",
      "city": "Campbellsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 921,
      "lat": 37.3582992554,
      "lon": -85.3094024658,
      "tz": "America\/New_York"
  },
  "KAAT": {
      "icao": "KAAT",
      "iata": "",
      "name": "Alturas Municipal Airport",
      "city": "Alturas",
      "state": "California",
      "country": "US",
      "elevation": 4378,
      "lat": 41.48300171,
      "lon": -120.5650024,
      "tz": "America\/Los_Angeles"
  },
  "KABE": {
      "icao": "KABE",
      "iata": "ABE",
      "name": "Lehigh Valley International Airport",
      "city": "Allentown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 393,
      "lat": 40.6520996094,
      "lon": -75.4408035278,
      "tz": "America\/New_York"
  },
  "KABI": {
      "icao": "KABI",
      "iata": "ABI",
      "name": "Abilene Regional Airport",
      "city": "Abilene",
      "state": "Texas",
      "country": "US",
      "elevation": 1791,
      "lat": 32.4113006592,
      "lon": -99.6819000244,
      "tz": "America\/Chicago"
  },
  "KABQ": {
      "icao": "KABQ",
      "iata": "ABQ",
      "name": "Albuquerque International Sunport Airport",
      "city": "Albuquerque",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5355,
      "lat": 35.0401992798,
      "lon": -106.6090011597,
      "tz": "America\/Denver"
  },
  "KABR": {
      "icao": "KABR",
      "iata": "ABR",
      "name": "Aberdeen Regional Airport",
      "city": "Aberdeen",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1302,
      "lat": 45.4491004944,
      "lon": -98.4217987061,
      "tz": "America\/Chicago"
  },
  "KABY": {
      "icao": "KABY",
      "iata": "ABY",
      "name": "Southwest Georgia Regional Airport",
      "city": "Albany",
      "state": "Georgia",
      "country": "US",
      "elevation": 197,
      "lat": 31.5354995728,
      "lon": -84.1945037842,
      "tz": "America\/New_York"
  },
  "KACB": {
      "icao": "KACB",
      "iata": "ACB",
      "name": "Antrim County Airport",
      "city": "Bellaire",
      "state": "Michigan",
      "country": "US",
      "elevation": 623,
      "lat": 44.9886016846,
      "lon": -85.1984024048,
      "tz": "America\/Detroit"
  },
  "KACJ": {
      "icao": "KACJ",
      "iata": "",
      "name": "Souther Field",
      "city": "Americus",
      "state": "Georgia",
      "country": "US",
      "elevation": 468,
      "lat": 32.1108017,
      "lon": -84.18890381,
      "tz": "America\/New_York"
  },
  "KACK": {
      "icao": "KACK",
      "iata": "ACK",
      "name": "Nantucket Memorial Airport",
      "city": "Nantucket",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 47,
      "lat": 41.25310135,
      "lon": -70.06020355,
      "tz": "America\/New_York"
  },
  "KACP": {
      "icao": "KACP",
      "iata": "",
      "name": "Allen Parish Airport",
      "city": "Oakdale",
      "state": "Louisiana",
      "country": "US",
      "elevation": 107,
      "lat": 30.7502994537,
      "lon": -92.6883010864,
      "tz": "America\/Chicago"
  },
  "KACQ": {
      "icao": "KACQ",
      "iata": "",
      "name": "Waseca Municipal Airport",
      "city": "Waseca",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1126,
      "lat": 44.0735015869,
      "lon": -93.5529022217,
      "tz": "America\/Chicago"
  },
  "KACT": {
      "icao": "KACT",
      "iata": "ACT",
      "name": "Waco Regional Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 516,
      "lat": 31.6112995148,
      "lon": -97.2304992676,
      "tz": "America\/Chicago"
  },
  "KACV": {
      "icao": "KACV",
      "iata": "ACV",
      "name": "Arcata Airport",
      "city": "Arcata\/Eureka",
      "state": "California",
      "country": "US",
      "elevation": 221,
      "lat": 40.978099823,
      "lon": -124.1090011597,
      "tz": "America\/Los_Angeles"
  },
  "KACY": {
      "icao": "KACY",
      "iata": "ACY",
      "name": "Atlantic City International Airport",
      "city": "Atlantic City",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 75,
      "lat": 39.4575996399,
      "lon": -74.5772018433,
      "tz": "America\/New_York"
  },
  "KACZ": {
      "icao": "KACZ",
      "iata": "",
      "name": "Henderson Field",
      "city": "Wallace",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 39,
      "lat": 34.7178993225,
      "lon": -78.0036010742,
      "tz": "America\/New_York"
  },
  "KADC": {
      "icao": "KADC",
      "iata": "",
      "name": "Wadena Municipal Airport",
      "city": "Wadena",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1369,
      "lat": 46.4502983093,
      "lon": -95.2109985352,
      "tz": "America\/Chicago"
  },
  "KADG": {
      "icao": "KADG",
      "iata": "ADG",
      "name": "Lenawee County Airport",
      "city": "Adrian",
      "state": "Michigan",
      "country": "US",
      "elevation": 798,
      "lat": 41.8676986694,
      "lon": -84.0773010254,
      "tz": "America\/Detroit"
  },
  "KADH": {
      "icao": "KADH",
      "iata": "ADT",
      "name": "Ada Municipal Airport",
      "city": "Ada",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1016,
      "lat": 34.8042984009,
      "lon": -96.6713027954,
      "tz": "America\/Chicago"
  },
  "KADM": {
      "icao": "KADM",
      "iata": "ADM",
      "name": "Ardmore Municipal Airport",
      "city": "Ardmore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 777,
      "lat": 34.30301,
      "lon": -97.0196342,
      "tz": "America\/Chicago"
  },
  "KADS": {
      "icao": "KADS",
      "iata": "ADS",
      "name": "Addison Airport",
      "city": "Dallas",
      "state": "Texas",
      "country": "US",
      "elevation": 644,
      "lat": 32.9686012268,
      "lon": -96.8364028931,
      "tz": "America\/Chicago"
  },
  "KADT": {
      "icao": "KADT",
      "iata": "",
      "name": "Atwood-Rawlins County City Airport",
      "city": "Atwood",
      "state": "Kansas",
      "country": "US",
      "elevation": 2991,
      "lat": 39.84009933,
      "lon": -101.0419998,
      "tz": "America\/Chicago"
  },
  "KADU": {
      "icao": "KADU",
      "iata": "",
      "name": "Audubon County Airport",
      "city": "Audubon",
      "state": "Iowa",
      "country": "US",
      "elevation": 1287,
      "lat": 41.7014007568,
      "lon": -94.920501709,
      "tz": "America\/Chicago"
  },
  "KADW": {
      "icao": "KADW",
      "iata": "ADW",
      "name": "Andrews Air Force Base",
      "city": "Camp Springs",
      "state": "Maryland",
      "country": "US",
      "elevation": 280,
      "lat": 38.810798645,
      "lon": -76.8669967651,
      "tz": "America\/New_York"
  },
  "KAEG": {
      "icao": "KAEG",
      "iata": "",
      "name": "Double Eagle II Airport",
      "city": "Albuquerque",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5837,
      "lat": 35.145198822,
      "lon": -106.7949981689,
      "tz": "America\/Denver"
  },
  "KAEJ": {
      "icao": "KAEJ",
      "iata": "",
      "name": "Central Colorado Regional Airport",
      "city": "Buena Vista",
      "state": "Colorado",
      "country": "US",
      "elevation": 7946,
      "lat": 38.814201355,
      "lon": -106.1210021973,
      "tz": "America\/Denver"
  },
  "KAEL": {
      "icao": "KAEL",
      "iata": "AEL",
      "name": "Albert Lea Municipal Airport",
      "city": "Albert Lea",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1260,
      "lat": 43.68149948,
      "lon": -93.36720276,
      "tz": "America\/Chicago"
  },
  "KAEX": {
      "icao": "KAEX",
      "iata": "AEX",
      "name": "Alexandria International Airport",
      "city": "Alexandria",
      "state": "Louisiana",
      "country": "US",
      "elevation": 89,
      "lat": 31.3274002075,
      "lon": -92.5497970581,
      "tz": "America\/Chicago"
  },
  "KAFF": {
      "icao": "KAFF",
      "iata": "AFF",
      "name": "USAF Academy Airfield",
      "city": "Colorado Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 6572,
      "lat": 38.96969986,
      "lon": -104.8130035,
      "tz": "America\/Denver"
  },
  "KAFJ": {
      "icao": "KAFJ",
      "iata": "WSG",
      "name": "Washington County Airport",
      "city": "Washington",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1184,
      "lat": 40.1365013123,
      "lon": -80.2901992798,
      "tz": "America\/New_York"
  },
  "KAFK": {
      "icao": "KAFK",
      "iata": "",
      "name": "Nebraska City Municipal Airport",
      "city": "Nebraska City",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1165,
      "lat": 40.60689926,
      "lon": -95.86569977,
      "tz": "America\/Chicago"
  },
  "KAFN": {
      "icao": "KAFN",
      "iata": "AFN",
      "name": "Jaffrey Airport Silver Ranch Airport",
      "city": "Jaffrey",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 1040,
      "lat": 42.8050994873,
      "lon": -72.0029983521,
      "tz": "America\/New_York"
  },
  "KAFO": {
      "icao": "KAFO",
      "iata": "AFO",
      "name": "Afton Municipal Airport",
      "city": "Afton",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6221,
      "lat": 42.7112007141,
      "lon": -110.942001343,
      "tz": "America\/Denver"
  },
  "KAFP": {
      "icao": "KAFP",
      "iata": "",
      "name": "Anson County Airport",
      "city": "Wadesboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 300,
      "lat": 35.0205993652,
      "lon": -80.0771026611,
      "tz": "America\/New_York"
  },
  "KAFW": {
      "icao": "KAFW",
      "iata": "AFW",
      "name": "Fort Worth Alliance Airport",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 722,
      "lat": 32.9875984192,
      "lon": -97.3188018799,
      "tz": "America\/Chicago"
  },
  "KAGC": {
      "icao": "KAGC",
      "iata": "AGC",
      "name": "Allegheny County Airport",
      "city": "Pittsburgh",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1252,
      "lat": 40.3544006348,
      "lon": -79.9301986694,
      "tz": "America\/New_York"
  },
  "KAGO": {
      "icao": "KAGO",
      "iata": "AGO",
      "name": "Magnolia Municipal Airport",
      "city": "Magnolia",
      "state": "Arkansas",
      "country": "US",
      "elevation": 319,
      "lat": 33.22800064,
      "lon": -93.21700287,
      "tz": "America\/Chicago"
  },
  "KAGR": {
      "icao": "KAGR",
      "iata": "",
      "name": "MacDill Air Force Base Auxiliary Field",
      "city": "Avon Park",
      "state": "Florida",
      "country": "US",
      "elevation": 68,
      "lat": 27.6506004333,
      "lon": -81.3494033813,
      "tz": "America\/New_York"
  },
  "KAGS": {
      "icao": "KAGS",
      "iata": "AGS",
      "name": "Augusta Regional At Bush Field",
      "city": "Augusta",
      "state": "Georgia",
      "country": "US",
      "elevation": 144,
      "lat": 33.3698997498,
      "lon": -81.9645004272,
      "tz": "America\/New_York"
  },
  "KAGZ": {
      "icao": "KAGZ",
      "iata": "",
      "name": "Wagner Municipal Airport",
      "city": "Wagner",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1475,
      "lat": 43.0633010864,
      "lon": -98.2962036133,
      "tz": "America\/Chicago"
  },
  "KAHC": {
      "icao": "KAHC",
      "iata": "AHC",
      "name": "Amedee Army Air Field",
      "city": "Herlong",
      "state": "California",
      "country": "US",
      "elevation": 4012,
      "lat": 40.26620102,
      "lon": -120.1529999,
      "tz": "America\/Los_Angeles"
  },
  "KAHH": {
      "icao": "KAHH",
      "iata": "AHH",
      "name": "Amery Municipal Airport",
      "city": "Amery",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1088,
      "lat": 45.2811012268,
      "lon": -92.3753967285,
      "tz": "America\/Chicago"
  },
  "KAHN": {
      "icao": "KAHN",
      "iata": "AHN",
      "name": "Athens Ben Epps Airport",
      "city": "Athens",
      "state": "Georgia",
      "country": "US",
      "elevation": 808,
      "lat": 33.948600769,
      "lon": -83.3263015747,
      "tz": "America\/New_York"
  },
  "KAHQ": {
      "icao": "KAHQ",
      "iata": "",
      "name": "Wahoo Municipal Airport",
      "city": "Wahoo",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1224,
      "lat": 41.2412986755,
      "lon": -96.59400177,
      "tz": "America\/Chicago"
  },
  "KAIA": {
      "icao": "KAIA",
      "iata": "AIA",
      "name": "Alliance Municipal Airport",
      "city": "Alliance",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3931,
      "lat": 42.0531997681,
      "lon": -102.804000854,
      "tz": "America\/Denver"
  },
  "KAIB": {
      "icao": "KAIB",
      "iata": "",
      "name": "Hopkins Field",
      "city": "Nucla",
      "state": "Colorado",
      "country": "US",
      "elevation": 5940,
      "lat": 38.2388000488,
      "lon": -108.56300354,
      "tz": "America\/Denver"
  },
  "KAID": {
      "icao": "KAID",
      "iata": "AID",
      "name": "Anderson Municipal Darlington Field",
      "city": "Anderson",
      "state": "Indiana",
      "country": "US",
      "elevation": 919,
      "lat": 40.1086006165,
      "lon": -85.6129989624,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KAIG": {
      "icao": "KAIG",
      "iata": "",
      "name": "Langlade County Airport",
      "city": "Antigo",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1521,
      "lat": 45.1542015076,
      "lon": -89.1107025146,
      "tz": "America\/Chicago"
  },
  "KAIK": {
      "icao": "KAIK",
      "iata": "AIK",
      "name": "Aiken Municipal Airport",
      "city": "Aiken",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 528,
      "lat": 33.6493988037,
      "lon": -81.6849975586,
      "tz": "America\/New_York"
  },
  "KAIO": {
      "icao": "KAIO",
      "iata": "AIO",
      "name": "Atlantic Municipal Airport",
      "city": "Atlantic",
      "state": "Iowa",
      "country": "US",
      "elevation": 1165,
      "lat": 41.40729904,
      "lon": -95.04689789,
      "tz": "America\/Chicago"
  },
  "KAIT": {
      "icao": "KAIT",
      "iata": "",
      "name": "Aitkin Municipal Steve Kurtz Field",
      "city": "Aitkin",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1205,
      "lat": 46.5484008789,
      "lon": -93.6768035889,
      "tz": "America\/Chicago"
  },
  "KAIV": {
      "icao": "KAIV",
      "iata": "AIV",
      "name": "George Downer Airport",
      "city": "Aliceville",
      "state": "Alabama",
      "country": "US",
      "elevation": 150,
      "lat": 33.1064987183,
      "lon": -88.1977996826,
      "tz": "America\/Chicago"
  },
  "KAIZ": {
      "icao": "KAIZ",
      "iata": "AIZ",
      "name": "Lee C Fine Memorial Airport",
      "city": "Kaiser Lake Ozark",
      "state": "Missouri",
      "country": "US",
      "elevation": 869,
      "lat": 38.0960006714,
      "lon": -92.5494995117,
      "tz": "America\/Chicago"
  },
  "KAJG": {
      "icao": "KAJG",
      "iata": "",
      "name": "Mount Carmel Municipal Airport",
      "city": "Mount Carmel",
      "state": "Illinois",
      "country": "US",
      "elevation": 429,
      "lat": 38.60649872,
      "lon": -87.72669983,
      "tz": "America\/Chicago"
  },
  "KAJO": {
      "icao": "KAJO",
      "iata": "",
      "name": "Corona Municipal Airport",
      "city": "Corona",
      "state": "California",
      "country": "US",
      "elevation": 533,
      "lat": 33.8977012634,
      "lon": -117.6019973755,
      "tz": "America\/Los_Angeles"
  },
  "KAJR": {
      "icao": "KAJR",
      "iata": "",
      "name": "Habersham County Airport",
      "city": "Cornelia",
      "state": "Georgia",
      "country": "US",
      "elevation": 1448,
      "lat": 34.49990082,
      "lon": -83.55670166,
      "tz": "America\/New_York"
  },
  "KAJZ": {
      "icao": "KAJZ",
      "iata": "",
      "name": "Blake Field",
      "city": "Delta",
      "state": "Colorado",
      "country": "US",
      "elevation": 5193,
      "lat": 38.7863998413,
      "lon": -108.0640029907,
      "tz": "America\/Denver"
  },
  "KAKH": {
      "icao": "KAKH",
      "iata": "",
      "name": "Gastonia Municipal Airport",
      "city": "Gastonia",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 798,
      "lat": 35.2025985718,
      "lon": -81.1499023438,
      "tz": "America\/New_York"
  },
  "KAKO": {
      "icao": "KAKO",
      "iata": "AKO",
      "name": "Colorado Plains Regional Airport",
      "city": "Akron",
      "state": "Colorado",
      "country": "US",
      "elevation": 4714,
      "lat": 40.1755981445,
      "lon": -103.222000122,
      "tz": "America\/Denver"
  },
  "KAKQ": {
      "icao": "KAKQ",
      "iata": "",
      "name": "Wakefield Municipal Airport",
      "city": "Wakefield",
      "state": "Virginia",
      "country": "US",
      "elevation": 113,
      "lat": 36.9872016907,
      "lon": -77.0010986328,
      "tz": "America\/New_York"
  },
  "KAKR": {
      "icao": "KAKR",
      "iata": "AKC",
      "name": "Akron Fulton International Airport",
      "city": "Akron",
      "state": "Ohio",
      "country": "US",
      "elevation": 1067,
      "lat": 41.0374984741,
      "lon": -81.4669036865,
      "tz": "America\/New_York"
  },
  "KALB": {
      "icao": "KALB",
      "iata": "ALB",
      "name": "Albany International Airport",
      "city": "Albany",
      "state": "New-York",
      "country": "US",
      "elevation": 285,
      "lat": 42.748298645,
      "lon": -73.8016967773,
      "tz": "America\/New_York"
  },
  "KALI": {
      "icao": "KALI",
      "iata": "ALI",
      "name": "Alice International Airport",
      "city": "Alice",
      "state": "Texas",
      "country": "US",
      "elevation": 178,
      "lat": 27.7409000397,
      "lon": -98.0269012451,
      "tz": "America\/Chicago"
  },
  "KALM": {
      "icao": "KALM",
      "iata": "ALM",
      "name": "Alamogordo White Sands Regional Airport",
      "city": "Alamogordo",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4200,
      "lat": 32.8399009705,
      "lon": -105.9909973145,
      "tz": "America\/Denver"
  },
  "KALN": {
      "icao": "KALN",
      "iata": "ALN",
      "name": "St Louis Regional Airport",
      "city": "Alton\/St Louis",
      "state": "Illinois",
      "country": "US",
      "elevation": 544,
      "lat": 38.8903007507,
      "lon": -90.0459976196,
      "tz": "America\/Chicago"
  },
  "KALO": {
      "icao": "KALO",
      "iata": "ALO",
      "name": "Waterloo Regional Airport",
      "city": "Waterloo",
      "state": "Iowa",
      "country": "US",
      "elevation": 873,
      "lat": 42.5570983887,
      "lon": -92.4002990723,
      "tz": "America\/Chicago"
  },
  "KALS": {
      "icao": "KALS",
      "iata": "ALS",
      "name": "San Luis Valley Regional Bergman Field",
      "city": "Alamosa",
      "state": "Colorado",
      "country": "US",
      "elevation": 7539,
      "lat": 37.4348983765,
      "lon": -105.866996765,
      "tz": "America\/Denver"
  },
  "KALW": {
      "icao": "KALW",
      "iata": "ALW",
      "name": "Walla Walla Regional Airport",
      "city": "Walla Walla",
      "state": "Washington",
      "country": "US",
      "elevation": 1194,
      "lat": 46.09489822,
      "lon": -118.288002,
      "tz": "America\/Los_Angeles"
  },
  "KAMA": {
      "icao": "KAMA",
      "iata": "AMA",
      "name": "Rick Husband Amarillo International Airport",
      "city": "Amarillo",
      "state": "Texas",
      "country": "US",
      "elevation": 3607,
      "lat": 35.2193984985,
      "lon": -101.7060012817,
      "tz": "America\/Chicago"
  },
  "KAMG": {
      "icao": "KAMG",
      "iata": "",
      "name": "Bacon County Airport",
      "city": "Alma",
      "state": "Georgia",
      "country": "US",
      "elevation": 200,
      "lat": 31.5361003876,
      "lon": -82.5065994263,
      "tz": "America\/New_York"
  },
  "KAMN": {
      "icao": "KAMN",
      "iata": "AMN",
      "name": "RPD Airport",
      "city": "Alma",
      "state": "Michigan",
      "country": "US",
      "elevation": 754,
      "lat": 43.322101593,
      "lon": -84.68800354,
      "tz": "America\/Detroit"
  },
  "KAMT": {
      "icao": "KAMT",
      "iata": "",
      "name": "Alexander Salamon Airport",
      "city": "West Union",
      "state": "Ohio",
      "country": "US",
      "elevation": 896,
      "lat": 38.8515014648,
      "lon": -83.5662994385,
      "tz": "America\/New_York"
  },
  "KAMW": {
      "icao": "KAMW",
      "iata": "AMW",
      "name": "Ames Municipal Airport",
      "city": "Ames",
      "state": "Iowa",
      "country": "US",
      "elevation": 956,
      "lat": 41.99200058,
      "lon": -93.62180328,
      "tz": "America\/Chicago"
  },
  "KANB": {
      "icao": "KANB",
      "iata": "ANB",
      "name": "Anniston Metropolitan Airport",
      "city": "Anniston",
      "state": "Alabama",
      "country": "US",
      "elevation": 612,
      "lat": 33.58819962,
      "lon": -85.85810089,
      "tz": "America\/Chicago"
  },
  "KAND": {
      "icao": "KAND",
      "iata": "AND",
      "name": "Anderson Regional Airport",
      "city": "Anderson",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 782,
      "lat": 34.4945983887,
      "lon": -82.7093963623,
      "tz": "America\/New_York"
  },
  "KANE": {
      "icao": "KANE",
      "iata": "",
      "name": "Anoka County-Blaine Arpt(Janes Field) Airport",
      "city": "Minneapolis",
      "state": "Minnesota",
      "country": "US",
      "elevation": 912,
      "lat": 45.14500046,
      "lon": -93.21140289,
      "tz": "America\/Chicago"
  },
  "KANJ": {
      "icao": "KANJ",
      "iata": "",
      "name": "Sault Ste Marie Municipal Sanderson Field",
      "city": "Sault Ste Marie",
      "state": "Michigan",
      "country": "US",
      "elevation": 716,
      "lat": 46.47919846,
      "lon": -84.36840057,
      "tz": "America\/Detroit"
  },
  "KANK": {
      "icao": "KANK",
      "iata": "SLT",
      "name": "Harriet Alexander Field",
      "city": "Salida",
      "state": "Colorado",
      "country": "US",
      "elevation": 7523,
      "lat": 38.5382995605,
      "lon": -106.0490036011,
      "tz": "America\/Denver"
  },
  "KANP": {
      "icao": "KANP",
      "iata": "ANP",
      "name": "Lee Airport",
      "city": "Annapolis",
      "state": "Maryland",
      "country": "US",
      "elevation": 34,
      "lat": 38.9429016113,
      "lon": -76.568397522,
      "tz": "America\/New_York"
  },
  "KANQ": {
      "icao": "KANQ",
      "iata": "ANQ",
      "name": "Tri State Steuben County Airport",
      "city": "Angola",
      "state": "Indiana",
      "country": "US",
      "elevation": 995,
      "lat": 41.6397018433,
      "lon": -85.0835037231,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KANW": {
      "icao": "KANW",
      "iata": "ANW",
      "name": "Ainsworth Municipal Airport",
      "city": "Ainsworth",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2589,
      "lat": 42.5792007446,
      "lon": -99.9929962158,
      "tz": "America\/Chicago"
  },
  "KANY": {
      "icao": "KANY",
      "iata": "ANY",
      "name": "Anthony Municipal Airport",
      "city": "Anthony",
      "state": "Kansas",
      "country": "US",
      "elevation": 1340,
      "lat": 37.1585006714,
      "lon": -98.0795974731,
      "tz": "America\/Chicago"
  },
  "KAOC": {
      "icao": "KAOC",
      "iata": "",
      "name": "Arco Butte County Airport",
      "city": "Arco",
      "state": "Idaho",
      "country": "US",
      "elevation": 5332,
      "lat": 43.6035003662,
      "lon": -113.3339996338,
      "tz": "America\/Boise"
  },
  "KAOH": {
      "icao": "KAOH",
      "iata": "AOH",
      "name": "Lima Allen County Airport",
      "city": "Lima",
      "state": "Ohio",
      "country": "US",
      "elevation": 975,
      "lat": 40.7069015503,
      "lon": -84.0267028809,
      "tz": "America\/New_York"
  },
  "KAOO": {
      "icao": "KAOO",
      "iata": "AOO",
      "name": "Altoona Blair County Airport",
      "city": "Altoona",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1503,
      "lat": 40.29639816,
      "lon": -78.31999969,
      "tz": "America\/New_York"
  },
  "KAOV": {
      "icao": "KAOV",
      "iata": "",
      "name": "Ava Bill Martin Memorial Airport",
      "city": "Ava",
      "state": "Missouri",
      "country": "US",
      "elevation": 1311,
      "lat": 36.9719009399,
      "lon": -92.6819000244,
      "tz": "America\/Chicago"
  },
  "KAPA": {
      "icao": "KAPA",
      "iata": "APA",
      "name": "Centennial Airport",
      "city": "Denver",
      "state": "Colorado",
      "country": "US",
      "elevation": 5885,
      "lat": 39.57009888,
      "lon": -104.848999,
      "tz": "America\/Denver"
  },
  "KAPC": {
      "icao": "KAPC",
      "iata": "APC",
      "name": "Napa County Airport",
      "city": "Napa",
      "state": "California",
      "country": "US",
      "elevation": 35,
      "lat": 38.2131996155,
      "lon": -122.28099823,
      "tz": "America\/Los_Angeles"
  },
  "KAPF": {
      "icao": "KAPF",
      "iata": "APF",
      "name": "Naples Municipal Airport",
      "city": "Naples",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 26.1525993347,
      "lon": -81.7752990723,
      "tz": "America\/New_York"
  },
  "KAPG": {
      "icao": "KAPG",
      "iata": "APG",
      "name": "Phillips Army Air Field",
      "city": "Aberdeen Proving Grounds(Aberdeen)",
      "state": "Maryland",
      "country": "US",
      "elevation": 57,
      "lat": 39.46620178,
      "lon": -76.16880035,
      "tz": "America\/New_York"
  },
  "KAPH": {
      "icao": "KAPH",
      "iata": "APH",
      "name": "A P Hill Aaf (Fort A P Hill) Airport",
      "city": "Fort A. P. Hill",
      "state": "Virginia",
      "country": "US",
      "elevation": 220,
      "lat": 38.06890106,
      "lon": -77.31829834,
      "tz": "America\/New_York"
  },
  "KAPN": {
      "icao": "KAPN",
      "iata": "APN",
      "name": "Alpena County Regional Airport",
      "city": "Alpena",
      "state": "Michigan",
      "country": "US",
      "elevation": 690,
      "lat": 45.0780983,
      "lon": -83.56030273,
      "tz": "America\/Detroit"
  },
  "KAPT": {
      "icao": "KAPT",
      "iata": "APT",
      "name": "Marion County Brown Field",
      "city": "Jasper",
      "state": "Tennessee",
      "country": "US",
      "elevation": 641,
      "lat": 35.0606994629,
      "lon": -85.5852966309,
      "tz": "America\/Chicago"
  },
  "KAPV": {
      "icao": "KAPV",
      "iata": "APV",
      "name": "Apple Valley Airport",
      "city": "Apple Valley",
      "state": "California",
      "country": "US",
      "elevation": 3062,
      "lat": 34.5752983093,
      "lon": -117.185997009,
      "tz": "America\/Los_Angeles"
  },
  "KAPY": {
      "icao": "KAPY",
      "iata": "",
      "name": "Zapata County Airport",
      "city": "Zapata",
      "state": "Texas",
      "country": "US",
      "elevation": 422,
      "lat": 26.9687995911,
      "lon": -99.2489013672,
      "tz": "America\/Chicago"
  },
  "KAQO": {
      "icao": "KAQO",
      "iata": "",
      "name": "Llano Municipal Airport",
      "city": "Llano",
      "state": "Texas",
      "country": "US",
      "elevation": 1102,
      "lat": 30.783700943,
      "lon": -98.6620025635,
      "tz": "America\/Chicago"
  },
  "KAQP": {
      "icao": "KAQP",
      "iata": "",
      "name": "Appleton Municipal Airport",
      "city": "Appleton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1020,
      "lat": 45.2275009155,
      "lon": -96.0043029785,
      "tz": "America\/Chicago"
  },
  "KAQR": {
      "icao": "KAQR",
      "iata": "",
      "name": "Atoka Municipal Airport",
      "city": "Atoka",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 590,
      "lat": 34.3983001709,
      "lon": -96.1481018066,
      "tz": "America\/Chicago"
  },
  "KAQW": {
      "icao": "KAQW",
      "iata": "",
      "name": "Harriman and West Airport",
      "city": "North Adams",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 654,
      "lat": 42.6958999634,
      "lon": -73.1704025269,
      "tz": "America\/New_York"
  },
  "KARA": {
      "icao": "KARA",
      "iata": "ARA",
      "name": "Acadiana Regional Airport",
      "city": "New Iberia",
      "state": "Louisiana",
      "country": "US",
      "elevation": 24,
      "lat": 30.0377998352,
      "lon": -91.8839035034,
      "tz": "America\/Chicago"
  },
  "KARB": {
      "icao": "KARB",
      "iata": "ARB",
      "name": "Ann Arbor Municipal Airport",
      "city": "Ann Arbor",
      "state": "Michigan",
      "country": "US",
      "elevation": 839,
      "lat": 42.2229995728,
      "lon": -83.7455978394,
      "tz": "America\/Detroit"
  },
  "KARG": {
      "icao": "KARG",
      "iata": "ARG",
      "name": "Walnut Ridge Regional Airport",
      "city": "Walnut Ridge",
      "state": "Arkansas",
      "country": "US",
      "elevation": 279,
      "lat": 36.1246667,
      "lon": -90.9251111,
      "tz": "America\/Chicago"
  },
  "KARM": {
      "icao": "KARM",
      "iata": "WHT",
      "name": "Wharton Regional Airport",
      "city": "Wharton",
      "state": "Texas",
      "country": "US",
      "elevation": 100,
      "lat": 29.2542991638,
      "lon": -96.1544036865,
      "tz": "America\/Chicago"
  },
  "KARR": {
      "icao": "KARR",
      "iata": "AUZ",
      "name": "Aurora Municipal Airport",
      "city": "Chicago\/Aurora",
      "state": "Illinois",
      "country": "US",
      "elevation": 712,
      "lat": 41.771900177,
      "lon": -88.4757003784,
      "tz": "America\/Chicago"
  },
  "KART": {
      "icao": "KART",
      "iata": "ART",
      "name": "Watertown International Airport",
      "city": "Watertown",
      "state": "New-York",
      "country": "US",
      "elevation": 325,
      "lat": 43.9919013977,
      "lon": -76.021697998,
      "tz": "America\/New_York"
  },
  "KARV": {
      "icao": "KARV",
      "iata": "ARV",
      "name": "Lakeland-Noble F. Lee Memorial field",
      "city": "Minocqua-Woodruff",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1629,
      "lat": 45.92789841,
      "lon": -89.73090363,
      "tz": "America\/Chicago"
  },
  "KARW": {
      "icao": "KARW",
      "iata": "BFT",
      "name": "Beaufort County Airport",
      "city": "Beaufort",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 10,
      "lat": 32.4122009277,
      "lon": -80.6343994141,
      "tz": "America\/New_York"
  },
  "KASD": {
      "icao": "KASD",
      "iata": "",
      "name": "Slidell Airport",
      "city": "Slidell",
      "state": "Louisiana",
      "country": "US",
      "elevation": 29,
      "lat": 30.3451004,
      "lon": -89.82080078,
      "tz": "America\/Chicago"
  },
  "KASE": {
      "icao": "KASE",
      "iata": "ASE",
      "name": "Aspen-Pitkin Co\/Sardy Field",
      "city": "Aspen",
      "state": "Colorado",
      "country": "US",
      "elevation": 7820,
      "lat": 39.22320175,
      "lon": -106.8690033,
      "tz": "America\/Denver"
  },
  "KASG": {
      "icao": "KASG",
      "iata": "SPZ",
      "name": "Springdale Municipal Airport",
      "city": "Springdale",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1353,
      "lat": 36.176399231,
      "lon": -94.1193008423,
      "tz": "America\/Chicago"
  },
  "KASH": {
      "icao": "KASH",
      "iata": "ASH",
      "name": "Boire Field",
      "city": "Nashua",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 199,
      "lat": 42.7817001343,
      "lon": -71.5148010254,
      "tz": "America\/New_York"
  },
  "KASJ": {
      "icao": "KASJ",
      "iata": "",
      "name": "Tri County Airport",
      "city": "Ahoskie",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 68,
      "lat": 36.2975006104,
      "lon": -77.1708984375,
      "tz": "America\/New_York"
  },
  "KASL": {
      "icao": "KASL",
      "iata": "ASL",
      "name": "Harrison County Airport",
      "city": "Marshall",
      "state": "Texas",
      "country": "US",
      "elevation": 357,
      "lat": 32.5205001831,
      "lon": -94.307800293,
      "tz": "America\/Chicago"
  },
  "KASN": {
      "icao": "KASN",
      "iata": "ASN",
      "name": "Talladega Municipal Airport",
      "city": "Talladega",
      "state": "Alabama",
      "country": "US",
      "elevation": 529,
      "lat": 33.5699005127,
      "lon": -86.0509033203,
      "tz": "America\/Chicago"
  },
  "KAST": {
      "icao": "KAST",
      "iata": "AST",
      "name": "Astoria Regional Airport",
      "city": "Astoria",
      "state": "Oregon",
      "country": "US",
      "elevation": 15,
      "lat": 46.158000946,
      "lon": -123.878997803,
      "tz": "America\/Los_Angeles"
  },
  "KASW": {
      "icao": "KASW",
      "iata": "",
      "name": "Warsaw Municipal Airport",
      "city": "Warsaw",
      "state": "Indiana",
      "country": "US",
      "elevation": 850,
      "lat": 41.2747001648,
      "lon": -85.8401031494,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KASX": {
      "icao": "KASX",
      "iata": "ASX",
      "name": "John F Kennedy Memorial Airport",
      "city": "Ashland",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 827,
      "lat": 46.54850006,
      "lon": -90.91899872,
      "tz": "America\/Chicago"
  },
  "KASY": {
      "icao": "KASY",
      "iata": "ASY",
      "name": "Ashley Municipal Airport",
      "city": "Ashley",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2032,
      "lat": 46.0238990784,
      "lon": -99.3526000977,
      "tz": "America\/Chicago"
  },
  "KATA": {
      "icao": "KATA",
      "iata": "",
      "name": "Hall Miller Municipal Airport",
      "city": "Atlanta",
      "state": "Texas",
      "country": "US",
      "elevation": 280,
      "lat": 33.1017990112,
      "lon": -94.1952972412,
      "tz": "America\/Chicago"
  },
  "KATL": {
      "icao": "KATL",
      "iata": "ATL",
      "name": "Hartsfield Jackson Atlanta International Airport",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 1026,
      "lat": 33.6366996765,
      "lon": -84.4281005859,
      "tz": "America\/New_York"
  },
  "KATS": {
      "icao": "KATS",
      "iata": "ATS",
      "name": "Artesia Municipal Airport",
      "city": "Artesia",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3541,
      "lat": 32.8525009155,
      "lon": -104.468002319,
      "tz": "America\/Denver"
  },
  "KATW": {
      "icao": "KATW",
      "iata": "ATW",
      "name": "Appleton International Airport",
      "city": "Appleton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 918,
      "lat": 44.2580986023,
      "lon": -88.5190963745,
      "tz": "America\/Chicago"
  },
  "KATY": {
      "icao": "KATY",
      "iata": "ATY",
      "name": "Watertown Regional Airport",
      "city": "Watertown",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1749,
      "lat": 44.91400146,
      "lon": -97.15470123,
      "tz": "America\/Chicago"
  },
  "KAUG": {
      "icao": "KAUG",
      "iata": "AUG",
      "name": "Augusta State Airport",
      "city": "Augusta",
      "state": "Maine",
      "country": "US",
      "elevation": 352,
      "lat": 44.3205986023,
      "lon": -69.7973022461,
      "tz": "America\/New_York"
  },
  "KAUH": {
      "icao": "KAUH",
      "iata": "",
      "name": "Aurora Municipal Al Potter Field",
      "city": "Aurora",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1803,
      "lat": 40.89410019,
      "lon": -97.99459839,
      "tz": "America\/Chicago"
  },
  "KAUM": {
      "icao": "KAUM",
      "iata": "AUM",
      "name": "Austin Municipal Airport",
      "city": "Austin",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1234,
      "lat": 43.66500092,
      "lon": -92.93340302,
      "tz": "America\/Chicago"
  },
  "KAUN": {
      "icao": "KAUN",
      "iata": "AUN",
      "name": "Auburn Municipal Airport",
      "city": "Auburn",
      "state": "California",
      "country": "US",
      "elevation": 1539,
      "lat": 38.95479965,
      "lon": -121.0820007,
      "tz": "America\/Los_Angeles"
  },
  "KAUO": {
      "icao": "KAUO",
      "iata": "AUO",
      "name": "Auburn Opelika Robert G. Pitts Airport",
      "city": "Auburn",
      "state": "Alabama",
      "country": "US",
      "elevation": 777,
      "lat": 32.61510086,
      "lon": -85.43399811,
      "tz": "America\/Chicago"
  },
  "KAUS": {
      "icao": "KAUS",
      "iata": "AUS",
      "name": "Austin Bergstrom International Airport",
      "city": "Austin",
      "state": "Texas",
      "country": "US",
      "elevation": 542,
      "lat": 30.1944999695,
      "lon": -97.6698989868,
      "tz": "America\/Chicago"
  },
  "KAUW": {
      "icao": "KAUW",
      "iata": "AUW",
      "name": "Wausau Downtown Airport",
      "city": "Wausau",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1201,
      "lat": 44.9262008667,
      "lon": -89.6266021729,
      "tz": "America\/Chicago"
  },
  "KAVC": {
      "icao": "KAVC",
      "iata": "",
      "name": "Mecklenburg Brunswick Regional Airport",
      "city": "South Hill",
      "state": "Virginia",
      "country": "US",
      "elevation": 441,
      "lat": 36.68830109,
      "lon": -78.05449677,
      "tz": "America\/New_York"
  },
  "KAVK": {
      "icao": "KAVK",
      "iata": "",
      "name": "Alva Regional Airport",
      "city": "Alva",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1474,
      "lat": 36.7732009888,
      "lon": -98.6698989868,
      "tz": "America\/Chicago"
  },
  "KAVL": {
      "icao": "KAVL",
      "iata": "AVL",
      "name": "Asheville Regional Airport",
      "city": "Asheville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2165,
      "lat": 35.4361991882,
      "lon": -82.5418014526,
      "tz": "America\/New_York"
  },
  "KAVO": {
      "icao": "KAVO",
      "iata": "AVO",
      "name": "Avon Park Executive Airport",
      "city": "Avon Park",
      "state": "Florida",
      "country": "US",
      "elevation": 160,
      "lat": 27.59119987,
      "lon": -81.52780151,
      "tz": "America\/New_York"
  },
  "KAVP": {
      "icao": "KAVP",
      "iata": "AVP",
      "name": "Wilkes Barre Scranton International Airport",
      "city": "Wilkes-Barre\/Scranton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 962,
      "lat": 41.3385009766,
      "lon": -75.7233963013,
      "tz": "America\/New_York"
  },
  "KAVQ": {
      "icao": "KAVQ",
      "iata": "AVW",
      "name": "Marana Regional Airport",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 2031,
      "lat": 32.4095993042,
      "lon": -111.218002319,
      "tz": "America\/Phoenix"
  },
  "KAVX": {
      "icao": "KAVX",
      "iata": "AVX",
      "name": "Catalina Airport",
      "city": "Avalon",
      "state": "California",
      "country": "US",
      "elevation": 1602,
      "lat": 33.4048995972,
      "lon": -118.4160003662,
      "tz": "America\/Los_Angeles"
  },
  "KAWG": {
      "icao": "KAWG",
      "iata": "",
      "name": "Washington Municipal Airport",
      "city": "Washington",
      "state": "Iowa",
      "country": "US",
      "elevation": 754,
      "lat": 41.2761001587,
      "lon": -91.6734008789,
      "tz": "America\/Chicago"
  },
  "KAWM": {
      "icao": "KAWM",
      "iata": "AWM",
      "name": "West Memphis Municipal Airport",
      "city": "West Memphis",
      "state": "Arkansas",
      "country": "US",
      "elevation": 212,
      "lat": 35.1351013184,
      "lon": -90.2343978882,
      "tz": "America\/Chicago"
  },
  "KAWO": {
      "icao": "KAWO",
      "iata": "",
      "name": "Arlington Municipal Airport",
      "city": "Arlington",
      "state": "Washington",
      "country": "US",
      "elevation": 142,
      "lat": 48.16070175,
      "lon": -122.1589966,
      "tz": "America\/Los_Angeles"
  },
  "KAXA": {
      "icao": "KAXA",
      "iata": "AXG",
      "name": "Algona Municipal Airport",
      "city": "Algona",
      "state": "Iowa",
      "country": "US",
      "elevation": 1219,
      "lat": 43.0778999329,
      "lon": -94.2720031738,
      "tz": "America\/Chicago"
  },
  "KAXH": {
      "icao": "KAXH",
      "iata": "",
      "name": "Houston Southwest Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 68,
      "lat": 29.5060997009,
      "lon": -95.4768981934,
      "tz": "America\/Chicago"
  },
  "KAXN": {
      "icao": "KAXN",
      "iata": "AXN",
      "name": "Chandler Field",
      "city": "Alexandria",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1425,
      "lat": 45.8662986755,
      "lon": -95.3946990967,
      "tz": "America\/Chicago"
  },
  "KAXQ": {
      "icao": "KAXQ",
      "iata": "",
      "name": "Clarion County Airport",
      "city": "Clarion",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1458,
      "lat": 41.2257995605,
      "lon": -79.4410018921,
      "tz": "America\/New_York"
  },
  "KAXS": {
      "icao": "KAXS",
      "iata": "AXS",
      "name": "Altus Quartz Mountain Regional Airport",
      "city": "Altus",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1433,
      "lat": 34.6963996887,
      "lon": -99.3403015137,
      "tz": "America\/Chicago"
  },
  "KAXV": {
      "icao": "KAXV",
      "iata": "AXV",
      "name": "Neil Armstrong Airport",
      "city": "Wapakoneta",
      "state": "Ohio",
      "country": "US",
      "elevation": 913,
      "lat": 40.49340057,
      "lon": -84.29889679,
      "tz": "America\/New_York"
  },
  "KAXX": {
      "icao": "KAXX",
      "iata": "AXX",
      "name": "Angel Fire Airport",
      "city": "Angel Fire",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 8380,
      "lat": 36.422000885,
      "lon": -105.290000916,
      "tz": "America\/Denver"
  },
  "KAYS": {
      "icao": "KAYS",
      "iata": "AYS",
      "name": "Waycross Ware County Airport",
      "city": "Waycross",
      "state": "Georgia",
      "country": "US",
      "elevation": 142,
      "lat": 31.2490997314,
      "lon": -82.3955001831,
      "tz": "America\/New_York"
  },
  "KAYX": {
      "icao": "KAYX",
      "iata": "",
      "name": "Arnold Air Force Base",
      "city": "Tullahoma",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1067,
      "lat": 35.39260101,
      "lon": -86.08580017,
      "tz": "America\/Chicago"
  },
  "KAZC": {
      "icao": "KAZC",
      "iata": "",
      "name": "Colorado City Municipal Airport",
      "city": "Colorado City",
      "state": "Arizona",
      "country": "US",
      "elevation": 4874,
      "lat": 36.9598999023,
      "lon": -113.013999939,
      "tz": "America\/Denver"
  },
  "KAZE": {
      "icao": "KAZE",
      "iata": "",
      "name": "Hazlehurst Airport",
      "city": "Hazlehurst",
      "state": "Georgia",
      "country": "US",
      "elevation": 255,
      "lat": 31.8847007751,
      "lon": -82.6473999023,
      "tz": "America\/New_York"
  },
  "KAZO": {
      "icao": "KAZO",
      "iata": "AZO",
      "name": "Kalamazoo Battle Creek International Airport",
      "city": "Kalamazoo",
      "state": "Michigan",
      "country": "US",
      "elevation": 874,
      "lat": 42.2349014282,
      "lon": -85.5521011353,
      "tz": "America\/Detroit"
  },
  "KB16": {
      "icao": "KB16",
      "iata": "",
      "name": "Whitfords Airport",
      "city": "Weedsport",
      "state": "New-York",
      "country": "US",
      "elevation": 400,
      "lat": 43.0802993774,
      "lon": -76.5383987427,
      "tz": "America\/New_York"
  },
  "KB19": {
      "icao": "KB19",
      "iata": "",
      "name": "Biddeford Municipal Airport",
      "city": "Biddeford",
      "state": "Maine",
      "country": "US",
      "elevation": 157,
      "lat": 43.464099884,
      "lon": -70.4723968506,
      "tz": "America\/New_York"
  },
  "KB21": {
      "icao": "KB21",
      "iata": "",
      "name": "Sugarloaf Regional Airport",
      "city": "Carrabassett",
      "state": "Maine",
      "country": "US",
      "elevation": 885,
      "lat": 45.0862007141,
      "lon": -70.2162017822,
      "tz": "America\/New_York"
  },
  "KBAB": {
      "icao": "KBAB",
      "iata": "BAB",
      "name": "Beale Air Force Base",
      "city": "Marysville",
      "state": "California",
      "country": "US",
      "elevation": 113,
      "lat": 39.136100769,
      "lon": -121.43699646,
      "tz": "America\/Los_Angeles"
  },
  "KBAD": {
      "icao": "KBAD",
      "iata": "BAD",
      "name": "Barksdale Air Force Base",
      "city": "Bossier City",
      "state": "Louisiana",
      "country": "US",
      "elevation": 166,
      "lat": 32.5018005371,
      "lon": -93.6626968384,
      "tz": "America\/Chicago"
  },
  "KBAF": {
      "icao": "KBAF",
      "iata": "BAF",
      "name": "Barnes Municipal Airport",
      "city": "Westfield\/Springfield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 271,
      "lat": 42.1577987671,
      "lon": -72.7155990601,
      "tz": "America\/New_York"
  },
  "KBAK": {
      "icao": "KBAK",
      "iata": "CLU",
      "name": "Columbus Municipal Airport",
      "city": "Columbus",
      "state": "Indiana",
      "country": "US",
      "elevation": 656,
      "lat": 39.2619018555,
      "lon": -85.8963012695,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KBAM": {
      "icao": "KBAM",
      "iata": "BAM",
      "name": "Battle Mountain Airport",
      "city": "Battle Mountain",
      "state": "Nevada",
      "country": "US",
      "elevation": 4532,
      "lat": 40.5989990234,
      "lon": -116.8740005493,
      "tz": "America\/Los_Angeles"
  },
  "KBAX": {
      "icao": "KBAX",
      "iata": "",
      "name": "Huron County Memorial Airport",
      "city": "Bad Axe",
      "state": "Michigan",
      "country": "US",
      "elevation": 763,
      "lat": 43.78020096,
      "lon": -82.98539734,
      "tz": "America\/Detroit"
  },
  "KBAZ": {
      "icao": "KBAZ",
      "iata": "",
      "name": "New Braunfels Municipal Airport",
      "city": "New Braunfels",
      "state": "Texas",
      "country": "US",
      "elevation": 651,
      "lat": 29.7045001984,
      "lon": -98.0421981812,
      "tz": "America\/Chicago"
  },
  "KBBB": {
      "icao": "KBBB",
      "iata": "BBB",
      "name": "Benson Municipal Airport",
      "city": "Benson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1039,
      "lat": 45.3319015503,
      "lon": -95.6505966187,
      "tz": "America\/Chicago"
  },
  "KBBD": {
      "icao": "KBBD",
      "iata": "BBD",
      "name": "Curtis Field",
      "city": "Brady",
      "state": "Texas",
      "country": "US",
      "elevation": 1827,
      "lat": 31.1793003082,
      "lon": -99.3238983154,
      "tz": "America\/Chicago"
  },
  "KBBG": {
      "icao": "KBBG",
      "iata": "BKG",
      "name": "Branson Airport",
      "city": "Branson",
      "state": "Missouri",
      "country": "US",
      "elevation": 1302,
      "lat": 36.532082,
      "lon": -93.200544,
      "tz": "America\/Chicago"
  },
  "KBBP": {
      "icao": "KBBP",
      "iata": "BTN",
      "name": "Marlboro County Jetport H.E. Avent Field",
      "city": "Bennettsville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 147,
      "lat": 34.62170029,
      "lon": -79.73439789,
      "tz": "America\/New_York"
  },
  "KBBW": {
      "icao": "KBBW",
      "iata": "BBW",
      "name": "Broken Bow Municipal Airport",
      "city": "Broken Bow",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2547,
      "lat": 41.4365005493,
      "lon": -99.6421966553,
      "tz": "America\/Chicago"
  },
  "KBCB": {
      "icao": "KBCB",
      "iata": "BCB",
      "name": "Virginia Tech Montgomery Executive Airport",
      "city": "Blacksburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 2132,
      "lat": 37.2075996399,
      "lon": -80.4077987671,
      "tz": "America\/New_York"
  },
  "KBCE": {
      "icao": "KBCE",
      "iata": "BCE",
      "name": "Bryce Canyon Airport",
      "city": "Bryce Canyon",
      "state": "Utah",
      "country": "US",
      "elevation": 7590,
      "lat": 37.706401825,
      "lon": -112.144996643,
      "tz": "America\/Denver"
  },
  "KBCK": {
      "icao": "KBCK",
      "iata": "",
      "name": "Black River Falls Area Airport",
      "city": "Black River Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 836,
      "lat": 44.2507019043,
      "lon": -90.8553009033,
      "tz": "America\/Chicago"
  },
  "KBCT": {
      "icao": "KBCT",
      "iata": "BCT",
      "name": "Boca Raton Airport",
      "city": "Boca Raton",
      "state": "Florida",
      "country": "US",
      "elevation": 13,
      "lat": 26.3784999847,
      "lon": -80.1076965332,
      "tz": "America\/New_York"
  },
  "KBDE": {
      "icao": "KBDE",
      "iata": "BDE",
      "name": "Baudette International Airport",
      "city": "Baudette",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1086,
      "lat": 48.7284011841,
      "lon": -94.612197876,
      "tz": "America\/Chicago"
  },
  "KBDG": {
      "icao": "KBDG",
      "iata": "BDG",
      "name": "Blanding Municipal Airport",
      "city": "Blanding",
      "state": "Utah",
      "country": "US",
      "elevation": 5868,
      "lat": 37.58330154,
      "lon": -109.4830017,
      "tz": "America\/Denver"
  },
  "KBDH": {
      "icao": "KBDH",
      "iata": "ILL",
      "name": "Willmar Municipal -John L Rice Field",
      "city": "Willmar",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1126,
      "lat": 45.11769867,
      "lon": -95.13040161,
      "tz": "America\/Chicago"
  },
  "KBDJ": {
      "icao": "KBDJ",
      "iata": "",
      "name": "Boulder Junction Airport",
      "city": "Boulder Junction",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1666,
      "lat": 46.1374015808,
      "lon": -89.6460037231,
      "tz": "America\/Chicago"
  },
  "KBDL": {
      "icao": "KBDL",
      "iata": "BDL",
      "name": "Bradley International Airport",
      "city": "Hartford",
      "state": "Connecticut",
      "country": "US",
      "elevation": 173,
      "lat": 41.9388999939,
      "lon": -72.6831970215,
      "tz": "America\/New_York"
  },
  "KBDN": {
      "icao": "KBDN",
      "iata": "",
      "name": "Bend Municipal Airport",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3460,
      "lat": 44.09479904,
      "lon": -121.2009964,
      "tz": "America\/Los_Angeles"
  },
  "KBDQ": {
      "icao": "KBDQ",
      "iata": "",
      "name": "Morrilton Municipal Airport",
      "city": "Morrilton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 321,
      "lat": 35.1361999512,
      "lon": -92.7135009766,
      "tz": "America\/Chicago"
  },
  "KBDR": {
      "icao": "KBDR",
      "iata": "BDR",
      "name": "Igor I Sikorsky Memorial Airport",
      "city": "Bridgeport",
      "state": "Connecticut",
      "country": "US",
      "elevation": 9,
      "lat": 41.1635017395,
      "lon": -73.1261978149,
      "tz": "America\/New_York"
  },
  "KBDU": {
      "icao": "KBDU",
      "iata": "WBU",
      "name": "Boulder Municipal Airport",
      "city": "Boulder",
      "state": "Colorado",
      "country": "US",
      "elevation": 5288,
      "lat": 40.0393981934,
      "lon": -105.225997925,
      "tz": "America\/Denver"
  },
  "KBEA": {
      "icao": "KBEA",
      "iata": "",
      "name": "Beeville Municipal Airport",
      "city": "Beeville",
      "state": "Texas",
      "country": "US",
      "elevation": 268,
      "lat": 28.3619003296,
      "lon": -97.7910003662,
      "tz": "America\/Chicago"
  },
  "KBEC": {
      "icao": "KBEC",
      "iata": "BEC",
      "name": "Beech Factory Airport",
      "city": "Wichita",
      "state": "Kansas",
      "country": "US",
      "elevation": 1408,
      "lat": 37.6944999695,
      "lon": -97.2149963379,
      "tz": "America\/Chicago"
  },
  "KBED": {
      "icao": "KBED",
      "iata": "BED",
      "name": "Laurence G Hanscom Field",
      "city": "Bedford",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 133,
      "lat": 42.47000122,
      "lon": -71.28900146,
      "tz": "America\/New_York"
  },
  "KBEH": {
      "icao": "KBEH",
      "iata": "BEH",
      "name": "Southwest Michigan Regional Airport",
      "city": "Benton Harbor",
      "state": "Michigan",
      "country": "US",
      "elevation": 643,
      "lat": 42.1286010742,
      "lon": -86.4284973145,
      "tz": "America\/Detroit"
  },
  "KBFA": {
      "icao": "KBFA",
      "iata": "",
      "name": "Boyne Mountain Airport",
      "city": "Boyne Falls",
      "state": "Michigan",
      "country": "US",
      "elevation": 719,
      "lat": 45.1657981873,
      "lon": -84.9241027832,
      "tz": "America\/Detroit"
  },
  "KBFD": {
      "icao": "KBFD",
      "iata": "BFD",
      "name": "Bradford Regional Airport",
      "city": "Bradford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2143,
      "lat": 41.8031005859,
      "lon": -78.6400985718,
      "tz": "America\/New_York"
  },
  "KBFE": {
      "icao": "KBFE",
      "iata": "",
      "name": "Terry County Airport",
      "city": "Brownfield",
      "state": "Texas",
      "country": "US",
      "elevation": 3264,
      "lat": 33.1730995178,
      "lon": -102.1930007935,
      "tz": "America\/Chicago"
  },
  "KBFF": {
      "icao": "KBFF",
      "iata": "BFF",
      "name": "Western Neb. Rgnl\/William B. Heilig Airport",
      "city": "Scottsbluff",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3967,
      "lat": 41.87400055,
      "lon": -103.5960007,
      "tz": "America\/Denver"
  },
  "KBFI": {
      "icao": "KBFI",
      "iata": "BFI",
      "name": "Boeing Field King County International Airport",
      "city": "Seattle",
      "state": "Washington",
      "country": "US",
      "elevation": 21,
      "lat": 47.5299987793,
      "lon": -122.3020019531,
      "tz": "America\/Los_Angeles"
  },
  "KBFK": {
      "icao": "KBFK",
      "iata": "",
      "name": "Buffalo Municipal Airport",
      "city": "Buffalo",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1822,
      "lat": 36.8633003235,
      "lon": -99.6186981201,
      "tz": "America\/Chicago"
  },
  "KBFL": {
      "icao": "KBFL",
      "iata": "BFL",
      "name": "Meadows Field",
      "city": "Bakersfield",
      "state": "California",
      "country": "US",
      "elevation": 510,
      "lat": 35.43360138,
      "lon": -119.0569992,
      "tz": "America\/Los_Angeles"
  },
  "KBFM": {
      "icao": "KBFM",
      "iata": "BFM",
      "name": "Mobile Downtown Airport",
      "city": "Mobile",
      "state": "Alabama",
      "country": "US",
      "elevation": 26,
      "lat": 30.6268005371,
      "lon": -88.0680999756,
      "tz": "America\/Chicago"
  },
  "KBFR": {
      "icao": "KBFR",
      "iata": "BFR",
      "name": "Virgil I Grissom Municipal Airport",
      "city": "Bedford",
      "state": "Indiana",
      "country": "US",
      "elevation": 727,
      "lat": 38.84000015,
      "lon": -86.44539642,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KBFW": {
      "icao": "KBFW",
      "iata": "",
      "name": "Silver Bay Municipal Airport",
      "city": "Silver Bay",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1089,
      "lat": 47.2490005493,
      "lon": -91.4156036377,
      "tz": "America\/Chicago"
  },
  "KBGD": {
      "icao": "KBGD",
      "iata": "BGD",
      "name": "Hutchinson County Airport",
      "city": "Borger",
      "state": "Texas",
      "country": "US",
      "elevation": 3055,
      "lat": 35.7009010315,
      "lon": -101.393997192,
      "tz": "America\/Chicago"
  },
  "KBGE": {
      "icao": "KBGE",
      "iata": "BGE",
      "name": "Decatur County Industrial Air Park",
      "city": "Bainbridge",
      "state": "Georgia",
      "country": "US",
      "elevation": 141,
      "lat": 30.9715004,
      "lon": -84.63739777,
      "tz": "America\/New_York"
  },
  "KBGF": {
      "icao": "KBGF",
      "iata": "",
      "name": "Winchester Municipal Airport",
      "city": "Winchester",
      "state": "Tennessee",
      "country": "US",
      "elevation": 979,
      "lat": 35.1775016785,
      "lon": -86.0662002563,
      "tz": "America\/Chicago"
  },
  "KBGM": {
      "icao": "KBGM",
      "iata": "BGM",
      "name": "Greater Binghamton\/Edwin A Link field",
      "city": "Binghamton",
      "state": "New-York",
      "country": "US",
      "elevation": 1636,
      "lat": 42.20869827,
      "lon": -75.97979736,
      "tz": "America\/New_York"
  },
  "KBGR": {
      "icao": "KBGR",
      "iata": "BGR",
      "name": "Bangor International Airport",
      "city": "Bangor",
      "state": "Maine",
      "country": "US",
      "elevation": 192,
      "lat": 44.8073997498,
      "lon": -68.8281021118,
      "tz": "America\/New_York"
  },
  "KBHB": {
      "icao": "KBHB",
      "iata": "BHB",
      "name": "Hancock County-Bar Harbor Airport",
      "city": "Bar Harbor",
      "state": "Maine",
      "country": "US",
      "elevation": 83,
      "lat": 44.45000076,
      "lon": -68.3615036,
      "tz": "America\/New_York"
  },
  "KBHC": {
      "icao": "KBHC",
      "iata": "",
      "name": "Baxley Municipal Airport",
      "city": "Baxley",
      "state": "Georgia",
      "country": "US",
      "elevation": 201,
      "lat": 31.7138004303,
      "lon": -82.3937988281,
      "tz": "America\/New_York"
  },
  "KBHK": {
      "icao": "KBHK",
      "iata": "",
      "name": "Baker Municipal Airport",
      "city": "Baker",
      "state": "Montana",
      "country": "US",
      "elevation": 2975,
      "lat": 46.34759903,
      "lon": -104.2590027,
      "tz": "America\/Denver"
  },
  "KBHM": {
      "icao": "KBHM",
      "iata": "BHM",
      "name": "Birmingham-Shuttlesworth International Airport",
      "city": "Birmingham",
      "state": "Alabama",
      "country": "US",
      "elevation": 650,
      "lat": 33.56290054,
      "lon": -86.75350189,
      "tz": "America\/Chicago"
  },
  "KBID": {
      "icao": "KBID",
      "iata": "BID",
      "name": "Block Island State Airport",
      "city": "Block Island",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 108,
      "lat": 41.1680984497,
      "lon": -71.577796936,
      "tz": "America\/New_York"
  },
  "KBIE": {
      "icao": "KBIE",
      "iata": "BIE",
      "name": "Beatrice Municipal Airport",
      "city": "Beatrice",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1324,
      "lat": 40.3013000488,
      "lon": -96.7540969849,
      "tz": "America\/Chicago"
  },
  "KBIF": {
      "icao": "KBIF",
      "iata": "BIF",
      "name": "Biggs Army Air Field (Fort Bliss)",
      "city": "Fort Bliss\/El Paso",
      "state": "Texas",
      "country": "US",
      "elevation": 3946,
      "lat": 31.84950066,
      "lon": -106.3799973,
      "tz": "America\/Denver"
  },
  "KBIH": {
      "icao": "KBIH",
      "iata": "BIH",
      "name": "Eastern Sierra Regional Airport",
      "city": "Bishop",
      "state": "California",
      "country": "US",
      "elevation": 4124,
      "lat": 37.3731002808,
      "lon": -118.363998413,
      "tz": "America\/Los_Angeles"
  },
  "KBIJ": {
      "icao": "KBIJ",
      "iata": "",
      "name": "Early County Airport",
      "city": "Blakely",
      "state": "Georgia",
      "country": "US",
      "elevation": 214,
      "lat": 31.3974990845,
      "lon": -84.8947982788,
      "tz": "America\/New_York"
  },
  "KBIL": {
      "icao": "KBIL",
      "iata": "BIL",
      "name": "Billings Logan International Airport",
      "city": "Billings",
      "state": "Montana",
      "country": "US",
      "elevation": 3652,
      "lat": 45.8077011108,
      "lon": -108.5429992676,
      "tz": "America\/Denver"
  },
  "KBIS": {
      "icao": "KBIS",
      "iata": "BIS",
      "name": "Bismarck Municipal Airport",
      "city": "Bismarck",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1661,
      "lat": 46.7727012634,
      "lon": -100.7460021973,
      "tz": "America\/Chicago"
  },
  "KBIV": {
      "icao": "KBIV",
      "iata": "",
      "name": "Tulip City Airport",
      "city": "Holland",
      "state": "Michigan",
      "country": "US",
      "elevation": 698,
      "lat": 42.7429008484,
      "lon": -86.1073989868,
      "tz": "America\/Detroit"
  },
  "KBIX": {
      "icao": "KBIX",
      "iata": "BIX",
      "name": "Keesler Air Force Base",
      "city": "Biloxi",
      "state": "Mississippi",
      "country": "US",
      "elevation": 33,
      "lat": 30.4104003906,
      "lon": -88.9244003296,
      "tz": "America\/Chicago"
  },
  "KBJC": {
      "icao": "KBJC",
      "iata": "BJC",
      "name": "Rocky Mountain Metropolitan Airport",
      "city": "Denver",
      "state": "Colorado",
      "country": "US",
      "elevation": 5673,
      "lat": 39.90879822,
      "lon": -105.1169968,
      "tz": "America\/Denver"
  },
  "KBJI": {
      "icao": "KBJI",
      "iata": "BJI",
      "name": "Bemidji Regional Airport",
      "city": "Bemidji",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1391,
      "lat": 47.50939941,
      "lon": -94.93370056,
      "tz": "America\/Chicago"
  },
  "KBJJ": {
      "icao": "KBJJ",
      "iata": "BJJ",
      "name": "Wayne County Airport",
      "city": "Wooster",
      "state": "Ohio",
      "country": "US",
      "elevation": 1136,
      "lat": 40.8748016357,
      "lon": -81.8882980347,
      "tz": "America\/New_York"
  },
  "KBKD": {
      "icao": "KBKD",
      "iata": "BKD",
      "name": "Stephens County Airport",
      "city": "Breckenridge",
      "state": "Texas",
      "country": "US",
      "elevation": 1284,
      "lat": 32.71900177,
      "lon": -98.8909988403,
      "tz": "America\/Chicago"
  },
  "KBKE": {
      "icao": "KBKE",
      "iata": "BKE",
      "name": "Baker City Municipal Airport",
      "city": "Baker City",
      "state": "Oregon",
      "country": "US",
      "elevation": 3373,
      "lat": 44.8372993469,
      "lon": -117.808998108,
      "tz": "America\/Los_Angeles"
  },
  "KBKF": {
      "icao": "KBKF",
      "iata": "BFK",
      "name": "Buckley Air Force Base",
      "city": "Aurora",
      "state": "Colorado",
      "country": "US",
      "elevation": 5662,
      "lat": 39.7016983032,
      "lon": -104.751998901,
      "tz": "America\/Denver"
  },
  "KBKL": {
      "icao": "KBKL",
      "iata": "BKL",
      "name": "Burke Lakefront Airport",
      "city": "Cleveland",
      "state": "Ohio",
      "country": "US",
      "elevation": 583,
      "lat": 41.5175018311,
      "lon": -81.6832962036,
      "tz": "America\/New_York"
  },
  "KBKN": {
      "icao": "KBKN",
      "iata": "BWL",
      "name": "Blackwell Tonkawa Municipal Airport",
      "city": "Blackwell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1030,
      "lat": 36.74509811,
      "lon": -97.34960175,
      "tz": "America\/Chicago"
  },
  "KBKS": {
      "icao": "KBKS",
      "iata": "",
      "name": "Brooks County Airport",
      "city": "Falfurrias",
      "state": "Texas",
      "country": "US",
      "elevation": 113,
      "lat": 27.20680046,
      "lon": -98.12120056,
      "tz": "America\/Chicago"
  },
  "KBKT": {
      "icao": "KBKT",
      "iata": "BKT",
      "name": "Allen C Perkinson Blackstone Army Air Field",
      "city": "Blackstone",
      "state": "Virginia",
      "country": "US",
      "elevation": 439,
      "lat": 37.0741996765,
      "lon": -77.9574966431,
      "tz": "America\/New_York"
  },
  "KBKV": {
      "icao": "KBKV",
      "iata": "",
      "name": "Hernando County Airport",
      "city": "Brooksville",
      "state": "Florida",
      "country": "US",
      "elevation": 76,
      "lat": 28.47360039,
      "lon": -82.45539856,
      "tz": "America\/New_York"
  },
  "KBKW": {
      "icao": "KBKW",
      "iata": "BKW",
      "name": "Raleigh County Memorial Airport",
      "city": "Beckley",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2504,
      "lat": 37.7873001099,
      "lon": -81.1241989136,
      "tz": "America\/New_York"
  },
  "KBKX": {
      "icao": "KBKX",
      "iata": "BKX",
      "name": "Brookings Regional Airport",
      "city": "Brookings",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1648,
      "lat": 44.3047981262,
      "lon": -96.8169021606,
      "tz": "America\/Chicago"
  },
  "KBLF": {
      "icao": "KBLF",
      "iata": "BLF",
      "name": "Mercer County Airport",
      "city": "Bluefield",
      "state": "Illinois",
      "country": "US",
      "elevation": 2857,
      "lat": 37.2957992554,
      "lon": -81.2077026367,
      "tz": "America\/New_York"
  },
  "KBLH": {
      "icao": "KBLH",
      "iata": "BLH",
      "name": "Blythe Airport",
      "city": "Blythe",
      "state": "California",
      "country": "US",
      "elevation": 399,
      "lat": 33.6192016602,
      "lon": -114.717002869,
      "tz": "America\/Los_Angeles"
  },
  "KBLI": {
      "icao": "KBLI",
      "iata": "BLI",
      "name": "Bellingham International Airport",
      "city": "Bellingham",
      "state": "Washington",
      "country": "US",
      "elevation": 170,
      "lat": 48.7928009033,
      "lon": -122.5380020142,
      "tz": "America\/Los_Angeles"
  },
  "KBLM": {
      "icao": "KBLM",
      "iata": "BLM",
      "name": "Monmouth Executive Airport",
      "city": "Belmar\/Farmingdale",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 153,
      "lat": 40.18690109,
      "lon": -74.12490082,
      "tz": "America\/New_York"
  },
  "KBLU": {
      "icao": "KBLU",
      "iata": "BLU",
      "name": "Blue Canyon Nyack Airport",
      "city": "Emigrant Gap",
      "state": "California",
      "country": "US",
      "elevation": 5284,
      "lat": 39.2750015259,
      "lon": -120.709999084,
      "tz": "America\/Los_Angeles"
  },
  "KBLV": {
      "icao": "KBLV",
      "iata": "BLV",
      "name": "Scott AFB\/Midamerica Airport",
      "city": "Belleville",
      "state": "Illinois",
      "country": "US",
      "elevation": 459,
      "lat": 38.54520035,
      "lon": -89.83519745,
      "tz": "America\/Chicago"
  },
  "KBMC": {
      "icao": "KBMC",
      "iata": "BMC",
      "name": "Brigham City Airport",
      "city": "Brigham City",
      "state": "Utah",
      "country": "US",
      "elevation": 4229,
      "lat": 41.5523986816,
      "lon": -112.06199646,
      "tz": "America\/Denver"
  },
  "KBMG": {
      "icao": "KBMG",
      "iata": "BMG",
      "name": "Monroe County Airport",
      "city": "Bloomington",
      "state": "Alabama",
      "country": "US",
      "elevation": 846,
      "lat": 39.1459999084,
      "lon": -86.6166992188,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KBMI": {
      "icao": "KBMI",
      "iata": "BMI",
      "name": "Central Illinois Regional Airport at Bloomington-Normal",
      "city": "Bloomington-Normal",
      "state": "Illinois",
      "country": "US",
      "elevation": 871,
      "lat": 40.47710037,
      "lon": -88.91590118,
      "tz": "America\/Chicago"
  },
  "KBML": {
      "icao": "KBML",
      "iata": "BML",
      "name": "Berlin Regional Airport",
      "city": "Berlin",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 1161,
      "lat": 44.57540131,
      "lon": -71.17590332,
      "tz": "America\/New_York"
  },
  "KBMQ": {
      "icao": "KBMQ",
      "iata": "",
      "name": "Burnet Municipal Kate Craddock Field",
      "city": "Burnet",
      "state": "Texas",
      "country": "US",
      "elevation": 1284,
      "lat": 30.738899231,
      "lon": -98.2386016846,
      "tz": "America\/Chicago"
  },
  "KBMT": {
      "icao": "KBMT",
      "iata": "BMT",
      "name": "Beaumont Municipal Airport",
      "city": "Beaumont",
      "state": "Texas",
      "country": "US",
      "elevation": 32,
      "lat": 30.0706996918,
      "lon": -94.2157974243,
      "tz": "America\/Chicago"
  },
  "KBNA": {
      "icao": "KBNA",
      "iata": "BNA",
      "name": "Nashville International Airport",
      "city": "Nashville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 599,
      "lat": 36.1245002747,
      "lon": -86.6781997681,
      "tz": "America\/Chicago"
  },
  "KBNG": {
      "icao": "KBNG",
      "iata": "BNG",
      "name": "Banning Municipal Airport",
      "city": "Banning",
      "state": "California",
      "country": "US",
      "elevation": 2219,
      "lat": 33.9230995178,
      "lon": -116.850997925,
      "tz": "America\/Los_Angeles"
  },
  "KBNL": {
      "icao": "KBNL",
      "iata": "BNL",
      "name": "Barnwell Regional Airport",
      "city": "Barnwell",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 246,
      "lat": 33.25780106,
      "lon": -81.38829803,
      "tz": "America\/New_York"
  },
  "KBNO": {
      "icao": "KBNO",
      "iata": "BNO",
      "name": "Burns Municipal Airport",
      "city": "Burns",
      "state": "Oregon",
      "country": "US",
      "elevation": 4148,
      "lat": 43.5918998718,
      "lon": -118.955001831,
      "tz": "America\/Los_Angeles"
  },
  "KBNW": {
      "icao": "KBNW",
      "iata": "BNW",
      "name": "Boone Municipal Airport",
      "city": "Boone",
      "state": "Iowa",
      "country": "US",
      "elevation": 1160,
      "lat": 42.0495986938,
      "lon": -93.8476028442,
      "tz": "America\/Chicago"
  },
  "KBOI": {
      "icao": "KBOI",
      "iata": "BOI",
      "name": "Boise Air Terminal\/Gowen field",
      "city": "Boise",
      "state": "Idaho",
      "country": "US",
      "elevation": 2871,
      "lat": 43.56439972,
      "lon": -116.2229996,
      "tz": "America\/Boise"
  },
  "KBOS": {
      "icao": "KBOS",
      "iata": "BOS",
      "name": "General Edward Lawrence Logan International Airport",
      "city": "Boston",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 20,
      "lat": 42.36429977,
      "lon": -71.00520325,
      "tz": "America\/New_York"
  },
  "KBOW": {
      "icao": "KBOW",
      "iata": "BOW",
      "name": "Bartow Municipal Airport",
      "city": "Bartow",
      "state": "Florida",
      "country": "US",
      "elevation": 125,
      "lat": 27.9433994293,
      "lon": -81.7834014893,
      "tz": "America\/New_York"
  },
  "KBPG": {
      "icao": "KBPG",
      "iata": "BGS",
      "name": "Big Spring Mc Mahon-Wrinkle Airport",
      "city": "Big Spring",
      "state": "Texas",
      "country": "US",
      "elevation": 2573,
      "lat": 32.21260071,
      "lon": -101.5220032,
      "tz": "America\/Chicago"
  },
  "KBPI": {
      "icao": "KBPI",
      "iata": "BPI",
      "name": "Miley Memorial Field",
      "city": "Big Piney",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6990,
      "lat": 42.58509827,
      "lon": -110.1110001,
      "tz": "America\/Denver"
  },
  "KBPK": {
      "icao": "KBPK",
      "iata": "WMH",
      "name": "Ozark Regional Airport",
      "city": "Mountain Home",
      "state": "Arkansas",
      "country": "US",
      "elevation": 928,
      "lat": 36.3689002991,
      "lon": -92.4704971313,
      "tz": "America\/Chicago"
  },
  "KBPP": {
      "icao": "KBPP",
      "iata": "BWM",
      "name": "Bowman Municipal Airport",
      "city": "Bowman",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2958,
      "lat": 46.1870002747,
      "lon": -103.428001404,
      "tz": "America\/Denver"
  },
  "KBPT": {
      "icao": "KBPT",
      "iata": "BPT",
      "name": "Southeast Texas Regional Airport",
      "city": "Beaumont\/Port Arthur",
      "state": "Texas",
      "country": "US",
      "elevation": 15,
      "lat": 29.950799942,
      "lon": -94.0206985474,
      "tz": "America\/Chicago"
  },
  "KBQK": {
      "icao": "KBQK",
      "iata": "BQK",
      "name": "Brunswick Golden Isles Airport",
      "city": "Brunswick",
      "state": "Georgia",
      "country": "US",
      "elevation": 26,
      "lat": 31.2588005066,
      "lon": -81.4664993286,
      "tz": "America\/New_York"
  },
  "KBQP": {
      "icao": "KBQP",
      "iata": "",
      "name": "Morehouse Memorial Airport",
      "city": "Bastrop",
      "state": "Louisiana",
      "country": "US",
      "elevation": 168,
      "lat": 32.75510025,
      "lon": -91.88189697,
      "tz": "America\/Chicago"
  },
  "KBQR": {
      "icao": "KBQR",
      "iata": "",
      "name": "Buffalo Lancaster Regional Airport",
      "city": "Lancaster",
      "state": "New-York",
      "country": "US",
      "elevation": 750,
      "lat": 42.92229843,
      "lon": -78.61229706,
      "tz": "America\/New_York"
  },
  "KBRD": {
      "icao": "KBRD",
      "iata": "BRD",
      "name": "Brainerd Lakes Regional Airport",
      "city": "Brainerd",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1232,
      "lat": 46.39830017,
      "lon": -94.13809967,
      "tz": "America\/Chicago"
  },
  "KBRL": {
      "icao": "KBRL",
      "iata": "BRL",
      "name": "Southeast Iowa Regional Airport",
      "city": "Burlington",
      "state": "Iowa",
      "country": "US",
      "elevation": 698,
      "lat": 40.7831993103,
      "lon": -91.12550354,
      "tz": "America\/Chicago"
  },
  "KBRO": {
      "icao": "KBRO",
      "iata": "BRO",
      "name": "Brownsville South Padre Island International Airport",
      "city": "Brownsville",
      "state": "Texas",
      "country": "US",
      "elevation": 22,
      "lat": 25.9067993164,
      "lon": -97.4259033203,
      "tz": "America\/Chicago"
  },
  "KBRY": {
      "icao": "KBRY",
      "iata": "BRY",
      "name": "Samuels Field",
      "city": "Bardstown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 669,
      "lat": 37.8143005371,
      "lon": -85.4996032715,
      "tz": "America\/New_York"
  },
  "KBST": {
      "icao": "KBST",
      "iata": "",
      "name": "Belfast Municipal Airport",
      "city": "Belfast",
      "state": "Maine",
      "country": "US",
      "elevation": 198,
      "lat": 44.4094009399,
      "lon": -69.0119018555,
      "tz": "America\/New_York"
  },
  "KBTA": {
      "icao": "KBTA",
      "iata": "",
      "name": "Blair Municipal Airport",
      "city": "Blair",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1325,
      "lat": 41.4180984497,
      "lon": -96.1136016846,
      "tz": "America\/Chicago"
  },
  "KBTF": {
      "icao": "KBTF",
      "iata": "BTF",
      "name": "Skypark Airport",
      "city": "Bountiful",
      "state": "Utah",
      "country": "US",
      "elevation": 4234,
      "lat": 40.8694000244,
      "lon": -111.927001953,
      "tz": "America\/Denver"
  },
  "KBTL": {
      "icao": "KBTL",
      "iata": "BTL",
      "name": "W K Kellogg Airport",
      "city": "Battle Creek",
      "state": "Michigan",
      "country": "US",
      "elevation": 952,
      "lat": 42.3073005676,
      "lon": -85.2515029907,
      "tz": "America\/Detroit"
  },
  "KBTM": {
      "icao": "KBTM",
      "iata": "BTM",
      "name": "Bert Mooney Airport",
      "city": "Butte",
      "state": "Montana",
      "country": "US",
      "elevation": 5550,
      "lat": 45.9547996521,
      "lon": -112.4970016479,
      "tz": "America\/Denver"
  },
  "KBTN": {
      "icao": "KBTN",
      "iata": "TTO",
      "name": "Britton Municipal Airport",
      "city": "Britton",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1318,
      "lat": 45.8152008057,
      "lon": -97.7431030273,
      "tz": "America\/Chicago"
  },
  "KBTP": {
      "icao": "KBTP",
      "iata": "BTP",
      "name": "Butler County-K W Scholter Field",
      "city": "Butler",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1248,
      "lat": 40.77690125,
      "lon": -79.9496994,
      "tz": "America\/New_York"
  },
  "KBTR": {
      "icao": "KBTR",
      "iata": "BTR",
      "name": "Baton Rouge Metropolitan Ryan Field",
      "city": "Baton Rouge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 70,
      "lat": 30.53319931,
      "lon": -91.14959717,
      "tz": "America\/Chicago"
  },
  "KBTV": {
      "icao": "KBTV",
      "iata": "BTV",
      "name": "Burlington International Airport",
      "city": "Burlington",
      "state": "Vermont",
      "country": "US",
      "elevation": 335,
      "lat": 44.4719009399,
      "lon": -73.1532974243,
      "tz": "America\/New_York"
  },
  "KBTY": {
      "icao": "KBTY",
      "iata": "BTY",
      "name": "Beatty Airport",
      "city": "Beatty",
      "state": "Nevada",
      "country": "US",
      "elevation": 3170,
      "lat": 36.8610992432,
      "lon": -116.787002563,
      "tz": "America\/Los_Angeles"
  },
  "KBUB": {
      "icao": "KBUB",
      "iata": "BUB",
      "name": "Cram Field",
      "city": "Burwell",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2182,
      "lat": 41.7766990662,
      "lon": -99.1496963501,
      "tz": "America\/Chicago"
  },
  "KBUF": {
      "icao": "KBUF",
      "iata": "BUF",
      "name": "Buffalo Niagara International Airport",
      "city": "Buffalo",
      "state": "New-York",
      "country": "US",
      "elevation": 728,
      "lat": 42.94049835,
      "lon": -78.73220062,
      "tz": "America\/New_York"
  },
  "KBUM": {
      "icao": "KBUM",
      "iata": "BUM",
      "name": "Butler Memorial Airport",
      "city": "Butler",
      "state": "Missouri",
      "country": "US",
      "elevation": 892,
      "lat": 38.2897987366,
      "lon": -94.3401031494,
      "tz": "America\/Chicago"
  },
  "KBUR": {
      "icao": "KBUR",
      "iata": "BUR",
      "name": "Bob Hope Airport",
      "city": "Burbank",
      "state": "California",
      "country": "US",
      "elevation": 778,
      "lat": 34.2006988525,
      "lon": -118.3590011597,
      "tz": "America\/Los_Angeles"
  },
  "KBUU": {
      "icao": "KBUU",
      "iata": "",
      "name": "Burlington Municipal Airport",
      "city": "Burlington",
      "state": "Iowa",
      "country": "US",
      "elevation": 779,
      "lat": 42.690700531,
      "lon": -88.3046035767,
      "tz": "America\/Chicago"
  },
  "KBUY": {
      "icao": "KBUY",
      "iata": "",
      "name": "Burlington Alamance Regional Airport",
      "city": "Burlington",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 617,
      "lat": 36.048500061,
      "lon": -79.474899292,
      "tz": "America\/New_York"
  },
  "KBVI": {
      "icao": "KBVI",
      "iata": "BFP",
      "name": "Beaver County Airport",
      "city": "Beaver Falls",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1253,
      "lat": 40.7724990845,
      "lon": -80.3914031982,
      "tz": "America\/New_York"
  },
  "KBVN": {
      "icao": "KBVN",
      "iata": "",
      "name": "Albion Municipal Airport",
      "city": "Albion",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1806,
      "lat": 41.7285995483,
      "lon": -98.0558013916,
      "tz": "America\/Chicago"
  },
  "KBVO": {
      "icao": "KBVO",
      "iata": "BVO",
      "name": "Bartlesville Municipal Airport",
      "city": "Bartlesville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 711,
      "lat": 36.76250076,
      "lon": -96.01119995,
      "tz": "America\/Chicago"
  },
  "KBVS": {
      "icao": "KBVS",
      "iata": "MVW",
      "name": "Skagit Regional Airport",
      "city": "Burlington\/Mount Vernon",
      "state": "Washington",
      "country": "US",
      "elevation": 144,
      "lat": 48.4709014893,
      "lon": -122.4209976196,
      "tz": "America\/Los_Angeles"
  },
  "KBVU": {
      "icao": "KBVU",
      "iata": "BLD",
      "name": "Boulder City Municipal Airport",
      "city": "Boulder City",
      "state": "Nevada",
      "country": "US",
      "elevation": 2201,
      "lat": 35.9474983215,
      "lon": -114.861000061,
      "tz": "America\/Los_Angeles"
  },
  "KBVX": {
      "icao": "KBVX",
      "iata": "BVX",
      "name": "Batesville Regional Airport",
      "city": "Batesville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 465,
      "lat": 35.7262001,
      "lon": -91.64730072,
      "tz": "America\/Chicago"
  },
  "KBVY": {
      "icao": "KBVY",
      "iata": "BVY",
      "name": "Beverly Municipal Airport",
      "city": "Beverly",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 107,
      "lat": 42.5842018127,
      "lon": -70.9164962769,
      "tz": "America\/New_York"
  },
  "KBWC": {
      "icao": "KBWC",
      "iata": "BWC",
      "name": "Brawley Municipal Airport",
      "city": "Brawley",
      "state": "California",
      "country": "US",
      "elevation": -128,
      "lat": 32.99290085,
      "lon": -115.5169983,
      "tz": "America\/Los_Angeles"
  },
  "KBWD": {
      "icao": "KBWD",
      "iata": "BWD",
      "name": "Brownwood Regional Airport",
      "city": "Brownwood",
      "state": "Texas",
      "country": "US",
      "elevation": 1387,
      "lat": 31.7936000824,
      "lon": -98.9564971924,
      "tz": "America\/Chicago"
  },
  "KBWG": {
      "icao": "KBWG",
      "iata": "BWG",
      "name": "Bowling Green Warren County Regional Airport",
      "city": "Bowling Green",
      "state": "Kentucky",
      "country": "US",
      "elevation": 547,
      "lat": 36.9645004272,
      "lon": -86.4197006226,
      "tz": "America\/Chicago"
  },
  "KBWI": {
      "icao": "KBWI",
      "iata": "BWI",
      "name": "Baltimore\/Washington International Thurgood Marshal Airport",
      "city": "Baltimore",
      "state": "Maryland",
      "country": "US",
      "elevation": 146,
      "lat": 39.17539978,
      "lon": -76.66829681,
      "tz": "America\/New_York"
  },
  "KBWP": {
      "icao": "KBWP",
      "iata": "WAH",
      "name": "Harry Stern Airport",
      "city": "Wahpeton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 968,
      "lat": 46.2440986633,
      "lon": -96.6073989868,
      "tz": "America\/Chicago"
  },
  "KBXA": {
      "icao": "KBXA",
      "iata": "BXA",
      "name": "George R Carr Memorial Air Field",
      "city": "Bogalusa",
      "state": "Louisiana",
      "country": "US",
      "elevation": 119,
      "lat": 30.8136997223,
      "lon": -89.8649978638,
      "tz": "America\/Chicago"
  },
  "KBXG": {
      "icao": "KBXG",
      "iata": "",
      "name": "Burke County Airport",
      "city": "Waynesboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 302,
      "lat": 33.0413017273,
      "lon": -82.0027008057,
      "tz": "America\/New_York"
  },
  "KBXK": {
      "icao": "KBXK",
      "iata": "BXK",
      "name": "Buckeye Municipal Airport",
      "city": "Buckeye",
      "state": "Arizona",
      "country": "US",
      "elevation": 1033,
      "lat": 33.42039871,
      "lon": -112.685997,
      "tz": "America\/Phoenix"
  },
  "KBXM": {
      "icao": "KBXM",
      "iata": "NHZ",
      "name": "Brunswick Executive Airport",
      "city": "Brunswick",
      "state": "Maine",
      "country": "US",
      "elevation": 75,
      "lat": 43.8923551,
      "lon": -69.9388297,
      "tz": "America\/New_York"
  },
  "KBYG": {
      "icao": "KBYG",
      "iata": "BYG",
      "name": "Johnson County Airport",
      "city": "Buffalo",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4968,
      "lat": 44.3810997009,
      "lon": -106.7220001221,
      "tz": "America\/Denver"
  },
  "KBYH": {
      "icao": "KBYH",
      "iata": "BYH",
      "name": "Arkansas International Airport",
      "city": "Blytheville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 254,
      "lat": 35.9642982483,
      "lon": -89.9440002441,
      "tz": "America\/Chicago"
  },
  "KBYI": {
      "icao": "KBYI",
      "iata": "BYI",
      "name": "Burley Municipal Airport",
      "city": "Burley",
      "state": "Idaho",
      "country": "US",
      "elevation": 4150,
      "lat": 42.5425987244,
      "lon": -113.7720031738,
      "tz": "America\/Boise"
  },
  "KBYS": {
      "icao": "KBYS",
      "iata": "BYS",
      "name": "Bicycle Lake Army Air Field",
      "city": "Fort Irwin\/Barstow",
      "state": "California",
      "country": "US",
      "elevation": 2350,
      "lat": 35.2804985046,
      "lon": -116.629997253,
      "tz": "America\/Los_Angeles"
  },
  "KBYY": {
      "icao": "KBYY",
      "iata": "BBC",
      "name": "Bay City Municipal Airport",
      "city": "Bay City",
      "state": "Texas",
      "country": "US",
      "elevation": 45,
      "lat": 28.9733009338,
      "lon": -95.8635025024,
      "tz": "America\/Chicago"
  },
  "KBZN": {
      "icao": "KBZN",
      "iata": "BZN",
      "name": "Gallatin Field",
      "city": "Bozeman",
      "state": "Montana",
      "country": "US",
      "elevation": 4473,
      "lat": 45.77750015,
      "lon": -111.1529999,
      "tz": "America\/Denver"
  },
  "KC02": {
      "icao": "KC02",
      "iata": "",
      "name": "Grand Geneva Resort Airport",
      "city": "Lake Geneva",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 835,
      "lat": 42.6148986816,
      "lon": -88.3896026611,
      "tz": "America\/Chicago"
  },
  "KC03": {
      "icao": "KC03",
      "iata": "",
      "name": "Nappanee Municipal Airport",
      "city": "Nappanee",
      "state": "Indiana",
      "country": "US",
      "elevation": 860,
      "lat": 41.4462013245,
      "lon": -85.9347991943,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KC04": {
      "icao": "KC04",
      "iata": "",
      "name": "Oceana County Airport",
      "city": "Hart\/Shelby",
      "state": "Michigan",
      "country": "US",
      "elevation": 910,
      "lat": 43.6417007446,
      "lon": -86.3292007446,
      "tz": "America\/Detroit"
  },
  "KC08": {
      "icao": "KC08",
      "iata": "",
      "name": "Silver West Airport",
      "city": "Westcliffe",
      "state": "Colorado",
      "country": "US",
      "elevation": 8290,
      "lat": 38.011100769,
      "lon": -105.3730010986,
      "tz": "America\/Denver"
  },
  "KC09": {
      "icao": "KC09",
      "iata": "",
      "name": "Morris Municipal James R. Washburn field",
      "city": "Morris",
      "state": "Illinois",
      "country": "US",
      "elevation": 584,
      "lat": 41.42539978,
      "lon": -88.41870117,
      "tz": "America\/Chicago"
  },
  "KC15": {
      "icao": "KC15",
      "iata": "",
      "name": "Pekin Municipal Airport",
      "city": "Pekin",
      "state": "Illinois",
      "country": "US",
      "elevation": 530,
      "lat": 40.4882011414,
      "lon": -89.6759033203,
      "tz": "America\/Chicago"
  },
  "KC16": {
      "icao": "KC16",
      "iata": "",
      "name": "Frasca Field",
      "city": "Urbana",
      "state": "Illinois",
      "country": "US",
      "elevation": 735,
      "lat": 40.1464004517,
      "lon": -88.1985015869,
      "tz": "America\/Chicago"
  },
  "KC17": {
      "icao": "KC17",
      "iata": "",
      "name": "Marion Airport",
      "city": "Marion",
      "state": "Iowa",
      "country": "US",
      "elevation": 862,
      "lat": 42.0311012268,
      "lon": -91.529296875,
      "tz": "America\/Chicago"
  },
  "KC20": {
      "icao": "KC20",
      "iata": "",
      "name": "Andrews University Airpark",
      "city": "Berrien Springs",
      "state": "Michigan",
      "country": "US",
      "elevation": 668,
      "lat": 41.9516983032,
      "lon": -86.3676986694,
      "tz": "America\/Detroit"
  },
  "KC22": {
      "icao": "KC22",
      "iata": "",
      "name": "Centre Municipal Airport",
      "city": "Centre",
      "state": "Alabama",
      "country": "US",
      "elevation": 619,
      "lat": 34.1599006653,
      "lon": -85.6351013184,
      "tz": "America\/Chicago"
  },
  "KC27": {
      "icao": "KC27",
      "iata": "",
      "name": "Manchester Municipal Airport",
      "city": "Manchester",
      "state": "Iowa",
      "country": "US",
      "elevation": 987,
      "lat": 42.4933013916,
      "lon": -91.4984970093,
      "tz": "America\/Chicago"
  },
  "KC29": {
      "icao": "KC29",
      "iata": "",
      "name": "Middleton Municipal Morey Field",
      "city": "Middleton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 928,
      "lat": 43.1142997742,
      "lon": -89.53150177,
      "tz": "America\/Chicago"
  },
  "KC35": {
      "icao": "KC35",
      "iata": "",
      "name": "Reedsburg Municipal Airport",
      "city": "Reedsburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 907,
      "lat": 43.5259017944,
      "lon": -89.9832000732,
      "tz": "America\/Chicago"
  },
  "KC47": {
      "icao": "KC47",
      "iata": "",
      "name": "Portage Municipal Airport",
      "city": "Portage",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 825,
      "lat": 43.5602989197,
      "lon": -89.4829025269,
      "tz": "America\/Chicago"
  },
  "KC62": {
      "icao": "KC62",
      "iata": "",
      "name": "Kendallville Municipal Airport",
      "city": "Kendallville",
      "state": "Indiana",
      "country": "US",
      "elevation": 1005,
      "lat": 41.4726982117,
      "lon": -85.2608032227,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KC65": {
      "icao": "KC65",
      "iata": "",
      "name": "Plymouth Municipal Airport",
      "city": "Plymouth",
      "state": "Indiana",
      "country": "US",
      "elevation": 800,
      "lat": 41.3651008606,
      "lon": -86.3004989624,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KC71": {
      "icao": "KC71",
      "iata": "",
      "name": "Crosby Municipal Airport",
      "city": "Crosby",
      "state": "Mississippi",
      "country": "US",
      "elevation": 336,
      "lat": 31.295999527,
      "lon": -91.0529022217,
      "tz": "America\/Chicago"
  },
  "KC73": {
      "icao": "KC73",
      "iata": "",
      "name": "Dixon Municipal Charles R. Walgreen Field",
      "city": "Dixon",
      "state": "Illinois",
      "country": "US",
      "elevation": 785,
      "lat": 41.83369827,
      "lon": -89.44619751,
      "tz": "America\/Chicago"
  },
  "KC74": {
      "icao": "KC74",
      "iata": "",
      "name": "Cassville Municipal Airport",
      "city": "Cassville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 627,
      "lat": 42.7042007446,
      "lon": -90.9645996094,
      "tz": "America\/Chicago"
  },
  "KC75": {
      "icao": "KC75",
      "iata": "",
      "name": "Marshall County Airport",
      "city": "Lacon",
      "state": "Illinois",
      "country": "US",
      "elevation": 568,
      "lat": 41.0192985535,
      "lon": -89.3863983154,
      "tz": "America\/Chicago"
  },
  "KC77": {
      "icao": "KC77",
      "iata": "",
      "name": "Poplar Grove Airport",
      "city": "Poplar Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 858,
      "lat": 42.3228988647,
      "lon": -88.8363037109,
      "tz": "America\/Chicago"
  },
  "KC81": {
      "icao": "KC81",
      "iata": "",
      "name": "Campbell Airport",
      "city": "Grayslake",
      "state": "Illinois",
      "country": "US",
      "elevation": 788,
      "lat": 42.3246002197,
      "lon": -88.0740966797,
      "tz": "America\/Chicago"
  },
  "KC83": {
      "icao": "KC83",
      "iata": "",
      "name": "Byron Airport",
      "city": "Byron",
      "state": "California",
      "country": "US",
      "elevation": 79,
      "lat": 37.8283996582,
      "lon": -121.6259994507,
      "tz": "America\/Los_Angeles"
  },
  "KC91": {
      "icao": "KC91",
      "iata": "",
      "name": "Dowagiac Municipal Airport",
      "city": "Dowagiac",
      "state": "Michigan",
      "country": "US",
      "elevation": 747,
      "lat": 41.99290085,
      "lon": -86.12799835,
      "tz": "America\/Detroit"
  },
  "KCAD": {
      "icao": "KCAD",
      "iata": "CAD",
      "name": "Wexford County Airport",
      "city": "Cadillac",
      "state": "Michigan",
      "country": "US",
      "elevation": 1307,
      "lat": 44.2752990723,
      "lon": -85.4188995361,
      "tz": "America\/Detroit"
  },
  "KCAE": {
      "icao": "KCAE",
      "iata": "CAE",
      "name": "Columbia Metropolitan Airport",
      "city": "Columbia",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 236,
      "lat": 33.9388008118,
      "lon": -81.1194992065,
      "tz": "America\/New_York"
  },
  "KCAG": {
      "icao": "KCAG",
      "iata": "CIG",
      "name": "Craig Moffat Airport",
      "city": "Craig",
      "state": "Colorado",
      "country": "US",
      "elevation": 6193,
      "lat": 40.4952011108,
      "lon": -107.5220031738,
      "tz": "America\/Denver"
  },
  "KCAK": {
      "icao": "KCAK",
      "iata": "CAK",
      "name": "Akron Canton Regional Airport",
      "city": "Akron",
      "state": "Ohio",
      "country": "US",
      "elevation": 1228,
      "lat": 40.9160995483,
      "lon": -81.442199707,
      "tz": "America\/New_York"
  },
  "KCAO": {
      "icao": "KCAO",
      "iata": "CAO",
      "name": "Clayton Municipal Airpark",
      "city": "Clayton",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4965,
      "lat": 36.4462013245,
      "lon": -103.166999817,
      "tz": "America\/Denver"
  },
  "KCAR": {
      "icao": "KCAR",
      "iata": "CAR",
      "name": "Caribou Municipal Airport",
      "city": "Caribou",
      "state": "Maine",
      "country": "US",
      "elevation": 626,
      "lat": 46.8714981079,
      "lon": -68.0178985596,
      "tz": "America\/New_York"
  },
  "KCAV": {
      "icao": "KCAV",
      "iata": "",
      "name": "Clarion Municipal Airport",
      "city": "Clarion",
      "state": "Iowa",
      "country": "US",
      "elevation": 1162,
      "lat": 42.7419013977,
      "lon": -93.7589035034,
      "tz": "America\/Chicago"
  },
  "KCBE": {
      "icao": "KCBE",
      "iata": "CBE",
      "name": "Greater Cumberland Regional Airport",
      "city": "Cumberland",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 775,
      "lat": 39.615398407,
      "lon": -78.7609024048,
      "tz": "America\/New_York"
  },
  "KCBF": {
      "icao": "KCBF",
      "iata": "CBF",
      "name": "Council Bluffs Municipal Airport",
      "city": "Council Bluffs",
      "state": "Iowa",
      "country": "US",
      "elevation": 1253,
      "lat": 41.2592010498,
      "lon": -95.760597229,
      "tz": "America\/Chicago"
  },
  "KCBG": {
      "icao": "KCBG",
      "iata": "",
      "name": "Cambridge Municipal Airport",
      "city": "Cambridge",
      "state": "Minnesota",
      "country": "US",
      "elevation": 945,
      "lat": 45.5574989319,
      "lon": -93.2641983032,
      "tz": "America\/Chicago"
  },
  "KCBK": {
      "icao": "KCBK",
      "iata": "CBK",
      "name": "Shalz Field",
      "city": "Colby",
      "state": "Kansas",
      "country": "US",
      "elevation": 3187,
      "lat": 39.42750168,
      "lon": -101.0469971,
      "tz": "America\/Chicago"
  },
  "KCBM": {
      "icao": "KCBM",
      "iata": "CBM",
      "name": "Columbus Air Force Base",
      "city": "Columbus",
      "state": "Mississippi",
      "country": "US",
      "elevation": 219,
      "lat": 33.6437988281,
      "lon": -88.4438018799,
      "tz": "America\/Chicago"
  },
  "KCCA": {
      "icao": "KCCA",
      "iata": "",
      "name": "Clinton Municipal Airport",
      "city": "Clinton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 514,
      "lat": 35.5978012085,
      "lon": -92.4515991211,
      "tz": "America\/Chicago"
  },
  "KCCB": {
      "icao": "KCCB",
      "iata": "CCB",
      "name": "Cable Airport",
      "city": "Upland",
      "state": "California",
      "country": "US",
      "elevation": 1444,
      "lat": 34.1115989685,
      "lon": -117.68800354,
      "tz": "America\/Los_Angeles"
  },
  "KCCO": {
      "icao": "KCCO",
      "iata": "",
      "name": "Newnan Coweta County Airport",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 970,
      "lat": 33.3115997314,
      "lon": -84.7697982788,
      "tz": "America\/New_York"
  },
  "KCCR": {
      "icao": "KCCR",
      "iata": "CCR",
      "name": "Buchanan Field",
      "city": "Concord",
      "state": "California",
      "country": "US",
      "elevation": 26,
      "lat": 37.9897003174,
      "lon": -122.056999207,
      "tz": "America\/Los_Angeles"
  },
  "KCCY": {
      "icao": "KCCY",
      "iata": "CCY",
      "name": "Northeast Iowa Regional Airport",
      "city": "Charles City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1125,
      "lat": 43.0726013184,
      "lon": -92.6108016968,
      "tz": "America\/Chicago"
  },
  "KCDA": {
      "icao": "KCDA",
      "iata": "LLX",
      "name": "Caledonia County Airport",
      "city": "Lyndonville",
      "state": "Vermont",
      "country": "US",
      "elevation": 1188,
      "lat": 44.5690994263,
      "lon": -72.0179977417,
      "tz": "America\/New_York"
  },
  "KCDC": {
      "icao": "KCDC",
      "iata": "CDC",
      "name": "Cedar City Regional Airport",
      "city": "Cedar City",
      "state": "Utah",
      "country": "US",
      "elevation": 5622,
      "lat": 37.7010002136,
      "lon": -113.0989990234,
      "tz": "America\/Denver"
  },
  "KCDH": {
      "icao": "KCDH",
      "iata": "CDH",
      "name": "Harrell Field",
      "city": "Camden",
      "state": "Arkansas",
      "country": "US",
      "elevation": 130,
      "lat": 33.6227989197,
      "lon": -92.7633972168,
      "tz": "America\/Chicago"
  },
  "KCDI": {
      "icao": "KCDI",
      "iata": "",
      "name": "Cambridge Municipal Airport",
      "city": "Cambridge",
      "state": "Ohio",
      "country": "US",
      "elevation": 799,
      "lat": 39.9749984741,
      "lon": -81.5775985718,
      "tz": "America\/New_York"
  },
  "KCDK": {
      "icao": "KCDK",
      "iata": "CDK",
      "name": "George T Lewis Airport",
      "city": "Cedar Key",
      "state": "Florida",
      "country": "US",
      "elevation": 11,
      "lat": 29.1352567648,
      "lon": -83.0483901501,
      "tz": "America\/New_York"
  },
  "KCDN": {
      "icao": "KCDN",
      "iata": "CDN",
      "name": "Woodward Field",
      "city": "Camden",
      "state": "New-York",
      "country": "US",
      "elevation": 302,
      "lat": 34.2835998535,
      "lon": -80.5649032593,
      "tz": "America\/New_York"
  },
  "KCDR": {
      "icao": "KCDR",
      "iata": "CDR",
      "name": "Chadron Municipal Airport",
      "city": "Chadron",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3297,
      "lat": 42.837600708,
      "lon": -103.095001221,
      "tz": "America\/Denver"
  },
  "KCDS": {
      "icao": "KCDS",
      "iata": "CDS",
      "name": "Childress Municipal Airport",
      "city": "Childress",
      "state": "Texas",
      "country": "US",
      "elevation": 1954,
      "lat": 34.4337997437,
      "lon": -100.288002014,
      "tz": "America\/Chicago"
  },
  "KCDW": {
      "icao": "KCDW",
      "iata": "CDW",
      "name": "Essex County Airport",
      "city": "Caldwell",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 173,
      "lat": 40.8751983643,
      "lon": -74.2814025879,
      "tz": "America\/New_York"
  },
  "KCEA": {
      "icao": "KCEA",
      "iata": "CEA",
      "name": "Cessna Acft Field",
      "city": "Wichita",
      "state": "Kansas",
      "country": "US",
      "elevation": 1378,
      "lat": 37.648601532,
      "lon": -97.2506027222,
      "tz": "America\/Chicago"
  },
  "KCEC": {
      "icao": "KCEC",
      "iata": "CEC",
      "name": "Jack Mc Namara Field Airport",
      "city": "Crescent City",
      "state": "California",
      "country": "US",
      "elevation": 61,
      "lat": 41.78020096,
      "lon": -124.2369995,
      "tz": "America\/Los_Angeles"
  },
  "KCEF": {
      "icao": "KCEF",
      "iata": "CEF",
      "name": "Westover ARB\/Metropolitan Airport",
      "city": "Springfield\/Chicopee",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 241,
      "lat": 42.19400024,
      "lon": -72.53479767,
      "tz": "America\/New_York"
  },
  "KCEK": {
      "icao": "KCEK",
      "iata": "",
      "name": "Crete Municipal Airport",
      "city": "Crete",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1500,
      "lat": 40.61790085,
      "lon": -96.92489624,
      "tz": "America\/Chicago"
  },
  "KCEU": {
      "icao": "KCEU",
      "iata": "CEU",
      "name": "Oconee County Regional Airport",
      "city": "Clemson",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 892,
      "lat": 34.6719017,
      "lon": -82.8864975,
      "tz": "America\/New_York"
  },
  "KCEV": {
      "icao": "KCEV",
      "iata": "CEV",
      "name": "Mettel Field",
      "city": "Connersville",
      "state": "Indiana",
      "country": "US",
      "elevation": 867,
      "lat": 39.6985015869,
      "lon": -85.129699707,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KCEW": {
      "icao": "KCEW",
      "iata": "CEW",
      "name": "Bob Sikes Airport",
      "city": "Crestview",
      "state": "Florida",
      "country": "US",
      "elevation": 213,
      "lat": 30.778799057,
      "lon": -86.522102356,
      "tz": "America\/Chicago"
  },
  "KCEY": {
      "icao": "KCEY",
      "iata": "CEY",
      "name": "Kyle Oakley Field",
      "city": "Murray",
      "state": "Kentucky",
      "country": "US",
      "elevation": 577,
      "lat": 36.66460037,
      "lon": -88.37280273,
      "tz": "America\/Chicago"
  },
  "KCEZ": {
      "icao": "KCEZ",
      "iata": "CEZ",
      "name": "Cortez Municipal Airport",
      "city": "Cortez",
      "state": "Colorado",
      "country": "US",
      "elevation": 5918,
      "lat": 37.3030014038,
      "lon": -108.627998352,
      "tz": "America\/Denver"
  },
  "KCFD": {
      "icao": "KCFD",
      "iata": "CFD",
      "name": "Coulter Field",
      "city": "Bryan",
      "state": "Texas",
      "country": "US",
      "elevation": 367,
      "lat": 30.7157001495,
      "lon": -96.3313980103,
      "tz": "America\/Chicago"
  },
  "KCFE": {
      "icao": "KCFE",
      "iata": "",
      "name": "Buffalo Municipal Airport",
      "city": "Buffalo",
      "state": "Minnesota",
      "country": "US",
      "elevation": 967,
      "lat": 45.1590003967,
      "lon": -93.8432998657,
      "tz": "America\/Chicago"
  },
  "KCFJ": {
      "icao": "KCFJ",
      "iata": "",
      "name": "Crawfordsville Municipal Airport",
      "city": "Crawfordsville",
      "state": "Indiana",
      "country": "US",
      "elevation": 799,
      "lat": 39.9756011963,
      "lon": -86.9198989868,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KCFS": {
      "icao": "KCFS",
      "iata": "TZC",
      "name": "Tuscola Area Airport",
      "city": "Caro",
      "state": "Michigan",
      "country": "US",
      "elevation": 701,
      "lat": 43.4588012695,
      "lon": -83.4455032349,
      "tz": "America\/Detroit"
  },
  "KCFT": {
      "icao": "KCFT",
      "iata": "CFT",
      "name": "Greenlee County Airport",
      "city": "Clifton\/Morenci",
      "state": "Arizona",
      "country": "US",
      "elevation": 3798,
      "lat": 32.95280075,
      "lon": -109.2099991,
      "tz": "America\/Phoenix"
  },
  "KCFV": {
      "icao": "KCFV",
      "iata": "CFV",
      "name": "Coffeyville Municipal Airport",
      "city": "Coffeyville",
      "state": "Kansas",
      "country": "US",
      "elevation": 754,
      "lat": 37.09400177,
      "lon": -95.5718994141,
      "tz": "America\/Chicago"
  },
  "KCGC": {
      "icao": "KCGC",
      "iata": "",
      "name": "Crystal River Airport",
      "city": "Crystal River",
      "state": "Florida",
      "country": "US",
      "elevation": 9,
      "lat": 28.8673000336,
      "lon": -82.5712966919,
      "tz": "America\/New_York"
  },
  "KCGE": {
      "icao": "KCGE",
      "iata": "CGE",
      "name": "Cambridge Dorchester Airport",
      "city": "Cambridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 20,
      "lat": 38.53929901,
      "lon": -76.03040314,
      "tz": "America\/New_York"
  },
  "KCGF": {
      "icao": "KCGF",
      "iata": "CGF",
      "name": "Cuyahoga County Airport",
      "city": "Cleveland",
      "state": "Ohio",
      "country": "US",
      "elevation": 879,
      "lat": 41.5651016235,
      "lon": -81.4863967896,
      "tz": "America\/New_York"
  },
  "KCGI": {
      "icao": "KCGI",
      "iata": "CGI",
      "name": "Cape Girardeau Regional Airport",
      "city": "Cape Girardeau",
      "state": "Missouri",
      "country": "US",
      "elevation": 342,
      "lat": 37.2252998352,
      "lon": -89.5708007813,
      "tz": "America\/Chicago"
  },
  "KCGS": {
      "icao": "KCGS",
      "iata": "CGS",
      "name": "College Park Airport",
      "city": "College Park",
      "state": "Maryland",
      "country": "US",
      "elevation": 48,
      "lat": 38.9805984497,
      "lon": -76.9223022461,
      "tz": "America\/New_York"
  },
  "KCGZ": {
      "icao": "KCGZ",
      "iata": "CGZ",
      "name": "Casa Grande Municipal Airport",
      "city": "Casa Grande",
      "state": "Arizona",
      "country": "US",
      "elevation": 1464,
      "lat": 32.9548988342,
      "lon": -111.766998291,
      "tz": "America\/Phoenix"
  },
  "KCHA": {
      "icao": "KCHA",
      "iata": "CHA",
      "name": "Lovell Field",
      "city": "Chattanooga",
      "state": "Tennessee",
      "country": "US",
      "elevation": 683,
      "lat": 35.0353012085,
      "lon": -85.2037963867,
      "tz": "America\/New_York"
  },
  "KCHD": {
      "icao": "KCHD",
      "iata": "",
      "name": "Chandler Municipal Airport",
      "city": "Chandler",
      "state": "Arizona",
      "country": "US",
      "elevation": 1243,
      "lat": 33.2691001892,
      "lon": -111.8109970093,
      "tz": "America\/Phoenix"
  },
  "KCHK": {
      "icao": "KCHK",
      "iata": "CHK",
      "name": "Chickasha Municipal Airport",
      "city": "Chickasha",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1152,
      "lat": 35.09740067,
      "lon": -97.96769714,
      "tz": "America\/Chicago"
  },
  "KCHN": {
      "icao": "KCHN",
      "iata": "",
      "name": "Wauchula Municipal Airport",
      "city": "Wauchula",
      "state": "Florida",
      "country": "US",
      "elevation": 106,
      "lat": 27.5149002075,
      "lon": -81.8805007935,
      "tz": "America\/New_York"
  },
  "KCHO": {
      "icao": "KCHO",
      "iata": "CHO",
      "name": "Charlottesville Albemarle Airport",
      "city": "Charlottesville",
      "state": "Virginia",
      "country": "US",
      "elevation": 639,
      "lat": 38.1385993958,
      "lon": -78.4529037476,
      "tz": "America\/New_York"
  },
  "KCHQ": {
      "icao": "KCHQ",
      "iata": "",
      "name": "Mississippi County Airport",
      "city": "Charleston",
      "state": "Missouri",
      "country": "US",
      "elevation": 313,
      "lat": 36.8420982361,
      "lon": -89.359703064,
      "tz": "America\/Chicago"
  },
  "KCHS": {
      "icao": "KCHS",
      "iata": "CHS",
      "name": "Charleston Air Force Base-International Airport",
      "city": "Charleston",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 46,
      "lat": 32.89860153,
      "lon": -80.04049683,
      "tz": "America\/New_York"
  },
  "KCHT": {
      "icao": "KCHT",
      "iata": "",
      "name": "Chillicothe Municipal Airport",
      "city": "Chillicothe",
      "state": "Missouri",
      "country": "US",
      "elevation": 783,
      "lat": 39.7821998596,
      "lon": -93.4956970215,
      "tz": "America\/Chicago"
  },
  "KCHU": {
      "icao": "KCHU",
      "iata": "",
      "name": "Houston County Airport",
      "city": "Caledonia",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1179,
      "lat": 43.5964012146,
      "lon": -91.5038986206,
      "tz": "America\/Chicago"
  },
  "KCIC": {
      "icao": "KCIC",
      "iata": "CIC",
      "name": "Chico Municipal Airport",
      "city": "Chico",
      "state": "California",
      "country": "US",
      "elevation": 240,
      "lat": 39.79539871,
      "lon": -121.8580017,
      "tz": "America\/Los_Angeles"
  },
  "KCID": {
      "icao": "KCID",
      "iata": "CID",
      "name": "The Eastern Iowa Airport",
      "city": "Cedar Rapids",
      "state": "Iowa",
      "country": "US",
      "elevation": 869,
      "lat": 41.8847007751,
      "lon": -91.7108001709,
      "tz": "America\/Chicago"
  },
  "KCII": {
      "icao": "KCII",
      "iata": "",
      "name": "Choteau Airport",
      "city": "Choteau",
      "state": "Montana",
      "country": "US",
      "elevation": 3947,
      "lat": 47.8283004761,
      "lon": -112.1679992676,
      "tz": "America\/Denver"
  },
  "KCIN": {
      "icao": "KCIN",
      "iata": "CIN",
      "name": "Arthur N Neu Airport",
      "city": "Carroll",
      "state": "Iowa",
      "country": "US",
      "elevation": 1204,
      "lat": 42.0461997986,
      "lon": -94.7890014648,
      "tz": "America\/Chicago"
  },
  "KCIR": {
      "icao": "KCIR",
      "iata": "CIR",
      "name": "Cairo Regional Airport",
      "city": "Cairo",
      "state": "Illinois",
      "country": "US",
      "elevation": 321,
      "lat": 37.0644989014,
      "lon": -89.2195968628,
      "tz": "America\/Chicago"
  },
  "KCIU": {
      "icao": "KCIU",
      "iata": "CIU",
      "name": "Chippewa County International Airport",
      "city": "Sault Ste Marie",
      "state": "Michigan",
      "country": "US",
      "elevation": 800,
      "lat": 46.2508010864,
      "lon": -84.4723968506,
      "tz": "America\/Detroit"
  },
  "KCJJ": {
      "icao": "KCJJ",
      "iata": "",
      "name": "Ellen Church Field",
      "city": "Cresco",
      "state": "Iowa",
      "country": "US",
      "elevation": 1279,
      "lat": 43.3652992249,
      "lon": -92.133102417,
      "tz": "America\/Chicago"
  },
  "KCJR": {
      "icao": "KCJR",
      "iata": "",
      "name": "Culpeper Regional Airport",
      "city": "Culpeper",
      "state": "Virginia",
      "country": "US",
      "elevation": 316,
      "lat": 38.5266990662,
      "lon": -77.8589019775,
      "tz": "America\/New_York"
  },
  "KCKA": {
      "icao": "KCKA",
      "iata": "CKA",
      "name": "Kegelman Af Aux Field",
      "city": "Cherokee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1202,
      "lat": 36.7439002991,
      "lon": -98.1231002808,
      "tz": "America\/Chicago"
  },
  "KCKB": {
      "icao": "KCKB",
      "iata": "CKB",
      "name": "North Central West Virginia Airport",
      "city": "Clarksburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1217,
      "lat": 39.2966003418,
      "lon": -80.2281036377,
      "tz": "America\/New_York"
  },
  "KCKC": {
      "icao": "KCKC",
      "iata": "GRM",
      "name": "Grand Marais Cook County Airport",
      "city": "Grand Marais",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1799,
      "lat": 47.8382987976,
      "lon": -90.3828964233,
      "tz": "America\/Chicago"
  },
  "KCKF": {
      "icao": "KCKF",
      "iata": "",
      "name": "Crisp County Cordele Airport",
      "city": "Cordele",
      "state": "Georgia",
      "country": "US",
      "elevation": 310,
      "lat": 31.98880005,
      "lon": -83.77390289,
      "tz": "America\/New_York"
  },
  "KCKI": {
      "icao": "KCKI",
      "iata": "",
      "name": "Williamsburg Regional Airport",
      "city": "Kingstree",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 67,
      "lat": 33.7172012329,
      "lon": -79.8570022583,
      "tz": "America\/New_York"
  },
  "KCKM": {
      "icao": "KCKM",
      "iata": "CKM",
      "name": "Fletcher Field",
      "city": "Clarksdale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 173,
      "lat": 34.2997016907,
      "lon": -90.512298584,
      "tz": "America\/Chicago"
  },
  "KCKN": {
      "icao": "KCKN",
      "iata": "CKN",
      "name": "Crookston Municipal Kirkwood Field",
      "city": "Crookston",
      "state": "Minnesota",
      "country": "US",
      "elevation": 899,
      "lat": 47.8417015076,
      "lon": -96.62159729,
      "tz": "America\/Chicago"
  },
  "KCKP": {
      "icao": "KCKP",
      "iata": "",
      "name": "Cherokee County Regional Airport",
      "city": "Cherokee",
      "state": "Iowa",
      "country": "US",
      "elevation": 1227,
      "lat": 42.7317009,
      "lon": -95.55590057,
      "tz": "America\/Chicago"
  },
  "KCKV": {
      "icao": "KCKV",
      "iata": "CKV",
      "name": "Outlaw Field",
      "city": "Clarksville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 550,
      "lat": 36.6218986511,
      "lon": -87.4150009155,
      "tz": "America\/Chicago"
  },
  "KCKZ": {
      "icao": "KCKZ",
      "iata": "",
      "name": "Pennridge Airport",
      "city": "Perkasie",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 568,
      "lat": 40.3891983032,
      "lon": -75.2904968262,
      "tz": "America\/New_York"
  },
  "KCLE": {
      "icao": "KCLE",
      "iata": "CLE",
      "name": "Cleveland Hopkins International Airport",
      "city": "Cleveland",
      "state": "Ohio",
      "country": "US",
      "elevation": 791,
      "lat": 41.4117012024,
      "lon": -81.8498001099,
      "tz": "America\/New_York"
  },
  "KCLI": {
      "icao": "KCLI",
      "iata": "CLI",
      "name": "Clintonville Municipal Airport",
      "city": "Clintonville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 822,
      "lat": 44.6138000488,
      "lon": -88.731300354,
      "tz": "America\/Chicago"
  },
  "KCLK": {
      "icao": "KCLK",
      "iata": "CLK",
      "name": "Clinton Regional Airport",
      "city": "Clinton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1616,
      "lat": 35.53829956,
      "lon": -98.93270111,
      "tz": "America\/Chicago"
  },
  "KCLL": {
      "icao": "KCLL",
      "iata": "CLL",
      "name": "Easterwood Field",
      "city": "College Station",
      "state": "Texas",
      "country": "US",
      "elevation": 320,
      "lat": 30.58860016,
      "lon": -96.36380005,
      "tz": "America\/Chicago"
  },
  "KCLM": {
      "icao": "KCLM",
      "iata": "CLM",
      "name": "William R Fairchild International Airport",
      "city": "Port Angeles",
      "state": "Washington",
      "country": "US",
      "elevation": 291,
      "lat": 48.1202011108,
      "lon": -123.5,
      "tz": "America\/Los_Angeles"
  },
  "KCLR": {
      "icao": "KCLR",
      "iata": "CLR",
      "name": "Cliff Hatfield Memorial Airport",
      "city": "Calipatria",
      "state": "California",
      "country": "US",
      "elevation": -182,
      "lat": 33.1315002441,
      "lon": -115.5210037231,
      "tz": "America\/Los_Angeles"
  },
  "KCLS": {
      "icao": "KCLS",
      "iata": "CLS",
      "name": "Chehalis Centralia Airport",
      "city": "Chehalis",
      "state": "Washington",
      "country": "US",
      "elevation": 176,
      "lat": 46.6769981384,
      "lon": -122.983001709,
      "tz": "America\/Los_Angeles"
  },
  "KCLT": {
      "icao": "KCLT",
      "iata": "CLT",
      "name": "Charlotte Douglas International Airport",
      "city": "Charlotte",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 748,
      "lat": 35.2140007019,
      "lon": -80.9430999756,
      "tz": "America\/New_York"
  },
  "KCLW": {
      "icao": "KCLW",
      "iata": "CLW",
      "name": "Clearwater Air Park",
      "city": "Clearwater",
      "state": "Florida",
      "country": "US",
      "elevation": 71,
      "lat": 27.9766998291,
      "lon": -82.7586975098,
      "tz": "America\/New_York"
  },
  "KCMA": {
      "icao": "KCMA",
      "iata": "",
      "name": "Camarillo Airport",
      "city": "Camarillo",
      "state": "California",
      "country": "US",
      "elevation": 77,
      "lat": 34.2136993408,
      "lon": -119.09400177,
      "tz": "America\/Los_Angeles"
  },
  "KCMH": {
      "icao": "KCMH",
      "iata": "CMH",
      "name": "Port Columbus International Airport",
      "city": "Columbus",
      "state": "Ohio",
      "country": "US",
      "elevation": 815,
      "lat": 39.9980010986,
      "lon": -82.8918991089,
      "tz": "America\/New_York"
  },
  "KCMI": {
      "icao": "KCMI",
      "iata": "CMI",
      "name": "University of Illinois Willard Airport",
      "city": "Champaign\/Urbana",
      "state": "Illinois",
      "country": "US",
      "elevation": 755,
      "lat": 40.03919983,
      "lon": -88.27809906,
      "tz": "America\/Chicago"
  },
  "KCMR": {
      "icao": "KCMR",
      "iata": "",
      "name": "H.A. Clark Memorial Field",
      "city": "Williams",
      "state": "Arizona",
      "country": "US",
      "elevation": 6680,
      "lat": 35.30220032,
      "lon": -112.1940002,
      "tz": "America\/Phoenix"
  },
  "KCMX": {
      "icao": "KCMX",
      "iata": "CMX",
      "name": "Houghton County Memorial Airport",
      "city": "Hancock",
      "state": "Michigan",
      "country": "US",
      "elevation": 1095,
      "lat": 47.1683998108,
      "lon": -88.4890975952,
      "tz": "America\/Detroit"
  },
  "KCMY": {
      "icao": "KCMY",
      "iata": "CMY",
      "name": "Sparta Fort Mc Coy Airport",
      "city": "Sparta",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 837,
      "lat": 43.9583015442,
      "lon": -90.7378997803,
      "tz": "America\/Chicago"
  },
  "KCNB": {
      "icao": "KCNB",
      "iata": "",
      "name": "Myers Field",
      "city": "Canby",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1194,
      "lat": 44.72949982,
      "lon": -96.26599884,
      "tz": "America\/Chicago"
  },
  "KCNC": {
      "icao": "KCNC",
      "iata": "",
      "name": "Chariton Municipal Airport",
      "city": "Chariton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1050,
      "lat": 41.0195999146,
      "lon": -93.359703064,
      "tz": "America\/Chicago"
  },
  "KCNH": {
      "icao": "KCNH",
      "iata": "CNH",
      "name": "Claremont Municipal Airport",
      "city": "Claremont",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 545,
      "lat": 43.3703994751,
      "lon": -72.3686981201,
      "tz": "America\/New_York"
  },
  "KCNK": {
      "icao": "KCNK",
      "iata": "CNK",
      "name": "Blosser Municipal Airport",
      "city": "Concordia",
      "state": "Kansas",
      "country": "US",
      "elevation": 1486,
      "lat": 39.5493011475,
      "lon": -97.6522979736,
      "tz": "America\/Chicago"
  },
  "KCNM": {
      "icao": "KCNM",
      "iata": "CNM",
      "name": "Cavern City Air Terminal",
      "city": "Carlsbad",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3295,
      "lat": 32.3375015259,
      "lon": -104.2630004883,
      "tz": "America\/Denver"
  },
  "KCNO": {
      "icao": "KCNO",
      "iata": "CNO",
      "name": "Chino Airport",
      "city": "Chino",
      "state": "California",
      "country": "US",
      "elevation": 650,
      "lat": 33.97470093,
      "lon": -117.637001,
      "tz": "America\/Los_Angeles"
  },
  "KCNP": {
      "icao": "KCNP",
      "iata": "",
      "name": "Billy G Ray Field",
      "city": "Chappell",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3682,
      "lat": 41.0774993896,
      "lon": -102.4639968872,
      "tz": "America\/Denver"
  },
  "KCNU": {
      "icao": "KCNU",
      "iata": "CNU",
      "name": "Chanute Martin Johnson Airport",
      "city": "Chanute",
      "state": "Kansas",
      "country": "US",
      "elevation": 1002,
      "lat": 37.668800354,
      "lon": -95.4850997925,
      "tz": "America\/Chicago"
  },
  "KCNW": {
      "icao": "KCNW",
      "iata": "CNW",
      "name": "TSTC Waco Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 470,
      "lat": 31.6378002167,
      "lon": -97.0740966797,
      "tz": "America\/Chicago"
  },
  "KCNY": {
      "icao": "KCNY",
      "iata": "CNY",
      "name": "Canyonlands Field",
      "city": "Moab",
      "state": "Utah",
      "country": "US",
      "elevation": 4557,
      "lat": 38.75500107,
      "lon": -109.7549973,
      "tz": "America\/Denver"
  },
  "KCOD": {
      "icao": "KCOD",
      "iata": "COD",
      "name": "Yellowstone Regional Airport",
      "city": "Cody",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5102,
      "lat": 44.520198822,
      "lon": -109.0240020752,
      "tz": "America\/Denver"
  },
  "KCOE": {
      "icao": "KCOE",
      "iata": "COE",
      "name": "Coeur D'Alene - Pappy Boyington Field",
      "city": "Coeur d'Alene",
      "state": "Idaho",
      "country": "US",
      "elevation": 2320,
      "lat": 47.77429962,
      "lon": -116.8199997,
      "tz": "America\/Los_Angeles"
  },
  "KCOF": {
      "icao": "KCOF",
      "iata": "COF",
      "name": "Patrick Air Force Base",
      "city": "Cocoa Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 28.2348995209,
      "lon": -80.6100997925,
      "tz": "America\/New_York"
  },
  "KCOI": {
      "icao": "KCOI",
      "iata": "COI",
      "name": "Merritt Island Airport",
      "city": "Merritt Island",
      "state": "Florida",
      "country": "US",
      "elevation": 6,
      "lat": 28.3416004181,
      "lon": -80.6855010986,
      "tz": "America\/New_York"
  },
  "KCOM": {
      "icao": "KCOM",
      "iata": "COM",
      "name": "Coleman Municipal Airport",
      "city": "Coleman",
      "state": "Texas",
      "country": "US",
      "elevation": 1697,
      "lat": 31.8411006927,
      "lon": -99.4036026001,
      "tz": "America\/Chicago"
  },
  "KCON": {
      "icao": "KCON",
      "iata": "CON",
      "name": "Concord Municipal Airport",
      "city": "Concord",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 342,
      "lat": 43.20270157,
      "lon": -71.50229645,
      "tz": "America\/New_York"
  },
  "KCOQ": {
      "icao": "KCOQ",
      "iata": "",
      "name": "Cloquet Carlton County Airport",
      "city": "Cloquet",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1279,
      "lat": 46.7010993958,
      "lon": -92.5036010742,
      "tz": "America\/Chicago"
  },
  "KCOS": {
      "icao": "KCOS",
      "iata": "COS",
      "name": "City of Colorado Springs Municipal Airport",
      "city": "Colorado Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 6187,
      "lat": 38.8058013916,
      "lon": -104.7009963989,
      "tz": "America\/Denver"
  },
  "KCOT": {
      "icao": "KCOT",
      "iata": "COT",
      "name": "Cotulla-La Salle County",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 474,
      "lat": 28.45669937,
      "lon": -99.22029877,
      "tz": "America\/Chicago"
  },
  "KCOU": {
      "icao": "KCOU",
      "iata": "COU",
      "name": "Columbia Regional Airport",
      "city": "Columbia",
      "state": "Missouri",
      "country": "US",
      "elevation": 889,
      "lat": 38.8180999756,
      "lon": -92.2195968628,
      "tz": "America\/Chicago"
  },
  "KCPC": {
      "icao": "KCPC",
      "iata": "",
      "name": "Columbus County Municipal Airport",
      "city": "Whiteville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 99,
      "lat": 34.27289963,
      "lon": -78.71499634,
      "tz": "America\/New_York"
  },
  "KCPK": {
      "icao": "KCPK",
      "iata": "",
      "name": "Chesapeake Regional Airport",
      "city": "Norfolk",
      "state": "Virginia",
      "country": "US",
      "elevation": 19,
      "lat": 36.66559982,
      "lon": -76.3207016,
      "tz": "America\/New_York"
  },
  "KCPM": {
      "icao": "KCPM",
      "iata": "CPM",
      "name": "Compton Woodley Airport",
      "city": "Compton",
      "state": "California",
      "country": "US",
      "elevation": 97,
      "lat": 33.8899993896,
      "lon": -118.2440032959,
      "tz": "America\/Los_Angeles"
  },
  "KCPR": {
      "icao": "KCPR",
      "iata": "CPR",
      "name": "Casper-Natrona County International Airport",
      "city": "Casper",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5350,
      "lat": 42.90800095,
      "lon": -106.4639969,
      "tz": "America\/Denver"
  },
  "KCPS": {
      "icao": "KCPS",
      "iata": "CPS",
      "name": "St Louis Downtown Airport",
      "city": "Cahokia\/St Louis",
      "state": "Illinois",
      "country": "US",
      "elevation": 413,
      "lat": 38.5707015991,
      "lon": -90.1561965942,
      "tz": "America\/Chicago"
  },
  "KCPT": {
      "icao": "KCPT",
      "iata": "",
      "name": "Cleburne Municipal Airport",
      "city": "Cleburne",
      "state": "Texas",
      "country": "US",
      "elevation": 854,
      "lat": 32.3538017273,
      "lon": -97.4337005615,
      "tz": "America\/Chicago"
  },
  "KCPU": {
      "icao": "KCPU",
      "iata": "",
      "name": "Calaveras Co Maury Rasmussen Field",
      "city": "San Andreas",
      "state": "California",
      "country": "US",
      "elevation": 1325,
      "lat": 38.1460990906,
      "lon": -120.6480026245,
      "tz": "America\/Los_Angeles"
  },
  "KCQA": {
      "icao": "KCQA",
      "iata": "",
      "name": "Lakefield Airport",
      "city": "Celina",
      "state": "Ohio",
      "country": "US",
      "elevation": 894,
      "lat": 40.4841003418,
      "lon": -84.5600967407,
      "tz": "America\/New_York"
  },
  "KCQB": {
      "icao": "KCQB",
      "iata": "",
      "name": "Chandler Regional Airport",
      "city": "Chandler",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 984,
      "lat": 35.7238006592,
      "lon": -96.8202972412,
      "tz": "America\/Chicago"
  },
  "KCQM": {
      "icao": "KCQM",
      "iata": "",
      "name": "Cook Municipal Airport",
      "city": "Cook",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1327,
      "lat": 47.8218994141,
      "lon": -92.6893997192,
      "tz": "America\/Chicago"
  },
  "KCQW": {
      "icao": "KCQW",
      "iata": "HCW",
      "name": "Cheraw Municipal Lynch Bellinger Field",
      "city": "Cheraw",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 239,
      "lat": 34.71289825,
      "lon": -79.95700073,
      "tz": "America\/New_York"
  },
  "KCQX": {
      "icao": "KCQX",
      "iata": "",
      "name": "Chatham Municipal Airport",
      "city": "Chatham",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 68,
      "lat": 41.6884002686,
      "lon": -69.9895019531,
      "tz": "America\/New_York"
  },
  "KCRE": {
      "icao": "KCRE",
      "iata": "CRE",
      "name": "Grand Strand Airport",
      "city": "North Myrtle Beach",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 32,
      "lat": 33.8116989136,
      "lon": -78.7238998413,
      "tz": "America\/New_York"
  },
  "KCRG": {
      "icao": "KCRG",
      "iata": "CRG",
      "name": "Craig Municipal Airport",
      "city": "Jacksonville",
      "state": "Florida",
      "country": "US",
      "elevation": 41,
      "lat": 30.3362998962,
      "lon": -81.5143966675,
      "tz": "America\/New_York"
  },
  "KCRO": {
      "icao": "KCRO",
      "iata": "CRO",
      "name": "Corcoran Airport",
      "city": "Corcoran",
      "state": "California",
      "country": "US",
      "elevation": 197,
      "lat": 36.1025009155,
      "lon": -119.595001221,
      "tz": "America\/Los_Angeles"
  },
  "KCRP": {
      "icao": "KCRP",
      "iata": "CRP",
      "name": "Corpus Christi International Airport",
      "city": "Corpus Christi",
      "state": "Texas",
      "country": "US",
      "elevation": 44,
      "lat": 27.7703990936,
      "lon": -97.5011978149,
      "tz": "America\/Chicago"
  },
  "KCRQ": {
      "icao": "KCRQ",
      "iata": "CLD",
      "name": "Mc Clellan-Palomar Airport",
      "city": "Carlsbad",
      "state": "California",
      "country": "US",
      "elevation": 331,
      "lat": 33.12829971,
      "lon": -117.2799988,
      "tz": "America\/Los_Angeles"
  },
  "KCRS": {
      "icao": "KCRS",
      "iata": "CRS",
      "name": "C David Campbell Field Corsicana Municipal Airport",
      "city": "Corsicana",
      "state": "Texas",
      "country": "US",
      "elevation": 449,
      "lat": 32.0280990601,
      "lon": -96.4005966187,
      "tz": "America\/Chicago"
  },
  "KCRT": {
      "icao": "KCRT",
      "iata": "CRT",
      "name": "Z M Jack Stell Field",
      "city": "Crossett",
      "state": "Arkansas",
      "country": "US",
      "elevation": 184,
      "lat": 33.1782989502,
      "lon": -91.8802032471,
      "tz": "America\/Chicago"
  },
  "KCRW": {
      "icao": "KCRW",
      "iata": "CRW",
      "name": "Yeager Airport",
      "city": "Charleston",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 981,
      "lat": 38.3731002808,
      "lon": -81.5932006836,
      "tz": "America\/New_York"
  },
  "KCRX": {
      "icao": "KCRX",
      "iata": "CRX",
      "name": "Roscoe Turner Airport",
      "city": "Corinth",
      "state": "Mississippi",
      "country": "US",
      "elevation": 425,
      "lat": 34.9150009155,
      "lon": -88.6035003662,
      "tz": "America\/Chicago"
  },
  "KCRZ": {
      "icao": "KCRZ",
      "iata": "",
      "name": "Corning Municipal Airport",
      "city": "Corning",
      "state": "Iowa",
      "country": "US",
      "elevation": 1274,
      "lat": 40.9940986633,
      "lon": -94.7549972534,
      "tz": "America\/Chicago"
  },
  "KCSB": {
      "icao": "KCSB",
      "iata": "",
      "name": "Cambridge Municipal Airport",
      "city": "Cambridge",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2414,
      "lat": 40.3065986633,
      "lon": -100.1620025635,
      "tz": "America\/Chicago"
  },
  "KCSG": {
      "icao": "KCSG",
      "iata": "CSG",
      "name": "Columbus Metropolitan Airport",
      "city": "Columbus",
      "state": "Georgia",
      "country": "US",
      "elevation": 397,
      "lat": 32.5163002014,
      "lon": -84.9389038086,
      "tz": "America\/New_York"
  },
  "KCSM": {
      "icao": "KCSM",
      "iata": "CSM",
      "name": "Clinton Sherman Airport",
      "city": "Clinton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1922,
      "lat": 35.3398017883,
      "lon": -99.2005004883,
      "tz": "America\/Chicago"
  },
  "KCSQ": {
      "icao": "KCSQ",
      "iata": "CSQ",
      "name": "Creston Municipal Airport",
      "city": "Creston",
      "state": "Iowa",
      "country": "US",
      "elevation": 1300,
      "lat": 41.0214004517,
      "lon": -94.3632965088,
      "tz": "America\/Chicago"
  },
  "KCSV": {
      "icao": "KCSV",
      "iata": "CSV",
      "name": "Crossville Memorial Whitson Field",
      "city": "Crossville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1881,
      "lat": 35.9513015747,
      "lon": -85.0849990845,
      "tz": "America\/Chicago"
  },
  "KCTB": {
      "icao": "KCTB",
      "iata": "CTB",
      "name": "Cut Bank Municipal Airport",
      "city": "Cut Bank",
      "state": "Montana",
      "country": "US",
      "elevation": 3854,
      "lat": 48.6083984375,
      "lon": -112.3759994507,
      "tz": "America\/Denver"
  },
  "KCTJ": {
      "icao": "KCTJ",
      "iata": "",
      "name": "West Georgia Regional O V Gray Field",
      "city": "Carrollton",
      "state": "Georgia",
      "country": "US",
      "elevation": 1161,
      "lat": 33.6310005188,
      "lon": -85.1520004272,
      "tz": "America\/New_York"
  },
  "KCTK": {
      "icao": "KCTK",
      "iata": "",
      "name": "Ingersoll Airport",
      "city": "Canton",
      "state": "Illinois",
      "country": "US",
      "elevation": 684,
      "lat": 40.5690994263,
      "lon": -90.074798584,
      "tz": "America\/Chicago"
  },
  "KCTY": {
      "icao": "KCTY",
      "iata": "CTY",
      "name": "Cross City Airport",
      "city": "Cross City",
      "state": "Florida",
      "country": "US",
      "elevation": 42,
      "lat": 29.6354999542,
      "lon": -83.1047973633,
      "tz": "America\/New_York"
  },
  "KCTZ": {
      "icao": "KCTZ",
      "iata": "CTZ",
      "name": "Sampson County Airport",
      "city": "Clinton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 148,
      "lat": 34.9756011963,
      "lon": -78.3646011353,
      "tz": "America\/New_York"
  },
  "KCUB": {
      "icao": "KCUB",
      "iata": "CUB",
      "name": "Jim Hamilton L.B. Owens Airport",
      "city": "Columbia",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 193,
      "lat": 33.970500946,
      "lon": -80.9952011108,
      "tz": "America\/New_York"
  },
  "KCUH": {
      "icao": "KCUH",
      "iata": "CUH",
      "name": "Cushing Municipal Airport",
      "city": "Cushing",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 916,
      "lat": 35.9499015808,
      "lon": -96.7731018066,
      "tz": "America\/Chicago"
  },
  "KCUL": {
      "icao": "KCUL",
      "iata": "",
      "name": "Carmi Municipal Airport",
      "city": "Carmi",
      "state": "Illinois",
      "country": "US",
      "elevation": 388,
      "lat": 38.0895004272,
      "lon": -88.1231002808,
      "tz": "America\/Chicago"
  },
  "KCUT": {
      "icao": "KCUT",
      "iata": "",
      "name": "Custer County Airport",
      "city": "Custer",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 5602,
      "lat": 43.7332992554,
      "lon": -103.6179962158,
      "tz": "America\/Denver"
  },
  "KCVC": {
      "icao": "KCVC",
      "iata": "",
      "name": "Covington Municipal Airport",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 820,
      "lat": 33.6322475,
      "lon": -83.8466189,
      "tz": "America\/New_York"
  },
  "KCVB": {
      "icao": "KCVB",
      "iata": "",
      "name": "Castroville Municipal Airport",
      "city": "Castroville",
      "state": "Texas",
      "country": "US",
      "elevation": 771,
      "lat": 29.3418998718,
      "lon": -98.8508987427,
      "tz": "America\/Chicago"
  },
  "KCVG": {
      "icao": "KCVG",
      "iata": "CVG",
      "name": "Cincinnati Northern Kentucky International Airport",
      "city": "Hebron",
      "state": "Kentucky",
      "country": "US",
      "elevation": 896,
      "lat": 39.0488014221,
      "lon": -84.6678009033,
      "tz": "America\/New_York"
  },
  "KCVH": {
      "icao": "KCVH",
      "iata": "HLI",
      "name": "Hollister Municipal Airport",
      "city": "Hollister",
      "state": "California",
      "country": "US",
      "elevation": 230,
      "lat": 36.8932991028,
      "lon": -121.410003662,
      "tz": "America\/Los_Angeles"
  },
  "KCVK": {
      "icao": "KCVK",
      "iata": "CKK",
      "name": "Sharp County Regional Airport",
      "city": "Ash Flat",
      "state": "Arkansas",
      "country": "US",
      "elevation": 716,
      "lat": 36.26490021,
      "lon": -91.56259918,
      "tz": "America\/Chicago"
  },
  "KCVN": {
      "icao": "KCVN",
      "iata": "CVN",
      "name": "Clovis Municipal Airport",
      "city": "Clovis",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4216,
      "lat": 34.4250984192,
      "lon": -103.07900238,
      "tz": "America\/Denver"
  },
  "KCVO": {
      "icao": "KCVO",
      "iata": "CVO",
      "name": "Corvallis Municipal Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 250,
      "lat": 44.49720001,
      "lon": -123.2900009,
      "tz": "America\/Los_Angeles"
  },
  "KCVS": {
      "icao": "KCVS",
      "iata": "CVS",
      "name": "Cannon Air Force Base",
      "city": "Clovis",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4295,
      "lat": 34.3828010559,
      "lon": -103.3219985962,
      "tz": "America\/Denver"
  },
  "KCVX": {
      "icao": "KCVX",
      "iata": "",
      "name": "Charlevoix Municipal Airport",
      "city": "Charlevoix",
      "state": "Michigan",
      "country": "US",
      "elevation": 669,
      "lat": 45.3047981262,
      "lon": -85.2748031616,
      "tz": "America\/Detroit"
  },
  "KCWA": {
      "icao": "KCWA",
      "iata": "CWA",
      "name": "Central Wisconsin Airport",
      "city": "Mosinee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1277,
      "lat": 44.7775993347,
      "lon": -89.6668014526,
      "tz": "America\/Chicago"
  },
  "KCWC": {
      "icao": "KCWC",
      "iata": "KIP",
      "name": "Kickapoo Downtown Airport",
      "city": "Wichita Falls",
      "state": "Texas",
      "country": "US",
      "elevation": 1003,
      "lat": 33.85779953,
      "lon": -98.49040222,
      "tz": "America\/Chicago"
  },
  "KCWF": {
      "icao": "KCWF",
      "iata": "CWF",
      "name": "Chennault International Airport",
      "city": "Lake Charles",
      "state": "Louisiana",
      "country": "US",
      "elevation": 17,
      "lat": 30.2105998993,
      "lon": -93.1432037354,
      "tz": "America\/Chicago"
  },
  "KCWI": {
      "icao": "KCWI",
      "iata": "CWI",
      "name": "Clinton Municipal Airport",
      "city": "Clinton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 708,
      "lat": 41.8311004639,
      "lon": -90.3291015625,
      "tz": "America\/Chicago"
  },
  "KCWS": {
      "icao": "KCWS",
      "iata": "",
      "name": "Dennis F Cantrell Field",
      "city": "Conway",
      "state": "Arkansas",
      "country": "US",
      "elevation": 316,
      "lat": 35.0807991,
      "lon": -92.42500305,
      "tz": "America\/Chicago"
  },
  "KCWV": {
      "icao": "KCWV",
      "iata": "",
      "name": "Claxton Evans County Airport",
      "city": "Claxton",
      "state": "Georgia",
      "country": "US",
      "elevation": 112,
      "lat": 32.195098877,
      "lon": -81.8695983887,
      "tz": "America\/New_York"
  },
  "KCXE": {
      "icao": "KCXE",
      "iata": "",
      "name": "Chase City Municipal Airport",
      "city": "Chase City",
      "state": "Virginia",
      "country": "US",
      "elevation": 503,
      "lat": 36.7882995605,
      "lon": -78.5016021729,
      "tz": "America\/New_York"
  },
  "KCXL": {
      "icao": "KCXL",
      "iata": "CXL",
      "name": "Calexico International Airport",
      "city": "Calexico",
      "state": "California",
      "country": "US",
      "elevation": 4,
      "lat": 32.6694984436,
      "lon": -115.5130004883,
      "tz": "America\/Los_Angeles"
  },
  "KCXO": {
      "icao": "KCXO",
      "iata": "CXO",
      "name": "Lone Star Executive Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 245,
      "lat": 30.3518009186,
      "lon": -95.4144973755,
      "tz": "America\/Chicago"
  },
  "KCXP": {
      "icao": "KCXP",
      "iata": "CSN",
      "name": "Carson Airport",
      "city": "Carson City",
      "state": "Nevada",
      "country": "US",
      "elevation": 4697,
      "lat": 39.192199707,
      "lon": -119.7340011597,
      "tz": "America\/Los_Angeles"
  },
  "KCXU": {
      "icao": "KCXU",
      "iata": "",
      "name": "Camilla Mitchell County Airport",
      "city": "Camilla",
      "state": "Georgia",
      "country": "US",
      "elevation": 175,
      "lat": 31.2129001617,
      "lon": -84.2368011475,
      "tz": "America\/New_York"
  },
  "KCXY": {
      "icao": "KCXY",
      "iata": "HAR",
      "name": "Capital City Airport",
      "city": "Harrisburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 347,
      "lat": 40.2170982361,
      "lon": -76.8515014648,
      "tz": "America\/New_York"
  },
  "KCYO": {
      "icao": "KCYO",
      "iata": "",
      "name": "Pickaway County Memorial Airport",
      "city": "Circleville",
      "state": "Ohio",
      "country": "US",
      "elevation": 684,
      "lat": 39.51599884,
      "lon": -82.98210144,
      "tz": "America\/New_York"
  },
  "KCYS": {
      "icao": "KCYS",
      "iata": "CYS",
      "name": "Cheyenne Regional Jerry Olson Field",
      "city": "Cheyenne",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6159,
      "lat": 41.15570068,
      "lon": -104.8119965,
      "tz": "America\/Denver"
  },
  "KCYW": {
      "icao": "KCYW",
      "iata": "",
      "name": "Clay Center Municipal Airport",
      "city": "Clay Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1208,
      "lat": 39.3871002197,
      "lon": -97.1572036743,
      "tz": "America\/Chicago"
  },
  "KCZD": {
      "icao": "KCZD",
      "iata": "",
      "name": "Cozad Municipal Airport",
      "city": "Cozad",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2503,
      "lat": 40.86909866,
      "lon": -100.0039978,
      "tz": "America\/Chicago"
  },
  "KCZG": {
      "icao": "KCZG",
      "iata": "",
      "name": "Tri Cities Airport",
      "city": "Endicott",
      "state": "Alabama",
      "country": "US",
      "elevation": 833,
      "lat": 42.0784988403,
      "lon": -76.0962982178,
      "tz": "America\/New_York"
  },
  "KCZL": {
      "icao": "KCZL",
      "iata": "",
      "name": "Tom B. David Field",
      "city": "Calhoun",
      "state": "Georgia",
      "country": "US",
      "elevation": 647,
      "lat": 34.45539856,
      "lon": -84.93920135,
      "tz": "America\/New_York"
  },
  "KCZT": {
      "icao": "KCZT",
      "iata": "CZT",
      "name": "Dimmit County Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 599,
      "lat": 28.5221996307,
      "lon": -99.823600769,
      "tz": "America\/Chicago"
  },
  "KD00": {
      "icao": "KD00",
      "iata": "",
      "name": "Norman County Ada Twin Valley Airport",
      "city": "Ada\/Twin Valley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 954,
      "lat": 47.26050186,
      "lon": -96.40029907,
      "tz": "America\/Chicago"
  },
  "KD02": {
      "icao": "KD02",
      "iata": "",
      "name": "Osage Municipal Airport",
      "city": "Osage",
      "state": "Iowa",
      "country": "US",
      "elevation": 1168,
      "lat": 43.2924995422,
      "lon": -92.7959976196,
      "tz": "America\/Chicago"
  },
  "KD05": {
      "icao": "KD05",
      "iata": "",
      "name": "Garrison Municipal Airport",
      "city": "Garrison",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1935,
      "lat": 47.6558990479,
      "lon": -101.43699646,
      "tz": "America\/Chicago"
  },
  "KD07": {
      "icao": "KD07",
      "iata": "",
      "name": "Faith Municipal Airport",
      "city": "Faith",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2582,
      "lat": 45.0360984802,
      "lon": -102.0199966431,
      "tz": "America\/Denver"
  },
  "KD09": {
      "icao": "KD09",
      "iata": "",
      "name": "Bottineau Municipal Airport",
      "city": "Bottineau",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1681,
      "lat": 48.83039856,
      "lon": -100.4169998,
      "tz": "America\/Chicago"
  },
  "KD11": {
      "icao": "KD11",
      "iata": "",
      "name": "Ojibwa Airpark",
      "city": "Weidman",
      "state": "Michigan",
      "country": "US",
      "elevation": 950,
      "lat": 43.7200012207,
      "lon": -85.0027999878,
      "tz": "America\/Detroit"
  },
  "KD14": {
      "icao": "KD14",
      "iata": "",
      "name": "Fertile Municipal Airport",
      "city": "Fertile",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1135,
      "lat": 47.5518989563,
      "lon": -96.2919006348,
      "tz": "America\/Chicago"
  },
  "KD17": {
      "icao": "KD17",
      "iata": "",
      "name": "Westwinds Airport",
      "city": "Delta",
      "state": "Colorado",
      "country": "US",
      "elevation": 5000,
      "lat": 38.7574996948,
      "lon": -108.1480026245,
      "tz": "America\/Denver"
  },
  "KD23": {
      "icao": "KD23",
      "iata": "",
      "name": "Arcade Tri County Airport",
      "city": "Arcade",
      "state": "New-York",
      "country": "US",
      "elevation": 1745,
      "lat": 42.5666999817,
      "lon": -78.4261016846,
      "tz": "America\/New_York"
  },
  "KD25": {
      "icao": "KD25",
      "iata": "",
      "name": "Manitowish Waters Airport",
      "city": "Manitowish Waters",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1610,
      "lat": 46.1220016479,
      "lon": -89.8823013306,
      "tz": "America\/Chicago"
  },
  "KD31": {
      "icao": "KD31",
      "iata": "",
      "name": "Leeds Municipal Airport",
      "city": "Leeds",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1508,
      "lat": 48.2849998474,
      "lon": -99.403503418,
      "tz": "America\/Chicago"
  },
  "KD37": {
      "icao": "KD37",
      "iata": "",
      "name": "Warren Municipal Airport",
      "city": "Warren",
      "state": "Minnesota",
      "country": "US",
      "elevation": 886,
      "lat": 48.1911010742,
      "lon": -96.7110977173,
      "tz": "America\/Chicago"
  },
  "KD39": {
      "icao": "KD39",
      "iata": "",
      "name": "Sauk Centre Municipal Airport",
      "city": "Sauk Centre",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1244,
      "lat": 45.7066001892,
      "lon": -94.9334030151,
      "tz": "America\/Chicago"
  },
  "KD42": {
      "icao": "KD42",
      "iata": "",
      "name": "Springfield Municipal Airport",
      "city": "Springfield",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1072,
      "lat": 44.231098175,
      "lon": -94.9989013672,
      "tz": "America\/Chicago"
  },
  "KD50": {
      "icao": "KD50",
      "iata": "",
      "name": "Crosby Municipal Airport",
      "city": "Crosby",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1950,
      "lat": 48.9285011292,
      "lon": -103.2969970703,
      "tz": "America\/Chicago"
  },
  "KD54": {
      "icao": "KD54",
      "iata": "",
      "name": "West Fargo Municipal Airport",
      "city": "Fargo",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 896,
      "lat": 46.9007987976,
      "lon": -96.9187011719,
      "tz": "America\/Chicago"
  },
  "KD55": {
      "icao": "KD55",
      "iata": "",
      "name": "Robertson Field",
      "city": "Langdon",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1608,
      "lat": 48.7529983521,
      "lon": -98.3933029175,
      "tz": "America\/Chicago"
  },
  "KD56": {
      "icao": "KD56",
      "iata": "",
      "name": "Mayville Municipal Airport",
      "city": "Mayville",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 975,
      "lat": 47.4749984741,
      "lon": -97.3337020874,
      "tz": "America\/Chicago"
  },
  "KD57": {
      "icao": "KD57",
      "iata": "",
      "name": "Glen Ullin Regional Airport",
      "city": "Glen Ullin",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2089,
      "lat": 46.8128013611,
      "lon": -101.8600006104,
      "tz": "America\/North_Dakota\/New_Salem"
  },
  "KD60": {
      "icao": "KD60",
      "iata": "",
      "name": "Tioga Municipal Airport",
      "city": "Tioga",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2271,
      "lat": 48.38050079,
      "lon": -102.8980026,
      "tz": "America\/Chicago"
  },
  "KD64": {
      "icao": "KD64",
      "iata": "",
      "name": "Westhope Municipal Airport",
      "city": "Westhope",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1494,
      "lat": 48.9133987427,
      "lon": -101.0329971313,
      "tz": "America\/Chicago"
  },
  "KD68": {
      "icao": "KD68",
      "iata": "",
      "name": "Springerville Municipal Airport",
      "city": "Springerville",
      "state": "Arizona",
      "country": "US",
      "elevation": 7055,
      "lat": 34.13539886,
      "lon": -109.3099976,
      "tz": "America\/Phoenix"
  },
  "KD74": {
      "icao": "KD74",
      "iata": "",
      "name": "Chorman Airport",
      "city": "Farmington",
      "state": "Delaware",
      "country": "US",
      "elevation": 66,
      "lat": 38.848400116,
      "lon": -75.6123962402,
      "tz": "America\/New_York"
  },
  "KD83": {
      "icao": "KD83",
      "iata": "",
      "name": "Boonville Airport",
      "city": "Boonville",
      "state": "California",
      "country": "US",
      "elevation": 371,
      "lat": 39.0126991272,
      "lon": -123.3830032349,
      "tz": "America\/Los_Angeles"
  },
  "KD86": {
      "icao": "KD86",
      "iata": "",
      "name": "Sequoia Field",
      "city": "Visalia",
      "state": "California",
      "country": "US",
      "elevation": 313,
      "lat": 36.448600769,
      "lon": -119.3190002441,
      "tz": "America\/Los_Angeles"
  },
  "KD95": {
      "icao": "KD95",
      "iata": "",
      "name": "Dupont Lapeer Airport",
      "city": "Lapeer",
      "state": "Michigan",
      "country": "US",
      "elevation": 834,
      "lat": 43.0666007996,
      "lon": -83.2723007202,
      "tz": "America\/Detroit"
  },
  "KD98": {
      "icao": "KD98",
      "iata": "",
      "name": "Romeo State Airport",
      "city": "Romeo",
      "state": "Michigan",
      "country": "US",
      "elevation": 739,
      "lat": 42.79610062,
      "lon": -82.97499847,
      "tz": "America\/Detroit"
  },
  "KDAA": {
      "icao": "KDAA",
      "iata": "DAA",
      "name": "Davison Army Air Field",
      "city": "Fort Belvoir",
      "state": "Virginia",
      "country": "US",
      "elevation": 73,
      "lat": 38.7150001526,
      "lon": -77.1809997559,
      "tz": "America\/New_York"
  },
  "KDAB": {
      "icao": "KDAB",
      "iata": "DAB",
      "name": "Daytona Beach International Airport",
      "city": "Daytona Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 34,
      "lat": 29.1798992157,
      "lon": -81.0580978394,
      "tz": "America\/New_York"
  },
  "KDAF": {
      "icao": "KDAF",
      "iata": "",
      "name": "Necedah Airport",
      "city": "Necedah",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 919,
      "lat": 44.0334014893,
      "lon": -90.0850982666,
      "tz": "America\/Chicago"
  },
  "KDAG": {
      "icao": "KDAG",
      "iata": "DAG",
      "name": "Barstow Daggett Airport",
      "city": "Daggett",
      "state": "California",
      "country": "US",
      "elevation": 1930,
      "lat": 34.85369873,
      "lon": -116.7870026,
      "tz": "America\/Los_Angeles"
  },
  "KDAL": {
      "icao": "KDAL",
      "iata": "DAL",
      "name": "Dallas Love Field",
      "city": "Dallas",
      "state": "Texas",
      "country": "US",
      "elevation": 487,
      "lat": 32.8470993042,
      "lon": -96.8517990112,
      "tz": "America\/Chicago"
  },
  "KDAN": {
      "icao": "KDAN",
      "iata": "DAN",
      "name": "Danville Regional Airport",
      "city": "Danville",
      "state": "Virginia",
      "country": "US",
      "elevation": 571,
      "lat": 36.5728988647,
      "lon": -79.3360977173,
      "tz": "America\/New_York"
  },
  "KDAW": {
      "icao": "KDAW",
      "iata": "",
      "name": "Skyhaven Airport",
      "city": "Rochester",
      "state": "Missouri",
      "country": "US",
      "elevation": 322,
      "lat": 43.2840995789,
      "lon": -70.9292984009,
      "tz": "America\/New_York"
  },
  "KDAY": {
      "icao": "KDAY",
      "iata": "DAY",
      "name": "James M Cox Dayton International Airport",
      "city": "Dayton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1009,
      "lat": 39.9024009705,
      "lon": -84.2193984985,
      "tz": "America\/New_York"
  },
  "KDBN": {
      "icao": "KDBN",
      "iata": "DBN",
      "name": "W H 'Bud' Barron Airport",
      "city": "Dublin",
      "state": "Georgia",
      "country": "US",
      "elevation": 309,
      "lat": 32.56439972,
      "lon": -82.98529816,
      "tz": "America\/New_York"
  },
  "KDBQ": {
      "icao": "KDBQ",
      "iata": "DBQ",
      "name": "Dubuque Regional Airport",
      "city": "Dubuque",
      "state": "Iowa",
      "country": "US",
      "elevation": 1077,
      "lat": 42.40200043,
      "lon": -90.70950317,
      "tz": "America\/Chicago"
  },
  "KDCA": {
      "icao": "KDCA",
      "iata": "DCA",
      "name": "Ronald Reagan Washington National Airport",
      "city": "Washington",
      "state": "Virginia",
      "country": "US",
      "elevation": 15,
      "lat": 38.8521003723,
      "lon": -77.0376968384,
      "tz": "America\/New_York"
  },
  "KDCM": {
      "icao": "KDCM",
      "iata": "",
      "name": "Chester Catawba Regional Airport",
      "city": "Chester",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 656,
      "lat": 34.7892990112,
      "lon": -81.1958007813,
      "tz": "America\/New_York"
  },
  "KDCU": {
      "icao": "KDCU",
      "iata": "DCU",
      "name": "Pryor Field Regional Airport",
      "city": "Decatur",
      "state": "Alabama",
      "country": "US",
      "elevation": 592,
      "lat": 34.6526985168,
      "lon": -86.9453964233,
      "tz": "America\/Chicago"
  },
  "KDCY": {
      "icao": "KDCY",
      "iata": "",
      "name": "Daviess County Airport",
      "city": "Washington",
      "state": "Indiana",
      "country": "US",
      "elevation": 473,
      "lat": 38.7004013062,
      "lon": -87.129699707,
      "tz": "America\/Indiana\/Vincennes"
  },
  "KDDC": {
      "icao": "KDDC",
      "iata": "DDC",
      "name": "Dodge City Regional Airport",
      "city": "Dodge City",
      "state": "Kansas",
      "country": "US",
      "elevation": 2594,
      "lat": 37.7634010315,
      "lon": -99.9655990601,
      "tz": "America\/Chicago"
  },
  "KDDH": {
      "icao": "KDDH",
      "iata": "",
      "name": "William H. Morse State Airport",
      "city": "Bennington",
      "state": "Vermont",
      "country": "US",
      "elevation": 827,
      "lat": 42.8913002,
      "lon": -73.24639893,
      "tz": "America\/New_York"
  },
  "KDEC": {
      "icao": "KDEC",
      "iata": "DEC",
      "name": "Decatur Airport",
      "city": "Decatur",
      "state": "Illinois",
      "country": "US",
      "elevation": 682,
      "lat": 39.8345985413,
      "lon": -88.8656997681,
      "tz": "America\/Chicago"
  },
  "KDED": {
      "icao": "KDED",
      "iata": "",
      "name": "Deland Municipal Sidney H Taylor Field",
      "city": "Deland",
      "state": "Florida",
      "country": "US",
      "elevation": 79,
      "lat": 29.06699944,
      "lon": -81.28379822,
      "tz": "America\/New_York"
  },
  "KDEH": {
      "icao": "KDEH",
      "iata": "DEH",
      "name": "Decorah Municipal Airport",
      "city": "Decorah",
      "state": "Iowa",
      "country": "US",
      "elevation": 1158,
      "lat": 43.27550125,
      "lon": -91.73940277,
      "tz": "America\/Chicago"
  },
  "KDEN": {
      "icao": "KDEN",
      "iata": "DEN",
      "name": "Denver International Airport",
      "city": "Denver",
      "state": "Colorado",
      "country": "US",
      "elevation": 5431,
      "lat": 39.8616981506,
      "lon": -104.672996521,
      "tz": "America\/Denver"
  },
  "KDEQ": {
      "icao": "KDEQ",
      "iata": "",
      "name": "J Lynn Helms Sevier County Airport",
      "city": "De Queen",
      "state": "Arkansas",
      "country": "US",
      "elevation": 355,
      "lat": 34.047000885,
      "lon": -94.3993988037,
      "tz": "America\/Chicago"
  },
  "KDET": {
      "icao": "KDET",
      "iata": "DET",
      "name": "Coleman A. Young Municipal Airport",
      "city": "Detroit",
      "state": "Michigan",
      "country": "US",
      "elevation": 626,
      "lat": 42.40919876,
      "lon": -83.00990295,
      "tz": "America\/Detroit"
  },
  "KDEW": {
      "icao": "KDEW",
      "iata": "",
      "name": "Deer Park Airport",
      "city": "Deer Park",
      "state": "Washington",
      "country": "US",
      "elevation": 2211,
      "lat": 47.96659851,
      "lon": -117.427002,
      "tz": "America\/Los_Angeles"
  },
  "KDFI": {
      "icao": "KDFI",
      "iata": "DFI",
      "name": "Defiance Memorial Airport",
      "city": "Defiance",
      "state": "Ohio",
      "country": "US",
      "elevation": 707,
      "lat": 41.3375015259,
      "lon": -84.4288024902,
      "tz": "America\/New_York"
  },
  "KDFW": {
      "icao": "KDFW",
      "iata": "DFW",
      "name": "Dallas Fort Worth International Airport",
      "city": "Dallas-Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 607,
      "lat": 32.8968009949,
      "lon": -97.0380020142,
      "tz": "America\/Chicago"
  },
  "KDGL": {
      "icao": "KDGL",
      "iata": "DGL",
      "name": "Douglas Municipal Airport",
      "city": "Douglas",
      "state": "Arizona",
      "country": "US",
      "elevation": 4173,
      "lat": 31.3425998688,
      "lon": -109.505996704,
      "tz": "America\/Hermosillo"
  },
  "KDGW": {
      "icao": "KDGW",
      "iata": "DGW",
      "name": "Converse County Airport",
      "city": "Douglas",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4933,
      "lat": 42.79719925,
      "lon": -105.3860016,
      "tz": "America\/Denver"
  },
  "KDHN": {
      "icao": "KDHN",
      "iata": "DHN",
      "name": "Dothan Regional Airport",
      "city": "Dothan",
      "state": "Alabama",
      "country": "US",
      "elevation": 401,
      "lat": 31.3213005066,
      "lon": -85.4496002197,
      "tz": "America\/Chicago"
  },
  "KDHT": {
      "icao": "KDHT",
      "iata": "DHT",
      "name": "Dalhart Municipal Airport",
      "city": "Dalhart",
      "state": "Texas",
      "country": "US",
      "elevation": 3991,
      "lat": 36.0225982666,
      "lon": -102.54699707,
      "tz": "America\/Chicago"
  },
  "KDIJ": {
      "icao": "KDIJ",
      "iata": "",
      "name": "Driggs Reed Memorial Airport",
      "city": "Driggs",
      "state": "Idaho",
      "country": "US",
      "elevation": 6229,
      "lat": 43.742401123,
      "lon": -111.0979995728,
      "tz": "America\/Boise"
  },
  "KDIK": {
      "icao": "KDIK",
      "iata": "DIK",
      "name": "Dickinson Theodore Roosevelt Regional Airport",
      "city": "Dickinson",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2592,
      "lat": 46.7974014282,
      "lon": -102.802001953,
      "tz": "America\/Denver"
  },
  "KDKB": {
      "icao": "KDKB",
      "iata": "",
      "name": "De Kalb Taylor Municipal Airport",
      "city": "De Kalb",
      "state": "Illinois",
      "country": "US",
      "elevation": 914,
      "lat": 41.93370056,
      "lon": -88.70570374,
      "tz": "America\/Chicago"
  },
  "KDKK": {
      "icao": "KDKK",
      "iata": "DKK",
      "name": "Chautauqua County-Dunkirk Airport",
      "city": "Dunkirk",
      "state": "New-York",
      "country": "US",
      "elevation": 693,
      "lat": 42.49330139,
      "lon": -79.27200317,
      "tz": "America\/New_York"
  },
  "KDKR": {
      "icao": "KDKR",
      "iata": "",
      "name": "Houston County Airport",
      "city": "Crockett",
      "state": "Texas",
      "country": "US",
      "elevation": 348,
      "lat": 31.3069992065,
      "lon": -95.4038009644,
      "tz": "America\/Chicago"
  },
  "KDKX": {
      "icao": "KDKX",
      "iata": "",
      "name": "Knoxville Downtown Island Airport",
      "city": "Knoxville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 833,
      "lat": 35.9639015198,
      "lon": -83.8739013672,
      "tz": "America\/New_York"
  },
  "KDLC": {
      "icao": "KDLC",
      "iata": "DLL",
      "name": "Dillon County Airport",
      "city": "Dillon",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 133,
      "lat": 34.4491004944,
      "lon": -79.368598938,
      "tz": "America\/New_York"
  },
  "KDLF": {
      "icao": "KDLF",
      "iata": "DLF",
      "name": "Laughlin Air Force Base",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1082,
      "lat": 29.359500885,
      "lon": -100.7779998779,
      "tz": "America\/Chicago"
  },
  "KDLH": {
      "icao": "KDLH",
      "iata": "DLH",
      "name": "Duluth International Airport",
      "city": "Duluth",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1428,
      "lat": 46.8420982361,
      "lon": -92.1936035156,
      "tz": "America\/Chicago"
  },
  "KDLL": {
      "icao": "KDLL",
      "iata": "",
      "name": "Baraboo Wisconsin Dells Airport",
      "city": "Baraboo",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 979,
      "lat": 43.52270126,
      "lon": -89.77020264,
      "tz": "America\/Chicago"
  },
  "KDLN": {
      "icao": "KDLN",
      "iata": "DLN",
      "name": "Dillon Airport",
      "city": "Dillon",
      "state": "Montana",
      "country": "US",
      "elevation": 5241,
      "lat": 45.2554016113,
      "lon": -112.5530014038,
      "tz": "America\/Denver"
  },
  "KDLO": {
      "icao": "KDLO",
      "iata": "",
      "name": "Delano Municipal Airport",
      "city": "Delano",
      "state": "California",
      "country": "US",
      "elevation": 314,
      "lat": 35.7456016541,
      "lon": -119.2369995117,
      "tz": "America\/Los_Angeles"
  },
  "KDLS": {
      "icao": "KDLS",
      "iata": "DLS",
      "name": "Columbia Gorge Regional the Dalles Municipal Airport",
      "city": "The Dalles",
      "state": "Washington",
      "country": "US",
      "elevation": 247,
      "lat": 45.6184997559,
      "lon": -121.1669998169,
      "tz": "America\/Los_Angeles"
  },
  "KDLZ": {
      "icao": "KDLZ",
      "iata": "",
      "name": "Delaware Municipal Airport",
      "city": "Delaware",
      "state": "Ohio",
      "country": "US",
      "elevation": 945,
      "lat": 40.2797012329,
      "lon": -83.1147994995,
      "tz": "America\/New_York"
  },
  "KDMA": {
      "icao": "KDMA",
      "iata": "DMA",
      "name": "Davis Monthan Air Force Base",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 2704,
      "lat": 32.1665000916,
      "lon": -110.8830032349,
      "tz": "America\/Phoenix"
  },
  "KDMN": {
      "icao": "KDMN",
      "iata": "DMN",
      "name": "Deming Municipal Airport",
      "city": "Deming",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4314,
      "lat": 32.262298584,
      "lon": -107.7210006714,
      "tz": "America\/Denver"
  },
  "KDMO": {
      "icao": "KDMO",
      "iata": "DMO",
      "name": "Sedalia Memorial Airport",
      "city": "Sedalia",
      "state": "Missouri",
      "country": "US",
      "elevation": 909,
      "lat": 38.7074012756,
      "lon": -93.1759033203,
      "tz": "America\/Chicago"
  },
  "KDMW": {
      "icao": "KDMW",
      "iata": "",
      "name": "Carroll County Regional Jack B Poage Field",
      "city": "Westminster",
      "state": "Maryland",
      "country": "US",
      "elevation": 789,
      "lat": 39.60829926,
      "lon": -77.00769806,
      "tz": "America\/New_York"
  },
  "KDNL": {
      "icao": "KDNL",
      "iata": "DNL",
      "name": "Daniel Field",
      "city": "Augusta",
      "state": "Georgia",
      "country": "US",
      "elevation": 423,
      "lat": 33.4664993286,
      "lon": -82.0393981934,
      "tz": "America\/New_York"
  },
  "KDNN": {
      "icao": "KDNN",
      "iata": "DNN",
      "name": "Dalton Municipal Airport",
      "city": "Dalton",
      "state": "Georgia",
      "country": "US",
      "elevation": 709,
      "lat": 34.72290039,
      "lon": -84.87020111,
      "tz": "America\/New_York"
  },
  "KDNS": {
      "icao": "KDNS",
      "iata": "DNS",
      "name": "Denison Municipal Airport",
      "city": "Denison",
      "state": "Iowa",
      "country": "US",
      "elevation": 1274,
      "lat": 41.9864006,
      "lon": -95.38069916,
      "tz": "America\/Chicago"
  },
  "KDNV": {
      "icao": "KDNV",
      "iata": "DNV",
      "name": "Vermilion Regional Airport",
      "city": "Danville",
      "state": "Illinois",
      "country": "US",
      "elevation": 697,
      "lat": 40.19919968,
      "lon": -87.59590149,
      "tz": "America\/Chicago"
  },
  "KDOV": {
      "icao": "KDOV",
      "iata": "DOV",
      "name": "Dover Air Force Base",
      "city": "Dover",
      "state": "Delaware",
      "country": "US",
      "elevation": 24,
      "lat": 39.12950134,
      "lon": -75.46600342,
      "tz": "America\/New_York"
  },
  "KDPA": {
      "icao": "KDPA",
      "iata": "DPA",
      "name": "Dupage Airport",
      "city": "Chicago\/West Chicago",
      "state": "Illinois",
      "country": "US",
      "elevation": 759,
      "lat": 41.90779877,
      "lon": -88.24859619,
      "tz": "America\/Chicago"
  },
  "KDPG": {
      "icao": "KDPG",
      "iata": "DPG",
      "name": "Michael AAF (Dugway Proving Ground) Airport",
      "city": "Dugway Proving Ground",
      "state": "Utah",
      "country": "US",
      "elevation": 4349,
      "lat": 40.19940186,
      "lon": -112.9369965,
      "tz": "America\/Denver"
  },
  "KDPL": {
      "icao": "KDPL",
      "iata": "",
      "name": "Duplin County Airport",
      "city": "Kenansville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 136,
      "lat": 35.00009918,
      "lon": -77.98169708,
      "tz": "America\/New_York"
  },
  "KDQH": {
      "icao": "KDQH",
      "iata": "",
      "name": "Douglas Municipal Airport",
      "city": "Douglas",
      "state": "Arizona",
      "country": "US",
      "elevation": 257,
      "lat": 31.4766998291,
      "lon": -82.860496521,
      "tz": "America\/New_York"
  },
  "KDRA": {
      "icao": "KDRA",
      "iata": "DRA",
      "name": "Desert Rock Airport",
      "city": "Mercury",
      "state": "Nevada",
      "country": "US",
      "elevation": 3314,
      "lat": 36.6194000244,
      "lon": -116.032997131,
      "tz": "America\/Los_Angeles"
  },
  "KDRI": {
      "icao": "KDRI",
      "iata": "DRI",
      "name": "Beauregard Regional Airport",
      "city": "De Ridder",
      "state": "Louisiana",
      "country": "US",
      "elevation": 202,
      "lat": 30.8316993713,
      "lon": -93.3398971558,
      "tz": "America\/Chicago"
  },
  "KDRM": {
      "icao": "KDRM",
      "iata": "DRE",
      "name": "Drummond Island Airport",
      "city": "Drummond Island",
      "state": "Michigan",
      "country": "US",
      "elevation": 668,
      "lat": 46.0093002319,
      "lon": -83.7438964844,
      "tz": "America\/Detroit"
  },
  "KDRO": {
      "icao": "KDRO",
      "iata": "DRO",
      "name": "Durango La Plata County Airport",
      "city": "Durango",
      "state": "Colorado",
      "country": "US",
      "elevation": 6685,
      "lat": 37.1515007019,
      "lon": -107.753997803,
      "tz": "America\/Denver"
  },
  "KDRT": {
      "icao": "KDRT",
      "iata": "DRT",
      "name": "Del Rio International Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1002,
      "lat": 29.3742008209,
      "lon": -100.927001953,
      "tz": "America\/Chicago"
  },
  "KDSM": {
      "icao": "KDSM",
      "iata": "DSM",
      "name": "Des Moines International Airport",
      "city": "Des Moines",
      "state": "Iowa",
      "country": "US",
      "elevation": 958,
      "lat": 41.5340003967,
      "lon": -93.6631011963,
      "tz": "America\/Chicago"
  },
  "KDSV": {
      "icao": "KDSV",
      "iata": "DSV",
      "name": "Dansville Municipal Airport",
      "city": "Dansville",
      "state": "New-York",
      "country": "US",
      "elevation": 662,
      "lat": 42.5708999634,
      "lon": -77.7130966187,
      "tz": "America\/New_York"
  },
  "KDTA": {
      "icao": "KDTA",
      "iata": "DTA",
      "name": "Delta Municipal Airport",
      "city": "Delta",
      "state": "Utah",
      "country": "US",
      "elevation": 4759,
      "lat": 39.3805999756,
      "lon": -112.508003235,
      "tz": "America\/Denver"
  },
  "KDTG": {
      "icao": "KDTG",
      "iata": "",
      "name": "Dwight Airport",
      "city": "Dwight",
      "state": "Illinois",
      "country": "US",
      "elevation": 632,
      "lat": 41.1333007812,
      "lon": -88.4408035278,
      "tz": "America\/Chicago"
  },
  "KDTL": {
      "icao": "KDTL",
      "iata": "DTL",
      "name": "Detroit Lakes Airport - Wething Field",
      "city": "Detroit Lakes",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1397,
      "lat": 46.82519913,
      "lon": -95.88569641,
      "tz": "America\/Chicago"
  },
  "KDTN": {
      "icao": "KDTN",
      "iata": "DTN",
      "name": "Shreveport Downtown Airport",
      "city": "Shreveport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 179,
      "lat": 32.5401992798,
      "lon": -93.7450027466,
      "tz": "America\/Chicago"
  },
  "KDTO": {
      "icao": "KDTO",
      "iata": "",
      "name": "Denton Municipal Airport",
      "city": "Denton",
      "state": "Texas",
      "country": "US",
      "elevation": 642,
      "lat": 33.2006988525,
      "lon": -97.1979980469,
      "tz": "America\/Chicago"
  },
  "KDTS": {
      "icao": "KDTS",
      "iata": "DSI",
      "name": "Destin Fort Walton Beach Airport",
      "city": "Destin",
      "state": "Florida",
      "country": "US",
      "elevation": 23,
      "lat": 30.40010071,
      "lon": -86.47149658,
      "tz": "America\/Chicago"
  },
  "KDTW": {
      "icao": "KDTW",
      "iata": "DTW",
      "name": "Detroit Metropolitan Wayne County Airport",
      "city": "Detroit",
      "state": "Michigan",
      "country": "US",
      "elevation": 645,
      "lat": 42.2123985291,
      "lon": -83.3534011841,
      "tz": "America\/Detroit"
  },
  "KDUA": {
      "icao": "KDUA",
      "iata": "DUA",
      "name": "Eaker Field",
      "city": "Durant",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 699,
      "lat": 33.94229889,
      "lon": -96.39450073,
      "tz": "America\/Chicago"
  },
  "KDUC": {
      "icao": "KDUC",
      "iata": "DUC",
      "name": "Halliburton Field",
      "city": "Duncan",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1114,
      "lat": 34.47090149,
      "lon": -97.9598999,
      "tz": "America\/Chicago"
  },
  "KDUG": {
      "icao": "KDUG",
      "iata": "DUG",
      "name": "Bisbee Douglas International Airport",
      "city": "Douglas Bisbee",
      "state": "Arizona",
      "country": "US",
      "elevation": 4154,
      "lat": 31.4689998627,
      "lon": -109.603996277,
      "tz": "America\/Phoenix"
  },
  "KDUH": {
      "icao": "KDUH",
      "iata": "",
      "name": "Toledo Suburban Airport",
      "city": "Lambertville",
      "state": "Michigan",
      "country": "US",
      "elevation": 669,
      "lat": 41.7359008789,
      "lon": -83.6554031372,
      "tz": "America\/Detroit"
  },
  "KDUJ": {
      "icao": "KDUJ",
      "iata": "DUJ",
      "name": "DuBois Regional Airport",
      "city": "Dubois",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1817,
      "lat": 41.17829895,
      "lon": -78.8986969,
      "tz": "America\/New_York"
  },
  "KDUX": {
      "icao": "KDUX",
      "iata": "",
      "name": "Moore County Airport",
      "city": "Dumas",
      "state": "Texas",
      "country": "US",
      "elevation": 3705,
      "lat": 35.8578987122,
      "lon": -102.0130004883,
      "tz": "America\/Chicago"
  },
  "KDVK": {
      "icao": "KDVK",
      "iata": "",
      "name": "Stuart Powell Field",
      "city": "Danville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1022,
      "lat": 37.5778999329,
      "lon": -84.7696990967,
      "tz": "America\/New_York"
  },
  "KDVL": {
      "icao": "KDVL",
      "iata": "DVL",
      "name": "Devils Lake Regional Airport",
      "city": "Devils Lake",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1456,
      "lat": 48.11420059,
      "lon": -98.90879822,
      "tz": "America\/Chicago"
  },
  "KDVN": {
      "icao": "KDVN",
      "iata": "DVN",
      "name": "Davenport Municipal Airport",
      "city": "Davenport",
      "state": "Iowa",
      "country": "US",
      "elevation": 751,
      "lat": 41.61029816,
      "lon": -90.58830261,
      "tz": "America\/Chicago"
  },
  "KDVO": {
      "icao": "KDVO",
      "iata": "NOT",
      "name": "Gnoss Field",
      "city": "Novato",
      "state": "California",
      "country": "US",
      "elevation": 2,
      "lat": 38.1436004639,
      "lon": -122.5559997559,
      "tz": "America\/Los_Angeles"
  },
  "KDVP": {
      "icao": "KDVP",
      "iata": "NSL",
      "name": "Slayton Municipal Airport",
      "city": "Slayton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1623,
      "lat": 43.9868011475,
      "lon": -95.7826004028,
      "tz": "America\/Chicago"
  },
  "KDVT": {
      "icao": "KDVT",
      "iata": "DVT",
      "name": "Phoenix Deer Valley Airport",
      "city": "Phoenix",
      "state": "Arizona",
      "country": "US",
      "elevation": 1478,
      "lat": 33.6883010864,
      "lon": -112.083000183,
      "tz": "America\/Phoenix"
  },
  "KDWA": {
      "icao": "KDWA",
      "iata": "",
      "name": "Yolo County Davis Woodland Winters Airport",
      "city": "Davis\/Woodland\/Winters",
      "state": "California",
      "country": "US",
      "elevation": 100,
      "lat": 38.57910156,
      "lon": -121.8570023,
      "tz": "America\/Los_Angeles"
  },
  "KDWH": {
      "icao": "KDWH",
      "iata": "DWH",
      "name": "David Wayne Hooks Memorial Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 152,
      "lat": 30.0618000031,
      "lon": -95.5528030396,
      "tz": "America\/Chicago"
  },
  "KDWU": {
      "icao": "KDWU",
      "iata": "",
      "name": "Ashland Regional Airport",
      "city": "Ashland",
      "state": "Kentucky",
      "country": "US",
      "elevation": 546,
      "lat": 38.5545005798,
      "lon": -82.7379989624,
      "tz": "America\/New_York"
  },
  "KDWX": {
      "icao": "KDWX",
      "iata": "",
      "name": "Dixon Airport",
      "city": "Dixon",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6520,
      "lat": 41.0382995605,
      "lon": -107.4970016479,
      "tz": "America\/Denver"
  },
  "KDXE": {
      "icao": "KDXE",
      "iata": "",
      "name": "Dexter Municipal Airport",
      "city": "Dexter",
      "state": "Missouri",
      "country": "US",
      "elevation": 304,
      "lat": 36.77750015,
      "lon": -89.94120026,
      "tz": "America\/Chicago"
  },
  "KDXR": {
      "icao": "KDXR",
      "iata": "DXR",
      "name": "Danbury Municipal Airport",
      "city": "Danbury",
      "state": "Connecticut",
      "country": "US",
      "elevation": 458,
      "lat": 41.3714981079,
      "lon": -73.4822006226,
      "tz": "America\/New_York"
  },
  "KDXX": {
      "icao": "KDXX",
      "iata": "",
      "name": "Lac Qui Parle County Airport",
      "city": "Madison",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1082,
      "lat": 44.9861984253,
      "lon": -96.177696228,
      "tz": "America\/Chicago"
  },
  "KDYA": {
      "icao": "KDYA",
      "iata": "",
      "name": "Demopolis Municipal Airport",
      "city": "Demopolis",
      "state": "Alabama",
      "country": "US",
      "elevation": 113,
      "lat": 32.4637985229,
      "lon": -87.9541015625,
      "tz": "America\/Chicago"
  },
  "KDYB": {
      "icao": "KDYB",
      "iata": "",
      "name": "Summerville Airport",
      "city": "Summerville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 56,
      "lat": 33.0634002686,
      "lon": -80.279296875,
      "tz": "America\/New_York"
  },
  "KDYL": {
      "icao": "KDYL",
      "iata": "DYL",
      "name": "Doylestown Airport",
      "city": "Doylestown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 394,
      "lat": 40.3330001831,
      "lon": -75.1222991943,
      "tz": "America\/New_York"
  },
  "KDYR": {
      "icao": "KDYR",
      "iata": "",
      "name": "Dyersburg Regional Airport",
      "city": "Dyersburg",
      "state": "Tennessee",
      "country": "US",
      "elevation": 338,
      "lat": 35.9980010986,
      "lon": -89.4066009521,
      "tz": "America\/Chicago"
  },
  "KDYS": {
      "icao": "KDYS",
      "iata": "DYS",
      "name": "Dyess Air Force Base",
      "city": "Abilene",
      "state": "Texas",
      "country": "US",
      "elevation": 1789,
      "lat": 32.4207992554,
      "lon": -99.854598999,
      "tz": "America\/Chicago"
  },
  "KDYT": {
      "icao": "KDYT",
      "iata": "",
      "name": "Sky Harbor Airport",
      "city": "Duluth",
      "state": "Minnesota",
      "country": "US",
      "elevation": 610,
      "lat": 46.7219009399,
      "lon": -92.0434036255,
      "tz": "America\/Chicago"
  },
  "KDZB": {
      "icao": "KDZB",
      "iata": "",
      "name": "Horseshoe Bay Resort Airpark",
      "city": "Horseshoe Bay",
      "state": "Texas",
      "country": "US",
      "elevation": 1093,
      "lat": 30.52709961,
      "lon": -98.35870361,
      "tz": "America\/Chicago"
  },
  "KDZJ": {
      "icao": "KDZJ",
      "iata": "",
      "name": "Blairsville Airport",
      "city": "Blairsville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1911,
      "lat": 34.8550987244,
      "lon": -83.9969024658,
      "tz": "America\/New_York"
  },
  "KE01": {
      "icao": "KE01",
      "iata": "",
      "name": "Roy Hurd Memorial Airport",
      "city": "Monahans",
      "state": "Texas",
      "country": "US",
      "elevation": 2615,
      "lat": 31.5825004578,
      "lon": -102.908996582,
      "tz": "America\/Chicago"
  },
  "KE05": {
      "icao": "KE05",
      "iata": "",
      "name": "Hatch Municipal Airport",
      "city": "Hatch",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4080,
      "lat": 32.6610984802,
      "lon": -107.1979980469,
      "tz": "America\/Denver"
  },
  "KE06": {
      "icao": "KE06",
      "iata": "",
      "name": "Lea County-Zip Franklin Memorial Airport",
      "city": "Lovington",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3979,
      "lat": 32.95389938,
      "lon": -103.4089966,
      "tz": "America\/Denver"
  },
  "KE11": {
      "icao": "KE11",
      "iata": "",
      "name": "Andrews County Airport",
      "city": "Andrews",
      "state": "Texas",
      "country": "US",
      "elevation": 3174,
      "lat": 32.3311004639,
      "lon": -102.5299987793,
      "tz": "America\/Chicago"
  },
  "KE13": {
      "icao": "KE13",
      "iata": "CCG",
      "name": "Crane County Airport",
      "city": "Crane",
      "state": "Texas",
      "country": "US",
      "elevation": 2552,
      "lat": 31.4151000977,
      "lon": -102.362998962,
      "tz": "America\/Chicago"
  },
  "KE14": {
      "icao": "KE14",
      "iata": "",
      "name": "Ohkay Owingeh Airport",
      "city": "Espanola",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5790,
      "lat": 36.0299987793,
      "lon": -106.04599762,
      "tz": "America\/Denver"
  },
  "KE16": {
      "icao": "KE16",
      "iata": "",
      "name": "South County Airport of Santa Clara County Airport",
      "city": "San Martin",
      "state": "California",
      "country": "US",
      "elevation": 281,
      "lat": 37.08160019,
      "lon": -121.5970001,
      "tz": "America\/Los_Angeles"
  },
  "KE19": {
      "icao": "KE19",
      "iata": "",
      "name": "Gruver Municipal Airport",
      "city": "Gruver",
      "state": "Texas",
      "country": "US",
      "elevation": 3205,
      "lat": 36.2336997986,
      "lon": -101.4319992065,
      "tz": "America\/Chicago"
  },
  "KE24": {
      "icao": "KE24",
      "iata": "",
      "name": "Whiteriver Airport",
      "city": "Whiteriver",
      "state": "Arizona",
      "country": "US",
      "elevation": 5153,
      "lat": 33.8125,
      "lon": -109.9869995,
      "tz": "America\/Phoenix"
  },
  "KE25": {
      "icao": "KE25",
      "iata": "",
      "name": "Wickenburg Municipal Airport",
      "city": "Wickenburg",
      "state": "Arizona",
      "country": "US",
      "elevation": 2377,
      "lat": 33.96889877,
      "lon": -112.7990036,
      "tz": "America\/Phoenix"
  },
  "KE26": {
      "icao": "KE26",
      "iata": "",
      "name": "Lea County Jal Airport",
      "city": "Jal",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3118,
      "lat": 32.1310997009,
      "lon": -103.1549987793,
      "tz": "America\/Denver"
  },
  "KE30": {
      "icao": "KE30",
      "iata": "",
      "name": "Bruce Field",
      "city": "Ballinger",
      "state": "Texas",
      "country": "US",
      "elevation": 1738,
      "lat": 31.6744995117,
      "lon": -99.9769973755,
      "tz": "America\/Chicago"
  },
  "KE34": {
      "icao": "KE34",
      "iata": "",
      "name": "Smiley Johnson Municipal-Bass Field",
      "city": "Clarendon",
      "state": "Texas",
      "country": "US",
      "elevation": 2833,
      "lat": 34.91149902,
      "lon": -100.8690033,
      "tz": "America\/Chicago"
  },
  "KE35": {
      "icao": "KE35",
      "iata": "",
      "name": "Fabens Airport",
      "city": "Fabens",
      "state": "Texas",
      "country": "US",
      "elevation": 3679,
      "lat": 31.5156993866,
      "lon": -106.1470031738,
      "tz": "America\/Denver"
  },
  "KE38": {
      "icao": "KE38",
      "iata": "",
      "name": "Alpine Casparis Municipal Airport",
      "city": "Alpine",
      "state": "Texas",
      "country": "US",
      "elevation": 4515,
      "lat": 30.3841991425,
      "lon": -103.683998108,
      "tz": "America\/Chicago"
  },
  "KE41": {
      "icao": "KE41",
      "iata": "BGQ",
      "name": "Reagan County Airport",
      "city": "Big Lake",
      "state": "Texas",
      "country": "US",
      "elevation": 2706,
      "lat": 31.1989002228,
      "lon": -101.472999573,
      "tz": "America\/Chicago"
  },
  "KE42": {
      "icao": "KE42",
      "iata": "",
      "name": "Spearman Municipal Airport",
      "city": "Spearman",
      "state": "Texas",
      "country": "US",
      "elevation": 3090,
      "lat": 36.2210006714,
      "lon": -101.1949996948,
      "tz": "America\/Chicago"
  },
  "KE45": {
      "icao": "KE45",
      "iata": "",
      "name": "Pine Mountain Lake Airport",
      "city": "Groveland",
      "state": "California",
      "country": "US",
      "elevation": 2930,
      "lat": 37.8616981506,
      "lon": -120.1780014038,
      "tz": "America\/Los_Angeles"
  },
  "KE48": {
      "icao": "KE48",
      "iata": "",
      "name": "Upton County Airport",
      "city": "Mc Camey",
      "state": "Texas",
      "country": "US",
      "elevation": 2433,
      "lat": 31.1250991821,
      "lon": -102.2249984741,
      "tz": "America\/Chicago"
  },
  "KE51": {
      "icao": "KE51",
      "iata": "BGT",
      "name": "Bagdad Airport",
      "city": "Bagdad",
      "state": "Arizona",
      "country": "US",
      "elevation": 4183,
      "lat": 34.5959014893,
      "lon": -113.1699981689,
      "tz": "America\/Phoenix"
  },
  "KE52": {
      "icao": "KE52",
      "iata": "",
      "name": "Oldham County Airport",
      "city": "Vega",
      "state": "Texas",
      "country": "US",
      "elevation": 3995,
      "lat": 35.2319984436,
      "lon": -102.3990020752,
      "tz": "America\/Chicago"
  },
  "KE57": {
      "icao": "KE57",
      "iata": "",
      "name": "Denver City Airport",
      "city": "Denver City",
      "state": "Texas",
      "country": "US",
      "elevation": 3575,
      "lat": 32.9751014709,
      "lon": -102.8420028687,
      "tz": "America\/Chicago"
  },
  "KE60": {
      "icao": "KE60",
      "iata": "",
      "name": "Eloy Municipal Airport",
      "city": "Eloy",
      "state": "Arizona",
      "country": "US",
      "elevation": 1513,
      "lat": 32.8069992065,
      "lon": -111.5869979858,
      "tz": "America\/Phoenix"
  },
  "KE63": {
      "icao": "KE63",
      "iata": "",
      "name": "Gila Bend Municipal Airport",
      "city": "Gila Bend",
      "state": "Arizona",
      "country": "US",
      "elevation": 789,
      "lat": 32.95809937,
      "lon": -112.6780014,
      "tz": "America\/Phoenix"
  },
  "KE67": {
      "icao": "KE67",
      "iata": "",
      "name": "Kearny Airport",
      "city": "Kearny",
      "state": "Arizona",
      "country": "US",
      "elevation": 1833,
      "lat": 33.0475997925,
      "lon": -110.908996582,
      "tz": "America\/Phoenix"
  },
  "KE77": {
      "icao": "KE77",
      "iata": "",
      "name": "San Manuel Airport",
      "city": "San Manuel",
      "state": "Arizona",
      "country": "US",
      "elevation": 3274,
      "lat": 32.6365013123,
      "lon": -110.6470031738,
      "tz": "America\/Phoenix"
  },
  "KE78": {
      "icao": "KE78",
      "iata": "",
      "name": "Sells Airport",
      "city": "Sells",
      "state": "Arizona",
      "country": "US",
      "elevation": 2409,
      "lat": 31.9328994751,
      "lon": -111.8939971924,
      "tz": "America\/Phoenix"
  },
  "KE79": {
      "icao": "KE79",
      "iata": "",
      "name": "Sierra Sky Park Airport",
      "city": "Fresno",
      "state": "California",
      "country": "US",
      "elevation": 321,
      "lat": 36.84016667,
      "lon": -119.86916667,
      "tz": "America\/Los_Angeles"
  },
  "KE80": {
      "icao": "KE80",
      "iata": "",
      "name": "Alexander Municipal Airport",
      "city": "Belen",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5194,
      "lat": 34.645198822,
      "lon": -106.8339996338,
      "tz": "America\/Denver"
  },
  "KE89": {
      "icao": "KE89",
      "iata": "",
      "name": "Conchas Lake Airport",
      "city": "Conchas Dam",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4230,
      "lat": 35.3643989563,
      "lon": -104.1819992065,
      "tz": "America\/Denver"
  },
  "KE91": {
      "icao": "KE91",
      "iata": "",
      "name": "Chinle Municipal Airport",
      "city": "Chinle",
      "state": "Arizona",
      "country": "US",
      "elevation": 5547,
      "lat": 36.1109008789,
      "lon": -109.5749969482,
      "tz": "America\/Denver"
  },
  "KE95": {
      "icao": "KE95",
      "iata": "",
      "name": "Benson Municipal Airport",
      "city": "Benson",
      "state": "Arizona",
      "country": "US",
      "elevation": 3829,
      "lat": 31.9997005463,
      "lon": -110.3570022583,
      "tz": "America\/Phoenix"
  },
  "KE98": {
      "icao": "KE98",
      "iata": "",
      "name": "Mid Valley Airpark",
      "city": "Los Lunas",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4830,
      "lat": 34.7597999573,
      "lon": -106.7450027466,
      "tz": "America\/Denver"
  },
  "KEAG": {
      "icao": "KEAG",
      "iata": "",
      "name": "Eagle Grove Municipal Airport",
      "city": "Eagle Grove",
      "state": "Iowa",
      "country": "US",
      "elevation": 1133,
      "lat": 42.7098007202,
      "lon": -93.9160995483,
      "tz": "America\/Chicago"
  },
  "KEAN": {
      "icao": "KEAN",
      "iata": "EAN",
      "name": "Phifer Airfield",
      "city": "Wheatland",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4776,
      "lat": 42.05550003,
      "lon": -104.9290009,
      "tz": "America\/Denver"
  },
  "KEAR": {
      "icao": "KEAR",
      "iata": "EAR",
      "name": "Kearney Regional Airport",
      "city": "Kearney",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2131,
      "lat": 40.72700119,
      "lon": -99.00679779,
      "tz": "America\/Chicago"
  },
  "KEAT": {
      "icao": "KEAT",
      "iata": "EAT",
      "name": "Pangborn Memorial Airport",
      "city": "Wenatchee",
      "state": "Washington",
      "country": "US",
      "elevation": 1249,
      "lat": 47.3988990784,
      "lon": -120.2070007324,
      "tz": "America\/Los_Angeles"
  },
  "KEAU": {
      "icao": "KEAU",
      "iata": "EAU",
      "name": "Chippewa Valley Regional Airport",
      "city": "Eau Claire",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 913,
      "lat": 44.8657989502,
      "lon": -91.4842987061,
      "tz": "America\/Chicago"
  },
  "KEBG": {
      "icao": "KEBG",
      "iata": "",
      "name": "South Texas International At Edinburg Airport",
      "city": "Edinburg",
      "state": "Texas",
      "country": "US",
      "elevation": 75,
      "lat": 26.44169998,
      "lon": -98.12220001,
      "tz": "America\/Chicago"
  },
  "KEBS": {
      "icao": "KEBS",
      "iata": "EBS",
      "name": "Webster City Municipal Airport",
      "city": "Webster City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1122,
      "lat": 42.43659973,
      "lon": -93.86889648,
      "tz": "America\/Chicago"
  },
  "KECG": {
      "icao": "KECG",
      "iata": "ECG",
      "name": "Elizabeth City Regional Airport & Coast Guard Air Station",
      "city": "Elizabeth City",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 12,
      "lat": 36.26060104,
      "lon": -76.17459869,
      "tz": "America\/New_York"
  },
  "KECP": {
      "icao": "KECP",
      "iata": "ECP",
      "name": "Northwest Florida Beaches International Airport",
      "city": "Panama City Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 69,
      "lat": 30.3417,
      "lon": -85.7973,
      "tz": "America\/Chicago"
  },
  "KECS": {
      "icao": "KECS",
      "iata": "ECS",
      "name": "Mondell Field",
      "city": "Newcastle",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4174,
      "lat": 43.8853988647,
      "lon": -104.3180007935,
      "tz": "America\/Denver"
  },
  "KECU": {
      "icao": "KECU",
      "iata": "",
      "name": "Edwards County Airport",
      "city": "Rocksprings",
      "state": "Texas",
      "country": "US",
      "elevation": 2372,
      "lat": 29.9468994141,
      "lon": -100.1740036011,
      "tz": "America\/Chicago"
  },
  "KEDC": {
      "icao": "KEDC",
      "iata": null,
      "name": "Austin Executive Airport",
      "city": "Austin",
      "state": "Texas",
      "country": "US",
      "elevation": 189,
      "lat": 30.4000778,
      "lon": -97.5760335,
      "tz": "America\/Chicago"
  },
  "KEDE": {
      "icao": "KEDE",
      "iata": "EDE",
      "name": "Northeastern Regional Airport",
      "city": "Edenton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 20,
      "lat": 36.0276985168,
      "lon": -76.5671005249,
      "tz": "America\/New_York"
  },
  "KEDG": {
      "icao": "KEDG",
      "iata": "",
      "name": "Weide Ahp (Aberdeen Proving Ground) Heliport",
      "city": "Edgewood Arsenal",
      "state": "Maryland",
      "country": "US",
      "elevation": 21,
      "lat": 39.39160156,
      "lon": -76.29109955,
      "tz": "America\/New_York"
  },
  "KEDJ": {
      "icao": "KEDJ",
      "iata": "",
      "name": "Bellefontaine Regional Airport",
      "city": "Bellefontaine",
      "state": "Ohio",
      "country": "US",
      "elevation": 1122,
      "lat": 40.37229919,
      "lon": -83.81900024,
      "tz": "America\/New_York"
  },
  "KEDN": {
      "icao": "KEDN",
      "iata": "ETS",
      "name": "Enterprise Municipal Airport",
      "city": "Enterprise",
      "state": "Alabama",
      "country": "US",
      "elevation": 361,
      "lat": 31.29969978,
      "lon": -85.89990234,
      "tz": "America\/Chicago"
  },
  "KEDU": {
      "icao": "KEDU",
      "iata": "",
      "name": "University Airport",
      "city": "Davis",
      "state": "California",
      "country": "US",
      "elevation": 69,
      "lat": 38.53150177,
      "lon": -121.7860031128,
      "tz": "America\/Los_Angeles"
  },
  "KEDW": {
      "icao": "KEDW",
      "iata": "EDW",
      "name": "Edwards Air Force Base",
      "city": "Edwards",
      "state": "California",
      "country": "US",
      "elevation": 2312,
      "lat": 34.90539932,
      "lon": -117.8840027,
      "tz": "America\/Los_Angeles"
  },
  "KEED": {
      "icao": "KEED",
      "iata": "EED",
      "name": "Needles Airport",
      "city": "Needles",
      "state": "California",
      "country": "US",
      "elevation": 983,
      "lat": 34.7663002014,
      "lon": -114.6230010986,
      "tz": "America\/Los_Angeles"
  },
  "KEEN": {
      "icao": "KEEN",
      "iata": "EEN",
      "name": "Dillant Hopkins Airport",
      "city": "Keene",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 488,
      "lat": 42.898399353,
      "lon": -72.2707977295,
      "tz": "America\/New_York"
  },
  "KEEO": {
      "icao": "KEEO",
      "iata": "",
      "name": "Meeker Airport",
      "city": "Meeker",
      "state": "Colorado",
      "country": "US",
      "elevation": 6421,
      "lat": 40.0488014221,
      "lon": -107.8860015869,
      "tz": "America\/Denver"
  },
  "KEET": {
      "icao": "KEET",
      "iata": "",
      "name": "Shelby County Airport",
      "city": "Alabaster",
      "state": "Alabama",
      "country": "US",
      "elevation": 586,
      "lat": 33.17699814,
      "lon": -86.78279877,
      "tz": "America\/Chicago"
  },
  "KEFC": {
      "icao": "KEFC",
      "iata": "",
      "name": "Belle Fourche Municipal Airport",
      "city": "Belle Fourche",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3191,
      "lat": 44.73419952,
      "lon": -103.8619995,
      "tz": "America\/Denver"
  },
  "KEFD": {
      "icao": "KEFD",
      "iata": "EFD",
      "name": "Ellington Field",
      "city": "Houston",
      "state": "Connecticut",
      "country": "US",
      "elevation": 32,
      "lat": 29.6072998047,
      "lon": -95.1587982178,
      "tz": "America\/Chicago"
  },
  "KEFK": {
      "icao": "KEFK",
      "iata": "EFK",
      "name": "Newport State Airport",
      "city": "Newport",
      "state": "Vermont",
      "country": "US",
      "elevation": 930,
      "lat": 44.8888015747,
      "lon": -72.2292022705,
      "tz": "America\/New_York"
  },
  "KEFT": {
      "icao": "KEFT",
      "iata": "",
      "name": "Monroe Municipal Airport",
      "city": "Monroe",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1086,
      "lat": 42.6148986816,
      "lon": -89.5904006958,
      "tz": "America\/Chicago"
  },
  "KEFW": {
      "icao": "KEFW",
      "iata": "EFW",
      "name": "Jefferson Municipal Airport",
      "city": "Jefferson",
      "state": "Iowa",
      "country": "US",
      "elevation": 1044,
      "lat": 42.0102005,
      "lon": -94.34259796,
      "tz": "America\/Chicago"
  },
  "KEGE": {
      "icao": "KEGE",
      "iata": "EGE",
      "name": "Eagle County Regional Airport",
      "city": "Eagle",
      "state": "Colorado",
      "country": "US",
      "elevation": 6548,
      "lat": 39.64260101,
      "lon": -106.9179993,
      "tz": "America\/Denver"
  },
  "KEGI": {
      "icao": "KEGI",
      "iata": "EGI",
      "name": "Duke Field(Eglin Af Aux Nr 3) Airport",
      "city": "Crestview",
      "state": "Florida",
      "country": "US",
      "elevation": 191,
      "lat": 30.65040016,
      "lon": -86.52290344,
      "tz": "America\/Chicago"
  },
  "KEGQ": {
      "icao": "KEGQ",
      "iata": "",
      "name": "Emmetsburg Municipal Airport",
      "city": "Emmetsburg",
      "state": "Iowa",
      "country": "US",
      "elevation": 1205,
      "lat": 43.1020011902,
      "lon": -94.7046966553,
      "tz": "America\/Chicago"
  },
  "KEGT": {
      "icao": "KEGT",
      "iata": "",
      "name": "Wellington Municipal Airport",
      "city": "Wellington",
      "state": "Kansas",
      "country": "US",
      "elevation": 1277,
      "lat": 37.323600769,
      "lon": -97.3882980347,
      "tz": "America\/Chicago"
  },
  "KEGV": {
      "icao": "KEGV",
      "iata": "EGV",
      "name": "Eagle River Union Airport",
      "city": "Eagle River",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1642,
      "lat": 45.9323005676,
      "lon": -89.2683029175,
      "tz": "America\/Chicago"
  },
  "KEHA": {
      "icao": "KEHA",
      "iata": "",
      "name": "Elkhart Morton County Airport",
      "city": "Elkhart",
      "state": "Kansas",
      "country": "US",
      "elevation": 3622,
      "lat": 37.0007019043,
      "lon": -101.8799972534,
      "tz": "America\/Chicago"
  },
  "KEHO": {
      "icao": "KEHO",
      "iata": "",
      "name": "Shelby-Cleveland County Regional Airport",
      "city": "Shelby",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 847,
      "lat": 35.25559998,
      "lon": -81.60099792,
      "tz": "America\/New_York"
  },
  "KEHR": {
      "icao": "KEHR",
      "iata": "",
      "name": "Henderson City County Airport",
      "city": "Henderson",
      "state": "Kentucky",
      "country": "US",
      "elevation": 387,
      "lat": 37.80780029,
      "lon": -87.68569946,
      "tz": "America\/Chicago"
  },
  "KEIK": {
      "icao": "KEIK",
      "iata": "",
      "name": "Erie Municipal Airport",
      "city": "Erie",
      "state": "Colorado",
      "country": "US",
      "elevation": 5130,
      "lat": 40.0102005005,
      "lon": -105.047996521,
      "tz": "America\/Denver"
  },
  "KEIW": {
      "icao": "KEIW",
      "iata": "",
      "name": "County Memorial Airport",
      "city": "New Madrid",
      "state": "Missouri",
      "country": "US",
      "elevation": 296,
      "lat": 36.53530121,
      "lon": -89.59970093,
      "tz": "America\/Chicago"
  },
  "KEKA": {
      "icao": "KEKA",
      "iata": "EKA",
      "name": "Murray Field",
      "city": "Eureka",
      "state": "California",
      "country": "US",
      "elevation": 7,
      "lat": 40.8033981323,
      "lon": -124.1129989624,
      "tz": "America\/Los_Angeles"
  },
  "KEKM": {
      "icao": "KEKM",
      "iata": "EKI",
      "name": "Elkhart Municipal Airport",
      "city": "Elkhart",
      "state": "Indiana",
      "country": "US",
      "elevation": 778,
      "lat": 41.7193984985,
      "lon": -86.0031967163,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KEKN": {
      "icao": "KEKN",
      "iata": "EKN",
      "name": "Elkins-Randolph Co-Jennings Randolph Field",
      "city": "Elkins",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1987,
      "lat": 38.88940048,
      "lon": -79.85710144,
      "tz": "America\/New_York"
  },
  "KEKO": {
      "icao": "KEKO",
      "iata": "EKO",
      "name": "Elko Regional Airport",
      "city": "Elko",
      "state": "Nevada",
      "country": "US",
      "elevation": 5140,
      "lat": 40.8249015808,
      "lon": -115.7919998169,
      "tz": "America\/Los_Angeles"
  },
  "KEKQ": {
      "icao": "KEKQ",
      "iata": "",
      "name": "Wayne County Airport",
      "city": "Monticello",
      "state": "Kentucky",
      "country": "US",
      "elevation": 963,
      "lat": 36.8553009033,
      "lon": -84.8561019897,
      "tz": "America\/Kentucky\/Monticello"
  },
  "KEKS": {
      "icao": "KEKS",
      "iata": "",
      "name": "Ennis Big Sky Airport",
      "city": "Ennis",
      "state": "Montana",
      "country": "US",
      "elevation": 5423,
      "lat": 45.27180099,
      "lon": -111.6490021,
      "tz": "America\/Denver"
  },
  "KEKX": {
      "icao": "KEKX",
      "iata": "EKX",
      "name": "Addington Field",
      "city": "Elizabethtown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 775,
      "lat": 37.686000824,
      "lon": -85.9250030518,
      "tz": "America\/New_York"
  },
  "KEKY": {
      "icao": "KEKY",
      "iata": "",
      "name": "Bessemer Airport",
      "city": "Bessemer",
      "state": "Alabama",
      "country": "US",
      "elevation": 700,
      "lat": 33.31290054,
      "lon": -86.92590332,
      "tz": "America\/Chicago"
  },
  "KELA": {
      "icao": "KELA",
      "iata": "ELA",
      "name": "Eagle Lake Airport",
      "city": "Eagle Lake",
      "state": "Texas",
      "country": "US",
      "elevation": 184,
      "lat": 29.6005992889,
      "lon": -96.3218994141,
      "tz": "America\/Chicago"
  },
  "KELD": {
      "icao": "KELD",
      "iata": "ELD",
      "name": "South Arkansas Regional At Goodwin Field",
      "city": "El Dorado",
      "state": "Arkansas",
      "country": "US",
      "elevation": 277,
      "lat": 33.2210006714,
      "lon": -92.8133010864,
      "tz": "America\/Chicago"
  },
  "KELK": {
      "icao": "KELK",
      "iata": "ELK",
      "name": "Elk City Regional Business Airport",
      "city": "Elk City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2013,
      "lat": 35.43080139,
      "lon": -99.39430237,
      "tz": "America\/Chicago"
  },
  "KELM": {
      "icao": "KELM",
      "iata": "ELM",
      "name": "Elmira Corning Regional Airport",
      "city": "Elmira\/Corning",
      "state": "New-York",
      "country": "US",
      "elevation": 954,
      "lat": 42.1599006653,
      "lon": -76.8916015625,
      "tz": "America\/New_York"
  },
  "KELN": {
      "icao": "KELN",
      "iata": "ELN",
      "name": "Bowers Field",
      "city": "Ellensburg",
      "state": "Washington",
      "country": "US",
      "elevation": 1764,
      "lat": 47.03300095,
      "lon": -120.5309982,
      "tz": "America\/Los_Angeles"
  },
  "KELO": {
      "icao": "KELO",
      "iata": "LYU",
      "name": "Ely Municipal Airport",
      "city": "Ely",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1456,
      "lat": 47.82450104,
      "lon": -91.83070374,
      "tz": "America\/Chicago"
  },
  "KELP": {
      "icao": "KELP",
      "iata": "ELP",
      "name": "El Paso International Airport",
      "city": "El Paso",
      "state": "Texas",
      "country": "US",
      "elevation": 3959,
      "lat": 31.80719948,
      "lon": -106.3779984,
      "tz": "America\/Denver"
  },
  "KELY": {
      "icao": "KELY",
      "iata": "ELY",
      "name": "Ely Airport Yelland Field",
      "city": "Ely",
      "state": "Nevada",
      "country": "US",
      "elevation": 6259,
      "lat": 39.29970169,
      "lon": -114.8420029,
      "tz": "America\/Los_Angeles"
  },
  "KELZ": {
      "icao": "KELZ",
      "iata": "ELZ",
      "name": "Wellsville Municipal ArptTarantine Field",
      "city": "Wellsville",
      "state": "New-York",
      "country": "US",
      "elevation": 2124,
      "lat": 42.10950089,
      "lon": -77.98999786,
      "tz": "America\/New_York"
  },
  "KEMM": {
      "icao": "KEMM",
      "iata": "EMM",
      "name": "Kemmerer Municipal Airport",
      "city": "Kemmerer",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7285,
      "lat": 41.8241004944,
      "lon": -110.5569992065,
      "tz": "America\/Denver"
  },
  "KEMP": {
      "icao": "KEMP",
      "iata": "EMP",
      "name": "Emporia Municipal Airport",
      "city": "Emporia",
      "state": "Kansas",
      "country": "US",
      "elevation": 1208,
      "lat": 38.3320999146,
      "lon": -96.1912002563,
      "tz": "America\/Chicago"
  },
  "KEMT": {
      "icao": "KEMT",
      "iata": "EMT",
      "name": "El Monte Airport",
      "city": "El Monte",
      "state": "California",
      "country": "US",
      "elevation": 296,
      "lat": 34.086101532,
      "lon": -118.0350036621,
      "tz": "America\/Los_Angeles"
  },
  "KEMV": {
      "icao": "KEMV",
      "iata": "",
      "name": "Emporia Greensville Regional Airport",
      "city": "Emporia",
      "state": "Virginia",
      "country": "US",
      "elevation": 127,
      "lat": 36.6869010925,
      "lon": -77.4828033447,
      "tz": "America\/New_York"
  },
  "KEND": {
      "icao": "KEND",
      "iata": "END",
      "name": "Vance Air Force Base",
      "city": "Enid",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1307,
      "lat": 36.3391990662,
      "lon": -97.9164962769,
      "tz": "America\/Chicago"
  },
  "KENL": {
      "icao": "KENL",
      "iata": "ENL",
      "name": "Centralia Municipal Airport",
      "city": "Centralia",
      "state": "Illinois",
      "country": "US",
      "elevation": 534,
      "lat": 38.5150985718,
      "lon": -89.0911026001,
      "tz": "America\/Chicago"
  },
  "KENV": {
      "icao": "KENV",
      "iata": "ENV",
      "name": "Wendover Airport",
      "city": "Wendover",
      "state": "Utah",
      "country": "US",
      "elevation": 4237,
      "lat": 40.7187004089,
      "lon": -114.03099823,
      "tz": "America\/Denver"
  },
  "KENW": {
      "icao": "KENW",
      "iata": "ENW",
      "name": "Kenosha Regional Airport",
      "city": "Kenosha",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 742,
      "lat": 42.59569931,
      "lon": -87.92780304,
      "tz": "America\/Chicago"
  },
  "KEOE": {
      "icao": "KEOE",
      "iata": "",
      "name": "Newberry County Airport",
      "city": "Newberry",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 570,
      "lat": 34.30929947,
      "lon": -81.63970184,
      "tz": "America\/New_York"
  },
  "KEOK": {
      "icao": "KEOK",
      "iata": "EOK",
      "name": "Keokuk Municipal Airport",
      "city": "Keokuk",
      "state": "Iowa",
      "country": "US",
      "elevation": 671,
      "lat": 40.4598999023,
      "lon": -91.4284973145,
      "tz": "America\/Chicago"
  },
  "KEOP": {
      "icao": "KEOP",
      "iata": "",
      "name": "Pike County Airport",
      "city": "Waverly",
      "state": "Ohio",
      "country": "US",
      "elevation": 660,
      "lat": 39.1669006348,
      "lon": -82.9281997681,
      "tz": "America\/New_York"
  },
  "KEOS": {
      "icao": "KEOS",
      "iata": "EOS",
      "name": "Neosho Hugh Robinson Airport",
      "city": "Neosho",
      "state": "Missouri",
      "country": "US",
      "elevation": 1255,
      "lat": 36.810798645,
      "lon": -94.3917007446,
      "tz": "America\/Chicago"
  },
  "KEPH": {
      "icao": "KEPH",
      "iata": "EPH",
      "name": "Ephrata Municipal Airport",
      "city": "Ephrata",
      "state": "Washington",
      "country": "US",
      "elevation": 1276,
      "lat": 47.30759811,
      "lon": -119.5159988,
      "tz": "America\/Los_Angeles"
  },
  "KEPM": {
      "icao": "KEPM",
      "iata": "",
      "name": "Eastport Municipal Airport",
      "city": "Eastport",
      "state": "Maine",
      "country": "US",
      "elevation": 45,
      "lat": 44.9100990295,
      "lon": -67.0127029419,
      "tz": "America\/Moncton"
  },
  "KEQA": {
      "icao": "KEQA",
      "iata": "EDK",
      "name": "Captain Jack Thomas El Dorado Airport",
      "city": "El Dorado",
      "state": "Kansas",
      "country": "US",
      "elevation": 1378,
      "lat": 37.7741012573,
      "lon": -96.8175964355,
      "tz": "America\/Chicago"
  },
  "KEQY": {
      "icao": "KEQY",
      "iata": "",
      "name": "Charlotte-Monroe Executive Airport",
      "city": "Monroe",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 679,
      "lat": 35.01879883,
      "lon": -80.62020111,
      "tz": "America\/New_York"
  },
  "KERI": {
      "icao": "KERI",
      "iata": "ERI",
      "name": "Erie International Tom Ridge Field",
      "city": "Erie",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 732,
      "lat": 42.0831270134,
      "lon": -80.1738667488,
      "tz": "America\/New_York"
  },
  "KERR": {
      "icao": "KERR",
      "iata": "ERR",
      "name": "Errol Airport",
      "city": "Errol",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 1245,
      "lat": 44.7924995422,
      "lon": -71.1641998291,
      "tz": "America\/New_York"
  },
  "KERV": {
      "icao": "KERV",
      "iata": "ERV",
      "name": "Kerrville Municipal Louis Schreiner Field",
      "city": "Kerrville",
      "state": "Texas",
      "country": "US",
      "elevation": 1617,
      "lat": 29.9766998291,
      "lon": -99.0857009888,
      "tz": "America\/Chicago"
  },
  "KERY": {
      "icao": "KERY",
      "iata": "",
      "name": "Luce County Airport",
      "city": "Newberry",
      "state": "Michigan",
      "country": "US",
      "elevation": 869,
      "lat": 46.3111991882,
      "lon": -85.4572982788,
      "tz": "America\/Detroit"
  },
  "KESC": {
      "icao": "KESC",
      "iata": "ESC",
      "name": "Delta County Airport",
      "city": "Escanaba",
      "state": "Michigan",
      "country": "US",
      "elevation": 609,
      "lat": 45.7226982117,
      "lon": -87.0936965942,
      "tz": "America\/Detroit"
  },
  "KESF": {
      "icao": "KESF",
      "iata": "ESF",
      "name": "Esler Regional Airport",
      "city": "Alexandria",
      "state": "Louisiana",
      "country": "US",
      "elevation": 112,
      "lat": 31.3948993683,
      "lon": -92.2957992554,
      "tz": "America\/Chicago"
  },
  "KESN": {
      "icao": "KESN",
      "iata": "ESN",
      "name": "Easton Newnam Field",
      "city": "Easton",
      "state": "Maryland",
      "country": "US",
      "elevation": 72,
      "lat": 38.8041992188,
      "lon": -76.0690002441,
      "tz": "America\/New_York"
  },
  "KEST": {
      "icao": "KEST",
      "iata": "EST",
      "name": "Estherville Municipal Airport",
      "city": "Estherville",
      "state": "Iowa",
      "country": "US",
      "elevation": 1319,
      "lat": 43.40739822,
      "lon": -94.74639893,
      "tz": "America\/Chicago"
  },
  "KESW": {
      "icao": "KESW",
      "iata": "ESW",
      "name": "Easton State Airport",
      "city": "Easton",
      "state": "Washington",
      "country": "US",
      "elevation": 2226,
      "lat": 47.2541999817,
      "lon": -121.1859970093,
      "tz": "America\/Los_Angeles"
  },
  "KETB": {
      "icao": "KETB",
      "iata": "ETB",
      "name": "West Bend Municipal Airport",
      "city": "West Bend",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 887,
      "lat": 43.4221992493,
      "lon": -88.1278991699,
      "tz": "America\/Chicago"
  },
  "KETC": {
      "icao": "KETC",
      "iata": "",
      "name": "Tarboro Edgecombe Airport",
      "city": "Tarboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 53,
      "lat": 35.9370994568,
      "lon": -77.5466003418,
      "tz": "America\/New_York"
  },
  "KETH": {
      "icao": "KETH",
      "iata": "",
      "name": "Wheaton Municipal Airport",
      "city": "Wheaton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1025,
      "lat": 45.7804985046,
      "lon": -96.5435028076,
      "tz": "America\/Chicago"
  },
  "KETN": {
      "icao": "KETN",
      "iata": "ETN",
      "name": "Eastland Municipal Airport",
      "city": "Eastland",
      "state": "Texas",
      "country": "US",
      "elevation": 1464,
      "lat": 32.4135017395,
      "lon": -98.8097991943,
      "tz": "America\/Chicago"
  },
  "KEUF": {
      "icao": "KEUF",
      "iata": "EUF",
      "name": "Weedon Field",
      "city": "Eufaula",
      "state": "Alabama",
      "country": "US",
      "elevation": 285,
      "lat": 31.9512996674,
      "lon": -85.1288986206,
      "tz": "America\/Chicago"
  },
  "KEUG": {
      "icao": "KEUG",
      "iata": "EUG",
      "name": "Mahlon Sweet Field",
      "city": "Eugene",
      "state": "Oregon",
      "country": "US",
      "elevation": 374,
      "lat": 44.1245994568,
      "lon": -123.2119979858,
      "tz": "America\/Los_Angeles"
  },
  "KEUL": {
      "icao": "KEUL",
      "iata": "",
      "name": "Caldwell Industrial Airport",
      "city": "Caldwell",
      "state": "Idaho",
      "country": "US",
      "elevation": 2432,
      "lat": 43.64189911,
      "lon": -116.6360016,
      "tz": "America\/Boise"
  },
  "KEVB": {
      "icao": "KEVB",
      "iata": "",
      "name": "New Smyrna Beach Municipal Airport",
      "city": "New Smyrna Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 29.0557003021,
      "lon": -80.9488983154,
      "tz": "America\/New_York"
  },
  "KEVM": {
      "icao": "KEVM",
      "iata": "EVM",
      "name": "Eveleth Virginia Municipal Airport",
      "city": "Eveleth",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1379,
      "lat": 47.42509842,
      "lon": -92.49849701,
      "tz": "America\/Chicago"
  },
  "KEVU": {
      "icao": "KEVU",
      "iata": "",
      "name": "Northwest Missouri Regional Airport",
      "city": "Maryville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1145,
      "lat": 40.35250092,
      "lon": -94.91500092,
      "tz": "America\/Chicago"
  },
  "KEVV": {
      "icao": "KEVV",
      "iata": "EVV",
      "name": "Evansville Regional Airport",
      "city": "Evansville",
      "state": "Indiana",
      "country": "US",
      "elevation": 418,
      "lat": 38.0369987488,
      "lon": -87.5324020386,
      "tz": "America\/Chicago"
  },
  "KEVW": {
      "icao": "KEVW",
      "iata": "EVW",
      "name": "Evanston-Uinta County Airport-Burns Field",
      "city": "Evanston",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7143,
      "lat": 41.27479935,
      "lon": -111.0350037,
      "tz": "America\/Denver"
  },
  "KEVY": {
      "icao": "KEVY",
      "iata": "",
      "name": "Summit Airport",
      "city": "Middletown",
      "state": "Delaware",
      "country": "US",
      "elevation": 70,
      "lat": 39.520401001,
      "lon": -75.7203979492,
      "tz": "America\/New_York"
  },
  "KEWB": {
      "icao": "KEWB",
      "iata": "EWB",
      "name": "New Bedford Regional Airport",
      "city": "New Bedford",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 80,
      "lat": 41.6761016846,
      "lon": -70.9569015503,
      "tz": "America\/New_York"
  },
  "KEWK": {
      "icao": "KEWK",
      "iata": "EWK",
      "name": "Newton City-County Airport",
      "city": "Newton",
      "state": "Kansas",
      "country": "US",
      "elevation": 1533,
      "lat": 38.0582008362,
      "lon": -97.2744979858,
      "tz": "America\/Chicago"
  },
  "KEWN": {
      "icao": "KEWN",
      "iata": "EWN",
      "name": "Coastal Carolina Regional Airport",
      "city": "New Bern",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 18,
      "lat": 35.0730018616,
      "lon": -77.0429000854,
      "tz": "America\/New_York"
  },
  "KEWR": {
      "icao": "KEWR",
      "iata": "EWR",
      "name": "Newark Liberty International Airport",
      "city": "Newark",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 18,
      "lat": 40.6925010681,
      "lon": -74.1687011719,
      "tz": "America\/New_York"
  },
  "KEXX": {
      "icao": "KEXX",
      "iata": "",
      "name": "Davidson County Airport",
      "city": "Lexington",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 733,
      "lat": 35.7811012268,
      "lon": -80.3038024902,
      "tz": "America\/New_York"
  },
  "KEYE": {
      "icao": "KEYE",
      "iata": "",
      "name": "Eagle Creek Airpark",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 823,
      "lat": 39.8306999207,
      "lon": -86.2944030762,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KEYF": {
      "icao": "KEYF",
      "iata": "",
      "name": "Curtis L Brown Jr Field",
      "city": "Elizabethtown",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 131,
      "lat": 34.60179901,
      "lon": -78.57929993,
      "tz": "America\/New_York"
  },
  "KEYQ": {
      "icao": "KEYQ",
      "iata": "",
      "name": "Weiser Air Park",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 137,
      "lat": 29.9351997375,
      "lon": -95.6396026611,
      "tz": "America\/Chicago"
  },
  "KEYW": {
      "icao": "KEYW",
      "iata": "EYW",
      "name": "Key West International Airport",
      "city": "Key West",
      "state": "Florida",
      "country": "US",
      "elevation": 3,
      "lat": 24.5561008453,
      "lon": -81.7595977783,
      "tz": "America\/New_York"
  },
  "KEZF": {
      "icao": "KEZF",
      "iata": "",
      "name": "Shannon Airport",
      "city": "Fredericksburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 85,
      "lat": 38.2667999268,
      "lon": -77.4492034912,
      "tz": "America\/New_York"
  },
  "KEZI": {
      "icao": "KEZI",
      "iata": "",
      "name": "Kewanee Municipal Airport",
      "city": "Kewanee",
      "state": "Illinois",
      "country": "US",
      "elevation": 858,
      "lat": 41.2052001953,
      "lon": -89.9638977051,
      "tz": "America\/Chicago"
  },
  "KEZM": {
      "icao": "KEZM",
      "iata": "",
      "name": "Heart of Georgia Regional Airport",
      "city": "Eastman",
      "state": "Georgia",
      "country": "US",
      "elevation": 304,
      "lat": 32.2141990662,
      "lon": -83.1279983521,
      "tz": "America\/New_York"
  },
  "KEZS": {
      "icao": "KEZS",
      "iata": "",
      "name": "Shawano Municipal Airport",
      "city": "Shawano",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 813,
      "lat": 44.7869987488,
      "lon": -88.5589981079,
      "tz": "America\/Chicago"
  },
  "KEZZ": {
      "icao": "KEZZ",
      "iata": "",
      "name": "Cameron Memorial Airport",
      "city": "Cameron",
      "state": "Missouri",
      "country": "US",
      "elevation": 1040,
      "lat": 39.7276001,
      "lon": -94.27639771,
      "tz": "America\/Chicago"
  },
  "KF00": {
      "icao": "KF00",
      "iata": "",
      "name": "Jones Field",
      "city": "Bonham",
      "state": "Texas",
      "country": "US",
      "elevation": 618,
      "lat": 33.6128005981,
      "lon": -96.1792984009,
      "tz": "America\/Chicago"
  },
  "KF01": {
      "icao": "KF01",
      "iata": "",
      "name": "Quanah Municipal Airport",
      "city": "Quanah",
      "state": "Texas",
      "country": "US",
      "elevation": 1602,
      "lat": 34.2770996094,
      "lon": -99.7593002319,
      "tz": "America\/Chicago"
  },
  "KF02": {
      "icao": "KF02",
      "iata": "",
      "name": "Fairgrounds Airpark",
      "city": "Hardin",
      "state": "Montana",
      "country": "US",
      "elevation": 2911,
      "lat": 45.7249984741,
      "lon": -107.6009979248,
      "tz": "America\/Denver"
  },
  "KF05": {
      "icao": "KF05",
      "iata": "",
      "name": "Wilbarger County Airport",
      "city": "Vernon",
      "state": "Texas",
      "country": "US",
      "elevation": 1265,
      "lat": 34.2257003784,
      "lon": -99.2837982178,
      "tz": "America\/Chicago"
  },
  "KF06": {
      "icao": "KF06",
      "iata": "",
      "name": "Marian Airpark",
      "city": "Wellington",
      "state": "Texas",
      "country": "US",
      "elevation": 2008,
      "lat": 34.8456001282,
      "lon": -100.1959991455,
      "tz": "America\/Chicago"
  },
  "KF08": {
      "icao": "KF08",
      "iata": "",
      "name": "Eufaula Municipal Airport",
      "city": "Eufaula",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 635,
      "lat": 35.2958984375,
      "lon": -95.6252975464,
      "tz": "America\/Chicago"
  },
  "KF10": {
      "icao": "KF10",
      "iata": "",
      "name": "Henryetta Municipal Airport",
      "city": "Henryetta",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 849,
      "lat": 35.4068984985,
      "lon": -96.0158004761,
      "tz": "America\/Chicago"
  },
  "KF14": {
      "icao": "KF14",
      "iata": "",
      "name": "Wichita Valley Airport",
      "city": "Wichita Falls",
      "state": "Texas",
      "country": "US",
      "elevation": 1005,
      "lat": 33.9477005005,
      "lon": -98.6166992188,
      "tz": "America\/Chicago"
  },
  "KF17": {
      "icao": "KF17",
      "iata": "",
      "name": "Center Municipal Airport",
      "city": "Center",
      "state": "Texas",
      "country": "US",
      "elevation": 319,
      "lat": 31.8316001892,
      "lon": -94.1564025879,
      "tz": "America\/Chicago"
  },
  "KF21": {
      "icao": "KF21",
      "iata": "",
      "name": "Memphis Municipal Airport",
      "city": "Memphis",
      "state": "Texas",
      "country": "US",
      "elevation": 2102,
      "lat": 34.7396011353,
      "lon": -100.5299987793,
      "tz": "America\/Chicago"
  },
  "KF22": {
      "icao": "KF22",
      "iata": "",
      "name": "Perry Municipal Airport",
      "city": "Perry",
      "state": "Iowa",
      "country": "US",
      "elevation": 1002,
      "lat": 36.3856010437,
      "lon": -97.2771987915,
      "tz": "America\/Chicago"
  },
  "KF24": {
      "icao": "KF24",
      "iata": "",
      "name": "Minden Webster Airport",
      "city": "Minden",
      "state": "Louisiana",
      "country": "US",
      "elevation": 278,
      "lat": 32.6459999084,
      "lon": -93.2981033325,
      "tz": "America\/Chicago"
  },
  "KF30": {
      "icao": "KF30",
      "iata": "",
      "name": "Sulphur Municipal Airport",
      "city": "Sulphur",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1051,
      "lat": 34.5245018005,
      "lon": -96.9897003174,
      "tz": "America\/Chicago"
  },
  "KF31": {
      "icao": "KF31",
      "iata": "",
      "name": "Lake Texoma State Park Airport",
      "city": "Kingston",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 693,
      "lat": 33.9910011292,
      "lon": -96.6427993774,
      "tz": "America\/Chicago"
  },
  "KF32": {
      "icao": "KF32",
      "iata": "",
      "name": "Healdton Municipal Airport",
      "city": "Healdton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 956,
      "lat": 34.2492980957,
      "lon": -97.4738998413,
      "tz": "America\/Chicago"
  },
  "KF34": {
      "icao": "KF34",
      "iata": "",
      "name": "Firebaugh Airport",
      "city": "Firebaugh",
      "state": "California",
      "country": "US",
      "elevation": 157,
      "lat": 36.8600006104,
      "lon": -120.4639968872,
      "tz": "America\/Los_Angeles"
  },
  "KF35": {
      "icao": "KF35",
      "iata": "",
      "name": "Possum Kingdom Airport",
      "city": "Graford",
      "state": "Texas",
      "country": "US",
      "elevation": 1008,
      "lat": 32.9231987,
      "lon": -98.4364013672,
      "tz": "America\/Chicago"
  },
  "KF36": {
      "icao": "KF36",
      "iata": "",
      "name": "Cordell Municipal Airport",
      "city": "Cordell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1589,
      "lat": 35.2975997925,
      "lon": -98.9673995972,
      "tz": "America\/Chicago"
  },
  "KF37": {
      "icao": "KF37",
      "iata": "",
      "name": "Carrizozo Municipal Airport",
      "city": "Carrizozo",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5371,
      "lat": 33.6488990784,
      "lon": -105.8960037231,
      "tz": "America\/Denver"
  },
  "KF41": {
      "icao": "KF41",
      "iata": "",
      "name": "Ennis Municipal Airport",
      "city": "Ennis",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 32.32970047,
      "lon": -96.6639022827,
      "tz": "America\/Chicago"
  },
  "KF43": {
      "icao": "KF43",
      "iata": "",
      "name": "El Dorado Downtown-Stevens field",
      "city": "El Dorado",
      "state": "Arkansas",
      "country": "US",
      "elevation": 256,
      "lat": 33.19120026,
      "lon": -92.66320038,
      "tz": "America\/Chicago"
  },
  "KF44": {
      "icao": "KF44",
      "iata": "",
      "name": "Athens Municipal Airport",
      "city": "Athens",
      "state": "Texas",
      "country": "US",
      "elevation": 444,
      "lat": 32.1638478,
      "lon": -95.8283531,
      "tz": "America\/Chicago"
  },
  "KF45": {
      "icao": "KF45",
      "iata": "",
      "name": "North Palm Beach County General Aviation Airport",
      "city": "West Palm Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 22,
      "lat": 26.84440041,
      "lon": -80.22129822,
      "tz": "America\/New_York"
  },
  "KF47": {
      "icao": "KF47",
      "iata": "",
      "name": "St George Island Airport",
      "city": "Apalachicola",
      "state": "Florida",
      "country": "US",
      "elevation": 3,
      "lat": 29.6459999084,
      "lon": -84.9166030884,
      "tz": "America\/New_York"
  },
  "KF48": {
      "icao": "KF48",
      "iata": "",
      "name": "Nocona Airport",
      "city": "Nocona",
      "state": "Texas",
      "country": "US",
      "elevation": 905,
      "lat": 33.7739982605,
      "lon": -97.7380981445,
      "tz": "America\/Chicago"
  },
  "KF49": {
      "icao": "KF49",
      "iata": "",
      "name": "Slaton Municipal Airport",
      "city": "Slaton",
      "state": "Texas",
      "country": "US",
      "elevation": 3123,
      "lat": 33.4847984314,
      "lon": -101.6610031128,
      "tz": "America\/Chicago"
  },
  "KF51": {
      "icao": "KF51",
      "iata": "",
      "name": "Winnsboro Municipal Airport",
      "city": "Winnsboro",
      "state": "Texas",
      "country": "US",
      "elevation": 513,
      "lat": 32.9388008118,
      "lon": -95.2789001465,
      "tz": "America\/Chicago"
  },
  "KF53": {
      "icao": "KF53",
      "iata": "",
      "name": "Franklin County Airport",
      "city": "Mount Vernon",
      "state": "Texas",
      "country": "US",
      "elevation": 412,
      "lat": 33.2154007,
      "lon": -95.23739624,
      "tz": "America\/Chicago"
  },
  "KF56": {
      "icao": "KF56",
      "iata": "",
      "name": "Arledge Field",
      "city": "Stamford",
      "state": "Texas",
      "country": "US",
      "elevation": 1561,
      "lat": 32.9090995789,
      "lon": -99.736000061,
      "tz": "America\/Chicago"
  },
  "KF62": {
      "icao": "KF62",
      "iata": "",
      "name": "Hayfork Airport",
      "city": "Hayfork",
      "state": "California",
      "country": "US",
      "elevation": 2321,
      "lat": 40.5471000671,
      "lon": -123.1819992065,
      "tz": "America\/Los_Angeles"
  },
  "KF68": {
      "icao": "KF68",
      "iata": "",
      "name": "Anadarko Municipal Airport",
      "city": "Anadarko",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1286,
      "lat": 35.0522003174,
      "lon": -98.2642974854,
      "tz": "America\/Chicago"
  },
  "KF69": {
      "icao": "KF69",
      "iata": "",
      "name": "Air Park Dallas Airport",
      "city": "Dallas",
      "state": "Texas",
      "country": "US",
      "elevation": 695,
      "lat": 33.0234985352,
      "lon": -96.8368988037,
      "tz": "America\/Chicago"
  },
  "KF70": {
      "icao": "KF70",
      "iata": "",
      "name": "French Valley Airport",
      "city": "Murrieta\/Temecula",
      "state": "California",
      "country": "US",
      "elevation": 1350,
      "lat": 33.5741996765,
      "lon": -117.1279983521,
      "tz": "America\/Los_Angeles"
  },
  "KF72": {
      "icao": "KF72",
      "iata": "",
      "name": "Franklin Field",
      "city": "Franklin",
      "state": "California",
      "country": "US",
      "elevation": 21,
      "lat": 38.304901123,
      "lon": -121.4300003052,
      "tz": "America\/Los_Angeles"
  },
  "KF75": {
      "icao": "KF75",
      "iata": "",
      "name": "Harrison Field of Knox City Airport",
      "city": "Knox City",
      "state": "Texas",
      "country": "US",
      "elevation": 1500,
      "lat": 33.4380989075,
      "lon": -99.8128967285,
      "tz": "America\/Chicago"
  },
  "KF82": {
      "icao": "KF82",
      "iata": "",
      "name": "Town & Country Airpark",
      "city": "Lubbock",
      "state": "Texas",
      "country": "US",
      "elevation": 3200,
      "lat": 33.48559952,
      "lon": -101.8130035,
      "tz": "America\/Chicago"
  },
  "KF83": {
      "icao": "KF83",
      "iata": "",
      "name": "Abernathy Municipal Airport",
      "city": "Abernathy",
      "state": "Texas",
      "country": "US",
      "elevation": 3327,
      "lat": 33.8459014893,
      "lon": -101.7630004883,
      "tz": "America\/Chicago"
  },
  "KF84": {
      "icao": "KF84",
      "iata": "",
      "name": "Stigler Regional Airport",
      "city": "Stigler",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 599,
      "lat": 35.28910065,
      "lon": -95.09390259,
      "tz": "America\/Chicago"
  },
  "KF85": {
      "icao": "KF85",
      "iata": "",
      "name": "Cochran County Airport",
      "city": "Morton",
      "state": "Texas",
      "country": "US",
      "elevation": 3746,
      "lat": 33.7293014526,
      "lon": -102.7340011597,
      "tz": "America\/Chicago"
  },
  "KF86": {
      "icao": "KF86",
      "iata": "",
      "name": "Columbia Airport",
      "city": "Columbia",
      "state": "Louisiana",
      "country": "US",
      "elevation": 67,
      "lat": 32.12239838,
      "lon": -92.05290222,
      "tz": "America\/Chicago"
  },
  "KF87": {
      "icao": "KF87",
      "iata": "",
      "name": "Union Parish Airport",
      "city": "Farmerville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 121,
      "lat": 32.7249984741,
      "lon": -92.3371963501,
      "tz": "America\/Chicago"
  },
  "KF88": {
      "icao": "KF88",
      "iata": "",
      "name": "Jonesboro Airport",
      "city": "Jonesboro",
      "state": "Louisiana",
      "country": "US",
      "elevation": 256,
      "lat": 32.2019996643,
      "lon": -92.7329025269,
      "tz": "America\/Chicago"
  },
  "KF89": {
      "icao": "KF89",
      "iata": "",
      "name": "Winnsboro Municipal Airport",
      "city": "Winnsboro",
      "state": "Louisiana",
      "country": "US",
      "elevation": 76,
      "lat": 32.1529998779,
      "lon": -91.6985015869,
      "tz": "America\/Chicago"
  },
  "KF97": {
      "icao": "KF97",
      "iata": "",
      "name": "Seagraves Airport",
      "city": "Seagraves",
      "state": "Texas",
      "country": "US",
      "elevation": 3366,
      "lat": 32.9546012878,
      "lon": -102.5410003662,
      "tz": "America\/Chicago"
  },
  "KF98": {
      "icao": "KF98",
      "iata": "",
      "name": "Yoakum County Airport",
      "city": "Plains",
      "state": "Texas",
      "country": "US",
      "elevation": 3704,
      "lat": 33.21730042,
      "lon": -102.8300018,
      "tz": "America\/Chicago"
  },
  "KF99": {
      "icao": "KF99",
      "iata": "",
      "name": "Holdenville Municipal Airport",
      "city": "Holdenville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 861,
      "lat": 35.08589935,
      "lon": -96.41670227,
      "tz": "America\/Chicago"
  },
  "KFAF": {
      "icao": "KFAF",
      "iata": "FAF",
      "name": "Felker Army Air Field",
      "city": "Fort Eustis",
      "state": "Virginia",
      "country": "US",
      "elevation": 12,
      "lat": 37.1324996948,
      "lon": -76.6088027954,
      "tz": "America\/New_York"
  },
  "KFAM": {
      "icao": "KFAM",
      "iata": "FAM",
      "name": "Farmington Regional Airport",
      "city": "Farmington",
      "state": "Missouri",
      "country": "US",
      "elevation": 946,
      "lat": 37.76110077,
      "lon": -90.4285965,
      "tz": "America\/Chicago"
  },
  "KFAR": {
      "icao": "KFAR",
      "iata": "FAR",
      "name": "Hector International Airport",
      "city": "Fargo",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 902,
      "lat": 46.9207000732,
      "lon": -96.8158035278,
      "tz": "America\/Chicago"
  },
  "KFAT": {
      "icao": "KFAT",
      "iata": "FAT",
      "name": "Fresno Yosemite International Airport",
      "city": "Fresno",
      "state": "California",
      "country": "US",
      "elevation": 336,
      "lat": 36.7761993408,
      "lon": -119.7180023193,
      "tz": "America\/Los_Angeles"
  },
  "KFAY": {
      "icao": "KFAY",
      "iata": "FAY",
      "name": "Fayetteville Regional Grannis Field",
      "city": "Fayetteville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 189,
      "lat": 34.9911994934,
      "lon": -78.8803024292,
      "tz": "America\/New_York"
  },
  "KFBG": {
      "icao": "KFBG",
      "iata": "FBG",
      "name": "Simmons Army Air Field",
      "city": "Fort Bragg",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 244,
      "lat": 35.13180161,
      "lon": -78.93669891,
      "tz": "America\/New_York"
  },
  "KFBL": {
      "icao": "KFBL",
      "iata": "FBL",
      "name": "Faribault Municipal Airport",
      "city": "Faribault",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1060,
      "lat": 44.3246994019,
      "lon": -93.310798645,
      "tz": "America\/Chicago"
  },
  "KFBR": {
      "icao": "KFBR",
      "iata": "FBR",
      "name": "Fort Bridger Airport",
      "city": "Fort Bridger",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7034,
      "lat": 41.3918991089,
      "lon": -110.4069976807,
      "tz": "America\/Denver"
  },
  "KFBY": {
      "icao": "KFBY",
      "iata": "FBY",
      "name": "Fairbury Municipal Airport",
      "city": "Fairbury",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1479,
      "lat": 40.1829986572,
      "lon": -97.1692962646,
      "tz": "America\/Chicago"
  },
  "KFCH": {
      "icao": "KFCH",
      "iata": "FCH",
      "name": "Fresno Chandler Executive Airport",
      "city": "Fresno",
      "state": "California",
      "country": "US",
      "elevation": 279,
      "lat": 36.73239899,
      "lon": -119.8199997,
      "tz": "America\/Los_Angeles"
  },
  "KFCI": {
      "icao": "KFCI",
      "iata": "",
      "name": "Chesterfield County Airport",
      "city": "Richmond",
      "state": "Virginia",
      "country": "US",
      "elevation": 236,
      "lat": 37.40650177,
      "lon": -77.52500153,
      "tz": "America\/New_York"
  },
  "KFCM": {
      "icao": "KFCM",
      "iata": "FCM",
      "name": "Flying Cloud Airport",
      "city": "Minneapolis",
      "state": "Minnesota",
      "country": "US",
      "elevation": 906,
      "lat": 44.8272018433,
      "lon": -93.4570999146,
      "tz": "America\/Chicago"
  },
  "KFCS": {
      "icao": "KFCS",
      "iata": "FCS",
      "name": "Butts AAF (Fort Carson) Air Field",
      "city": "Fort Carson",
      "state": "Colorado",
      "country": "US",
      "elevation": 5838,
      "lat": 38.67839813,
      "lon": -104.7570038,
      "tz": "America\/Denver"
  },
  "KFCY": {
      "icao": "KFCY",
      "iata": "FCY",
      "name": "Forrest City Municipal Airport",
      "city": "Forrest City",
      "state": "Arkansas",
      "country": "US",
      "elevation": 249,
      "lat": 34.9420013428,
      "lon": -90.7750015259,
      "tz": "America\/Chicago"
  },
  "KFDK": {
      "icao": "KFDK",
      "iata": "FDK",
      "name": "Frederick Municipal Airport",
      "city": "Frederick",
      "state": "Maryland",
      "country": "US",
      "elevation": 303,
      "lat": 39.4175987244,
      "lon": -77.3742980957,
      "tz": "America\/New_York"
  },
  "KFDR": {
      "icao": "KFDR",
      "iata": "FDR",
      "name": "Frederick Regional Airport",
      "city": "Frederick",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1258,
      "lat": 34.35200119,
      "lon": -98.98390198,
      "tz": "America\/Chicago"
  },
  "KFDW": {
      "icao": "KFDW",
      "iata": "",
      "name": "Fairfield County Airport",
      "city": "Winnsboro",
      "state": "Ohio",
      "country": "US",
      "elevation": 577,
      "lat": 34.3154983521,
      "lon": -81.1088027954,
      "tz": "America\/New_York"
  },
  "KFDY": {
      "icao": "KFDY",
      "iata": "FDY",
      "name": "Findlay Airport",
      "city": "Findlay",
      "state": "Ohio",
      "country": "US",
      "elevation": 813,
      "lat": 41.0135002136,
      "lon": -83.6687011719,
      "tz": "America\/New_York"
  },
  "KFEP": {
      "icao": "KFEP",
      "iata": "FEP",
      "name": "Albertus Airport",
      "city": "Freeport",
      "state": "Illinois",
      "country": "US",
      "elevation": 859,
      "lat": 42.2462005615,
      "lon": -89.5820007324,
      "tz": "America\/Chicago"
  },
  "KFES": {
      "icao": "KFES",
      "iata": "",
      "name": "Festus Memorial Airport",
      "city": "Festus",
      "state": "Missouri",
      "country": "US",
      "elevation": 433,
      "lat": 38.1949005127,
      "lon": -90.3853988647,
      "tz": "America\/Chicago"
  },
  "KFET": {
      "icao": "KFET",
      "iata": "FET",
      "name": "Fremont Municipal Airport",
      "city": "Fremont",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1204,
      "lat": 41.44910049,
      "lon": -96.52020264,
      "tz": "America\/Chicago"
  },
  "KFFA": {
      "icao": "KFFA",
      "iata": "FFA",
      "name": "First Flight Airport",
      "city": "Kill Devil Hills",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 13,
      "lat": 36.0181999207,
      "lon": -75.6713027954,
      "tz": "America\/New_York"
  },
  "KFFC": {
      "icao": "KFFC",
      "iata": "",
      "name": "Peachtree City Falcon Field",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 808,
      "lat": 33.3572998047,
      "lon": -84.5718002319,
      "tz": "America\/New_York"
  },
  "KFFL": {
      "icao": "KFFL",
      "iata": "FFL",
      "name": "Fairfield Municipal Airport",
      "city": "Fairfield",
      "state": "Iowa",
      "country": "US",
      "elevation": 799,
      "lat": 41.0532989502,
      "lon": -91.9788970947,
      "tz": "America\/Chicago"
  },
  "KFFM": {
      "icao": "KFFM",
      "iata": "FFM",
      "name": "Fergus Falls Municipal Einar Mickelson Field",
      "city": "Fergus Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1183,
      "lat": 46.28440094,
      "lon": -96.15670013,
      "tz": "America\/Chicago"
  },
  "KFFO": {
      "icao": "KFFO",
      "iata": "FFO",
      "name": "Wright Patterson Air Force Base",
      "city": "Dayton",
      "state": "Ohio",
      "country": "US",
      "elevation": 823,
      "lat": 39.8260993958,
      "lon": -84.0483016968,
      "tz": "America\/New_York"
  },
  "KFFT": {
      "icao": "KFFT",
      "iata": "FFT",
      "name": "Capital City Airport",
      "city": "Frankfort",
      "state": "Kentucky",
      "country": "US",
      "elevation": 806,
      "lat": 38.18249893,
      "lon": -84.90470123,
      "tz": "America\/New_York"
  },
  "KFFX": {
      "icao": "KFFX",
      "iata": "",
      "name": "Fremont Municipal Airport",
      "city": "Fremont",
      "state": "Michigan",
      "country": "US",
      "elevation": 772,
      "lat": 43.43930054,
      "lon": -85.99490356,
      "tz": "America\/Detroit"
  },
  "KFFZ": {
      "icao": "KFFZ",
      "iata": "MSC",
      "name": "Falcon Field",
      "city": "Mesa",
      "state": "Alabama",
      "country": "US",
      "elevation": 1394,
      "lat": 33.4608001709,
      "lon": -111.7279968262,
      "tz": "America\/Phoenix"
  },
  "KFGX": {
      "icao": "KFGX",
      "iata": "",
      "name": "Fleming Mason Airport",
      "city": "Flemingsburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 913,
      "lat": 38.5418014526,
      "lon": -83.7434005737,
      "tz": "America\/New_York"
  },
  "KFHB": {
      "icao": "KFHB",
      "iata": "FHB",
      "name": "Fernandina Beach Municipal Airport",
      "city": "Fernandina Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 16,
      "lat": 30.6117992401,
      "lon": -81.4611968994,
      "tz": "America\/New_York"
  },
  "KFHR": {
      "icao": "KFHR",
      "iata": "FRD",
      "name": "Friday Harbor Airport",
      "city": "Friday Harbor",
      "state": "Washington",
      "country": "US",
      "elevation": 113,
      "lat": 48.5219993591,
      "lon": -123.024002075,
      "tz": "America\/Los_Angeles"
  },
  "KFHU": {
      "icao": "KFHU",
      "iata": "FHU",
      "name": "Sierra Vista Municipal Libby Army Air Field",
      "city": "Fort Huachuca Sierra Vista",
      "state": "Arizona",
      "country": "US",
      "elevation": 4719,
      "lat": 31.5884990692,
      "lon": -110.34400177,
      "tz": "America\/Phoenix"
  },
  "KFIG": {
      "icao": "KFIG",
      "iata": "",
      "name": "Clearfield Lawrence Airport",
      "city": "Clearfield",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1516,
      "lat": 41.0485992432,
      "lon": -78.4131011963,
      "tz": "America\/New_York"
  },
  "KFIT": {
      "icao": "KFIT",
      "iata": "",
      "name": "Fitchburg Municipal Airport",
      "city": "Fitchburg",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 348,
      "lat": 42.5541000366,
      "lon": -71.7590026855,
      "tz": "America\/New_York"
  },
  "KFKA": {
      "icao": "KFKA",
      "iata": "",
      "name": "Fillmore County Airport",
      "city": "Preston",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1277,
      "lat": 43.67679977,
      "lon": -92.17970276,
      "tz": "America\/Chicago"
  },
  "KFKL": {
      "icao": "KFKL",
      "iata": "FKL",
      "name": "Venango Regional Airport",
      "city": "Franklin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1540,
      "lat": 41.3778991699,
      "lon": -79.8603973389,
      "tz": "America\/New_York"
  },
  "KFKN": {
      "icao": "KFKN",
      "iata": "FKN",
      "name": "Franklin Municipal John Beverly Rose Airport",
      "city": "Franklin",
      "state": "Virginia",
      "country": "US",
      "elevation": 41,
      "lat": 36.6981010437,
      "lon": -76.9038009644,
      "tz": "America\/New_York"
  },
  "KFKR": {
      "icao": "KFKR",
      "iata": "",
      "name": "Frankfort Municipal Airport",
      "city": "Frankfort",
      "state": "Indiana",
      "country": "US",
      "elevation": 861,
      "lat": 40.273399353,
      "lon": -86.5622024536,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KFKS": {
      "icao": "KFKS",
      "iata": "",
      "name": "Frankfort Dow Memorial Field",
      "city": "Frankfort",
      "state": "Michigan",
      "country": "US",
      "elevation": 633,
      "lat": 44.6251983643,
      "lon": -86.2007980347,
      "tz": "America\/Detroit"
  },
  "KFLD": {
      "icao": "KFLD",
      "iata": "FLD",
      "name": "Fond du Lac County Airport",
      "city": "Fond du Lac",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 808,
      "lat": 43.7711982727,
      "lon": -88.4884033203,
      "tz": "America\/Chicago"
  },
  "KFLG": {
      "icao": "KFLG",
      "iata": "FLG",
      "name": "Flagstaff Pulliam Airport",
      "city": "Flagstaff",
      "state": "Arizona",
      "country": "US",
      "elevation": 7014,
      "lat": 35.13850021,
      "lon": -111.6709976,
      "tz": "America\/Phoenix"
  },
  "KFLL": {
      "icao": "KFLL",
      "iata": "FLL",
      "name": "Fort Lauderdale Hollywood International Airport",
      "city": "Fort Lauderdale",
      "state": "Florida",
      "country": "US",
      "elevation": 9,
      "lat": 26.072599411,
      "lon": -80.1527023315,
      "tz": "America\/New_York"
  },
  "KFLO": {
      "icao": "KFLO",
      "iata": "FLO",
      "name": "Florence Regional Airport",
      "city": "Florence",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 146,
      "lat": 34.1853981018,
      "lon": -79.7238998413,
      "tz": "America\/New_York"
  },
  "KFLP": {
      "icao": "KFLP",
      "iata": "FLP",
      "name": "Marion County Regional Airport",
      "city": "Flippin",
      "state": "Arkansas",
      "country": "US",
      "elevation": 719,
      "lat": 36.29090118,
      "lon": -92.59030151,
      "tz": "America\/Chicago"
  },
  "KFLV": {
      "icao": "KFLV",
      "iata": "FLV",
      "name": "Sherman Army Air Field",
      "city": "Fort Leavenworth",
      "state": "Kansas",
      "country": "US",
      "elevation": 772,
      "lat": 39.3683013916,
      "lon": -94.9147033691,
      "tz": "America\/Chicago"
  },
  "KFLX": {
      "icao": "KFLX",
      "iata": "FLX",
      "name": "Fallon Municipal Airport",
      "city": "Fallon",
      "state": "Nevada",
      "country": "US",
      "elevation": 3963,
      "lat": 39.4990997314,
      "lon": -118.7490005493,
      "tz": "America\/Los_Angeles"
  },
  "KFLY": {
      "icao": "KFLY",
      "iata": "",
      "name": "Meadow Lake Airport",
      "city": "Colorado Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 6874,
      "lat": 38.9458007812,
      "lon": -104.569999695,
      "tz": "America\/Denver"
  },
  "KFME": {
      "icao": "KFME",
      "iata": "FME",
      "name": "Tipton Airport",
      "city": "Fort Meade(Odenton)",
      "state": "Maryland",
      "country": "US",
      "elevation": 150,
      "lat": 39.0853996277,
      "lon": -76.7593994141,
      "tz": "America\/New_York"
  },
  "KFMH": {
      "icao": "KFMH",
      "iata": "FMH",
      "name": "Cape Cod Coast Guard Air Station",
      "city": "Falmouth",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 131,
      "lat": 41.6584014893,
      "lon": -70.5214004517,
      "tz": "America\/New_York"
  },
  "KFMM": {
      "icao": "KFMM",
      "iata": "",
      "name": "Fort Morgan Municipal Airport",
      "city": "Fort Morgan",
      "state": "Colorado",
      "country": "US",
      "elevation": 4569,
      "lat": 40.3335990906,
      "lon": -103.8040008545,
      "tz": "America\/Denver"
  },
  "KFMN": {
      "icao": "KFMN",
      "iata": "FMN",
      "name": "Four Corners Regional Airport",
      "city": "Farmington",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5506,
      "lat": 36.7411994934,
      "lon": -108.2300033569,
      "tz": "America\/Denver"
  },
  "KFMY": {
      "icao": "KFMY",
      "iata": "FMY",
      "name": "Page Field",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 17,
      "lat": 26.58659935,
      "lon": -81.8632965088,
      "tz": "America\/New_York"
  },
  "KFMZ": {
      "icao": "KFMZ",
      "iata": "",
      "name": "Fairmont State Airfield",
      "city": "Fairmont",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1636,
      "lat": 40.586101532,
      "lon": -97.573097229,
      "tz": "America\/Chicago"
  },
  "KFNB": {
      "icao": "KFNB",
      "iata": "",
      "name": "Brenner Field",
      "city": "Falls City",
      "state": "Nebraska",
      "country": "US",
      "elevation": 984,
      "lat": 40.0788002014,
      "lon": -95.5920028687,
      "tz": "America\/Chicago"
  },
  "KFNL": {
      "icao": "KFNL",
      "iata": "FNL",
      "name": "Fort Collins Loveland Municipal Airport",
      "city": "Fort Collins\/Loveland",
      "state": "Colorado",
      "country": "US",
      "elevation": 5016,
      "lat": 40.4518013,
      "lon": -105.011001587,
      "tz": "America\/Denver"
  },
  "KFNT": {
      "icao": "KFNT",
      "iata": "FNT",
      "name": "Bishop International Airport",
      "city": "Flint",
      "state": "Michigan",
      "country": "US",
      "elevation": 782,
      "lat": 42.9654006958,
      "lon": -83.743598938,
      "tz": "America\/Detroit"
  },
  "KFOA": {
      "icao": "KFOA",
      "iata": "",
      "name": "Flora Municipal Airport",
      "city": "Flora",
      "state": "Illinois",
      "country": "US",
      "elevation": 473,
      "lat": 38.6649017334,
      "lon": -88.4530029297,
      "tz": "America\/Chicago"
  },
  "KFOD": {
      "icao": "KFOD",
      "iata": "FOD",
      "name": "Fort Dodge Regional Airport",
      "city": "Fort Dodge",
      "state": "Iowa",
      "country": "US",
      "elevation": 1156,
      "lat": 42.55149841,
      "lon": -94.19259644,
      "tz": "America\/Chicago"
  },
  "KFOE": {
      "icao": "KFOE",
      "iata": "FOE",
      "name": "Forbes Field",
      "city": "Topeka",
      "state": "Kansas",
      "country": "US",
      "elevation": 1078,
      "lat": 38.9509010315,
      "lon": -95.6635971069,
      "tz": "America\/Chicago"
  },
  "KFOK": {
      "icao": "KFOK",
      "iata": "FOK",
      "name": "Francis S Gabreski Airport",
      "city": "Westhampton Beach",
      "state": "New-York",
      "country": "US",
      "elevation": 67,
      "lat": 40.8437004089,
      "lon": -72.6317977905,
      "tz": "America\/New_York"
  },
  "KFOM": {
      "icao": "KFOM",
      "iata": "FIL",
      "name": "Fillmore Municipal Airport",
      "city": "Fillmore",
      "state": "Utah",
      "country": "US",
      "elevation": 4985,
      "lat": 38.95830154,
      "lon": -112.362999,
      "tz": "America\/Denver"
  },
  "KFOT": {
      "icao": "KFOT",
      "iata": "",
      "name": "Rohnerville Airport",
      "city": "Fortuna",
      "state": "California",
      "country": "US",
      "elevation": 393,
      "lat": 40.55390167,
      "lon": -124.1330032,
      "tz": "America\/Los_Angeles"
  },
  "KFOZ": {
      "icao": "KFOZ",
      "iata": "",
      "name": "Bigfork Municipal Airport",
      "city": "Bigfork",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1348,
      "lat": 47.7827987671,
      "lon": -93.6501998901,
      "tz": "America\/Chicago"
  },
  "KFPK": {
      "icao": "KFPK",
      "iata": "",
      "name": "Fitch H Beach Airport",
      "city": "Charlotte",
      "state": "Michigan",
      "country": "US",
      "elevation": 891,
      "lat": 42.5745010376,
      "lon": -84.8114013672,
      "tz": "America\/Detroit"
  },
  "KFPR": {
      "icao": "KFPR",
      "iata": "FPR",
      "name": "St Lucie County International Airport",
      "city": "Fort Pierce",
      "state": "Florida",
      "country": "US",
      "elevation": 24,
      "lat": 27.49510002,
      "lon": -80.36830139,
      "tz": "America\/New_York"
  },
  "KFQD": {
      "icao": "KFQD",
      "iata": "",
      "name": "Rutherford Co Marchman Field",
      "city": "Rutherfordton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1077,
      "lat": 35.42819977,
      "lon": -81.93509674,
      "tz": "America\/New_York"
  },
  "KFRG": {
      "icao": "KFRG",
      "iata": "FRG",
      "name": "Republic Airport",
      "city": "Farmingdale",
      "state": "New-York",
      "country": "US",
      "elevation": 82,
      "lat": 40.7288017273,
      "lon": -73.4133987427,
      "tz": "America\/New_York"
  },
  "KFRH": {
      "icao": "KFRH",
      "iata": "FRH",
      "name": "French Lick Municipal Airport",
      "city": "French Lick",
      "state": "Indiana",
      "country": "US",
      "elevation": 792,
      "lat": 38.5061988831,
      "lon": -86.6369018555,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KFRI": {
      "icao": "KFRI",
      "iata": "FRI",
      "name": "Marshall Army Air Field",
      "city": "Fort Riley(Junction City)",
      "state": "Kansas",
      "country": "US",
      "elevation": 1065,
      "lat": 39.05530167,
      "lon": -96.76450348,
      "tz": "America\/Chicago"
  },
  "KFRM": {
      "icao": "KFRM",
      "iata": "FRM",
      "name": "Fairmont Municipal Airport",
      "city": "Fairmont",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1162,
      "lat": 43.643901825,
      "lon": -94.4156036377,
      "tz": "America\/Chicago"
  },
  "KFRR": {
      "icao": "KFRR",
      "iata": "FRR",
      "name": "Front Royal Warren County Airport",
      "city": "Front Royal",
      "state": "Virginia",
      "country": "US",
      "elevation": 709,
      "lat": 38.9174995422,
      "lon": -78.2535018921,
      "tz": "America\/New_York"
  },
  "KFSD": {
      "icao": "KFSD",
      "iata": "FSD",
      "name": "Joe Foss Field Airport",
      "city": "Sioux Falls",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1429,
      "lat": 43.5820007324,
      "lon": -96.741897583,
      "tz": "America\/Chicago"
  },
  "KFSE": {
      "icao": "KFSE",
      "iata": "",
      "name": "Fosston Municipal Airport",
      "city": "Fosston",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1277,
      "lat": 47.5928001404,
      "lon": -95.7734985352,
      "tz": "America\/Chicago"
  },
  "KFSI": {
      "icao": "KFSI",
      "iata": "FSI",
      "name": "Henry Post Army Air Field (Fort Sill)",
      "city": "Fort Sill",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1189,
      "lat": 34.64979935,
      "lon": -98.40219879,
      "tz": "America\/Chicago"
  },
  "KFSK": {
      "icao": "KFSK",
      "iata": "FSK",
      "name": "Fort Scott Municipal Airport",
      "city": "Fort Scott",
      "state": "Kansas",
      "country": "US",
      "elevation": 918,
      "lat": 37.7984008789,
      "lon": -94.7694015503,
      "tz": "America\/Chicago"
  },
  "KFSM": {
      "icao": "KFSM",
      "iata": "FSM",
      "name": "Fort Smith Regional Airport",
      "city": "Fort Smith",
      "state": "Arkansas",
      "country": "US",
      "elevation": 469,
      "lat": 35.3366012573,
      "lon": -94.367401123,
      "tz": "America\/Chicago"
  },
  "KFSO": {
      "icao": "KFSO",
      "iata": "",
      "name": "Franklin County State Airport",
      "city": "Highgate",
      "state": "Vermont",
      "country": "US",
      "elevation": 228,
      "lat": 44.94029999,
      "lon": -73.09750366,
      "tz": "America\/New_York"
  },
  "KFST": {
      "icao": "KFST",
      "iata": "FST",
      "name": "Fort Stockton Pecos County Airport",
      "city": "Fort Stockton",
      "state": "Texas",
      "country": "US",
      "elevation": 3011,
      "lat": 30.9157009125,
      "lon": -102.9160003662,
      "tz": "America\/Chicago"
  },
  "KFSU": {
      "icao": "KFSU",
      "iata": "FSU",
      "name": "Fort Sumner Municipal Airport",
      "city": "Fort Sumner",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4165,
      "lat": 34.4833984375,
      "lon": -104.2170028687,
      "tz": "America\/Denver"
  },
  "KFSW": {
      "icao": "KFSW",
      "iata": "FMS",
      "name": "Fort Madison Municipal Airport",
      "city": "Fort Madison",
      "state": "Iowa",
      "country": "US",
      "elevation": 724,
      "lat": 40.6593017578,
      "lon": -91.3267974854,
      "tz": "America\/Chicago"
  },
  "KFTG": {
      "icao": "KFTG",
      "iata": "",
      "name": "Front Range Airport",
      "city": "Denver",
      "state": "Colorado",
      "country": "US",
      "elevation": 5512,
      "lat": 39.7853012085,
      "lon": -104.5429992676,
      "tz": "America\/Denver"
  },
  "KFTK": {
      "icao": "KFTK",
      "iata": "FTK",
      "name": "Godman Army Air Field",
      "city": "Fort Knox",
      "state": "Kentucky",
      "country": "US",
      "elevation": 756,
      "lat": 37.9071006775,
      "lon": -85.9720993042,
      "tz": "America\/New_York"
  },
  "KFTT": {
      "icao": "KFTT",
      "iata": "",
      "name": "Elton Hensley Memorial Airport",
      "city": "Fulton",
      "state": "Missouri",
      "country": "US",
      "elevation": 886,
      "lat": 38.8381004333,
      "lon": -92.0026016235,
      "tz": "America\/Chicago"
  },
  "KFTW": {
      "icao": "KFTW",
      "iata": "FTW",
      "name": "Fort Worth Meacham International Airport",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 32.8198013306,
      "lon": -97.3623962402,
      "tz": "America\/Chicago"
  },
  "KFTY": {
      "icao": "KFTY",
      "iata": "FTY",
      "name": "Fulton County Airport Brown Field",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 841,
      "lat": 33.7790985107,
      "lon": -84.5214004517,
      "tz": "America\/New_York"
  },
  "KFUL": {
      "icao": "KFUL",
      "iata": "FUL",
      "name": "Fullerton Municipal Airport",
      "city": "Fullerton",
      "state": "California",
      "country": "US",
      "elevation": 96,
      "lat": 33.8720016479,
      "lon": -117.980003357,
      "tz": "America\/Los_Angeles"
  },
  "KFVE": {
      "icao": "KFVE",
      "iata": "WFK",
      "name": "Northern Aroostook Regional Airport",
      "city": "Frenchville",
      "state": "Maine",
      "country": "US",
      "elevation": 988,
      "lat": 47.2854995728,
      "lon": -68.3127975464,
      "tz": "America\/New_York"
  },
  "KFVX": {
      "icao": "KFVX",
      "iata": "",
      "name": "Farmville Regional Airport",
      "city": "Farmville",
      "state": "Virginia",
      "country": "US",
      "elevation": 416,
      "lat": 37.35749817,
      "lon": -78.43779755,
      "tz": "America\/New_York"
  },
  "KFWA": {
      "icao": "KFWA",
      "iata": "FWA",
      "name": "Fort Wayne International Airport",
      "city": "Fort Wayne",
      "state": "Indiana",
      "country": "US",
      "elevation": 814,
      "lat": 40.97850037,
      "lon": -85.19509888,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KFWB": {
      "icao": "KFWB",
      "iata": "",
      "name": "Branson West Airport",
      "city": "Branson West",
      "state": "Missouri",
      "country": "US",
      "elevation": 1348,
      "lat": 36.698497,
      "lon": -93.402249,
      "tz": "America\/Chicago"
  },
  "KFWC": {
      "icao": "KFWC",
      "iata": "",
      "name": "Fairfield Municipal Airport",
      "city": "Fairfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 436,
      "lat": 38.3786010742,
      "lon": -88.4126968384,
      "tz": "America\/Chicago"
  },
  "KFWN": {
      "icao": "KFWN",
      "iata": "",
      "name": "Sussex Airport",
      "city": "Sussex",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 421,
      "lat": 41.2001991272,
      "lon": -74.6230010986,
      "tz": "America\/New_York"
  },
  "KFWQ": {
      "icao": "KFWQ",
      "iata": "",
      "name": "Rostraver Airport",
      "city": "Monongahela",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1228,
      "lat": 40.2097015381,
      "lon": -79.8313980103,
      "tz": "America\/New_York"
  },
  "KFWS": {
      "icao": "KFWS",
      "iata": "",
      "name": "Fort Worth Spinks Airport",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.5652008057,
      "lon": -97.3080978394,
      "tz": "America\/Chicago"
  },
  "KFXE": {
      "icao": "KFXE",
      "iata": "FXE",
      "name": "Fort Lauderdale Executive Airport",
      "city": "Fort Lauderdale",
      "state": "Florida",
      "country": "US",
      "elevation": 13,
      "lat": 26.1972999573,
      "lon": -80.1707000732,
      "tz": "America\/New_York"
  },
  "KFXY": {
      "icao": "KFXY",
      "iata": "FXY",
      "name": "Forest City Municipal Airport",
      "city": "Forest City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1229,
      "lat": 43.23469925,
      "lon": -93.62409973,
      "tz": "America\/Chicago"
  },
  "KFYE": {
      "icao": "KFYE",
      "iata": "",
      "name": "Fayette County Airport",
      "city": "Somerville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 436,
      "lat": 35.207698822,
      "lon": -89.3945007324,
      "tz": "America\/Chicago"
  },
  "KFYJ": {
      "icao": "KFYJ",
      "iata": "",
      "name": "Middle Peninsula Regional Airport",
      "city": "West Point",
      "state": "Virginia",
      "country": "US",
      "elevation": 20,
      "lat": 37.52119827,
      "lon": -76.76470184,
      "tz": "America\/New_York"
  },
  "KFYM": {
      "icao": "KFYM",
      "iata": "FYM",
      "name": "Fayetteville Municipal Airport",
      "city": "Fayetteville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 984,
      "lat": 35.0597000122,
      "lon": -86.5640029907,
      "tz": "America\/Chicago"
  },
  "KFYV": {
      "icao": "KFYV",
      "iata": "FYV",
      "name": "Drake Field",
      "city": "Fayetteville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1251,
      "lat": 36.0051002502,
      "lon": -94.1700973511,
      "tz": "America\/Chicago"
  },
  "KFZG": {
      "icao": "KFZG",
      "iata": "",
      "name": "Fitzgerald Municipal Airport",
      "city": "Fitzgerald",
      "state": "Georgia",
      "country": "US",
      "elevation": 365,
      "lat": 31.6837005615,
      "lon": -83.2705001831,
      "tz": "America\/New_York"
  },
  "KFZI": {
      "icao": "KFZI",
      "iata": "",
      "name": "Fostoria Metropolitan Airport",
      "city": "Fostoria",
      "state": "Ohio",
      "country": "US",
      "elevation": 752,
      "lat": 41.19079971,
      "lon": -83.39450073,
      "tz": "America\/New_York"
  },
  "KFZY": {
      "icao": "KFZY",
      "iata": "",
      "name": "Oswego County Airport",
      "city": "Fulton",
      "state": "New-York",
      "country": "US",
      "elevation": 475,
      "lat": 43.3507995605,
      "lon": -76.3880996704,
      "tz": "America\/New_York"
  },
  "KGAB": {
      "icao": "KGAB",
      "iata": "GAB",
      "name": "Gabbs Airport",
      "city": "Gabbs",
      "state": "Nevada",
      "country": "US",
      "elevation": 4700,
      "lat": 38.9240989685,
      "lon": -117.958999634,
      "tz": "America\/Los_Angeles"
  },
  "KGAD": {
      "icao": "KGAD",
      "iata": "GAD",
      "name": "Northeast Alabama Regional Airport",
      "city": "Gadsden",
      "state": "Alabama",
      "country": "US",
      "elevation": 569,
      "lat": 33.9725990295,
      "lon": -86.0889968872,
      "tz": "America\/Chicago"
  },
  "KGAF": {
      "icao": "KGAF",
      "iata": "",
      "name": "Hutson Field",
      "city": "Grafton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 824,
      "lat": 48.4047012329,
      "lon": -97.3709030151,
      "tz": "America\/Chicago"
  },
  "KGAG": {
      "icao": "KGAG",
      "iata": "GAG",
      "name": "Gage Airport",
      "city": "Gage",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2223,
      "lat": 36.295501709,
      "lon": -99.7763977051,
      "tz": "America\/Chicago"
  },
  "KGAI": {
      "icao": "KGAI",
      "iata": "GAI",
      "name": "Montgomery County Airpark",
      "city": "Gaithersburg",
      "state": "Maryland",
      "country": "US",
      "elevation": 539,
      "lat": 39.1683006287,
      "lon": -77.1660003662,
      "tz": "America\/New_York"
  },
  "KGAO": {
      "icao": "KGAO",
      "iata": "",
      "name": "South Lafourche Leonard Miller Jr Airport",
      "city": "Galliano",
      "state": "Louisiana",
      "country": "US",
      "elevation": 1,
      "lat": 29.44479942,
      "lon": -90.26110077,
      "tz": "America\/Chicago"
  },
  "KGAS": {
      "icao": "KGAS",
      "iata": "",
      "name": "Gallia Meigs Regional Airport",
      "city": "Gallipolis",
      "state": "Ohio",
      "country": "US",
      "elevation": 566,
      "lat": 38.8340988159,
      "lon": -82.1633987427,
      "tz": "America\/New_York"
  },
  "KGBD": {
      "icao": "KGBD",
      "iata": "GBD",
      "name": "Great Bend Municipal Airport",
      "city": "Great Bend",
      "state": "Kansas",
      "country": "US",
      "elevation": 1887,
      "lat": 38.3442993164,
      "lon": -98.8591995239,
      "tz": "America\/Chicago"
  },
  "KGBG": {
      "icao": "KGBG",
      "iata": "GBG",
      "name": "Galesburg Municipal Airport",
      "city": "Galesburg",
      "state": "Illinois",
      "country": "US",
      "elevation": 764,
      "lat": 40.9379997253,
      "lon": -90.431098938,
      "tz": "America\/Chicago"
  },
  "KGBN": {
      "icao": "KGBN",
      "iata": "",
      "name": "Gila Bend Af Aux Airport",
      "city": "Gila Bend",
      "state": "Arizona",
      "country": "US",
      "elevation": 858,
      "lat": 32.88750076,
      "lon": -112.7200012,
      "tz": "America\/Phoenix"
  },
  "KGBR": {
      "icao": "KGBR",
      "iata": "GBR",
      "name": "Walter J. Koladza Airport",
      "city": "Great Barrington",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 739,
      "lat": 42.18420029,
      "lon": -73.40319824,
      "tz": "America\/New_York"
  },
  "KGCC": {
      "icao": "KGCC",
      "iata": "GCC",
      "name": "Gillette Campbell County Airport",
      "city": "Gillette",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4365,
      "lat": 44.3488998413,
      "lon": -105.5390014648,
      "tz": "America\/Denver"
  },
  "KGCD": {
      "icao": "KGCD",
      "iata": "JDA",
      "name": "Grant Co Regional\/Ogilvie Field",
      "city": "John Day",
      "state": "Oregon",
      "country": "US",
      "elevation": 3703,
      "lat": 44.40420151,
      "lon": -118.9629974,
      "tz": "America\/Los_Angeles"
  },
  "KGCK": {
      "icao": "KGCK",
      "iata": "GCK",
      "name": "Garden City Regional Airport",
      "city": "Garden City",
      "state": "Kansas",
      "country": "US",
      "elevation": 2891,
      "lat": 37.9275016785,
      "lon": -100.7239990234,
      "tz": "America\/Chicago"
  },
  "KGCM": {
      "icao": "KGCM",
      "iata": "",
      "name": "Claremore Regional Airport",
      "city": "Claremore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 733,
      "lat": 36.2927017212,
      "lon": -95.479598999,
      "tz": "America\/Chicago"
  },
  "KGCN": {
      "icao": "KGCN",
      "iata": "GCN",
      "name": "Grand Canyon National Park Airport",
      "city": "Grand Canyon",
      "state": "Arizona",
      "country": "US",
      "elevation": 6609,
      "lat": 35.9524002075,
      "lon": -112.1470031738,
      "tz": "America\/Phoenix"
  },
  "KGCT": {
      "icao": "KGCT",
      "iata": "",
      "name": "Guthrie County Regional Airport",
      "city": "Guthrie Center",
      "state": "Iowa",
      "country": "US",
      "elevation": 1230,
      "lat": 41.6878013611,
      "lon": -94.4352035522,
      "tz": "America\/Chicago"
  },
  "KGCY": {
      "icao": "KGCY",
      "iata": "GCY",
      "name": "Greeneville Greene County Municipal Airport",
      "city": "Greeneville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1608,
      "lat": 36.19300079,
      "lon": -82.81510162,
      "tz": "America\/New_York"
  },
  "KGDB": {
      "icao": "KGDB",
      "iata": "",
      "name": "Granite Falls Municipal-Lenzen-Roe Memorial Field",
      "city": "Granite Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1047,
      "lat": 44.75329971,
      "lon": -95.55599976,
      "tz": "America\/Chicago"
  },
  "KGDJ": {
      "icao": "KGDJ",
      "iata": "",
      "name": "Granbury Regional Airport",
      "city": "Granbury",
      "state": "Texas",
      "country": "US",
      "elevation": 778,
      "lat": 32.44440079,
      "lon": -97.81690216,
      "tz": "America\/Chicago"
  },
  "KGDM": {
      "icao": "KGDM",
      "iata": "GDM",
      "name": "Gardner Municipal Airport",
      "city": "Gardner",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 955,
      "lat": 42.5499992371,
      "lon": -72.0160980225,
      "tz": "America\/New_York"
  },
  "KGDV": {
      "icao": "KGDV",
      "iata": "GDV",
      "name": "Dawson Community Airport",
      "city": "Glendive",
      "state": "Montana",
      "country": "US",
      "elevation": 2458,
      "lat": 47.13869858,
      "lon": -104.8069992,
      "tz": "America\/Denver"
  },
  "KGDW": {
      "icao": "KGDW",
      "iata": "GDW",
      "name": "Gladwin Zettel Memorial Airport",
      "city": "Gladwin",
      "state": "Michigan",
      "country": "US",
      "elevation": 776,
      "lat": 43.9706001282,
      "lon": -84.4749984741,
      "tz": "America\/Detroit"
  },
  "KGDY": {
      "icao": "KGDY",
      "iata": "GDY",
      "name": "Grundy Municipal Airport",
      "city": "Grundy",
      "state": "Virginia",
      "country": "US",
      "elevation": 2304,
      "lat": 37.2323989868,
      "lon": -82.125,
      "tz": "America\/New_York"
  },
  "KGED": {
      "icao": "KGED",
      "iata": "GED",
      "name": "Sussex County Airport",
      "city": "Georgetown",
      "state": "Delaware",
      "country": "US",
      "elevation": 53,
      "lat": 38.68920135,
      "lon": -75.35890198,
      "tz": "America\/New_York"
  },
  "KGEG": {
      "icao": "KGEG",
      "iata": "GEG",
      "name": "Spokane International Airport",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2376,
      "lat": 47.6198997498,
      "lon": -117.533996582,
      "tz": "America\/Los_Angeles"
  },
  "KGEO": {
      "icao": "KGEO",
      "iata": "",
      "name": "Brown County Airport",
      "city": "Georgetown",
      "state": "Ohio",
      "country": "US",
      "elevation": 958,
      "lat": 38.8819007874,
      "lon": -83.8826980591,
      "tz": "America\/New_York"
  },
  "KGEU": {
      "icao": "KGEU",
      "iata": "",
      "name": "Glendale Municipal Airport",
      "city": "Glendale",
      "state": "Arizona",
      "country": "US",
      "elevation": 1071,
      "lat": 33.5269012451,
      "lon": -112.2949981689,
      "tz": "America\/Phoenix"
  },
  "KGEV": {
      "icao": "KGEV",
      "iata": "",
      "name": "Ashe County Airport",
      "city": "Jefferson",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 3180,
      "lat": 36.4323997498,
      "lon": -81.4197006226,
      "tz": "America\/New_York"
  },
  "KGEY": {
      "icao": "KGEY",
      "iata": "GEY",
      "name": "South Big Horn County Airport",
      "city": "Greybull",
      "state": "Wyoming",
      "country": "US",
      "elevation": 3939,
      "lat": 44.51679993,
      "lon": -108.0830002,
      "tz": "America\/Denver"
  },
  "KGEZ": {
      "icao": "KGEZ",
      "iata": "",
      "name": "Shelbyville Municipal Airport",
      "city": "Shelbyville",
      "state": "Indiana",
      "country": "US",
      "elevation": 803,
      "lat": 39.58319855,
      "lon": -85.80480194,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KGFK": {
      "icao": "KGFK",
      "iata": "GFK",
      "name": "Grand Forks International Airport",
      "city": "Grand Forks",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 845,
      "lat": 47.9492988586,
      "lon": -97.1761016846,
      "tz": "America\/Chicago"
  },
  "KGFL": {
      "icao": "KGFL",
      "iata": "GFL",
      "name": "Floyd Bennett Memorial Airport",
      "city": "Glens Falls",
      "state": "New-York",
      "country": "US",
      "elevation": 328,
      "lat": 43.3412017822,
      "lon": -73.6102981567,
      "tz": "America\/New_York"
  },
  "KGFZ": {
      "icao": "KGFZ",
      "iata": "",
      "name": "Greenfield Municipal Airport",
      "city": "Greenfield",
      "state": "Iowa",
      "country": "US",
      "elevation": 1364,
      "lat": 41.32699966,
      "lon": -94.4457016,
      "tz": "America\/Chicago"
  },
  "KGGE": {
      "icao": "KGGE",
      "iata": "GGE",
      "name": "Georgetown County Airport",
      "city": "Georgetown",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 39,
      "lat": 33.3116989136,
      "lon": -79.3196029663,
      "tz": "America\/New_York"
  },
  "KGGF": {
      "icao": "KGGF",
      "iata": "",
      "name": "Grant Municipal Airport",
      "city": "Grant",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3425,
      "lat": 40.86949921,
      "lon": -101.7330017,
      "tz": "America\/Denver"
  },
  "KGGG": {
      "icao": "KGGG",
      "iata": "GGG",
      "name": "East Texas Regional Airport",
      "city": "Longview",
      "state": "Texas",
      "country": "US",
      "elevation": 365,
      "lat": 32.3839988708,
      "lon": -94.7115020752,
      "tz": "America\/Chicago"
  },
  "KGGI": {
      "icao": "KGGI",
      "iata": "",
      "name": "Grinnell Regional Airport",
      "city": "Grinnell",
      "state": "Iowa",
      "country": "US",
      "elevation": 1008,
      "lat": 41.7092018127,
      "lon": -92.7349014282,
      "tz": "America\/Chicago"
  },
  "KGGP": {
      "icao": "KGGP",
      "iata": "",
      "name": "Logansport Cass County Airport",
      "city": "Logansport",
      "state": "Indiana",
      "country": "US",
      "elevation": 738,
      "lat": 40.7112007141,
      "lon": -86.3749008179,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KGGW": {
      "icao": "KGGW",
      "iata": "GGW",
      "name": "Wokal Field Glasgow International Airport",
      "city": "Glasgow",
      "state": "Montana",
      "country": "US",
      "elevation": 2296,
      "lat": 48.2125015259,
      "lon": -106.6149978638,
      "tz": "America\/Denver"
  },
  "KGHG": {
      "icao": "KGHG",
      "iata": "",
      "name": "Marshfield Municipal George Harlow Field",
      "city": "Marshfield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 11,
      "lat": 42.0983009338,
      "lon": -70.672203064,
      "tz": "America\/New_York"
  },
  "KGHM": {
      "icao": "KGHM",
      "iata": "GHM",
      "name": "Centerville Municipal Airport",
      "city": "Centerville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 768,
      "lat": 35.8373985291,
      "lon": -87.4453964233,
      "tz": "America\/Chicago"
  },
  "KGHW": {
      "icao": "KGHW",
      "iata": "",
      "name": "Glenwood Municipal Airport",
      "city": "Glenwood",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1393,
      "lat": 45.643901825,
      "lon": -95.3203964233,
      "tz": "America\/Chicago"
  },
  "KGIC": {
      "icao": "KGIC",
      "iata": "IDH",
      "name": "Idaho County Airport",
      "city": "Grangeville",
      "state": "Idaho",
      "country": "US",
      "elevation": 3314,
      "lat": 45.94260025,
      "lon": -116.1230011,
      "tz": "America\/Los_Angeles"
  },
  "KGIF": {
      "icao": "KGIF",
      "iata": "GIF",
      "name": "Winter Haven's Gilbert Airport",
      "city": "Winter Haven",
      "state": "Florida",
      "country": "US",
      "elevation": 145,
      "lat": 28.06290054,
      "lon": -81.75330353,
      "tz": "America\/New_York"
  },
  "KGJT": {
      "icao": "KGJT",
      "iata": "GJT",
      "name": "Grand Junction Regional Airport",
      "city": "Grand Junction",
      "state": "Colorado",
      "country": "US",
      "elevation": 4858,
      "lat": 39.1223983765,
      "lon": -108.527000427,
      "tz": "America\/Denver"
  },
  "KGKJ": {
      "icao": "KGKJ",
      "iata": "MEJ",
      "name": "Port Meadville Airport",
      "city": "Meadville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1399,
      "lat": 41.62649918,
      "lon": -80.21469879,
      "tz": "America\/New_York"
  },
  "KGKT": {
      "icao": "KGKT",
      "iata": "GKT",
      "name": "Gatlinburg Pigeon Forge Airport",
      "city": "Sevierville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1014,
      "lat": 35.85779953,
      "lon": -83.5287017822,
      "tz": "America\/New_York"
  },
  "KGKY": {
      "icao": "KGKY",
      "iata": "",
      "name": "Arlington Municipal Airport",
      "city": "Arlington",
      "state": "Oregon",
      "country": "US",
      "elevation": 628,
      "lat": 32.663898468,
      "lon": -97.0942993164,
      "tz": "America\/Chicago"
  },
  "KGLD": {
      "icao": "KGLD",
      "iata": "GLD",
      "name": "Renner Field-Goodland Municipal Airport",
      "city": "Goodland",
      "state": "Kansas",
      "country": "US",
      "elevation": 3656,
      "lat": 39.37060165,
      "lon": -101.6989975,
      "tz": "America\/Denver"
  },
  "KGLE": {
      "icao": "KGLE",
      "iata": "GLE",
      "name": "Gainesville Municipal Airport",
      "city": "Gainesville",
      "state": "Texas",
      "country": "US",
      "elevation": 845,
      "lat": 33.6514015198,
      "lon": -97.1969985962,
      "tz": "America\/Chicago"
  },
  "KGLH": {
      "icao": "KGLH",
      "iata": "GLH",
      "name": "Mid Delta Regional Airport",
      "city": "Greenville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 131,
      "lat": 33.4828987122,
      "lon": -90.9856033325,
      "tz": "America\/Chicago"
  },
  "KGLR": {
      "icao": "KGLR",
      "iata": "GLR",
      "name": "Gaylord Regional Airport",
      "city": "Gaylord",
      "state": "Michigan",
      "country": "US",
      "elevation": 1328,
      "lat": 45.0135002136,
      "lon": -84.7035980225,
      "tz": "America\/Detroit"
  },
  "KGLS": {
      "icao": "KGLS",
      "iata": "GLS",
      "name": "Scholes International At Galveston Airport",
      "city": "Galveston",
      "state": "Texas",
      "country": "US",
      "elevation": 6,
      "lat": 29.2653007507,
      "lon": -94.8603973389,
      "tz": "America\/Chicago"
  },
  "KGLW": {
      "icao": "KGLW",
      "iata": "GLW",
      "name": "Glasgow Municipal Airport",
      "city": "Glasgow",
      "state": "Kentucky",
      "country": "US",
      "elevation": 716,
      "lat": 37.03179932,
      "lon": -85.9536972,
      "tz": "America\/Chicago"
  },
  "KGLY": {
      "icao": "KGLY",
      "iata": "",
      "name": "Clinton Memorial Airport",
      "city": "Clinton",
      "state": "Missouri",
      "country": "US",
      "elevation": 822,
      "lat": 38.3566017151,
      "lon": -93.6841964722,
      "tz": "America\/Chicago"
  },
  "KGMJ": {
      "icao": "KGMJ",
      "iata": "",
      "name": "Grove Municipal Airport",
      "city": "Grove",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 831,
      "lat": 36.60680008,
      "lon": -94.73860168,
      "tz": "America\/Chicago"
  },
  "KGMU": {
      "icao": "KGMU",
      "iata": "GMU",
      "name": "Greenville Downtown Airport",
      "city": "Greenville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1048,
      "lat": 34.8479003906,
      "lon": -82.3499984741,
      "tz": "America\/New_York"
  },
  "KGNB": {
      "icao": "KGNB",
      "iata": "",
      "name": "Granby Grand County Airport",
      "city": "Granby",
      "state": "Colorado",
      "country": "US",
      "elevation": 8203,
      "lat": 40.0896987915,
      "lon": -105.9169998169,
      "tz": "America\/Denver"
  },
  "KGNC": {
      "icao": "KGNC",
      "iata": "",
      "name": "Gaines County Airport",
      "city": "Seminole",
      "state": "Texas",
      "country": "US",
      "elevation": 3315,
      "lat": 32.6753005981,
      "lon": -102.6529998779,
      "tz": "America\/Chicago"
  },
  "KGNF": {
      "icao": "KGNF",
      "iata": "",
      "name": "Grenada Municipal Airport",
      "city": "Grenada",
      "state": "Mississippi",
      "country": "US",
      "elevation": 208,
      "lat": 33.8325004578,
      "lon": -89.7982025146,
      "tz": "America\/Chicago"
  },
  "KGNG": {
      "icao": "KGNG",
      "iata": "GNG",
      "name": "Gooding Municipal Airport",
      "city": "Gooding",
      "state": "Idaho",
      "country": "US",
      "elevation": 3732,
      "lat": 42.91719818,
      "lon": -114.7649994,
      "tz": "America\/Boise"
  },
  "KGNT": {
      "icao": "KGNT",
      "iata": "GNT",
      "name": "Grants Milan Municipal Airport",
      "city": "Grants",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6537,
      "lat": 35.167301178,
      "lon": -107.9020004272,
      "tz": "America\/Denver"
  },
  "KGNV": {
      "icao": "KGNV",
      "iata": "GNV",
      "name": "Gainesville Regional Airport",
      "city": "Gainesville",
      "state": "Florida",
      "country": "US",
      "elevation": 152,
      "lat": 29.6900997162,
      "lon": -82.2717971802,
      "tz": "America\/New_York"
  },
  "KGOK": {
      "icao": "KGOK",
      "iata": "GOK",
      "name": "Guthrie-Edmond Regional Airport",
      "city": "Guthrie",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1069,
      "lat": 35.84980011,
      "lon": -97.41560364,
      "tz": "America\/Chicago"
  },
  "KGON": {
      "icao": "KGON",
      "iata": "GON",
      "name": "Groton New London Airport",
      "city": "Groton (New London)",
      "state": "Connecticut",
      "country": "US",
      "elevation": 9,
      "lat": 41.3301010132,
      "lon": -72.0450973511,
      "tz": "America\/New_York"
  },
  "KGOO": {
      "icao": "KGOO",
      "iata": "",
      "name": "Nevada County Air Park",
      "city": "Grass Valley",
      "state": "California",
      "country": "US",
      "elevation": 3152,
      "lat": 39.2239990234,
      "lon": -121.0029983521,
      "tz": "America\/Los_Angeles"
  },
  "KGOP": {
      "icao": "KGOP",
      "iata": "",
      "name": "Gatesville Municipal Airport",
      "city": "Gatesville",
      "state": "Texas",
      "country": "US",
      "elevation": 905,
      "lat": 31.42130089,
      "lon": -97.79699707,
      "tz": "America\/Chicago"
  },
  "KGOV": {
      "icao": "KGOV",
      "iata": "",
      "name": "Grayling Army Air Field",
      "city": "Grayling",
      "state": "Michigan",
      "country": "US",
      "elevation": 1158,
      "lat": 44.6803016663,
      "lon": -84.7288970947,
      "tz": "America\/Detroit"
  },
  "KGPH": {
      "icao": "KGPH",
      "iata": "",
      "name": "Midwest National Air Center Airport",
      "city": "Mosby",
      "state": "Missouri",
      "country": "US",
      "elevation": 777,
      "lat": 39.33250046,
      "lon": -94.30960083,
      "tz": "America\/Chicago"
  },
  "KGPI": {
      "icao": "KGPI",
      "iata": "FCA",
      "name": "Glacier Park International Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2977,
      "lat": 48.3105010986,
      "lon": -114.2559967041,
      "tz": "America\/Denver"
  },
  "KGPM": {
      "icao": "KGPM",
      "iata": "",
      "name": "Grand Prairie Municipal Airport",
      "city": "Grand Prairie",
      "state": "Texas",
      "country": "US",
      "elevation": 588,
      "lat": 32.6987991333,
      "lon": -97.0468978882,
      "tz": "America\/Chicago"
  },
  "KGPT": {
      "icao": "KGPT",
      "iata": "GPT",
      "name": "Gulfport Biloxi International Airport",
      "city": "Gulfport",
      "state": "Mississippi",
      "country": "US",
      "elevation": 28,
      "lat": 30.4073009491,
      "lon": -89.070098877,
      "tz": "America\/Chicago"
  },
  "KGPZ": {
      "icao": "KGPZ",
      "iata": "GPZ",
      "name": "Grand Rapids Itasca Co-Gordon Newstrom field",
      "city": "Grand Rapids",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1355,
      "lat": 47.21110153,
      "lon": -93.50980377,
      "tz": "America\/Chicago"
  },
  "KGQQ": {
      "icao": "KGQQ",
      "iata": "GQQ",
      "name": "Galion Municipal Airport",
      "city": "Galion",
      "state": "Ohio",
      "country": "US",
      "elevation": 1224,
      "lat": 40.7533988953,
      "lon": -82.7238006592,
      "tz": "America\/New_York"
  },
  "KGRB": {
      "icao": "KGRB",
      "iata": "GRB",
      "name": "Austin Straubel International Airport",
      "city": "Green Bay",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 695,
      "lat": 44.4850997925,
      "lon": -88.1296005249,
      "tz": "America\/Chicago"
  },
  "KGRD": {
      "icao": "KGRD",
      "iata": "GRD",
      "name": "Greenwood County Airport",
      "city": "Greenwood",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 631,
      "lat": 34.2486991882,
      "lon": -82.1591033936,
      "tz": "America\/New_York"
  },
  "KGRE": {
      "icao": "KGRE",
      "iata": "GRE",
      "name": "Greenville Airport",
      "city": "Greenville",
      "state": "Illinois",
      "country": "US",
      "elevation": 541,
      "lat": 38.8362007141,
      "lon": -89.37840271,
      "tz": "America\/Chicago"
  },
  "KGRF": {
      "icao": "KGRF",
      "iata": "GRF",
      "name": "Gray Army Air Field",
      "city": "Fort Lewis\/Tacoma",
      "state": "Washington",
      "country": "US",
      "elevation": 300,
      "lat": 47.07920074,
      "lon": -122.5810013,
      "tz": "America\/Los_Angeles"
  },
  "KGRI": {
      "icao": "KGRI",
      "iata": "GRI",
      "name": "Central Nebraska Regional Airport",
      "city": "Grand Island",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1847,
      "lat": 40.9674987793,
      "lon": -98.3096008301,
      "tz": "America\/Chicago"
  },
  "KGRK": {
      "icao": "KGRK",
      "iata": "GRK",
      "name": "Robert Gray  Army Air Field Airport",
      "city": "Fort Hood\/Killeen",
      "state": "Texas",
      "country": "US",
      "elevation": 1015,
      "lat": 31.067199707,
      "lon": -97.8289031982,
      "tz": "America\/Chicago"
  },
  "KGRN": {
      "icao": "KGRN",
      "iata": "GRN",
      "name": "Gordon Municipal Airport",
      "city": "Gordon",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3562,
      "lat": 42.8059997559,
      "lon": -102.1750030518,
      "tz": "America\/Denver"
  },
  "KGRR": {
      "icao": "KGRR",
      "iata": "GRR",
      "name": "Gerald R. Ford International Airport",
      "city": "Grand Rapids",
      "state": "Michigan",
      "country": "US",
      "elevation": 794,
      "lat": 42.88079834,
      "lon": -85.52279663,
      "tz": "America\/Detroit"
  },
  "KGSB": {
      "icao": "KGSB",
      "iata": "GSB",
      "name": "Seymour Johnson Air Force Base",
      "city": "Goldsboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 109,
      "lat": 35.33940125,
      "lon": -77.96060181,
      "tz": "America\/New_York"
  },
  "KGSH": {
      "icao": "KGSH",
      "iata": "GSH",
      "name": "Goshen Municipal Airport",
      "city": "Goshen",
      "state": "Indiana",
      "country": "US",
      "elevation": 827,
      "lat": 41.5264015198,
      "lon": -85.7929000854,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KGSO": {
      "icao": "KGSO",
      "iata": "GSO",
      "name": "Piedmont Triad International Airport",
      "city": "Greensboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 925,
      "lat": 36.0978012085,
      "lon": -79.9373016357,
      "tz": "America\/New_York"
  },
  "KGSP": {
      "icao": "KGSP",
      "iata": "GSP",
      "name": "Greenville Spartanburg International Airport",
      "city": "Greenville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 964,
      "lat": 34.8956985474,
      "lon": -82.2189025879,
      "tz": "America\/New_York"
  },
  "KGTB": {
      "icao": "KGTB",
      "iata": "GTB",
      "name": "Wheeler Sack Army Air Field",
      "city": "Fort Drum",
      "state": "New-York",
      "country": "US",
      "elevation": 688,
      "lat": 44.05559921,
      "lon": -75.71949768,
      "tz": "America\/New_York"
  },
  "KGTE": {
      "icao": "KGTE",
      "iata": "",
      "name": "Quinn Field",
      "city": "Gothenburg",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2559,
      "lat": 40.9263000488,
      "lon": -100.1480026245,
      "tz": "America\/Chicago"
  },
  "KGTF": {
      "icao": "KGTF",
      "iata": "GTF",
      "name": "Great Falls International Airport",
      "city": "Great Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 3680,
      "lat": 47.48199844,
      "lon": -111.3710022,
      "tz": "America\/Denver"
  },
  "KGTG": {
      "icao": "KGTG",
      "iata": "GTG",
      "name": "Grantsburg Municipal Airport",
      "city": "Grantsburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 927,
      "lat": 45.7980995178,
      "lon": -92.6643981934,
      "tz": "America\/Chicago"
  },
  "KGTR": {
      "icao": "KGTR",
      "iata": "GTR",
      "name": "Golden Triangle Regional Airport",
      "city": "Columbus\/W Point\/Starkville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 264,
      "lat": 33.4502983093,
      "lon": -88.5914001465,
      "tz": "America\/Chicago"
  },
  "KGTU": {
      "icao": "KGTU",
      "iata": "",
      "name": "Georgetown Municipal Airport",
      "city": "Georgetown",
      "state": "Texas",
      "country": "US",
      "elevation": 790,
      "lat": 30.6788005829,
      "lon": -97.679397583,
      "tz": "America\/Chicago"
  },
  "KGUC": {
      "icao": "KGUC",
      "iata": "GUC",
      "name": "Gunnison Crested Butte Regional Airport",
      "city": "Gunnison",
      "state": "Colorado",
      "country": "US",
      "elevation": 7680,
      "lat": 38.53390121,
      "lon": -106.9329987,
      "tz": "America\/Denver"
  },
  "KGUP": {
      "icao": "KGUP",
      "iata": "GUP",
      "name": "Gallup Municipal Airport",
      "city": "Gallup",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6472,
      "lat": 35.511100769,
      "lon": -108.789001465,
      "tz": "America\/Denver"
  },
  "KGUS": {
      "icao": "KGUS",
      "iata": "GUS",
      "name": "Grissom ARB Airport",
      "city": "Peru",
      "state": "Indiana",
      "country": "US",
      "elevation": 812,
      "lat": 40.6481018066,
      "lon": -86.1520996094,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KGUY": {
      "icao": "KGUY",
      "iata": "GUY",
      "name": "Guymon Municipal Airport",
      "city": "Guymon",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 3123,
      "lat": 36.6851005554,
      "lon": -101.5080032349,
      "tz": "America\/Chicago"
  },
  "KGVL": {
      "icao": "KGVL",
      "iata": "GVL",
      "name": "Lee Gilmer Memorial Airport",
      "city": "Gainesville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1276,
      "lat": 34.27259827,
      "lon": -83.8302002,
      "tz": "America\/New_York"
  },
  "KGVQ": {
      "icao": "KGVQ",
      "iata": "",
      "name": "Genesee County Airport",
      "city": "Batavia",
      "state": "New-York",
      "country": "US",
      "elevation": 914,
      "lat": 43.03170013,
      "lon": -78.16760254,
      "tz": "America\/New_York"
  },
  "KGVT": {
      "icao": "KGVT",
      "iata": "GVT",
      "name": "Majors Airport",
      "city": "Greenville",
      "state": "California",
      "country": "US",
      "elevation": 535,
      "lat": 33.0677986145,
      "lon": -96.0652999878,
      "tz": "America\/Chicago"
  },
  "KGWB": {
      "icao": "KGWB",
      "iata": "",
      "name": "De Kalb County Airport",
      "city": "Auburn",
      "state": "Indiana",
      "country": "US",
      "elevation": 880,
      "lat": 41.3072013855,
      "lon": -85.0643997192,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KGWO": {
      "icao": "KGWO",
      "iata": "GWO",
      "name": "Greenwood Leflore Airport",
      "city": "Greenwood",
      "state": "Mississippi",
      "country": "US",
      "elevation": 162,
      "lat": 33.4943008423,
      "lon": -90.0847015381,
      "tz": "America\/Chicago"
  },
  "KGWR": {
      "icao": "KGWR",
      "iata": "",
      "name": "Gwinner Roger Melroe Field",
      "city": "Gwinner",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1266,
      "lat": 46.2187004089,
      "lon": -97.6432037354,
      "tz": "America\/Chicago"
  },
  "KGWS": {
      "icao": "KGWS",
      "iata": "GWS",
      "name": "Glenwood Springs Municipal Airport",
      "city": "Glenwood Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 5916,
      "lat": 39.5083007813,
      "lon": -107.3109970093,
      "tz": "America\/Denver"
  },
  "KGWW": {
      "icao": "KGWW",
      "iata": "",
      "name": "Goldsboro-Wayne Municipal Airport",
      "city": "Goldsboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 134,
      "lat": 35.4606018066,
      "lon": -77.9648971558,
      "tz": "America\/New_York"
  },
  "KGXY": {
      "icao": "KGXY",
      "iata": "GXY",
      "name": "Greeley Weld County Airport",
      "city": "Greeley",
      "state": "Colorado",
      "country": "US",
      "elevation": 4697,
      "lat": 40.4374008179,
      "lon": -104.6330032349,
      "tz": "America\/Denver"
  },
  "KGYB": {
      "icao": "KGYB",
      "iata": "",
      "name": "Giddings Lee County Airport",
      "city": "Giddings",
      "state": "Texas",
      "country": "US",
      "elevation": 485,
      "lat": 30.1693000793,
      "lon": -96.9800033569,
      "tz": "America\/Chicago"
  },
  "KGYH": {
      "icao": "KGYH",
      "iata": "GDC",
      "name": "Donaldson Center Airport",
      "city": "Greenville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 955,
      "lat": 34.7583007813,
      "lon": -82.3764038086,
      "tz": "America\/New_York"
  },
  "KGYI": {
      "icao": "KGYI",
      "iata": "PNX",
      "name": "North Texas Rgnl\/Perrin Field",
      "city": "Sherman\/Denison",
      "state": "Texas",
      "country": "US",
      "elevation": 749,
      "lat": 33.714099884,
      "lon": -96.6736984253,
      "tz": "America\/Chicago"
  },
  "KGYL": {
      "icao": "KGYL",
      "iata": "",
      "name": "Glencoe Municipal Airport",
      "city": "Glencoe",
      "state": "Minnesota",
      "country": "US",
      "elevation": 992,
      "lat": 44.7560005188,
      "lon": -94.081199646,
      "tz": "America\/Chicago"
  },
  "KGYR": {
      "icao": "KGYR",
      "iata": "GYR",
      "name": "Phoenix Goodyear Airport",
      "city": "Goodyear",
      "state": "Arizona",
      "country": "US",
      "elevation": 968,
      "lat": 33.4225006104,
      "lon": -112.3759994507,
      "tz": "America\/Phoenix"
  },
  "KGYY": {
      "icao": "KGYY",
      "iata": "GYY",
      "name": "Gary Chicago International Airport",
      "city": "Gary",
      "state": "Indiana",
      "country": "US",
      "elevation": 591,
      "lat": 41.6162986755,
      "lon": -87.4128036499,
      "tz": "America\/Chicago"
  },
  "KGZH": {
      "icao": "KGZH",
      "iata": "",
      "name": "Middleton Field",
      "city": "Evergreen",
      "state": "Alabama",
      "country": "US",
      "elevation": 259,
      "lat": 31.4158000946,
      "lon": -87.0439987183,
      "tz": "America\/Chicago"
  },
  "KGZS": {
      "icao": "KGZS",
      "iata": "",
      "name": "Abernathy Field",
      "city": "Pulaski",
      "state": "Tennessee",
      "country": "US",
      "elevation": 685,
      "lat": 35.1537017822,
      "lon": -87.0568008423,
      "tz": "America\/Chicago"
  },
  "KH04": {
      "icao": "KH04",
      "iata": "",
      "name": "Vinita Municipal Airport",
      "city": "Vinita",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 696,
      "lat": 36.6329994202,
      "lon": -95.1513977051,
      "tz": "America\/Chicago"
  },
  "KH05": {
      "icao": "KH05",
      "iata": "",
      "name": "Wilburton Municipal Airport",
      "city": "Wilburton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 670,
      "lat": 34.9199981689,
      "lon": -95.3939971924,
      "tz": "America\/Chicago"
  },
  "KH17": {
      "icao": "KH17",
      "iata": "",
      "name": "Buffalo Municipal Airport",
      "city": "Buffalo",
      "state": "Missouri",
      "country": "US",
      "elevation": 1154,
      "lat": 37.6542015076,
      "lon": -93.0876998901,
      "tz": "America\/Chicago"
  },
  "KH19": {
      "icao": "KH19",
      "iata": "",
      "name": "Bowling Green Municipal Airport",
      "city": "Bowling Green",
      "state": "Missouri",
      "country": "US",
      "elevation": 886,
      "lat": 39.3698997498,
      "lon": -91.2192993164,
      "tz": "America\/Chicago"
  },
  "KH21": {
      "icao": "KH21",
      "iata": "",
      "name": "Camdenton Memorial Airport",
      "city": "Camdenton",
      "state": "Missouri",
      "country": "US",
      "elevation": 1062,
      "lat": 37.9739990234,
      "lon": -92.6912002563,
      "tz": "America\/Chicago"
  },
  "KH34": {
      "icao": "KH34",
      "iata": "",
      "name": "Huntsville Municipal Airport",
      "city": "Huntsville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1749,
      "lat": 36.0782012939,
      "lon": -93.7547988892,
      "tz": "America\/Chicago"
  },
  "KH35": {
      "icao": "KH35",
      "iata": "",
      "name": "Clarksville Municipal Airport",
      "city": "Clarksville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 481,
      "lat": 35.4706993103,
      "lon": -93.4272003174,
      "tz": "America\/Chicago"
  },
  "KH37": {
      "icao": "KH37",
      "iata": "",
      "name": "Herlong Airport",
      "city": "Herlong",
      "state": "California",
      "country": "US",
      "elevation": 4055,
      "lat": 40.1388015747,
      "lon": -120.1790008545,
      "tz": "America\/Los_Angeles"
  },
  "KH58": {
      "icao": "KH58",
      "iata": "",
      "name": "Owen Field",
      "city": "Seymour",
      "state": "Missouri",
      "country": "US",
      "elevation": 1600,
      "lat": 37.1847000122,
      "lon": -92.7376022339,
      "tz": "America\/Chicago"
  },
  "KH68": {
      "icao": "KH68",
      "iata": "",
      "name": "Hefner Easley Airport",
      "city": "Wagoner",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 609,
      "lat": 35.962600708,
      "lon": -95.3419036865,
      "tz": "America\/Chicago"
  },
  "KH70": {
      "icao": "KH70",
      "iata": "",
      "name": "Stratford Field",
      "city": "Stratford",
      "state": "Texas",
      "country": "US",
      "elevation": 3668,
      "lat": 36.3456001282,
      "lon": -102.0490036011,
      "tz": "America\/Chicago"
  },
  "KH71": {
      "icao": "KH71",
      "iata": "",
      "name": "Mid America Industrial Airport",
      "city": "Pryor",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 622,
      "lat": 36.2253990173,
      "lon": -95.3301010132,
      "tz": "America\/Chicago"
  },
  "KH76": {
      "icao": "KH76",
      "iata": "",
      "name": "Pawhuska Municipal Airport",
      "city": "Pawhuska",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1000,
      "lat": 36.6716995239,
      "lon": -96.4056015015,
      "tz": "America\/Chicago"
  },
  "KH79": {
      "icao": "KH79",
      "iata": "",
      "name": "Eldon Model Airpark",
      "city": "Eldon",
      "state": "Missouri",
      "country": "US",
      "elevation": 909,
      "lat": 38.3605995178,
      "lon": -92.5716018677,
      "tz": "America\/Chicago"
  },
  "KH88": {
      "icao": "KH88",
      "iata": "",
      "name": "Fredericktown Regional Airport",
      "city": "Fredericktown",
      "state": "Missouri",
      "country": "US",
      "elevation": 880,
      "lat": 37.6058006287,
      "lon": -90.2873001099,
      "tz": "America\/Chicago"
  },
  "KH92": {
      "icao": "KH92",
      "iata": "",
      "name": "Hominy Municipal Airport",
      "city": "Hominy",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 825,
      "lat": 36.4426994324,
      "lon": -96.3871994019,
      "tz": "America\/Chicago"
  },
  "KH96": {
      "icao": "KH96",
      "iata": "",
      "name": "Benton Municipal Airport",
      "city": "Benton",
      "state": "Illinois",
      "country": "US",
      "elevation": 444,
      "lat": 38.0066986084,
      "lon": -88.9344024658,
      "tz": "America\/Chicago"
  },
  "KHAB": {
      "icao": "KHAB",
      "iata": "HAB",
      "name": "Marion County Rankin Fite Airport",
      "city": "Hamilton",
      "state": "Alabama",
      "country": "US",
      "elevation": 436,
      "lat": 34.11759949,
      "lon": -87.99819946,
      "tz": "America\/Chicago"
  },
  "KHAE": {
      "icao": "KHAE",
      "iata": "",
      "name": "Hannibal Regional Airport",
      "city": "Hannibal",
      "state": "Missouri",
      "country": "US",
      "elevation": 769,
      "lat": 39.72449875,
      "lon": -91.4437027,
      "tz": "America\/Chicago"
  },
  "KHAF": {
      "icao": "KHAF",
      "iata": "HAF",
      "name": "Half Moon Bay Airport",
      "city": "Half Moon Bay",
      "state": "California",
      "country": "US",
      "elevation": 66,
      "lat": 37.5134010315,
      "lon": -122.5009994507,
      "tz": "America\/Los_Angeles"
  },
  "KHAI": {
      "icao": "KHAI",
      "iata": "HAI",
      "name": "Three Rivers Municipal Dr Haines Airport",
      "city": "Three Rivers",
      "state": "Michigan",
      "country": "US",
      "elevation": 824,
      "lat": 41.9598007202,
      "lon": -85.5933990479,
      "tz": "America\/Detroit"
  },
  "KHAO": {
      "icao": "KHAO",
      "iata": "HAO",
      "name": "Butler Co Regional Airport",
      "city": "Hamilton",
      "state": "Ohio",
      "country": "US",
      "elevation": 633,
      "lat": 39.3638000488,
      "lon": -84.5220031738,
      "tz": "America\/New_York"
  },
  "KHBC": {
      "icao": "KHBC",
      "iata": "",
      "name": "Mohall Municipal Airport",
      "city": "Mohall",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1649,
      "lat": 48.7683982849,
      "lon": -101.5370025635,
      "tz": "America\/Chicago"
  },
  "KHBG": {
      "icao": "KHBG",
      "iata": "HBG",
      "name": "Hattiesburg Bobby L Chain Municipal Airport",
      "city": "Hattiesburg",
      "state": "Mississippi",
      "country": "US",
      "elevation": 151,
      "lat": 31.26479912,
      "lon": -89.25279999,
      "tz": "America\/Chicago"
  },
  "KHBI": {
      "icao": "KHBI",
      "iata": "",
      "name": "Asheboro Regional Airport",
      "city": "Asheboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 671,
      "lat": 35.65449905,
      "lon": -79.8946991,
      "tz": "America\/New_York"
  },
  "KHBR": {
      "icao": "KHBR",
      "iata": "HBR",
      "name": "Hobart Regional Airport",
      "city": "Hobart",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1563,
      "lat": 34.99129868,
      "lon": -99.05139923,
      "tz": "America\/Chicago"
  },
  "KHBV": {
      "icao": "KHBV",
      "iata": "",
      "name": "Jim Hogg County Airport",
      "city": "Hebbronville",
      "state": "Texas",
      "country": "US",
      "elevation": 663,
      "lat": 27.3495998383,
      "lon": -98.7369995117,
      "tz": "America\/Chicago"
  },
  "KHBW": {
      "icao": "KHBW",
      "iata": "",
      "name": "Joshua Sanford Field",
      "city": "Hillsboro",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 938,
      "lat": 43.6566009521,
      "lon": -90.3282012939,
      "tz": "America\/Chicago"
  },
  "KHBZ": {
      "icao": "KHBZ",
      "iata": "",
      "name": "Heber Springs Municipal Airport",
      "city": "Heber Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 632,
      "lat": 35.5116996765,
      "lon": -92.0130004883,
      "tz": "America\/Chicago"
  },
  "KHCD": {
      "icao": "KHCD",
      "iata": "",
      "name": "Hutchinson Municipal Butler Field",
      "city": "Hutchinson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1062,
      "lat": 44.85990143,
      "lon": -94.38249969,
      "tz": "America\/Chicago"
  },
  "KHCO": {
      "icao": "KHCO",
      "iata": "",
      "name": "Hallock Municipal Airport",
      "city": "Hallock",
      "state": "Minnesota",
      "country": "US",
      "elevation": 819,
      "lat": 48.7527008057,
      "lon": -96.9430007935,
      "tz": "America\/Chicago"
  },
  "KHCR": {
      "icao": "KHCR",
      "iata": "",
      "name": "Heber City Municipal Airport - Russ McDonald Field",
      "city": "Heber",
      "state": "Utah",
      "country": "US",
      "elevation": 1718,
      "lat": 40.4818056,
      "lon": -111.4288056,
      "tz": "America\/Denver"
  },
  "KHDC": {
      "icao": "KHDC",
      "iata": "",
      "name": "Hammond Northshore Regional Airport",
      "city": "Hammond",
      "state": "Louisiana",
      "country": "US",
      "elevation": 47,
      "lat": 30.52160072,
      "lon": -90.41840363,
      "tz": "America\/Chicago"
  },
  "KHDE": {
      "icao": "KHDE",
      "iata": "HDE",
      "name": "Brewster Field",
      "city": "Holdrege",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2313,
      "lat": 40.45209885,
      "lon": -99.33650208,
      "tz": "America\/Chicago"
  },
  "KHDI": {
      "icao": "KHDI",
      "iata": "",
      "name": "Hardwick Field",
      "city": "Cleveland",
      "state": "Tennessee",
      "country": "US",
      "elevation": 874,
      "lat": 35.2201004028,
      "lon": -84.8323974609,
      "tz": "America\/New_York"
  },
  "KHDN": {
      "icao": "KHDN",
      "iata": "HDN",
      "name": "Yampa Valley Airport",
      "city": "Hayden",
      "state": "Colorado",
      "country": "US",
      "elevation": 6606,
      "lat": 40.48120117,
      "lon": -107.2180023,
      "tz": "America\/Denver"
  },
  "KHDO": {
      "icao": "KHDO",
      "iata": "",
      "name": "Hondo Municipal Airport",
      "city": "Hondo",
      "state": "Texas",
      "country": "US",
      "elevation": 930,
      "lat": 29.359500885,
      "lon": -99.1766967773,
      "tz": "America\/Chicago"
  },
  "KHEE": {
      "icao": "KHEE",
      "iata": "HEE",
      "name": "Thompson Robbins Airport",
      "city": "Helena\/West Helena",
      "state": "Arkansas",
      "country": "US",
      "elevation": 242,
      "lat": 34.576499939,
      "lon": -90.6759033203,
      "tz": "America\/Chicago"
  },
  "KHEF": {
      "icao": "KHEF",
      "iata": "MNZ",
      "name": "Manassas Regional Harry P. Davis field",
      "city": "Manassas",
      "state": "Virginia",
      "country": "US",
      "elevation": 192,
      "lat": 38.72140121,
      "lon": -77.51540375,
      "tz": "America\/New_York"
  },
  "KHEG": {
      "icao": "KHEG",
      "iata": "",
      "name": "Herlong Airport",
      "city": "Jacksonville",
      "state": "Florida",
      "country": "US",
      "elevation": 87,
      "lat": 30.2777996063,
      "lon": -81.8059005737,
      "tz": "America\/New_York"
  },
  "KHEI": {
      "icao": "KHEI",
      "iata": "",
      "name": "Hettinger Municipal Airport",
      "city": "Hettinger",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2705,
      "lat": 46.0149002075,
      "lon": -102.65599823,
      "tz": "America\/Denver"
  },
  "KHEQ": {
      "icao": "KHEQ",
      "iata": "",
      "name": "Holyoke Airport",
      "city": "Holyoke",
      "state": "Colorado",
      "country": "US",
      "elevation": 3730,
      "lat": 40.5694007874,
      "lon": -102.2730026245,
      "tz": "America\/Denver"
  },
  "KHES": {
      "icao": "KHES",
      "iata": "",
      "name": "Healdsburg Municipal Airport",
      "city": "Healdsburg",
      "state": "California",
      "country": "US",
      "elevation": 278,
      "lat": 38.6534996033,
      "lon": -122.899002075,
      "tz": "America\/Los_Angeles"
  },
  "KHEZ": {
      "icao": "KHEZ",
      "iata": "HEZ",
      "name": "Hardy Anders Field Natchez Adams County Airport",
      "city": "Natchez",
      "state": "Mississippi",
      "country": "US",
      "elevation": 272,
      "lat": 31.6137008667,
      "lon": -91.2973022461,
      "tz": "America\/Chicago"
  },
  "KHFD": {
      "icao": "KHFD",
      "iata": "HFD",
      "name": "Hartford Brainard Airport",
      "city": "Hartford",
      "state": "Connecticut",
      "country": "US",
      "elevation": 18,
      "lat": 41.7366981506,
      "lon": -72.6493988037,
      "tz": "America\/New_York"
  },
  "KHFF": {
      "icao": "KHFF",
      "iata": "HFF",
      "name": "Mackall Army Air Field",
      "city": "Camp Mackall",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 376,
      "lat": 35.03649902,
      "lon": -79.49749756,
      "tz": "America\/New_York"
  },
  "KHFJ": {
      "icao": "KHFJ",
      "iata": "",
      "name": "Monett Municipal Airport",
      "city": "Monett",
      "state": "Missouri",
      "country": "US",
      "elevation": 1314,
      "lat": 36.90620041,
      "lon": -94.01280212,
      "tz": "America\/Chicago"
  },
  "KHFY": {
      "icao": "KHFY",
      "iata": "",
      "name": "Greenwood Municipal Airport",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 822,
      "lat": 39.6283988953,
      "lon": -86.0878982544,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KHGR": {
      "icao": "KHGR",
      "iata": "HGR",
      "name": "Hagerstown Regional Richard A Henson Field",
      "city": "Hagerstown",
      "state": "Maryland",
      "country": "US",
      "elevation": 703,
      "lat": 39.707901,
      "lon": -77.72949982,
      "tz": "America\/New_York"
  },
  "KHHF": {
      "icao": "KHHF",
      "iata": "",
      "name": "Hemphill County Airport",
      "city": "Canadian",
      "state": "Texas",
      "country": "US",
      "elevation": 2396,
      "lat": 35.89530182,
      "lon": -100.4039993,
      "tz": "America\/Chicago"
  },
  "KHHG": {
      "icao": "KHHG",
      "iata": "",
      "name": "Huntington Municipal Airport",
      "city": "Huntington",
      "state": "Indiana",
      "country": "US",
      "elevation": 806,
      "lat": 40.8529014587,
      "lon": -85.4570999146,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KHHR": {
      "icao": "KHHR",
      "iata": "HHR",
      "name": "Jack Northrop Field Hawthorne Municipal Airport",
      "city": "Hawthorne",
      "state": "California",
      "country": "US",
      "elevation": 66,
      "lat": 33.9227981567,
      "lon": -118.3349990845,
      "tz": "America\/Los_Angeles"
  },
  "KHHW": {
      "icao": "KHHW",
      "iata": "HUJ",
      "name": "Stan Stamper Municipal Airport",
      "city": "Hugo",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 572,
      "lat": 34.03480148,
      "lon": -95.54190063,
      "tz": "America\/Chicago"
  },
  "KHIB": {
      "icao": "KHIB",
      "iata": "HIB",
      "name": "Chisholm Hibbing Airport",
      "city": "Hibbing",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1354,
      "lat": 47.38660049,
      "lon": -92.83899689,
      "tz": "America\/Chicago"
  },
  "KHIE": {
      "icao": "KHIE",
      "iata": "HIE",
      "name": "Mount Washington Regional Airport",
      "city": "Whitefield",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 1074,
      "lat": 44.3675994873,
      "lon": -71.5445022583,
      "tz": "America\/New_York"
  },
  "KHIF": {
      "icao": "KHIF",
      "iata": "HIF",
      "name": "Hill Air Force Base",
      "city": "Ogden",
      "state": "Utah",
      "country": "US",
      "elevation": 4789,
      "lat": 41.1239013672,
      "lon": -111.9729995728,
      "tz": "America\/Denver"
  },
  "KHIG": {
      "icao": "KHIG",
      "iata": "",
      "name": "Higginsville Industrial Municipal Airport",
      "city": "Higginsville",
      "state": "Missouri",
      "country": "US",
      "elevation": 840,
      "lat": 39.07289886,
      "lon": -93.67749786,
      "tz": "America\/Chicago"
  },
  "KHII": {
      "icao": "KHII",
      "iata": "HII",
      "name": "Lake Havasu City Airport",
      "city": "Lake Havasu City",
      "state": "Arizona",
      "country": "US",
      "elevation": 783,
      "lat": 34.57109833,
      "lon": -114.3580017,
      "tz": "America\/Los_Angeles"
  },
  "KHIO": {
      "icao": "KHIO",
      "iata": "HIO",
      "name": "Portland Hillsboro Airport",
      "city": "Portland",
      "state": "Oregon",
      "country": "US",
      "elevation": 208,
      "lat": 45.54040146,
      "lon": -122.9499969,
      "tz": "America\/Los_Angeles"
  },
  "KHJH": {
      "icao": "KHJH",
      "iata": "",
      "name": "Hebron Municipal Airport",
      "city": "Hebron",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1466,
      "lat": 40.1521987915,
      "lon": -97.5869979858,
      "tz": "America\/Chicago"
  },
  "KHJO": {
      "icao": "KHJO",
      "iata": "",
      "name": "Hanford Municipal Airport",
      "city": "Hanford",
      "state": "California",
      "country": "US",
      "elevation": 240,
      "lat": 36.31669998,
      "lon": -119.6279984,
      "tz": "America\/Los_Angeles"
  },
  "KHKA": {
      "icao": "KHKA",
      "iata": "HKA",
      "name": "Blytheville Municipal Airport",
      "city": "Blytheville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 255,
      "lat": 35.9403991699,
      "lon": -89.8308029175,
      "tz": "America\/Chicago"
  },
  "KHKS": {
      "icao": "KHKS",
      "iata": "HKS",
      "name": "Hawkins Field",
      "city": "Jackson",
      "state": "Mississippi",
      "country": "US",
      "elevation": 341,
      "lat": 32.33449936,
      "lon": -90.22219849,
      "tz": "America\/Chicago"
  },
  "KHKY": {
      "icao": "KHKY",
      "iata": "HKY",
      "name": "Hickory Regional Airport",
      "city": "Hickory",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1190,
      "lat": 35.74110031,
      "lon": -81.38950348,
      "tz": "America\/New_York"
  },
  "KHLB": {
      "icao": "KHLB",
      "iata": "HLB",
      "name": "Hillenbrand Industries Airport",
      "city": "Batesville",
      "state": "Indiana",
      "country": "US",
      "elevation": 973,
      "lat": 39.3445014954,
      "lon": -85.2583007813,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KHLC": {
      "icao": "KHLC",
      "iata": "HLC",
      "name": "Hill City Municipal Airport",
      "city": "Hill City",
      "state": "Kansas",
      "country": "US",
      "elevation": 2238,
      "lat": 39.37879944,
      "lon": -99.83149719,
      "tz": "America\/Chicago"
  },
  "KHLG": {
      "icao": "KHLG",
      "iata": "HLG",
      "name": "Wheeling Ohio County Airport",
      "city": "Wheeling",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1195,
      "lat": 40.1749992371,
      "lon": -80.6463012695,
      "tz": "America\/New_York"
  },
  "KHLM": {
      "icao": "KHLM",
      "iata": "HLM",
      "name": "Park Township Airport",
      "city": "Holland",
      "state": "Michigan",
      "country": "US",
      "elevation": 603,
      "lat": 42.7958984375,
      "lon": -86.1620025635,
      "tz": "America\/Detroit"
  },
  "KHLN": {
      "icao": "KHLN",
      "iata": "HLN",
      "name": "Helena Regional Airport",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 3877,
      "lat": 46.6068000793,
      "lon": -111.983001709,
      "tz": "America\/Denver"
  },
  "KHLR": {
      "icao": "KHLR",
      "iata": "HLR",
      "name": "Hood Army Air Field",
      "city": "Fort Hood(Killeen)",
      "state": "Texas",
      "country": "US",
      "elevation": 924,
      "lat": 31.1387004852,
      "lon": -97.7145004272,
      "tz": "America\/Chicago"
  },
  "KHLX": {
      "icao": "KHLX",
      "iata": "",
      "name": "Twin County Airport",
      "city": "Galax Hillsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 2693,
      "lat": 36.7661018372,
      "lon": -80.823600769,
      "tz": "America\/New_York"
  },
  "KHMN": {
      "icao": "KHMN",
      "iata": "HMN",
      "name": "Holloman Air Force Base",
      "city": "Alamogordo",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4093,
      "lat": 32.8525009155,
      "lon": -106.1070022583,
      "tz": "America\/Denver"
  },
  "KHMT": {
      "icao": "KHMT",
      "iata": "HMT",
      "name": "Hemet Ryan Airport",
      "city": "Hemet",
      "state": "California",
      "country": "US",
      "elevation": 1512,
      "lat": 33.7340011597,
      "lon": -117.0230026245,
      "tz": "America\/Los_Angeles"
  },
  "KHMZ": {
      "icao": "KHMZ",
      "iata": "",
      "name": "Bedford County Airport",
      "city": "Bedford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1162,
      "lat": 40.08530045,
      "lon": -78.5121994,
      "tz": "America\/New_York"
  },
  "KHNB": {
      "icao": "KHNB",
      "iata": "HNB",
      "name": "Huntingburg Airport",
      "city": "Huntingburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 529,
      "lat": 38.2490005493,
      "lon": -86.9536972046,
      "tz": "America\/Indiana\/Vincennes"
  },
  "KHND": {
      "icao": "KHND",
      "iata": "HSH",
      "name": "Henderson Executive Airport",
      "city": "Las Vegas",
      "state": "Nevada",
      "country": "US",
      "elevation": 2492,
      "lat": 35.9728012085,
      "lon": -115.1340026855,
      "tz": "America\/Los_Angeles"
  },
  "KHNR": {
      "icao": "KHNR",
      "iata": "",
      "name": "Harlan Municipal Airport",
      "city": "Harlan",
      "state": "Iowa",
      "country": "US",
      "elevation": 1231,
      "lat": 41.584400177,
      "lon": -95.3395996094,
      "tz": "America\/Chicago"
  },
  "KHNZ": {
      "icao": "KHNZ",
      "iata": "",
      "name": "Henderson Oxford Airport",
      "city": "Oxford",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 526,
      "lat": 36.36159897,
      "lon": -78.52919769,
      "tz": "America\/New_York"
  },
  "KHOB": {
      "icao": "KHOB",
      "iata": "HOB",
      "name": "Lea County Regional Airport",
      "city": "Hobbs",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3661,
      "lat": 32.6875,
      "lon": -103.2170029,
      "tz": "America\/Denver"
  },
  "KHOC": {
      "icao": "KHOC",
      "iata": "",
      "name": "Highland County Airport",
      "city": "Hillsboro",
      "state": "Ohio",
      "country": "US",
      "elevation": 977,
      "lat": 39.1888008118,
      "lon": -83.5388031006,
      "tz": "America\/New_York"
  },
  "KHOE": {
      "icao": "KHOE",
      "iata": "",
      "name": "Homerville Airport",
      "city": "Homerville",
      "state": "Georgia",
      "country": "US",
      "elevation": 186,
      "lat": 31.0559005737,
      "lon": -82.7741012573,
      "tz": "America\/New_York"
  },
  "KHON": {
      "icao": "KHON",
      "iata": "HON",
      "name": "Huron Regional Airport",
      "city": "Huron",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1289,
      "lat": 44.3852005005,
      "lon": -98.2285003662,
      "tz": "America\/Chicago"
  },
  "KHOP": {
      "icao": "KHOP",
      "iata": "HOP",
      "name": "Campbell AAF (Fort Campbell) Air Field",
      "city": "Fort Campbell\/Hopkinsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 573,
      "lat": 36.668598175,
      "lon": -87.4962005615,
      "tz": "America\/Chicago"
  },
  "KHOT": {
      "icao": "KHOT",
      "iata": "HOT",
      "name": "Memorial Field",
      "city": "Hot Springs",
      "state": "Iowa",
      "country": "US",
      "elevation": 540,
      "lat": 34.4780006409,
      "lon": -93.0961990356,
      "tz": "America\/Chicago"
  },
  "KHOU": {
      "icao": "KHOU",
      "iata": "HOU",
      "name": "William P Hobby Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 46,
      "lat": 29.64539909,
      "lon": -95.27890015,
      "tz": "America\/Chicago"
  },
  "KHPN": {
      "icao": "KHPN",
      "iata": "HPN",
      "name": "Westchester County Airport",
      "city": "White Plains",
      "state": "New-York",
      "country": "US",
      "elevation": 439,
      "lat": 41.0670013428,
      "lon": -73.7076034546,
      "tz": "America\/New_York"
  },
  "KHPT": {
      "icao": "KHPT",
      "iata": "HPT",
      "name": "Hampton Municipal Airport",
      "city": "Hampton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1176,
      "lat": 42.7237014771,
      "lon": -93.2263031006,
      "tz": "America\/Chicago"
  },
  "KHPY": {
      "icao": "KHPY",
      "iata": "HPY",
      "name": "Baytown Airport",
      "city": "Baytown",
      "state": "Texas",
      "country": "US",
      "elevation": 34,
      "lat": 29.7861003876,
      "lon": -94.9526977539,
      "tz": "America\/Chicago"
  },
  "KHQG": {
      "icao": "KHQG",
      "iata": "",
      "name": "Hugoton Municipal Airport",
      "city": "Hugoton",
      "state": "Kansas",
      "country": "US",
      "elevation": 3134,
      "lat": 37.1631012,
      "lon": -101.3710022,
      "tz": "America\/Chicago"
  },
  "KHQM": {
      "icao": "KHQM",
      "iata": "HQM",
      "name": "Bowerman Airport",
      "city": "Hoquiam",
      "state": "Washington",
      "country": "US",
      "elevation": 18,
      "lat": 46.9711990356,
      "lon": -123.93699646,
      "tz": "America\/Los_Angeles"
  },
  "KHQU": {
      "icao": "KHQU",
      "iata": "",
      "name": "Thomson Mcduffie County Airport",
      "city": "Thomson",
      "state": "Georgia",
      "country": "US",
      "elevation": 501,
      "lat": 33.52970123,
      "lon": -82.51650238,
      "tz": "America\/New_York"
  },
  "KHQZ": {
      "icao": "KHQZ",
      "iata": "",
      "name": "Mesquite Metro Airport",
      "city": "Mesquite",
      "state": "Texas",
      "country": "US",
      "elevation": 447,
      "lat": 32.7470016479,
      "lon": -96.5304031372,
      "tz": "America\/Chicago"
  },
  "KHRI": {
      "icao": "KHRI",
      "iata": "HES",
      "name": "Hermiston Municipal Airport",
      "city": "Hermiston",
      "state": "Oregon",
      "country": "US",
      "elevation": 644,
      "lat": 45.8282012939,
      "lon": -119.2590026855,
      "tz": "America\/Los_Angeles"
  },
  "KHRJ": {
      "icao": "KHRJ",
      "iata": "",
      "name": "Harnett Regional Jetport Airport",
      "city": "Erwin",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 202,
      "lat": 35.37939835,
      "lon": -78.73300171,
      "tz": "America\/New_York"
  },
  "KHRL": {
      "icao": "KHRL",
      "iata": "HRL",
      "name": "Valley International Airport",
      "city": "Harlingen",
      "state": "Texas",
      "country": "US",
      "elevation": 36,
      "lat": 26.2285003662,
      "lon": -97.6544036865,
      "tz": "America\/Chicago"
  },
  "KHRO": {
      "icao": "KHRO",
      "iata": "HRO",
      "name": "Boone County Airport",
      "city": "Harrison",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1365,
      "lat": 36.2615013123,
      "lon": -93.1547012329,
      "tz": "America\/Chicago"
  },
  "KHRT": {
      "icao": "KHRT",
      "iata": "",
      "name": "Hurlburt Field",
      "city": "Mary Esther",
      "state": "Florida",
      "country": "US",
      "elevation": 38,
      "lat": 30.4277992249,
      "lon": -86.6893005371,
      "tz": "America\/Chicago"
  },
  "KHRU": {
      "icao": "KHRU",
      "iata": "",
      "name": "Herington Regional Airport",
      "city": "Herington",
      "state": "Kansas",
      "country": "US",
      "elevation": 1480,
      "lat": 38.6946983337,
      "lon": -96.8079986572,
      "tz": "America\/Chicago"
  },
  "KHRX": {
      "icao": "KHRX",
      "iata": "",
      "name": "Hereford Municipal Airport",
      "city": "Hereford",
      "state": "Texas",
      "country": "US",
      "elevation": 3788,
      "lat": 34.85779953,
      "lon": -102.3259964,
      "tz": "America\/Chicago"
  },
  "KHSA": {
      "icao": "KHSA",
      "iata": "",
      "name": "Stennis International Airport",
      "city": "Bay St Louis",
      "state": "Mississippi",
      "country": "US",
      "elevation": 23,
      "lat": 30.3677997589,
      "lon": -89.4545974731,
      "tz": "America\/Chicago"
  },
  "KHSB": {
      "icao": "KHSB",
      "iata": "HSB",
      "name": "Harrisburg Raleigh Airport",
      "city": "Harrisburg",
      "state": "Illinois",
      "country": "US",
      "elevation": 398,
      "lat": 37.81129837,
      "lon": -88.5503006,
      "tz": "America\/Chicago"
  },
  "KHSD": {
      "icao": "KHSD",
      "iata": "",
      "name": "Sundance Airpark",
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1193,
      "lat": 35.6017990112,
      "lon": -97.706199646,
      "tz": "America\/Chicago"
  },
  "KHSE": {
      "icao": "KHSE",
      "iata": "HNC",
      "name": "Billy Mitchell Airport",
      "city": "Hatteras",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 17,
      "lat": 35.23279953,
      "lon": -75.6177978516,
      "tz": "America\/New_York"
  },
  "KHSG": {
      "icao": "KHSG",
      "iata": "",
      "name": "Hot Springs County Airport",
      "city": "Thermopolis",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4892,
      "lat": 43.7136111,
      "lon": -108.3896944,
      "tz": "America\/Denver"
  },
  "KHSI": {
      "icao": "KHSI",
      "iata": "HSI",
      "name": "Hastings Municipal Airport",
      "city": "Hastings",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1961,
      "lat": 40.6053009033,
      "lon": -98.4279022217,
      "tz": "America\/Chicago"
  },
  "KHSP": {
      "icao": "KHSP",
      "iata": "HSP",
      "name": "Ingalls Field",
      "city": "Hot Springs",
      "state": "Virginia",
      "country": "US",
      "elevation": 3793,
      "lat": 37.95140076,
      "lon": -79.83390045,
      "tz": "America\/New_York"
  },
  "KHSR": {
      "icao": "KHSR",
      "iata": "",
      "name": "Hot Springs Municipal Airport",
      "city": "Hot Springs",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3150,
      "lat": 43.36819839,
      "lon": -103.3880005,
      "tz": "America\/Denver"
  },
  "KHST": {
      "icao": "KHST",
      "iata": "HST",
      "name": "Homestead ARB Airport",
      "city": "Homestead",
      "state": "Florida",
      "country": "US",
      "elevation": 5,
      "lat": 25.48859978,
      "lon": -80.38359833,
      "tz": "America\/New_York"
  },
  "KHSV": {
      "icao": "KHSV",
      "iata": "HSV",
      "name": "Huntsville International Carl T Jones Field",
      "city": "Huntsville",
      "state": "Alabama",
      "country": "US",
      "elevation": 629,
      "lat": 34.6371994019,
      "lon": -86.775100708,
      "tz": "America\/Chicago"
  },
  "KHTH": {
      "icao": "KHTH",
      "iata": "HTH",
      "name": "Hawthorne Industrial Airport",
      "city": "Hawthorne",
      "state": "Nevada",
      "country": "US",
      "elevation": 4215,
      "lat": 38.5443992615,
      "lon": -118.6340026855,
      "tz": "America\/Los_Angeles"
  },
  "KHTL": {
      "icao": "KHTL",
      "iata": "HTL",
      "name": "Roscommon County - Blodgett Memorial Airport",
      "city": "Houghton Lake",
      "state": "Michigan",
      "country": "US",
      "elevation": 1150,
      "lat": 44.35979843,
      "lon": -84.6710968,
      "tz": "America\/Detroit"
  },
  "KHTO": {
      "icao": "KHTO",
      "iata": "HTO",
      "name": "East Hampton Airport",
      "city": "East Hampton",
      "state": "New-York",
      "country": "US",
      "elevation": 55,
      "lat": 40.95959854,
      "lon": -72.25180054,
      "tz": "America\/New_York"
  },
  "KHTS": {
      "icao": "KHTS",
      "iata": "HTS",
      "name": "Tri-State\/Milton J. Ferguson Field",
      "city": "Huntington",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 828,
      "lat": 38.36669922,
      "lon": -82.55799866,
      "tz": "America\/New_York"
  },
  "KHTW": {
      "icao": "KHTW",
      "iata": "HTW",
      "name": "Lawrence County Airpark",
      "city": "Chesapeake\/Huntington Wva",
      "state": "Ohio",
      "country": "US",
      "elevation": 568,
      "lat": 38.4193000793,
      "lon": -82.4943008423,
      "tz": "America\/New_York"
  },
  "KHUA": {
      "icao": "KHUA",
      "iata": "HUA",
      "name": "Redstone Army Air Field",
      "city": "Redstone Arsnl Huntsville",
      "state": "Alabama",
      "country": "US",
      "elevation": 684,
      "lat": 34.67869949,
      "lon": -86.68479919,
      "tz": "America\/Chicago"
  },
  "KHUF": {
      "icao": "KHUF",
      "iata": "HUF",
      "name": "Terre Haute International Hulman Field",
      "city": "Terre Haute",
      "state": "Indiana",
      "country": "US",
      "elevation": 589,
      "lat": 39.451499939,
      "lon": -87.3076019287,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KHUL": {
      "icao": "KHUL",
      "iata": "HUL",
      "name": "Houlton International Airport",
      "city": "Houlton",
      "state": "Maine",
      "country": "US",
      "elevation": 489,
      "lat": 46.1231002808,
      "lon": -67.792098999,
      "tz": "America\/Moncton"
  },
  "KHUM": {
      "icao": "KHUM",
      "iata": "HUM",
      "name": "Houma Terrebonne Airport",
      "city": "Houma",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.5664997101,
      "lon": -90.6604003906,
      "tz": "America\/Chicago"
  },
  "KHUT": {
      "icao": "KHUT",
      "iata": "HUT",
      "name": "Hutchinson Municipal Airport",
      "city": "Hutchinson",
      "state": "Kansas",
      "country": "US",
      "elevation": 1543,
      "lat": 38.0654983521,
      "lon": -97.8606033325,
      "tz": "America\/Chicago"
  },
  "KHVC": {
      "icao": "KHVC",
      "iata": "",
      "name": "Hopkinsville Christian County Airport",
      "city": "Hopkinsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 564,
      "lat": 36.8569984436,
      "lon": -87.4551010132,
      "tz": "America\/Chicago"
  },
  "KHVE": {
      "icao": "KHVE",
      "iata": "HVE",
      "name": "Hanksville Airport",
      "city": "Hanksville",
      "state": "Utah",
      "country": "US",
      "elevation": 4444,
      "lat": 38.4179992676,
      "lon": -110.7040023804,
      "tz": "America\/Denver"
  },
  "KHVN": {
      "icao": "KHVN",
      "iata": "HVN",
      "name": "Tweed New Haven Airport",
      "city": "New Haven",
      "state": "Connecticut",
      "country": "US",
      "elevation": 12,
      "lat": 41.26369858,
      "lon": -72.88680267,
      "tz": "America\/New_York"
  },
  "KHVR": {
      "icao": "KHVR",
      "iata": "HVR",
      "name": "Havre City County Airport",
      "city": "Havre",
      "state": "Montana",
      "country": "US",
      "elevation": 2591,
      "lat": 48.54299927,
      "lon": -109.762001,
      "tz": "America\/Denver"
  },
  "KHVS": {
      "icao": "KHVS",
      "iata": "HVS",
      "name": "Hartsville Regional Airport",
      "city": "Hartsville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 364,
      "lat": 34.4030990601,
      "lon": -80.1192016602,
      "tz": "America\/New_York"
  },
  "KHWD": {
      "icao": "KHWD",
      "iata": "HWD",
      "name": "Hayward Executive Airport",
      "city": "Hayward",
      "state": "California",
      "country": "US",
      "elevation": 52,
      "lat": 37.659198761,
      "lon": -122.1220016479,
      "tz": "America\/Los_Angeles"
  },
  "KHWO": {
      "icao": "KHWO",
      "iata": "HWO",
      "name": "North Perry Airport",
      "city": "Hollywood",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 26.0011997223,
      "lon": -80.2406997681,
      "tz": "America\/New_York"
  },
  "KHWQ": {
      "icao": "KHWQ",
      "iata": "",
      "name": "Wheatland County At Harlowton Airport",
      "city": "Harlowton",
      "state": "Montana",
      "country": "US",
      "elevation": 4311,
      "lat": 46.44860077,
      "lon": -109.8529968,
      "tz": "America\/Denver"
  },
  "KHWV": {
      "icao": "KHWV",
      "iata": "WSH",
      "name": "Brookhaven Airport",
      "city": "Shirley",
      "state": "New-York",
      "country": "US",
      "elevation": 81,
      "lat": 40.8218994141,
      "lon": -72.8694000244,
      "tz": "America\/New_York"
  },
  "KHWY": {
      "icao": "KHWY",
      "iata": "",
      "name": "Warrenton Fauquier Airport",
      "city": "Warrenton",
      "state": "Virginia",
      "country": "US",
      "elevation": 336,
      "lat": 38.5862998962,
      "lon": -77.7106018066,
      "tz": "America\/New_York"
  },
  "KHXD": {
      "icao": "KHXD",
      "iata": "HHH",
      "name": "Hilton Head Airport",
      "city": "Hilton Head Island",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 19,
      "lat": 32.2243995667,
      "lon": -80.6975021362,
      "tz": "America\/New_York"
  },
  "KHXF": {
      "icao": "KHXF",
      "iata": "",
      "name": "Hartford Municipal Airport",
      "city": "Hartford",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1069,
      "lat": 43.34930038,
      "lon": -88.39109802,
      "tz": "America\/Chicago"
  },
  "KHYA": {
      "icao": "KHYA",
      "iata": "HYA",
      "name": "Cape Cod Gateway Airport",
      "city": "Hyannis",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 54,
      "lat": 41.66930008,
      "lon": -70.28040314,
      "tz": "America\/New_York"
  },
  "KHYI": {
      "icao": "KHYI",
      "iata": "",
      "name": "San Marcos Municipal Airport",
      "city": "San Marcos",
      "state": "Texas",
      "country": "US",
      "elevation": 597,
      "lat": 29.8927001953,
      "lon": -97.8629989624,
      "tz": "America\/Chicago"
  },
  "KHYR": {
      "icao": "KHYR",
      "iata": "HYR",
      "name": "Sawyer County Airport",
      "city": "Hayward",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1216,
      "lat": 46.0251998901,
      "lon": -91.4442977905,
      "tz": "America\/Chicago"
  },
  "KHYS": {
      "icao": "KHYS",
      "iata": "HYS",
      "name": "Hays Regional Airport",
      "city": "Hays",
      "state": "Kansas",
      "country": "US",
      "elevation": 1999,
      "lat": 38.84220123,
      "lon": -99.27320099,
      "tz": "America\/Chicago"
  },
  "KHYW": {
      "icao": "KHYW",
      "iata": "",
      "name": "Conway Horry County Airport",
      "city": "Conway",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 35,
      "lat": 33.82849884,
      "lon": -79.12220001,
      "tz": "America\/New_York"
  },
  "KHYX": {
      "icao": "KHYX",
      "iata": "",
      "name": "Saginaw County H.W. Browne Airport",
      "city": "Saginaw",
      "state": "Michigan",
      "country": "US",
      "elevation": 601,
      "lat": 43.4333992,
      "lon": -83.86229706,
      "tz": "America\/Detroit"
  },
  "KHZD": {
      "icao": "KHZD",
      "iata": "",
      "name": "Carroll County Airport",
      "city": "Huntingdon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 497,
      "lat": 36.08929825,
      "lon": -88.46330261,
      "tz": "America\/Chicago"
  },
  "KHZE": {
      "icao": "KHZE",
      "iata": "",
      "name": "Mercer County Regional Airport",
      "city": "Hazen",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1814,
      "lat": 47.28990173,
      "lon": -101.5810013,
      "tz": "America\/North_Dakota\/Beulah"
  },
  "KHZL": {
      "icao": "KHZL",
      "iata": "HZL",
      "name": "Hazleton Municipal Airport",
      "city": "Hazleton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1603,
      "lat": 40.9868011475,
      "lon": -75.9949035645,
      "tz": "America\/New_York"
  },
  "KHZR": {
      "icao": "KHZR",
      "iata": "",
      "name": "False River Regional Airport",
      "city": "New Roads",
      "state": "Louisiana",
      "country": "US",
      "elevation": 40,
      "lat": 30.71829987,
      "lon": -91.47869873,
      "tz": "America\/Chicago"
  },
  "KHZX": {
      "icao": "KHZX",
      "iata": "",
      "name": "Isedor Iverson Airport",
      "city": "Mc Gregor",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1228,
      "lat": 46.6188011169,
      "lon": -93.3097991943,
      "tz": "America\/Chicago"
  },
  "KHZY": {
      "icao": "KHZY",
      "iata": "JFN",
      "name": "Ashtabula County Airport",
      "city": "Ashtabula",
      "state": "Ohio",
      "country": "US",
      "elevation": 924,
      "lat": 41.7779998779,
      "lon": -80.6955032349,
      "tz": "America\/New_York"
  },
  "KI06": {
      "icao": "KI06",
      "iata": "",
      "name": "City of Tulia-Swisher County Municipal Airport",
      "city": "Tulia",
      "state": "Texas",
      "country": "US",
      "elevation": 3503,
      "lat": 34.56679916,
      "lon": -101.7809982,
      "tz": "America\/Chicago"
  },
  "KI10": {
      "icao": "KI10",
      "iata": "",
      "name": "Noble County Airport",
      "city": "Caldwell",
      "state": "Ohio",
      "country": "US",
      "elevation": 879,
      "lat": 39.8009986877,
      "lon": -81.5363006592,
      "tz": "America\/New_York"
  },
  "KI12": {
      "icao": "KI12",
      "iata": "",
      "name": "Sidney Municipal Airport",
      "city": "Sidney",
      "state": "Ohio",
      "country": "US",
      "elevation": 1044,
      "lat": 40.2414016724,
      "lon": -84.1509017944,
      "tz": "America\/New_York"
  },
  "KI16": {
      "icao": "KI16",
      "iata": "",
      "name": "Kee Field",
      "city": "Pineville",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1783,
      "lat": 37.6003990173,
      "lon": -81.5593032837,
      "tz": "America\/New_York"
  },
  "KI17": {
      "icao": "KI17",
      "iata": "",
      "name": "Piqua Airport-Hartzell Field",
      "city": "Piqua",
      "state": "Ohio",
      "country": "US",
      "elevation": 994,
      "lat": 40.16469955,
      "lon": -84.30840302,
      "tz": "America\/New_York"
  },
  "KI18": {
      "icao": "KI18",
      "iata": "",
      "name": "Jackson County Airport",
      "city": "Ravenswood",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 758,
      "lat": 38.9297981262,
      "lon": -81.8195037842,
      "tz": "America\/New_York"
  },
  "KI22": {
      "icao": "KI22",
      "iata": "",
      "name": "Randolph County Airport",
      "city": "Winchester",
      "state": "Indiana",
      "country": "US",
      "elevation": 1119,
      "lat": 40.1688995361,
      "lon": -84.9256973267,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KI23": {
      "icao": "KI23",
      "iata": "",
      "name": "Fayette County Airport",
      "city": "Washington Court House",
      "state": "Ohio",
      "country": "US",
      "elevation": 980,
      "lat": 39.570400238,
      "lon": -83.420501709,
      "tz": "America\/New_York"
  },
  "KI34": {
      "icao": "KI34",
      "iata": "",
      "name": "Greensburg Municipal Airport",
      "city": "Greensburg",
      "state": "Indiana",
      "country": "US",
      "elevation": 912,
      "lat": 39.32690048,
      "lon": -85.52249908,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KI35": {
      "icao": "KI35",
      "iata": "",
      "name": "Tucker Guthrie Memorial Airport",
      "city": "Harlan",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1551,
      "lat": 36.8592987061,
      "lon": -83.3584976196,
      "tz": "America\/New_York"
  },
  "KI39": {
      "icao": "KI39",
      "iata": "",
      "name": "Madison Airport",
      "city": "Richmond",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1002,
      "lat": 37.63079834,
      "lon": -84.33229828,
      "tz": "America\/New_York"
  },
  "KI40": {
      "icao": "KI40",
      "iata": "",
      "name": "Richard Downing Airport",
      "city": "Coshocton",
      "state": "Ohio",
      "country": "US",
      "elevation": 979,
      "lat": 40.3092002869,
      "lon": -81.8534011841,
      "tz": "America\/New_York"
  },
  "KI43": {
      "icao": "KI43",
      "iata": "",
      "name": "James A Rhodes Airport",
      "city": "Jackson",
      "state": "Ohio",
      "country": "US",
      "elevation": 726,
      "lat": 38.9813995361,
      "lon": -82.5779037476,
      "tz": "America\/New_York"
  },
  "KI50": {
      "icao": "KI50",
      "iata": "",
      "name": "Stanton Airport",
      "city": "Stanton",
      "state": "Kentucky",
      "country": "US",
      "elevation": 651,
      "lat": 37.8501014709,
      "lon": -83.8458023071,
      "tz": "America\/New_York"
  },
  "KI53": {
      "icao": "KI53",
      "iata": "",
      "name": "Liberty Casey County Airport",
      "city": "Liberty",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1040,
      "lat": 37.3095016479,
      "lon": -85.0587997437,
      "tz": "America\/Chicago"
  },
  "KI54": {
      "icao": "KI54",
      "iata": "",
      "name": "Mad River Inc Airport",
      "city": "Tremont City",
      "state": "Ohio",
      "country": "US",
      "elevation": 958,
      "lat": 40.0200996399,
      "lon": -83.8284988403,
      "tz": "America\/New_York"
  },
  "KI63": {
      "icao": "KI63",
      "iata": "",
      "name": "Mount Sterling Municipal Airport",
      "city": "Mount Sterling",
      "state": "Illinois",
      "country": "US",
      "elevation": 734,
      "lat": 39.98749924,
      "lon": -90.80419922,
      "tz": "America\/Chicago"
  },
  "KI64": {
      "icao": "KI64",
      "iata": "",
      "name": "Wakeman Airport",
      "city": "Wakeman",
      "state": "Ohio",
      "country": "US",
      "elevation": 848,
      "lat": 41.2930984497,
      "lon": -82.3706970215,
      "tz": "America\/New_York"
  },
  "KI66": {
      "icao": "KI66",
      "iata": "",
      "name": "Clinton Field",
      "city": "Wilmington",
      "state": "Ohio",
      "country": "US",
      "elevation": 1033,
      "lat": 39.5032997131,
      "lon": -83.8628005981,
      "tz": "America\/New_York"
  },
  "KI67": {
      "icao": "KI67",
      "iata": "",
      "name": "Cincinnati West Airport",
      "city": "Harrison",
      "state": "Ohio",
      "country": "US",
      "elevation": 584,
      "lat": 39.2588996887,
      "lon": -84.7742996216,
      "tz": "America\/New_York"
  },
  "KI68": {
      "icao": "KI68",
      "iata": "",
      "name": "Lebanon Warren County Airport",
      "city": "Lebanon",
      "state": "Ohio",
      "country": "US",
      "elevation": 898,
      "lat": 39.4622001648,
      "lon": -84.2518005371,
      "tz": "America\/New_York"
  },
  "KI69": {
      "icao": "KI69",
      "iata": "",
      "name": "Clermont County Airport",
      "city": "Batavia",
      "state": "Ohio",
      "country": "US",
      "elevation": 843,
      "lat": 39.07839966,
      "lon": -84.21019745,
      "tz": "America\/New_York"
  },
  "KI71": {
      "icao": "KI71",
      "iata": "",
      "name": "Morgan County Airport",
      "city": "Mc Connelsville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 39.6542015076,
      "lon": -81.8031997681,
      "tz": "America\/New_York"
  },
  "KI73": {
      "icao": "KI73",
      "iata": "",
      "name": "Moraine Air Park",
      "city": "Dayton",
      "state": "Ohio",
      "country": "US",
      "elevation": 720,
      "lat": 39.682598114,
      "lon": -84.2429962158,
      "tz": "America\/New_York"
  },
  "KI74": {
      "icao": "KI74",
      "iata": "",
      "name": "Grimes Field",
      "city": "Urbana",
      "state": "Ohio",
      "country": "US",
      "elevation": 1068,
      "lat": 40.13259888,
      "lon": -83.75340271,
      "tz": "America\/New_York"
  },
  "KI75": {
      "icao": "KI75",
      "iata": "",
      "name": "Osceola Municipal Airport",
      "city": "Osceola",
      "state": "Iowa",
      "country": "US",
      "elevation": 1110,
      "lat": 41.0522003174,
      "lon": -93.6896972656,
      "tz": "America\/Chicago"
  },
  "KI76": {
      "icao": "KI76",
      "iata": "",
      "name": "Peru Municipal Airport",
      "city": "Peru",
      "state": "Indiana",
      "country": "US",
      "elevation": 779,
      "lat": 40.7863006592,
      "lon": -86.1464004517,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KI86": {
      "icao": "KI86",
      "iata": "",
      "name": "Perry County Airport",
      "city": "New Lexington",
      "state": "Ohio",
      "country": "US",
      "elevation": 1051,
      "lat": 39.6916007996,
      "lon": -82.1977996826,
      "tz": "America\/New_York"
  },
  "KI93": {
      "icao": "KI93",
      "iata": "",
      "name": "Breckinridge County Airport",
      "city": "Hardinsburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 735,
      "lat": 37.7850990295,
      "lon": -86.4419021606,
      "tz": "America\/Chicago"
  },
  "KI95": {
      "icao": "KI95",
      "iata": "",
      "name": "Hardin County Airport",
      "city": "Kenton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1024,
      "lat": 40.6106987,
      "lon": -83.6436004639,
      "tz": "America\/New_York"
  },
  "KIAB": {
      "icao": "KIAB",
      "iata": "IAB",
      "name": "Mc Connell Air Force Base",
      "city": "Wichita",
      "state": "Kansas",
      "country": "US",
      "elevation": 1371,
      "lat": 37.62189865,
      "lon": -97.26820374,
      "tz": "America\/Chicago"
  },
  "KIAD": {
      "icao": "KIAD",
      "iata": "IAD",
      "name": "Washington Dulles International Airport",
      "city": "Dulles",
      "state": "Virginia",
      "country": "US",
      "elevation": 312,
      "lat": 38.94449997,
      "lon": -77.45580292,
      "tz": "America\/New_York"
  },
  "KIAG": {
      "icao": "KIAG",
      "iata": "IAG",
      "name": "Niagara Falls International Airport",
      "city": "Niagara Falls",
      "state": "New-York",
      "country": "US",
      "elevation": 589,
      "lat": 43.1072998047,
      "lon": -78.9461975098,
      "tz": "America\/New_York"
  },
  "KIAH": {
      "icao": "KIAH",
      "iata": "IAH",
      "name": "George Bush Intercontinental Houston Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 97,
      "lat": 29.9843997955,
      "lon": -95.3414001465,
      "tz": "America\/Chicago"
  },
  "KIBM": {
      "icao": "KIBM",
      "iata": "",
      "name": "Kimball Municipal Robert E Arraj Field",
      "city": "Kimball",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4926,
      "lat": 41.1880989075,
      "lon": -103.6770019531,
      "tz": "America\/Denver"
  },
  "KICL": {
      "icao": "KICL",
      "iata": "ICL",
      "name": "Schenck Field",
      "city": "Clarinda",
      "state": "Iowa",
      "country": "US",
      "elevation": 996,
      "lat": 40.72180176,
      "lon": -95.02639771,
      "tz": "America\/Chicago"
  },
  "KICR": {
      "icao": "KICR",
      "iata": "",
      "name": "Winner Regional Airport",
      "city": "Winner",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2033,
      "lat": 43.39020157,
      "lon": -99.84210205,
      "tz": "America\/Chicago"
  },
  "KICT": {
      "icao": "KICT",
      "iata": "ICT",
      "name": "Wichita Mid Continent Airport",
      "city": "Wichita",
      "state": "Kansas",
      "country": "US",
      "elevation": 1333,
      "lat": 37.6498985291,
      "lon": -97.4330978394,
      "tz": "America\/Chicago"
  },
  "KIDA": {
      "icao": "KIDA",
      "iata": "IDA",
      "name": "Idaho Falls Regional Airport",
      "city": "Idaho Falls",
      "state": "Idaho",
      "country": "US",
      "elevation": 4744,
      "lat": 43.5145988464,
      "lon": -112.0709991455,
      "tz": "America\/Boise"
  },
  "KIDG": {
      "icao": "KIDG",
      "iata": "IDG",
      "name": "Ida Grove Municipal Airport",
      "city": "Ida Grove",
      "state": "Iowa",
      "country": "US",
      "elevation": 1245,
      "lat": 42.3325996399,
      "lon": -95.4449005127,
      "tz": "America\/Chicago"
  },
  "KIDI": {
      "icao": "KIDI",
      "iata": "IDI",
      "name": "Indiana County\/Jimmy Stewart Fld\/ Airport",
      "city": "Indiana",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1405,
      "lat": 40.63219833,
      "lon": -79.10549927,
      "tz": "America\/New_York"
  },
  "KIDL": {
      "icao": "KIDL",
      "iata": "",
      "name": "Indianola Municipal Airport",
      "city": "Indianola",
      "state": "Mississippi",
      "country": "US",
      "elevation": 126,
      "lat": 33.4856987,
      "lon": -90.6789016724,
      "tz": "America\/Chicago"
  },
  "KIDP": {
      "icao": "KIDP",
      "iata": "IDP",
      "name": "Independence Municipal Airport",
      "city": "Independence",
      "state": "Kansas",
      "country": "US",
      "elevation": 825,
      "lat": 37.1584014893,
      "lon": -95.7783966064,
      "tz": "America\/Chicago"
  },
  "KIEN": {
      "icao": "KIEN",
      "iata": "XPR",
      "name": "Pine Ridge Airport",
      "city": "Pine Ridge",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3333,
      "lat": 43.0224990845,
      "lon": -102.511001587,
      "tz": "America\/Denver"
  },
  "KIER": {
      "icao": "KIER",
      "iata": "",
      "name": "Natchitoches Regional Airport",
      "city": "Natchitoches",
      "state": "Louisiana",
      "country": "US",
      "elevation": 121,
      "lat": 31.7357006073,
      "lon": -93.0990982056,
      "tz": "America\/Chicago"
  },
  "KIFA": {
      "icao": "KIFA",
      "iata": "IFA",
      "name": "Iowa Falls Municipal Airport",
      "city": "Iowa Falls",
      "state": "Iowa",
      "country": "US",
      "elevation": 1137,
      "lat": 42.4707984924,
      "lon": -93.2699966431,
      "tz": "America\/Chicago"
  },
  "KIFP": {
      "icao": "KIFP",
      "iata": "IFP",
      "name": "Laughlin Bullhead International Airport",
      "city": "Bullhead City",
      "state": "Arizona",
      "country": "US",
      "elevation": 701,
      "lat": 35.15739822,
      "lon": -114.5599976,
      "tz": "America\/Phoenix"
  },
  "KIGM": {
      "icao": "KIGM",
      "iata": "IGM",
      "name": "Kingman Airport",
      "city": "Kingman",
      "state": "Arizona",
      "country": "US",
      "elevation": 3449,
      "lat": 35.2594985962,
      "lon": -113.93800354,
      "tz": "America\/Phoenix"
  },
  "KIGQ": {
      "icao": "KIGQ",
      "iata": "",
      "name": "Lansing Municipal Airport",
      "city": "Chicago",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 41.5349006653,
      "lon": -87.5295028687,
      "tz": "America\/Chicago"
  },
  "KIGX": {
      "icao": "KIGX",
      "iata": "",
      "name": "Horace Williams Airport",
      "city": "Chapel Hill",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 512,
      "lat": 35.9350013733,
      "lon": -79.06590271,
      "tz": "America\/New_York"
  },
  "KIIB": {
      "icao": "KIIB",
      "iata": "",
      "name": "Independence Municipal Airport",
      "city": "Independence",
      "state": "Iowa",
      "country": "US",
      "elevation": 979,
      "lat": 42.45360184,
      "lon": -91.94760132,
      "tz": "America\/Chicago"
  },
  "KIIY": {
      "icao": "KIIY",
      "iata": "",
      "name": "Washington Wilkes County Airport",
      "city": "Washington",
      "state": "Georgia",
      "country": "US",
      "elevation": 646,
      "lat": 33.77939987,
      "lon": -82.81580353,
      "tz": "America\/New_York"
  },
  "KIJD": {
      "icao": "KIJD",
      "iata": "",
      "name": "Windham Airport",
      "city": "Willimantic",
      "state": "Connecticut",
      "country": "US",
      "elevation": 247,
      "lat": 41.7439994812,
      "lon": -72.1802978516,
      "tz": "America\/New_York"
  },
  "KIJX": {
      "icao": "KIJX",
      "iata": "IJX",
      "name": "Jacksonville Municipal Airport",
      "city": "Jacksonville",
      "state": "Illinois",
      "country": "US",
      "elevation": 624,
      "lat": 39.7746009827,
      "lon": -90.2382965088,
      "tz": "America\/Chicago"
  },
  "KIKG": {
      "icao": "KIKG",
      "iata": "",
      "name": "Kleberg County Airport",
      "city": "Kingsville",
      "state": "Texas",
      "country": "US",
      "elevation": 130,
      "lat": 27.55089951,
      "lon": -98.03089905,
      "tz": "America\/Chicago"
  },
  "KIKK": {
      "icao": "KIKK",
      "iata": "IKK",
      "name": "Greater Kankakee Airport",
      "city": "Kankakee",
      "state": "Illinois",
      "country": "US",
      "elevation": 630,
      "lat": 41.0713996887,
      "lon": -87.8462982178,
      "tz": "America\/Chicago"
  },
  "KIKV": {
      "icao": "KIKV",
      "iata": "",
      "name": "Ankeny Regional Airport",
      "city": "Ankeny",
      "state": "Iowa",
      "country": "US",
      "elevation": 910,
      "lat": 41.6913986206,
      "lon": -93.5663986206,
      "tz": "America\/Chicago"
  },
  "KIKW": {
      "icao": "KIKW",
      "iata": "",
      "name": "Jack Barstow Airport",
      "city": "Midland",
      "state": "Michigan",
      "country": "US",
      "elevation": 635,
      "lat": 43.66289902,
      "lon": -84.26129913,
      "tz": "America\/Detroit"
  },
  "KILE": {
      "icao": "KILE",
      "iata": "ILE",
      "name": "Skylark Field",
      "city": "Killeen",
      "state": "Texas",
      "country": "US",
      "elevation": 848,
      "lat": 31.0858001709,
      "lon": -97.6865005493,
      "tz": "America\/Chicago"
  },
  "KILG": {
      "icao": "KILG",
      "iata": "ILG",
      "name": "New Castle Airport",
      "city": "Wilmington",
      "state": "Delaware",
      "country": "US",
      "elevation": 80,
      "lat": 39.67869949,
      "lon": -75.60649872,
      "tz": "America\/New_York"
  },
  "KILM": {
      "icao": "KILM",
      "iata": "ILM",
      "name": "Wilmington International Airport",
      "city": "Wilmington",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 32,
      "lat": 34.2705993652,
      "lon": -77.9026031494,
      "tz": "America\/New_York"
  },
  "KILN": {
      "icao": "KILN",
      "iata": "ILN",
      "name": "Airborne Airpark",
      "city": "Wilmington",
      "state": "Ohio",
      "country": "US",
      "elevation": 1077,
      "lat": 39.427898407,
      "lon": -83.792098999,
      "tz": "America\/New_York"
  },
  "KIML": {
      "icao": "KIML",
      "iata": "IML",
      "name": "Imperial Municipal Airport",
      "city": "Imperial",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3275,
      "lat": 40.50930023,
      "lon": -101.6210022,
      "tz": "America\/Denver"
  },
  "KIMM": {
      "icao": "KIMM",
      "iata": "IMM",
      "name": "Immokalee Regional Airport",
      "city": "Immokalee",
      "state": "Florida",
      "country": "US",
      "elevation": 37,
      "lat": 26.43320084,
      "lon": -81.40100098,
      "tz": "America\/New_York"
  },
  "KIMS": {
      "icao": "KIMS",
      "iata": "MDN",
      "name": "Madison Municipal Airport",
      "city": "Madison",
      "state": "Indiana",
      "country": "US",
      "elevation": 819,
      "lat": 38.75889969,
      "lon": -85.46549988,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KIMT": {
      "icao": "KIMT",
      "iata": "IMT",
      "name": "Ford Airport",
      "city": "Iron Mountain Kingsford",
      "state": "Michigan",
      "country": "US",
      "elevation": 1182,
      "lat": 45.8184013367,
      "lon": -88.1145019531,
      "tz": "America\/Chicago"
  },
  "KIND": {
      "icao": "KIND",
      "iata": "IND",
      "name": "Indianapolis International Airport",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 797,
      "lat": 39.717300415,
      "lon": -86.2944030762,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KINJ": {
      "icao": "KINJ",
      "iata": "",
      "name": "Hillsboro Municipal Airport",
      "city": "Hillsboro",
      "state": "Illinois",
      "country": "US",
      "elevation": 686,
      "lat": 32.08349991,
      "lon": -97.09719849,
      "tz": "America\/Chicago"
  },
  "KINK": {
      "icao": "KINK",
      "iata": "INK",
      "name": "Winkler County Airport",
      "city": "Wink",
      "state": "Texas",
      "country": "US",
      "elevation": 2822,
      "lat": 31.7796001434,
      "lon": -103.2009963989,
      "tz": "America\/Chicago"
  },
  "KINL": {
      "icao": "KINL",
      "iata": "INL",
      "name": "Falls International Airport",
      "city": "International Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1185,
      "lat": 48.5662002563,
      "lon": -93.4030990601,
      "tz": "America\/Chicago"
  },
  "KINS": {
      "icao": "KINS",
      "iata": "INS",
      "name": "Creech Air Force Base",
      "city": "Indian Springs",
      "state": "Nevada",
      "country": "US",
      "elevation": 3133,
      "lat": 36.5872001648,
      "lon": -115.672996521,
      "tz": "America\/Los_Angeles"
  },
  "KINT": {
      "icao": "KINT",
      "iata": "INT",
      "name": "Smith Reynolds Airport",
      "city": "Winston Salem",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 969,
      "lat": 36.1337013245,
      "lon": -80.2220001221,
      "tz": "America\/New_York"
  },
  "KINW": {
      "icao": "KINW",
      "iata": "INW",
      "name": "Winslow Lindbergh Regional Airport",
      "city": "Winslow",
      "state": "Arizona",
      "country": "US",
      "elevation": 4941,
      "lat": 35.021900177,
      "lon": -110.7229995728,
      "tz": "America\/Phoenix"
  },
  "KIOB": {
      "icao": "KIOB",
      "iata": "",
      "name": "Mount Sterling Montgomery County Airport",
      "city": "Mount Sterling",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1019,
      "lat": 38.05810165,
      "lon": -83.979599,
      "tz": "America\/New_York"
  },
  "KIOW": {
      "icao": "KIOW",
      "iata": "IOW",
      "name": "Iowa City Municipal Airport",
      "city": "Iowa City",
      "state": "Iowa",
      "country": "US",
      "elevation": 668,
      "lat": 41.6391983032,
      "lon": -91.5465011597,
      "tz": "America\/Chicago"
  },
  "KIPJ": {
      "icao": "KIPJ",
      "iata": "",
      "name": "Lincolnton Lincoln County Regional Airport",
      "city": "Lincolnton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 877,
      "lat": 35.48329926,
      "lon": -81.16130066,
      "tz": "America\/New_York"
  },
  "KIPL": {
      "icao": "KIPL",
      "iata": "IPL",
      "name": "Imperial County Airport",
      "city": "Imperial",
      "state": "California",
      "country": "US",
      "elevation": -54,
      "lat": 32.8342018127,
      "lon": -115.57900238,
      "tz": "America\/Los_Angeles"
  },
  "KIPT": {
      "icao": "KIPT",
      "iata": "IPT",
      "name": "Williamsport Regional Airport",
      "city": "Williamsport",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 529,
      "lat": 41.2417984009,
      "lon": -76.9210968018,
      "tz": "America\/New_York"
  },
  "KIRK": {
      "icao": "KIRK",
      "iata": "IRK",
      "name": "Kirksville Regional Airport",
      "city": "Kirksville",
      "state": "Missouri",
      "country": "US",
      "elevation": 966,
      "lat": 40.09349823,
      "lon": -92.5448989868,
      "tz": "America\/Chicago"
  },
  "KIRS": {
      "icao": "KIRS",
      "iata": "IRS",
      "name": "Kirsch Municipal Airport",
      "city": "Sturgis",
      "state": "Michigan",
      "country": "US",
      "elevation": 924,
      "lat": 41.81330109,
      "lon": -85.43900299,
      "tz": "America\/Detroit"
  },
  "KISB": {
      "icao": "KISB",
      "iata": "",
      "name": "Sibley Municipal Airport",
      "city": "Sibley",
      "state": "Iowa",
      "country": "US",
      "elevation": 1538,
      "lat": 43.36949921,
      "lon": -95.75980377,
      "tz": "America\/Chicago"
  },
  "KISM": {
      "icao": "KISM",
      "iata": "ISM",
      "name": "Kissimmee Gateway Airport",
      "city": "Orlando",
      "state": "Florida",
      "country": "US",
      "elevation": 82,
      "lat": 28.2898006439,
      "lon": -81.4371032715,
      "tz": "America\/New_York"
  },
  "KISN": {
      "icao": "KISN",
      "iata": "ISN",
      "name": "Sloulin Field International Airport",
      "city": "Williston",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1982,
      "lat": 48.177898407,
      "lon": -103.641998291,
      "tz": "America\/Chicago"
  },
  "KISO": {
      "icao": "KISO",
      "iata": "ISO",
      "name": "Kinston Regional Jetport At Stallings Field",
      "city": "Kinston",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 93,
      "lat": 35.331401825,
      "lon": -77.6088027954,
      "tz": "America\/New_York"
  },
  "KISP": {
      "icao": "KISP",
      "iata": "ISP",
      "name": "Long Island Mac Arthur Airport",
      "city": "Islip",
      "state": "New-York",
      "country": "US",
      "elevation": 99,
      "lat": 40.79520035,
      "lon": -73.10019684,
      "tz": "America\/New_York"
  },
  "KISQ": {
      "icao": "KISQ",
      "iata": "ISQ",
      "name": "Schoolcraft County Airport",
      "city": "Manistique",
      "state": "Michigan",
      "country": "US",
      "elevation": 684,
      "lat": 45.97460175,
      "lon": -86.17179871,
      "tz": "America\/Detroit"
  },
  "KISW": {
      "icao": "KISW",
      "iata": "ISW",
      "name": "Alexander Field South Wood County Airport",
      "city": "Wisconsin Rapids",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1021,
      "lat": 44.3602981567,
      "lon": -89.8389968872,
      "tz": "America\/Chicago"
  },
  "KITH": {
      "icao": "KITH",
      "iata": "ITH",
      "name": "Ithaca Tompkins Regional Airport",
      "city": "Ithaca",
      "state": "New-York",
      "country": "US",
      "elevation": 1099,
      "lat": 42.4910011292,
      "lon": -76.4583969116,
      "tz": "America\/New_York"
  },
  "KITR": {
      "icao": "KITR",
      "iata": "",
      "name": "Kit Carson County Airport",
      "city": "Burlington",
      "state": "Colorado",
      "country": "US",
      "elevation": 4219,
      "lat": 39.2425003052,
      "lon": -102.2850036621,
      "tz": "America\/Denver"
  },
  "KIUA": {
      "icao": "KIUA",
      "iata": "",
      "name": "Canandaigua Airport",
      "city": "Canandaigua",
      "state": "New York",
      "country": "US",
      "elevation": 814,
      "lat": 42.9089019,
      "lon": -77.3252263,
      "tz": "America\/New_York"
  },
  "KIWA": {
      "icao": "KIWA",
      "iata": "AZA",
      "name": "Phoenix-Mesa-Gateway Airport",
      "city": "Phoenix",
      "state": "Arizona",
      "country": "US",
      "elevation": 1382,
      "lat": 33.30780029,
      "lon": -111.6549988,
      "tz": "America\/Phoenix"
  },
  "KIWD": {
      "icao": "KIWD",
      "iata": "IWD",
      "name": "Gogebic Iron County Airport",
      "city": "Ironwood",
      "state": "Michigan",
      "country": "US",
      "elevation": 1230,
      "lat": 46.5275001526,
      "lon": -90.131401062,
      "tz": "America\/Menominee"
  },
  "KIWH": {
      "icao": "KIWH",
      "iata": "",
      "name": "Wabash Municipal Airport",
      "city": "Wabash",
      "state": "Indiana",
      "country": "US",
      "elevation": 796,
      "lat": 40.7620010376,
      "lon": -85.7987976074,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KIWI": {
      "icao": "KIWI",
      "iata": "ISS",
      "name": "Wiscasset Airport",
      "city": "Wiscasset",
      "state": "Maine",
      "country": "US",
      "elevation": 70,
      "lat": 43.9613990784,
      "lon": -69.712600708,
      "tz": "America\/New_York"
  },
  "KIWS": {
      "icao": "KIWS",
      "iata": "IWS",
      "name": "West Houston Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 111,
      "lat": 29.8181991577,
      "lon": -95.6725997925,
      "tz": "America\/Chicago"
  },
  "KIXA": {
      "icao": "KIXA",
      "iata": "",
      "name": "Halifax-Northampton Regional Airport",
      "city": "Roanoke Rapids",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 145,
      "lat": 36.32979965,
      "lon": -77.63523102,
      "tz": "America\/New_York"
  },
  "KIXD": {
      "icao": "KIXD",
      "iata": "JCI",
      "name": "New Century Aircenter Airport",
      "city": "Olathe",
      "state": "Kansas",
      "country": "US",
      "elevation": 1087,
      "lat": 38.8308982849,
      "lon": -94.890296936,
      "tz": "America\/Chicago"
  },
  "KIYK": {
      "icao": "KIYK",
      "iata": "IYK",
      "name": "Inyokern Airport",
      "city": "Inyokern",
      "state": "California",
      "country": "US",
      "elevation": 2457,
      "lat": 35.65879822,
      "lon": -117.8300018,
      "tz": "America\/Los_Angeles"
  },
  "KIZA": {
      "icao": "KIZA",
      "iata": "SQA",
      "name": "Santa Ynez Airport",
      "city": "Santa Ynez",
      "state": "California",
      "country": "US",
      "elevation": 674,
      "lat": 34.60680008,
      "lon": -120.0759964,
      "tz": "America\/Los_Angeles"
  },
  "KIZG": {
      "icao": "KIZG",
      "iata": "FRY",
      "name": "Eastern Slopes Regional Airport",
      "city": "Fryeburg",
      "state": "Maine",
      "country": "US",
      "elevation": 454,
      "lat": 43.9911003113,
      "lon": -70.9478988647,
      "tz": "America\/New_York"
  },
  "KJAC": {
      "icao": "KJAC",
      "iata": "JAC",
      "name": "Jackson Hole Airport",
      "city": "Jackson",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6451,
      "lat": 43.6072998047,
      "lon": -110.7379989624,
      "tz": "America\/Denver"
  },
  "KJAN": {
      "icao": "KJAN",
      "iata": "JAN",
      "name": "Jackson Evers International Airport",
      "city": "Jackson",
      "state": "Mississippi",
      "country": "US",
      "elevation": 346,
      "lat": 32.3111991882,
      "lon": -90.0758972168,
      "tz": "America\/Chicago"
  },
  "KJAQ": {
      "icao": "KJAQ",
      "iata": "",
      "name": "Westover Field Amador County Airport",
      "city": "Jackson",
      "state": "California",
      "country": "US",
      "elevation": 1690,
      "lat": 38.3768005371,
      "lon": -120.793998718,
      "tz": "America\/Los_Angeles"
  },
  "KJAS": {
      "icao": "KJAS",
      "iata": "JAS",
      "name": "Jasper County-Bell Field",
      "city": "Jasper",
      "state": "Texas",
      "country": "US",
      "elevation": 213,
      "lat": 30.88570023,
      "lon": -94.03489685,
      "tz": "America\/Chicago"
  },
  "KJAU": {
      "icao": "KJAU",
      "iata": "",
      "name": "Campbell County Airport",
      "city": "Jacksboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1180,
      "lat": 36.3345985413,
      "lon": -84.1623001099,
      "tz": "America\/New_York"
  },
  "KJAX": {
      "icao": "KJAX",
      "iata": "JAX",
      "name": "Jacksonville International Airport",
      "city": "Jacksonville",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 30.4941005707,
      "lon": -81.6878967285,
      "tz": "America\/New_York"
  },
  "KJBR": {
      "icao": "KJBR",
      "iata": "JBR",
      "name": "Jonesboro Municipal Airport",
      "city": "Jonesboro",
      "state": "Arkansas",
      "country": "US",
      "elevation": 262,
      "lat": 35.8316993713,
      "lon": -90.6464004517,
      "tz": "America\/Chicago"
  },
  "KJCT": {
      "icao": "KJCT",
      "iata": "JCT",
      "name": "Kimble County Airport",
      "city": "Junction",
      "state": "Texas",
      "country": "US",
      "elevation": 1749,
      "lat": 30.5112991333,
      "lon": -99.7634963989,
      "tz": "America\/Chicago"
  },
  "KJDD": {
      "icao": "KJDD",
      "iata": "",
      "name": "Wood County Airport",
      "city": "Mineola\/Quitman",
      "state": "Texas",
      "country": "US",
      "elevation": 434,
      "lat": 32.7421989441,
      "lon": -95.4964981079,
      "tz": "America\/Chicago"
  },
  "KJDN": {
      "icao": "KJDN",
      "iata": "JDN",
      "name": "Jordan Airport",
      "city": "Jordan",
      "state": "Montana",
      "country": "US",
      "elevation": 2662,
      "lat": 47.3288002014,
      "lon": -106.9530029297,
      "tz": "America\/Denver"
  },
  "KJEF": {
      "icao": "KJEF",
      "iata": "JEF",
      "name": "Jefferson City Memorial Airport",
      "city": "Jefferson City",
      "state": "Missouri",
      "country": "US",
      "elevation": 549,
      "lat": 38.5912017822,
      "lon": -92.1560974121,
      "tz": "America\/Chicago"
  },
  "KJER": {
      "icao": "KJER",
      "iata": "",
      "name": "Jerome County Airport",
      "city": "Jerome",
      "state": "Idaho",
      "country": "US",
      "elevation": 4053,
      "lat": 42.7266998291,
      "lon": -114.4570007324,
      "tz": "America\/Boise"
  },
  "KJES": {
      "icao": "KJES",
      "iata": "",
      "name": "Jesup Wayne County Airport",
      "city": "Jesup",
      "state": "Georgia",
      "country": "US",
      "elevation": 107,
      "lat": 31.55400085,
      "lon": -81.88249969,
      "tz": "America\/New_York"
  },
  "KJFK": {
      "icao": "KJFK",
      "iata": "JFK",
      "name": "John F Kennedy International Airport",
      "city": "New York",
      "state": "New-York",
      "country": "US",
      "elevation": 13,
      "lat": 40.63980103,
      "lon": -73.77890015,
      "tz": "America\/New_York"
  },
  "KJFX": {
      "icao": "KJFX",
      "iata": "",
      "name": "Walker County Bevill Field",
      "city": "Jasper",
      "state": "Alabama",
      "country": "US",
      "elevation": 482,
      "lat": 33.90200043,
      "lon": -87.31420135,
      "tz": "America\/Chicago"
  },
  "KJFZ": {
      "icao": "KJFZ",
      "iata": "",
      "name": "Tazewell County Airport",
      "city": "Richlands",
      "state": "Virginia",
      "country": "US",
      "elevation": 2653,
      "lat": 37.06370163,
      "lon": -81.7983017,
      "tz": "America\/New_York"
  },
  "KJGG": {
      "icao": "KJGG",
      "iata": "",
      "name": "Williamsburg Jamestown Airport",
      "city": "Williamsburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 49,
      "lat": 37.239200592,
      "lon": -76.7161026001,
      "tz": "America\/New_York"
  },
  "KJHW": {
      "icao": "KJHW",
      "iata": "JHW",
      "name": "Chautauqua County-Jamestown Airport",
      "city": "Jamestown",
      "state": "New-York",
      "country": "US",
      "elevation": 1723,
      "lat": 42.15340042,
      "lon": -79.25800323,
      "tz": "America\/New_York"
  },
  "KJKA": {
      "icao": "KJKA",
      "iata": "GUF",
      "name": "Jack Edwards Airport",
      "city": "Gulf Shores",
      "state": "Alabama",
      "country": "US",
      "elevation": 17,
      "lat": 30.29050064,
      "lon": -87.67179871,
      "tz": "America\/Chicago"
  },
  "KJKJ": {
      "icao": "KJKJ",
      "iata": "",
      "name": "Moorhead Municipal Airport",
      "city": "Moorhead",
      "state": "Minnesota",
      "country": "US",
      "elevation": 918,
      "lat": 46.83929825,
      "lon": -96.66369629,
      "tz": "America\/Chicago"
  },
  "KJKL": {
      "icao": "KJKL",
      "iata": "",
      "name": "Julian Carroll Airport",
      "city": "Jackson",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1381,
      "lat": 37.5938987732,
      "lon": -83.3172988892,
      "tz": "America\/New_York"
  },
  "KJLN": {
      "icao": "KJLN",
      "iata": "JLN",
      "name": "Joplin Regional Airport",
      "city": "Joplin",
      "state": "Missouri",
      "country": "US",
      "elevation": 981,
      "lat": 37.1517982483,
      "lon": -94.498298645,
      "tz": "America\/Chicago"
  },
  "KJMR": {
      "icao": "KJMR",
      "iata": "",
      "name": "Mora Municipal Airport",
      "city": "Mora",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1012,
      "lat": 45.886100769,
      "lon": -93.2717971802,
      "tz": "America\/Chicago"
  },
  "KJMS": {
      "icao": "KJMS",
      "iata": "JMS",
      "name": "Jamestown Regional Airport",
      "city": "Jamestown",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1500,
      "lat": 46.92969894,
      "lon": -98.67819977,
      "tz": "America\/Chicago"
  },
  "KJNX": {
      "icao": "KJNX",
      "iata": "",
      "name": "Johnston County Airport",
      "city": "Smithfield",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 164,
      "lat": 35.54090118,
      "lon": -78.39029694,
      "tz": "America\/New_York"
  },
  "KJOT": {
      "icao": "KJOT",
      "iata": "JOT",
      "name": "Joliet Regional Airport",
      "city": "Joliet",
      "state": "Illinois",
      "country": "US",
      "elevation": 582,
      "lat": 41.51779938,
      "lon": -88.17549896,
      "tz": "America\/Chicago"
  },
  "KJQF": {
      "icao": "KJQF",
      "iata": "USA",
      "name": "Concord Regional Airport",
      "city": "Concord",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 705,
      "lat": 35.3877983093,
      "lon": -80.7090988159,
      "tz": "America\/New_York"
  },
  "KJSO": {
      "icao": "KJSO",
      "iata": "JKV",
      "name": "Cherokee County Airport",
      "city": "Jacksonville",
      "state": "Texas",
      "country": "US",
      "elevation": 677,
      "lat": 31.8693008423,
      "lon": -95.2173995972,
      "tz": "America\/Chicago"
  },
  "KJST": {
      "icao": "KJST",
      "iata": "JST",
      "name": "John Murtha Johnstown Cambria County Airport",
      "city": "Johnstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2284,
      "lat": 40.3161010742,
      "lon": -78.8339004517,
      "tz": "America\/New_York"
  },
  "KJSV": {
      "icao": "KJSV",
      "iata": "",
      "name": "Sallisaw Municipal Airport",
      "city": "Sallisaw",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 527,
      "lat": 35.4382019,
      "lon": -94.80280304,
      "tz": "America\/Chicago"
  },
  "KJSY": {
      "icao": "KJSY",
      "iata": "",
      "name": "Joseph State Airport",
      "city": "Joseph",
      "state": "Oregon",
      "country": "US",
      "elevation": 4121,
      "lat": 45.3596000671,
      "lon": -117.253997803,
      "tz": "America\/Los_Angeles"
  },
  "KJVL": {
      "icao": "KJVL",
      "iata": "JVL",
      "name": "Southern Wisconsin Regional Airport",
      "city": "Janesville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 808,
      "lat": 42.620300293,
      "lon": -89.0416030884,
      "tz": "America\/Chicago"
  },
  "KJVW": {
      "icao": "KJVW",
      "iata": "",
      "name": "John Bell Williams Airport",
      "city": "Raymond",
      "state": "Mississippi",
      "country": "US",
      "elevation": 247,
      "lat": 32.30329895,
      "lon": -90.40850067,
      "tz": "America\/Chicago"
  },
  "KJVY": {
      "icao": "KJVY",
      "iata": "",
      "name": "Clark Regional Airport",
      "city": "Jeffersonville",
      "state": "Indiana",
      "country": "US",
      "elevation": 474,
      "lat": 38.365398407,
      "lon": -85.7381973267,
      "tz": "America\/Kentucky\/Louisville"
  },
  "KJWG": {
      "icao": "KJWG",
      "iata": "",
      "name": "Watonga Regional Airport",
      "city": "Watonga",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1550,
      "lat": 35.86470032,
      "lon": -98.42079926,
      "tz": "America\/Chicago"
  },
  "KJWN": {
      "icao": "KJWN",
      "iata": "",
      "name": "John C Tune Airport",
      "city": "Nashville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 495,
      "lat": 36.1823997498,
      "lon": -86.8867034912,
      "tz": "America\/Chicago"
  },
  "KJWY": {
      "icao": "KJWY",
      "iata": "",
      "name": "Mid Way Regional Airport",
      "city": "Midlothian\/Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 713,
      "lat": 32.4561004639,
      "lon": -96.912399292,
      "tz": "America\/Chicago"
  },
  "KJXI": {
      "icao": "KJXI",
      "iata": "",
      "name": "Fox Stephens Field Gilmer Municipal Airport",
      "city": "Gilmer",
      "state": "Texas",
      "country": "US",
      "elevation": 415,
      "lat": 32.6990013123,
      "lon": -94.9488983154,
      "tz": "America\/Chicago"
  },
  "KJXN": {
      "icao": "KJXN",
      "iata": "JXN",
      "name": "Jackson County Reynolds Field",
      "city": "Jackson",
      "state": "Michigan",
      "country": "US",
      "elevation": 1001,
      "lat": 42.2597999573,
      "lon": -84.4593963623,
      "tz": "America\/Detroit"
  },
  "KJYG": {
      "icao": "KJYG",
      "iata": "",
      "name": "St James Municipal Airport",
      "city": "St James",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1067,
      "lat": 43.9863014221,
      "lon": -94.5578994751,
      "tz": "America\/Chicago"
  },
  "KJYL": {
      "icao": "KJYL",
      "iata": "",
      "name": "Plantation Airpark",
      "city": "Sylvania",
      "state": "Georgia",
      "country": "US",
      "elevation": 188,
      "lat": 32.6453018188,
      "lon": -81.5970993042,
      "tz": "America\/New_York"
  },
  "KJYM": {
      "icao": "KJYM",
      "iata": "",
      "name": "Hillsdale Municipal Airport",
      "city": "Hillsdale",
      "state": "Michigan",
      "country": "US",
      "elevation": 1182,
      "lat": 41.9212989807,
      "lon": -84.5858001709,
      "tz": "America\/Detroit"
  },
  "KJYO": {
      "icao": "KJYO",
      "iata": "",
      "name": "Leesburg Executive Airport",
      "city": "Leesburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 389,
      "lat": 39.077999115,
      "lon": -77.5575027466,
      "tz": "America\/New_York"
  },
  "KJYR": {
      "icao": "KJYR",
      "iata": "",
      "name": "York Municipal Airport",
      "city": "York",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1670,
      "lat": 40.8968009949,
      "lon": -97.6228027344,
      "tz": "America\/Chicago"
  },
  "KJZI": {
      "icao": "KJZI",
      "iata": "",
      "name": "Charleston Executive Airport",
      "city": "Charleston",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 17,
      "lat": 32.7009010315,
      "lon": -80.0028991699,
      "tz": "America\/New_York"
  },
  "KJZP": {
      "icao": "KJZP",
      "iata": "",
      "name": "Pickens County Airport",
      "city": "Jasper",
      "state": "Georgia",
      "country": "US",
      "elevation": 1535,
      "lat": 34.4533996582,
      "lon": -84.4573974609,
      "tz": "America\/New_York"
  },
  "KK02": {
      "icao": "KK02",
      "iata": "",
      "name": "Perryville Municipal Airport",
      "city": "Perryville",
      "state": "Missouri",
      "country": "US",
      "elevation": 372,
      "lat": 37.86869812,
      "lon": -89.86209869,
      "tz": "America\/Chicago"
  },
  "KK06": {
      "icao": "KK06",
      "iata": "",
      "name": "Greater Beardstown Airport",
      "city": "Beardstown",
      "state": "Illinois",
      "country": "US",
      "elevation": 465,
      "lat": 39.973400116,
      "lon": -90.4037017822,
      "tz": "America\/Chicago"
  },
  "KK09": {
      "icao": "KK09",
      "iata": "",
      "name": "Piseco Airport",
      "city": "Piseco",
      "state": "New-York",
      "country": "US",
      "elevation": 1703,
      "lat": 43.4533996582,
      "lon": -74.5176010132,
      "tz": "America\/New_York"
  },
  "KK16": {
      "icao": "KK16",
      "iata": "",
      "name": "Becks Grove Airport",
      "city": "Rome",
      "state": "New-York",
      "country": "US",
      "elevation": 450,
      "lat": 43.2583999634,
      "lon": -75.6037979126,
      "tz": "America\/New_York"
  },
  "KK19": {
      "icao": "KK19",
      "iata": "",
      "name": "Albany Municipal Airport",
      "city": "Albany",
      "state": "Missouri",
      "country": "US",
      "elevation": 886,
      "lat": 40.2621994019,
      "lon": -94.3389968872,
      "tz": "America\/Chicago"
  },
  "KK20": {
      "icao": "KK20",
      "iata": "",
      "name": "Wendell H Ford Airport",
      "city": "Hazard",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1253,
      "lat": 37.3877983093,
      "lon": -83.2621002197,
      "tz": "America\/New_York"
  },
  "KK22": {
      "icao": "KK22",
      "iata": "",
      "name": "Big Sandy Regional Airport",
      "city": "Prestonsburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1221,
      "lat": 37.7509994507,
      "lon": -82.6367034912,
      "tz": "America\/New_York"
  },
  "KK24": {
      "icao": "KK24",
      "iata": "",
      "name": "Russell County Airport",
      "city": "Jamestown",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1011,
      "lat": 37.0097007751,
      "lon": -85.1026992798,
      "tz": "America\/Chicago"
  },
  "KK32": {
      "icao": "KK32",
      "iata": "",
      "name": "Riverside Airport",
      "city": "Wichita",
      "state": "Kansas",
      "country": "US",
      "elevation": 1335,
      "lat": 37.7481002808,
      "lon": -97.4067001343,
      "tz": "America\/Chicago"
  },
  "KK33": {
      "icao": "KK33",
      "iata": "",
      "name": "Salem Memorial Airport",
      "city": "Salem",
      "state": "Missouri",
      "country": "US",
      "elevation": 1241,
      "lat": 37.6152000427,
      "lon": -91.6044006348,
      "tz": "America\/Chicago"
  },
  "KK36": {
      "icao": "KK36",
      "iata": "",
      "name": "Onawa Municipal Airport",
      "city": "Onawa",
      "state": "Iowa",
      "country": "US",
      "elevation": 1047,
      "lat": 42.0043983459,
      "lon": -96.1035995483,
      "tz": "America\/Chicago"
  },
  "KK38": {
      "icao": "KK38",
      "iata": "",
      "name": "Washington County Memorial Airport",
      "city": "Washington",
      "state": "Kansas",
      "country": "US",
      "elevation": 1435,
      "lat": 39.73350143,
      "lon": -97.04769897,
      "tz": "America\/Chicago"
  },
  "KK39": {
      "icao": "KK39",
      "iata": "",
      "name": "St Clair Regional Airport",
      "city": "St Clair",
      "state": "Missouri",
      "country": "US",
      "elevation": 656,
      "lat": 38.3759002686,
      "lon": -90.970703125,
      "tz": "America\/Chicago"
  },
  "KK44": {
      "icao": "KK44",
      "iata": "",
      "name": "Beaver Municipal Airport",
      "city": "Beaver",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2491,
      "lat": 36.7989006042,
      "lon": -100.5299987793,
      "tz": "America\/Chicago"
  },
  "KK49": {
      "icao": "KK49",
      "iata": "",
      "name": "Municipal Airport",
      "city": "Texhoma",
      "state": "Texas",
      "country": "US",
      "elevation": 3462,
      "lat": 36.5055999756,
      "lon": -101.8140029907,
      "tz": "America\/Chicago"
  },
  "KK51": {
      "icao": "KK51",
      "iata": "",
      "name": "Medicine Lodge Airport",
      "city": "Medicine Lodge",
      "state": "Kansas",
      "country": "US",
      "elevation": 1543,
      "lat": 37.2625007629,
      "lon": -98.5462036133,
      "tz": "America\/Chicago"
  },
  "KK52": {
      "icao": "KK52",
      "iata": "",
      "name": "Capt. Ben Smith Airfield - Monroe City Airport",
      "city": "Monroe City",
      "state": "Missouri",
      "country": "US",
      "elevation": 737,
      "lat": 39.63439941,
      "lon": -91.72699738,
      "tz": "America\/Chicago"
  },
  "KK57": {
      "icao": "KK57",
      "iata": "",
      "name": "Gould Peterson Municipal Airport",
      "city": "Tarkio",
      "state": "Missouri",
      "country": "US",
      "elevation": 913,
      "lat": 40.44580078,
      "lon": -95.3628006,
      "tz": "America\/Chicago"
  },
  "KK58": {
      "icao": "KK58",
      "iata": "",
      "name": "Harold Krier Field",
      "city": "Ashland",
      "state": "Kansas",
      "country": "US",
      "elevation": 1951,
      "lat": 37.1666984558,
      "lon": -99.775100708,
      "tz": "America\/Chicago"
  },
  "KK59": {
      "icao": "KK59",
      "iata": "",
      "name": "Amelia Earhart Airport",
      "city": "Atchison",
      "state": "Kansas",
      "country": "US",
      "elevation": 1073,
      "lat": 39.5704994202,
      "lon": -95.1802978516,
      "tz": "America\/Chicago"
  },
  "KK61": {
      "icao": "KK61",
      "iata": "",
      "name": "Moritz Memorial Airport",
      "city": "Beloit",
      "state": "Kansas",
      "country": "US",
      "elevation": 1416,
      "lat": 39.4711990356,
      "lon": -98.1287994385,
      "tz": "America\/Chicago"
  },
  "KK62": {
      "icao": "KK62",
      "iata": "",
      "name": "Gene Snyder Airport",
      "city": "Falmouth",
      "state": "Kentucky",
      "country": "US",
      "elevation": 899,
      "lat": 38.70420074,
      "lon": -84.39160156,
      "tz": "America\/New_York"
  },
  "KK74": {
      "icao": "KK74",
      "iata": "",
      "name": "Hamry Field",
      "city": "Kindred",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 947,
      "lat": 46.6487999,
      "lon": -96.99859619,
      "tz": "America\/Chicago"
  },
  "KK75": {
      "icao": "KK75",
      "iata": "",
      "name": "Osborne Municipal Airport",
      "city": "Osborne",
      "state": "Kansas",
      "country": "US",
      "elevation": 1565,
      "lat": 39.4291992188,
      "lon": -98.6794967651,
      "tz": "America\/Chicago"
  },
  "KK77": {
      "icao": "KK77",
      "iata": "",
      "name": "Freedom Municipal Airport",
      "city": "Freedom",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1517,
      "lat": 36.7584991455,
      "lon": -99.1019973755,
      "tz": "America\/Chicago"
  },
  "KK78": {
      "icao": "KK78",
      "iata": "",
      "name": "Abilene Municipal Airport",
      "city": "Abilene",
      "state": "Kansas",
      "country": "US",
      "elevation": 1152,
      "lat": 38.9040985107,
      "lon": -97.2359008789,
      "tz": "America\/Chicago"
  },
  "KK79": {
      "icao": "KK79",
      "iata": "",
      "name": "Jetmore Municipal Airport",
      "city": "Jetmore",
      "state": "Kansas",
      "country": "US",
      "elevation": 2466,
      "lat": 37.984500885,
      "lon": -99.8943023682,
      "tz": "America\/Chicago"
  },
  "KK81": {
      "icao": "KK81",
      "iata": "",
      "name": "Miami County Airport",
      "city": "Paola",
      "state": "Kansas",
      "country": "US",
      "elevation": 940,
      "lat": 38.540599823,
      "lon": -94.920501709,
      "tz": "America\/Chicago"
  },
  "KK82": {
      "icao": "KK82",
      "iata": "",
      "name": "Smith Center Municipal Airport",
      "city": "Smith Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1799,
      "lat": 39.761100769,
      "lon": -98.7934036255,
      "tz": "America\/Chicago"
  },
  "KK83": {
      "icao": "KK83",
      "iata": "",
      "name": "Sabetha Municipal Airport",
      "city": "Sabetha",
      "state": "Kansas",
      "country": "US",
      "elevation": 1330,
      "lat": 39.9042015076,
      "lon": -95.7794036865,
      "tz": "America\/Chicago"
  },
  "KK88": {
      "icao": "KK88",
      "iata": "",
      "name": "Allen County Airport",
      "city": "Iola",
      "state": "Kansas",
      "country": "US",
      "elevation": 1015,
      "lat": 37.870098114,
      "lon": -95.3863983154,
      "tz": "America\/Chicago"
  },
  "KK89": {
      "icao": "KK89",
      "iata": "",
      "name": "Macon Fower Memorial Airport",
      "city": "Macon",
      "state": "Missouri",
      "country": "US",
      "elevation": 874,
      "lat": 39.7286987305,
      "lon": -92.4645004272,
      "tz": "America\/Chicago"
  },
  "KK94": {
      "icao": "KK94",
      "iata": "",
      "name": "Rush County Airport",
      "city": "La Crosse",
      "state": "Kansas",
      "country": "US",
      "elevation": 2070,
      "lat": 38.5483016968,
      "lon": -99.2886962891,
      "tz": "America\/Chicago"
  },
  "KKIC": {
      "icao": "KKIC",
      "iata": "KIC",
      "name": "Mesa Del Rey Airport",
      "city": "King City",
      "state": "California",
      "country": "US",
      "elevation": 370,
      "lat": 36.2280006409,
      "lon": -121.1220016479,
      "tz": "America\/Los_Angeles"
  },
  "KKLS": {
      "icao": "KKLS",
      "iata": "KLS",
      "name": "Southwest Washington Regional Airport",
      "city": "Kelso",
      "state": "Washington",
      "country": "US",
      "elevation": 20,
      "lat": 46.1180000305,
      "lon": -122.898002625,
      "tz": "America\/Los_Angeles"
  },
  "KKNB": {
      "icao": "KKNB",
      "iata": "KNB",
      "name": "Kanab Municipal Airport",
      "city": "Kanab",
      "state": "Utah",
      "country": "US",
      "elevation": 4868,
      "lat": 37.011100769,
      "lon": -112.53099823,
      "tz": "America\/Denver"
  },
  "KL00": {
      "icao": "KL00",
      "iata": "",
      "name": "Rosamond Skypark Airport",
      "city": "Rosamond",
      "state": "California",
      "country": "US",
      "elevation": 2415,
      "lat": 34.8708000183,
      "lon": -118.2089996338,
      "tz": "America\/Los_Angeles"
  },
  "KL04": {
      "icao": "KL04",
      "iata": "",
      "name": "Holtville Airport",
      "city": "Holtville",
      "state": "California",
      "country": "US",
      "elevation": 59,
      "lat": 32.8403015137,
      "lon": -115.266998291,
      "tz": "America\/Los_Angeles"
  },
  "KL05": {
      "icao": "KL05",
      "iata": "",
      "name": "Kern Valley Airport",
      "city": "Kernville",
      "state": "California",
      "country": "US",
      "elevation": 2614,
      "lat": 35.7282981873,
      "lon": -118.4199981689,
      "tz": "America\/Los_Angeles"
  },
  "KL06": {
      "icao": "KL06",
      "iata": "",
      "name": "Furnace Creek Airport",
      "city": "Death Valley National Park",
      "state": "California",
      "country": "US",
      "elevation": -210,
      "lat": 36.4637985229,
      "lon": -116.8809967041,
      "tz": "America\/Los_Angeles"
  },
  "KL08": {
      "icao": "KL08",
      "iata": "BXS",
      "name": "Borrego Valley Airport",
      "city": "Borrego Springs",
      "state": "California",
      "country": "US",
      "elevation": 520,
      "lat": 33.2589988708,
      "lon": -116.320999146,
      "tz": "America\/Los_Angeles"
  },
  "KL09": {
      "icao": "KL09",
      "iata": "",
      "name": "Stovepipe Wells Airport",
      "city": "Death Valley National Park",
      "state": "California",
      "country": "US",
      "elevation": 25,
      "lat": 36.6040992737,
      "lon": -117.158996582,
      "tz": "America\/Los_Angeles"
  },
  "KL17": {
      "icao": "KL17",
      "iata": "",
      "name": "Taft Kern County Airport",
      "city": "Taft",
      "state": "California",
      "country": "US",
      "elevation": 875,
      "lat": 35.1411018372,
      "lon": -119.4410018921,
      "tz": "America\/Los_Angeles"
  },
  "KL19": {
      "icao": "KL19",
      "iata": "",
      "name": "Wasco Kern County Airport",
      "city": "Wasco",
      "state": "California",
      "country": "US",
      "elevation": 313,
      "lat": 35.6197013855,
      "lon": -119.3539962769,
      "tz": "America\/Los_Angeles"
  },
  "KL22": {
      "icao": "KL22",
      "iata": "",
      "name": "Yucca Valley Airport",
      "city": "Yucca Valley",
      "state": "California",
      "country": "US",
      "elevation": 3224,
      "lat": 34.1277999878,
      "lon": -116.4079971313,
      "tz": "America\/Los_Angeles"
  },
  "KL23": {
      "icao": "KL23",
      "iata": "",
      "name": "Pahute Mesa Airstrip",
      "city": "Mercury",
      "state": "Nevada",
      "country": "US",
      "elevation": 5068,
      "lat": 37.1026992798,
      "lon": -116.31300354,
      "tz": "America\/Los_Angeles"
  },
  "KL26": {
      "icao": "KL26",
      "iata": "",
      "name": "Hesperia Airport",
      "city": "Hesperia",
      "state": "California",
      "country": "US",
      "elevation": 3390,
      "lat": 34.3772010803,
      "lon": -117.3160018921,
      "tz": "America\/Los_Angeles"
  },
  "KL31": {
      "icao": "KL31",
      "iata": "",
      "name": "St. Tammany Regional Airport",
      "city": "Covington",
      "state": "Louisiana",
      "country": "US",
      "elevation": 39,
      "lat": 30.44510078,
      "lon": -89.98889923,
      "tz": "America\/Chicago"
  },
  "KL32": {
      "icao": "KL32",
      "iata": "",
      "name": "Jonesville Airport",
      "city": "Jonesville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 56,
      "lat": 31.6252994537,
      "lon": -91.8292007446,
      "tz": "America\/Chicago"
  },
  "KL33": {
      "icao": "KL33",
      "iata": "",
      "name": "Tensas Parish Airport",
      "city": "St Joseph",
      "state": "Louisiana",
      "country": "US",
      "elevation": 74,
      "lat": 31.9974002838,
      "lon": -91.2472991943,
      "tz": "America\/Chicago"
  },
  "KL35": {
      "icao": "KL35",
      "iata": "",
      "name": "Big Bear City Airport",
      "city": "Big Bear City",
      "state": "California",
      "country": "US",
      "elevation": 6752,
      "lat": 34.2638015747,
      "lon": -116.8560028076,
      "tz": "America\/Los_Angeles"
  },
  "KL38": {
      "icao": "KL38",
      "iata": "",
      "name": "Louisiana Regional Airport",
      "city": "Gonzales",
      "state": "Louisiana",
      "country": "US",
      "elevation": 14,
      "lat": 30.17270088,
      "lon": -90.94059753,
      "tz": "America\/Chicago"
  },
  "KL39": {
      "icao": "KL39",
      "iata": "",
      "name": "Leesville Airport",
      "city": "Leesville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 282,
      "lat": 31.16860008,
      "lon": -93.34249878,
      "tz": "America\/Chicago"
  },
  "KL41": {
      "icao": "KL41",
      "iata": "",
      "name": "Marble Canyon Airport",
      "city": "Marble Canyon",
      "state": "Arizona",
      "country": "US",
      "elevation": 3603,
      "lat": 36.8125,
      "lon": -111.6470031738,
      "tz": "America\/Denver"
  },
  "KL45": {
      "icao": "KL45",
      "iata": "",
      "name": "Bakersfield Municipal Airport",
      "city": "Bakersfield",
      "state": "California",
      "country": "US",
      "elevation": 378,
      "lat": 35.32479858,
      "lon": -118.9960022,
      "tz": "America\/Los_Angeles"
  },
  "KL47": {
      "icao": "KL47",
      "iata": "",
      "name": "Olla Airport",
      "city": "Olla",
      "state": "Louisiana",
      "country": "US",
      "elevation": 130,
      "lat": 31.8953990936,
      "lon": -92.2179031372,
      "tz": "America\/Chicago"
  },
  "KL52": {
      "icao": "KL52",
      "iata": "",
      "name": "Oceano County Airport",
      "city": "Oceano",
      "state": "California",
      "country": "US",
      "elevation": 14,
      "lat": 35.1015292,
      "lon": -120.6223153,
      "tz": "America\/Los_Angeles"
  },
  "KL62": {
      "icao": "KL62",
      "iata": "",
      "name": "Elk Hills Buttonwillow Airport",
      "city": "Buttonwillow",
      "state": "California",
      "country": "US",
      "elevation": 326,
      "lat": 35.3526992798,
      "lon": -119.4789962769,
      "tz": "America\/Los_Angeles"
  },
  "KL64": {
      "icao": "KL64",
      "iata": "",
      "name": "Desert Center Airport",
      "city": "Desert Center",
      "state": "California",
      "country": "US",
      "elevation": 559,
      "lat": 33.7486000061,
      "lon": -115.3229980469,
      "tz": "America\/Los_Angeles"
  },
  "KL65": {
      "icao": "KL65",
      "iata": "",
      "name": "Perris Valley Airport",
      "city": "Perris",
      "state": "California",
      "country": "US",
      "elevation": 1413,
      "lat": 33.7608985901,
      "lon": -117.2180023193,
      "tz": "America\/Los_Angeles"
  },
  "KL66": {
      "icao": "KL66",
      "iata": "",
      "name": "Pollock Municipal Airport",
      "city": "Pollock",
      "state": "Louisiana",
      "country": "US",
      "elevation": 203,
      "lat": 31.47750092,
      "lon": -92.46109772,
      "tz": "America\/Chicago"
  },
  "KL67": {
      "icao": "KL67",
      "iata": "",
      "name": "Rialto Municipal Miro Field",
      "city": "Rialto",
      "state": "California",
      "country": "US",
      "elevation": 1455,
      "lat": 34.1292991638,
      "lon": -117.4020004272,
      "tz": "America\/Los_Angeles"
  },
  "KL70": {
      "icao": "KL70",
      "iata": "",
      "name": "Agua Dulce Airpark",
      "city": "Agua Dulce",
      "state": "California",
      "country": "US",
      "elevation": 2660,
      "lat": 34.5041999817,
      "lon": -118.31300354,
      "tz": "America\/Los_Angeles"
  },
  "KL71": {
      "icao": "KL71",
      "iata": "",
      "name": "California City Municipal Airport",
      "city": "California City",
      "state": "California",
      "country": "US",
      "elevation": 2454,
      "lat": 35.1511993408,
      "lon": -118.016998291,
      "tz": "America\/Los_Angeles"
  },
  "KL72": {
      "icao": "KL72",
      "iata": "",
      "name": "Trona Airport",
      "city": "Trona",
      "state": "California",
      "country": "US",
      "elevation": 1716,
      "lat": 35.8125,
      "lon": -117.327003479,
      "tz": "America\/Los_Angeles"
  },
  "KL73": {
      "icao": "KL73",
      "iata": "",
      "name": "Poso Kern County Airport",
      "city": "Famoso",
      "state": "California",
      "country": "US",
      "elevation": 635,
      "lat": 35.5965995789,
      "lon": -119.1279983521,
      "tz": "America\/Los_Angeles"
  },
  "KL77": {
      "icao": "KL77",
      "iata": "",
      "name": "Chiriaco Summit Airport",
      "city": "Chiriaco Summit",
      "state": "California",
      "country": "US",
      "elevation": 1713,
      "lat": 33.6652984619,
      "lon": -115.7109985352,
      "tz": "America\/Los_Angeles"
  },
  "KL83": {
      "icao": "KL83",
      "iata": "",
      "name": "Thibodaux Municipal Airport",
      "city": "Thibodaux",
      "state": "Louisiana",
      "country": "US",
      "elevation": 9,
      "lat": 29.747800827,
      "lon": -90.832901001,
      "tz": "America\/Chicago"
  },
  "KL84": {
      "icao": "KL84",
      "iata": "",
      "name": "Lost Hills Kern County Airport",
      "city": "Lost Hills",
      "state": "California",
      "country": "US",
      "elevation": 274,
      "lat": 35.6236000061,
      "lon": -119.6859970093,
      "tz": "America\/Los_Angeles"
  },
  "KL88": {
      "icao": "KL88",
      "iata": "",
      "name": "New Cuyama Airport",
      "city": "New Cuyama",
      "state": "California",
      "country": "US",
      "elevation": 2203,
      "lat": 34.9375,
      "lon": -119.68800354,
      "tz": "America\/Los_Angeles"
  },
  "KL94": {
      "icao": "KL94",
      "iata": "",
      "name": "Mountain Valley Airport",
      "city": "Tehachapi",
      "state": "California",
      "country": "US",
      "elevation": 4220,
      "lat": 35.1011009216,
      "lon": -118.422996521,
      "tz": "America\/Los_Angeles"
  },
  "KLAA": {
      "icao": "KLAA",
      "iata": "LAA",
      "name": "Lamar Municipal Airport",
      "city": "Lamar",
      "state": "Colorado",
      "country": "US",
      "elevation": 3706,
      "lat": 38.0696983337,
      "lon": -102.68800354,
      "tz": "America\/Denver"
  },
  "KLAF": {
      "icao": "KLAF",
      "iata": "LAF",
      "name": "Purdue University Airport",
      "city": "Lafayette",
      "state": "Indiana",
      "country": "US",
      "elevation": 606,
      "lat": 40.4123001099,
      "lon": -86.9368972778,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KLAL": {
      "icao": "KLAL",
      "iata": "LAL",
      "name": "Lakeland Linder Regional Airport",
      "city": "Lakeland",
      "state": "Florida",
      "country": "US",
      "elevation": 142,
      "lat": 27.988899231,
      "lon": -82.0186004639,
      "tz": "America\/New_York"
  },
  "KLAM": {
      "icao": "KLAM",
      "iata": "LAM",
      "name": "Los Alamos Airport",
      "city": "Los Alamos",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7171,
      "lat": 35.8797988892,
      "lon": -106.2689971924,
      "tz": "America\/Denver"
  },
  "KLAN": {
      "icao": "KLAN",
      "iata": "LAN",
      "name": "Capital City Airport",
      "city": "Lansing",
      "state": "Michigan",
      "country": "US",
      "elevation": 861,
      "lat": 42.7787017822,
      "lon": -84.5874023438,
      "tz": "America\/Detroit"
  },
  "KLAR": {
      "icao": "KLAR",
      "iata": "LAR",
      "name": "Laramie Regional Airport",
      "city": "Laramie",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7284,
      "lat": 41.3120994568,
      "lon": -105.6750030518,
      "tz": "America\/Denver"
  },
  "KLAS": {
      "icao": "KLAS",
      "iata": "LAS",
      "name": "Harry Reid International Airport",
      "city": "Las Vegas",
      "state": "Nevada",
      "country": "US",
      "elevation": 2181,
      "lat": 36.08010101,
      "lon": -115.1520004,
      "tz": "America\/Los_Angeles"
  },
  "KLAW": {
      "icao": "KLAW",
      "iata": "LAW",
      "name": "Lawton Fort Sill Regional Airport",
      "city": "Lawton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1110,
      "lat": 34.5676994324,
      "lon": -98.4166030884,
      "tz": "America\/Chicago"
  },
  "KLAX": {
      "icao": "KLAX",
      "iata": "LAX",
      "name": "Los Angeles International Airport",
      "city": "Los Angeles",
      "state": "California",
      "country": "US",
      "elevation": 125,
      "lat": 33.94250107,
      "lon": -118.4079971,
      "tz": "America\/Los_Angeles"
  },
  "KLBB": {
      "icao": "KLBB",
      "iata": "LBB",
      "name": "Lubbock Preston Smith International Airport",
      "city": "Lubbock",
      "state": "Texas",
      "country": "US",
      "elevation": 3282,
      "lat": 33.6636009216,
      "lon": -101.8229980469,
      "tz": "America\/Chicago"
  },
  "KLBE": {
      "icao": "KLBE",
      "iata": "LBE",
      "name": "Arnold Palmer Regional Airport",
      "city": "Latrobe",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1199,
      "lat": 40.27590179,
      "lon": -79.40480042,
      "tz": "America\/New_York"
  },
  "KLBF": {
      "icao": "KLBF",
      "iata": "LBF",
      "name": "North Platte Regional Airport Lee Bird Field",
      "city": "North Platte",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2777,
      "lat": 41.12620163,
      "lon": -100.6839981,
      "tz": "America\/Chicago"
  },
  "KLBL": {
      "icao": "KLBL",
      "iata": "LBL",
      "name": "Liberal Mid-America Regional Airport",
      "city": "Liberal",
      "state": "Kansas",
      "country": "US",
      "elevation": 2885,
      "lat": 37.0442009,
      "lon": -100.9599991,
      "tz": "America\/Chicago"
  },
  "KLBO": {
      "icao": "KLBO",
      "iata": "",
      "name": "Floyd W. Jones Lebanon Airport",
      "city": "Lebanon",
      "state": "Missouri",
      "country": "US",
      "elevation": 1321,
      "lat": 37.64830017,
      "lon": -92.65239716,
      "tz": "America\/Chicago"
  },
  "KLBR": {
      "icao": "KLBR",
      "iata": "",
      "name": "Clarksville Red River City-J D Trissell Field",
      "city": "Clarksville",
      "state": "Texas",
      "country": "US",
      "elevation": 440,
      "lat": 33.59320068,
      "lon": -95.06359863,
      "tz": "America\/Chicago"
  },
  "KLBT": {
      "icao": "KLBT",
      "iata": "LBT",
      "name": "Lumberton Municipal Airport",
      "city": "Lumberton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 126,
      "lat": 34.6099014282,
      "lon": -79.0594024658,
      "tz": "America\/New_York"
  },
  "KLBX": {
      "icao": "KLBX",
      "iata": "LJN",
      "name": "Brazoria County Airport",
      "city": "Angleton\/Lake Jackson",
      "state": "Texas",
      "country": "US",
      "elevation": 25,
      "lat": 29.1086006165,
      "lon": -95.462097168,
      "tz": "America\/Chicago"
  },
  "KLCG": {
      "icao": "KLCG",
      "iata": "",
      "name": "Wayne Municipal Airport",
      "city": "Wayne",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1431,
      "lat": 42.2419013977,
      "lon": -96.9813995361,
      "tz": "America\/Chicago"
  },
  "KLCH": {
      "icao": "KLCH",
      "iata": "LCH",
      "name": "Lake Charles Regional Airport",
      "city": "Lake Charles",
      "state": "Louisiana",
      "country": "US",
      "elevation": 15,
      "lat": 30.1261005402,
      "lon": -93.2232971191,
      "tz": "America\/Chicago"
  },
  "KLCI": {
      "icao": "KLCI",
      "iata": "LCI",
      "name": "Laconia Municipal Airport",
      "city": "Laconia",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 545,
      "lat": 43.5727005005,
      "lon": -71.4188995361,
      "tz": "America\/New_York"
  },
  "KLCK": {
      "icao": "KLCK",
      "iata": "LCK",
      "name": "Rickenbacker International Airport",
      "city": "Columbus",
      "state": "Ohio",
      "country": "US",
      "elevation": 744,
      "lat": 39.8138008118,
      "lon": -82.9278030396,
      "tz": "America\/New_York"
  },
  "KLCQ": {
      "icao": "KLCQ",
      "iata": "LCQ",
      "name": "Lake City Municipal Airport",
      "city": "Lake City",
      "state": "Florida",
      "country": "US",
      "elevation": 201,
      "lat": 30.1819992065,
      "lon": -82.5768966675,
      "tz": "America\/New_York"
  },
  "KLDJ": {
      "icao": "KLDJ",
      "iata": "LDJ",
      "name": "Linden Airport",
      "city": "Linden",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 23,
      "lat": 40.617401123,
      "lon": -74.2445983887,
      "tz": "America\/New_York"
  },
  "KLDM": {
      "icao": "KLDM",
      "iata": "LDM",
      "name": "Mason County Airport",
      "city": "Ludington",
      "state": "Michigan",
      "country": "US",
      "elevation": 646,
      "lat": 43.96250153,
      "lon": -86.40789795,
      "tz": "America\/Detroit"
  },
  "KLEB": {
      "icao": "KLEB",
      "iata": "LEB",
      "name": "Lebanon Municipal Airport",
      "city": "Lebanon",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 603,
      "lat": 43.6260986328,
      "lon": -72.3041992188,
      "tz": "America\/New_York"
  },
  "KLEE": {
      "icao": "KLEE",
      "iata": "LEE",
      "name": "Leesburg International Airport",
      "city": "Leesburg",
      "state": "Florida",
      "country": "US",
      "elevation": 76,
      "lat": 28.82309914,
      "lon": -81.80870056,
      "tz": "America\/New_York"
  },
  "KLEM": {
      "icao": "KLEM",
      "iata": "LEM",
      "name": "Lemmon Municipal Airport",
      "city": "Lemmon",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2571,
      "lat": 45.9187011719,
      "lon": -102.1060028076,
      "tz": "America\/Denver"
  },
  "KLEW": {
      "icao": "KLEW",
      "iata": "LEW",
      "name": "Auburn Lewiston Municipal Airport",
      "city": "Auburn\/Lewiston",
      "state": "Maine",
      "country": "US",
      "elevation": 288,
      "lat": 44.048500061,
      "lon": -70.2835006714,
      "tz": "America\/New_York"
  },
  "KLEX": {
      "icao": "KLEX",
      "iata": "LEX",
      "name": "Blue Grass Airport",
      "city": "Lexington",
      "state": "Kentucky",
      "country": "US",
      "elevation": 979,
      "lat": 38.0364990234,
      "lon": -84.6059036255,
      "tz": "America\/New_York"
  },
  "KLFI": {
      "icao": "KLFI",
      "iata": "LFI",
      "name": "Langley Air Force Base",
      "city": "Hampton",
      "state": "Virginia",
      "country": "US",
      "elevation": 11,
      "lat": 37.082901001,
      "lon": -76.360496521,
      "tz": "America\/New_York"
  },
  "KLFK": {
      "icao": "KLFK",
      "iata": "LFK",
      "name": "Angelina County Airport",
      "city": "Lufkin",
      "state": "Texas",
      "country": "US",
      "elevation": 296,
      "lat": 31.2339992523,
      "lon": -94.75,
      "tz": "America\/Chicago"
  },
  "KLFT": {
      "icao": "KLFT",
      "iata": "LFT",
      "name": "Lafayette Regional Airport",
      "city": "Lafayette",
      "state": "Louisiana",
      "country": "US",
      "elevation": 42,
      "lat": 30.20529938,
      "lon": -91.98760223,
      "tz": "America\/Chicago"
  },
  "KLGA": {
      "icao": "KLGA",
      "iata": "LGA",
      "name": "La Guardia Airport",
      "city": "New York",
      "state": "New-York",
      "country": "US",
      "elevation": 21,
      "lat": 40.77719879,
      "lon": -73.87259674,
      "tz": "America\/New_York"
  },
  "KLGB": {
      "icao": "KLGB",
      "iata": "LGB",
      "name": "Long Beach \/Daugherty Field\/ Airport",
      "city": "Long Beach",
      "state": "California",
      "country": "US",
      "elevation": 60,
      "lat": 33.81769943,
      "lon": -118.1520004,
      "tz": "America\/Los_Angeles"
  },
  "KLGC": {
      "icao": "KLGC",
      "iata": "LGC",
      "name": "Lagrange Callaway Airport",
      "city": "Lagrange",
      "state": "Georgia",
      "country": "US",
      "elevation": 693,
      "lat": 33.0088996887,
      "lon": -85.0726013184,
      "tz": "America\/New_York"
  },
  "KLGD": {
      "icao": "KLGD",
      "iata": "LGD",
      "name": "La Grande Union County Airport",
      "city": "La Grande",
      "state": "Oregon",
      "country": "US",
      "elevation": 2717,
      "lat": 45.2901992798,
      "lon": -118.0070037842,
      "tz": "America\/Los_Angeles"
  },
  "KLGF": {
      "icao": "KLGF",
      "iata": "LGF",
      "name": "Laguna AAF (Yuma Proving Ground) Airfield",
      "city": "Yuma Proving Ground(Yuma)",
      "state": "Arizona",
      "country": "US",
      "elevation": 433,
      "lat": 32.86000061,
      "lon": -114.3970032,
      "tz": "America\/Phoenix"
  },
  "KLGU": {
      "icao": "KLGU",
      "iata": "LGU",
      "name": "Logan Cache Airport",
      "city": "Logan",
      "state": "Utah",
      "country": "US",
      "elevation": 4457,
      "lat": 41.7911987305,
      "lon": -111.8519973755,
      "tz": "America\/Denver"
  },
  "KLHB": {
      "icao": "KLHB",
      "iata": "",
      "name": "Hearne Municipal Airport",
      "city": "Hearne",
      "state": "Texas",
      "country": "US",
      "elevation": 285,
      "lat": 30.871799469,
      "lon": -96.6222000122,
      "tz": "America\/Chicago"
  },
  "KLHM": {
      "icao": "KLHM",
      "iata": "",
      "name": "Lincoln Regional Karl Harder Field",
      "city": "Lincoln",
      "state": "California",
      "country": "US",
      "elevation": 121,
      "lat": 38.909198761,
      "lon": -121.3509979248,
      "tz": "America\/Los_Angeles"
  },
  "KLHQ": {
      "icao": "KLHQ",
      "iata": "",
      "name": "Fairfield County Airport",
      "city": "Lancaster",
      "state": "Ohio",
      "country": "US",
      "elevation": 868,
      "lat": 39.7555999756,
      "lon": -82.6570968628,
      "tz": "America\/New_York"
  },
  "KLHV": {
      "icao": "KLHV",
      "iata": "LHV",
      "name": "William T. Piper Memorial Airport",
      "city": "Lock Haven",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 556,
      "lat": 41.13560104,
      "lon": -77.42230225,
      "tz": "America\/New_York"
  },
  "KLHW": {
      "icao": "KLHW",
      "iata": "LIY",
      "name": "Wright Aaf (Fort Stewart)\/Midcoast Regional Airport",
      "city": "Fort Stewart(Hinesville)",
      "state": "Georgia",
      "country": "US",
      "elevation": 45,
      "lat": 31.88909912,
      "lon": -81.56230164,
      "tz": "America\/New_York"
  },
  "KLHX": {
      "icao": "KLHX",
      "iata": "",
      "name": "La Junta Municipal Airport",
      "city": "La Junta",
      "state": "Colorado",
      "country": "US",
      "elevation": 4229,
      "lat": 38.04970169,
      "lon": -103.5090027,
      "tz": "America\/Denver"
  },
  "KLHZ": {
      "icao": "KLHZ",
      "iata": "LFN",
      "name": "Franklin County Airport",
      "city": "Louisburg",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 369,
      "lat": 36.0233001709,
      "lon": -78.3302993774,
      "tz": "America\/New_York"
  },
  "KLIC": {
      "icao": "KLIC",
      "iata": "LIC",
      "name": "Limon Municipal Airport",
      "city": "Limon",
      "state": "Colorado",
      "country": "US",
      "elevation": 5374,
      "lat": 39.2747993469,
      "lon": -103.6660003662,
      "tz": "America\/Denver"
  },
  "KLIT": {
      "icao": "KLIT",
      "iata": "LIT",
      "name": "Adams Field",
      "city": "Little Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 262,
      "lat": 34.7294006348,
      "lon": -92.2242965698,
      "tz": "America\/Chicago"
  },
  "KLIU": {
      "icao": "KLIU",
      "iata": "",
      "name": "Littlefield Municipal Airport",
      "city": "Littlefield",
      "state": "Texas",
      "country": "US",
      "elevation": 3616,
      "lat": 33.9239006042,
      "lon": -102.3870010376,
      "tz": "America\/Chicago"
  },
  "KLJF": {
      "icao": "KLJF",
      "iata": "",
      "name": "Litchfield Municipal Airport",
      "city": "Litchfield",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1140,
      "lat": 45.0970993042,
      "lon": -94.5073013306,
      "tz": "America\/Chicago"
  },
  "KLKP": {
      "icao": "KLKP",
      "iata": "LKP",
      "name": "Lake Placid Airport",
      "city": "Lake Placid",
      "state": "New-York",
      "country": "US",
      "elevation": 1747,
      "lat": 44.2644996643,
      "lon": -73.9618988037,
      "tz": "America\/New_York"
  },
  "KLKR": {
      "icao": "KLKR",
      "iata": "",
      "name": "Lancaster County-Mc Whirter Field",
      "city": "Lancaster",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 486,
      "lat": 34.72290039,
      "lon": -80.854599,
      "tz": "America\/New_York"
  },
  "KLKU": {
      "icao": "KLKU",
      "iata": "LOW",
      "name": "Louisa County-Freeman field",
      "city": "Louisa",
      "state": "Virginia",
      "country": "US",
      "elevation": 493,
      "lat": 38.00979996,
      "lon": -77.9701004,
      "tz": "America\/New_York"
  },
  "KLKV": {
      "icao": "KLKV",
      "iata": "LKV",
      "name": "Lake County Airport",
      "city": "Lakeview",
      "state": "Colorado",
      "country": "US",
      "elevation": 4733,
      "lat": 42.1610984802,
      "lon": -120.3990020752,
      "tz": "America\/Los_Angeles"
  },
  "KLLJ": {
      "icao": "KLLJ",
      "iata": "CHL",
      "name": "Challis Airport",
      "city": "Challis",
      "state": "Idaho",
      "country": "US",
      "elevation": 5072,
      "lat": 44.5229988098,
      "lon": -114.2180023193,
      "tz": "America\/Boise"
  },
  "KLLN": {
      "icao": "KLLN",
      "iata": "",
      "name": "Levelland Municipal Airport",
      "city": "Levelland",
      "state": "Texas",
      "country": "US",
      "elevation": 3514,
      "lat": 33.5525016785,
      "lon": -102.3720016479,
      "tz": "America\/Chicago"
  },
  "KLLQ": {
      "icao": "KLLQ",
      "iata": "",
      "name": "Monticello Municipal Ellis Field",
      "city": "Monticello",
      "state": "Arkansas",
      "country": "US",
      "elevation": 270,
      "lat": 33.6385994,
      "lon": -91.75099945,
      "tz": "America\/Chicago"
  },
  "KLLR": {
      "icao": "KLLR",
      "iata": "",
      "name": "Little River Airport",
      "city": "Little River",
      "state": "California",
      "country": "US",
      "elevation": 572,
      "lat": 39.2620010376,
      "lon": -123.7539978027,
      "tz": "America\/Los_Angeles"
  },
  "KLLU": {
      "icao": "KLLU",
      "iata": "",
      "name": "Lamar Municipal Airport",
      "city": "Lamar",
      "state": "Missouri",
      "country": "US",
      "elevation": 1009,
      "lat": 37.4894981384,
      "lon": -94.3115005493,
      "tz": "America\/Chicago"
  },
  "KLMO": {
      "icao": "KLMO",
      "iata": "",
      "name": "Vance Brand Airport",
      "city": "Longmont",
      "state": "Colorado",
      "country": "US",
      "elevation": 5055,
      "lat": 40.1637001,
      "lon": -105.163002,
      "tz": "America\/Denver"
  },
  "KLMS": {
      "icao": "KLMS",
      "iata": "LMS",
      "name": "Louisville Winston County Airport",
      "city": "Louisville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 575,
      "lat": 33.1461982727,
      "lon": -89.0625,
      "tz": "America\/Chicago"
  },
  "KLMT": {
      "icao": "KLMT",
      "iata": "LMT",
      "name": "Klamath Falls Airport",
      "city": "Klamath Falls",
      "state": "Oregon",
      "country": "US",
      "elevation": 4095,
      "lat": 42.1561012268,
      "lon": -121.733001709,
      "tz": "America\/Los_Angeles"
  },
  "KLNA": {
      "icao": "KLNA",
      "iata": "LNA",
      "name": "Palm Beach County Park Airport",
      "city": "West Palm Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 26.59300041,
      "lon": -80.08509827,
      "tz": "America\/New_York"
  },
  "KLNC": {
      "icao": "KLNC",
      "iata": "",
      "name": "Lancaster Airport",
      "city": "Lancaster",
      "state": "Texas",
      "country": "US",
      "elevation": 501,
      "lat": 32.5792007446,
      "lon": -96.71900177,
      "tz": "America\/Chicago"
  },
  "KLND": {
      "icao": "KLND",
      "iata": "LND",
      "name": "Hunt Field",
      "city": "Lander",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5586,
      "lat": 42.8152008057,
      "lon": -108.7300033569,
      "tz": "America\/Denver"
  },
  "KLNK": {
      "icao": "KLNK",
      "iata": "LNK",
      "name": "Lincoln Airport",
      "city": "Lincoln",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1219,
      "lat": 40.8510017395,
      "lon": -96.7592010498,
      "tz": "America\/Chicago"
  },
  "KLNL": {
      "icao": "KLNL",
      "iata": "",
      "name": "Kings Land O' Lakes Airport",
      "city": "Land O' Lakes",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1704,
      "lat": 46.15399933,
      "lon": -89.21209717,
      "tz": "America\/Chicago"
  },
  "KLNN": {
      "icao": "KLNN",
      "iata": "LNN",
      "name": "Willoughby Lost Nation Municipal Airport",
      "city": "Willoughby",
      "state": "Ohio",
      "country": "US",
      "elevation": 626,
      "lat": 41.6839981079,
      "lon": -81.3897018433,
      "tz": "America\/New_York"
  },
  "KLNP": {
      "icao": "KLNP",
      "iata": "LNP",
      "name": "Lonesome Pine Airport",
      "city": "Wise",
      "state": "Virginia",
      "country": "US",
      "elevation": 2684,
      "lat": 36.9874992371,
      "lon": -82.5299987793,
      "tz": "America\/New_York"
  },
  "KLNR": {
      "icao": "KLNR",
      "iata": "LNR",
      "name": "Tri-County Regional Airport",
      "city": "Lone Rock",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 717,
      "lat": 43.2117004395,
      "lon": -90.181602478,
      "tz": "America\/Chicago"
  },
  "KLNS": {
      "icao": "KLNS",
      "iata": "LNS",
      "name": "Lancaster Airport",
      "city": "Lancaster",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 403,
      "lat": 40.1217002869,
      "lon": -76.2960968018,
      "tz": "America\/New_York"
  },
  "KLOL": {
      "icao": "KLOL",
      "iata": "LOL",
      "name": "Derby Field",
      "city": "Lovelock",
      "state": "Nevada",
      "country": "US",
      "elevation": 3904,
      "lat": 40.0663986206,
      "lon": -118.5650024414,
      "tz": "America\/Los_Angeles"
  },
  "KLOM": {
      "icao": "KLOM",
      "iata": "BBX",
      "name": "Wings Field",
      "city": "Philadelphia",
      "state": "Florida",
      "country": "US",
      "elevation": 302,
      "lat": 40.1375007629,
      "lon": -75.2650985718,
      "tz": "America\/New_York"
  },
  "KLOT": {
      "icao": "KLOT",
      "iata": "LOT",
      "name": "Lewis University Airport",
      "city": "Chicago\/Romeoville",
      "state": "Illinois",
      "country": "US",
      "elevation": 679,
      "lat": 41.6072998,
      "lon": -88.09619904,
      "tz": "America\/Chicago"
  },
  "KLOU": {
      "icao": "KLOU",
      "iata": "LOU",
      "name": "Bowman Field",
      "city": "Louisville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 546,
      "lat": 38.2280006409,
      "lon": -85.6636962891,
      "tz": "America\/Kentucky\/Louisville"
  },
  "KLOZ": {
      "icao": "KLOZ",
      "iata": "LOZ",
      "name": "London Corbin Airport Magee Field",
      "city": "London",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1212,
      "lat": 37.0821990967,
      "lon": -84.0848999023,
      "tz": "America\/New_York"
  },
  "KLPC": {
      "icao": "KLPC",
      "iata": "LPC",
      "name": "Lompoc Airport",
      "city": "Lompoc",
      "state": "California",
      "country": "US",
      "elevation": 88,
      "lat": 34.665599823,
      "lon": -120.4680023193,
      "tz": "America\/Los_Angeles"
  },
  "KLPR": {
      "icao": "KLPR",
      "iata": "",
      "name": "Lorain County Regional Airport",
      "city": "Lorain\/Elyria",
      "state": "Ohio",
      "country": "US",
      "elevation": 793,
      "lat": 41.34429932,
      "lon": -82.17759705,
      "tz": "America\/New_York"
  },
  "KLQK": {
      "icao": "KLQK",
      "iata": "LQK",
      "name": "Pickens County Airport",
      "city": "Pickens",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1013,
      "lat": 34.8100013733,
      "lon": -82.7029037476,
      "tz": "America\/New_York"
  },
  "KLQR": {
      "icao": "KLQR",
      "iata": "",
      "name": "Larned Pawnee County Airport",
      "city": "Larned",
      "state": "Kansas",
      "country": "US",
      "elevation": 2012,
      "lat": 38.20859909,
      "lon": -99.08599854,
      "tz": "America\/Chicago"
  },
  "KLRD": {
      "icao": "KLRD",
      "iata": "LRD",
      "name": "Laredo International Airport",
      "city": "Laredo",
      "state": "Texas",
      "country": "US",
      "elevation": 508,
      "lat": 27.543800354,
      "lon": -99.4616012573,
      "tz": "America\/Chicago"
  },
  "KLRF": {
      "icao": "KLRF",
      "iata": "LRF",
      "name": "Little Rock Air Force Base",
      "city": "Jacksonville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 311,
      "lat": 34.9169006348,
      "lon": -92.1496963501,
      "tz": "America\/Chicago"
  },
  "KLRG": {
      "icao": "KLRG",
      "iata": "",
      "name": "Lincoln Regional Airport",
      "city": "Lincoln",
      "state": "Maine",
      "country": "US",
      "elevation": 208,
      "lat": 45.3622016907,
      "lon": -68.5346984863,
      "tz": "America\/New_York"
  },
  "KLRJ": {
      "icao": "KLRJ",
      "iata": "LRJ",
      "name": "Le Mars Municipal Airport",
      "city": "Le Mars",
      "state": "Iowa",
      "country": "US",
      "elevation": 1197,
      "lat": 42.77799988,
      "lon": -96.1937027,
      "tz": "America\/Chicago"
  },
  "KLRO": {
      "icao": "KLRO",
      "iata": "",
      "name": "Mt Pleasant Regional-Faison field",
      "city": "Mount Pleasant",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 12,
      "lat": 32.89780045,
      "lon": -79.78289795,
      "tz": "America\/New_York"
  },
  "KLRU": {
      "icao": "KLRU",
      "iata": "LRU",
      "name": "Las Cruces International Airport",
      "city": "Las Cruces",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4456,
      "lat": 32.2893981934,
      "lon": -106.9219970703,
      "tz": "America\/Denver"
  },
  "KLRY": {
      "icao": "KLRY",
      "iata": "",
      "name": "Lawrence Smith Memorial Airport",
      "city": "Harrisonville",
      "state": "Missouri",
      "country": "US",
      "elevation": 915,
      "lat": 38.611000061,
      "lon": -94.3421020508,
      "tz": "America\/Chicago"
  },
  "KLSB": {
      "icao": "KLSB",
      "iata": "LSB",
      "name": "Lordsburg Municipal Airport",
      "city": "Lordsburg",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4289,
      "lat": 32.3334999084,
      "lon": -108.6920013428,
      "tz": "America\/Denver"
  },
  "KLSE": {
      "icao": "KLSE",
      "iata": "LSE",
      "name": "La Crosse Municipal Airport",
      "city": "La Crosse",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 655,
      "lat": 43.87900162,
      "lon": -91.25669861,
      "tz": "America\/Chicago"
  },
  "KLSF": {
      "icao": "KLSF",
      "iata": "LSF",
      "name": "Lawson Army Air Field (Fort Benning)",
      "city": "Fort Benning(Columbus)",
      "state": "Georgia",
      "country": "US",
      "elevation": 232,
      "lat": 32.3372993469,
      "lon": -84.9913024902,
      "tz": "America\/Chicago"
  },
  "KLSK": {
      "icao": "KLSK",
      "iata": "LSK",
      "name": "Lusk Municipal Airport",
      "city": "Lusk",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4964,
      "lat": 42.7537994385,
      "lon": -104.4049987793,
      "tz": "America\/Denver"
  },
  "KLSN": {
      "icao": "KLSN",
      "iata": "LSN",
      "name": "Los Banos Municipal Airport",
      "city": "Los Banos",
      "state": "California",
      "country": "US",
      "elevation": 121,
      "lat": 37.06290054,
      "lon": -120.8690033,
      "tz": "America\/Los_Angeles"
  },
  "KLSV": {
      "icao": "KLSV",
      "iata": "LSV",
      "name": "Nellis Air Force Base",
      "city": "Las Vegas",
      "state": "Nevada",
      "country": "US",
      "elevation": 1870,
      "lat": 36.2361984253,
      "lon": -115.033996582,
      "tz": "America\/Los_Angeles"
  },
  "KLTS": {
      "icao": "KLTS",
      "iata": "LTS",
      "name": "Altus Air Force Base",
      "city": "Altus",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1382,
      "lat": 34.667098999,
      "lon": -99.2667007446,
      "tz": "America\/Chicago"
  },
  "KLTY": {
      "icao": "KLTY",
      "iata": "",
      "name": "Liberty County Airport",
      "city": "Chester",
      "state": "Montana",
      "country": "US",
      "elevation": 3160,
      "lat": 48.5107002258,
      "lon": -110.9909973145,
      "tz": "America\/Denver"
  },
  "KLUD": {
      "icao": "KLUD",
      "iata": "",
      "name": "Decatur Municipal Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 1047,
      "lat": 33.2546005249,
      "lon": -97.5805969238,
      "tz": "America\/Chicago"
  },
  "KLUF": {
      "icao": "KLUF",
      "iata": "LUF",
      "name": "Luke Air Force Base",
      "city": "Glendale",
      "state": "Arizona",
      "country": "US",
      "elevation": 1085,
      "lat": 33.5349998474,
      "lon": -112.3830032349,
      "tz": "America\/Phoenix"
  },
  "KLUG": {
      "icao": "KLUG",
      "iata": "",
      "name": "Ellington Airport",
      "city": "Lewisburg",
      "state": "Tennessee",
      "country": "US",
      "elevation": 717,
      "lat": 35.5069999695,
      "lon": -86.8039016724,
      "tz": "America\/Chicago"
  },
  "KLUK": {
      "icao": "KLUK",
      "iata": "LUK",
      "name": "Cincinnati Municipal Airport Lunken Field",
      "city": "Cincinnati",
      "state": "Ohio",
      "country": "US",
      "elevation": 483,
      "lat": 39.10329819,
      "lon": -84.41860199,
      "tz": "America\/New_York"
  },
  "KLUL": {
      "icao": "KLUL",
      "iata": "LUL",
      "name": "Hesler Noble Field",
      "city": "Laurel",
      "state": "Mississippi",
      "country": "US",
      "elevation": 238,
      "lat": 31.6725997925,
      "lon": -89.172203064,
      "tz": "America\/Chicago"
  },
  "KLUM": {
      "icao": "KLUM",
      "iata": "",
      "name": "Menomonie Municipal Score Field",
      "city": "Menomonie",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 895,
      "lat": 44.8922996521,
      "lon": -91.8678970337,
      "tz": "America\/Chicago"
  },
  "KLUX": {
      "icao": "KLUX",
      "iata": "",
      "name": "Laurens County Airport",
      "city": "Laurens",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 697,
      "lat": 34.5070991516,
      "lon": -81.9471969604,
      "tz": "America\/New_York"
  },
  "KLVJ": {
      "icao": "KLVJ",
      "iata": "",
      "name": "Pearland Regional Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 44,
      "lat": 29.5212993622,
      "lon": -95.2421035767,
      "tz": "America\/Chicago"
  },
  "KLVK": {
      "icao": "KLVK",
      "iata": "LVK",
      "name": "Livermore Municipal Airport",
      "city": "Livermore",
      "state": "California",
      "country": "US",
      "elevation": 400,
      "lat": 37.6934013367,
      "lon": -121.8199996948,
      "tz": "America\/Los_Angeles"
  },
  "KLVL": {
      "icao": "KLVL",
      "iata": "LVL",
      "name": "Lawrenceville Brunswick Municipal Airport",
      "city": "Lawrenceville",
      "state": "Virginia",
      "country": "US",
      "elevation": 329,
      "lat": 36.7728004456,
      "lon": -77.7942962646,
      "tz": "America\/New_York"
  },
  "KLVM": {
      "icao": "KLVM",
      "iata": "LVM",
      "name": "Mission Field",
      "city": "Livingston",
      "state": "Montana",
      "country": "US",
      "elevation": 4660,
      "lat": 45.6994018555,
      "lon": -110.4479980469,
      "tz": "America\/Denver"
  },
  "KLVN": {
      "icao": "KLVN",
      "iata": "",
      "name": "Airlake Airport",
      "city": "Minneapolis",
      "state": "Minnesota",
      "country": "US",
      "elevation": 960,
      "lat": 44.6278991699,
      "lon": -93.2281036377,
      "tz": "America\/Chicago"
  },
  "KLVS": {
      "icao": "KLVS",
      "iata": "LVS",
      "name": "Las Vegas Municipal Airport",
      "city": "Las Vegas",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6877,
      "lat": 35.6542015076,
      "lon": -105.141998291,
      "tz": "America\/Denver"
  },
  "KLWA": {
      "icao": "KLWA",
      "iata": "",
      "name": "South Haven Area Regional Airport",
      "city": "South Haven",
      "state": "Michigan",
      "country": "US",
      "elevation": 666,
      "lat": 42.3512001038,
      "lon": -86.2556991577,
      "tz": "America\/Detroit"
  },
  "KLWB": {
      "icao": "KLWB",
      "iata": "LWB",
      "name": "Greenbrier Valley Airport",
      "city": "Lewisburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2302,
      "lat": 37.8582992554,
      "lon": -80.3994979858,
      "tz": "America\/New_York"
  },
  "KLWC": {
      "icao": "KLWC",
      "iata": "LWC",
      "name": "Lawrence Municipal Airport",
      "city": "Lawrence",
      "state": "Kansas",
      "country": "US",
      "elevation": 833,
      "lat": 39.01119995,
      "lon": -95.21659851,
      "tz": "America\/Chicago"
  },
  "KLWL": {
      "icao": "KLWL",
      "iata": "LWL",
      "name": "Wells Municipal Harriet Field",
      "city": "Wells",
      "state": "Nevada",
      "country": "US",
      "elevation": 5772,
      "lat": 41.117099762,
      "lon": -114.9219970703,
      "tz": "America\/Los_Angeles"
  },
  "KLWM": {
      "icao": "KLWM",
      "iata": "LWM",
      "name": "Lawrence Municipal Airport",
      "city": "Lawrence",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 148,
      "lat": 42.7172012329,
      "lon": -71.1233978271,
      "tz": "America\/New_York"
  },
  "KLWS": {
      "icao": "KLWS",
      "iata": "LWS",
      "name": "Lewiston Nez Perce County Airport",
      "city": "Lewiston",
      "state": "Idaho",
      "country": "US",
      "elevation": 1442,
      "lat": 46.3745002747,
      "lon": -117.0149993896,
      "tz": "America\/Los_Angeles"
  },
  "KLWT": {
      "icao": "KLWT",
      "iata": "LWT",
      "name": "Lewistown Municipal Airport",
      "city": "Lewistown",
      "state": "Montana",
      "country": "US",
      "elevation": 4170,
      "lat": 47.0493011475,
      "lon": -109.4670028687,
      "tz": "America\/Denver"
  },
  "KLWV": {
      "icao": "KLWV",
      "iata": "LWV",
      "name": "Lawrenceville Vincennes International Airport",
      "city": "Lawrenceville",
      "state": "Illinois",
      "country": "US",
      "elevation": 430,
      "lat": 38.7643013,
      "lon": -87.6054992676,
      "tz": "America\/Chicago"
  },
  "KLXL": {
      "icao": "KLXL",
      "iata": "",
      "name": "Little Falls-Morrison County-Lindbergh field",
      "city": "Little Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1123,
      "lat": 45.9496994,
      "lon": -94.34739685,
      "tz": "America\/Chicago"
  },
  "KLXN": {
      "icao": "KLXN",
      "iata": "LXN",
      "name": "Jim Kelly Field",
      "city": "Lexington",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2413,
      "lat": 40.7910003662,
      "lon": -99.7772979736,
      "tz": "America\/Chicago"
  },
  "KLXT": {
      "icao": "KLXT",
      "iata": "",
      "name": "Lee's Summit Municipal Airport",
      "city": "Lee's Summit",
      "state": "Missouri",
      "country": "US",
      "elevation": 1004,
      "lat": 38.95970154,
      "lon": -94.37139893,
      "tz": "America\/Chicago"
  },
  "KLXV": {
      "icao": "KLXV",
      "iata": "LXV",
      "name": "Lake County Airport",
      "city": "Leadville",
      "state": "Colorado",
      "country": "US",
      "elevation": 9927,
      "lat": 39.2202987671,
      "lon": -106.3170013428,
      "tz": "America\/Denver"
  },
  "KLXY": {
      "icao": "KLXY",
      "iata": "",
      "name": "Mexia Limestone County Airport",
      "city": "Mexia",
      "state": "Texas",
      "country": "US",
      "elevation": 544,
      "lat": 31.639799118,
      "lon": -96.5147018433,
      "tz": "America\/Chicago"
  },
  "KLYH": {
      "icao": "KLYH",
      "iata": "LYH",
      "name": "Lynchburg Regional Preston Glenn Field",
      "city": "Lynchburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 938,
      "lat": 37.3266983032,
      "lon": -79.2004013062,
      "tz": "America\/New_York"
  },
  "KLYO": {
      "icao": "KLYO",
      "iata": "LYO",
      "name": "Lyons Rice County Municipal Airport",
      "city": "Lyons",
      "state": "Kansas",
      "country": "US",
      "elevation": 1691,
      "lat": 38.34280014,
      "lon": -98.22689819,
      "tz": "America\/Chicago"
  },
  "KLYV": {
      "icao": "KLYV",
      "iata": "",
      "name": "Quentin Aanenson Field",
      "city": "Luverne",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1431,
      "lat": 43.6212005615,
      "lon": -96.2157974243,
      "tz": "America\/Chicago"
  },
  "KLZD": {
      "icao": "KLZD",
      "iata": "",
      "name": "Danielson Airport",
      "city": "Danielson",
      "state": "Connecticut",
      "country": "US",
      "elevation": 238,
      "lat": 41.8196983337,
      "lon": -71.9010009766,
      "tz": "America\/New_York"
  },
  "KLZU": {
      "icao": "KLZU",
      "iata": "LZU",
      "name": "Gwinnett County Briscoe Field",
      "city": "Lawrenceville",
      "state": "Georgia",
      "country": "US",
      "elevation": 1061,
      "lat": 33.97809982,
      "lon": -83.96240234,
      "tz": "America\/New_York"
  },
  "KLZZ": {
      "icao": "KLZZ",
      "iata": "",
      "name": "Lampasas Airport",
      "city": "Lampasas",
      "state": "Texas",
      "country": "US",
      "elevation": 1215,
      "lat": 31.1061992645,
      "lon": -98.1958999634,
      "tz": "America\/Chicago"
  },
  "KM01": {
      "icao": "KM01",
      "iata": "",
      "name": "General Dewitt Spain Airport",
      "city": "Memphis",
      "state": "Tennessee",
      "country": "US",
      "elevation": 225,
      "lat": 35.20069885,
      "lon": -90.05400085,
      "tz": "America\/Chicago"
  },
  "KM02": {
      "icao": "KM02",
      "iata": "",
      "name": "Dickson Municipal Airport",
      "city": "Dickson",
      "state": "Tennessee",
      "country": "US",
      "elevation": 892,
      "lat": 36.1279983521,
      "lon": -87.4298019409,
      "tz": "America\/Chicago"
  },
  "KM04": {
      "icao": "KM04",
      "iata": "",
      "name": "Covington Municipal Airport",
      "city": "Covington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 280,
      "lat": 35.5834007263,
      "lon": -89.5871963501,
      "tz": "America\/Chicago"
  },
  "KM05": {
      "icao": "KM05",
      "iata": "",
      "name": "Caruthersville Memorial Airport",
      "city": "Caruthersville",
      "state": "Missouri",
      "country": "US",
      "elevation": 268,
      "lat": 36.1691017151,
      "lon": -89.6764984131,
      "tz": "America\/Chicago"
  },
  "KM08": {
      "icao": "KM08",
      "iata": "",
      "name": "William L. Whitehurst Field",
      "city": "Bolivar",
      "state": "Tennessee",
      "country": "US",
      "elevation": 499,
      "lat": 35.21450043,
      "lon": -89.04340363,
      "tz": "America\/Chicago"
  },
  "KM11": {
      "icao": "KM11",
      "iata": "",
      "name": "Copiah County Airport",
      "city": "Crystal Springs",
      "state": "Mississippi",
      "country": "US",
      "elevation": 443,
      "lat": 31.9029006958,
      "lon": -90.3686981201,
      "tz": "America\/Chicago"
  },
  "KM12": {
      "icao": "KM12",
      "iata": "",
      "name": "Steele Municipal Airport",
      "city": "Steele",
      "state": "Missouri",
      "country": "US",
      "elevation": 258,
      "lat": 36.09590149,
      "lon": -89.86340332,
      "tz": "America\/Chicago"
  },
  "KM13": {
      "icao": "KM13",
      "iata": "",
      "name": "Poplarville Pearl River County Airport",
      "city": "Poplarville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 320,
      "lat": 30.7859992981,
      "lon": -89.5045013428,
      "tz": "America\/Chicago"
  },
  "KM15": {
      "icao": "KM15",
      "iata": "",
      "name": "Perry County Airport",
      "city": "Linden",
      "state": "Tennessee",
      "country": "US",
      "elevation": 740,
      "lat": 35.5957984924,
      "lon": -87.876701355,
      "tz": "America\/Chicago"
  },
  "KM17": {
      "icao": "KM17",
      "iata": "",
      "name": "Bolivar Municipal Airport",
      "city": "Bolivar",
      "state": "Missouri",
      "country": "US",
      "elevation": 1092,
      "lat": 37.5960998535,
      "lon": -93.3477020264,
      "tz": "America\/Chicago"
  },
  "KM18": {
      "icao": "KM18",
      "iata": "",
      "name": "Hope Municipal Airport",
      "city": "Hope",
      "state": "Arkansas",
      "country": "US",
      "elevation": 359,
      "lat": 33.7201004028,
      "lon": -93.6587982178,
      "tz": "America\/Chicago"
  },
  "KM19": {
      "icao": "KM19",
      "iata": "",
      "name": "Newport Municipal Airport",
      "city": "Newport",
      "state": "Arkansas",
      "country": "US",
      "elevation": 239,
      "lat": 35.6376991272,
      "lon": -91.176399231,
      "tz": "America\/Chicago"
  },
  "KM20": {
      "icao": "KM20",
      "iata": "",
      "name": "Grayson County Airport",
      "city": "Leitchfield",
      "state": "Kentucky",
      "country": "US",
      "elevation": 760,
      "lat": 37.3995018005,
      "lon": -86.2602005005,
      "tz": "America\/Chicago"
  },
  "KM21": {
      "icao": "KM21",
      "iata": "",
      "name": "Muhlenberg County Airport",
      "city": "Greenville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 422,
      "lat": 37.2262001038,
      "lon": -87.1564025879,
      "tz": "America\/Chicago"
  },
  "KM22": {
      "icao": "KM22",
      "iata": "",
      "name": "Russellville Municipal Airport",
      "city": "Russellville",
      "state": "Alabama",
      "country": "US",
      "elevation": 723,
      "lat": 34.4453010559,
      "lon": -87.7113037109,
      "tz": "America\/Chicago"
  },
  "KM23": {
      "icao": "KM23",
      "iata": "",
      "name": "James H Easom Field",
      "city": "Newton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 369,
      "lat": 32.3117980957,
      "lon": -89.1359024048,
      "tz": "America\/Chicago"
  },
  "KM24": {
      "icao": "KM24",
      "iata": "",
      "name": "Dean Griffin Memorial Airport",
      "city": "Wiggins",
      "state": "Mississippi",
      "country": "US",
      "elevation": 270,
      "lat": 30.8432006836,
      "lon": -89.1597976685,
      "tz": "America\/Chicago"
  },
  "KM25": {
      "icao": "KM25",
      "iata": "",
      "name": "Mayfield Graves County Airport",
      "city": "Mayfield",
      "state": "Kentucky",
      "country": "US",
      "elevation": 522,
      "lat": 36.7696389,
      "lon": -88.5846667,
      "tz": "America\/Chicago"
  },
  "KM27": {
      "icao": "KM27",
      "iata": "",
      "name": "Waldron Municipal Airport",
      "city": "Waldron",
      "state": "Arkansas",
      "country": "US",
      "elevation": 695,
      "lat": 34.8759994507,
      "lon": -94.1092987061,
      "tz": "America\/Chicago"
  },
  "KM29": {
      "icao": "KM29",
      "iata": "",
      "name": "Hassell Field",
      "city": "Clifton",
      "state": "Tennessee",
      "country": "US",
      "elevation": 401,
      "lat": 35.3851013184,
      "lon": -87.9674987793,
      "tz": "America\/Chicago"
  },
  "KM30": {
      "icao": "KM30",
      "iata": "",
      "name": "Metropolis Municipal Airport",
      "city": "Metropolis",
      "state": "Illinois",
      "country": "US",
      "elevation": 384,
      "lat": 37.1859016418,
      "lon": -88.7506027222,
      "tz": "America\/Chicago"
  },
  "KM31": {
      "icao": "KM31",
      "iata": "",
      "name": "Arnold Field",
      "city": "Halls",
      "state": "Tennessee",
      "country": "US",
      "elevation": 292,
      "lat": 35.9034004211,
      "lon": -89.3973007202,
      "tz": "America\/Chicago"
  },
  "KM32": {
      "icao": "KM32",
      "iata": "",
      "name": "Lake Village Municipal Airport",
      "city": "Lake Village",
      "state": "Arkansas",
      "country": "US",
      "elevation": 125,
      "lat": 33.3460006714,
      "lon": -91.3156967163,
      "tz": "America\/Chicago"
  },
  "KM33": {
      "icao": "KM33",
      "iata": "",
      "name": "Sumner County Regional Airport",
      "city": "Gallatin",
      "state": "Tennessee",
      "country": "US",
      "elevation": 583,
      "lat": 36.37680054,
      "lon": -86.40879822,
      "tz": "America\/Chicago"
  },
  "KM34": {
      "icao": "KM34",
      "iata": "",
      "name": "Kentucky Dam State Park Airport",
      "city": "Gilbertsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 349,
      "lat": 37.0094985962,
      "lon": -88.2958984375,
      "tz": "America\/Chicago"
  },
  "KM36": {
      "icao": "KM36",
      "iata": "",
      "name": "Frank Federer Memorial Airport",
      "city": "Brinkley",
      "state": "Arkansas",
      "country": "US",
      "elevation": 194,
      "lat": 34.8802986145,
      "lon": -91.176399231,
      "tz": "America\/Chicago"
  },
  "KM37": {
      "icao": "KM37",
      "iata": "",
      "name": "Ruleville Drew Airport",
      "city": "Drew",
      "state": "Mississippi",
      "country": "US",
      "elevation": 137,
      "lat": 33.7764015198,
      "lon": -90.5250015259,
      "tz": "America\/Chicago"
  },
  "KM40": {
      "icao": "KM40",
      "iata": "",
      "name": "Monroe County Airport",
      "city": "Aberdeen\/Amory",
      "state": "Mississippi",
      "country": "US",
      "elevation": 226,
      "lat": 33.8736991882,
      "lon": -88.4897003174,
      "tz": "America\/Chicago"
  },
  "KM41": {
      "icao": "KM41",
      "iata": "",
      "name": "Holly Springs Marshall County Airport",
      "city": "Holly Springs",
      "state": "Mississippi",
      "country": "US",
      "elevation": 553,
      "lat": 34.8042984009,
      "lon": -89.5211029053,
      "tz": "America\/Chicago"
  },
  "KM43": {
      "icao": "KM43",
      "iata": "",
      "name": "Prentiss Jefferson Davis County Airport",
      "city": "Prentiss",
      "state": "Mississippi",
      "country": "US",
      "elevation": 455,
      "lat": 31.59539986,
      "lon": -89.90619659,
      "tz": "America\/Chicago"
  },
  "KM44": {
      "icao": "KM44",
      "iata": "",
      "name": "Houston Municipal Airport",
      "city": "Houston",
      "state": "Mississippi",
      "country": "US",
      "elevation": 337,
      "lat": 33.8917999268,
      "lon": -89.0236968994,
      "tz": "America\/Chicago"
  },
  "KM45": {
      "icao": "KM45",
      "iata": "",
      "name": "Alpine County Airport",
      "city": "Markleeville",
      "state": "California",
      "country": "US",
      "elevation": 5867,
      "lat": 38.7346992493,
      "lon": -119.766998291,
      "tz": "America\/Los_Angeles"
  },
  "KM46": {
      "icao": "KM46",
      "iata": "",
      "name": "Colstrip Airport",
      "city": "Colstrip",
      "state": "Montana",
      "country": "US",
      "elevation": 3426,
      "lat": 45.8529014587,
      "lon": -106.7089996338,
      "tz": "America\/Denver"
  },
  "KM48": {
      "icao": "KM48",
      "iata": "",
      "name": "Houston Memorial Airport",
      "city": "Houston",
      "state": "Missouri",
      "country": "US",
      "elevation": 1196,
      "lat": 37.3301010132,
      "lon": -91.973197937,
      "tz": "America\/Chicago"
  },
  "KM50": {
      "icao": "KM50",
      "iata": "",
      "name": "Boardman Airport",
      "city": "Boardman",
      "state": "Oregon",
      "country": "US",
      "elevation": 396,
      "lat": 45.8148002625,
      "lon": -119.8209991455,
      "tz": "America\/Los_Angeles"
  },
  "KM51": {
      "icao": "KM51",
      "iata": "",
      "name": "Oktibbeha Airport",
      "city": "Starkville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 250,
      "lat": 33.4975013733,
      "lon": -88.6812973022,
      "tz": "America\/Chicago"
  },
  "KM52": {
      "icao": "KM52",
      "iata": "",
      "name": "Franklin Wilkins Airport",
      "city": "Lexington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 514,
      "lat": 35.6512985229,
      "lon": -88.3788986206,
      "tz": "America\/Chicago"
  },
  "KM53": {
      "icao": "KM53",
      "iata": "",
      "name": "Humboldt Municipal Airport",
      "city": "Humboldt",
      "state": "Tennessee",
      "country": "US",
      "elevation": 421,
      "lat": 35.8022003174,
      "lon": -88.8749008179,
      "tz": "America\/Chicago"
  },
  "KM54": {
      "icao": "KM54",
      "iata": "",
      "name": "Lebanon Municipal Airport",
      "city": "Lebanon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 588,
      "lat": 36.19039917,
      "lon": -86.31569672,
      "tz": "America\/Chicago"
  },
  "KM55": {
      "icao": "KM55",
      "iata": "",
      "name": "Lamar County Airport",
      "city": "Vernon",
      "state": "Alabama",
      "country": "US",
      "elevation": 463,
      "lat": 33.846698761,
      "lon": -88.1155014038,
      "tz": "America\/Chicago"
  },
  "KM59": {
      "icao": "KM59",
      "iata": "",
      "name": "Richton Perry County Airport",
      "city": "Richton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 167,
      "lat": 31.3173999786,
      "lon": -88.9349975586,
      "tz": "America\/Chicago"
  },
  "KM60": {
      "icao": "KM60",
      "iata": "",
      "name": "Woodruff County Airport",
      "city": "Augusta",
      "state": "Arkansas",
      "country": "US",
      "elevation": 200,
      "lat": 35.271900177,
      "lon": -91.2696990967,
      "tz": "America\/Chicago"
  },
  "KM65": {
      "icao": "KM65",
      "iata": "",
      "name": "Wynne Municipal Airport",
      "city": "Wynne",
      "state": "Arkansas",
      "country": "US",
      "elevation": 370,
      "lat": 35.2316017151,
      "lon": -90.7615966797,
      "tz": "America\/Chicago"
  },
  "KM66": {
      "icao": "KM66",
      "iata": "",
      "name": "Alfred Schroeder Field",
      "city": "Hillsboro",
      "state": "Kansas",
      "country": "US",
      "elevation": 1434,
      "lat": 38.3431015015,
      "lon": -97.2142028809,
      "tz": "America\/Chicago"
  },
  "KM70": {
      "icao": "KM70",
      "iata": "",
      "name": "Pocahontas Municipal Airport",
      "city": "Pocahontas",
      "state": "Arkansas",
      "country": "US",
      "elevation": 273,
      "lat": 36.2454986572,
      "lon": -90.9552001953,
      "tz": "America\/Chicago"
  },
  "KM71": {
      "icao": "KM71",
      "iata": "",
      "name": "Greensfield Airport",
      "city": "Moscow Mills",
      "state": "Missouri",
      "country": "US",
      "elevation": 549,
      "lat": 38.9017982483,
      "lon": -90.9602966309,
      "tz": "America\/Chicago"
  },
  "KM72": {
      "icao": "KM72",
      "iata": "",
      "name": "New Albany Union County Airport",
      "city": "New Albany",
      "state": "Mississippi",
      "country": "US",
      "elevation": 413,
      "lat": 34.5486984253,
      "lon": -89.0242004395,
      "tz": "America\/Chicago"
  },
  "KM73": {
      "icao": "KM73",
      "iata": "",
      "name": "Almyra Municipal Airport",
      "city": "Almyra",
      "state": "Arkansas",
      "country": "US",
      "elevation": 211,
      "lat": 34.4123001099,
      "lon": -91.4663009644,
      "tz": "America\/Chicago"
  },
  "KM75": {
      "icao": "KM75",
      "iata": "",
      "name": "Malta Airport",
      "city": "Malta",
      "state": "Montana",
      "country": "US",
      "elevation": 2254,
      "lat": 48.3669013977,
      "lon": -107.9189987183,
      "tz": "America\/Denver"
  },
  "KM77": {
      "icao": "KM77",
      "iata": "",
      "name": "Howard County Airport",
      "city": "Nashville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 553,
      "lat": 33.9967002869,
      "lon": -93.8380966187,
      "tz": "America\/Chicago"
  },
  "KM78": {
      "icao": "KM78",
      "iata": "",
      "name": "Malvern Municipal Airport",
      "city": "Malvern",
      "state": "Arkansas",
      "country": "US",
      "elevation": 538,
      "lat": 34.3333015442,
      "lon": -92.7614974976,
      "tz": "America\/Chicago"
  },
  "KM79": {
      "icao": "KM79",
      "iata": "",
      "name": "John H Hooks Jr Memorial Airport",
      "city": "Rayville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 83,
      "lat": 32.4852981567,
      "lon": -91.7703018188,
      "tz": "America\/Chicago"
  },
  "KM80": {
      "icao": "KM80",
      "iata": "",
      "name": "Scott Airport",
      "city": "Tallulah",
      "state": "Louisiana",
      "country": "US",
      "elevation": 84,
      "lat": 32.4163017273,
      "lon": -91.1489028931,
      "tz": "America\/Chicago"
  },
  "KM83": {
      "icao": "KM83",
      "iata": "",
      "name": "Mccharen Field",
      "city": "West Point",
      "state": "Mississippi",
      "country": "US",
      "elevation": 205,
      "lat": 33.5839996338,
      "lon": -88.6667022705,
      "tz": "America\/Chicago"
  },
  "KM85": {
      "icao": "KM85",
      "iata": "",
      "name": "Gideon Memorial Airport",
      "city": "Gideon",
      "state": "Missouri",
      "country": "US",
      "elevation": 268,
      "lat": 36.4438018799,
      "lon": -89.9038009644,
      "tz": "America\/Chicago"
  },
  "KM88": {
      "icao": "KM88",
      "iata": "",
      "name": "Cornelia Fort Airpark",
      "city": "Nashville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 418,
      "lat": 36.1902999878,
      "lon": -86.6996994019,
      "tz": "America\/Chicago"
  },
  "KM89": {
      "icao": "KM89",
      "iata": "",
      "name": "Dexter B Florence Memorial Field",
      "city": "Arkadelphia",
      "state": "Arkansas",
      "country": "US",
      "elevation": 182,
      "lat": 34.0998001099,
      "lon": -93.0661010742,
      "tz": "America\/Chicago"
  },
  "KM90": {
      "icao": "KM90",
      "iata": "",
      "name": "William Robert Johnston Municipal Airport",
      "city": "Mendota",
      "state": "California",
      "country": "US",
      "elevation": 162,
      "lat": 36.7585983276,
      "lon": -120.371002197,
      "tz": "America\/Los_Angeles"
  },
  "KM91": {
      "icao": "KM91",
      "iata": "",
      "name": "Springfield Robertson County Airport",
      "city": "Springfield",
      "state": "Tennessee",
      "country": "US",
      "elevation": 706,
      "lat": 36.53720093,
      "lon": -86.92060089,
      "tz": "America\/Chicago"
  },
  "KM93": {
      "icao": "KM93",
      "iata": "",
      "name": "Houston County Airport",
      "city": "Mc Kinnon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 370,
      "lat": 36.3166999817,
      "lon": -87.9167022705,
      "tz": "America\/Chicago"
  },
  "KM94": {
      "icao": "KM94",
      "iata": "",
      "name": "Desert Aire Airport",
      "city": "Mattawa",
      "state": "Washington",
      "country": "US",
      "elevation": 586,
      "lat": 46.6874008179,
      "lon": -119.9209976196,
      "tz": "America\/Los_Angeles"
  },
  "KM99": {
      "icao": "KM99",
      "iata": "",
      "name": "Saline County\/Watts Field",
      "city": "Benton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 318,
      "lat": 34.5565986633,
      "lon": -92.6069030762,
      "tz": "America\/Chicago"
  },
  "KMAC": {
      "icao": "KMAC",
      "iata": "MAC",
      "name": "Macon Downtown Airport",
      "city": "Macon",
      "state": "Georgia",
      "country": "US",
      "elevation": 437,
      "lat": 32.82210159,
      "lon": -83.56199646,
      "tz": "America\/New_York"
  },
  "KMAE": {
      "icao": "KMAE",
      "iata": "MAE",
      "name": "Madera Municipal Airport",
      "city": "Madera",
      "state": "California",
      "country": "US",
      "elevation": 255,
      "lat": 36.9886016846,
      "lon": -120.1119995117,
      "tz": "America\/Los_Angeles"
  },
  "KMAF": {
      "icao": "KMAF",
      "iata": "MAF",
      "name": "Midland International Airport",
      "city": "Midland",
      "state": "Texas",
      "country": "US",
      "elevation": 2871,
      "lat": 31.9424991608,
      "lon": -102.202003479,
      "tz": "America\/Chicago"
  },
  "KMAI": {
      "icao": "KMAI",
      "iata": "",
      "name": "Marianna Municipal Airport",
      "city": "Marianna",
      "state": "Florida",
      "country": "US",
      "elevation": 110,
      "lat": 30.8377990723,
      "lon": -85.1819000244,
      "tz": "America\/Chicago"
  },
  "KMAL": {
      "icao": "KMAL",
      "iata": "",
      "name": "Malone Dufort Airport",
      "city": "Malone",
      "state": "New-York",
      "country": "US",
      "elevation": 790,
      "lat": 44.8536987305,
      "lon": -74.3289031982,
      "tz": "America\/New_York"
  },
  "KMAO": {
      "icao": "KMAO",
      "iata": "",
      "name": "Marion County Airport",
      "city": "Marion",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 92,
      "lat": 34.1811981201,
      "lon": -79.3347015381,
      "tz": "America\/New_York"
  },
  "KMAW": {
      "icao": "KMAW",
      "iata": "MAW",
      "name": "Malden Regional Airport",
      "city": "Malden",
      "state": "Missouri",
      "country": "US",
      "elevation": 294,
      "lat": 36.6006012,
      "lon": -89.99220276,
      "tz": "America\/Chicago"
  },
  "KMBG": {
      "icao": "KMBG",
      "iata": "MBG",
      "name": "Mobridge Municipal Airport",
      "city": "Mobridge",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1716,
      "lat": 45.54650116,
      "lon": -100.4079971,
      "tz": "America\/Chicago"
  },
  "KMBL": {
      "icao": "KMBL",
      "iata": "MBL",
      "name": "Manistee Co Blacker Airport",
      "city": "Manistee",
      "state": "Michigan",
      "country": "US",
      "elevation": 621,
      "lat": 44.2723999,
      "lon": -86.24690247,
      "tz": "America\/Detroit"
  },
  "KMBO": {
      "icao": "KMBO",
      "iata": "DXE",
      "name": "Bruce Campbell Field",
      "city": "Madison",
      "state": "Mississippi",
      "country": "US",
      "elevation": 326,
      "lat": 32.4387016296,
      "lon": -90.1031036377,
      "tz": "America\/Chicago"
  },
  "KMBS": {
      "icao": "KMBS",
      "iata": "MBS",
      "name": "MBS International Airport",
      "city": "Saginaw",
      "state": "Michigan",
      "country": "US",
      "elevation": 668,
      "lat": 43.5329017639,
      "lon": -84.0795974731,
      "tz": "America\/Detroit"
  },
  "KMBT": {
      "icao": "KMBT",
      "iata": "",
      "name": "Murfreesboro Municipal Airport",
      "city": "Murfreesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 614,
      "lat": 35.87749863,
      "lon": -86.37750244,
      "tz": "America\/Chicago"
  },
  "KMBY": {
      "icao": "KMBY",
      "iata": "MBY",
      "name": "Omar N Bradley Airport",
      "city": "Moberly",
      "state": "Missouri",
      "country": "US",
      "elevation": 867,
      "lat": 39.4639015198,
      "lon": -92.4270019531,
      "tz": "America\/Chicago"
  },
  "KMCB": {
      "icao": "KMCB",
      "iata": "MCB",
      "name": "Mc Comb\/Pike County\/John E Lewis Field",
      "city": "Mc Comb",
      "state": "Mississippi",
      "country": "US",
      "elevation": 413,
      "lat": 31.17849922,
      "lon": -90.47190094,
      "tz": "America\/Chicago"
  },
  "KMCC": {
      "icao": "KMCC",
      "iata": "MCC",
      "name": "Mc Clellan Airfield",
      "city": "Sacramento",
      "state": "California",
      "country": "US",
      "elevation": 77,
      "lat": 38.66759872,
      "lon": -121.401001,
      "tz": "America\/Los_Angeles"
  },
  "KMCD": {
      "icao": "KMCD",
      "iata": "MCD",
      "name": "Mackinac Island Airport",
      "city": "Mackinac Island",
      "state": "Michigan",
      "country": "US",
      "elevation": 739,
      "lat": 45.86489868,
      "lon": -84.63729858,
      "tz": "America\/Detroit"
  },
  "KMCE": {
      "icao": "KMCE",
      "iata": "MCE",
      "name": "Merced Regional Macready Field",
      "city": "Merced",
      "state": "California",
      "country": "US",
      "elevation": 155,
      "lat": 37.28469849,
      "lon": -120.5139999,
      "tz": "America\/Los_Angeles"
  },
  "KMCF": {
      "icao": "KMCF",
      "iata": "MCF",
      "name": "MacDill Air Force Base",
      "city": "Tampa",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 27.84930038,
      "lon": -82.52120209,
      "tz": "America\/New_York"
  },
  "KMCI": {
      "icao": "KMCI",
      "iata": "MCI",
      "name": "Kansas City International Airport",
      "city": "Kansas City",
      "state": "Missouri",
      "country": "US",
      "elevation": 1026,
      "lat": 39.2975997925,
      "lon": -94.7138977051,
      "tz": "America\/Chicago"
  },
  "KMCK": {
      "icao": "KMCK",
      "iata": "MCK",
      "name": "Mc Cook Ben Nelson Regional Airport",
      "city": "Mc Cook",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2583,
      "lat": 40.20629883,
      "lon": -100.5920029,
      "tz": "America\/Chicago"
  },
  "KMCN": {
      "icao": "KMCN",
      "iata": "MCN",
      "name": "Middle Georgia Regional Airport",
      "city": "Macon",
      "state": "Georgia",
      "country": "US",
      "elevation": 354,
      "lat": 32.6927986145,
      "lon": -83.6492004395,
      "tz": "America\/New_York"
  },
  "KMCO": {
      "icao": "KMCO",
      "iata": "MCO",
      "name": "Orlando International Airport",
      "city": "Orlando",
      "state": "Florida",
      "country": "US",
      "elevation": 96,
      "lat": 28.4293994904,
      "lon": -81.3089981079,
      "tz": "America\/New_York"
  },
  "KMCW": {
      "icao": "KMCW",
      "iata": "MCW",
      "name": "Mason City Municipal Airport",
      "city": "Mason City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1213,
      "lat": 43.1577987671,
      "lon": -93.3312988281,
      "tz": "America\/Chicago"
  },
  "KMCX": {
      "icao": "KMCX",
      "iata": "",
      "name": "White County Airport",
      "city": "Monticello",
      "state": "Indiana",
      "country": "US",
      "elevation": 676,
      "lat": 40.7088012695,
      "lon": -86.7667999268,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KMCZ": {
      "icao": "KMCZ",
      "iata": "",
      "name": "Martin County Airport",
      "city": "Williamston",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 76,
      "lat": 35.8622016907,
      "lon": -77.1781997681,
      "tz": "America\/New_York"
  },
  "KMDD": {
      "icao": "KMDD",
      "iata": "MDD",
      "name": "Midland Airpark",
      "city": "Midland",
      "state": "Texas",
      "country": "US",
      "elevation": 2803,
      "lat": 32.0364990234,
      "lon": -102.1009979248,
      "tz": "America\/Chicago"
  },
  "KMDF": {
      "icao": "KMDF",
      "iata": "",
      "name": "Mooreland Municipal Airport",
      "city": "Mooreland",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1970,
      "lat": 36.4847984314,
      "lon": -99.1941986084,
      "tz": "America\/Chicago"
  },
  "KMDH": {
      "icao": "KMDH",
      "iata": "MDH",
      "name": "Southern Illinois Airport",
      "city": "Carbondale\/Murphysboro",
      "state": "Illinois",
      "country": "US",
      "elevation": 411,
      "lat": 37.7780990601,
      "lon": -89.2519989014,
      "tz": "America\/Chicago"
  },
  "KMDQ": {
      "icao": "KMDQ",
      "iata": "",
      "name": "Madison County Executive -Tom Sharp Jr Airport",
      "city": "Huntsville",
      "state": "Alabama",
      "country": "US",
      "elevation": 756,
      "lat": 34.8614006,
      "lon": -86.55750275,
      "tz": "America\/Chicago"
  },
  "KMDS": {
      "icao": "KMDS",
      "iata": "XMD",
      "name": "Madison Municipal Airport",
      "city": "Madison",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1718,
      "lat": 44.01599884,
      "lon": -97.08589935,
      "tz": "America\/Chicago"
  },
  "KMDT": {
      "icao": "KMDT",
      "iata": "MDT",
      "name": "Harrisburg International Airport",
      "city": "Harrisburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 310,
      "lat": 40.1935005188,
      "lon": -76.7633972168,
      "tz": "America\/New_York"
  },
  "KMDW": {
      "icao": "KMDW",
      "iata": "MDW",
      "name": "Chicago Midway International Airport",
      "city": "Chicago",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 41.7859992981,
      "lon": -87.7524032593,
      "tz": "America\/Chicago"
  },
  "KMDZ": {
      "icao": "KMDZ",
      "iata": "MDF",
      "name": "Taylor County Airport",
      "city": "Medford",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1478,
      "lat": 45.10100174,
      "lon": -90.30329895,
      "tz": "America\/Chicago"
  },
  "KMEB": {
      "icao": "KMEB",
      "iata": "MXE",
      "name": "Laurinburg Maxton Airport",
      "city": "Maxton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 216,
      "lat": 34.79190063,
      "lon": -79.36579895,
      "tz": "America\/New_York"
  },
  "KMEI": {
      "icao": "KMEI",
      "iata": "MEI",
      "name": "Key Field",
      "city": "Meridian",
      "state": "Mississippi",
      "country": "US",
      "elevation": 297,
      "lat": 32.3325996399,
      "lon": -88.7518997192,
      "tz": "America\/Chicago"
  },
  "KMEJ": {
      "icao": "KMEJ",
      "iata": "",
      "name": "Meade Municipal Airport",
      "city": "Meade",
      "state": "Kansas",
      "country": "US",
      "elevation": 2529,
      "lat": 37.2769012451,
      "lon": -100.3560028076,
      "tz": "America\/Chicago"
  },
  "KMEM": {
      "icao": "KMEM",
      "iata": "MEM",
      "name": "Memphis International Airport",
      "city": "Memphis",
      "state": "Tennessee",
      "country": "US",
      "elevation": 341,
      "lat": 35.0424003601,
      "lon": -89.9766998291,
      "tz": "America\/Chicago"
  },
  "KMER": {
      "icao": "KMER",
      "iata": "MER",
      "name": "Castle Airport",
      "city": "Merced",
      "state": "California",
      "country": "US",
      "elevation": 191,
      "lat": 37.38050079,
      "lon": -120.5680008,
      "tz": "America\/Los_Angeles"
  },
  "KMEV": {
      "icao": "KMEV",
      "iata": "MEV",
      "name": "Minden Tahoe Airport",
      "city": "Minden",
      "state": "Nevada",
      "country": "US",
      "elevation": 4722,
      "lat": 39.00030136,
      "lon": -119.7509995,
      "tz": "America\/Los_Angeles"
  },
  "KMEY": {
      "icao": "KMEY",
      "iata": "",
      "name": "James G. Whiting Memorial Field",
      "city": "Mapleton",
      "state": "Iowa",
      "country": "US",
      "elevation": 1116,
      "lat": 42.1782989502,
      "lon": -95.7936019897,
      "tz": "America\/Chicago"
  },
  "KMEZ": {
      "icao": "KMEZ",
      "iata": "",
      "name": "Mena Intermountain Municipal Airport",
      "city": "Mena",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1080,
      "lat": 34.54539871,
      "lon": -94.20269775,
      "tz": "America\/Chicago"
  },
  "KMFD": {
      "icao": "KMFD",
      "iata": "MFD",
      "name": "Mansfield Lahm Regional Airport",
      "city": "Mansfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1297,
      "lat": 40.8213996887,
      "lon": -82.5166015625,
      "tz": "America\/New_York"
  },
  "KMFE": {
      "icao": "KMFE",
      "iata": "MFE",
      "name": "Mc Allen Miller International Airport",
      "city": "Mc Allen",
      "state": "Texas",
      "country": "US",
      "elevation": 107,
      "lat": 26.17580032,
      "lon": -98.23860168,
      "tz": "America\/Chicago"
  },
  "KMFI": {
      "icao": "KMFI",
      "iata": "MFI",
      "name": "Marshfield Municipal Airport",
      "city": "Marshfield",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1277,
      "lat": 44.6369018555,
      "lon": -90.1893005371,
      "tz": "America\/Chicago"
  },
  "KMFR": {
      "icao": "KMFR",
      "iata": "MFR",
      "name": "Rogue Valley International Medford Airport",
      "city": "Medford",
      "state": "Oregon",
      "country": "US",
      "elevation": 1335,
      "lat": 42.3741989136,
      "lon": -122.8730010986,
      "tz": "America\/Los_Angeles"
  },
  "KMFV": {
      "icao": "KMFV",
      "iata": "MFV",
      "name": "Accomack County Airport",
      "city": "Melfa",
      "state": "Virginia",
      "country": "US",
      "elevation": 47,
      "lat": 37.646900177,
      "lon": -75.761100769,
      "tz": "America\/New_York"
  },
  "KMGC": {
      "icao": "KMGC",
      "iata": "MGC",
      "name": "Michigan City Municipal Airport",
      "city": "Michigan City",
      "state": "Indiana",
      "country": "US",
      "elevation": 655,
      "lat": 41.7033004761,
      "lon": -86.8211975098,
      "tz": "America\/Chicago"
  },
  "KMGE": {
      "icao": "KMGE",
      "iata": "MGE",
      "name": "Dobbins Air Reserve Base Airport",
      "city": "Marietta",
      "state": "Georgia",
      "country": "US",
      "elevation": 1068,
      "lat": 33.91540146,
      "lon": -84.51629639,
      "tz": "America\/New_York"
  },
  "KMGG": {
      "icao": "KMGG",
      "iata": "",
      "name": "Maple Lake Municipal Airport",
      "city": "Maple Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1028,
      "lat": 45.236000061,
      "lon": -93.9856033325,
      "tz": "America\/Chicago"
  },
  "KMGJ": {
      "icao": "KMGJ",
      "iata": "MGJ",
      "name": "Orange County Airport",
      "city": "Montgomery",
      "state": "New-York",
      "country": "US",
      "elevation": 364,
      "lat": 41.50999832,
      "lon": -74.26460266,
      "tz": "America\/New_York"
  },
  "KMGM": {
      "icao": "KMGM",
      "iata": "MGM",
      "name": "Montgomery Regional (Dannelly Field) Airport",
      "city": "Montgomery",
      "state": "Alabama",
      "country": "US",
      "elevation": 221,
      "lat": 32.30059814,
      "lon": -86.39399719,
      "tz": "America\/Chicago"
  },
  "KMGN": {
      "icao": "KMGN",
      "iata": "",
      "name": "Harbor Springs Airport",
      "city": "Harbor Springs",
      "state": "Michigan",
      "country": "US",
      "elevation": 686,
      "lat": 45.4255981445,
      "lon": -84.9133987427,
      "tz": "America\/Detroit"
  },
  "KMGR": {
      "icao": "KMGR",
      "iata": "MGR",
      "name": "Moultrie Municipal Airport",
      "city": "Moultrie",
      "state": "Georgia",
      "country": "US",
      "elevation": 294,
      "lat": 31.0848999023,
      "lon": -83.8032989502,
      "tz": "America\/New_York"
  },
  "KMGW": {
      "icao": "KMGW",
      "iata": "MGW",
      "name": "Morgantown Municipal Walter L. Bill Hart Field",
      "city": "Morgantown",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1248,
      "lat": 39.64289856,
      "lon": -79.91629791,
      "tz": "America\/New_York"
  },
  "KMGY": {
      "icao": "KMGY",
      "iata": "MGY",
      "name": "Dayton Wright Brothers Airport",
      "city": "Dayton",
      "state": "Ohio",
      "country": "US",
      "elevation": 957,
      "lat": 39.5890007019,
      "lon": -84.224899292,
      "tz": "America\/New_York"
  },
  "KMHE": {
      "icao": "KMHE",
      "iata": "MHE",
      "name": "Mitchell Municipal Airport",
      "city": "Mitchell",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1304,
      "lat": 43.7747993469,
      "lon": -98.0385971069,
      "tz": "America\/Chicago"
  },
  "KMHK": {
      "icao": "KMHK",
      "iata": "MHK",
      "name": "Manhattan Regional Airport",
      "city": "Manhattan",
      "state": "Kansas",
      "country": "US",
      "elevation": 1057,
      "lat": 39.1409988403,
      "lon": -96.6707992554,
      "tz": "America\/Chicago"
  },
  "KMHL": {
      "icao": "KMHL",
      "iata": "MHL",
      "name": "Marshall Memorial Municipal Airport",
      "city": "Marshall",
      "state": "Missouri",
      "country": "US",
      "elevation": 779,
      "lat": 39.0957984924,
      "lon": -93.2029037476,
      "tz": "America\/Chicago"
  },
  "KMHP": {
      "icao": "KMHP",
      "iata": "",
      "name": "Metter Municipal Airport",
      "city": "Metter",
      "state": "Georgia",
      "country": "US",
      "elevation": 197,
      "lat": 32.3739013672,
      "lon": -82.0792007446,
      "tz": "America\/New_York"
  },
  "KMHR": {
      "icao": "KMHR",
      "iata": "MHR",
      "name": "Sacramento Mather Airport",
      "city": "Sacramento",
      "state": "California",
      "country": "US",
      "elevation": 99,
      "lat": 38.55390167,
      "lon": -121.2979965,
      "tz": "America\/Los_Angeles"
  },
  "KMHT": {
      "icao": "KMHT",
      "iata": "MHT",
      "name": "Manchester Airport",
      "city": "Manchester",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 266,
      "lat": 42.932598114,
      "lon": -71.4356994629,
      "tz": "America\/New_York"
  },
  "KMHV": {
      "icao": "KMHV",
      "iata": "MHV",
      "name": "Mojave Airport",
      "city": "Mojave",
      "state": "California",
      "country": "US",
      "elevation": 2801,
      "lat": 35.05939865,
      "lon": -118.1520004,
      "tz": "America\/Los_Angeles"
  },
  "KMIA": {
      "icao": "KMIA",
      "iata": "MIA",
      "name": "Miami International Airport",
      "city": "Miami",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 25.7931995392,
      "lon": -80.2906036377,
      "tz": "America\/New_York"
  },
  "KMIB": {
      "icao": "KMIB",
      "iata": "MIB",
      "name": "Minot Air Force Base",
      "city": "Minot",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1667,
      "lat": 48.41559982,
      "lon": -101.3580017,
      "tz": "America\/Chicago"
  },
  "KMIC": {
      "icao": "KMIC",
      "iata": "MIC",
      "name": "Crystal Airport",
      "city": "Minneapolis",
      "state": "Minnesota",
      "country": "US",
      "elevation": 869,
      "lat": 45.0620002747,
      "lon": -93.3538970947,
      "tz": "America\/Chicago"
  },
  "KMIE": {
      "icao": "KMIE",
      "iata": "MIE",
      "name": "Delaware County Johnson Field",
      "city": "Muncie",
      "state": "Indiana",
      "country": "US",
      "elevation": 937,
      "lat": 40.2422981262,
      "lon": -85.3958969116,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KMIO": {
      "icao": "KMIO",
      "iata": "MIO",
      "name": "Miami Municipal Airport",
      "city": "Miami",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 808,
      "lat": 36.909198761,
      "lon": -94.8874969482,
      "tz": "America\/Chicago"
  },
  "KMIT": {
      "icao": "KMIT",
      "iata": "MIT",
      "name": "Shafter Minter Field",
      "city": "Shafter",
      "state": "California",
      "country": "US",
      "elevation": 424,
      "lat": 35.50740051,
      "lon": -119.1920013,
      "tz": "America\/Los_Angeles"
  },
  "KMIV": {
      "icao": "KMIV",
      "iata": "MIV",
      "name": "Millville Municipal Airport",
      "city": "Millville",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 85,
      "lat": 39.3678016663,
      "lon": -75.0721969604,
      "tz": "America\/New_York"
  },
  "KMIW": {
      "icao": "KMIW",
      "iata": "MIW",
      "name": "Marshalltown Municipal Airport",
      "city": "Marshalltown",
      "state": "Iowa",
      "country": "US",
      "elevation": 974,
      "lat": 42.112701416,
      "lon": -92.9178009033,
      "tz": "America\/Chicago"
  },
  "KMJD": {
      "icao": "KMJD",
      "iata": "",
      "name": "Picayune Municipal Airport",
      "city": "Picayune",
      "state": "Mississippi",
      "country": "US",
      "elevation": 55,
      "lat": 30.48749924,
      "lon": -89.65119934,
      "tz": "America\/Chicago"
  },
  "KMJQ": {
      "icao": "KMJQ",
      "iata": "MJQ",
      "name": "Jackson Municipal Airport",
      "city": "Jackson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1446,
      "lat": 43.6500015259,
      "lon": -94.9865036011,
      "tz": "America\/Chicago"
  },
  "KMJX": {
      "icao": "KMJX",
      "iata": "MJX",
      "name": "Robert J. Miller Air Park",
      "city": "Toms River",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 82,
      "lat": 39.92750168,
      "lon": -74.29239655,
      "tz": "America\/New_York"
  },
  "KMKA": {
      "icao": "KMKA",
      "iata": "",
      "name": "Miller Municipal Airport",
      "city": "Miller",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1569,
      "lat": 44.5251998901,
      "lon": -98.9580993652,
      "tz": "America\/Chicago"
  },
  "KMKC": {
      "icao": "KMKC",
      "iata": "MKC",
      "name": "Charles B. Wheeler Downtown Airport",
      "city": "Kansas City",
      "state": "Missouri",
      "country": "US",
      "elevation": 759,
      "lat": 39.1231994629,
      "lon": -94.5927963257,
      "tz": "America\/Chicago"
  },
  "KMKE": {
      "icao": "KMKE",
      "iata": "MKE",
      "name": "General Mitchell International Airport",
      "city": "Milwaukee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 723,
      "lat": 42.9472007751,
      "lon": -87.8965988159,
      "tz": "America\/Chicago"
  },
  "KMKG": {
      "icao": "KMKG",
      "iata": "MKG",
      "name": "Muskegon County Airport",
      "city": "Muskegon",
      "state": "Michigan",
      "country": "US",
      "elevation": 629,
      "lat": 43.16949844,
      "lon": -86.23819733,
      "tz": "America\/Detroit"
  },
  "KMKJ": {
      "icao": "KMKJ",
      "iata": "",
      "name": "Mountain Empire Airport",
      "city": "Marion\/Wytheville",
      "state": "Virginia",
      "country": "US",
      "elevation": 2558,
      "lat": 36.8949012756,
      "lon": -81.349899292,
      "tz": "America\/New_York"
  },
  "KMKL": {
      "icao": "KMKL",
      "iata": "MKL",
      "name": "Mc Kellar Sipes Regional Airport",
      "city": "Jackson",
      "state": "Tennessee",
      "country": "US",
      "elevation": 434,
      "lat": 35.59989929,
      "lon": -88.91560364,
      "tz": "America\/Chicago"
  },
  "KMKN": {
      "icao": "KMKN",
      "iata": "",
      "name": "Comanche County City Airport",
      "city": "Comanche",
      "state": "Texas",
      "country": "US",
      "elevation": 1385,
      "lat": 31.91679955,
      "lon": -98.60030365,
      "tz": "America\/Chicago"
  },
  "KMKO": {
      "icao": "KMKO",
      "iata": "MKO",
      "name": "Davis Field",
      "city": "Muskogee",
      "state": "Florida",
      "country": "US",
      "elevation": 611,
      "lat": 35.65650177,
      "lon": -95.36669922,
      "tz": "America\/Chicago"
  },
  "KMKS": {
      "icao": "KMKS",
      "iata": "",
      "name": "Berkeley County Airport",
      "city": "Moncks Corner",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 73,
      "lat": 33.1855010986,
      "lon": -80.0363006592,
      "tz": "America\/New_York"
  },
  "KMKT": {
      "icao": "KMKT",
      "iata": "MKT",
      "name": "Mankato Regional Airport",
      "city": "Mankato",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1021,
      "lat": 44.22159958,
      "lon": -93.91870117,
      "tz": "America\/Chicago"
  },
  "KMKV": {
      "icao": "KMKV",
      "iata": "",
      "name": "Marksville Municipal Airport",
      "city": "Marksville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 79,
      "lat": 31.0946998596,
      "lon": -92.0690994263,
      "tz": "America\/Chicago"
  },
  "KMKY": {
      "icao": "KMKY",
      "iata": "MRK",
      "name": "Marco Island Airport",
      "city": "Marco Island",
      "state": "Florida",
      "country": "US",
      "elevation": 5,
      "lat": 25.9950008392,
      "lon": -81.6725006104,
      "tz": "America\/New_York"
  },
  "KMLB": {
      "icao": "KMLB",
      "iata": "MLB",
      "name": "Melbourne International Airport",
      "city": "Melbourne",
      "state": "Florida",
      "country": "US",
      "elevation": 33,
      "lat": 28.1028003693,
      "lon": -80.6453018188,
      "tz": "America\/New_York"
  },
  "KMLC": {
      "icao": "KMLC",
      "iata": "MLC",
      "name": "Mc Alester Regional Airport",
      "city": "Mc Alester",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 770,
      "lat": 34.88240051,
      "lon": -95.78350067,
      "tz": "America\/Chicago"
  },
  "KMLD": {
      "icao": "KMLD",
      "iata": "MLD",
      "name": "Malad City Airport",
      "city": "Malad City",
      "state": "Idaho",
      "country": "US",
      "elevation": 4503,
      "lat": 42.1665992737,
      "lon": -112.2969970703,
      "tz": "America\/Boise"
  },
  "KMLE": {
      "icao": "KMLE",
      "iata": "MIQ",
      "name": "Millard Airport",
      "city": "Omaha",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1051,
      "lat": 41.1959991455,
      "lon": -96.1119995117,
      "tz": "America\/Chicago"
  },
  "KMLF": {
      "icao": "KMLF",
      "iata": "MLF",
      "name": "Milford Municipal-Ben and Judy Briscoe Field",
      "city": "Milford",
      "state": "Utah",
      "country": "US",
      "elevation": 5039,
      "lat": 38.42660141,
      "lon": -113.012001,
      "tz": "America\/Denver"
  },
  "KMLI": {
      "icao": "KMLI",
      "iata": "MLI",
      "name": "Quad City International Airport",
      "city": "Moline",
      "state": "Illinois",
      "country": "US",
      "elevation": 590,
      "lat": 41.4485015869,
      "lon": -90.5074996948,
      "tz": "America\/Chicago"
  },
  "KMLJ": {
      "icao": "KMLJ",
      "iata": "MLJ",
      "name": "Baldwin County Airport",
      "city": "Milledgeville",
      "state": "Georgia",
      "country": "US",
      "elevation": 385,
      "lat": 33.15420151,
      "lon": -83.24069977,
      "tz": "America\/New_York"
  },
  "KMLS": {
      "icao": "KMLS",
      "iata": "MLS",
      "name": "Frank Wiley Field",
      "city": "Miles City",
      "state": "Montana",
      "country": "US",
      "elevation": 2630,
      "lat": 46.4280014038,
      "lon": -105.8860015869,
      "tz": "America\/Denver"
  },
  "KMLT": {
      "icao": "KMLT",
      "iata": "MLT",
      "name": "Millinocket Municipal Airport",
      "city": "Millinocket",
      "state": "Maine",
      "country": "US",
      "elevation": 408,
      "lat": 45.6478004456,
      "lon": -68.6856002808,
      "tz": "America\/New_York"
  },
  "KMLU": {
      "icao": "KMLU",
      "iata": "MLU",
      "name": "Monroe Regional Airport",
      "city": "Monroe",
      "state": "Louisiana",
      "country": "US",
      "elevation": 79,
      "lat": 32.5108985901,
      "lon": -92.0376968384,
      "tz": "America\/Chicago"
  },
  "KMMH": {
      "icao": "KMMH",
      "iata": "MMH",
      "name": "Mammoth Yosemite Airport",
      "city": "Mammoth Lakes",
      "state": "California",
      "country": "US",
      "elevation": 7135,
      "lat": 37.62409973,
      "lon": -118.8379974,
      "tz": "America\/Los_Angeles"
  },
  "KMMI": {
      "icao": "KMMI",
      "iata": "MMI",
      "name": "McMinn County Airport",
      "city": "Athens",
      "state": "Tennessee",
      "country": "US",
      "elevation": 875,
      "lat": 35.39730072,
      "lon": -84.56259918,
      "tz": "America\/New_York"
  },
  "KMMK": {
      "icao": "KMMK",
      "iata": "",
      "name": "Meriden Markham Municipal Airport",
      "city": "Meriden",
      "state": "Connecticut",
      "country": "US",
      "elevation": 103,
      "lat": 41.5087013245,
      "lon": -72.829498291,
      "tz": "America\/New_York"
  },
  "KMML": {
      "icao": "KMML",
      "iata": "MML",
      "name": "Southwest Minnesota Regional-Marshall-Ryan Field",
      "city": "Marshall",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1183,
      "lat": 44.45050049,
      "lon": -95.82189941,
      "tz": "America\/Chicago"
  },
  "KMMS": {
      "icao": "KMMS",
      "iata": "MMS",
      "name": "Selfs Airport",
      "city": "Marks",
      "state": "Mississippi",
      "country": "US",
      "elevation": 162,
      "lat": 34.2314987183,
      "lon": -90.2895965576,
      "tz": "America\/Chicago"
  },
  "KMMT": {
      "icao": "KMMT",
      "iata": "MMT",
      "name": "Mc Entire JNGB Airport",
      "city": "Eastover",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 254,
      "lat": 33.92079926,
      "lon": -80.80130005,
      "tz": "America\/New_York"
  },
  "KMMU": {
      "icao": "KMMU",
      "iata": "MMU",
      "name": "Morristown Municipal Airport",
      "city": "Morristown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 187,
      "lat": 40.7994003296,
      "lon": -74.4149017334,
      "tz": "America\/New_York"
  },
  "KMMV": {
      "icao": "KMMV",
      "iata": "",
      "name": "Mc Minnville Municipal Airport",
      "city": "Mc Minnville",
      "state": "Oregon",
      "country": "US",
      "elevation": 163,
      "lat": 45.19440079,
      "lon": -123.1360016,
      "tz": "America\/Los_Angeles"
  },
  "KMNE": {
      "icao": "KMNE",
      "iata": "",
      "name": "Minden Airport",
      "city": "Minden",
      "state": "Luisiana",
      "country": "US",
      "elevation": 278,
      "lat": 32.6460278,
      "lon": -93.2980833,
      "tz": "America\/Chicago"
  },
  "KMNF": {
      "icao": "KMNF",
      "iata": "",
      "name": "Mountain View Airport",
      "city": "Mountain View",
      "state": "Missouri",
      "country": "US",
      "elevation": 1181,
      "lat": 36.9928016663,
      "lon": -91.7145004272,
      "tz": "America\/Chicago"
  },
  "KMNI": {
      "icao": "KMNI",
      "iata": "",
      "name": "Santee Cooper Regional Airport",
      "city": "Manning",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 103,
      "lat": 33.5871009827,
      "lon": -80.2087020874,
      "tz": "America\/New_York"
  },
  "KMNM": {
      "icao": "KMNM",
      "iata": "MNM",
      "name": "Menominee Marinette Twin County Airport",
      "city": "Menominee",
      "state": "Michigan",
      "country": "US",
      "elevation": 625,
      "lat": 45.126701355,
      "lon": -87.6383972168,
      "tz": "America\/Chicago"
  },
  "KMNN": {
      "icao": "KMNN",
      "iata": "MNN",
      "name": "Marion Municipal Airport",
      "city": "Marion",
      "state": "Ohio",
      "country": "US",
      "elevation": 993,
      "lat": 40.6161994934,
      "lon": -83.0634994507,
      "tz": "America\/New_York"
  },
  "KMNV": {
      "icao": "KMNV",
      "iata": "",
      "name": "Monroe County Airport",
      "city": "Madisonville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1031,
      "lat": 35.5453987122,
      "lon": -84.3802032471,
      "tz": "America\/New_York"
  },
  "KMNZ": {
      "icao": "KMNZ",
      "iata": "",
      "name": "Hamilton Municipal Airport",
      "city": "Hamilton",
      "state": "New-York",
      "country": "US",
      "elevation": 1299,
      "lat": 31.6658992767,
      "lon": -98.1485977173,
      "tz": "America\/Chicago"
  },
  "KMO1": {
      "icao": "KMO1",
      "iata": "",
      "name": "Richland Municipal Airport",
      "city": "Richland",
      "state": "Missouri",
      "country": "US",
      "elevation": 1110,
      "lat": 37.875,
      "lon": -92.4085006714,
      "tz": "America\/Chicago"
  },
  "KMO3": {
      "icao": "KMO3",
      "iata": "",
      "name": "Stockton Municipal Airport",
      "city": "Stockton",
      "state": "Missouri",
      "country": "US",
      "elevation": 1042,
      "lat": 37.6603012085,
      "lon": -93.8168029785,
      "tz": "America\/Chicago"
  },
  "KMO6": {
      "icao": "KMO6",
      "iata": "",
      "name": "Washington Regional Airport",
      "city": "Washington",
      "state": "Missouri",
      "country": "US",
      "elevation": 488,
      "lat": 38.58760071,
      "lon": -90.9937973,
      "tz": "America\/Chicago"
  },
  "KMO8": {
      "icao": "KMO8",
      "iata": "",
      "name": "North Central Missouri Regional Airport",
      "city": "Brookfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 843,
      "lat": 39.7700004578,
      "lon": -93.0127029419,
      "tz": "America\/Chicago"
  },
  "KMOB": {
      "icao": "KMOB",
      "iata": "MOB",
      "name": "Mobile Regional Airport",
      "city": "Mobile",
      "state": "Alabama",
      "country": "US",
      "elevation": 219,
      "lat": 30.6912002563,
      "lon": -88.2427978516,
      "tz": "America\/Chicago"
  },
  "KMOD": {
      "icao": "KMOD",
      "iata": "MOD",
      "name": "Modesto City Co-Harry Sham Field",
      "city": "Modesto",
      "state": "California",
      "country": "US",
      "elevation": 97,
      "lat": 37.62580109,
      "lon": -120.9540024,
      "tz": "America\/Los_Angeles"
  },
  "KMOP": {
      "icao": "KMOP",
      "iata": "MOP",
      "name": "Mount Pleasant Municipal Airport",
      "city": "Mount Pleasant",
      "state": "Michigan",
      "country": "US",
      "elevation": 755,
      "lat": 43.6217002869,
      "lon": -84.7375030518,
      "tz": "America\/Detroit"
  },
  "KMOR": {
      "icao": "KMOR",
      "iata": "MOR",
      "name": "Moore Murrell Airport",
      "city": "Morristown",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1313,
      "lat": 36.1794013977,
      "lon": -83.37550354,
      "tz": "America\/New_York"
  },
  "KMOT": {
      "icao": "KMOT",
      "iata": "MOT",
      "name": "Minot International Airport",
      "city": "Minot",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1716,
      "lat": 48.2593994141,
      "lon": -101.2799987793,
      "tz": "America\/Chicago"
  },
  "KMOX": {
      "icao": "KMOX",
      "iata": "MOX",
      "name": "Morris Municipal - Charlie Schmidt Airport",
      "city": "Morris",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1138,
      "lat": 45.56600189,
      "lon": -95.96720123,
      "tz": "America\/Chicago"
  },
  "KMPE": {
      "icao": "KMPE",
      "iata": "",
      "name": "Philadelphia Municipal Airport",
      "city": "Philadelphia",
      "state": "Mississippi",
      "country": "US",
      "elevation": 458,
      "lat": 32.79940033,
      "lon": -89.12599945,
      "tz": "America\/Chicago"
  },
  "KMPG": {
      "icao": "KMPG",
      "iata": "",
      "name": "Marshall County Airport",
      "city": "Moundsville",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1214,
      "lat": 39.8807983398,
      "lon": -80.7358016968,
      "tz": "America\/New_York"
  },
  "KMPI": {
      "icao": "KMPI",
      "iata": "RMY",
      "name": "Mariposa Yosemite Airport",
      "city": "Mariposa",
      "state": "California",
      "country": "US",
      "elevation": 2254,
      "lat": 37.5108985901,
      "lon": -120.040000916,
      "tz": "America\/Los_Angeles"
  },
  "KMPJ": {
      "icao": "KMPJ",
      "iata": "MPJ",
      "name": "Petit Jean Park Airport",
      "city": "Morrilton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 923,
      "lat": 35.1389007568,
      "lon": -92.9092025757,
      "tz": "America\/Chicago"
  },
  "KMPO": {
      "icao": "KMPO",
      "iata": "MPO",
      "name": "Pocono Mountains Municipal Airport",
      "city": "Mount Pocono",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1915,
      "lat": 41.13750076,
      "lon": -75.37889862,
      "tz": "America\/New_York"
  },
  "KMPR": {
      "icao": "KMPR",
      "iata": "MPR",
      "name": "Mc Pherson Airport",
      "city": "Mc Pherson",
      "state": "Kansas",
      "country": "US",
      "elevation": 1498,
      "lat": 38.35240173,
      "lon": -97.69129944,
      "tz": "America\/Chicago"
  },
  "KMPV": {
      "icao": "KMPV",
      "iata": "MPV",
      "name": "Edward F Knapp State Airport",
      "city": "Barre\/Montpelier",
      "state": "Vermont",
      "country": "US",
      "elevation": 1166,
      "lat": 44.20349884,
      "lon": -72.56230164,
      "tz": "America\/New_York"
  },
  "KMPZ": {
      "icao": "KMPZ",
      "iata": "MPZ",
      "name": "Mount Pleasant Municipal Airport",
      "city": "Mount Pleasant",
      "state": "Iowa",
      "country": "US",
      "elevation": 730,
      "lat": 40.94660187,
      "lon": -91.51110077,
      "tz": "America\/Chicago"
  },
  "KMQB": {
      "icao": "KMQB",
      "iata": "MQB",
      "name": "Macomb Municipal Airport",
      "city": "Macomb",
      "state": "Illinois",
      "country": "US",
      "elevation": 707,
      "lat": 40.5200996399,
      "lon": -90.6523971558,
      "tz": "America\/Chicago"
  },
  "KMQI": {
      "icao": "KMQI",
      "iata": "MEO",
      "name": "Dare County Regional Airport",
      "city": "Manteo",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 13,
      "lat": 35.91899872,
      "lon": -75.69550323,
      "tz": "America\/New_York"
  },
  "KMQJ": {
      "icao": "KMQJ",
      "iata": "",
      "name": "Mount Comfort Airport",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 862,
      "lat": 39.84349823,
      "lon": -85.89710236,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KMQS": {
      "icao": "KMQS",
      "iata": "CTH",
      "name": "Chester County G O Carlson Airport",
      "city": "Coatesville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 660,
      "lat": 39.97900009,
      "lon": -75.8655014,
      "tz": "America\/New_York"
  },
  "KMQW": {
      "icao": "KMQW",
      "iata": "MQW",
      "name": "Telfair Wheeler Airport",
      "city": "Mc Rae",
      "state": "Georgia",
      "country": "US",
      "elevation": 202,
      "lat": 32.0957984924,
      "lon": -82.8799972534,
      "tz": "America\/New_York"
  },
  "KMQY": {
      "icao": "KMQY",
      "iata": "MQY",
      "name": "Smyrna Airport",
      "city": "Smyrna",
      "state": "Delaware",
      "country": "US",
      "elevation": 543,
      "lat": 36.0089988708,
      "lon": -86.5201034546,
      "tz": "America\/Chicago"
  },
  "KMRB": {
      "icao": "KMRB",
      "iata": "MRB",
      "name": "Eastern Wv Regional Shepherd Field",
      "city": "Martinsburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 565,
      "lat": 39.40190125,
      "lon": -77.98459625,
      "tz": "America\/New_York"
  },
  "KMRC": {
      "icao": "KMRC",
      "iata": "MRC",
      "name": "Maury County Airport",
      "city": "Columbia\/Mount Pleasant",
      "state": "Tennessee",
      "country": "US",
      "elevation": 681,
      "lat": 35.5541000366,
      "lon": -87.1789016724,
      "tz": "America\/Chicago"
  },
  "KMRF": {
      "icao": "KMRF",
      "iata": "MRF",
      "name": "Marfa Municipal Airport",
      "city": "Marfa",
      "state": "Texas",
      "country": "US",
      "elevation": 4849,
      "lat": 30.371099472,
      "lon": -104.0179977417,
      "tz": "America\/Chicago"
  },
  "KMRH": {
      "icao": "KMRH",
      "iata": "",
      "name": "Michael J. Smith Field",
      "city": "Beaufort",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 11,
      "lat": 34.73360062,
      "lon": -76.66059875,
      "tz": "America\/New_York"
  },
  "KMRJ": {
      "icao": "KMRJ",
      "iata": "",
      "name": "Iowa County Airport",
      "city": "Mineral Point",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1171,
      "lat": 42.8867988586,
      "lon": -90.2361984253,
      "tz": "America\/Chicago"
  },
  "KMRN": {
      "icao": "KMRN",
      "iata": "MRN",
      "name": "Foothills Regional Airport",
      "city": "Morganton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1270,
      "lat": 35.8202018738,
      "lon": -81.6113967896,
      "tz": "America\/New_York"
  },
  "KMRT": {
      "icao": "KMRT",
      "iata": "",
      "name": "Union County Airport",
      "city": "Marysville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1021,
      "lat": 40.2247009277,
      "lon": -83.351600647,
      "tz": "America\/New_York"
  },
  "KMRY": {
      "icao": "KMRY",
      "iata": "MRY",
      "name": "Monterey Peninsula Airport",
      "city": "Monterey",
      "state": "California",
      "country": "US",
      "elevation": 257,
      "lat": 36.5870018005,
      "lon": -121.8430023193,
      "tz": "America\/Los_Angeles"
  },
  "KMSL": {
      "icao": "KMSL",
      "iata": "MSL",
      "name": "Northwest Alabama Regional Airport",
      "city": "Muscle Shoals",
      "state": "Alabama",
      "country": "US",
      "elevation": 551,
      "lat": 34.74530029,
      "lon": -87.61019897,
      "tz": "America\/Chicago"
  },
  "KMSN": {
      "icao": "KMSN",
      "iata": "MSN",
      "name": "Dane County Regional Truax Field",
      "city": "Madison",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 887,
      "lat": 43.1399002075,
      "lon": -89.3375015259,
      "tz": "America\/Chicago"
  },
  "KMSO": {
      "icao": "KMSO",
      "iata": "MSO",
      "name": "Missoula International Airport",
      "city": "Missoula",
      "state": "Montana",
      "country": "US",
      "elevation": 3206,
      "lat": 46.91630173,
      "lon": -114.0910034,
      "tz": "America\/Denver"
  },
  "KMSP": {
      "icao": "KMSP",
      "iata": "MSP",
      "name": "Minneapolis-St Paul International\/Wold-Chamberlain Airport",
      "city": "Minneapolis",
      "state": "Minnesota",
      "country": "US",
      "elevation": 841,
      "lat": 44.8819999695,
      "lon": -93.2218017578,
      "tz": "America\/Chicago"
  },
  "KMSS": {
      "icao": "KMSS",
      "iata": "MSS",
      "name": "Massena International Richards Field",
      "city": "Massena",
      "state": "New-York",
      "country": "US",
      "elevation": 215,
      "lat": 44.935798645,
      "lon": -74.8455963135,
      "tz": "America\/New_York"
  },
  "KMSV": {
      "icao": "KMSV",
      "iata": "MSV",
      "name": "Sullivan County International Airport",
      "city": "Monticello",
      "state": "New-York",
      "country": "US",
      "elevation": 1403,
      "lat": 41.70159912,
      "lon": -74.79499817,
      "tz": "America\/New_York"
  },
  "KMSY": {
      "icao": "KMSY",
      "iata": "MSY",
      "name": "Louis Armstrong New Orleans International Airport",
      "city": "New Orleans",
      "state": "Louisiana",
      "country": "US",
      "elevation": 4,
      "lat": 29.9934005737,
      "lon": -90.2580032349,
      "tz": "America\/Chicago"
  },
  "KMTC": {
      "icao": "KMTC",
      "iata": "MTC",
      "name": "Selfridge Angb Airport",
      "city": "Mount Clemens",
      "state": "Michigan",
      "country": "US",
      "elevation": 580,
      "lat": 42.6082992554,
      "lon": -82.8355026245,
      "tz": "America\/Detroit"
  },
  "KMTH": {
      "icao": "KMTH",
      "iata": "MTH",
      "name": "The Florida Keys Marathon Airport",
      "city": "Marathon",
      "state": "Florida",
      "country": "US",
      "elevation": 5,
      "lat": 24.72610092,
      "lon": -81.05139923,
      "tz": "America\/New_York"
  },
  "KMTJ": {
      "icao": "KMTJ",
      "iata": "MTJ",
      "name": "Montrose Regional Airport",
      "city": "Montrose",
      "state": "Colorado",
      "country": "US",
      "elevation": 5759,
      "lat": 38.5097999573,
      "lon": -107.893997192,
      "tz": "America\/Denver"
  },
  "KMTN": {
      "icao": "KMTN",
      "iata": "MTN",
      "name": "Martin State Airport",
      "city": "Baltimore",
      "state": "Maryland",
      "country": "US",
      "elevation": 21,
      "lat": 39.32569885,
      "lon": -76.4138031,
      "tz": "America\/New_York"
  },
  "KMTO": {
      "icao": "KMTO",
      "iata": "MTO",
      "name": "Coles County Memorial Airport",
      "city": "Mattoon\/Charleston",
      "state": "Illinois",
      "country": "US",
      "elevation": 722,
      "lat": 39.47790146,
      "lon": -88.27919769,
      "tz": "America\/Chicago"
  },
  "KMTP": {
      "icao": "KMTP",
      "iata": "MTP",
      "name": "Montauk Airport",
      "city": "Montauk",
      "state": "New-York",
      "country": "US",
      "elevation": 6,
      "lat": 41.076499939,
      "lon": -71.9207992554,
      "tz": "America\/New_York"
  },
  "KMTV": {
      "icao": "KMTV",
      "iata": "",
      "name": "Blue Ridge Airport",
      "city": "Martinsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 941,
      "lat": 36.6306991577,
      "lon": -80.0183029175,
      "tz": "America\/New_York"
  },
  "KMTW": {
      "icao": "KMTW",
      "iata": "MTW",
      "name": "Manitowoc County Airport",
      "city": "Manitowoc",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 651,
      "lat": 44.1287994385,
      "lon": -87.6806030273,
      "tz": "America\/Chicago"
  },
  "KMUI": {
      "icao": "KMUI",
      "iata": "MUI",
      "name": "Muir Army Air Field (Fort Indiantown Gap) Airport",
      "city": "Fort Indiantown Gap(Annville)",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 488,
      "lat": 40.43479919,
      "lon": -76.56939697,
      "tz": "America\/New_York"
  },
  "KMUL": {
      "icao": "KMUL",
      "iata": "MUL",
      "name": "Spence Airport",
      "city": "Moultrie",
      "state": "Georgia",
      "country": "US",
      "elevation": 292,
      "lat": 31.1376991272,
      "lon": -83.7041015625,
      "tz": "America\/New_York"
  },
  "KMUO": {
      "icao": "KMUO",
      "iata": "MUO",
      "name": "Mountain Home Air Force Base",
      "city": "Mountain Home",
      "state": "Idaho",
      "country": "US",
      "elevation": 2996,
      "lat": 43.043598175,
      "lon": -115.8720016479,
      "tz": "America\/Boise"
  },
  "KMUT": {
      "icao": "KMUT",
      "iata": "MUT",
      "name": "Muscatine Municipal Airport",
      "city": "Muscatine",
      "state": "Iowa",
      "country": "US",
      "elevation": 547,
      "lat": 41.3678016663,
      "lon": -91.1482009888,
      "tz": "America\/Chicago"
  },
  "KMVC": {
      "icao": "KMVC",
      "iata": "MVC",
      "name": "Monroe County Airport",
      "city": "Monroeville",
      "state": "Alabama",
      "country": "US",
      "elevation": 419,
      "lat": 31.4580001831,
      "lon": -87.3509979248,
      "tz": "America\/Chicago"
  },
  "KMVE": {
      "icao": "KMVE",
      "iata": "MVE",
      "name": "Montevideo Chippewa County Airport",
      "city": "Montevideo",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1034,
      "lat": 44.9691009521,
      "lon": -95.7102966309,
      "tz": "America\/Chicago"
  },
  "KMVI": {
      "icao": "KMVI",
      "iata": "",
      "name": "Monte Vista Municipal Airport",
      "city": "Monte Vista",
      "state": "Colorado",
      "country": "US",
      "elevation": 7611,
      "lat": 37.5273017883,
      "lon": -106.047996521,
      "tz": "America\/Denver"
  },
  "KMVL": {
      "icao": "KMVL",
      "iata": "MVL",
      "name": "Morrisville Stowe State Airport",
      "city": "Morrisville",
      "state": "Vermont",
      "country": "US",
      "elevation": 732,
      "lat": 44.5345993042,
      "lon": -72.6139984131,
      "tz": "America\/New_York"
  },
  "KMVM": {
      "icao": "KMVM",
      "iata": "",
      "name": "Machias Valley Airport",
      "city": "Machias",
      "state": "Maine",
      "country": "US",
      "elevation": 96,
      "lat": 44.7030982971,
      "lon": -67.4785995483,
      "tz": "America\/New_York"
  },
  "KMVN": {
      "icao": "KMVN",
      "iata": "MVN",
      "name": "Mount Vernon Airport",
      "city": "Mount Vernon",
      "state": "Illinois",
      "country": "US",
      "elevation": 480,
      "lat": 38.3233985901,
      "lon": -88.8588027954,
      "tz": "America\/Chicago"
  },
  "KMVY": {
      "icao": "KMVY",
      "iata": "MVY",
      "name": "Martha's Vineyard Airport",
      "city": "Martha's Vineyard",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 67,
      "lat": 41.3931007385,
      "lon": -70.6143035889,
      "tz": "America\/New_York"
  },
  "KMWA": {
      "icao": "KMWA",
      "iata": "MWA",
      "name": "Williamson County Regional Airport",
      "city": "Marion",
      "state": "Illinois",
      "country": "US",
      "elevation": 472,
      "lat": 37.75500107,
      "lon": -89.01110077,
      "tz": "America\/Chicago"
  },
  "KMWC": {
      "icao": "KMWC",
      "iata": "MWC",
      "name": "Lawrence J Timmerman Airport",
      "city": "Milwaukee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 745,
      "lat": 43.1104011536,
      "lon": -88.0344009399,
      "tz": "America\/Chicago"
  },
  "KMWH": {
      "icao": "KMWH",
      "iata": "MWH",
      "name": "Grant Co International Airport",
      "city": "Moses Lake",
      "state": "Washington",
      "country": "US",
      "elevation": 1189,
      "lat": 47.20769882,
      "lon": -119.3199997,
      "tz": "America\/Los_Angeles"
  },
  "KMWK": {
      "icao": "KMWK",
      "iata": "",
      "name": "Mount Airy Surry County Airport",
      "city": "Mount Airy",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1249,
      "lat": 36.45970154,
      "lon": -80.5530014,
      "tz": "America\/New_York"
  },
  "KMWL": {
      "icao": "KMWL",
      "iata": "MWL",
      "name": "Mineral Wells Airport",
      "city": "Mineral Wells",
      "state": "Texas",
      "country": "US",
      "elevation": 974,
      "lat": 32.7816009521,
      "lon": -98.0602035522,
      "tz": "America\/Chicago"
  },
  "KMWM": {
      "icao": "KMWM",
      "iata": "MWM",
      "name": "Windom Municipal Airport",
      "city": "Windom",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1410,
      "lat": 43.9133987427,
      "lon": -95.1093978882,
      "tz": "America\/Chicago"
  },
  "KMWO": {
      "icao": "KMWO",
      "iata": "MWO",
      "name": "Middletown Regional-Hook Field Municipal Airport",
      "city": "Middletown",
      "state": "Ohio",
      "country": "US",
      "elevation": 650,
      "lat": 39.53099823,
      "lon": -84.39530182,
      "tz": "America\/New_York"
  },
  "KMXA": {
      "icao": "KMXA",
      "iata": "MXA",
      "name": "Manila Municipal Airport",
      "city": "Manila",
      "state": "Arkansas",
      "country": "US",
      "elevation": 242,
      "lat": 35.8944015503,
      "lon": -90.1546020508,
      "tz": "America\/Chicago"
  },
  "KMXF": {
      "icao": "KMXF",
      "iata": "MXF",
      "name": "Maxwell Air Force Base",
      "city": "Montgomery",
      "state": "Alabama",
      "country": "US",
      "elevation": 171,
      "lat": 32.382900238,
      "lon": -86.3657989502,
      "tz": "America\/Chicago"
  },
  "KMXO": {
      "icao": "KMXO",
      "iata": "MXO",
      "name": "Monticello Regional Airport",
      "city": "Monticello",
      "state": "Iowa",
      "country": "US",
      "elevation": 832,
      "lat": 42.22040176,
      "lon": -91.16329956,
      "tz": "America\/Chicago"
  },
  "KMYF": {
      "icao": "KMYF",
      "iata": "MYF",
      "name": "Montgomery Field",
      "city": "San Diego",
      "state": "California",
      "country": "US",
      "elevation": 427,
      "lat": 32.815700531,
      "lon": -117.1399993896,
      "tz": "America\/Los_Angeles"
  },
  "KMYJ": {
      "icao": "KMYJ",
      "iata": "",
      "name": "Mexico Memorial Airport",
      "city": "Mexico",
      "state": "Missouri",
      "country": "US",
      "elevation": 823,
      "lat": 39.1575012207,
      "lon": -91.8182983398,
      "tz": "America\/Chicago"
  },
  "KMYL": {
      "icao": "KMYL",
      "iata": "MYL",
      "name": "Mc Call Municipal Airport",
      "city": "Mc Call",
      "state": "Idaho",
      "country": "US",
      "elevation": 5024,
      "lat": 44.88970184,
      "lon": -116.1009979,
      "tz": "America\/Boise"
  },
  "KMYR": {
      "icao": "KMYR",
      "iata": "MYR",
      "name": "Myrtle Beach International Airport",
      "city": "Myrtle Beach",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 25,
      "lat": 33.6796989441,
      "lon": -78.9282989502,
      "tz": "America\/New_York"
  },
  "KMYV": {
      "icao": "KMYV",
      "iata": "MYV",
      "name": "Yuba County Airport",
      "city": "Marysville",
      "state": "California",
      "country": "US",
      "elevation": 64,
      "lat": 39.09780121,
      "lon": -121.5699997,
      "tz": "America\/Los_Angeles"
  },
  "KMYZ": {
      "icao": "KMYZ",
      "iata": "",
      "name": "Marysville Municipal Airport",
      "city": "Marysville",
      "state": "Kansas",
      "country": "US",
      "elevation": 1283,
      "lat": 39.8553009033,
      "lon": -96.6305999756,
      "tz": "America\/Chicago"
  },
  "KMZH": {
      "icao": "KMZH",
      "iata": "",
      "name": "Moose Lake Carlton County Airport",
      "city": "Moose Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1076,
      "lat": 46.418800354,
      "lon": -92.8047027588,
      "tz": "America\/Chicago"
  },
  "KMZJ": {
      "icao": "KMZJ",
      "iata": "MZJ",
      "name": "Pinal Airpark",
      "city": "Marana",
      "state": "Arizona",
      "country": "US",
      "elevation": 1893,
      "lat": 32.5106010437,
      "lon": -111.3280029297,
      "tz": "America\/Phoenix"
  },
  "KMZZ": {
      "icao": "KMZZ",
      "iata": "MZZ",
      "name": "Marion Municipal Airport",
      "city": "Marion",
      "state": "Indiana",
      "country": "US",
      "elevation": 859,
      "lat": 40.4898986816,
      "lon": -85.6797027588,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KN03": {
      "icao": "KN03",
      "iata": "",
      "name": "Cortland County Chase Field",
      "city": "Cortland",
      "state": "New-York",
      "country": "US",
      "elevation": 1198,
      "lat": 42.59260178,
      "lon": -76.21489716,
      "tz": "America\/New_York"
  },
  "KN06": {
      "icao": "KN06",
      "iata": "",
      "name": "Laurel Airport",
      "city": "Laurel",
      "state": "Delaware",
      "country": "US",
      "elevation": 30,
      "lat": 38.5421981812,
      "lon": -75.5943984985,
      "tz": "America\/New_York"
  },
  "KN12": {
      "icao": "KN12",
      "iata": "",
      "name": "Lakewood Airport",
      "city": "Lakewood",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 43,
      "lat": 40.0667991638,
      "lon": -74.177696228,
      "tz": "America\/New_York"
  },
  "KN13": {
      "icao": "KN13",
      "iata": "",
      "name": "Bloomsburg Municipal Airport",
      "city": "Bloomsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 481,
      "lat": 40.9977989197,
      "lon": -76.4360961914,
      "tz": "America\/New_York"
  },
  "KN14": {
      "icao": "KN14",
      "iata": "",
      "name": "Flying W Airport",
      "city": "Lumberton",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 49,
      "lat": 39.934299469,
      "lon": -74.8072967529,
      "tz": "America\/New_York"
  },
  "KN19": {
      "icao": "KN19",
      "iata": "",
      "name": "Aztec Municipal Airport",
      "city": "Aztec",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5877,
      "lat": 36.8347015381,
      "lon": -108.0279998779,
      "tz": "America\/Denver"
  },
  "KN23": {
      "icao": "KN23",
      "iata": "",
      "name": "Sidney Municipal Airport",
      "city": "Sidney",
      "state": "New-York",
      "country": "US",
      "elevation": 1027,
      "lat": 42.3026008606,
      "lon": -75.4160003662,
      "tz": "America\/New_York"
  },
  "KN24": {
      "icao": "KN24",
      "iata": "",
      "name": "Questa Municipal Nr 2 Airport",
      "city": "Questa",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7700,
      "lat": 36.8003005981,
      "lon": -105.5979995728,
      "tz": "America\/Denver"
  },
  "KN27": {
      "icao": "KN27",
      "iata": "",
      "name": "Bradford County Airport",
      "city": "Towanda",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 730,
      "lat": 41.7400016785,
      "lon": -76.4470977783,
      "tz": "America\/New_York"
  },
  "KN35": {
      "icao": "KN35",
      "iata": "",
      "name": "Punxsutawney Municipal Airport",
      "city": "Punxsutawney",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1439,
      "lat": 40.9667015076,
      "lon": -78.9300003052,
      "tz": "America\/New_York"
  },
  "KN37": {
      "icao": "KN37",
      "iata": "",
      "name": "Monticello Airport",
      "city": "Monticello",
      "state": "New-York",
      "country": "US",
      "elevation": 1545,
      "lat": 41.6225013733,
      "lon": -74.7013015747,
      "tz": "America\/New_York"
  },
  "KN38": {
      "icao": "KN38",
      "iata": "",
      "name": "Wellsboro Johnston Airport",
      "city": "Wellsboro",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1892,
      "lat": 41.72790146,
      "lon": -77.39649963,
      "tz": "America\/New_York"
  },
  "KN40": {
      "icao": "KN40",
      "iata": "",
      "name": "Sky Manor Airport",
      "city": "Pittstown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 560,
      "lat": 40.5662994385,
      "lon": -74.9785995483,
      "tz": "America\/New_York"
  },
  "KN47": {
      "icao": "KN47",
      "iata": "",
      "name": "Pottstown Municipal Airport",
      "city": "Pottstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 256,
      "lat": 40.2602996826,
      "lon": -75.6707992554,
      "tz": "America\/New_York"
  },
  "KN51": {
      "icao": "KN51",
      "iata": "",
      "name": "Solberg Hunterdon Airport",
      "city": "Readington",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 195,
      "lat": 40.5836982727,
      "lon": -74.7361984253,
      "tz": "America\/New_York"
  },
  "KN52": {
      "icao": "KN52",
      "iata": "",
      "name": "Jaars Townsend Airport",
      "city": "Waxhaw",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 602,
      "lat": 34.8638000488,
      "lon": -80.7480010986,
      "tz": "America\/New_York"
  },
  "KN53": {
      "icao": "KN53",
      "iata": "",
      "name": "Stroudsburg Pocono Airport",
      "city": "East Stroudsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 480,
      "lat": 41.0358009338,
      "lon": -75.1605987549,
      "tz": "America\/New_York"
  },
  "KN57": {
      "icao": "KN57",
      "iata": "",
      "name": "New Garden Airport",
      "city": "Toughkenamon",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 436,
      "lat": 39.8305015564,
      "lon": -75.7696990967,
      "tz": "America\/New_York"
  },
  "KN58": {
      "icao": "KN58",
      "iata": "",
      "name": "Tiger Field",
      "city": "Fernley",
      "state": "Nevada",
      "country": "US",
      "elevation": 4346,
      "lat": 39.5596008301,
      "lon": -119.2409973145,
      "tz": "America\/Los_Angeles"
  },
  "KN59": {
      "icao": "KN59",
      "iata": "",
      "name": "Rosaschi Air Park",
      "city": "Smith",
      "state": "Nevada",
      "country": "US",
      "elevation": 4809,
      "lat": 38.8394012451,
      "lon": -119.3379974365,
      "tz": "America\/Los_Angeles"
  },
  "KN66": {
      "icao": "KN66",
      "iata": "",
      "name": "Oneonta Municipal Airport",
      "city": "Oneonta",
      "state": "New-York",
      "country": "US",
      "elevation": 1763,
      "lat": 42.52470016,
      "lon": -75.06439972,
      "tz": "America\/New_York"
  },
  "KN68": {
      "icao": "KN68",
      "iata": "",
      "name": "Franklin County Regional Airport",
      "city": "Chambersburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 697,
      "lat": 39.97309875,
      "lon": -77.64330292,
      "tz": "America\/New_York"
  },
  "KN69": {
      "icao": "KN69",
      "iata": "",
      "name": "Stormville Airport",
      "city": "Stormville",
      "state": "New-York",
      "country": "US",
      "elevation": 358,
      "lat": 41.5769996643,
      "lon": -73.7323989868,
      "tz": "America\/New_York"
  },
  "KN71": {
      "icao": "KN71",
      "iata": "",
      "name": "Donegal Springs Airpark",
      "city": "Mount Joy\/Marietta",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 458,
      "lat": 40.0922012329,
      "lon": -76.5744018555,
      "tz": "America\/New_York"
  },
  "KN73": {
      "icao": "KN73",
      "iata": "",
      "name": "Red Lion Airport",
      "city": "Vincentown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 54,
      "lat": 39.9042015076,
      "lon": -74.74949646,
      "tz": "America\/New_York"
  },
  "KN79": {
      "icao": "KN79",
      "iata": "",
      "name": "Northumberland County Airport",
      "city": "Shamokin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 881,
      "lat": 40.8368988037,
      "lon": -76.5524978638,
      "tz": "America\/New_York"
  },
  "KN81": {
      "icao": "KN81",
      "iata": "",
      "name": "Hammonton Municipal Airport",
      "city": "Hammonton",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 65,
      "lat": 39.66749954,
      "lon": -74.75769806,
      "tz": "America\/New_York"
  },
  "KN82": {
      "icao": "KN82",
      "iata": "",
      "name": "Wurtsboro Sullivan County Airport",
      "city": "Wurtsboro",
      "state": "New-York",
      "country": "US",
      "elevation": 560,
      "lat": 41.5971984863,
      "lon": -74.4582977295,
      "tz": "America\/New_York"
  },
  "KN94": {
      "icao": "KN94",
      "iata": "",
      "name": "Carlisle Airport",
      "city": "Carlisle",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 510,
      "lat": 40.1879005432,
      "lon": -77.1743011475,
      "tz": "America\/New_York"
  },
  "KN96": {
      "icao": "KN96",
      "iata": "",
      "name": "Bellefonte Airport",
      "city": "Bellefonte",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1071,
      "lat": 40.8855018616,
      "lon": -77.8162994385,
      "tz": "America\/New_York"
  },
  "KN98": {
      "icao": "KN98",
      "iata": "",
      "name": "Boyne City Municipal Airport",
      "city": "Boyne City",
      "state": "Michigan",
      "country": "US",
      "elevation": 657,
      "lat": 45.2083015442,
      "lon": -84.9903030396,
      "tz": "America\/Detroit"
  },
  "KNBC": {
      "icao": "KNBC",
      "iata": "",
      "name": "Beaufort MCAS - Merritt Field",
      "city": "Beaufort",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 37,
      "lat": 32.4774017334,
      "lon": -80.723197937,
      "tz": "America\/New_York"
  },
  "KNBG": {
      "icao": "KNBG",
      "iata": "NBG",
      "name": "New Orleans Nas Jrb\/Alvin Callender Field\/ Airport",
      "city": "New Orleans",
      "state": "Louisiana",
      "country": "US",
      "elevation": 2,
      "lat": 29.82530022,
      "lon": -90.03500366,
      "tz": "America\/Chicago"
  },
  "KNBJ": {
      "icao": "KNBJ",
      "iata": "NHX",
      "name": "Barin Nolf Airport",
      "city": "Foley",
      "state": "Alabama",
      "country": "US",
      "elevation": 54,
      "lat": 30.3890991211,
      "lon": -87.6352996826,
      "tz": "America\/Chicago"
  },
  "KNCA": {
      "icao": "KNCA",
      "iata": "",
      "name": "New River MCAS \/H\/ \/Mccutcheon Fld\/ Airport",
      "city": "Jacksonville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 26,
      "lat": 34.70840073,
      "lon": -77.43969727,
      "tz": "America\/New_York"
  },
  "KNDY": {
      "icao": "KNDY",
      "iata": "DGN",
      "name": "Dahlgren Naval Surface Warfare Center Airport",
      "city": "Dahlgren",
      "state": "Virginia",
      "country": "US",
      "elevation": 18,
      "lat": 38.33250046,
      "lon": -77.03720093,
      "tz": "America\/New_York"
  },
  "KNDZ": {
      "icao": "KNDZ",
      "iata": "",
      "name": "Whiting Field Naval Air Station South Airport",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 177,
      "lat": 30.7043991089,
      "lon": -87.0230026245,
      "tz": "America\/Chicago"
  },
  "KNEL": {
      "icao": "KNEL",
      "iata": "NEL",
      "name": "Lakehurst NAES \/Maxfield Field",
      "city": "Lakehurst",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 101,
      "lat": 40.03329849,
      "lon": -74.353302,
      "tz": "America\/New_York"
  },
  "KNEN": {
      "icao": "KNEN",
      "iata": "NEN",
      "name": "Whitehouse Nolf Airport",
      "city": "Jacksonville",
      "state": "Florida",
      "country": "US",
      "elevation": 99,
      "lat": 30.3539009094,
      "lon": -81.8719024658,
      "tz": "America\/New_York"
  },
  "KNEW": {
      "icao": "KNEW",
      "iata": "NEW",
      "name": "Lakefront Airport",
      "city": "New Orleans",
      "state": "Louisiana",
      "country": "US",
      "elevation": 8,
      "lat": 30.0424003601,
      "lon": -90.0282974243,
      "tz": "America\/Chicago"
  },
  "KNFD": {
      "icao": "KNFD",
      "iata": "",
      "name": "Summerdale Nolf Airport",
      "city": "Summerdale",
      "state": "Alabama",
      "country": "US",
      "elevation": 149,
      "lat": 30.5076999664,
      "lon": -87.6455001831,
      "tz": "America\/Chicago"
  },
  "KNFE": {
      "icao": "KNFE",
      "iata": "",
      "name": "Fentress Naval Auxiliary Landing Field",
      "city": "Fentress",
      "state": "Virginia",
      "country": "US",
      "elevation": 16,
      "lat": 36.69509888,
      "lon": -76.13549805,
      "tz": "America\/New_York"
  },
  "KNFG": {
      "icao": "KNFG",
      "iata": "",
      "name": "Camp Pendleton MCAS (Munn Field) Airport",
      "city": "Oceanside",
      "state": "California",
      "country": "US",
      "elevation": 78,
      "lat": 33.30130005,
      "lon": -117.3550034,
      "tz": "America\/Los_Angeles"
  },
  "KNFJ": {
      "icao": "KNFJ",
      "iata": "",
      "name": "Choctaw Nolf Airport",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 102,
      "lat": 30.5069007874,
      "lon": -86.9597015381,
      "tz": "America\/Chicago"
  },
  "KNFL": {
      "icao": "KNFL",
      "iata": "NFL",
      "name": "Fallon Naval Air Station \/Van Voorhis Field",
      "city": "Fallon",
      "state": "Nevada",
      "country": "US",
      "elevation": 3934,
      "lat": 39.41659927,
      "lon": -118.7009964,
      "tz": "America\/Los_Angeles"
  },
  "KNFW": {
      "icao": "KNFW",
      "iata": "FWH",
      "name": "Fort Worth Nas Jrb\/Carswell field",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 650,
      "lat": 32.76919937,
      "lon": -97.4414978,
      "tz": "America\/Chicago"
  },
  "KNGP": {
      "icao": "KNGP",
      "iata": "NGP",
      "name": "Corpus Christi Naval Air Station\/Truax Field",
      "city": "Corpus Christi",
      "state": "Texas",
      "country": "US",
      "elevation": 18,
      "lat": 27.69260025,
      "lon": -97.29109955,
      "tz": "America\/Chicago"
  },
  "KNGS": {
      "icao": "KNGS",
      "iata": "",
      "name": "Santa Rosa Nolf Airport",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 150,
      "lat": 30.6107997894,
      "lon": -86.9400024414,
      "tz": "America\/Chicago"
  },
  "KNGU": {
      "icao": "KNGU",
      "iata": "NGU",
      "name": "Norfolk Ns (Chambers Fld) Airport",
      "city": "Norfolk",
      "state": "Virginia",
      "country": "US",
      "elevation": 17,
      "lat": 36.93759918,
      "lon": -76.28929901,
      "tz": "America\/New_York"
  },
  "KNGW": {
      "icao": "KNGW",
      "iata": "NGW",
      "name": "Cabaniss Field Nolf Airport",
      "city": "Corpus Christi",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 27.7003002167,
      "lon": -97.4344024658,
      "tz": "America\/Chicago"
  },
  "KNHK": {
      "icao": "KNHK",
      "iata": "NHK",
      "name": "Patuxent River Naval Air Station\/Trapnell Field Aiport",
      "city": "Patuxent River",
      "state": "Maryland",
      "country": "US",
      "elevation": 39,
      "lat": 38.2859993,
      "lon": -76.41179657,
      "tz": "America\/New_York"
  },
  "KNHL": {
      "icao": "KNHL",
      "iata": "",
      "name": "Wolf Nolf Airport",
      "city": "Joesphine",
      "state": "Alabama",
      "country": "US",
      "elevation": 61,
      "lat": 30.3460998535,
      "lon": -87.5417022705,
      "tz": "America\/Chicago"
  },
  "KNHZ": {
      "icao": "KNHZ",
      "iata": "",
      "name": "Brunswick Naval Air Station",
      "city": "Brunswick",
      "state": "Maine",
      "country": "US",
      "elevation": 72,
      "lat": 43.89220047,
      "lon": -69.93859863,
      "tz": "America\/New_York"
  },
  "KNID": {
      "icao": "KNID",
      "iata": "",
      "name": "China Lake Naws (Armitage Field) Airport",
      "city": "China Lake",
      "state": "California",
      "country": "US",
      "elevation": 2283,
      "lat": 35.6853981,
      "lon": -117.6920013,
      "tz": "America\/Los_Angeles"
  },
  "KNIP": {
      "icao": "KNIP",
      "iata": "NIP",
      "name": "Jacksonville Naval Air Station",
      "city": "Jacksonville",
      "state": "Florida",
      "country": "US",
      "elevation": 21,
      "lat": 30.23579979,
      "lon": -81.68060303,
      "tz": "America\/New_York"
  },
  "KNJK": {
      "icao": "KNJK",
      "iata": "NJK",
      "name": "El Centro Naf Airport",
      "city": "El Centro",
      "state": "California",
      "country": "US",
      "elevation": -42,
      "lat": 32.8292007446,
      "lon": -115.6719970703,
      "tz": "America\/Los_Angeles"
  },
  "KNJM": {
      "icao": "KNJM",
      "iata": "",
      "name": "Bogue Field Mcalf Airport",
      "city": "Swansboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 21,
      "lat": 34.69039917,
      "lon": -77.02970123,
      "tz": "America\/New_York"
  },
  "KNJW": {
      "icao": "KNJW",
      "iata": "",
      "name": "Joe Williams Nolf Airport",
      "city": "Meridian",
      "state": "Mississippi",
      "country": "US",
      "elevation": 539,
      "lat": 32.7961998,
      "lon": -88.83170319,
      "tz": "America\/Chicago"
  },
  "KNKL": {
      "icao": "KNKL",
      "iata": "",
      "name": "Holley Nolf Airport",
      "city": "Fort Walton Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 39,
      "lat": 30.4253005981,
      "lon": -86.8938980103,
      "tz": "America\/Chicago"
  },
  "KNKT": {
      "icao": "KNKT",
      "iata": "",
      "name": "Cherry Point MCAS \/Cunningham Field\/",
      "city": "Cherry Point",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 29,
      "lat": 34.90090179,
      "lon": -76.88069916,
      "tz": "America\/New_York"
  },
  "KNKX": {
      "icao": "KNKX",
      "iata": "NKX",
      "name": "Miramar Marine Corps Air Station",
      "city": "San Diego",
      "state": "California",
      "country": "US",
      "elevation": 477,
      "lat": 32.86840057,
      "lon": -117.1429977,
      "tz": "America\/Los_Angeles"
  },
  "KNLC": {
      "icao": "KNLC",
      "iata": "NLC",
      "name": "Lemoore Naval Air Station (Reeves Field) Airport",
      "city": "Lemoore",
      "state": "California",
      "country": "US",
      "elevation": 232,
      "lat": 36.33300018,
      "lon": -119.9520035,
      "tz": "America\/Los_Angeles"
  },
  "KNMM": {
      "icao": "KNMM",
      "iata": "",
      "name": "Meridian Naval Air Station",
      "city": "Meridian",
      "state": "Mississippi",
      "country": "US",
      "elevation": 316,
      "lat": 32.55210114,
      "lon": -88.55560303,
      "tz": "America\/Chicago"
  },
  "KNOG": {
      "icao": "KNOG",
      "iata": "",
      "name": "Orange Grove Naval Auxiliary Landing Field",
      "city": "Orange Grove",
      "state": "Texas",
      "country": "US",
      "elevation": 257,
      "lat": 27.9011001587,
      "lon": -98.0516967773,
      "tz": "America\/Chicago"
  },
  "KNOW": {
      "icao": "KNOW",
      "iata": "",
      "name": "Port Angeles Cgas Airport",
      "city": "Port Angeles",
      "state": "Washington",
      "country": "US",
      "elevation": 13,
      "lat": 48.1414985657,
      "lon": -123.4140014648,
      "tz": "America\/Los_Angeles"
  },
  "KNPA": {
      "icao": "KNPA",
      "iata": "NPA",
      "name": "Pensacola Naval Air Station\/Forrest Sherman field",
      "city": "Pensacola",
      "state": "Florida",
      "country": "US",
      "elevation": 28,
      "lat": 30.35269928,
      "lon": -87.31860352,
      "tz": "America\/Chicago"
  },
  "KNQA": {
      "icao": "KNQA",
      "iata": "NQA",
      "name": "Millington Regional Jetport Airport",
      "city": "Millington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 320,
      "lat": 35.3567008972,
      "lon": -89.870300293,
      "tz": "America\/Chicago"
  },
  "KNQB": {
      "icao": "KNQB",
      "iata": "",
      "name": "Silverhill Nolf Airport",
      "city": "",
      "state": "Alabama",
      "country": "US",
      "elevation": 129,
      "lat": 30.5636005402,
      "lon": -87.8097000122,
      "tz": "America\/Chicago"
  },
  "KNQI": {
      "icao": "KNQI",
      "iata": "NQI",
      "name": "Kingsville Naval Air Station",
      "city": "Kingsville",
      "state": "Texas",
      "country": "US",
      "elevation": 50,
      "lat": 27.5072002411,
      "lon": -97.8097000122,
      "tz": "America\/Chicago"
  },
  "KNQX": {
      "icao": "KNQX",
      "iata": "NQX",
      "name": "Key West Nas \/Boca Chica Field\/ Airport",
      "city": "Key West",
      "state": "Florida",
      "country": "US",
      "elevation": 6,
      "lat": 24.57579994,
      "lon": -81.68890381,
      "tz": "America\/New_York"
  },
  "KNRA": {
      "icao": "KNRA",
      "iata": "",
      "name": "Coupeville Nolf Airport",
      "city": "Coupeville",
      "state": "Washington",
      "country": "US",
      "elevation": 199,
      "lat": 48.1883010864,
      "lon": -122.6320037842,
      "tz": "America\/Los_Angeles"
  },
  "KNRB": {
      "icao": "KNRB",
      "iata": "NRB",
      "name": "Mayport Ns (Adm David L. Mcdonald Field) Airport",
      "city": "Mayport",
      "state": "Florida",
      "country": "US",
      "elevation": 15,
      "lat": 30.39109993,
      "lon": -81.42469788,
      "tz": "America\/New_York"
  },
  "KNRN": {
      "icao": "KNRN",
      "iata": "",
      "name": "Norton Municipal Airport",
      "city": "Norton",
      "state": "Kansas",
      "country": "US",
      "elevation": 2383,
      "lat": 39.8503990173,
      "lon": -99.8946990967,
      "tz": "America\/Chicago"
  },
  "KNRQ": {
      "icao": "KNRQ",
      "iata": "",
      "name": "Spencer Nolf Airport",
      "city": "Pace",
      "state": "Florida",
      "country": "US",
      "elevation": 151,
      "lat": 30.6252994537,
      "lon": -87.1399993896,
      "tz": "America\/Chicago"
  },
  "KNRS": {
      "icao": "KNRS",
      "iata": "NRS",
      "name": "Imperial Beach Nolf (Ream Fld) Airport",
      "city": "Imperial Beach",
      "state": "California",
      "country": "US",
      "elevation": 24,
      "lat": 32.56669998,
      "lon": -117.1169968,
      "tz": "America\/Los_Angeles"
  },
  "KNSE": {
      "icao": "KNSE",
      "iata": "NSE",
      "name": "Whiting Field Naval Air Station North Airport",
      "city": "Milton",
      "state": "Florida",
      "country": "US",
      "elevation": 199,
      "lat": 30.7241993,
      "lon": -87.02189636,
      "tz": "America\/Chicago"
  },
  "KNSI": {
      "icao": "KNSI",
      "iata": "",
      "name": "San Nicolas Island Nolf Airport",
      "city": "San Nicolas Island",
      "state": "California",
      "country": "US",
      "elevation": 506,
      "lat": 33.2397994995,
      "lon": -119.4580001831,
      "tz": "America\/Los_Angeles"
  },
  "KNTD": {
      "icao": "KNTD",
      "iata": "NTD",
      "name": "Point Mugu Naval Air Station (Naval Base Ventura Co)",
      "city": "Point Mugu",
      "state": "California",
      "country": "US",
      "elevation": 13,
      "lat": 34.120300293,
      "lon": -119.121002197,
      "tz": "America\/Los_Angeles"
  },
  "KNTU": {
      "icao": "KNTU",
      "iata": "NTU",
      "name": "Oceana Nas \/Apollo Soucek Field\/ Station",
      "city": "Virginia Beach",
      "state": "Virginia",
      "country": "US",
      "elevation": 23,
      "lat": 36.8207016,
      "lon": -76.03350067,
      "tz": "America\/New_York"
  },
  "KNUC": {
      "icao": "KNUC",
      "iata": "",
      "name": "San Clemente Island Naval Auxiliary Landing Field",
      "city": "San Clemente Island",
      "state": "California",
      "country": "US",
      "elevation": 184,
      "lat": 33.02270126,
      "lon": -118.5879974,
      "tz": "America\/Los_Angeles"
  },
  "KNUI": {
      "icao": "KNUI",
      "iata": "",
      "name": "Webster Nolf Airport",
      "city": "St Inigoes",
      "state": "Maryland",
      "country": "US",
      "elevation": 22,
      "lat": 38.1459999084,
      "lon": -76.4285964966,
      "tz": "America\/New_York"
  },
  "KNUN": {
      "icao": "KNUN",
      "iata": "NUN",
      "name": "Saufley Field Nolf Airport",
      "city": "Pensacola",
      "state": "Florida",
      "country": "US",
      "elevation": 85,
      "lat": 30.4696998596,
      "lon": -87.3380966187,
      "tz": "America\/Chicago"
  },
  "KNUQ": {
      "icao": "KNUQ",
      "iata": "NUQ",
      "name": "Moffett Federal Airfield",
      "city": "Mountain View",
      "state": "California",
      "country": "US",
      "elevation": 32,
      "lat": 37.4160995483,
      "lon": -122.0490036011,
      "tz": "America\/Los_Angeles"
  },
  "KNUW": {
      "icao": "KNUW",
      "iata": "NUW",
      "name": "Whidbey Island Naval Air Station \/Ault Field\/ Airport",
      "city": "Oak Harbor",
      "state": "Washington",
      "country": "US",
      "elevation": 47,
      "lat": 48.35179901,
      "lon": -122.6559982,
      "tz": "America\/Los_Angeles"
  },
  "KNVD": {
      "icao": "KNVD",
      "iata": "NVD",
      "name": "Nevada Municipal Airport",
      "city": "Nevada",
      "state": "Missouri",
      "country": "US",
      "elevation": 892,
      "lat": 37.8521003723,
      "lon": -94.304901123,
      "tz": "America\/Chicago"
  },
  "KNWL": {
      "icao": "KNWL",
      "iata": "",
      "name": "Waldron Field Nolf Airport",
      "city": "Corpus Christi",
      "state": "Texas",
      "country": "US",
      "elevation": 25,
      "lat": 27.6350002289,
      "lon": -97.3122024536,
      "tz": "America\/Chicago"
  },
  "KNXF": {
      "icao": "KNXF",
      "iata": "",
      "name": "MCOLF Camp Pendleton (Red Beach) Airport",
      "city": "Oceanside",
      "state": "California",
      "country": "US",
      "elevation": 89,
      "lat": 33.285644,
      "lon": -117.456478,
      "tz": "America\/Los_Angeles"
  },
  "KNXP": {
      "icao": "KNXP",
      "iata": "",
      "name": "Twentynine Palms (Self) Airport",
      "city": "Twentynine Palms",
      "state": "California",
      "country": "US",
      "elevation": 2051,
      "lat": 34.2961998,
      "lon": -116.1620026,
      "tz": "America\/Los_Angeles"
  },
  "KNXX": {
      "icao": "KNXX",
      "iata": "",
      "name": "Willow Grove Naval Air Station\/Joint Reserve Base",
      "city": "Willow Grove",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 358,
      "lat": 40.19979858,
      "lon": -75.14820099,
      "tz": "America\/New_York"
  },
  "KNY0": {
      "icao": "KNY0",
      "iata": "",
      "name": "Fulton County Airport",
      "city": "Johnstown",
      "state": "New-York",
      "country": "US",
      "elevation": 881,
      "lat": 42.9981994629,
      "lon": -74.3295974731,
      "tz": "America\/New_York"
  },
  "KNY2": {
      "icao": "KNY2",
      "iata": "",
      "name": "Camillus Airport",
      "city": "Camillus",
      "state": "New-York",
      "country": "US",
      "elevation": 405,
      "lat": 43.0834007263,
      "lon": -76.2829971313,
      "tz": "America\/New_York"
  },
  "KNYG": {
      "icao": "KNYG",
      "iata": "NYG",
      "name": "Quantico MCAF \/Turner field",
      "city": "Quantico",
      "state": "Virginia",
      "country": "US",
      "elevation": 10,
      "lat": 38.50170135,
      "lon": -77.30529785,
      "tz": "America\/New_York"
  },
  "KNYL": {
      "icao": "KNYL",
      "iata": "YUM",
      "name": "Yuma MCAS\/Yuma International Airport",
      "city": "Yuma",
      "state": "Arizona",
      "country": "US",
      "elevation": 213,
      "lat": 32.65660095,
      "lon": -114.6060028,
      "tz": "America\/Phoenix"
  },
  "KNZY": {
      "icao": "KNZY",
      "iata": "NZY",
      "name": "North Island Naval Air Station-Halsey Field",
      "city": "San Diego",
      "state": "California",
      "country": "US",
      "elevation": 26,
      "lat": 32.69919968,
      "lon": -117.2149963,
      "tz": "America\/Los_Angeles"
  },
  "KO02": {
      "icao": "KO02",
      "iata": "NVN",
      "name": "Nervino Airport",
      "city": "Beckwourth",
      "state": "California",
      "country": "US",
      "elevation": 4900,
      "lat": 39.8185005188,
      "lon": -120.352996826,
      "tz": "America\/Los_Angeles"
  },
  "KO05": {
      "icao": "KO05",
      "iata": "",
      "name": "Rogers Field",
      "city": "Chester",
      "state": "California",
      "country": "US",
      "elevation": 4528,
      "lat": 40.2823982239,
      "lon": -121.2409973145,
      "tz": "America\/Los_Angeles"
  },
  "KO08": {
      "icao": "KO08",
      "iata": "",
      "name": "Colusa County Airport",
      "city": "Colusa",
      "state": "California",
      "country": "US",
      "elevation": 50,
      "lat": 39.17900085,
      "lon": -121.9929962,
      "tz": "America\/Los_Angeles"
  },
  "KO09": {
      "icao": "KO09",
      "iata": "",
      "name": "Round Valley Airport",
      "city": "Covelo",
      "state": "California",
      "country": "US",
      "elevation": 1434,
      "lat": 39.7901992798,
      "lon": -123.2659988403,
      "tz": "America\/Los_Angeles"
  },
  "KO11": {
      "icao": "KO11",
      "iata": "",
      "name": "Stilwell Cherokee Nation Airport",
      "city": "Stilwell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1084,
      "lat": 35.7569999695,
      "lon": -94.6499023438,
      "tz": "America\/Chicago"
  },
  "KO15": {
      "icao": "KO15",
      "iata": "",
      "name": "Turlock Municipal Airport",
      "city": "Turlock",
      "state": "California",
      "country": "US",
      "elevation": 159,
      "lat": 37.4874000549,
      "lon": -120.6969985962,
      "tz": "America\/Los_Angeles"
  },
  "KO16": {
      "icao": "KO16",
      "iata": "",
      "name": "Garberville Airport",
      "city": "Garberville",
      "state": "California",
      "country": "US",
      "elevation": 546,
      "lat": 40.0859985352,
      "lon": -123.8140029907,
      "tz": "America\/Los_Angeles"
  },
  "KO20": {
      "icao": "KO20",
      "iata": "",
      "name": "Kingdon Airpark",
      "city": "Lodi",
      "state": "California",
      "country": "US",
      "elevation": 15,
      "lat": 38.0915985107,
      "lon": -121.3590011597,
      "tz": "America\/Los_Angeles"
  },
  "KO22": {
      "icao": "KO22",
      "iata": "",
      "name": "Columbia Airport",
      "city": "Columbia",
      "state": "California",
      "country": "US",
      "elevation": 2118,
      "lat": 38.0303993225,
      "lon": -120.4150009155,
      "tz": "America\/Los_Angeles"
  },
  "KO26": {
      "icao": "KO26",
      "iata": "",
      "name": "Lone Pine Airport",
      "city": "Lone Pine",
      "state": "California",
      "country": "US",
      "elevation": 3680,
      "lat": 36.5882987976,
      "lon": -118.0520019531,
      "tz": "America\/Los_Angeles"
  },
  "KO27": {
      "icao": "KO27",
      "iata": "ODC",
      "name": "Oakdale Airport",
      "city": "Oakdale",
      "state": "California",
      "country": "US",
      "elevation": 237,
      "lat": 37.75630188,
      "lon": -120.8000031,
      "tz": "America\/Los_Angeles"
  },
  "KO28": {
      "icao": "KO28",
      "iata": "",
      "name": "Ells Field Willits Municipal Airport",
      "city": "Willits",
      "state": "California",
      "country": "US",
      "elevation": 2063,
      "lat": 39.4513015747,
      "lon": -123.3720016479,
      "tz": "America\/Los_Angeles"
  },
  "KO32": {
      "icao": "KO32",
      "iata": "",
      "name": "Reedley Municipal Airport",
      "city": "Reedley",
      "state": "California",
      "country": "US",
      "elevation": 383,
      "lat": 36.6663017273,
      "lon": -119.4499969482,
      "tz": "America\/Los_Angeles"
  },
  "KO35": {
      "icao": "KO35",
      "iata": "",
      "name": "Hollis Municipal Airport",
      "city": "Hollis",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1658,
      "lat": 34.7084007263,
      "lon": -99.9086990356,
      "tz": "America\/Chicago"
  },
  "KO37": {
      "icao": "KO37",
      "iata": "",
      "name": "Haigh Field",
      "city": "Orland",
      "state": "California",
      "country": "US",
      "elevation": 215,
      "lat": 39.7211990356,
      "lon": -122.1470031738,
      "tz": "America\/Los_Angeles"
  },
  "KO41": {
      "icao": "KO41",
      "iata": "",
      "name": "Watts Woodland Airport",
      "city": "Woodland",
      "state": "California",
      "country": "US",
      "elevation": 125,
      "lat": 38.6739006042,
      "lon": -121.8720016479,
      "tz": "America\/Los_Angeles"
  },
  "KO42": {
      "icao": "KO42",
      "iata": "",
      "name": "Woodlake Airport",
      "city": "Woodlake",
      "state": "California",
      "country": "US",
      "elevation": 425,
      "lat": 36.3987998962,
      "lon": -119.1070022583,
      "tz": "America\/Los_Angeles"
  },
  "KO43": {
      "icao": "KO43",
      "iata": "EYR",
      "name": "Yerington Municipal Airport",
      "city": "Yerington",
      "state": "Nevada",
      "country": "US",
      "elevation": 4378,
      "lat": 39.0041007996,
      "lon": -119.157997131,
      "tz": "America\/Los_Angeles"
  },
  "KO46": {
      "icao": "KO46",
      "iata": "",
      "name": "Weed Airport",
      "city": "Weed",
      "state": "California",
      "country": "US",
      "elevation": 2938,
      "lat": 41.474899292,
      "lon": -122.4530029297,
      "tz": "America\/Los_Angeles"
  },
  "KO47": {
      "icao": "KO47",
      "iata": "",
      "name": "Prague Municipal Airport",
      "city": "Prague",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1042,
      "lat": 35.4822998047,
      "lon": -96.7186965942,
      "tz": "America\/Chicago"
  },
  "KO51": {
      "icao": "KO51",
      "iata": "",
      "name": "Laverne Municipal Airport",
      "city": "Laverne",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2112,
      "lat": 36.7444992065,
      "lon": -99.9086990356,
      "tz": "America\/Chicago"
  },
  "KO52": {
      "icao": "KO52",
      "iata": "",
      "name": "Sutter County Airport",
      "city": "Yuba City",
      "state": "California",
      "country": "US",
      "elevation": 58,
      "lat": 39.1236991882,
      "lon": -121.6050033569,
      "tz": "America\/Los_Angeles"
  },
  "KO53": {
      "icao": "KO53",
      "iata": "",
      "name": "Medford Municipal Airport",
      "city": "Medford",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1092,
      "lat": 36.790599823,
      "lon": -97.7490005493,
      "tz": "America\/Chicago"
  },
  "KO54": {
      "icao": "KO54",
      "iata": "",
      "name": "Lonnie Pool Field Weaverville Airport",
      "city": "Weaverville",
      "state": "California",
      "country": "US",
      "elevation": 2350,
      "lat": 40.7457008362,
      "lon": -122.9219970703,
      "tz": "America\/Los_Angeles"
  },
  "KO57": {
      "icao": "KO57",
      "iata": "",
      "name": "Bryant Field",
      "city": "Bridgeport",
      "state": "California",
      "country": "US",
      "elevation": 6468,
      "lat": 38.2624015808,
      "lon": -119.2259979248,
      "tz": "America\/Los_Angeles"
  },
  "KO59": {
      "icao": "KO59",
      "iata": "",
      "name": "Cedarville Airport",
      "city": "Cedarville",
      "state": "California",
      "country": "US",
      "elevation": 4623,
      "lat": 41.5527000427,
      "lon": -120.1660003662,
      "tz": "America\/Los_Angeles"
  },
  "KO61": {
      "icao": "KO61",
      "iata": "",
      "name": "Cameron Airpark",
      "city": "Cameron Park",
      "state": "California",
      "country": "US",
      "elevation": 1286,
      "lat": 38.6839981079,
      "lon": -120.9879989624,
      "tz": "America\/Los_Angeles"
  },
  "KO65": {
      "icao": "KO65",
      "iata": "",
      "name": "Christman Airfield",
      "city": "Okeene",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1205,
      "lat": 36.1124992371,
      "lon": -98.3087005615,
      "tz": "America\/Chicago"
  },
  "KO69": {
      "icao": "KO69",
      "iata": "",
      "name": "Petaluma Municipal Airport",
      "city": "Petaluma",
      "state": "California",
      "country": "US",
      "elevation": 90,
      "lat": 38.2578333333,
      "lon": -122.6053333333,
      "tz": "America\/Los_Angeles"
  },
  "KO79": {
      "icao": "KO79",
      "iata": "",
      "name": "Sierraville Dearwater Airport",
      "city": "Sierraville",
      "state": "California",
      "country": "US",
      "elevation": 4984,
      "lat": 39.5810012817,
      "lon": -120.3539962769,
      "tz": "America\/Los_Angeles"
  },
  "KO81": {
      "icao": "KO81",
      "iata": "",
      "name": "Tulelake Municipal Airport",
      "city": "Tulelake",
      "state": "California",
      "country": "US",
      "elevation": 4044,
      "lat": 41.8874015808,
      "lon": -121.3590011597,
      "tz": "America\/Los_Angeles"
  },
  "KO86": {
      "icao": "KO86",
      "iata": "",
      "name": "Trinity Center Airport",
      "city": "Trinity Center",
      "state": "California",
      "country": "US",
      "elevation": 2390,
      "lat": 40.9832000732,
      "lon": -122.6940002441,
      "tz": "America\/Los_Angeles"
  },
  "KO88": {
      "icao": "KO88",
      "iata": "",
      "name": "Rio Vista Municipal Airport",
      "city": "Rio Vista",
      "state": "California",
      "country": "US",
      "elevation": 20,
      "lat": 38.1934013367,
      "lon": -121.7040023804,
      "tz": "America\/Los_Angeles"
  },
  "KO89": {
      "icao": "KO89",
      "iata": "",
      "name": "Fall River Mills Airport",
      "city": "Fall River Mills",
      "state": "California",
      "country": "US",
      "elevation": 3323,
      "lat": 41.0187988281,
      "lon": -121.4329986572,
      "tz": "America\/Los_Angeles"
  },
  "KOAJ": {
      "icao": "KOAJ",
      "iata": "OAJ",
      "name": "Albert J Ellis Airport",
      "city": "Jacksonville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 94,
      "lat": 34.8292007446,
      "lon": -77.6120986938,
      "tz": "America\/New_York"
  },
  "KOAK": {
      "icao": "KOAK",
      "iata": "OAK",
      "name": "Metropolitan Oakland International Airport",
      "city": "Oakland",
      "state": "California",
      "country": "US",
      "elevation": 9,
      "lat": 37.7212982178,
      "lon": -122.2210006714,
      "tz": "America\/Los_Angeles"
  },
  "KOAR": {
      "icao": "KOAR",
      "iata": "OAR",
      "name": "Marina Municipal Airport",
      "city": "Marina",
      "state": "California",
      "country": "US",
      "elevation": 137,
      "lat": 36.68190002,
      "lon": -121.762001,
      "tz": "America\/Los_Angeles"
  },
  "KOBE": {
      "icao": "KOBE",
      "iata": "OBE",
      "name": "Okeechobee County Airport",
      "city": "Okeechobee",
      "state": "Florida",
      "country": "US",
      "elevation": 34,
      "lat": 27.2628002167,
      "lon": -80.8498001099,
      "tz": "America\/New_York"
  },
  "KOBI": {
      "icao": "KOBI",
      "iata": "",
      "name": "Woodbine Municipal Airport",
      "city": "Woodbine",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 42,
      "lat": 39.2192001343,
      "lon": -74.7947998047,
      "tz": "America\/New_York"
  },
  "KOCF": {
      "icao": "KOCF",
      "iata": "OCF",
      "name": "Ocala International Jim Taylor Field",
      "city": "Ocala",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 29.17259979,
      "lon": -82.22419739,
      "tz": "America\/New_York"
  },
  "KOCH": {
      "icao": "KOCH",
      "iata": "OCH",
      "name": "A L Mangham Jr. Regional Airport",
      "city": "Nacogdoches",
      "state": "Texas",
      "country": "US",
      "elevation": 355,
      "lat": 31.57799911,
      "lon": -94.70950317,
      "tz": "America\/Chicago"
  },
  "KOCQ": {
      "icao": "KOCQ",
      "iata": "",
      "name": "J. Douglas Bake Memorial Airport",
      "city": "Oconto",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 604,
      "lat": 44.87419891,
      "lon": -87.9095993,
      "tz": "America\/Chicago"
  },
  "KOCW": {
      "icao": "KOCW",
      "iata": "OCW",
      "name": "Warren Field",
      "city": "Washington",
      "state": "Georgia",
      "country": "US",
      "elevation": 38,
      "lat": 35.5704994202,
      "lon": -77.0497970581,
      "tz": "America\/New_York"
  },
  "KODO": {
      "icao": "KODO",
      "iata": "",
      "name": "Odessa Schlemeyer Field",
      "city": "Odessa",
      "state": "Texas",
      "country": "US",
      "elevation": 3004,
      "lat": 31.9206008911,
      "lon": -102.3870010376,
      "tz": "America\/Chicago"
  },
  "KODX": {
      "icao": "KODX",
      "iata": "",
      "name": "Evelyn Sharp Field",
      "city": "Ord",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2070,
      "lat": 41.6241989136,
      "lon": -98.9524002075,
      "tz": "America\/Chicago"
  },
  "KOEA": {
      "icao": "KOEA",
      "iata": "",
      "name": "O'Neal Airport",
      "city": "Vincennes",
      "state": "Illinois",
      "country": "US",
      "elevation": 414,
      "lat": 38.6913986206,
      "lon": -87.5522003174,
      "tz": "America\/Chicago"
  },
  "KOEB": {
      "icao": "KOEB",
      "iata": "",
      "name": "Branch County Memorial Airport",
      "city": "Coldwater",
      "state": "Michigan",
      "country": "US",
      "elevation": 959,
      "lat": 41.9333992,
      "lon": -85.05259705,
      "tz": "America\/Detroit"
  },
  "KOEL": {
      "icao": "KOEL",
      "iata": "",
      "name": "Oakley Municipal Airport",
      "city": "Oakley",
      "state": "Kansas",
      "country": "US",
      "elevation": 3045,
      "lat": 39.1099014282,
      "lon": -100.8160018921,
      "tz": "America\/Chicago"
  },
  "KOEO": {
      "icao": "KOEO",
      "iata": "OEO",
      "name": "L O Simenstad Municipal Airport",
      "city": "Osceola",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 903,
      "lat": 45.3100013733,
      "lon": -92.6919021606,
      "tz": "America\/Chicago"
  },
  "KOFF": {
      "icao": "KOFF",
      "iata": "OFF",
      "name": "Offutt Air Force Base",
      "city": "Omaha",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1052,
      "lat": 41.1183013916,
      "lon": -95.9124984741,
      "tz": "America\/Chicago"
  },
  "KOFK": {
      "icao": "KOFK",
      "iata": "OFK",
      "name": "Karl Stefan Memorial Airport",
      "city": "Norfolk",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1573,
      "lat": 41.9855003357,
      "lon": -97.4350967407,
      "tz": "America\/Chicago"
  },
  "KOFP": {
      "icao": "KOFP",
      "iata": "",
      "name": "Hanover County Municipal Airport",
      "city": "Richmond\/Ashland",
      "state": "Virginia",
      "country": "US",
      "elevation": 207,
      "lat": 37.70899963,
      "lon": -77.43669891,
      "tz": "America\/New_York"
  },
  "KOGA": {
      "icao": "KOGA",
      "iata": "OGA",
      "name": "Searle Field",
      "city": "Ogallala",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3279,
      "lat": 41.11949921,
      "lon": -101.7699966,
      "tz": "America\/Denver"
  },
  "KOGB": {
      "icao": "KOGB",
      "iata": "OGB",
      "name": "Orangeburg Municipal Airport",
      "city": "Orangeburg",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 195,
      "lat": 33.4567985535,
      "lon": -80.8594970703,
      "tz": "America\/New_York"
  },
  "KOGD": {
      "icao": "KOGD",
      "iata": "OGD",
      "name": "Ogden Hinckley Airport",
      "city": "Ogden",
      "state": "Utah",
      "country": "US",
      "elevation": 4473,
      "lat": 41.1958999634,
      "lon": -112.0120010376,
      "tz": "America\/Denver"
  },
  "KOGM": {
      "icao": "KOGM",
      "iata": "",
      "name": "Ontonagon County Schuster Field",
      "city": "Ontonagon",
      "state": "Michigan",
      "country": "US",
      "elevation": 669,
      "lat": 46.84550095,
      "lon": -89.36710358,
      "tz": "America\/Detroit"
  },
  "KOGS": {
      "icao": "KOGS",
      "iata": "OGS",
      "name": "Ogdensburg International Airport",
      "city": "Ogdensburg",
      "state": "New-York",
      "country": "US",
      "elevation": 297,
      "lat": 44.6819000244,
      "lon": -75.4654998779,
      "tz": "America\/New_York"
  },
  "KOIC": {
      "icao": "KOIC",
      "iata": "OIC",
      "name": "Lt Warren Eaton Airport",
      "city": "Norwich",
      "state": "New-York",
      "country": "US",
      "elevation": 1025,
      "lat": 42.5666007996,
      "lon": -75.5241012573,
      "tz": "America\/New_York"
  },
  "KOIN": {
      "icao": "KOIN",
      "iata": "",
      "name": "Oberlin Municipal Airport",
      "city": "Oberlin",
      "state": "Kansas",
      "country": "US",
      "elevation": 2703,
      "lat": 39.8339004517,
      "lon": -100.5390014648,
      "tz": "America\/Chicago"
  },
  "KOJA": {
      "icao": "KOJA",
      "iata": "",
      "name": "Thomas P Stafford Airport",
      "city": "Weatherford",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1605,
      "lat": 35.5447998047,
      "lon": -98.6685028076,
      "tz": "America\/Chicago"
  },
  "KOJC": {
      "icao": "KOJC",
      "iata": "OJC",
      "name": "Johnson County Executive Airport",
      "city": "Olathe",
      "state": "Kansas",
      "country": "US",
      "elevation": 1096,
      "lat": 38.84759903,
      "lon": -94.73760223,
      "tz": "America\/Chicago"
  },
  "KOKB": {
      "icao": "KOKB",
      "iata": "OCN",
      "name": "Oceanside Municipal Airport",
      "city": "Oceanside",
      "state": "California",
      "country": "US",
      "elevation": 28,
      "lat": 33.217300415,
      "lon": -117.3539962769,
      "tz": "America\/Los_Angeles"
  },
  "KOKC": {
      "icao": "KOKC",
      "iata": "OKC",
      "name": "Will Rogers World Airport",
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1295,
      "lat": 35.3931007385,
      "lon": -97.6007003784,
      "tz": "America\/Chicago"
  },
  "KOKH": {
      "icao": "KOKH",
      "iata": "ODW",
      "name": "AJ Eisenberg Airport",
      "city": "Oak Harbor",
      "state": "Washington",
      "country": "US",
      "elevation": 193,
      "lat": 48.251499176,
      "lon": -122.674003601,
      "tz": "America\/Los_Angeles"
  },
  "KOKK": {
      "icao": "KOKK",
      "iata": "OKK",
      "name": "Kokomo Municipal Airport",
      "city": "Kokomo",
      "state": "Indiana",
      "country": "US",
      "elevation": 830,
      "lat": 40.5281982422,
      "lon": -86.0589981079,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KOKM": {
      "icao": "KOKM",
      "iata": "OKM",
      "name": "Okmulgee Regional Airport",
      "city": "Okmulgee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 720,
      "lat": 35.6680984497,
      "lon": -95.9486999512,
      "tz": "America\/Chicago"
  },
  "KOKS": {
      "icao": "KOKS",
      "iata": "OKS",
      "name": "Garden County Airport",
      "city": "Oshkosh",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3394,
      "lat": 41.4010009766,
      "lon": -102.3550033569,
      "tz": "America\/Denver"
  },
  "KOKV": {
      "icao": "KOKV",
      "iata": "WGO",
      "name": "Winchester Regional Airport",
      "city": "Winchester",
      "state": "Virginia",
      "country": "US",
      "elevation": 726,
      "lat": 39.14350128,
      "lon": -78.14440155,
      "tz": "America\/New_York"
  },
  "KOKZ": {
      "icao": "KOKZ",
      "iata": "",
      "name": "Kaolin Field",
      "city": "Sandersville",
      "state": "Georgia",
      "country": "US",
      "elevation": 438,
      "lat": 32.9667015076,
      "lon": -82.8382034302,
      "tz": "America\/New_York"
  },
  "KOLD": {
      "icao": "KOLD",
      "iata": "OLD",
      "name": "Dewitt FieldOld Town Municipal Airport",
      "city": "Old Town",
      "state": "Maine",
      "country": "US",
      "elevation": 126,
      "lat": 44.95280075,
      "lon": -68.67430115,
      "tz": "America\/New_York"
  },
  "KOLE": {
      "icao": "KOLE",
      "iata": "OLE",
      "name": "Cattaraugus County-Olean Airport",
      "city": "Olean",
      "state": "New-York",
      "country": "US",
      "elevation": 2135,
      "lat": 42.24119949,
      "lon": -78.37139893,
      "tz": "America\/New_York"
  },
  "KOLF": {
      "icao": "KOLF",
      "iata": "OLF",
      "name": "L M Clayton Airport",
      "city": "Wolf Point",
      "state": "Montana",
      "country": "US",
      "elevation": 1986,
      "lat": 48.0945014954,
      "lon": -105.5749969482,
      "tz": "America\/Denver"
  },
  "KOLG": {
      "icao": "KOLG",
      "iata": "",
      "name": "Solon Springs Municipal Airport",
      "city": "Solon Springs",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1102,
      "lat": 46.3148002625,
      "lon": -91.8163986206,
      "tz": "America\/Chicago"
  },
  "KOLM": {
      "icao": "KOLM",
      "iata": "OLM",
      "name": "Olympia Regional Airport",
      "city": "Olympia",
      "state": "Washington",
      "country": "US",
      "elevation": 209,
      "lat": 46.9693985,
      "lon": -122.9029999,
      "tz": "America\/Los_Angeles"
  },
  "KOLS": {
      "icao": "KOLS",
      "iata": "OLS",
      "name": "Nogales International Airport",
      "city": "Nogales",
      "state": "Arizona",
      "country": "US",
      "elevation": 3955,
      "lat": 31.4176998138,
      "lon": -110.8479995728,
      "tz": "America\/Phoenix"
  },
  "KOLU": {
      "icao": "KOLU",
      "iata": "OLU",
      "name": "Columbus Municipal Airport",
      "city": "Columbus",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1447,
      "lat": 41.44800186,
      "lon": -97.34259796,
      "tz": "America\/Chicago"
  },
  "KOLV": {
      "icao": "KOLV",
      "iata": "OLV",
      "name": "Olive Branch Airport",
      "city": "Olive Branch",
      "state": "Mississippi",
      "country": "US",
      "elevation": 402,
      "lat": 34.9786987305,
      "lon": -89.7869033813,
      "tz": "America\/Chicago"
  },
  "KOLY": {
      "icao": "KOLY",
      "iata": "OLY",
      "name": "Olney Noble Airport",
      "city": "Olney-Noble",
      "state": "Illinois",
      "country": "US",
      "elevation": 482,
      "lat": 38.7218017578,
      "lon": -88.176399231,
      "tz": "America\/Chicago"
  },
  "KOLZ": {
      "icao": "KOLZ",
      "iata": "",
      "name": "Oelwein Municipal Airport",
      "city": "Oelwein",
      "state": "Iowa",
      "country": "US",
      "elevation": 1076,
      "lat": 42.6808013916,
      "lon": -91.9745025635,
      "tz": "America\/Chicago"
  },
  "KOMA": {
      "icao": "KOMA",
      "iata": "OMA",
      "name": "Eppley Airfield",
      "city": "Omaha",
      "state": "Nebraska",
      "country": "US",
      "elevation": 984,
      "lat": 41.3031997681,
      "lon": -95.8940963745,
      "tz": "America\/Chicago"
  },
  "KOMH": {
      "icao": "KOMH",
      "iata": "",
      "name": "Orange County Airport",
      "city": "Orange",
      "state": "Virginia",
      "country": "US",
      "elevation": 465,
      "lat": 38.2472000122,
      "lon": -78.0456008911,
      "tz": "America\/New_York"
  },
  "KOMK": {
      "icao": "KOMK",
      "iata": "OMK",
      "name": "Omak Airport",
      "city": "Omak",
      "state": "Washington",
      "country": "US",
      "elevation": 1305,
      "lat": 48.4644012451,
      "lon": -119.5179977417,
      "tz": "America\/Los_Angeles"
  },
  "KOMN": {
      "icao": "KOMN",
      "iata": "",
      "name": "Ormond Beach Municipal Airport",
      "city": "Ormond Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 29,
      "lat": 29.3006000519,
      "lon": -81.1136016846,
      "tz": "America\/New_York"
  },
  "KONA": {
      "icao": "KONA",
      "iata": "ONA",
      "name": "Winona Municipal-Max Conrad Field",
      "city": "Winona",
      "state": "Minnesota",
      "country": "US",
      "elevation": 656,
      "lat": 44.07720184,
      "lon": -91.70829773,
      "tz": "America\/Chicago"
  },
  "KONL": {
      "icao": "KONL",
      "iata": "ONL",
      "name": "The O'Neill Municipal John L Baker Field",
      "city": "O'Neill",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2031,
      "lat": 42.46989822,
      "lon": -98.68810272,
      "tz": "America\/Chicago"
  },
  "KONM": {
      "icao": "KONM",
      "iata": "ONM",
      "name": "Socorro Municipal Airport",
      "city": "Socorro",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4875,
      "lat": 34.0224990845,
      "lon": -106.9029998779,
      "tz": "America\/Denver"
  },
  "KONO": {
      "icao": "KONO",
      "iata": "ONO",
      "name": "Ontario Municipal Airport",
      "city": "Ontario",
      "state": "Oregon",
      "country": "US",
      "elevation": 2193,
      "lat": 44.0205001831,
      "lon": -117.013999939,
      "tz": "America\/Boise"
  },
  "KONP": {
      "icao": "KONP",
      "iata": "ONP",
      "name": "Newport Municipal Airport",
      "city": "Newport",
      "state": "Oregon",
      "country": "US",
      "elevation": 160,
      "lat": 44.5803985596,
      "lon": -124.0579986572,
      "tz": "America\/Los_Angeles"
  },
  "KONT": {
      "icao": "KONT",
      "iata": "ONT",
      "name": "Ontario International Airport",
      "city": "Ontario",
      "state": "California",
      "country": "US",
      "elevation": 944,
      "lat": 34.0559997559,
      "lon": -117.6009979248,
      "tz": "America\/Los_Angeles"
  },
  "KONX": {
      "icao": "KONX",
      "iata": "",
      "name": "Currituck County Regional Airport",
      "city": "Currituck",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 18,
      "lat": 36.39889908,
      "lon": -76.01629639,
      "tz": "America\/New_York"
  },
  "KONY": {
      "icao": "KONY",
      "iata": "ONY",
      "name": "Olney Municipal Airport",
      "city": "Olney",
      "state": "Texas",
      "country": "US",
      "elevation": 1275,
      "lat": 33.3508987427,
      "lon": -98.8191986084,
      "tz": "America\/Chicago"
  },
  "KONZ": {
      "icao": "KONZ",
      "iata": "",
      "name": "Grosse Ile Municipal Airport",
      "city": "Detroit\/Grosse Ile",
      "state": "Michigan",
      "country": "US",
      "elevation": 591,
      "lat": 42.0990982056,
      "lon": -83.1614990234,
      "tz": "America\/Detroit"
  },
  "KOOA": {
      "icao": "KOOA",
      "iata": "OOA",
      "name": "Oskaloosa Municipal Airport",
      "city": "Oskaloosa",
      "state": "Iowa",
      "country": "US",
      "elevation": 841,
      "lat": 41.2262001,
      "lon": -92.49389648,
      "tz": "America\/Chicago"
  },
  "KOPF": {
      "icao": "KOPF",
      "iata": "OPF",
      "name": "Opa-locka Executive Airport",
      "city": "Miami",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 25.90699959,
      "lon": -80.27839661,
      "tz": "America\/New_York"
  },
  "KOPL": {
      "icao": "KOPL",
      "iata": "OPL",
      "name": "St Landry Parish Ahart Field",
      "city": "Opelousas",
      "state": "Louisiana",
      "country": "US",
      "elevation": 75,
      "lat": 30.5583992004,
      "lon": -92.0994033813,
      "tz": "America\/Chicago"
  },
  "KOPN": {
      "icao": "KOPN",
      "iata": "",
      "name": "Thomaston Upson County Airport",
      "city": "Thomaston",
      "state": "Georgia",
      "country": "US",
      "elevation": 798,
      "lat": 32.95460129,
      "lon": -84.26319885,
      "tz": "America\/New_York"
  },
  "KOQN": {
      "icao": "KOQN",
      "iata": "",
      "name": "Brandywine Airport",
      "city": "West Chester",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 466,
      "lat": 39.9901008606,
      "lon": -75.5819015503,
      "tz": "America\/New_York"
  },
  "KOQU": {
      "icao": "KOQU",
      "iata": "NCO",
      "name": "Quonset State Airport",
      "city": "North Kingstown",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 18,
      "lat": 41.5970993042,
      "lon": -71.4121017456,
      "tz": "America\/New_York"
  },
  "KOQW": {
      "icao": "KOQW",
      "iata": "",
      "name": "Maquoketa Municipal Airport",
      "city": "Maquoketa",
      "state": "Iowa",
      "country": "US",
      "elevation": 769,
      "lat": 42.0500984192,
      "lon": -90.7388000488,
      "tz": "America\/Chicago"
  },
  "KORB": {
      "icao": "KORB",
      "iata": "",
      "name": "Orr Regional Airport",
      "city": "Orr",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1311,
      "lat": 48.0158996582,
      "lon": -92.8561019897,
      "tz": "America\/Chicago"
  },
  "KORC": {
      "icao": "KORC",
      "iata": "",
      "name": "Orange City Municipal Airport",
      "city": "Orange City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1414,
      "lat": 42.9902992249,
      "lon": -96.0627975464,
      "tz": "America\/Chicago"
  },
  "KORD": {
      "icao": "KORD",
      "iata": "ORD",
      "name": "Chicago O'Hare International Airport",
      "city": "Chicago",
      "state": "Illinois",
      "country": "US",
      "elevation": 672,
      "lat": 41.97859955,
      "lon": -87.90480042,
      "tz": "America\/Chicago"
  },
  "KORE": {
      "icao": "KORE",
      "iata": "",
      "name": "Orange Municipal Airport",
      "city": "Orange",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 555,
      "lat": 42.570098877,
      "lon": -72.2885971069,
      "tz": "America\/New_York"
  },
  "KORF": {
      "icao": "KORF",
      "iata": "ORF",
      "name": "Norfolk International Airport",
      "city": "Norfolk",
      "state": "Virginia",
      "country": "US",
      "elevation": 26,
      "lat": 36.8945999146,
      "lon": -76.2012023926,
      "tz": "America\/New_York"
  },
  "KORG": {
      "icao": "KORG",
      "iata": "",
      "name": "Orange County Airport",
      "city": "Orange",
      "state": "Texas",
      "country": "US",
      "elevation": 13,
      "lat": 30.0692005157,
      "lon": -93.8009033203,
      "tz": "America\/Chicago"
  },
  "KORH": {
      "icao": "KORH",
      "iata": "ORH",
      "name": "Worcester Regional Airport",
      "city": "Worcester",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 1009,
      "lat": 42.2672996521,
      "lon": -71.8757019043,
      "tz": "America\/New_York"
  },
  "KORK": {
      "icao": "KORK",
      "iata": "",
      "name": "North Little Rock Municipal Airport",
      "city": "North Little Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 545,
      "lat": 34.83309937,
      "lon": -92.25409698,
      "tz": "America\/Chicago"
  },
  "KORL": {
      "icao": "KORL",
      "iata": "ORL",
      "name": "Orlando Executive Airport",
      "city": "Orlando",
      "state": "Florida",
      "country": "US",
      "elevation": 113,
      "lat": 28.5454998016,
      "lon": -81.332901001,
      "tz": "America\/New_York"
  },
  "KORS": {
      "icao": "KORS",
      "iata": "ESD",
      "name": "Orcas Island Airport",
      "city": "Eastsound",
      "state": "Washington",
      "country": "US",
      "elevation": 31,
      "lat": 48.7081985474,
      "lon": -122.910003662,
      "tz": "America\/Los_Angeles"
  },
  "KOSA": {
      "icao": "KOSA",
      "iata": "MPS",
      "name": "Mount Pleasant Regional Airport",
      "city": "Mount Pleasant",
      "state": "Texas",
      "country": "US",
      "elevation": 364,
      "lat": 33.09550095,
      "lon": -94.96150208,
      "tz": "America\/Chicago"
  },
  "KOSC": {
      "icao": "KOSC",
      "iata": "OSC",
      "name": "Oscoda Wurtsmith Airport",
      "city": "Oscoda",
      "state": "Michigan",
      "country": "US",
      "elevation": 633,
      "lat": 44.45159912,
      "lon": -83.39409637,
      "tz": "America\/Detroit"
  },
  "KOSH": {
      "icao": "KOSH",
      "iata": "OSH",
      "name": "Wittman Regional Airport",
      "city": "Oshkosh",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 808,
      "lat": 43.9844017029,
      "lon": -88.5569992065,
      "tz": "America\/Chicago"
  },
  "KOSU": {
      "icao": "KOSU",
      "iata": "OSU",
      "name": "Ohio State University Airport",
      "city": "Columbus",
      "state": "Ohio",
      "country": "US",
      "elevation": 905,
      "lat": 40.0797996521,
      "lon": -83.0729980469,
      "tz": "America\/New_York"
  },
  "KOSX": {
      "icao": "KOSX",
      "iata": "OSX",
      "name": "Kosciusko Attala County Airport",
      "city": "Kosciusko",
      "state": "Mississippi",
      "country": "US",
      "elevation": 480,
      "lat": 33.0903015137,
      "lon": -89.5419998169,
      "tz": "America\/Chicago"
  },
  "KOTG": {
      "icao": "KOTG",
      "iata": "OTG",
      "name": "Worthington Municipal Airport",
      "city": "Worthington",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1574,
      "lat": 43.6551017761,
      "lon": -95.5792007446,
      "tz": "America\/Chicago"
  },
  "KOTH": {
      "icao": "KOTH",
      "iata": "OTH",
      "name": "Southwest Oregon Regional Airport",
      "city": "North Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 17,
      "lat": 43.417098999,
      "lon": -124.2460021973,
      "tz": "America\/Los_Angeles"
  },
  "KOTM": {
      "icao": "KOTM",
      "iata": "OTM",
      "name": "Ottumwa Regional Airport",
      "city": "Ottumwa",
      "state": "Iowa",
      "country": "US",
      "elevation": 845,
      "lat": 41.10660172,
      "lon": -92.44789886,
      "tz": "America\/Chicago"
  },
  "KOUN": {
      "icao": "KOUN",
      "iata": "OUN",
      "name": "University of Oklahoma Westheimer Airport",
      "city": "Norman",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1182,
      "lat": 35.2456016541,
      "lon": -97.4720993042,
      "tz": "America\/Chicago"
  },
  "KOVE": {
      "icao": "KOVE",
      "iata": "OVE",
      "name": "Oroville Municipal Airport",
      "city": "Oroville",
      "state": "California",
      "country": "US",
      "elevation": 192,
      "lat": 39.4878005981,
      "lon": -121.6220016479,
      "tz": "America\/Los_Angeles"
  },
  "KOVL": {
      "icao": "KOVL",
      "iata": "",
      "name": "Olivia Regional Airport",
      "city": "Olivia",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1076,
      "lat": 44.7785987854,
      "lon": -95.0327987671,
      "tz": "America\/Chicago"
  },
  "KOVO": {
      "icao": "KOVO",
      "iata": "",
      "name": "North Vernon Airport",
      "city": "North Vernon",
      "state": "Indiana",
      "country": "US",
      "elevation": 757,
      "lat": 39.04560089,
      "lon": -85.6053009,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KOVS": {
      "icao": "KOVS",
      "iata": "",
      "name": "Boscobel Airport",
      "city": "Boscobel",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 673,
      "lat": 43.16019821,
      "lon": -90.67549896,
      "tz": "America\/Chicago"
  },
  "KOWA": {
      "icao": "KOWA",
      "iata": "OWA",
      "name": "Owatonna Degner Regional Airport",
      "city": "Owatonna",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1145,
      "lat": 44.12340164,
      "lon": -93.26059723,
      "tz": "America\/Chicago"
  },
  "KOWB": {
      "icao": "KOWB",
      "iata": "OWB",
      "name": "Owensboro Daviess County Airport",
      "city": "Owensboro",
      "state": "Kentucky",
      "country": "US",
      "elevation": 407,
      "lat": 37.74010086,
      "lon": -87.16680145,
      "tz": "America\/Chicago"
  },
  "KOWD": {
      "icao": "KOWD",
      "iata": "OWD",
      "name": "Norwood Memorial Airport",
      "city": "Norwood",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 49,
      "lat": 42.1904983521,
      "lon": -71.1728973389,
      "tz": "America\/New_York"
  },
  "KOWI": {
      "icao": "KOWI",
      "iata": "",
      "name": "Ottawa Municipal Airport",
      "city": "Ottawa",
      "state": "Kansas",
      "country": "US",
      "elevation": 966,
      "lat": 38.5387001038,
      "lon": -95.2529983521,
      "tz": "America\/Chicago"
  },
  "KOWK": {
      "icao": "KOWK",
      "iata": "OWK",
      "name": "Central Maine Arpt of Norridgewock Airport",
      "city": "Norridgewock",
      "state": "Maine",
      "country": "US",
      "elevation": 270,
      "lat": 44.71549988,
      "lon": -69.86650085,
      "tz": "America\/New_York"
  },
  "KOWP": {
      "icao": "KOWP",
      "iata": "",
      "name": "William R. Pogue Municipal Airport",
      "city": "Sand Springs",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 892,
      "lat": 36.1753006,
      "lon": -96.15180206,
      "tz": "America\/Chicago"
  },
  "KOWX": {
      "icao": "KOWX",
      "iata": "",
      "name": "Putnam County Airport",
      "city": "Ottawa",
      "state": "Ohio",
      "country": "US",
      "elevation": 764,
      "lat": 41.03559875,
      "lon": -83.98200226,
      "tz": "America\/New_York"
  },
  "KOXB": {
      "icao": "KOXB",
      "iata": "OCE",
      "name": "Ocean City Municipal Airport",
      "city": "Ocean City",
      "state": "Maryland",
      "country": "US",
      "elevation": 11,
      "lat": 38.3103981018,
      "lon": -75.1240005493,
      "tz": "America\/New_York"
  },
  "KOXC": {
      "icao": "KOXC",
      "iata": "OXC",
      "name": "Waterbury Oxford Airport",
      "city": "Oxford",
      "state": "Connecticut",
      "country": "US",
      "elevation": 726,
      "lat": 41.4785995483,
      "lon": -73.1352005005,
      "tz": "America\/New_York"
  },
  "KOXD": {
      "icao": "KOXD",
      "iata": "OXD",
      "name": "Miami University Airport",
      "city": "Oxford",
      "state": "Ohio",
      "country": "US",
      "elevation": 1041,
      "lat": 39.50230026,
      "lon": -84.78440094,
      "tz": "America\/New_York"
  },
  "KOXI": {
      "icao": "KOXI",
      "iata": "",
      "name": "Starke County Airport",
      "city": "Knox",
      "state": "Indiana",
      "country": "US",
      "elevation": 685,
      "lat": 41.3302002,
      "lon": -86.66470337,
      "tz": "America\/Indiana\/Knox"
  },
  "KOXR": {
      "icao": "KOXR",
      "iata": "OXR",
      "name": "Oxnard Airport",
      "city": "Oxnard",
      "state": "California",
      "country": "US",
      "elevation": 45,
      "lat": 34.2008018494,
      "lon": -119.2070007324,
      "tz": "America\/Los_Angeles"
  },
  "KOXV": {
      "icao": "KOXV",
      "iata": "",
      "name": "Knoxville Municipal Airport",
      "city": "Knoxville",
      "state": "Iowa",
      "country": "US",
      "elevation": 928,
      "lat": 41.2989006042,
      "lon": -93.1138000488,
      "tz": "America\/Chicago"
  },
  "KOYM": {
      "icao": "KOYM",
      "iata": "STQ",
      "name": "St Marys Municipal Airport",
      "city": "St Marys",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1934,
      "lat": 41.4124984741,
      "lon": -78.5026016235,
      "tz": "America\/New_York"
  },
  "KOZA": {
      "icao": "KOZA",
      "iata": "OZA",
      "name": "Ozona Municipal Airport",
      "city": "Ozona",
      "state": "Texas",
      "country": "US",
      "elevation": 2381,
      "lat": 30.7353000641,
      "lon": -101.2030029297,
      "tz": "America\/Chicago"
  },
  "KOZR": {
      "icao": "KOZR",
      "iata": "OZR",
      "name": "Cairns AAF (Fort Rucker) Air Field",
      "city": "Fort Rucker\/Ozark",
      "state": "Alabama",
      "country": "US",
      "elevation": 301,
      "lat": 31.27569962,
      "lon": -85.71340179,
      "tz": "America\/Chicago"
  },
  "KOZW": {
      "icao": "KOZW",
      "iata": "",
      "name": "Livingston County Spencer J. Hardy Airport",
      "city": "Howell",
      "state": "Michigan",
      "country": "US",
      "elevation": 962,
      "lat": 42.62919998,
      "lon": -83.98210144,
      "tz": "America\/Detroit"
  },
  "KP01": {
      "icao": "KP01",
      "iata": "",
      "name": "Eric Marcus Municipal Airport",
      "city": "Ajo",
      "state": "Arizona",
      "country": "US",
      "elevation": 1458,
      "lat": 32.4500999451,
      "lon": -112.8669967651,
      "tz": "America\/Phoenix"
  },
  "KP03": {
      "icao": "KP03",
      "iata": "",
      "name": "Cochise College Airport",
      "city": "Douglas",
      "state": "Arizona",
      "country": "US",
      "elevation": 4124,
      "lat": 31.371099472,
      "lon": -109.6900024414,
      "tz": "America\/Hermosillo"
  },
  "KP04": {
      "icao": "KP04",
      "iata": "BSQ",
      "name": "Bisbee Municipal Airport",
      "city": "Bisbee",
      "state": "Arizona",
      "country": "US",
      "elevation": 4780,
      "lat": 31.3640003204,
      "lon": -109.883003235,
      "tz": "America\/Hermosillo"
  },
  "KP08": {
      "icao": "KP08",
      "iata": "",
      "name": "Coolidge Municipal Airport",
      "city": "Coolidge",
      "state": "Arizona",
      "country": "US",
      "elevation": 1574,
      "lat": 32.9359016418,
      "lon": -111.4270019531,
      "tz": "America\/Phoenix"
  },
  "KP10": {
      "icao": "KP10",
      "iata": "",
      "name": "Polacca Airport",
      "city": "Polacca",
      "state": "Arizona",
      "country": "US",
      "elevation": 5573,
      "lat": 35.7916984558,
      "lon": -110.422996521,
      "tz": "America\/Phoenix"
  },
  "KP13": {
      "icao": "KP13",
      "iata": "",
      "name": "San Carlos Apache Airport",
      "city": "Globe",
      "state": "Arizona",
      "country": "US",
      "elevation": 3261,
      "lat": 33.353099823,
      "lon": -110.6669998169,
      "tz": "America\/Phoenix"
  },
  "KP14": {
      "icao": "KP14",
      "iata": "HBK",
      "name": "Holbrook Municipal Airport",
      "city": "Holbrook",
      "state": "Arizona",
      "country": "US",
      "elevation": 5262,
      "lat": 34.940700531,
      "lon": -110.138000488,
      "tz": "America\/Phoenix"
  },
  "KP19": {
      "icao": "KP19",
      "iata": "",
      "name": "Stellar Airpark",
      "city": "Chandler",
      "state": "Arizona",
      "country": "US",
      "elevation": 1177,
      "lat": 33.2989006042,
      "lon": -111.9150009155,
      "tz": "America\/Phoenix"
  },
  "KP20": {
      "icao": "KP20",
      "iata": "",
      "name": "Avi Suquilla Airport",
      "city": "Parker",
      "state": "Arizona",
      "country": "US",
      "elevation": 452,
      "lat": 34.1506004333,
      "lon": -114.2710037231,
      "tz": "America\/Los_Angeles"
  },
  "KP23": {
      "icao": "KP23",
      "iata": "",
      "name": "Seligman Airport",
      "city": "Seligman",
      "state": "Arizona",
      "country": "US",
      "elevation": 5235,
      "lat": 35.3349990845,
      "lon": -112.8870010376,
      "tz": "America\/Phoenix"
  },
  "KP29": {
      "icao": "KP29",
      "iata": "",
      "name": "Tombstone Municipal Airport",
      "city": "Tombstone",
      "state": "Arizona",
      "country": "US",
      "elevation": 4743,
      "lat": 31.6709003448,
      "lon": -110.0230026245,
      "tz": "America\/Phoenix"
  },
  "KP33": {
      "icao": "KP33",
      "iata": "CWX",
      "name": "Cochise County Airport",
      "city": "Willcox",
      "state": "Arizona",
      "country": "US",
      "elevation": 4187,
      "lat": 32.24539948,
      "lon": -109.8949966,
      "tz": "America\/Phoenix"
  },
  "KP52": {
      "icao": "KP52",
      "iata": "",
      "name": "Cottonwood Airport",
      "city": "Cottonwood",
      "state": "Arizona",
      "country": "US",
      "elevation": 3550,
      "lat": 34.7299995422,
      "lon": -112.0350036621,
      "tz": "America\/Phoenix"
  },
  "KPAE": {
      "icao": "KPAE",
      "iata": "PAE",
      "name": "Snohomish County (Paine Field) Airport",
      "city": "Everett",
      "state": "Washington",
      "country": "US",
      "elevation": 606,
      "lat": 47.90629959,
      "lon": -122.2819977,
      "tz": "America\/Los_Angeles"
  },
  "KPAH": {
      "icao": "KPAH",
      "iata": "PAH",
      "name": "Barkley Regional Airport",
      "city": "Paducah",
      "state": "Kentucky",
      "country": "US",
      "elevation": 410,
      "lat": 37.060798645,
      "lon": -88.7738037109,
      "tz": "America\/Chicago"
  },
  "KPAM": {
      "icao": "KPAM",
      "iata": "PAM",
      "name": "Tyndall Air Force Base",
      "city": "Panama City",
      "state": "Florida",
      "country": "US",
      "elevation": 17,
      "lat": 30.0695991516,
      "lon": -85.5754013062,
      "tz": "America\/Chicago"
  },
  "KPAN": {
      "icao": "KPAN",
      "iata": "PJB",
      "name": "Payson Airport",
      "city": "Payson",
      "state": "Arizona",
      "country": "US",
      "elevation": 5157,
      "lat": 34.2568016052,
      "lon": -111.3389968872,
      "tz": "America\/Phoenix"
  },
  "KPAO": {
      "icao": "KPAO",
      "iata": "PAO",
      "name": "Palo Alto Airport of Santa Clara County",
      "city": "Palo Alto",
      "state": "California",
      "country": "US",
      "elevation": 4,
      "lat": 37.461101532,
      "lon": -122.1149978638,
      "tz": "America\/Los_Angeles"
  },
  "KPBF": {
      "icao": "KPBF",
      "iata": "PBF",
      "name": "Grider Field",
      "city": "Pine Bluff",
      "state": "Arkansas",
      "country": "US",
      "elevation": 206,
      "lat": 34.1730995178,
      "lon": -91.9356002808,
      "tz": "America\/Chicago"
  },
  "KPBG": {
      "icao": "KPBG",
      "iata": "PBG",
      "name": "Plattsburgh International Airport",
      "city": "Plattsburgh",
      "state": "New-York",
      "country": "US",
      "elevation": 234,
      "lat": 44.6509017944,
      "lon": -73.4681015015,
      "tz": "America\/New_York"
  },
  "KPBH": {
      "icao": "KPBH",
      "iata": "",
      "name": "Price County Airport",
      "city": "Phillips",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1497,
      "lat": 45.7089996338,
      "lon": -90.4024963379,
      "tz": "America\/Chicago"
  },
  "KPBI": {
      "icao": "KPBI",
      "iata": "PBI",
      "name": "Palm Beach International Airport",
      "city": "West Palm Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 19,
      "lat": 26.6832008362,
      "lon": -80.0955963135,
      "tz": "America\/New_York"
  },
  "KPBX": {
      "icao": "KPBX",
      "iata": "PVL",
      "name": "Pike County-Hatcher Field",
      "city": "Pikeville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1473,
      "lat": 37.5617981,
      "lon": -82.56639862,
      "tz": "America\/New_York"
  },
  "KPCD": {
      "icao": "KPCD",
      "iata": "",
      "name": "Perryville Regional Airport",
      "city": "Perryville, Missouri",
      "state": "Missouri",
      "country": "US",
      "elevation": 372,
      "lat": 37.8686667,
      "lon": -89.8621389,
      "tz": "America\/Chicago"
  },
  "KPCM": {
      "icao": "KPCM",
      "iata": "",
      "name": "Plant City Municipal Airport",
      "city": "Plant City",
      "state": "Florida",
      "country": "US",
      "elevation": 153,
      "lat": 28.00020027,
      "lon": -82.16419983,
      "tz": "America\/New_York"
  },
  "KPCW": {
      "icao": "KPCW",
      "iata": "",
      "name": "Carl R Keller Field",
      "city": "Port Clinton",
      "state": "Ohio",
      "country": "US",
      "elevation": 590,
      "lat": 41.5163002014,
      "lon": -82.8686981201,
      "tz": "America\/New_York"
  },
  "KPCZ": {
      "icao": "KPCZ",
      "iata": "",
      "name": "Waupaca Municipal Airport",
      "city": "Waupaca",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 840,
      "lat": 44.33330154,
      "lon": -89.01979828,
      "tz": "America\/Chicago"
  },
  "KPDC": {
      "icao": "KPDC",
      "iata": "PCD",
      "name": "Prairie Du Chien Municipal Airport",
      "city": "Prairie Du Chien",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 661,
      "lat": 43.0192985535,
      "lon": -91.1237030029,
      "tz": "America\/Chicago"
  },
  "KPDK": {
      "icao": "KPDK",
      "iata": "PDK",
      "name": "DeKalb Peachtree Airport",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 1003,
      "lat": 33.8755989075,
      "lon": -84.3020019531,
      "tz": "America\/New_York"
  },
  "KPDT": {
      "icao": "KPDT",
      "iata": "PDT",
      "name": "Eastern Oregon Regional At Pendleton Airport",
      "city": "Pendleton",
      "state": "Oregon",
      "country": "US",
      "elevation": 1497,
      "lat": 45.695098877,
      "lon": -118.841003418,
      "tz": "America\/Los_Angeles"
  },
  "KPDX": {
      "icao": "KPDX",
      "iata": "PDX",
      "name": "Portland International Airport",
      "city": "Portland",
      "state": "Oregon",
      "country": "US",
      "elevation": 31,
      "lat": 45.58869934,
      "lon": -122.5979996,
      "tz": "America\/Los_Angeles"
  },
  "KPEA": {
      "icao": "KPEA",
      "iata": "",
      "name": "Pella Municipal Airport",
      "city": "Pella",
      "state": "Iowa",
      "country": "US",
      "elevation": 885,
      "lat": 41.400100708,
      "lon": -92.9458999634,
      "tz": "America\/Chicago"
  },
  "KPEO": {
      "icao": "KPEO",
      "iata": "",
      "name": "Penn Yan Airport",
      "city": "Penn Yan",
      "state": "New-York",
      "country": "US",
      "elevation": 990,
      "lat": 42.6371002197,
      "lon": -77.0529022217,
      "tz": "America\/New_York"
  },
  "KPEQ": {
      "icao": "KPEQ",
      "iata": "PEQ",
      "name": "Pecos Municipal Airport",
      "city": "Pecos",
      "state": "Texas",
      "country": "US",
      "elevation": 2613,
      "lat": 31.3824005127,
      "lon": -103.5110015869,
      "tz": "America\/Chicago"
  },
  "KPEX": {
      "icao": "KPEX",
      "iata": "",
      "name": "Paynesville Municipal Airport",
      "city": "Paynesville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1180,
      "lat": 45.3722991943,
      "lon": -94.74659729,
      "tz": "America\/Chicago"
  },
  "KPEZ": {
      "icao": "KPEZ",
      "iata": "",
      "name": "Pleasanton Municipal Airport",
      "city": "Pleasanton",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 28.9542007446,
      "lon": -98.5199966431,
      "tz": "America\/Chicago"
  },
  "KPFC": {
      "icao": "KPFC",
      "iata": "PFC",
      "name": "Pacific City State Airport",
      "city": "Pacific City",
      "state": "Oregon",
      "country": "US",
      "elevation": 5,
      "lat": 45.199798584,
      "lon": -123.961997986,
      "tz": "America\/Los_Angeles"
  },
  "KPFN": {
      "icao": "KPFN",
      "iata": "PFN",
      "name": "Panama City-Bay Co International Airport",
      "city": "Panama City",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 30.2120990753,
      "lon": -85.682800293,
      "tz": "America\/Chicago"
  },
  "KPGA": {
      "icao": "KPGA",
      "iata": "PGA",
      "name": "Page Municipal Airport",
      "city": "Page",
      "state": "Arizona",
      "country": "US",
      "elevation": 4316,
      "lat": 36.92610168,
      "lon": -111.447998,
      "tz": "America\/Phoenix"
  },
  "KPGD": {
      "icao": "KPGD",
      "iata": "PGD",
      "name": "Charlotte County Airport",
      "city": "Punta Gorda",
      "state": "Florida",
      "country": "US",
      "elevation": 26,
      "lat": 26.92020035,
      "lon": -81.9905014,
      "tz": "America\/New_York"
  },
  "KPGR": {
      "icao": "KPGR",
      "iata": "PGR",
      "name": "Kirk Field",
      "city": "Paragould",
      "state": "Arkansas",
      "country": "US",
      "elevation": 290,
      "lat": 36.06290054,
      "lon": -90.50779724,
      "tz": "America\/Chicago"
  },
  "KPGV": {
      "icao": "KPGV",
      "iata": "PGV",
      "name": "Pitt Greenville Airport",
      "city": "Greenville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 26,
      "lat": 35.6352005,
      "lon": -77.38529968,
      "tz": "America\/New_York"
  },
  "KPHD": {
      "icao": "KPHD",
      "iata": "PHD",
      "name": "Harry Clever Field",
      "city": "New Philadelphia",
      "state": "Ohio",
      "country": "US",
      "elevation": 894,
      "lat": 40.4709014893,
      "lon": -81.4197006226,
      "tz": "America\/New_York"
  },
  "KPHF": {
      "icao": "KPHF",
      "iata": "PHF",
      "name": "Newport News Williamsburg International Airport",
      "city": "Newport News",
      "state": "Virginia",
      "country": "US",
      "elevation": 42,
      "lat": 37.13190079,
      "lon": -76.49299622,
      "tz": "America\/New_York"
  },
  "KPHG": {
      "icao": "KPHG",
      "iata": "",
      "name": "Phillipsburg Municipal Airport",
      "city": "Phillipsburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1907,
      "lat": 39.7358016968,
      "lon": -99.3171005249,
      "tz": "America\/Chicago"
  },
  "KPHH": {
      "icao": "KPHH",
      "iata": "ADR",
      "name": "Robert F Swinnie Airport",
      "city": "Andrews",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 26,
      "lat": 33.4516983032,
      "lon": -79.5261993408,
      "tz": "America\/New_York"
  },
  "KPHK": {
      "icao": "KPHK",
      "iata": "PHK",
      "name": "Palm Beach Co Glades Airport",
      "city": "Pahokee",
      "state": "Florida",
      "country": "US",
      "elevation": 16,
      "lat": 26.78499985,
      "lon": -80.69339752,
      "tz": "America\/New_York"
  },
  "KPHL": {
      "icao": "KPHL",
      "iata": "PHL",
      "name": "Philadelphia International Airport",
      "city": "Philadelphia",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 36,
      "lat": 39.8718986511,
      "lon": -75.2410964966,
      "tz": "America\/New_York"
  },
  "KPHN": {
      "icao": "KPHN",
      "iata": "PHN",
      "name": "St Clair County International Airport",
      "city": "Port Huron",
      "state": "Michigan",
      "country": "US",
      "elevation": 650,
      "lat": 42.9109993,
      "lon": -82.52890015,
      "tz": "America\/Detroit"
  },
  "KPHP": {
      "icao": "KPHP",
      "iata": "PHP",
      "name": "Philip Airport",
      "city": "Philip",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2207,
      "lat": 44.0485992432,
      "lon": -101.5989990234,
      "tz": "America\/Denver"
  },
  "KPHT": {
      "icao": "KPHT",
      "iata": "PHT",
      "name": "Henry County Airport",
      "city": "Paris",
      "state": "Tennessee",
      "country": "US",
      "elevation": 580,
      "lat": 36.3381996155,
      "lon": -88.3828964233,
      "tz": "America\/Chicago"
  },
  "KPHX": {
      "icao": "KPHX",
      "iata": "PHX",
      "name": "Phoenix Sky Harbor International Airport",
      "city": "Phoenix",
      "state": "Arizona",
      "country": "US",
      "elevation": 1135,
      "lat": 33.434299469,
      "lon": -112.0120010376,
      "tz": "America\/Phoenix"
  },
  "KPIA": {
      "icao": "KPIA",
      "iata": "PIA",
      "name": "Greater Peoria Regional Airport",
      "city": "Peoria",
      "state": "Illinois",
      "country": "US",
      "elevation": 660,
      "lat": 40.6641998291,
      "lon": -89.6932983398,
      "tz": "America\/Chicago"
  },
  "KPIB": {
      "icao": "KPIB",
      "iata": "PIB",
      "name": "Hattiesburg Laurel Regional Airport",
      "city": "Hattiesburg\/Laurel",
      "state": "Mississippi",
      "country": "US",
      "elevation": 298,
      "lat": 31.4671001434,
      "lon": -89.337097168,
      "tz": "America\/Chicago"
  },
  "KPIE": {
      "icao": "KPIE",
      "iata": "PIE",
      "name": "St Petersburg Clearwater International Airport",
      "city": "St Petersburg-Clearwater",
      "state": "Florida",
      "country": "US",
      "elevation": 11,
      "lat": 27.91020012,
      "lon": -82.68740082,
      "tz": "America\/New_York"
  },
  "KPIH": {
      "icao": "KPIH",
      "iata": "PIH",
      "name": "Pocatello Regional Airport",
      "city": "Pocatello",
      "state": "Idaho",
      "country": "US",
      "elevation": 4452,
      "lat": 42.9098014832,
      "lon": -112.5960006714,
      "tz": "America\/Boise"
  },
  "KPIL": {
      "icao": "KPIL",
      "iata": "",
      "name": "Port Isabel Cameron County Airport",
      "city": "Port Isabel",
      "state": "Texas",
      "country": "US",
      "elevation": 19,
      "lat": 26.1662006378,
      "lon": -97.3459014893,
      "tz": "America\/Chicago"
  },
  "KPIM": {
      "icao": "KPIM",
      "iata": "PIM",
      "name": "Harris County Airport",
      "city": "Pine Mountain",
      "state": "Georgia",
      "country": "US",
      "elevation": 902,
      "lat": 32.8406982422,
      "lon": -84.8824005127,
      "tz": "America\/New_York"
  },
  "KPIR": {
      "icao": "KPIR",
      "iata": "PIR",
      "name": "Pierre Regional Airport",
      "city": "Pierre",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1744,
      "lat": 44.38270187,
      "lon": -100.2860031,
      "tz": "America\/Chicago"
  },
  "KPIT": {
      "icao": "KPIT",
      "iata": "PIT",
      "name": "Pittsburgh International Airport",
      "city": "Pittsburgh",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1203,
      "lat": 40.49150085,
      "lon": -80.23290253,
      "tz": "America\/New_York"
  },
  "KPJC": {
      "icao": "KPJC",
      "iata": "",
      "name": "Zelienople Municipal Airport",
      "city": "Zelienople",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 898,
      "lat": 40.80160141,
      "lon": -80.16069794,
      "tz": "America\/New_York"
  },
  "KPJY": {
      "icao": "KPJY",
      "iata": "",
      "name": "Pinckneyville Du Quoin Airport",
      "city": "Pinckneyville",
      "state": "Illinois",
      "country": "US",
      "elevation": 400,
      "lat": 37.9779014587,
      "lon": -89.360496521,
      "tz": "America\/Chicago"
  },
  "KPKB": {
      "icao": "KPKB",
      "iata": "PKB",
      "name": "Mid Ohio Valley Regional Airport",
      "city": "Parkersburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 858,
      "lat": 39.3451004028,
      "lon": -81.439201355,
      "tz": "America\/New_York"
  },
  "KPKD": {
      "icao": "KPKD",
      "iata": "PKD",
      "name": "Park Rapids Municipal Konshok Field",
      "city": "Park Rapids",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1445,
      "lat": 46.90060043,
      "lon": -95.07309723,
      "tz": "America\/Chicago"
  },
  "KPKF": {
      "icao": "KPKF",
      "iata": "PKF",
      "name": "Park Falls Municipal Airport",
      "city": "Park Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1500,
      "lat": 45.9550018311,
      "lon": -90.4244003296,
      "tz": "America\/Chicago"
  },
  "KPKV": {
      "icao": "KPKV",
      "iata": "",
      "name": "Calhoun County Airport",
      "city": "Port Lavaca",
      "state": "Texas",
      "country": "US",
      "elevation": 32,
      "lat": 28.65399933,
      "lon": -96.6812973,
      "tz": "America\/Chicago"
  },
  "KPLD": {
      "icao": "KPLD",
      "iata": "",
      "name": "Portland Municipal Airport",
      "city": "Portland",
      "state": "Indiana",
      "country": "US",
      "elevation": 925,
      "lat": 40.45080185,
      "lon": -84.99009705,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KPLK": {
      "icao": "KPLK",
      "iata": "PLK",
      "name": "M. Graham Clark-Taney County Airport",
      "city": "Point Lookout",
      "state": "Missouri",
      "country": "US",
      "elevation": 940,
      "lat": 36.62590027,
      "lon": -93.22889709,
      "tz": "America\/Chicago"
  },
  "KPLN": {
      "icao": "KPLN",
      "iata": "PLN",
      "name": "Pellston Regional Airport of Emmet County Airport",
      "city": "Pellston",
      "state": "Michigan",
      "country": "US",
      "elevation": 721,
      "lat": 45.57089996,
      "lon": -84.79669952,
      "tz": "America\/Detroit"
  },
  "KPLR": {
      "icao": "KPLR",
      "iata": "PLR",
      "name": "St Clair County Airport",
      "city": "Pell City",
      "state": "Alabama",
      "country": "US",
      "elevation": 485,
      "lat": 33.5587997437,
      "lon": -86.2490997314,
      "tz": "America\/Chicago"
  },
  "KPLU": {
      "icao": "KPLU",
      "iata": "",
      "name": "Pierce County-Thun Field",
      "city": "Puyallup",
      "state": "Washington",
      "country": "US",
      "elevation": 538,
      "lat": 47.1039009094,
      "lon": -122.2870025635,
      "tz": "America\/Los_Angeles"
  },
  "KPMB": {
      "icao": "KPMB",
      "iata": "PMB",
      "name": "Pembina Municipal Airport",
      "city": "Pembina",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 795,
      "lat": 48.9425010681,
      "lon": -97.2407989502,
      "tz": "America\/Chicago"
  },
  "KPMD": {
      "icao": "KPMD",
      "iata": "PMD",
      "name": "Palmdale Regional\/USAF Plant 42 Airport",
      "city": "Palmdale",
      "state": "California",
      "country": "US",
      "elevation": 2543,
      "lat": 34.62939835,
      "lon": -118.0849991,
      "tz": "America\/Los_Angeles"
  },
  "KPMH": {
      "icao": "KPMH",
      "iata": "PMH",
      "name": "Greater Portsmouth Regional Airport",
      "city": "Portsmouth",
      "state": "Ohio",
      "country": "US",
      "elevation": 663,
      "lat": 38.8404998779,
      "lon": -82.8472976685,
      "tz": "America\/New_York"
  },
  "KPMP": {
      "icao": "KPMP",
      "iata": "PPM",
      "name": "Pompano Beach Airpark",
      "city": "Pompano Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 19,
      "lat": 26.2471008301,
      "lon": -80.1110992432,
      "tz": "America\/New_York"
  },
  "KPMU": {
      "icao": "KPMU",
      "iata": "",
      "name": "Panola County Airport",
      "city": "Batesville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 221,
      "lat": 34.36349869,
      "lon": -89.89260101,
      "tz": "America\/Chicago"
  },
  "KPMV": {
      "icao": "KPMV",
      "iata": "",
      "name": "Plattsmouth Municipal Airport",
      "city": "Plattsmouth",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1204,
      "lat": 40.95019913,
      "lon": -95.91790009,
      "tz": "America\/Chicago"
  },
  "KPMZ": {
      "icao": "KPMZ",
      "iata": "",
      "name": "Plymouth Municipal Airport",
      "city": "Plymouth",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 39,
      "lat": 35.8083992004,
      "lon": -76.7593002319,
      "tz": "America\/New_York"
  },
  "KPNA": {
      "icao": "KPNA",
      "iata": "PWY",
      "name": "Ralph Wenz Field",
      "city": "Pinedale",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7102,
      "lat": 42.79550171,
      "lon": -109.8069992,
      "tz": "America\/Denver"
  },
  "KPNC": {
      "icao": "KPNC",
      "iata": "PNC",
      "name": "Ponca City Regional Airport",
      "city": "Ponca City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1008,
      "lat": 36.73199844,
      "lon": -97.09980011,
      "tz": "America\/Chicago"
  },
  "KPNE": {
      "icao": "KPNE",
      "iata": "PNE",
      "name": "Northeast Philadelphia Airport",
      "city": "Philadelphia",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 120,
      "lat": 40.08190155,
      "lon": -75.01059723,
      "tz": "America\/New_York"
  },
  "KPNM": {
      "icao": "KPNM",
      "iata": "",
      "name": "Princeton Municipal Airport",
      "city": "Princeton",
      "state": "Maine",
      "country": "US",
      "elevation": 980,
      "lat": 45.55989838,
      "lon": -93.60820007,
      "tz": "America\/Chicago"
  },
  "KPNN": {
      "icao": "KPNN",
      "iata": "PNN",
      "name": "Princeton Municipal Airport",
      "city": "Princeton",
      "state": "Maine",
      "country": "US",
      "elevation": 266,
      "lat": 45.2006988525,
      "lon": -67.5643997192,
      "tz": "America\/New_York"
  },
  "KPNS": {
      "icao": "KPNS",
      "iata": "PNS",
      "name": "Pensacola Regional Airport",
      "city": "Pensacola",
      "state": "Florida",
      "country": "US",
      "elevation": 121,
      "lat": 30.473400116,
      "lon": -87.1865997314,
      "tz": "America\/Chicago"
  },
  "KPNT": {
      "icao": "KPNT",
      "iata": "",
      "name": "Pontiac Municipal Airport",
      "city": "Pontiac",
      "state": "Illinois",
      "country": "US",
      "elevation": 666,
      "lat": 40.9244003296,
      "lon": -88.6239013672,
      "tz": "America\/Chicago"
  },
  "KPOB": {
      "icao": "KPOB",
      "iata": "POB",
      "name": "Pope Air Force Base",
      "city": "Fayetteville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 217,
      "lat": 35.1708984375,
      "lon": -79.014503479,
      "tz": "America\/New_York"
  },
  "KPOC": {
      "icao": "KPOC",
      "iata": "POC",
      "name": "Brackett Field",
      "city": "La Verne",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1011,
      "lat": 34.0915985107,
      "lon": -117.7819976807,
      "tz": "America\/Los_Angeles"
  },
  "KPOE": {
      "icao": "KPOE",
      "iata": "POE",
      "name": "Polk Army Air Field",
      "city": "Fort Polk",
      "state": "Louisiana",
      "country": "US",
      "elevation": 330,
      "lat": 31.0447998,
      "lon": -93.1917038,
      "tz": "America\/Chicago"
  },
  "KPOF": {
      "icao": "KPOF",
      "iata": "POF",
      "name": "Poplar Bluff Municipal Airport",
      "city": "Poplar Bluff",
      "state": "Missouri",
      "country": "US",
      "elevation": 331,
      "lat": 36.7738990784,
      "lon": -90.3248977661,
      "tz": "America\/Chicago"
  },
  "KPOH": {
      "icao": "KPOH",
      "iata": "POH",
      "name": "Pocahontas Municipal Airport",
      "city": "Pocahontas",
      "state": "Iowa",
      "country": "US",
      "elevation": 1226,
      "lat": 42.74280167,
      "lon": -94.64730072,
      "tz": "America\/Chicago"
  },
  "KPOU": {
      "icao": "KPOU",
      "iata": "POU",
      "name": "Dutchess County Airport",
      "city": "Poughkeepsie",
      "state": "New-York",
      "country": "US",
      "elevation": 165,
      "lat": 41.6265983582,
      "lon": -73.8842010498,
      "tz": "America\/New_York"
  },
  "KPOY": {
      "icao": "KPOY",
      "iata": "POY",
      "name": "Powell Municipal Airport",
      "city": "Powell",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5092,
      "lat": 44.8671989441,
      "lon": -108.7929992676,
      "tz": "America\/Denver"
  },
  "KPPA": {
      "icao": "KPPA",
      "iata": "PPA",
      "name": "Perry Lefors Field",
      "city": "Pampa",
      "state": "Texas",
      "country": "US",
      "elevation": 3245,
      "lat": 35.6129989624,
      "lon": -100.9960021973,
      "tz": "America\/Chicago"
  },
  "KPPF": {
      "icao": "KPPF",
      "iata": "PPF",
      "name": "Tri City Airport",
      "city": "Parsons",
      "state": "Kansas",
      "country": "US",
      "elevation": 900,
      "lat": 37.32989883,
      "lon": -95.5062027,
      "tz": "America\/Chicago"
  },
  "KPPO": {
      "icao": "KPPO",
      "iata": "LPO",
      "name": "La Porte Municipal Airport",
      "city": "La Porte",
      "state": "Indiana",
      "country": "US",
      "elevation": 812,
      "lat": 41.5724983215,
      "lon": -86.7344970703,
      "tz": "America\/Chicago"
  },
  "KPPQ": {
      "icao": "KPPQ",
      "iata": "",
      "name": "Pittsfield Penstone Municipal Airport",
      "city": "Pittsfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 710,
      "lat": 39.6389007568,
      "lon": -90.7783966064,
      "tz": "America\/Chicago"
  },
  "KPQI": {
      "icao": "KPQI",
      "iata": "PQI",
      "name": "Northern Maine Regional Airport at Presque Isle",
      "city": "Presque Isle",
      "state": "Maine",
      "country": "US",
      "elevation": 534,
      "lat": 46.68899918,
      "lon": -68.0447998,
      "tz": "America\/New_York"
  },
  "KPQL": {
      "icao": "KPQL",
      "iata": "PGL",
      "name": "Trent Lott International Airport",
      "city": "Pascagoula",
      "state": "Mississippi",
      "country": "US",
      "elevation": 17,
      "lat": 30.4627990723,
      "lon": -88.5291976929,
      "tz": "America\/Chicago"
  },
  "KPQN": {
      "icao": "KPQN",
      "iata": "",
      "name": "Pipestone Municipal Airport",
      "city": "Pipestone",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1736,
      "lat": 43.9832992554,
      "lon": -96.3003005981,
      "tz": "America\/Chicago"
  },
  "KPRB": {
      "icao": "KPRB",
      "iata": "PRB",
      "name": "Paso Robles Municipal Airport",
      "city": "Paso Robles",
      "state": "California",
      "country": "US",
      "elevation": 840,
      "lat": 35.67290115,
      "lon": -120.6269989,
      "tz": "America\/Los_Angeles"
  },
  "KPRC": {
      "icao": "KPRC",
      "iata": "PRC",
      "name": "Ernest A. Love Field",
      "city": "Prescott",
      "state": "Arizona",
      "country": "US",
      "elevation": 5045,
      "lat": 34.65449905,
      "lon": -112.4199982,
      "tz": "America\/Phoenix"
  },
  "KPRG": {
      "icao": "KPRG",
      "iata": "",
      "name": "Edgar County Airport",
      "city": "Paris",
      "state": "Illinois",
      "country": "US",
      "elevation": 654,
      "lat": 39.7001991272,
      "lon": -87.6696014404,
      "tz": "America\/Chicago"
  },
  "KPRN": {
      "icao": "KPRN",
      "iata": "",
      "name": "Mac Crenshaw Memorial Airport",
      "city": "Greenville",
      "state": "Alabama",
      "country": "US",
      "elevation": 451,
      "lat": 31.84569931,
      "lon": -86.61070251,
      "tz": "America\/Chicago"
  },
  "KPRO": {
      "icao": "KPRO",
      "iata": "PRO",
      "name": "Perry Municipal Airport",
      "city": "Perry",
      "state": "Iowa",
      "country": "US",
      "elevation": 1013,
      "lat": 41.82799911,
      "lon": -94.15989685,
      "tz": "America\/Chicago"
  },
  "KPRX": {
      "icao": "KPRX",
      "iata": "PRX",
      "name": "Cox Field",
      "city": "Paris",
      "state": "Texas",
      "country": "US",
      "elevation": 547,
      "lat": 33.6366004944,
      "lon": -95.4507980347,
      "tz": "America\/Chicago"
  },
  "KPRZ": {
      "icao": "KPRZ",
      "iata": "",
      "name": "Portales Municipal Airport",
      "city": "Portales",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4078,
      "lat": 34.1455001831,
      "lon": -103.4100036621,
      "tz": "America\/Denver"
  },
  "KPSB": {
      "icao": "KPSB",
      "iata": "PSB",
      "name": "Mid State Airport",
      "city": "Philipsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1948,
      "lat": 40.8843994141,
      "lon": -78.0873031616,
      "tz": "America\/New_York"
  },
  "KPSC": {
      "icao": "KPSC",
      "iata": "PSC",
      "name": "Tri Cities Airport",
      "city": "Pasco",
      "state": "Washington",
      "country": "US",
      "elevation": 410,
      "lat": 46.2647018433,
      "lon": -119.1190032959,
      "tz": "America\/Los_Angeles"
  },
  "KPSF": {
      "icao": "KPSF",
      "iata": "PSF",
      "name": "Pittsfield Municipal Airport",
      "city": "Pittsfield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 1194,
      "lat": 42.4267997742,
      "lon": -73.2929000854,
      "tz": "America\/New_York"
  },
  "KPSK": {
      "icao": "KPSK",
      "iata": "PSK",
      "name": "New River Valley Airport",
      "city": "Dublin",
      "state": "Virginia",
      "country": "US",
      "elevation": 2105,
      "lat": 37.137298584,
      "lon": -80.6784973145,
      "tz": "America\/New_York"
  },
  "KPSM": {
      "icao": "KPSM",
      "iata": "PSM",
      "name": "Portsmouth International at Pease Airport",
      "city": "Portsmouth",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 100,
      "lat": 43.0778999329,
      "lon": -70.8233032227,
      "tz": "America\/New_York"
  },
  "KPSN": {
      "icao": "KPSN",
      "iata": "PSN",
      "name": "Palestine Municipal Airport",
      "city": "Palestine",
      "state": "Texas",
      "country": "US",
      "elevation": 423,
      "lat": 31.7796993256,
      "lon": -95.7062988281,
      "tz": "America\/Chicago"
  },
  "KPSO": {
      "icao": "KPSO",
      "iata": "PGO",
      "name": "Stevens Field",
      "city": "Pagosa Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 7664,
      "lat": 37.28630066,
      "lon": -107.0559998,
      "tz": "America\/Denver"
  },
  "KPSP": {
      "icao": "KPSP",
      "iata": "PSP",
      "name": "Palm Springs International Airport",
      "city": "Palm Springs",
      "state": "California",
      "country": "US",
      "elevation": 477,
      "lat": 33.82970047,
      "lon": -116.5070037842,
      "tz": "America\/Los_Angeles"
  },
  "KPSX": {
      "icao": "KPSX",
      "iata": "PSX",
      "name": "Palacios Municipal Airport",
      "city": "Palacios",
      "state": "Texas",
      "country": "US",
      "elevation": 14,
      "lat": 28.7275009155,
      "lon": -96.2509994507,
      "tz": "America\/Chicago"
  },
  "KPTB": {
      "icao": "KPTB",
      "iata": "PTB",
      "name": "Dinwiddie County Airport",
      "city": "Petersburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 193,
      "lat": 37.1837997437,
      "lon": -77.5074005127,
      "tz": "America\/New_York"
  },
  "KPTD": {
      "icao": "KPTD",
      "iata": "",
      "name": "Potsdam Municipal-Damon field",
      "city": "Potsdam",
      "state": "New-York",
      "country": "US",
      "elevation": 474,
      "lat": 44.67670059,
      "lon": -74.9484024,
      "tz": "America\/New_York"
  },
  "KPTK": {
      "icao": "KPTK",
      "iata": "PTK",
      "name": "Oakland County International Airport",
      "city": "Pontiac",
      "state": "Michigan",
      "country": "US",
      "elevation": 980,
      "lat": 42.6655006409,
      "lon": -83.4200973511,
      "tz": "America\/Detroit"
  },
  "KPTN": {
      "icao": "KPTN",
      "iata": "PTN",
      "name": "Harry P Williams Memorial Airport",
      "city": "Patterson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 9,
      "lat": 29.7094993591,
      "lon": -91.3389968872,
      "tz": "America\/Chicago"
  },
  "KPTS": {
      "icao": "KPTS",
      "iata": "PTS",
      "name": "Atkinson Municipal Airport",
      "city": "Pittsburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 950,
      "lat": 37.4495010376,
      "lon": -94.7311019897,
      "tz": "America\/Chicago"
  },
  "KPTT": {
      "icao": "KPTT",
      "iata": "PTT",
      "name": "Pratt Industrial Airport",
      "city": "Pratt",
      "state": "Kansas",
      "country": "US",
      "elevation": 1953,
      "lat": 37.70159912,
      "lon": -98.74690247,
      "tz": "America\/Chicago"
  },
  "KPTV": {
      "icao": "KPTV",
      "iata": "PTV",
      "name": "Porterville Municipal Airport",
      "city": "Porterville",
      "state": "California",
      "country": "US",
      "elevation": 442,
      "lat": 36.0295982361,
      "lon": -119.06300354,
      "tz": "America\/Los_Angeles"
  },
  "KPTW": {
      "icao": "KPTW",
      "iata": "PTW",
      "name": "Pottstown Limerick Airport",
      "city": "Pottstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 309,
      "lat": 40.2396011353,
      "lon": -75.5567016602,
      "tz": "America\/New_York"
  },
  "KPUB": {
      "icao": "KPUB",
      "iata": "PUB",
      "name": "Pueblo Memorial Airport",
      "city": "Pueblo",
      "state": "Colorado",
      "country": "US",
      "elevation": 4726,
      "lat": 38.289100647,
      "lon": -104.4970016479,
      "tz": "America\/Denver"
  },
  "KPUC": {
      "icao": "KPUC",
      "iata": "PUC",
      "name": "Carbon County Regional\/Buck Davis Field",
      "city": "Price",
      "state": "Utah",
      "country": "US",
      "elevation": 5957,
      "lat": 39.61389923,
      "lon": -110.7509995,
      "tz": "America\/Denver"
  },
  "KPUJ": {
      "icao": "KPUJ",
      "iata": "",
      "name": "Paulding County Regional Airport",
      "city": "Dallas",
      "state": "Georgia",
      "country": "US",
      "elevation": 1289,
      "lat": 33.913306439,
      "lon": -84.9420833588,
      "tz": "America\/New_York"
  },
  "KPUW": {
      "icao": "KPUW",
      "iata": "PUW",
      "name": "Pullman Moscow Regional Airport",
      "city": "Pullman\/Moscow",
      "state": "Washington",
      "country": "US",
      "elevation": 2556,
      "lat": 46.7439002991,
      "lon": -117.1100006104,
      "tz": "America\/Los_Angeles"
  },
  "KPVB": {
      "icao": "KPVB",
      "iata": "",
      "name": "Platteville Municipal Airport",
      "city": "Platteville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1025,
      "lat": 42.68939972,
      "lon": -90.44439697,
      "tz": "America\/Chicago"
  },
  "KPVC": {
      "icao": "KPVC",
      "iata": "PVC",
      "name": "Provincetown Municipal Airport",
      "city": "Provincetown",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 9,
      "lat": 42.0718994141,
      "lon": -70.2213973999,
      "tz": "America\/New_York"
  },
  "KPVD": {
      "icao": "KPVD",
      "iata": "PVD",
      "name": "Theodore Francis Green State Airport",
      "city": "Providence",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 55,
      "lat": 41.7326011658,
      "lon": -71.4204025269,
      "tz": "America\/New_York"
  },
  "KPVE": {
      "icao": "KPVE",
      "iata": "",
      "name": "Beech River Regional Airport",
      "city": "Lexington-Parsons",
      "state": "Tennessee",
      "country": "US",
      "elevation": 488,
      "lat": 35.6563987732,
      "lon": -88.1953964233,
      "tz": "America\/Chicago"
  },
  "KPVF": {
      "icao": "KPVF",
      "iata": "PVF",
      "name": "Placerville Airport",
      "city": "Placerville",
      "state": "California",
      "country": "US",
      "elevation": 2585,
      "lat": 38.7242012024,
      "lon": -120.7529983521,
      "tz": "America\/Los_Angeles"
  },
  "KPVG": {
      "icao": "KPVG",
      "iata": "",
      "name": "Hampton Roads Executive Airport",
      "city": "Norfolk",
      "state": "Virginia",
      "country": "US",
      "elevation": 23,
      "lat": 36.7802009583,
      "lon": -76.4487991333,
      "tz": "America\/New_York"
  },
  "KPVJ": {
      "icao": "KPVJ",
      "iata": "",
      "name": "Pauls Valley Municipal Airport",
      "city": "Pauls Valley",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 971,
      "lat": 34.71110153,
      "lon": -97.22319794,
      "tz": "America\/Chicago"
  },
  "KPVU": {
      "icao": "KPVU",
      "iata": "PVU",
      "name": "Provo Municipal Airport",
      "city": "Provo",
      "state": "Utah",
      "country": "US",
      "elevation": 4497,
      "lat": 40.2192001343,
      "lon": -111.7229995728,
      "tz": "America\/Denver"
  },
  "KPVW": {
      "icao": "KPVW",
      "iata": "PVW",
      "name": "Hale County Airport",
      "city": "Plainview",
      "state": "Texas",
      "country": "US",
      "elevation": 3374,
      "lat": 34.1680984497,
      "lon": -101.7170028687,
      "tz": "America\/Chicago"
  },
  "KPWA": {
      "icao": "KPWA",
      "iata": "PWA",
      "name": "Wiley Post Airport",
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1300,
      "lat": 35.53419876,
      "lon": -97.64710236,
      "tz": "America\/Chicago"
  },
  "KPWC": {
      "icao": "KPWC",
      "iata": "",
      "name": "Pine River Regional Airport",
      "city": "Pine River",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1295,
      "lat": 46.7248001099,
      "lon": -94.3816986084,
      "tz": "America\/Chicago"
  },
  "KPWD": {
      "icao": "KPWD",
      "iata": "PWD",
      "name": "Sher Wood Airport",
      "city": "Plentywood",
      "state": "Montana",
      "country": "US",
      "elevation": 2250,
      "lat": 48.7902984619,
      "lon": -104.533996582,
      "tz": "America\/Denver"
  },
  "KPWG": {
      "icao": "KPWG",
      "iata": "",
      "name": "Mc Gregor Executive Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 592,
      "lat": 31.48489952,
      "lon": -97.3164978,
      "tz": "America\/Chicago"
  },
  "KPWK": {
      "icao": "KPWK",
      "iata": "",
      "name": "Chicago Executive Airport",
      "city": "Chicago\/Prospect Heights\/Wheeling",
      "state": "Illinois",
      "country": "US",
      "elevation": 647,
      "lat": 42.114222,
      "lon": -87.901494,
      "tz": "America\/Chicago"
  },
  "KPWM": {
      "icao": "KPWM",
      "iata": "PWM",
      "name": "Portland International Jetport Airport",
      "city": "Portland",
      "state": "Maine",
      "country": "US",
      "elevation": 76,
      "lat": 43.64619827,
      "lon": -70.30930328,
      "tz": "America\/New_York"
  },
  "KPWT": {
      "icao": "KPWT",
      "iata": "PWT",
      "name": "Bremerton National Airport",
      "city": "Bremerton",
      "state": "Washington",
      "country": "US",
      "elevation": 444,
      "lat": 47.4902000427,
      "lon": -122.7649993896,
      "tz": "America\/Los_Angeles"
  },
  "KPXE": {
      "icao": "KPXE",
      "iata": "",
      "name": "Perry Houston County Airport",
      "city": "Perry",
      "state": "Georgia",
      "country": "US",
      "elevation": 418,
      "lat": 32.5106010437,
      "lon": -83.7673034668,
      "tz": "America\/New_York"
  },
  "KPYG": {
      "icao": "KPYG",
      "iata": "",
      "name": "Pageland Airport",
      "city": "Pageland",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 575,
      "lat": 34.742099762,
      "lon": -80.345199585,
      "tz": "America\/New_York"
  },
  "KPYM": {
      "icao": "KPYM",
      "iata": "PYM",
      "name": "Plymouth Municipal Airport",
      "city": "Plymouth",
      "state": "Indiana",
      "country": "US",
      "elevation": 148,
      "lat": 41.9090003967,
      "lon": -70.7287979126,
      "tz": "America\/New_York"
  },
  "KPYN": {
      "icao": "KPYN",
      "iata": "",
      "name": "Piedmont Municipal Airport",
      "city": "Piedmont",
      "state": "Missouri",
      "country": "US",
      "elevation": 467,
      "lat": 37.126701355,
      "lon": -90.7128982544,
      "tz": "America\/Chicago"
  },
  "KPYP": {
      "icao": "KPYP",
      "iata": "",
      "name": "Centre-Piedmont-Cherokee County Regional Airport",
      "city": "Centre",
      "state": "Alabama",
      "country": "US",
      "elevation": 596,
      "lat": 34.08997726,
      "lon": -85.61006927,
      "tz": "America\/Chicago"
  },
  "KPYX": {
      "icao": "KPYX",
      "iata": "",
      "name": "Perryton Ochiltree County Airport",
      "city": "Perryton",
      "state": "Texas",
      "country": "US",
      "elevation": 2918,
      "lat": 36.4128990173,
      "lon": -100.7519989014,
      "tz": "America\/Chicago"
  },
  "KPZQ": {
      "icao": "KPZQ",
      "iata": "",
      "name": "Presque Isle County Airport",
      "city": "Rogers City",
      "state": "Michigan",
      "country": "US",
      "elevation": 670,
      "lat": 45.4071006775,
      "lon": -83.8128967285,
      "tz": "America\/Detroit"
  },
  "KQMG": {
      "icao": "KQMG",
      "iata": "",
      "name": "Mudaysis Air Base",
      "city": "",
      "state": "Anbar",
      "country": "IQ",
      "elevation": 1210,
      "lat": 32.4106712341,
      "lon": 41.9474945068,
      "tz": "Asia\/Baghdad"
  },
  "KR47": {
      "icao": "KR47",
      "iata": "",
      "name": "Ruhe's Airport",
      "city": "Leipsic",
      "state": "Ohio",
      "country": "US",
      "elevation": 801,
      "lat": 41.10309982,
      "lon": -84.05190277,
      "tz": "America\/New_York"
  },
  "KR49": {
      "icao": "KR49",
      "iata": "",
      "name": "Ferry County Airport",
      "city": "Republic",
      "state": "Washington",
      "country": "US",
      "elevation": 2522,
      "lat": 48.7182006836,
      "lon": -118.65599823,
      "tz": "America\/Los_Angeles"
  },
  "KRAC": {
      "icao": "KRAC",
      "iata": "RAC",
      "name": "John H Batten Airport",
      "city": "Racine",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 674,
      "lat": 42.7606010437,
      "lon": -87.8152008057,
      "tz": "America\/Chicago"
  },
  "KRAL": {
      "icao": "KRAL",
      "iata": "RAL",
      "name": "Riverside Municipal Airport",
      "city": "Riverside",
      "state": "California",
      "country": "US",
      "elevation": 819,
      "lat": 33.95190048,
      "lon": -117.4449997,
      "tz": "America\/Los_Angeles"
  },
  "KRAP": {
      "icao": "KRAP",
      "iata": "RAP",
      "name": "Rapid City Regional Airport",
      "city": "Rapid City",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3204,
      "lat": 44.04529953,
      "lon": -103.0569992065,
      "tz": "America\/Denver"
  },
  "KRAS": {
      "icao": "KRAS",
      "iata": "",
      "name": "Mustang Beach Airport",
      "city": "Port Aransas",
      "state": "Texas",
      "country": "US",
      "elevation": 5,
      "lat": 27.8118000031,
      "lon": -97.0887985229,
      "tz": "America\/Chicago"
  },
  "KRAW": {
      "icao": "KRAW",
      "iata": "",
      "name": "Warsaw Municipal Airport",
      "city": "Warsaw",
      "state": "Missouri",
      "country": "US",
      "elevation": 936,
      "lat": 38.3469009399,
      "lon": -93.3453979492,
      "tz": "America\/Chicago"
  },
  "KRBD": {
      "icao": "KRBD",
      "iata": "RBD",
      "name": "Dallas Executive Airport",
      "city": "Dallas",
      "state": "Texas",
      "country": "US",
      "elevation": 660,
      "lat": 32.6809005737,
      "lon": -96.8682022095,
      "tz": "America\/Chicago"
  },
  "KRBE": {
      "icao": "KRBE",
      "iata": "",
      "name": "Rock County Airport",
      "city": "Bassett",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2349,
      "lat": 42.5696983337,
      "lon": -99.568397522,
      "tz": "America\/Chicago"
  },
  "KRBG": {
      "icao": "KRBG",
      "iata": "RBG",
      "name": "Roseburg Regional Airport",
      "city": "Roseburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 529,
      "lat": 43.2388000488,
      "lon": -123.3560028076,
      "tz": "America\/Los_Angeles"
  },
  "KRBL": {
      "icao": "KRBL",
      "iata": "RBL",
      "name": "Red Bluff Municipal Airport",
      "city": "Red Bluff",
      "state": "California",
      "country": "US",
      "elevation": 352,
      "lat": 40.1506996155,
      "lon": -122.2519989014,
      "tz": "America\/Los_Angeles"
  },
  "KRBM": {
      "icao": "KRBM",
      "iata": "",
      "name": "Robinson Army Air Field",
      "city": "Camp Robinson\/Little Rock",
      "state": "Arkansas",
      "country": "US",
      "elevation": 587,
      "lat": 34.85010147,
      "lon": -92.30020142,
      "tz": "America\/Chicago"
  },
  "KRBO": {
      "icao": "KRBO",
      "iata": "",
      "name": "Nueces County Airport",
      "city": "Robstown",
      "state": "Texas",
      "country": "US",
      "elevation": 79,
      "lat": 27.7784996033,
      "lon": -97.6904983521,
      "tz": "America\/Chicago"
  },
  "KRBW": {
      "icao": "KRBW",
      "iata": "RBW",
      "name": "Lowcountry Regional Airport",
      "city": "Walterboro",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 101,
      "lat": 32.9210014343,
      "lon": -80.6406021118,
      "tz": "America\/New_York"
  },
  "KRCA": {
      "icao": "KRCA",
      "iata": "RCA",
      "name": "Ellsworth Air Force Base",
      "city": "Rapid City",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3276,
      "lat": 44.14500046,
      "lon": -103.1039963,
      "tz": "America\/Denver"
  },
  "KRCE": {
      "icao": "KRCE",
      "iata": "",
      "name": "Clarence E Page Municipal Airport",
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1354,
      "lat": 35.48809814,
      "lon": -97.82360077,
      "tz": "America\/Chicago"
  },
  "KRCK": {
      "icao": "KRCK",
      "iata": "RCK",
      "name": "H H Coffield Regional Airport",
      "city": "Rockdale",
      "state": "Texas",
      "country": "US",
      "elevation": 474,
      "lat": 30.6315994263,
      "lon": -96.9897003174,
      "tz": "America\/Chicago"
  },
  "KRCM": {
      "icao": "KRCM",
      "iata": "",
      "name": "Skyhaven Airport",
      "city": "Warrensburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 798,
      "lat": 38.784198761,
      "lon": -93.8029022217,
      "tz": "America\/Chicago"
  },
  "KRCP": {
      "icao": "KRCP",
      "iata": "",
      "name": "Stockton \/ Rooks County Regional",
      "city": "",
      "state": "Kansas",
      "country": "US",
      "elevation": 1998,
      "lat": 39.346592,
      "lon": -99.304649,
      "tz": "America\/Chicago"
  },
  "KRCR": {
      "icao": "KRCR",
      "iata": "RCR",
      "name": "Fulton County Airport",
      "city": "Rochester",
      "state": "Indiana",
      "country": "US",
      "elevation": 790,
      "lat": 41.0656013489,
      "lon": -86.1817016602,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KRCT": {
      "icao": "KRCT",
      "iata": "RCT",
      "name": "Nartron Field",
      "city": "Reed City",
      "state": "Michigan",
      "country": "US",
      "elevation": 1055,
      "lat": 43.9000015259,
      "lon": -85.5167007446,
      "tz": "America\/Detroit"
  },
  "KRCV": {
      "icao": "KRCV",
      "iata": "",
      "name": "Astronaut Kent Rominger Airport",
      "city": "Del Norte",
      "state": "Colorado",
      "country": "US",
      "elevation": 7955,
      "lat": 37.7137838,
      "lon": -106.3520185,
      "tz": "America\/Denver"
  },
  "KRCX": {
      "icao": "KRCX",
      "iata": "",
      "name": "Rusk County Airport",
      "city": "Ladysmith",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1238,
      "lat": 45.496799469,
      "lon": -91.00050354,
      "tz": "America\/Chicago"
  },
  "KRCZ": {
      "icao": "KRCZ",
      "iata": "",
      "name": "Richmond County Airport",
      "city": "Rockingham",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 358,
      "lat": 34.8913002,
      "lon": -79.75959778,
      "tz": "America\/New_York"
  },
  "KRDD": {
      "icao": "KRDD",
      "iata": "RDD",
      "name": "Redding Municipal Airport",
      "city": "Redding",
      "state": "California",
      "country": "US",
      "elevation": 505,
      "lat": 40.50899887,
      "lon": -122.2929993,
      "tz": "America\/Los_Angeles"
  },
  "KRDG": {
      "icao": "KRDG",
      "iata": "RDG",
      "name": "Reading Regional Carl A Spaatz Field",
      "city": "Reading",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 344,
      "lat": 40.3785018921,
      "lon": -75.9652023315,
      "tz": "America\/New_York"
  },
  "KRDK": {
      "icao": "KRDK",
      "iata": "",
      "name": "Red Oak Municipal Airport",
      "city": "Red Oak",
      "state": "Iowa",
      "country": "US",
      "elevation": 1045,
      "lat": 41.01050186,
      "lon": -95.25990295,
      "tz": "America\/Chicago"
  },
  "KRDM": {
      "icao": "KRDM",
      "iata": "RDM",
      "name": "Roberts Field",
      "city": "Redmond",
      "state": "Oregon",
      "country": "US",
      "elevation": 3080,
      "lat": 44.2541008,
      "lon": -121.1500015,
      "tz": "America\/Los_Angeles"
  },
  "KRDR": {
      "icao": "KRDR",
      "iata": "RDR",
      "name": "Grand Forks Air Force Base",
      "city": "Grand Forks",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 913,
      "lat": 47.961101532,
      "lon": -97.4011993408,
      "tz": "America\/Chicago"
  },
  "KRDU": {
      "icao": "KRDU",
      "iata": "RDU",
      "name": "Raleigh Durham International Airport",
      "city": "Raleigh\/Durham",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 435,
      "lat": 35.8776016235,
      "lon": -78.7874984741,
      "tz": "America\/New_York"
  },
  "KRED": {
      "icao": "KRED",
      "iata": "",
      "name": "Red Lodge Airport",
      "city": "Red Lodge",
      "state": "Montana",
      "country": "US",
      "elevation": 5763,
      "lat": 45.1874008179,
      "lon": -109.266998291,
      "tz": "America\/Denver"
  },
  "KREG": {
      "icao": "KREG",
      "iata": "",
      "name": "Louisiana Regional Airport",
      "city": "Gonzales",
      "state": "Louisiana",
      "country": "US",
      "elevation": 4,
      "lat": 30.1713667,
      "lon": -90.9403861,
      "tz": "America\/Chicago"
  },
  "KREI": {
      "icao": "KREI",
      "iata": "",
      "name": "Redlands Municipal Airport",
      "city": "Redlands",
      "state": "California",
      "country": "US",
      "elevation": 1571,
      "lat": 34.0853004456,
      "lon": -117.1460037231,
      "tz": "America\/Los_Angeles"
  },
  "KREO": {
      "icao": "KREO",
      "iata": "REO",
      "name": "Rome State Airport",
      "city": "Rome",
      "state": "Oregon",
      "country": "US",
      "elevation": 4053,
      "lat": 42.5777015686,
      "lon": -117.8850021362,
      "tz": "America\/Boise"
  },
  "KRFD": {
      "icao": "KRFD",
      "iata": "RFD",
      "name": "Chicago Rockford International Airport",
      "city": "Chicago\/Rockford",
      "state": "Illinois",
      "country": "US",
      "elevation": 742,
      "lat": 42.195400238,
      "lon": -89.0971984863,
      "tz": "America\/Chicago"
  },
  "KRFG": {
      "icao": "KRFG",
      "iata": "RFG",
      "name": "Rooke Field",
      "city": "Refugio",
      "state": "Texas",
      "country": "US",
      "elevation": 56,
      "lat": 28.2936000824,
      "lon": -97.3229980469,
      "tz": "America\/Chicago"
  },
  "KRFI": {
      "icao": "KRFI",
      "iata": "",
      "name": "Rusk County Airport",
      "city": "Henderson",
      "state": "Texas",
      "country": "US",
      "elevation": 442,
      "lat": 32.1417007446,
      "lon": -94.8516998291,
      "tz": "America\/Chicago"
  },
  "KRGK": {
      "icao": "KRGK",
      "iata": "",
      "name": "Red Wing Regional Airport",
      "city": "Red Wing",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 778,
      "lat": 44.58940125,
      "lon": -92.48500061,
      "tz": "America\/Chicago"
  },
  "KRHI": {
      "icao": "KRHI",
      "iata": "RHI",
      "name": "Rhinelander Oneida County Airport",
      "city": "Rhinelander",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1624,
      "lat": 45.6311988831,
      "lon": -89.4674987793,
      "tz": "America\/Chicago"
  },
  "KRHP": {
      "icao": "KRHP",
      "iata": "",
      "name": "Western Carolina Regional Airport",
      "city": "Andrews",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1697,
      "lat": 35.19520187,
      "lon": -83.86299896,
      "tz": "America\/New_York"
  },
  "KRHV": {
      "icao": "KRHV",
      "iata": "RHV",
      "name": "Reid Hillview of Santa Clara County Airport",
      "city": "San Jose",
      "state": "California",
      "country": "US",
      "elevation": 135,
      "lat": 37.332901001,
      "lon": -121.8190002441,
      "tz": "America\/Los_Angeles"
  },
  "KRIC": {
      "icao": "KRIC",
      "iata": "RIC",
      "name": "Richmond International Airport",
      "city": "Richmond",
      "state": "Virginia",
      "country": "US",
      "elevation": 167,
      "lat": 37.5051994324,
      "lon": -77.3197021484,
      "tz": "America\/New_York"
  },
  "KRID": {
      "icao": "KRID",
      "iata": "RID",
      "name": "Richmond Municipal Airport",
      "city": "Richmond",
      "state": "Indiana",
      "country": "US",
      "elevation": 1140,
      "lat": 39.7571983337,
      "lon": -84.8427963257,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KRIE": {
      "icao": "KRIE",
      "iata": "",
      "name": "Rice Lake Airport",
      "city": "",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1142,
      "lat": 45.4785995483,
      "lon": -91.7221984863,
      "tz": "America\/Chicago"
  },
  "KRIF": {
      "icao": "KRIF",
      "iata": "RIF",
      "name": "Richfield Municipal Airport",
      "city": "Richfield",
      "state": "Utah",
      "country": "US",
      "elevation": 5301,
      "lat": 38.7364006042,
      "lon": -112.0989990234,
      "tz": "America\/Denver"
  },
  "KRIL": {
      "icao": "KRIL",
      "iata": "RIL",
      "name": "Garfield County Regional Airport",
      "city": "Rifle",
      "state": "Colorado",
      "country": "US",
      "elevation": 5548,
      "lat": 39.52629852,
      "lon": -107.7269974,
      "tz": "America\/Denver"
  },
  "KRIR": {
      "icao": "KRIR",
      "iata": "RIR",
      "name": "Flabob Airport",
      "city": "Riverside\/Rubidoux\/",
      "state": "California",
      "country": "US",
      "elevation": 764,
      "lat": 33.9897003174,
      "lon": -117.4110031128,
      "tz": "America\/Los_Angeles"
  },
  "KRIU": {
      "icao": "KRIU",
      "iata": "",
      "name": "Rancho Murieta Airport",
      "city": "Rancho Murieta",
      "state": "California",
      "country": "US",
      "elevation": 141,
      "lat": 38.4868011475,
      "lon": -121.1029968262,
      "tz": "America\/Los_Angeles"
  },
  "KRIV": {
      "icao": "KRIV",
      "iata": "RIV",
      "name": "March ARB Airport",
      "city": "Riverside",
      "state": "California",
      "country": "US",
      "elevation": 1536,
      "lat": 33.88069916,
      "lon": -117.2590027,
      "tz": "America\/Los_Angeles"
  },
  "KRIW": {
      "icao": "KRIW",
      "iata": "RIW",
      "name": "Riverton Regional Airport",
      "city": "Riverton",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5525,
      "lat": 43.064201355,
      "lon": -108.4599990845,
      "tz": "America\/Denver"
  },
  "KRJD": {
      "icao": "KRJD",
      "iata": "",
      "name": "Ridgely Airpark",
      "city": "Ridgely",
      "state": "Maryland",
      "country": "US",
      "elevation": 64,
      "lat": 38.9701004028,
      "lon": -75.8663024902,
      "tz": "America\/New_York"
  },
  "KRKD": {
      "icao": "KRKD",
      "iata": "RKD",
      "name": "Knox County Regional Airport",
      "city": "Rockland",
      "state": "Maine",
      "country": "US",
      "elevation": 56,
      "lat": 44.06010056,
      "lon": -69.09919739,
      "tz": "America\/New_York"
  },
  "KRKP": {
      "icao": "KRKP",
      "iata": "RKP",
      "name": "Aransas County Airport",
      "city": "Rockport",
      "state": "Texas",
      "country": "US",
      "elevation": 24,
      "lat": 28.0867996216,
      "lon": -97.0446014404,
      "tz": "America\/Chicago"
  },
  "KRKR": {
      "icao": "KRKR",
      "iata": "RKR",
      "name": "Robert S Kerr Airport",
      "city": "Poteau",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 451,
      "lat": 35.0215988159,
      "lon": -94.6212997437,
      "tz": "America\/Chicago"
  },
  "KRKS": {
      "icao": "KRKS",
      "iata": "RKS",
      "name": "Rock Springs Sweetwater County Airport",
      "city": "Rock Springs",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6764,
      "lat": 41.59420013,
      "lon": -109.0650024,
      "tz": "America\/Denver"
  },
  "KRKW": {
      "icao": "KRKW",
      "iata": "RKW",
      "name": "Rockwood Municipal Airport",
      "city": "Rockwood",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1664,
      "lat": 35.9222984314,
      "lon": -84.6896972656,
      "tz": "America\/Chicago"
  },
  "KRLD": {
      "icao": "KRLD",
      "iata": "RLD",
      "name": "Richland Airport",
      "city": "Richland",
      "state": "Washington",
      "country": "US",
      "elevation": 394,
      "lat": 46.3055992126,
      "lon": -119.3040008545,
      "tz": "America\/Los_Angeles"
  },
  "KRME": {
      "icao": "KRME",
      "iata": "RME",
      "name": "Griffiss International Airport",
      "city": "Rome",
      "state": "New-York",
      "country": "US",
      "elevation": 504,
      "lat": 43.23379898,
      "lon": -75.40699768,
      "tz": "America\/New_York"
  },
  "KRMG": {
      "icao": "KRMG",
      "iata": "RMG",
      "name": "Richard B Russell Airport",
      "city": "Rome",
      "state": "Georgia",
      "country": "US",
      "elevation": 644,
      "lat": 34.3506011963,
      "lon": -85.1579971313,
      "tz": "America\/New_York"
  },
  "KRMN": {
      "icao": "KRMN",
      "iata": "",
      "name": "Stafford Regional Airport",
      "city": "Stafford",
      "state": "Virginia",
      "country": "US",
      "elevation": 211,
      "lat": 38.3981018066,
      "lon": -77.4554977417,
      "tz": "America\/New_York"
  },
  "KRMY": {
      "icao": "KRMY",
      "iata": "",
      "name": "Brooks Field",
      "city": "Marshall",
      "state": "Colorado",
      "country": "US",
      "elevation": 941,
      "lat": 42.2512016296,
      "lon": -84.9554977417,
      "tz": "America\/Detroit"
  },
  "KRNC": {
      "icao": "KRNC",
      "iata": "RNC",
      "name": "Warren County Memorial Airport",
      "city": "Mc Minnville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1032,
      "lat": 35.69869995,
      "lon": -85.84380341,
      "tz": "America\/Chicago"
  },
  "KRND": {
      "icao": "KRND",
      "iata": "RND",
      "name": "Randolph Air Force Base",
      "city": "Universal City",
      "state": "Texas",
      "country": "US",
      "elevation": 761,
      "lat": 29.52969933,
      "lon": -98.27890015,
      "tz": "America\/Chicago"
  },
  "KRNH": {
      "icao": "KRNH",
      "iata": "RNH",
      "name": "New Richmond Regional Airport",
      "city": "New Richmond",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 998,
      "lat": 45.14830017,
      "lon": -92.5381012,
      "tz": "America\/Chicago"
  },
  "KRNM": {
      "icao": "KRNM",
      "iata": "",
      "name": "Ramona Airport",
      "city": "Ramona",
      "state": "California",
      "country": "US",
      "elevation": 1395,
      "lat": 33.0391998291,
      "lon": -116.9150009155,
      "tz": "America\/Los_Angeles"
  },
  "KRNO": {
      "icao": "KRNO",
      "iata": "RNO",
      "name": "Reno Tahoe International Airport",
      "city": "Reno",
      "state": "Nevada",
      "country": "US",
      "elevation": 4415,
      "lat": 39.4990997314,
      "lon": -119.7679977417,
      "tz": "America\/Los_Angeles"
  },
  "KRNP": {
      "icao": "KRNP",
      "iata": "",
      "name": "Owosso Community Airport",
      "city": "Owosso",
      "state": "Michigan",
      "country": "US",
      "elevation": 736,
      "lat": 42.9930000305,
      "lon": -84.1389007568,
      "tz": "America\/Detroit"
  },
  "KRNT": {
      "icao": "KRNT",
      "iata": "RNT",
      "name": "Renton Municipal Airport",
      "city": "Renton",
      "state": "Washington",
      "country": "US",
      "elevation": 32,
      "lat": 47.4930992126,
      "lon": -122.216003418,
      "tz": "America\/Los_Angeles"
  },
  "KRNV": {
      "icao": "KRNV",
      "iata": "",
      "name": "Cleveland Municipal Airport",
      "city": "Cleveland",
      "state": "Mississippi",
      "country": "US",
      "elevation": 139,
      "lat": 33.76110077,
      "lon": -90.75789642,
      "tz": "America\/Chicago"
  },
  "KROA": {
      "icao": "KROA",
      "iata": "ROA",
      "name": "Roanoke Regional Woodrum Field",
      "city": "Roanoke",
      "state": "Virginia",
      "country": "US",
      "elevation": 1175,
      "lat": 37.3255004883,
      "lon": -79.975402832,
      "tz": "America\/New_York"
  },
  "KROC": {
      "icao": "KROC",
      "iata": "ROC",
      "name": "Greater Rochester International Airport",
      "city": "Rochester",
      "state": "New-York",
      "country": "US",
      "elevation": 559,
      "lat": 43.1189002991,
      "lon": -77.6724014282,
      "tz": "America\/New_York"
  },
  "KROG": {
      "icao": "KROG",
      "iata": "ROG",
      "name": "Rogers Municipal Carter Field",
      "city": "Rogers",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1359,
      "lat": 36.37229919,
      "lon": -94.10690308,
      "tz": "America\/Chicago"
  },
  "KROS": {
      "icao": "KROS",
      "iata": "",
      "name": "Rush City Regional Airport",
      "city": "Rush City",
      "state": "Minnesota",
      "country": "US",
      "elevation": 926,
      "lat": 45.69800186,
      "lon": -92.95300293,
      "tz": "America\/Chicago"
  },
  "KROW": {
      "icao": "KROW",
      "iata": "ROW",
      "name": "Roswell International Air Center Airport",
      "city": "Roswell",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3671,
      "lat": 33.3016014099,
      "lon": -104.53099823,
      "tz": "America\/Denver"
  },
  "KROX": {
      "icao": "KROX",
      "iata": "ROX",
      "name": "Roseau Municipal Rudy Billberg Field",
      "city": "Roseau",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1060,
      "lat": 48.85599899,
      "lon": -95.6969986,
      "tz": "America\/Chicago"
  },
  "KRPB": {
      "icao": "KRPB",
      "iata": "",
      "name": "Belleville Municipal Airport",
      "city": "Belleville",
      "state": "Kansas",
      "country": "US",
      "elevation": 1537,
      "lat": 39.8179016113,
      "lon": -97.6595993042,
      "tz": "America\/Chicago"
  },
  "KRPD": {
      "icao": "KRPD",
      "iata": "RIE",
      "name": "Rice Lake Regional Carl's Field",
      "city": "Rice Lake",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1109,
      "lat": 45.41899872,
      "lon": -91.77349854,
      "tz": "America\/Chicago"
  },
  "KRPH": {
      "icao": "KRPH",
      "iata": "",
      "name": "Graham Municipal Airport",
      "city": "Graham",
      "state": "Texas",
      "country": "US",
      "elevation": 1123,
      "lat": 33.1101989746,
      "lon": -98.5552978516,
      "tz": "America\/Chicago"
  },
  "KRPJ": {
      "icao": "KRPJ",
      "iata": "",
      "name": "Rochelle Municipal Airport - Koritz Field",
      "city": "Rochelle",
      "state": "Illinois",
      "country": "US",
      "elevation": 781,
      "lat": 41.8930015564,
      "lon": -89.0783004761,
      "tz": "America\/Chicago"
  },
  "KRPX": {
      "icao": "KRPX",
      "iata": "RPX",
      "name": "Roundup Airport",
      "city": "Roundup",
      "state": "Montana",
      "country": "US",
      "elevation": 3491,
      "lat": 46.4749984741,
      "lon": -108.5429992676,
      "tz": "America\/Denver"
  },
  "KRQB": {
      "icao": "KRQB",
      "iata": "WBR",
      "name": "Roben Hood Airport",
      "city": "Big Rapids",
      "state": "Michigan",
      "country": "US",
      "elevation": 990,
      "lat": 43.7225990295,
      "lon": -85.5040969849,
      "tz": "America\/Detroit"
  },
  "KRQE": {
      "icao": "KRQE",
      "iata": "",
      "name": "Window Rock Airport",
      "city": "Window Rock",
      "state": "Arizona",
      "country": "US",
      "elevation": 6742,
      "lat": 35.6520996094,
      "lon": -109.0670013428,
      "tz": "America\/Denver"
  },
  "KRQO": {
      "icao": "KRQO",
      "iata": "RQO",
      "name": "El Reno Regional Airport",
      "city": "El Reno",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1420,
      "lat": 35.47269821,
      "lon": -98.00579834,
      "tz": "America\/Chicago"
  },
  "KRRL": {
      "icao": "KRRL",
      "iata": "RRL",
      "name": "Merrill Municipal Airport",
      "city": "Merrill",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1318,
      "lat": 45.1988983154,
      "lon": -89.7128982544,
      "tz": "America\/Chicago"
  },
  "KRRQ": {
      "icao": "KRRQ",
      "iata": "",
      "name": "Rock Rapids Municipal Airport",
      "city": "Rock Rapids",
      "state": "Iowa",
      "country": "US",
      "elevation": 1363,
      "lat": 43.4522018433,
      "lon": -96.1798019409,
      "tz": "America\/Chicago"
  },
  "KRRT": {
      "icao": "KRRT",
      "iata": "RRT",
      "name": "Warroad International Memorial Field",
      "city": "Warroad",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1076,
      "lat": 48.94139862,
      "lon": -95.3483963,
      "tz": "America\/Chicago"
  },
  "KRSL": {
      "icao": "KRSL",
      "iata": "RSL",
      "name": "Russell Municipal Airport",
      "city": "Russell",
      "state": "Kansas",
      "country": "US",
      "elevation": 1862,
      "lat": 38.8721008301,
      "lon": -98.8117980957,
      "tz": "America\/Chicago"
  },
  "KRSN": {
      "icao": "KRSN",
      "iata": "RSN",
      "name": "Ruston Regional Airport",
      "city": "Ruston",
      "state": "Louisiana",
      "country": "US",
      "elevation": 311,
      "lat": 32.5144004822,
      "lon": -92.5916976929,
      "tz": "America\/Chicago"
  },
  "KRST": {
      "icao": "KRST",
      "iata": "RST",
      "name": "Rochester International Airport",
      "city": "Rochester",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1317,
      "lat": 43.9082984924,
      "lon": -92.5,
      "tz": "America\/Chicago"
  },
  "KRSV": {
      "icao": "KRSV",
      "iata": "",
      "name": "Robinson Municipal Airport",
      "city": "Robinson",
      "state": "Illinois",
      "country": "US",
      "elevation": 462,
      "lat": 39.0159988403,
      "lon": -87.6498031616,
      "tz": "America\/Chicago"
  },
  "KRSW": {
      "icao": "KRSW",
      "iata": "RSW",
      "name": "Southwest Florida International Airport",
      "city": "Fort Myers",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 26.5361995697,
      "lon": -81.7552032471,
      "tz": "America\/New_York"
  },
  "KRTN": {
      "icao": "KRTN",
      "iata": "RTN",
      "name": "Raton Municipal-Crews Field",
      "city": "Raton",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6352,
      "lat": 36.74150085,
      "lon": -104.5019989,
      "tz": "America\/Denver"
  },
  "KRTS": {
      "icao": "KRTS",
      "iata": "",
      "name": "Reno Stead Airport",
      "city": "Reno",
      "state": "Nevada",
      "country": "US",
      "elevation": 5050,
      "lat": 39.6674003601,
      "lon": -119.875999451,
      "tz": "America\/Los_Angeles"
  },
  "KRUE": {
      "icao": "KRUE",
      "iata": "",
      "name": "Russellville Regional Airport",
      "city": "Russellville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 404,
      "lat": 35.25910187,
      "lon": -93.09329987,
      "tz": "America\/Chicago"
  },
  "KRUG": {
      "icao": "KRUG",
      "iata": "",
      "name": "Rugby Municipal Airport",
      "city": "Rugby",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1548,
      "lat": 48.3903999329,
      "lon": -100.0240020752,
      "tz": "America\/Chicago"
  },
  "KRUQ": {
      "icao": "KRUQ",
      "iata": "SRW",
      "name": "Rowan County Airport",
      "city": "Salisbury",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 772,
      "lat": 35.64590073,
      "lon": -80.52030182,
      "tz": "America\/New_York"
  },
  "KRUT": {
      "icao": "KRUT",
      "iata": "RUT",
      "name": "Rutland - Southern Vermont Regional Airport",
      "city": "Rutland",
      "state": "Vermont",
      "country": "US",
      "elevation": 787,
      "lat": 43.52939987,
      "lon": -72.94960022,
      "tz": "America\/New_York"
  },
  "KRVJ": {
      "icao": "KRVJ",
      "iata": "",
      "name": "Swinton Smith Field at Reidsville Municipal Airport",
      "city": "Reidsville",
      "state": "Georgia",
      "country": "US",
      "elevation": 195,
      "lat": 32.0589981079,
      "lon": -82.1517028809,
      "tz": "America\/New_York"
  },
  "KRVL": {
      "icao": "KRVL",
      "iata": "RED",
      "name": "Mifflin County Airport",
      "city": "Reedsville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 819,
      "lat": 40.6773986816,
      "lon": -77.6268005371,
      "tz": "America\/New_York"
  },
  "KRVN": {
      "icao": "KRVN",
      "iata": "",
      "name": "Hawkins County Airport",
      "city": "Rogersville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1255,
      "lat": 36.4575996399,
      "lon": -82.8850021362,
      "tz": "America\/New_York"
  },
  "KRVS": {
      "icao": "KRVS",
      "iata": "RVS",
      "name": "Richard Lloyd Jones Jr Airport",
      "city": "Tulsa",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 638,
      "lat": 36.0396003723,
      "lon": -95.9845962524,
      "tz": "America\/Chicago"
  },
  "KRWF": {
      "icao": "KRWF",
      "iata": "RWF",
      "name": "Redwood Falls Municipal Airport",
      "city": "Redwood Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1024,
      "lat": 44.54719925,
      "lon": -95.08229828,
      "tz": "America\/Chicago"
  },
  "KRWI": {
      "icao": "KRWI",
      "iata": "RWI",
      "name": "Rocky Mount Wilson Regional Airport",
      "city": "Rocky Mount",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 159,
      "lat": 35.856300354,
      "lon": -77.8918991089,
      "tz": "America\/New_York"
  },
  "KRWL": {
      "icao": "KRWL",
      "iata": "RWL",
      "name": "Rawlins Municipal Harvey Field",
      "city": "Rawlins",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6813,
      "lat": 41.80559921,
      "lon": -107.1999969,
      "tz": "America\/Denver"
  },
  "KRWN": {
      "icao": "KRWN",
      "iata": "",
      "name": "Arens Field",
      "city": "Winamac",
      "state": "Indiana",
      "country": "US",
      "elevation": 711,
      "lat": 41.092300415,
      "lon": -86.6128997803,
      "tz": "America\/Indiana\/Winamac"
  },
  "KRWV": {
      "icao": "KRWV",
      "iata": "",
      "name": "Caldwell Municipal Airport",
      "city": "Caldwell",
      "state": "Texas",
      "country": "US",
      "elevation": 391,
      "lat": 30.515499115,
      "lon": -96.7041015625,
      "tz": "America\/Chicago"
  },
  "KRXE": {
      "icao": "KRXE",
      "iata": "RXE",
      "name": "Rexburg Madison County Airport",
      "city": "Rexburg",
      "state": "Idaho",
      "country": "US",
      "elevation": 4858,
      "lat": 43.8339004517,
      "lon": -111.805000305,
      "tz": "America\/Boise"
  },
  "KRYM": {
      "icao": "KRYM",
      "iata": "",
      "name": "Ray S Miller Army Air Field",
      "city": "Camp Ripley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1150,
      "lat": 46.0912017822,
      "lon": -94.360496521,
      "tz": "America\/Chicago"
  },
  "KRYN": {
      "icao": "KRYN",
      "iata": "",
      "name": "Ryan Field",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 2417,
      "lat": 32.14220047,
      "lon": -111.1750030518,
      "tz": "America\/Phoenix"
  },
  "KRYV": {
      "icao": "KRYV",
      "iata": "",
      "name": "Watertown Municipal Airport",
      "city": "Watertown",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 833,
      "lat": 43.1696014404,
      "lon": -88.723197937,
      "tz": "America\/Chicago"
  },
  "KRYW": {
      "icao": "KRYW",
      "iata": "",
      "name": "Lago Vista Tx Rusty Allen Airport",
      "city": "Lago Vista",
      "state": "Texas",
      "country": "US",
      "elevation": 1231,
      "lat": 30.4986000061,
      "lon": -97.9694976807,
      "tz": "America\/Chicago"
  },
  "KRYY": {
      "icao": "KRYY",
      "iata": "",
      "name": "Cobb County-Mc Collum Field",
      "city": "Atlanta",
      "state": "Georgia",
      "country": "US",
      "elevation": 1040,
      "lat": 34.01319885,
      "lon": -84.59860229,
      "tz": "America\/New_York"
  },
  "KRZL": {
      "icao": "KRZL",
      "iata": "RNZ",
      "name": "Jasper County Airport",
      "city": "Rensselaer",
      "state": "Indiana",
      "country": "US",
      "elevation": 698,
      "lat": 40.9478988647,
      "lon": -87.1826019287,
      "tz": "America\/Chicago"
  },
  "KRZN": {
      "icao": "KRZN",
      "iata": "",
      "name": "Burnett County Airport",
      "city": "Siren",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 989,
      "lat": 45.8227005005,
      "lon": -92.3724975586,
      "tz": "America\/Chicago"
  },
  "KRZR": {
      "icao": "KRZR",
      "iata": "",
      "name": "Cleveland Regional Jetport C",
      "city": "Cleveland",
      "state": "Tennessee",
      "country": "US",
      "elevation": 866,
      "lat": 35.2123364,
      "lon": -84.7991994,
      "tz": "America\/New_York"
  },
  "KRZT": {
      "icao": "KRZT",
      "iata": "",
      "name": "Ross County Airport",
      "city": "Chillicothe",
      "state": "Ohio",
      "country": "US",
      "elevation": 725,
      "lat": 39.4403991699,
      "lon": -83.0231018066,
      "tz": "America\/New_York"
  },
  "KRZZ": {
      "icao": "KRZZ",
      "iata": "RZZ",
      "name": "Halifax County Airport",
      "city": "Roanoke Rapids",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 256,
      "lat": 36.4394989014,
      "lon": -77.7092971802,
      "tz": "America\/New_York"
  },
  "KS01": {
      "icao": "KS01",
      "iata": "",
      "name": "Conrad Airport",
      "city": "Conrad",
      "state": "Montana",
      "country": "US",
      "elevation": 3545,
      "lat": 48.168598175,
      "lon": -111.9759979248,
      "tz": "America\/Denver"
  },
  "KS03": {
      "icao": "KS03",
      "iata": "AHM",
      "name": "Ashland Municipal Sumner Parker Field",
      "city": "Ashland",
      "state": "Oregon",
      "country": "US",
      "elevation": 1885,
      "lat": 42.1902999878,
      "lon": -122.661003113,
      "tz": "America\/Los_Angeles"
  },
  "KS05": {
      "icao": "KS05",
      "iata": "BDY",
      "name": "Bandon State Airport",
      "city": "Bandon",
      "state": "Oregon",
      "country": "US",
      "elevation": 122,
      "lat": 43.08649826,
      "lon": -124.4079971,
      "tz": "America\/Los_Angeles"
  },
  "KS10": {
      "icao": "KS10",
      "iata": "",
      "name": "Lake Chelan Airport",
      "city": "Chelan",
      "state": "Washington",
      "country": "US",
      "elevation": 1263,
      "lat": 47.8660011292,
      "lon": -119.9430007935,
      "tz": "America\/Los_Angeles"
  },
  "KS12": {
      "icao": "KS12",
      "iata": "",
      "name": "Albany Municipal Airport",
      "city": "Albany",
      "state": "Oregon",
      "country": "US",
      "elevation": 226,
      "lat": 44.6377983093,
      "lon": -123.0589981079,
      "tz": "America\/Los_Angeles"
  },
  "KS17": {
      "icao": "KS17",
      "iata": "",
      "name": "Twin Lakes Airport",
      "city": "Graniteville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 540,
      "lat": 33.6456985474,
      "lon": -81.8671035767,
      "tz": "America\/New_York"
  },
  "KS19": {
      "icao": "KS19",
      "iata": "",
      "name": "Mc Cormick County Airport",
      "city": "Mc Cormick",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 459,
      "lat": 33.90810013,
      "lon": -82.26689911,
      "tz": "America\/New_York"
  },
  "KS20": {
      "icao": "KS20",
      "iata": "",
      "name": "Goldendale Airport",
      "city": "Goldendale",
      "state": "Washington",
      "country": "US",
      "elevation": 1678,
      "lat": 45.8320999146,
      "lon": -120.8450012207,
      "tz": "America\/Los_Angeles"
  },
  "KS21": {
      "icao": "KS21",
      "iata": "SUO",
      "name": "Sunriver Airport",
      "city": "Sunriver",
      "state": "Oregon",
      "country": "US",
      "elevation": 4164,
      "lat": 43.8763008118,
      "lon": -121.45300293,
      "tz": "America\/Los_Angeles"
  },
  "KS23": {
      "icao": "KS23",
      "iata": "",
      "name": "Ione Municipal Airport",
      "city": "Ione",
      "state": "Washington",
      "country": "US",
      "elevation": 2108,
      "lat": 48.7080993652,
      "lon": -117.4130020142,
      "tz": "America\/Los_Angeles"
  },
  "KS24": {
      "icao": "KS24",
      "iata": "",
      "name": "Sandusky County Regional Airport",
      "city": "Fremont, Ohio",
      "state": "Ohio",
      "country": "US",
      "elevation": 665,
      "lat": 41.29575,
      "lon": -83.0372222,
      "tz": "America\/New_York"
  },
  "KS25": {
      "icao": "KS25",
      "iata": "",
      "name": "Watford City Municipal Airport",
      "city": "Watford City",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2111,
      "lat": 47.79570007,
      "lon": -103.2539978,
      "tz": "America\/Chicago"
  },
  "KS27": {
      "icao": "KS27",
      "iata": "",
      "name": "Kalispell City Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2932,
      "lat": 48.1786003113,
      "lon": -114.3040008545,
      "tz": "America\/Denver"
  },
  "KS32": {
      "icao": "KS32",
      "iata": "",
      "name": "Cooperstown Municipal Airport",
      "city": "Cooperstown",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1424,
      "lat": 47.4227981567,
      "lon": -98.1059036255,
      "tz": "America\/Chicago"
  },
  "KS33": {
      "icao": "KS33",
      "iata": "",
      "name": "Madras Municipal Airport",
      "city": "Madras",
      "state": "Oregon",
      "country": "US",
      "elevation": 2437,
      "lat": 44.67020035,
      "lon": -121.1549988,
      "tz": "America\/Los_Angeles"
  },
  "KS34": {
      "icao": "KS34",
      "iata": "",
      "name": "Plains Airport",
      "city": "Plains",
      "state": "Montana",
      "country": "US",
      "elevation": 2467,
      "lat": 47.47240067,
      "lon": -114.9000015,
      "tz": "America\/Denver"
  },
  "KS36": {
      "icao": "KS36",
      "iata": "",
      "name": "Crest Airpark",
      "city": "Kent",
      "state": "Washington",
      "country": "US",
      "elevation": 472,
      "lat": 47.3371009827,
      "lon": -122.1039962769,
      "tz": "America\/Los_Angeles"
  },
  "KS39": {
      "icao": "KS39",
      "iata": "",
      "name": "Prineville Airport",
      "city": "Prineville",
      "state": "Oregon",
      "country": "US",
      "elevation": 3250,
      "lat": 44.2869987488,
      "lon": -120.9039993286,
      "tz": "America\/Los_Angeles"
  },
  "KS40": {
      "icao": "KS40",
      "iata": "",
      "name": "Prosser Airport",
      "city": "Prosser",
      "state": "Washington",
      "country": "US",
      "elevation": 697,
      "lat": 46.21340179,
      "lon": -119.7910004,
      "tz": "America\/Los_Angeles"
  },
  "KS42": {
      "icao": "KS42",
      "iata": "",
      "name": "Springer Municipal Airport",
      "city": "Springer",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5891,
      "lat": 36.3269996643,
      "lon": -104.6200027466,
      "tz": "America\/Denver"
  },
  "KS45": {
      "icao": "KS45",
      "iata": "",
      "name": "Siletz Bay State Airport",
      "city": "Gleneden Beach",
      "state": "Oregon",
      "country": "US",
      "elevation": 69,
      "lat": 44.8768997192,
      "lon": -124.0289993286,
      "tz": "America\/Los_Angeles"
  },
  "KS48": {
      "icao": "KS48",
      "iata": "",
      "name": "Country Squire Airpark",
      "city": "Sandy",
      "state": "Oregon",
      "country": "US",
      "elevation": 1175,
      "lat": 45.3544006348,
      "lon": -122.2679977417,
      "tz": "America\/Los_Angeles"
  },
  "KS50": {
      "icao": "KS50",
      "iata": "",
      "name": "Auburn Municipal Airport",
      "city": "Auburn",
      "state": "Washington",
      "country": "US",
      "elevation": 63,
      "lat": 47.3277015686,
      "lon": -122.2269973755,
      "tz": "America\/Los_Angeles"
  },
  "KS52": {
      "icao": "KS52",
      "iata": "",
      "name": "Methow Valley State Airport",
      "city": "Winthrop",
      "state": "Washington",
      "country": "US",
      "elevation": 1706,
      "lat": 48.4249000549,
      "lon": -120.1460037231,
      "tz": "America\/Los_Angeles"
  },
  "KS59": {
      "icao": "KS59",
      "iata": "",
      "name": "Libby Airport",
      "city": "Libby",
      "state": "Montana",
      "country": "US",
      "elevation": 2601,
      "lat": 48.2837982178,
      "lon": -115.4899978638,
      "tz": "America\/Denver"
  },
  "KS64": {
      "icao": "KS64",
      "iata": "",
      "name": "Stanford Airport",
      "city": "Stanford",
      "state": "Montana",
      "country": "US",
      "elevation": 4327,
      "lat": 47.14720154,
      "lon": -110.2300034,
      "tz": "America\/Denver"
  },
  "KS67": {
      "icao": "KS67",
      "iata": "",
      "name": "Nampa Municipal Airport",
      "city": "Nampa",
      "state": "Idaho",
      "country": "US",
      "elevation": 2537,
      "lat": 43.5812988281,
      "lon": -116.5230026245,
      "tz": "America\/Boise"
  },
  "KS69": {
      "icao": "KS69",
      "iata": "",
      "name": "Lincoln Airport",
      "city": "Lincoln",
      "state": "Montana",
      "country": "US",
      "elevation": 4603,
      "lat": 46.95439911,
      "lon": -112.6500015,
      "tz": "America\/Denver"
  },
  "KS70": {
      "icao": "KS70",
      "iata": "",
      "name": "Othello Municipal Airport",
      "city": "Othello",
      "state": "Washington",
      "country": "US",
      "elevation": 1139,
      "lat": 46.79489899,
      "lon": -119.0790024,
      "tz": "America\/Los_Angeles"
  },
  "KS71": {
      "icao": "KS71",
      "iata": "",
      "name": "Edgar G Obie Airport",
      "city": "Chinook",
      "state": "Montana",
      "country": "US",
      "elevation": 2416,
      "lat": 48.5920982361,
      "lon": -109.2509994507,
      "tz": "America\/Denver"
  },
  "KS72": {
      "icao": "KS72",
      "iata": "",
      "name": "St Maries Municipal Airport",
      "city": "St Maries",
      "state": "Idaho",
      "country": "US",
      "elevation": 2127,
      "lat": 47.3277015686,
      "lon": -116.577003479,
      "tz": "America\/Los_Angeles"
  },
  "KS73": {
      "icao": "KS73",
      "iata": "",
      "name": "Kamiah Municipal Airport",
      "city": "Kamiah",
      "state": "Idaho",
      "country": "US",
      "elevation": 1194,
      "lat": 46.2192993164,
      "lon": -116.0130004883,
      "tz": "America\/Los_Angeles"
  },
  "KS75": {
      "icao": "KS75",
      "iata": "",
      "name": "Payette Municipal Airport",
      "city": "Payette",
      "state": "Idaho",
      "country": "US",
      "elevation": 2228,
      "lat": 44.0915985107,
      "lon": -116.9010009766,
      "tz": "America\/Boise"
  },
  "KS78": {
      "icao": "KS78",
      "iata": "",
      "name": "Emmett Municipal Airport",
      "city": "Emmett",
      "state": "Idaho",
      "country": "US",
      "elevation": 2350,
      "lat": 43.849899292,
      "lon": -116.5429992676,
      "tz": "America\/Boise"
  },
  "KS81": {
      "icao": "KS81",
      "iata": "",
      "name": "Indian Creek USFS Airport",
      "city": "Indian Creek, Idaho",
      "state": "Idaho",
      "country": "US",
      "elevation": 4718,
      "lat": 44.7611389,
      "lon": -115.1073611,
      "tz": "America\/Boise"
  },
  "KS83": {
      "icao": "KS83",
      "iata": "",
      "name": "Shoshone County Airport",
      "city": "Kellogg",
      "state": "Idaho",
      "country": "US",
      "elevation": 2223,
      "lat": 47.5476989746,
      "lon": -116.18800354,
      "tz": "America\/Los_Angeles"
  },
  "KS84": {
      "icao": "KS84",
      "iata": "",
      "name": "Cottonwood Municipal Airport",
      "city": "Cottonwood",
      "state": "Idaho",
      "country": "US",
      "elevation": 3474,
      "lat": 46.0387992859,
      "lon": -116.3320007324,
      "tz": "America\/Los_Angeles"
  },
  "KS85": {
      "icao": "KS85",
      "iata": "",
      "name": "Big Sky Field",
      "city": "Culbertson",
      "state": "Montana",
      "country": "US",
      "elevation": 1954,
      "lat": 48.153301239,
      "lon": -104.5039978027,
      "tz": "America\/Denver"
  },
  "KS87": {
      "icao": "KS87",
      "iata": "",
      "name": "Weiser Municipal Airport",
      "city": "Weiser",
      "state": "Idaho",
      "country": "US",
      "elevation": 2120,
      "lat": 44.20679855,
      "lon": -116.961998,
      "tz": "America\/Boise"
  },
  "KS94": {
      "icao": "KS94",
      "iata": "",
      "name": "Port of Whitman Business Air Center Airport",
      "city": "Colfax",
      "state": "Washington",
      "country": "US",
      "elevation": 2181,
      "lat": 46.8586997986,
      "lon": -117.4140014648,
      "tz": "America\/Los_Angeles"
  },
  "KS95": {
      "icao": "KS95",
      "iata": "",
      "name": "Martin Field",
      "city": "College Place",
      "state": "Washington",
      "country": "US",
      "elevation": 746,
      "lat": 46.0469017029,
      "lon": -118.4169998169,
      "tz": "America\/Los_Angeles"
  },
  "KS97": {
      "icao": "KS97",
      "iata": "",
      "name": "Anderson Field",
      "city": "Brewster",
      "state": "Washington",
      "country": "US",
      "elevation": 914,
      "lat": 48.1049003601,
      "lon": -119.7210006714,
      "tz": "America\/Los_Angeles"
  },
  "KS98": {
      "icao": "KS98",
      "iata": "VSK",
      "name": "Vista Field",
      "city": "Kennewick",
      "state": "Washington",
      "country": "US",
      "elevation": 534,
      "lat": 46.2186012268,
      "lon": -119.209999084,
      "tz": "America\/Los_Angeles"
  },
  "KSAA": {
      "icao": "KSAA",
      "iata": "SAA",
      "name": "Shively Field",
      "city": "Saratoga",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7012,
      "lat": 41.4449005127,
      "lon": -106.8239974976,
      "tz": "America\/Denver"
  },
  "KSAC": {
      "icao": "KSAC",
      "iata": "SAC",
      "name": "Sacramento Executive Airport",
      "city": "Sacramento",
      "state": "California",
      "country": "US",
      "elevation": 24,
      "lat": 38.5125007629,
      "lon": -121.4929962158,
      "tz": "America\/Los_Angeles"
  },
  "KSAD": {
      "icao": "KSAD",
      "iata": "SAD",
      "name": "Safford Regional Airport",
      "city": "Safford",
      "state": "Arizona",
      "country": "US",
      "elevation": 3179,
      "lat": 32.85480118,
      "lon": -109.6350021,
      "tz": "America\/Phoenix"
  },
  "KSAF": {
      "icao": "KSAF",
      "iata": "SAF",
      "name": "Santa Fe Municipal Airport",
      "city": "Santa Fe",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6348,
      "lat": 35.617099762,
      "lon": -106.088996887,
      "tz": "America\/Denver"
  },
  "KSAN": {
      "icao": "KSAN",
      "iata": "SAN",
      "name": "San Diego International Airport",
      "city": "San Diego",
      "state": "California",
      "country": "US",
      "elevation": 17,
      "lat": 32.7336006165,
      "lon": -117.1900024414,
      "tz": "America\/Los_Angeles"
  },
  "KSAR": {
      "icao": "KSAR",
      "iata": "SAR",
      "name": "Sparta Community Hunter Field",
      "city": "Sparta",
      "state": "Illinois",
      "country": "US",
      "elevation": 538,
      "lat": 38.1488990784,
      "lon": -89.6986999512,
      "tz": "America\/Chicago"
  },
  "KSAS": {
      "icao": "KSAS",
      "iata": "SAS",
      "name": "Salton Sea Airport",
      "city": "Salton City",
      "state": "California",
      "country": "US",
      "elevation": -84,
      "lat": 33.2414016724,
      "lon": -115.952003479,
      "tz": "America\/Los_Angeles"
  },
  "KSAT": {
      "icao": "KSAT",
      "iata": "SAT",
      "name": "San Antonio International Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 809,
      "lat": 29.533700943,
      "lon": -98.4698028564,
      "tz": "America\/Chicago"
  },
  "KSAV": {
      "icao": "KSAV",
      "iata": "SAV",
      "name": "Savannah Hilton Head International Airport",
      "city": "Savannah",
      "state": "Georgia",
      "country": "US",
      "elevation": 50,
      "lat": 32.12760162,
      "lon": -81.20210266,
      "tz": "America\/New_York"
  },
  "KSAW": {
      "icao": "KSAW",
      "iata": "MQT",
      "name": "Sawyer International Airport",
      "city": "Marquette",
      "state": "Michigan",
      "country": "US",
      "elevation": 1221,
      "lat": 46.3535995483,
      "lon": -87.395401001,
      "tz": "America\/Detroit"
  },
  "KSAZ": {
      "icao": "KSAZ",
      "iata": "",
      "name": "Staples Municipal Airport",
      "city": "Staples",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1287,
      "lat": 46.3809013367,
      "lon": -94.806602478,
      "tz": "America\/Chicago"
  },
  "KSBA": {
      "icao": "KSBA",
      "iata": "SBA",
      "name": "Santa Barbara Municipal Airport",
      "city": "Santa Barbara",
      "state": "California",
      "country": "US",
      "elevation": 13,
      "lat": 34.42620087,
      "lon": -119.8399963,
      "tz": "America\/Los_Angeles"
  },
  "KSBD": {
      "icao": "KSBD",
      "iata": "SBD",
      "name": "San Bernardino International Airport",
      "city": "San Bernardino",
      "state": "California",
      "country": "US",
      "elevation": 1159,
      "lat": 34.0954017639,
      "lon": -117.2350006104,
      "tz": "America\/Los_Angeles"
  },
  "KSBM": {
      "icao": "KSBM",
      "iata": "SBM",
      "name": "Sheboygan County Memorial Airport",
      "city": "Sheboygan",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 755,
      "lat": 43.76959991,
      "lon": -87.85140228,
      "tz": "America\/Chicago"
  },
  "KSBN": {
      "icao": "KSBN",
      "iata": "SBN",
      "name": "South Bend Regional Airport",
      "city": "South Bend",
      "state": "Indiana",
      "country": "US",
      "elevation": 799,
      "lat": 41.7086982727,
      "lon": -86.3172988892,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KSBO": {
      "icao": "KSBO",
      "iata": "",
      "name": "Emanuel County Airport",
      "city": "Swainsboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 327,
      "lat": 32.6091003418,
      "lon": -82.3699035645,
      "tz": "America\/New_York"
  },
  "KSBP": {
      "icao": "KSBP",
      "iata": "SBP",
      "name": "San Luis County Regional Airport",
      "city": "San Luis Obispo",
      "state": "California",
      "country": "US",
      "elevation": 212,
      "lat": 35.2368011475,
      "lon": -120.641998291,
      "tz": "America\/Los_Angeles"
  },
  "KSBS": {
      "icao": "KSBS",
      "iata": "SBS",
      "name": "Steamboat Springs Bob Adams Field",
      "city": "Steamboat Springs",
      "state": "Colorado",
      "country": "US",
      "elevation": 6882,
      "lat": 40.5163002,
      "lon": -106.8659973,
      "tz": "America\/Denver"
  },
  "KSBU": {
      "icao": "KSBU",
      "iata": "",
      "name": "Blue Earth Municipal Airport",
      "city": "Blue Earth",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1107,
      "lat": 43.5952987671,
      "lon": -94.0927963257,
      "tz": "America\/Chicago"
  },
  "KSBX": {
      "icao": "KSBX",
      "iata": "SBX",
      "name": "Shelby Airport",
      "city": "Shelby",
      "state": "Montana",
      "country": "US",
      "elevation": 3443,
      "lat": 48.5406990051,
      "lon": -111.8710021973,
      "tz": "America\/Denver"
  },
  "KSBY": {
      "icao": "KSBY",
      "iata": "SBY",
      "name": "Salisbury Ocean City Wicomico Regional Airport",
      "city": "Salisbury",
      "state": "Maryland",
      "country": "US",
      "elevation": 52,
      "lat": 38.3404998779,
      "lon": -75.5102996826,
      "tz": "America\/New_York"
  },
  "KSCB": {
      "icao": "KSCB",
      "iata": "SCB",
      "name": "Scribner State Airport",
      "city": "Scribner",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1325,
      "lat": 41.6102981567,
      "lon": -96.6298980713,
      "tz": "America\/Chicago"
  },
  "KSCD": {
      "icao": "KSCD",
      "iata": "",
      "name": "Merkel Field Sylacauga Municipal Airport",
      "city": "Sylacauga",
      "state": "Alabama",
      "country": "US",
      "elevation": 569,
      "lat": 33.1717987061,
      "lon": -86.3054962158,
      "tz": "America\/Chicago"
  },
  "KSCH": {
      "icao": "KSCH",
      "iata": "SCH",
      "name": "Schenectady County Airport",
      "city": "Schenectady",
      "state": "New-York",
      "country": "US",
      "elevation": 378,
      "lat": 42.8525009155,
      "lon": -73.9289016724,
      "tz": "America\/New_York"
  },
  "KSCK": {
      "icao": "KSCK",
      "iata": "SCK",
      "name": "Stockton Metropolitan Airport",
      "city": "Stockton",
      "state": "California",
      "country": "US",
      "elevation": 33,
      "lat": 37.8941993713,
      "lon": -121.2379989624,
      "tz": "America\/Los_Angeles"
  },
  "KSCX": {
      "icao": "KSCX",
      "iata": "",
      "name": "Scott Municipal Airport",
      "city": "Oneida",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1545,
      "lat": 36.4556007385,
      "lon": -84.5857009888,
      "tz": "America\/New_York"
  },
  "KSDA": {
      "icao": "KSDA",
      "iata": "",
      "name": "Shenandoah Municipal Airport",
      "city": "Shenandoah",
      "state": "Iowa",
      "country": "US",
      "elevation": 971,
      "lat": 40.7515983582,
      "lon": -95.4136962891,
      "tz": "America\/Chicago"
  },
  "KSDC": {
      "icao": "KSDC",
      "iata": "",
      "name": "Williamson Sodus Airport",
      "city": "Williamson\/Sodus",
      "state": "New-York",
      "country": "US",
      "elevation": 424,
      "lat": 43.23469925,
      "lon": -77.1210022,
      "tz": "America\/New_York"
  },
  "KSDF": {
      "icao": "KSDF",
      "iata": "SDF",
      "name": "Louisville International Standiford Field",
      "city": "Louisville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 501,
      "lat": 38.1744003296,
      "lon": -85.736000061,
      "tz": "America\/Kentucky\/Louisville"
  },
  "KSDL": {
      "icao": "KSDL",
      "iata": "SCF",
      "name": "Scottsdale Airport",
      "city": "Scottsdale",
      "state": "Arizona",
      "country": "US",
      "elevation": 1510,
      "lat": 33.6228981018,
      "lon": -111.9110031128,
      "tz": "America\/Phoenix"
  },
  "KSDM": {
      "icao": "KSDM",
      "iata": "SDM",
      "name": "Brown Field Municipal Airport",
      "city": "San Diego",
      "state": "California",
      "country": "US",
      "elevation": 526,
      "lat": 32.5722999573,
      "lon": -116.9800033569,
      "tz": "America\/Los_Angeles"
  },
  "KSDY": {
      "icao": "KSDY",
      "iata": "SDY",
      "name": "Sidney Richland Municipal Airport",
      "city": "Sidney",
      "state": "Montana",
      "country": "US",
      "elevation": 1985,
      "lat": 47.70690155,
      "lon": -104.1930008,
      "tz": "America\/Denver"
  },
  "KSEA": {
      "icao": "KSEA",
      "iata": "SEA",
      "name": "Seattle Tacoma International Airport",
      "city": "Seattle",
      "state": "Washington",
      "country": "US",
      "elevation": 433,
      "lat": 47.4490013123,
      "lon": -122.3089981079,
      "tz": "America\/Los_Angeles"
  },
  "KSEE": {
      "icao": "KSEE",
      "iata": "SEE",
      "name": "Gillespie Field",
      "city": "San Diego\/El Cajon",
      "state": "California",
      "country": "US",
      "elevation": 388,
      "lat": 32.8261985779,
      "lon": -116.9720001221,
      "tz": "America\/Los_Angeles"
  },
  "KSEF": {
      "icao": "KSEF",
      "iata": "SEF",
      "name": "Sebring Regional Airport",
      "city": "Sebring",
      "state": "Florida",
      "country": "US",
      "elevation": 62,
      "lat": 27.45639992,
      "lon": -81.3423996,
      "tz": "America\/New_York"
  },
  "KSEG": {
      "icao": "KSEG",
      "iata": "SEG",
      "name": "Penn Valley Airport",
      "city": "Selinsgrove",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 450,
      "lat": 40.8205986023,
      "lon": -76.863899231,
      "tz": "America\/New_York"
  },
  "KSEM": {
      "icao": "KSEM",
      "iata": "SEM",
      "name": "Craig Field",
      "city": "Selma",
      "state": "Alabama",
      "country": "US",
      "elevation": 166,
      "lat": 32.3438987732,
      "lon": -86.9878005981,
      "tz": "America\/Chicago"
  },
  "KSEP": {
      "icao": "KSEP",
      "iata": "SEP",
      "name": "Clark Field Municipal Airport",
      "city": "Stephenville",
      "state": "Texas",
      "country": "US",
      "elevation": 1321,
      "lat": 32.2153015137,
      "lon": -98.177696228,
      "tz": "America\/Chicago"
  },
  "KSEQ": {
      "icao": "KSEQ",
      "iata": "",
      "name": "Randolph Air Force Base Auxiliary Airport",
      "city": "Seguin",
      "state": "Texas",
      "country": "US",
      "elevation": 525,
      "lat": 29.56579971,
      "lon": -97.90830231,
      "tz": "America\/Chicago"
  },
  "KSER": {
      "icao": "KSER",
      "iata": "SER",
      "name": "Freeman Municipal Airport",
      "city": "Seymour",
      "state": "Indiana",
      "country": "US",
      "elevation": 583,
      "lat": 38.9235992432,
      "lon": -85.9074020386,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KSET": {
      "icao": "KSET",
      "iata": "",
      "name": "St Charles County Smartt Airport",
      "city": "St Charles",
      "state": "Missouri",
      "country": "US",
      "elevation": 437,
      "lat": 38.92969894,
      "lon": -90.43000031,
      "tz": "America\/Chicago"
  },
  "KSEZ": {
      "icao": "KSEZ",
      "iata": "SDX",
      "name": "Sedona Airport",
      "city": "Sedona",
      "state": "Arizona",
      "country": "US",
      "elevation": 4830,
      "lat": 34.8485984802,
      "lon": -111.7880020142,
      "tz": "America\/Phoenix"
  },
  "KSFB": {
      "icao": "KSFB",
      "iata": "SFB",
      "name": "Orlando Sanford International Airport",
      "city": "Orlando",
      "state": "Florida",
      "country": "US",
      "elevation": 55,
      "lat": 28.7775993347,
      "lon": -81.2375030518,
      "tz": "America\/New_York"
  },
  "KSFF": {
      "icao": "KSFF",
      "iata": "SFF",
      "name": "Felts Field",
      "city": "Spokane",
      "state": "Oregon",
      "country": "US",
      "elevation": 1953,
      "lat": 47.682800293,
      "lon": -117.3229980469,
      "tz": "America\/Los_Angeles"
  },
  "KSFM": {
      "icao": "KSFM",
      "iata": "SFM",
      "name": "Sanford Regional Airport",
      "city": "Sanford",
      "state": "Maine",
      "country": "US",
      "elevation": 244,
      "lat": 43.393901825,
      "lon": -70.7080001831,
      "tz": "America\/New_York"
  },
  "KSFO": {
      "icao": "KSFO",
      "iata": "SFO",
      "name": "San Francisco International Airport",
      "city": "San Francisco",
      "state": "California",
      "country": "US",
      "elevation": 13,
      "lat": 37.6189994812,
      "lon": -122.375,
      "tz": "America\/Los_Angeles"
  },
  "KSFQ": {
      "icao": "KSFQ",
      "iata": "",
      "name": "Suffolk Executive Airport",
      "city": "Suffolk",
      "state": "Virginia",
      "country": "US",
      "elevation": 72,
      "lat": 36.6823997498,
      "lon": -76.6018981934,
      "tz": "America\/New_York"
  },
  "KSFY": {
      "icao": "KSFY",
      "iata": "",
      "name": "Tri Township Airport",
      "city": "Savanna",
      "state": "Illinois",
      "country": "US",
      "elevation": 616,
      "lat": 42.0457992554,
      "lon": -90.1079025269,
      "tz": "America\/Chicago"
  },
  "KSFZ": {
      "icao": "KSFZ",
      "iata": "SFZ",
      "name": "North Central State Airport",
      "city": "Pawtucket",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 441,
      "lat": 41.9207992554,
      "lon": -71.4914016724,
      "tz": "America\/New_York"
  },
  "KSGF": {
      "icao": "KSGF",
      "iata": "SGF",
      "name": "Springfield Branson National Airport",
      "city": "Springfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1268,
      "lat": 37.24570084,
      "lon": -93.38860321,
      "tz": "America\/Chicago"
  },
  "KSGH": {
      "icao": "KSGH",
      "iata": "SGH",
      "name": "Springfield Beckley Municipal Airport",
      "city": "Springfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1051,
      "lat": 39.8403015137,
      "lon": -83.8402023315,
      "tz": "America\/New_York"
  },
  "KSGJ": {
      "icao": "KSGJ",
      "iata": "UST",
      "name": "St Augustine Airport",
      "city": "St Augustine",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 29.9591999054,
      "lon": -81.3397979736,
      "tz": "America\/New_York"
  },
  "KSGR": {
      "icao": "KSGR",
      "iata": "SGR",
      "name": "Sugar Land Regional Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 82,
      "lat": 29.6222991943,
      "lon": -95.65650177,
      "tz": "America\/Chicago"
  },
  "KSGS": {
      "icao": "KSGS",
      "iata": "",
      "name": "South St Paul Municipal Richard E Fleming field",
      "city": "South St Paul",
      "state": "Minnesota",
      "country": "US",
      "elevation": 821,
      "lat": 44.85710144,
      "lon": -93.03289795,
      "tz": "America\/Chicago"
  },
  "KSGT": {
      "icao": "KSGT",
      "iata": "SGT",
      "name": "Stuttgart Municipal Airport",
      "city": "Stuttgart",
      "state": "Arkansas",
      "country": "US",
      "elevation": 224,
      "lat": 34.5994987488,
      "lon": -91.5749969482,
      "tz": "America\/Chicago"
  },
  "KSGU": {
      "icao": "KSGU",
      "iata": "SGU",
      "name": "St George Municipal Airport",
      "city": "St George",
      "state": "Utah",
      "country": "US",
      "elevation": 2941,
      "lat": 37.0363888889,
      "lon": -113.510305556,
      "tz": "America\/Denver"
  },
  "KSHD": {
      "icao": "KSHD",
      "iata": "SHD",
      "name": "Shenandoah Valley Regional Airport",
      "city": "Staunton\/Waynesboro\/Harrisonburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 1201,
      "lat": 38.2638015747,
      "lon": -78.8964004517,
      "tz": "America\/New_York"
  },
  "KSHL": {
      "icao": "KSHL",
      "iata": "",
      "name": "Sheldon Municipal Airport",
      "city": "Sheldon",
      "state": "Iowa",
      "country": "US",
      "elevation": 1419,
      "lat": 43.2084007263,
      "lon": -95.8333969116,
      "tz": "America\/Chicago"
  },
  "KSHN": {
      "icao": "KSHN",
      "iata": "SHN",
      "name": "Sanderson Field",
      "city": "Shelton",
      "state": "Washington",
      "country": "US",
      "elevation": 273,
      "lat": 47.2336006165,
      "lon": -123.1480026245,
      "tz": "America\/Los_Angeles"
  },
  "KSHR": {
      "icao": "KSHR",
      "iata": "SHR",
      "name": "Sheridan County Airport",
      "city": "Sheridan",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4021,
      "lat": 44.7691993713,
      "lon": -106.9800033569,
      "tz": "America\/Denver"
  },
  "KSHV": {
      "icao": "KSHV",
      "iata": "SHV",
      "name": "Shreveport Regional Airport",
      "city": "Shreveport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 258,
      "lat": 32.4466018677,
      "lon": -93.8255996704,
      "tz": "America\/Chicago"
  },
  "KSIF": {
      "icao": "KSIF",
      "iata": "",
      "name": "Rockingham County Nc Shiloh Airport",
      "city": "Reidsville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 694,
      "lat": 36.43719864,
      "lon": -79.85099792,
      "tz": "America\/New_York"
  },
  "KSIK": {
      "icao": "KSIK",
      "iata": "SIK",
      "name": "Sikeston Memorial Municipal Airport",
      "city": "Sikeston",
      "state": "Missouri",
      "country": "US",
      "elevation": 315,
      "lat": 36.8988990784,
      "lon": -89.5617980957,
      "tz": "America\/Chicago"
  },
  "KSIV": {
      "icao": "KSIV",
      "iata": "SIV",
      "name": "Sullivan County Airport",
      "city": "Sullivan",
      "state": "Indiana",
      "country": "US",
      "elevation": 540,
      "lat": 39.1147003174,
      "lon": -87.4483032227,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KSIY": {
      "icao": "KSIY",
      "iata": "SIY",
      "name": "Siskiyou County Airport",
      "city": "Montague",
      "state": "California",
      "country": "US",
      "elevation": 2648,
      "lat": 41.7813987732,
      "lon": -122.4680023193,
      "tz": "America\/Los_Angeles"
  },
  "KSJC": {
      "icao": "KSJC",
      "iata": "SJC",
      "name": "Norman Y. Mineta San Jose International Airport",
      "city": "San Jose",
      "state": "California",
      "country": "US",
      "elevation": 62,
      "lat": 37.3625984192,
      "lon": -121.9290008545,
      "tz": "America\/Los_Angeles"
  },
  "KSJN": {
      "icao": "KSJN",
      "iata": "SJN",
      "name": "St Johns Industrial Air Park",
      "city": "St Johns",
      "state": "Arizona",
      "country": "US",
      "elevation": 5737,
      "lat": 34.51860046,
      "lon": -109.3789978,
      "tz": "America\/Phoenix"
  },
  "KSJT": {
      "icao": "KSJT",
      "iata": "SJT",
      "name": "San Angelo Regional Mathis Field",
      "city": "San Angelo",
      "state": "Texas",
      "country": "US",
      "elevation": 1919,
      "lat": 31.3577003479,
      "lon": -100.4960021973,
      "tz": "America\/Chicago"
  },
  "KSJX": {
      "icao": "KSJX",
      "iata": "",
      "name": "Beaver Island Airport",
      "city": "Beaver Island",
      "state": "Michigan",
      "country": "US",
      "elevation": 669,
      "lat": 45.6922988892,
      "lon": -85.5665969849,
      "tz": "America\/Detroit"
  },
  "KSKA": {
      "icao": "KSKA",
      "iata": "SKA",
      "name": "Fairchild Air Force Base",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2461,
      "lat": 47.6151008606,
      "lon": -117.65599823,
      "tz": "America\/Los_Angeles"
  },
  "KSKF": {
      "icao": "KSKF",
      "iata": "SKF",
      "name": "Lackland Air Force Base (Kelly Field Annex) Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 691,
      "lat": 29.38419914,
      "lon": -98.58110046,
      "tz": "America\/Chicago"
  },
  "KSKI": {
      "icao": "KSKI",
      "iata": "",
      "name": "Sac City Municipal Airport",
      "city": "Sac City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1250,
      "lat": 42.3791007996,
      "lon": -94.9796981812,
      "tz": "America\/Chicago"
  },
  "KSKX": {
      "icao": "KSKX",
      "iata": "TSM",
      "name": "Taos Regional Airport",
      "city": "Taos",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7095,
      "lat": 36.45819855,
      "lon": -105.6719971,
      "tz": "America\/Denver"
  },
  "KSKY": {
      "icao": "KSKY",
      "iata": "",
      "name": "Griffing Sandusky Airport",
      "city": "Sandusky",
      "state": "Ohio",
      "country": "US",
      "elevation": 580,
      "lat": 41.4333992004,
      "lon": -82.6522979736,
      "tz": "America\/New_York"
  },
  "KSLB": {
      "icao": "KSLB",
      "iata": "SLB",
      "name": "Storm Lake Municipal Airport",
      "city": "Storm Lake",
      "state": "Iowa",
      "country": "US",
      "elevation": 1488,
      "lat": 42.5973014832,
      "lon": -95.2406997681,
      "tz": "America\/Chicago"
  },
  "KSLC": {
      "icao": "KSLC",
      "iata": "SLC",
      "name": "Salt Lake City International Airport",
      "city": "Salt Lake City",
      "state": "Utah",
      "country": "US",
      "elevation": 4227,
      "lat": 40.7883987427,
      "lon": -111.9779968262,
      "tz": "America\/Denver"
  },
  "KSLE": {
      "icao": "KSLE",
      "iata": "SLE",
      "name": "McNary Field",
      "city": "Salem",
      "state": "Oregon",
      "country": "US",
      "elevation": 214,
      "lat": 44.90950012,
      "lon": -123.0029984,
      "tz": "America\/Los_Angeles"
  },
  "KSLG": {
      "icao": "KSLG",
      "iata": "SLG",
      "name": "Smith Field",
      "city": "Siloam Springs",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1191,
      "lat": 36.19189835,
      "lon": -94.48999786,
      "tz": "America\/Chicago"
  },
  "KSLH": {
      "icao": "KSLH",
      "iata": "",
      "name": "Cheboygan County Airport",
      "city": "Cheboygan",
      "state": "Michigan",
      "country": "US",
      "elevation": 639,
      "lat": 45.65370178,
      "lon": -84.51930237,
      "tz": "America\/Detroit"
  },
  "KSLI": {
      "icao": "KSLI",
      "iata": "",
      "name": "Los Alamitos Army Air Field",
      "city": "Los Alamitos",
      "state": "California",
      "country": "US",
      "elevation": 32,
      "lat": 33.79000092,
      "lon": -118.052002,
      "tz": "America\/Los_Angeles"
  },
  "KSLJ": {
      "icao": "KSLJ",
      "iata": "",
      "name": "Hagler Army Air Field",
      "city": "Camp Shelby",
      "state": "Mississippi",
      "country": "US",
      "elevation": 280,
      "lat": 31.17379951,
      "lon": -89.19120026,
      "tz": "America\/Chicago"
  },
  "KSLK": {
      "icao": "KSLK",
      "iata": "SLK",
      "name": "Adirondack Regional Airport",
      "city": "Saranac Lake",
      "state": "New-York",
      "country": "US",
      "elevation": 1663,
      "lat": 44.3852996826,
      "lon": -74.206199646,
      "tz": "America\/New_York"
  },
  "KSLN": {
      "icao": "KSLN",
      "iata": "SLN",
      "name": "Salina Municipal Airport",
      "city": "Salina",
      "state": "Kansas",
      "country": "US",
      "elevation": 1288,
      "lat": 38.7910003662,
      "lon": -97.6521987915,
      "tz": "America\/Chicago"
  },
  "KSLO": {
      "icao": "KSLO",
      "iata": "SLO",
      "name": "Salem Leckrone Airport",
      "city": "Salem",
      "state": "Illinois",
      "country": "US",
      "elevation": 573,
      "lat": 38.6428985596,
      "lon": -88.9642028809,
      "tz": "America\/Chicago"
  },
  "KSLR": {
      "icao": "KSLR",
      "iata": "SLR",
      "name": "Sulphur Springs Municipal Airport",
      "city": "Sulphur Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 489,
      "lat": 33.1598014832,
      "lon": -95.6211013794,
      "tz": "America\/Chicago"
  },
  "KSMD": {
      "icao": "KSMD",
      "iata": "SMD",
      "name": "Smith Field",
      "city": "Fort Wayne",
      "state": "Arkansas",
      "country": "US",
      "elevation": 835,
      "lat": 41.14339828,
      "lon": -85.15280151,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KSME": {
      "icao": "KSME",
      "iata": "SME",
      "name": "Lake Cumberland Regional Airport",
      "city": "Somerset",
      "state": "Kentucky",
      "country": "US",
      "elevation": 927,
      "lat": 37.0533981323,
      "lon": -84.6158981323,
      "tz": "America\/New_York"
  },
  "KSMF": {
      "icao": "KSMF",
      "iata": "SMF",
      "name": "Sacramento International Airport",
      "city": "Sacramento",
      "state": "California",
      "country": "US",
      "elevation": 27,
      "lat": 38.695400238,
      "lon": -121.591003418,
      "tz": "America\/Los_Angeles"
  },
  "KSMN": {
      "icao": "KSMN",
      "iata": "SMN",
      "name": "Lemhi County Airport",
      "city": "Salmon",
      "state": "Idaho",
      "country": "US",
      "elevation": 4043,
      "lat": 45.1237983704,
      "lon": -113.8809967041,
      "tz": "America\/Boise"
  },
  "KSMO": {
      "icao": "KSMO",
      "iata": "SMO",
      "name": "Santa Monica Municipal Airport",
      "city": "Santa Monica",
      "state": "California",
      "country": "US",
      "elevation": 177,
      "lat": 34.0158004761,
      "lon": -118.4509963989,
      "tz": "America\/Los_Angeles"
  },
  "KSMQ": {
      "icao": "KSMQ",
      "iata": "",
      "name": "Somerset Airport",
      "city": "Somerville",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 105,
      "lat": 40.6259994507,
      "lon": -74.6701965332,
      "tz": "America\/New_York"
  },
  "KSMS": {
      "icao": "KSMS",
      "iata": "SUM",
      "name": "Sumter Airport",
      "city": "Sumter",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 182,
      "lat": 33.9949989319,
      "lon": -80.3612976074,
      "tz": "America\/New_York"
  },
  "KSMX": {
      "icao": "KSMX",
      "iata": "SMX",
      "name": "Santa Maria Pub\/Capt G Allan Hancock Field",
      "city": "Santa Maria",
      "state": "California",
      "country": "US",
      "elevation": 261,
      "lat": 34.89889908,
      "lon": -120.4570007,
      "tz": "America\/Los_Angeles"
  },
  "KSNA": {
      "icao": "KSNA",
      "iata": "SNA",
      "name": "John Wayne Airport-Orange County Airport",
      "city": "Santa Ana",
      "state": "California",
      "country": "US",
      "elevation": 56,
      "lat": 33.67570114,
      "lon": -117.8679962,
      "tz": "America\/Los_Angeles"
  },
  "KSNC": {
      "icao": "KSNC",
      "iata": "",
      "name": "Chester Airport",
      "city": "Chester",
      "state": "Connecticut",
      "country": "US",
      "elevation": 416,
      "lat": 41.3838996887,
      "lon": -72.505897522,
      "tz": "America\/New_York"
  },
  "KSNH": {
      "icao": "KSNH",
      "iata": "",
      "name": "Savannah Hardin County Airport",
      "city": "Savannah",
      "state": "Tennessee",
      "country": "US",
      "elevation": 473,
      "lat": 35.1703987122,
      "lon": -88.2158966064,
      "tz": "America\/Chicago"
  },
  "KSNK": {
      "icao": "KSNK",
      "iata": "SNK",
      "name": "Winston Field",
      "city": "Snyder",
      "state": "Texas",
      "country": "US",
      "elevation": 2430,
      "lat": 32.6934013367,
      "lon": -100.9499969482,
      "tz": "America\/Chicago"
  },
  "KSNL": {
      "icao": "KSNL",
      "iata": "SNL",
      "name": "Shawnee Regional Airport",
      "city": "Shawnee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1073,
      "lat": 35.3578987122,
      "lon": -96.9428024292,
      "tz": "America\/Chicago"
  },
  "KSNS": {
      "icao": "KSNS",
      "iata": "SNS",
      "name": "Salinas Municipal Airport",
      "city": "Salinas",
      "state": "California",
      "country": "US",
      "elevation": 85,
      "lat": 36.6627998352,
      "lon": -121.6060028076,
      "tz": "America\/Los_Angeles"
  },
  "KSNY": {
      "icao": "KSNY",
      "iata": "SNY",
      "name": "Sidney Municipal-Lloyd W Carr Field",
      "city": "Sidney",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4313,
      "lat": 41.10129929,
      "lon": -102.9850006,
      "tz": "America\/Denver"
  },
  "KSOA": {
      "icao": "KSOA",
      "iata": "",
      "name": "Sonora Municipal Airport",
      "city": "Sonora",
      "state": "Texas",
      "country": "US",
      "elevation": 2140,
      "lat": 30.5856990814,
      "lon": -100.6490020752,
      "tz": "America\/Chicago"
  },
  "KSOP": {
      "icao": "KSOP",
      "iata": "SOP",
      "name": "Moore County Airport",
      "city": "Pinehurst\/Southern Pines",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 455,
      "lat": 35.23740005,
      "lon": -79.3911972,
      "tz": "America\/New_York"
  },
  "KSOW": {
      "icao": "KSOW",
      "iata": "SOW",
      "name": "Show Low Regional Airport",
      "city": "Show Low",
      "state": "Arizona",
      "country": "US",
      "elevation": 6415,
      "lat": 34.265499115,
      "lon": -110.005996704,
      "tz": "America\/Phoenix"
  },
  "KSOY": {
      "icao": "KSOY",
      "iata": "",
      "name": "Sioux Center Municipal Airport",
      "city": "Sioux Center",
      "state": "Iowa",
      "country": "US",
      "elevation": 1448,
      "lat": 43.1343994141,
      "lon": -96.1875,
      "tz": "America\/Chicago"
  },
  "KSPA": {
      "icao": "KSPA",
      "iata": "SPA",
      "name": "Spartanburg Downtown Memorial Airport",
      "city": "Spartanburg",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 801,
      "lat": 34.9156990051,
      "lon": -81.9564971924,
      "tz": "America\/New_York"
  },
  "KSPB": {
      "icao": "KSPB",
      "iata": "",
      "name": "Scappoose Industrial Airpark",
      "city": "Scappoose",
      "state": "Oregon",
      "country": "US",
      "elevation": 58,
      "lat": 45.7709999084,
      "lon": -122.8619995117,
      "tz": "America\/Los_Angeles"
  },
  "KSPF": {
      "icao": "KSPF",
      "iata": "SPF",
      "name": "Black Hills Clyde Ice Field",
      "city": "Spearfish",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3931,
      "lat": 44.4803009033,
      "lon": -103.7829971313,
      "tz": "America\/Denver"
  },
  "KSPG": {
      "icao": "KSPG",
      "iata": "SPG",
      "name": "Albert Whitted Airport",
      "city": "St Petersburg",
      "state": "Florida",
      "country": "US",
      "elevation": 7,
      "lat": 27.7651004791,
      "lon": -82.6269989014,
      "tz": "America\/New_York"
  },
  "KSPH": {
      "icao": "KSPH",
      "iata": "",
      "name": "Springhill Airport",
      "city": "Springhill",
      "state": "Louisiana",
      "country": "US",
      "elevation": 218,
      "lat": 32.9833984375,
      "lon": -93.4092025757,
      "tz": "America\/Chicago"
  },
  "KSPI": {
      "icao": "KSPI",
      "iata": "SPI",
      "name": "Abraham Lincoln Capital Airport",
      "city": "Springfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 598,
      "lat": 39.84410095,
      "lon": -89.67790222,
      "tz": "America\/Chicago"
  },
  "KSPS": {
      "icao": "KSPS",
      "iata": "SPS",
      "name": "Sheppard Air Force Base-Wichita Falls Municipal Airport",
      "city": "Wichita Falls",
      "state": "Texas",
      "country": "US",
      "elevation": 1019,
      "lat": 33.98880005,
      "lon": -98.49189758,
      "tz": "America\/Chicago"
  },
  "KSPW": {
      "icao": "KSPW",
      "iata": "SPW",
      "name": "Spencer Municipal Airport",
      "city": "Spencer",
      "state": "Iowa",
      "country": "US",
      "elevation": 1339,
      "lat": 43.1655006409,
      "lon": -95.202796936,
      "tz": "America\/Chicago"
  },
  "KSPZ": {
      "icao": "KSPZ",
      "iata": "",
      "name": "Silver Springs Airport",
      "city": "Silver Springs",
      "state": "Nevada",
      "country": "US",
      "elevation": 4269,
      "lat": 39.4029998779,
      "lon": -119.2509994507,
      "tz": "America\/Los_Angeles"
  },
  "KSQI": {
      "icao": "KSQI",
      "iata": "SQI",
      "name": "Whiteside Co Arpt-Jos H Bittorf field",
      "city": "Sterling\/Rockfalls",
      "state": "Illinois",
      "country": "US",
      "elevation": 648,
      "lat": 41.74280167,
      "lon": -89.67630005,
      "tz": "America\/Chicago"
  },
  "KSQL": {
      "icao": "KSQL",
      "iata": "SQL",
      "name": "San Carlos Airport",
      "city": "San Carlos",
      "state": "California",
      "country": "US",
      "elevation": 5,
      "lat": 37.5119018555,
      "lon": -122.25,
      "tz": "America\/Los_Angeles"
  },
  "KSRB": {
      "icao": "KSRB",
      "iata": "",
      "name": "Upper Cumberland Regional Airport",
      "city": "Sparta",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1025,
      "lat": 36.0559005737,
      "lon": -85.5307006836,
      "tz": "America\/Chicago"
  },
  "KSRC": {
      "icao": "KSRC",
      "iata": "SRC",
      "name": "Searcy Municipal Airport",
      "city": "Searcy",
      "state": "Arkansas",
      "country": "US",
      "elevation": 265,
      "lat": 35.21060181,
      "lon": -91.73750305,
      "tz": "America\/Chicago"
  },
  "KSRE": {
      "icao": "KSRE",
      "iata": "",
      "name": "Seminole Municipal Airport",
      "city": "Seminole",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1024,
      "lat": 35.2747001648,
      "lon": -96.675201416,
      "tz": "America\/Chicago"
  },
  "KSRQ": {
      "icao": "KSRQ",
      "iata": "SRQ",
      "name": "Sarasota Bradenton International Airport",
      "city": "Sarasota\/Bradenton",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 27.3953990936,
      "lon": -82.554397583,
      "tz": "America\/New_York"
  },
  "KSRR": {
      "icao": "KSRR",
      "iata": "RUI",
      "name": "Sierra Blanca Regional Airport",
      "city": "Ruidoso",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6814,
      "lat": 33.4627990723,
      "lon": -105.5350036621,
      "tz": "America\/Denver"
  },
  "KSSC": {
      "icao": "KSSC",
      "iata": "SSC",
      "name": "Shaw Air Force Base",
      "city": "Sumter",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 241,
      "lat": 33.97269821,
      "lon": -80.47059631,
      "tz": "America\/New_York"
  },
  "KSSF": {
      "icao": "KSSF",
      "iata": "SSF",
      "name": "Stinson Municipal Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 577,
      "lat": 29.3369998932,
      "lon": -98.4710998535,
      "tz": "America\/Chicago"
  },
  "KSSI": {
      "icao": "KSSI",
      "iata": "SSI",
      "name": "Malcolm Mc Kinnon Airport",
      "city": "Brunswick",
      "state": "Georgia",
      "country": "US",
      "elevation": 19,
      "lat": 31.15180016,
      "lon": -81.39129639,
      "tz": "America\/New_York"
  },
  "KSSQ": {
      "icao": "KSSQ",
      "iata": "",
      "name": "Shell Lake Municipal Airport",
      "city": "Shell Lake",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1233,
      "lat": 45.73139954,
      "lon": -91.92070007,
      "tz": "America\/Chicago"
  },
  "KSTC": {
      "icao": "KSTC",
      "iata": "STC",
      "name": "St Cloud Regional Airport",
      "city": "St Cloud",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1031,
      "lat": 45.5466003418,
      "lon": -94.0598983765,
      "tz": "America\/Chicago"
  },
  "KSTE": {
      "icao": "KSTE",
      "iata": "STE",
      "name": "Stevens Point Municipal Airport",
      "city": "Stevens Point",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1110,
      "lat": 44.5452003479,
      "lon": -89.5302963257,
      "tz": "America\/Chicago"
  },
  "KSTF": {
      "icao": "KSTF",
      "iata": "",
      "name": "George M Bryan Airport",
      "city": "Starkville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 333,
      "lat": 33.43310165,
      "lon": -88.84860229,
      "tz": "America\/Chicago"
  },
  "KSTJ": {
      "icao": "KSTJ",
      "iata": "STJ",
      "name": "Rosecrans Memorial Airport",
      "city": "St Joseph",
      "state": "Missouri",
      "country": "US",
      "elevation": 826,
      "lat": 39.771900177,
      "lon": -94.9096984863,
      "tz": "America\/Chicago"
  },
  "KSTK": {
      "icao": "KSTK",
      "iata": "STK",
      "name": "Sterling Municipal Airport",
      "city": "Sterling",
      "state": "Colorado",
      "country": "US",
      "elevation": 4040,
      "lat": 40.61529922,
      "lon": -103.2649994,
      "tz": "America\/Denver"
  },
  "KSTL": {
      "icao": "KSTL",
      "iata": "STL",
      "name": "Lambert St Louis International Airport",
      "city": "St Louis",
      "state": "Missouri",
      "country": "US",
      "elevation": 618,
      "lat": 38.7486991882,
      "lon": -90.3700027466,
      "tz": "America\/Chicago"
  },
  "KSTP": {
      "icao": "KSTP",
      "iata": "STP",
      "name": "St Paul Downtown Holman Field",
      "city": "St Paul",
      "state": "Minnesota",
      "country": "US",
      "elevation": 705,
      "lat": 44.9345016479,
      "lon": -93.0599975586,
      "tz": "America\/Chicago"
  },
  "KSTS": {
      "icao": "KSTS",
      "iata": "STS",
      "name": "Charles M. Schulz Sonoma County Airport",
      "city": "Santa Rosa",
      "state": "California",
      "country": "US",
      "elevation": 128,
      "lat": 38.50899887,
      "lon": -122.8130035,
      "tz": "America\/Los_Angeles"
  },
  "KSUA": {
      "icao": "KSUA",
      "iata": "SUA",
      "name": "Witham Field",
      "city": "Stuart",
      "state": "Florida",
      "country": "US",
      "elevation": 16,
      "lat": 27.18169975,
      "lon": -80.22109985,
      "tz": "America\/New_York"
  },
  "KSUD": {
      "icao": "KSUD",
      "iata": "SUD",
      "name": "Stroud Municipal Airport",
      "city": "Stroud",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 900,
      "lat": 35.7896003723,
      "lon": -96.6557006836,
      "tz": "America\/Chicago"
  },
  "KSUE": {
      "icao": "KSUE",
      "iata": "SUE",
      "name": "Door County Cherryland Airport",
      "city": "Sturgeon Bay",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 725,
      "lat": 44.84370041,
      "lon": -87.42150116,
      "tz": "America\/Chicago"
  },
  "KSUN": {
      "icao": "KSUN",
      "iata": "SUN",
      "name": "Friedman Memorial Airport",
      "city": "Hailey",
      "state": "Idaho",
      "country": "US",
      "elevation": 5318,
      "lat": 43.50439835,
      "lon": -114.2959976,
      "tz": "America\/Boise"
  },
  "KSUS": {
      "icao": "KSUS",
      "iata": "SUS",
      "name": "Spirit of St Louis Airport",
      "city": "St Louis",
      "state": "Missouri",
      "country": "US",
      "elevation": 463,
      "lat": 38.6621017456,
      "lon": -90.6520004272,
      "tz": "America\/Chicago"
  },
  "KSUT": {
      "icao": "KSUT",
      "iata": "",
      "name": "Brunswick County Airport",
      "city": "Oak Island",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 24,
      "lat": 33.9292984,
      "lon": -78.07499695,
      "tz": "America\/New_York"
  },
  "KSUU": {
      "icao": "KSUU",
      "iata": "SUU",
      "name": "Travis Air Force Base",
      "city": "Fairfield",
      "state": "California",
      "country": "US",
      "elevation": 62,
      "lat": 38.2626991272,
      "lon": -121.9270019531,
      "tz": "America\/Los_Angeles"
  },
  "KSUW": {
      "icao": "KSUW",
      "iata": "SUW",
      "name": "Richard I Bong Airport",
      "city": "Superior",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 674,
      "lat": 46.6897010803,
      "lon": -92.0947036743,
      "tz": "America\/Chicago"
  },
  "KSUX": {
      "icao": "KSUX",
      "iata": "SUX",
      "name": "Sioux Gateway Col. Bud Day Field",
      "city": "Sioux City",
      "state": "Iowa",
      "country": "US",
      "elevation": 1098,
      "lat": 42.40259933,
      "lon": -96.38439941,
      "tz": "America\/Chicago"
  },
  "KSUZ": {
      "icao": "KSUZ",
      "iata": "",
      "name": "Saline County Regional Airport",
      "city": "Benton",
      "state": "Arkansas",
      "country": "US",
      "elevation": 390,
      "lat": 34.59059906,
      "lon": -92.47940063,
      "tz": "America\/Chicago"
  },
  "KSVC": {
      "icao": "KSVC",
      "iata": "SVC",
      "name": "Grant County Airport",
      "city": "Silver City",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5446,
      "lat": 32.6365013123,
      "lon": -108.15599823,
      "tz": "America\/Denver"
  },
  "KSVE": {
      "icao": "KSVE",
      "iata": "SVE",
      "name": "Susanville Municipal Airport",
      "city": "Susanville",
      "state": "California",
      "country": "US",
      "elevation": 4149,
      "lat": 40.3757019043,
      "lon": -120.5729980469,
      "tz": "America\/Los_Angeles"
  },
  "KSVH": {
      "icao": "KSVH",
      "iata": "SVH",
      "name": "Statesville Regional Airport",
      "city": "Statesville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 968,
      "lat": 35.7653007507,
      "lon": -80.9539031982,
      "tz": "America\/New_York"
  },
  "KSVN": {
      "icao": "KSVN",
      "iata": "SVN",
      "name": "Hunter Army Air Field",
      "city": "Savannah",
      "state": "Georgia",
      "country": "US",
      "elevation": 41,
      "lat": 32.00999832,
      "lon": -81.14569855,
      "tz": "America\/New_York"
  },
  "KSWF": {
      "icao": "KSWF",
      "iata": "SWF",
      "name": "Stewart International Airport",
      "city": "Newburgh",
      "state": "New-York",
      "country": "US",
      "elevation": 491,
      "lat": 41.5041007996,
      "lon": -74.1047973633,
      "tz": "America\/New_York"
  },
  "KSWI": {
      "icao": "KSWI",
      "iata": "",
      "name": "Sherman Municipal Airport",
      "city": "Sherman",
      "state": "Texas",
      "country": "US",
      "elevation": 745,
      "lat": 33.6241989136,
      "lon": -96.5860977173,
      "tz": "America\/Chicago"
  },
  "KSWO": {
      "icao": "KSWO",
      "iata": "SWO",
      "name": "Stillwater Regional Airport",
      "city": "Stillwater",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1000,
      "lat": 36.1612014771,
      "lon": -97.0857009888,
      "tz": "America\/Chicago"
  },
  "KSWT": {
      "icao": "KSWT",
      "iata": "",
      "name": "Seward Municipal Airport",
      "city": "Seward",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1506,
      "lat": 40.86470032,
      "lon": -97.10919952,
      "tz": "America\/Chicago"
  },
  "KSWW": {
      "icao": "KSWW",
      "iata": "SWW",
      "name": "Avenger Field",
      "city": "Sweetwater",
      "state": "Texas",
      "country": "US",
      "elevation": 2380,
      "lat": 32.4673995972,
      "lon": -100.4670028687,
      "tz": "America\/Chicago"
  },
  "KSXL": {
      "icao": "KSXL",
      "iata": "",
      "name": "Summersville Airport",
      "city": "Summersville",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1820,
      "lat": 38.2316017151,
      "lon": -80.8707962036,
      "tz": "America\/New_York"
  },
  "KSXU": {
      "icao": "KSXU",
      "iata": "",
      "name": "Santa Rosa Route 66 Airport",
      "city": "Santa Rosa",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4792,
      "lat": 34.93439865,
      "lon": -104.6429977,
      "tz": "America\/Denver"
  },
  "KSYF": {
      "icao": "KSYF",
      "iata": "",
      "name": "Cheyenne County Municipal Airport",
      "city": "St Francis",
      "state": "Kansas",
      "country": "US",
      "elevation": 3413,
      "lat": 39.76110077,
      "lon": -101.7959976,
      "tz": "America\/Chicago"
  },
  "KSYI": {
      "icao": "KSYI",
      "iata": "SYI",
      "name": "Bomar Field Shelbyville Municipal Airport",
      "city": "Shelbyville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 801,
      "lat": 35.56010056,
      "lon": -86.44249725,
      "tz": "America\/Chicago"
  },
  "KSYN": {
      "icao": "KSYN",
      "iata": "SYN",
      "name": "Stanton Airfield",
      "city": "Stanton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 920,
      "lat": 44.4754981995,
      "lon": -93.0162963867,
      "tz": "America\/Chicago"
  },
  "KSYR": {
      "icao": "KSYR",
      "iata": "SYR",
      "name": "Syracuse Hancock International Airport",
      "city": "Syracuse",
      "state": "New-York",
      "country": "US",
      "elevation": 421,
      "lat": 43.1111984253,
      "lon": -76.106300354,
      "tz": "America\/New_York"
  },
  "KSYV": {
      "icao": "KSYV",
      "iata": "SYV",
      "name": "Sylvester Airport",
      "city": "Sylvester",
      "state": "Georgia",
      "country": "US",
      "elevation": 403,
      "lat": 31.5585002899,
      "lon": -83.8956985474,
      "tz": "America\/New_York"
  },
  "KSZL": {
      "icao": "KSZL",
      "iata": "SZL",
      "name": "Whiteman Air Force Base",
      "city": "Knob Noster",
      "state": "Missouri",
      "country": "US",
      "elevation": 870,
      "lat": 38.7303009033,
      "lon": -93.5478973389,
      "tz": "America\/Chicago"
  },
  "KSZN": {
      "icao": "KSZN",
      "iata": "SZN",
      "name": "Santa Cruz Island Airport",
      "city": "Santa Barbara",
      "state": "California",
      "country": "US",
      "elevation": 50,
      "lat": 34.0606002808,
      "lon": -119.915000916,
      "tz": "America\/Los_Angeles"
  },
  "KSZP": {
      "icao": "KSZP",
      "iata": "SZP",
      "name": "Santa Paula Airport",
      "city": "Santa Paula",
      "state": "California",
      "country": "US",
      "elevation": 243,
      "lat": 34.34719849,
      "lon": -119.060997,
      "tz": "America\/Los_Angeles"
  },
  "KSZT": {
      "icao": "KSZT",
      "iata": "",
      "name": "Sandpoint Airport",
      "city": "Sandpoint",
      "state": "Idaho",
      "country": "US",
      "elevation": 2131,
      "lat": 48.2994995117,
      "lon": -116.5599975586,
      "tz": "America\/Los_Angeles"
  },
  "KSZY": {
      "icao": "KSZY",
      "iata": "",
      "name": "Robert Sibley Airport",
      "city": "Selmer",
      "state": "Tennessee",
      "country": "US",
      "elevation": 610,
      "lat": 35.2028999329,
      "lon": -88.4983978271,
      "tz": "America\/Chicago"
  },
  "KT00": {
      "icao": "KT00",
      "iata": "",
      "name": "Chambers County Airport",
      "city": "Anahuac",
      "state": "Texas",
      "country": "US",
      "elevation": 21,
      "lat": 29.7700996399,
      "lon": -94.662399292,
      "tz": "America\/Chicago"
  },
  "KT03": {
      "icao": "KT03",
      "iata": "",
      "name": "Tuba City Airport",
      "city": "Tuba City",
      "state": "Arizona",
      "country": "US",
      "elevation": 4513,
      "lat": 36.0928001404,
      "lon": -111.3830032349,
      "tz": "America\/Denver"
  },
  "KT05": {
      "icao": "KT05",
      "iata": "",
      "name": "Charles R Johnson Airport",
      "city": "Port Mansfield",
      "state": "Texas",
      "country": "US",
      "elevation": 10,
      "lat": 26.5627994537,
      "lon": -97.4377975464,
      "tz": "America\/Chicago"
  },
  "KT12": {
      "icao": "KT12",
      "iata": "",
      "name": "Kirbyville Airport",
      "city": "Kirbyville",
      "state": "Texas",
      "country": "US",
      "elevation": 121,
      "lat": 30.6466007233,
      "lon": -93.9149017334,
      "tz": "America\/Chicago"
  },
  "KT15": {
      "icao": "KT15",
      "iata": "",
      "name": "Marlin Airport",
      "city": "Marlin",
      "state": "Texas",
      "country": "US",
      "elevation": 411,
      "lat": 31.3407001495,
      "lon": -96.8519973755,
      "tz": "America\/Chicago"
  },
  "KT16": {
      "icao": "KT16",
      "iata": "",
      "name": "Reserve Airport",
      "city": "Reserve",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6360,
      "lat": 33.6941986084,
      "lon": -108.8489990234,
      "tz": "America\/Denver"
  },
  "KT17": {
      "icao": "KT17",
      "iata": "",
      "name": "New Gulf Airport",
      "city": "New Gulf",
      "state": "Texas",
      "country": "US",
      "elevation": 100,
      "lat": 29.2761001587,
      "lon": -95.8886032104,
      "tz": "America\/Chicago"
  },
  "KT19": {
      "icao": "KT19",
      "iata": "",
      "name": "Duval Freer Airport",
      "city": "Freer",
      "state": "Texas",
      "country": "US",
      "elevation": 564,
      "lat": 27.883600235,
      "lon": -98.6003036499,
      "tz": "America\/Chicago"
  },
  "KT20": {
      "icao": "KT20",
      "iata": "",
      "name": "Roger M. Dreyer Memorial Airport",
      "city": "Gonzales",
      "state": "Texas",
      "country": "US",
      "elevation": 354,
      "lat": 29.5279998779,
      "lon": -97.4614028931,
      "tz": "America\/Chicago"
  },
  "KT23": {
      "icao": "KT23",
      "iata": "",
      "name": "Albany Municipal Airport",
      "city": "Albany",
      "state": "Texas",
      "country": "US",
      "elevation": 1425,
      "lat": 32.71900177,
      "lon": -99.2676010132,
      "tz": "America\/Chicago"
  },
  "KT24": {
      "icao": "KT24",
      "iata": "",
      "name": "Pineland Municipal Airport",
      "city": "Pineland",
      "state": "Texas",
      "country": "US",
      "elevation": 260,
      "lat": 31.233499527,
      "lon": -93.9819030762,
      "tz": "America\/Chicago"
  },
  "KT27": {
      "icao": "KT27",
      "iata": "",
      "name": "Horizon Airport",
      "city": "El Paso",
      "state": "Texas",
      "country": "US",
      "elevation": 4007,
      "lat": 31.7198009491,
      "lon": -106.2369995117,
      "tz": "America\/Denver"
  },
  "KT30": {
      "icao": "KT30",
      "iata": "",
      "name": "Mc Kinley Field",
      "city": "Pearsall",
      "state": "Texas",
      "country": "US",
      "elevation": 586,
      "lat": 28.82229996,
      "lon": -99.10900116,
      "tz": "America\/Chicago"
  },
  "KT31": {
      "icao": "KT31",
      "iata": "",
      "name": "Aero Country Airport",
      "city": "Mc Kinney",
      "state": "Texas",
      "country": "US",
      "elevation": 792,
      "lat": 33.2084999084,
      "lon": -96.741897583,
      "tz": "America\/Chicago"
  },
  "KT35": {
      "icao": "KT35",
      "iata": "",
      "name": "Cameron Municipal Airpark",
      "city": "Cameron",
      "state": "Texas",
      "country": "US",
      "elevation": 402,
      "lat": 30.8794002533,
      "lon": -96.9710998535,
      "tz": "America\/Chicago"
  },
  "KT36": {
      "icao": "KT36",
      "iata": "",
      "name": "Paul Pittman Memorial Airport",
      "city": "Tylertown",
      "state": "Mississippi",
      "country": "US",
      "elevation": 384,
      "lat": 31.1459999084,
      "lon": -90.1680984497,
      "tz": "America\/Chicago"
  },
  "KT39": {
      "icao": "KT39",
      "iata": "",
      "name": "Archer City Municipal Airport",
      "city": "Archer City",
      "state": "Texas",
      "country": "US",
      "elevation": 1065,
      "lat": 33.5822982788,
      "lon": -98.6186981201,
      "tz": "America\/Chicago"
  },
  "KT41": {
      "icao": "KT41",
      "iata": "",
      "name": "La Porte Municipal Airport",
      "city": "La Porte",
      "state": "Texas",
      "country": "US",
      "elevation": 25,
      "lat": 29.6693000793,
      "lon": -95.064201355,
      "tz": "America\/Chicago"
  },
  "KT42": {
      "icao": "KT42",
      "iata": "",
      "name": "Ruth Airport",
      "city": "Ruth",
      "state": "California",
      "country": "US",
      "elevation": 2781,
      "lat": 40.2112998962,
      "lon": -123.297996521,
      "tz": "America\/Los_Angeles"
  },
  "KT45": {
      "icao": "KT45",
      "iata": "",
      "name": "Panhandle Carson County Airport",
      "city": "Panhandle",
      "state": "Texas",
      "country": "US",
      "elevation": 3454,
      "lat": 35.3616981506,
      "lon": -101.3649978638,
      "tz": "America\/Chicago"
  },
  "KT50": {
      "icao": "KT50",
      "iata": "",
      "name": "Menard County Airport",
      "city": "Menard",
      "state": "Texas",
      "country": "US",
      "elevation": 1930,
      "lat": 30.9335002899,
      "lon": -99.8170013428,
      "tz": "America\/Chicago"
  },
  "KT51": {
      "icao": "KT51",
      "iata": "",
      "name": "Dan Jones International Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 166,
      "lat": 30.0428009033,
      "lon": -95.6671981812,
      "tz": "America\/Chicago"
  },
  "KT54": {
      "icao": "KT54",
      "iata": "",
      "name": "Lane Airpark",
      "city": "Rosenberg",
      "state": "Texas",
      "country": "US",
      "elevation": 94,
      "lat": 29.5244007111,
      "lon": -95.7751998901,
      "tz": "America\/Chicago"
  },
  "KT55": {
      "icao": "KT55",
      "iata": "",
      "name": "Dimmitt Municipal Airport",
      "city": "Dimmitt",
      "state": "Texas",
      "country": "US",
      "elevation": 3883,
      "lat": 34.5666999817,
      "lon": -102.3229980469,
      "tz": "America\/Chicago"
  },
  "KT59": {
      "icao": "KT59",
      "iata": "",
      "name": "Wheeler Municipal Airport",
      "city": "Wheeler",
      "state": "Texas",
      "country": "US",
      "elevation": 2470,
      "lat": 35.4510993958,
      "lon": -100.1999969482,
      "tz": "America\/Chicago"
  },
  "KT60": {
      "icao": "KT60",
      "iata": "",
      "name": "Stonewall County Airport",
      "city": "Aspermont",
      "state": "Texas",
      "country": "US",
      "elevation": 1744,
      "lat": 33.1722984314,
      "lon": -100.1979980469,
      "tz": "America\/Chicago"
  },
  "KT65": {
      "icao": "KT65",
      "iata": "",
      "name": "Mid Valley Airport",
      "city": "Weslaco",
      "state": "Texas",
      "country": "US",
      "elevation": 70,
      "lat": 26.1776008606,
      "lon": -97.9730987549,
      "tz": "America\/Chicago"
  },
  "KT67": {
      "icao": "KT67",
      "iata": "",
      "name": "Hicks Air Field",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 855,
      "lat": 32.93119812,
      "lon": -97.41169739,
      "tz": "America\/Chicago"
  },
  "KT69": {
      "icao": "KT69",
      "iata": "",
      "name": "Alfred C 'Bubba' Thomas Airport",
      "city": "Sinton",
      "state": "Texas",
      "country": "US",
      "elevation": 48,
      "lat": 28.0391998291,
      "lon": -97.5423965454,
      "tz": "America\/Chicago"
  },
  "KT70": {
      "icao": "KT70",
      "iata": "",
      "name": "Laughlin Air Force Base Auxiliary Nr 1 Airport",
      "city": "Spofford",
      "state": "Texas",
      "country": "US",
      "elevation": 976,
      "lat": 29.1259994507,
      "lon": -100.481002808,
      "tz": "America\/Chicago"
  },
  "KT74": {
      "icao": "KT74",
      "iata": "",
      "name": "Taylor Municipal Airport",
      "city": "Taylor",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 30.572599411,
      "lon": -97.4431991577,
      "tz": "America\/Chicago"
  },
  "KT77": {
      "icao": "KT77",
      "iata": "",
      "name": "Presidio Lely International Airport",
      "city": "Presidio",
      "state": "Texas",
      "country": "US",
      "elevation": 2932,
      "lat": 29.6340999603,
      "lon": -104.3619995117,
      "tz": "America\/Chicago"
  },
  "KT78": {
      "icao": "KT78",
      "iata": "",
      "name": "Liberty Municipal Airport",
      "city": "Liberty",
      "state": "Texas",
      "country": "US",
      "elevation": 70,
      "lat": 30.0778007507,
      "lon": -94.698600769,
      "tz": "America\/Chicago"
  },
  "KT82": {
      "icao": "KT82",
      "iata": "",
      "name": "Gillespie County Airport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 1695,
      "lat": 30.2432003021,
      "lon": -98.9092025757,
      "tz": "America\/Chicago"
  },
  "KT85": {
      "icao": "KT85",
      "iata": "",
      "name": "Yoakum Municipal Airport",
      "city": "Yoakum",
      "state": "Texas",
      "country": "US",
      "elevation": 365,
      "lat": 29.3131999969,
      "lon": -97.1383972168,
      "tz": "America\/Chicago"
  },
  "KT88": {
      "icao": "KT88",
      "iata": "",
      "name": "Colorado City Airport",
      "city": "Colorado City",
      "state": "Texas",
      "country": "US",
      "elevation": 2214,
      "lat": 32.4683990479,
      "lon": -100.9209976196,
      "tz": "America\/Chicago"
  },
  "KT90": {
      "icao": "KT90",
      "iata": "",
      "name": "Chambers County Winnie Stowell Airport",
      "city": "Winnie\/Stowell",
      "state": "Texas",
      "country": "US",
      "elevation": 25,
      "lat": 29.80410004,
      "lon": -94.43099976,
      "tz": "America\/Chicago"
  },
  "KT92": {
      "icao": "KT92",
      "iata": "",
      "name": "Mason County Airport",
      "city": "Mason",
      "state": "Texas",
      "country": "US",
      "elevation": 1502,
      "lat": 30.7322006226,
      "lon": -99.1843032837,
      "tz": "America\/Chicago"
  },
  "KT93": {
      "icao": "KT93",
      "iata": "",
      "name": "Follett Lipscomb County Airport",
      "city": "Follett",
      "state": "Texas",
      "country": "US",
      "elevation": 2601,
      "lat": 36.4407997131,
      "lon": -100.1240005493,
      "tz": "America\/Chicago"
  },
  "KTAD": {
      "icao": "KTAD",
      "iata": "TAD",
      "name": "Perry Stokes Airport",
      "city": "Trinidad",
      "state": "Colorado",
      "country": "US",
      "elevation": 5762,
      "lat": 37.2593994141,
      "lon": -104.341003418,
      "tz": "America\/Denver"
  },
  "KTAN": {
      "icao": "KTAN",
      "iata": "",
      "name": "Taunton Municipal King Field",
      "city": "Taunton",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 43,
      "lat": 41.8744010925,
      "lon": -71.0166015625,
      "tz": "America\/New_York"
  },
  "KTAZ": {
      "icao": "KTAZ",
      "iata": "",
      "name": "Taylorville Municipal Airport",
      "city": "Taylorville",
      "state": "Illinois",
      "country": "US",
      "elevation": 622,
      "lat": 39.534198761,
      "lon": -89.327796936,
      "tz": "America\/Chicago"
  },
  "KTBN": {
      "icao": "KTBN",
      "iata": "TBN",
      "name": "Waynesville-St. Robert Regional Forney field",
      "city": "Fort Leonard Wood",
      "state": "Missouri",
      "country": "US",
      "elevation": 1159,
      "lat": 37.74160004,
      "lon": -92.14070129,
      "tz": "America\/Chicago"
  },
  "KTBR": {
      "icao": "KTBR",
      "iata": "TBR",
      "name": "Statesboro Bulloch County Airport",
      "city": "Statesboro",
      "state": "Georgia",
      "country": "US",
      "elevation": 187,
      "lat": 32.4827003479,
      "lon": -81.7369003296,
      "tz": "America\/New_York"
  },
  "KTCC": {
      "icao": "KTCC",
      "iata": "TCC",
      "name": "Tucumcari Municipal Airport",
      "city": "Tucumcari",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4065,
      "lat": 35.182800293,
      "lon": -103.6029968262,
      "tz": "America\/Denver"
  },
  "KTCL": {
      "icao": "KTCL",
      "iata": "TCL",
      "name": "Tuscaloosa Regional Airport",
      "city": "Tuscaloosa",
      "state": "Alabama",
      "country": "US",
      "elevation": 170,
      "lat": 33.2206001282,
      "lon": -87.6113967896,
      "tz": "America\/Chicago"
  },
  "KTCM": {
      "icao": "KTCM",
      "iata": "TCM",
      "name": "McChord Air Force Base",
      "city": "Tacoma",
      "state": "Washington",
      "country": "US",
      "elevation": 322,
      "lat": 47.1376991272,
      "lon": -122.4759979248,
      "tz": "America\/Los_Angeles"
  },
  "KTCS": {
      "icao": "KTCS",
      "iata": "TCS",
      "name": "Truth Or Consequences Municipal Airport",
      "city": "Truth Or Consequences",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4853,
      "lat": 33.2369003296,
      "lon": -107.2720031738,
      "tz": "America\/Denver"
  },
  "KTCY": {
      "icao": "KTCY",
      "iata": "",
      "name": "Tracy Municipal Airport",
      "city": "Tracy",
      "state": "California",
      "country": "US",
      "elevation": 193,
      "lat": 37.688999176,
      "lon": -121.4420013428,
      "tz": "America\/Los_Angeles"
  },
  "KTDF": {
      "icao": "KTDF",
      "iata": "",
      "name": "Person County Airport",
      "city": "Roxboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 609,
      "lat": 36.28490067,
      "lon": -78.98419952,
      "tz": "America\/New_York"
  },
  "KTDO": {
      "icao": "KTDO",
      "iata": "TDO",
      "name": "Ed Carlson Memorial Field South Lewis County Airport",
      "city": "Toledo",
      "state": "Washington",
      "country": "US",
      "elevation": 374,
      "lat": 46.4771995544,
      "lon": -122.8059997559,
      "tz": "America\/Los_Angeles"
  },
  "KTDW": {
      "icao": "KTDW",
      "iata": "TDW",
      "name": "Tradewind Airport",
      "city": "Amarillo",
      "state": "Texas",
      "country": "US",
      "elevation": 3649,
      "lat": 35.1698989868,
      "lon": -101.8259963989,
      "tz": "America\/Chicago"
  },
  "KTDZ": {
      "icao": "KTDZ",
      "iata": "TDZ",
      "name": "Metcalf Field",
      "city": "Toledo",
      "state": "Ohio",
      "country": "US",
      "elevation": 623,
      "lat": 41.56489944,
      "lon": -83.4822998,
      "tz": "America\/New_York"
  },
  "KTEB": {
      "icao": "KTEB",
      "iata": "TEB",
      "name": "Teterboro Airport",
      "city": "Teterboro",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 9,
      "lat": 40.8501014709,
      "lon": -74.060798645,
      "tz": "America\/New_York"
  },
  "KTEL": {
      "icao": "KTEL",
      "iata": "",
      "name": "Perry County Municipal Airport",
      "city": "Tell City",
      "state": "Indiana",
      "country": "US",
      "elevation": 660,
      "lat": 38.0177002,
      "lon": -86.69090271,
      "tz": "America\/Indiana\/Tell_City"
  },
  "KTEW": {
      "icao": "KTEW",
      "iata": "",
      "name": "Mason Jewett Field",
      "city": "Mason",
      "state": "Michigan",
      "country": "US",
      "elevation": 920,
      "lat": 42.56579971,
      "lon": -84.42320251,
      "tz": "America\/Detroit"
  },
  "KTEX": {
      "icao": "KTEX",
      "iata": "TEX",
      "name": "Telluride Regional Airport",
      "city": "Telluride",
      "state": "Colorado",
      "country": "US",
      "elevation": 9070,
      "lat": 37.9538002,
      "lon": -107.9079971,
      "tz": "America\/Denver"
  },
  "KTFP": {
      "icao": "KTFP",
      "iata": "",
      "name": "T P Mc Campbell Airport",
      "city": "Ingleside",
      "state": "Texas",
      "country": "US",
      "elevation": 18,
      "lat": 27.9130001068,
      "lon": -97.2115020752,
      "tz": "America\/Chicago"
  },
  "KTGC": {
      "icao": "KTGC",
      "iata": "",
      "name": "Gibson County Airport",
      "city": "Trenton",
      "state": "Tennessee",
      "country": "US",
      "elevation": 359,
      "lat": 35.9324989319,
      "lon": -88.8488998413,
      "tz": "America\/Chicago"
  },
  "KTGI": {
      "icao": "KTGI",
      "iata": "",
      "name": "Tangier Island Airport",
      "city": "Tangier",
      "state": "Virginia",
      "country": "US",
      "elevation": 5,
      "lat": 37.8250999451,
      "lon": -75.9978027344,
      "tz": "America\/New_York"
  },
  "KTHA": {
      "icao": "KTHA",
      "iata": "THA",
      "name": "Tullahoma Regional Arpt\/Wm Northern Field",
      "city": "Tullahoma",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1083,
      "lat": 35.38010025,
      "lon": -86.24639893,
      "tz": "America\/Chicago"
  },
  "KTHM": {
      "icao": "KTHM",
      "iata": "THM",
      "name": "Thompson Falls Airport",
      "city": "Thompson Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 2467,
      "lat": 47.5735015869,
      "lon": -115.28099823,
      "tz": "America\/Denver"
  },
  "KTHP": {
      "icao": "KTHP",
      "iata": "THP",
      "name": "Hot Springs Co Thermopolis Municipal Airport",
      "city": "Thermopolis",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4592,
      "lat": 43.6582984924,
      "lon": -108.2129974365,
      "tz": "America\/Denver"
  },
  "KTHV": {
      "icao": "KTHV",
      "iata": "THV",
      "name": "York Airport",
      "city": "York",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 495,
      "lat": 39.91699982,
      "lon": -76.8730011,
      "tz": "America\/New_York"
  },
  "KTIF": {
      "icao": "KTIF",
      "iata": "",
      "name": "Thomas County Airport",
      "city": "Thedford",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2925,
      "lat": 41.96220016,
      "lon": -100.5690002,
      "tz": "America\/Chicago"
  },
  "KTIK": {
      "icao": "KTIK",
      "iata": "TIK",
      "name": "Tinker Air Force Base",
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1291,
      "lat": 35.4146995544,
      "lon": -97.3865966797,
      "tz": "America\/Chicago"
  },
  "KTIP": {
      "icao": "KTIP",
      "iata": "",
      "name": "Rantoul National Avn Center-Frank Elliot field",
      "city": "Rantoul",
      "state": "Illinois",
      "country": "US",
      "elevation": 737,
      "lat": 40.29359818,
      "lon": -88.14240265,
      "tz": "America\/Chicago"
  },
  "KTIW": {
      "icao": "KTIW",
      "iata": "TIW",
      "name": "Tacoma Narrows Airport",
      "city": "Tacoma",
      "state": "Washington",
      "country": "US",
      "elevation": 294,
      "lat": 47.26789856,
      "lon": -122.5780029,
      "tz": "America\/Los_Angeles"
  },
  "KTIX": {
      "icao": "KTIX",
      "iata": "TIX",
      "name": "Space Coast Regional Airport",
      "city": "Titusville",
      "state": "Florida",
      "country": "US",
      "elevation": 34,
      "lat": 28.514799118,
      "lon": -80.7992019653,
      "tz": "America\/New_York"
  },
  "KTKC": {
      "icao": "KTKC",
      "iata": "",
      "name": "Tracy Municipal Airport",
      "city": "Tracy",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1340,
      "lat": 44.2490997314,
      "lon": -95.6072998047,
      "tz": "America\/Chicago"
  },
  "KTKI": {
      "icao": "KTKI",
      "iata": "",
      "name": "Collin County Regional At Mc Kinney Airport",
      "city": "Dallas",
      "state": "Texas",
      "country": "US",
      "elevation": 585,
      "lat": 33.17789841,
      "lon": -96.59049988,
      "tz": "America\/Chicago"
  },
  "KTKO": {
      "icao": "KTKO",
      "iata": "",
      "name": "Mankato Airport",
      "city": "Mankato",
      "state": "Kansas",
      "country": "US",
      "elevation": 1859,
      "lat": 39.8027992249,
      "lon": -98.2211990356,
      "tz": "America\/Chicago"
  },
  "KTKV": {
      "icao": "KTKV",
      "iata": "",
      "name": "Tomahawk Regional Airport",
      "city": "Tomahawk",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1487,
      "lat": 45.46910095,
      "lon": -89.80570221,
      "tz": "America\/Chicago"
  },
  "KTKX": {
      "icao": "KTKX",
      "iata": "KNT",
      "name": "Kennett Memorial Airport",
      "city": "Kennett",
      "state": "Missouri",
      "country": "US",
      "elevation": 262,
      "lat": 36.2258987427,
      "lon": -90.0365982056,
      "tz": "America\/Chicago"
  },
  "KTLH": {
      "icao": "KTLH",
      "iata": "TLH",
      "name": "Tallahassee Regional Airport",
      "city": "Tallahassee",
      "state": "Florida",
      "country": "US",
      "elevation": 81,
      "lat": 30.3964996338,
      "lon": -84.3503036499,
      "tz": "America\/New_York"
  },
  "KTLR": {
      "icao": "KTLR",
      "iata": "TLR",
      "name": "Mefford Field",
      "city": "Tulare",
      "state": "California",
      "country": "US",
      "elevation": 265,
      "lat": 36.15629959,
      "lon": -119.3259964,
      "tz": "America\/Los_Angeles"
  },
  "KTMA": {
      "icao": "KTMA",
      "iata": "TMA",
      "name": "Henry Tift Myers Airport",
      "city": "Tifton",
      "state": "Georgia",
      "country": "US",
      "elevation": 355,
      "lat": 31.4290008545,
      "lon": -83.4885025024,
      "tz": "America\/New_York"
  },
  "KTMB": {
      "icao": "KTMB",
      "iata": "TMB",
      "name": "Miami Executive Airport",
      "city": "Miami",
      "state": "Miami",
      "country": "US",
      "elevation": 10,
      "lat": 25.6475637,
      "lon": -80.4332246,
      "tz": "America\/New_York"
  },
  "KTME": {
      "icao": "KTME",
      "iata": "",
      "name": "Houston Executive Airport",
      "city": "Houston",
      "state": "Texas",
      "country": "US",
      "elevation": 166,
      "lat": 29.8071994781,
      "lon": -95.8979034424,
      "tz": "America\/Chicago"
  },
  "KTMK": {
      "icao": "KTMK",
      "iata": "OTK",
      "name": "Tillamook Airport",
      "city": "Tillamook",
      "state": "Oregon",
      "country": "US",
      "elevation": 36,
      "lat": 45.4182014465,
      "lon": -123.814002991,
      "tz": "America\/Los_Angeles"
  },
  "KTMT": {
      "icao": "KTMT",
      "iata": "ASQ",
      "name": "Austin Airport",
      "city": "Austin",
      "state": "Nevada",
      "country": "US",
      "elevation": 5730,
      "lat": 39.4679985046,
      "lon": -117.194999695,
      "tz": "America\/Los_Angeles"
  },
  "KTNP": {
      "icao": "KTNP",
      "iata": "TNP",
      "name": "Twentynine Palms Airport",
      "city": "Twentynine Palms",
      "state": "California",
      "country": "US",
      "elevation": 1888,
      "lat": 34.13159943,
      "lon": -115.9459991,
      "tz": "America\/Los_Angeles"
  },
  "KTNT": {
      "icao": "KTNT",
      "iata": "TNT",
      "name": "Dade Collier Training and Transition Airport",
      "city": "Miami",
      "state": "Florida",
      "country": "US",
      "elevation": 13,
      "lat": 25.8617992401,
      "lon": -80.8970031738,
      "tz": "America\/New_York"
  },
  "KTNU": {
      "icao": "KTNU",
      "iata": "TNU",
      "name": "Newton Municipal Airport",
      "city": "Newton",
      "state": "Iowa",
      "country": "US",
      "elevation": 953,
      "lat": 41.6744003296,
      "lon": -93.021697998,
      "tz": "America\/Chicago"
  },
  "KTNX": {
      "icao": "KTNX",
      "iata": "XSD",
      "name": "Tonopah Test Range Airport",
      "city": "Tonopah",
      "state": "Nevada",
      "country": "US",
      "elevation": 5549,
      "lat": 37.7988014221,
      "lon": -116.78099823,
      "tz": "America\/Los_Angeles"
  },
  "KTOA": {
      "icao": "KTOA",
      "iata": "TOA",
      "name": "Zamperini Field",
      "city": "Torrance",
      "state": "California",
      "country": "US",
      "elevation": 103,
      "lat": 33.8033981323,
      "lon": -118.3399963379,
      "tz": "America\/Los_Angeles"
  },
  "KTOB": {
      "icao": "KTOB",
      "iata": "",
      "name": "Dodge Center Airport",
      "city": "Dodge Center",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1305,
      "lat": 44.0180015564,
      "lon": -92.8314971924,
      "tz": "America\/Chicago"
  },
  "KTOC": {
      "icao": "KTOC",
      "iata": "TOC",
      "name": "Toccoa Rg Letourneau Field",
      "city": "Toccoa",
      "state": "Georgia",
      "country": "US",
      "elevation": 996,
      "lat": 34.59379959,
      "lon": -83.29579926,
      "tz": "America\/New_York"
  },
  "KTOI": {
      "icao": "KTOI",
      "iata": "TOI",
      "name": "Troy Municipal Airport",
      "city": "Troy",
      "state": "Alabama",
      "country": "US",
      "elevation": 398,
      "lat": 31.8603992462,
      "lon": -86.0121002197,
      "tz": "America\/Chicago"
  },
  "KTOL": {
      "icao": "KTOL",
      "iata": "TOL",
      "name": "Toledo Express Airport",
      "city": "Toledo",
      "state": "Ohio",
      "country": "US",
      "elevation": 683,
      "lat": 41.58679962,
      "lon": -83.80780029,
      "tz": "America\/New_York"
  },
  "KTOP": {
      "icao": "KTOP",
      "iata": "TOP",
      "name": "Philip Billard Municipal Airport",
      "city": "Topeka",
      "state": "Kansas",
      "country": "US",
      "elevation": 881,
      "lat": 39.0686988831,
      "lon": -95.6224975586,
      "tz": "America\/Chicago"
  },
  "KTOR": {
      "icao": "KTOR",
      "iata": "TOR",
      "name": "Torrington Municipal Airport",
      "city": "Torrington",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4207,
      "lat": 42.0644989,
      "lon": -104.1529999,
      "tz": "America\/Denver"
  },
  "KTPA": {
      "icao": "KTPA",
      "iata": "TPA",
      "name": "Tampa International Airport",
      "city": "Tampa",
      "state": "Florida",
      "country": "US",
      "elevation": 26,
      "lat": 27.9755001068,
      "lon": -82.533203125,
      "tz": "America\/New_York"
  },
  "KTPF": {
      "icao": "KTPF",
      "iata": "TPF",
      "name": "Peter O Knight Airport",
      "city": "Tampa",
      "state": "Florida",
      "country": "US",
      "elevation": 8,
      "lat": 27.915599823,
      "lon": -82.4493026733,
      "tz": "America\/New_York"
  },
  "KTPH": {
      "icao": "KTPH",
      "iata": "TPH",
      "name": "Tonopah Airport",
      "city": "Tonopah",
      "state": "Nevada",
      "country": "US",
      "elevation": 5430,
      "lat": 38.06019974,
      "lon": -117.086998,
      "tz": "America\/Los_Angeles"
  },
  "KTPL": {
      "icao": "KTPL",
      "iata": "TPL",
      "name": "Draughon Miller Central Texas Regional Airport",
      "city": "Temple",
      "state": "Texas",
      "country": "US",
      "elevation": 682,
      "lat": 31.1525001526,
      "lon": -97.4077987671,
      "tz": "America\/Chicago"
  },
  "KTQE": {
      "icao": "KTQE",
      "iata": "",
      "name": "Tekamah Municipal Airport",
      "city": "Tekamah",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1027,
      "lat": 41.7635002136,
      "lon": -96.1779022217,
      "tz": "America\/Chicago"
  },
  "KTQH": {
      "icao": "KTQH",
      "iata": "",
      "name": "Tahlequah Municipal Airport",
      "city": "Tahlequah",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 874,
      "lat": 35.92890167,
      "lon": -95.00450134,
      "tz": "America\/Chicago"
  },
  "KTQK": {
      "icao": "KTQK",
      "iata": "",
      "name": "Scott City Municipal Airport",
      "city": "Scott City",
      "state": "Kansas",
      "country": "US",
      "elevation": 2963,
      "lat": 38.4743003845,
      "lon": -100.8850021362,
      "tz": "America\/Chicago"
  },
  "KTRI": {
      "icao": "KTRI",
      "iata": "TRI",
      "name": "Tri Cities Regional Tn Va Airport",
      "city": "Bristol\/Johnson\/Kingsport",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1519,
      "lat": 36.4752006531,
      "lon": -82.4074020386,
      "tz": "America\/New_York"
  },
  "KTRK": {
      "icao": "KTRK",
      "iata": "TKF",
      "name": "Truckee Tahoe Airport",
      "city": "Truckee",
      "state": "California",
      "country": "US",
      "elevation": 5900,
      "lat": 39.3199996948,
      "lon": -120.1399993896,
      "tz": "America\/Los_Angeles"
  },
  "KTRL": {
      "icao": "KTRL",
      "iata": "TRL",
      "name": "Terrell Municipal Airport",
      "city": "Terrell",
      "state": "Texas",
      "country": "US",
      "elevation": 474,
      "lat": 32.7092018127,
      "lon": -96.2674026489,
      "tz": "America\/Chicago"
  },
  "KTRM": {
      "icao": "KTRM",
      "iata": "TRM",
      "name": "Jacqueline Cochran Regional Airport",
      "city": "Palm Springs",
      "state": "California",
      "country": "US",
      "elevation": -115,
      "lat": 33.626701355,
      "lon": -116.1600036621,
      "tz": "America\/Los_Angeles"
  },
  "KTRX": {
      "icao": "KTRX",
      "iata": "TRX",
      "name": "Trenton Municipal Airport",
      "city": "Trenton",
      "state": "Missouri",
      "country": "US",
      "elevation": 758,
      "lat": 40.08349991,
      "lon": -93.59059906,
      "tz": "America\/Chicago"
  },
  "KTSO": {
      "icao": "KTSO",
      "iata": "",
      "name": "Carroll County-Tolson Airport",
      "city": "Carrollton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1163,
      "lat": 40.56190109,
      "lon": -81.07749939,
      "tz": "America\/New_York"
  },
  "KTSP": {
      "icao": "KTSP",
      "iata": "TSP",
      "name": "Tehachapi Municipal Airport",
      "city": "Tehachapi",
      "state": "California",
      "country": "US",
      "elevation": 4001,
      "lat": 35.1349983215,
      "lon": -118.4390029907,
      "tz": "America\/Los_Angeles"
  },
  "KTTA": {
      "icao": "KTTA",
      "iata": "",
      "name": "Sanford-Lee County Regional Airport",
      "city": "Sanford",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 246,
      "lat": 35.58369827,
      "lon": -79.10079956,
      "tz": "America\/New_York"
  },
  "KTTD": {
      "icao": "KTTD",
      "iata": "TTD",
      "name": "Portland Troutdale Airport",
      "city": "Portland",
      "state": "Oregon",
      "country": "US",
      "elevation": 39,
      "lat": 45.5494003296,
      "lon": -122.4010009766,
      "tz": "America\/Los_Angeles"
  },
  "KTTF": {
      "icao": "KTTF",
      "iata": "",
      "name": "Custer Airport",
      "city": "Monroe",
      "state": "Michigan",
      "country": "US",
      "elevation": 616,
      "lat": 41.9398994446,
      "lon": -83.4347000122,
      "tz": "America\/Detroit"
  },
  "KTTN": {
      "icao": "KTTN",
      "iata": "TTN",
      "name": "Trenton Mercer Airport",
      "city": "Trenton",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 213,
      "lat": 40.2766990662,
      "lon": -74.8134994507,
      "tz": "America\/New_York"
  },
  "KTTS": {
      "icao": "KTTS",
      "iata": "",
      "name": "Nasa Shuttle Landing Facility Airport",
      "city": "Titusville",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 28.6149997711,
      "lon": -80.6945037842,
      "tz": "America\/New_York"
  },
  "KTUL": {
      "icao": "KTUL",
      "iata": "TUL",
      "name": "Tulsa International Airport",
      "city": "Tulsa",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 677,
      "lat": 36.1983985901,
      "lon": -95.8880996704,
      "tz": "America\/Chicago"
  },
  "KTUP": {
      "icao": "KTUP",
      "iata": "TUP",
      "name": "Tupelo Regional Airport",
      "city": "Tupelo",
      "state": "Mississippi",
      "country": "US",
      "elevation": 346,
      "lat": 34.2681007385,
      "lon": -88.7698974609,
      "tz": "America\/Chicago"
  },
  "KTUS": {
      "icao": "KTUS",
      "iata": "TUS",
      "name": "Tucson International Airport",
      "city": "Tucson",
      "state": "Arizona",
      "country": "US",
      "elevation": 2643,
      "lat": 32.1161003113,
      "lon": -110.9410018921,
      "tz": "America\/Phoenix"
  },
  "KTVB": {
      "icao": "KTVB",
      "iata": "",
      "name": "Cabool Memorial Airport",
      "city": "Cabool",
      "state": "Missouri",
      "country": "US",
      "elevation": 1220,
      "lat": 37.1324005127,
      "lon": -92.0839996338,
      "tz": "America\/Chicago"
  },
  "KTVC": {
      "icao": "KTVC",
      "iata": "TVC",
      "name": "Cherry Capital Airport",
      "city": "Traverse City",
      "state": "Michigan",
      "country": "US",
      "elevation": 624,
      "lat": 44.7414016724,
      "lon": -85.5821990967,
      "tz": "America\/Detroit"
  },
  "KTVF": {
      "icao": "KTVF",
      "iata": "TVF",
      "name": "Thief River Falls Regional Airport",
      "city": "Thief River Falls",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1119,
      "lat": 48.06570053,
      "lon": -96.18499756,
      "tz": "America\/Chicago"
  },
  "KTVI": {
      "icao": "KTVI",
      "iata": "TVI",
      "name": "Thomasville Regional Airport",
      "city": "Thomasville",
      "state": "Georgia",
      "country": "US",
      "elevation": 264,
      "lat": 30.901599884,
      "lon": -83.8813018799,
      "tz": "America\/New_York"
  },
  "KTVK": {
      "icao": "KTVK",
      "iata": "",
      "name": "Centerville Municipal Airport",
      "city": "Centerville",
      "state": "Iowa",
      "country": "US",
      "elevation": 1023,
      "lat": 40.68389893,
      "lon": -92.90100098,
      "tz": "America\/Chicago"
  },
  "KTVL": {
      "icao": "KTVL",
      "iata": "TVL",
      "name": "Lake Tahoe Airport",
      "city": "South Lake Tahoe",
      "state": "California",
      "country": "US",
      "elevation": 6264,
      "lat": 38.893901825,
      "lon": -119.9950027466,
      "tz": "America\/Los_Angeles"
  },
  "KTVR": {
      "icao": "KTVR",
      "iata": "",
      "name": "Vicksburg Tallulah Regional Airport",
      "city": "Tallulah\/Vicksburg",
      "state": "Louisiana",
      "country": "US",
      "elevation": 86,
      "lat": 32.351600647,
      "lon": -91.0277023315,
      "tz": "America\/Chicago"
  },
  "KTVY": {
      "icao": "KTVY",
      "iata": "",
      "name": "Bolinder Field Tooele Valley Airport",
      "city": "Tooele",
      "state": "Utah",
      "country": "US",
      "elevation": 4322,
      "lat": 40.61230087,
      "lon": -112.3509979,
      "tz": "America\/Denver"
  },
  "KTWF": {
      "icao": "KTWF",
      "iata": "TWF",
      "name": "Joslin Field Magic Valley Regional Airport",
      "city": "Twin Falls",
      "state": "Idaho",
      "country": "US",
      "elevation": 4154,
      "lat": 42.48180008,
      "lon": -114.487999,
      "tz": "America\/Boise"
  },
  "KTWM": {
      "icao": "KTWM",
      "iata": "",
      "name": "Richard B Helgeson Airport",
      "city": "Two Harbors",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1073,
      "lat": 47.04919815,
      "lon": -91.74510193,
      "tz": "America\/Chicago"
  },
  "KTWT": {
      "icao": "KTWT",
      "iata": "",
      "name": "Sturgis Municipal Airport",
      "city": "Sturgis",
      "state": "Kentucky",
      "country": "US",
      "elevation": 372,
      "lat": 37.5407981873,
      "lon": -87.9517974854,
      "tz": "America\/Chicago"
  },
  "KTXK": {
      "icao": "KTXK",
      "iata": "TXK",
      "name": "Texarkana Regional Webb Field",
      "city": "Texarkana",
      "state": "Arkansas",
      "country": "US",
      "elevation": 390,
      "lat": 33.4537010193,
      "lon": -93.9909973145,
      "tz": "America\/Chicago"
  },
  "KTYL": {
      "icao": "KTYL",
      "iata": "TYZ",
      "name": "Taylor Airport",
      "city": "Taylor",
      "state": "Arizona",
      "country": "US",
      "elevation": 5823,
      "lat": 34.45280075,
      "lon": -110.1149979,
      "tz": "America\/Phoenix"
  },
  "KTYQ": {
      "icao": "KTYQ",
      "iata": "",
      "name": "Indianapolis Executive Airport",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 922,
      "lat": 40.0307006836,
      "lon": -86.2514038086,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KTYR": {
      "icao": "KTYR",
      "iata": "TYR",
      "name": "Tyler Pounds Regional Airport",
      "city": "Tyler",
      "state": "Texas",
      "country": "US",
      "elevation": 544,
      "lat": 32.3540992737,
      "lon": -95.4023971558,
      "tz": "America\/Chicago"
  },
  "KTYS": {
      "icao": "KTYS",
      "iata": "TYS",
      "name": "McGhee Tyson Airport",
      "city": "Knoxville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 981,
      "lat": 35.81100082,
      "lon": -83.9940033,
      "tz": "America\/New_York"
  },
  "KTZR": {
      "icao": "KTZR",
      "iata": "",
      "name": "Bolton Field",
      "city": "Columbus",
      "state": "Ohio",
      "country": "US",
      "elevation": 905,
      "lat": 39.9011993408,
      "lon": -83.1369018555,
      "tz": "America\/New_York"
  },
  "KTZT": {
      "icao": "KTZT",
      "iata": "",
      "name": "Belle Plaine Municipal Airport",
      "city": "Belle Plaine",
      "state": "Iowa",
      "country": "US",
      "elevation": 771,
      "lat": 41.8787994385,
      "lon": -92.2845993042,
      "tz": "America\/Chicago"
  },
  "KTZV": {
      "icao": "KTZV",
      "iata": "",
      "name": "Tompkinsville Monroe County Airport",
      "city": "Tompkinsville",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1036,
      "lat": 36.7290000916,
      "lon": -85.6523971558,
      "tz": "America\/Chicago"
  },
  "KU00": {
      "icao": "KU00",
      "iata": "",
      "name": "Leadore Airport",
      "city": "Leadore",
      "state": "Idaho",
      "country": "US",
      "elevation": 6018,
      "lat": 44.6738014221,
      "lon": -113.3529968262,
      "tz": "America\/Boise"
  },
  "KU01": {
      "icao": "KU01",
      "iata": "",
      "name": "American Falls Airport",
      "city": "American Falls",
      "state": "Idaho",
      "country": "US",
      "elevation": 4419,
      "lat": 42.7972984314,
      "lon": -112.8249969482,
      "tz": "America\/Boise"
  },
  "KU02": {
      "icao": "KU02",
      "iata": "",
      "name": "Mccarley Field",
      "city": "Blackfoot",
      "state": "Idaho",
      "country": "US",
      "elevation": 4488,
      "lat": 43.2093009949,
      "lon": -112.3499984741,
      "tz": "America\/Boise"
  },
  "KU03": {
      "icao": "KU03",
      "iata": "",
      "name": "Buhl Municipal Airport",
      "city": "Buhl",
      "state": "Idaho",
      "country": "US",
      "elevation": 3660,
      "lat": 42.5915985107,
      "lon": -114.7969970703,
      "tz": "America\/Boise"
  },
  "KU05": {
      "icao": "KU05",
      "iata": "",
      "name": "Riddick Field",
      "city": "Philipsburg",
      "state": "Montana",
      "country": "US",
      "elevation": 5212,
      "lat": 46.3194007874,
      "lon": -113.3050003052,
      "tz": "America\/Denver"
  },
  "KU06": {
      "icao": "KU06",
      "iata": "",
      "name": "Cokeville Municipal Airport",
      "city": "Cokeville",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6270,
      "lat": 42.0457992554,
      "lon": -110.966003418,
      "tz": "America\/Denver"
  },
  "KU07": {
      "icao": "KU07",
      "iata": "BFG",
      "name": "Bullfrog Basin Airport",
      "city": "Glen Canyon Natl Rec Area",
      "state": "Utah",
      "country": "US",
      "elevation": 4167,
      "lat": 37.54579926,
      "lon": -110.7129974,
      "tz": "America\/Denver"
  },
  "KU08": {
      "icao": "KU08",
      "iata": "",
      "name": "Perkins Field",
      "city": "Overton",
      "state": "Nevada",
      "country": "US",
      "elevation": 1358,
      "lat": 36.5680007935,
      "lon": -114.4430007935,
      "tz": "America\/Los_Angeles"
  },
  "KU09": {
      "icao": "KU09",
      "iata": "",
      "name": "Fort Belknap Agency Airport",
      "city": "Harlem",
      "state": "Montana",
      "country": "US",
      "elevation": 2374,
      "lat": 48.481098175,
      "lon": -108.7689971924,
      "tz": "America\/Denver"
  },
  "KU10": {
      "icao": "KU10",
      "iata": "",
      "name": "Preston Airport",
      "city": "Preston",
      "state": "Idaho",
      "country": "US",
      "elevation": 4728,
      "lat": 42.1068992615,
      "lon": -111.9130020142,
      "tz": "America\/Boise"
  },
  "KU12": {
      "icao": "KU12",
      "iata": "",
      "name": "Stanford Field",
      "city": "St Anthony",
      "state": "Idaho",
      "country": "US",
      "elevation": 4966,
      "lat": 43.9457015991,
      "lon": -111.6839981079,
      "tz": "America\/Boise"
  },
  "KU13": {
      "icao": "KU13",
      "iata": "",
      "name": "Junction Airport",
      "city": "Junction",
      "state": "Utah",
      "country": "US",
      "elevation": 6069,
      "lat": 38.25,
      "lon": -112.2249984741,
      "tz": "America\/Denver"
  },
  "KU14": {
      "icao": "KU14",
      "iata": "",
      "name": "Nephi Municipal Airport",
      "city": "Nephi",
      "state": "Utah",
      "country": "US",
      "elevation": 5022,
      "lat": 39.73880005,
      "lon": -111.8720016,
      "tz": "America\/Denver"
  },
  "KU25": {
      "icao": "KU25",
      "iata": "",
      "name": "Dubois Municipal Airport",
      "city": "Dubois",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7291,
      "lat": 43.5483016968,
      "lon": -109.6900024414,
      "tz": "America\/Denver"
  },
  "KU30": {
      "icao": "KU30",
      "iata": "",
      "name": "Temple Bar Airport",
      "city": "Temple Bar",
      "state": "Arizona",
      "country": "US",
      "elevation": 1549,
      "lat": 36.0205001831,
      "lon": -114.3349990845,
      "tz": "America\/Phoenix"
  },
  "KU34": {
      "icao": "KU34",
      "iata": "RVR",
      "name": "Green River Municipal Airport",
      "city": "Green River",
      "state": "Utah",
      "country": "US",
      "elevation": 4225,
      "lat": 38.9613990784,
      "lon": -110.226997375,
      "tz": "America\/Denver"
  },
  "KU36": {
      "icao": "KU36",
      "iata": "",
      "name": "Aberdeen Municipal Airport",
      "city": "Aberdeen",
      "state": "Idaho",
      "country": "US",
      "elevation": 4470,
      "lat": 42.9210014343,
      "lon": -112.8809967041,
      "tz": "America\/Boise"
  },
  "KU42": {
      "icao": "KU42",
      "iata": "",
      "name": "South Valley Regional Airport",
      "city": "West Jordan",
      "state": "Utah",
      "country": "US",
      "elevation": 4607,
      "lat": 40.6195556,
      "lon": -111.9928889,
      "tz": "America\/Denver"
  },
  "KU43": {
      "icao": "KU43",
      "iata": "",
      "name": "Monticello Airport",
      "city": "Monticello",
      "state": "Utah",
      "country": "US",
      "elevation": 6998,
      "lat": 37.9371986389,
      "lon": -109.3470001221,
      "tz": "America\/Denver"
  },
  "KU52": {
      "icao": "KU52",
      "iata": "",
      "name": "Beaver Municipal Airport",
      "city": "Beaver",
      "state": "Utah",
      "country": "US",
      "elevation": 5863,
      "lat": 38.23070145,
      "lon": -112.6750031,
      "tz": "America\/Denver"
  },
  "KU55": {
      "icao": "KU55",
      "iata": "",
      "name": "Panguitch Municipal Airport",
      "city": "Panguitch",
      "state": "Utah",
      "country": "US",
      "elevation": 6763,
      "lat": 37.84519958,
      "lon": -112.3919983,
      "tz": "America\/Denver"
  },
  "KU56": {
      "icao": "KU56",
      "iata": "",
      "name": "Rigby Jefferson County Airport",
      "city": "Rigby",
      "state": "Idaho",
      "country": "US",
      "elevation": 4845,
      "lat": 43.6444015503,
      "lon": -111.9290008545,
      "tz": "America\/Boise"
  },
  "KU58": {
      "icao": "KU58",
      "iata": "",
      "name": "Downey\/Hyde Memorial Airport",
      "city": "Downey",
      "state": "Idaho",
      "country": "US",
      "elevation": 4906,
      "lat": 42.42630005,
      "lon": -112.1090012,
      "tz": "America\/Boise"
  },
  "KU62": {
      "icao": "KU62",
      "iata": "",
      "name": "Mackay Airport",
      "city": "Mackay",
      "state": "Idaho",
      "country": "US",
      "elevation": 5892,
      "lat": 43.90409851,
      "lon": -113.6009979,
      "tz": "America\/Boise"
  },
  "KU63": {
      "icao": "KU63",
      "iata": "",
      "name": "Bruce Meadows Airport",
      "city": "Stanley",
      "state": "Idaho",
      "country": "US",
      "elevation": 6370,
      "lat": 44.4155006409,
      "lon": -115.3170013428,
      "tz": "America\/Boise"
  },
  "KU64": {
      "icao": "KU64",
      "iata": "",
      "name": "Monticello Airport",
      "city": "Monticello",
      "state": "Utah",
      "country": "US",
      "elevation": 6966,
      "lat": 37.93243,
      "lon": -109.341225,
      "tz": "America\/Denver"
  },
  "KU68": {
      "icao": "KU68",
      "iata": "",
      "name": "North Big Horn County Airport",
      "city": "Cowley\/Lovell\/Byron",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4090,
      "lat": 44.9117012024,
      "lon": -108.4459991455,
      "tz": "America\/Denver"
  },
  "KU69": {
      "icao": "KU69",
      "iata": "",
      "name": "Duchesne Municipal Airport",
      "city": "Duchesne",
      "state": "Utah",
      "country": "US",
      "elevation": 5826,
      "lat": 40.1918983459,
      "lon": -110.3809967041,
      "tz": "America\/Denver"
  },
  "KU70": {
      "icao": "KU70",
      "iata": "ICS",
      "name": "Cascade Airport",
      "city": "Cascade",
      "state": "Idaho",
      "country": "US",
      "elevation": 4742,
      "lat": 44.4938011169,
      "lon": -116.01599884,
      "tz": "America\/Boise"
  },
  "KU76": {
      "icao": "KU76",
      "iata": "",
      "name": "Mountain Home Municipal Airport",
      "city": "Mountain Home",
      "state": "Idaho",
      "country": "US",
      "elevation": 3167,
      "lat": 43.13130188,
      "lon": -115.7300034,
      "tz": "America\/Boise"
  },
  "KU77": {
      "icao": "KU77",
      "iata": "",
      "name": "Spanish Fork Springville Airport",
      "city": "Spanish Fork",
      "state": "Utah",
      "country": "US",
      "elevation": 4529,
      "lat": 40.1416015625,
      "lon": -111.6610031128,
      "tz": "America\/Denver"
  },
  "KU78": {
      "icao": "KU78",
      "iata": "",
      "name": "Allen H Tigert Airport",
      "city": "Soda Springs",
      "state": "Idaho",
      "country": "US",
      "elevation": 5839,
      "lat": 42.6416015625,
      "lon": -111.5800018311,
      "tz": "America\/Boise"
  },
  "KU79": {
      "icao": "KU79",
      "iata": "",
      "name": "Chamberlain Usfs Airport",
      "city": "Chamberlain Guard Station",
      "state": "Idaho",
      "country": "US",
      "elevation": 5765,
      "lat": 45.3791007996,
      "lon": -115.1969985962,
      "tz": "America\/Boise"
  },
  "KU81": {
      "icao": "KU81",
      "iata": "",
      "name": "Cold Meadows US Forest Service Airport",
      "city": "Cold Meadows Guard Station",
      "state": "Idaho",
      "country": "US",
      "elevation": 7030,
      "lat": 45.2915992737,
      "lon": -114.949996948,
      "tz": "America\/Boise"
  },
  "KU82": {
      "icao": "KU82",
      "iata": "",
      "name": "Council Municipal Airport",
      "city": "Council",
      "state": "Idaho",
      "country": "US",
      "elevation": 2963,
      "lat": 44.7499008179,
      "lon": -116.4469985962,
      "tz": "America\/Boise"
  },
  "KU89": {
      "icao": "KU89",
      "iata": "",
      "name": "Glenns Ferry Municipal Airport",
      "city": "Glenns Ferry",
      "state": "Idaho",
      "country": "US",
      "elevation": 2536,
      "lat": 42.9457015991,
      "lon": -115.3300018311,
      "tz": "America\/Boise"
  },
  "KU96": {
      "icao": "KU96",
      "iata": "",
      "name": "Cal Black Memorial Airport",
      "city": "Halls Crossing",
      "state": "Utah",
      "country": "US",
      "elevation": 4388,
      "lat": 37.442199707,
      "lon": -110.5699996948,
      "tz": "America\/Denver"
  },
  "KUAO": {
      "icao": "KUAO",
      "iata": "",
      "name": "Aurora State Airport",
      "city": "Aurora",
      "state": "Oregon",
      "country": "US",
      "elevation": 200,
      "lat": 45.2471008301,
      "lon": -122.7699966431,
      "tz": "America\/Los_Angeles"
  },
  "KUBE": {
      "icao": "KUBE",
      "iata": "",
      "name": "Cumberland Municipal Airport",
      "city": "Cumberland",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1241,
      "lat": 45.5060005188,
      "lon": -91.9811019897,
      "tz": "America\/Chicago"
  },
  "KUBS": {
      "icao": "KUBS",
      "iata": "UBS",
      "name": "Columbus Lowndes County Airport",
      "city": "Columbus",
      "state": "Mississippi",
      "country": "US",
      "elevation": 188,
      "lat": 33.4654006958,
      "lon": -88.3803024292,
      "tz": "America\/Chicago"
  },
  "KUBX": {
      "icao": "KUBX",
      "iata": "",
      "name": "Cuba Municipal Airport",
      "city": "Cuba",
      "state": "Missouri",
      "country": "US",
      "elevation": 1023,
      "lat": 38.0688018799,
      "lon": -91.4289016724,
      "tz": "America\/Chicago"
  },
  "KUCP": {
      "icao": "KUCP",
      "iata": "",
      "name": "New Castle Municipal Airport",
      "city": "New Castle",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1070,
      "lat": 41.02529907,
      "lon": -80.41339874,
      "tz": "America\/New_York"
  },
  "KUCY": {
      "icao": "KUCY",
      "iata": "UCY",
      "name": "Everett-Stewart Regional Airport",
      "city": "Union City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 336,
      "lat": 36.38180161,
      "lon": -88.98539734,
      "tz": "America\/Chicago"
  },
  "KUDD": {
      "icao": "KUDD",
      "iata": "UDD",
      "name": "Bermuda Dunes Airport",
      "city": "Palm Springs",
      "state": "California",
      "country": "US",
      "elevation": 73,
      "lat": 33.7484016418,
      "lon": -116.2750015259,
      "tz": "America\/Los_Angeles"
  },
  "KUDG": {
      "icao": "KUDG",
      "iata": "",
      "name": "Darlington County Jetport Airport",
      "city": "Darlington",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 192,
      "lat": 34.44940186,
      "lon": -79.89009857,
      "tz": "America\/New_York"
  },
  "KUES": {
      "icao": "KUES",
      "iata": "UES",
      "name": "Waukesha County Airport",
      "city": "Waukesha",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 911,
      "lat": 43.0410003662,
      "lon": -88.2370986938,
      "tz": "America\/Chicago"
  },
  "KUGN": {
      "icao": "KUGN",
      "iata": "UGN",
      "name": "Waukegan Regional Airport",
      "city": "Chicago\/Waukegan",
      "state": "Illinois",
      "country": "US",
      "elevation": 727,
      "lat": 42.4221992493,
      "lon": -87.8678970337,
      "tz": "America\/Chicago"
  },
  "KUIL": {
      "icao": "KUIL",
      "iata": "UIL",
      "name": "Quillayute Airport",
      "city": "Quillayute",
      "state": "Washington",
      "country": "US",
      "elevation": 194,
      "lat": 47.9365997314,
      "lon": -124.56300354,
      "tz": "America\/Los_Angeles"
  },
  "KUIN": {
      "icao": "KUIN",
      "iata": "UIN",
      "name": "Quincy Regional Baldwin Field",
      "city": "Quincy",
      "state": "Illinois",
      "country": "US",
      "elevation": 768,
      "lat": 39.94269943,
      "lon": -91.19460297,
      "tz": "America\/Chicago"
  },
  "KUKF": {
      "icao": "KUKF",
      "iata": "IKB",
      "name": "Wilkes County Airport",
      "city": "North Wilkesboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1301,
      "lat": 36.2228012085,
      "lon": -81.0982971191,
      "tz": "America\/New_York"
  },
  "KUKI": {
      "icao": "KUKI",
      "iata": "UKI",
      "name": "Ukiah Municipal Airport",
      "city": "Ukiah",
      "state": "California",
      "country": "US",
      "elevation": 614,
      "lat": 39.1259994507,
      "lon": -123.2009963989,
      "tz": "America\/Los_Angeles"
  },
  "KUKL": {
      "icao": "KUKL",
      "iata": "",
      "name": "Coffey County Airport",
      "city": "Burlington",
      "state": "Kansas",
      "country": "US",
      "elevation": 1174,
      "lat": 38.30250168,
      "lon": -95.72499847,
      "tz": "America\/Chicago"
  },
  "KUKT": {
      "icao": "KUKT",
      "iata": "UKT",
      "name": "Quakertown Airport",
      "city": "Quakertown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 526,
      "lat": 40.4351997375,
      "lon": -75.3818969727,
      "tz": "America\/New_York"
  },
  "KULM": {
      "icao": "KULM",
      "iata": "ULM",
      "name": "New Ulm Municipal Airport",
      "city": "New Ulm",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1011,
      "lat": 44.3195991516,
      "lon": -94.5022964478,
      "tz": "America\/Chicago"
  },
  "KULS": {
      "icao": "KULS",
      "iata": "",
      "name": "Ulysses Airport",
      "city": "Ulysses",
      "state": "Kansas",
      "country": "US",
      "elevation": 3071,
      "lat": 37.60400009,
      "lon": -101.3740005,
      "tz": "America\/Chicago"
  },
  "KUMP": {
      "icao": "KUMP",
      "iata": "",
      "name": "Indianapolis Metropolitan Airport",
      "city": "Indianapolis",
      "state": "Indiana",
      "country": "US",
      "elevation": 811,
      "lat": 39.93519974,
      "lon": -86.04499817,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KUNI": {
      "icao": "KUNI",
      "iata": "ATO",
      "name": "Ohio University Snyder Field",
      "city": "Athens\/Albany",
      "state": "Ohio",
      "country": "US",
      "elevation": 766,
      "lat": 39.2109985352,
      "lon": -82.2313995361,
      "tz": "America\/New_York"
  },
  "KUNO": {
      "icao": "KUNO",
      "iata": "",
      "name": "West Plains Municipal Airport",
      "city": "West Plains",
      "state": "Missouri",
      "country": "US",
      "elevation": 1228,
      "lat": 36.8783988953,
      "lon": -91.9027023315,
      "tz": "America\/Chicago"
  },
  "KUNU": {
      "icao": "KUNU",
      "iata": "UNU",
      "name": "Dodge County Airport",
      "city": "Juneau",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 934,
      "lat": 43.42660141,
      "lon": -88.70320129,
      "tz": "America\/Chicago"
  },
  "KUNV": {
      "icao": "KUNV",
      "iata": "SCE",
      "name": "University Park Airport",
      "city": "State College",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1239,
      "lat": 40.8493003845,
      "lon": -77.8487014771,
      "tz": "America\/New_York"
  },
  "KUOS": {
      "icao": "KUOS",
      "iata": "UOS",
      "name": "Franklin County Airport",
      "city": "Sewanee",
      "state": "Georgia",
      "country": "US",
      "elevation": 1953,
      "lat": 35.2051010132,
      "lon": -85.8981018066,
      "tz": "America\/Chicago"
  },
  "KUOX": {
      "icao": "KUOX",
      "iata": "UOX",
      "name": "University Oxford Airport",
      "city": "Oxford",
      "state": "Mississippi",
      "country": "US",
      "elevation": 452,
      "lat": 34.3843002319,
      "lon": -89.5367965698,
      "tz": "America\/Chicago"
  },
  "KUSE": {
      "icao": "KUSE",
      "iata": "",
      "name": "Fulton County Airport",
      "city": "Wauseon",
      "state": "Ohio",
      "country": "US",
      "elevation": 779,
      "lat": 41.6100997925,
      "lon": -84.1271972656,
      "tz": "America\/New_York"
  },
  "KUTA": {
      "icao": "KUTA",
      "iata": "UTM",
      "name": "Tunica Municipal Airport",
      "city": "Tunica",
      "state": "Mississippi",
      "country": "US",
      "elevation": 194,
      "lat": 34.6809997559,
      "lon": -90.3467025757,
      "tz": "America\/Chicago"
  },
  "KUTS": {
      "icao": "KUTS",
      "iata": "HTV",
      "name": "Huntsville Municipal Airport",
      "city": "Huntsville",
      "state": "Texas",
      "country": "US",
      "elevation": 363,
      "lat": 30.7469005585,
      "lon": -95.5871963501,
      "tz": "America\/Chicago"
  },
  "KUUU": {
      "icao": "KUUU",
      "iata": "NPT",
      "name": "Newport State Airport",
      "city": "Newport",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 172,
      "lat": 41.5323982239,
      "lon": -71.28150177,
      "tz": "America\/New_York"
  },
  "KUUV": {
      "icao": "KUUV",
      "iata": "",
      "name": "Sullivan Regional Airport",
      "city": "Sullivan",
      "state": "Missouri",
      "country": "US",
      "elevation": 933,
      "lat": 38.2335014343,
      "lon": -91.1642990112,
      "tz": "America\/Chicago"
  },
  "KUVA": {
      "icao": "KUVA",
      "iata": "UVA",
      "name": "Garner Field",
      "city": "Uvalde",
      "state": "Florida",
      "country": "US",
      "elevation": 942,
      "lat": 29.2112998962,
      "lon": -99.743598938,
      "tz": "America\/Chicago"
  },
  "KUWL": {
      "icao": "KUWL",
      "iata": "",
      "name": "New Castle Henry Co. Municipal Airport",
      "city": "New Castle",
      "state": "Indiana",
      "country": "US",
      "elevation": 1088,
      "lat": 39.87590027,
      "lon": -85.32649994,
      "tz": "America\/Indiana\/Indianapolis"
  },
  "KUXL": {
      "icao": "KUXL",
      "iata": "",
      "name": "Southland Field",
      "city": "Sulphur",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 30.13139915,
      "lon": -93.37609863,
      "tz": "America\/Chicago"
  },
  "KUYF": {
      "icao": "KUYF",
      "iata": "",
      "name": "Madison County Airport",
      "city": "London",
      "state": "Ohio",
      "country": "US",
      "elevation": 1082,
      "lat": 39.93270111,
      "lon": -83.46199799,
      "tz": "America\/New_York"
  },
  "KUZA": {
      "icao": "KUZA",
      "iata": "RKH",
      "name": "Rock Hill York Co Bryant field",
      "city": "Rock Hill",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 666,
      "lat": 34.9878006,
      "lon": -81.05719757,
      "tz": "America\/New_York"
  },
  "KVAD": {
      "icao": "KVAD",
      "iata": "VAD",
      "name": "Moody Air Force Base",
      "city": "Valdosta",
      "state": "Georgia",
      "country": "US",
      "elevation": 233,
      "lat": 30.9678001404,
      "lon": -83.1930007935,
      "tz": "America\/New_York"
  },
  "KVAY": {
      "icao": "KVAY",
      "iata": "LLY",
      "name": "South Jersey Regional Airport",
      "city": "Mount Holly",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 53,
      "lat": 39.9429016113,
      "lon": -74.845703125,
      "tz": "America\/New_York"
  },
  "KVBG": {
      "icao": "KVBG",
      "iata": "VBG",
      "name": "Vandenberg Air Force Base",
      "city": "Lompoc",
      "state": "California",
      "country": "US",
      "elevation": 369,
      "lat": 34.7373008728,
      "lon": -120.5839996338,
      "tz": "America\/Los_Angeles"
  },
  "KVBT": {
      "icao": "KVBT",
      "iata": "",
      "name": "Bentonville Municipal-Louise M Thaden Field",
      "city": "Bentonville",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1296,
      "lat": 36.34569931,
      "lon": -94.21929932,
      "tz": "America\/Chicago"
  },
  "KVCB": {
      "icao": "KVCB",
      "iata": "",
      "name": "Nut Tree Airport",
      "city": "Vacaville",
      "state": "California",
      "country": "US",
      "elevation": 117,
      "lat": 38.3768005371,
      "lon": -121.9619979858,
      "tz": "America\/Los_Angeles"
  },
  "KVCT": {
      "icao": "KVCT",
      "iata": "VCT",
      "name": "Victoria Regional Airport",
      "city": "Victoria",
      "state": "Texas",
      "country": "US",
      "elevation": 115,
      "lat": 28.8526000977,
      "lon": -96.9185028076,
      "tz": "America\/Chicago"
  },
  "KVCV": {
      "icao": "KVCV",
      "iata": "VCV",
      "name": "Southern California Logistics Airport",
      "city": "Victorville",
      "state": "California",
      "country": "US",
      "elevation": 2885,
      "lat": 34.5974998474,
      "lon": -117.3830032349,
      "tz": "America\/Los_Angeles"
  },
  "KVDF": {
      "icao": "KVDF",
      "iata": "",
      "name": "Tampa Executive Airport",
      "city": "Tampa",
      "state": "Florida",
      "country": "US",
      "elevation": 22,
      "lat": 28.013999939,
      "lon": -82.3452987671,
      "tz": "America\/New_York"
  },
  "KVDI": {
      "icao": "KVDI",
      "iata": "VDI",
      "name": "Vidalia Regional Airport",
      "city": "Vidalia",
      "state": "Georgia",
      "country": "US",
      "elevation": 275,
      "lat": 32.1926994324,
      "lon": -82.3712005615,
      "tz": "America\/New_York"
  },
  "KVEL": {
      "icao": "KVEL",
      "iata": "VEL",
      "name": "Vernal Regional Airport",
      "city": "Vernal",
      "state": "Utah",
      "country": "US",
      "elevation": 5278,
      "lat": 40.4408989,
      "lon": -109.5100021,
      "tz": "America\/Denver"
  },
  "KVER": {
      "icao": "KVER",
      "iata": "",
      "name": "Jesse Viertel Memorial Airport",
      "city": "Boonville",
      "state": "Missouri",
      "country": "US",
      "elevation": 715,
      "lat": 38.9467010498,
      "lon": -92.6827011108,
      "tz": "America\/Chicago"
  },
  "KVES": {
      "icao": "KVES",
      "iata": "",
      "name": "Darke County Airport",
      "city": "Versailles",
      "state": "Ohio",
      "country": "US",
      "elevation": 1007,
      "lat": 40.2043991089,
      "lon": -84.5318984985,
      "tz": "America\/New_York"
  },
  "KVGC": {
      "icao": "KVGC",
      "iata": "",
      "name": "Hamilton Municipal Airport",
      "city": "Hamilton",
      "state": "New-York",
      "country": "US",
      "elevation": 1137,
      "lat": 42.84379959,
      "lon": -75.56140137,
      "tz": "America\/New_York"
  },
  "KVGT": {
      "icao": "KVGT",
      "iata": "VGT",
      "name": "North Las Vegas Airport",
      "city": "Las Vegas",
      "state": "Nevada",
      "country": "US",
      "elevation": 2205,
      "lat": 36.2107009888,
      "lon": -115.1940002441,
      "tz": "America\/Los_Angeles"
  },
  "KVHN": {
      "icao": "KVHN",
      "iata": "VHN",
      "name": "Culberson County Airport",
      "city": "Van Horn",
      "state": "Texas",
      "country": "US",
      "elevation": 3957,
      "lat": 31.057800293,
      "lon": -104.783996582,
      "tz": "America\/Chicago"
  },
  "KVIH": {
      "icao": "KVIH",
      "iata": "VIH",
      "name": "Rolla National Airport",
      "city": "Rolla\/Vichy",
      "state": "Missouri",
      "country": "US",
      "elevation": 1148,
      "lat": 38.1273994446,
      "lon": -91.7695007324,
      "tz": "America\/Chicago"
  },
  "KVIQ": {
      "icao": "KVIQ",
      "iata": "",
      "name": "Neillsville Municipal Airport",
      "city": "Neillsville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1237,
      "lat": 44.5581016541,
      "lon": -90.5121994019,
      "tz": "America\/Chicago"
  },
  "KVIS": {
      "icao": "KVIS",
      "iata": "VIS",
      "name": "Visalia Municipal Airport",
      "city": "Visalia",
      "state": "California",
      "country": "US",
      "elevation": 295,
      "lat": 36.3186988831,
      "lon": -119.3929977417,
      "tz": "America\/Los_Angeles"
  },
  "KVJI": {
      "icao": "KVJI",
      "iata": "VJI",
      "name": "Virginia Highlands Airport",
      "city": "Abingdon",
      "state": "Virginia",
      "country": "US",
      "elevation": 2087,
      "lat": 36.6870994568,
      "lon": -82.0333023071,
      "tz": "America\/New_York"
  },
  "KVKS": {
      "icao": "KVKS",
      "iata": "VKS",
      "name": "Vicksburg Municipal Airport",
      "city": "Vicksburg",
      "state": "Mississippi",
      "country": "US",
      "elevation": 106,
      "lat": 32.2392997742,
      "lon": -90.9283981323,
      "tz": "America\/Chicago"
  },
  "KVKX": {
      "icao": "KVKX",
      "iata": "VKX",
      "name": "Potomac Airfield",
      "city": "Friendly",
      "state": "Maryland",
      "country": "US",
      "elevation": 118,
      "lat": 38.74760056,
      "lon": -76.9571991,
      "tz": "America\/New_York"
  },
  "KVLA": {
      "icao": "KVLA",
      "iata": "VLA",
      "name": "Vandalia Municipal Airport",
      "city": "Vandalia",
      "state": "Illinois",
      "country": "US",
      "elevation": 537,
      "lat": 38.9915008545,
      "lon": -89.1661987305,
      "tz": "America\/Chicago"
  },
  "KVLD": {
      "icao": "KVLD",
      "iata": "VLD",
      "name": "Valdosta Regional Airport",
      "city": "Valdosta",
      "state": "Georgia",
      "country": "US",
      "elevation": 203,
      "lat": 30.7824993134,
      "lon": -83.2767028809,
      "tz": "America\/New_York"
  },
  "KVLL": {
      "icao": "KVLL",
      "iata": "",
      "name": "Oakland Troy Airport",
      "city": "Troy",
      "state": "Michigan",
      "country": "US",
      "elevation": 727,
      "lat": 42.54290009,
      "lon": -83.17790222,
      "tz": "America\/Detroit"
  },
  "KVMR": {
      "icao": "KVMR",
      "iata": "",
      "name": "Harold Davidson Field",
      "city": "Vermillion",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1146,
      "lat": 42.76530075,
      "lon": -96.93430328,
      "tz": "America\/Chicago"
  },
  "KVNC": {
      "icao": "KVNC",
      "iata": "VNC",
      "name": "Venice Municipal Airport",
      "city": "Venice",
      "state": "Florida",
      "country": "US",
      "elevation": 18,
      "lat": 27.0715999603,
      "lon": -82.4402999878,
      "tz": "America\/New_York"
  },
  "KVNW": {
      "icao": "KVNW",
      "iata": "",
      "name": "Van Wert County Airport",
      "city": "Van Wert",
      "state": "Ohio",
      "country": "US",
      "elevation": 785,
      "lat": 40.8647003174,
      "lon": -84.6093978882,
      "tz": "America\/New_York"
  },
  "KVNY": {
      "icao": "KVNY",
      "iata": "VNY",
      "name": "Van Nuys Airport",
      "city": "Van Nuys",
      "state": "California",
      "country": "US",
      "elevation": 802,
      "lat": 34.2098007202,
      "lon": -118.4899978638,
      "tz": "America\/Los_Angeles"
  },
  "KVOK": {
      "icao": "KVOK",
      "iata": "VOK",
      "name": "Volk Field",
      "city": "Camp Douglas",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 912,
      "lat": 43.938999176,
      "lon": -90.25340271,
      "tz": "America\/Chicago"
  },
  "KVPC": {
      "icao": "KVPC",
      "iata": "",
      "name": "Cartersville Airport",
      "city": "Cartersville",
      "state": "Georgia",
      "country": "US",
      "elevation": 759,
      "lat": 34.1231002808,
      "lon": -84.8487014771,
      "tz": "America\/New_York"
  },
  "KVPS": {
      "icao": "KVPS",
      "iata": "VPS",
      "name": "Eglin Air Force Base",
      "city": "Valparaiso",
      "state": "Florida",
      "country": "US",
      "elevation": 87,
      "lat": 30.4832000732,
      "lon": -86.5253982544,
      "tz": "America\/Chicago"
  },
  "KVPZ": {
      "icao": "KVPZ",
      "iata": "VPZ",
      "name": "Porter County Municipal Airport",
      "city": "Valparaiso",
      "state": "Indiana",
      "country": "US",
      "elevation": 770,
      "lat": 41.45399857,
      "lon": -87.00710297,
      "tz": "America\/Chicago"
  },
  "KVQQ": {
      "icao": "KVQQ",
      "iata": "VQQ",
      "name": "Cecil Field",
      "city": "Jacksonville",
      "state": "Florida",
      "country": "US",
      "elevation": 81,
      "lat": 30.2187004089,
      "lon": -81.876701355,
      "tz": "America\/New_York"
  },
  "KVRB": {
      "icao": "KVRB",
      "iata": "VRB",
      "name": "Vero Beach Municipal Airport",
      "city": "Vero Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 24,
      "lat": 27.6555995941,
      "lon": -80.4179000854,
      "tz": "America\/New_York"
  },
  "KVSF": {
      "icao": "KVSF",
      "iata": "VSF",
      "name": "Hartness State (Springfield) Airport",
      "city": "Springfield",
      "state": "Vermont",
      "country": "US",
      "elevation": 577,
      "lat": 43.34360123,
      "lon": -72.51730347,
      "tz": "America\/New_York"
  },
  "KVTA": {
      "icao": "KVTA",
      "iata": "",
      "name": "Newark Heath Airport",
      "city": "Newark",
      "state": "Ohio",
      "country": "US",
      "elevation": 884,
      "lat": 40.0247001648,
      "lon": -82.4617996216,
      "tz": "America\/New_York"
  },
  "KVTI": {
      "icao": "KVTI",
      "iata": "",
      "name": "Vinton Veterans Memorial Airpark",
      "city": "Vinton",
      "state": "Iowa",
      "country": "US",
      "elevation": 842,
      "lat": 42.21860123,
      "lon": -92.02590179,
      "tz": "America\/Chicago"
  },
  "KVTN": {
      "icao": "KVTN",
      "iata": "VTN",
      "name": "Miller Field",
      "city": "Valentine",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2596,
      "lat": 42.85779953,
      "lon": -100.5479965,
      "tz": "America\/Chicago"
  },
  "KVUJ": {
      "icao": "KVUJ",
      "iata": "",
      "name": "Stanly County Airport",
      "city": "Albemarle",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 609,
      "lat": 35.4166984558,
      "lon": -80.1508026123,
      "tz": "America\/New_York"
  },
  "KVUO": {
      "icao": "KVUO",
      "iata": "",
      "name": "Pearson Field",
      "city": "Vancouver",
      "state": "Washington",
      "country": "US",
      "elevation": 25,
      "lat": 45.6204986572,
      "lon": -122.65599823,
      "tz": "America\/Los_Angeles"
  },
  "KVVS": {
      "icao": "KVVS",
      "iata": "",
      "name": "Joseph A. Hardy Connellsville Airport",
      "city": "Connellsville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1267,
      "lat": 39.95920181,
      "lon": -79.65709686,
      "tz": "America\/New_York"
  },
  "KVVV": {
      "icao": "KVVV",
      "iata": "",
      "name": "Ortonville Municipal Martinson Field",
      "city": "Ortonville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 45.30569839,
      "lon": -96.42440033,
      "tz": "America\/Chicago"
  },
  "KVWU": {
      "icao": "KVWU",
      "iata": "",
      "name": "Waskish Municipal Airport",
      "city": "Waskish",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1181,
      "lat": 48.1540985107,
      "lon": -94.5168991089,
      "tz": "America\/Chicago"
  },
  "KVYS": {
      "icao": "KVYS",
      "iata": "VYS",
      "name": "Illinois Valley Regional-Walter A Duncan field",
      "city": "Peru",
      "state": "Illinois",
      "country": "US",
      "elevation": 654,
      "lat": 41.35189819,
      "lon": -89.15309906,
      "tz": "America\/Chicago"
  },
  "KW01": {
      "icao": "KW01",
      "iata": "",
      "name": "Tonasket Municipal Airport",
      "city": "Tonasket",
      "state": "Washington",
      "country": "US",
      "elevation": 1311,
      "lat": 48.7248683333,
      "lon": -119.465634722,
      "tz": "America\/Los_Angeles"
  },
  "KW03": {
      "icao": "KW03",
      "iata": "",
      "name": "Wilson Industrial Air Center Airport",
      "city": "Wilson",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 161,
      "lat": 35.770401001,
      "lon": -77.9698028564,
      "tz": "America\/New_York"
  },
  "KW05": {
      "icao": "KW05",
      "iata": "",
      "name": "Gettysburg Regional Airport",
      "city": "Gettysburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 590,
      "lat": 39.84090042,
      "lon": -77.27420044,
      "tz": "America\/New_York"
  },
  "KW22": {
      "icao": "KW22",
      "iata": "",
      "name": "Upshur County Regional Airport",
      "city": "Buckhannon",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1635,
      "lat": 39.00049973,
      "lon": -80.2736969,
      "tz": "America\/New_York"
  },
  "KW28": {
      "icao": "KW28",
      "iata": "",
      "name": "Sequim Valley Airport",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 144,
      "lat": 48.0980987549,
      "lon": -123.18699646,
      "tz": "America\/Los_Angeles"
  },
  "KW31": {
      "icao": "KW31",
      "iata": "",
      "name": "Lunenburg County Airport",
      "city": "Kenbridge",
      "state": "Virginia",
      "country": "US",
      "elevation": 526,
      "lat": 36.9602012634,
      "lon": -78.1849975586,
      "tz": "America\/New_York"
  },
  "KW32": {
      "icao": "KW32",
      "iata": "",
      "name": "Washington Executive Hyde Field",
      "city": "Clinton",
      "state": "Maryland",
      "country": "US",
      "elevation": 249,
      "lat": 38.748298645,
      "lon": -76.932800293,
      "tz": "America\/New_York"
  },
  "KW35": {
      "icao": "KW35",
      "iata": "",
      "name": "Potomac Airpark",
      "city": "Berkeley Springs",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 412,
      "lat": 39.6926002502,
      "lon": -78.1660995483,
      "tz": "America\/New_York"
  },
  "KW38": {
      "icao": "KW38",
      "iata": "",
      "name": "Williamsburg Whitley County Airport",
      "city": "Williamsburg",
      "state": "Kentucky",
      "country": "US",
      "elevation": 1178,
      "lat": 36.7949981689,
      "lon": -84.1995010376,
      "tz": "America\/New_York"
  },
  "KW40": {
      "icao": "KW40",
      "iata": "",
      "name": "Mount Olive Municipal Airport",
      "city": "Mount Olive",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 168,
      "lat": 35.22219849,
      "lon": -78.03780365,
      "tz": "America\/New_York"
  },
  "KW41": {
      "icao": "KW41",
      "iata": "",
      "name": "Crisfield Municipal Airport",
      "city": "Crisfield",
      "state": "Maryland",
      "country": "US",
      "elevation": 4,
      "lat": 38.0176010132,
      "lon": -75.827796936,
      "tz": "America\/New_York"
  },
  "KW43": {
      "icao": "KW43",
      "iata": "",
      "name": "Hulett Municipal Airport",
      "city": "Hulett",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4264,
      "lat": 44.6628990173,
      "lon": -104.5680007935,
      "tz": "America\/Denver"
  },
  "KW63": {
      "icao": "KW63",
      "iata": "",
      "name": "Lake Country Regional Airport",
      "city": "Clarksville",
      "state": "Virginia",
      "country": "US",
      "elevation": 421,
      "lat": 36.5957984924,
      "lon": -78.5600967407,
      "tz": "America\/New_York"
  },
  "KW78": {
      "icao": "KW78",
      "iata": "",
      "name": "William M Tuck Airport",
      "city": "South Boston",
      "state": "Virginia",
      "country": "US",
      "elevation": 420,
      "lat": 36.7099990845,
      "lon": -78.8479995728,
      "tz": "America\/New_York"
  },
  "KW81": {
      "icao": "KW81",
      "iata": "",
      "name": "Crewe Municipal Airport",
      "city": "Crewe",
      "state": "Virginia",
      "country": "US",
      "elevation": 420,
      "lat": 37.1809997559,
      "lon": -78.0982971191,
      "tz": "America\/New_York"
  },
  "KW90": {
      "icao": "KW90",
      "iata": "",
      "name": "New London Airport",
      "city": "Forest",
      "state": "Virginia",
      "country": "US",
      "elevation": 849,
      "lat": 37.2718009949,
      "lon": -79.335899353,
      "tz": "America\/New_York"
  },
  "KW91": {
      "icao": "KW91",
      "iata": "",
      "name": "Smith Mountain Lake Airport",
      "city": "Moneta",
      "state": "Virginia",
      "country": "US",
      "elevation": 892,
      "lat": 37.1077003479,
      "lon": -79.5924987793,
      "tz": "America\/New_York"
  },
  "KW94": {
      "icao": "KW94",
      "iata": "",
      "name": "Camp Peary Landing Strip",
      "city": "Williamsburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 41,
      "lat": 37.30559921,
      "lon": -76.63749695,
      "tz": "America\/New_York"
  },
  "KW95": {
      "icao": "KW95",
      "iata": "",
      "name": "Ocracoke Island Airport",
      "city": "Ocracoke",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 5,
      "lat": 35.1012001038,
      "lon": -75.966003418,
      "tz": "America\/New_York"
  },
  "KW96": {
      "icao": "KW96",
      "iata": "",
      "name": "New Kent County Airport",
      "city": "Quinton",
      "state": "Virginia",
      "country": "US",
      "elevation": 123,
      "lat": 37.503200531,
      "lon": -77.12550354,
      "tz": "America\/New_York"
  },
  "KWAL": {
      "icao": "KWAL",
      "iata": "WAL",
      "name": "Wallops Flight Facility Airport",
      "city": "Wallops Island",
      "state": "Virginia",
      "country": "US",
      "elevation": 40,
      "lat": 37.9402008057,
      "lon": -75.4664001465,
      "tz": "America\/New_York"
  },
  "KWAY": {
      "icao": "KWAY",
      "iata": "WAY",
      "name": "Greene County Airport",
      "city": "Waynesburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1069,
      "lat": 39.90010071,
      "lon": -80.13310242,
      "tz": "America\/New_York"
  },
  "KWBW": {
      "icao": "KWBW",
      "iata": "WBW",
      "name": "Wilkes Barre Wyoming Valley Airport",
      "city": "Wilkes-Barre",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 545,
      "lat": 41.2971992493,
      "lon": -75.8511962891,
      "tz": "America\/New_York"
  },
  "KWDG": {
      "icao": "KWDG",
      "iata": "WDG",
      "name": "Enid Woodring Regional Airport",
      "city": "Enid",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1167,
      "lat": 36.3791999817,
      "lon": -97.7910995483,
      "tz": "America\/Chicago"
  },
  "KWDR": {
      "icao": "KWDR",
      "iata": "WDR",
      "name": "Barrow County Airport",
      "city": "Winder",
      "state": "Georgia",
      "country": "US",
      "elevation": 943,
      "lat": 33.98289871,
      "lon": -83.66739655,
      "tz": "America\/New_York"
  },
  "KWHP": {
      "icao": "KWHP",
      "iata": "WHP",
      "name": "Whiteman Airport",
      "city": "Los Angeles",
      "state": "California",
      "country": "US",
      "elevation": 1003,
      "lat": 34.2593002319,
      "lon": -118.4130020142,
      "tz": "America\/Los_Angeles"
  },
  "KWJF": {
      "icao": "KWJF",
      "iata": "WJF",
      "name": "General WM J Fox Airfield",
      "city": "Lancaster",
      "state": "California",
      "country": "US",
      "elevation": 2351,
      "lat": 34.74110031,
      "lon": -118.2190018,
      "tz": "America\/Los_Angeles"
  },
  "KWLD": {
      "icao": "KWLD",
      "iata": "WLD",
      "name": "Strother Field",
      "city": "Winfield\/Arkansas City",
      "state": "Kansas",
      "country": "US",
      "elevation": 1160,
      "lat": 37.168598175,
      "lon": -97.0375976563,
      "tz": "America\/Chicago"
  },
  "KWLW": {
      "icao": "KWLW",
      "iata": "WLW",
      "name": "Willows Glenn County Airport",
      "city": "Willows",
      "state": "California",
      "country": "US",
      "elevation": 141,
      "lat": 39.51639938,
      "lon": -122.2180023,
      "tz": "America\/Los_Angeles"
  },
  "KWMC": {
      "icao": "KWMC",
      "iata": "WMC",
      "name": "Winnemucca Municipal Airport",
      "city": "Winnemucca",
      "state": "Nevada",
      "country": "US",
      "elevation": 4308,
      "lat": 40.8965988159,
      "lon": -117.8059997559,
      "tz": "America\/Los_Angeles"
  },
  "KWRB": {
      "icao": "KWRB",
      "iata": "WRB",
      "name": "Robins Air Force Base",
      "city": "Warner Robins",
      "state": "Georgia",
      "country": "US",
      "elevation": 294,
      "lat": 32.6400985718,
      "lon": -83.5919036865,
      "tz": "America\/New_York"
  },
  "KWRI": {
      "icao": "KWRI",
      "iata": "WRI",
      "name": "Mc Guire Air Force Base",
      "city": "Wrightstown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 131,
      "lat": 40.0155983,
      "lon": -74.59169769,
      "tz": "America\/New_York"
  },
  "KWRL": {
      "icao": "KWRL",
      "iata": "WRL",
      "name": "Worland Municipal Airport",
      "city": "Worland",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4227,
      "lat": 43.9656982422,
      "lon": -107.9509963989,
      "tz": "America\/Denver"
  },
  "KWSD": {
      "icao": "KWSD",
      "iata": "WSD",
      "name": "Condron Army Air Field",
      "city": "White Sands",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3934,
      "lat": 32.34149933,
      "lon": -106.4029999,
      "tz": "America\/Denver"
  },
  "KWST": {
      "icao": "KWST",
      "iata": "WST",
      "name": "Westerly State Airport",
      "city": "Westerly",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 81,
      "lat": 41.3496017456,
      "lon": -71.8033981323,
      "tz": "America\/New_York"
  },
  "KWVI": {
      "icao": "KWVI",
      "iata": "WVI",
      "name": "Watsonville Municipal Airport",
      "city": "Watsonville",
      "state": "California",
      "country": "US",
      "elevation": 163,
      "lat": 36.9356994629,
      "lon": -121.7900009155,
      "tz": "America\/Los_Angeles"
  },
  "KWVL": {
      "icao": "KWVL",
      "iata": "WVL",
      "name": "Waterville Robert Lafleur Airport",
      "city": "Waterville",
      "state": "Maine",
      "country": "US",
      "elevation": 333,
      "lat": 44.5331993103,
      "lon": -69.6754989624,
      "tz": "America\/New_York"
  },
  "KWWD": {
      "icao": "KWWD",
      "iata": "WWD",
      "name": "Cape May County Airport",
      "city": "Wildwood",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 23,
      "lat": 39.0084991455,
      "lon": -74.9083023071,
      "tz": "America\/New_York"
  },
  "KWWR": {
      "icao": "KWWR",
      "iata": "WWR",
      "name": "West Woodward Airport",
      "city": "Woodward",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2189,
      "lat": 36.438,
      "lon": -99.5226667,
      "tz": "America\/Chicago"
  },
  "KWYS": {
      "icao": "KWYS",
      "iata": "WYS",
      "name": "Yellowstone Airport",
      "city": "West Yellowstone",
      "state": "Montana",
      "country": "US",
      "elevation": 6649,
      "lat": 44.68840027,
      "lon": -111.1179962,
      "tz": "America\/Denver"
  },
  "KX04": {
      "icao": "KX04",
      "iata": "",
      "name": "Orlando Apopka Airport",
      "city": "Apopka",
      "state": "Florida",
      "country": "US",
      "elevation": 143,
      "lat": 28.7071990967,
      "lon": -81.581703186,
      "tz": "America\/New_York"
  },
  "KX05": {
      "icao": "KX05",
      "iata": "",
      "name": "Pilot Country Airport",
      "city": "Brooksville",
      "state": "Florida",
      "country": "US",
      "elevation": 80,
      "lat": 28.3302993774,
      "lon": -82.4964981079,
      "tz": "America\/New_York"
  },
  "KX06": {
      "icao": "KX06",
      "iata": "",
      "name": "Arcadia Municipal Airport",
      "city": "Arcadia",
      "state": "Florida",
      "country": "US",
      "elevation": 60,
      "lat": 27.1919994354,
      "lon": -81.8373031616,
      "tz": "America\/New_York"
  },
  "KX07": {
      "icao": "KX07",
      "iata": "",
      "name": "Lake Wales Municipal Airport",
      "city": "Lake Wales",
      "state": "Florida",
      "country": "US",
      "elevation": 127,
      "lat": 27.8938007355,
      "lon": -81.6203994751,
      "tz": "America\/New_York"
  },
  "KX10": {
      "icao": "KX10",
      "iata": "",
      "name": "Belle Glade State Municipal Airport",
      "city": "Belle Glade",
      "state": "Florida",
      "country": "US",
      "elevation": 14,
      "lat": 26.7008991241,
      "lon": -80.6623001099,
      "tz": "America\/New_York"
  },
  "KX13": {
      "icao": "KX13",
      "iata": "",
      "name": "Carrabelle Thompson Airport",
      "city": "Carrabelle",
      "state": "Florida",
      "country": "US",
      "elevation": 20,
      "lat": 29.8421993256,
      "lon": -84.7009963989,
      "tz": "America\/New_York"
  },
  "KX21": {
      "icao": "KX21",
      "iata": "",
      "name": "Arthur Dunn Air Park",
      "city": "Titusville",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 28.6222991943,
      "lon": -80.8357009888,
      "tz": "America\/New_York"
  },
  "KX26": {
      "icao": "KX26",
      "iata": "",
      "name": "Sebastian Municipal Airport",
      "city": "Sebastian",
      "state": "Florida",
      "country": "US",
      "elevation": 21,
      "lat": 27.8125991821,
      "lon": -80.4959030151,
      "tz": "America\/New_York"
  },
  "KX39": {
      "icao": "KX39",
      "iata": "",
      "name": "Tampa North Aero Park Airport",
      "city": "Tampa",
      "state": "Florida",
      "country": "US",
      "elevation": 68,
      "lat": 28.2213001251,
      "lon": -82.37449646,
      "tz": "America\/New_York"
  },
  "KX43": {
      "icao": "KX43",
      "iata": "",
      "name": "Sunray Airport",
      "city": "Sunray",
      "state": "Texas",
      "country": "US",
      "elevation": 3507,
      "lat": 36.0292015076,
      "lon": -101.8290023804,
      "tz": "America\/Chicago"
  },
  "KX49": {
      "icao": "KX49",
      "iata": "",
      "name": "South Lakeland Airport",
      "city": "Lakeland",
      "state": "Florida",
      "country": "US",
      "elevation": 110,
      "lat": 27.9333992004,
      "lon": -82.0439987183,
      "tz": "America\/New_York"
  },
  "KX50": {
      "icao": "KX50",
      "iata": "",
      "name": "Massey Ranch Airpark",
      "city": "New Smyrna Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 12,
      "lat": 28.9789009094,
      "lon": -80.9251022339,
      "tz": "America\/New_York"
  },
  "KX54": {
      "icao": "KX54",
      "iata": "",
      "name": "Benger Air Park",
      "city": "Friona",
      "state": "Texas",
      "country": "US",
      "elevation": 4003,
      "lat": 34.6542015076,
      "lon": -102.6920013428,
      "tz": "America\/Chicago"
  },
  "KX58": {
      "icao": "KX58",
      "iata": "",
      "name": "Indiantown Airport",
      "city": "Indiantown",
      "state": "Florida",
      "country": "US",
      "elevation": 30,
      "lat": 27.0363998413,
      "lon": -80.4401016235,
      "tz": "America\/New_York"
  },
  "KX59": {
      "icao": "KX59",
      "iata": "",
      "name": "Valkaria Airport",
      "city": "Malabar",
      "state": "Florida",
      "country": "US",
      "elevation": 26,
      "lat": 27.96199989,
      "lon": -80.55979919,
      "tz": "America\/New_York"
  },
  "KX60": {
      "icao": "KX60",
      "iata": "",
      "name": "Williston Municipal Airport",
      "city": "Williston",
      "state": "Florida",
      "country": "US",
      "elevation": 76,
      "lat": 29.3542003632,
      "lon": -82.4729003906,
      "tz": "America\/New_York"
  },
  "KXBP": {
      "icao": "KXBP",
      "iata": "",
      "name": "Bridgeport Municipal Airport",
      "city": "Bridgeport",
      "state": "Texas",
      "country": "US",
      "elevation": 852,
      "lat": 33.1753005981,
      "lon": -97.8283996582,
      "tz": "America\/Chicago"
  },
  "KXFL": {
      "icao": "KXFL",
      "iata": "",
      "name": "Flagler County Airport",
      "city": "Palm Coast",
      "state": "Florida",
      "country": "US",
      "elevation": 33,
      "lat": 29.4673996,
      "lon": -81.20629883,
      "tz": "America\/New_York"
  },
  "KXLL": {
      "icao": "KXLL",
      "iata": "",
      "name": "Allentown Queen City Municipal Airport",
      "city": "Allentown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 399,
      "lat": 40.5703010559,
      "lon": -75.4882965088,
      "tz": "America\/New_York"
  },
  "KXMR": {
      "icao": "KXMR",
      "iata": "",
      "name": "Cape Canaveral AFS Skid Strip",
      "city": "Cocoa Beach",
      "state": "Florida",
      "country": "US",
      "elevation": 10,
      "lat": 28.4675998688,
      "lon": -80.5665969849,
      "tz": "America\/New_York"
  },
  "KXNA": {
      "icao": "KXNA",
      "iata": "XNA",
      "name": "Northwest Arkansas Regional Airport",
      "city": "Fayetteville\/Springdale\/",
      "state": "Arkansas",
      "country": "US",
      "elevation": 1287,
      "lat": 36.2818984985,
      "lon": -94.3068008423,
      "tz": "America\/Chicago"
  },
  "KXNO": {
      "icao": "KXNO",
      "iata": "",
      "name": "North Af Aux Airport",
      "city": "North",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 321,
      "lat": 33.6150016785,
      "lon": -81.0843963623,
      "tz": "America\/New_York"
  },
  "KXSA": {
      "icao": "KXSA",
      "iata": "",
      "name": "Tappahannock-Essex County Airport",
      "city": "Tappahannock",
      "state": "Virginia",
      "country": "US",
      "elevation": 135,
      "lat": 37.8596000671,
      "lon": -76.8940963745,
      "tz": "America\/New_York"
  },
  "KXTA": {
      "icao": "KXTA",
      "iata": "",
      "name": "Homey (Area 51) Airport",
      "city": "Groom Lake",
      "state": "Nevada",
      "country": "US",
      "elevation": 4462,
      "lat": 37.2350006104,
      "lon": -115.8109970093,
      "tz": "America\/Los_Angeles"
  },
  "KXVG": {
      "icao": "KXVG",
      "iata": "",
      "name": "Longville Municipal Airport",
      "city": "Longville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1334,
      "lat": 46.9902000427,
      "lon": -94.2040023804,
      "tz": "America\/Chicago"
  },
  "KY03": {
      "icao": "KY03",
      "iata": "",
      "name": "Springfield Municipal Airport",
      "city": "Springfield",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1324,
      "lat": 42.8800010681,
      "lon": -97.9011993408,
      "tz": "America\/Chicago"
  },
  "KY14": {
      "icao": "KY14",
      "iata": "",
      "name": "Marv Skie Lincoln County Airport",
      "city": "Tea",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1515,
      "lat": 43.4575004578,
      "lon": -96.8020019531,
      "tz": "America\/Chicago"
  },
  "KY19": {
      "icao": "KY19",
      "iata": "",
      "name": "Mandan Municipal Airport",
      "city": "Mandan",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1944,
      "lat": 46.7681999207,
      "lon": -100.8939971924,
      "tz": "America\/North_Dakota\/New_Salem"
  },
  "KY23": {
      "icao": "KY23",
      "iata": "",
      "name": "Chetek Municipal Southworth Airport",
      "city": "Chetek",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1055,
      "lat": 45.30619812,
      "lon": -91.63619995,
      "tz": "America\/Chicago"
  },
  "KY27": {
      "icao": "KY27",
      "iata": "",
      "name": "Standing Rock Airport",
      "city": "Fort Yates",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1633,
      "lat": 46.0663986206,
      "lon": -100.6350021362,
      "tz": "America\/Chicago"
  },
  "KY31": {
      "icao": "KY31",
      "iata": "",
      "name": "West Branch Community Airport",
      "city": "West Branch",
      "state": "Michigan",
      "country": "US",
      "elevation": 882,
      "lat": 44.2448005676,
      "lon": -84.1798019409,
      "tz": "America\/Detroit"
  },
  "KY37": {
      "icao": "KY37",
      "iata": "",
      "name": "Park River W C Skjerven Field",
      "city": "Park River",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1104,
      "lat": 48.39400101,
      "lon": -97.78079987,
      "tz": "America\/Chicago"
  },
  "KY43": {
      "icao": "KY43",
      "iata": "",
      "name": "Anita Municipal Kevin Burke Memorial Field",
      "city": "Anita",
      "state": "Iowa",
      "country": "US",
      "elevation": 1251,
      "lat": 41.4402999878,
      "lon": -94.7696990967,
      "tz": "America\/Chicago"
  },
  "KY47": {
      "icao": "KY47",
      "iata": "",
      "name": "Oakland Southwest Airport",
      "city": "New Hudson",
      "state": "Michigan",
      "country": "US",
      "elevation": 926,
      "lat": 42.5031013489,
      "lon": -83.6237030029,
      "tz": "America\/Detroit"
  },
  "KY50": {
      "icao": "KY50",
      "iata": "",
      "name": "Wautoma Municipal Airport",
      "city": "Wautoma",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 859,
      "lat": 44.0415992737,
      "lon": -89.3044967651,
      "tz": "America\/Chicago"
  },
  "KY51": {
      "icao": "KY51",
      "iata": "",
      "name": "Viroqua Municipal Airport",
      "city": "Viroqua",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1292,
      "lat": 43.5793991089,
      "lon": -90.9131011963,
      "tz": "America\/Chicago"
  },
  "KY55": {
      "icao": "KY55",
      "iata": "",
      "name": "Crandon Municipal Airport",
      "city": "Crandon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1646,
      "lat": 45.5166015625,
      "lon": -88.9334030151,
      "tz": "America\/Chicago"
  },
  "KY65": {
      "icao": "KY65",
      "iata": "",
      "name": "Calvin Campbell Municipal Airport",
      "city": "Indian River",
      "state": "Michigan",
      "country": "US",
      "elevation": 602,
      "lat": 45.4082984924,
      "lon": -84.5999984741,
      "tz": "America\/Detroit"
  },
  "KY70": {
      "icao": "KY70",
      "iata": "",
      "name": "Ionia County Airport",
      "city": "Ionia",
      "state": "Michigan",
      "country": "US",
      "elevation": 818,
      "lat": 42.9379997253,
      "lon": -85.0605010986,
      "tz": "America\/Detroit"
  },
  "KY72": {
      "icao": "KY72",
      "iata": "",
      "name": "Bloyer Field",
      "city": "Tomah",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 966,
      "lat": 43.97499847,
      "lon": -90.48349762,
      "tz": "America\/Chicago"
  },
  "KY74": {
      "icao": "KY74",
      "iata": "",
      "name": "Parshall Hankins Airport",
      "city": "Parshall",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2031,
      "lat": 47.9364013672,
      "lon": -102.141998291,
      "tz": "America\/Chicago"
  },
  "KY83": {
      "icao": "KY83",
      "iata": "",
      "name": "Sandusky City Airport",
      "city": "Sandusky",
      "state": "Michigan",
      "country": "US",
      "elevation": 776,
      "lat": 43.4542007446,
      "lon": -82.8494033813,
      "tz": "America\/Detroit"
  },
  "KY89": {
      "icao": "KY89",
      "iata": "",
      "name": "Kalkaska City Airport",
      "city": "Kalkaska",
      "state": "Michigan",
      "country": "US",
      "elevation": 1030,
      "lat": 44.7252006531,
      "lon": -85.2031021118,
      "tz": "America\/Detroit"
  },
  "KY93": {
      "icao": "KY93",
      "iata": "",
      "name": "Atlanta Municipal Airport",
      "city": "Atlanta",
      "state": "Michigan",
      "country": "US",
      "elevation": 875,
      "lat": 45,
      "lon": -84.1333007813,
      "tz": "America\/Detroit"
  },
  "KY94": {
      "icao": "KY94",
      "iata": "",
      "name": "East Jordan City Airport",
      "city": "East Jordan",
      "state": "Michigan",
      "country": "US",
      "elevation": 642,
      "lat": 45.1301002502,
      "lon": -85.1065979004,
      "tz": "America\/Detroit"
  },
  "KY95": {
      "icao": "KY95",
      "iata": "",
      "name": "Hillman Airport",
      "city": "Hillman",
      "state": "Michigan",
      "country": "US",
      "elevation": 850,
      "lat": 45.0833015442,
      "lon": -83.9402999878,
      "tz": "America\/Detroit"
  },
  "KYIP": {
      "icao": "KYIP",
      "iata": "YIP",
      "name": "Willow Run Airport",
      "city": "Detroit",
      "state": "Michigan",
      "country": "US",
      "elevation": 716,
      "lat": 42.23789978,
      "lon": -83.53040314,
      "tz": "America\/Detroit"
  },
  "KYKM": {
      "icao": "KYKM",
      "iata": "YKM",
      "name": "Yakima Air Terminal McAllister Field",
      "city": "Yakima",
      "state": "Washington",
      "country": "US",
      "elevation": 1099,
      "lat": 46.56819916,
      "lon": -120.5439987,
      "tz": "America\/Los_Angeles"
  },
  "KYKN": {
      "icao": "KYKN",
      "iata": "YKN",
      "name": "Chan Gurney Municipal Airport",
      "city": "Yankton",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1306,
      "lat": 42.9166984558,
      "lon": -97.3859024048,
      "tz": "America\/Chicago"
  },
  "KYNG": {
      "icao": "KYNG",
      "iata": "YNG",
      "name": "Youngstown Warren Regional Airport",
      "city": "Youngstown\/Warren",
      "state": "Ohio",
      "country": "US",
      "elevation": 1192,
      "lat": 41.26070023,
      "lon": -80.67910004,
      "tz": "America\/New_York"
  },
  "KZ01": {
      "icao": "KZ01",
      "iata": "",
      "name": "Eglin Auxiliary Field 6 Airport",
      "city": "Valparaiso",
      "state": "Florida",
      "country": "US",
      "elevation": 120,
      "lat": 30.6336994171,
      "lon": -86.7426986694,
      "tz": "America\/Chicago"
  },
  "KZ10": {
      "icao": "KZ10",
      "iata": "",
      "name": "Pacemaker Landing Zone Airport",
      "city": "",
      "state": "Washington",
      "country": "US",
      "elevation": 397,
      "lat": 47.0217018127,
      "lon": -122.4459991455,
      "tz": "America\/Los_Angeles"
  },
  "KZ98": {
      "icao": "KZ98",
      "iata": "",
      "name": "Ottawa Executive Airport",
      "city": "Zeeland",
      "state": "Michigan",
      "country": "US",
      "elevation": 740,
      "lat": 42.817199707,
      "lon": -85.9281005859,
      "tz": "America\/Detroit"
  },
  "KZEF": {
      "icao": "KZEF",
      "iata": "",
      "name": "Elkin Municipal Airport",
      "city": "Elkin",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1068,
      "lat": 36.2799987793,
      "lon": -80.7861022949,
      "tz": "America\/New_York"
  },
  "KZER": {
      "icao": "KZER",
      "iata": "",
      "name": "Schuylkill County \/Joe Zerbey\/ Airport",
      "city": "Pottsville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1729,
      "lat": 40.70650101,
      "lon": -76.37310028,
      "tz": "America\/New_York"
  },
  "KZPH": {
      "icao": "KZPH",
      "iata": "ZPH",
      "name": "Zephyrhills Municipal Airport",
      "city": "Zephyrhills",
      "state": "Florida",
      "country": "US",
      "elevation": 90,
      "lat": 28.2282009125,
      "lon": -82.1558990479,
      "tz": "America\/New_York"
  },
  "KZUN": {
      "icao": "KZUN",
      "iata": "",
      "name": "Black Rock Airport",
      "city": "Zuni Pueblo",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6454,
      "lat": 35.0831985474,
      "lon": -108.7919998169,
      "tz": "America\/Denver"
  },
  "KZZV": {
      "icao": "KZZV",
      "iata": "ZZV",
      "name": "Zanesville Municipal Airport",
      "city": "Zanesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 900,
      "lat": 39.9444007874,
      "lon": -81.8920974731,
      "tz": "America\/New_York"
  },
  "LA01": {
      "icao": "LA01",
      "iata": "",
      "name": "Millers Flying Service Airport",
      "city": "Basile",
      "state": "Louisiana",
      "country": "US",
      "elevation": 44,
      "lat": 30.483499527,
      "lon": -92.5467987061,
      "tz": "America\/Chicago"
  },
  "LA03": {
      "icao": "LA03",
      "iata": "",
      "name": "Daniel Airport",
      "city": "Addis",
      "state": "Louisiana",
      "country": "US",
      "elevation": 18,
      "lat": 30.3532009125,
      "lon": -91.2820968628,
      "tz": "America\/Chicago"
  },
  "LA04": {
      "icao": "LA04",
      "iata": "",
      "name": "Cane-Air Airport",
      "city": "Belle Rose",
      "state": "Louisiana",
      "country": "US",
      "elevation": 17,
      "lat": 30.0501995087,
      "lon": -91.0668029785,
      "tz": "America\/Chicago"
  },
  "LA07": {
      "icao": "LA07",
      "iata": "",
      "name": "Price Ultralightport",
      "city": "Abita Springs",
      "state": "Louisiana",
      "country": "US",
      "elevation": 50,
      "lat": 30.5174999237,
      "lon": -90.0083007813,
      "tz": "America\/Chicago"
  },
  "LA13": {
      "icao": "LA13",
      "iata": "",
      "name": "Shaw Crop Service Airport",
      "city": "Hammond",
      "state": "Louisiana",
      "country": "US",
      "elevation": 95,
      "lat": 30.5781993866,
      "lon": -90.31590271,
      "tz": "America\/Chicago"
  },
  "LA15": {
      "icao": "LA15",
      "iata": "",
      "name": "Wilbert Airport",
      "city": "Plaquemine",
      "state": "Louisiana",
      "country": "US",
      "elevation": 12,
      "lat": 30.2598991394,
      "lon": -91.2862014771,
      "tz": "America\/Chicago"
  },
  "LA16": {
      "icao": "LA16",
      "iata": "",
      "name": "Castille Field",
      "city": "Breaux Bridge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 25,
      "lat": 30.2460002899,
      "lon": -91.8667984009,
      "tz": "America\/Chicago"
  },
  "LA17": {
      "icao": "LA17",
      "iata": "",
      "name": "Pioneer Field Flight Park Ultralightport",
      "city": "Bossier City",
      "state": "Louisiana",
      "country": "US",
      "elevation": 173,
      "lat": 32.6017990112,
      "lon": -93.6940994263,
      "tz": "America\/Chicago"
  },
  "LA21": {
      "icao": "LA21",
      "iata": "",
      "name": "Chloe Airport",
      "city": "Lake Charles",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 30.2548999786,
      "lon": -93.1352005005,
      "tz": "America\/Chicago"
  },
  "LA25": {
      "icao": "LA25",
      "iata": "",
      "name": "Central Farmers Coop Airport",
      "city": "Mamou",
      "state": "Louisiana",
      "country": "US",
      "elevation": 68,
      "lat": 30.6973991394,
      "lon": -92.4140014648,
      "tz": "America\/Chicago"
  },
  "LA26": {
      "icao": "LA26",
      "iata": "",
      "name": "Unicorn Airport",
      "city": "Folsom",
      "state": "Louisiana",
      "country": "US",
      "elevation": 147,
      "lat": 30.6611003876,
      "lon": -90.2332992554,
      "tz": "America\/Chicago"
  },
  "LA30": {
      "icao": "LA30",
      "iata": "",
      "name": "Phoenix Airport",
      "city": "Rayne",
      "state": "Louisiana",
      "country": "US",
      "elevation": 31,
      "lat": 30.2481994629,
      "lon": -92.2416992188,
      "tz": "America\/Chicago"
  },
  "LA31": {
      "icao": "LA31",
      "iata": "",
      "name": "Gotreaux Strip",
      "city": "Lacassine",
      "state": "Louisiana",
      "country": "US",
      "elevation": 16,
      "lat": 30.2000007629,
      "lon": -92.9332962036,
      "tz": "America\/Chicago"
  },
  "LA32": {
      "icao": "LA32",
      "iata": "",
      "name": "Lake Air Service Airport",
      "city": "Lake Arthur",
      "state": "Louisiana",
      "country": "US",
      "elevation": 11,
      "lat": 30.0834999084,
      "lon": -92.6836013794,
      "tz": "America\/Chicago"
  },
  "LA33": {
      "icao": "LA33",
      "iata": "",
      "name": "Cuba Farm Airport",
      "city": "Monroe",
      "state": "Louisiana",
      "country": "US",
      "elevation": 80,
      "lat": 32.576499939,
      "lon": -92.1137008667,
      "tz": "America\/Chicago"
  },
  "LA34": {
      "icao": "LA34",
      "iata": "",
      "name": "Reno Flight Park Airport",
      "city": "Monroe",
      "state": "Louisiana",
      "country": "US",
      "elevation": 68,
      "lat": 32.4052009583,
      "lon": -92.0663986206,
      "tz": "America\/Chicago"
  },
  "LA35": {
      "icao": "LA35",
      "iata": "",
      "name": "W & E Air Service Airport",
      "city": "Monterey",
      "state": "Louisiana",
      "country": "US",
      "elevation": 54,
      "lat": 31.447599411,
      "lon": -91.6838989258,
      "tz": "America\/Chicago"
  },
  "LA36": {
      "icao": "LA36",
      "iata": "",
      "name": "Whitehall Field Ultralightport",
      "city": "Convent",
      "state": "Louisiana",
      "country": "US",
      "elevation": 16,
      "lat": 30.07980537,
      "lon": -90.8932724,
      "tz": "America\/Chicago"
  },
  "LA40": {
      "icao": "LA40",
      "iata": "",
      "name": "F L Braughton Airport",
      "city": "Vidalia",
      "state": "Louisiana",
      "country": "US",
      "elevation": 50,
      "lat": 31.3439006805,
      "lon": -91.5927963257,
      "tz": "America\/Chicago"
  },
  "LA41": {
      "icao": "LA41",
      "iata": "",
      "name": "Dupuis Airport",
      "city": "Breaux Bridge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.315667,
      "lon": -91.940778,
      "tz": "America\/Chicago"
  },
  "LA42": {
      "icao": "LA42",
      "iata": "",
      "name": "Capozzoli Airport",
      "city": "Prairieville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 24,
      "lat": 30.3502998352,
      "lon": -90.922203064,
      "tz": "America\/Chicago"
  },
  "LA46": {
      "icao": "LA46",
      "iata": "",
      "name": "Omni Airport",
      "city": "Port Allen",
      "state": "Louisiana",
      "country": "US",
      "elevation": 24,
      "lat": 30.5041007996,
      "lon": -91.3022994995,
      "tz": "America\/Chicago"
  },
  "LA48": {
      "icao": "LA48",
      "iata": "",
      "name": "Max Airport",
      "city": "Cottonport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 55,
      "lat": 30.9939002991,
      "lon": -92.0446014404,
      "tz": "America\/Chicago"
  },
  "LA51": {
      "icao": "LA51",
      "iata": "",
      "name": "Stuart Airstrip",
      "city": "Coushatta",
      "state": "Louisiana",
      "country": "US",
      "elevation": 130,
      "lat": 32.0181999207,
      "lon": -93.4307022095,
      "tz": "America\/Chicago"
  },
  "LA52": {
      "icao": "LA52",
      "iata": "",
      "name": "T & M Ag Aviation Airport",
      "city": "Dixie",
      "state": "Louisiana",
      "country": "US",
      "elevation": 185,
      "lat": 32.6987991333,
      "lon": -93.8237991333,
      "tz": "America\/Chicago"
  },
  "LA53": {
      "icao": "LA53",
      "iata": "",
      "name": "Tensas Flying Service Airport",
      "city": "St. Joseph",
      "state": "Louisiana",
      "country": "US",
      "elevation": 96,
      "lat": 31.9775,
      "lon": -91.263889,
      "tz": "America\/Chicago"
  },
  "LA54": {
      "icao": "LA54",
      "iata": "",
      "name": "Gilliam Airport",
      "city": "Gilliam",
      "state": "Louisiana",
      "country": "US",
      "elevation": 194,
      "lat": 32.837600708,
      "lon": -93.8376998901,
      "tz": "America\/Chicago"
  },
  "LA56": {
      "icao": "LA56",
      "iata": "",
      "name": "Naylor Airport",
      "city": "Robson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 155,
      "lat": 32.3418006897,
      "lon": -93.6585006714,
      "tz": "America\/Chicago"
  },
  "LA57": {
      "icao": "LA57",
      "iata": "",
      "name": "Mowata Flying Service-Eunice Airport",
      "city": "Eunice",
      "state": "Louisiana",
      "country": "US",
      "elevation": 45,
      "lat": 30.39909935,
      "lon": -92.408996582,
      "tz": "America\/Chicago"
  },
  "LA58": {
      "icao": "LA58",
      "iata": "",
      "name": "Peter Creek Ranch Airport",
      "city": "Spearsville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 200,
      "lat": 32.9790000916,
      "lon": -92.5932006836,
      "tz": "America\/Chicago"
  },
  "LA60": {
      "icao": "LA60",
      "iata": "",
      "name": "Live Oak Landing Strip",
      "city": "Abbeville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 6,
      "lat": 29.8169002533,
      "lon": -92.1167984009,
      "tz": "America\/Chicago"
  },
  "LA61": {
      "icao": "LA61",
      "iata": "",
      "name": "Kenan Airstrip",
      "city": "Kaplan",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.00839996,
      "lon": -92.24120331,
      "tz": "America\/Chicago"
  },
  "LA63": {
      "icao": "LA63",
      "iata": "",
      "name": "Travis Airport",
      "city": "Bastrop",
      "state": "Louisiana",
      "country": "US",
      "elevation": 122,
      "lat": 32.971698761,
      "lon": -91.8308029175,
      "tz": "America\/Chicago"
  },
  "LA67": {
      "icao": "LA67",
      "iata": "",
      "name": "Angola Airstrip",
      "city": "Angola",
      "state": "Louisiana",
      "country": "US",
      "elevation": 45,
      "lat": 30.9515991211,
      "lon": -91.5848007202,
      "tz": "America\/Chicago"
  },
  "LA68": {
      "icao": "LA68",
      "iata": "",
      "name": "Zaunbrecher Strip",
      "city": "Iota",
      "state": "Louisiana",
      "country": "US",
      "elevation": 26,
      "lat": 30.3157997131,
      "lon": -92.5198974609,
      "tz": "America\/Chicago"
  },
  "LA71": {
      "icao": "LA71",
      "iata": "",
      "name": "O'Brien Flying Service Airport",
      "city": "Iowa",
      "state": "Louisiana",
      "country": "US",
      "elevation": 26,
      "lat": 30.2243995667,
      "lon": -93.0001983643,
      "tz": "America\/Chicago"
  },
  "LA73": {
      "icao": "LA73",
      "iata": "",
      "name": "Huffaker Field",
      "city": "Houma",
      "state": "Louisiana",
      "country": "US",
      "elevation": 6,
      "lat": 29.6086006165,
      "lon": -90.8022994995,
      "tz": "America\/Chicago"
  },
  "LA75": {
      "icao": "LA75",
      "iata": "",
      "name": "Glenn's Strip Ultralightport",
      "city": "Duson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 29,
      "lat": 30.2052784,
      "lon": -92.21444702,
      "tz": "America\/Chicago"
  },
  "LA76": {
      "icao": "LA76",
      "iata": "",
      "name": "Spillers Club Airport",
      "city": "Breaux Bridge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 16,
      "lat": 30.2294006348,
      "lon": -91.9084014893,
      "tz": "America\/Chicago"
  },
  "LA77": {
      "icao": "LA77",
      "iata": "",
      "name": "Mixon Airport",
      "city": "Bunkie",
      "state": "Louisiana",
      "country": "US",
      "elevation": 60,
      "lat": 30.9631996155,
      "lon": -92.1745986938,
      "tz": "America\/Chicago"
  },
  "LA79": {
      "icao": "LA79",
      "iata": "",
      "name": "Sycamore Airport",
      "city": "Amite",
      "state": "Louisiana",
      "country": "US",
      "elevation": 180,
      "lat": 30.7185001373,
      "lon": -90.3579025269,
      "tz": "America\/Chicago"
  },
  "LA81": {
      "icao": "LA81",
      "iata": "",
      "name": "Cheneyville Airport",
      "city": "Cheneyville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 63,
      "lat": 30.0016002655,
      "lon": -92.2800979614,
      "tz": "America\/Chicago"
  },
  "LA83": {
      "icao": "LA83",
      "iata": "",
      "name": "Mc Cutcheon Field",
      "city": "Clinton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 272,
      "lat": 30.8959999084,
      "lon": -91.0458984375,
      "tz": "America\/Chicago"
  },
  "LA87": {
      "icao": "LA87",
      "iata": "",
      "name": "Covington-Vincent Airport",
      "city": "Covington",
      "state": "Louisiana",
      "country": "US",
      "elevation": 35,
      "lat": 30.5095996857,
      "lon": -90.1529006958,
      "tz": "America\/Chicago"
  },
  "LA88": {
      "icao": "LA88",
      "iata": "",
      "name": "Hensgens Strip",
      "city": "Crowley",
      "state": "Louisiana",
      "country": "US",
      "elevation": 35,
      "lat": 30.3446006775,
      "lon": -92.3432006836,
      "tz": "America\/Chicago"
  },
  "LA90": {
      "icao": "LA90",
      "iata": "",
      "name": "Walsh Airport",
      "city": "Crowley",
      "state": "Louisiana",
      "country": "US",
      "elevation": 26,
      "lat": 30.4610004425,
      "lon": -92.4262008667,
      "tz": "America\/Chicago"
  },
  "LA91": {
      "icao": "LA91",
      "iata": "",
      "name": "Gary Landing Strip Ultralightport",
      "city": "Parks",
      "state": "Louisiana",
      "country": "US",
      "elevation": 21,
      "lat": 30.2213001251,
      "lon": -91.8335037231,
      "tz": "America\/Chicago"
  },
  "LA94": {
      "icao": "LA94",
      "iata": "",
      "name": "Corkern Airport",
      "city": "Enon",
      "state": "Louisiana",
      "country": "US",
      "elevation": 105,
      "lat": 30.7224006653,
      "lon": -90.0653991699,
      "tz": "America\/Chicago"
  },
  "LAGJ": {
      "icao": "LAGJ",
      "iata": "",
      "name": "Gjader Air Base",
      "city": "Gjader",
      "state": "Lezhe",
      "country": "AL",
      "elevation": 23,
      "lat": 41.895198822,
      "lon": 19.5986995697,
      "tz": "Europe\/Tirane"
  },
  "LAKO": {
      "icao": "LAKO",
      "iata": "",
      "name": "Korce Northwest Airport",
      "city": "Korce",
      "state": "Korce",
      "country": "AL",
      "elevation": 665,
      "lat": 40.6456985474,
      "lon": 20.7415008545,
      "tz": "Europe\/Tirane"
  },
  "LAKU": {
      "icao": "LAKU",
      "iata": "",
      "name": "Kukes Airport",
      "city": "Kukes",
      "state": "Kukes",
      "country": "AL",
      "elevation": 1120,
      "lat": 42.0336990356,
      "lon": 20.4158992767,
      "tz": "Europe\/Tirane"
  },
  "LAKV": {
      "icao": "LAKV",
      "iata": "",
      "name": "Kucove Air Base",
      "city": "Kucove",
      "state": "Berat",
      "country": "AL",
      "elevation": 135,
      "lat": 40.771900177,
      "lon": 19.9018993378,
      "tz": "Europe\/Tirane"
  },
  "LATI": {
      "icao": "LATI",
      "iata": "TIA",
      "name": "Tirana International Airport Mother Teresa",
      "city": "Tirana",
      "state": "",
      "country": "AL",
      "elevation": 126,
      "lat": 41.4146995544,
      "lon": 19.7206001282,
      "tz": "Europe\/Tirane"
  },
  "LAVL": {
      "icao": "LAVL",
      "iata": "",
      "name": "Vlore Air Base",
      "city": "Vlore",
      "state": "Vlore",
      "country": "AL",
      "elevation": 3,
      "lat": 40.4761009216,
      "lon": 19.474199295,
      "tz": "Europe\/Tirane"
  },
  "LB11": {
      "icao": "LB11",
      "iata": "",
      "name": "Tsalapita Airport",
      "city": "Tsalapita",
      "state": "Plovdiv",
      "country": "BG",
      "elevation": 630,
      "lat": 42.1876983643,
      "lon": 24.5349006653,
      "tz": "Europe\/Sofia"
  },
  "LB13": {
      "icao": "LB13",
      "iata": "",
      "name": "Radomir Dolni Rakovets Airfield",
      "city": "Radomir",
      "state": "Pernik",
      "country": "BG",
      "elevation": 625,
      "lat": 42.4441986084,
      "lon": 22.983499527,
      "tz": "Europe\/Sofia"
  },
  "LB18": {
      "icao": "LB18",
      "iata": "",
      "name": "Tenevo Airport",
      "city": "Tenevo",
      "state": "Yambol",
      "country": "BG",
      "elevation": 390,
      "lat": 42.3464012146,
      "lon": 26.5739002228,
      "tz": "Europe\/Sofia"
  },
  "LB19": {
      "icao": "LB19",
      "iata": "",
      "name": "Zimnitsa Airport",
      "city": "Zimnitsa",
      "state": "Yambol",
      "country": "BG",
      "elevation": 518,
      "lat": 42.6004981995,
      "lon": 26.6336994171,
      "tz": "Europe\/Sofia"
  },
  "LB22": {
      "icao": "LB22",
      "iata": "",
      "name": "Stanke Dimitrov Airport",
      "city": "Sapareva Banya",
      "state": "Kyustendil",
      "country": "BG",
      "elevation": 2149,
      "lat": 42.3116989136,
      "lon": 23.2469005585,
      "tz": "Europe\/Sofia"
  },
  "LB27": {
      "icao": "LB27",
      "iata": "",
      "name": "Podem Airport",
      "city": "Podem",
      "state": "Pleven",
      "country": "BG",
      "elevation": 150,
      "lat": 43.5481987,
      "lon": 24.5904006958,
      "tz": "Europe\/Sofia"
  },
  "LB29": {
      "icao": "LB29",
      "iata": "",
      "name": "Maslarevo Airport",
      "city": "Maslarevo",
      "state": "Veliko-Turnovo",
      "country": "BG",
      "elevation": 349,
      "lat": 43.4053993225,
      "lon": 25.4740009308,
      "tz": "Europe\/Sofia"
  },
  "LB30": {
      "icao": "LB30",
      "iata": "",
      "name": "Stryama Airport",
      "city": "Stryama",
      "state": "Plovdiv",
      "country": "BG",
      "elevation": 560,
      "lat": 42.2853012085,
      "lon": 24.8684997559,
      "tz": "Europe\/Sofia"
  },
  "LB32": {
      "icao": "LB32",
      "iata": "",
      "name": "Konush Airport",
      "city": "Konush",
      "state": "Plovdiv",
      "country": "BG",
      "elevation": 500,
      "lat": 42.0723991394,
      "lon": 25.0533008575,
      "tz": "Europe\/Sofia"
  },
  "LB33": {
      "icao": "LB33",
      "iata": "",
      "name": "Leskovo Airport",
      "city": "Leskovo",
      "state": "Dobrich",
      "country": "BG",
      "elevation": 700,
      "lat": 43.726600647,
      "lon": 27.686000824,
      "tz": "Europe\/Sofia"
  },
  "LB34": {
      "icao": "LB34",
      "iata": "",
      "name": "Stefanovo Airport",
      "city": "Stefanovo",
      "state": "Dobrich",
      "country": "BG",
      "elevation": 905,
      "lat": 43.5069007874,
      "lon": 27.8449001312,
      "tz": "Europe\/Sofia"
  },
  "LB35": {
      "icao": "LB35",
      "iata": "",
      "name": "Golyama Smolnitsa Airport",
      "city": "Smolnitsa",
      "state": "Dobrich",
      "country": "BG",
      "elevation": 810,
      "lat": 43.6278991699,
      "lon": 27.6940994263,
      "tz": "Europe\/Sofia"
  },
  "LB36": {
      "icao": "LB36",
      "iata": "",
      "name": "Sokolovo Airport",
      "city": "Sokolovo",
      "state": "Dobrich",
      "country": "BG",
      "elevation": 760,
      "lat": 43.4756011963,
      "lon": 28.1026992798,
      "tz": "Europe\/Sofia"
  },
  "LB37": {
      "icao": "LB37",
      "iata": "",
      "name": "Stanke Dimitrov Highway Strip",
      "city": "Boboshevo",
      "state": "Kyustendil",
      "country": "BG",
      "elevation": 3447,
      "lat": 42.1757011414,
      "lon": 23.0508995056,
      "tz": "Europe\/Sofia"
  },
  "LB38": {
      "icao": "LB38",
      "iata": "",
      "name": "Mustrak Airport",
      "city": "Mustrak",
      "state": "Khaskovo",
      "country": "BG",
      "elevation": 675,
      "lat": 41.8400001526,
      "lon": 26.3097000122,
      "tz": "Europe\/Sofia"
  },
  "LB39": {
      "icao": "LB39",
      "iata": "",
      "name": "Graf Ignatievo South Airport",
      "city": "Graf Ignatievo",
      "state": "Plovdiv",
      "country": "BG",
      "elevation": 623,
      "lat": 42.2631988525,
      "lon": 24.7192993164,
      "tz": "Europe\/Sofia"
  },
  "LB42": {
      "icao": "LB42",
      "iata": "",
      "name": "Kamenets Airport",
      "city": "Kamenets",
      "state": "Pleven",
      "country": "BG",
      "elevation": 420,
      "lat": 43.3273010254,
      "lon": 25.0034008026,
      "tz": "Europe\/Sofia"
  },
  "LB45": {
      "icao": "LB45",
      "iata": "",
      "name": "Petrich Airport",
      "city": "Petrich",
      "state": "Blagoevgrad",
      "country": "BG",
      "elevation": 371,
      "lat": 41.448299408,
      "lon": 23.2171001434,
      "tz": "Europe\/Sofia"
  },
  "LBBG": {
      "icao": "LBBG",
      "iata": "BOJ",
      "name": "Burgas Airport",
      "city": "Burgas",
      "state": "Burgas",
      "country": "BG",
      "elevation": 135,
      "lat": 42.5695991516,
      "lon": 27.5151996613,
      "tz": "Europe\/Sofia"
  },
  "LBBO": {
      "icao": "LBBO",
      "iata": "",
      "name": "Bohot Airport",
      "city": "Bohot",
      "state": "Pleven",
      "country": "BG",
      "elevation": 1162,
      "lat": 43.306388855,
      "lon": 24.6913890839,
      "tz": "Europe\/Sofia"
  },
  "LBDB": {
      "icao": "LBDB",
      "iata": "",
      "name": "Dolna Banya Airport",
      "city": "Dolna Banya",
      "state": "Sofia",
      "country": "BG",
      "elevation": 1779,
      "lat": 42.3085289001,
      "lon": 23.8204174042,
      "tz": "Europe\/Sofia"
  },
  "LBGO": {
      "icao": "LBGO",
      "iata": "GOZ",
      "name": "Gorna Oryahovitsa Airport",
      "city": "Gorna Oryahovitsa",
      "state": "Veliko-Turnovo",
      "country": "BG",
      "elevation": 285,
      "lat": 43.1514015198,
      "lon": 25.7129001617,
      "tz": "Europe\/Sofia"
  },
  "LBGR": {
      "icao": "LBGR",
      "iata": "",
      "name": "Grivitsa Airport",
      "city": "Grivitsa",
      "state": "Lovech",
      "country": "BG",
      "elevation": 919,
      "lat": 43.4000015259,
      "lon": 24.7666664124,
      "tz": "Europe\/Sofia"
  },
  "LBHS": {
      "icao": "LBHS",
      "iata": "HKV",
      "name": "Uzundzhovo Air Base",
      "city": "Haskovo",
      "state": "Khaskovo",
      "country": "BG",
      "elevation": 160,
      "lat": 41.976398468,
      "lon": 25.589799881,
      "tz": "Europe\/Sofia"
  },
  "LBHT": {
      "icao": "LBHT",
      "iata": "",
      "name": "Ihtiman Airport",
      "city": "Ihtiman",
      "state": "Sofia",
      "country": "BG",
      "elevation": 2113,
      "lat": 42.4218902588,
      "lon": 23.7672214508,
      "tz": "Europe\/Sofia"
  },
  "LBIA": {
      "icao": "LBIA",
      "iata": "JAM",
      "name": "Bezmer Air Base",
      "city": "Yambol",
      "state": "Burgas",
      "country": "BG",
      "elevation": 509,
      "lat": 42.4548988342,
      "lon": 26.3521995544,
      "tz": "Europe\/Sofia"
  },
  "LBKJ": {
      "icao": "LBKJ",
      "iata": "",
      "name": "Kainardzha Airport",
      "city": "Kainardzha",
      "state": "Silistra",
      "country": "BG",
      "elevation": 575,
      "lat": 43.9704170227,
      "lon": 27.4695568085,
      "tz": "Europe\/Sofia"
  },
  "LBKL": {
      "icao": "LBKL",
      "iata": "",
      "name": "Kalvacha Airport",
      "city": "Kalvacha",
      "state": "Khaskovo",
      "country": "BG",
      "elevation": 1083,
      "lat": 42.5862503052,
      "lon": 25.4262504578,
      "tz": "Europe\/Sofia"
  },
  "LBLS": {
      "icao": "LBLS",
      "iata": "",
      "name": "Lesnovo Airport",
      "city": "Lesnovo",
      "state": "Sofia",
      "country": "BG",
      "elevation": 1825,
      "lat": 42.6344985962,
      "lon": 23.6464443207,
      "tz": "Europe\/Sofia"
  },
  "LBMG": {
      "icao": "LBMG",
      "iata": "",
      "name": "Gabrovnitsa Air Base",
      "city": "Gabrovnitsa",
      "state": "Montana",
      "country": "BG",
      "elevation": 626,
      "lat": 43.5443000793,
      "lon": 23.2724990845,
      "tz": "Europe\/Sofia"
  },
  "LBPD": {
      "icao": "LBPD",
      "iata": "PDV",
      "name": "Plovdiv International Airport",
      "city": "Plovdiv",
      "state": "Plovdiv",
      "country": "BG",
      "elevation": 597,
      "lat": 42.0677986145,
      "lon": 24.8507995605,
      "tz": "Europe\/Sofia"
  },
  "LBPG": {
      "icao": "LBPG",
      "iata": "",
      "name": "Graf Ignatievo Air Base",
      "city": "Graf Ignatievo",
      "state": "Plovdiv",
      "country": "BG",
      "elevation": 190,
      "lat": 42.2904014587,
      "lon": 24.7140007019,
      "tz": "Europe\/Sofia"
  },
  "LBPL": {
      "icao": "LBPL",
      "iata": "PVN",
      "name": "Dolna Mitropoliya Air Base",
      "city": "Dolna Mitropoliya",
      "state": "Lovech",
      "country": "BG",
      "elevation": 330,
      "lat": 43.4514007568,
      "lon": 24.5027999878,
      "tz": "Europe\/Sofia"
  },
  "LBPR": {
      "icao": "LBPR",
      "iata": "",
      "name": "Primorsko Airport",
      "city": "Primorsko",
      "state": "Burgas",
      "country": "BG",
      "elevation": 14,
      "lat": 42.2633323669,
      "lon": 27.7016677856,
      "tz": "Europe\/Sofia"
  },
  "LBRD": {
      "icao": "LBRD",
      "iata": "",
      "name": "Erden Airport",
      "city": "Erden",
      "state": "Montana",
      "country": "BG",
      "elevation": 617,
      "lat": 43.499168396,
      "lon": 23.3047218323,
      "tz": "Europe\/Sofia"
  },
  "LBSF": {
      "icao": "LBSF",
      "iata": "SOF",
      "name": "Sofia Airport",
      "city": "Sofia",
      "state": "Sofia-Capital",
      "country": "BG",
      "elevation": 1742,
      "lat": 42.6966934204,
      "lon": 23.4114360809,
      "tz": "Europe\/Sofia"
  },
  "LBSL": {
      "icao": "LBSL",
      "iata": "",
      "name": "Sliven Airfield",
      "city": "Sliven",
      "state": "Sliven",
      "country": "BG",
      "elevation": 656,
      "lat": 42.6463012695,
      "lon": 26.3593997955,
      "tz": "Europe\/Sofia"
  },
  "LBSS": {
      "icao": "LBSS",
      "iata": "SLS",
      "name": "Silistra Polkovnik Lambrinovo Airfield",
      "city": "Silistra",
      "state": "Razgrad",
      "country": "BG",
      "elevation": 351,
      "lat": 44.0551986694,
      "lon": 27.1788005829,
      "tz": "Europe\/Sofia"
  },
  "LBST": {
      "icao": "LBST",
      "iata": "",
      "name": "Stryama Airport",
      "city": "Stryama",
      "state": "Plovdiv",
      "country": "BG",
      "elevation": 916,
      "lat": 42.5402793884,
      "lon": 24.8252220154,
      "tz": "Europe\/Sofia"
  },
  "LBSZ": {
      "icao": "LBSZ",
      "iata": "SZR",
      "name": "Stara Zagora Airport",
      "city": "Stara Zagora",
      "state": "Khaskovo",
      "country": "BG",
      "elevation": 558,
      "lat": 42.376701355,
      "lon": 25.6551990509,
      "tz": "Europe\/Sofia"
  },
  "LBTG": {
      "icao": "LBTG",
      "iata": "TGV",
      "name": "Bukhovtsi Airfield",
      "city": "Targovishte",
      "state": "Razgrad",
      "country": "BG",
      "elevation": 666,
      "lat": 43.3065986633,
      "lon": 26.7008991241,
      "tz": "Europe\/Sofia"
  },
  "LBVD": {
      "icao": "LBVD",
      "iata": "VID",
      "name": "Vidin Smurdan Airfield",
      "city": "Vidin",
      "state": "Montana",
      "country": "BG",
      "elevation": 200,
      "lat": 44.0223999023,
      "lon": 22.8160991669,
      "tz": "Europe\/Sofia"
  },
  "LBWB": {
      "icao": "LBWB",
      "iata": "",
      "name": "Balchik Air Base",
      "city": "Balchik",
      "state": "Varna",
      "country": "BG",
      "elevation": 660,
      "lat": 43.4238014221,
      "lon": 28.1812992096,
      "tz": "Europe\/Sofia"
  },
  "LBWN": {
      "icao": "LBWN",
      "iata": "VAR",
      "name": "Varna Airport",
      "city": "Varna",
      "state": "Varna",
      "country": "BG",
      "elevation": 230,
      "lat": 43.2321014404,
      "lon": 27.8250999451,
      "tz": "Europe\/Sofia"
  },
  "LBWV": {
      "icao": "LBWV",
      "iata": "",
      "name": "Izgrev Airport",
      "city": "Izgrev",
      "state": "Varna",
      "country": "BG",
      "elevation": 1119,
      "lat": 43.2772216797,
      "lon": 27.7019443512,
      "tz": "Europe\/Sofia"
  },
  "LCEN": {
      "icao": "LCEN",
      "iata": "ECN",
      "name": "Ercan International Airport",
      "city": "Nicosia",
      "state": "Nicosia",
      "country": "CY",
      "elevation": 404,
      "lat": 35.1547012329,
      "lon": 33.4961013794,
      "tz": "Asia\/Famagusta"
  },
  "LCLK": {
      "icao": "LCLK",
      "iata": "LCA",
      "name": "Larnaca International Airport",
      "city": "Larnarca",
      "state": "Larnaka",
      "country": "CY",
      "elevation": 8,
      "lat": 34.8750991821,
      "lon": 33.6249008179,
      "tz": "Asia\/Nicosia"
  },
  "LCPH": {
      "icao": "LCPH",
      "iata": "PFO",
      "name": "Paphos International Airport",
      "city": "Paphos",
      "state": "Pafos",
      "country": "CY",
      "elevation": 41,
      "lat": 34.7179985046,
      "lon": 32.4856987,
      "tz": "Asia\/Nicosia"
  },
  "LCRA": {
      "icao": "LCRA",
      "iata": "AKT",
      "name": "RAF Akrotiri",
      "city": "",
      "state": "",
      "country": "CY",
      "elevation": 76,
      "lat": 34.5904006958,
      "lon": 32.9878997803,
      "tz": "Asia\/Nicosia"
  },
  "LD57": {
      "icao": "LD57",
      "iata": "",
      "name": "Sepurine Training Base",
      "city": "Zaton",
      "state": "Zadarska",
      "country": "HR",
      "elevation": 60,
      "lat": 44.2099990845,
      "lon": 15.1632003784,
      "tz": "Europe\/Zagreb"
  },
  "LDDU": {
      "icao": "LDDU",
      "iata": "DBV",
      "name": "Dubrovnik Airport",
      "city": "Dubrovnik",
      "state": "Dubrovacko-Neretvanska",
      "country": "HR",
      "elevation": 527,
      "lat": 42.5614013672,
      "lon": 18.2681999207,
      "tz": "Europe\/Zagreb"
  },
  "LDLO": {
      "icao": "LDLO",
      "iata": "LSZ",
      "name": "Losinj Island Airport",
      "city": "Losinj",
      "state": "Primorsko-Goranska",
      "country": "HR",
      "elevation": 151,
      "lat": 44.5657997131,
      "lon": 14.3930997849,
      "tz": "Europe\/Zagreb"
  },
  "LDOB": {
      "icao": "LDOB",
      "iata": "",
      "name": "Vukovar Borovo N Airport",
      "city": "Vukovar",
      "state": "Vukovar-Sirmium",
      "country": "HR",
      "elevation": 289,
      "lat": 45.3863983154,
      "lon": 18.9627990723,
      "tz": "Europe\/Belgrade"
  },
  "LDOC": {
      "icao": "LDOC",
      "iata": "",
      "name": "Cepin Airport",
      "city": "Cepin",
      "state": "Osjecko-Baranjska",
      "country": "HR",
      "elevation": 299,
      "lat": 45.5421981812,
      "lon": 18.6361999512,
      "tz": "Europe\/Zagreb"
  },
  "LDOR": {
      "icao": "LDOR",
      "iata": "",
      "name": "Slavonski Jelas Airport",
      "city": "Slavonski",
      "state": "Slavonski-Brod-Posavina",
      "country": "HR",
      "elevation": 276,
      "lat": 45.1561012268,
      "lon": 17.9881000519,
      "tz": "Europe\/Sarajevo"
  },
  "LDOS": {
      "icao": "LDOS",
      "iata": "OSI",
      "name": "Osijek Airport",
      "city": "Osijek",
      "state": "Osjecko-Baranjska",
      "country": "HR",
      "elevation": 290,
      "lat": 45.4626998901,
      "lon": 18.8101997375,
      "tz": "Europe\/Zagreb"
  },
  "LDOV": {
      "icao": "LDOV",
      "iata": "",
      "name": "Vinkovci Sopot Airport",
      "city": "Vinkovci",
      "state": "Vukovar-Sirmium",
      "country": "HR",
      "elevation": 266,
      "lat": 45.2510986328,
      "lon": 18.7591991425,
      "tz": "Europe\/Zagreb"
  },
  "LDPL": {
      "icao": "LDPL",
      "iata": "PUY",
      "name": "Pula Airport",
      "city": "Pula",
      "state": "Istria",
      "country": "HR",
      "elevation": 274,
      "lat": 44.8935012817,
      "lon": 13.9222002029,
      "tz": "Europe\/Zagreb"
  },
  "LDPM": {
      "icao": "LDPM",
      "iata": "",
      "name": "Medulin Campanoz Airport",
      "city": "Medulin",
      "state": "Istria",
      "country": "HR",
      "elevation": 171,
      "lat": 44.8432998657,
      "lon": 13.9041996002,
      "tz": "Europe\/Zagreb"
  },
  "LDPN": {
      "icao": "LDPN",
      "iata": "",
      "name": "Unije Airport",
      "city": "Unije Island",
      "state": "Primorsko-Goranska",
      "country": "HR",
      "elevation": 39,
      "lat": 44.6282997131,
      "lon": 14.2411003113,
      "tz": "Europe\/Zagreb"
  },
  "LDPV": {
      "icao": "LDPV",
      "iata": "",
      "name": "Vrsar Crljenka Airport",
      "city": "Vrsar",
      "state": "Istria",
      "country": "HR",
      "elevation": 121,
      "lat": 45.1417007446,
      "lon": 13.6305999756,
      "tz": "Europe\/Zagreb"
  },
  "LDRG": {
      "icao": "LDRG",
      "iata": "",
      "name": "Grobnicko Polje Airport",
      "city": "Grobnicko",
      "state": "Primorsko-Goranska",
      "country": "HR",
      "elevation": 951,
      "lat": 45.3795013428,
      "lon": 14.5038003922,
      "tz": "Europe\/Zagreb"
  },
  "LDRI": {
      "icao": "LDRI",
      "iata": "RJK",
      "name": "Rijeka Airport",
      "city": "Rijeka",
      "state": "Primorsko-Goranska",
      "country": "HR",
      "elevation": 278,
      "lat": 45.2168998718,
      "lon": 14.5703001022,
      "tz": "Europe\/Zagreb"
  },
  "LDRO": {
      "icao": "LDRO",
      "iata": "",
      "name": "Otocac Airport",
      "city": "Otocac",
      "state": "Licko-Senjska",
      "country": "HR",
      "elevation": 1539,
      "lat": 44.8477745056,
      "lon": 15.2866172791,
      "tz": "Europe\/Zagreb"
  },
  "LDSB": {
      "icao": "LDSB",
      "iata": "BWK",
      "name": "Brac Airport",
      "city": "Brac Island",
      "state": "Split-Dalmatia",
      "country": "HR",
      "elevation": 1776,
      "lat": 43.2857017517,
      "lon": 16.6797008514,
      "tz": "Europe\/Zagreb"
  },
  "LDSH": {
      "icao": "LDSH",
      "iata": "",
      "name": "Hvar Private Airport",
      "city": "Hvar Island",
      "state": "Split-Dalmatia",
      "country": "HR",
      "elevation": 92,
      "lat": 43.1817016602,
      "lon": 16.6333007813,
      "tz": "Europe\/Zagreb"
  },
  "LDSP": {
      "icao": "LDSP",
      "iata": "SPU",
      "name": "Split Airport",
      "city": "Split",
      "state": "Split-Dalmatia",
      "country": "HR",
      "elevation": 79,
      "lat": 43.538898468,
      "lon": 16.2980003357,
      "tz": "Europe\/Zagreb"
  },
  "LDSS": {
      "icao": "LDSS",
      "iata": "",
      "name": "Sinj Glider Airport",
      "city": "Sinj",
      "state": "Split-Dalmatia",
      "country": "HR",
      "elevation": 981,
      "lat": 43.7002983093,
      "lon": 16.6714000702,
      "tz": "Europe\/Zagreb"
  },
  "LDVA": {
      "icao": "LDVA",
      "iata": "",
      "name": "Varazdin Airport",
      "city": "Varazdin",
      "state": "Varazdinska",
      "country": "HR",
      "elevation": 548,
      "lat": 46.2946472168,
      "lon": 16.382932663,
      "tz": "Europe\/Zagreb"
  },
  "LDVC": {
      "icao": "LDVC",
      "iata": "",
      "name": "Cakovec Pribisla Airport",
      "city": "Cakovec",
      "state": "Medimurska",
      "country": "HR",
      "elevation": 512,
      "lat": 46.3918991089,
      "lon": 16.5002994537,
      "tz": "Europe\/Zagreb"
  },
  "LDVD": {
      "icao": "LDVD",
      "iata": "",
      "name": "Daruvar Blagorod Airport",
      "city": "Daruvar",
      "state": "Bjelovarsko-Bilogorska",
      "country": "HR",
      "elevation": 410,
      "lat": 45.5593986511,
      "lon": 17.0331001282,
      "tz": "Europe\/Zagreb"
  },
  "LDZA": {
      "icao": "LDZA",
      "iata": "ZAG",
      "name": "Zagreb Airport",
      "city": "Zagreb",
      "state": "Zagrebacka",
      "country": "HR",
      "elevation": 353,
      "lat": 45.7429008484,
      "lon": 16.0687999725,
      "tz": "Europe\/Zagreb"
  },
  "LDZB": {
      "icao": "LDZB",
      "iata": "",
      "name": "Busevec Velika Glider Airport",
      "city": "Busevec",
      "state": "Zagrebacka",
      "country": "HR",
      "elevation": 341,
      "lat": 45.6474990845,
      "lon": 16.1243991852,
      "tz": "Europe\/Zagreb"
  },
  "LDZD": {
      "icao": "LDZD",
      "iata": "ZAD",
      "name": "Zemunik Airport",
      "city": "Zadar",
      "state": "Zadarska",
      "country": "HR",
      "elevation": 289,
      "lat": 44.1082992554,
      "lon": 15.3466997147,
      "tz": "Europe\/Zagreb"
  },
  "LDZJ": {
      "icao": "LDZJ",
      "iata": "",
      "name": "Bjelovar Brezova Airport",
      "city": "Bjelovar",
      "state": "Bjelovarsko-Bilogorska",
      "country": "HR",
      "elevation": 430,
      "lat": 45.8608016968,
      "lon": 16.8358001709,
      "tz": "Europe\/Zagreb"
  },
  "LDZL": {
      "icao": "LDZL",
      "iata": "",
      "name": "Lucko Airport",
      "city": "Zagreb",
      "state": "City-of-Zagreb",
      "country": "HR",
      "elevation": 400,
      "lat": 45.7668991089,
      "lon": 15.8486003876,
      "tz": "Europe\/Zagreb"
  },
  "LE84": {
      "icao": "LE84",
      "iata": "",
      "name": "Jayena Airport",
      "city": "Jayena",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 3654,
      "lat": 36.9306983948,
      "lon": -3.8353800774,
      "tz": "Europe\/Madrid"
  },
  "LEAB": {
      "icao": "LEAB",
      "iata": "ABC",
      "name": "Albacete-Los Llanos Airport",
      "city": "Albacete",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2302,
      "lat": 38.9485015869,
      "lon": -1.8635200262,
      "tz": "Europe\/Madrid"
  },
  "LEAE": {
      "icao": "LEAE",
      "iata": "",
      "name": "Alcolea Airport",
      "city": "Alcolea",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 463,
      "lat": 37.9460983276,
      "lon": -4.5894398689,
      "tz": "Europe\/Madrid"
  },
  "LEAL": {
      "icao": "LEAL",
      "iata": "ALC",
      "name": "Alicante International Airport",
      "city": "Alicante",
      "state": "Valencia",
      "country": "ES",
      "elevation": 142,
      "lat": 38.2821998596,
      "lon": -0.5581560135,
      "tz": "Europe\/Madrid"
  },
  "LEAM": {
      "icao": "LEAM",
      "iata": "LEI",
      "name": "Almeria International Airport",
      "city": "Almeria",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 70,
      "lat": 36.8438987732,
      "lon": -2.3701000214,
      "tz": "Europe\/Madrid"
  },
  "LEAP": {
      "icao": "LEAP",
      "iata": "",
      "name": "Ampuriabrava Airport",
      "city": "Ampuriabraba",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 2,
      "lat": 42.2599983215,
      "lon": 3.109721899,
      "tz": "Europe\/Madrid"
  },
  "LEAS": {
      "icao": "LEAS",
      "iata": "OVD",
      "name": "Asturias Airport",
      "city": "Ranon",
      "state": "Asturias",
      "country": "ES",
      "elevation": 416,
      "lat": 43.5635986328,
      "lon": -6.0346198082,
      "tz": "Europe\/Madrid"
  },
  "LEAT": {
      "icao": "LEAT",
      "iata": "",
      "name": "Alfes Airport",
      "city": "Alfes",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 705,
      "lat": 41.5499992371,
      "lon": 0.6499999762,
      "tz": "Europe\/Madrid"
  },
  "LEAX": {
      "icao": "LEAX",
      "iata": "",
      "name": "La Axarquia-Leoni Benabu Airport",
      "city": "Malaga",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 118,
      "lat": 36.8016662598,
      "lon": -4.135556221,
      "tz": "Europe\/Madrid"
  },
  "LEBA": {
      "icao": "LEBA",
      "iata": "ODB",
      "name": "Cordoba Airport",
      "city": "Cordoba",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 297,
      "lat": 37.841999054,
      "lon": -4.8488798141,
      "tz": "Europe\/Madrid"
  },
  "LEBB": {
      "icao": "LEBB",
      "iata": "BIO",
      "name": "Bilbao Airport",
      "city": "Bilbao",
      "state": "Basque-Country",
      "country": "ES",
      "elevation": 138,
      "lat": 43.3011016846,
      "lon": -2.9106099606,
      "tz": "Europe\/Madrid"
  },
  "LEBE": {
      "icao": "LEBE",
      "iata": "",
      "name": "Beas De Segura Airport",
      "city": "Jaen",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 1780,
      "lat": 38.2711105347,
      "lon": -2.9488890171,
      "tz": "Europe\/Madrid"
  },
  "LEBG": {
      "icao": "LEBG",
      "iata": "RGS",
      "name": "Burgos Airport",
      "city": "Burgos",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 2945,
      "lat": 42.3576011658,
      "lon": -3.620759964,
      "tz": "Europe\/Madrid"
  },
  "LEBL": {
      "icao": "LEBL",
      "iata": "BCN",
      "name": "Barcelona International Airport",
      "city": "Barcelona",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 12,
      "lat": 41.2971000671,
      "lon": 2.0784599781,
      "tz": "Europe\/Madrid"
  },
  "LEBZ": {
      "icao": "LEBZ",
      "iata": "BJZ",
      "name": "Badajoz Airport",
      "city": "Badajoz",
      "state": "Extremadura",
      "country": "ES",
      "elevation": 609,
      "lat": 38.8913002014,
      "lon": -6.8213300705,
      "tz": "Europe\/Madrid"
  },
  "LECA": {
      "icao": "LECA",
      "iata": "",
      "name": "La Nava - Corral De Ayllon Airport",
      "city": "Corral dev Ayllon",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3280,
      "lat": 41.4108009338,
      "lon": -3.4483299255,
      "tz": "Europe\/Madrid"
  },
  "LECD": {
      "icao": "LECD",
      "iata": "",
      "name": "La Cerdanya Airport",
      "city": "Alp",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 3586,
      "lat": 42.3863983154,
      "lon": 1.8666700125,
      "tz": "Europe\/Madrid"
  },
  "LECF": {
      "icao": "LECF",
      "iata": "",
      "name": "Calaf-Sallavinera Airport",
      "city": "Sant Pere Sallavinera",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 2355,
      "lat": 41.7442016602,
      "lon": 1.5569399595,
      "tz": "Europe\/Madrid"
  },
  "LECH": {
      "icao": "LECH",
      "iata": "CDT",
      "name": "Calamocha Airport",
      "city": "Calamocha",
      "state": "Aragon",
      "country": "ES",
      "elevation": 2936,
      "lat": 40.9000015259,
      "lon": -1.3041199446,
      "tz": "Europe\/Madrid"
  },
  "LECI": {
      "icao": "LECI",
      "iata": "",
      "name": "Santa Cilia De Jaca Airport",
      "city": "Huesca",
      "state": "Aragon",
      "country": "ES",
      "elevation": 2241,
      "lat": 42.5691986084,
      "lon": -0.7277780175,
      "tz": "Europe\/Madrid"
  },
  "LECJ": {
      "icao": "LECJ",
      "iata": "",
      "name": "Castejon de los Monegros Airport",
      "city": "Huesca",
      "state": "Aragon",
      "country": "ES",
      "elevation": 1424,
      "lat": 41.60779953,
      "lon": -0.2183270007,
      "tz": "Europe\/Madrid"
  },
  "LECN": {
      "icao": "LECN",
      "iata": "CDT",
      "name": "Castellon De La Plana Airport",
      "city": "Castellon de La Plana",
      "state": "Valencia",
      "country": "ES",
      "elevation": 20,
      "lat": 39.9991989136,
      "lon": 0.0261109993,
      "tz": "Europe\/Madrid"
  },
  "LECO": {
      "icao": "LECO",
      "iata": "LCG",
      "name": "A Coruna Airport",
      "city": "Culleredo",
      "state": "Galicia",
      "country": "ES",
      "elevation": 326,
      "lat": 43.3021011353,
      "lon": -8.3772602081,
      "tz": "Europe\/Madrid"
  },
  "LECT": {
      "icao": "LECT",
      "iata": "",
      "name": "El Castano Airport",
      "city": "Luciana",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2116,
      "lat": 39.0107002258,
      "lon": -4.3863601685,
      "tz": "Europe\/Madrid"
  },
  "LECU": {
      "icao": "LECU",
      "iata": "ECV",
      "name": "Cuatro Vientos Airport",
      "city": "Madrid",
      "state": "Madrid",
      "country": "ES",
      "elevation": 2269,
      "lat": 40.3707008362,
      "lon": -3.7851400375,
      "tz": "Europe\/Madrid"
  },
  "LECX": {
      "icao": "LECX",
      "iata": "",
      "name": "Campolara Airport",
      "city": "Segovia",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3353,
      "lat": 40.9030990601,
      "lon": -4.5202798843,
      "tz": "Europe\/Madrid"
  },
  "LEDA": {
      "icao": "LEDA",
      "iata": "ILD",
      "name": "Lleida-Alguaire Airport",
      "city": "Lleida",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 1170,
      "lat": 41.728185,
      "lon": 0.535023,
      "tz": "Europe\/Madrid"
  },
  "LEDS": {
      "icao": "LEDS",
      "iata": "",
      "name": "Castellon-Costa Azahar Airport",
      "city": "Castellon de la Plana",
      "state": "Valencia",
      "country": "ES",
      "elevation": 1145,
      "lat": 40.2138888889,
      "lon": 0.0733333333,
      "tz": "Europe\/Madrid"
  },
  "LEEM": {
      "icao": "LEEM",
      "iata": "",
      "name": "El Manantio Airport",
      "city": "Badajoz",
      "state": "Extremadura",
      "country": "ES",
      "elevation": 708,
      "lat": 38.777507782,
      "lon": -6.9911241531,
      "tz": "Europe\/Madrid"
  },
  "LEEV": {
      "icao": "LEEV",
      "iata": "",
      "name": "E. Castellanos-Villacastin Airport",
      "city": "Villacastin",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3637,
      "lat": 40.7839012146,
      "lon": -4.4627799988,
      "tz": "Europe\/Madrid"
  },
  "LEFM": {
      "icao": "LEFM",
      "iata": "",
      "name": "Fuentemilanos Airport",
      "city": "Segovia",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3307,
      "lat": 40.8886108398,
      "lon": -4.2375001907,
      "tz": "Europe\/Madrid"
  },
  "LEGA": {
      "icao": "LEGA",
      "iata": "",
      "name": "Armilla Airport",
      "city": "Armilla",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 2297,
      "lat": 37.1332015991,
      "lon": -3.6356899738,
      "tz": "Europe\/Madrid"
  },
  "LEGC": {
      "icao": "LEGC",
      "iata": "",
      "name": "Altarejos-Guadalcanal Airport",
      "city": "Sevilla",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 1928,
      "lat": 38.1716995239,
      "lon": -5.7395300865,
      "tz": "Europe\/Madrid"
  },
  "LEGE": {
      "icao": "LEGE",
      "iata": "GRO",
      "name": "Girona Airport",
      "city": "Girona",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 468,
      "lat": 41.9010009766,
      "lon": 2.7605500221,
      "tz": "Europe\/Madrid"
  },
  "LEGR": {
      "icao": "LEGR",
      "iata": "GRX",
      "name": "Federico Garcia Lorca Airport",
      "city": "Granada",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 1860,
      "lat": 37.1887016296,
      "lon": -3.7773599625,
      "tz": "Europe\/Madrid"
  },
  "LEGT": {
      "icao": "LEGT",
      "iata": "",
      "name": "Getafe Air Base",
      "city": "Getafe",
      "state": "Madrid",
      "country": "ES",
      "elevation": 2031,
      "lat": 40.2941017151,
      "lon": -3.7238299847,
      "tz": "Europe\/Madrid"
  },
  "LEGU": {
      "icao": "LEGU",
      "iata": "",
      "name": "Guadalupe Airport",
      "city": "Guadalupe",
      "state": "Extremadura",
      "country": "ES",
      "elevation": 1408,
      "lat": 39.3456993103,
      "lon": -5.1973500252,
      "tz": "Europe\/Madrid"
  },
  "LEGY": {
      "icao": "LEGY",
      "iata": "",
      "name": "Garray Airport",
      "city": "Soria",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3360,
      "lat": 41.8231010437,
      "lon": -2.4786100388,
      "tz": "Europe\/Madrid"
  },
  "LEHC": {
      "icao": "LEHC",
      "iata": "HSK",
      "name": "Huesca\/Pirineos Airport",
      "city": "Monflorite\/Alcala del Obispo",
      "state": "Aragon",
      "country": "ES",
      "elevation": 1768,
      "lat": 42.0760993958,
      "lon": -0.3166669905,
      "tz": "Europe\/Madrid"
  },
  "LEHI": {
      "icao": "LEHI",
      "iata": "",
      "name": "Hinojosa del Duque Airport",
      "city": "Hinojosa del Duque",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 1772,
      "lat": 38.5,
      "lon": -5.117000103,
      "tz": "Europe\/Madrid"
  },
  "LEIB": {
      "icao": "LEIB",
      "iata": "IBZ",
      "name": "Ibiza Airport",
      "city": "Ibiza",
      "state": "Balearic-Islands",
      "country": "ES",
      "elevation": 24,
      "lat": 38.8728981018,
      "lon": 1.3731199503,
      "tz": "Europe\/Madrid"
  },
  "LEIG": {
      "icao": "LEIG",
      "iata": "",
      "name": "Igualada\/Odena Airport",
      "city": "Barcelona",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 1148,
      "lat": 41.5856018066,
      "lon": 1.6530599594,
      "tz": "Europe\/Madrid"
  },
  "LEIZ": {
      "icao": "LEIZ",
      "iata": "",
      "name": "La Perdiz - Torre De Juan Abad Airport",
      "city": "Torre de Juan Abad",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2902,
      "lat": 38.5130996704,
      "lon": -3.3643300533,
      "tz": "Europe\/Madrid"
  },
  "LEJO": {
      "icao": "LEJO",
      "iata": "",
      "name": "Madrigalejo Del Monte Airport",
      "city": "Madrigalejo Del Monte",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3030,
      "lat": 42.1343994141,
      "lon": -3.7310299873,
      "tz": "Europe\/Madrid"
  },
  "LEJR": {
      "icao": "LEJR",
      "iata": "XRY",
      "name": "Jerez Airport",
      "city": "Jerez de la Forntera",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 93,
      "lat": 36.7445983887,
      "lon": -6.0601100922,
      "tz": "Europe\/Madrid"
  },
  "LEJU": {
      "icao": "LEJU",
      "iata": "",
      "name": "La Juliana Airport",
      "city": "Sevilla",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 148,
      "lat": 37.2949981689,
      "lon": -6.1624999046,
      "tz": "Europe\/Madrid"
  },
  "LELA": {
      "icao": "LELA",
      "iata": "",
      "name": "La Calderera Airport",
      "city": "Valdepenas",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2644,
      "lat": 38.7478981018,
      "lon": -3.5145599842,
      "tz": "Europe\/Madrid"
  },
  "LELC": {
      "icao": "LELC",
      "iata": "MJV",
      "name": "San Javier Airport",
      "city": "San Javier",
      "state": "Murcia",
      "country": "ES",
      "elevation": 11,
      "lat": 37.7750015259,
      "lon": -0.8123890162,
      "tz": "Europe\/Madrid"
  },
  "LELH": {
      "icao": "LELH",
      "iata": "",
      "name": "Alhama De Murcia Airport",
      "city": "Canovas",
      "state": "Murcia",
      "country": "ES",
      "elevation": 669,
      "lat": 37.7489013672,
      "lon": -1.3021600246,
      "tz": "Europe\/Madrid"
  },
  "LELI": {
      "icao": "LELI",
      "iata": "",
      "name": "Linares Airport",
      "city": "Linares",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 1033,
      "lat": 38.090801239,
      "lon": -3.7066700459,
      "tz": "Europe\/Madrid"
  },
  "LELL": {
      "icao": "LELL",
      "iata": "QSA",
      "name": "Sabadell Airport",
      "city": "Sabadell",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 485,
      "lat": 41.5209007263,
      "lon": 2.1050798893,
      "tz": "Europe\/Madrid"
  },
  "LELM": {
      "icao": "LELM",
      "iata": "",
      "name": "Almansa Airport",
      "city": "Albacete",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2248,
      "lat": 38.8950004578,
      "lon": -1.1131800413,
      "tz": "Europe\/Madrid"
  },
  "LELN": {
      "icao": "LELN",
      "iata": "LEN",
      "name": "Leon Airport",
      "city": "Leon",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3006,
      "lat": 42.5890007019,
      "lon": -5.6555600166,
      "tz": "Europe\/Madrid"
  },
  "LELO": {
      "icao": "LELO",
      "iata": "RJL",
      "name": "Logrono-Agoncillo Airport",
      "city": "Logrono",
      "state": "La-Rioja",
      "country": "ES",
      "elevation": 1161,
      "lat": 42.4609534888,
      "lon": -2.3222351074,
      "tz": "Europe\/Madrid"
  },
  "LELT": {
      "icao": "LELT",
      "iata": "",
      "name": "Lillo Airport",
      "city": "Lillo",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2272,
      "lat": 39.7168998718,
      "lon": -3.3205599785,
      "tz": "Europe\/Madrid"
  },
  "LEMD": {
      "icao": "LEMD",
      "iata": "MAD",
      "name": "Madrid Barajas International Airport",
      "city": "Madrid",
      "state": "Madrid",
      "country": "ES",
      "elevation": 2001,
      "lat": 40.4936,
      "lon": -3.56676,
      "tz": "Europe\/Madrid"
  },
  "LEMF": {
      "icao": "LEMF",
      "iata": "HEV",
      "name": "Mafe - Gibraleon Airport",
      "city": "Gibraleon",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 86,
      "lat": 37.3642997742,
      "lon": -6.9209399223,
      "tz": "Europe\/Madrid"
  },
  "LEMG": {
      "icao": "LEMG",
      "iata": "AGP",
      "name": "Malaga Airport",
      "city": "Malaga",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 53,
      "lat": 36.6749000549,
      "lon": -4.4991102219,
      "tz": "Europe\/Madrid"
  },
  "LEMH": {
      "icao": "LEMH",
      "iata": "MAH",
      "name": "Menorca Airport",
      "city": "Menorca Island",
      "state": "Balearic-Islands",
      "country": "ES",
      "elevation": 302,
      "lat": 39.8625984192,
      "lon": 4.2186498642,
      "tz": "Europe\/Madrid"
  },
  "LEMI": {
      "icao": "LEMI",
      "iata": "RMU",
      "name": "Regi\u00f3n de Murcia International Airport",
      "city": "Corvera",
      "state": "Corvera",
      "country": "ES",
      "elevation": 633,
      "lat": 37.803,
      "lon": -1.125,
      "tz": "Europe\/Madrid"
  },
  "LEMO": {
      "icao": "LEMO",
      "iata": "OZP",
      "name": "Moron Air Base",
      "city": "Moron",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 285,
      "lat": 37.1749000549,
      "lon": -5.615940094,
      "tz": "Europe\/Madrid"
  },
  "LEMP": {
      "icao": "LEMP",
      "iata": "",
      "name": "Los Martinez Del Puerto Airport",
      "city": "Murcia",
      "state": "Murcia",
      "country": "ES",
      "elevation": 534,
      "lat": 37.7989006042,
      "lon": -1.0974999666,
      "tz": "Europe\/Madrid"
  },
  "LEMR": {
      "icao": "LEMR",
      "iata": "",
      "name": "La Morgal Airport",
      "city": "Oviedo",
      "state": "Asturias",
      "country": "ES",
      "elevation": 541,
      "lat": 43.4291992188,
      "lon": -5.8305602074,
      "tz": "Europe\/Madrid"
  },
  "LEMS": {
      "icao": "LEMS",
      "iata": "",
      "name": "Manresa Airport",
      "city": "Manresa",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 897,
      "lat": 41.7653007507,
      "lon": 1.8616700172,
      "tz": "Europe\/Madrid"
  },
  "LEMT": {
      "icao": "LEMT",
      "iata": "",
      "name": "Casarrubios Del Monte Airport",
      "city": "Toledo",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2050,
      "lat": 40.2350006104,
      "lon": -4.0263900757,
      "tz": "Europe\/Madrid"
  },
  "LEMU": {
      "icao": "LEMU",
      "iata": "",
      "name": "Muchamiel Airport",
      "city": "Muchamiel",
      "state": "Valencia",
      "country": "ES",
      "elevation": 427,
      "lat": 38.4406013489,
      "lon": -0.4752779901,
      "tz": "Europe\/Madrid"
  },
  "LEMX": {
      "icao": "LEMX",
      "iata": "",
      "name": "La Mancha-Toledo Airport",
      "city": "Toledo",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2405,
      "lat": 39.5620994568,
      "lon": -3.2507500648,
      "tz": "Europe\/Madrid"
  },
  "LENA": {
      "icao": "LENA",
      "iata": "",
      "name": "Benabarre Airport",
      "city": "Huesca",
      "state": "Aragon",
      "country": "ES",
      "elevation": 2450,
      "lat": 42.0228004456,
      "lon": 0.4822219908,
      "tz": "Europe\/Madrid"
  },
  "LENE": {
      "icao": "LENE",
      "iata": "",
      "name": "La Caminera Airport",
      "city": "Torrenueva",
      "state": "Ciudad Real",
      "country": "ES",
      "elevation": 2513,
      "lat": 38.669739,
      "lon": -3.303389,
      "tz": "Europe\/Madrid"
  },
  "LEOC": {
      "icao": "LEOC",
      "iata": "",
      "name": "Ocana Airport",
      "city": "Toledo",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2405,
      "lat": 39.9375,
      "lon": -3.5033299923,
      "tz": "Europe\/Madrid"
  },
  "LEOT": {
      "icao": "LEOT",
      "iata": "",
      "name": "Ontur Airport",
      "city": "Ontur",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2200,
      "lat": 38.6169013977,
      "lon": -1.5249999762,
      "tz": "Europe\/Madrid"
  },
  "LEPA": {
      "icao": "LEPA",
      "iata": "PMI",
      "name": "Palma De Mallorca Airport",
      "city": "Palma De Mallorca",
      "state": "Balearic-Islands",
      "country": "ES",
      "elevation": 27,
      "lat": 39.551700592,
      "lon": 2.7388100624,
      "tz": "Europe\/Madrid"
  },
  "LEPI": {
      "icao": "LEPI",
      "iata": "",
      "name": "Casas de los Pinos Airport",
      "city": "Cuenca",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2370,
      "lat": 39.2981987,
      "lon": -2.3787200451,
      "tz": "Europe\/Madrid"
  },
  "LEPP": {
      "icao": "LEPP",
      "iata": "PNA",
      "name": "Pamplona Airport",
      "city": "Pamplona",
      "state": "Navarre",
      "country": "ES",
      "elevation": 1504,
      "lat": 42.7700004578,
      "lon": -1.646329999,
      "tz": "Europe\/Madrid"
  },
  "LEPR": {
      "icao": "LEPR",
      "iata": "",
      "name": "Palma Del Rio Airport",
      "city": "Palma del Rio",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 408,
      "lat": 37.7153015137,
      "lon": -5.2135601044,
      "tz": "Europe\/Madrid"
  },
  "LEPZ": {
      "icao": "LEPZ",
      "iata": "",
      "name": "Los Pozuelos De Calatrava Airport",
      "city": "Los Pozuelos De Calatrava",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 1850,
      "lat": 38.9122009277,
      "lon": -4.1911101341,
      "tz": "Europe\/Madrid"
  },
  "LERE": {
      "icao": "LERE",
      "iata": "",
      "name": "Requena Airport",
      "city": "Requena",
      "state": "Valencia",
      "country": "ES",
      "elevation": 2330,
      "lat": 39.4747009277,
      "lon": -1.0344400406,
      "tz": "Europe\/Madrid"
  },
  "LERI": {
      "icao": "LERI",
      "iata": "",
      "name": "Alcantarilla Airport",
      "city": "Alcantarilla",
      "state": "Murcia",
      "country": "ES",
      "elevation": 250,
      "lat": 37.9510993958,
      "lon": -1.2303199768,
      "tz": "Europe\/Madrid"
  },
  "LERM": {
      "icao": "LERM",
      "iata": "",
      "name": "Robledillo De Mohernando Airport",
      "city": "Robledillo De Mohernando",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 3097,
      "lat": 40.8652992249,
      "lon": -3.2477800846,
      "tz": "Europe\/Madrid"
  },
  "LERO": {
      "icao": "LERO",
      "iata": "",
      "name": "Rozas Airport",
      "city": "Lugo",
      "state": "Galicia",
      "country": "ES",
      "elevation": 1444,
      "lat": 43.1169013977,
      "lon": -7.4702801704,
      "tz": "Europe\/Madrid"
  },
  "LERS": {
      "icao": "LERS",
      "iata": "REU",
      "name": "Reus Air Base",
      "city": "Reus",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 233,
      "lat": 41.1473999023,
      "lon": 1.1671700478,
      "tz": "Europe\/Madrid"
  },
  "LERT": {
      "icao": "LERT",
      "iata": "ROZ",
      "name": "Rota Naval Station Airport",
      "city": "Rota",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 86,
      "lat": 36.645198822,
      "lon": -6.349460125,
      "tz": "Europe\/Madrid"
  },
  "LESA": {
      "icao": "LESA",
      "iata": "SLM",
      "name": "Salamanca Airport",
      "city": "Salamanca",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 2595,
      "lat": 40.9520988464,
      "lon": -5.5019898415,
      "tz": "Europe\/Madrid"
  },
  "LESB": {
      "icao": "LESB",
      "iata": "",
      "name": "Son Bonet Airport",
      "city": "Mallorca Island",
      "state": "Balearic-Islands",
      "country": "ES",
      "elevation": 157,
      "lat": 39.5988998413,
      "lon": 2.7027800083,
      "tz": "Europe\/Madrid"
  },
  "LESE": {
      "icao": "LESE",
      "iata": "",
      "name": "San Enrique Airport",
      "city": "Almodovar del Campo",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2263,
      "lat": 38.7308006287,
      "lon": -4.3130598068,
      "tz": "Europe\/Madrid"
  },
  "LESG": {
      "icao": "LESG",
      "iata": "",
      "name": "Sanguesa Airport",
      "city": "Sanguesa",
      "state": "Navarre",
      "country": "ES",
      "elevation": 1297,
      "lat": 42.5656013489,
      "lon": -1.284719944,
      "tz": "Europe\/Madrid"
  },
  "LESL": {
      "icao": "LESL",
      "iata": "",
      "name": "San Luis Airport",
      "city": "Menorca Island",
      "state": "Balearic-Islands",
      "country": "ES",
      "elevation": 197,
      "lat": 39.8622016907,
      "lon": 4.2583298683,
      "tz": "Europe\/Madrid"
  },
  "LESO": {
      "icao": "LESO",
      "iata": "EAS",
      "name": "San Sebastian Airport",
      "city": "Hondarribia",
      "state": "Basque-Country",
      "country": "ES",
      "elevation": 16,
      "lat": 43.3564987183,
      "lon": -1.7906099558,
      "tz": "Europe\/Madrid"
  },
  "LESS": {
      "icao": "LESS",
      "iata": "",
      "name": "Sotos Airport",
      "city": "Sotos",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 3170,
      "lat": 40.2042007446,
      "lon": -2.143889904,
      "tz": "Europe\/Madrid"
  },
  "LEST": {
      "icao": "LEST",
      "iata": "SCQ",
      "name": "Santiago de Compostela Airport",
      "city": "Santiago de Compostela",
      "state": "Galicia",
      "country": "ES",
      "elevation": 1213,
      "lat": 42.8963012695,
      "lon": -8.415140152,
      "tz": "Europe\/Madrid"
  },
  "LESU": {
      "icao": "LESU",
      "iata": "LEU",
      "name": "Aerodrom dels Pirineus-Alt Urgell Airport",
      "city": "Montferrer \/ Castellbo",
      "state": "Catalonia",
      "country": "ES",
      "elevation": 2625,
      "lat": 42.3386001587,
      "lon": 1.4091700315,
      "tz": "Europe\/Madrid"
  },
  "LETC": {
      "icao": "LETC",
      "iata": "",
      "name": "Matilla De Los Canos Airport",
      "city": "Valladolid",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 2300,
      "lat": 41.5306015015,
      "lon": -4.9250001907,
      "tz": "Europe\/Madrid"
  },
  "LETE": {
      "icao": "LETE",
      "iata": "",
      "name": "Morante Airport",
      "city": "Badajoz",
      "state": "Extremadura",
      "country": "ES",
      "elevation": 703,
      "lat": 39.0368995667,
      "lon": -6.690559864,
      "tz": "Europe\/Madrid"
  },
  "LETF": {
      "icao": "LETF",
      "iata": "",
      "name": "Tomas Fernandez Espada Airport",
      "city": "Villamartin",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 470,
      "lat": 36.8717934415,
      "lon": -5.6487751007,
      "tz": "Europe\/Madrid"
  },
  "LETI": {
      "icao": "LETI",
      "iata": "",
      "name": "El Tietar Airport",
      "city": "La Iglesuela",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 1401,
      "lat": 40.2439002991,
      "lon": -4.7944397926,
      "tz": "Europe\/Madrid"
  },
  "LETJ": {
      "icao": "LETJ",
      "iata": "",
      "name": "Trebujena Airfield",
      "city": "Trebujena",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 12,
      "lat": 36.8588888889,
      "lon": -6.1391666667,
      "tz": "Europe\/Madrid"
  },
  "LETO": {
      "icao": "LETO",
      "iata": "TOJ",
      "name": "Torrejon Airport",
      "city": "Madrid",
      "state": "Madrid",
      "country": "ES",
      "elevation": 2026,
      "lat": 40.4967002869,
      "lon": -3.4458699226,
      "tz": "Europe\/Madrid"
  },
  "LETP": {
      "icao": "LETP",
      "iata": "",
      "name": "Santo Tome Del Puerto Airport",
      "city": "Segovia",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 3638,
      "lat": 41.2042007446,
      "lon": -3.5947198868,
      "tz": "Europe\/Madrid"
  },
  "LETZ": {
      "icao": "LETZ",
      "iata": "",
      "name": "Torozos Airport",
      "city": "Valladolid",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 2784,
      "lat": 41.7853012085,
      "lon": -4.8647298813,
      "tz": "Europe\/Madrid"
  },
  "LEVB": {
      "icao": "LEVB",
      "iata": "",
      "name": "El Carrascal Airport",
      "city": "Valladolid",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 2788,
      "lat": 41.8246994019,
      "lon": -4.8930602074,
      "tz": "Europe\/Madrid"
  },
  "LEVC": {
      "icao": "LEVC",
      "iata": "VLC",
      "name": "Valencia Airport",
      "city": "Valencia",
      "state": "Valencia",
      "country": "ES",
      "elevation": 240,
      "lat": 39.4892997742,
      "lon": -0.4816249907,
      "tz": "Europe\/Madrid"
  },
  "LEVD": {
      "icao": "LEVD",
      "iata": "VLL",
      "name": "Valladolid Airport",
      "city": "Valladolid",
      "state": "Castille-and-Leon",
      "country": "ES",
      "elevation": 2776,
      "lat": 41.7061004639,
      "lon": -4.851940155,
      "tz": "Europe\/Madrid"
  },
  "LEVF": {
      "icao": "LEVF",
      "iata": "",
      "name": "Villaframil Airport",
      "city": "Lugo",
      "state": "Galicia",
      "country": "ES",
      "elevation": 80,
      "lat": 43.5525016785,
      "lon": -7.0877799988,
      "tz": "Europe\/Madrid"
  },
  "LEVI": {
      "icao": "LEVI",
      "iata": "",
      "name": "Viso del Marques Airport",
      "city": "Viso del Marques",
      "state": "Castille-La-Mancha",
      "country": "ES",
      "elevation": 2672,
      "lat": 38.5055999756,
      "lon": -3.4261500835,
      "tz": "Europe\/Madrid"
  },
  "LEVT": {
      "icao": "LEVT",
      "iata": "VIT",
      "name": "Vitoria\/Foronda Airport",
      "city": "Alava",
      "state": "Basque-Country",
      "country": "ES",
      "elevation": 1682,
      "lat": 42.8828010559,
      "lon": -2.7244699001,
      "tz": "Europe\/Madrid"
  },
  "LEVX": {
      "icao": "LEVX",
      "iata": "VGO",
      "name": "Vigo Airport",
      "city": "Vigo",
      "state": "Galicia",
      "country": "ES",
      "elevation": 856,
      "lat": 42.2318000793,
      "lon": -8.6267700195,
      "tz": "Europe\/Madrid"
  },
  "LEXJ": {
      "icao": "LEXJ",
      "iata": "SDR",
      "name": "Santander Airport",
      "city": "Santander",
      "state": "Cantabria",
      "country": "ES",
      "elevation": 16,
      "lat": 43.4271011353,
      "lon": -3.8200099468,
      "tz": "Europe\/Madrid"
  },
  "LEZG": {
      "icao": "LEZG",
      "iata": "ZAZ",
      "name": "Zaragoza Air Base",
      "city": "Zaragoza",
      "state": "Aragon",
      "country": "ES",
      "elevation": 863,
      "lat": 41.6661987305,
      "lon": -1.0415500402,
      "tz": "Europe\/Madrid"
  },
  "LEZL": {
      "icao": "LEZL",
      "iata": "SVQ",
      "name": "Sevilla Airport",
      "city": "Sevilla",
      "state": "Andalusia",
      "country": "ES",
      "elevation": 112,
      "lat": 37.4179992676,
      "lon": -5.8931097984,
      "tz": "Europe\/Madrid"
  },
  "LF50": {
      "icao": "LF50",
      "iata": "",
      "name": "Soucelles Airport",
      "city": "Soucelles",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 255,
      "lat": 47.5803985596,
      "lon": -0.4122689962,
      "tz": "Europe\/Paris"
  },
  "LF51": {
      "icao": "LF51",
      "iata": "",
      "name": "Plan-de-Dieu Airport",
      "city": "Plan-de-Dieu",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 305,
      "lat": 44.1800003052,
      "lon": 4.9188899994,
      "tz": "Europe\/Paris"
  },
  "LF52": {
      "icao": "LF52",
      "iata": "",
      "name": "Chambley Airport",
      "city": "Chambley",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 866,
      "lat": 49.0255012512,
      "lon": 5.8760700226,
      "tz": "Europe\/Paris"
  },
  "LFAB": {
      "icao": "LFAB",
      "iata": "DPE",
      "name": "St Aubin Airport",
      "city": "Dieppe",
      "state": "Normandy",
      "country": "FR",
      "elevation": 344,
      "lat": 49.8824996948,
      "lon": 1.0852799416,
      "tz": "Europe\/Paris"
  },
  "LFAC": {
      "icao": "LFAC",
      "iata": "CQF",
      "name": "Calais-Dunkerque Airport",
      "city": "Marck",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 12,
      "lat": 50.9621009827,
      "lon": 1.9547599554,
      "tz": "Europe\/Paris"
  },
  "LFAD": {
      "icao": "LFAD",
      "iata": "XCP",
      "name": "Compiegne Margny Airport",
      "city": "Compiegne",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 315,
      "lat": 49.4343986511,
      "lon": 2.8061099052,
      "tz": "Europe\/Paris"
  },
  "LFAE": {
      "icao": "LFAE",
      "iata": "",
      "name": "Eu Mers Le Treport Airport",
      "city": "Le Treport",
      "state": "Normandy",
      "country": "FR",
      "elevation": 328,
      "lat": 50.0691986084,
      "lon": 1.4266699553,
      "tz": "Europe\/Paris"
  },
  "LFAF": {
      "icao": "LFAF",
      "iata": "XLN",
      "name": "Laon - Chambry Airport",
      "city": "Laon-Chambry",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 256,
      "lat": 49.5957984924,
      "lon": 3.6316699982,
      "tz": "Europe\/Paris"
  },
  "LFAG": {
      "icao": "LFAG",
      "iata": "XSJ",
      "name": "Peronne-Saint-Quentin Airport",
      "city": "Peronne\/Saint-Quentin",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 295,
      "lat": 49.8684997559,
      "lon": 3.0295801163,
      "tz": "Europe\/Paris"
  },
  "LFAH": {
      "icao": "LFAH",
      "iata": "",
      "name": "Soissons-Cuffies Airport",
      "city": "Soissons-Cuffies",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 138,
      "lat": 49.4000015259,
      "lon": 3.3169999123,
      "tz": "Europe\/Paris"
  },
  "LFAI": {
      "icao": "LFAI",
      "iata": "",
      "name": "Nangis-Les Loges Airport",
      "city": "Nangis\/Les Loges",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 428,
      "lat": 48.5961990356,
      "lon": 3.0067899227,
      "tz": "Europe\/Paris"
  },
  "LFAJ": {
      "icao": "LFAJ",
      "iata": "",
      "name": "Argentan Airport",
      "city": "Argentan",
      "state": "Normandy",
      "country": "FR",
      "elevation": 581,
      "lat": 48.7106018066,
      "lon": 0.003889,
      "tz": "Europe\/Paris"
  },
  "LFAK": {
      "icao": "LFAK",
      "iata": "XDK",
      "name": "Dunkerque Les Moeres Airport",
      "city": "Les Moeres",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": -3,
      "lat": 51.040599823,
      "lon": 2.5502800941,
      "tz": "Europe\/Brussels"
  },
  "LFAL": {
      "icao": "LFAL",
      "iata": "",
      "name": "La Fleche Thoree Les Pins Airport",
      "city": "La Fleche",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 121,
      "lat": 47.6941986084,
      "lon": 0.003333,
      "tz": "Europe\/Paris"
  },
  "LFAM": {
      "icao": "LFAM",
      "iata": "",
      "name": "Berck sur Mer Airport",
      "city": "Berck",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 30,
      "lat": 50.4230995178,
      "lon": 1.5919400454,
      "tz": "Europe\/Paris"
  },
  "LFAN": {
      "icao": "LFAN",
      "iata": "",
      "name": "Conde\/Noireau Airport",
      "city": "Conde-sur-noireau",
      "state": "Normandy",
      "country": "FR",
      "elevation": 833,
      "lat": 48.8917007446,
      "lon": -0.5019440055,
      "tz": "Europe\/Paris"
  },
  "LFAO": {
      "icao": "LFAO",
      "iata": "",
      "name": "Bagnoles-de-l'Orne-Couterne Airport",
      "city": "Bagnoles-de-l'Orne\/Couterne",
      "state": "Normandy",
      "country": "FR",
      "elevation": 718,
      "lat": 48.5457992554,
      "lon": -0.3874439895,
      "tz": "Europe\/Paris"
  },
  "LFAP": {
      "icao": "LFAP",
      "iata": "",
      "name": "Rethel Airport",
      "city": "Rethel",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 410,
      "lat": 49.4818992615,
      "lon": 4.3647198677,
      "tz": "Europe\/Paris"
  },
  "LFAQ": {
      "icao": "LFAQ",
      "iata": "BYF",
      "name": "Albert-Bray Airport",
      "city": "Albert\/Bray",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 364,
      "lat": 49.9715003967,
      "lon": 2.6976599693,
      "tz": "Europe\/Paris"
  },
  "LFAR": {
      "icao": "LFAR",
      "iata": "",
      "name": "Montdidier Airport",
      "city": "Montdidier",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 358,
      "lat": 49.6730995178,
      "lon": 2.5691699982,
      "tz": "Europe\/Paris"
  },
  "LFAS": {
      "icao": "LFAS",
      "iata": "",
      "name": "Falaise Airport",
      "city": "Falaise",
      "state": "Normandy",
      "country": "FR",
      "elevation": 512,
      "lat": 48.9272003174,
      "lon": -0.1447219998,
      "tz": "Europe\/Paris"
  },
  "LFAT": {
      "icao": "LFAT",
      "iata": "LTQ",
      "name": "Le Touquet-Cote d'Opale Airport",
      "city": "Le Touquet-Paris-Plage",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 36,
      "lat": 50.5173988342,
      "lon": 1.6205899715,
      "tz": "Europe\/Paris"
  },
  "LFAU": {
      "icao": "LFAU",
      "iata": "",
      "name": "Vauville Airport",
      "city": "Le Touquet",
      "state": "Normandy",
      "country": "FR",
      "elevation": 456,
      "lat": 49.6241989136,
      "lon": -1.8291699886,
      "tz": "Europe\/Paris"
  },
  "LFAV": {
      "icao": "LFAV",
      "iata": "XVS",
      "name": "Valenciennes-Denain Airport",
      "city": "Valenciennes\/Denain",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 177,
      "lat": 50.3258018494,
      "lon": 3.4612600803,
      "tz": "Europe\/Paris"
  },
  "LFAW": {
      "icao": "LFAW",
      "iata": "",
      "name": "Villerupt Airport",
      "city": "Valenciennes",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1299,
      "lat": 49.4113998413,
      "lon": 5.8905601501,
      "tz": "Europe\/Paris"
  },
  "LFAX": {
      "icao": "LFAX",
      "iata": "",
      "name": "Mortagne Au Perche Airport",
      "city": "Valenciennes",
      "state": "Normandy",
      "country": "FR",
      "elevation": 886,
      "lat": 48.5402984619,
      "lon": 0.5338889956,
      "tz": "Europe\/Paris"
  },
  "LFAY": {
      "icao": "LFAY",
      "iata": "QAM",
      "name": "Amiens-Glisy Airport",
      "city": "Amiens\/Glisy",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 208,
      "lat": 49.8730010986,
      "lon": 2.3870699406,
      "tz": "Europe\/Paris"
  },
  "LFBA": {
      "icao": "LFBA",
      "iata": "AGF",
      "name": "Agen-La Garenne Airport",
      "city": "Agen\/La Garenne",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 204,
      "lat": 44.1747016907,
      "lon": 0.5905560255,
      "tz": "Europe\/Paris"
  },
  "LFBC": {
      "icao": "LFBC",
      "iata": "",
      "name": "Cazaux (BA 120) Air Base",
      "city": "Cazaux (La Teste-de-Buch)",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 84,
      "lat": 44.5332984924,
      "lon": -1.125,
      "tz": "Europe\/Paris"
  },
  "LFBD": {
      "icao": "LFBD",
      "iata": "BOD",
      "name": "Bordeaux-Merignac (BA 106) Airport",
      "city": "Bordeaux\/Merignac",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 162,
      "lat": 44.8283004761,
      "lon": -0.7155560255,
      "tz": "Europe\/Paris"
  },
  "LFBE": {
      "icao": "LFBE",
      "iata": "EGC",
      "name": "Bergerac-Roumaniere Airport",
      "city": "Bergerac\/Roumaniere",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 171,
      "lat": 44.8252983093,
      "lon": 0.5186110139,
      "tz": "Europe\/Paris"
  },
  "LFBF": {
      "icao": "LFBF",
      "iata": "",
      "name": "Toulouse-Francazal (BA 101) Air Base",
      "city": "Toulouse\/Francazal",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 535,
      "lat": 43.5456008911,
      "lon": 1.3674999475,
      "tz": "Europe\/Paris"
  },
  "LFBG": {
      "icao": "LFBG",
      "iata": "CNG",
      "name": "Cognac-Chateaubernard (BA 709) Air Base",
      "city": "Cognac\/Chateaubernard",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 102,
      "lat": 45.6582984924,
      "lon": -0.3174999952,
      "tz": "Europe\/Paris"
  },
  "LFBH": {
      "icao": "LFBH",
      "iata": "LRH",
      "name": "La Rochelle-Ile de Re Airport",
      "city": "La Rochelle\/Ile de Re",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 74,
      "lat": 46.1791992188,
      "lon": -1.1952799559,
      "tz": "Europe\/Paris"
  },
  "LFBI": {
      "icao": "LFBI",
      "iata": "PIS",
      "name": "Poitiers-Biard Airport",
      "city": "Poitiers\/Biard",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 423,
      "lat": 46.5876998901,
      "lon": 0.3066659868,
      "tz": "Europe\/Paris"
  },
  "LFBJ": {
      "icao": "LFBJ",
      "iata": "",
      "name": "St Junien Airport",
      "city": "Poitiers",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 902,
      "lat": 45.903301239,
      "lon": 0.9200000167,
      "tz": "Europe\/Paris"
  },
  "LFBK": {
      "icao": "LFBK",
      "iata": "MCU",
      "name": "Montlucon-Gueret Airport",
      "city": "Montlucon\/Gueret",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 1497,
      "lat": 46.2225990295,
      "lon": 2.3639600277,
      "tz": "Europe\/Paris"
  },
  "LFBL": {
      "icao": "LFBL",
      "iata": "LIG",
      "name": "Limoges Airport",
      "city": "Limoges\/Bellegarde",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 1300,
      "lat": 45.8628005981,
      "lon": 1.1794400215,
      "tz": "Europe\/Paris"
  },
  "LFBM": {
      "icao": "LFBM",
      "iata": "XMJ",
      "name": "Mont-de-Marsan (BA 118) Air Base",
      "city": "Mont-de-Marsan",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 203,
      "lat": 43.9117012024,
      "lon": -0.5074999928,
      "tz": "Europe\/Paris"
  },
  "LFBN": {
      "icao": "LFBN",
      "iata": "NIT",
      "name": "Niort-Souche Airport",
      "city": "Niort\/Souche",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 203,
      "lat": 46.3112983704,
      "lon": -0.4015029967,
      "tz": "Europe\/Paris"
  },
  "LFBO": {
      "icao": "LFBO",
      "iata": "TLS",
      "name": "Toulouse-Blagnac Airport",
      "city": "Toulouse\/Blagnac",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 499,
      "lat": 43.6291007996,
      "lon": 1.3638199568,
      "tz": "Europe\/Paris"
  },
  "LFBP": {
      "icao": "LFBP",
      "iata": "PUF",
      "name": "Pau Pyrenees Airport",
      "city": "Pau\/Pyrenees (Uzein)",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 616,
      "lat": 43.3800010681,
      "lon": -0.41861099,
      "tz": "Europe\/Paris"
  },
  "LFBR": {
      "icao": "LFBR",
      "iata": "",
      "name": "Muret-Lherm Airport",
      "city": "Muret\/Lherm",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 622,
      "lat": 43.4488983154,
      "lon": 1.2633299828,
      "tz": "Europe\/Paris"
  },
  "LFBS": {
      "icao": "LFBS",
      "iata": "",
      "name": "Biscarrosse Parentis Airport",
      "city": "Muret",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 98,
      "lat": 44.3694000244,
      "lon": -1.1305600405,
      "tz": "Europe\/Paris"
  },
  "LFBT": {
      "icao": "LFBT",
      "iata": "LDE",
      "name": "Tarbes-Lourdes-Pyrenees Airport",
      "city": "Tarbes\/Lourdes\/Pyrenees",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1260,
      "lat": 43.1786994934,
      "lon": -0.0064389999,
      "tz": "Europe\/Paris"
  },
  "LFBU": {
      "icao": "LFBU",
      "iata": "ANG",
      "name": "Angouleme-Brie-Champniers Airport",
      "city": "Angouleme\/Brie\/Champniers",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 436,
      "lat": 45.7291984558,
      "lon": 0.2214560062,
      "tz": "Europe\/Paris"
  },
  "LFBV": {
      "icao": "LFBV",
      "iata": "BVE",
      "name": "Brive-La Roche Airport",
      "city": "Brive-la-Gaillarde",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 379,
      "lat": 45.1507987976,
      "lon": 1.4691699743,
      "tz": "Europe\/Paris"
  },
  "LFBX": {
      "icao": "LFBX",
      "iata": "PGX",
      "name": "Perigueux-Bassillac Airport",
      "city": "Perigueux\/Bassillac",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 328,
      "lat": 45.1981010437,
      "lon": 0.8155559897,
      "tz": "Europe\/Paris"
  },
  "LFBY": {
      "icao": "LFBY",
      "iata": "XDA",
      "name": "Dax Seyresse Airport",
      "city": "Perigueux",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 106,
      "lat": 43.689201355,
      "lon": -1.0688899755,
      "tz": "Europe\/Paris"
  },
  "LFBZ": {
      "icao": "LFBZ",
      "iata": "BIQ",
      "name": "Biarritz-Anglet-Bayonne Airport",
      "city": "Biarritz\/Anglet\/Bayonne",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 245,
      "lat": 43.4683990479,
      "lon": -1.5233199596,
      "tz": "Europe\/Paris"
  },
  "LFCA": {
      "icao": "LFCA",
      "iata": "XCX",
      "name": "Chatellerault Airport",
      "city": "Biarritz",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 207,
      "lat": 46.7813987732,
      "lon": 0.5519440174,
      "tz": "Europe\/Paris"
  },
  "LFCB": {
      "icao": "LFCB",
      "iata": "",
      "name": "Bagneres De Luchon Airport",
      "city": "Biarritz",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 2028,
      "lat": 42.7999992371,
      "lon": 0.6000000238,
      "tz": "Europe\/Paris"
  },
  "LFCC": {
      "icao": "LFCC",
      "iata": "ZAO",
      "name": "Cahors-Lalbenque Airport",
      "city": "Cahors\/Lalbenque",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 912,
      "lat": 44.351398468,
      "lon": 1.4752800465,
      "tz": "Europe\/Paris"
  },
  "LFCD": {
      "icao": "LFCD",
      "iata": "",
      "name": "Andernos Les Bains Airport",
      "city": "Cahors",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 66,
      "lat": 44.7560997009,
      "lon": -1.0633300543,
      "tz": "Europe\/Paris"
  },
  "LFCE": {
      "icao": "LFCE",
      "iata": "XGT",
      "name": "Gueret St Laurent Airport",
      "city": "Cahors",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 1207,
      "lat": 46.1791992188,
      "lon": 1.9583300352,
      "tz": "Europe\/Paris"
  },
  "LFCF": {
      "icao": "LFCF",
      "iata": "",
      "name": "Figeac Livernon Airport",
      "city": "Cahors",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1086,
      "lat": 44.6733016968,
      "lon": 1.7891700268,
      "tz": "Europe\/Paris"
  },
  "LFCG": {
      "icao": "LFCG",
      "iata": "",
      "name": "Saint-Girons-Antichan Airport",
      "city": "Saint-Girons\/Antichan",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1368,
      "lat": 43.0078010559,
      "lon": 1.1031500101,
      "tz": "Europe\/Paris"
  },
  "LFCH": {
      "icao": "LFCH",
      "iata": "XAC",
      "name": "Arcachon-La Teste-de-Buch Airport",
      "city": "Arcachon\/La Teste-de-Buch",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 49,
      "lat": 44.5964012146,
      "lon": -1.1108299494,
      "tz": "Europe\/Paris"
  },
  "LFCI": {
      "icao": "LFCI",
      "iata": "LBI",
      "name": "Albi-Le Sequestre Airport",
      "city": "Albi\/Le Sequestre",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 564,
      "lat": 43.913898468,
      "lon": 2.1130599976,
      "tz": "Europe\/Paris"
  },
  "LFCJ": {
      "icao": "LFCJ",
      "iata": "",
      "name": "Jonzac Neulles Airport",
      "city": "Albi",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 128,
      "lat": 45.4841995239,
      "lon": -0.4213890135,
      "tz": "Europe\/Paris"
  },
  "LFCK": {
      "icao": "LFCK",
      "iata": "DCM",
      "name": "Castres-Mazamet Airport",
      "city": "Castres\/Mazamet",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 788,
      "lat": 43.5563011169,
      "lon": 2.2891800404,
      "tz": "Europe\/Paris"
  },
  "LFCL": {
      "icao": "LFCL",
      "iata": "",
      "name": "Toulouse-Lasbordes Airport",
      "city": "Toulouse\/Lasbordes",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 459,
      "lat": 43.586101532,
      "lon": 1.4991699457,
      "tz": "Europe\/Paris"
  },
  "LFCM": {
      "icao": "LFCM",
      "iata": "",
      "name": "Millau-Larzac Airport",
      "city": "Millau\/Larzac",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 2606,
      "lat": 43.9892997742,
      "lon": 3.1830000877,
      "tz": "Europe\/Paris"
  },
  "LFCN": {
      "icao": "LFCN",
      "iata": "",
      "name": "Nogaro Airport",
      "city": "Millau",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 302,
      "lat": 43.7696990967,
      "lon": -0.0327779986,
      "tz": "Europe\/Paris"
  },
  "LFCO": {
      "icao": "LFCO",
      "iata": "",
      "name": "Oloron Herrere Airport",
      "city": "Millau",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 984,
      "lat": 43.1646995544,
      "lon": -0.5602779984,
      "tz": "Europe\/Paris"
  },
  "LFCP": {
      "icao": "LFCP",
      "iata": "",
      "name": "Pons Avy Airport",
      "city": "Millau",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 115,
      "lat": 45.5699996948,
      "lon": -0.5149999857,
      "tz": "Europe\/Paris"
  },
  "LFCQ": {
      "icao": "LFCQ",
      "iata": "",
      "name": "Graulhet-Montdragon Airport",
      "city": "Graulhet\/Montdragon",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 581,
      "lat": 43.7710990906,
      "lon": 2.0108299255,
      "tz": "Europe\/Paris"
  },
  "LFCR": {
      "icao": "LFCR",
      "iata": "RDZ",
      "name": "Rodez-Marcillac Airport",
      "city": "Rodez\/Marcillac",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1910,
      "lat": 44.4079017639,
      "lon": 2.4826700687,
      "tz": "Europe\/Paris"
  },
  "LFCS": {
      "icao": "LFCS",
      "iata": "",
      "name": "Bordeaux Leognan Saucats Airport",
      "city": "Rodez",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 190,
      "lat": 44.7002983093,
      "lon": -0.5955560207,
      "tz": "Europe\/Paris"
  },
  "LFCT": {
      "icao": "LFCT",
      "iata": "",
      "name": "Thouars Airport",
      "city": "Rodez",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 341,
      "lat": 46.9618988037,
      "lon": -0.1527779996,
      "tz": "Europe\/Paris"
  },
  "LFCU": {
      "icao": "LFCU",
      "iata": "",
      "name": "Ussel-Thalamy Airport",
      "city": "Ussel\/Thalamy",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 2428,
      "lat": 45.5346984863,
      "lon": 2.4238901138,
      "tz": "Europe\/Paris"
  },
  "LFCV": {
      "icao": "LFCV",
      "iata": "",
      "name": "Villefranche De Rouergue Airport",
      "city": "Rodez",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1096,
      "lat": 44.3699989319,
      "lon": 2.0280599594,
      "tz": "Europe\/Paris"
  },
  "LFCW": {
      "icao": "LFCW",
      "iata": "",
      "name": "Villeneuve-sur-Lot Airport",
      "city": "Villeneuve-sur-Lot",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 190,
      "lat": 44.396900177,
      "lon": 0.7588890195,
      "tz": "Europe\/Paris"
  },
  "LFCX": {
      "icao": "LFCX",
      "iata": "",
      "name": "Castelsarrasin Moissac Airport",
      "city": "Rodez",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 243,
      "lat": 44.0868988037,
      "lon": 1.1283299923,
      "tz": "Europe\/Paris"
  },
  "LFCY": {
      "icao": "LFCY",
      "iata": "RYN",
      "name": "Royan-Medis Airport",
      "city": "Royan\/Medis",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 72,
      "lat": 45.6281013489,
      "lon": -0.9725000262,
      "tz": "Europe\/Paris"
  },
  "LFCZ": {
      "icao": "LFCZ",
      "iata": "",
      "name": "Mimizan Airport",
      "city": "Mimizan",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 164,
      "lat": 44.1460990906,
      "lon": -1.1744400263,
      "tz": "Europe\/Paris"
  },
  "LFDA": {
      "icao": "LFDA",
      "iata": "",
      "name": "Aire-sur-l'Adour Airport",
      "city": "Aire-sur-Adour",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 259,
      "lat": 43.709400177,
      "lon": -0.2452780008,
      "tz": "Europe\/Paris"
  },
  "LFDB": {
      "icao": "LFDB",
      "iata": "XMW",
      "name": "Montauban Airport",
      "city": "Montauban",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 351,
      "lat": 44.0256996155,
      "lon": 1.3780399561,
      "tz": "Europe\/Paris"
  },
  "LFDC": {
      "icao": "LFDC",
      "iata": "",
      "name": "Montendre Marcillac Airport",
      "city": "Royan",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 148,
      "lat": 45.2743988037,
      "lon": -0.4522219896,
      "tz": "Europe\/Paris"
  },
  "LFDE": {
      "icao": "LFDE",
      "iata": "",
      "name": "Egletons Airport",
      "city": "Royan",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 1857,
      "lat": 45.4213981628,
      "lon": 2.0688900948,
      "tz": "Europe\/Paris"
  },
  "LFDF": {
      "icao": "LFDF",
      "iata": "",
      "name": "Ste Foy La Grande Airport",
      "city": "Royan",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 282,
      "lat": 44.8535995483,
      "lon": 0.1766670048,
      "tz": "Europe\/Paris"
  },
  "LFDG": {
      "icao": "LFDG",
      "iata": "",
      "name": "Gaillac Lisle Sur Tarn Airport",
      "city": "Royan",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 446,
      "lat": 43.8838996887,
      "lon": 1.8755600452,
      "tz": "Europe\/Paris"
  },
  "LFDH": {
      "icao": "LFDH",
      "iata": "",
      "name": "Auch-Lamothe Airport",
      "city": "Auch\/Lamothe",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 411,
      "lat": 43.6878013611,
      "lon": 0.6016669869,
      "tz": "Europe\/Paris"
  },
  "LFDI": {
      "icao": "LFDI",
      "iata": "XLR",
      "name": "Libourne-Artigues-de-Lussac Airport",
      "city": "Libourne\/Artigues-de-Lussac",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 157,
      "lat": 44.9824981689,
      "lon": -0.1347219944,
      "tz": "Europe\/Paris"
  },
  "LFDJ": {
      "icao": "LFDJ",
      "iata": "",
      "name": "Pamiers-Les Pujols Airport",
      "city": "Pamiers\/Les Pujols",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1115,
      "lat": 43.0905990601,
      "lon": 1.6958299875,
      "tz": "Europe\/Paris"
  },
  "LFDK": {
      "icao": "LFDK",
      "iata": "",
      "name": "Soulac Sur Mer Airport",
      "city": "Pamiers",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 7,
      "lat": 45.4949989319,
      "lon": -1.0822199583,
      "tz": "Europe\/Paris"
  },
  "LFDL": {
      "icao": "LFDL",
      "iata": "",
      "name": "Loudun Airport",
      "city": "Pamiers",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 315,
      "lat": 47.0372009277,
      "lon": 0.1013889983,
      "tz": "Europe\/Paris"
  },
  "LFDM": {
      "icao": "LFDM",
      "iata": "",
      "name": "Marmande-Virazeil Airport",
      "city": "Marmande\/Virazeil",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 105,
      "lat": 44.4989013672,
      "lon": 0.2005140036,
      "tz": "Europe\/Paris"
  },
  "LFDN": {
      "icao": "LFDN",
      "iata": "RCO",
      "name": "Rochefort-Saint-Agnant (BA 721) Airport",
      "city": "Rochefort\/Saint-Agnant",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 60,
      "lat": 45.8877983093,
      "lon": -0.9830560088,
      "tz": "Europe\/Paris"
  },
  "LFDO": {
      "icao": "LFDO",
      "iata": "",
      "name": "Bordeaux Souge Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 135,
      "lat": 44.8499984741,
      "lon": -0.8000000119,
      "tz": "Europe\/Paris"
  },
  "LFDP": {
      "icao": "LFDP",
      "iata": "",
      "name": "St Pierre D'oleron Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 20,
      "lat": 45.9592018127,
      "lon": -1.3161100149,
      "tz": "Europe\/Paris"
  },
  "LFDQ": {
      "icao": "LFDQ",
      "iata": "",
      "name": "Castelnau Magnoac Airport",
      "city": "Rochefort",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 984,
      "lat": 43.2793998718,
      "lon": 0.5216670036,
      "tz": "Europe\/Paris"
  },
  "LFDR": {
      "icao": "LFDR",
      "iata": "",
      "name": "La Reole Floudes Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 43,
      "lat": 44.5680999756,
      "lon": -0.0561110005,
      "tz": "Europe\/Paris"
  },
  "LFDS": {
      "icao": "LFDS",
      "iata": "XSL",
      "name": "Sarlat Domme Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 978,
      "lat": 44.7933006287,
      "lon": 1.2447199821,
      "tz": "Europe\/Paris"
  },
  "LFDT": {
      "icao": "LFDT",
      "iata": "XTB",
      "name": "Tarbes Laloubere Airport",
      "city": "Rochefort",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1076,
      "lat": 43.2160987854,
      "lon": 0.0786110014,
      "tz": "Europe\/Paris"
  },
  "LFDU": {
      "icao": "LFDU",
      "iata": "",
      "name": "Lesparre St Laurent Medoc Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 105,
      "lat": 45.1977996826,
      "lon": -0.8822219968,
      "tz": "Europe\/Paris"
  },
  "LFDV": {
      "icao": "LFDV",
      "iata": "",
      "name": "Couhe Verac Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 499,
      "lat": 46.2728004456,
      "lon": 0.1905560046,
      "tz": "Europe\/Paris"
  },
  "LFDW": {
      "icao": "LFDW",
      "iata": "",
      "name": "Chauvigny Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 440,
      "lat": 46.5835990906,
      "lon": 0.6424999833,
      "tz": "Europe\/Paris"
  },
  "LFDX": {
      "icao": "LFDX",
      "iata": "",
      "name": "Fumel Montayral Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 692,
      "lat": 44.4636001587,
      "lon": 1.0077799559,
      "tz": "Europe\/Paris"
  },
  "LFDY": {
      "icao": "LFDY",
      "iata": "",
      "name": "Bordeaux Yvrac Airport",
      "city": "Rochefort",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 240,
      "lat": 44.8772010803,
      "lon": -0.4791670144,
      "tz": "Europe\/Paris"
  },
  "LFDZ": {
      "icao": "LFDZ",
      "iata": "",
      "name": "Condat-Sur-Vezere Airport",
      "city": "Condat-Sur-Vezere",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 499,
      "lat": 45.0999984741,
      "lon": 1.2170000076,
      "tz": "Europe\/Paris"
  },
  "LFEA": {
      "icao": "LFEA",
      "iata": "",
      "name": "Belle Ile Airport",
      "city": "Rochefort",
      "state": "Brittany",
      "country": "FR",
      "elevation": 171,
      "lat": 47.3266983032,
      "lon": -3.1983299255,
      "tz": "Europe\/Paris"
  },
  "LFEB": {
      "icao": "LFEB",
      "iata": "",
      "name": "Dinan - Trelivan Airport",
      "city": "Dinan",
      "state": "Brittany",
      "country": "FR",
      "elevation": 394,
      "lat": 48.4444007874,
      "lon": -2.1013898849,
      "tz": "Europe\/Paris"
  },
  "LFEC": {
      "icao": "LFEC",
      "iata": "",
      "name": "Ouessant Airport",
      "city": "Ushant",
      "state": "Brittany",
      "country": "FR",
      "elevation": 142,
      "lat": 48.4631996155,
      "lon": -5.0635800362,
      "tz": "Europe\/Paris"
  },
  "LFED": {
      "icao": "LFED",
      "iata": "",
      "name": "Pontivy Airport",
      "city": "Pontivy",
      "state": "Brittany",
      "country": "FR",
      "elevation": 407,
      "lat": 48.0584983826,
      "lon": -2.9218299389,
      "tz": "Europe\/Paris"
  },
  "LFEF": {
      "icao": "LFEF",
      "iata": "",
      "name": "Amboise Dierre Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 180,
      "lat": 47.3414001465,
      "lon": 0.9424999952,
      "tz": "Europe\/Paris"
  },
  "LFEG": {
      "icao": "LFEG",
      "iata": "",
      "name": "Argenton Sur Creuse Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 663,
      "lat": 46.5969009399,
      "lon": 1.6024999619,
      "tz": "Europe\/Paris"
  },
  "LFEH": {
      "icao": "LFEH",
      "iata": "",
      "name": "Aubigny-sur-Nere Airport",
      "city": "Aubigny-sur-Nere",
      "state": "Centre",
      "country": "FR",
      "elevation": 630,
      "lat": 47.4742012024,
      "lon": 2.3930499554,
      "tz": "Europe\/Paris"
  },
  "LFEI": {
      "icao": "LFEI",
      "iata": "",
      "name": "Briare Chatillon Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 539,
      "lat": 47.6143989563,
      "lon": 2.7819399834,
      "tz": "Europe\/Paris"
  },
  "LFEJ": {
      "icao": "LFEJ",
      "iata": "",
      "name": "Chateauroux Villers Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 541,
      "lat": 46.8418998718,
      "lon": 1.6211099625,
      "tz": "Europe\/Paris"
  },
  "LFEK": {
      "icao": "LFEK",
      "iata": "",
      "name": "Issoudun Le Fay Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 531,
      "lat": 46.8885993958,
      "lon": 2.0413899422,
      "tz": "Europe\/Paris"
  },
  "LFEL": {
      "icao": "LFEL",
      "iata": "",
      "name": "Le Blanc Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 377,
      "lat": 46.6208000183,
      "lon": 1.0874999762,
      "tz": "Europe\/Paris"
  },
  "LFEM": {
      "icao": "LFEM",
      "iata": "",
      "name": "Montargis Vimory Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 308,
      "lat": 47.9606018066,
      "lon": 2.6858301163,
      "tz": "Europe\/Paris"
  },
  "LFEN": {
      "icao": "LFEN",
      "iata": "",
      "name": "Tours Sorigny Airport",
      "city": "Pontivy",
      "state": "Centre",
      "country": "FR",
      "elevation": 299,
      "lat": 47.2675018311,
      "lon": 0.7011110187,
      "tz": "Europe\/Paris"
  },
  "LFEO": {
      "icao": "LFEO",
      "iata": "",
      "name": "St Malo St Serva Airport",
      "city": "",
      "state": "Brittany",
      "country": "FR",
      "elevation": 151,
      "lat": 48.6136016846,
      "lon": -1.9736100435,
      "tz": "Europe\/Paris"
  },
  "LFEP": {
      "icao": "LFEP",
      "iata": "",
      "name": "Pouilly Maconge Airport",
      "city": "Pontivy",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1411,
      "lat": 47.2214012146,
      "lon": 4.5611100197,
      "tz": "Europe\/Paris"
  },
  "LFEQ": {
      "icao": "LFEQ",
      "iata": "",
      "name": "Quiberon Airport",
      "city": "Pontivy",
      "state": "Brittany",
      "country": "FR",
      "elevation": 37,
      "lat": 47.4822006226,
      "lon": -3.0999999046,
      "tz": "Europe\/Paris"
  },
  "LFER": {
      "icao": "LFER",
      "iata": "",
      "name": "Redon Bains-sur-Oust Airport",
      "city": "Redon",
      "state": "Brittany",
      "country": "FR",
      "elevation": 223,
      "lat": 47.6994018555,
      "lon": -2.0366699696,
      "tz": "Europe\/Paris"
  },
  "LFES": {
      "icao": "LFES",
      "iata": "",
      "name": "Guiscriff Scaer Airport",
      "city": "Guiscriff",
      "state": "Brittany",
      "country": "FR",
      "elevation": 574,
      "lat": 48.0525016785,
      "lon": -3.6647200584,
      "tz": "Europe\/Paris"
  },
  "LFET": {
      "icao": "LFET",
      "iata": "",
      "name": "Til Chatel Airport",
      "city": "Guiscriff",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 938,
      "lat": 47.5475006104,
      "lon": 5.2119398117,
      "tz": "Europe\/Paris"
  },
  "LFEU": {
      "icao": "LFEU",
      "iata": "",
      "name": "Bar Le Duc Airport",
      "city": "Guiscriff",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 909,
      "lat": 48.8683013916,
      "lon": 5.1858301163,
      "tz": "Europe\/Paris"
  },
  "LFEV": {
      "icao": "LFEV",
      "iata": "",
      "name": "Gray St Adrien Airport",
      "city": "Guiscriff",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 679,
      "lat": 47.4333000183,
      "lon": 5.6227798462,
      "tz": "Europe\/Paris"
  },
  "LFEW": {
      "icao": "LFEW",
      "iata": "",
      "name": "Saulieu Liernais Airport",
      "city": "Guiscriff",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1722,
      "lat": 47.2393989563,
      "lon": 4.26583004,
      "tz": "Europe\/Paris"
  },
  "LFEX": {
      "icao": "LFEX",
      "iata": "",
      "name": "Nancy Azelot Airport",
      "city": "Guiscriff",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 961,
      "lat": 48.5928001404,
      "lon": 6.241109848,
      "tz": "Europe\/Paris"
  },
  "LFEY": {
      "icao": "LFEY",
      "iata": "IDY",
      "name": "Ile d'Yeu Airport",
      "city": "Ile d'Yeu",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 79,
      "lat": 46.7186012268,
      "lon": -2.3911099434,
      "tz": "Europe\/Paris"
  },
  "LFEZ": {
      "icao": "LFEZ",
      "iata": "",
      "name": "Nancy Malzeville Airport",
      "city": "Guiscriff",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1253,
      "lat": 48.7243995667,
      "lon": 6.2077798843,
      "tz": "Europe\/Paris"
  },
  "LFFB": {
      "icao": "LFFB",
      "iata": "",
      "name": "Buno Bonnevaux Airport",
      "city": "Guiscriff",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 420,
      "lat": 48.3511009216,
      "lon": 2.4255599976,
      "tz": "Europe\/Paris"
  },
  "LFFC": {
      "icao": "LFFC",
      "iata": "",
      "name": "Mantes Cherence Airport",
      "city": "Guiscriff",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 512,
      "lat": 49.0788993835,
      "lon": 1.6897200346,
      "tz": "Europe\/Paris"
  },
  "LFFD": {
      "icao": "LFFD",
      "iata": "",
      "name": "St Andre De L'eure Airport",
      "city": "Guiscriff",
      "state": "Normandy",
      "country": "FR",
      "elevation": 489,
      "lat": 48.898601532,
      "lon": 1.2505600452,
      "tz": "Europe\/Paris"
  },
  "LFFE": {
      "icao": "LFFE",
      "iata": "",
      "name": "Enghien Moisselles Airport",
      "city": "Guiscriff",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 335,
      "lat": 49.0463981628,
      "lon": 2.3530600071,
      "tz": "Europe\/Paris"
  },
  "LFFG": {
      "icao": "LFFG",
      "iata": "",
      "name": "La Ferte Gaucher Airport",
      "city": "Guiscriff",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 538,
      "lat": 48.7557983398,
      "lon": 3.2766699791,
      "tz": "Europe\/Paris"
  },
  "LFFH": {
      "icao": "LFFH",
      "iata": "",
      "name": "Chateau-Thierry - Belleau Airport",
      "city": "Guiscriff",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 728,
      "lat": 49.067199707,
      "lon": 3.3569400311,
      "tz": "Europe\/Paris"
  },
  "LFFI": {
      "icao": "LFFI",
      "iata": "",
      "name": "Ancenis Airport",
      "city": "Ancenis",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 111,
      "lat": 47.4081001282,
      "lon": -1.1775000095,
      "tz": "Europe\/Paris"
  },
  "LFFJ": {
      "icao": "LFFJ",
      "iata": "",
      "name": "Joinville Mussey Airport",
      "city": "Guiscriff",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1024,
      "lat": 48.386100769,
      "lon": 5.1449999809,
      "tz": "Europe\/Paris"
  },
  "LFFK": {
      "icao": "LFFK",
      "iata": "",
      "name": "Fontenay Le Comte Airport",
      "city": "Guiscriff",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 85,
      "lat": 46.4413986206,
      "lon": -0.7927780151,
      "tz": "Europe\/Paris"
  },
  "LFFL": {
      "icao": "LFFL",
      "iata": "",
      "name": "Bailleau Armenonville Airport",
      "city": "Guiscriff",
      "state": "Centre",
      "country": "FR",
      "elevation": 509,
      "lat": 48.5158004761,
      "lon": 1.6399999857,
      "tz": "Europe\/Paris"
  },
  "LFFM": {
      "icao": "LFFM",
      "iata": "",
      "name": "Lamotte Beuvron Airport",
      "city": "Guiscriff",
      "state": "Centre",
      "country": "FR",
      "elevation": 413,
      "lat": 47.6567001343,
      "lon": 1.9891699553,
      "tz": "Europe\/Paris"
  },
  "LFFN": {
      "icao": "LFFN",
      "iata": "",
      "name": "Brienne-le-Chateau Airport",
      "city": "Brienne-le-Chateau",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 381,
      "lat": 48.4297981262,
      "lon": 4.4822201729,
      "tz": "Europe\/Paris"
  },
  "LFFP": {
      "icao": "LFFP",
      "iata": "",
      "name": "Pithiviers Airport",
      "city": "Guiscriff",
      "state": "Centre",
      "country": "FR",
      "elevation": 384,
      "lat": 48.1571998596,
      "lon": 2.1925001144,
      "tz": "Europe\/Paris"
  },
  "LFFQ": {
      "icao": "LFFQ",
      "iata": "",
      "name": "La Ferte Alais Airport",
      "city": "Guiscriff",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 453,
      "lat": 48.4977989197,
      "lon": 2.3433299065,
      "tz": "Europe\/Paris"
  },
  "LFFR": {
      "icao": "LFFR",
      "iata": "",
      "name": "Bar Sur Seine Airport",
      "city": "Guiscriff",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 938,
      "lat": 48.0668983459,
      "lon": 4.4136099815,
      "tz": "Europe\/Paris"
  },
  "LFFT": {
      "icao": "LFFT",
      "iata": "",
      "name": "Neufchateau Airport",
      "city": "Guiscriff",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1224,
      "lat": 48.3624992371,
      "lon": 5.7213897705,
      "tz": "Europe\/Paris"
  },
  "LFFU": {
      "icao": "LFFU",
      "iata": "",
      "name": "Chateauneuf Sur Cher Airport",
      "city": "Guiscriff",
      "state": "Centre",
      "country": "FR",
      "elevation": 551,
      "lat": 46.8711013794,
      "lon": 2.376940012,
      "tz": "Europe\/Paris"
  },
  "LFFV": {
      "icao": "LFFV",
      "iata": "",
      "name": "Vierzon Mereau Airport",
      "city": "Guiscriff",
      "state": "Centre",
      "country": "FR",
      "elevation": 430,
      "lat": 47.1946983337,
      "lon": 2.0666699409,
      "tz": "Europe\/Paris"
  },
  "LFFW": {
      "icao": "LFFW",
      "iata": "",
      "name": "Montaigu St Georges Airport",
      "city": "Guiscriff",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 184,
      "lat": 46.9331016541,
      "lon": -1.3255599737,
      "tz": "Europe\/Paris"
  },
  "LFFX": {
      "icao": "LFFX",
      "iata": "",
      "name": "Tournus Cuisery Airport",
      "city": "Guiscriff",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 682,
      "lat": 46.5628013611,
      "lon": 4.9766697884,
      "tz": "Europe\/Paris"
  },
  "LFFY": {
      "icao": "LFFY",
      "iata": "",
      "name": "Etrepagny Airport",
      "city": "Guiscriff",
      "state": "Normandy",
      "country": "FR",
      "elevation": 308,
      "lat": 49.306098938,
      "lon": 1.6386100054,
      "tz": "Europe\/Paris"
  },
  "LFFZ": {
      "icao": "LFFZ",
      "iata": "",
      "name": "Sezanne St Remy Airport",
      "city": "Guiscriff",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 354,
      "lat": 48.7106018066,
      "lon": 3.7641699314,
      "tz": "Europe\/Paris"
  },
  "LFGA": {
      "icao": "LFGA",
      "iata": "CMR",
      "name": "Colmar-Houssen Airport",
      "city": "Colmar\/Houssen",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 628,
      "lat": 48.1099014282,
      "lon": 7.3590102196,
      "tz": "Europe\/Paris"
  },
  "LFGB": {
      "icao": "LFGB",
      "iata": "",
      "name": "Mulhouse-Habsheim Airport",
      "city": "Mulhouse\/Habsheim",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 788,
      "lat": 47.7412986755,
      "lon": 7.4322099686,
      "tz": "Europe\/Paris"
  },
  "LFGC": {
      "icao": "LFGC",
      "iata": "",
      "name": "Strasbourg Neuhof Airport",
      "city": "Colmar",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 456,
      "lat": 48.5544013977,
      "lon": 7.7780599594,
      "tz": "Europe\/Paris"
  },
  "LFGD": {
      "icao": "LFGD",
      "iata": "",
      "name": "Arbois Airport",
      "city": "Colmar",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 876,
      "lat": 46.9199981689,
      "lon": 5.7600002289,
      "tz": "Europe\/Paris"
  },
  "LFGE": {
      "icao": "LFGE",
      "iata": "",
      "name": "Avallon Airport",
      "city": "Colmar",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 781,
      "lat": 47.5031013489,
      "lon": 3.8994400501,
      "tz": "Europe\/Paris"
  },
  "LFGF": {
      "icao": "LFGF",
      "iata": "XBV",
      "name": "Beaune-Challanges Airport",
      "city": "Beaune\/Challanges",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 656,
      "lat": 47.0059013367,
      "lon": 4.8934202194,
      "tz": "Europe\/Paris"
  },
  "LFGG": {
      "icao": "LFGG",
      "iata": "",
      "name": "Belfort Chaux Airport",
      "city": "Colmar",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1368,
      "lat": 47.7022018433,
      "lon": 6.8324999809,
      "tz": "Europe\/Paris"
  },
  "LFGH": {
      "icao": "LFGH",
      "iata": "",
      "name": "Cosne Sur Loire Airport",
      "city": "Colmar",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 581,
      "lat": 47.3605995178,
      "lon": 2.9194400311,
      "tz": "Europe\/Paris"
  },
  "LFGI": {
      "icao": "LFGI",
      "iata": "",
      "name": "Dijon Darois Airport",
      "city": "Colmar",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1585,
      "lat": 47.3869018555,
      "lon": 4.9480600357,
      "tz": "Europe\/Paris"
  },
  "LFGJ": {
      "icao": "LFGJ",
      "iata": "DLE",
      "name": "Dole-Tavaux Airport",
      "city": "Dole\/Tavaux",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 645,
      "lat": 47.0390014648,
      "lon": 5.4272499084,
      "tz": "Europe\/Paris"
  },
  "LFGK": {
      "icao": "LFGK",
      "iata": "",
      "name": "Joigny Airport",
      "city": "Joigny",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 732,
      "lat": 47.9921989441,
      "lon": 3.3922200203,
      "tz": "Europe\/Paris"
  },
  "LFGL": {
      "icao": "LFGL",
      "iata": "",
      "name": "Lons Le Saulnier Courlaoux Airport",
      "city": "Dole",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 761,
      "lat": 46.6761016846,
      "lon": 5.4711098671,
      "tz": "Europe\/Paris"
  },
  "LFGM": {
      "icao": "LFGM",
      "iata": "",
      "name": "Montceau Les Mines Airport",
      "city": "Dole",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1030,
      "lat": 46.6041984558,
      "lon": 4.3338899612,
      "tz": "Europe\/Paris"
  },
  "LFGN": {
      "icao": "LFGN",
      "iata": "",
      "name": "Paray Le Monial Airport",
      "city": "Dole",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 997,
      "lat": 46.4678001404,
      "lon": 4.1350002289,
      "tz": "Europe\/Paris"
  },
  "LFGO": {
      "icao": "LFGO",
      "iata": "",
      "name": "Pont Sur Yonne Airport",
      "city": "Dole",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 236,
      "lat": 48.290599823,
      "lon": 3.2508299351,
      "tz": "Europe\/Paris"
  },
  "LFGP": {
      "icao": "LFGP",
      "iata": "",
      "name": "St Florentin Cheu Airport",
      "city": "Dole",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 351,
      "lat": 47.9822006226,
      "lon": 3.7783300877,
      "tz": "Europe\/Paris"
  },
  "LFGQ": {
      "icao": "LFGQ",
      "iata": "",
      "name": "Semur En Auxois Airport",
      "city": "Dole",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1053,
      "lat": 47.4818992615,
      "lon": 4.3441700935,
      "tz": "Europe\/Paris"
  },
  "LFGR": {
      "icao": "LFGR",
      "iata": "",
      "name": "Doncourt Les Conflans Airport",
      "city": "Dole",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 804,
      "lat": 49.1528015137,
      "lon": 5.932779789,
      "tz": "Europe\/Paris"
  },
  "LFGS": {
      "icao": "LFGS",
      "iata": "",
      "name": "Longuyon Villette Airport",
      "city": "Dole",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1148,
      "lat": 49.4844017029,
      "lon": 5.5727801323,
      "tz": "Europe\/Brussels"
  },
  "LFGT": {
      "icao": "LFGT",
      "iata": "",
      "name": "Sarrebourg Buhl Airport",
      "city": "Dole",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 873,
      "lat": 48.7188987732,
      "lon": 7.0794401169,
      "tz": "Europe\/Paris"
  },
  "LFGU": {
      "icao": "LFGU",
      "iata": "",
      "name": "Sarreguemines Neunkirch Airport",
      "city": "Dole",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 853,
      "lat": 49.1281013489,
      "lon": 7.1083297729,
      "tz": "Europe\/Berlin"
  },
  "LFGV": {
      "icao": "LFGV",
      "iata": "",
      "name": "Thionville Yutz Airport",
      "city": "Dole",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 518,
      "lat": 49.3546981812,
      "lon": 6.2013897896,
      "tz": "Europe\/Paris"
  },
  "LFGW": {
      "icao": "LFGW",
      "iata": "XVN",
      "name": "Verdun-Le Rozelier Airport",
      "city": "Verdun\/Le Rozelier",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1230,
      "lat": 49.1223983765,
      "lon": 5.4690499306,
      "tz": "Europe\/Paris"
  },
  "LFGX": {
      "icao": "LFGX",
      "iata": "",
      "name": "Champagnole Crotenay Airport",
      "city": "Verdun",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1745,
      "lat": 46.7644004822,
      "lon": 5.8208298683,
      "tz": "Europe\/Paris"
  },
  "LFGY": {
      "icao": "LFGY",
      "iata": "",
      "name": "St Die Remomeix Airport",
      "city": "Verdun",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1184,
      "lat": 48.26720047,
      "lon": 7.0086097717,
      "tz": "Europe\/Paris"
  },
  "LFGZ": {
      "icao": "LFGZ",
      "iata": "",
      "name": "Nuits St Georges Airport",
      "city": "Verdun",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 797,
      "lat": 47.1431007385,
      "lon": 4.9691700935,
      "tz": "Europe\/Paris"
  },
  "LFHA": {
      "icao": "LFHA",
      "iata": "",
      "name": "Issoire Le Broc Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1240,
      "lat": 45.5149993896,
      "lon": 3.2674999237,
      "tz": "Europe\/Paris"
  },
  "LFHC": {
      "icao": "LFHC",
      "iata": "",
      "name": "Perouges - Meximieux Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 702,
      "lat": 45.8697013855,
      "lon": 5.1872200966,
      "tz": "Europe\/Paris"
  },
  "LFHD": {
      "icao": "LFHD",
      "iata": "",
      "name": "Pierrelatte Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 197,
      "lat": 44.3988990784,
      "lon": 4.7180600166,
      "tz": "Europe\/Paris"
  },
  "LFHE": {
      "icao": "LFHE",
      "iata": "",
      "name": "Romans - Saint-Paul Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 594,
      "lat": 45.0661010742,
      "lon": 5.1033301353,
      "tz": "Europe\/Paris"
  },
  "LFHF": {
      "icao": "LFHF",
      "iata": "",
      "name": "Ruoms Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 361,
      "lat": 44.4453010559,
      "lon": 4.3338899612,
      "tz": "Europe\/Paris"
  },
  "LFHG": {
      "icao": "LFHG",
      "iata": "",
      "name": "St Chamond L'horme Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1296,
      "lat": 45.4930992126,
      "lon": 4.5355601311,
      "tz": "Europe\/Paris"
  },
  "LFHH": {
      "icao": "LFHH",
      "iata": "",
      "name": "Vienne Reventin Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 719,
      "lat": 45.4641990662,
      "lon": 4.8294401169,
      "tz": "Europe\/Paris"
  },
  "LFHI": {
      "icao": "LFHI",
      "iata": "",
      "name": "Morestel Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 804,
      "lat": 45.6878013611,
      "lon": 5.4536099434,
      "tz": "Europe\/Paris"
  },
  "LFHJ": {
      "icao": "LFHJ",
      "iata": "",
      "name": "Lyon Corbas Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 650,
      "lat": 45.6542015076,
      "lon": 4.9136099815,
      "tz": "Europe\/Paris"
  },
  "LFHL": {
      "icao": "LFHL",
      "iata": "",
      "name": "Langogne - Lesperon Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 3330,
      "lat": 44.706401825,
      "lon": 3.8883299828,
      "tz": "Europe\/Paris"
  },
  "LFHM": {
      "icao": "LFHM",
      "iata": "MVV",
      "name": "Megeve Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 4823,
      "lat": 45.8208007813,
      "lon": 6.6522197723,
      "tz": "Europe\/Paris"
  },
  "LFHN": {
      "icao": "LFHN",
      "iata": "",
      "name": "Bellegarde-Vouvray Airport",
      "city": "Verdun",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1624,
      "lat": 46.1241989136,
      "lon": 5.8061099052,
      "tz": "Europe\/Paris"
  },
  "LFHO": {
      "icao": "LFHO",
      "iata": "OBS",
      "name": "Aubenas-Ardeche Meridional Airport",
      "city": "Aubenas\/Ardeche Meridional",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 923,
      "lat": 44.5442008972,
      "lon": 4.3721899986,
      "tz": "Europe\/Paris"
  },
  "LFHP": {
      "icao": "LFHP",
      "iata": "LPY",
      "name": "Le Puy-Loudes Airport",
      "city": "Le Puy\/Loudes",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 2731,
      "lat": 45.0806999207,
      "lon": 3.7628901005,
      "tz": "Europe\/Paris"
  },
  "LFHQ": {
      "icao": "LFHQ",
      "iata": "",
      "name": "Saint-Flour-Coltines Airport",
      "city": "Saint-Flour\/Coltines",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 3218,
      "lat": 45.0764007568,
      "lon": 2.9936099052,
      "tz": "Europe\/Paris"
  },
  "LFHR": {
      "icao": "LFHR",
      "iata": "",
      "name": "Brioude Beaumont Airport",
      "city": "St Flour",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1483,
      "lat": 45.3250007629,
      "lon": 3.35916996,
      "tz": "Europe\/Paris"
  },
  "LFHS": {
      "icao": "LFHS",
      "iata": "XBK",
      "name": "Bourg-Ceyzeriat Airport",
      "city": "Bourg\/Ceyzeriat",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 857,
      "lat": 46.2009010315,
      "lon": 5.2920298576,
      "tz": "Europe\/Paris"
  },
  "LFHT": {
      "icao": "LFHT",
      "iata": "",
      "name": "Ambert Le Poyet Airport",
      "city": "Bourg",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1847,
      "lat": 45.5168991089,
      "lon": 3.7463901043,
      "tz": "Europe\/Paris"
  },
  "LFHU": {
      "icao": "LFHU",
      "iata": "AHZ",
      "name": "L'alpe D'huez Airport",
      "city": "Bourg",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 6102,
      "lat": 45.0882987976,
      "lon": 6.0847201347,
      "tz": "Europe\/Paris"
  },
  "LFHV": {
      "icao": "LFHV",
      "iata": "",
      "name": "Villefranche-Tarare Airport",
      "city": "Villefranche\/Tarare",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1076,
      "lat": 45.919983,
      "lon": 4.634931,
      "tz": "Europe\/Paris"
  },
  "LFHW": {
      "icao": "LFHW",
      "iata": "",
      "name": "Belleville Villie Morgon Airport",
      "city": "Villefranche",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 705,
      "lat": 46.1427993774,
      "lon": 4.7147197723,
      "tz": "Europe\/Paris"
  },
  "LFHX": {
      "icao": "LFHX",
      "iata": "",
      "name": "Lapalisse - Perigny Airport",
      "city": "Villefranche",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1040,
      "lat": 46.2538986206,
      "lon": 3.5886099339,
      "tz": "Europe\/Paris"
  },
  "LFHY": {
      "icao": "LFHY",
      "iata": "XMU",
      "name": "Moulins-Montbeugny Airport",
      "city": "Moulins\/Montbeugny",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 915,
      "lat": 46.5345993042,
      "lon": 3.423719883,
      "tz": "Europe\/Paris"
  },
  "LFHZ": {
      "icao": "LFHZ",
      "iata": "",
      "name": "Sallanches Airport",
      "city": "Moulins",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1755,
      "lat": 45.9538993835,
      "lon": 6.6391701698,
      "tz": "Europe\/Paris"
  },
  "LFIB": {
      "icao": "LFIB",
      "iata": "",
      "name": "Belves St Pardoux Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 791,
      "lat": 44.7825012207,
      "lon": 0.9588890076,
      "tz": "Europe\/Paris"
  },
  "LFID": {
      "icao": "LFID",
      "iata": "",
      "name": "Condom Valence Sur Baise Airport",
      "city": "Moulins",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 444,
      "lat": 43.9103012085,
      "lon": 0.387221992,
      "tz": "Europe\/Paris"
  },
  "LFIF": {
      "icao": "LFIF",
      "iata": "",
      "name": "Saint-Affrique-Belmont Airport",
      "city": "Saint-Affrique\/Belmont",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1686,
      "lat": 43.823299408,
      "lon": 2.7452800274,
      "tz": "Europe\/Paris"
  },
  "LFIG": {
      "icao": "LFIG",
      "iata": "",
      "name": "Cassagnes-Begonhes Airport",
      "city": "Cassagnes-Begonhes",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 2024,
      "lat": 44.1777992249,
      "lon": 2.5150001049,
      "tz": "Europe\/Paris"
  },
  "LFIH": {
      "icao": "LFIH",
      "iata": "",
      "name": "Chalais Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 285,
      "lat": 45.2681007385,
      "lon": 0.0169440005,
      "tz": "Europe\/Paris"
  },
  "LFIK": {
      "icao": "LFIK",
      "iata": "",
      "name": "Riberac St Aulaye Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 351,
      "lat": 45.2402992249,
      "lon": 0.2669439912,
      "tz": "Europe\/Paris"
  },
  "LFIL": {
      "icao": "LFIL",
      "iata": "",
      "name": "Rion Des Landes Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 256,
      "lat": 43.9157981873,
      "lon": -0.9491670132,
      "tz": "Europe\/Paris"
  },
  "LFIM": {
      "icao": "LFIM",
      "iata": "",
      "name": "St Gaudens Montrejeau Airport",
      "city": "Moulins",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1325,
      "lat": 43.1086006165,
      "lon": 0.6202780008,
      "tz": "Europe\/Paris"
  },
  "LFIP": {
      "icao": "LFIP",
      "iata": "",
      "name": "Peyresourde Balestas Airport",
      "city": "Moulins",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 5190,
      "lat": 42.7969017029,
      "lon": 0.4355559945,
      "tz": "Europe\/Paris"
  },
  "LFIR": {
      "icao": "LFIR",
      "iata": "",
      "name": "Revel Montgey Airport",
      "city": "Moulins",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 643,
      "lat": 43.4813995361,
      "lon": 1.9800000191,
      "tz": "Europe\/Paris"
  },
  "LFIT": {
      "icao": "LFIT",
      "iata": "",
      "name": "Toulouse Bourg St Bernard Airport",
      "city": "Moulins",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 525,
      "lat": 43.6122016907,
      "lon": 1.7252800465,
      "tz": "Europe\/Paris"
  },
  "LFIV": {
      "icao": "LFIV",
      "iata": "",
      "name": "Vendays Montalivet Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 16,
      "lat": 45.3805999756,
      "lon": -1.1158299446,
      "tz": "Europe\/Paris"
  },
  "LFIX": {
      "icao": "LFIX",
      "iata": "",
      "name": "Itxassou Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 607,
      "lat": 43.3375015259,
      "lon": -1.4222199917,
      "tz": "Europe\/Paris"
  },
  "LFIY": {
      "icao": "LFIY",
      "iata": "",
      "name": "St Jean D'angely Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 246,
      "lat": 45.9664001465,
      "lon": -0.5252779722,
      "tz": "Europe\/Paris"
  },
  "LFJA": {
      "icao": "LFJA",
      "iata": "",
      "name": "Chaumont-Semoutiers Airport",
      "city": "Chaumont\/Semoutiers",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1001,
      "lat": 48.0862998962,
      "lon": 5.0490198135,
      "tz": "Europe\/Paris"
  },
  "LFJB": {
      "icao": "LFJB",
      "iata": "",
      "name": "Mauleon Airport",
      "city": "Mauleon",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 576,
      "lat": 46.9028015137,
      "lon": -0.6977779865,
      "tz": "Europe\/Paris"
  },
  "LFJC": {
      "icao": "LFJC",
      "iata": "",
      "name": "Clamecy Airport",
      "city": "Moulins",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 713,
      "lat": 47.4383010864,
      "lon": 3.5086100101,
      "tz": "Europe\/Paris"
  },
  "LFJD": {
      "icao": "LFJD",
      "iata": "",
      "name": "Corlier Airport",
      "city": "Moulins",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 2762,
      "lat": 46.0396995544,
      "lon": 5.496940136,
      "tz": "Europe\/Paris"
  },
  "LFJE": {
      "icao": "LFJE",
      "iata": "",
      "name": "La Motte Chalancon Airport",
      "city": "Moulins",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 2887,
      "lat": 44.4958000183,
      "lon": 5.4028501511,
      "tz": "Europe\/Paris"
  },
  "LFJF": {
      "icao": "LFJF",
      "iata": "",
      "name": "Aubenasson Airport",
      "city": "Moulins",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 810,
      "lat": 44.6944999695,
      "lon": 5.1511101723,
      "tz": "Europe\/Paris"
  },
  "LFJH": {
      "icao": "LFJH",
      "iata": "",
      "name": "Cazeres Palaminy Airport",
      "city": "Moulins",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 811,
      "lat": 43.2022018433,
      "lon": 1.0511100292,
      "tz": "Europe\/Paris"
  },
  "LFJI": {
      "icao": "LFJI",
      "iata": "",
      "name": "Marennes Le Bournet Airport",
      "city": "Moulins",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 16,
      "lat": 45.8250007629,
      "lon": -1.0766700506,
      "tz": "Europe\/Paris"
  },
  "LFJL": {
      "icao": "LFJL",
      "iata": "ETZ",
      "name": "Metz-Nancy-Lorraine Airport",
      "city": "Metz \/ Nancy",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 870,
      "lat": 48.9821014404,
      "lon": 6.2513198853,
      "tz": "Europe\/Paris"
  },
  "LFJM": {
      "icao": "LFJM",
      "iata": "",
      "name": "Chailley Airport",
      "city": "Metz-Nancy\/Lorraine",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 0,
      "lat": 48.0807151794,
      "lon": 3.7142372131,
      "tz": "Europe\/Paris"
  },
  "LFJR": {
      "icao": "LFJR",
      "iata": "ANE",
      "name": "Angers-Loire Airport",
      "city": "Angers\/Marce",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 194,
      "lat": 47.5602989197,
      "lon": -0.3122220039,
      "tz": "Europe\/Paris"
  },
  "LFJS": {
      "icao": "LFJS",
      "iata": "",
      "name": "Soissons - Courmelles Airport",
      "city": "Angers",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 509,
      "lat": 49.3457984924,
      "lon": 3.2841699123,
      "tz": "Europe\/Paris"
  },
  "LFJT": {
      "icao": "LFJT",
      "iata": "",
      "name": "Tours Le Louroux Airport",
      "city": "Angers",
      "state": "Centre",
      "country": "FR",
      "elevation": 413,
      "lat": 47.1500015259,
      "lon": 0.7127779722,
      "tz": "Europe\/Paris"
  },
  "LFJU": {
      "icao": "LFJU",
      "iata": "",
      "name": "Lurcy-Levis Airport",
      "city": "Lurcy-Levis",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 746,
      "lat": 46.7136001587,
      "lon": 2.9458999634,
      "tz": "Europe\/Paris"
  },
  "LFKA": {
      "icao": "LFKA",
      "iata": "XAV",
      "name": "Albertville Airport",
      "city": "Angers",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1033,
      "lat": 45.6272010803,
      "lon": 6.3297200203,
      "tz": "Europe\/Paris"
  },
  "LFKB": {
      "icao": "LFKB",
      "iata": "BIA",
      "name": "Bastia-Poretta Airport",
      "city": "Bastia\/Poretta",
      "state": "Corsica",
      "country": "FR",
      "elevation": 26,
      "lat": 42.5527000427,
      "lon": 9.4837303162,
      "tz": "Europe\/Paris"
  },
  "LFKC": {
      "icao": "LFKC",
      "iata": "CLY",
      "name": "Calvi-Sainte-Catherine Airport",
      "city": "Calvi\/Sainte-Catherine",
      "state": "Corsica",
      "country": "FR",
      "elevation": 209,
      "lat": 42.5307998657,
      "lon": 8.7931900024,
      "tz": "Europe\/Paris"
  },
  "LFKD": {
      "icao": "LFKD",
      "iata": "",
      "name": "Sollieres Sardieres Airport",
      "city": "Calvi",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 4255,
      "lat": 45.256401062,
      "lon": 6.8013901711,
      "tz": "Europe\/Paris"
  },
  "LFKE": {
      "icao": "LFKE",
      "iata": "",
      "name": "Saint-Jean-en-Royans Airport",
      "city": "Calvi",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 866,
      "lat": 45.0278015137,
      "lon": 5.3099999428,
      "tz": "Europe\/Paris"
  },
  "LFKF": {
      "icao": "LFKF",
      "iata": "FSC",
      "name": "Figari Sud-Corse Airport",
      "city": "Figari Sud-Corse",
      "state": "Corsica",
      "country": "FR",
      "elevation": 87,
      "lat": 41.5005989075,
      "lon": 9.0977802277,
      "tz": "Europe\/Paris"
  },
  "LFKG": {
      "icao": "LFKG",
      "iata": "",
      "name": "Ghisonaccia Alzitone Airport",
      "city": "Figari\/Sud Corse",
      "state": "Corsica",
      "country": "FR",
      "elevation": 177,
      "lat": 42.0550003052,
      "lon": 9.4019403458,
      "tz": "Europe\/Paris"
  },
  "LFKH": {
      "icao": "LFKH",
      "iata": "",
      "name": "St Jean D'avelanne Airport",
      "city": "Figari\/Sud Corse",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 968,
      "lat": 45.5167007446,
      "lon": 5.680560112,
      "tz": "Europe\/Paris"
  },
  "LFKJ": {
      "icao": "LFKJ",
      "iata": "AJA",
      "name": "Ajaccio-Napoleon Bonaparte Airport",
      "city": "Ajaccio\/Napoleon Bonaparte",
      "state": "Corsica",
      "country": "FR",
      "elevation": 18,
      "lat": 41.9235992432,
      "lon": 8.8029203415,
      "tz": "Europe\/Paris"
  },
  "LFKL": {
      "icao": "LFKL",
      "iata": "",
      "name": "Lyon Brindas Airport",
      "city": "Ajaccio",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1040,
      "lat": 45.7117004395,
      "lon": 4.6977801323,
      "tz": "Europe\/Paris"
  },
  "LFKM": {
      "icao": "LFKM",
      "iata": "",
      "name": "St Galmier Airport",
      "city": "Ajaccio",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1266,
      "lat": 45.6072006226,
      "lon": 4.3058300018,
      "tz": "Europe\/Paris"
  },
  "LFKO": {
      "icao": "LFKO",
      "iata": "PRP",
      "name": "Propriano Airport",
      "city": "Propriano",
      "state": "Corsica",
      "country": "FR",
      "elevation": 13,
      "lat": 41.6605987549,
      "lon": 8.889749527,
      "tz": "Europe\/Paris"
  },
  "LFKP": {
      "icao": "LFKP",
      "iata": "",
      "name": "La Tour Du Pin Airport",
      "city": "Ajaccio",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1050,
      "lat": 45.5600013733,
      "lon": 5.3847198486,
      "tz": "Europe\/Paris"
  },
  "LFKS": {
      "icao": "LFKS",
      "iata": "SOZ",
      "name": "Solenzara (BA 126) Air Base",
      "city": "Solenzara",
      "state": "Corsica",
      "country": "FR",
      "elevation": 28,
      "lat": 41.9244003296,
      "lon": 9.4060001373,
      "tz": "Europe\/Paris"
  },
  "LFKT": {
      "icao": "LFKT",
      "iata": "",
      "name": "Corte Airport",
      "city": "Corte",
      "state": "Corsica",
      "country": "FR",
      "elevation": 1132,
      "lat": 42.293598175,
      "lon": 9.1930599213,
      "tz": "Europe\/Paris"
  },
  "LFKX": {
      "icao": "LFKX",
      "iata": "MFX",
      "name": "Meribel Airport",
      "city": "Ajaccio",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 5636,
      "lat": 45.4068984985,
      "lon": 6.5805602074,
      "tz": "Europe\/Paris"
  },
  "LFKY": {
      "icao": "LFKY",
      "iata": "",
      "name": "Belley - Peyrieu Airport",
      "city": "Ajaccio",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 738,
      "lat": 45.6949996948,
      "lon": 5.6927800179,
      "tz": "Europe\/Paris"
  },
  "LFKZ": {
      "icao": "LFKZ",
      "iata": "",
      "name": "St Claude Pratz Airport",
      "city": "Ajaccio",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 2051,
      "lat": 46.3889007568,
      "lon": 5.7722201347,
      "tz": "Europe\/Paris"
  },
  "LFLA": {
      "icao": "LFLA",
      "iata": "AUF",
      "name": "Auxerre-Branches Airport",
      "city": "Auxerre\/Branches",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 523,
      "lat": 47.8502006531,
      "lon": 3.49710989,
      "tz": "Europe\/Paris"
  },
  "LFLB": {
      "icao": "LFLB",
      "iata": "CMF",
      "name": "Chambery-Savoie Airport",
      "city": "Chambery\/Aix-les-Bains",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 779,
      "lat": 45.6380996704,
      "lon": 5.88022995,
      "tz": "Europe\/Paris"
  },
  "LFLC": {
      "icao": "LFLC",
      "iata": "CFE",
      "name": "Clermont-Ferrand Auvergne Airport",
      "city": "Clermont-Ferrand\/Auvergne",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1090,
      "lat": 45.7867012024,
      "lon": 3.1691699028,
      "tz": "Europe\/Paris"
  },
  "LFLD": {
      "icao": "LFLD",
      "iata": "BOU",
      "name": "Bourges Airport",
      "city": "Bourges",
      "state": "Centre",
      "country": "FR",
      "elevation": 529,
      "lat": 47.0581016541,
      "lon": 2.3702800274,
      "tz": "Europe\/Paris"
  },
  "LFLE": {
      "icao": "LFLE",
      "iata": "",
      "name": "Chambery-Challes-les-Eaux Airport",
      "city": "Chambery\/Challes-les-Eaux",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 973,
      "lat": 45.5611000061,
      "lon": 5.9757599831,
      "tz": "Europe\/Paris"
  },
  "LFLG": {
      "icao": "LFLG",
      "iata": "",
      "name": "Grenoble Le Versoud Airport",
      "city": "Bourges",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 722,
      "lat": 45.2192001343,
      "lon": 5.8497200012,
      "tz": "Europe\/Paris"
  },
  "LFLH": {
      "icao": "LFLH",
      "iata": "",
      "name": "Chalon-Champforgeuil Airport",
      "city": "Chalon\/Champforgueil",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 623,
      "lat": 46.8260993958,
      "lon": 4.8176298141,
      "tz": "Europe\/Paris"
  },
  "LFLI": {
      "icao": "LFLI",
      "iata": "QNJ",
      "name": "Annemasse Airport",
      "city": "Annemasse",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1620,
      "lat": 46.1920013428,
      "lon": 6.2683901787,
      "tz": "Europe\/Paris"
  },
  "LFLJ": {
      "icao": "LFLJ",
      "iata": "CVF",
      "name": "Courchevel Airport",
      "city": "Courcheval",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 6588,
      "lat": 45.3967018127,
      "lon": 6.6347198486,
      "tz": "Europe\/Paris"
  },
  "LFLK": {
      "icao": "LFLK",
      "iata": "",
      "name": "Oyonnax-Arbent Airport",
      "city": "Chalon",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1755,
      "lat": 46.2792015076,
      "lon": 5.6675000191,
      "tz": "Europe\/Paris"
  },
  "LFLL": {
      "icao": "LFLL",
      "iata": "LYS",
      "name": "Lyon Saint-Exupery Airport",
      "city": "Lyon",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 821,
      "lat": 45.726398468,
      "lon": 5.0908298492,
      "tz": "Europe\/Paris"
  },
  "LFLM": {
      "icao": "LFLM",
      "iata": "QNX",
      "name": "Macon-Charnay Airport",
      "city": "Macon\/Charnay",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 728,
      "lat": 46.2951011658,
      "lon": 4.7957701683,
      "tz": "Europe\/Paris"
  },
  "LFLN": {
      "icao": "LFLN",
      "iata": "SYT",
      "name": "Saint-Yan Airport",
      "city": "Saint-Yan",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 796,
      "lat": 46.4124984741,
      "lon": 4.0132598877,
      "tz": "Europe\/Paris"
  },
  "LFLO": {
      "icao": "LFLO",
      "iata": "RNE",
      "name": "Roanne-Renaison Airport",
      "city": "Roanne\/Renaison",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1106,
      "lat": 46.0583000183,
      "lon": 4.0013899803,
      "tz": "Europe\/Paris"
  },
  "LFLP": {
      "icao": "LFLP",
      "iata": "NCY",
      "name": "Annecy-Haute-Savoie-Mont Blanc Airport",
      "city": "Annecy\/Meythet",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1521,
      "lat": 45.9291992188,
      "lon": 6.098760128,
      "tz": "Europe\/Paris"
  },
  "LFLQ": {
      "icao": "LFLQ",
      "iata": "XMK",
      "name": "Montelimar - Ancone Airport",
      "city": "Annecy",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 240,
      "lat": 44.5835990906,
      "lon": 4.7405600548,
      "tz": "Europe\/Paris"
  },
  "LFLR": {
      "icao": "LFLR",
      "iata": "",
      "name": "Saint-Rambert-d'Albon Airport",
      "city": "Annecy",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 509,
      "lat": 45.2560997009,
      "lon": 4.8258299828,
      "tz": "Europe\/Paris"
  },
  "LFLS": {
      "icao": "LFLS",
      "iata": "GNB",
      "name": "Grenoble-Isere Airport",
      "city": "Grenoble\/Saint-Geoirs",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1302,
      "lat": 45.3628997803,
      "lon": 5.3293700218,
      "tz": "Europe\/Paris"
  },
  "LFLT": {
      "icao": "LFLT",
      "iata": "",
      "name": "Montlucon-Domerat Airport",
      "city": "Montlucon\/Domerat",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 771,
      "lat": 46.3525009155,
      "lon": 2.5704898834,
      "tz": "Europe\/Paris"
  },
  "LFLU": {
      "icao": "LFLU",
      "iata": "VAF",
      "name": "Valence-Chabeuil Airport",
      "city": "Valence\/Chabeuil",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 525,
      "lat": 44.9216003418,
      "lon": 4.9699001312,
      "tz": "Europe\/Paris"
  },
  "LFLV": {
      "icao": "LFLV",
      "iata": "VHY",
      "name": "Vichy-Charmeil Airport",
      "city": "Vichy\/Charmeil",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 817,
      "lat": 46.1697006226,
      "lon": 3.4037399292,
      "tz": "Europe\/Paris"
  },
  "LFLW": {
      "icao": "LFLW",
      "iata": "AUR",
      "name": "Aurillac Airport",
      "city": "Aurillac",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 2096,
      "lat": 44.8913993835,
      "lon": 2.4219400883,
      "tz": "Europe\/Paris"
  },
  "LFLX": {
      "icao": "LFLX",
      "iata": "CHR",
      "name": "Chateauroux-Deols Marcel Dassault Airport",
      "city": "Chateauroux\/Deols",
      "state": "Centre",
      "country": "FR",
      "elevation": 529,
      "lat": 46.8622016907,
      "lon": 1.7306699753,
      "tz": "Europe\/Paris"
  },
  "LFLY": {
      "icao": "LFLY",
      "iata": "LYN",
      "name": "Lyon-Bron Airport",
      "city": "Lyon\/Bron",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 659,
      "lat": 45.7271995544,
      "lon": 4.944270134,
      "tz": "Europe\/Paris"
  },
  "LFLZ": {
      "icao": "LFLZ",
      "iata": "",
      "name": "Feurs Chambeon Airport",
      "city": "Lyon",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1096,
      "lat": 45.7036018372,
      "lon": 4.2011098862,
      "tz": "Europe\/Paris"
  },
  "LFMA": {
      "icao": "LFMA",
      "iata": "QXB",
      "name": "Aix-en-Provence (BA 114) Airport",
      "city": "Lyon",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 367,
      "lat": 43.5055999756,
      "lon": 5.3677802086,
      "tz": "Europe\/Paris"
  },
  "LFMC": {
      "icao": "LFMC",
      "iata": "",
      "name": "Le Luc-Le Cannet Airport",
      "city": "Le Luc\/Le Cannet",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 265,
      "lat": 43.3847007751,
      "lon": 6.3871397972,
      "tz": "Europe\/Paris"
  },
  "LFMD": {
      "icao": "LFMD",
      "iata": "CEQ",
      "name": "Cannes-Mandelieu Airport",
      "city": "Cannes\/Mandelieu",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 13,
      "lat": 43.5419998169,
      "lon": 6.9534797668,
      "tz": "Europe\/Paris"
  },
  "LFME": {
      "icao": "LFME",
      "iata": "",
      "name": "Nimes Courbessac Airport",
      "city": "Cannes",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 197,
      "lat": 43.8539009094,
      "lon": 4.4136099815,
      "tz": "Europe\/Paris"
  },
  "LFMF": {
      "icao": "LFMF",
      "iata": "",
      "name": "Fayence Airport",
      "city": "Cannes",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 741,
      "lat": 43.6088981628,
      "lon": 6.7027797699,
      "tz": "Europe\/Paris"
  },
  "LFMG": {
      "icao": "LFMG",
      "iata": "",
      "name": "Montagne Noire Airport",
      "city": "Cannes",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1470,
      "lat": 43.4075012207,
      "lon": 1.9902800322,
      "tz": "Europe\/Paris"
  },
  "LFMH": {
      "icao": "LFMH",
      "iata": "EBU",
      "name": "Saint-Etienne-Boutheon Airport",
      "city": "Saint-Etienne\/Boutheon",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 1325,
      "lat": 45.540599823,
      "lon": 4.2963900566,
      "tz": "Europe\/Paris"
  },
  "LFMI": {
      "icao": "LFMI",
      "iata": "QIE",
      "name": "Istres Le Tube\/Istres Air Base (BA 125) Airport",
      "city": "Istres\/Le Tube",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 82,
      "lat": 43.5227012634,
      "lon": 4.9238400459,
      "tz": "Europe\/Paris"
  },
  "LFMK": {
      "icao": "LFMK",
      "iata": "CCF",
      "name": "Carcassonne Airport",
      "city": "Carcassonne\/Salvaza",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 433,
      "lat": 43.2159996033,
      "lon": 2.306319952,
      "tz": "Europe\/Paris"
  },
  "LFML": {
      "icao": "LFML",
      "iata": "MRS",
      "name": "Marseille Provence Airport",
      "city": "Marseille",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 74,
      "lat": 43.439271922,
      "lon": 5.2214241028,
      "tz": "Europe\/Paris"
  },
  "LFMN": {
      "icao": "LFMN",
      "iata": "NCE",
      "name": "Nice-Cote d'Azur Airport",
      "city": "Nice",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 12,
      "lat": 43.6584014893,
      "lon": 7.2158699036,
      "tz": "Europe\/Paris"
  },
  "LFMO": {
      "icao": "LFMO",
      "iata": "XOG",
      "name": "Orange-Caritat (BA 115) Air Base",
      "city": "Orange\/Caritat",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 197,
      "lat": 44.140499115,
      "lon": 4.8667201996,
      "tz": "Europe\/Paris"
  },
  "LFMP": {
      "icao": "LFMP",
      "iata": "PGF",
      "name": "Perpignan-Rivesaltes (Llabanere) Airport",
      "city": "Perpignan\/Rivesaltes",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 144,
      "lat": 42.740398407,
      "lon": 2.8706700802,
      "tz": "Europe\/Paris"
  },
  "LFMQ": {
      "icao": "LFMQ",
      "iata": "CTT",
      "name": "Le Castellet Airport",
      "city": "Le Castellet",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 1391,
      "lat": 43.2524986267,
      "lon": 5.7851901054,
      "tz": "Europe\/Paris"
  },
  "LFMR": {
      "icao": "LFMR",
      "iata": "BAE",
      "name": "Barcelonnette - Saint-Pons Airport",
      "city": "Le Castellet",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 3714,
      "lat": 44.3883018494,
      "lon": 6.6102800369,
      "tz": "Europe\/Paris"
  },
  "LFMS": {
      "icao": "LFMS",
      "iata": "XAS",
      "name": "Ales-Deaux Airport",
      "city": "Ales\/Deaux",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 668,
      "lat": 44.0696983337,
      "lon": 4.1421198845,
      "tz": "Europe\/Paris"
  },
  "LFMT": {
      "icao": "LFMT",
      "iata": "MPL",
      "name": "Montpellier-Mediterranee Airport",
      "city": "Montpellier\/Mediterranee",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 17,
      "lat": 43.5761985779,
      "lon": 3.9630100727,
      "tz": "Europe\/Paris"
  },
  "LFMU": {
      "icao": "LFMU",
      "iata": "BZR",
      "name": "Beziers-Vias Airport",
      "city": "Beziers\/Vias",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 56,
      "lat": 43.3235015869,
      "lon": 3.3538999557,
      "tz": "Europe\/Paris"
  },
  "LFMV": {
      "icao": "LFMV",
      "iata": "AVN",
      "name": "Avignon-Caumont Airport",
      "city": "Avignon\/Caumont",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 124,
      "lat": 43.9072990417,
      "lon": 4.9018301964,
      "tz": "Europe\/Paris"
  },
  "LFMW": {
      "icao": "LFMW",
      "iata": "",
      "name": "Castelnaudary Villeneuve Airport",
      "city": "Avignon",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 551,
      "lat": 43.3121986389,
      "lon": 1.921110034,
      "tz": "Europe\/Paris"
  },
  "LFMX": {
      "icao": "LFMX",
      "iata": "",
      "name": "Chateau-Arnoux-Saint-Auban Airport",
      "city": "Avignon",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 1509,
      "lat": 44.0600013733,
      "lon": 5.9913902283,
      "tz": "Europe\/Paris"
  },
  "LFMY": {
      "icao": "LFMY",
      "iata": "",
      "name": "Salon-de-Provence (BA 701) Air Base",
      "city": "Salon",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 195,
      "lat": 43.6063995361,
      "lon": 5.1092500687,
      "tz": "Europe\/Paris"
  },
  "LFMZ": {
      "icao": "LFMZ",
      "iata": "",
      "name": "Lezignan-Corbieres Airport",
      "city": "Lezignan-Corbieres",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 207,
      "lat": 43.1758003235,
      "lon": 2.73416996,
      "tz": "Europe\/Paris"
  },
  "LFNA": {
      "icao": "LFNA",
      "iata": "GAT",
      "name": "Gap - Tallard Airport",
      "city": "Avignon",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 1986,
      "lat": 44.4550018311,
      "lon": 6.037779808,
      "tz": "Europe\/Paris"
  },
  "LFNB": {
      "icao": "LFNB",
      "iata": "MEN",
      "name": "Mende-Brenoux Airport",
      "city": "Mende\/Brenoux",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 3362,
      "lat": 44.5021018982,
      "lon": 3.5328199863,
      "tz": "Europe\/Paris"
  },
  "LFNC": {
      "icao": "LFNC",
      "iata": "SCP",
      "name": "Mont-Dauphin - St-Crepin Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 2963,
      "lat": 44.7016983032,
      "lon": 6.600279808,
      "tz": "Europe\/Paris"
  },
  "LFND": {
      "icao": "LFND",
      "iata": "",
      "name": "Pont St Esprit Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 144,
      "lat": 44.2694015503,
      "lon": 4.6533298492,
      "tz": "Europe\/Paris"
  },
  "LFNE": {
      "icao": "LFNE",
      "iata": "",
      "name": "Salon Eyguieres Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 246,
      "lat": 43.6582984924,
      "lon": 5.0136098862,
      "tz": "Europe\/Paris"
  },
  "LFNF": {
      "icao": "LFNF",
      "iata": "",
      "name": "Vinon Sur Verdon Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 902,
      "lat": 43.7378005981,
      "lon": 5.7841701508,
      "tz": "Europe\/Paris"
  },
  "LFNG": {
      "icao": "LFNG",
      "iata": "",
      "name": "Montpellier Candillargues Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 5,
      "lat": 43.6102981567,
      "lon": 4.0702800751,
      "tz": "Europe\/Paris"
  },
  "LFNH": {
      "icao": "LFNH",
      "iata": "",
      "name": "Carpentras Airport",
      "city": "Carpentras",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 394,
      "lat": 44.029800415,
      "lon": 5.0780601501,
      "tz": "Europe\/Paris"
  },
  "LFNJ": {
      "icao": "LFNJ",
      "iata": "",
      "name": "Aspres-sur-Buech Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 2726,
      "lat": 44.518901825,
      "lon": 5.7375001907,
      "tz": "Europe\/Paris"
  },
  "LFNL": {
      "icao": "LFNL",
      "iata": "",
      "name": "St Martin De Londres Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 597,
      "lat": 43.8008003235,
      "lon": 3.7825000286,
      "tz": "Europe\/Paris"
  },
  "LFNO": {
      "icao": "LFNO",
      "iata": "",
      "name": "Florac Ste Enimie Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 3054,
      "lat": 44.2863998413,
      "lon": 3.4666700363,
      "tz": "Europe\/Paris"
  },
  "LFNP": {
      "icao": "LFNP",
      "iata": "",
      "name": "Pezenas Nizas Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 322,
      "lat": 43.5057983398,
      "lon": 3.4136099815,
      "tz": "Europe\/Paris"
  },
  "LFNQ": {
      "icao": "LFNQ",
      "iata": "",
      "name": "Mont Louis La Quillane Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 5610,
      "lat": 42.543598175,
      "lon": 2.1199998856,
      "tz": "Europe\/Paris"
  },
  "LFNR": {
      "icao": "LFNR",
      "iata": "",
      "name": "Berre La Fare Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 108,
      "lat": 43.5377998352,
      "lon": 5.1783299446,
      "tz": "Europe\/Paris"
  },
  "LFNS": {
      "icao": "LFNS",
      "iata": "",
      "name": "Sisteron - Theze Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 1772,
      "lat": 44.2874984741,
      "lon": 5.9302802086,
      "tz": "Europe\/Paris"
  },
  "LFNT": {
      "icao": "LFNT",
      "iata": "",
      "name": "Avignon Pujaut Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 148,
      "lat": 43.9968986511,
      "lon": 4.7555599213,
      "tz": "Europe\/Paris"
  },
  "LFNU": {
      "icao": "LFNU",
      "iata": "",
      "name": "Uzes Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 886,
      "lat": 44.0847015381,
      "lon": 4.3952798843,
      "tz": "Europe\/Paris"
  },
  "LFNV": {
      "icao": "LFNV",
      "iata": "",
      "name": "Valreas Visan Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 472,
      "lat": 44.3368988037,
      "lon": 4.9080600739,
      "tz": "Europe\/Paris"
  },
  "LFNW": {
      "icao": "LFNW",
      "iata": "",
      "name": "Puivert Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1608,
      "lat": 42.9113998413,
      "lon": 2.0561099052,
      "tz": "Europe\/Paris"
  },
  "LFNX": {
      "icao": "LFNX",
      "iata": "",
      "name": "Bedarieux La Tour Airport",
      "city": "Mende",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1237,
      "lat": 43.6408004761,
      "lon": 3.1455600262,
      "tz": "Europe\/Paris"
  },
  "LFNY": {
      "icao": "LFNY",
      "iata": "",
      "name": "St Etienne En Devoluy Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 6201,
      "lat": 44.6688995361,
      "lon": 5.9061098099,
      "tz": "Europe\/Paris"
  },
  "LFNZ": {
      "icao": "LFNZ",
      "iata": "",
      "name": "Le Mazet De Romanin Airport",
      "city": "Mende",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 387,
      "lat": 43.768901825,
      "lon": 4.8936100006,
      "tz": "Europe\/Paris"
  },
  "LFOA": {
      "icao": "LFOA",
      "iata": "",
      "name": "Avord (BA 702) Air Base",
      "city": "Avord",
      "state": "Centre",
      "country": "FR",
      "elevation": 580,
      "lat": 47.0532989502,
      "lon": 2.6324999332,
      "tz": "Europe\/Paris"
  },
  "LFOB": {
      "icao": "LFOB",
      "iata": "BVA",
      "name": "Paris Beauvais Tille Airport",
      "city": "Beauvais\/Tille",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 359,
      "lat": 49.4543991089,
      "lon": 2.1127800941,
      "tz": "Europe\/Paris"
  },
  "LFOC": {
      "icao": "LFOC",
      "iata": "",
      "name": "Chateaudun (BA 279) Airport",
      "city": "Chateaudun",
      "state": "Centre",
      "country": "FR",
      "elevation": 433,
      "lat": 48.0581016541,
      "lon": 1.3766200542,
      "tz": "Europe\/Paris"
  },
  "LFOD": {
      "icao": "LFOD",
      "iata": "XSU",
      "name": "Saumur-Saint-Florent Airport",
      "city": "Saumur\/Saint-Florent",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 269,
      "lat": 47.2568016052,
      "lon": -0.1151420027,
      "tz": "Europe\/Paris"
  },
  "LFOE": {
      "icao": "LFOE",
      "iata": "EVX",
      "name": "Evreux-Fauville (BA 105) Air Base",
      "city": "Evreux\/Fauville",
      "state": "Normandy",
      "country": "FR",
      "elevation": 464,
      "lat": 49.0287017822,
      "lon": 1.2198599577,
      "tz": "Europe\/Paris"
  },
  "LFOF": {
      "icao": "LFOF",
      "iata": "XAN",
      "name": "Alencon Valframbert Airport",
      "city": "Evreux",
      "state": "Normandy",
      "country": "FR",
      "elevation": 479,
      "lat": 48.4474983215,
      "lon": 0.1091670021,
      "tz": "Europe\/Paris"
  },
  "LFOG": {
      "icao": "LFOG",
      "iata": "",
      "name": "Flers Saint Paul Airport",
      "city": "Evreux",
      "state": "Normandy",
      "country": "FR",
      "elevation": 656,
      "lat": 48.7524986267,
      "lon": -0.5894439816,
      "tz": "Europe\/Paris"
  },
  "LFOH": {
      "icao": "LFOH",
      "iata": "LEH",
      "name": "Le Havre Octeville Airport",
      "city": "Le Havre\/Octeville",
      "state": "Normandy",
      "country": "FR",
      "elevation": 313,
      "lat": 49.5339012146,
      "lon": 0.0880559981,
      "tz": "Europe\/Paris"
  },
  "LFOI": {
      "icao": "LFOI",
      "iata": "XAB",
      "name": "Abbeville-Buigny-Saint-Maclou Airport",
      "city": "Abbeville (Buigny\/Saint-Maclou)",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 220,
      "lat": 50.1435012817,
      "lon": 1.831889987,
      "tz": "Europe\/Paris"
  },
  "LFOJ": {
      "icao": "LFOJ",
      "iata": "ORE",
      "name": "Orleans-Bricy (BA 123) Air Base",
      "city": "Orleans\/Bricy",
      "state": "Centre",
      "country": "FR",
      "elevation": 412,
      "lat": 47.9878005981,
      "lon": 1.7605600357,
      "tz": "Europe\/Paris"
  },
  "LFOK": {
      "icao": "LFOK",
      "iata": "XCR",
      "name": "Chalons-Vatry Air Base",
      "city": "Chalons\/Vatry",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 587,
      "lat": 48.7761001587,
      "lon": 4.1844902039,
      "tz": "Europe\/Paris"
  },
  "LFOL": {
      "icao": "LFOL",
      "iata": "",
      "name": "L'aigle St Michel Airport",
      "city": "Chalons",
      "state": "Normandy",
      "country": "FR",
      "elevation": 787,
      "lat": 48.7597007751,
      "lon": 0.6591669917,
      "tz": "Europe\/Paris"
  },
  "LFOM": {
      "icao": "LFOM",
      "iata": "",
      "name": "Lessay Airport",
      "city": "Chalons",
      "state": "Normandy",
      "country": "FR",
      "elevation": 92,
      "lat": 49.2030982971,
      "lon": -1.5066699982,
      "tz": "Europe\/Paris"
  },
  "LFON": {
      "icao": "LFON",
      "iata": "",
      "name": "Dreux Vernouillet Airport",
      "city": "Chalons",
      "state": "Centre",
      "country": "FR",
      "elevation": 443,
      "lat": 48.7066993713,
      "lon": 1.3627799749,
      "tz": "Europe\/Paris"
  },
  "LFOO": {
      "icao": "LFOO",
      "iata": "LSO",
      "name": "Les Sables-d'Olonne Talmont Airport",
      "city": "Les Sables-d'Olonne",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 105,
      "lat": 46.4768981934,
      "lon": -1.7227799892,
      "tz": "Europe\/Paris"
  },
  "LFOP": {
      "icao": "LFOP",
      "iata": "URO",
      "name": "Rouen Airport",
      "city": "Rouen\/Vallee de Seine",
      "state": "Normandy",
      "country": "FR",
      "elevation": 512,
      "lat": 49.3842010498,
      "lon": 1.1748000383,
      "tz": "Europe\/Paris"
  },
  "LFOQ": {
      "icao": "LFOQ",
      "iata": "XBQ",
      "name": "Blois-Le Breuil Airport",
      "city": "Blois\/Le Breuil",
      "state": "Centre",
      "country": "FR",
      "elevation": 398,
      "lat": 47.6785011292,
      "lon": 1.2088400126,
      "tz": "Europe\/Paris"
  },
  "LFOR": {
      "icao": "LFOR",
      "iata": "QTJ",
      "name": "Chartres Champhol Airport",
      "city": "Blois",
      "state": "Centre",
      "country": "FR",
      "elevation": 509,
      "lat": 48.4589004517,
      "lon": 1.5238900185,
      "tz": "Europe\/Paris"
  },
  "LFOS": {
      "icao": "LFOS",
      "iata": "",
      "name": "St Valery Vittefleur Airport",
      "city": "Blois",
      "state": "Normandy",
      "country": "FR",
      "elevation": 272,
      "lat": 49.836101532,
      "lon": 0.6549999714,
      "tz": "Europe\/Paris"
  },
  "LFOT": {
      "icao": "LFOT",
      "iata": "TUF",
      "name": "Tours-Val-de-Loire Airport",
      "city": "Tours\/Val de Loire (Loire Valley)",
      "state": "Centre",
      "country": "FR",
      "elevation": 357,
      "lat": 47.4322013855,
      "lon": 0.7276059985,
      "tz": "Europe\/Paris"
  },
  "LFOU": {
      "icao": "LFOU",
      "iata": "CET",
      "name": "Cholet Le Pontreau Airport",
      "city": "Cholet\/Le Pontreau",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 443,
      "lat": 47.0820999146,
      "lon": -0.8770639896,
      "tz": "Europe\/Paris"
  },
  "LFOV": {
      "icao": "LFOV",
      "iata": "LVA",
      "name": "Laval-Entrammes Airport",
      "city": "Laval\/Entrammes",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 330,
      "lat": 48.0313987732,
      "lon": -0.7429860234,
      "tz": "Europe\/Paris"
  },
  "LFOW": {
      "icao": "LFOW",
      "iata": "",
      "name": "Saint-Quentin - Roupy Airport",
      "city": "Laval",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 325,
      "lat": 49.8168983459,
      "lon": 3.2066700459,
      "tz": "Europe\/Paris"
  },
  "LFOX": {
      "icao": "LFOX",
      "iata": "",
      "name": "Etampes Mondesir Airport",
      "city": "Laval",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 489,
      "lat": 48.3819007874,
      "lon": 2.0752799511,
      "tz": "Europe\/Paris"
  },
  "LFOY": {
      "icao": "LFOY",
      "iata": "",
      "name": "Le Havre St Romain Airport",
      "city": "Laval",
      "state": "Normandy",
      "country": "FR",
      "elevation": 423,
      "lat": 49.5447006226,
      "lon": 0.3611109853,
      "tz": "Europe\/Paris"
  },
  "LFOZ": {
      "icao": "LFOZ",
      "iata": "ORE",
      "name": "Orleans-Saint-Denis-de-l'Hotel Airport",
      "city": "Orleans\/Saint-Denis-de-l'Hotel",
      "state": "Centre",
      "country": "FR",
      "elevation": 396,
      "lat": 47.896900177,
      "lon": 2.1633300781,
      "tz": "Europe\/Paris"
  },
  "LFPA": {
      "icao": "LFPA",
      "iata": "",
      "name": "Persan Beaumont Airport",
      "city": "Orleans",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 148,
      "lat": 49.1657981873,
      "lon": 2.3130600452,
      "tz": "Europe\/Paris"
  },
  "LFPB": {
      "icao": "LFPB",
      "iata": "LBG",
      "name": "Paris-Le Bourget Airport",
      "city": "Paris",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 218,
      "lat": 48.9693984985,
      "lon": 2.4413900375,
      "tz": "Europe\/Paris"
  },
  "LFPC": {
      "icao": "LFPC",
      "iata": "CSF",
      "name": "Creil Air Base",
      "city": "Creil",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 291,
      "lat": 49.2535018921,
      "lon": 2.5191400051,
      "tz": "Europe\/Paris"
  },
  "LFPD": {
      "icao": "LFPD",
      "iata": "",
      "name": "Bernay St Martin Airport",
      "city": "Creil",
      "state": "Normandy",
      "country": "FR",
      "elevation": 554,
      "lat": 49.1027984619,
      "lon": 0.5666670203,
      "tz": "Europe\/Paris"
  },
  "LFPE": {
      "icao": "LFPE",
      "iata": "",
      "name": "Meaux Esbly Airport",
      "city": "Creil",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 217,
      "lat": 48.9277992249,
      "lon": 2.8352799416,
      "tz": "Europe\/Paris"
  },
  "LFPF": {
      "icao": "LFPF",
      "iata": "",
      "name": "Beynes Thiverval Airport",
      "city": "Creil",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 371,
      "lat": 48.8436012268,
      "lon": 1.9088900089,
      "tz": "Europe\/Paris"
  },
  "LFPG": {
      "icao": "LFPG",
      "iata": "CDG",
      "name": "Charles de Gaulle International Airport",
      "city": "Paris",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 392,
      "lat": 49.0127983093,
      "lon": 2.5499999523,
      "tz": "Europe\/Paris"
  },
  "LFPH": {
      "icao": "LFPH",
      "iata": "",
      "name": "Chelles le Pin Airport",
      "city": "Paris",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 207,
      "lat": 48.8978004456,
      "lon": 2.6075000763,
      "tz": "Europe\/Paris"
  },
  "LFPK": {
      "icao": "LFPK",
      "iata": "",
      "name": "Coulommiers-Voisins Airport",
      "city": "Coulommiers\/Voisins",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 470,
      "lat": 48.8376998901,
      "lon": 3.016119957,
      "tz": "Europe\/Paris"
  },
  "LFPL": {
      "icao": "LFPL",
      "iata": "",
      "name": "Lognes Emerainville Airport",
      "city": "Paris",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 354,
      "lat": 48.8231010437,
      "lon": 2.6238899231,
      "tz": "Europe\/Paris"
  },
  "LFPM": {
      "icao": "LFPM",
      "iata": "",
      "name": "Melun-Villaroche Air Base",
      "city": "Melun\/Villaroche",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 302,
      "lat": 48.6046981812,
      "lon": 2.6711199284,
      "tz": "Europe\/Paris"
  },
  "LFPN": {
      "icao": "LFPN",
      "iata": "TNF",
      "name": "Toussus-le-Noble Airport",
      "city": "Toussus-le-Noble",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 538,
      "lat": 48.7518997192,
      "lon": 2.1061899662,
      "tz": "Europe\/Paris"
  },
  "LFPO": {
      "icao": "LFPO",
      "iata": "ORY",
      "name": "Paris-Orly Airport",
      "city": "Paris",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 291,
      "lat": 48.7252998352,
      "lon": 2.3594400883,
      "tz": "Europe\/Paris"
  },
  "LFPP": {
      "icao": "LFPP",
      "iata": "",
      "name": "Le Plessis Belleville Airport",
      "city": "Paris",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 387,
      "lat": 49.1100006104,
      "lon": 2.7380599976,
      "tz": "Europe\/Paris"
  },
  "LFPQ": {
      "icao": "LFPQ",
      "iata": "",
      "name": "Fontenay Tresigny Airport",
      "city": "Paris",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 371,
      "lat": 48.7071990967,
      "lon": 2.9044399261,
      "tz": "Europe\/Paris"
  },
  "LFPT": {
      "icao": "LFPT",
      "iata": "POX",
      "name": "Pontoise - Cormeilles-en-Vexin Airport",
      "city": "Pontoise",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 325,
      "lat": 49.0965995789,
      "lon": 2.0408298969,
      "tz": "Europe\/Paris"
  },
  "LFPU": {
      "icao": "LFPU",
      "iata": "",
      "name": "Moret Episy Airport",
      "city": "Pontoise",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 253,
      "lat": 48.3418998718,
      "lon": 2.7994399071,
      "tz": "Europe\/Paris"
  },
  "LFPV": {
      "icao": "LFPV",
      "iata": "VIY",
      "name": "Villacoublay-Velizy (BA 107) Air Base",
      "city": "Villacoublay\/Velizy",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 584,
      "lat": 48.7743988037,
      "lon": 2.2015399933,
      "tz": "Europe\/Paris"
  },
  "LFPX": {
      "icao": "LFPX",
      "iata": "",
      "name": "Chavenay Villepreux Airport",
      "city": "Villacoublay",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 426,
      "lat": 48.8436012268,
      "lon": 1.9822200537,
      "tz": "Europe\/Paris"
  },
  "LFPY": {
      "icao": "LFPY",
      "iata": "",
      "name": "Bretigny sur Orge Air Base",
      "city": "Villacoublay",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 270,
      "lat": 48.5960998535,
      "lon": 2.3322200775,
      "tz": "Europe\/Paris"
  },
  "LFPZ": {
      "icao": "LFPZ",
      "iata": "",
      "name": "Saint-Cyr-l'Ecole Airport",
      "city": "Villacoublay",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 371,
      "lat": 48.8114013672,
      "lon": 2.0747199059,
      "tz": "Europe\/Paris"
  },
  "LFQA": {
      "icao": "LFQA",
      "iata": "",
      "name": "Reims-Prunay Airport",
      "city": "Reims\/Prunay",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 313,
      "lat": 49.2086982727,
      "lon": 4.1565799713,
      "tz": "Europe\/Paris"
  },
  "LFQB": {
      "icao": "LFQB",
      "iata": "QYR",
      "name": "Troyes-Barberey Airport",
      "city": "Troyes\/Barberey",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 388,
      "lat": 48.322101593,
      "lon": 4.016699791,
      "tz": "Europe\/Paris"
  },
  "LFQC": {
      "icao": "LFQC",
      "iata": "",
      "name": "Luneville-Croismare Airport",
      "city": "Luneville\/Croismare",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 790,
      "lat": 48.5932998657,
      "lon": 6.5434598923,
      "tz": "Europe\/Paris"
  },
  "LFQD": {
      "icao": "LFQD",
      "iata": "",
      "name": "Arras Roclincourt Airport",
      "city": "Troyes",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 338,
      "lat": 50.3238983154,
      "lon": 2.8027799129,
      "tz": "Europe\/Paris"
  },
  "LFQE": {
      "icao": "LFQE",
      "iata": "",
      "name": "Etain-Rouvres Air Base",
      "city": "Etain\/Rouvres",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 770,
      "lat": 49.2268981934,
      "lon": 5.6722202301,
      "tz": "Europe\/Paris"
  },
  "LFQF": {
      "icao": "LFQF",
      "iata": "",
      "name": "Autun-Bellevue Airport",
      "city": "Autun\/Bellevue",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 997,
      "lat": 46.967300415,
      "lon": 4.2605700493,
      "tz": "Europe\/Paris"
  },
  "LFQG": {
      "icao": "LFQG",
      "iata": "NVS",
      "name": "Nevers-Fourchambault Airport",
      "city": "Nevers\/Fourchambault",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 602,
      "lat": 47.0026016235,
      "lon": 3.1133298874,
      "tz": "Europe\/Paris"
  },
  "LFQH": {
      "icao": "LFQH",
      "iata": "",
      "name": "Chatillon Sur Seine Airport",
      "city": "Nevers",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 905,
      "lat": 47.8464012146,
      "lon": 4.5805602074,
      "tz": "Europe\/Paris"
  },
  "LFQI": {
      "icao": "LFQI",
      "iata": "XCB",
      "name": "Cambrai-Epinoy (BA 103) Air Base",
      "city": "Cambrai\/Epinoy",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 257,
      "lat": 50.2218017578,
      "lon": 3.154239893,
      "tz": "Europe\/Paris"
  },
  "LFQJ": {
      "icao": "LFQJ",
      "iata": "XME",
      "name": "Maubeuge-Elesmes Airport",
      "city": "Maubeuge\/Elesmes",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 452,
      "lat": 50.3105010986,
      "lon": 4.0331201553,
      "tz": "Europe\/Paris"
  },
  "LFQK": {
      "icao": "LFQK",
      "iata": "",
      "name": "Chalons Ecury Sur Coole Airport",
      "city": "Cambrai",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 319,
      "lat": 48.9061012268,
      "lon": 4.3541698456,
      "tz": "Europe\/Paris"
  },
  "LFQL": {
      "icao": "LFQL",
      "iata": "",
      "name": "Lens Benifontaine Airport",
      "city": "Cambrai",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 167,
      "lat": 50.4664001465,
      "lon": 2.8197200298,
      "tz": "Europe\/Paris"
  },
  "LFQM": {
      "icao": "LFQM",
      "iata": "",
      "name": "Besancon-La Veze Airport",
      "city": "Besancon\/La Veze",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1271,
      "lat": 47.2066001892,
      "lon": 6.0836801529,
      "tz": "Europe\/Paris"
  },
  "LFQN": {
      "icao": "LFQN",
      "iata": "",
      "name": "Saint Omer Wizernes Airport",
      "city": "Besancon",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 249,
      "lat": 50.7294006348,
      "lon": 2.2358300686,
      "tz": "Europe\/Paris"
  },
  "LFQO": {
      "icao": "LFQO",
      "iata": "",
      "name": "Lille\/Marcq-en-Baroeul Airport",
      "city": "Besancon",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 69,
      "lat": 50.6871986389,
      "lon": 3.0755600929,
      "tz": "Europe\/Paris"
  },
  "LFQP": {
      "icao": "LFQP",
      "iata": "",
      "name": "Phalsbourg-Bourscheid Air Base",
      "city": "Phalsbourg\/Bourscheid",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1017,
      "lat": 48.7663002014,
      "lon": 7.2005200386,
      "tz": "Europe\/Paris"
  },
  "LFQQ": {
      "icao": "LFQQ",
      "iata": "LIL",
      "name": "Lille-Lesquin Airport",
      "city": "Lille\/Lesquin",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 157,
      "lat": 50.5619010925,
      "lon": 3.0894401073,
      "tz": "Europe\/Paris"
  },
  "LFQR": {
      "icao": "LFQR",
      "iata": "",
      "name": "Romilly Sur Seine Airport",
      "city": "Lille",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 267,
      "lat": 48.5005989075,
      "lon": 3.7633299828,
      "tz": "Europe\/Paris"
  },
  "LFQS": {
      "icao": "LFQS",
      "iata": "",
      "name": "Vitry en Artois Airport",
      "city": "Lille",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 174,
      "lat": 50.3382987976,
      "lon": 2.9933300018,
      "tz": "Europe\/Paris"
  },
  "LFQT": {
      "icao": "LFQT",
      "iata": "HZB",
      "name": "Merville-Calonne Airport",
      "city": "Merville\/Calonne",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 61,
      "lat": 50.6184005737,
      "lon": 2.6422400475,
      "tz": "Europe\/Paris"
  },
  "LFQU": {
      "icao": "LFQU",
      "iata": "",
      "name": "Sarre Union Airport",
      "city": "Merville",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 837,
      "lat": 48.9514007568,
      "lon": 7.0777797699,
      "tz": "Europe\/Paris"
  },
  "LFQV": {
      "icao": "LFQV",
      "iata": "XCZ",
      "name": "Charleville-Mezieres Airport",
      "city": "Charleville-Mezieres",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 492,
      "lat": 49.7839012146,
      "lon": 4.6470799446,
      "tz": "Europe\/Paris"
  },
  "LFQW": {
      "icao": "LFQW",
      "iata": "XVO",
      "name": "Vesoul-Frotey Airport",
      "city": "Vesoul\/Frotey",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1249,
      "lat": 47.6375999451,
      "lon": 6.2039198875,
      "tz": "Europe\/Paris"
  },
  "LFQX": {
      "icao": "LFQX",
      "iata": "",
      "name": "Juvancourt Airport",
      "city": "Charleville-Mezieres",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1145,
      "lat": 48.1150016785,
      "lon": 4.8208298683,
      "tz": "Europe\/Paris"
  },
  "LFQY": {
      "icao": "LFQY",
      "iata": "",
      "name": "Saverne Steinbourg Airport",
      "city": "Charleville-Mezieres",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 630,
      "lat": 48.7541999817,
      "lon": 7.4263901711,
      "tz": "Europe\/Paris"
  },
  "LFQZ": {
      "icao": "LFQZ",
      "iata": "",
      "name": "Dieuze Gueblange Airport",
      "city": "Charleville-Mezieres",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 722,
      "lat": 48.7752990723,
      "lon": 6.715280056,
      "tz": "Europe\/Paris"
  },
  "LFRA": {
      "icao": "LFRA",
      "iata": "",
      "name": "Angers Avrille Airport",
      "city": "Charleville-Mezieres",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 187,
      "lat": 47.4991989136,
      "lon": -0.5727779865,
      "tz": "Europe\/Paris"
  },
  "LFRB": {
      "icao": "LFRB",
      "iata": "BES",
      "name": "Brest Bretagne Airport",
      "city": "Brest\/Guipavas",
      "state": "Brittany",
      "country": "FR",
      "elevation": 325,
      "lat": 48.4478988647,
      "lon": -4.4185400009,
      "tz": "Europe\/Paris"
  },
  "LFRC": {
      "icao": "LFRC",
      "iata": "CER",
      "name": "Cherbourg-Maupertus Airport",
      "city": "Cherbourg\/Maupertus",
      "state": "Normandy",
      "country": "FR",
      "elevation": 459,
      "lat": 49.650100708,
      "lon": -1.4702800512,
      "tz": "Europe\/Paris"
  },
  "LFRD": {
      "icao": "LFRD",
      "iata": "DNR",
      "name": "Dinard-Pleurtuit-Saint-Malo Airport",
      "city": "Dinard\/Pleurtuit\/Saint-Malo",
      "state": "Brittany",
      "country": "FR",
      "elevation": 219,
      "lat": 48.5876998901,
      "lon": -2.0799601078,
      "tz": "Europe\/Paris"
  },
  "LFRE": {
      "icao": "LFRE",
      "iata": "LBY",
      "name": "La Baule-Escoublac Airport",
      "city": "La Baule-Escoublac",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 105,
      "lat": 47.2893981934,
      "lon": -2.3463900089,
      "tz": "Europe\/Paris"
  },
  "LFRF": {
      "icao": "LFRF",
      "iata": "GFR",
      "name": "Granville Airport",
      "city": "Granville",
      "state": "Normandy",
      "country": "FR",
      "elevation": 45,
      "lat": 48.8830986023,
      "lon": -1.5641700029,
      "tz": "Europe\/Paris"
  },
  "LFRG": {
      "icao": "LFRG",
      "iata": "DOL",
      "name": "Deauville-Saint-Gatien Airport",
      "city": "Deauville",
      "state": "Normandy",
      "country": "FR",
      "elevation": 479,
      "lat": 49.3652992249,
      "lon": 0.1543059945,
      "tz": "Europe\/Paris"
  },
  "LFRH": {
      "icao": "LFRH",
      "iata": "LRT",
      "name": "Lorient South Brittany (Bretagne Sud) Airport",
      "city": "Lorient\/Lann\/Bihoue",
      "state": "Brittany",
      "country": "FR",
      "elevation": 160,
      "lat": 47.7606010437,
      "lon": -3.4400000572,
      "tz": "Europe\/Paris"
  },
  "LFRI": {
      "icao": "LFRI",
      "iata": "EDM",
      "name": "La Roche-sur-Yon Airport",
      "city": "La Roche-sur-Yon\/Les Ajoncs",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 299,
      "lat": 46.7019004822,
      "lon": -1.3786300421,
      "tz": "Europe\/Paris"
  },
  "LFRJ": {
      "icao": "LFRJ",
      "iata": "LDV",
      "name": "Landivisiau Air Base",
      "city": "Landivisiau",
      "state": "Brittany",
      "country": "FR",
      "elevation": 348,
      "lat": 48.5303001404,
      "lon": -4.1516399384,
      "tz": "Europe\/Paris"
  },
  "LFRK": {
      "icao": "LFRK",
      "iata": "CFR",
      "name": "Caen-Carpiquet Airport",
      "city": "Caen\/Carpiquet",
      "state": "Normandy",
      "country": "FR",
      "elevation": 256,
      "lat": 49.1733016968,
      "lon": -0.4499999881,
      "tz": "Europe\/Paris"
  },
  "LFRL": {
      "icao": "LFRL",
      "iata": "",
      "name": "Lanveoc-Poulmic Air Base",
      "city": "Lanveoc\/Poulmic",
      "state": "Brittany",
      "country": "FR",
      "elevation": 287,
      "lat": 48.2817001343,
      "lon": -4.4450201988,
      "tz": "Europe\/Paris"
  },
  "LFRM": {
      "icao": "LFRM",
      "iata": "LME",
      "name": "Le Mans-Arnage Airport",
      "city": "Le Mans\/Arnage",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 194,
      "lat": 47.948600769,
      "lon": 0.2016669959,
      "tz": "Europe\/Paris"
  },
  "LFRN": {
      "icao": "LFRN",
      "iata": "RNS",
      "name": "Rennes-Saint-Jacques Airport",
      "city": "Rennes\/Saint-Jacques",
      "state": "Brittany",
      "country": "FR",
      "elevation": 124,
      "lat": 48.0694999695,
      "lon": -1.7347899675,
      "tz": "Europe\/Paris"
  },
  "LFRO": {
      "icao": "LFRO",
      "iata": "LAI",
      "name": "Lannion-Cote de Granit Airport",
      "city": "Lannion",
      "state": "Brittany",
      "country": "FR",
      "elevation": 290,
      "lat": 48.7543983459,
      "lon": -3.4716598988,
      "tz": "Europe\/Paris"
  },
  "LFRP": {
      "icao": "LFRP",
      "iata": "",
      "name": "Ploermel Loyat Airport",
      "city": "Rennes",
      "state": "Brittany",
      "country": "FR",
      "elevation": 236,
      "lat": 48.0027999878,
      "lon": -2.3772199154,
      "tz": "Europe\/Paris"
  },
  "LFRQ": {
      "icao": "LFRQ",
      "iata": "UIP",
      "name": "Quimper-Cornouaille Airport",
      "city": "Quimper\/Pluguffan",
      "state": "Brittany",
      "country": "FR",
      "elevation": 297,
      "lat": 47.9749984741,
      "lon": -4.1677899361,
      "tz": "Europe\/Paris"
  },
  "LFRS": {
      "icao": "LFRS",
      "iata": "NTE",
      "name": "Nantes Atlantique Airport",
      "city": "Nantes",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 90,
      "lat": 47.1531982422,
      "lon": -1.610730052,
      "tz": "Europe\/Paris"
  },
  "LFRT": {
      "icao": "LFRT",
      "iata": "SBK",
      "name": "Saint-Brieuc-Armor Airport",
      "city": "Saint-Brieuc\/Armor",
      "state": "Brittany",
      "country": "FR",
      "elevation": 453,
      "lat": 48.5377998352,
      "lon": -2.8544399738,
      "tz": "Europe\/Paris"
  },
  "LFRU": {
      "icao": "LFRU",
      "iata": "MXN",
      "name": "Morlaix-Ploujean Airport",
      "city": "Morlaix\/Ploujean",
      "state": "Brittany",
      "country": "FR",
      "elevation": 272,
      "lat": 48.6031990051,
      "lon": -3.8157799244,
      "tz": "Europe\/Paris"
  },
  "LFRV": {
      "icao": "LFRV",
      "iata": "VNE",
      "name": "Vannes-Meucon Airport",
      "city": "Vannes\/Meucon",
      "state": "Brittany",
      "country": "FR",
      "elevation": 446,
      "lat": 47.7233009338,
      "lon": -2.7185599804,
      "tz": "Europe\/Paris"
  },
  "LFRW": {
      "icao": "LFRW",
      "iata": "",
      "name": "Avranches Le Val St Pere Airport",
      "city": "Vannes",
      "state": "Normandy",
      "country": "FR",
      "elevation": 26,
      "lat": 48.6617012024,
      "lon": -1.4044400454,
      "tz": "Europe\/Paris"
  },
  "LFRZ": {
      "icao": "LFRZ",
      "iata": "SNR",
      "name": "Saint-Nazaire-Montoir Airport",
      "city": "Saint-Nazaire\/Montoir",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 13,
      "lat": 47.3121986389,
      "lon": -2.1491799355,
      "tz": "Europe\/Paris"
  },
  "LFSA": {
      "icao": "LFSA",
      "iata": "QBQ",
      "name": "Besancon Thise Airport",
      "city": "St Nazaire",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 807,
      "lat": 47.2747001648,
      "lon": 6.0841698647,
      "tz": "Europe\/Paris"
  },
  "LFSB": {
      "icao": "LFSB",
      "iata": "BSL",
      "name": "EuroAirport Basel-Mulhouse-Freiburg Airport",
      "city": "Bale\/Mulhouse",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 885,
      "lat": 47.5895996094,
      "lon": 7.5299100876,
      "tz": "Europe\/Paris"
  },
  "LFSC": {
      "icao": "LFSC",
      "iata": "",
      "name": "Colmar-Meyenheim Air Base",
      "city": "Colmar\/Meyenheim",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 693,
      "lat": 47.922000885,
      "lon": 7.3996701241,
      "tz": "Europe\/Paris"
  },
  "LFSD": {
      "icao": "LFSD",
      "iata": "DIJ",
      "name": "Dijon-Bourgogne Airport",
      "city": "Dijon\/Longvic",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 726,
      "lat": 47.268901825,
      "lon": 5.0900001526,
      "tz": "Europe\/Paris"
  },
  "LFSE": {
      "icao": "LFSE",
      "iata": "",
      "name": "Epinal Dogneville Airport",
      "city": "Dijon",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1040,
      "lat": 48.2118988037,
      "lon": 6.4491701126,
      "tz": "Europe\/Paris"
  },
  "LFSF": {
      "icao": "LFSF",
      "iata": "MZM",
      "name": "Metz-Frescaty (BA 128) Air Base",
      "city": "Metz\/Frescaty",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 629,
      "lat": 49.0717010498,
      "lon": 6.1316699982,
      "tz": "Europe\/Paris"
  },
  "LFSG": {
      "icao": "LFSG",
      "iata": "EPL",
      "name": "Epinal-Mirecourt Airport",
      "city": "Epinal\/Mirecourt",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1084,
      "lat": 48.3250007629,
      "lon": 6.0699801445,
      "tz": "Europe\/Paris"
  },
  "LFSH": {
      "icao": "LFSH",
      "iata": "",
      "name": "Haguenau Airport",
      "city": "Haguenau",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 491,
      "lat": 48.7943000793,
      "lon": 7.817609787,
      "tz": "Europe\/Paris"
  },
  "LFSI": {
      "icao": "LFSI",
      "iata": "",
      "name": "Saint-Dizier-Robinson (BA 113) Air Base",
      "city": "Saint-Dizier\/Robinson",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 458,
      "lat": 48.6360015869,
      "lon": 4.8994197845,
      "tz": "Europe\/Paris"
  },
  "LFSJ": {
      "icao": "LFSJ",
      "iata": "",
      "name": "Sedan Douzy Airport",
      "city": "St Dizier",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 512,
      "lat": 49.6596984863,
      "lon": 5.037779808,
      "tz": "Europe\/Paris"
  },
  "LFSK": {
      "icao": "LFSK",
      "iata": "",
      "name": "Vitry Le Francois Vauclerc Airport",
      "city": "St Dizier",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 410,
      "lat": 48.7033004761,
      "lon": 4.684440136,
      "tz": "Europe\/Paris"
  },
  "LFSL": {
      "icao": "LFSL",
      "iata": "BVE",
      "name": "Toul Rosieres Air Base",
      "city": "St Dizier",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 936,
      "lat": 48.7799987793,
      "lon": 5.9800000191,
      "tz": "Europe\/Paris"
  },
  "LFSM": {
      "icao": "LFSM",
      "iata": "XMF",
      "name": "Montbeliard-Courcelles Airport",
      "city": "Montbeliard\/Courcelles",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1041,
      "lat": 47.4869995117,
      "lon": 6.7905402184,
      "tz": "Europe\/Paris"
  },
  "LFSN": {
      "icao": "LFSN",
      "iata": "ENC",
      "name": "Nancy-Essey Airport",
      "city": "Nancy\/Essey",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 751,
      "lat": 48.6921005249,
      "lon": 6.2304601669,
      "tz": "Europe\/Paris"
  },
  "LFSO": {
      "icao": "LFSO",
      "iata": "",
      "name": "Nancy-Ochey (BA 133) Air Base",
      "city": "Nancy\/Ochey",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1106,
      "lat": 48.5830993652,
      "lon": 5.9549999237,
      "tz": "Europe\/Paris"
  },
  "LFSP": {
      "icao": "LFSP",
      "iata": "",
      "name": "Pontarlier Airport",
      "city": "Pontarlier",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 2683,
      "lat": 46.9039993286,
      "lon": 6.3273701668,
      "tz": "Europe\/Paris"
  },
  "LFSQ": {
      "icao": "LFSQ",
      "iata": "BOR",
      "name": "Fontaine Airport",
      "city": "Belfort",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1208,
      "lat": 47.6556015015,
      "lon": 7.0108299255,
      "tz": "Europe\/Paris"
  },
  "LFSR": {
      "icao": "LFSR",
      "iata": "RHE",
      "name": "Reims-Champagne (BA 112) Airport",
      "city": "Reims\/Champagne",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 312,
      "lat": 49.3100013733,
      "lon": 4.0500001907,
      "tz": "Europe\/Paris"
  },
  "LFST": {
      "icao": "LFST",
      "iata": "SXB",
      "name": "Strasbourg Airport",
      "city": "Strasbourg",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 505,
      "lat": 48.5382995605,
      "lon": 7.6282300949,
      "tz": "Europe\/Paris"
  },
  "LFSU": {
      "icao": "LFSU",
      "iata": "",
      "name": "Langres Rolampont Airport",
      "city": "Strasbourg",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1378,
      "lat": 47.9655990601,
      "lon": 5.2950000763,
      "tz": "Europe\/Paris"
  },
  "LFSV": {
      "icao": "LFSV",
      "iata": "",
      "name": "Pont St Vincent Airport",
      "city": "Strasbourg",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1306,
      "lat": 48.601398468,
      "lon": 6.057779789,
      "tz": "Europe\/Paris"
  },
  "LFSW": {
      "icao": "LFSW",
      "iata": "",
      "name": "Epernay Plivot Airport",
      "city": "Strasbourg",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 289,
      "lat": 49.0052986145,
      "lon": 4.0869398117,
      "tz": "Europe\/Paris"
  },
  "LFSX": {
      "icao": "LFSX",
      "iata": "",
      "name": "Luxeuil-Saint-Sauveur (BA 116) Air Base",
      "city": "Luxeuil\/Saint-Sauveur",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 913,
      "lat": 47.7831001282,
      "lon": 6.3640599251,
      "tz": "Europe\/Paris"
  },
  "LFSY": {
      "icao": "LFSY",
      "iata": "",
      "name": "Chaumont Airport",
      "city": "",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1175,
      "lat": 48.0769004822,
      "lon": 5.1513900757,
      "tz": "Europe\/Paris"
  },
  "LFSZ": {
      "icao": "LFSZ",
      "iata": "VTL",
      "name": "Vittel Champ De Course Airport",
      "city": "Luxeuil",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1076,
      "lat": 48.2238998413,
      "lon": 5.9352798462,
      "tz": "Europe\/Paris"
  },
  "LFTF": {
      "icao": "LFTF",
      "iata": "",
      "name": "Cuers-Pierrefeu Airport",
      "city": "Cuers\/Pierrefeu",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 266,
      "lat": 43.2477989197,
      "lon": 6.1266999245,
      "tz": "Europe\/Paris"
  },
  "LFTH": {
      "icao": "LFTH",
      "iata": "TLN",
      "name": "Toulon-Hyeres Airport",
      "city": "Toulon\/Hyeres\/Le Palyvestre",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 7,
      "lat": 43.0973014832,
      "lon": 6.1460299492,
      "tz": "Europe\/Paris"
  },
  "LFTM": {
      "icao": "LFTM",
      "iata": "",
      "name": "Serres - La Batie-Montsaleon Airport",
      "city": "Hyeres",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 2345,
      "lat": 44.4583015442,
      "lon": 5.7283301353,
      "tz": "Europe\/Paris"
  },
  "LFTN": {
      "icao": "LFTN",
      "iata": "",
      "name": "La Grand'combe Airport",
      "city": "Hyeres",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 1647,
      "lat": 44.2444000244,
      "lon": 4.0122199059,
      "tz": "Europe\/Paris"
  },
  "LFTP": {
      "icao": "LFTP",
      "iata": "",
      "name": "Puimoisson Airport",
      "city": "Hyeres",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 2523,
      "lat": 43.870300293,
      "lon": 6.1647200584,
      "tz": "Europe\/Paris"
  },
  "LFTQ": {
      "icao": "LFTQ",
      "iata": "",
      "name": "Chateaubriant Pouance Airport",
      "city": "Hyeres",
      "state": "Pays-de-la-Loire",
      "country": "FR",
      "elevation": 322,
      "lat": 47.7406005859,
      "lon": -1.1880600452,
      "tz": "Europe\/Paris"
  },
  "LFTR": {
      "icao": "LFTR",
      "iata": "",
      "name": "Toulo(St Mandrier Navy) Airport",
      "city": "Hyeres",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 0,
      "lat": 43.082698822,
      "lon": 5.9338197708,
      "tz": "Europe\/Paris"
  },
  "LFTU": {
      "icao": "LFTU",
      "iata": "",
      "name": "Frejus Airport",
      "city": "Hyeres",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 0,
      "lat": 43.4146995544,
      "lon": 6.7455601692,
      "tz": "Europe\/Paris"
  },
  "LFTW": {
      "icao": "LFTW",
      "iata": "FNI",
      "name": "Nimes-Arles-Camargue Airport",
      "city": "Nimes\/Garons",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 309,
      "lat": 43.7574005127,
      "lon": 4.4163498878,
      "tz": "Europe\/Paris"
  },
  "LFTZ": {
      "icao": "LFTZ",
      "iata": "LTT",
      "name": "La Mole Airport",
      "city": "La Mole",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 59,
      "lat": 43.2053985596,
      "lon": 6.4819998741,
      "tz": "Europe\/Paris"
  },
  "LFVM": {
      "icao": "LFVM",
      "iata": "MQC",
      "name": "Miquelon Airport",
      "city": "Miquelon",
      "state": "Miquelon-Langlade",
      "country": "PM",
      "elevation": 10,
      "lat": 47.095500946,
      "lon": -56.3802986145,
      "tz": "America\/Miquelon"
  },
  "LFVP": {
      "icao": "LFVP",
      "iata": "FSP",
      "name": "St Pierre Airport",
      "city": "Saint-Pierre",
      "state": "",
      "country": "PM",
      "elevation": 27,
      "lat": 46.7629013062,
      "lon": -56.1730995178,
      "tz": "America\/Miquelon"
  },
  "LFXA": {
      "icao": "LFXA",
      "iata": "",
      "name": "Amberieu Airport (BA 278)",
      "city": "Amberieu",
      "state": "Auvergne-Rhone-Alpes",
      "country": "FR",
      "elevation": 823,
      "lat": 45.9873008728,
      "lon": 5.3284401894,
      "tz": "Europe\/Paris"
  },
  "LFXB": {
      "icao": "LFXB",
      "iata": "",
      "name": "Saintes Thenac Airport",
      "city": "St Pierre I",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 118,
      "lat": 45.7019004822,
      "lon": -0.636111021,
      "tz": "Europe\/Paris"
  },
  "LFXC": {
      "icao": "LFXC",
      "iata": "",
      "name": "Vittel (Auzainvilliers) Airport",
      "city": "St Pierre I",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 0,
      "lat": 48.2285995483,
      "lon": 5.8430199623,
      "tz": "Europe\/Paris"
  },
  "LFXG": {
      "icao": "LFXG",
      "iata": "",
      "name": "Bitche Airport",
      "city": "St Pierre I",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 984,
      "lat": 49.0666999817,
      "lon": 7.4499998093,
      "tz": "Europe\/Paris"
  },
  "LFXH": {
      "icao": "LFXH",
      "iata": "",
      "name": "Le Valdahon Air Base",
      "city": "St Pierre I",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 2067,
      "lat": 47.1666984558,
      "lon": 6.3499999046,
      "tz": "Europe\/Paris"
  },
  "LFXI": {
      "icao": "LFXI",
      "iata": "",
      "name": "Apt St Christol Airport",
      "city": "St Pierre I",
      "state": "Provence-Alpes-Cote-d'Azur",
      "country": "FR",
      "elevation": 2736,
      "lat": 44.0532989502,
      "lon": 5.4949998856,
      "tz": "Europe\/Paris"
  },
  "LFXM": {
      "icao": "LFXM",
      "iata": "",
      "name": "Mourmelon Airport",
      "city": "St Pierre I",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 387,
      "lat": 49.1114006042,
      "lon": 4.3669400215,
      "tz": "Europe\/Paris"
  },
  "LFXQ": {
      "icao": "LFXQ",
      "iata": "",
      "name": "Coetquidan Air Base",
      "city": "Guer",
      "state": "Brittany",
      "country": "FR",
      "elevation": 0,
      "lat": 47.9466018677,
      "lon": -2.1923000813,
      "tz": "Europe\/Paris"
  },
  "LFXR": {
      "icao": "LFXR",
      "iata": "",
      "name": "Rochefort Soubise Airport",
      "city": "St Pierre I",
      "state": "Nouvelle-Aquitaine",
      "country": "FR",
      "elevation": 13,
      "lat": 45.9308013916,
      "lon": -0.9952780008,
      "tz": "Europe\/Paris"
  },
  "LFXU": {
      "icao": "LFXU",
      "iata": "",
      "name": "Les Mureaux Airport",
      "city": "St Pierre I",
      "state": "Ile-de-France",
      "country": "FR",
      "elevation": 89,
      "lat": 48.998298645,
      "lon": 1.9427800179,
      "tz": "Europe\/Paris"
  },
  "LFYD": {
      "icao": "LFYD",
      "iata": "",
      "name": "Damblain Airport",
      "city": "Damblain",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 1280,
      "lat": 48.0862998962,
      "lon": 5.6640601158,
      "tz": "Europe\/Paris"
  },
  "LFYG": {
      "icao": "LFYG",
      "iata": "",
      "name": "Cambrai Niergnies Airport",
      "city": "St Pierre I",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 312,
      "lat": 50.1425018311,
      "lon": 3.2650001049,
      "tz": "Europe\/Paris"
  },
  "LFYH": {
      "icao": "LFYH",
      "iata": "",
      "name": "Broye Les Pesmes Airport",
      "city": "St Pierre I",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 682,
      "lat": 47.3166999817,
      "lon": 5.5166702271,
      "tz": "Europe\/Paris"
  },
  "LFYK": {
      "icao": "LFYK",
      "iata": "",
      "name": "Marville Montmedy Airport",
      "city": "St Pierre I",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 909,
      "lat": 49.4592018127,
      "lon": 5.4025001526,
      "tz": "Europe\/Paris"
  },
  "LFYL": {
      "icao": "LFYL",
      "iata": "",
      "name": "Lure Malbouhans Airport",
      "city": "St Pierre I",
      "state": "Bourgogne-Franche-Comte",
      "country": "FR",
      "elevation": 1040,
      "lat": 47.70470047,
      "lon": 6.5458297729,
      "tz": "Europe\/Paris"
  },
  "LFYM": {
      "icao": "LFYM",
      "iata": "",
      "name": "Marigny Le Grand Airport",
      "city": "St Pierre I",
      "state": "Grand-Est",
      "country": "FR",
      "elevation": 329,
      "lat": 48.6599998474,
      "lon": 3.8338899612,
      "tz": "Europe\/Paris"
  },
  "LFYR": {
      "icao": "LFYR",
      "iata": "",
      "name": "Romorantin Pruniers Airport",
      "city": "St Pierre I",
      "state": "Centre",
      "country": "FR",
      "elevation": 289,
      "lat": 47.3208007813,
      "lon": 1.6888899803,
      "tz": "Europe\/Paris"
  },
  "LFYS": {
      "icao": "LFYS",
      "iata": "",
      "name": "Ste Leocadie Airport",
      "city": "St Pierre I",
      "state": "Occitanie",
      "country": "FR",
      "elevation": 4331,
      "lat": 42.4472007751,
      "lon": 2.0108299255,
      "tz": "Europe\/Madrid"
  },
  "LFYT": {
      "icao": "LFYT",
      "iata": "",
      "name": "Saint-Simon - Clastres Air Base",
      "city": "St Pierre I",
      "state": "Hauts-de-France",
      "country": "FR",
      "elevation": 295,
      "lat": 49.75,
      "lon": 3.2166700363,
      "tz": "Europe\/Paris"
  },
  "LG53": {
      "icao": "LG53",
      "iata": "",
      "name": "Lamia Airport",
      "city": "Lamia",
      "state": "Central-Greece",
      "country": "GR",
      "elevation": 38,
      "lat": 38.8745994568,
      "lon": 22.4349994659,
      "tz": "Europe\/Athens"
  },
  "LG54": {
      "icao": "LG54",
      "iata": "",
      "name": "Tympaki Airport",
      "city": "Crete Island",
      "state": "Crete",
      "country": "GR",
      "elevation": 7,
      "lat": 35.0637016296,
      "lon": 24.7674007416,
      "tz": "Europe\/Athens"
  },
  "LG55": {
      "icao": "LG55",
      "iata": "",
      "name": "Triodhon Airport",
      "city": "Triodhon",
      "state": "Peloponnese",
      "country": "GR",
      "elevation": 36,
      "lat": 37.0941009521,
      "lon": 21.9904003143,
      "tz": "Europe\/Athens"
  },
  "LG56": {
      "icao": "LG56",
      "iata": "",
      "name": "Olimboi Airport",
      "city": "Chios Island",
      "state": "North-Aegean",
      "country": "GR",
      "elevation": 656,
      "lat": 38.2360992432,
      "lon": 25.9391994476,
      "tz": "Europe\/Athens"
  },
  "LGAD": {
      "icao": "LGAD",
      "iata": "PYR",
      "name": "Andravida Airport",
      "city": "Andravida",
      "state": "West-Greece",
      "country": "GR",
      "elevation": 55,
      "lat": 37.9207000732,
      "lon": 21.2926006317,
      "tz": "Europe\/Athens"
  },
  "LGAG": {
      "icao": "LGAG",
      "iata": "AGQ",
      "name": "Agrinion Airport",
      "city": "Agrinion",
      "state": "West-Greece",
      "country": "GR",
      "elevation": 154,
      "lat": 38.6020011902,
      "lon": 21.3512001038,
      "tz": "Europe\/Athens"
  },
  "LGAL": {
      "icao": "LGAL",
      "iata": "AXD",
      "name": "Dimokritos Airport",
      "city": "Alexandroupolis",
      "state": "East-Macedonia-and-Thrace",
      "country": "GR",
      "elevation": 24,
      "lat": 40.8558998108,
      "lon": 25.9563007355,
      "tz": "Europe\/Athens"
  },
  "LGAT": {
      "icao": "LGAT",
      "iata": "HEW",
      "name": "Athen Helenikon Airport",
      "city": "Athens",
      "state": "Attica",
      "country": "GR",
      "elevation": 69,
      "lat": 37.8932991028,
      "lon": 23.7261009216,
      "tz": "Europe\/Athens"
  },
  "LGAV": {
      "icao": "LGAV",
      "iata": "ATH",
      "name": "Eleftherios Venizelos International Airport",
      "city": "Athens",
      "state": "Attica",
      "country": "GR",
      "elevation": 308,
      "lat": 37.9364013672,
      "lon": 23.9444999695,
      "tz": "Europe\/Athens"
  },
  "LGAX": {
      "icao": "LGAX",
      "iata": "",
      "name": "Alexandria Airport",
      "city": "Alexandria",
      "state": "Central-Macedonia",
      "country": "GR",
      "elevation": 27,
      "lat": 40.6511001587,
      "lon": 22.4887008667,
      "tz": "Europe\/Athens"
  },
  "LGBL": {
      "icao": "LGBL",
      "iata": "VOL",
      "name": "Nea Anchialos Airport",
      "city": "Nea Anchialos",
      "state": "Thessaly",
      "country": "GR",
      "elevation": 83,
      "lat": 39.2196006775,
      "lon": 22.7943000793,
      "tz": "Europe\/Athens"
  },
  "LGEL": {
      "icao": "LGEL",
      "iata": "",
      "name": "Elefsis Airport",
      "city": "Elefsina",
      "state": "Attica",
      "country": "GR",
      "elevation": 143,
      "lat": 38.0638008118,
      "lon": 23.5559997559,
      "tz": "Europe\/Athens"
  },
  "LGEP": {
      "icao": "LGEP",
      "iata": "",
      "name": "Epitalion Airport",
      "city": "Pyrgos",
      "state": "West-Greece",
      "country": "GR",
      "elevation": 3,
      "lat": 37.613899231,
      "lon": 21.4981002808,
      "tz": "Europe\/Athens"
  },
  "LGHI": {
      "icao": "LGHI",
      "iata": "JKH",
      "name": "Chios Island National Airport",
      "city": "Chios Island",
      "state": "North-Aegean",
      "country": "GR",
      "elevation": 15,
      "lat": 38.3432006836,
      "lon": 26.1406002045,
      "tz": "Europe\/Athens"
  },
  "LGHL": {
      "icao": "LGHL",
      "iata": "PKH",
      "name": "Porto Cheli Airport",
      "city": "Porto Cheli",
      "state": "Peloponnese",
      "country": "GR",
      "elevation": 69,
      "lat": 37.2975006104,
      "lon": 23.1478004456,
      "tz": "Europe\/Athens"
  },
  "LGIK": {
      "icao": "LGIK",
      "iata": "JIK",
      "name": "Ikaria Airport",
      "city": "Ikaria Island",
      "state": "North-Aegean",
      "country": "GR",
      "elevation": 79,
      "lat": 37.6827011108,
      "lon": 26.3470993042,
      "tz": "Europe\/Athens"
  },
  "LGIO": {
      "icao": "LGIO",
      "iata": "IOA",
      "name": "Ioannina Airport",
      "city": "Ioannina",
      "state": "Epirus",
      "country": "GR",
      "elevation": 1558,
      "lat": 39.6963996887,
      "lon": 20.8225002289,
      "tz": "Europe\/Athens"
  },
  "LGIR": {
      "icao": "LGIR",
      "iata": "HER",
      "name": "Heraklion International Nikos Kazantzakis Airport",
      "city": "Heraklion",
      "state": "Crete",
      "country": "GR",
      "elevation": 115,
      "lat": 35.3396987915,
      "lon": 25.1802997589,
      "tz": "Europe\/Athens"
  },
  "LGKA": {
      "icao": "LGKA",
      "iata": "KSO",
      "name": "Kastoria National Airport",
      "city": "Kastoria",
      "state": "West-Macedonia",
      "country": "GR",
      "elevation": 2167,
      "lat": 40.4463005066,
      "lon": 21.2821998596,
      "tz": "Europe\/Athens"
  },
  "LGKC": {
      "icao": "LGKC",
      "iata": "KIT",
      "name": "Kithira Airport",
      "city": "Kithira Island",
      "state": "Attica",
      "country": "GR",
      "elevation": 1045,
      "lat": 36.2742996216,
      "lon": 23.0170001984,
      "tz": "Europe\/Athens"
  },
  "LGKF": {
      "icao": "LGKF",
      "iata": "EFL",
      "name": "Kefallinia Airport",
      "city": "Kefallinia Island",
      "state": "Ionian-Islands",
      "country": "GR",
      "elevation": 59,
      "lat": 38.120098114,
      "lon": 20.5004997253,
      "tz": "Europe\/Athens"
  },
  "LGKJ": {
      "icao": "LGKJ",
      "iata": "KZS",
      "name": "Kastelorizo Airport",
      "city": "Kastelorizo Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 489,
      "lat": 36.1417007446,
      "lon": 29.5764007568,
      "tz": "Europe\/Athens"
  },
  "LGKL": {
      "icao": "LGKL",
      "iata": "KLX",
      "name": "Kalamata Airport",
      "city": "Kalamata",
      "state": "Peloponnese",
      "country": "GR",
      "elevation": 26,
      "lat": 37.0682983398,
      "lon": 22.0254993439,
      "tz": "Europe\/Athens"
  },
  "LGKM": {
      "icao": "LGKM",
      "iata": "",
      "name": "Amigdhaleon Airport",
      "city": "Filipos",
      "state": "East-Macedonia-and-Thrace",
      "country": "GR",
      "elevation": 203,
      "lat": 40.9728012085,
      "lon": 24.3414001465,
      "tz": "Europe\/Athens"
  },
  "LGKN": {
      "icao": "LGKN",
      "iata": "",
      "name": "Marathon Kotroni Airport",
      "city": "Marathon",
      "state": "Attica",
      "country": "GR",
      "elevation": 682,
      "lat": 38.1377983093,
      "lon": 23.9510993958,
      "tz": "Europe\/Athens"
  },
  "LGKO": {
      "icao": "LGKO",
      "iata": "KGS",
      "name": "Kos Airport",
      "city": "Kos Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 412,
      "lat": 36.7933006287,
      "lon": 27.0916996002,
      "tz": "Europe\/Athens"
  },
  "LGKP": {
      "icao": "LGKP",
      "iata": "AOK",
      "name": "Karpathos Airport",
      "city": "Karpathos Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 66,
      "lat": 35.4213981628,
      "lon": 27.1459999084,
      "tz": "Europe\/Athens"
  },
  "LGKR": {
      "icao": "LGKR",
      "iata": "CFU",
      "name": "Ioannis Kapodistrias International Airport",
      "city": "Kerkyra Island",
      "state": "Ionian-Islands",
      "country": "GR",
      "elevation": 6,
      "lat": 39.6018981934,
      "lon": 19.911699295,
      "tz": "Europe\/Athens"
  },
  "LGKS": {
      "icao": "LGKS",
      "iata": "KSJ",
      "name": "Kasos Airport",
      "city": "Kasos Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 35,
      "lat": 35.4213981628,
      "lon": 26.9099998474,
      "tz": "Europe\/Athens"
  },
  "LGKV": {
      "icao": "LGKV",
      "iata": "KVA",
      "name": "Alexander the Great International Airport",
      "city": "Kavala",
      "state": "East-Macedonia-and-Thrace",
      "country": "GR",
      "elevation": 18,
      "lat": 40.9132995605,
      "lon": 24.6191997528,
      "tz": "Europe\/Athens"
  },
  "LGKY": {
      "icao": "LGKY",
      "iata": "JKL",
      "name": "Kalymnos Airport",
      "city": "Kalymnos Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 771,
      "lat": 36.9632987976,
      "lon": 26.9405994415,
      "tz": "Europe\/Athens"
  },
  "LGKZ": {
      "icao": "LGKZ",
      "iata": "KZI",
      "name": "Filippos Airport",
      "city": "Kozani",
      "state": "West-Macedonia",
      "country": "GR",
      "elevation": 2059,
      "lat": 40.2860984802,
      "lon": 21.8407993317,
      "tz": "Europe\/Athens"
  },
  "LGLE": {
      "icao": "LGLE",
      "iata": "LRS",
      "name": "Leros Airport",
      "city": "Leros Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 39,
      "lat": 37.1848983765,
      "lon": 26.8003005981,
      "tz": "Europe\/Athens"
  },
  "LGLM": {
      "icao": "LGLM",
      "iata": "LXS",
      "name": "Limnos Airport",
      "city": "Limnos Island",
      "state": "North-Aegean",
      "country": "GR",
      "elevation": 14,
      "lat": 39.917098999,
      "lon": 25.2362995148,
      "tz": "Europe\/Athens"
  },
  "LGLR": {
      "icao": "LGLR",
      "iata": "LRA",
      "name": "Larisa Airport",
      "city": "Larisa",
      "state": "Thessaly",
      "country": "GR",
      "elevation": 241,
      "lat": 39.6562825886,
      "lon": 22.3400008678,
      "tz": "Europe\/Athens"
  },
  "LGMG": {
      "icao": "LGMG",
      "iata": "",
      "name": "Megara Airport",
      "city": "Megara",
      "state": "Attica",
      "country": "GR",
      "elevation": 12,
      "lat": 37.981098175,
      "lon": 23.3654003143,
      "tz": "Europe\/Athens"
  },
  "LGMK": {
      "icao": "LGMK",
      "iata": "JMK",
      "name": "Mikonos Airport",
      "city": "Mykonos Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 405,
      "lat": 37.4351005554,
      "lon": 25.3481006622,
      "tz": "Europe\/Athens"
  },
  "LGML": {
      "icao": "LGML",
      "iata": "MLO",
      "name": "Milos Airport",
      "city": "Milos Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 10,
      "lat": 36.6968994141,
      "lon": 24.4769001007,
      "tz": "Europe\/Athens"
  },
  "LGMR": {
      "icao": "LGMR",
      "iata": "",
      "name": "Marathon Wasser Airport",
      "city": "Marathon",
      "state": "Attica",
      "country": "GR",
      "elevation": 3,
      "lat": 38.143901825,
      "lon": 24.0128002167,
      "tz": "Europe\/Athens"
  },
  "LGMT": {
      "icao": "LGMT",
      "iata": "MJT",
      "name": "Mytilene International Airport",
      "city": "Mytilene",
      "state": "North-Aegean",
      "country": "GR",
      "elevation": 60,
      "lat": 39.0567016602,
      "lon": 26.5983009338,
      "tz": "Europe\/Athens"
  },
  "LGNX": {
      "icao": "LGNX",
      "iata": "JNX",
      "name": "Naxos Airport",
      "city": "Naxos Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 10,
      "lat": 37.0811004639,
      "lon": 25.3680992126,
      "tz": "Europe\/Athens"
  },
  "LGPA": {
      "icao": "LGPA",
      "iata": "PAS",
      "name": "Paros Airport",
      "city": "Paros Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 131,
      "lat": 37.0102996826,
      "lon": 25.1280994415,
      "tz": "Europe\/Athens"
  },
  "LGPL": {
      "icao": "LGPL",
      "iata": "JTY",
      "name": "Astypalaia Airport",
      "city": "Astypalaia Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 165,
      "lat": 36.5798988342,
      "lon": 26.3757991791,
      "tz": "Europe\/Athens"
  },
  "LGPZ": {
      "icao": "LGPZ",
      "iata": "PVK",
      "name": "Aktion National Airport",
      "city": "Preveza\/Lefkada",
      "state": "West-Greece",
      "country": "GR",
      "elevation": 11,
      "lat": 38.9254989624,
      "lon": 20.7653007507,
      "tz": "Europe\/Athens"
  },
  "LGRD": {
      "icao": "LGRD",
      "iata": "",
      "name": "Maritsa Airport",
      "city": "Rodes Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 204,
      "lat": 36.3830986023,
      "lon": 28.1089000702,
      "tz": "Europe\/Athens"
  },
  "LGRP": {
      "icao": "LGRP",
      "iata": "RHO",
      "name": "Diagoras Airport",
      "city": "Rodes Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 17,
      "lat": 36.4053993225,
      "lon": 28.0862007141,
      "tz": "Europe\/Athens"
  },
  "LGRS": {
      "icao": "LGRS",
      "iata": "",
      "name": "Logan Reserve Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7082996368,
      "lon": 153.1049957275,
      "tz": "Australia\/Brisbane"
  },
  "LGRX": {
      "icao": "LGRX",
      "iata": "GPA",
      "name": "Araxos Airport",
      "city": "Patras",
      "state": "West-Greece",
      "country": "GR",
      "elevation": 46,
      "lat": 38.1511001587,
      "lon": 21.4256000519,
      "tz": "Europe\/Athens"
  },
  "LGSA": {
      "icao": "LGSA",
      "iata": "CHQ",
      "name": "Chania International Airport",
      "city": "Souda",
      "state": "Crete",
      "country": "GR",
      "elevation": 490,
      "lat": 35.5317001343,
      "lon": 24.1497001648,
      "tz": "Europe\/Athens"
  },
  "LGSD": {
      "icao": "LGSD",
      "iata": "",
      "name": "Sedes Airport",
      "city": "Sedes",
      "state": "Central-Macedonia",
      "country": "GR",
      "elevation": 85,
      "lat": 40.5331001282,
      "lon": 23.0252990723,
      "tz": "Europe\/Athens"
  },
  "LGSK": {
      "icao": "LGSK",
      "iata": "JSI",
      "name": "Skiathos Island National Airport",
      "city": "Skiathos",
      "state": "Thessaly",
      "country": "GR",
      "elevation": 54,
      "lat": 39.1771011353,
      "lon": 23.5037002563,
      "tz": "Europe\/Athens"
  },
  "LGSM": {
      "icao": "LGSM",
      "iata": "SMI",
      "name": "Samos Airport",
      "city": "Samos Island",
      "state": "North-Aegean",
      "country": "GR",
      "elevation": 19,
      "lat": 37.6899986267,
      "lon": 26.911699295,
      "tz": "Europe\/Athens"
  },
  "LGSO": {
      "icao": "LGSO",
      "iata": "JSY",
      "name": "Syros Airport",
      "city": "Syros Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 236,
      "lat": 37.4227981567,
      "lon": 24.9508991241,
      "tz": "Europe\/Athens"
  },
  "LGSP": {
      "icao": "LGSP",
      "iata": "SPJ",
      "name": "Sparti Airport",
      "city": "Sparti",
      "state": "Peloponnese",
      "country": "GR",
      "elevation": 500,
      "lat": 36.9738998413,
      "lon": 22.5263004303,
      "tz": "Europe\/Athens"
  },
  "LGSR": {
      "icao": "LGSR",
      "iata": "JTR",
      "name": "Santorini Airport",
      "city": "Santorini Island",
      "state": "South-Aegean",
      "country": "GR",
      "elevation": 127,
      "lat": 36.3992004395,
      "lon": 25.4792995453,
      "tz": "Europe\/Athens"
  },
  "LGST": {
      "icao": "LGST",
      "iata": "JSH",
      "name": "Sitia Airport",
      "city": "Crete Island",
      "state": "Crete",
      "country": "GR",
      "elevation": 376,
      "lat": 35.2160987854,
      "lon": 26.1012992859,
      "tz": "Europe\/Athens"
  },
  "LGSV": {
      "icao": "LGSV",
      "iata": "",
      "name": "Stefanovikion Airport",
      "city": "Stefanovilion",
      "state": "Thessaly",
      "country": "GR",
      "elevation": 146,
      "lat": 39.4799995422,
      "lon": 22.76720047,
      "tz": "Europe\/Athens"
  },
  "LGSY": {
      "icao": "LGSY",
      "iata": "SKU",
      "name": "Skiros Airport",
      "city": "Skiros Island",
      "state": "Central-Greece",
      "country": "GR",
      "elevation": 44,
      "lat": 38.9676017761,
      "lon": 24.4871997833,
      "tz": "Europe\/Athens"
  },
  "LGTG": {
      "icao": "LGTG",
      "iata": "",
      "name": "Tanagra Airport",
      "city": "Tanagra",
      "state": "Central-Greece",
      "country": "GR",
      "elevation": 485,
      "lat": 38.3398017883,
      "lon": 23.5650005341,
      "tz": "Europe\/Athens"
  },
  "LGTL": {
      "icao": "LGTL",
      "iata": "",
      "name": "Kasteli Airport",
      "city": "Crete Island",
      "state": "Crete",
      "country": "GR",
      "elevation": 1180,
      "lat": 35.1920013428,
      "lon": 25.3269996643,
      "tz": "Europe\/Athens"
  },
  "LGTP": {
      "icao": "LGTP",
      "iata": "",
      "name": "Tripolis Airport",
      "city": "Tripoli",
      "state": "Peloponnese",
      "country": "GR",
      "elevation": 2113,
      "lat": 37.5306015015,
      "lon": 22.4036006927,
      "tz": "Europe\/Athens"
  },
  "LGTS": {
      "icao": "LGTS",
      "iata": "SKG",
      "name": "Thessaloniki Macedonia International Airport",
      "city": "Thessaloniki",
      "state": "Central-Macedonia",
      "country": "GR",
      "elevation": 22,
      "lat": 40.5196990967,
      "lon": 22.9708995819,
      "tz": "Europe\/Athens"
  },
  "LGTT": {
      "icao": "LGTT",
      "iata": "",
      "name": "Tatoi Airport",
      "city": "Tatoi",
      "state": "Attica",
      "country": "GR",
      "elevation": 785,
      "lat": 38.1088981628,
      "lon": 23.7838001251,
      "tz": "Europe\/Athens"
  },
  "LGZA": {
      "icao": "LGZA",
      "iata": "ZTH",
      "name": "Dionysios Solomos Airport",
      "city": "Zakynthos Island",
      "state": "Ionian-Islands",
      "country": "GR",
      "elevation": 15,
      "lat": 37.7509002686,
      "lon": 20.8843002319,
      "tz": "Europe\/Athens"
  },
  "LHBA": {
      "icao": "LHBA",
      "iata": "",
      "name": "Balassagyarmat Airport",
      "city": "Balassagyarmat",
      "state": "Nograd",
      "country": "HU",
      "elevation": 466,
      "lat": 48.078037262,
      "lon": 19.3217487335,
      "tz": "Europe\/Bratislava"
  },
  "LHBC": {
      "icao": "LHBC",
      "iata": "",
      "name": "Bekescsaba Glider Airport",
      "city": "Bekescsaba",
      "state": "Bekes",
      "country": "HU",
      "elevation": 279,
      "lat": 46.6758003235,
      "lon": 21.1622009277,
      "tz": "Europe\/Budapest"
  },
  "LHBP": {
      "icao": "LHBP",
      "iata": "BUD",
      "name": "Budapest Ferenc Liszt International Airport",
      "city": "Budapest",
      "state": "Budapest",
      "country": "HU",
      "elevation": 495,
      "lat": 47.4369010925,
      "lon": 19.2555999756,
      "tz": "Europe\/Budapest"
  },
  "LHBS": {
      "icao": "LHBS",
      "iata": "",
      "name": "Budaors Glider Airport",
      "city": "Budaors",
      "state": "Pest",
      "country": "HU",
      "elevation": 397,
      "lat": 47.4510993958,
      "lon": 18.9806003571,
      "tz": "Europe\/Budapest"
  },
  "LHDC": {
      "icao": "LHDC",
      "iata": "DEB",
      "name": "Debrecen International Airport",
      "city": "Debrecen",
      "state": "Hajdu-Bihar",
      "country": "HU",
      "elevation": 359,
      "lat": 47.488899231,
      "lon": 21.6152992249,
      "tz": "Europe\/Budapest"
  },
  "LHDK": {
      "icao": "LHDK",
      "iata": "",
      "name": "Dunakeszi Airport",
      "city": "Dunakeszi",
      "state": "Pest",
      "country": "HU",
      "elevation": 397,
      "lat": 47.613899231,
      "lon": 19.1432991028,
      "tz": "Europe\/Budapest"
  },
  "LHDV": {
      "icao": "LHDV",
      "iata": "",
      "name": "Dunaujvaros Glider Airport",
      "city": "Dunaujvaros",
      "state": "Fejer",
      "country": "HU",
      "elevation": 394,
      "lat": 46.8958015442,
      "lon": 18.9139003754,
      "tz": "Europe\/Budapest"
  },
  "LHEC": {
      "icao": "LHEC",
      "iata": "",
      "name": "Ersekcsanad Airport",
      "city": "Ersekcsanad",
      "state": "Bacs-Kiskun",
      "country": "HU",
      "elevation": 285,
      "lat": 46.2462425232,
      "lon": 18.9210224152,
      "tz": "Europe\/Budapest"
  },
  "LHEM": {
      "icao": "LHEM",
      "iata": "",
      "name": "Esztergom Airport",
      "city": "Esztergom",
      "state": "Komarom-Esztergom",
      "country": "HU",
      "elevation": 342,
      "lat": 47.7728004456,
      "lon": 18.7217006683,
      "tz": "Europe\/Bratislava"
  },
  "LHFH": {
      "icao": "LHFH",
      "iata": "",
      "name": "Farkashegy Airport",
      "city": "Farkashegy",
      "state": "Pest",
      "country": "HU",
      "elevation": 690,
      "lat": 47.4869003296,
      "lon": 18.9146995544,
      "tz": "Europe\/Budapest"
  },
  "LHFM": {
      "icao": "LHFM",
      "iata": "",
      "name": "Fertoszentmiklos Airport",
      "city": "Fertoszentmiklos",
      "state": "Gyor-Moson-Sopron",
      "country": "HU",
      "elevation": 440,
      "lat": 47.5828018188,
      "lon": 16.8453998566,
      "tz": "Europe\/Budapest"
  },
  "LHGD": {
      "icao": "LHGD",
      "iata": "",
      "name": "Godollo Airport",
      "city": "Godollo",
      "state": "Pest",
      "country": "HU",
      "elevation": 600,
      "lat": 47.5709991455,
      "lon": 19.3386001587,
      "tz": "Europe\/Budapest"
  },
  "LHGM": {
      "icao": "LHGM",
      "iata": "",
      "name": "Gyomai Airport",
      "city": "Gyomai",
      "state": "Bekes",
      "country": "HU",
      "elevation": 265,
      "lat": 46.9561653137,
      "lon": 20.8831233978,
      "tz": "Europe\/Budapest"
  },
  "LHGY": {
      "icao": "LHGY",
      "iata": "",
      "name": "Gyongyos Pipishegy Airport",
      "city": "Gyongyos Pipishegy",
      "state": "Heves",
      "country": "HU",
      "elevation": 1150,
      "lat": 47.8144989014,
      "lon": 19.9776000977,
      "tz": "Europe\/Budapest"
  },
  "LHHH": {
      "icao": "LHHH",
      "iata": "",
      "name": "Harmashatarhegy Airport",
      "city": "Budapest",
      "state": "Budapest",
      "country": "HU",
      "elevation": 960,
      "lat": 47.5503005981,
      "lon": 18.9787006378,
      "tz": "Europe\/Budapest"
  },
  "LHHO": {
      "icao": "LHHO",
      "iata": "",
      "name": "Hajduszoboszlo Airport",
      "city": "Hajduszoboszlo",
      "state": "Hajdu-Bihar",
      "country": "HU",
      "elevation": 335,
      "lat": 47.4592018127,
      "lon": 21.3910999298,
      "tz": "Europe\/Budapest"
  },
  "LHJK": {
      "icao": "LHJK",
      "iata": "",
      "name": "Jakabszallas Airport",
      "city": "Jakabszallas",
      "state": "Bacs-Kiskun",
      "country": "HU",
      "elevation": 348,
      "lat": 46.7472000122,
      "lon": 19.6056003571,
      "tz": "Europe\/Budapest"
  },
  "LHKD": {
      "icao": "LHKD",
      "iata": "",
      "name": "Kecsked Airport",
      "city": "Kecsked",
      "state": "Komarom-Esztergom",
      "country": "HU",
      "elevation": 570,
      "lat": 47.507900238,
      "lon": 18.3227005005,
      "tz": "Europe\/Budapest"
  },
  "LHKE": {
      "icao": "LHKE",
      "iata": "",
      "name": "Kecskemet Airport",
      "city": "Kecskemet",
      "state": "Bacs-Kiskun",
      "country": "HU",
      "elevation": 376,
      "lat": 46.9174995422,
      "lon": 19.7492008209,
      "tz": "Europe\/Budapest"
  },
  "LHKH": {
      "icao": "LHKH",
      "iata": "",
      "name": "Kiskunfelegyhaza Airport",
      "city": "Kiskunfelegyhaza",
      "state": "Bacs-Kiskun",
      "country": "HU",
      "elevation": 289,
      "lat": 46.7331008911,
      "lon": 19.8932991028,
      "tz": "Europe\/Budapest"
  },
  "LHKK": {
      "icao": "LHKK",
      "iata": "",
      "name": "Kiskunlachaza-airport",
      "city": "Budapest",
      "state": "Pest",
      "country": "HU",
      "elevation": 322,
      "lat": 47.1688852045,
      "lon": 19.0849685669,
      "tz": "Europe\/Budapest"
  },
  "LHKV": {
      "icao": "LHKV",
      "iata": "",
      "name": "Kaposvar Kaposujlak Glider Airport",
      "city": "Kaposujlak",
      "state": "Somogy",
      "country": "HU",
      "elevation": 499,
      "lat": 46.3863983154,
      "lon": 17.7339000702,
      "tz": "Europe\/Budapest"
  },
  "LHMC": {
      "icao": "LHMC",
      "iata": "MCQ",
      "name": "Miskolc Airport",
      "city": "Miskolc",
      "state": "Borsod-Abauj-Zemplen",
      "country": "HU",
      "elevation": 390,
      "lat": 48.1369018555,
      "lon": 20.7914009094,
      "tz": "Europe\/Budapest"
  },
  "LHMR": {
      "icao": "LHMR",
      "iata": "",
      "name": "Eger Maklar Airport",
      "city": "Eger",
      "state": "",
      "country": "HU",
      "elevation": 407,
      "lat": 47.8111000061,
      "lon": 20.4228000641,
      "tz": "Europe\/Budapest"
  },
  "LHNK": {
      "icao": "LHNK",
      "iata": "",
      "name": "Nagykanizsa Airport",
      "city": "Nagykanizsa",
      "state": "Zala",
      "country": "HU",
      "elevation": 466,
      "lat": 46.4320007859,
      "lon": 16.9581699371,
      "tz": "Europe\/Budapest"
  },
  "LHNY": {
      "icao": "LHNY",
      "iata": "",
      "name": "Nyiregyhaza Airport",
      "city": "Nyiregyhaza",
      "state": "Szabolcs-Szatmar-Bereg",
      "country": "HU",
      "elevation": 338,
      "lat": 47.9838981628,
      "lon": 21.6923007965,
      "tz": "Europe\/Budapest"
  },
  "LHOY": {
      "icao": "LHOY",
      "iata": "",
      "name": "Ocseny Airport",
      "city": "Ocseny",
      "state": "Tolna",
      "country": "HU",
      "elevation": 295,
      "lat": 46.3039016724,
      "lon": 18.7691993713,
      "tz": "Europe\/Budapest"
  },
  "LHPA": {
      "icao": "LHPA",
      "iata": "",
      "name": "Papa Airport",
      "city": "Papa",
      "state": "Veszprem",
      "country": "HU",
      "elevation": 466,
      "lat": 47.3636016846,
      "lon": 17.5007991791,
      "tz": "Europe\/Budapest"
  },
  "LHPP": {
      "icao": "LHPP",
      "iata": "PEV",
      "name": "Pecs-Pogany Airport",
      "city": "Pecs-Pogany",
      "state": "Baranya",
      "country": "HU",
      "elevation": 1000,
      "lat": 45.9908981323,
      "lon": 18.2409992218,
      "tz": "Europe\/Budapest"
  },
  "LHPR": {
      "icao": "LHPR",
      "iata": "QGY",
      "name": "Gyor-Per International Airport",
      "city": "Gyor",
      "state": "Gyor-Moson-Sopron",
      "country": "HU",
      "elevation": 424,
      "lat": 47.6244010925,
      "lon": 17.8136005402,
      "tz": "Europe\/Budapest"
  },
  "LHSA": {
      "icao": "LHSA",
      "iata": "",
      "name": "Szentkiralyszabadja Airport",
      "city": "Szentkiralyszabadja",
      "state": "Veszprem",
      "country": "HU",
      "elevation": 919,
      "lat": 47.0778999329,
      "lon": 17.9683990479,
      "tz": "Europe\/Budapest"
  },
  "LHSK": {
      "icao": "LHSK",
      "iata": "",
      "name": "Siofok-Kiliti Airport",
      "city": "Siofok-Kiliti",
      "state": "Somogy",
      "country": "HU",
      "elevation": 417,
      "lat": 46.8568992615,
      "lon": 18.0958003998,
      "tz": "Europe\/Budapest"
  },
  "LHSM": {
      "icao": "LHSM",
      "iata": "SOB",
      "name": "Sarmellek International Airport",
      "city": "Sarmellek",
      "state": "Zala",
      "country": "HU",
      "elevation": 408,
      "lat": 46.6864013672,
      "lon": 17.1590995789,
      "tz": "Europe\/Budapest"
  },
  "LHSN": {
      "icao": "LHSN",
      "iata": "",
      "name": "Szolnok Airport",
      "city": "Szolnok",
      "state": "Jasz-Nagykun-Szolnok",
      "country": "HU",
      "elevation": 322,
      "lat": 47.1228981018,
      "lon": 20.2355003357,
      "tz": "Europe\/Budapest"
  },
  "LHSS": {
      "icao": "LHSS",
      "iata": "",
      "name": "Szolnok-Szandaszolos Sport Airport",
      "city": "Szolnok-Szandaszolos",
      "state": "Jasz-Nagykun-Szolnok",
      "country": "HU",
      "elevation": 266,
      "lat": 47.1444015503,
      "lon": 20.1977996826,
      "tz": "Europe\/Budapest"
  },
  "LHSY": {
      "icao": "LHSY",
      "iata": "",
      "name": "Szombathely Info Airport",
      "city": "Szombathely",
      "state": "Vas",
      "country": "HU",
      "elevation": 732,
      "lat": 47.2799987793,
      "lon": 16.6263999939,
      "tz": "Europe\/Budapest"
  },
  "LHSZ": {
      "icao": "LHSZ",
      "iata": "",
      "name": "Szentes Aerodrome",
      "city": "Szentes",
      "state": "Csongrad",
      "country": "HU",
      "elevation": 259,
      "lat": 46.6133003235,
      "lon": 20.2828006744,
      "tz": "Europe\/Budapest"
  },
  "LHTA": {
      "icao": "LHTA",
      "iata": "TZR",
      "name": "Taszar Air Base",
      "city": "Taszar",
      "state": "Somogy",
      "country": "HU",
      "elevation": 531,
      "lat": 46.3931007385,
      "lon": 17.9174995422,
      "tz": "Europe\/Budapest"
  },
  "LHTL": {
      "icao": "LHTL",
      "iata": "",
      "name": "Tokol Air Base",
      "city": "Tokol",
      "state": "Pest",
      "country": "HU",
      "elevation": 318,
      "lat": 47.3452987671,
      "lon": 18.9808006287,
      "tz": "Europe\/Budapest"
  },
  "LHUD": {
      "icao": "LHUD",
      "iata": "QZD",
      "name": "Szeged Glider Airport",
      "city": "Szeged",
      "state": "Csongrad",
      "country": "HU",
      "elevation": 249,
      "lat": 46.2468986511,
      "lon": 20.0907993317,
      "tz": "Europe\/Budapest"
  },
  "LHZA": {
      "icao": "LHZA",
      "iata": "",
      "name": "Zalaegerszeg Airport",
      "city": "Zalaegerszeg",
      "state": "Zala",
      "country": "HU",
      "elevation": 617,
      "lat": 46.8847007751,
      "lon": 16.7889003754,
      "tz": "Europe\/Budapest"
  },
  "LIAF": {
      "icao": "LIAF",
      "iata": "",
      "name": "Foligno Airport",
      "city": "Foligno",
      "state": "Umbria",
      "country": "IT",
      "elevation": 730,
      "lat": 42.932800293,
      "lon": 12.710100174,
      "tz": "Europe\/Rome"
  },
  "LIAP": {
      "icao": "LIAP",
      "iata": "QAQ",
      "name": "L'Aquila \/ Preturo Airport",
      "city": "L'Aquila",
      "state": "Abruzzo",
      "country": "IT",
      "elevation": 2211,
      "lat": 42.379901886,
      "lon": 13.3092002869,
      "tz": "Europe\/Rome"
  },
  "LIAQ": {
      "icao": "LIAQ",
      "iata": "",
      "name": "Aquino Airport",
      "city": "Aquino",
      "state": "Latium",
      "country": "IT",
      "elevation": 361,
      "lat": 41.4863014221,
      "lon": 13.7187995911,
      "tz": "Europe\/Rome"
  },
  "LIAU": {
      "icao": "LIAU",
      "iata": "",
      "name": "Capua Airport",
      "city": "Capua",
      "state": "Campania",
      "country": "IT",
      "elevation": 64,
      "lat": 41.1160011292,
      "lon": 14.1780004501,
      "tz": "Europe\/Rome"
  },
  "LIBA": {
      "icao": "LIBA",
      "iata": "",
      "name": "Amendola Airport",
      "city": "Foggia",
      "state": "Apulia",
      "country": "IT",
      "elevation": 183,
      "lat": 41.5414009094,
      "lon": 15.7180995941,
      "tz": "Europe\/Rome"
  },
  "LIBC": {
      "icao": "LIBC",
      "iata": "CRV",
      "name": "Crotone Airport",
      "city": "Crotone",
      "state": "Calabria",
      "country": "IT",
      "elevation": 522,
      "lat": 38.9972000122,
      "lon": 17.0802001953,
      "tz": "Europe\/Rome"
  },
  "LIBD": {
      "icao": "LIBD",
      "iata": "BRI",
      "name": "Bari \/ Palese International Airport",
      "city": "Bari",
      "state": "Apulia",
      "country": "IT",
      "elevation": 177,
      "lat": 41.1389007568,
      "lon": 16.7605991364,
      "tz": "Europe\/Rome"
  },
  "LIBF": {
      "icao": "LIBF",
      "iata": "FOG",
      "name": "Foggia \/ Gino Lisa Airport",
      "city": "Foggia",
      "state": "Apulia",
      "country": "IT",
      "elevation": 265,
      "lat": 41.4328994751,
      "lon": 15.5349998474,
      "tz": "Europe\/Rome"
  },
  "LIBG": {
      "icao": "LIBG",
      "iata": "TAR",
      "name": "Taranto \/ Grottaglie Airport",
      "city": "Grottaglie",
      "state": "Apulia",
      "country": "IT",
      "elevation": 215,
      "lat": 40.5175018311,
      "lon": 17.4032001495,
      "tz": "Europe\/Rome"
  },
  "LIBN": {
      "icao": "LIBN",
      "iata": "LCC",
      "name": "Lecce Airport",
      "city": "",
      "state": "Apulia",
      "country": "IT",
      "elevation": 156,
      "lat": 40.239200592,
      "lon": 18.1333007813,
      "tz": "Europe\/Rome"
  },
  "LIBP": {
      "icao": "LIBP",
      "iata": "PSR",
      "name": "Pescara International Airport",
      "city": "Pescara",
      "state": "Abruzzo",
      "country": "IT",
      "elevation": 48,
      "lat": 42.4317016602,
      "lon": 14.1810998917,
      "tz": "Europe\/Rome"
  },
  "LIBR": {
      "icao": "LIBR",
      "iata": "BDS",
      "name": "Brindisi \/ Casale Airport",
      "city": "Brindisi",
      "state": "Apulia",
      "country": "IT",
      "elevation": 47,
      "lat": 40.6576004028,
      "lon": 17.9470005035,
      "tz": "Europe\/Rome"
  },
  "LIBV": {
      "icao": "LIBV",
      "iata": "",
      "name": "Gioia Del Colle Airport",
      "city": "Gioia Del Colle",
      "state": "Apulia",
      "country": "IT",
      "elevation": 1187,
      "lat": 40.7677993774,
      "lon": 16.9333000183,
      "tz": "Europe\/Rome"
  },
  "LIBX": {
      "icao": "LIBX",
      "iata": "",
      "name": "Martina Franca Air Force Base",
      "city": "Martina Franca",
      "state": "Apulia",
      "country": "IT",
      "elevation": 1467,
      "lat": 40.7000007629,
      "lon": 17.3330001831,
      "tz": "Europe\/Rome"
  },
  "LICA": {
      "icao": "LICA",
      "iata": "SUF",
      "name": "Lamezia Terme Airport",
      "city": "Lamezia Terme",
      "state": "Calabria",
      "country": "IT",
      "elevation": 39,
      "lat": 38.9053993225,
      "lon": 16.2423000336,
      "tz": "Europe\/Rome"
  },
  "LICB": {
      "icao": "LICB",
      "iata": "CIY",
      "name": "Comiso Airport Vincenzo Magliocco",
      "city": "Comiso",
      "state": "Sicily",
      "country": "IT",
      "elevation": 623,
      "lat": 36.9946010208,
      "lon": 14.6071815491,
      "tz": "Europe\/Rome"
  },
  "LICC": {
      "icao": "LICC",
      "iata": "CTA",
      "name": "Catania \/ Fontanarossa Airport",
      "city": "Catania",
      "state": "Sicily",
      "country": "IT",
      "elevation": 39,
      "lat": 37.4668006897,
      "lon": 15.0663995743,
      "tz": "Europe\/Rome"
  },
  "LICD": {
      "icao": "LICD",
      "iata": "LMP",
      "name": "Lampedusa Airport",
      "city": "Lampedusa",
      "state": "Sicily",
      "country": "IT",
      "elevation": 70,
      "lat": 35.4978981018,
      "lon": 12.6181001663,
      "tz": "Europe\/Rome"
  },
  "LICG": {
      "icao": "LICG",
      "iata": "PNL",
      "name": "Pantelleria Airport",
      "city": "Pantelleria",
      "state": "Sicily",
      "country": "IT",
      "elevation": 628,
      "lat": 36.8165016174,
      "lon": 11.9688997269,
      "tz": "Europe\/Rome"
  },
  "LICJ": {
      "icao": "LICJ",
      "iata": "PMO",
      "name": "Palermo \/ Punta Raisi Airport",
      "city": "Palermo",
      "state": "Sicily",
      "country": "IT",
      "elevation": 65,
      "lat": 38.1759986877,
      "lon": 13.0909996033,
      "tz": "Europe\/Rome"
  },
  "LICP": {
      "icao": "LICP",
      "iata": "",
      "name": "Palermo \/ Bocca Di Falco Airport",
      "city": "Palermo",
      "state": "Sicily",
      "country": "IT",
      "elevation": 345,
      "lat": 38.1108016968,
      "lon": 13.3133001328,
      "tz": "Europe\/Rome"
  },
  "LICR": {
      "icao": "LICR",
      "iata": "REG",
      "name": "Reggio Calabria Airport",
      "city": "Reggio Calabria",
      "state": "Calabria",
      "country": "IT",
      "elevation": 96,
      "lat": 38.0712013245,
      "lon": 15.651599884,
      "tz": "Europe\/Rome"
  },
  "LICT": {
      "icao": "LICT",
      "iata": "TPS",
      "name": "Trapani \/ Birgi Airport",
      "city": "Trapani",
      "state": "Sicily",
      "country": "IT",
      "elevation": 25,
      "lat": 37.9113998413,
      "lon": 12.4879999161,
      "tz": "Europe\/Rome"
  },
  "LICZ": {
      "icao": "LICZ",
      "iata": "NSY",
      "name": "Sigonella Airport",
      "city": "",
      "state": "Sicily",
      "country": "IT",
      "elevation": 79,
      "lat": 37.4016990662,
      "lon": 14.9224004745,
      "tz": "Europe\/Rome"
  },
  "LIDA": {
      "icao": "LIDA",
      "iata": "",
      "name": "Asiago Airport",
      "city": "Vicenza",
      "state": "Veneto",
      "country": "IT",
      "elevation": 3409,
      "lat": 45.8869018555,
      "lon": 11.5169000626,
      "tz": "Europe\/Rome"
  },
  "LIDB": {
      "icao": "LIDB",
      "iata": "BLX",
      "name": "Belluno Airport",
      "city": "Belluno",
      "state": "Veneto",
      "country": "IT",
      "elevation": 1240,
      "lat": 46.1674995422,
      "lon": 12.2479000092,
      "tz": "Europe\/Rome"
  },
  "LIDC": {
      "icao": "LIDC",
      "iata": "",
      "name": "Ca Negra Airport",
      "city": "Ca Negra",
      "state": "Veneto",
      "country": "IT",
      "elevation": 1,
      "lat": 45.0830001831,
      "lon": 12.1499996185,
      "tz": "Europe\/Rome"
  },
  "LIDE": {
      "icao": "LIDE",
      "iata": "",
      "name": "Reggio Emilia Airport",
      "city": "Reggio Emilia",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 152,
      "lat": 44.698299408,
      "lon": 10.6627998352,
      "tz": "Europe\/Rome"
  },
  "LIDF": {
      "icao": "LIDF",
      "iata": "",
      "name": "Fano Airport",
      "city": "Fano",
      "state": "The-Marches",
      "country": "IT",
      "elevation": 54,
      "lat": 43.8260002136,
      "lon": 13.0275001526,
      "tz": "Europe\/Rome"
  },
  "LIDG": {
      "icao": "LIDG",
      "iata": "",
      "name": "Lugo Di Romagna Airport",
      "city": "Lugo",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 62,
      "lat": 44.3982009888,
      "lon": 11.8548002243,
      "tz": "Europe\/Rome"
  },
  "LIDH": {
      "icao": "LIDH",
      "iata": "",
      "name": "Thiene Airport",
      "city": "Vicenza",
      "state": "Veneto",
      "country": "IT",
      "elevation": 335,
      "lat": 45.6757011414,
      "lon": 11.4965000153,
      "tz": "Europe\/Rome"
  },
  "LIDI": {
      "icao": "LIDI",
      "iata": "CDF",
      "name": "Cortina Airport",
      "city": "Cortina D'Ampezzo",
      "state": "Veneto",
      "country": "IT",
      "elevation": 4213,
      "lat": 46.5730018616,
      "lon": 12.1180000305,
      "tz": "Europe\/Rome"
  },
  "LIDL": {
      "icao": "LIDL",
      "iata": "",
      "name": "Legnago Airport",
      "city": "Legnago",
      "state": "Veneto",
      "country": "IT",
      "elevation": 30,
      "lat": 45.1332015991,
      "lon": 11.2922000885,
      "tz": "Europe\/Rome"
  },
  "LIDM": {
      "icao": "LIDM",
      "iata": "",
      "name": "Mantova Airport",
      "city": "Mantova",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 57,
      "lat": 45.1358337402,
      "lon": 10.7944440842,
      "tz": "Europe\/Rome"
  },
  "LIDP": {
      "icao": "LIDP",
      "iata": "",
      "name": "Pavullo Airport",
      "city": "Pavullo",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 2244,
      "lat": 44.3223991394,
      "lon": 10.831700325,
      "tz": "Europe\/Rome"
  },
  "LIDR": {
      "icao": "LIDR",
      "iata": "RAN",
      "name": "Ravenna Airport",
      "city": "Ravenna",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 1,
      "lat": 44.3642997742,
      "lon": 12.2249002457,
      "tz": "Europe\/Rome"
  },
  "LIDT": {
      "icao": "LIDT",
      "iata": "ZIA",
      "name": "Trento \/ Mattarello Airport",
      "city": "Trento",
      "state": "Trentino-Alto-Adige",
      "country": "IT",
      "elevation": 610,
      "lat": 46.0214004517,
      "lon": 11.1241998672,
      "tz": "Europe\/Rome"
  },
  "LIDU": {
      "icao": "LIDU",
      "iata": "",
      "name": "Carpi \/ Budrione Airport",
      "city": "Modena",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 69,
      "lat": 44.8352012634,
      "lon": 10.8716001511,
      "tz": "Europe\/Rome"
  },
  "LIDV": {
      "icao": "LIDV",
      "iata": "",
      "name": "Prati Vecchi D'Aguscello Airport",
      "city": "Ferrara",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 20,
      "lat": 44.7902984619,
      "lon": 11.6691999435,
      "tz": "Europe\/Rome"
  },
  "LIDW": {
      "icao": "LIDW",
      "iata": "",
      "name": "Salgareda Carrer Airport",
      "city": "Salgareda",
      "state": "Veneto",
      "country": "IT",
      "elevation": 7,
      "lat": 45.6991996765,
      "lon": 12.5431003571,
      "tz": "Europe\/Rome"
  },
  "LIEA": {
      "icao": "LIEA",
      "iata": "AHO",
      "name": "Alghero \/ Fertilia Airport",
      "city": "Alghero",
      "state": "Sardinia",
      "country": "IT",
      "elevation": 87,
      "lat": 40.6320991516,
      "lon": 8.290769577,
      "tz": "Europe\/Rome"
  },
  "LIED": {
      "icao": "LIED",
      "iata": "DCI",
      "name": "Decimomannu Airport",
      "city": "Decimomannu",
      "state": "Sardinia",
      "country": "IT",
      "elevation": 100,
      "lat": 39.3541984558,
      "lon": 8.9724798203,
      "tz": "Europe\/Rome"
  },
  "LIEE": {
      "icao": "LIEE",
      "iata": "CAG",
      "name": "Cagliari \/ Elmas Airport",
      "city": "Cagliari",
      "state": "Sardinia",
      "country": "IT",
      "elevation": 13,
      "lat": 39.251499176,
      "lon": 9.0542802811,
      "tz": "Europe\/Rome"
  },
  "LIEO": {
      "icao": "LIEO",
      "iata": "OLB",
      "name": "Olbia \/ Costa Smeralda Airport",
      "city": "Olbia",
      "state": "Sardinia",
      "country": "IT",
      "elevation": 37,
      "lat": 40.8987007141,
      "lon": 9.5176296234,
      "tz": "Europe\/Rome"
  },
  "LIER": {
      "icao": "LIER",
      "iata": "FNU",
      "name": "Oristano \/ Fenosu Airport",
      "city": "Oristano",
      "state": "Sardinia",
      "country": "IT",
      "elevation": 36,
      "lat": 39.8942985535,
      "lon": 8.6403999329,
      "tz": "Europe\/Rome"
  },
  "LIET": {
      "icao": "LIET",
      "iata": "TTB",
      "name": "Tortoli' \/ Arbatax Airport",
      "city": "Arbatax",
      "state": "Sardinia",
      "country": "IT",
      "elevation": 23,
      "lat": 39.918800354,
      "lon": 9.6829795837,
      "tz": "Europe\/Rome"
  },
  "LILA": {
      "icao": "LILA",
      "iata": "",
      "name": "Alessandria Airport",
      "city": "Alessandria",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 299,
      "lat": 44.925201416,
      "lon": 8.6251296997,
      "tz": "Europe\/Rome"
  },
  "LILB": {
      "icao": "LILB",
      "iata": "",
      "name": "Alzate Brianza Airport",
      "city": "Como",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 1260,
      "lat": 45.770198822,
      "lon": 9.1607198715,
      "tz": "Europe\/Rome"
  },
  "LILC": {
      "icao": "LILC",
      "iata": "",
      "name": "Calcinate Del Pesce Airport",
      "city": "Varese",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 797,
      "lat": 45.8097000122,
      "lon": 8.7680597305,
      "tz": "Europe\/Rome"
  },
  "LILE": {
      "icao": "LILE",
      "iata": "",
      "name": "Biella \/ Cerrione Airport",
      "city": "Biella",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 920,
      "lat": 45.495300293,
      "lon": 8.1027803421,
      "tz": "Europe\/Rome"
  },
  "LILG": {
      "icao": "LILG",
      "iata": "",
      "name": "Vergiate Airport",
      "city": "Milano",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 863,
      "lat": 45.7131996155,
      "lon": 8.6991195679,
      "tz": "Europe\/Rome"
  },
  "LILH": {
      "icao": "LILH",
      "iata": "",
      "name": "Voghera-Rivanazzano Airport",
      "city": "Voghera",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 423,
      "lat": 44.9519996643,
      "lon": 9.0165300369,
      "tz": "Europe\/Rome"
  },
  "LILI": {
      "icao": "LILI",
      "iata": "",
      "name": "Vercelli Airport",
      "city": "Vercelli",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 417,
      "lat": 45.3111991882,
      "lon": 8.4174203873,
      "tz": "Europe\/Rome"
  },
  "LILM": {
      "icao": "LILM",
      "iata": "",
      "name": "Casale Monferrato Airport",
      "city": "Casale Monferrato",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 377,
      "lat": 45.1111984253,
      "lon": 8.456029892,
      "tz": "Europe\/Rome"
  },
  "LILN": {
      "icao": "LILN",
      "iata": "QVA",
      "name": "Varese \/ Venegono Airport",
      "city": "Varese",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 1050,
      "lat": 45.7421989441,
      "lon": 8.8882303238,
      "tz": "Europe\/Rome"
  },
  "LILO": {
      "icao": "LILO",
      "iata": "",
      "name": "Sondrio Caiolo Airport",
      "city": "Caiolo",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 899,
      "lat": 46.1542015076,
      "lon": 9.7980604172,
      "tz": "Europe\/Rome"
  },
  "LILQ": {
      "icao": "LILQ",
      "iata": "QMM",
      "name": "Massa Cinquale Airport",
      "city": "Marina Di Massa",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 13,
      "lat": 43.986000061,
      "lon": 10.1428003311,
      "tz": "Europe\/Rome"
  },
  "LILR": {
      "icao": "LILR",
      "iata": "",
      "name": "Cremona \/ Migliaro Airport",
      "city": "Cremona",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 161,
      "lat": 45.1674003601,
      "lon": 10.0018997192,
      "tz": "Europe\/Rome"
  },
  "LILV": {
      "icao": "LILV",
      "iata": "",
      "name": "Valbrembo Airport",
      "city": "Bergamo",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 745,
      "lat": 45.7207984924,
      "lon": 9.5934696198,
      "tz": "Europe\/Rome"
  },
  "LIMA": {
      "icao": "LIMA",
      "iata": "",
      "name": "Torino \/ Aeritalia Airport",
      "city": "Torino",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 943,
      "lat": 45.0863990784,
      "lon": 7.6033701897,
      "tz": "Europe\/Rome"
  },
  "LIMB": {
      "icao": "LIMB",
      "iata": "",
      "name": "Milano \/ Bresso Airport",
      "city": "Milano",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 484,
      "lat": 45.5421981812,
      "lon": 9.20333004,
      "tz": "Europe\/Rome"
  },
  "LIMC": {
      "icao": "LIMC",
      "iata": "MXP",
      "name": "Malpensa International Airport",
      "city": "Milan",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 768,
      "lat": 45.6305999756,
      "lon": 8.7281103134,
      "tz": "Europe\/Rome"
  },
  "LIME": {
      "icao": "LIME",
      "iata": "BGY",
      "name": "Bergamo \/ Orio Al Serio Airport",
      "city": "Bergamo",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 782,
      "lat": 45.6739006042,
      "lon": 9.7041702271,
      "tz": "Europe\/Rome"
  },
  "LIMF": {
      "icao": "LIMF",
      "iata": "TRN",
      "name": "Torino \/ Caselle International Airport",
      "city": "Torino",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 989,
      "lat": 45.2008018494,
      "lon": 7.6496300697,
      "tz": "Europe\/Rome"
  },
  "LIMG": {
      "icao": "LIMG",
      "iata": "ALL",
      "name": "Villanova D'Albenga International Airport",
      "city": "Albenga",
      "state": "Liguria",
      "country": "IT",
      "elevation": 148,
      "lat": 44.0505981445,
      "lon": 8.1274299622,
      "tz": "Europe\/Rome"
  },
  "LIMJ": {
      "icao": "LIMJ",
      "iata": "GOA",
      "name": "Genova \/ Sestri Cristoforo Colombo Airport",
      "city": "Genova",
      "state": "Liguria",
      "country": "IT",
      "elevation": 13,
      "lat": 44.4132995605,
      "lon": 8.8374996185,
      "tz": "Europe\/Rome"
  },
  "LIML": {
      "icao": "LIML",
      "iata": "LIN",
      "name": "Linate Airport",
      "city": "Milan",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 353,
      "lat": 45.445098877,
      "lon": 9.2767400742,
      "tz": "Europe\/Rome"
  },
  "LIMN": {
      "icao": "LIMN",
      "iata": "",
      "name": "Cameri Airport",
      "city": "Cameri",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 586,
      "lat": 45.5295982361,
      "lon": 8.6692199707,
      "tz": "Europe\/Rome"
  },
  "LIMP": {
      "icao": "LIMP",
      "iata": "PMF",
      "name": "Parma Airport",
      "city": "Parma",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 161,
      "lat": 44.8245010376,
      "lon": 10.2964000702,
      "tz": "Europe\/Rome"
  },
  "LIMR": {
      "icao": "LIMR",
      "iata": "",
      "name": "Novi Ligure Glider Airport",
      "city": "Novi Ligure",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 607,
      "lat": 44.7799987793,
      "lon": 8.7863903046,
      "tz": "Europe\/Rome"
  },
  "LIMS": {
      "icao": "LIMS",
      "iata": "QPZ",
      "name": "Piacenza Airport",
      "city": "Piacenza",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 456,
      "lat": 44.9131011963,
      "lon": 9.7233295441,
      "tz": "Europe\/Rome"
  },
  "LIMW": {
      "icao": "LIMW",
      "iata": "AOT",
      "name": "Aosta Airport",
      "city": "Aosta",
      "state": "Aosta-Valley",
      "country": "IT",
      "elevation": 1791,
      "lat": 45.7384986877,
      "lon": 7.3687200546,
      "tz": "Europe\/Rome"
  },
  "LIMZ": {
      "icao": "LIMZ",
      "iata": "CUF",
      "name": "Cuneo \/ Levaldigi Airport",
      "city": "Cuneo",
      "state": "Piedmont",
      "country": "IT",
      "elevation": 1267,
      "lat": 44.547000885,
      "lon": 7.6232199669,
      "tz": "Europe\/Rome"
  },
  "LINL": {
      "icao": "LINL",
      "iata": "",
      "name": "Lecce \/ Lepore Airport",
      "city": "Lecce",
      "state": "Apulia",
      "country": "IT",
      "elevation": 59,
      "lat": 40.3576011658,
      "lon": 18.2938995361,
      "tz": "Europe\/Rome"
  },
  "LIPA": {
      "icao": "LIPA",
      "iata": "AVB",
      "name": "Aviano Air Base",
      "city": "Aviano",
      "state": "Friuli-Venezia-Giulia",
      "country": "IT",
      "elevation": 410,
      "lat": 46.0318984985,
      "lon": 12.5965003967,
      "tz": "Europe\/Rome"
  },
  "LIPB": {
      "icao": "LIPB",
      "iata": "BZO",
      "name": "Bolzano Airport",
      "city": "Bolzano",
      "state": "Trentino-Alto-Adige",
      "country": "IT",
      "elevation": 789,
      "lat": 46.4602012634,
      "lon": 11.3263998032,
      "tz": "Europe\/Rome"
  },
  "LIPC": {
      "icao": "LIPC",
      "iata": "",
      "name": "Cervia Air Force Base",
      "city": "Cervia",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 18,
      "lat": 44.2242012024,
      "lon": 12.3072004318,
      "tz": "Europe\/Rome"
  },
  "LIPD": {
      "icao": "LIPD",
      "iata": "UDN",
      "name": "Udine \/ Campoformido Air Base",
      "city": "Udine",
      "state": "Friuli-Venezia-Giulia",
      "country": "IT",
      "elevation": 305,
      "lat": 46.0321998596,
      "lon": 13.1868000031,
      "tz": "Europe\/Rome"
  },
  "LIPE": {
      "icao": "LIPE",
      "iata": "BLQ",
      "name": "Bologna \/ Borgo Panigale Airport",
      "city": "Bologna",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 123,
      "lat": 44.5354003906,
      "lon": 11.2887001038,
      "tz": "Europe\/Rome"
  },
  "LIPF": {
      "icao": "LIPF",
      "iata": "",
      "name": "Ferrara Airport",
      "city": "Ferrara",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 20,
      "lat": 44.8160018921,
      "lon": 11.6134004593,
      "tz": "Europe\/Rome"
  },
  "LIPG": {
      "icao": "LIPG",
      "iata": "",
      "name": "Gorizia Airport",
      "city": "Gorizia",
      "state": "Friuli-Venezia-Giulia",
      "country": "IT",
      "elevation": 207,
      "lat": 45.9068984985,
      "lon": 13.5993003845,
      "tz": "Europe\/Ljubljana"
  },
  "LIPH": {
      "icao": "LIPH",
      "iata": "TSF",
      "name": "Treviso \/ Sant'Angelo Airport",
      "city": "Treviso",
      "state": "Veneto",
      "country": "IT",
      "elevation": 59,
      "lat": 45.648399353,
      "lon": 12.1943998337,
      "tz": "Europe\/Rome"
  },
  "LIPI": {
      "icao": "LIPI",
      "iata": "",
      "name": "Rivolto Air Base",
      "city": "Codroipo",
      "state": "Friuli-Venezia-Giulia",
      "country": "IT",
      "elevation": 179,
      "lat": 45.9786987305,
      "lon": 13.0493001938,
      "tz": "Europe\/Rome"
  },
  "LIPK": {
      "icao": "LIPK",
      "iata": "FRL",
      "name": "Forli Airport",
      "city": "Forli",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 97,
      "lat": 44.1948013306,
      "lon": 12.0700998306,
      "tz": "Europe\/Rome"
  },
  "LIPL": {
      "icao": "LIPL",
      "iata": "",
      "name": "Ghedi Airport",
      "city": "Ghedi",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 333,
      "lat": 45.4322013855,
      "lon": 10.2677001953,
      "tz": "Europe\/Rome"
  },
  "LIPM": {
      "icao": "LIPM",
      "iata": "",
      "name": "Modena \/ Marzaglia Airport",
      "city": "Modena",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 184,
      "lat": 44.6348991394,
      "lon": 10.8101997375,
      "tz": "Europe\/Rome"
  },
  "LIPN": {
      "icao": "LIPN",
      "iata": "",
      "name": "Verona \/ Boscomantico Airport",
      "city": "Verona",
      "state": "Veneto",
      "country": "IT",
      "elevation": 286,
      "lat": 45.4720001221,
      "lon": 10.9279003143,
      "tz": "Europe\/Rome"
  },
  "LIPO": {
      "icao": "LIPO",
      "iata": "VBS",
      "name": "Brescia \/ Montichiari Airport",
      "city": "Brescia",
      "state": "Lombardy",
      "country": "IT",
      "elevation": 355,
      "lat": 45.4289016724,
      "lon": 10.3305997849,
      "tz": "Europe\/Rome"
  },
  "LIPQ": {
      "icao": "LIPQ",
      "iata": "TRS",
      "name": "Trieste \/ Ronchi Dei Legionari",
      "city": "Trieste",
      "state": "Friuli-Venezia-Giulia",
      "country": "IT",
      "elevation": 39,
      "lat": 45.8274993896,
      "lon": 13.4722003937,
      "tz": "Europe\/Rome"
  },
  "LIPR": {
      "icao": "LIPR",
      "iata": "RMI",
      "name": "Rimini \/ Miramare - Federico Fellini International Airport",
      "city": "Rimini",
      "state": "Emilia-Romagna",
      "country": "IT",
      "elevation": 40,
      "lat": 44.0203018188,
      "lon": 12.611700058,
      "tz": "Europe\/Rome"
  },
  "LIPS": {
      "icao": "LIPS",
      "iata": "",
      "name": "Istrana Airport",
      "city": "Istrana",
      "state": "Veneto",
      "country": "IT",
      "elevation": 137,
      "lat": 45.6848983765,
      "lon": 12.0829000473,
      "tz": "Europe\/Rome"
  },
  "LIPT": {
      "icao": "LIPT",
      "iata": "VIC",
      "name": "Vicenza Airport",
      "city": "Vicenza",
      "state": "Veneto",
      "country": "IT",
      "elevation": 128,
      "lat": 45.5733985901,
      "lon": 11.5295000076,
      "tz": "Europe\/Rome"
  },
  "LIPU": {
      "icao": "LIPU",
      "iata": "QPA",
      "name": "Padova Airport",
      "city": "Padova",
      "state": "Veneto",
      "country": "IT",
      "elevation": 44,
      "lat": 45.3958015442,
      "lon": 11.8479003906,
      "tz": "Europe\/Rome"
  },
  "LIPV": {
      "icao": "LIPV",
      "iata": "",
      "name": "Venezia \/ Lido - San Nicolo Airport",
      "city": "Venezia",
      "state": "Veneto",
      "country": "IT",
      "elevation": 13,
      "lat": 45.4282989502,
      "lon": 12.3880996704,
      "tz": "Europe\/Rome"
  },
  "LIPX": {
      "icao": "LIPX",
      "iata": "VRN",
      "name": "Verona \/ Villafranca Airport",
      "city": "Verona",
      "state": "Veneto",
      "country": "IT",
      "elevation": 239,
      "lat": 45.3956985474,
      "lon": 10.8885002136,
      "tz": "Europe\/Rome"
  },
  "LIPY": {
      "icao": "LIPY",
      "iata": "AOI",
      "name": "Ancona \/ Falconara Airport",
      "city": "Ancona",
      "state": "The-Marches",
      "country": "IT",
      "elevation": 49,
      "lat": 43.6162986755,
      "lon": 13.3622999191,
      "tz": "Europe\/Rome"
  },
  "LIPZ": {
      "icao": "LIPZ",
      "iata": "VCE",
      "name": "Venezia \/ Tessera -  Marco Polo Airport",
      "city": "Venezia",
      "state": "Veneto",
      "country": "IT",
      "elevation": 7,
      "lat": 45.5052986145,
      "lon": 12.3519001007,
      "tz": "Europe\/Rome"
  },
  "LIQB": {
      "icao": "LIQB",
      "iata": "QZO",
      "name": "Arezzo Airport",
      "city": "Arezzo",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 814,
      "lat": 43.4558982849,
      "lon": 11.8470001221,
      "tz": "Europe\/Rome"
  },
  "LIQL": {
      "icao": "LIQL",
      "iata": "LCV",
      "name": "Lucca \/ Tassignano Airport",
      "city": "Lucca",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 39,
      "lat": 43.8282012939,
      "lon": 10.5781002045,
      "tz": "Europe\/Rome"
  },
  "LIQN": {
      "icao": "LIQN",
      "iata": "QRT",
      "name": "Rieti Airport",
      "city": "Rieti",
      "state": "Latium",
      "country": "IT",
      "elevation": 1278,
      "lat": 42.4272003174,
      "lon": 12.8516998291,
      "tz": "Europe\/Rome"
  },
  "LIQS": {
      "icao": "LIQS",
      "iata": "SAY",
      "name": "Siena \/ Ampugnano Airport",
      "city": "Siena",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 634,
      "lat": 43.2563018799,
      "lon": 11.2550001144,
      "tz": "Europe\/Rome"
  },
  "LIQW": {
      "icao": "LIQW",
      "iata": "",
      "name": "Sarzana \/ Luni Military Airport",
      "city": "Sarzana",
      "state": "Liguria",
      "country": "IT",
      "elevation": 45,
      "lat": 44.0880012512,
      "lon": 9.987950325,
      "tz": "Europe\/Rome"
  },
  "LIRA": {
      "icao": "LIRA",
      "iata": "CIA",
      "name": "Ciampino Airport",
      "city": "Roma",
      "state": "Latium",
      "country": "IT",
      "elevation": 427,
      "lat": 41.7994003296,
      "lon": 12.5949001312,
      "tz": "Europe\/Rome"
  },
  "LIRE": {
      "icao": "LIRE",
      "iata": "",
      "name": "Pratica Di Mare Airport",
      "city": "Pomezia",
      "state": "Latium",
      "country": "IT",
      "elevation": 41,
      "lat": 41.654499054,
      "lon": 12.4451999664,
      "tz": "Europe\/Rome"
  },
  "LIRF": {
      "icao": "LIRF",
      "iata": "FCO",
      "name": "Leonardo Da Vinci (Fiumicino) International Airport",
      "city": "Rome",
      "state": "Latium",
      "country": "IT",
      "elevation": 15,
      "lat": 41.8045005798,
      "lon": 12.2508001328,
      "tz": "Europe\/Rome"
  },
  "LIRG": {
      "icao": "LIRG",
      "iata": "",
      "name": "Guidonia Airport",
      "city": "Guidonia",
      "state": "Latium",
      "country": "IT",
      "elevation": 289,
      "lat": 41.9902992249,
      "lon": 12.7407999039,
      "tz": "Europe\/Rome"
  },
  "LIRH": {
      "icao": "LIRH",
      "iata": "QFR",
      "name": "Frosinone Military Airport",
      "city": "Frosinone",
      "state": "Latium",
      "country": "IT",
      "elevation": 0,
      "lat": 41.6464004517,
      "lon": 13.2997999191,
      "tz": "Europe\/Rome"
  },
  "LIRI": {
      "icao": "LIRI",
      "iata": "QSR",
      "name": "Salerno \/ Pontecagnano Airport",
      "city": "Salerno",
      "state": "Campania",
      "country": "IT",
      "elevation": 119,
      "lat": 40.6203994751,
      "lon": 14.9112997055,
      "tz": "Europe\/Rome"
  },
  "LIRJ": {
      "icao": "LIRJ",
      "iata": "EBA",
      "name": "Marina Di Campo Airport",
      "city": "Marina  Di Campo",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 31,
      "lat": 42.7602996826,
      "lon": 10.23939991,
      "tz": "Europe\/Rome"
  },
  "LIRL": {
      "icao": "LIRL",
      "iata": "QLT",
      "name": "Latina Airport",
      "city": "Latina",
      "state": "Latium",
      "country": "IT",
      "elevation": 94,
      "lat": 41.5424003601,
      "lon": 12.9090003967,
      "tz": "Europe\/Rome"
  },
  "LIRM": {
      "icao": "LIRM",
      "iata": "",
      "name": "Grazzanise Airport",
      "city": "Caserta",
      "state": "Campania",
      "country": "IT",
      "elevation": 29,
      "lat": 41.060798645,
      "lon": 14.0818996429,
      "tz": "Europe\/Rome"
  },
  "LIRN": {
      "icao": "LIRN",
      "iata": "NAP",
      "name": "Napoli \/ Capodichino International Airport",
      "city": "Napoli",
      "state": "Campania",
      "country": "IT",
      "elevation": 294,
      "lat": 40.8860015869,
      "lon": 14.2908000946,
      "tz": "Europe\/Rome"
  },
  "LIRP": {
      "icao": "LIRP",
      "iata": "PSA",
      "name": "Pisa \/ San Giusto - Galileo Galilei International Airport",
      "city": "Pisa",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 6,
      "lat": 43.6838989258,
      "lon": 10.3927001953,
      "tz": "Europe\/Rome"
  },
  "LIRQ": {
      "icao": "LIRQ",
      "iata": "FLR",
      "name": "Firenze \/ Peretola Airport",
      "city": "Firenze",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 142,
      "lat": 43.8100013733,
      "lon": 11.2051000595,
      "tz": "Europe\/Rome"
  },
  "LIRS": {
      "icao": "LIRS",
      "iata": "GRS",
      "name": "Grosseto Airport",
      "city": "Grosetto",
      "state": "Tuscany",
      "country": "IT",
      "elevation": 17,
      "lat": 42.7597007751,
      "lon": 11.0719003677,
      "tz": "Europe\/Rome"
  },
  "LIRU": {
      "icao": "LIRU",
      "iata": "",
      "name": "Urbe Airport",
      "city": "Roma",
      "state": "Latium",
      "country": "IT",
      "elevation": 55,
      "lat": 41.9519004822,
      "lon": 12.4989004135,
      "tz": "Europe\/Rome"
  },
  "LIRV": {
      "icao": "LIRV",
      "iata": "",
      "name": "Viterbo Airport",
      "city": "Viterbo",
      "state": "Latium",
      "country": "IT",
      "elevation": 992,
      "lat": 42.4301986694,
      "lon": 12.0642004013,
      "tz": "Europe\/Rome"
  },
  "LIRZ": {
      "icao": "LIRZ",
      "iata": "PEG",
      "name": "Perugia \/ San Egidio Airport",
      "city": "Perugia",
      "state": "Umbria",
      "country": "IT",
      "elevation": 697,
      "lat": 43.0959014893,
      "lon": 12.5131998062,
      "tz": "Europe\/Rome"
  },
  "LJAJ": {
      "icao": "LJAJ",
      "iata": "",
      "name": "Ajdovscina Airport",
      "city": "Ajdovscina",
      "state": "Ajdovscina",
      "country": "SI",
      "elevation": 381,
      "lat": 45.8891983032,
      "lon": 13.8868999481,
      "tz": "Europe\/Ljubljana"
  },
  "LJBL": {
      "icao": "LJBL",
      "iata": "",
      "name": "Lesce Bled Glider Airport",
      "city": "Bled",
      "state": "Radovljica",
      "country": "SI",
      "elevation": 1654,
      "lat": 46.3572006226,
      "lon": 14.1738996506,
      "tz": "Europe\/Ljubljana"
  },
  "LJBO": {
      "icao": "LJBO",
      "iata": "",
      "name": "Bovec Letalisce Airport",
      "city": "Bovec",
      "state": "Bovec",
      "country": "SI",
      "elevation": 1417,
      "lat": 46.3286018372,
      "lon": 13.5486001968,
      "tz": "Europe\/Ljubljana"
  },
  "LJCE": {
      "icao": "LJCE",
      "iata": "",
      "name": "Cerklje Airport",
      "city": "",
      "state": "Brezice",
      "country": "SI",
      "elevation": 510,
      "lat": 45.9000015259,
      "lon": 15.5302000046,
      "tz": "Europe\/Ljubljana"
  },
  "LJCL": {
      "icao": "LJCL",
      "iata": "",
      "name": "Celje Glider Airport",
      "city": "Celje",
      "state": "Celje",
      "country": "SI",
      "elevation": 801,
      "lat": 46.2456016541,
      "lon": 15.2230997086,
      "tz": "Europe\/Ljubljana"
  },
  "LJDI": {
      "icao": "LJDI",
      "iata": "",
      "name": "Divaca Airport",
      "city": "Divaca",
      "state": "Divaca",
      "country": "SI",
      "elevation": 1420,
      "lat": 45.6819000244,
      "lon": 14.0027999878,
      "tz": "Europe\/Ljubljana"
  },
  "LJLJ": {
      "icao": "LJLJ",
      "iata": "LJU",
      "name": "Ljubljana Joze Pucnik Airport",
      "city": "Ljubljana",
      "state": "Cerklje-na-Gorenjskem",
      "country": "SI",
      "elevation": 1273,
      "lat": 46.2237014771,
      "lon": 14.4575996399,
      "tz": "Europe\/Ljubljana"
  },
  "LJMB": {
      "icao": "LJMB",
      "iata": "MBX",
      "name": "Maribor Airport",
      "city": "",
      "state": "Hoce-Slivnica",
      "country": "SI",
      "elevation": 876,
      "lat": 46.4799003601,
      "lon": 15.6861000061,
      "tz": "Europe\/Ljubljana"
  },
  "LJMS": {
      "icao": "LJMS",
      "iata": "",
      "name": "Murska Sobota Airport",
      "city": "Murska Sobota",
      "state": "Murska-Sobota",
      "country": "SI",
      "elevation": 600,
      "lat": 46.6293983459,
      "lon": 16.1749992371,
      "tz": "Europe\/Ljubljana"
  },
  "LJNM": {
      "icao": "LJNM",
      "iata": "",
      "name": "Novo Mesto Airport",
      "city": "Novo mesto",
      "state": "Novo-Mesto",
      "country": "SI",
      "elevation": 572,
      "lat": 45.8102989197,
      "lon": 15.1127004623,
      "tz": "Europe\/Ljubljana"
  },
  "LJPO": {
      "icao": "LJPO",
      "iata": "",
      "name": "Postojna Airfield",
      "city": "Postojna",
      "state": "Postojna",
      "country": "SI",
      "elevation": 0,
      "lat": 45.7522010803,
      "lon": 14.1947002411,
      "tz": "Europe\/Ljubljana"
  },
  "LJPT": {
      "icao": "LJPT",
      "iata": "",
      "name": "Ptuj Airport",
      "city": "",
      "state": "Gorisnica",
      "country": "SI",
      "elevation": 689,
      "lat": 46.4253005981,
      "lon": 15.9885997772,
      "tz": "Europe\/Ljubljana"
  },
  "LJPZ": {
      "icao": "LJPZ",
      "iata": "POW",
      "name": "Portoroz Airport",
      "city": "Portoroz",
      "state": "Piran-Pirano",
      "country": "SI",
      "elevation": 7,
      "lat": 45.473400116,
      "lon": 13.6149997711,
      "tz": "Europe\/Ljubljana"
  },
  "LJSG": {
      "icao": "LJSG",
      "iata": "",
      "name": "Slovenj Gradec Airport",
      "city": "Slovenc Gradec",
      "state": "Slovenj-Gradec",
      "country": "SI",
      "elevation": 1645,
      "lat": 46.4720001221,
      "lon": 15.1169996262,
      "tz": "Europe\/Ljubljana"
  },
  "LJVE": {
      "icao": "LJVE",
      "iata": "",
      "name": "Velenje Airport",
      "city": "Velenje",
      "state": "Sostanj",
      "country": "SI",
      "elevation": 1283,
      "lat": 46.3974990845,
      "lon": 15.04529953,
      "tz": "Europe\/Ljubljana"
  },
  "LKBA": {
      "icao": "LKBA",
      "iata": "",
      "name": "Breclav Airport",
      "city": "Breclav",
      "state": "South-Moravian",
      "country": "CZ",
      "elevation": 531,
      "lat": 48.7900009155,
      "lon": 16.8924999237,
      "tz": "Europe\/Prague"
  },
  "LKBE": {
      "icao": "LKBE",
      "iata": "",
      "name": "Benesov Airport",
      "city": "Benesov",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1319,
      "lat": 49.7407989502,
      "lon": 14.6447000504,
      "tz": "Europe\/Prague"
  },
  "LKBO": {
      "icao": "LKBO",
      "iata": "",
      "name": "Bohunovice Airport",
      "city": "Bohunovice",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 771,
      "lat": 49.6706008911,
      "lon": 17.2950000763,
      "tz": "Europe\/Prague"
  },
  "LKBR": {
      "icao": "LKBR",
      "iata": "",
      "name": "Broumov Airport",
      "city": "Broumov",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 1342,
      "lat": 50.5619010925,
      "lon": 16.3428001404,
      "tz": "Europe\/Prague"
  },
  "LKBU": {
      "icao": "LKBU",
      "iata": "",
      "name": "Bubovice Airport",
      "city": "Bubovice",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1401,
      "lat": 49.9743995667,
      "lon": 14.1780996323,
      "tz": "Europe\/Prague"
  },
  "LKCB": {
      "icao": "LKCB",
      "iata": "",
      "name": "Cheb Airport",
      "city": "Cheb",
      "state": "Karlovarsky",
      "country": "CZ",
      "elevation": 1585,
      "lat": 50.0661010742,
      "lon": 12.4117002487,
      "tz": "Europe\/Prague"
  },
  "LKCE": {
      "icao": "LKCE",
      "iata": "",
      "name": "Ceska Lipa Airport",
      "city": "Ceska Lipa",
      "state": "Liberecky",
      "country": "CZ",
      "elevation": 932,
      "lat": 50.709400177,
      "lon": 14.5666999817,
      "tz": "Europe\/Prague"
  },
  "LKCH": {
      "icao": "LKCH",
      "iata": "",
      "name": "Chomutov Airport",
      "city": "Chotumov",
      "state": "Ustecky",
      "country": "CZ",
      "elevation": 1132,
      "lat": 50.4688987732,
      "lon": 13.4680995941,
      "tz": "Europe\/Prague"
  },
  "LKCL": {
      "icao": "LKCL",
      "iata": "",
      "name": "Cernovice Air Base",
      "city": "Cernovice",
      "state": "South-Moravian",
      "country": "CZ",
      "elevation": 778,
      "lat": 49.1775016785,
      "lon": 16.6608009338,
      "tz": "Europe\/Prague"
  },
  "LKCM": {
      "icao": "LKCM",
      "iata": "",
      "name": "Medlanky Airport",
      "city": "Medlanky",
      "state": "South-Moravian",
      "country": "CZ",
      "elevation": 853,
      "lat": 49.2366981506,
      "lon": 16.5550003052,
      "tz": "Europe\/Prague"
  },
  "LKCO": {
      "icao": "LKCO",
      "iata": "",
      "name": "Chocen Airport",
      "city": "Chocen",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 873,
      "lat": 49.9772796631,
      "lon": 16.1849212646,
      "tz": "Europe\/Prague"
  },
  "LKCR": {
      "icao": "LKCR",
      "iata": "",
      "name": "Chrudim Airport",
      "city": "Chrudim",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 981,
      "lat": 49.9364013672,
      "lon": 15.7805995941,
      "tz": "Europe\/Prague"
  },
  "LKCS": {
      "icao": "LKCS",
      "iata": "",
      "name": "Ceske Budejovice Airport",
      "city": "Ceske Budejovice",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1417,
      "lat": 48.9463996887,
      "lon": 14.4274997711,
      "tz": "Europe\/Prague"
  },
  "LKCT": {
      "icao": "LKCT",
      "iata": "",
      "name": "Chotebor Airport",
      "city": "Chotebor",
      "state": "Vysocina",
      "country": "CZ",
      "elevation": 1949,
      "lat": 49.685798645,
      "lon": 15.6760997772,
      "tz": "Europe\/Prague"
  },
  "LKCV": {
      "icao": "LKCV",
      "iata": "",
      "name": "Caslav Air Base",
      "city": "Caslav",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 794,
      "lat": 49.9397010803,
      "lon": 15.3817996979,
      "tz": "Europe\/Prague"
  },
  "LKDK": {
      "icao": "LKDK",
      "iata": "",
      "name": "Dvur Kralove Nad Labem Airport",
      "city": "Dvur Kralove Nad Labem",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 932,
      "lat": 50.4141998291,
      "lon": 15.8368997574,
      "tz": "Europe\/Prague"
  },
  "LKER": {
      "icao": "LKER",
      "iata": "",
      "name": "Erpuzice Airport",
      "city": "Erpuzice",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 1572,
      "lat": 49.8027992249,
      "lon": 13.0381002426,
      "tz": "Europe\/Prague"
  },
  "LKFR": {
      "icao": "LKFR",
      "iata": "",
      "name": "Frydlant Airport",
      "city": "Frydlant",
      "state": "Moravskoslezsky",
      "country": "CZ",
      "elevation": 1440,
      "lat": 49.5894012451,
      "lon": 18.3791999817,
      "tz": "Europe\/Prague"
  },
  "LKHB": {
      "icao": "LKHB",
      "iata": "",
      "name": "Havlickuv Brod Airport",
      "city": "Havlickuv Brod",
      "state": "Vysocina",
      "country": "CZ",
      "elevation": 1519,
      "lat": 49.5971984863,
      "lon": 15.549200058,
      "tz": "Europe\/Prague"
  },
  "LKHC": {
      "icao": "LKHC",
      "iata": "",
      "name": "Horice Airport",
      "city": "Horice",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 922,
      "lat": 50.3572006226,
      "lon": 15.5767002106,
      "tz": "Europe\/Prague"
  },
  "LKHD": {
      "icao": "LKHD",
      "iata": "",
      "name": "Hodkovice Nad Mohelkou Airport",
      "city": "Hodkovice Nad Mohelkou",
      "state": "Liberecky",
      "country": "CZ",
      "elevation": 1480,
      "lat": 50.6571998596,
      "lon": 15.0777997971,
      "tz": "Europe\/Prague"
  },
  "LKHK": {
      "icao": "LKHK",
      "iata": "",
      "name": "Hradec Kralove Airport",
      "city": "Hradec Kralove",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 791,
      "lat": 50.253200531,
      "lon": 15.845199585,
      "tz": "Europe\/Prague"
  },
  "LKHN": {
      "icao": "LKHN",
      "iata": "",
      "name": "Hranice Airport",
      "city": "Hranice",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 801,
      "lat": 49.5461006165,
      "lon": 17.7043991089,
      "tz": "Europe\/Prague"
  },
  "LKHO": {
      "icao": "LKHO",
      "iata": "GTW",
      "name": "Holesov Airport",
      "city": "Holesov",
      "state": "Zlin",
      "country": "CZ",
      "elevation": 761,
      "lat": 49.3143997192,
      "lon": 17.5688991547,
      "tz": "Europe\/Prague"
  },
  "LKHR": {
      "icao": "LKHR",
      "iata": "",
      "name": "Hradcany Airport",
      "city": "Ralsko",
      "state": "Liberecky",
      "country": "CZ",
      "elevation": 912,
      "lat": 50.6205558777,
      "lon": 14.7380561829,
      "tz": "Europe\/Prague"
  },
  "LKHS": {
      "icao": "LKHS",
      "iata": "",
      "name": "Hosin Airport",
      "city": "Hosin",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1621,
      "lat": 49.0400009155,
      "lon": 14.4949998856,
      "tz": "Europe\/Prague"
  },
  "LKHV": {
      "icao": "LKHV",
      "iata": "",
      "name": "Horovice Airport",
      "city": "Horovice",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1214,
      "lat": 49.8480987549,
      "lon": 13.8935003281,
      "tz": "Europe\/Prague"
  },
  "LKJA": {
      "icao": "LKJA",
      "iata": "",
      "name": "Jaromer Airport",
      "city": "Jaromer",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 889,
      "lat": 50.331401825,
      "lon": 15.9539003372,
      "tz": "Europe\/Prague"
  },
  "LKJC": {
      "icao": "LKJC",
      "iata": "",
      "name": "Jicin Airport",
      "city": "Jicin",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 863,
      "lat": 50.4300003052,
      "lon": 15.3331003189,
      "tz": "Europe\/Prague"
  },
  "LKJH": {
      "icao": "LKJH",
      "iata": "",
      "name": "Jindrichuv Hradec Airport",
      "city": "Jindrichuv Hradec",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1667,
      "lat": 49.1502990723,
      "lon": 14.9710998535,
      "tz": "Europe\/Prague"
  },
  "LKJI": {
      "icao": "LKJI",
      "iata": "",
      "name": "Jihlava Airport",
      "city": "Jihlava",
      "state": "Vysocina",
      "country": "CZ",
      "elevation": 1821,
      "lat": 49.4193992615,
      "lon": 15.6352996826,
      "tz": "Europe\/Prague"
  },
  "LKKA": {
      "icao": "LKKA",
      "iata": "",
      "name": "Krizanov Airport",
      "city": "Krizanov",
      "state": "Vysocina",
      "country": "CZ",
      "elevation": 1831,
      "lat": 49.3683013916,
      "lon": 16.1161003113,
      "tz": "Europe\/Prague"
  },
  "LKKB": {
      "icao": "LKKB",
      "iata": "",
      "name": "Kbely Air Base",
      "city": "Prague",
      "state": "Praha",
      "country": "CZ",
      "elevation": 939,
      "lat": 50.1213989258,
      "lon": 14.5436000824,
      "tz": "Europe\/Prague"
  },
  "LKKC": {
      "icao": "LKKC",
      "iata": "",
      "name": "Krizenec Plana Airport",
      "city": "Plana",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 2080,
      "lat": 49.8706016541,
      "lon": 12.7725000381,
      "tz": "Europe\/Prague"
  },
  "LKKL": {
      "icao": "LKKL",
      "iata": "",
      "name": "Kladno Airport",
      "city": "Kladno",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1421,
      "lat": 50.1128005981,
      "lon": 14.0896997452,
      "tz": "Europe\/Prague"
  },
  "LKKM": {
      "icao": "LKKM",
      "iata": "",
      "name": "Kromeriz Airport",
      "city": "Kromeriz",
      "state": "Zlin",
      "country": "CZ",
      "elevation": 620,
      "lat": 49.2855987549,
      "lon": 17.4158000946,
      "tz": "Europe\/Prague"
  },
  "LKKO": {
      "icao": "LKKO",
      "iata": "",
      "name": "Kolin Airport",
      "city": "Kolin",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 932,
      "lat": 50.0018997192,
      "lon": 15.1732997894,
      "tz": "Europe\/Prague"
  },
  "LKKR": {
      "icao": "LKKR",
      "iata": "",
      "name": "Krnov Airport",
      "city": "Krnov",
      "state": "Moravskoslezsky",
      "country": "CZ",
      "elevation": 1230,
      "lat": 50.0741996765,
      "lon": 17.6947002411,
      "tz": "Europe\/Prague"
  },
  "LKKT": {
      "icao": "LKKT",
      "iata": "",
      "name": "Klatovy Airport",
      "city": "Klatovy",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 1299,
      "lat": 49.4183006287,
      "lon": 13.3219003677,
      "tz": "Europe\/Prague"
  },
  "LKKU": {
      "icao": "LKKU",
      "iata": "UHE",
      "name": "Kunovice Airport",
      "city": "Uherske Hradiste",
      "state": "Zlin",
      "country": "CZ",
      "elevation": 581,
      "lat": 49.0293998718,
      "lon": 17.439699173,
      "tz": "Europe\/Prague"
  },
  "LKKV": {
      "icao": "LKKV",
      "iata": "KLV",
      "name": "Karlovy Vary International Airport",
      "city": "Karlovy Vary",
      "state": "Karlovarsky",
      "country": "CZ",
      "elevation": 1989,
      "lat": 50.202999115,
      "lon": 12.9149999619,
      "tz": "Europe\/Prague"
  },
  "LKKY": {
      "icao": "LKKY",
      "iata": "",
      "name": "Kyjov Airport",
      "city": "Kyjov",
      "state": "South-Moravian",
      "country": "CZ",
      "elevation": 689,
      "lat": 48.9799995422,
      "lon": 17.1247005463,
      "tz": "Europe\/Prague"
  },
  "LKLB": {
      "icao": "LKLB",
      "iata": "",
      "name": "Liberec Airport",
      "city": "Liberec",
      "state": "Liberecky",
      "country": "CZ",
      "elevation": 1329,
      "lat": 50.7682991028,
      "lon": 15.0249996185,
      "tz": "Europe\/Prague"
  },
  "LKLN": {
      "icao": "LKLN",
      "iata": "",
      "name": "Plzen Line Airport",
      "city": "Plzen",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 1188,
      "lat": 49.675201416,
      "lon": 13.274600029,
      "tz": "Europe\/Prague"
  },
  "LKLT": {
      "icao": "LKLT",
      "iata": "",
      "name": "Letnany Airport",
      "city": "Letnany",
      "state": "Praha",
      "country": "CZ",
      "elevation": 909,
      "lat": 50.131401062,
      "lon": 14.5256004333,
      "tz": "Europe\/Prague"
  },
  "LKMB": {
      "icao": "LKMB",
      "iata": "",
      "name": "Mlada Boleslav Airport",
      "city": "Mlada Boleslav",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 781,
      "lat": 50.3983001709,
      "lon": 14.8983001709,
      "tz": "Europe\/Prague"
  },
  "LKMH": {
      "icao": "LKMH",
      "iata": "",
      "name": "Mnichovo Hradiste Airport",
      "city": "Mnichovo Hradiste",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 800,
      "lat": 50.5401992798,
      "lon": 15.0066003799,
      "tz": "Europe\/Prague"
  },
  "LKMI": {
      "icao": "LKMI",
      "iata": "",
      "name": "Mikulovice Airport",
      "city": "Mikulovice",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 1381,
      "lat": 50.301700592,
      "lon": 17.2975006104,
      "tz": "Europe\/Prague"
  },
  "LKMK": {
      "icao": "LKMK",
      "iata": "",
      "name": "Moravska Trebova Airport",
      "city": "Moravska Trebova",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 1319,
      "lat": 49.7983016968,
      "lon": 16.6877994537,
      "tz": "Europe\/Prague"
  },
  "LKMO": {
      "icao": "LKMO",
      "iata": "",
      "name": "Most Airport",
      "city": "Most",
      "state": "Ustecky",
      "country": "CZ",
      "elevation": 1089,
      "lat": 50.5250015259,
      "lon": 13.6830997467,
      "tz": "Europe\/Prague"
  },
  "LKMR": {
      "icao": "LKMR",
      "iata": "MKA",
      "name": "Marianske Lazne Airport",
      "city": "Marianske Lazne",
      "state": "Karlovarsky",
      "country": "CZ",
      "elevation": 1772,
      "lat": 49.9227981567,
      "lon": 12.7246999741,
      "tz": "Europe\/Prague"
  },
  "LKMT": {
      "icao": "LKMT",
      "iata": "OSR",
      "name": "Ostrava Leos Janacek Airport",
      "city": "Ostrava",
      "state": "Moravskoslezsky",
      "country": "CZ",
      "elevation": 844,
      "lat": 49.6963005066,
      "lon": 18.1110992432,
      "tz": "Europe\/Prague"
  },
  "LKNA": {
      "icao": "LKNA",
      "iata": "",
      "name": "Namest Air Base",
      "city": "Namest",
      "state": "Vysocina",
      "country": "CZ",
      "elevation": 1548,
      "lat": 49.1657981873,
      "lon": 16.1249008179,
      "tz": "Europe\/Prague"
  },
  "LKNM": {
      "icao": "LKNM",
      "iata": "",
      "name": "Nove Mesto Airport",
      "city": "Nove Mesto Nad Metuji Morave",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 1001,
      "lat": 50.364200592,
      "lon": 16.1135997772,
      "tz": "Europe\/Prague"
  },
  "LKNY": {
      "icao": "LKNY",
      "iata": "",
      "name": "Nymburk Ul Ploch Airport",
      "city": "Nymburk",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 607,
      "lat": 50.168598175,
      "lon": 15.0524997711,
      "tz": "Europe\/Prague"
  },
  "LKOL": {
      "icao": "LKOL",
      "iata": "OLO",
      "name": "Olomouc Glider Airport",
      "city": "Olomouc",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 869,
      "lat": 49.5877990723,
      "lon": 17.2108001709,
      "tz": "Europe\/Prague"
  },
  "LKOT": {
      "icao": "LKOT",
      "iata": "",
      "name": "Otrokovice Zlin Airport",
      "city": "Otrokovice",
      "state": "Zlin",
      "country": "CZ",
      "elevation": 600,
      "lat": 49.198299408,
      "lon": 17.5177993774,
      "tz": "Europe\/Prague"
  },
  "LKPA": {
      "icao": "LKPA",
      "iata": "",
      "name": "Policka Airport",
      "city": "Policka",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 1982,
      "lat": 49.7393989563,
      "lon": 16.2588996887,
      "tz": "Europe\/Prague"
  },
  "LKPC": {
      "icao": "LKPC",
      "iata": "",
      "name": "Panensky Tynec Airport",
      "city": "Panensky Tynec",
      "state": "Ustecky",
      "country": "CZ",
      "elevation": 1207,
      "lat": 50.306098938,
      "lon": 13.9342002869,
      "tz": "Europe\/Prague"
  },
  "LKPD": {
      "icao": "LKPD",
      "iata": "PED",
      "name": "Pardubice Airport",
      "city": "Pardubice",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 741,
      "lat": 50.0134010315,
      "lon": 15.7385997772,
      "tz": "Europe\/Prague"
  },
  "LKPI": {
      "icao": "LKPI",
      "iata": "",
      "name": "Pribyslav Airport",
      "city": "Pribyslav",
      "state": "Vysocina",
      "country": "CZ",
      "elevation": 1739,
      "lat": 49.5807991028,
      "lon": 15.7628002167,
      "tz": "Europe\/Prague"
  },
  "LKPJ": {
      "icao": "LKPJ",
      "iata": "",
      "name": "Prostejov Airport",
      "city": "Prostejov",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 699,
      "lat": 49.4477996826,
      "lon": 17.1338996887,
      "tz": "Europe\/Prague"
  },
  "LKPL": {
      "icao": "LKPL",
      "iata": "",
      "name": "Letkov Airport",
      "city": "Letkov",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 1371,
      "lat": 49.7230987549,
      "lon": 13.4521999359,
      "tz": "Europe\/Prague"
  },
  "LKPM": {
      "icao": "LKPM",
      "iata": "",
      "name": "Pribram Airport",
      "city": "Pribram",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1529,
      "lat": 49.7201004028,
      "lon": 14.1006002426,
      "tz": "Europe\/Prague"
  },
  "LKPN": {
      "icao": "LKPN",
      "iata": "",
      "name": "Podhorany Glider Airport",
      "city": "Podhoramy",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 1250,
      "lat": 49.939201355,
      "lon": 15.5496997833,
      "tz": "Europe\/Prague"
  },
  "LKPO": {
      "icao": "LKPO",
      "iata": "PRV",
      "name": "Prerov Air Base",
      "city": "Prerov",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 676,
      "lat": 49.4258003235,
      "lon": 17.4046993256,
      "tz": "Europe\/Prague"
  },
  "LKPR": {
      "icao": "LKPR",
      "iata": "PRG",
      "name": "Vaclav Havel Airport",
      "city": "Prague",
      "state": "Praha",
      "country": "CZ",
      "elevation": 1247,
      "lat": 50.1007995605,
      "lon": 14.2600002289,
      "tz": "Europe\/Prague"
  },
  "LKPS": {
      "icao": "LKPS",
      "iata": "",
      "name": "Plasy Rybnice Airport",
      "city": "Rybnice",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 1430,
      "lat": 49.92029953,
      "lon": 13.3768997192,
      "tz": "Europe\/Prague"
  },
  "LKRA": {
      "icao": "LKRA",
      "iata": "",
      "name": "Rana Loumy Airport",
      "city": "Rana",
      "state": "Ustecky",
      "country": "CZ",
      "elevation": 879,
      "lat": 50.4039001465,
      "lon": 13.7518997192,
      "tz": "Europe\/Prague"
  },
  "LKRK": {
      "icao": "LKRK",
      "iata": "",
      "name": "Rakovnik Glider Airport",
      "city": "Rakovnik",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1270,
      "lat": 50.0942001343,
      "lon": 13.6888999939,
      "tz": "Europe\/Prague"
  },
  "LKRO": {
      "icao": "LKRO",
      "iata": "",
      "name": "Roudnice Mad Airport",
      "city": "Roudnice Nad Labem",
      "state": "Ustecky",
      "country": "CZ",
      "elevation": 732,
      "lat": 50.4105987549,
      "lon": 14.226099968,
      "tz": "Europe\/Prague"
  },
  "LKSA": {
      "icao": "LKSA",
      "iata": "",
      "name": "Stankov Glider Airport",
      "city": "Stankov",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 1401,
      "lat": 49.5666999817,
      "lon": 13.0486001968,
      "tz": "Europe\/Prague"
  },
  "LKSB": {
      "icao": "LKSB",
      "iata": "",
      "name": "Stichovice Pluml Airport",
      "city": "Mostkovice",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 840,
      "lat": 49.4860992432,
      "lon": 17.0555992126,
      "tz": "Europe\/Prague"
  },
  "LKSK": {
      "icao": "LKSK",
      "iata": "",
      "name": "Skutec Airport",
      "city": "Skutec",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 1601,
      "lat": 49.8278007507,
      "lon": 16.0058002472,
      "tz": "Europe\/Prague"
  },
  "LKSN": {
      "icao": "LKSN",
      "iata": "",
      "name": "Slany Airport",
      "city": "Slany",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1079,
      "lat": 50.2167015076,
      "lon": 14.0886001587,
      "tz": "Europe\/Prague"
  },
  "LKSO": {
      "icao": "LKSO",
      "iata": "",
      "name": "Sobeslav Airport",
      "city": "Sobeslav",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1342,
      "lat": 49.2467002869,
      "lon": 14.7136001587,
      "tz": "Europe\/Prague"
  },
  "LKSR": {
      "icao": "LKSR",
      "iata": "",
      "name": "Strunkovice Airport",
      "city": "Strunkovice",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1572,
      "lat": 49.0825004578,
      "lon": 14.075799942,
      "tz": "Europe\/Prague"
  },
  "LKST": {
      "icao": "LKST",
      "iata": "",
      "name": "Strakonice Airport",
      "city": "Strakonice",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1381,
      "lat": 49.251701355,
      "lon": 13.8928003311,
      "tz": "Europe\/Prague"
  },
  "LKSU": {
      "icao": "LKSU",
      "iata": "",
      "name": "Sumperk Airport",
      "city": "Sumperk",
      "state": "Olomoucky",
      "country": "CZ",
      "elevation": 1099,
      "lat": 49.9606018066,
      "lon": 17.0177993774,
      "tz": "Europe\/Prague"
  },
  "LKSZ": {
      "icao": "LKSZ",
      "iata": "",
      "name": "Sazena Kralupy Airport",
      "city": "Sazena",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 761,
      "lat": 50.3246994019,
      "lon": 14.2588996887,
      "tz": "Europe\/Prague"
  },
  "LKTA": {
      "icao": "LKTA",
      "iata": "",
      "name": "Tabor Airport",
      "city": "Tabor",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1440,
      "lat": 49.3911018372,
      "lon": 14.7082996368,
      "tz": "Europe\/Prague"
  },
  "LKTB": {
      "icao": "LKTB",
      "iata": "BRQ",
      "name": "Brno-Turany Airport",
      "city": "Brno",
      "state": "South-Moravian",
      "country": "CZ",
      "elevation": 778,
      "lat": 49.1512985229,
      "lon": 16.6944007874,
      "tz": "Europe\/Prague"
  },
  "LKTC": {
      "icao": "LKTC",
      "iata": "",
      "name": "Tocna Praha Glider Airport",
      "city": "Tocna",
      "state": "Praha",
      "country": "CZ",
      "elevation": 1030,
      "lat": 49.9852981567,
      "lon": 14.4260997772,
      "tz": "Europe\/Prague"
  },
  "LKTD": {
      "icao": "LKTD",
      "iata": "",
      "name": "Tachov Oldrichov Airport",
      "city": "Tachov",
      "state": "Plzensky",
      "country": "CZ",
      "elevation": 1640,
      "lat": 49.7977981567,
      "lon": 12.7068996429,
      "tz": "Europe\/Prague"
  },
  "LKTO": {
      "icao": "LKTO",
      "iata": "",
      "name": "Touzim Airport",
      "city": "Touzim",
      "state": "Karlovarsky",
      "country": "CZ",
      "elevation": 2139,
      "lat": 50.0863990784,
      "lon": 12.9527997971,
      "tz": "Europe\/Prague"
  },
  "LKTV": {
      "icao": "LKTV",
      "iata": "",
      "name": "Tabor Vsechov Ultralight Airport",
      "city": "Tabor",
      "state": "Jihocesky",
      "country": "CZ",
      "elevation": 1604,
      "lat": 49.4378013611,
      "lon": 14.6217002869,
      "tz": "Europe\/Prague"
  },
  "LKUL": {
      "icao": "LKUL",
      "iata": "",
      "name": "Usti Nad Labem Airport",
      "city": "Usti Nad Labem",
      "state": "Ustecky",
      "country": "CZ",
      "elevation": 791,
      "lat": 50.6996994019,
      "lon": 13.9696998596,
      "tz": "Europe\/Prague"
  },
  "LKUO": {
      "icao": "LKUO",
      "iata": "",
      "name": "Usti Mad Orlici Airport",
      "city": "Usti Mad Orlici",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 1342,
      "lat": 49.9785995483,
      "lon": 16.426399231,
      "tz": "Europe\/Prague"
  },
  "LKVL": {
      "icao": "LKVL",
      "iata": "",
      "name": "Vlasin Glider Airport",
      "city": "Vlasin",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1421,
      "lat": 49.7289009094,
      "lon": 14.8788995743,
      "tz": "Europe\/Prague"
  },
  "LKVM": {
      "icao": "LKVM",
      "iata": "",
      "name": "Vysoke Myto Glider Airport",
      "city": "Vysoke Myto",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 991,
      "lat": 49.9268989563,
      "lon": 16.1858005524,
      "tz": "Europe\/Prague"
  },
  "LKVO": {
      "icao": "LKVO",
      "iata": "VOD",
      "name": "Vodochody Airport",
      "city": "Vodochoky",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 919,
      "lat": 50.2165985107,
      "lon": 14.3957996368,
      "tz": "Europe\/Prague"
  },
  "LKVP": {
      "icao": "LKVP",
      "iata": "",
      "name": "Velke Porici Glider Airport",
      "city": "Velke Porici",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 1322,
      "lat": 50.4678001404,
      "lon": 16.2056007385,
      "tz": "Europe\/Prague"
  },
  "LKVR": {
      "icao": "LKVR",
      "iata": "",
      "name": "Vrchlabi Airport",
      "city": "Vrchlabi",
      "state": "Kralovehradecky",
      "country": "CZ",
      "elevation": 1611,
      "lat": 50.6241989136,
      "lon": 15.6464004517,
      "tz": "Europe\/Prague"
  },
  "LKVY": {
      "icao": "LKVY",
      "iata": "",
      "name": "Vyskov Airport",
      "city": "Vyskov",
      "state": "South-Moravian",
      "country": "CZ",
      "elevation": 922,
      "lat": 49.3003005981,
      "lon": 17.0252990723,
      "tz": "Europe\/Prague"
  },
  "LKZA": {
      "icao": "LKZA",
      "iata": "ZBE",
      "name": "Zabreh Ostrava Airport",
      "city": "Zabreh",
      "state": "Moravskoslezsky",
      "country": "CZ",
      "elevation": 791,
      "lat": 49.9282989502,
      "lon": 18.0783004761,
      "tz": "Europe\/Prague"
  },
  "LKZB": {
      "icao": "LKZB",
      "iata": "",
      "name": "Zbraslavice Glider Airport",
      "city": "Zbraslavice",
      "state": "Central-Bohemia",
      "country": "CZ",
      "elevation": 1621,
      "lat": 49.814201355,
      "lon": 15.2017002106,
      "tz": "Europe\/Prague"
  },
  "LKZD": {
      "icao": "LKZD",
      "iata": "",
      "name": "Zatec Macerka Ar Airport",
      "city": "Zatek",
      "state": "Ustecky",
      "country": "CZ",
      "elevation": 879,
      "lat": 50.3175010681,
      "lon": 13.5128002167,
      "tz": "Europe\/Prague"
  },
  "LKZM": {
      "icao": "LKZM",
      "iata": "",
      "name": "Zamberk Airport",
      "city": "Zamberk",
      "state": "Pardubicky",
      "country": "CZ",
      "elevation": 1411,
      "lat": 50.0839004517,
      "lon": 16.4438991547,
      "tz": "Europe\/Prague"
  },
  "LKZN": {
      "icao": "LKZN",
      "iata": "",
      "name": "Znojmo Airport",
      "city": "Znojmo",
      "state": "South-Moravian",
      "country": "CZ",
      "elevation": 830,
      "lat": 48.8177986145,
      "lon": 16.0657997131,
      "tz": "Europe\/Prague"
  },
  "LL07": {
      "icao": "LL07",
      "iata": "",
      "name": "Tautz Airport",
      "city": "Mount Carroll",
      "state": "Illinois",
      "country": "US",
      "elevation": 853,
      "lat": 42.0638999939,
      "lon": -89.9695968628,
      "tz": "America\/Chicago"
  },
  "LL09": {
      "icao": "LL09",
      "iata": "",
      "name": "Air Estates Inc Airport",
      "city": "Mundelein",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 42.2806015015,
      "lon": -88.0944976807,
      "tz": "America\/Chicago"
  },
  "LL10": {
      "icao": "LL10",
      "iata": "",
      "name": "Naper Aero Club Airport",
      "city": "Naperville",
      "state": "Illinois",
      "country": "US",
      "elevation": 708,
      "lat": 41.7347984314,
      "lon": -88.2033996582,
      "tz": "America\/Chicago"
  },
  "LL13": {
      "icao": "LL13",
      "iata": "",
      "name": "Stoney Real Estate Airport",
      "city": "Enfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 460,
      "lat": 38.0485992432,
      "lon": -88.3005981445,
      "tz": "America\/Chicago"
  },
  "LL18": {
      "icao": "LL18",
      "iata": "",
      "name": "Ernest E Orwig Airport",
      "city": "North Henderson",
      "state": "Illinois",
      "country": "US",
      "elevation": 790,
      "lat": 41.0973014832,
      "lon": -90.4529037476,
      "tz": "America\/Chicago"
  },
  "LL1B": {
      "icao": "LL1B",
      "iata": "",
      "name": "Arad Airport",
      "city": "Arad",
      "state": "",
      "country": "IL",
      "elevation": 1952,
      "lat": 31.2291698456,
      "lon": 35.189037323,
      "tz": "Asia\/Jerusalem"
  },
  "LL20": {
      "icao": "LL20",
      "iata": "",
      "name": "Smith Restricted Landing Area",
      "city": "Greenfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 582,
      "lat": 39.3130989075,
      "lon": -90.2778015137,
      "tz": "America\/Chicago"
  },
  "LL22": {
      "icao": "LL22",
      "iata": "",
      "name": "Brookeridge Air Park",
      "city": "Downers Grove",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 41.7327003479,
      "lon": -87.9992980957,
      "tz": "America\/Chicago"
  },
  "LL23": {
      "icao": "LL23",
      "iata": "",
      "name": "Meyer Airport",
      "city": "Oquawka",
      "state": "Illinois",
      "country": "US",
      "elevation": 580,
      "lat": 41.01720047,
      "lon": -90.8777999878,
      "tz": "America\/Chicago"
  },
  "LL24": {
      "icao": "LL24",
      "iata": "",
      "name": "Sunset Acres Airport",
      "city": "Manteno",
      "state": "Illinois",
      "country": "US",
      "elevation": 665,
      "lat": 41.2070007324,
      "lon": -87.7853012085,
      "tz": "America\/Chicago"
  },
  "LL26": {
      "icao": "LL26",
      "iata": "",
      "name": "Schaller Airport",
      "city": "New Athens",
      "state": "Illinois",
      "country": "US",
      "elevation": 434,
      "lat": 38.2750015259,
      "lon": -89.8709030151,
      "tz": "America\/Chicago"
  },
  "LL27": {
      "icao": "LL27",
      "iata": "",
      "name": "Smith Airport",
      "city": "Macomb",
      "state": "Illinois",
      "country": "US",
      "elevation": 702,
      "lat": 40.4542007446,
      "lon": -90.6234970093,
      "tz": "America\/Chicago"
  },
  "LL28": {
      "icao": "LL28",
      "iata": "",
      "name": "Sd Aero Airport",
      "city": "Capron",
      "state": "Illinois",
      "country": "US",
      "elevation": 930,
      "lat": 42.432800293,
      "lon": -88.7172012329,
      "tz": "America\/Chicago"
  },
  "LL32": {
      "icao": "LL32",
      "iata": "",
      "name": "C D Maulding Airport",
      "city": "Paxton",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 40.4500007629,
      "lon": -88.0749969482,
      "tz": "America\/Chicago"
  },
  "LL33": {
      "icao": "LL33",
      "iata": "",
      "name": "Rdh Farms Airport",
      "city": "Good Hope",
      "state": "Illinois",
      "country": "US",
      "elevation": 745,
      "lat": 40.57970047,
      "lon": -90.6797027588,
      "tz": "America\/Chicago"
  },
  "LL34": {
      "icao": "LL34",
      "iata": "",
      "name": "Thorp Airport",
      "city": "Wapella",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 40.2167015076,
      "lon": -88.900100708,
      "tz": "America\/Chicago"
  },
  "LL38": {
      "icao": "LL38",
      "iata": "",
      "name": "Stutzke Airport",
      "city": "Sterling",
      "state": "Illinois",
      "country": "US",
      "elevation": 740,
      "lat": 41.8102989197,
      "lon": -89.8383026123,
      "tz": "America\/Chicago"
  },
  "LL39": {
      "icao": "LL39",
      "iata": "",
      "name": "Curanda Airport",
      "city": "Morris",
      "state": "Illinois",
      "country": "US",
      "elevation": 620,
      "lat": 41.2574996948,
      "lon": -88.4777984619,
      "tz": "America\/Chicago"
  },
  "LL40": {
      "icao": "LL40",
      "iata": "",
      "name": "Frankfort Airport",
      "city": "Frankfort",
      "state": "Illinois",
      "country": "US",
      "elevation": 778,
      "lat": 41.4775009155,
      "lon": -87.8404998779,
      "tz": "America\/Chicago"
  },
  "LL43": {
      "icao": "LL43",
      "iata": "",
      "name": "Gerald H Hamer Airport",
      "city": "Peru",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 41.4500007629,
      "lon": -89.1667022705,
      "tz": "America\/Chicago"
  },
  "LL44": {
      "icao": "LL44",
      "iata": "",
      "name": "Trovero Airport",
      "city": "Peru",
      "state": "Illinois",
      "country": "US",
      "elevation": 625,
      "lat": 41.2985992432,
      "lon": -89.1092987061,
      "tz": "America\/Chicago"
  },
  "LL45": {
      "icao": "LL45",
      "iata": "",
      "name": "Lindell Loveless Airport",
      "city": "Gillespie",
      "state": "Illinois",
      "country": "US",
      "elevation": 657,
      "lat": 39.1823005676,
      "lon": -89.8218002319,
      "tz": "America\/Chicago"
  },
  "LL46": {
      "icao": "LL46",
      "iata": "",
      "name": "Read Airport",
      "city": "Piper City",
      "state": "Illinois",
      "country": "US",
      "elevation": 663,
      "lat": 40.8353004456,
      "lon": -88.1528015137,
      "tz": "America\/Chicago"
  },
  "LL48": {
      "icao": "LL48",
      "iata": "",
      "name": "Wilcoxon Airport",
      "city": "Athens",
      "state": "Illinois",
      "country": "US",
      "elevation": 600,
      "lat": 39.9491996765,
      "lon": -89.7425003052,
      "tz": "America\/Chicago"
  },
  "LL49": {
      "icao": "LL49",
      "iata": "",
      "name": "Raymond Restricted Landing Area",
      "city": "Jerseyville",
      "state": "Illinois",
      "country": "US",
      "elevation": 658,
      "lat": 39.0621986389,
      "lon": -90.2722015381,
      "tz": "America\/Chicago"
  },
  "LL51": {
      "icao": "LL51",
      "iata": "",
      "name": "Riley's Field",
      "city": "Plainfield",
      "state": "Illinois",
      "country": "US",
      "elevation": 663,
      "lat": 41.6041984558,
      "lon": -88.2736968994,
      "tz": "America\/Chicago"
  },
  "LL52": {
      "icao": "LL52",
      "iata": "",
      "name": "Wilts Airport",
      "city": "Streator",
      "state": "Illinois",
      "country": "US",
      "elevation": 650,
      "lat": 41.1610984802,
      "lon": -88.9391021729,
      "tz": "America\/Chicago"
  },
  "LL53": {
      "icao": "LL53",
      "iata": "",
      "name": "Olson Airport",
      "city": "Plato Center",
      "state": "Illinois",
      "country": "US",
      "elevation": 955,
      "lat": 42.0088996887,
      "lon": -88.4576034546,
      "tz": "America\/Chicago"
  },
  "LL54": {
      "icao": "LL54",
      "iata": "",
      "name": "Dunn Airport",
      "city": "Paw Paw",
      "state": "Illinois",
      "country": "US",
      "elevation": 850,
      "lat": 41.7193984985,
      "lon": -89.0299987793,
      "tz": "America\/Chicago"
  },
  "LL55": {
      "icao": "LL55",
      "iata": "",
      "name": "Gentry Airport",
      "city": "Polo",
      "state": "Illinois",
      "country": "US",
      "elevation": 865,
      "lat": 41.9908981323,
      "lon": -89.5597991943,
      "tz": "America\/Chicago"
  },
  "LL56": {
      "icao": "LL56",
      "iata": "",
      "name": "Schott Airport",
      "city": "Pontiac",
      "state": "Illinois",
      "country": "US",
      "elevation": 659,
      "lat": 40.8842010498,
      "lon": -88.5908966064,
      "tz": "America\/Chicago"
  },
  "LL59": {
      "icao": "LL59",
      "iata": "",
      "name": "Palmahim Air Base",
      "city": "Rishon LeZion",
      "state": "",
      "country": "IL",
      "elevation": 32,
      "lat": 31.8978996277,
      "lon": 34.6907997131,
      "tz": "Asia\/Jerusalem"
  },
  "LL61": {
      "icao": "LL61",
      "iata": "",
      "name": "Doering's Port Airport",
      "city": "Prophetstown",
      "state": "Illinois",
      "country": "US",
      "elevation": 606,
      "lat": 41.6856002808,
      "lon": -89.9026031494,
      "tz": "America\/Chicago"
  },
  "LL64": {
      "icao": "LL64",
      "iata": "",
      "name": "Ralph E. Daniels Airport",
      "city": "Putnam",
      "state": "Illinois",
      "country": "US",
      "elevation": 457,
      "lat": 41.1995010376,
      "lon": -89.3945007324,
      "tz": "America\/Chicago"
  },
  "LL65": {
      "icao": "LL65",
      "iata": "",
      "name": "Edgar Read Airport",
      "city": "Putnam",
      "state": "Illinois",
      "country": "US",
      "elevation": 690,
      "lat": 41.1944999695,
      "lon": -89.4501037598,
      "tz": "America\/Chicago"
  },
  "LL66": {
      "icao": "LL66",
      "iata": "",
      "name": "Egland Field",
      "city": "Ransom",
      "state": "Illinois",
      "country": "US",
      "elevation": 750,
      "lat": 41.2122993469,
      "lon": -88.6930999756,
      "tz": "America\/Chicago"
  },
  "LL69": {
      "icao": "LL69",
      "iata": "",
      "name": "Brewer Airport",
      "city": "Ramsey",
      "state": "Illinois",
      "country": "US",
      "elevation": 640,
      "lat": 39.2108421986,
      "lon": -89.1182398796,
      "tz": "America\/Chicago"
  },
  "LL74": {
      "icao": "LL74",
      "iata": "",
      "name": "Maple Hurst Farms Airport",
      "city": "Rochelle",
      "state": "Illinois",
      "country": "US",
      "elevation": 823,
      "lat": 42.0083999634,
      "lon": -89.0486984253,
      "tz": "America\/Chicago"
  },
  "LL75": {
      "icao": "LL75",
      "iata": "",
      "name": "Chester Wyss Airport",
      "city": "Rock City",
      "state": "Illinois",
      "country": "US",
      "elevation": 920,
      "lat": 42.4249992371,
      "lon": -89.4501037598,
      "tz": "America\/Chicago"
  },
  "LL78": {
      "icao": "LL78",
      "iata": "",
      "name": "Lz Fairwinds Airport",
      "city": "Rockford",
      "state": "Illinois",
      "country": "US",
      "elevation": 752,
      "lat": 42.3116989136,
      "lon": -89.1509017944,
      "tz": "America\/Chicago"
  },
  "LL79": {
      "icao": "LL79",
      "iata": "",
      "name": "Severson Airport",
      "city": "Rockford",
      "state": "Illinois",
      "country": "US",
      "elevation": 813,
      "lat": 42.3931007385,
      "lon": -89.1306991577,
      "tz": "America\/Chicago"
  },
  "LL80": {
      "icao": "LL80",
      "iata": "",
      "name": "Darrington Airport",
      "city": "Rockford",
      "state": "Illinois",
      "country": "US",
      "elevation": 862,
      "lat": 42.3819999695,
      "lon": -89.1612014771,
      "tz": "America\/Chicago"
  },
  "LL81": {
      "icao": "LL81",
      "iata": "",
      "name": "Spickard-Marshall Airport",
      "city": "Rockford",
      "state": "Illinois",
      "country": "US",
      "elevation": 778,
      "lat": 42.375,
      "lon": -89.070098877,
      "tz": "America\/Chicago"
  },
  "LL87": {
      "icao": "LL87",
      "iata": "",
      "name": "Compass Rose Airport",
      "city": "Rockton",
      "state": "Illinois",
      "country": "US",
      "elevation": 970,
      "lat": 42.4556007385,
      "lon": -88.9029006958,
      "tz": "America\/Chicago"
  },
  "LL88": {
      "icao": "LL88",
      "iata": "",
      "name": "Harry D Fenton Airport",
      "city": "Rockton",
      "state": "Illinois",
      "country": "US",
      "elevation": 770,
      "lat": 42.4749984741,
      "lon": -89.0889968872,
      "tz": "America\/Chicago"
  },
  "LL89": {
      "icao": "LL89",
      "iata": "",
      "name": "Blackhawk Farms Inc Airport",
      "city": "Rockton",
      "state": "Illinois",
      "country": "US",
      "elevation": 759,
      "lat": 42.4872016907,
      "lon": -89.1079025269,
      "tz": "America\/Chicago"
  },
  "LL90": {
      "icao": "LL90",
      "iata": "",
      "name": "Mc Curdy Strip",
      "city": "Rockton\/Belvidere",
      "state": "Illinois",
      "country": "US",
      "elevation": 960,
      "lat": 42.4458999634,
      "lon": -88.900100708,
      "tz": "America\/Chicago"
  },
  "LL91": {
      "icao": "LL91",
      "iata": "",
      "name": "Hillman Airport",
      "city": "Rock City",
      "state": "Illinois",
      "country": "US",
      "elevation": 870,
      "lat": 42.4157981873,
      "lon": -89.4529037476,
      "tz": "America\/Chicago"
  },
  "LL94": {
      "icao": "LL94",
      "iata": "",
      "name": "Mc Curdy Airport",
      "city": "Roscoe",
      "state": "Illinois",
      "country": "US",
      "elevation": 755,
      "lat": 42.4345016479,
      "lon": -88.99949646,
      "tz": "America\/Chicago"
  },
  "LL97": {
      "icao": "LL97",
      "iata": "",
      "name": "Rockenbach Airport",
      "city": "Round Lake",
      "state": "Illinois",
      "country": "US",
      "elevation": 800,
      "lat": 42.3389015198,
      "lon": -88.0848007202,
      "tz": "America\/Chicago"
  },
  "LL98": {
      "icao": "LL98",
      "iata": "",
      "name": "John W Meils Restricted Landing Area",
      "city": "Rutland",
      "state": "Illinois",
      "country": "US",
      "elevation": 705,
      "lat": 40.9435997009,
      "lon": -89.1082992554,
      "tz": "America\/Chicago"
  },
  "LLBG": {
      "icao": "LLBG",
      "iata": "TLV",
      "name": "Ben Gurion International Airport",
      "city": "Tel Aviv",
      "state": "Central-District",
      "country": "IL",
      "elevation": 135,
      "lat": 32.0113983154,
      "lon": 34.8866996765,
      "tz": "Asia\/Jerusalem"
  },
  "LLBS": {
      "icao": "LLBS",
      "iata": "BEV",
      "name": "Be'er Sheva (Teyman) Airport",
      "city": "Beersheva",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 656,
      "lat": 31.2870006561,
      "lon": 34.7229995728,
      "tz": "Asia\/Jerusalem"
  },
  "LLEK": {
      "icao": "LLEK",
      "iata": "",
      "name": "Tel Nov Air Base",
      "city": "Rehovot",
      "state": "",
      "country": "IL",
      "elevation": 193,
      "lat": 31.8395004272,
      "lon": 34.8218002319,
      "tz": "Asia\/Jerusalem"
  },
  "LLES": {
      "icao": "LLES",
      "iata": "",
      "name": "Ein Shemer Airfield",
      "city": "Ein Shemer",
      "state": "",
      "country": "IL",
      "elevation": 95,
      "lat": 32.4407997131,
      "lon": 35.0077018738,
      "tz": "Asia\/Jerusalem"
  },
  "LLET": {
      "icao": "LLET",
      "iata": "ETH",
      "name": "Eilat Airport",
      "city": "Eilat",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 42,
      "lat": 29.5613002777,
      "lon": 34.9600982666,
      "tz": "Asia\/Jerusalem"
  },
  "LLER": {
      "icao": "LLER",
      "iata": "ETM",
      "name": "Ramon Airport",
      "city": "Eilat",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 288,
      "lat": 29.723694,
      "lon": 35.011417,
      "tz": "Asia\/Jerusalem"
  },
  "LLEY": {
      "icao": "LLEY",
      "iata": "EIY",
      "name": "Ein Yahav Airfield",
      "city": "Sapir",
      "state": "Southern-District",
      "country": "IL",
      "elevation": -164,
      "lat": 30.6217002869,
      "lon": 35.2033004761,
      "tz": "Asia\/Jerusalem"
  },
  "LLFK": {
      "icao": "LLFK",
      "iata": "",
      "name": "Fiq Airfield",
      "city": "Katzrin",
      "state": "Quneitra",
      "country": "IL",
      "elevation": 1218,
      "lat": 32.786388888,
      "lon": 35.7174987793,
      "tz": "Asia\/Jerusalem"
  },
  "LLHA": {
      "icao": "LLHA",
      "iata": "HFA",
      "name": "Haifa International Airport",
      "city": "Haifa",
      "state": "Haifa",
      "country": "IL",
      "elevation": 28,
      "lat": 32.8093986511,
      "lon": 35.0430984497,
      "tz": "Asia\/Jerusalem"
  },
  "LLHB": {
      "icao": "LLHB",
      "iata": "",
      "name": "Hatzerim Air Base",
      "city": "Beersheba",
      "state": "",
      "country": "IL",
      "elevation": 725,
      "lat": 31.2334003448,
      "lon": 34.6626014709,
      "tz": "Asia\/Jerusalem"
  },
  "LLHS": {
      "icao": "LLHS",
      "iata": "",
      "name": "Hatzor Air Base",
      "city": "Hatzor",
      "state": "",
      "country": "IL",
      "elevation": 148,
      "lat": 31.7625007629,
      "lon": 34.7271995544,
      "tz": "Asia\/Jerusalem"
  },
  "LLHZ": {
      "icao": "LLHZ",
      "iata": "",
      "name": "Herzliya Airport",
      "city": "Herzliya",
      "state": "Tel-Aviv",
      "country": "IL",
      "elevation": 121,
      "lat": 32.1805992126,
      "lon": 34.8347015381,
      "tz": "Asia\/Jerusalem"
  },
  "LLIB": {
      "icao": "LLIB",
      "iata": "RPN",
      "name": "Ben Ya'akov Airport",
      "city": "Rosh Pina",
      "state": "Northern-District",
      "country": "IL",
      "elevation": 922,
      "lat": 32.9809989929,
      "lon": 35.5718994141,
      "tz": "Asia\/Jerusalem"
  },
  "LLKS": {
      "icao": "LLKS",
      "iata": "KSW",
      "name": "Kiryat Shmona Airport",
      "city": "Kiryat Shmona",
      "state": "Northern-District",
      "country": "IL",
      "elevation": 381,
      "lat": 33.2167015076,
      "lon": 35.5999984741,
      "tz": "Asia\/Jerusalem"
  },
  "LLMG": {
      "icao": "LLMG",
      "iata": "",
      "name": "Megiddo Airport",
      "city": "Afula",
      "state": "Northern-District",
      "country": "IL",
      "elevation": 200,
      "lat": 32.5973014832,
      "lon": 35.2288017273,
      "tz": "Asia\/Jerusalem"
  },
  "LLMR": {
      "icao": "LLMR",
      "iata": "MIP",
      "name": "Mitzpe Ramon Airfield",
      "city": "Mitzpe Ramon",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 2556,
      "lat": 30.6506004333,
      "lon": 34.8069000244,
      "tz": "Asia\/Jerusalem"
  },
  "LLMZ": {
      "icao": "LLMZ",
      "iata": "MTZ",
      "name": "Bar Yehuda Airfield",
      "city": "Masada",
      "state": "Southern-District",
      "country": "IL",
      "elevation": -1266,
      "lat": 31.3281993866,
      "lon": 35.3885993958,
      "tz": "Asia\/Amman"
  },
  "LLNV": {
      "icao": "LLNV",
      "iata": "VTM",
      "name": "Nevatim Air Base",
      "city": "Beersheba",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 1330,
      "lat": 31.2082996368,
      "lon": 35.012298584,
      "tz": "Asia\/Jerusalem"
  },
  "LLOV": {
      "icao": "LLOV",
      "iata": "VDA",
      "name": "Ovda International Airport",
      "city": "Eilat",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 1492,
      "lat": 29.9402999878,
      "lon": 34.935798645,
      "tz": "Asia\/Jerusalem"
  },
  "LLRD": {
      "icao": "LLRD",
      "iata": "",
      "name": "Ramat David Air Base",
      "city": "Haifa",
      "state": "",
      "country": "IL",
      "elevation": 185,
      "lat": 32.6651000977,
      "lon": 35.1795005798,
      "tz": "Asia\/Jerusalem"
  },
  "LLRM": {
      "icao": "LLRM",
      "iata": "MIP",
      "name": "Ramon Air Base",
      "city": "Beersheba",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 2126,
      "lat": 30.7761001587,
      "lon": 34.6666984558,
      "tz": "Asia\/Jerusalem"
  },
  "LLSD": {
      "icao": "LLSD",
      "iata": "SDV",
      "name": "Sde Dov Airport",
      "city": "Tel Aviv",
      "state": "Tel-Aviv",
      "country": "IL",
      "elevation": 43,
      "lat": 32.1147003174,
      "lon": 34.7821998596,
      "tz": "Asia\/Jerusalem"
  },
  "LLYT": {
      "icao": "LLYT",
      "iata": "YOT",
      "name": "Yotvata Airfield",
      "city": "Yotvata",
      "state": "Southern-District",
      "country": "IL",
      "elevation": 300,
      "lat": 29.9062004089,
      "lon": 35.0666999817,
      "tz": "Asia\/Jerusalem"
  },
  "LMML": {
      "icao": "LMML",
      "iata": "MLA",
      "name": "Luqa Airport",
      "city": "Luqa",
      "state": "Luqa",
      "country": "MT",
      "elevation": 300,
      "lat": 35.857498,
      "lon": 14.4775,
      "tz": "Europe\/Malta"
  },
  "LOAA": {
      "icao": "LOAA",
      "iata": "",
      "name": "Ottenschlag Airport",
      "city": "Ottenschlag",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 2867,
      "lat": 48.418598175,
      "lon": 15.2166996002,
      "tz": "Europe\/Vienna"
  },
  "LOAB": {
      "icao": "LOAB",
      "iata": "",
      "name": "Dobersberg Airport",
      "city": "Dobersberg",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 1719,
      "lat": 48.9233016968,
      "lon": 15.2966995239,
      "tz": "Europe\/Vienna"
  },
  "LOAD": {
      "icao": "LOAD",
      "iata": "",
      "name": "Voeltendorf Airport",
      "city": "Voeltendorf",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 1063,
      "lat": 48.1617012024,
      "lon": 15.5882997513,
      "tz": "Europe\/Vienna"
  },
  "LOAG": {
      "icao": "LOAG",
      "iata": "",
      "name": "Krems Airport",
      "city": "Krems",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 1022,
      "lat": 48.4468994141,
      "lon": 15.6350002289,
      "tz": "Europe\/Vienna"
  },
  "LOAL": {
      "icao": "LOAL",
      "iata": "",
      "name": "Leopoldsdorf Airport",
      "city": "Leopoldsdorf",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 486,
      "lat": 48.2266998291,
      "lon": 16.6721992493,
      "tz": "Europe\/Vienna"
  },
  "LOAN": {
      "icao": "LOAN",
      "iata": "",
      "name": "Wiener Neustadt East Airport",
      "city": "Wiener Neustadt",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 896,
      "lat": 47.8432998657,
      "lon": 16.260099411,
      "tz": "Europe\/Vienna"
  },
  "LOAR": {
      "icao": "LOAR",
      "iata": "",
      "name": "Altlichtenwarth Airport",
      "city": "Altlichtenwarth",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 604,
      "lat": 48.6660995483,
      "lon": 16.8250007629,
      "tz": "Europe\/Vienna"
  },
  "LOAS": {
      "icao": "LOAS",
      "iata": "",
      "name": "Spitzerberg Airport",
      "city": "Spitzerberg",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 600,
      "lat": 48.1021995544,
      "lon": 16.935300827,
      "tz": "Europe\/Vienna"
  },
  "LOAT": {
      "icao": "LOAT",
      "iata": "",
      "name": "Trausdorf Airport",
      "city": "Eisenstadt",
      "state": "Burgenland",
      "country": "AT",
      "elevation": 525,
      "lat": 47.7999992371,
      "lon": 16.5669994354,
      "tz": "Europe\/Vienna"
  },
  "LOAU": {
      "icao": "LOAU",
      "iata": "",
      "name": "Stockerau Airport",
      "city": "Stockerau",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 686,
      "lat": 48.4099998474,
      "lon": 16.1916999817,
      "tz": "Europe\/Vienna"
  },
  "LOAV": {
      "icao": "LOAV",
      "iata": "",
      "name": "Voslau Airport",
      "city": "Bad Voslau",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 765,
      "lat": 47.9650001526,
      "lon": 16.2600002289,
      "tz": "Europe\/Vienna"
  },
  "LOGF": {
      "icao": "LOGF",
      "iata": "",
      "name": "Fuerstenfeld Airport",
      "city": "Fuerstenfeld",
      "state": "Styria",
      "country": "AT",
      "elevation": 830,
      "lat": 47.0625,
      "lon": 16.0855998993,
      "tz": "Europe\/Vienna"
  },
  "LOGG": {
      "icao": "LOGG",
      "iata": "",
      "name": "Punitz-Gussing Airport",
      "city": "Punitz",
      "state": "Burgenland",
      "country": "AT",
      "elevation": 950,
      "lat": 47.1465301514,
      "lon": 16.3168430328,
      "tz": "Europe\/Vienna"
  },
  "LOGI": {
      "icao": "LOGI",
      "iata": "",
      "name": "Trieben Airport",
      "city": "Trieben",
      "state": "Styria",
      "country": "AT",
      "elevation": 2283,
      "lat": 47.4942016602,
      "lon": 14.4975004196,
      "tz": "Europe\/Vienna"
  },
  "LOGK": {
      "icao": "LOGK",
      "iata": "",
      "name": "Kapfenberg Airport",
      "city": "Kapfenberg",
      "state": "Styria",
      "country": "AT",
      "elevation": 1690,
      "lat": 47.4583015442,
      "lon": 15.3299999237,
      "tz": "Europe\/Vienna"
  },
  "LOGL": {
      "icao": "LOGL",
      "iata": "",
      "name": "Turnau Airport",
      "city": "Lanzen",
      "state": "Styria",
      "country": "AT",
      "elevation": 2575,
      "lat": 47.5567016602,
      "lon": 15.3249998093,
      "tz": "Europe\/Vienna"
  },
  "LOGM": {
      "icao": "LOGM",
      "iata": "",
      "name": "Mariazell Airport",
      "city": "Mariazell",
      "state": "Styria",
      "country": "AT",
      "elevation": 2822,
      "lat": 47.7882995605,
      "lon": 15.3016996384,
      "tz": "Europe\/Vienna"
  },
  "LOGO": {
      "icao": "LOGO",
      "iata": "",
      "name": "Niederoeblarn Airport",
      "city": "Niederoeblarn",
      "state": "Styria",
      "country": "AT",
      "elevation": 2139,
      "lat": 47.4782981873,
      "lon": 14.0082998276,
      "tz": "Europe\/Vienna"
  },
  "LOGP": {
      "icao": "LOGP",
      "iata": "",
      "name": "Pinkafeld Airport",
      "city": "Pinkafeld",
      "state": "Burgenland",
      "country": "AT",
      "elevation": 1339,
      "lat": 47.3866844177,
      "lon": 16.1126728058,
      "tz": "Europe\/Vienna"
  },
  "LOGT": {
      "icao": "LOGT",
      "iata": "",
      "name": "Timmersdorf Airport",
      "city": "Timmersdorf",
      "state": "Styria",
      "country": "AT",
      "elevation": 2060,
      "lat": 47.3800010681,
      "lon": 14.9682998657,
      "tz": "Europe\/Vienna"
  },
  "LOGW": {
      "icao": "LOGW",
      "iata": "",
      "name": "Unterfladnitz Airport",
      "city": "Weiz",
      "state": "Styria",
      "country": "AT",
      "elevation": 1296,
      "lat": 47.1707992554,
      "lon": 15.6653003693,
      "tz": "Europe\/Vienna"
  },
  "LOIH": {
      "icao": "LOIH",
      "iata": "HOH",
      "name": "Hohenems-Dornbirn Airport",
      "city": "Hohenems \/ Dornbirn",
      "state": "Vorarlberg",
      "country": "AT",
      "elevation": 1352,
      "lat": 47.3849983215,
      "lon": 9.6999998093,
      "tz": "Europe\/Vienna"
  },
  "LOIJ": {
      "icao": "LOIJ",
      "iata": "",
      "name": "St. Johann In Tirol Airport",
      "city": "St. Johann In Tirol",
      "state": "Tyrol",
      "country": "AT",
      "elevation": 2198,
      "lat": 47.5200996399,
      "lon": 12.4497003555,
      "tz": "Europe\/Vienna"
  },
  "LOIK": {
      "icao": "LOIK",
      "iata": "",
      "name": "Langkampfen Airport",
      "city": "Kufstein",
      "state": "Tyrol",
      "country": "AT",
      "elevation": 1588,
      "lat": 47.5651168823,
      "lon": 12.1280698776,
      "tz": "Europe\/Vienna"
  },
  "LOIR": {
      "icao": "LOIR",
      "iata": "",
      "name": "Hoefen Airport",
      "city": "Reutte",
      "state": "Tyrol",
      "country": "AT",
      "elevation": 2805,
      "lat": 47.4700012207,
      "lon": 10.6916999817,
      "tz": "Europe\/Vienna"
  },
  "LOKF": {
      "icao": "LOKF",
      "iata": "",
      "name": "Ossiacher See Airport",
      "city": "Feldkirchen",
      "state": "Carinthia",
      "country": "AT",
      "elevation": 1706,
      "lat": 46.7089004517,
      "lon": 14.0771999359,
      "tz": "Europe\/Vienna"
  },
  "LOKG": {
      "icao": "LOKG",
      "iata": "",
      "name": "Glainach Airport",
      "city": "Ferlach",
      "state": "Carinthia",
      "country": "AT",
      "elevation": 1499,
      "lat": 46.5332984924,
      "lon": 14.3332996368,
      "tz": "Europe\/Vienna"
  },
  "LOKH": {
      "icao": "LOKH",
      "iata": "",
      "name": "Hirt Airport",
      "city": "Friesach",
      "state": "Carinthia",
      "country": "AT",
      "elevation": 2018,
      "lat": 46.9282989502,
      "lon": 14.4333000183,
      "tz": "Europe\/Vienna"
  },
  "LOKL": {
      "icao": "LOKL",
      "iata": "",
      "name": "Nikolsdorf Airport",
      "city": "Lienz",
      "state": "Tyrol",
      "country": "AT",
      "elevation": 2087,
      "lat": 46.7988014221,
      "lon": 12.8767995834,
      "tz": "Europe\/Vienna"
  },
  "LOKM": {
      "icao": "LOKM",
      "iata": "",
      "name": "Mayerhofen Airport",
      "city": "Mayerhofen",
      "state": "Carinthia",
      "country": "AT",
      "elevation": 2119,
      "lat": 46.9757995605,
      "lon": 14.3717002869,
      "tz": "Europe\/Vienna"
  },
  "LOKN": {
      "icao": "LOKN",
      "iata": "",
      "name": "Notsch Im Gailtal Airport",
      "city": "Notsch Im Gailtal",
      "state": "Carinthia",
      "country": "AT",
      "elevation": 1801,
      "lat": 46.5811004639,
      "lon": 13.6291999817,
      "tz": "Europe\/Rome"
  },
  "LOKW": {
      "icao": "LOKW",
      "iata": "",
      "name": "Wolfsberg Airport",
      "city": "Wolfsberg",
      "state": "Carinthia",
      "country": "AT",
      "elevation": 1460,
      "lat": 46.8182983398,
      "lon": 14.8263998032,
      "tz": "Europe\/Vienna"
  },
  "LOLC": {
      "icao": "LOLC",
      "iata": "",
      "name": "Scharnstein Airport",
      "city": "Scharnstein",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 1742,
      "lat": 47.898601532,
      "lon": 13.9393997192,
      "tz": "Europe\/Vienna"
  },
  "LOLE": {
      "icao": "LOLE",
      "iata": "",
      "name": "Eferding Airport",
      "city": "Eferding",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 886,
      "lat": 48.3382987976,
      "lon": 13.9849996567,
      "tz": "Europe\/Vienna"
  },
  "LOLF": {
      "icao": "LOLF",
      "iata": "",
      "name": "Freistadt Airport",
      "city": "Freistadt",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 2238,
      "lat": 48.5149993896,
      "lon": 14.4083003998,
      "tz": "Europe\/Vienna"
  },
  "LOLG": {
      "icao": "LOLG",
      "iata": "",
      "name": "St. Georgen Am Ybbsfelde Airport",
      "city": "St. Georgen Am Ybbsfelde",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 827,
      "lat": 48.1035995483,
      "lon": 14.950799942,
      "tz": "Europe\/Vienna"
  },
  "LOLH": {
      "icao": "LOLH",
      "iata": "",
      "name": "Hb Hofkirchen Airport",
      "city": "Hofkirchen",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 1175,
      "lat": 48.1399993896,
      "lon": 14.3382997513,
      "tz": "Europe\/Vienna"
  },
  "LOLK": {
      "icao": "LOLK",
      "iata": "",
      "name": "Kirchheim Airport",
      "city": "Ried",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 1350,
      "lat": 48.2122001648,
      "lon": 13.345000267,
      "tz": "Europe\/Vienna"
  },
  "LOLM": {
      "icao": "LOLM",
      "iata": "",
      "name": "Micheldorf Airport",
      "city": "Micheldorf",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 1509,
      "lat": 47.8708000183,
      "lon": 14.125,
      "tz": "Europe\/Vienna"
  },
  "LOLO": {
      "icao": "LOLO",
      "iata": "",
      "name": "Linz-Ost Airport",
      "city": "Linz",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 827,
      "lat": 48.2999992371,
      "lon": 14.3332996368,
      "tz": "Europe\/Vienna"
  },
  "LOLS": {
      "icao": "LOLS",
      "iata": "",
      "name": "Schaerding-Suben Airport",
      "city": "Schaerding \/ Suben",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 1070,
      "lat": 48.403301239,
      "lon": 13.4483003616,
      "tz": "Europe\/Vienna"
  },
  "LOLT": {
      "icao": "LOLT",
      "iata": "",
      "name": "Seitenstetten Airport",
      "city": "Seitenstetten",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 1050,
      "lat": 48.0499992371,
      "lon": 14.6519002914,
      "tz": "Europe\/Vienna"
  },
  "LOLU": {
      "icao": "LOLU",
      "iata": "",
      "name": "Gmunden-Laakirchen Airport",
      "city": "Gmunden",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 1670,
      "lat": 47.9516983032,
      "lon": 13.8667001724,
      "tz": "Europe\/Vienna"
  },
  "LOLW": {
      "icao": "LOLW",
      "iata": "",
      "name": "Wels Airport",
      "city": "Wels",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 1043,
      "lat": 48.1833000183,
      "lon": 14.0409002304,
      "tz": "Europe\/Vienna"
  },
  "LOSM": {
      "icao": "LOSM",
      "iata": "",
      "name": "Mauterndorf Airport",
      "city": "Mauterndorf",
      "state": "Salzburg",
      "country": "AT",
      "elevation": 3642,
      "lat": 47.1324996948,
      "lon": 13.6969003677,
      "tz": "Europe\/Vienna"
  },
  "LOWG": {
      "icao": "LOWG",
      "iata": "GRZ",
      "name": "Graz Airport",
      "city": "Graz",
      "state": "Styria",
      "country": "AT",
      "elevation": 1115,
      "lat": 46.9911003113,
      "lon": 15.4395999908,
      "tz": "Europe\/Vienna"
  },
  "LOWI": {
      "icao": "LOWI",
      "iata": "INN",
      "name": "Innsbruck Airport",
      "city": "Innsbruck",
      "state": "Tyrol",
      "country": "AT",
      "elevation": 1906,
      "lat": 47.2602005005,
      "lon": 11.3439998627,
      "tz": "Europe\/Vienna"
  },
  "LOWK": {
      "icao": "LOWK",
      "iata": "KLU",
      "name": "Klagenfurt Airport",
      "city": "Klagenfurt am Worthersee",
      "state": "Carinthia",
      "country": "AT",
      "elevation": 1470,
      "lat": 46.6425018311,
      "lon": 14.3376998901,
      "tz": "Europe\/Vienna"
  },
  "LOWL": {
      "icao": "LOWL",
      "iata": "LNZ",
      "name": "Linz Airport",
      "city": "Linz",
      "state": "Upper-Austria",
      "country": "AT",
      "elevation": 978,
      "lat": 48.2332000732,
      "lon": 14.1875,
      "tz": "Europe\/Vienna"
  },
  "LOWS": {
      "icao": "LOWS",
      "iata": "SZG",
      "name": "Salzburg Airport",
      "city": "Salzburg",
      "state": "Salzburg",
      "country": "AT",
      "elevation": 1411,
      "lat": 47.7933006287,
      "lon": 13.0043001175,
      "tz": "Europe\/Berlin"
  },
  "LOWW": {
      "icao": "LOWW",
      "iata": "VIE",
      "name": "Vienna International Airport",
      "city": "Vienna",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 600,
      "lat": 48.1102981567,
      "lon": 16.5697002411,
      "tz": "Europe\/Vienna"
  },
  "LOWZ": {
      "icao": "LOWZ",
      "iata": "",
      "name": "Zell Am See Airport",
      "city": "Zell Am See",
      "state": "Salzburg",
      "country": "AT",
      "elevation": 2470,
      "lat": 47.2933006287,
      "lon": 12.7882995605,
      "tz": "Europe\/Vienna"
  },
  "LOXN": {
      "icao": "LOXN",
      "iata": "",
      "name": "Wiener Neustadt West Airport",
      "city": "Wiener Neustadt",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 935,
      "lat": 47.8400001526,
      "lon": 16.2217006683,
      "tz": "Europe\/Vienna"
  },
  "LOXT": {
      "icao": "LOXT",
      "iata": "",
      "name": "Brumowski  Air Base",
      "city": "Langenlebarn",
      "state": "Lower-Austria",
      "country": "AT",
      "elevation": 574,
      "lat": 48.3166999817,
      "lon": 16.1166992188,
      "tz": "Europe\/Vienna"
  },
  "LOXZ": {
      "icao": "LOXZ",
      "iata": "",
      "name": "Hinterstoisser Air Base",
      "city": "Zeltweg",
      "state": "Styria",
      "country": "AT",
      "elevation": 2220,
      "lat": 47.2028007507,
      "lon": 14.7441997528,
      "tz": "Europe\/Vienna"
  },
  "LP77": {
      "icao": "LP77",
      "iata": "",
      "name": "Santa Margarida Airport",
      "city": "",
      "state": "Santarem",
      "country": "PT",
      "elevation": 541,
      "lat": 39.4024009705,
      "lon": -8.289059639,
      "tz": "Europe\/Lisbon"
  },
  "LPAR": {
      "icao": "LPAR",
      "iata": "",
      "name": "Alverca Airport",
      "city": "",
      "state": "Lisbon",
      "country": "PT",
      "elevation": 11,
      "lat": 38.8833007813,
      "lon": -9.0300998688,
      "tz": "Europe\/Lisbon"
  },
  "LPAV": {
      "icao": "LPAV",
      "iata": "",
      "name": "Aveiro Airport",
      "city": "",
      "state": "Aveiro",
      "country": "PT",
      "elevation": 26,
      "lat": 40.6542015076,
      "lon": -8.7435503006,
      "tz": "Europe\/Lisbon"
  },
  "LPAZ": {
      "icao": "LPAZ",
      "iata": "SMA",
      "name": "Santa Maria Airport",
      "city": "Vila do Porto",
      "state": "Azores",
      "country": "PT",
      "elevation": 308,
      "lat": 36.9714012146,
      "lon": -25.1706008911,
      "tz": "Atlantic\/Azores"
  },
  "LPBG": {
      "icao": "LPBG",
      "iata": "BGC",
      "name": "Braganca Airport",
      "city": "",
      "state": "Braganca",
      "country": "PT",
      "elevation": 2241,
      "lat": 41.85779953,
      "lon": -6.7071299553,
      "tz": "Europe\/Lisbon"
  },
  "LPBJ": {
      "icao": "LPBJ",
      "iata": "BYJ",
      "name": "Beja International Airport",
      "city": "Beja",
      "state": "Beja",
      "country": "PT",
      "elevation": 636,
      "lat": 38.0788993835,
      "lon": -7.9324002266,
      "tz": "Europe\/Lisbon"
  },
  "LPBR": {
      "icao": "LPBR",
      "iata": "BGZ",
      "name": "Braga Municipal Aerodrome",
      "city": "Braga",
      "state": "Braga",
      "country": "PT",
      "elevation": 247,
      "lat": 41.5871009827,
      "lon": -8.445139885,
      "tz": "Europe\/Lisbon"
  },
  "LPCH": {
      "icao": "LPCH",
      "iata": "CHV",
      "name": "Chaves Airport",
      "city": "Chaves",
      "state": "Vila-Real",
      "country": "PT",
      "elevation": 0,
      "lat": 41.7223243713,
      "lon": -7.4631500244,
      "tz": "Europe\/Lisbon"
  },
  "LPCO": {
      "icao": "LPCO",
      "iata": "CBP",
      "name": "Coimbra Airport",
      "city": "",
      "state": "Coimbra",
      "country": "PT",
      "elevation": 587,
      "lat": 40.1571998596,
      "lon": -8.470000267,
      "tz": "Europe\/Lisbon"
  },
  "LPCR": {
      "icao": "LPCR",
      "iata": "CVU",
      "name": "Corvo Airport",
      "city": "Corvo",
      "state": "Azores",
      "country": "PT",
      "elevation": 0,
      "lat": 39.6715011597,
      "lon": -31.1135997772,
      "tz": "Atlantic\/Azores"
  },
  "LPCS": {
      "icao": "LPCS",
      "iata": "CAT",
      "name": "Cascais Airport",
      "city": "",
      "state": "Lisbon",
      "country": "PT",
      "elevation": 325,
      "lat": 38.7249984741,
      "lon": -9.3552303314,
      "tz": "Europe\/Lisbon"
  },
  "LPCV": {
      "icao": "LPCV",
      "iata": "COV",
      "name": "Covilha Airport",
      "city": "",
      "state": "Castelo-Branco",
      "country": "PT",
      "elevation": 1572,
      "lat": 40.2648010254,
      "lon": -7.4799599648,
      "tz": "Europe\/Lisbon"
  },
  "LPEV": {
      "icao": "LPEV",
      "iata": "",
      "name": "Evora Airport",
      "city": "",
      "state": "Evora",
      "country": "PT",
      "elevation": 807,
      "lat": 38.5335006714,
      "lon": -7.8896398544,
      "tz": "Europe\/Lisbon"
  },
  "LPFL": {
      "icao": "LPFL",
      "iata": "FLW",
      "name": "Flores Airport",
      "city": "Santa Cruz das Flores",
      "state": "Azores",
      "country": "PT",
      "elevation": 112,
      "lat": 39.4552993774,
      "lon": -31.1313991547,
      "tz": "Atlantic\/Azores"
  },
  "LPFR": {
      "icao": "LPFR",
      "iata": "FAO",
      "name": "Faro Airport",
      "city": "Faro",
      "state": "Faro",
      "country": "PT",
      "elevation": 24,
      "lat": 37.0144004822,
      "lon": -7.9659099579,
      "tz": "Europe\/Lisbon"
  },
  "LPGR": {
      "icao": "LPGR",
      "iata": "GRW",
      "name": "Graciosa Airport",
      "city": "Santa Cruz da Graciosa",
      "state": "Azores",
      "country": "PT",
      "elevation": 86,
      "lat": 39.0922012329,
      "lon": -28.029800415,
      "tz": "Atlantic\/Azores"
  },
  "LPHR": {
      "icao": "LPHR",
      "iata": "HOR",
      "name": "Horta Airport",
      "city": "Horta",
      "state": "Azores",
      "country": "PT",
      "elevation": 118,
      "lat": 38.5199012756,
      "lon": -28.7159004211,
      "tz": "Atlantic\/Azores"
  },
  "LPIN": {
      "icao": "LPIN",
      "iata": "",
      "name": "Espinho Airport",
      "city": "",
      "state": "Porto",
      "country": "PT",
      "elevation": 10,
      "lat": 40.9742012024,
      "lon": -8.6444396973,
      "tz": "Europe\/Lisbon"
  },
  "LPJF": {
      "icao": "LPJF",
      "iata": "",
      "name": "Leiria Gandara Airport",
      "city": "Leiria",
      "state": "Leiria",
      "country": "PT",
      "elevation": 151,
      "lat": 39.7785987854,
      "lon": -8.8197202682,
      "tz": "Europe\/Lisbon"
  },
  "LPJO": {
      "icao": "LPJO",
      "iata": "",
      "name": "Alijo Airport",
      "city": "Alijo",
      "state": "Vila-Real",
      "country": "PT",
      "elevation": 2562,
      "lat": 41.3252983093,
      "lon": -7.4461097717,
      "tz": "Europe\/Lisbon"
  },
  "LPLA": {
      "icao": "LPLA",
      "iata": "TER",
      "name": "Lajes Field",
      "city": "Lajes",
      "state": "Azores",
      "country": "PT",
      "elevation": 180,
      "lat": 38.7617988586,
      "lon": -27.0907993317,
      "tz": "Atlantic\/Azores"
  },
  "LPLZ": {
      "icao": "LPLZ",
      "iata": "",
      "name": "Lousa Private Airport",
      "city": "Lousa",
      "state": "Coimbra",
      "country": "PT",
      "elevation": 669,
      "lat": 40.1342010498,
      "lon": -8.2325000763,
      "tz": "Europe\/Lisbon"
  },
  "LPMA": {
      "icao": "LPMA",
      "iata": "FNC",
      "name": "Madeira Airport",
      "city": "Funchal",
      "state": "Madeira",
      "country": "PT",
      "elevation": 192,
      "lat": 32.6978988647,
      "lon": -16.7744998932,
      "tz": "Atlantic\/Madeira"
  },
  "LPMF": {
      "icao": "LPMF",
      "iata": "",
      "name": "Monfortinho Airport",
      "city": "Monfortinho",
      "state": "Castelo-Branco",
      "country": "PT",
      "elevation": 0,
      "lat": 39.976398468,
      "lon": -6.9066238403,
      "tz": "Europe\/Lisbon"
  },
  "LPMI": {
      "icao": "LPMI",
      "iata": "",
      "name": "Mirandela Airport",
      "city": "Mirandela",
      "state": "Braganca",
      "country": "PT",
      "elevation": 0,
      "lat": 41.468006134,
      "lon": -7.2262573242,
      "tz": "Europe\/Lisbon"
  },
  "LPMO": {
      "icao": "LPMO",
      "iata": "",
      "name": "Montargil Airport",
      "city": "Montargil",
      "state": "Evora",
      "country": "PT",
      "elevation": 341,
      "lat": 38.9958000183,
      "lon": -8.14083004,
      "tz": "Europe\/Lisbon"
  },
  "LPMR": {
      "icao": "LPMR",
      "iata": "",
      "name": "Monte Real Airport",
      "city": "",
      "state": "Leiria",
      "country": "PT",
      "elevation": 187,
      "lat": 39.831199646,
      "lon": -8.887260437,
      "tz": "Europe\/Lisbon"
  },
  "LPMT": {
      "icao": "LPMT",
      "iata": "",
      "name": "Montijo Airport",
      "city": "Montijo",
      "state": "Setubal",
      "country": "PT",
      "elevation": 46,
      "lat": 38.7038993835,
      "lon": -9.0359201431,
      "tz": "Europe\/Lisbon"
  },
  "LPMU": {
      "icao": "LPMU",
      "iata": "",
      "name": "Mogadouro Airport",
      "city": "Mogadouro",
      "state": "Braganca",
      "country": "PT",
      "elevation": 2349,
      "lat": 41.396900177,
      "lon": -6.6816701889,
      "tz": "Europe\/Lisbon"
  },
  "LPOT": {
      "icao": "LPOT",
      "iata": "",
      "name": "Ota Airport",
      "city": "Ota",
      "state": "Lisbon",
      "country": "PT",
      "elevation": 140,
      "lat": 39.0875015259,
      "lon": -8.9627799988,
      "tz": "Europe\/Lisbon"
  },
  "LPOV": {
      "icao": "LPOV",
      "iata": "",
      "name": "Ovar Airport",
      "city": "",
      "state": "Aveiro",
      "country": "PT",
      "elevation": 56,
      "lat": 40.9159011841,
      "lon": -8.6459197998,
      "tz": "Europe\/Lisbon"
  },
  "LPPD": {
      "icao": "LPPD",
      "iata": "PDL",
      "name": "Joao Paulo II Airport",
      "city": "Ponta Delgada",
      "state": "Azores",
      "country": "PT",
      "elevation": 259,
      "lat": 37.7411994934,
      "lon": -25.6979007721,
      "tz": "Atlantic\/Azores"
  },
  "LPPI": {
      "icao": "LPPI",
      "iata": "PIX",
      "name": "Pico Airport",
      "city": "Pico Island",
      "state": "Azores",
      "country": "PT",
      "elevation": 109,
      "lat": 38.5542984009,
      "lon": -28.4412994385,
      "tz": "Atlantic\/Azores"
  },
  "LPPM": {
      "icao": "LPPM",
      "iata": "PRM",
      "name": "Portimao Airport",
      "city": "",
      "state": "Faro",
      "country": "PT",
      "elevation": 5,
      "lat": 37.1492996216,
      "lon": -8.5839595795,
      "tz": "Europe\/Lisbon"
  },
  "LPPR": {
      "icao": "LPPR",
      "iata": "OPO",
      "name": "Francisco de Sa Carneiro Airport",
      "city": "Porto",
      "state": "Porto",
      "country": "PT",
      "elevation": 228,
      "lat": 41.2481002808,
      "lon": -8.6813898087,
      "tz": "Europe\/Lisbon"
  },
  "LPPS": {
      "icao": "LPPS",
      "iata": "PXO",
      "name": "Porto Santo Airport",
      "city": "Vila Baleira",
      "state": "Madeira",
      "country": "PT",
      "elevation": 341,
      "lat": 33.0733985901,
      "lon": -16.3500003815,
      "tz": "Atlantic\/Madeira"
  },
  "LPPT": {
      "icao": "LPPT",
      "iata": "LIS",
      "name": "Lisbon Portela Airport",
      "city": "Lisbon",
      "state": "Lisbon",
      "country": "PT",
      "elevation": 374,
      "lat": 38.7812995911,
      "lon": -9.1359195709,
      "tz": "Europe\/Lisbon"
  },
  "LPPV": {
      "icao": "LPPV",
      "iata": "",
      "name": "Praia Verde 23M Airport",
      "city": "",
      "state": "Faro",
      "country": "PT",
      "elevation": 10,
      "lat": 37.1925010681,
      "lon": -7.4730601311,
      "tz": "Europe\/Lisbon"
  },
  "LPSC": {
      "icao": "LPSC",
      "iata": "",
      "name": "Santa Cruz Airport",
      "city": "",
      "state": "Lisbon",
      "country": "PT",
      "elevation": 0,
      "lat": 39.1246986389,
      "lon": -9.3811302185,
      "tz": "Europe\/Lisbon"
  },
  "LPSI": {
      "icao": "LPSI",
      "iata": "SIE",
      "name": "Sines Airport",
      "city": "",
      "state": "Setubal",
      "country": "PT",
      "elevation": 0,
      "lat": 37.9416923523,
      "lon": -8.8173007965,
      "tz": "Europe\/Lisbon"
  },
  "LPSJ": {
      "icao": "LPSJ",
      "iata": "SJZ",
      "name": "Sao Jorge Airport",
      "city": "Velas",
      "state": "Azores",
      "country": "PT",
      "elevation": 311,
      "lat": 38.6655006409,
      "lon": -28.1758003235,
      "tz": "Atlantic\/Azores"
  },
  "LPSO": {
      "icao": "LPSO",
      "iata": "",
      "name": "Ponte De Sor",
      "city": "Ponte De Sor",
      "state": "Portalegre",
      "country": "PT",
      "elevation": 390,
      "lat": 39.208089,
      "lon": -8.057534,
      "tz": "Europe\/Lisbon"
  },
  "LPSR": {
      "icao": "LPSR",
      "iata": "",
      "name": "Santarem Airport",
      "city": "Santarem",
      "state": "Santarem",
      "country": "PT",
      "elevation": 0,
      "lat": 39.2094993591,
      "lon": -8.6883001328,
      "tz": "Europe\/Lisbon"
  },
  "LPST": {
      "icao": "LPST",
      "iata": "",
      "name": "Sintra Airport",
      "city": "",
      "state": "Lisbon",
      "country": "PT",
      "elevation": 440,
      "lat": 38.8311004639,
      "lon": -9.3395500183,
      "tz": "Europe\/Lisbon"
  },
  "LPTN": {
      "icao": "LPTN",
      "iata": "",
      "name": "Tancos Airport",
      "city": "",
      "state": "Santarem",
      "country": "PT",
      "elevation": 266,
      "lat": 39.4751014709,
      "lon": -8.3645801544,
      "tz": "Europe\/Lisbon"
  },
  "LPVL": {
      "icao": "LPVL",
      "iata": "",
      "name": "Vilar Da Luz Airport",
      "city": "Maia",
      "state": "Porto",
      "country": "PT",
      "elevation": 0,
      "lat": 41.2772903442,
      "lon": -8.5162067413,
      "tz": "Europe\/Lisbon"
  },
  "LPVR": {
      "icao": "LPVR",
      "iata": "VRL",
      "name": "Vila Real Airport",
      "city": "",
      "state": "Vila-Real",
      "country": "PT",
      "elevation": 1805,
      "lat": 41.2742996216,
      "lon": -7.7204699516,
      "tz": "Europe\/Lisbon"
  },
  "LPVZ": {
      "icao": "LPVZ",
      "iata": "VSE",
      "name": "Viseu Airport",
      "city": "",
      "state": "Viseu",
      "country": "PT",
      "elevation": 2060,
      "lat": 40.7254981995,
      "lon": -7.8889899254,
      "tz": "Europe\/Lisbon"
  },
  "LQBI": {
      "icao": "LQBI",
      "iata": "",
      "name": "Bihac Golubic Airport",
      "city": "Bihac",
      "state": "Federation-of-B&H",
      "country": "BA",
      "elevation": 761,
      "lat": 44.7959367906,
      "lon": 15.9035682678,
      "tz": "Europe\/Sarajevo"
  },
  "LQBK": {
      "icao": "LQBK",
      "iata": "BNX",
      "name": "Banja Luka International Airport",
      "city": "Banja Luka",
      "state": "Srspka",
      "country": "BA",
      "elevation": 400,
      "lat": 44.9413986206,
      "lon": 17.2975006104,
      "tz": "Europe\/Sarajevo"
  },
  "LQBZ": {
      "icao": "LQBZ",
      "iata": "",
      "name": "Zaluzani Airport",
      "city": "Banja Luka",
      "state": "Srspka",
      "country": "BA",
      "elevation": 541,
      "lat": 44.7913856506,
      "lon": 17.2146568298,
      "tz": "Europe\/Sarajevo"
  },
  "LQJL": {
      "icao": "LQJL",
      "iata": "",
      "name": "Tuzla Jegin Lug Sport Airfield",
      "city": "Tuzla",
      "state": "Federation-of-B&H",
      "country": "BA",
      "elevation": 869,
      "lat": 44.4578018188,
      "lon": 18.8083000183,
      "tz": "Europe\/Sarajevo"
  },
  "LQLV": {
      "icao": "LQLV",
      "iata": "",
      "name": "Livno Brda Bosni Airport",
      "city": "Livno",
      "state": "Federation-of-B&H",
      "country": "BA",
      "elevation": 2349,
      "lat": 43.7930984497,
      "lon": 16.9069004059,
      "tz": "Europe\/Sarajevo"
  },
  "LQMO": {
      "icao": "LQMO",
      "iata": "OMO",
      "name": "Mostar International Airport",
      "city": "Mostar",
      "state": "Federation-of-B&H",
      "country": "BA",
      "elevation": 156,
      "lat": 43.2829017639,
      "lon": 17.8458995819,
      "tz": "Europe\/Sarajevo"
  },
  "LQPD": {
      "icao": "LQPD",
      "iata": "",
      "name": "Prijedor Urije Airport",
      "city": "Prijedor",
      "state": "Srspka",
      "country": "BA",
      "elevation": 591,
      "lat": 44.9880981445,
      "lon": 16.7357997894,
      "tz": "Europe\/Sarajevo"
  },
  "LQSA": {
      "icao": "LQSA",
      "iata": "SJJ",
      "name": "Sarajevo International Airport",
      "city": "Sarajevo",
      "state": "Federation-of-B&H",
      "country": "BA",
      "elevation": 1708,
      "lat": 43.8246002197,
      "lon": 18.3314990997,
      "tz": "Europe\/Sarajevo"
  },
  "LQTZ": {
      "icao": "LQTZ",
      "iata": "TZL",
      "name": "Tuzla International Airport",
      "city": "Tuzla",
      "state": "Federation-of-B&H",
      "country": "BA",
      "elevation": 784,
      "lat": 44.4586982727,
      "lon": 18.7248001099,
      "tz": "Europe\/Sarajevo"
  },
  "LQVI": {
      "icao": "LQVI",
      "iata": "",
      "name": "Visoko Sport Airfield",
      "city": "Visoko",
      "state": "Federation-of-B&H",
      "country": "BA",
      "elevation": 1470,
      "lat": 44.0249769612,
      "lon": 18.0970573425,
      "tz": "Europe\/Sarajevo"
  },
  "LR79": {
      "icao": "LR79",
      "iata": "",
      "name": "Ianca Air Base",
      "city": "Ianca",
      "state": "Braila",
      "country": "RO",
      "elevation": 131,
      "lat": 45.1586990356,
      "lon": 27.4309005737,
      "tz": "Europe\/Bucharest"
  },
  "LR80": {
      "icao": "LR80",
      "iata": "",
      "name": "Fetesti Air Base",
      "city": "Fetesti",
      "state": "Calara\u015fi",
      "country": "RO",
      "elevation": 180,
      "lat": 44.3922996521,
      "lon": 27.7266998291,
      "tz": "Europe\/Bucharest"
  },
  "LR82": {
      "icao": "LR82",
      "iata": "",
      "name": "Boboc Air Base",
      "city": "Boboc",
      "state": "Buzau",
      "country": "RO",
      "elevation": 344,
      "lat": 45.2164001465,
      "lon": 26.9785995483,
      "tz": "Europe\/Bucharest"
  },
  "LRAR": {
      "icao": "LRAR",
      "iata": "ARW",
      "name": "Arad International Airport",
      "city": "Arad",
      "state": "Arad",
      "country": "RO",
      "elevation": 352,
      "lat": 46.1766014099,
      "lon": 21.2619991302,
      "tz": "Europe\/Bucharest"
  },
  "LRBC": {
      "icao": "LRBC",
      "iata": "BCM",
      "name": "Bacau Airport",
      "city": "Bacau",
      "state": "Bacau",
      "country": "RO",
      "elevation": 607,
      "lat": 46.521900177,
      "lon": 26.9102993011,
      "tz": "Europe\/Bucharest"
  },
  "LRBM": {
      "icao": "LRBM",
      "iata": "BAY",
      "name": "Tautii Magheraus Airport",
      "city": "Baia Mare",
      "state": "Maramure\u015f",
      "country": "RO",
      "elevation": 605,
      "lat": 47.6584014893,
      "lon": 23.4699993134,
      "tz": "Europe\/Bucharest"
  },
  "LRBS": {
      "icao": "LRBS",
      "iata": "BBU",
      "name": "Baneasa International Airport",
      "city": "Bucharest",
      "state": "Bucure\u015fti",
      "country": "RO",
      "elevation": 297,
      "lat": 44.503200531,
      "lon": 26.1021003723,
      "tz": "Europe\/Bucharest"
  },
  "LRCK": {
      "icao": "LRCK",
      "iata": "CND",
      "name": "Mihail Kogalniceanu International Airport",
      "city": "Constanta",
      "state": "Constan\u021ba",
      "country": "RO",
      "elevation": 353,
      "lat": 44.3622016907,
      "lon": 28.4883003235,
      "tz": "Europe\/Bucharest"
  },
  "LRCL": {
      "icao": "LRCL",
      "iata": "CLJ",
      "name": "Cluj-Napoca International Airport",
      "city": "Cluj-Napoca",
      "state": "Cluj",
      "country": "RO",
      "elevation": 1036,
      "lat": 46.7851982117,
      "lon": 23.6861991882,
      "tz": "Europe\/Bucharest"
  },
  "LRCS": {
      "icao": "LRCS",
      "iata": "CSB",
      "name": "Caransebes Airport",
      "city": "Caransebes",
      "state": "Cara\u015f-Severin",
      "country": "RO",
      "elevation": 866,
      "lat": 45.4199981689,
      "lon": 22.2532997131,
      "tz": "Europe\/Bucharest"
  },
  "LRCT": {
      "icao": "LRCT",
      "iata": "",
      "name": "Campia Turzii Air Base",
      "city": "Campia Turzii",
      "state": "Cluj",
      "country": "RO",
      "elevation": 1083,
      "lat": 46.5023002625,
      "lon": 23.8859004974,
      "tz": "Europe\/Bucharest"
  },
  "LRCV": {
      "icao": "LRCV",
      "iata": "CRA",
      "name": "Craiova Airport",
      "city": "Craiova",
      "state": "Dolj",
      "country": "RO",
      "elevation": 626,
      "lat": 44.3180999756,
      "lon": 23.8885993958,
      "tz": "Europe\/Bucharest"
  },
  "LRIA": {
      "icao": "LRIA",
      "iata": "IAS",
      "name": "Iasi Airport",
      "city": "Iasi",
      "state": "Ia\u015fi",
      "country": "RO",
      "elevation": 397,
      "lat": 47.1785011292,
      "lon": 27.6205997467,
      "tz": "Europe\/Bucharest"
  },
  "LROD": {
      "icao": "LROD",
      "iata": "OMR",
      "name": "Oradea International Airport",
      "city": "Oradea",
      "state": "Bihor",
      "country": "RO",
      "elevation": 465,
      "lat": 47.0252990723,
      "lon": 21.9025001526,
      "tz": "Europe\/Bucharest"
  },
  "LROP": {
      "icao": "LROP",
      "iata": "OTP",
      "name": "Henri Coanda International Airport",
      "city": "Bucharest",
      "state": "Ilfov",
      "country": "RO",
      "elevation": 314,
      "lat": 44.5722007751,
      "lon": 26.1021995544,
      "tz": "Europe\/Bucharest"
  },
  "LRSB": {
      "icao": "LRSB",
      "iata": "SBZ",
      "name": "Sibiu International Airport",
      "city": "Sibiu",
      "state": "Sibiu",
      "country": "RO",
      "elevation": 1496,
      "lat": 45.7855987549,
      "lon": 24.091299057,
      "tz": "Europe\/Bucharest"
  },
  "LRSM": {
      "icao": "LRSM",
      "iata": "SUJ",
      "name": "Satu Mare Airport",
      "city": "Satu Mare",
      "state": "Satu-Mare",
      "country": "RO",
      "elevation": 405,
      "lat": 47.7033004761,
      "lon": 22.8857002258,
      "tz": "Europe\/Bucharest"
  },
  "LRSV": {
      "icao": "LRSV",
      "iata": "SCV",
      "name": "Suceava Stefan cel Mare Airport",
      "city": "Suceava",
      "state": "Suceava",
      "country": "RO",
      "elevation": 1375,
      "lat": 47.6875,
      "lon": 26.3540992737,
      "tz": "Europe\/Bucharest"
  },
  "LRTC": {
      "icao": "LRTC",
      "iata": "TCE",
      "name": "Tulcea Airport",
      "city": "Tulcea",
      "state": "Tulcea",
      "country": "RO",
      "elevation": 200,
      "lat": 45.0625,
      "lon": 28.7143001556,
      "tz": "Europe\/Bucharest"
  },
  "LRTM": {
      "icao": "LRTM",
      "iata": "TGM",
      "name": "Transilvania Targu Mures International Airport",
      "city": "Targu Mures",
      "state": "Mure\u015f",
      "country": "RO",
      "elevation": 963,
      "lat": 46.4677009583,
      "lon": 24.4125003815,
      "tz": "Europe\/Bucharest"
  },
  "LRTR": {
      "icao": "LRTR",
      "iata": "TSR",
      "name": "Timisoara Traian Vuia Airport",
      "city": "Timisoara",
      "state": "Timi\u015f",
      "country": "RO",
      "elevation": 348,
      "lat": 45.8098983765,
      "lon": 21.3379001617,
      "tz": "Europe\/Bucharest"
  },
  "LRTZ": {
      "icao": "LRTZ",
      "iata": "",
      "name": "Tuzla Romania Airport",
      "city": "Tuzla",
      "state": "Constan\u021ba",
      "country": "RO",
      "elevation": 161,
      "lat": 43.9841995239,
      "lon": 28.6096992493,
      "tz": "Europe\/Bucharest"
  },
  "LS00": {
      "icao": "LS00",
      "iata": "",
      "name": "Whitehead Ultralightport",
      "city": "Zachary",
      "state": "Louisiana",
      "country": "US",
      "elevation": 105,
      "lat": 30.6755008698,
      "lon": -91.1143035889,
      "tz": "America\/Chicago"
  },
  "LS03": {
      "icao": "LS03",
      "iata": "",
      "name": "Stephen's Flying Service Airport",
      "city": "Winnsboro",
      "state": "Louisiana",
      "country": "US",
      "elevation": 89,
      "lat": 32.2933006287,
      "lon": -91.5932998657,
      "tz": "America\/Chicago"
  },
  "LS04": {
      "icao": "LS04",
      "iata": "",
      "name": "Bobby Jones Flying Service Inc Airport",
      "city": "Mangham",
      "state": "Louisiana",
      "country": "US",
      "elevation": 69,
      "lat": 32.3166999817,
      "lon": -91.8395004272,
      "tz": "America\/Chicago"
  },
  "LS09": {
      "icao": "LS09",
      "iata": "",
      "name": "Sylvester's Airport",
      "city": "Ville Platte",
      "state": "Louisiana",
      "country": "US",
      "elevation": 70,
      "lat": 30.6958007813,
      "lon": -92.3175964355,
      "tz": "America\/Chicago"
  },
  "LS10": {
      "icao": "LS10",
      "iata": "",
      "name": "Reynolds Airport",
      "city": "Westlake",
      "state": "Louisiana",
      "country": "US",
      "elevation": 20,
      "lat": 30.2721004486,
      "lon": -93.3117980957,
      "tz": "America\/Chicago"
  },
  "LS11": {
      "icao": "LS11",
      "iata": "",
      "name": "Liddieville Airport",
      "city": "Winnsboro",
      "state": "Louisiana",
      "country": "US",
      "elevation": 73,
      "lat": 32.1534996033,
      "lon": -91.8436965942,
      "tz": "America\/Chicago"
  },
  "LS14": {
      "icao": "LS14",
      "iata": "",
      "name": "Lyon Airport",
      "city": "Thornwell",
      "state": "Louisiana",
      "country": "US",
      "elevation": 6,
      "lat": 30.0709991455,
      "lon": -92.8251037598,
      "tz": "America\/Chicago"
  },
  "LS16": {
      "icao": "LS16",
      "iata": "",
      "name": "Circle G Airport",
      "city": "Jigger",
      "state": "Louisiana",
      "country": "US",
      "elevation": 75,
      "lat": 32.0112991333,
      "lon": -91.7550964355,
      "tz": "America\/Chicago"
  },
  "LS18": {
      "icao": "LS18",
      "iata": "",
      "name": "Ag Aviation Airport",
      "city": "Welsh",
      "state": "Louisiana",
      "country": "US",
      "elevation": 21,
      "lat": 30.2238998413,
      "lon": -92.7786026001,
      "tz": "America\/Chicago"
  },
  "LS21": {
      "icao": "LS21",
      "iata": "",
      "name": "Southland Strip Ultralightport",
      "city": "Houma",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.6704998016,
      "lon": -90.7705993652,
      "tz": "America\/Chicago"
  },
  "LS25": {
      "icao": "LS25",
      "iata": "",
      "name": "Annison Private Airport",
      "city": "Slaughter",
      "state": "Louisiana",
      "country": "US",
      "elevation": 120,
      "lat": 30.7045993805,
      "lon": -91.0606994629,
      "tz": "America\/Chicago"
  },
  "LS34": {
      "icao": "LS34",
      "iata": "",
      "name": "Bordelon Airpark",
      "city": "Breaux Bridge",
      "state": "Louisiana",
      "country": "US",
      "elevation": 22,
      "lat": 30.3066997528,
      "lon": -91.8764038086,
      "tz": "America\/Chicago"
  },
  "LS35": {
      "icao": "LS35",
      "iata": "",
      "name": "Nauga Field",
      "city": "St Francisville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 38,
      "lat": 30.7625007629,
      "lon": -91.2772979736,
      "tz": "America\/Chicago"
  },
  "LS36": {
      "icao": "LS36",
      "iata": "",
      "name": "Light Plane Flyers Airfield",
      "city": "Iowa",
      "state": "Louisiana",
      "country": "US",
      "elevation": 25,
      "lat": 30.2502002716,
      "lon": -92.9720001221,
      "tz": "America\/Chicago"
  },
  "LS38": {
      "icao": "LS38",
      "iata": "",
      "name": "Richard's Airport",
      "city": "New Iberia",
      "state": "Louisiana",
      "country": "US",
      "elevation": 15,
      "lat": 30.0130996704,
      "lon": -91.8692016602,
      "tz": "America\/Chicago"
  },
  "LS39": {
      "icao": "LS39",
      "iata": "",
      "name": "Country Bend Airport",
      "city": "Watson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 49,
      "lat": 30.5893993378,
      "lon": -90.9845962524,
      "tz": "America\/Chicago"
  },
  "LS40": {
      "icao": "LS40",
      "iata": "",
      "name": "St Charles Airport",
      "city": "Ama",
      "state": "Louisiana",
      "country": "US",
      "elevation": 13,
      "lat": 29.9519004822,
      "lon": -90.2861022949,
      "tz": "America\/Chicago"
  },
  "LS41": {
      "icao": "LS41",
      "iata": "",
      "name": "Tater Patch Ultralightport",
      "city": "Blanchard",
      "state": "Louisiana",
      "country": "US",
      "elevation": 240,
      "lat": 32.5671005249,
      "lon": -93.8788986206,
      "tz": "America\/Chicago"
  },
  "LS43": {
      "icao": "LS43",
      "iata": "",
      "name": "Southland Strip Nr 2 Ultralightport",
      "city": "Gray",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.6718997955,
      "lon": -90.7676010132,
      "tz": "America\/Chicago"
  },
  "LS44": {
      "icao": "LS44",
      "iata": "",
      "name": "Ken Guidry No 3 Airport",
      "city": "Kaplan",
      "state": "Louisiana",
      "country": "US",
      "elevation": 15,
      "lat": 30.0898990631,
      "lon": -92.3436965942,
      "tz": "America\/Chicago"
  },
  "LS46": {
      "icao": "LS46",
      "iata": "",
      "name": "Blount Airport",
      "city": "Monterey",
      "state": "Louisiana",
      "country": "US",
      "elevation": 50,
      "lat": 31.4584999084,
      "lon": -91.8228988647,
      "tz": "America\/Chicago"
  },
  "LS49": {
      "icao": "LS49",
      "iata": "",
      "name": "Simpson Airport",
      "city": "Plaquemine",
      "state": "Louisiana",
      "country": "US",
      "elevation": 13,
      "lat": 30.3027000427,
      "lon": -91.2714996338,
      "tz": "America\/Chicago"
  },
  "LS54": {
      "icao": "LS54",
      "iata": "",
      "name": "Brian's Ultralightport",
      "city": "Zachary",
      "state": "Louisiana",
      "country": "US",
      "elevation": 65,
      "lat": 30.6919002533,
      "lon": -91.1875991821,
      "tz": "America\/Chicago"
  },
  "LS59": {
      "icao": "LS59",
      "iata": "",
      "name": "La Petite Airdrome Ultralightport",
      "city": "Opelousas",
      "state": "Louisiana",
      "country": "US",
      "elevation": 63,
      "lat": 30.6294002533,
      "lon": -92.1878967285,
      "tz": "America\/Chicago"
  },
  "LS68": {
      "icao": "LS68",
      "iata": "",
      "name": "Ken Guidry Nr 1 Airport",
      "city": "Abbeville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 10,
      "lat": 29.9347000122,
      "lon": -92.2135009766,
      "tz": "America\/Chicago"
  },
  "LS69": {
      "icao": "LS69",
      "iata": "",
      "name": "L J Earnest Airport",
      "city": "Benton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 165,
      "lat": 32.6435012817,
      "lon": -93.662399292,
      "tz": "America\/Chicago"
  },
  "LS72": {
      "icao": "LS72",
      "iata": "",
      "name": "Aerolite Aero Park Ultralightport",
      "city": "Welsh",
      "state": "Louisiana",
      "country": "US",
      "elevation": 13,
      "lat": 30.202999115,
      "lon": -92.8121032715,
      "tz": "America\/Chicago"
  },
  "LS76": {
      "icao": "LS76",
      "iata": "",
      "name": "Hickham Field",
      "city": "Covington",
      "state": "Louisiana",
      "country": "US",
      "elevation": 25,
      "lat": 30.4626998901,
      "lon": -90.1700973511,
      "tz": "America\/Chicago"
  },
  "LS77": {
      "icao": "LS77",
      "iata": "",
      "name": "A & P Airpark",
      "city": "Slaughter",
      "state": "Louisiana",
      "country": "US",
      "elevation": 140,
      "lat": 30.7271003723,
      "lon": -91.1485977173,
      "tz": "America\/Chicago"
  },
  "LS83": {
      "icao": "LS83",
      "iata": "",
      "name": "Kifer Airport",
      "city": "Newellton",
      "state": "Louisiana",
      "country": "US",
      "elevation": 77,
      "lat": 32.0626564026,
      "lon": -91.2542800903,
      "tz": "America\/Chicago"
  },
  "LS86": {
      "icao": "LS86",
      "iata": "",
      "name": "Le Blanc Field",
      "city": "Hammond",
      "state": "Louisiana",
      "country": "US",
      "elevation": 158,
      "lat": 30.6819000244,
      "lon": -90.4192962646,
      "tz": "America\/Chicago"
  },
  "LS89": {
      "icao": "LS89",
      "iata": "",
      "name": "Sara Field",
      "city": "St Francisville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 333,
      "lat": 30.9466991425,
      "lon": -91.4749984741,
      "tz": "America\/Chicago"
  },
  "LS90": {
      "icao": "LS90",
      "iata": "",
      "name": "Caddo Detention Center Airport",
      "city": "Shreveport",
      "state": "Louisiana",
      "country": "US",
      "elevation": 294,
      "lat": 32.2624015808,
      "lon": -93.9356994629,
      "tz": "America\/Chicago"
  },
  "LS91": {
      "icao": "LS91",
      "iata": "",
      "name": "Tim Bullard Memorial Airport",
      "city": "Arnaudville",
      "state": "Louisiana",
      "country": "US",
      "elevation": 32,
      "lat": 30.4172000885,
      "lon": -91.9736022949,
      "tz": "America\/Chicago"
  },
  "LS92": {
      "icao": "LS92",
      "iata": "",
      "name": "Koenig Airpark",
      "city": "Robert",
      "state": "Louisiana",
      "country": "US",
      "elevation": 100,
      "lat": 30.5974006653,
      "lon": -90.31590271,
      "tz": "America\/Chicago"
  },
  "LS93": {
      "icao": "LS93",
      "iata": "",
      "name": "Greenwood Plantation Ultralightport",
      "city": "Gibson",
      "state": "Louisiana",
      "country": "US",
      "elevation": 6,
      "lat": 29.6107997894,
      "lon": -90.898399353,
      "tz": "America\/Chicago"
  },
  "LSGB": {
      "icao": "LSGB",
      "iata": "",
      "name": "Bex Airport",
      "city": "Bex",
      "state": "Vaud",
      "country": "CH",
      "elevation": 1312,
      "lat": 46.2583007813,
      "lon": 6.9863901138,
      "tz": "Europe\/Zurich"
  },
  "LSGC": {
      "icao": "LSGC",
      "iata": "",
      "name": "Les Eplatures Airport",
      "city": "La Chaux-de-Fonds",
      "state": "Neuchatel",
      "country": "CH",
      "elevation": 3368,
      "lat": 47.0839004517,
      "lon": 6.792840004,
      "tz": "Europe\/Zurich"
  },
  "LSGE": {
      "icao": "LSGE",
      "iata": "",
      "name": "Ecuvillens Airport",
      "city": "",
      "state": "Fribourg",
      "country": "CH",
      "elevation": 2293,
      "lat": 46.7550010681,
      "lon": 7.0761098862,
      "tz": "Europe\/Zurich"
  },
  "LSGG": {
      "icao": "LSGG",
      "iata": "GVA",
      "name": "Geneva Cointrin International Airport",
      "city": "Geneva",
      "state": "Geneva",
      "country": "CH",
      "elevation": 1411,
      "lat": 46.2380981445,
      "lon": 6.1089501381,
      "tz": "Europe\/Paris"
  },
  "LSGK": {
      "icao": "LSGK",
      "iata": "",
      "name": "Saanen Airport",
      "city": "Saanen",
      "state": "Bern",
      "country": "CH",
      "elevation": 3307,
      "lat": 46.4874992371,
      "lon": 7.2508301735,
      "tz": "Europe\/Zurich"
  },
  "LSGL": {
      "icao": "LSGL",
      "iata": "QLS",
      "name": "Lausanne-la Blecherette Airport",
      "city": "Lausanne",
      "state": "Vaud",
      "country": "CH",
      "elevation": 2041,
      "lat": 46.54529953,
      "lon": 6.6166701317,
      "tz": "Europe\/Zurich"
  },
  "LSGN": {
      "icao": "LSGN",
      "iata": "QNC",
      "name": "Neuchatel Airport",
      "city": "",
      "state": "Neuchatel",
      "country": "CH",
      "elevation": 1427,
      "lat": 46.9575004578,
      "lon": 6.8647198677,
      "tz": "Europe\/Zurich"
  },
  "LSGP": {
      "icao": "LSGP",
      "iata": "",
      "name": "La Cote Airport",
      "city": "La Cote",
      "state": "Vaud",
      "country": "CH",
      "elevation": 1352,
      "lat": 46.4063987732,
      "lon": 6.2580599785,
      "tz": "Europe\/Zurich"
  },
  "LSGR": {
      "icao": "LSGR",
      "iata": "",
      "name": "Reichenbach Air Base",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 2385,
      "lat": 46.6136016846,
      "lon": 7.6777801514,
      "tz": "Europe\/Zurich"
  },
  "LSGS": {
      "icao": "LSGS",
      "iata": "SIR",
      "name": "Sion Airport",
      "city": "Sion",
      "state": "Valais",
      "country": "CH",
      "elevation": 1585,
      "lat": 46.2196006775,
      "lon": 7.3267598152,
      "tz": "Europe\/Zurich"
  },
  "LSGT": {
      "icao": "LSGT",
      "iata": "",
      "name": "Gruyeres Airport",
      "city": "",
      "state": "Fribourg",
      "country": "CH",
      "elevation": 2257,
      "lat": 46.5942001343,
      "lon": 7.0944399834,
      "tz": "Europe\/Zurich"
  },
  "LSGY": {
      "icao": "LSGY",
      "iata": "",
      "name": "Yverdon-les-Bains Airport",
      "city": "Yverdon-les-Bains",
      "state": "Vaud",
      "country": "CH",
      "elevation": 1421,
      "lat": 46.7619018555,
      "lon": 6.6133298874,
      "tz": "Europe\/Zurich"
  },
  "LSMA": {
      "icao": "LSMA",
      "iata": "",
      "name": "Alpnach Air Base",
      "city": "Alpnach",
      "state": "Obwalden",
      "country": "CH",
      "elevation": 1460,
      "lat": 46.943901062,
      "lon": 8.2841701508,
      "tz": "Europe\/Zurich"
  },
  "LSMD": {
      "icao": "LSMD",
      "iata": "",
      "name": "Dubendorf Airport",
      "city": "Zurich",
      "state": "Zurich",
      "country": "CH",
      "elevation": 1470,
      "lat": 47.398601532,
      "lon": 8.648229599,
      "tz": "Europe\/Zurich"
  },
  "LSME": {
      "icao": "LSME",
      "iata": "EML",
      "name": "Emmen Airport",
      "city": "",
      "state": "Lucerne",
      "country": "CH",
      "elevation": 1400,
      "lat": 47.0923995972,
      "lon": 8.3051204681,
      "tz": "Europe\/Zurich"
  },
  "LSMF": {
      "icao": "LSMF",
      "iata": "",
      "name": "Mollis Airport",
      "city": "",
      "state": "Glarus",
      "country": "CH",
      "elevation": 1485,
      "lat": 47.0788993835,
      "lon": 9.0648298264,
      "tz": "Europe\/Zurich"
  },
  "LSMI": {
      "icao": "LSMI",
      "iata": "ZIN",
      "name": "Interlaken Air Base",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 0,
      "lat": 46.6766014099,
      "lon": 7.8790798187,
      "tz": "Europe\/Zurich"
  },
  "LSMJ": {
      "icao": "LSMJ",
      "iata": "",
      "name": "Turtman Air Base",
      "city": "Turtman",
      "state": "Valais",
      "country": "CH",
      "elevation": 0,
      "lat": 46.3026008606,
      "lon": 7.7139501572,
      "tz": "Europe\/Zurich"
  },
  "LSML": {
      "icao": "LSML",
      "iata": "",
      "name": "Lodrino Air Base",
      "city": "Lodrino",
      "state": "Ticino",
      "country": "CH",
      "elevation": 0,
      "lat": 46.2958984375,
      "lon": 8.9921302795,
      "tz": "Europe\/Zurich"
  },
  "LSMM": {
      "icao": "LSMM",
      "iata": "",
      "name": "Meiringen Airport",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 1895,
      "lat": 46.7433013916,
      "lon": 8.1099996567,
      "tz": "Europe\/Zurich"
  },
  "LSMP": {
      "icao": "LSMP",
      "iata": "",
      "name": "Payerne Airport",
      "city": "",
      "state": "Vaud",
      "country": "CH",
      "elevation": 1465,
      "lat": 46.8432006836,
      "lon": 6.9150600433,
      "tz": "Europe\/Zurich"
  },
  "LSPA": {
      "icao": "LSPA",
      "iata": "",
      "name": "Amlikon Glider Airport",
      "city": "",
      "state": "Thurgau",
      "country": "CH",
      "elevation": 1371,
      "lat": 47.5741996765,
      "lon": 9.0474996567,
      "tz": "Europe\/Zurich"
  },
  "LSPD": {
      "icao": "LSPD",
      "iata": "",
      "name": "Dittingen Airport",
      "city": "",
      "state": "Basel-Landschaft",
      "country": "CH",
      "elevation": 1758,
      "lat": 47.4385986328,
      "lon": 7.4913902283,
      "tz": "Europe\/Zurich"
  },
  "LSPF": {
      "icao": "LSPF",
      "iata": "",
      "name": "Schaffhausen Airport",
      "city": "",
      "state": "Schaffhausen",
      "country": "CH",
      "elevation": 1519,
      "lat": 47.6906013489,
      "lon": 8.5269403458,
      "tz": "Europe\/Zurich"
  },
  "LSPG": {
      "icao": "LSPG",
      "iata": "",
      "name": "Kagiswil Airport",
      "city": "",
      "state": "Obwalden",
      "country": "CH",
      "elevation": 1525,
      "lat": 46.9082984924,
      "lon": 8.2541704178,
      "tz": "Europe\/Zurich"
  },
  "LSPH": {
      "icao": "LSPH",
      "iata": "",
      "name": "Winterthur Airport",
      "city": "Winterthur",
      "state": "Zurich",
      "country": "CH",
      "elevation": 1506,
      "lat": 47.5149993896,
      "lon": 8.7719402313,
      "tz": "Europe\/Zurich"
  },
  "LSPK": {
      "icao": "LSPK",
      "iata": "",
      "name": "Hasenstrick Airport",
      "city": "",
      "state": "Zurich",
      "country": "CH",
      "elevation": 0,
      "lat": 47.2799987793,
      "lon": 8.881939888,
      "tz": "Europe\/Zurich"
  },
  "LSPL": {
      "icao": "LSPL",
      "iata": "",
      "name": "Langenthal Airport",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 1575,
      "lat": 47.182800293,
      "lon": 7.7413902283,
      "tz": "Europe\/Zurich"
  },
  "LSPM": {
      "icao": "LSPM",
      "iata": "",
      "name": "Ambri Airport",
      "city": "Quinto",
      "state": "Ticino",
      "country": "CH",
      "elevation": 3241,
      "lat": 46.5125007629,
      "lon": 8.6897802353,
      "tz": "Europe\/Zurich"
  },
  "LSPN": {
      "icao": "LSPN",
      "iata": "",
      "name": "Triengen Airport",
      "city": "",
      "state": "Lucerne",
      "country": "CH",
      "elevation": 1594,
      "lat": 47.2266998291,
      "lon": 8.0780601501,
      "tz": "Europe\/Zurich"
  },
  "LSPO": {
      "icao": "LSPO",
      "iata": "",
      "name": "Olten Airport",
      "city": "",
      "state": "Solothurn",
      "country": "CH",
      "elevation": 0,
      "lat": 47.3443984985,
      "lon": 7.8894400597,
      "tz": "Europe\/Zurich"
  },
  "LSPU": {
      "icao": "LSPU",
      "iata": "",
      "name": "Muenster Aero Airport",
      "city": "",
      "state": "Valais",
      "country": "CH",
      "elevation": 4380,
      "lat": 46.4803009033,
      "lon": 8.2633304596,
      "tz": "Europe\/Zurich"
  },
  "LSPV": {
      "icao": "LSPV",
      "iata": "",
      "name": "Wangen-Lachen Airport",
      "city": "",
      "state": "Schwyz",
      "country": "CH",
      "elevation": 1335,
      "lat": 47.20470047,
      "lon": 8.8675003052,
      "tz": "Europe\/Zurich"
  },
  "LSTA": {
      "icao": "LSTA",
      "iata": "",
      "name": "Raron Airport",
      "city": "Raron",
      "state": "Valais",
      "country": "CH",
      "elevation": 2029,
      "lat": 46.3036003113,
      "lon": 7.8233299255,
      "tz": "Europe\/Zurich"
  },
  "LSTB": {
      "icao": "LSTB",
      "iata": "",
      "name": "Bellechasse Airport",
      "city": "",
      "state": "Fribourg",
      "country": "CH",
      "elevation": 1421,
      "lat": 46.9794006348,
      "lon": 7.1322197914,
      "tz": "Europe\/Zurich"
  },
  "LSTO": {
      "icao": "LSTO",
      "iata": "",
      "name": "Motiers Airport",
      "city": "",
      "state": "Neuchatel",
      "country": "CH",
      "elevation": 2402,
      "lat": 46.9166984558,
      "lon": 6.6149997711,
      "tz": "Europe\/Zurich"
  },
  "LSTR": {
      "icao": "LSTR",
      "iata": "",
      "name": "Montricher Airport",
      "city": "",
      "state": "Vaud",
      "country": "CH",
      "elevation": 2178,
      "lat": 46.5903015137,
      "lon": 6.4005599022,
      "tz": "Europe\/Zurich"
  },
  "LSTS": {
      "icao": "LSTS",
      "iata": "",
      "name": "St Stephan Airport",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 3304,
      "lat": 46.4973983765,
      "lon": 7.4125699997,
      "tz": "Europe\/Zurich"
  },
  "LSTZ": {
      "icao": "LSTZ",
      "iata": "",
      "name": "Zweisimmen Airport",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 3068,
      "lat": 46.5525016785,
      "lon": 7.3805599213,
      "tz": "Europe\/Zurich"
  },
  "LSXV": {
      "icao": "LSXV",
      "iata": "",
      "name": "San Vittore Airport",
      "city": "",
      "state": "Grisons",
      "country": "CH",
      "elevation": 869,
      "lat": 46.2341995239,
      "lon": 9.0941696167,
      "tz": "Europe\/Zurich"
  },
  "LSZA": {
      "icao": "LSZA",
      "iata": "LUG",
      "name": "Lugano Airport",
      "city": "Lugano",
      "state": "Ticino",
      "country": "CH",
      "elevation": 915,
      "lat": 46.0042991638,
      "lon": 8.9105796814,
      "tz": "Europe\/Zurich"
  },
  "LSZB": {
      "icao": "LSZB",
      "iata": "BRN",
      "name": "Bern Belp Airport",
      "city": "Bern",
      "state": "Bern",
      "country": "CH",
      "elevation": 1674,
      "lat": 46.914100647,
      "lon": 7.4971499443,
      "tz": "Europe\/Zurich"
  },
  "LSZC": {
      "icao": "LSZC",
      "iata": "BXO",
      "name": "Buochs Airport",
      "city": "Buochs",
      "state": "Nidwalden",
      "country": "CH",
      "elevation": 1475,
      "lat": 46.9744444444,
      "lon": 8.3969444444,
      "tz": "Europe\/Zurich"
  },
  "LSZE": {
      "icao": "LSZE",
      "iata": "",
      "name": "Bad Ragaz Airport",
      "city": "",
      "state": "Saint-Gallen",
      "country": "CH",
      "elevation": 1617,
      "lat": 47.0149993896,
      "lon": 9.4819402695,
      "tz": "Europe\/Zurich"
  },
  "LSZF": {
      "icao": "LSZF",
      "iata": "",
      "name": "Birrfeld Airport",
      "city": "",
      "state": "Aargau",
      "country": "CH",
      "elevation": 1300,
      "lat": 47.4435997009,
      "lon": 8.2336101532,
      "tz": "Europe\/Zurich"
  },
  "LSZG": {
      "icao": "LSZG",
      "iata": "ZHI",
      "name": "Grenchen Airport",
      "city": "",
      "state": "Solothurn",
      "country": "CH",
      "elevation": 1411,
      "lat": 47.1815986633,
      "lon": 7.4171900749,
      "tz": "Europe\/Zurich"
  },
  "LSZH": {
      "icao": "LSZH",
      "iata": "ZRH",
      "name": "Zurich Airport",
      "city": "Zurich",
      "state": "Zurich",
      "country": "CH",
      "elevation": 1416,
      "lat": 47.4646987915,
      "lon": 8.5491695404,
      "tz": "Europe\/Zurich"
  },
  "LSZI": {
      "icao": "LSZI",
      "iata": "",
      "name": "Fricktal-Schupfart Airport",
      "city": "",
      "state": "Aargau",
      "country": "CH",
      "elevation": 1788,
      "lat": 47.5088996887,
      "lon": 7.9499998093,
      "tz": "Europe\/Berlin"
  },
  "LSZJ": {
      "icao": "LSZJ",
      "iata": "",
      "name": "Courtelary Airport",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 2247,
      "lat": 47.1836013794,
      "lon": 7.0908298492,
      "tz": "Europe\/Zurich"
  },
  "LSZK": {
      "icao": "LSZK",
      "iata": "",
      "name": "Speck-Fehraltorf Airport",
      "city": "",
      "state": "Zurich",
      "country": "CH",
      "elevation": 1748,
      "lat": 47.3763999939,
      "lon": 8.7574996948,
      "tz": "Europe\/Zurich"
  },
  "LSZL": {
      "icao": "LSZL",
      "iata": "ZJI",
      "name": "Locarno Airport",
      "city": "",
      "state": "Ticino",
      "country": "CH",
      "elevation": 650,
      "lat": 46.1608009338,
      "lon": 8.8786096573,
      "tz": "Europe\/Zurich"
  },
  "LSZM": {
      "icao": "LSZM",
      "iata": "MLH",
      "name": "EuroAirport Basel-Mulhouse-Freiburg Airport",
      "city": "Bale\/Mulhouse",
      "state": "Grand-Est",
      "country": "CH",
      "elevation": 885,
      "lat": 47.5895996094,
      "lon": 7.5299100876,
      "tz": "Europe\/Paris"
  },
  "LSZN": {
      "icao": "LSZN",
      "iata": "",
      "name": "Hausen am Albis Airport",
      "city": "",
      "state": "Zurich",
      "country": "CH",
      "elevation": 1928,
      "lat": 47.2386016846,
      "lon": 8.5155601501,
      "tz": "Europe\/Zurich"
  },
  "LSZO": {
      "icao": "LSZO",
      "iata": "",
      "name": "Luzern-Beromunster Airport",
      "city": "Lucerne",
      "state": "Lucerne",
      "country": "CH",
      "elevation": 2146,
      "lat": 47.1899986267,
      "lon": 8.2047195435,
      "tz": "Europe\/Zurich"
  },
  "LSZP": {
      "icao": "LSZP",
      "iata": "",
      "name": "Biel-Kappelen Airport",
      "city": "",
      "state": "Bern",
      "country": "CH",
      "elevation": 1437,
      "lat": 47.0891990662,
      "lon": 7.2899999619,
      "tz": "Europe\/Zurich"
  },
  "LSZQ": {
      "icao": "LSZQ",
      "iata": "",
      "name": "Bressaucourt Airport",
      "city": "Bressaucourt",
      "state": "Jura",
      "country": "CH",
      "elevation": 1866,
      "lat": 47.392677,
      "lon": 7.028648,
      "tz": "Europe\/Zurich"
  },
  "LSZR": {
      "icao": "LSZR",
      "iata": "ACH",
      "name": "St Gallen Altenrhein Airport",
      "city": "Altenrhein",
      "state": "Saint-Gallen",
      "country": "CH",
      "elevation": 1306,
      "lat": 47.4850006104,
      "lon": 9.5607700348,
      "tz": "Europe\/Vienna"
  },
  "LSZS": {
      "icao": "LSZS",
      "iata": "SMV",
      "name": "Samedan Airport",
      "city": "",
      "state": "Grisons",
      "country": "CH",
      "elevation": 5600,
      "lat": 46.5340995789,
      "lon": 9.8841104507,
      "tz": "Europe\/Zurich"
  },
  "LSZT": {
      "icao": "LSZT",
      "iata": "",
      "name": "Lommis Airfield",
      "city": "Lommis",
      "state": "Thurgau",
      "country": "CH",
      "elevation": 1539,
      "lat": 47.5243988037,
      "lon": 9.0030603409,
      "tz": "Europe\/Zurich"
  },
  "LSZU": {
      "icao": "LSZU",
      "iata": "",
      "name": "Buttwil Airport",
      "city": "",
      "state": "Aargau",
      "country": "CH",
      "elevation": 2372,
      "lat": 47.2647018433,
      "lon": 8.3024997711,
      "tz": "Europe\/Zurich"
  },
  "LSZV": {
      "icao": "LSZV",
      "iata": "",
      "name": "Sitterdorf Airport",
      "city": "",
      "state": "Thurgau",
      "country": "CH",
      "elevation": 1660,
      "lat": 47.5088996887,
      "lon": 9.2627801895,
      "tz": "Europe\/Zurich"
  },
  "LSZW": {
      "icao": "LSZW",
      "iata": "",
      "name": "Thun Airport",
      "city": "Thun",
      "state": "Bern",
      "country": "CH",
      "elevation": 1837,
      "lat": 46.756401062,
      "lon": 7.6005601883,
      "tz": "Europe\/Zurich"
  },
  "LSZX": {
      "icao": "LSZX",
      "iata": "",
      "name": "Schanis Airport",
      "city": "",
      "state": "Saint-Gallen",
      "country": "CH",
      "elevation": 1365,
      "lat": 47.1716995239,
      "lon": 9.039440155,
      "tz": "Europe\/Zurich"
  },
  "LTAB": {
      "icao": "LTAB",
      "iata": "",
      "name": "Guvercinlik Airport",
      "city": "Ankara",
      "state": "Ankara",
      "country": "TR",
      "elevation": 2687,
      "lat": 39.9350013733,
      "lon": 32.7407989502,
      "tz": "Europe\/Istanbul"
  },
  "LTAC": {
      "icao": "LTAC",
      "iata": "ESB",
      "name": "Esenboga International Airport",
      "city": "Ankara",
      "state": "Ankara",
      "country": "TR",
      "elevation": 3125,
      "lat": 40.1281013489,
      "lon": 32.995098114,
      "tz": "Europe\/Istanbul"
  },
  "LTAD": {
      "icao": "LTAD",
      "iata": "ANK",
      "name": "Etimesgut Air Base",
      "city": "Ankara",
      "state": "Ankara",
      "country": "TR",
      "elevation": 2653,
      "lat": 39.949798584,
      "lon": 32.6885986328,
      "tz": "Europe\/Istanbul"
  },
  "LTAE": {
      "icao": "LTAE",
      "iata": "",
      "name": "Akinci Air Base",
      "city": "Ankara",
      "state": "Ankara",
      "country": "TR",
      "elevation": 2767,
      "lat": 40.0788993835,
      "lon": 32.5656013489,
      "tz": "Europe\/Istanbul"
  },
  "LTAF": {
      "icao": "LTAF",
      "iata": "ADA",
      "name": "Adana Airport",
      "city": "Adana",
      "state": "Adana",
      "country": "TR",
      "elevation": 65,
      "lat": 36.9822006226,
      "lon": 35.2803993225,
      "tz": "Europe\/Istanbul"
  },
  "LTAG": {
      "icao": "LTAG",
      "iata": "UAB",
      "name": "Incirlik Air Base",
      "city": "Adana",
      "state": "Adana",
      "country": "TR",
      "elevation": 238,
      "lat": 37.0021018982,
      "lon": 35.4258995056,
      "tz": "Europe\/Istanbul"
  },
  "LTAH": {
      "icao": "LTAH",
      "iata": "AFY",
      "name": "Afyon Airport",
      "city": "Afyonkarahisar",
      "state": "Afyonkarahisar",
      "country": "TR",
      "elevation": 3310,
      "lat": 38.726398468,
      "lon": 30.6011009216,
      "tz": "Europe\/Istanbul"
  },
  "LTAI": {
      "icao": "LTAI",
      "iata": "AYT",
      "name": "Antalya International Airport",
      "city": "Antalya",
      "state": "Antalya",
      "country": "TR",
      "elevation": 177,
      "lat": 36.8987007141,
      "lon": 30.8005008698,
      "tz": "Europe\/Istanbul"
  },
  "LTAJ": {
      "icao": "LTAJ",
      "iata": "GZT",
      "name": "Gaziantep International Airport",
      "city": "Gaziantep",
      "state": "Gaziantep",
      "country": "TR",
      "elevation": 2315,
      "lat": 36.9472007751,
      "lon": 37.4786987305,
      "tz": "Europe\/Istanbul"
  },
  "LTAK": {
      "icao": "LTAK",
      "iata": "",
      "name": "Iskenderun Airport",
      "city": "Iskenderun",
      "state": "Hatay",
      "country": "TR",
      "elevation": 25,
      "lat": 36.5744552612,
      "lon": 36.1534194946,
      "tz": "Europe\/Istanbul"
  },
  "LTAL": {
      "icao": "LTAL",
      "iata": "KFS",
      "name": "Kastamonu Airport",
      "city": "Kastamonu",
      "state": "Kastamonu",
      "country": "TR",
      "elevation": 3520,
      "lat": 41.314201355,
      "lon": 33.7957992554,
      "tz": "Europe\/Istanbul"
  },
  "LTAN": {
      "icao": "LTAN",
      "iata": "KYA",
      "name": "Konya Airport",
      "city": "Konya",
      "state": "Konya",
      "country": "TR",
      "elevation": 3381,
      "lat": 37.9790000916,
      "lon": 32.5619010925,
      "tz": "Europe\/Istanbul"
  },
  "LTAO": {
      "icao": "LTAO",
      "iata": "",
      "name": "Malatya Tulga Airport",
      "city": "Malatya",
      "state": "Malatya",
      "country": "TR",
      "elevation": 3016,
      "lat": 38.3536987305,
      "lon": 38.2538986206,
      "tz": "Europe\/Istanbul"
  },
  "LTAP": {
      "icao": "LTAP",
      "iata": "MZH",
      "name": "Amasya Merzifon Airport",
      "city": "Amasya",
      "state": "Amasya",
      "country": "TR",
      "elevation": 1758,
      "lat": 40.8293991089,
      "lon": 35.5219993591,
      "tz": "Europe\/Istanbul"
  },
  "LTAQ": {
      "icao": "LTAQ",
      "iata": "SSX",
      "name": "Samsun Samair Airport",
      "city": "Samsun",
      "state": "Samsun",
      "country": "TR",
      "elevation": 521,
      "lat": 41.2786445618,
      "lon": 36.3051795959,
      "tz": "Europe\/Istanbul"
  },
  "LTAR": {
      "icao": "LTAR",
      "iata": "VAS",
      "name": "Sivas Airport",
      "city": "Sivas",
      "state": "Sivas",
      "country": "TR",
      "elevation": 5236,
      "lat": 39.8138008118,
      "lon": 36.9034996033,
      "tz": "Europe\/Istanbul"
  },
  "LTAS": {
      "icao": "LTAS",
      "iata": "ONQ",
      "name": "Zonguldak Airport",
      "city": "Zonguldak",
      "state": "Zonguldak",
      "country": "TR",
      "elevation": 39,
      "lat": 41.506401062,
      "lon": 32.0886001587,
      "tz": "Europe\/Istanbul"
  },
  "LTAT": {
      "icao": "LTAT",
      "iata": "MLX",
      "name": "Malatya Erhac Airport",
      "city": "Malatya",
      "state": "Malatya",
      "country": "TR",
      "elevation": 2828,
      "lat": 38.4352989197,
      "lon": 38.0909996033,
      "tz": "Europe\/Istanbul"
  },
  "LTAU": {
      "icao": "LTAU",
      "iata": "ASR",
      "name": "Kayseri Erkilet Airport",
      "city": "Kayseri",
      "state": "Kayseri",
      "country": "TR",
      "elevation": 3463,
      "lat": 38.770401001,
      "lon": 35.4953994751,
      "tz": "Europe\/Istanbul"
  },
  "LTAV": {
      "icao": "LTAV",
      "iata": "",
      "name": "Sivrihisar Airport",
      "city": "Sivrihisar",
      "state": "Eski\u015fehir",
      "country": "TR",
      "elevation": 3185,
      "lat": 39.451499939,
      "lon": 31.3652992249,
      "tz": "Europe\/Istanbul"
  },
  "LTAW": {
      "icao": "LTAW",
      "iata": "TJK",
      "name": "Tokat Airport",
      "city": "Tokat",
      "state": "Tokat",
      "country": "TR",
      "elevation": 1831,
      "lat": 40.3074302673,
      "lon": 36.3674087524,
      "tz": "Europe\/Istanbul"
  },
  "LTAY": {
      "icao": "LTAY",
      "iata": "DNZ",
      "name": "Cardak Airport",
      "city": "Denizli",
      "state": "Denizli",
      "country": "TR",
      "elevation": 2795,
      "lat": 37.7855987549,
      "lon": 29.7012996674,
      "tz": "Europe\/Istanbul"
  },
  "LTAZ": {
      "icao": "LTAZ",
      "iata": "NAV",
      "name": "Nevsehir Kapadokya International Airport",
      "city": "Nevsehir",
      "state": "Nev\u015fehir",
      "country": "TR",
      "elevation": 3100,
      "lat": 38.771900177,
      "lon": 34.5345001221,
      "tz": "Europe\/Istanbul"
  },
  "LTBA": {
      "icao": "LTBA",
      "iata": "ISL",
      "name": "Ataturk International Airport",
      "city": "Istanbul",
      "state": "Istanbul",
      "country": "TR",
      "elevation": 163,
      "lat": 40.9768981934,
      "lon": 28.8145999908,
      "tz": "Europe\/Istanbul"
  },
  "LTFM": {
      "icao": "LTFM",
      "iata": "IST",
      "name": "Istanbul Airport",
      "city": "Arnavutkoy",
      "state": "Istanbul",
      "country": "TR",
      "elevation": 325,
      "lat": 41.262222,
      "lon": 28.727778,
      "tz": "Europe\/Istanbul"
  },
  "LTBC": {
      "icao": "LTBC",
      "iata": "",
      "name": "Alasehir Airport",
      "city": "Alasehir",
      "state": "Manisa",
      "country": "TR",
      "elevation": 0,
      "lat": 38.3699989319,
      "lon": 28.557100296,
      "tz": "Europe\/Istanbul"
  },
  "LTBD": {
      "icao": "LTBD",
      "iata": "CII",
      "name": "Cildir Airport",
      "city": "Aydin",
      "state": "Aydin",
      "country": "TR",
      "elevation": 102,
      "lat": 37.8149986267,
      "lon": 27.8952999115,
      "tz": "Europe\/Istanbul"
  },
  "LTBE": {
      "icao": "LTBE",
      "iata": "BTZ",
      "name": "Bursa Airport",
      "city": "Bursa",
      "state": "Bursa",
      "country": "TR",
      "elevation": 331,
      "lat": 40.2332992554,
      "lon": 29.0091991425,
      "tz": "Europe\/Istanbul"
  },
  "LTBF": {
      "icao": "LTBF",
      "iata": "BZI",
      "name": "Balikesir Merkez Airport",
      "city": "Balikesir",
      "state": "Balikesir",
      "country": "TR",
      "elevation": 340,
      "lat": 39.6193008423,
      "lon": 27.9260005951,
      "tz": "Europe\/Istanbul"
  },
  "LTBG": {
      "icao": "LTBG",
      "iata": "BDM",
      "name": "Bandirma Airport",
      "city": "Bandirma",
      "state": "Balikesir",
      "country": "TR",
      "elevation": 170,
      "lat": 40.3180007935,
      "lon": 27.9776992798,
      "tz": "Europe\/Istanbul"
  },
  "LTBH": {
      "icao": "LTBH",
      "iata": "CKZ",
      "name": "Canakkale Airport",
      "city": "Canakkale",
      "state": "Canakkale",
      "country": "TR",
      "elevation": 23,
      "lat": 40.1376991272,
      "lon": 26.4267997742,
      "tz": "Europe\/Istanbul"
  },
  "LTBI": {
      "icao": "LTBI",
      "iata": "ESK",
      "name": "Eskisehir Air Base",
      "city": "Eskisehir",
      "state": "Eski\u015fehir",
      "country": "TR",
      "elevation": 2581,
      "lat": 39.7840995789,
      "lon": 30.5820999146,
      "tz": "Europe\/Istanbul"
  },
  "LTBJ": {
      "icao": "LTBJ",
      "iata": "ADB",
      "name": "Adnan Menderes International Airport",
      "city": "Izmir",
      "state": "Izmir",
      "country": "TR",
      "elevation": 412,
      "lat": 38.2924003601,
      "lon": 27.156999588,
      "tz": "Europe\/Istanbul"
  },
  "LTBK": {
      "icao": "LTBK",
      "iata": "",
      "name": "Gaziemir Airport",
      "city": "",
      "state": "Izmir",
      "country": "TR",
      "elevation": 433,
      "lat": 38.3190994263,
      "lon": 27.1594009399,
      "tz": "Europe\/Istanbul"
  },
  "LTBL": {
      "icao": "LTBL",
      "iata": "IGL",
      "name": "Cigli Airport",
      "city": "Izmir",
      "state": "Izmir",
      "country": "TR",
      "elevation": 16,
      "lat": 38.5130004883,
      "lon": 27.010099411,
      "tz": "Europe\/Istanbul"
  },
  "LTBM": {
      "icao": "LTBM",
      "iata": "",
      "name": "Isparta Airport",
      "city": "",
      "state": "Isparta",
      "country": "TR",
      "elevation": 3250,
      "lat": 37.7851219177,
      "lon": 30.5900192261,
      "tz": "Europe\/Istanbul"
  },
  "LTBN": {
      "icao": "LTBN",
      "iata": "",
      "name": "Kutahya Airport",
      "city": "Kutahya",
      "state": "Kuetahya",
      "country": "TR",
      "elevation": 3026,
      "lat": 39.426700592,
      "lon": 30.0168991089,
      "tz": "Europe\/Istanbul"
  },
  "LTBO": {
      "icao": "LTBO",
      "iata": "USQ",
      "name": "Usak Airport",
      "city": "Usak",
      "state": "U\u015fak",
      "country": "TR",
      "elevation": 2897,
      "lat": 38.6814994812,
      "lon": 29.4717006683,
      "tz": "Europe\/Istanbul"
  },
  "LTBP": {
      "icao": "LTBP",
      "iata": "",
      "name": "Yalova Airport",
      "city": "Ciftlikkoy",
      "state": "Yalova",
      "country": "TR",
      "elevation": 6,
      "lat": 40.6843986511,
      "lon": 29.3756999969,
      "tz": "Europe\/Istanbul"
  },
  "LTBQ": {
      "icao": "LTBQ",
      "iata": "KCO",
      "name": "Cengiz Topel Airport",
      "city": "",
      "state": "Kocaeli",
      "country": "TR",
      "elevation": 182,
      "lat": 40.7350006104,
      "lon": 30.0832996368,
      "tz": "Europe\/Istanbul"
  },
  "LTBR": {
      "icao": "LTBR",
      "iata": "YEI",
      "name": "Bursa Yenisehir Airport",
      "city": "Bursa",
      "state": "Bursa",
      "country": "TR",
      "elevation": 764,
      "lat": 40.2551994324,
      "lon": 29.5625991821,
      "tz": "Europe\/Istanbul"
  },
  "LTBS": {
      "icao": "LTBS",
      "iata": "DLM",
      "name": "Dalaman International Airport",
      "city": "Dalaman",
      "state": "Mugla",
      "country": "TR",
      "elevation": 20,
      "lat": 36.7131004333,
      "lon": 28.7924995422,
      "tz": "Europe\/Istanbul"
  },
  "LTBT": {
      "icao": "LTBT",
      "iata": "",
      "name": "Akhisar Airport",
      "city": "",
      "state": "Manisa",
      "country": "TR",
      "elevation": 263,
      "lat": 38.8088989258,
      "lon": 27.8339004517,
      "tz": "Europe\/Istanbul"
  },
  "LTBU": {
      "icao": "LTBU",
      "iata": "TEQ",
      "name": "Tekirdag Corlu Airport",
      "city": "Corlu",
      "state": "Tekirdag",
      "country": "TR",
      "elevation": 574,
      "lat": 41.1381988525,
      "lon": 27.9190998077,
      "tz": "Europe\/Istanbul"
  },
  "LTBV": {
      "icao": "LTBV",
      "iata": "BXN",
      "name": "Imsik Airport",
      "city": "Bodrum",
      "state": "Mugla",
      "country": "TR",
      "elevation": 202,
      "lat": 37.1400985718,
      "lon": 27.6697006226,
      "tz": "Europe\/Istanbul"
  },
  "LTBW": {
      "icao": "LTBW",
      "iata": "",
      "name": "Istanbul Hezarfen Airfield",
      "city": "Istanbul",
      "state": "Istanbul",
      "country": "TR",
      "elevation": 57,
      "lat": 41.1035995483,
      "lon": 28.547700882,
      "tz": "Europe\/Istanbul"
  },
  "LTBX": {
      "icao": "LTBX",
      "iata": "",
      "name": "Samandira Air Base",
      "city": "Umraniye",
      "state": "Istanbul",
      "country": "TR",
      "elevation": 400,
      "lat": 40.9930000305,
      "lon": 29.2164993286,
      "tz": "Europe\/Istanbul"
  },
  "LTBY": {
      "icao": "LTBY",
      "iata": "AOE",
      "name": "Anadolu University Airport",
      "city": "Eskisehir",
      "state": "Eski\u015fehir",
      "country": "TR",
      "elevation": 2588,
      "lat": 39.8098983765,
      "lon": 30.5193996429,
      "tz": "Europe\/Istanbul"
  },
  "LTBZ": {
      "icao": "LTBZ",
      "iata": "KZR",
      "name": "Zafer Airport",
      "city": "Altintas",
      "state": "Kutahya",
      "country": "TR",
      "elevation": 3327,
      "lat": 39.1090247,
      "lon": 30.1371297052,
      "tz": "Europe\/Istanbul"
  },
  "LTCA": {
      "icao": "LTCA",
      "iata": "EZS",
      "name": "Elazig Airport",
      "city": "Elazig",
      "state": "Elazig",
      "country": "TR",
      "elevation": 2927,
      "lat": 38.6068992615,
      "lon": 39.2914009094,
      "tz": "Europe\/Istanbul"
  },
  "LTCB": {
      "icao": "LTCB",
      "iata": "OGU",
      "name": "Ordu\u2013Giresun Airport",
      "city": "Ordu",
      "state": "Ordu Province",
      "country": "TR",
      "elevation": 18,
      "lat": 40.966667,
      "lon": 38.08,
      "tz": "Europe\/Istanbul"
  },
  "LTCC": {
      "icao": "LTCC",
      "iata": "DIY",
      "name": "Diyarbakir Airport",
      "city": "Diyarbakir",
      "state": "Diyarbakir",
      "country": "TR",
      "elevation": 2251,
      "lat": 37.893901825,
      "lon": 40.2010002136,
      "tz": "Europe\/Istanbul"
  },
  "LTCD": {
      "icao": "LTCD",
      "iata": "ERC",
      "name": "Erzincan Airport",
      "city": "Erzincan",
      "state": "Erzincan",
      "country": "TR",
      "elevation": 3783,
      "lat": 39.7102012634,
      "lon": 39.5270004272,
      "tz": "Europe\/Istanbul"
  },
  "LTCE": {
      "icao": "LTCE",
      "iata": "ERZ",
      "name": "Erzurum International Airport",
      "city": "Erzurum",
      "state": "Erzurum",
      "country": "TR",
      "elevation": 5763,
      "lat": 39.9565010071,
      "lon": 41.1702003479,
      "tz": "Europe\/Istanbul"
  },
  "LTCF": {
      "icao": "LTCF",
      "iata": "KSY",
      "name": "Kars Airport",
      "city": "Kars",
      "state": "Kars",
      "country": "TR",
      "elevation": 5889,
      "lat": 40.5621986389,
      "lon": 43.1150016785,
      "tz": "Europe\/Istanbul"
  },
  "LTCG": {
      "icao": "LTCG",
      "iata": "TZX",
      "name": "Trabzon International Airport",
      "city": "Trabzon",
      "state": "Trabzon",
      "country": "TR",
      "elevation": 104,
      "lat": 40.995098114,
      "lon": 39.7896995544,
      "tz": "Europe\/Istanbul"
  },
  "LTCH": {
      "icao": "LTCH",
      "iata": "SFQ",
      "name": "Sanliurfa Airport",
      "city": "Sanliurfa",
      "state": "Sanliurfa",
      "country": "TR",
      "elevation": 1483,
      "lat": 37.0942993164,
      "lon": 38.8470993042,
      "tz": "Europe\/Istanbul"
  },
  "LTCI": {
      "icao": "LTCI",
      "iata": "VAN",
      "name": "Van Ferit Melen Airport",
      "city": "Van",
      "state": "Van",
      "country": "TR",
      "elevation": 5480,
      "lat": 38.4682006836,
      "lon": 43.3322982788,
      "tz": "Europe\/Istanbul"
  },
  "LTCJ": {
      "icao": "LTCJ",
      "iata": "BAL",
      "name": "Batman Airport",
      "city": "Batman",
      "state": "Batman",
      "country": "TR",
      "elevation": 1822,
      "lat": 37.9290008545,
      "lon": 41.1166000366,
      "tz": "Europe\/Istanbul"
  },
  "LTCK": {
      "icao": "LTCK",
      "iata": "MSR",
      "name": "Mus Airport",
      "city": "Mus",
      "state": "Mu\u015f",
      "country": "TR",
      "elevation": 4157,
      "lat": 38.7477989197,
      "lon": 41.6612014771,
      "tz": "Europe\/Istanbul"
  },
  "LTCL": {
      "icao": "LTCL",
      "iata": "SXZ",
      "name": "Siirt Airport",
      "city": "Siirt",
      "state": "Siirt",
      "country": "TR",
      "elevation": 2001,
      "lat": 37.9789009094,
      "lon": 41.8404006958,
      "tz": "Europe\/Istanbul"
  },
  "LTCM": {
      "icao": "LTCM",
      "iata": "NOP",
      "name": "Sinop Airport",
      "city": "Sinop",
      "state": "Sinop",
      "country": "TR",
      "elevation": 20,
      "lat": 42.0158004761,
      "lon": 35.0663986206,
      "tz": "Europe\/Istanbul"
  },
  "LTCN": {
      "icao": "LTCN",
      "iata": "KCM",
      "name": "Kahramanmaras Airport",
      "city": "Kahramanmaras",
      "state": "Kahramanmara\u015f",
      "country": "TR",
      "elevation": 1723,
      "lat": 37.5388259888,
      "lon": 36.9535217285,
      "tz": "Europe\/Istanbul"
  },
  "LTCO": {
      "icao": "LTCO",
      "iata": "AJI",
      "name": "Agri Airport",
      "city": "Agri",
      "state": "Agri",
      "country": "TR",
      "elevation": 5462,
      "lat": 39.6545410156,
      "lon": 43.0259780884,
      "tz": "Europe\/Istanbul"
  },
  "LTCP": {
      "icao": "LTCP",
      "iata": "ADF",
      "name": "Adiyaman Airport",
      "city": "Adiyaman",
      "state": "Adiyaman",
      "country": "TR",
      "elevation": 2216,
      "lat": 37.7313995361,
      "lon": 38.4688987732,
      "tz": "Europe\/Istanbul"
  },
  "LTCR": {
      "icao": "LTCR",
      "iata": "MQM",
      "name": "Mardin Airport",
      "city": "Mardin",
      "state": "Mardin",
      "country": "TR",
      "elevation": 1729,
      "lat": 37.2233009338,
      "lon": 40.6316986084,
      "tz": "Europe\/Istanbul"
  },
  "LTCS": {
      "icao": "LTCS",
      "iata": "GNY",
      "name": "Sanliurfa GAP Airport",
      "city": "Sanliurfa",
      "state": "Sanliurfa",
      "country": "TR",
      "elevation": 2708,
      "lat": 37.4456634521,
      "lon": 38.8955917358,
      "tz": "Europe\/Istanbul"
  },
  "LTCT": {
      "icao": "LTCT",
      "iata": "IGD",
      "name": "Igdir Airport",
      "city": "Igdir",
      "state": "Kars",
      "country": "TR",
      "elevation": 3101,
      "lat": 39.9766273499,
      "lon": 43.8766479492,
      "tz": "Europe\/Istanbul"
  },
  "LTCW": {
      "icao": "LTCW",
      "iata": "YKO",
      "name": "Hakkari Yuksekova Airport",
      "city": "Yuksekova",
      "state": "Hakkari",
      "country": "TR",
      "elevation": 6400,
      "lat": 37.551667,
      "lon": 44.233611,
      "tz": "Europe\/Istanbul"
  },
  "LTDA": {
      "icao": "LTDA",
      "iata": "HTY",
      "name": "Hatay Airport",
      "city": "Hatay",
      "state": "Hatay",
      "country": "TR",
      "elevation": 269,
      "lat": 36.36277771,
      "lon": 36.2822227478,
      "tz": "Europe\/Istanbul"
  },
  "LTFA": {
      "icao": "LTFA",
      "iata": "",
      "name": "Kaklic Airport",
      "city": "Izmir",
      "state": "Izmir",
      "country": "TR",
      "elevation": 13,
      "lat": 38.5176010132,
      "lon": 26.977399826,
      "tz": "Europe\/Istanbul"
  },
  "LTFB": {
      "icao": "LTFB",
      "iata": "",
      "name": "Selcuk Efes Airport",
      "city": "Selcuk",
      "state": "Izmir",
      "country": "TR",
      "elevation": 10,
      "lat": 37.9506988525,
      "lon": 27.329000473,
      "tz": "Europe\/Istanbul"
  },
  "LTFC": {
      "icao": "LTFC",
      "iata": "ISE",
      "name": "Suleyman Demirel International Airport",
      "city": "Isparta",
      "state": "Isparta",
      "country": "TR",
      "elevation": 2835,
      "lat": 37.8554000854,
      "lon": 30.3684005737,
      "tz": "Europe\/Istanbul"
  },
  "LTFD": {
      "icao": "LTFD",
      "iata": "EDO",
      "name": "Balikesir Korfez Airport",
      "city": "Edremit",
      "state": "Balikesir",
      "country": "TR",
      "elevation": 50,
      "lat": 39.554599762,
      "lon": 27.0137996674,
      "tz": "Europe\/Istanbul"
  },
  "LTFE": {
      "icao": "LTFE",
      "iata": "BJV",
      "name": "Milas Bodrum International Airport",
      "city": "Bodrum",
      "state": "Mugla",
      "country": "TR",
      "elevation": 21,
      "lat": 37.2505989075,
      "lon": 27.6643009186,
      "tz": "Europe\/Istanbul"
  },
  "LTFG": {
      "icao": "LTFG",
      "iata": "GZP",
      "name": "Gazipasa Airport",
      "city": "Gazipasa",
      "state": "Antalya",
      "country": "TR",
      "elevation": 86,
      "lat": 36.2992172241,
      "lon": 32.3005981445,
      "tz": "Europe\/Istanbul"
  },
  "LTFH": {
      "icao": "LTFH",
      "iata": "SZF",
      "name": "Samsun Carsamba Airport",
      "city": "Samsun",
      "state": "Samsun",
      "country": "TR",
      "elevation": 18,
      "lat": 41.2545013428,
      "lon": 36.5671005249,
      "tz": "Europe\/Istanbul"
  },
  "LTFJ": {
      "icao": "LTFJ",
      "iata": "SAW",
      "name": "Sabiha Gokcen International Airport",
      "city": "Istanbul",
      "state": "Istanbul",
      "country": "TR",
      "elevation": 312,
      "lat": 40.898601532,
      "lon": 29.3092002869,
      "tz": "Europe\/Istanbul"
  },
  "LTFK": {
      "icao": "LTFK",
      "iata": "GKD",
      "name": "Imroz Airport",
      "city": "Gokceada",
      "state": "Canakkale",
      "country": "TR",
      "elevation": 73,
      "lat": 40.204498291,
      "lon": 25.8833007812,
      "tz": "Europe\/Istanbul"
  },
  "LTXE": {
      "icao": "LTXE",
      "iata": "",
      "name": "Karain Airport",
      "city": "Antalya",
      "state": "Hakkari",
      "country": "TR",
      "elevation": 1015,
      "lat": 37.0964431763,
      "lon": 30.6477355957,
      "tz": "Europe\/Istanbul"
  },
  "LUBL": {
      "icao": "LUBL",
      "iata": "BZY",
      "name": "Balti International Airport",
      "city": "Strymba",
      "state": "Bal\u0163i",
      "country": "MD",
      "elevation": 758,
      "lat": 47.8381004333,
      "lon": 27.7814998627,
      "tz": "Europe\/Chisinau"
  },
  "LUBM": {
      "icao": "LUBM",
      "iata": "",
      "name": "Marculesti International Airport",
      "city": "Marculesti",
      "state": "Flore\u015fti",
      "country": "MD",
      "elevation": 312,
      "lat": 47.862701416,
      "lon": 28.2127990723,
      "tz": "Europe\/Chisinau"
  },
  "LUCH": {
      "icao": "LUCH",
      "iata": "",
      "name": "Cahul International Airport",
      "city": "Cahul",
      "state": "Cahul",
      "country": "MD",
      "elevation": 652,
      "lat": 45.8437995911,
      "lon": 28.2637004852,
      "tz": "Europe\/Chisinau"
  },
  "LUCL": {
      "icao": "LUCL",
      "iata": "",
      "name": "Chadyr Lunga Airport",
      "city": "",
      "state": "Gagauzia",
      "country": "MD",
      "elevation": 587,
      "lat": 46.034994,
      "lon": 28.851844,
      "tz": "Europe\/Chisinau"
  },
  "LUKK": {
      "icao": "LUKK",
      "iata": "KIV",
      "name": "Chisinau International Airport",
      "city": "Chisinau",
      "state": "Chi\u0219inau-Municipality",
      "country": "MD",
      "elevation": 399,
      "lat": 46.9277000427,
      "lon": 28.9309997559,
      "tz": "Europe\/Chisinau"
  },
  "LUTR": {
      "icao": "LUTR",
      "iata": "",
      "name": "Tiraspol Airport",
      "city": "Tiraspol",
      "state": "Stinga-Nistrului",
      "country": "MD",
      "elevation": 141,
      "lat": 46.8680992126,
      "lon": 29.5905990601,
      "tz": "Europe\/Chisinau"
  },
  "LW75": {
      "icao": "LW75",
      "iata": "",
      "name": "Stenkovec Brazda Airport",
      "city": "Brazda",
      "state": "",
      "country": "MK",
      "elevation": 1150,
      "lat": 42.0591011047,
      "lon": 21.3910007477,
      "tz": "Europe\/Skopje"
  },
  "LWOH": {
      "icao": "LWOH",
      "iata": "OHD",
      "name": "Ohrid St. Paul the Apostle Airport",
      "city": "Ohrid",
      "state": "Debarca",
      "country": "MK",
      "elevation": 2313,
      "lat": 41.1800003052,
      "lon": 20.7423000336,
      "tz": "Europe\/Skopje"
  },
  "LWSK": {
      "icao": "LWSK",
      "iata": "SKP",
      "name": "Skopje Alexander the Great Airport",
      "city": "Skopje",
      "state": "Ilinden",
      "country": "MK",
      "elevation": 781,
      "lat": 41.9616012573,
      "lon": 21.6214008331,
      "tz": "Europe\/Skopje"
  },
  "LXGB": {
      "icao": "LXGB",
      "iata": "GIB",
      "name": "Gibraltar Airport",
      "city": "Gibraltar",
      "state": "",
      "country": "GI",
      "elevation": 15,
      "lat": 36.1511993408,
      "lon": -5.3496599197,
      "tz": "Europe\/Gibraltar"
  },
  "LY87": {
      "icao": "LY87",
      "iata": "",
      "name": "Kovin Airport",
      "city": "",
      "state": "",
      "country": "RS",
      "elevation": 280,
      "lat": 44.7743,
      "lon": 20.9613,
      "tz": "Europe\/Belgrade"
  },
  "LYBE": {
      "icao": "LYBE",
      "iata": "BEG",
      "name": "Belgrade Nikola Tesla Airport",
      "city": "Belgrad",
      "state": "",
      "country": "RS",
      "elevation": 335,
      "lat": 44.8184013367,
      "lon": 20.3090991974,
      "tz": "Europe\/Belgrade"
  },
  "LYBJ": {
      "icao": "LYBJ",
      "iata": "",
      "name": "Airport Lisicji Jarak",
      "city": "Belgrade",
      "state": "",
      "country": "RS",
      "elevation": 214,
      "lat": 44.9369583333,
      "lon": 20.4425555556,
      "tz": "Europe\/Belgrade"
  },
  "LYBO": {
      "icao": "LYBO",
      "iata": "",
      "name": "Bor Airport",
      "city": "Bor",
      "state": "",
      "country": "RS",
      "elevation": 1266,
      "lat": 44.0181999207,
      "lon": 22.1371002197,
      "tz": "Europe\/Belgrade"
  },
  "LYBR": {
      "icao": "LYBR",
      "iata": "IVG",
      "name": "Berane Airport",
      "city": "Berane",
      "state": "",
      "country": "ME",
      "elevation": 2287,
      "lat": 42.8390007019,
      "lon": 19.8619995117,
      "tz": "Europe\/Podgorica"
  },
  "LYBT": {
      "icao": "LYBT",
      "iata": "BJY",
      "name": "Batajnica Air Base",
      "city": "Batajnica",
      "state": "",
      "country": "RS",
      "elevation": 265,
      "lat": 44.935299,
      "lon": 20.2575,
      "tz": "Europe\/Belgrade"
  },
  "LYKI": {
      "icao": "LYKI",
      "iata": "",
      "name": "Kikinda Airport",
      "city": "Kikinda",
      "state": "",
      "country": "RS",
      "elevation": 266,
      "lat": 45.768122,
      "lon": 20.417261,
      "tz": "Europe\/Belgrade"
  },
  "LYKV": {
      "icao": "LYKV",
      "iata": "",
      "name": "Ladevci Airport",
      "city": "Kraljevo",
      "state": "",
      "country": "RS",
      "elevation": 686,
      "lat": 43.818298,
      "lon": 20.5872,
      "tz": "Europe\/Belgrade"
  },
  "LYNI": {
      "icao": "LYNI",
      "iata": "INI",
      "name": "Nis Airport",
      "city": "Nis",
      "state": "Central-Serbia",
      "country": "RS",
      "elevation": 648,
      "lat": 43.337299,
      "lon": 21.853701,
      "tz": "Europe\/Belgrade"
  },
  "LYNK": {
      "icao": "LYNK",
      "iata": "",
      "name": "Niksic Airport",
      "city": "Niksic",
      "state": "",
      "country": "ME",
      "elevation": 2008,
      "lat": 42.7737007141,
      "lon": 18.9148006439,
      "tz": "Europe\/Podgorica"
  },
  "LYNS": {
      "icao": "LYNS",
      "iata": "QND",
      "name": "Cenej Airport",
      "city": "Novi Sad",
      "state": "",
      "country": "RS",
      "elevation": 266,
      "lat": 45.385799,
      "lon": 19.839199,
      "tz": "Europe\/Belgrade"
  },
  "LYPG": {
      "icao": "LYPG",
      "iata": "TGD",
      "name": "Podgorica Airport",
      "city": "Podgorica",
      "state": "Podgorica",
      "country": "ME",
      "elevation": 141,
      "lat": 42.3594017029,
      "lon": 19.2518997192,
      "tz": "Europe\/Podgorica"
  },
  "LYPO": {
      "icao": "LYPO",
      "iata": "",
      "name": "Cemovsko Polje Airport",
      "city": "Podgorica",
      "state": "",
      "country": "ME",
      "elevation": 197,
      "lat": 42.4223175049,
      "lon": 19.2907772064,
      "tz": "Europe\/Podgorica"
  },
  "LYPT": {
      "icao": "LYPT",
      "iata": "",
      "name": "Batlava-Donja Penduha Airfield",
      "city": "Batlava",
      "state": "Pristina",
      "country": "KS",
      "elevation": 1978,
      "lat": 42.845500946,
      "lon": 21.2199993134,
      "tz": "Europe\/Belgrade"
  },
  "LYSM": {
      "icao": "LYSM",
      "iata": "",
      "name": "Veliki Radinci Airfield",
      "city": "Sremska Mitrovica",
      "state": "",
      "country": "RS",
      "elevation": 320,
      "lat": 45.037453,
      "lon": 19.660661,
      "tz": "Europe\/Belgrade"
  },
  "LYSP": {
      "icao": "LYSP",
      "iata": "",
      "name": "Rudine airfield",
      "city": "Smederevska palanka",
      "state": "",
      "country": "RS",
      "elevation": 0,
      "lat": 44.3513888889,
      "lon": 20.96,
      "tz": "Europe\/Belgrade"
  },
  "LYSU": {
      "icao": "LYSU",
      "iata": "",
      "name": "Subotica Airport",
      "city": "Subotica",
      "state": "",
      "country": "RS",
      "elevation": 340,
      "lat": 46.022837,
      "lon": 19.706454,
      "tz": "Europe\/Belgrade"
  },
  "LYTR": {
      "icao": "LYTR",
      "iata": "",
      "name": "Trstenik Airport",
      "city": "",
      "state": "",
      "country": "RS",
      "elevation": 0,
      "lat": 43.614189,
      "lon": 21.030217,
      "tz": "Europe\/Belgrade"
  },
  "LYTV": {
      "icao": "LYTV",
      "iata": "TIV",
      "name": "Tivat Airport",
      "city": "Tivat",
      "state": "",
      "country": "ME",
      "elevation": 20,
      "lat": 42.4047012329,
      "lon": 18.7233009338,
      "tz": "Europe\/Podgorica"
  },
  "LYUZ": {
      "icao": "LYUZ",
      "iata": "UZC",
      "name": "Ponikve Airport",
      "city": "Uzice",
      "state": "",
      "country": "RS",
      "elevation": 2966,
      "lat": 43.898899,
      "lon": 19.697701,
      "tz": "Europe\/Belgrade"
  },
  "LYVA": {
      "icao": "LYVA",
      "iata": "QWV",
      "name": "Divci Airport",
      "city": "Valjevo",
      "state": "",
      "country": "RS",
      "elevation": 469,
      "lat": 44.2978166667,
      "lon": 20.0219111111,
      "tz": "Europe\/Belgrade"
  },
  "LYVR": {
      "icao": "LYVR",
      "iata": "",
      "name": "Vrsac International Airport",
      "city": "",
      "state": "",
      "country": "RS",
      "elevation": 276,
      "lat": 45.1469,
      "lon": 21.3099,
      "tz": "Europe\/Belgrade"
  },
  "LYZR": {
      "icao": "LYZR",
      "iata": "ZRE",
      "name": "Zrenjanin Airport",
      "city": "Zrenjanin",
      "state": "Vojvodina",
      "country": "RS",
      "elevation": 246,
      "lat": 45.339756,
      "lon": 20.454078,
      "tz": "Europe\/Belgrade"
  },
  "LZDB": {
      "icao": "LZDB",
      "iata": "",
      "name": "Dubnica Glider Airport",
      "city": "Dubnica nad Vahom",
      "state": "Trenciansky",
      "country": "SK",
      "elevation": 771,
      "lat": 48.9968986511,
      "lon": 18.192199707,
      "tz": "Europe\/Bratislava"
  },
  "LZHL": {
      "icao": "LZHL",
      "iata": "",
      "name": "Holic Airport",
      "city": "Holic",
      "state": "Trnavsky",
      "country": "SK",
      "elevation": 520,
      "lat": 48.8103,
      "lon": 17.1338,
      "tz": "Europe\/Bratislava"
  },
  "LZIB": {
      "icao": "LZIB",
      "iata": "BTS",
      "name": "M. R. Stefanik Airport",
      "city": "Bratislava",
      "state": "Bratislavsky",
      "country": "SK",
      "elevation": 436,
      "lat": 48.1702003479,
      "lon": 17.2126998901,
      "tz": "Europe\/Bratislava"
  },
  "LZKC": {
      "icao": "LZKC",
      "iata": "",
      "name": "Kamenica nad Cirochou Airport",
      "city": "Kamenica nad Cirochou",
      "state": "Presovsky",
      "country": "SK",
      "elevation": 560,
      "lat": 48.937,
      "lon": 21.9952,
      "tz": "Europe\/Bratislava"
  },
  "LZKS": {
      "icao": "LZKS",
      "iata": "",
      "name": "Kralova pri Senci Airport",
      "city": "Senec",
      "state": "Bratislavsky",
      "country": "SK",
      "elevation": 400,
      "lat": 48.2010993958,
      "lon": 17.4719009399,
      "tz": "Europe\/Bratislava"
  },
  "LZKZ": {
      "icao": "LZKZ",
      "iata": "KSC",
      "name": "Kosice Airport",
      "city": "Kosice",
      "state": "Kosicky",
      "country": "SK",
      "elevation": 755,
      "lat": 48.6631011963,
      "lon": 21.2411003113,
      "tz": "Europe\/Bratislava"
  },
  "LZLU": {
      "icao": "LZLU",
      "iata": "LUE",
      "name": "Lucenec Airport",
      "city": "Lucenec",
      "state": "Banskobystricky",
      "country": "SK",
      "elevation": 0,
      "lat": 48.3394012451,
      "lon": 19.7357997894,
      "tz": "Europe\/Bratislava"
  },
  "LZMA": {
      "icao": "LZMA",
      "iata": "",
      "name": "Martin Glider Airport",
      "city": "Martin",
      "state": "Zilinsky",
      "country": "SK",
      "elevation": 1381,
      "lat": 49.0652999878,
      "lon": 18.950799942,
      "tz": "Europe\/Bratislava"
  },
  "LZMC": {
      "icao": "LZMC",
      "iata": "",
      "name": "Malacky Air Base",
      "city": "Malacky",
      "state": "Bratislavsky",
      "country": "SK",
      "elevation": 679,
      "lat": 48.4020004272,
      "lon": 17.1184005737,
      "tz": "Europe\/Bratislava"
  },
  "LZNI": {
      "icao": "LZNI",
      "iata": "",
      "name": "Nitra Glider Airport",
      "city": "Nitra",
      "state": "Nitriansky",
      "country": "SK",
      "elevation": 440,
      "lat": 48.2793998718,
      "lon": 18.1327991486,
      "tz": "Europe\/Bratislava"
  },
  "LZPE": {
      "icao": "LZPE",
      "iata": "",
      "name": "Prievidza Glider Airport",
      "city": "Prievidza",
      "state": "Nitriansky",
      "country": "SK",
      "elevation": 850,
      "lat": 48.7661018372,
      "lon": 18.5867004395,
      "tz": "Europe\/Bratislava"
  },
  "LZPP": {
      "icao": "LZPP",
      "iata": "PZY",
      "name": "Piestany Airport",
      "city": "Piestany",
      "state": "",
      "country": "SK",
      "elevation": 545,
      "lat": 48.6251983643,
      "lon": 17.8283996582,
      "tz": "Europe\/Bratislava"
  },
  "LZPT": {
      "icao": "LZPT",
      "iata": "",
      "name": "Male Bielice Glider Airport",
      "city": "Partizanske",
      "state": "Trenciansky",
      "country": "SK",
      "elevation": 600,
      "lat": 48.6192016602,
      "lon": 18.32970047,
      "tz": "Europe\/Bratislava"
  },
  "LZPW": {
      "icao": "LZPW",
      "iata": "POV",
      "name": "Presov Air Base",
      "city": "Presov",
      "state": "Presovsky",
      "country": "SK",
      "elevation": 1060,
      "lat": 49.0297012329,
      "lon": 21.3155994415,
      "tz": "Europe\/Bratislava"
  },
  "LZSE": {
      "icao": "LZSE",
      "iata": "",
      "name": "Senica Airport",
      "city": "Senica",
      "state": "",
      "country": "SK",
      "elevation": 610,
      "lat": 48.6568984985,
      "lon": 17.3321990967,
      "tz": "Europe\/Bratislava"
  },
  "LZSK": {
      "icao": "LZSK",
      "iata": "",
      "name": "Svidnik Airport",
      "city": "Svidnik",
      "state": "Presovsky",
      "country": "SK",
      "elevation": 1161,
      "lat": 49.3339004517,
      "lon": 21.5702991486,
      "tz": "Europe\/Bratislava"
  },
  "LZSL": {
      "icao": "LZSL",
      "iata": "SLD",
      "name": "Sliac Airport",
      "city": "Sliac",
      "state": "Banskobystricky",
      "country": "SK",
      "elevation": 1043,
      "lat": 48.6377983093,
      "lon": 19.1340999603,
      "tz": "Europe\/Bratislava"
  },
  "LZSV": {
      "icao": "LZSV",
      "iata": "",
      "name": "Spisska Nova Glider Airport",
      "city": "Spisska Nova Ves",
      "state": "Kosicky",
      "country": "SK",
      "elevation": 1621,
      "lat": 48.9407997131,
      "lon": 20.5338993073,
      "tz": "Europe\/Bratislava"
  },
  "LZTN": {
      "icao": "LZTN",
      "iata": "",
      "name": "Trencin Air Base",
      "city": "Trencin",
      "state": "Nitriansky",
      "country": "SK",
      "elevation": 676,
      "lat": 48.8650016785,
      "lon": 17.9923000336,
      "tz": "Europe\/Bratislava"
  },
  "LZTR": {
      "icao": "LZTR",
      "iata": "",
      "name": "Letisko Airport",
      "city": "Boleraz",
      "state": "Trnavsky",
      "country": "SK",
      "elevation": 666,
      "lat": 48.4557991028,
      "lon": 17.5263996124,
      "tz": "Europe\/Bratislava"
  },
  "LZTT": {
      "icao": "LZTT",
      "iata": "TAT",
      "name": "Poprad-Tatry Airport",
      "city": "Poprad",
      "state": "Presovsky",
      "country": "SK",
      "elevation": 2356,
      "lat": 49.073600769,
      "lon": 20.2411003113,
      "tz": "Europe\/Bratislava"
  },
  "LZZI": {
      "icao": "LZZI",
      "iata": "ILZ",
      "name": "Zilina Airport",
      "city": "Zilina",
      "state": "Zilinsky",
      "country": "SK",
      "elevation": 1020,
      "lat": 49.2314987183,
      "lon": 18.6135005951,
      "tz": "Europe\/Bratislava"
  },
  "MA07": {
      "icao": "MA07",
      "iata": "",
      "name": "Friends Ultralightport",
      "city": "Westport",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 131,
      "lat": 41.5676002502,
      "lon": -71.1192016602,
      "tz": "America\/New_York"
  },
  "MA16": {
      "icao": "MA16",
      "iata": "",
      "name": "Marshall's Airport",
      "city": "Holden",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 630,
      "lat": 42.3633995056,
      "lon": -71.8217010498,
      "tz": "America\/New_York"
  },
  "MA18": {
      "icao": "MA18",
      "iata": "",
      "name": "Cmelak Field",
      "city": "Sheffield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 980,
      "lat": 42.0791015625,
      "lon": -73.3071975708,
      "tz": "America\/New_York"
  },
  "MA19": {
      "icao": "MA19",
      "iata": "",
      "name": "Berlin Lndg Area",
      "city": "Berlin",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 270,
      "lat": 42.3675994873,
      "lon": -71.6417007446,
      "tz": "America\/New_York"
  },
  "MA30": {
      "icao": "MA30",
      "iata": "",
      "name": "Dresser Hill Airport",
      "city": "Charlton",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 810,
      "lat": 42.1001014709,
      "lon": -71.9662017822,
      "tz": "America\/New_York"
  },
  "MA36": {
      "icao": "MA36",
      "iata": "",
      "name": "Snow Airport",
      "city": "Ipswich",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 61,
      "lat": 42.6665000916,
      "lon": -70.8529968262,
      "tz": "America\/New_York"
  },
  "MA43": {
      "icao": "MA43",
      "iata": "",
      "name": "Morehaven Airport",
      "city": "Granville",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 1120,
      "lat": 42.0722999573,
      "lon": -72.900100708,
      "tz": "America\/New_York"
  },
  "MA44": {
      "icao": "MA44",
      "iata": "",
      "name": "Trade Wind Airport",
      "city": "Oak Bluffs",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 43,
      "lat": 41.4417991638,
      "lon": -70.5702972412,
      "tz": "America\/New_York"
  },
  "MA52": {
      "icao": "MA52",
      "iata": "",
      "name": "Sids Airport",
      "city": "Maynard",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 220,
      "lat": 42.4381713867,
      "lon": -71.4702453613,
      "tz": "America\/New_York"
  },
  "MA53": {
      "icao": "MA53",
      "iata": "",
      "name": "Ware Airport",
      "city": "Ware",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 483,
      "lat": 42.2820014954,
      "lon": -72.2147979736,
      "tz": "America\/New_York"
  },
  "MA55": {
      "icao": "MA55",
      "iata": "",
      "name": "Muskeget Island Airport",
      "city": "Muskeget Island",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 90,
      "lat": 41.3348007202,
      "lon": -70.2994995117,
      "tz": "America\/New_York"
  },
  "MA63": {
      "icao": "MA63",
      "iata": "",
      "name": "Sherman-Private Airport",
      "city": "Pembroke",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 34,
      "lat": 42.0740013123,
      "lon": -70.7981033325,
      "tz": "America\/New_York"
  },
  "MA64": {
      "icao": "MA64",
      "iata": "",
      "name": "Pheasant Field",
      "city": "Pembroke",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 40,
      "lat": 42.0754013062,
      "lon": -70.8152999878,
      "tz": "America\/New_York"
  },
  "MA66": {
      "icao": "MA66",
      "iata": "",
      "name": "Wolomolopoag STOLport",
      "city": "Sharon",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 276,
      "lat": 42.1007003784,
      "lon": -71.2078018188,
      "tz": "America\/New_York"
  },
  "MA70": {
      "icao": "MA70",
      "iata": "",
      "name": "Sudbury Airport",
      "city": "Sudbury",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 135,
      "lat": 42.3508987427,
      "lon": -71.4298019409,
      "tz": "America\/New_York"
  },
  "MA72": {
      "icao": "MA72",
      "iata": "",
      "name": "Tuckernuck Airport",
      "city": "Tuckernuck Island",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 23,
      "lat": 41.2975006104,
      "lon": -70.2603988647,
      "tz": "America\/New_York"
  },
  "MA75": {
      "icao": "MA75",
      "iata": "",
      "name": "Sky Glen Airport",
      "city": "Uxbridge",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 260,
      "lat": 42.0409011841,
      "lon": -71.6162033081,
      "tz": "America\/New_York"
  },
  "MA77": {
      "icao": "MA77",
      "iata": "",
      "name": "Blueberry Hill Airport",
      "city": "Washington",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 2000,
      "lat": 42.3750991821,
      "lon": -73.1475982666,
      "tz": "America\/New_York"
  },
  "MA80": {
      "icao": "MA80",
      "iata": "",
      "name": "Mundale Airport",
      "city": "Westfield",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 280,
      "lat": 42.1198005676,
      "lon": -72.8143005371,
      "tz": "America\/New_York"
  },
  "MA86": {
      "icao": "MA86",
      "iata": "",
      "name": "Kendalls Lndg Area Airport",
      "city": "Windsor",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 2102,
      "lat": 42.5055999756,
      "lon": -73.0597991943,
      "tz": "America\/New_York"
  },
  "MA88": {
      "icao": "MA88",
      "iata": "",
      "name": "Albert Farms Airport",
      "city": "Worthington",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 1425,
      "lat": 42.3900985718,
      "lon": -72.9309005737,
      "tz": "America\/New_York"
  },
  "MA89": {
      "icao": "MA89",
      "iata": "",
      "name": "B&B Farm Airport",
      "city": "New Braintree",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 980,
      "lat": 42.3460998535,
      "lon": -72.1038970947,
      "tz": "America\/New_York"
  },
  "MA97": {
      "icao": "MA97",
      "iata": "",
      "name": "Waters Airport",
      "city": "Sutton",
      "state": "Massachusetts",
      "country": "US",
      "elevation": 700,
      "lat": 42.1250991821,
      "lon": -71.7397994995,
      "tz": "America\/New_York"
  },
  "MBAC": {
      "icao": "MBAC",
      "iata": "",
      "name": "Ambergris Cay International Airport",
      "city": "Big Ambergris Cay",
      "state": "Big Ambergris Cay",
      "country": "GB",
      "elevation": 9,
      "lat": 21.3006333,
      "lon": -71.64115,
      "tz": "America\/Grand_Turk"
  },
  "MBGT": {
      "icao": "MBGT",
      "iata": "GDT",
      "name": "JAGS McCartney International Airport",
      "city": "Cockburn Town",
      "state": "",
      "country": "TC",
      "elevation": 13,
      "lat": 21.4444999695,
      "lon": -71.1423034668,
      "tz": "America\/Grand_Turk"
  },
  "MBMC": {
      "icao": "MBMC",
      "iata": "MDS",
      "name": "Middle Caicos Airport",
      "city": "Middle Caicos",
      "state": "",
      "country": "TC",
      "elevation": 9,
      "lat": 21.82602,
      "lon": -71.8025,
      "tz": "America\/Grand_Turk"
  },
  "MBNC": {
      "icao": "MBNC",
      "iata": "NCA",
      "name": "North Caicos Airport",
      "city": "",
      "state": "",
      "country": "TC",
      "elevation": 10,
      "lat": 21.9174995422,
      "lon": -71.9395980835,
      "tz": "America\/Grand_Turk"
  },
  "MBPI": {
      "icao": "MBPI",
      "iata": "PIC",
      "name": "Pine Cay Airport",
      "city": "Pine Cay",
      "state": "",
      "country": "TC",
      "elevation": 3,
      "lat": 21.8999996185,
      "lon": -72.0999984741,
      "tz": "America\/Grand_Turk"
  },
  "MBPV": {
      "icao": "MBPV",
      "iata": "PLS",
      "name": "Providenciales Airport",
      "city": "Providenciales Island",
      "state": "",
      "country": "TC",
      "elevation": 15,
      "lat": 21.7735996246,
      "lon": -72.2658996582,
      "tz": "America\/Grand_Turk"
  },
  "MBSC": {
      "icao": "MBSC",
      "iata": "XSC",
      "name": "South Caicos Airport",
      "city": "",
      "state": "",
      "country": "TC",
      "elevation": 6,
      "lat": 21.5156993866,
      "lon": -71.528503418,
      "tz": "America\/Grand_Turk"
  },
  "MBSY": {
      "icao": "MBSY",
      "iata": "SLX",
      "name": "Salt Cay Airport",
      "city": "Salt Cay",
      "state": "",
      "country": "TC",
      "elevation": 3,
      "lat": 21.3330001831,
      "lon": -71.1999969482,
      "tz": "America\/Grand_Turk"
  },
  "MD00": {
      "icao": "MD00",
      "iata": "",
      "name": "Fair's Airport",
      "city": "Pocomoke City",
      "state": "Maryland",
      "country": "US",
      "elevation": 22,
      "lat": 38.0461997986,
      "lon": -75.5532989502,
      "tz": "America\/New_York"
  },
  "MD01": {
      "icao": "MD01",
      "iata": "",
      "name": "Wingfield Airport",
      "city": "Ridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 20,
      "lat": 38.1435012817,
      "lon": -76.3565979004,
      "tz": "America\/New_York"
  },
  "MD03": {
      "icao": "MD03",
      "iata": "",
      "name": "Woodstock Airport",
      "city": "Chesapeake City",
      "state": "Maryland",
      "country": "US",
      "elevation": 81,
      "lat": 39.4883995056,
      "lon": -75.8110961914,
      "tz": "America\/New_York"
  },
  "MD04": {
      "icao": "MD04",
      "iata": "",
      "name": "Rossneck Airport",
      "city": "Cambridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 5,
      "lat": 38.568901062,
      "lon": -76.2358016968,
      "tz": "America\/New_York"
  },
  "MD05": {
      "icao": "MD05",
      "iata": "",
      "name": "Finagin Airfield",
      "city": "Welcome",
      "state": "Maryland",
      "country": "US",
      "elevation": 185,
      "lat": 38.5074996948,
      "lon": -77.12159729,
      "tz": "America\/New_York"
  },
  "MD06": {
      "icao": "MD06",
      "iata": "",
      "name": "Pilots Cove Airport",
      "city": "Tompkinsville",
      "state": "Maryland",
      "country": "US",
      "elevation": 20,
      "lat": 38.3353996277,
      "lon": -76.8786010742,
      "tz": "America\/New_York"
  },
  "MD09": {
      "icao": "MD09",
      "iata": "",
      "name": "Meadow Brook Airport",
      "city": "Queen Anne",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 38.9154014587,
      "lon": -75.9710006714,
      "tz": "America\/New_York"
  },
  "MD11": {
      "icao": "MD11",
      "iata": "",
      "name": "Wright Field",
      "city": "Chestertown",
      "state": "Maryland",
      "country": "US",
      "elevation": 80,
      "lat": 39.2770996094,
      "lon": -76.0626983643,
      "tz": "America\/New_York"
  },
  "MD12": {
      "icao": "MD12",
      "iata": "",
      "name": "Bell Airport",
      "city": "Emmittsburg",
      "state": "Maryland",
      "country": "US",
      "elevation": 370,
      "lat": 39.6363983154,
      "lon": -77.2928009033,
      "tz": "America\/New_York"
  },
  "MD14": {
      "icao": "MD14",
      "iata": "",
      "name": "Robinson Private Airport",
      "city": "Benedict",
      "state": "Maryland",
      "country": "US",
      "elevation": 20,
      "lat": 38.5242996216,
      "lon": -76.6836013794,
      "tz": "America\/New_York"
  },
  "MD17": {
      "icao": "MD17",
      "iata": "",
      "name": "Tinsley Airstrip",
      "city": "Butler",
      "state": "Maryland",
      "country": "US",
      "elevation": 400,
      "lat": 39.5167999268,
      "lon": -76.733001709,
      "tz": "America\/New_York"
  },
  "MD18": {
      "icao": "MD18",
      "iata": "",
      "name": "Horn Point Airport",
      "city": "Cambridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 12,
      "lat": 38.5895996094,
      "lon": -76.1384963989,
      "tz": "America\/New_York"
  },
  "MD19": {
      "icao": "MD19",
      "iata": "",
      "name": "Hybarc Farm Airport",
      "city": "Chestertown",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 39.2032012939,
      "lon": -76.0249023438,
      "tz": "America\/New_York"
  },
  "MD20": {
      "icao": "MD20",
      "iata": "",
      "name": "Greer Airport",
      "city": "Taneytown",
      "state": "Maryland",
      "country": "US",
      "elevation": 520,
      "lat": 39.6361999512,
      "lon": -77.1228027344,
      "tz": "America\/New_York"
  },
  "MD21": {
      "icao": "MD21",
      "iata": "",
      "name": "Ashland Landing Farm Airport",
      "city": "Centreville",
      "state": "Maryland",
      "country": "US",
      "elevation": 10,
      "lat": 39.1180992126,
      "lon": -76.0963973999,
      "tz": "America\/New_York"
  },
  "MD22": {
      "icao": "MD22",
      "iata": "",
      "name": "Deale Airport",
      "city": "Deale",
      "state": "Maryland",
      "country": "US",
      "elevation": 15,
      "lat": 38.7834014893,
      "lon": -76.5646972656,
      "tz": "America\/New_York"
  },
  "MD23": {
      "icao": "MD23",
      "iata": "",
      "name": "Kennersley Airport",
      "city": "Church Hill",
      "state": "Maryland",
      "country": "US",
      "elevation": 30,
      "lat": 39.1447982788,
      "lon": -76.0404968262,
      "tz": "America\/New_York"
  },
  "MD24": {
      "icao": "MD24",
      "iata": "",
      "name": "Haysfield Airport",
      "city": "Clarksville",
      "state": "Maryland",
      "country": "US",
      "elevation": 420,
      "lat": 39.2382011414,
      "lon": -76.9466018677,
      "tz": "America\/New_York"
  },
  "MD28": {
      "icao": "MD28",
      "iata": "",
      "name": "Ewing Airport",
      "city": "Easton",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 38.809299469,
      "lon": -75.9922027588,
      "tz": "America\/New_York"
  },
  "MD31": {
      "icao": "MD31",
      "iata": "",
      "name": "Forest Hill Airport",
      "city": "Forest Hill",
      "state": "Maryland",
      "country": "US",
      "elevation": 476,
      "lat": 39.5801010132,
      "lon": -76.3747024536,
      "tz": "America\/New_York"
  },
  "MD35": {
      "icao": "MD35",
      "iata": "",
      "name": "Spring Hill Airport",
      "city": "Hebron",
      "state": "Maryland",
      "country": "US",
      "elevation": 42,
      "lat": 38.4347991943,
      "lon": -75.6512985229,
      "tz": "America\/New_York"
  },
  "MD39": {
      "icao": "MD39",
      "iata": "",
      "name": "Buds Ferry Airport",
      "city": "Indian Head",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 38.5209999084,
      "lon": -77.2518997192,
      "tz": "America\/New_York"
  },
  "MD41": {
      "icao": "MD41",
      "iata": "",
      "name": "Gary Field",
      "city": "Denton",
      "state": "Maryland",
      "country": "US",
      "elevation": 50,
      "lat": 38.8983001709,
      "lon": -75.9105987549,
      "tz": "America\/New_York"
  },
  "MD42": {
      "icao": "MD42",
      "iata": "",
      "name": "Keymar Airpark",
      "city": "Keymar",
      "state": "Maryland",
      "country": "US",
      "elevation": 437,
      "lat": 39.6194992065,
      "lon": -77.2275009155,
      "tz": "America\/New_York"
  },
  "MD43": {
      "icao": "MD43",
      "iata": "",
      "name": "Mountain Road Airport",
      "city": "Lakeshore",
      "state": "Maryland",
      "country": "US",
      "elevation": 70,
      "lat": 39.1184005737,
      "lon": -76.49659729,
      "tz": "America\/New_York"
  },
  "MD45": {
      "icao": "MD45",
      "iata": "",
      "name": "Hampton Airport",
      "city": "Leonardtown",
      "state": "Maryland",
      "country": "US",
      "elevation": 18,
      "lat": 38.2307014465,
      "lon": -76.6287994385,
      "tz": "America\/New_York"
  },
  "MD46": {
      "icao": "MD46",
      "iata": "",
      "name": "Glenair Airport",
      "city": "Dayton",
      "state": "Maryland",
      "country": "US",
      "elevation": 590,
      "lat": 39.2695999146,
      "lon": -77.0053024292,
      "tz": "America\/New_York"
  },
  "MD47": {
      "icao": "MD47",
      "iata": "",
      "name": "Barnes Airport",
      "city": "Lisbon",
      "state": "Maryland",
      "country": "US",
      "elevation": 780,
      "lat": 39.332901001,
      "lon": -77.0969009399,
      "tz": "America\/New_York"
  },
  "MD48": {
      "icao": "MD48",
      "iata": "",
      "name": "Albrecht Airstrip",
      "city": "Long Green",
      "state": "Maryland",
      "country": "US",
      "elevation": 325,
      "lat": 39.473400116,
      "lon": -76.5001983643,
      "tz": "America\/New_York"
  },
  "MD50": {
      "icao": "MD50",
      "iata": "",
      "name": "Chesapeake Ranch Airstrip",
      "city": "Lusby",
      "state": "Maryland",
      "country": "US",
      "elevation": 116,
      "lat": 38.3611984253,
      "lon": -76.4051971436,
      "tz": "America\/New_York"
  },
  "MD53": {
      "icao": "MD53",
      "iata": "",
      "name": "Mac Kinnis Airport",
      "city": "Salisbury",
      "state": "Maryland",
      "country": "US",
      "elevation": 10,
      "lat": 38.2887001038,
      "lon": -75.739402771,
      "tz": "America\/New_York"
  },
  "MD55": {
      "icao": "MD55",
      "iata": "",
      "name": "Holly Springs Farm Airport",
      "city": "Nanjemoy",
      "state": "Maryland",
      "country": "US",
      "elevation": 90,
      "lat": 38.3975982666,
      "lon": -77.1860961914,
      "tz": "America\/New_York"
  },
  "MD56": {
      "icao": "MD56",
      "iata": "",
      "name": "Three J Airport",
      "city": "New Windsor",
      "state": "Maryland",
      "country": "US",
      "elevation": 510,
      "lat": 39.5690002441,
      "lon": -77.1213989258,
      "tz": "America\/New_York"
  },
  "MD57": {
      "icao": "MD57",
      "iata": "",
      "name": "Beverly Airport",
      "city": "Pocomoke City",
      "state": "Maryland",
      "country": "US",
      "elevation": 10,
      "lat": 38.0070991516,
      "lon": -75.617401123,
      "tz": "America\/New_York"
  },
  "MD59": {
      "icao": "MD59",
      "iata": "",
      "name": "Silver Hill Airport",
      "city": "Chestertown",
      "state": "Maryland",
      "country": "US",
      "elevation": 30,
      "lat": 39.1398010254,
      "lon": -76.0935974121,
      "tz": "America\/New_York"
  },
  "MD61": {
      "icao": "MD61",
      "iata": "",
      "name": "Lynch's Landing Airport",
      "city": "Rising Sun",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 510,
      "lat": 39.7246017456,
      "lon": -76.0705032349,
      "tz": "America\/New_York"
  },
  "MD62": {
      "icao": "MD62",
      "iata": "",
      "name": "Church Hill Airport",
      "city": "Church Hill",
      "state": "Maryland",
      "country": "US",
      "elevation": 70,
      "lat": 39.125,
      "lon": -76.0016021729,
      "tz": "America\/New_York"
  },
  "MD63": {
      "icao": "MD63",
      "iata": "",
      "name": "Cromwell Farm Airport",
      "city": "Chestertown",
      "state": "Maryland",
      "country": "US",
      "elevation": 65,
      "lat": 39.2747993469,
      "lon": -76.0099029541,
      "tz": "America\/New_York"
  },
  "MD64": {
      "icao": "MD64",
      "iata": "",
      "name": "Stewart Airport",
      "city": "St Michaels",
      "state": "Maryland",
      "country": "US",
      "elevation": 10,
      "lat": 38.7995986938,
      "lon": -76.2723999023,
      "tz": "America\/New_York"
  },
  "MD66": {
      "icao": "MD66",
      "iata": "",
      "name": "Smith Field",
      "city": "Chestertown",
      "state": "Maryland",
      "country": "US",
      "elevation": 60,
      "lat": 39.3378982544,
      "lon": -76.0979995728,
      "tz": "America\/New_York"
  },
  "MD69": {
      "icao": "MD69",
      "iata": "",
      "name": "Harris Airport",
      "city": "Still Pond",
      "state": "Maryland",
      "country": "US",
      "elevation": 80,
      "lat": 39.3334007263,
      "lon": -76.0830001831,
      "tz": "America\/New_York"
  },
  "MD70": {
      "icao": "MD70",
      "iata": "",
      "name": "Flying Acres Airport",
      "city": "Sudlersville",
      "state": "Maryland",
      "country": "US",
      "elevation": 70,
      "lat": 39.1811981201,
      "lon": -75.9087982178,
      "tz": "America\/New_York"
  },
  "MD73": {
      "icao": "MD73",
      "iata": "",
      "name": "Happy Landings Farm Airport",
      "city": "Unionville",
      "state": "Maryland",
      "country": "US",
      "elevation": 693,
      "lat": 39.4584007263,
      "lon": -77.1480026245,
      "tz": "America\/New_York"
  },
  "MD74": {
      "icao": "MD74",
      "iata": "",
      "name": "Good Neighbor Farm Airport",
      "city": "Unionville",
      "state": "Maryland",
      "country": "US",
      "elevation": 580,
      "lat": 39.4743003845,
      "lon": -77.1952972412,
      "tz": "America\/New_York"
  },
  "MD75": {
      "icao": "MD75",
      "iata": "",
      "name": "Stolcrest STOLport",
      "city": "Urbana",
      "state": "Maryland",
      "country": "US",
      "elevation": 530,
      "lat": 39.3418006897,
      "lon": -77.3035964966,
      "tz": "America\/New_York"
  },
  "MD77": {
      "icao": "MD77",
      "iata": "",
      "name": "Flying M Farms Airport",
      "city": "Comus",
      "state": "Maryland",
      "country": "US",
      "elevation": 590,
      "lat": 39.2597999573,
      "lon": -77.3414001465,
      "tz": "America\/New_York"
  },
  "MD78": {
      "icao": "MD78",
      "iata": "",
      "name": "Woodbine Airport",
      "city": "Woodbine",
      "state": "Maryland",
      "country": "US",
      "elevation": 720,
      "lat": 39.3833999634,
      "lon": -77.0702972412,
      "tz": "America\/New_York"
  },
  "MD82": {
      "icao": "MD82",
      "iata": "",
      "name": "Ragged Island Airport",
      "city": "Cambridge",
      "state": "Maryland",
      "country": "US",
      "elevation": 5,
      "lat": 38.5397987366,
      "lon": -76.2754974365,
      "tz": "America\/New_York"
  },
  "MD83": {
      "icao": "MD83",
      "iata": "",
      "name": "Ty-Ti-To Airport",
      "city": "La Plata",
      "state": "Maryland",
      "country": "US",
      "elevation": 170,
      "lat": 38.5208015442,
      "lon": -77.0680999756,
      "tz": "America\/New_York"
  },
  "MD85": {
      "icao": "MD85",
      "iata": "",
      "name": "Carmean Airport",
      "city": "Ridgely",
      "state": "Maryland",
      "country": "US",
      "elevation": 58,
      "lat": 38.9328994751,
      "lon": -75.8901977539,
      "tz": "America\/New_York"
  },
  "MD91": {
      "icao": "MD91",
      "iata": "",
      "name": "Saxon Farms Airport",
      "city": "Church Hill",
      "state": "Maryland",
      "country": "US",
      "elevation": 70,
      "lat": 39.1523017883,
      "lon": -76.0186004639,
      "tz": "America\/New_York"
  },
  "MD93": {
      "icao": "MD93",
      "iata": "",
      "name": "Hexton Farms Airport",
      "city": "Cecilton",
      "state": "Maryland",
      "country": "US",
      "elevation": 65,
      "lat": 39.3750991821,
      "lon": -75.9066009521,
      "tz": "America\/New_York"
  },
  "MD94": {
      "icao": "MD94",
      "iata": "",
      "name": "Farmington Airport",
      "city": "Farmington",
      "state": "Maryland",
      "country": "US",
      "elevation": 445,
      "lat": 39.6764984131,
      "lon": -76.0369033813,
      "tz": "America\/New_York"
  },
  "MD95": {
      "icao": "MD95",
      "iata": "",
      "name": "Reservoir Airport",
      "city": "Finksburg",
      "state": "Maryland",
      "country": "US",
      "elevation": 550,
      "lat": 39.4819984436,
      "lon": -76.8815994263,
      "tz": "America\/New_York"
  },
  "MD97": {
      "icao": "MD97",
      "iata": "",
      "name": "Lanseair Farms Airport",
      "city": "La Plata",
      "state": "Maryland",
      "country": "US",
      "elevation": 40,
      "lat": 38.4614982605,
      "lon": -77.0464019775,
      "tz": "America\/New_York"
  },
  "MD99": {
      "icao": "MD99",
      "iata": "",
      "name": "Moran Field",
      "city": "Westernport",
      "state": "Maryland",
      "country": "US",
      "elevation": 2080,
      "lat": 39.5005989075,
      "lon": -79.072303772,
      "tz": "America\/New_York"
  },
  "MDAB": {
      "icao": "MDAB",
      "iata": "EPS",
      "name": "Arroyo Barril Airport",
      "city": "Arroyo Barril",
      "state": "Samana",
      "country": "DO",
      "elevation": 57,
      "lat": 19.198600769,
      "lon": -69.4298019409,
      "tz": "America\/Santo_Domingo"
  },
  "MDAD": {
      "icao": "MDAD",
      "iata": "",
      "name": "Azua Airport",
      "city": "Azua",
      "state": "Azua",
      "country": "DO",
      "elevation": 262,
      "lat": 18.4255008698,
      "lon": -70.7313995361,
      "tz": "America\/Santo_Domingo"
  },
  "MDAN": {
      "icao": "MDAN",
      "iata": "",
      "name": "Cotui Angelina Airport",
      "city": "Cotui Angelina",
      "state": "Sanchez-Ramirez",
      "country": "DO",
      "elevation": 200,
      "lat": 19.1315994263,
      "lon": -70.2216033936,
      "tz": "America\/Santo_Domingo"
  },
  "MDBA": {
      "icao": "MDBA",
      "iata": "",
      "name": "Consuelo Batey Anita airport",
      "city": "Consuelo",
      "state": "Hato-Mayor",
      "country": "DO",
      "elevation": 150,
      "lat": 18.6833324432,
      "lon": -69.3833312988,
      "tz": "America\/Santo_Domingo"
  },
  "MDBC": {
      "icao": "MDBC",
      "iata": "",
      "name": "La Romana Batey Cacata airport",
      "city": "La Romana",
      "state": "La-Romana",
      "country": "DO",
      "elevation": 223,
      "lat": 18.4797000885,
      "lon": -68.9159011841,
      "tz": "America\/Santo_Domingo"
  },
  "MDBE": {
      "icao": "MDBE",
      "iata": "",
      "name": "La Romana Batey Lechuga Airport",
      "city": "La Romana",
      "state": "El-Seibo",
      "country": "DO",
      "elevation": 252,
      "lat": 18.5867996216,
      "lon": -69.0553970337,
      "tz": "America\/Santo_Domingo"
  },
  "MDBG": {
      "icao": "MDBG",
      "iata": "",
      "name": "Higuey Airport",
      "city": "Higuey",
      "state": "La-Altagracia",
      "country": "DO",
      "elevation": 429,
      "lat": 18.6315994263,
      "lon": -68.7378005981,
      "tz": "America\/Santo_Domingo"
  },
  "MDBH": {
      "icao": "MDBH",
      "iata": "BRX",
      "name": "Maria Montez International Airport",
      "city": "Barahona",
      "state": "Barahona",
      "country": "DO",
      "elevation": 10,
      "lat": 18.251499176,
      "lon": -71.1203994751,
      "tz": "America\/Santo_Domingo"
  },
  "MDBL": {
      "icao": "MDBL",
      "iata": "",
      "name": "Boca Chica Airport",
      "city": "Boca Chica",
      "state": "Santo-Domingo",
      "country": "DO",
      "elevation": 1,
      "lat": 18.450799942,
      "lon": -69.5925979614,
      "tz": "America\/Santo_Domingo"
  },
  "MDBM": {
      "icao": "MDBM",
      "iata": "",
      "name": "San Pedro De Macoris Airport",
      "city": "San Pedro de Macoris",
      "state": "San-Pedro-de-Macoris",
      "country": "DO",
      "elevation": 17,
      "lat": 18.4480991364,
      "lon": -69.2811965942,
      "tz": "America\/Santo_Domingo"
  },
  "MDCR": {
      "icao": "MDCR",
      "iata": "CBJ",
      "name": "Cabo Rojo Airport",
      "city": "Cabo Rojo",
      "state": "Pedernales",
      "country": "DO",
      "elevation": 262,
      "lat": 17.9290008545,
      "lon": -71.6447982788,
      "tz": "America\/Santo_Domingo"
  },
  "MDCY": {
      "icao": "MDCY",
      "iata": "AZS",
      "name": "Samana El Catey International Airport",
      "city": "Samana",
      "state": "Maria-Trinidad-Sanchez",
      "country": "DO",
      "elevation": 30,
      "lat": 19.2670001984,
      "lon": -69.7419967651,
      "tz": "America\/Santo_Domingo"
  },
  "MDCZ": {
      "icao": "MDCZ",
      "iata": "COZ",
      "name": "Constanza Dom Re Airport",
      "city": "Costanza",
      "state": "La-Vega",
      "country": "DO",
      "elevation": 3950,
      "lat": 18.9074993134,
      "lon": -70.7219009399,
      "tz": "America\/Santo_Domingo"
  },
  "MDDJ": {
      "icao": "MDDJ",
      "iata": "",
      "name": "Dajabon Airport",
      "city": "Dajabon",
      "state": "Dajabon",
      "country": "DO",
      "elevation": 69,
      "lat": 19.5636005402,
      "lon": -71.6808013916,
      "tz": "America\/Port-au-Prince"
  },
  "MDER": {
      "icao": "MDER",
      "iata": "",
      "name": "El Ranchito Airport",
      "city": "La Vega",
      "state": "La-Vega",
      "country": "DO",
      "elevation": 240,
      "lat": 19.1848132376,
      "lon": -70.3649640083,
      "tz": "America\/Santo_Domingo"
  },
  "MDES": {
      "icao": "MDES",
      "iata": "",
      "name": "Penuela \/ Esperanza Field",
      "city": "Valverde",
      "state": "Valverde",
      "country": "DO",
      "elevation": 328,
      "lat": 19.5841122474,
      "lon": -70.95672369,
      "tz": "America\/Santo_Domingo"
  },
  "MDHN": {
      "icao": "MDHN",
      "iata": "",
      "name": "Juancho Enriquillo Airport",
      "city": "Barahona",
      "state": "Barahona",
      "country": "DO",
      "elevation": 115,
      "lat": 17.8725186193,
      "lon": -71.2687182426,
      "tz": "America\/Santo_Domingo"
  },
  "MDJB": {
      "icao": "MDJB",
      "iata": "JBQ",
      "name": "La Isabela International Airport",
      "city": "La Isabela",
      "state": "Santo-Domingo",
      "country": "DO",
      "elevation": 98,
      "lat": 18.5725002289,
      "lon": -69.9856033325,
      "tz": "America\/Santo_Domingo"
  },
  "MDJI": {
      "icao": "MDJI",
      "iata": "",
      "name": "Jimani Airport",
      "city": "",
      "state": "Independencia",
      "country": "DO",
      "elevation": 0,
      "lat": 18.49285,
      "lon": -71.869999,
      "tz": "America\/Port-au-Prince"
  },
  "MDLL": {
      "icao": "MDLL",
      "iata": "",
      "name": "Los Llanos de Sabanatosa Airport",
      "city": "",
      "state": "San-Pedro-de-Macoris",
      "country": "DO",
      "elevation": 262,
      "lat": 18.5964259778,
      "lon": -69.5257759094,
      "tz": "America\/Santo_Domingo"
  },
  "MDLM": {
      "icao": "MDLM",
      "iata": "",
      "name": "Los Montones Airport",
      "city": "San Cristobal",
      "state": "San-Cristobal",
      "country": "DO",
      "elevation": 305,
      "lat": 18.521233,
      "lon": -70.099132,
      "tz": "America\/Santo_Domingo"
  },
  "MDLR": {
      "icao": "MDLR",
      "iata": "LRM",
      "name": "Casa De Campo International Airport",
      "city": "La Romana",
      "state": "La-Romana",
      "country": "DO",
      "elevation": 240,
      "lat": 18.4507007599,
      "lon": -68.9117965698,
      "tz": "America\/Santo_Domingo"
  },
  "MDMA": {
      "icao": "MDMA",
      "iata": "",
      "name": "Magdalena Cuya Airport",
      "city": "La Romana",
      "state": "La-Altagracia",
      "country": "DO",
      "elevation": 325,
      "lat": 18.501933,
      "lon": -68.799099,
      "tz": "America\/Santo_Domingo"
  },
  "MDMC": {
      "icao": "MDMC",
      "iata": "",
      "name": "Monte Cristi Airport",
      "city": "Monte Cristi",
      "state": "Monte-Cristi",
      "country": "DO",
      "elevation": 121,
      "lat": 19.8652992249,
      "lon": -71.6453018188,
      "tz": "America\/Santo_Domingo"
  },
  "MDPC": {
      "icao": "MDPC",
      "iata": "PUJ",
      "name": "Punta Cana International Airport",
      "city": "Punta Cana",
      "state": "La-Altagracia",
      "country": "DO",
      "elevation": 47,
      "lat": 18.5673999786,
      "lon": -68.3634033203,
      "tz": "America\/Santo_Domingo"
  },
  "MDPM": {
      "icao": "MDPM",
      "iata": "",
      "name": "Piloto Airport",
      "city": "Monte Cristi",
      "state": "Monte-Cristi",
      "country": "DO",
      "elevation": 131,
      "lat": 19.5977273869,
      "lon": -71.205970645,
      "tz": "America\/Santo_Domingo"
  },
  "MDPO": {
      "icao": "MDPO",
      "iata": "EPS",
      "name": "Samana El Portillo Airport",
      "city": "Samana",
      "state": "Samana",
      "country": "DO",
      "elevation": 30,
      "lat": 19.3214150435,
      "lon": -69.4959121943,
      "tz": "America\/Santo_Domingo"
  },
  "MDPP": {
      "icao": "MDPP",
      "iata": "POP",
      "name": "Gregorio Luperon International Airport",
      "city": "Puerto Plata",
      "state": "Puerto-Plata",
      "country": "DO",
      "elevation": 15,
      "lat": 19.757900238,
      "lon": -70.5699996948,
      "tz": "America\/Santo_Domingo"
  },
  "MDSA": {
      "icao": "MDSA",
      "iata": "",
      "name": "San Juan De La Maguana Airport",
      "city": "San Juan de la Maguana",
      "state": "San-Juan",
      "country": "DO",
      "elevation": 1309,
      "lat": 18.8255004883,
      "lon": -71.2313995361,
      "tz": "America\/Santo_Domingo"
  },
  "MDSB": {
      "icao": "MDSB",
      "iata": "SNX",
      "name": "Sabana de Mar Airport",
      "city": "Sabana de Mar",
      "state": "Hato-Mayor",
      "country": "DO",
      "elevation": 15,
      "lat": 19.0618991852,
      "lon": -69.3852005005,
      "tz": "America\/Santo_Domingo"
  },
  "MDSD": {
      "icao": "MDSD",
      "iata": "SDQ",
      "name": "Las Americas International Airport",
      "city": "Santo Domingo",
      "state": "Nacional",
      "country": "DO",
      "elevation": 59,
      "lat": 18.4297008514,
      "lon": -69.6688995361,
      "tz": "America\/Santo_Domingo"
  },
  "MDSI": {
      "icao": "MDSI",
      "iata": "",
      "name": "San Isidro Air Base",
      "city": "San Isidro",
      "state": "Santo-Domingo",
      "country": "DO",
      "elevation": 111,
      "lat": 18.5037002563,
      "lon": -69.7617034912,
      "tz": "America\/Santo_Domingo"
  },
  "MDST": {
      "icao": "MDST",
      "iata": "STI",
      "name": "Cibao International Airport",
      "city": "Santiago",
      "state": "Dajabon",
      "country": "DO",
      "elevation": 565,
      "lat": 19.4060993195,
      "lon": -70.6046981812,
      "tz": "America\/Santo_Domingo"
  },
  "MDWO": {
      "icao": "MDWO",
      "iata": "",
      "name": "Walterio Airport",
      "city": "Monte Cristi",
      "state": "Monte-Cristi",
      "country": "DO",
      "elevation": 33,
      "lat": 19.757867,
      "lon": -71.623482,
      "tz": "America\/Santo_Domingo"
  },
  "ME00": {
      "icao": "ME00",
      "iata": "",
      "name": "Fort Fairfield Airport",
      "city": "Fort Fairfield",
      "state": "Maine",
      "country": "US",
      "elevation": 471,
      "lat": 46.765706,
      "lon": -67.847392,
      "tz": "America\/New_York"
  },
  "ME03": {
      "icao": "ME03",
      "iata": "",
      "name": "Webber Jones Airport",
      "city": "Brownville",
      "state": "Maine",
      "country": "US",
      "elevation": 400,
      "lat": 45.3362007141,
      "lon": -69.0509033203,
      "tz": "America\/New_York"
  },
  "ME05": {
      "icao": "ME05",
      "iata": "",
      "name": "Allen St Peter Memorial Airport",
      "city": "Portage",
      "state": "Maine",
      "country": "US",
      "elevation": 800,
      "lat": 46.75,
      "lon": -68.4711990356,
      "tz": "America\/New_York"
  },
  "ME06": {
      "icao": "ME06",
      "iata": "",
      "name": "Flying Ed Airport",
      "city": "Charlotte",
      "state": "Maine",
      "country": "US",
      "elevation": 440,
      "lat": 45.0279006958,
      "lon": -67.3094024658,
      "tz": "America\/New_York"
  },
  "ME08": {
      "icao": "ME08",
      "iata": "",
      "name": "Gadabout Gaddis Airport",
      "city": "Bingham",
      "state": "Maine",
      "country": "US",
      "elevation": 342,
      "lat": 45.0402984619,
      "lon": -69.8688964844,
      "tz": "America\/New_York"
  },
  "ME14": {
      "icao": "ME14",
      "iata": "",
      "name": "Bald Mountain Airport",
      "city": "Camden",
      "state": "Maine",
      "country": "US",
      "elevation": 570,
      "lat": 44.2305984497,
      "lon": -69.1231002808,
      "tz": "America\/New_York"
  },
  "ME16": {
      "icao": "ME16",
      "iata": "",
      "name": "Loring International Airport",
      "city": "Limestone",
      "state": "Maine",
      "country": "US",
      "elevation": 746,
      "lat": 46.9504013062,
      "lon": -67.8859024048,
      "tz": "America\/New_York"
  },
  "ME17": {
      "icao": "ME17",
      "iata": "",
      "name": "Thomas Airport",
      "city": "Roxbury",
      "state": "Maine",
      "country": "US",
      "elevation": 670,
      "lat": 44.6601982117,
      "lon": -70.5879974365,
      "tz": "America\/New_York"
  },
  "ME19": {
      "icao": "ME19",
      "iata": "",
      "name": "Clayton Lake Strip",
      "city": "Clayton Lake",
      "state": "Maine",
      "country": "US",
      "elevation": 1150,
      "lat": 46.6161003113,
      "lon": -69.5223007202,
      "tz": "America\/New_York"
  },
  "ME20": {
      "icao": "ME20",
      "iata": "",
      "name": "Bob-Mar Airport",
      "city": "Fairfield",
      "state": "Maine",
      "country": "US",
      "elevation": 310,
      "lat": 44.5992012024,
      "lon": -69.6239013672,
      "tz": "America\/New_York"
  },
  "ME22": {
      "icao": "ME22",
      "iata": "",
      "name": "Avery Field",
      "city": "Rockwood",
      "state": "Maine",
      "country": "US",
      "elevation": 1070,
      "lat": 45.6711997986,
      "lon": -69.8068008423,
      "tz": "America\/New_York"
  },
  "ME25": {
      "icao": "ME25",
      "iata": "",
      "name": "Sunrise Farms Airport",
      "city": "Fort Kent",
      "state": "Maine",
      "country": "US",
      "elevation": 640,
      "lat": 47.1660995483,
      "lon": -68.391998291,
      "tz": "America\/New_York"
  },
  "ME32": {
      "icao": "ME32",
      "iata": "",
      "name": "Morrel Field",
      "city": "Sangerville",
      "state": "Maine",
      "country": "US",
      "elevation": 500,
      "lat": 45.0903015137,
      "lon": -69.2586975098,
      "tz": "America\/New_York"
  },
  "ME33": {
      "icao": "ME33",
      "iata": "",
      "name": "Farr Field",
      "city": "Harpswell",
      "state": "Maine",
      "country": "US",
      "elevation": 70,
      "lat": 43.7787017822,
      "lon": -70.0130996704,
      "tz": "America\/New_York"
  },
  "ME34": {
      "icao": "ME34",
      "iata": "",
      "name": "Robinson Ridge Field",
      "city": "Jefferson",
      "state": "Maine",
      "country": "US",
      "elevation": 250,
      "lat": 44.1736984253,
      "lon": -69.4287033081,
      "tz": "America\/New_York"
  },
  "ME35": {
      "icao": "ME35",
      "iata": "",
      "name": "Terra B & B Airport",
      "city": "Levant",
      "state": "Maine",
      "country": "US",
      "elevation": 382,
      "lat": 44.9314994812,
      "lon": -69.0073013306,
      "tz": "America\/New_York"
  },
  "ME36": {
      "icao": "ME36",
      "iata": "",
      "name": "Mount Vernon Airport",
      "city": "Mount Vernon",
      "state": "Maine",
      "country": "US",
      "elevation": 478,
      "lat": 44.5092010498,
      "lon": -69.9525985718,
      "tz": "America\/New_York"
  },
  "ME38": {
      "icao": "ME38",
      "iata": "",
      "name": "Mars Hill Airport",
      "city": "Mars Hill",
      "state": "Maine",
      "country": "US",
      "elevation": 432,
      "lat": 46.5278015137,
      "lon": -67.87449646,
      "tz": "America\/New_York"
  },
  "ME39": {
      "icao": "ME39",
      "iata": "",
      "name": "Rocky Ridge Airport",
      "city": "Greene",
      "state": "Maine",
      "country": "US",
      "elevation": 480,
      "lat": 44.2354011536,
      "lon": -70.132598877,
      "tz": "America\/New_York"
  },
  "ME41": {
      "icao": "ME41",
      "iata": "",
      "name": "Witherspoons Airport",
      "city": "North Haven",
      "state": "Maine",
      "country": "US",
      "elevation": 80,
      "lat": 44.1525993347,
      "lon": -68.8764038086,
      "tz": "America\/New_York"
  },
  "ME44": {
      "icao": "ME44",
      "iata": "",
      "name": "Cummings Airport",
      "city": "Paris Hill",
      "state": "Maine",
      "country": "US",
      "elevation": 960,
      "lat": 44.2890014648,
      "lon": -70.4461975098,
      "tz": "America\/New_York"
  },
  "ME45": {
      "icao": "ME45",
      "iata": "",
      "name": "Goosefair Airport",
      "city": "Kennebunkport",
      "state": "Maine",
      "country": "US",
      "elevation": 40,
      "lat": 43.4056015015,
      "lon": -70.4322967529,
      "tz": "America\/New_York"
  },
  "ME46": {
      "icao": "ME46",
      "iata": "",
      "name": "Back Acres Airport",
      "city": "Kennebunkport",
      "state": "Maine",
      "country": "US",
      "elevation": 60,
      "lat": 43.4123001099,
      "lon": -70.4467010498,
      "tz": "America\/New_York"
  },
  "ME47": {
      "icao": "ME47",
      "iata": "",
      "name": "Payne Field",
      "city": "Hartland",
      "state": "Maine",
      "country": "US",
      "elevation": 350,
      "lat": 44.8544998169,
      "lon": -69.4563980103,
      "tz": "America\/New_York"
  },
  "ME55": {
      "icao": "ME55",
      "iata": "",
      "name": "Vinalhaven Airport",
      "city": "Vinalhaven",
      "state": "Maine",
      "country": "US",
      "elevation": 72,
      "lat": 44.0736999512,
      "lon": -68.818901062,
      "tz": "America\/New_York"
  },
  "ME56": {
      "icao": "ME56",
      "iata": "",
      "name": "Kimberly Airport",
      "city": "Waldoboro",
      "state": "Maine",
      "country": "US",
      "elevation": 255,
      "lat": 44.0691986084,
      "lon": -69.3052978516,
      "tz": "America\/New_York"
  },
  "ME60": {
      "icao": "ME60",
      "iata": "",
      "name": "Sunny Hill Airport",
      "city": "Belgrade",
      "state": "Maine",
      "country": "US",
      "elevation": 390,
      "lat": 44.4683990479,
      "lon": -69.8178024292,
      "tz": "America\/New_York"
  },
  "ME61": {
      "icao": "ME61",
      "iata": "",
      "name": "Salmon Falls Airport",
      "city": "Berwick",
      "state": "Maine",
      "country": "US",
      "elevation": 190,
      "lat": 43.2944984436,
      "lon": -70.8994979858,
      "tz": "America\/New_York"
  },
  "ME62": {
      "icao": "ME62",
      "iata": "",
      "name": "Grignons Private Lndg Area Airport",
      "city": "West Pittsfield",
      "state": "Maine",
      "country": "US",
      "elevation": 390,
      "lat": 44.8097991943,
      "lon": -69.4730987549,
      "tz": "America\/New_York"
  },
  "ME64": {
      "icao": "ME64",
      "iata": "",
      "name": "Skydive Lebanon Airport",
      "city": "West Lebanon",
      "state": "Maine",
      "country": "US",
      "elevation": 365,
      "lat": 43.375,
      "lon": -70.9291992188,
      "tz": "America\/New_York"
  },
  "ME67": {
      "icao": "ME67",
      "iata": "",
      "name": "Morrill Airpark",
      "city": "Van Buren",
      "state": "Maine",
      "country": "US",
      "elevation": 860,
      "lat": 47.1274986267,
      "lon": -67.9658966064,
      "tz": "America\/New_York"
  },
  "ME68": {
      "icao": "ME68",
      "iata": "",
      "name": "Newport Sky Park Airport",
      "city": "Newport",
      "state": "Maine",
      "country": "US",
      "elevation": 300,
      "lat": 44.820098877,
      "lon": -69.2384033203,
      "tz": "America\/New_York"
  },
  "ME69": {
      "icao": "ME69",
      "iata": "",
      "name": "Cliffords Airport",
      "city": "Sabattus",
      "state": "Maine",
      "country": "US",
      "elevation": 280,
      "lat": 44.0690002441,
      "lon": -70.0644989014,
      "tz": "America\/New_York"
  },
  "ME70": {
      "icao": "ME70",
      "iata": "",
      "name": "Greenfield Hill Airport",
      "city": "Cambridge",
      "state": "Maine",
      "country": "US",
      "elevation": 672,
      "lat": 45.0376014709,
      "lon": -69.4792022705,
      "tz": "America\/New_York"
  },
  "ME74": {
      "icao": "ME74",
      "iata": "",
      "name": "Miller's Field",
      "city": "Newburgh",
      "state": "Maine",
      "country": "US",
      "elevation": 160,
      "lat": 44.7495002747,
      "lon": -68.9813995361,
      "tz": "America\/New_York"
  },
  "ME75": {
      "icao": "ME75",
      "iata": "",
      "name": "Margerison Airport",
      "city": "Lisbon Falls",
      "state": "Maine",
      "country": "US",
      "elevation": 220,
      "lat": 43.9539985657,
      "lon": -70.1414031982,
      "tz": "America\/New_York"
  },
  "ME79": {
      "icao": "ME79",
      "iata": "",
      "name": "Two Falls Airport",
      "city": "Willimantic",
      "state": "Maine",
      "country": "US",
      "elevation": 366,
      "lat": 45.3055992126,
      "lon": -69.3467025757,
      "tz": "America\/New_York"
  },
  "ME88": {
      "icao": "ME88",
      "iata": "",
      "name": "Morrison's Airport",
      "city": "Perry",
      "state": "Maine",
      "country": "US",
      "elevation": 200,
      "lat": 44.9916992188,
      "lon": -67.0744018555,
      "tz": "America\/New_York"
  },
  "ME89": {
      "icao": "ME89",
      "iata": "",
      "name": "Buzzy's Field",
      "city": "Acton",
      "state": "Maine",
      "country": "US",
      "elevation": 650,
      "lat": 43.6083984375,
      "lon": -70.9681015015,
      "tz": "America\/New_York"
  },
  "ME91": {
      "icao": "ME91",
      "iata": "",
      "name": "Webster Field",
      "city": "Gorham",
      "state": "Maine",
      "country": "US",
      "elevation": 200,
      "lat": 43.6750984192,
      "lon": -70.4955978394,
      "tz": "America\/New_York"
  },
  "ME92": {
      "icao": "ME92",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Jay",
      "state": "Maine",
      "country": "US",
      "elevation": 600,
      "lat": 44.5167007446,
      "lon": -70.2222976685,
      "tz": "America\/New_York"
  },
  "ME94": {
      "icao": "ME94",
      "iata": "",
      "name": "Clark Field",
      "city": "Union",
      "state": "Maine",
      "country": "US",
      "elevation": 54,
      "lat": 44.2167015076,
      "lon": -69.2911987305,
      "tz": "America\/New_York"
  },
  "ME96": {
      "icao": "ME96",
      "iata": "",
      "name": "Clark Field",
      "city": "Gorham",
      "state": "Maine",
      "country": "US",
      "elevation": 250,
      "lat": 43.7336006165,
      "lon": -70.4863967896,
      "tz": "America\/New_York"
  },
  "MECT": {
      "icao": "MECT",
      "iata": "",
      "name": "Catsa Airport",
      "city": "Liberia",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 66,
      "lat": 10.5169439316,
      "lon": -85.5655593872,
      "tz": "America\/Costa_Rica"
  },
  "MG13": {
      "icao": "MG13",
      "iata": "",
      "name": "Inta Northeast Airport",
      "city": "El Achiotal",
      "state": "Alta-Verapaz",
      "country": "GT",
      "elevation": 510,
      "lat": 15.8042001724,
      "lon": -89.8507003784,
      "tz": "America\/Guatemala"
  },
  "MGBN": {
      "icao": "MGBN",
      "iata": "",
      "name": "Bananera Airport",
      "city": "Morales",
      "state": "Izabal",
      "country": "GT",
      "elevation": 130,
      "lat": 15.4735002518,
      "lon": -88.8371963501,
      "tz": "America\/Guatemala"
  },
  "MGCB": {
      "icao": "MGCB",
      "iata": "CBV",
      "name": "Coban Airport",
      "city": "Coban",
      "state": "Alta-Verapaz",
      "country": "GT",
      "elevation": 4339,
      "lat": 15.4689998627,
      "lon": -90.4067001343,
      "tz": "America\/Guatemala"
  },
  "MGCQ": {
      "icao": "MGCQ",
      "iata": "CIQ",
      "name": "Chiquimula Airport",
      "city": "Chiquimula",
      "state": "Chiquimula",
      "country": "GT",
      "elevation": 1484,
      "lat": 14.83092,
      "lon": -89.520938,
      "tz": "America\/Guatemala"
  },
  "MGCR": {
      "icao": "MGCR",
      "iata": "CMM",
      "name": "Carmelita Airport",
      "city": "Carmelita",
      "state": "Peten",
      "country": "GT",
      "elevation": 753,
      "lat": 17.4612007141,
      "lon": -90.0537033081,
      "tz": "America\/Guatemala"
  },
  "MGCT": {
      "icao": "MGCT",
      "iata": "CTF",
      "name": "Coatepeque Airport",
      "city": "Coatepeque",
      "state": "Totonicapan",
      "country": "GT",
      "elevation": 1486,
      "lat": 14.6941995621,
      "lon": -91.8824996948,
      "tz": "America\/Guatemala"
  },
  "MGDL": {
      "icao": "MGDL",
      "iata": "DON",
      "name": "Dos Lagunas Airport",
      "city": "Dos Lagunas",
      "state": "Peten",
      "country": "GT",
      "elevation": 1057,
      "lat": 17.6124000549,
      "lon": -89.6884002686,
      "tz": "America\/Guatemala"
  },
  "MGES": {
      "icao": "MGES",
      "iata": "",
      "name": "Esquipulas Airport",
      "city": "Esquipulas",
      "state": "Chiquimula",
      "country": "GT",
      "elevation": 3090,
      "lat": 14.5666704178,
      "lon": -89.3499984741,
      "tz": "America\/Guatemala"
  },
  "MGGT": {
      "icao": "MGGT",
      "iata": "GUA",
      "name": "La Aurora Airport",
      "city": "Guatemala City",
      "state": "Guatemala",
      "country": "GT",
      "elevation": 4952,
      "lat": 14.5832996368,
      "lon": -90.5274963379,
      "tz": "America\/Guatemala"
  },
  "MGHT": {
      "icao": "MGHT",
      "iata": "HUG",
      "name": "Huehuetenango Airport",
      "city": "Huehuetenango",
      "state": "Huehuetenango",
      "country": "GT",
      "elevation": 6375,
      "lat": 15.3274002075,
      "lon": -91.4624023438,
      "tz": "America\/Guatemala"
  },
  "MGLL": {
      "icao": "MGLL",
      "iata": "",
      "name": "La Libertad Airport",
      "city": "La Libertad",
      "state": "Peten",
      "country": "GT",
      "elevation": 623,
      "lat": 16.7502994537,
      "lon": -90.1399993896,
      "tz": "America\/Guatemala"
  },
  "MGML": {
      "icao": "MGML",
      "iata": "",
      "name": "Malacatan Airport",
      "city": "San Marcos",
      "state": "San-Marcos",
      "country": "GT",
      "elevation": 1194,
      "lat": 14.9074640274,
      "lon": -92.0879211426,
      "tz": "America\/Guatemala"
  },
  "MGMM": {
      "icao": "MGMM",
      "iata": "MCR",
      "name": "Melchor de Mencos Airport",
      "city": "Melchor de Mencos",
      "state": "Peten",
      "country": "GT",
      "elevation": 4670,
      "lat": 17.0686092377,
      "lon": -89.1522216797,
      "tz": "America\/Belize"
  },
  "MGPB": {
      "icao": "MGPB",
      "iata": "PBR",
      "name": "Puerto Barrios Airport",
      "city": "Puerto Barrios",
      "state": "Izabal",
      "country": "GT",
      "elevation": 33,
      "lat": 15.7308998108,
      "lon": -88.5838012695,
      "tz": "America\/Guatemala"
  },
  "MGPC": {
      "icao": "MGPC",
      "iata": "PCG",
      "name": "Paso Caballos Airport",
      "city": "Paso Caballos",
      "state": "Peten",
      "country": "GT",
      "elevation": 213,
      "lat": 17.2639007568,
      "lon": -90.2563018799,
      "tz": "America\/Guatemala"
  },
  "MGPG": {
      "icao": "MGPG",
      "iata": "PKJ",
      "name": "Playa Grande Airport",
      "city": "Playa Grande",
      "state": "Quiche",
      "country": "GT",
      "elevation": 577,
      "lat": 15.9975004196,
      "lon": -90.7416992188,
      "tz": "America\/Guatemala"
  },
  "MGPP": {
      "icao": "MGPP",
      "iata": "PON",
      "name": "Poptun Airport",
      "city": "Poptun",
      "state": "Peten",
      "country": "GT",
      "elevation": 1801,
      "lat": 16.325799942,
      "lon": -89.4160995483,
      "tz": "America\/Guatemala"
  },
  "MGQC": {
      "icao": "MGQC",
      "iata": "AQB",
      "name": "Santa Cruz del Quiche Airport",
      "city": "Santa Cruz del Quiche",
      "state": "Quiche",
      "country": "GT",
      "elevation": 6631,
      "lat": 15.0122003555,
      "lon": -91.1505966187,
      "tz": "America\/Guatemala"
  },
  "MGQZ": {
      "icao": "MGQZ",
      "iata": "AAZ",
      "name": "Quezaltenango Airport",
      "city": "Quezaltenango",
      "state": "Quetzaltenango",
      "country": "GT",
      "elevation": 7779,
      "lat": 14.8655996323,
      "lon": -91.5019989014,
      "tz": "America\/Guatemala"
  },
  "MGRA": {
      "icao": "MGRA",
      "iata": "",
      "name": "Rabinal Airport",
      "city": "Rabinal",
      "state": "Baja-Verapaz",
      "country": "GT",
      "elevation": 3180,
      "lat": 15.0935,
      "lon": -90.5063888889,
      "tz": "America\/Guatemala"
  },
  "MGRB": {
      "icao": "MGRB",
      "iata": "RUV",
      "name": "Rubelsanto Airport",
      "city": "Rubelsanto",
      "state": "Alta-Verapaz",
      "country": "GT",
      "elevation": 426,
      "lat": 15.9919996262,
      "lon": -90.4452972412,
      "tz": "America\/Guatemala"
  },
  "MGRD": {
      "icao": "MGRD",
      "iata": "LCF",
      "name": "Las Vegas Airport",
      "city": "Rio Dulce",
      "state": "Izabal",
      "country": "GT",
      "elevation": 28,
      "lat": 15.6688995361,
      "lon": -88.957901001,
      "tz": "America\/Guatemala"
  },
  "MGRT": {
      "icao": "MGRT",
      "iata": "RER",
      "name": "Retalhuleu Airport",
      "city": "Retalhuleu",
      "state": "Retalhuleu",
      "country": "GT",
      "elevation": 656,
      "lat": 14.5209999084,
      "lon": -91.697303772,
      "tz": "America\/Guatemala"
  },
  "MGSJ": {
      "icao": "MGSJ",
      "iata": "GSJ",
      "name": "San Jose Airport",
      "city": "Puerto San Jose",
      "state": "Escuintla",
      "country": "GT",
      "elevation": 29,
      "lat": 13.9362001419,
      "lon": -90.8358001709,
      "tz": "America\/Guatemala"
  },
  "MGSM": {
      "icao": "MGSM",
      "iata": "",
      "name": "San Marcos Airport",
      "city": "San Marcos",
      "state": "San-Marcos",
      "country": "GT",
      "elevation": 7933,
      "lat": 14.9562997818,
      "lon": -91.8062973022,
      "tz": "America\/Guatemala"
  },
  "MGTK": {
      "icao": "MGTK",
      "iata": "FRS",
      "name": "Mundo Maya International Airport",
      "city": "San Benito",
      "state": "Peten",
      "country": "GT",
      "elevation": 427,
      "lat": 16.9137992859,
      "lon": -89.8664016724,
      "tz": "America\/Guatemala"
  },
  "MGUX": {
      "icao": "MGUX",
      "iata": "UAX",
      "name": "Uaxactun Airport",
      "city": "Uaxactun",
      "state": "Peten",
      "country": "GT",
      "elevation": 573,
      "lat": 17.3938884735,
      "lon": -89.6327438354,
      "tz": "America\/Guatemala"
  },
  "MGZA": {
      "icao": "MGZA",
      "iata": "",
      "name": "Zacapa Airport",
      "city": "Zacapa",
      "state": "Zacapa",
      "country": "GT",
      "elevation": 626,
      "lat": 14.9603004456,
      "lon": -89.5391998291,
      "tz": "America\/Guatemala"
  },
  "MHAC": {
      "icao": "MHAC",
      "iata": "",
      "name": "Acensa Airport",
      "city": "Acensa",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 42,
      "lat": 13.2436113358,
      "lon": -87.3413925171,
      "tz": "America\/Tegucigalpa"
  },
  "MHAG": {
      "icao": "MHAG",
      "iata": "",
      "name": "Sur Agropecuaria Airport",
      "city": "Buena Vista",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 12,
      "lat": 13.25,
      "lon": -87.3580551147,
      "tz": "America\/Tegucigalpa"
  },
  "MHAH": {
      "icao": "MHAH",
      "iata": "AHS",
      "name": "Ahuas Airport",
      "city": "Ahuas",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 98,
      "lat": 15.4722003937,
      "lon": -84.3522033691,
      "tz": "America\/Tegucigalpa"
  },
  "MHAL": {
      "icao": "MHAL",
      "iata": "",
      "name": "El Alto Airport",
      "city": "",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 760,
      "lat": 14.4933328629,
      "lon": -87.1063919067,
      "tz": "America\/Tegucigalpa"
  },
  "MHAM": {
      "icao": "MHAM",
      "iata": "",
      "name": "Amapala Airport",
      "city": "Amapala Island",
      "state": "Valle",
      "country": "HN",
      "elevation": 98,
      "lat": 13.2683000565,
      "lon": -87.6603012085,
      "tz": "America\/El_Salvador"
  },
  "MHAN": {
      "icao": "MHAN",
      "iata": "",
      "name": "San Luis Airport",
      "city": "San Luis",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 690,
      "lat": 14.7458333969,
      "lon": -87.4052810669,
      "tz": "America\/Tegucigalpa"
  },
  "MHAP": {
      "icao": "MHAP",
      "iata": "",
      "name": "Apala Airport",
      "city": "Apala",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 470,
      "lat": 14.0108327866,
      "lon": -86.3891677856,
      "tz": "America\/Tegucigalpa"
  },
  "MHAR": {
      "icao": "MHAR",
      "iata": "",
      "name": "Archaga Airport",
      "city": "Archaga",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 875,
      "lat": 14.2852783203,
      "lon": -87.2291641235,
      "tz": "America\/Tegucigalpa"
  },
  "MHAS": {
      "icao": "MHAS",
      "iata": "",
      "name": "Alas Del Socorro Airport",
      "city": "Siguatepeque",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 1068,
      "lat": 14.5930557251,
      "lon": -87.8405532837,
      "tz": "America\/Tegucigalpa"
  },
  "MHAU": {
      "icao": "MHAU",
      "iata": "",
      "name": "Hacienda Ulua Airport",
      "city": "Tasajeras",
      "state": "Olancho",
      "country": "HN",
      "elevation": 710,
      "lat": 14.7177782059,
      "lon": -86.5708312988,
      "tz": "America\/Tegucigalpa"
  },
  "MHAY": {
      "icao": "MHAY",
      "iata": "",
      "name": "Araslaya Airport",
      "city": "Araslaya",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 3,
      "lat": 15.7352781296,
      "lon": -84.5433349609,
      "tz": "America\/Tegucigalpa"
  },
  "MHAZ": {
      "icao": "MHAZ",
      "iata": "",
      "name": "Aserradero Azacualpa Airport",
      "city": "Cacao",
      "state": "Olancho",
      "country": "HN",
      "elevation": 370,
      "lat": 14.4333333969,
      "lon": -86.0891647339,
      "tz": "America\/Tegucigalpa"
  },
  "MHBA": {
      "icao": "MHBA",
      "iata": "",
      "name": "Barbareta Airport",
      "city": "Barbareta Island",
      "state": "Bay-Islands",
      "country": "HN",
      "elevation": 7,
      "lat": 16.4330558777,
      "lon": -86.1394424438,
      "tz": "America\/Tegucigalpa"
  },
  "MHBE": {
      "icao": "MHBE",
      "iata": "",
      "name": "San Bernardo Airport",
      "city": "San Bernardo",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 14,
      "lat": 13.1019439697,
      "lon": -87.1419448853,
      "tz": "America\/Tegucigalpa"
  },
  "MHBL": {
      "icao": "MHBL",
      "iata": "BHG",
      "name": "Brus Laguna Airport",
      "city": "Brus Laguna",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 2,
      "lat": 15.7630996704,
      "lon": -84.5436019897,
      "tz": "America\/Tegucigalpa"
  },
  "MHCA": {
      "icao": "MHCA",
      "iata": "CAA",
      "name": "Catacamas Airport",
      "city": "Catacamas",
      "state": "Olancho",
      "country": "HN",
      "elevation": 1489,
      "lat": 14.9169998169,
      "lon": -85.9000015259,
      "tz": "America\/Tegucigalpa"
  },
  "MHCB": {
      "icao": "MHCB",
      "iata": "",
      "name": "Cocobila Airport",
      "city": "Cocobila",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 2,
      "lat": 15.8883333206,
      "lon": -84.7555541992,
      "tz": "America\/Tegucigalpa"
  },
  "MHCC": {
      "icao": "MHCC",
      "iata": "",
      "name": "Concepcion Airport",
      "city": "Concepcion",
      "state": "Intibuca",
      "country": "HN",
      "elevation": 560,
      "lat": 14.0266666412,
      "lon": -88.3441696167,
      "tz": "America\/Tegucigalpa"
  },
  "MHCG": {
      "icao": "MHCG",
      "iata": "",
      "name": "Shaoguan Airport",
      "city": "Comayagua",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 1980,
      "lat": 14.432299614,
      "lon": -87.6246032715,
      "tz": "America\/Tegucigalpa"
  },
  "MHCI": {
      "icao": "MHCI",
      "iata": "",
      "name": "Chiquerito Airport",
      "city": "Chiquerito",
      "state": "Colon",
      "country": "HN",
      "elevation": 30,
      "lat": 15.8319444656,
      "lon": -85.0269470215,
      "tz": "America\/Tegucigalpa"
  },
  "MHCL": {
      "icao": "MHCL",
      "iata": "",
      "name": "Colon Airport",
      "city": "Marcala",
      "state": "La-Paz",
      "country": "HN",
      "elevation": 1240,
      "lat": 14.1619443893,
      "lon": -88.0344467163,
      "tz": "America\/Tegucigalpa"
  },
  "MHCM": {
      "icao": "MHCM",
      "iata": "",
      "name": "Choloma Airport",
      "city": "Choloma",
      "state": "Santa-Barbara",
      "country": "HN",
      "elevation": 1100,
      "lat": 14.8883333206,
      "lon": -88.4044418335,
      "tz": "America\/Tegucigalpa"
  },
  "MHCO": {
      "icao": "MHCO",
      "iata": "",
      "name": "Coco Airport",
      "city": "",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 61,
      "lat": 15.2527780533,
      "lon": -84.2333297729,
      "tz": "America\/Tegucigalpa"
  },
  "MHCP": {
      "icao": "MHCP",
      "iata": "",
      "name": "Cochino Pequeno Airport",
      "city": "Cochino Pequeno Island",
      "state": "Bay-Islands",
      "country": "HN",
      "elevation": 1,
      "lat": 15.9544439316,
      "lon": -86.4986114502,
      "tz": "America\/Tegucigalpa"
  },
  "MHCR": {
      "icao": "MHCR",
      "iata": "",
      "name": "Carta Airport",
      "city": "La Union",
      "state": "Olancho",
      "country": "HN",
      "elevation": 800,
      "lat": 15.0293998718,
      "lon": -86.6923980713,
      "tz": "America\/Tegucigalpa"
  },
  "MHCS": {
      "icao": "MHCS",
      "iata": "CYL",
      "name": "Coyoles Airport",
      "city": "Coyoles",
      "state": "Yoro",
      "country": "HN",
      "elevation": 150,
      "lat": 15.445555687,
      "lon": -86.67527771,
      "tz": "America\/Tegucigalpa"
  },
  "MHCU": {
      "icao": "MHCU",
      "iata": "CDD",
      "name": "Cauquira Airport",
      "city": "Cauquira",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 21,
      "lat": 15.3166666031,
      "lon": -83.5916671753,
      "tz": "America\/Tegucigalpa"
  },
  "MHDU": {
      "icao": "MHDU",
      "iata": "",
      "name": "Durzona Airport",
      "city": "Mocoron",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 250,
      "lat": 14.9891004562,
      "lon": -84.2208023071,
      "tz": "America\/Tegucigalpa"
  },
  "MHEA": {
      "icao": "MHEA",
      "iata": "OAN",
      "name": "El Arrayan Airport",
      "city": "Olanchito",
      "state": "Yoro",
      "country": "HN",
      "elevation": 220,
      "lat": 15.5055561066,
      "lon": -86.57472229,
      "tz": "America\/Tegucigalpa"
  },
  "MHEC": {
      "icao": "MHEC",
      "iata": "",
      "name": "El Cubo Airport",
      "city": "",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 780,
      "lat": 14.457777977,
      "lon": -87.0586090088,
      "tz": "America\/Tegucigalpa"
  },
  "MHEI": {
      "icao": "MHEI",
      "iata": "",
      "name": "La America Airport",
      "city": "El Guanacaste",
      "state": "Yoro",
      "country": "HN",
      "elevation": 53,
      "lat": 15.6486110687,
      "lon": -87.7883300781,
      "tz": "America\/Tegucigalpa"
  },
  "MHEL": {
      "icao": "MHEL",
      "iata": "",
      "name": "Barrio El Aterrizaje Airport",
      "city": "El Paraiso",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 760,
      "lat": 13.8730564117,
      "lon": -86.5605545044,
      "tz": "America\/Tegucigalpa"
  },
  "MHEN": {
      "icao": "MHEN",
      "iata": "",
      "name": "Aserradero El Encino Airport",
      "city": "La Canoa",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 970,
      "lat": 14.6619443893,
      "lon": -86.9150009155,
      "tz": "America\/Tegucigalpa"
  },
  "MHEZ": {
      "icao": "MHEZ",
      "iata": "",
      "name": "La Esperanza Airport",
      "city": "Lerida",
      "state": "Colon",
      "country": "HN",
      "elevation": 60,
      "lat": 15.6097221375,
      "lon": -86.1394424438,
      "tz": "America\/Tegucigalpa"
  },
  "MHFA": {
      "icao": "MHFA",
      "iata": "",
      "name": "Finca San Antonio Airport",
      "city": "La Lima",
      "state": "Olancho",
      "country": "HN",
      "elevation": 646,
      "lat": 14.5980558395,
      "lon": -86.4838867188,
      "tz": "America\/Tegucigalpa"
  },
  "MHFC": {
      "icao": "MHFC",
      "iata": "",
      "name": "Fort Cay Airport",
      "city": "Fort Cay",
      "state": "Bay-Islands",
      "country": "HN",
      "elevation": 5,
      "lat": 16.403055191,
      "lon": -86.2844467163,
      "tz": "America\/Tegucigalpa"
  },
  "MHFD": {
      "icao": "MHFD",
      "iata": "",
      "name": "Finca 12 Airport",
      "city": "Agua Blanca",
      "state": "Yoro",
      "country": "HN",
      "elevation": 116,
      "lat": 15.2697219849,
      "lon": -87.9027786255,
      "tz": "America\/Tegucigalpa"
  },
  "MHFN": {
      "icao": "MHFN",
      "iata": "",
      "name": "San Fernando Airport",
      "city": "San Fernando",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 648,
      "lat": 14.1133327484,
      "lon": -86.9527816772,
      "tz": "America\/Tegucigalpa"
  },
  "MHGA": {
      "icao": "MHGA",
      "iata": "",
      "name": "Chumbagua Airport",
      "city": "Chumbagua",
      "state": "Santa-Barbara",
      "country": "HN",
      "elevation": 300,
      "lat": 15.1766672134,
      "lon": -88.4899978638,
      "tz": "America\/Tegucigalpa"
  },
  "MHGB": {
      "icao": "MHGB",
      "iata": "",
      "name": "Guayabillas Airport",
      "city": "Esquias",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 700,
      "lat": 14.7466669083,
      "lon": -87.3752746582,
      "tz": "America\/Tegucigalpa"
  },
  "MHGC": {
      "icao": "MHGC",
      "iata": "",
      "name": "Grupo Carnol Airport",
      "city": "Catacamas",
      "state": "Olancho",
      "country": "HN",
      "elevation": 378,
      "lat": 14.8386106491,
      "lon": -85.8683319092,
      "tz": "America\/Tegucigalpa"
  },
  "MHGE": {
      "icao": "MHGE",
      "iata": "",
      "name": "El Aguacate Airport",
      "city": "El Aguacate",
      "state": "Olancho",
      "country": "HN",
      "elevation": 384,
      "lat": 14.875,
      "lon": -85.7761077881,
      "tz": "America\/Tegucigalpa"
  },
  "MHGG": {
      "icao": "MHGG",
      "iata": "",
      "name": "Santiago De Puringla Airport",
      "city": "Santiago de Puringla",
      "state": "Intibuca",
      "country": "HN",
      "elevation": 960,
      "lat": 14.3675003052,
      "lon": -87.9113922119,
      "tz": "America\/Tegucigalpa"
  },
  "MHGR": {
      "icao": "MHGR",
      "iata": "",
      "name": "Sangrelaya Airport",
      "city": "Donel",
      "state": "Colon",
      "country": "HN",
      "elevation": 3,
      "lat": 15.9699001312,
      "lon": -85.0892028809,
      "tz": "America\/Tegucigalpa"
  },
  "MHGS": {
      "icao": "MHGS",
      "iata": "GAC",
      "name": "Gracias Airport",
      "city": "El Molino",
      "state": "Lempira",
      "country": "HN",
      "elevation": 828,
      "lat": 14.5966672897,
      "lon": -88.5941696167,
      "tz": "America\/Tegucigalpa"
  },
  "MHGY": {
      "icao": "MHGY",
      "iata": "",
      "name": "Guayape Airport",
      "city": "El Zapato",
      "state": "Olancho",
      "country": "HN",
      "elevation": 765,
      "lat": 14.7847223282,
      "lon": -86.8633346558,
      "tz": "America\/Tegucigalpa"
  },
  "MHHE": {
      "icao": "MHHE",
      "iata": "",
      "name": "La Herradura Airport",
      "city": "Punuare",
      "state": "Olancho",
      "country": "HN",
      "elevation": 342,
      "lat": 14.7550001144,
      "lon": -86.0041656494,
      "tz": "America\/Tegucigalpa"
  },
  "MHHG": {
      "icao": "MHHG",
      "iata": "",
      "name": "Hacienda Galeras Airport",
      "city": "La Lima",
      "state": "Olancho",
      "country": "HN",
      "elevation": 618,
      "lat": 14.5988893509,
      "lon": -86.4608306885,
      "tz": "America\/Tegucigalpa"
  },
  "MHHO": {
      "icao": "MHHO",
      "iata": "",
      "name": "Agua Caliente Airport",
      "city": "Horcones",
      "state": "Yoro",
      "country": "HN",
      "elevation": 250,
      "lat": 15.427778244,
      "lon": -86.8949966431,
      "tz": "America\/Tegucigalpa"
  },
  "MHIC": {
      "icao": "MHIC",
      "iata": "",
      "name": "Islas Del Cisne Airport",
      "city": "Del Cisne Island",
      "state": "Bay-Islands",
      "country": "HN",
      "elevation": 7,
      "lat": 17.4073009491,
      "lon": -83.9327011108,
      "tz": "America\/Tegucigalpa"
  },
  "MHIN": {
      "icao": "MHIN",
      "iata": "",
      "name": "Minas De Oro Airport",
      "city": "Minas de Oro",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 1000,
      "lat": 14.788611412,
      "lon": -87.3497238159,
      "tz": "America\/Tegucigalpa"
  },
  "MHIR": {
      "icao": "MHIR",
      "iata": "IRN",
      "name": "Iriona Airport",
      "city": "Iriona",
      "state": "Colon",
      "country": "HN",
      "elevation": 8,
      "lat": 15.9391670227,
      "lon": -85.13722229,
      "tz": "America\/Tegucigalpa"
  },
  "MHIZ": {
      "icao": "MHIZ",
      "iata": "",
      "name": "Izapan Airport",
      "city": "La Puerta",
      "state": "Colon",
      "country": "HN",
      "elevation": 8,
      "lat": 15.9102783203,
      "lon": -85.1711120605,
      "tz": "America\/Tegucigalpa"
  },
  "MHJC": {
      "icao": "MHJC",
      "iata": "",
      "name": "Jocon Airport",
      "city": "Jocon",
      "state": "Yoro",
      "country": "HN",
      "elevation": 490,
      "lat": 15.290555954,
      "lon": -86.9122238159,
      "tz": "America\/Tegucigalpa"
  },
  "MHJE": {
      "icao": "MHJE",
      "iata": "",
      "name": "Nueva Jerusalen Airport",
      "city": "Rio Platano",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 5,
      "lat": 15.8780555725,
      "lon": -84.7263870239,
      "tz": "America\/Tegucigalpa"
  },
  "MHJI": {
      "icao": "MHJI",
      "iata": "",
      "name": "Jicalapa Airport",
      "city": "Jicalapa",
      "state": "Olancho",
      "country": "HN",
      "elevation": 650,
      "lat": 15.006111145,
      "lon": -86.0491638184,
      "tz": "America\/Tegucigalpa"
  },
  "MHJO": {
      "icao": "MHJO",
      "iata": "",
      "name": "La Joya Airport",
      "city": "",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 780,
      "lat": 14.8047218323,
      "lon": -86.9191665649,
      "tz": "America\/Tegucigalpa"
  },
  "MHJQ": {
      "icao": "MHJQ",
      "iata": "",
      "name": "Joya Del Quebracho Airport",
      "city": "Caleras\/El Triunfo",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 890,
      "lat": 14.838889122,
      "lon": -86.9622192383,
      "tz": "America\/Tegucigalpa"
  },
  "MHJU": {
      "icao": "MHJU",
      "iata": "JUT",
      "name": "Jutigalpa airport",
      "city": "Jutigalpa",
      "state": "Olancho",
      "country": "HN",
      "elevation": 1314,
      "lat": 14.6526002884,
      "lon": -86.2202987671,
      "tz": "America\/Tegucigalpa"
  },
  "MHLA": {
      "icao": "MHLA",
      "iata": "",
      "name": "La Alondra Airport",
      "city": "Candelaria",
      "state": "Lempira",
      "country": "HN",
      "elevation": 1000,
      "lat": 14.1088886261,
      "lon": -88.5338897705,
      "tz": "America\/Tegucigalpa"
  },
  "MHLC": {
      "icao": "MHLC",
      "iata": "LCE",
      "name": "Goloson International Airport",
      "city": "La Ceiba",
      "state": "Atlantida",
      "country": "HN",
      "elevation": 49,
      "lat": 15.7425003052,
      "lon": -86.8529968262,
      "tz": "America\/Tegucigalpa"
  },
  "MHLE": {
      "icao": "MHLE",
      "iata": "LEZ",
      "name": "La Esperanza Airport",
      "city": "La Esperanza",
      "state": "Intibuca",
      "country": "HN",
      "elevation": 5475,
      "lat": 14.2911109924,
      "lon": -88.1750030518,
      "tz": "America\/Tegucigalpa"
  },
  "MHLF": {
      "icao": "MHLF",
      "iata": "",
      "name": "Flefil Airport",
      "city": "",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 810,
      "lat": 14.423333168,
      "lon": -87.119720459,
      "tz": "America\/Tegucigalpa"
  },
  "MHLG": {
      "icao": "MHLG",
      "iata": "",
      "name": "La Grecia Airport",
      "city": "Monjaras",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 29,
      "lat": 13.2147216797,
      "lon": -87.363609314,
      "tz": "America\/Tegucigalpa"
  },
  "MHLI": {
      "icao": "MHLI",
      "iata": "",
      "name": "Talanguita Airport",
      "city": "",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 760,
      "lat": 14.4875001907,
      "lon": -87.179725647,
      "tz": "America\/Tegucigalpa"
  },
  "MHLJ": {
      "icao": "MHLJ",
      "iata": "",
      "name": "Las Lajas Airport",
      "city": "Coyoles",
      "state": "Olancho",
      "country": "HN",
      "elevation": 1050,
      "lat": 14.8947219849,
      "lon": -86.57472229,
      "tz": "America\/Tegucigalpa"
  },
  "MHLM": {
      "icao": "MHLM",
      "iata": "SAP",
      "name": "Ramon Villeda Morales International Airport",
      "city": "La Mesa",
      "state": "Cortes",
      "country": "HN",
      "elevation": 91,
      "lat": 15.4525995255,
      "lon": -87.9235992432,
      "tz": "America\/Tegucigalpa"
  },
  "MHLN": {
      "icao": "MHLN",
      "iata": "",
      "name": "Limon Airport",
      "city": "Limon",
      "state": "Colon",
      "country": "HN",
      "elevation": 3,
      "lat": 15.8633327484,
      "lon": -85.4986114502,
      "tz": "America\/Tegucigalpa"
  },
  "MHLP": {
      "icao": "MHLP",
      "iata": "",
      "name": "Mapulaca Airport",
      "city": "Mapulaca",
      "state": "Lempira",
      "country": "HN",
      "elevation": 170,
      "lat": 14.0341672897,
      "lon": -88.6283340454,
      "tz": "America\/El_Salvador"
  },
  "MHLS": {
      "icao": "MHLS",
      "iata": "",
      "name": "Laguna Seca Airport",
      "city": "Laguna Seca",
      "state": "Olancho",
      "country": "HN",
      "elevation": 353,
      "lat": 14.6447000504,
      "lon": -86.0698013306,
      "tz": "America\/Tegucigalpa"
  },
  "MHLT": {
      "icao": "MHLT",
      "iata": "",
      "name": "Llanos Del Tigre Airport",
      "city": "Moroceli",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 625,
      "lat": 14.076666832,
      "lon": -86.8416671753,
      "tz": "America\/Tegucigalpa"
  },
  "MHLU": {
      "icao": "MHLU",
      "iata": "",
      "name": "Luz Y Vida Airport",
      "city": "San Luis Pajon",
      "state": "Santa-Barbara",
      "country": "HN",
      "elevation": 764,
      "lat": 15.1463890076,
      "lon": -88.444442749,
      "tz": "America\/Tegucigalpa"
  },
  "MHLV": {
      "icao": "MHLV",
      "iata": "",
      "name": "Las Vegas Airport",
      "city": "Las Vegas",
      "state": "Santa-Barbara",
      "country": "HN",
      "elevation": 870,
      "lat": 14.8736114502,
      "lon": -88.0744476318,
      "tz": "America\/Tegucigalpa"
  },
  "MHLZ": {
      "icao": "MHLZ",
      "iata": "",
      "name": "La Estanzuela Airport",
      "city": "el agua",
      "state": "Copan",
      "country": "HN",
      "elevation": 2227,
      "lat": 15.016667366,
      "lon": -88.8000030518,
      "tz": "America\/Tegucigalpa"
  },
  "MHMI": {
      "icao": "MHMI",
      "iata": "",
      "name": "Las Minitas Airport",
      "city": "Vallecillo",
      "state": "Yoro",
      "country": "HN",
      "elevation": 2500,
      "lat": 15.0511112213,
      "lon": -87.2466659546,
      "tz": "America\/Tegucigalpa"
  },
  "MHML": {
      "icao": "MHML",
      "iata": "",
      "name": "Monte Libano Airport",
      "city": "Monte Libano",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 46,
      "lat": 13.179444313,
      "lon": -87.1855545044,
      "tz": "America\/Tegucigalpa"
  },
  "MHMM": {
      "icao": "MHMM",
      "iata": "",
      "name": "San Antonio Malera Airport",
      "city": "Malera",
      "state": "Santa-Barbara",
      "country": "HN",
      "elevation": 670,
      "lat": 14.6719436646,
      "lon": -88.2549972534,
      "tz": "America\/Tegucigalpa"
  },
  "MHMN": {
      "icao": "MHMN",
      "iata": "",
      "name": "Monica Airport",
      "city": "Monica",
      "state": "Atlantida",
      "country": "HN",
      "elevation": 59,
      "lat": 15.6529998779,
      "lon": -87.0876998901,
      "tz": "America\/Tegucigalpa"
  },
  "MHMO": {
      "icao": "MHMO",
      "iata": "",
      "name": "San Marcos De Ocotepeque Airport",
      "city": "San Marcos",
      "state": "Ocotepeque",
      "country": "HN",
      "elevation": 961,
      "lat": 14.4130563736,
      "lon": -88.9519424438,
      "tz": "America\/Tegucigalpa"
  },
  "MHMP": {
      "icao": "MHMP",
      "iata": "",
      "name": "Marcos Perez Airport",
      "city": "Agua Blanca",
      "state": "Yoro",
      "country": "HN",
      "elevation": 115,
      "lat": 15.286110878,
      "lon": -87.8852767944,
      "tz": "America\/Tegucigalpa"
  },
  "MHMS": {
      "icao": "MHMS",
      "iata": "",
      "name": "Las Marias Airport",
      "city": "Siksatara",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 30,
      "lat": 15.6727781296,
      "lon": -84.8447189331,
      "tz": "America\/Tegucigalpa"
  },
  "MHMT": {
      "icao": "MHMT",
      "iata": "",
      "name": "Manto Airport",
      "city": "Manto",
      "state": "Olancho",
      "country": "HN",
      "elevation": 650,
      "lat": 14.915555954,
      "lon": -86.3816680908,
      "tz": "America\/Tegucigalpa"
  },
  "MHNA": {
      "icao": "MHNA",
      "iata": "",
      "name": "Ciudad Nacaome Airport",
      "city": "Nacaome",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 35,
      "lat": 13.5294437408,
      "lon": -87.314163208,
      "tz": "America\/Tegucigalpa"
  },
  "MHNB": {
      "icao": "MHNB",
      "iata": "",
      "name": "Noveno Batallon Airport",
      "city": "Apala",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 426,
      "lat": 14.043299675,
      "lon": -86.4207992554,
      "tz": "America\/Tegucigalpa"
  },
  "MHNC": {
      "icao": "MHNC",
      "iata": "",
      "name": "Nueva Choluteca Airport",
      "city": "Choluteca",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 52,
      "lat": 13.3233327866,
      "lon": -87.1425018311,
      "tz": "America\/Tegucigalpa"
  },
  "MHNJ": {
      "icao": "MHNJ",
      "iata": "GJA",
      "name": "La Laguna Airport",
      "city": "Guanaja",
      "state": "Bay-Islands",
      "country": "HN",
      "elevation": 49,
      "lat": 16.445400238,
      "lon": -85.9066009521,
      "tz": "America\/Tegucigalpa"
  },
  "MHNS": {
      "icao": "MHNS",
      "iata": "",
      "name": "Los Llanos Airport",
      "city": "",
      "state": "La-Paz",
      "country": "HN",
      "elevation": 1180,
      "lat": 14.2105560303,
      "lon": -88.0216674805,
      "tz": "America\/Tegucigalpa"
  },
  "MHNV": {
      "icao": "MHNV",
      "iata": "",
      "name": "Nueva Ocotepeque Airport",
      "city": "Nueva Ocotepeque",
      "state": "Ocotepeque",
      "country": "HN",
      "elevation": 850,
      "lat": 14.4288892746,
      "lon": -89.1938858032,
      "tz": "America\/Tegucigalpa"
  },
  "MHOT": {
      "icao": "MHOT",
      "iata": "",
      "name": "Ocotales Airport",
      "city": "Copon",
      "state": "Colon",
      "country": "HN",
      "elevation": 130,
      "lat": 15.6691665649,
      "lon": -85.194442749,
      "tz": "America\/Tegucigalpa"
  },
  "MHPA": {
      "icao": "MHPA",
      "iata": "",
      "name": "Barra Del Patuca Airport",
      "city": "Barra del Patuca",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 4,
      "lat": 15.8013887405,
      "lon": -84.2966690063,
      "tz": "America\/Tegucigalpa"
  },
  "MHPC": {
      "icao": "MHPC",
      "iata": "PCH",
      "name": "Palacios Airport",
      "city": "Palacios",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 30,
      "lat": 15.9549999237,
      "lon": -84.9413909912,
      "tz": "America\/Tegucigalpa"
  },
  "MHPI": {
      "icao": "MHPI",
      "iata": "",
      "name": "Agropecuaria Piedra De Agua Azul Airport",
      "city": "Buena Vista",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 56,
      "lat": 13.2961111069,
      "lon": -87.3455581665,
      "tz": "America\/Tegucigalpa"
  },
  "MHPL": {
      "icao": "MHPL",
      "iata": "PEU",
      "name": "Puerto Lempira Airport",
      "city": "Puerto Lempira",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 25,
      "lat": 15.2622003555,
      "lon": -83.7811965942,
      "tz": "America\/Tegucigalpa"
  },
  "MHPS": {
      "icao": "MHPS",
      "iata": "",
      "name": "Desvio Potrerillos Airport",
      "city": "El Encinal",
      "state": "Olancho",
      "country": "HN",
      "elevation": 395,
      "lat": 14.5958328247,
      "lon": -86.1500015259,
      "tz": "America\/Tegucigalpa"
  },
  "MHPV": {
      "icao": "MHPV",
      "iata": "",
      "name": "El Porvenir Airport",
      "city": "Manga",
      "state": "Colon",
      "country": "HN",
      "elevation": 69,
      "lat": 15.5302782059,
      "lon": -86.2736129761,
      "tz": "America\/Tegucigalpa"
  },
  "MHPY": {
      "icao": "MHPY",
      "iata": "",
      "name": "Payasito Airport",
      "city": "Farallones",
      "state": "Colon",
      "country": "HN",
      "elevation": 5,
      "lat": 15.8758325577,
      "lon": -85.3969421387,
      "tz": "America\/Tegucigalpa"
  },
  "MHRA": {
      "icao": "MHRA",
      "iata": "",
      "name": "Rapaco II Airport",
      "city": "",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 645,
      "lat": 14.0736112595,
      "lon": -86.9100036621,
      "tz": "America\/Tegucigalpa"
  },
  "MHRD": {
      "icao": "MHRD",
      "iata": "",
      "name": "Rus Rus II Airport",
      "city": "Rus Rus",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 173,
      "lat": 14.7872219086,
      "lon": -84.369720459,
      "tz": "America\/Tegucigalpa"
  },
  "MHRH": {
      "icao": "MHRH",
      "iata": "",
      "name": "Regional Hamer Airport",
      "city": "",
      "state": "Colon",
      "country": "HN",
      "elevation": 202,
      "lat": 15.5633325577,
      "lon": -86.1913909912,
      "tz": "America\/Tegucigalpa"
  },
  "MHRJ": {
      "icao": "MHRJ",
      "iata": "",
      "name": "Rancho Jamastran Airport",
      "city": "Apala",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 482,
      "lat": 14.0609998703,
      "lon": -86.4075012207,
      "tz": "America\/Tegucigalpa"
  },
  "MHRO": {
      "icao": "MHRO",
      "iata": "RTB",
      "name": "Juan Manuel Galvez International Airport",
      "city": "Roatan Island",
      "state": "Bay-Islands",
      "country": "HN",
      "elevation": 18,
      "lat": 16.3167991638,
      "lon": -86.5230026245,
      "tz": "America\/Tegucigalpa"
  },
  "MHRR": {
      "icao": "MHRR",
      "iata": "",
      "name": "Rus Rus I Airport",
      "city": "Rus Rus",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 90,
      "lat": 14.7130556107,
      "lon": -84.4524993896,
      "tz": "America\/Tegucigalpa"
  },
  "MHRS": {
      "icao": "MHRS",
      "iata": "SDH",
      "name": "Santa Rosa Copan Airport",
      "city": "Santa Rosa de Copan",
      "state": "Copan",
      "country": "HN",
      "elevation": 2818,
      "lat": 14.8149003983,
      "lon": -88.7515029907,
      "tz": "America\/Tegucigalpa"
  },
  "MHRU": {
      "icao": "MHRU",
      "iata": "RUY",
      "name": "Copan Ruinas Airport",
      "city": "Ruinas de Copan",
      "state": "Copan",
      "country": "HN",
      "elevation": 1990,
      "lat": 14.8402996063,
      "lon": -89.1439971924,
      "tz": "America\/Tegucigalpa"
  },
  "MHRY": {
      "icao": "MHRY",
      "iata": "",
      "name": "Raya Airport",
      "city": "Raya",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 30,
      "lat": 15.0663890839,
      "lon": -83.2972183228,
      "tz": "America\/Tegucigalpa"
  },
  "MHSC": {
      "icao": "MHSC",
      "iata": "XPL",
      "name": "Coronel Enrique Soto Cano Air Base",
      "city": "Comayagua",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 2061,
      "lat": 14.382399559,
      "lon": -87.6212005615,
      "tz": "America\/Tegucigalpa"
  },
  "MHSI": {
      "icao": "MHSI",
      "iata": "",
      "name": "Sico Airport",
      "city": "Sico",
      "state": "Colon",
      "country": "HN",
      "elevation": 20,
      "lat": 15.818611145,
      "lon": -85.114440918,
      "tz": "America\/Tegucigalpa"
  },
  "MHSJ": {
      "icao": "MHSJ",
      "iata": "",
      "name": "San Jose Airport",
      "city": "Oropoli",
      "state": "El-Paraiso",
      "country": "HN",
      "elevation": 454,
      "lat": 13.8277778625,
      "lon": -86.8102798462,
      "tz": "America\/Tegucigalpa"
  },
  "MHSL": {
      "icao": "MHSL",
      "iata": "",
      "name": "San Lorenzo Airport",
      "city": "El Hato",
      "state": "Valle",
      "country": "HN",
      "elevation": 8,
      "lat": 13.4422216415,
      "lon": -87.4597244263,
      "tz": "America\/Tegucigalpa"
  },
  "MHSN": {
      "icao": "MHSN",
      "iata": "",
      "name": "Sinaloa Airport",
      "city": "Sinaloa",
      "state": "Colon",
      "country": "HN",
      "elevation": 20,
      "lat": 15.6933326721,
      "lon": -85.9552764893,
      "tz": "America\/Tegucigalpa"
  },
  "MHSX": {
      "icao": "MHSX",
      "iata": "",
      "name": "Sixatigni Airport",
      "city": "",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 70,
      "lat": 15.1836109161,
      "lon": -84.369720459,
      "tz": "America\/Tegucigalpa"
  },
  "MHSZ": {
      "icao": "MHSZ",
      "iata": "",
      "name": "Santa Barbara Airport",
      "city": "Choluteca",
      "state": "Choluteca",
      "country": "HN",
      "elevation": 28,
      "lat": 13.3305559158,
      "lon": -87.1544418335,
      "tz": "America\/Tegucigalpa"
  },
  "MHTA": {
      "icao": "MHTA",
      "iata": "",
      "name": "Tamara Airport",
      "city": "Tamara",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 1080,
      "lat": 14.1800003052,
      "lon": -87.3499984741,
      "tz": "America\/Tegucigalpa"
  },
  "MHTB": {
      "icao": "MHTB",
      "iata": "",
      "name": "La Katabila Airport",
      "city": "Tuntuntara",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 14,
      "lat": 15.1750001907,
      "lon": -83.682220459,
      "tz": "America\/Tegucigalpa"
  },
  "MHTE": {
      "icao": "MHTE",
      "iata": "TEA",
      "name": "Tela Airport",
      "city": "Tela",
      "state": "Atlantida",
      "country": "HN",
      "elevation": 7,
      "lat": 15.7758998871,
      "lon": -87.4757995605,
      "tz": "America\/Tegucigalpa"
  },
  "MHTG": {
      "icao": "MHTG",
      "iata": "TGU",
      "name": "Toncontin International Airport",
      "city": "Tegucigalpa",
      "state": "Francisco-Morazan",
      "country": "HN",
      "elevation": 3294,
      "lat": 14.0608997345,
      "lon": -87.2172012329,
      "tz": "America\/Tegucigalpa"
  },
  "MHTI": {
      "icao": "MHTI",
      "iata": "",
      "name": "Tipimuratara Airport",
      "city": "Tipimunatara",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 43,
      "lat": 15.0011110306,
      "lon": -83.7222213745,
      "tz": "America\/Tegucigalpa"
  },
  "MHTJ": {
      "icao": "MHTJ",
      "iata": "TJI",
      "name": "Trujillo Airport",
      "city": "Trujillo",
      "state": "Colon",
      "country": "HN",
      "elevation": 3,
      "lat": 15.9267997742,
      "lon": -85.9382019043,
      "tz": "America\/Tegucigalpa"
  },
  "MHTM": {
      "icao": "MHTM",
      "iata": "",
      "name": "Santa Maria Airport",
      "city": "Santa Maria",
      "state": "La-Paz",
      "country": "HN",
      "elevation": 1151,
      "lat": 14.2772216797,
      "lon": -87.936668396,
      "tz": "America\/Tegucigalpa"
  },
  "MHTO": {
      "icao": "MHTO",
      "iata": "",
      "name": "Tocoa Airport",
      "city": "Tocoa",
      "state": "Colon",
      "country": "HN",
      "elevation": 37,
      "lat": 15.6597223282,
      "lon": -85.9941635132,
      "tz": "America\/Tegucigalpa"
  },
  "MHUC": {
      "icao": "MHUC",
      "iata": "",
      "name": "Auca Airport",
      "city": "Auca",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 190,
      "lat": 14.9372215271,
      "lon": -83.8441696167,
      "tz": "America\/Tegucigalpa"
  },
  "MHUG": {
      "icao": "MHUG",
      "iata": "",
      "name": "Guanacastal Airport",
      "city": "Canchias",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 1563,
      "lat": 14.915555954,
      "lon": -87.8333358765,
      "tz": "America\/Tegucigalpa"
  },
  "MHUH": {
      "icao": "MHUH",
      "iata": "",
      "name": "Uhi Airport",
      "city": "Uhi",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 3,
      "lat": 15.476099968,
      "lon": -83.9113006592,
      "tz": "America\/Tegucigalpa"
  },
  "MHUL": {
      "icao": "MHUL",
      "iata": "SCD",
      "name": "Sulaco Airport",
      "city": "Sulaco",
      "state": "Comayagua",
      "country": "HN",
      "elevation": 400,
      "lat": 14.9071998596,
      "lon": -87.2633972168,
      "tz": "America\/Tegucigalpa"
  },
  "MHUO": {
      "icao": "MHUO",
      "iata": "",
      "name": "La Union Airport",
      "city": "La Union",
      "state": "Lempira",
      "country": "HN",
      "elevation": 1000,
      "lat": 14.8080558777,
      "lon": -88.4199981689,
      "tz": "America\/Tegucigalpa"
  },
  "MHUT": {
      "icao": "MHUT",
      "iata": "UII",
      "name": "Utila Airport",
      "city": "Utila Island",
      "state": "Bay-Islands",
      "country": "HN",
      "elevation": 29,
      "lat": 16.1131000519,
      "lon": -86.8803024292,
      "tz": "America\/Tegucigalpa"
  },
  "MHUY": {
      "icao": "MHUY",
      "iata": "",
      "name": "Cucuyagua Airport",
      "city": "Cucuyagua",
      "state": "Copan",
      "country": "HN",
      "elevation": 805,
      "lat": 14.6269435883,
      "lon": -88.8763885498,
      "tz": "America\/Tegucigalpa"
  },
  "MHVE": {
      "icao": "MHVE",
      "iata": "",
      "name": "Villa Hermosa Airport",
      "city": "Coyolillo",
      "state": "Lempira",
      "country": "HN",
      "elevation": 1280,
      "lat": 14.2302780151,
      "lon": -88.799446106,
      "tz": "America\/Tegucigalpa"
  },
  "MHVG": {
      "icao": "MHVG",
      "iata": "",
      "name": "Villa Guadalupe Airport",
      "city": "Villa Guadalupe",
      "state": "Santa-Barbara",
      "country": "HN",
      "elevation": 400,
      "lat": 15.0455560684,
      "lon": -88.3088912964,
      "tz": "America\/Tegucigalpa"
  },
  "MHWA": {
      "icao": "MHWA",
      "iata": "",
      "name": "Wampusirpi I Airport",
      "city": "Wampusirpi",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 250,
      "lat": 15.1602783203,
      "lon": -84.617225647,
      "tz": "America\/Tegucigalpa"
  },
  "MHWD": {
      "icao": "MHWD",
      "iata": "",
      "name": "Wampusirpi II Airport",
      "city": "Wampusirpi",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 250,
      "lat": 15.1591672897,
      "lon": -84.5983352661,
      "tz": "America\/Tegucigalpa"
  },
  "MHWP": {
      "icao": "MHWP",
      "iata": "",
      "name": "Waplaya Airport",
      "city": "Waplaya",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 43,
      "lat": 15.2130556107,
      "lon": -84.068611145,
      "tz": "America\/Tegucigalpa"
  },
  "MHWR": {
      "icao": "MHWR",
      "iata": "",
      "name": "Warunta Airport",
      "city": "Warunta",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 30,
      "lat": 15.348610878,
      "lon": -84.2344436646,
      "tz": "America\/Tegucigalpa"
  },
  "MHWS": {
      "icao": "MHWS",
      "iata": "",
      "name": "Wasma Airport",
      "city": "Wasma",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 15,
      "lat": 15.4819002151,
      "lon": -84.4160995483,
      "tz": "America\/Tegucigalpa"
  },
  "MHWW": {
      "icao": "MHWW",
      "iata": "",
      "name": "Wawina Airport",
      "city": "Wawina",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 19,
      "lat": 15.4161109924,
      "lon": -84.4736099243,
      "tz": "America\/Tegucigalpa"
  },
  "MHYX": {
      "icao": "MHYX",
      "iata": "",
      "name": "Yaxu Airport",
      "city": "Puerto Lempira",
      "state": "Gracias-a-Dios",
      "country": "HN",
      "elevation": 8,
      "lat": 15.2775001526,
      "lon": -83.820274353,
      "tz": "America\/Tegucigalpa"
  },
  "MHZA": {
      "icao": "MHZA",
      "iata": "",
      "name": "Azacualpa Airport",
      "city": "Azacualpa",
      "state": "Lempira",
      "country": "HN",
      "elevation": 1200,
      "lat": 14.2569437027,
      "lon": -88.3925018311,
      "tz": "America\/Tegucigalpa"
  },
  "MI00": {
      "icao": "MI00",
      "iata": "",
      "name": "Bonnie Field",
      "city": "Rock",
      "state": "Michigan",
      "country": "US",
      "elevation": 970,
      "lat": 46.0522003174,
      "lon": -87.2582015991,
      "tz": "America\/Detroit"
  },
  "MI01": {
      "icao": "MI01",
      "iata": "",
      "name": "Fasel Field",
      "city": "Avoca",
      "state": "Michigan",
      "country": "US",
      "elevation": 735,
      "lat": 43.057800293,
      "lon": -82.676902771,
      "tz": "America\/Detroit"
  },
  "MI02": {
      "icao": "MI02",
      "iata": "",
      "name": "Brablec Farms Airport",
      "city": "Britton",
      "state": "Michigan",
      "country": "US",
      "elevation": 691,
      "lat": 41.9869995117,
      "lon": -83.8047027588,
      "tz": "America\/Detroit"
  },
  "MI04": {
      "icao": "MI04",
      "iata": "",
      "name": "Airborn Paraflite Ultralightport",
      "city": "Ithaca",
      "state": "Michigan",
      "country": "US",
      "elevation": 758,
      "lat": 43.3069000244,
      "lon": -84.6457977295,
      "tz": "America\/Detroit"
  },
  "MI05": {
      "icao": "MI05",
      "iata": "",
      "name": "Crompton's Private Strip",
      "city": "Clio",
      "state": "Michigan",
      "country": "US",
      "elevation": 680,
      "lat": 43.1666984558,
      "lon": -83.7874984741,
      "tz": "America\/Detroit"
  },
  "MI06": {
      "icao": "MI06",
      "iata": "",
      "name": "Lee Field",
      "city": "Bennington",
      "state": "Michigan",
      "country": "US",
      "elevation": 810,
      "lat": 42.9230995178,
      "lon": -84.2833023071,
      "tz": "America\/Detroit"
  },
  "MI09": {
      "icao": "MI09",
      "iata": "",
      "name": "Milan Airport",
      "city": "Milan",
      "state": "Michigan",
      "country": "US",
      "elevation": 706,
      "lat": 42.0499992371,
      "lon": -83.740196228,
      "tz": "America\/Detroit"
  },
  "MI10": {
      "icao": "MI10",
      "iata": "",
      "name": "Hoerners Corners Airport",
      "city": "De Witt",
      "state": "Michigan",
      "country": "US",
      "elevation": 800,
      "lat": 42.8652992249,
      "lon": -84.7054977417,
      "tz": "America\/Detroit"
  },
  "MI11": {
      "icao": "MI11",
      "iata": "",
      "name": "Crippen Field",
      "city": "Charlotte",
      "state": "Michigan",
      "country": "US",
      "elevation": 890,
      "lat": 42.534198761,
      "lon": -84.801399231,
      "tz": "America\/Detroit"
  },
  "MI13": {
      "icao": "MI13",
      "iata": "",
      "name": "Minikey Airport",
      "city": "Sheridan",
      "state": "Michigan",
      "country": "US",
      "elevation": 900,
      "lat": 43.1617012024,
      "lon": -85.0616989136,
      "tz": "America\/Detroit"
  },
  "MI15": {
      "icao": "MI15",
      "iata": "",
      "name": "Wards Long Acres Airport",
      "city": "Fair Haven",
      "state": "Michigan",
      "country": "US",
      "elevation": 618,
      "lat": 42.7291984558,
      "lon": -82.6651992798,
      "tz": "America\/Detroit"
  },
  "MI18": {
      "icao": "MI18",
      "iata": "",
      "name": "Cedarville Airport",
      "city": "Flushing",
      "state": "Michigan",
      "country": "US",
      "elevation": 725,
      "lat": 43.0931015015,
      "lon": -83.8360977173,
      "tz": "America\/Detroit"
  },
  "MI19": {
      "icao": "MI19",
      "iata": "",
      "name": "Thorn Airport",
      "city": "Custer",
      "state": "Michigan",
      "country": "US",
      "elevation": 780,
      "lat": 43.9300003052,
      "lon": -86.1656036377,
      "tz": "America\/Detroit"
  },
  "MI21": {
      "icao": "MI21",
      "iata": "",
      "name": "A.C. Miller Airport",
      "city": "Frontier",
      "state": "Michigan",
      "country": "US",
      "elevation": 944,
      "lat": 41.7374992371,
      "lon": -84.5649032593,
      "tz": "America\/Detroit"
  },
  "MI22": {
      "icao": "MI22",
      "iata": "",
      "name": "Crump Airport",
      "city": "Niles",
      "state": "Michigan",
      "country": "US",
      "elevation": 745,
      "lat": 41.8736991882,
      "lon": -86.202796936,
      "tz": "America\/Detroit"
  },
  "MI23": {
      "icao": "MI23",
      "iata": "",
      "name": "Mckimmy Field",
      "city": "Bridgeport",
      "state": "Michigan",
      "country": "US",
      "elevation": 600,
      "lat": 43.3469009399,
      "lon": -83.8666992188,
      "tz": "America\/Detroit"
  },
  "MI24": {
      "icao": "MI24",
      "iata": "",
      "name": "Wilson Township Airport",
      "city": "East Jordan",
      "state": "Michigan",
      "country": "US",
      "elevation": 730,
      "lat": 45.1417007446,
      "lon": -85.0066986084,
      "tz": "America\/Detroit"
  },
  "MI25": {
      "icao": "MI25",
      "iata": "",
      "name": "Myers Airport",
      "city": "Bridgman",
      "state": "Michigan",
      "country": "US",
      "elevation": 670,
      "lat": 41.9222984314,
      "lon": -86.5456008911,
      "tz": "America\/Detroit"
  },
  "MI26": {
      "icao": "MI26",
      "iata": "",
      "name": "Hynes Field",
      "city": "Hartland",
      "state": "Michigan",
      "country": "US",
      "elevation": 978,
      "lat": 42.6109008789,
      "lon": -83.7326965332,
      "tz": "America\/Detroit"
  },
  "MI27": {
      "icao": "MI27",
      "iata": "",
      "name": "Haigh Airport",
      "city": "Howell",
      "state": "Michigan",
      "country": "US",
      "elevation": 1000,
      "lat": 42.6124992371,
      "lon": -83.8541030884,
      "tz": "America\/Detroit"
  },
  "MI29": {
      "icao": "MI29",
      "iata": "",
      "name": "Gooding Airport",
      "city": "Whitmore Lake",
      "state": "Michigan",
      "country": "US",
      "elevation": 920,
      "lat": 42.3763999939,
      "lon": -83.7927017212,
      "tz": "America\/Detroit"
  },
  "MI31": {
      "icao": "MI31",
      "iata": "",
      "name": "Cornish Field",
      "city": "Lawton",
      "state": "Michigan",
      "country": "US",
      "elevation": 910,
      "lat": 42.1015014648,
      "lon": -85.8378982544,
      "tz": "America\/Detroit"
  },
  "MI33": {
      "icao": "MI33",
      "iata": "",
      "name": "Adair Airstrip",
      "city": "Richmond",
      "state": "Michigan",
      "country": "US",
      "elevation": 631,
      "lat": 42.792301178,
      "lon": -82.6393966675,
      "tz": "America\/Detroit"
  },
  "MI35": {
      "icao": "MI35",
      "iata": "",
      "name": "Claucherty Airport",
      "city": "Litchfield",
      "state": "Michigan",
      "country": "US",
      "elevation": 988,
      "lat": 42.0778007507,
      "lon": -84.8291015625,
      "tz": "America\/Detroit"
  },
  "MI37": {
      "icao": "MI37",
      "iata": "",
      "name": "Wenning Landing Area Airport",
      "city": "Marine City",
      "state": "Michigan",
      "country": "US",
      "elevation": 620,
      "lat": 42.7555999756,
      "lon": -82.5582962036,
      "tz": "America\/Detroit"
  },
  "MI38": {
      "icao": "MI38",
      "iata": "",
      "name": "Eichmeier Field",
      "city": "Mason",
      "state": "Michigan",
      "country": "US",
      "elevation": 900,
      "lat": 42.6022987366,
      "lon": -84.426399231,
      "tz": "America\/Detroit"
  },
  "MI39": {
      "icao": "MI39",
      "iata": "",
      "name": "Laszlo Airport",
      "city": "Milan",
      "state": "Michigan",
      "country": "US",
      "elevation": 675,
      "lat": 42.0750007629,
      "lon": -83.6082992554,
      "tz": "America\/Detroit"
  },
  "MI41": {
      "icao": "MI41",
      "iata": "",
      "name": "Crystal Airport",
      "city": "St. Clair",
      "state": "Michigan",
      "country": "US",
      "elevation": 610,
      "lat": 42.8316993713,
      "lon": -82.5345993042,
      "tz": "America\/Detroit"
  },
  "MI43": {
      "icao": "MI43",
      "iata": "",
      "name": "Pewanogowink-Banks Airport",
      "city": "Montrose",
      "state": "Michigan",
      "country": "US",
      "elevation": 660,
      "lat": 43.1861000061,
      "lon": -83.9013977051,
      "tz": "America\/Detroit"
  },
  "MI44": {
      "icao": "MI44",
      "iata": "",
      "name": "D J Airport",
      "city": "Mount Pleasant",
      "state": "Michigan",
      "country": "US",
      "elevation": 755,
      "lat": 43.6722984314,
      "lon": -84.7367019653,
      "tz": "America\/Detroit"
  },
  "MI45": {
      "icao": "MI45",
      "iata": "",
      "name": "Ed Schulte's Place STOLport",
      "city": "Ortonville",
      "state": "Michigan",
      "country": "US",
      "elevation": 1050,
      "lat": 42.8081016541,
      "lon": -83.4518966675,
      "tz": "America\/Detroit"
  },
  "MI47": {
      "icao": "MI47",
      "iata": "",
      "name": "Dysinger Airport",
      "city": "Perry",
      "state": "Michigan",
      "country": "US",
      "elevation": 897,
      "lat": 42.8083992004,
      "lon": -84.1902008057,
      "tz": "America\/Detroit"
  },
  "MI48": {
      "icao": "MI48",
      "iata": "",
      "name": "Ham-A-Lot Field",
      "city": "Petersburg",
      "state": "Michigan",
      "country": "US",
      "elevation": 680,
      "lat": 41.8334007263,
      "lon": -83.6832962036,
      "tz": "America\/Detroit"
  },
  "MI50": {
      "icao": "MI50",
      "iata": "",
      "name": "Dodge Airport",
      "city": "Romeo",
      "state": "Michigan",
      "country": "US",
      "elevation": 705,
      "lat": 42.7667007446,
      "lon": -82.9498977661,
      "tz": "America\/Detroit"
  },
  "MI51": {
      "icao": "MI51",
      "iata": "",
      "name": "Loars Field",
      "city": "Onsted",
      "state": "Michigan",
      "country": "US",
      "elevation": 995,
      "lat": 41.9968986511,
      "lon": -84.1885986328,
      "tz": "America\/Detroit"
  },
  "MI52": {
      "icao": "MI52",
      "iata": "",
      "name": "Markham Airport",
      "city": "Clinton",
      "state": "Michigan",
      "country": "US",
      "elevation": 825,
      "lat": 42.0905990601,
      "lon": -83.9680023193,
      "tz": "America\/Detroit"
  },
  "MI53": {
      "icao": "MI53",
      "iata": "",
      "name": "Mills Field",
      "city": "Newport",
      "state": "Michigan",
      "country": "US",
      "elevation": 590,
      "lat": 42.0092010498,
      "lon": -83.2583007813,
      "tz": "America\/Detroit"
  },
  "MI55": {
      "icao": "MI55",
      "iata": "",
      "name": "Zeitler Airport",
      "city": "Merril",
      "state": "Michigan",
      "country": "US",
      "elevation": 678,
      "lat": 43.4900016785,
      "lon": -84.3653030396,
      "tz": "America\/Detroit"
  },
  "MI58": {
      "icao": "MI58",
      "iata": "",
      "name": "Stony Acres Airport",
      "city": "Sunfield",
      "state": "Michigan",
      "country": "US",
      "elevation": 849,
      "lat": 42.7545013428,
      "lon": -85.0286026001,
      "tz": "America\/Detroit"
  },
  "MI60": {
      "icao": "MI60",
      "iata": "",
      "name": "Tannehill Airfield",
      "city": "Fife Lake",
      "state": "Michigan",
      "country": "US",
      "elevation": 1050,
      "lat": 44.5914001465,
      "lon": -85.310798645,
      "tz": "America\/Detroit"
  },
  "MI67": {
      "icao": "MI67",
      "iata": "",
      "name": "Tyrone Airport",
      "city": "Bailey",
      "state": "Michigan",
      "country": "US",
      "elevation": 810,
      "lat": 43.2891998291,
      "lon": -85.7811965942,
      "tz": "America\/Detroit"
  },
  "MI68": {
      "icao": "MI68",
      "iata": "",
      "name": "Del Hickcox Airport",
      "city": "Baroda",
      "state": "Michigan",
      "country": "US",
      "elevation": 645,
      "lat": 41.9375,
      "lon": -86.5014038086,
      "tz": "America\/Detroit"
  },
  "MI70": {
      "icao": "MI70",
      "iata": "",
      "name": "Carl's Airport",
      "city": "Macon",
      "state": "Michigan",
      "country": "US",
      "elevation": 850,
      "lat": 42.0712013245,
      "lon": -83.8835983276,
      "tz": "America\/Detroit"
  },
  "MI71": {
      "icao": "MI71",
      "iata": "",
      "name": "Silver Lake Airport",
      "city": "Mears",
      "state": "Michigan",
      "country": "US",
      "elevation": 772,
      "lat": 43.6445007324,
      "lon": -86.4529037476,
      "tz": "America\/Detroit"
  },
  "MI72": {
      "icao": "MI72",
      "iata": "",
      "name": "Nelson Airport",
      "city": "Buchanan",
      "state": "Michigan",
      "country": "US",
      "elevation": 665,
      "lat": 41.8499984741,
      "lon": -86.3499984741,
      "tz": "America\/Detroit"
  },
  "MI73": {
      "icao": "MI73",
      "iata": "",
      "name": "North Cedar Airport",
      "city": "Cedar Springs",
      "state": "Michigan",
      "country": "US",
      "elevation": 893,
      "lat": 43.2583999634,
      "lon": -85.5458984375,
      "tz": "America\/Detroit"
  },
  "MI76": {
      "icao": "MI76",
      "iata": "",
      "name": "Reading Airport",
      "city": "Fennville",
      "state": "Michigan",
      "country": "US",
      "elevation": 700,
      "lat": 42.5499992371,
      "lon": -86.2166976929,
      "tz": "America\/Detroit"
  },
  "MI77": {
      "icao": "MI77",
      "iata": "",
      "name": "Shafter Airport",
      "city": "Galesburg",
      "state": "Michigan",
      "country": "US",
      "elevation": 805,
      "lat": 42.2999992371,
      "lon": -85.4167022705,
      "tz": "America\/Detroit"
  },
  "MI78": {
      "icao": "MI78",
      "iata": "",
      "name": "Weller Airport",
      "city": "Grandville",
      "state": "Michigan",
      "country": "US",
      "elevation": 600,
      "lat": 42.9166984558,
      "lon": -85.7834014893,
      "tz": "America\/Detroit"
  },
  "MI80": {
      "icao": "MI80",
      "iata": "",
      "name": "Wabasis Lake Airport",
      "city": "Greenville",
      "state": "Michigan",
      "country": "US",
      "elevation": 892,
      "lat": 43.1283988953,
      "lon": -85.3992004395,
      "tz": "America\/Detroit"
  },
  "MI82": {
      "icao": "MI82",
      "iata": "",
      "name": "Harry's Field",
      "city": "Hudsonville",
      "state": "Michigan",
      "country": "US",
      "elevation": 662,
      "lat": 42.8763999939,
      "lon": -85.9555969238,
      "tz": "America\/Detroit"
  },
  "MI84": {
      "icao": "MI84",
      "iata": "",
      "name": "Kerby Field",
      "city": "Mattawan",
      "state": "Michigan",
      "country": "US",
      "elevation": 780,
      "lat": 42.1983985901,
      "lon": -85.8067016602,
      "tz": "America\/Detroit"
  },
  "MI86": {
      "icao": "MI86",
      "iata": "",
      "name": "Midget Airport",
      "city": "North Muskegon",
      "state": "Michigan",
      "country": "US",
      "elevation": 669,
      "lat": 43.3424987793,
      "lon": -86.1868972778,
      "tz": "America\/Detroit"
  },
  "MI88": {
      "icao": "MI88",
      "iata": "",
      "name": "Bakers Field",
      "city": "Burnips",
      "state": "Michigan",
      "country": "US",
      "elevation": 680,
      "lat": 42.7322998047,
      "lon": -85.9052963257,
      "tz": "America\/Detroit"
  },
  "MI89": {
      "icao": "MI89",
      "iata": "",
      "name": "Perry Airport",
      "city": "Schoolcraft",
      "state": "Michigan",
      "country": "US",
      "elevation": 894,
      "lat": 42.1487007141,
      "lon": -85.6528015137,
      "tz": "America\/Detroit"
  },
  "MI92": {
      "icao": "MI92",
      "iata": "",
      "name": "Lilienthal Airport",
      "city": "Iron Mountain",
      "state": "Michigan",
      "country": "US",
      "elevation": 1250,
      "lat": 45.9327011108,
      "lon": -88.098197937,
      "tz": "America\/Menominee"
  },
  "MI93": {
      "icao": "MI93",
      "iata": "",
      "name": "Circle T Ranch Airport",
      "city": "Curran",
      "state": "Michigan",
      "country": "US",
      "elevation": 1010,
      "lat": 44.6878013611,
      "lon": -83.7438964844,
      "tz": "America\/Detroit"
  },
  "MI94": {
      "icao": "MI94",
      "iata": "",
      "name": "Fontecchio Airport",
      "city": "E Kingsford",
      "state": "Michigan",
      "country": "US",
      "elevation": 1110,
      "lat": 45.7846984863,
      "lon": -88.0681991577,
      "tz": "America\/Chicago"
  },
  "MI95": {
      "icao": "MI95",
      "iata": "",
      "name": "Taylors Flight Park Airport",
      "city": "Cassopolis",
      "state": "Michigan",
      "country": "US",
      "elevation": 890,
      "lat": 41.846698761,
      "lon": -85.8949966431,
      "tz": "America\/Detroit"
  },
  "MI98": {
      "icao": "MI98",
      "iata": "",
      "name": "Dowd Field",
      "city": "Fibre",
      "state": "Michigan",
      "country": "US",
      "elevation": 705,
      "lat": 46.2014007568,
      "lon": -84.7416992188,
      "tz": "America\/Detroit"
  },
  "MI99": {
      "icao": "MI99",
      "iata": "",
      "name": "Robertson Field",
      "city": "Richmond",
      "state": "Michigan",
      "country": "US",
      "elevation": 620,
      "lat": 42.7989006042,
      "lon": -82.6587982178,
      "tz": "America\/Detroit"
  },
  "MKBS": {
      "icao": "MKBS",
      "iata": "OCJ",
      "name": "Boscobel Aerodrome",
      "city": "Ocho Rios",
      "state": "St-Mary",
      "country": "JM",
      "elevation": 90,
      "lat": 18.4041996002,
      "lon": -76.96900177,
      "tz": "America\/Jamaica"
  },
  "MKJP": {
      "icao": "MKJP",
      "iata": "KIN",
      "name": "Norman Manley International Airport",
      "city": "Kingston",
      "state": "Kingston",
      "country": "JM",
      "elevation": 10,
      "lat": 17.9356994629,
      "lon": -76.7874984741,
      "tz": "America\/Jamaica"
  },
  "MKJS": {
      "icao": "MKJS",
      "iata": "MBJ",
      "name": "Sangster International Airport",
      "city": "Montego Bay",
      "state": "St-James",
      "country": "JM",
      "elevation": 4,
      "lat": 18.5037002563,
      "lon": -77.9133987427,
      "tz": "America\/Jamaica"
  },
  "MKKJ": {
      "icao": "MKKJ",
      "iata": "POT",
      "name": "Ken Jones Airport",
      "city": "Ken Jones",
      "state": "Portland",
      "country": "JM",
      "elevation": 20,
      "lat": 18.1987991333,
      "lon": -76.5345001221,
      "tz": "America\/Jamaica"
  },
  "MKNG": {
      "icao": "MKNG",
      "iata": "NEG",
      "name": "Negril Airport",
      "city": "Negril",
      "state": "Hanover",
      "country": "JM",
      "elevation": 9,
      "lat": 18.3428001404,
      "lon": -78.3320999146,
      "tz": "America\/Jamaica"
  },
  "MKTP": {
      "icao": "MKTP",
      "iata": "KTP",
      "name": "Tinson Pen Airport",
      "city": "Tinson Pen",
      "state": "St-Andrew",
      "country": "JM",
      "elevation": 16,
      "lat": 17.9885997772,
      "lon": -76.8237991333,
      "tz": "America\/Jamaica"
  },
  "MLIP": {
      "icao": "MLIP",
      "iata": "MIJ",
      "name": "Mili Island Airport",
      "city": "Mili Island",
      "state": "Mili-Atoll",
      "country": "MH",
      "elevation": 4,
      "lat": 6.0833301544,
      "lon": 171.733001709,
      "tz": "Pacific\/Majuro"
  },
  "MLLJ": {
      "icao": "MLLJ",
      "iata": "",
      "name": "La Javilla Airport",
      "city": "Guanacaste",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 43,
      "lat": 9.8111200333,
      "lon": -85.295501709,
      "tz": "America\/Costa_Rica"
  },
  "MM10": {
      "icao": "MM10",
      "iata": "",
      "name": "Isla Cedros Airport",
      "city": "",
      "state": "Baja-California",
      "country": "MX",
      "elevation": 98,
      "lat": 28.0375995636,
      "lon": -115.1900024414,
      "tz": "America\/Tijuana"
  },
  "MM11": {
      "icao": "MM11",
      "iata": "",
      "name": "Rancho El Durangueno Airport",
      "city": "",
      "state": "Durango",
      "country": "MX",
      "elevation": 8202,
      "lat": 24.4183006287,
      "lon": -104.8860015869,
      "tz": "America\/Monterrey"
  },
  "MM12": {
      "icao": "MM12",
      "iata": "",
      "name": "Bacubirito Airport",
      "city": "",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 986,
      "lat": 25.815700531,
      "lon": -107.9079971313,
      "tz": "America\/Mazatlan"
  },
  "MM13": {
      "icao": "MM13",
      "iata": "",
      "name": "Ciudad Camargo Southeast Airport",
      "city": "",
      "state": "Chihuahua",
      "country": "MX",
      "elevation": 4265,
      "lat": 27.5988998413,
      "lon": -105.1039962769,
      "tz": "America\/Chihuahua"
  },
  "MM14": {
      "icao": "MM14",
      "iata": "",
      "name": "Rio Verde Airport",
      "city": "",
      "state": "San-Luis-Potosi",
      "country": "MX",
      "elevation": 3281,
      "lat": 21.9629001617,
      "lon": -100.0080032349,
      "tz": "America\/Mexico_City"
  },
  "MM16": {
      "icao": "MM16",
      "iata": "",
      "name": "J Agustin Castro Airport",
      "city": "",
      "state": "Durango",
      "country": "MX",
      "elevation": 3720,
      "lat": 25.5265007019,
      "lon": -103.5179977417,
      "tz": "America\/Monterrey"
  },
  "MM17": {
      "icao": "MM17",
      "iata": "",
      "name": "La Retama Southwest Airport",
      "city": "",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 2000,
      "lat": 23.966299057,
      "lon": -98.8348007202,
      "tz": "America\/Monterrey"
  },
  "MM20": {
      "icao": "MM20",
      "iata": "",
      "name": "Cordoba Airport",
      "city": "",
      "state": "Veracruz",
      "country": "MX",
      "elevation": 3000,
      "lat": 18.8742008209,
      "lon": -96.9581985474,
      "tz": "America\/Mexico_City"
  },
  "MM21": {
      "icao": "MM21",
      "iata": "",
      "name": "La Encarnacion Airport",
      "city": "",
      "state": "Nuevo-Leon",
      "country": "MX",
      "elevation": 1560,
      "lat": 25.7360992432,
      "lon": -100.2249984741,
      "tz": "America\/Monterrey"
  },
  "MM22": {
      "icao": "MM22",
      "iata": "",
      "name": "La Pesca Airport",
      "city": "",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 15,
      "lat": 23.8022003174,
      "lon": -97.7736968994,
      "tz": "America\/Monterrey"
  },
  "MM23": {
      "icao": "MM23",
      "iata": "",
      "name": "Rancho Santa Ynes Airport",
      "city": "",
      "state": "Baja-California",
      "country": "MX",
      "elevation": 1843,
      "lat": 29.72590065,
      "lon": -114.6999969482,
      "tz": "America\/Tijuana"
  },
  "MM25": {
      "icao": "MM25",
      "iata": "",
      "name": "Ciudad Guzman Airport",
      "city": "",
      "state": "Jalisco",
      "country": "MX",
      "elevation": 4987,
      "lat": 19.7084999084,
      "lon": -103.4899978638,
      "tz": "America\/Mexico_City"
  },
  "MM27": {
      "icao": "MM27",
      "iata": "",
      "name": "Cementos Mexicanos Airport",
      "city": "",
      "state": "San-Luis-Potosi",
      "country": "MX",
      "elevation": 302,
      "lat": 21.9839992523,
      "lon": -98.9618988037,
      "tz": "America\/Mexico_City"
  },
  "MM28": {
      "icao": "MM28",
      "iata": "",
      "name": "Tizayuca Airport",
      "city": "",
      "state": "Morelos",
      "country": "MX",
      "elevation": 7600,
      "lat": 19.8740005493,
      "lon": -98.9436035156,
      "tz": "America\/Mexico_City"
  },
  "MM29": {
      "icao": "MM29",
      "iata": "",
      "name": "Ta Lo De Soto Airport",
      "city": "",
      "state": "Guerrero",
      "country": "MX",
      "elevation": 490,
      "lat": 16.4634990692,
      "lon": -98.3929977417,
      "tz": "America\/Mexico_City"
  },
  "MM30": {
      "icao": "MM30",
      "iata": "",
      "name": "Campo Gobierno Airport",
      "city": "",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 43,
      "lat": 24.6585006714,
      "lon": -107.5520019531,
      "tz": "America\/Mazatlan"
  },
  "MM31": {
      "icao": "MM31",
      "iata": "",
      "name": "Fausto Vega Santander Airport",
      "city": "",
      "state": "Veracruz",
      "country": "MX",
      "elevation": 10,
      "lat": 20.9468002319,
      "lon": -97.3750991821,
      "tz": "America\/Mexico_City"
  },
  "MM32": {
      "icao": "MM32",
      "iata": "",
      "name": "Puerto Libertad North Airport",
      "city": "",
      "state": "Sonora",
      "country": "MX",
      "elevation": 148,
      "lat": 29.929599762,
      "lon": -112.65599823,
      "tz": "America\/Hermosillo"
  },
  "MM36": {
      "icao": "MM36",
      "iata": "",
      "name": "Rancho San Salvador Northeast Airport",
      "city": "",
      "state": "Nuevo-Leon",
      "country": "MX",
      "elevation": 900,
      "lat": 26.6784992218,
      "lon": -100.1709976196,
      "tz": "America\/Monterrey"
  },
  "MM37": {
      "icao": "MM37",
      "iata": "",
      "name": "Casa Madero Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 4498,
      "lat": 25.5062007904,
      "lon": -102.202003479,
      "tz": "America\/Monterrey"
  },
  "MM38": {
      "icao": "MM38",
      "iata": "",
      "name": "Falcon Dam Airport",
      "city": "",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 150,
      "lat": 26.5210990906,
      "lon": -99.1485977173,
      "tz": "America\/Chicago"
  },
  "MM39": {
      "icao": "MM39",
      "iata": "",
      "name": "Guamuchil Airport",
      "city": "",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 80,
      "lat": 25.4386997223,
      "lon": -108.0920028687,
      "tz": "America\/Mazatlan"
  },
  "MM41": {
      "icao": "MM41",
      "iata": "",
      "name": "Leon Gonzales Pie De La Cuesta Airport",
      "city": "",
      "state": "Guerrero",
      "country": "MX",
      "elevation": 20,
      "lat": 16.910200119,
      "lon": -99.9887008667,
      "tz": "America\/Mexico_City"
  },
  "MM42": {
      "icao": "MM42",
      "iata": "",
      "name": "Muzquiz New Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 1750,
      "lat": 27.856300354,
      "lon": -101.5260009766,
      "tz": "America\/Monterrey"
  },
  "MM44": {
      "icao": "MM44",
      "iata": "",
      "name": "Agualeguas Old Airport",
      "city": "",
      "state": "Nuevo-Leon",
      "country": "MX",
      "elevation": 676,
      "lat": 26.3043994904,
      "lon": -99.550201416,
      "tz": "America\/Monterrey"
  },
  "MM47": {
      "icao": "MM47",
      "iata": "",
      "name": "Jaujilla Airport",
      "city": "",
      "state": "Michoacan",
      "country": "MX",
      "elevation": 6398,
      "lat": 19.8514995575,
      "lon": -101.7519989014,
      "tz": "America\/Mexico_City"
  },
  "MM48": {
      "icao": "MM48",
      "iata": "",
      "name": "Ciudad Pemex Airport",
      "city": "",
      "state": "Tabasco",
      "country": "MX",
      "elevation": 20,
      "lat": 17.8801002502,
      "lon": -92.4757003784,
      "tz": "America\/Mexico_City"
  },
  "MM50": {
      "icao": "MM50",
      "iata": "",
      "name": "Cosala Airport",
      "city": "",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 250,
      "lat": 24.4206008911,
      "lon": -106.702003479,
      "tz": "America\/Mazatlan"
  },
  "MM51": {
      "icao": "MM51",
      "iata": "",
      "name": "Rancho Guadalupe South Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 2200,
      "lat": 26.6355991364,
      "lon": -100.8899993896,
      "tz": "America\/Monterrey"
  },
  "MM53": {
      "icao": "MM53",
      "iata": "",
      "name": "Nuevo Dolores Airport",
      "city": "",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 480,
      "lat": 24.0615005493,
      "lon": -98.4156036377,
      "tz": "America\/Monterrey"
  },
  "MM54": {
      "icao": "MM54",
      "iata": "",
      "name": "Rancho Marina Vieja Airport",
      "city": "",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 100,
      "lat": 23.7381000519,
      "lon": -98.0612030029,
      "tz": "America\/Monterrey"
  },
  "MM56": {
      "icao": "MM56",
      "iata": "",
      "name": "Xicotencatl Airport",
      "city": "",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 377,
      "lat": 22.9538993835,
      "lon": -98.959602356,
      "tz": "America\/Monterrey"
  },
  "MM61": {
      "icao": "MM61",
      "iata": "",
      "name": "Ixmiquilpan Airport",
      "city": "",
      "state": "Hidalgo",
      "country": "MX",
      "elevation": 5577,
      "lat": 20.486000061,
      "lon": -99.2587966919,
      "tz": "America\/Mexico_City"
  },
  "MM63": {
      "icao": "MM63",
      "iata": "",
      "name": "Autlan Airport",
      "city": "",
      "state": "Jalisco",
      "country": "MX",
      "elevation": 2900,
      "lat": 19.745300293,
      "lon": -104.3369979858,
      "tz": "America\/Mexico_City"
  },
  "MM64": {
      "icao": "MM64",
      "iata": "",
      "name": "Cuatro Cienegas New Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 2450,
      "lat": 26.9904003143,
      "lon": -102.0329971313,
      "tz": "America\/Monterrey"
  },
  "MM65": {
      "icao": "MM65",
      "iata": "",
      "name": "Agua Prieta South Airport",
      "city": "",
      "state": "Sonora",
      "country": "MX",
      "elevation": 4165,
      "lat": 31.2523002625,
      "lon": -109.608001709,
      "tz": "America\/Hermosillo"
  },
  "MM66": {
      "icao": "MM66",
      "iata": "",
      "name": "Coahuayana Airport",
      "city": "",
      "state": "Michoacan",
      "country": "MX",
      "elevation": 200,
      "lat": 18.6846008301,
      "lon": -103.6760025024,
      "tz": "America\/Mexico_City"
  },
  "MM67": {
      "icao": "MM67",
      "iata": "",
      "name": "Matehuala Airport",
      "city": "",
      "state": "San-Luis-Potosi",
      "country": "MX",
      "elevation": 5118,
      "lat": 23.6765995026,
      "lon": -100.6230010986,
      "tz": "America\/Mexico_City"
  },
  "MM68": {
      "icao": "MM68",
      "iata": "",
      "name": "Mina Hercules Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 4310,
      "lat": 28.0366001129,
      "lon": -103.7710037231,
      "tz": "America\/Monterrey"
  },
  "MM69": {
      "icao": "MM69",
      "iata": "",
      "name": "Tacicuri Airport",
      "city": "",
      "state": "Sonora",
      "country": "MX",
      "elevation": 2536,
      "lat": 30.6744995117,
      "lon": -110.9309997559,
      "tz": "America\/Hermosillo"
  },
  "MM72": {
      "icao": "MM72",
      "iata": "",
      "name": "Cupul Airport",
      "city": "",
      "state": "Yucatan",
      "country": "MX",
      "elevation": 56,
      "lat": 21.1557006836,
      "lon": -88.1728973389,
      "tz": "America\/Merida"
  },
  "MM73": {
      "icao": "MM73",
      "iata": "",
      "name": "Huetamo Airport",
      "city": "",
      "state": "Michoacan",
      "country": "MX",
      "elevation": 984,
      "lat": 18.5273990631,
      "lon": -100.8509979248,
      "tz": "America\/Mexico_City"
  },
  "MM75": {
      "icao": "MM75",
      "iata": "",
      "name": "Agua Prieta Southwest Airport",
      "city": "",
      "state": "Sonora",
      "country": "MX",
      "elevation": 4000,
      "lat": 31.2429008484,
      "lon": -109.625,
      "tz": "America\/Hermosillo"
  },
  "MM77": {
      "icao": "MM77",
      "iata": "",
      "name": "Las Delicias Airport",
      "city": "",
      "state": "Chihuahua",
      "country": "MX",
      "elevation": 3888,
      "lat": 28.2131004333,
      "lon": -105.4479980469,
      "tz": "America\/Chihuahua"
  },
  "MM79": {
      "icao": "MM79",
      "iata": "",
      "name": "El Fuerte Airport",
      "city": "",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 246,
      "lat": 26.3981990814,
      "lon": -108.6119995117,
      "tz": "America\/Mazatlan"
  },
  "MM80": {
      "icao": "MM80",
      "iata": "",
      "name": "Rancho La Milpita Airport",
      "city": "",
      "state": "Sonora",
      "country": "MX",
      "elevation": 4707,
      "lat": 30.4808998108,
      "lon": -109.6439971924,
      "tz": "America\/Hermosillo"
  },
  "MM81": {
      "icao": "MM81",
      "iata": "",
      "name": "Isla Socorro Airport",
      "city": "",
      "state": "Colima",
      "country": "MX",
      "elevation": 1085,
      "lat": 18.7742004395,
      "lon": -110.9300003052,
      "tz": "America\/Mexico_City"
  },
  "MM82": {
      "icao": "MM82",
      "iata": "",
      "name": "Laguna Del Rey Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 3450,
      "lat": 27.0237998962,
      "lon": -103.375,
      "tz": "America\/Matamoros"
  },
  "MM84": {
      "icao": "MM84",
      "iata": "",
      "name": "Parral Airport",
      "city": "",
      "state": "Chihuahua",
      "country": "MX",
      "elevation": 5965,
      "lat": 26.9221000671,
      "lon": -105.7819976807,
      "tz": "America\/Chihuahua"
  },
  "MMAA": {
      "icao": "MMAA",
      "iata": "ACA",
      "name": "General Juan N Alvarez International Airport",
      "city": "Acapulco",
      "state": "Guerrero",
      "country": "MX",
      "elevation": 16,
      "lat": 16.7570991516,
      "lon": -99.7539978027,
      "tz": "America\/Mexico_City"
  },
  "MMAL": {
      "icao": "MMAL",
      "iata": "",
      "name": "Agualeguas Airport",
      "city": "",
      "state": "Nuevo-Leon",
      "country": "MX",
      "elevation": 676,
      "lat": 26.3339996338,
      "lon": -99.5423965454,
      "tz": "America\/Monterrey"
  },
  "MMAN": {
      "icao": "MMAN",
      "iata": "NTR",
      "name": "Del Norte International Airport",
      "city": "",
      "state": "Nuevo-Leon",
      "country": "MX",
      "elevation": 1476,
      "lat": 25.8656005859,
      "lon": -100.2369995117,
      "tz": "America\/Monterrey"
  },
  "MMAS": {
      "icao": "MMAS",
      "iata": "AGU",
      "name": "Jesus Teran International Airport",
      "city": "Aguascalientes",
      "state": "Aguascalientes",
      "country": "MX",
      "elevation": 6112,
      "lat": 21.7056007385,
      "lon": -102.318000793,
      "tz": "America\/Mexico_City"
  },
  "MMBT": {
      "icao": "MMBT",
      "iata": "HUX",
      "name": "Bahias de Huatulco International Airport",
      "city": "Huatulco",
      "state": "Oaxaca",
      "country": "MX",
      "elevation": 464,
      "lat": 15.7753000259,
      "lon": -96.2626037598,
      "tz": "America\/Mexico_City"
  },
  "MMCA": {
      "icao": "MMCA",
      "iata": "CNA",
      "name": "Cananea Airport",
      "city": "",
      "state": "Sonora",
      "country": "MX",
      "elevation": 5200,
      "lat": 31.0661501549,
      "lon": -110.097877979,
      "tz": "America\/Hermosillo"
  },
  "MMCB": {
      "icao": "MMCB",
      "iata": "CVJ",
      "name": "General Mariano Matamoros Airport",
      "city": "",
      "state": "Morelos",
      "country": "MX",
      "elevation": 4277,
      "lat": 18.8348007202,
      "lon": -99.2612991333,
      "tz": "America\/Mexico_City"
  },
  "MMCC": {
      "icao": "MMCC",
      "iata": "ACN",
      "name": "Ciudad Acuna New International Airport",
      "city": "Ciudad Acuna",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 1410,
      "lat": 29.3328990936,
      "lon": -101.0989990234,
      "tz": "America\/Matamoros"
  },
  "MMCE": {
      "icao": "MMCE",
      "iata": "CME",
      "name": "Ciudad del Carmen International Airport",
      "city": "Ciudad del Carmen",
      "state": "Campeche",
      "country": "MX",
      "elevation": 10,
      "lat": 18.6536998749,
      "lon": -91.7990036011,
      "tz": "America\/Merida"
  },
  "MMCG": {
      "icao": "MMCG",
      "iata": "NCG",
      "name": "Nuevo Casas Grandes Airport",
      "city": "",
      "state": "Chihuahua",
      "country": "MX",
      "elevation": 4850,
      "lat": 30.3973999023,
      "lon": -107.875,
      "tz": "America\/Chihuahua"
  },
  "MMCH": {
      "icao": "MMCH",
      "iata": "",
      "name": "Chilpancingo Airport",
      "city": "",
      "state": "Guerrero",
      "country": "MX",
      "elevation": 4199,
      "lat": 17.5737991333,
      "lon": -99.5142974854,
      "tz": "America\/Mexico_City"
  },
  "MMCL": {
      "icao": "MMCL",
      "iata": "CUL",
      "name": "Federal de Bachigualato International Airport",
      "city": "Culiacan",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 108,
      "lat": 24.7644996643,
      "lon": -107.474998474,
      "tz": "America\/Mazatlan"
  },
  "MMCM": {
      "icao": "MMCM",
      "iata": "CTM",
      "name": "Chetumal International Airport",
      "city": "Chetumal",
      "state": "Quintana-Roo",
      "country": "MX",
      "elevation": 39,
      "lat": 18.504699707,
      "lon": -88.3267974854,
      "tz": "America\/Cancun"
  },
  "MMCN": {
      "icao": "MMCN",
      "iata": "CEN",
      "name": "Ciudad Obregon International Airport",
      "city": "Ciudad Obregon",
      "state": "Sonora",
      "country": "MX",
      "elevation": 243,
      "lat": 27.3925991058,
      "lon": -109.8330001831,
      "tz": "America\/Hermosillo"
  },
  "MMCO": {
      "icao": "MMCO",
      "iata": "CJT",
      "name": "Comitan Airport",
      "city": "",
      "state": "Chiapas",
      "country": "MX",
      "elevation": 5161,
      "lat": 16.176700592,
      "lon": -92.0505981445,
      "tz": "America\/Mexico_City"
  },
  "MMCP": {
      "icao": "MMCP",
      "iata": "CPE",
      "name": "Ingeniero Alberto Acuna Ongay International Airport",
      "city": "Campeche",
      "state": "Campeche",
      "country": "MX",
      "elevation": 34,
      "lat": 19.8167991638,
      "lon": -90.5002975464,
      "tz": "America\/Merida"
  },
  "MMCS": {
      "icao": "MMCS",
      "iata": "CJS",
      "name": "Abraham Gonzalez International Airport",
      "city": "Ciudad Juarez",
      "state": "Chihuahua",
      "country": "MX",
      "elevation": 3904,
      "lat": 31.636100769,
      "lon": -106.4290008545,
      "tz": "America\/Ojinaga"
  },
  "MMCT": {
      "icao": "MMCT",
      "iata": "CZA",
      "name": "Chichen Itza International Airport",
      "city": "",
      "state": "Yucatan",
      "country": "MX",
      "elevation": 102,
      "lat": 20.6413002014,
      "lon": -88.4461975098,
      "tz": "America\/Merida"
  },
  "MMCU": {
      "icao": "MMCU",
      "iata": "CUU",
      "name": "General Roberto Fierro Villalobos International Airport",
      "city": "Chihuahua",
      "state": "Chihuahua",
      "country": "MX",
      "elevation": 4462,
      "lat": 28.7028999329,
      "lon": -105.964996338,
      "tz": "America\/Chihuahua"
  },
  "MMCV": {
      "icao": "MMCV",
      "iata": "CVM",
      "name": "General Pedro Jose Mendez International Airport",
      "city": "Ciudad Victoria",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 761,
      "lat": 23.7033004761,
      "lon": -98.9564971924,
      "tz": "America\/Monterrey"
  },
  "MMCY": {
      "icao": "MMCY",
      "iata": "CYW",
      "name": "Captain Rogelio Castillo National Airport",
      "city": "Celaya",
      "state": "Guanajuato",
      "country": "MX",
      "elevation": 5709,
      "lat": 20.545999527,
      "lon": -100.8870010376,
      "tz": "America\/Mexico_City"
  },
  "MMCZ": {
      "icao": "MMCZ",
      "iata": "CZM",
      "name": "Cozumel International Airport",
      "city": "Cozumel",
      "state": "Quintana-Roo",
      "country": "MX",
      "elevation": 15,
      "lat": 20.5223999023,
      "lon": -86.9255981445,
      "tz": "America\/Cancun"
  },
  "MMDA": {
      "icao": "MMDA",
      "iata": "CUA",
      "name": "Ciudad Constitucion Airport",
      "city": "Ciudad Constitucion",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 213,
      "lat": 25.0538005829,
      "lon": -111.6149978638,
      "tz": "America\/Mazatlan"
  },
  "MMDM": {
      "icao": "MMDM",
      "iata": "MMC",
      "name": "Ciudad Mante National Airport",
      "city": "Ciudad Mante",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 341,
      "lat": 22.7431774139,
      "lon": -99.0173721313,
      "tz": "America\/Monterrey"
  },
  "MMDO": {
      "icao": "MMDO",
      "iata": "DGO",
      "name": "General Guadalupe Victoria International Airport",
      "city": "Durango",
      "state": "Durango",
      "country": "MX",
      "elevation": 6104,
      "lat": 24.1242008209,
      "lon": -104.527999878,
      "tz": "America\/Monterrey"
  },
  "MMEP": {
      "icao": "MMEP",
      "iata": "TPQ",
      "name": "Amado Nervo National Airport",
      "city": "Tepic",
      "state": "Nayarit",
      "country": "MX",
      "elevation": 3020,
      "lat": 21.419500351,
      "lon": -104.8430023193,
      "tz": "America\/Mazatlan"
  },
  "MMES": {
      "icao": "MMES",
      "iata": "ESE",
      "name": "Ensenada Airport",
      "city": "",
      "state": "Baja-California",
      "country": "MX",
      "elevation": 66,
      "lat": 31.79529953,
      "lon": -116.6029968262,
      "tz": "America\/Tijuana"
  },
  "MMGL": {
      "icao": "MMGL",
      "iata": "GDL",
      "name": "Don Miguel Hidalgo Y Costilla International Airport",
      "city": "Guadalajara",
      "state": "Jalisco",
      "country": "MX",
      "elevation": 5016,
      "lat": 20.5217990875,
      "lon": -103.3109970093,
      "tz": "America\/Mexico_City"
  },
  "MMGM": {
      "icao": "MMGM",
      "iata": "GYM",
      "name": "General Jose Maria Yanez International Airport",
      "city": "Guaymas",
      "state": "Sonora",
      "country": "MX",
      "elevation": 59,
      "lat": 27.9689998627,
      "lon": -110.9250030518,
      "tz": "America\/Hermosillo"
  },
  "MMGR": {
      "icao": "MMGR",
      "iata": "GUB",
      "name": "Guerrero Negro Airport",
      "city": "Guerrero Negro",
      "state": "Baja-California",
      "country": "MX",
      "elevation": 59,
      "lat": 28.0261001587,
      "lon": -114.0240020752,
      "tz": "America\/Mazatlan"
  },
  "MMGT": {
      "icao": "MMGT",
      "iata": "",
      "name": "Guanajuato Airport",
      "city": "",
      "state": "Guanajuato",
      "country": "MX",
      "elevation": 6152,
      "lat": 20.9172000885,
      "lon": -101.3339996338,
      "tz": "America\/Mexico_City"
  },
  "MMHC": {
      "icao": "MMHC",
      "iata": "TCN",
      "name": "Tehuacan Airport",
      "city": "",
      "state": "Puebla",
      "country": "MX",
      "elevation": 5509,
      "lat": 18.4972000122,
      "lon": -97.4198989868,
      "tz": "America\/Mexico_City"
  },
  "MMHO": {
      "icao": "MMHO",
      "iata": "HMO",
      "name": "General Ignacio P. Garcia International Airport",
      "city": "Hermosillo",
      "state": "Sonora",
      "country": "MX",
      "elevation": 627,
      "lat": 29.0958995819,
      "lon": -111.047996521,
      "tz": "America\/Hermosillo"
  },
  "MMIA": {
      "icao": "MMIA",
      "iata": "CLQ",
      "name": "Lic. Miguel de la Madrid Airport",
      "city": "Colima",
      "state": "Colima",
      "country": "MX",
      "elevation": 2467,
      "lat": 19.2770004272,
      "lon": -103.577003479,
      "tz": "America\/Mexico_City"
  },
  "MMIM": {
      "icao": "MMIM",
      "iata": "ISJ",
      "name": "Isla Mujeres Airport",
      "city": "",
      "state": "Quintana-Roo",
      "country": "MX",
      "elevation": 7,
      "lat": 21.2450008392,
      "lon": -86.7399978638,
      "tz": "America\/Cancun"
  },
  "MMIO": {
      "icao": "MMIO",
      "iata": "SLW",
      "name": "Plan De Guadalupe International Airport",
      "city": "Saltillo",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 4778,
      "lat": 25.5494995117,
      "lon": -100.9290008545,
      "tz": "America\/Monterrey"
  },
  "MMIT": {
      "icao": "MMIT",
      "iata": "IZT",
      "name": "Ixtepec Airport",
      "city": "",
      "state": "Oaxaca",
      "country": "MX",
      "elevation": 164,
      "lat": 16.449300766,
      "lon": -95.0936965942,
      "tz": "America\/Mexico_City"
  },
  "MMJA": {
      "icao": "MMJA",
      "iata": "JAL",
      "name": "El Lencero Airport",
      "city": "Xalapa",
      "state": "Veracruz",
      "country": "MX",
      "elevation": 3127,
      "lat": 19.4750995636,
      "lon": -96.7975006104,
      "tz": "America\/Mexico_City"
  },
  "MMJC": {
      "icao": "MMJC",
      "iata": "AZP",
      "name": "Atizapan De Zaragoza Airport",
      "city": "",
      "state": "Mexico",
      "country": "MX",
      "elevation": 8120,
      "lat": 19.5748004913,
      "lon": -99.2888031006,
      "tz": "America\/Mexico_City"
  },
  "MMLC": {
      "icao": "MMLC",
      "iata": "LZC",
      "name": "Lazaro Cardenas Airport",
      "city": "Lazaro Cardenas",
      "state": "Michoacan",
      "country": "MX",
      "elevation": 39,
      "lat": 18.0016994476,
      "lon": -102.221000671,
      "tz": "America\/Mexico_City"
  },
  "MMLM": {
      "icao": "MMLM",
      "iata": "LMM",
      "name": "Valle del Fuerte International Airport",
      "city": "Los Mochis",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 16,
      "lat": 25.6851997375,
      "lon": -109.081001282,
      "tz": "America\/Mazatlan"
  },
  "MMLO": {
      "icao": "MMLO",
      "iata": "BJX",
      "name": "Del Bajio International Airport",
      "city": "Silao",
      "state": "Guanajuato",
      "country": "MX",
      "elevation": 5956,
      "lat": 20.9934997559,
      "lon": -101.481002808,
      "tz": "America\/Mexico_City"
  },
  "MMLP": {
      "icao": "MMLP",
      "iata": "LAP",
      "name": "Manuel Marquez de Leon International Airport",
      "city": "La Paz",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 69,
      "lat": 24.0727005005,
      "lon": -110.361999512,
      "tz": "America\/Mazatlan"
  },
  "MMLT": {
      "icao": "MMLT",
      "iata": "LTO",
      "name": "Loreto International Airport",
      "city": "Loreto",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 34,
      "lat": 25.989200592,
      "lon": -111.3479995728,
      "tz": "America\/Mazatlan"
  },
  "MMMA": {
      "icao": "MMMA",
      "iata": "MAM",
      "name": "General Servando Canales International Airport",
      "city": "Matamoros",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 25,
      "lat": 25.7698993683,
      "lon": -97.5252990723,
      "tz": "America\/Matamoros"
  },
  "MMMD": {
      "icao": "MMMD",
      "iata": "MID",
      "name": "Licenciado Manuel Crescencio Rejon Int Airport",
      "city": "Merida",
      "state": "Yucatan",
      "country": "MX",
      "elevation": 38,
      "lat": 20.9370002747,
      "lon": -89.657699585,
      "tz": "America\/Merida"
  },
  "MMMG": {
      "icao": "MMMG",
      "iata": "MUG",
      "name": "Mulege Airport",
      "city": "Mulege",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 140,
      "lat": 26.897310257,
      "lon": -111.9575500488,
      "tz": "America\/Mazatlan"
  },
  "MMML": {
      "icao": "MMML",
      "iata": "MXL",
      "name": "General Rodolfo Sanchez Taboada International Airport",
      "city": "Mexicali",
      "state": "Baja-California",
      "country": "MX",
      "elevation": 74,
      "lat": 32.6305999756,
      "lon": -115.241996765,
      "tz": "America\/Tijuana"
  },
  "MMMM": {
      "icao": "MMMM",
      "iata": "MLM",
      "name": "General Francisco J. Mujica International Airport",
      "city": "Morelia",
      "state": "Michoacan",
      "country": "MX",
      "elevation": 6033,
      "lat": 19.849899292,
      "lon": -101.025001526,
      "tz": "America\/Mexico_City"
  },
  "MMMT": {
      "icao": "MMMT",
      "iata": "MTT",
      "name": "Minatitlan\/Coatzacoalcos National Airport",
      "city": "Minatitlan",
      "state": "Veracruz",
      "country": "MX",
      "elevation": 36,
      "lat": 18.1033992767,
      "lon": -94.5807037354,
      "tz": "America\/Mexico_City"
  },
  "MMMV": {
      "icao": "MMMV",
      "iata": "LOV",
      "name": "Monclova International Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 1864,
      "lat": 26.9556999207,
      "lon": -101.4700012207,
      "tz": "America\/Monterrey"
  },
  "MMMX": {
      "icao": "MMMX",
      "iata": "MEX",
      "name": "Licenciado Benito Juarez International Airport",
      "city": "Mexico City",
      "state": "Mexico-City",
      "country": "MX",
      "elevation": 7316,
      "lat": 19.4363002777,
      "lon": -99.0720977783,
      "tz": "America\/Mexico_City"
  },
  "MMMY": {
      "icao": "MMMY",
      "iata": "MTY",
      "name": "General Mariano Escobedo International Airport",
      "city": "Monterrey",
      "state": "Nuevo-Leon",
      "country": "MX",
      "elevation": 1278,
      "lat": 25.7784996033,
      "lon": -100.107002258,
      "tz": "America\/Monterrey"
  },
  "MMMZ": {
      "icao": "MMMZ",
      "iata": "MZT",
      "name": "General Rafael Buelna International Airport",
      "city": "Mazatlan",
      "state": "Sinaloa",
      "country": "MX",
      "elevation": 38,
      "lat": 23.1613998413,
      "lon": -106.26599884,
      "tz": "America\/Mazatlan"
  },
  "MMNG": {
      "icao": "MMNG",
      "iata": "NOG",
      "name": "Nogales International Airport",
      "city": "",
      "state": "Sonora",
      "country": "MX",
      "elevation": 3990,
      "lat": 31.2261009216,
      "lon": -110.9759979248,
      "tz": "America\/Hermosillo"
  },
  "MMNL": {
      "icao": "MMNL",
      "iata": "NLD",
      "name": "Quetzalcoatl International Airport",
      "city": "Nuevo Laredo",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 484,
      "lat": 27.4438991547,
      "lon": -99.5705032349,
      "tz": "America\/Matamoros"
  },
  "MMOX": {
      "icao": "MMOX",
      "iata": "OAX",
      "name": "Xoxocotlan International Airport",
      "city": "Oaxaca",
      "state": "Oaxaca",
      "country": "MX",
      "elevation": 4989,
      "lat": 16.9999008179,
      "lon": -96.726600647,
      "tz": "America\/Mexico_City"
  },
  "MMPA": {
      "icao": "MMPA",
      "iata": "PAZ",
      "name": "El Tajin National Airport",
      "city": "Poza Rica",
      "state": "Veracruz",
      "country": "MX",
      "elevation": 497,
      "lat": 20.6026992798,
      "lon": -97.4608001709,
      "tz": "America\/Mexico_City"
  },
  "MMPB": {
      "icao": "MMPB",
      "iata": "PBC",
      "name": "Hermanos Serdan International Airport",
      "city": "Puebla",
      "state": "Puebla",
      "country": "MX",
      "elevation": 7361,
      "lat": 19.1581001282,
      "lon": -98.3713989258,
      "tz": "America\/Mexico_City"
  },
  "MMPC": {
      "icao": "MMPC",
      "iata": "",
      "name": "Ingeniero Juan Guillermo Villasana Airport",
      "city": "",
      "state": "Hidalgo",
      "country": "MX",
      "elevation": 7600,
      "lat": 20.0774002075,
      "lon": -98.7825012207,
      "tz": "America\/Mexico_City"
  },
  "MMPE": {
      "icao": "MMPE",
      "iata": "PPE",
      "name": "Puerto Penasco International Airport",
      "city": "Puerto Penasco",
      "state": "Sonora",
      "country": "MX",
      "elevation": 30,
      "lat": 31.356202302,
      "lon": -113.525676727,
      "tz": "America\/Hermosillo"
  },
  "MMPG": {
      "icao": "MMPG",
      "iata": "PDS",
      "name": "Piedras Negras International Airport",
      "city": "",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 901,
      "lat": 28.6273994446,
      "lon": -100.5350036621,
      "tz": "America\/Matamoros"
  },
  "MMPL": {
      "icao": "MMPL",
      "iata": "PCO",
      "name": "Punta Colorada Airport",
      "city": "La Ribera",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 55,
      "lat": 23.575011,
      "lon": -109.535826,
      "tz": "America\/Mazatlan"
  },
  "MMPN": {
      "icao": "MMPN",
      "iata": "UPN",
      "name": "Licenciado y General Ignacio Lopez Rayon Airport",
      "city": "",
      "state": "Michoacan",
      "country": "MX",
      "elevation": 5258,
      "lat": 19.3966999054,
      "lon": -102.0390014648,
      "tz": "America\/Mexico_City"
  },
  "MMPP": {
      "icao": "MMPP",
      "iata": "",
      "name": "Punta Pescadero Airport",
      "city": "",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 33,
      "lat": 23.8034000397,
      "lon": -109.7070007324,
      "tz": "America\/Mazatlan"
  },
  "MMPQ": {
      "icao": "MMPQ",
      "iata": "PQM",
      "name": "Palenque International Airport",
      "city": "",
      "state": "Chiapas",
      "country": "MX",
      "elevation": 200,
      "lat": 17.5333995819,
      "lon": -91.9844970703,
      "tz": "America\/Mexico_City"
  },
  "MMPR": {
      "icao": "MMPR",
      "iata": "PVR",
      "name": "Licenciado Gustavo Diaz Ordaz International Airport",
      "city": "Puerto Vallarta",
      "state": "Jalisco",
      "country": "MX",
      "elevation": 23,
      "lat": 20.6800994873,
      "lon": -105.2539978027,
      "tz": "America\/Bahia_Banderas"
  },
  "MMPS": {
      "icao": "MMPS",
      "iata": "PXM",
      "name": "Puerto Escondido International Airport",
      "city": "Puerto Escondido",
      "state": "Oaxaca",
      "country": "MX",
      "elevation": 294,
      "lat": 15.8768997192,
      "lon": -97.0891036987,
      "tz": "America\/Mexico_City"
  },
  "MMQT": {
      "icao": "MMQT",
      "iata": "QRO",
      "name": "Queretaro Intercontinental Airport",
      "city": "Queretaro",
      "state": "Queretaro",
      "country": "MX",
      "elevation": 6296,
      "lat": 20.6173000336,
      "lon": -100.185997009,
      "tz": "America\/Mexico_City"
  },
  "MMRX": {
      "icao": "MMRX",
      "iata": "REX",
      "name": "General Lucio Blanco International Airport",
      "city": "Reynosa",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 139,
      "lat": 26.0088996887,
      "lon": -98.2285003662,
      "tz": "America\/Matamoros"
  },
  "MMSC": {
      "icao": "MMSC",
      "iata": "SZT",
      "name": "San Cristobal De Las Casas Airport",
      "city": "",
      "state": "Chiapas",
      "country": "MX",
      "elevation": 7707,
      "lat": 16.6902999878,
      "lon": -92.5300979614,
      "tz": "America\/Mexico_City"
  },
  "MMSD": {
      "icao": "MMSD",
      "iata": "SJD",
      "name": "Los Cabos International Airport",
      "city": "San Jose del Cabo",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 374,
      "lat": 23.1518001556,
      "lon": -109.7210006714,
      "tz": "America\/Mazatlan"
  },
  "MMSF": {
      "icao": "MMSF",
      "iata": "SFH",
      "name": "San Felipe International Airport",
      "city": "",
      "state": "Baja-California",
      "country": "MX",
      "elevation": 148,
      "lat": 30.9302005768,
      "lon": -114.8089981079,
      "tz": "America\/Tijuana"
  },
  "MMSL": {
      "icao": "MMSL",
      "iata": "",
      "name": "Cabo San Lucas International Airport",
      "city": "Cabo San Lucas",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 459,
      "lat": 22.9477005005,
      "lon": -109.93699646,
      "tz": "America\/Mazatlan"
  },
  "MMSM": {
      "icao": "MMSM",
      "iata": "NLU",
      "name": "Felipe Angeles International Airport",
      "city": "Santa Lucia",
      "state": "Mexico",
      "country": "MX",
      "elevation": 7369,
      "lat": 19.756667,
      "lon": -99.015278,
      "tz": "America\/Mexico_City"
  },
  "MMSP": {
      "icao": "MMSP",
      "iata": "SLP",
      "name": "Ponciano Arriaga International Airport",
      "city": "San Luis Potosi",
      "state": "San-Luis-Potosi",
      "country": "MX",
      "elevation": 6035,
      "lat": 22.2542991638,
      "lon": -100.930999756,
      "tz": "America\/Mexico_City"
  },
  "MMTA": {
      "icao": "MMTA",
      "iata": "",
      "name": "Tlaxcala Airport",
      "city": "",
      "state": "Tlaxcala",
      "country": "MX",
      "elevation": 8229,
      "lat": 19.5380001068,
      "lon": -98.173500061,
      "tz": "America\/Mexico_City"
  },
  "MMTB": {
      "icao": "MMTB",
      "iata": "",
      "name": "Teran Air Base",
      "city": "Tuxtla Gutierrez",
      "state": "Chiapas",
      "country": "MX",
      "elevation": 1909,
      "lat": 16.739900589,
      "lon": -93.1733016968,
      "tz": "America\/Mexico_City"
  },
  "MMTC": {
      "icao": "MMTC",
      "iata": "TRC",
      "name": "Francisco Sarabia International Airport",
      "city": "Torreon",
      "state": "Coahuila",
      "country": "MX",
      "elevation": 3688,
      "lat": 25.5683002472,
      "lon": -103.411003113,
      "tz": "America\/Monterrey"
  },
  "MMTG": {
      "icao": "MMTG",
      "iata": "TGZ",
      "name": "Angel Albino Corzo International Airport",
      "city": "Tuxtla Gutierrez",
      "state": "Chiapas",
      "country": "MX",
      "elevation": 1499,
      "lat": 16.5636005402,
      "lon": -93.0224990845,
      "tz": "America\/Mexico_City"
  },
  "MMTJ": {
      "icao": "MMTJ",
      "iata": "TIJ",
      "name": "General Abelardo L. Rodriguez International Airport",
      "city": "Tijuana",
      "state": "Baja-California-Sur",
      "country": "MX",
      "elevation": 489,
      "lat": 32.5410995483,
      "lon": -116.9700012207,
      "tz": "America\/Los_Angeles"
  },
  "MMTM": {
      "icao": "MMTM",
      "iata": "TAM",
      "name": "General Francisco Javier Mina International Airport",
      "city": "Tampico",
      "state": "Tamaulipas",
      "country": "MX",
      "elevation": 80,
      "lat": 22.2964000702,
      "lon": -97.8658981323,
      "tz": "America\/Monterrey"
  },
  "MMTN": {
      "icao": "MMTN",
      "iata": "TSL",
      "name": "Tamuin Airport",
      "city": "",
      "state": "San-Luis-Potosi",
      "country": "MX",
      "elevation": 164,
      "lat": 22.0382995605,
      "lon": -98.8065032959,
      "tz": "America\/Mexico_City"
  },
  "MMTO": {
      "icao": "MMTO",
      "iata": "TLC",
      "name": "Licenciado Adolfo Lopez Mateos International Airport",
      "city": "Toluca",
      "state": "Mexico",
      "country": "MX",
      "elevation": 8466,
      "lat": 19.3370990753,
      "lon": -99.5660018921,
      "tz": "America\/Mexico_City"
  },
  "MMTP": {
      "icao": "MMTP",
      "iata": "TAP",
      "name": "Tapachula International Airport",
      "city": "Tapachula",
      "state": "Chiapas",
      "country": "MX",
      "elevation": 97,
      "lat": 14.7943000793,
      "lon": -92.3700027466,
      "tz": "America\/Mexico_City"
  },
  "MMTX": {
      "icao": "MMTX",
      "iata": "WIX",
      "name": "Tuxpan Airport",
      "city": "",
      "state": "Jalisco",
      "country": "MX",
      "elevation": 4019,
      "lat": 19.5839004517,
      "lon": -103.3840026855,
      "tz": "America\/Mexico_City"
  },
  "MMUN": {
      "icao": "MMUN",
      "iata": "CUN",
      "name": "Cancun International Airport",
      "city": "Cancun",
      "state": "Quintana-Roo",
      "country": "MX",
      "elevation": 22,
      "lat": 21.0365009308,
      "lon": -86.8770980835,
      "tz": "America\/Cancun"
  },
  "MMVA": {
      "icao": "MMVA",
      "iata": "VSA",
      "name": "Carlos Rovirosa Perez International Airport",
      "city": "Villahermosa",
      "state": "Tabasco",
      "country": "MX",
      "elevation": 46,
      "lat": 17.9969997406,
      "lon": -92.8173980713,
      "tz": "America\/Mexico_City"
  },
  "MMVR": {
      "icao": "MMVR",
      "iata": "VER",
      "name": "General Heriberto Jara International Airport",
      "city": "Veracruz",
      "state": "Veracruz",
      "country": "MX",
      "elevation": 90,
      "lat": 19.1459007263,
      "lon": -96.1873016357,
      "tz": "America\/Mexico_City"
  },
  "MMZC": {
      "icao": "MMZC",
      "iata": "ZCL",
      "name": "General Leobardo C. Ruiz International Airport",
      "city": "Zacatecas",
      "state": "Zacatecas",
      "country": "MX",
      "elevation": 7141,
      "lat": 22.8971004486,
      "lon": -102.68699646,
      "tz": "America\/Mexico_City"
  },
  "MMZH": {
      "icao": "MMZH",
      "iata": "ZIH",
      "name": "Ixtapa Zihuatanejo International Airport",
      "city": "Ixtapa",
      "state": "Guerrero",
      "country": "MX",
      "elevation": 26,
      "lat": 17.601600647,
      "lon": -101.460998535,
      "tz": "America\/Mexico_City"
  },
  "MMZM": {
      "icao": "MMZM",
      "iata": "ZMM",
      "name": "Zamora Airport",
      "city": "",
      "state": "Michoacan",
      "country": "MX",
      "elevation": 5141,
      "lat": 20.0450000763,
      "lon": -102.2760009766,
      "tz": "America\/Mexico_City"
  },
  "MMZO": {
      "icao": "MMZO",
      "iata": "ZLO",
      "name": "Playa De Oro International Airport",
      "city": "Manzanillo",
      "state": "Colima",
      "country": "MX",
      "elevation": 30,
      "lat": 19.1448001862,
      "lon": -104.558998108,
      "tz": "America\/Mexico_City"
  },
  "MMZP": {
      "icao": "MMZP",
      "iata": "",
      "name": "Zapopan Airport",
      "city": "",
      "state": "Jalisco",
      "country": "MX",
      "elevation": 5333,
      "lat": 20.7558002472,
      "lon": -103.4649963379,
      "tz": "America\/Mexico_City"
  },
  "MN00": {
      "icao": "MN00",
      "iata": "",
      "name": "Webb Lake Airport",
      "city": "Hackensack",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1359,
      "lat": 46.95980072,
      "lon": -94.42469788,
      "tz": "America\/Chicago"
  },
  "MN01": {
      "icao": "MN01",
      "iata": "",
      "name": "Hammars Farm Airport",
      "city": "Ada",
      "state": "Minnesota",
      "country": "US",
      "elevation": 886,
      "lat": 47.2832984924,
      "lon": -96.6169967651,
      "tz": "America\/Chicago"
  },
  "MN04": {
      "icao": "MN04",
      "iata": "",
      "name": "Aggies Landing Airport",
      "city": "Alexandria",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1387,
      "lat": 45.773601532,
      "lon": -95.262802124,
      "tz": "America\/Chicago"
  },
  "MN06": {
      "icao": "MN06",
      "iata": "",
      "name": "Pulkrabek Private Landing Field",
      "city": "Angus",
      "state": "Minnesota",
      "country": "US",
      "elevation": 850,
      "lat": 48.0499992371,
      "lon": -96.7836990356,
      "tz": "America\/Chicago"
  },
  "MN08": {
      "icao": "MN08",
      "iata": "",
      "name": "Eagles Nest Aerodrome",
      "city": "Eagle Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 44.126499176,
      "lon": -93.8718032837,
      "tz": "America\/Chicago"
  },
  "MN11": {
      "icao": "MN11",
      "iata": "",
      "name": "Lorenz Airport",
      "city": "Benson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1050,
      "lat": 45.2999992371,
      "lon": -95.5449981689,
      "tz": "America\/Chicago"
  },
  "MN12": {
      "icao": "MN12",
      "iata": "",
      "name": "Robco Airport",
      "city": "Laporte",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1380,
      "lat": 47.1988983154,
      "lon": -94.7521972656,
      "tz": "America\/Chicago"
  },
  "MN13": {
      "icao": "MN13",
      "iata": "",
      "name": "Moberg Air Base",
      "city": "Bemidji",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1373,
      "lat": 47.4961013794,
      "lon": -94.952796936,
      "tz": "America\/Chicago"
  },
  "MN15": {
      "icao": "MN15",
      "iata": "",
      "name": "Empire Farm Strip",
      "city": "Bongards",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1000,
      "lat": 44.7949981689,
      "lon": -93.8538970947,
      "tz": "America\/Chicago"
  },
  "MN17": {
      "icao": "MN17",
      "iata": "",
      "name": "Jackson Field",
      "city": "Brainerd",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1225,
      "lat": 46.27220154,
      "lon": -94.23940277,
      "tz": "America\/Chicago"
  },
  "MN18": {
      "icao": "MN18",
      "iata": "",
      "name": "Barrett Airport",
      "city": "Brainerd",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1275,
      "lat": 46.1781997681,
      "lon": -94.0886001587,
      "tz": "America\/Chicago"
  },
  "MN19": {
      "icao": "MN19",
      "iata": "",
      "name": "Brandt Airport",
      "city": "Delano",
      "state": "Minnesota",
      "country": "US",
      "elevation": 930,
      "lat": 45.0472984314,
      "lon": -93.8364028931,
      "tz": "America\/Chicago"
  },
  "MN20": {
      "icao": "MN20",
      "iata": "",
      "name": "Runke's Field",
      "city": "Cedar Mills",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 44.9104995728,
      "lon": -94.5714035034,
      "tz": "America\/Chicago"
  },
  "MN23": {
      "icao": "MN23",
      "iata": "",
      "name": "Dupre's Airport",
      "city": "Chisago",
      "state": "Minnesota",
      "country": "US",
      "elevation": 936,
      "lat": 45.3157997131,
      "lon": -92.8858032227,
      "tz": "America\/Chicago"
  },
  "MN24": {
      "icao": "MN24",
      "iata": "",
      "name": "Surfside Airport",
      "city": "Circle Pines",
      "state": "Minnesota",
      "country": "US",
      "elevation": 890,
      "lat": 45.1500015259,
      "lon": -93.116897583,
      "tz": "America\/Chicago"
  },
  "MN28": {
      "icao": "MN28",
      "iata": "",
      "name": "Lux Strip",
      "city": "Cosmos",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1120,
      "lat": 44.983001709,
      "lon": -94.7481002808,
      "tz": "America\/Chicago"
  },
  "MN31": {
      "icao": "MN31",
      "iata": "",
      "name": "L & M Aerodrome",
      "city": "Litchfield",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1205,
      "lat": 45.0621986389,
      "lon": -94.5792007446,
      "tz": "America\/Chicago"
  },
  "MN32": {
      "icao": "MN32",
      "iata": "",
      "name": "Nietz Airstrip",
      "city": "Oronoco",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1130,
      "lat": 44.1913986206,
      "lon": -92.4526977539,
      "tz": "America\/Chicago"
  },
  "MN37": {
      "icao": "MN37",
      "iata": "",
      "name": "Shelton's Private Airport",
      "city": "Duluth",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1320,
      "lat": 46.9449005127,
      "lon": -92.3571014404,
      "tz": "America\/Chicago"
  },
  "MN38": {
      "icao": "MN38",
      "iata": "",
      "name": "Spud Field",
      "city": "East Grand Forks",
      "state": "Minnesota",
      "country": "US",
      "elevation": 840,
      "lat": 47.9249992371,
      "lon": -96.9837036133,
      "tz": "America\/Chicago"
  },
  "MN40": {
      "icao": "MN40",
      "iata": "",
      "name": "Meadowvale Airport",
      "city": "Elk River",
      "state": "Minnesota",
      "country": "US",
      "elevation": 950,
      "lat": 45.3624992371,
      "lon": -93.5886001587,
      "tz": "America\/Chicago"
  },
  "MN41": {
      "icao": "MN41",
      "iata": "",
      "name": "Timmers Landing Field",
      "city": "Silver Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1070,
      "lat": 44.973428,
      "lon": -94.214186,
      "tz": "America\/Chicago"
  },
  "MN42": {
      "icao": "MN42",
      "iata": "",
      "name": "Oak Lake Air Strip",
      "city": "Erskine",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1204,
      "lat": 47.6585998535,
      "lon": -95.9460983276,
      "tz": "America\/Chicago"
  },
  "MN44": {
      "icao": "MN44",
      "iata": "",
      "name": "Angen Field",
      "city": "Garfield",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1450,
      "lat": 45.9364013672,
      "lon": -95.5261993408,
      "tz": "America\/Chicago"
  },
  "MN45": {
      "icao": "MN45",
      "iata": "",
      "name": "Jennrich Field",
      "city": "Farmington",
      "state": "Minnesota",
      "country": "US",
      "elevation": 951,
      "lat": 44.5661010742,
      "lon": -93.1322021484,
      "tz": "America\/Chicago"
  },
  "MN46": {
      "icao": "MN46",
      "iata": "",
      "name": "Lucht Field",
      "city": "Farmington",
      "state": "Minnesota",
      "country": "US",
      "elevation": 900,
      "lat": 44.6040992737,
      "lon": -93.0919036865,
      "tz": "America\/Chicago"
  },
  "MN47": {
      "icao": "MN47",
      "iata": "",
      "name": "Cameron's Airport",
      "city": "Princeton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 930,
      "lat": 45.54970169,
      "lon": -93.46980286,
      "tz": "America\/Chicago"
  },
  "MN49": {
      "icao": "MN49",
      "iata": "",
      "name": "Tuma Private Airport",
      "city": "Montgomery",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 44.4239006042,
      "lon": -93.4843978882,
      "tz": "America\/Chicago"
  },
  "MN50": {
      "icao": "MN50",
      "iata": "",
      "name": "Arthur Field",
      "city": "Gatzke",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1150,
      "lat": 48.3702011108,
      "lon": -95.8069000244,
      "tz": "America\/Chicago"
  },
  "MN51": {
      "icao": "MN51",
      "iata": "",
      "name": "Bowers Airport",
      "city": "Stacy",
      "state": "Minnesota",
      "country": "US",
      "elevation": 909,
      "lat": 45.4536018372,
      "lon": -92.9747009277,
      "tz": "America\/Chicago"
  },
  "MN52": {
      "icao": "MN52",
      "iata": "",
      "name": "Gospel Ranch Airport",
      "city": "Hibbing",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1360,
      "lat": 47.22909927,
      "lon": -93.09210205,
      "tz": "America\/Chicago"
  },
  "MN53": {
      "icao": "MN53",
      "iata": "",
      "name": "Stanley Field",
      "city": "Graceville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1112,
      "lat": 45.5821990967,
      "lon": -96.576499939,
      "tz": "America\/Chicago"
  },
  "MN55": {
      "icao": "MN55",
      "iata": "",
      "name": "Sandy Flats Airport",
      "city": "Hastings",
      "state": "Minnesota",
      "country": "US",
      "elevation": 835,
      "lat": 44.7052001953,
      "lon": -92.8118972778,
      "tz": "America\/Chicago"
  },
  "MN57": {
      "icao": "MN57",
      "iata": "",
      "name": "Bush Field",
      "city": "Hinckley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1050,
      "lat": 46.036072,
      "lon": -92.988253,
      "tz": "America\/Chicago"
  },
  "MN58": {
      "icao": "MN58",
      "iata": "",
      "name": "Sky Meadow Airport",
      "city": "Hastings",
      "state": "Minnesota",
      "country": "US",
      "elevation": 820,
      "lat": 44.6977005005,
      "lon": -92.8640975952,
      "tz": "America\/Chicago"
  },
  "MN60": {
      "icao": "MN60",
      "iata": "",
      "name": "Itzen Air Strip",
      "city": "Herman",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1053,
      "lat": 45.7633018494,
      "lon": -96.2155990601,
      "tz": "America\/Chicago"
  },
  "MN61": {
      "icao": "MN61",
      "iata": "",
      "name": "Ward Airport",
      "city": "Hollandale",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1205,
      "lat": 43.7602005005,
      "lon": -93.1780014038,
      "tz": "America\/Chicago"
  },
  "MN62": {
      "icao": "MN62",
      "iata": "",
      "name": "Quast Airport",
      "city": "Hutchinson",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1050,
      "lat": 44.8499984741,
      "lon": -94.3503036499,
      "tz": "America\/Chicago"
  },
  "MN63": {
      "icao": "MN63",
      "iata": "",
      "name": "Stocker Private Airport",
      "city": "Jordan",
      "state": "Minnesota",
      "country": "US",
      "elevation": 855,
      "lat": 44.6833000183,
      "lon": -93.6369018555,
      "tz": "America\/Chicago"
  },
  "MN64": {
      "icao": "MN64",
      "iata": "",
      "name": "H Reder Field",
      "city": "Lakefield",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1504,
      "lat": 43.6958007813,
      "lon": -95.1725006104,
      "tz": "America\/Chicago"
  },
  "MN66": {
      "icao": "MN66",
      "iata": "",
      "name": "Ingleside Airport",
      "city": "Loretto",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1021,
      "lat": 45.0630989075,
      "lon": -93.6522979736,
      "tz": "America\/Chicago"
  },
  "MN67": {
      "icao": "MN67",
      "iata": "",
      "name": "Sky Park Airport",
      "city": "Lydia",
      "state": "Minnesota",
      "country": "US",
      "elevation": 940,
      "lat": 44.6596984863,
      "lon": -93.5183029175,
      "tz": "America\/Chicago"
  },
  "MN68": {
      "icao": "MN68",
      "iata": "",
      "name": "Pangerl Airport",
      "city": "Braham",
      "state": "Minnesota",
      "country": "US",
      "elevation": 990,
      "lat": 45.7008018494,
      "lon": -93.37159729,
      "tz": "America\/Chicago"
  },
  "MN69": {
      "icao": "MN69",
      "iata": "",
      "name": "Maple Airport",
      "city": "Maple Plain",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1026,
      "lat": 45.0027008057,
      "lon": -93.6718978882,
      "tz": "America\/Chicago"
  },
  "MN71": {
      "icao": "MN71",
      "iata": "",
      "name": "Ziermann Airport",
      "city": "Mayer",
      "state": "Minnesota",
      "country": "US",
      "elevation": 988,
      "lat": 44.875,
      "lon": -93.8918991089,
      "tz": "America\/Chicago"
  },
  "MN74": {
      "icao": "MN74",
      "iata": "",
      "name": "B & D Flyers International Airport",
      "city": "Minnesota Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1055,
      "lat": 43.8305015564,
      "lon": -93.8404998779,
      "tz": "America\/Chicago"
  },
  "MN77": {
      "icao": "MN77",
      "iata": "",
      "name": "Jerger's Field",
      "city": "Moorhead",
      "state": "Minnesota",
      "country": "US",
      "elevation": 900,
      "lat": 46.9235992432,
      "lon": -96.7155990601,
      "tz": "America\/Chicago"
  },
  "MN79": {
      "icao": "MN79",
      "iata": "",
      "name": "Schroeder Airport",
      "city": "Becker",
      "state": "Minnesota",
      "country": "US",
      "elevation": 970,
      "lat": 45.4314002991,
      "lon": -93.8576965332,
      "tz": "America\/Chicago"
  },
  "MN81": {
      "icao": "MN81",
      "iata": "",
      "name": "J J and T Airport",
      "city": "East Grand Forks",
      "state": "Minnesota",
      "country": "US",
      "elevation": 828,
      "lat": 47.988899231,
      "lon": -97.0311965942,
      "tz": "America\/Chicago"
  },
  "MN84": {
      "icao": "MN84",
      "iata": "",
      "name": "Bachand Airport",
      "city": "Oklee",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1100,
      "lat": 47.8138999939,
      "lon": -95.891998291,
      "tz": "America\/Chicago"
  },
  "MN85": {
      "icao": "MN85",
      "iata": "",
      "name": "Swiderski Field",
      "city": "Onamia",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1250,
      "lat": 46.0332984924,
      "lon": -93.6613998413,
      "tz": "America\/Chicago"
  },
  "MN86": {
      "icao": "MN86",
      "iata": "",
      "name": "Sky Manor Aero Estates Airport",
      "city": "Park Rapids",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1492,
      "lat": 47.0457992554,
      "lon": -95.1224975586,
      "tz": "America\/Chicago"
  },
  "MN87": {
      "icao": "MN87",
      "iata": "",
      "name": "Cooks Landing Airport",
      "city": "Ham Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 895,
      "lat": 45.243598938,
      "lon": -93.2217025757,
      "tz": "America\/Chicago"
  },
  "MN88": {
      "icao": "MN88",
      "iata": "",
      "name": "Empire Valley Airport",
      "city": "Paynesville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1160,
      "lat": 45.2868995667,
      "lon": -94.6753005981,
      "tz": "America\/Chicago"
  },
  "MN89": {
      "icao": "MN89",
      "iata": "",
      "name": "Aerovilla Airport",
      "city": "Perham",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1374,
      "lat": 46.6166000366,
      "lon": -95.6502990723,
      "tz": "America\/Chicago"
  },
  "MN91": {
      "icao": "MN91",
      "iata": "",
      "name": "Reynolds Field",
      "city": "Princeton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 985,
      "lat": 45.5374984741,
      "lon": -93.5140991211,
      "tz": "America\/Chicago"
  },
  "MN92": {
      "icao": "MN92",
      "iata": "",
      "name": "Lennartson Airport",
      "city": "Proctor",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1250,
      "lat": 46.7499008179,
      "lon": -92.300201416,
      "tz": "America\/Chicago"
  },
  "MN93": {
      "icao": "MN93",
      "iata": "",
      "name": "Chandler Field",
      "city": "Randall",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1200,
      "lat": 46.1054992676,
      "lon": -94.516998291,
      "tz": "America\/Chicago"
  },
  "MN95": {
      "icao": "MN95",
      "iata": "",
      "name": "Velo Airstrip",
      "city": "Rothsay",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1386,
      "lat": 46.511100769,
      "lon": -96.1920013428,
      "tz": "America\/Chicago"
  },
  "MN96": {
      "icao": "MN96",
      "iata": "",
      "name": "Rohwer Airport",
      "city": "Rushmore",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1640,
      "lat": 43.5983009338,
      "lon": -95.8170013428,
      "tz": "America\/Chicago"
  },
  "MN97": {
      "icao": "MN97",
      "iata": "",
      "name": "Thens Private Airstrip",
      "city": "St. Cloud",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1042,
      "lat": 45.5901985168,
      "lon": -94.2043991089,
      "tz": "America\/Chicago"
  },
  "MN98": {
      "icao": "MN98",
      "iata": "",
      "name": "Aero-Plain Airport",
      "city": "St Francis",
      "state": "Minnesota",
      "country": "US",
      "elevation": 931,
      "lat": 45.4113998413,
      "lon": -93.3846969604,
      "tz": "America\/Chicago"
  },
  "MN99": {
      "icao": "MN99",
      "iata": "",
      "name": "Serenity Airport",
      "city": "Lester Prairie",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1050,
      "lat": 44.915599823,
      "lon": -94.0888977051,
      "tz": "America\/Chicago"
  },
  "MNAL": {
      "icao": "MNAL",
      "iata": "",
      "name": "Alamicamba Airport",
      "city": "Alamicamba",
      "state": "Atlantico-Norte-(RAAN)",
      "country": "NI",
      "elevation": 65,
      "lat": 13.5122995377,
      "lon": -84.2471008301,
      "tz": "America\/Managua"
  },
  "MNAM": {
      "icao": "MNAM",
      "iata": "",
      "name": "Altamira Airport",
      "city": "Altamira",
      "state": "Boaco",
      "country": "NI",
      "elevation": 121,
      "lat": 12.1106004715,
      "lon": -85.7604980469,
      "tz": "America\/Managua"
  },
  "MNBC": {
      "icao": "MNBC",
      "iata": "",
      "name": "Boaco Airport",
      "city": "Boaco",
      "state": "Boaco",
      "country": "NI",
      "elevation": 1184,
      "lat": 12.4690999985,
      "lon": -85.6625976563,
      "tz": "America\/Managua"
  },
  "MNBL": {
      "icao": "MNBL",
      "iata": "BEF",
      "name": "Bluefields Airport",
      "city": "Bluefileds",
      "state": "Atlantico-Sur",
      "country": "NI",
      "elevation": 20,
      "lat": 11.9910001755,
      "lon": -83.7741012573,
      "tz": "America\/Managua"
  },
  "MNBR": {
      "icao": "MNBR",
      "iata": "",
      "name": "Los Brasiles Airport",
      "city": "Los Brasiles",
      "state": "Managua",
      "country": "NI",
      "elevation": 262,
      "lat": 12.1899995804,
      "lon": -86.3538970947,
      "tz": "America\/Managua"
  },
  "MNBZ": {
      "icao": "MNBZ",
      "iata": "BZA",
      "name": "San Pedro Airport",
      "city": "Bonanza",
      "state": "",
      "country": "NI",
      "elevation": 600,
      "lat": 13.9499998093,
      "lon": -84.5999984741,
      "tz": "America\/Managua"
  },
  "MNCH": {
      "icao": "MNCH",
      "iata": "",
      "name": "Chinandega",
      "city": "Chinandega",
      "state": "Chinandega",
      "country": "NI",
      "elevation": 174,
      "lat": 12.6400003433,
      "lon": -87.1373977661,
      "tz": "America\/Managua"
  },
  "MNCI": {
      "icao": "MNCI",
      "iata": "RNI",
      "name": "Corn Island",
      "city": "Corn Island",
      "state": "",
      "country": "NI",
      "elevation": 1,
      "lat": 12.162899971,
      "lon": -83.0637969971,
      "tz": "America\/Managua"
  },
  "MNCT": {
      "icao": "MNCT",
      "iata": "",
      "name": "Corinto Airport",
      "city": "Chinandega",
      "state": "Chinandega",
      "country": "NI",
      "elevation": 1,
      "lat": 12.4788999557,
      "lon": -87.1772003174,
      "tz": "America\/Managua"
  },
  "MNDM": {
      "icao": "MNDM",
      "iata": "",
      "name": "Dos Montes Airport",
      "city": "Dos Montes",
      "state": "Matagalpa",
      "country": "NI",
      "elevation": 1469,
      "lat": 12.8004999161,
      "lon": -86.1483001709,
      "tz": "America\/Managua"
  },
  "MNEP": {
      "icao": "MNEP",
      "iata": "",
      "name": "La Esperanza Airport",
      "city": "La Esperanza",
      "state": "Atlantico-Sur",
      "country": "NI",
      "elevation": 88,
      "lat": 12.1999998093,
      "lon": -84.2833328247,
      "tz": "America\/Managua"
  },
  "MNES": {
      "icao": "MNES",
      "iata": "",
      "name": "Esteli Airport",
      "city": "Esteli",
      "state": "Esteli",
      "country": "NI",
      "elevation": 2811,
      "lat": 13.111700058,
      "lon": -86.3632965088,
      "tz": "America\/Managua"
  },
  "MNFC": {
      "icao": "MNFC",
      "iata": "",
      "name": "Punta Huete (Panchito) Airport",
      "city": "Punta Huete",
      "state": "Managua",
      "country": "NI",
      "elevation": 217,
      "lat": 12.3534002304,
      "lon": -86.1828994751,
      "tz": "America\/Managua"
  },
  "MNFF": {
      "icao": "MNFF",
      "iata": "",
      "name": "El Bluff Airport",
      "city": "El Bluff",
      "state": "Atlantico-Sur",
      "country": "NI",
      "elevation": 1,
      "lat": 11.9942998886,
      "lon": -83.6878967285,
      "tz": "America\/Managua"
  },
  "MNHG": {
      "icao": "MNHG",
      "iata": "",
      "name": "Hato Grande Airport",
      "city": "Hato Grande",
      "state": "Chontales",
      "country": "NI",
      "elevation": 177,
      "lat": 11.9813995361,
      "lon": -85.4327011108,
      "tz": "America\/Managua"
  },
  "MNJG": {
      "icao": "MNJG",
      "iata": "",
      "name": "Jinotega",
      "city": "Jinotega",
      "state": "Jinotega",
      "country": "NI",
      "elevation": 3232,
      "lat": 13.0857000351,
      "lon": -85.987701416,
      "tz": "America\/Managua"
  },
  "MNJU": {
      "icao": "MNJU",
      "iata": "",
      "name": "Juigalpa",
      "city": "Juigalpa",
      "state": "Chontales",
      "country": "NI",
      "elevation": 295,
      "lat": 12.1000003815,
      "lon": -85.3666687012,
      "tz": "America\/Managua"
  },
  "MNKW": {
      "icao": "MNKW",
      "iata": "",
      "name": "Karawala Airport",
      "city": "Karawala",
      "state": "Atlantico-Sur",
      "country": "NI",
      "elevation": 3,
      "lat": 12.8921003342,
      "lon": -83.582901001,
      "tz": "America\/Managua"
  },
  "MNLN": {
      "icao": "MNLN",
      "iata": "",
      "name": "Leon (Fanor Urroz) Airport",
      "city": "Leon",
      "state": "Leon",
      "country": "NI",
      "elevation": 328,
      "lat": 12.4292001724,
      "lon": -86.9028015137,
      "tz": "America\/Managua"
  },
  "MNMA": {
      "icao": "MNMA",
      "iata": "",
      "name": "Macantaca Airport",
      "city": "Macantaca",
      "state": "Atlantico-Norte-(RAAN)",
      "country": "NI",
      "elevation": 55,
      "lat": 13.2451000214,
      "lon": -84.0839004517,
      "tz": "America\/Managua"
  },
  "MNMG": {
      "icao": "MNMG",
      "iata": "MGA",
      "name": "Augusto C. Sandino (Managua) International Airport",
      "city": "Managua",
      "state": "Managua",
      "country": "NI",
      "elevation": 194,
      "lat": 12.141500473,
      "lon": -86.1681976318,
      "tz": "America\/Managua"
  },
  "MNMR": {
      "icao": "MNMR",
      "iata": "",
      "name": "Montelimar Airport",
      "city": "Montelimar",
      "state": "Managua",
      "country": "NI",
      "elevation": 94,
      "lat": 11.8051996231,
      "lon": -86.5112991333,
      "tz": "America\/Managua"
  },
  "MNNG": {
      "icao": "MNNG",
      "iata": "NVG",
      "name": "Nueva Guinea Airport",
      "city": "Nueva Guinea",
      "state": "",
      "country": "NI",
      "elevation": 606,
      "lat": 11.6666669846,
      "lon": -84.4499969482,
      "tz": "America\/Managua"
  },
  "MNPC": {
      "icao": "MNPC",
      "iata": "PUZ",
      "name": "Puerto Cabezas Airport",
      "city": "Puerto Cabezas",
      "state": "Atlantico-Norte-(RAAN)",
      "country": "NI",
      "elevation": 52,
      "lat": 14.0472002029,
      "lon": -83.3867034912,
      "tz": "America\/Managua"
  },
  "MNPP": {
      "icao": "MNPP",
      "iata": "",
      "name": "El Papalonal Airport",
      "city": "El Papalonal",
      "state": "Leon",
      "country": "NI",
      "elevation": 141,
      "lat": 12.4657001495,
      "lon": -86.4679031372,
      "tz": "America\/Managua"
  },
  "MNRS": {
      "icao": "MNRS",
      "iata": "",
      "name": "Rivas",
      "city": "Rivas",
      "state": "Rivas",
      "country": "NI",
      "elevation": 174,
      "lat": 11.4277000427,
      "lon": -85.8385009766,
      "tz": "America\/Managua"
  },
  "MNRT": {
      "icao": "MNRT",
      "iata": "RFS",
      "name": "Rosita Airport",
      "city": "La Rosita",
      "state": "Atlantico-Norte-(RAAN)",
      "country": "NI",
      "elevation": 193,
      "lat": 13.8896999359,
      "lon": -84.4088973999,
      "tz": "America\/Managua"
  },
  "MNSC": {
      "icao": "MNSC",
      "iata": "NCR",
      "name": "San Carlos",
      "city": "San Carlos",
      "state": "Granada",
      "country": "NI",
      "elevation": 91,
      "lat": 11.1333999634,
      "lon": -84.7699966431,
      "tz": "America\/Managua"
  },
  "MNSI": {
      "icao": "MNSI",
      "iata": "SIU",
      "name": "Siuna",
      "city": "Siuna",
      "state": "",
      "country": "NI",
      "elevation": 606,
      "lat": 13.7272224426,
      "lon": -84.7777786255,
      "tz": "America\/Managua"
  },
  "MNWP": {
      "icao": "MNWP",
      "iata": "WSP",
      "name": "Waspam Airport",
      "city": "Waspam",
      "state": "",
      "country": "NI",
      "elevation": 98,
      "lat": 14.7391996384,
      "lon": -83.9693984985,
      "tz": "America\/Managua"
  },
  "MO00": {
      "icao": "MO00",
      "iata": "",
      "name": "Turkey Mountain Estates Airport",
      "city": "Shell Knob",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 36.5917015076,
      "lon": -93.6669006348,
      "tz": "America\/Chicago"
  },
  "MO01": {
      "icao": "MO01",
      "iata": "",
      "name": "Cuinche Airport",
      "city": "Strafford",
      "state": "Missouri",
      "country": "US",
      "elevation": 1475,
      "lat": 37.25,
      "lon": -93.1060028076,
      "tz": "America\/Chicago"
  },
  "MO02": {
      "icao": "MO02",
      "iata": "",
      "name": "Morgan Airport",
      "city": "Rolla",
      "state": "Missouri",
      "country": "US",
      "elevation": 1018,
      "lat": 37.9166984558,
      "lon": -91.6835021973,
      "tz": "America\/Chicago"
  },
  "MO03": {
      "icao": "MO03",
      "iata": "",
      "name": "Moders Airport",
      "city": "House Springs",
      "state": "Missouri",
      "country": "US",
      "elevation": 465,
      "lat": 38.4241981506,
      "lon": -90.582901001,
      "tz": "America\/Chicago"
  },
  "MO04": {
      "icao": "MO04",
      "iata": "",
      "name": "Lyell Airport",
      "city": "Hunnewell",
      "state": "Missouri",
      "country": "US",
      "elevation": 769,
      "lat": 39.7167015076,
      "lon": -91.8501968384,
      "tz": "America\/Chicago"
  },
  "MO07": {
      "icao": "MO07",
      "iata": "",
      "name": "Adventures Aloft Airport",
      "city": "Jonesburg",
      "state": "Missouri",
      "country": "US",
      "elevation": 871,
      "lat": 38.8725013733,
      "lon": -91.300201416,
      "tz": "America\/Chicago"
  },
  "MO08": {
      "icao": "MO08",
      "iata": "",
      "name": "Homan Field",
      "city": "Sedalia",
      "state": "Missouri",
      "country": "US",
      "elevation": 885,
      "lat": 38.6627998352,
      "lon": -93.3327026367,
      "tz": "America\/Chicago"
  },
  "MO09": {
      "icao": "MO09",
      "iata": "",
      "name": "Sharpe Farms Airport",
      "city": "Lewistown",
      "state": "Missouri",
      "country": "US",
      "elevation": 702,
      "lat": 40.0237007141,
      "lon": -91.8738021851,
      "tz": "America\/Chicago"
  },
  "MO10": {
      "icao": "MO10",
      "iata": "",
      "name": "Hawk Air Airport",
      "city": "Sturgeon",
      "state": "Missouri",
      "country": "US",
      "elevation": 870,
      "lat": 39.2042999268,
      "lon": -92.2623977661,
      "tz": "America\/Chicago"
  },
  "MO11": {
      "icao": "MO11",
      "iata": "",
      "name": "Barron Aviation Airport",
      "city": "Perry",
      "state": "Missouri",
      "country": "US",
      "elevation": 745,
      "lat": 39.4005012512,
      "lon": -91.582901001,
      "tz": "America\/Chicago"
  },
  "MO13": {
      "icao": "MO13",
      "iata": "",
      "name": "Blue Hollow Airpark",
      "city": "Eldridge",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 37.7975997925,
      "lon": -92.8292007446,
      "tz": "America\/Chicago"
  },
  "MO14": {
      "icao": "MO14",
      "iata": "",
      "name": "Marshall Field",
      "city": "Fair Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 1166,
      "lat": 37.422778,
      "lon": -93.208611,
      "tz": "America\/Chicago"
  },
  "MO15": {
      "icao": "MO15",
      "iata": "",
      "name": "Beckner Field",
      "city": "Marshfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1285,
      "lat": 37.3875007629,
      "lon": -92.9573974609,
      "tz": "America\/Chicago"
  },
  "MO16": {
      "icao": "MO16",
      "iata": "",
      "name": "Baugh Flight Park Ultralightport",
      "city": "Avilla",
      "state": "Missouri",
      "country": "US",
      "elevation": 984,
      "lat": 37.163898468,
      "lon": -94.168296814,
      "tz": "America\/Chicago"
  },
  "MO17": {
      "icao": "MO17",
      "iata": "",
      "name": "Garst Airport",
      "city": "Watson",
      "state": "Missouri",
      "country": "US",
      "elevation": 905,
      "lat": 40.4791984558,
      "lon": -95.6461029053,
      "tz": "America\/Chicago"
  },
  "MO18": {
      "icao": "MO18",
      "iata": "",
      "name": "Clevenger Airport",
      "city": "Blue Springs",
      "state": "Missouri",
      "country": "US",
      "elevation": 990,
      "lat": 38.9472007751,
      "lon": -94.2490997314,
      "tz": "America\/Chicago"
  },
  "MO22": {
      "icao": "MO22",
      "iata": "",
      "name": "Jta Asbell Field",
      "city": "Carl Junction",
      "state": "Missouri",
      "country": "US",
      "elevation": 940,
      "lat": 37.1916999817,
      "lon": -94.549697876,
      "tz": "America\/Chicago"
  },
  "MO23": {
      "icao": "MO23",
      "iata": "",
      "name": "Phillips Field",
      "city": "California",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 38.6333999634,
      "lon": -92.5335006714,
      "tz": "America\/Chicago"
  },
  "MO24": {
      "icao": "MO24",
      "iata": "",
      "name": "Lonesome Sky Airport",
      "city": "Craig",
      "state": "Missouri",
      "country": "US",
      "elevation": 868,
      "lat": 40.1427993774,
      "lon": -95.3882980347,
      "tz": "America\/Chicago"
  },
  "MO25": {
      "icao": "MO25",
      "iata": "",
      "name": "Show Me The Sky Airport",
      "city": "Barnard",
      "state": "Missouri",
      "country": "US",
      "elevation": 1050,
      "lat": 40.1375007629,
      "lon": -94.9255981445,
      "tz": "America\/Chicago"
  },
  "MO26": {
      "icao": "MO26",
      "iata": "",
      "name": "Peterson Farm Airport",
      "city": "Kearney",
      "state": "Missouri",
      "country": "US",
      "elevation": 860,
      "lat": 39.393901825,
      "lon": -94.3529968262,
      "tz": "America\/Chicago"
  },
  "MO27": {
      "icao": "MO27",
      "iata": "",
      "name": "Ccc Airport",
      "city": "Cameron",
      "state": "Missouri",
      "country": "US",
      "elevation": 984,
      "lat": 39.7352981567,
      "lon": -94.1927032471,
      "tz": "America\/Chicago"
  },
  "MO29": {
      "icao": "MO29",
      "iata": "",
      "name": "Inter-State Airport",
      "city": "Pleasant Hill",
      "state": "Missouri",
      "country": "US",
      "elevation": 960,
      "lat": 38.7722015381,
      "lon": -94.3348999023,
      "tz": "America\/Chicago"
  },
  "MO30": {
      "icao": "MO30",
      "iata": "",
      "name": "Harbour Airport",
      "city": "Gravois Mills",
      "state": "Missouri",
      "country": "US",
      "elevation": 823,
      "lat": 38.2699012756,
      "lon": -92.8115005493,
      "tz": "America\/Chicago"
  },
  "MO31": {
      "icao": "MO31",
      "iata": "",
      "name": "Malina Airport",
      "city": "Rogersville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1350,
      "lat": 37.0494995117,
      "lon": -93.0724029541,
      "tz": "America\/Chicago"
  },
  "MO32": {
      "icao": "MO32",
      "iata": "",
      "name": "Table Rock Airport",
      "city": "Golden",
      "state": "Missouri",
      "country": "US",
      "elevation": 1053,
      "lat": 36.5287017822,
      "lon": -93.676902771,
      "tz": "America\/Chicago"
  },
  "MO33": {
      "icao": "MO33",
      "iata": "",
      "name": "Barlet's Base Airport",
      "city": "Carthage",
      "state": "Missouri",
      "country": "US",
      "elevation": 1068,
      "lat": 37.1291999817,
      "lon": -94.2537994385,
      "tz": "America\/Chicago"
  },
  "MO34": {
      "icao": "MO34",
      "iata": "",
      "name": "Ellingsen Field",
      "city": "Everton",
      "state": "Missouri",
      "country": "US",
      "elevation": 1115,
      "lat": 37.3596992493,
      "lon": -93.6624984741,
      "tz": "America\/Chicago"
  },
  "MO35": {
      "icao": "MO35",
      "iata": "",
      "name": "Harvey Airport",
      "city": "Eolia",
      "state": "Missouri",
      "country": "US",
      "elevation": 710,
      "lat": 39.2127990723,
      "lon": -90.9173965454,
      "tz": "America\/Chicago"
  },
  "MO36": {
      "icao": "MO36",
      "iata": "",
      "name": "Riddle's Roost Airport",
      "city": "Foristell",
      "state": "Missouri",
      "country": "US",
      "elevation": 650,
      "lat": 38.8320007324,
      "lon": -90.9251022339,
      "tz": "America\/Chicago"
  },
  "MO37": {
      "icao": "MO37",
      "iata": "",
      "name": "Ridgeview Ranch Airport",
      "city": "Harrisonville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 38.7230987549,
      "lon": -94.3818969727,
      "tz": "America\/Chicago"
  },
  "MO39": {
      "icao": "MO39",
      "iata": "",
      "name": "Baldwin Airport",
      "city": "Silex",
      "state": "Missouri",
      "country": "US",
      "elevation": 505,
      "lat": 39.1133995056,
      "lon": -91.0418014526,
      "tz": "America\/Chicago"
  },
  "MO42": {
      "icao": "MO42",
      "iata": "",
      "name": "Wileys Air Strip",
      "city": "Gorin",
      "state": "Missouri",
      "country": "US",
      "elevation": 662,
      "lat": 40.3763999939,
      "lon": -92.0724029541,
      "tz": "America\/Chicago"
  },
  "MO43": {
      "icao": "MO43",
      "iata": "",
      "name": "Wilkins Airport",
      "city": "Montgomery City",
      "state": "Missouri",
      "country": "US",
      "elevation": 835,
      "lat": 38.96849823,
      "lon": -91.4434967041,
      "tz": "America\/Chicago"
  },
  "MO45": {
      "icao": "MO45",
      "iata": "",
      "name": "Sky-Vu Airport",
      "city": "Montgomery City",
      "state": "Missouri",
      "country": "US",
      "elevation": 800,
      "lat": 38.89220047,
      "lon": -91.5660018921,
      "tz": "America\/Chicago"
  },
  "MO46": {
      "icao": "MO46",
      "iata": "",
      "name": "Walker Airport",
      "city": "Louisana",
      "state": "Missouri",
      "country": "US",
      "elevation": 510,
      "lat": 39.5203018188,
      "lon": -91.1717987061,
      "tz": "America\/Chicago"
  },
  "MO49": {
      "icao": "MO49",
      "iata": "",
      "name": "Five Mile Airport",
      "city": "Joplin",
      "state": "Missouri",
      "country": "US",
      "elevation": 1100,
      "lat": 36.9702987671,
      "lon": -94.5766983032,
      "tz": "America\/Chicago"
  },
  "MO51": {
      "icao": "MO51",
      "iata": "",
      "name": "Schaback Strip",
      "city": "Weston",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.4235992432,
      "lon": -94.8477020264,
      "tz": "America\/Chicago"
  },
  "MO52": {
      "icao": "MO52",
      "iata": "",
      "name": "Skyriders Airport",
      "city": "Wheatland",
      "state": "Missouri",
      "country": "US",
      "elevation": 900,
      "lat": 37.8666992188,
      "lon": -93.3834991455,
      "tz": "America\/Chicago"
  },
  "MO53": {
      "icao": "MO53",
      "iata": "",
      "name": "Meadows Airport",
      "city": "Bethany",
      "state": "Missouri",
      "country": "US",
      "elevation": 1010,
      "lat": 40.2332992554,
      "lon": -93.9336013794,
      "tz": "America\/Chicago"
  },
  "MO54": {
      "icao": "MO54",
      "iata": "",
      "name": "Dudley Airport",
      "city": "Dudley",
      "state": "Missouri",
      "country": "US",
      "elevation": 310,
      "lat": 36.7820014954,
      "lon": -90.0889968872,
      "tz": "America\/Chicago"
  },
  "MO56": {
      "icao": "MO56",
      "iata": "",
      "name": "Lost Mine Airport",
      "city": "Theodosia",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 36.5141983032,
      "lon": -92.6401977539,
      "tz": "America\/Chicago"
  },
  "MO57": {
      "icao": "MO57",
      "iata": "",
      "name": "Henderson Mounds E B G Airport",
      "city": "East Prairie",
      "state": "Missouri",
      "country": "US",
      "elevation": 295,
      "lat": 36.7070007324,
      "lon": -89.4666976929,
      "tz": "America\/Chicago"
  },
  "MO58": {
      "icao": "MO58",
      "iata": "",
      "name": "Waldemer Flying W Ranch Airport",
      "city": "Fairdealing",
      "state": "Missouri",
      "country": "US",
      "elevation": 460,
      "lat": 36.6584014893,
      "lon": -90.6404037476,
      "tz": "America\/Chicago"
  },
  "MO59": {
      "icao": "MO59",
      "iata": "",
      "name": "K & N Field",
      "city": "Moscow Mills",
      "state": "Missouri",
      "country": "US",
      "elevation": 500,
      "lat": 38.9145011902,
      "lon": -90.900100708,
      "tz": "America\/Chicago"
  },
  "MO61": {
      "icao": "MO61",
      "iata": "",
      "name": "Bel-Voir Acres Airport",
      "city": "Golden",
      "state": "Missouri",
      "country": "US",
      "elevation": 1139,
      "lat": 36.5583992004,
      "lon": -93.6418991089,
      "tz": "America\/Chicago"
  },
  "MO62": {
      "icao": "MO62",
      "iata": "",
      "name": "Hibbs Farm Airport",
      "city": "Sheridan",
      "state": "Missouri",
      "country": "US",
      "elevation": 1150,
      "lat": 40.5102996826,
      "lon": -94.5815963745,
      "tz": "America\/Chicago"
  },
  "MO64": {
      "icao": "MO64",
      "iata": "",
      "name": "Kimberling Airport",
      "city": "Kimberling City",
      "state": "Missouri",
      "country": "US",
      "elevation": 1050,
      "lat": 36.6097984314,
      "lon": -93.4446029663,
      "tz": "America\/Chicago"
  },
  "MO65": {
      "icao": "MO65",
      "iata": "",
      "name": "Misty Meadows Airport",
      "city": "Kirbyville",
      "state": "Missouri",
      "country": "US",
      "elevation": 940,
      "lat": 36.5834007263,
      "lon": -93.1029968262,
      "tz": "America\/Chicago"
  },
  "MO66": {
      "icao": "MO66",
      "iata": "",
      "name": "Linden Air Airport",
      "city": "Ozark",
      "state": "Missouri",
      "country": "US",
      "elevation": 1280,
      "lat": 37.059167,
      "lon": -93.145556,
      "tz": "America\/Chicago"
  },
  "MO67": {
      "icao": "MO67",
      "iata": "",
      "name": "Hayes Field",
      "city": "Poplar Bluff",
      "state": "Missouri",
      "country": "US",
      "elevation": 323,
      "lat": 36.7112007141,
      "lon": -90.3140029907,
      "tz": "America\/Chicago"
  },
  "MO68": {
      "icao": "MO68",
      "iata": "",
      "name": "Eads Ridge Airport",
      "city": "Fair Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 1310,
      "lat": 37.4053001404,
      "lon": -93.0996017456,
      "tz": "America\/Chicago"
  },
  "MO71": {
      "icao": "MO71",
      "iata": "",
      "name": "Block Air Village Airport",
      "city": "Holt",
      "state": "Missouri",
      "country": "US",
      "elevation": 1030,
      "lat": 39.4631004333,
      "lon": -94.418296814,
      "tz": "America\/Chicago"
  },
  "MO72": {
      "icao": "MO72",
      "iata": "",
      "name": "Flying 'J' Ranch Airport",
      "city": "Van Buren",
      "state": "Missouri",
      "country": "US",
      "elevation": 690,
      "lat": 37.0402984619,
      "lon": -90.8667984009,
      "tz": "America\/Chicago"
  },
  "MO73": {
      "icao": "MO73",
      "iata": "",
      "name": "Rolling Shoals Farm Airport",
      "city": "Williamsville",
      "state": "Missouri",
      "country": "US",
      "elevation": 450,
      "lat": 36.9875984192,
      "lon": -90.5584030151,
      "tz": "America\/Chicago"
  },
  "MO74": {
      "icao": "MO74",
      "iata": "",
      "name": "Cedar Creek Airport",
      "city": "Columbia",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 38.9222984314,
      "lon": -92.1696014404,
      "tz": "America\/Chicago"
  },
  "MO75": {
      "icao": "MO75",
      "iata": "",
      "name": "Brownsberger Airport",
      "city": "Montrose",
      "state": "Missouri",
      "country": "US",
      "elevation": 794,
      "lat": 38.3050003052,
      "lon": -94.0532989502,
      "tz": "America\/Chicago"
  },
  "MO77": {
      "icao": "MO77",
      "iata": "",
      "name": "Arnika Ranch Airport",
      "city": "Ava",
      "state": "Missouri",
      "country": "US",
      "elevation": 1320,
      "lat": 36.9751014709,
      "lon": -92.5427017212,
      "tz": "America\/Chicago"
  },
  "MO78": {
      "icao": "MO78",
      "iata": "",
      "name": "Stickle Cattle Farms Airport",
      "city": "Belle",
      "state": "Missouri",
      "country": "US",
      "elevation": 700,
      "lat": 38.3166999817,
      "lon": -91.7835006714,
      "tz": "America\/Chicago"
  },
  "MO79": {
      "icao": "MO79",
      "iata": "",
      "name": "Sky Ranch Ii Airport",
      "city": "Belleview",
      "state": "Missouri",
      "country": "US",
      "elevation": 1060,
      "lat": 37.6791992188,
      "lon": -90.7154006958,
      "tz": "America\/Chicago"
  },
  "MO80": {
      "icao": "MO80",
      "iata": "",
      "name": "Johnston's Nest Aerodrome",
      "city": "Mountain Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 1482,
      "lat": 37.1605987549,
      "lon": -92.3235015869,
      "tz": "America\/Chicago"
  },
  "MO81": {
      "icao": "MO81",
      "iata": "",
      "name": "Dove Airstrip",
      "city": "Bismarck",
      "state": "Missouri",
      "country": "US",
      "elevation": 1080,
      "lat": 37.6708984375,
      "lon": -90.6147994995,
      "tz": "America\/Chicago"
  },
  "MO82": {
      "icao": "MO82",
      "iata": "",
      "name": "Howard Airport",
      "city": "Gorin",
      "state": "Missouri",
      "country": "US",
      "elevation": 740,
      "lat": 40.4089012146,
      "lon": -92.0568008423,
      "tz": "America\/Chicago"
  },
  "MO83": {
      "icao": "MO83",
      "iata": "",
      "name": "Widmark Airport",
      "city": "Green City",
      "state": "Missouri",
      "country": "US",
      "elevation": 1045,
      "lat": 40.2531013489,
      "lon": -92.9549026489,
      "tz": "America\/Chicago"
  },
  "MO84": {
      "icao": "MO84",
      "iata": "",
      "name": "Viburnum Airport",
      "city": "Viburnum",
      "state": "Missouri",
      "country": "US",
      "elevation": 1272,
      "lat": 37.7167015076,
      "lon": -91.1334991455,
      "tz": "America\/Chicago"
  },
  "MO85": {
      "icao": "MO85",
      "iata": "",
      "name": "Wells Airport",
      "city": "Marshfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1530,
      "lat": 37.251701355,
      "lon": -92.8338012695,
      "tz": "America\/Chicago"
  },
  "MO86": {
      "icao": "MO86",
      "iata": "",
      "name": "Sanctuary Airport",
      "city": "Bellflower",
      "state": "Missouri",
      "country": "US",
      "elevation": 750,
      "lat": 38.9597015381,
      "lon": -91.3039016724,
      "tz": "America\/Chicago"
  },
  "MO87": {
      "icao": "MO87",
      "iata": "",
      "name": "Powis Airport",
      "city": "Oak Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 879,
      "lat": 38.9458007813,
      "lon": -94.1619033813,
      "tz": "America\/Chicago"
  },
  "MO88": {
      "icao": "MO88",
      "iata": "",
      "name": "Feutz Airport",
      "city": "Hatton",
      "state": "Missouri",
      "country": "US",
      "elevation": 890,
      "lat": 39.0999984741,
      "lon": -92.0093002319,
      "tz": "America\/Chicago"
  },
  "MO89": {
      "icao": "MO89",
      "iata": "",
      "name": "Bar-Vik Airport",
      "city": "Laurie",
      "state": "Missouri",
      "country": "US",
      "elevation": 930,
      "lat": 38.1741981506,
      "lon": -92.8584976196,
      "tz": "America\/Chicago"
  },
  "MO93": {
      "icao": "MO93",
      "iata": "",
      "name": "Keeven Air Ranch Airport",
      "city": "Hawk Point",
      "state": "Missouri",
      "country": "US",
      "elevation": 700,
      "lat": 39.044819,
      "lon": -91.148094,
      "tz": "America\/Chicago"
  },
  "MO94": {
      "icao": "MO94",
      "iata": "",
      "name": "Starr Airport",
      "city": "Trimble",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 39.5,
      "lon": -94.5802001953,
      "tz": "America\/Chicago"
  },
  "MO95": {
      "icao": "MO95",
      "iata": "",
      "name": "Hawkins Airport",
      "city": "Carrollton",
      "state": "Missouri",
      "country": "US",
      "elevation": 720,
      "lat": 39.4416999817,
      "lon": -93.6622009277,
      "tz": "America\/Chicago"
  },
  "MO96": {
      "icao": "MO96",
      "iata": "",
      "name": "Ray Johnson Inc Airport",
      "city": "Centerville",
      "state": "Missouri",
      "country": "US",
      "elevation": 950,
      "lat": 37.4161987305,
      "lon": -90.9635009766,
      "tz": "America\/Chicago"
  },
  "MO98": {
      "icao": "MO98",
      "iata": "",
      "name": "Williams Airport",
      "city": "Cleveland",
      "state": "Missouri",
      "country": "US",
      "elevation": 985,
      "lat": 38.7131004333,
      "lon": -94.5849990845,
      "tz": "America\/Chicago"
  },
  "MO99": {
      "icao": "MO99",
      "iata": "",
      "name": "Miller Airport",
      "city": "Climax Springs",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 38.1164016724,
      "lon": -93.0771026611,
      "tz": "America\/Chicago"
  },
  "MP00": {
      "icao": "MP00",
      "iata": "",
      "name": "Cap Justiniano Montenegro Airport",
      "city": "Pedasi",
      "state": "Los-Santos",
      "country": "PA",
      "elevation": 16,
      "lat": 7.5568799973,
      "lon": -80.0233001709,
      "tz": "America\/Panama"
  },
  "MP01": {
      "icao": "MP01",
      "iata": "",
      "name": "Finca Ceiba Airport",
      "city": "Finca Jagua",
      "state": "Chiriqui",
      "country": "PA",
      "elevation": 52,
      "lat": 8.3549995422,
      "lon": -82.8364028931,
      "tz": "America\/Panama"
  },
  "MP02": {
      "icao": "MP02",
      "iata": "",
      "name": "Finca 45 Airport",
      "city": "Dos Canos",
      "state": "Bocas-del-Toro",
      "country": "PA",
      "elevation": 56,
      "lat": 9.5433301926,
      "lon": -82.7338027954,
      "tz": "America\/Costa_Rica"
  },
  "MP03": {
      "icao": "MP03",
      "iata": "",
      "name": "La Cabezona Airport",
      "city": "Guarumal",
      "state": "Chiriqui",
      "country": "PA",
      "elevation": 31,
      "lat": 8.3383903503,
      "lon": -82.5035018921,
      "tz": "America\/Panama"
  },
  "MP17": {
      "icao": "MP17",
      "iata": "",
      "name": "Finca 67 Airport",
      "city": "Changuinola",
      "state": "Bocas-del-Toro",
      "country": "PA",
      "elevation": 30,
      "lat": 9.4344100952,
      "lon": -82.4990997314,
      "tz": "America\/Panama"
  },
  "MP18": {
      "icao": "MP18",
      "iata": "",
      "name": "Penonome Airport",
      "city": "Penonome",
      "state": "Cocle",
      "country": "PA",
      "elevation": 282,
      "lat": 8.5038299561,
      "lon": -80.3602981567,
      "tz": "America\/Panama"
  },
  "MP21": {
      "icao": "MP21",
      "iata": "",
      "name": "Alvaro Berroa Airport",
      "city": "Nueva California",
      "state": "Chiriqui",
      "country": "PA",
      "elevation": 5000,
      "lat": 8.7703895569,
      "lon": -82.6643981934,
      "tz": "America\/Panama"
  },
  "MP22": {
      "icao": "MP22",
      "iata": "",
      "name": "Ingenio Santa Rosa Airport",
      "city": "Ingenio Santa Rosa",
      "state": "Cocle",
      "country": "PA",
      "elevation": 109,
      "lat": 8.1952199936,
      "lon": -80.6586990356,
      "tz": "America\/Panama"
  },
  "MP23": {
      "icao": "MP23",
      "iata": "",
      "name": "Calzada Larga Airport",
      "city": "Calzada Larga",
      "state": "Panama",
      "country": "PA",
      "elevation": 394,
      "lat": 9.1662797928,
      "lon": -79.5450973511,
      "tz": "America\/Panama"
  },
  "MP24": {
      "icao": "MP24",
      "iata": "",
      "name": "Chame Airport",
      "city": "La Paz",
      "state": "Panama-Oeste",
      "country": "PA",
      "elevation": 141,
      "lat": 8.5884599686,
      "lon": -79.8897018433,
      "tz": "America\/Panama"
  },
  "MP26": {
      "icao": "MP26",
      "iata": "",
      "name": "Punta Cocos Airport",
      "city": "Punta Cocos",
      "state": "Panama",
      "country": "PA",
      "elevation": 66,
      "lat": 8.2248497009,
      "lon": -78.9044036865,
      "tz": "America\/Panama"
  },
  "MP27": {
      "icao": "MP27",
      "iata": "",
      "name": "Deborah Airport",
      "city": "Guabito",
      "state": "Bocas-del-Toro",
      "country": "PA",
      "elevation": 20,
      "lat": 9.5161399841,
      "lon": -82.5954971313,
      "tz": "America\/Costa_Rica"
  },
  "MPBO": {
      "icao": "MPBO",
      "iata": "BOC",
      "name": "Bocas Del Toro International Airport",
      "city": "Isla Colon",
      "state": "Bocas-del-Toro",
      "country": "PA",
      "elevation": 10,
      "lat": 9.3408498764,
      "lon": -82.2508010864,
      "tz": "America\/Panama"
  },
  "MPCE": {
      "icao": "MPCE",
      "iata": "CTD",
      "name": "Alonso Valderrama Airport",
      "city": "Chitre",
      "state": "Herrera",
      "country": "PA",
      "elevation": 33,
      "lat": 7.9878401756,
      "lon": -80.4096984863,
      "tz": "America\/Panama"
  },
  "MPCH": {
      "icao": "MPCH",
      "iata": "CHX",
      "name": "Cap Manuel Nino International Airport",
      "city": "Changuinola",
      "state": "Bocas-del-Toro",
      "country": "PA",
      "elevation": 19,
      "lat": 9.4586400986,
      "lon": -82.5167999268,
      "tz": "America\/Panama"
  },
  "MPDA": {
      "icao": "MPDA",
      "iata": "DAV",
      "name": "Enrique Malek International Airport",
      "city": "David",
      "state": "Chiriqui",
      "country": "PA",
      "elevation": 89,
      "lat": 8.390999794,
      "lon": -82.4349975586,
      "tz": "America\/Panama"
  },
  "MPEJ": {
      "icao": "MPEJ",
      "iata": "ONX",
      "name": "Enrique Adolfo Jimenez Airport",
      "city": "Colon",
      "state": "Colon",
      "country": "PA",
      "elevation": 25,
      "lat": 9.3566398621,
      "lon": -79.867401123,
      "tz": "America\/Panama"
  },
  "MPFS": {
      "icao": "MPFS",
      "iata": "",
      "name": "Fort Sherman Airport",
      "city": "Fort Sherman",
      "state": "Colon",
      "country": "PA",
      "elevation": 10,
      "lat": 9.3650903702,
      "lon": -79.949798584,
      "tz": "America\/Panama"
  },
  "MPHO": {
      "icao": "MPHO",
      "iata": "BLB",
      "name": "Howard Airport",
      "city": "Panama City",
      "state": "Panama",
      "country": "PA",
      "elevation": 52,
      "lat": 8.9147901535,
      "lon": -79.5996017456,
      "tz": "America\/Panama"
  },
  "MPJE": {
      "icao": "MPJE",
      "iata": "JQE",
      "name": "Jaque Airport",
      "city": "Jaque",
      "state": "Darien",
      "country": "PA",
      "elevation": 29,
      "lat": 7.5177798271,
      "lon": -78.1572036743,
      "tz": "America\/Panama"
  },
  "MPLP": {
      "icao": "MPLP",
      "iata": "PLP",
      "name": "Captain Ramon Xatruch Airport",
      "city": "La Palma",
      "state": "Darien",
      "country": "PA",
      "elevation": 30,
      "lat": 8.4066696167,
      "lon": -78.1417007446,
      "tz": "America\/Panama"
  },
  "MPMG": {
      "icao": "MPMG",
      "iata": "PAC",
      "name": "Marcos A. Gelabert International Airport",
      "city": "Albrook",
      "state": "Panama",
      "country": "PA",
      "elevation": 31,
      "lat": 8.9733400345,
      "lon": -79.5556030273,
      "tz": "America\/Panama"
  },
  "MPNU": {
      "icao": "MPNU",
      "iata": "",
      "name": "Augusto Vergara Airport",
      "city": "Los Santos",
      "state": "Los-Santos",
      "country": "PA",
      "elevation": 69,
      "lat": 7.8573799133,
      "lon": -80.2761001587,
      "tz": "America\/Panama"
  },
  "MPOA": {
      "icao": "MPOA",
      "iata": "PUE",
      "name": "Puerto Obaldia Airport",
      "city": "Puerto Obaldia",
      "state": "Guna-Yala",
      "country": "PA",
      "elevation": 223,
      "lat": 8.6862602234,
      "lon": -77.5243988037,
      "tz": "America\/Panama"
  },
  "MPPA": {
      "icao": "MPPA",
      "iata": "BLB",
      "name": "Howard\/Panama Pacifico International Airport",
      "city": "Panama City",
      "state": "Panama",
      "country": "PA",
      "elevation": 52,
      "lat": 8.9147901535,
      "lon": -79.5996017456,
      "tz": "America\/Panama"
  },
  "MPSM": {
      "icao": "MPSM",
      "iata": "RIH",
      "name": "Scarlett Martinez International Airport",
      "city": "Rio Hato",
      "state": "Cocle",
      "country": "PA",
      "elevation": 121,
      "lat": 8.375833,
      "lon": -80.127778,
      "tz": "America\/Panama"
  },
  "MPSA": {
      "icao": "MPSA",
      "iata": "SYP",
      "name": "Ruben Cantu Airport",
      "city": "Santiago",
      "state": "Veraguas",
      "country": "PA",
      "elevation": 272,
      "lat": 8.0855998993,
      "lon": -80.9452972412,
      "tz": "America\/Panama"
  },
  "MPTO": {
      "icao": "MPTO",
      "iata": "PTY",
      "name": "Tocumen International Airport",
      "city": "Tocumen",
      "state": "Panama",
      "country": "PA",
      "elevation": 135,
      "lat": 9.0713596344,
      "lon": -79.3834991455,
      "tz": "America\/Panama"
  },
  "MPVR": {
      "icao": "MPVR",
      "iata": "PVE",
      "name": "El Porvenir Airport",
      "city": "El Porvenir",
      "state": "Guna-Yala",
      "country": "PA",
      "elevation": 17,
      "lat": 9.5634202957,
      "lon": -79.0040969849,
      "tz": "America\/Panama"
  },
  "MPWN": {
      "icao": "MPWN",
      "iata": "NBL",
      "name": "San Blas Airport",
      "city": "Wannukandi",
      "state": "Guna-Yala",
      "country": "PA",
      "elevation": 17,
      "lat": 9.4496002197,
      "lon": -78.9794998169,
      "tz": "America\/Panama"
  },
  "MPZL": {
      "icao": "MPZL",
      "iata": "",
      "name": "Finca 32 Airport",
      "city": "La Dalia",
      "state": "Bocas-del-Toro",
      "country": "PA",
      "elevation": 23,
      "lat": 9.4270896912,
      "lon": -82.5626983643,
      "tz": "America\/Panama"
  },
  "MRAD": {
      "icao": "MRAD",
      "iata": "",
      "name": "Aerodamas Airport",
      "city": "Quepos",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 18,
      "lat": 9.4577770233,
      "lon": -84.2089996338,
      "tz": "America\/Costa_Rica"
  },
  "MRAJ": {
      "icao": "MRAJ",
      "iata": "",
      "name": "Aranjuez Airport",
      "city": "Miramar",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 52,
      "lat": 10.0500001907,
      "lon": -84.8000030518,
      "tz": "America\/Costa_Rica"
  },
  "MRAM": {
      "icao": "MRAM",
      "iata": "",
      "name": "Amubri Airport",
      "city": "Amubri",
      "state": "Limon",
      "country": "CR",
      "elevation": 301,
      "lat": 9.5216302872,
      "lon": -82.9546966553,
      "tz": "America\/Costa_Rica"
  },
  "MRAN": {
      "icao": "MRAN",
      "iata": "FON",
      "name": "Arenal Airport",
      "city": "La Fortuna\/San Carlos",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 342,
      "lat": 10.4779996872,
      "lon": -84.6344985962,
      "tz": "America\/Costa_Rica"
  },
  "MRAO": {
      "icao": "MRAO",
      "iata": "TTQ",
      "name": "Aerotortuguero Airport",
      "city": "Roxana",
      "state": "Limon",
      "country": "CR",
      "elevation": 82,
      "lat": 10.5690002441,
      "lon": -83.5148010254,
      "tz": "America\/Costa_Rica"
  },
  "MRAR": {
      "icao": "MRAR",
      "iata": "",
      "name": "Atirro Airport",
      "city": "Turrialba",
      "state": "Cartago",
      "country": "CR",
      "elevation": 1936,
      "lat": 9.8500003815,
      "lon": -83.6500015259,
      "tz": "America\/Costa_Rica"
  },
  "MRAT": {
      "icao": "MRAT",
      "iata": "",
      "name": "Altamira de San Carlos Airport",
      "city": "San Carlos",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 226,
      "lat": 10.516699791,
      "lon": -84.3666992188,
      "tz": "America\/Costa_Rica"
  },
  "MRBA": {
      "icao": "MRBA",
      "iata": "BAI",
      "name": "Buenos Aires Airport",
      "city": "Punta Arenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 1214,
      "lat": 9.1636104584,
      "lon": -83.3299026489,
      "tz": "America\/Costa_Rica"
  },
  "MRBB": {
      "icao": "MRBB",
      "iata": "",
      "name": "Babilonia Airport",
      "city": "Siquirres",
      "state": "Limon",
      "country": "CR",
      "elevation": 591,
      "lat": 10.1333332062,
      "lon": -83.5833358765,
      "tz": "America\/Costa_Rica"
  },
  "MRBC": {
      "icao": "MRBC",
      "iata": "BCL",
      "name": "Barra del Colorado Airport",
      "city": "Pococi",
      "state": "Limon",
      "country": "CR",
      "elevation": 3,
      "lat": 10.768699646,
      "lon": -83.5856018066,
      "tz": "America\/Costa_Rica"
  },
  "MRBM": {
      "icao": "MRBM",
      "iata": "",
      "name": "Bremen Airport",
      "city": "Guacimo",
      "state": "Limon",
      "country": "CR",
      "elevation": 102,
      "lat": 10.219499588,
      "lon": -83.5845031738,
      "tz": "America\/Costa_Rica"
  },
  "MRBN": {
      "icao": "MRBN",
      "iata": "",
      "name": "Bataan (Monte Libano) Airport",
      "city": "Limon",
      "state": "Limon",
      "country": "CR",
      "elevation": 39,
      "lat": 10.0833330154,
      "lon": -83.3166656494,
      "tz": "America\/Costa_Rica"
  },
  "MRBO": {
      "icao": "MRBO",
      "iata": "",
      "name": "Boca Naranjo Airport",
      "city": "Quepos",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 15,
      "lat": 9.391960144,
      "lon": -84.1259002686,
      "tz": "America\/Costa_Rica"
  },
  "MRBP": {
      "icao": "MRBP",
      "iata": "",
      "name": "Barra de Parismina Airport",
      "city": "Pococi",
      "state": "Limon",
      "country": "CR",
      "elevation": 7,
      "lat": 10.3000001907,
      "lon": -83.3499984741,
      "tz": "America\/Costa_Rica"
  },
  "MRCA": {
      "icao": "MRCA",
      "iata": "CSC",
      "name": "Codela Airport",
      "city": "Guapiles",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 328,
      "lat": 10.4139995575,
      "lon": -85.0916976929,
      "tz": "America\/Costa_Rica"
  },
  "MRCC": {
      "icao": "MRCC",
      "iata": "OTR",
      "name": "Coto 47 Airport",
      "city": "Corredores",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 26,
      "lat": 8.601559639,
      "lon": -82.9685974121,
      "tz": "America\/Costa_Rica"
  },
  "MRCE": {
      "icao": "MRCE",
      "iata": "",
      "name": "Carate Airport",
      "city": "Golfito",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 16,
      "lat": 8.445110321,
      "lon": -83.4649963379,
      "tz": "America\/Costa_Rica"
  },
  "MRCH": {
      "icao": "MRCH",
      "iata": "JAP",
      "name": "Chacarita Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 7,
      "lat": 9.9814100266,
      "lon": -84.7726974487,
      "tz": "America\/Costa_Rica"
  },
  "MRCI": {
      "icao": "MRCI",
      "iata": "",
      "name": "Ciruelas Airport",
      "city": "Bagaces",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 361,
      "lat": 10.516667366,
      "lon": -85.3499984741,
      "tz": "America\/Costa_Rica"
  },
  "MRCL": {
      "icao": "MRCL",
      "iata": "",
      "name": "Coyolar Airport",
      "city": "Liberia",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 262,
      "lat": 10.667222023,
      "lon": -85.511390686,
      "tz": "America\/Costa_Rica"
  },
  "MRCO": {
      "icao": "MRCO",
      "iata": "",
      "name": "El Cerrito Airport",
      "city": "Filadelfia",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 59,
      "lat": 10.4535999298,
      "lon": -85.5581970215,
      "tz": "America\/Costa_Rica"
  },
  "MRCR": {
      "icao": "MRCR",
      "iata": "RIK",
      "name": "Carrillo Airport",
      "city": "Nicoya",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 6,
      "lat": 9.8705101013,
      "lon": -85.4813995361,
      "tz": "America\/Costa_Rica"
  },
  "MRCV": {
      "icao": "MRCV",
      "iata": "",
      "name": "Cabo Velas Airport",
      "city": "Nicoya",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 33,
      "lat": 10.3556995392,
      "lon": -85.852897644,
      "tz": "America\/Costa_Rica"
  },
  "MRCZ": {
      "icao": "MRCZ",
      "iata": "",
      "name": "Carrizal Airport",
      "city": "Carrizal",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 4650,
      "lat": 10.0875997543,
      "lon": -84.1695022583,
      "tz": "America\/Costa_Rica"
  },
  "MRDC": {
      "icao": "MRDC",
      "iata": "",
      "name": "Duacari 2 Airport",
      "city": "Guapiles",
      "state": "Limon",
      "country": "CR",
      "elevation": 79,
      "lat": 10.3514995575,
      "lon": -83.6305007935,
      "tz": "America\/Costa_Rica"
  },
  "MRDD": {
      "icao": "MRDD",
      "iata": "",
      "name": "Don Diego Airport",
      "city": "Limon",
      "state": "Limon",
      "country": "CR",
      "elevation": 161,
      "lat": 9.5500001907,
      "lon": -82.8833312988,
      "tz": "America\/Costa_Rica"
  },
  "MRDK": {
      "icao": "MRDK",
      "iata": "DRK",
      "name": "Drake Bay Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 12,
      "lat": 8.7188901901,
      "lon": -83.6417007446,
      "tz": "America\/Costa_Rica"
  },
  "MRDM": {
      "icao": "MRDM",
      "iata": "",
      "name": "Dos Marias Airport",
      "city": "Chorotega",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 16,
      "lat": 9.9279098511,
      "lon": -84.9899978638,
      "tz": "America\/Costa_Rica"
  },
  "MRDO": {
      "icao": "MRDO",
      "iata": "",
      "name": "Dieciocho Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 19,
      "lat": 8.9036998749,
      "lon": -83.4298019409,
      "tz": "America\/Costa_Rica"
  },
  "MREC": {
      "icao": "MREC",
      "iata": "",
      "name": "El Carmen de Siquirres Airport",
      "city": "Siquirres",
      "state": "Limon",
      "country": "CR",
      "elevation": 56,
      "lat": 10.2019996643,
      "lon": -83.4721984863,
      "tz": "America\/Costa_Rica"
  },
  "MRED": {
      "icao": "MRED",
      "iata": "",
      "name": "El Descanso de Poco Sol Airport",
      "city": "San Carlos",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 23,
      "lat": 10.8667001724,
      "lon": -84.5333023071,
      "tz": "America\/Costa_Rica"
  },
  "MREO": {
      "icao": "MREO",
      "iata": "",
      "name": "El Ceibo Airport",
      "city": "Ticaban",
      "state": "Heredia",
      "country": "CR",
      "elevation": 33,
      "lat": 10.5333328247,
      "lon": -83.8499984741,
      "tz": "America\/Costa_Rica"
  },
  "MRER": {
      "icao": "MRER",
      "iata": "",
      "name": "El Ron Ron Airport",
      "city": "Ron Ron",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 2109,
      "lat": 10.3002996445,
      "lon": -84.4501037598,
      "tz": "America\/Costa_Rica"
  },
  "MRET": {
      "icao": "MRET",
      "iata": "",
      "name": "Esterillos \/ Finca Airport",
      "city": "Parrita",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 20,
      "lat": 9.5333328247,
      "lon": -84.4666671753,
      "tz": "America\/Costa_Rica"
  },
  "MRFD": {
      "icao": "MRFD",
      "iata": "",
      "name": "Finca Delicias Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 26,
      "lat": 8.9448299408,
      "lon": -83.5589981079,
      "tz": "America\/Costa_Rica"
  },
  "MRFI": {
      "icao": "MRFI",
      "iata": "",
      "name": "Finca 10 \/ Nuevo Palmar Sur Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 25,
      "lat": 8.9163503647,
      "lon": -83.5073013306,
      "tz": "America\/Costa_Rica"
  },
  "MRFL": {
      "icao": "MRFL",
      "iata": "FMG",
      "name": "Flamingo Airport",
      "city": "Brasilito",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 270,
      "lat": 10.4186000824,
      "lon": -85.782699585,
      "tz": "America\/Costa_Rica"
  },
  "MRFP": {
      "icao": "MRFP",
      "iata": "",
      "name": "Frutez - Pital Airport",
      "city": "San Carlos",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 617,
      "lat": 10.4474000931,
      "lon": -84.3690032959,
      "tz": "America\/Costa_Rica"
  },
  "MRFS": {
      "icao": "MRFS",
      "iata": "",
      "name": "Finca 63 (Coto 63) Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 36,
      "lat": 8.6525001526,
      "lon": -83.0652999878,
      "tz": "America\/Costa_Rica"
  },
  "MRGA": {
      "icao": "MRGA",
      "iata": "",
      "name": "Garza Airport",
      "city": "Garza",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 3,
      "lat": 9.9167003632,
      "lon": -85.6333007813,
      "tz": "America\/Costa_Rica"
  },
  "MRGF": {
      "icao": "MRGF",
      "iata": "GLF",
      "name": "Golfito Airport",
      "city": "Golfito",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 49,
      "lat": 8.654009819,
      "lon": -83.1821975708,
      "tz": "America\/Costa_Rica"
  },
  "MRGP": {
      "icao": "MRGP",
      "iata": "GPL",
      "name": "Guapiles Airport",
      "city": "Pococi",
      "state": "Limon",
      "country": "CR",
      "elevation": 883,
      "lat": 10.2172002792,
      "lon": -83.7969970703,
      "tz": "America\/Costa_Rica"
  },
  "MRGT": {
      "icao": "MRGT",
      "iata": "",
      "name": "Guatuso Airport",
      "city": "Alajuela",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 164,
      "lat": 10.6833333969,
      "lon": -84.8333358765,
      "tz": "America\/Costa_Rica"
  },
  "MRHG": {
      "icao": "MRHG",
      "iata": "",
      "name": "Hacienda Rancho Grande Airport",
      "city": "Sarapiqui",
      "state": "Heredia",
      "country": "CR",
      "elevation": 98,
      "lat": 10.4499998093,
      "lon": -84.0833358765,
      "tz": "America\/Costa_Rica"
  },
  "MRHH": {
      "icao": "MRHH",
      "iata": "",
      "name": "Hacienda Homuha Airport",
      "city": "San Carlos",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 230,
      "lat": 10.8000001907,
      "lon": -84.3666687012,
      "tz": "America\/Costa_Rica"
  },
  "MRHO": {
      "icao": "MRHO",
      "iata": "",
      "name": "Hacienda Rio Cuarto Airport",
      "city": "Sarapiqui",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 197,
      "lat": 10.5666666031,
      "lon": -84.1500015259,
      "tz": "America\/Costa_Rica"
  },
  "MRHP": {
      "icao": "MRHP",
      "iata": "",
      "name": "Hacienda La Pacifica Airport",
      "city": "Canas",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 164,
      "lat": 10.4499998093,
      "lon": -85.1500015259,
      "tz": "America\/Costa_Rica"
  },
  "MRHS": {
      "icao": "MRHS",
      "iata": "",
      "name": "Hacienda La Suerte Airport",
      "city": "Pococi",
      "state": "Limon",
      "country": "CR",
      "elevation": 492,
      "lat": 10.4499998093,
      "lon": -83.6666641235,
      "tz": "America\/Costa_Rica"
  },
  "MRIA": {
      "icao": "MRIA",
      "iata": "PBP",
      "name": "Islita Airport",
      "city": "Nandayure",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 7,
      "lat": 9.8561096191,
      "lon": -85.3707962036,
      "tz": "America\/Costa_Rica"
  },
  "MRIS": {
      "icao": "MRIS",
      "iata": "",
      "name": "Las Islas Airport",
      "city": "San Carlos",
      "state": "Heredia",
      "country": "CR",
      "elevation": 98,
      "lat": 10.5583000183,
      "lon": -83.9740982056,
      "tz": "America\/Costa_Rica"
  },
  "MRJO": {
      "icao": "MRJO",
      "iata": "",
      "name": "Jaco Airport",
      "city": "Jaco",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 20,
      "lat": 9.555270195,
      "lon": -84.5563964844,
      "tz": "America\/Costa_Rica"
  },
  "MRLA": {
      "icao": "MRLA",
      "iata": "",
      "name": "La Zampona Airport",
      "city": "Guanacaste",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 328,
      "lat": 10.266667366,
      "lon": -85.4333343506,
      "tz": "America\/Costa_Rica"
  },
  "MRLB": {
      "icao": "MRLB",
      "iata": "LIR",
      "name": "Daniel Oduber Quiros International Airport",
      "city": "Liberia",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 270,
      "lat": 10.5932998657,
      "lon": -85.5444030762,
      "tz": "America\/Costa_Rica"
  },
  "MRLC": {
      "icao": "MRLC",
      "iata": "LSL",
      "name": "Los Chiles Airport",
      "city": "Los Chiles",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 131,
      "lat": 11.0353002548,
      "lon": -84.7061004639,
      "tz": "America\/Costa_Rica"
  },
  "MRLE": {
      "icao": "MRLE",
      "iata": "",
      "name": "Laurel Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 65,
      "lat": 8.4404096603,
      "lon": -82.90650177,
      "tz": "America\/Costa_Rica"
  },
  "MRLF": {
      "icao": "MRLF",
      "iata": "",
      "name": "La Flor Airport",
      "city": "Liberia",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 177,
      "lat": 10.6499996185,
      "lon": -85.5329971313,
      "tz": "America\/Costa_Rica"
  },
  "MRLG": {
      "icao": "MRLG",
      "iata": "",
      "name": "La Garroba Airport",
      "city": "Upala",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 295,
      "lat": 10.8000001907,
      "lon": -84.9166641235,
      "tz": "America\/Costa_Rica"
  },
  "MRLI": {
      "icao": "MRLI",
      "iata": "",
      "name": "La Ligia Airport",
      "city": "Parrita",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 16,
      "lat": 9.5152702332,
      "lon": -84.3014984131,
      "tz": "America\/Costa_Rica"
  },
  "MRLL": {
      "icao": "MRLL",
      "iata": "",
      "name": "Las Lomas Airport",
      "city": "Siquirres",
      "state": "Limon",
      "country": "CR",
      "elevation": 1325,
      "lat": 10.0666666031,
      "lon": -83.5833358765,
      "tz": "America\/Costa_Rica"
  },
  "MRLM": {
      "icao": "MRLM",
      "iata": "LIO",
      "name": "Limon International Airport",
      "city": "Puerto Limon",
      "state": "Limon",
      "country": "CR",
      "elevation": 7,
      "lat": 9.9579601288,
      "lon": -83.0220031738,
      "tz": "America\/Costa_Rica"
  },
  "MRLN": {
      "icao": "MRLN",
      "iata": "",
      "name": "La Guinea Airport",
      "city": "Nicoya",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 33,
      "lat": 10.4207000732,
      "lon": -85.4720001221,
      "tz": "America\/Costa_Rica"
  },
  "MRLP": {
      "icao": "MRLP",
      "iata": "",
      "name": "Las Piedras Airport",
      "city": "Canas",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 82,
      "lat": 10.3666667938,
      "lon": -85.1999969482,
      "tz": "America\/Costa_Rica"
  },
  "MRLT": {
      "icao": "MRLT",
      "iata": "",
      "name": "Las Trancas Airport",
      "city": "Las Trancas",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 68,
      "lat": 10.5670003891,
      "lon": -85.5999984741,
      "tz": "America\/Costa_Rica"
  },
  "MRLV": {
      "icao": "MRLV",
      "iata": "",
      "name": "La Cueva Airport",
      "city": "Liberia",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 230,
      "lat": 10.6812000275,
      "lon": -85.5280990601,
      "tz": "America\/Costa_Rica"
  },
  "MRLY": {
      "icao": "MRLY",
      "iata": "",
      "name": "La Yolanda Airport",
      "city": "Parrita",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 13,
      "lat": 9.5500001907,
      "lon": -84.5666656494,
      "tz": "America\/Costa_Rica"
  },
  "MRLZ": {
      "icao": "MRLZ",
      "iata": "",
      "name": "La Zopilota Airport",
      "city": "Guanacaste",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 39,
      "lat": 10.4835996628,
      "lon": -85.4619979858,
      "tz": "America\/Costa_Rica"
  },
  "MRMA": {
      "icao": "MRMA",
      "iata": "",
      "name": "Monte Alto Airport",
      "city": "Abangares",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 66,
      "lat": 10.0833330154,
      "lon": -85.2666702271,
      "tz": "America\/Costa_Rica"
  },
  "MRMC": {
      "icao": "MRMC",
      "iata": "",
      "name": "Murcielago Airport",
      "city": "La Cruz",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 66,
      "lat": 10.9166669846,
      "lon": -85.7166671753,
      "tz": "America\/Costa_Rica"
  },
  "MRMJ": {
      "icao": "MRMJ",
      "iata": "CSC",
      "name": "Mojica Airport",
      "city": "Canas",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 230,
      "lat": 10.4307003021,
      "lon": -85.1745986938,
      "tz": "America\/Costa_Rica"
  },
  "MRNC": {
      "icao": "MRNC",
      "iata": "NCT",
      "name": "Guanacaste Airport",
      "city": "Nicoya\/Guanacate",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 365,
      "lat": 10.1393995285,
      "lon": -85.4458007813,
      "tz": "America\/Costa_Rica"
  },
  "MRNS": {
      "icao": "MRNS",
      "iata": "NOB",
      "name": "Nosara Airport",
      "city": "Nicoya",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 33,
      "lat": 9.9764900208,
      "lon": -85.6529998779,
      "tz": "America\/Costa_Rica"
  },
  "MROC": {
      "icao": "MROC",
      "iata": "SJO",
      "name": "Juan Santamaria International Airport",
      "city": "San Jose",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 3021,
      "lat": 9.9938602448,
      "lon": -84.2088012695,
      "tz": "America\/Costa_Rica"
  },
  "MRPA": {
      "icao": "MRPA",
      "iata": "",
      "name": "Palo Arco Airport",
      "city": "Nandayure",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 324,
      "lat": 9.8514099121,
      "lon": -85.2378997803,
      "tz": "America\/Costa_Rica"
  },
  "MRPB": {
      "icao": "MRPB",
      "iata": "",
      "name": "Playa Blanca (J. W. Berteus) Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 6,
      "lat": 8.6499996185,
      "lon": -83.4329986572,
      "tz": "America\/Costa_Rica"
  },
  "MRPC": {
      "icao": "MRPC",
      "iata": "",
      "name": "Paso Canoas Airport",
      "city": "Paso Canoas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 324,
      "lat": 8.9577798843,
      "lon": -83.5503005981,
      "tz": "America\/Costa_Rica"
  },
  "MRPD": {
      "icao": "MRPD",
      "iata": "",
      "name": "Pandora Airport",
      "city": "Limon",
      "state": "Limon",
      "country": "CR",
      "elevation": 98,
      "lat": 9.732170105,
      "lon": -82.9832000732,
      "tz": "America\/Costa_Rica"
  },
  "MRPJ": {
      "icao": "MRPJ",
      "iata": "PJM",
      "name": "Puerto Jimenez Airport",
      "city": "Puerto Jimenez",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 7,
      "lat": 8.5333299637,
      "lon": -83.3000030518,
      "tz": "America\/Costa_Rica"
  },
  "MRPL": {
      "icao": "MRPL",
      "iata": "",
      "name": "Portalon Airport",
      "city": "Portalon",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 206,
      "lat": 9.3579797745,
      "lon": -83.9794998169,
      "tz": "America\/Costa_Rica"
  },
  "MRPM": {
      "icao": "MRPM",
      "iata": "PMZ",
      "name": "Palmar Sur Airport",
      "city": "Palmar Sur",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 49,
      "lat": 8.9510297775,
      "lon": -83.4685974121,
      "tz": "America\/Costa_Rica"
  },
  "MRPN": {
      "icao": "MRPN",
      "iata": "",
      "name": "Pelon Nuevo Airport",
      "city": "Liberia",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 49,
      "lat": 10.486700058,
      "lon": -85.4141998291,
      "tz": "America\/Costa_Rica"
  },
  "MRPO": {
      "icao": "MRPO",
      "iata": "",
      "name": "Punta Banco Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 16,
      "lat": 8.3573102951,
      "lon": -83.1369018555,
      "tz": "America\/Costa_Rica"
  },
  "MRPP": {
      "icao": "MRPP",
      "iata": "",
      "name": "Playa Pajaros Airport",
      "city": "Paquera",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 30,
      "lat": 9.8583097458,
      "lon": -84.6943969727,
      "tz": "America\/Costa_Rica"
  },
  "MRPS": {
      "icao": "MRPS",
      "iata": "",
      "name": "Penas Blancas Airport",
      "city": "Santa Cruz",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 98,
      "lat": 10.3156003952,
      "lon": -85.6780014038,
      "tz": "America\/Costa_Rica"
  },
  "MRPT": {
      "icao": "MRPT",
      "iata": "",
      "name": "Agropecuaria Playa Caletas Airport",
      "city": "Nicoya\/Guanacate",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 7,
      "lat": 9.733332634,
      "lon": -85.2333297729,
      "tz": "America\/Costa_Rica"
  },
  "MRPV": {
      "icao": "MRPV",
      "iata": "SYQ",
      "name": "Tobias Bolanos International Airport",
      "city": "San Jose",
      "state": "San-Jose",
      "country": "CR",
      "elevation": 3287,
      "lat": 9.9570503235,
      "lon": -84.1398010254,
      "tz": "America\/Costa_Rica"
  },
  "MRPY": {
      "icao": "MRPY",
      "iata": "",
      "name": "Playa Ballena Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 7,
      "lat": 9.122420311,
      "lon": -83.7063980103,
      "tz": "America\/Costa_Rica"
  },
  "MRQA": {
      "icao": "MRQA",
      "iata": "",
      "name": "Quebrada Azul Airport",
      "city": "Florencia",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 197,
      "lat": 10.3999996185,
      "lon": -84.4833297729,
      "tz": "America\/Costa_Rica"
  },
  "MRQP": {
      "icao": "MRQP",
      "iata": "XQP",
      "name": "Quepos Managua Airport",
      "city": "Quepos",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 85,
      "lat": 9.4431600571,
      "lon": -84.1297988892,
      "tz": "America\/Costa_Rica"
  },
  "MRRF": {
      "icao": "MRRF",
      "iata": "RFR",
      "name": "Rio Frio \/ Progreso Airport",
      "city": "Rio Frio \/ Progreso",
      "state": "Heredia",
      "country": "CR",
      "elevation": 350,
      "lat": 10.3274002075,
      "lon": -83.8876037598,
      "tz": "America\/Costa_Rica"
  },
  "MRRH": {
      "icao": "MRRH",
      "iata": "",
      "name": "Rancho Humo Airport",
      "city": "Santa Cruz",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 33,
      "lat": 10.3166666031,
      "lon": -85.3333358765,
      "tz": "America\/Costa_Rica"
  },
  "MRRN": {
      "icao": "MRRN",
      "iata": "",
      "name": "Rancho Nuevo Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 33,
      "lat": 9.5666666031,
      "lon": -84.5166702271,
      "tz": "America\/Costa_Rica"
  },
  "MRRX": {
      "icao": "MRRX",
      "iata": "",
      "name": "Roxana Farms Airport",
      "city": "Limon",
      "state": "Limon",
      "country": "CR",
      "elevation": 312,
      "lat": 10.3081998825,
      "lon": -83.7543029785,
      "tz": "America\/Costa_Rica"
  },
  "MRSA": {
      "icao": "MRSA",
      "iata": "",
      "name": "San Alberto Airport",
      "city": "Limon",
      "state": "Limon",
      "country": "CR",
      "elevation": 88,
      "lat": 10.1465997696,
      "lon": -83.4881973267,
      "tz": "America\/Costa_Rica"
  },
  "MRSB": {
      "icao": "MRSB",
      "iata": "",
      "name": "San Cristobal Airport",
      "city": "San Carlos",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 259,
      "lat": 10.5,
      "lon": -84.5666656494,
      "tz": "America\/Costa_Rica"
  },
  "MRSC": {
      "icao": "MRSC",
      "iata": "",
      "name": "Santa Cruz Airport",
      "city": "Santa Cruz",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 206,
      "lat": 10.2737998962,
      "lon": -85.5914993286,
      "tz": "America\/Costa_Rica"
  },
  "MRSF": {
      "icao": "MRSF",
      "iata": "",
      "name": "Santa Fe Airport",
      "city": "Perez Zeledon",
      "state": "San-Jose",
      "country": "CR",
      "elevation": 1995,
      "lat": 9.3000001907,
      "lon": -83.6166687012,
      "tz": "America\/Costa_Rica"
  },
  "MRSG": {
      "icao": "MRSG",
      "iata": "",
      "name": "Santa Clara De Guapiles Airport",
      "city": "Limon",
      "state": "Limon",
      "country": "CR",
      "elevation": 262,
      "lat": 10.2882995605,
      "lon": -83.7135009766,
      "tz": "America\/Costa_Rica"
  },
  "MRSH": {
      "icao": "MRSH",
      "iata": "",
      "name": "Shiroles Airport",
      "city": "Talamanca",
      "state": "Limon",
      "country": "CR",
      "elevation": 20,
      "lat": 9.5807695389,
      "lon": -82.9598999023,
      "tz": "America\/Costa_Rica"
  },
  "MRSI": {
      "icao": "MRSI",
      "iata": "IPZ",
      "name": "San Isidro del General Airport",
      "city": "Perez Zeledon",
      "state": "San-Jose",
      "country": "CR",
      "elevation": 2100,
      "lat": 9.3526201248,
      "lon": -83.7130966187,
      "tz": "America\/Costa_Rica"
  },
  "MRSL": {
      "icao": "MRSL",
      "iata": "",
      "name": "Salama Airport",
      "city": "Salama",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 32,
      "lat": 8.8330001831,
      "lon": -83.25,
      "tz": "America\/Costa_Rica"
  },
  "MRSM": {
      "icao": "MRSM",
      "iata": "",
      "name": "Santa Marta Airport",
      "city": "Orotina",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 417,
      "lat": 9.9166669846,
      "lon": -84.6166687012,
      "tz": "America\/Costa_Rica"
  },
  "MRSO": {
      "icao": "MRSO",
      "iata": "",
      "name": "Santa Maria De Guacimo Airport",
      "city": "Guapiles",
      "state": "Limon",
      "country": "CR",
      "elevation": 32,
      "lat": 10.2670001984,
      "lon": -83.6169967651,
      "tz": "America\/Costa_Rica"
  },
  "MRSP": {
      "icao": "MRSP",
      "iata": "",
      "name": "San Pedro Airport",
      "city": "San Pedro",
      "state": "Limon",
      "country": "CR",
      "elevation": 230,
      "lat": 10.4179000854,
      "lon": -83.7529983521,
      "tz": "America\/Costa_Rica"
  },
  "MRSQ": {
      "icao": "MRSQ",
      "iata": "",
      "name": "Sarapigui Airport",
      "city": "San Carlos",
      "state": "Heredia",
      "country": "CR",
      "elevation": 164,
      "lat": 10.4718999863,
      "lon": -83.9069976807,
      "tz": "America\/Costa_Rica"
  },
  "MRSR": {
      "icao": "MRSR",
      "iata": "PLD",
      "name": "Playa Samara Airport",
      "city": "Playa Samara",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 10,
      "lat": 10.25,
      "lon": -85.4169998169,
      "tz": "America\/Costa_Rica"
  },
  "MRST": {
      "icao": "MRST",
      "iata": "",
      "name": "San Agustin Airport",
      "city": "Puntarenas",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 66,
      "lat": 10.0666666031,
      "lon": -84.8833312988,
      "tz": "America\/Costa_Rica"
  },
  "MRSV": {
      "icao": "MRSV",
      "iata": "TOO",
      "name": "San Vito De Java Airport",
      "city": "Coto Brus",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 3228,
      "lat": 8.8261098862,
      "lon": -82.9589004517,
      "tz": "America\/Costa_Rica"
  },
  "MRSX": {
      "icao": "MRSX",
      "iata": "",
      "name": "Sixaola Airport",
      "city": "Sixaola",
      "state": "Limon",
      "country": "CR",
      "elevation": 26,
      "lat": 9.5032196045,
      "lon": -82.6327972412,
      "tz": "America\/Costa_Rica"
  },
  "MRTB": {
      "icao": "MRTB",
      "iata": "",
      "name": "Ticaban Airport",
      "city": "Pococi",
      "state": "Limon",
      "country": "CR",
      "elevation": 246,
      "lat": 10.3666667938,
      "lon": -83.8499984741,
      "tz": "America\/Costa_Rica"
  },
  "MRTG": {
      "icao": "MRTG",
      "iata": "",
      "name": "Taboga Airport",
      "city": "Canas",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 108,
      "lat": 10.3500003815,
      "lon": -85.1999969482,
      "tz": "America\/Costa_Rica"
  },
  "MRTL": {
      "icao": "MRTL",
      "iata": "",
      "name": "Talolinga Airport",
      "city": "Nicoya",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 33,
      "lat": 10.3084001541,
      "lon": -85.4683990479,
      "tz": "America\/Costa_Rica"
  },
  "MRTM": {
      "icao": "MRTM",
      "iata": "TNO",
      "name": "Tamarindo De Santa Cruz Airport",
      "city": "Santa Cruz",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 41,
      "lat": 10.3135004044,
      "lon": -85.8154983521,
      "tz": "America\/Costa_Rica"
  },
  "MRTR": {
      "icao": "MRTR",
      "iata": "TMU",
      "name": "Tambor Airport",
      "city": "Nicoya",
      "state": "Puntarenas",
      "country": "CR",
      "elevation": 33,
      "lat": 9.7385196686,
      "lon": -85.0138015747,
      "tz": "America\/Costa_Rica"
  },
  "MRUP": {
      "icao": "MRUP",
      "iata": "UPL",
      "name": "Upala Airport",
      "city": "Upala",
      "state": "Alajuela",
      "country": "CR",
      "elevation": 184,
      "lat": 10.89220047,
      "lon": -85.0161972046,
      "tz": "America\/Costa_Rica"
  },
  "MRYT": {
      "icao": "MRYT",
      "iata": "",
      "name": "Yucatica Airport",
      "city": "Guacimo",
      "state": "Limon",
      "country": "CR",
      "elevation": 151,
      "lat": 10.3333330154,
      "lon": -83.6666641235,
      "tz": "America\/Costa_Rica"
  },
  "MRZP": {
      "icao": "MRZP",
      "iata": "",
      "name": "Zapotal De Guanacaste Airport",
      "city": "Zapotal",
      "state": "Guanacaste",
      "country": "CR",
      "elevation": 1305,
      "lat": 9.9940099716,
      "lon": -85.3102035522,
      "tz": "America\/Costa_Rica"
  },
  "MS00": {
      "icao": "MS00",
      "iata": "",
      "name": "Double O Ranch Airport",
      "city": "Olive Branch",
      "state": "Mississippi",
      "country": "US",
      "elevation": 385,
      "lat": 34.988899231,
      "lon": -89.9152984619,
      "tz": "America\/Chicago"
  },
  "MS03": {
      "icao": "MS03",
      "iata": "",
      "name": "Christmas Airport",
      "city": "Shelby",
      "state": "Mississippi",
      "country": "US",
      "elevation": 153,
      "lat": 33.9243011475,
      "lon": -90.7870025635,
      "tz": "America\/Chicago"
  },
  "MS04": {
      "icao": "MS04",
      "iata": "",
      "name": "Hopper Field",
      "city": "Steens",
      "state": "Mississippi",
      "country": "US",
      "elevation": 300,
      "lat": 33.6225013733,
      "lon": -88.2861022949,
      "tz": "America\/Chicago"
  },
  "MS06": {
      "icao": "MS06",
      "iata": "",
      "name": "Linwood Airport",
      "city": "Linwood",
      "state": "Mississippi",
      "country": "US",
      "elevation": 285,
      "lat": 32.7904014587,
      "lon": -90.1380996704,
      "tz": "America\/Chicago"
  },
  "MS08": {
      "icao": "MS08",
      "iata": "",
      "name": "Harrell Field",
      "city": "Pisgah",
      "state": "Mississippi",
      "country": "US",
      "elevation": 385,
      "lat": 32.4738998413,
      "lon": -89.8517990112,
      "tz": "America\/Chicago"
  },
  "MS09": {
      "icao": "MS09",
      "iata": "",
      "name": "Glidwell Flying Service Airport",
      "city": "Alligator",
      "state": "Mississippi",
      "country": "US",
      "elevation": 160,
      "lat": 34.1017990112,
      "lon": -90.7012023926,
      "tz": "America\/Chicago"
  },
  "MS10": {
      "icao": "MS10",
      "iata": "",
      "name": "Turner Field",
      "city": "Belzoni",
      "state": "Mississippi",
      "country": "US",
      "elevation": 109,
      "lat": 33.1585006714,
      "lon": -90.5043029785,
      "tz": "America\/Chicago"
  },
  "MS11": {
      "icao": "MS11",
      "iata": "",
      "name": "Mohr Farm Airport",
      "city": "Brandon",
      "state": "Mississippi",
      "country": "US",
      "elevation": 360,
      "lat": 32.2084999084,
      "lon": -89.9417037964,
      "tz": "America\/Chicago"
  },
  "MS13": {
      "icao": "MS13",
      "iata": "",
      "name": "Eifling Farms Airport",
      "city": "Glen Allan",
      "state": "Mississippi",
      "country": "US",
      "elevation": 110,
      "lat": 33.0756988525,
      "lon": -90.9533996582,
      "tz": "America\/Chicago"
  },
  "MS14": {
      "icao": "MS14",
      "iata": "",
      "name": "John Farese Airpark",
      "city": "Ashland",
      "state": "Mississippi",
      "country": "US",
      "elevation": 565,
      "lat": 34.813889,
      "lon": -89.199617,
      "tz": "America\/Chicago"
  },
  "MS15": {
      "icao": "MS15",
      "iata": "",
      "name": "Country Club Airport",
      "city": "Greenville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 117,
      "lat": 33.331199646,
      "lon": -91.0370025635,
      "tz": "America\/Chicago"
  },
  "MS18": {
      "icao": "MS18",
      "iata": "",
      "name": "Dilworth Airport",
      "city": "Corinth",
      "state": "Mississippi",
      "country": "US",
      "elevation": 465,
      "lat": 34.7700004578,
      "lon": -88.6055984497,
      "tz": "America\/Chicago"
  },
  "MS20": {
      "icao": "MS20",
      "iata": "",
      "name": "Newell Flying Service Airport",
      "city": "Inverness",
      "state": "Mississippi",
      "country": "US",
      "elevation": 126,
      "lat": 33.354801178,
      "lon": -90.5790023804,
      "tz": "America\/Chicago"
  },
  "MS21": {
      "icao": "MS21",
      "iata": "",
      "name": "Lester Field",
      "city": "Inverness",
      "state": "Mississippi",
      "country": "US",
      "elevation": 115,
      "lat": 33.3320999146,
      "lon": -90.5820007324,
      "tz": "America\/Chicago"
  },
  "MS22": {
      "icao": "MS22",
      "iata": "",
      "name": "Murphey Flying Service Airport",
      "city": "Itta Bena",
      "state": "Mississippi",
      "country": "US",
      "elevation": 120,
      "lat": 33.4889984131,
      "lon": -90.3570022583,
      "tz": "America\/Chicago"
  },
  "MS23": {
      "icao": "MS23",
      "iata": "",
      "name": "Forest Home Airport",
      "city": "Woodville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 240,
      "lat": 31.0067005157,
      "lon": -91.2031021118,
      "tz": "America\/Chicago"
  },
  "MS24": {
      "icao": "MS24",
      "iata": "",
      "name": "Joe Sanford Field",
      "city": "Edwards",
      "state": "Mississippi",
      "country": "US",
      "elevation": 180,
      "lat": 32.246799469,
      "lon": -90.5822982788,
      "tz": "America\/Chicago"
  },
  "MS25": {
      "icao": "MS25",
      "iata": "",
      "name": "Huntley Airport",
      "city": "Leland",
      "state": "Mississippi",
      "country": "US",
      "elevation": 117,
      "lat": 33.3987007141,
      "lon": -90.9675979614,
      "tz": "America\/Chicago"
  },
  "MS26": {
      "icao": "MS26",
      "iata": "",
      "name": "Cedar Creek Air Ranch Airport",
      "city": "Lucedale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 165,
      "lat": 30.8022003174,
      "lon": -88.5475006104,
      "tz": "America\/Chicago"
  },
  "MS27": {
      "icao": "MS27",
      "iata": "",
      "name": "Aerohead Airport",
      "city": "Hurley",
      "state": "Mississippi",
      "country": "US",
      "elevation": 130,
      "lat": 30.7068996429,
      "lon": -88.4291992188,
      "tz": "America\/Chicago"
  },
  "MS28": {
      "icao": "MS28",
      "iata": "",
      "name": "Alcorn State University Airport",
      "city": "Lorman",
      "state": "Mississippi",
      "country": "US",
      "elevation": 269,
      "lat": 31.8687992096,
      "lon": -91.134803772,
      "tz": "America\/Chicago"
  },
  "MS29": {
      "icao": "MS29",
      "iata": "",
      "name": "Pace Airstrip",
      "city": "Canton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 350,
      "lat": 32.601944,
      "lon": -89.863889,
      "tz": "America\/Chicago"
  },
  "MS30": {
      "icao": "MS30",
      "iata": "",
      "name": "Abide Airpark",
      "city": "Greenville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 115,
      "lat": 33.3056983948,
      "lon": -90.9904022217,
      "tz": "America\/Chicago"
  },
  "MS33": {
      "icao": "MS33",
      "iata": "",
      "name": "Hobbs Airport",
      "city": "Moorhead",
      "state": "Mississippi",
      "country": "US",
      "elevation": 115,
      "lat": 33.4168014526,
      "lon": -90.4944992065,
      "tz": "America\/Chicago"
  },
  "MS34": {
      "icao": "MS34",
      "iata": "",
      "name": "Reality Plantation Airport",
      "city": "Onward",
      "state": "Mississippi",
      "country": "US",
      "elevation": 98,
      "lat": 32.6982002258,
      "lon": -90.9465026855,
      "tz": "America\/Chicago"
  },
  "MS35": {
      "icao": "MS35",
      "iata": "",
      "name": "Wade Airport",
      "city": "Rolling Fork",
      "state": "Mississippi",
      "country": "US",
      "elevation": 106,
      "lat": 32.9812011719,
      "lon": -90.9654006958,
      "tz": "America\/Chicago"
  },
  "MS36": {
      "icao": "MS36",
      "iata": "",
      "name": "Supplejack Airport",
      "city": "Canton",
      "state": "Mississippi",
      "country": "US",
      "elevation": 340,
      "lat": 32.6500015259,
      "lon": -89.9708023071,
      "tz": "America\/Chicago"
  },
  "MS37": {
      "icao": "MS37",
      "iata": "",
      "name": "West Bolivar Flying Service Airport",
      "city": "Benoit",
      "state": "Mississippi",
      "country": "US",
      "elevation": 136,
      "lat": 33.6534004211,
      "lon": -90.9889984131,
      "tz": "America\/Chicago"
  },
  "MS38": {
      "icao": "MS38",
      "iata": "",
      "name": "Flautt Airport",
      "city": "Swan Lake",
      "state": "Mississippi",
      "country": "US",
      "elevation": 145,
      "lat": 33.8722991943,
      "lon": -90.2834014893,
      "tz": "America\/Chicago"
  },
  "MS39": {
      "icao": "MS39",
      "iata": "",
      "name": "Smith County Airport",
      "city": "Taylorsville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 332,
      "lat": 31.8209991455,
      "lon": -89.4583969116,
      "tz": "America\/Chicago"
  },
  "MS40": {
      "icao": "MS40",
      "iata": "",
      "name": "Southland Flying Service Inc. Airport",
      "city": "Tchula",
      "state": "Mississippi",
      "country": "US",
      "elevation": 115,
      "lat": 33.2256011963,
      "lon": -90.2466964722,
      "tz": "America\/Chicago"
  },
  "MS41": {
      "icao": "MS41",
      "iata": "",
      "name": "Flying Y Service Airport",
      "city": "Tutwiler",
      "state": "Mississippi",
      "country": "US",
      "elevation": 149,
      "lat": 34.0000991821,
      "lon": -90.4251022339,
      "tz": "America\/Chicago"
  },
  "MS42": {
      "icao": "MS42",
      "iata": "",
      "name": "Mississippi Petrified Forest Airport",
      "city": "Flora",
      "state": "Mississippi",
      "country": "US",
      "elevation": 265,
      "lat": 32.520401001,
      "lon": -90.3236999512,
      "tz": "America\/Chicago"
  },
  "MS43": {
      "icao": "MS43",
      "iata": "",
      "name": "Whitaker Airport",
      "city": "Vicksburg",
      "state": "Mississippi",
      "country": "US",
      "elevation": 98,
      "lat": 32.5517997742,
      "lon": -90.8303985596,
      "tz": "America\/Chicago"
  },
  "MS45": {
      "icao": "MS45",
      "iata": "",
      "name": "Dale Landing Strip",
      "city": "Yokena",
      "state": "Mississippi",
      "country": "US",
      "elevation": 85,
      "lat": 32.1562995911,
      "lon": -91.1246032715,
      "tz": "America\/Chicago"
  },
  "MS48": {
      "icao": "MS48",
      "iata": "",
      "name": "George Ford Airport",
      "city": "Picayune",
      "state": "Mississippi",
      "country": "US",
      "elevation": 60,
      "lat": 30.6219005585,
      "lon": -89.6965026855,
      "tz": "America\/Chicago"
  },
  "MS49": {
      "icao": "MS49",
      "iata": "",
      "name": "Mc Gehee Air Park",
      "city": "Liberty",
      "state": "Mississippi",
      "country": "US",
      "elevation": 315,
      "lat": 31.1630001068,
      "lon": -90.8172988892,
      "tz": "America\/Chicago"
  },
  "MS50": {
      "icao": "MS50",
      "iata": "",
      "name": "Clay Airport",
      "city": "Monticello",
      "state": "Mississippi",
      "country": "US",
      "elevation": 201,
      "lat": 31.5792999268,
      "lon": -90.1111984253,
      "tz": "America\/Chicago"
  },
  "MS52": {
      "icao": "MS52",
      "iata": "",
      "name": "Leuth Flying Service Airport",
      "city": "Okolona",
      "state": "Mississippi",
      "country": "US",
      "elevation": 310,
      "lat": 33.9236984253,
      "lon": -88.7305984497,
      "tz": "America\/Chicago"
  },
  "MS53": {
      "icao": "MS53",
      "iata": "",
      "name": "Wolf River Ranch Airport",
      "city": "Poplarville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 365,
      "lat": 30.9146003723,
      "lon": -89.4445037842,
      "tz": "America\/Chicago"
  },
  "MS54": {
      "icao": "MS54",
      "iata": "",
      "name": "Burney Farms Airport",
      "city": "Coffeeville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 285,
      "lat": 34.0097999573,
      "lon": -89.7361984253,
      "tz": "America\/Chicago"
  },
  "MS55": {
      "icao": "MS55",
      "iata": "",
      "name": "Schloss Adlers Airport",
      "city": "Red Banks",
      "state": "Mississippi",
      "country": "US",
      "elevation": 478,
      "lat": 34.8109016418,
      "lon": -89.5783996582,
      "tz": "America\/Chicago"
  },
  "MS57": {
      "icao": "MS57",
      "iata": "",
      "name": "Fred Netterville Lbr Co Wilkinson Commnty Airport",
      "city": "Woodville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 120,
      "lat": 31.220199585,
      "lon": -91.2404022217,
      "tz": "America\/Chicago"
  },
  "MS58": {
      "icao": "MS58",
      "iata": "",
      "name": "Tullos Field",
      "city": "Florence",
      "state": "Mississippi",
      "country": "US",
      "elevation": 300,
      "lat": 32.1654014587,
      "lon": -90.0439987183,
      "tz": "America\/Chicago"
  },
  "MS59": {
      "icao": "MS59",
      "iata": "",
      "name": "Valley of The Moon Airport",
      "city": "Hermanville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 125,
      "lat": 31.9969997406,
      "lon": -90.8422012329,
      "tz": "America\/Chicago"
  },
  "MS61": {
      "icao": "MS61",
      "iata": "",
      "name": "Simpson Field",
      "city": "Picayune",
      "state": "Mississippi",
      "country": "US",
      "elevation": 175,
      "lat": 30.6252002716,
      "lon": -89.7333984375,
      "tz": "America\/Chicago"
  },
  "MS62": {
      "icao": "MS62",
      "iata": "",
      "name": "Davis Field",
      "city": "Olive Branch",
      "state": "Mississippi",
      "country": "US",
      "elevation": 410,
      "lat": 34.9911994934,
      "lon": -89.8155975342,
      "tz": "America\/Chicago"
  },
  "MS63": {
      "icao": "MS63",
      "iata": "",
      "name": "Rye Field",
      "city": "Smithville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 330,
      "lat": 34.0542984009,
      "lon": -88.3712005615,
      "tz": "America\/Chicago"
  },
  "MS64": {
      "icao": "MS64",
      "iata": "",
      "name": "Vaiden Landing Airport",
      "city": "Hernando",
      "state": "Mississippi",
      "country": "US",
      "elevation": 250,
      "lat": 34.848342,
      "lon": -89.877992,
      "tz": "America\/Chicago"
  },
  "MS65": {
      "icao": "MS65",
      "iata": "",
      "name": "Delta Flying Service Inc Airport",
      "city": "Walls",
      "state": "Mississippi",
      "country": "US",
      "elevation": 210,
      "lat": 34.9500999451,
      "lon": -90.2001037598,
      "tz": "America\/Chicago"
  },
  "MS66": {
      "icao": "MS66",
      "iata": "",
      "name": "Kennedy Executive Airport",
      "city": "Biloxi",
      "state": "Mississippi",
      "country": "US",
      "elevation": 42,
      "lat": 30.4459991455,
      "lon": -88.9259033203,
      "tz": "America\/Chicago"
  },
  "MS68": {
      "icao": "MS68",
      "iata": "",
      "name": "Lamb's Field",
      "city": "Mantachie",
      "state": "Mississippi",
      "country": "US",
      "elevation": 458,
      "lat": 34.326499939,
      "lon": -88.5725021362,
      "tz": "America\/Chicago"
  },
  "MS69": {
      "icao": "MS69",
      "iata": "",
      "name": "Falcon Field",
      "city": "Star",
      "state": "Mississippi",
      "country": "US",
      "elevation": 415,
      "lat": 32.1087989807,
      "lon": -90.0465011597,
      "tz": "America\/Chicago"
  },
  "MS70": {
      "icao": "MS70",
      "iata": "",
      "name": "Mc Lain  -Calico Airport",
      "city": "Philadelphia",
      "state": "Mississippi",
      "country": "US",
      "elevation": 560,
      "lat": 32.7354011536,
      "lon": -88.9259033203,
      "tz": "America\/Chicago"
  },
  "MS71": {
      "icao": "MS71",
      "iata": "",
      "name": "Slobovia Outernational Airport",
      "city": "Pocahontas",
      "state": "Mississippi",
      "country": "US",
      "elevation": 250,
      "lat": 32.495098114,
      "lon": -90.2929000854,
      "tz": "America\/Chicago"
  },
  "MS74": {
      "icao": "MS74",
      "iata": "",
      "name": "Lee's Field",
      "city": "Picayune",
      "state": "Mississippi",
      "country": "US",
      "elevation": 100,
      "lat": 30.5363006592,
      "lon": -89.5111999512,
      "tz": "America\/Chicago"
  },
  "MS75": {
      "icao": "MS75",
      "iata": "",
      "name": "Lewis Airport",
      "city": "Glen Allan",
      "state": "Mississippi",
      "country": "US",
      "elevation": 112,
      "lat": 33.013999939,
      "lon": -91.0390014648,
      "tz": "America\/Chicago"
  },
  "MS76": {
      "icao": "MS76",
      "iata": "",
      "name": "Wade Field",
      "city": "Calhoun City",
      "state": "Mississippi",
      "country": "US",
      "elevation": 300,
      "lat": 33.7728996277,
      "lon": -89.2425994873,
      "tz": "America\/Chicago"
  },
  "MS77": {
      "icao": "MS77",
      "iata": "",
      "name": "Lang Flying Service Airport",
      "city": "Inverness",
      "state": "Mississippi",
      "country": "US",
      "elevation": 114,
      "lat": 33.3317985535,
      "lon": -90.6432037354,
      "tz": "America\/Chicago"
  },
  "MS80": {
      "icao": "MS80",
      "iata": "",
      "name": "Hasting Airpark",
      "city": "Bruce",
      "state": "Mississippi",
      "country": "US",
      "elevation": 275,
      "lat": 33.9945983887,
      "lon": -89.3125991821,
      "tz": "America\/Chicago"
  },
  "MS82": {
      "icao": "MS82",
      "iata": "",
      "name": "Shade Tree Field",
      "city": "Lyman",
      "state": "Mississippi",
      "country": "US",
      "elevation": 113,
      "lat": 30.5104999542,
      "lon": -89.1473007202,
      "tz": "America\/Chicago"
  },
  "MS83": {
      "icao": "MS83",
      "iata": "",
      "name": "Alton Field",
      "city": "Harrisville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 850,
      "lat": 32.0295982361,
      "lon": -90.1015014648,
      "tz": "America\/Chicago"
  },
  "MS85": {
      "icao": "MS85",
      "iata": "",
      "name": "Skelton Airport",
      "city": "Shaw",
      "state": "Mississippi",
      "country": "US",
      "elevation": 130,
      "lat": 33.5979003906,
      "lon": -90.8029022217,
      "tz": "America\/Chicago"
  },
  "MS86": {
      "icao": "MS86",
      "iata": "",
      "name": "Oglesby Farms Inc. Airport",
      "city": "Glen Allan",
      "state": "Mississippi",
      "country": "US",
      "elevation": 110,
      "lat": 33.0500984192,
      "lon": -91.0833969116,
      "tz": "America\/Chicago"
  },
  "MS88": {
      "icao": "MS88",
      "iata": "",
      "name": "Oreck Airport",
      "city": "Poplarville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 180,
      "lat": 30.7773990631,
      "lon": -89.7250976563,
      "tz": "America\/Chicago"
  },
  "MS90": {
      "icao": "MS90",
      "iata": "",
      "name": "Flowood Industrial STOLport",
      "city": "Flowood",
      "state": "Mississippi",
      "country": "US",
      "elevation": 270,
      "lat": 32.3156013489,
      "lon": -90.1436004639,
      "tz": "America\/Chicago"
  },
  "MS92": {
      "icao": "MS92",
      "iata": "",
      "name": "Heigle Field",
      "city": "Mayersville",
      "state": "Mississippi",
      "country": "US",
      "elevation": 105,
      "lat": 32.9201011658,
      "lon": -91.0257034302,
      "tz": "America\/Chicago"
  },
  "MS93": {
      "icao": "MS93",
      "iata": "",
      "name": "Hooper Skylark Field",
      "city": "Mc Neil",
      "state": "Mississippi",
      "country": "US",
      "elevation": 150,
      "lat": 30.726600647,
      "lon": -89.7316970825,
      "tz": "America\/Chicago"
  },
  "MS95": {
      "icao": "MS95",
      "iata": "",
      "name": "Dorr Field",
      "city": "Merigold",
      "state": "Mississippi",
      "country": "US",
      "elevation": 142,
      "lat": 33.8473014832,
      "lon": -90.7250976563,
      "tz": "America\/Chicago"
  },
  "MS96": {
      "icao": "MS96",
      "iata": "",
      "name": "Barrett Field",
      "city": "Philadelphia",
      "state": "Mississippi",
      "country": "US",
      "elevation": 435,
      "lat": 32.7667999268,
      "lon": -89.1000976563,
      "tz": "America\/Chicago"
  },
  "MS98": {
      "icao": "MS98",
      "iata": "",
      "name": "Farmco Field",
      "city": "Lucedale",
      "state": "Mississippi",
      "country": "US",
      "elevation": 130,
      "lat": 30.7668991089,
      "lon": -88.5999984741,
      "tz": "America\/Chicago"
  },
  "MS99": {
      "icao": "MS99",
      "iata": "",
      "name": "Rocking T Ranch Airport",
      "city": "Bay St. Louis",
      "state": "Mississippi",
      "country": "US",
      "elevation": 14,
      "lat": 30.2558002472,
      "lon": -89.5417022705,
      "tz": "America\/Chicago"
  },
  "MSAC": {
      "icao": "MSAC",
      "iata": "",
      "name": "La Aramuaca Airport",
      "city": "San Miguel",
      "state": "San-Miguel",
      "country": "SV",
      "elevation": 285,
      "lat": 13.4479904175,
      "lon": -88.1201095581,
      "tz": "America\/El_Salvador"
  },
  "MSBS": {
      "icao": "MSBS",
      "iata": "",
      "name": "Barillas Airport",
      "city": "Barrillas",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 32,
      "lat": 13.2652626038,
      "lon": -88.499130249,
      "tz": "America\/El_Salvador"
  },
  "MSCD": {
      "icao": "MSCD",
      "iata": "",
      "name": "Ceiba Doblada Airport",
      "city": "Ceiba Doblada",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 10,
      "lat": 13.213010788,
      "lon": -88.6028137207,
      "tz": "America\/El_Salvador"
  },
  "MSCH": {
      "icao": "MSCH",
      "iata": "",
      "name": "La Chepona Airport",
      "city": "La Chepona",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 13,
      "lat": 13.1880998611,
      "lon": -88.4115982056,
      "tz": "America\/El_Salvador"
  },
  "MSCM": {
      "icao": "MSCM",
      "iata": "",
      "name": "Corral De Mulas Airport",
      "city": "Corral de Mulas",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 26,
      "lat": 13.2055883408,
      "lon": -88.5473403931,
      "tz": "America\/El_Salvador"
  },
  "MSCN": {
      "icao": "MSCN",
      "iata": "",
      "name": "Casas Nuevas Airport",
      "city": "Jiquilisco",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 157,
      "lat": 13.3099727631,
      "lon": -88.5058670044,
      "tz": "America\/El_Salvador"
  },
  "MSCR": {
      "icao": "MSCR",
      "iata": "",
      "name": "La Carrera Airport",
      "city": "Jiquilisco",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 254,
      "lat": 13.3287029266,
      "lon": -88.5209197998,
      "tz": "America\/El_Salvador"
  },
  "MSES": {
      "icao": "MSES",
      "iata": "",
      "name": "Espiritu Santo Airport",
      "city": "Espiritu Santo",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 1,
      "lat": 13.2193241119,
      "lon": -88.5540466309,
      "tz": "America\/El_Salvador"
  },
  "MSET": {
      "icao": "MSET",
      "iata": "",
      "name": "El Tamarindo Airport",
      "city": "Canoguero",
      "state": "La-Union",
      "country": "SV",
      "elevation": 10,
      "lat": 13.1625995636,
      "lon": -87.904800415,
      "tz": "America\/El_Salvador"
  },
  "MSJC": {
      "icao": "MSJC",
      "iata": "",
      "name": "El Jocotillo Airport",
      "city": "El Jocotillo",
      "state": "Sonsonate",
      "country": "SV",
      "elevation": 127,
      "lat": 13.5861539841,
      "lon": -89.7465209961,
      "tz": "America\/El_Salvador"
  },
  "MSLC": {
      "icao": "MSLC",
      "iata": "",
      "name": "La Cabana Airport",
      "city": "La Cabana",
      "state": "San-Salvador",
      "country": "SV",
      "elevation": 885,
      "lat": 14.0098104477,
      "lon": -89.1847610474,
      "tz": "America\/El_Salvador"
  },
  "MSLD": {
      "icao": "MSLD",
      "iata": "",
      "name": "Los Comandos Airport",
      "city": "San Francisco Gotera",
      "state": "Morazan",
      "country": "SV",
      "elevation": 919,
      "lat": 13.7262001038,
      "lon": -88.1066970825,
      "tz": "America\/El_Salvador"
  },
  "MSLG": {
      "icao": "MSLG",
      "iata": "",
      "name": "La Gloria Airport",
      "city": "San Salvador",
      "state": "San-Salvador",
      "country": "SV",
      "elevation": 2163,
      "lat": 13.7147397995,
      "lon": -89.1913452148,
      "tz": "America\/El_Salvador"
  },
  "MSLM": {
      "icao": "MSLM",
      "iata": "",
      "name": "Las Mesas Airport",
      "city": "Las Mesas",
      "state": "La-Libertad",
      "country": "SV",
      "elevation": 187,
      "lat": 13.4870700836,
      "lon": -89.1926574707,
      "tz": "America\/El_Salvador"
  },
  "MSLP": {
      "icao": "MSLP",
      "iata": "SAL",
      "name": "El Salvador International Airport",
      "city": "Santa Clara",
      "state": "La-Paz",
      "country": "SV",
      "elevation": 101,
      "lat": 13.4408998489,
      "lon": -89.0557022095,
      "tz": "America\/El_Salvador"
  },
  "MSPT": {
      "icao": "MSPT",
      "iata": "",
      "name": "El Platanar Airport",
      "city": "Suchitoto",
      "state": "Cuscatlan",
      "country": "SV",
      "elevation": 1354,
      "lat": 13.9453001022,
      "lon": -89.0627975464,
      "tz": "America\/El_Salvador"
  },
  "MSRC": {
      "icao": "MSRC",
      "iata": "",
      "name": "El Ronco Airport",
      "city": "Metapan",
      "state": "Santa-Ana",
      "country": "SV",
      "elevation": 1460,
      "lat": 14.3187150955,
      "lon": -89.5068969727,
      "tz": "America\/El_Salvador"
  },
  "MSSA": {
      "icao": "MSSA",
      "iata": "",
      "name": "El Palmer Airport",
      "city": "Santa Ana",
      "state": "Santa-Ana",
      "country": "SV",
      "elevation": 2229,
      "lat": 14.0672222222,
      "lon": -89.6322222222,
      "tz": "America\/El_Salvador"
  },
  "MSSJ": {
      "icao": "MSSJ",
      "iata": "",
      "name": "Punta San Juan Airport",
      "city": "Corral de Mulas",
      "state": "Usulutan",
      "country": "SV",
      "elevation": 3,
      "lat": 13.1787347794,
      "lon": -88.47315979,
      "tz": "America\/El_Salvador"
  },
  "MSSM": {
      "icao": "MSSM",
      "iata": "",
      "name": "El Papalon Airport",
      "city": "San Miguel",
      "state": "San-Miguel",
      "country": "SV",
      "elevation": 283,
      "lat": 13.4441003799,
      "lon": -88.1269989014,
      "tz": "America\/El_Salvador"
  },
  "MSSN": {
      "icao": "MSSN",
      "iata": "",
      "name": "San Ramon Airport",
      "city": "San Ramon",
      "state": "La-Union",
      "country": "SV",
      "elevation": 37,
      "lat": 13.1974658966,
      "lon": -87.9609146118,
      "tz": "America\/El_Salvador"
  },
  "MSSS": {
      "icao": "MSSS",
      "iata": "ILS",
      "name": "Ilopango International Airport",
      "city": "San Salvador",
      "state": "San-Salvador",
      "country": "SV",
      "elevation": 2021,
      "lat": 13.6995000839,
      "lon": -89.1199035645,
      "tz": "America\/El_Salvador"
  },
  "MSZT": {
      "icao": "MSZT",
      "iata": "",
      "name": "El Zapote Airport",
      "city": "Barra de Santiago",
      "state": "Ahuachapan",
      "country": "SV",
      "elevation": 6,
      "lat": 13.7079544067,
      "lon": -90.0262680054,
      "tz": "America\/El_Salvador"
  },
  "MT01": {
      "icao": "MT01",
      "iata": "",
      "name": "Owen Bros Airport",
      "city": "Geraldine",
      "state": "Montana",
      "country": "US",
      "elevation": 3106,
      "lat": 47.6180000305,
      "lon": -110.0920028687,
      "tz": "America\/Denver"
  },
  "MT02": {
      "icao": "MT02",
      "iata": "",
      "name": "Hellinger Airport",
      "city": "Devon",
      "state": "Montana",
      "country": "US",
      "elevation": 3215,
      "lat": 48.5382995605,
      "lon": -111.4970016479,
      "tz": "America\/Denver"
  },
  "MT03": {
      "icao": "MT03",
      "iata": "",
      "name": "Lakeside Airport",
      "city": "Lakeside",
      "state": "Montana",
      "country": "US",
      "elevation": 3440,
      "lat": 47.993408,
      "lon": -114.224306,
      "tz": "America\/Denver"
  },
  "MT04": {
      "icao": "MT04",
      "iata": "",
      "name": "Olfert Airport",
      "city": "Lustre",
      "state": "Montana",
      "country": "US",
      "elevation": 2750,
      "lat": 48.4042015076,
      "lon": -106.0090026855,
      "tz": "America\/Denver"
  },
  "MT05": {
      "icao": "MT05",
      "iata": "",
      "name": "Laird Ranch Airport",
      "city": "Ekalaka",
      "state": "Montana",
      "country": "US",
      "elevation": 3462,
      "lat": 45.6680984497,
      "lon": -104.7030029297,
      "tz": "America\/Denver"
  },
  "MT06": {
      "icao": "MT06",
      "iata": "",
      "name": "Duncan Airport",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 4260,
      "lat": 46.5702018738,
      "lon": -112.1750030518,
      "tz": "America\/Denver"
  },
  "MT07": {
      "icao": "MT07",
      "iata": "",
      "name": "Langhus Airstrip",
      "city": "Melville",
      "state": "Montana",
      "country": "US",
      "elevation": 5785,
      "lat": 46.0415992737,
      "lon": -110.158996582,
      "tz": "America\/Denver"
  },
  "MT08": {
      "icao": "MT08",
      "iata": "",
      "name": "Zortman Airport",
      "city": "Zortman",
      "state": "Montana",
      "country": "US",
      "elevation": 3900,
      "lat": 47.9166984558,
      "lon": -108.483001709,
      "tz": "America\/Denver"
  },
  "MT09": {
      "icao": "MT09",
      "iata": "",
      "name": "Floyd Ranch Airport",
      "city": "Opheim",
      "state": "Montana",
      "country": "US",
      "elevation": 2948,
      "lat": 48.8459014893,
      "lon": -106.6090011597,
      "tz": "America\/Denver"
  },
  "MT11": {
      "icao": "MT11",
      "iata": "",
      "name": "Etchart Field",
      "city": "Tampico",
      "state": "Montana",
      "country": "US",
      "elevation": 2157,
      "lat": 48.3166999817,
      "lon": -106.8339996338,
      "tz": "America\/Denver"
  },
  "MT12": {
      "icao": "MT12",
      "iata": "",
      "name": "Smith Field",
      "city": "Butte",
      "state": "Montana",
      "country": "US",
      "elevation": 6000,
      "lat": 45.9384994507,
      "lon": -112.5640029907,
      "tz": "America\/Denver"
  },
  "MT14": {
      "icao": "MT14",
      "iata": "",
      "name": "Jerry Creek Airport",
      "city": "Wise River",
      "state": "Montana",
      "country": "US",
      "elevation": 5940,
      "lat": 45.8235015869,
      "lon": -112.8860015869,
      "tz": "America\/Denver"
  },
  "MT15": {
      "icao": "MT15",
      "iata": "",
      "name": "Fort Harrison Army Airfield",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 4050,
      "lat": 46.6258010864,
      "lon": -112.11000061,
      "tz": "America\/Denver"
  },
  "MT16": {
      "icao": "MT16",
      "iata": "",
      "name": "Buffalo Trail Ranch Strip",
      "city": "Ovando",
      "state": "Montana",
      "country": "US",
      "elevation": 3906,
      "lat": 47.0138015747,
      "lon": -113.2829971313,
      "tz": "America\/Denver"
  },
  "MT17": {
      "icao": "MT17",
      "iata": "",
      "name": "Wurtz Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 3800,
      "lat": 48.9049987793,
      "lon": -114.3820037842,
      "tz": "America\/Denver"
  },
  "MT18": {
      "icao": "MT18",
      "iata": "",
      "name": "Hoerner Airport",
      "city": "Columbia Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 3078,
      "lat": 48.28995,
      "lon": -114.182867,
      "tz": "America\/Denver"
  },
  "MT19": {
      "icao": "MT19",
      "iata": "",
      "name": "Olson Airport",
      "city": "Ronan",
      "state": "Montana",
      "country": "US",
      "elevation": 3330,
      "lat": 47.5909996033,
      "lon": -114.0520019531,
      "tz": "America\/Denver"
  },
  "MT20": {
      "icao": "MT20",
      "iata": "",
      "name": "Hollstein Ranch Airport",
      "city": "Wibaux",
      "state": "Montana",
      "country": "US",
      "elevation": 2778,
      "lat": 46.8582992554,
      "lon": -104.0989990234,
      "tz": "America\/Denver"
  },
  "MT21": {
      "icao": "MT21",
      "iata": "",
      "name": "Flying Arrow Ranch Airport",
      "city": "Butte",
      "state": "Montana",
      "country": "US",
      "elevation": 5950,
      "lat": 45.901599884,
      "lon": -112.5490036011,
      "tz": "America\/Denver"
  },
  "MT22": {
      "icao": "MT22",
      "iata": "",
      "name": "Tezak's-Colterville-Spur Airport",
      "city": "Sheridan",
      "state": "Montana",
      "country": "US",
      "elevation": 5000,
      "lat": 45.3998985291,
      "lon": -112.1669998169,
      "tz": "America\/Denver"
  },
  "MT24": {
      "icao": "MT24",
      "iata": "",
      "name": "Beacon Star Antique Airfield",
      "city": "Moore",
      "state": "Montana",
      "country": "US",
      "elevation": 4320,
      "lat": 46.9721984863,
      "lon": -109.6139984131,
      "tz": "America\/Denver"
  },
  "MT26": {
      "icao": "MT26",
      "iata": "",
      "name": "Ranch Strip",
      "city": "Great Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 3355,
      "lat": 47.470500946,
      "lon": -111.2369995117,
      "tz": "America\/Denver"
  },
  "MT29": {
      "icao": "MT29",
      "iata": "",
      "name": "Sunday Creek Airpark",
      "city": "Miles City",
      "state": "Montana",
      "country": "US",
      "elevation": 2490,
      "lat": 46.4789009094,
      "lon": -105.858001709,
      "tz": "America\/Denver"
  },
  "MT30": {
      "icao": "MT30",
      "iata": "",
      "name": "Trapper Creek Strip",
      "city": "Conner",
      "state": "Montana",
      "country": "US",
      "elevation": 4040,
      "lat": 45.9402008057,
      "lon": -114.1370010376,
      "tz": "America\/Denver"
  },
  "MT32": {
      "icao": "MT32",
      "iata": "",
      "name": "Ckye Field",
      "city": "Stevensville",
      "state": "Montana",
      "country": "US",
      "elevation": 3600,
      "lat": 46.5387992859,
      "lon": -114.0400009155,
      "tz": "America\/Denver"
  },
  "MT33": {
      "icao": "MT33",
      "iata": "",
      "name": "Bair Airport",
      "city": "Ulm",
      "state": "Montana",
      "country": "US",
      "elevation": 3340,
      "lat": 47.4094009399,
      "lon": -111.4869995117,
      "tz": "America\/Denver"
  },
  "MT34": {
      "icao": "MT34",
      "iata": "",
      "name": "Ruff Airport",
      "city": "Custer",
      "state": "Montana",
      "country": "US",
      "elevation": 2740,
      "lat": 46.1333007813,
      "lon": -107.5510025024,
      "tz": "America\/Denver"
  },
  "MT36": {
      "icao": "MT36",
      "iata": "",
      "name": "Cottontail Ranch Airport",
      "city": "Melville",
      "state": "Montana",
      "country": "US",
      "elevation": 5340,
      "lat": 46.1321983337,
      "lon": -110.0469970703,
      "tz": "America\/Denver"
  },
  "MT37": {
      "icao": "MT37",
      "iata": "",
      "name": "Sanders Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2910,
      "lat": 48.1246986389,
      "lon": -114.2399978638,
      "tz": "America\/Denver"
  },
  "MT38": {
      "icao": "MT38",
      "iata": "",
      "name": "Swank Airport",
      "city": "Poplar",
      "state": "Montana",
      "country": "US",
      "elevation": 2205,
      "lat": 48.4034004211,
      "lon": -105.1569976807,
      "tz": "America\/Denver"
  },
  "MT39": {
      "icao": "MT39",
      "iata": "",
      "name": "Black Canyon Ranch Airport",
      "city": "Seeley Lake",
      "state": "Montana",
      "country": "US",
      "elevation": 4260,
      "lat": 47.1059989929,
      "lon": -113.3170013428,
      "tz": "America\/Denver"
  },
  "MT40": {
      "icao": "MT40",
      "iata": "",
      "name": "Horner Field",
      "city": "Great Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 3430,
      "lat": 47.5913009644,
      "lon": -111.2720031738,
      "tz": "America\/Denver"
  },
  "MT41": {
      "icao": "MT41",
      "iata": "",
      "name": "Jefco Skypark Airport",
      "city": "Whitehall",
      "state": "Montana",
      "country": "US",
      "elevation": 4510,
      "lat": 45.8782997131,
      "lon": -112.1169967651,
      "tz": "America\/Denver"
  },
  "MT42": {
      "icao": "MT42",
      "iata": "",
      "name": "Hasskamp STOLport",
      "city": "Three Forks",
      "state": "Montana",
      "country": "US",
      "elevation": 4052,
      "lat": 45.9104995728,
      "lon": -111.513999939,
      "tz": "America\/Denver"
  },
  "MT43": {
      "icao": "MT43",
      "iata": "",
      "name": "Klies Air Strip",
      "city": "Basin",
      "state": "Montana",
      "country": "US",
      "elevation": 6300,
      "lat": 46.3462982178,
      "lon": -112.34400177,
      "tz": "America\/Denver"
  },
  "MT45": {
      "icao": "MT45",
      "iata": "",
      "name": "Castleberry Airport",
      "city": "Ekalaka",
      "state": "Montana",
      "country": "US",
      "elevation": 3373,
      "lat": 45.9089012146,
      "lon": -104.5759963989,
      "tz": "America\/Denver"
  },
  "MT46": {
      "icao": "MT46",
      "iata": "",
      "name": "Lakeview Airport",
      "city": "Lakeview",
      "state": "Montana",
      "country": "US",
      "elevation": 6630,
      "lat": 44.617099762,
      "lon": -111.797996521,
      "tz": "America\/Denver"
  },
  "MT47": {
      "icao": "MT47",
      "iata": "",
      "name": "Metzel Creek Airport",
      "city": "Lakeview",
      "state": "Montana",
      "country": "US",
      "elevation": 6691,
      "lat": 44.6869010925,
      "lon": -111.8939971924,
      "tz": "America\/Denver"
  },
  "MT48": {
      "icao": "MT48",
      "iata": "",
      "name": "Flying Y Ranch Airport",
      "city": "Livingston",
      "state": "Montana",
      "country": "US",
      "elevation": 4857,
      "lat": 45.4347000122,
      "lon": -110.6439971924,
      "tz": "America\/Denver"
  },
  "MT49": {
      "icao": "MT49",
      "iata": "",
      "name": "Ford's South Airport",
      "city": "Lolo",
      "state": "Montana",
      "country": "US",
      "elevation": 3200,
      "lat": 46.923500061,
      "lon": -114.0879974365,
      "tz": "America\/Denver"
  },
  "MT50": {
      "icao": "MT50",
      "iata": "",
      "name": "Lanning Ranch Airport",
      "city": "Alzada",
      "state": "Montana",
      "country": "US",
      "elevation": 3995,
      "lat": 45.0877990723,
      "lon": -104.8190002441,
      "tz": "America\/Denver"
  },
  "MT51": {
      "icao": "MT51",
      "iata": "",
      "name": "Zerbe Airport",
      "city": "Frazer",
      "state": "Montana",
      "country": "US",
      "elevation": 2720,
      "lat": 48.2770004272,
      "lon": -105.9619979858,
      "tz": "America\/Denver"
  },
  "MT52": {
      "icao": "MT52",
      "iata": "",
      "name": "Nine Mile Airport",
      "city": "Huson",
      "state": "Montana",
      "country": "US",
      "elevation": 3364,
      "lat": 47.0777015686,
      "lon": -114.4130020142,
      "tz": "America\/Denver"
  },
  "MT53": {
      "icao": "MT53",
      "iata": "",
      "name": "Carson Field",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 3550,
      "lat": 48.0946998596,
      "lon": -114.8519973755,
      "tz": "America\/Denver"
  },
  "MT54": {
      "icao": "MT54",
      "iata": "",
      "name": "Weaver Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2929,
      "lat": 48.2439002991,
      "lon": -114.2440032959,
      "tz": "America\/Denver"
  },
  "MT58": {
      "icao": "MT58",
      "iata": "",
      "name": "Nelson Airport",
      "city": "Haxby \/ Fort Peck",
      "state": "Montana",
      "country": "US",
      "elevation": 2425,
      "lat": 47.7667007446,
      "lon": -106.18800354,
      "tz": "America\/Denver"
  },
  "MT59": {
      "icao": "MT59",
      "iata": "",
      "name": "Gardner Airport",
      "city": "Hammond",
      "state": "Montana",
      "country": "US",
      "elevation": 3560,
      "lat": 45.26720047,
      "lon": -104.8570022583,
      "tz": "America\/Denver"
  },
  "MT60": {
      "icao": "MT60",
      "iata": "",
      "name": "Langton Airstrip",
      "city": "Polebridge",
      "state": "Montana",
      "country": "US",
      "elevation": 3900,
      "lat": 48.8143997192,
      "lon": -114.3539962769,
      "tz": "America\/Denver"
  },
  "MT62": {
      "icao": "MT62",
      "iata": "",
      "name": "Ted Luark Private STOLport",
      "city": "Huson",
      "state": "Montana",
      "country": "US",
      "elevation": 3500,
      "lat": 47.0666007996,
      "lon": -114.4840011597,
      "tz": "America\/Denver"
  },
  "MT63": {
      "icao": "MT63",
      "iata": "",
      "name": "Lee Metcalf National Wildlife Refuge Airport",
      "city": "Stevensville",
      "state": "Montana",
      "country": "US",
      "elevation": 3315,
      "lat": 46.5555000305,
      "lon": -114.0729980469,
      "tz": "America\/Denver"
  },
  "MT68": {
      "icao": "MT68",
      "iata": "",
      "name": "Wood Strip",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 3850,
      "lat": 46.6208000183,
      "lon": -111.93800354,
      "tz": "America\/Denver"
  },
  "MT71": {
      "icao": "MT71",
      "iata": "",
      "name": "Edsall Field",
      "city": "Bozeman",
      "state": "Montana",
      "country": "US",
      "elevation": 4659,
      "lat": 45.7240982056,
      "lon": -111.0429992676,
      "tz": "America\/Denver"
  },
  "MT72": {
      "icao": "MT72",
      "iata": "",
      "name": "Hedditch Airport",
      "city": "Corvallis",
      "state": "Montana",
      "country": "US",
      "elevation": 3663,
      "lat": 46.3460006714,
      "lon": -114.1760025024,
      "tz": "America\/Denver"
  },
  "MT73": {
      "icao": "MT73",
      "iata": "",
      "name": "Camas Airport",
      "city": "Missoula",
      "state": "Montana",
      "country": "US",
      "elevation": 3870,
      "lat": 46.8507995605,
      "lon": -113.5380020142,
      "tz": "America\/Denver"
  },
  "MT74": {
      "icao": "MT74",
      "iata": "",
      "name": "Sikorski Ranch Airport",
      "city": "Ekalaka",
      "state": "Montana",
      "country": "US",
      "elevation": 3330,
      "lat": 46.1068992615,
      "lon": -104.4150009155,
      "tz": "America\/Denver"
  },
  "MT75": {
      "icao": "MT75",
      "iata": "",
      "name": "Buchanan Ranch Airport",
      "city": "Great Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 3345,
      "lat": 47.4301986694,
      "lon": -111.4599990845,
      "tz": "America\/Denver"
  },
  "MT76": {
      "icao": "MT76",
      "iata": "",
      "name": "Smith Farms Airport",
      "city": "Great Falls",
      "state": "Montana",
      "country": "US",
      "elevation": 3340,
      "lat": 47.3997001648,
      "lon": -111.4700012207,
      "tz": "America\/Denver"
  },
  "MT77": {
      "icao": "MT77",
      "iata": "",
      "name": "Peterson Ranch Airport",
      "city": "Highwood",
      "state": "Montana",
      "country": "US",
      "elevation": 3340,
      "lat": 47.6082992554,
      "lon": -110.90599823,
      "tz": "America\/Denver"
  },
  "MT79": {
      "icao": "MT79",
      "iata": "",
      "name": "Sand Creek Wildlife Station Airport",
      "city": "Roy",
      "state": "Montana",
      "country": "US",
      "elevation": 2940,
      "lat": 47.5842018127,
      "lon": -108.7089996338,
      "tz": "America\/Denver"
  },
  "MT80": {
      "icao": "MT80",
      "iata": "",
      "name": "Vine Airport",
      "city": "Wolf Point",
      "state": "Montana",
      "country": "US",
      "elevation": 2075,
      "lat": 48.0430984497,
      "lon": -105.6090011597,
      "tz": "America\/Denver"
  },
  "MT81": {
      "icao": "MT81",
      "iata": "",
      "name": "Thompson Field",
      "city": "Belgrade",
      "state": "Montana",
      "country": "US",
      "elevation": 4400,
      "lat": 45.8035011292,
      "lon": -111.1380004883,
      "tz": "America\/Denver"
  },
  "MT82": {
      "icao": "MT82",
      "iata": "",
      "name": "Baxter Strip",
      "city": "Harlowton",
      "state": "Montana",
      "country": "US",
      "elevation": 4520,
      "lat": 46.3083000183,
      "lon": -109.8720016479,
      "tz": "America\/Denver"
  },
  "MT83": {
      "icao": "MT83",
      "iata": "",
      "name": "Ten Mile Airport",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 4294,
      "lat": 46.5716018677,
      "lon": -112.1809997559,
      "tz": "America\/Denver"
  },
  "MT84": {
      "icao": "MT84",
      "iata": "",
      "name": "Mower Field",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 4000,
      "lat": 48.2207984924,
      "lon": -114.3759994507,
      "tz": "America\/Denver"
  },
  "MT85": {
      "icao": "MT85",
      "iata": "",
      "name": "Redfield Ag Strip",
      "city": "Opheim",
      "state": "Montana",
      "country": "US",
      "elevation": 3100,
      "lat": 48.7588996887,
      "lon": -106.364997864,
      "tz": "America\/Denver"
  },
  "MT86": {
      "icao": "MT86",
      "iata": "",
      "name": "Bar E Airport",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 3875,
      "lat": 47.1689987183,
      "lon": -112.0849990845,
      "tz": "America\/Denver"
  },
  "MT87": {
      "icao": "MT87",
      "iata": "",
      "name": "Oglesby Farms Inc Airport",
      "city": "Peerless",
      "state": "Montana",
      "country": "US",
      "elevation": 2850,
      "lat": 48.6666984558,
      "lon": -105.9339981079,
      "tz": "America\/Denver"
  },
  "MT88": {
      "icao": "MT88",
      "iata": "",
      "name": "Campbell Ranch Airport",
      "city": "Marion",
      "state": "Montana",
      "country": "US",
      "elevation": 3360,
      "lat": 48.0043983459,
      "lon": -114.9990005493,
      "tz": "America\/Denver"
  },
  "MT89": {
      "icao": "MT89",
      "iata": "",
      "name": "Foster Ranches Airport",
      "city": "Livingston",
      "state": "Montana",
      "country": "US",
      "elevation": 4680,
      "lat": 45.7999000549,
      "lon": -110.533996582,
      "tz": "America\/Denver"
  },
  "MT90": {
      "icao": "MT90",
      "iata": "",
      "name": "J B Airport",
      "city": "Wolf Point",
      "state": "Montana",
      "country": "US",
      "elevation": 2710,
      "lat": 48.3877983093,
      "lon": -105.3349990845,
      "tz": "America\/Denver"
  },
  "MT93": {
      "icao": "MT93",
      "iata": "",
      "name": "Larner Field",
      "city": "Deer Lodge",
      "state": "Montana",
      "country": "US",
      "elevation": 4710,
      "lat": 46.2871017456,
      "lon": -112.7580032349,
      "tz": "America\/Denver"
  },
  "MT94": {
      "icao": "MT94",
      "iata": "",
      "name": "Ousel Falls Airport",
      "city": "Big Sky",
      "state": "Montana",
      "country": "US",
      "elevation": 6600,
      "lat": 45.2466011047,
      "lon": -111.3290023804,
      "tz": "America\/Denver"
  },
  "MT95": {
      "icao": "MT95",
      "iata": "",
      "name": "Flathead Lake Sky Ranch Airport",
      "city": "Kalispell",
      "state": "Montana",
      "country": "US",
      "elevation": 2905,
      "lat": 48.1169013977,
      "lon": -114.1859970093,
      "tz": "America\/Denver"
  },
  "MT96": {
      "icao": "MT96",
      "iata": "",
      "name": "Wilhelm Airstrip",
      "city": "Helena",
      "state": "Montana",
      "country": "US",
      "elevation": 4000,
      "lat": 46.7402000427,
      "lon": -112.0350036621,
      "tz": "America\/Denver"
  },
  "MT97": {
      "icao": "MT97",
      "iata": "",
      "name": "Frampton Airport",
      "city": "Noxon",
      "state": "Montana",
      "country": "US",
      "elevation": 2400,
      "lat": 47.9785995483,
      "lon": -115.7679977417,
      "tz": "America\/Denver"
  },
  "MT98": {
      "icao": "MT98",
      "iata": "",
      "name": "Big Beaver Creek Ranch Airport",
      "city": "Trout Creek",
      "state": "Montana",
      "country": "US",
      "elevation": 2600,
      "lat": 47.6980018616,
      "lon": -115.5510025024,
      "tz": "America\/Denver"
  },
  "MTCA": {
      "icao": "MTCA",
      "iata": "CYA",
      "name": "Les Cayes Airport",
      "city": "Les Cayes",
      "state": "Sud",
      "country": "HT",
      "elevation": 203,
      "lat": 18.2710990906,
      "lon": -73.7882995605,
      "tz": "America\/Port-au-Prince"
  },
  "MTCH": {
      "icao": "MTCH",
      "iata": "CAP",
      "name": "Cap Haitien International Airport",
      "city": "Cap Haitien",
      "state": "Nord",
      "country": "HT",
      "elevation": 10,
      "lat": 19.7329998016,
      "lon": -72.1947021484,
      "tz": "America\/Port-au-Prince"
  },
  "MTJA": {
      "icao": "MTJA",
      "iata": "JAK",
      "name": "Jacmel Airport",
      "city": "Jacmel",
      "state": "Sud-Est",
      "country": "HT",
      "elevation": 167,
      "lat": 18.2411003113,
      "lon": -72.5185012817,
      "tz": "America\/Port-au-Prince"
  },
  "MTJE": {
      "icao": "MTJE",
      "iata": "JEE",
      "name": "Jeremie Airport",
      "city": "Jeremie",
      "state": "Grand\u02bcAnse",
      "country": "HT",
      "elevation": 147,
      "lat": 18.6630992889,
      "lon": -74.1703033447,
      "tz": "America\/Port-au-Prince"
  },
  "MTPP": {
      "icao": "MTPP",
      "iata": "PAP",
      "name": "Toussaint Louverture International Airport",
      "city": "Port-au-Prince",
      "state": "Ouest",
      "country": "HT",
      "elevation": 122,
      "lat": 18.5799999237,
      "lon": -72.2925033569,
      "tz": "America\/Port-au-Prince"
  },
  "MTPX": {
      "icao": "MTPX",
      "iata": "PAX",
      "name": "Port-de-Paix Airport",
      "city": "Port-de-Paix",
      "state": "Nord-Ouest",
      "country": "HT",
      "elevation": 9,
      "lat": 19.933599472,
      "lon": -72.8486022949,
      "tz": "America\/Port-au-Prince"
  },
  "MTRH": {
      "icao": "MTRH",
      "iata": "",
      "name": "Trojes Airport",
      "city": "Trojes",
      "state": "Yoro",
      "country": "HN",
      "elevation": 170,
      "lat": 15.4044437408,
      "lon": -86.6827774048,
      "tz": "America\/Tegucigalpa"
  },
  "MU00": {
      "icao": "MU00",
      "iata": "",
      "name": "Samuel L. Clemens Memorial Airport",
      "city": "Paris",
      "state": "Missouri",
      "country": "US",
      "elevation": 730,
      "lat": 39.4444999695,
      "lon": -91.9517974854,
      "tz": "America\/Chicago"
  },
  "MU02": {
      "icao": "MU02",
      "iata": "",
      "name": "C.E.F. Airport",
      "city": "Warrenton",
      "state": "Missouri",
      "country": "US",
      "elevation": 638,
      "lat": 38.8097991943,
      "lon": -91.1035003662,
      "tz": "America\/Chicago"
  },
  "MU03": {
      "icao": "MU03",
      "iata": "",
      "name": "Leo's Angus Ranch Airport",
      "city": "Dayton",
      "state": "Missouri",
      "country": "US",
      "elevation": 900,
      "lat": 38.4799995422,
      "lon": -94.1974029541,
      "tz": "America\/Chicago"
  },
  "MU04": {
      "icao": "MU04",
      "iata": "",
      "name": "Broadwater Airport",
      "city": "Malden",
      "state": "Missouri",
      "country": "US",
      "elevation": 280,
      "lat": 36.5898017883,
      "lon": -89.9200973511,
      "tz": "America\/Chicago"
  },
  "MU07": {
      "icao": "MU07",
      "iata": "",
      "name": "Angle Bar M Airport",
      "city": "Adrian",
      "state": "Missouri",
      "country": "US",
      "elevation": 900,
      "lat": 38.3946990967,
      "lon": -94.3102035522,
      "tz": "America\/Chicago"
  },
  "MU09": {
      "icao": "MU09",
      "iata": "",
      "name": "Hester Airport",
      "city": "Burlington Junction",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 40.4667015076,
      "lon": -95.0211029053,
      "tz": "America\/Chicago"
  },
  "MU11": {
      "icao": "MU11",
      "iata": "",
      "name": "Eagle's Landing Airport",
      "city": "Climax Springs",
      "state": "Missouri",
      "country": "US",
      "elevation": 822,
      "lat": 38.2209014893,
      "lon": -93.0155029297,
      "tz": "America\/Chicago"
  },
  "MU12": {
      "icao": "MU12",
      "iata": "",
      "name": "Childress Airstrip",
      "city": "Springfield",
      "state": "Missouri",
      "country": "US",
      "elevation": 1400,
      "lat": 37.1403007507,
      "lon": -93.2154998779,
      "tz": "America\/Chicago"
  },
  "MU13": {
      "icao": "MU13",
      "iata": "",
      "name": "Bakers Landing Airport",
      "city": "Billings",
      "state": "Missouri",
      "country": "US",
      "elevation": 1340,
      "lat": 37.0597991943,
      "lon": -93.6009979248,
      "tz": "America\/Chicago"
  },
  "MU16": {
      "icao": "MU16",
      "iata": "",
      "name": "Spillman Field",
      "city": "Jamesport",
      "state": "Missouri",
      "country": "US",
      "elevation": 981,
      "lat": 40.0003013611,
      "lon": -93.7899017334,
      "tz": "America\/Chicago"
  },
  "MU18": {
      "icao": "MU18",
      "iata": "",
      "name": "Stark Airport",
      "city": "Warrenton",
      "state": "Missouri",
      "country": "US",
      "elevation": 840,
      "lat": 38.7806015015,
      "lon": -91.1473999023,
      "tz": "America\/Chicago"
  },
  "MU19": {
      "icao": "MU19",
      "iata": "",
      "name": "Cherokee Airpark",
      "city": "Bolivar",
      "state": "Missouri",
      "country": "US",
      "elevation": 1150,
      "lat": 37.551700592,
      "lon": -93.4001998901,
      "tz": "America\/Chicago"
  },
  "MU20": {
      "icao": "MU20",
      "iata": "",
      "name": "Robbins Airport",
      "city": "Odessa",
      "state": "Missouri",
      "country": "US",
      "elevation": 910,
      "lat": 38.9455986023,
      "lon": -93.9646987915,
      "tz": "America\/Chicago"
  },
  "MU21": {
      "icao": "MU21",
      "iata": "",
      "name": "Miller Farm Airport",
      "city": "Bay",
      "state": "Missouri",
      "country": "US",
      "elevation": 630,
      "lat": 38.5237007141,
      "lon": -91.5177001953,
      "tz": "America\/Chicago"
  },
  "MU23": {
      "icao": "MU23",
      "iata": "",
      "name": "White Cloud Flying Field",
      "city": "Columbia",
      "state": "Missouri",
      "country": "US",
      "elevation": 600,
      "lat": 39.0306015015,
      "lon": -92.3917999268,
      "tz": "America\/Chicago"
  },
  "MU24": {
      "icao": "MU24",
      "iata": "",
      "name": "Someday Ranch Airport",
      "city": "Cedarcreek",
      "state": "Missouri",
      "country": "US",
      "elevation": 812,
      "lat": 36.5717010498,
      "lon": -93.0367965698,
      "tz": "America\/Chicago"
  },
  "MU25": {
      "icao": "MU25",
      "iata": "",
      "name": "Piney Bend Airport",
      "city": "Houston",
      "state": "Missouri",
      "country": "US",
      "elevation": 950,
      "lat": 37.4378013611,
      "lon": -91.9493026733,
      "tz": "America\/Chicago"
  },
  "MU26": {
      "icao": "MU26",
      "iata": "",
      "name": "Robinson Airport",
      "city": "Belton",
      "state": "Missouri",
      "country": "US",
      "elevation": 1080,
      "lat": 38.7731018066,
      "lon": -94.5652999878,
      "tz": "America\/Chicago"
  },
  "MU27": {
      "icao": "MU27",
      "iata": "",
      "name": "Woodfield Airpark Inc Airport",
      "city": "Lockwood",
      "state": "Missouri",
      "country": "US",
      "elevation": 1050,
      "lat": 37.4681015015,
      "lon": -93.929397583,
      "tz": "America\/Chicago"
  },
  "MU32": {
      "icao": "MU32",
      "iata": "",
      "name": "Sugar Branch Airport",
      "city": "Columbia",
      "state": "Missouri",
      "country": "US",
      "elevation": 700,
      "lat": 38.9510993958,
      "lon": -92.4474029541,
      "tz": "America\/Chicago"
  },
  "MU33": {
      "icao": "MU33",
      "iata": "",
      "name": "Harrison Airport",
      "city": "Hurdland",
      "state": "Missouri",
      "country": "US",
      "elevation": 860,
      "lat": 40.0769996643,
      "lon": -92.3199005127,
      "tz": "America\/Chicago"
  },
  "MU34": {
      "icao": "MU34",
      "iata": "",
      "name": "Haven Wood Airport",
      "city": "Round Spring",
      "state": "Missouri",
      "country": "US",
      "elevation": 740,
      "lat": 37.3362007141,
      "lon": -91.3945999146,
      "tz": "America\/Chicago"
  },
  "MU35": {
      "icao": "MU35",
      "iata": "",
      "name": "Tallen Airport",
      "city": "Arkoe",
      "state": "Missouri",
      "country": "US",
      "elevation": 1123,
      "lat": 40.2675018311,
      "lon": -94.7865982056,
      "tz": "America\/Chicago"
  },
  "MU38": {
      "icao": "MU38",
      "iata": "",
      "name": "Cowgill-Roemer Airport",
      "city": "Milan",
      "state": "Missouri",
      "country": "US",
      "elevation": 900,
      "lat": 40.1783981323,
      "lon": -93.1313018799,
      "tz": "America\/Chicago"
  },
  "MU40": {
      "icao": "MU40",
      "iata": "",
      "name": "Lake Village Airport",
      "city": "Paris",
      "state": "Missouri",
      "country": "US",
      "elevation": 750,
      "lat": 39.4606018066,
      "lon": -92.0103988647,
      "tz": "America\/Chicago"
  },
  "MU41": {
      "icao": "MU41",
      "iata": "",
      "name": "Ingram Private Airport",
      "city": "Purdy",
      "state": "Missouri",
      "country": "US",
      "elevation": 1485,
      "lat": 36.7867012024,
      "lon": -93.887802124,
      "tz": "America\/Chicago"
  },
  "MU42": {
      "icao": "MU42",
      "iata": "",
      "name": "Strutman Field",
      "city": "Wentzville",
      "state": "Missouri",
      "country": "US",
      "elevation": 620,
      "lat": 38.7527999878,
      "lon": -90.7871017456,
      "tz": "America\/Chicago"
  },
  "MU48": {
      "icao": "MU48",
      "iata": "",
      "name": "Sunburst Ranch Airport",
      "city": "Gainesville",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 36.648399353,
      "lon": -92.2203979492,
      "tz": "America\/Chicago"
  },
  "MU55": {
      "icao": "MU55",
      "iata": "",
      "name": "Bauer Pgi Airport",
      "city": "Appleton City",
      "state": "Missouri",
      "country": "US",
      "elevation": 900,
      "lat": 38.1366996765,
      "lon": -94.1737976074,
      "tz": "America\/Chicago"
  },
  "MU56": {
      "icao": "MU56",
      "iata": "",
      "name": "Brown Field",
      "city": "Broseley",
      "state": "Missouri",
      "country": "US",
      "elevation": 323,
      "lat": 36.7088012695,
      "lon": -90.2682037354,
      "tz": "America\/Chicago"
  },
  "MU62": {
      "icao": "MU62",
      "iata": "",
      "name": "Hilltop Airport",
      "city": "Drexel",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 38.5633010864,
      "lon": -94.5911026001,
      "tz": "America\/Chicago"
  },
  "MU63": {
      "icao": "MU63",
      "iata": "",
      "name": "Blumenstetter Airport",
      "city": "Elkland",
      "state": "Missouri",
      "country": "US",
      "elevation": 1327,
      "lat": 37.4350013733,
      "lon": -93.0668029785,
      "tz": "America\/Chicago"
  },
  "MU65": {
      "icao": "MU65",
      "iata": "",
      "name": "Lakeside Airport",
      "city": "Farmington",
      "state": "Missouri",
      "country": "US",
      "elevation": 910,
      "lat": 37.7695007324,
      "lon": -90.4656982422,
      "tz": "America\/Chicago"
  },
  "MU68": {
      "icao": "MU68",
      "iata": "",
      "name": "Eu-Wish Airport",
      "city": "Hermann",
      "state": "Missouri",
      "country": "US",
      "elevation": 920,
      "lat": 38.6688995361,
      "lon": -91.5360031128,
      "tz": "America\/Chicago"
  },
  "MU69": {
      "icao": "MU69",
      "iata": "",
      "name": "Peterman Airport",
      "city": "Collins",
      "state": "Missouri",
      "country": "US",
      "elevation": 888,
      "lat": 37.8694992065,
      "lon": -93.6259994507,
      "tz": "America\/Chicago"
  },
  "MU70": {
      "icao": "MU70",
      "iata": "",
      "name": "Skyview Airport",
      "city": "Ozark",
      "state": "Missouri",
      "country": "US",
      "elevation": 1360,
      "lat": 36.995098114,
      "lon": -93.1388015747,
      "tz": "America\/Chicago"
  },
  "MU71": {
      "icao": "MU71",
      "iata": "",
      "name": "Vandahl Airport",
      "city": "Weston",
      "state": "Missouri",
      "country": "US",
      "elevation": 1020,
      "lat": 39.3474998474,
      "lon": -94.858001709,
      "tz": "America\/Chicago"
  },
  "MU77": {
      "icao": "MU77",
      "iata": "",
      "name": "Aero Britton Airport",
      "city": "Fayette",
      "state": "Missouri",
      "country": "US",
      "elevation": 820,
      "lat": 39.1291999817,
      "lon": -92.7306976318,
      "tz": "America\/Chicago"
  },
  "MU78": {
      "icao": "MU78",
      "iata": "",
      "name": "Taylor Field",
      "city": "Waynesville",
      "state": "Missouri",
      "country": "US",
      "elevation": 1022,
      "lat": 37.847964,
      "lon": -92.188933,
      "tz": "America\/Chicago"
  },
  "MU84": {
      "icao": "MU84",
      "iata": "",
      "name": "Michael Farm Airport",
      "city": "Lone Jack",
      "state": "Missouri",
      "country": "US",
      "elevation": 1050,
      "lat": 38.8995018005,
      "lon": -94.2294006348,
      "tz": "America\/Chicago"
  },
  "MU85": {
      "icao": "MU85",
      "iata": "",
      "name": "Thomas Airport",
      "city": "Oak Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 890,
      "lat": 38.9550018311,
      "lon": -94.1274032593,
      "tz": "America\/Chicago"
  },
  "MU86": {
      "icao": "MU86",
      "iata": "",
      "name": "Taus River Ranch Airport",
      "city": "Houston",
      "state": "Missouri",
      "country": "US",
      "elevation": 956,
      "lat": 37.4199981689,
      "lon": -91.9577026367,
      "tz": "America\/Chicago"
  },
  "MU89": {
      "icao": "MU89",
      "iata": "",
      "name": "Woodland Airstrip",
      "city": "Troy",
      "state": "Missouri",
      "country": "US",
      "elevation": 625,
      "lat": 38.9119987488,
      "lon": -91.035697937,
      "tz": "America\/Chicago"
  },
  "MU97": {
      "icao": "MU97",
      "iata": "",
      "name": "Platte Valley Airport",
      "city": "Platte City",
      "state": "Missouri",
      "country": "US",
      "elevation": 920,
      "lat": 39.3675003052,
      "lon": -94.7617034912,
      "tz": "America\/Chicago"
  },
  "MU98": {
      "icao": "MU98",
      "iata": "",
      "name": "Eagles Nest Airport",
      "city": "Golden",
      "state": "Missouri",
      "country": "US",
      "elevation": 1000,
      "lat": 36.5181007385,
      "lon": -93.6836013794,
      "tz": "America\/Chicago"
  },
  "MUAT": {
      "icao": "MUAT",
      "iata": "",
      "name": "Antilla Airport",
      "city": "Antilla",
      "state": "Holguin",
      "country": "CU",
      "elevation": 6,
      "lat": 20.8388004303,
      "lon": -75.7328033447,
      "tz": "America\/Havana"
  },
  "MUBA": {
      "icao": "MUBA",
      "iata": "BCA",
      "name": "Gustavo Rizo Airport",
      "city": "Baracoa",
      "state": "Guantanamo",
      "country": "CU",
      "elevation": 26,
      "lat": 20.3652992249,
      "lon": -74.5062026978,
      "tz": "America\/Havana"
  },
  "MUBE": {
      "icao": "MUBE",
      "iata": "",
      "name": "El Caribe Airport",
      "city": "El Caribe",
      "state": "Pinar-del-Rio",
      "country": "CU",
      "elevation": 29,
      "lat": 22.3512001038,
      "lon": -83.3990020752,
      "tz": "America\/Havana"
  },
  "MUBO": {
      "icao": "MUBO",
      "iata": "",
      "name": "Batabano Airport",
      "city": "Batabano",
      "state": "Mayabeque",
      "country": "CU",
      "elevation": 0,
      "lat": 22.7185993195,
      "lon": -82.2686004639,
      "tz": "America\/Havana"
  },
  "MUBR": {
      "icao": "MUBR",
      "iata": "BWW",
      "name": "Las Brujas Airport",
      "city": "Cayo Santa Maria",
      "state": "Villa-Clara",
      "country": "CU",
      "elevation": 13,
      "lat": 22.6212997437,
      "lon": -79.1472015381,
      "tz": "America\/Havana"
  },
  "MUBY": {
      "icao": "MUBY",
      "iata": "BYM",
      "name": "Carlos Manuel de Cespedes Airport",
      "city": "Bayamo",
      "state": "Granma",
      "country": "CU",
      "elevation": 203,
      "lat": 20.3964004517,
      "lon": -76.6213989258,
      "tz": "America\/Havana"
  },
  "MUCA": {
      "icao": "MUCA",
      "iata": "AVI",
      "name": "Maximo Gomez Airport",
      "city": "Ciego de Avila",
      "state": "Ciego-de-Avila",
      "country": "CU",
      "elevation": 335,
      "lat": 22.0270996094,
      "lon": -78.7895965576,
      "tz": "America\/Havana"
  },
  "MUCB": {
      "icao": "MUCB",
      "iata": "",
      "name": "Caibarien Airport",
      "city": "Caibarien",
      "state": "Villa-Clara",
      "country": "CU",
      "elevation": 50,
      "lat": 22.5063991547,
      "lon": -79.4698028564,
      "tz": "America\/Havana"
  },
  "MUCC": {
      "icao": "MUCC",
      "iata": "CCC",
      "name": "Jardines Del Rey Airport",
      "city": "Cayo Coco",
      "state": "Ciego-de-Avila",
      "country": "CU",
      "elevation": 13,
      "lat": 22.4610004425,
      "lon": -78.3283996582,
      "tz": "America\/Havana"
  },
  "MUCD": {
      "icao": "MUCD",
      "iata": "",
      "name": "Ciego De Avila Sur Airport",
      "city": "Ciego de Avila",
      "state": "Ciego-de-Avila",
      "country": "CU",
      "elevation": 131,
      "lat": 21.8211994171,
      "lon": -78.7566986084,
      "tz": "America\/Havana"
  },
  "MUCF": {
      "icao": "MUCF",
      "iata": "CFG",
      "name": "Jaime Gonzalez Airport",
      "city": "Cienfuegos",
      "state": "Cienfuegos",
      "country": "CU",
      "elevation": 102,
      "lat": 22.1499996185,
      "lon": -80.4141998291,
      "tz": "America\/Havana"
  },
  "MUCL": {
      "icao": "MUCL",
      "iata": "CYO",
      "name": "Vilo Acuna International Airport",
      "city": "Cayo Largo del Sur",
      "state": "Isla-de-la-Juventud",
      "country": "CU",
      "elevation": 10,
      "lat": 21.6165008545,
      "lon": -81.5459976196,
      "tz": "America\/Havana"
  },
  "MUCM": {
      "icao": "MUCM",
      "iata": "CMW",
      "name": "Ignacio Agramonte International Airport",
      "city": "Camaguey",
      "state": "Camagueey",
      "country": "CU",
      "elevation": 413,
      "lat": 21.42029953,
      "lon": -77.8475036621,
      "tz": "America\/Havana"
  },
  "MUCO": {
      "icao": "MUCO",
      "iata": "QCO",
      "name": "Colon Airport",
      "city": "Colon",
      "state": "Matanzas",
      "country": "CU",
      "elevation": 0,
      "lat": 22.7110996246,
      "lon": -80.9227981567,
      "tz": "America\/Havana"
  },
  "MUCU": {
      "icao": "MUCU",
      "iata": "SCU",
      "name": "Antonio Maceo International Airport",
      "city": "Santiago",
      "state": "Santiago-de-Cuba",
      "country": "CU",
      "elevation": 249,
      "lat": 19.9698009491,
      "lon": -75.8354034424,
      "tz": "America\/Havana"
  },
  "MUCV": {
      "icao": "MUCV",
      "iata": "",
      "name": "Las Clavellinas Airport",
      "city": "Las Clavellinas",
      "state": "Pinar-del-Rio",
      "country": "CU",
      "elevation": 19,
      "lat": 22.2287006378,
      "lon": -84.3720016479,
      "tz": "America\/Havana"
  },
  "MUCY": {
      "icao": "MUCY",
      "iata": "",
      "name": "Cayajabo Airport",
      "city": "Cayajabo",
      "state": "Artemisa",
      "country": "CU",
      "elevation": 259,
      "lat": 22.8644447327,
      "lon": -82.8558349609,
      "tz": "America\/Havana"
  },
  "MUFL": {
      "icao": "MUFL",
      "iata": "",
      "name": "Florida Airport",
      "city": "Florida",
      "state": "Camagueey",
      "country": "CU",
      "elevation": 197,
      "lat": 21.4997005463,
      "lon": -78.202796936,
      "tz": "America\/Havana"
  },
  "MUGA": {
      "icao": "MUGA",
      "iata": "",
      "name": "Central Agramonte Airport",
      "city": "Florida",
      "state": "Camagueey",
      "country": "CU",
      "elevation": 196,
      "lat": 21.5499992371,
      "lon": -78.2333297729,
      "tz": "America\/Havana"
  },
  "MUGM": {
      "icao": "MUGM",
      "iata": "NBW",
      "name": "Leeward Point Field",
      "city": "Guantanamo Bay Naval Station",
      "state": "Guantanamo",
      "country": "CU",
      "elevation": 56,
      "lat": 19.9064998627,
      "lon": -75.2070999146,
      "tz": "America\/Havana"
  },
  "MUGT": {
      "icao": "MUGT",
      "iata": "GAO",
      "name": "Mariana Grajales Airport",
      "city": "Guantanamo",
      "state": "Guantanamo",
      "country": "CU",
      "elevation": 56,
      "lat": 20.0853004456,
      "lon": -75.1583023071,
      "tz": "America\/Havana"
  },
  "MUGV": {
      "icao": "MUGV",
      "iata": "",
      "name": "Guardalavaca Airport",
      "city": "Yaguajay",
      "state": "Holguin",
      "country": "CU",
      "elevation": 318,
      "lat": 21.1116008759,
      "lon": -75.8198013306,
      "tz": "America\/Havana"
  },
  "MUHA": {
      "icao": "MUHA",
      "iata": "HAV",
      "name": "Jose Marti International Airport",
      "city": "Havana",
      "state": "La-Habana",
      "country": "CU",
      "elevation": 210,
      "lat": 22.989200592,
      "lon": -82.4091033936,
      "tz": "America\/Havana"
  },
  "MUHG": {
      "icao": "MUHG",
      "iata": "HOG",
      "name": "Frank Pais International Airport",
      "city": "Holguin",
      "state": "Holguin",
      "country": "CU",
      "elevation": 361,
      "lat": 20.7856006622,
      "lon": -76.3151016235,
      "tz": "America\/Havana"
  },
  "MUIV": {
      "icao": "MUIV",
      "iata": "",
      "name": "Nuevitas Airport",
      "city": "Nuevitas",
      "state": "Camagueey",
      "country": "CU",
      "elevation": 3,
      "lat": 21.5538005829,
      "lon": -77.2585983276,
      "tz": "America\/Havana"
  },
  "MUKW": {
      "icao": "MUKW",
      "iata": "VRO",
      "name": "Kawama Airport",
      "city": "Matanzas",
      "state": "Matanzas",
      "country": "CU",
      "elevation": 16,
      "lat": 23.1240005493,
      "lon": -81.3015975952,
      "tz": "America\/Havana"
  },
  "MULB": {
      "icao": "MULB",
      "iata": "",
      "name": "Ciudad Libertad Airport",
      "city": "Havana",
      "state": "La-Habana",
      "country": "CU",
      "elevation": 98,
      "lat": 23.0939006805,
      "lon": -82.4381027222,
      "tz": "America\/Havana"
  },
  "MULM": {
      "icao": "MULM",
      "iata": "LCL",
      "name": "La Coloma Airport",
      "city": "Pinar del Rio",
      "state": "Pinar-del-Rio",
      "country": "CU",
      "elevation": 131,
      "lat": 22.3360996246,
      "lon": -83.6418991089,
      "tz": "America\/Havana"
  },
  "MUMA": {
      "icao": "MUMA",
      "iata": "UMA",
      "name": "Punta de Maisi Airport",
      "city": "Maisi",
      "state": "Guantanamo",
      "country": "CU",
      "elevation": 3,
      "lat": 20.2506008148,
      "lon": -74.1504974365,
      "tz": "America\/Havana"
  },
  "MUMG": {
      "icao": "MUMG",
      "iata": "",
      "name": "Managua Airport",
      "city": "Managua",
      "state": "Mayabeque",
      "country": "CU",
      "elevation": 377,
      "lat": 22.9699001312,
      "lon": -82.2748031616,
      "tz": "America\/Havana"
  },
  "MUMH": {
      "icao": "MUMH",
      "iata": "",
      "name": "Matahambre Airport",
      "city": "Matahambre",
      "state": "Pinar-del-Rio",
      "country": "CU",
      "elevation": 396,
      "lat": 22.5874996185,
      "lon": -83.9449996948,
      "tz": "America\/Havana"
  },
  "MUMJ": {
      "icao": "MUMJ",
      "iata": "MJG",
      "name": "Mayajigua Airport",
      "city": "Mayajigua",
      "state": "Sancti-Spiritus",
      "country": "CU",
      "elevation": 173,
      "lat": 22.2308006287,
      "lon": -79.0622024536,
      "tz": "America\/Havana"
  },
  "MUMO": {
      "icao": "MUMO",
      "iata": "MOA",
      "name": "Orestes Acosta Airport",
      "city": "Moa",
      "state": "Holguin",
      "country": "CU",
      "elevation": 16,
      "lat": 20.6539001465,
      "lon": -74.922203064,
      "tz": "America\/Havana"
  },
  "MUMT": {
      "icao": "MUMT",
      "iata": "",
      "name": "Matanzas Airport",
      "city": "Matanzas",
      "state": "Matanzas",
      "country": "CU",
      "elevation": 9,
      "lat": 23.0100002289,
      "lon": -81.5100021362,
      "tz": "America\/Havana"
  },
  "MUMZ": {
      "icao": "MUMZ",
      "iata": "MZO",
      "name": "Sierra Maestra Airport",
      "city": "Manzanillo",
      "state": "Granma",
      "country": "CU",
      "elevation": 112,
      "lat": 20.2880992889,
      "lon": -77.0892028809,
      "tz": "America\/Havana"
  },
  "MUNB": {
      "icao": "MUNB",
      "iata": "QSN",
      "name": "San Nicolas De Bari Airport",
      "city": "San Nicolas",
      "state": "Mayabeque",
      "country": "CU",
      "elevation": 49,
      "lat": 22.7560997009,
      "lon": -81.9208984375,
      "tz": "America\/Havana"
  },
  "MUNC": {
      "icao": "MUNC",
      "iata": "ICR",
      "name": "Nicaro Airport",
      "city": "Nicaro",
      "state": "Holguin",
      "country": "CU",
      "elevation": 26,
      "lat": 20.6886005402,
      "lon": -75.53150177,
      "tz": "America\/Havana"
  },
  "MUNG": {
      "icao": "MUNG",
      "iata": "GER",
      "name": "Rafael Cabrera Airport",
      "city": "Nueva Gerona",
      "state": "Isla-de-la-Juventud",
      "country": "CU",
      "elevation": 79,
      "lat": 21.8346996307,
      "lon": -82.7837982178,
      "tz": "America\/Havana"
  },
  "MUPA": {
      "icao": "MUPA",
      "iata": "",
      "name": "Punta Alegre Airport",
      "city": "Punta Alegre",
      "state": "Ciego-de-Avila",
      "country": "CU",
      "elevation": 82,
      "lat": 22.3799991608,
      "lon": -78.8174972534,
      "tz": "America\/Havana"
  },
  "MUPB": {
      "icao": "MUPB",
      "iata": "UPB",
      "name": "Playa Baracoa Airport",
      "city": "Havana",
      "state": "Artemisa",
      "country": "CU",
      "elevation": 102,
      "lat": 23.0328006744,
      "lon": -82.5793991089,
      "tz": "America\/Havana"
  },
  "MUPR": {
      "icao": "MUPR",
      "iata": "QPD",
      "name": "Pinar Del Rio Airport",
      "city": "Pinar del Rio",
      "state": "Pinar-del-Rio",
      "country": "CU",
      "elevation": 131,
      "lat": 22.4214000702,
      "lon": -83.6783981323,
      "tz": "America\/Havana"
  },
  "MUSA": {
      "icao": "MUSA",
      "iata": "",
      "name": "San Antonio De Los Banos Airport",
      "city": "San Antonio de los Banos",
      "state": "Artemisa",
      "country": "CU",
      "elevation": 164,
      "lat": 22.8715000153,
      "lon": -82.5093002319,
      "tz": "America\/Havana"
  },
  "MUSC": {
      "icao": "MUSC",
      "iata": "SNU",
      "name": "Abel Santamaria Airport",
      "city": "Santa Clara",
      "state": "Villa-Clara",
      "country": "CU",
      "elevation": 338,
      "lat": 22.4922008514,
      "lon": -79.9436035156,
      "tz": "America\/Havana"
  },
  "MUSG": {
      "icao": "MUSG",
      "iata": "",
      "name": "Sagua La Grande Airport",
      "city": "Sagua la Grande",
      "state": "Villa-Clara",
      "country": "CU",
      "elevation": 36,
      "lat": 22.8015995026,
      "lon": -80.0548019409,
      "tz": "America\/Havana"
  },
  "MUSJ": {
      "icao": "MUSJ",
      "iata": "SNJ",
      "name": "San Julian Air Base",
      "city": "Pinar Del Rio",
      "state": "Pinar-del-Rio",
      "country": "CU",
      "elevation": 98,
      "lat": 22.0953006744,
      "lon": -84.1520004272,
      "tz": "America\/Havana"
  },
  "MUSL": {
      "icao": "MUSL",
      "iata": "",
      "name": "Joaquin de Aguero Airport",
      "city": "Playa Santa Lucia",
      "state": "Camagueey",
      "country": "CU",
      "elevation": 13,
      "lat": 21.5094559067,
      "lon": -77.0205974579,
      "tz": "America\/Havana"
  },
  "MUSN": {
      "icao": "MUSN",
      "iata": "SZJ",
      "name": "Siguanea Airport",
      "city": "Isla de la Juventud",
      "state": "Isla-de-la-Juventud",
      "country": "CU",
      "elevation": 39,
      "lat": 21.6424999237,
      "lon": -82.9551010132,
      "tz": "America\/Havana"
  },
  "MUSS": {
      "icao": "MUSS",
      "iata": "USS",
      "name": "Sancti Spiritus Airport",
      "city": "Sancti Spiritus",
      "state": "Sancti-Spiritus",
      "country": "CU",
      "elevation": 295,
      "lat": 21.9703998566,
      "lon": -79.4427032471,
      "tz": "America\/Havana"
  },
  "MUTD": {
      "icao": "MUTD",
      "iata": "TND",
      "name": "Alberto Delgado Airport",
      "city": "Trinidad",
      "state": "Sancti-Spiritus",
      "country": "CU",
      "elevation": 125,
      "lat": 21.7882995605,
      "lon": -79.9972000122,
      "tz": "America\/Havana"
  },
  "MUTI": {
      "icao": "MUTI",
      "iata": "",
      "name": "Manati Airport",
      "city": "Manati",
      "state": "Las-Tunas",
      "country": "CU",
      "elevation": 6,
      "lat": 21.3106994629,
      "lon": -76.9201965332,
      "tz": "America\/Havana"
  },
  "MUVR": {
      "icao": "MUVR",
      "iata": "VRA",
      "name": "Juan Gualberto Gomez International Airport",
      "city": "Varadero",
      "state": "Matanzas",
      "country": "CU",
      "elevation": 210,
      "lat": 23.0344009399,
      "lon": -81.4353027344,
      "tz": "America\/Havana"
  },
  "MUVT": {
      "icao": "MUVT",
      "iata": "VTU",
      "name": "Hermanos Ameijeiras Airport",
      "city": "Las Tunas",
      "state": "Las-Tunas",
      "country": "CU",
      "elevation": 328,
      "lat": 20.9876003265,
      "lon": -76.935798645,
      "tz": "America\/Havana"
  },
  "MWCB": {
      "icao": "MWCB",
      "iata": "CYB",
      "name": "Gerrard Smith International Airport",
      "city": "Cayman Brac",
      "state": "",
      "country": "KY",
      "elevation": 8,
      "lat": 19.6870002747,
      "lon": -79.8827972412,
      "tz": "America\/Cayman"
  },
  "MWCL": {
      "icao": "MWCL",
      "iata": "LYB",
      "name": "Edward Bodden Airfield",
      "city": "Little Cayman",
      "state": "",
      "country": "KY",
      "elevation": 3,
      "lat": 19.6669998169,
      "lon": -80.0999984741,
      "tz": "America\/Cayman"
  },
  "MWCR": {
      "icao": "MWCR",
      "iata": "GCM",
      "name": "Owen Roberts International Airport",
      "city": "Georgetown",
      "state": "",
      "country": "KY",
      "elevation": 8,
      "lat": 19.2928009033,
      "lon": -81.3576965332,
      "tz": "America\/Cayman"
  },
  "MY00": {
      "icao": "MY00",
      "iata": "",
      "name": "Sieg's Farm Airport",
      "city": "Truman",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1060,
      "lat": 43.8941001892,
      "lon": -94.4938964844,
      "tz": "America\/Chicago"
  },
  "MY01": {
      "icao": "MY01",
      "iata": "",
      "name": "Roan Airport",
      "city": "Warren",
      "state": "Minnesota",
      "country": "US",
      "elevation": 860,
      "lat": 48.1458015442,
      "lon": -96.7526016235,
      "tz": "America\/Chicago"
  },
  "MY03": {
      "icao": "MY03",
      "iata": "",
      "name": "Braun's Airport",
      "city": "Sleepy Eye",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1009,
      "lat": 44.2816009521,
      "lon": -94.6405029297,
      "tz": "America\/Chicago"
  },
  "MY04": {
      "icao": "MY04",
      "iata": "",
      "name": "Koch's Personal Field",
      "city": "Taunton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1130,
      "lat": 44.6599998474,
      "lon": -96.0373001099,
      "tz": "America\/Chicago"
  },
  "MY06": {
      "icao": "MY06",
      "iata": "",
      "name": "Ramerth Airport",
      "city": "Fulda",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1500,
      "lat": 43.831401825,
      "lon": -95.5552978516,
      "tz": "America\/Chicago"
  },
  "MY07": {
      "icao": "MY07",
      "iata": "",
      "name": "Nord Field",
      "city": "Wolverton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 948,
      "lat": 46.5833015442,
      "lon": -96.6064987183,
      "tz": "America\/Chicago"
  },
  "MY10": {
      "icao": "MY10",
      "iata": "",
      "name": "Deters Farms Airport",
      "city": "Spring Grove",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1220,
      "lat": 43.5611000061,
      "lon": -91.6815032959,
      "tz": "America\/Chicago"
  },
  "MY11": {
      "icao": "MY11",
      "iata": "",
      "name": "Gilgenbach's Airport",
      "city": "Taopi",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1320,
      "lat": 43.5214004517,
      "lon": -92.6585006714,
      "tz": "America\/Chicago"
  },
  "MY12": {
      "icao": "MY12",
      "iata": "",
      "name": "Dykstra Acreage Airport",
      "city": "Trosky",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1690,
      "lat": 43.8807983398,
      "lon": -96.2530975342,
      "tz": "America\/Chicago"
  },
  "MY13": {
      "icao": "MY13",
      "iata": "",
      "name": "Swan Field",
      "city": "Saginaw",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1345,
      "lat": 46.86930084,
      "lon": -92.45709991,
      "tz": "America\/Chicago"
  },
  "MY15": {
      "icao": "MY15",
      "iata": "",
      "name": "Kastanek Airport",
      "city": "Pierz",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1320,
      "lat": 45.9557991028,
      "lon": -93.989402771,
      "tz": "America\/Chicago"
  },
  "MY17": {
      "icao": "MY17",
      "iata": "",
      "name": "Swanson Private Airport",
      "city": "Goodridge",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1170,
      "lat": 48.0889015198,
      "lon": -95.7746963501,
      "tz": "America\/Chicago"
  },
  "MY18": {
      "icao": "MY18",
      "iata": "",
      "name": "Lino Air Park",
      "city": "Lino Lakes",
      "state": "Minnesota",
      "country": "US",
      "elevation": 907,
      "lat": 45.1871986389,
      "lon": -93.1305007935,
      "tz": "America\/Chicago"
  },
  "MY19": {
      "icao": "MY19",
      "iata": "",
      "name": "Schwenk Airport",
      "city": "Murdock",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1082,
      "lat": 45.1707992554,
      "lon": -95.3925018311,
      "tz": "America\/Chicago"
  },
  "MY24": {
      "icao": "MY24",
      "iata": "",
      "name": "Falk Private Airport",
      "city": "Park Rapids",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1443,
      "lat": 46.8622016907,
      "lon": -95.0522003174,
      "tz": "America\/Chicago"
  },
  "MY25": {
      "icao": "MY25",
      "iata": "",
      "name": "Knapp Personal Use Airport",
      "city": "Foxhome",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1018,
      "lat": 46.2799987793,
      "lon": -96.3314971924,
      "tz": "America\/Chicago"
  },
  "MY28": {
      "icao": "MY28",
      "iata": "",
      "name": "Pinetree Airpark",
      "city": "Harris",
      "state": "Minnesota",
      "country": "US",
      "elevation": 930,
      "lat": 45.5797996521,
      "lon": -92.9630966187,
      "tz": "America\/Chicago"
  },
  "MY29": {
      "icao": "MY29",
      "iata": "",
      "name": "Kaiser's Airstrip",
      "city": "Lake Park",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1400,
      "lat": 46.7969017029,
      "lon": -96.0996017456,
      "tz": "America\/Chicago"
  },
  "MY35": {
      "icao": "MY35",
      "iata": "",
      "name": "Walker Field",
      "city": "Afton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 902,
      "lat": 44.9334983826,
      "lon": -92.8177032471,
      "tz": "America\/Chicago"
  },
  "MY37": {
      "icao": "MY37",
      "iata": "",
      "name": "Thomas Field",
      "city": "Eyota",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1285,
      "lat": 43.981076,
      "lon": -92.246554,
      "tz": "America\/Chicago"
  },
  "MY42": {
      "icao": "MY42",
      "iata": "",
      "name": "Mathis Airport",
      "city": "Gonvick",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1170,
      "lat": 47.8638000488,
      "lon": -95.5270004272,
      "tz": "America\/Chicago"
  },
  "MY43": {
      "icao": "MY43",
      "iata": "",
      "name": "Becker Personal Airport",
      "city": "St Francis",
      "state": "Minnesota",
      "country": "US",
      "elevation": 920,
      "lat": 45.4026985168,
      "lon": -93.3893966675,
      "tz": "America\/Chicago"
  },
  "MY46": {
      "icao": "MY46",
      "iata": "",
      "name": "Hay Shakers Airport",
      "city": "Nicollet",
      "state": "Minnesota",
      "country": "US",
      "elevation": 995,
      "lat": 44.2792015076,
      "lon": -94.2133026123,
      "tz": "America\/Chicago"
  },
  "MY47": {
      "icao": "MY47",
      "iata": "",
      "name": "Guggenberger Airport",
      "city": "Sartell",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1050,
      "lat": 45.6046981812,
      "lon": -94.2108001709,
      "tz": "America\/Chicago"
  },
  "MY49": {
      "icao": "MY49",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Bear River",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1310,
      "lat": 47.787399292,
      "lon": -93.0605010986,
      "tz": "America\/Chicago"
  },
  "MY50": {
      "icao": "MY50",
      "iata": "",
      "name": "Frontenac Airport",
      "city": "Frontenac",
      "state": "Minnesota",
      "country": "US",
      "elevation": 793,
      "lat": 44.5027008057,
      "lon": -92.3612976074,
      "tz": "America\/Chicago"
  },
  "MY52": {
      "icao": "MY52",
      "iata": "",
      "name": "Turkey Track Airport",
      "city": "Coates",
      "state": "Minnesota",
      "country": "US",
      "elevation": 923,
      "lat": 44.7186012268,
      "lon": -93.0440979004,
      "tz": "America\/Chicago"
  },
  "MY54": {
      "icao": "MY54",
      "iata": "",
      "name": "Cloverleaf-East Bemidji Airport",
      "city": "Bemidji",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1445,
      "lat": 47.4383010864,
      "lon": -94.8122024536,
      "tz": "America\/Chicago"
  },
  "MY55": {
      "icao": "MY55",
      "iata": "",
      "name": "B I R Airport",
      "city": "",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1210,
      "lat": 46.4197006226,
      "lon": -94.2752990723,
      "tz": "America\/Chicago"
  },
  "MY56": {
      "icao": "MY56",
      "iata": "",
      "name": "Robertson Field",
      "city": "Hibbing",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1290,
      "lat": 47.2523994446,
      "lon": -92.8593978882,
      "tz": "America\/Chicago"
  },
  "MY57": {
      "icao": "MY57",
      "iata": "",
      "name": "Fedor Airport",
      "city": "Holdingford",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1175,
      "lat": 45.7633018494,
      "lon": -94.4574966431,
      "tz": "America\/Chicago"
  },
  "MY58": {
      "icao": "MY58",
      "iata": "",
      "name": "Home-Base Airport",
      "city": "Holloway",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1020,
      "lat": 45.2388000488,
      "lon": -95.9253005981,
      "tz": "America\/Chicago"
  },
  "MY59": {
      "icao": "MY59",
      "iata": "",
      "name": "Gasper Airport",
      "city": "Euclid",
      "state": "Minnesota",
      "country": "US",
      "elevation": 910,
      "lat": 47.901599884,
      "lon": -96.6122970581,
      "tz": "America\/Chicago"
  },
  "MY60": {
      "icao": "MY60",
      "iata": "",
      "name": "Hagen Airport",
      "city": "Richville",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1370,
      "lat": 46.5027008057,
      "lon": -95.6197967529,
      "tz": "America\/Chicago"
  },
  "MY62": {
      "icao": "MY62",
      "iata": "",
      "name": "Ultraflyte Inc Ultralightport",
      "city": "Jenkins",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1270,
      "lat": 46.6651992798,
      "lon": -94.3432998657,
      "tz": "America\/Chicago"
  },
  "MY64": {
      "icao": "MY64",
      "iata": "",
      "name": "Schjeldrup Airport",
      "city": "Felton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 903,
      "lat": 47.0311012268,
      "lon": -96.6023025513,
      "tz": "America\/Chicago"
  },
  "MY66": {
      "icao": "MY66",
      "iata": "",
      "name": "North Star Ranch Airport",
      "city": "Onamia",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1330,
      "lat": 46.0704994202,
      "lon": -93.8471984863,
      "tz": "America\/Chicago"
  },
  "MY68": {
      "icao": "MY68",
      "iata": "",
      "name": "Johnsons Aero Repair Airport",
      "city": "Wheaton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1028,
      "lat": 45.7771987915,
      "lon": -96.3914031982,
      "tz": "America\/Chicago"
  },
  "MY71": {
      "icao": "MY71",
      "iata": "",
      "name": "Mueller Airport",
      "city": "Georgetown",
      "state": "Minnesota",
      "country": "US",
      "elevation": 880,
      "lat": 47.1263999939,
      "lon": -96.7806015015,
      "tz": "America\/Chicago"
  },
  "MY72": {
      "icao": "MY72",
      "iata": "",
      "name": "Isle Private Airport",
      "city": "Isle",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1271,
      "lat": 46.1596984863,
      "lon": -93.4613037109,
      "tz": "America\/Chicago"
  },
  "MY78": {
      "icao": "MY78",
      "iata": "",
      "name": "Sunrise Airport",
      "city": "Stacy",
      "state": "Minnesota",
      "country": "US",
      "elevation": 910,
      "lat": 45.4291000366,
      "lon": -92.9862976074,
      "tz": "America\/Chicago"
  },
  "MY80": {
      "icao": "MY80",
      "iata": "",
      "name": "Rosenberg Airport",
      "city": "Ceylon",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1255,
      "lat": 43.5222015381,
      "lon": -94.5824966431,
      "tz": "America\/Chicago"
  },
  "MY82": {
      "icao": "MY82",
      "iata": "",
      "name": "Du Fresne Airport",
      "city": "Forest Lake",
      "state": "Minnesota",
      "country": "US",
      "elevation": 900,
      "lat": 45.2130012512,
      "lon": -93.0746994019,
      "tz": "America\/Chicago"
  },
  "MY88": {
      "icao": "MY88",
      "iata": "",
      "name": "Cary Airport",
      "city": "Park Rapids",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1413,
      "lat": 46.8574981689,
      "lon": -94.9831008911,
      "tz": "America\/Chicago"
  },
  "MY89": {
      "icao": "MY89",
      "iata": "",
      "name": "Pavek Personal Airport",
      "city": "Pine City",
      "state": "Minnesota",
      "country": "US",
      "elevation": 980,
      "lat": 45.9082984924,
      "lon": -92.9057998657,
      "tz": "America\/Chicago"
  },
  "MY90": {
      "icao": "MY90",
      "iata": "",
      "name": "Al's Due North Airport",
      "city": "North Branch",
      "state": "Minnesota",
      "country": "US",
      "elevation": 915,
      "lat": 45.4822006226,
      "lon": -92.9885025024,
      "tz": "America\/Chicago"
  },
  "MY92": {
      "icao": "MY92",
      "iata": "",
      "name": "Grohnke Field",
      "city": "Braham",
      "state": "Minnesota",
      "country": "US",
      "elevation": 960,
      "lat": 45.7285995483,
      "lon": -93.2882995605,
      "tz": "America\/Chicago"
  },
  "MY94": {
      "icao": "MY94",
      "iata": "",
      "name": "Country Haven Airport",
      "city": "Cloquet",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1309,
      "lat": 46.7524986267,
      "lon": -92.4191970825,
      "tz": "America\/Chicago"
  },
  "MY95": {
      "icao": "MY95",
      "iata": "",
      "name": "Swanson Field",
      "city": "Crown",
      "state": "Minnesota",
      "country": "US",
      "elevation": 935,
      "lat": 45.4463996887,
      "lon": -93.3827972412,
      "tz": "America\/Chicago"
  },
  "MY97": {
      "icao": "MY97",
      "iata": "",
      "name": "Nielsen's Airport",
      "city": "Farmington",
      "state": "Minnesota",
      "country": "US",
      "elevation": 990,
      "lat": 44.5979995728,
      "lon": -93.1268997192,
      "tz": "America\/Chicago"
  },
  "MY98": {
      "icao": "MY98",
      "iata": "",
      "name": "Pake Airport",
      "city": "Felton",
      "state": "Minnesota",
      "country": "US",
      "elevation": 898,
      "lat": 47.045501709,
      "lon": -96.6656036377,
      "tz": "America\/Chicago"
  },
  "MY99": {
      "icao": "MY99",
      "iata": "",
      "name": "Johnston Airport",
      "city": "Hawley",
      "state": "Minnesota",
      "country": "US",
      "elevation": 1360,
      "lat": 46.8574981689,
      "lon": -96.2351989746,
      "tz": "America\/Chicago"
  },
  "MYA0": {
      "icao": "MYA0",
      "iata": "",
      "name": "Mores Island Airport",
      "city": "Mores Island",
      "state": "Moore\u2019s-Island",
      "country": "BS",
      "elevation": 10,
      "lat": 26.3176994324,
      "lon": -77.5635986328,
      "tz": "America\/Nassau"
  },
  "MYAB": {
      "icao": "MYAB",
      "iata": "MAY",
      "name": "Clarence A. Bain Airport",
      "city": "Mangrove Cay",
      "state": "Mangrove-Cay",
      "country": "BS",
      "elevation": 19,
      "lat": 24.2877006531,
      "lon": -77.6846008301,
      "tz": "America\/Nassau"
  },
  "MYAF": {
      "icao": "MYAF",
      "iata": "ASD",
      "name": "Andros Town Airport",
      "city": "",
      "state": "North-Andros",
      "country": "BS",
      "elevation": 5,
      "lat": 24.6979007721,
      "lon": -77.7956008911,
      "tz": "America\/Nassau"
  },
  "MYAK": {
      "icao": "MYAK",
      "iata": "COX",
      "name": "Congo Town Airport",
      "city": "Andros",
      "state": "South-Andros",
      "country": "BS",
      "elevation": 15,
      "lat": 24.158700943,
      "lon": -77.5897979736,
      "tz": "America\/Nassau"
  },
  "MYAM": {
      "icao": "MYAM",
      "iata": "MHH",
      "name": "Marsh Harbour International Airport",
      "city": "Marsh Harbour",
      "state": "",
      "country": "BS",
      "elevation": 6,
      "lat": 26.5114002228,
      "lon": -77.0835037231,
      "tz": "America\/Nassau"
  },
  "MYAN": {
      "icao": "MYAN",
      "iata": "SAQ",
      "name": "San Andros Airport",
      "city": "Andros Island",
      "state": "North-Andros",
      "country": "BS",
      "elevation": 5,
      "lat": 25.0538005829,
      "lon": -78.0490036011,
      "tz": "America\/Nassau"
  },
  "MYAP": {
      "icao": "MYAP",
      "iata": "AXP",
      "name": "Spring Point Airport",
      "city": "Spring Point",
      "state": "Acklins",
      "country": "BS",
      "elevation": 11,
      "lat": 22.4417991638,
      "lon": -73.9709014893,
      "tz": "America\/Nassau"
  },
  "MYAS": {
      "icao": "MYAS",
      "iata": "",
      "name": "Sandy Point Airport",
      "city": "",
      "state": "South-Abaco",
      "country": "BS",
      "elevation": 8,
      "lat": 26.0046005249,
      "lon": -77.3955001831,
      "tz": "America\/Nassau"
  },
  "MYAT": {
      "icao": "MYAT",
      "iata": "TCB",
      "name": "Treasure Cay Airport",
      "city": "Treasure Cay",
      "state": "",
      "country": "BS",
      "elevation": 8,
      "lat": 26.745300293,
      "lon": -77.3912963867,
      "tz": "America\/Nassau"
  },
  "MYAW": {
      "icao": "MYAW",
      "iata": "WKR",
      "name": "Abaco I Walker C Airport",
      "city": "",
      "state": "Grand-Cay",
      "country": "BS",
      "elevation": 10,
      "lat": 27.2667007446,
      "lon": -78.3996963501,
      "tz": "America\/Nassau"
  },
  "MYAX": {
      "icao": "MYAX",
      "iata": "",
      "name": "Spanish Cay Airport",
      "city": "",
      "state": "North-Abaco",
      "country": "BS",
      "elevation": 10,
      "lat": 26.9503002167,
      "lon": -77.543800354,
      "tz": "America\/Nassau"
  },
  "MYBC": {
      "icao": "MYBC",
      "iata": "CCZ",
      "name": "Chub Cay Airport",
      "city": "",
      "state": "Berry-Islands",
      "country": "BS",
      "elevation": 5,
      "lat": 25.4171009064,
      "lon": -77.880897522,
      "tz": "America\/Nassau"
  },
  "MYBG": {
      "icao": "MYBG",
      "iata": "GHC",
      "name": "Great Harbour Cay Airport",
      "city": "",
      "state": "Berry-Islands",
      "country": "BS",
      "elevation": 18,
      "lat": 25.7383003235,
      "lon": -77.8401031494,
      "tz": "America\/Nassau"
  },
  "MYBS": {
      "icao": "MYBS",
      "iata": "BIM",
      "name": "South Bimini Airport",
      "city": "South Bimini",
      "state": "Bimini",
      "country": "BS",
      "elevation": 10,
      "lat": 25.6998996735,
      "lon": -79.2647018433,
      "tz": "America\/Nassau"
  },
  "MYCA": {
      "icao": "MYCA",
      "iata": "ATC",
      "name": "Arthur's Town Airport",
      "city": "Arthur's Town",
      "state": "Cat-Island",
      "country": "BS",
      "elevation": 18,
      "lat": 24.6294002533,
      "lon": -75.6737976074,
      "tz": "America\/Nassau"
  },
  "MYCB": {
      "icao": "MYCB",
      "iata": "CAT",
      "name": "New Bight Airport",
      "city": "Cat Island",
      "state": "Cat-Island",
      "country": "BS",
      "elevation": 5,
      "lat": 24.3152999878,
      "lon": -75.4523010254,
      "tz": "America\/Nassau"
  },
  "MYCC": {
      "icao": "MYCC",
      "iata": "CXY",
      "name": "Cat Cay Airport",
      "city": "Cat Cay",
      "state": "Bimini",
      "country": "BS",
      "elevation": 0,
      "lat": 25.6000003815,
      "lon": -79.266998291,
      "tz": "America\/Nassau"
  },
  "MYCH": {
      "icao": "MYCH",
      "iata": "",
      "name": "Hawks News Airport",
      "city": "Hawks News",
      "state": "Cat-Island",
      "country": "BS",
      "elevation": 3,
      "lat": 24.1536006927,
      "lon": -75.5199966431,
      "tz": "America\/Nassau"
  },
  "MYCI": {
      "icao": "MYCI",
      "iata": "CRI",
      "name": "Colonel Hill Airport",
      "city": "Colonel Hill",
      "state": "Crooked-Island-and-Long-Cay",
      "country": "BS",
      "elevation": 5,
      "lat": 22.7455997467,
      "lon": -74.1824035645,
      "tz": "America\/Nassau"
  },
  "MYCP": {
      "icao": "MYCP",
      "iata": "PWN",
      "name": "Pitts Town Airport",
      "city": "Pitts Town",
      "state": "Crooked-Island-and-Long-Cay",
      "country": "BS",
      "elevation": 0,
      "lat": 22.82970047,
      "lon": -74.3460998535,
      "tz": "America\/Nassau"
  },
  "MYCS": {
      "icao": "MYCS",
      "iata": "",
      "name": "Cay Sal Airport",
      "city": "Cay Sal",
      "state": "Bimini",
      "country": "BS",
      "elevation": 3,
      "lat": 23.6963996887,
      "lon": -80.3212966919,
      "tz": "America\/Nassau"
  },
  "MYCX": {
      "icao": "MYCX",
      "iata": "",
      "name": "Cutlass Bay Airport",
      "city": "Cutlass Bay",
      "state": "Cat-Island",
      "country": "BS",
      "elevation": 3,
      "lat": 24.1488990784,
      "lon": -75.3981018066,
      "tz": "America\/Nassau"
  },
  "MYEB": {
      "icao": "MYEB",
      "iata": "",
      "name": "Black Point Airstrip",
      "city": "Black Point",
      "state": "Black-Point",
      "country": "BS",
      "elevation": 10,
      "lat": 24.0894884488,
      "lon": -76.3979172707,
      "tz": "America\/Nassau"
  },
  "MYEF": {
      "icao": "MYEF",
      "iata": "GGT",
      "name": "Exuma International Airport",
      "city": "George Town",
      "state": "Exuma",
      "country": "BS",
      "elevation": 9,
      "lat": 23.5625991821,
      "lon": -75.8779983521,
      "tz": "America\/Nassau"
  },
  "MYEG": {
      "icao": "MYEG",
      "iata": "",
      "name": "George Town Airport",
      "city": "George Town",
      "state": "Exuma",
      "country": "BS",
      "elevation": 5,
      "lat": 23.4666996002,
      "lon": -75.7817001343,
      "tz": "America\/Nassau"
  },
  "MYEH": {
      "icao": "MYEH",
      "iata": "ELH",
      "name": "North Eleuthera Airport",
      "city": "North Eleuthera",
      "state": "North-Eleuthera",
      "country": "BS",
      "elevation": 13,
      "lat": 25.474899292,
      "lon": -76.6835021973,
      "tz": "America\/Nassau"
  },
  "MYEL": {
      "icao": "MYEL",
      "iata": "",
      "name": "Lee Stocking Airport",
      "city": "Lee Stocking",
      "state": "Exuma",
      "country": "BS",
      "elevation": 0,
      "lat": 23.7756004333,
      "lon": -76.1035995483,
      "tz": "America\/Nassau"
  },
  "MYEM": {
      "icao": "MYEM",
      "iata": "GHB",
      "name": "Governor's Harbour Airport",
      "city": "Governor's Harbour",
      "state": "North-Eleuthera",
      "country": "BS",
      "elevation": 26,
      "lat": 25.2847003937,
      "lon": -76.3310012817,
      "tz": "America\/Nassau"
  },
  "MYEN": {
      "icao": "MYEN",
      "iata": "NMC",
      "name": "Normans Cay Airport",
      "city": "",
      "state": "Black-Point",
      "country": "BS",
      "elevation": 8,
      "lat": 24.5942993164,
      "lon": -76.8201980591,
      "tz": "America\/Nassau"
  },
  "MYER": {
      "icao": "MYER",
      "iata": "RSD",
      "name": "Rock Sound Airport",
      "city": "Rock Sound",
      "state": "South-Eleuthera",
      "country": "BS",
      "elevation": 10,
      "lat": 24.8950787333,
      "lon": -76.1768817902,
      "tz": "America\/Nassau"
  },
  "MYES": {
      "icao": "MYES",
      "iata": "TYM",
      "name": "Staniel Cay Airport",
      "city": "",
      "state": "Black-Point",
      "country": "BS",
      "elevation": 5,
      "lat": 24.1690998077,
      "lon": -76.4391021729,
      "tz": "America\/Nassau"
  },
  "MYEY": {
      "icao": "MYEY",
      "iata": "",
      "name": "Hog Cay Airport",
      "city": "Hog Cay",
      "state": "Exuma",
      "country": "BS",
      "elevation": 0,
      "lat": 23.3971996307,
      "lon": -75.4969024658,
      "tz": "America\/Nassau"
  },
  "MYGD": {
      "icao": "MYGD",
      "iata": "",
      "name": "Deep Water Cay Airport",
      "city": "Deep Water Cay",
      "state": "East-Grand-Bahama",
      "country": "BS",
      "elevation": 0,
      "lat": 26.6317005157,
      "lon": -77.9216995239,
      "tz": "America\/Nassau"
  },
  "MYGF": {
      "icao": "MYGF",
      "iata": "FPO",
      "name": "Grand Bahama International Airport",
      "city": "Freeport",
      "state": "Freeport",
      "country": "BS",
      "elevation": 7,
      "lat": 26.5587005615,
      "lon": -78.695602417,
      "tz": "America\/Nassau"
  },
  "MYGM": {
      "icao": "MYGM",
      "iata": "GBI",
      "name": "Auxiliary Airfield",
      "city": "Grand Bahama",
      "state": "East-Grand-Bahama",
      "country": "BS",
      "elevation": 8,
      "lat": 26.6319007874,
      "lon": -78.3591995239,
      "tz": "America\/Nassau"
  },
  "MYGW": {
      "icao": "MYGW",
      "iata": "WTD",
      "name": "West End Airport",
      "city": "West End",
      "state": "West-Grand-Bahama",
      "country": "BS",
      "elevation": 5,
      "lat": 26.685300827,
      "lon": -78.9749984741,
      "tz": "America\/Nassau"
  },
  "MYIG": {
      "icao": "MYIG",
      "iata": "IGA",
      "name": "Inagua Airport",
      "city": "Matthew Town",
      "state": "Inagua",
      "country": "BS",
      "elevation": 8,
      "lat": 20.9750003815,
      "lon": -73.6669006348,
      "tz": "America\/Nassau"
  },
  "MYLD": {
      "icao": "MYLD",
      "iata": "LGI",
      "name": "Deadman's Cay Airport",
      "city": "Deadman's Cay",
      "state": "Long-Island",
      "country": "BS",
      "elevation": 9,
      "lat": 23.1790008545,
      "lon": -75.0935974121,
      "tz": "America\/Nassau"
  },
  "MYLS": {
      "icao": "MYLS",
      "iata": "SML",
      "name": "Stella Maris Airport",
      "city": "Stella Maris",
      "state": "Long-Island",
      "country": "BS",
      "elevation": 10,
      "lat": 23.5823168043,
      "lon": -75.2686214447,
      "tz": "America\/Nassau"
  },
  "MYMM": {
      "icao": "MYMM",
      "iata": "MYG",
      "name": "Mayaguana Airport",
      "city": "Mayaguana",
      "state": "Mayaguana",
      "country": "BS",
      "elevation": 11,
      "lat": 22.3794994354,
      "lon": -73.0134963989,
      "tz": "America\/Nassau"
  },
  "MYNN": {
      "icao": "MYNN",
      "iata": "NAS",
      "name": "Lynden Pindling International Airport",
      "city": "Nassau",
      "state": "New-Providence",
      "country": "BS",
      "elevation": 16,
      "lat": 25.0389995575,
      "lon": -77.4662017822,
      "tz": "America\/Nassau"
  },
  "MYPI": {
      "icao": "MYPI",
      "iata": "PID",
      "name": "Nassau Paradise Island Airport",
      "city": "Nassau",
      "state": "New-Providence",
      "country": "BS",
      "elevation": 0,
      "lat": 25.0830001831,
      "lon": -77.3000030518,
      "tz": "America\/Nassau"
  },
  "MYRD": {
      "icao": "MYRD",
      "iata": "DCT",
      "name": "Duncan Town Airport",
      "city": "",
      "state": "Ragged-Island",
      "country": "BS",
      "elevation": 6,
      "lat": 22.1818008423,
      "lon": -75.7294998169,
      "tz": "America\/Nassau"
  },
  "MYRP": {
      "icao": "MYRP",
      "iata": "RCY",
      "name": "Rum Cay Airport",
      "city": "",
      "state": "Rum-Cay",
      "country": "BS",
      "elevation": 15,
      "lat": 23.6844005585,
      "lon": -74.8361968994,
      "tz": "America\/Nassau"
  },
  "MYSM": {
      "icao": "MYSM",
      "iata": "ZSA",
      "name": "San Salvador Airport",
      "city": "San Salvador",
      "state": "San-Salvador",
      "country": "BS",
      "elevation": 24,
      "lat": 24.0632991791,
      "lon": -74.5240020752,
      "tz": "America\/Nassau"
  },
  "MYX4": {
      "icao": "MYX4",
      "iata": "",
      "name": "Big Whale Cay Airport",
      "city": "Big Whale Cay",
      "state": "Berry-Islands",
      "country": "BS",
      "elevation": 16,
      "lat": 25.3995990753,
      "lon": -77.7910003662,
      "tz": "America\/Nassau"
  },
  "MYX5": {
      "icao": "MYX5",
      "iata": "",
      "name": "Cistern Field",
      "city": "Cistern Field",
      "state": "Berry-Islands",
      "country": "BS",
      "elevation": 18,
      "lat": 25.7786998749,
      "lon": -77.8852005005,
      "tz": "America\/Nassau"
  },
  "MYX7": {
      "icao": "MYX7",
      "iata": "",
      "name": "Rudder Cut Cay Airport",
      "city": "Rudder Cut Cay",
      "state": "Black-Point",
      "country": "BS",
      "elevation": 10,
      "lat": 23.8864002228,
      "lon": -76.2537002563,
      "tz": "America\/Nassau"
  },
  "MYX8": {
      "icao": "MYX8",
      "iata": "",
      "name": "Darby Island Airport",
      "city": "Darby Island",
      "state": "Black-Point",
      "country": "BS",
      "elevation": 5,
      "lat": 23.8502998352,
      "lon": -76.2282028198,
      "tz": "America\/Nassau"
  },
  "MYXC": {
      "icao": "MYXC",
      "iata": "",
      "name": "Hog Key Airport",
      "city": "Long Island",
      "state": "Long-Island",
      "country": "BS",
      "elevation": 5,
      "lat": 23.6007003784,
      "lon": -75.339302063,
      "tz": "America\/Nassau"
  },
  "MYXD": {
      "icao": "MYXD",
      "iata": "",
      "name": "Leaf Cay Airport",
      "city": "Leaf Cay",
      "state": "Black-Point",
      "country": "BS",
      "elevation": 0,
      "lat": 24.1492004395,
      "lon": -76.4664001465,
      "tz": "America\/Nassau"
  },
  "MYXH": {
      "icao": "MYXH",
      "iata": "",
      "name": "Sampson Cay Airport",
      "city": "Sampson Cay",
      "state": "Black-Point",
      "country": "BS",
      "elevation": 8,
      "lat": 24.2159996033,
      "lon": -76.4785003662,
      "tz": "America\/Nassau"
  },
  "MYZ2": {
      "icao": "MYZ2",
      "iata": "",
      "name": "Cape Eleuthera Airport",
      "city": "Cape Eleuthera",
      "state": "South-Eleuthera",
      "country": "BS",
      "elevation": 9,
      "lat": 24.789899826,
      "lon": -76.2939987183,
      "tz": "America\/Nassau"
  },
  "MYZ3": {
      "icao": "MYZ3",
      "iata": "",
      "name": "Little Whale Cay Berry Islands Airport",
      "city": "Little Whale Cay",
      "state": "Berry-Islands",
      "country": "BS",
      "elevation": 5,
      "lat": 25.4417991638,
      "lon": -77.7617034912,
      "tz": "America\/Nassau"
  },
  "MZBZ": {
      "icao": "MZBZ",
      "iata": "BZE",
      "name": "Philip S. W. Goldson International Airport",
      "city": "Belize City",
      "state": "Belize",
      "country": "BZ",
      "elevation": 15,
      "lat": 17.539100647,
      "lon": -88.3081970215,
      "tz": "America\/Belize"
  },
  "NA01": {
      "icao": "NA01",
      "iata": "",
      "name": "Jenson Airport",
      "city": "Reynolds",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 867,
      "lat": 47.6683006287,
      "lon": -97.0054016113,
      "tz": "America\/Chicago"
  },
  "NA02": {
      "icao": "NA02",
      "iata": "",
      "name": "Saure Airport",
      "city": "Reynolds",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 870,
      "lat": 47.7050018311,
      "lon": -97.0548019409,
      "tz": "America\/Chicago"
  },
  "NA03": {
      "icao": "NA03",
      "iata": "",
      "name": "Boll Brothers Airstrip",
      "city": "Russell",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1453,
      "lat": 48.6725006104,
      "lon": -100.858001709,
      "tz": "America\/Chicago"
  },
  "NA05": {
      "icao": "NA05",
      "iata": "",
      "name": "Kraig Farms Airport",
      "city": "Sheldon",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1060,
      "lat": 46.6122016907,
      "lon": -97.4356002808,
      "tz": "America\/Chicago"
  },
  "NA06": {
      "icao": "NA06",
      "iata": "",
      "name": "Bouret Ranch Airport",
      "city": "Sheyenne",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1505,
      "lat": 47.8628005981,
      "lon": -98.9726028442,
      "tz": "America\/Chicago"
  },
  "NA07": {
      "icao": "NA07",
      "iata": "",
      "name": "Kornkven Airstrip",
      "city": "Souris",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1505,
      "lat": 48.8863983154,
      "lon": -100.6949996948,
      "tz": "America\/Chicago"
  },
  "NA10": {
      "icao": "NA10",
      "iata": "",
      "name": "Anderson Private Airport",
      "city": "Starkweather",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1460,
      "lat": 48.3805999756,
      "lon": -99.0336990356,
      "tz": "America\/Chicago"
  },
  "NA12": {
      "icao": "NA12",
      "iata": "",
      "name": "Johnson Private Airport",
      "city": "Turtle Lake",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1920,
      "lat": 47.4317016602,
      "lon": -100.8420028687,
      "tz": "America\/Chicago"
  },
  "NA13": {
      "icao": "NA13",
      "iata": "",
      "name": "Linrud Airstrip",
      "city": "Velva",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1605,
      "lat": 48.1222000122,
      "lon": -100.93699646,
      "tz": "America\/Chicago"
  },
  "NA17": {
      "icao": "NA17",
      "iata": "",
      "name": "Jacob Gust Airport",
      "city": "West Fargo",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 895,
      "lat": 46.9426994324,
      "lon": -96.9290008545,
      "tz": "America\/Chicago"
  },
  "NA18": {
      "icao": "NA18",
      "iata": "",
      "name": "Hought Airstrip",
      "city": "Williston",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2400,
      "lat": 48.4133987427,
      "lon": -103.8359985352,
      "tz": "America\/Chicago"
  },
  "NA20": {
      "icao": "NA20",
      "iata": "",
      "name": "Hudson's Strip",
      "city": "Wyndmere",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1080,
      "lat": 46.2480010986,
      "lon": -97.2573013306,
      "tz": "America\/Chicago"
  },
  "NA21": {
      "icao": "NA21",
      "iata": "",
      "name": "Thompson Private Airport",
      "city": "Wyndmere",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1075,
      "lat": 46.2282981873,
      "lon": -97.2361984253,
      "tz": "America\/Chicago"
  },
  "NA22": {
      "icao": "NA22",
      "iata": "",
      "name": "Vernon Miller Private Airport",
      "city": "Rhame",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 3102,
      "lat": 46.0125007629,
      "lon": -103.7959976196,
      "tz": "America\/Denver"
  },
  "NA25": {
      "icao": "NA25",
      "iata": "",
      "name": "Holen Aerial Spray Airstrip",
      "city": "Bantry",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1460,
      "lat": 48.4986000061,
      "lon": -100.6529998779,
      "tz": "America\/Chicago"
  },
  "NA30": {
      "icao": "NA30",
      "iata": "",
      "name": "Behrens Airstrip",
      "city": "Burlington",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1740,
      "lat": 48.2722015381,
      "lon": -101.3960037231,
      "tz": "America\/Chicago"
  },
  "NA32": {
      "icao": "NA32",
      "iata": "",
      "name": "Johnson Airstrip",
      "city": "Eldridge",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1548,
      "lat": 46.898601532,
      "lon": -98.8587036133,
      "tz": "America\/Chicago"
  },
  "NA33": {
      "icao": "NA33",
      "iata": "",
      "name": "Ripplinger Strip",
      "city": "Esmond",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1600,
      "lat": 48.0010986328,
      "lon": -99.9260025024,
      "tz": "America\/Chicago"
  },
  "NA38": {
      "icao": "NA38",
      "iata": "",
      "name": "Sabbe Brothers Landing Strip",
      "city": "Maddock",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1572,
      "lat": 47.9505996704,
      "lon": -99.4296035767,
      "tz": "America\/Chicago"
  },
  "NA39": {
      "icao": "NA39",
      "iata": "",
      "name": "Dorbrinski Airport",
      "city": "Makoti",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2133,
      "lat": 47.8978004456,
      "lon": -101.8550033569,
      "tz": "America\/Chicago"
  },
  "NA44": {
      "icao": "NA44",
      "iata": "",
      "name": "Georgeson Farm Strip",
      "city": "New Rockford",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1535,
      "lat": 47.6542015076,
      "lon": -99.1595993042,
      "tz": "America\/Chicago"
  },
  "NA49": {
      "icao": "NA49",
      "iata": "",
      "name": "Paul Airstrip",
      "city": "Rugby",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1530,
      "lat": 48.4178009033,
      "lon": -100.0550003052,
      "tz": "America\/Chicago"
  },
  "NA54": {
      "icao": "NA54",
      "iata": "",
      "name": "Moser Airstrip",
      "city": "Westfield",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1750,
      "lat": 45.9550018311,
      "lon": -100.4420013428,
      "tz": "America\/Chicago"
  },
  "NA55": {
      "icao": "NA55",
      "iata": "",
      "name": "Hager Strip",
      "city": "Barney",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1037,
      "lat": 46.2661018372,
      "lon": -97.0161972046,
      "tz": "America\/Chicago"
  },
  "NA56": {
      "icao": "NA56",
      "iata": "",
      "name": "Moffet Airstrip",
      "city": "Barney",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1028,
      "lat": 46.3022003174,
      "lon": -96.9915008545,
      "tz": "America\/Chicago"
  },
  "NA60": {
      "icao": "NA60",
      "iata": "",
      "name": "Dakota Airport",
      "city": "Grandin",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 903,
      "lat": 47.2350006104,
      "lon": -97.0383987427,
      "tz": "America\/Chicago"
  },
  "NA61": {
      "icao": "NA61",
      "iata": "",
      "name": "Tachenko Strip",
      "city": "Grassy Butte",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2686,
      "lat": 47.2956008911,
      "lon": -103.1890029907,
      "tz": "America\/Denver"
  },
  "NA63": {
      "icao": "NA63",
      "iata": "",
      "name": "Storseth Airstrip",
      "city": "Grenora",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2200,
      "lat": 48.6666984558,
      "lon": -103.8560028076,
      "tz": "America\/Chicago"
  },
  "NA65": {
      "icao": "NA65",
      "iata": "",
      "name": "Anderson Strip",
      "city": "Hoople",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 890,
      "lat": 48.5313987732,
      "lon": -97.6280975342,
      "tz": "America\/Chicago"
  },
  "NA67": {
      "icao": "NA67",
      "iata": "",
      "name": "Horsley Airstrip",
      "city": "Neche",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 815,
      "lat": 48.9838981628,
      "lon": -97.4645004272,
      "tz": "America\/Chicago"
  },
  "NA70": {
      "icao": "NA70",
      "iata": "",
      "name": "Smith Airstrip",
      "city": "Sheyenne",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1535,
      "lat": 47.7738990784,
      "lon": -98.9884033203,
      "tz": "America\/Chicago"
  },
  "NA71": {
      "icao": "NA71",
      "iata": "",
      "name": "M. Bodvig Airstrip",
      "city": "Tappen",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1750,
      "lat": 46.9958000183,
      "lon": -99.6712036133,
      "tz": "America\/Chicago"
  },
  "NA73": {
      "icao": "NA73",
      "iata": "",
      "name": "Welstad Farms Airstrip",
      "city": "Upham",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1480,
      "lat": 48.5638999939,
      "lon": -101,
      "tz": "America\/Chicago"
  },
  "NA75": {
      "icao": "NA75",
      "iata": "",
      "name": "Lill Strip",
      "city": "Bremen",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1560,
      "lat": 47.676399231,
      "lon": -99.3904037476,
      "tz": "America\/Chicago"
  },
  "NA77": {
      "icao": "NA77",
      "iata": "",
      "name": "Mc Gee Strip",
      "city": "Rhame",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 3014,
      "lat": 45.9866981506,
      "lon": -103.846000671,
      "tz": "America\/Denver"
  },
  "NA79": {
      "icao": "NA79",
      "iata": "",
      "name": "Philbrick Private Airstrip",
      "city": "Turtle Lake",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1915,
      "lat": 47.4042015076,
      "lon": -100.8820037842,
      "tz": "America\/Chicago"
  },
  "NA81": {
      "icao": "NA81",
      "iata": "",
      "name": "Central Valley Aviation Airport",
      "city": "Buxton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 920,
      "lat": 47.6282997131,
      "lon": -97.0865020752,
      "tz": "America\/Chicago"
  },
  "NA84": {
      "icao": "NA84",
      "iata": "",
      "name": "L. Seckerson Airstrip",
      "city": "Eldridge",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1740,
      "lat": 46.8004989624,
      "lon": -99.0050964355,
      "tz": "America\/Chicago"
  },
  "NA86": {
      "icao": "NA86",
      "iata": "",
      "name": "Johnson Private Airstrip",
      "city": "Luverne",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1492,
      "lat": 47.3333015442,
      "lon": -97.9009017944,
      "tz": "America\/Chicago"
  },
  "NA88": {
      "icao": "NA88",
      "iata": "",
      "name": "Regan Airstrip",
      "city": "Regan",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2060,
      "lat": 47.2238998413,
      "lon": -100.422996521,
      "tz": "America\/Chicago"
  },
  "NA89": {
      "icao": "NA89",
      "iata": "",
      "name": "Holzman Airstrip",
      "city": "Scranton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2840,
      "lat": 46.2221984863,
      "lon": -103.0210037231,
      "tz": "America\/Denver"
  },
  "NA90": {
      "icao": "NA90",
      "iata": "",
      "name": "Circle Z Landing Strip",
      "city": "Underwood",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2000,
      "lat": 47.4667015076,
      "lon": -101.2229995728,
      "tz": "America\/Chicago"
  },
  "NA92": {
      "icao": "NA92",
      "iata": "",
      "name": "Roy Lohse Airport",
      "city": "Alamo",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2124,
      "lat": 48.5786018372,
      "lon": -103.466003418,
      "tz": "America\/Chicago"
  },
  "NA98": {
      "icao": "NA98",
      "iata": "",
      "name": "Dilse Private Airstrip",
      "city": "Scranton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2878,
      "lat": 46.3025016785,
      "lon": -103.1699981689,
      "tz": "America\/Denver"
  },
  "NA99": {
      "icao": "NA99",
      "iata": "",
      "name": "Bakko Airstrip",
      "city": "Walcott",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1040,
      "lat": 46.5504989624,
      "lon": -97.0319976807,
      "tz": "America\/Chicago"
  },
  "NC00": {
      "icao": "NC00",
      "iata": "",
      "name": "Moretz Riverside Landing Airport",
      "city": "Sanford",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 326,
      "lat": 35.5600013733,
      "lon": -79.1936035156,
      "tz": "America\/New_York"
  },
  "NC01": {
      "icao": "NC01",
      "iata": "",
      "name": "Harvey Point Defense Testing Activity Airport",
      "city": "Hertford",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 10,
      "lat": 36.0961990356,
      "lon": -76.3271026611,
      "tz": "America\/New_York"
  },
  "NC02": {
      "icao": "NC02",
      "iata": "",
      "name": "Boomerang Airport",
      "city": "Harris",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 850,
      "lat": 35.212600708,
      "lon": -81.8665008545,
      "tz": "America\/New_York"
  },
  "NC03": {
      "icao": "NC03",
      "iata": "",
      "name": "Darr Field",
      "city": "High Point",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 874,
      "lat": 35.9085006714,
      "lon": -79.9872970581,
      "tz": "America\/New_York"
  },
  "NC05": {
      "icao": "NC05",
      "iata": "",
      "name": "Bradford Field Airport",
      "city": "Huntersville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 649,
      "lat": 35.4086990356,
      "lon": -80.7941970825,
      "tz": "America\/New_York"
  },
  "NC06": {
      "icao": "NC06",
      "iata": "",
      "name": "Elk River Airport",
      "city": "Banner Elk",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 3468,
      "lat": 36.1604003906,
      "lon": -81.8967971802,
      "tz": "America\/New_York"
  },
  "NC08": {
      "icao": "NC08",
      "iata": "",
      "name": "Tusquittee Landing Airport",
      "city": "Hayesville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2000,
      "lat": 35.0853996277,
      "lon": -83.7362976074,
      "tz": "America\/New_York"
  },
  "NC09": {
      "icao": "NC09",
      "iata": "",
      "name": "Stoneriver Airport",
      "city": "Statesville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 820,
      "lat": 35.8838996887,
      "lon": -80.7767028809,
      "tz": "America\/New_York"
  },
  "NC10": {
      "icao": "NC10",
      "iata": "",
      "name": "Nocarva Airport",
      "city": "Macon",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 275,
      "lat": 36.5418014526,
      "lon": -78.0579986572,
      "tz": "America\/New_York"
  },
  "NC11": {
      "icao": "NC11",
      "iata": "",
      "name": "Deck Airpark",
      "city": "Apex",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 338,
      "lat": 35.7415008545,
      "lon": -78.924697876,
      "tz": "America\/New_York"
  },
  "NC12": {
      "icao": "NC12",
      "iata": "",
      "name": "Lee Creek Airport",
      "city": "Aurora",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 3,
      "lat": 35.3895988464,
      "lon": -76.7846984863,
      "tz": "America\/New_York"
  },
  "NC13": {
      "icao": "NC13",
      "iata": "",
      "name": "Yorks Field",
      "city": "Ramseur",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 571,
      "lat": 35.751499176,
      "lon": -79.6706008911,
      "tz": "America\/New_York"
  },
  "NC14": {
      "icao": "NC14",
      "iata": "",
      "name": "Boone Inc Airport",
      "city": "Boone",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 3120,
      "lat": 36.1968002319,
      "lon": -81.639503479,
      "tz": "America\/New_York"
  },
  "NC15": {
      "icao": "NC15",
      "iata": "",
      "name": "Raeford Rhyne Airpark",
      "city": "Statesville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1140,
      "lat": 35.9570999146,
      "lon": -80.9789962769,
      "tz": "America\/New_York"
  },
  "NC16": {
      "icao": "NC16",
      "iata": "",
      "name": "Brevard Airport",
      "city": "Brevard",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2125,
      "lat": 35.2225990295,
      "lon": -82.7267990112,
      "tz": "America\/New_York"
  },
  "NC17": {
      "icao": "NC17",
      "iata": "",
      "name": "Wolf's Den Airport",
      "city": "Cedar Island",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 10,
      "lat": 34.9802017212,
      "lon": -76.3079986572,
      "tz": "America\/New_York"
  },
  "NC18": {
      "icao": "NC18",
      "iata": "",
      "name": "Brickhouse Field",
      "city": "Burgaw",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 31,
      "lat": 34.414100647,
      "lon": -77.9496994019,
      "tz": "America\/New_York"
  },
  "NC19": {
      "icao": "NC19",
      "iata": "",
      "name": "Propst Airport",
      "city": "Concord",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 621,
      "lat": 35.3917999268,
      "lon": -80.5755996704,
      "tz": "America\/New_York"
  },
  "NC20": {
      "icao": "NC20",
      "iata": "",
      "name": "Canaan Air Base",
      "city": "New Bern",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 33,
      "lat": 35.08727264,
      "lon": -77.16246796,
      "tz": "America\/New_York"
  },
  "NC21": {
      "icao": "NC21",
      "iata": "",
      "name": "Aero Plantation Airport",
      "city": "Weddington",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 634,
      "lat": 34.9917984009,
      "lon": -80.7483978271,
      "tz": "America\/New_York"
  },
  "NC22": {
      "icao": "NC22",
      "iata": "",
      "name": "Charles Field",
      "city": "Dunn",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 185,
      "lat": 35.2275009155,
      "lon": -78.5551986694,
      "tz": "America\/New_York"
  },
  "NC25": {
      "icao": "NC25",
      "iata": "",
      "name": "Gold Hill Airport",
      "city": "Gold Hill",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 720,
      "lat": 35.5089988708,
      "lon": -80.3112030029,
      "tz": "America\/New_York"
  },
  "NC26": {
      "icao": "NC26",
      "iata": "",
      "name": "Long Island Airport",
      "city": "Long Island",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 864,
      "lat": 35.6609992981,
      "lon": -80.9705963135,
      "tz": "America\/New_York"
  },
  "NC27": {
      "icao": "NC27",
      "iata": "",
      "name": "Lower Creek Airport",
      "city": "Lenoir",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1195,
      "lat": 35.9445991516,
      "lon": -81.4900970459,
      "tz": "America\/New_York"
  },
  "NC28": {
      "icao": "NC28",
      "iata": "",
      "name": "Meadstown Airstrip",
      "city": "Elizabeth City",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 7,
      "lat": 36.2001991272,
      "lon": -76.2358016968,
      "tz": "America\/New_York"
  },
  "NC29": {
      "icao": "NC29",
      "iata": "",
      "name": "Bradley Field",
      "city": "China Grove",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 795,
      "lat": 35.620098114,
      "lon": -80.5976028442,
      "tz": "America\/New_York"
  },
  "NC30": {
      "icao": "NC30",
      "iata": "",
      "name": "Miller Air Park",
      "city": "Mooresville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 864,
      "lat": 35.6139984131,
      "lon": -80.7415008545,
      "tz": "America\/New_York"
  },
  "NC31": {
      "icao": "NC31",
      "iata": "",
      "name": "Quiet Acres Airport",
      "city": "Thomasville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 740,
      "lat": 35.7649002075,
      "lon": -80.0242004395,
      "tz": "America\/New_York"
  },
  "NC32": {
      "icao": "NC32",
      "iata": "",
      "name": "Berts Airport",
      "city": "Clemmons",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 690,
      "lat": 36.0257987976,
      "lon": -80.4364013672,
      "tz": "America\/New_York"
  },
  "NC34": {
      "icao": "NC34",
      "iata": "",
      "name": "Miles Airport",
      "city": "Chapel Hill",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 580,
      "lat": 35.9704017639,
      "lon": -79.1247024536,
      "tz": "America\/New_York"
  },
  "NC35": {
      "icao": "NC35",
      "iata": "",
      "name": "Spencer Airport",
      "city": "Concord",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 755,
      "lat": 35.4029006958,
      "lon": -80.6220016479,
      "tz": "America\/New_York"
  },
  "NC36": {
      "icao": "NC36",
      "iata": "",
      "name": "Benton's Airfield",
      "city": "Pikeville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 145,
      "lat": 35.5028991699,
      "lon": -78.0302963257,
      "tz": "America\/New_York"
  },
  "NC37": {
      "icao": "NC37",
      "iata": "",
      "name": "Mountain View Aerodrome",
      "city": "Reidsville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 800,
      "lat": 36.3400993347,
      "lon": -79.8411026001,
      "tz": "America\/New_York"
  },
  "NC39": {
      "icao": "NC39",
      "iata": "",
      "name": "Enochville Airport",
      "city": "Kannapolis",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 789,
      "lat": 35.5242996216,
      "lon": -80.6477966309,
      "tz": "America\/New_York"
  },
  "NC40": {
      "icao": "NC40",
      "iata": "",
      "name": "Holeman Field",
      "city": "Roxboro",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 578,
      "lat": 36.4996986389,
      "lon": -79.118598938,
      "tz": "America\/New_York"
  },
  "NC42": {
      "icao": "NC42",
      "iata": "",
      "name": "Piney Ridge Airport",
      "city": "Yadkinville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 960,
      "lat": 36.072101593,
      "lon": -80.699798584,
      "tz": "America\/New_York"
  },
  "NC43": {
      "icao": "NC43",
      "iata": "",
      "name": "Bear Pen Airport",
      "city": "Supply",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 63,
      "lat": 34.1251983643,
      "lon": -78.3380966187,
      "tz": "America\/New_York"
  },
  "NC44": {
      "icao": "NC44",
      "iata": "",
      "name": "Barclaysville Field",
      "city": "Angier",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 300,
      "lat": 35.4668998718,
      "lon": -78.6838989258,
      "tz": "America\/New_York"
  },
  "NC45": {
      "icao": "NC45",
      "iata": "",
      "name": "Enfield-Shearin Airport",
      "city": "Enfield",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 125,
      "lat": 36.2172012329,
      "lon": -77.6374969482,
      "tz": "America\/New_York"
  },
  "NC47": {
      "icao": "NC47",
      "iata": "",
      "name": "South Oak Aerodrome",
      "city": "Winterville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 50,
      "lat": 35.48279953,
      "lon": -77.353302002,
      "tz": "America\/New_York"
  },
  "NC48": {
      "icao": "NC48",
      "iata": "",
      "name": "Safe Field",
      "city": "Wallace",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 50,
      "lat": 34.7501983643,
      "lon": -78.0599975586,
      "tz": "America\/New_York"
  },
  "NC49": {
      "icao": "NC49",
      "iata": "",
      "name": "Boyd Field",
      "city": "Grimesland",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 50,
      "lat": 35.5278015137,
      "lon": -77.1967010498,
      "tz": "America\/New_York"
  },
  "NC50": {
      "icao": "NC50",
      "iata": "",
      "name": "Skyland Airport",
      "city": "Jefferson",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2860,
      "lat": 36.4600982666,
      "lon": -81.3615036011,
      "tz": "America\/New_York"
  },
  "NC52": {
      "icao": "NC52",
      "iata": "",
      "name": "Silver Creek Airport",
      "city": "Morganton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1020,
      "lat": 35.7285003662,
      "lon": -81.7289962769,
      "tz": "America\/New_York"
  },
  "NC54": {
      "icao": "NC54",
      "iata": "",
      "name": "Jackson Private Airport",
      "city": "Atlantic",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 10,
      "lat": 34.8777008057,
      "lon": -76.3565979004,
      "tz": "America\/New_York"
  },
  "NC56": {
      "icao": "NC56",
      "iata": "",
      "name": "Deer Run Airport",
      "city": "Kittrell",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 405,
      "lat": 36.20470047,
      "lon": -78.4689025879,
      "tz": "America\/New_York"
  },
  "NC58": {
      "icao": "NC58",
      "iata": "",
      "name": "Gryder-Teague Airport",
      "city": "Taylorsville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1190,
      "lat": 35.9210014343,
      "lon": -81.1201019287,
      "tz": "America\/New_York"
  },
  "NC59": {
      "icao": "NC59",
      "iata": "",
      "name": "Alexander County Airport",
      "city": "Taylorsville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1110,
      "lat": 35.8507003784,
      "lon": -81.1439971924,
      "tz": "America\/New_York"
  },
  "NC61": {
      "icao": "NC61",
      "iata": "",
      "name": "Blackwater Airstrip",
      "city": "Moyock",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 12,
      "lat": 36.459400177,
      "lon": -76.2108001709,
      "tz": "America\/New_York"
  },
  "NC63": {
      "icao": "NC63",
      "iata": "",
      "name": "Robertson Field",
      "city": "Winston Salem",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 848,
      "lat": 36.1351013184,
      "lon": -80.1847991943,
      "tz": "America\/New_York"
  },
  "NC64": {
      "icao": "NC64",
      "iata": "",
      "name": "Barringer Field",
      "city": "Kannapolis",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 800,
      "lat": 35.5278015137,
      "lon": -80.6967010498,
      "tz": "America\/New_York"
  },
  "NC65": {
      "icao": "NC65",
      "iata": "",
      "name": "Yonder Airport",
      "city": "Atkinson",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 35,
      "lat": 34.5596008301,
      "lon": -78.2680969238,
      "tz": "America\/New_York"
  },
  "NC66": {
      "icao": "NC66",
      "iata": "",
      "name": "Ventosa Plantation Airport",
      "city": "Scotland Neck",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 30,
      "lat": 36.1313018799,
      "lon": -77.2957992554,
      "tz": "America\/New_York"
  },
  "NC67": {
      "icao": "NC67",
      "iata": "",
      "name": "Six Oaks Airport",
      "city": "Asheville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 2015,
      "lat": 35.467778,
      "lon": -82.4525,
      "tz": "America\/New_York"
  },
  "NC69": {
      "icao": "NC69",
      "iata": "",
      "name": "Twin Oak Airport",
      "city": "Clinton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 125,
      "lat": 34.8709983826,
      "lon": -78.4741973877,
      "tz": "America\/New_York"
  },
  "NC70": {
      "icao": "NC70",
      "iata": "",
      "name": "Mckee Airport",
      "city": "Fairmont",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 110,
      "lat": 34.504901886,
      "lon": -79.1025009155,
      "tz": "America\/New_York"
  },
  "NC71": {
      "icao": "NC71",
      "iata": "",
      "name": "E T Field",
      "city": "Parkton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 187,
      "lat": 34.9179000854,
      "lon": -79.0524978638,
      "tz": "America\/New_York"
  },
  "NC72": {
      "icao": "NC72",
      "iata": "",
      "name": "Hinton Field",
      "city": "Princeton",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 152,
      "lat": 35.451499939,
      "lon": -78.1600036621,
      "tz": "America\/New_York"
  },
  "NC73": {
      "icao": "NC73",
      "iata": "",
      "name": "Hodges Farm Airport",
      "city": "Sladesville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 6,
      "lat": 35.4388008118,
      "lon": -76.4494018555,
      "tz": "America\/New_York"
  },
  "NC75": {
      "icao": "NC75",
      "iata": "",
      "name": "Schneider Haven Airstrip",
      "city": "Harmony",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 865,
      "lat": 35.8983001709,
      "lon": -80.7202987671,
      "tz": "America\/New_York"
  },
  "NC76": {
      "icao": "NC76",
      "iata": "",
      "name": "Massengill Airport",
      "city": "Newton Grove",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 190,
      "lat": 35.3179016113,
      "lon": -78.387802124,
      "tz": "America\/New_York"
  },
  "NC77": {
      "icao": "NC77",
      "iata": "",
      "name": "Chalfant Airport",
      "city": "Concord",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 587,
      "lat": 35.4556999207,
      "lon": -80.5755996704,
      "tz": "America\/New_York"
  },
  "NC78": {
      "icao": "NC78",
      "iata": "",
      "name": "Turbeville Airport",
      "city": "Tabor City",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 105,
      "lat": 34.2102012634,
      "lon": -78.9552993774,
      "tz": "America\/New_York"
  },
  "NC79": {
      "icao": "NC79",
      "iata": "",
      "name": "Bear Creek Airport",
      "city": "Mount Pleasant",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 795,
      "lat": 35.4071006775,
      "lon": -80.3575973511,
      "tz": "America\/New_York"
  },
  "NC81": {
      "icao": "NC81",
      "iata": "",
      "name": "Cox Airport",
      "city": "Apex",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 455,
      "lat": 35.7518005371,
      "lon": -78.859703064,
      "tz": "America\/New_York"
  },
  "NC82": {
      "icao": "NC82",
      "iata": "",
      "name": "Dublin Field",
      "city": "Dublin",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 126,
      "lat": 34.6696014404,
      "lon": -78.7024993896,
      "tz": "America\/New_York"
  },
  "NC86": {
      "icao": "NC86",
      "iata": "",
      "name": "Rocking A Farm Airport",
      "city": "White Oak",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 58,
      "lat": 34.7336006165,
      "lon": -78.7530975342,
      "tz": "America\/New_York"
  },
  "NC87": {
      "icao": "NC87",
      "iata": "",
      "name": "Dogwood Farm Airport",
      "city": "Havelock",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 26,
      "lat": 34.8818016052,
      "lon": -76.9427032471,
      "tz": "America\/New_York"
  },
  "NC93": {
      "icao": "NC93",
      "iata": "",
      "name": "South River Airport",
      "city": "Salisbury",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 650,
      "lat": 35.7778015137,
      "lon": -80.5014038086,
      "tz": "America\/New_York"
  },
  "NC97": {
      "icao": "NC97",
      "iata": "",
      "name": "Dirt Dobber's Grass Strip",
      "city": "Shelby",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 930,
      "lat": 35.3689002991,
      "lon": -81.5224990845,
      "tz": "America\/New_York"
  },
  "NC99": {
      "icao": "NC99",
      "iata": "",
      "name": "Bagwell Airport",
      "city": "Garner",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 300,
      "lat": 35.6743011475,
      "lon": -78.6072006226,
      "tz": "America\/New_York"
  },
  "NCAI": {
      "icao": "NCAI",
      "iata": "AIT",
      "name": "Aitutaki Airport",
      "city": "Aitutaki",
      "state": "",
      "country": "CK",
      "elevation": 14,
      "lat": -18.8309001923,
      "lon": -159.7640075684,
      "tz": "Pacific\/Rarotonga"
  },
  "NCAT": {
      "icao": "NCAT",
      "iata": "AIU",
      "name": "Enua Airport",
      "city": "Atiu Island",
      "state": "",
      "country": "CK",
      "elevation": 36,
      "lat": -19.9678001404,
      "lon": -158.1190032959,
      "tz": "Pacific\/Rarotonga"
  },
  "NCMG": {
      "icao": "NCMG",
      "iata": "MGS",
      "name": "Mangaia Island Airport",
      "city": "Mangaia Island",
      "state": "",
      "country": "CK",
      "elevation": 45,
      "lat": -21.895986557,
      "lon": -157.9066619873,
      "tz": "Pacific\/Rarotonga"
  },
  "NCMH": {
      "icao": "NCMH",
      "iata": "MHX",
      "name": "Manihiki Island Airport",
      "city": "Manihiki Island",
      "state": "",
      "country": "CK",
      "elevation": 0,
      "lat": -10.3767004013,
      "lon": -161.0019989014,
      "tz": "Pacific\/Rarotonga"
  },
  "NCMK": {
      "icao": "NCMK",
      "iata": "MUK",
      "name": "Mauke Airport",
      "city": "Mauke Island",
      "state": "",
      "country": "CK",
      "elevation": 26,
      "lat": -20.136100769,
      "lon": -157.3450012207,
      "tz": "Pacific\/Rarotonga"
  },
  "NCMN": {
      "icao": "NCMN",
      "iata": "",
      "name": "Manuae Airport",
      "city": "Manuae",
      "state": "",
      "country": "CK",
      "elevation": 15,
      "lat": -19.2667007446,
      "lon": -158.9600067139,
      "tz": "Pacific\/Rarotonga"
  },
  "NCMR": {
      "icao": "NCMR",
      "iata": "MOI",
      "name": "Mitiaro Island Airport",
      "city": "Mitiaro Island",
      "state": "",
      "country": "CK",
      "elevation": 25,
      "lat": -19.8425006866,
      "lon": -157.7030029297,
      "tz": "Pacific\/Rarotonga"
  },
  "NCPY": {
      "icao": "NCPY",
      "iata": "PYE",
      "name": "Tongareva Airport",
      "city": "Penrhyn Island",
      "state": "",
      "country": "CK",
      "elevation": 8,
      "lat": -9.0143699646,
      "lon": -158.032409668,
      "tz": "Pacific\/Rarotonga"
  },
  "NCRG": {
      "icao": "NCRG",
      "iata": "RAR",
      "name": "Rarotonga International Airport",
      "city": "Avarua",
      "state": "",
      "country": "CK",
      "elevation": 19,
      "lat": -21.2026996613,
      "lon": -159.8059997559,
      "tz": "Pacific\/Rarotonga"
  },
  "ND01": {
      "icao": "ND01",
      "iata": "",
      "name": "Nelson Airport",
      "city": "Amenia",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 950,
      "lat": 47.0457992554,
      "lon": -97.1865005493,
      "tz": "America\/Chicago"
  },
  "ND02": {
      "icao": "ND02",
      "iata": "",
      "name": "Ricketyback Field",
      "city": "Aneta",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1510,
      "lat": 47.8111000061,
      "lon": -97.9600982666,
      "tz": "America\/Chicago"
  },
  "ND07": {
      "icao": "ND07",
      "iata": "",
      "name": "Punton Private Airport",
      "city": "Absaraka",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1105,
      "lat": 47.0027999878,
      "lon": -97.4072036743,
      "tz": "America\/Chicago"
  },
  "ND09": {
      "icao": "ND09",
      "iata": "",
      "name": "Bryn Airport",
      "city": "Dazey",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1431,
      "lat": 47.1911010742,
      "lon": -98.1689987183,
      "tz": "America\/Chicago"
  },
  "ND10": {
      "icao": "ND10",
      "iata": "",
      "name": "Flying-N-Ranch Airport",
      "city": "Christine",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 930,
      "lat": 46.5489006042,
      "lon": -96.7864990234,
      "tz": "America\/Chicago"
  },
  "ND14": {
      "icao": "ND14",
      "iata": "",
      "name": "Fordville Airport",
      "city": "Fordville",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1154,
      "lat": 48.2346992493,
      "lon": -97.8018035889,
      "tz": "America\/Chicago"
  },
  "ND16": {
      "icao": "ND16",
      "iata": "",
      "name": "Frei Private Airport",
      "city": "Halliday",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2100,
      "lat": 47.4416999817,
      "lon": -102.2419967651,
      "tz": "America\/Denver"
  },
  "ND18": {
      "icao": "ND18",
      "iata": "",
      "name": "Judy Strip",
      "city": "Glenburn",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1570,
      "lat": 48.506401062,
      "lon": -101.2170028687,
      "tz": "America\/Chicago"
  },
  "ND20": {
      "icao": "ND20",
      "iata": "",
      "name": "Gensrich Airport",
      "city": "Hatton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1021,
      "lat": 47.7042007446,
      "lon": -97.3500976563,
      "tz": "America\/Chicago"
  },
  "ND22": {
      "icao": "ND22",
      "iata": "",
      "name": "Fischer Private Airport",
      "city": "Garrison",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2065,
      "lat": 47.6833000183,
      "lon": -101.5999984741,
      "tz": "America\/Chicago"
  },
  "ND23": {
      "icao": "ND23",
      "iata": "",
      "name": "Wright Field",
      "city": "Williston",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1885,
      "lat": 48.1880989075,
      "lon": -103.6200027466,
      "tz": "America\/Chicago"
  },
  "ND24": {
      "icao": "ND24",
      "iata": "",
      "name": "Inkster Airport",
      "city": "Inkster",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1092,
      "lat": 48.1403007507,
      "lon": -97.6725997925,
      "tz": "America\/Chicago"
  },
  "ND26": {
      "icao": "ND26",
      "iata": "",
      "name": "Gajewski Field",
      "city": "Alexander",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2100,
      "lat": 47.9000015259,
      "lon": -103.658996582,
      "tz": "America\/Chicago"
  },
  "ND29": {
      "icao": "ND29",
      "iata": "",
      "name": "Swenson Airport",
      "city": "Belfield",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2900,
      "lat": 46.6666984558,
      "lon": -103.2340011597,
      "tz": "America\/Denver"
  },
  "ND32": {
      "icao": "ND32",
      "iata": "",
      "name": "Kalainov Private Airport",
      "city": "Steele",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1845,
      "lat": 46.9155006409,
      "lon": -99.8945007324,
      "tz": "America\/Chicago"
  },
  "ND34": {
      "icao": "ND34",
      "iata": "",
      "name": "Moen Airport",
      "city": "Epping",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2200,
      "lat": 48.2750015259,
      "lon": -103.3499984741,
      "tz": "America\/Chicago"
  },
  "ND35": {
      "icao": "ND35",
      "iata": "",
      "name": "Lindemann Airport",
      "city": "Lucca",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1199,
      "lat": 46.7000007629,
      "lon": -97.7004013062,
      "tz": "America\/Chicago"
  },
  "ND36": {
      "icao": "ND36",
      "iata": "",
      "name": "Don's Airport",
      "city": "St Thomas",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 840,
      "lat": 48.6380004883,
      "lon": -97.4848022461,
      "tz": "America\/Chicago"
  },
  "ND38": {
      "icao": "ND38",
      "iata": "",
      "name": "Rices Airpark",
      "city": "Maddock",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1575,
      "lat": 47.9583015442,
      "lon": -99.5364990234,
      "tz": "America\/Chicago"
  },
  "ND40": {
      "icao": "ND40",
      "iata": "",
      "name": "Rau Field",
      "city": "Medina",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1855,
      "lat": 46.8166999817,
      "lon": -99.2503967285,
      "tz": "America\/Chicago"
  },
  "ND41": {
      "icao": "ND41",
      "iata": "",
      "name": "Voller Airport",
      "city": "Strasburg",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1900,
      "lat": 46.1347007751,
      "lon": -100.1129989624,
      "tz": "America\/Chicago"
  },
  "ND42": {
      "icao": "ND42",
      "iata": "",
      "name": "Warren Pietsch Airport",
      "city": "Sawyer",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1550,
      "lat": 48.103099823,
      "lon": -101.0780029297,
      "tz": "America\/Chicago"
  },
  "ND43": {
      "icao": "ND43",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Minot",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1840,
      "lat": 48.2000007629,
      "lon": -101.4039993286,
      "tz": "America\/Chicago"
  },
  "ND44": {
      "icao": "ND44",
      "iata": "",
      "name": "Underwood Airport",
      "city": "Underwood",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2035,
      "lat": 47.46670151,
      "lon": -101.1340027,
      "tz": "America\/Chicago"
  },
  "ND47": {
      "icao": "ND47",
      "iata": "",
      "name": "Geske Airfield",
      "city": "Enderlin",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1103,
      "lat": 46.6408004761,
      "lon": -97.5511016846,
      "tz": "America\/Chicago"
  },
  "ND49": {
      "icao": "ND49",
      "iata": "",
      "name": "Krause Private Airport",
      "city": "Wyndmere",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1060,
      "lat": 46.2235984802,
      "lon": -97.1304016113,
      "tz": "America\/Chicago"
  },
  "ND52": {
      "icao": "ND52",
      "iata": "",
      "name": "True North Airpark",
      "city": "West Fargo",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 913,
      "lat": 46.8027992249,
      "lon": -97.0121994019,
      "tz": "America\/Chicago"
  },
  "ND54": {
      "icao": "ND54",
      "iata": "",
      "name": "Whitman Field",
      "city": "Robinson",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1783,
      "lat": 47.1291999817,
      "lon": -99.7304000854,
      "tz": "America\/Chicago"
  },
  "ND59": {
      "icao": "ND59",
      "iata": "",
      "name": "Grieve Airport",
      "city": "Buffalo",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1208,
      "lat": 46.9874992371,
      "lon": -97.5295028687,
      "tz": "America\/Chicago"
  },
  "ND60": {
      "icao": "ND60",
      "iata": "",
      "name": "Frokjer Airport",
      "city": "Arvilla",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 953,
      "lat": 47.9589004517,
      "lon": -97.4797973633,
      "tz": "America\/Chicago"
  },
  "ND62": {
      "icao": "ND62",
      "iata": "",
      "name": "Brands Airport",
      "city": "Taylor",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2423,
      "lat": 46.8483009338,
      "lon": -102.4580001831,
      "tz": "America\/Denver"
  },
  "ND65": {
      "icao": "ND65",
      "iata": "",
      "name": "Letzring Airport",
      "city": "Center",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2230,
      "lat": 46.988899231,
      "lon": -101.3539962769,
      "tz": "America\/North_Dakota\/Center"
  },
  "ND68": {
      "icao": "ND68",
      "iata": "",
      "name": "Vining Airport",
      "city": "Wheatland",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 980,
      "lat": 46.9557991028,
      "lon": -97.3012008667,
      "tz": "America\/Chicago"
  },
  "ND72": {
      "icao": "ND72",
      "iata": "",
      "name": "Lonetree Airstrip",
      "city": "Harvey",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1647,
      "lat": 47.6843986511,
      "lon": -100.0849990845,
      "tz": "America\/Chicago"
  },
  "ND73": {
      "icao": "ND73",
      "iata": "",
      "name": "Peterson Airport",
      "city": "Amenia",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 999,
      "lat": 47.0483016968,
      "lon": -97.2555999756,
      "tz": "America\/Chicago"
  },
  "ND74": {
      "icao": "ND74",
      "iata": "",
      "name": "Smith Private Airport",
      "city": "Amenia",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 940,
      "lat": 47.0166015625,
      "lon": -97.162902832,
      "tz": "America\/Chicago"
  },
  "ND75": {
      "icao": "ND75",
      "iata": "",
      "name": "Pete's Tractor Salvage Airport",
      "city": "Anamoose",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1655,
      "lat": 47.7200012207,
      "lon": -100.2689971924,
      "tz": "America\/Chicago"
  },
  "ND76": {
      "icao": "ND76",
      "iata": "",
      "name": "Turner Field",
      "city": "Arthur",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 942,
      "lat": 47.0597000122,
      "lon": -97.1567993164,
      "tz": "America\/Chicago"
  },
  "ND78": {
      "icao": "ND78",
      "iata": "",
      "name": "Wilcox Farm Airport",
      "city": "Ayr",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1210,
      "lat": 47.0400009155,
      "lon": -97.5370025635,
      "tz": "America\/Chicago"
  },
  "ND80": {
      "icao": "ND80",
      "iata": "",
      "name": "Spitzer Airport",
      "city": "Baldwin",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2050,
      "lat": 47.0816993713,
      "lon": -100.7249984741,
      "tz": "America\/Chicago"
  },
  "ND85": {
      "icao": "ND85",
      "iata": "",
      "name": "Humann Private Airstrip",
      "city": "Hazelton",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1980,
      "lat": 46.4930992126,
      "lon": -100.1790008545,
      "tz": "America\/Chicago"
  },
  "ND86": {
      "icao": "ND86",
      "iata": "",
      "name": "Peterson Airport",
      "city": "Grendra",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2190,
      "lat": 48.5638999939,
      "lon": -103.9629974365,
      "tz": "America\/Chicago"
  },
  "ND87": {
      "icao": "ND87",
      "iata": "",
      "name": "Indian Hill Resort Airport",
      "city": "Garrison",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 2010,
      "lat": 47.6055984497,
      "lon": -102.1050033569,
      "tz": "America\/Chicago"
  },
  "ND89": {
      "icao": "ND89",
      "iata": "",
      "name": "Mutschler Field",
      "city": "Clementsville",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1490,
      "lat": 47.0558013916,
      "lon": -98.5070037842,
      "tz": "America\/Chicago"
  },
  "ND90": {
      "icao": "ND90",
      "iata": "",
      "name": "Dahl Private Airport",
      "city": "Cogswell\/Brampton\/",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1285,
      "lat": 45.9665985107,
      "lon": -97.7337036133,
      "tz": "America\/Chicago"
  },
  "ND92": {
      "icao": "ND92",
      "iata": "",
      "name": "Schroeder Airport",
      "city": "Davenport",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 920,
      "lat": 46.7083015442,
      "lon": -97.1169967651,
      "tz": "America\/Chicago"
  },
  "ND96": {
      "icao": "ND96",
      "iata": "",
      "name": "Sauter Airport",
      "city": "Bismark",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1730,
      "lat": 46.801700592,
      "lon": -100.6790008545,
      "tz": "America\/Chicago"
  },
  "ND98": {
      "icao": "ND98",
      "iata": "",
      "name": "Cloud Nine Airport",
      "city": "Lincoln",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1830,
      "lat": 46.760799408,
      "lon": -100.6549987793,
      "tz": "America\/Chicago"
  },
  "ND99": {
      "icao": "ND99",
      "iata": "",
      "name": "Ellig Field",
      "city": "Hickson",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 910,
      "lat": 46.720199585,
      "lon": -96.8095016479,
      "tz": "America\/Chicago"
  },
  "NE01": {
      "icao": "NE01",
      "iata": "",
      "name": "Schutte Airport",
      "city": "Guide Rock",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1876,
      "lat": 40.1946983337,
      "lon": -98.3519973755,
      "tz": "America\/Chicago"
  },
  "NE04": {
      "icao": "NE04",
      "iata": "",
      "name": "Ely Airport",
      "city": "Guide Rock",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1743,
      "lat": 40.0945014954,
      "lon": -98.3416976929,
      "tz": "America\/Chicago"
  },
  "NE06": {
      "icao": "NE06",
      "iata": "",
      "name": "Woolf Brothers Airport",
      "city": "Lynch",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1395,
      "lat": 42.8250007629,
      "lon": -98.4253997803,
      "tz": "America\/Chicago"
  },
  "NE08": {
      "icao": "NE08",
      "iata": "",
      "name": "Larrabee Airport",
      "city": "Lamar",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3518,
      "lat": 40.5477981567,
      "lon": -101.9779968262,
      "tz": "America\/Denver"
  },
  "NE09": {
      "icao": "NE09",
      "iata": "",
      "name": "Simpson Airport",
      "city": "Norfolk",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1650,
      "lat": 41.9632987976,
      "lon": -97.467300415,
      "tz": "America\/Chicago"
  },
  "NE11": {
      "icao": "NE11",
      "iata": "",
      "name": "Durands Sky Ranch Airport",
      "city": "Omaha",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1285,
      "lat": 41.3600006104,
      "lon": -96.0402984619,
      "tz": "America\/Chicago"
  },
  "NE12": {
      "icao": "NE12",
      "iata": "",
      "name": "Sullivan Airstrip",
      "city": "Palmer",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1745,
      "lat": 41.238899231,
      "lon": -98.2281036377,
      "tz": "America\/Chicago"
  },
  "NE15": {
      "icao": "NE15",
      "iata": "",
      "name": "Starns Auxiliary Airport",
      "city": "Ashland",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1080,
      "lat": 41.0499992371,
      "lon": -96.3827972412,
      "tz": "America\/Chicago"
  },
  "NE17": {
      "icao": "NE17",
      "iata": "",
      "name": "Walts Aerial Service Airport",
      "city": "St. Paul",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1795,
      "lat": 41.2486000061,
      "lon": -98.4559020996,
      "tz": "America\/Chicago"
  },
  "NE18": {
      "icao": "NE18",
      "iata": "",
      "name": "Krutz Airport",
      "city": "Seward",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1450,
      "lat": 40.9141998291,
      "lon": -97.0805969238,
      "tz": "America\/Chicago"
  },
  "NE19": {
      "icao": "NE19",
      "iata": "",
      "name": "Ag Air Airport",
      "city": "Broadwater",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3651,
      "lat": 41.5957984924,
      "lon": -102.8170013428,
      "tz": "America\/Denver"
  },
  "NE20": {
      "icao": "NE20",
      "iata": "",
      "name": "Bernadt Airport",
      "city": "Steinauer",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1290,
      "lat": 40.1861000061,
      "lon": -96.2114028931,
      "tz": "America\/Chicago"
  },
  "NE24": {
      "icao": "NE24",
      "iata": "",
      "name": "Polaks Sky Ranch Airport",
      "city": "Valparaiso",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1268,
      "lat": 41.0332984924,
      "lon": -96.8170013428,
      "tz": "America\/Chicago"
  },
  "NE25": {
      "icao": "NE25",
      "iata": "",
      "name": "Orr Field",
      "city": "North Platte",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2860,
      "lat": 41.1819000244,
      "lon": -100.8600006104,
      "tz": "America\/Chicago"
  },
  "NE27": {
      "icao": "NE27",
      "iata": "",
      "name": "Abie Sky Ranch Airport",
      "city": "Abie",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1440,
      "lat": 41.3666992188,
      "lon": -96.950302124,
      "tz": "America\/Chicago"
  },
  "NE29": {
      "icao": "NE29",
      "iata": "",
      "name": "Cavanaugh Airport",
      "city": "Minden",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2175,
      "lat": 40.5139007568,
      "lon": -99.0120010376,
      "tz": "America\/Chicago"
  },
  "NE30": {
      "icao": "NE30",
      "iata": "",
      "name": "Olson Field",
      "city": "Holdrege",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2330,
      "lat": 40.573600769,
      "lon": -99.4204025269,
      "tz": "America\/Chicago"
  },
  "NE31": {
      "icao": "NE31",
      "iata": "",
      "name": "B.C. Air Airport",
      "city": "Battle Creek",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1600,
      "lat": 42.0144996643,
      "lon": -97.5864028931,
      "tz": "America\/Chicago"
  },
  "NE33": {
      "icao": "NE33",
      "iata": "",
      "name": "Clearidge Airport",
      "city": "Bennet",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1320,
      "lat": 40.7097015381,
      "lon": -96.4766998291,
      "tz": "America\/Chicago"
  },
  "NE34": {
      "icao": "NE34",
      "iata": "",
      "name": "Fehringer Aerodrome",
      "city": "Sidney",
      "state": "Nebraska",
      "country": "US",
      "elevation": 4300,
      "lat": 41.038898468,
      "lon": -102.9530029297,
      "tz": "America\/Denver"
  },
  "NE35": {
      "icao": "NE35",
      "iata": "",
      "name": "Sutton Airport",
      "city": "Chester",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1583,
      "lat": 40.0583000183,
      "lon": -97.5411987305,
      "tz": "America\/Chicago"
  },
  "NE37": {
      "icao": "NE37",
      "iata": "",
      "name": "Loseke Airstrip",
      "city": "Columbus",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1490,
      "lat": 41.4724998474,
      "lon": -97.2714004517,
      "tz": "America\/Chicago"
  },
  "NE38": {
      "icao": "NE38",
      "iata": "",
      "name": "Aknux Airport",
      "city": "Prosser",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2025,
      "lat": 40.7094993591,
      "lon": -98.5600967407,
      "tz": "America\/Chicago"
  },
  "NE39": {
      "icao": "NE39",
      "iata": "",
      "name": "Warbonnet Ag Strip",
      "city": "Davey",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1325,
      "lat": 40.9500007629,
      "lon": -96.6502990723,
      "tz": "America\/Chicago"
  },
  "NE40": {
      "icao": "NE40",
      "iata": "",
      "name": "Denton Airfield",
      "city": "Denton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1270,
      "lat": 40.715801239,
      "lon": -96.8677978516,
      "tz": "America\/Chicago"
  },
  "NE42": {
      "icao": "NE42",
      "iata": "",
      "name": "Dream Field",
      "city": "Lincoln",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1194,
      "lat": 40.9333000183,
      "lon": -96.7005996704,
      "tz": "America\/Chicago"
  },
  "NE43": {
      "icao": "NE43",
      "iata": "",
      "name": "Musiel Airport",
      "city": "Morse Bluff",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1230,
      "lat": 41.4397010803,
      "lon": -96.7542037964,
      "tz": "America\/Chicago"
  },
  "NE44": {
      "icao": "NE44",
      "iata": "",
      "name": "Koinzan Airport",
      "city": "Elgin",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1919,
      "lat": 41.9832992554,
      "lon": -98.0837020874,
      "tz": "America\/Chicago"
  },
  "NE45": {
      "icao": "NE45",
      "iata": "",
      "name": "Stewart Field",
      "city": "Bennet",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1380,
      "lat": 40.6944007874,
      "lon": -96.577796936,
      "tz": "America\/Chicago"
  },
  "NE49": {
      "icao": "NE49",
      "iata": "",
      "name": "Koke Airport",
      "city": "Gretna",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1265,
      "lat": 41.0666999817,
      "lon": -96.2502975464,
      "tz": "America\/Chicago"
  },
  "NE50": {
      "icao": "NE50",
      "iata": "",
      "name": "Sudbeck Field",
      "city": "Hartington",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1480,
      "lat": 42.6152992249,
      "lon": -97.4103012085,
      "tz": "America\/Chicago"
  },
  "NE51": {
      "icao": "NE51",
      "iata": "",
      "name": "Kohles Airport",
      "city": "Martell",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1320,
      "lat": 40.6571998596,
      "lon": -96.7577972412,
      "tz": "America\/Chicago"
  },
  "NE52": {
      "icao": "NE52",
      "iata": "",
      "name": "Easton Field",
      "city": "Hebron",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1600,
      "lat": 40.0778007507,
      "lon": -97.6149978638,
      "tz": "America\/Chicago"
  },
  "NE53": {
      "icao": "NE53",
      "iata": "",
      "name": "Liesveld Airport",
      "city": "Holland",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1385,
      "lat": 40.5910987854,
      "lon": -96.5888977051,
      "tz": "America\/Chicago"
  },
  "NE54": {
      "icao": "NE54",
      "iata": "",
      "name": "Dostal-Bradley Airport",
      "city": "Howells",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1500,
      "lat": 41.7083015442,
      "lon": -96.9794998169,
      "tz": "America\/Chicago"
  },
  "NE56": {
      "icao": "NE56",
      "iata": "",
      "name": "Werner Airport",
      "city": "Valley",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1157,
      "lat": 41.3414001465,
      "lon": -96.3949966431,
      "tz": "America\/Chicago"
  },
  "NE57": {
      "icao": "NE57",
      "iata": "",
      "name": "Thomas Airport",
      "city": "Liberty",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1350,
      "lat": 40.0922012329,
      "lon": -96.4781036377,
      "tz": "America\/Chicago"
  },
  "NE59": {
      "icao": "NE59",
      "iata": "",
      "name": "Pester Airport",
      "city": "Lincoln",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1150,
      "lat": 40.8333015442,
      "lon": -96.5670013428,
      "tz": "America\/Chicago"
  },
  "NE64": {
      "icao": "NE64",
      "iata": "",
      "name": "Luetkenhaus Airport",
      "city": "Memphis",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1120,
      "lat": 41.1053009033,
      "lon": -96.4095001221,
      "tz": "America\/Chicago"
  },
  "NE65": {
      "icao": "NE65",
      "iata": "",
      "name": "Roth Airport",
      "city": "Milford",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1485,
      "lat": 40.766998291,
      "lon": -97.077796936,
      "tz": "America\/Chicago"
  },
  "NE69": {
      "icao": "NE69",
      "iata": "",
      "name": "Browns Airport",
      "city": "Weeping Water",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1183,
      "lat": 40.8675003052,
      "lon": -96.1100006104,
      "tz": "America\/Chicago"
  },
  "NE73": {
      "icao": "NE73",
      "iata": "",
      "name": "Stinking Water Creek Airport",
      "city": "Elsie",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3165,
      "lat": 40.7233009338,
      "lon": -101.4570007324,
      "tz": "America\/Denver"
  },
  "NE79": {
      "icao": "NE79",
      "iata": "",
      "name": "Mueller Field",
      "city": "Roca",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1405,
      "lat": 40.6528015137,
      "lon": -96.6211013794,
      "tz": "America\/Chicago"
  },
  "NE80": {
      "icao": "NE80",
      "iata": "",
      "name": "Weaver Airport",
      "city": "Roca \/Lincoln\/",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1330,
      "lat": 40.676399231,
      "lon": -96.6391983032,
      "tz": "America\/Chicago"
  },
  "NE82": {
      "icao": "NE82",
      "iata": "",
      "name": "Nolte Farms Airport",
      "city": "Murray",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1245,
      "lat": 40.9324989319,
      "lon": -95.99949646,
      "tz": "America\/Chicago"
  },
  "NE85": {
      "icao": "NE85",
      "iata": "",
      "name": "Glaser Airport",
      "city": "Spalding",
      "state": "Nebraska",
      "country": "US",
      "elevation": 2035,
      "lat": 41.6871986389,
      "lon": -98.3025970459,
      "tz": "America\/Chicago"
  },
  "NE86": {
      "icao": "NE86",
      "iata": "",
      "name": "Traudt Airport",
      "city": "Sutton",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1725,
      "lat": 40.665599823,
      "lon": -97.8697967529,
      "tz": "America\/Chicago"
  },
  "NE87": {
      "icao": "NE87",
      "iata": "",
      "name": "Rempe Private Airport",
      "city": "Superior",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1760,
      "lat": 40.0971984863,
      "lon": -98.0647964478,
      "tz": "America\/Chicago"
  },
  "NE88": {
      "icao": "NE88",
      "iata": "",
      "name": "Novotny\/Tonar Farms Airport",
      "city": "Valparaiso",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1426,
      "lat": 41.0208015442,
      "lon": -96.8641967773,
      "tz": "America\/Chicago"
  },
  "NE89": {
      "icao": "NE89",
      "iata": "",
      "name": "Herberts Farm Airport",
      "city": "Winnetoon",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1658,
      "lat": 42.5433006287,
      "lon": -97.9686965942,
      "tz": "America\/Chicago"
  },
  "NE92": {
      "icao": "NE92",
      "iata": "",
      "name": "Thomsen Airport",
      "city": "Wymore",
      "state": "Nebraska",
      "country": "US",
      "elevation": 1405,
      "lat": 40.0494003296,
      "lon": -96.6660995483,
      "tz": "America\/Chicago"
  },
  "NE99": {
      "icao": "NE99",
      "iata": "",
      "name": "Hawkins Ranch Airport",
      "city": "Arthur",
      "state": "Nebraska",
      "country": "US",
      "elevation": 3680,
      "lat": 41.5750007629,
      "lon": -101.8180007935,
      "tz": "America\/Denver"
  },
  "NFCI": {
      "icao": "NFCI",
      "iata": "ICI",
      "name": "Cicia Airport",
      "city": "Cicia",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 13,
      "lat": -17.7432994843,
      "lon": -179.341995239,
      "tz": "Pacific\/Fiji"
  },
  "NFFA": {
      "icao": "NFFA",
      "iata": "BFJ",
      "name": "Ba Airport",
      "city": "",
      "state": "Western",
      "country": "FJ",
      "elevation": 20,
      "lat": -17.5350555556,
      "lon": 177.680916667,
      "tz": "Pacific\/Fiji"
  },
  "NFFN": {
      "icao": "NFFN",
      "iata": "NAN",
      "name": "Nadi International Airport",
      "city": "Nadi",
      "state": "Western",
      "country": "FJ",
      "elevation": 59,
      "lat": -17.755399704,
      "lon": 177.4429931641,
      "tz": "Pacific\/Fiji"
  },
  "NFFO": {
      "icao": "NFFO",
      "iata": "PTF",
      "name": "Malolo Lailai Island Airport",
      "city": "Malolo Lailai Island",
      "state": "Western",
      "country": "FJ",
      "elevation": 10,
      "lat": -17.7779006958,
      "lon": 177.197006226,
      "tz": "Pacific\/Fiji"
  },
  "NFKB": {
      "icao": "NFKB",
      "iata": "",
      "name": "Kaibu Island Airport",
      "city": "Kaibu Island",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 0,
      "lat": -17.2541999817,
      "lon": -179.4889984131,
      "tz": "Pacific\/Fiji"
  },
  "NFKD": {
      "icao": "NFKD",
      "iata": "KDV",
      "name": "Vunisea Airport",
      "city": "Vunisea",
      "state": "",
      "country": "FJ",
      "elevation": 6,
      "lat": -19.0580997467,
      "lon": 178.156997681,
      "tz": "Pacific\/Fiji"
  },
  "NFMA": {
      "icao": "NFMA",
      "iata": "MNF",
      "name": "Mana Island Airport",
      "city": "Mana Island",
      "state": "Western",
      "country": "FJ",
      "elevation": 0,
      "lat": -17.6730995178,
      "lon": 177.098007202,
      "tz": "Pacific\/Fiji"
  },
  "NFMO": {
      "icao": "NFMO",
      "iata": "MFJ",
      "name": "Moala Airport",
      "city": "Moala",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 13,
      "lat": -18.5666999817,
      "lon": 179.951004028,
      "tz": "Pacific\/Fiji"
  },
  "NFNA": {
      "icao": "NFNA",
      "iata": "SUV",
      "name": "Nausori International Airport",
      "city": "Nausori",
      "state": "Central",
      "country": "FJ",
      "elevation": 17,
      "lat": -18.0433006287,
      "lon": 178.5590057373,
      "tz": "Pacific\/Fiji"
  },
  "NFNB": {
      "icao": "NFNB",
      "iata": "LEV",
      "name": "Levuka Airfield",
      "city": "Bureta",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 11,
      "lat": -17.7110996246,
      "lon": 178.759002686,
      "tz": "Pacific\/Fiji"
  },
  "NFNG": {
      "icao": "NFNG",
      "iata": "NGI",
      "name": "Ngau Airport",
      "city": "Ngau",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 50,
      "lat": -18.1156005859,
      "lon": 179.339996338,
      "tz": "Pacific\/Fiji"
  },
  "NFNH": {
      "icao": "NFNH",
      "iata": "LUC",
      "name": "Laucala Island Airport",
      "city": "Laucala Island",
      "state": "Northern",
      "country": "FJ",
      "elevation": 10,
      "lat": -16.7481002808,
      "lon": -179.6670074463,
      "tz": "Pacific\/Fiji"
  },
  "NFNK": {
      "icao": "NFNK",
      "iata": "LKB",
      "name": "Lakeba Island Airport",
      "city": "Lakeba Island",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 280,
      "lat": -18.1991996765,
      "lon": -178.817001343,
      "tz": "Pacific\/Fiji"
  },
  "NFNL": {
      "icao": "NFNL",
      "iata": "LBS",
      "name": "Labasa Airport",
      "city": "",
      "state": "Northern",
      "country": "FJ",
      "elevation": 44,
      "lat": -16.4666996002,
      "lon": 179.3399963379,
      "tz": "Pacific\/Fiji"
  },
  "NFNM": {
      "icao": "NFNM",
      "iata": "TVU",
      "name": "Matei Airport",
      "city": "Matei",
      "state": "Northern",
      "country": "FJ",
      "elevation": 60,
      "lat": -16.6905994415,
      "lon": -179.876998901,
      "tz": "Pacific\/Fiji"
  },
  "NFNO": {
      "icao": "NFNO",
      "iata": "KXF",
      "name": "Koro Island Airport",
      "city": "Koro Island",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 358,
      "lat": -17.3458003998,
      "lon": 179.42199707,
      "tz": "Pacific\/Fiji"
  },
  "NFNR": {
      "icao": "NFNR",
      "iata": "RTA",
      "name": "Rotuma Airport",
      "city": "Rotuma",
      "state": "Rotuma",
      "country": "FJ",
      "elevation": 22,
      "lat": -12.4825000763,
      "lon": 177.0709991455,
      "tz": "Pacific\/Fiji"
  },
  "NFNS": {
      "icao": "NFNS",
      "iata": "SVU",
      "name": "Savusavu Airport",
      "city": "Savusavu",
      "state": "Northern",
      "country": "FJ",
      "elevation": 17,
      "lat": -16.8027992249,
      "lon": 179.341003418,
      "tz": "Pacific\/Fiji"
  },
  "NFNV": {
      "icao": "NFNV",
      "iata": "VAU",
      "name": "Vatukoula Airport",
      "city": "Vatukoula",
      "state": "Western",
      "country": "FJ",
      "elevation": 156,
      "lat": -17.5,
      "lon": 177.8419952393,
      "tz": "Pacific\/Fiji"
  },
  "NFNW": {
      "icao": "NFNW",
      "iata": "KAY",
      "name": "Wakaya Island Airport",
      "city": "Wakaya Island",
      "state": "",
      "country": "FJ",
      "elevation": 130,
      "lat": -17.6170005798,
      "lon": 179.016998291,
      "tz": "Pacific\/Fiji"
  },
  "NFOL": {
      "icao": "NFOL",
      "iata": "ONU",
      "name": "Ono-I-Lau Airport",
      "city": "Ono-I-Lau",
      "state": "",
      "country": "FJ",
      "elevation": 110,
      "lat": -20.6499996185,
      "lon": -178.6999969482,
      "tz": "Pacific\/Fiji"
  },
  "NFSW": {
      "icao": "NFSW",
      "iata": "YAS",
      "name": "Yasawa Island Airport",
      "city": "Yasawa Island",
      "state": "Western",
      "country": "FJ",
      "elevation": 37,
      "lat": -16.7588996887,
      "lon": 177.5449981689,
      "tz": "Pacific\/Fiji"
  },
  "NFTE": {
      "icao": "NFTE",
      "iata": "EUA",
      "name": "Kaufana Airport",
      "city": "Eua Island",
      "state": "\u02bbEua",
      "country": "TO",
      "elevation": 325,
      "lat": -21.3782997131,
      "lon": -174.957992554,
      "tz": "Pacific\/Tongatapu"
  },
  "NFTF": {
      "icao": "NFTF",
      "iata": "TBU",
      "name": "Fua'amotu International Airport",
      "city": "Nuku'alofa",
      "state": "Tongatapu",
      "country": "TO",
      "elevation": 126,
      "lat": -21.2411994934,
      "lon": -175.1499938965,
      "tz": "Pacific\/Tongatapu"
  },
  "NFTL": {
      "icao": "NFTL",
      "iata": "HPA",
      "name": "Lifuka Island Airport",
      "city": "Lifuka",
      "state": "Ha\u2018apai",
      "country": "TO",
      "elevation": 31,
      "lat": -19.7770004272,
      "lon": -174.341003418,
      "tz": "Pacific\/Tongatapu"
  },
  "NFTO": {
      "icao": "NFTO",
      "iata": "Niu",
      "name": "Mata'aho Airport",
      "city": "Angaha",
      "state": "Vava\u2018u",
      "country": "TO",
      "elevation": 160,
      "lat": -15.5707998276,
      "lon": -175.632995605,
      "tz": "Pacific\/Tongatapu"
  },
  "NFTP": {
      "icao": "NFTP",
      "iata": "NTT",
      "name": "Kuini Lavenia Airport",
      "city": "Niuatoputapu",
      "state": "Niuas",
      "country": "TO",
      "elevation": 30,
      "lat": -15.9773376519,
      "lon": -173.791029453,
      "tz": "Pacific\/Tongatapu"
  },
  "NFTV": {
      "icao": "NFTV",
      "iata": "VAV",
      "name": "Vava'u International Airport",
      "city": "Vava'u Island",
      "state": "Vava\u2018u",
      "country": "TO",
      "elevation": 236,
      "lat": -18.5853004456,
      "lon": -173.9620056152,
      "tz": "Pacific\/Tongatapu"
  },
  "NFVB": {
      "icao": "NFVB",
      "iata": "VBV",
      "name": "Vanua Balavu Airport",
      "city": "Vanua Balavu",
      "state": "Eastern",
      "country": "FJ",
      "elevation": 76,
      "lat": -17.2689990997,
      "lon": -178.9759979248,
      "tz": "Pacific\/Fiji"
  },
  "NFVL": {
      "icao": "NFVL",
      "iata": "VTF",
      "name": "Vatulele Airport",
      "city": "Vatulele",
      "state": "Western",
      "country": "FJ",
      "elevation": 36,
      "lat": -18.5125007629,
      "lon": 177.6390075684,
      "tz": "Pacific\/Fiji"
  },
  "NGAB": {
      "icao": "NGAB",
      "iata": "ABF",
      "name": "Abaiang Airport",
      "city": "Abaiang",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": 1.798609972,
      "lon": 173.0410003662,
      "tz": "Pacific\/Tarawa"
  },
  "NGBR": {
      "icao": "NGBR",
      "iata": "BEZ",
      "name": "Beru Airport",
      "city": "Beru",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 6,
      "lat": -1.3547199965,
      "lon": 176.0070037842,
      "tz": "Pacific\/Tarawa"
  },
  "NGFU": {
      "icao": "NGFU",
      "iata": "FUN",
      "name": "Funafuti International Airport",
      "city": "Funafuti",
      "state": "Funafuti",
      "country": "TV",
      "elevation": 0,
      "lat": -8.525,
      "lon": 179.195999,
      "tz": "Pacific\/Funafuti"
  },
  "NGKT": {
      "icao": "NGKT",
      "iata": "KUC",
      "name": "Kuria Airport",
      "city": "Kuria",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": 0.218611002,
      "lon": 173.4420013428,
      "tz": "Pacific\/Tarawa"
  },
  "NGMA": {
      "icao": "NGMA",
      "iata": "MNK",
      "name": "Maiana Airport",
      "city": "Maiana",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 8,
      "lat": 1.0036100149,
      "lon": 173.0310058594,
      "tz": "Pacific\/Tarawa"
  },
  "NGMK": {
      "icao": "NGMK",
      "iata": "MZK",
      "name": "Marakei Airport",
      "city": "Marakei",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 10,
      "lat": 2.0586099625,
      "lon": 173.2709960938,
      "tz": "Pacific\/Tarawa"
  },
  "NGMN": {
      "icao": "NGMN",
      "iata": "MTK",
      "name": "Makin Island Airport",
      "city": "Makin Island",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": 3.3744399548,
      "lon": 172.9920043945,
      "tz": "Pacific\/Tarawa"
  },
  "NGNU": {
      "icao": "NGNU",
      "iata": "NIG",
      "name": "Nikunau Airport",
      "city": "Nikunau",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 6,
      "lat": -1.314440012,
      "lon": 176.410003662,
      "tz": "Pacific\/Tarawa"
  },
  "NGON": {
      "icao": "NGON",
      "iata": "OOT",
      "name": "Onotoa Airport",
      "city": "Onotoa",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 7,
      "lat": -1.796110034,
      "lon": 175.5260009766,
      "tz": "Pacific\/Tarawa"
  },
  "NGTA": {
      "icao": "NGTA",
      "iata": "TRW",
      "name": "Bonriki International Airport",
      "city": "Tarawa",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 9,
      "lat": 1.3816399574,
      "lon": 173.1470031738,
      "tz": "Pacific\/Tarawa"
  },
  "NGTB": {
      "icao": "NGTB",
      "iata": "AEA",
      "name": "Abemama Atoll Airport",
      "city": "Abemama Atoll",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 8,
      "lat": 0.4908330142,
      "lon": 173.828994751,
      "tz": "Pacific\/Tarawa"
  },
  "NGTE": {
      "icao": "NGTE",
      "iata": "TBF",
      "name": "Tabiteuea North Airport",
      "city": "",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 7,
      "lat": -1.2244700193,
      "lon": 174.7760009766,
      "tz": "Pacific\/Tarawa"
  },
  "NGTM": {
      "icao": "NGTM",
      "iata": "TMN",
      "name": "Tamana Island Airport",
      "city": "Tamana Island",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": -2.4858300686,
      "lon": 175.9700012207,
      "tz": "Pacific\/Tarawa"
  },
  "NGTO": {
      "icao": "NGTO",
      "iata": "NON",
      "name": "Nonouti Airport",
      "city": "Nonouti",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 10,
      "lat": -0.6397219896,
      "lon": 174.4279937744,
      "tz": "Pacific\/Tarawa"
  },
  "NGTR": {
      "icao": "NGTR",
      "iata": "AIS",
      "name": "Arorae Island Airport",
      "city": "Arorae Island",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": -2.6161100864,
      "lon": 176.8029937744,
      "tz": "Pacific\/Tarawa"
  },
  "NGTS": {
      "icao": "NGTS",
      "iata": "TSU",
      "name": "Tabiteuea South Airport",
      "city": "Tabiteuea South",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": -1.4744399786,
      "lon": 175.0639953613,
      "tz": "Pacific\/Tarawa"
  },
  "NGTU": {
      "icao": "NGTU",
      "iata": "BBG",
      "name": "Butaritari Atoll Airport",
      "city": "Butaritari Atoll",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 5,
      "lat": 3.0858299732,
      "lon": 172.8110046387,
      "tz": "Pacific\/Tarawa"
  },
  "NGUK": {
      "icao": "NGUK",
      "iata": "AAK",
      "name": "Buariki Airport",
      "city": "Buariki",
      "state": "Gilbert-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": 0.1852779984,
      "lon": 173.6369934082,
      "tz": "Pacific\/Tarawa"
  },
  "NH07": {
      "icao": "NH07",
      "iata": "",
      "name": "Cooper Farm Airport",
      "city": "Loudon",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 485,
      "lat": 43.2803993225,
      "lon": -71.4565963745,
      "tz": "America\/New_York"
  },
  "NH15": {
      "icao": "NH15",
      "iata": "",
      "name": "Locke Lake Airport",
      "city": "Barnstead",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 702,
      "lat": 43.3866996765,
      "lon": -71.2333984375,
      "tz": "America\/New_York"
  },
  "NH16": {
      "icao": "NH16",
      "iata": "",
      "name": "Brookline Airport",
      "city": "Brookline",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 415,
      "lat": 42.7417984009,
      "lon": -71.7076034546,
      "tz": "America\/New_York"
  },
  "NH17": {
      "icao": "NH17",
      "iata": "",
      "name": "Zim Airport",
      "city": "Mason",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 520,
      "lat": 42.7276000977,
      "lon": -71.7175979614,
      "tz": "America\/New_York"
  },
  "NH18": {
      "icao": "NH18",
      "iata": "",
      "name": "Chickville Airport",
      "city": "Center Ossipee",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 560,
      "lat": 43.73339844,
      "lon": -71.15119934,
      "tz": "America\/New_York"
  },
  "NH20": {
      "icao": "NH20",
      "iata": "",
      "name": "Ward Field",
      "city": "Sanbornton",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 440,
      "lat": 43.4922981262,
      "lon": -71.6458969116,
      "tz": "America\/New_York"
  },
  "NH25": {
      "icao": "NH25",
      "iata": "",
      "name": "Flying H Skyport Airport",
      "city": "Melvin Village",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 620,
      "lat": 43.6866989136,
      "lon": -71.2891998291,
      "tz": "America\/New_York"
  },
  "NH31": {
      "icao": "NH31",
      "iata": "",
      "name": "Mountain View Field",
      "city": "Wolfeboro",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 965,
      "lat": 43.650100708,
      "lon": -71.1494979858,
      "tz": "America\/New_York"
  },
  "NH38": {
      "icao": "NH38",
      "iata": "",
      "name": "Leavitt Airport",
      "city": "Albany",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 860,
      "lat": 43.9725990295,
      "lon": -71.1697998047,
      "tz": "America\/New_York"
  },
  "NH39": {
      "icao": "NH39",
      "iata": "",
      "name": "Frank D. Comerford Airport",
      "city": "Walpole",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 640,
      "lat": 43.0334014893,
      "lon": -72.4298019409,
      "tz": "America\/New_York"
  },
  "NH40": {
      "icao": "NH40",
      "iata": "",
      "name": "Eagles Nest Airport",
      "city": "New London",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 998,
      "lat": 43.4047012329,
      "lon": -71.9504013062,
      "tz": "America\/New_York"
  },
  "NH43": {
      "icao": "NH43",
      "iata": "",
      "name": "Murphy-Sherwood Park Airport",
      "city": "Nottingham",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 200,
      "lat": 43.1795005798,
      "lon": -71.1370010376,
      "tz": "America\/New_York"
  },
  "NH49": {
      "icao": "NH49",
      "iata": "",
      "name": "Bradley Field",
      "city": "Woodstock",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 650,
      "lat": 43.9702987671,
      "lon": -71.6794967651,
      "tz": "America\/New_York"
  },
  "NH60": {
      "icao": "NH60",
      "iata": "",
      "name": "Huff Memorial Airport",
      "city": "New Boston",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 840,
      "lat": 42.9606018066,
      "lon": -71.6533966064,
      "tz": "America\/New_York"
  },
  "NH61": {
      "icao": "NH61",
      "iata": "",
      "name": "Heaton Airport",
      "city": "Chester",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 188,
      "lat": 42.9681015015,
      "lon": -71.1811981201,
      "tz": "America\/New_York"
  },
  "NH67": {
      "icao": "NH67",
      "iata": "",
      "name": "Winterwood Airport",
      "city": "Brentwood",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 130,
      "lat": 42.9784011841,
      "lon": -71.0094985962,
      "tz": "America\/New_York"
  },
  "NH69": {
      "icao": "NH69",
      "iata": "",
      "name": "Windsock Village Airport",
      "city": "West Ossipee",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 460,
      "lat": 43.8306007385,
      "lon": -71.18699646,
      "tz": "America\/New_York"
  },
  "NH76": {
      "icao": "NH76",
      "iata": "",
      "name": "Mason Airfield",
      "city": "Mason",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 740,
      "lat": 42.7290000916,
      "lon": -71.7842025757,
      "tz": "America\/New_York"
  },
  "NH78": {
      "icao": "NH78",
      "iata": "",
      "name": "Steck Farm Airport",
      "city": "Pelham",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 460,
      "lat": 42.7472000122,
      "lon": -71.3692016602,
      "tz": "America\/New_York"
  },
  "NH84": {
      "icao": "NH84",
      "iata": "",
      "name": "Northwood Airport",
      "city": "Northwood",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 640,
      "lat": 43.2075996399,
      "lon": -71.2211990356,
      "tz": "America\/New_York"
  },
  "NH86": {
      "icao": "NH86",
      "iata": "",
      "name": "Intervale Airport",
      "city": "Henniker",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 425,
      "lat": 43.1750984192,
      "lon": -71.7873001099,
      "tz": "America\/New_York"
  },
  "NH88": {
      "icao": "NH88",
      "iata": "",
      "name": "Country Club Air Park",
      "city": "Goffstown",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 780,
      "lat": 43.0672988892,
      "lon": -71.6111984253,
      "tz": "America\/New_York"
  },
  "NH96": {
      "icao": "NH96",
      "iata": "",
      "name": "Wentworth Aerodrome Airport",
      "city": "Wentworth",
      "state": "New-Hampshire",
      "country": "US",
      "elevation": 610,
      "lat": 43.873444,
      "lon": -71.904722,
      "tz": "America\/New_York"
  },
  "NIUE": {
      "icao": "NIUE",
      "iata": "IUE",
      "name": "Niue International Airport",
      "city": "Alofi",
      "state": "",
      "country": "NU",
      "elevation": 209,
      "lat": -19.0790309906,
      "lon": -169.9255981445,
      "tz": "Pacific\/Niue"
  },
  "NJ02": {
      "icao": "NJ02",
      "iata": "",
      "name": "Alloway Airfield",
      "city": "Alloway",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 140,
      "lat": 39.5418014526,
      "lon": -75.304397583,
      "tz": "America\/New_York"
  },
  "NJ06": {
      "icao": "NJ06",
      "iata": "",
      "name": "B J Farms Airport",
      "city": "Shiloh",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 100,
      "lat": 39.4575996399,
      "lon": -75.2732009888,
      "tz": "America\/New_York"
  },
  "NJ09": {
      "icao": "NJ09",
      "iata": "",
      "name": "Matthews Airport",
      "city": "Belvidere",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 750,
      "lat": 40.7918014526,
      "lon": -75.0703964233,
      "tz": "America\/New_York"
  },
  "NJ16": {
      "icao": "NJ16",
      "iata": "",
      "name": "Sliker Strip",
      "city": "Califon",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 965,
      "lat": 40.749066667,
      "lon": -74.862786111,
      "tz": "America\/New_York"
  },
  "NJ20": {
      "icao": "NJ20",
      "iata": "",
      "name": "Coyle Field",
      "city": "Chatsworth",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 190,
      "lat": 39.8125991821,
      "lon": -74.4245986938,
      "tz": "America\/New_York"
  },
  "NJ24": {
      "icao": "NJ24",
      "iata": "",
      "name": "Warren Grove Range Airport",
      "city": "Burlington County",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 105,
      "lat": 39.6967010498,
      "lon": -74.4000015259,
      "tz": "America\/New_York"
  },
  "NJ25": {
      "icao": "NJ25",
      "iata": "",
      "name": "Peaslees Airstrip",
      "city": "Clarksboro",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 99,
      "lat": 39.7892990112,
      "lon": -75.2291030884,
      "tz": "America\/New_York"
  },
  "NJ30": {
      "icao": "NJ30",
      "iata": "",
      "name": "Paruszewski Farm Strip",
      "city": "Salem",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 12,
      "lat": 39.5307998657,
      "lon": -75.4772033691,
      "tz": "America\/New_York"
  },
  "NJ46": {
      "icao": "NJ46",
      "iata": "",
      "name": "Mar Bar L Farms Airport",
      "city": "Englishtown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 80,
      "lat": 40.2765007019,
      "lon": -74.3884963989,
      "tz": "America\/New_York"
  },
  "NJ47": {
      "icao": "NJ47",
      "iata": "",
      "name": "Teeny Weeny Acres Airport",
      "city": "Flemington",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 565,
      "lat": 40.5167999268,
      "lon": -74.8828964233,
      "tz": "America\/New_York"
  },
  "NJ49": {
      "icao": "NJ49",
      "iata": "",
      "name": "Bradford Field",
      "city": "Flemington",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 530,
      "lat": 40.5007019043,
      "lon": -74.9560012817,
      "tz": "America\/New_York"
  },
  "NJ50": {
      "icao": "NJ50",
      "iata": "",
      "name": "The Landing Airport",
      "city": "Flemington",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 470,
      "lat": 40.4807014465,
      "lon": -74.9617996216,
      "tz": "America\/New_York"
  },
  "NJ52": {
      "icao": "NJ52",
      "iata": "",
      "name": "Folsom Airport",
      "city": "Folsom",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 90,
      "lat": 39.5918006897,
      "lon": -74.8246002197,
      "tz": "America\/New_York"
  },
  "NJ58": {
      "icao": "NJ58",
      "iata": "",
      "name": "Nordheim Flying K Airpark",
      "city": "Bargaintown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 53,
      "lat": 39.3926010132,
      "lon": -74.606300354,
      "tz": "America\/New_York"
  },
  "NJ59": {
      "icao": "NJ59",
      "iata": "",
      "name": "Ekdahl Airport",
      "city": "Freehold",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 118,
      "lat": 40.1828994751,
      "lon": -74.2788009644,
      "tz": "America\/New_York"
  },
  "NJ60": {
      "icao": "NJ60",
      "iata": "",
      "name": "Cuddihy Landing Strip",
      "city": "Freehold",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 105,
      "lat": 40.1870994568,
      "lon": -74.2670974731,
      "tz": "America\/New_York"
  },
  "NJ61": {
      "icao": "NJ61",
      "iata": "",
      "name": "Malone Airport",
      "city": "Frenchtown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 480,
      "lat": 40.5500984192,
      "lon": -75.0162963867,
      "tz": "America\/New_York"
  },
  "NJ63": {
      "icao": "NJ63",
      "iata": "",
      "name": "Eagles Lair Airport",
      "city": "Frenchtown",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 460,
      "lat": 40.4678993225,
      "lon": -75.0503997803,
      "tz": "America\/New_York"
  },
  "NJ64": {
      "icao": "NJ64",
      "iata": "",
      "name": "Reeder Airport",
      "city": "Glassboro",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 145,
      "lat": 39.7056999207,
      "lon": -75.0802001953,
      "tz": "America\/New_York"
  },
  "NJ65": {
      "icao": "NJ65",
      "iata": "",
      "name": "John E. Rogers Airport",
      "city": "Great Meadows",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 500,
      "lat": 40.8534011841,
      "lon": -74.9296035767,
      "tz": "America\/New_York"
  },
  "NJ69": {
      "icao": "NJ69",
      "iata": "",
      "name": "Ideal Mfg Corp Airport",
      "city": "Hammonton",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 80,
      "lat": 39.6585006714,
      "lon": -74.8023986816,
      "tz": "America\/New_York"
  },
  "NJ72": {
      "icao": "NJ72",
      "iata": "",
      "name": "Hop Brook Farm Airport",
      "city": "Holmdel",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 140,
      "lat": 40.3512001038,
      "lon": -74.1812973022,
      "tz": "America\/New_York"
  },
  "NJ74": {
      "icao": "NJ74",
      "iata": "",
      "name": "Salem Airfield",
      "city": "Salem",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 15,
      "lat": 39.5625991821,
      "lon": -75.4496002197,
      "tz": "America\/New_York"
  },
  "NJ75": {
      "icao": "NJ75",
      "iata": "",
      "name": "Werner Private Airport",
      "city": "Jamesburg",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 50,
      "lat": 40.3501014709,
      "lon": -74.3663024902,
      "tz": "America\/New_York"
  },
  "NJ79": {
      "icao": "NJ79",
      "iata": "",
      "name": "Goat Hill Airport",
      "city": "Lambertville",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 350,
      "lat": 40.3515014648,
      "lon": -74.9093017578,
      "tz": "America\/New_York"
  },
  "NJ81": {
      "icao": "NJ81",
      "iata": "",
      "name": "Free Spirit Airport",
      "city": "Vineland",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 85,
      "lat": 39.4422988892,
      "lon": -74.9427032471,
      "tz": "America\/New_York"
  },
  "NJ84": {
      "icao": "NJ84",
      "iata": "",
      "name": "Hidden Acres Farm Airport",
      "city": "Bridgeton",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 60,
      "lat": 39.4331016541,
      "lon": -75.396697998,
      "tz": "America\/New_York"
  },
  "NJ95": {
      "icao": "NJ95",
      "iata": "",
      "name": "Herr Brothers Airport",
      "city": "Milford",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 330,
      "lat": 40.5556983948,
      "lon": -75.0724029541,
      "tz": "America\/New_York"
  },
  "NJ96": {
      "icao": "NJ96",
      "iata": "",
      "name": "Als Landing Strip",
      "city": "Minotola",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 115,
      "lat": 39.5261993408,
      "lon": -74.9565963745,
      "tz": "America\/New_York"
  },
  "NJ98": {
      "icao": "NJ98",
      "iata": "",
      "name": "Zitone Airport",
      "city": "Montague",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 587,
      "lat": 41.3167991638,
      "lon": -74.7496032715,
      "tz": "America\/New_York"
  },
  "NK01": {
      "icao": "NK01",
      "iata": "",
      "name": "Countryman's Landing Strip",
      "city": "Carthage",
      "state": "New-York",
      "country": "US",
      "elevation": 790,
      "lat": 43.9995002747,
      "lon": -75.6048965454,
      "tz": "America\/New_York"
  },
  "NK04": {
      "icao": "NK04",
      "iata": "",
      "name": "Shepard Airport",
      "city": "Constantia",
      "state": "New-York",
      "country": "US",
      "elevation": 463,
      "lat": 43.2500991821,
      "lon": -75.9996032715,
      "tz": "America\/New_York"
  },
  "NK05": {
      "icao": "NK05",
      "iata": "",
      "name": "Tom N' Jerry Airport",
      "city": "Danby",
      "state": "New-York",
      "country": "US",
      "elevation": 1490,
      "lat": 42.354801178,
      "lon": -76.5160980225,
      "tz": "America\/New_York"
  },
  "NK06": {
      "icao": "NK06",
      "iata": "",
      "name": "S.O.P. Airport",
      "city": "Germantown",
      "state": "New-York",
      "country": "US",
      "elevation": 190,
      "lat": 42.0961990356,
      "lon": -73.8554000854,
      "tz": "America\/New_York"
  },
  "NK07": {
      "icao": "NK07",
      "iata": "",
      "name": "Big Island Airport",
      "city": "Goshen",
      "state": "New-York",
      "country": "US",
      "elevation": 410,
      "lat": 41.3278999329,
      "lon": -74.4098968506,
      "tz": "America\/New_York"
  },
  "NK08": {
      "icao": "NK08",
      "iata": "",
      "name": "Sheeley's Farm Airport",
      "city": "High Falls",
      "state": "New-York",
      "country": "US",
      "elevation": 260,
      "lat": 41.8317985535,
      "lon": -74.1298980713,
      "tz": "America\/New_York"
  },
  "NK09": {
      "icao": "NK09",
      "iata": "",
      "name": "Eagle Ridge Airport",
      "city": "Burdett",
      "state": "New-York",
      "country": "US",
      "elevation": 1480,
      "lat": 42.4056015015,
      "lon": -76.8205032349,
      "tz": "America\/New_York"
  },
  "NK12": {
      "icao": "NK12",
      "iata": "",
      "name": "Donnelly's Airport",
      "city": "Wales",
      "state": "New-York",
      "country": "US",
      "elevation": 1030,
      "lat": 42.7695007324,
      "lon": -78.5469970703,
      "tz": "America\/New_York"
  },
  "NK16": {
      "icao": "NK16",
      "iata": "",
      "name": "Hendricks Field",
      "city": "Gouverneur",
      "state": "New-York",
      "country": "US",
      "elevation": 480,
      "lat": 44.3680992126,
      "lon": -75.4023971558,
      "tz": "America\/New_York"
  },
  "NK17": {
      "icao": "NK17",
      "iata": "",
      "name": "August Field",
      "city": "Gansevoort",
      "state": "New-York",
      "country": "US",
      "elevation": 131,
      "lat": 43.209361,
      "lon": -73.581711,
      "tz": "America\/New_York"
  },
  "NK19": {
      "icao": "NK19",
      "iata": "",
      "name": "Potoczak Airport",
      "city": "Clarence Center",
      "state": "New-York",
      "country": "US",
      "elevation": 582,
      "lat": 43.0611991882,
      "lon": -78.6613998413,
      "tz": "America\/New_York"
  },
  "NK24": {
      "icao": "NK24",
      "iata": "",
      "name": "Tilden Airport",
      "city": "Montour Falls",
      "state": "New-York",
      "country": "US",
      "elevation": 900,
      "lat": 42.3473014832,
      "lon": -76.8787994385,
      "tz": "America\/New_York"
  },
  "NK25": {
      "icao": "NK25",
      "iata": "",
      "name": "Cambria Airport",
      "city": "Lockport",
      "state": "New-York",
      "country": "US",
      "elevation": 380,
      "lat": 43.2159004211,
      "lon": -78.8084030151,
      "tz": "America\/New_York"
  },
  "NK26": {
      "icao": "NK26",
      "iata": "",
      "name": "Old Forge Airport",
      "city": "Old Forge",
      "state": "New-York",
      "country": "US",
      "elevation": 1753,
      "lat": 43.725183,
      "lon": -74.976217,
      "tz": "America\/New_York"
  },
  "NK27": {
      "icao": "NK27",
      "iata": "",
      "name": "Hedge Hop Field",
      "city": "Springville",
      "state": "New-York",
      "country": "US",
      "elevation": 1400,
      "lat": 42.4889984131,
      "lon": -78.634803772,
      "tz": "America\/New_York"
  },
  "NK31": {
      "icao": "NK31",
      "iata": "",
      "name": "Dawn Patrol Aviation Airport",
      "city": "Albion",
      "state": "New-York",
      "country": "US",
      "elevation": 480,
      "lat": 43.2695007324,
      "lon": -78.1453018188,
      "tz": "America\/New_York"
  },
  "NK43": {
      "icao": "NK43",
      "iata": "",
      "name": "Jerry Phibbs Airport",
      "city": "Albany",
      "state": "New-York",
      "country": "US",
      "elevation": 340,
      "lat": 42.7583999634,
      "lon": -73.9245986938,
      "tz": "America\/New_York"
  },
  "NK47": {
      "icao": "NK47",
      "iata": "",
      "name": "Dalrymples Airport",
      "city": "Elmira",
      "state": "New-York",
      "country": "US",
      "elevation": 900,
      "lat": 42.0416984558,
      "lon": -76.8330001831,
      "tz": "America\/New_York"
  },
  "NK52": {
      "icao": "NK52",
      "iata": "",
      "name": "Connelly Field",
      "city": "Elmira",
      "state": "New-York",
      "country": "US",
      "elevation": 1525,
      "lat": 42.0850982666,
      "lon": -76.9213027954,
      "tz": "America\/New_York"
  },
  "NK53": {
      "icao": "NK53",
      "iata": "",
      "name": "Dodge\/Coppola\/Wheeler Airport",
      "city": "Sullivanville",
      "state": "New-York",
      "country": "US",
      "elevation": 1030,
      "lat": 42.2056007385,
      "lon": -76.7705001831,
      "tz": "America\/New_York"
  },
  "NK54": {
      "icao": "NK54",
      "iata": "",
      "name": "Matejka Field",
      "city": "Erin",
      "state": "New-York",
      "country": "US",
      "elevation": 1690,
      "lat": 42.176700592,
      "lon": -76.6410980225,
      "tz": "America\/New_York"
  },
  "NK68": {
      "icao": "NK68",
      "iata": "",
      "name": "White Birch Field",
      "city": "Hancock",
      "state": "New-York",
      "country": "US",
      "elevation": 1860,
      "lat": 42.0500984192,
      "lon": -75.3010025024,
      "tz": "America\/New_York"
  },
  "NK71": {
      "icao": "NK71",
      "iata": "",
      "name": "Marcellus Airport",
      "city": "Marcellus",
      "state": "New-York",
      "country": "US",
      "elevation": 850,
      "lat": 43.0111999512,
      "lon": -76.3497009277,
      "tz": "America\/New_York"
  },
  "NK72": {
      "icao": "NK72",
      "iata": "",
      "name": "James Henion Private Field",
      "city": "Ithaca",
      "state": "New-York",
      "country": "US",
      "elevation": 1300,
      "lat": 42.3762016296,
      "lon": -76.5324020386,
      "tz": "America\/New_York"
  },
  "NK74": {
      "icao": "NK74",
      "iata": "",
      "name": "Match Mate Airport",
      "city": "Aurora",
      "state": "New-York",
      "country": "US",
      "elevation": 840,
      "lat": 42.7444992065,
      "lon": -76.6635971069,
      "tz": "America\/New_York"
  },
  "NK76": {
      "icao": "NK76",
      "iata": "",
      "name": "Grammar Airport",
      "city": "Geneva",
      "state": "New-York",
      "country": "US",
      "elevation": 755,
      "lat": 42.8824996948,
      "lon": -77.0516967773,
      "tz": "America\/New_York"
  },
  "NK79": {
      "icao": "NK79",
      "iata": "",
      "name": "Lewis Landing Airport",
      "city": "Slate Hill",
      "state": "New-York",
      "country": "US",
      "elevation": 880,
      "lat": 41.4183998108,
      "lon": -74.5324020386,
      "tz": "America\/New_York"
  },
  "NK81": {
      "icao": "NK81",
      "iata": "",
      "name": "Roberts Roost Airport",
      "city": "Arcade",
      "state": "New-York",
      "country": "US",
      "elevation": 1751,
      "lat": 42.6333999634,
      "lon": -78.3553009033,
      "tz": "America\/New_York"
  },
  "NK82": {
      "icao": "NK82",
      "iata": "",
      "name": "Engineers Airport",
      "city": "Constantia",
      "state": "New-York",
      "country": "US",
      "elevation": 480,
      "lat": 43.3158988953,
      "lon": -76.0149002075,
      "tz": "America\/New_York"
  },
  "NK83": {
      "icao": "NK83",
      "iata": "",
      "name": "Nettie's Place Airport",
      "city": "Quaker Street",
      "state": "New-York",
      "country": "US",
      "elevation": 1330,
      "lat": 42.7100982666,
      "lon": -74.2237014771,
      "tz": "America\/New_York"
  },
  "NK87": {
      "icao": "NK87",
      "iata": "",
      "name": "Merkle Airport",
      "city": "Clarence Center",
      "state": "New-York",
      "country": "US",
      "elevation": 590,
      "lat": 43.0666999817,
      "lon": -78.5997009277,
      "tz": "America\/New_York"
  },
  "NK89": {
      "icao": "NK89",
      "iata": "",
      "name": "Ultralight Flight Farm Ultralightport",
      "city": "Monterey",
      "state": "New-York",
      "country": "US",
      "elevation": 1200,
      "lat": 42.2916984558,
      "lon": -77.0579986572,
      "tz": "America\/New_York"
  },
  "NK91": {
      "icao": "NK91",
      "iata": "",
      "name": "Boyle's Landing Airport",
      "city": "Sharon Springs",
      "state": "New-York",
      "country": "US",
      "elevation": 1300,
      "lat": 42.8162002563,
      "lon": -74.6246032715,
      "tz": "America\/New_York"
  },
  "NLWF": {
      "icao": "NLWF",
      "iata": "FUT",
      "name": "Pointe Vele Airport",
      "city": "Futuna Island",
      "state": "Alo",
      "country": "WF",
      "elevation": 20,
      "lat": -14.3114004135,
      "lon": -178.065994263,
      "tz": "Pacific\/Wallis"
  },
  "NLWW": {
      "icao": "NLWW",
      "iata": "WLS",
      "name": "Hihifo Airport",
      "city": "Wallis Island",
      "state": "Uvea",
      "country": "WF",
      "elevation": 79,
      "lat": -13.2383003235,
      "lon": -176.199005127,
      "tz": "Pacific\/Wallis"
  },
  "NM01": {
      "icao": "NM01",
      "iata": "",
      "name": "Rancho Magdalena Airport",
      "city": "Magdalena",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6676,
      "lat": 34.0974998474,
      "lon": -107.297996521,
      "tz": "America\/Denver"
  },
  "NM02": {
      "icao": "NM02",
      "iata": "",
      "name": "Biplane Ranch Airport",
      "city": "Moriarty",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6500,
      "lat": 34.92229843,
      "lon": -106.1669998,
      "tz": "America\/Denver"
  },
  "NM03": {
      "icao": "NM03",
      "iata": "",
      "name": "K-D Field",
      "city": "Dexter",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3465,
      "lat": 33.275100708,
      "lon": -104.3690032959,
      "tz": "America\/Denver"
  },
  "NM04": {
      "icao": "NM04",
      "iata": "",
      "name": "Keller Field",
      "city": "Farmington",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5345,
      "lat": 36.6777992249,
      "lon": -108.0839996338,
      "tz": "America\/Denver"
  },
  "NM05": {
      "icao": "NM05",
      "iata": "",
      "name": "Williams Airport",
      "city": "Farmington",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5500,
      "lat": 36.7447013855,
      "lon": -108.1500015259,
      "tz": "America\/Denver"
  },
  "NM08": {
      "icao": "NM08",
      "iata": "",
      "name": "Solo Ranch Airport",
      "city": "Deming",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4200,
      "lat": 32.2625999451,
      "lon": -107.4039993286,
      "tz": "America\/Denver"
  },
  "NM09": {
      "icao": "NM09",
      "iata": "",
      "name": "First Aero Squadron Airpark",
      "city": "Columbus",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4140,
      "lat": 31.8673000336,
      "lon": -107.6360015869,
      "tz": "America\/Denver"
  },
  "NM10": {
      "icao": "NM10",
      "iata": "",
      "name": "Tequesquite Ranch Airport",
      "city": "Albert",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4500,
      "lat": 35.8791999817,
      "lon": -103.81300354,
      "tz": "America\/Denver"
  },
  "NM13": {
      "icao": "NM13",
      "iata": "",
      "name": "Burris E Station Airport",
      "city": "Las Cruces",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4300,
      "lat": 32.2472991943,
      "lon": -107.1920013428,
      "tz": "America\/Denver"
  },
  "NM14": {
      "icao": "NM14",
      "iata": "",
      "name": "Burris 'E' Ranch Airport",
      "city": "Las Cruces",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4400,
      "lat": 32.3111991882,
      "lon": -107.2949981689,
      "tz": "America\/Denver"
  },
  "NM15": {
      "icao": "NM15",
      "iata": "",
      "name": "Clavel Ranch Airport",
      "city": "Roy",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5534,
      "lat": 36.0461006165,
      "lon": -103.8870010376,
      "tz": "America\/Denver"
  },
  "NM16": {
      "icao": "NM16",
      "iata": "",
      "name": "Chama Land & Cattle Co. Airport",
      "city": "Chama",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 8025,
      "lat": 36.8666992188,
      "lon": -106.5670013428,
      "tz": "America\/Denver"
  },
  "NM17": {
      "icao": "NM17",
      "iata": "",
      "name": "Ray Ranch Airport",
      "city": "Roy",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5850,
      "lat": 35.9305992126,
      "lon": -104.1839981079,
      "tz": "America\/Denver"
  },
  "NM20": {
      "icao": "NM20",
      "iata": "",
      "name": "Benedict Airpark",
      "city": "Roswell",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3556,
      "lat": 33.2519989014,
      "lon": -104.4420013428,
      "tz": "America\/Denver"
  },
  "NM21": {
      "icao": "NM21",
      "iata": "",
      "name": "Patterson Ranch Airport",
      "city": "Stanley",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6696,
      "lat": 35.1749992371,
      "lon": -105.8700027466,
      "tz": "America\/Denver"
  },
  "NM24": {
      "icao": "NM24",
      "iata": "",
      "name": "Eastside Airport",
      "city": "Chama",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 8030,
      "lat": 36.9216995239,
      "lon": -106.5599975586,
      "tz": "America\/Denver"
  },
  "NM25": {
      "icao": "NM25",
      "iata": "",
      "name": "Lincoln Station Airport",
      "city": "Corona",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6655,
      "lat": 34.1241989136,
      "lon": -105.6709976196,
      "tz": "America\/Denver"
  },
  "NM26": {
      "icao": "NM26",
      "iata": "",
      "name": "Luna Landing Airport",
      "city": "Deming",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4250,
      "lat": 32.1001014709,
      "lon": -107.8229980469,
      "tz": "America\/Denver"
  },
  "NM27": {
      "icao": "NM27",
      "iata": "",
      "name": "Sanostee Airport",
      "city": "Gallup",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5950,
      "lat": 36.4225006104,
      "lon": -108.861000061,
      "tz": "America\/Denver"
  },
  "NM28": {
      "icao": "NM28",
      "iata": "",
      "name": "Beckett Farm Airport",
      "city": "Tularosa",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4430,
      "lat": 33.0569992065,
      "lon": -106.0250015259,
      "tz": "America\/Denver"
  },
  "NM29": {
      "icao": "NM29",
      "iata": "",
      "name": "Rosebud Airport",
      "city": "Rosebud",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4820,
      "lat": 35.8342018127,
      "lon": -103.43699646,
      "tz": "America\/Denver"
  },
  "NM30": {
      "icao": "NM30",
      "iata": "",
      "name": "Mitchell Farms Airport",
      "city": "Bellview",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4475,
      "lat": 34.8750991821,
      "lon": -103.0670013428,
      "tz": "America\/Denver"
  },
  "NM31": {
      "icao": "NM31",
      "iata": "",
      "name": "Elk Valley Airstrip",
      "city": "Pie Town",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7600,
      "lat": 34.496208,
      "lon": -108.029722,
      "tz": "America\/Denver"
  },
  "NM32": {
      "icao": "NM32",
      "iata": "",
      "name": "Bell Ranch Waggoner Airport",
      "city": "Bell Ranch",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4725,
      "lat": 35.7078018188,
      "lon": -104.0609970093,
      "tz": "America\/Denver"
  },
  "NM33": {
      "icao": "NM33",
      "iata": "",
      "name": "Bell Ranch Headquarters Airport",
      "city": "Bell Ranch",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4400,
      "lat": 35.5475006104,
      "lon": -104.0839996338,
      "tz": "America\/Denver"
  },
  "NM36": {
      "icao": "NM36",
      "iata": "",
      "name": "Candy Kitchen Ranch Airport",
      "city": "Ramah",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7220,
      "lat": 34.9028015137,
      "lon": -108.5149993896,
      "tz": "America\/Denver"
  },
  "NM37": {
      "icao": "NM37",
      "iata": "",
      "name": "Adobe Ranch Private Airport",
      "city": "Truth Or Consequences",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7354,
      "lat": 33.576499939,
      "lon": -107.9000015259,
      "tz": "America\/Denver"
  },
  "NM38": {
      "icao": "NM38",
      "iata": "",
      "name": "Double V Ranch Airport",
      "city": "Fort Sumner",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4114,
      "lat": 34.1172981262,
      "lon": -104.4270019531,
      "tz": "America\/Denver"
  },
  "NM39": {
      "icao": "NM39",
      "iata": "",
      "name": "Davenport Airport",
      "city": "Farmington",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5720,
      "lat": 36.6328010559,
      "lon": -108.3109970093,
      "tz": "America\/Denver"
  },
  "NM40": {
      "icao": "NM40",
      "iata": "",
      "name": "Gorby Ranch Airport",
      "city": "Alamogordo",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4400,
      "lat": 32.9634017944,
      "lon": -105.9990005493,
      "tz": "America\/Denver"
  },
  "NM41": {
      "icao": "NM41",
      "iata": "",
      "name": "Happy Mountain Airport",
      "city": "Pie Town",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7250,
      "lat": 34.0611991882,
      "lon": -108.0960006714,
      "tz": "America\/Denver"
  },
  "NM42": {
      "icao": "NM42",
      "iata": "",
      "name": "Aero Tech Inc Airport",
      "city": "Clovis",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4250,
      "lat": 34.4157981873,
      "lon": -103.1480026245,
      "tz": "America\/Denver"
  },
  "NM44": {
      "icao": "NM44",
      "iata": "",
      "name": "Bojax Ranch Airport",
      "city": "Kenna",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3920,
      "lat": 34.0172996521,
      "lon": -104.1159973145,
      "tz": "America\/Denver"
  },
  "NM47": {
      "icao": "NM47",
      "iata": "",
      "name": "2 X 4 Ranch Airport",
      "city": "Artesia",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3360,
      "lat": 32.7801017761,
      "lon": -104.3809967041,
      "tz": "America\/Denver"
  },
  "NM48": {
      "icao": "NM48",
      "iata": "",
      "name": "Esenada Airport",
      "city": "Ensenada",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7640,
      "lat": 36.7305984497,
      "lon": -106.4950027466,
      "tz": "America\/Denver"
  },
  "NM49": {
      "icao": "NM49",
      "iata": "",
      "name": "J & M Farms Airport",
      "city": "Willard",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6135,
      "lat": 34.5815010071,
      "lon": -106.0749969482,
      "tz": "America\/Denver"
  },
  "NM51": {
      "icao": "NM51",
      "iata": "",
      "name": "Chloride Airport",
      "city": "Chloride",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6202,
      "lat": 33.3283996582,
      "lon": -107.6620025635,
      "tz": "America\/Denver"
  },
  "NM52": {
      "icao": "NM52",
      "iata": "",
      "name": "Camco Ranch Airport",
      "city": "Nara Visa",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4255,
      "lat": 35.5746994019,
      "lon": -103.2870025635,
      "tz": "America\/Denver"
  },
  "NM53": {
      "icao": "NM53",
      "iata": "",
      "name": "San Miguel Ranch Airport",
      "city": "Las Vegas",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6300,
      "lat": 35.5099983215,
      "lon": -104.5839996338,
      "tz": "America\/Denver"
  },
  "NM55": {
      "icao": "NM55",
      "iata": "",
      "name": "Whitewater Mesa Ranch Airport",
      "city": "Glenwood",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5460,
      "lat": 33.3628005981,
      "lon": -108.8639984131,
      "tz": "America\/Denver"
  },
  "NM56": {
      "icao": "NM56",
      "iata": "",
      "name": "Mystic Bluffs Airport",
      "city": "Ramah",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6980,
      "lat": 35.1652984619,
      "lon": -108.458999634,
      "tz": "America\/Denver"
  },
  "NM59": {
      "icao": "NM59",
      "iata": "",
      "name": "Price's Dairy Airport",
      "city": "Albuquerque",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5100,
      "lat": 35.324798584,
      "lon": -106.6669998169,
      "tz": "America\/Denver"
  },
  "NM61": {
      "icao": "NM61",
      "iata": "",
      "name": "New Mexico Soaring Ranch Airport",
      "city": "Albuquerque",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6530,
      "lat": 35.1166992188,
      "lon": -106.1470031738,
      "tz": "America\/Denver"
  },
  "NM63": {
      "icao": "NM63",
      "iata": "",
      "name": "Big Sky Airport",
      "city": "Stanley",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6200,
      "lat": 35.1657981873,
      "lon": -106.0120010376,
      "tz": "America\/Denver"
  },
  "NM64": {
      "icao": "NM64",
      "iata": "",
      "name": "Gray Ranch Airport",
      "city": "Animas",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5155,
      "lat": 31.4587001801,
      "lon": -108.8629989624,
      "tz": "America\/Denver"
  },
  "NM66": {
      "icao": "NM66",
      "iata": "",
      "name": "Poco Loco Airport",
      "city": "Pie Town",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7420,
      "lat": 34.4151992798,
      "lon": -108.077003479,
      "tz": "America\/Denver"
  },
  "NM67": {
      "icao": "NM67",
      "iata": "",
      "name": "La Mesa Park Airport",
      "city": "Raton",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6578,
      "lat": 36.8569984436,
      "lon": -104.4479980469,
      "tz": "America\/Denver"
  },
  "NM68": {
      "icao": "NM68",
      "iata": "",
      "name": "Rael Ranch Horse Pasture Airport",
      "city": "Horse Springs",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6950,
      "lat": 33.8050994873,
      "lon": -108.3430023193,
      "tz": "America\/Denver"
  },
  "NM69": {
      "icao": "NM69",
      "iata": "",
      "name": "Casas Adobes Airpark",
      "city": "Mimbres",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5800,
      "lat": 32.814201355,
      "lon": -107.9400024414,
      "tz": "America\/Denver"
  },
  "NM70": {
      "icao": "NM70",
      "iata": "",
      "name": "Rodeo Airport",
      "city": "Rodeo",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4157,
      "lat": 31.9500007629,
      "lon": -109.0419998169,
      "tz": "America\/Denver"
  },
  "NM71": {
      "icao": "NM71",
      "iata": "",
      "name": "Three Rivers Ranch Airport",
      "city": "Three Rivers",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4807,
      "lat": 33.332901001,
      "lon": -106.0260009766,
      "tz": "America\/Denver"
  },
  "NM72": {
      "icao": "NM72",
      "iata": "",
      "name": "Turner Ridgeport Airport",
      "city": "Silver City",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6037,
      "lat": 32.7419013977,
      "lon": -108.2880020142,
      "tz": "America\/Denver"
  },
  "NM73": {
      "icao": "NM73",
      "iata": "",
      "name": "Akin and Akin Airport",
      "city": "Mc Alister",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4858,
      "lat": 34.7723007202,
      "lon": -103.8560028076,
      "tz": "America\/Denver"
  },
  "NM74": {
      "icao": "NM74",
      "iata": "",
      "name": "Cubero Airport",
      "city": "Cubero",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6192,
      "lat": 35.0875015259,
      "lon": -107.56300354,
      "tz": "America\/Denver"
  },
  "NM75": {
      "icao": "NM75",
      "iata": "",
      "name": "Doolittle Ranch Airport",
      "city": "Watrous",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6550,
      "lat": 35.8135986328,
      "lon": -104.9700012207,
      "tz": "America\/Denver"
  },
  "NM76": {
      "icao": "NM76",
      "iata": "",
      "name": "Park Springs Airport",
      "city": "Anton Chico",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5115,
      "lat": 35.260799408,
      "lon": -104.9189987183,
      "tz": "America\/Denver"
  },
  "NM78": {
      "icao": "NM78",
      "iata": "",
      "name": "Hacienda Sur Luna Airport",
      "city": "Columbus",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4150,
      "lat": 31.8778991699,
      "lon": -107.6460037231,
      "tz": "America\/Denver"
  },
  "NM79": {
      "icao": "NM79",
      "iata": "",
      "name": "Shoestring Ranch Airport",
      "city": "Organ",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4440,
      "lat": 32.4430999756,
      "lon": -106.6800003052,
      "tz": "America\/Denver"
  },
  "NM80": {
      "icao": "NM80",
      "iata": "",
      "name": "Quemado Airport",
      "city": "Quemado",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6917,
      "lat": 34.3403015137,
      "lon": -108.5149993896,
      "tz": "America\/Denver"
  },
  "NM83": {
      "icao": "NM83",
      "iata": "",
      "name": "Industrial Airpark",
      "city": "Hobbs",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3707,
      "lat": 32.7667999268,
      "lon": -103.2089996338,
      "tz": "America\/Denver"
  },
  "NM84": {
      "icao": "NM84",
      "iata": "",
      "name": "G Bar F Ranch Airport",
      "city": "Capitan",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 6505,
      "lat": 33.5444984436,
      "lon": -105.3759994507,
      "tz": "America\/Denver"
  },
  "NM86": {
      "icao": "NM86",
      "iata": "",
      "name": "Playas Air Strip",
      "city": "Playas",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4519,
      "lat": 31.9368000031,
      "lon": -108.5370025635,
      "tz": "America\/Denver"
  },
  "NM87": {
      "icao": "NM87",
      "iata": "",
      "name": "Jenkins Airport",
      "city": "Roswell",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3600,
      "lat": 33.2383003235,
      "lon": -104.4710006714,
      "tz": "America\/Denver"
  },
  "NM88": {
      "icao": "NM88",
      "iata": "",
      "name": "Skywagon Farm Airport",
      "city": "Bosque",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4770,
      "lat": 34.54330063,
      "lon": -106.7600021,
      "tz": "America\/Denver"
  },
  "NM89": {
      "icao": "NM89",
      "iata": "",
      "name": "Manzano-Mountain Air Ranch Airport",
      "city": "Los Lunas",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 5630,
      "lat": 34.7756996155,
      "lon": -106.5210037231,
      "tz": "America\/Denver"
  },
  "NM90": {
      "icao": "NM90",
      "iata": "",
      "name": "Amigos Del Cielo Airport",
      "city": "Rodeo",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 4107,
      "lat": 31.9029998779,
      "lon": -109.0250015259,
      "tz": "America\/Denver"
  },
  "NM91": {
      "icao": "NM91",
      "iata": "",
      "name": "High Lonesome Airport",
      "city": "Fence Lake",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7474,
      "lat": 34.62229919,
      "lon": -108.7900009,
      "tz": "America\/Denver"
  },
  "NM92": {
      "icao": "NM92",
      "iata": "",
      "name": "Tierra De Dios Airport",
      "city": "Rowe",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 7050,
      "lat": 35.295767,
      "lon": -105.6115,
      "tz": "America\/Denver"
  },
  "NR02": {
      "icao": "NR02",
      "iata": "",
      "name": "Jordan Field",
      "city": "Mount Gilead",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 600,
      "lat": 35.237778,
      "lon": -79.995556,
      "tz": "America\/New_York"
  },
  "NR04": {
      "icao": "NR04",
      "iata": "",
      "name": "Southern Skies Airstrip",
      "city": "Taylorsville",
      "state": "North-Carolina",
      "country": "US",
      "elevation": 1180,
      "lat": 35.960278,
      "lon": -81.173056,
      "tz": "America\/New_York"
  },
  "NSAS": {
      "icao": "NSAS",
      "iata": "OFU",
      "name": "Ofu Village Airport",
      "city": "Ofu Village",
      "state": "Manu'a",
      "country": "AS",
      "elevation": 9,
      "lat": -14.1843996048,
      "lon": -169.6699981689,
      "tz": "Pacific\/Pago_Pago"
  },
  "NSAU": {
      "icao": "NSAU",
      "iata": "AAU",
      "name": "Asau Airport",
      "city": "Asau",
      "state": "",
      "country": "WS",
      "elevation": 0,
      "lat": -13.5058002472,
      "lon": -172.628005981,
      "tz": "Pacific\/Apia"
  },
  "NSFA": {
      "icao": "NSFA",
      "iata": "APW",
      "name": "Faleolo International Airport",
      "city": "Apia",
      "state": "A'ana",
      "country": "WS",
      "elevation": 58,
      "lat": -13.8299999237,
      "lon": -172.0079956055,
      "tz": "Pacific\/Apia"
  },
  "NSFI": {
      "icao": "NSFI",
      "iata": "FGI",
      "name": "Fagali'i Airport",
      "city": "Apia",
      "state": "Tuamasaga",
      "country": "WS",
      "elevation": 131,
      "lat": -13.8486995697,
      "lon": -171.740005493,
      "tz": "Pacific\/Apia"
  },
  "NSFQ": {
      "icao": "NSFQ",
      "iata": "FTI",
      "name": "Fitiuta Airport",
      "city": "Fitiuta Village",
      "state": "Manu'a",
      "country": "AS",
      "elevation": 110,
      "lat": -14.2172002792,
      "lon": -169.425003052,
      "tz": "Pacific\/Pago_Pago"
  },
  "NSMA": {
      "icao": "NSMA",
      "iata": "MXS",
      "name": "Maota Airport",
      "city": "Maota",
      "state": "",
      "country": "WS",
      "elevation": 0,
      "lat": -13.7423000336,
      "lon": -172.2579956055,
      "tz": "Pacific\/Apia"
  },
  "NSTU": {
      "icao": "NSTU",
      "iata": "PPG",
      "name": "Pago Pago International Airport",
      "city": "Pago Pago",
      "state": "Eastern-District",
      "country": "AS",
      "elevation": 32,
      "lat": -14.3310003281,
      "lon": -170.710006714,
      "tz": "Pacific\/Pago_Pago"
  },
  "NTAA": {
      "icao": "NTAA",
      "iata": "PPT",
      "name": "Faa'a International Airport",
      "city": "Papeete",
      "state": "Iles-du-Vent",
      "country": "PF",
      "elevation": 5,
      "lat": -17.5536994934,
      "lon": -149.606994629,
      "tz": "Pacific\/Tahiti"
  },
  "NTAR": {
      "icao": "NTAR",
      "iata": "RUR",
      "name": "Rurutu Airport",
      "city": "",
      "state": "Iles-Australes",
      "country": "PF",
      "elevation": 18,
      "lat": -22.4340991974,
      "lon": -151.3609924316,
      "tz": "Pacific\/Tahiti"
  },
  "NTAT": {
      "icao": "NTAT",
      "iata": "TUB",
      "name": "Tubuai Airport",
      "city": "",
      "state": "Iles-Australes",
      "country": "PF",
      "elevation": 7,
      "lat": -23.3654003143,
      "lon": -149.5240020752,
      "tz": "Pacific\/Tahiti"
  },
  "NTAV": {
      "icao": "NTAV",
      "iata": "RVV",
      "name": "Raivavae Airport",
      "city": "",
      "state": "Iles-Australes",
      "country": "PF",
      "elevation": 7,
      "lat": -23.8852005005,
      "lon": -147.662002563,
      "tz": "Pacific\/Tahiti"
  },
  "NTGA": {
      "icao": "NTGA",
      "iata": "AAA",
      "name": "Anaa Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 10,
      "lat": -17.3526000977,
      "lon": -145.5099945068,
      "tz": "Pacific\/Tahiti"
  },
  "NTGB": {
      "icao": "NTGB",
      "iata": "FGU",
      "name": "Fangatau Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 9,
      "lat": -15.819899559,
      "lon": -140.8869934082,
      "tz": "Pacific\/Tahiti"
  },
  "NTGC": {
      "icao": "NTGC",
      "iata": "TIH",
      "name": "Tikehau Airport",
      "city": "",
      "state": "",
      "country": "PF",
      "elevation": 6,
      "lat": -15.119600296,
      "lon": -148.2310028076,
      "tz": "Pacific\/Tahiti"
  },
  "NTGD": {
      "icao": "NTGD",
      "iata": "APK",
      "name": "Apataki Airport",
      "city": "Apataki",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 8,
      "lat": -15.5735998154,
      "lon": -146.4149932861,
      "tz": "Pacific\/Tahiti"
  },
  "NTGE": {
      "icao": "NTGE",
      "iata": "REA",
      "name": "Reao Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 12,
      "lat": -18.4659004211,
      "lon": -136.4400024414,
      "tz": "Pacific\/Tahiti"
  },
  "NTGF": {
      "icao": "NTGF",
      "iata": "FAV",
      "name": "Fakarava Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 13,
      "lat": -16.0541000366,
      "lon": -145.6569976807,
      "tz": "Pacific\/Tahiti"
  },
  "NTGH": {
      "icao": "NTGH",
      "iata": "HHZ",
      "name": "Hikueru Atoll Airport",
      "city": "Hikueru Atoll",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 5,
      "lat": -17.5447006226,
      "lon": -142.6139984131,
      "tz": "Pacific\/Tahiti"
  },
  "NTGI": {
      "icao": "NTGI",
      "iata": "XMH",
      "name": "Manihi Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 14,
      "lat": -14.4368000031,
      "lon": -146.0700073242,
      "tz": "Pacific\/Tahiti"
  },
  "NTGJ": {
      "icao": "NTGJ",
      "iata": "GMR",
      "name": "Totegegie Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 7,
      "lat": -23.0799007416,
      "lon": -134.8899993896,
      "tz": "Pacific\/Gambier"
  },
  "NTGK": {
      "icao": "NTGK",
      "iata": "KKR",
      "name": "Kaukura Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 11,
      "lat": -15.6632995605,
      "lon": -146.8849945068,
      "tz": "Pacific\/Tahiti"
  },
  "NTGM": {
      "icao": "NTGM",
      "iata": "MKP",
      "name": "Makemo Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 3,
      "lat": -16.5839004517,
      "lon": -143.6580047607,
      "tz": "Pacific\/Tahiti"
  },
  "NTGN": {
      "icao": "NTGN",
      "iata": "NAU",
      "name": "Napuka Island Airport",
      "city": "Napuka Island",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 7,
      "lat": -14.1767997742,
      "lon": -141.266998291,
      "tz": "Pacific\/Tahiti"
  },
  "NTGO": {
      "icao": "NTGO",
      "iata": "TKV",
      "name": "Tatakoto Airport",
      "city": "Tatakoto",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 12,
      "lat": -17.3553009033,
      "lon": -138.4450073242,
      "tz": "Pacific\/Tahiti"
  },
  "NTGP": {
      "icao": "NTGP",
      "iata": "PKP",
      "name": "Puka Puka Airport",
      "city": "",
      "state": "",
      "country": "PF",
      "elevation": 5,
      "lat": -14.8094997406,
      "lon": -138.81300354,
      "tz": "Pacific\/Tahiti"
  },
  "NTGQ": {
      "icao": "NTGQ",
      "iata": "PUK",
      "name": "Pukarua Airport",
      "city": "Pukarua",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 5,
      "lat": -18.2956008911,
      "lon": -137.016998291,
      "tz": "Pacific\/Tahiti"
  },
  "NTGR": {
      "icao": "NTGR",
      "iata": "",
      "name": "Aratika Airport",
      "city": "Aratika",
      "state": "",
      "country": "PF",
      "elevation": 7,
      "lat": -15.6332998276,
      "lon": -145.5,
      "tz": "Pacific\/Tahiti"
  },
  "NTGS": {
      "icao": "NTGS",
      "iata": "",
      "name": "Marutea Airport",
      "city": "Auorotini",
      "state": "",
      "country": "PF",
      "elevation": 10,
      "lat": -21.4821949005,
      "lon": -135.6370544434,
      "tz": "Pacific\/Gambier"
  },
  "NTGT": {
      "icao": "NTGT",
      "iata": "TKP",
      "name": "Takapoto Airport",
      "city": "",
      "state": "",
      "country": "PF",
      "elevation": 12,
      "lat": -14.7095003128,
      "lon": -145.2460021973,
      "tz": "Pacific\/Tahiti"
  },
  "NTGU": {
      "icao": "NTGU",
      "iata": "AXR",
      "name": "Arutua Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 9,
      "lat": -15.2482995987,
      "lon": -146.6170043945,
      "tz": "Pacific\/Tahiti"
  },
  "NTGV": {
      "icao": "NTGV",
      "iata": "MVT",
      "name": "Mataiva Airport",
      "city": "",
      "state": "",
      "country": "PF",
      "elevation": 11,
      "lat": -14.8681001663,
      "lon": -148.7169952393,
      "tz": "Pacific\/Tahiti"
  },
  "NTGW": {
      "icao": "NTGW",
      "iata": "NUK",
      "name": "Nukutavake Airport",
      "city": "Nukutavake",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 17,
      "lat": -19.2849998474,
      "lon": -138.7720031738,
      "tz": "Pacific\/Tahiti"
  },
  "NTGY": {
      "icao": "NTGY",
      "iata": "ZTA",
      "name": "Tureia Airport",
      "city": "",
      "state": "",
      "country": "PF",
      "elevation": 12,
      "lat": -20.7896995544,
      "lon": -138.5700073242,
      "tz": "Pacific\/Tahiti"
  },
  "NTHE": {
      "icao": "NTHE",
      "iata": "AHE",
      "name": "Ahe Airport",
      "city": "Ahe Atoll",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 11,
      "lat": -14.4280996323,
      "lon": -146.2570037842,
      "tz": "Pacific\/Tahiti"
  },
  "NTKA": {
      "icao": "NTKA",
      "iata": "KHZ",
      "name": "Kauehi Airport",
      "city": "Kauehi",
      "state": "",
      "country": "PF",
      "elevation": 13,
      "lat": -15.7807998657,
      "lon": -145.1239929199,
      "tz": "Pacific\/Tahiti"
  },
  "NTKF": {
      "icao": "NTKF",
      "iata": "FAC",
      "name": "Faaite Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 7,
      "lat": -16.6867008209,
      "lon": -145.328994751,
      "tz": "Pacific\/Tahiti"
  },
  "NTKH": {
      "icao": "NTKH",
      "iata": "FHZ",
      "name": "Fakahina Airport",
      "city": "Fakahina",
      "state": "",
      "country": "PF",
      "elevation": 3,
      "lat": -15.9921998978,
      "lon": -140.1649932861,
      "tz": "Pacific\/Tahiti"
  },
  "NTKK": {
      "icao": "NTKK",
      "iata": "RKA",
      "name": "Aratika Nord Airport",
      "city": "",
      "state": "",
      "country": "PF",
      "elevation": 10,
      "lat": -15.4853000641,
      "lon": -145.470001221,
      "tz": "Pacific\/Tahiti"
  },
  "NTKM": {
      "icao": "NTKM",
      "iata": "TJN",
      "name": "Takume Airport",
      "city": "Takume",
      "state": "",
      "country": "PF",
      "elevation": 7,
      "lat": -15.8547000885,
      "lon": -142.2680053711,
      "tz": "Pacific\/Tahiti"
  },
  "NTKO": {
      "icao": "NTKO",
      "iata": "RRR",
      "name": "Raroia Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 18,
      "lat": -16.045,
      "lon": -142.476944,
      "tz": "Pacific\/Tahiti"
  },
  "NTKR": {
      "icao": "NTKR",
      "iata": "TKX",
      "name": "Takaroa Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 13,
      "lat": -14.4558000565,
      "lon": -145.0249938965,
      "tz": "Pacific\/Tahiti"
  },
  "NTKT": {
      "icao": "NTKT",
      "iata": "KXU",
      "name": "Katiu Airport",
      "city": "Katiu",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 7,
      "lat": -16.3393993378,
      "lon": -144.4029998779,
      "tz": "Pacific\/Tahiti"
  },
  "NTMD": {
      "icao": "NTMD",
      "iata": "NHV",
      "name": "Nuku Hiva Airport",
      "city": "",
      "state": "Iles-Marquises",
      "country": "PF",
      "elevation": 220,
      "lat": -8.7955999374,
      "lon": -140.2290039063,
      "tz": "Pacific\/Marquesas"
  },
  "NTMN": {
      "icao": "NTMN",
      "iata": "AUQ",
      "name": "Hiva Oa-Atuona Airport",
      "city": "",
      "state": "Iles-Marquises",
      "country": "PF",
      "elevation": 1481,
      "lat": -9.7687902451,
      "lon": -139.0110015869,
      "tz": "Pacific\/Marquesas"
  },
  "NTMP": {
      "icao": "NTMP",
      "iata": "UAP",
      "name": "Ua Pou Airport",
      "city": "Ua Pou",
      "state": "Iles-Marquises",
      "country": "PF",
      "elevation": 16,
      "lat": -9.3516702652,
      "lon": -140.0780029297,
      "tz": "Pacific\/Marquesas"
  },
  "NTMU": {
      "icao": "NTMU",
      "iata": "UAH",
      "name": "Ua Huka Airport",
      "city": "Ua Huka",
      "state": "Iles-Marquises",
      "country": "PF",
      "elevation": 160,
      "lat": -8.9361095428,
      "lon": -139.5520019531,
      "tz": "Pacific\/Marquesas"
  },
  "NTTB": {
      "icao": "NTTB",
      "iata": "BOB",
      "name": "Bora Bora Airport",
      "city": "Motu Mute",
      "state": "Leeward-Islands",
      "country": "PF",
      "elevation": 10,
      "lat": -16.4444007874,
      "lon": -151.7510070801,
      "tz": "Pacific\/Tahiti"
  },
  "NTTE": {
      "icao": "NTTE",
      "iata": "TTI",
      "name": "Tetiaroa Airport",
      "city": "Tetiaroa",
      "state": "",
      "country": "PF",
      "elevation": 7,
      "lat": -17.013299942,
      "lon": -149.5870056152,
      "tz": "Pacific\/Tahiti"
  },
  "NTTG": {
      "icao": "NTTG",
      "iata": "RGI",
      "name": "Rangiroa Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 10,
      "lat": -14.9542999268,
      "lon": -147.6609954834,
      "tz": "Pacific\/Tahiti"
  },
  "NTTH": {
      "icao": "NTTH",
      "iata": "HUH",
      "name": "Huahine-Fare Airport",
      "city": "Fare",
      "state": "Leeward-Islands",
      "country": "PF",
      "elevation": 7,
      "lat": -16.6872005463,
      "lon": -151.0220031738,
      "tz": "Pacific\/Tahiti"
  },
  "NTTM": {
      "icao": "NTTM",
      "iata": "MOZ",
      "name": "Moorea Airport",
      "city": "",
      "state": "Iles-du-Vent",
      "country": "PF",
      "elevation": 9,
      "lat": -17.4899997711,
      "lon": -149.7619934082,
      "tz": "Pacific\/Tahiti"
  },
  "NTTO": {
      "icao": "NTTO",
      "iata": "HOI",
      "name": "Hao Airport",
      "city": "",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 10,
      "lat": -18.0748004913,
      "lon": -140.9459991455,
      "tz": "Pacific\/Tahiti"
  },
  "NTTP": {
      "icao": "NTTP",
      "iata": "MAU",
      "name": "Maupiti Airport",
      "city": "",
      "state": "Leeward-Islands",
      "country": "PF",
      "elevation": 15,
      "lat": -16.4265003204,
      "lon": -152.2440032959,
      "tz": "Pacific\/Tahiti"
  },
  "NTTR": {
      "icao": "NTTR",
      "iata": "RFP",
      "name": "Raiatea Airport",
      "city": "Uturoa",
      "state": "Leeward-Islands",
      "country": "PF",
      "elevation": 3,
      "lat": -16.7229003906,
      "lon": -151.466003418,
      "tz": "Pacific\/Tahiti"
  },
  "NTTX": {
      "icao": "NTTX",
      "iata": "UOA",
      "name": "Mururoa Atoll Airport",
      "city": "Mururoa Atoll",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 7,
      "lat": -21.8670005798,
      "lon": -138.9170074463,
      "tz": "Pacific\/Tahiti"
  },
  "NTUV": {
      "icao": "NTUV",
      "iata": "VHZ",
      "name": "Vahitahi Airport",
      "city": "Vahitahi",
      "state": "Iles-Tuamotu-Gambier",
      "country": "PF",
      "elevation": 9,
      "lat": -18.7800006866,
      "lon": -138.8529968262,
      "tz": "Pacific\/Tahiti"
  },
  "NV00": {
      "icao": "NV00",
      "iata": "",
      "name": "Valley View Airport",
      "city": "Pahrump",
      "state": "Nevada",
      "country": "US",
      "elevation": 2740,
      "lat": 36.2555007935,
      "lon": -115.9929962158,
      "tz": "America\/Los_Angeles"
  },
  "NV02": {
      "icao": "NV02",
      "iata": "",
      "name": "O'Toole Ranch Airport",
      "city": "Austin",
      "state": "Nevada",
      "country": "US",
      "elevation": 6520,
      "lat": 39.0666007996,
      "lon": -117.4219970703,
      "tz": "America\/Los_Angeles"
  },
  "NV04": {
      "icao": "NV04",
      "iata": "",
      "name": "Marys River Ranch Airport",
      "city": "Deeth",
      "state": "Nevada",
      "country": "US",
      "elevation": 5600,
      "lat": 41.3162994385,
      "lon": -115.25,
      "tz": "America\/Los_Angeles"
  },
  "NV05": {
      "icao": "NV05",
      "iata": "",
      "name": "Soldier Meadow Nr 2 Airport",
      "city": "Gerlach",
      "state": "Nevada",
      "country": "US",
      "elevation": 4150,
      "lat": 41.123500824,
      "lon": -119.138999939,
      "tz": "America\/Los_Angeles"
  },
  "NV06": {
      "icao": "NV06",
      "iata": "",
      "name": "Soldier Meadow Nr 1 Airport",
      "city": "Gerlach",
      "state": "Nevada",
      "country": "US",
      "elevation": 4494,
      "lat": 41.3827018738,
      "lon": -119.1689987183,
      "tz": "America\/Los_Angeles"
  },
  "NV07": {
      "icao": "NV07",
      "iata": "",
      "name": "Sunnyside\/Kirch Wildlife Mgmt Area Airport",
      "city": "Lund",
      "state": "Nevada",
      "country": "US",
      "elevation": 5288,
      "lat": 38.4146995544,
      "lon": -115.0370025635,
      "tz": "America\/Los_Angeles"
  },
  "NV08": {
      "icao": "NV08",
      "iata": "",
      "name": "Petan Ranch Airport",
      "city": "Mountain City",
      "state": "Nevada",
      "country": "US",
      "elevation": 5616,
      "lat": 41.7379989624,
      "lon": -116.216003418,
      "tz": "America\/Los_Angeles"
  },
  "NV09": {
      "icao": "NV09",
      "iata": "",
      "name": "H Bar H Airport",
      "city": "Reno",
      "state": "Nevada",
      "country": "US",
      "elevation": 5220,
      "lat": 39.7243995667,
      "lon": -119.8990020752,
      "tz": "America\/Los_Angeles"
  },
  "NV11": {
      "icao": "NV11",
      "iata": "",
      "name": "Yucca Airstrip",
      "city": "Mercury",
      "state": "Nevada",
      "country": "US",
      "elevation": 3919,
      "lat": 36.9458007813,
      "lon": -116.0380020142,
      "tz": "America\/Los_Angeles"
  },
  "NV12": {
      "icao": "NV12",
      "iata": "",
      "name": "I-L Ranch Airport",
      "city": "Tuscarora",
      "state": "Nevada",
      "country": "US",
      "elevation": 5368,
      "lat": 41.5638008118,
      "lon": -116.408996582,
      "tz": "America\/Los_Angeles"
  },
  "NV13": {
      "icao": "NV13",
      "iata": "",
      "name": "Bailey Ranch Airport",
      "city": "Carson City",
      "state": "Nevada",
      "country": "US",
      "elevation": 5060,
      "lat": 39.2827987671,
      "lon": -119.8280029297,
      "tz": "America\/Los_Angeles"
  },
  "NV14": {
      "icao": "NV14",
      "iata": "",
      "name": "Juniper Airport",
      "city": "Montello",
      "state": "Nevada",
      "country": "US",
      "elevation": 5400,
      "lat": 41.3554992676,
      "lon": -114.2300033569,
      "tz": "America\/Los_Angeles"
  },
  "NV16": {
      "icao": "NV16",
      "iata": "",
      "name": "Lackerman Ranch Airport",
      "city": "Gerlach",
      "state": "Nevada",
      "country": "US",
      "elevation": 4706,
      "lat": 40.9992980957,
      "lon": -119.93699646,
      "tz": "America\/Los_Angeles"
  },
  "NV17": {
      "icao": "NV17",
      "iata": "",
      "name": "Youngberg Ranch Airport",
      "city": "Lemmon Valley",
      "state": "Nevada",
      "country": "US",
      "elevation": 4960,
      "lat": 39.6731987,
      "lon": -119.81300354,
      "tz": "America\/Los_Angeles"
  },
  "NV22": {
      "icao": "NV22",
      "iata": "",
      "name": "Red Rock Ranch Airport",
      "city": "Elko",
      "state": "Nevada",
      "country": "US",
      "elevation": 6158,
      "lat": 40.3126983643,
      "lon": -115.8970031738,
      "tz": "America\/Los_Angeles"
  },
  "NV23": {
      "icao": "NV23",
      "iata": "",
      "name": "Air Sailing Gliderport",
      "city": "Sparks",
      "state": "Nevada",
      "country": "US",
      "elevation": 4300,
      "lat": 39.8721008301,
      "lon": -119.7009963989,
      "tz": "America\/Los_Angeles"
  },
  "NV24": {
      "icao": "NV24",
      "iata": "",
      "name": "Fort Ruby Ranch Airstrip",
      "city": "Ruby Valley",
      "state": "Nevada",
      "country": "US",
      "elevation": 6006,
      "lat": 40.0579986572,
      "lon": -115.5059967041,
      "tz": "America\/Los_Angeles"
  },
  "NV26": {
      "icao": "NV26",
      "iata": "",
      "name": "Voc Tech Airport",
      "city": "Henderson",
      "state": "Nevada",
      "country": "US",
      "elevation": 1913,
      "lat": 36.0791015625,
      "lon": -115.0690002441,
      "tz": "America\/Los_Angeles"
  },
  "NV27": {
      "icao": "NV27",
      "iata": "",
      "name": "Circle L Ranch Airport",
      "city": "Dyer",
      "state": "Nevada",
      "country": "US",
      "elevation": 4835,
      "lat": 37.7165985107,
      "lon": -118.0839996338,
      "tz": "America\/Los_Angeles"
  },
  "NV30": {
      "icao": "NV30",
      "iata": "",
      "name": "Dixie Valley Airport",
      "city": "Fallon",
      "state": "Nevada",
      "country": "US",
      "elevation": 3443,
      "lat": 39.9665985107,
      "lon": -117.8280029297,
      "tz": "America\/Los_Angeles"
  },
  "NV31": {
      "icao": "NV31",
      "iata": "",
      "name": "Barker Creek Ranch Airstrip",
      "city": "Round Mountain",
      "state": "Nevada",
      "country": "US",
      "elevation": 6620,
      "lat": 38.8058013916,
      "lon": -117.03099823,
      "tz": "America\/Los_Angeles"
  },
  "NV33": {
      "icao": "NV33",
      "iata": "",
      "name": "Farias Wheel Airport",
      "city": "Smith Valley",
      "state": "Nevada",
      "country": "US",
      "elevation": 4848,
      "lat": 38.8331985474,
      "lon": -119.3960037231,
      "tz": "America\/Los_Angeles"
  },
  "NV35": {
      "icao": "NV35",
      "iata": "",
      "name": "Hudson Airport",
      "city": "Austin",
      "state": "Nevada",
      "country": "US",
      "elevation": 5180,
      "lat": 39.5443992615,
      "lon": -117.7509994507,
      "tz": "America\/Los_Angeles"
  },
  "NV36": {
      "icao": "NV36",
      "iata": "",
      "name": "Imvite Airport",
      "city": "Amargosa Valley",
      "state": "Nevada",
      "country": "US",
      "elevation": 2250,
      "lat": 36.4477005005,
      "lon": -116.4540023804,
      "tz": "America\/Los_Angeles"
  },
  "NV41": {
      "icao": "NV41",
      "iata": "",
      "name": "Sulfur Airport",
      "city": "Gerlach",
      "state": "Nevada",
      "country": "US",
      "elevation": 4068,
      "lat": 40.8638000488,
      "lon": -118.7350006104,
      "tz": "America\/Los_Angeles"
  },
  "NV42": {
      "icao": "NV42",
      "iata": "",
      "name": "Heritage Airport",
      "city": "Jean",
      "state": "California",
      "country": "US",
      "elevation": 2575,
      "lat": 35.7757987976,
      "lon": -115.6309967041,
      "tz": "America\/Los_Angeles"
  },
  "NV44": {
      "icao": "NV44",
      "iata": "",
      "name": "Justover Field",
      "city": "Sparks",
      "state": "Nevada",
      "country": "US",
      "elevation": 4480,
      "lat": 39.7551994324,
      "lon": -119.5989990234,
      "tz": "America\/Los_Angeles"
  },
  "NV47": {
      "icao": "NV47",
      "iata": "",
      "name": "Palomino Airport",
      "city": "Sparks",
      "state": "Nevada",
      "country": "US",
      "elevation": 4240,
      "lat": 39.8331985474,
      "lon": -119.6819992065,
      "tz": "America\/Los_Angeles"
  },
  "NV54": {
      "icao": "NV54",
      "iata": "",
      "name": "Flying S Ranch Ultralightport",
      "city": "Pahrump",
      "state": "Nevada",
      "country": "US",
      "elevation": 2800,
      "lat": 36.3069992065,
      "lon": -115.9889984131,
      "tz": "America\/Los_Angeles"
  },
  "NV55": {
      "icao": "NV55",
      "iata": "",
      "name": "Pinenut Airport",
      "city": "Gardnerville",
      "state": "Nevada",
      "country": "US",
      "elevation": 5260,
      "lat": 38.8988990784,
      "lon": -119.6490020752,
      "tz": "America\/Los_Angeles"
  },
  "NV56": {
      "icao": "NV56",
      "iata": "",
      "name": "Wine Glass Ranch Airport",
      "city": "Round Mountain",
      "state": "Nevada",
      "country": "US",
      "elevation": 5568,
      "lat": 38.7994003296,
      "lon": -117.1750030518,
      "tz": "America\/Los_Angeles"
  },
  "NV64": {
      "icao": "NV64",
      "iata": "",
      "name": "Swanson Ranch 3 Airport",
      "city": "Battle Mountain",
      "state": "Nevada",
      "country": "US",
      "elevation": 4940,
      "lat": 40.0083007813,
      "lon": -117.391998291,
      "tz": "America\/Los_Angeles"
  },
  "NV67": {
      "icao": "NV67",
      "iata": "",
      "name": "Pilot Creek Ranches Airport",
      "city": "Montello",
      "state": "Nevada",
      "country": "US",
      "elevation": 4980,
      "lat": 41.1157989502,
      "lon": -114.1139984131,
      "tz": "America\/Los_Angeles"
  },
  "NV68": {
      "icao": "NV68",
      "iata": "",
      "name": "Pine Grove Airport",
      "city": "Orovada",
      "state": "Nevada",
      "country": "US",
      "elevation": 4250,
      "lat": 41.565700531,
      "lon": -117.8089981079,
      "tz": "America\/Los_Angeles"
  },
  "NV72": {
      "icao": "NV72",
      "iata": "",
      "name": "Sweetwater (USMC) Airport",
      "city": "Wellington",
      "state": "Nevada",
      "country": "US",
      "elevation": 6837,
      "lat": 38.5097999573,
      "lon": -119.217002869,
      "tz": "America\/Los_Angeles"
  },
  "NV74": {
      "icao": "NV74",
      "iata": "",
      "name": "Calvada Meadows Airport",
      "city": "Pahrump",
      "state": "Nevada",
      "country": "US",
      "elevation": 2726,
      "lat": 36.2710990906,
      "lon": -115.9950027466,
      "tz": "America\/Los_Angeles"
  },
  "NV77": {
      "icao": "NV77",
      "iata": "",
      "name": "Empire Farms Airport",
      "city": "Empire",
      "state": "Nevada",
      "country": "US",
      "elevation": 4085,
      "lat": 40.4356994629,
      "lon": -119.4619979858,
      "tz": "America\/Los_Angeles"
  },
  "NV83": {
      "icao": "NV83",
      "iata": "",
      "name": "Hadley Airport",
      "city": "Round Mountain",
      "state": "Nevada",
      "country": "US",
      "elevation": 5744,
      "lat": 38.694098,
      "lon": -117.146752,
      "tz": "America\/Los_Angeles"
  },
  "NV96": {
      "icao": "NV96",
      "iata": "",
      "name": "Rolling Thunder Airport",
      "city": "Sparks",
      "state": "Nevada",
      "country": "US",
      "elevation": 4240,
      "lat": 39.8252983093,
      "lon": -119.6539993286,
      "tz": "America\/Los_Angeles"
  },
  "NV97": {
      "icao": "NV97",
      "iata": "",
      "name": "Desert Creek Airport",
      "city": "Wellington",
      "state": "Nevada",
      "country": "US",
      "elevation": 5358,
      "lat": 38.6842002869,
      "lon": -119.3219985962,
      "tz": "America\/Los_Angeles"
  },
  "NV98": {
      "icao": "NV98",
      "iata": "",
      "name": "Caas Airport",
      "city": "Pahrump",
      "state": "Nevada",
      "country": "US",
      "elevation": 2800,
      "lat": 36.1502990723,
      "lon": -115.9000015259,
      "tz": "America\/Los_Angeles"
  },
  "NVSA": {
      "icao": "NVSA",
      "iata": "MTV",
      "name": "Mota Lava Airport",
      "city": "Ablow",
      "state": "",
      "country": "VU",
      "elevation": 63,
      "lat": -13.6660003662,
      "lon": 167.7120056152,
      "tz": "Pacific\/Efate"
  },
  "NVSC": {
      "icao": "NVSC",
      "iata": "SLH",
      "name": "Sola Airport",
      "city": "Sola",
      "state": "Torba",
      "country": "VU",
      "elevation": 7,
      "lat": -13.8516998291,
      "lon": 167.5370025635,
      "tz": "Pacific\/Efate"
  },
  "NVSD": {
      "icao": "NVSD",
      "iata": "TOH",
      "name": "Torres Airstrip",
      "city": "Loh\/Linua",
      "state": "Torba",
      "country": "VU",
      "elevation": 0,
      "lat": -13.3280000687,
      "lon": 166.6380004883,
      "tz": "Pacific\/Efate"
  },
  "NVSE": {
      "icao": "NVSE",
      "iata": "EAE",
      "name": "Sangafa Airport",
      "city": "Sangafa",
      "state": "",
      "country": "VU",
      "elevation": 7,
      "lat": -17.0902996063,
      "lon": 168.3430023193,
      "tz": "Pacific\/Efate"
  },
  "NVSF": {
      "icao": "NVSF",
      "iata": "CCV",
      "name": "Craig Cove Airport",
      "city": "Craig Cove",
      "state": "Malampa",
      "country": "VU",
      "elevation": 69,
      "lat": -16.2649993896,
      "lon": 167.9239959717,
      "tz": "Pacific\/Efate"
  },
  "NVSG": {
      "icao": "NVSG",
      "iata": "LOD",
      "name": "Longana Airport",
      "city": "Longana",
      "state": "Penama",
      "country": "VU",
      "elevation": 167,
      "lat": -15.3066997528,
      "lon": 167.9669952393,
      "tz": "Pacific\/Efate"
  },
  "NVSH": {
      "icao": "NVSH",
      "iata": "SSR",
      "name": "Sara Airport",
      "city": "Pentecost Island",
      "state": "Penama",
      "country": "VU",
      "elevation": 0,
      "lat": -15.4708003998,
      "lon": 168.1519927979,
      "tz": "Pacific\/Efate"
  },
  "NVSI": {
      "icao": "NVSI",
      "iata": "PBJ",
      "name": "Tavie Airport",
      "city": "Paama Island",
      "state": "Malampa",
      "country": "VU",
      "elevation": 0,
      "lat": -16.438999176,
      "lon": 168.2570037842,
      "tz": "Pacific\/Efate"
  },
  "NVSL": {
      "icao": "NVSL",
      "iata": "LPM",
      "name": "Lamap Airport",
      "city": "Lamap",
      "state": "Malampa",
      "country": "VU",
      "elevation": 7,
      "lat": -16.454000473,
      "lon": 167.8229980469,
      "tz": "Pacific\/Efate"
  },
  "NVSM": {
      "icao": "NVSM",
      "iata": "LNB",
      "name": "Lamen Bay Airport",
      "city": "Lamen Bay",
      "state": "Shefa",
      "country": "VU",
      "elevation": 7,
      "lat": -16.5841999054,
      "lon": 168.158996582,
      "tz": "Pacific\/Efate"
  },
  "NVSN": {
      "icao": "NVSN",
      "iata": "MWF",
      "name": "Naone Airport",
      "city": "Maewo Island",
      "state": "Penama",
      "country": "VU",
      "elevation": 0,
      "lat": -15,
      "lon": 168.0829925537,
      "tz": "Pacific\/Efate"
  },
  "NVSO": {
      "icao": "NVSO",
      "iata": "LNE",
      "name": "Lonorore Airport",
      "city": "Lonorore",
      "state": "Penama",
      "country": "VU",
      "elevation": 43,
      "lat": -15.8655996323,
      "lon": 168.1719970703,
      "tz": "Pacific\/Efate"
  },
  "NVSP": {
      "icao": "NVSP",
      "iata": "NUS",
      "name": "Norsup Airport",
      "city": "Norsup",
      "state": "Malampa",
      "country": "VU",
      "elevation": 23,
      "lat": -16.07970047,
      "lon": 167.4010009766,
      "tz": "Pacific\/Efate"
  },
  "NVSQ": {
      "icao": "NVSQ",
      "iata": "ZGU",
      "name": "Gaua Island Airport",
      "city": "Gaua Island",
      "state": "Torba",
      "country": "VU",
      "elevation": 0,
      "lat": -14.2180995941,
      "lon": 167.587005615,
      "tz": "Pacific\/Efate"
  },
  "NVSR": {
      "icao": "NVSR",
      "iata": "RCL",
      "name": "Redcliffe Airport",
      "city": "Redcliffe",
      "state": "Penama",
      "country": "VU",
      "elevation": 36,
      "lat": -15.4720001221,
      "lon": 167.8350067139,
      "tz": "Pacific\/Efate"
  },
  "NVSS": {
      "icao": "NVSS",
      "iata": "SON",
      "name": "Santo Pekoa International Airport",
      "city": "Luganville",
      "state": "Sanma",
      "country": "VU",
      "elevation": 184,
      "lat": -15.5050001144,
      "lon": 167.220001221,
      "tz": "Pacific\/Efate"
  },
  "NVST": {
      "icao": "NVST",
      "iata": "TGH",
      "name": "Tongoa Island Airport",
      "city": "Tongoa Island",
      "state": "Shefa",
      "country": "VU",
      "elevation": 443,
      "lat": -16.8910999298,
      "lon": 168.550994873,
      "tz": "Pacific\/Efate"
  },
  "NVSU": {
      "icao": "NVSU",
      "iata": "ULB",
      "name": "Ulei Airport",
      "city": "Ambryn Island",
      "state": "Malampa",
      "country": "VU",
      "elevation": 0,
      "lat": -16.3297,
      "lon": 168.3011,
      "tz": "Pacific\/Efate"
  },
  "NVSV": {
      "icao": "NVSV",
      "iata": "VLS",
      "name": "Valesdir Airport",
      "city": "Valesdir",
      "state": "Shefa",
      "country": "VU",
      "elevation": 10,
      "lat": -16.7961006165,
      "lon": 168.1770019531,
      "tz": "Pacific\/Efate"
  },
  "NVSW": {
      "icao": "NVSW",
      "iata": "WLH",
      "name": "Walaha Airport",
      "city": "Walaha",
      "state": "Penama",
      "country": "VU",
      "elevation": 151,
      "lat": -15.4119997025,
      "lon": 167.6909942627,
      "tz": "Pacific\/Efate"
  },
  "NVSX": {
      "icao": "NVSX",
      "iata": "SWJ",
      "name": "Southwest Bay Airport",
      "city": "Malekula Island",
      "state": "Malampa",
      "country": "VU",
      "elevation": 0,
      "lat": -16.4864,
      "lon": 167.4472,
      "tz": "Pacific\/Efate"
  },
  "NVSZ": {
      "icao": "NVSZ",
      "iata": "OLJ",
      "name": "North West Santo Airport",
      "city": "Olpoi",
      "state": "Sanma",
      "country": "VU",
      "elevation": 0,
      "lat": -14.8816995621,
      "lon": 166.557998657,
      "tz": "Pacific\/Efate"
  },
  "NVVA": {
      "icao": "NVVA",
      "iata": "AUY",
      "name": "Anelghowhat Airport",
      "city": "Anelghowhat",
      "state": "Tafea",
      "country": "VU",
      "elevation": 7,
      "lat": -20.2492008209,
      "lon": 169.7709960938,
      "tz": "Pacific\/Efate"
  },
  "NVVB": {
      "icao": "NVVB",
      "iata": "AWD",
      "name": "Aniwa Airport",
      "city": "Aniwa",
      "state": "Tafea",
      "country": "VU",
      "elevation": 69,
      "lat": -19.2399997711,
      "lon": 169.6049957275,
      "tz": "Pacific\/Efate"
  },
  "NVVD": {
      "icao": "NVVD",
      "iata": "DLY",
      "name": "Dillon's Bay Airport",
      "city": "Dillon's Bay",
      "state": "Tafea",
      "country": "VU",
      "elevation": 538,
      "lat": -18.7693996429,
      "lon": 169.0010070801,
      "tz": "Pacific\/Efate"
  },
  "NVVF": {
      "icao": "NVVF",
      "iata": "FTA",
      "name": "Futuna Airport",
      "city": "Futuna Island",
      "state": "Tafea",
      "country": "VU",
      "elevation": 95,
      "lat": -19.5163993835,
      "lon": 170.231994629,
      "tz": "Pacific\/Efate"
  },
  "NVVI": {
      "icao": "NVVI",
      "iata": "IPA",
      "name": "Ipota Airport",
      "city": "Ipota",
      "state": "Tafea",
      "country": "VU",
      "elevation": 23,
      "lat": -18.856389,
      "lon": 169.283333,
      "tz": "Pacific\/Efate"
  },
  "NVVJ": {
      "icao": "NVVJ",
      "iata": "",
      "name": "Forari Airport",
      "city": "Forari",
      "state": "Shefa",
      "country": "VU",
      "elevation": 351,
      "lat": -17.7010993958,
      "lon": 168.5269927979,
      "tz": "Pacific\/Efate"
  },
  "NVVK": {
      "icao": "NVVK",
      "iata": "",
      "name": "Lenakel Airport",
      "city": "Lenakel",
      "state": "Tafea",
      "country": "VU",
      "elevation": 230,
      "lat": -19.5130004883,
      "lon": 169.2599945068,
      "tz": "Pacific\/Efate"
  },
  "NVVQ": {
      "icao": "NVVQ",
      "iata": "UIQ",
      "name": "Quion Hill Airport",
      "city": "Quion Hill",
      "state": "Shefa",
      "country": "VU",
      "elevation": 16,
      "lat": -17.5400009155,
      "lon": 168.4420013428,
      "tz": "Pacific\/Efate"
  },
  "NVVV": {
      "icao": "NVVV",
      "iata": "VLI",
      "name": "Port Vila Bauerfield Airport",
      "city": "Port Vila",
      "state": "Shefa",
      "country": "VU",
      "elevation": 70,
      "lat": -17.699300766,
      "lon": 168.3200073242,
      "tz": "Pacific\/Efate"
  },
  "NVVW": {
      "icao": "NVVW",
      "iata": "TAH",
      "name": "Tanna Airport",
      "city": "",
      "state": "Tafea",
      "country": "VU",
      "elevation": 19,
      "lat": -19.4550991058,
      "lon": 169.2239990234,
      "tz": "Pacific\/Efate"
  },
  "NWWA": {
      "icao": "NWWA",
      "iata": "TGJ",
      "name": "Tiga Airport",
      "city": "Tiga",
      "state": "",
      "country": "NC",
      "elevation": 128,
      "lat": -21.0960998535,
      "lon": 167.8040008545,
      "tz": "Pacific\/Noumea"
  },
  "NWWB": {
      "icao": "NWWB",
      "iata": "",
      "name": "Bourail - Poe Airport",
      "city": "Bourail \/ Poe",
      "state": "",
      "country": "NC",
      "elevation": 7,
      "lat": -21.6096992493,
      "lon": 165.3970031738,
      "tz": "Pacific\/Noumea"
  },
  "NWWC": {
      "icao": "NWWC",
      "iata": "BMY",
      "name": "Ile Art - Waala Airport",
      "city": "Waala",
      "state": "North-Province",
      "country": "NC",
      "elevation": 306,
      "lat": -19.7206001282,
      "lon": 163.6609954834,
      "tz": "Pacific\/Noumea"
  },
  "NWWD": {
      "icao": "NWWD",
      "iata": "KNQ",
      "name": "Kone Airport",
      "city": "Kone",
      "state": "",
      "country": "NC",
      "elevation": 23,
      "lat": -21.0543003082,
      "lon": 164.8370056152,
      "tz": "Pacific\/Noumea"
  },
  "NWWE": {
      "icao": "NWWE",
      "iata": "ILP",
      "name": "Ile des Pins Airport",
      "city": "Ile des Pins",
      "state": "",
      "country": "NC",
      "elevation": 315,
      "lat": -22.5888996124,
      "lon": 167.4559936523,
      "tz": "Pacific\/Noumea"
  },
  "NWWF": {
      "icao": "NWWF",
      "iata": "",
      "name": "Voh Airport",
      "city": "Voh",
      "state": "North-Province",
      "country": "NC",
      "elevation": 33,
      "lat": -20.966999054,
      "lon": 164.6999969482,
      "tz": "Pacific\/Noumea"
  },
  "NWWH": {
      "icao": "NWWH",
      "iata": "HLU",
      "name": "Nesson Airport",
      "city": "Houailou",
      "state": "",
      "country": "NC",
      "elevation": 10,
      "lat": -21.2563991547,
      "lon": 165.6170043945,
      "tz": "Pacific\/Noumea"
  },
  "NWWK": {
      "icao": "NWWK",
      "iata": "KOC",
      "name": "Koumac Airport",
      "city": "Koumac",
      "state": "",
      "country": "NC",
      "elevation": 42,
      "lat": -20.5463008881,
      "lon": 164.2559967041,
      "tz": "Pacific\/Noumea"
  },
  "NWWL": {
      "icao": "NWWL",
      "iata": "LIF",
      "name": "Lifou Airport",
      "city": "Lifou",
      "state": "Loyalty-Islands",
      "country": "NC",
      "elevation": 92,
      "lat": -20.7747993469,
      "lon": 167.2400054932,
      "tz": "Pacific\/Noumea"
  },
  "NWWM": {
      "icao": "NWWM",
      "iata": "GEA",
      "name": "Noumea Magenta Airport",
      "city": "Noumea",
      "state": "South-Province",
      "country": "NC",
      "elevation": 10,
      "lat": -22.2583007813,
      "lon": 166.4730072021,
      "tz": "Pacific\/Noumea"
  },
  "NWWO": {
      "icao": "NWWO",
      "iata": "IOU",
      "name": "Edmond Cane Airport",
      "city": "Ile Ouen",
      "state": "",
      "country": "NC",
      "elevation": 7,
      "lat": -22.4599990845,
      "lon": 166.7830047607,
      "tz": "Pacific\/Noumea"
  },
  "NWWP": {
      "icao": "NWWP",
      "iata": "PUV",
      "name": "Poum Airport",
      "city": "Poum",
      "state": "",
      "country": "NC",
      "elevation": 6,
      "lat": -20.2891998291,
      "lon": 164.0989990234,
      "tz": "Pacific\/Noumea"
  },
  "NWWQ": {
      "icao": "NWWQ",
      "iata": "PDC",
      "name": "Mueo Airport",
      "city": "Mueo",
      "state": "",
      "country": "NC",
      "elevation": 0,
      "lat": -21.316400528,
      "lon": 164.9989929199,
      "tz": "Pacific\/Noumea"
  },
  "NWWR": {
      "icao": "NWWR",
      "iata": "MEE",
      "name": "Mare Airport",
      "city": "Mare",
      "state": "Loyalty-Islands",
      "country": "NC",
      "elevation": 141,
      "lat": -21.4817008972,
      "lon": 168.0379943848,
      "tz": "Pacific\/Noumea"
  },
  "NWWS": {
      "icao": "NWWS",
      "iata": "",
      "name": "Plaine des Lacs Airport",
      "city": "Plaine des Lacs",
      "state": "",
      "country": "NC",
      "elevation": 902,
      "lat": -22.2670001984,
      "lon": 166.9170074463,
      "tz": "Pacific\/Noumea"
  },
  "NWWT": {
      "icao": "NWWT",
      "iata": "",
      "name": "Oua Tom Airport",
      "city": "La Foa",
      "state": "",
      "country": "NC",
      "elevation": 98,
      "lat": -21.8197002411,
      "lon": 165.8609924316,
      "tz": "Pacific\/Noumea"
  },
  "NWWU": {
      "icao": "NWWU",
      "iata": "TOU",
      "name": "Touho Airport",
      "city": "Touho",
      "state": "",
      "country": "NC",
      "elevation": 10,
      "lat": -20.7900009155,
      "lon": 165.2590026855,
      "tz": "Pacific\/Noumea"
  },
  "NWWV": {
      "icao": "NWWV",
      "iata": "UVE",
      "name": "Ouvea Airport",
      "city": "Ouvea",
      "state": "Loyalty-Islands",
      "country": "NC",
      "elevation": 23,
      "lat": -20.6406002045,
      "lon": 166.5729980469,
      "tz": "Pacific\/Noumea"
  },
  "NWWW": {
      "icao": "NWWW",
      "iata": "NOU",
      "name": "La Tontouta International Airport",
      "city": "Noumea",
      "state": "South-Province",
      "country": "NC",
      "elevation": 52,
      "lat": -22.0146007538,
      "lon": 166.2129974365,
      "tz": "Pacific\/Noumea"
  },
  "NWWX": {
      "icao": "NWWX",
      "iata": "",
      "name": "Canala Airport",
      "city": "Canala",
      "state": "North-Province",
      "country": "NC",
      "elevation": 7,
      "lat": -21.5202007294,
      "lon": 165.9720001221,
      "tz": "Pacific\/Noumea"
  },
  "NWWY": {
      "icao": "NWWY",
      "iata": "",
      "name": "Ouaco Airport",
      "city": "Ouaco",
      "state": "",
      "country": "NC",
      "elevation": 69,
      "lat": -20.8500003815,
      "lon": 164.5330047607,
      "tz": "Pacific\/Noumea"
  },
  "NX01": {
      "icao": "NX01",
      "iata": "",
      "name": "Cole Landing Zone Airport",
      "city": "",
      "state": "Arkansas",
      "country": "US",
      "elevation": 811,
      "lat": 35.2779006958,
      "lon": -94.2242965698,
      "tz": "America\/Chicago"
  },
  "NY00": {
      "icao": "NY00",
      "iata": "",
      "name": "Basher Field",
      "city": "Sardinia",
      "state": "New-York",
      "country": "US",
      "elevation": 1855,
      "lat": 42.5666999817,
      "lon": -78.556098938,
      "tz": "America\/New_York"
  },
  "NY01": {
      "icao": "NY01",
      "iata": "",
      "name": "Knowlesville Airport",
      "city": "Knowlesville",
      "state": "New-York",
      "country": "US",
      "elevation": 555,
      "lat": 43.2209014893,
      "lon": -78.3150024414,
      "tz": "America\/New_York"
  },
  "NY02": {
      "icao": "NY02",
      "iata": "",
      "name": "Mountain Top Airport",
      "city": "Davenport",
      "state": "New-York",
      "country": "US",
      "elevation": 1960,
      "lat": 42.4873008728,
      "lon": -74.7801971436,
      "tz": "America\/New_York"
  },
  "NY03": {
      "icao": "NY03",
      "iata": "",
      "name": "Skytop Airport",
      "city": "Roscoe",
      "state": "New-York",
      "country": "US",
      "elevation": 2094,
      "lat": 41.8750991821,
      "lon": -74.9802017212,
      "tz": "America\/New_York"
  },
  "NY04": {
      "icao": "NY04",
      "iata": "",
      "name": "East Arcade Airport",
      "city": "Arcade",
      "state": "New-York",
      "country": "US",
      "elevation": 1830,
      "lat": 42.5856018066,
      "lon": -78.3491973877,
      "tz": "America\/New_York"
  },
  "NY05": {
      "icao": "NY05",
      "iata": "",
      "name": "Hogan Airport",
      "city": "Esperance",
      "state": "New-York",
      "country": "US",
      "elevation": 1260,
      "lat": 42.78150177,
      "lon": -74.3256988525,
      "tz": "America\/New_York"
  },
  "NY06": {
      "icao": "NY06",
      "iata": "",
      "name": "Gaines Valley Aviation Airport",
      "city": "Albion",
      "state": "New-York",
      "country": "US",
      "elevation": 380,
      "lat": 43.2963981628,
      "lon": -78.2138977051,
      "tz": "America\/New_York"
  },
  "NY07": {
      "icao": "NY07",
      "iata": "",
      "name": "Hurlbut Field",
      "city": "Canton",
      "state": "New-York",
      "country": "US",
      "elevation": 500,
      "lat": 44.5457992554,
      "lon": -75.1611022949,
      "tz": "America\/New_York"
  },
  "NY09": {
      "icao": "NY09",
      "iata": "",
      "name": "Middle Hope Airport",
      "city": "Newburgh",
      "state": "New-York",
      "country": "US",
      "elevation": 450,
      "lat": 41.5611991882,
      "lon": -74.0162963867,
      "tz": "America\/New_York"
  },
  "NY10": {
      "icao": "NY10",
      "iata": "",
      "name": "Duflo Airport",
      "city": "New Bremen",
      "state": "New-York",
      "country": "US",
      "elevation": 789,
      "lat": 43.8426017761,
      "lon": -75.4307022095,
      "tz": "America\/New_York"
  },
  "NY11": {
      "icao": "NY11",
      "iata": "",
      "name": "Lakestone Farm Airport",
      "city": "Macedon",
      "state": "New-York",
      "country": "US",
      "elevation": 520,
      "lat": 43.0353012085,
      "lon": -77.3079986572,
      "tz": "America\/New_York"
  },
  "NY13": {
      "icao": "NY13",
      "iata": "",
      "name": "D'Amico Airport",
      "city": "Lyons",
      "state": "New-York",
      "country": "US",
      "elevation": 515,
      "lat": 43.0917015076,
      "lon": -76.9905014038,
      "tz": "America\/New_York"
  },
  "NY15": {
      "icao": "NY15",
      "iata": "",
      "name": "Lakeville Airport",
      "city": "Lakeville",
      "state": "New-York",
      "country": "US",
      "elevation": 935,
      "lat": 42.826499939,
      "lon": -77.7144012451,
      "tz": "America\/New_York"
  },
  "NY16": {
      "icao": "NY16",
      "iata": "",
      "name": "Oak Ridge Airport",
      "city": "East Palmyra",
      "state": "New-York",
      "country": "US",
      "elevation": 540,
      "lat": 43.098400116,
      "lon": -77.1819000244,
      "tz": "America\/New_York"
  },
  "NY17": {
      "icao": "NY17",
      "iata": "",
      "name": "Adirondack Airpark Estates Airport",
      "city": "Plattsburgh",
      "state": "New-York",
      "country": "US",
      "elevation": 1352,
      "lat": 44.6091995239,
      "lon": -73.8579025269,
      "tz": "America\/New_York"
  },
  "NY18": {
      "icao": "NY18",
      "iata": "",
      "name": "Neno International Airport",
      "city": "Ithaca",
      "state": "New-York",
      "country": "US",
      "elevation": 1283,
      "lat": 42.4486999512,
      "lon": -76.616897583,
      "tz": "America\/New_York"
  },
  "NY19": {
      "icao": "NY19",
      "iata": "",
      "name": "Walls Airport",
      "city": "Elbridge",
      "state": "New-York",
      "country": "US",
      "elevation": 610,
      "lat": 43.0439987183,
      "lon": -76.4618988037,
      "tz": "America\/New_York"
  },
  "NY20": {
      "icao": "NY20",
      "iata": "",
      "name": "Nellis Field",
      "city": "Fort Plain",
      "state": "New-York",
      "country": "US",
      "elevation": 700,
      "lat": 42.9584007263,
      "lon": -74.6788024902,
      "tz": "America\/New_York"
  },
  "NY23": {
      "icao": "NY23",
      "iata": "",
      "name": "Mohawk Aviation Center Airport",
      "city": "Mohawk",
      "state": "New-York",
      "country": "US",
      "elevation": 1210,
      "lat": 42.9875984192,
      "lon": -74.9664993286,
      "tz": "America\/New_York"
  },
  "NY24": {
      "icao": "NY24",
      "iata": "",
      "name": "Taylor Johnson Airport",
      "city": "Niagara Falls",
      "state": "New-York",
      "country": "US",
      "elevation": 633,
      "lat": 43.1277999878,
      "lon": -78.9700012207,
      "tz": "America\/New_York"
  },
  "NY25": {
      "icao": "NY25",
      "iata": "",
      "name": "F&F Airpark Airport",
      "city": "Oneonta",
      "state": "New-York",
      "country": "US",
      "elevation": 1110,
      "lat": 42.4500999451,
      "lon": -75.0162963867,
      "tz": "America\/New_York"
  },
  "NY26": {
      "icao": "NY26",
      "iata": "",
      "name": "Sky-Ranch Airport",
      "city": "Herkimer",
      "state": "New-York",
      "country": "US",
      "elevation": 1330,
      "lat": 42.9833984375,
      "lon": -74.899597168,
      "tz": "America\/New_York"
  },
  "NY27": {
      "icao": "NY27",
      "iata": "",
      "name": "South Dayton Airport",
      "city": "South Dayton",
      "state": "New-York",
      "country": "US",
      "elevation": 1300,
      "lat": 42.3708992004,
      "lon": -79.0094985962,
      "tz": "America\/New_York"
  },
  "NY28": {
      "icao": "NY28",
      "iata": "",
      "name": "Anthonson Airport",
      "city": "Jordan",
      "state": "New-York",
      "country": "US",
      "elevation": 430,
      "lat": 43.0708999634,
      "lon": -76.4079971313,
      "tz": "America\/New_York"
  },
  "NY29": {
      "icao": "NY29",
      "iata": "",
      "name": "Schuyler Airport",
      "city": "Watkins Glen",
      "state": "New-York",
      "country": "US",
      "elevation": 870,
      "lat": 42.4519996643,
      "lon": -76.9115982056,
      "tz": "America\/New_York"
  },
  "NY31": {
      "icao": "NY31",
      "iata": "",
      "name": "Rabbit Lane Airport",
      "city": "Phoenix",
      "state": "New-York",
      "country": "US",
      "elevation": 405,
      "lat": 43.2237014771,
      "lon": -76.3687973022,
      "tz": "America\/New_York"
  },
  "NY32": {
      "icao": "NY32",
      "iata": "",
      "name": "Fort Hill Airport",
      "city": "West Bloomfield",
      "state": "New-York",
      "country": "US",
      "elevation": 915,
      "lat": 42.9020004272,
      "lon": -77.5477981567,
      "tz": "America\/New_York"
  },
  "NY33": {
      "icao": "NY33",
      "iata": "",
      "name": "Westerlo Airport",
      "city": "Westerlo",
      "state": "New-York",
      "country": "US",
      "elevation": 1400,
      "lat": 42.5223007202,
      "lon": -74.0287017822,
      "tz": "America\/New_York"
  },
  "NY34": {
      "icao": "NY34",
      "iata": "",
      "name": "Randall's Roost Airport",
      "city": "Cameron",
      "state": "New-York",
      "country": "US",
      "elevation": 1825,
      "lat": 42.1622009277,
      "lon": -77.4274978638,
      "tz": "America\/New_York"
  },
  "NY35": {
      "icao": "NY35",
      "iata": "",
      "name": "Stanton Airport",
      "city": "New Paltz",
      "state": "New-York",
      "country": "US",
      "elevation": 303,
      "lat": 41.7500991821,
      "lon": -74.1163024902,
      "tz": "America\/New_York"
  },
  "NY37": {
      "icao": "NY37",
      "iata": "",
      "name": "Galway Airport",
      "city": "Galway",
      "state": "New-York",
      "country": "US",
      "elevation": 950,
      "lat": 43.0092010498,
      "lon": -74.0912017822,
      "tz": "America\/New_York"
  },
  "NY38": {
      "icao": "NY38",
      "iata": "",
      "name": "Mc Bride's Airport",
      "city": "Gorham",
      "state": "New-York",
      "country": "US",
      "elevation": 915,
      "lat": 42.853099823,
      "lon": -77.1247024536,
      "tz": "America\/New_York"
  },
  "NY40": {
      "icao": "NY40",
      "iata": "",
      "name": "Gentzke Aeronautical Park Airport",
      "city": "Holland",
      "state": "New-York",
      "country": "US",
      "elevation": 1750,
      "lat": 42.6105995178,
      "lon": -78.5955963135,
      "tz": "America\/New_York"
  },
  "NY42": {
      "icao": "NY42",
      "iata": "",
      "name": "Paradise Airport",
      "city": "Red Creek",
      "state": "New-York",
      "country": "US",
      "elevation": 420,
      "lat": 43.2201004028,
      "lon": -76.6899032593,
      "tz": "America\/New_York"
  },
  "NY48": {
      "icao": "NY48",
      "iata": "",
      "name": "Grace's Landing Airport",
      "city": "South Kortright",
      "state": "New-York",
      "country": "US",
      "elevation": 1500,
      "lat": 42.3381004333,
      "lon": -74.7490005493,
      "tz": "America\/New_York"
  },
  "NY49": {
      "icao": "NY49",
      "iata": "",
      "name": "Mesmer Airport",
      "city": "Niagara Falls",
      "state": "New-York",
      "country": "US",
      "elevation": 600,
      "lat": 42.9928016663,
      "lon": -78.9863967896,
      "tz": "America\/New_York"
  },
  "NY50": {
      "icao": "NY50",
      "iata": "",
      "name": "Johnson Airport",
      "city": "Perth",
      "state": "New-York",
      "country": "US",
      "elevation": 760,
      "lat": 42.9916992188,
      "lon": -74.136100769,
      "tz": "America\/New_York"
  },
  "NY51": {
      "icao": "NY51",
      "iata": "",
      "name": "Russell Airport",
      "city": "Canajoharie",
      "state": "New-York",
      "country": "US",
      "elevation": 700,
      "lat": 42.870098114,
      "lon": -74.4992980957,
      "tz": "America\/New_York"
  },
  "NY53": {
      "icao": "NY53",
      "iata": "",
      "name": "Tri County Airways Airport",
      "city": "West Edmeston",
      "state": "New-York",
      "country": "US",
      "elevation": 1150,
      "lat": 42.7389984131,
      "lon": -75.300201416,
      "tz": "America\/New_York"
  },
  "NY55": {
      "icao": "NY55",
      "iata": "",
      "name": "Grund Field",
      "city": "Ithaca",
      "state": "New-York",
      "country": "US",
      "elevation": 1453,
      "lat": 42.4472999573,
      "lon": -76.5969009399,
      "tz": "America\/New_York"
  },
  "NY57": {
      "icao": "NY57",
      "iata": "",
      "name": "Remsen City Airport",
      "city": "Remsen",
      "state": "New-York",
      "country": "US",
      "elevation": 1220,
      "lat": 43.3600997925,
      "lon": -75.1846008301,
      "tz": "America\/New_York"
  },
  "NY58": {
      "icao": "NY58",
      "iata": "",
      "name": "Snow Field",
      "city": "Amsterdam",
      "state": "New-York",
      "country": "US",
      "elevation": 775,
      "lat": 42.9566993713,
      "lon": -74.0814971924,
      "tz": "America\/New_York"
  },
  "NY59": {
      "icao": "NY59",
      "iata": "",
      "name": "Valenty Mierek Airport",
      "city": "Ava",
      "state": "New-York",
      "country": "US",
      "elevation": 1220,
      "lat": 43.387298584,
      "lon": -75.4862976074,
      "tz": "America\/New_York"
  },
  "NY60": {
      "icao": "NY60",
      "iata": "",
      "name": "Vasile Field",
      "city": "Beekmanton",
      "state": "New-York",
      "country": "US",
      "elevation": 580,
      "lat": 44.7625007629,
      "lon": -73.5593032837,
      "tz": "America\/New_York"
  },
  "NY62": {
      "icao": "NY62",
      "iata": "",
      "name": "Curtis Airport",
      "city": "Verona",
      "state": "New-York",
      "country": "US",
      "elevation": 535,
      "lat": 43.1458015442,
      "lon": -75.5419006348,
      "tz": "America\/New_York"
  },
  "NY63": {
      "icao": "NY63",
      "iata": "",
      "name": "Archdale Meadows Airport",
      "city": "Greenwich",
      "state": "New-York",
      "country": "US",
      "elevation": 584,
      "lat": 43.0532989502,
      "lon": -73.478302002,
      "tz": "America\/New_York"
  },
  "NY64": {
      "icao": "NY64",
      "iata": "",
      "name": "The Pines Airport",
      "city": "Broadalbin",
      "state": "New-York",
      "country": "US",
      "elevation": 850,
      "lat": 43.1029014587,
      "lon": -74.1550979614,
      "tz": "America\/New_York"
  },
  "NY65": {
      "icao": "NY65",
      "iata": "",
      "name": "Circle K Ranch Airport",
      "city": "Broadalbin",
      "state": "New-York",
      "country": "US",
      "elevation": 920,
      "lat": 43.137298584,
      "lon": -74.1162033081,
      "tz": "America\/New_York"
  },
  "NY66": {
      "icao": "NY66",
      "iata": "",
      "name": "Lewis Field",
      "city": "Broadalbin",
      "state": "New-York",
      "country": "US",
      "elevation": 900,
      "lat": 43.1333999634,
      "lon": -74.1162033081,
      "tz": "America\/New_York"
  },
  "NY67": {
      "icao": "NY67",
      "iata": "",
      "name": "Carter Flight Park Ultralightport",
      "city": "Manlius",
      "state": "New-York",
      "country": "US",
      "elevation": 1301,
      "lat": 42.9930992126,
      "lon": -75.9116973877,
      "tz": "America\/New_York"
  },
  "NY68": {
      "icao": "NY68",
      "iata": "",
      "name": "Richfield Airport",
      "city": "Richfield Springs",
      "state": "New-York",
      "country": "US",
      "elevation": 1450,
      "lat": 42.8611984253,
      "lon": -74.9453964233,
      "tz": "America\/New_York"
  },
  "NY69": {
      "icao": "NY69",
      "iata": "",
      "name": "John Gonzales Field",
      "city": "Cape Vincent",
      "state": "New-York",
      "country": "US",
      "elevation": 260,
      "lat": 44.120098114,
      "lon": -76.3013000488,
      "tz": "America\/New_York"
  },
  "NY70": {
      "icao": "NY70",
      "iata": "",
      "name": "Scott'S Sky Ranch Airport",
      "city": "Mount Morris",
      "state": "New-York",
      "country": "US",
      "elevation": 1221,
      "lat": 42.648889,
      "lon": -77.920278,
      "tz": "America\/New_York"
  },
  "NY71": {
      "icao": "NY71",
      "iata": "",
      "name": "O'Riley Airport",
      "city": "Fort Plain",
      "state": "New-York",
      "country": "US",
      "elevation": 700,
      "lat": 42.9756011963,
      "lon": -74.7298965454,
      "tz": "America\/New_York"
  },
  "NY72": {
      "icao": "NY72",
      "iata": "",
      "name": "Poolsbrook Aerodrome",
      "city": "Manlius",
      "state": "New-York",
      "country": "US",
      "elevation": 500,
      "lat": 43.0722999573,
      "lon": -75.9384994507,
      "tz": "America\/New_York"
  },
  "NY73": {
      "icao": "NY73",
      "iata": "",
      "name": "Miller Field",
      "city": "Coventryville",
      "state": "New-York",
      "country": "US",
      "elevation": 1580,
      "lat": 42.3180999756,
      "lon": -75.5990982056,
      "tz": "America\/New_York"
  },
  "NY74": {
      "icao": "NY74",
      "iata": "",
      "name": "Deer Run Airport",
      "city": "Coxsackie",
      "state": "New-York",
      "country": "US",
      "elevation": 320,
      "lat": 42.3236999512,
      "lon": -73.8731994629,
      "tz": "America\/New_York"
  },
  "NY76": {
      "icao": "NY76",
      "iata": "",
      "name": "Neverland Airport",
      "city": "Cattaraugus",
      "state": "New-York",
      "country": "US",
      "elevation": 1901,
      "lat": 42.3156661987,
      "lon": -78.8953323364,
      "tz": "America\/New_York"
  },
  "NY77": {
      "icao": "NY77",
      "iata": "",
      "name": "Wandervogel Gliderport",
      "city": "Delanson",
      "state": "New-York",
      "country": "US",
      "elevation": 1260,
      "lat": 42.7181015015,
      "lon": -74.1634979248,
      "tz": "America\/New_York"
  },
  "NY78": {
      "icao": "NY78",
      "iata": "",
      "name": "Downsville Airport",
      "city": "Downsville",
      "state": "New-York",
      "country": "US",
      "elevation": 1087,
      "lat": 42.0583992004,
      "lon": -75.020401001,
      "tz": "America\/New_York"
  },
  "NY79": {
      "icao": "NY79",
      "iata": "",
      "name": "Keysa Airport",
      "city": "Bliss",
      "state": "New-York",
      "country": "US",
      "elevation": 1620,
      "lat": 42.596698761,
      "lon": -78.3403015137,
      "tz": "America\/New_York"
  },
  "NY81": {
      "icao": "NY81",
      "iata": "",
      "name": "Heldeberg Airstrip",
      "city": "East Berne",
      "state": "New-York",
      "country": "US",
      "elevation": 1237,
      "lat": 42.6111984253,
      "lon": -74.0647964478,
      "tz": "America\/New_York"
  },
  "NY82": {
      "icao": "NY82",
      "iata": "",
      "name": "Alexander Farm Airport",
      "city": "East Schodack",
      "state": "New-York",
      "country": "US",
      "elevation": 525,
      "lat": 42.5806007385,
      "lon": -73.6162033081,
      "tz": "America\/New_York"
  },
  "NY84": {
      "icao": "NY84",
      "iata": "",
      "name": "Richter Aero Airport",
      "city": "Essex",
      "state": "New-York",
      "country": "US",
      "elevation": 360,
      "lat": 44.2792015076,
      "lon": -73.3787002563,
      "tz": "America\/New_York"
  },
  "NY85": {
      "icao": "NY85",
      "iata": "",
      "name": "Hickory Hollow Airport",
      "city": "Floyd",
      "state": "New-York",
      "country": "US",
      "elevation": 930,
      "lat": 43.2495002747,
      "lon": -75.3137969971,
      "tz": "America\/New_York"
  },
  "NY86": {
      "icao": "NY86",
      "iata": "",
      "name": "Waxwing Airport",
      "city": "Rensselaerville",
      "state": "New-York",
      "country": "US",
      "elevation": 1680,
      "lat": 42.5125999451,
      "lon": -74.1148986816,
      "tz": "America\/New_York"
  },
  "NY87": {
      "icao": "NY87",
      "iata": "",
      "name": "Amsterdam Airfield",
      "city": "Fort Johnson",
      "state": "New-York",
      "country": "US",
      "elevation": 340,
      "lat": 42.962600708,
      "lon": -74.2537002563,
      "tz": "America\/New_York"
  },
  "NY88": {
      "icao": "NY88",
      "iata": "",
      "name": "Hickory Acres Airport",
      "city": "Fort Plain",
      "state": "New-York",
      "country": "US",
      "elevation": 970,
      "lat": 42.8833999634,
      "lon": -74.6912994385,
      "tz": "America\/New_York"
  },
  "NY89": {
      "icao": "NY89",
      "iata": "",
      "name": "Fairbank Farms Airport",
      "city": "Jamestown",
      "state": "New-York",
      "country": "US",
      "elevation": 1350,
      "lat": 42.0801010132,
      "lon": -79.3914031982,
      "tz": "America\/New_York"
  },
  "NY91": {
      "icao": "NY91",
      "iata": "",
      "name": "Cloverdale Farm Airport",
      "city": "Peterboro",
      "state": "New-York",
      "country": "US",
      "elevation": 1465,
      "lat": 42.9940261841,
      "lon": -75.7027740479,
      "tz": "America\/New_York"
  },
  "NY92": {
      "icao": "NY92",
      "iata": "",
      "name": "Owasco Airport",
      "city": "Moravia",
      "state": "New-York",
      "country": "US",
      "elevation": 1378,
      "lat": 42.7358016968,
      "lon": -76.4858016968,
      "tz": "America\/New_York"
  },
  "NY94": {
      "icao": "NY94",
      "iata": "",
      "name": "Old Rhinebeck Airport",
      "city": "Rhinebeck",
      "state": "New-York",
      "country": "US",
      "elevation": 323,
      "lat": 41.9715004,
      "lon": -73.86289978,
      "tz": "America\/New_York"
  },
  "NY96": {
      "icao": "NY96",
      "iata": "",
      "name": "Mexico Airdrome Airport",
      "city": "Mexico",
      "state": "New-York",
      "country": "US",
      "elevation": 470,
      "lat": 43.426700592,
      "lon": -76.1938018799,
      "tz": "America\/New_York"
  },
  "NY97": {
      "icao": "NY97",
      "iata": "",
      "name": "Northway Airport",
      "city": "Morrisonville",
      "state": "New-York",
      "country": "US",
      "elevation": 420,
      "lat": 44.6666984558,
      "lon": -73.5662002563,
      "tz": "America\/New_York"
  },
  "NZ12": {
      "icao": "NZ12",
      "iata": "",
      "name": "Palmer Station Airport",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 149,
      "lat": -64.7750015259,
      "lon": -64.054397583,
      "tz": "Antarctica\/Palmer"
  },
  "NZAA": {
      "icao": "NZAA",
      "iata": "AKL",
      "name": "Auckland International Airport",
      "city": "Auckland",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 23,
      "lat": -37.0080986023,
      "lon": 174.7920074463,
      "tz": "Pacific\/Auckland"
  },
  "NZAE": {
      "icao": "NZAE",
      "iata": "",
      "name": "Mount Tarawera Airport",
      "city": "Mount Tarawera",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 3100,
      "lat": -38.233001709,
      "lon": 176.516998291,
      "tz": "Pacific\/Auckland"
  },
  "NZAG": {
      "icao": "NZAG",
      "iata": "",
      "name": "Matarangi Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 10,
      "lat": -36.7317008972,
      "lon": 175.6499938965,
      "tz": "Pacific\/Auckland"
  },
  "NZAN": {
      "icao": "NZAN",
      "iata": "",
      "name": "Alfredton Airport",
      "city": "Alfredton",
      "state": "Manawatu-Wanganui",
      "country": "NZ",
      "elevation": 500,
      "lat": -40.6981010437,
      "lon": 175.8560028076,
      "tz": "Pacific\/Auckland"
  },
  "NZAP": {
      "icao": "NZAP",
      "iata": "TUO",
      "name": "Taupo Airport",
      "city": "Taupo",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 1335,
      "lat": -38.7397003174,
      "lon": 176.0839996338,
      "tz": "Pacific\/Auckland"
  },
  "NZAR": {
      "icao": "NZAR",
      "iata": "AMZ",
      "name": "Ardmore Airport",
      "city": "Manurewa",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 111,
      "lat": -37.0297012329,
      "lon": 174.9730072021,
      "tz": "Pacific\/Auckland"
  },
  "NZAS": {
      "icao": "NZAS",
      "iata": "ASG",
      "name": "Ashburton Aerodrome",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 302,
      "lat": -43.903301239,
      "lon": 171.7969970703,
      "tz": "Pacific\/Auckland"
  },
  "NZBA": {
      "icao": "NZBA",
      "iata": "",
      "name": "Balclutha Aerodrome",
      "city": "Balclutha",
      "state": "Otago",
      "country": "NZ",
      "elevation": 22,
      "lat": -46.2433013916,
      "lon": 169.75,
      "tz": "Pacific\/Auckland"
  },
  "NZCB": {
      "icao": "NZCB",
      "iata": "",
      "name": "Centre Bush Aerodrome",
      "city": "Centre Bush",
      "state": "Southland",
      "country": "NZ",
      "elevation": 255,
      "lat": -46.0400009155,
      "lon": 168.31199646,
      "tz": "Pacific\/Auckland"
  },
  "NZCH": {
      "icao": "NZCH",
      "iata": "CHC",
      "name": "Christchurch International Airport",
      "city": "Christchurch",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 123,
      "lat": -43.4893989563,
      "lon": 172.5319976807,
      "tz": "Pacific\/Auckland"
  },
  "NZCI": {
      "icao": "NZCI",
      "iata": "CHT",
      "name": "Chatham Islands-Tuuta Airport",
      "city": "Waitangi",
      "state": "Chatham-Islands",
      "country": "NZ",
      "elevation": 43,
      "lat": -43.8100013733,
      "lon": -176.4570007324,
      "tz": "Pacific\/Chatham"
  },
  "NZCS": {
      "icao": "NZCS",
      "iata": "",
      "name": "Cromwell Racecourse Aerodrome",
      "city": "",
      "state": "",
      "country": "NZ",
      "elevation": 856,
      "lat": -45.0485992432,
      "lon": 169.171005249,
      "tz": "Pacific\/Auckland"
  },
  "NZCX": {
      "icao": "NZCX",
      "iata": "CMV",
      "name": "Coromandel Aerodrome",
      "city": "",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 10,
      "lat": -36.7916984558,
      "lon": 175.5090026855,
      "tz": "Pacific\/Auckland"
  },
  "NZDA": {
      "icao": "NZDA",
      "iata": "DGR",
      "name": "Dargaville Aerodrome",
      "city": "",
      "state": "Northland",
      "country": "NZ",
      "elevation": 10,
      "lat": -35.9397010803,
      "lon": 173.8939971924,
      "tz": "Pacific\/Auckland"
  },
  "NZDN": {
      "icao": "NZDN",
      "iata": "DUD",
      "name": "Dunedin Airport",
      "city": "Dunedin",
      "state": "Otago",
      "country": "NZ",
      "elevation": 4,
      "lat": -45.9281005859,
      "lon": 170.1979980469,
      "tz": "Pacific\/Auckland"
  },
  "NZDV": {
      "icao": "NZDV",
      "iata": "",
      "name": "Dannevirke Aerodrome",
      "city": "",
      "state": "Manawatu-Wanganui",
      "country": "NZ",
      "elevation": 640,
      "lat": -40.2282981873,
      "lon": 176.078994751,
      "tz": "Pacific\/Auckland"
  },
  "NZES": {
      "icao": "NZES",
      "iata": "",
      "name": "Wharepapa South Airport",
      "city": "",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 443,
      "lat": -38.1483001709,
      "lon": 175.5500030518,
      "tz": "Pacific\/Auckland"
  },
  "NZFF": {
      "icao": "NZFF",
      "iata": "",
      "name": "Forest Field Aerodrome",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 381,
      "lat": -43.385799408,
      "lon": 172.3609924316,
      "tz": "Pacific\/Auckland"
  },
  "NZFI": {
      "icao": "NZFI",
      "iata": "",
      "name": "Feilding Airport",
      "city": "Feilding",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 214,
      "lat": -40.2557983398,
      "lon": 175.6049957275,
      "tz": "Pacific\/Auckland"
  },
  "NZFJ": {
      "icao": "NZFJ",
      "iata": "WHO",
      "name": "Franz Josef Aerodrome",
      "city": "",
      "state": "West-Coast",
      "country": "NZ",
      "elevation": 240,
      "lat": -43.3630981445,
      "lon": 170.1340026855,
      "tz": "Pacific\/Auckland"
  },
  "NZFP": {
      "icao": "NZFP",
      "iata": "",
      "name": "Foxpine Aerodrome",
      "city": "",
      "state": "",
      "country": "NZ",
      "elevation": 39,
      "lat": -40.4566993713,
      "lon": 175.2700042725,
      "tz": "Pacific\/Auckland"
  },
  "NZFT": {
      "icao": "NZFT",
      "iata": "",
      "name": "Flat Point Aerodrome",
      "city": "",
      "state": "",
      "country": "NZ",
      "elevation": 46,
      "lat": -41.2433013916,
      "lon": 175.9600067139,
      "tz": "Pacific\/Auckland"
  },
  "NZGA": {
      "icao": "NZGA",
      "iata": "",
      "name": "Galatea Airfield",
      "city": "",
      "state": "Bay-of-Plenty",
      "country": "NZ",
      "elevation": 594,
      "lat": -38.4039001465,
      "lon": 176.7440032959,
      "tz": "Pacific\/Auckland"
  },
  "NZGB": {
      "icao": "NZGB",
      "iata": "GBZ",
      "name": "Great Barrier Aerodrome",
      "city": "Claris",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 20,
      "lat": -36.2414016724,
      "lon": 175.4720001221,
      "tz": "Pacific\/Auckland"
  },
  "NZGC": {
      "icao": "NZGC",
      "iata": "",
      "name": "Gore3 Airport",
      "city": "",
      "state": "Southland",
      "country": "NZ",
      "elevation": 200,
      "lat": -46.1567001343,
      "lon": 168.8979949951,
      "tz": "Pacific\/Auckland"
  },
  "NZGM": {
      "icao": "NZGM",
      "iata": "GMN",
      "name": "Greymouth Airport",
      "city": "",
      "state": "West-Coast",
      "country": "NZ",
      "elevation": 10,
      "lat": -42.4617004395,
      "lon": 171.1900024414,
      "tz": "Pacific\/Auckland"
  },
  "NZGR": {
      "icao": "NZGR",
      "iata": "",
      "name": "Great Mercury Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 180,
      "lat": -36.5974998474,
      "lon": 175.766998291,
      "tz": "Pacific\/Auckland"
  },
  "NZGS": {
      "icao": "NZGS",
      "iata": "GIS",
      "name": "Gisborne Airport",
      "city": "Gisborne",
      "state": "Gisborne",
      "country": "NZ",
      "elevation": 15,
      "lat": -38.6632995605,
      "lon": 177.9779968262,
      "tz": "Pacific\/Auckland"
  },
  "NZGT": {
      "icao": "NZGT",
      "iata": "GTN",
      "name": "Glentanner Airport",
      "city": "Glentanner Station",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 1824,
      "lat": -43.9067001343,
      "lon": 170.1280059814,
      "tz": "Pacific\/Auckland"
  },
  "NZGY": {
      "icao": "NZGY",
      "iata": "",
      "name": "Glenorchy Airport",
      "city": "",
      "state": "Otago",
      "country": "NZ",
      "elevation": 1260,
      "lat": -44.8717002869,
      "lon": 168.3979949951,
      "tz": "Pacific\/Auckland"
  },
  "NZHA": {
      "icao": "NZHA",
      "iata": "",
      "name": "Hawera Airport",
      "city": "Hawera",
      "state": "Taranaki",
      "country": "NZ",
      "elevation": 371,
      "lat": -39.5532989502,
      "lon": 174.266998291,
      "tz": "Pacific\/Auckland"
  },
  "NZHB": {
      "icao": "NZHB",
      "iata": "",
      "name": "Auckland Hobsonville Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 59,
      "lat": -36.7916984558,
      "lon": 174.6640014648,
      "tz": "Pacific\/Auckland"
  },
  "NZHK": {
      "icao": "NZHK",
      "iata": "HKK",
      "name": "Hokitika Airfield",
      "city": "",
      "state": "West-Coast",
      "country": "NZ",
      "elevation": 146,
      "lat": -42.7136001587,
      "lon": 170.9850006104,
      "tz": "Pacific\/Auckland"
  },
  "NZHN": {
      "icao": "NZHN",
      "iata": "HLZ",
      "name": "Hamilton International Airport",
      "city": "Hamilton",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 172,
      "lat": -37.8666992188,
      "lon": 175.332000732,
      "tz": "Pacific\/Auckland"
  },
  "NZHP": {
      "icao": "NZHP",
      "iata": "",
      "name": "Lake Haupiri\/Gloriavale Christian Community Airport",
      "city": "",
      "state": "West-Coast",
      "country": "NZ",
      "elevation": 600,
      "lat": -42.6012001038,
      "lon": 171.699005127,
      "tz": "Pacific\/Auckland"
  },
  "NZHR": {
      "icao": "NZHR",
      "iata": "",
      "name": "Hanmer Springs Airport",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 1109,
      "lat": -42.5508003235,
      "lon": 172.8269958496,
      "tz": "Pacific\/Auckland"
  },
  "NZHS": {
      "icao": "NZHS",
      "iata": "",
      "name": "Hastings Airport",
      "city": "",
      "state": "Hawke's-Bay",
      "country": "NZ",
      "elevation": 72,
      "lat": -39.6467018127,
      "lon": 176.766998291,
      "tz": "Pacific\/Auckland"
  },
  "NZHT": {
      "icao": "NZHT",
      "iata": "",
      "name": "Haast Aerodrome",
      "city": "",
      "state": "West-Coast",
      "country": "NZ",
      "elevation": 13,
      "lat": -43.8652992249,
      "lon": 169.0410003662,
      "tz": "Pacific\/Auckland"
  },
  "NZIR": {
      "icao": "NZIR",
      "iata": "",
      "name": "McMurdo Station Ice Runway",
      "city": "McMurdo Station",
      "state": "",
      "country": "AQ",
      "elevation": 1,
      "lat": -77.8539962769,
      "lon": 166.4689941406,
      "tz": "Antarctica\/McMurdo"
  },
  "NZKC": {
      "icao": "NZKC",
      "iata": "",
      "name": "Kelly Field",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 52,
      "lat": -37.2560997009,
      "lon": 175.0720062256,
      "tz": "Pacific\/Auckland"
  },
  "NZKD": {
      "icao": "NZKD",
      "iata": "",
      "name": "Motu Kaikoura Island Aerodrome",
      "city": "Kaikoura Island",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 400,
      "lat": -36.1781005859,
      "lon": 175.324005127,
      "tz": "Pacific\/Auckland"
  },
  "NZKE": {
      "icao": "NZKE",
      "iata": "WIK",
      "name": "Waiheke Reeve Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 226,
      "lat": -36.8088989258,
      "lon": 175.0859985352,
      "tz": "Pacific\/Auckland"
  },
  "NZKF": {
      "icao": "NZKF",
      "iata": "",
      "name": "Kaipara Flats Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 112,
      "lat": -36.4063987732,
      "lon": 174.5870056152,
      "tz": "Pacific\/Auckland"
  },
  "NZKI": {
      "icao": "NZKI",
      "iata": "KBZ",
      "name": "Kaikoura Airport",
      "city": "",
      "state": "Marlborough",
      "country": "NZ",
      "elevation": 20,
      "lat": -42.4249992371,
      "lon": 173.6049957275,
      "tz": "Pacific\/Auckland"
  },
  "NZKK": {
      "icao": "NZKK",
      "iata": "KKE",
      "name": "Kerikeri Airport",
      "city": "Kerikeri",
      "state": "Northland",
      "country": "NZ",
      "elevation": 492,
      "lat": -35.2627983093,
      "lon": 173.9120025635,
      "tz": "Pacific\/Auckland"
  },
  "NZKM": {
      "icao": "NZKM",
      "iata": "",
      "name": "Karamea Airport",
      "city": "",
      "state": "",
      "country": "NZ",
      "elevation": 30,
      "lat": -41.2366981506,
      "lon": 172.1049957275,
      "tz": "Pacific\/Auckland"
  },
  "NZKO": {
      "icao": "NZKO",
      "iata": "KKO",
      "name": "Kaikohe Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 571,
      "lat": -35.4510993958,
      "lon": 173.8170013428,
      "tz": "Pacific\/Auckland"
  },
  "NZKT": {
      "icao": "NZKT",
      "iata": "KAT",
      "name": "Kaitaia Airport",
      "city": "Kaitaia",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 270,
      "lat": -35.0699996948,
      "lon": 173.2850036621,
      "tz": "Pacific\/Auckland"
  },
  "NZKY": {
      "icao": "NZKY",
      "iata": "",
      "name": "Kowhai Aerodrome",
      "city": "",
      "state": "Hawke's-Bay",
      "country": "NZ",
      "elevation": 840,
      "lat": -39.8460998535,
      "lon": 176.4230041504,
      "tz": "Pacific\/Auckland"
  },
  "NZLD": {
      "icao": "NZLD",
      "iata": "",
      "name": "Limestone Downs Airport",
      "city": "Limestone Downs",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 596,
      "lat": -37.4799995422,
      "lon": 174.7449951172,
      "tz": "Pacific\/Auckland"
  },
  "NZLE": {
      "icao": "NZLE",
      "iata": "",
      "name": "Lake Station Airport",
      "city": "",
      "state": "Nelson",
      "country": "NZ",
      "elevation": 1709,
      "lat": -41.7592010498,
      "lon": 172.7460021973,
      "tz": "Pacific\/Auckland"
  },
  "NZLX": {
      "icao": "NZLX",
      "iata": "ALR",
      "name": "Alexandra Airport",
      "city": "Alexandra",
      "state": "Otago",
      "country": "NZ",
      "elevation": 752,
      "lat": -45.2117004395,
      "lon": 169.3730010986,
      "tz": "Pacific\/Auckland"
  },
  "NZMA": {
      "icao": "NZMA",
      "iata": "MTA",
      "name": "Matamata Glider Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 180,
      "lat": -37.7344017029,
      "lon": 175.7420043945,
      "tz": "Pacific\/Auckland"
  },
  "NZMC": {
      "icao": "NZMC",
      "iata": "MON",
      "name": "Mount Cook Airport",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 2153,
      "lat": -43.7649993896,
      "lon": 170.1329956055,
      "tz": "Pacific\/Auckland"
  },
  "NZME": {
      "icao": "NZME",
      "iata": "",
      "name": "Mercer1 PDZ Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 30,
      "lat": -37.2574996948,
      "lon": 175.1150054932,
      "tz": "Pacific\/Auckland"
  },
  "NZMF": {
      "icao": "NZMF",
      "iata": "MFN",
      "name": "Milford Sound Airport",
      "city": "",
      "state": "Southland",
      "country": "NZ",
      "elevation": 10,
      "lat": -44.6733016968,
      "lon": 167.9230041504,
      "tz": "Pacific\/Auckland"
  },
  "NZMJ": {
      "icao": "NZMJ",
      "iata": "",
      "name": "Martins Bay Aerodrome",
      "city": "",
      "state": "Otago",
      "country": "NZ",
      "elevation": 348,
      "lat": -44.3657989502,
      "lon": 168.0180053711,
      "tz": "Pacific\/Auckland"
  },
  "NZMK": {
      "icao": "NZMK",
      "iata": "MZP",
      "name": "Motueka Airport",
      "city": "",
      "state": "Nelson",
      "country": "NZ",
      "elevation": 39,
      "lat": -41.123298645,
      "lon": 172.9889984131,
      "tz": "Pacific\/Auckland"
  },
  "NZML": {
      "icao": "NZML",
      "iata": "",
      "name": "Molesworth Airport",
      "city": "Molesworth",
      "state": "Marlborough",
      "country": "NZ",
      "elevation": 2813,
      "lat": -42.0830001831,
      "lon": 173.2830047607,
      "tz": "Pacific\/Auckland"
  },
  "NZMO": {
      "icao": "NZMO",
      "iata": "TEU",
      "name": "Manapouri Airport",
      "city": "",
      "state": "Southland",
      "country": "NZ",
      "elevation": 687,
      "lat": -45.5331001282,
      "lon": 167.6499938965,
      "tz": "Pacific\/Auckland"
  },
  "NZMR": {
      "icao": "NZMR",
      "iata": "",
      "name": "Murchison Airport",
      "city": "Murchison",
      "state": "",
      "country": "NZ",
      "elevation": 531,
      "lat": -41.7966995239,
      "lon": 172.3150024414,
      "tz": "Pacific\/Auckland"
  },
  "NZMS": {
      "icao": "NZMS",
      "iata": "MRO",
      "name": "Hood Airport",
      "city": "Masterton",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 364,
      "lat": -40.9733009338,
      "lon": 175.6340026855,
      "tz": "Pacific\/Auckland"
  },
  "NZMT": {
      "icao": "NZMT",
      "iata": "",
      "name": "Martinborough Airport",
      "city": "Martinborough",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 0,
      "lat": -41.2141990662,
      "lon": 175.4859924316,
      "tz": "Pacific\/Auckland"
  },
  "NZMW": {
      "icao": "NZMW",
      "iata": "",
      "name": "Makarora Airstrip",
      "city": "",
      "state": "",
      "country": "NZ",
      "elevation": 1142,
      "lat": -44.2317008972,
      "lon": 169.2299957275,
      "tz": "Pacific\/Auckland"
  },
  "NZMZ": {
      "icao": "NZMZ",
      "iata": "",
      "name": "Matakana Island Airport",
      "city": "Matakana Island",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 95,
      "lat": -37.5999984741,
      "lon": 176.0829925537,
      "tz": "Pacific\/Auckland"
  },
  "NZNE": {
      "icao": "NZNE",
      "iata": "",
      "name": "North Shore Aerodrome",
      "city": "Dairy Flat",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 210,
      "lat": -36.6567001343,
      "lon": 174.6549987793,
      "tz": "Pacific\/Auckland"
  },
  "NZNP": {
      "icao": "NZNP",
      "iata": "NPL",
      "name": "New Plymouth Airport",
      "city": "New Plymouth",
      "state": "Taranaki",
      "country": "NZ",
      "elevation": 97,
      "lat": -39.0085983276,
      "lon": 174.1790008545,
      "tz": "Pacific\/Auckland"
  },
  "NZNR": {
      "icao": "NZNR",
      "iata": "NPE",
      "name": "Napier Airport",
      "city": "",
      "state": "Hawke's-Bay",
      "country": "NZ",
      "elevation": 6,
      "lat": -39.465801239,
      "lon": 176.8699951172,
      "tz": "Pacific\/Auckland"
  },
  "NZNS": {
      "icao": "NZNS",
      "iata": "NSN",
      "name": "Nelson Airport",
      "city": "Nelson",
      "state": "Nelson",
      "country": "NZ",
      "elevation": 17,
      "lat": -41.2983016968,
      "lon": 173.220993042,
      "tz": "Pacific\/Auckland"
  },
  "NZNV": {
      "icao": "NZNV",
      "iata": "IVC",
      "name": "Invercargill Airport",
      "city": "Invercargill",
      "state": "Southland",
      "country": "NZ",
      "elevation": 5,
      "lat": -46.412399292,
      "lon": 168.31300354,
      "tz": "Pacific\/Auckland"
  },
  "NZOA": {
      "icao": "NZOA",
      "iata": "",
      "name": "Omarama Glider Airport",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 1381,
      "lat": -44.4866981506,
      "lon": 169.9859924316,
      "tz": "Pacific\/Auckland"
  },
  "NZOH": {
      "icao": "NZOH",
      "iata": "OHA",
      "name": "RNZAF Base Ohakea",
      "city": "",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 164,
      "lat": -40.2060012817,
      "lon": 175.3880004883,
      "tz": "Pacific\/Auckland"
  },
  "NZOI": {
      "icao": "NZOI",
      "iata": "",
      "name": "Motiti Island Airport",
      "city": "Motiti Island",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 135,
      "lat": -37.6329994202,
      "lon": 176.4170074463,
      "tz": "Pacific\/Auckland"
  },
  "NZOM": {
      "icao": "NZOM",
      "iata": "",
      "name": "Omaka Blenheim Airport",
      "city": "Blenheim",
      "state": "Marlborough",
      "country": "NZ",
      "elevation": 102,
      "lat": -41.5400009155,
      "lon": 173.9219970703,
      "tz": "Pacific\/Auckland"
  },
  "NZOP": {
      "icao": "NZOP",
      "iata": "",
      "name": "Opotiki Airport",
      "city": "",
      "state": "Gisborne",
      "country": "NZ",
      "elevation": 30,
      "lat": -38.0224990845,
      "lon": 177.3070068359,
      "tz": "Pacific\/Auckland"
  },
  "NZOU": {
      "icao": "NZOU",
      "iata": "OAM",
      "name": "Oamaru Airport",
      "city": "",
      "state": "Otago",
      "country": "NZ",
      "elevation": 99,
      "lat": -44.9700012207,
      "lon": 171.0820007324,
      "tz": "Pacific\/Auckland"
  },
  "NZOX": {
      "icao": "NZOX",
      "iata": "",
      "name": "Okiwi Station Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 10,
      "lat": -36.1464004517,
      "lon": 175.4190063477,
      "tz": "Pacific\/Auckland"
  },
  "NZPA": {
      "icao": "NZPA",
      "iata": "",
      "name": "Paihia Private Airport",
      "city": "",
      "state": "Northland",
      "country": "NZ",
      "elevation": 20,
      "lat": -35.2821998596,
      "lon": 174.091003418,
      "tz": "Pacific\/Auckland"
  },
  "NZPG": {
      "icao": "NZPG",
      "iata": "",
      "name": "McMurdo Station Pegasus Field",
      "city": "McMurdo Station",
      "state": "",
      "country": "AQ",
      "elevation": 18,
      "lat": -77.9634017944,
      "lon": 166.5249938965,
      "tz": "Antarctica\/McMurdo"
  },
  "NZPH": {
      "icao": "NZPH",
      "iata": "",
      "name": "Pudding Hill Aerodrome",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 1558,
      "lat": -43.5942001343,
      "lon": 171.5310058594,
      "tz": "Pacific\/Auckland"
  },
  "NZPI": {
      "icao": "NZPI",
      "iata": "",
      "name": "Parakai Aerodrome",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 49,
      "lat": -36.6519012451,
      "lon": 174.4360046387,
      "tz": "Pacific\/Auckland"
  },
  "NZPK": {
      "icao": "NZPK",
      "iata": "",
      "name": "Pikes Point Airport",
      "city": "Pikes Point",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 0,
      "lat": -36.9282989502,
      "lon": 174.81199646,
      "tz": "Pacific\/Auckland"
  },
  "NZPM": {
      "icao": "NZPM",
      "iata": "PMR",
      "name": "Palmerston North Airport",
      "city": "",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 151,
      "lat": -40.3205986023,
      "lon": 175.6170043945,
      "tz": "Pacific\/Auckland"
  },
  "NZPN": {
      "icao": "NZPN",
      "iata": "PCN",
      "name": "Picton Aerodrome",
      "city": "Picton",
      "state": "Marlborough",
      "country": "NZ",
      "elevation": 161,
      "lat": -41.3460998535,
      "lon": 173.9559936523,
      "tz": "Pacific\/Auckland"
  },
  "NZPO": {
      "icao": "NZPO",
      "iata": "",
      "name": "Porangahau Aerodrome",
      "city": "",
      "state": "Hawke's-Bay",
      "country": "NZ",
      "elevation": 200,
      "lat": -40.2807998657,
      "lon": 176.6519927979,
      "tz": "Pacific\/Auckland"
  },
  "NZPP": {
      "icao": "NZPP",
      "iata": "PPQ",
      "name": "Paraparaumu Airport",
      "city": "",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 22,
      "lat": -40.9047012329,
      "lon": 174.9889984131,
      "tz": "Pacific\/Auckland"
  },
  "NZQN": {
      "icao": "NZQN",
      "iata": "ZQN",
      "name": "Queenstown International Airport",
      "city": "Queenstown",
      "state": "Otago",
      "country": "NZ",
      "elevation": 1171,
      "lat": -45.0210990906,
      "lon": 168.738998413,
      "tz": "Pacific\/Auckland"
  },
  "NZRA": {
      "icao": "NZRA",
      "iata": "RAG",
      "name": "Raglan Airfield",
      "city": "",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 10,
      "lat": -37.8046989441,
      "lon": 174.8600006104,
      "tz": "Pacific\/Auckland"
  },
  "NZRC": {
      "icao": "NZRC",
      "iata": "SZS",
      "name": "Ryans Creek Aerodrome",
      "city": "Oban",
      "state": "Southland",
      "country": "NZ",
      "elevation": 62,
      "lat": -46.8997001648,
      "lon": 168.100997925,
      "tz": "Pacific\/Auckland"
  },
  "NZRI": {
      "icao": "NZRI",
      "iata": "",
      "name": "Rangitata Island Airport",
      "city": "Rangitata Island",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 288,
      "lat": -44.0849990845,
      "lon": 171.4160003662,
      "tz": "Pacific\/Auckland"
  },
  "NZRK": {
      "icao": "NZRK",
      "iata": "",
      "name": "Rangitaiki Airfield",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 2323,
      "lat": -38.8863983154,
      "lon": 176.3639984131,
      "tz": "Pacific\/Auckland"
  },
  "NZRO": {
      "icao": "NZRO",
      "iata": "ROT",
      "name": "Rotorua Regional Airport",
      "city": "Rotorua",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 935,
      "lat": -38.1091995239,
      "lon": 176.3170013428,
      "tz": "Pacific\/Auckland"
  },
  "NZRT": {
      "icao": "NZRT",
      "iata": "",
      "name": "Rangiora Airfield",
      "city": "Rangiora",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 180,
      "lat": -43.2900009155,
      "lon": 172.5420074463,
      "tz": "Pacific\/Auckland"
  },
  "NZRU": {
      "icao": "NZRU",
      "iata": "",
      "name": "Waiouru Airport",
      "city": "",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 2686,
      "lat": -39.4463996887,
      "lon": 175.6580047607,
      "tz": "Pacific\/Auckland"
  },
  "NZRW": {
      "icao": "NZRW",
      "iata": "",
      "name": "Ruawai Aerodrome",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 62,
      "lat": -36.0971984863,
      "lon": 173.9770050049,
      "tz": "Pacific\/Auckland"
  },
  "NZRX": {
      "icao": "NZRX",
      "iata": "",
      "name": "Roxburgh Aerodrome",
      "city": "",
      "state": "Otago",
      "country": "NZ",
      "elevation": 463,
      "lat": -45.5116996765,
      "lon": 169.3170013428,
      "tz": "Pacific\/Auckland"
  },
  "NZSD": {
      "icao": "NZSD",
      "iata": "",
      "name": "Stratford Airport",
      "city": "Stratford",
      "state": "Taranaki",
      "country": "NZ",
      "elevation": 951,
      "lat": -39.318901062,
      "lon": 174.3099975586,
      "tz": "Pacific\/Auckland"
  },
  "NZSL": {
      "icao": "NZSL",
      "iata": "",
      "name": "Springhill Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 102,
      "lat": -36.323600769,
      "lon": 174.5559997559,
      "tz": "Pacific\/Auckland"
  },
  "NZSP": {
      "icao": "NZSP",
      "iata": "",
      "name": "South Pole Station Airport",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 9300,
      "lat": -90,
      "lon": 0,
      "tz": "Antarctica\/McMurdo"
  },
  "NZTA": {
      "icao": "NZTA",
      "iata": "",
      "name": "Te Aroha Airfield",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 30,
      "lat": -37.4747009277,
      "lon": 175.6179962158,
      "tz": "Pacific\/Auckland"
  },
  "NZTE": {
      "icao": "NZTE",
      "iata": "",
      "name": "Te Kowhai Airfield",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 49,
      "lat": -37.7442016602,
      "lon": 175.1609954834,
      "tz": "Pacific\/Auckland"
  },
  "NZTG": {
      "icao": "NZTG",
      "iata": "TRG",
      "name": "Tauranga Airport",
      "city": "Tauranga",
      "state": "Bay-of-Plenty",
      "country": "NZ",
      "elevation": 13,
      "lat": -37.6719017029,
      "lon": 176.1959991455,
      "tz": "Pacific\/Auckland"
  },
  "NZTH": {
      "icao": "NZTH",
      "iata": "TMZ",
      "name": "Thames Aerodrome",
      "city": "",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 10,
      "lat": -37.1567001343,
      "lon": 175.5500030518,
      "tz": "Pacific\/Auckland"
  },
  "NZTI": {
      "icao": "NZTI",
      "iata": "",
      "name": "Taieri Airport",
      "city": "",
      "state": "Otago",
      "country": "NZ",
      "elevation": 92,
      "lat": -45.8600006104,
      "lon": 170.358001709,
      "tz": "Pacific\/Auckland"
  },
  "NZTK": {
      "icao": "NZTK",
      "iata": "KTF",
      "name": "Takaka Airport",
      "city": "",
      "state": "Tasman",
      "country": "NZ",
      "elevation": 102,
      "lat": -40.8133010864,
      "lon": 172.7749938965,
      "tz": "Pacific\/Auckland"
  },
  "NZTL": {
      "icao": "NZTL",
      "iata": "",
      "name": "Tekapo Aerodrome",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 2496,
      "lat": -44.0052986145,
      "lon": 170.444000244,
      "tz": "Pacific\/Auckland"
  },
  "NZTM": {
      "icao": "NZTM",
      "iata": "",
      "name": "Taumarunui Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 902,
      "lat": -38.8394012451,
      "lon": 175.2619934082,
      "tz": "Pacific\/Auckland"
  },
  "NZTN": {
      "icao": "NZTN",
      "iata": "",
      "name": "Turangi Airport",
      "city": "",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 1220,
      "lat": -38.9682998657,
      "lon": 175.8139953613,
      "tz": "Pacific\/Auckland"
  },
  "NZTO": {
      "icao": "NZTO",
      "iata": "TKZ",
      "name": "Tokoroa Airfield",
      "city": "Tokoroa",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 1220,
      "lat": -38.2366981506,
      "lon": 175.891998291,
      "tz": "Pacific\/Auckland"
  },
  "NZTS": {
      "icao": "NZTS",
      "iata": "THH",
      "name": "Taharoa Aerodrome",
      "city": "Taharoa",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 69,
      "lat": -38.181098938,
      "lon": 174.7079925537,
      "tz": "Pacific\/Auckland"
  },
  "NZTT": {
      "icao": "NZTT",
      "iata": "",
      "name": "Te Kuiti Airfield",
      "city": "Te Kuiti",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 161,
      "lat": -38.3032989502,
      "lon": 175.1470031738,
      "tz": "Pacific\/Auckland"
  },
  "NZTU": {
      "icao": "NZTU",
      "iata": "TIU",
      "name": "Timaru Airport",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 89,
      "lat": -44.3027992249,
      "lon": 171.2250061035,
      "tz": "Pacific\/Auckland"
  },
  "NZTZ": {
      "icao": "NZTZ",
      "iata": "",
      "name": "Te Anau Airport",
      "city": "",
      "state": "Southland",
      "country": "NZ",
      "elevation": 791,
      "lat": -45.4627990723,
      "lon": 167.7010040283,
      "tz": "Pacific\/Auckland"
  },
  "NZUK": {
      "icao": "NZUK",
      "iata": "TWZ",
      "name": "Pukaki Airport",
      "city": "Twitzel",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 1575,
      "lat": -44.2350006104,
      "lon": 170.117996216,
      "tz": "Pacific\/Auckland"
  },
  "NZUN": {
      "icao": "NZUN",
      "iata": "",
      "name": "Pauanui Beach Glider Airport",
      "city": "",
      "state": "Waikato",
      "country": "NZ",
      "elevation": 20,
      "lat": -37.0217018127,
      "lon": 175.8639984131,
      "tz": "Pacific\/Auckland"
  },
  "NZVL": {
      "icao": "NZVL",
      "iata": "",
      "name": "Mandeville Aerodrome",
      "city": "",
      "state": "",
      "country": "NZ",
      "elevation": 351,
      "lat": -45.9902992249,
      "lon": 168.81199646,
      "tz": "Pacific\/Auckland"
  },
  "NZVR": {
      "icao": "NZVR",
      "iata": "",
      "name": "Taihape Airport",
      "city": "Taihape",
      "state": "",
      "country": "NZ",
      "elevation": 1550,
      "lat": -39.6850013733,
      "lon": 175.7890014648,
      "tz": "Pacific\/Auckland"
  },
  "NZWB": {
      "icao": "NZWB",
      "iata": "BHE",
      "name": "Woodbourne Airport",
      "city": "Blenheim",
      "state": "Marlborough",
      "country": "NZ",
      "elevation": 109,
      "lat": -41.5182991028,
      "lon": 173.8699951172,
      "tz": "Pacific\/Auckland"
  },
  "NZWD": {
      "icao": "NZWD",
      "iata": "",
      "name": "Williams Field",
      "city": "McMurdo Station",
      "state": "",
      "country": "AQ",
      "elevation": 68,
      "lat": -77.867401123,
      "lon": 167.0570068359,
      "tz": "Antarctica\/McMurdo"
  },
  "NZWF": {
      "icao": "NZWF",
      "iata": "WKA",
      "name": "Wanaka Airport",
      "city": "",
      "state": "Otago",
      "country": "NZ",
      "elevation": 1142,
      "lat": -44.7221984863,
      "lon": 169.2460021973,
      "tz": "Pacific\/Auckland"
  },
  "NZWJ": {
      "icao": "NZWJ",
      "iata": "",
      "name": "Wellsford Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 194,
      "lat": -36.2969017029,
      "lon": 174.5229949951,
      "tz": "Pacific\/Auckland"
  },
  "NZWK": {
      "icao": "NZWK",
      "iata": "WHK",
      "name": "Whakatane Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 20,
      "lat": -37.9206008911,
      "lon": 176.9140014648,
      "tz": "Pacific\/Auckland"
  },
  "NZWL": {
      "icao": "NZWL",
      "iata": "",
      "name": "West Melton Aerodrome",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 312,
      "lat": -43.4766998291,
      "lon": 172.3970031738,
      "tz": "Pacific\/Auckland"
  },
  "NZWM": {
      "icao": "NZWM",
      "iata": "",
      "name": "Waimate Airport",
      "city": "",
      "state": "Canterbury",
      "country": "NZ",
      "elevation": 79,
      "lat": -44.7900009155,
      "lon": 171.0919952393,
      "tz": "Pacific\/Auckland"
  },
  "NZWN": {
      "icao": "NZWN",
      "iata": "WLG",
      "name": "Wellington International Airport",
      "city": "Wellington",
      "state": "Wellington",
      "country": "NZ",
      "elevation": 41,
      "lat": -41.3272018433,
      "lon": 174.804992676,
      "tz": "Pacific\/Auckland"
  },
  "NZWO": {
      "icao": "NZWO",
      "iata": "WIR",
      "name": "Wairoa Airport",
      "city": "Wairoa",
      "state": "Hawke's-Bay",
      "country": "NZ",
      "elevation": 42,
      "lat": -39.0069007874,
      "lon": 177.4069976807,
      "tz": "Pacific\/Auckland"
  },
  "NZWP": {
      "icao": "NZWP",
      "iata": "",
      "name": "RNZAF Base Auckland-Whenuapai",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 100,
      "lat": -36.7877998352,
      "lon": 174.6300048828,
      "tz": "Pacific\/Auckland"
  },
  "NZWR": {
      "icao": "NZWR",
      "iata": "WRE",
      "name": "Whangarei Airport",
      "city": "",
      "state": "Northland",
      "country": "NZ",
      "elevation": 133,
      "lat": -35.7682991028,
      "lon": 174.3650054932,
      "tz": "Pacific\/Auckland"
  },
  "NZWS": {
      "icao": "NZWS",
      "iata": "WSZ",
      "name": "Westport Airport",
      "city": "",
      "state": "West-Coast",
      "country": "NZ",
      "elevation": 13,
      "lat": -41.7380981445,
      "lon": 171.5809936523,
      "tz": "Pacific\/Auckland"
  },
  "NZWT": {
      "icao": "NZWT",
      "iata": "WTZ",
      "name": "Whitianga Airport",
      "city": "",
      "state": "Auckland",
      "country": "NZ",
      "elevation": 10,
      "lat": -36.8316993713,
      "lon": 175.6790008545,
      "tz": "Pacific\/Auckland"
  },
  "NZWU": {
      "icao": "NZWU",
      "iata": "WAG",
      "name": "Wanganui Airport",
      "city": "Wanganui",
      "state": "Manawatu-Wanganui",
      "country": "NZ",
      "elevation": 27,
      "lat": -39.9622001648,
      "lon": 175.0249938965,
      "tz": "Pacific\/Auckland"
  },
  "NZWV": {
      "icao": "NZWV",
      "iata": "",
      "name": "Waihi Beach Airport",
      "city": "",
      "state": "Bay-of-Plenty",
      "country": "NZ",
      "elevation": 3,
      "lat": -37.4300003052,
      "lon": 175.9519958496,
      "tz": "Pacific\/Auckland"
  },
  "NZYP": {
      "icao": "NZYP",
      "iata": "",
      "name": "Waipukurau Airport",
      "city": "",
      "state": "Hawke's-Bay",
      "country": "NZ",
      "elevation": 430,
      "lat": -39.9967002869,
      "lon": 176.5370025635,
      "tz": "Pacific\/Auckland"
  },
  "OA00": {
      "icao": "OA00",
      "iata": "",
      "name": "Taildragger Airport",
      "city": "Cambridge",
      "state": "Ohio",
      "country": "US",
      "elevation": 800,
      "lat": 40.1170005798,
      "lon": -81.5711975098,
      "tz": "America\/New_York"
  },
  "OA04": {
      "icao": "OA04",
      "iata": "",
      "name": "Rutter Airport",
      "city": "Arcadia",
      "state": "Ohio",
      "country": "US",
      "elevation": 815,
      "lat": 41.0975990295,
      "lon": -83.5027008057,
      "tz": "America\/New_York"
  },
  "OA05": {
      "icao": "OA05",
      "iata": "",
      "name": "Hogan Airport",
      "city": "Mc Gonigle",
      "state": "Ohio",
      "country": "US",
      "elevation": 865,
      "lat": 39.4241981506,
      "lon": -84.6591033936,
      "tz": "America\/New_York"
  },
  "OA07": {
      "icao": "OA07",
      "iata": "",
      "name": "Hemlock Field",
      "city": "Rock Creek",
      "state": "Ohio",
      "country": "US",
      "elevation": 815,
      "lat": 41.7014007568,
      "lon": -80.8602981567,
      "tz": "America\/New_York"
  },
  "OA10": {
      "icao": "OA10",
      "iata": "",
      "name": "Bernie's Airport",
      "city": "Mount Orab",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 39.0085983276,
      "lon": -83.9092025757,
      "tz": "America\/New_York"
  },
  "OA11": {
      "icao": "OA11",
      "iata": "",
      "name": "Heitman Field",
      "city": "Anna",
      "state": "Ohio",
      "country": "US",
      "elevation": 998,
      "lat": 40.3760986328,
      "lon": -84.2549972534,
      "tz": "America\/New_York"
  },
  "OA12": {
      "icao": "OA12",
      "iata": "",
      "name": "Buena Vista Farm Airport",
      "city": "Morrow",
      "state": "Ohio",
      "country": "US",
      "elevation": 855,
      "lat": 39.334400177,
      "lon": -84.0699996948,
      "tz": "America\/New_York"
  },
  "OA14": {
      "icao": "OA14",
      "iata": "",
      "name": "Roxford Airport",
      "city": "Dennison",
      "state": "Ohio",
      "country": "US",
      "elevation": 872,
      "lat": 40.422222,
      "lon": -81.318056,
      "tz": "America\/New_York"
  },
  "OA16": {
      "icao": "OA16",
      "iata": "",
      "name": "Lost Bridge Airport",
      "city": "North Bend",
      "state": "Ohio",
      "country": "US",
      "elevation": 818,
      "lat": 39.156111,
      "lon": -84.781667,
      "tz": "America\/New_York"
  },
  "OA19": {
      "icao": "OA19",
      "iata": "",
      "name": "Kiko Farm Airport",
      "city": "North Canton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1170,
      "lat": 40.898889,
      "lon": -81.3,
      "tz": "America\/New_York"
  },
  "OA23": {
      "icao": "OA23",
      "iata": "",
      "name": "Heins Field",
      "city": "Arcanum",
      "state": "Ohio",
      "country": "US",
      "elevation": 1040,
      "lat": 39.968667,
      "lon": -84.539,
      "tz": "America\/New_York"
  },
  "OA51": {
      "icao": "OA51",
      "iata": "",
      "name": "Fighter Field Airport",
      "city": "Watkins",
      "state": "Ohio",
      "country": "US",
      "elevation": 980,
      "lat": 40.206111,
      "lon": -83.228611,
      "tz": "America\/New_York"
  },
  "OA85": {
      "icao": "OA85",
      "iata": "",
      "name": "Riverview Airport",
      "city": "Dresden",
      "state": "Ohio",
      "country": "US",
      "elevation": 730,
      "lat": 40.1365013123,
      "lon": -81.9936981201,
      "tz": "America\/New_York"
  },
  "OABN": {
      "icao": "OABN",
      "iata": "BIN",
      "name": "Bamiyan Airport",
      "city": "Bamiyan",
      "state": "Bamyan",
      "country": "AF",
      "elevation": 8367,
      "lat": 34.8170013428,
      "lon": 67.8170013428,
      "tz": "Asia\/Kabul"
  },
  "OABT": {
      "icao": "OABT",
      "iata": "BST",
      "name": "Bost Airport",
      "city": "Bost",
      "state": "Helmand",
      "country": "AF",
      "elevation": 2464,
      "lat": 31.5597000122,
      "lon": 64.3649978638,
      "tz": "Asia\/Kabul"
  },
  "OACC": {
      "icao": "OACC",
      "iata": "CCN",
      "name": "Chakcharan Airport",
      "city": "Chakcharan",
      "state": "Ghowr",
      "country": "AF",
      "elevation": 7383,
      "lat": 34.533000946,
      "lon": 65.266998291,
      "tz": "Asia\/Kabul"
  },
  "OADZ": {
      "icao": "OADZ",
      "iata": "DAZ",
      "name": "Darwaz Airport",
      "city": "Darwaz",
      "state": "Badakhshan",
      "country": "AF",
      "elevation": 5250,
      "lat": 38.461101532,
      "lon": 70.8824996948,
      "tz": "Asia\/Dushanbe"
  },
  "OAFR": {
      "icao": "OAFR",
      "iata": "FAH",
      "name": "Farah Airport",
      "city": "Farah",
      "state": "Farah",
      "country": "AF",
      "elevation": 3083,
      "lat": 32.3670005798,
      "lon": 62.1829986572,
      "tz": "Asia\/Kabul"
  },
  "OAFZ": {
      "icao": "OAFZ",
      "iata": "FBD",
      "name": "Faizabad Airport",
      "city": "Faizabad",
      "state": "Badakhshan",
      "country": "AF",
      "elevation": 3872,
      "lat": 37.1211013794,
      "lon": 70.5180969238,
      "tz": "Asia\/Kabul"
  },
  "OAHN": {
      "icao": "OAHN",
      "iata": "KWH",
      "name": "Khwahan Airport",
      "city": "Khwahan",
      "state": "Badakhshan",
      "country": "AF",
      "elevation": 3593,
      "lat": 37.8829994202,
      "lon": 70.2170028687,
      "tz": "Asia\/Dushanbe"
  },
  "OAHR": {
      "icao": "OAHR",
      "iata": "HEA",
      "name": "Herat Airport",
      "city": "",
      "state": "Herat",
      "country": "AF",
      "elevation": 3206,
      "lat": 34.2099990845,
      "lon": 62.2282981873,
      "tz": "Asia\/Kabul"
  },
  "OAIX": {
      "icao": "OAIX",
      "iata": "OAI",
      "name": "Bagram Air Base",
      "city": "Bagram",
      "state": "Parwan",
      "country": "AF",
      "elevation": 4895,
      "lat": 34.9460983276,
      "lon": 69.2649993896,
      "tz": "Asia\/Kabul"
  },
  "OAJL": {
      "icao": "OAJL",
      "iata": "JAA",
      "name": "Jalalabad Airport",
      "city": "",
      "state": "Nangarhar",
      "country": "AF",
      "elevation": 1814,
      "lat": 34.3997993469,
      "lon": 70.4985961914,
      "tz": "Asia\/Kabul"
  },
  "OAKB": {
      "icao": "OAKB",
      "iata": "KBL",
      "name": "Kabul International Airport",
      "city": "Kabul",
      "state": "Kabul",
      "country": "AF",
      "elevation": 5877,
      "lat": 34.5658988953,
      "lon": 69.2123031616,
      "tz": "Asia\/Kabul"
  },
  "OAKG": {
      "icao": "OAKG",
      "iata": "",
      "name": "Khojaghar Airport",
      "city": "Khojaghar",
      "state": "Takhar",
      "country": "AF",
      "elevation": 1607,
      "lat": 37.0830001831,
      "lon": 69.3669967651,
      "tz": "Asia\/Kabul"
  },
  "OAKN": {
      "icao": "OAKN",
      "iata": "KDH",
      "name": "Kandahar Airport",
      "city": "",
      "state": "Kandahar",
      "country": "AF",
      "elevation": 3337,
      "lat": 31.5058002472,
      "lon": 65.8478012085,
      "tz": "Asia\/Kabul"
  },
  "OAKS": {
      "icao": "OAKS",
      "iata": "KHT",
      "name": "Khost Airport",
      "city": "Khost",
      "state": "Khowst",
      "country": "AF",
      "elevation": 3756,
      "lat": 33.3334007263,
      "lon": 69.952003479,
      "tz": "Asia\/Kabul"
  },
  "OAMN": {
      "icao": "OAMN",
      "iata": "MMZ",
      "name": "Maimana Airport",
      "city": "",
      "state": "Faryab",
      "country": "AF",
      "elevation": 2743,
      "lat": 35.9308013916,
      "lon": 64.7609024048,
      "tz": "Asia\/Kabul"
  },
  "OAMS": {
      "icao": "OAMS",
      "iata": "MZR",
      "name": "Mazar I Sharif Airport",
      "city": "",
      "state": "Balkh",
      "country": "AF",
      "elevation": 1284,
      "lat": 36.7069015503,
      "lon": 67.2097015381,
      "tz": "Asia\/Kabul"
  },
  "OAQM": {
      "icao": "OAQM",
      "iata": "",
      "name": "Kron Monajn Airport",
      "city": "Kron Monajn",
      "state": "Badakhshan",
      "country": "AF",
      "elevation": 8420,
      "lat": 36.1669998169,
      "lon": 70.7170028687,
      "tz": "Asia\/Kabul"
  },
  "OAQN": {
      "icao": "OAQN",
      "iata": "LQN",
      "name": "Qala-I-Naw Airport",
      "city": "Qala-I-Naw",
      "state": "Badghis",
      "country": "AF",
      "elevation": 2999,
      "lat": 34.9850006104,
      "lon": 63.1178016663,
      "tz": "Asia\/Kabul"
  },
  "OASA": {
      "icao": "OASA",
      "iata": "OAS",
      "name": "Sharana Airstrip",
      "city": "Sharana",
      "state": "Paktika",
      "country": "AF",
      "elevation": 7340,
      "lat": 33.12575,
      "lon": 68.838517,
      "tz": "Asia\/Kabul"
  },
  "OASD": {
      "icao": "OASD",
      "iata": "OAH",
      "name": "Shindand Airport",
      "city": "",
      "state": "Herat",
      "country": "AF",
      "elevation": 3773,
      "lat": 33.3913002014,
      "lon": 62.2610015869,
      "tz": "Asia\/Kabul"
  },
  "OASG": {
      "icao": "OASG",
      "iata": "",
      "name": "Sheberghan Airport",
      "city": "",
      "state": "Jowzjan",
      "country": "AF",
      "elevation": 1053,
      "lat": 36.7508010864,
      "lon": 65.9132003784,
      "tz": "Asia\/Kabul"
  },
  "OASN": {
      "icao": "OASN",
      "iata": "SGA",
      "name": "Sheghnan Airport",
      "city": "Sheghnan",
      "state": "Badakhshan",
      "country": "AF",
      "elevation": 6700,
      "lat": 37.5670013428,
      "lon": 71.5,
      "tz": "Asia\/Dushanbe"
  },
  "OATN": {
      "icao": "OATN",
      "iata": "TII",
      "name": "Tarin Kowt Airport",
      "city": "Tarin Kowt",
      "state": "Oruzgan",
      "country": "AF",
      "elevation": 4429,
      "lat": 32.6041984558,
      "lon": 65.8657989502,
      "tz": "Asia\/Kabul"
  },
  "OATQ": {
      "icao": "OATQ",
      "iata": "TQN",
      "name": "Talolqan Airport",
      "city": "Taloqan",
      "state": "Takhar",
      "country": "AF",
      "elevation": 2606,
      "lat": 36.7706985474,
      "lon": 69.5319976807,
      "tz": "Asia\/Kabul"
  },
  "OAUZ": {
      "icao": "OAUZ",
      "iata": "UND",
      "name": "Konduz Airport",
      "city": "",
      "state": "Kunduz",
      "country": "AF",
      "elevation": 1457,
      "lat": 36.6651000977,
      "lon": 68.9107971191,
      "tz": "Asia\/Kabul"
  },
  "OAYQ": {
      "icao": "OAYQ",
      "iata": "",
      "name": "Yangi Qala Airport",
      "city": "",
      "state": "Takhar",
      "country": "AF",
      "elevation": 1600,
      "lat": 37.4589996338,
      "lon": 69.6465988159,
      "tz": "Asia\/Kabul"
  },
  "OAYW": {
      "icao": "OAYW",
      "iata": "",
      "name": "Yawan Airport",
      "city": "",
      "state": "Badakhshan",
      "country": "AF",
      "elevation": 5645,
      "lat": 37.5616989136,
      "lon": 70.4417037964,
      "tz": "Asia\/Kabul"
  },
  "OAZI": {
      "icao": "OAZI",
      "iata": "OAZ",
      "name": "Camp Bastion Airport",
      "city": "",
      "state": "Helmand",
      "country": "AF",
      "elevation": 2943,
      "lat": 31.8638000488,
      "lon": 64.2246017456,
      "tz": "Asia\/Kabul"
  },
  "OAZJ": {
      "icao": "OAZJ",
      "iata": "ZAJ",
      "name": "Zaranj Airport",
      "city": "Zaranj",
      "state": "",
      "country": "AF",
      "elevation": 1572,
      "lat": 30.972222,
      "lon": 61.865833,
      "tz": "Asia\/Kabul"
  },
  "OBBI": {
      "icao": "OBBI",
      "iata": "BAH",
      "name": "Bahrain International Airport",
      "city": "Manama",
      "state": "Muharraq",
      "country": "BH",
      "elevation": 6,
      "lat": 26.2707996368,
      "lon": 50.6335983276,
      "tz": "Asia\/Bahrain"
  },
  "OBBS": {
      "icao": "OBBS",
      "iata": "",
      "name": "Sheik Isa Air Base",
      "city": "Sitrah",
      "state": "",
      "country": "BH",
      "elevation": 136,
      "lat": 25.9183998108,
      "lon": 50.5905990601,
      "tz": "Asia\/Bahrain"
  },
  "OE43": {
      "icao": "OE43",
      "iata": "",
      "name": "Arfa Airport",
      "city": "",
      "state": "Makkah",
      "country": "SA",
      "elevation": 4155,
      "lat": 21.323600769,
      "lon": 41.198299408,
      "tz": "Asia\/Riyadh"
  },
  "OE45": {
      "icao": "OE45",
      "iata": "",
      "name": "Ras Khafji Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 50,
      "lat": 28.3899002075,
      "lon": 48.5164985657,
      "tz": "Asia\/Riyadh"
  },
  "OE46": {
      "icao": "OE46",
      "iata": "",
      "name": "Al Lidem Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 2225,
      "lat": 20.47590065,
      "lon": 44.7574996948,
      "tz": "Asia\/Riyadh"
  },
  "OE47": {
      "icao": "OE47",
      "iata": "",
      "name": "Al Kharj",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 0,
      "lat": 24.0607223511,
      "lon": 47.4108047485,
      "tz": "Asia\/Riyadh"
  },
  "OE48": {
      "icao": "OE48",
      "iata": "",
      "name": "Quz South Airport",
      "city": "",
      "state": "Makkah",
      "country": "SA",
      "elevation": 200,
      "lat": 18.8931999207,
      "lon": 41.3549995422,
      "tz": "Asia\/Riyadh"
  },
  "OE49": {
      "icao": "OE49",
      "iata": "",
      "name": "Mecca East Airport",
      "city": "",
      "state": "Makkah",
      "country": "SA",
      "elevation": 1210,
      "lat": 21.4375,
      "lon": 39.9942016602,
      "tz": "Asia\/Riyadh"
  },
  "OE51": {
      "icao": "OE51",
      "iata": "",
      "name": "Al Kharj East Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 1650,
      "lat": 24.0515003204,
      "lon": 47.6542015076,
      "tz": "Asia\/Riyadh"
  },
  "OE52": {
      "icao": "OE52",
      "iata": "",
      "name": "Al Artawiyah South Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 1900,
      "lat": 26.6116008759,
      "lon": 45.3219985962,
      "tz": "Asia\/Riyadh"
  },
  "OE53": {
      "icao": "OE53",
      "iata": "",
      "name": "King Abdul Aziz Military Academy Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 2420,
      "lat": 24.9421005249,
      "lon": 46.3912010193,
      "tz": "Asia\/Riyadh"
  },
  "OE54": {
      "icao": "OE54",
      "iata": "",
      "name": "Hafar Al Atk Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 1970,
      "lat": 25.9934005737,
      "lon": 46.5367012024,
      "tz": "Asia\/Riyadh"
  },
  "OE55": {
      "icao": "OE55",
      "iata": "",
      "name": "Ghafah Airport",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 699,
      "lat": 21.2269992828,
      "lon": 55.2672996521,
      "tz": "Asia\/Riyadh"
  },
  "OE56": {
      "icao": "OE56",
      "iata": "",
      "name": "Khalfan Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 305,
      "lat": 22.710100174,
      "lon": 53.2812004089,
      "tz": "Asia\/Riyadh"
  },
  "OEAA": {
      "icao": "OEAA",
      "iata": "",
      "name": "Abu Ali Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 69,
      "lat": 27.3190994263,
      "lon": 49.5871009827,
      "tz": "Asia\/Riyadh"
  },
  "OEAB": {
      "icao": "OEAB",
      "iata": "AHB",
      "name": "Abha Regional Airport",
      "city": "Abha",
      "state": "",
      "country": "SA",
      "elevation": 6858,
      "lat": 18.2404003143,
      "lon": 42.6566009521,
      "tz": "Asia\/Riyadh"
  },
  "OEAH": {
      "icao": "OEAH",
      "iata": "HOF",
      "name": "Al Ahsa Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 588,
      "lat": 25.2852993011,
      "lon": 49.4851989746,
      "tz": "Asia\/Riyadh"
  },
  "OEAO": {
      "icao": "OEAO",
      "iata": "ULH",
      "name": "Prince Abdul Majeed bin Abdulaziz International Airport",
      "city": "Al-'Ula",
      "state": "Al Madinah",
      "country": "SA",
      "elevation": 2047,
      "lat": 26.483333,
      "lon": 38.116944,
      "tz": "Asia\/Riyadh"
  },
  "OEBA": {
      "icao": "OEBA",
      "iata": "ABT",
      "name": "Al Baha Airport",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 5486,
      "lat": 20.2961006165,
      "lon": 41.6343002319,
      "tz": "Asia\/Riyadh"
  },
  "OEBH": {
      "icao": "OEBH",
      "iata": "BHH",
      "name": "Bisha Airport",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 3887,
      "lat": 19.9843997955,
      "lon": 42.6208992004,
      "tz": "Asia\/Riyadh"
  },
  "OEBQ": {
      "icao": "OEBQ",
      "iata": "",
      "name": "Abqaiq Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 229,
      "lat": 25.9113006592,
      "lon": 49.5912017822,
      "tz": "Asia\/Riyadh"
  },
  "OEDF": {
      "icao": "OEDF",
      "iata": "DMM",
      "name": "King Fahd International Airport",
      "city": "Ad Dammam",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 72,
      "lat": 26.471200943,
      "lon": 49.7979011536,
      "tz": "Asia\/Riyadh"
  },
  "OEDM": {
      "icao": "OEDM",
      "iata": "",
      "name": "Prince Salman Bin Abdulaziz Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 3026,
      "lat": 24.4498996735,
      "lon": 44.1212005615,
      "tz": "Asia\/Riyadh"
  },
  "OEDR": {
      "icao": "OEDR",
      "iata": "DHA",
      "name": "King Abdulaziz Air Base",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 84,
      "lat": 26.2653999329,
      "lon": 50.1520004272,
      "tz": "Asia\/Riyadh"
  },
  "OEDW": {
      "icao": "OEDW",
      "iata": "DWD",
      "name": "Dawadmi Domestic Airport",
      "city": "Dawadmi",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 3429,
      "lat": 24.5,
      "lon": 44.4000015259,
      "tz": "Asia\/Riyadh"
  },
  "OEGN": {
      "icao": "OEGN",
      "iata": "GIZ",
      "name": "Jizan Regional Airport",
      "city": "Jizan",
      "state": "Jizan",
      "country": "SA",
      "elevation": 20,
      "lat": 16.9011001587,
      "lon": 42.5858001709,
      "tz": "Asia\/Riyadh"
  },
  "OEGS": {
      "icao": "OEGS",
      "iata": "ELQ",
      "name": "Gassim Airport",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 2126,
      "lat": 26.3027992249,
      "lon": 43.7743988037,
      "tz": "Asia\/Riyadh"
  },
  "OEGT": {
      "icao": "OEGT",
      "iata": "URY",
      "name": "Guriat Domestic Airport",
      "city": "",
      "state": "Al-Jawf",
      "country": "SA",
      "elevation": 1672,
      "lat": 31.4118995667,
      "lon": 37.279499054,
      "tz": "Asia\/Riyadh"
  },
  "OEHL": {
      "icao": "OEHL",
      "iata": "HAS",
      "name": "Hail Airport",
      "city": "",
      "state": "\u1e28a\u02bcil",
      "country": "SA",
      "elevation": 3331,
      "lat": 27.4379005432,
      "lon": 41.6862983704,
      "tz": "Asia\/Riyadh"
  },
  "OEHR": {
      "icao": "OEHR",
      "iata": "",
      "name": "Harad Airport",
      "city": "Harad",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 110,
      "lat": 24.1329994202,
      "lon": 49.0670013428,
      "tz": "Asia\/Riyadh"
  },
  "OEHW": {
      "icao": "OEHW",
      "iata": "",
      "name": "Hawtah Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 2091,
      "lat": 22.9678001404,
      "lon": 46.8995018005,
      "tz": "Asia\/Riyadh"
  },
  "OEJB": {
      "icao": "OEJB",
      "iata": "QJB",
      "name": "Jubail Airport",
      "city": "Jubail",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 26,
      "lat": 27.0389995575,
      "lon": 49.4051017761,
      "tz": "Asia\/Riyadh"
  },
  "OEJF": {
      "icao": "OEJF",
      "iata": "",
      "name": "King Faisal Naval Base",
      "city": "Jeddah",
      "state": "Makkah",
      "country": "SA",
      "elevation": 7,
      "lat": 21.3481006622,
      "lon": 39.1730003357,
      "tz": "Asia\/Riyadh"
  },
  "OEJN": {
      "icao": "OEJN",
      "iata": "JED",
      "name": "King Abdulaziz International Airport",
      "city": "Jeddah",
      "state": "Makkah",
      "country": "SA",
      "elevation": 48,
      "lat": 21.679599762,
      "lon": 39.15650177,
      "tz": "Asia\/Riyadh"
  },
  "OEKK": {
      "icao": "OEKK",
      "iata": "HBT",
      "name": "King Khaled Military City Airport",
      "city": "King Khaled Military City",
      "state": "",
      "country": "SA",
      "elevation": 1352,
      "lat": 27.9008998871,
      "lon": 45.5281982422,
      "tz": "Asia\/Riyadh"
  },
  "OEKM": {
      "icao": "OEKM",
      "iata": "KMX",
      "name": "King Khaled Air Base",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 6778,
      "lat": 18.2973003387,
      "lon": 42.8035011292,
      "tz": "Asia\/Riyadh"
  },
  "OEKR": {
      "icao": "OEKR",
      "iata": "",
      "name": "Khurais Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 1300,
      "lat": 25.0695991516,
      "lon": 48.1949005127,
      "tz": "Asia\/Riyadh"
  },
  "OEMA": {
      "icao": "OEMA",
      "iata": "MED",
      "name": "Prince Mohammad Bin Abdulaziz Airport",
      "city": "Medina",
      "state": "Al-Madinah-al-Munawwarah",
      "country": "SA",
      "elevation": 2151,
      "lat": 24.5534000397,
      "lon": 39.7051010132,
      "tz": "Asia\/Riyadh"
  },
  "OENG": {
      "icao": "OENG",
      "iata": "EAM",
      "name": "Nejran Airport",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 3982,
      "lat": 17.6114006042,
      "lon": 44.4192008972,
      "tz": "Asia\/Riyadh"
  },
  "OENR": {
      "icao": "OENR",
      "iata": "",
      "name": "Nariya Airport",
      "city": "Nariya",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 180,
      "lat": 27.5,
      "lon": 48.5,
      "tz": "Asia\/Riyadh"
  },
  "OEPA": {
      "icao": "OEPA",
      "iata": "AQI",
      "name": "Hafr Al Batin Airport",
      "city": "Qaisumah",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 1174,
      "lat": 28.3351993561,
      "lon": 46.1250991821,
      "tz": "Asia\/Riyadh"
  },
  "OEPC": {
      "icao": "OEPC",
      "iata": "",
      "name": "Pump Station 3 Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 1740,
      "lat": 25.1744995117,
      "lon": 47.4883995056,
      "tz": "Asia\/Riyadh"
  },
  "OEPF": {
      "icao": "OEPF",
      "iata": "",
      "name": "Pump Station 6 Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 2530,
      "lat": 24.7103004456,
      "lon": 44.9645004272,
      "tz": "Asia\/Riyadh"
  },
  "OEPI": {
      "icao": "OEPI",
      "iata": "",
      "name": "Pump Station 9 Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 3000,
      "lat": 24.2761001587,
      "lon": 42.143699646,
      "tz": "Asia\/Riyadh"
  },
  "OEPJ": {
      "icao": "OEPJ",
      "iata": "",
      "name": "Pump Station 10 Airport",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 2840,
      "lat": 24.1072998047,
      "lon": 41.0359992981,
      "tz": "Asia\/Riyadh"
  },
  "OEPK": {
      "icao": "OEPK",
      "iata": "",
      "name": "Ipsa 3 Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 1000,
      "lat": 27.9542007446,
      "lon": 46.7425994873,
      "tz": "Asia\/Riyadh"
  },
  "OEPS": {
      "icao": "OEPS",
      "iata": "AKH",
      "name": "Prince Sultan Air Base",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 1651,
      "lat": 24.0627002716,
      "lon": 47.5805015564,
      "tz": "Asia\/Riyadh"
  },
  "OERB": {
      "icao": "OERB",
      "iata": "",
      "name": "Rabigh Airport",
      "city": "",
      "state": "Makkah",
      "country": "SA",
      "elevation": 22,
      "lat": 22.7026004791,
      "lon": 39.0698013306,
      "tz": "Asia\/Riyadh"
  },
  "OERF": {
      "icao": "OERF",
      "iata": "RAH",
      "name": "Rafha Domestic Airport",
      "city": "Rafha",
      "state": "Northern-Borders",
      "country": "SA",
      "elevation": 1474,
      "lat": 29.6263999939,
      "lon": 43.4906005859,
      "tz": "Asia\/Riyadh"
  },
  "OERK": {
      "icao": "OERK",
      "iata": "RUH",
      "name": "King Khaled International Airport",
      "city": "Riyadh",
      "state": "",
      "country": "SA",
      "elevation": 2049,
      "lat": 24.9575996399,
      "lon": 46.6987991333,
      "tz": "Asia\/Riyadh"
  },
  "OERM": {
      "icao": "OERM",
      "iata": "",
      "name": "Ras Mishab Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 13,
      "lat": 28.0795993805,
      "lon": 48.611000061,
      "tz": "Asia\/Riyadh"
  },
  "OERR": {
      "icao": "OERR",
      "iata": "RAE",
      "name": "Arar Domestic Airport",
      "city": "Arar",
      "state": "Northern-Borders",
      "country": "SA",
      "elevation": 1813,
      "lat": 30.9066009521,
      "lon": 41.1381988525,
      "tz": "Asia\/Riyadh"
  },
  "OERT": {
      "icao": "OERT",
      "iata": "",
      "name": "Ras Tanura Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 6,
      "lat": 26.7231006622,
      "lon": 50.0307998657,
      "tz": "Asia\/Riyadh"
  },
  "OERY": {
      "icao": "OERY",
      "iata": "XXN",
      "name": "Riyadh Air Base",
      "city": "Riyadh",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 2082,
      "lat": 24.7098007202,
      "lon": 46.7252006531,
      "tz": "Asia\/Riyadh"
  },
  "OESB": {
      "icao": "OESB",
      "iata": "",
      "name": "Shaibah Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 300,
      "lat": 22.5144996643,
      "lon": 53.964099884,
      "tz": "Asia\/Riyadh"
  },
  "OESH": {
      "icao": "OESH",
      "iata": "SHW",
      "name": "Sharurah Airport",
      "city": "",
      "state": "",
      "country": "SA",
      "elevation": 2363,
      "lat": 17.4668998718,
      "lon": 47.1213989258,
      "tz": "Asia\/Riyadh"
  },
  "OESK": {
      "icao": "OESK",
      "iata": "AJF",
      "name": "Al-Jawf Domestic Airport",
      "city": "Al-Jawf",
      "state": "Al-Jawf",
      "country": "SA",
      "elevation": 2261,
      "lat": 29.7851009369,
      "lon": 40.0999984741,
      "tz": "Asia\/Riyadh"
  },
  "OESL": {
      "icao": "OESL",
      "iata": "SLF",
      "name": "Sulayel Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 2021,
      "lat": 20.4647006989,
      "lon": 45.6195983887,
      "tz": "Asia\/Riyadh"
  },
  "OETB": {
      "icao": "OETB",
      "iata": "TUU",
      "name": "Tabuk Airport",
      "city": "",
      "state": "Tabuk",
      "country": "SA",
      "elevation": 2551,
      "lat": 28.3654003143,
      "lon": 36.6189002991,
      "tz": "Asia\/Riyadh"
  },
  "OETF": {
      "icao": "OETF",
      "iata": "TIF",
      "name": "Taif Airport",
      "city": "",
      "state": "Makkah",
      "country": "SA",
      "elevation": 4848,
      "lat": 21.4834003448,
      "lon": 40.5443000793,
      "tz": "Asia\/Riyadh"
  },
  "OETH": {
      "icao": "OETH",
      "iata": "",
      "name": "Thumamah Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 1870,
      "lat": 25.2129993439,
      "lon": 46.6409988403,
      "tz": "Asia\/Riyadh"
  },
  "OETN": {
      "icao": "OETN",
      "iata": "",
      "name": "Ras Tanajib Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 30,
      "lat": 27.8677997589,
      "lon": 48.7691001892,
      "tz": "Asia\/Riyadh"
  },
  "OETR": {
      "icao": "OETR",
      "iata": "TUI",
      "name": "Turaif Domestic Airport",
      "city": "",
      "state": "Northern-Borders",
      "country": "SA",
      "elevation": 2803,
      "lat": 31.6926994324,
      "lon": 38.7312011719,
      "tz": "Asia\/Riyadh"
  },
  "OEUD": {
      "icao": "OEUD",
      "iata": "",
      "name": "Udhailiyah Airport",
      "city": "",
      "state": "Eastern-Province",
      "country": "SA",
      "elevation": 759,
      "lat": 25.1511001587,
      "lon": 49.3288002014,
      "tz": "Asia\/Riyadh"
  },
  "OEUM": {
      "icao": "OEUM",
      "iata": "",
      "name": "Umm Lajj Airport",
      "city": "Umm Lejj",
      "state": "Tabuk",
      "country": "SA",
      "elevation": 50,
      "lat": 25.1669998169,
      "lon": 37.3330001831,
      "tz": "Asia\/Riyadh"
  },
  "OEWD": {
      "icao": "OEWD",
      "iata": "WAE",
      "name": "Wadi Al Dawasir Airport",
      "city": "",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 2062,
      "lat": 20.5042991638,
      "lon": 45.1996002197,
      "tz": "Asia\/Riyadh"
  },
  "OEWJ": {
      "icao": "OEWJ",
      "iata": "EJH",
      "name": "Al Wajh Domestic Airport",
      "city": "Al Wajh",
      "state": "Tabuk",
      "country": "SA",
      "elevation": 66,
      "lat": 26.198600769,
      "lon": 36.476398468,
      "tz": "Asia\/Riyadh"
  },
  "OEYN": {
      "icao": "OEYN",
      "iata": "YNB",
      "name": "Yenbo Airport",
      "city": "",
      "state": "Al-Madinah-al-Munawwarah",
      "country": "SA",
      "elevation": 26,
      "lat": 24.1441993713,
      "lon": 38.0634002686,
      "tz": "Asia\/Riyadh"
  },
  "OEZL": {
      "icao": "OEZL",
      "iata": "ZUL",
      "name": "Zilfi Airport",
      "city": "Zilfi",
      "state": "Ar-Riya\u1e11",
      "country": "SA",
      "elevation": 1900,
      "lat": 26.3500003815,
      "lon": 44.8330001831,
      "tz": "Asia\/Riyadh"
  },
  "OG00": {
      "icao": "OG00",
      "iata": "",
      "name": "3 Rivers Recreation Area Airport",
      "city": "Culver",
      "state": "Oregon",
      "country": "US",
      "elevation": 2695,
      "lat": 44.5578994751,
      "lon": -121.4010009766,
      "tz": "America\/Los_Angeles"
  },
  "OG01": {
      "icao": "OG01",
      "iata": "",
      "name": "Dunning Vineyards Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 240,
      "lat": 44.5974998474,
      "lon": -123.233001709,
      "tz": "America\/Los_Angeles"
  },
  "OG02": {
      "icao": "OG02",
      "iata": "",
      "name": "Rieben Airport",
      "city": "Banks",
      "state": "Oregon",
      "country": "US",
      "elevation": 200,
      "lat": 45.6102981567,
      "lon": -123.0800018311,
      "tz": "America\/Los_Angeles"
  },
  "OG04": {
      "icao": "OG04",
      "iata": "",
      "name": "Rothrock Field",
      "city": "Adams",
      "state": "Oregon",
      "country": "US",
      "elevation": 1450,
      "lat": 45.7486000061,
      "lon": -118.6210021973,
      "tz": "America\/Los_Angeles"
  },
  "OG05": {
      "icao": "OG05",
      "iata": "",
      "name": "Sundance Meadows Airport",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3850,
      "lat": 43.9900016785,
      "lon": -121.2060012817,
      "tz": "America\/Los_Angeles"
  },
  "OG06": {
      "icao": "OG06",
      "iata": "",
      "name": "Table Rock Airport",
      "city": "Christmas Valley",
      "state": "Oregon",
      "country": "US",
      "elevation": 4305,
      "lat": 43.2114982605,
      "lon": -120.8430023193,
      "tz": "America\/Los_Angeles"
  },
  "OG07": {
      "icao": "OG07",
      "iata": "",
      "name": "Cove Side Ranch Port Airport",
      "city": "Cove",
      "state": "Oregon",
      "country": "US",
      "elevation": 2770,
      "lat": 45.3335990906,
      "lon": -117.8199996948,
      "tz": "America\/Los_Angeles"
  },
  "OG08": {
      "icao": "OG08",
      "iata": "",
      "name": "Curtis Airfield",
      "city": "Helix",
      "state": "Oregon",
      "country": "US",
      "elevation": 1805,
      "lat": 45.8086013794,
      "lon": -118.65599823,
      "tz": "America\/Los_Angeles"
  },
  "OG10": {
      "icao": "OG10",
      "iata": "",
      "name": "Mt Hope Airport",
      "city": "Lebanon",
      "state": "Oregon",
      "country": "US",
      "elevation": 400,
      "lat": 44.5572013855,
      "lon": -122.8259963989,
      "tz": "America\/Los_Angeles"
  },
  "OG12": {
      "icao": "OG12",
      "iata": "",
      "name": "Wilson Ranch Airport",
      "city": "Prineville",
      "state": "Oregon",
      "country": "US",
      "elevation": 4220,
      "lat": 44.2122001648,
      "lon": -120.5240020752,
      "tz": "America\/Los_Angeles"
  },
  "OG13": {
      "icao": "OG13",
      "iata": "",
      "name": "Fly By Night Airport",
      "city": "Ruch",
      "state": "Oregon",
      "country": "US",
      "elevation": 1660,
      "lat": 42.2461013794,
      "lon": -123.0699996948,
      "tz": "America\/Los_Angeles"
  },
  "OG14": {
      "icao": "OG14",
      "iata": "",
      "name": "Ponderosa Ranch Airport",
      "city": "Seneca",
      "state": "Oregon",
      "country": "US",
      "elevation": 4584,
      "lat": 44.0242996216,
      "lon": -118.8489990234,
      "tz": "America\/Los_Angeles"
  },
  "OG15": {
      "icao": "OG15",
      "iata": "",
      "name": "Sage Ranch Airport",
      "city": "Sisters",
      "state": "Oregon",
      "country": "US",
      "elevation": 3170,
      "lat": 44.2332000732,
      "lon": -121.3909988403,
      "tz": "America\/Los_Angeles"
  },
  "OG16": {
      "icao": "OG16",
      "iata": "",
      "name": "Jim's Airstrip",
      "city": "Halsey",
      "state": "Oregon",
      "country": "US",
      "elevation": 275,
      "lat": 44.382598877,
      "lon": -123.2129974365,
      "tz": "America\/Los_Angeles"
  },
  "OG17": {
      "icao": "OG17",
      "iata": "",
      "name": "Teed's Airport",
      "city": "Lakeview",
      "state": "Oregon",
      "country": "US",
      "elevation": 4930,
      "lat": 42.1848983765,
      "lon": -120.466003418,
      "tz": "America\/Los_Angeles"
  },
  "OG18": {
      "icao": "OG18",
      "iata": "",
      "name": "Lawen Strip",
      "city": "Lawen",
      "state": "Oregon",
      "country": "US",
      "elevation": 4130,
      "lat": 43.479598999,
      "lon": -118.8310012817,
      "tz": "America\/Los_Angeles"
  },
  "OG19": {
      "icao": "OG19",
      "iata": "",
      "name": "Bombay Farms Airport",
      "city": "Madras",
      "state": "Oregon",
      "country": "US",
      "elevation": 2385,
      "lat": 44.650100708,
      "lon": -121.2379989624,
      "tz": "America\/Los_Angeles"
  },
  "OG20": {
      "icao": "OG20",
      "iata": "",
      "name": "Fairways Airport",
      "city": "Oregon City",
      "state": "Oregon",
      "country": "US",
      "elevation": 525,
      "lat": 45.3207015991,
      "lon": -122.5510025024,
      "tz": "America\/Los_Angeles"
  },
  "OG21": {
      "icao": "OG21",
      "iata": "",
      "name": "Dry Creek Airpark",
      "city": "Prineville",
      "state": "Oregon",
      "country": "US",
      "elevation": 3625,
      "lat": 44.195098877,
      "lon": -120.8229980469,
      "tz": "America\/Los_Angeles"
  },
  "OG23": {
      "icao": "OG23",
      "iata": "",
      "name": "Poverty Hollow Airport",
      "city": "Sheridan",
      "state": "Oregon",
      "country": "US",
      "elevation": 330,
      "lat": 45.1571006775,
      "lon": -123.3909988403,
      "tz": "America\/Los_Angeles"
  },
  "OG24": {
      "icao": "OG24",
      "iata": "",
      "name": "Flying D Ranch Airport",
      "city": "Sutherlin",
      "state": "Oregon",
      "country": "US",
      "elevation": 730,
      "lat": 43.3886985779,
      "lon": -123.2220001221,
      "tz": "America\/Los_Angeles"
  },
  "OG25": {
      "icao": "OG25",
      "iata": "",
      "name": "Firefly Ranch Airfield",
      "city": "White City",
      "state": "Oregon",
      "country": "US",
      "elevation": 1310,
      "lat": 42.5111999512,
      "lon": -122.9240036011,
      "tz": "America\/Los_Angeles"
  },
  "OG26": {
      "icao": "OG26",
      "iata": "",
      "name": "Roscoes Airport",
      "city": "Willamina",
      "state": "Oregon",
      "country": "US",
      "elevation": 250,
      "lat": 45.0619010925,
      "lon": -123.486000061,
      "tz": "America\/Los_Angeles"
  },
  "OG27": {
      "icao": "OG27",
      "iata": "",
      "name": "Muddy Creek Airport",
      "city": "Haines",
      "state": "Oregon",
      "country": "US",
      "elevation": 3500,
      "lat": 44.9401016235,
      "lon": -118.0120010376,
      "tz": "America\/Los_Angeles"
  },
  "OG28": {
      "icao": "OG28",
      "iata": "",
      "name": "The Green Trees Ranch Airport",
      "city": "Scio",
      "state": "Oregon",
      "country": "US",
      "elevation": 1139,
      "lat": 44.6832008362,
      "lon": -122.7829971313,
      "tz": "America\/Los_Angeles"
  },
  "OG29": {
      "icao": "OG29",
      "iata": "",
      "name": "Mc Kinnon Airpark",
      "city": "Sandy",
      "state": "Oregon",
      "country": "US",
      "elevation": 658,
      "lat": 45.4306983948,
      "lon": -122.241996765,
      "tz": "America\/Los_Angeles"
  },
  "OG30": {
      "icao": "OG30",
      "iata": "",
      "name": "Aeroacres Airport",
      "city": "Oregon City",
      "state": "Oregon",
      "country": "US",
      "elevation": 420,
      "lat": 45.3165016174,
      "lon": -122.6050033569,
      "tz": "America\/Los_Angeles"
  },
  "OG31": {
      "icao": "OG31",
      "iata": "",
      "name": "Shady Cove Airpark",
      "city": "Shady Cove",
      "state": "Oregon",
      "country": "US",
      "elevation": 1504,
      "lat": 42.6082000732,
      "lon": -122.8259963989,
      "tz": "America\/Los_Angeles"
  },
  "OG33": {
      "icao": "OG33",
      "iata": "",
      "name": "Oregon Sky Ranch Airport",
      "city": "Milton\/Freewater",
      "state": "Oregon",
      "country": "US",
      "elevation": 1357,
      "lat": 45.8790016174,
      "lon": -118.4049987793,
      "tz": "America\/Los_Angeles"
  },
  "OG34": {
      "icao": "OG34",
      "iata": "",
      "name": "Meyer Riverside Airpark",
      "city": "Tigard",
      "state": "Oregon",
      "country": "US",
      "elevation": 252,
      "lat": 45.3997993469,
      "lon": -122.8290023804,
      "tz": "America\/Los_Angeles"
  },
  "OG36": {
      "icao": "OG36",
      "iata": "",
      "name": "Munson Airport",
      "city": "Junction City",
      "state": "Oregon",
      "country": "US",
      "elevation": 302,
      "lat": 44.2504005432,
      "lon": -123.2279968262,
      "tz": "America\/Los_Angeles"
  },
  "OG39": {
      "icao": "OG39",
      "iata": "",
      "name": "Longview Ranch Airport",
      "city": "Kimberly",
      "state": "Oregon",
      "country": "US",
      "elevation": 2080,
      "lat": 44.6617012024,
      "lon": -119.6520004272,
      "tz": "America\/Los_Angeles"
  },
  "OG40": {
      "icao": "OG40",
      "iata": "",
      "name": "Napier Ranch Airport",
      "city": "Roseburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 485,
      "lat": 43.2373008728,
      "lon": -123.4929962158,
      "tz": "America\/Los_Angeles"
  },
  "OG41": {
      "icao": "OG41",
      "iata": "",
      "name": "Nace Family Airstrip",
      "city": "Glendale",
      "state": "Oregon",
      "country": "US",
      "elevation": 1550,
      "lat": 42.7711982727,
      "lon": -123.3150024414,
      "tz": "America\/Los_Angeles"
  },
  "OG42": {
      "icao": "OG42",
      "iata": "",
      "name": "Quail Field",
      "city": "Pendleton",
      "state": "Oregon",
      "country": "US",
      "elevation": 1610,
      "lat": 45.6548995972,
      "lon": -118.5899963379,
      "tz": "America\/Los_Angeles"
  },
  "OG44": {
      "icao": "OG44",
      "iata": "",
      "name": "Watts Landing Airport",
      "city": "Amity",
      "state": "Oregon",
      "country": "US",
      "elevation": 155,
      "lat": 45.1534004211,
      "lon": -123.2369995117,
      "tz": "America\/Los_Angeles"
  },
  "OG45": {
      "icao": "OG45",
      "iata": "",
      "name": "Bald Mountain Airport",
      "city": "Baker City",
      "state": "Oregon",
      "country": "US",
      "elevation": 4640,
      "lat": 44.6169013977,
      "lon": -117.8720016479,
      "tz": "America\/Los_Angeles"
  },
  "OG48": {
      "icao": "OG48",
      "iata": "",
      "name": "Greer Airport",
      "city": "Coburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 360,
      "lat": 44.1958999634,
      "lon": -123.0729980469,
      "tz": "America\/Los_Angeles"
  },
  "OG49": {
      "icao": "OG49",
      "iata": "",
      "name": "Coca Cola Airport",
      "city": "Corvalis",
      "state": "Oregon",
      "country": "US",
      "elevation": 300,
      "lat": 44.4219017029,
      "lon": -123.2590026855,
      "tz": "America\/Los_Angeles"
  },
  "OG50": {
      "icao": "OG50",
      "iata": "",
      "name": "Cottonwood Creek Ranch Airport",
      "city": "Harper",
      "state": "Oregon",
      "country": "US",
      "elevation": 2555,
      "lat": 43.8471984863,
      "lon": -117.5739974976,
      "tz": "America\/Boise"
  },
  "OG51": {
      "icao": "OG51",
      "iata": "",
      "name": "Six Springs Ranch Airport",
      "city": "Madras",
      "state": "Oregon",
      "country": "US",
      "elevation": 3780,
      "lat": 44.4835014343,
      "lon": -120.9160003662,
      "tz": "America\/Los_Angeles"
  },
  "OG52": {
      "icao": "OG52",
      "iata": "",
      "name": "Jpm Airport",
      "city": "Monmouth",
      "state": "Oregon",
      "country": "US",
      "elevation": 260,
      "lat": 44.8391990662,
      "lon": -123.2590026855,
      "tz": "America\/Los_Angeles"
  },
  "OG53": {
      "icao": "OG53",
      "iata": "",
      "name": "Wildhorse Valley Airport",
      "city": "Andrews",
      "state": "Oregon",
      "country": "US",
      "elevation": 4225,
      "lat": 42.4878997803,
      "lon": -118.608001709,
      "tz": "America\/Los_Angeles"
  },
  "OG54": {
      "icao": "OG54",
      "iata": "",
      "name": "Shaniko Cattle Airport",
      "city": "Shaniko",
      "state": "Oregon",
      "country": "US",
      "elevation": 3300,
      "lat": 44.9600982666,
      "lon": -120.8850021362,
      "tz": "America\/Los_Angeles"
  },
  "OG63": {
      "icao": "OG63",
      "iata": "",
      "name": "Gilbert Airport",
      "city": "North Plains",
      "state": "Oregon",
      "country": "US",
      "elevation": 211,
      "lat": 45.648889,
      "lon": -123.039444,
      "tz": "America\/Los_Angeles"
  },
  "OH02": {
      "icao": "OH02",
      "iata": "",
      "name": "Eickmeier Airport",
      "city": "Mc Clure",
      "state": "Ohio",
      "country": "US",
      "elevation": 694,
      "lat": 41.2944984436,
      "lon": -83.9546966553,
      "tz": "America\/New_York"
  },
  "OH03": {
      "icao": "OH03",
      "iata": "",
      "name": "Woodworth Airport",
      "city": "Madison",
      "state": "Ohio",
      "country": "US",
      "elevation": 678,
      "lat": 41.792301178,
      "lon": -81.0581970215,
      "tz": "America\/New_York"
  },
  "OH07": {
      "icao": "OH07",
      "iata": "",
      "name": "Sunset Strip",
      "city": "Marlboro",
      "state": "Ohio",
      "country": "US",
      "elevation": 1190,
      "lat": 40.943901062,
      "lon": -81.2389984131,
      "tz": "America\/New_York"
  },
  "OH11": {
      "icao": "OH11",
      "iata": "",
      "name": "Arnold Airport",
      "city": "Harrisonville",
      "state": "Ohio",
      "country": "US",
      "elevation": 930,
      "lat": 39.1105995178,
      "lon": -82.0922012329,
      "tz": "America\/New_York"
  },
  "OH13": {
      "icao": "OH13",
      "iata": "",
      "name": "Bulick Field",
      "city": "Minerva",
      "state": "Ohio",
      "country": "US",
      "elevation": 1285,
      "lat": 40.7681007385,
      "lon": -81.0581970215,
      "tz": "America\/New_York"
  },
  "OH14": {
      "icao": "OH14",
      "iata": "",
      "name": "Brown's Lakeside Landings Airport",
      "city": "Sunbury",
      "state": "Ohio",
      "country": "US",
      "elevation": 970,
      "lat": 40.2792015076,
      "lon": -82.9168014526,
      "tz": "America\/New_York"
  },
  "OH15": {
      "icao": "OH15",
      "iata": "",
      "name": "Minerva Airport",
      "city": "Minerva",
      "state": "Ohio",
      "country": "US",
      "elevation": 1080,
      "lat": 40.7458992004,
      "lon": -81.1443023682,
      "tz": "America\/New_York"
  },
  "OH17": {
      "icao": "OH17",
      "iata": "",
      "name": "August Acres Airport",
      "city": "Center Village",
      "state": "Ohio",
      "country": "US",
      "elevation": 1100,
      "lat": 40.1626014709,
      "lon": -82.7817993164,
      "tz": "America\/New_York"
  },
  "OH18": {
      "icao": "OH18",
      "iata": "",
      "name": "Freefall Field",
      "city": "Sycamore",
      "state": "Ohio",
      "country": "US",
      "elevation": 885,
      "lat": 40.9324989319,
      "lon": -83.140296936,
      "tz": "America\/New_York"
  },
  "OH19": {
      "icao": "OH19",
      "iata": "",
      "name": "Hiltner Airport",
      "city": "North Creek",
      "state": "Ohio",
      "country": "US",
      "elevation": 725,
      "lat": 41.1549987793,
      "lon": -84.2148971558,
      "tz": "America\/New_York"
  },
  "OH20": {
      "icao": "OH20",
      "iata": "",
      "name": "Spring Valley Farm Airport",
      "city": "Marshallville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 40.9197998047,
      "lon": -81.7012023926,
      "tz": "America\/New_York"
  },
  "OH21": {
      "icao": "OH21",
      "iata": "",
      "name": "Horning Airport",
      "city": "Galion",
      "state": "Ohio",
      "country": "US",
      "elevation": 1190,
      "lat": 40.7361984253,
      "lon": -82.7527008057,
      "tz": "America\/New_York"
  },
  "OH22": {
      "icao": "OH22",
      "iata": "",
      "name": "Stoltzfus Airfield",
      "city": "Kidron",
      "state": "Ohio",
      "country": "US",
      "elevation": 1130,
      "lat": 40.7611999512,
      "lon": -81.7789993286,
      "tz": "America\/New_York"
  },
  "OH24": {
      "icao": "OH24",
      "iata": "",
      "name": "Brookfield Airpark",
      "city": "Brookfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1040,
      "lat": 41.2248001099,
      "lon": -80.5790023804,
      "tz": "America\/New_York"
  },
  "OH26": {
      "icao": "OH26",
      "iata": "",
      "name": "Birdland Airport",
      "city": "Thompson",
      "state": "Ohio",
      "country": "US",
      "elevation": 1160,
      "lat": 41.6563987732,
      "lon": -81.1062011719,
      "tz": "America\/New_York"
  },
  "OH27": {
      "icao": "OH27",
      "iata": "",
      "name": "Salem Lakefront Airport",
      "city": "Salem",
      "state": "Ohio",
      "country": "US",
      "elevation": 1170,
      "lat": 40.807800293,
      "lon": -80.8453979492,
      "tz": "America\/New_York"
  },
  "OH28": {
      "icao": "OH28",
      "iata": "",
      "name": "Donner Field",
      "city": "Leesburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 990,
      "lat": 39.3666992188,
      "lon": -83.5999984741,
      "tz": "America\/New_York"
  },
  "OH29": {
      "icao": "OH29",
      "iata": "",
      "name": "Petersburg Airport",
      "city": "Petersburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 1174,
      "lat": 40.9006004333,
      "lon": -80.5553970337,
      "tz": "America\/New_York"
  },
  "OH32": {
      "icao": "OH32",
      "iata": "",
      "name": "Stone Airport",
      "city": "Alvada",
      "state": "Ohio",
      "country": "US",
      "elevation": 835,
      "lat": 41.006401062,
      "lon": -83.4273986816,
      "tz": "America\/New_York"
  },
  "OH33": {
      "icao": "OH33",
      "iata": "",
      "name": "Humphries Rotordrome Airport",
      "city": "Amelia",
      "state": "Ohio",
      "country": "US",
      "elevation": 840,
      "lat": 39.0567016602,
      "lon": -84.2041015625,
      "tz": "America\/New_York"
  },
  "OH34": {
      "icao": "OH34",
      "iata": "",
      "name": "Leis Airport",
      "city": "Ansonia",
      "state": "Ohio",
      "country": "US",
      "elevation": 1015,
      "lat": 40.2058982849,
      "lon": -84.6615982056,
      "tz": "America\/New_York"
  },
  "OH35": {
      "icao": "OH35",
      "iata": "",
      "name": "Mission Field",
      "city": "Hallsville",
      "state": "Ohio",
      "country": "US",
      "elevation": 900,
      "lat": 39.4283981323,
      "lon": -82.8227005005,
      "tz": "America\/New_York"
  },
  "OH36": {
      "icao": "OH36",
      "iata": "",
      "name": "Riverside Airport",
      "city": "Zanesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 700,
      "lat": 39.9861984253,
      "lon": -81.9837036133,
      "tz": "America\/New_York"
  },
  "OH37": {
      "icao": "OH37",
      "iata": "",
      "name": "Glade STOLport",
      "city": "Beaver",
      "state": "Ohio",
      "country": "US",
      "elevation": 690,
      "lat": 39.0116996765,
      "lon": -82.7884979248,
      "tz": "America\/New_York"
  },
  "OH38": {
      "icao": "OH38",
      "iata": "",
      "name": "Blue Bird Airport",
      "city": "Belpre",
      "state": "Ohio",
      "country": "US",
      "elevation": 780,
      "lat": 39.3144989014,
      "lon": -81.6165008545,
      "tz": "America\/New_York"
  },
  "OH39": {
      "icao": "OH39",
      "iata": "",
      "name": "Pine Lake Airport",
      "city": "Berkshire",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 40.2347984314,
      "lon": -82.9192962646,
      "tz": "America\/New_York"
  },
  "OH41": {
      "icao": "OH41",
      "iata": "",
      "name": "Cutler Field",
      "city": "Powell",
      "state": "Ohio",
      "country": "US",
      "elevation": 945,
      "lat": 40.1930999756,
      "lon": -83.1119003296,
      "tz": "America\/New_York"
  },
  "OH42": {
      "icao": "OH42",
      "iata": "",
      "name": "Hawk's Nest Airport",
      "city": "Butler",
      "state": "Ohio",
      "country": "US",
      "elevation": 1260,
      "lat": 40.5401000977,
      "lon": -82.3937988281,
      "tz": "America\/New_York"
  },
  "OH43": {
      "icao": "OH43",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Canal Winchester",
      "state": "Ohio",
      "country": "US",
      "elevation": 800,
      "lat": 39.8334007263,
      "lon": -82.7332000732,
      "tz": "America\/New_York"
  },
  "OH44": {
      "icao": "OH44",
      "iata": "",
      "name": "Grover Airport",
      "city": "Cheshire",
      "state": "Ohio",
      "country": "US",
      "elevation": 945,
      "lat": 40.2206001282,
      "lon": -82.9462966919,
      "tz": "America\/New_York"
  },
  "OH45": {
      "icao": "OH45",
      "iata": "",
      "name": "Drake Airport",
      "city": "Bowling Green",
      "state": "Ohio",
      "country": "US",
      "elevation": 655,
      "lat": 41.4542007446,
      "lon": -83.5863037109,
      "tz": "America\/New_York"
  },
  "OH48": {
      "icao": "OH48",
      "iata": "",
      "name": "Alliance Airport",
      "city": "Alliance",
      "state": "Ohio",
      "country": "US",
      "elevation": 1077,
      "lat": 40.9056015015,
      "lon": -81.0651016235,
      "tz": "America\/New_York"
  },
  "OH50": {
      "icao": "OH50",
      "iata": "",
      "name": "Lindsey L.S. Airport",
      "city": "Circleville",
      "state": "Ohio",
      "country": "US",
      "elevation": 780,
      "lat": 39.6875991821,
      "lon": -83.0969009399,
      "tz": "America\/New_York"
  },
  "OH51": {
      "icao": "OH51",
      "iata": "",
      "name": "Miami Valley Career Technology Center Airport",
      "city": "Clayton",
      "state": "Ohio",
      "country": "US",
      "elevation": 930,
      "lat": 39.8433990479,
      "lon": -84.3312988281,
      "tz": "America\/New_York"
  },
  "OH57": {
      "icao": "OH57",
      "iata": "",
      "name": "Berlin Station Lndg Strip",
      "city": "Delaware",
      "state": "Ohio",
      "country": "US",
      "elevation": 955,
      "lat": 40.2588996887,
      "lon": -83.0118026733,
      "tz": "America\/New_York"
  },
  "OH59": {
      "icao": "OH59",
      "iata": "",
      "name": "Mc Intosh Airport",
      "city": "Farmersville",
      "state": "Ohio",
      "country": "US",
      "elevation": 914,
      "lat": 39.6958999634,
      "lon": -84.448600769,
      "tz": "America\/New_York"
  },
  "OH60": {
      "icao": "OH60",
      "iata": "",
      "name": "Gerker Airfield",
      "city": "Fort Jennings",
      "state": "Ohio",
      "country": "US",
      "elevation": 780,
      "lat": 40.9537010193,
      "lon": -84.2735977173,
      "tz": "America\/New_York"
  },
  "OH61": {
      "icao": "OH61",
      "iata": "",
      "name": "Amanda Airport",
      "city": "Amanda",
      "state": "Ohio",
      "country": "US",
      "elevation": 900,
      "lat": 39.6352996826,
      "lon": -82.7378997803,
      "tz": "America\/New_York"
  },
  "OH63": {
      "icao": "OH63",
      "iata": "",
      "name": "Jims Airport",
      "city": "Germantown",
      "state": "Ohio",
      "country": "US",
      "elevation": 955,
      "lat": 39.6001014709,
      "lon": -84.4166030884,
      "tz": "America\/New_York"
  },
  "OH64": {
      "icao": "OH64",
      "iata": "",
      "name": "Hummel Airport",
      "city": "Germantown",
      "state": "Ohio",
      "country": "US",
      "elevation": 695,
      "lat": 39.5931015015,
      "lon": -84.3438034058,
      "tz": "America\/New_York"
  },
  "OH65": {
      "icao": "OH65",
      "iata": "",
      "name": "Antique Acres Airpark",
      "city": "Goshen",
      "state": "Ohio",
      "country": "US",
      "elevation": 895,
      "lat": 39.2393989563,
      "lon": -84.1003036499,
      "tz": "America\/New_York"
  },
  "OH66": {
      "icao": "OH66",
      "iata": "",
      "name": "Obannon Creek Aerodrome",
      "city": "Goshen",
      "state": "Ohio",
      "country": "US",
      "elevation": 835,
      "lat": 39.2394981384,
      "lon": -84.1763000488,
      "tz": "America\/New_York"
  },
  "OH67": {
      "icao": "OH67",
      "iata": "",
      "name": "Sell Field",
      "city": "Bradford",
      "state": "Ohio",
      "country": "US",
      "elevation": 1018,
      "lat": 40.1206016541,
      "lon": -84.4535980225,
      "tz": "America\/New_York"
  },
  "OH68": {
      "icao": "OH68",
      "iata": "",
      "name": "Raylene Airport",
      "city": "Harrison",
      "state": "Ohio",
      "country": "US",
      "elevation": 590,
      "lat": 39.3000984192,
      "lon": -84.7499008179,
      "tz": "America\/New_York"
  },
  "OH71": {
      "icao": "OH71",
      "iata": "",
      "name": "Chapman Memorial Field",
      "city": "Centerburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 1180,
      "lat": 40.2947998047,
      "lon": -82.731300354,
      "tz": "America\/New_York"
  },
  "OH74": {
      "icao": "OH74",
      "iata": "",
      "name": "Kearns Airport",
      "city": "Johnstown",
      "state": "Ohio",
      "country": "US",
      "elevation": 1210,
      "lat": 40.0937004089,
      "lon": -82.716796875,
      "tz": "America\/New_York"
  },
  "OH77": {
      "icao": "OH77",
      "iata": "",
      "name": "Lumberton Airport",
      "city": "Wilmington",
      "state": "Ohio",
      "country": "US",
      "elevation": 1008,
      "lat": 39.5531005859,
      "lon": -83.8548965454,
      "tz": "America\/New_York"
  },
  "OH78": {
      "icao": "OH78",
      "iata": "",
      "name": "Swigart Airport",
      "city": "Laura",
      "state": "Ohio",
      "country": "US",
      "elevation": 1005,
      "lat": 39.9676017761,
      "lon": -84.4087982178,
      "tz": "America\/New_York"
  },
  "OH81": {
      "icao": "OH81",
      "iata": "",
      "name": "Graham's Landing Airport",
      "city": "Valley City",
      "state": "Ohio",
      "country": "US",
      "elevation": 890,
      "lat": 41.2256011963,
      "lon": -81.9414978027,
      "tz": "America\/New_York"
  },
  "OH82": {
      "icao": "OH82",
      "iata": "",
      "name": "Graham Farm Airport",
      "city": "Adams Mills",
      "state": "Ohio",
      "country": "US",
      "elevation": 725,
      "lat": 40.1534004211,
      "lon": -81.9735031128,
      "tz": "America\/New_York"
  },
  "OH86": {
      "icao": "OH86",
      "iata": "",
      "name": "Deeds Field",
      "city": "Sugar Grove",
      "state": "Ohio",
      "country": "US",
      "elevation": 800,
      "lat": 39.648399353,
      "lon": -82.5399017334,
      "tz": "America\/New_York"
  },
  "OH88": {
      "icao": "OH88",
      "iata": "",
      "name": "Roberts Airport",
      "city": "London",
      "state": "Ohio",
      "country": "US",
      "elevation": 1066,
      "lat": 39.8527984619,
      "lon": -83.4704971313,
      "tz": "America\/New_York"
  },
  "OH90": {
      "icao": "OH90",
      "iata": "",
      "name": "Hochstetler Airport",
      "city": "Lyons",
      "state": "Ohio",
      "country": "US",
      "elevation": 763,
      "lat": 41.7072982788,
      "lon": -84.234703064,
      "tz": "America\/Detroit"
  },
  "OH91": {
      "icao": "OH91",
      "iata": "",
      "name": "Dave Rice Ultralightport",
      "city": "Lima",
      "state": "Ohio",
      "country": "US",
      "elevation": 775,
      "lat": 40.70009995,
      "lon": -84.23439789,
      "tz": "America\/New_York"
  },
  "OH92": {
      "icao": "OH92",
      "iata": "",
      "name": "Porter Airport",
      "city": "Hopewell",
      "state": "Ohio",
      "country": "US",
      "elevation": 1070,
      "lat": 39.9542007446,
      "lon": -82.1831970215,
      "tz": "America\/New_York"
  },
  "OH93": {
      "icao": "OH93",
      "iata": "",
      "name": "Darby Airport",
      "city": "Milford Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 972,
      "lat": 40.1403007507,
      "lon": -83.3901977539,
      "tz": "America\/New_York"
  },
  "OH94": {
      "icao": "OH94",
      "iata": "",
      "name": "Knore Airport",
      "city": "Minford",
      "state": "Ohio",
      "country": "US",
      "elevation": 674,
      "lat": 38.837600708,
      "lon": -82.8348999023,
      "tz": "America\/New_York"
  },
  "OH95": {
      "icao": "OH95",
      "iata": "",
      "name": "Maplewood Orchard Airport",
      "city": "Morrow",
      "state": "Ohio",
      "country": "US",
      "elevation": 880,
      "lat": 39.3842010498,
      "lon": -84.168296814,
      "tz": "America\/New_York"
  },
  "OH96": {
      "icao": "OH96",
      "iata": "",
      "name": "Neals Airport",
      "city": "Mount Orab",
      "state": "Ohio",
      "country": "US",
      "elevation": 953,
      "lat": 39.0335998535,
      "lon": -83.886100769,
      "tz": "America\/New_York"
  },
  "OH97": {
      "icao": "OH97",
      "iata": "",
      "name": "Carcioppolo Field",
      "city": "Mantua",
      "state": "Ohio",
      "country": "US",
      "elevation": 1165,
      "lat": 41.3591995239,
      "lon": -81.2177963257,
      "tz": "America\/New_York"
  },
  "OH98": {
      "icao": "OH98",
      "iata": "",
      "name": "Lyttle Airport",
      "city": "New Albany",
      "state": "Ohio",
      "country": "US",
      "elevation": 1050,
      "lat": 40.0494995117,
      "lon": -82.7882003784,
      "tz": "America\/New_York"
  },
  "OH99": {
      "icao": "OH99",
      "iata": "",
      "name": "Planevue Airport",
      "city": "Newark",
      "state": "Ohio",
      "country": "US",
      "elevation": 935,
      "lat": 40.1139984131,
      "lon": -82.4581985474,
      "tz": "America\/New_York"
  },
  "OI01": {
      "icao": "OI01",
      "iata": "",
      "name": "Lazy-W Airport",
      "city": "Logan",
      "state": "Ohio",
      "country": "US",
      "elevation": 750,
      "lat": 39.5136985779,
      "lon": -82.4489974976,
      "tz": "America\/New_York"
  },
  "OI03": {
      "icao": "OI03",
      "iata": "",
      "name": "Garmcar Sw Airport",
      "city": "",
      "state": "Semnan",
      "country": "IR",
      "elevation": 2717,
      "lat": 35.1741981506,
      "lon": 52.323299408,
      "tz": "Asia\/Tehran"
  },
  "OI06": {
      "icao": "OI06",
      "iata": "",
      "name": "Wagner International Airport",
      "city": "West Milton",
      "state": "Ohio",
      "country": "US",
      "elevation": 935,
      "lat": 39.9914016724,
      "lon": -84.2916030884,
      "tz": "America\/New_York"
  },
  "OI07": {
      "icao": "OI07",
      "iata": "",
      "name": "Varns Farms Airport",
      "city": "Wooster",
      "state": "Ohio",
      "country": "US",
      "elevation": 1110,
      "lat": 40.827999115,
      "lon": -81.9796981812,
      "tz": "America\/New_York"
  },
  "OI08": {
      "icao": "OI08",
      "iata": "",
      "name": "Davies Air Field",
      "city": "Wooster",
      "state": "Ohio",
      "country": "US",
      "elevation": 1200,
      "lat": 40.738899231,
      "lon": -81.8716964722,
      "tz": "America\/New_York"
  },
  "OI11": {
      "icao": "OI11",
      "iata": "",
      "name": "Hibbetts Airport",
      "city": "Carrollton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1060,
      "lat": 40.637298584,
      "lon": -81.1126022339,
      "tz": "America\/New_York"
  },
  "OI12": {
      "icao": "OI12",
      "iata": "",
      "name": "Fly-A-Way Farm Airport",
      "city": "Delaware",
      "state": "Ohio",
      "country": "US",
      "elevation": 940,
      "lat": 40.2503013611,
      "lon": -82.9943008423,
      "tz": "America\/New_York"
  },
  "OI13": {
      "icao": "OI13",
      "iata": "",
      "name": "Mahmudabad Airport",
      "city": "",
      "state": "Fars",
      "country": "IR",
      "elevation": 6970,
      "lat": 30,
      "lon": 53.7114982605,
      "tz": "Asia\/Tehran"
  },
  "OI19": {
      "icao": "OI19",
      "iata": "",
      "name": "Firuzabad Airport",
      "city": "",
      "state": "Tehran",
      "country": "IR",
      "elevation": 3258,
      "lat": 35.5298995972,
      "lon": 51.5057983398,
      "tz": "Asia\/Tehran"
  },
  "OI1C": {
      "icao": "OI1C",
      "iata": "",
      "name": "Mehtar Kalateh Airport",
      "city": "",
      "state": "Golestan",
      "country": "IR",
      "elevation": -64,
      "lat": 36.8568992615,
      "lon": 54.2022018433,
      "tz": "Asia\/Tehran"
  },
  "OI20": {
      "icao": "OI20",
      "iata": "",
      "name": "Bahregan Airport",
      "city": "",
      "state": "Bushehr",
      "country": "IR",
      "elevation": 20,
      "lat": 29.8400993347,
      "lon": 50.2727012634,
      "tz": "Asia\/Tehran"
  },
  "OI21": {
      "icao": "OI21",
      "iata": "",
      "name": "Semnan New Airport",
      "city": "",
      "state": "Semnan",
      "country": "IR",
      "elevation": 3659,
      "lat": 35.3894004822,
      "lon": 53.6713981628,
      "tz": "Asia\/Tehran"
  },
  "OI22": {
      "icao": "OI22",
      "iata": "",
      "name": "Carroll's Airport",
      "city": "Elyria",
      "state": "Ohio",
      "country": "US",
      "elevation": 755,
      "lat": 41.3339004517,
      "lon": -82.1026000977,
      "tz": "America\/New_York"
  },
  "OI24": {
      "icao": "OI24",
      "iata": "",
      "name": "Gonbad Ghabus Airport",
      "city": "",
      "state": "Golestan",
      "country": "IR",
      "elevation": 107,
      "lat": 37.2461013794,
      "lon": 55.0959014893,
      "tz": "Asia\/Tehran"
  },
  "OI25": {
      "icao": "OI25",
      "iata": "",
      "name": "Ross Field",
      "city": "Greenfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 960,
      "lat": 39.3652992249,
      "lon": -83.3544006348,
      "tz": "America\/New_York"
  },
  "OI26": {
      "icao": "OI26",
      "iata": "",
      "name": "Hillcrest Airport",
      "city": "Oxford",
      "state": "Ohio",
      "country": "US",
      "elevation": 890,
      "lat": 39.5055999756,
      "lon": -84.6987991333,
      "tz": "America\/New_York"
  },
  "OI27": {
      "icao": "OI27",
      "iata": "",
      "name": "Checkpoint Charlie Airport",
      "city": "Lowell",
      "state": "Ohio",
      "country": "US",
      "elevation": 640,
      "lat": 39.5567016602,
      "lon": -81.5696029663,
      "tz": "America\/New_York"
  },
  "OI28": {
      "icao": "OI28",
      "iata": "",
      "name": "Lanker Airport",
      "city": "Mount Gilead",
      "state": "Ohio",
      "country": "US",
      "elevation": 1440,
      "lat": 40.5839004517,
      "lon": -82.6792984009,
      "tz": "America\/New_York"
  },
  "OI29": {
      "icao": "OI29",
      "iata": "",
      "name": "Yellowbird Farm Airport",
      "city": "Otway",
      "state": "Ohio",
      "country": "US",
      "elevation": 610,
      "lat": 38.8541984558,
      "lon": -83.2304992676,
      "tz": "America\/New_York"
  },
  "OI31": {
      "icao": "OI31",
      "iata": "",
      "name": "Pheasant Run Airport",
      "city": "Leroy",
      "state": "Ohio",
      "country": "US",
      "elevation": 960,
      "lat": 41.7098007202,
      "lon": -81.1248016357,
      "tz": "America\/New_York"
  },
  "OI32": {
      "icao": "OI32",
      "iata": "",
      "name": "Stoney's Airport",
      "city": "Ravenna",
      "state": "Ohio",
      "country": "US",
      "elevation": 1105,
      "lat": 41.1680984497,
      "lon": -81.287902832,
      "tz": "America\/New_York"
  },
  "OI34": {
      "icao": "OI34",
      "iata": "",
      "name": "Fricke Airport",
      "city": "Spring Valley",
      "state": "Ohio",
      "country": "US",
      "elevation": 905,
      "lat": 39.6130981445,
      "lon": -83.992401123,
      "tz": "America\/New_York"
  },
  "OI36": {
      "icao": "OI36",
      "iata": "",
      "name": "Farpoint Airfield",
      "city": "Gratis",
      "state": "Ohio",
      "country": "US",
      "elevation": 990,
      "lat": 39.6225013733,
      "lon": -84.4831008911,
      "tz": "America\/New_York"
  },
  "OI38": {
      "icao": "OI38",
      "iata": "",
      "name": "Hillview Airstrip",
      "city": "East Liberty",
      "state": "Ohio",
      "country": "US",
      "elevation": 1400,
      "lat": 40.3087005615,
      "lon": -83.615196228,
      "tz": "America\/New_York"
  },
  "OI39": {
      "icao": "OI39",
      "iata": "",
      "name": "Vensil Farms Airport",
      "city": "Frazeysburg",
      "state": "Ohio",
      "country": "US",
      "elevation": 760,
      "lat": 40.1067008972,
      "lon": -82.1321029663,
      "tz": "America\/New_York"
  },
  "OI40": {
      "icao": "OI40",
      "iata": "",
      "name": "Hallelujah Field",
      "city": "Goshen",
      "state": "Ohio",
      "country": "US",
      "elevation": 890,
      "lat": 39.2025985718,
      "lon": -84.1046981812,
      "tz": "America\/New_York"
  },
  "OI41": {
      "icao": "OI41",
      "iata": "",
      "name": "Far View Airport",
      "city": "Hiram",
      "state": "Ohio",
      "country": "US",
      "elevation": 1300,
      "lat": 41.295728,
      "lon": -81.158165,
      "tz": "America\/New_York"
  },
  "OI42": {
      "icao": "OI42",
      "iata": "",
      "name": "D. A. Chandler Airport",
      "city": "New London",
      "state": "Ohio",
      "country": "US",
      "elevation": 950,
      "lat": 41.1328010559,
      "lon": -82.4607009888,
      "tz": "America\/New_York"
  },
  "OI45": {
      "icao": "OI45",
      "iata": "",
      "name": "Shootz Field",
      "city": "Richfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1250,
      "lat": 41.2650985718,
      "lon": -81.6586990356,
      "tz": "America\/New_York"
  },
  "OI46": {
      "icao": "OI46",
      "iata": "",
      "name": "Sharondale Field",
      "city": "Sharon Center",
      "state": "Ohio",
      "country": "US",
      "elevation": 1180,
      "lat": 41.1151008606,
      "lon": -81.7151031494,
      "tz": "America\/New_York"
  },
  "OI49": {
      "icao": "OI49",
      "iata": "",
      "name": "Flying Acres Airport",
      "city": "Delaware",
      "state": "Ohio",
      "country": "US",
      "elevation": 975,
      "lat": 40.3838996887,
      "lon": -83.1069030762,
      "tz": "America\/New_York"
  },
  "OI52": {
      "icao": "OI52",
      "iata": "",
      "name": "Lyons Field",
      "city": "Jamestown",
      "state": "Ohio",
      "country": "US",
      "elevation": 1045,
      "lat": 39.6250991821,
      "lon": -83.7833023071,
      "tz": "America\/New_York"
  },
  "OI55": {
      "icao": "OI55",
      "iata": "",
      "name": "Scheibe Field",
      "city": "Wooster",
      "state": "Ohio",
      "country": "US",
      "elevation": 1010,
      "lat": 40.7509002686,
      "lon": -82.0043029785,
      "tz": "America\/New_York"
  },
  "OI56": {
      "icao": "OI56",
      "iata": "",
      "name": "Lee's Dogpatch Airport",
      "city": "Kirkersville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1055,
      "lat": 39.95320129,
      "lon": -82.64070129,
      "tz": "America\/New_York"
  },
  "OI57": {
      "icao": "OI57",
      "iata": "",
      "name": "Mc Kinney Field",
      "city": "Hamersville",
      "state": "Ohio",
      "country": "US",
      "elevation": 970,
      "lat": 38.9269981384,
      "lon": -83.9885025024,
      "tz": "America\/New_York"
  },
  "OI58": {
      "icao": "OI58",
      "iata": "",
      "name": "Lockeridge Airport",
      "city": "Canton",
      "state": "Ohio",
      "country": "US",
      "elevation": 1090,
      "lat": 40.8417015076,
      "lon": -81.4378967285,
      "tz": "America\/New_York"
  },
  "OI59": {
      "icao": "OI59",
      "iata": "",
      "name": "Miller Airport",
      "city": "Chester",
      "state": "Ohio",
      "country": "US",
      "elevation": 690,
      "lat": 39.1316986084,
      "lon": -81.9318008423,
      "tz": "America\/New_York"
  },
  "OI60": {
      "icao": "OI60",
      "iata": "",
      "name": "Gorman Airport",
      "city": "Mansfield",
      "state": "Ohio",
      "country": "US",
      "elevation": 1230,
      "lat": 40.7547988892,
      "lon": -82.3916015625,
      "tz": "America\/New_York"
  },
  "OI62": {
      "icao": "OI62",
      "iata": "",
      "name": "Rataiczak Airport",
      "city": "Russell",
      "state": "Ohio",
      "country": "US",
      "elevation": 1200,
      "lat": 41.476398468,
      "lon": -81.3171005249,
      "tz": "America\/New_York"
  },
  "OI64": {
      "icao": "OI64",
      "iata": "",
      "name": "Bush Field",
      "city": "Thompson",
      "state": "Ohio",
      "country": "US",
      "elevation": 1145,
      "lat": 41.6805992126,
      "lon": -81.0301971436,
      "tz": "America\/New_York"
  },
  "OI66": {
      "icao": "OI66",
      "iata": "",
      "name": "Eibling Circle E Airport",
      "city": "Dola",
      "state": "Ohio",
      "country": "US",
      "elevation": 980,
      "lat": 40.7408981323,
      "lon": -83.6709976196,
      "tz": "America\/New_York"
  },
  "OI67": {
      "icao": "OI67",
      "iata": "",
      "name": "Mc Clain Field",
      "city": "Mechanicstown",
      "state": "Ohio",
      "country": "US",
      "elevation": 1250,
      "lat": 40.6083984375,
      "lon": -80.9330978394,
      "tz": "America\/New_York"
  },
  "OI68": {
      "icao": "OI68",
      "iata": "",
      "name": "Hilty Field",
      "city": "Rittman",
      "state": "Ohio",
      "country": "US",
      "elevation": 1000,
      "lat": 40.9413986206,
      "lon": -81.7977981567,
      "tz": "America\/New_York"
  },
  "OI69": {
      "icao": "OI69",
      "iata": "",
      "name": "Blackacre Farm Airport",
      "city": "Painesville",
      "state": "Ohio",
      "country": "US",
      "elevation": 640,
      "lat": 41.7784004211,
      "lon": -81.1669998169,
      "tz": "America\/New_York"
  },
  "OI72": {
      "icao": "OI72",
      "iata": "",
      "name": "Marvin Thiel Field",
      "city": "Blakeslee",
      "state": "Ohio",
      "country": "US",
      "elevation": 859,
      "lat": 41.5222015381,
      "lon": -84.7208023071,
      "tz": "America\/New_York"
  },
  "OI73": {
      "icao": "OI73",
      "iata": "",
      "name": "Williamson Airport",
      "city": "Cedarville",
      "state": "Ohio",
      "country": "US",
      "elevation": 1060,
      "lat": 39.712600708,
      "lon": -83.8038024902,
      "tz": "America\/New_York"
  },
  "OI77": {
      "icao": "OI77",
      "iata": "",
      "name": "Mc Ardle Airport",
      "city": "Fostoria",
      "state": "Ohio",
      "country": "US",
      "elevation": 715,
      "lat": 41.2159004211,
      "lon": -83.5579986572,
      "tz": "America\/New_York"
  },
  "OI78": {
      "icao": "OI78",
      "iata": "",
      "name": "Diebleys Airport",
      "city": "Fostoria",
      "state": "Ohio",
      "country": "US",
      "elevation": 823,
      "lat": 41.0848007202,
      "lon": -83.4363021851,
      "tz": "America\/New_York"
  },
  "OI81": {
      "icao": "OI81",
      "iata": "",
      "name": "Mollica Airport",
      "city": "Austintown",
      "state": "Ohio",
      "country": "US",
      "elevation": 1170,
      "lat": 41.0713996887,
      "lon": -80.7585983276,
      "tz": "America\/New_York"
  },
  "OI82": {
      "icao": "OI82",
      "iata": "",
      "name": "Russ Airport",
      "city": "Jackson",
      "state": "Ohio",
      "country": "US",
      "elevation": 800,
      "lat": 38.9122009277,
      "lon": -82.724899292,
      "tz": "America\/New_York"
  },
  "OI84": {
      "icao": "OI84",
      "iata": "",
      "name": "King Knoll Airport",
      "city": "Okeana",
      "state": "Ohio",
      "country": "US",
      "elevation": 855,
      "lat": 39.3871002197,
      "lon": -84.7978973389,
      "tz": "America\/New_York"
  },
  "OI85": {
      "icao": "OI85",
      "iata": "",
      "name": "Mill Creek Airport",
      "city": "Ostrander",
      "state": "Ohio",
      "country": "US",
      "elevation": 960,
      "lat": 40.2683982849,
      "lon": -83.2216033936,
      "tz": "America\/New_York"
  },
  "OI88": {
      "icao": "OI88",
      "iata": "",
      "name": "Mindzak Airfield",
      "city": "Greenwich",
      "state": "Ohio",
      "country": "US",
      "elevation": 1109,
      "lat": 41.0111999512,
      "lon": -82.4359970093,
      "tz": "America\/New_York"
  },
  "OI89": {
      "icao": "OI89",
      "iata": "",
      "name": "Mite Airport",
      "city": "Camden",
      "state": "Ohio",
      "country": "US",
      "elevation": 866,
      "lat": 39.6495018005,
      "lon": -84.6299972534,
      "tz": "America\/New_York"
  },
  "OI91": {
      "icao": "OI91",
      "iata": "",
      "name": "Hammond Airport",
      "city": "Yellow Springs",
      "state": "Ohio",
      "country": "US",
      "elevation": 990,
      "lat": 39.7797012329,
      "lon": -83.9092025757,
      "tz": "America\/New_York"
  },
  "OI92": {
      "icao": "OI92",
      "iata": "",
      "name": "Conklin Airport",
      "city": "Bowling Green",
      "state": "Ohio",
      "country": "US",
      "elevation": 675,
      "lat": 41.3917007446,
      "lon": -83.7033004761,
      "tz": "America\/New_York"
  },
  "OI99": {
      "icao": "OI99",
      "iata": "",
      "name": "Mulholland Airport",
      "city": "Celina",
      "state": "Ohio",
      "country": "US",
      "elevation": 875,
      "lat": 40.5733985901,
      "lon": -84.4899978638,
      "tz": "America\/New_York"
  },
  "OIAA": {
      "icao": "OIAA",
      "iata": "ABD",
      "name": "Abadan Airport",
      "city": "Abadan",
      "state": "Khuzestan",
      "country": "IR",
      "elevation": 10,
      "lat": 30.371099472,
      "lon": 48.2282981873,
      "tz": "Asia\/Tehran"
  },
  "OIAD": {
      "icao": "OIAD",
      "iata": "DEF",
      "name": "Dezful Airport",
      "city": "",
      "state": "Khuzestan",
      "country": "IR",
      "elevation": 474,
      "lat": 32.4343986511,
      "lon": 48.3975982666,
      "tz": "Asia\/Tehran"
  },
  "OIAG": {
      "icao": "OIAG",
      "iata": "AKW",
      "name": "Aghajari Airport",
      "city": "",
      "state": "Khuzestan",
      "country": "IR",
      "elevation": 88,
      "lat": 30.7444000244,
      "lon": 49.6772003174,
      "tz": "Asia\/Tehran"
  },
  "OIAH": {
      "icao": "OIAH",
      "iata": "GCH",
      "name": "Gachsaran Airport",
      "city": "",
      "state": "Kohgiluyeh-va-Buyer-A\u1e29mad",
      "country": "IR",
      "elevation": 2414,
      "lat": 30.337600708,
      "lon": 50.827999115,
      "tz": "Asia\/Tehran"
  },
  "OIAI": {
      "icao": "OIAI",
      "iata": "",
      "name": "Shahid Asyaee Airport",
      "city": "",
      "state": "Khuzestan",
      "country": "IR",
      "elevation": 1206,
      "lat": 32.0023994446,
      "lon": 49.270401001,
      "tz": "Asia\/Tehran"
  },
  "OIAJ": {
      "icao": "OIAJ",
      "iata": "OMI",
      "name": "Omidiyeh Airport",
      "city": "Omidiyeh",
      "state": "Khuzestan",
      "country": "IR",
      "elevation": 85,
      "lat": 30.8351993561,
      "lon": 49.5349006653,
      "tz": "Asia\/Tehran"
  },
  "OIAM": {
      "icao": "OIAM",
      "iata": "MRX",
      "name": "Mahshahr Airport",
      "city": "",
      "state": "Khuzestan",
      "country": "IR",
      "elevation": 8,
      "lat": 30.5562000275,
      "lon": 49.1519012451,
      "tz": "Asia\/Tehran"
  },
  "OIAW": {
      "icao": "OIAW",
      "iata": "AWZ",
      "name": "Ahwaz Airport",
      "city": "Ahwaz",
      "state": "Khuzestan",
      "country": "IR",
      "elevation": 66,
      "lat": 31.3374004364,
      "lon": 48.7620010376,
      "tz": "Asia\/Tehran"
  },
  "OIBA": {
      "icao": "OIBA",
      "iata": "AEU",
      "name": "Abumusa Island Airport",
      "city": "",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 23,
      "lat": 25.8756999969,
      "lon": 55.033000946,
      "tz": "Asia\/Tehran"
  },
  "OIBB": {
      "icao": "OIBB",
      "iata": "BUZ",
      "name": "Bushehr Airport",
      "city": "Bushehr",
      "state": "Bushehr",
      "country": "IR",
      "elevation": 68,
      "lat": 28.9447994232,
      "lon": 50.8345985413,
      "tz": "Asia\/Tehran"
  },
  "OIBH": {
      "icao": "OIBH",
      "iata": "IAQ",
      "name": "Bastak Airport",
      "city": "",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 1350,
      "lat": 27.2126998901,
      "lon": 54.3185997009,
      "tz": "Asia\/Tehran"
  },
  "OIBI": {
      "icao": "OIBI",
      "iata": "AOY",
      "name": "Asaloyeh Airport",
      "city": "Asaloyeh",
      "state": "",
      "country": "IR",
      "elevation": 15,
      "lat": 27.4813995361,
      "lon": 52.6155014038,
      "tz": "Asia\/Tehran"
  },
  "OIBJ": {
      "icao": "OIBJ",
      "iata": "KNR",
      "name": "Jam Airport",
      "city": "Kangan",
      "state": "Bushehr",
      "country": "IR",
      "elevation": 2173,
      "lat": 27.8204994202,
      "lon": 52.3521995544,
      "tz": "Asia\/Tehran"
  },
  "OIBK": {
      "icao": "OIBK",
      "iata": "KIH",
      "name": "Kish International Airport",
      "city": "Kish Island",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 101,
      "lat": 26.5261993408,
      "lon": 53.9802017212,
      "tz": "Asia\/Tehran"
  },
  "OIBL": {
      "icao": "OIBL",
      "iata": "BDH",
      "name": "Bandar Lengeh Airport",
      "city": "Bandar Lengeh",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 67,
      "lat": 26.531999588,
      "lon": 54.824798584,
      "tz": "Asia\/Tehran"
  },
  "OIBN": {
      "icao": "OIBN",
      "iata": "",
      "name": "Borazjan Airport",
      "city": "Borazjan",
      "state": "Bushehr",
      "country": "IR",
      "elevation": 265,
      "lat": 29.2666664124,
      "lon": 51.2000007629,
      "tz": "Asia\/Tehran"
  },
  "OIBP": {
      "icao": "OIBP",
      "iata": "YEH",
      "name": "Persian Gulf International Airport",
      "city": "Asalouyeh",
      "state": "Bushehr",
      "country": "IR",
      "elevation": 27,
      "lat": 27.3796005249,
      "lon": 52.737701416,
      "tz": "Asia\/Tehran"
  },
  "OIBQ": {
      "icao": "OIBQ",
      "iata": "KHK",
      "name": "Khark Island Airport",
      "city": "",
      "state": "Bushehr",
      "country": "IR",
      "elevation": 17,
      "lat": 29.2602996826,
      "lon": 50.3238983154,
      "tz": "Asia\/Tehran"
  },
  "OIBS": {
      "icao": "OIBS",
      "iata": "SXI",
      "name": "Sirri Island Airport",
      "city": "",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 43,
      "lat": 25.9088993073,
      "lon": 54.5393981934,
      "tz": "Asia\/Tehran"
  },
  "OIBV": {
      "icao": "OIBV",
      "iata": "LVP",
      "name": "Lavan Island Airport",
      "city": "",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 76,
      "lat": 26.810300827,
      "lon": 53.356300354,
      "tz": "Asia\/Tehran"
  },
  "OICB": {
      "icao": "OICB",
      "iata": "",
      "name": "Baneh Airport",
      "city": "Baneh",
      "state": "Kordestan",
      "country": "IR",
      "elevation": 4911,
      "lat": 35.987701416,
      "lon": 45.8470993042,
      "tz": "Asia\/Tehran"
  },
  "OICC": {
      "icao": "OICC",
      "iata": "KSH",
      "name": "Shahid Ashrafi Esfahani Airport",
      "city": "Kermanshah",
      "state": "Kermanshah",
      "country": "IR",
      "elevation": 4307,
      "lat": 34.3459014893,
      "lon": 47.1581001282,
      "tz": "Asia\/Tehran"
  },
  "OICD": {
      "icao": "OICD",
      "iata": "",
      "name": "Abdanan Airport",
      "city": "Abdanan",
      "state": "Ilam",
      "country": "IR",
      "elevation": 2600,
      "lat": 32.9345016479,
      "lon": 47.4833984375,
      "tz": "Asia\/Tehran"
  },
  "OICG": {
      "icao": "OICG",
      "iata": "",
      "name": "Ghasre-Shirin Airport",
      "city": "Ghasre-Shirin",
      "state": "Kermanshah",
      "country": "IR",
      "elevation": 0,
      "lat": 34.5166664124,
      "lon": 45.5666656494,
      "tz": "Asia\/Tehran"
  },
  "OICI": {
      "icao": "OICI",
      "iata": "IIL",
      "name": "Ilam Airport",
      "city": "Ilam",
      "state": "Ilam",
      "country": "IR",
      "elevation": 4404,
      "lat": 33.5866012573,
      "lon": 46.404800415,
      "tz": "Asia\/Tehran"
  },
  "OICK": {
      "icao": "OICK",
      "iata": "KHD",
      "name": "Khoram Abad Airport",
      "city": "",
      "state": "Lorestan",
      "country": "IR",
      "elevation": 3782,
      "lat": 33.4353981018,
      "lon": 48.2829017639,
      "tz": "Asia\/Tehran"
  },
  "OICS": {
      "icao": "OICS",
      "iata": "SDG",
      "name": "Sanandaj Airport",
      "city": "",
      "state": "Kordestan",
      "country": "IR",
      "elevation": 4522,
      "lat": 35.2458992004,
      "lon": 47.0092010498,
      "tz": "Asia\/Tehran"
  },
  "OICZ": {
      "icao": "OICZ",
      "iata": "",
      "name": "Aligoodarz Airport",
      "city": "",
      "state": "Lorestan",
      "country": "IR",
      "elevation": 6505,
      "lat": 33.3835983276,
      "lon": 49.6963996887,
      "tz": "Asia\/Tehran"
  },
  "OIFE": {
      "icao": "OIFE",
      "iata": "IFH",
      "name": "Hesa Airport",
      "city": "Hesa",
      "state": "Isfahan",
      "country": "IR",
      "elevation": 5256,
      "lat": 32.9289016724,
      "lon": 51.5611000061,
      "tz": "Asia\/Tehran"
  },
  "OIFH": {
      "icao": "OIFH",
      "iata": "",
      "name": "Shahid Vatan Pour Air Base",
      "city": "",
      "state": "Isfahan",
      "country": "IR",
      "elevation": 5310,
      "lat": 32.5670013428,
      "lon": 51.6916007996,
      "tz": "Asia\/Tehran"
  },
  "OIFK": {
      "icao": "OIFK",
      "iata": "KKS",
      "name": "Kashan Airport",
      "city": "",
      "state": "Isfahan",
      "country": "IR",
      "elevation": 3465,
      "lat": 33.8953018188,
      "lon": 51.5769996643,
      "tz": "Asia\/Tehran"
  },
  "OIFM": {
      "icao": "OIFM",
      "iata": "IFN",
      "name": "Esfahan Shahid Beheshti International Airport",
      "city": "Isfahan",
      "state": "Isfahan",
      "country": "IR",
      "elevation": 5059,
      "lat": 32.7508010864,
      "lon": 51.8613014221,
      "tz": "Asia\/Tehran"
  },
  "OIFP": {
      "icao": "OIFP",
      "iata": "",
      "name": "Badr Air Base",
      "city": "Esfahan",
      "state": "Isfahan",
      "country": "IR",
      "elevation": 5242,
      "lat": 32.6211013794,
      "lon": 51.6969985962,
      "tz": "Asia\/Tehran"
  },
  "OIFS": {
      "icao": "OIFS",
      "iata": "CQD",
      "name": "Shahrekord Airport",
      "city": "Shahrekord",
      "state": "Chaharmahal-and-Bakhtiari",
      "country": "IR",
      "elevation": 6723,
      "lat": 32.2971992493,
      "lon": 50.8422012329,
      "tz": "Asia\/Tehran"
  },
  "OIFV": {
      "icao": "OIFV",
      "iata": "",
      "name": "Zarrinshahr Airport",
      "city": "Zarrinshahr",
      "state": "Isfahan",
      "country": "IR",
      "elevation": 5650,
      "lat": 32.3274002075,
      "lon": 51.3773002625,
      "tz": "Asia\/Tehran"
  },
  "OIGG": {
      "icao": "OIGG",
      "iata": "RAS",
      "name": "Sardar-e-Jangal Airport",
      "city": "Rasht",
      "state": "Gilan",
      "country": "IR",
      "elevation": -40,
      "lat": 37.323333,
      "lon": 49.617778,
      "tz": "Asia\/Tehran"
  },
  "OIHH": {
      "icao": "OIHH",
      "iata": "HDM",
      "name": "Hamadan Airport",
      "city": "Hamadan",
      "state": "Hamadan",
      "country": "IR",
      "elevation": 5755,
      "lat": 34.8692016602,
      "lon": 48.5525016785,
      "tz": "Asia\/Tehran"
  },
  "OIHM": {
      "icao": "OIHM",
      "iata": "",
      "name": "Malayer Airport",
      "city": "Malayer",
      "state": "Hamadan",
      "country": "IR",
      "elevation": 5662,
      "lat": 34.2833328247,
      "lon": 48.8166656494,
      "tz": "Asia\/Tehran"
  },
  "OIHR": {
      "icao": "OIHR",
      "iata": "AJK",
      "name": "Arak Airport",
      "city": "Araak",
      "state": "Markazi",
      "country": "IR",
      "elevation": 5440,
      "lat": 34.1380996704,
      "lon": 49.8473014832,
      "tz": "Asia\/Tehran"
  },
  "OIHS": {
      "icao": "OIHS",
      "iata": "NUJ",
      "name": "Hamadan Air Base",
      "city": "Hamadan",
      "state": "Hamadan",
      "country": "IR",
      "elevation": 5609,
      "lat": 35.2116012573,
      "lon": 48.6534004211,
      "tz": "Asia\/Tehran"
  },
  "OIIA": {
      "icao": "OIIA",
      "iata": "",
      "name": "Ghazvin Azadi Airport",
      "city": "",
      "state": "Alborz",
      "country": "IR",
      "elevation": 3769,
      "lat": 35.9520988464,
      "lon": 50.4508018494,
      "tz": "Asia\/Tehran"
  },
  "OIIC": {
      "icao": "OIIC",
      "iata": "",
      "name": "Kushke Nosrat Airport",
      "city": "",
      "state": "Qom",
      "country": "IR",
      "elevation": 3008,
      "lat": 34.9840011597,
      "lon": 50.8061981201,
      "tz": "Asia\/Tehran"
  },
  "OIID": {
      "icao": "OIID",
      "iata": "",
      "name": "Doshan Tappeh Air Base",
      "city": "",
      "state": "Tehran",
      "country": "IR",
      "elevation": 4046,
      "lat": 35.702999115,
      "lon": 51.4751014709,
      "tz": "Asia\/Tehran"
  },
  "OIIE": {
      "icao": "OIIE",
      "iata": "IKA",
      "name": "Imam Khomeini International Airport",
      "city": "Tehran",
      "state": "Tehran",
      "country": "IR",
      "elevation": 3305,
      "lat": 35.4160995483,
      "lon": 51.1521987915,
      "tz": "Asia\/Tehran"
  },
  "OIIG": {
      "icao": "OIIG",
      "iata": "",
      "name": "Ghale Morghi Airport",
      "city": "",
      "state": "Tehran",
      "country": "IR",
      "elevation": 3627,
      "lat": 35.6447982788,
      "lon": 51.3806991577,
      "tz": "Asia\/Tehran"
  },
  "OIII": {
      "icao": "OIII",
      "iata": "THR",
      "name": "Mehrabad International Airport",
      "city": "Tehran",
      "state": "Tehran",
      "country": "IR",
      "elevation": 3962,
      "lat": 35.689201355,
      "lon": 51.3134002686,
      "tz": "Asia\/Tehran"
  },
  "OIIK": {
      "icao": "OIIK",
      "iata": "GZW",
      "name": "Qazvin Airport",
      "city": "Qazvin",
      "state": "Qazvin",
      "country": "IR",
      "elevation": 4184,
      "lat": 36.2401008606,
      "lon": 50.0471000671,
      "tz": "Asia\/Tehran"
  },
  "OIIM": {
      "icao": "OIIM",
      "iata": "",
      "name": "Naja Airport",
      "city": "",
      "state": "Alborz",
      "country": "IR",
      "elevation": 4040,
      "lat": 35.7762985229,
      "lon": 50.8810005188,
      "tz": "Asia\/Tehran"
  },
  "OIIP": {
      "icao": "OIIP",
      "iata": "PYK",
      "name": "Payam Airport",
      "city": "",
      "state": "Alborz",
      "country": "IR",
      "elevation": 4170,
      "lat": 35.7761001587,
      "lon": 50.8266983032,
      "tz": "Asia\/Tehran"
  },
  "OIIQ": {
      "icao": "OIIQ",
      "iata": "",
      "name": "Ghom Airport",
      "city": "Ghom",
      "state": "Qom",
      "country": "IR",
      "elevation": 0,
      "lat": 34.6333312988,
      "lon": 50.8833312988,
      "tz": "Asia\/Tehran"
  },
  "OIIS": {
      "icao": "OIIS",
      "iata": "SNX",
      "name": "Semnan Airport",
      "city": "Semnan",
      "state": "Semnan",
      "country": "IR",
      "elevation": 3665,
      "lat": 35.5910987854,
      "lon": 53.495098114,
      "tz": "Asia\/Tehran"
  },
  "OIIU": {
      "icao": "OIIU",
      "iata": "",
      "name": "Damghan Airport",
      "city": "",
      "state": "Semnan",
      "country": "IR",
      "elevation": 3887,
      "lat": 36.1666984558,
      "lon": 54.3438987732,
      "tz": "Asia\/Tehran"
  },
  "OIKB": {
      "icao": "OIKB",
      "iata": "BND",
      "name": "Bandar Abbas International Airport",
      "city": "Bandar Abbas",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 22,
      "lat": 27.2182998657,
      "lon": 56.3777999878,
      "tz": "Asia\/Tehran"
  },
  "OIKF": {
      "icao": "OIKF",
      "iata": "",
      "name": "Baft Airport",
      "city": "",
      "state": "Kerman",
      "country": "IR",
      "elevation": 7385,
      "lat": 29.2341995239,
      "lon": 56.6068992615,
      "tz": "Asia\/Tehran"
  },
  "OIKJ": {
      "icao": "OIKJ",
      "iata": "JYR",
      "name": "Jiroft Airport",
      "city": "",
      "state": "Kerman",
      "country": "IR",
      "elevation": 2663,
      "lat": 28.7269001007,
      "lon": 57.67029953,
      "tz": "Asia\/Tehran"
  },
  "OIKK": {
      "icao": "OIKK",
      "iata": "KER",
      "name": "Kerman Airport",
      "city": "Kerman",
      "state": "Kerman",
      "country": "IR",
      "elevation": 5741,
      "lat": 30.2744007111,
      "lon": 56.9510993958,
      "tz": "Asia\/Tehran"
  },
  "OIKM": {
      "icao": "OIKM",
      "iata": "BXR",
      "name": "Bam Airport",
      "city": "",
      "state": "Kerman",
      "country": "IR",
      "elevation": 3231,
      "lat": 29.0841999054,
      "lon": 58.4500007629,
      "tz": "Asia\/Tehran"
  },
  "OIKP": {
      "icao": "OIKP",
      "iata": "HDR",
      "name": "Havadarya Airport",
      "city": "Havadarya",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 19,
      "lat": 27.1583003998,
      "lon": 56.1725006104,
      "tz": "Asia\/Tehran"
  },
  "OIKQ": {
      "icao": "OIKQ",
      "iata": "GSM",
      "name": "Dayrestan Airport",
      "city": "",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 45,
      "lat": 26.7546005249,
      "lon": 55.9024009705,
      "tz": "Asia\/Tehran"
  },
  "OIKR": {
      "icao": "OIKR",
      "iata": "RJN",
      "name": "Rafsanjan Airport",
      "city": "",
      "state": "Kerman",
      "country": "IR",
      "elevation": 5298,
      "lat": 30.297700882,
      "lon": 56.0511016846,
      "tz": "Asia\/Tehran"
  },
  "OIKY": {
      "icao": "OIKY",
      "iata": "SYJ",
      "name": "Sirjan Airport",
      "city": "",
      "state": "Kerman",
      "country": "IR",
      "elevation": 5846,
      "lat": 29.5508995056,
      "lon": 55.6726989746,
      "tz": "Asia\/Tehran"
  },
  "OIMB": {
      "icao": "OIMB",
      "iata": "XBJ",
      "name": "Birjand Airport",
      "city": "Birjand",
      "state": "Khorasan-e-Jonubi",
      "country": "IR",
      "elevation": 4952,
      "lat": 32.8981018066,
      "lon": 59.2661018372,
      "tz": "Asia\/Tehran"
  },
  "OIMC": {
      "icao": "OIMC",
      "iata": "CKT",
      "name": "Sarakhs Airport",
      "city": "Sarakhs",
      "state": "Razavi-Khorasan",
      "country": "IR",
      "elevation": 945,
      "lat": 36.5012016296,
      "lon": 61.0648994446,
      "tz": "Asia\/Tehran"
  },
  "OIMD": {
      "icao": "OIMD",
      "iata": "",
      "name": "Gonabad Airport",
      "city": "Gonabad",
      "state": "Razavi-Khorasan",
      "country": "IR",
      "elevation": 0,
      "lat": 34.3499984741,
      "lon": 58.6800003052,
      "tz": "Asia\/Tehran"
  },
  "OIMH": {
      "icao": "OIMH",
      "iata": "",
      "name": "Torbat-e Heydarieh Airport",
      "city": "Torbat-E-Heidarieh",
      "state": "Razavi-Khorasan",
      "country": "IR",
      "elevation": 4363,
      "lat": 35.2667007446,
      "lon": 59.2167015076,
      "tz": "Asia\/Tehran"
  },
  "OIMJ": {
      "icao": "OIMJ",
      "iata": "RUD",
      "name": "Shahroud Airport",
      "city": "",
      "state": "Semnan",
      "country": "IR",
      "elevation": 4215,
      "lat": 36.4253005981,
      "lon": 55.1041984558,
      "tz": "Asia\/Tehran"
  },
  "OIMM": {
      "icao": "OIMM",
      "iata": "MHD",
      "name": "Mashhad International Airport",
      "city": "Mashhad",
      "state": "Razavi-Khorasan",
      "country": "IR",
      "elevation": 3263,
      "lat": 36.2351989746,
      "lon": 59.6409988403,
      "tz": "Asia\/Tehran"
  },
  "OIMN": {
      "icao": "OIMN",
      "iata": "BJB",
      "name": "Bojnord Airport",
      "city": "Bojnord",
      "state": "Khorasan-e-Shomali",
      "country": "IR",
      "elevation": 3499,
      "lat": 37.4930000305,
      "lon": 57.3082008362,
      "tz": "Asia\/Tehran"
  },
  "OIMS": {
      "icao": "OIMS",
      "iata": "AFZ",
      "name": "Sabzevar National Airport",
      "city": "Sabzevar",
      "state": "Razavi-Khorasan",
      "country": "IR",
      "elevation": 3010,
      "lat": 36.1680984497,
      "lon": 57.595199585,
      "tz": "Asia\/Tehran"
  },
  "OIMT": {
      "icao": "OIMT",
      "iata": "TCX",
      "name": "Tabas Airport",
      "city": "Tabas",
      "state": "Yazd",
      "country": "IR",
      "elevation": 2312,
      "lat": 33.6678009033,
      "lon": 56.8927001953,
      "tz": "Asia\/Tehran"
  },
  "OIMX": {
      "icao": "OIMX",
      "iata": "",
      "name": "Soga Airport",
      "city": "Soga",
      "state": "Khorasan-e-Shomali",
      "country": "IR",
      "elevation": 2460,
      "lat": 37.6278991699,
      "lon": 56.1730995178,
      "tz": "Asia\/Tehran"
  },
  "OINA": {
      "icao": "OINA",
      "iata": "",
      "name": "Amol Airport",
      "city": "",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": 318,
      "lat": 36.4707984924,
      "lon": 52.3633003235,
      "tz": "Asia\/Tehran"
  },
  "OINB": {
      "icao": "OINB",
      "iata": "BBL",
      "name": "Babolsar Airport",
      "city": "Babolsar",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": -49,
      "lat": 36.7200012207,
      "lon": 52.6500015259,
      "tz": "Asia\/Tehran"
  },
  "OINE": {
      "icao": "OINE",
      "iata": "KLM",
      "name": "Kalaleh Airport",
      "city": "",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": 425,
      "lat": 37.3833007813,
      "lon": 55.4519996643,
      "tz": "Asia\/Tehran"
  },
  "OING": {
      "icao": "OING",
      "iata": "GBT",
      "name": "Gorgan Airport",
      "city": "Gorgan",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": -24,
      "lat": 36.9094009399,
      "lon": 54.4012985229,
      "tz": "Asia\/Tehran"
  },
  "OINH": {
      "icao": "OINH",
      "iata": "",
      "name": "Behshahr Airport",
      "city": "",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": 137,
      "lat": 36.6944007874,
      "lon": 53.5424995422,
      "tz": "Asia\/Tehran"
  },
  "OINI": {
      "icao": "OINI",
      "iata": "",
      "name": "Ghaem Shahr Airport",
      "city": "Ghaem Shahr",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": 0,
      "lat": 36.4666671753,
      "lon": 52.8666687012,
      "tz": "Asia\/Tehran"
  },
  "OINJ": {
      "icao": "OINJ",
      "iata": "BSM",
      "name": "Bishe Kola Air Base",
      "city": "Amol",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": -79,
      "lat": 36.6551017761,
      "lon": 52.3496017456,
      "tz": "Asia\/Tehran"
  },
  "OINM": {
      "icao": "OINM",
      "iata": "",
      "name": "Mahmood Abad Airport",
      "city": "Mahmood Abad",
      "state": "Isfahan",
      "country": "IR",
      "elevation": 0,
      "lat": 34.1691665649,
      "lon": 51.3175010681,
      "tz": "Asia\/Tehran"
  },
  "OINN": {
      "icao": "OINN",
      "iata": "NSH",
      "name": "Noshahr Airport",
      "city": "",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": -61,
      "lat": 36.6632995605,
      "lon": 51.4646987915,
      "tz": "Asia\/Tehran"
  },
  "OINR": {
      "icao": "OINR",
      "iata": "RZR",
      "name": "Ramsar Airport",
      "city": "",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": -70,
      "lat": 36.9099006653,
      "lon": 50.679599762,
      "tz": "Asia\/Tehran"
  },
  "OINZ": {
      "icao": "OINZ",
      "iata": "SRY",
      "name": "Dasht-e Naz Airport",
      "city": "Sari",
      "state": "Mazandaran",
      "country": "IR",
      "elevation": 35,
      "lat": 36.635799408,
      "lon": 53.1935997009,
      "tz": "Asia\/Tehran"
  },
  "OISA": {
      "icao": "OISA",
      "iata": "",
      "name": "Abadeh Airport",
      "city": "Abadeh",
      "state": "Fars",
      "country": "IR",
      "elevation": 5320,
      "lat": 31.1666679382,
      "lon": 52.6666679382,
      "tz": "Asia\/Tehran"
  },
  "OISD": {
      "icao": "OISD",
      "iata": "",
      "name": "Darab Airport",
      "city": "Darab",
      "state": "Fars",
      "country": "IR",
      "elevation": 3600,
      "lat": 28.7215995789,
      "lon": 54.4412994385,
      "tz": "Asia\/Tehran"
  },
  "OISF": {
      "icao": "OISF",
      "iata": "FAZ",
      "name": "Fasa Airport",
      "city": "Fasa",
      "state": "Fars",
      "country": "IR",
      "elevation": 4261,
      "lat": 28.8917999268,
      "lon": 53.7233009338,
      "tz": "Asia\/Tehran"
  },
  "OISJ": {
      "icao": "OISJ",
      "iata": "JAR",
      "name": "Jahrom Airport",
      "city": "",
      "state": "Fars",
      "country": "IR",
      "elevation": 3358,
      "lat": 28.5867004395,
      "lon": 53.5791015625,
      "tz": "Asia\/Tehran"
  },
  "OISL": {
      "icao": "OISL",
      "iata": "LRR",
      "name": "Lar Airport",
      "city": "Lar",
      "state": "Fars",
      "country": "IR",
      "elevation": 2641,
      "lat": 27.6746997833,
      "lon": 54.3833007812,
      "tz": "Asia\/Tehran"
  },
  "OISO": {
      "icao": "OISO",
      "iata": "",
      "name": "Zargan Airport",
      "city": "Zargan",
      "state": "Fars",
      "country": "IR",
      "elevation": 5500,
      "lat": 29.7541999817,
      "lon": 52.6943016052,
      "tz": "Asia\/Tehran"
  },
  "OISR": {
      "icao": "OISR",
      "iata": "LFM",
      "name": "Lamerd Airport",
      "city": "Lamerd",
      "state": "Fars",
      "country": "IR",
      "elevation": 1337,
      "lat": 27.3726997375,
      "lon": 53.1888008118,
      "tz": "Asia\/Tehran"
  },
  "OISS": {
      "icao": "OISS",
      "iata": "SYZ",
      "name": "Shiraz Shahid Dastghaib International Airport",
      "city": "Shiraz",
      "state": "Fars",
      "country": "IR",
      "elevation": 4920,
      "lat": 29.5391998291,
      "lon": 52.5898017883,
      "tz": "Asia\/Tehran"
  },
  "OISY": {
      "icao": "OISY",
      "iata": "YES",
      "name": "Yasouj Airport",
      "city": "",
      "state": "Kohgiluyeh-va-Buyer-A\u1e29mad",
      "country": "IR",
      "elevation": 5939,
      "lat": 30.7005004883,
      "lon": 51.5451011658,
      "tz": "Asia\/Tehran"
  },
  "OITK": {
      "icao": "OITK",
      "iata": "KHY",
      "name": "Khoy Airport",
      "city": "Khoy",
      "state": "Az\u0304arbayjan-e-Gharbi",
      "country": "IR",
      "elevation": 3981,
      "lat": 38.4275016785,
      "lon": 44.9735984802,
      "tz": "Asia\/Tehran"
  },
  "OITL": {
      "icao": "OITL",
      "iata": "ADU",
      "name": "Ardabil Airport",
      "city": "Ardabil",
      "state": "Ardabil",
      "country": "IR",
      "elevation": 4315,
      "lat": 38.3256988525,
      "lon": 48.4244003296,
      "tz": "Asia\/Tehran"
  },
  "OITM": {
      "icao": "OITM",
      "iata": "ACP",
      "name": "Sahand Airport",
      "city": "Maragheh",
      "state": "East-Azerbaijan",
      "country": "IR",
      "elevation": 4396,
      "lat": 37.3479995728,
      "lon": 46.1278991699,
      "tz": "Asia\/Tehran"
  },
  "OITP": {
      "icao": "OITP",
      "iata": "PFQ",
      "name": "Parsabade Moghan Airport",
      "city": "Parsabad",
      "state": "Ardabil",
      "country": "IR",
      "elevation": 251,
      "lat": 39.6035995483,
      "lon": 47.8815002441,
      "tz": "Asia\/Tehran"
  },
  "OITR": {
      "icao": "OITR",
      "iata": "OMH",
      "name": "Urmia Airport",
      "city": "Urmia",
      "state": "Az\u0304arbayjan-e-Gharbi",
      "country": "IR",
      "elevation": 4343,
      "lat": 37.6680984497,
      "lon": 45.0686988831,
      "tz": "Asia\/Tehran"
  },
  "OITS": {
      "icao": "OITS",
      "iata": "",
      "name": "Saghez Airport",
      "city": "Saghez",
      "state": "Kordestan",
      "country": "IR",
      "elevation": 4880,
      "lat": 36.25,
      "lon": 46.2666664124,
      "tz": "Asia\/Tehran"
  },
  "OITT": {
      "icao": "OITT",
      "iata": "TBZ",
      "name": "Tabriz International Airport",
      "city": "Tabriz",
      "state": "East-Azerbaijan",
      "country": "IR",
      "elevation": 4459,
      "lat": 38.1338996887,
      "lon": 46.2350006104,
      "tz": "Asia\/Tehran"
  },
  "OITU": {
      "icao": "OITU",
      "iata": "IMQ",
      "name": "Makou Airport",
      "city": "Makou",
      "state": "Az\u0304arbayjan-e-Gharbi",
      "country": "IR",
      "elevation": 0,
      "lat": 39.3300018311,
      "lon": 44.4300003052,
      "tz": "Asia\/Tehran"
  },
  "OITZ": {
      "icao": "OITZ",
      "iata": "JWN",
      "name": "Zanjan Airport",
      "city": "",
      "state": "Zanjan",
      "country": "IR",
      "elevation": 5382,
      "lat": 36.7737007141,
      "lon": 48.3594017029,
      "tz": "Asia\/Tehran"
  },
  "OIYB": {
      "icao": "OIYB",
      "iata": "",
      "name": "Bafgh Airport",
      "city": "Bafgh",
      "state": "Yazd",
      "country": "IR",
      "elevation": 0,
      "lat": 31.5833320618,
      "lon": 55.5666656494,
      "tz": "Asia\/Tehran"
  },
  "OIYY": {
      "icao": "OIYY",
      "iata": "AZD",
      "name": "Shahid Sadooghi Airport",
      "city": "Yazd",
      "state": "Yazd",
      "country": "IR",
      "elevation": 4054,
      "lat": 31.9048995972,
      "lon": 54.2765007019,
      "tz": "Asia\/Tehran"
  },
  "OIZB": {
      "icao": "OIZB",
      "iata": "ACZ",
      "name": "Zabol Airport",
      "city": "",
      "state": "Sistan-and-Baluchestan",
      "country": "IR",
      "elevation": 1628,
      "lat": 31.0983009338,
      "lon": 61.5438995361,
      "tz": "Asia\/Tehran"
  },
  "OIZC": {
      "icao": "OIZC",
      "iata": "ZBR",
      "name": "Konarak Airport",
      "city": "Chabahar",
      "state": "Sistan-and-Baluchestan",
      "country": "IR",
      "elevation": 43,
      "lat": 25.4433002472,
      "lon": 60.3820991516,
      "tz": "Asia\/Tehran"
  },
  "OIZH": {
      "icao": "OIZH",
      "iata": "ZAH",
      "name": "Zahedan International Airport",
      "city": "Zahedan",
      "state": "Sistan-and-Baluchestan",
      "country": "IR",
      "elevation": 4564,
      "lat": 29.4757003784,
      "lon": 60.9062004089,
      "tz": "Asia\/Tehran"
  },
  "OIZI": {
      "icao": "OIZI",
      "iata": "IHR",
      "name": "Iran Shahr Airport",
      "city": "",
      "state": "Sistan-and-Baluchestan",
      "country": "IR",
      "elevation": 2040,
      "lat": 27.2360992432,
      "lon": 60.7200012207,
      "tz": "Asia\/Tehran"
  },
  "OIZJ": {
      "icao": "OIZJ",
      "iata": "",
      "name": "Jask Airport",
      "city": "Jask",
      "state": "Hormozgan",
      "country": "IR",
      "elevation": 19,
      "lat": 25.6536006927,
      "lon": 57.7993011475,
      "tz": "Asia\/Tehran"
  },
  "OIZS": {
      "icao": "OIZS",
      "iata": "",
      "name": "Saravan Airport",
      "city": "",
      "state": "Sistan-and-Baluchestan",
      "country": "IR",
      "elevation": 3930,
      "lat": 27.4193000793,
      "lon": 62.3157997131,
      "tz": "Asia\/Tehran"
  },
  "OJ38": {
      "icao": "OJ38",
      "iata": "",
      "name": "Zarqa Airport",
      "city": "Zarqa",
      "state": "Zarqa",
      "country": "JO",
      "elevation": 2400,
      "lat": 32.0252990723,
      "lon": 36.1447982788,
      "tz": "Asia\/Amman"
  },
  "OJ40": {
      "icao": "OJ40",
      "iata": "",
      "name": "Al Azraq \/ Al Shaheed Muwaffaq Salti Air Base",
      "city": "",
      "state": "",
      "country": "JO",
      "elevation": 1706,
      "lat": 31.8255996704,
      "lon": 36.7820014954,
      "tz": "Asia\/Amman"
  },
  "OJAI": {
      "icao": "OJAI",
      "iata": "AMM",
      "name": "Queen Alia International Airport",
      "city": "Amman",
      "state": "Amman",
      "country": "JO",
      "elevation": 2395,
      "lat": 31.7226009369,
      "lon": 35.9931983948,
      "tz": "Asia\/Amman"
  },
  "OJAM": {
      "icao": "OJAM",
      "iata": "ADJ",
      "name": "Amman-Marka International Airport",
      "city": "Amman",
      "state": "Amman",
      "country": "JO",
      "elevation": 2555,
      "lat": 31.972700119,
      "lon": 35.9916000366,
      "tz": "Asia\/Amman"
  },
  "OJAQ": {
      "icao": "OJAQ",
      "iata": "AQJ",
      "name": "Aqaba King Hussein International Airport",
      "city": "Aqaba",
      "state": "Aqaba",
      "country": "JO",
      "elevation": 175,
      "lat": 29.6116008759,
      "lon": 35.0181007385,
      "tz": "Asia\/Amman"
  },
  "OJHF": {
      "icao": "OJHF",
      "iata": "",
      "name": "Prince Hasan Air Base",
      "city": "",
      "state": "Mafraq",
      "country": "JO",
      "elevation": 2220,
      "lat": 32.1607017517,
      "lon": 37.1493988037,
      "tz": "Asia\/Amman"
  },
  "OJMF": {
      "icao": "OJMF",
      "iata": "OMF",
      "name": "King Hussein Air College",
      "city": "Mafraq",
      "state": "Mafraq",
      "country": "JO",
      "elevation": 2240,
      "lat": 32.3563995361,
      "lon": 36.2592010498,
      "tz": "Asia\/Amman"
  },
  "OK00": {
      "icao": "OK00",
      "iata": "",
      "name": "Jacktown Airport",
      "city": "Jacktown",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 954,
      "lat": 35.5163002014,
      "lon": -97.022102356,
      "tz": "America\/Chicago"
  },
  "OK01": {
      "icao": "OK01",
      "iata": "",
      "name": "Sky Haven Airpark\/Sellmeyer Field",
      "city": "Vera",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 675,
      "lat": 36.4290008545,
      "lon": -95.9032974243,
      "tz": "America\/Chicago"
  },
  "OK02": {
      "icao": "OK02",
      "iata": "",
      "name": "Dick's Airport",
      "city": "Goldsby",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1160,
      "lat": 35.1108016968,
      "lon": -97.4816970825,
      "tz": "America\/Chicago"
  },
  "OK03": {
      "icao": "OK03",
      "iata": "DWN",
      "name": "Downtown Airpark",
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1180,
      "lat": 35.4491996765,
      "lon": -97.5330963135,
      "tz": "America\/Chicago"
  },
  "OK04": {
      "icao": "OK04",
      "iata": "",
      "name": "Canadian River Ranch Airport",
      "city": "Eufaula",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 850,
      "lat": 35.3093986511,
      "lon": -95.8082962036,
      "tz": "America\/Chicago"
  },
  "OK05": {
      "icao": "OK05",
      "iata": "",
      "name": "Ray Preston Airport",
      "city": "Hollis",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1660,
      "lat": 34.7000999451,
      "lon": -99.9253997803,
      "tz": "America\/Chicago"
  },
  "OK06": {
      "icao": "OK06",
      "iata": "",
      "name": "Snake Creek Wilderness Airport",
      "city": "Cookson",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1130,
      "lat": 35.6478004456,
      "lon": -94.9499969482,
      "tz": "America\/Chicago"
  },
  "OK07": {
      "icao": "OK07",
      "iata": "",
      "name": "Djs Airport",
      "city": "Lawton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1115,
      "lat": 34.5181999207,
      "lon": -98.3255996704,
      "tz": "America\/Chicago"
  },
  "OK08": {
      "icao": "OK08",
      "iata": "",
      "name": "Hill Top Private Airport",
      "city": "Lawton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 34.5334014893,
      "lon": -98.3503036499,
      "tz": "America\/Chicago"
  },
  "OK10": {
      "icao": "OK10",
      "iata": "",
      "name": "Entropy Airport",
      "city": "Elmore City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1020,
      "lat": 34.5819015503,
      "lon": -97.4096984863,
      "tz": "America\/Chicago"
  },
  "OK11": {
      "icao": "OK11",
      "iata": "",
      "name": "Ksa Orchards Airport",
      "city": "Comanche",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1080,
      "lat": 34.3175010681,
      "lon": -98.0149993896,
      "tz": "America\/Chicago"
  },
  "OK12": {
      "icao": "OK12",
      "iata": "",
      "name": "Jones Farm Field",
      "city": "Walters",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1042,
      "lat": 34.399167,
      "lon": -98.266389,
      "tz": "America\/Chicago"
  },
  "OK13": {
      "icao": "OK13",
      "iata": "",
      "name": "Erroport Airport",
      "city": "Mounds",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 660,
      "lat": 35.8457984924,
      "lon": -95.9710998535,
      "tz": "America\/Chicago"
  },
  "OK14": {
      "icao": "OK14",
      "iata": "",
      "name": "Woodlake Airport",
      "city": "Alva",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1420,
      "lat": 36.8352012634,
      "lon": -98.657699585,
      "tz": "America\/Chicago"
  },
  "OK15": {
      "icao": "OK15",
      "iata": "",
      "name": "Avian Country Estates Airport",
      "city": "Oolagah",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 750,
      "lat": 36.3760986328,
      "lon": -95.7052993774,
      "tz": "America\/Chicago"
  },
  "OK16": {
      "icao": "OK16",
      "iata": "",
      "name": "Fairmont Field Airport",
      "city": "Fairmont",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1168,
      "lat": 36.3647003174,
      "lon": -97.6678009033,
      "tz": "America\/Chicago"
  },
  "OK17": {
      "icao": "OK17",
      "iata": "",
      "name": "Bass Aero Airport",
      "city": "Ardmore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 840,
      "lat": 34.21825,
      "lon": -97.052567,
      "tz": "America\/Chicago"
  },
  "OK18": {
      "icao": "OK18",
      "iata": "",
      "name": "Grand Isle Airport",
      "city": "Adair",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 636,
      "lat": 36.4234008789,
      "lon": -95.1710968018,
      "tz": "America\/Chicago"
  },
  "OK20": {
      "icao": "OK20",
      "iata": "",
      "name": "Sageeyah Airfield",
      "city": "Claremore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 640,
      "lat": 36.3837013245,
      "lon": -95.6480026245,
      "tz": "America\/Chicago"
  },
  "OK21": {
      "icao": "OK21",
      "iata": "",
      "name": "Longs Airport North Airport",
      "city": "Pryor",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 650,
      "lat": 36.3334007263,
      "lon": -95.304397583,
      "tz": "America\/Chicago"
  },
  "OK22": {
      "icao": "OK22",
      "iata": "",
      "name": "Bluebird Airpark",
      "city": "Shawnee",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1030,
      "lat": 35.4029998779,
      "lon": -96.8321990967,
      "tz": "America\/Chicago"
  },
  "OK23": {
      "icao": "OK23",
      "iata": "",
      "name": "Taliaferro Field",
      "city": "Ardmore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 930,
      "lat": 34.19829941,
      "lon": -97.23120117,
      "tz": "America\/Chicago"
  },
  "OK24": {
      "icao": "OK24",
      "iata": "",
      "name": "Colby Field",
      "city": "Lone Grove",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 930,
      "lat": 34.206199646,
      "lon": -97.2311019897,
      "tz": "America\/Chicago"
  },
  "OK25": {
      "icao": "OK25",
      "iata": "",
      "name": "Cherokee Ranch Airport",
      "city": "Haskell",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 710,
      "lat": 35.8133010864,
      "lon": -95.7533035278,
      "tz": "America\/Chicago"
  },
  "OK27": {
      "icao": "OK27",
      "iata": "",
      "name": "Venture Aerodrome Airpark Nr 2 Ultralightport",
      "city": "Roland",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 450,
      "lat": 35.4356002808,
      "lon": -94.474899292,
      "tz": "America\/Chicago"
  },
  "OK28": {
      "icao": "OK28",
      "iata": "",
      "name": "Mcdaniel Aviation Airport",
      "city": "Ravia",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 700,
      "lat": 34.229167,
      "lon": -96.749167,
      "tz": "America\/Chicago"
  },
  "OK29": {
      "icao": "OK29",
      "iata": "",
      "name": "Travis Airport",
      "city": "Marietta",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 862,
      "lat": 33.9359016418,
      "lon": -97.0544967651,
      "tz": "America\/Chicago"
  },
  "OK30": {
      "icao": "OK30",
      "iata": "",
      "name": "Grandcraft Landing Strip",
      "city": "Langley",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 827,
      "lat": 36.4917984009,
      "lon": -95.050201416,
      "tz": "America\/Chicago"
  },
  "OK31": {
      "icao": "OK31",
      "iata": "",
      "name": "Whittington Ranch Airport",
      "city": "Thackerville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 845,
      "lat": 33.778542,
      "lon": -97.131458,
      "tz": "America\/Chicago"
  },
  "OK34": {
      "icao": "OK34",
      "iata": "",
      "name": "Gustafson Airport",
      "city": "Sallisaw",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 855,
      "lat": 35.4842987061,
      "lon": -94.8419036865,
      "tz": "America\/Chicago"
  },
  "OK36": {
      "icao": "OK36",
      "iata": "",
      "name": "W C Ranch STOLport",
      "city": "Crowder",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 725,
      "lat": 35.1197013855,
      "lon": -95.7039031982,
      "tz": "America\/Chicago"
  },
  "OK37": {
      "icao": "OK37",
      "iata": "",
      "name": "Dog Iron Ranch Airport",
      "city": "Oologah",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 760,
      "lat": 36.4695015,
      "lon": -95.6678009,
      "tz": "America\/Chicago"
  },
  "OK39": {
      "icao": "OK39",
      "iata": "",
      "name": "Judy Ranch Airport",
      "city": "Forgan",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 2240,
      "lat": 36.9667015076,
      "lon": -100.3170013428,
      "tz": "America\/Chicago"
  },
  "OK40": {
      "icao": "OK40",
      "iata": "",
      "name": "Eden Ranch Airport",
      "city": "Freedom",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1641,
      "lat": 36.7583999634,
      "lon": -99.0336990356,
      "tz": "America\/Chicago"
  },
  "OK42": {
      "icao": "OK42",
      "iata": "",
      "name": "Siegmanns Airport",
      "city": "Hennessey",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1053,
      "lat": 36.0834007263,
      "lon": -97.7170028687,
      "tz": "America\/Chicago"
  },
  "OK43": {
      "icao": "OK43",
      "iata": "",
      "name": "Logsdon Ranch Airport",
      "city": "Alva",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1475,
      "lat": 36.5306015015,
      "lon": -98.6367034912,
      "tz": "America\/Chicago"
  },
  "OK44": {
      "icao": "OK44",
      "iata": "",
      "name": "Canyon Springs Ranch Airport",
      "city": "Bokchito",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 625,
      "lat": 34.1461982727,
      "lon": -96.0768966675,
      "tz": "America\/Chicago"
  },
  "OK46": {
      "icao": "OK46",
      "iata": "",
      "name": "Mc Crays Airport",
      "city": "Manchester",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1260,
      "lat": 36.9589004517,
      "lon": -98.075302124,
      "tz": "America\/Chicago"
  },
  "OK47": {
      "icao": "OK47",
      "iata": "",
      "name": "Miller Brothers Airport",
      "city": "Manchester",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1250,
      "lat": 36.9416999817,
      "lon": -98.016998291,
      "tz": "America\/Chicago"
  },
  "OK48": {
      "icao": "OK48",
      "iata": "",
      "name": "Grass Roots Airport",
      "city": "Newkirk",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1113,
      "lat": 36.875,
      "lon": -97.0961990356,
      "tz": "America\/Chicago"
  },
  "OK49": {
      "icao": "OK49",
      "iata": "",
      "name": "Secrest Ranch Airport",
      "city": "Ponca City",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1050,
      "lat": 36.6500015259,
      "lon": -97.016998291,
      "tz": "America\/Chicago"
  },
  "OK50": {
      "icao": "OK50",
      "iata": "",
      "name": "Traynor Ranch Airport",
      "city": "Waukomis",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1220,
      "lat": 36.3325004578,
      "lon": -97.8930969238,
      "tz": "America\/Chicago"
  },
  "OK51": {
      "icao": "OK51",
      "iata": "",
      "name": "Enix Boys Airport",
      "city": "Hennessey",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 36.1041984558,
      "lon": -97.93699646,
      "tz": "America\/Chicago"
  },
  "OK54": {
      "icao": "OK54",
      "iata": "",
      "name": "May Ranch Airport",
      "city": "Alva",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1833,
      "lat": 36.9967002869,
      "lon": -99.0047988892,
      "tz": "America\/Chicago"
  },
  "OK55": {
      "icao": "OK55",
      "iata": "",
      "name": "Bost Ranch Airport",
      "city": "Luther",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1070,
      "lat": 35.7145004272,
      "lon": -97.2258987427,
      "tz": "America\/Chicago"
  },
  "OK58": {
      "icao": "OK58",
      "iata": "",
      "name": "Bluestem Airport",
      "city": "Collinsville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 660,
      "lat": 36.3973007202,
      "lon": -95.9049987793,
      "tz": "America\/Chicago"
  },
  "OK59": {
      "icao": "OK59",
      "iata": "",
      "name": "Wolf Mountain Airport",
      "city": "Poteau",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 860,
      "lat": 35.0223007202,
      "lon": -94.7043991089,
      "tz": "America\/Chicago"
  },
  "OK62": {
      "icao": "OK62",
      "iata": "",
      "name": "Strader Ranch Airport",
      "city": "Ardmore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 850,
      "lat": 34.2486991882,
      "lon": -97.209197998,
      "tz": "America\/Chicago"
  },
  "OK64": {
      "icao": "OK64",
      "iata": "",
      "name": "Weedpatch International Airport",
      "city": "Wakita",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1113,
      "lat": 36.8138999939,
      "lon": -97.9865036011,
      "tz": "America\/Chicago"
  },
  "OK66": {
      "icao": "OK66",
      "iata": "",
      "name": "F.W. Zaloudek Airport",
      "city": "Kremlin",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1118,
      "lat": 36.5567016602,
      "lon": -97.8337020874,
      "tz": "America\/Chicago"
  },
  "OK69": {
      "icao": "OK69",
      "iata": "",
      "name": "Monarch Field",
      "city": "Chelsea",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 755,
      "lat": 36.525100708,
      "lon": -95.4669036865,
      "tz": "America\/Chicago"
  },
  "OK74": {
      "icao": "OK74",
      "iata": "",
      "name": "Flying H Airport",
      "city": "Ashland",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 850,
      "lat": 34.829498291,
      "lon": -96.0342025757,
      "tz": "America\/Chicago"
  },
  "OK79": {
      "icao": "OK79",
      "iata": "",
      "name": "Temple Airport Inc Airport",
      "city": "Temple",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1005,
      "lat": 34.2568016052,
      "lon": -98.2412033081,
      "tz": "America\/Chicago"
  },
  "OK80": {
      "icao": "OK80",
      "iata": "",
      "name": "Thomas Ranch Airport",
      "city": "Ada",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 811,
      "lat": 34.7286987305,
      "lon": -96.6010971069,
      "tz": "America\/Chicago"
  },
  "OK81": {
      "icao": "OK81",
      "iata": "",
      "name": "Homer Ranch Airport",
      "city": "Albion",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 570,
      "lat": 34.6584014893,
      "lon": -95.0710983276,
      "tz": "America\/Chicago"
  },
  "OK82": {
      "icao": "OK82",
      "iata": "",
      "name": "Scottys Field",
      "city": "Altus",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1425,
      "lat": 34.6916999817,
      "lon": -99.3448028564,
      "tz": "America\/Chicago"
  },
  "OK83": {
      "icao": "OK83",
      "iata": "",
      "name": "Sheffield-Smith Airstrip",
      "city": "Altus",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1355,
      "lat": 34.5682983398,
      "lon": -99.3516998291,
      "tz": "America\/Chicago"
  },
  "OK85": {
      "icao": "OK85",
      "iata": "",
      "name": "Goddard Ranch Airport",
      "city": "Ardmore",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 858,
      "lat": 34.3000984192,
      "lon": -96.9169998169,
      "tz": "America\/Chicago"
  },
  "OK87": {
      "icao": "OK87",
      "iata": "",
      "name": "Renavair Field",
      "city": "Wynnewood",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 905,
      "lat": 34.6549987793,
      "lon": -97.2146987915,
      "tz": "America\/Chicago"
  },
  "OK88": {
      "icao": "OK88",
      "iata": "",
      "name": "Silverwood Ultralightport",
      "city": "Broken Arrow",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 632,
      "lat": 36.0101013184,
      "lon": -95.8435974121,
      "tz": "America\/Chicago"
  },
  "OK89": {
      "icao": "OK89",
      "iata": "",
      "name": "Stuart Ranch Airport",
      "city": "Caddo",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 624,
      "lat": 34.1556015015,
      "lon": -96.1557998657,
      "tz": "America\/Chicago"
  },
  "OK90": {
      "icao": "OK90",
      "iata": "",
      "name": "Boatner Field",
      "city": "Calera",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 734,
      "lat": 33.9020004272,
      "lon": -96.4721984863,
      "tz": "America\/Chicago"
  },
  "OK91": {
      "icao": "OK91",
      "iata": "",
      "name": "Stidham-Private Airport",
      "city": "Chickasha",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1177,
      "lat": 34.9833984375,
      "lon": -97.9337005615,
      "tz": "America\/Chicago"
  },
  "OK93": {
      "icao": "OK93",
      "iata": "",
      "name": "Airman Acres Airport",
      "city": "Collinsville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 695,
      "lat": 36.3334007263,
      "lon": -95.8835983276,
      "tz": "America\/Chicago"
  },
  "OK94": {
      "icao": "OK94",
      "iata": "",
      "name": "Sand Ridge Airpark Inc Airport",
      "city": "Collinsville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 675,
      "lat": 36.354801178,
      "lon": -95.8032989502,
      "tz": "America\/Chicago"
  },
  "OK95": {
      "icao": "OK95",
      "iata": "",
      "name": "Disney Airport",
      "city": "Disney",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 955,
      "lat": 36.4889984131,
      "lon": -94.9582977295,
      "tz": "America\/Chicago"
  },
  "OK96": {
      "icao": "OK96",
      "iata": "",
      "name": "Ives Airport",
      "city": "Wellston",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1035,
      "lat": 35.756401062,
      "lon": -97.0848007202,
      "tz": "America\/Chicago"
  },
  "OK97": {
      "icao": "OK97",
      "iata": "",
      "name": "Ketchum Ranch Airport",
      "city": "Duncan",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1100,
      "lat": 34.5334014893,
      "lon": -97.766998291,
      "tz": "America\/Chicago"
  },
  "OK98": {
      "icao": "OK98",
      "iata": "",
      "name": "King Airport",
      "city": "Eakly",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1535,
      "lat": 35.3000984192,
      "lon": -98.4837036133,
      "tz": "America\/Chicago"
  },
  "OKAJ": {
      "icao": "OKAJ",
      "iata": "XIJ",
      "name": "Ahmed Al Jaber Air Base",
      "city": "Ahmed Al Jaber AB",
      "state": "",
      "country": "KW",
      "elevation": 409,
      "lat": 28.9347991943,
      "lon": 47.7919006348,
      "tz": "Asia\/Kuwait"
  },
  "OKAS": {
      "icao": "OKAS",
      "iata": "",
      "name": "Ali Al Salem Air Base",
      "city": "",
      "state": "",
      "country": "KW",
      "elevation": 472,
      "lat": 29.3467006683,
      "lon": 47.5208015442,
      "tz": "Asia\/Kuwait"
  },
  "OKKK": {
      "icao": "OKKK",
      "iata": "KWI",
      "name": "Kuwait International Airport",
      "city": "Kuwait City",
      "state": "",
      "country": "KW",
      "elevation": 206,
      "lat": 29.226600647,
      "lon": 47.9688987732,
      "tz": "Asia\/Kuwait"
  },
  "OKDI": {
      "icao": "OKDI",
      "iata": "",
      "name": "Udairi Army Air Field",
      "city": "Camp Buehring",
      "state": "Al-Jahra\u02bc",
      "country": "KW",
      "elevation": 430,
      "lat": 29.697599411,
      "lon": 47.436000824,
      "tz": "Asia\/Kuwait"
  },
  "OL02": {
      "icao": "OL02",
      "iata": "",
      "name": "West Buttercreek Airport",
      "city": "Echo",
      "state": "Oregon",
      "country": "US",
      "elevation": 917,
      "lat": 45.6652984619,
      "lon": -119.3860015869,
      "tz": "America\/Los_Angeles"
  },
  "OL03": {
      "icao": "OL03",
      "iata": "",
      "name": "Happy Valley Airport",
      "city": "Happy Valley",
      "state": "Oregon",
      "country": "US",
      "elevation": 797,
      "lat": 45.4482002258,
      "lon": -122.5,
      "tz": "America\/Los_Angeles"
  },
  "OL04": {
      "icao": "OL04",
      "iata": "",
      "name": "Decker Ranch Airport",
      "city": "Kent",
      "state": "Oregon",
      "country": "US",
      "elevation": 2621,
      "lat": 45.1665000916,
      "lon": -120.6679992676,
      "tz": "America\/Los_Angeles"
  },
  "OL05": {
      "icao": "OL05",
      "iata": "",
      "name": "Skydive Oregon Airport",
      "city": "Molalla",
      "state": "Oregon",
      "country": "US",
      "elevation": 360,
      "lat": 45.1461982727,
      "lon": -122.6179962158,
      "tz": "America\/Los_Angeles"
  },
  "OL09": {
      "icao": "OL09",
      "iata": "",
      "name": "Jennings Ranch Airport",
      "city": "Cooperton",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1620,
      "lat": 34.8833999634,
      "lon": -98.7502975464,
      "tz": "America\/Chicago"
  },
  "OL12": {
      "icao": "OL12",
      "iata": "",
      "name": "Northwest Edmond Airport",
      "city": "Edmond",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 1070,
      "lat": 35.7075996399,
      "lon": -97.5406036377,
      "tz": "America\/Chicago"
  },
  "OL19": {
      "icao": "OL19",
      "iata": "",
      "name": "Stuart Mountain Airpark",
      "city": "Stuart",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 990,
      "lat": 34.9154014587,
      "lon": -96.1294021606,
      "tz": "America\/Chicago"
  },
  "OL20": {
      "icao": "OL20",
      "iata": "",
      "name": "Whitehorn Cove Airport",
      "city": "Wagoner",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 568,
      "lat": 35.9972991943,
      "lon": -95.2589035034,
      "tz": "America\/Chicago"
  },
  "OL23": {
      "icao": "OL23",
      "iata": "",
      "name": "Morris Airport",
      "city": "Sapulpa",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 730,
      "lat": 35.9500999451,
      "lon": -96.1044006348,
      "tz": "America\/Chicago"
  },
  "OL92": {
      "icao": "OL92",
      "iata": "",
      "name": "Hi-Way Airport",
      "city": "Bartlesville",
      "state": "Oklahoma",
      "country": "US",
      "elevation": 680,
      "lat": 36.7834014893,
      "lon": -95.950302124,
      "tz": "America\/Chicago"
  },
  "OLBA": {
      "icao": "OLBA",
      "iata": "BEY",
      "name": "Beirut Rafic Hariri International Airport",
      "city": "Beirut",
      "state": "Mont-Liban",
      "country": "LB",
      "elevation": 87,
      "lat": 33.8208999634,
      "lon": 35.4883995056,
      "tz": "Asia\/Beirut"
  },
  "OLKA": {
      "icao": "OLKA",
      "iata": "KYE",
      "name": "Rene Mouawad Air Base",
      "city": "Tripoli",
      "state": "Liban-Nord",
      "country": "LB",
      "elevation": 75,
      "lat": 34.5892982483,
      "lon": 36.0112991333,
      "tz": "Asia\/Beirut"
  },
  "OLRA": {
      "icao": "OLRA",
      "iata": "",
      "name": "Rayak Air Base",
      "city": "Rayak",
      "state": "Beqaa",
      "country": "LB",
      "elevation": 3018,
      "lat": 33.8507995605,
      "lon": 35.987701416,
      "tz": "Asia\/Beirut"
  },
  "OMAA": {
      "icao": "OMAA",
      "iata": "AUH",
      "name": "Abu Dhabi International Airport",
      "city": "Abu Dhabi",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 88,
      "lat": 24.4330005646,
      "lon": 54.6511001587,
      "tz": "Asia\/Dubai"
  },
  "OMAB": {
      "icao": "OMAB",
      "iata": "",
      "name": "Buhasa Airport",
      "city": "",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 0,
      "lat": 23.5997009277,
      "lon": 53.3793983459,
      "tz": "Asia\/Dubai"
  },
  "OMAD": {
      "icao": "OMAD",
      "iata": "AZI",
      "name": "Al Bateen Executive Airport",
      "city": "",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 16,
      "lat": 24.4283008575,
      "lon": 54.4580993652,
      "tz": "Asia\/Dubai"
  },
  "OMAF": {
      "icao": "OMAF",
      "iata": "",
      "name": "Futaysi",
      "city": "",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 0,
      "lat": 24.3788890839,
      "lon": 54.3161125183,
      "tz": "Asia\/Dubai"
  },
  "OMAH": {
      "icao": "OMAH",
      "iata": "",
      "name": "Al Hamra Aux Airport",
      "city": "",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 50,
      "lat": 24.0739994049,
      "lon": 52.4636001587,
      "tz": "Asia\/Dubai"
  },
  "OMAJ": {
      "icao": "OMAJ",
      "iata": "",
      "name": "Jebel Dhana Airport",
      "city": "",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 43,
      "lat": 24.1874008179,
      "lon": 52.6139984131,
      "tz": "Asia\/Dubai"
  },
  "OMAL": {
      "icao": "OMAL",
      "iata": "AAN",
      "name": "Al Ain International Airport",
      "city": "Al Ain",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 869,
      "lat": 24.2616996765,
      "lon": 55.6091995239,
      "tz": "Asia\/Dubai"
  },
  "OMAM": {
      "icao": "OMAM",
      "iata": "DHF",
      "name": "Al Dhafra Air Base",
      "city": "",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 77,
      "lat": 24.2481994629,
      "lon": 54.5476989746,
      "tz": "Asia\/Dubai"
  },
  "OMAQ": {
      "icao": "OMAQ",
      "iata": "",
      "name": "Qarnayn Airport",
      "city": "Qarnayn Island",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 0,
      "lat": 24.9305992126,
      "lon": 52.8550987244,
      "tz": "Asia\/Dubai"
  },
  "OMAR": {
      "icao": "OMAR",
      "iata": "",
      "name": "Arzanah Airport",
      "city": "Arzanah Island",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 15,
      "lat": 24.780500412,
      "lon": 52.5598983765,
      "tz": "Asia\/Dubai"
  },
  "OMAS": {
      "icao": "OMAS",
      "iata": "",
      "name": "Das Island Airport",
      "city": "Das Island",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 12,
      "lat": 25.1462001801,
      "lon": 52.8736991882,
      "tz": "Asia\/Dubai"
  },
  "OMAZ": {
      "icao": "OMAZ",
      "iata": "",
      "name": "Zirku Airport",
      "city": "Zirku Island",
      "state": "Abu-Dhabi",
      "country": "AE",
      "elevation": 14,
      "lat": 24.8624916077,
      "lon": 53.0771408081,
      "tz": "Asia\/Dubai"
  },
  "OMDB": {
      "icao": "OMDB",
      "iata": "DXB",
      "name": "Dubai International Airport",
      "city": "Dubai",
      "state": "Dubai",
      "country": "AE",
      "elevation": 34,
      "lat": 25.2527999878,
      "lon": 55.3643989563,
      "tz": "Asia\/Dubai"
  },
  "OMDM": {
      "icao": "OMDM",
      "iata": "NHD",
      "name": "Al Minhad Air Base",
      "city": "Dubai",
      "state": "Dubai",
      "country": "AE",
      "elevation": 165,
      "lat": 25.0268001556,
      "lon": 55.3661994934,
      "tz": "Asia\/Dubai"
  },
  "OMDW": {
      "icao": "OMDW",
      "iata": "DWC",
      "name": "Al Maktoum International Airport",
      "city": "Jebel Ali",
      "state": "Dubai",
      "country": "AE",
      "elevation": 114,
      "lat": 24.8966666667,
      "lon": 55.1613888889,
      "tz": "Asia\/Dubai"
  },
  "OMFJ": {
      "icao": "OMFJ",
      "iata": "FJR",
      "name": "Fujairah International Airport",
      "city": "",
      "state": "Al-Fujayrah",
      "country": "AE",
      "elevation": 152,
      "lat": 25.1121997833,
      "lon": 56.3240013123,
      "tz": "Asia\/Dubai"
  },
  "OMRK": {
      "icao": "OMRK",
      "iata": "RKT",
      "name": "Ras Al Khaimah International Airport",
      "city": "Ras Al Khaimah",
      "state": "Ra\u02bcs-al-Khaymah",
      "country": "AE",
      "elevation": 102,
      "lat": 25.6135005951,
      "lon": 55.9388008118,
      "tz": "Asia\/Dubai"
  },
  "OMRS": {
      "icao": "OMRS",
      "iata": "",
      "name": "Al Saqr Field",
      "city": "",
      "state": "Ra\u02bcs-al-Khaymah",
      "country": "AE",
      "elevation": 0,
      "lat": 25.7527770996,
      "lon": 55.9594459534,
      "tz": "Asia\/Dubai"
  },
  "OMSJ": {
      "icao": "OMSJ",
      "iata": "SHJ",
      "name": "Sharjah International Airport",
      "city": "Sharjah",
      "state": "Ash-Shariqah",
      "country": "AE",
      "elevation": 111,
      "lat": 25.3285999298,
      "lon": 55.51720047,
      "tz": "Asia\/Dubai"
  },
  "OMU9": {
      "icao": "OMU9",
      "iata": "",
      "name": "Kollmeyer Airport",
      "city": "Pilot Grove",
      "state": "Missouri",
      "country": "US",
      "elevation": 810,
      "lat": 38.8842010498,
      "lon": -92.985496521,
      "tz": "America\/Chicago"
  },
  "OMUQ": {
      "icao": "OMUQ",
      "iata": "",
      "name": "Umm Al Quwain",
      "city": "",
      "state": "Umm-al-Qaywayn",
      "country": "AE",
      "elevation": 0,
      "lat": 25.5772228241,
      "lon": 55.6511116028,
      "tz": "Asia\/Dubai"
  },
  "OOBR": {
      "icao": "OOBR",
      "iata": "RMB",
      "name": "Buraimi Airport",
      "city": "Buraimi",
      "state": "Al-Buraimi",
      "country": "OM",
      "elevation": 970,
      "lat": 24.2329998016,
      "lon": 55.783000946,
      "tz": "Asia\/Dubai"
  },
  "OODQ": {
      "icao": "OODQ",
      "iata": "DQM",
      "name": "Duqm International Airport",
      "city": "Duqm",
      "state": "Duqm",
      "country": "OM",
      "elevation": 383,
      "lat": 19.5019,
      "lon": 57.6342,
      "tz": "Asia\/Muscat"
  },
  "OOFD": {
      "icao": "OOFD",
      "iata": "FAU",
      "name": "Fahud Airport",
      "city": "Fahud",
      "state": "Az\u0327-Z\u0327ahirah",
      "country": "OM",
      "elevation": 552,
      "lat": 22.354759318,
      "lon": 56.4841461182,
      "tz": "Asia\/Muscat"
  },
  "OOFQ": {
      "icao": "OOFQ",
      "iata": "",
      "name": "Firq Air Base",
      "city": "Firq",
      "state": "Ad-Dakhiliyah",
      "country": "OM",
      "elevation": 1560,
      "lat": 22.8691997528,
      "lon": 57.5433006287,
      "tz": "Asia\/Muscat"
  },
  "OOGB": {
      "icao": "OOGB",
      "iata": "RNM",
      "name": "Qarn Alam Airport",
      "city": "Ghaba",
      "state": "Al-Wus\u0163a",
      "country": "OM",
      "elevation": 443,
      "lat": 21.3829994202,
      "lon": 57.0499992371,
      "tz": "Asia\/Muscat"
  },
  "OOHA": {
      "icao": "OOHA",
      "iata": "",
      "name": "Haima Airport",
      "city": "Haima",
      "state": "Al-Wus\u0163a",
      "country": "OM",
      "elevation": 400,
      "lat": 19.966999054,
      "lon": 56.283000946,
      "tz": "Asia\/Muscat"
  },
  "OOIA": {
      "icao": "OOIA",
      "iata": "",
      "name": "Ibra Airport",
      "city": "Ibra",
      "state": "Northeastern-Governorate",
      "country": "OM",
      "elevation": 1500,
      "lat": 22.7327777778,
      "lon": 58.5136111111,
      "tz": "Asia\/Muscat"
  },
  "OOIZ": {
      "icao": "OOIZ",
      "iata": "",
      "name": "Izki Air Base",
      "city": "Izki",
      "state": "Ad-Dakhiliyah",
      "country": "OM",
      "elevation": 1700,
      "lat": 22.8917007446,
      "lon": 57.7583007813,
      "tz": "Asia\/Muscat"
  },
  "OOKB": {
      "icao": "OOKB",
      "iata": "KHS",
      "name": "Khasab Air Base",
      "city": "Khasab",
      "state": "Musandam",
      "country": "OM",
      "elevation": 100,
      "lat": 26.170999527,
      "lon": 56.2406005859,
      "tz": "Asia\/Muscat"
  },
  "OOLK": {
      "icao": "OOLK",
      "iata": "LKW",
      "name": "Lekhwair Airport",
      "city": "",
      "state": "Az\u0327-Z\u0327ahirah",
      "country": "OM",
      "elevation": 354,
      "lat": 22.8049846844,
      "lon": 55.3733682632,
      "tz": "Asia\/Muscat"
  },
  "OOMA": {
      "icao": "OOMA",
      "iata": "MSH",
      "name": "Masirah Air Base",
      "city": "Masirah",
      "state": "Southeastern-Governorate",
      "country": "OM",
      "elevation": 64,
      "lat": 20.6753997803,
      "lon": 58.890499115,
      "tz": "Asia\/Muscat"
  },
  "OOMS": {
      "icao": "OOMS",
      "iata": "MCT",
      "name": "Muscat International Airport",
      "city": "Muscat",
      "state": "Muscat",
      "country": "OM",
      "elevation": 48,
      "lat": 23.5932998657,
      "lon": 58.2844009399,
      "tz": "Asia\/Muscat"
  },
  "OOMX": {
      "icao": "OOMX",
      "iata": "OMM",
      "name": "Marmul Airport",
      "city": "Marmul",
      "state": "Dhofar",
      "country": "OM",
      "elevation": 925,
      "lat": 18.1359996796,
      "lon": 55.1820983887,
      "tz": "Asia\/Muscat"
  },
  "OORQ": {
      "icao": "OORQ",
      "iata": "MNH",
      "name": "Rustaq Airport",
      "city": "Al Muladdah",
      "state": "Al-Batinah-South",
      "country": "OM",
      "elevation": 349,
      "lat": 23.6405555556,
      "lon": 57.4875,
      "tz": "Asia\/Muscat"
  },
  "OOSA": {
      "icao": "OOSA",
      "iata": "SLL",
      "name": "Salalah Airport",
      "city": "Salalah",
      "state": "Dhofar",
      "country": "OM",
      "elevation": 73,
      "lat": 17.0387001038,
      "lon": 54.0913009644,
      "tz": "Asia\/Muscat"
  },
  "OOSH": {
      "icao": "OOSH",
      "iata": "OHS",
      "name": "Sohar Airport",
      "city": "Sohar",
      "state": "Al-Batinah-North",
      "country": "OM",
      "elevation": 105,
      "lat": 24.4641666667,
      "lon": 56.6283333333,
      "tz": "Asia\/Muscat"
  },
  "OOSQ": {
      "icao": "OOSQ",
      "iata": "",
      "name": "Saiq Airport",
      "city": "Saiq",
      "state": "Ad-Dakhiliyah",
      "country": "OM",
      "elevation": 6500,
      "lat": 23.0669994354,
      "lon": 57.6500015259,
      "tz": "Asia\/Muscat"
  },
  "OOSR": {
      "icao": "OOSR",
      "iata": "SUH",
      "name": "Sur Airport",
      "city": "Sur",
      "state": "Southeastern-Governorate",
      "country": "OM",
      "elevation": 20,
      "lat": 22.533000946,
      "lon": 59.483001709,
      "tz": "Asia\/Muscat"
  },
  "OOTH": {
      "icao": "OOTH",
      "iata": "TTH",
      "name": "Thumrait Air Base",
      "city": "Thumrait",
      "state": "Dhofar",
      "country": "OM",
      "elevation": 1570,
      "lat": 17.6660003662,
      "lon": 54.0246009827,
      "tz": "Asia\/Muscat"
  },
  "OOYB": {
      "icao": "OOYB",
      "iata": "",
      "name": "Yibal Airport",
      "city": "Yibal Oilfield",
      "state": "Az\u0327-Z\u0327ahirah",
      "country": "OM",
      "elevation": 0,
      "lat": 22.201499939,
      "lon": 56.0344009399,
      "tz": "Asia\/Muscat"
  },
  "OP10": {
      "icao": "OP10",
      "iata": "",
      "name": "Thar Airport",
      "city": "Chuchra",
      "state": "Sindh",
      "country": "PK",
      "elevation": 167,
      "lat": 27.2005004883,
      "lon": 69.1538009644,
      "tz": "Asia\/Karachi"
  },
  "OP11": {
      "icao": "OP11",
      "iata": "",
      "name": "Mir Baz Airport",
      "city": "Mir Baz",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 2350,
      "lat": 31.1877994537,
      "lon": 70.1845016479,
      "tz": "Asia\/Karachi"
  },
  "OP13": {
      "icao": "OP13",
      "iata": "",
      "name": "Mad Jamu Airport",
      "city": "Mad Jamu Kholelan",
      "state": "Punjab",
      "country": "PK",
      "elevation": 256,
      "lat": 28.3561992645,
      "lon": 70.6001968384,
      "tz": "Asia\/Karachi"
  },
  "OP14": {
      "icao": "OP14",
      "iata": "",
      "name": "Belab Airport",
      "city": "Belab",
      "state": "Punjab",
      "country": "PK",
      "elevation": 377,
      "lat": 30.3400001526,
      "lon": 70.5567016602,
      "tz": "Asia\/Karachi"
  },
  "OP15": {
      "icao": "OP15",
      "iata": "",
      "name": "Ouzkani Airport",
      "city": "Khandor",
      "state": "Punjab",
      "country": "PK",
      "elevation": 2331,
      "lat": 30.2528991699,
      "lon": 70.2128982544,
      "tz": "Asia\/Karachi"
  },
  "OP16": {
      "icao": "OP16",
      "iata": "",
      "name": "Khorewah Airport",
      "city": "Khorewah",
      "state": "Sindh",
      "country": "PK",
      "elevation": 16,
      "lat": 24.7872009277,
      "lon": 68.5571975708,
      "tz": "Asia\/Karachi"
  },
  "OP17": {
      "icao": "OP17",
      "iata": "",
      "name": "Dadu West Airport",
      "city": "Dadu",
      "state": "Sindh",
      "country": "PK",
      "elevation": 128,
      "lat": 26.7408008575,
      "lon": 67.6666030884,
      "tz": "Asia\/Karachi"
  },
  "OP18": {
      "icao": "OP18",
      "iata": "",
      "name": "Kot Addu Airport",
      "city": "Kot  Addu",
      "state": "Punjab",
      "country": "PK",
      "elevation": 423,
      "lat": 30.4948997498,
      "lon": 70.9710998535,
      "tz": "Asia\/Karachi"
  },
  "OP19": {
      "icao": "OP19",
      "iata": "",
      "name": "Chashma Airport",
      "city": "Kundian",
      "state": "Punjab",
      "country": "PK",
      "elevation": 645,
      "lat": 32.4244995117,
      "lon": 71.4585037231,
      "tz": "Asia\/Karachi"
  },
  "OP1A": {
      "icao": "OP1A",
      "iata": "",
      "name": "Jam Nida Northwest Airport",
      "city": "Jam Nida",
      "state": "Sindh",
      "country": "PK",
      "elevation": 420,
      "lat": 26.1980991364,
      "lon": 67.5037002563,
      "tz": "Asia\/Karachi"
  },
  "OP1Y": {
      "icao": "OP1Y",
      "iata": "",
      "name": "Chandhar Airport",
      "city": "Hafizabad",
      "state": "Punjab",
      "country": "PK",
      "elevation": 620,
      "lat": 32.0778007507,
      "lon": 73.7901992798,
      "tz": "Asia\/Karachi"
  },
  "OP21": {
      "icao": "OP21",
      "iata": "",
      "name": "Velhari Airport",
      "city": "Velhari",
      "state": "Punjab",
      "country": "PK",
      "elevation": 430,
      "lat": 30.0914001465,
      "lon": 72.1538009644,
      "tz": "Asia\/Karachi"
  },
  "OP22": {
      "icao": "OP22",
      "iata": "",
      "name": "Rajanpur Airport",
      "city": "Rajanpur",
      "state": "Punjab",
      "country": "PK",
      "elevation": 401,
      "lat": 29.263999939,
      "lon": 70.1864013672,
      "tz": "Asia\/Karachi"
  },
  "OP23": {
      "icao": "OP23",
      "iata": "",
      "name": "Thal Airport",
      "city": "Thal",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 2650,
      "lat": 33.3875999451,
      "lon": 70.5887985229,
      "tz": "Asia\/Karachi"
  },
  "OP25": {
      "icao": "OP25",
      "iata": "",
      "name": "Gurha Salim Airport",
      "city": "Gurha Salim",
      "state": "Punjab",
      "country": "PK",
      "elevation": 800,
      "lat": 32.8785018921,
      "lon": 73.6053009033,
      "tz": "Asia\/Karachi"
  },
  "OP26": {
      "icao": "OP26",
      "iata": "",
      "name": "Khewra Airport",
      "city": "Khewra",
      "state": "Punjab",
      "country": "PK",
      "elevation": 767,
      "lat": 32.6291007996,
      "lon": 73.0225982666,
      "tz": "Asia\/Karachi"
  },
  "OP27": {
      "icao": "OP27",
      "iata": "",
      "name": "Rahwali Airport",
      "city": "Rahwali",
      "state": "Punjab",
      "country": "PK",
      "elevation": 745,
      "lat": 32.2389984131,
      "lon": 74.1309967041,
      "tz": "Asia\/Karachi"
  },
  "OP28": {
      "icao": "OP28",
      "iata": "",
      "name": "Dhingar Airport",
      "city": "Dhingar",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 5215,
      "lat": 29.9232997894,
      "lon": 66.7461013794,
      "tz": "Asia\/Karachi"
  },
  "OP31": {
      "icao": "OP31",
      "iata": "",
      "name": "Kashmor Airport",
      "city": "Kashmor",
      "state": "Sindh",
      "country": "PK",
      "elevation": 260,
      "lat": 28.4706001282,
      "lon": 69.5990982056,
      "tz": "Asia\/Karachi"
  },
  "OP32": {
      "icao": "OP32",
      "iata": "",
      "name": "Khairpur Airport",
      "city": "Khairpur",
      "state": "Sindh",
      "country": "PK",
      "elevation": 300,
      "lat": 28.0361003876,
      "lon": 69.6748962402,
      "tz": "Asia\/Karachi"
  },
  "OP33": {
      "icao": "OP33",
      "iata": "",
      "name": "Kandhkot Airport",
      "city": "Kandhkot",
      "state": "Sindh",
      "country": "PK",
      "elevation": 250,
      "lat": 28.2740001678,
      "lon": 69.2760009766,
      "tz": "Asia\/Karachi"
  },
  "OP34": {
      "icao": "OP34",
      "iata": "",
      "name": "Nok Kundi Airport",
      "city": "Nok Kundi",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 2227,
      "lat": 28.8194007874,
      "lon": 62.7307014465,
      "tz": "Asia\/Karachi"
  },
  "OP35": {
      "icao": "OP35",
      "iata": "",
      "name": "Juzzak Airport",
      "city": "Juzzak",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 2861,
      "lat": 29.0405006409,
      "lon": 61.6473999023,
      "tz": "Asia\/Karachi"
  },
  "OP36": {
      "icao": "OP36",
      "iata": "",
      "name": "Robray Airport",
      "city": "",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 1840,
      "lat": 26.2518997192,
      "lon": 63.1302986145,
      "tz": "Asia\/Karachi"
  },
  "OP37": {
      "icao": "OP37",
      "iata": "",
      "name": "Pano Aqil Southeast Airport",
      "city": "Pano Aqil",
      "state": "Sindh",
      "country": "PK",
      "elevation": 200,
      "lat": 27.8131008148,
      "lon": 69.167098999,
      "tz": "Asia\/Karachi"
  },
  "OPAB": {
      "icao": "OPAB",
      "iata": "AAW",
      "name": "Abbottabad Airport",
      "city": "Abbottabad",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 4072,
      "lat": 34.2000007629,
      "lon": 73.25,
      "tz": "Asia\/Karachi"
  },
  "OPBG": {
      "icao": "OPBG",
      "iata": "BHW",
      "name": "Bhagatanwala Airport",
      "city": "Bhagatanwala",
      "state": "Punjab",
      "country": "PK",
      "elevation": 600,
      "lat": 32.056098938,
      "lon": 72.9484024048,
      "tz": "Asia\/Karachi"
  },
  "OPBL": {
      "icao": "OPBL",
      "iata": "",
      "name": "Bela Airport",
      "city": "Khan Bela",
      "state": "Punjab",
      "country": "PK",
      "elevation": 347,
      "lat": 29.4922008514,
      "lon": 71.1184005737,
      "tz": "Asia\/Karachi"
  },
  "OPBN": {
      "icao": "OPBN",
      "iata": "BNP",
      "name": "Bannu Airport",
      "city": "Bannu",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 1325,
      "lat": 32.9729003906,
      "lon": 70.5279006958,
      "tz": "Asia\/Karachi"
  },
  "OPBR": {
      "icao": "OPBR",
      "iata": "WGB",
      "name": "Bahawalnagar Airport",
      "city": "Bahawalnagar",
      "state": "Punjab",
      "country": "PK",
      "elevation": 500,
      "lat": 29.9463005066,
      "lon": 73.2490997314,
      "tz": "Asia\/Karachi"
  },
  "OPBW": {
      "icao": "OPBW",
      "iata": "BHV",
      "name": "Bahawalpur Airport",
      "city": "Bahawalpur",
      "state": "Punjab",
      "country": "PK",
      "elevation": 392,
      "lat": 29.3481006622,
      "lon": 71.7180023193,
      "tz": "Asia\/Karachi"
  },
  "OPCH": {
      "icao": "OPCH",
      "iata": "CJL",
      "name": "Chitral Airport",
      "city": "Chitral",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 4920,
      "lat": 35.8866004944,
      "lon": 71.8005981445,
      "tz": "Asia\/Karachi"
  },
  "OPCL": {
      "icao": "OPCL",
      "iata": "CHB",
      "name": "Chilas Airport",
      "city": "Chilas",
      "state": "Gilgit-Baltistan",
      "country": "PK",
      "elevation": 4146,
      "lat": 35.426700592,
      "lon": 74.081703186,
      "tz": "Asia\/Karachi"
  },
  "OPCT": {
      "icao": "OPCT",
      "iata": "",
      "name": "Chirat Airport",
      "city": "Nowshera",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 1192,
      "lat": 33.9681015015,
      "lon": 71.9674987793,
      "tz": "Asia\/Karachi"
  },
  "OPDB": {
      "icao": "OPDB",
      "iata": "DBA",
      "name": "Dalbandin Airport",
      "city": "Dalbandin",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 2800,
      "lat": 28.8782997131,
      "lon": 64.3998031616,
      "tz": "Asia\/Karachi"
  },
  "OPDD": {
      "icao": "OPDD",
      "iata": "DDU",
      "name": "Dadu Airport",
      "city": "Dadu",
      "state": "Sindh",
      "country": "PK",
      "elevation": 121,
      "lat": 26.5545005798,
      "lon": 67.6745986938,
      "tz": "Asia\/Karachi"
  },
  "OPDG": {
      "icao": "OPDG",
      "iata": "DEA",
      "name": "Dera Ghazi Khan Airport",
      "city": "Dera Ghazi Khan",
      "state": "Punjab",
      "country": "PK",
      "elevation": 492,
      "lat": 29.9610004425,
      "lon": 70.4859008789,
      "tz": "Asia\/Karachi"
  },
  "OPDI": {
      "icao": "OPDI",
      "iata": "DSK",
      "name": "Dera Ismael Khan Airport",
      "city": "Dera Ismael Khan",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 594,
      "lat": 31.9094009399,
      "lon": 70.8965988159,
      "tz": "Asia\/Karachi"
  },
  "OPDK": {
      "icao": "OPDK",
      "iata": "",
      "name": "Daharki Airport",
      "city": "Daharki",
      "state": "Punjab",
      "country": "PK",
      "elevation": 274,
      "lat": 28.5,
      "lon": 69.6999969482,
      "tz": "Asia\/Karachi"
  },
  "OPFA": {
      "icao": "OPFA",
      "iata": "LYP",
      "name": "Faisalabad International Airport",
      "city": "Faisalabad",
      "state": "Punjab",
      "country": "PK",
      "elevation": 591,
      "lat": 31.3649997711,
      "lon": 72.9947967529,
      "tz": "Asia\/Karachi"
  },
  "OPGD": {
      "icao": "OPGD",
      "iata": "GWD",
      "name": "Gwadar International Airport",
      "city": "Gwadar",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 36,
      "lat": 25.2332992554,
      "lon": 62.329498291,
      "tz": "Asia\/Karachi"
  },
  "OPGT": {
      "icao": "OPGT",
      "iata": "GIL",
      "name": "Gilgit Airport",
      "city": "Gilgit",
      "state": "Gilgit-Baltistan",
      "country": "PK",
      "elevation": 4796,
      "lat": 35.918800354,
      "lon": 74.3336029053,
      "tz": "Asia\/Karachi"
  },
  "OPIS": {
      "icao": "OPIS",
      "iata": "ISB",
      "name": "Islamabad International Airport",
      "city": "Islamabad",
      "state": "Punjab",
      "country": "PK",
      "elevation": 1761,
      "lat": 33.5490833333333,
      "lon": 72.82565,
      "tz": "Asia\/Karachi"
  },
  "OPJA": {
      "icao": "OPJA",
      "iata": "JAG",
      "name": "Shahbaz Air Base",
      "city": "Jacobabad",
      "state": "Sindh",
      "country": "PK",
      "elevation": 185,
      "lat": 28.2842006683,
      "lon": 68.4496994019,
      "tz": "Asia\/Karachi"
  },
  "OPJI": {
      "icao": "OPJI",
      "iata": "JIW",
      "name": "Jiwani Airport",
      "city": "Jiwani",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 186,
      "lat": 25.0678005219,
      "lon": 61.8054008484,
      "tz": "Asia\/Karachi"
  },
  "OPKA": {
      "icao": "OPKA",
      "iata": "",
      "name": "Cape Monz Airport",
      "city": "Cape Monz",
      "state": "Sindh",
      "country": "PK",
      "elevation": 40,
      "lat": 24.8279323578,
      "lon": 66.6644287109,
      "tz": "Asia\/Karachi"
  },
  "OPKC": {
      "icao": "OPKC",
      "iata": "KHI",
      "name": "Jinnah International Airport",
      "city": "Karachi",
      "state": "Sindh",
      "country": "PK",
      "elevation": 100,
      "lat": 24.9064998627,
      "lon": 67.1607971191,
      "tz": "Asia\/Karachi"
  },
  "OPKD": {
      "icao": "OPKD",
      "iata": "HDD",
      "name": "Hyderabad Airport",
      "city": "Hyderabad",
      "state": "Sindh",
      "country": "PK",
      "elevation": 130,
      "lat": 25.3180999756,
      "lon": 68.3660964966,
      "tz": "Asia\/Karachi"
  },
  "OPKE": {
      "icao": "OPKE",
      "iata": "",
      "name": "Chore Airport",
      "city": "Chore",
      "state": "Sindh",
      "country": "PK",
      "elevation": 200,
      "lat": 25.5259990692,
      "lon": 69.7717971802,
      "tz": "Asia\/Karachi"
  },
  "OPKF": {
      "icao": "OPKF",
      "iata": "",
      "name": "Gharo Airport",
      "city": "Gharo",
      "state": "Sindh",
      "country": "PK",
      "elevation": 20,
      "lat": 24.7290000916,
      "lon": 67.587600708,
      "tz": "Asia\/Karachi"
  },
  "OPKH": {
      "icao": "OPKH",
      "iata": "KDD",
      "name": "Khuzdar Airport",
      "city": "Khuzdar",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 4012,
      "lat": 27.790599823,
      "lon": 66.6473007202,
      "tz": "Asia\/Karachi"
  },
  "OPKK": {
      "icao": "OPKK",
      "iata": "",
      "name": "Korangi Creek Airport",
      "city": "Karachi",
      "state": "Sindh",
      "country": "PK",
      "elevation": 3,
      "lat": 24.7842998505,
      "lon": 67.138999939,
      "tz": "Asia\/Karachi"
  },
  "OPKL": {
      "icao": "OPKL",
      "iata": "KBH",
      "name": "Kalat Airport",
      "city": "Kalat",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 6100,
      "lat": 29.1333332062,
      "lon": 66.5166702271,
      "tz": "Asia\/Karachi"
  },
  "OPKN": {
      "icao": "OPKN",
      "iata": "",
      "name": "Kharan Airport",
      "city": "Kharan",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 2408,
      "lat": 28.5972003937,
      "lon": 65.4225006104,
      "tz": "Asia\/Karachi"
  },
  "OPKT": {
      "icao": "OPKT",
      "iata": "OHT",
      "name": "Kohat Airport",
      "city": "Kohat",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 1650,
      "lat": 33.5699996948,
      "lon": 71.4400024414,
      "tz": "Asia\/Karachi"
  },
  "OPLA": {
      "icao": "OPLA",
      "iata": "LHE",
      "name": "Alama Iqbal International Airport",
      "city": "Lahore",
      "state": "Punjab",
      "country": "PK",
      "elevation": 712,
      "lat": 31.5216007233,
      "lon": 74.4036026001,
      "tz": "Asia\/Karachi"
  },
  "OPLH": {
      "icao": "OPLH",
      "iata": "",
      "name": "Walton Airport",
      "city": "Walton",
      "state": "Punjab",
      "country": "PK",
      "elevation": 679,
      "lat": 31.4948005676,
      "lon": 74.3461990356,
      "tz": "Asia\/Karachi"
  },
  "OPLL": {
      "icao": "OPLL",
      "iata": "LRG",
      "name": "Loralai Airport",
      "city": "Loralai",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 4631,
      "lat": 30.3554992676,
      "lon": 68.6135025024,
      "tz": "Asia\/Karachi"
  },
  "OPMA": {
      "icao": "OPMA",
      "iata": "XJM",
      "name": "Mangla Airport",
      "city": "Mangla",
      "state": "Punjab",
      "country": "PK",
      "elevation": 902,
      "lat": 33.0500984192,
      "lon": 73.6383972168,
      "tz": "Asia\/Karachi"
  },
  "OPMF": {
      "icao": "OPMF",
      "iata": "MFG",
      "name": "Muzaffarabad Airport",
      "city": "Muzaffarabad",
      "state": "Azad-Kashmir",
      "country": "PK",
      "elevation": 2691,
      "lat": 34.3390007019,
      "lon": 73.5085983276,
      "tz": "Asia\/Karachi"
  },
  "OPMI": {
      "icao": "OPMI",
      "iata": "MWD",
      "name": "Mianwali Air Base",
      "city": "Mianwali",
      "state": "Punjab",
      "country": "PK",
      "elevation": 690,
      "lat": 32.5630989075,
      "lon": 71.5707015991,
      "tz": "Asia\/Karachi"
  },
  "OPMJ": {
      "icao": "OPMJ",
      "iata": "MJD",
      "name": "Moenjodaro Airport",
      "city": "Moenjodaro",
      "state": "Sindh",
      "country": "PK",
      "elevation": 154,
      "lat": 27.3351993561,
      "lon": 68.1430969238,
      "tz": "Asia\/Karachi"
  },
  "OPMK": {
      "icao": "OPMK",
      "iata": "",
      "name": "Mirpur Khas Air Base",
      "city": "Mirpur Khas",
      "state": "Sindh",
      "country": "PK",
      "elevation": 60,
      "lat": 25.6825008392,
      "lon": 69.0727996826,
      "tz": "Asia\/Karachi"
  },
  "OPMN": {
      "icao": "OPMN",
      "iata": "",
      "name": "Miram Shah Airport",
      "city": "Miram Shah",
      "state": "FATA",
      "country": "PK",
      "elevation": 3022,
      "lat": 33.0147018433,
      "lon": 70.0643997192,
      "tz": "Asia\/Karachi"
  },
  "OPMP": {
      "icao": "OPMP",
      "iata": "MPD",
      "name": "Sindhri Tharparkar Airport",
      "city": "Sindhri",
      "state": "Sindh",
      "country": "PK",
      "elevation": 51,
      "lat": 25.682800293,
      "lon": 69.0727996826,
      "tz": "Asia\/Karachi"
  },
  "OPMR": {
      "icao": "OPMR",
      "iata": "",
      "name": "Masroor Air Base",
      "city": "Karachi",
      "state": "Sindh",
      "country": "PK",
      "elevation": 35,
      "lat": 24.8936004639,
      "lon": 66.9387969971,
      "tz": "Asia\/Karachi"
  },
  "OPMS": {
      "icao": "OPMS",
      "iata": "",
      "name": "Minhas Air Base",
      "city": "Kamra",
      "state": "Punjab",
      "country": "PK",
      "elevation": 1023,
      "lat": 33.8690986633,
      "lon": 72.4009017944,
      "tz": "Asia\/Karachi"
  },
  "OPMT": {
      "icao": "OPMT",
      "iata": "MUX",
      "name": "Multan International Airport",
      "city": "Multan",
      "state": "Punjab",
      "country": "PK",
      "elevation": 403,
      "lat": 30.2031993866,
      "lon": 71.4190979004,
      "tz": "Asia\/Karachi"
  },
  "OPNH": {
      "icao": "OPNH",
      "iata": "WNS",
      "name": "Nawabshah Airport",
      "city": "Nawabash",
      "state": "Sindh",
      "country": "PK",
      "elevation": 95,
      "lat": 26.2194004059,
      "lon": 68.3900985718,
      "tz": "Asia\/Karachi"
  },
  "OPNK": {
      "icao": "OPNK",
      "iata": "NHS",
      "name": "Nushki Airport",
      "city": "Nushki",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 3200,
      "lat": 29.5389995575,
      "lon": 66.0233001709,
      "tz": "Asia\/Karachi"
  },
  "OPOK": {
      "icao": "OPOK",
      "iata": "",
      "name": "Okara Cantonment Airstrip",
      "city": "Okara",
      "state": "Punjab",
      "country": "PK",
      "elevation": 568,
      "lat": 30.7409992218,
      "lon": 73.3576965332,
      "tz": "Asia\/Karachi"
  },
  "OPOR": {
      "icao": "OPOR",
      "iata": "ORW",
      "name": "Ormara Airport",
      "city": "Ormara Raik",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 10,
      "lat": 25.2747001648,
      "lon": 64.5859985352,
      "tz": "Asia\/Karachi"
  },
  "OPPC": {
      "icao": "OPPC",
      "iata": "PAJ",
      "name": "Parachinar Airport",
      "city": "Parachinar",
      "state": "FATA",
      "country": "PK",
      "elevation": 5800,
      "lat": 33.9020996094,
      "lon": 70.0716018677,
      "tz": "Asia\/Karachi"
  },
  "OPPG": {
      "icao": "OPPG",
      "iata": "PJG",
      "name": "Panjgur Airport",
      "city": "Panjgur",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 3289,
      "lat": 26.9545001984,
      "lon": 64.1324996948,
      "tz": "Asia\/Karachi"
  },
  "OPPI": {
      "icao": "OPPI",
      "iata": "PSI",
      "name": "Pasni Airport",
      "city": "Pasni",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 33,
      "lat": 25.2905006409,
      "lon": 63.3451004028,
      "tz": "Asia\/Karachi"
  },
  "OPPN": {
      "icao": "OPPN",
      "iata": "",
      "name": "Pishin Airport",
      "city": "Pishin",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 5075,
      "lat": 30.5562000275,
      "lon": 66.985496521,
      "tz": "Asia\/Karachi"
  },
  "OPPS": {
      "icao": "OPPS",
      "iata": "PEW",
      "name": "Peshawar International Airport",
      "city": "Peshawar",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 1158,
      "lat": 33.9939002991,
      "lon": 71.5146026611,
      "tz": "Asia\/Karachi"
  },
  "OPQS": {
      "icao": "OPQS",
      "iata": "",
      "name": "Qasim Airport",
      "city": "Qasim",
      "state": "Punjab",
      "country": "PK",
      "elevation": 1581,
      "lat": 33.5601997375,
      "lon": 73.033203125,
      "tz": "Asia\/Karachi"
  },
  "OPQT": {
      "icao": "OPQT",
      "iata": "UET",
      "name": "Quetta International Airport",
      "city": "Quetta",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 5267,
      "lat": 30.2513999939,
      "lon": 66.9377975464,
      "tz": "Asia\/Karachi"
  },
  "OPRK": {
      "icao": "OPRK",
      "iata": "RYK",
      "name": "Shaikh Zaid Airport",
      "city": "Rahim Yar Khan",
      "state": "Punjab",
      "country": "PK",
      "elevation": 271,
      "lat": 28.3838996887,
      "lon": 70.2796020508,
      "tz": "Asia\/Karachi"
  },
  "OPRN": {
      "icao": "OPRN",
      "iata": "ISB",
      "name": "Benazir Bhutto International Airport",
      "city": "Islamabad",
      "state": "Punjab",
      "country": "PK",
      "elevation": 1668,
      "lat": 33.6166992188,
      "lon": 73.0991973877,
      "tz": "Asia\/Karachi"
  },
  "OPRQ": {
      "icao": "OPRQ",
      "iata": "",
      "name": "Rafiqui Air Base",
      "city": "Shorkot",
      "state": "Punjab",
      "country": "PK",
      "elevation": 492,
      "lat": 30.7581005096,
      "lon": 72.2825012207,
      "tz": "Asia\/Karachi"
  },
  "OPRS": {
      "icao": "OPRS",
      "iata": "",
      "name": "Risalpur Air Base",
      "city": "Risalpur",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 1050,
      "lat": 34.0811004639,
      "lon": 71.9726028442,
      "tz": "Asia\/Karachi"
  },
  "OPRT": {
      "icao": "OPRT",
      "iata": "RAZ",
      "name": "Rawalakot Airport",
      "city": "Rawalakot",
      "state": "Azad-Kashmir",
      "country": "PK",
      "elevation": 5479,
      "lat": 33.8497009277,
      "lon": 73.7981033325,
      "tz": "Asia\/Karachi"
  },
  "OPSB": {
      "icao": "OPSB",
      "iata": "SBQ",
      "name": "Sibi Airport",
      "city": "Sibi",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 436,
      "lat": 29.5711994171,
      "lon": 67.8479003906,
      "tz": "Asia\/Karachi"
  },
  "OPSD": {
      "icao": "OPSD",
      "iata": "KDU",
      "name": "Skardu Airport",
      "city": "Skardu",
      "state": "Gilgit-Baltistan",
      "country": "PK",
      "elevation": 7316,
      "lat": 35.3354988098,
      "lon": 75.5360031128,
      "tz": "Asia\/Karachi"
  },
  "OPSF": {
      "icao": "OPSF",
      "iata": "",
      "name": "Faisal Air Base",
      "city": "Karachi",
      "state": "Sindh",
      "country": "PK",
      "elevation": 29,
      "lat": 24.8742008209,
      "lon": 67.1184997559,
      "tz": "Asia\/Karachi"
  },
  "OPSK": {
      "icao": "OPSK",
      "iata": "SKZ",
      "name": "Sukkur Airport",
      "city": "Mirpur Khas",
      "state": "Sindh",
      "country": "PK",
      "elevation": 196,
      "lat": 27.7220001221,
      "lon": 68.7917022705,
      "tz": "Asia\/Karachi"
  },
  "OPSN": {
      "icao": "OPSN",
      "iata": "SYW",
      "name": "Sehwan Sharif Airport",
      "city": "Sehwan Sharif",
      "state": "Sindh",
      "country": "PK",
      "elevation": 121,
      "lat": 26.4731006622,
      "lon": 67.7172012329,
      "tz": "Asia\/Karachi"
  },
  "OPSP": {
      "icao": "OPSP",
      "iata": "",
      "name": "Shekhupura Airport",
      "city": "Shekhupura",
      "state": "Punjab",
      "country": "PK",
      "elevation": 680,
      "lat": 31.7084999084,
      "lon": 74.0102005005,
      "tz": "Asia\/Karachi"
  },
  "OPSR": {
      "icao": "OPSR",
      "iata": "SGI",
      "name": "Mushaf Air Base",
      "city": "Sargodha",
      "state": "Punjab",
      "country": "PK",
      "elevation": 614,
      "lat": 32.0485992432,
      "lon": 72.6650009155,
      "tz": "Asia\/Karachi"
  },
  "OPSS": {
      "icao": "OPSS",
      "iata": "SDT",
      "name": "Saidu Sharif Airport",
      "city": "Saidu Sharif",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 3183,
      "lat": 34.8135986328,
      "lon": 72.3527984619,
      "tz": "Asia\/Karachi"
  },
  "OPST": {
      "icao": "OPST",
      "iata": "SKT",
      "name": "Sialkot Airport",
      "city": "Sialkot",
      "state": "Punjab",
      "country": "PK",
      "elevation": 837,
      "lat": 32.5355567932,
      "lon": 74.3638916016,
      "tz": "Asia\/Karachi"
  },
  "OPSU": {
      "icao": "OPSU",
      "iata": "SUL",
      "name": "Sui Airport",
      "city": "Sui",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 763,
      "lat": 28.6450996399,
      "lon": 69.176902771,
      "tz": "Asia\/Karachi"
  },
  "OPSW": {
      "icao": "OPSW",
      "iata": "SWN",
      "name": "Sahiwal Airport",
      "city": "Sahiwal",
      "state": "Punjab",
      "country": "PK",
      "elevation": 570,
      "lat": 31.8894443512,
      "lon": 72.3916702271,
      "tz": "Asia\/Karachi"
  },
  "OPTA": {
      "icao": "OPTA",
      "iata": "TLB",
      "name": "Tarbela Dam Airport",
      "city": "Tarbela",
      "state": "Khyber-Pakhtunkhwa",
      "country": "PK",
      "elevation": 1114,
      "lat": 33.9860992432,
      "lon": 72.6113967896,
      "tz": "Asia\/Karachi"
  },
  "OPTH": {
      "icao": "OPTH",
      "iata": "BDN",
      "name": "Talhar Airport",
      "city": "Badin",
      "state": "Sindh",
      "country": "PK",
      "elevation": 28,
      "lat": 24.8414993286,
      "lon": 68.8384017944,
      "tz": "Asia\/Karachi"
  },
  "OPTT": {
      "icao": "OPTT",
      "iata": "TFT",
      "name": "Taftan Airport",
      "city": "Taftan",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 2742,
      "lat": 28.9641990662,
      "lon": 61.5954017639,
      "tz": "Asia\/Karachi"
  },
  "OPTU": {
      "icao": "OPTU",
      "iata": "TUK",
      "name": "Turbat International Airport",
      "city": "Turbat",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 498,
      "lat": 25.9864006042,
      "lon": 63.0302009583,
      "tz": "Asia\/Karachi"
  },
  "OPWN": {
      "icao": "OPWN",
      "iata": "WAF",
      "name": "Wana Airport",
      "city": "Waana",
      "state": "FATA",
      "country": "PK",
      "elevation": 4550,
      "lat": 32.3046989441,
      "lon": 69.5703964233,
      "tz": "Asia\/Karachi"
  },
  "OPZB": {
      "icao": "OPZB",
      "iata": "PZH",
      "name": "Zhob Airport",
      "city": "Fort Sandeman",
      "state": "Balochistan",
      "country": "PK",
      "elevation": 4728,
      "lat": 31.3584003448,
      "lon": 69.4636001587,
      "tz": "Asia\/Karachi"
  },
  "OR00": {
      "icao": "OR00",
      "iata": "",
      "name": "Flying K Ranch Airport",
      "city": "Beaverton",
      "state": "Oregon",
      "country": "US",
      "elevation": 350,
      "lat": 45.4345016479,
      "lon": -122.8799972534,
      "tz": "America\/Los_Angeles"
  },
  "OR02": {
      "icao": "OR02",
      "iata": "",
      "name": "River Run Ranch Airport",
      "city": "Redmond",
      "state": "Oregon",
      "country": "US",
      "elevation": 2600,
      "lat": 44.3357009888,
      "lon": -121.0199966431,
      "tz": "America\/Los_Angeles"
  },
  "OR04": {
      "icao": "OR04",
      "iata": "",
      "name": "Kennel Airstrip",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3595,
      "lat": 44.0295982361,
      "lon": -121.1650009155,
      "tz": "America\/Los_Angeles"
  },
  "OR05": {
      "icao": "OR05",
      "iata": "",
      "name": "Flying M Airport",
      "city": "Yamhill",
      "state": "Oregon",
      "country": "US",
      "elevation": 448,
      "lat": 45.3620986938,
      "lon": -123.3560028076,
      "tz": "America\/Los_Angeles"
  },
  "OR06": {
      "icao": "OR06",
      "iata": "",
      "name": "Snider Creek Airport",
      "city": "Medford",
      "state": "Oregon",
      "country": "US",
      "elevation": 1430,
      "lat": 42.5390014648,
      "lon": -122.922996521,
      "tz": "America\/Los_Angeles"
  },
  "OR07": {
      "icao": "OR07",
      "iata": "",
      "name": "Miranda'S Skyranch Airport",
      "city": "Long Creek",
      "state": "Oregon",
      "country": "US",
      "elevation": 3823,
      "lat": 44.76240158,
      "lon": -119.1019974,
      "tz": "America\/Los_Angeles"
  },
  "OR08": {
      "icao": "OR08",
      "iata": "",
      "name": "Barton Lake Ranch Airport",
      "city": "Diamond",
      "state": "Oregon",
      "country": "US",
      "elevation": 4159,
      "lat": 43.1287994385,
      "lon": -118.6770019531,
      "tz": "America\/Los_Angeles"
  },
  "OR09": {
      "icao": "OR09",
      "iata": "",
      "name": "Whitehorse Ranch Airport",
      "city": "Fields",
      "state": "Oregon",
      "country": "US",
      "elevation": 4447,
      "lat": 42.3356018066,
      "lon": -118.2580032349,
      "tz": "America\/Los_Angeles"
  },
  "OR10": {
      "icao": "OR10",
      "iata": "",
      "name": "Roaring Springs Ranch Airport",
      "city": "Frenchglen",
      "state": "Oregon",
      "country": "US",
      "elevation": 4576,
      "lat": 42.6496009827,
      "lon": -118.9919967651,
      "tz": "America\/Los_Angeles"
  },
  "OR11": {
      "icao": "OR11",
      "iata": "",
      "name": "Jensens Strip",
      "city": "Haines",
      "state": "Oregon",
      "country": "US",
      "elevation": 3350,
      "lat": 44.912399292,
      "lon": -117.9580001831,
      "tz": "America\/Los_Angeles"
  },
  "OR12": {
      "icao": "OR12",
      "iata": "",
      "name": "Oxbow Airport",
      "city": "Homestead",
      "state": "Oregon",
      "country": "US",
      "elevation": 1806,
      "lat": 45.0042991638,
      "lon": -116.8489990234,
      "tz": "America\/Boise"
  },
  "OR13": {
      "icao": "OR13",
      "iata": "",
      "name": "Wiley Creek Airport",
      "city": "Dayville",
      "state": "Oregon",
      "country": "US",
      "elevation": 4130,
      "lat": 44.5023994446,
      "lon": -119.3720016479,
      "tz": "America\/Los_Angeles"
  },
  "OR14": {
      "icao": "OR14",
      "iata": "",
      "name": "Juntura Airport",
      "city": "Juntura",
      "state": "Oregon",
      "country": "US",
      "elevation": 3034,
      "lat": 43.7415008545,
      "lon": -118.0640029907,
      "tz": "America\/Boise"
  },
  "OR16": {
      "icao": "OR16",
      "iata": "",
      "name": "Umpleby Ranch Airport",
      "city": "North Powder",
      "state": "Oregon",
      "country": "US",
      "elevation": 3412,
      "lat": 45.0573997498,
      "lon": -117.9680023193,
      "tz": "America\/Los_Angeles"
  },
  "OR17": {
      "icao": "OR17",
      "iata": "",
      "name": "Oxbow Ranch Airport",
      "city": "Prairie City",
      "state": "Oregon",
      "country": "US",
      "elevation": 3972,
      "lat": 44.4118003845,
      "lon": -118.6910018921,
      "tz": "America\/Los_Angeles"
  },
  "OR19": {
      "icao": "OR19",
      "iata": "",
      "name": "Propst Airport",
      "city": "Albany",
      "state": "Oregon",
      "country": "US",
      "elevation": 239,
      "lat": 44.6386985779,
      "lon": -123.0230026245,
      "tz": "America\/Los_Angeles"
  },
  "OR20": {
      "icao": "OR20",
      "iata": "",
      "name": "Hemmingson Airport",
      "city": "Albany",
      "state": "Oregon",
      "country": "US",
      "elevation": 250,
      "lat": 44.6581993103,
      "lon": -123.1429977417,
      "tz": "America\/Los_Angeles"
  },
  "OR21": {
      "icao": "OR21",
      "iata": "",
      "name": "Miller Airstrip",
      "city": "Albany",
      "state": "Oregon",
      "country": "US",
      "elevation": 279,
      "lat": 44.5932006836,
      "lon": -122.9899978638,
      "tz": "America\/Los_Angeles"
  },
  "OR22": {
      "icao": "OR22",
      "iata": "",
      "name": "Roppair Airport",
      "city": "Albany",
      "state": "Oregon",
      "country": "US",
      "elevation": 247,
      "lat": 44.6193008423,
      "lon": -123.0319976807,
      "tz": "America\/Los_Angeles"
  },
  "OR23": {
      "icao": "OR23",
      "iata": "",
      "name": "Karpens Airport",
      "city": "Astoria",
      "state": "Oregon",
      "country": "US",
      "elevation": 125,
      "lat": 46.1613998413,
      "lon": -123.6399993896,
      "tz": "America\/Los_Angeles"
  },
  "OR24": {
      "icao": "OR24",
      "iata": "",
      "name": "Barrett Field",
      "city": "Athena",
      "state": "Oregon",
      "country": "US",
      "elevation": 1738,
      "lat": 45.8212013245,
      "lon": -118.4980010986,
      "tz": "America\/Los_Angeles"
  },
  "OR25": {
      "icao": "OR25",
      "iata": "",
      "name": "Flying E Airport",
      "city": "Aumsville",
      "state": "Oregon",
      "country": "US",
      "elevation": 325,
      "lat": 44.8447990417,
      "lon": -122.9010009766,
      "tz": "America\/Los_Angeles"
  },
  "OR26": {
      "icao": "OR26",
      "iata": "",
      "name": "Farr Airport",
      "city": "Lakeview",
      "state": "Oregon",
      "country": "US",
      "elevation": 4775,
      "lat": 42.1277008057,
      "lon": -120.4840011597,
      "tz": "America\/Los_Angeles"
  },
  "OR27": {
      "icao": "OR27",
      "iata": "",
      "name": "Chadwick Airport",
      "city": "Banks",
      "state": "Oregon",
      "country": "US",
      "elevation": 670,
      "lat": 45.6332015991,
      "lon": -123.1679992676,
      "tz": "America\/Los_Angeles"
  },
  "OR28": {
      "icao": "OR28",
      "iata": "",
      "name": "Harvey'S Acres Airport",
      "city": "Beaverton",
      "state": "Oregon",
      "country": "US",
      "elevation": 250,
      "lat": 45.44150162,
      "lon": -122.8929977,
      "tz": "America\/Los_Angeles"
  },
  "OR29": {
      "icao": "OR29",
      "iata": "",
      "name": "Gopher Gulch Airport",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3480,
      "lat": 44.1111984253,
      "lon": -121.3339996338,
      "tz": "America\/Los_Angeles"
  },
  "OR30": {
      "icao": "OR30",
      "iata": "",
      "name": "D M Stevenson Ranch Airport",
      "city": "Bend",
      "state": "Oregon",
      "country": "US",
      "elevation": 3800,
      "lat": 44.003200531,
      "lon": -121.2720031738,
      "tz": "America\/Los_Angeles"
  },
  "OR32": {
      "icao": "OR32",
      "iata": "",
      "name": "Simtag Farms Airport",
      "city": "Boardman",
      "state": "Oregon",
      "country": "US",
      "elevation": 550,
      "lat": 45.7521018982,
      "lon": -119.9459991455,
      "tz": "America\/Los_Angeles"
  },
  "OR34": {
      "icao": "OR34",
      "iata": "",
      "name": "Whippet Field",
      "city": "Sisters",
      "state": "Oregon",
      "country": "US",
      "elevation": 3040,
      "lat": 44.3222999573,
      "lon": -121.4329986572,
      "tz": "America\/Los_Angeles"
  },
  "OR35": {
      "icao": "OR35",
      "iata": "",
      "name": "Flying K Bar J Ranch Airport",
      "city": "Boring",
      "state": "Oregon",
      "country": "US",
      "elevation": 635,
      "lat": 45.4426002502,
      "lon": -122.3209991455,
      "tz": "America\/Los_Angeles"
  },
  "OR38": {
      "icao": "OR38",
      "iata": "",
      "name": "Harchenko Industrial Airport",
      "city": "Brooks",
      "state": "Oregon",
      "country": "US",
      "elevation": 183,
      "lat": 45.0775985718,
      "lon": -122.9290008545,
      "tz": "America\/Los_Angeles"
  },
  "OR39": {
      "icao": "OR39",
      "iata": "",
      "name": "Flying Tom Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 300,
      "lat": 44.6576004028,
      "lon": -123.2119979858,
      "tz": "America\/Los_Angeles"
  },
  "OR40": {
      "icao": "OR40",
      "iata": "",
      "name": "Dietz Airpark",
      "city": "Canby",
      "state": "Oregon",
      "country": "US",
      "elevation": 180,
      "lat": 45.2556991577,
      "lon": -122.6510009766,
      "tz": "America\/Los_Angeles"
  },
  "OR41": {
      "icao": "OR41",
      "iata": "",
      "name": "Workman Airpark",
      "city": "Canby",
      "state": "Oregon",
      "country": "US",
      "elevation": 210,
      "lat": 45.2075996399,
      "lon": -122.6689987183,
      "tz": "America\/Los_Angeles"
  },
  "OR42": {
      "icao": "OR42",
      "iata": "",
      "name": "Norway Airport",
      "city": "Norway",
      "state": "Oregon",
      "country": "US",
      "elevation": 23,
      "lat": 43.1026000977,
      "lon": -124.1610031128,
      "tz": "America\/Los_Angeles"
  },
  "OR44": {
      "icao": "OR44",
      "iata": "",
      "name": "Briggs Airport",
      "city": "Coburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 387,
      "lat": 44.153301239,
      "lon": -123.0650024414,
      "tz": "America\/Los_Angeles"
  },
  "OR45": {
      "icao": "OR45",
      "iata": "",
      "name": "West Point Airport",
      "city": "Coburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 378,
      "lat": 44.197101593,
      "lon": -123.0559997559,
      "tz": "America\/Los_Angeles"
  },
  "OR46": {
      "icao": "OR46",
      "iata": "",
      "name": "Ajax Airport",
      "city": "Condon",
      "state": "Oregon",
      "country": "US",
      "elevation": 2165,
      "lat": 45.3832015991,
      "lon": -120.4680023193,
      "tz": "America\/Los_Angeles"
  },
  "OR47": {
      "icao": "OR47",
      "iata": "",
      "name": "Strauch Field",
      "city": "Junction City",
      "state": "Oregon",
      "country": "US",
      "elevation": 335,
      "lat": 44.1883010864,
      "lon": -123.1890029907,
      "tz": "America\/Los_Angeles"
  },
  "OR48": {
      "icao": "OR48",
      "iata": "",
      "name": "Snyder Ranch Airport",
      "city": "Condon",
      "state": "Oregon",
      "country": "US",
      "elevation": 2655,
      "lat": 45.1943016052,
      "lon": -120.2170028687,
      "tz": "America\/Los_Angeles"
  },
  "OR50": {
      "icao": "OR50",
      "iata": "",
      "name": "Lehman Field",
      "city": "Corbett",
      "state": "Oregon",
      "country": "US",
      "elevation": 1003,
      "lat": 45.4856987,
      "lon": -122.2340011597,
      "tz": "America\/Los_Angeles"
  },
  "OR51": {
      "icao": "OR51",
      "iata": "",
      "name": "Gillette Field",
      "city": "Scio",
      "state": "Oregon",
      "country": "US",
      "elevation": 735,
      "lat": 44.7196006775,
      "lon": -122.6999969482,
      "tz": "America\/Los_Angeles"
  },
  "OR52": {
      "icao": "OR52",
      "iata": "",
      "name": "Venell Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 246,
      "lat": 44.4734992981,
      "lon": -123.3030014038,
      "tz": "America\/Los_Angeles"
  },
  "OR53": {
      "icao": "OR53",
      "iata": "",
      "name": "Wapinitia Airport",
      "city": "Wapinitia",
      "state": "Oregon",
      "country": "US",
      "elevation": 1910,
      "lat": 45.1456985474,
      "lon": -121.2289962769,
      "tz": "America\/Los_Angeles"
  },
  "OR54": {
      "icao": "OR54",
      "iata": "",
      "name": "Winn Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 205,
      "lat": 44.5984992981,
      "lon": -123.2070007324,
      "tz": "America\/Los_Angeles"
  },
  "OR56": {
      "icao": "OR56",
      "iata": "",
      "name": "Holiday Airport",
      "city": "Corvallis",
      "state": "Oregon",
      "country": "US",
      "elevation": 225,
      "lat": 44.5698013306,
      "lon": -123.1709976196,
      "tz": "America\/Los_Angeles"
  },
  "OR57": {
      "icao": "OR57",
      "iata": "",
      "name": "Walker Airport",
      "city": "Creswell",
      "state": "Oregon",
      "country": "US",
      "elevation": 580,
      "lat": 43.8686981201,
      "lon": -123.033996582,
      "tz": "America\/Los_Angeles"
  },
  "OR59": {
      "icao": "OR59",
      "iata": "",
      "name": "Trivelpiece Airport",
      "city": "Yamhill",
      "state": "Oregon",
      "country": "US",
      "elevation": 165,
      "lat": 45.3315010071,
      "lon": -123.2070007324,
      "tz": "America\/Los_Angeles"
  },
  "OR60": {
      "icao": "OR60",
      "iata": "",
      "name": "Fargher Airport",
      "city": "Dufur",
      "state": "Oregon",
      "country": "US",
      "elevation": 1440,
      "lat": 45.4365005493,
      "lon": -121.1660003662,
      "tz": "America\/Los_Angeles"
  },
  "OR61": {
      "icao": "OR61",
      "iata": "",
      "name": "Apple Valley Airport",
      "city": "Buxton",
      "state": "Oregon",
      "country": "US",
      "elevation": 247,
      "lat": 45.6783981323,
      "lon": -123.1859970093,
      "tz": "America\/Los_Angeles"
  },
  "OR64": {
      "icao": "OR64",
      "iata": "",
      "name": "Beach Ranch Airport",
      "city": "Enterprise",
      "state": "Oregon",
      "country": "US",
      "elevation": 4000,
      "lat": 45.9637985229,
      "lon": -117.2350006104,
      "tz": "America\/Los_Angeles"
  },
  "OR65": {
      "icao": "OR65",
      "iata": "",
      "name": "Eagle Nest Ranch Airport",
      "city": "Estacada",
      "state": "Oregon",
      "country": "US",
      "elevation": 425,
      "lat": 45.354801178,
      "lon": -122.3460006714,
      "tz": "America\/Los_Angeles"
  },
  "OR66": {
      "icao": "OR66",
      "iata": "",
      "name": "Beaver Oaks Airport",
      "city": "Estacada",
      "state": "Oregon",
      "country": "US",
      "elevation": 400,
      "lat": 45.3040008545,
      "lon": -122.361000061,
      "tz": "America\/Los_Angeles"
  },
  "OR67": {
      "icao": "OR67",
      "iata": "",
      "name": "Mc Gill Airport",
      "city": "Estacada",
      "state": "Oregon",
      "country": "US",
      "elevation": 370,
      "lat": 45.3372993469,
      "lon": -122.398002625,
      "tz": "America\/Los_Angeles"
  },
  "OR70": {
      "icao": "OR70",
      "iata": "",
      "name": "Pine Valley Airport",
      "city": "Halfway",
      "state": "Oregon",
      "country": "US",
      "elevation": 2577,
      "lat": 44.8596000671,
      "lon": -117.0879974365,
      "tz": "America\/Los_Angeles"
  },
  "OR72": {
      "icao": "OR72",
      "iata": "",
      "name": "Krueger Airport",
      "city": "Boring",
      "state": "Oregon",
      "country": "US",
      "elevation": 620,
      "lat": 45.4421005249,
      "lon": -122.3229980469,
      "tz": "America\/Los_Angeles"
  },
  "OR73": {
      "icao": "OR73",
      "iata": "",
      "name": "Calvert Peak STOLport",
      "city": "Glendale",
      "state": "Oregon",
      "country": "US",
      "elevation": 3808,
      "lat": 42.7787017822,
      "lon": -123.7340011597,
      "tz": "America\/Los_Angeles"
  },
  "OR74": {
      "icao": "OR74",
      "iata": "",
      "name": "Winkle Bar Airport",
      "city": "Grants Pass",
      "state": "Oregon",
      "country": "US",
      "elevation": 450,
      "lat": 42.701499939,
      "lon": -123.8050003052,
      "tz": "America\/Los_Angeles"
  },
  "OR76": {
      "icao": "OR76",
      "iata": "",
      "name": "Waynes Air Service Airport",
      "city": "Halsey",
      "state": "Oregon",
      "country": "US",
      "elevation": 285,
      "lat": 44.3632011414,
      "lon": -123.1399993896,
      "tz": "America\/Los_Angeles"
  },
  "OR77": {
      "icao": "OR77",
      "iata": "",
      "name": "Faust Field Airport",
      "city": "Independence",
      "state": "Oregon",
      "country": "US",
      "elevation": 175,
      "lat": 44.8003997803,
      "lon": -123.15599823,
      "tz": "America\/Los_Angeles"
  },
  "OR78": {
      "icao": "OR78",
      "iata": "",
      "name": "Daniels Field",
      "city": "Harrisburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 320,
      "lat": 44.2720985413,
      "lon": -123.0609970093,
      "tz": "America\/Los_Angeles"
  },
  "OR79": {
      "icao": "OR79",
      "iata": "",
      "name": "Knox's Private Airstrip",
      "city": "Harrisburg",
      "state": "Oregon",
      "country": "US",
      "elevation": 313,
      "lat": 44.2892990112,
      "lon": -123.1309967041,
      "tz": "America\/Los_Angeles"
  },
  "OR81": {
      "icao": "OR81",
      "iata": "",
      "name": "Olinger Airpark",
      "city": "Hillsboro",
      "state": "Oregon",
      "country": "US",
      "elevation": 185,
      "lat": 45.5597991943,
      "lon": -123.019996643,
      "tz": "America\/Los_Angeles"
  },
  "OR85": {
      "icao": "OR85",
      "iata": "",
      "name": "Wigrich Airport",
      "city": "Independence",
      "state": "Oregon",
      "country": "US",
      "elevation": 165,
      "lat": 44.8123016357,
      "lon": -123.1500015259,
      "tz": "America\/Los_Angeles"
  },
  "OR86": {
      "icao": "OR86",
      "iata": "",
      "name": "Gilmour Ag Air Airport",
      "city": "Jefferson",
      "state": "Oregon",
      "country": "US",
      "elevation": 244,
      "lat": 44.7554016113,
      "lon": -123.04699707,
      "tz": "America\/Los_Angeles"
  },
  "OR87": {
      "icao": "OR87",
      "iata": "",
      "name": "Blue Skies Farm Airport",
      "city": "Salem",
      "state": "Oregon",
      "country": "US",
      "elevation": 200,
      "lat": 44.966111,
      "lon": -122.9225,
      "tz": "America\/Los_Angeles"
  },
  "OR89": {
      "icao": "OR89",
      "iata": "",
      "name": "Kinzua Airport",
      "city": "Kinzua",
      "state": "Oregon",
      "country": "US",
      "elevation": 3966,
      "lat": 44.9999008179,
      "lon": -120.0530014038,
      "tz": "America\/Los_Angeles"
  },
  "OR90": {
      "icao": "OR90",
      "iata": "",
      "name": "Lafayette Airstrip",
      "city": "Lafayette",
      "state": "Oregon",
      "country": "US",
      "elevation": 160,
      "lat": 45.2443008423,
      "lon": -123.1289978027,
      "tz": "America\/Los_Angeles"
  },
  "OR92": {
      "icao": "OR92",
      "iata": "",
      "name": "Billiebob Ultralightport",
      "city": "Grants Pass",
      "state": "Oregon",
      "country": "US",
      "elevation": 1000,
      "lat": 42.3914985657,
      "lon": -123.43699646,
      "tz": "America\/Los_Angeles"
  },
  "OR94": {
      "icao": "OR94",
      "iata": "",
      "name": "Showa Airport",
      "city": "Brownsville",
      "state": "Oregon",
      "country": "US",
      "elevation": 298,
      "lat": 44.3917007446,
      "lon": -123.0299987793,
      "tz": "America\/Los_Angeles"
  },
  "OR95": {
      "icao": "OR95",
      "iata": "",
      "name": "Vineyard Airport",
      "city": "Mc Coy",
      "state": "Oregon",
      "country": "US",
      "elevation": 190,
      "lat": 45.0498008728,
      "lon": -123.2350006104,
      "tz": "America\/Los_Angeles"
  },
  "OR96": {
      "icao": "OR96",
      "iata": "",
      "name": "Beagle Sky Ranch Airport",
      "city": "Medford",
      "state": "Oregon",
      "country": "US",
      "elevation": 1436,
      "lat": 42.5390014648,
      "lon": -122.9039993286,
      "tz": "America\/Los_Angeles"
  },
  "OR97": {
      "icao": "OR97",
      "iata": "",
      "name": "Burrill Airport",
      "city": "Medford",
      "state": "Oregon",
      "country": "US",
      "elevation": 1310,
      "lat": 42.4387016296,
      "lon": -122.8639984131,
      "tz": "America\/Los_Angeles"
  },
  "OR98": {
      "icao": "OR98",
      "iata": "",
      "name": "Seneca Emergency Airstrip",
      "city": "Seneca",
      "state": "Oregon",
      "country": "US",
      "elevation": 4666,
      "lat": 44.1381988525,
      "lon": -118.9820022583,
      "tz": "America\/Los_Angeles"
  },
  "ORAA": {
      "icao": "ORAA",
      "iata": "IQA",
      "name": "Al Asad Air Base",
      "city": "Hit",
      "state": "Anbar",
      "country": "IQ",
      "elevation": 618,
      "lat": 33.7855987549,
      "lon": 42.4412002563,
      "tz": "Asia\/Baghdad"
  },
  "ORAT": {
      "icao": "ORAT",
      "iata": "TQD",
      "name": "Al Taqaddum Air Base",
      "city": "Al Habbaniyah",
      "state": "Anbar",
      "country": "IQ",
      "elevation": 275,
      "lat": 33.3381004333,
      "lon": 43.5970993042,
      "tz": "Asia\/Baghdad"
  },
  "ORBB": {
      "icao": "ORBB",
      "iata": "BMN",
      "name": "Bamarni Airport",
      "city": "Bamarni",
      "state": "Dahuk",
      "country": "IQ",
      "elevation": 3455,
      "lat": 37.0988006592,
      "lon": 43.2666015625,
      "tz": "Asia\/Baghdad"
  },
  "ORBD": {
      "icao": "ORBD",
      "iata": "",
      "name": "Balad Southeast Air Base",
      "city": "Balad",
      "state": "Salah-ad-Din",
      "country": "IQ",
      "elevation": 161,
      "lat": 33.9402008057,
      "lon": 44.3615989685,
      "tz": "Asia\/Baghdad"
  },
  "ORBI": {
      "icao": "ORBI",
      "iata": "BGW",
      "name": "Baghdad International Airport",
      "city": "Baghdad",
      "state": "Baghdad",
      "country": "IQ",
      "elevation": 114,
      "lat": 33.2625007629,
      "lon": 44.2346000671,
      "tz": "Asia\/Baghdad"
  },
  "ORBM": {
      "icao": "ORBM",
      "iata": "OSB",
      "name": "Mosul International Airport",
      "city": "Mosul",
      "state": "Ninawa",
      "country": "IQ",
      "elevation": 719,
      "lat": 36.3058013916,
      "lon": 43.1473999023,
      "tz": "Asia\/Baghdad"
  },
  "ORBR": {
      "icao": "ORBR",
      "iata": "",
      "name": "Bashur Airfield",
      "city": "Bashur",
      "state": "Arbil",
      "country": "IQ",
      "elevation": 2079,
      "lat": 36.5336990356,
      "lon": 44.3399009705,
      "tz": "Asia\/Baghdad"
  },
  "ORER": {
      "icao": "ORER",
      "iata": "EBL",
      "name": "Erbil International Airport",
      "city": "Arbil",
      "state": "Arbil",
      "country": "IQ",
      "elevation": 1341,
      "lat": 36.2375984192,
      "lon": 43.9631996155,
      "tz": "Asia\/Baghdad"
  },
  "ORKK": {
      "icao": "ORKK",
      "iata": "KIK",
      "name": "Kirkuk Air Base",
      "city": "Kirkuk",
      "state": "Kirkuk",
      "country": "IQ",
      "elevation": 1061,
      "lat": 35.4695014954,
      "lon": 44.3488998413,
      "tz": "Asia\/Baghdad"
  },
  "ORMM": {
      "icao": "ORMM",
      "iata": "BSR",
      "name": "Basrah International Airport",
      "city": "Basrah",
      "state": "Basra",
      "country": "IQ",
      "elevation": 11,
      "lat": 30.5491008759,
      "lon": 47.6621017456,
      "tz": "Asia\/Baghdad"
  },
  "ORNI": {
      "icao": "ORNI",
      "iata": "NJF",
      "name": "Al Najaf International Airport",
      "city": "Najaf",
      "state": "Najaf Governorate",
      "country": "IQ",
      "elevation": 103,
      "lat": 31.989722,
      "lon": 44.404167,
      "tz": "Asia\/Baghdad"
  },
  "ORQW": {
      "icao": "ORQW",
      "iata": "RQW",
      "name": "Qayyarah West Airport",
      "city": "Qayyarah",
      "state": "Ninawa",
      "country": "IQ",
      "elevation": 749,
      "lat": 35.76720047,
      "lon": 43.1250991821,
      "tz": "Asia\/Baghdad"
  },
  "ORSH": {
      "icao": "ORSH",
      "iata": "",
      "name": "Al Sahra Army Air Field",
      "city": "Tikrit",
      "state": "Salah-ad-Din",
      "country": "IQ",
      "elevation": 451,
      "lat": 34.6731987,
      "lon": 43.5429992676,
      "tz": "Asia\/Baghdad"
  },
  "ORSU": {
      "icao": "ORSU",
      "iata": "ISU",
      "name": "Sulaymaniyah International Airport",
      "city": "Sulaymaniyah",
      "state": "As-Sulaymaniyah",
      "country": "IQ",
      "elevation": 2494,
      "lat": 35.5617485046,
      "lon": 45.3167381287,
      "tz": "Asia\/Baghdad"
  },
  "ORTF": {
      "icao": "ORTF",
      "iata": "",
      "name": "Tall Afar Army Air Field",
      "city": "Tall Afar",
      "state": "Ninawa",
      "country": "IQ",
      "elevation": 996,
      "lat": 36.2831001282,
      "lon": 42.4029998779,
      "tz": "Asia\/Baghdad"
  },
  "ORTI": {
      "icao": "ORTI",
      "iata": "",
      "name": "Al Taji Army Air Field",
      "city": "Taji",
      "state": "Baghdad",
      "country": "IQ",
      "elevation": 121,
      "lat": 33.5238990784,
      "lon": 44.2566986084,
      "tz": "Asia\/Baghdad"
  },
  "ORTL": {
      "icao": "ORTL",
      "iata": "",
      "name": "Ali Air Base",
      "city": "Nasiriyah",
      "state": "Dhi-Qar",
      "country": "IQ",
      "elevation": 20,
      "lat": 30.9358005524,
      "lon": 46.0900993347,
      "tz": "Asia\/Baghdad"
  },
  "ORUB": {
      "icao": "ORUB",
      "iata": "",
      "name": "Ubaydah Bin Al Jarrah Airport",
      "city": "Al Cut",
      "state": "Wasi\u0163",
      "country": "IQ",
      "elevation": 68,
      "lat": 32.4819984436,
      "lon": 45.7570991516,
      "tz": "Asia\/Baghdad"
  },
  "ORUQ": {
      "icao": "ORUQ",
      "iata": "",
      "name": "Umm Qasr Airport",
      "city": "",
      "state": "Basra",
      "country": "IQ",
      "elevation": 0,
      "lat": 30.0181435212,
      "lon": 47.9246163368,
      "tz": "Asia\/Baghdad"
  },
  "OS58": {
      "icao": "OS58",
      "iata": "",
      "name": "Hamah Air Base",
      "city": "",
      "state": "Hama",
      "country": "SY",
      "elevation": 1014,
      "lat": 35.1181983948,
      "lon": 36.7112007141,
      "tz": "Asia\/Damascus"
  },
  "OS59": {
      "icao": "OS59",
      "iata": "",
      "name": "Tabqa Airport",
      "city": "",
      "state": "Ar-Raqqah",
      "country": "SY",
      "elevation": 1050,
      "lat": 35.754699707,
      "lon": 38.5667991638,
      "tz": "Asia\/Damascus"
  },
  "OS61": {
      "icao": "OS61",
      "iata": "",
      "name": "Dumayr Air Base",
      "city": "",
      "state": "Rif-dimashq",
      "country": "SY",
      "elevation": 2060,
      "lat": 33.6096000671,
      "lon": 36.7490005493,
      "tz": "Asia\/Damascus"
  },
  "OS62": {
      "icao": "OS62",
      "iata": "",
      "name": "Jirah Air Base",
      "city": "",
      "state": "Aleppo",
      "country": "SY",
      "elevation": 1145,
      "lat": 36.096698761,
      "lon": 37.9365005493,
      "tz": "Asia\/Damascus"
  },
  "OS63": {
      "icao": "OS63",
      "iata": "",
      "name": "Marj Ruhayyil Air Base",
      "city": "Marj Ruhayyil",
      "state": "Rif-dimashq",
      "country": "SY",
      "elevation": 2190,
      "lat": 33.2864990234,
      "lon": 36.4574012756,
      "tz": "Asia\/Damascus"
  },
  "OS65": {
      "icao": "OS65",
      "iata": "",
      "name": "Shayrat Air Base",
      "city": "Shayrat",
      "state": "Homs",
      "country": "SY",
      "elevation": 2644,
      "lat": 34.4902992249,
      "lon": 36.9090003967,
      "tz": "Asia\/Damascus"
  },
  "OS66": {
      "icao": "OS66",
      "iata": "",
      "name": "Rasin El Aboud Airport",
      "city": "",
      "state": "Aleppo",
      "country": "SY",
      "elevation": 1207,
      "lat": 36.1870994568,
      "lon": 37.5831985474,
      "tz": "Asia\/Damascus"
  },
  "OS67": {
      "icao": "OS67",
      "iata": "",
      "name": "Mezze Airport",
      "city": "",
      "state": "Dimashq",
      "country": "SY",
      "elevation": 2407,
      "lat": 33.4775009155,
      "lon": 36.223400116,
      "tz": "Asia\/Damascus"
  },
  "OS68": {
      "icao": "OS68",
      "iata": "",
      "name": "Saiqal Air Base",
      "city": "Saiqal",
      "state": "Rif-dimashq",
      "country": "SY",
      "elevation": 2300,
      "lat": 33.6823997498,
      "lon": 37.2139015198,
      "tz": "Asia\/Damascus"
  },
  "OS73": {
      "icao": "OS73",
      "iata": "",
      "name": "Al Al Airport",
      "city": "",
      "state": "Quneitra",
      "country": "SY",
      "elevation": 1214,
      "lat": 32.7867012024,
      "lon": 35.7179985046,
      "tz": "Asia\/Jerusalem"
  },
  "OSAP": {
      "icao": "OSAP",
      "iata": "ALP",
      "name": "Aleppo International Airport",
      "city": "Aleppo",
      "state": "Aleppo",
      "country": "SY",
      "elevation": 1276,
      "lat": 36.1806983948,
      "lon": 37.2243995667,
      "tz": "Asia\/Damascus"
  },
  "OSDI": {
      "icao": "OSDI",
      "iata": "DAM",
      "name": "Damascus International Airport",
      "city": "Damascus",
      "state": "Rif-dimashq",
      "country": "SY",
      "elevation": 2020,
      "lat": 33.4114990234,
      "lon": 36.5155982971,
      "tz": "Asia\/Damascus"
  },
  "OSDZ": {
      "icao": "OSDZ",
      "iata": "DEZ",
      "name": "Deir ez-Zor Airport",
      "city": "Deir ez-Zor",
      "state": "Deir-ez-Zor",
      "country": "SY",
      "elevation": 700,
      "lat": 35.2854003906,
      "lon": 40.1759986877,
      "tz": "Asia\/Damascus"
  },
  "OSKL": {
      "icao": "OSKL",
      "iata": "KAC",
      "name": "Kamishly Airport",
      "city": "Kamishly",
      "state": "Al-Hasakah",
      "country": "SY",
      "elevation": 1480,
      "lat": 37.0205993652,
      "lon": 41.1913986206,
      "tz": "Asia\/Damascus"
  },
  "OSLK": {
      "icao": "OSLK",
      "iata": "LTK",
      "name": "Bassel Al-Assad International Airport",
      "city": "Latakia",
      "state": "Latakia",
      "country": "SY",
      "elevation": 157,
      "lat": 35.4011001587,
      "lon": 35.9486999512,
      "tz": "Asia\/Damascus"
  },
  "OSPR": {
      "icao": "OSPR",
      "iata": "PMS",
      "name": "Palmyra Airport",
      "city": "",
      "state": "Homs",
      "country": "SY",
      "elevation": 1322,
      "lat": 34.5573997498,
      "lon": 38.3168983459,
      "tz": "Asia\/Damascus"
  },
  "OTBD": {
      "icao": "OTBD",
      "iata": "DIA",
      "name": "Doha International Airport",
      "city": "Doha",
      "state": "Baladiyat-ad-Daw\u1e29ah",
      "country": "QA",
      "elevation": 35,
      "lat": 25.261100769,
      "lon": 51.5651016235,
      "tz": "Asia\/Qatar"
  },
  "OTBH": {
      "icao": "OTBH",
      "iata": "IUD",
      "name": "Al Udeid Air Base",
      "city": "Ar Rayyan",
      "state": "Baladiyat-ar-Rayyan",
      "country": "QA",
      "elevation": 130,
      "lat": 25.1173000336,
      "lon": 51.3149986267,
      "tz": "Asia\/Qatar"
  },
  "OTBK": {
      "icao": "OTBK",
      "iata": "",
      "name": "Al Khawr Airport",
      "city": "Al Khawr",
      "state": "Al-Khawr",
      "country": "QA",
      "elevation": 10,
      "lat": 25.6296005249,
      "lon": 51.5066986084,
      "tz": "Asia\/Qatar"
  },
  "OTHH": {
      "icao": "OTHH",
      "iata": "DOH",
      "name": "Hamad International Airport",
      "city": "Doha",
      "state": "Baladiyat-ad-Daw\u1e29ah",
      "country": "QA",
      "elevation": 13,
      "lat": 25.2605946,
      "lon": 51.6137665,
      "tz": "Asia\/Qatar"
  },
  "OY75": {
      "icao": "OY75",
      "iata": "",
      "name": "Canoxy Airport",
      "city": "",
      "state": "Mu\u1e29afaz\u0327at-\u1e28a\u1e11ramawt",
      "country": "YE",
      "elevation": 3300,
      "lat": 15.6017999649,
      "lon": 49.0806999207,
      "tz": "Asia\/Aden"
  },
  "OYAA": {
      "icao": "OYAA",
      "iata": "ADE",
      "name": "Aden International Airport",
      "city": "Aden",
      "state": "Aden",
      "country": "YE",
      "elevation": 7,
      "lat": 12.8295001984,
      "lon": 45.0288009644,
      "tz": "Asia\/Aden"
  },
  "OYAB": {
      "icao": "OYAB",
      "iata": "",
      "name": "Ibb Airport",
      "city": "",
      "state": "Sanaa",
      "country": "YE",
      "elevation": 0,
      "lat": 16.010753477,
      "lon": 43.1781148911,
      "tz": "Asia\/Aden"
  },
  "OYAT": {
      "icao": "OYAT",
      "iata": "AXK",
      "name": "Ataq Airport",
      "city": "",
      "state": "Shabwah",
      "country": "YE",
      "elevation": 3735,
      "lat": 14.5513000488,
      "lon": 46.8261985779,
      "tz": "Asia\/Aden"
  },
  "OYBA": {
      "icao": "OYBA",
      "iata": "",
      "name": "Al Badie Airport",
      "city": "",
      "state": "",
      "country": "YE",
      "elevation": 908,
      "lat": 18.7192993164,
      "lon": 50.8368988037,
      "tz": "Asia\/Aden"
  },
  "OYBI": {
      "icao": "OYBI",
      "iata": "BYD",
      "name": "Al-Bayda Airport",
      "city": "Al-Bayda",
      "state": "Al-Bayda-Governorate",
      "country": "YE",
      "elevation": 6120,
      "lat": 13.9670000076,
      "lon": 45.5830001831,
      "tz": "Asia\/Aden"
  },
  "OYBN": {
      "icao": "OYBN",
      "iata": "BHN",
      "name": "Beihan Airport",
      "city": "",
      "state": "Shabwah",
      "country": "YE",
      "elevation": 3800,
      "lat": 14.781999588,
      "lon": 45.7201004028,
      "tz": "Asia\/Aden"
  },
  "OYBQ": {
      "icao": "OYBQ",
      "iata": "",
      "name": "Al-Bough Airport",
      "city": "Al-Bough",
      "state": "Omran",
      "country": "YE",
      "elevation": 3800,
      "lat": 17.3467006683,
      "lon": 44.6217002869,
      "tz": "Asia\/Aden"
  },
  "OYGD": {
      "icao": "OYGD",
      "iata": "AAY",
      "name": "Al Ghaidah International Airport",
      "city": "",
      "state": "Al-Mahrah",
      "country": "YE",
      "elevation": 134,
      "lat": 16.1916999817,
      "lon": 52.1749992371,
      "tz": "Asia\/Aden"
  },
  "OYHD": {
      "icao": "OYHD",
      "iata": "HOD",
      "name": "Hodeidah International Airport",
      "city": "Hodeida",
      "state": "Al-Hudaydah",
      "country": "YE",
      "elevation": 41,
      "lat": 14.7530002594,
      "lon": 42.9762992859,
      "tz": "Asia\/Aden"
  },
  "OYKM": {
      "icao": "OYKM",
      "iata": "",
      "name": "Kamaran Airport",
      "city": "Kamaran",
      "state": "Al-Hudaydah",
      "country": "YE",
      "elevation": 51,
      "lat": 15.3633003235,
      "lon": 42.6049995422,
      "tz": "Asia\/Aden"
  },
  "OYMB": {
      "icao": "OYMB",
      "iata": "MYN",
      "name": "Mareb Airport",
      "city": "Mareb",
      "state": "Ma\u2019rib",
      "country": "YE",
      "elevation": 3300,
      "lat": 15.4692001343,
      "lon": 45.3269004822,
      "tz": "Asia\/Aden"
  },
  "OYQN": {
      "icao": "OYQN",
      "iata": "IHN",
      "name": "Qishn Airport",
      "city": "Qishn",
      "state": "Al-Mahrah",
      "country": "YE",
      "elevation": 98,
      "lat": 15.4169998169,
      "lon": 51.6829986572,
      "tz": "Asia\/Aden"
  },
  "OYRN": {
      "icao": "OYRN",
      "iata": "RIY",
      "name": "Mukalla International Airport",
      "city": "Riyan",
      "state": "Mu\u1e29afaz\u0327at-\u1e28a\u1e11ramawt",
      "country": "YE",
      "elevation": 54,
      "lat": 14.6625995636,
      "lon": 49.375,
      "tz": "Asia\/Aden"
  },
  "OYRT": {
      "icao": "OYRT",
      "iata": "",
      "name": "Barat Airport",
      "city": "Barat",
      "state": "Al-Jawf",
      "country": "YE",
      "elevation": 6800,
      "lat": 16.7329998016,
      "lon": 44.3499984741,
      "tz": "Asia\/Aden"
  },
  "OYSF": {
      "icao": "OYSF",
      "iata": "",
      "name": "As Salif Airport",
      "city": "As Salif",
      "state": "Al-Hudaydah",
      "country": "YE",
      "elevation": 0,
      "lat": 15.3000001907,
      "lon": 42.6829986572,
      "tz": "Asia\/Aden"
  },
  "OYSH": {
      "icao": "OYSH",
      "iata": "SYE",
      "name": "Sadah Airport",
      "city": "Sadah",
      "state": "Sa\u2018dah",
      "country": "YE",
      "elevation": 5938,
      "lat": 16.966999054,
      "lon": 43.733001709,
      "tz": "Asia\/Aden"
  },
  "OYSN": {
      "icao": "OYSN",
      "iata": "SAH",
      "name": "Sana'a International Airport",
      "city": "Sana'a",
      "state": "Amanat-Al-Asimah",
      "country": "YE",
      "elevation": 7216,
      "lat": 15.4763002396,
      "lon": 44.2196998596,
      "tz": "Asia\/Aden"
  },
  "OYSQ": {
      "icao": "OYSQ",
      "iata": "SCT",
      "name": "Socotra International Airport",
      "city": "Socotra Islands",
      "state": "Soqatra",
      "country": "YE",
      "elevation": 146,
      "lat": 12.6307001114,
      "lon": 53.9057998657,
      "tz": "Asia\/Aden"
  },
  "OYSY": {
      "icao": "OYSY",
      "iata": "GXF",
      "name": "Sayun International Airport",
      "city": "Sayun",
      "state": "Mu\u1e29afaz\u0327at-\u1e28a\u1e11ramawt",
      "country": "YE",
      "elevation": 2097,
      "lat": 15.9660997391,
      "lon": 48.7882995605,
      "tz": "Asia\/Aden"
  },
  "OYTZ": {
      "icao": "OYTZ",
      "iata": "TAI",
      "name": "Ta'izz International Airport",
      "city": "Ta'izz",
      "state": "Ta\u2018izz",
      "country": "YE",
      "elevation": 4838,
      "lat": 13.6859998703,
      "lon": 44.1390991211,
      "tz": "Asia\/Aden"
  },
  "OYZM": {
      "icao": "OYZM",
      "iata": "",
      "name": "Al-Hazm Airport",
      "city": "Al-Hazm",
      "state": "Al-Jawf",
      "country": "YE",
      "elevation": 3200,
      "lat": 16.2000007629,
      "lon": 44.783000946,
      "tz": "Asia\/Aden"
  },
  "PA01": {
      "icao": "PA01",
      "iata": "",
      "name": "Merrys Pymatuning Airport",
      "city": "Linesville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1203,
      "lat": 41.6750984192,
      "lon": -80.4345016479,
      "tz": "America\/New_York"
  },
  "PA02": {
      "icao": "PA02",
      "iata": "",
      "name": "Dillen Personal Airport",
      "city": "Mc Kean",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1028,
      "lat": 41.9833984375,
      "lon": -80.1830978394,
      "tz": "America\/New_York"
  },
  "PA05": {
      "icao": "PA05",
      "iata": "",
      "name": "Monesmith Airport",
      "city": "Point Pleasant",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 390,
      "lat": 40.4412002563,
      "lon": -75.0748977661,
      "tz": "America\/New_York"
  },
  "PA07": {
      "icao": "PA07",
      "iata": "",
      "name": "Bilinski Airport",
      "city": "Middleboro",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1080,
      "lat": 42.0144996643,
      "lon": -80.1201019287,
      "tz": "America\/New_York"
  },
  "PA08": {
      "icao": "PA08",
      "iata": "",
      "name": "Jj & Pk Airport",
      "city": "Greencastle",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 542,
      "lat": 39.7933998108,
      "lon": -77.7780990601,
      "tz": "America\/New_York"
  },
  "PA17": {
      "icao": "PA17",
      "iata": "",
      "name": "Canaan's Field",
      "city": "Lawrenceville",
      "state": "New-York",
      "country": "US",
      "elevation": 1600,
      "lat": 42.0037002563,
      "lon": -77.0999984741,
      "tz": "America\/New_York"
  },
  "PA18": {
      "icao": "PA18",
      "iata": "",
      "name": "Buzzards Field",
      "city": "Mill Hall",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 760,
      "lat": 41.0942001343,
      "lon": -77.4143981934,
      "tz": "America\/New_York"
  },
  "PA19": {
      "icao": "PA19",
      "iata": "",
      "name": "Strizki Ultralightport",
      "city": "Elkdale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1260,
      "lat": 41.6972999573,
      "lon": -75.5949020386,
      "tz": "America\/New_York"
  },
  "PA20": {
      "icao": "PA20",
      "iata": "",
      "name": "Thermal G. Ranch Gliderport",
      "city": "Erie",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1430,
      "lat": 42.0205993652,
      "lon": -80.070602417,
      "tz": "America\/New_York"
  },
  "PA21": {
      "icao": "PA21",
      "iata": "",
      "name": "West Middlesex Airport",
      "city": "West Middlesex",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1100,
      "lat": 41.1652984619,
      "lon": -80.5026016235,
      "tz": "America\/New_York"
  },
  "PA22": {
      "icao": "PA22",
      "iata": "",
      "name": "Hermitage Airport",
      "city": "Sharon",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1100,
      "lat": 41.2014007568,
      "lon": -80.4223022461,
      "tz": "America\/New_York"
  },
  "PA23": {
      "icao": "PA23",
      "iata": "",
      "name": "Kingsdale Air Park",
      "city": "Littlestown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 595,
      "lat": 39.7270011902,
      "lon": -77.1119003296,
      "tz": "America\/New_York"
  },
  "PA25": {
      "icao": "PA25",
      "iata": "",
      "name": "Cuatros Vientos Airport",
      "city": "Quaker City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 500,
      "lat": 40.6593017578,
      "lon": -75.8738021851,
      "tz": "America\/New_York"
  },
  "PA30": {
      "icao": "PA30",
      "iata": "",
      "name": "East Penn Airport",
      "city": "Andreas",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 885,
      "lat": 40.7667999268,
      "lon": -75.774597168,
      "tz": "America\/New_York"
  },
  "PA31": {
      "icao": "PA31",
      "iata": "",
      "name": "Rover Airport",
      "city": "Annville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 500,
      "lat": 40.2834014893,
      "lon": -76.5393981934,
      "tz": "America\/New_York"
  },
  "PA33": {
      "icao": "PA33",
      "iata": "",
      "name": "Lars\/Private Airport",
      "city": "Athens",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1300,
      "lat": 41.9500999451,
      "lon": -76.483001709,
      "tz": "America\/New_York"
  },
  "PA35": {
      "icao": "PA35",
      "iata": "",
      "name": "Bally Spring Farm Airport",
      "city": "Bally",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 660,
      "lat": 40.4319992065,
      "lon": -75.6259994507,
      "tz": "America\/New_York"
  },
  "PA36": {
      "icao": "PA36",
      "iata": "",
      "name": "Stefanik Airport",
      "city": "Bedminster",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 520,
      "lat": 40.4528999329,
      "lon": -75.2037963867,
      "tz": "America\/New_York"
  },
  "PA39": {
      "icao": "PA39",
      "iata": "",
      "name": "Beaver Springs Airport",
      "city": "Benfer",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 642,
      "lat": 40.7765007019,
      "lon": -77.2164001465,
      "tz": "America\/New_York"
  },
  "PA40": {
      "icao": "PA40",
      "iata": "",
      "name": "Benton Airport",
      "city": "Benton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 780,
      "lat": 41.2000999451,
      "lon": -76.3871994019,
      "tz": "America\/New_York"
  },
  "PA43": {
      "icao": "PA43",
      "iata": "",
      "name": "Arnold Airport",
      "city": "Birdsboro",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 680,
      "lat": 40.2667999268,
      "lon": -75.8830032349,
      "tz": "America\/New_York"
  },
  "PA44": {
      "icao": "PA44",
      "iata": "",
      "name": "Stone Castle Motel Airport",
      "city": "Bloomsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 585,
      "lat": 40.9735984802,
      "lon": -76.5186004639,
      "tz": "America\/New_York"
  },
  "PA45": {
      "icao": "PA45",
      "iata": "",
      "name": "Logue Airport",
      "city": "Bodines",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1320,
      "lat": 41.4166984558,
      "lon": -76.9329986572,
      "tz": "America\/New_York"
  },
  "PA49": {
      "icao": "PA49",
      "iata": "",
      "name": "Mountain Bay Air Park Inc Airport",
      "city": "Hawley",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1500,
      "lat": 41.3801002502,
      "lon": -75.2323989868,
      "tz": "America\/New_York"
  },
  "PA51": {
      "icao": "PA51",
      "iata": "",
      "name": "Bowtie Airport",
      "city": "Biglerville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 560,
      "lat": 39.9085006714,
      "lon": -77.2052001953,
      "tz": "America\/New_York"
  },
  "PA52": {
      "icao": "PA52",
      "iata": "",
      "name": "Oberlander Airport",
      "city": "Union City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1626,
      "lat": 41.859333,
      "lon": -79.907167,
      "tz": "America\/New_York"
  },
  "PA53": {
      "icao": "PA53",
      "iata": "",
      "name": "Cosklos Elkview Airport",
      "city": "Carbondale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1710,
      "lat": 41.6269989014,
      "lon": -75.5281982422,
      "tz": "America\/New_York"
  },
  "PA54": {
      "icao": "PA54",
      "iata": "",
      "name": "Kellachows Airport",
      "city": "Carbondale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1430,
      "lat": 41.5223007202,
      "lon": -75.3960037231,
      "tz": "America\/New_York"
  },
  "PA55": {
      "icao": "PA55",
      "iata": "",
      "name": "Neiderer Airport",
      "city": "Carlisle",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 440,
      "lat": 40.2168006897,
      "lon": -77.1164016724,
      "tz": "America\/New_York"
  },
  "PA58": {
      "icao": "PA58",
      "iata": "",
      "name": "Rocktop Airport",
      "city": "Chambersburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 770,
      "lat": 39.9749984741,
      "lon": -77.557800293,
      "tz": "America\/New_York"
  },
  "PA61": {
      "icao": "PA61",
      "iata": "",
      "name": "Kunda Airport",
      "city": "Collegeville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 252,
      "lat": 40.1668014526,
      "lon": -75.4496002197,
      "tz": "America\/New_York"
  },
  "PA65": {
      "icao": "PA65",
      "iata": "",
      "name": "Hi-Vu Airport",
      "city": "Coplay",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 760,
      "lat": 40.7000999451,
      "lon": -75.5495986938,
      "tz": "America\/New_York"
  },
  "PA66": {
      "icao": "PA66",
      "iata": "",
      "name": "Cumberland Valley Airstrip",
      "city": "Greencastle",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 39.8167991638,
      "lon": -77.7260971069,
      "tz": "America\/New_York"
  },
  "PA68": {
      "icao": "PA68",
      "iata": "",
      "name": "Bugs Airport",
      "city": "Nazareth",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 430,
      "lat": 40.7142982483,
      "lon": -75.3042984009,
      "tz": "America\/New_York"
  },
  "PA69": {
      "icao": "PA69",
      "iata": "",
      "name": "Vogelsong Airport",
      "city": "Dillsburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 590,
      "lat": 40.1287002563,
      "lon": -76.9693984985,
      "tz": "America\/New_York"
  },
  "PA70": {
      "icao": "PA70",
      "iata": "",
      "name": "Fetters Construction Airport",
      "city": "Downingtown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 500,
      "lat": 40.0918006897,
      "lon": -75.7108001709,
      "tz": "America\/New_York"
  },
  "PA71": {
      "icao": "PA71",
      "iata": "",
      "name": "Dwight's Delight Airport",
      "city": "Dushore",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1717,
      "lat": 41.5209007263,
      "lon": -76.4621963501,
      "tz": "America\/New_York"
  },
  "PA72": {
      "icao": "PA72",
      "iata": "",
      "name": "Circle W Airfield",
      "city": "East Berlin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 460,
      "lat": 39.9586982727,
      "lon": -76.9708023071,
      "tz": "America\/New_York"
  },
  "PA73": {
      "icao": "PA73",
      "iata": "",
      "name": "D.Evans Farm Airport",
      "city": "East Prospect",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 525,
      "lat": 39.9794998169,
      "lon": -76.5361022949,
      "tz": "America\/New_York"
  },
  "PA74": {
      "icao": "PA74",
      "iata": "",
      "name": "Gusler Airport",
      "city": "Elizabethville\/Halifax",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 700,
      "lat": 40.4654006958,
      "lon": -76.8399963379,
      "tz": "America\/New_York"
  },
  "PA75": {
      "icao": "PA75",
      "iata": "",
      "name": "Baker Airport",
      "city": "Elkland",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1800,
      "lat": 41.9650993347,
      "lon": -77.3158035278,
      "tz": "America\/New_York"
  },
  "PA76": {
      "icao": "PA76",
      "iata": "",
      "name": "Rosenzweig Airport",
      "city": "Wilkes Barre",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1930,
      "lat": 41.1370010376,
      "lon": -75.8610992432,
      "tz": "America\/New_York"
  },
  "PA77": {
      "icao": "PA77",
      "iata": "",
      "name": "Erwinna Private Airport",
      "city": "Erwinna",
      "state": "New-Jersey",
      "country": "US",
      "elevation": 350,
      "lat": 40.5000991821,
      "lon": -75.0662994385,
      "tz": "America\/New_York"
  },
  "PA78": {
      "icao": "PA78",
      "iata": "",
      "name": "Tintinhull Airport",
      "city": "Erwinna",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 350,
      "lat": 40.5000991821,
      "lon": -75.082901001,
      "tz": "America\/New_York"
  },
  "PA81": {
      "icao": "PA81",
      "iata": "",
      "name": "5 Lakes Airport",
      "city": "Fayetteville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 819,
      "lat": 39.9127998352,
      "lon": -77.5327987671,
      "tz": "America\/New_York"
  },
  "PA82": {
      "icao": "PA82",
      "iata": "",
      "name": "Grayce Farms Airport",
      "city": "Fleetville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1300,
      "lat": 41.6001014709,
      "lon": -75.6829986572,
      "tz": "America\/New_York"
  },
  "PA84": {
      "icao": "PA84",
      "iata": "",
      "name": "Level Acres Farm Airport",
      "city": "Fordville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 523,
      "lat": 39.9542999268,
      "lon": -76.9094009399,
      "tz": "America\/New_York"
  },
  "PA85": {
      "icao": "PA85",
      "iata": "",
      "name": "Drewniany-Springmeadow Airport",
      "city": "Frederick",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 330,
      "lat": 40.3047981262,
      "lon": -75.5335006714,
      "tz": "America\/New_York"
  },
  "PA86": {
      "icao": "PA86",
      "iata": "",
      "name": "Krill Personal Use Airport",
      "city": "Frystown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 520,
      "lat": 40.4476013184,
      "lon": -76.3411026001,
      "tz": "America\/New_York"
  },
  "PA88": {
      "icao": "PA88",
      "iata": "",
      "name": "Nemacolin Airport",
      "city": "Farmington",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2010,
      "lat": 39.8055992126,
      "lon": -79.5488967896,
      "tz": "America\/New_York"
  },
  "PA89": {
      "icao": "PA89",
      "iata": "",
      "name": "Ben's Landing Ultralightport",
      "city": "East Berlin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 39.9589996338,
      "lon": -77.049697876,
      "tz": "America\/New_York"
  },
  "PA92": {
      "icao": "PA92",
      "iata": "",
      "name": "Blue Mountain Academy (Private) Airport",
      "city": "Hamburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 531,
      "lat": 40.552898407,
      "lon": -76.0288009644,
      "tz": "America\/New_York"
  },
  "PAAK": {
      "icao": "PAAK",
      "iata": "AKB",
      "name": "Atka Airport",
      "city": "Atka",
      "state": "Alaska",
      "country": "US",
      "elevation": 57,
      "lat": 52.22029877,
      "lon": -174.2059937,
      "tz": "America\/Adak"
  },
  "PAAL": {
      "icao": "PAAL",
      "iata": "PML",
      "name": "Port Moller Airport",
      "city": "Cold Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 20,
      "lat": 56.0060005188,
      "lon": -160.561004639,
      "tz": "America\/Anchorage"
  },
  "PAAM": {
      "icao": "PAAM",
      "iata": "",
      "name": "Driftwood Bay Air Force Station Airport",
      "city": "Dutch Harbor",
      "state": "Alaska",
      "country": "US",
      "elevation": 24,
      "lat": 53.9729499817,
      "lon": -166.858459473,
      "tz": "America\/Nome"
  },
  "PAAN": {
      "icao": "PAAN",
      "iata": "",
      "name": "Gold King Creek Airport",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 1720,
      "lat": 64.1989974976,
      "lon": -147.9279937744,
      "tz": "America\/Anchorage"
  },
  "PAAQ": {
      "icao": "PAAQ",
      "iata": "PAQ",
      "name": "Palmer Municipal Airport",
      "city": "Palmer",
      "state": "Alaska",
      "country": "US",
      "elevation": 242,
      "lat": 61.5948982239,
      "lon": -149.0890045166,
      "tz": "America\/Anchorage"
  },
  "PABA": {
      "icao": "PABA",
      "iata": "BTI",
      "name": "Barter Island LRRS Airport",
      "city": "Barter Island Lrrs",
      "state": "Alaska",
      "country": "US",
      "elevation": 2,
      "lat": 70.1340026855,
      "lon": -143.582000732,
      "tz": "America\/Anchorage"
  },
  "PABE": {
      "icao": "PABE",
      "iata": "BET",
      "name": "Bethel Airport",
      "city": "Bethel",
      "state": "Alaska",
      "country": "US",
      "elevation": 126,
      "lat": 60.77980042,
      "lon": -161.8379974,
      "tz": "America\/Anchorage"
  },
  "PABG": {
      "icao": "PABG",
      "iata": "BVU",
      "name": "Beluga Airport",
      "city": "Beluga",
      "state": "Alaska",
      "country": "US",
      "elevation": 130,
      "lat": 61.1721992493,
      "lon": -151.044006348,
      "tz": "America\/Anchorage"
  },
  "PABI": {
      "icao": "PABI",
      "iata": "BIG",
      "name": "Allen Army Airfield",
      "city": "Delta Junction Ft Greely",
      "state": "Alaska",
      "country": "US",
      "elevation": 1291,
      "lat": 63.9944992065,
      "lon": -145.722000122,
      "tz": "America\/Anchorage"
  },
  "PABL": {
      "icao": "PABL",
      "iata": "BKC",
      "name": "Buckland Airport",
      "city": "Buckland",
      "state": "Alaska",
      "country": "US",
      "elevation": 31,
      "lat": 65.9815979004,
      "lon": -161.149002075,
      "tz": "America\/Anchorage"
  },
  "PABM": {
      "icao": "PABM",
      "iata": "BMX",
      "name": "Big Mountain Airport",
      "city": "Big Mountain",
      "state": "Alaska",
      "country": "US",
      "elevation": 663,
      "lat": 59.3611984253,
      "lon": -155.259002686,
      "tz": "America\/Anchorage"
  },
  "PABN": {
      "icao": "PABN",
      "iata": "",
      "name": "Devils Mountain Lodge Airport",
      "city": "Nabesna",
      "state": "Alaska",
      "country": "US",
      "elevation": 2880,
      "lat": 62.4017982483,
      "lon": -142.994995117,
      "tz": "America\/Anchorage"
  },
  "PABP": {
      "icao": "PABP",
      "iata": "",
      "name": "Badami Airport",
      "city": "Deadhorse",
      "state": "Alaska",
      "country": "US",
      "elevation": 26,
      "lat": 70.1374969482,
      "lon": -147.0299987793,
      "tz": "America\/Anchorage"
  },
  "PABR": {
      "icao": "PABR",
      "iata": "BRW",
      "name": "Wiley Post Will Rogers Memorial Airport",
      "city": "Barrow",
      "state": "Alaska",
      "country": "US",
      "elevation": 44,
      "lat": 71.2854003906,
      "lon": -156.7660064697,
      "tz": "America\/Anchorage"
  },
  "PABT": {
      "icao": "PABT",
      "iata": "BTT",
      "name": "Bettles Airport",
      "city": "Bettles",
      "state": "Alaska",
      "country": "US",
      "elevation": 647,
      "lat": 66.91390228,
      "lon": -151.529007,
      "tz": "America\/Anchorage"
  },
  "PABU": {
      "icao": "PABU",
      "iata": "",
      "name": "Bullen Point Air Force Station Airport",
      "city": "Kaktovik",
      "state": "Alaska",
      "country": "US",
      "elevation": 18,
      "lat": 70.1727981567,
      "lon": -146.8359985352,
      "tz": "America\/Anchorage"
  },
  "PABV": {
      "icao": "PABV",
      "iata": "",
      "name": "Birchwood Airport",
      "city": "Birchwood",
      "state": "Alaska",
      "country": "US",
      "elevation": 83,
      "lat": 61.41650009,
      "lon": -149.5070038,
      "tz": "America\/Anchorage"
  },
  "PACD": {
      "icao": "PACD",
      "iata": "CDB",
      "name": "Cold Bay Airport",
      "city": "Cold Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 96,
      "lat": 55.2061004639,
      "lon": -162.7250061035,
      "tz": "America\/Nome"
  },
  "PACE": {
      "icao": "PACE",
      "iata": "CEM",
      "name": "Central Airport",
      "city": "Central",
      "state": "Alaska",
      "country": "US",
      "elevation": 937,
      "lat": 65.57379913,
      "lon": -144.7830048,
      "tz": "America\/Anchorage"
  },
  "PACH": {
      "icao": "PACH",
      "iata": "CHU",
      "name": "Chuathbaluk Airport",
      "city": "Chuathbaluk",
      "state": "Alaska",
      "country": "US",
      "elevation": 244,
      "lat": 61.57910156,
      "lon": -159.2160034,
      "tz": "America\/Anchorage"
  },
  "PACI": {
      "icao": "PACI",
      "iata": "CIK",
      "name": "Chalkyitsik Airport",
      "city": "Chalkyitsik",
      "state": "Alaska",
      "country": "US",
      "elevation": 544,
      "lat": 66.6449966431,
      "lon": -143.740005493,
      "tz": "America\/Anchorage"
  },
  "PACK": {
      "icao": "PACK",
      "iata": "CYF",
      "name": "Chefornak Airport",
      "city": "Chefornak",
      "state": "Alaska",
      "country": "US",
      "elevation": 40,
      "lat": 60.1492004395,
      "lon": -164.2859954834,
      "tz": "America\/Nome"
  },
  "PACL": {
      "icao": "PACL",
      "iata": "",
      "name": "Clear Airport",
      "city": "Clear",
      "state": "Alaska",
      "country": "US",
      "elevation": 552,
      "lat": 64.3012008667,
      "lon": -149.119995117,
      "tz": "America\/Anchorage"
  },
  "PACM": {
      "icao": "PACM",
      "iata": "SCM",
      "name": "Scammon Bay Airport",
      "city": "Scammon Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 14,
      "lat": 61.8452987671,
      "lon": -165.570999146,
      "tz": "America\/Nome"
  },
  "PACR": {
      "icao": "PACR",
      "iata": "IRC",
      "name": "Circle City \/New\/ Airport",
      "city": "Circle",
      "state": "Alaska",
      "country": "US",
      "elevation": 613,
      "lat": 65.83049774,
      "lon": -144.076004,
      "tz": "America\/Anchorage"
  },
  "PACS": {
      "icao": "PACS",
      "iata": "WSF",
      "name": "Cape Sarichef Airport",
      "city": "Cape Sarichef",
      "state": "Alaska",
      "country": "US",
      "elevation": 291,
      "lat": 54.5824012756,
      "lon": -164.9140014648,
      "tz": "America\/Nome"
  },
  "PACV": {
      "icao": "PACV",
      "iata": "CDV",
      "name": "Merle K (Mudhole) Smith Airport",
      "city": "Cordova",
      "state": "Alaska",
      "country": "US",
      "elevation": 54,
      "lat": 60.4917984,
      "lon": -145.4779968,
      "tz": "America\/Anchorage"
  },
  "PACX": {
      "icao": "PACX",
      "iata": "CXF",
      "name": "Coldfoot Airport",
      "city": "Coldfoot",
      "state": "Alaska",
      "country": "US",
      "elevation": 1042,
      "lat": 67.2521972656,
      "lon": -150.203994751,
      "tz": "America\/Anchorage"
  },
  "PACY": {
      "icao": "PACY",
      "iata": "CYT",
      "name": "Yakataga Airport",
      "city": "Yakataga",
      "state": "Alaska",
      "country": "US",
      "elevation": 12,
      "lat": 60.0820007324,
      "lon": -142.4929962158,
      "tz": "America\/Anchorage"
  },
  "PACZ": {
      "icao": "PACZ",
      "iata": "CZF",
      "name": "Cape Romanzof LRRS Airport",
      "city": "Cape Romanzof",
      "state": "Alaska",
      "country": "US",
      "elevation": 464,
      "lat": 61.78030014,
      "lon": -166.0390015,
      "tz": "America\/Nome"
  },
  "PADE": {
      "icao": "PADE",
      "iata": "DRG",
      "name": "Deering Airport",
      "city": "Deering",
      "state": "Alaska",
      "country": "US",
      "elevation": 21,
      "lat": 66.0696029663,
      "lon": -162.76600647,
      "tz": "America\/Nome"
  },
  "PADG": {
      "icao": "PADG",
      "iata": "RDB",
      "name": "Red Dog Airport",
      "city": "Red Dog",
      "state": "Alaska",
      "country": "US",
      "elevation": 969,
      "lat": 68.0320968628,
      "lon": -162.899002075,
      "tz": "America\/Nome"
  },
  "PADK": {
      "icao": "PADK",
      "iata": "ADK",
      "name": "Adak Airport",
      "city": "Adak Island",
      "state": "Alaska",
      "country": "US",
      "elevation": 18,
      "lat": 51.8779983521,
      "lon": -176.6459960938,
      "tz": "America\/Adak"
  },
  "PADL": {
      "icao": "PADL",
      "iata": "DLG",
      "name": "Dillingham Airport",
      "city": "Dillingham",
      "state": "Alaska",
      "country": "US",
      "elevation": 81,
      "lat": 59.04470062,
      "lon": -158.5050049,
      "tz": "America\/Anchorage"
  },
  "PADM": {
      "icao": "PADM",
      "iata": "MLL",
      "name": "Marshall Don Hunter Sr Airport",
      "city": "Marshall",
      "state": "Alaska",
      "country": "US",
      "elevation": 103,
      "lat": 61.8642997742,
      "lon": -162.0260009766,
      "tz": "America\/Anchorage"
  },
  "PADQ": {
      "icao": "PADQ",
      "iata": "ADQ",
      "name": "Kodiak Airport",
      "city": "Kodiak",
      "state": "Alaska",
      "country": "US",
      "elevation": 78,
      "lat": 57.75,
      "lon": -152.4940033,
      "tz": "America\/Anchorage"
  },
  "PADU": {
      "icao": "PADU",
      "iata": "DUT",
      "name": "Unalaska Airport",
      "city": "Unalaska",
      "state": "Alaska",
      "country": "US",
      "elevation": 22,
      "lat": 53.900100708,
      "lon": -166.5440063477,
      "tz": "America\/Nome"
  },
  "PADY": {
      "icao": "PADY",
      "iata": "KKH",
      "name": "Kongiganak Airport",
      "city": "Kongiganak",
      "state": "Alaska",
      "country": "US",
      "elevation": 30,
      "lat": 59.9608001709,
      "lon": -162.8809967041,
      "tz": "America\/Nome"
  },
  "PAED": {
      "icao": "PAED",
      "iata": "EDF",
      "name": "Elmendorf Air Force Base",
      "city": "Anchorage",
      "state": "Alaska",
      "country": "US",
      "elevation": 212,
      "lat": 61.2509994507,
      "lon": -149.8070068359,
      "tz": "America\/Anchorage"
  },
  "PAEE": {
      "icao": "PAEE",
      "iata": "EEK",
      "name": "Eek Airport",
      "city": "Eek",
      "state": "Alaska",
      "country": "US",
      "elevation": 12,
      "lat": 60.21367264,
      "lon": -162.0438843,
      "tz": "America\/Nome"
  },
  "PAEG": {
      "icao": "PAEG",
      "iata": "EAA",
      "name": "Eagle Airport",
      "city": "Eagle",
      "state": "Alaska",
      "country": "US",
      "elevation": 908,
      "lat": 64.77639771,
      "lon": -141.151001,
      "tz": "America\/Anchorage"
  },
  "PAEH": {
      "icao": "PAEH",
      "iata": "EHM",
      "name": "Cape Newenham LRRS Airport",
      "city": "Cape Newenham",
      "state": "Alaska",
      "country": "US",
      "elevation": 541,
      "lat": 58.6464004517,
      "lon": -162.06300354,
      "tz": "America\/Nome"
  },
  "PAEI": {
      "icao": "PAEI",
      "iata": "EIL",
      "name": "Eielson Air Force Base",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 547,
      "lat": 64.66570282,
      "lon": -147.102005,
      "tz": "America\/Anchorage"
  },
  "PAEM": {
      "icao": "PAEM",
      "iata": "EMK",
      "name": "Emmonak Airport",
      "city": "Emmonak",
      "state": "Alaska",
      "country": "US",
      "elevation": 13,
      "lat": 62.78609848,
      "lon": -164.4909973,
      "tz": "America\/Nome"
  },
  "PAEN": {
      "icao": "PAEN",
      "iata": "ENA",
      "name": "Kenai Municipal Airport",
      "city": "Kenai",
      "state": "Alaska",
      "country": "US",
      "elevation": 99,
      "lat": 60.5731010437,
      "lon": -151.2449951172,
      "tz": "America\/Anchorage"
  },
  "PAEW": {
      "icao": "PAEW",
      "iata": "WWT",
      "name": "Newtok Airport",
      "city": "Newtok",
      "state": "Alaska",
      "country": "US",
      "elevation": 25,
      "lat": 60.9390983582,
      "lon": -164.6410064697,
      "tz": "America\/Nome"
  },
  "PAFA": {
      "icao": "PAFA",
      "iata": "FAI",
      "name": "Fairbanks International Airport",
      "city": "Fairbanks",
      "state": "Alaska",
      "country": "US",
      "elevation": 439,
      "lat": 64.81510162,
      "lon": -147.8560028,
      "tz": "America\/Anchorage"
  },
  "PAFB": {
      "icao": "PAFB",
      "iata": "FBK",
      "name": "Ladd AAF Airfield",
      "city": "Fairbanks\/Ft Wainwright",
      "state": "Alaska",
      "country": "US",
      "elevation": 454,
      "lat": 64.83750153,
      "lon": -147.6139984,
      "tz": "America\/Anchorage"
  },
  "PAFE": {
      "icao": "PAFE",
      "iata": "AFE",
      "name": "Kake Airport",
      "city": "Kake",
      "state": "Alaska",
      "country": "US",
      "elevation": 172,
      "lat": 56.9613990784,
      "lon": -133.9100036621,
      "tz": "America\/Sitka"
  },
  "PAFL": {
      "icao": "PAFL",
      "iata": "",
      "name": "Tin Creek Airport",
      "city": "Farewell Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 1151,
      "lat": 62.5316009521,
      "lon": -153.6109924316,
      "tz": "America\/Anchorage"
  },
  "PAFM": {
      "icao": "PAFM",
      "iata": "ABL",
      "name": "Ambler Airport",
      "city": "Ambler",
      "state": "Alaska",
      "country": "US",
      "elevation": 334,
      "lat": 67.106300354,
      "lon": -157.856994629,
      "tz": "America\/Anchorage"
  },
  "PAFS": {
      "icao": "PAFS",
      "iata": "NIB",
      "name": "Nikolai Airport",
      "city": "Nikolai",
      "state": "Alaska",
      "country": "US",
      "elevation": 441,
      "lat": 63.0186004639,
      "lon": -154.358001709,
      "tz": "America\/Anchorage"
  },
  "PAFV": {
      "icao": "PAFV",
      "iata": "FMC",
      "name": "Five Mile Airport",
      "city": "Five Mile",
      "state": "Alaska",
      "country": "US",
      "elevation": 510,
      "lat": 65.9270019531,
      "lon": -149.839996338,
      "tz": "America\/Anchorage"
  },
  "PAFW": {
      "icao": "PAFW",
      "iata": "FWL",
      "name": "Farewell Airport",
      "city": "Farewell",
      "state": "Alaska",
      "country": "US",
      "elevation": 1535,
      "lat": 62.5065994263,
      "lon": -153.8939971924,
      "tz": "America\/Anchorage"
  },
  "PAGA": {
      "icao": "PAGA",
      "iata": "GAL",
      "name": "Edward G. Pitka Sr Airport",
      "city": "Galena",
      "state": "Alaska",
      "country": "US",
      "elevation": 153,
      "lat": 64.73619843,
      "lon": -156.9369965,
      "tz": "America\/Anchorage"
  },
  "PAGB": {
      "icao": "PAGB",
      "iata": "GBH",
      "name": "Galbraith Lake Airport",
      "city": "Galbraith Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 2663,
      "lat": 68.4796981812,
      "lon": -149.4900054932,
      "tz": "America\/Anchorage"
  },
  "PAGG": {
      "icao": "PAGG",
      "iata": "KWK",
      "name": "Kwigillingok Airport",
      "city": "Kwigillingok",
      "state": "Alaska",
      "country": "US",
      "elevation": 18,
      "lat": 59.876499176,
      "lon": -163.1690063477,
      "tz": "America\/Nome"
  },
  "PAGH": {
      "icao": "PAGH",
      "iata": "",
      "name": "Shungnak Airport",
      "city": "Shungnak",
      "state": "Alaska",
      "country": "US",
      "elevation": 197,
      "lat": 66.8880996704,
      "lon": -157.1620025635,
      "tz": "America\/Anchorage"
  },
  "PAGK": {
      "icao": "PAGK",
      "iata": "GKN",
      "name": "Gulkana Airport",
      "city": "Gulkana",
      "state": "Alaska",
      "country": "US",
      "elevation": 1586,
      "lat": 62.1548996,
      "lon": -145.4570007,
      "tz": "America\/Anchorage"
  },
  "PAGL": {
      "icao": "PAGL",
      "iata": "GLV",
      "name": "Golovin Airport",
      "city": "Golovin",
      "state": "Alaska",
      "country": "US",
      "elevation": 59,
      "lat": 64.5504989624,
      "lon": -163.007003784,
      "tz": "America\/Nome"
  },
  "PAGM": {
      "icao": "PAGM",
      "iata": "GAM",
      "name": "Gambell Airport",
      "city": "Gambell",
      "state": "Alaska",
      "country": "US",
      "elevation": 27,
      "lat": 63.7667999268,
      "lon": -171.733001709,
      "tz": "America\/Nome"
  },
  "PAGQ": {
      "icao": "PAGQ",
      "iata": "BGQ",
      "name": "Big Lake Airport",
      "city": "Big Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 158,
      "lat": 61.5360984802,
      "lon": -149.8139953613,
      "tz": "America\/Anchorage"
  },
  "PAGS": {
      "icao": "PAGS",
      "iata": "GST",
      "name": "Gustavus Airport",
      "city": "Gustavus",
      "state": "Alaska",
      "country": "US",
      "elevation": 35,
      "lat": 58.4253006,
      "lon": -135.7070007,
      "tz": "America\/Juneau"
  },
  "PAGT": {
      "icao": "PAGT",
      "iata": "NME",
      "name": "Nightmute Airport",
      "city": "Nightmute",
      "state": "Alaska",
      "country": "US",
      "elevation": 4,
      "lat": 60.4710006714,
      "lon": -164.7010040283,
      "tz": "America\/Nome"
  },
  "PAGY": {
      "icao": "PAGY",
      "iata": "SGY",
      "name": "Skagway Airport",
      "city": "Skagway",
      "state": "Alaska",
      "country": "US",
      "elevation": 44,
      "lat": 59.4600982666,
      "lon": -135.3159942627,
      "tz": "America\/Juneau"
  },
  "PAGZ": {
      "icao": "PAGZ",
      "iata": "GMT",
      "name": "Granite Mountain Air Station",
      "city": "Granite Mountain",
      "state": "Alaska",
      "country": "US",
      "elevation": 1313,
      "lat": 65.40209961,
      "lon": -161.2810059,
      "tz": "America\/Anchorage"
  },
  "PAHC": {
      "icao": "PAHC",
      "iata": "HCR",
      "name": "Holy Cross Airport",
      "city": "Holy Cross",
      "state": "Alaska",
      "country": "US",
      "elevation": 70,
      "lat": 62.1883010864,
      "lon": -159.7749938965,
      "tz": "America\/Anchorage"
  },
  "PAHE": {
      "icao": "PAHE",
      "iata": "HNE",
      "name": "Tahneta Pass Airport",
      "city": "Tahneta Pass Lodge",
      "state": "Alaska",
      "country": "US",
      "elevation": 2960,
      "lat": 61.9014015198,
      "lon": -147.304000854,
      "tz": "America\/Anchorage"
  },
  "PAHL": {
      "icao": "PAHL",
      "iata": "HSL",
      "name": "Huslia Airport",
      "city": "Huslia",
      "state": "Alaska",
      "country": "US",
      "elevation": 220,
      "lat": 65.69789886,
      "lon": -156.3509979,
      "tz": "America\/Anchorage"
  },
  "PAHN": {
      "icao": "PAHN",
      "iata": "HNS",
      "name": "Haines Airport",
      "city": "Haines",
      "state": "Alaska",
      "country": "US",
      "elevation": 15,
      "lat": 59.2438011169,
      "lon": -135.5240020752,
      "tz": "America\/Juneau"
  },
  "PAHO": {
      "icao": "PAHO",
      "iata": "HOM",
      "name": "Homer Airport",
      "city": "Homer",
      "state": "Alaska",
      "country": "US",
      "elevation": 84,
      "lat": 59.6455993652,
      "lon": -151.4770050049,
      "tz": "America\/Anchorage"
  },
  "PAHP": {
      "icao": "PAHP",
      "iata": "HPB",
      "name": "Hooper Bay Airport",
      "city": "Hooper Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 13,
      "lat": 61.52389908,
      "lon": -166.1470032,
      "tz": "America\/Nome"
  },
  "PAHU": {
      "icao": "PAHU",
      "iata": "HUS",
      "name": "Hughes Airport",
      "city": "Hughes",
      "state": "Alaska",
      "country": "US",
      "elevation": 299,
      "lat": 66.04109955,
      "lon": -154.2630005,
      "tz": "America\/Anchorage"
  },
  "PAHV": {
      "icao": "PAHV",
      "iata": "",
      "name": "Healy River Airport",
      "city": "Healy",
      "state": "Alaska",
      "country": "US",
      "elevation": 1263,
      "lat": 63.8661994934,
      "lon": -148.9689941406,
      "tz": "America\/Anchorage"
  },
  "PAHX": {
      "icao": "PAHX",
      "iata": "SHX",
      "name": "Shageluk Airport",
      "city": "Shageluk",
      "state": "Alaska",
      "country": "US",
      "elevation": 79,
      "lat": 62.6922988892,
      "lon": -159.569000244,
      "tz": "America\/Anchorage"
  },
  "PAIG": {
      "icao": "PAIG",
      "iata": "IGG",
      "name": "Igiugig Airport",
      "city": "Igiugig",
      "state": "Alaska",
      "country": "US",
      "elevation": 90,
      "lat": 59.3240013123,
      "lon": -155.9019927979,
      "tz": "America\/Anchorage"
  },
  "PAII": {
      "icao": "PAII",
      "iata": "EGX",
      "name": "Egegik Airport",
      "city": "Egegik",
      "state": "Alaska",
      "country": "US",
      "elevation": 92,
      "lat": 58.1855010986,
      "lon": -157.375,
      "tz": "America\/Anchorage"
  },
  "PAIK": {
      "icao": "PAIK",
      "iata": "IAN",
      "name": "Bob Baker Memorial Airport",
      "city": "Kiana",
      "state": "Alaska",
      "country": "US",
      "elevation": 166,
      "lat": 66.9759979248,
      "lon": -160.43699646,
      "tz": "America\/Anchorage"
  },
  "PAIL": {
      "icao": "PAIL",
      "iata": "ILI",
      "name": "Iliamna Airport",
      "city": "Iliamna",
      "state": "Alaska",
      "country": "US",
      "elevation": 192,
      "lat": 59.75439835,
      "lon": -154.9109955,
      "tz": "America\/Anchorage"
  },
  "PAIM": {
      "icao": "PAIM",
      "iata": "UTO",
      "name": "Indian Mountain LRRS Airport",
      "city": "Utopia Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 1273,
      "lat": 65.99279785,
      "lon": -153.7039948,
      "tz": "America\/Anchorage"
  },
  "PAIN": {
      "icao": "PAIN",
      "iata": "MCL",
      "name": "Mc Kinley National Park Airport",
      "city": "Mckinley Park",
      "state": "Alaska",
      "country": "US",
      "elevation": 1720,
      "lat": 63.7326011658,
      "lon": -148.910995483,
      "tz": "America\/Anchorage"
  },
  "PAIW": {
      "icao": "PAIW",
      "iata": "WAA",
      "name": "Wales Airport",
      "city": "Wales",
      "state": "Alaska",
      "country": "US",
      "elevation": 22,
      "lat": 65.6225967407,
      "lon": -168.095001221,
      "tz": "America\/Nome"
  },
  "PAJC": {
      "icao": "PAJC",
      "iata": "",
      "name": "Chignik Airport",
      "city": "Chignik",
      "state": "Alaska",
      "country": "US",
      "elevation": 18,
      "lat": 56.3115005493,
      "lon": -158.3730010986,
      "tz": "America\/Anchorage"
  },
  "PAJN": {
      "icao": "PAJN",
      "iata": "JNU",
      "name": "Juneau International Airport",
      "city": "Juneau",
      "state": "Alaska",
      "country": "US",
      "elevation": 21,
      "lat": 58.3549995422,
      "lon": -134.5760040283,
      "tz": "America\/Juneau"
  },
  "PAJO": {
      "icao": "PAJO",
      "iata": "",
      "name": "Johnstone Point Airport",
      "city": "Hinchinbrook",
      "state": "Alaska",
      "country": "US",
      "elevation": 15,
      "lat": 60.4817008972,
      "lon": -146.583999634,
      "tz": "America\/Anchorage"
  },
  "PAJV": {
      "icao": "PAJV",
      "iata": "",
      "name": "Jonesville Mine Airport",
      "city": "Sutton",
      "state": "Alaska",
      "country": "US",
      "elevation": 870,
      "lat": 61.7313995361,
      "lon": -148.9279937744,
      "tz": "America\/Anchorage"
  },
  "PAJZ": {
      "icao": "PAJZ",
      "iata": "KGK",
      "name": "Koliganek Airport",
      "city": "Koliganek",
      "state": "Alaska",
      "country": "US",
      "elevation": 269,
      "lat": 59.726600647,
      "lon": -157.2590026855,
      "tz": "America\/Anchorage"
  },
  "PAKD": {
      "icao": "PAKD",
      "iata": "KDK",
      "name": "Kodiak Municipal Airport",
      "city": "Kodiak",
      "state": "Alaska",
      "country": "US",
      "elevation": 139,
      "lat": 57.8059005737,
      "lon": -152.3739929199,
      "tz": "America\/Anchorage"
  },
  "PAKF": {
      "icao": "PAKF",
      "iata": "KFP",
      "name": "False Pass Airport",
      "city": "False Pass",
      "state": "Alaska",
      "country": "US",
      "elevation": 20,
      "lat": 54.8474006653,
      "lon": -163.4100036621,
      "tz": "America\/Nome"
  },
  "PAKH": {
      "icao": "PAKH",
      "iata": "AKK",
      "name": "Akhiok Airport",
      "city": "Akhiok",
      "state": "Alaska",
      "country": "US",
      "elevation": 44,
      "lat": 56.9387016296,
      "lon": -154.182998657,
      "tz": "America\/Anchorage"
  },
  "PAKI": {
      "icao": "PAKI",
      "iata": "KPN",
      "name": "Kipnuk Airport",
      "city": "Kipnuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 11,
      "lat": 59.9329986572,
      "lon": -164.0310058594,
      "tz": "America\/Nome"
  },
  "PAKK": {
      "icao": "PAKK",
      "iata": "KKA",
      "name": "Koyuk Alfred Adams Airport",
      "city": "Koyuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 154,
      "lat": 64.9394989014,
      "lon": -161.154006958,
      "tz": "America\/Anchorage"
  },
  "PAKL": {
      "icao": "PAKL",
      "iata": "LKK",
      "name": "Kulik Lake Airport",
      "city": "Kulik Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 717,
      "lat": 58.9821014404,
      "lon": -155.1210021973,
      "tz": "America\/Anchorage"
  },
  "PAKN": {
      "icao": "PAKN",
      "iata": "AKN",
      "name": "King Salmon Airport",
      "city": "King Salmon",
      "state": "Alaska",
      "country": "US",
      "elevation": 73,
      "lat": 58.67679977,
      "lon": -156.6490021,
      "tz": "America\/Anchorage"
  },
  "PAKO": {
      "icao": "PAKO",
      "iata": "IKO",
      "name": "Nikolski Air Station",
      "city": "Nikolski",
      "state": "Alaska",
      "country": "US",
      "elevation": 77,
      "lat": 52.9416007996,
      "lon": -168.8489990234,
      "tz": "America\/Nome"
  },
  "PAKP": {
      "icao": "PAKP",
      "iata": "AKP",
      "name": "Anaktuvuk Pass Airport",
      "city": "Anaktuvuk Pass",
      "state": "Alaska",
      "country": "US",
      "elevation": 2102,
      "lat": 68.13359833,
      "lon": -151.7429962,
      "tz": "America\/Anchorage"
  },
  "PAKT": {
      "icao": "PAKT",
      "iata": "KTN",
      "name": "Ketchikan International Airport",
      "city": "Ketchikan",
      "state": "Alaska",
      "country": "US",
      "elevation": 89,
      "lat": 55.35559845,
      "lon": -131.7140045,
      "tz": "America\/Sitka"
  },
  "PAKU": {
      "icao": "PAKU",
      "iata": "UUK",
      "name": "Ugnu-Kuparuk Airport",
      "city": "Kuparuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 67,
      "lat": 70.3308029175,
      "lon": -149.598007202,
      "tz": "America\/Anchorage"
  },
  "PAKV": {
      "icao": "PAKV",
      "iata": "KAL",
      "name": "Kaltag Airport",
      "city": "Kaltag",
      "state": "Alaska",
      "country": "US",
      "elevation": 181,
      "lat": 64.31909943,
      "lon": -158.7409973,
      "tz": "America\/Anchorage"
  },
  "PAKW": {
      "icao": "PAKW",
      "iata": "AKW",
      "name": "Klawock Airport",
      "city": "Klawock",
      "state": "Alaska",
      "country": "US",
      "elevation": 80,
      "lat": 55.5792007446,
      "lon": -133.0760040283,
      "tz": "America\/Sitka"
  },
  "PAKY": {
      "icao": "PAKY",
      "iata": "KYK",
      "name": "Karluk Airport",
      "city": "Karluk",
      "state": "Alaska",
      "country": "US",
      "elevation": 137,
      "lat": 57.5671005249,
      "lon": -154.449996948,
      "tz": "America\/Anchorage"
  },
  "PALB": {
      "icao": "PALB",
      "iata": "KLN",
      "name": "Larsen Bay Airport",
      "city": "Larsen Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 87,
      "lat": 57.5350990295,
      "lon": -153.9779968262,
      "tz": "America\/Anchorage"
  },
  "PALG": {
      "icao": "PALG",
      "iata": "KLG",
      "name": "Kalskag Airport",
      "city": "Kalskag",
      "state": "Alaska",
      "country": "US",
      "elevation": 55,
      "lat": 61.5363006592,
      "lon": -160.341003418,
      "tz": "America\/Anchorage"
  },
  "PALJ": {
      "icao": "PALJ",
      "iata": "PTA",
      "name": "Port Alsworth Airport",
      "city": "Port Alsworth",
      "state": "Alaska",
      "country": "US",
      "elevation": 280,
      "lat": 60.2042999268,
      "lon": -154.319000244,
      "tz": "America\/Anchorage"
  },
  "PALN": {
      "icao": "PALN",
      "iata": "LNI",
      "name": "Lonely Air Station",
      "city": "Lonely",
      "state": "Alaska",
      "country": "US",
      "elevation": 17,
      "lat": 70.91069794,
      "lon": -153.2420044,
      "tz": "America\/Anchorage"
  },
  "PALP": {
      "icao": "PALP",
      "iata": "DQH",
      "name": "Alpine Airstrip",
      "city": "Deadhorse",
      "state": "Alaska",
      "country": "US",
      "elevation": 18,
      "lat": 70.3442993164,
      "lon": -150.945007324,
      "tz": "America\/Anchorage"
  },
  "PALR": {
      "icao": "PALR",
      "iata": "WCR",
      "name": "Chandalar Lake Airport",
      "city": "Chandalar Lake",
      "state": "Alaska",
      "country": "US",
      "elevation": 1920,
      "lat": 67.5045013428,
      "lon": -148.483001709,
      "tz": "America\/Anchorage"
  },
  "PALU": {
      "icao": "PALU",
      "iata": "LUR",
      "name": "Cape Lisburne LRRS Airport",
      "city": "Cape Lisburne",
      "state": "Alaska",
      "country": "US",
      "elevation": 16,
      "lat": 68.87509918,
      "lon": -166.1100006,
      "tz": "America\/Nome"
  },
  "PAMB": {
      "icao": "PAMB",
      "iata": "KMO",
      "name": "Manokotak Airport",
      "city": "Manokotak",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 58.9902000427,
      "lon": -159.050003052,
      "tz": "America\/Anchorage"
  },
  "PAMC": {
      "icao": "PAMC",
      "iata": "MCG",
      "name": "McGrath Airport",
      "city": "McGrath",
      "state": "Alaska",
      "country": "US",
      "elevation": 341,
      "lat": 62.95289993,
      "lon": -155.6060028,
      "tz": "America\/Anchorage"
  },
  "PAMD": {
      "icao": "PAMD",
      "iata": "MDO",
      "name": "Middleton Island Airport",
      "city": "Middleton Island",
      "state": "Alaska",
      "country": "US",
      "elevation": 100,
      "lat": 59.4499015808,
      "lon": -146.307006836,
      "tz": "America\/Anchorage"
  },
  "PAMH": {
      "icao": "PAMH",
      "iata": "LMA",
      "name": "Minchumina Airport",
      "city": "Minchumina",
      "state": "Alaska",
      "country": "US",
      "elevation": 678,
      "lat": 63.8860015869,
      "lon": -152.3020019531,
      "tz": "America\/Anchorage"
  },
  "PAMK": {
      "icao": "PAMK",
      "iata": "SMK",
      "name": "St Michael Airport",
      "city": "St Michael",
      "state": "Alaska",
      "country": "US",
      "elevation": 98,
      "lat": 63.49010086,
      "lon": -162.1100006,
      "tz": "America\/Nome"
  },
  "PAML": {
      "icao": "PAML",
      "iata": "MLY",
      "name": "Manley Hot Springs Airport",
      "city": "Manley Hot Springs",
      "state": "Alaska",
      "country": "US",
      "elevation": 270,
      "lat": 64.9975967407,
      "lon": -150.643997192,
      "tz": "America\/Anchorage"
  },
  "PAMO": {
      "icao": "PAMO",
      "iata": "MOU",
      "name": "Mountain Village Airport",
      "city": "Mountain Village",
      "state": "Alaska",
      "country": "US",
      "elevation": 337,
      "lat": 62.0954017639,
      "lon": -163.6820068359,
      "tz": "America\/Nome"
  },
  "PAMR": {
      "icao": "PAMR",
      "iata": "MRI",
      "name": "Merrill Field",
      "city": "Anchorage",
      "state": "Iowa",
      "country": "US",
      "elevation": 137,
      "lat": 61.2135009766,
      "lon": -149.8439941406,
      "tz": "America\/Anchorage"
  },
  "PAMX": {
      "icao": "PAMX",
      "iata": "MXY",
      "name": "Mc Carthy Airport",
      "city": "Mccarthy",
      "state": "Alaska",
      "country": "US",
      "elevation": 1531,
      "lat": 61.4370994568,
      "lon": -142.904006958,
      "tz": "America\/Anchorage"
  },
  "PAMY": {
      "icao": "PAMY",
      "iata": "MYU",
      "name": "Mekoryuk Airport",
      "city": "Mekoryuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 48,
      "lat": 60.3713989258,
      "lon": -166.2709960938,
      "tz": "America\/Nome"
  },
  "PANA": {
      "icao": "PANA",
      "iata": "WNA",
      "name": "Napakiak Airport",
      "city": "Napakiak",
      "state": "Alaska",
      "country": "US",
      "elevation": 17,
      "lat": 60.6902999878,
      "lon": -161.9790039063,
      "tz": "America\/Anchorage"
  },
  "PANC": {
      "icao": "PANC",
      "iata": "ANC",
      "name": "Ted Stevens Anchorage International Airport",
      "city": "Anchorage",
      "state": "Alaska",
      "country": "US",
      "elevation": 152,
      "lat": 61.1744003296,
      "lon": -149.9960021973,
      "tz": "America\/Anchorage"
  },
  "PANI": {
      "icao": "PANI",
      "iata": "ANI",
      "name": "Aniak Airport",
      "city": "Aniak",
      "state": "Alaska",
      "country": "US",
      "elevation": 88,
      "lat": 61.5816001892,
      "lon": -159.5429992676,
      "tz": "America\/Anchorage"
  },
  "PANN": {
      "icao": "PANN",
      "iata": "ENN",
      "name": "Nenana Municipal Airport",
      "city": "Nenana",
      "state": "Alaska",
      "country": "US",
      "elevation": 362,
      "lat": 64.5473022461,
      "lon": -149.074005127,
      "tz": "America\/Anchorage"
  },
  "PANO": {
      "icao": "PANO",
      "iata": "NNL",
      "name": "Nondalton Airport",
      "city": "Nondalton",
      "state": "Alaska",
      "country": "US",
      "elevation": 314,
      "lat": 59.9802017212,
      "lon": -154.8390045166,
      "tz": "America\/Anchorage"
  },
  "PANT": {
      "icao": "PANT",
      "iata": "ANN",
      "name": "Annette Island Airport",
      "city": "Annette",
      "state": "Alaska",
      "country": "US",
      "elevation": 119,
      "lat": 55.0424003601,
      "lon": -131.5720062256,
      "tz": "America\/Metlakatla"
  },
  "PANU": {
      "icao": "PANU",
      "iata": "NUL",
      "name": "Nulato Airport",
      "city": "Nulato",
      "state": "Alaska",
      "country": "US",
      "elevation": 399,
      "lat": 64.7293014526,
      "lon": -158.074005127,
      "tz": "America\/Anchorage"
  },
  "PANV": {
      "icao": "PANV",
      "iata": "ANV",
      "name": "Anvik Airport",
      "city": "Anvik",
      "state": "Alaska",
      "country": "US",
      "elevation": 291,
      "lat": 62.64670181,
      "lon": -160.1909943,
      "tz": "America\/Anchorage"
  },
  "PANW": {
      "icao": "PANW",
      "iata": "KNW",
      "name": "New Stuyahok Airport",
      "city": "New Stuyahok",
      "state": "Alaska",
      "country": "US",
      "elevation": 364,
      "lat": 59.4499015808,
      "lon": -157.32800293,
      "tz": "America\/Anchorage"
  },
  "PAOB": {
      "icao": "PAOB",
      "iata": "OBU",
      "name": "Kobuk Airport",
      "city": "Kobuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 137,
      "lat": 66.9123001099,
      "lon": -156.897003174,
      "tz": "America\/Anchorage"
  },
  "PAOC": {
      "icao": "PAOC",
      "iata": "PCA",
      "name": "Portage Creek Airport",
      "city": "Portage Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 137,
      "lat": 58.90650177,
      "lon": -157.714004517,
      "tz": "America\/Anchorage"
  },
  "PAOH": {
      "icao": "PAOH",
      "iata": "HNH",
      "name": "Hoonah Airport",
      "city": "Hoonah",
      "state": "Alaska",
      "country": "US",
      "elevation": 19,
      "lat": 58.0960998535,
      "lon": -135.4100036621,
      "tz": "America\/Juneau"
  },
  "PAOM": {
      "icao": "PAOM",
      "iata": "OME",
      "name": "Nome Airport",
      "city": "Nome",
      "state": "Alaska",
      "country": "US",
      "elevation": 37,
      "lat": 64.5121994019,
      "lon": -165.4450073242,
      "tz": "America\/Nome"
  },
  "PAOO": {
      "icao": "PAOO",
      "iata": "OOK",
      "name": "Toksook Bay Airport",
      "city": "Toksook Bay",
      "state": "Alaska",
      "country": "US",
      "elevation": 59,
      "lat": 60.54140091,
      "lon": -165.0870056,
      "tz": "America\/Nome"
  },
  "PAOR": {
      "icao": "PAOR",
      "iata": "ORT",
      "name": "Northway Airport",
      "city": "Northway",
      "state": "Alaska",
      "country": "US",
      "elevation": 1715,
      "lat": 62.9612999,
      "lon": -141.9290009,
      "tz": "America\/Anchorage"
  },
  "PAOT": {
      "icao": "PAOT",
      "iata": "OTZ",
      "name": "Ralph Wien Memorial Airport",
      "city": "Kotzebue",
      "state": "Alaska",
      "country": "US",
      "elevation": 14,
      "lat": 66.88469696,
      "lon": -162.598999,
      "tz": "America\/Nome"
  },
  "PAOU": {
      "icao": "PAOU",
      "iata": "NLG",
      "name": "Nelson Lagoon Airport",
      "city": "Nelson Lagoon",
      "state": "Alaska",
      "country": "US",
      "elevation": 14,
      "lat": 56.0074996948,
      "lon": -161.1600036621,
      "tz": "America\/Anchorage"
  },
  "PAPB": {
      "icao": "PAPB",
      "iata": "PBV",
      "name": "St George Airport",
      "city": "St George",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 125,
      "lat": 56.5783004761,
      "lon": -169.6620025635,
      "tz": "America\/Nome"
  },
  "PAPC": {
      "icao": "PAPC",
      "iata": "KPC",
      "name": "Port Clarence Coast Guard Station",
      "city": "Port Clarence",
      "state": "Alaska",
      "country": "US",
      "elevation": 10,
      "lat": 65.2537002563,
      "lon": -166.85899353,
      "tz": "America\/Nome"
  },
  "PAPE": {
      "icao": "PAPE",
      "iata": "KPV",
      "name": "Perryville Airport",
      "city": "Perryville",
      "state": "Alaska",
      "country": "US",
      "elevation": 29,
      "lat": 55.90599823,
      "lon": -159.1629944,
      "tz": "America\/Anchorage"
  },
  "PAPG": {
      "icao": "PAPG",
      "iata": "PSG",
      "name": "Petersburg James A Johnson Airport",
      "city": "Petersburg",
      "state": "Alaska",
      "country": "US",
      "elevation": 111,
      "lat": 56.80170059,
      "lon": -132.9450073,
      "tz": "America\/Sitka"
  },
  "PAPH": {
      "icao": "PAPH",
      "iata": "PTH",
      "name": "Port Heiden Airport",
      "city": "Port Heiden",
      "state": "Alaska",
      "country": "US",
      "elevation": 95,
      "lat": 56.9590988159,
      "lon": -158.6329956055,
      "tz": "America\/Anchorage"
  },
  "PAPK": {
      "icao": "PAPK",
      "iata": "PKA",
      "name": "Napaskiak Airport",
      "city": "Napaskiak",
      "state": "Alaska",
      "country": "US",
      "elevation": 24,
      "lat": 60.70289993,
      "lon": -161.7779999,
      "tz": "America\/Anchorage"
  },
  "PAPM": {
      "icao": "PAPM",
      "iata": "PTU",
      "name": "Platinum Airport",
      "city": "Platinum",
      "state": "Alaska",
      "country": "US",
      "elevation": 15,
      "lat": 59.0113983154,
      "lon": -161.8200073242,
      "tz": "America\/Anchorage"
  },
  "PAPN": {
      "icao": "PAPN",
      "iata": "PIP",
      "name": "Pilot Point Airport",
      "city": "Pilot Point",
      "state": "Alaska",
      "country": "US",
      "elevation": 57,
      "lat": 57.5803985596,
      "lon": -157.572006226,
      "tz": "America\/Anchorage"
  },
  "PAPO": {
      "icao": "PAPO",
      "iata": "PHO",
      "name": "Point Hope Airport",
      "city": "Point Hope",
      "state": "Alaska",
      "country": "US",
      "elevation": 12,
      "lat": 68.3488006592,
      "lon": -166.7989959717,
      "tz": "America\/Nome"
  },
  "PAPR": {
      "icao": "PAPR",
      "iata": "PPC",
      "name": "Prospect Creek Airport",
      "city": "Prospect Creek",
      "state": "Alaska",
      "country": "US",
      "elevation": 1095,
      "lat": 66.8141021729,
      "lon": -150.6439971924,
      "tz": "America\/Anchorage"
  },
  "PAQH": {
      "icao": "PAQH",
      "iata": "KWN",
      "name": "Quinhagak Airport",
      "city": "Quinhagak",
      "state": "Alaska",
      "country": "US",
      "elevation": 42,
      "lat": 59.75510025,
      "lon": -161.8450012,
      "tz": "America\/Anchorage"
  },
  "PAQT": {
      "icao": "PAQT",
      "iata": "NUI",
      "name": "Nuiqsut Airport",
      "city": "Nuiqsut",
      "state": "Alaska",
      "country": "US",
      "elevation": 38,
      "lat": 70.2099990845,
      "lon": -151.005996704,
      "tz": "America\/Anchorage"
  },
  "PARC": {
      "icao": "PARC",
      "iata": "ARC",
      "name": "Arctic Village Airport",
      "city": "Arctic Village",
      "state": "Alaska",
      "country": "US",
      "elevation": 2092,
      "lat": 68.1147003174,
      "lon": -145.578994751,
      "tz": "America\/Anchorage"
  },
  "PARS": {
      "icao": "PARS",
      "iata": "RSH",
      "name": "Russian Mission Airport",
      "city": "Russian Mission",
      "state": "Alaska",
      "country": "US",
      "elevation": 51,
      "lat": 61.7788848877,
      "lon": -161.3194580078,
      "tz": "America\/Anchorage"
  },
  "PARY": {
      "icao": "PARY",
      "iata": "RBY",
      "name": "Ruby Airport",
      "city": "Ruby",
      "state": "Alaska",
      "country": "US",
      "elevation": 658,
      "lat": 64.72720337,
      "lon": -155.4700012,
      "tz": "America\/Anchorage"
  },
  "PASA": {
      "icao": "PASA",
      "iata": "SVA",
      "name": "Savoonga Airport",
      "city": "Savoonga",
      "state": "Alaska",
      "country": "US",
      "elevation": 53,
      "lat": 63.6864013672,
      "lon": -170.4929962158,
      "tz": "America\/Nome"
  },
  "PASC": {
      "icao": "PASC",
      "iata": "SCC",
      "name": "Deadhorse Airport",
      "city": "Deadhorse",
      "state": "Alaska",
      "country": "US",
      "elevation": 65,
      "lat": 70.19470215,
      "lon": -148.4649963,
      "tz": "America\/Anchorage"
  },
  "PASD": {
      "icao": "PASD",
      "iata": "SDP",
      "name": "Sand Point Airport",
      "city": "Sand Point",
      "state": "Alaska",
      "country": "US",
      "elevation": 21,
      "lat": 55.3149986267,
      "lon": -160.5229949951,
      "tz": "America\/Anchorage"
  },
  "PASH": {
      "icao": "PASH",
      "iata": "SHH",
      "name": "Shishmaref Airport",
      "city": "Shishmaref",
      "state": "Alaska",
      "country": "US",
      "elevation": 12,
      "lat": 66.2496032715,
      "lon": -166.0890045166,
      "tz": "America\/Nome"
  },
  "PASI": {
      "icao": "PASI",
      "iata": "SIT",
      "name": "Sitka Rocky Gutierrez Airport",
      "city": "Sitka",
      "state": "Alaska",
      "country": "US",
      "elevation": 21,
      "lat": 57.0471000671,
      "lon": -135.3619995117,
      "tz": "America\/Sitka"
  },
  "PASK": {
      "icao": "PASK",
      "iata": "WLK",
      "name": "Selawik Airport",
      "city": "Selawik",
      "state": "Alaska",
      "country": "US",
      "elevation": 17,
      "lat": 66.60009766,
      "lon": -159.9859924,
      "tz": "America\/Anchorage"
  },
  "PASL": {
      "icao": "PASL",
      "iata": "SQL",
      "name": "Sleetmute Airport",
      "city": "Sleetmute",
      "state": "Alaska",
      "country": "US",
      "elevation": 190,
      "lat": 61.7005004883,
      "lon": -157.166000366,
      "tz": "America\/Anchorage"
  },
  "PASM": {
      "icao": "PASM",
      "iata": "KSM",
      "name": "St Mary's Airport",
      "city": "St Mary's",
      "state": "Alaska",
      "country": "US",
      "elevation": 312,
      "lat": 62.0605011,
      "lon": -163.302002,
      "tz": "America\/Nome"
  },
  "PASN": {
      "icao": "PASN",
      "iata": "SNP",
      "name": "St Paul Island Airport",
      "city": "St Paul Island",
      "state": "Alaska",
      "country": "US",
      "elevation": 63,
      "lat": 57.167301178,
      "lon": -170.2200012207,
      "tz": "America\/Nome"
  },
  "PASO": {
      "icao": "PASO",
      "iata": "SOV",
      "name": "Seldovia Airport",
      "city": "Seldovia",
      "state": "Alaska",
      "country": "US",
      "elevation": 29,
      "lat": 59.442401886,
      "lon": -151.703994751,
      "tz": "America\/Anchorage"
  },
  "PASP": {
      "icao": "PASP",
      "iata": "SMU",
      "name": "Sheep Mountain Airport",
      "city": "Sheep Mountain",
      "state": "Alaska",
      "country": "US",
      "elevation": 2750,
      "lat": 61.8120002747,
      "lon": -147.507003784,
      "tz": "America\/Anchorage"
  },
  "PAST": {
      "icao": "PAST",
      "iata": "UMM",
      "name": "Summit Airport",
      "city": "Summit",
      "state": "Alaska",
      "country": "US",
      "elevation": 2409,
      "lat": 63.3315010071,
      "lon": -149.126998901,
      "tz": "America\/Anchorage"
  },
  "PASV": {
      "icao": "PASV",
      "iata": "SVW",
      "name": "Sparrevohn LRRS Airport",
      "city": "Sparrevohn",
      "state": "Alaska",
      "country": "US",
      "elevation": 1585,
      "lat": 61.09740067,
      "lon": -155.5740051,
      "tz": "America\/Anchorage"
  },
  "PASW": {
      "icao": "PASW",
      "iata": "SKW",
      "name": "Skwentna Airport",
      "city": "Skwentna",
      "state": "Alaska",
      "country": "US",
      "elevation": 148,
      "lat": 61.9653015137,
      "lon": -151.1909942627,
      "tz": "America\/Anchorage"
  },
  "PASX": {
      "icao": "PASX",
      "iata": "SXQ",
      "name": "Soldotna Airport",
      "city": "Soldotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 113,
      "lat": 60.4757003784,
      "lon": -151.033996582,
      "tz": "America\/Anchorage"
  },
  "PASY": {
      "icao": "PASY",
      "iata": "SYA",
      "name": "Eareckson Air Station",
      "city": "Shemya",
      "state": "Alaska",
      "country": "US",
      "elevation": 95,
      "lat": 52.71229935,
      "lon": 174.1139984,
      "tz": "America\/Adak"
  },
  "PATA": {
      "icao": "PATA",
      "iata": "TAL",
      "name": "Ralph M Calhoun Memorial Airport",
      "city": "Tanana",
      "state": "Alaska",
      "country": "US",
      "elevation": 236,
      "lat": 65.1744003296,
      "lon": -152.10899353,
      "tz": "America\/Anchorage"
  },
  "PATC": {
      "icao": "PATC",
      "iata": "TNC",
      "name": "Tin City Long Range Radar Station Airport",
      "city": "Tin City",
      "state": "Alaska",
      "country": "US",
      "elevation": 271,
      "lat": 65.56310272,
      "lon": -167.9219971,
      "tz": "America\/Nome"
  },
  "PATE": {
      "icao": "PATE",
      "iata": "TLA",
      "name": "Teller Airport",
      "city": "Teller",
      "state": "Alaska",
      "country": "US",
      "elevation": 294,
      "lat": 65.2404022217,
      "lon": -166.3390045166,
      "tz": "America\/Nome"
  },
  "PATG": {
      "icao": "PATG",
      "iata": "TOG",
      "name": "Togiak Airport",
      "city": "Togiak Village",
      "state": "Alaska",
      "country": "US",
      "elevation": 21,
      "lat": 59.0527992249,
      "lon": -160.3970031738,
      "tz": "America\/Anchorage"
  },
  "PATJ": {
      "icao": "PATJ",
      "iata": "TKJ",
      "name": "Tok Airport",
      "city": "Tok",
      "state": "Alaska",
      "country": "US",
      "elevation": 1670,
      "lat": 63.303333,
      "lon": -143.001111,
      "tz": "America\/Anchorage"
  },
  "PATK": {
      "icao": "PATK",
      "iata": "TKA",
      "name": "Talkeetna Airport",
      "city": "Talkeetna",
      "state": "Alaska",
      "country": "US",
      "elevation": 358,
      "lat": 62.3204994202,
      "lon": -150.0939941406,
      "tz": "America\/Anchorage"
  },
  "PATL": {
      "icao": "PATL",
      "iata": "TLJ",
      "name": "Tatalina LRRS Airport",
      "city": "Takotna",
      "state": "Alaska",
      "country": "US",
      "elevation": 964,
      "lat": 62.8944015503,
      "lon": -155.977005005,
      "tz": "America\/Anchorage"
  },
  "PATQ": {
      "icao": "PATQ",
      "iata": "ATK",
      "name": "Atqasuk Edward Burnell Sr Memorial Airport",
      "city": "Atqasuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 96,
      "lat": 70.467300415,
      "lon": -157.4360046387,
      "tz": "America\/Anchorage"
  },
  "PATW": {
      "icao": "PATW",
      "iata": "",
      "name": "Cantwell Airport",
      "city": "Cantwell",
      "state": "Alaska",
      "country": "US",
      "elevation": 2190,
      "lat": 63.3912010193,
      "lon": -148.9559936523,
      "tz": "America\/Anchorage"
  },
  "PAUK": {
      "icao": "PAUK",
      "iata": "AUK",
      "name": "Alakanuk Airport",
      "city": "Alakanuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 10,
      "lat": 62.6800422668,
      "lon": -164.659927368,
      "tz": "America\/Nome"
  },
  "PAUM": {
      "icao": "PAUM",
      "iata": "UMT",
      "name": "Umiat Airport",
      "city": "Umiat",
      "state": "Alaska",
      "country": "US",
      "elevation": 267,
      "lat": 69.37110138,
      "lon": -152.1360016,
      "tz": "America\/Anchorage"
  },
  "PAUN": {
      "icao": "PAUN",
      "iata": "UNK",
      "name": "Unalakleet Airport",
      "city": "Unalakleet",
      "state": "Alaska",
      "country": "US",
      "elevation": 27,
      "lat": 63.88840103,
      "lon": -160.798996,
      "tz": "America\/Anchorage"
  },
  "PAUO": {
      "icao": "PAUO",
      "iata": "WOW",
      "name": "Willow Airport",
      "city": "Willow",
      "state": "Alaska",
      "country": "US",
      "elevation": 221,
      "lat": 61.7541999817,
      "lon": -150.0520019531,
      "tz": "America\/Anchorage"
  },
  "PAUT": {
      "icao": "PAUT",
      "iata": "",
      "name": "Akun Airport",
      "city": "Akutan",
      "state": "Alaska",
      "country": "US",
      "elevation": 0,
      "lat": 54.1449925194,
      "lon": -165.607566833,
      "tz": "America\/Nome"
  },
  "PAVA": {
      "icao": "PAVA",
      "iata": "VAK",
      "name": "Chevak Airport",
      "city": "Chevak",
      "state": "Alaska",
      "country": "US",
      "elevation": 75,
      "lat": 61.5409,
      "lon": -165.6005,
      "tz": "America\/Nome"
  },
  "PAVC": {
      "icao": "PAVC",
      "iata": "KVC",
      "name": "King Cove Airport",
      "city": "King Cove",
      "state": "Alaska",
      "country": "US",
      "elevation": 155,
      "lat": 55.1162986755,
      "lon": -162.2660064697,
      "tz": "America\/Nome"
  },
  "PAVD": {
      "icao": "PAVD",
      "iata": "VDZ",
      "name": "Valdez Pioneer Field",
      "city": "Valdez",
      "state": "Alaska",
      "country": "US",
      "elevation": 121,
      "lat": 61.13389969,
      "lon": -146.2480011,
      "tz": "America\/Anchorage"
  },
  "PAVE": {
      "icao": "PAVE",
      "iata": "VEE",
      "name": "Venetie Airport",
      "city": "Venetie",
      "state": "Alaska",
      "country": "US",
      "elevation": 574,
      "lat": 67.0086975098,
      "lon": -146.365997314,
      "tz": "America\/Anchorage"
  },
  "PAVL": {
      "icao": "PAVL",
      "iata": "KVL",
      "name": "Kivalina Airport",
      "city": "Kivalina",
      "state": "Alaska",
      "country": "US",
      "elevation": 13,
      "lat": 67.7361984253,
      "lon": -164.56300354,
      "tz": "America\/Nome"
  },
  "PAWB": {
      "icao": "PAWB",
      "iata": "WBQ",
      "name": "Beaver Airport",
      "city": "Beaver",
      "state": "Alaska",
      "country": "US",
      "elevation": 359,
      "lat": 66.362197876,
      "lon": -147.406997681,
      "tz": "America\/Anchorage"
  },
  "PAWD": {
      "icao": "PAWD",
      "iata": "SWD",
      "name": "Seward Airport",
      "city": "Seward",
      "state": "Alaska",
      "country": "US",
      "elevation": 22,
      "lat": 60.1268997192,
      "lon": -149.4190063477,
      "tz": "America\/Anchorage"
  },
  "PAWG": {
      "icao": "PAWG",
      "iata": "WRG",
      "name": "Wrangell Airport",
      "city": "Wrangell",
      "state": "Alaska",
      "country": "US",
      "elevation": 49,
      "lat": 56.48429871,
      "lon": -132.3699951,
      "tz": "America\/Sitka"
  },
  "PAWI": {
      "icao": "PAWI",
      "iata": "AIN",
      "name": "Wainwright Airport",
      "city": "Wainwright",
      "state": "Alaska",
      "country": "US",
      "elevation": 41,
      "lat": 70.6380004883,
      "lon": -159.994995117,
      "tz": "America\/Anchorage"
  },
  "PAWM": {
      "icao": "PAWM",
      "iata": "WMO",
      "name": "White Mountain Airport",
      "city": "White Mountain",
      "state": "Alaska",
      "country": "US",
      "elevation": 267,
      "lat": 64.689201355,
      "lon": -163.4129943848,
      "tz": "America\/Nome"
  },
  "PAWN": {
      "icao": "PAWN",
      "iata": "WTK",
      "name": "Noatak Airport",
      "city": "Noatak",
      "state": "Alaska",
      "country": "US",
      "elevation": 88,
      "lat": 67.5661010742,
      "lon": -162.9750061035,
      "tz": "America\/Nome"
  },
  "PAWR": {
      "icao": "PAWR",
      "iata": "",
      "name": "Whittier Airport",
      "city": "Whittier",
      "state": "Alaska",
      "country": "US",
      "elevation": 30,
      "lat": 60.7771987915,
      "lon": -148.7220001221,
      "tz": "America\/Anchorage"
  },
  "PAWS": {
      "icao": "PAWS",
      "iata": "IYS",
      "name": "Wasilla Airport",
      "city": "Wasilla",
      "state": "Alaska",
      "country": "US",
      "elevation": 354,
      "lat": 61.5717010498,
      "lon": -149.5399932861,
      "tz": "America\/Anchorage"
  },
  "PAWT": {
      "icao": "PAWT",
      "iata": "",
      "name": "Wainwright Air Station",
      "city": "Wainwright",
      "state": "Alaska",
      "country": "US",
      "elevation": 35,
      "lat": 70.61340332,
      "lon": -159.8600006,
      "tz": "America\/Anchorage"
  },
  "PAXK": {
      "icao": "PAXK",
      "iata": "",
      "name": "Paxson Airport",
      "city": "Paxson",
      "state": "Alaska",
      "country": "US",
      "elevation": 2653,
      "lat": 63.0246009827,
      "lon": -145.50100708,
      "tz": "America\/Anchorage"
  },
  "PAYA": {
      "icao": "PAYA",
      "iata": "YAK",
      "name": "Yakutat Airport",
      "city": "Yakutat",
      "state": "Alaska",
      "country": "US",
      "elevation": 33,
      "lat": 59.5032997131,
      "lon": -139.660003662,
      "tz": "America\/Yakutat"
  },
  "PAZK": {
      "icao": "PAZK",
      "iata": "",
      "name": "Skelton Airport",
      "city": "Eureka",
      "state": "Alaska",
      "country": "US",
      "elevation": 3289,
      "lat": 61.9370002747,
      "lon": -147.169006348,
      "tz": "America\/Anchorage"
  },
  "PCIS": {
      "icao": "PCIS",
      "iata": "CIS",
      "name": "Canton Airport",
      "city": "Abariringa",
      "state": "Phoenix-Islands",
      "country": "KI",
      "elevation": 9,
      "lat": -2.7681200504,
      "lon": -171.7100067139,
      "tz": "Pacific\/Enderbury"
  },
  "PFAK": {
      "icao": "PFAK",
      "iata": "AKI",
      "name": "Akiak Airport",
      "city": "Akiak",
      "state": "Alaska",
      "country": "US",
      "elevation": 30,
      "lat": 60.9029006958,
      "lon": -161.231002808,
      "tz": "America\/Anchorage"
  },
  "PFAL": {
      "icao": "PFAL",
      "iata": "AET",
      "name": "Allakaket Airport",
      "city": "Allakaket",
      "state": "Alaska",
      "country": "US",
      "elevation": 441,
      "lat": 66.5518035889,
      "lon": -152.621994019,
      "tz": "America\/Anchorage"
  },
  "PFCB": {
      "icao": "PFCB",
      "iata": "NCN",
      "name": "Chenega Bay Airport",
      "city": "Chenega",
      "state": "Alaska",
      "country": "US",
      "elevation": 72,
      "lat": 60.0773010254,
      "lon": -147.992004395,
      "tz": "America\/Anchorage"
  },
  "PFCL": {
      "icao": "PFCL",
      "iata": "CLP",
      "name": "Clarks Point Airport",
      "city": "Clarks Point",
      "state": "Alaska",
      "country": "US",
      "elevation": 80,
      "lat": 58.83369827,
      "lon": -158.529007,
      "tz": "America\/Anchorage"
  },
  "PFEL": {
      "icao": "PFEL",
      "iata": "ELI",
      "name": "Elim Airport",
      "city": "Elim",
      "state": "Alaska",
      "country": "US",
      "elevation": 162,
      "lat": 64.61470032,
      "lon": -162.2720032,
      "tz": "America\/Nome"
  },
  "PFKA": {
      "icao": "PFKA",
      "iata": "KUK",
      "name": "Kasigluk Airport",
      "city": "Kasigluk",
      "state": "Alaska",
      "country": "US",
      "elevation": 48,
      "lat": 60.87440109,
      "lon": -162.5240021,
      "tz": "America\/Nome"
  },
  "PFKK": {
      "icao": "PFKK",
      "iata": "KNK",
      "name": "Kokhanok Airport",
      "city": "Kokhanok",
      "state": "Alaska",
      "country": "US",
      "elevation": 115,
      "lat": 59.4332008362,
      "lon": -154.804000854,
      "tz": "America\/Anchorage"
  },
  "PFKO": {
      "icao": "PFKO",
      "iata": "KOT",
      "name": "Kotlik Airport",
      "city": "Kotlik",
      "state": "Alaska",
      "country": "US",
      "elevation": 15,
      "lat": 63.0306015015,
      "lon": -163.533004761,
      "tz": "America\/Nome"
  },
  "PFKT": {
      "icao": "PFKT",
      "iata": "KTS",
      "name": "Brevig Mission Airport",
      "city": "Brevig Mission",
      "state": "Alaska",
      "country": "US",
      "elevation": 38,
      "lat": 65.3312988281,
      "lon": -166.466003418,
      "tz": "America\/Nome"
  },
  "PFKU": {
      "icao": "PFKU",
      "iata": "KYU",
      "name": "Koyukuk Airport",
      "city": "Koyukuk",
      "state": "Alaska",
      "country": "US",
      "elevation": 149,
      "lat": 64.8760986328,
      "lon": -157.727005005,
      "tz": "America\/Anchorage"
  },
  "PFKW": {
      "icao": "PFKW",
      "iata": "KWT",
      "name": "Kwethluk Airport",
      "city": "Kwethluk",
      "state": "Alaska",
      "country": "US",
      "elevation": 25,
      "lat": 60.7902984619,
      "lon": -161.444000244,
      "tz": "America\/Anchorage"
  },
  "PFNO": {
      "icao": "PFNO",
      "iata": "ORV",
      "name": "Robert (Bob) Curtis Memorial Airport",
      "city": "Noorvik",
      "state": "Alaska",
      "country": "US",
      "elevation": 55,
      "lat": 66.81790161,
      "lon": -161.0189972,
      "tz": "America\/Anchorage"
  },
  "PFSH": {
      "icao": "PFSH",
      "iata": "SKK",
      "name": "Shaktoolik Airport",
      "city": "Shaktoolik",
      "state": "Alaska",
      "country": "US",
      "elevation": 24,
      "lat": 64.37110138,
      "lon": -161.223999,
      "tz": "America\/Anchorage"
  },
  "PFTO": {
      "icao": "PFTO",
      "iata": "TKJ",
      "name": "Tok Junction Airport",
      "city": "Tok",
      "state": "Alaska",
      "country": "US",
      "elevation": 1639,
      "lat": 63.32949829,
      "lon": -142.9539948,
      "tz": "America\/Anchorage"
  },
  "PFWS": {
      "icao": "PFWS",
      "iata": "WSN",
      "name": "South Naknek Nr 2 Airport",
      "city": "South Naknek",
      "state": "Alaska",
      "country": "US",
      "elevation": 162,
      "lat": 58.7033996582,
      "lon": -157.007995605,
      "tz": "America\/Anchorage"
  },
  "PFYU": {
      "icao": "PFYU",
      "iata": "FYU",
      "name": "Fort Yukon Airport",
      "city": "Fort Yukon",
      "state": "Alaska",
      "country": "US",
      "elevation": 433,
      "lat": 66.5715026855,
      "lon": -145.25,
      "tz": "America\/Anchorage"
  },
  "PGRO": {
      "icao": "PGRO",
      "iata": "ROP",
      "name": "Rota International Airport",
      "city": "Rota Island",
      "state": "Rota",
      "country": "MP",
      "elevation": 607,
      "lat": 14.1743001938,
      "lon": 145.2429962158,
      "tz": "Pacific\/Saipan"
  },
  "PGSN": {
      "icao": "PGSN",
      "iata": "SPN",
      "name": "Francisco C. Ada Saipan International Airport",
      "city": "Saipan Island",
      "state": "Saipan",
      "country": "MP",
      "elevation": 215,
      "lat": 15.1190004349,
      "lon": 145.7290039063,
      "tz": "Pacific\/Saipan"
  },
  "PGUA": {
      "icao": "PGUA",
      "iata": "UAM",
      "name": "Andersen Air Force Base",
      "city": "Andersen",
      "state": "Yigo",
      "country": "GU",
      "elevation": 627,
      "lat": 13.5839996338,
      "lon": 144.9299926758,
      "tz": "Pacific\/Guam"
  },
  "PGUM": {
      "icao": "PGUM",
      "iata": "GUM",
      "name": "Antonio B. Won Pat International Airport",
      "city": "Hagatna",
      "state": "Barrigada",
      "country": "GU",
      "elevation": 298,
      "lat": 13.4834003448,
      "lon": 144.796005249,
      "tz": "Pacific\/Guam"
  },
  "PGWT": {
      "icao": "PGWT",
      "iata": "TIQ",
      "name": "Tinian International Airport",
      "city": "Tinian Island",
      "state": "Tinian",
      "country": "MP",
      "elevation": 271,
      "lat": 14.9991998672,
      "lon": 145.6190032959,
      "tz": "Pacific\/Saipan"
  },
  "PHBK": {
      "icao": "PHBK",
      "iata": "BKH",
      "name": "Barking Sands Airport",
      "city": "Kekaha",
      "state": "Hawaii",
      "country": "US",
      "elevation": 23,
      "lat": 22.0228004456,
      "lon": -159.785003662,
      "tz": "Pacific\/Honolulu"
  },
  "PHDH": {
      "icao": "PHDH",
      "iata": "HDH",
      "name": "Dillingham Airfield",
      "city": "Mokuleia",
      "state": "Hawaii",
      "country": "US",
      "elevation": 14,
      "lat": 21.5795001984,
      "lon": -158.197006226,
      "tz": "Pacific\/Honolulu"
  },
  "PHHF": {
      "icao": "PHHF",
      "iata": "",
      "name": "French Frigate Shoals Airport",
      "city": "Tern Island",
      "state": "Hawaii",
      "country": "US",
      "elevation": 6,
      "lat": 23.8694000244,
      "lon": -166.2850036621,
      "tz": "Pacific\/Honolulu"
  },
  "PHHI": {
      "icao": "PHHI",
      "iata": "HHI",
      "name": "Wheeler Army Airfield",
      "city": "Wahiawa",
      "state": "Hawaii",
      "country": "US",
      "elevation": 837,
      "lat": 21.48349953,
      "lon": -158.0399933,
      "tz": "Pacific\/Honolulu"
  },
  "PHHN": {
      "icao": "PHHN",
      "iata": "HNM",
      "name": "Hana Airport",
      "city": "Hana",
      "state": "Hawaii",
      "country": "US",
      "elevation": 78,
      "lat": 20.7956008911,
      "lon": -156.0140075684,
      "tz": "Pacific\/Honolulu"
  },
  "PHJH": {
      "icao": "PHJH",
      "iata": "JHM",
      "name": "Kapalua Airport",
      "city": "Lahaina",
      "state": "Hawaii",
      "country": "US",
      "elevation": 256,
      "lat": 20.9629001617,
      "lon": -156.6730041504,
      "tz": "Pacific\/Honolulu"
  },
  "PHJR": {
      "icao": "PHJR",
      "iata": "JRF",
      "name": "Kalaeloa (John Rodgers Field) Airport",
      "city": "Kapolei",
      "state": "Hawaii",
      "country": "US",
      "elevation": 30,
      "lat": 21.30739975,
      "lon": -158.0700073,
      "tz": "Pacific\/Honolulu"
  },
  "PHKO": {
      "icao": "PHKO",
      "iata": "KOA",
      "name": "Kona International At Keahole Airport",
      "city": "Kailua\/Kona",
      "state": "Hawaii",
      "country": "US",
      "elevation": 47,
      "lat": 19.7388000488,
      "lon": -156.046005249,
      "tz": "Pacific\/Honolulu"
  },
  "PHLI": {
      "icao": "PHLI",
      "iata": "LIH",
      "name": "Lihue Airport",
      "city": "Lihue",
      "state": "Hawaii",
      "country": "US",
      "elevation": 153,
      "lat": 21.9759998322,
      "lon": -159.3390045166,
      "tz": "Pacific\/Honolulu"
  },
  "PHLU": {
      "icao": "PHLU",
      "iata": "LUP",
      "name": "Kalaupapa Airport",
      "city": "Kalaupapa",
      "state": "Hawaii",
      "country": "US",
      "elevation": 24,
      "lat": 21.21100044,
      "lon": -156.973999,
      "tz": "Pacific\/Honolulu"
  },
  "PHMK": {
      "icao": "PHMK",
      "iata": "MKK",
      "name": "Molokai Airport",
      "city": "Kaunakakai",
      "state": "Hawaii",
      "country": "US",
      "elevation": 454,
      "lat": 21.1529006958,
      "lon": -157.095993042,
      "tz": "Pacific\/Honolulu"
  },
  "PHMU": {
      "icao": "PHMU",
      "iata": "MUE",
      "name": "Waimea Kohala Airport",
      "city": "Kamuela",
      "state": "Hawaii",
      "country": "US",
      "elevation": 2671,
      "lat": 20.0013008118,
      "lon": -155.6679992676,
      "tz": "Pacific\/Honolulu"
  },
  "PHNG": {
      "icao": "PHNG",
      "iata": "NGF",
      "name": "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
      "city": "Kaneohe",
      "state": "Hawaii",
      "country": "US",
      "elevation": 24,
      "lat": 21.4505004883,
      "lon": -157.768005371,
      "tz": "Pacific\/Honolulu"
  },
  "PHNL": {
      "icao": "PHNL",
      "iata": "HNL",
      "name": "Honolulu International Airport",
      "city": "Honolulu",
      "state": "Hawaii",
      "country": "US",
      "elevation": 13,
      "lat": 21.3187007904,
      "lon": -157.9219970703,
      "tz": "Pacific\/Honolulu"
  },
  "PHNP": {
      "icao": "PHNP",
      "iata": "",
      "name": "Ford Island Naval Auxiliary Landing Field",
      "city": "Honolulu",
      "state": "Hawaii",
      "country": "US",
      "elevation": 18,
      "lat": 21.364900589,
      "lon": -157.9600067139,
      "tz": "Pacific\/Honolulu"
  },
  "PHNY": {
      "icao": "PHNY",
      "iata": "LNY",
      "name": "Lanai Airport",
      "city": "Lanai City",
      "state": "Hawaii",
      "country": "US",
      "elevation": 1308,
      "lat": 20.7856006622,
      "lon": -156.9510040283,
      "tz": "Pacific\/Honolulu"
  },
  "PHOG": {
      "icao": "PHOG",
      "iata": "OGG",
      "name": "Kahului Airport",
      "city": "Kahului",
      "state": "Hawaii",
      "country": "US",
      "elevation": 54,
      "lat": 20.8985996246,
      "lon": -156.4299926758,
      "tz": "Pacific\/Honolulu"
  },
  "PHPA": {
      "icao": "PHPA",
      "iata": "PAK",
      "name": "Port Allen Airport",
      "city": "Hanapepe",
      "state": "Hawaii",
      "country": "US",
      "elevation": 24,
      "lat": 21.896900177,
      "lon": -159.602996826,
      "tz": "Pacific\/Honolulu"
  },
  "PHSF": {
      "icao": "PHSF",
      "iata": "BSF",
      "name": "Bradshaw Army Airfield",
      "city": "Camp Pohakuloa",
      "state": "Hawaii",
      "country": "US",
      "elevation": 6190,
      "lat": 19.760099411,
      "lon": -155.554000854,
      "tz": "Pacific\/Honolulu"
  },
  "PHTO": {
      "icao": "PHTO",
      "iata": "ITO",
      "name": "Hilo International Airport",
      "city": "Hilo",
      "state": "Hawaii",
      "country": "US",
      "elevation": 38,
      "lat": 19.7213993073,
      "lon": -155.0480041504,
      "tz": "Pacific\/Honolulu"
  },
  "PHUP": {
      "icao": "PHUP",
      "iata": "UPP",
      "name": "Upolu Airport",
      "city": "Hawi",
      "state": "Hawaii",
      "country": "US",
      "elevation": 96,
      "lat": 20.2653007507,
      "lon": -155.8600006104,
      "tz": "Pacific\/Honolulu"
  },
  "PKMA": {
      "icao": "PKMA",
      "iata": "ENT",
      "name": "Eniwetok Airport",
      "city": "Eniwetok Atoll",
      "state": "Enewetak-Atoll",
      "country": "MH",
      "elevation": 13,
      "lat": 11.3407001495,
      "lon": 162.3280029297,
      "tz": "Pacific\/Majuro"
  },
  "PKMJ": {
      "icao": "PKMJ",
      "iata": "MAJ",
      "name": "Marshall Islands International Airport",
      "city": "Majuro Atoll",
      "state": "Majuro-Atoll",
      "country": "MH",
      "elevation": 6,
      "lat": 7.0647602081,
      "lon": 171.2720031738,
      "tz": "Pacific\/Majuro"
  },
  "PKRO": {
      "icao": "PKRO",
      "iata": "",
      "name": "Dyess Army Air Field",
      "city": "Roi-Namur",
      "state": "Kwajalein-Atoll",
      "country": "MH",
      "elevation": 9,
      "lat": 9.3968896866,
      "lon": 167.470993042,
      "tz": "Pacific\/Kwajalein"
  },
  "PKSA": {
      "icao": "PKSA",
      "iata": "KIA",
      "name": "Kaieteur International Airport",
      "city": "Kaieteur Falls",
      "state": "Potaro-Siparuni",
      "country": "GY",
      "elevation": 0,
      "lat": 5.1727547646,
      "lon": -59.491481781,
      "tz": "America\/Guyana"
  },
  "PKWA": {
      "icao": "PKWA",
      "iata": "KWA",
      "name": "Bucholz Army Air Field",
      "city": "Kwajalein",
      "state": "Kwajalein-Atoll",
      "country": "MH",
      "elevation": 9,
      "lat": 8.72012043,
      "lon": 167.7319946289,
      "tz": "Pacific\/Kwajalein"
  },
  "PLCH": {
      "icao": "PLCH",
      "iata": "CXI",
      "name": "Cassidy International Airport",
      "city": "Banana",
      "state": "Line-Islands",
      "country": "KI",
      "elevation": 5,
      "lat": 1.9861600399,
      "lon": -157.3500061035,
      "tz": "Pacific\/Kiritimati"
  },
  "PLFA": {
      "icao": "PLFA",
      "iata": "TNV",
      "name": "Tabuaeran Island Airport",
      "city": "Tabuaeran Island",
      "state": "Line-Islands",
      "country": "KI",
      "elevation": 0,
      "lat": 3.8994400501,
      "lon": -159.3890075684,
      "tz": "Pacific\/Kiritimati"
  },
  "PLPA": {
      "icao": "PLPA",
      "iata": "",
      "name": "Palmyra (Cooper) Airport",
      "city": "Palmyra Island Atoll",
      "state": "Palmyra-Atoll",
      "country": "UM",
      "elevation": 5,
      "lat": 5.8887481689,
      "lon": -162.0778656006,
      "tz": "Etc\/GMT+11"
  },
  "PLWN": {
      "icao": "PLWN",
      "iata": "",
      "name": "Washington Island Airstrip",
      "city": "Teraina",
      "state": "Line-Islands",
      "country": "KI",
      "elevation": 30,
      "lat": 4.698359,
      "lon": -160.394376,
      "tz": "Pacific\/Kiritimati"
  },
  "PMDY": {
      "icao": "PMDY",
      "iata": "MDY",
      "name": "Henderson Field",
      "city": "Sand Island",
      "state": "Midway-Islands",
      "country": "UM",
      "elevation": 13,
      "lat": 28.2017002106,
      "lon": -177.3809967041,
      "tz": "Pacific\/Midway"
  },
  "PN00": {
      "icao": "PN00",
      "iata": "",
      "name": "Crosswinds Airfield",
      "city": "Sassamansville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 300,
      "lat": 40.3432006836,
      "lon": -75.5727005005,
      "tz": "America\/New_York"
  },
  "PN01": {
      "icao": "PN01",
      "iata": "",
      "name": "Cedar Run Airport",
      "city": "Dorseyville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1180,
      "lat": 40.5881004333,
      "lon": -79.9058990479,
      "tz": "America\/New_York"
  },
  "PN02": {
      "icao": "PN02",
      "iata": "",
      "name": "Offutt Acres Airport",
      "city": "East Brady",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 865,
      "lat": 40.9847984314,
      "lon": -79.5663986206,
      "tz": "America\/New_York"
  },
  "PN04": {
      "icao": "PN04",
      "iata": "",
      "name": "Strittmatter Airport",
      "city": "Ebensburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2080,
      "lat": 40.547000885,
      "lon": -78.6947021484,
      "tz": "America\/New_York"
  },
  "PN06": {
      "icao": "PN06",
      "iata": "",
      "name": "Carlson Airport",
      "city": "Edinboro",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1490,
      "lat": 41.9042015076,
      "lon": -80.1026000977,
      "tz": "America\/New_York"
  },
  "PN08": {
      "icao": "PN08",
      "iata": "",
      "name": "Davis Airport",
      "city": "Chambersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1373,
      "lat": 40.7346000671,
      "lon": -79.1598968506,
      "tz": "America\/New_York"
  },
  "PN10": {
      "icao": "PN10",
      "iata": "",
      "name": "Cash Creek Airport",
      "city": "Ulster",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1250,
      "lat": 41.8651008606,
      "lon": -76.5099029541,
      "tz": "America\/New_York"
  },
  "PN11": {
      "icao": "PN11",
      "iata": "",
      "name": "Reno Airport",
      "city": "Enon Valley",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1160,
      "lat": 40.8898010254,
      "lon": -80.4865036011,
      "tz": "America\/New_York"
  },
  "PN12": {
      "icao": "PN12",
      "iata": "",
      "name": "Sweet Valley Airport",
      "city": "Sweet Valley",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 41.2806015015,
      "lon": -78.1430969238,
      "tz": "America\/New_York"
  },
  "PN13": {
      "icao": "PN13",
      "iata": "",
      "name": "C & W Milliron Flying Field",
      "city": "Sigel",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1700,
      "lat": 41.2873001099,
      "lon": -79.1663970947,
      "tz": "America\/New_York"
  },
  "PN14": {
      "icao": "PN14",
      "iata": "",
      "name": "Misty Hill Farm Airport",
      "city": "Ren Frew",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1243,
      "lat": 40.7893981934,
      "lon": -79.9974975586,
      "tz": "America\/New_York"
  },
  "PN15": {
      "icao": "PN15",
      "iata": "",
      "name": "Greeley Airport",
      "city": "Coudersport",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2300,
      "lat": 41.8791999817,
      "lon": -77.9499969482,
      "tz": "America\/New_York"
  },
  "PN16": {
      "icao": "PN16",
      "iata": "",
      "name": "Pabst Blue Ribbon Airport",
      "city": "Evans City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1250,
      "lat": 40.7583999634,
      "lon": -80.0137023926,
      "tz": "America\/New_York"
  },
  "PN17": {
      "icao": "PN17",
      "iata": "",
      "name": "Marsteller Airport",
      "city": "Stewartstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 780,
      "lat": 39.7333984375,
      "lon": -76.5329971313,
      "tz": "America\/New_York"
  },
  "PN18": {
      "icao": "PN18",
      "iata": "",
      "name": "Fairview Evergreen Airport",
      "city": "Fairview",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 805,
      "lat": 42.0273017883,
      "lon": -80.2436981201,
      "tz": "America\/New_York"
  },
  "PN21": {
      "icao": "PN21",
      "iata": "",
      "name": "Mc Clure Airport",
      "city": "Troy",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1470,
      "lat": 41.8278999329,
      "lon": -76.8565979004,
      "tz": "America\/New_York"
  },
  "PN25": {
      "icao": "PN25",
      "iata": "",
      "name": "Lindsay Airport",
      "city": "Freeport",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1080,
      "lat": 40.7355995178,
      "lon": -79.6886978149,
      "tz": "America\/New_York"
  },
  "PN30": {
      "icao": "PN30",
      "iata": "",
      "name": "Schrenkel Airport",
      "city": "Cassville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.2627983093,
      "lon": -78.1025009155,
      "tz": "America\/New_York"
  },
  "PN31": {
      "icao": "PN31",
      "iata": "",
      "name": "Sagulla Airport",
      "city": "Grove City",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1378,
      "lat": 41.2430992126,
      "lon": -80.1141967773,
      "tz": "America\/New_York"
  },
  "PN33": {
      "icao": "PN33",
      "iata": "",
      "name": "Branning Field",
      "city": "Honesdale",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1420,
      "lat": 41.552898407,
      "lon": -75.2820968628,
      "tz": "America\/New_York"
  },
  "PN35": {
      "icao": "PN35",
      "iata": "",
      "name": "Flying R Airport",
      "city": "Harrisonville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 960,
      "lat": 40.0000991821,
      "lon": -78.0830993652,
      "tz": "America\/New_York"
  },
  "PN36": {
      "icao": "PN36",
      "iata": "",
      "name": "Rayne Airport",
      "city": "Home",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1420,
      "lat": 40.7333984375,
      "lon": -79.0672988892,
      "tz": "America\/New_York"
  },
  "PN37": {
      "icao": "PN37",
      "iata": "",
      "name": "Fino Airport",
      "city": "Hookstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1150,
      "lat": 40.6050987244,
      "lon": -80.4580993652,
      "tz": "America\/New_York"
  },
  "PN38": {
      "icao": "PN38",
      "iata": "",
      "name": "Culmerville Airport",
      "city": "Culmerville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1220,
      "lat": 40.6626014709,
      "lon": -79.8455963135,
      "tz": "America\/New_York"
  },
  "PN40": {
      "icao": "PN40",
      "iata": "",
      "name": "Moorhead Airpark LLC",
      "city": "North East",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 805,
      "lat": 42.1842002869,
      "lon": -79.8975982666,
      "tz": "America\/New_York"
  },
  "PN43": {
      "icao": "PN43",
      "iata": "",
      "name": "Sainovich Airport",
      "city": "Industry",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1275,
      "lat": 40.7000999451,
      "lon": -80.4330978394,
      "tz": "America\/New_York"
  },
  "PN46": {
      "icao": "PN46",
      "iata": "",
      "name": "River Hill Aviation Airport",
      "city": "Apollo",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1250,
      "lat": 40.5643997192,
      "lon": -79.5457992554,
      "tz": "America\/New_York"
  },
  "PN47": {
      "icao": "PN47",
      "iata": "",
      "name": "Lohr's Landing Airport",
      "city": "Jennerstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1970,
      "lat": 40.2083015442,
      "lon": -79.0558013916,
      "tz": "America\/New_York"
  },
  "PN48": {
      "icao": "PN48",
      "iata": "",
      "name": "Bullfly Ultralightport",
      "city": "Germansville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 812,
      "lat": 40.7083015442,
      "lon": -75.6860961914,
      "tz": "America\/New_York"
  },
  "PN49": {
      "icao": "PN49",
      "iata": "",
      "name": "Dunbar Airport",
      "city": "Kittanning",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1500,
      "lat": 40.7719993591,
      "lon": -79.4289016724,
      "tz": "America\/New_York"
  },
  "PN50": {
      "icao": "PN50",
      "iata": "",
      "name": "Skyline Airstrip",
      "city": "Shoemakersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 580,
      "lat": 40.5065002441,
      "lon": -75.9432983398,
      "tz": "America\/New_York"
  },
  "PN53": {
      "icao": "PN53",
      "iata": "",
      "name": "Strohmier Airport",
      "city": "Loretto",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1962,
      "lat": 40.5514984131,
      "lon": -78.6233978271,
      "tz": "America\/New_York"
  },
  "PN54": {
      "icao": "PN54",
      "iata": "",
      "name": "Akm Airfield",
      "city": "Columia Cross Roads",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1393,
      "lat": 41.8417015076,
      "lon": -76.7457962036,
      "tz": "America\/New_York"
  },
  "PN55": {
      "icao": "PN55",
      "iata": "",
      "name": "Skala Airport",
      "city": "Mc Clellandtown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1270,
      "lat": 39.8583984375,
      "lon": -79.8234024048,
      "tz": "America\/New_York"
  },
  "PN56": {
      "icao": "PN56",
      "iata": "",
      "name": "Miller Airport",
      "city": "Mahaffey",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1540,
      "lat": 40.8852996826,
      "lon": -78.7391967773,
      "tz": "America\/New_York"
  },
  "PN57": {
      "icao": "PN57",
      "iata": "",
      "name": "Marion Center Speedway Airport",
      "city": "Marion Center",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1500,
      "lat": 40.8120002747,
      "lon": -79.0373001099,
      "tz": "America\/New_York"
  },
  "PN58": {
      "icao": "PN58",
      "iata": "",
      "name": "Bittner\/Whitsel Airport",
      "city": "Waynesboro",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 760,
      "lat": 39.7426986694,
      "lon": -77.526802063,
      "tz": "America\/New_York"
  },
  "PN59": {
      "icao": "PN59",
      "iata": "",
      "name": "Stitt Airport",
      "city": "Kittanning",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.8311004639,
      "lon": -79.4869003296,
      "tz": "America\/New_York"
  },
  "PN62": {
      "icao": "PN62",
      "iata": "",
      "name": "Cataney Airport",
      "city": "Midway",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.3750991821,
      "lon": -80.2997970581,
      "tz": "America\/New_York"
  },
  "PN64": {
      "icao": "PN64",
      "iata": "",
      "name": "Hilling International Airport",
      "city": "Huntingdon",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1400,
      "lat": 40.5023994446,
      "lon": -77.9393997192,
      "tz": "America\/New_York"
  },
  "PN66": {
      "icao": "PN66",
      "iata": "",
      "name": "Dunlea Airpark",
      "city": "New Alexandria",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1220,
      "lat": 40.4255981445,
      "lon": -79.3636016846,
      "tz": "America\/New_York"
  },
  "PN68": {
      "icao": "PN68",
      "iata": "",
      "name": "Jackson Airport",
      "city": "New Galilee",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 930,
      "lat": 40.9009017944,
      "lon": -80.3681030273,
      "tz": "America\/New_York"
  },
  "PN69": {
      "icao": "PN69",
      "iata": "",
      "name": "Altemose Ultralightport",
      "city": "Malvern",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 345,
      "lat": 40.0924987793,
      "lon": -75.5158004761,
      "tz": "America\/New_York"
  },
  "PN71": {
      "icao": "PN71",
      "iata": "",
      "name": "Jansen Vineyards Airport",
      "city": "Richfield",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 860,
      "lat": 40.6500015259,
      "lon": -77.0575027466,
      "tz": "America\/New_York"
  },
  "PN72": {
      "icao": "PN72",
      "iata": "",
      "name": "Rocky Hollow Field",
      "city": "Strongstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1806,
      "lat": 40.5691986084,
      "lon": -78.8824996948,
      "tz": "America\/New_York"
  },
  "PN73": {
      "icao": "PN73",
      "iata": "",
      "name": "Beers Farm Airport",
      "city": "Orbisona\/Rockhill",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 630,
      "lat": 40.2300987244,
      "lon": -77.9206008911,
      "tz": "America\/New_York"
  },
  "PN83": {
      "icao": "PN83",
      "iata": "",
      "name": "Skunk Hollow Airport",
      "city": "Plumville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1380,
      "lat": 40.8027992249,
      "lon": -79.2024993896,
      "tz": "America\/New_York"
  },
  "PN84": {
      "icao": "PN84",
      "iata": "",
      "name": "Lake Arthur Field",
      "city": "Portersville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1240,
      "lat": 40.984500885,
      "lon": -80.1620025635,
      "tz": "America\/New_York"
  },
  "PN85": {
      "icao": "PN85",
      "iata": "",
      "name": "Brandon Airport",
      "city": "Prospect",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1290,
      "lat": 40.876499176,
      "lon": -80.0330963135,
      "tz": "America\/New_York"
  },
  "PN90": {
      "icao": "PN90",
      "iata": "",
      "name": "Ranch-Aero Airport",
      "city": "Roulette",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1575,
      "lat": 41.7486991882,
      "lon": -78.0997009277,
      "tz": "America\/New_York"
  },
  "PN91": {
      "icao": "PN91",
      "iata": "",
      "name": "Sharretts Airport",
      "city": "Sabinsville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2360,
      "lat": 41.8334007263,
      "lon": -77.4997024536,
      "tz": "America\/New_York"
  },
  "PN95": {
      "icao": "PN95",
      "iata": "",
      "name": "Horne Airport",
      "city": "Scenery Hill",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1040,
      "lat": 40.1001014709,
      "lon": -80.0330963135,
      "tz": "America\/New_York"
  },
  "PN99": {
      "icao": "PN99",
      "iata": "",
      "name": "Lackawannock Airport",
      "city": "Sharon",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1300,
      "lat": 41.2058982849,
      "lon": -80.3581008911,
      "tz": "America\/New_York"
  },
  "POLI": {
      "icao": "POLI",
      "iata": "",
      "name": "Oliktok LRRS Airport",
      "city": "Oliktok Point",
      "state": "Alaska",
      "country": "US",
      "elevation": 16,
      "lat": 70.49971,
      "lon": -149.879535,
      "tz": "America\/Anchorage"
  },
  "PPIZ": {
      "icao": "PPIZ",
      "iata": "PIZ",
      "name": "Point Lay Lrrs Airport",
      "city": "Point Lay",
      "state": "Alaska",
      "country": "US",
      "elevation": 22,
      "lat": 69.73290253,
      "lon": -163.0050049,
      "tz": "America\/Nome"
  },
  "PR07": {
      "icao": "PR07",
      "iata": "",
      "name": "Boqueron Airport",
      "city": "Las Piedras",
      "state": "Las-Piedras",
      "country": "PR",
      "elevation": 373,
      "lat": 18.2019004822,
      "lon": -65.8391036987,
      "tz": "America\/Puerto_Rico"
  },
  "PR10": {
      "icao": "PR10",
      "iata": "",
      "name": "Boqueron Airport",
      "city": "Cabo Rojo",
      "state": "Cabo-Rojo",
      "country": "PR",
      "elevation": 3,
      "lat": 18.0107002258,
      "lon": -67.1412963867,
      "tz": "America\/Puerto_Rico"
  },
  "PR20": {
      "icao": "PR20",
      "iata": "",
      "name": "Michael Gonzalez Airport",
      "city": "Adjuntas",
      "state": "Adjuntas",
      "country": "PR",
      "elevation": 2340,
      "lat": 18.1802005768,
      "lon": -66.7568969727,
      "tz": "America\/Puerto_Rico"
  },
  "PR24": {
      "icao": "PR24",
      "iata": "",
      "name": "Cullingford Field",
      "city": "Boqueron",
      "state": "Cabo-Rojo",
      "country": "PR",
      "elevation": 14,
      "lat": 17.9764003754,
      "lon": -67.1707992554,
      "tz": "America\/Puerto_Rico"
  },
  "PR27": {
      "icao": "PR27",
      "iata": "",
      "name": "Santa Isabel Airport",
      "city": "Santa Isabel",
      "state": "Santa-Isabel-Municipio",
      "country": "PR",
      "elevation": 27,
      "lat": 17.9631996155,
      "lon": -66.3911972046,
      "tz": "America\/Puerto_Rico"
  },
  "PS00": {
      "icao": "PS00",
      "iata": "",
      "name": "Tallman West Airport",
      "city": "Dauphin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 480,
      "lat": 40.4056015015,
      "lon": -76.9400024414,
      "tz": "America\/New_York"
  },
  "PS02": {
      "icao": "PS02",
      "iata": "",
      "name": "Heberlig Airport",
      "city": "Newville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 560,
      "lat": 40.1642990112,
      "lon": -77.3704986572,
      "tz": "America\/New_York"
  },
  "PS03": {
      "icao": "PS03",
      "iata": "",
      "name": "Elephant Path Airport",
      "city": "Perkasie",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 370,
      "lat": 40.4104003906,
      "lon": -75.2293014526,
      "tz": "America\/New_York"
  },
  "PS05": {
      "icao": "PS05",
      "iata": "",
      "name": "G & N Airport",
      "city": "Albion",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 985,
      "lat": 41.8753013611,
      "lon": -80.3748016357,
      "tz": "America\/New_York"
  },
  "PS06": {
      "icao": "PS06",
      "iata": "",
      "name": "Snook Airport",
      "city": "Beaver Springs",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 710,
      "lat": 40.7333984375,
      "lon": -77.2664031982,
      "tz": "America\/New_York"
  },
  "PS08": {
      "icao": "PS08",
      "iata": "",
      "name": "Lazy B Ranch Airport",
      "city": "Dover",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 450,
      "lat": 40.0225982666,
      "lon": -76.8165969849,
      "tz": "America\/New_York"
  },
  "PS11": {
      "icao": "PS11",
      "iata": "",
      "name": "Mc Cardle Farm Airport",
      "city": "Burnham",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 600,
      "lat": 40.6453018188,
      "lon": -77.5552978516,
      "tz": "America\/New_York"
  },
  "PS12": {
      "icao": "PS12",
      "iata": "",
      "name": "Grover Airport",
      "city": "Weatherly",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1223,
      "lat": 40.9168014526,
      "lon": -75.8663024902,
      "tz": "America\/New_York"
  },
  "PS15": {
      "icao": "PS15",
      "iata": "",
      "name": "Vicars Private Airport",
      "city": "Altoona",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1370,
      "lat": 40.5475997925,
      "lon": -78.3917007446,
      "tz": "America\/New_York"
  },
  "PS18": {
      "icao": "PS18",
      "iata": "",
      "name": "Pecora Field",
      "city": "Bradford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1500,
      "lat": 41.9037017822,
      "lon": -78.6275024414,
      "tz": "America\/New_York"
  },
  "PS20": {
      "icao": "PS20",
      "iata": "",
      "name": "Fairview Farm Airfield",
      "city": "Pine Grove",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 600,
      "lat": 40.5334014893,
      "lon": -76.4621963501,
      "tz": "America\/New_York"
  },
  "PS21": {
      "icao": "PS21",
      "iata": "",
      "name": "Flying Eagle Airport",
      "city": "Dalmatia",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 660,
      "lat": 40.6459007263,
      "lon": -76.8122024536,
      "tz": "America\/New_York"
  },
  "PS23": {
      "icao": "PS23",
      "iata": "",
      "name": "Windy Hill Airport",
      "city": "Curwensville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1622,
      "lat": 40.0850982666,
      "lon": -78.5475006104,
      "tz": "America\/New_York"
  },
  "PS25": {
      "icao": "PS25",
      "iata": "",
      "name": "Captain's Folly Airport",
      "city": "Gettysburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 641,
      "lat": 39.8433990479,
      "lon": -77.1647033691,
      "tz": "America\/New_York"
  },
  "PS27": {
      "icao": "PS27",
      "iata": "",
      "name": "Juergensen Airpark and Maritime Facility Airport",
      "city": "Addison",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1890,
      "lat": 39.7313995361,
      "lon": -79.375,
      "tz": "America\/New_York"
  },
  "PS35": {
      "icao": "PS35",
      "iata": "",
      "name": "Dutch Country Egg Farms Airport",
      "city": "Fredericksburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 550,
      "lat": 40.4580993652,
      "lon": -76.4580001831,
      "tz": "America\/New_York"
  },
  "PS38": {
      "icao": "PS38",
      "iata": "",
      "name": "Bert's Airport",
      "city": "Birdsboro - Pottstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 240,
      "lat": 40.2855987549,
      "lon": -75.7481994629,
      "tz": "America\/New_York"
  },
  "PS39": {
      "icao": "PS39",
      "iata": "",
      "name": "Downes Airport",
      "city": "Clymer",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1590,
      "lat": 40.6282997131,
      "lon": -78.9602966309,
      "tz": "America\/New_York"
  },
  "PS43": {
      "icao": "PS43",
      "iata": "",
      "name": "Barnes Farmland Airport",
      "city": "Volant",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1280,
      "lat": 41.0680999756,
      "lon": -80.255897522,
      "tz": "America\/New_York"
  },
  "PS46": {
      "icao": "PS46",
      "iata": "",
      "name": "Graystrip Airport",
      "city": "Dublin",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 642,
      "lat": 40.3623008728,
      "lon": -75.1801986694,
      "tz": "America\/New_York"
  },
  "PS47": {
      "icao": "PS47",
      "iata": "",
      "name": "Hamilton Hill Airport",
      "city": "Ebensburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2245,
      "lat": 40.427898407,
      "lon": -78.7414016724,
      "tz": "America\/New_York"
  },
  "PS49": {
      "icao": "PS49",
      "iata": "",
      "name": "Hartman Airport",
      "city": "Garrett",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2350,
      "lat": 39.8604011536,
      "lon": -78.9886016846,
      "tz": "America\/New_York"
  },
  "PS50": {
      "icao": "PS50",
      "iata": "",
      "name": "Huf Airport",
      "city": "Harford",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1600,
      "lat": 41.7678985596,
      "lon": -75.723197937,
      "tz": "America\/New_York"
  },
  "PS52": {
      "icao": "PS52",
      "iata": "",
      "name": "Alberter Farms Airport",
      "city": "Johnstown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 2070,
      "lat": 40.195098877,
      "lon": -78.8328018188,
      "tz": "America\/New_York"
  },
  "PS53": {
      "icao": "PS53",
      "iata": "",
      "name": "7-H Skeet Club Inc. Field",
      "city": "Patton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1605,
      "lat": 40.638999939,
      "lon": -78.5813980103,
      "tz": "America\/New_York"
  },
  "PS54": {
      "icao": "PS54",
      "iata": "",
      "name": "Gunden Airport",
      "city": "Perkasie",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 530,
      "lat": 40.3292999268,
      "lon": -75.2816009521,
      "tz": "America\/New_York"
  },
  "PS61": {
      "icao": "PS61",
      "iata": "",
      "name": "Tidioute Airport",
      "city": "Tidioute",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1100,
      "lat": 41.6908988953,
      "lon": -79.3658981323,
      "tz": "America\/New_York"
  },
  "PS64": {
      "icao": "PS64",
      "iata": "",
      "name": "Morton's Airport",
      "city": "Cambridge Springs",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 41.8250999451,
      "lon": -80.0789031982,
      "tz": "America\/New_York"
  },
  "PS66": {
      "icao": "PS66",
      "iata": "",
      "name": "Parker-Cramer Airport",
      "city": "Clarion",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1480,
      "lat": 41.2114982605,
      "lon": -79.3609008789,
      "tz": "America\/New_York"
  },
  "PS67": {
      "icao": "PS67",
      "iata": "",
      "name": "Uphill Airport",
      "city": "Claysville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1360,
      "lat": 40.1062011719,
      "lon": -80.4309005737,
      "tz": "America\/New_York"
  },
  "PS68": {
      "icao": "PS68",
      "iata": "",
      "name": "Buckingham Airport",
      "city": "Doylestown",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 250,
      "lat": 40.3322982788,
      "lon": -75.0304031372,
      "tz": "America\/New_York"
  },
  "PS69": {
      "icao": "PS69",
      "iata": "",
      "name": "Barnhart Airport",
      "city": "Flinton",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1640,
      "lat": 40.7270011902,
      "lon": -78.5394973755,
      "tz": "America\/New_York"
  },
  "PS70": {
      "icao": "PS70",
      "iata": "",
      "name": "Cider Field",
      "city": "Friedensburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 770,
      "lat": 40.6058998108,
      "lon": -76.2829971313,
      "tz": "America\/New_York"
  },
  "PS73": {
      "icao": "PS73",
      "iata": "",
      "name": "Poverty Airport",
      "city": "Mackeyville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 860,
      "lat": 41.0634002686,
      "lon": -77.4336013794,
      "tz": "America\/New_York"
  },
  "PS82": {
      "icao": "PS82",
      "iata": "",
      "name": "Lazy J. Ranch Airport",
      "city": "Springbrook",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1650,
      "lat": 41.2958984375,
      "lon": -75.5977020264,
      "tz": "America\/New_York"
  },
  "PS87": {
      "icao": "PS87",
      "iata": "",
      "name": "Home Safe Airport",
      "city": "Ellsworth\/Bentleyville",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1140,
      "lat": 40.1058998108,
      "lon": -80.0338973999,
      "tz": "America\/New_York"
  },
  "PS98": {
      "icao": "PS98",
      "iata": "",
      "name": "Travis Airport",
      "city": "Smicksburg",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1320,
      "lat": 40.8828010559,
      "lon": -79.1965026855,
      "tz": "America\/New_York"
  },
  "PS99": {
      "icao": "PS99",
      "iata": "",
      "name": "West Penn Township Airport",
      "city": "Tamaqua",
      "state": "Pennsylvania",
      "country": "US",
      "elevation": 1200,
      "lat": 40.7478981018,
      "lon": -75.9329986572,
      "tz": "America\/New_York"
  },
  "PTKK": {
      "icao": "PTKK",
      "iata": "TKK",
      "name": "Chuuk International Airport",
      "city": "Weno Island",
      "state": "Chuuk",
      "country": "FM",
      "elevation": 11,
      "lat": 7.4618701935,
      "lon": 151.8430023193,
      "tz": "Pacific\/Chuuk"
  },
  "PTPN": {
      "icao": "PTPN",
      "iata": "PNI",
      "name": "Pohnpei International Airport",
      "city": "Pohnpei Island",
      "state": "Pohnpei",
      "country": "FM",
      "elevation": 10,
      "lat": 6.9850997925,
      "lon": 158.2089996338,
      "tz": "Pacific\/Pohnpei"
  },
  "PTRO": {
      "icao": "PTRO",
      "iata": "ROR",
      "name": "Babelthuap Airport",
      "city": "Babelthuap Island",
      "state": "Airai",
      "country": "PW",
      "elevation": 176,
      "lat": 7.367650032,
      "lon": 134.5440063477,
      "tz": "Pacific\/Palau"
  },
  "PTSA": {
      "icao": "PTSA",
      "iata": "KSA",
      "name": "Kosrae International Airport",
      "city": "Okat",
      "state": "Kosrae",
      "country": "FM",
      "elevation": 11,
      "lat": 5.356979847,
      "lon": 162.957992554,
      "tz": "Pacific\/Kosrae"
  },
  "PTYA": {
      "icao": "PTYA",
      "iata": "YAP",
      "name": "Yap International Airport",
      "city": "Yap Island",
      "state": "Yap",
      "country": "FM",
      "elevation": 91,
      "lat": 9.4989099503,
      "lon": 138.0829925537,
      "tz": "Pacific\/Chuuk"
  },
  "PWAK": {
      "icao": "PWAK",
      "iata": "AWK",
      "name": "Wake Island Airfield",
      "city": "Wake Island",
      "state": "Wake-Island",
      "country": "UM",
      "elevation": 14,
      "lat": 19.2821006775,
      "lon": 166.6360015869,
      "tz": "Pacific\/Wake"
  },
  "RBLA": {
      "icao": "RBLA",
      "iata": "",
      "name": "Sierra Blanca Airport",
      "city": "Sierra Blanca",
      "state": "Texas",
      "country": "US",
      "elevation": 4512,
      "lat": 31.2583007812,
      "lon": -105.297996521,
      "tz": "America\/Denver"
  },
  "RCAY": {
      "icao": "RCAY",
      "iata": "",
      "name": "Gangshan Air Force Base",
      "city": "Gangshan",
      "state": "Takao",
      "country": "TW",
      "elevation": 34,
      "lat": 22.7824993134,
      "lon": 120.2630004883,
      "tz": "Asia\/Taipei"
  },
  "RCBS": {
      "icao": "RCBS",
      "iata": "KNH",
      "name": "Kinmen Airport",
      "city": "Shang-I",
      "state": "Fukien",
      "country": "TW",
      "elevation": 93,
      "lat": 24.4279003143,
      "lon": 118.3590011597,
      "tz": "Asia\/Taipei"
  },
  "RCDC": {
      "icao": "RCDC",
      "iata": "PIF",
      "name": "Pingtung South Airport",
      "city": "Pingtung",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 78,
      "lat": 22.6723995209,
      "lon": 120.4619979858,
      "tz": "Asia\/Taipei"
  },
  "RCDI": {
      "icao": "RCDI",
      "iata": "",
      "name": "Longtan Air Base",
      "city": "Longtan Township",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 790,
      "lat": 24.8551006317,
      "lon": 121.2379989624,
      "tz": "Asia\/Taipei"
  },
  "RCFG": {
      "icao": "RCFG",
      "iata": "LZN",
      "name": "Matsu Nangan Airport",
      "city": "Nangang Island",
      "state": "Fukien",
      "country": "TW",
      "elevation": 232,
      "lat": 26.1597995758,
      "lon": 119.9580001831,
      "tz": "Asia\/Taipei"
  },
  "RCFN": {
      "icao": "RCFN",
      "iata": "TTT",
      "name": "Taitung Airport",
      "city": "Taitung City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 143,
      "lat": 22.7549991608,
      "lon": 121.1019973755,
      "tz": "Asia\/Taipei"
  },
  "RCGI": {
      "icao": "RCGI",
      "iata": "GNI",
      "name": "Lyudao Airport",
      "city": "Lyudao",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 28,
      "lat": 22.6739006042,
      "lon": 121.466003418,
      "tz": "Asia\/Taipei"
  },
  "RCGM": {
      "icao": "RCGM",
      "iata": "",
      "name": "Taoyuan Air Base",
      "city": "Taoyuan City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 144,
      "lat": 25.0566005707,
      "lon": 121.2442626953,
      "tz": "Asia\/Taipei"
  },
  "RCKH": {
      "icao": "RCKH",
      "iata": "KHH",
      "name": "Kaohsiung International Airport",
      "city": "Kaohsiung City",
      "state": "Takao",
      "country": "TW",
      "elevation": 31,
      "lat": 22.5771007538,
      "lon": 120.3499984741,
      "tz": "Asia\/Taipei"
  },
  "RCKU": {
      "icao": "RCKU",
      "iata": "CYI",
      "name": "Chiayi Airport",
      "city": "Chiayi City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 85,
      "lat": 23.4617996216,
      "lon": 120.3929977417,
      "tz": "Asia\/Taipei"
  },
  "RCKW": {
      "icao": "RCKW",
      "iata": "HCN",
      "name": "Hengchun Airport",
      "city": "Hengchung",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 46,
      "lat": 22.0410995483,
      "lon": 120.7300033569,
      "tz": "Asia\/Taipei"
  },
  "RCLG": {
      "icao": "RCLG",
      "iata": "TXG",
      "name": "Taichung Airport",
      "city": "Taichung City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 369,
      "lat": 24.1863002777,
      "lon": 120.6539993286,
      "tz": "Asia\/Taipei"
  },
  "RCLY": {
      "icao": "RCLY",
      "iata": "KYD",
      "name": "Lanyu Airport",
      "city": "Orchid Island",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 44,
      "lat": 22.0270004272,
      "lon": 121.5350036621,
      "tz": "Asia\/Taipei"
  },
  "RCMQ": {
      "icao": "RCMQ",
      "iata": "RMQ",
      "name": "Taichung Ching Chuang Kang Airport",
      "city": "Taichung City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 663,
      "lat": 24.2646999359,
      "lon": 120.6210021973,
      "tz": "Asia\/Taipei"
  },
  "RCMT": {
      "icao": "RCMT",
      "iata": "MFK",
      "name": "Matsu Beigan Airport",
      "city": "Beigan Island",
      "state": "Fukien",
      "country": "TW",
      "elevation": 41,
      "lat": 26.224199295,
      "lon": 120.0029983521,
      "tz": "Asia\/Taipei"
  },
  "RCNN": {
      "icao": "RCNN",
      "iata": "TNN",
      "name": "Tainan Airport",
      "city": "Tainan City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 63,
      "lat": 22.9503993988,
      "lon": 120.2060012817,
      "tz": "Asia\/Taipei"
  },
  "RCPO": {
      "icao": "RCPO",
      "iata": "HSZ",
      "name": "Hsinchu Air Base",
      "city": "Hsinchu City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 26,
      "lat": 24.8180007935,
      "lon": 120.9390029907,
      "tz": "Asia\/Taipei"
  },
  "RCQC": {
      "icao": "RCQC",
      "iata": "MZG",
      "name": "Makung Airport",
      "city": "Makung City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 103,
      "lat": 23.5687007904,
      "lon": 119.6279983521,
      "tz": "Asia\/Taipei"
  },
  "RCQS": {
      "icao": "RCQS",
      "iata": "",
      "name": "Chihhang Air Base",
      "city": "Taitung City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 121,
      "lat": 22.7931003571,
      "lon": 121.1819992065,
      "tz": "Asia\/Taipei"
  },
  "RCSQ": {
      "icao": "RCSQ",
      "iata": "PIF",
      "name": "Pingtung North Airport",
      "city": "Pingtung",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 97,
      "lat": 22.7001991272,
      "lon": 120.4820022583,
      "tz": "Asia\/Taipei"
  },
  "RCSS": {
      "icao": "RCSS",
      "iata": "TSA",
      "name": "Taipei Songshan Airport",
      "city": "Taipei City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 18,
      "lat": 25.0694007874,
      "lon": 121.5520019531,
      "tz": "Asia\/Taipei"
  },
  "RCTP": {
      "icao": "RCTP",
      "iata": "TPE",
      "name": "Taiwan Taoyuan International Airport",
      "city": "Taipei",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 106,
      "lat": 25.0776996613,
      "lon": 121.233001709,
      "tz": "Asia\/Taipei"
  },
  "RCWA": {
      "icao": "RCWA",
      "iata": "WOT",
      "name": "Wang-an Airport",
      "city": "Wang-an",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 115,
      "lat": 23.3673725128,
      "lon": 119.5027770996,
      "tz": "Asia\/Taipei"
  },
  "RCYU": {
      "icao": "RCYU",
      "iata": "HUN",
      "name": "Hualien Airport",
      "city": "Hualien City",
      "state": "Taiwan",
      "country": "TW",
      "elevation": 52,
      "lat": 24.0230998993,
      "lon": 121.6179962158,
      "tz": "Asia\/Taipei"
  },
  "RHST": {
      "icao": "RHST",
      "iata": "",
      "name": "Rheinstetten Airport",
      "city": "",
      "state": "Baden-Wuerttemberg",
      "country": "DE",
      "elevation": 0,
      "lat": 48.9777777778,
      "lon": 8.3425,
      "tz": "Europe\/Berlin"
  },
  "RI07": {
      "icao": "RI07",
      "iata": "",
      "name": "Wing-Over Farm Airport",
      "city": "Tiverton",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 113,
      "lat": 41.5789985657,
      "lon": -71.1417007446,
      "tz": "America\/New_York"
  },
  "RI11": {
      "icao": "RI11",
      "iata": "",
      "name": "Riconn Airport",
      "city": "Greene",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 385,
      "lat": 41.6968002319,
      "lon": -71.7836990356,
      "tz": "America\/New_York"
  },
  "RI20": {
      "icao": "RI20",
      "iata": "",
      "name": "Mystery Farm Airport",
      "city": "Cranston",
      "state": "Rhode-Island",
      "country": "US",
      "elevation": 310,
      "lat": 41.7394981384,
      "lon": -71.5244979858,
      "tz": "America\/New_York"
  },
  "RJ04": {
      "icao": "RJ04",
      "iata": "",
      "name": "Shikabe Airport",
      "city": "",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 63,
      "lat": 42.0449981689,
      "lon": 140.7929992676,
      "tz": "Asia\/Tokyo"
  },
  "RJAA": {
      "icao": "RJAA",
      "iata": "NRT",
      "name": "Narita International Airport",
      "city": "Tokyo",
      "state": "Chiba",
      "country": "JP",
      "elevation": 141,
      "lat": 35.7647018433,
      "lon": 140.3860015869,
      "tz": "Asia\/Tokyo"
  },
  "RJAF": {
      "icao": "RJAF",
      "iata": "MMJ",
      "name": "Matsumoto Airport",
      "city": "Matsumoto",
      "state": "Nagano",
      "country": "JP",
      "elevation": 2182,
      "lat": 36.1668014526,
      "lon": 137.9230041504,
      "tz": "Asia\/Tokyo"
  },
  "RJAH": {
      "icao": "RJAH",
      "iata": "IBR",
      "name": "Hyakuri Airport",
      "city": "Omitama",
      "state": "Ibaraki",
      "country": "JP",
      "elevation": 105,
      "lat": 36.181098938,
      "lon": 140.414993286,
      "tz": "Asia\/Tokyo"
  },
  "RJAK": {
      "icao": "RJAK",
      "iata": "",
      "name": "Kasumigaura Airport",
      "city": "",
      "state": "Ibaraki",
      "country": "JP",
      "elevation": 92,
      "lat": 36.0346984863,
      "lon": 140.1929931641,
      "tz": "Asia\/Tokyo"
  },
  "RJAM": {
      "icao": "RJAM",
      "iata": "MUS",
      "name": "Minami Torishima Airport",
      "city": "",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 22,
      "lat": 24.2896995544,
      "lon": 153.979003906,
      "tz": "Asia\/Tokyo"
  },
  "RJAN": {
      "icao": "RJAN",
      "iata": "",
      "name": "Nijima Airport",
      "city": "",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 92,
      "lat": 34.3694000244,
      "lon": 139.2689971924,
      "tz": "Asia\/Tokyo"
  },
  "RJAW": {
      "icao": "RJAW",
      "iata": "IWO",
      "name": "Iwo Jima Airport",
      "city": "",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 384,
      "lat": 24.7840003967,
      "lon": 141.3229980469,
      "tz": "Asia\/Tokyo"
  },
  "RJAZ": {
      "icao": "RJAZ",
      "iata": "",
      "name": "Kozushima Airport",
      "city": "Kozushima",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 452,
      "lat": 34.1899986267,
      "lon": 139.1340026855,
      "tz": "Asia\/Tokyo"
  },
  "RJBB": {
      "icao": "RJBB",
      "iata": "KIX",
      "name": "Kansai International Airport",
      "city": "Osaka",
      "state": "Osaka",
      "country": "JP",
      "elevation": 26,
      "lat": 34.4272994995,
      "lon": 135.2440032959,
      "tz": "Asia\/Tokyo"
  },
  "RJBD": {
      "icao": "RJBD",
      "iata": "SHM",
      "name": "Nanki Shirahama Airport",
      "city": "Shirahama",
      "state": "Wakayama",
      "country": "JP",
      "elevation": 298,
      "lat": 33.6622009277,
      "lon": 135.363998413,
      "tz": "Asia\/Tokyo"
  },
  "RJBE": {
      "icao": "RJBE",
      "iata": "UKB",
      "name": "Kobe Airport",
      "city": "Kobe",
      "state": "Hyogo",
      "country": "JP",
      "elevation": 22,
      "lat": 34.6328010559,
      "lon": 135.2239990234,
      "tz": "Asia\/Tokyo"
  },
  "RJBH": {
      "icao": "RJBH",
      "iata": "HIW",
      "name": "Hiroshimanishi Airport",
      "city": "",
      "state": "Hiroshima",
      "country": "JP",
      "elevation": 15,
      "lat": 34.3669013977,
      "lon": 132.4140014648,
      "tz": "Asia\/Tokyo"
  },
  "RJBK": {
      "icao": "RJBK",
      "iata": "",
      "name": "Kohnan Airport",
      "city": "",
      "state": "Okayama",
      "country": "JP",
      "elevation": 3,
      "lat": 34.590801239,
      "lon": 133.9329986572,
      "tz": "Asia\/Tokyo"
  },
  "RJBT": {
      "icao": "RJBT",
      "iata": "TJH",
      "name": "Tajima Airport",
      "city": "Tajima",
      "state": "Hyogo",
      "country": "JP",
      "elevation": 584,
      "lat": 35.5127983093,
      "lon": 134.7870025635,
      "tz": "Asia\/Tokyo"
  },
  "RJCA": {
      "icao": "RJCA",
      "iata": "",
      "name": "Asahikawa Airport",
      "city": "",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 377,
      "lat": 43.7952003479,
      "lon": 142.3630065918,
      "tz": "Asia\/Tokyo"
  },
  "RJCB": {
      "icao": "RJCB",
      "iata": "OBO",
      "name": "Tokachi-Obihiro Airport",
      "city": "Obihiro",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 505,
      "lat": 42.7332992554,
      "lon": 143.216995239,
      "tz": "Asia\/Tokyo"
  },
  "RJCC": {
      "icao": "RJCC",
      "iata": "CTS",
      "name": "New Chitose Airport",
      "city": "Chitose \/ Tomakomai",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 82,
      "lat": 42.7751998901,
      "lon": 141.6920013428,
      "tz": "Asia\/Tokyo"
  },
  "RJCH": {
      "icao": "RJCH",
      "iata": "HKD",
      "name": "Hakodate Airport",
      "city": "Hakodate",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 151,
      "lat": 41.7700004578,
      "lon": 140.822006226,
      "tz": "Asia\/Tokyo"
  },
  "RJCJ": {
      "icao": "RJCJ",
      "iata": "",
      "name": "Chitose Air Base",
      "city": "",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 87,
      "lat": 42.7944984436,
      "lon": 141.666000366,
      "tz": "Asia\/Tokyo"
  },
  "RJCK": {
      "icao": "RJCK",
      "iata": "KUH",
      "name": "Kushiro Airport",
      "city": "Kushiro",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 327,
      "lat": 43.0410003662,
      "lon": 144.192993164,
      "tz": "Asia\/Tokyo"
  },
  "RJCM": {
      "icao": "RJCM",
      "iata": "MMB",
      "name": "Memanbetsu Airport",
      "city": "Ozora",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 135,
      "lat": 43.8805999756,
      "lon": 144.164001465,
      "tz": "Asia\/Tokyo"
  },
  "RJCN": {
      "icao": "RJCN",
      "iata": "SHB",
      "name": "Nakashibetsu Airport",
      "city": "Nakashibetsu",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 234,
      "lat": 43.5774993896,
      "lon": 144.960006714,
      "tz": "Asia\/Tokyo"
  },
  "RJCO": {
      "icao": "RJCO",
      "iata": "OKD",
      "name": "Okadama Airport",
      "city": "Sapporo",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 25,
      "lat": 43.1161003113,
      "lon": 141.3800048828,
      "tz": "Asia\/Tokyo"
  },
  "RJCR": {
      "icao": "RJCR",
      "iata": "RBJ",
      "name": "Rebun Airport Airport",
      "city": "",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 92,
      "lat": 45.4550018311,
      "lon": 141.0390014648,
      "tz": "Asia\/Tokyo"
  },
  "RJCT": {
      "icao": "RJCT",
      "iata": "",
      "name": "Tokachi Airport",
      "city": "",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 281,
      "lat": 42.890499115,
      "lon": 143.1580047607,
      "tz": "Asia\/Tokyo"
  },
  "RJCW": {
      "icao": "RJCW",
      "iata": "WKJ",
      "name": "Wakkanai Airport",
      "city": "Wakkanai",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 30,
      "lat": 45.4042015076,
      "lon": 141.800994873,
      "tz": "Asia\/Tokyo"
  },
  "RJDA": {
      "icao": "RJDA",
      "iata": "AXJ",
      "name": "Amakusa Airport",
      "city": "",
      "state": "Kumamoto",
      "country": "JP",
      "elevation": 340,
      "lat": 32.4824981689,
      "lon": 130.158996582,
      "tz": "Asia\/Tokyo"
  },
  "RJDB": {
      "icao": "RJDB",
      "iata": "IKI",
      "name": "Iki Airport",
      "city": "Iki",
      "state": "Nagasaki",
      "country": "JP",
      "elevation": 41,
      "lat": 33.7490005493,
      "lon": 129.785003662,
      "tz": "Asia\/Tokyo"
  },
  "RJDC": {
      "icao": "RJDC",
      "iata": "UBJ",
      "name": "Yamaguchi Ube Airport",
      "city": "Ube",
      "state": "Yamaguchi",
      "country": "JP",
      "elevation": 23,
      "lat": 33.9300003052,
      "lon": 131.279006958,
      "tz": "Asia\/Tokyo"
  },
  "RJDK": {
      "icao": "RJDK",
      "iata": "",
      "name": "Kamigoto Airport",
      "city": "Kamigoto",
      "state": "Nagasaki",
      "country": "JP",
      "elevation": 263,
      "lat": 33.0130996704,
      "lon": 129.1920013428,
      "tz": "Asia\/Tokyo"
  },
  "RJDM": {
      "icao": "RJDM",
      "iata": "",
      "name": "Metabaru Air Base",
      "city": "",
      "state": "Saga",
      "country": "JP",
      "elevation": 49,
      "lat": 33.3252983093,
      "lon": 130.4140014648,
      "tz": "Asia\/Tokyo"
  },
  "RJDO": {
      "icao": "RJDO",
      "iata": "",
      "name": "Ojika Airport",
      "city": "",
      "state": "Nagasaki",
      "country": "JP",
      "elevation": 30,
      "lat": 33.1907997131,
      "lon": 129.0899963379,
      "tz": "Asia\/Tokyo"
  },
  "RJDT": {
      "icao": "RJDT",
      "iata": "TSJ",
      "name": "Tsushima Airport",
      "city": "Tsushima",
      "state": "Nagasaki",
      "country": "JP",
      "elevation": 213,
      "lat": 34.2849006653,
      "lon": 129.330993652,
      "tz": "Asia\/Tokyo"
  },
  "RJEB": {
      "icao": "RJEB",
      "iata": "MBE",
      "name": "Monbetsu Airport",
      "city": "Monbetsu",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 80,
      "lat": 44.3039016724,
      "lon": 143.404006958,
      "tz": "Asia\/Tokyo"
  },
  "RJEC": {
      "icao": "RJEC",
      "iata": "AKJ",
      "name": "Asahikawa Airport",
      "city": "Asahikawa",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 721,
      "lat": 43.6707992554,
      "lon": 142.4470062256,
      "tz": "Asia\/Tokyo"
  },
  "RJEO": {
      "icao": "RJEO",
      "iata": "OIR",
      "name": "Okushiri Airport",
      "city": "",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 161,
      "lat": 42.0717010498,
      "lon": 139.4329986572,
      "tz": "Asia\/Tokyo"
  },
  "RJER": {
      "icao": "RJER",
      "iata": "RIS",
      "name": "Rishiri Airport",
      "city": "Rishiri",
      "state": "Hokkaido",
      "country": "JP",
      "elevation": 112,
      "lat": 45.2420005798,
      "lon": 141.186004639,
      "tz": "Asia\/Tokyo"
  },
  "RJFA": {
      "icao": "RJFA",
      "iata": "",
      "name": "Ashiya Airport",
      "city": "",
      "state": "Fukuoka",
      "country": "JP",
      "elevation": 98,
      "lat": 33.8830986023,
      "lon": 130.6529998779,
      "tz": "Asia\/Tokyo"
  },
  "RJFC": {
      "icao": "RJFC",
      "iata": "KUM",
      "name": "Yakushima Airport",
      "city": "",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 124,
      "lat": 30.3855991364,
      "lon": 130.658996582,
      "tz": "Asia\/Tokyo"
  },
  "RJFE": {
      "icao": "RJFE",
      "iata": "FUJ",
      "name": "Fukue Airport",
      "city": "Goto",
      "state": "Nagasaki",
      "country": "JP",
      "elevation": 273,
      "lat": 32.6663017273,
      "lon": 128.8329925537,
      "tz": "Asia\/Tokyo"
  },
  "RJFF": {
      "icao": "RJFF",
      "iata": "FUK",
      "name": "Fukuoka Airport",
      "city": "Fukuoka",
      "state": "Fukuoka",
      "country": "JP",
      "elevation": 32,
      "lat": 33.585899353,
      "lon": 130.4510040283,
      "tz": "Asia\/Tokyo"
  },
  "RJFG": {
      "icao": "RJFG",
      "iata": "TNE",
      "name": "New Tanegashima Airport",
      "city": "",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 768,
      "lat": 30.6051006317,
      "lon": 130.9909973145,
      "tz": "Asia\/Tokyo"
  },
  "RJFK": {
      "icao": "RJFK",
      "iata": "KOJ",
      "name": "Kagoshima Airport",
      "city": "Kagoshima",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 906,
      "lat": 31.8034000397,
      "lon": 130.7189941406,
      "tz": "Asia\/Tokyo"
  },
  "RJFM": {
      "icao": "RJFM",
      "iata": "KMI",
      "name": "Miyazaki Airport",
      "city": "Miyazaki",
      "state": "Miyazaki",
      "country": "JP",
      "elevation": 20,
      "lat": 31.877199173,
      "lon": 131.449005127,
      "tz": "Asia\/Tokyo"
  },
  "RJFN": {
      "icao": "RJFN",
      "iata": "",
      "name": "Nyutabaru Airport",
      "city": "",
      "state": "Miyazaki",
      "country": "JP",
      "elevation": 259,
      "lat": 32.0835990906,
      "lon": 131.4510040283,
      "tz": "Asia\/Tokyo"
  },
  "RJFO": {
      "icao": "RJFO",
      "iata": "OIT",
      "name": "Oita Airport",
      "city": "Oita",
      "state": "Oita",
      "country": "JP",
      "elevation": 19,
      "lat": 33.4794006348,
      "lon": 131.736999512,
      "tz": "Asia\/Tokyo"
  },
  "RJFR": {
      "icao": "RJFR",
      "iata": "KKJ",
      "name": "Kitakyushu Airport",
      "city": "Kitakyushu",
      "state": "Fukuoka",
      "country": "JP",
      "elevation": 21,
      "lat": 33.8459014893,
      "lon": 131.035003662,
      "tz": "Asia\/Tokyo"
  },
  "RJFS": {
      "icao": "RJFS",
      "iata": "HSG",
      "name": "Saga Airport",
      "city": "Saga",
      "state": "Saga",
      "country": "JP",
      "elevation": 6,
      "lat": 33.1497001648,
      "lon": 130.302001953,
      "tz": "Asia\/Tokyo"
  },
  "RJFT": {
      "icao": "RJFT",
      "iata": "KMJ",
      "name": "Kumamoto Airport",
      "city": "Kumamoto",
      "state": "Kumamoto",
      "country": "JP",
      "elevation": 642,
      "lat": 32.8372993469,
      "lon": 130.8549957275,
      "tz": "Asia\/Tokyo"
  },
  "RJFU": {
      "icao": "RJFU",
      "iata": "NGS",
      "name": "Nagasaki Airport",
      "city": "Nagasaki",
      "state": "Nagasaki",
      "country": "JP",
      "elevation": 15,
      "lat": 32.9169006348,
      "lon": 129.914001465,
      "tz": "Asia\/Tokyo"
  },
  "RJFY": {
      "icao": "RJFY",
      "iata": "",
      "name": "Kanoya Airport",
      "city": "",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 214,
      "lat": 31.3675994873,
      "lon": 130.8450012207,
      "tz": "Asia\/Tokyo"
  },
  "RJFZ": {
      "icao": "RJFZ",
      "iata": "",
      "name": "Tsuiki Airport",
      "city": "",
      "state": "Fukuoka",
      "country": "JP",
      "elevation": 55,
      "lat": 33.6850013733,
      "lon": 131.0399932861,
      "tz": "Asia\/Tokyo"
  },
  "RJGG": {
      "icao": "RJGG",
      "iata": "NGO",
      "name": "Chubu Centrair International Airport",
      "city": "Tokoname",
      "state": "Aichi",
      "country": "JP",
      "elevation": 15,
      "lat": 34.8583984375,
      "lon": 136.8049926758,
      "tz": "Asia\/Tokyo"
  },
  "RJKA": {
      "icao": "RJKA",
      "iata": "ASJ",
      "name": "Amami Airport",
      "city": "Amami",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 27,
      "lat": 28.4305992126,
      "lon": 129.7129974365,
      "tz": "Asia\/Tokyo"
  },
  "RJKB": {
      "icao": "RJKB",
      "iata": "OKE",
      "name": "Okierabu Airport",
      "city": "",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 101,
      "lat": 27.4255008698,
      "lon": 128.7010040283,
      "tz": "Asia\/Tokyo"
  },
  "RJKI": {
      "icao": "RJKI",
      "iata": "KKX",
      "name": "Kikai Airport",
      "city": "",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 21,
      "lat": 28.3213005066,
      "lon": 129.9279937744,
      "tz": "Asia\/Tokyo"
  },
  "RJKN": {
      "icao": "RJKN",
      "iata": "TKN",
      "name": "Tokunoshima Airport",
      "city": "Tokunoshima",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 17,
      "lat": 27.8363990784,
      "lon": 128.8809967041,
      "tz": "Asia\/Tokyo"
  },
  "RJNA": {
      "icao": "RJNA",
      "iata": "NKM",
      "name": "Nagoya Airport",
      "city": "Nagoya",
      "state": "Aichi",
      "country": "JP",
      "elevation": 52,
      "lat": 35.2550010681,
      "lon": 136.9239959717,
      "tz": "Asia\/Tokyo"
  },
  "RJNF": {
      "icao": "RJNF",
      "iata": "FKJ",
      "name": "Fukui Airport",
      "city": "",
      "state": "Fukui",
      "country": "JP",
      "elevation": 19,
      "lat": 36.1427993774,
      "lon": 136.2239990234,
      "tz": "Asia\/Tokyo"
  },
  "RJNG": {
      "icao": "RJNG",
      "iata": "QGU",
      "name": "Gifu Airport",
      "city": "Gifu",
      "state": "Gifu",
      "country": "JP",
      "elevation": 128,
      "lat": 35.3941001892,
      "lon": 136.8699951172,
      "tz": "Asia\/Tokyo"
  },
  "RJNH": {
      "icao": "RJNH",
      "iata": "",
      "name": "Hamamatsu Airport",
      "city": "",
      "state": "Shizuoka",
      "country": "JP",
      "elevation": 150,
      "lat": 34.7501983643,
      "lon": 137.7030029297,
      "tz": "Asia\/Tokyo"
  },
  "RJNK": {
      "icao": "RJNK",
      "iata": "KMQ",
      "name": "Komatsu Airport",
      "city": "Kanazawa",
      "state": "Ishikawa",
      "country": "JP",
      "elevation": 36,
      "lat": 36.3945999146,
      "lon": 136.4069976807,
      "tz": "Asia\/Tokyo"
  },
  "RJNO": {
      "icao": "RJNO",
      "iata": "OKI",
      "name": "Oki Airport",
      "city": "Okinoshima",
      "state": "Shimane",
      "country": "JP",
      "elevation": 311,
      "lat": 36.181098938,
      "lon": 133.3249969482,
      "tz": "Asia\/Tokyo"
  },
  "RJNS": {
      "icao": "RJNS",
      "iata": "FSZ",
      "name": "Mt. Fuji Shizuoka Airport",
      "city": "",
      "state": "Shizuoka",
      "country": "JP",
      "elevation": 433,
      "lat": 34.7960434679,
      "lon": 138.18775177,
      "tz": "Asia\/Tokyo"
  },
  "RJNT": {
      "icao": "RJNT",
      "iata": "TOY",
      "name": "Toyama Airport",
      "city": "Toyama",
      "state": "Toyama",
      "country": "JP",
      "elevation": 95,
      "lat": 36.6483001709,
      "lon": 137.18800354,
      "tz": "Asia\/Tokyo"
  },
  "RJNW": {
      "icao": "RJNW",
      "iata": "NTQ",
      "name": "Noto Airport",
      "city": "Wajima",
      "state": "Ishikawa",
      "country": "JP",
      "elevation": 718,
      "lat": 37.2930984497,
      "lon": 136.962005615,
      "tz": "Asia\/Tokyo"
  },
  "RJNY": {
      "icao": "RJNY",
      "iata": "",
      "name": "Shizuhama Airport",
      "city": "",
      "state": "Shizuoka",
      "country": "JP",
      "elevation": 23,
      "lat": 34.8128013611,
      "lon": 138.2980041504,
      "tz": "Asia\/Tokyo"
  },
  "RJOA": {
      "icao": "RJOA",
      "iata": "HIJ",
      "name": "Hiroshima Airport",
      "city": "Hiroshima",
      "state": "Hiroshima",
      "country": "JP",
      "elevation": 1088,
      "lat": 34.4361000061,
      "lon": 132.919006348,
      "tz": "Asia\/Tokyo"
  },
  "RJOB": {
      "icao": "RJOB",
      "iata": "OKJ",
      "name": "Okayama Airport",
      "city": "Okayama City",
      "state": "Okayama",
      "country": "JP",
      "elevation": 806,
      "lat": 34.7569007874,
      "lon": 133.854995728,
      "tz": "Asia\/Tokyo"
  },
  "RJOC": {
      "icao": "RJOC",
      "iata": "IZO",
      "name": "Izumo Airport",
      "city": "Izumo",
      "state": "Shimane",
      "country": "JP",
      "elevation": 15,
      "lat": 35.4136009216,
      "lon": 132.88999939,
      "tz": "Asia\/Tokyo"
  },
  "RJOE": {
      "icao": "RJOE",
      "iata": "",
      "name": "Akeno Airport",
      "city": "",
      "state": "Mie",
      "country": "JP",
      "elevation": 20,
      "lat": 34.5332984924,
      "lon": 136.6719970703,
      "tz": "Asia\/Tokyo"
  },
  "RJOF": {
      "icao": "RJOF",
      "iata": "",
      "name": "Hofu Airport",
      "city": "",
      "state": "Yamaguchi",
      "country": "JP",
      "elevation": 7,
      "lat": 34.0346984863,
      "lon": 131.5489959717,
      "tz": "Asia\/Tokyo"
  },
  "RJOH": {
      "icao": "RJOH",
      "iata": "YGJ",
      "name": "Miho Yonago Airport",
      "city": "Yonago",
      "state": "Tottori",
      "country": "JP",
      "elevation": 20,
      "lat": 35.4921989441,
      "lon": 133.2359924316,
      "tz": "Asia\/Tokyo"
  },
  "RJOI": {
      "icao": "RJOI",
      "iata": "IWK",
      "name": "Iwakuni Marine Corps Air Station",
      "city": "Iwakuni",
      "state": "Yamaguchi",
      "country": "JP",
      "elevation": 7,
      "lat": 34.143901825,
      "lon": 132.2359924316,
      "tz": "Asia\/Tokyo"
  },
  "RJOK": {
      "icao": "RJOK",
      "iata": "KCZ",
      "name": "Kochi Ryoma Airport",
      "city": "Nankoku",
      "state": "Kochi",
      "country": "JP",
      "elevation": 42,
      "lat": 33.5461006165,
      "lon": 133.669006348,
      "tz": "Asia\/Tokyo"
  },
  "RJOM": {
      "icao": "RJOM",
      "iata": "MYJ",
      "name": "Matsuyama Airport",
      "city": "Matsuyama",
      "state": "Ehime",
      "country": "JP",
      "elevation": 25,
      "lat": 33.8272018433,
      "lon": 132.6999969482,
      "tz": "Asia\/Tokyo"
  },
  "RJOO": {
      "icao": "RJOO",
      "iata": "ITM",
      "name": "Osaka International Airport",
      "city": "Osaka",
      "state": "Hyogo",
      "country": "JP",
      "elevation": 50,
      "lat": 34.7854995728,
      "lon": 135.43800354,
      "tz": "Asia\/Tokyo"
  },
  "RJOR": {
      "icao": "RJOR",
      "iata": "TTJ",
      "name": "Tottori Airport",
      "city": "Tottori",
      "state": "Tottori",
      "country": "JP",
      "elevation": 65,
      "lat": 35.5301017761,
      "lon": 134.167007446,
      "tz": "Asia\/Tokyo"
  },
  "RJOS": {
      "icao": "RJOS",
      "iata": "TKS",
      "name": "Tokushima Airport",
      "city": "Tokushima",
      "state": "Tokushima",
      "country": "JP",
      "elevation": 26,
      "lat": 34.1328010559,
      "lon": 134.606994629,
      "tz": "Asia\/Tokyo"
  },
  "RJOT": {
      "icao": "RJOT",
      "iata": "TAK",
      "name": "Takamatsu Airport",
      "city": "Takamatsu",
      "state": "Kagawa",
      "country": "JP",
      "elevation": 607,
      "lat": 34.2141990662,
      "lon": 134.01600647,
      "tz": "Asia\/Tokyo"
  },
  "RJOW": {
      "icao": "RJOW",
      "iata": "IWJ",
      "name": "Iwami Airport",
      "city": "Masuda",
      "state": "Shimane",
      "country": "JP",
      "elevation": 184,
      "lat": 34.676399231,
      "lon": 131.789993286,
      "tz": "Asia\/Tokyo"
  },
  "RJOY": {
      "icao": "RJOY",
      "iata": "",
      "name": "Yao Airport",
      "city": "Yao",
      "state": "Osaka",
      "country": "JP",
      "elevation": 39,
      "lat": 34.5962982178,
      "lon": 135.6029968262,
      "tz": "Asia\/Tokyo"
  },
  "RJOZ": {
      "icao": "RJOZ",
      "iata": "",
      "name": "Ozuki Airport",
      "city": "",
      "state": "Yamaguchi",
      "country": "JP",
      "elevation": 13,
      "lat": 34.04529953,
      "lon": 131.0520019531,
      "tz": "Asia\/Tokyo"
  },
  "RJSA": {
      "icao": "RJSA",
      "iata": "AOJ",
      "name": "Aomori Airport",
      "city": "Aomori",
      "state": "Aomori",
      "country": "JP",
      "elevation": 664,
      "lat": 40.7346992493,
      "lon": 140.6909942627,
      "tz": "Asia\/Tokyo"
  },
  "RJSC": {
      "icao": "RJSC",
      "iata": "GAJ",
      "name": "Yamagata Airport",
      "city": "Yamagata",
      "state": "Yamagata",
      "country": "JP",
      "elevation": 353,
      "lat": 38.4118995667,
      "lon": 140.371002197,
      "tz": "Asia\/Tokyo"
  },
  "RJSD": {
      "icao": "RJSD",
      "iata": "SDS",
      "name": "Sado Airport",
      "city": "",
      "state": "Niigata",
      "country": "JP",
      "elevation": 88,
      "lat": 38.0601997375,
      "lon": 138.4140014648,
      "tz": "Asia\/Tokyo"
  },
  "RJSF": {
      "icao": "RJSF",
      "iata": "FKS",
      "name": "Fukushima Airport",
      "city": "Sukagawa",
      "state": "Fukushima",
      "country": "JP",
      "elevation": 1221,
      "lat": 37.2274017334,
      "lon": 140.4309997559,
      "tz": "Asia\/Tokyo"
  },
  "RJSH": {
      "icao": "RJSH",
      "iata": "HHE",
      "name": "Hachinohe Airport",
      "city": "",
      "state": "Aomori",
      "country": "JP",
      "elevation": 152,
      "lat": 40.5564002991,
      "lon": 141.466003418,
      "tz": "Asia\/Tokyo"
  },
  "RJSI": {
      "icao": "RJSI",
      "iata": "HNA",
      "name": "Hanamaki Airport",
      "city": "",
      "state": "Iwate",
      "country": "JP",
      "elevation": 297,
      "lat": 39.4286003113,
      "lon": 141.1349945068,
      "tz": "Asia\/Tokyo"
  },
  "RJSK": {
      "icao": "RJSK",
      "iata": "AXT",
      "name": "Akita Airport",
      "city": "Akita",
      "state": "Akita",
      "country": "JP",
      "elevation": 313,
      "lat": 39.6156005859,
      "lon": 140.2189941406,
      "tz": "Asia\/Tokyo"
  },
  "RJSM": {
      "icao": "RJSM",
      "iata": "MSJ",
      "name": "Misawa Air Base",
      "city": "Misawa",
      "state": "Aomori",
      "country": "JP",
      "elevation": 119,
      "lat": 40.7032012939,
      "lon": 141.367996216,
      "tz": "Asia\/Tokyo"
  },
  "RJSN": {
      "icao": "RJSN",
      "iata": "KIJ",
      "name": "Niigata Airport",
      "city": "Niigata",
      "state": "Niigata",
      "country": "JP",
      "elevation": 29,
      "lat": 37.9558982849,
      "lon": 139.121002197,
      "tz": "Asia\/Tokyo"
  },
  "RJSO": {
      "icao": "RJSO",
      "iata": "",
      "name": "JASDF Airport",
      "city": "Ominato",
      "state": "Aomori",
      "country": "JP",
      "elevation": 24,
      "lat": 41.233001709,
      "lon": 141.1329956055,
      "tz": "Asia\/Tokyo"
  },
  "RJSR": {
      "icao": "RJSR",
      "iata": "ONJ",
      "name": "Odate Noshiro Airport",
      "city": "Odate",
      "state": "Akita",
      "country": "JP",
      "elevation": 292,
      "lat": 40.1918983459,
      "lon": 140.371002197,
      "tz": "Asia\/Tokyo"
  },
  "RJSS": {
      "icao": "RJSS",
      "iata": "SDJ",
      "name": "Sendai Airport",
      "city": "Sendai",
      "state": "Miyagi",
      "country": "JP",
      "elevation": 15,
      "lat": 38.1397018433,
      "lon": 140.917007446,
      "tz": "Asia\/Tokyo"
  },
  "RJST": {
      "icao": "RJST",
      "iata": "",
      "name": "Matsushima Air Base",
      "city": "Ishinomaki",
      "state": "Miyagi",
      "country": "JP",
      "elevation": 7,
      "lat": 38.4048995972,
      "lon": 141.220001221,
      "tz": "Asia\/Tokyo"
  },
  "RJSU": {
      "icao": "RJSU",
      "iata": "",
      "name": "Sendai Kasuminom Airport",
      "city": "",
      "state": "Miyagi",
      "country": "JP",
      "elevation": 20,
      "lat": 38.2355995178,
      "lon": 140.9230041504,
      "tz": "Asia\/Tokyo"
  },
  "RJSY": {
      "icao": "RJSY",
      "iata": "SYO",
      "name": "Shonai Airport",
      "city": "Shonai",
      "state": "Yamagata",
      "country": "JP",
      "elevation": 86,
      "lat": 38.8121986389,
      "lon": 139.787002563,
      "tz": "Asia\/Tokyo"
  },
  "RJTA": {
      "icao": "RJTA",
      "iata": "NJA",
      "name": "Atsugi Naval Air Facility",
      "city": "",
      "state": "Kanagawa",
      "country": "JP",
      "elevation": 205,
      "lat": 35.4546012878,
      "lon": 139.4499969482,
      "tz": "Asia\/Tokyo"
  },
  "RJTC": {
      "icao": "RJTC",
      "iata": "",
      "name": "Tachikawa Airfield",
      "city": "Tokyo",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 312,
      "lat": 35.7108001709,
      "lon": 139.4029998779,
      "tz": "Asia\/Tokyo"
  },
  "RJTE": {
      "icao": "RJTE",
      "iata": "",
      "name": "Tateyama Airport",
      "city": "",
      "state": "Chiba",
      "country": "JP",
      "elevation": 10,
      "lat": 34.9870986938,
      "lon": 139.828994751,
      "tz": "Asia\/Tokyo"
  },
  "RJTF": {
      "icao": "RJTF",
      "iata": "",
      "name": "Chofu Airport",
      "city": "Tokyo",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 141,
      "lat": 35.6716995239,
      "lon": 139.5279998779,
      "tz": "Asia\/Tokyo"
  },
  "RJTH": {
      "icao": "RJTH",
      "iata": "HAC",
      "name": "Hachijojima Airport",
      "city": "Hachijojima",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 303,
      "lat": 33.1150016785,
      "lon": 139.785995483,
      "tz": "Asia\/Tokyo"
  },
  "RJTJ": {
      "icao": "RJTJ",
      "iata": "",
      "name": "Iruma Air Base",
      "city": "Tokyo",
      "state": "Saitama",
      "country": "JP",
      "elevation": 295,
      "lat": 35.8418998718,
      "lon": 139.4109954834,
      "tz": "Asia\/Tokyo"
  },
  "RJTK": {
      "icao": "RJTK",
      "iata": "",
      "name": "Kisarazu Airport",
      "city": "",
      "state": "Chiba",
      "country": "JP",
      "elevation": 10,
      "lat": 35.3983001709,
      "lon": 139.9100036621,
      "tz": "Asia\/Tokyo"
  },
  "RJTL": {
      "icao": "RJTL",
      "iata": "",
      "name": "Shimofusa Airport",
      "city": "",
      "state": "Chiba",
      "country": "JP",
      "elevation": 98,
      "lat": 35.7989006042,
      "lon": 140.0110015869,
      "tz": "Asia\/Tokyo"
  },
  "RJTO": {
      "icao": "RJTO",
      "iata": "OIM",
      "name": "Oshima Airport",
      "city": "Izu Oshima",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 130,
      "lat": 34.7820014954,
      "lon": 139.36000061,
      "tz": "Asia\/Tokyo"
  },
  "RJTQ": {
      "icao": "RJTQ",
      "iata": "MYE",
      "name": "Miyakejima Airport",
      "city": "Miyakejima",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 67,
      "lat": 34.073600769,
      "lon": 139.559997559,
      "tz": "Asia\/Tokyo"
  },
  "RJTR": {
      "icao": "RJTR",
      "iata": "",
      "name": "Kastner Army Air Field",
      "city": "",
      "state": "Kanagawa",
      "country": "JP",
      "elevation": 360,
      "lat": 35.5138015747,
      "lon": 139.3939971924,
      "tz": "Asia\/Tokyo"
  },
  "RJTT": {
      "icao": "RJTT",
      "iata": "HND",
      "name": "Tokyo International Airport",
      "city": "Tokyo",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 35,
      "lat": 35.5522994995,
      "lon": 139.7799987793,
      "tz": "Asia\/Tokyo"
  },
  "RJTU": {
      "icao": "RJTU",
      "iata": "QUT",
      "name": "Utsunomiya Airport",
      "city": "",
      "state": "Tochigi",
      "country": "JP",
      "elevation": 334,
      "lat": 36.5144996643,
      "lon": 139.8710021973,
      "tz": "Asia\/Tokyo"
  },
  "RJTY": {
      "icao": "RJTY",
      "iata": "OKO",
      "name": "Yokota Air Base",
      "city": "Fussa",
      "state": "Tokyo",
      "country": "JP",
      "elevation": 463,
      "lat": 35.748500824,
      "lon": 139.3480072021,
      "tz": "Asia\/Tokyo"
  },
  "RK13": {
      "icao": "RK13",
      "iata": "",
      "name": "G 404 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 655,
      "lat": 38.0875015259,
      "lon": 127.9869995117,
      "tz": "Asia\/Seoul"
  },
  "RK14": {
      "icao": "RK14",
      "iata": "",
      "name": "G 231 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 464,
      "lat": 38.026599884,
      "lon": 127.3669967651,
      "tz": "Asia\/Seoul"
  },
  "RK15": {
      "icao": "RK15",
      "iata": "",
      "name": "G 237 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 510,
      "lat": 38.1547012329,
      "lon": 127.31300354,
      "tz": "Asia\/Seoul"
  },
  "RK16": {
      "icao": "RK16",
      "iata": "",
      "name": "G 312 Airport",
      "city": "",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 855,
      "lat": 38.0731010437,
      "lon": 127.5230026245,
      "tz": "Asia\/Seoul"
  },
  "RK17": {
      "icao": "RK17",
      "iata": "",
      "name": "G 406 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 656,
      "lat": 38.1398010254,
      "lon": 128.0090026855,
      "tz": "Asia\/Seoul"
  },
  "RK18": {
      "icao": "RK18",
      "iata": "",
      "name": "G 233 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 340,
      "lat": 38.0792007446,
      "lon": 127.2720031738,
      "tz": "Asia\/Seoul"
  },
  "RK19": {
      "icao": "RK19",
      "iata": "",
      "name": "G 314 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 600,
      "lat": 38.1375007629,
      "lon": 127.7419967651,
      "tz": "Asia\/Seoul"
  },
  "RK20": {
      "icao": "RK20",
      "iata": "",
      "name": "G 500 Airport",
      "city": "",
      "state": "Chungcheongnam-do",
      "country": "KR",
      "elevation": 66,
      "lat": 36.1110992432,
      "lon": 127.1039962769,
      "tz": "Asia\/Seoul"
  },
  "RK21": {
      "icao": "RK21",
      "iata": "",
      "name": "G 413 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 23,
      "lat": 38.3827018738,
      "lon": 128.4579925537,
      "tz": "Asia\/Seoul"
  },
  "RK22": {
      "icao": "RK22",
      "iata": "",
      "name": "G 313 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 460,
      "lat": 38.1190986633,
      "lon": 127.6849975586,
      "tz": "Asia\/Seoul"
  },
  "RK24": {
      "icao": "RK24",
      "iata": "",
      "name": "G 522 Airport",
      "city": "",
      "state": "North-Chungcheong",
      "country": "KR",
      "elevation": 546,
      "lat": 36.1801986694,
      "lon": 127.8330001831,
      "tz": "Asia\/Seoul"
  },
  "RK25": {
      "icao": "RK25",
      "iata": "",
      "name": "G 107 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 45,
      "lat": 37.7153015137,
      "lon": 126.5589981079,
      "tz": "Asia\/Seoul"
  },
  "RK27": {
      "icao": "RK27",
      "iata": "",
      "name": "G 218 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 381,
      "lat": 37.8955001831,
      "lon": 126.9720001221,
      "tz": "Asia\/Seoul"
  },
  "RK28": {
      "icao": "RK28",
      "iata": "",
      "name": "G 219 Airport",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 317,
      "lat": 37.9094009399,
      "lon": 127.0080032349,
      "tz": "Asia\/Seoul"
  },
  "RK29": {
      "icao": "RK29",
      "iata": "",
      "name": "G 203 Airport",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 80,
      "lat": 37.643699646,
      "lon": 127.1490020752,
      "tz": "Asia\/Seoul"
  },
  "RK31": {
      "icao": "RK31",
      "iata": "",
      "name": "G 307 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 270,
      "lat": 37.9295005798,
      "lon": 127.7570037842,
      "tz": "Asia\/Seoul"
  },
  "RK32": {
      "icao": "RK32",
      "iata": "",
      "name": "G 420 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 951,
      "lat": 37.956199646,
      "lon": 128.3159942627,
      "tz": "Asia\/Seoul"
  },
  "RK33": {
      "icao": "RK33",
      "iata": "",
      "name": "G 418 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 933,
      "lat": 37.341999054,
      "lon": 128.3860015869,
      "tz": "Asia\/Seoul"
  },
  "RK34": {
      "icao": "RK34",
      "iata": "",
      "name": "G 417 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 1778,
      "lat": 37.6502990723,
      "lon": 128.5700073242,
      "tz": "Asia\/Seoul"
  },
  "RK35": {
      "icao": "RK35",
      "iata": "",
      "name": "G 225 Airport",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 138,
      "lat": 38.018901825,
      "lon": 126.9769973755,
      "tz": "Asia\/Seoul"
  },
  "RK36": {
      "icao": "RK36",
      "iata": "",
      "name": "G 238 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 262,
      "lat": 38.1766014099,
      "lon": 127.1029968262,
      "tz": "Asia\/Seoul"
  },
  "RK37": {
      "icao": "RK37",
      "iata": "",
      "name": "G 227 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 220,
      "lat": 38.0255012512,
      "lon": 127.1119995117,
      "tz": "Asia\/Seoul"
  },
  "RK38": {
      "icao": "RK38",
      "iata": "",
      "name": "G 228 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 228,
      "lat": 38.029800415,
      "lon": 127.1409988403,
      "tz": "Asia\/Seoul"
  },
  "RK39": {
      "icao": "RK39",
      "iata": "",
      "name": "G 239 Airport",
      "city": "",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 630,
      "lat": 38.1991996765,
      "lon": 127.2220001221,
      "tz": "Asia\/Seoul"
  },
  "RK40": {
      "icao": "RK40",
      "iata": "",
      "name": "G 240 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 630,
      "lat": 38.2489013672,
      "lon": 127.3769989014,
      "tz": "Asia\/Seoul"
  },
  "RK41": {
      "icao": "RK41",
      "iata": "",
      "name": "G 317 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 750,
      "lat": 38.215801239,
      "lon": 127.6539993286,
      "tz": "Asia\/Seoul"
  },
  "RK42": {
      "icao": "RK42",
      "iata": "",
      "name": "G 311 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 590,
      "lat": 38.0567016602,
      "lon": 127.7949981689,
      "tz": "Asia\/Seoul"
  },
  "RK43": {
      "icao": "RK43",
      "iata": "",
      "name": "G 414 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 688,
      "lat": 38.1054992676,
      "lon": 128.1999969482,
      "tz": "Asia\/Seoul"
  },
  "RK44": {
      "icao": "RK44",
      "iata": "",
      "name": "G 412 Airport",
      "city": "",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 950,
      "lat": 38.2416992188,
      "lon": 128.2079925537,
      "tz": "Asia\/Seoul"
  },
  "RK48": {
      "icao": "RK48",
      "iata": "",
      "name": "G 419 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 450,
      "lat": 37.7032012939,
      "lon": 127.9049987793,
      "tz": "Asia\/Seoul"
  },
  "RK49": {
      "icao": "RK49",
      "iata": "",
      "name": "G 530 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 91,
      "lat": 36.7560005188,
      "lon": 126.3300018311,
      "tz": "Asia\/Seoul"
  },
  "RK50": {
      "icao": "RK50",
      "iata": "",
      "name": "G 526 Airport",
      "city": "",
      "state": "Chungcheongnam-do",
      "country": "KR",
      "elevation": 197,
      "lat": 36.5838012695,
      "lon": 126.6600036621,
      "tz": "Asia\/Seoul"
  },
  "RK52": {
      "icao": "RK52",
      "iata": "",
      "name": "G 501 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 243,
      "lat": 37.2868003845,
      "lon": 127.2269973755,
      "tz": "Asia\/Seoul"
  },
  "RK58": {
      "icao": "RK58",
      "iata": "",
      "name": "G 806 Airport",
      "city": "",
      "state": "Gyeongsangbuk-do",
      "country": "KR",
      "elevation": 86,
      "lat": 35.8566017151,
      "lon": 129.2120056152,
      "tz": "Asia\/Seoul"
  },
  "RK5M": {
      "icao": "RK5M",
      "iata": "",
      "name": "C 719 Heliport",
      "city": "",
      "state": "Jeollabuk-do",
      "country": "KR",
      "elevation": 132,
      "lat": 35.876108,
      "lon": 127.132562,
      "tz": "Asia\/Seoul"
  },
  "RK60": {
      "icao": "RK60",
      "iata": "",
      "name": "G 712 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 67,
      "lat": 35.3067016602,
      "lon": 126.4950027466,
      "tz": "Asia\/Seoul"
  },
  "RK6D": {
      "icao": "RK6D",
      "iata": "",
      "name": "G 710 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 173,
      "lat": 35.3420982361,
      "lon": 127.0299987793,
      "tz": "Asia\/Seoul"
  },
  "RK6O": {
      "icao": "RK6O",
      "iata": "",
      "name": "G 605 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 925,
      "lat": 37.1628990173,
      "lon": 128.2189941406,
      "tz": "Asia\/Seoul"
  },
  "RK82": {
      "icao": "RK82",
      "iata": "",
      "name": "G 405 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 721,
      "lat": 38.1186981201,
      "lon": 128.0390014648,
      "tz": "Asia\/Seoul"
  },
  "RKD1": {
      "icao": "RKD1",
      "iata": "",
      "name": "G 802 Airport",
      "city": "Masan",
      "state": "",
      "country": "KR",
      "elevation": 110,
      "lat": 35.2582015991,
      "lon": 128.6260070801,
      "tz": "Asia\/Seoul"
  },
  "RKJB": {
      "icao": "RKJB",
      "iata": "MWX",
      "name": "Muan International Airport",
      "city": "",
      "state": "Jeollanam-do",
      "country": "KR",
      "elevation": 0,
      "lat": 34.991406,
      "lon": 126.382814,
      "tz": "Asia\/Seoul"
  },
  "RKJJ": {
      "icao": "RKJJ",
      "iata": "KWJ",
      "name": "Gwangju Airport",
      "city": "Gwangju",
      "state": "Gwangju",
      "country": "KR",
      "elevation": 39,
      "lat": 35.1263999939,
      "lon": 126.808998108,
      "tz": "Asia\/Seoul"
  },
  "RKJK": {
      "icao": "RKJK",
      "iata": "KUV",
      "name": "Kunsan Air Base",
      "city": "Kunsan",
      "state": "Jeollabuk-do",
      "country": "KR",
      "elevation": 29,
      "lat": 35.9038009644,
      "lon": 126.6159973145,
      "tz": "Asia\/Seoul"
  },
  "RKJU": {
      "icao": "RKJU",
      "iata": "CHN",
      "name": "Jeon Ju Airport",
      "city": "Jeon Ju",
      "state": "Jeollabuk-do",
      "country": "KR",
      "elevation": 96,
      "lat": 35.8783988953,
      "lon": 127.1200027466,
      "tz": "Asia\/Seoul"
  },
  "RKJY": {
      "icao": "RKJY",
      "iata": "RSU",
      "name": "Yeosu Airport",
      "city": "Yeosu",
      "state": "Jeollanam-do",
      "country": "KR",
      "elevation": 53,
      "lat": 34.842300415,
      "lon": 127.6169967651,
      "tz": "Asia\/Seoul"
  },
  "RKNC": {
      "icao": "RKNC",
      "iata": "QUN",
      "name": "A-306 Airport",
      "city": "Chun Chon City",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 245,
      "lat": 37.8838005066,
      "lon": 127.7180023193,
      "tz": "Asia\/Seoul"
  },
  "RKND": {
      "icao": "RKND",
      "iata": "SHO",
      "name": "Sokcho Airport",
      "city": "",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 92,
      "lat": 38.1426010132,
      "lon": 128.5989990234,
      "tz": "Asia\/Seoul"
  },
  "RKNN": {
      "icao": "RKNN",
      "iata": "KAG",
      "name": "Gangneung Airport",
      "city": "",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 35,
      "lat": 37.7536010742,
      "lon": 128.9440002441,
      "tz": "Asia\/Seoul"
  },
  "RKNW": {
      "icao": "RKNW",
      "iata": "WJU",
      "name": "Wonju Airport",
      "city": "Wonju",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 329,
      "lat": 37.4380989075,
      "lon": 127.959999084,
      "tz": "Asia\/Seoul"
  },
  "RKNY": {
      "icao": "RKNY",
      "iata": "YNY",
      "name": "Yangyang International Airport",
      "city": "Sokcho \/ Gangneung",
      "state": "Gangwon-do",
      "country": "KR",
      "elevation": 241,
      "lat": 38.0612983704,
      "lon": 128.6690063477,
      "tz": "Asia\/Seoul"
  },
  "RKPC": {
      "icao": "RKPC",
      "iata": "CJU",
      "name": "Jeju International Airport",
      "city": "Jeju City",
      "state": "Jeju-do",
      "country": "KR",
      "elevation": 118,
      "lat": 33.5112991333,
      "lon": 126.4929962158,
      "tz": "Asia\/Seoul"
  },
  "RKPD": {
      "icao": "RKPD",
      "iata": "JDG",
      "name": "Jeongseok Airport",
      "city": "",
      "state": "Jeju-do",
      "country": "KR",
      "elevation": 1171,
      "lat": 33.3996009827,
      "lon": 126.7119979858,
      "tz": "Asia\/Seoul"
  },
  "RKPE": {
      "icao": "RKPE",
      "iata": "CHF",
      "name": "Jinhae Airport",
      "city": "Jinhae",
      "state": "Gyeongsangnam-do",
      "country": "KR",
      "elevation": 8,
      "lat": 35.1412010193,
      "lon": 128.6959991455,
      "tz": "Asia\/Seoul"
  },
  "RKPK": {
      "icao": "RKPK",
      "iata": "PUS",
      "name": "Gimhae International Airport",
      "city": "Busan",
      "state": "Busan",
      "country": "KR",
      "elevation": 6,
      "lat": 35.1795005798,
      "lon": 128.93800354,
      "tz": "Asia\/Seoul"
  },
  "RKPS": {
      "icao": "RKPS",
      "iata": "HIN",
      "name": "Sacheon Air Base",
      "city": "Sacheon",
      "state": "Gyeongsangnam-do",
      "country": "KR",
      "elevation": 25,
      "lat": 35.0885009766,
      "lon": 128.0700073242,
      "tz": "Asia\/Seoul"
  },
  "RKPU": {
      "icao": "RKPU",
      "iata": "USN",
      "name": "Ulsan Airport",
      "city": "Ulsan",
      "state": "Ulsan",
      "country": "KR",
      "elevation": 45,
      "lat": 35.59349823,
      "lon": 129.352005005,
      "tz": "Asia\/Seoul"
  },
  "RKRA": {
      "icao": "RKRA",
      "iata": "",
      "name": "G 222 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 287,
      "lat": 37.8303985596,
      "lon": 126.9899978638,
      "tz": "Asia\/Seoul"
  },
  "RKRB": {
      "icao": "RKRB",
      "iata": "",
      "name": "G 103 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 92,
      "lat": 37.474899292,
      "lon": 126.7470016479,
      "tz": "Asia\/Seoul"
  },
  "RKRG": {
      "icao": "RKRG",
      "iata": "",
      "name": "G 301 Airport",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 230,
      "lat": 37.5003013611,
      "lon": 127.6190032959,
      "tz": "Asia\/Seoul"
  },
  "RKRK": {
      "icao": "RKRK",
      "iata": "",
      "name": "G 213 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 400,
      "lat": 37.8116989136,
      "lon": 127.3570022583,
      "tz": "Asia\/Seoul"
  },
  "RKRN": {
      "icao": "RKRN",
      "iata": "",
      "name": "G 510 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 255,
      "lat": 37.1968994141,
      "lon": 127.4749984741,
      "tz": "Asia\/Seoul"
  },
  "RKRO": {
      "icao": "RKRO",
      "iata": "",
      "name": "G 217 Airport",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 328,
      "lat": 37.8665008545,
      "lon": 127.1750030518,
      "tz": "Asia\/Seoul"
  },
  "RKRP": {
      "icao": "RKRP",
      "iata": "",
      "name": "G 110 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 65,
      "lat": 37.7625999451,
      "lon": 126.7929992676,
      "tz": "Asia\/Seoul"
  },
  "RKSG": {
      "icao": "RKSG",
      "iata": "",
      "name": "A 511 Airport",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 51,
      "lat": 36.9622001648,
      "lon": 127.03099823,
      "tz": "Asia\/Seoul"
  },
  "RKSI": {
      "icao": "RKSI",
      "iata": "ICN",
      "name": "Incheon International Airport",
      "city": "Seoul",
      "state": "Incheon",
      "country": "KR",
      "elevation": 23,
      "lat": 37.4691009521,
      "lon": 126.4509963989,
      "tz": "Asia\/Seoul"
  },
  "RKSM": {
      "icao": "RKSM",
      "iata": "SSN",
      "name": "Seoul Air Base",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 92,
      "lat": 37.4458007813,
      "lon": 127.1139984131,
      "tz": "Asia\/Seoul"
  },
  "RKSO": {
      "icao": "RKSO",
      "iata": "OSN",
      "name": "Osan Air Base",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 38,
      "lat": 37.0905990601,
      "lon": 127.0299987793,
      "tz": "Asia\/Seoul"
  },
  "RKSS": {
      "icao": "RKSS",
      "iata": "GMP",
      "name": "Gimpo International Airport",
      "city": "Seoul",
      "state": "Seoul",
      "country": "KR",
      "elevation": 58,
      "lat": 37.5583000183,
      "lon": 126.791000366,
      "tz": "Asia\/Seoul"
  },
  "RKSW": {
      "icao": "RKSW",
      "iata": "SWU",
      "name": "Suwon Airport",
      "city": "",
      "state": "Gyeonggi-do",
      "country": "KR",
      "elevation": 88,
      "lat": 37.2393989563,
      "lon": 127.0070037842,
      "tz": "Asia\/Seoul"
  },
  "RKTE": {
      "icao": "RKTE",
      "iata": "",
      "name": "Seongmu Airport",
      "city": "",
      "state": "North-Chungcheong",
      "country": "KR",
      "elevation": 258,
      "lat": 36.5681991577,
      "lon": 127.5,
      "tz": "Asia\/Seoul"
  },
  "RKTH": {
      "icao": "RKTH",
      "iata": "KPO",
      "name": "Pohang Airport",
      "city": "Pohang",
      "state": "Gyeongsangbuk-do",
      "country": "KR",
      "elevation": 70,
      "lat": 35.9878997803,
      "lon": 129.419998169,
      "tz": "Asia\/Seoul"
  },
  "RKTI": {
      "icao": "RKTI",
      "iata": "JWO",
      "name": "Jungwon Air Base",
      "city": "",
      "state": "North-Chungcheong",
      "country": "KR",
      "elevation": 281,
      "lat": 37.0299987793,
      "lon": 127.8850021362,
      "tz": "Asia\/Seoul"
  },
  "RKTN": {
      "icao": "RKTN",
      "iata": "TAE",
      "name": "Daegu Airport",
      "city": "Daegu",
      "state": "Daegu",
      "country": "KR",
      "elevation": 116,
      "lat": 35.8941001892,
      "lon": 128.658996582,
      "tz": "Asia\/Seoul"
  },
  "RKTP": {
      "icao": "RKTP",
      "iata": "HMY",
      "name": "Seosan Air Base",
      "city": "Seosan",
      "state": "Chungcheongnam-do",
      "country": "KR",
      "elevation": 39,
      "lat": 36.7039985657,
      "lon": 126.486000061,
      "tz": "Asia\/Seoul"
  },
  "RKTU": {
      "icao": "RKTU",
      "iata": "CJJ",
      "name": "Cheongju International Airport",
      "city": "Cheongju",
      "state": "North-Chungcheong",
      "country": "KR",
      "elevation": 191,
      "lat": 36.7165985107,
      "lon": 127.499000549,
      "tz": "Asia\/Seoul"
  },
  "RKTY": {
      "icao": "RKTY",
      "iata": "YEC",
      "name": "Yecheon Airport",
      "city": "",
      "state": "Gyeongsangbuk-do",
      "country": "KR",
      "elevation": 354,
      "lat": 36.6319007874,
      "lon": 128.3549957275,
      "tz": "Asia\/Seoul"
  },
  "RKUC": {
      "icao": "RKUC",
      "iata": "",
      "name": "G 505 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 82,
      "lat": 36.5716018677,
      "lon": 127.2949981689,
      "tz": "Asia\/Seoul"
  },
  "RKUL": {
      "icao": "RKUL",
      "iata": "",
      "name": "G 536 Airport",
      "city": "",
      "state": "",
      "country": "KR",
      "elevation": 104,
      "lat": 36.2694015503,
      "lon": 127.1139984131,
      "tz": "Asia\/Seoul"
  },
  "ROAH": {
      "icao": "ROAH",
      "iata": "OKA",
      "name": "Naha Airport",
      "city": "Naha",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 12,
      "lat": 26.1958007812,
      "lon": 127.646003723,
      "tz": "Asia\/Tokyo"
  },
  "RODE": {
      "icao": "RODE",
      "iata": "",
      "name": "Ie Shima Auxiliary Air Base",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 184,
      "lat": 26.7257575989,
      "lon": 127.7649002075,
      "tz": "Asia\/Tokyo"
  },
  "RODN": {
      "icao": "RODN",
      "iata": "DNA",
      "name": "Kadena Air Base",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 143,
      "lat": 26.3556003571,
      "lon": 127.7679977417,
      "tz": "Asia\/Tokyo"
  },
  "ROIG": {
      "icao": "ROIG",
      "iata": "ISG",
      "name": "Ishigaki Airport",
      "city": "Ishigaki",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 93,
      "lat": 24.344499588,
      "lon": 124.18699646,
      "tz": "Asia\/Tokyo"
  },
  "ROKJ": {
      "icao": "ROKJ",
      "iata": "UEO",
      "name": "Kumejima Airport",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 23,
      "lat": 26.3635005951,
      "lon": 126.7139968872,
      "tz": "Asia\/Tokyo"
  },
  "ROKR": {
      "icao": "ROKR",
      "iata": "KJP",
      "name": "Kerama Airport",
      "city": "Kerama",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 156,
      "lat": 26.1683006287,
      "lon": 127.2929992676,
      "tz": "Asia\/Tokyo"
  },
  "ROMD": {
      "icao": "ROMD",
      "iata": "MMD",
      "name": "Minami Daito Airport",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 167,
      "lat": 25.8465003967,
      "lon": 131.2630004883,
      "tz": "Asia\/Tokyo"
  },
  "ROMY": {
      "icao": "ROMY",
      "iata": "MMY",
      "name": "Miyako Airport",
      "city": "Miyako City",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 150,
      "lat": 24.7828006744,
      "lon": 125.294998169,
      "tz": "Asia\/Tokyo"
  },
  "RORA": {
      "icao": "RORA",
      "iata": "AGJ",
      "name": "Aguni Airport",
      "city": "Aguni",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 38,
      "lat": 26.5925006866,
      "lon": 127.2409973145,
      "tz": "Asia\/Tokyo"
  },
  "RORE": {
      "icao": "RORE",
      "iata": "IEJ",
      "name": "Ie Jima Airport",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 246,
      "lat": 26.7220001221,
      "lon": 127.7850036621,
      "tz": "Asia\/Tokyo"
  },
  "RORH": {
      "icao": "RORH",
      "iata": "HTR",
      "name": "Hateruma Airport",
      "city": "Hateruma",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 43,
      "lat": 24.0589008331,
      "lon": 123.8059997559,
      "tz": "Asia\/Tokyo"
  },
  "RORK": {
      "icao": "RORK",
      "iata": "KTD",
      "name": "Kitadaito Airport",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 80,
      "lat": 25.9447002411,
      "lon": 131.3269958496,
      "tz": "Asia\/Tokyo"
  },
  "RORS": {
      "icao": "RORS",
      "iata": "SHI",
      "name": "Shimojishima Airport",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 54,
      "lat": 24.8267002106,
      "lon": 125.1449966431,
      "tz": "Asia\/Tokyo"
  },
  "RORT": {
      "icao": "RORT",
      "iata": "TRA",
      "name": "Tarama Airport",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 36,
      "lat": 24.6539001465,
      "lon": 124.6750030518,
      "tz": "Asia\/Tokyo"
  },
  "RORY": {
      "icao": "RORY",
      "iata": "RNJ",
      "name": "Yoron Airport",
      "city": "",
      "state": "Kagoshima",
      "country": "JP",
      "elevation": 52,
      "lat": 27.0440006256,
      "lon": 128.4019927979,
      "tz": "Asia\/Tokyo"
  },
  "ROTM": {
      "icao": "ROTM",
      "iata": "",
      "name": "Futenma Marine Corps Air Station",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 247,
      "lat": 26.2742996216,
      "lon": 127.7559967041,
      "tz": "Asia\/Tokyo"
  },
  "ROYN": {
      "icao": "ROYN",
      "iata": "OGN",
      "name": "Yonaguni Airport",
      "city": "",
      "state": "Okinawa",
      "country": "JP",
      "elevation": 70,
      "lat": 24.4668998718,
      "lon": 122.9779968262,
      "tz": "Asia\/Tokyo"
  },
  "RP12": {
      "icao": "RP12",
      "iata": "",
      "name": "Pagbilao Grande Island Airport",
      "city": "",
      "state": "Calabarzon",
      "country": "PH",
      "elevation": 13,
      "lat": 13.9001998901,
      "lon": 121.7440032959,
      "tz": "Asia\/Manila"
  },
  "RP13": {
      "icao": "RP13",
      "iata": "",
      "name": "Nonoc Airport",
      "city": "",
      "state": "Caraga",
      "country": "PH",
      "elevation": 10,
      "lat": 9.8092603683,
      "lon": 125.5930023193,
      "tz": "Asia\/Manila"
  },
  "RP15": {
      "icao": "RP15",
      "iata": "",
      "name": "Pasar Airport",
      "city": "",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 16,
      "lat": 10.9120998383,
      "lon": 124.43699646,
      "tz": "Asia\/Manila"
  },
  "RP16": {
      "icao": "RP16",
      "iata": "",
      "name": "Seahawk Lz Airport",
      "city": "",
      "state": "ARMM",
      "country": "PH",
      "elevation": 1011,
      "lat": 6.6541700363,
      "lon": 121.9800033569,
      "tz": "Asia\/Manila"
  },
  "RP17": {
      "icao": "RP17",
      "iata": "",
      "name": "Malita Airport",
      "city": "",
      "state": "Davao",
      "country": "PH",
      "elevation": 16,
      "lat": 6.4022798538,
      "lon": 125.6190032959,
      "tz": "Asia\/Manila"
  },
  "RPLA": {
      "icao": "RPLA",
      "iata": "",
      "name": "Pinamalayan Airport",
      "city": "",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 92,
      "lat": 12.9857997894,
      "lon": 121.4260025024,
      "tz": "Asia\/Manila"
  },
  "RPLB": {
      "icao": "RPLB",
      "iata": "SFS",
      "name": "Subic Bay International Airport",
      "city": "Olongapo City",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 64,
      "lat": 14.7944002151,
      "lon": 120.2710037231,
      "tz": "Asia\/Manila"
  },
  "RPLC": {
      "icao": "RPLC",
      "iata": "CRK",
      "name": "Diosdado Macapagal International Airport",
      "city": "Angeles City",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 484,
      "lat": 15.1859998703,
      "lon": 120.559997559,
      "tz": "Asia\/Manila"
  },
  "RPLI": {
      "icao": "RPLI",
      "iata": "LAO",
      "name": "Laoag International Airport",
      "city": "Laoag City",
      "state": "Ilocos",
      "country": "PH",
      "elevation": 25,
      "lat": 18.1781005859,
      "lon": 120.5319976807,
      "tz": "Asia\/Manila"
  },
  "RPLJ": {
      "icao": "RPLJ",
      "iata": "",
      "name": "Jomalig Island Airport",
      "city": "Jomalig",
      "state": "Calabarzon",
      "country": "PH",
      "elevation": 36,
      "lat": 14.704000473,
      "lon": 122.3310012817,
      "tz": "Asia\/Manila"
  },
  "RPLL": {
      "icao": "RPLL",
      "iata": "MNL",
      "name": "Ninoy Aquino International Airport",
      "city": "Manila",
      "state": "",
      "country": "PH",
      "elevation": 75,
      "lat": 14.508600235,
      "lon": 121.019996643,
      "tz": "Asia\/Manila"
  },
  "RPLN": {
      "icao": "RPLN",
      "iata": "",
      "name": "Palanan Municipal Airport",
      "city": "Palanan",
      "state": "Cagayan-Valley",
      "country": "PH",
      "elevation": 52,
      "lat": 17.0656668941,
      "lon": 122.427670956,
      "tz": "Asia\/Manila"
  },
  "RPLO": {
      "icao": "RPLO",
      "iata": "CYU",
      "name": "Cuyo Airport",
      "city": "Cuyo",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 0,
      "lat": 10.8580999374,
      "lon": 121.0690002441,
      "tz": "Asia\/Manila"
  },
  "RPLP": {
      "icao": "RPLP",
      "iata": "LGP",
      "name": "Legazpi City International Airport",
      "city": "Legazpi City",
      "state": "Bicol",
      "country": "PH",
      "elevation": 66,
      "lat": 13.1575,
      "lon": 123.735,
      "tz": "Asia\/Manila"
  },
  "RPLR": {
      "icao": "RPLR",
      "iata": "",
      "name": "Carmen Rosales Airstrip",
      "city": "Carmen",
      "state": "Ilocos",
      "country": "PH",
      "elevation": 0,
      "lat": 15.8843421975,
      "lon": 120.601944923,
      "tz": "Asia\/Manila"
  },
  "RPLS": {
      "icao": "RPLS",
      "iata": "NSP",
      "name": "Sangley Point Air Base",
      "city": "Cavite City",
      "state": "Calabarzon",
      "country": "PH",
      "elevation": 8,
      "lat": 14.4954004288,
      "lon": 120.9039993286,
      "tz": "Asia\/Manila"
  },
  "RPLT": {
      "icao": "RPLT",
      "iata": "",
      "name": "Itbayat Airport",
      "city": "Itbayat",
      "state": "ARMM",
      "country": "PH",
      "elevation": 328,
      "lat": 20.716999054,
      "lon": 121.8170013428,
      "tz": "Asia\/Manila"
  },
  "RPLU": {
      "icao": "RPLU",
      "iata": "LBX",
      "name": "Lubang Airport",
      "city": "",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 43,
      "lat": 13.8554000854,
      "lon": 120.1050033569,
      "tz": "Asia\/Manila"
  },
  "RPLV": {
      "icao": "RPLV",
      "iata": "",
      "name": "Fort Magsaysay Airport",
      "city": "",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 200,
      "lat": 15.4347000122,
      "lon": 121.091003418,
      "tz": "Asia\/Manila"
  },
  "RPLX": {
      "icao": "RPLX",
      "iata": "",
      "name": "Corregidor Airport",
      "city": "Corregidor",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 102,
      "lat": 14.3913002014,
      "lon": 120.6070022583,
      "tz": "Asia\/Manila"
  },
  "RPLY": {
      "icao": "RPLY",
      "iata": "",
      "name": "Alabat Island Airport",
      "city": "Alabat Island",
      "state": "Calabarzon",
      "country": "PH",
      "elevation": 6,
      "lat": 14.232000351,
      "lon": 121.9290008545,
      "tz": "Asia\/Manila"
  },
  "RPLZ": {
      "icao": "RPLZ",
      "iata": "",
      "name": "Sorsogon",
      "city": "",
      "state": "Bicol",
      "country": "PH",
      "elevation": 0,
      "lat": 12.998588562,
      "lon": 124.018081665,
      "tz": "Asia\/Manila"
  },
  "RPMA": {
      "icao": "RPMA",
      "iata": "AAV",
      "name": "Allah Valley Airport",
      "city": "Surallah",
      "state": "Soccsksargen",
      "country": "PH",
      "elevation": 659,
      "lat": 6.3668198586,
      "lon": 124.7509994507,
      "tz": "Asia\/Manila"
  },
  "RPMB": {
      "icao": "RPMB",
      "iata": "GES",
      "name": "General Santos International Airport",
      "city": "General Santos City",
      "state": "Soccsksargen",
      "country": "PH",
      "elevation": 28,
      "lat": 6.1064400673,
      "lon": 125.2350006104,
      "tz": "Asia\/Manila"
  },
  "RPMC": {
      "icao": "RPMC",
      "iata": "CBO",
      "name": "Awang Airport",
      "city": "Cotabato City",
      "state": "ARMM",
      "country": "PH",
      "elevation": 189,
      "lat": 7.1652398109,
      "lon": 124.2099990845,
      "tz": "Asia\/Manila"
  },
  "RPMD": {
      "icao": "RPMD",
      "iata": "DVO",
      "name": "Francisco Bangoy International Airport",
      "city": "Davao City",
      "state": "Davao",
      "country": "PH",
      "elevation": 96,
      "lat": 7.1255202293,
      "lon": 125.6460037231,
      "tz": "Asia\/Manila"
  },
  "RPME": {
      "icao": "RPME",
      "iata": "BXU",
      "name": "Bancasi Airport",
      "city": "Butuan City",
      "state": "Caraga",
      "country": "PH",
      "elevation": 141,
      "lat": 8.9513196945,
      "lon": 125.4779968262,
      "tz": "Asia\/Manila"
  },
  "RPMF": {
      "icao": "RPMF",
      "iata": "BPH",
      "name": "Bislig Airport",
      "city": "",
      "state": "Caraga",
      "country": "PH",
      "elevation": 12,
      "lat": 8.1959495544,
      "lon": 126.3219985962,
      "tz": "Asia\/Manila"
  },
  "RPMG": {
      "icao": "RPMG",
      "iata": "DPL",
      "name": "Dipolog Airport",
      "city": "Dipolog City",
      "state": "Zamboanga-Peninsula",
      "country": "PH",
      "elevation": 12,
      "lat": 8.6019834988,
      "lon": 123.341875076,
      "tz": "Asia\/Manila"
  },
  "RPMH": {
      "icao": "RPMH",
      "iata": "CGM",
      "name": "Camiguin Airport",
      "city": "",
      "state": "Northern-Mindanao",
      "country": "PH",
      "elevation": 53,
      "lat": 9.2535200119,
      "lon": 124.7070007324,
      "tz": "Asia\/Manila"
  },
  "RPMI": {
      "icao": "RPMI",
      "iata": "IGN",
      "name": "Iligan Airport",
      "city": "",
      "state": "",
      "country": "PH",
      "elevation": 1300,
      "lat": 8.130490303,
      "lon": 124.2149963379,
      "tz": "Asia\/Manila"
  },
  "RPMJ": {
      "icao": "RPMJ",
      "iata": "JOL",
      "name": "Jolo Airport",
      "city": "",
      "state": "ARMM",
      "country": "PH",
      "elevation": 118,
      "lat": 6.0536699295,
      "lon": 121.0110015869,
      "tz": "Asia\/Manila"
  },
  "RPML": {
      "icao": "RPML",
      "iata": "CGY",
      "name": "Cagayan De Oro Airport",
      "city": "Cagayan De Oro City",
      "state": "Northern-Mindanao",
      "country": "PH",
      "elevation": 601,
      "lat": 8.4156198502,
      "lon": 124.611000061,
      "tz": "Asia\/Manila"
  },
  "RPMM": {
      "icao": "RPMM",
      "iata": "MLP",
      "name": "Malabang Airport",
      "city": "Malabang",
      "state": "ARMM",
      "country": "PH",
      "elevation": 27,
      "lat": 7.6172199249,
      "lon": 124.0589981079,
      "tz": "Asia\/Manila"
  },
  "RPMN": {
      "icao": "RPMN",
      "iata": "SGS",
      "name": "Sanga Sanga Airport",
      "city": "",
      "state": "ARMM",
      "country": "PH",
      "elevation": 15,
      "lat": 5.0469899178,
      "lon": 119.7429962158,
      "tz": "Asia\/Manila"
  },
  "RPMO": {
      "icao": "RPMO",
      "iata": "OZC",
      "name": "Labo Airport",
      "city": "Ozamiz City",
      "state": "Northern-Mindanao",
      "country": "PH",
      "elevation": 75,
      "lat": 8.1785097122,
      "lon": 123.8420028687,
      "tz": "Asia\/Manila"
  },
  "RPMP": {
      "icao": "RPMP",
      "iata": "PAG",
      "name": "Pagadian Airport",
      "city": "Pagadian City",
      "state": "Zamboanga-Peninsula",
      "country": "PH",
      "elevation": 5,
      "lat": 7.8307314479,
      "lon": 123.461179733,
      "tz": "Asia\/Manila"
  },
  "RPMQ": {
      "icao": "RPMQ",
      "iata": "MXI",
      "name": "Mati National Airport",
      "city": "",
      "state": "Davao",
      "country": "PH",
      "elevation": 156,
      "lat": 6.9493699074,
      "lon": 126.2730026245,
      "tz": "Asia\/Manila"
  },
  "RPMR": {
      "icao": "RPMR",
      "iata": "GES",
      "name": "Tambler Airport",
      "city": "",
      "state": "Soccsksargen",
      "country": "PH",
      "elevation": 505,
      "lat": 6.0580000877,
      "lon": 125.0960006714,
      "tz": "Asia\/Manila"
  },
  "RPMS": {
      "icao": "RPMS",
      "iata": "SUG",
      "name": "Surigao Airport",
      "city": "Surigao City",
      "state": "Caraga",
      "country": "PH",
      "elevation": 20,
      "lat": 9.7558383256,
      "lon": 125.480947495,
      "tz": "Asia\/Manila"
  },
  "RPMU": {
      "icao": "RPMU",
      "iata": "CDY",
      "name": "Cagayan de Sulu Airport",
      "city": "Mapun",
      "state": "ARMM",
      "country": "PH",
      "elevation": 0,
      "lat": 7.013999939,
      "lon": 118.4960021973,
      "tz": "Asia\/Manila"
  },
  "RPMV": {
      "icao": "RPMV",
      "iata": "IPE",
      "name": "Ipil Airport",
      "city": "Ipil",
      "state": "Zamboanga-Peninsula",
      "country": "PH",
      "elevation": 0,
      "lat": 7.7855601311,
      "lon": 122.6019973755,
      "tz": "Asia\/Manila"
  },
  "RPMW": {
      "icao": "RPMW",
      "iata": "TDG",
      "name": "Tandag Airport",
      "city": "",
      "state": "Caraga",
      "country": "PH",
      "elevation": 16,
      "lat": 9.0721101761,
      "lon": 126.1709976196,
      "tz": "Asia\/Manila"
  },
  "RPMY": {
      "icao": "RPMY",
      "iata": "CGY",
      "name": "Malaybalay Airport",
      "city": "Malaybalay City",
      "state": "Northern-Mindanao",
      "country": "PH",
      "elevation": 2051,
      "lat": 8.1416597366,
      "lon": 125.1169967651,
      "tz": "Asia\/Manila"
  },
  "RPMZ": {
      "icao": "RPMZ",
      "iata": "ZAM",
      "name": "Zamboanga International Airport",
      "city": "Zamboanga City",
      "state": "Zamboanga-Peninsula",
      "country": "PH",
      "elevation": 33,
      "lat": 6.9224200249,
      "lon": 122.0599975586,
      "tz": "Asia\/Manila"
  },
  "RPNS": {
      "icao": "RPNS",
      "iata": "IAO",
      "name": "Siargao Airport",
      "city": "Del Carmen",
      "state": "Caraga",
      "country": "PH",
      "elevation": 10,
      "lat": 9.8591003418,
      "lon": 126.013999939,
      "tz": "Asia\/Manila"
  },
  "RPSB": {
      "icao": "RPSB",
      "iata": "",
      "name": "Bantayan Airport",
      "city": "",
      "state": "Central-Visayas",
      "country": "PH",
      "elevation": 0,
      "lat": 11.1624,
      "lon": 123.7848,
      "tz": "Asia\/Manila"
  },
  "RPSD": {
      "icao": "RPSD",
      "iata": "RZP",
      "name": "Cesar Lim Rodriguez Airport",
      "city": "Taytay Airport",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 80,
      "lat": 10.81874,
      "lon": 119.507697,
      "tz": "Asia\/Manila"
  },
  "RPSM": {
      "icao": "RPSM",
      "iata": "",
      "name": "Maasin Airport",
      "city": "",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 328,
      "lat": 10.1872997284,
      "lon": 124.7819976807,
      "tz": "Asia\/Manila"
  },
  "RPUB": {
      "icao": "RPUB",
      "iata": "BAG",
      "name": "Loakan Airport",
      "city": "Baguio City",
      "state": "Cordillera",
      "country": "PH",
      "elevation": 4251,
      "lat": 16.3750991821,
      "lon": 120.6200027466,
      "tz": "Asia\/Manila"
  },
  "RPUD": {
      "icao": "RPUD",
      "iata": "DTE",
      "name": "Daet Airport",
      "city": "Daet",
      "state": "Bicol",
      "country": "PH",
      "elevation": 10,
      "lat": 14.1291999817,
      "lon": 122.9800033569,
      "tz": "Asia\/Manila"
  },
  "RPUE": {
      "icao": "RPUE",
      "iata": "",
      "name": "Lucena Airport",
      "city": "Lucena",
      "state": "Calabarzon",
      "country": "PH",
      "elevation": 39,
      "lat": 13.9289999008,
      "lon": 121.6019973755,
      "tz": "Asia\/Manila"
  },
  "RPUF": {
      "icao": "RPUF",
      "iata": "",
      "name": "Basa Air Base",
      "city": "",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 151,
      "lat": 14.9864997864,
      "lon": 120.4929962158,
      "tz": "Asia\/Manila"
  },
  "RPUG": {
      "icao": "RPUG",
      "iata": "",
      "name": "Lingayen Airport",
      "city": "",
      "state": "Ilocos",
      "country": "PH",
      "elevation": 7,
      "lat": 16.0347995758,
      "lon": 120.2409973145,
      "tz": "Asia\/Manila"
  },
  "RPUH": {
      "icao": "RPUH",
      "iata": "SJI",
      "name": "San Jose Airport",
      "city": "San Jose",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 14,
      "lat": 12.3614997864,
      "lon": 121.04699707,
      "tz": "Asia\/Manila"
  },
  "RPUI": {
      "icao": "RPUI",
      "iata": "",
      "name": "Iba Airport",
      "city": "Iba",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 11,
      "lat": 15.3247556686,
      "lon": 119.969497681,
      "tz": "Asia\/Manila"
  },
  "RPUJ": {
      "icao": "RPUJ",
      "iata": "",
      "name": "Castillejos Airport",
      "city": "Castillejos",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 200,
      "lat": 14.9670000076,
      "lon": 120.1500015259,
      "tz": "Asia\/Manila"
  },
  "RPUK": {
      "icao": "RPUK",
      "iata": "",
      "name": "Calapan Airport",
      "city": "Calapan City",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 11,
      "lat": 13.4169998169,
      "lon": 121.1999969482,
      "tz": "Asia\/Manila"
  },
  "RPUL": {
      "icao": "RPUL",
      "iata": "",
      "name": "Fernando Air Base",
      "city": "",
      "state": "Calabarzon",
      "country": "PH",
      "elevation": 1220,
      "lat": 13.9549999237,
      "lon": 121.125,
      "tz": "Asia\/Manila"
  },
  "RPUM": {
      "icao": "RPUM",
      "iata": "MBO",
      "name": "Mamburao Airport",
      "city": "",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 13,
      "lat": 13.2081003189,
      "lon": 120.6050033569,
      "tz": "Asia\/Manila"
  },
  "RPUN": {
      "icao": "RPUN",
      "iata": "WNP",
      "name": "Naga Airport",
      "city": "Naga",
      "state": "Bicol",
      "country": "PH",
      "elevation": 142,
      "lat": 13.5848999023,
      "lon": 123.2699966431,
      "tz": "Asia\/Manila"
  },
  "RPUO": {
      "icao": "RPUO",
      "iata": "BSO",
      "name": "Basco Airport",
      "city": "Basco",
      "state": "Cagayan-Valley",
      "country": "PH",
      "elevation": 291,
      "lat": 20.4512996674,
      "lon": 121.980003357,
      "tz": "Asia\/Manila"
  },
  "RPUQ": {
      "icao": "RPUQ",
      "iata": "",
      "name": "Vigan Airport",
      "city": "Vigan City",
      "state": "Ilocos",
      "country": "PH",
      "elevation": 16,
      "lat": 17.5552997589,
      "lon": 120.3560028076,
      "tz": "Asia\/Manila"
  },
  "RPUR": {
      "icao": "RPUR",
      "iata": "BQA",
      "name": "Dr.Juan C. Angara Airport",
      "city": "Baler",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 108,
      "lat": 15.7298002243,
      "lon": 121.5,
      "tz": "Asia\/Manila"
  },
  "RPUS": {
      "icao": "RPUS",
      "iata": "SFE",
      "name": "San Fernando Airport",
      "city": "",
      "state": "Ilocos",
      "country": "PH",
      "elevation": 13,
      "lat": 16.5956001282,
      "lon": 120.3030014038,
      "tz": "Asia\/Manila"
  },
  "RPUT": {
      "icao": "RPUT",
      "iata": "TUG",
      "name": "Tuguegarao Airport",
      "city": "Tuguegarao City",
      "state": "Cagayan-Valley",
      "country": "PH",
      "elevation": 70,
      "lat": 17.6433676823,
      "lon": 121.733150482,
      "tz": "Asia\/Manila"
  },
  "RPUU": {
      "icao": "RPUU",
      "iata": "",
      "name": "Bulan Airport",
      "city": "Bulan",
      "state": "Bicol",
      "country": "PH",
      "elevation": 3937,
      "lat": 12.6842002869,
      "lon": 123.8779983521,
      "tz": "Asia\/Manila"
  },
  "RPUV": {
      "icao": "RPUV",
      "iata": "VRC",
      "name": "Virac Airport",
      "city": "Virac",
      "state": "Bicol",
      "country": "PH",
      "elevation": 121,
      "lat": 13.5763998032,
      "lon": 124.2060012817,
      "tz": "Asia\/Manila"
  },
  "RPUW": {
      "icao": "RPUW",
      "iata": "MRQ",
      "name": "Marinduque Airport",
      "city": "Gasan",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 32,
      "lat": 13.361000061,
      "lon": 121.8259963989,
      "tz": "Asia\/Manila"
  },
  "RPUX": {
      "icao": "RPUX",
      "iata": "",
      "name": "Plaridel Airport",
      "city": "Plaridel",
      "state": "Central-Luzon",
      "country": "PH",
      "elevation": 20,
      "lat": 14.8930997849,
      "lon": 120.8519973755,
      "tz": "Asia\/Manila"
  },
  "RPUY": {
      "icao": "RPUY",
      "iata": "CYZ",
      "name": "Cauayan Airport",
      "city": "Cauayan City",
      "state": "Cagayan-Valley",
      "country": "PH",
      "elevation": 200,
      "lat": 16.9298992157,
      "lon": 121.752998352,
      "tz": "Asia\/Manila"
  },
  "RPUZ": {
      "icao": "RPUZ",
      "iata": "",
      "name": "Bagabag Airport",
      "city": "",
      "state": "Cagayan-Valley",
      "country": "PH",
      "elevation": 820,
      "lat": 16.6191997528,
      "lon": 121.2519989014,
      "tz": "Asia\/Manila"
  },
  "RPVA": {
      "icao": "RPVA",
      "iata": "TAC",
      "name": "Daniel Z. Romualdez Airport",
      "city": "Tacloban City",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 10,
      "lat": 11.2276000977,
      "lon": 125.027999878,
      "tz": "Asia\/Manila"
  },
  "RPVB": {
      "icao": "RPVB",
      "iata": "BCD",
      "name": "Bacolod-Silay City International Airport",
      "city": "Bacolod City",
      "state": "Negros-Island-Region",
      "country": "PH",
      "elevation": 0,
      "lat": 10.7763996124,
      "lon": 123.0149993896,
      "tz": "Asia\/Manila"
  },
  "RPVC": {
      "icao": "RPVC",
      "iata": "CYP",
      "name": "Calbayog Airport",
      "city": "Calbayog City",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 12,
      "lat": 12.0726995468,
      "lon": 124.5449981689,
      "tz": "Asia\/Manila"
  },
  "RPVD": {
      "icao": "RPVD",
      "iata": "DGT",
      "name": "Sibulan Airport",
      "city": "Dumaguete City",
      "state": "Central-Visayas",
      "country": "PH",
      "elevation": 15,
      "lat": 9.3337097168,
      "lon": 123.300003052,
      "tz": "Asia\/Manila"
  },
  "RPVE": {
      "icao": "RPVE",
      "iata": "MPH",
      "name": "Godofredo P. Ramos Airport",
      "city": "Malay",
      "state": "Western-Visayas",
      "country": "PH",
      "elevation": 7,
      "lat": 11.9245004654,
      "lon": 121.95400238,
      "tz": "Asia\/Manila"
  },
  "RPVF": {
      "icao": "RPVF",
      "iata": "CRM",
      "name": "Catarman National Airport",
      "city": "Catarman",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 6,
      "lat": 12.5024003983,
      "lon": 124.6360015869,
      "tz": "Asia\/Manila"
  },
  "RPVG": {
      "icao": "RPVG",
      "iata": "",
      "name": "Guiuan Airport",
      "city": "Guiuan",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 7,
      "lat": 11.0354995728,
      "lon": 125.741996765,
      "tz": "Asia\/Manila"
  },
  "RPVH": {
      "icao": "RPVH",
      "iata": "",
      "name": "Hilongos Airport",
      "city": "Hilongos",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 12,
      "lat": 10.3800001144,
      "lon": 124.7429962158,
      "tz": "Asia\/Manila"
  },
  "RPVI": {
      "icao": "RPVI",
      "iata": "ILO",
      "name": "Iloilo International Airport",
      "city": "Iloilo City",
      "state": "Western-Visayas",
      "country": "PH",
      "elevation": 27,
      "lat": 10.833017,
      "lon": 122.493358,
      "tz": "Asia\/Manila"
  },
  "RPVJ": {
      "icao": "RPVJ",
      "iata": "MBT",
      "name": "Moises R. Espinosa Airport",
      "city": "Masbate",
      "state": "Bicol",
      "country": "PH",
      "elevation": 26,
      "lat": 12.3694000244,
      "lon": 123.628997803,
      "tz": "Asia\/Manila"
  },
  "RPVK": {
      "icao": "RPVK",
      "iata": "KLO",
      "name": "Kalibo International Airport",
      "city": "Kalibo",
      "state": "Western-Visayas",
      "country": "PH",
      "elevation": 14,
      "lat": 11.679400444,
      "lon": 122.375999451,
      "tz": "Asia\/Manila"
  },
  "RPVL": {
      "icao": "RPVL",
      "iata": "",
      "name": "Wasig Airport",
      "city": "Mansalay",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 13,
      "lat": 12.5361003876,
      "lon": 121.4810028076,
      "tz": "Asia\/Manila"
  },
  "RPVM": {
      "icao": "RPVM",
      "iata": "CEB",
      "name": "Mactan Cebu International Airport",
      "city": "Lapu-Lapu City",
      "state": "Central-Visayas",
      "country": "PH",
      "elevation": 31,
      "lat": 10.3074998856,
      "lon": 123.9789962769,
      "tz": "Asia\/Manila"
  },
  "RPVO": {
      "icao": "RPVO",
      "iata": "OMC",
      "name": "Ormoc Airport",
      "city": "Ormoc City",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 83,
      "lat": 11.0579996109,
      "lon": 124.5650024414,
      "tz": "Asia\/Manila"
  },
  "RPVP": {
      "icao": "RPVP",
      "iata": "PPS",
      "name": "Puerto Princesa Airport",
      "city": "Puerto Princesa City",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 71,
      "lat": 9.7421197891,
      "lon": 118.7590026855,
      "tz": "Asia\/Manila"
  },
  "RPVR": {
      "icao": "RPVR",
      "iata": "RXS",
      "name": "Roxas Airport",
      "city": "Roxas City",
      "state": "Western-Visayas",
      "country": "PH",
      "elevation": 10,
      "lat": 11.597700119,
      "lon": 122.7519989014,
      "tz": "Asia\/Manila"
  },
  "RPVS": {
      "icao": "RPVS",
      "iata": "EUQ",
      "name": "Evelio Javier Airport",
      "city": "San Jose",
      "state": "Western-Visayas",
      "country": "PH",
      "elevation": 23,
      "lat": 10.765999794,
      "lon": 121.932998657,
      "tz": "Asia\/Manila"
  },
  "RPVT": {
      "icao": "RPVT",
      "iata": "TAG",
      "name": "Tagbilaran Airport",
      "city": "Tagbilaran City",
      "state": "Central-Visayas",
      "country": "PH",
      "elevation": 38,
      "lat": 9.6640796661,
      "lon": 123.8529968262,
      "tz": "Asia\/Manila"
  },
  "RPVU": {
      "icao": "RPVU",
      "iata": "TBH",
      "name": "Romblon Airport",
      "city": "Romblon",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 10,
      "lat": 12.3109998703,
      "lon": 122.084999084,
      "tz": "Asia\/Manila"
  },
  "RPVV": {
      "icao": "RPVV",
      "iata": "USU",
      "name": "Francisco B. Reyes Airport",
      "city": "Coron",
      "state": "Mimaropa",
      "country": "PH",
      "elevation": 148,
      "lat": 12.1215000153,
      "lon": 120.099998474,
      "tz": "Asia\/Manila"
  },
  "RPVW": {
      "icao": "RPVW",
      "iata": "BPR",
      "name": "Borongan Airport",
      "city": "Borongan City",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 7,
      "lat": 11.6743001938,
      "lon": 125.478996277,
      "tz": "Asia\/Manila"
  },
  "RPVY": {
      "icao": "RPVY",
      "iata": "",
      "name": "Catbalogan Airport",
      "city": "",
      "state": "Eastern-Visayas",
      "country": "PH",
      "elevation": 0,
      "lat": 11.81,
      "lon": 124.83,
      "tz": "Asia\/Manila"
  },
  "RPXX": {
      "icao": "RPXX",
      "iata": "ENI",
      "name": "El Nido Airport",
      "city": "El Nido",
      "state": "Palawan",
      "country": "PH",
      "elevation": 17,
      "lat": 11.2025,
      "lon": 119.416,
      "tz": "Asia\/Manila"
  },
  "SA01": {
      "icao": "SA01",
      "iata": "",
      "name": "Cachi Airport",
      "city": "Cachi",
      "state": "Salta",
      "country": "AR",
      "elevation": 8232,
      "lat": -25.1049995422,
      "lon": -66.1572036743,
      "tz": "America\/Argentina\/Salta"
  },
  "SA02": {
      "icao": "SA02",
      "iata": "",
      "name": "Cafayate Airport",
      "city": "Cafayate",
      "state": "Salta",
      "country": "AR",
      "elevation": 5375,
      "lat": -26.0561008453,
      "lon": -65.9368972778,
      "tz": "America\/Argentina\/Salta"
  },
  "SA03": {
      "icao": "SA03",
      "iata": "",
      "name": "Villa Minetti Airport",
      "city": "Villa Minetti",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 246,
      "lat": -28.6201,
      "lon": -61.6038,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SA05": {
      "icao": "SA05",
      "iata": "",
      "name": "Bell Ville Airport",
      "city": "Bell Ville",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 429,
      "lat": -32.6599,
      "lon": -62.702,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SA06": {
      "icao": "SA06",
      "iata": "",
      "name": "Santa Rita Airport",
      "city": "Saladillo",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 177,
      "lat": -35.8283,
      "lon": -60.1467,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SA07": {
      "icao": "SA07",
      "iata": "",
      "name": "El Porton Airport",
      "city": "Buta Ranquil",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 2853,
      "lat": -37.1949996948,
      "lon": -69.6094970703,
      "tz": "America\/Argentina\/Salta"
  },
  "SA10": {
      "icao": "SA10",
      "iata": "",
      "name": "Estancia Don Panos Airport",
      "city": "Presidencia Roca",
      "state": "Chaco",
      "country": "AR",
      "elevation": 262,
      "lat": -26.2959,
      "lon": -59.5353,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SA11": {
      "icao": "SA11",
      "iata": "",
      "name": "Campo Arenal Airport",
      "city": "Campo Arenal",
      "state": "Catamarca",
      "country": "AR",
      "elevation": 7622,
      "lat": -27.0722999573,
      "lon": -66.5860977173,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SA12": {
      "icao": "SA12",
      "iata": "",
      "name": "Quemu Quemu Airport",
      "city": "Quemu Quemu",
      "state": "La-Pampa",
      "country": "AR",
      "elevation": 396,
      "lat": -36.0587,
      "lon": -63.6313,
      "tz": "America\/Argentina\/Salta"
  },
  "SA13": {
      "icao": "SA13",
      "iata": "",
      "name": "Estancia La Estrella Airport",
      "city": "Tandil",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 456,
      "lat": -37.5593986511,
      "lon": -58.6660003662,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SA15": {
      "icao": "SA15",
      "iata": "",
      "name": "Lago Fagnano North Airport",
      "city": "Kaiken",
      "state": "Tierra-del-Fuego",
      "country": "AR",
      "elevation": 600,
      "lat": -54.4996986389,
      "lon": -67.1731033325,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SA16": {
      "icao": "SA16",
      "iata": "",
      "name": "La Puntilla Airport",
      "city": "La Puntilla",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 2952,
      "lat": -32.9627990723,
      "lon": -68.8737030029,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SA17": {
      "icao": "SA17",
      "iata": "",
      "name": "Rio Cuarto Aeroclub Airport",
      "city": "Rio Cuarto",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1423,
      "lat": -33.1605987549,
      "lon": -64.3382034302,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SA19": {
      "icao": "SA19",
      "iata": "",
      "name": "Puerto Deseado West Airport",
      "city": "Puerto Deseado",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 271,
      "lat": -47.7201004028,
      "lon": -65.9324035645,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SA20": {
      "icao": "SA20",
      "iata": "",
      "name": "Loma La Lata Airport",
      "city": "Neuquen",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 1328,
      "lat": -38.4137992859,
      "lon": -68.7372970581,
      "tz": "America\/Argentina\/Salta"
  },
  "SA21": {
      "icao": "SA21",
      "iata": "",
      "name": "Veinticinco De Mayo Airport",
      "city": "Colonia Veinticinco de Mayo",
      "state": "La-Pampa",
      "country": "AR",
      "elevation": 1138,
      "lat": -37.8097991943,
      "lon": -67.6593017578,
      "tz": "America\/Argentina\/Salta"
  },
  "SA22": {
      "icao": "SA22",
      "iata": "",
      "name": "Santa Victoria Airport",
      "city": "Salta",
      "state": "Salta",
      "country": "AR",
      "elevation": 918,
      "lat": -22.2856998444,
      "lon": -62.7136993408,
      "tz": "America\/Argentina\/Salta"
  },
  "SA23": {
      "icao": "SA23",
      "iata": "",
      "name": "Apostoles Airport",
      "city": "Apostoles",
      "state": "Misiones",
      "country": "AR",
      "elevation": 581,
      "lat": -27.9032001495,
      "lon": -55.765499115,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SA24": {
      "icao": "SA24",
      "iata": "",
      "name": "Calilegua Airport",
      "city": "Calilegua",
      "state": "Jujuy",
      "country": "AR",
      "elevation": 1449,
      "lat": -23.7819004059,
      "lon": -64.74949646,
      "tz": "America\/Argentina\/Jujuy"
  },
  "SA25": {
      "icao": "SA25",
      "iata": "",
      "name": "Canadon Seco Airport",
      "city": "Canadon Seco",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 295,
      "lat": -46.539,
      "lon": -67.5639,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SA26": {
      "icao": "SA26",
      "iata": "",
      "name": "Bella Vista Airport",
      "city": "Bella Vista",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 180,
      "lat": -28.5262,
      "lon": -59.0385,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SA27": {
      "icao": "SA27",
      "iata": "",
      "name": "Puerto Rosales Airport",
      "city": "Puerto Rosales",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 16,
      "lat": -38.8970985413,
      "lon": -62.0102996826,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SA28": {
      "icao": "SA28",
      "iata": "",
      "name": "Nanco Lauquen Airport",
      "city": "Trenque Lauquen",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 301,
      "lat": -35.9715,
      "lon": -62.7724,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SA29": {
      "icao": "SA29",
      "iata": "",
      "name": "Choele Choel Airport",
      "city": "Choele Choel",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 642,
      "lat": -39.2863998413,
      "lon": -65.6102981567,
      "tz": "America\/Argentina\/Salta"
  },
  "SA30": {
      "icao": "SA30",
      "iata": "CCT",
      "name": "Colonia Catriel Airport",
      "city": "Colonia Catriel",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 1026,
      "lat": -37.9101982117,
      "lon": -67.8349990845,
      "tz": "America\/Argentina\/Salta"
  },
  "SA31": {
      "icao": "SA31",
      "iata": "",
      "name": "San Nicolas De Los Arroyos Airport",
      "city": "San Nicolas",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 98,
      "lat": -33.3907,
      "lon": -60.1957,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SA33": {
      "icao": "SA33",
      "iata": "",
      "name": "Comandante Luis Piedrabuena Airport",
      "city": "Comandante Luis Piedrabuena",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 78,
      "lat": -49.9951,
      "lon": -68.9531,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SA34": {
      "icao": "SA34",
      "iata": "",
      "name": "Trelew Aeroclub Airport",
      "city": "Trelew",
      "state": "Chubut",
      "country": "AR",
      "elevation": 127,
      "lat": -43.2356,
      "lon": -65.3241,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SA35": {
      "icao": "SA35",
      "iata": "",
      "name": "Andalgala Airport",
      "city": "Andalgala",
      "state": "Catamarca",
      "country": "AR",
      "elevation": 2952,
      "lat": -27.6317005157,
      "lon": -66.3507995605,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SA36": {
      "icao": "SA36",
      "iata": "",
      "name": "Saladillo Airport",
      "city": "Saladillo",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 147,
      "lat": -35.6081,
      "lon": -59.8164,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SA38": {
      "icao": "SA38",
      "iata": "",
      "name": "Las Lajas Airport",
      "city": "Las Lajas",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 2788,
      "lat": -38.538898468,
      "lon": -70.337097168,
      "tz": "America\/Argentina\/Salta"
  },
  "SA39": {
      "icao": "SA39",
      "iata": "",
      "name": "Jujuy Aeroclub Airport",
      "city": "San Salvador de Jujuy",
      "state": "Jujuy",
      "country": "AR",
      "elevation": 4047,
      "lat": -24.2364997864,
      "lon": -65.2690963745,
      "tz": "America\/Argentina\/Jujuy"
  },
  "SA40": {
      "icao": "SA40",
      "iata": "",
      "name": "San Juan Aeroclub Airport",
      "city": "San Juan",
      "state": "San-Juan",
      "country": "AR",
      "elevation": 2079,
      "lat": -31.6028003693,
      "lon": -68.5473022461,
      "tz": "America\/Argentina\/San_Juan"
  },
  "SA41": {
      "icao": "SA41",
      "iata": "",
      "name": "El Colorado Airport",
      "city": "El Colorado",
      "state": "Formosa",
      "country": "AR",
      "elevation": 269,
      "lat": -26.3166866302,
      "lon": -59.3397636414,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SA42": {
      "icao": "SA42",
      "iata": "",
      "name": "Santa Maria Airport",
      "city": "Santa Maria",
      "state": "Catamarca",
      "country": "AR",
      "elevation": 5904,
      "lat": -26.6734008789,
      "lon": -66.0240020752,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SA44": {
      "icao": "SA44",
      "iata": "",
      "name": "Londres\/Belen Airport",
      "city": "Londres\/Belen",
      "state": "Catamarca",
      "country": "AR",
      "elevation": 3936,
      "lat": -27.7078990936,
      "lon": -67.0951004028,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SA45": {
      "icao": "SA45",
      "iata": "",
      "name": "Frias Airport",
      "city": "Frias",
      "state": "Santiago-del-Estero",
      "country": "AR",
      "elevation": 1082,
      "lat": -28.6343994141,
      "lon": -65.1091995239,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SA46": {
      "icao": "SA46",
      "iata": "",
      "name": "Fiambala Airport",
      "city": "Fiambala",
      "state": "Catamarca",
      "country": "AR",
      "elevation": 5029,
      "lat": -27.64220047,
      "lon": -67.6247024536,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAAA": {
      "icao": "SAAA",
      "iata": "",
      "name": "San Antonio De Areco Airport",
      "city": "San Antonio de Areco",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 98,
      "lat": -34.2214,
      "lon": -59.4422,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAAC": {
      "icao": "SAAC",
      "iata": "COC",
      "name": "Comodoro Pierrestegui Airport",
      "city": "Concordia",
      "state": "Entre-Rios",
      "country": "AR",
      "elevation": 112,
      "lat": -31.2969,
      "lon": -57.9966,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAAG": {
      "icao": "SAAG",
      "iata": "GHU",
      "name": "Gualeguaychu Airport",
      "city": "Gualeguaychu",
      "state": "Entre-Rios",
      "country": "AR",
      "elevation": 75,
      "lat": -33.0103,
      "lon": -58.6131,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAAI": {
      "icao": "SAAI",
      "iata": "",
      "name": "Punta Indio Airport",
      "city": "Veronica",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 49,
      "lat": -35.3532981873,
      "lon": -57.2900009155,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAAJ": {
      "icao": "SAAJ",
      "iata": "JNI",
      "name": "Junin Airport",
      "city": "Junin",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 262,
      "lat": -34.5459,
      "lon": -60.9306,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAAK": {
      "icao": "SAAK",
      "iata": "",
      "name": "Isla Martin Garcia Airport",
      "city": "Isla Martin Garcia",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 6,
      "lat": -34.1821,
      "lon": -58.2469,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAAM": {
      "icao": "SAAM",
      "iata": "",
      "name": "Mazaruca Airport",
      "city": "Puerto Ibicuy\/Mazuraca",
      "state": "Entre-Rios",
      "country": "AR",
      "elevation": 20,
      "lat": -33.5960006714,
      "lon": -59.266998291,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAAN": {
      "icao": "SAAN",
      "iata": "",
      "name": "Pergamino Airport",
      "city": "Pergamino",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 236,
      "lat": -33.919,
      "lon": -60.648,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAAP": {
      "icao": "SAAP",
      "iata": "PRA",
      "name": "General Urquiza Airport",
      "city": "Parana",
      "state": "Entre-Rios",
      "country": "AR",
      "elevation": 242,
      "lat": -31.7948,
      "lon": -60.4804,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAAR": {
      "icao": "SAAR",
      "iata": "ROS",
      "name": "Islas Malvinas Airport",
      "city": "Rosario",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 85,
      "lat": -32.9036,
      "lon": -60.785,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAAU": {
      "icao": "SAAU",
      "iata": "",
      "name": "Villaguay Airport",
      "city": "Villaguay",
      "state": "Entre-Rios",
      "country": "AR",
      "elevation": 131,
      "lat": -31.855,
      "lon": -59.0756,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAAV": {
      "icao": "SAAV",
      "iata": "SFN",
      "name": "Sauce Viejo Airport",
      "city": "Santa Fe",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 55,
      "lat": -31.7117,
      "lon": -60.8117,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SABE": {
      "icao": "SABE",
      "iata": "AEP",
      "name": "Jorge Newbery Airpark",
      "city": "Buenos Aires",
      "state": "Buenos-Aires-F-D-",
      "country": "AR",
      "elevation": 18,
      "lat": -34.5592,
      "lon": -58.4156,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SABP": {
      "icao": "SABP",
      "iata": "",
      "name": "Coronel Pringles Airport",
      "city": "Coronel Pringles",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 810,
      "lat": -38.0066680908,
      "lon": -61.331943512,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SACA": {
      "icao": "SACA",
      "iata": "",
      "name": "Cap D. Omar Dario Gelardi Airport",
      "city": "Santa Isabel - Cordoba",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1594,
      "lat": -31.4419002533,
      "lon": -64.2582015991,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACC": {
      "icao": "SACC",
      "iata": "LCM",
      "name": "La Cumbre Airport",
      "city": "La Cumbre",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 3733,
      "lat": -31.0058002472,
      "lon": -64.5318984985,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACD": {
      "icao": "SACD",
      "iata": "",
      "name": "Coronel Olmedo Airport",
      "city": "Cordoba",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1416,
      "lat": -31.4874992371,
      "lon": -64.1414031982,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACE": {
      "icao": "SACE",
      "iata": "",
      "name": "Escuela De Aviacion Militar Airport",
      "city": "Santa Isabel - Cordoba",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1646,
      "lat": -31.4449996948,
      "lon": -64.2838973999,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACI": {
      "icao": "SACI",
      "iata": "",
      "name": "Pilar Airport",
      "city": "Pilar",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1072,
      "lat": -31.6819992065,
      "lon": -63.8638000488,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACL": {
      "icao": "SACL",
      "iata": "",
      "name": "Laguna Larga Airport",
      "city": "Laguna Larga",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1013,
      "lat": -31,
      "lon": -63,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACM": {
      "icao": "SACM",
      "iata": "",
      "name": "Villa General Mitre Airport",
      "city": "Villa General Mitre",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1760,
      "lat": -30.7005996704,
      "lon": -64.0430984497,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACN": {
      "icao": "SACN",
      "iata": "",
      "name": "Ascochinga Airport",
      "city": "Ascochinga",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 677,
      "lat": -30.9679203033,
      "lon": -64.2777404785,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACO": {
      "icao": "SACO",
      "iata": "COR",
      "name": "Ingeniero Ambrosio Taravella Airport",
      "city": "Cordoba",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1604,
      "lat": -31.323600769,
      "lon": -64.2080001831,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACP": {
      "icao": "SACP",
      "iata": "",
      "name": "Chepes Airport",
      "city": "Chepes",
      "state": "La-Rioja",
      "country": "AR",
      "elevation": 714,
      "lat": -31.3561420441,
      "lon": -66.5918884277,
      "tz": "America\/Argentina\/La_Rioja"
  },
  "SACQ": {
      "icao": "SACQ",
      "iata": "",
      "name": "Monte Quemado Airport",
      "city": "Monte Quemado",
      "state": "Santiago-del-Estero",
      "country": "AR",
      "elevation": 1242,
      "lat": -26.1597995758,
      "lon": -64.4091033936,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACS": {
      "icao": "SACS",
      "iata": "",
      "name": "Villa De Soto Airport",
      "city": "Villa de Soto",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 2013,
      "lat": -30.8652000427,
      "lon": -64.9789962769,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SACT": {
      "icao": "SACT",
      "iata": "",
      "name": "Chamical Airport",
      "city": "Gobernador Gordillo",
      "state": "La-Rioja",
      "country": "AR",
      "elevation": 1502,
      "lat": -30.3453006744,
      "lon": -66.2936019897,
      "tz": "America\/Argentina\/La_Rioja"
  },
  "SACV": {
      "icao": "SACV",
      "iata": "",
      "name": "Villa de Maria de Rio Seco Airport",
      "city": "Villa de Maria de Rio Seco",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 0,
      "lat": -29.9,
      "lon": -63.683,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SADF": {
      "icao": "SADF",
      "iata": "FDO",
      "name": "San Fernando Airport",
      "city": "San Fernando",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 10,
      "lat": -34.4532,
      "lon": -58.5896,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADG": {
      "icao": "SADG",
      "iata": "",
      "name": "Monte Grande Airport",
      "city": "Monte Grande",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 30,
      "lat": -34.8055992126,
      "lon": -58.4920005798,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADJ": {
      "icao": "SADJ",
      "iata": "",
      "name": "Mariano Moreno Airport",
      "city": "General Sarmiento",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 105,
      "lat": -34.5606,
      "lon": -58.7896,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADL": {
      "icao": "SADL",
      "iata": "LPG",
      "name": "La Plata Airport",
      "city": "La Plata",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 72,
      "lat": -34.9722,
      "lon": -57.8947,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADM": {
      "icao": "SADM",
      "iata": "",
      "name": "Moron Airport",
      "city": "Moron",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 95,
      "lat": -34.6763,
      "lon": -58.6428,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADO": {
      "icao": "SADO",
      "iata": "",
      "name": "Campo De Mayo Airport",
      "city": "Campo de Mayo",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 78,
      "lat": -34.5344,
      "lon": -58.6717,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADP": {
      "icao": "SADP",
      "iata": "EPA",
      "name": "El Palomar Airport",
      "city": "El Palomar",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 59,
      "lat": -34.6099,
      "lon": -58.6126,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADQ": {
      "icao": "SADQ",
      "iata": "",
      "name": "Quilmes Airport",
      "city": "Quilmes",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 6,
      "lat": -34.7064,
      "lon": -58.2444,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADR": {
      "icao": "SADR",
      "iata": "",
      "name": "Merlo Airport",
      "city": "Merlo",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 49,
      "lat": -34.6848,
      "lon": -58.6891,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADS": {
      "icao": "SADS",
      "iata": "",
      "name": "Aeroclub Argentino Airport",
      "city": "San Justo",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 62,
      "lat": -34.7311,
      "lon": -58.5975,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SADZ": {
      "icao": "SADZ",
      "iata": "",
      "name": "Matanza Airport",
      "city": "La Matanza",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 9,
      "lat": -34.7261,
      "lon": -58.5011,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAEA": {
      "icao": "SAEA",
      "iata": "",
      "name": "General Acha Airport",
      "city": "General Acha",
      "state": "La-Pampa",
      "country": "AR",
      "elevation": 908,
      "lat": -37.4007987976,
      "lon": -64.6128005981,
      "tz": "America\/Argentina\/Salta"
  },
  "SAEK": {
      "icao": "SAEK",
      "iata": "",
      "name": "Laprida Airport",
      "city": "Laprida",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 705,
      "lat": -37.518901825,
      "lon": -60.7769012451,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAEL": {
      "icao": "SAEL",
      "iata": "",
      "name": "Las Flores Airport",
      "city": "Las Flores",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 111,
      "lat": -36.0672,
      "lon": -59.1042,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAEM": {
      "icao": "SAEM",
      "iata": "MJR",
      "name": "Miramar Airport",
      "city": "Miramar",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 42,
      "lat": -38.2271,
      "lon": -57.8697,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAET": {
      "icao": "SAET",
      "iata": "",
      "name": "Trenque Lauquen Airport",
      "city": "Trenque Lauquen",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 324,
      "lat": -36.0158,
      "lon": -62.6939,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAEZ": {
      "icao": "SAEZ",
      "iata": "EZE",
      "name": "Ministro Pistarini International Airport",
      "city": "Ezeiza",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 67,
      "lat": -34.8222,
      "lon": -58.5358,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAFR": {
      "icao": "SAFR",
      "iata": "RAF",
      "name": "Rafaela Airport",
      "city": "Rafaela",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 325,
      "lat": -31.2825,
      "lon": -61.5017,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAFS": {
      "icao": "SAFS",
      "iata": "NCJ",
      "name": "Sunchales Aeroclub Airport",
      "city": "Sunchales",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 311,
      "lat": -30.9575,
      "lon": -61.5283,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAFT": {
      "icao": "SAFT",
      "iata": "",
      "name": "El Trebol Airport",
      "city": "El Trebol",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 301,
      "lat": -32.2153,
      "lon": -61.7161,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAFV": {
      "icao": "SAFV",
      "iata": "",
      "name": "Venado Tuerto Airport",
      "city": "Venado Tuerto",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 367,
      "lat": -33.6818,
      "lon": -61.9564,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAHC": {
      "icao": "SAHC",
      "iata": "HOS",
      "name": "Chos Malal Airport",
      "city": "Chos Malal",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 2788,
      "lat": -37.4446983337,
      "lon": -70.2225036621,
      "tz": "America\/Argentina\/Salta"
  },
  "SAHE": {
      "icao": "SAHE",
      "iata": "CVH",
      "name": "Caviahue Airport",
      "city": "Lafontaine",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 5435,
      "lat": -37.851398468,
      "lon": -71.0092010498,
      "tz": "America\/Argentina\/Salta"
  },
  "SAHI": {
      "icao": "SAHI",
      "iata": "",
      "name": "Cipoletti Aeroclub Airport",
      "city": "Cipoletti",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 885,
      "lat": -38.8931007385,
      "lon": -67.9886016846,
      "tz": "America\/Argentina\/Salta"
  },
  "SAHR": {
      "icao": "SAHR",
      "iata": "GNR",
      "name": "Dr. Arturo H. Illia Airport",
      "city": "General Roca",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 852,
      "lat": -39.0007019043,
      "lon": -67.6204986572,
      "tz": "America\/Argentina\/Salta"
  },
  "SAHS": {
      "icao": "SAHS",
      "iata": "RDS",
      "name": "Rincon De Los Sauces Airport",
      "city": "Rincon de los Sauces",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 1968,
      "lat": -37.3905982971,
      "lon": -68.9041976929,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAHV": {
      "icao": "SAHV",
      "iata": "",
      "name": "Victorica Airport",
      "city": "Victorica",
      "state": "La-Pampa",
      "country": "AR",
      "elevation": 1020,
      "lat": -36.2374992371,
      "lon": -65.450553894,
      "tz": "America\/Argentina\/Salta"
  },
  "SAHZ": {
      "icao": "SAHZ",
      "iata": "APZ",
      "name": "Zapala Airport",
      "city": "Zapala",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 3330,
      "lat": -38.9754981995,
      "lon": -70.1136016846,
      "tz": "America\/Argentina\/Salta"
  },
  "SAMA": {
      "icao": "SAMA",
      "iata": "",
      "name": "General Alvear Airport",
      "city": "General Alvear",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 1489,
      "lat": -35.0588989258,
      "lon": -67.6928024292,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMC": {
      "icao": "SAMC",
      "iata": "",
      "name": "Cristo Redentor Airport",
      "city": "Cristo Redentor",
      "state": "",
      "country": "AR",
      "elevation": 10477,
      "lat": -32.8073005676,
      "lon": -70.0681991577,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAME": {
      "icao": "SAME",
      "iata": "MDZ",
      "name": "El Plumerillo Airport",
      "city": "Mendoza",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 2310,
      "lat": -32.8316993713,
      "lon": -68.7929000854,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMH": {
      "icao": "SAMH",
      "iata": "",
      "name": "Valle Hermoso Airport",
      "city": "Valle Hermoso",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 2500,
      "lat": -35.1461982727,
      "lon": -70.2053985596,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMI": {
      "icao": "SAMI",
      "iata": "",
      "name": "San Martin Airport",
      "city": "San Martin",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 2154,
      "lat": -33.0625,
      "lon": -68.5083007812,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMJ": {
      "icao": "SAMJ",
      "iata": "",
      "name": "Jachal Airport",
      "city": "Jachal",
      "state": "San-Juan",
      "country": "AR",
      "elevation": 3790,
      "lat": -30.2495002747,
      "lon": -68.740196228,
      "tz": "America\/Argentina\/San_Juan"
  },
  "SAML": {
      "icao": "SAML",
      "iata": "",
      "name": "Punta De Vacas Airport",
      "city": "Punta de Vacas",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 7906,
      "lat": -32.854598999,
      "lon": -69.7604980469,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMM": {
      "icao": "SAMM",
      "iata": "LGS",
      "name": "Comodoro D.R. Salomon Airport",
      "city": "Malargue",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 4685,
      "lat": -35.493598938,
      "lon": -69.5743026733,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMP": {
      "icao": "SAMP",
      "iata": "",
      "name": "La Paz Airport",
      "city": "La Paz",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 0,
      "lat": -33.467,
      "lon": -67.55,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMQ": {
      "icao": "SAMQ",
      "iata": "",
      "name": "Mendoza Airpark",
      "city": "Mendoza",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 2694,
      "lat": -32.8652992249,
      "lon": -68.8719024658,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMR": {
      "icao": "SAMR",
      "iata": "AFA",
      "name": "Suboficial Ay Santiago Germano Airport",
      "city": "San Rafael",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 2470,
      "lat": -34.5882987976,
      "lon": -68.4039001465,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMS": {
      "icao": "SAMS",
      "iata": "",
      "name": "San Carlos Airport",
      "city": "San Carlos",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 3050,
      "lat": -33.7756996155,
      "lon": -69.0537033081,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SAMU": {
      "icao": "SAMU",
      "iata": "",
      "name": "Uspallata Airport",
      "city": "Uspallata",
      "state": "Mendoza",
      "country": "AR",
      "elevation": 6525,
      "lat": -32.538898468,
      "lon": -69.3458023071,
      "tz": "America\/Argentina\/Mendoza"
  },
  "SANC": {
      "icao": "SANC",
      "iata": "CTC",
      "name": "Catamarca Airport",
      "city": "Catamarca",
      "state": "Catamarca",
      "country": "AR",
      "elevation": 1522,
      "lat": -28.5956001282,
      "lon": -65.751701355,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SANE": {
      "icao": "SANE",
      "iata": "SDE",
      "name": "Vicecomodoro Angel D. La Paz Aragones Airport",
      "city": "Santiago del Estero",
      "state": "Santiago-del-Estero",
      "country": "AR",
      "elevation": 656,
      "lat": -27.7655563354,
      "lon": -64.3099975586,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SANH": {
      "icao": "SANH",
      "iata": "RHD",
      "name": "Las Termas Airport",
      "city": "Rio Hondo",
      "state": "Santiago-del-Estero",
      "country": "AR",
      "elevation": 270,
      "lat": -27.4736995697,
      "lon": -64.9055023193,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SANI": {
      "icao": "SANI",
      "iata": "",
      "name": "Tinogasta Airport",
      "city": "Tinogasta",
      "state": "Catamarca",
      "country": "AR",
      "elevation": 3968,
      "lat": -28.0377998352,
      "lon": -67.5802993774,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SANL": {
      "icao": "SANL",
      "iata": "IRJ",
      "name": "Capitan V A Almonacid Airport",
      "city": "La Rioja",
      "state": "La-Rioja",
      "country": "AR",
      "elevation": 1437,
      "lat": -29.3815994263,
      "lon": -66.7957992554,
      "tz": "America\/Argentina\/La_Rioja"
  },
  "SANO": {
      "icao": "SANO",
      "iata": "",
      "name": "Chilecito Airport",
      "city": "Chilecito",
      "state": "La-Rioja",
      "country": "AR",
      "elevation": 3099,
      "lat": -29.2238998413,
      "lon": -67.4389038086,
      "tz": "America\/Argentina\/La_Rioja"
  },
  "SANT": {
      "icao": "SANT",
      "iata": "TUC",
      "name": "Teniente Benjamin Matienzo Airport",
      "city": "San Miguel de Tucuman",
      "state": "Tucuman",
      "country": "AR",
      "elevation": 1493,
      "lat": -26.8409004211,
      "lon": -65.1048965454,
      "tz": "America\/Argentina\/Tucuman"
  },
  "SANU": {
      "icao": "SANU",
      "iata": "UAQ",
      "name": "Domingo Faustino Sarmiento Airport",
      "city": "San Juan",
      "state": "San-Juan",
      "country": "AR",
      "elevation": 1958,
      "lat": -31.5715007782,
      "lon": -68.4181976318,
      "tz": "America\/Argentina\/San_Juan"
  },
  "SANW": {
      "icao": "SANW",
      "iata": "CRR",
      "name": "Ceres Airport",
      "city": "Ceres",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 285,
      "lat": -29.8631,
      "lon": -61.8728,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAOC": {
      "icao": "SAOC",
      "iata": "RCU",
      "name": "Area De Material Airport",
      "city": "Rio Cuarto",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1380,
      "lat": -33.0850982666,
      "lon": -64.2612991333,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAOD": {
      "icao": "SAOD",
      "iata": "VDR",
      "name": "Villa Dolores Airport",
      "city": "Villa Dolores",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1847,
      "lat": -31.9451999664,
      "lon": -65.1463012695,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAOE": {
      "icao": "SAOE",
      "iata": "",
      "name": "Rio Tercero Airport",
      "city": "Rio Tercero",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 1246,
      "lat": -32.1733016968,
      "lon": -64.0883026123,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAOI": {
      "icao": "SAOI",
      "iata": "",
      "name": "Villa Maria Airport",
      "city": "Villa Maria del Rio Seco",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 672,
      "lat": -32.3894004822,
      "lon": -63.2585983276,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAOL": {
      "icao": "SAOL",
      "iata": "",
      "name": "Laboulaye Airport",
      "city": "Laboulaye",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 449,
      "lat": -34.1353988647,
      "lon": -63.3623008728,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAOM": {
      "icao": "SAOM",
      "iata": "",
      "name": "Marcos Juarez Airport",
      "city": "Marcos Juarez",
      "state": "Cordoba",
      "country": "AR",
      "elevation": 360,
      "lat": -32.6836,
      "lon": -62.1578,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAOR": {
      "icao": "SAOR",
      "iata": "VME",
      "name": "Villa Reynolds Airport",
      "city": "Villa Reynolds",
      "state": "San-Luis",
      "country": "AR",
      "elevation": 1591,
      "lat": -33.7299003601,
      "lon": -65.3873977661,
      "tz": "America\/Argentina\/San_Luis"
  },
  "SAOS": {
      "icao": "SAOS",
      "iata": "RLO",
      "name": "Valle Del Conlara International Airport",
      "city": "Merlo",
      "state": "San-Luis",
      "country": "AR",
      "elevation": 2021,
      "lat": -32.3847007751,
      "lon": -65.1865005493,
      "tz": "America\/Argentina\/San_Luis"
  },
  "SAOU": {
      "icao": "SAOU",
      "iata": "LUQ",
      "name": "Brigadier Mayor D Cesar Raul Ojeda Airport",
      "city": "San Luis",
      "state": "San-Luis",
      "country": "AR",
      "elevation": 2328,
      "lat": -33.2732009888,
      "lon": -66.3563995361,
      "tz": "America\/Argentina\/San_Luis"
  },
  "SARC": {
      "icao": "SARC",
      "iata": "CNQ",
      "name": "Corrientes Airport",
      "city": "Corrientes",
      "state": "Corrientes",
      "country": "AR",
      "elevation": 202,
      "lat": -27.4455,
      "lon": -58.7619,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARD": {
      "icao": "SARD",
      "iata": "",
      "name": "Resistencia (City) Airport",
      "city": "Resistencia",
      "state": "Chaco",
      "country": "AR",
      "elevation": 175,
      "lat": -27.4657001495,
      "lon": -58.9790000916,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARE": {
      "icao": "SARE",
      "iata": "RES",
      "name": "Resistencia International Airport",
      "city": "Resistencia",
      "state": "Chaco",
      "country": "AR",
      "elevation": 173,
      "lat": -27.45,
      "lon": -59.0561,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARF": {
      "icao": "SARF",
      "iata": "FMA",
      "name": "Formosa Airport",
      "city": "Formosa",
      "state": "Formosa",
      "country": "AR",
      "elevation": 193,
      "lat": -26.2127,
      "lon": -58.2281,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARG": {
      "icao": "SARG",
      "iata": "",
      "name": "Caa Cati Airport",
      "city": "General Paz",
      "state": "Corrientes",
      "country": "AR",
      "elevation": 249,
      "lat": -27.7553005219,
      "lon": -57.6329994202,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARI": {
      "icao": "SARI",
      "iata": "IGR",
      "name": "Cataratas Del Iguazu International Airport",
      "city": "Puerto Iguazu",
      "state": "Misiones",
      "country": "AR",
      "elevation": 916,
      "lat": -25.7373008728,
      "lon": -54.473400116,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARL": {
      "icao": "SARL",
      "iata": "AOL",
      "name": "Paso De Los Libres Airport",
      "city": "Paso de los Libres",
      "state": "Corrientes",
      "country": "AR",
      "elevation": 230,
      "lat": -29.6894,
      "lon": -57.1521,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARM": {
      "icao": "SARM",
      "iata": "MCS",
      "name": "Monte Caseros Airport",
      "city": "Monte Caseros",
      "state": "Corrientes",
      "country": "AR",
      "elevation": 170,
      "lat": -30.2719,
      "lon": -57.6402,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARP": {
      "icao": "SARP",
      "iata": "PSS",
      "name": "Libertador Gral D Jose De San Martin Airport",
      "city": "Posadas",
      "state": "Misiones",
      "country": "AR",
      "elevation": 430,
      "lat": -27.3858,
      "lon": -55.9707,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARS": {
      "icao": "SARS",
      "iata": "PRQ",
      "name": "Termal Airport",
      "city": "Presidencia Roque Saenz Pena",
      "state": "Chaco",
      "country": "AR",
      "elevation": 308,
      "lat": -26.753610611,
      "lon": -60.4922218323,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SARV": {
      "icao": "SARV",
      "iata": "",
      "name": "Villa Angela Airport",
      "city": "Villa Angela",
      "state": "Chaco",
      "country": "AR",
      "elevation": 246,
      "lat": -27.5915,
      "lon": -60.6864,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SASA": {
      "icao": "SASA",
      "iata": "SLA",
      "name": "Martin Miguel De Guemes International Airport",
      "city": "Salta",
      "state": "Salta",
      "country": "AR",
      "elevation": 4088,
      "lat": -24.8560009003,
      "lon": -65.4861984253,
      "tz": "America\/Argentina\/Salta"
  },
  "SASJ": {
      "icao": "SASJ",
      "iata": "JUJ",
      "name": "Gobernador Horacio Guzman International Airport",
      "city": "San Salvador de Jujuy",
      "state": "Jujuy",
      "country": "AR",
      "elevation": 3019,
      "lat": -24.3927993774,
      "lon": -65.0978012085,
      "tz": "America\/Argentina\/Jujuy"
  },
  "SASL": {
      "icao": "SASL",
      "iata": "",
      "name": "Salar De Cauchari Airport",
      "city": "Salar de Cauchari",
      "state": "Salta",
      "country": "AR",
      "elevation": 13000,
      "lat": -24.0191993713,
      "lon": -66.797203064,
      "tz": "America\/Argentina\/Jujuy"
  },
  "SASM": {
      "icao": "SASM",
      "iata": "",
      "name": "Metan Airport",
      "city": "Metan",
      "state": "Salta",
      "country": "AR",
      "elevation": 2765,
      "lat": -25.5144004822,
      "lon": -64.9655990601,
      "tz": "America\/Argentina\/Salta"
  },
  "SASO": {
      "icao": "SASO",
      "iata": "ORA",
      "name": "Oran Airport",
      "city": "Oran",
      "state": "Salta",
      "country": "AR",
      "elevation": 1171,
      "lat": -23.1527996063,
      "lon": -64.3292007446,
      "tz": "America\/Argentina\/Salta"
  },
  "SASQ": {
      "icao": "SASQ",
      "iata": "",
      "name": "La Quiaca Airport",
      "city": "La Quiaca",
      "state": "Jujuy",
      "country": "AR",
      "elevation": 11414,
      "lat": -22.1506004333,
      "lon": -65.5774993896,
      "tz": "America\/Argentina\/Jujuy"
  },
  "SASR": {
      "icao": "SASR",
      "iata": "",
      "name": "Rivadavia Airport",
      "city": "Rivadavia",
      "state": "Salta",
      "country": "AR",
      "elevation": 679,
      "lat": -24.1886005402,
      "lon": -62.8833999634,
      "tz": "America\/Argentina\/Salta"
  },
  "SAST": {
      "icao": "SAST",
      "iata": "TTG",
      "name": "General Enrique Mosconi Airport",
      "city": "Tartagal",
      "state": "Salta",
      "country": "AR",
      "elevation": 1472,
      "lat": -22.619600296,
      "lon": -63.7937011719,
      "tz": "America\/Argentina\/Salta"
  },
  "SATC": {
      "icao": "SATC",
      "iata": "CLX",
      "name": "Clorinda Airport",
      "city": "Clorinda",
      "state": "Formosa",
      "country": "AR",
      "elevation": 206,
      "lat": -25.3036,
      "lon": -57.7344,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATD": {
      "icao": "SATD",
      "iata": "ELO",
      "name": "El Dorado Airport",
      "city": "El Dorado",
      "state": "Misiones",
      "country": "AR",
      "elevation": 685,
      "lat": -26.3974990845,
      "lon": -54.5746994019,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATG": {
      "icao": "SATG",
      "iata": "OYA",
      "name": "Goya Airport",
      "city": "Goya",
      "state": "Corrientes",
      "country": "AR",
      "elevation": 128,
      "lat": -29.1058,
      "lon": -59.2189,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATI": {
      "icao": "SATI",
      "iata": "",
      "name": "Bernardo De Irigoyen Airport",
      "city": "Bernardo de Irigoyen",
      "state": "Misiones",
      "country": "AR",
      "elevation": 2673,
      "lat": -26.2813892365,
      "lon": -53.6733322144,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATK": {
      "icao": "SATK",
      "iata": "LLS",
      "name": "Alferez Armando Rodriguez Airport",
      "city": "Las Lomitas",
      "state": "Formosa",
      "country": "AR",
      "elevation": 426,
      "lat": -24.7213993073,
      "lon": -60.5488014221,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATM": {
      "icao": "SATM",
      "iata": "MDX",
      "name": "Mercedes Airport",
      "city": "Mercedes",
      "state": "Corrientes",
      "country": "AR",
      "elevation": 358,
      "lat": -29.2213993073,
      "lon": -58.0877990723,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATO": {
      "icao": "SATO",
      "iata": "",
      "name": "Obera Airport",
      "city": "Obera",
      "state": "Misiones",
      "country": "AR",
      "elevation": 1125,
      "lat": -27.5181999207,
      "lon": -55.1241989136,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATR": {
      "icao": "SATR",
      "iata": "RCQ",
      "name": "Reconquista Airport",
      "city": "Reconquista",
      "state": "Santa-Fe",
      "country": "AR",
      "elevation": 160,
      "lat": -29.2103,
      "lon": -59.68,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SATU": {
      "icao": "SATU",
      "iata": "UZU",
      "name": "Curuzu Cuatia Airport",
      "city": "Curuzu Cuatia",
      "state": "Corrientes",
      "country": "AR",
      "elevation": 229,
      "lat": -29.7706,
      "lon": -57.9789,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SAVA": {
      "icao": "SAVA",
      "iata": "",
      "name": "Piedra Del Aguila Airport",
      "city": "Piedra del Aguila",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 2128,
      "lat": -40.192199707,
      "lon": -70.0100021362,
      "tz": "America\/Argentina\/Salta"
  },
  "SAVB": {
      "icao": "SAVB",
      "iata": "EHL",
      "name": "El Bolson Airport",
      "city": "El Bolson",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 1141,
      "lat": -41.9431991577,
      "lon": -71.5323028564,
      "tz": "America\/Argentina\/Salta"
  },
  "SAVC": {
      "icao": "SAVC",
      "iata": "CRD",
      "name": "General E. Mosconi Airport",
      "city": "Comodoro Rivadavia",
      "state": "Chubut",
      "country": "AR",
      "elevation": 189,
      "lat": -45.7853,
      "lon": -67.4655,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAVD": {
      "icao": "SAVD",
      "iata": "EMX",
      "name": "El Maiten Airport",
      "city": "El Maiten",
      "state": "Chubut",
      "country": "AR",
      "elevation": 2355,
      "lat": -42.0292015076,
      "lon": -71.1725006104,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAVE": {
      "icao": "SAVE",
      "iata": "EQS",
      "name": "Brigadier Antonio Parodi Airport",
      "city": "Esquel",
      "state": "Chubut",
      "country": "AR",
      "elevation": 2621,
      "lat": -42.908000946,
      "lon": -71.139503479,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAVH": {
      "icao": "SAVH",
      "iata": "LHS",
      "name": "Las Heras Airport",
      "city": "Las Heras",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 1082,
      "lat": -46.5382995605,
      "lon": -68.9653015137,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAVJ": {
      "icao": "SAVJ",
      "iata": "IGB",
      "name": "Cabo F.A.A. H. R. Bordon Airport",
      "city": "Ingeniero Jacobacci",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 2925,
      "lat": -41.3208999634,
      "lon": -69.5748977661,
      "tz": "America\/Argentina\/Salta"
  },
  "SAVM": {
      "icao": "SAVM",
      "iata": "OLN",
      "name": "Lago Musters Airport",
      "city": "Sarmiento",
      "state": "Chubut",
      "country": "AR",
      "elevation": 883,
      "lat": -45.5777777778,
      "lon": -69.0727777778,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAVN": {
      "icao": "SAVN",
      "iata": "OES",
      "name": "Antoine De St Exupery Airport",
      "city": "San Antonio Oeste",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 85,
      "lat": -40.7512,
      "lon": -65.0343,
      "tz": "America\/Argentina\/Salta"
  },
  "SAVP": {
      "icao": "SAVP",
      "iata": "",
      "name": "Paso De Los Indios Airport",
      "city": "Paso de los Indios",
      "state": "Chubut",
      "country": "AR",
      "elevation": 575,
      "lat": -43.8666992188,
      "lon": -69.0500030518,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAVQ": {
      "icao": "SAVQ",
      "iata": "MQD",
      "name": "Maquinchao Airport",
      "city": "Maquinchao",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 2912,
      "lat": -41.2430992126,
      "lon": -68.7078018188,
      "tz": "America\/Argentina\/Salta"
  },
  "SAVR": {
      "icao": "SAVR",
      "iata": "ARR",
      "name": "D. Casimiro Szlapelis Airport",
      "city": "Alto Rio Senguerr",
      "state": "Chubut",
      "country": "AR",
      "elevation": 2286,
      "lat": -45.0135993958,
      "lon": -70.8122024536,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAVS": {
      "icao": "SAVS",
      "iata": "SGV",
      "name": "Sierra Grande Airport",
      "city": "Sierra Grande",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 688,
      "lat": -41.5917015076,
      "lon": -65.3394012451,
      "tz": "America\/Argentina\/Salta"
  },
  "SAVT": {
      "icao": "SAVT",
      "iata": "REL",
      "name": "Almirante Marco Andres Zar Airport",
      "city": "Rawson",
      "state": "Chubut",
      "country": "AR",
      "elevation": 141,
      "lat": -43.2105,
      "lon": -65.2703,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAVV": {
      "icao": "SAVV",
      "iata": "VDM",
      "name": "Gobernador Castello Airport",
      "city": "Viedma \/ Carmen de Patagones",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 20,
      "lat": -40.8692,
      "lon": -63.0004,
      "tz": "America\/Argentina\/Salta"
  },
  "SAVY": {
      "icao": "SAVY",
      "iata": "PMY",
      "name": "El Tehuelche Airport",
      "city": "Puerto Madryn",
      "state": "Chubut",
      "country": "AR",
      "elevation": 427,
      "lat": -42.7592,
      "lon": -65.1027,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAWA": {
      "icao": "SAWA",
      "iata": "ING",
      "name": "Lago Argentino Airport",
      "city": "El Calafate",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 732,
      "lat": -50.336101532,
      "lon": -72.2485961914,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWB": {
      "icao": "SAWB",
      "iata": "",
      "name": "Marambio Base",
      "city": "Seymour Island",
      "state": "",
      "country": "AQ",
      "elevation": 760,
      "lat": -64.2382965088,
      "lon": -56.6307983398,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAWC": {
      "icao": "SAWC",
      "iata": "FTE",
      "name": "El Calafate Airport",
      "city": "El Calafate",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 669,
      "lat": -50.2803001404,
      "lon": -72.0531005859,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWD": {
      "icao": "SAWD",
      "iata": "PUD",
      "name": "Puerto Deseado Airport",
      "city": "Puerto Deseado",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 268,
      "lat": -47.7353,
      "lon": -65.9041,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWE": {
      "icao": "SAWE",
      "iata": "RGA",
      "name": "Hermes Quijada International Airport",
      "city": "Rio Grande",
      "state": "Tierra-del-Fuego",
      "country": "AR",
      "elevation": 65,
      "lat": -53.7777,
      "lon": -67.7494,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAWG": {
      "icao": "SAWG",
      "iata": "RGL",
      "name": "Piloto Civil N. Fernandez Airport",
      "city": "Rio Gallegos",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 61,
      "lat": -51.6089,
      "lon": -69.3126,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWH": {
      "icao": "SAWH",
      "iata": "USH",
      "name": "Malvinas Argentinas Airport",
      "city": "Ushuahia",
      "state": "Tierra-del-Fuego",
      "country": "AR",
      "elevation": 102,
      "lat": -54.8433,
      "lon": -68.2958,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAWJ": {
      "icao": "SAWJ",
      "iata": "ULA",
      "name": "Capitan D Daniel Vazquez Airport",
      "city": "San Julian",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 203,
      "lat": -49.3068,
      "lon": -67.8026,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWL": {
      "icao": "SAWL",
      "iata": "",
      "name": "Tolwin Observatory Airport",
      "city": "Tolwin (Lago Fagnano)\/observatorio MET(TAIS)",
      "state": "Tierra-del-Fuego",
      "country": "AR",
      "elevation": 90,
      "lat": -54.521900177,
      "lon": -67.1989974976,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAWM": {
      "icao": "SAWM",
      "iata": "ROY",
      "name": "Rio Mayo Airport",
      "city": "Rio Mayo",
      "state": "Chubut",
      "country": "AR",
      "elevation": 1784,
      "lat": -45.7038993835,
      "lon": -70.2455978394,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAWO": {
      "icao": "SAWO",
      "iata": "",
      "name": "Estacion Aeronaval Airport",
      "city": "Ushuahia",
      "state": "",
      "country": "AR",
      "elevation": 19,
      "lat": -54.8227,
      "lon": -68.3043,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAWP": {
      "icao": "SAWP",
      "iata": "PMQ",
      "name": "Perito Moreno Airport",
      "city": "Perito Moreno",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 1410,
      "lat": -46.5378990173,
      "lon": -70.9786987305,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWR": {
      "icao": "SAWR",
      "iata": "GGS",
      "name": "Gobernador Gregores Airport",
      "city": "Gobernador Gregores",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 356,
      "lat": -48.7831001282,
      "lon": -70.1500015259,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWS": {
      "icao": "SAWS",
      "iata": "JSM",
      "name": "Jose De San Martin Airport",
      "city": "Chubut",
      "state": "Chubut",
      "country": "AR",
      "elevation": 2407,
      "lat": -44.0485992432,
      "lon": -70.4589004517,
      "tz": "America\/Argentina\/Catamarca"
  },
  "SAWT": {
      "icao": "SAWT",
      "iata": "RYO",
      "name": "28 De Noviembre Airport",
      "city": "El Turbio",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 895,
      "lat": -51.6091995239,
      "lon": -72.2217025757,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWU": {
      "icao": "SAWU",
      "iata": "RZA",
      "name": "Santa Cruz Airport",
      "city": "Santa Cruz",
      "state": "Santa-Cruz",
      "country": "AR",
      "elevation": 364,
      "lat": -50.0165,
      "lon": -68.5792,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SAWZ": {
      "icao": "SAWZ",
      "iata": "",
      "name": "Matienzo Base",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 75,
      "lat": -64,
      "lon": -60,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAYB": {
      "icao": "SAYB",
      "iata": "",
      "name": "Base Belgrano II Airport",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 820,
      "lat": -77.8740548037,
      "lon": -34.626159668,
      "tz": "Antarctica\/Rothera"
  },
  "SAYE": {
      "icao": "SAYE",
      "iata": "",
      "name": "Esperanza Base",
      "city": "Hope Bay",
      "state": "",
      "country": "AQ",
      "elevation": 75,
      "lat": -63.4000015259,
      "lon": -56.9832992554,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAYJ": {
      "icao": "SAYJ",
      "iata": "",
      "name": "Jubany Airbase",
      "city": "South Shetland Archipielago- King George Island",
      "state": "",
      "country": "AQ",
      "elevation": 12,
      "lat": -62.2383003235,
      "lon": -58.6665992737,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAYO": {
      "icao": "SAYO",
      "iata": "",
      "name": "Orcadas Base",
      "city": "Laurie Island",
      "state": "",
      "country": "AQ",
      "elevation": 75,
      "lat": -60.7402992249,
      "lon": -44.7425003052,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAYS": {
      "icao": "SAYS",
      "iata": "",
      "name": "San Martin Base",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 75,
      "lat": -68.1166992188,
      "lon": -67.0999984741,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SAZA": {
      "icao": "SAZA",
      "iata": "",
      "name": "Azul Airport",
      "city": "Azul",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 478,
      "lat": -36.8368988037,
      "lon": -59.8802986145,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZB": {
      "icao": "SAZB",
      "iata": "BHI",
      "name": "Comandante Espora Airport",
      "city": "Bahia Blanca",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 246,
      "lat": -38.725,
      "lon": -62.1693,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZC": {
      "icao": "SAZC",
      "iata": "CSZ",
      "name": "Brigadier D.H.E. Ruiz Airport",
      "city": "Coronel Suarez",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 767,
      "lat": -37.4460983276,
      "lon": -61.8893013,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZD": {
      "icao": "SAZD",
      "iata": "",
      "name": "Dolores Airport",
      "city": "Dolores",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 32,
      "lat": -36.3225,
      "lon": -57.7214,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZE": {
      "icao": "SAZE",
      "iata": "",
      "name": "Pigue Airport",
      "city": "Pigue",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 997,
      "lat": -37.6016998291,
      "lon": -62.3781013489,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZF": {
      "icao": "SAZF",
      "iata": "OVR",
      "name": "Olavarria Airport",
      "city": "Olavarria",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 551,
      "lat": -36.8899993896,
      "lon": -60.2165985107,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZG": {
      "icao": "SAZG",
      "iata": "GPO",
      "name": "General Pico Airport",
      "city": "General Pico",
      "state": "La-Pampa",
      "country": "AR",
      "elevation": 459,
      "lat": -35.6962013245,
      "lon": -63.7583007812,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZH": {
      "icao": "SAZH",
      "iata": "OYO",
      "name": "Tres Arroyos Airport",
      "city": "Tres Arroyos",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 400,
      "lat": -38.3869,
      "lon": -60.3297,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZI": {
      "icao": "SAZI",
      "iata": "",
      "name": "Bolivar Airport",
      "city": "Bolivar",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 308,
      "lat": -36.1866,
      "lon": -61.0764,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZJ": {
      "icao": "SAZJ",
      "iata": "",
      "name": "Benito Juarez Airport",
      "city": "Benito Juarez",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 682,
      "lat": -37.7061004639,
      "lon": -59.7919006348,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZK": {
      "icao": "SAZK",
      "iata": "",
      "name": "Cerro Catedral Airport",
      "city": "Cerro Catedral",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 3450,
      "lat": -41.1632995605,
      "lon": -71.4427032471,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZL": {
      "icao": "SAZL",
      "iata": "SST",
      "name": "Santa Teresita Airport",
      "city": "Santa Teresita",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 9,
      "lat": -36.5423,
      "lon": -56.7218,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZM": {
      "icao": "SAZM",
      "iata": "MDQ",
      "name": "Astor Piazzola International Airport",
      "city": "Mar del Plata",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 72,
      "lat": -37.9342,
      "lon": -57.5733,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZN": {
      "icao": "SAZN",
      "iata": "NQN",
      "name": "Presidente Peron Airport",
      "city": "Neuquen",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 895,
      "lat": -38.9490013123,
      "lon": -68.1557006836,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZO": {
      "icao": "SAZO",
      "iata": "NEC",
      "name": "Necochea Airport",
      "city": "Necochea",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 72,
      "lat": -38.4831,
      "lon": -58.8172,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZP": {
      "icao": "SAZP",
      "iata": "PEH",
      "name": "Comodoro Pedro Zanni Airport",
      "city": "Pehuajo",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 278,
      "lat": -35.8446,
      "lon": -61.8576,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZQ": {
      "icao": "SAZQ",
      "iata": "",
      "name": "Rio Colorado Airport",
      "city": "Rio Colorado",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 275,
      "lat": -38.995,
      "lon": -64.1408,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZR": {
      "icao": "SAZR",
      "iata": "RSA",
      "name": "Santa Rosa Airport",
      "city": "Santa Rosa",
      "state": "La-Pampa",
      "country": "AR",
      "elevation": 630,
      "lat": -36.5882987976,
      "lon": -64.2757034302,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZS": {
      "icao": "SAZS",
      "iata": "BRC",
      "name": "San Carlos De Bariloche Airport",
      "city": "San Carlos de Bariloche",
      "state": "Rio-Negro",
      "country": "AR",
      "elevation": 2774,
      "lat": -41.1511993408,
      "lon": -71.1575012207,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZT": {
      "icao": "SAZT",
      "iata": "TDL",
      "name": "Heroes De Malvinas Airport",
      "city": "Tandil",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 574,
      "lat": -37.2374000549,
      "lon": -59.2279014587,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZU": {
      "icao": "SAZU",
      "iata": "",
      "name": "Puelches Airport",
      "city": "Puelches",
      "state": "La-Pampa",
      "country": "AR",
      "elevation": 180,
      "lat": -38.1451034546,
      "lon": -65.9229431152,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZV": {
      "icao": "SAZV",
      "iata": "VLG",
      "name": "Villa Gesell Airport",
      "city": "Villa Gesell",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 32,
      "lat": -37.2354,
      "lon": -57.0292,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZW": {
      "icao": "SAZW",
      "iata": "CUT",
      "name": "Cutral-Co Airport",
      "city": "Cutral-Co",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 2132,
      "lat": -38.9397010803,
      "lon": -69.2646026611,
      "tz": "America\/Argentina\/Salta"
  },
  "SAZX": {
      "icao": "SAZX",
      "iata": "",
      "name": "Nueve De Julio Airport",
      "city": "Nueve de Julio",
      "state": "Buenos-Aires",
      "country": "AR",
      "elevation": 246,
      "lat": -35.3997,
      "lon": -60.9358,
      "tz": "America\/Argentina\/Buenos_Aires"
  },
  "SAZY": {
      "icao": "SAZY",
      "iata": "CPC",
      "name": "Aviador C. Campos Airport",
      "city": "Chapelco\/San Martin de los Andes",
      "state": "Neuquen",
      "country": "AR",
      "elevation": 2569,
      "lat": -40.0754013062,
      "lon": -71.137298584,
      "tz": "America\/Argentina\/Salta"
  },
  "SBAA": {
      "icao": "SBAA",
      "iata": "CDJ",
      "name": "Conceicao do Araguaia Airport",
      "city": "Conceicao Do Araguaia",
      "state": "Para",
      "country": "BR",
      "elevation": 653,
      "lat": -8.3483495712,
      "lon": -49.3014984131,
      "tz": "America\/Araguaina"
  },
  "SBAE": {
      "icao": "SBAE",
      "iata": "JTC",
      "name": "Bauru-Arealva Airport",
      "city": "Bauru",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1949,
      "lat": -22.15778,
      "lon": -49.06833,
      "tz": "America\/Sao_Paulo"
  },
  "SBAF": {
      "icao": "SBAF",
      "iata": "0",
      "name": "Campo Delio Jardim de Mattos Airport",
      "city": "Rio De Janeiro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 110,
      "lat": -22.8750991821,
      "lon": -43.3847007751,
      "tz": "America\/Sao_Paulo"
  },
  "SBAM": {
      "icao": "SBAM",
      "iata": "0",
      "name": "Amapa Airport",
      "city": "Amapa",
      "state": "Amapa",
      "country": "BR",
      "elevation": 45,
      "lat": 2.0775101185,
      "lon": -50.8582000732,
      "tz": "America\/Belem"
  },
  "SBAN": {
      "icao": "SBAN",
      "iata": "",
      "name": "Base Aerea Airport",
      "city": "Anapolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 3731,
      "lat": -16.2292003632,
      "lon": -48.9642982483,
      "tz": "America\/Sao_Paulo"
  },
  "SBAQ": {
      "icao": "SBAQ",
      "iata": "AQA",
      "name": "Araraquara Airport",
      "city": "Araraquara",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2334,
      "lat": -21.8120002747,
      "lon": -48.1329994202,
      "tz": "America\/Sao_Paulo"
  },
  "SBAR": {
      "icao": "SBAR",
      "iata": "AJU",
      "name": "Santa Maria Airport",
      "city": "Aracaju",
      "state": "Sergipe",
      "country": "BR",
      "elevation": 23,
      "lat": -10.984000206,
      "lon": -37.0703010559,
      "tz": "America\/Maceio"
  },
  "SBAS": {
      "icao": "SBAS",
      "iata": "AIF",
      "name": "Marcelo Pires Halzhausen Airport",
      "city": "Assis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1850,
      "lat": -22.64,
      "lon": -50.453056,
      "tz": "America\/Sao_Paulo"
  },
  "SBAT": {
      "icao": "SBAT",
      "iata": "AFL",
      "name": "Alta Floresta Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 948,
      "lat": -9.8663892746,
      "lon": -56.1049995422,
      "tz": "America\/Cuiaba"
  },
  "SBAU": {
      "icao": "SBAU",
      "iata": "ARU",
      "name": "Aracatuba Airport",
      "city": "Aracatuba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1361,
      "lat": -21.1413002014,
      "lon": -50.4247016907,
      "tz": "America\/Sao_Paulo"
  },
  "SBAX": {
      "icao": "SBAX",
      "iata": "AAX",
      "name": "Araxa Airport",
      "city": "Araxa",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3276,
      "lat": -19.5631999969,
      "lon": -46.9603996277,
      "tz": "America\/Sao_Paulo"
  },
  "SBBE": {
      "icao": "SBBE",
      "iata": "BEL",
      "name": "Val de Cans\/Julio Cezar Ribeiro International Airport",
      "city": "Belem",
      "state": "Para",
      "country": "BR",
      "elevation": 54,
      "lat": -1.3792500496,
      "lon": -48.4762992859,
      "tz": "America\/Belem"
  },
  "SBBG": {
      "icao": "SBBG",
      "iata": "BGX",
      "name": "Comandante Gustavo Kraemer Airport",
      "city": "Bage",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 600,
      "lat": -31.390499115,
      "lon": -54.1122016907,
      "tz": "America\/Sao_Paulo"
  },
  "SBBH": {
      "icao": "SBBH",
      "iata": "PLU",
      "name": "Pampulha - Carlos Drummond de Andrade Airport",
      "city": "Belo Horizonte",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2589,
      "lat": -19.8512001038,
      "lon": -43.9505996704,
      "tz": "America\/Sao_Paulo"
  },
  "SBBI": {
      "icao": "SBBI",
      "iata": "BFH",
      "name": "Bacacheri Airport",
      "city": "Curitiba",
      "state": "Parana",
      "country": "BR",
      "elevation": 3057,
      "lat": -25.4050998688,
      "lon": -49.2319984436,
      "tz": "America\/Sao_Paulo"
  },
  "SBBP": {
      "icao": "SBBP",
      "iata": "BJP",
      "name": "Aeroporto Estadual Arthur Siqueira Airport",
      "city": "Braganca Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2887,
      "lat": -22.979167,
      "lon": -46.5375,
      "tz": "America\/Sao_Paulo"
  },
  "SBBQ": {
      "icao": "SBBQ",
      "iata": "QAK",
      "name": "Major Brigadeiro Doorgal Borges Airport",
      "city": "Barbacena",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3657,
      "lat": -21.26720047,
      "lon": -43.761100769,
      "tz": "America\/Sao_Paulo"
  },
  "SBBR": {
      "icao": "SBBR",
      "iata": "BSB",
      "name": "Presidente Juscelino Kubistschek International Airport",
      "city": "Brasilia",
      "state": "Federal-District",
      "country": "BR",
      "elevation": 3497,
      "lat": -15.8691673279,
      "lon": -47.9208335876,
      "tz": "America\/Sao_Paulo"
  },
  "SBBT": {
      "icao": "SBBT",
      "iata": "BAT",
      "name": "Chafei Amsei Airport",
      "city": "Barretos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1898,
      "lat": -20.5844993591,
      "lon": -48.5941009521,
      "tz": "America\/Sao_Paulo"
  },
  "SBBU": {
      "icao": "SBBU",
      "iata": "BAU",
      "name": "Bauru Airport",
      "city": "Bauru",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2025,
      "lat": -22.3449993134,
      "lon": -49.0537986755,
      "tz": "America\/Sao_Paulo"
  },
  "SBBV": {
      "icao": "SBBV",
      "iata": "BVB",
      "name": "Atlas Brasil Cantanhede Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 276,
      "lat": 2.8413889408,
      "lon": -60.6922225952,
      "tz": "America\/Boa_Vista"
  },
  "SBBW": {
      "icao": "SBBW",
      "iata": "BPG",
      "name": "Barra do Garcas Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1147,
      "lat": -15.8613004684,
      "lon": -52.3889007568,
      "tz": "America\/Cuiaba"
  },
  "SBBZ": {
      "icao": "SBBZ",
      "iata": "BZC",
      "name": "Umberto Modiano Airport",
      "city": "Cabo Frio",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 10,
      "lat": -22.7709999084,
      "lon": -41.9628982544,
      "tz": "America\/Sao_Paulo"
  },
  "SBCA": {
      "icao": "SBCA",
      "iata": "CAC",
      "name": "Cascavel Airport",
      "city": "Cascavel",
      "state": "Parana",
      "country": "BR",
      "elevation": 2473,
      "lat": -25.0002994537,
      "lon": -53.5008010864,
      "tz": "America\/Sao_Paulo"
  },
  "SBCB": {
      "icao": "SBCB",
      "iata": "CFB",
      "name": "Cabo Frio Airport",
      "city": "Cabo Frio",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 23,
      "lat": -22.9216995239,
      "lon": -42.0742988586,
      "tz": "America\/Sao_Paulo"
  },
  "SBCC": {
      "icao": "SBCC",
      "iata": "",
      "name": "Cachimbo Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 1762,
      "lat": -9.3339395523,
      "lon": -54.9654006958,
      "tz": "America\/Santarem"
  },
  "SBCD": {
      "icao": "SBCD",
      "iata": "0",
      "name": "Cacador Airport",
      "city": "Cacador",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 3376,
      "lat": -26.78840065,
      "lon": -50.9398002625,
      "tz": "America\/Sao_Paulo"
  },
  "SBCF": {
      "icao": "SBCF",
      "iata": "CNF",
      "name": "Tancredo Neves International Airport",
      "city": "Belo Horizonte",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2715,
      "lat": -19.6244430542,
      "lon": -43.9719429016,
      "tz": "America\/Sao_Paulo"
  },
  "SBCG": {
      "icao": "SBCG",
      "iata": "CGR",
      "name": "Campo Grande Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1833,
      "lat": -20.4687004089,
      "lon": -54.6725006104,
      "tz": "America\/Campo_Grande"
  },
  "SBCH": {
      "icao": "SBCH",
      "iata": "XAP",
      "name": "Chapeco Airport",
      "city": "Chapeco",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2146,
      "lat": -27.1341991425,
      "lon": -52.6566009521,
      "tz": "America\/Sao_Paulo"
  },
  "SBCI": {
      "icao": "SBCI",
      "iata": "CLN",
      "name": "Brig. Lysias Augusto Rodrigues Airport",
      "city": "Carolina",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 565,
      "lat": -7.3204398155,
      "lon": -47.4586982727,
      "tz": "America\/Fortaleza"
  },
  "SBCJ": {
      "icao": "SBCJ",
      "iata": "CKS",
      "name": "Carajas Airport",
      "city": "Carajas",
      "state": "Para",
      "country": "BR",
      "elevation": 2064,
      "lat": -6.1152777672,
      "lon": -50.0013885498,
      "tz": "America\/Belem"
  },
  "SBCM": {
      "icao": "SBCM",
      "iata": "CCM",
      "name": "Forquilhinha - Criciuma Airport",
      "city": "Criciuma",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 93,
      "lat": -28.7244434357,
      "lon": -49.4213905334,
      "tz": "America\/Sao_Paulo"
  },
  "SBCN": {
      "icao": "SBCN",
      "iata": "CLV",
      "name": "Caldas Novas Airport",
      "city": "Caldas Novas",
      "state": "Goias",
      "country": "BR",
      "elevation": 2247,
      "lat": -17.7252998352,
      "lon": -48.6074981689,
      "tz": "America\/Sao_Paulo"
  },
  "SBCO": {
      "icao": "SBCO",
      "iata": "QNS",
      "name": "Canoas Airport",
      "city": "Porto Alegre",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 26,
      "lat": -29.9458999634,
      "lon": -51.1444015503,
      "tz": "America\/Sao_Paulo"
  },
  "SBCP": {
      "icao": "SBCP",
      "iata": "CAW",
      "name": "Bartolomeu Lisandro Airport",
      "city": "Campos Dos Goytacazes",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 57,
      "lat": -21.698299408,
      "lon": -41.301700592,
      "tz": "America\/Sao_Paulo"
  },
  "SBCR": {
      "icao": "SBCR",
      "iata": "CMG",
      "name": "Corumba International Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 461,
      "lat": -19.0119438171,
      "lon": -57.6713905334,
      "tz": "America\/Campo_Grande"
  },
  "SBCT": {
      "icao": "SBCT",
      "iata": "CWB",
      "name": "Afonso Pena Airport",
      "city": "Curitiba",
      "state": "Parana",
      "country": "BR",
      "elevation": 2988,
      "lat": -25.5284996033,
      "lon": -49.1758003235,
      "tz": "America\/Sao_Paulo"
  },
  "SBCV": {
      "icao": "SBCV",
      "iata": "CRQ",
      "name": "Caravelas Airport",
      "city": "Caravelas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 36,
      "lat": -17.652299881,
      "lon": -39.2531013489,
      "tz": "America\/Bahia"
  },
  "SBCX": {
      "icao": "SBCX",
      "iata": "CXJ",
      "name": "Campo dos Bugres Airport",
      "city": "Caxias Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2472,
      "lat": -29.1970996857,
      "lon": -51.1875,
      "tz": "America\/Sao_Paulo"
  },
  "SBCY": {
      "icao": "SBCY",
      "iata": "CGB",
      "name": "Marechal Rondon Airport",
      "city": "Cuiaba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 617,
      "lat": -15.6528997421,
      "lon": -56.1166992188,
      "tz": "America\/Cuiaba"
  },
  "SBCZ": {
      "icao": "SBCZ",
      "iata": "CZS",
      "name": "Cruzeiro do Sul Airport",
      "city": "Cruzeiro Do Sul",
      "state": "Acre",
      "country": "BR",
      "elevation": 637,
      "lat": -7.5999097824,
      "lon": -72.7695007324,
      "tz": "America\/Rio_Branco"
  },
  "SBDB": {
      "icao": "SBDB",
      "iata": "BYO",
      "name": "Bonito Airport",
      "city": "Bonito",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1180,
      "lat": -21.2294445038,
      "lon": -56.456111908,
      "tz": "America\/Campo_Grande"
  },
  "SBDN": {
      "icao": "SBDN",
      "iata": "PPB",
      "name": "Presidente Prudente Airport",
      "city": "Presidente Prudente",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1477,
      "lat": -22.1751003265,
      "lon": -51.4245986938,
      "tz": "America\/Sao_Paulo"
  },
  "SBEG": {
      "icao": "SBEG",
      "iata": "MAO",
      "name": "Eduardo Gomes International Airport",
      "city": "Manaus",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 264,
      "lat": -3.0386099815,
      "lon": -60.0497016907,
      "tz": "America\/Manaus"
  },
  "SBEK": {
      "icao": "SBEK",
      "iata": "JCR",
      "name": "Jacareacanga Airport",
      "city": "Jacareacanga",
      "state": "Para",
      "country": "BR",
      "elevation": 323,
      "lat": -6.2331600189,
      "lon": -57.7769012451,
      "tz": "America\/Santarem"
  },
  "SBEP": {
      "icao": "SBEP",
      "iata": "ESI",
      "name": "Espinosa Airport",
      "city": "Espinosa",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1900,
      "lat": -14.9336944444,
      "lon": -42.81,
      "tz": "America\/Sao_Paulo"
  },
  "SBES": {
      "icao": "SBES",
      "iata": "0",
      "name": "Sao Pedro da Aldeia Airport",
      "city": "Sao Pedro Da Aldeia",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 61,
      "lat": -22.8129005432,
      "lon": -42.0926017761,
      "tz": "America\/Sao_Paulo"
  },
  "SBFI": {
      "icao": "SBFI",
      "iata": "IGU",
      "name": "Cataratas International Airport",
      "city": "Foz Do Iguacu",
      "state": "Parana",
      "country": "BR",
      "elevation": 786,
      "lat": -25.6002788544,
      "lon": -54.4850006104,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SBFL": {
      "icao": "SBFL",
      "iata": "FLN",
      "name": "Hercilio Luz International Airport",
      "city": "Florianopolis",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 16,
      "lat": -27.6702785492,
      "lon": -48.5525016785,
      "tz": "America\/Sao_Paulo"
  },
  "SBFN": {
      "icao": "SBFN",
      "iata": "FEN",
      "name": "Fernando de Noronha Airport",
      "city": "Fernando De Noronha",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 193,
      "lat": -3.854929924,
      "lon": -32.4233016968,
      "tz": "America\/Noronha"
  },
  "SBFU": {
      "icao": "SBFU",
      "iata": "0",
      "name": "Furnas Airport",
      "city": "Sao Jose Da Barra",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2413,
      "lat": -20.7028007507,
      "lon": -46.3353004456,
      "tz": "America\/Sao_Paulo"
  },
  "SBFZ": {
      "icao": "SBFZ",
      "iata": "FOR",
      "name": "Pinto Martins International Airport",
      "city": "Fortaleza",
      "state": "Ceara",
      "country": "BR",
      "elevation": 82,
      "lat": -3.7762799263,
      "lon": -38.5326004028,
      "tz": "America\/Fortaleza"
  },
  "SBGL": {
      "icao": "SBGL",
      "iata": "GIG",
      "name": "Galeao - Antonio Carlos Jobim International Airport",
      "city": "Rio De Janeiro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 28,
      "lat": -22.8099994659,
      "lon": -43.2505569458,
      "tz": "America\/Sao_Paulo"
  },
  "SBGM": {
      "icao": "SBGM",
      "iata": "GJM",
      "name": "Guajara-Mirim Airport",
      "city": "Guajara-Mirim",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 478,
      "lat": -10.7863998413,
      "lon": -65.2847976685,
      "tz": "America\/Porto_Velho"
  },
  "SBGO": {
      "icao": "SBGO",
      "iata": "GYN",
      "name": "Santa Genoveva Airport",
      "city": "Goiania",
      "state": "Goias",
      "country": "BR",
      "elevation": 2450,
      "lat": -16.6319999695,
      "lon": -49.2206993103,
      "tz": "America\/Sao_Paulo"
  },
  "SBGP": {
      "icao": "SBGP",
      "iata": "0",
      "name": "EMBRAER - Unidade Gaviao Peixoto Airport",
      "city": "Gaviao Peixoto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1998,
      "lat": -21.7737007141,
      "lon": -48.4051017761,
      "tz": "America\/Sao_Paulo"
  },
  "SBGR": {
      "icao": "SBGR",
      "iata": "GRU",
      "name": "Guarulhos - Governador Andre Franco Montoro International Airport",
      "city": "Sao Paulo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2459,
      "lat": -23.4355564117,
      "lon": -46.4730567932,
      "tz": "America\/Sao_Paulo"
  },
  "SBGU": {
      "icao": "SBGU",
      "iata": "GPB",
      "name": "Tancredo Thomas de Faria Airport",
      "city": "Guarapuava",
      "state": "Parana",
      "country": "BR",
      "elevation": 3494,
      "lat": -25.3875007629,
      "lon": -51.520198822,
      "tz": "America\/Sao_Paulo"
  },
  "SBGV": {
      "icao": "SBGV",
      "iata": "GVR",
      "name": "Governador Valadares Airport",
      "city": "Governador Valadares",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 561,
      "lat": -18.8952007294,
      "lon": -41.9822006226,
      "tz": "America\/Sao_Paulo"
  },
  "SBGW": {
      "icao": "SBGW",
      "iata": "GUJ",
      "name": "Guaratingueta Airport",
      "city": "Guaratingueta",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1761,
      "lat": -22.7915992737,
      "lon": -45.2047996521,
      "tz": "America\/Sao_Paulo"
  },
  "SBHT": {
      "icao": "SBHT",
      "iata": "ATM",
      "name": "Altamira Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 369,
      "lat": -3.2539100647,
      "lon": -52.2540016174,
      "tz": "America\/Santarem"
  },
  "SBIC": {
      "icao": "SBIC",
      "iata": "ITA",
      "name": "Itacoatiara Airport",
      "city": "Itacoatiara",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 142,
      "lat": -3.1272599697,
      "lon": -58.4812011719,
      "tz": "America\/Manaus"
  },
  "SBIH": {
      "icao": "SBIH",
      "iata": "ITB",
      "name": "Itaituba Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 110,
      "lat": -4.2423400879,
      "lon": -56.0007019043,
      "tz": "America\/Santarem"
  },
  "SBIL": {
      "icao": "SBIL",
      "iata": "IOS",
      "name": "Bahia - Jorge Amado Airport",
      "city": "Ilheus",
      "state": "Bahia",
      "country": "BR",
      "elevation": 15,
      "lat": -14.8159999847,
      "lon": -39.0331993103,
      "tz": "America\/Bahia"
  },
  "SBIP": {
      "icao": "SBIP",
      "iata": "IPN",
      "name": "Usiminas Airport",
      "city": "Ipatinga",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 784,
      "lat": -19.4706993103,
      "lon": -42.4875984192,
      "tz": "America\/Sao_Paulo"
  },
  "SBIT": {
      "icao": "SBIT",
      "iata": "0",
      "name": "Hidroeletrica Airport",
      "city": "Itumbiara",
      "state": "Goias",
      "country": "BR",
      "elevation": 1630,
      "lat": -18.4447002411,
      "lon": -49.2134017944,
      "tz": "America\/Sao_Paulo"
  },
  "SBIZ": {
      "icao": "SBIZ",
      "iata": "IMP",
      "name": "Prefeito Renato Moreira Airport",
      "city": "Imperatriz",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 432,
      "lat": -5.5312900543,
      "lon": -47.4599990845,
      "tz": "America\/Fortaleza"
  },
  "SBJC": {
      "icao": "SBJC",
      "iata": "0",
      "name": "Julio Cesar Airport",
      "city": "Belem",
      "state": "Para",
      "country": "BR",
      "elevation": 52,
      "lat": -1.4141600132,
      "lon": -48.4607009888,
      "tz": "America\/Belem"
  },
  "SBJD": {
      "icao": "SBJD",
      "iata": "",
      "name": "Jundiai Airport",
      "city": "Jundiai",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2484,
      "lat": -23.1803690757,
      "lon": -46.9444084167,
      "tz": "America\/Sao_Paulo"
  },
  "SBJE": {
      "icao": "SBJE",
      "iata": "JJD",
      "name": "Comte. Ariston Pessoa Regional Airport",
      "city": "Jijoca de Jericoacoara",
      "state": "Ceara",
      "country": "BR",
      "elevation": 89,
      "lat": -2.906667,
      "lon": -40.358056,
      "tz": "America\/Fortaleza"
  },
  "SBJF": {
      "icao": "SBJF",
      "iata": "JDF",
      "name": "Francisco de Assis Airport",
      "city": "Juiz De Fora",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2989,
      "lat": -21.7915000916,
      "lon": -43.3867988586,
      "tz": "America\/Sao_Paulo"
  },
  "SBJP": {
      "icao": "SBJP",
      "iata": "JPA",
      "name": "Presidente Castro Pinto Airport",
      "city": "Joao Pessoa",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 217,
      "lat": -7.1458330154,
      "lon": -34.9486122131,
      "tz": "America\/Fortaleza"
  },
  "SBJR": {
      "icao": "SBJR",
      "iata": "",
      "name": "Jacarepagua - Roberto Marinho Airport",
      "city": "Rio De Janeiro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 10,
      "lat": -22.9874992371,
      "lon": -43.3699989319,
      "tz": "America\/Sao_Paulo"
  },
  "SBJU": {
      "icao": "SBJU",
      "iata": "JDO",
      "name": "Orlando Bezerra de Menezes Airport",
      "city": "Juazeiro Do Norte",
      "state": "Ceara",
      "country": "BR",
      "elevation": 1392,
      "lat": -7.2189598084,
      "lon": -39.2700996399,
      "tz": "America\/Fortaleza"
  },
  "SBJV": {
      "icao": "SBJV",
      "iata": "JOI",
      "name": "Lauro Carneiro de Loyola Airport",
      "city": "Joinville",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 15,
      "lat": -26.2245006561,
      "lon": -48.7974014282,
      "tz": "America\/Sao_Paulo"
  },
  "SBKG": {
      "icao": "SBKG",
      "iata": "CPV",
      "name": "Presidente Joao Suassuna Airport",
      "city": "Campina Grande",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 1646,
      "lat": -7.2699198723,
      "lon": -35.8964004517,
      "tz": "America\/Fortaleza"
  },
  "SBKP": {
      "icao": "SBKP",
      "iata": "VCP",
      "name": "Viracopos International Airport",
      "city": "Campinas",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2170,
      "lat": -23.0074005127,
      "lon": -47.1344985962,
      "tz": "America\/Sao_Paulo"
  },
  "SBLE": {
      "icao": "SBLE",
      "iata": "LEC",
      "name": "Chapada Diamantina Airport",
      "city": "Lencois",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1676,
      "lat": -12.4822998047,
      "lon": -41.2770004272,
      "tz": "America\/Bahia"
  },
  "SBLJ": {
      "icao": "SBLJ",
      "iata": "LAJ",
      "name": "Lages Airport",
      "city": "Lages",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 3065,
      "lat": -27.7821006775,
      "lon": -50.28150177,
      "tz": "America\/Sao_Paulo"
  },
  "SBLN": {
      "icao": "SBLN",
      "iata": "LIP",
      "name": "Lins Airport",
      "city": "Lins",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1559,
      "lat": -21.6639995575,
      "lon": -49.7304992676,
      "tz": "America\/Sao_Paulo"
  },
  "SBLO": {
      "icao": "SBLO",
      "iata": "LDB",
      "name": "Governador Jose Richa Airport",
      "city": "Londrina",
      "state": "Parana",
      "country": "BR",
      "elevation": 1867,
      "lat": -23.3335990906,
      "lon": -51.1301002502,
      "tz": "America\/Sao_Paulo"
  },
  "SBLP": {
      "icao": "SBLP",
      "iata": "LAZ",
      "name": "Bom Jesus da Lapa Airport",
      "city": "Bom Jesus Da Lapa",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1454,
      "lat": -13.2621002197,
      "lon": -43.4081001282,
      "tz": "America\/Bahia"
  },
  "SBLS": {
      "icao": "SBLS",
      "iata": "0",
      "name": "Lagoa Santa Airport",
      "city": "Lagoa Santa",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2795,
      "lat": -19.6616001129,
      "lon": -43.8964004517,
      "tz": "America\/Sao_Paulo"
  },
  "SBMA": {
      "icao": "SBMA",
      "iata": "MAB",
      "name": "Maraba Airport",
      "city": "Maraba",
      "state": "Para",
      "country": "BR",
      "elevation": 357,
      "lat": -5.3685898781,
      "lon": -49.1380004883,
      "tz": "America\/Belem"
  },
  "SBMC": {
      "icao": "SBMC",
      "iata": "MQH",
      "name": "Minacu Airport",
      "city": "Minacu",
      "state": "Goias",
      "country": "BR",
      "elevation": 1401,
      "lat": -13.5490999222,
      "lon": -48.1953010559,
      "tz": "America\/Sao_Paulo"
  },
  "SBMD": {
      "icao": "SBMD",
      "iata": "MEU",
      "name": "Monte Dourado Airport",
      "city": "Almeirim",
      "state": "Para",
      "country": "BR",
      "elevation": 677,
      "lat": -0.8898389935,
      "lon": -52.6021995544,
      "tz": "America\/Santarem"
  },
  "SBME": {
      "icao": "SBME",
      "iata": "MEA",
      "name": "Macae Airport",
      "city": "Macae",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 8,
      "lat": -22.343000412,
      "lon": -41.7659988403,
      "tz": "America\/Sao_Paulo"
  },
  "SBMG": {
      "icao": "SBMG",
      "iata": "MGF",
      "name": "Regional de Maringa - Silvio Nane Junior Airport",
      "city": "Maringa",
      "state": "Parana",
      "country": "BR",
      "elevation": 1788,
      "lat": -23.4794445038,
      "lon": -52.01222229,
      "tz": "America\/Sao_Paulo"
  },
  "SBMK": {
      "icao": "SBMK",
      "iata": "MOC",
      "name": "Mario Ribeiro Airport",
      "city": "Montes Claros",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2191,
      "lat": -16.7068996429,
      "lon": -43.818901062,
      "tz": "America\/Sao_Paulo"
  },
  "SBML": {
      "icao": "SBML",
      "iata": "MII",
      "name": "Marilia Airport",
      "city": "Marilia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2122,
      "lat": -22.1968994141,
      "lon": -49.926399231,
      "tz": "America\/Sao_Paulo"
  },
  "SBMN": {
      "icao": "SBMN",
      "iata": "PLL",
      "name": "Ponta Pelada Airport",
      "city": "Manaus",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 267,
      "lat": -3.1460399628,
      "lon": -59.9863014221,
      "tz": "America\/Manaus"
  },
  "SBMO": {
      "icao": "SBMO",
      "iata": "MCZ",
      "name": "Zumbi dos Palmares Airport",
      "city": "Maceio",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 387,
      "lat": -9.5108098984,
      "lon": -35.7916984558,
      "tz": "America\/Maceio"
  },
  "SBMQ": {
      "icao": "SBMQ",
      "iata": "MCP",
      "name": "Alberto Alcolumbre Airport",
      "city": "Macapa",
      "state": "Amapa",
      "country": "BR",
      "elevation": 56,
      "lat": 0.0506640002,
      "lon": -51.0722007751,
      "tz": "America\/Belem"
  },
  "SBMS": {
      "icao": "SBMS",
      "iata": "MVF",
      "name": "Dix-Sept Rosado Airport",
      "city": "Mossoro",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 76,
      "lat": -5.2019200325,
      "lon": -37.3642997742,
      "tz": "America\/Fortaleza"
  },
  "SBMT": {
      "icao": "SBMT",
      "iata": "SAO",
      "name": "Campo de Marte Airport",
      "city": "Sao Paulo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2368,
      "lat": -23.5090999603,
      "lon": -46.6377983093,
      "tz": "America\/Sao_Paulo"
  },
  "SBMY": {
      "icao": "SBMY",
      "iata": "MNX",
      "name": "Manicore Airport",
      "city": "Manicore",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 174,
      "lat": -5.8113799095,
      "lon": -61.278301239,
      "tz": "America\/Manaus"
  },
  "SBNF": {
      "icao": "SBNF",
      "iata": "NVT",
      "name": "Ministro Victor Konder International Airport",
      "city": "Navegantes",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 18,
      "lat": -26.8799991608,
      "lon": -48.6514015198,
      "tz": "America\/Sao_Paulo"
  },
  "SBNM": {
      "icao": "SBNM",
      "iata": "GEL",
      "name": "Santo Angelo Airport",
      "city": "Santo Angelo",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1056,
      "lat": -28.2817001343,
      "lon": -54.1691017151,
      "tz": "America\/Sao_Paulo"
  },
  "SBNT": {
      "icao": "SBNT",
      "iata": "NAT",
      "name": "Augusto Severo Airport",
      "city": "Natal",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 169,
      "lat": -5.9114198685,
      "lon": -35.2476997375,
      "tz": "America\/Fortaleza"
  },
  "SBOI": {
      "icao": "SBOI",
      "iata": "OYK",
      "name": "Oiapoque Airport",
      "city": "Oiapoque",
      "state": "",
      "country": "BR",
      "elevation": 63,
      "lat": 3.8554899693,
      "lon": -51.7969017029,
      "tz": "America\/Belem"
  },
  "SBPA": {
      "icao": "SBPA",
      "iata": "POA",
      "name": "Salgado Filho Airport",
      "city": "Porto Alegre",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 11,
      "lat": -29.9944000244,
      "lon": -51.1713981628,
      "tz": "America\/Sao_Paulo"
  },
  "SBPB": {
      "icao": "SBPB",
      "iata": "PHB",
      "name": "Prefeito Doutor Joao Silva Filho Airport",
      "city": "Parnaiba",
      "state": "Piaui",
      "country": "BR",
      "elevation": 16,
      "lat": -2.8937499523,
      "lon": -41.7319984436,
      "tz": "America\/Fortaleza"
  },
  "SBPC": {
      "icao": "SBPC",
      "iata": "POO",
      "name": "Pocos de Caldas Airport",
      "city": "Pocos De Caldas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 4135,
      "lat": -21.843000412,
      "lon": -46.5679016113,
      "tz": "America\/Sao_Paulo"
  },
  "SBPF": {
      "icao": "SBPF",
      "iata": "PFB",
      "name": "Lauro Kurtz Airport",
      "city": "Passo Fundo",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2376,
      "lat": -28.2439994812,
      "lon": -52.3265991211,
      "tz": "America\/Sao_Paulo"
  },
  "SBPJ": {
      "icao": "SBPJ",
      "iata": "PMW",
      "name": "Brigadeiro Lysias Rodrigues Airport",
      "city": "Palmas",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 774,
      "lat": -10.2915000916,
      "lon": -48.3569984436,
      "tz": "America\/Araguaina"
  },
  "SBPK": {
      "icao": "SBPK",
      "iata": "PET",
      "name": "Pelotas Airport",
      "city": "Pelotas",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 59,
      "lat": -31.7183990479,
      "lon": -52.3277015686,
      "tz": "America\/Sao_Paulo"
  },
  "SBPL": {
      "icao": "SBPL",
      "iata": "PNZ",
      "name": "Senador Nilo Coelho Airport",
      "city": "Petrolina",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1263,
      "lat": -9.3624095917,
      "lon": -40.5690994263,
      "tz": "America\/Recife"
  },
  "SBPN": {
      "icao": "SBPN",
      "iata": "PNB",
      "name": "Porto Nacional Airport",
      "city": "Porto Nacional",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 870,
      "lat": -10.7194004059,
      "lon": -48.3997001648,
      "tz": "America\/Araguaina"
  },
  "SBPP": {
      "icao": "SBPP",
      "iata": "PMG",
      "name": "Ponta Pora Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2156,
      "lat": -22.5496006012,
      "lon": -55.7025985718,
      "tz": "America\/Asuncion"
  },
  "SBPR": {
      "icao": "SBPR",
      "iata": "0",
      "name": "Carlos Prates Airport",
      "city": "Belo Horizonte",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3044,
      "lat": -19.9092998505,
      "lon": -43.9906997681,
      "tz": "America\/Sao_Paulo"
  },
  "SBPS": {
      "icao": "SBPS",
      "iata": "BPS",
      "name": "Porto Seguro Airport",
      "city": "Porto Seguro",
      "state": "Bahia",
      "country": "BR",
      "elevation": 168,
      "lat": -16.4386005402,
      "lon": -39.0808982849,
      "tz": "America\/Bahia"
  },
  "SBPV": {
      "icao": "SBPV",
      "iata": "PVH",
      "name": "Governador Jorge Teixeira de Oliveira Airport",
      "city": "Porto Velho",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 290,
      "lat": -8.7092895508,
      "lon": -63.9023017883,
      "tz": "America\/Porto_Velho"
  },
  "SBQV": {
      "icao": "SBQV",
      "iata": "VDC",
      "name": "Vitoria da Conquista Airport",
      "city": "Vitoria Da Conquista",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3002,
      "lat": -14.8627996445,
      "lon": -40.8630981445,
      "tz": "America\/Bahia"
  },
  "SBRB": {
      "icao": "SBRB",
      "iata": "RBR",
      "name": "Placido de Castro Airport",
      "city": "Rio Branco",
      "state": "Acre",
      "country": "BR",
      "elevation": 633,
      "lat": -9.868888855,
      "lon": -67.8980560303,
      "tz": "America\/Rio_Branco"
  },
  "SBRF": {
      "icao": "SBRF",
      "iata": "REC",
      "name": "Guararapes - Gilberto Freyre International Airport",
      "city": "Recife",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 33,
      "lat": -8.1264896393,
      "lon": -34.9235992432,
      "tz": "America\/Recife"
  },
  "SBRJ": {
      "icao": "SBRJ",
      "iata": "SDU",
      "name": "Santos Dumont Airport",
      "city": "Rio De Janeiro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 11,
      "lat": -22.9104995728,
      "lon": -43.1631011963,
      "tz": "America\/Sao_Paulo"
  },
  "SBRP": {
      "icao": "SBRP",
      "iata": "RAO",
      "name": "Leite Lopes Airport",
      "city": "Ribeirao Preto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1806,
      "lat": -21.1363887787,
      "lon": -47.7766685486,
      "tz": "America\/Sao_Paulo"
  },
  "SBRR": {
      "icao": "SBRR",
      "iata": "BRB",
      "name": "Barreirinhas Airport",
      "city": "",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 40,
      "lat": -2.7555555556,
      "lon": -42.81,
      "tz": "America\/Fortaleza"
  },
  "SBSC": {
      "icao": "SBSC",
      "iata": "SNZ",
      "name": "Santa Cruz Airport",
      "city": "Rio De Janeiro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 10,
      "lat": -22.9323997498,
      "lon": -43.7191009521,
      "tz": "America\/Sao_Paulo"
  },
  "SBSJ": {
      "icao": "SBSJ",
      "iata": "SJK",
      "name": "Professor Urbano Ernesto Stumpf Airport",
      "city": "Sao Jose Dos Campos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2120,
      "lat": -23.2292003632,
      "lon": -45.8614997864,
      "tz": "America\/Sao_Paulo"
  },
  "SBSL": {
      "icao": "SBSL",
      "iata": "SLZ",
      "name": "Marechal Cunha Machado International Airport",
      "city": "Sao Luis",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 178,
      "lat": -2.5853600502,
      "lon": -44.2341003418,
      "tz": "America\/Fortaleza"
  },
  "SBSM": {
      "icao": "SBSM",
      "iata": "RIA",
      "name": "Santa Maria Airport",
      "city": "Santa Maria",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 287,
      "lat": -29.7113990784,
      "lon": -53.6882019043,
      "tz": "America\/Sao_Paulo"
  },
  "SBSN": {
      "icao": "SBSN",
      "iata": "STM",
      "name": "Maestro Wilson Fonseca Airport",
      "city": "Santarem",
      "state": "Para",
      "country": "BR",
      "elevation": 198,
      "lat": -2.4247219563,
      "lon": -54.7858314514,
      "tz": "America\/Santarem"
  },
  "SBSP": {
      "icao": "SBSP",
      "iata": "CGH",
      "name": "Congonhas Airport",
      "city": "Sao Paulo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2631,
      "lat": -23.6261100769,
      "lon": -46.6563873291,
      "tz": "America\/Sao_Paulo"
  },
  "SBSR": {
      "icao": "SBSR",
      "iata": "SJP",
      "name": "Sao Jose do Rio Preto Airport",
      "city": "Sao Jose Do Rio Preto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1784,
      "lat": -20.8166007996,
      "lon": -49.40650177,
      "tz": "America\/Sao_Paulo"
  },
  "SBST": {
      "icao": "SBST",
      "iata": "SSZ",
      "name": "Base Aerea de Santos Airport",
      "city": "Guaruja",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 10,
      "lat": -23.9280567169,
      "lon": -46.2997207642,
      "tz": "America\/Sao_Paulo"
  },
  "SBSV": {
      "icao": "SBSV",
      "iata": "SSA",
      "name": "Deputado Luiz Eduardo Magalhaes International Airport",
      "city": "Salvador",
      "state": "Bahia",
      "country": "BR",
      "elevation": 64,
      "lat": -12.9086112976,
      "lon": -38.3224983215,
      "tz": "America\/Bahia"
  },
  "SBTA": {
      "icao": "SBTA",
      "iata": "QHP",
      "name": "Base de Aviacao de Taubate Airport",
      "city": "Taubate",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1908,
      "lat": -23.0401000977,
      "lon": -45.5159988403,
      "tz": "America\/Sao_Paulo"
  },
  "SBTB": {
      "icao": "SBTB",
      "iata": "TMT",
      "name": "Trombetas Airport",
      "city": "Oriximina",
      "state": "Para",
      "country": "BR",
      "elevation": 287,
      "lat": -1.4895999432,
      "lon": -56.3968009949,
      "tz": "America\/Santarem"
  },
  "SBTC": {
      "icao": "SBTC",
      "iata": "UNA",
      "name": "Hotel Transamerica Airport",
      "city": "Una",
      "state": "Bahia",
      "country": "BR",
      "elevation": 20,
      "lat": -15.3551998138,
      "lon": -38.9990005493,
      "tz": "America\/Bahia"
  },
  "SBTD": {
      "icao": "SBTD",
      "iata": "TOW",
      "name": "Toledo Airport",
      "city": "Toledo",
      "state": "Parana",
      "country": "BR",
      "elevation": 1843,
      "lat": -24.6863002777,
      "lon": -53.6974983215,
      "tz": "America\/Sao_Paulo"
  },
  "SBTE": {
      "icao": "SBTE",
      "iata": "THE",
      "name": "Senador Petronio Portela Airport",
      "city": "Teresina",
      "state": "Piaui",
      "country": "BR",
      "elevation": 219,
      "lat": -5.0599398613,
      "lon": -42.8235015869,
      "tz": "America\/Fortaleza"
  },
  "SBTF": {
      "icao": "SBTF",
      "iata": "TFF",
      "name": "Tefe Airport",
      "city": "Tefe",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 188,
      "lat": -3.3829400539,
      "lon": -64.7240982056,
      "tz": "America\/Manaus"
  },
  "SBTG": {
      "icao": "SBTG",
      "iata": "TJL",
      "name": "Plinio Alarcom Airport",
      "city": "Tres Lagoas",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1075,
      "lat": -20.754444,
      "lon": -51.683889,
      "tz": "America\/Campo_Grande"
  },
  "SBTK": {
      "icao": "SBTK",
      "iata": "TRQ",
      "name": "Tarauaca Airport",
      "city": "Tarauaca",
      "state": "Acre",
      "country": "BR",
      "elevation": 646,
      "lat": -8.1552600861,
      "lon": -70.7833023071,
      "tz": "America\/Rio_Branco"
  },
  "SBTL": {
      "icao": "SBTL",
      "iata": "TEC",
      "name": "Telemaco Borba Airport",
      "city": "Telemaco Borba",
      "state": "Parana",
      "country": "BR",
      "elevation": 2610,
      "lat": -24.3178005219,
      "lon": -50.651599884,
      "tz": "America\/Sao_Paulo"
  },
  "SBTR": {
      "icao": "SBTR",
      "iata": "0",
      "name": "Torres Airport",
      "city": "Torres",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 26,
      "lat": -29.414899826,
      "lon": -49.8100013733,
      "tz": "America\/Sao_Paulo"
  },
  "SBTS": {
      "icao": "SBTS",
      "iata": "",
      "name": "Tirios Airport",
      "city": "Obidos",
      "state": "Para",
      "country": "BR",
      "elevation": 1127,
      "lat": 2.2234699726,
      "lon": -55.9460983276,
      "tz": "America\/Santarem"
  },
  "SBTT": {
      "icao": "SBTT",
      "iata": "TBT",
      "name": "Tabatinga Airport",
      "city": "Tabatinga",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 279,
      "lat": -4.2556700706,
      "lon": -69.935798645,
      "tz": "America\/Bogota"
  },
  "SBTU": {
      "icao": "SBTU",
      "iata": "TUR",
      "name": "Tucurui Airport",
      "city": "Tucurui",
      "state": "Para",
      "country": "BR",
      "elevation": 830,
      "lat": -3.7860100269,
      "lon": -49.7202987671,
      "tz": "America\/Belem"
  },
  "SBTV": {
      "icao": "SBTV",
      "iata": "",
      "name": "Terravista Airport",
      "city": "Porto Seguro",
      "state": "",
      "country": "BR",
      "elevation": 174,
      "lat": -16.5408325195,
      "lon": -39.1077766418,
      "tz": "America\/Bahia"
  },
  "SBUA": {
      "icao": "SBUA",
      "iata": "SJL",
      "name": "Sao Gabriel da Cachoeira Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 251,
      "lat": -0.14835,
      "lon": -66.9855,
      "tz": "America\/Manaus"
  },
  "SBUF": {
      "icao": "SBUF",
      "iata": "PAV",
      "name": "Paulo Afonso Airport",
      "city": "Paulo Afonso",
      "state": "Bahia",
      "country": "BR",
      "elevation": 883,
      "lat": -9.4008798599,
      "lon": -38.2505989075,
      "tz": "America\/Bahia"
  },
  "SBUG": {
      "icao": "SBUG",
      "iata": "URG",
      "name": "Rubem Berta Airport",
      "city": "Uruguaiana",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 256,
      "lat": -29.7821998596,
      "lon": -57.0382003784,
      "tz": "America\/Sao_Paulo"
  },
  "SBUL": {
      "icao": "SBUL",
      "iata": "UDI",
      "name": "Ten. Cel. Aviador Cesar Bombonato Airport",
      "city": "Uberlandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3094,
      "lat": -18.8836116791,
      "lon": -48.225276947,
      "tz": "America\/Sao_Paulo"
  },
  "SBUR": {
      "icao": "SBUR",
      "iata": "UBA",
      "name": "Mario de Almeida Franco Airport",
      "city": "Uberaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2655,
      "lat": -19.7647228241,
      "lon": -47.9661102295,
      "tz": "America\/Sao_Paulo"
  },
  "SBVG": {
      "icao": "SBVG",
      "iata": "VAG",
      "name": "Major Brigadeiro Trompowsky Airport",
      "city": "Varginha",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3025,
      "lat": -21.5900993347,
      "lon": -45.4733009338,
      "tz": "America\/Sao_Paulo"
  },
  "SBVH": {
      "icao": "SBVH",
      "iata": "BVH",
      "name": "Vilhena Airport",
      "city": "Vilhena",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 2018,
      "lat": -12.6943998337,
      "lon": -60.0983009338,
      "tz": "America\/Cuiaba"
  },
  "SBVT": {
      "icao": "SBVT",
      "iata": "VIX",
      "name": "Eurico de Aguiar Salles Airport",
      "city": "Vitoria",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 11,
      "lat": -20.2580566406,
      "lon": -40.2863883972,
      "tz": "America\/Sao_Paulo"
  },
  "SBYA": {
      "icao": "SBYA",
      "iata": "0",
      "name": "Iauarete Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 345,
      "lat": 0.6075000167,
      "lon": -69.185798645,
      "tz": "America\/Bogota"
  },
  "SBYS": {
      "icao": "SBYS",
      "iata": "QPS",
      "name": "Campo Fontenelle Airport",
      "city": "Pirassununga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1968,
      "lat": -21.9846000671,
      "lon": -47.3348007202,
      "tz": "America\/Sao_Paulo"
  },
  "SBZM": {
      "icao": "SBZM",
      "iata": "IZA",
      "name": "Zona da Mata Regional Airport",
      "city": "Juiz De Fora",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1348,
      "lat": -21.5130558014,
      "lon": -43.1730575562,
      "tz": "America\/Sao_Paulo"
  },
  "SC00": {
      "icao": "SC00",
      "iata": "",
      "name": "Triple Tree Airport",
      "city": "Woodruff",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 680,
      "lat": 34.6744003296,
      "lon": -82.0014038086,
      "tz": "America\/New_York"
  },
  "SC01": {
      "icao": "SC01",
      "iata": "",
      "name": "Sugar Hill Airport",
      "city": "Allendale",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 203,
      "lat": 33.0390014648,
      "lon": -81.2942962646,
      "tz": "America\/New_York"
  },
  "SC03": {
      "icao": "SC03",
      "iata": "",
      "name": "Mc Neil Airport",
      "city": "Cameron",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 170,
      "lat": 33.5570983887,
      "lon": -80.648399353,
      "tz": "America\/New_York"
  },
  "SC05": {
      "icao": "SC05",
      "iata": "",
      "name": "Laurel Hill Plantation Airport",
      "city": "Beaufort",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 10,
      "lat": 32.4912986755,
      "lon": -80.6150970459,
      "tz": "America\/New_York"
  },
  "SC06": {
      "icao": "SC06",
      "iata": "",
      "name": "Pluff Mud Field",
      "city": "Charleston",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 15,
      "lat": 32.6320991516,
      "lon": -80.092300415,
      "tz": "America\/New_York"
  },
  "SC07": {
      "icao": "SC07",
      "iata": "",
      "name": "Alan's Airport",
      "city": "St Matthews",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 369,
      "lat": 33.6758003235,
      "lon": -80.9496994019,
      "tz": "America\/New_York"
  },
  "SC08": {
      "icao": "SC08",
      "iata": "",
      "name": "Bethel-Lake Wylie Airport",
      "city": "York",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 670,
      "lat": 35.0500984192,
      "lon": -81.137298584,
      "tz": "America\/New_York"
  },
  "SC10": {
      "icao": "SC10",
      "iata": "",
      "name": "State Line Ultraport\/Flightpark Ultralightport",
      "city": "Chesnee",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 870,
      "lat": 35.1785011292,
      "lon": -81.8179016113,
      "tz": "America\/New_York"
  },
  "SC12": {
      "icao": "SC12",
      "iata": "",
      "name": "Davis Airport",
      "city": "Garnett",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 82,
      "lat": 32.6721000671,
      "lon": -81.3112030029,
      "tz": "America\/New_York"
  },
  "SC13": {
      "icao": "SC13",
      "iata": "",
      "name": "Darden Airport",
      "city": "Gaston",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 450,
      "lat": 33.8406982422,
      "lon": -81.1837005615,
      "tz": "America\/New_York"
  },
  "SC14": {
      "icao": "SC14",
      "iata": "",
      "name": "Shealy Airport",
      "city": "Gilbert",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 380,
      "lat": 34.0625991821,
      "lon": -81.4097976685,
      "tz": "America\/New_York"
  },
  "SC15": {
      "icao": "SC15",
      "iata": "",
      "name": "Airy Hall Airport",
      "city": "Green Pond",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 20,
      "lat": 32.6320991516,
      "lon": -80.5039978027,
      "tz": "America\/New_York"
  },
  "SC16": {
      "icao": "SC16",
      "iata": "",
      "name": "Curry Airport",
      "city": "Hartsville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 199,
      "lat": 34.3778991699,
      "lon": -80.0053024292,
      "tz": "America\/New_York"
  },
  "SC17": {
      "icao": "SC17",
      "iata": "",
      "name": "Russell Airport",
      "city": "Holly Hill",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 110,
      "lat": 33.3578987122,
      "lon": -80.4598007202,
      "tz": "America\/New_York"
  },
  "SC19": {
      "icao": "SC19",
      "iata": "",
      "name": "Lamar Airport",
      "city": "Lamar",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 175,
      "lat": 34.1677017212,
      "lon": -80.0775985718,
      "tz": "America\/New_York"
  },
  "SC1A": {
      "icao": "SC1A",
      "iata": "",
      "name": "Canchones West Airport",
      "city": "Canchones",
      "state": "Tarapaca",
      "country": "CL",
      "elevation": 3251,
      "lat": -20.4270992279,
      "lon": -69.6415023804,
      "tz": "America\/Santiago"
  },
  "SC20": {
      "icao": "SC20",
      "iata": "",
      "name": "Harman Airport",
      "city": "Langley",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 458,
      "lat": 33.4842987061,
      "lon": -81.8258972168,
      "tz": "America\/New_York"
  },
  "SC21": {
      "icao": "SC21",
      "iata": "",
      "name": "Myrtle Beach Hardee Airpark",
      "city": "Loris",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 45,
      "lat": 33.9417991638,
      "lon": -78.8330993652,
      "tz": "America\/New_York"
  },
  "SC23": {
      "icao": "SC23",
      "iata": "",
      "name": "Eagles Nest-Fairview Airpark",
      "city": "Pelion",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 460,
      "lat": 33.763999939,
      "lon": -81.3587036133,
      "tz": "America\/New_York"
  },
  "SC24": {
      "icao": "SC24",
      "iata": "",
      "name": "Eagle Ridge Airport",
      "city": "Seneca",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 850,
      "lat": 34.6716995239,
      "lon": -82.9135971069,
      "tz": "America\/New_York"
  },
  "SC25": {
      "icao": "SC25",
      "iata": "",
      "name": "Ross Strip",
      "city": "W Society Hill",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 190,
      "lat": 34.4226989746,
      "lon": -79.884803772,
      "tz": "America\/New_York"
  },
  "SC26": {
      "icao": "SC26",
      "iata": "",
      "name": "Hawks Nest Farm Airport",
      "city": "West Union",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1050,
      "lat": 34.8081016541,
      "lon": -82.9985961914,
      "tz": "America\/New_York"
  },
  "SC27": {
      "icao": "SC27",
      "iata": "",
      "name": "Tallon Field",
      "city": "Lynchburg",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 190,
      "lat": 34.1010017395,
      "lon": -80.1256027222,
      "tz": "America\/New_York"
  },
  "SC28": {
      "icao": "SC28",
      "iata": "",
      "name": "Javika Airport",
      "city": "Surfside Beach",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 26,
      "lat": 33.6520996094,
      "lon": -78.9942016602,
      "tz": "America\/New_York"
  },
  "SC29": {
      "icao": "SC29",
      "iata": "",
      "name": "Mc Kay Airport",
      "city": "Timmonsville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 135,
      "lat": 34.1106987,
      "lon": -79.9014968872,
      "tz": "America\/New_York"
  },
  "SC32": {
      "icao": "SC32",
      "iata": "",
      "name": "Sky Valley Airpark",
      "city": "Pelzer",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 900,
      "lat": 34.6503982544,
      "lon": -82.5035018921,
      "tz": "America\/New_York"
  },
  "SC33": {
      "icao": "SC33",
      "iata": "",
      "name": "Cox Airport",
      "city": "Simpsonville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1000,
      "lat": 34.8242988586,
      "lon": -82.259803772,
      "tz": "America\/New_York"
  },
  "SC34": {
      "icao": "SC34",
      "iata": "",
      "name": "Iva Field",
      "city": "Iva",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 660,
      "lat": 34.2971000671,
      "lon": -82.6837005615,
      "tz": "America\/New_York"
  },
  "SC35": {
      "icao": "SC35",
      "iata": "",
      "name": "Ehrhardt Airport",
      "city": "Ehrhardt",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 146,
      "lat": 33.0895996094,
      "lon": -81.0082015991,
      "tz": "America\/New_York"
  },
  "SC36": {
      "icao": "SC36",
      "iata": "",
      "name": "Emery Airport",
      "city": "Greer",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1220,
      "lat": 35.1246986389,
      "lon": -82.3264007568,
      "tz": "America\/New_York"
  },
  "SC37": {
      "icao": "SC37",
      "iata": "",
      "name": "Crosswinds-Wilson Private Airport",
      "city": "Cross",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 90,
      "lat": 33.2718009949,
      "lon": -80.2543029785,
      "tz": "America\/New_York"
  },
  "SC38": {
      "icao": "SC38",
      "iata": "",
      "name": "Pocotaligo Airport",
      "city": "Manning",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 112,
      "lat": 33.7075004578,
      "lon": -80.0805969238,
      "tz": "America\/New_York"
  },
  "SC39": {
      "icao": "SC39",
      "iata": "",
      "name": "Green Pond Airport",
      "city": "Woodruff",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 788,
      "lat": 34.808553284,
      "lon": -82.0773768425,
      "tz": "America\/New_York"
  },
  "SC40": {
      "icao": "SC40",
      "iata": "",
      "name": "Pearson's Farm Airport",
      "city": "Spartanburg",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 850,
      "lat": 35.0573005676,
      "lon": -81.9448013306,
      "tz": "America\/New_York"
  },
  "SC41": {
      "icao": "SC41",
      "iata": "",
      "name": "Palmetto Air Plantation Airport",
      "city": "Manning",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 140,
      "lat": 33.66609955,
      "lon": -80.24389648,
      "tz": "America\/New_York"
  },
  "SC42": {
      "icao": "SC42",
      "iata": "",
      "name": "Hondarosa Airport",
      "city": "Charleston",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 13,
      "lat": 32.6869010925,
      "lon": -80.1063995361,
      "tz": "America\/New_York"
  },
  "SC43": {
      "icao": "SC43",
      "iata": "",
      "name": "Moore's Field",
      "city": "Williamston",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 871,
      "lat": 34.5937004089,
      "lon": -82.3990020752,
      "tz": "America\/New_York"
  },
  "SC44": {
      "icao": "SC44",
      "iata": "",
      "name": "El Porvenir Airpark",
      "city": "Boykin",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 250,
      "lat": 34.12879944,
      "lon": -80.54730225,
      "tz": "America\/New_York"
  },
  "SC45": {
      "icao": "SC45",
      "iata": "",
      "name": "Gilbert International Airport",
      "city": "Gilbert",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 551,
      "lat": 33.8839988708,
      "lon": -81.3817977905,
      "tz": "America\/New_York"
  },
  "SC46": {
      "icao": "SC46",
      "iata": "",
      "name": "House Movers Field",
      "city": "Batesburg",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 540,
      "lat": 33.8510017395,
      "lon": -81.5484008789,
      "tz": "America\/New_York"
  },
  "SC47": {
      "icao": "SC47",
      "iata": "",
      "name": "Parker Field",
      "city": "Simpsonville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 860,
      "lat": 34.7613983154,
      "lon": -82.1613998413,
      "tz": "America\/New_York"
  },
  "SC48": {
      "icao": "SC48",
      "iata": "",
      "name": "Byrd Field",
      "city": "Turbeville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 110,
      "lat": 33.8843002319,
      "lon": -79.9998016357,
      "tz": "America\/New_York"
  },
  "SC49": {
      "icao": "SC49",
      "iata": "",
      "name": "Oficina Victoria Airport",
      "city": "Oficina Victoria",
      "state": "Tarapaca",
      "country": "CL",
      "elevation": 3280,
      "lat": -20.7346992493,
      "lon": -69.6257019043,
      "tz": "America\/Santiago"
  },
  "SC50": {
      "icao": "SC50",
      "iata": "",
      "name": "Yonges Island Airport",
      "city": "Charleston",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 10,
      "lat": 32.6973991394,
      "lon": -80.2258987427,
      "tz": "America\/New_York"
  },
  "SC51": {
      "icao": "SC51",
      "iata": "",
      "name": "Too Goo Doo Farms Airport",
      "city": "Meggett",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 15,
      "lat": 32.7046012878,
      "lon": -80.2531967163,
      "tz": "America\/New_York"
  },
  "SC52": {
      "icao": "SC52",
      "iata": "",
      "name": "Oakview Airport",
      "city": "Six Mile",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1040,
      "lat": 34.8123016357,
      "lon": -82.8188018799,
      "tz": "America\/New_York"
  },
  "SC55": {
      "icao": "SC55",
      "iata": "",
      "name": "Broxton Bridge Plantation Airport",
      "city": "Ehrhardt",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 120,
      "lat": 33.0056991577,
      "lon": -81.0484008789,
      "tz": "America\/New_York"
  },
  "SC56": {
      "icao": "SC56",
      "iata": "",
      "name": "Thomason Airfield",
      "city": "Mountville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 642,
      "lat": 34.3634986877,
      "lon": -81.9932022095,
      "tz": "America\/New_York"
  },
  "SC57": {
      "icao": "SC57",
      "iata": "",
      "name": "Creech Aviation Facility Airport",
      "city": "Wedgefield",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 178,
      "lat": 33.8843002319,
      "lon": -80.4947967529,
      "tz": "America\/New_York"
  },
  "SC58": {
      "icao": "SC58",
      "iata": "",
      "name": "Hartness Airport",
      "city": "Greenville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 850,
      "lat": 34.8470993042,
      "lon": -82.2518005371,
      "tz": "America\/New_York"
  },
  "SC60": {
      "icao": "SC60",
      "iata": "",
      "name": "Cockfield Aerodrome",
      "city": "Scranton",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 82,
      "lat": 33.9040985107,
      "lon": -79.6839981079,
      "tz": "America\/New_York"
  },
  "SC61": {
      "icao": "SC61",
      "iata": "",
      "name": "Wild Irish Rose Airport",
      "city": "Cheraw",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 376,
      "lat": 34.6179008484,
      "lon": -80.1517028809,
      "tz": "America\/New_York"
  },
  "SC65": {
      "icao": "SC65",
      "iata": "",
      "name": "Raven's Run Airport",
      "city": "Mount Pleasant",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 8,
      "lat": 32.8237991333,
      "lon": -79.8067016602,
      "tz": "America\/New_York"
  },
  "SC67": {
      "icao": "SC67",
      "iata": "",
      "name": "Country Squire Airport",
      "city": "Rock Hill",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 587,
      "lat": 34.9099006653,
      "lon": -81.074798584,
      "tz": "America\/New_York"
  },
  "SC69": {
      "icao": "SC69",
      "iata": "",
      "name": "Shiloh Plantation Airport",
      "city": "Ehrhardt",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 131,
      "lat": 33.1206016541,
      "lon": -80.9507980347,
      "tz": "America\/New_York"
  },
  "SC70": {
      "icao": "SC70",
      "iata": "",
      "name": "Anna's Airport",
      "city": "Walhalla",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 930,
      "lat": 34.7249984741,
      "lon": -83.0849990845,
      "tz": "America\/New_York"
  },
  "SC72": {
      "icao": "SC72",
      "iata": "",
      "name": "Chandelle Airport",
      "city": "Greer",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 850,
      "lat": 34.8228988647,
      "lon": -82.1482009888,
      "tz": "America\/New_York"
  },
  "SC74": {
      "icao": "SC74",
      "iata": "",
      "name": "Marsh Point Airport",
      "city": "Beaufort",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 12,
      "lat": 32.4618988037,
      "lon": -80.6008987427,
      "tz": "America\/New_York"
  },
  "SC75": {
      "icao": "SC75",
      "iata": "",
      "name": "Oolenoy Valley Airport",
      "city": "Pickens",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1000,
      "lat": 34.9981994629,
      "lon": -82.7349014282,
      "tz": "America\/New_York"
  },
  "SC76": {
      "icao": "SC76",
      "iata": "",
      "name": "Unity Aerodrome",
      "city": "Lancaster",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 640,
      "lat": 34.8027000427,
      "lon": -80.6800994873,
      "tz": "America\/New_York"
  },
  "SC78": {
      "icao": "SC78",
      "iata": "",
      "name": "King Field",
      "city": "Cleveland",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1250,
      "lat": 35.0906982422,
      "lon": -82.4953994751,
      "tz": "America\/New_York"
  },
  "SC79": {
      "icao": "SC79",
      "iata": "",
      "name": "Bermuda High Gliderport",
      "city": "Lancaster",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 550,
      "lat": 34.6113014221,
      "lon": -80.449798584,
      "tz": "America\/New_York"
  },
  "SC80": {
      "icao": "SC80",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Pendleton",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 817,
      "lat": 34.6678009033,
      "lon": -82.7099990845,
      "tz": "America\/New_York"
  },
  "SC81": {
      "icao": "SC81",
      "iata": "",
      "name": "Abbeville Airport",
      "city": "Abbeville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 550,
      "lat": 34.1515007019,
      "lon": -82.3536987305,
      "tz": "America\/New_York"
  },
  "SC82": {
      "icao": "SC82",
      "iata": "",
      "name": "Oakhill Airpark",
      "city": "Williamson",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 837,
      "lat": 34.5847015381,
      "lon": -82.3516998291,
      "tz": "America\/New_York"
  },
  "SC86": {
      "icao": "SC86",
      "iata": "",
      "name": "Williamsport Airpark",
      "city": "Easley",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 1100,
      "lat": 34.8646011353,
      "lon": -82.5675964355,
      "tz": "America\/New_York"
  },
  "SC87": {
      "icao": "SC87",
      "iata": "",
      "name": "Avinger Field",
      "city": "Vance",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 138,
      "lat": 33.4427986145,
      "lon": -80.4555969238,
      "tz": "America\/New_York"
  },
  "SC88": {
      "icao": "SC88",
      "iata": "",
      "name": "Davis Field",
      "city": "Chesnee",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 935,
      "lat": 35.1473007202,
      "lon": -81.7942962646,
      "tz": "America\/New_York"
  },
  "SC89": {
      "icao": "SC89",
      "iata": "",
      "name": "Price Airport",
      "city": "Dillon",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 138,
      "lat": 34.4749984741,
      "lon": -79.3936004639,
      "tz": "America\/New_York"
  },
  "SC90": {
      "icao": "SC90",
      "iata": "",
      "name": "Do-Little Field",
      "city": "St. Matthews",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 369,
      "lat": 33.6761016846,
      "lon": -80.9483032227,
      "tz": "America\/New_York"
  },
  "SC91": {
      "icao": "SC91",
      "iata": "",
      "name": "Bell's Branch Airport",
      "city": "Branchville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 95,
      "lat": 33.3064994812,
      "lon": -80.848197937,
      "tz": "America\/New_York"
  },
  "SC92": {
      "icao": "SC92",
      "iata": "",
      "name": "Rambos Field",
      "city": "Abbeville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 550,
      "lat": 34.1031990051,
      "lon": -82.2817993164,
      "tz": "America\/New_York"
  },
  "SC93": {
      "icao": "SC93",
      "iata": "",
      "name": "Paul's Plantation Airport",
      "city": "Darlington",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 180,
      "lat": 34.3660011292,
      "lon": -79.8242034912,
      "tz": "America\/New_York"
  },
  "SC94": {
      "icao": "SC94",
      "iata": "",
      "name": "Weaver Field",
      "city": "Johnsonville",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 75,
      "lat": 33.8894004822,
      "lon": -79.506401062,
      "tz": "America\/New_York"
  },
  "SC95": {
      "icao": "SC95",
      "iata": "",
      "name": "Perry International Airport",
      "city": "Perry",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 438,
      "lat": 33.6292991638,
      "lon": -81.3296966553,
      "tz": "America\/New_York"
  },
  "SC96": {
      "icao": "SC96",
      "iata": "",
      "name": "Tokeena Air Park",
      "city": "Seneca",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 800,
      "lat": 34.5602989197,
      "lon": -82.9353027344,
      "tz": "America\/New_York"
  },
  "SC97": {
      "icao": "SC97",
      "iata": "",
      "name": "Riverbend Airpark",
      "city": "Mauldin",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 920,
      "lat": 34.7266998291,
      "lon": -82.3396987915,
      "tz": "America\/New_York"
  },
  "SC98": {
      "icao": "SC98",
      "iata": "",
      "name": "Mount Holly Airport",
      "city": "Goose Creek",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 55,
      "lat": 33.0542984009,
      "lon": -80.0833969116,
      "tz": "America\/New_York"
  },
  "SC99": {
      "icao": "SC99",
      "iata": "",
      "name": "Whiteplains Airport",
      "city": "Lexington",
      "state": "South-Carolina",
      "country": "US",
      "elevation": 520,
      "lat": 33.9639015198,
      "lon": -81.3591995239,
      "tz": "America\/New_York"
  },
  "SCAA": {
      "icao": "SCAA",
      "iata": "",
      "name": "Anorada Airport",
      "city": "Frutillar",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 459,
      "lat": -41.0427780151,
      "lon": -73.0475006104,
      "tz": "America\/Santiago"
  },
  "SCAB": {
      "icao": "SCAB",
      "iata": "",
      "name": "El Alba Airport",
      "city": "La Lumbrera",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 450,
      "lat": -33.6588897705,
      "lon": -71.2891693115,
      "tz": "America\/Santiago"
  },
  "SCAC": {
      "icao": "SCAC",
      "iata": "ZUD",
      "name": "Pupelde Airport",
      "city": "Ancud",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 315,
      "lat": -41.9039001465,
      "lon": -73.7966995239,
      "tz": "America\/Santiago"
  },
  "SCAD": {
      "icao": "SCAD",
      "iata": "",
      "name": "Santa Adriana Airport",
      "city": "Ovalle",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 558,
      "lat": -30.628610611,
      "lon": -71.2730560303,
      "tz": "America\/Santiago"
  },
  "SCAE": {
      "icao": "SCAE",
      "iata": "",
      "name": "El Buitre Airport",
      "city": "Arica",
      "state": "Arica-y-Parinacota",
      "country": "CL",
      "elevation": 328,
      "lat": -18.5100002289,
      "lon": -70.2891998291,
      "tz": "America\/Santiago"
  },
  "SCAF": {
      "icao": "SCAF",
      "iata": "",
      "name": "San Alfonso Airport",
      "city": "Alhue",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 407,
      "lat": -34.0555992126,
      "lon": -71.2369003296,
      "tz": "America\/Santiago"
  },
  "SCAG": {
      "icao": "SCAG",
      "iata": "",
      "name": "Agua Santa Airport",
      "city": "Palmilla",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 476,
      "lat": -34.5514755847,
      "lon": -71.3358914852,
      "tz": "America\/Santiago"
  },
  "SCAH": {
      "icao": "SCAH",
      "iata": "",
      "name": "Tolquien Airport",
      "city": "Achao",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 551,
      "lat": -42.4494018555,
      "lon": -73.5246963501,
      "tz": "America\/Santiago"
  },
  "SCAJ": {
      "icao": "SCAJ",
      "iata": "",
      "name": "Las Alpacas Airport",
      "city": "Retiro",
      "state": "Maule",
      "country": "CL",
      "elevation": 550,
      "lat": -36.1452789307,
      "lon": -71.763053894,
      "tz": "America\/Santiago"
  },
  "SCAK": {
      "icao": "SCAK",
      "iata": "",
      "name": "Atacalco Airport",
      "city": "Recinto",
      "state": "Biobio",
      "country": "CL",
      "elevation": 2362,
      "lat": -36.9194450378,
      "lon": -71.5774993896,
      "tz": "America\/Santiago"
  },
  "SCAL": {
      "icao": "SCAL",
      "iata": "",
      "name": "Valchac Airport",
      "city": "Cochrane",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1116,
      "lat": -47.115556,
      "lon": -72.480278,
      "tz": "America\/Santiago"
  },
  "SCAM": {
      "icao": "SCAM",
      "iata": "",
      "name": "Alempue Airport",
      "city": "Teno",
      "state": "Maule",
      "country": "CL",
      "elevation": 1083,
      "lat": -34.8650016785,
      "lon": -71.1019439697,
      "tz": "America\/Santiago"
  },
  "SCAN": {
      "icao": "SCAN",
      "iata": "LOB",
      "name": "San Rafael Airport",
      "city": "Los Andes",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 2461,
      "lat": -32.814201355,
      "lon": -70.646697998,
      "tz": "America\/Santiago"
  },
  "SCAO": {
      "icao": "SCAO",
      "iata": "",
      "name": "Palo Alto Airport",
      "city": "Lolol",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 279,
      "lat": -34.7330551147,
      "lon": -71.7408370972,
      "tz": "America\/Santiago"
  },
  "SCAP": {
      "icao": "SCAP",
      "iata": "WAP",
      "name": "Alto Palena Airport",
      "city": "Alto Palena",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 794,
      "lat": -43.6119003296,
      "lon": -71.806098938,
      "tz": "America\/Santiago"
  },
  "SCAQ": {
      "icao": "SCAQ",
      "iata": "",
      "name": "Arquilhue Airport",
      "city": "Lago Ranco",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 590,
      "lat": -40.198299408,
      "lon": -72.0291976929,
      "tz": "America\/Santiago"
  },
  "SCAR": {
      "icao": "SCAR",
      "iata": "ARI",
      "name": "Chacalluta Airport",
      "city": "Arica",
      "state": "Arica-y-Parinacota",
      "country": "CL",
      "elevation": 167,
      "lat": -18.3484992981,
      "lon": -70.3386993408,
      "tz": "America\/Lima"
  },
  "SCAS": {
      "icao": "SCAS",
      "iata": "WPA",
      "name": "Cabo 1\u00b0 Juan Roman Airport",
      "city": "Puerto Aysen",
      "state": "Aisen",
      "country": "CL",
      "elevation": 30,
      "lat": -45.3992004395,
      "lon": -72.6703033447,
      "tz": "America\/Santiago"
  },
  "SCAT": {
      "icao": "SCAT",
      "iata": "CPO",
      "name": "Desierto de Atacama Airport",
      "city": "Copiapo",
      "state": "Atacama",
      "country": "CL",
      "elevation": 670,
      "lat": -27.2611999512,
      "lon": -70.7791976929,
      "tz": "America\/Santiago"
  },
  "SCAU": {
      "icao": "SCAU",
      "iata": "",
      "name": "Juan Enrique Airport",
      "city": "Paine",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1263,
      "lat": -33.8936004639,
      "lon": -70.885597229,
      "tz": "America\/Santiago"
  },
  "SCAV": {
      "icao": "SCAV",
      "iata": "",
      "name": "La Vertiente Airport",
      "city": "Chillan",
      "state": "Biobio",
      "country": "CL",
      "elevation": 459,
      "lat": -36.6102790833,
      "lon": -72.0383300781,
      "tz": "America\/Santiago"
  },
  "SCAY": {
      "icao": "SCAY",
      "iata": "",
      "name": "Ayacara Airport",
      "city": "Ayacara",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 80,
      "lat": -42.3108329773,
      "lon": -72.7902755737,
      "tz": "America\/Santiago"
  },
  "SCAZ": {
      "icao": "SCAZ",
      "iata": "",
      "name": "Azopardo Airport",
      "city": "Timaukel",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 59,
      "lat": -54.4797210693,
      "lon": -68.936668396,
      "tz": "America\/Punta_Arenas"
  },
  "SCBA": {
      "icao": "SCBA",
      "iata": "BBA",
      "name": "Balmaceda Airport",
      "city": "Balmaceda",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1722,
      "lat": -45.9160995483,
      "lon": -71.6894989014,
      "tz": "America\/Santiago"
  },
  "SCBB": {
      "icao": "SCBB",
      "iata": "",
      "name": "Del Bio Bio Airport",
      "city": "Negrete",
      "state": "Biobio",
      "country": "CL",
      "elevation": 249,
      "lat": -37.587222,
      "lon": -72.503611,
      "tz": "America\/Santiago"
  },
  "SCBC": {
      "icao": "SCBC",
      "iata": "",
      "name": "Cacique Blanco Airport",
      "city": "Lago Verde",
      "state": "Aisen",
      "country": "CL",
      "elevation": 750,
      "lat": -44.2311096191,
      "lon": -71.8641662598,
      "tz": "America\/Santiago"
  },
  "SCBD": {
      "icao": "SCBD",
      "iata": "",
      "name": "El Boldal Airport",
      "city": "Santa Cruz",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 574,
      "lat": -34.6844444275,
      "lon": -71.2061080933,
      "tz": "America\/Santiago"
  },
  "SCBE": {
      "icao": "SCBE",
      "iata": "TOQ",
      "name": "Barriles Airport",
      "city": "Tocopilla",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 3475,
      "lat": -22.1410999298,
      "lon": -70.0628967285,
      "tz": "America\/Santiago"
  },
  "SCBI": {
      "icao": "SCBI",
      "iata": "",
      "name": "Pampa Guanaco Airport",
      "city": "Bahia Inutil",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 591,
      "lat": -54.0449981689,
      "lon": -68.8316650391,
      "tz": "America\/Punta_Arenas"
  },
  "SCBN": {
      "icao": "SCBN",
      "iata": "",
      "name": "Cotreumo Airport",
      "city": "Rio Bueno",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 607,
      "lat": -40.4006004333,
      "lon": -72.6613998413,
      "tz": "America\/Santiago"
  },
  "SCBO": {
      "icao": "SCBO",
      "iata": "",
      "name": "General Bernardo O'Higgins Base Skyway",
      "city": "General Bernardo O'Higgins Base",
      "state": "",
      "country": "AQ",
      "elevation": 900,
      "lat": -63.344,
      "lon": -57.842,
      "tz": "America\/Punta_Arenas"
  },
  "SCBQ": {
      "icao": "SCBQ",
      "iata": "",
      "name": "El Bosque Airport",
      "city": "Santiago",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1844,
      "lat": -33.5617980957,
      "lon": -70.6884002686,
      "tz": "America\/Santiago"
  },
  "SCBR": {
      "icao": "SCBR",
      "iata": "",
      "name": "Lago Brown Airport",
      "city": "Lago Brown",
      "state": "Aisen",
      "country": "CL",
      "elevation": 540,
      "lat": -47.3919448853,
      "lon": -72.3174972534,
      "tz": "America\/Santiago"
  },
  "SCBS": {
      "icao": "SCBS",
      "iata": "",
      "name": "Posesion Airport",
      "city": "Bahia Posesion",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 100,
      "lat": -52.2999992371,
      "lon": -68.9333343506,
      "tz": "America\/Punta_Arenas"
  },
  "SCBT": {
      "icao": "SCBT",
      "iata": "",
      "name": "Rincon Bonito Airport",
      "city": "Cochamo",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 705,
      "lat": -42.0288887024,
      "lon": -72.0961074829,
      "tz": "America\/Santiago"
  },
  "SCBU": {
      "icao": "SCBU",
      "iata": "",
      "name": "El Baul Airstrip",
      "city": "Molina",
      "state": "Maule",
      "country": "CL",
      "elevation": 3070,
      "lat": -35.341435,
      "lon": -70.9108,
      "tz": "America\/Santiago"
  },
  "SCBV": {
      "icao": "SCBV",
      "iata": "",
      "name": "Bellavista Airport",
      "city": "Rio Claro",
      "state": "Maule",
      "country": "CL",
      "elevation": 885,
      "lat": -35.1897239685,
      "lon": -71.2966690063,
      "tz": "America\/Santiago"
  },
  "SCCA": {
      "icao": "SCCA",
      "iata": "",
      "name": "El Boldo Airport",
      "city": "Cauquenes",
      "state": "Maule",
      "country": "CL",
      "elevation": 525,
      "lat": -35.9713897705,
      "lon": -72.2249984741,
      "tz": "America\/Santiago"
  },
  "SCCB": {
      "icao": "SCCB",
      "iata": "",
      "name": "Pedro Villarroel C. Airport",
      "city": "Combarbala",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 3002,
      "lat": -31.221944809,
      "lon": -71.070274353,
      "tz": "America\/Santiago"
  },
  "SCCC": {
      "icao": "SCCC",
      "iata": "CCH",
      "name": "Chile Chico Airport",
      "city": "Chile Chico",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1070,
      "lat": -46.5833015442,
      "lon": -71.6874008179,
      "tz": "America\/Santiago"
  },
  "SCCE": {
      "icao": "SCCE",
      "iata": "",
      "name": "El Arenal Airport",
      "city": "Cauquenes",
      "state": "Maule",
      "country": "CL",
      "elevation": 397,
      "lat": -35.896389,
      "lon": -72.055833,
      "tz": "America\/Santiago"
  },
  "SCCF": {
      "icao": "SCCF",
      "iata": "CJC",
      "name": "El Loa Airport",
      "city": "Calama",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 7543,
      "lat": -22.4981994629,
      "lon": -68.9036026001,
      "tz": "America\/Santiago"
  },
  "SCCG": {
      "icao": "SCCG",
      "iata": "",
      "name": "La Pelicana Airport",
      "city": "Combarbala",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 2790,
      "lat": -31.0638885498,
      "lon": -70.9544448853,
      "tz": "America\/Santiago"
  },
  "SCCH": {
      "icao": "SCCH",
      "iata": "YAI",
      "name": "Gral. Bernardo O\u00b4Higgins Airport",
      "city": "Chillan",
      "state": "Biobio",
      "country": "CL",
      "elevation": 495,
      "lat": -36.5825004578,
      "lon": -72.0314025879,
      "tz": "America\/Santiago"
  },
  "SCCI": {
      "icao": "SCCI",
      "iata": "PUQ",
      "name": "Pdte. Carlos Ibanez del Campo Airport",
      "city": "Punta Arenas",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 139,
      "lat": -53.0026016235,
      "lon": -70.854598999,
      "tz": "America\/Punta_Arenas"
  },
  "SCCK": {
      "icao": "SCCK",
      "iata": "",
      "name": "Contao Airport",
      "city": "Contao",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 10,
      "lat": -41.8003044128,
      "lon": -72.7222366333,
      "tz": "America\/Santiago"
  },
  "SCCL": {
      "icao": "SCCL",
      "iata": "",
      "name": "Caldera Airport",
      "city": "Caldera",
      "state": "Atacama",
      "country": "CL",
      "elevation": 180,
      "lat": -27.0781002045,
      "lon": -70.7953033447,
      "tz": "America\/Santiago"
  },
  "SCCM": {
      "icao": "SCCM",
      "iata": "",
      "name": "Molco Airport",
      "city": "Choshuenco",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 479,
      "lat": -39.8250007629,
      "lon": -72.0824966431,
      "tz": "America\/Santiago"
  },
  "SCCN": {
      "icao": "SCCN",
      "iata": "",
      "name": "Alto Cauquenes Airport",
      "city": "Cauquenes",
      "state": "Maule",
      "country": "CL",
      "elevation": 568,
      "lat": -35.880279541,
      "lon": -72.3272247314,
      "tz": "America\/Santiago"
  },
  "SCCP": {
      "icao": "SCCP",
      "iata": "",
      "name": "Callipulli Airport",
      "city": "Osorno",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 377,
      "lat": -40.6202774048,
      "lon": -72.8463897705,
      "tz": "America\/Santiago"
  },
  "SCCQ": {
      "icao": "SCCQ",
      "iata": "COW",
      "name": "Tambillos Airport",
      "city": "Coquimbo",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 656,
      "lat": -30.198889,
      "lon": -71.246944,
      "tz": "America\/Santiago"
  },
  "SCCR": {
      "icao": "SCCR",
      "iata": "",
      "name": "Enrique Mayer Soto Airport",
      "city": "Caleta Tortel",
      "state": "Aisen",
      "country": "CL",
      "elevation": 4,
      "lat": -47.787776947,
      "lon": -73.5202789307,
      "tz": "America\/Santiago"
  },
  "SCCS": {
      "icao": "SCCS",
      "iata": "",
      "name": "Santa Rita Airport",
      "city": "Casablanca",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 738,
      "lat": -33.2775001526,
      "lon": -71.4563903809,
      "tz": "America\/Santiago"
  },
  "SCCT": {
      "icao": "SCCT",
      "iata": "",
      "name": "Quivolgo Airport",
      "city": "Constitucion",
      "state": "Maule",
      "country": "CL",
      "elevation": 36,
      "lat": -35.3086013794,
      "lon": -72.3927993774,
      "tz": "America\/Santiago"
  },
  "SCCU": {
      "icao": "SCCU",
      "iata": "",
      "name": "Lolco Airport",
      "city": "Lonquimay",
      "state": "Araucania",
      "country": "CL",
      "elevation": 2132,
      "lat": -38.1580543518,
      "lon": -71.4238891602,
      "tz": "America\/Santiago"
  },
  "SCCV": {
      "icao": "SCCV",
      "iata": "",
      "name": "Curacavi Airport",
      "city": "Curacavi",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 686,
      "lat": -33.4127998352,
      "lon": -71.1656036377,
      "tz": "America\/Santiago"
  },
  "SCCY": {
      "icao": "SCCY",
      "iata": "GXQ",
      "name": "Teniente Vidal Airport",
      "city": "Coyhaique",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1020,
      "lat": -45.5942001343,
      "lon": -72.1061019897,
      "tz": "America\/Santiago"
  },
  "SCDA": {
      "icao": "SCDA",
      "iata": "IQQ",
      "name": "Diego Aracena Airport",
      "city": "Iquique",
      "state": "Tarapaca",
      "country": "CL",
      "elevation": 155,
      "lat": -20.535200119,
      "lon": -70.1812973022,
      "tz": "America\/Santiago"
  },
  "SCDD": {
      "icao": "SCDD",
      "iata": "",
      "name": "Don Dobri Airport",
      "city": "Puerto Varas",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 226,
      "lat": -41.2347221375,
      "lon": -72.5149993896,
      "tz": "America\/Santiago"
  },
  "SCDH": {
      "icao": "SCDH",
      "iata": "",
      "name": "Vodudahue Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 98,
      "lat": -42.4888877869,
      "lon": -72.3505554199,
      "tz": "America\/Santiago"
  },
  "SCDI": {
      "icao": "SCDI",
      "iata": "",
      "name": "Pichidangui Airport",
      "city": "Pichidangui",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 98,
      "lat": -32.1430549622,
      "lon": -71.505279541,
      "tz": "America\/Santiago"
  },
  "SCDL": {
      "icao": "SCDL",
      "iata": "",
      "name": "El Algarrobo Airport",
      "city": "Cabildo",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 951,
      "lat": -32.4719429016,
      "lon": -70.993057251,
      "tz": "America\/Santiago"
  },
  "SCDM": {
      "icao": "SCDM",
      "iata": "",
      "name": "San Damian Airport",
      "city": "Duao",
      "state": "Maule",
      "country": "CL",
      "elevation": 220,
      "lat": -34.8780555725,
      "lon": -72.1455535889,
      "tz": "America\/Santiago"
  },
  "SCDQ": {
      "icao": "SCDQ",
      "iata": "",
      "name": "San Lorenzo Airport",
      "city": "Duqueco",
      "state": "Biobio",
      "country": "CL",
      "elevation": 1902,
      "lat": -37.5233345032,
      "lon": -71.7261123657,
      "tz": "America\/Santiago"
  },
  "SCDS": {
      "icao": "SCDS",
      "iata": "",
      "name": "San Andres Airport",
      "city": "Retiro",
      "state": "Maule",
      "country": "CL",
      "elevation": 574,
      "lat": -36.0055541992,
      "lon": -71.7666702271,
      "tz": "America\/Santiago"
  },
  "SCDW": {
      "icao": "SCDW",
      "iata": "",
      "name": "Almirante Schroeders Airport",
      "city": "Isla Dawson",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 160,
      "lat": -53.6106987,
      "lon": -70.4704971313,
      "tz": "America\/Punta_Arenas"
  },
  "SCEA": {
      "icao": "SCEA",
      "iata": "",
      "name": "El Amarillo Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 1083,
      "lat": -43.006111145,
      "lon": -72.4783325195,
      "tz": "America\/Santiago"
  },
  "SCEB": {
      "icao": "SCEB",
      "iata": "",
      "name": "Entrada Baker Airport",
      "city": "Entrada Baker",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1640,
      "lat": -47.1841659546,
      "lon": -71.9761123657,
      "tz": "America\/Santiago"
  },
  "SCEC": {
      "icao": "SCEC",
      "iata": "",
      "name": "Pelicano Airport",
      "city": "Estacion Chanaral",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 3678,
      "lat": -29.1448993683,
      "lon": -70.887802124,
      "tz": "America\/Santiago"
  },
  "SCED": {
      "icao": "SCED",
      "iata": "",
      "name": "Los Cedros Airport",
      "city": "Sagrada Familia",
      "state": "Maule",
      "country": "CL",
      "elevation": 305,
      "lat": -35.1549987793,
      "lon": -71.5852813721,
      "tz": "America\/Santiago"
  },
  "SCEG": {
      "icao": "SCEG",
      "iata": "",
      "name": "El Corte Airport",
      "city": "Talagante",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1024,
      "lat": -33.7099990845,
      "lon": -70.9130554199,
      "tz": "America\/Santiago"
  },
  "SCEH": {
      "icao": "SCEH",
      "iata": "",
      "name": "El Huachi Airport",
      "city": "Santa Barbara",
      "state": "Biobio",
      "country": "CL",
      "elevation": 1558,
      "lat": -37.6472206116,
      "lon": -71.7538909912,
      "tz": "America\/Santiago"
  },
  "SCEK": {
      "icao": "SCEK",
      "iata": "",
      "name": "Chepica Airport",
      "city": "Chepica",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 610,
      "lat": -34.7397232056,
      "lon": -71.32472229,
      "tz": "America\/Santiago"
  },
  "SCEL": {
      "icao": "SCEL",
      "iata": "SCL",
      "name": "Comodoro Arturo Merino Benitez International Airport",
      "city": "Santiago",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1555,
      "lat": -33.3930015564,
      "lon": -70.7857971191,
      "tz": "America\/Santiago"
  },
  "SCEO": {
      "icao": "SCEO",
      "iata": "",
      "name": "El Salto Airport",
      "city": "Parral",
      "state": "Maule",
      "country": "CL",
      "elevation": 564,
      "lat": -36.130279541,
      "lon": -71.8574981689,
      "tz": "America\/Santiago"
  },
  "SCEP": {
      "icao": "SCEP",
      "iata": "",
      "name": "El Principal Airport",
      "city": "Pirque",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 2592,
      "lat": -33.7249984741,
      "lon": -70.5102996826,
      "tz": "America\/Santiago"
  },
  "SCER": {
      "icao": "SCER",
      "iata": "",
      "name": "Quintero Airport",
      "city": "Quintero",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 12,
      "lat": -32.7901992798,
      "lon": -71.521697998,
      "tz": "America\/Santiago"
  },
  "SCES": {
      "icao": "SCES",
      "iata": "ESR",
      "name": "Ricardo Garcia Posada Airport",
      "city": "El Salvador",
      "state": "Atacama",
      "country": "CL",
      "elevation": 5240,
      "lat": -26.3111000061,
      "lon": -69.7651977539,
      "tz": "America\/Santiago"
  },
  "SCET": {
      "icao": "SCET",
      "iata": "",
      "name": "El Tambo Airport",
      "city": "San Vicente De Tagua Tagua",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 748,
      "lat": -34.4755554199,
      "lon": -71.0141677856,
      "tz": "America\/Santiago"
  },
  "SCEV": {
      "icao": "SCEV",
      "iata": "FRT",
      "name": "El Avellano Airport",
      "city": "Frutillar",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 489,
      "lat": -41.1170005798,
      "lon": -73.0500030518,
      "tz": "America\/Santiago"
  },
  "SCEX": {
      "icao": "SCEX",
      "iata": "",
      "name": "Rio Exploradores Airport",
      "city": "Aysen",
      "state": "Aisen",
      "country": "CL",
      "elevation": 59,
      "lat": -46.353611,
      "lon": -73.327778,
      "tz": "America\/Santiago"
  },
  "SCEY": {
      "icao": "SCEY",
      "iata": "",
      "name": "Entrada Mayer Airport",
      "city": "Entrada Mayer",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1550,
      "lat": -48.2014517431,
      "lon": -72.3275899887,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SCFA": {
      "icao": "SCFA",
      "iata": "ANF",
      "name": "Cerro Moreno Airport",
      "city": "Antofagasta",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 455,
      "lat": -23.4444999695,
      "lon": -70.445098877,
      "tz": "America\/Santiago"
  },
  "SCFC": {
      "icao": "SCFC",
      "iata": "",
      "name": "Fachinal Airport",
      "city": "Fachinal",
      "state": "Aisen",
      "country": "CL",
      "elevation": 693,
      "lat": -46.551109314,
      "lon": -72.2180557251,
      "tz": "America\/Santiago"
  },
  "SCFF": {
      "icao": "SCFF",
      "iata": "",
      "name": "Freirina Airport",
      "city": "Freirina",
      "state": "Atacama",
      "country": "CL",
      "elevation": 738,
      "lat": -28.5263881683,
      "lon": -71.0619430542,
      "tz": "America\/Santiago"
  },
  "SCFI": {
      "icao": "SCFI",
      "iata": "FRT",
      "name": "Fundo Tehuen Airport",
      "city": "Frutillar",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 377,
      "lat": -41.0950012207,
      "lon": -73.126663208,
      "tz": "America\/Santiago"
  },
  "SCFJ": {
      "icao": "SCFJ",
      "iata": "",
      "name": "Fray Jorge Airport",
      "city": "Ovalle",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 492,
      "lat": -30.6800003052,
      "lon": -71.5786132813,
      "tz": "America\/Santiago"
  },
  "SCFK": {
      "icao": "SCFK",
      "iata": "",
      "name": "Fundo El Carmen Airport",
      "city": "Chillan",
      "state": "Biobio",
      "country": "CL",
      "elevation": 557,
      "lat": -36.6413879395,
      "lon": -72.0166702271,
      "tz": "America\/Santiago"
  },
  "SCFL": {
      "icao": "SCFL",
      "iata": "",
      "name": "Fundo Loma Larga Airport",
      "city": "Casablanca",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 869,
      "lat": -33.2799987793,
      "lon": -71.3792037964,
      "tz": "America\/Santiago"
  },
  "SCFM": {
      "icao": "SCFM",
      "iata": "WPR",
      "name": "Capitan Fuentes Martinez Airport Airport",
      "city": "Porvenir",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 104,
      "lat": -53.2537002563,
      "lon": -70.3191986084,
      "tz": "America\/Punta_Arenas"
  },
  "SCFN": {
      "icao": "SCFN",
      "iata": "",
      "name": "Russfin Airport",
      "city": "Russfin",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 600,
      "lat": -53.7999992371,
      "lon": -69.266998291,
      "tz": "America\/Punta_Arenas"
  },
  "SCFO": {
      "icao": "SCFO",
      "iata": "",
      "name": "La Reforma Airport",
      "city": "Pelarco",
      "state": "Maule",
      "country": "CL",
      "elevation": 853,
      "lat": -35.4580574036,
      "lon": -71.2811126709,
      "tz": "America\/Santiago"
  },
  "SCFR": {
      "icao": "SCFR",
      "iata": "",
      "name": "Frutillar Airport",
      "city": "Frutillar",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 469,
      "lat": -41.1308326721,
      "lon": -73.0647201538,
      "tz": "America\/Santiago"
  },
  "SCFS": {
      "icao": "SCFS",
      "iata": "",
      "name": "Los Calafates Airport",
      "city": "Chonchi",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 82,
      "lat": -42.6788902283,
      "lon": -73.8966674805,
      "tz": "America\/Santiago"
  },
  "SCFT": {
      "icao": "SCFT",
      "iata": "FFU",
      "name": "Futaleufu Airport",
      "city": "Futaleufu",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 1148,
      "lat": -43.189201355,
      "lon": -71.8510971069,
      "tz": "America\/Santiago"
  },
  "SCFU": {
      "icao": "SCFU",
      "iata": "",
      "name": "Loncopan Airport",
      "city": "Futrono",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 886,
      "lat": -40.0663986206,
      "lon": -72.5378036499,
      "tz": "America\/Santiago"
  },
  "SCFX": {
      "icao": "SCFX",
      "iata": "",
      "name": "Isla San Felix Airport",
      "city": "Isla San Felix",
      "state": "Atacama",
      "country": "CL",
      "elevation": 165,
      "lat": -26.2938995361,
      "lon": -80.0961990356,
      "tz": "America\/Santiago"
  },
  "SCGA": {
      "icao": "SCGA",
      "iata": "",
      "name": "Punta Galera Airport",
      "city": "La Union",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 118,
      "lat": -40.0018997192,
      "lon": -73.6964035034,
      "tz": "America\/Santiago"
  },
  "SCGB": {
      "icao": "SCGB",
      "iata": "",
      "name": "Guadaba Airport",
      "city": "Los Sauces",
      "state": "Araucania",
      "country": "CL",
      "elevation": 272,
      "lat": -37.9777793884,
      "lon": -72.9294433594,
      "tz": "America\/Santiago"
  },
  "SCGC": {
      "icao": "SCGC",
      "iata": "",
      "name": "Union Glaciar Airport",
      "city": "Antartica",
      "state": "",
      "country": "AQ",
      "elevation": 2461,
      "lat": -79.777778,
      "lon": -83.320833,
      "tz": "Etc\/GMT+6"
  },
  "SCGE": {
      "icao": "SCGE",
      "iata": "LSQ",
      "name": "Maria Dolores Airport",
      "city": "Los Angeles",
      "state": "Biobio",
      "country": "CL",
      "elevation": 374,
      "lat": -37.4016990662,
      "lon": -72.4253997803,
      "tz": "America\/Santiago"
  },
  "SCGF": {
      "icao": "SCGF",
      "iata": "",
      "name": "Golfo Azul Airport",
      "city": "Futrono",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 486,
      "lat": -40.140833,
      "lon": -72.283611,
      "tz": "America\/Santiago"
  },
  "SCGH": {
      "icao": "SCGH",
      "iata": "",
      "name": "Cholguahue Airport",
      "city": "Los Angeles",
      "state": "Biobio",
      "country": "CL",
      "elevation": 732,
      "lat": -37.4697227478,
      "lon": -72.1386108398,
      "tz": "America\/Santiago"
  },
  "SCGI": {
      "icao": "SCGI",
      "iata": "",
      "name": "San Guillermo Airport",
      "city": "Retiro",
      "state": "Maule",
      "country": "CL",
      "elevation": 495,
      "lat": -36.0011100769,
      "lon": -71.8294448853,
      "tz": "America\/Santiago"
  },
  "SCGL": {
      "icao": "SCGL",
      "iata": "",
      "name": "Las Aguilas Airport",
      "city": "Rapel",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 450,
      "lat": -34.1691665649,
      "lon": -71.5311126709,
      "tz": "America\/Santiago"
  },
  "SCGM": {
      "icao": "SCGM",
      "iata": "",
      "name": "Los Gomeros Airport",
      "city": "Rengo",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 1034,
      "lat": -34.3611106873,
      "lon": -70.881942749,
      "tz": "America\/Santiago"
  },
  "SCGN": {
      "icao": "SCGN",
      "iata": "",
      "name": "Caleta Gonzalo Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 33,
      "lat": -42.5636100769,
      "lon": -72.5997238159,
      "tz": "America\/Santiago"
  },
  "SCGO": {
      "icao": "SCGO",
      "iata": "",
      "name": "Los Confines Airport",
      "city": "Angol",
      "state": "Araucania",
      "country": "CL",
      "elevation": 240,
      "lat": -37.7947006226,
      "lon": -72.6872024536,
      "tz": "America\/Santiago"
  },
  "SCGP": {
      "icao": "SCGP",
      "iata": "",
      "name": "Curaco Airport",
      "city": "Panguipulli",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 820,
      "lat": -39.5306015015,
      "lon": -72.3499984741,
      "tz": "America\/Santiago"
  },
  "SCGS": {
      "icao": "SCGS",
      "iata": "",
      "name": "Siberia Airport",
      "city": "Cholguan",
      "state": "Biobio",
      "country": "CL",
      "elevation": 722,
      "lat": -37.1772232056,
      "lon": -72.0797195435,
      "tz": "America\/Santiago"
  },
  "SCGU": {
      "icao": "SCGU",
      "iata": "",
      "name": "Aguas Blancas Airport",
      "city": "Antofagasta",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 3346,
      "lat": -24.1364002228,
      "lon": -69.8285980225,
      "tz": "America\/Santiago"
  },
  "SCGV": {
      "icao": "SCGV",
      "iata": "",
      "name": "Punta Gaviota Airport",
      "city": "Caleta Chanaral De Aceituno",
      "state": "Atacama",
      "country": "CL",
      "elevation": 99,
      "lat": -29.084444,
      "lon": -71.467222,
      "tz": "America\/Santiago"
  },
  "SCGY": {
      "icao": "SCGY",
      "iata": "",
      "name": "Los Guayes Airport",
      "city": "Cunco",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1312,
      "lat": -39.0530548096,
      "lon": -71.9936141968,
      "tz": "America\/Santiago"
  },
  "SCGZ": {
      "icao": "SCGZ",
      "iata": "WPU",
      "name": "Guardiamarina Zanartu Airport",
      "city": "Puerto Williams",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 88,
      "lat": -54.931098938,
      "lon": -67.6262969971,
      "tz": "America\/Argentina\/Ushuaia"
  },
  "SCHA": {
      "icao": "SCHA",
      "iata": "",
      "name": "Chamonate Airport",
      "city": "Copiapo",
      "state": "Atacama",
      "country": "CL",
      "elevation": 984,
      "lat": -27.2968997955,
      "lon": -70.4131011963,
      "tz": "America\/Santiago"
  },
  "SCHC": {
      "icao": "SCHC",
      "iata": "",
      "name": "Chicureo Airport",
      "city": "Santiago",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1886,
      "lat": -33.2677764893,
      "lon": -70.6472244263,
      "tz": "America\/Santiago"
  },
  "SCHD": {
      "icao": "SCHD",
      "iata": "",
      "name": "Chumilden Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 66,
      "lat": -42.5244445801,
      "lon": -72.816947937,
      "tz": "America\/Santiago"
  },
  "SCHE": {
      "icao": "SCHE",
      "iata": "",
      "name": "Rucamanqui Airport",
      "city": "Huepil",
      "state": "Biobio",
      "country": "CL",
      "elevation": 1132,
      "lat": -37.19972229,
      "lon": -71.8966674805,
      "tz": "America\/Santiago"
  },
  "SCHG": {
      "icao": "SCHG",
      "iata": "",
      "name": "Almahue Airport",
      "city": "Pichidegua",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 420,
      "lat": -34.3986129761,
      "lon": -71.3711090088,
      "tz": "America\/Santiago"
  },
  "SCHH": {
      "icao": "SCHH",
      "iata": "",
      "name": "Punta Baja Airport",
      "city": "Chile Chico",
      "state": "Aisen",
      "country": "CL",
      "elevation": 721,
      "lat": -46.7936096191,
      "lon": -72.7952804565,
      "tz": "America\/Santiago"
  },
  "SCHK": {
      "icao": "SCHK",
      "iata": "",
      "name": "Hueicolla Airport",
      "city": "La Union",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 59,
      "lat": -40.1585998535,
      "lon": -73.6544036865,
      "tz": "America\/Santiago"
  },
  "SCHL": {
      "icao": "SCHL",
      "iata": "",
      "name": "Hacienda Lipangue Airport",
      "city": "Lampa",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1745,
      "lat": -33.3352775574,
      "lon": -70.9111099243,
      "tz": "America\/Santiago"
  },
  "SCHM": {
      "icao": "SCHM",
      "iata": "",
      "name": "Punta El Saco Airport",
      "city": "Isla Mocha",
      "state": "Biobio",
      "country": "CL",
      "elevation": 23,
      "lat": -38.4099998474,
      "lon": -73.9008331299,
      "tz": "America\/Santiago"
  },
  "SCHN": {
      "icao": "SCHN",
      "iata": "",
      "name": "Chan Chan Airport",
      "city": "Choshuenco",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 457,
      "lat": -39.8657989502,
      "lon": -72.131401062,
      "tz": "America\/Santiago"
  },
  "SCHO": {
      "icao": "SCHO",
      "iata": "",
      "name": "Punta Chungo Airport",
      "city": "Los Vilos",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 249,
      "lat": -31.8827781677,
      "lon": -71.4763870239,
      "tz": "America\/Santiago"
  },
  "SCHP": {
      "icao": "SCHP",
      "iata": "",
      "name": "Copihue Airport",
      "city": "Retiro",
      "state": "Maule",
      "country": "CL",
      "elevation": 551,
      "lat": -36.0774993896,
      "lon": -71.7797241211,
      "tz": "America\/Santiago"
  },
  "SCHR": {
      "icao": "SCHR",
      "iata": "LGR",
      "name": "Cochrane Airport",
      "city": "Cochrane",
      "state": "Aisen",
      "country": "CL",
      "elevation": 643,
      "lat": -47.2438011169,
      "lon": -72.5884017944,
      "tz": "America\/Santiago"
  },
  "SCHT": {
      "icao": "SCHT",
      "iata": "",
      "name": "Tic Toc Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 13,
      "lat": -43.6130561829,
      "lon": -72.8974990845,
      "tz": "America\/Santiago"
  },
  "SCHU": {
      "icao": "SCHU",
      "iata": "",
      "name": "Gran Canon Airport",
      "city": "Huasco",
      "state": "Atacama",
      "country": "CL",
      "elevation": 230,
      "lat": -28.0988883972,
      "lon": -71.1466674805,
      "tz": "America\/Santiago"
  },
  "SCHW": {
      "icao": "SCHW",
      "iata": "",
      "name": "Hualaihue Airport",
      "city": "Hualaihue",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 30,
      "lat": -42.0286102295,
      "lon": -72.6894454956,
      "tz": "America\/Santiago"
  },
  "SCIA": {
      "icao": "SCIA",
      "iata": "",
      "name": "Isla Apiao Airport",
      "city": "Isla Apiao",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 250,
      "lat": -42.5997238159,
      "lon": -73.2122192383,
      "tz": "America\/Santiago"
  },
  "SCIB": {
      "icao": "SCIB",
      "iata": "",
      "name": "Butachauques Airport",
      "city": "Isla Butachauques",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 150,
      "lat": -42.3083343506,
      "lon": -73.1422195435,
      "tz": "America\/Santiago"
  },
  "SCIC": {
      "icao": "SCIC",
      "iata": "",
      "name": "General Freire Airport",
      "city": "Curico",
      "state": "Maule",
      "country": "CL",
      "elevation": 722,
      "lat": -34.9666671753,
      "lon": -71.2163925171,
      "tz": "America\/Santiago"
  },
  "SCID": {
      "icao": "SCID",
      "iata": "",
      "name": "Marco Davison Bascur Airport",
      "city": "Punta Arenas",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 30,
      "lat": -52.8736114502,
      "lon": -70.75,
      "tz": "America\/Punta_Arenas"
  },
  "SCIE": {
      "icao": "SCIE",
      "iata": "CCP",
      "name": "Carriel Sur Airport",
      "city": "Concepcion",
      "state": "Biobio",
      "country": "CL",
      "elevation": 26,
      "lat": -36.7727012634,
      "lon": -73.0631027222,
      "tz": "America\/Santiago"
  },
  "SCIF": {
      "icao": "SCIF",
      "iata": "",
      "name": "Chollinco Airport",
      "city": "Llifen",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 330,
      "lat": -40.2057991028,
      "lon": -72.2313995361,
      "tz": "America\/Santiago"
  },
  "SCIH": {
      "icao": "SCIH",
      "iata": "",
      "name": "Caleta Blanco Airport",
      "city": "Isla Las Huichas",
      "state": "Aisen",
      "country": "CL",
      "elevation": 115,
      "lat": -45.1477775574,
      "lon": -73.5086135864,
      "tz": "America\/Santiago"
  },
  "SCII": {
      "icao": "SCII",
      "iata": "",
      "name": "Puerto Ingeniero Ibanez Airport",
      "city": "Puerto Ingeniero Ibanez",
      "state": "Aisen",
      "country": "CL",
      "elevation": 820,
      "lat": -46.2858314514,
      "lon": -71.9477767944,
      "tz": "America\/Santiago"
  },
  "SCIK": {
      "icao": "SCIK",
      "iata": "",
      "name": "Isla Talcan Airport",
      "city": "Isla Talcan",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 45,
      "lat": -42.7466659546,
      "lon": -72.9705581665,
      "tz": "America\/Santiago"
  },
  "SCIL": {
      "icao": "SCIL",
      "iata": "",
      "name": "Auco Airport",
      "city": "Illapel",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 1420,
      "lat": -31.5778007507,
      "lon": -71.1108016968,
      "tz": "America\/Santiago"
  },
  "SCIM": {
      "icao": "SCIM",
      "iata": "",
      "name": "Isla Mocha Airport",
      "city": "Isla Mocha",
      "state": "Biobio",
      "country": "CL",
      "elevation": 52,
      "lat": -38.3849983215,
      "lon": -73.8688964844,
      "tz": "America\/Santiago"
  },
  "SCIO": {
      "icao": "SCIO",
      "iata": "",
      "name": "Laguna Redonda Airport",
      "city": "Villa O'Higgins",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1970,
      "lat": -48.9335784912,
      "lon": -72.7996139526,
      "tz": "America\/Argentina\/Rio_Gallegos"
  },
  "SCIP": {
      "icao": "SCIP",
      "iata": "IPC",
      "name": "Mataveri Airport",
      "city": "Isla De Pascua",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 227,
      "lat": -27.1648006439,
      "lon": -109.42199707,
      "tz": "Pacific\/Easter"
  },
  "SCIR": {
      "icao": "SCIR",
      "iata": "",
      "name": "Robinson Crusoe Airport",
      "city": "Isla Robinson Crusoe",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 433,
      "lat": -33.6650009155,
      "lon": -78.9297027588,
      "tz": "America\/Santiago"
  },
  "SCIS": {
      "icao": "SCIS",
      "iata": "",
      "name": "Puerto Sur Airport",
      "city": "Isla Santa Maria",
      "state": "Biobio",
      "country": "CL",
      "elevation": 20,
      "lat": -37.0261116028,
      "lon": -73.506942749,
      "tz": "America\/Santiago"
  },
  "SCIT": {
      "icao": "SCIT",
      "iata": "",
      "name": "Ivan Martinez Airport",
      "city": "Isla Tierra Del Fuego",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 144,
      "lat": -52.9550018311,
      "lon": -70.0327987671,
      "tz": "America\/Punta_Arenas"
  },
  "SCIV": {
      "icao": "SCIV",
      "iata": "",
      "name": "Panimavida Airport",
      "city": "Panimavida",
      "state": "Maule",
      "country": "CL",
      "elevation": 591,
      "lat": -35.7569007874,
      "lon": -71.4041976929,
      "tz": "America\/Santiago"
  },
  "SCJC": {
      "icao": "SCJC",
      "iata": "",
      "name": "James Conrad Airport",
      "city": "Ranguelmo",
      "state": "Biobio",
      "country": "CL",
      "elevation": 528,
      "lat": -36.581111908,
      "lon": -72.7733306885,
      "tz": "America\/Santiago"
  },
  "SCJK": {
      "icao": "SCJK",
      "iata": "",
      "name": "Juan Kemp Airport",
      "city": "Osorno",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 459,
      "lat": -40.8844451904,
      "lon": -72.368888855,
      "tz": "America\/Santiago"
  },
  "SCJO": {
      "icao": "SCJO",
      "iata": "ZOS",
      "name": "Canal Bajo Carlos - Hott Siebert Airport",
      "city": "Osorno",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 187,
      "lat": -40.6111984253,
      "lon": -73.0609970093,
      "tz": "America\/Santiago"
  },
  "SCJV": {
      "icao": "SCJV",
      "iata": "",
      "name": "El Parron Airport",
      "city": "San Javier",
      "state": "Maule",
      "country": "CL",
      "elevation": 200,
      "lat": -35.5480537415,
      "lon": -71.7761077881,
      "tz": "America\/Santiago"
  },
  "SCKA": {
      "icao": "SCKA",
      "iata": "",
      "name": "Santa Marta Airport",
      "city": "San Carlos",
      "state": "Biobio",
      "country": "CL",
      "elevation": 577,
      "lat": -36.493057251,
      "lon": -71.8969421387,
      "tz": "America\/Santiago"
  },
  "SCKB": {
      "icao": "SCKB",
      "iata": "",
      "name": "Llollenorte Airport",
      "city": "Lago Caburga",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1641,
      "lat": -39.0574989319,
      "lon": -71.6897201538,
      "tz": "America\/Santiago"
  },
  "SCKC": {
      "icao": "SCKC",
      "iata": "",
      "name": "Roberto Chavez Airport",
      "city": "Cunco",
      "state": "Araucania",
      "country": "CL",
      "elevation": 895,
      "lat": -38.9069442749,
      "lon": -72.2152786255,
      "tz": "America\/Santiago"
  },
  "SCKD": {
      "icao": "SCKD",
      "iata": "",
      "name": "El Cardal Airport",
      "city": "Rio Bueno",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 525,
      "lat": -40.4678001404,
      "lon": -72.6849975586,
      "tz": "America\/Santiago"
  },
  "SCKE": {
      "icao": "SCKE",
      "iata": "",
      "name": "Piedra Negra Airport",
      "city": "Pelluhue",
      "state": "Maule",
      "country": "CL",
      "elevation": 164,
      "lat": -35.8544425964,
      "lon": -72.6458358765,
      "tz": "America\/Santiago"
  },
  "SCKI": {
      "icao": "SCKI",
      "iata": "",
      "name": "Los Lirios Airport",
      "city": "Curico",
      "state": "Maule",
      "country": "CL",
      "elevation": 820,
      "lat": -34.9038887024,
      "lon": -71.201385498,
      "tz": "America\/Santiago"
  },
  "SCKK": {
      "icao": "SCKK",
      "iata": "",
      "name": "La Cascada Airport",
      "city": "Molina",
      "state": "Maule",
      "country": "CL",
      "elevation": 1804,
      "lat": -35.3849983215,
      "lon": -71.1066665649,
      "tz": "America\/Santiago"
  },
  "SCKL": {
      "icao": "SCKL",
      "iata": "",
      "name": "Lipangui Airport",
      "city": "Lampa",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1558,
      "lat": -33.331111908,
      "lon": -70.8499984741,
      "tz": "America\/Santiago"
  },
  "SCKM": {
      "icao": "SCKM",
      "iata": "",
      "name": "Cochamo Airport",
      "city": "Cochamo",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 130,
      "lat": -41.4833335876,
      "lon": -72.3000030518,
      "tz": "America\/Santiago"
  },
  "SCKN": {
      "icao": "SCKN",
      "iata": "",
      "name": "Licancel Airport",
      "city": "Licanten",
      "state": "Maule",
      "country": "CL",
      "elevation": 59,
      "lat": -34.9891662598,
      "lon": -72.0188903809,
      "tz": "America\/Santiago"
  },
  "SCKO": {
      "icao": "SCKO",
      "iata": "",
      "name": "Agua Buena Airport",
      "city": "Collipulli",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1420,
      "lat": -37.993888855,
      "lon": -72.249168396,
      "tz": "America\/Santiago"
  },
  "SCKP": {
      "icao": "SCKP",
      "iata": "CPP",
      "name": "Coposa Airport",
      "city": "Pica",
      "state": "Tarapaca",
      "country": "CL",
      "elevation": 12468,
      "lat": -20.7504997253,
      "lon": -68.6835021973,
      "tz": "America\/Santiago"
  },
  "SCKQ": {
      "icao": "SCKQ",
      "iata": "",
      "name": "Curimanque Airport",
      "city": "Pucon",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1476,
      "lat": -39.3269462585,
      "lon": -72.0272216797,
      "tz": "America\/Santiago"
  },
  "SCKT": {
      "icao": "SCKT",
      "iata": "",
      "name": "Torreon Airport",
      "city": "Coelemu",
      "state": "Biobio",
      "country": "CL",
      "elevation": 216,
      "lat": -36.4988899231,
      "lon": -72.6800003052,
      "tz": "America\/Santiago"
  },
  "SCLA": {
      "icao": "SCLA",
      "iata": "",
      "name": "General Tovarias Airport",
      "city": "Lautaro",
      "state": "Araucania",
      "country": "CL",
      "elevation": 699,
      "lat": -38.5427780151,
      "lon": -72.4547195435,
      "tz": "America\/Santiago"
  },
  "SCLB": {
      "icao": "SCLB",
      "iata": "",
      "name": "Los Pehuenches Airport",
      "city": "Lebu",
      "state": "Biobio",
      "country": "CL",
      "elevation": 587,
      "lat": -37.6580543518,
      "lon": -73.629447937,
      "tz": "America\/Santiago"
  },
  "SCLC": {
      "icao": "SCLC",
      "iata": "",
      "name": "Municipal de Vitacura Airport",
      "city": "Santiago",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 2274,
      "lat": -33.3807983398,
      "lon": -70.5821990967,
      "tz": "America\/Santiago"
  },
  "SCLD": {
      "icao": "SCLD",
      "iata": "",
      "name": "Llanada Grande Airport",
      "city": "Llanada Grande",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 1001,
      "lat": -41.8666992188,
      "lon": -71.9344024658,
      "tz": "America\/Santiago"
  },
  "SCLE": {
      "icao": "SCLE",
      "iata": "",
      "name": "La Escondida Airport",
      "city": "Antofagasta",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 10289,
      "lat": -24.2947006226,
      "lon": -69.1316986084,
      "tz": "America\/Santiago"
  },
  "SCLF": {
      "icao": "SCLF",
      "iata": "",
      "name": "Calcurrupe Airport",
      "city": "Llifen",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 335,
      "lat": -40.2442016602,
      "lon": -72.2369003296,
      "tz": "America\/Santiago"
  },
  "SCLG": {
      "icao": "SCLG",
      "iata": "",
      "name": "La Aguada Airport",
      "city": "Pencahue",
      "state": "Maule",
      "country": "CL",
      "elevation": 246,
      "lat": -35.3427772522,
      "lon": -71.7769470215,
      "tz": "America\/Santiago"
  },
  "SCLI": {
      "icao": "SCLI",
      "iata": "",
      "name": "Torca Airport",
      "city": "Llico",
      "state": "Maule",
      "country": "CL",
      "elevation": 66,
      "lat": -34.7833328247,
      "lon": -72.0508346558,
      "tz": "America\/Santiago"
  },
  "SCLJ": {
      "icao": "SCLJ",
      "iata": "",
      "name": "La Junta Airport",
      "city": "La Junta",
      "state": "Aisen",
      "country": "CL",
      "elevation": 120,
      "lat": -43.975276947,
      "lon": -72.4130554199,
      "tz": "America\/Santiago"
  },
  "SCLK": {
      "icao": "SCLK",
      "iata": "",
      "name": "Lago Colico Airport",
      "city": "Cunco",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1148,
      "lat": -39.0574989319,
      "lon": -72.0741653442,
      "tz": "America\/Santiago"
  },
  "SCLL": {
      "icao": "SCLL",
      "iata": "VLR",
      "name": "Vallenar Airport",
      "city": "Vallenar",
      "state": "Atacama",
      "country": "CL",
      "elevation": 1725,
      "lat": -28.5963993073,
      "lon": -70.7559967041,
      "tz": "America\/Santiago"
  },
  "SCLM": {
      "icao": "SCLM",
      "iata": "",
      "name": "Las Mercedes Airport",
      "city": "San Javier",
      "state": "Maule",
      "country": "CL",
      "elevation": 246,
      "lat": -35.5,
      "lon": -71.8808364868,
      "tz": "America\/Santiago"
  },
  "SCLN": {
      "icao": "SCLN",
      "iata": "ZLR",
      "name": "Municipal de Linares Airport",
      "city": "Linares",
      "state": "Maule",
      "country": "CL",
      "elevation": 591,
      "lat": -35.8616981506,
      "lon": -71.5485992432,
      "tz": "America\/Santiago"
  },
  "SCLO": {
      "icao": "SCLO",
      "iata": "",
      "name": "Leones Airport",
      "city": "Chile Chico",
      "state": "Aisen",
      "country": "CL",
      "elevation": 705,
      "lat": -46.7655563354,
      "lon": -72.8183364868,
      "tz": "America\/Santiago"
  },
  "SCLP": {
      "icao": "SCLP",
      "iata": "",
      "name": "Los Petiles Airport",
      "city": "Molina",
      "state": "Maule",
      "country": "CL",
      "elevation": 1575,
      "lat": -35.2155570984,
      "lon": -71.1086120605,
      "tz": "America\/Santiago"
  },
  "SCLQ": {
      "icao": "SCLQ",
      "iata": "",
      "name": "Diego Portales Airport",
      "city": "La Ligua",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 250,
      "lat": -32.4524993896,
      "lon": -71.2588882446,
      "tz": "America\/Santiago"
  },
  "SCLR": {
      "icao": "SCLR",
      "iata": "",
      "name": "Los Alerces Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 345,
      "lat": -42.7805557251,
      "lon": -72.6447219849,
      "tz": "America\/Santiago"
  },
  "SCLS": {
      "icao": "SCLS",
      "iata": "",
      "name": "Esperanza Airport",
      "city": "Lautaro",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1230,
      "lat": -38.5488891602,
      "lon": -72.1458358765,
      "tz": "America\/Santiago"
  },
  "SCLU": {
      "icao": "SCLU",
      "iata": "",
      "name": "La Laguna Airport",
      "city": "Marchigue",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 528,
      "lat": -34.3525009155,
      "lon": -71.6638870239,
      "tz": "America\/Santiago"
  },
  "SCLV": {
      "icao": "SCLV",
      "iata": "",
      "name": "La Vina Airport",
      "city": "Guangali",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 870,
      "lat": -32.138053894,
      "lon": -71.3980560303,
      "tz": "America\/Santiago"
  },
  "SCLY": {
      "icao": "SCLY",
      "iata": "",
      "name": "La Playa Airport",
      "city": "Arauco",
      "state": "Biobio",
      "country": "CL",
      "elevation": 32,
      "lat": -37.2163887024,
      "lon": -73.2330551147,
      "tz": "America\/Santiago"
  },
  "SCMA": {
      "icao": "SCMA",
      "iata": "",
      "name": "Puerto Marin Balmaceda Airport",
      "city": "Puerto Marin Balmaceda",
      "state": "Aisen",
      "country": "CL",
      "elevation": 10,
      "lat": -43.7863883972,
      "lon": -72.9502792358,
      "tz": "America\/Santiago"
  },
  "SCMC": {
      "icao": "SCMC",
      "iata": "",
      "name": "Meseta Cosmelli Airport",
      "city": "Puerto Guadal",
      "state": "Aisen",
      "country": "CL",
      "elevation": 950,
      "lat": -46.7455558777,
      "lon": -72.5261077881,
      "tz": "America\/Santiago"
  },
  "SCME": {
      "icao": "SCME",
      "iata": "",
      "name": "Los Cuatro Diablos Airport",
      "city": "Melipilla",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 660,
      "lat": -33.676109314,
      "lon": -71.1433334351,
      "tz": "America\/Santiago"
  },
  "SCMF": {
      "icao": "SCMF",
      "iata": "",
      "name": "Malloco Airport",
      "city": "Villarrica",
      "state": "Araucania",
      "country": "CL",
      "elevation": 935,
      "lat": -39.2558326721,
      "lon": -72.3433303833,
      "tz": "America\/Santiago"
  },
  "SCMG": {
      "icao": "SCMG",
      "iata": "",
      "name": "Santa Maria de Mingre Airport",
      "city": "San Javier",
      "state": "Maule",
      "country": "CL",
      "elevation": 574,
      "lat": -35.5705566406,
      "lon": -72.0038909912,
      "tz": "America\/Santiago"
  },
  "SCMH": {
      "icao": "SCMH",
      "iata": "",
      "name": "La Esperanza Airport",
      "city": "Marchigue",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 540,
      "lat": -34.2872238159,
      "lon": -71.5583343506,
      "tz": "America\/Santiago"
  },
  "SCMI": {
      "icao": "SCMI",
      "iata": "",
      "name": "Los Tricahues Airport",
      "city": "Mialqui",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 1650,
      "lat": -30.7374992371,
      "lon": -70.8597259521,
      "tz": "America\/Santiago"
  },
  "SCMK": {
      "icao": "SCMK",
      "iata": "",
      "name": "Melinka Airport",
      "city": "Melinka",
      "state": "Aisen",
      "country": "CL",
      "elevation": 35,
      "lat": -43.8950004578,
      "lon": -73.7388916016,
      "tz": "America\/Santiago"
  },
  "SCML": {
      "icao": "SCML",
      "iata": "",
      "name": "Melipeuco Airport",
      "city": "Santa Barbara",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1388,
      "lat": -38.856111,
      "lon": -71.8125,
      "tz": "America\/Santiago"
  },
  "SCMO": {
      "icao": "SCMO",
      "iata": "",
      "name": "Los Monos Airport",
      "city": "Molina",
      "state": "Maule",
      "country": "CL",
      "elevation": 574,
      "lat": -35.1902770996,
      "lon": -71.4186096191,
      "tz": "America\/Santiago"
  },
  "SCMP": {
      "icao": "SCMP",
      "iata": "",
      "name": "Melipilla Airport",
      "city": "Melipilla",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 574,
      "lat": -33.6738891602,
      "lon": -71.193611145,
      "tz": "America\/Santiago"
  },
  "SCMR": {
      "icao": "SCMR",
      "iata": "",
      "name": "Las Aguilas Oriente Airport",
      "city": "Rapel",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 450,
      "lat": -34.169167,
      "lon": -71.531111,
      "tz": "America\/Santiago"
  },
  "SCMS": {
      "icao": "SCMS",
      "iata": "",
      "name": "Las Moras Airport",
      "city": "Longavi",
      "state": "Maule",
      "country": "CL",
      "elevation": 512,
      "lat": -36.010278,
      "lon": -71.594444,
      "tz": "America\/Santiago"
  },
  "SCMU": {
      "icao": "SCMU",
      "iata": "",
      "name": "Panilonco Airport",
      "city": "Pichilemu",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 574,
      "lat": -34.2811126709,
      "lon": -71.9469451904,
      "tz": "America\/Santiago"
  },
  "SCMV": {
      "icao": "SCMV",
      "iata": "",
      "name": "Vina San Pedro Airport",
      "city": "Molina",
      "state": "Maule",
      "country": "CL",
      "elevation": 738,
      "lat": -35.1058349609,
      "lon": -71.3277740479,
      "tz": "America\/Santiago"
  },
  "SCMY": {
      "icao": "SCMY",
      "iata": "",
      "name": "Carolina Airport",
      "city": "Michilla",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 128,
      "lat": -22.7036113739,
      "lon": -70.2825012207,
      "tz": "America\/Santiago"
  },
  "SCMZ": {
      "icao": "SCMZ",
      "iata": "",
      "name": "Marina de Rapel Airport",
      "city": "El Manzano",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 344,
      "lat": -34.1516685486,
      "lon": -71.4458312988,
      "tz": "America\/Santiago"
  },
  "SCNA": {
      "icao": "SCNA",
      "iata": "",
      "name": "Fundo La Cana Airport",
      "city": "Linares",
      "state": "Maule",
      "country": "CL",
      "elevation": 525,
      "lat": -35.976389,
      "lon": -71.597222,
      "tz": "America\/Santiago"
  },
  "SCND": {
      "icao": "SCND",
      "iata": "",
      "name": "Nadis Airport",
      "city": "Nadis",
      "state": "Aisen",
      "country": "CL",
      "elevation": 262,
      "lat": -47.4730567932,
      "lon": -72.9519424438,
      "tz": "America\/Santiago"
  },
  "SCNE": {
      "icao": "SCNE",
      "iata": "",
      "name": "Maria Elena Airport",
      "city": "Maria Elena",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 4003,
      "lat": -22.3072223663,
      "lon": -69.7050018311,
      "tz": "America\/Santiago"
  },
  "SCNG": {
      "icao": "SCNG",
      "iata": "",
      "name": "Papageno Airport",
      "city": "Panguipulli",
      "state": "Araucania",
      "country": "CL",
      "elevation": 853,
      "lat": -39.5505981445,
      "lon": -72.2902984619,
      "tz": "America\/Santiago"
  },
  "SCNH": {
      "icao": "SCNH",
      "iata": "",
      "name": "Ainhoa Airport",
      "city": "Vilcun",
      "state": "Araucania",
      "country": "CL",
      "elevation": 992,
      "lat": -38.683333,
      "lon": -72.243889,
      "tz": "America\/Santiago"
  },
  "SCNI": {
      "icao": "SCNI",
      "iata": "",
      "name": "Santa Eugenia Airport",
      "city": "San Nicolas",
      "state": "Biobio",
      "country": "CL",
      "elevation": 410,
      "lat": -36.4344444275,
      "lon": -72.1569442749,
      "tz": "America\/Santiago"
  },
  "SCNK": {
      "icao": "SCNK",
      "iata": "",
      "name": "Los Pelambres Airport",
      "city": "Cuncumen",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 4003,
      "lat": -31.8730564117,
      "lon": -70.6183319092,
      "tz": "America\/Santiago"
  },
  "SCNM": {
      "icao": "SCNM",
      "iata": "",
      "name": "Las Misiones Airport",
      "city": "Canete",
      "state": "Biobio",
      "country": "CL",
      "elevation": 252,
      "lat": -37.7761116028,
      "lon": -73.3836135864,
      "tz": "America\/Santiago"
  },
  "SCNO": {
      "icao": "SCNO",
      "iata": "",
      "name": "Nochaco Airport",
      "city": "Nochaco",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 328,
      "lat": -40.892868042,
      "lon": -72.8799972534,
      "tz": "America\/Santiago"
  },
  "SCNR": {
      "icao": "SCNR",
      "iata": "",
      "name": "Fundo Naicura Airport",
      "city": "Rengo",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 1050,
      "lat": -34.3202781677,
      "lon": -70.9202804565,
      "tz": "America\/Santiago"
  },
  "SCNS": {
      "icao": "SCNS",
      "iata": "",
      "name": "Sandra Scabini Airport",
      "city": "Punta Arenas",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 236,
      "lat": -52.3961105347,
      "lon": -69.756942749,
      "tz": "America\/Punta_Arenas"
  },
  "SCNT": {
      "icao": "SCNT",
      "iata": "PNT",
      "name": "Tte. Julio Gallardo Airport",
      "city": "Puerto Natales",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 217,
      "lat": -51.6715011597,
      "lon": -72.5283966064,
      "tz": "America\/Punta_Arenas"
  },
  "SCNY": {
      "icao": "SCNY",
      "iata": "",
      "name": "Yendegaia Airport",
      "city": "Navarino",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 164,
      "lat": -54.8313903809,
      "lon": -68.8316650391,
      "tz": "America\/Punta_Arenas"
  },
  "SCOA": {
      "icao": "SCOA",
      "iata": "",
      "name": "Estancia Los Loros Airport",
      "city": "Ovalle",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 820,
      "lat": -30.7894439697,
      "lon": -71.6183319092,
      "tz": "America\/Santiago"
  },
  "SCOB": {
      "icao": "SCOB",
      "iata": "",
      "name": "Tabali Bajo Airport",
      "city": "Ovalle",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 732,
      "lat": -30.643333,
      "lon": -71.401944,
      "tz": "America\/Santiago"
  },
  "SCOC": {
      "icao": "SCOC",
      "iata": "",
      "name": "Las Araucarias Airport",
      "city": "Puerto Octay",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 600,
      "lat": -40.993057251,
      "lon": -72.6597213745,
      "tz": "America\/Santiago"
  },
  "SCOE": {
      "icao": "SCOE",
      "iata": "",
      "name": "San Miguel Airport",
      "city": "Romeral",
      "state": "Maule",
      "country": "CL",
      "elevation": 1509,
      "lat": -34.962223053,
      "lon": -71.0163879395,
      "tz": "America\/Santiago"
  },
  "SCOH": {
      "icao": "SCOH",
      "iata": "",
      "name": "Villa O'Higgins Airport",
      "city": "Villa O'Higgins",
      "state": "Aisen",
      "country": "CL",
      "elevation": 898,
      "lat": -48.4692115784,
      "lon": -72.5643157959,
      "tz": "America\/Santiago"
  },
  "SCOI": {
      "icao": "SCOI",
      "iata": "",
      "name": "Los Coipos Airport",
      "city": "Hualanne",
      "state": "Maule",
      "country": "CL",
      "elevation": 318,
      "lat": -34.912778,
      "lon": -71.660833,
      "tz": "America\/Santiago"
  },
  "SCOL": {
      "icao": "SCOL",
      "iata": "",
      "name": "Refugio del Lago Airport",
      "city": "Puyehue",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 676,
      "lat": -40.6894454956,
      "lon": -72.3455581665,
      "tz": "America\/Santiago"
  },
  "SCOM": {
      "icao": "SCOM",
      "iata": "",
      "name": "Olmue Airport",
      "city": "Olmue",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 396,
      "lat": -32.9975013733,
      "lon": -71.1722259521,
      "tz": "America\/Santiago"
  },
  "SCON": {
      "icao": "SCON",
      "iata": "",
      "name": "Quellon Airport",
      "city": "Quellon",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 16,
      "lat": -43.1366996765,
      "lon": -73.6350021362,
      "tz": "America\/Santiago"
  },
  "SCOO": {
      "icao": "SCOO",
      "iata": "",
      "name": "Melimoyu Airport",
      "city": "Cisnes",
      "state": "Aisen",
      "country": "CL",
      "elevation": 20,
      "lat": -44.0975,
      "lon": -73.096944,
      "tz": "America\/Santiago"
  },
  "SCOP": {
      "icao": "SCOP",
      "iata": "",
      "name": "Pilauco Airport",
      "city": "Osorno",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 213,
      "lat": -40.5691680908,
      "lon": -73.1299972534,
      "tz": "America\/Santiago"
  },
  "SCOS": {
      "icao": "SCOS",
      "iata": "",
      "name": "Osorno Pampa Ale Airport",
      "city": "Osorno",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 79,
      "lat": -40.5471992493,
      "lon": -73.1556015015,
      "tz": "America\/Santiago"
  },
  "SCOT": {
      "icao": "SCOT",
      "iata": "",
      "name": "Santa Rosa de Tabali Airport",
      "city": "Ovalle",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 787,
      "lat": -30.6765003204,
      "lon": -71.4010009766,
      "tz": "America\/Santiago"
  },
  "SCOV": {
      "icao": "SCOV",
      "iata": "OVL",
      "name": "El Tuqui Airport",
      "city": "Ovalle",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 1100,
      "lat": -30.5592002869,
      "lon": -71.1755981445,
      "tz": "America\/Santiago"
  },
  "SCOY": {
      "icao": "SCOY",
      "iata": "",
      "name": "Huayanay Airport",
      "city": "Ovalle",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 164,
      "lat": -30.4305553436,
      "lon": -71.5322189331,
      "tz": "America\/Santiago"
  },
  "SCPA": {
      "icao": "SCPA",
      "iata": "",
      "name": "Paranal Airport",
      "city": "Antofagasta",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 6834,
      "lat": -24.6394004822,
      "lon": -70.3488998413,
      "tz": "America\/Santiago"
  },
  "SCPB": {
      "icao": "SCPB",
      "iata": "",
      "name": "Puelo Bajo Airport",
      "city": "Puelo Bajo",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 120,
      "lat": -41.6622238159,
      "lon": -72.3022232056,
      "tz": "America\/Santiago"
  },
  "SCPC": {
      "icao": "SCPC",
      "iata": "ZPC",
      "name": "Pucon Airport",
      "city": "Pucon",
      "state": "Araucania",
      "country": "CL",
      "elevation": 853,
      "lat": -39.2928009033,
      "lon": -71.9159011841,
      "tz": "America\/Santiago"
  },
  "SCPE": {
      "icao": "SCPE",
      "iata": "",
      "name": "San Pedro de Atacama Airport",
      "city": "San Pedro De Atacama",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 7960,
      "lat": -22.9216995239,
      "lon": -68.1584014893,
      "tz": "America\/Santiago"
  },
  "SCPF": {
      "icao": "SCPF",
      "iata": "",
      "name": "Marcel Marchant Airport",
      "city": "Puerto Montt",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 367,
      "lat": -41.4577789307,
      "lon": -72.9186096191,
      "tz": "America\/Santiago"
  },
  "SCPG": {
      "icao": "SCPG",
      "iata": "",
      "name": "Municipal de Panguipulli Airport",
      "city": "Panguipulli",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 940,
      "lat": -39.653301239,
      "lon": -72.3602981567,
      "tz": "America\/Santiago"
  },
  "SCPH": {
      "icao": "SCPH",
      "iata": "",
      "name": "Puyuhuapi Airport",
      "city": "Puyuhuapi",
      "state": "Aisen",
      "country": "CL",
      "elevation": 43,
      "lat": -44.3856010437,
      "lon": -72.5942001343,
      "tz": "America\/Santiago"
  },
  "SCPI": {
      "icao": "SCPI",
      "iata": "",
      "name": "Pullami Airport",
      "city": "Coihueco",
      "state": "Biobio",
      "country": "CL",
      "elevation": 804,
      "lat": -36.586667,
      "lon": -71.807778,
      "tz": "America\/Santiago"
  },
  "SCPK": {
      "icao": "SCPK",
      "iata": "",
      "name": "Puerto Cisnes Airport",
      "city": "Puerto Cisnes",
      "state": "Aisen",
      "country": "CL",
      "elevation": 45,
      "lat": -44.7458343506,
      "lon": -72.700553894,
      "tz": "America\/Santiago"
  },
  "SCPL": {
      "icao": "SCPL",
      "iata": "",
      "name": "Calpulli Airport",
      "city": "Paillaco",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 755,
      "lat": -40.1288986206,
      "lon": -72.6616973877,
      "tz": "America\/Santiago"
  },
  "SCPM": {
      "icao": "SCPM",
      "iata": "",
      "name": "Pichilemu Airport",
      "city": "Pichilemu",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 82,
      "lat": -34.3941650391,
      "lon": -72.0191650391,
      "tz": "America\/Santiago"
  },
  "SCPN": {
      "icao": "SCPN",
      "iata": "",
      "name": "Pillan Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 33,
      "lat": -42.5461120605,
      "lon": -72.4941635132,
      "tz": "America\/Santiago"
  },
  "SCPO": {
      "icao": "SCPO",
      "iata": "",
      "name": "Los Paltos Airport",
      "city": "Quinta Tilcoco",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 869,
      "lat": -34.3280563354,
      "lon": -70.9641647339,
      "tz": "America\/Santiago"
  },
  "SCPP": {
      "icao": "SCPP",
      "iata": "",
      "name": "Poco a Poco Airport",
      "city": "Mulchen",
      "state": "Biobio",
      "country": "CL",
      "elevation": 1680,
      "lat": -37.8488883972,
      "lon": -72.0083312988,
      "tz": "America\/Santiago"
  },
  "SCPQ": {
      "icao": "SCPQ",
      "iata": "MHC",
      "name": "Mocopulli Airport",
      "city": "Dalcahue",
      "state": "Los Lagos Region",
      "country": "CL",
      "elevation": 528,
      "lat": -42.340278,
      "lon": -73.715556,
      "tz": "America\/Santiago"
  },
  "SCPR": {
      "icao": "SCPR",
      "iata": "",
      "name": "Corte Alto Airport",
      "city": "Purranque",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 416,
      "lat": -40.9433326721,
      "lon": -73.1699981689,
      "tz": "America\/Santiago"
  },
  "SCPS": {
      "icao": "SCPS",
      "iata": "",
      "name": "Perales Airport",
      "city": "Copiapo",
      "state": "Atacama",
      "country": "CL",
      "elevation": 633,
      "lat": -27.3516674042,
      "lon": -70.5894470215,
      "tz": "America\/Santiago"
  },
  "SCPT": {
      "icao": "SCPT",
      "iata": "",
      "name": "La Puerta Airport",
      "city": "Santa Cruz",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 509,
      "lat": -34.613056,
      "lon": -71.375833,
      "tz": "America\/Santiago"
  },
  "SCPU": {
      "icao": "SCPU",
      "iata": "",
      "name": "Peulla Airport",
      "city": "Peulla",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 900,
      "lat": -41.0541648865,
      "lon": -72.0083312988,
      "tz": "America\/Santiago"
  },
  "SCPV": {
      "icao": "SCPV",
      "iata": "PUX",
      "name": "El Mirador Airport",
      "city": "Puerto Varas",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 430,
      "lat": -41.3493995667,
      "lon": -72.9467010498,
      "tz": "America\/Santiago"
  },
  "SCPW": {
      "icao": "SCPW",
      "iata": "",
      "name": "Peumo Airport",
      "city": "Peumo",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 557,
      "lat": -34.4088897705,
      "lon": -71.1688919067,
      "tz": "America\/Santiago"
  },
  "SCPX": {
      "icao": "SCPX",
      "iata": "",
      "name": "Punta Catalina Airport",
      "city": "Punta Catalina",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 90,
      "lat": -52.5916671753,
      "lon": -68.75,
      "tz": "America\/Punta_Arenas"
  },
  "SCPY": {
      "icao": "SCPY",
      "iata": "",
      "name": "Cerro Castillo Airport",
      "city": "Cerro Castillo",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 427,
      "lat": -51.26222229,
      "lon": -72.3375015259,
      "tz": "America\/Punta_Arenas"
  },
  "SCPZ": {
      "icao": "SCPZ",
      "iata": "",
      "name": "Patriot Hills Airport",
      "city": "Antartica",
      "state": "",
      "country": "AQ",
      "elevation": 2900,
      "lat": -80.3182983398,
      "lon": -81.3080978394,
      "tz": "Etc\/GMT+5"
  },
  "SCQC": {
      "icao": "SCQC",
      "iata": "",
      "name": "La Colmena Airport",
      "city": "Quino",
      "state": "Araucania",
      "country": "CL",
      "elevation": 823,
      "lat": -38.3330574036,
      "lon": -72.5869445801,
      "tz": "America\/Santiago"
  },
  "SCQE": {
      "icao": "SCQE",
      "iata": "",
      "name": "Quenac Airport",
      "city": "Isla Quenac",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 164,
      "lat": -42.4672241211,
      "lon": -73.3358306885,
      "tz": "America\/Santiago"
  },
  "SCQI": {
      "icao": "SCQI",
      "iata": "",
      "name": "Icalma Airport",
      "city": "Lonquimay",
      "state": "Araucania",
      "country": "CL",
      "elevation": 3806,
      "lat": -38.7497215271,
      "lon": -71.2177810669,
      "tz": "America\/Santiago"
  },
  "SCQK": {
      "icao": "SCQK",
      "iata": "",
      "name": "Lequecahue Airport",
      "city": "Tirua",
      "state": "Biobio",
      "country": "CL",
      "elevation": 220,
      "lat": -38.3516654968,
      "lon": -73.488609314,
      "tz": "America\/Santiago"
  },
  "SCQL": {
      "icao": "SCQL",
      "iata": "",
      "name": "El Boco Airport",
      "city": "Quillota",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 459,
      "lat": -32.8511123657,
      "lon": -71.2463912964,
      "tz": "America\/Santiago"
  },
  "SCQM": {
      "icao": "SCQM",
      "iata": "",
      "name": "Las Quemas Airport",
      "city": "Osorno",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 250,
      "lat": -40.6383323669,
      "lon": -73.1275024414,
      "tz": "America\/Santiago"
  },
  "SCQO": {
      "icao": "SCQO",
      "iata": "",
      "name": "Quitralco Airport",
      "city": "Aysen",
      "state": "Aisen",
      "country": "CL",
      "elevation": 46,
      "lat": -45.580556,
      "lon": -73.225556,
      "tz": "America\/Santiago"
  },
  "SCQR": {
      "icao": "SCQR",
      "iata": "",
      "name": "Los Morros Airport",
      "city": "Cobquecura",
      "state": "Biobio",
      "country": "CL",
      "elevation": 7,
      "lat": -36.1344451904,
      "lon": -72.8044433594,
      "tz": "America\/Santiago"
  },
  "SCQT": {
      "icao": "SCQT",
      "iata": "",
      "name": "LasTacas Airport",
      "city": "Quebrada Las Tacas",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 140,
      "lat": -30.0963993073,
      "lon": -71.3640975952,
      "tz": "America\/Santiago"
  },
  "SCQW": {
      "icao": "SCQW",
      "iata": "",
      "name": "Quemchi Airport",
      "city": "Quemchi",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 393,
      "lat": -42.1527786255,
      "lon": -73.5169448853,
      "tz": "America\/Santiago"
  },
  "SCQX": {
      "icao": "SCQX",
      "iata": "",
      "name": "Queilen Airport",
      "city": "Queilen",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 60,
      "lat": -42.8930549622,
      "lon": -73.4763870239,
      "tz": "America\/Santiago"
  },
  "SCQY": {
      "icao": "SCQY",
      "iata": "",
      "name": "Villa Portales Airport",
      "city": "Lonquimay",
      "state": "Araucania",
      "country": "CL",
      "elevation": 3182,
      "lat": -38.4499015808,
      "lon": -71.3547973633,
      "tz": "America\/Santiago"
  },
  "SCRA": {
      "icao": "SCRA",
      "iata": "CNR",
      "name": "Chanaral Airport",
      "city": "Chanaral",
      "state": "Atacama",
      "country": "CL",
      "elevation": 97,
      "lat": -26.3325004578,
      "lon": -70.6072998047,
      "tz": "America\/Santiago"
  },
  "SCRB": {
      "icao": "SCRB",
      "iata": "",
      "name": "Rio Bravo Airport",
      "city": "Tortel",
      "state": "Aisen",
      "country": "CL",
      "elevation": 37,
      "lat": -47.9858322144,
      "lon": -73.138885498,
      "tz": "America\/Santiago"
  },
  "SCRC": {
      "icao": "SCRC",
      "iata": "",
      "name": "Villa Tapera Airport",
      "city": "Rio Cisnes",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1640,
      "lat": -44.6263885498,
      "lon": -71.638885498,
      "tz": "America\/Santiago"
  },
  "SCRD": {
      "icao": "SCRD",
      "iata": "VAP",
      "name": "Rodelillo Airport",
      "city": "Vina Del Mar",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 1100,
      "lat": -33.0680999756,
      "lon": -71.5575027466,
      "tz": "America\/Santiago"
  },
  "SCRE": {
      "icao": "SCRE",
      "iata": "",
      "name": "Estancia Rio Cisnes Airport",
      "city": "Rio Cisnes",
      "state": "Aisen",
      "country": "CL",
      "elevation": 2150,
      "lat": -44.5,
      "lon": -71.3216629028,
      "tz": "America\/Santiago"
  },
  "SCRF": {
      "icao": "SCRF",
      "iata": "",
      "name": "Laguna San Rafael Airport",
      "city": "Laguna San Rafael",
      "state": "Aisen",
      "country": "CL",
      "elevation": 20,
      "lat": -46.6388893127,
      "lon": -73.8499984741,
      "tz": "America\/Santiago"
  },
  "SCRG": {
      "icao": "SCRG",
      "iata": "QRC",
      "name": "De La Independencia Airport",
      "city": "Rancagua",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 1446,
      "lat": -34.1736984253,
      "lon": -70.7757034302,
      "tz": "America\/Santiago"
  },
  "SCRH": {
      "icao": "SCRH",
      "iata": "",
      "name": "Renihue Airport",
      "city": "Renihue",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 16,
      "lat": -42.5855560303,
      "lon": -72.4944458008,
      "tz": "America\/Santiago"
  },
  "SCRI": {
      "icao": "SCRI",
      "iata": "",
      "name": "Rio Frio Airport",
      "city": "Rio Frio",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 1000,
      "lat": -41.7333335876,
      "lon": -71.9666671753,
      "tz": "America\/Santiago"
  },
  "SCRL": {
      "icao": "SCRL",
      "iata": "",
      "name": "La Estrella Airport",
      "city": "Rapel",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 397,
      "lat": -34.2011108398,
      "lon": -71.4816665649,
      "tz": "America\/Santiago"
  },
  "SCRM": {
      "icao": "SCRM",
      "iata": "TNM",
      "name": "Teniente Rodolfo Marsh Martin Base",
      "city": "Isla Rey Jorge",
      "state": "",
      "country": "AQ",
      "elevation": 147,
      "lat": -62.1907997131,
      "lon": -58.9866981506,
      "tz": "America\/Punta_Arenas"
  },
  "SCRN": {
      "icao": "SCRN",
      "iata": "",
      "name": "Rio Negro Airport",
      "city": "Rio Negro",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 10,
      "lat": -41.962223053,
      "lon": -72.4538879395,
      "tz": "America\/Santiago"
  },
  "SCRO": {
      "icao": "SCRO",
      "iata": "",
      "name": "Santa Barbara Airport",
      "city": "Romeral",
      "state": "Maule",
      "country": "CL",
      "elevation": 1420,
      "lat": -34.9588890076,
      "lon": -71.1827774048,
      "tz": "America\/Santiago"
  },
  "SCRP": {
      "icao": "SCRP",
      "iata": "",
      "name": "Rapelhuapi Airport",
      "city": "Rapel",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 568,
      "lat": -34.1058006287,
      "lon": -71.512802124,
      "tz": "America\/Santiago"
  },
  "SCRQ": {
      "icao": "SCRQ",
      "iata": "",
      "name": "Rucananco Airport",
      "city": "Rio Bueno",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 502,
      "lat": -40.521900177,
      "lon": -72.7727966309,
      "tz": "America\/Santiago"
  },
  "SCRR": {
      "icao": "SCRR",
      "iata": "",
      "name": "Purrahuin Airport",
      "city": "Rio Bueno",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 345,
      "lat": -40.365278,
      "lon": -72.776667,
      "tz": "America\/Santiago"
  },
  "SCRS": {
      "icao": "SCRS",
      "iata": "",
      "name": "El Rosario Airport",
      "city": "Cartagena",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 804,
      "lat": -33.488889,
      "lon": -71.405556,
      "tz": "America\/Santiago"
  },
  "SCRT": {
      "icao": "SCRT",
      "iata": "",
      "name": "El Almendro Airport",
      "city": "Retiro",
      "state": "Maule",
      "country": "CL",
      "elevation": 486,
      "lat": -35.9605560303,
      "lon": -71.7933349609,
      "tz": "America\/Santiago"
  },
  "SCRU": {
      "icao": "SCRU",
      "iata": "",
      "name": "Rio Murta Airport",
      "city": "Rio Murta",
      "state": "Aisen",
      "country": "CL",
      "elevation": 600,
      "lat": -46.4533348083,
      "lon": -72.688331604,
      "tz": "America\/Santiago"
  },
  "SCRV": {
      "icao": "SCRV",
      "iata": "",
      "name": "La Veronica Airport",
      "city": "Vilcun",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1280,
      "lat": -38.611389,
      "lon": -72.128889,
      "tz": "America\/Santiago"
  },
  "SCRW": {
      "icao": "SCRW",
      "iata": "",
      "name": "Rucalonco Airport",
      "city": "Paredones",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 240,
      "lat": -34.5486106873,
      "lon": -72.0452804565,
      "tz": "America\/Santiago"
  },
  "SCRZ": {
      "icao": "SCRZ",
      "iata": "",
      "name": "El Carrizal Airport",
      "city": "Marchigue",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 492,
      "lat": -34.443333,
      "lon": -71.706111,
      "tz": "America\/Santiago"
  },
  "SCSA": {
      "icao": "SCSA",
      "iata": "",
      "name": "Alberto Santos Dumont Airport",
      "city": "Til Til",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 2346,
      "lat": -33.0286102295,
      "lon": -70.8799972534,
      "tz": "America\/Santiago"
  },
  "SCSB": {
      "icao": "SCSB",
      "iata": "SMB",
      "name": "Franco Bianco Airport",
      "city": "Cerro Sombrero",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 104,
      "lat": -52.7366981506,
      "lon": -69.3336029053,
      "tz": "America\/Punta_Arenas"
  },
  "SCSD": {
      "icao": "SCSD",
      "iata": "",
      "name": "San Fernando Airport",
      "city": "San Fernando",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 1079,
      "lat": -34.5657997131,
      "lon": -70.9682998657,
      "tz": "America\/Santiago"
  },
  "SCSE": {
      "icao": "SCSE",
      "iata": "LSC",
      "name": "La Florida Airport",
      "city": "La Serena-Coquimbo",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 481,
      "lat": -29.9162006378,
      "lon": -71.1995010376,
      "tz": "America\/Santiago"
  },
  "SCSF": {
      "icao": "SCSF",
      "iata": "SSD",
      "name": "Victor Lafon Airport",
      "city": "San Felipe",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 2188,
      "lat": -32.7458000183,
      "lon": -70.7050018311,
      "tz": "America\/Santiago"
  },
  "SCSG": {
      "icao": "SCSG",
      "iata": "",
      "name": "San Geronimo Airport",
      "city": "Algarrobo",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 341,
      "lat": -33.3532981873,
      "lon": -71.6260986328,
      "tz": "America\/Santiago"
  },
  "SCSH": {
      "icao": "SCSH",
      "iata": "",
      "name": "El Budi Airport",
      "city": "Teodoro Schmidt",
      "state": "Araucania",
      "country": "CL",
      "elevation": 82,
      "lat": -39.0627784729,
      "lon": -73.1699981689,
      "tz": "America\/Santiago"
  },
  "SCSJ": {
      "icao": "SCSJ",
      "iata": "",
      "name": "San Javier Airport",
      "city": "San Javier",
      "state": "Maule",
      "country": "CL",
      "elevation": 361,
      "lat": -35.6297225952,
      "lon": -71.69972229,
      "tz": "America\/Santiago"
  },
  "SCSK": {
      "icao": "SCSK",
      "iata": "",
      "name": "Colorado Airport",
      "city": "San Clemente",
      "state": "Maule",
      "country": "CL",
      "elevation": 1410,
      "lat": -35.6630554199,
      "lon": -71.3036117554,
      "tz": "America\/Santiago"
  },
  "SCSL": {
      "icao": "SCSL",
      "iata": "",
      "name": "El Salar Airport",
      "city": "Salar\u00a0De Atacama",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 7556,
      "lat": -23.6488990784,
      "lon": -68.3050003052,
      "tz": "America\/Santiago"
  },
  "SCSM": {
      "icao": "SCSM",
      "iata": "",
      "name": "Minsal Airport",
      "city": "Salar\u00a0De Atacama",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 7776,
      "lat": -23.5852775574,
      "lon": -68.382774353,
      "tz": "America\/Santiago"
  },
  "SCSN": {
      "icao": "SCSN",
      "iata": "",
      "name": "Santo Domingo Airport",
      "city": "Santo Domingo",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 246,
      "lat": -33.6563987732,
      "lon": -71.614402771,
      "tz": "America\/Santiago"
  },
  "SCSO": {
      "icao": "SCSO",
      "iata": "",
      "name": "Costa del Sol Airport",
      "city": "Lago Rapel",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 390,
      "lat": -34.1208343506,
      "lon": -71.5272216797,
      "tz": "America\/Santiago"
  },
  "SCSP": {
      "icao": "SCSP",
      "iata": "",
      "name": "El Sobrante Airport",
      "city": "Petorca",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 2559,
      "lat": -32.223611,
      "lon": -70.8,
      "tz": "America\/Santiago"
  },
  "SCSQ": {
      "icao": "SCSQ",
      "iata": "",
      "name": "Quilpe Airport",
      "city": "San Pablo",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 157,
      "lat": -40.3411102295,
      "lon": -73.3172225952,
      "tz": "America\/Santiago"
  },
  "SCSR": {
      "icao": "SCSR",
      "iata": "",
      "name": "Segundo Corral Alto Airport",
      "city": "Segundo Corral",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 1200,
      "lat": -42.0833320618,
      "lon": -71.8638916016,
      "tz": "America\/Santiago"
  },
  "SCSS": {
      "icao": "SCSS",
      "iata": "",
      "name": "San Sebastian Airport",
      "city": "San Sebastian",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 50,
      "lat": -53.3170013428,
      "lon": -68.6500015259,
      "tz": "America\/Punta_Arenas"
  },
  "SCST": {
      "icao": "SCST",
      "iata": "WCA",
      "name": "Gamboa Airport",
      "city": "Castro",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 151,
      "lat": -42.4902992249,
      "lon": -73.7727966309,
      "tz": "America\/Santiago"
  },
  "SCSU": {
      "icao": "SCSU",
      "iata": "",
      "name": "Santa Lucia Airport",
      "city": "Freire",
      "state": "Araucania",
      "country": "CL",
      "elevation": 705,
      "lat": -38.9191665649,
      "lon": -72.3666687012,
      "tz": "America\/Santiago"
  },
  "SCSV": {
      "icao": "SCSV",
      "iata": "",
      "name": "Vinasutil Airport",
      "city": "Peralillo",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 427,
      "lat": -34.448890686,
      "lon": -71.3858337402,
      "tz": "America\/Santiago"
  },
  "SCSZ": {
      "icao": "SCSZ",
      "iata": "",
      "name": "Puerto Sanchez Airport",
      "city": "Puerto Sanchez",
      "state": "Aisen",
      "country": "CL",
      "elevation": 680,
      "lat": -46.5999984741,
      "lon": -72.6166687012,
      "tz": "America\/Santiago"
  },
  "SCTA": {
      "icao": "SCTA",
      "iata": "",
      "name": "Santa Luisa Airport",
      "city": "Santa Barbara",
      "state": "Biobio",
      "country": "CL",
      "elevation": 1818,
      "lat": -37.743889,
      "lon": -71.71,
      "tz": "America\/Santiago"
  },
  "SCTB": {
      "icao": "SCTB",
      "iata": "",
      "name": "Eulogio Sanchez Airport",
      "city": "Santiago",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 2129,
      "lat": -33.4562988281,
      "lon": -70.5466995239,
      "tz": "America\/Santiago"
  },
  "SCTC": {
      "icao": "SCTC",
      "iata": "ZCO",
      "name": "Maquehue Airport",
      "city": "Temuco",
      "state": "Araucania",
      "country": "CL",
      "elevation": 304,
      "lat": -38.7667999268,
      "lon": -72.6371002197,
      "tz": "America\/Santiago"
  },
  "SCTE": {
      "icao": "SCTE",
      "iata": "PMC",
      "name": "El Tepual Airport",
      "city": "Puerto Montt",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 294,
      "lat": -41.4388999939,
      "lon": -73.09400177,
      "tz": "America\/Santiago"
  },
  "SCTG": {
      "icao": "SCTG",
      "iata": "",
      "name": "Tongoy Airport",
      "city": "Tongoy",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 49,
      "lat": -30.2663879395,
      "lon": -71.4836120605,
      "tz": "America\/Santiago"
  },
  "SCTH": {
      "icao": "SCTH",
      "iata": "",
      "name": "Tres Chorrillos Airport",
      "city": "San Gregorio",
      "state": "Magallanes",
      "country": "CL",
      "elevation": 322,
      "lat": -52.525278,
      "lon": -70.723889,
      "tz": "America\/Punta_Arenas"
  },
  "SCTL": {
      "icao": "SCTL",
      "iata": "TLX",
      "name": "Panguilemo Airport",
      "city": "Talca",
      "state": "Maule",
      "country": "CL",
      "elevation": 371,
      "lat": -35.3777999878,
      "lon": -71.6016998291,
      "tz": "America\/Santiago"
  },
  "SCTM": {
      "icao": "SCTM",
      "iata": "",
      "name": "La Montana Airport",
      "city": "Curico",
      "state": "Maule",
      "country": "CL",
      "elevation": 1969,
      "lat": -34.9702758789,
      "lon": -70.8463897705,
      "tz": "America\/Santiago"
  },
  "SCTN": {
      "icao": "SCTN",
      "iata": "WCH",
      "name": "Chaiten Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 13,
      "lat": -42.932800293,
      "lon": -72.6990966797,
      "tz": "America\/Santiago"
  },
  "SCTO": {
      "icao": "SCTO",
      "iata": "ZIC",
      "name": "Victoria Airport",
      "city": "Victoria",
      "state": "Araucania",
      "country": "CL",
      "elevation": 1148,
      "lat": -38.2456016541,
      "lon": -72.3486022949,
      "tz": "America\/Santiago"
  },
  "SCTP": {
      "icao": "SCTP",
      "iata": "",
      "name": "Rio Pascua Airport",
      "city": "Tortel",
      "state": "Aisen",
      "country": "CL",
      "elevation": 197,
      "lat": -48.2277793884,
      "lon": -73.2977752686,
      "tz": "America\/Santiago"
  },
  "SCTQ": {
      "icao": "SCTQ",
      "iata": "",
      "name": "Tres Quebradas Airport",
      "city": "Alto Del Carmen",
      "state": "Atacama",
      "country": "CL",
      "elevation": 11690,
      "lat": -29.2527999878,
      "lon": -70.0888977051,
      "tz": "America\/Santiago"
  },
  "SCTR": {
      "icao": "SCTR",
      "iata": "",
      "name": "Traiguen Airport",
      "city": "Traiguen",
      "state": "Araucania",
      "country": "CL",
      "elevation": 812,
      "lat": -38.2713890076,
      "lon": -72.6624984741,
      "tz": "America\/Santiago"
  },
  "SCTS": {
      "icao": "SCTS",
      "iata": "",
      "name": "Santa Teresa del Almendral Airport",
      "city": "Melipilla",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 574,
      "lat": -33.575553894,
      "lon": -71.2608337402,
      "tz": "America\/Santiago"
  },
  "SCTT": {
      "icao": "SCTT",
      "iata": "TTC",
      "name": "Las Breas Airport",
      "city": "Taltal",
      "state": "Antofagasta",
      "country": "CL",
      "elevation": 2580,
      "lat": -25.5643005371,
      "lon": -70.3759002686,
      "tz": "America\/Santiago"
  },
  "SCTU": {
      "icao": "SCTU",
      "iata": "",
      "name": "Litueche Airport",
      "city": "Litueche",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 787,
      "lat": -34.1105537415,
      "lon": -71.7158355713,
      "tz": "America\/Santiago"
  },
  "SCTW": {
      "icao": "SCTW",
      "iata": "",
      "name": "El Tapihue Airport",
      "city": "Casablanca",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 935,
      "lat": -33.3202781677,
      "lon": -71.3361129761,
      "tz": "America\/Santiago"
  },
  "SCUI": {
      "icao": "SCUI",
      "iata": "",
      "name": "Pumalin Airport",
      "city": "Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 18,
      "lat": -42.6958351135,
      "lon": -72.8333358765,
      "tz": "America\/Santiago"
  },
  "SCUL": {
      "icao": "SCUL",
      "iata": "",
      "name": "El Litral Airport",
      "city": "Bulnes",
      "state": "Biobio",
      "country": "CL",
      "elevation": 180,
      "lat": -36.7936096191,
      "lon": -72.4208297729,
      "tz": "America\/Santiago"
  },
  "SCUM": {
      "icao": "SCUM",
      "iata": "",
      "name": "La Obra Airport",
      "city": "Cumpeo",
      "state": "Maule",
      "country": "CL",
      "elevation": 673,
      "lat": -35.3005561829,
      "lon": -71.3302764893,
      "tz": "America\/Santiago"
  },
  "SCUN": {
      "icao": "SCUN",
      "iata": "",
      "name": "Uni Frutti Airport",
      "city": "Teno",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 950,
      "lat": -34.8130569458,
      "lon": -71.0461120605,
      "tz": "America\/Santiago"
  },
  "SCUP": {
      "icao": "SCUP",
      "iata": "",
      "name": "Lontuecito Airport",
      "city": "Cumpeo",
      "state": "Maule",
      "country": "CL",
      "elevation": 1066,
      "lat": -35.2822227478,
      "lon": -71.2363891602,
      "tz": "America\/Santiago"
  },
  "SCUR": {
      "icao": "SCUR",
      "iata": "",
      "name": "Rucamalen Airport",
      "city": "Bulnes",
      "state": "Biobio",
      "country": "CL",
      "elevation": 475,
      "lat": -36.8119430542,
      "lon": -72.1672210693,
      "tz": "America\/Santiago"
  },
  "SCUT": {
      "icao": "SCUT",
      "iata": "",
      "name": "Verfrut Sur Airport",
      "city": "Longavi",
      "state": "Maule",
      "country": "CL",
      "elevation": 1132,
      "lat": -36.2125015259,
      "lon": -71.5444412231,
      "tz": "America\/Santiago"
  },
  "SCUZ": {
      "icao": "SCUZ",
      "iata": "",
      "name": "Aerosanta Cruz Airport",
      "city": "Santa Cruz",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 502,
      "lat": -34.6491661072,
      "lon": -71.3861083984,
      "tz": "America\/Santiago"
  },
  "SCVA": {
      "icao": "SCVA",
      "iata": "",
      "name": "Vinamar Airport",
      "city": "Casablanca",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 935,
      "lat": -33.3569450378,
      "lon": -71.3541641235,
      "tz": "America\/Santiago"
  },
  "SCVB": {
      "icao": "SCVB",
      "iata": "",
      "name": "Hospital Villa Baviera Airport",
      "city": "Parral",
      "state": "Maule",
      "country": "CL",
      "elevation": 1040,
      "lat": -36.4002761841,
      "lon": -71.563331604,
      "tz": "America\/Santiago"
  },
  "SCVC": {
      "icao": "SCVC",
      "iata": "",
      "name": "El Indio Airport",
      "city": "Vicuna",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 11975,
      "lat": -29.834444046,
      "lon": -70.0461120605,
      "tz": "America\/Santiago"
  },
  "SCVD": {
      "icao": "SCVD",
      "iata": "ZAL",
      "name": "Pichoy Airport",
      "city": "Valdivia",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 59,
      "lat": -39.6500015259,
      "lon": -73.0860977173,
      "tz": "America\/Santiago"
  },
  "SCVE": {
      "icao": "SCVE",
      "iata": "",
      "name": "Lago Verde Airport",
      "city": "Lago Verde",
      "state": "Aisen",
      "country": "CL",
      "elevation": 1020,
      "lat": -44.2708320618,
      "lon": -71.9166641235,
      "tz": "America\/Santiago"
  },
  "SCVF": {
      "icao": "SCVF",
      "iata": "",
      "name": "Verfrut Airport",
      "city": "San Pedro",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 443,
      "lat": -34.0072212219,
      "lon": -71.3919448853,
      "tz": "America\/Santiago"
  },
  "SCVG": {
      "icao": "SCVG",
      "iata": "",
      "name": "El Vergel Airport",
      "city": "Rinihue",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 820,
      "lat": -39.8293991089,
      "lon": -72.4882965088,
      "tz": "America\/Santiago"
  },
  "SCVH": {
      "icao": "SCVH",
      "iata": "",
      "name": "La Victoria de Chacabuco Airport",
      "city": "Santiago",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 2139,
      "lat": -33.0508346558,
      "lon": -70.7088851929,
      "tz": "America\/Santiago"
  },
  "SCVI": {
      "icao": "SCVI",
      "iata": "",
      "name": "Villarrica Airport",
      "city": "Villarrica",
      "state": "Araucania",
      "country": "CL",
      "elevation": 945,
      "lat": -39.3125,
      "lon": -72.0622253418,
      "tz": "America\/Santiago"
  },
  "SCVJ": {
      "icao": "SCVJ",
      "iata": "",
      "name": "Paredes Viejas Airport",
      "city": "Marchigue",
      "state": "O'Higgins",
      "country": "CL",
      "elevation": 449,
      "lat": -34.3958320618,
      "lon": -71.5452804565,
      "tz": "America\/Santiago"
  },
  "SCVK": {
      "icao": "SCVK",
      "iata": "",
      "name": "El Alamo Airport",
      "city": "Vichuquen",
      "state": "Maule",
      "country": "CL",
      "elevation": 33,
      "lat": -34.8477783203,
      "lon": -72.0222244263,
      "tz": "America\/Santiago"
  },
  "SCVL": {
      "icao": "SCVL",
      "iata": "",
      "name": "Las Marias Airport",
      "city": "Valdivia",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 13,
      "lat": -39.7969017029,
      "lon": -73.2416992188,
      "tz": "America\/Santiago"
  },
  "SCVM": {
      "icao": "SCVM",
      "iata": "KNA",
      "name": "Vina del mar Airport",
      "city": "Vina Del Mar",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 461,
      "lat": -32.9496002197,
      "lon": -71.4785995483,
      "tz": "America\/Santiago"
  },
  "SCVN": {
      "icao": "SCVN",
      "iata": "",
      "name": "Huancara Airport",
      "city": "Vicuna",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 1968,
      "lat": -30.0268993378,
      "lon": -70.7516021729,
      "tz": "America\/Santiago"
  },
  "SCVO": {
      "icao": "SCVO",
      "iata": "",
      "name": "Maria Ester Airport",
      "city": "Victoria",
      "state": "Araucania",
      "country": "CL",
      "elevation": 978,
      "lat": -38.2319450378,
      "lon": -72.4833297729,
      "tz": "America\/Santiago"
  },
  "SCVQ": {
      "icao": "SCVQ",
      "iata": "",
      "name": "Cuatro Pantanos Airport",
      "city": "Vichuquen",
      "state": "Maule",
      "country": "CL",
      "elevation": 82,
      "lat": -34.837223053,
      "lon": -72.0650024414,
      "tz": "America\/Santiago"
  },
  "SCVS": {
      "icao": "SCVS",
      "iata": "",
      "name": "Lago Vargas Airport",
      "city": "Cochrane",
      "state": "Aisen",
      "country": "CL",
      "elevation": 56,
      "lat": -47.6772232056,
      "lon": -73.067779541,
      "tz": "America\/Santiago"
  },
  "SCVT": {
      "icao": "SCVT",
      "iata": "",
      "name": "Vina Tarapaca Airport",
      "city": "Isla De Maipo",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 1066,
      "lat": -33.7661094666,
      "lon": -70.9238891602,
      "tz": "America\/Santiago"
  },
  "SCVU": {
      "icao": "SCVU",
      "iata": "",
      "name": "Agromanzun Airport",
      "city": "Vilcun",
      "state": "Araucania",
      "country": "CL",
      "elevation": 738,
      "lat": -38.6958351135,
      "lon": -72.3405532837,
      "tz": "America\/Santiago"
  },
  "SCVV": {
      "icao": "SCVV",
      "iata": "",
      "name": "Los Maitenes de Villa Vieja Airport",
      "city": "La Union",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 147,
      "lat": -40.3163986206,
      "lon": -72.9772033691,
      "tz": "America\/Santiago"
  },
  "SCVY": {
      "icao": "SCVY",
      "iata": "",
      "name": "Malla Airport",
      "city": "Vilcun",
      "state": "Araucania",
      "country": "CL",
      "elevation": 820,
      "lat": -38.6913871765,
      "lon": -72.2855529785,
      "tz": "America\/Santiago"
  },
  "SCXA": {
      "icao": "SCXA",
      "iata": "",
      "name": "Alupenhue Airport",
      "city": "Molina",
      "state": "Maule",
      "country": "CL",
      "elevation": 1600,
      "lat": -35.2394447327,
      "lon": -71.0708312988,
      "tz": "America\/Santiago"
  },
  "SCXB": {
      "icao": "SCXB",
      "iata": "",
      "name": "Las Brujas Airport",
      "city": "Salamanca",
      "state": "Coquimbo",
      "country": "CL",
      "elevation": 1483,
      "lat": -31.7866668701,
      "lon": -71.0111083984,
      "tz": "America\/Santiago"
  },
  "SCXR": {
      "icao": "SCXR",
      "iata": "",
      "name": "Las Bandurrias Airport",
      "city": "Lago Ranco",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 328,
      "lat": -40.3199996948,
      "lon": -72.2249984741,
      "tz": "America\/Santiago"
  },
  "SCYB": {
      "icao": "SCYB",
      "iata": "",
      "name": "Trilahue Airport",
      "city": "Yumbel",
      "state": "Biobio",
      "country": "CL",
      "elevation": 423,
      "lat": -37.1172218323,
      "lon": -72.4111099243,
      "tz": "America\/Santiago"
  },
  "SCYC": {
      "icao": "SCYC",
      "iata": "",
      "name": "La Capilla Airport",
      "city": "Puyehue",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 485,
      "lat": -40.6277770996,
      "lon": -72.76222229,
      "tz": "America\/Santiago"
  },
  "SCYL": {
      "icao": "SCYL",
      "iata": "",
      "name": "Lican Airport",
      "city": "Puyehue",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 625,
      "lat": -40.6413993835,
      "lon": -72.4111022949,
      "tz": "America\/Santiago"
  },
  "SCYO": {
      "icao": "SCYO",
      "iata": "",
      "name": "Poyo Airport",
      "city": "El Chaiten",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 13,
      "lat": -42.226667,
      "lon": -72.693611,
      "tz": "America\/Santiago"
  },
  "SCYR": {
      "icao": "SCYR",
      "iata": "",
      "name": "Los Maitenes Airport",
      "city": "Retiro",
      "state": "Maule",
      "country": "CL",
      "elevation": 538,
      "lat": -36.031665802,
      "lon": -71.7411117554,
      "tz": "America\/Santiago"
  },
  "SCYU": {
      "icao": "SCYU",
      "iata": "",
      "name": "Cuyumaique Airport",
      "city": "Purranque",
      "state": "Los-Lagos",
      "country": "CL",
      "elevation": 443,
      "lat": -40.940556,
      "lon": -73.011667,
      "tz": "America\/Santiago"
  },
  "SCZB": {
      "icao": "SCZB",
      "iata": "",
      "name": "Pozo Brujo Airport",
      "city": "La Union",
      "state": "Los-Rios",
      "country": "CL",
      "elevation": 394,
      "lat": -40.205278,
      "lon": -72.568056,
      "tz": "America\/Santiago"
  },
  "SCZC": {
      "icao": "SCZC",
      "iata": "",
      "name": "Casas Viejas Airport",
      "city": "Zapallar",
      "state": "Valparaiso",
      "country": "CL",
      "elevation": 262,
      "lat": -32.5952758789,
      "lon": -71.3472213745,
      "tz": "America\/Santiago"
  },
  "SCZE": {
      "icao": "SCZE",
      "iata": "",
      "name": "Estero Seco Airport",
      "city": "Pirque",
      "state": "Santiago-Metropolitan",
      "country": "CL",
      "elevation": 2461,
      "lat": -33.7449989319,
      "lon": -70.549446106,
      "tz": "America\/Santiago"
  },
  "SD00": {
      "icao": "SD00",
      "iata": "",
      "name": "Mj Aviation Ii Airport",
      "city": "Elk Point",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1115,
      "lat": 42.6786994934,
      "lon": -96.6988983154,
      "tz": "America\/Chicago"
  },
  "SD01": {
      "icao": "SD01",
      "iata": "",
      "name": "Mj Aviation I Airport",
      "city": "Letcher",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1305,
      "lat": 43.8997001648,
      "lon": -98.1076965332,
      "tz": "America\/Chicago"
  },
  "SD03": {
      "icao": "SD03",
      "iata": "",
      "name": "Calico Field",
      "city": "Winfred",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1750,
      "lat": 44.0504989624,
      "lon": -97.3350982666,
      "tz": "America\/Chicago"
  },
  "SD05": {
      "icao": "SD05",
      "iata": "",
      "name": "Thorson Airfield",
      "city": "Aberdeen",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1300,
      "lat": 45.3716011047,
      "lon": -98.4786987305,
      "tz": "America\/Chicago"
  },
  "SD13": {
      "icao": "SD13",
      "iata": "",
      "name": "Hofer Private Airport",
      "city": "Doland",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1368,
      "lat": 44.8666000366,
      "lon": -98.0719985962,
      "tz": "America\/Chicago"
  },
  "SD14": {
      "icao": "SD14",
      "iata": "",
      "name": "Valburg Ranch Airport",
      "city": "Draper",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1688,
      "lat": 43.7332992554,
      "lon": -100.3980026245,
      "tz": "America\/Chicago"
  },
  "SD19": {
      "icao": "SD19",
      "iata": "",
      "name": "Andersen Farms Airport",
      "city": "Badger",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1750,
      "lat": 44.4653015137,
      "lon": -97.2225036621,
      "tz": "America\/Chicago"
  },
  "SD21": {
      "icao": "SD21",
      "iata": "",
      "name": "Hayes Emergency Airstrip",
      "city": "Hayes",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1986,
      "lat": 44.3708000183,
      "lon": -101.0090026855,
      "tz": "America\/Denver"
  },
  "SD24": {
      "icao": "SD24",
      "iata": "",
      "name": "Bruch Ranch Airport",
      "city": "Sturgis",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3070,
      "lat": 44.4472007751,
      "lon": -103.3949966431,
      "tz": "America\/Denver"
  },
  "SD25": {
      "icao": "SD25",
      "iata": "",
      "name": "Rappe Field",
      "city": "Ipswich",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1521,
      "lat": 45.4522018433,
      "lon": -99.012298584,
      "tz": "America\/Chicago"
  },
  "SD29": {
      "icao": "SD29",
      "iata": "",
      "name": "Menno Airport",
      "city": "Menno",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1350,
      "lat": 43.2444000244,
      "lon": -97.5531005859,
      "tz": "America\/Chicago"
  },
  "SD33": {
      "icao": "SD33",
      "iata": "",
      "name": "Sky Ranch For Boys Airport",
      "city": "Camp Crook",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3200,
      "lat": 45.5,
      "lon": -104,
      "tz": "America\/Denver"
  },
  "SD35": {
      "icao": "SD35",
      "iata": "",
      "name": "Bruch Airfield",
      "city": "Sturgis",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2980,
      "lat": 44.494167,
      "lon": -103.394444,
      "tz": "America\/Denver"
  },
  "SD36": {
      "icao": "SD36",
      "iata": "",
      "name": "Booth Ranch Airport",
      "city": "Ridgeview",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2250,
      "lat": 45.1291999817,
      "lon": -100.7559967041,
      "tz": "America\/Denver"
  },
  "SD37": {
      "icao": "SD37",
      "iata": "",
      "name": "Fiedler Airport",
      "city": "Selby",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1950,
      "lat": 45.4961013794,
      "lon": -100.0039978027,
      "tz": "America\/Chicago"
  },
  "SD41": {
      "icao": "SD41",
      "iata": "",
      "name": "Shambo Ranch Airport",
      "city": "Lemmon",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2500,
      "lat": 45.5332984924,
      "lon": -101.8339996338,
      "tz": "America\/Denver"
  },
  "SD42": {
      "icao": "SD42",
      "iata": "",
      "name": "Waltner & Richards Airport",
      "city": "Bridgewater",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1403,
      "lat": 43.5666007996,
      "lon": -97.5336990356,
      "tz": "America\/Chicago"
  },
  "SD43": {
      "icao": "SD43",
      "iata": "",
      "name": "Ingle Airport",
      "city": "Cavour",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1305,
      "lat": 44.2397003174,
      "lon": -98.049697876,
      "tz": "America\/Chicago"
  },
  "SD44": {
      "icao": "SD44",
      "iata": "",
      "name": "Cook Field",
      "city": "Chamberlain",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1678,
      "lat": 43.7832984924,
      "lon": -99.257598877,
      "tz": "America\/Chicago"
  },
  "SD46": {
      "icao": "SD46",
      "iata": "",
      "name": "Jensen Airport",
      "city": "Dell Rapids",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1520,
      "lat": 43.8333015442,
      "lon": -96.8503036499,
      "tz": "America\/Chicago"
  },
  "SD47": {
      "icao": "SD47",
      "iata": "",
      "name": "Hunt Field",
      "city": "Eagle Butte",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2440,
      "lat": 45.0027999878,
      "lon": -101.2129974365,
      "tz": "America\/Denver"
  },
  "SD48": {
      "icao": "SD48",
      "iata": "",
      "name": "Blomberg 42 Ranch Private Airport",
      "city": "Faith",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2610,
      "lat": 45.2000007629,
      "lon": -102.6500015259,
      "tz": "America\/Denver"
  },
  "SD49": {
      "icao": "SD49",
      "iata": "",
      "name": "Hite Private Airport",
      "city": "Ferney",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1320,
      "lat": 45.2872009277,
      "lon": -98.0542984009,
      "tz": "America\/Chicago"
  },
  "SD50": {
      "icao": "SD50",
      "iata": "",
      "name": "Harrold Municipal Airport",
      "city": "Harrold",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1787,
      "lat": 44.5308990479,
      "lon": -99.7474975586,
      "tz": "America\/Chicago"
  },
  "SD53": {
      "icao": "SD53",
      "iata": "",
      "name": "Howard Field",
      "city": "Beresford",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1250,
      "lat": 42.9063987732,
      "lon": -96.810798645,
      "tz": "America\/Chicago"
  },
  "SD55": {
      "icao": "SD55",
      "iata": "",
      "name": "Winter Airfield",
      "city": "Huron",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1323,
      "lat": 44.3157997131,
      "lon": -98.2733001709,
      "tz": "America\/Chicago"
  },
  "SD56": {
      "icao": "SD56",
      "iata": "",
      "name": "Ralph Myers Airport",
      "city": "Onida",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1900,
      "lat": 44.8666000366,
      "lon": -99.6171035767,
      "tz": "America\/Chicago"
  },
  "SD57": {
      "icao": "SD57",
      "iata": "",
      "name": "W L Thompson Airport",
      "city": "Pierre",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2113,
      "lat": 44.5666007996,
      "lon": -100.75,
      "tz": "America\/Denver"
  },
  "SD59": {
      "icao": "SD59",
      "iata": "",
      "name": "Carr Airport",
      "city": "Prairie City",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2886,
      "lat": 45.5332984924,
      "lon": -102.8140029907,
      "tz": "America\/Denver"
  },
  "SD60": {
      "icao": "SD60",
      "iata": "",
      "name": "Ike John Private Airport",
      "city": "Strool\/Prairie City",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2756,
      "lat": 45.4000015259,
      "lon": -102.7959976196,
      "tz": "America\/Denver"
  },
  "SD61": {
      "icao": "SD61",
      "iata": "",
      "name": "Plihal Farms Airport",
      "city": "Tyndall",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1435,
      "lat": 43.0167007446,
      "lon": -97.8669967651,
      "tz": "America\/Chicago"
  },
  "SD62": {
      "icao": "SD62",
      "iata": "",
      "name": "Schaller Airport",
      "city": "Verdon",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1320,
      "lat": 45.25,
      "lon": -98.1669998169,
      "tz": "America\/Chicago"
  },
  "SD65": {
      "icao": "SD65",
      "iata": "",
      "name": "Whipple Ranch Airport",
      "city": "Wilmot",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1462,
      "lat": 45.4376983643,
      "lon": -96.9998016357,
      "tz": "America\/Chicago"
  },
  "SD66": {
      "icao": "SD66",
      "iata": "",
      "name": "Cooks Airport",
      "city": "Cottonwood",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2456,
      "lat": 43.9733009338,
      "lon": -101.952003479,
      "tz": "America\/Denver"
  },
  "SD68": {
      "icao": "SD68",
      "iata": "",
      "name": "Flying T Airport",
      "city": "Hot Springs",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3675,
      "lat": 43.3847007751,
      "lon": -103.4309997559,
      "tz": "America\/Denver"
  },
  "SD71": {
      "icao": "SD71",
      "iata": "",
      "name": "Bogner Field",
      "city": "Oelrichs",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3660,
      "lat": 43.1166000366,
      "lon": -103.3669967651,
      "tz": "America\/Denver"
  },
  "SD72": {
      "icao": "SD72",
      "iata": "",
      "name": "Vig Ranch Airfield",
      "city": "Opal",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2600,
      "lat": 44.8499984741,
      "lon": -102.5839996338,
      "tz": "America\/Denver"
  },
  "SD73": {
      "icao": "SD73",
      "iata": "",
      "name": "Staben Strip",
      "city": "Philip",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2227,
      "lat": 44.5208015442,
      "lon": -101.5930023193,
      "tz": "America\/Denver"
  },
  "SD74": {
      "icao": "SD74",
      "iata": "",
      "name": "Vander Wal Private Airport",
      "city": "Pollock",
      "state": "North-Dakota",
      "country": "US",
      "elevation": 1955,
      "lat": 45.9954986572,
      "lon": -100.3759994507,
      "tz": "America\/Chicago"
  },
  "SD75": {
      "icao": "SD75",
      "iata": "",
      "name": "Livingston Airport",
      "city": "Stickney",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1641,
      "lat": 43.5910987854,
      "lon": -98.452003479,
      "tz": "America\/Chicago"
  },
  "SD76": {
      "icao": "SD76",
      "iata": "",
      "name": "Tennant Ranch Airport",
      "city": "Camp Crook",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3090,
      "lat": 45.8293991089,
      "lon": -103.9449996948,
      "tz": "America\/Denver"
  },
  "SD77": {
      "icao": "SD77",
      "iata": "",
      "name": "Barber Strip",
      "city": "Rapid City",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3200,
      "lat": 44.0003013611,
      "lon": -103.0179977417,
      "tz": "America\/Denver"
  },
  "SD78": {
      "icao": "SD78",
      "iata": "",
      "name": "Anderson Aerial Spraying Airport",
      "city": "Kennebec",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1750,
      "lat": 43.9015007019,
      "lon": -99.8788986206,
      "tz": "America\/Chicago"
  },
  "SD79": {
      "icao": "SD79",
      "iata": "",
      "name": "Monty Harer Airstrip",
      "city": "Hoven",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1860,
      "lat": 45.2014007568,
      "lon": -99.8361968994,
      "tz": "America\/Chicago"
  },
  "SD81": {
      "icao": "SD81",
      "iata": "",
      "name": "Vivian Airport",
      "city": "Vivian",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1920,
      "lat": 43.9314002991,
      "lon": -100.3150024414,
      "tz": "America\/Chicago"
  },
  "SD82": {
      "icao": "SD82",
      "iata": "",
      "name": "Webster-Eneboe Airstrip",
      "city": "Wilmot",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1160,
      "lat": 45.4249992371,
      "lon": -96.8669967651,
      "tz": "America\/Chicago"
  },
  "SD83": {
      "icao": "SD83",
      "iata": "",
      "name": "Lee Airport",
      "city": "De Smet",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1555,
      "lat": 44.2155990601,
      "lon": -97.6074981689,
      "tz": "America\/Chicago"
  },
  "SD84": {
      "icao": "SD84",
      "iata": "",
      "name": "Marone Airport",
      "city": "Huron",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1285,
      "lat": 44.2658004761,
      "lon": -98.1203994751,
      "tz": "America\/Chicago"
  },
  "SD88": {
      "icao": "SD88",
      "iata": "",
      "name": "Dunn Airport",
      "city": "Bison",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2467,
      "lat": 45.3890991211,
      "lon": -102.0029983521,
      "tz": "America\/Denver"
  },
  "SD89": {
      "icao": "SD89",
      "iata": "",
      "name": "Priebe Landing Strip",
      "city": "Pukwana",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1628,
      "lat": 43.7705993652,
      "lon": -99.2074966431,
      "tz": "America\/Chicago"
  },
  "SD90": {
      "icao": "SD90",
      "iata": "",
      "name": "Mitchell's Strip",
      "city": "Spearfish",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 3920,
      "lat": 44.4672012329,
      "lon": -103.7870025635,
      "tz": "America\/Denver"
  },
  "SD93": {
      "icao": "SD93",
      "iata": "",
      "name": "Gary Myers Airport",
      "city": "Gettysburg",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1930,
      "lat": 44.864200592,
      "lon": -99.6063995361,
      "tz": "America\/Chicago"
  },
  "SD94": {
      "icao": "SD94",
      "iata": "",
      "name": "Lundin Airport",
      "city": "Revillo",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1186,
      "lat": 44.9930000305,
      "lon": -96.4769973755,
      "tz": "America\/Chicago"
  },
  "SD97": {
      "icao": "SD97",
      "iata": "",
      "name": "Oller Airport",
      "city": "Vivian",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2100,
      "lat": 43.8777999878,
      "lon": -100.3619995117,
      "tz": "America\/Chicago"
  },
  "SD98": {
      "icao": "SD98",
      "iata": "",
      "name": "Barber Airport",
      "city": "Enning",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 2655,
      "lat": 44.4625015259,
      "lon": -102.5520019531,
      "tz": "America\/Denver"
  },
  "SD99": {
      "icao": "SD99",
      "iata": "",
      "name": "Glawe's Airport",
      "city": "Sioux Falls",
      "state": "South-Dakota",
      "country": "US",
      "elevation": 1495,
      "lat": 43.625,
      "lon": -96.7502975464,
      "tz": "America\/Chicago"
  },
  "SDAA": {
      "icao": "SDAA",
      "iata": "",
      "name": "Araras Airport",
      "city": "Araras",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2247,
      "lat": -22.339099884,
      "lon": -47.3592987061,
      "tz": "America\/Sao_Paulo"
  },
  "SDAD": {
      "icao": "SDAD",
      "iata": "",
      "name": "Everaldo Moras Barreto Airport",
      "city": "Adamantina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1394,
      "lat": -21.6947994232,
      "lon": -51.0973014832,
      "tz": "America\/Sao_Paulo"
  },
  "SDAE": {
      "icao": "SDAE",
      "iata": "",
      "name": "Sao Pedro Airport",
      "city": "Sao Pedro",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1858,
      "lat": -22.5836105347,
      "lon": -47.8961105347,
      "tz": "America\/Sao_Paulo"
  },
  "SDAF": {
      "icao": "SDAF",
      "iata": "",
      "name": "Fazenda Palmeiras Airport",
      "city": "Analandia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2762,
      "lat": -22.1567001343,
      "lon": -47.7192993164,
      "tz": "America\/Sao_Paulo"
  },
  "SDAG": {
      "icao": "SDAG",
      "iata": "",
      "name": "Angra dos Reis Airport",
      "city": "Angra Dos Reis",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 10,
      "lat": -22.9752998352,
      "lon": -44.3070983887,
      "tz": "America\/Sao_Paulo"
  },
  "SDAI": {
      "icao": "SDAI",
      "iata": "",
      "name": "Americana Airport",
      "city": "Americana",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2085,
      "lat": -22.7558002472,
      "lon": -47.2694015503,
      "tz": "America\/Sao_Paulo"
  },
  "SDAJ": {
      "icao": "SDAJ",
      "iata": "",
      "name": "Fazenda Santa Marina Airport",
      "city": "Santo Antonio Do Aracangua",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1126,
      "lat": -20.8470993042,
      "lon": -50.7879981995,
      "tz": "America\/Sao_Paulo"
  },
  "SDAM": {
      "icao": "SDAM",
      "iata": "CPQ",
      "name": "Amarais Airport",
      "city": "Campinas",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2008,
      "lat": -22.8591995239,
      "lon": -47.1082000732,
      "tz": "America\/Sao_Paulo"
  },
  "SDAN": {
      "icao": "SDAN",
      "iata": "",
      "name": "Fazenda Santo Angelo Airport",
      "city": "Brotas",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1680,
      "lat": -22.2455558777,
      "lon": -48.2577781677,
      "tz": "America\/Sao_Paulo"
  },
  "SDAS": {
      "icao": "SDAS",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Mirandopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1141,
      "lat": -21.2756996155,
      "lon": -51.2831993103,
      "tz": "America\/Sao_Paulo"
  },
  "SDAY": {
      "icao": "SDAY",
      "iata": "0",
      "name": "Fazenda Altair Airport",
      "city": "Santa Ernestina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2034,
      "lat": -21.4671993256,
      "lon": -48.3671989441,
      "tz": "America\/Sao_Paulo"
  },
  "SDBA": {
      "icao": "SDBA",
      "iata": "",
      "name": "Batatais Airport",
      "city": "Batatais",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2890,
      "lat": -20.8772220612,
      "lon": -47.5855560303,
      "tz": "America\/Sao_Paulo"
  },
  "SDBB": {
      "icao": "SDBB",
      "iata": "",
      "name": "Bebedouro Airport",
      "city": "Bebedouro",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1942,
      "lat": -20.9004001617,
      "lon": -48.4733009338,
      "tz": "America\/Sao_Paulo"
  },
  "SDBI": {
      "icao": "SDBI",
      "iata": "",
      "name": "Aeroclube Airport",
      "city": "Birigui",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1394,
      "lat": -21.2819442749,
      "lon": -50.3269424438,
      "tz": "America\/Sao_Paulo"
  },
  "SDBK": {
      "icao": "SDBK",
      "iata": "QCJ",
      "name": "Botucatu Airport",
      "city": "Botucatu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 3012,
      "lat": -22.9395008087,
      "lon": -48.4679985046,
      "tz": "America\/Sao_Paulo"
  },
  "SDBN": {
      "icao": "SDBN",
      "iata": "",
      "name": "Fazenda Bonanza Airport",
      "city": "Salto De Pirapora",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2316,
      "lat": -23.6861114502,
      "lon": -47.5574989319,
      "tz": "America\/Sao_Paulo"
  },
  "SDBV": {
      "icao": "SDBV",
      "iata": "",
      "name": "Balneario Sao Januario Airport",
      "city": "Ilha Comprida",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 26,
      "lat": -24.8708324432,
      "lon": -47.7583312988,
      "tz": "America\/Sao_Paulo"
  },
  "SDBY": {
      "icao": "SDBY",
      "iata": "0",
      "name": "Bariri Airport",
      "city": "Bariri",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1594,
      "lat": -22.0692005157,
      "lon": -48.706401825,
      "tz": "America\/Sao_Paulo"
  },
  "SDCA": {
      "icao": "SDCA",
      "iata": "",
      "name": "Capao Bonito Airport",
      "city": "Capao Bonito",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2402,
      "lat": -24.0361995697,
      "lon": -48.3567008972,
      "tz": "America\/Sao_Paulo"
  },
  "SDCD": {
      "icao": "SDCD",
      "iata": "",
      "name": "Catanduva Airport",
      "city": "Catanduva",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1841,
      "lat": -21.1492004395,
      "lon": -48.9887008667,
      "tz": "America\/Sao_Paulo"
  },
  "SDCE": {
      "icao": "SDCE",
      "iata": "",
      "name": "Fazenda Marambaia Airport",
      "city": "Pirapora",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1766,
      "lat": -17.4383335114,
      "lon": -44.9369430542,
      "tz": "America\/Sao_Paulo"
  },
  "SDCG": {
      "icao": "SDCG",
      "iata": "OLC",
      "name": "Senadora Eunice Micheles Airport",
      "city": "Sao Paulo De Olivenca",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 335,
      "lat": -3.4679295077,
      "lon": -68.9204120636,
      "tz": "America\/Manaus"
  },
  "SDCL": {
      "icao": "SDCL",
      "iata": "",
      "name": "Fazenda Campo Alegre Airport",
      "city": "Cerqueira Cesar",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2182,
      "lat": -23.1541671753,
      "lon": -49.2088890076,
      "tz": "America\/Sao_Paulo"
  },
  "SDCM": {
      "icao": "SDCM",
      "iata": "",
      "name": "Sete Povos Airport",
      "city": "Sao Desiderio",
      "state": "",
      "country": "BR",
      "elevation": 2957,
      "lat": -12.8633327484,
      "lon": -46.1624984741,
      "tz": "America\/Bahia"
  },
  "SDCO": {
      "icao": "SDCO",
      "iata": "SOD",
      "name": "Sorocaba Airport",
      "city": "Sorocaba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2077,
      "lat": -23.4780006409,
      "lon": -47.4900016785,
      "tz": "America\/Sao_Paulo"
  },
  "SDCR": {
      "icao": "SDCR",
      "iata": "",
      "name": "Fazenda Caicara Airport",
      "city": "Itanhaem",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 36,
      "lat": -24.0711116791,
      "lon": -46.8294448853,
      "tz": "America\/Sao_Paulo"
  },
  "SDCT": {
      "icao": "SDCT",
      "iata": "",
      "name": "Fazenda Santo Antonio do Oricanga Airport",
      "city": "Estiva Gerbi",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2237,
      "lat": -22.2286109924,
      "lon": -46.9700012207,
      "tz": "America\/Sao_Paulo"
  },
  "SDCU": {
      "icao": "SDCU",
      "iata": "",
      "name": "Brigadeiro Francisco Pinto de Moura Airport",
      "city": "Casemiro De Abreu",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 18,
      "lat": -22.5541667938,
      "lon": -42.1150016785,
      "tz": "America\/Sao_Paulo"
  },
  "SDCW": {
      "icao": "SDCW",
      "iata": "",
      "name": "Fazenda Paloma Airport",
      "city": "Colombia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1706,
      "lat": -20.3261108398,
      "lon": -48.8283348083,
      "tz": "America\/Sao_Paulo"
  },
  "SDCZ": {
      "icao": "SDCZ",
      "iata": "",
      "name": "Fazenda Bom Jardim Airport",
      "city": "Mangaratiba",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 16,
      "lat": -22.9361114502,
      "lon": -44.0986099243,
      "tz": "America\/Sao_Paulo"
  },
  "SDDB": {
      "icao": "SDDB",
      "iata": "",
      "name": "Maggi Agropecuaria Airport",
      "city": "Campos Novos Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1703,
      "lat": -22.6124992371,
      "lon": -50.0955543518,
      "tz": "America\/Sao_Paulo"
  },
  "SDDJ": {
      "icao": "SDDJ",
      "iata": "0",
      "name": "Fazenda Santa Maria Airport",
      "city": "Moncoes",
      "state": "",
      "country": "BR",
      "elevation": 1525,
      "lat": -20.8488998413,
      "lon": -50.0793991089,
      "tz": "America\/Sao_Paulo"
  },
  "SDDK": {
      "icao": "SDDK",
      "iata": "",
      "name": "Saint-Exupery Airport",
      "city": "Ocaucu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1886,
      "lat": -22.4827785492,
      "lon": -49.9838905334,
      "tz": "America\/Sao_Paulo"
  },
  "SDDL": {
      "icao": "SDDL",
      "iata": "",
      "name": "Fazenda Dois Lagos Airport",
      "city": "Tatui",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1916,
      "lat": -23.3075008392,
      "lon": -47.9338874817,
      "tz": "America\/Sao_Paulo"
  },
  "SDDN": {
      "icao": "SDDN",
      "iata": "",
      "name": "Andradina Airport",
      "city": "Andradina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1247,
      "lat": -20.9249992371,
      "lon": -51.3819999695,
      "tz": "America\/Sao_Paulo"
  },
  "SDDR": {
      "icao": "SDDR",
      "iata": "QDC",
      "name": "Dracena Airport",
      "city": "Dracena",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1220,
      "lat": -21.4605007172,
      "lon": -51.6068992615,
      "tz": "America\/Sao_Paulo"
  },
  "SDDV": {
      "icao": "SDDV",
      "iata": "0",
      "name": "Usina Catanduva Airport",
      "city": "Palmares Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1860,
      "lat": -21.1268997192,
      "lon": -48.8446998596,
      "tz": "America\/Sao_Paulo"
  },
  "SDDX": {
      "icao": "SDDX",
      "iata": "",
      "name": "Fazenda Paiquere Airport",
      "city": "Populina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1483,
      "lat": -19.8880558014,
      "lon": -50.5141677856,
      "tz": "America\/Sao_Paulo"
  },
  "SDEC": {
      "icao": "SDEC",
      "iata": "",
      "name": "Rancho Enagri Airport",
      "city": "Alto Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2572,
      "lat": -16.95611,
      "lon": -53.57,
      "tz": "America\/Cuiaba"
  },
  "SDED": {
      "icao": "SDED",
      "iata": "",
      "name": "Edra do Brasil Airport",
      "city": "Ipeuna",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2008,
      "lat": -22.4402770996,
      "lon": -47.7016677856,
      "tz": "America\/Sao_Paulo"
  },
  "SDEE": {
      "icao": "SDEE",
      "iata": "",
      "name": "Rancho Villela Airport",
      "city": "Itai",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1801,
      "lat": -23.5702781677,
      "lon": -48.8633346558,
      "tz": "America\/Sao_Paulo"
  },
  "SDEG": {
      "icao": "SDEG",
      "iata": "",
      "name": "Fazenda Nova California Airport",
      "city": "Martinopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1450,
      "lat": -22.4666996002,
      "lon": -51.2008018494,
      "tz": "America\/Sao_Paulo"
  },
  "SDEJ": {
      "icao": "SDEJ",
      "iata": "",
      "name": "Fazenda Guara do Pinhal Airport",
      "city": "Itapetininga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2036,
      "lat": -23.6658325195,
      "lon": -48.0736122131,
      "tz": "America\/Sao_Paulo"
  },
  "SDEM": {
      "icao": "SDEM",
      "iata": "",
      "name": "Estancia Machado Airport",
      "city": "Alvares Machado",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1388,
      "lat": -22.1011104584,
      "lon": -51.4516677856,
      "tz": "America\/Sao_Paulo"
  },
  "SDEO": {
      "icao": "SDEO",
      "iata": "",
      "name": "Fazenda Entre Rios Airport",
      "city": "Ibitinga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1709,
      "lat": -21.8785991669,
      "lon": -48.6594009399,
      "tz": "America\/Sao_Paulo"
  },
  "SDEP": {
      "icao": "SDEP",
      "iata": "",
      "name": "Presidente Epitacio Airport",
      "city": "Presidente Epitacio",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 974,
      "lat": -21.7742996216,
      "lon": -52.1437988281,
      "tz": "America\/Campo_Grande"
  },
  "SDER": {
      "icao": "SDER",
      "iata": "",
      "name": "Fazenda Entre Rios Airport",
      "city": "Sud Menucci",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1207,
      "lat": -20.7649993896,
      "lon": -50.8569450378,
      "tz": "America\/Sao_Paulo"
  },
  "SDES": {
      "icao": "SDES",
      "iata": "",
      "name": "Estancia Colorado Airport",
      "city": "Sales De Oliveira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2372,
      "lat": -20.7602787018,
      "lon": -47.8619422913,
      "tz": "America\/Sao_Paulo"
  },
  "SDET": {
      "icao": "SDET",
      "iata": "",
      "name": "Tiete Airport",
      "city": "Tiete",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1644,
      "lat": -23.1047210693,
      "lon": -47.724445343,
      "tz": "America\/Sao_Paulo"
  },
  "SDFA": {
      "icao": "SDFA",
      "iata": "",
      "name": "Fazenda Bonanca Airport",
      "city": "Pereira Barreto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1200,
      "lat": -20.676399231,
      "lon": -51.0307998657,
      "tz": "America\/Sao_Paulo"
  },
  "SDFB": {
      "icao": "SDFB",
      "iata": "0",
      "name": "Fazenda Brumado Airport",
      "city": "Barretos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1755,
      "lat": -20.4230995178,
      "lon": -48.567199707,
      "tz": "America\/Sao_Paulo"
  },
  "SDFC": {
      "icao": "SDFC",
      "iata": "0",
      "name": "Fazenda Constancia Airport",
      "city": "Altair",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1673,
      "lat": -20.4885997772,
      "lon": -49.1880989075,
      "tz": "America\/Sao_Paulo"
  },
  "SDFD": {
      "icao": "SDFD",
      "iata": "",
      "name": "Fernandopolis Airport",
      "city": "Fernandopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1640,
      "lat": -20.276599884,
      "lon": -50.2155990601,
      "tz": "America\/Sao_Paulo"
  },
  "SDFE": {
      "icao": "SDFE",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 633,
      "lat": -22.0680561066,
      "lon": -56.7024993896,
      "tz": "America\/Campo_Grande"
  },
  "SDFF": {
      "icao": "SDFF",
      "iata": "",
      "name": "Fazenda Fittipaldi Citrus Airport",
      "city": "Araraquara",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1673,
      "lat": -21.8544445038,
      "lon": -48.2958335876,
      "tz": "America\/Sao_Paulo"
  },
  "SDFH": {
      "icao": "SDFH",
      "iata": "",
      "name": "Fazenda Amalia Airport",
      "city": "Santa Rosa Do Viterbo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2318,
      "lat": -21.4440002441,
      "lon": -47.3708992004,
      "tz": "America\/Sao_Paulo"
  },
  "SDFK": {
      "icao": "SDFK",
      "iata": "",
      "name": "Fazenda Figueira Airport",
      "city": "Maraba Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1234,
      "lat": -22.0688896179,
      "lon": -52.1588897705,
      "tz": "America\/Sao_Paulo"
  },
  "SDFL": {
      "icao": "SDFL",
      "iata": "",
      "name": "Fazenda Santa Albana Airport",
      "city": "Itapetininga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2051,
      "lat": -23.5711116791,
      "lon": -48.2833328247,
      "tz": "America\/Sao_Paulo"
  },
  "SDFM": {
      "icao": "SDFM",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Teodoro Sampaio",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1083,
      "lat": -22.2888889313,
      "lon": -52.2558326721,
      "tz": "America\/Sao_Paulo"
  },
  "SDFN": {
      "icao": "SDFN",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Fartura",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1703,
      "lat": -23.4750003815,
      "lon": -49.543888092,
      "tz": "America\/Sao_Paulo"
  },
  "SDFT": {
      "icao": "SDFT",
      "iata": "",
      "name": "Virgulino de Oliveira Airport",
      "city": "Itapira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2152,
      "lat": -22.4144439697,
      "lon": -46.8088874817,
      "tz": "America\/Sao_Paulo"
  },
  "SDFU": {
      "icao": "SDFU",
      "iata": "",
      "name": "Fazenda Ponte Funda Airport",
      "city": "Presidente Eptacio",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 984,
      "lat": -21.8705558777,
      "lon": -52.1630554199,
      "tz": "America\/Sao_Paulo"
  },
  "SDFX": {
      "icao": "SDFX",
      "iata": "",
      "name": "Casa Nova Airport",
      "city": "Casa Nova",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1247,
      "lat": -9.1570901871,
      "lon": -40.9376983643,
      "tz": "America\/Bahia"
  },
  "SDFY": {
      "icao": "SDFY",
      "iata": "",
      "name": "Fazenda Ipameri Airport",
      "city": "Osvaldo Cruz",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1280,
      "lat": -21.6313896179,
      "lon": -50.8088874817,
      "tz": "America\/Sao_Paulo"
  },
  "SDGB": {
      "icao": "SDGB",
      "iata": "",
      "name": "Usina Colorado Airport",
      "city": "Guaira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1988,
      "lat": -20.2769432068,
      "lon": -48.1819458008,
      "tz": "America\/Sao_Paulo"
  },
  "SDGC": {
      "icao": "SDGC",
      "iata": "0",
      "name": "Garca Airport",
      "city": "Garca",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2182,
      "lat": -22.183599472,
      "lon": -49.6561012268,
      "tz": "America\/Sao_Paulo"
  },
  "SDGF": {
      "icao": "SDGF",
      "iata": "",
      "name": "Fazenda Fartura Airport",
      "city": "Ocaucu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1647,
      "lat": -22.3672218323,
      "lon": -49.9469451904,
      "tz": "America\/Sao_Paulo"
  },
  "SDGJ": {
      "icao": "SDGJ",
      "iata": "",
      "name": "Fazenda Suissa Airport",
      "city": "Guaimbe",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1748,
      "lat": -21.9122219086,
      "lon": -49.8574981689,
      "tz": "America\/Sao_Paulo"
  },
  "SDGK": {
      "icao": "SDGK",
      "iata": "",
      "name": "Fazenda Gurucaia Airport",
      "city": "Guararapes",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1345,
      "lat": -21.4947223663,
      "lon": -50.7338905334,
      "tz": "America\/Sao_Paulo"
  },
  "SDGP": {
      "icao": "SDGP",
      "iata": "",
      "name": "Hotel Fazenda Capricho Airport",
      "city": "Avare",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2099,
      "lat": -23.2244434357,
      "lon": -48.7961120605,
      "tz": "America\/Sao_Paulo"
  },
  "SDGR": {
      "icao": "SDGR",
      "iata": "",
      "name": "Guararapes Airport",
      "city": "Guararapes",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1312,
      "lat": -21.2630558014,
      "lon": -50.6530570984,
      "tz": "America\/Sao_Paulo"
  },
  "SDGT": {
      "icao": "SDGT",
      "iata": "",
      "name": "Fazenda Alvorada Airport",
      "city": "Campos Novos Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1895,
      "lat": -22.5249996185,
      "lon": -50.0458335876,
      "tz": "America\/Sao_Paulo"
  },
  "SDGU": {
      "icao": "SDGU",
      "iata": "",
      "name": "Usina Bonfim Airport",
      "city": "Guariba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2116,
      "lat": -21.4044437408,
      "lon": -48.3033332825,
      "tz": "America\/Sao_Paulo"
  },
  "SDGY": {
      "icao": "SDGY",
      "iata": "",
      "name": "Fazenda Anhangai Airport",
      "city": "Aracatuba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1181,
      "lat": -20.9274997711,
      "lon": -50.7433319092,
      "tz": "America\/Sao_Paulo"
  },
  "SDHC": {
      "icao": "SDHC",
      "iata": "",
      "name": "Fazenda Koga Airport",
      "city": "Getulina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1292,
      "lat": -21.8719444275,
      "lon": -49.9802780151,
      "tz": "America\/Sao_Paulo"
  },
  "SDHE": {
      "icao": "SDHE",
      "iata": "",
      "name": "Fazenda Herdade Airport",
      "city": "Itapira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2494,
      "lat": -22.4925003052,
      "lon": -46.8436126709,
      "tz": "America\/Sao_Paulo"
  },
  "SDIB": {
      "icao": "SDIB",
      "iata": "",
      "name": "Irmaos Ribeiro Airport",
      "city": "Espirito Santo Do Pinhal",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 3018,
      "lat": -22.1581001282,
      "lon": -46.7752990723,
      "tz": "America\/Sao_Paulo"
  },
  "SDID": {
      "icao": "SDID",
      "iata": "",
      "name": "Itarare Airport",
      "city": "Itarare",
      "state": "Parana",
      "country": "BR",
      "elevation": 2598,
      "lat": -24.1352787018,
      "lon": -49.3283348083,
      "tz": "America\/Sao_Paulo"
  },
  "SDIG": {
      "icao": "SDIG",
      "iata": "",
      "name": "Ibitinga Airport",
      "city": "Ibitinga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1780,
      "lat": -21.7472991943,
      "lon": -48.8557014465,
      "tz": "America\/Sao_Paulo"
  },
  "SDIH": {
      "icao": "SDIH",
      "iata": "",
      "name": "Fazenda Irohy Airport",
      "city": "Biritiba-Mirim",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2467,
      "lat": -23.5655555725,
      "lon": -46.069442749,
      "tz": "America\/Sao_Paulo"
  },
  "SDIL": {
      "icao": "SDIL",
      "iata": "",
      "name": "Fazenda Pedra Branca Airport",
      "city": "Angra Dos Reis",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 10,
      "lat": -22.9034004211,
      "lon": -44.2890014648,
      "tz": "America\/Sao_Paulo"
  },
  "SDIM": {
      "icao": "SDIM",
      "iata": "",
      "name": "Itanhaem Airport",
      "city": "Itanhaem",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 13,
      "lat": -24.1647224426,
      "lon": -46.7855567932,
      "tz": "America\/Sao_Paulo"
  },
  "SDIN": {
      "icao": "SDIN",
      "iata": "",
      "name": "Clube do Ceu Airport",
      "city": "Rio De Janeiro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 3,
      "lat": -22.9974994659,
      "lon": -43.4013900757,
      "tz": "America\/Sao_Paulo"
  },
  "SDIO": {
      "icao": "SDIO",
      "iata": "",
      "name": "Aeroclube de Itapolis Airport",
      "city": "Itapolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1739,
      "lat": -21.5997219086,
      "lon": -48.8327789307,
      "tz": "America\/Sao_Paulo"
  },
  "SDIP": {
      "icao": "SDIP",
      "iata": "",
      "name": "Fazenda Centro de Voo a Vela Ipua Airport",
      "city": "Cacapava",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1913,
      "lat": -23.0433330536,
      "lon": -45.775554657,
      "tz": "America\/Sao_Paulo"
  },
  "SDIU": {
      "icao": "SDIU",
      "iata": "",
      "name": "Itu Airport",
      "city": "Itu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2231,
      "lat": -23.3497219086,
      "lon": -47.3277778625,
      "tz": "America\/Sao_Paulo"
  },
  "SDIV": {
      "icao": "SDIV",
      "iata": "",
      "name": "Ituverava Airport",
      "city": "Ituverava",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2044,
      "lat": -20.3754997253,
      "lon": -47.768699646,
      "tz": "America\/Sao_Paulo"
  },
  "SDIZ": {
      "icao": "SDIZ",
      "iata": "",
      "name": "Fazenda Sao Paulo Airport",
      "city": "Cardoso",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1656,
      "lat": -20.1555557251,
      "lon": -49.9347229004,
      "tz": "America\/Sao_Paulo"
  },
  "SDJA": {
      "icao": "SDJA",
      "iata": "",
      "name": "Doutor Jose Augusto de Arruda Botelho Airport",
      "city": "Itirapina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2569,
      "lat": -22.19472,
      "lon": -47.86167,
      "tz": "America\/Sao_Paulo"
  },
  "SDJC": {
      "icao": "SDJC",
      "iata": "0",
      "name": "Jaboticabal Airport",
      "city": "Jaboticabal",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2024,
      "lat": -21.2299995422,
      "lon": -48.2846984863,
      "tz": "America\/Sao_Paulo"
  },
  "SDJF": {
      "icao": "SDJF",
      "iata": "",
      "name": "Fazenda Jequitiba Airport",
      "city": "Buri",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2310,
      "lat": -23.8452777863,
      "lon": -48.4480552673,
      "tz": "America\/Sao_Paulo"
  },
  "SDJG": {
      "icao": "SDJG",
      "iata": "",
      "name": "Fazenda Ponte Preta Airport",
      "city": "Santa Cruz Do Rio Pardo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1689,
      "lat": -22.7333335876,
      "lon": -49.4833335876,
      "tz": "America\/Sao_Paulo"
  },
  "SDJL": {
      "icao": "SDJL",
      "iata": "JLS",
      "name": "Jales Airport",
      "city": "Jales",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1496,
      "lat": -20.2929992676,
      "lon": -50.5463981628,
      "tz": "America\/Sao_Paulo"
  },
  "SDJN": {
      "icao": "SDJN",
      "iata": "",
      "name": "Fazenda Sao Luiz Airport",
      "city": "Jardinopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1772,
      "lat": -21.1005554199,
      "lon": -47.7505569458,
      "tz": "America\/Sao_Paulo"
  },
  "SDJO": {
      "icao": "SDJO",
      "iata": "0",
      "name": "Sao Joaquim da Barra Airport",
      "city": "Sao Joaquim Da Barra",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2136,
      "lat": -20.5932998657,
      "lon": -47.8422012329,
      "tz": "America\/Sao_Paulo"
  },
  "SDJQ": {
      "icao": "SDJQ",
      "iata": "",
      "name": "Fazenda Sao Joaquim O.B. Airport",
      "city": "Pereira Barreto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1276,
      "lat": -20.8283996582,
      "lon": -51.0079994202,
      "tz": "America\/Sao_Paulo"
  },
  "SDJR": {
      "icao": "SDJR",
      "iata": "",
      "name": "Fazenda Santa Ines Airport",
      "city": "Sao Jose Do Rio Preto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1804,
      "lat": -20.8824996948,
      "lon": -49.3925018311,
      "tz": "America\/Sao_Paulo"
  },
  "SDJV": {
      "icao": "SDJV",
      "iata": "",
      "name": "Sao Joao da Boa Vista Airport",
      "city": "Sao Joao Da Boa Vista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2500,
      "lat": -22.0172214508,
      "lon": -46.8408317566,
      "tz": "America\/Sao_Paulo"
  },
  "SDJZ": {
      "icao": "SDJZ",
      "iata": "0",
      "name": "Fazenda Barra do Agudo Airport",
      "city": "Morro Agudo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1608,
      "lat": -20.8083000183,
      "lon": -48.2219009399,
      "tz": "America\/Sao_Paulo"
  },
  "SDKB": {
      "icao": "SDKB",
      "iata": "",
      "name": "Casa Branca Airport",
      "city": "Casa Branca",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2346,
      "lat": -21.7880554199,
      "lon": -47.055557251,
      "tz": "America\/Sao_Paulo"
  },
  "SDKC": {
      "icao": "SDKC",
      "iata": "",
      "name": "Fazenda Eldorado Airport",
      "city": "Galia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1771,
      "lat": -22.3036117554,
      "lon": -49.462223053,
      "tz": "America\/Sao_Paulo"
  },
  "SDKE": {
      "icao": "SDKE",
      "iata": "",
      "name": "Coribe Airport",
      "city": "Coribe",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2198,
      "lat": -13.7772216797,
      "lon": -44.4644432068,
      "tz": "America\/Bahia"
  },
  "SDKF": {
      "icao": "SDKF",
      "iata": "",
      "name": "Juazeiro Airport",
      "city": "Curaca",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1220,
      "lat": -9.1555404663,
      "lon": -40.0914001465,
      "tz": "America\/Bahia"
  },
  "SDKG": {
      "icao": "SDKG",
      "iata": "",
      "name": "Fazenda Gavea Airport",
      "city": "Cerqueira Cesar",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2031,
      "lat": -23.2250003815,
      "lon": -49.1791687012,
      "tz": "America\/Sao_Paulo"
  },
  "SDKJ": {
      "icao": "SDKJ",
      "iata": "",
      "name": "Formosa do Rio Preto Airport",
      "city": "Formosa Do Rio Preto",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1598,
      "lat": -11.0215997696,
      "lon": -45.1866989136,
      "tz": "America\/Bahia"
  },
  "SDKK": {
      "icao": "SDKK",
      "iata": "QOA",
      "name": "Mococa Airport",
      "city": "Mococa",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2116,
      "lat": -21.4878005981,
      "lon": -47.0344009399,
      "tz": "America\/Sao_Paulo"
  },
  "SDKN": {
      "icao": "SDKN",
      "iata": "",
      "name": "Fazenda e Haras Bela Vista Airport",
      "city": "Mineiros Do Tiete",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2339,
      "lat": -22.4380569458,
      "lon": -48.4055557251,
      "tz": "America\/Sao_Paulo"
  },
  "SDKO": {
      "icao": "SDKO",
      "iata": "",
      "name": "Fazenda Campo Grande Airport",
      "city": "Colombia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1830,
      "lat": -20.2576999664,
      "lon": -48.7886009216,
      "tz": "America\/Sao_Paulo"
  },
  "SDKP": {
      "icao": "SDKP",
      "iata": "",
      "name": "Fazenda Jamaica Airport",
      "city": "Pompeia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1611,
      "lat": -22.1897220612,
      "lon": -50.2788887024,
      "tz": "America\/Sao_Paulo"
  },
  "SDKQ": {
      "icao": "SDKQ",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Guaraci",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1673,
      "lat": -20.2166671753,
      "lon": -49.0666656494,
      "tz": "America\/Sao_Paulo"
  },
  "SDKR": {
      "icao": "SDKR",
      "iata": "",
      "name": "Fazenda Triangulo Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1116,
      "lat": -15.1750001907,
      "lon": -59.1711120605,
      "tz": "America\/Cuiaba"
  },
  "SDKW": {
      "icao": "SDKW",
      "iata": "",
      "name": "FazendaSodema Airport",
      "city": "Vera",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1181,
      "lat": -12.295278,
      "lon": -55.536944,
      "tz": "America\/Cuiaba"
  },
  "SDLC": {
      "icao": "SDLC",
      "iata": "0",
      "name": "Lucelia Airport",
      "city": "Lucelia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1476,
      "lat": -21.7493991852,
      "lon": -51.0182991028,
      "tz": "America\/Sao_Paulo"
  },
  "SDLE": {
      "icao": "SDLE",
      "iata": "",
      "name": "Rio de Contas Airport",
      "city": "Rio De Contas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3576,
      "lat": -13.5874996185,
      "lon": -41.7891654968,
      "tz": "America\/Bahia"
  },
  "SDLG": {
      "icao": "SDLG",
      "iata": "",
      "name": "Salviano Inacio Rocha Airport",
      "city": "Ourolandia",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1890,
      "lat": -10.9744443893,
      "lon": -41.069442749,
      "tz": "America\/Bahia"
  },
  "SDLH": {
      "icao": "SDLH",
      "iata": "",
      "name": "Caldas do Jorro Airport",
      "city": "Tucano",
      "state": "Bahia",
      "country": "BR",
      "elevation": 696,
      "lat": -11.0441665649,
      "lon": -38.7841682434,
      "tz": "America\/Bahia"
  },
  "SDLI": {
      "icao": "SDLI",
      "iata": "",
      "name": "Abare Airport",
      "city": "Abare",
      "state": "Bahia",
      "country": "BR",
      "elevation": 955,
      "lat": -8.736989975,
      "lon": -39.115398407,
      "tz": "America\/Bahia"
  },
  "SDLJ": {
      "icao": "SDLJ",
      "iata": "",
      "name": "Igarape Bahia Airport",
      "city": "Parauapebas",
      "state": "Para",
      "country": "BR",
      "elevation": 2257,
      "lat": -6.0477800369,
      "lon": -50.57970047,
      "tz": "America\/Belem"
  },
  "SDLK": {
      "icao": "SDLK",
      "iata": "",
      "name": "Cacule Airport",
      "city": "Cacule",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2064,
      "lat": -14.4812002182,
      "lon": -42.2647018433,
      "tz": "America\/Bahia"
  },
  "SDLL": {
      "icao": "SDLL",
      "iata": "0",
      "name": "Leme Airport",
      "city": "Leme",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2024,
      "lat": -22.2269001007,
      "lon": -47.3821983337,
      "tz": "America\/Sao_Paulo"
  },
  "SDLN": {
      "icao": "SDLN",
      "iata": "",
      "name": "Fazenda Tosana Airport",
      "city": "Cabo Frio",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 26,
      "lat": -22.5997219086,
      "lon": -42.0288887024,
      "tz": "America\/Sao_Paulo"
  },
  "SDLO": {
      "icao": "SDLO",
      "iata": "",
      "name": "Fazenda Pontal Airport",
      "city": "Cairu",
      "state": "Bahia",
      "country": "BR",
      "elevation": 12,
      "lat": -13.5648002625,
      "lon": -38.9399986267,
      "tz": "America\/Bahia"
  },
  "SDLP": {
      "icao": "SDLP",
      "iata": "QGC",
      "name": "Lencois Paulista Airport",
      "city": "Lencois Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2039,
      "lat": -22.5783996582,
      "lon": -48.7746009827,
      "tz": "America\/Sao_Paulo"
  },
  "SDLQ": {
      "icao": "SDLQ",
      "iata": "",
      "name": "Fazenda Perobal Airport",
      "city": "Guararapes",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1339,
      "lat": -21.503610611,
      "lon": -50.7780570984,
      "tz": "America\/Sao_Paulo"
  },
  "SDLR": {
      "icao": "SDLR",
      "iata": "",
      "name": "Fazenda Sonho Dourado Airport",
      "city": "Valentim Gentil",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1722,
      "lat": -20.4672222137,
      "lon": -50.1422233582,
      "tz": "America\/Sao_Paulo"
  },
  "SDLU": {
      "icao": "SDLU",
      "iata": "",
      "name": "Fazenda Santa Luiza Airport",
      "city": "Barra Bonita",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1782,
      "lat": -22.4831008911,
      "lon": -48.5245018005,
      "tz": "America\/Sao_Paulo"
  },
  "SDLV": {
      "icao": "SDLV",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Florestopolis",
      "state": "Parana",
      "country": "BR",
      "elevation": 1581,
      "lat": -22.9208335876,
      "lon": -51.3519439697,
      "tz": "America\/Sao_Paulo"
  },
  "SDLW": {
      "icao": "SDLW",
      "iata": "",
      "name": "Fazenda Campo Real Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1804,
      "lat": -13.4469442368,
      "lon": -57.7811126709,
      "tz": "America\/Cuiaba"
  },
  "SDLX": {
      "icao": "SDLX",
      "iata": "",
      "name": "Fazenda Jacareuna Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1417,
      "lat": -11.441110611,
      "lon": -52.2047233582,
      "tz": "America\/Cuiaba"
  },
  "SDLY": {
      "icao": "SDLY",
      "iata": "0",
      "name": "Matao Airport",
      "city": "Matao",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2083,
      "lat": -21.6231002808,
      "lon": -48.3525009155,
      "tz": "America\/Sao_Paulo"
  },
  "SDMA": {
      "icao": "SDMA",
      "iata": "",
      "name": "Fazenda Maristela Airport",
      "city": "Tremembe",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1804,
      "lat": -22.9541664124,
      "lon": -45.6016654968,
      "tz": "America\/Sao_Paulo"
  },
  "SDMC": {
      "icao": "SDMC",
      "iata": "",
      "name": "Marica Airport",
      "city": "Marica",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 13,
      "lat": -22.919500351,
      "lon": -42.8308982849,
      "tz": "America\/Sao_Paulo"
  },
  "SDMD": {
      "icao": "SDMD",
      "iata": "",
      "name": "Fazenda Santa Izabel Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1033,
      "lat": -18,
      "lon": -54.6958333333,
      "tz": "America\/Campo_Grande"
  },
  "SDME": {
      "icao": "SDME",
      "iata": "",
      "name": "Marchesan S.A. Airport",
      "city": "Matao",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1942,
      "lat": -21.6305999756,
      "lon": -48.3928985596,
      "tz": "America\/Sao_Paulo"
  },
  "SDMH": {
      "icao": "SDMH",
      "iata": "0",
      "name": "Mirassol Airport",
      "city": "Mirassol",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1916,
      "lat": -20.8071994781,
      "lon": -49.4860992432,
      "tz": "America\/Sao_Paulo"
  },
  "SDMJ": {
      "icao": "SDMJ",
      "iata": "0",
      "name": "Mogi Mirim Airport",
      "city": "Mogi Mirim",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2274,
      "lat": -22.4099998474,
      "lon": -46.9053001404,
      "tz": "America\/Sao_Paulo"
  },
  "SDMO": {
      "icao": "SDMO",
      "iata": "",
      "name": "Monte Alto Airport",
      "city": "Monte Alto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2461,
      "lat": -21.2589812941,
      "lon": -48.523607254,
      "tz": "America\/Sao_Paulo"
  },
  "SDMQ": {
      "icao": "SDMQ",
      "iata": "",
      "name": "Fazenda Quebracho Brasil Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 299,
      "lat": -21.8480555556,
      "lon": -57.8991666667,
      "tz": "America\/Asuncion"
  },
  "SDMR": {
      "icao": "SDMR",
      "iata": "",
      "name": "Marambaia Airport",
      "city": "Rio De Janeiro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 16,
      "lat": -23.0663890839,
      "lon": -43.8797225952,
      "tz": "America\/Sao_Paulo"
  },
  "SDMV": {
      "icao": "SDMV",
      "iata": "",
      "name": "Fazenda Morro Vermelho Airport",
      "city": "Jau",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1736,
      "lat": -22.2772006989,
      "lon": -48.6044998169,
      "tz": "America\/Sao_Paulo"
  },
  "SDMW": {
      "icao": "SDMW",
      "iata": "",
      "name": "Fazenda Karaja Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 833,
      "lat": -14.1125,
      "lon": -50.6808333333,
      "tz": "America\/Sao_Paulo"
  },
  "SDMX": {
      "icao": "SDMX",
      "iata": "",
      "name": "Fazenda Fontana Airport",
      "city": "Campo Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2267,
      "lat": -15.5,
      "lon": -55.3791666667,
      "tz": "America\/Cuiaba"
  },
  "SDMY": {
      "icao": "SDMY",
      "iata": "",
      "name": "Fazenda Cambuhy Airport",
      "city": "Matao",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2008,
      "lat": -21.632900238,
      "lon": -48.4790992737,
      "tz": "America\/Sao_Paulo"
  },
  "SDNA": {
      "icao": "SDNA",
      "iata": "",
      "name": "Fazenda Vale Verde Airport",
      "city": "Comendador Gomes",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2336,
      "lat": -19.671944,
      "lon": -48.994722,
      "tz": "America\/Sao_Paulo"
  },
  "SDND": {
      "icao": "SDND",
      "iata": "",
      "name": "Fazenda Nova Damasco Airport",
      "city": "Narandiba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1411,
      "lat": -22.4827785492,
      "lon": -51.5302772522,
      "tz": "America\/Sao_Paulo"
  },
  "SDNE": {
      "icao": "SDNE",
      "iata": "",
      "name": "Fazenda Sao Joaquim Airport",
      "city": "Maraba Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1585,
      "lat": -22.1994438171,
      "lon": -51.9736099243,
      "tz": "America\/Sao_Paulo"
  },
  "SDNG": {
      "icao": "SDNG",
      "iata": "",
      "name": "Fazenda Sao Gabriel Airport",
      "city": "Nova Maringa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1197,
      "lat": -13.1000003815,
      "lon": -56.9527778625,
      "tz": "America\/Cuiaba"
  },
  "SDNH": {
      "icao": "SDNH",
      "iata": "0",
      "name": "Novo Horizonte Airport",
      "city": "Novo Horizonte",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1526,
      "lat": -21.497800827,
      "lon": -49.2344017029,
      "tz": "America\/Sao_Paulo"
  },
  "SDNI": {
      "icao": "SDNI",
      "iata": "",
      "name": "Nascimento I Airport",
      "city": "Vargem Grande Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 3090,
      "lat": -23.6197223663,
      "lon": -46.9836120605,
      "tz": "America\/Sao_Paulo"
  },
  "SDNJ": {
      "icao": "SDNJ",
      "iata": "",
      "name": "Nascimento II Airport",
      "city": "Capao Bonito",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2604,
      "lat": -24.07472229,
      "lon": -48.2247238159,
      "tz": "America\/Sao_Paulo"
  },
  "SDNK": {
      "icao": "SDNK",
      "iata": "",
      "name": "Fazenda Vitoria Airport",
      "city": "Primavera Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2513,
      "lat": -14.9650001526,
      "lon": -54.0508346558,
      "tz": "America\/Cuiaba"
  },
  "SDNL": {
      "icao": "SDNL",
      "iata": "0",
      "name": "Fazenda Sao Francisco do Itaquere Airport",
      "city": "Nova Europa",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1877,
      "lat": -21.7597007751,
      "lon": -48.586101532,
      "tz": "America\/Sao_Paulo"
  },
  "SDNO": {
      "icao": "SDNO",
      "iata": "",
      "name": "Sao Manuel Airport",
      "city": "Sao Manuel",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2395,
      "lat": -22.6958007813,
      "lon": -48.5765991211,
      "tz": "America\/Sao_Paulo"
  },
  "SDNV": {
      "icao": "SDNV",
      "iata": "",
      "name": "Aldeia Nacepoti Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 1001,
      "lat": -9.503889,
      "lon": -54.011944,
      "tz": "America\/Santarem"
  },
  "SDNW": {
      "icao": "SDNW",
      "iata": "",
      "name": "Aldeia Piaracu Airport",
      "city": "Sao Jose Do Xingu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1148,
      "lat": -10.788333,
      "lon": -53.071667,
      "tz": "America\/Cuiaba"
  },
  "SDNY": {
      "icao": "SDNY",
      "iata": "QNV",
      "name": "Aeroclube Airport",
      "city": "Nova Iguacu",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 164,
      "lat": -22.745300293,
      "lon": -43.4603004456,
      "tz": "America\/Sao_Paulo"
  },
  "SDNZ": {
      "icao": "SDNZ",
      "iata": "",
      "name": "Fazenda Alto do Piriqui Airport",
      "city": "Sonora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1640,
      "lat": -17.8449993134,
      "lon": -54.7688903809,
      "tz": "America\/Campo_Grande"
  },
  "SDOA": {
      "icao": "SDOA",
      "iata": "",
      "name": "Lagoa da Floresta Airport",
      "city": "Barra Do Corda",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 591,
      "lat": -5.4886111111,
      "lon": -45.4886111111,
      "tz": "America\/Fortaleza"
  },
  "SDOB": {
      "icao": "SDOB",
      "iata": "",
      "name": "Fazenda Sao Jose OB Airport",
      "city": "Tapiratiba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2585,
      "lat": -21.4251995087,
      "lon": -46.7542991638,
      "tz": "America\/Sao_Paulo"
  },
  "SDOD": {
      "icao": "SDOD",
      "iata": "",
      "name": "Fazenda Vida Airport",
      "city": "Barra Do Corda",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 820,
      "lat": -5.84,
      "lon": -45.5419444444,
      "tz": "America\/Fortaleza"
  },
  "SDOE": {
      "icao": "SDOE",
      "iata": "",
      "name": "Fazenda Siberia Airport",
      "city": "Grajau",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 741,
      "lat": -5.7222222222,
      "lon": -45.7913888889,
      "tz": "America\/Fortaleza"
  },
  "SDOI": {
      "icao": "SDOI",
      "iata": "",
      "name": "Centro Nacional de Para-quedismo Airport",
      "city": "Boituva",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2051,
      "lat": -23.2980556488,
      "lon": -47.6919441223,
      "tz": "America\/Sao_Paulo"
  },
  "SDOL": {
      "icao": "SDOL",
      "iata": "",
      "name": "Agropesp Airport",
      "city": "Tapura",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -12.441389,
      "lon": -56.406944,
      "tz": "America\/Cuiaba"
  },
  "SDOR": {
      "icao": "SDOR",
      "iata": "",
      "name": "Fazenda Mosquito Airport",
      "city": "Orlandia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2415,
      "lat": -20.7472229004,
      "lon": -47.8897209167,
      "tz": "America\/Sao_Paulo"
  },
  "SDOU": {
      "icao": "SDOU",
      "iata": "OUS",
      "name": "Ourinhos Airport",
      "city": "Ourinhos",
      "state": "",
      "country": "BR",
      "elevation": 1532,
      "lat": -22.9664993286,
      "lon": -49.9132995605,
      "tz": "America\/Sao_Paulo"
  },
  "SDOV": {
      "icao": "SDOV",
      "iata": "",
      "name": "Mozarlandia Airport",
      "city": "Mozarlandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1076,
      "lat": -14.7704000473,
      "lon": -50.5640983582,
      "tz": "America\/Sao_Paulo"
  },
  "SDOW": {
      "icao": "SDOW",
      "iata": "OIA",
      "name": "Ourilandia do Norte Airport",
      "city": "Ourilandia Do Norte",
      "state": "Para",
      "country": "BR",
      "elevation": 751,
      "lat": -6.7631001472,
      "lon": -51.0499000549,
      "tz": "America\/Belem"
  },
  "SDOX": {
      "icao": "SDOX",
      "iata": "",
      "name": "Fazenda Pixoxo Airport",
      "city": "Sao Carlos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2133,
      "lat": -21.7811107635,
      "lon": -47.8844451904,
      "tz": "America\/Sao_Paulo"
  },
  "SDOZ": {
      "icao": "SDOZ",
      "iata": "",
      "name": "Aldeia Pontal Airport",
      "city": "Apiacas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 869,
      "lat": -8.100833,
      "lon": -58.284444,
      "tz": "America\/Cuiaba"
  },
  "SDPA": {
      "icao": "SDPA",
      "iata": "",
      "name": "Fazenda Portobello Airport",
      "city": "Mangaratiba",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 20,
      "lat": -22.9274997711,
      "lon": -44.0800018311,
      "tz": "America\/Sao_Paulo"
  },
  "SDPC": {
      "icao": "SDPC",
      "iata": "",
      "name": "Atena Airport",
      "city": "Rancharia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1585,
      "lat": -22.15278,
      "lon": -51.02861,
      "tz": "America\/Sao_Paulo"
  },
  "SDPD": {
      "icao": "SDPD",
      "iata": "",
      "name": "Pindamonhangaba Airport",
      "city": "Pindamonhangaba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1919,
      "lat": -22.943611145,
      "lon": -45.431388855,
      "tz": "America\/Sao_Paulo"
  },
  "SDPG": {
      "icao": "SDPG",
      "iata": "",
      "name": "Fazenda Progresso Airport",
      "city": "Andradina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1198,
      "lat": -21.0544433594,
      "lon": -51.4061126709,
      "tz": "America\/Sao_Paulo"
  },
  "SDPN": {
      "icao": "SDPN",
      "iata": "",
      "name": "Penapolis Airport",
      "city": "Penapolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1371,
      "lat": -21.4099998474,
      "lon": -50.0335998535,
      "tz": "America\/Sao_Paulo"
  },
  "SDPP": {
      "icao": "SDPP",
      "iata": "",
      "name": "Fazenda Fortaleza Airport",
      "city": "Paranapanema",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1952,
      "lat": -23.2929992676,
      "lon": -48.8143997192,
      "tz": "America\/Sao_Paulo"
  },
  "SDPV": {
      "icao": "SDPV",
      "iata": "0",
      "name": "Presidente Venceslau Airport",
      "city": "Presidente Venceslau",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1460,
      "lat": -21.8932991028,
      "lon": -51.8843994141,
      "tz": "America\/Sao_Paulo"
  },
  "SDPW": {
      "icao": "SDPW",
      "iata": "QHB",
      "name": "Piracicaba Airport",
      "city": "Piracicaba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1887,
      "lat": -22.7115001678,
      "lon": -47.6181983948,
      "tz": "America\/Sao_Paulo"
  },
  "SDPY": {
      "icao": "SDPY",
      "iata": "",
      "name": "Pirassununga Airport",
      "city": "Pirassununga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2241,
      "lat": -22.0263881683,
      "lon": -47.4194450378,
      "tz": "America\/Sao_Paulo"
  },
  "SDQE": {
      "icao": "SDQE",
      "iata": "",
      "name": "Fazenda Carambola Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2041,
      "lat": -22.5694446564,
      "lon": -55.6361122131,
      "tz": "America\/Campo_Grande"
  },
  "SDQG": {
      "icao": "SDQG",
      "iata": "",
      "name": "Fazenda Nova Floresta Airport",
      "city": "Caiabu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1460,
      "lat": -21.8988895416,
      "lon": -51.1863899231,
      "tz": "America\/Sao_Paulo"
  },
  "SDQH": {
      "icao": "SDQH",
      "iata": "",
      "name": "Fazenda da Ilha Airport",
      "city": "Tibagi",
      "state": "Parana",
      "country": "BR",
      "elevation": 2812,
      "lat": -24.6499996185,
      "lon": -50.3624992371,
      "tz": "America\/Sao_Paulo"
  },
  "SDQL": {
      "icao": "SDQL",
      "iata": "",
      "name": "Fazenda Cerro Azul Airport",
      "city": "Nioaque",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1191,
      "lat": -21.18861,
      "lon": -55.7344,
      "tz": "America\/Campo_Grande"
  },
  "SDQN": {
      "icao": "SDQN",
      "iata": "",
      "name": "Fazenda Tangara Airport",
      "city": "Nioaque",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 919,
      "lat": -20.8986110687,
      "lon": -55.7099990845,
      "tz": "America\/Campo_Grande"
  },
  "SDQQ": {
      "icao": "SDQQ",
      "iata": "",
      "name": "Companhia Agricola de Quata Airport",
      "city": "Quata",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1919,
      "lat": -22.2861003876,
      "lon": -50.6389007568,
      "tz": "America\/Sao_Paulo"
  },
  "SDQV": {
      "icao": "SDQV",
      "iata": "",
      "name": "Fazenda Santa Fe Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 676,
      "lat": -16.1399993896,
      "lon": -58.5149993896,
      "tz": "America\/Cuiaba"
  },
  "SDQW": {
      "icao": "SDQW",
      "iata": "",
      "name": "Fazenda Criciuma Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1575,
      "lat": -13.41,
      "lon": -55.233611,
      "tz": "America\/Cuiaba"
  },
  "SDQX": {
      "icao": "SDQX",
      "iata": "",
      "name": "Fazenda Esperanca Airport",
      "city": "Itapeva",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2267,
      "lat": -23.867778,
      "lon": -48.805278,
      "tz": "America\/Sao_Paulo"
  },
  "SDQZ": {
      "icao": "SDQZ",
      "iata": "",
      "name": "Fazenda Espinhaco II Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 991,
      "lat": -15.3972215652,
      "lon": -52.07472229,
      "tz": "America\/Cuiaba"
  },
  "SDRA": {
      "icao": "SDRA",
      "iata": "0",
      "name": "Fazenda das Represas Airport",
      "city": "Sao Miguel Arcanjo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2271,
      "lat": -23.8756008148,
      "lon": -48.0517997742,
      "tz": "America\/Sao_Paulo"
  },
  "SDRC": {
      "icao": "SDRC",
      "iata": "",
      "name": "Fazenda Santana Airport",
      "city": "Rancharia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1608,
      "lat": -22.1819438934,
      "lon": -50.8658332825,
      "tz": "America\/Sao_Paulo"
  },
  "SDRD": {
      "icao": "SDRD",
      "iata": "",
      "name": "Adhemar Ribeiro Airport",
      "city": "Santa Rita Do Passa Quatro",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2776,
      "lat": -21.6424999237,
      "lon": -47.4711112976,
      "tz": "America\/Sao_Paulo"
  },
  "SDRF": {
      "icao": "SDRF",
      "iata": "",
      "name": "Fazenda Recanto Feliz Airport",
      "city": "Rio Claro",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 1750,
      "lat": -22.7461109161,
      "lon": -44.0508346558,
      "tz": "America\/Sao_Paulo"
  },
  "SDRH": {
      "icao": "SDRH",
      "iata": "",
      "name": "Fazenda Chaparral Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1509,
      "lat": -20.8444442749,
      "lon": -54.5261116028,
      "tz": "America\/Campo_Grande"
  },
  "SDRK": {
      "icao": "SDRK",
      "iata": "QIQ",
      "name": "Rio Claro Airport",
      "city": "Rio Claro",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1991,
      "lat": -22.4311008453,
      "lon": -47.564201355,
      "tz": "America\/Sao_Paulo"
  },
  "SDRO": {
      "icao": "SDRO",
      "iata": "",
      "name": "Agropastoril bom Pastor Airport",
      "city": "Rondonopolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1034,
      "lat": -16.465555191,
      "lon": -54.7119445801,
      "tz": "America\/Cuiaba"
  },
  "SDRR": {
      "icao": "SDRR",
      "iata": "QVP",
      "name": "Avare-Arandu Airport",
      "city": "Avare",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2657,
      "lat": -23.0925006866,
      "lon": -48.9874000549,
      "tz": "America\/Sao_Paulo"
  },
  "SDRS": {
      "icao": "SDRS",
      "iata": "QRZ",
      "name": "Resende Airport",
      "city": "Resende",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 1320,
      "lat": -22.4785003662,
      "lon": -44.4803009033,
      "tz": "America\/Sao_Paulo"
  },
  "SDRU": {
      "icao": "SDRU",
      "iata": "",
      "name": "Fazenda Caramuru Airport",
      "city": "Inubia Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1285,
      "lat": -21.6938896179,
      "lon": -50.9361114502,
      "tz": "America\/Sao_Paulo"
  },
  "SDRW": {
      "icao": "SDRW",
      "iata": "",
      "name": "Fazenda Pirapitinga Airport",
      "city": "Canapolis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2172,
      "lat": -18.7775,
      "lon": -49.231667,
      "tz": "America\/Sao_Paulo"
  },
  "SDRX": {
      "icao": "SDRX",
      "iata": "",
      "name": "Fazenda Palmeiras Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 499,
      "lat": -17.9669437408,
      "lon": -57.0330543518,
      "tz": "America\/Campo_Grande"
  },
  "SDRY": {
      "icao": "SDRY",
      "iata": "",
      "name": "Fazenda Sao Paulo Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1493,
      "lat": -22.9527778625,
      "lon": -54.8452796936,
      "tz": "America\/Campo_Grande"
  },
  "SDRZ": {
      "icao": "SDRZ",
      "iata": "",
      "name": "Fazenda Maraba Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 499,
      "lat": -21.6872215271,
      "lon": -57.3580551147,
      "tz": "America\/Campo_Grande"
  },
  "SDSB": {
      "icao": "SDSB",
      "iata": "",
      "name": "Fazenda Sao Sebastiao Airport",
      "city": "Rifaina",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1705,
      "lat": -19.9958324432,
      "lon": -47.5238876343,
      "tz": "America\/Sao_Paulo"
  },
  "SDSC": {
      "icao": "SDSC",
      "iata": "QSC",
      "name": "Sao Carlos Airport",
      "city": "Sao Carlos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2649,
      "lat": -21.8754005432,
      "lon": -47.9037017822,
      "tz": "America\/Sao_Paulo"
  },
  "SDSE": {
      "icao": "SDSE",
      "iata": "",
      "name": "Sitio Santa Helena Airport",
      "city": "Gabriel Monteiro",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1414,
      "lat": -21.4733333588,
      "lon": -50.6155548096,
      "tz": "America\/Sao_Paulo"
  },
  "SDSH": {
      "icao": "SDSH",
      "iata": "",
      "name": "Fazenda Sangrila Airport",
      "city": "Bauru",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1755,
      "lat": -22.2955551147,
      "lon": -49.212223053,
      "tz": "America\/Sao_Paulo"
  },
  "SDSJ": {
      "icao": "SDSJ",
      "iata": "0",
      "name": "Fazenda Sao Joao Airport",
      "city": "Orlandia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2172,
      "lat": -20.7455997467,
      "lon": -47.9336013794,
      "tz": "America\/Sao_Paulo"
  },
  "SDSK": {
      "icao": "SDSK",
      "iata": "",
      "name": "Saquarema Airport",
      "city": "Saquarema",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 26,
      "lat": -22.9297218323,
      "lon": -42.506942749,
      "tz": "America\/Sao_Paulo"
  },
  "SDSM": {
      "icao": "SDSM",
      "iata": "",
      "name": "Fazenda Nossa Senhora da Conceicao Airport",
      "city": "Sao Manuel",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2482,
      "lat": -22.7647228241,
      "lon": -48.5424995422,
      "tz": "America\/Sao_Paulo"
  },
  "SDSO": {
      "icao": "SDSO",
      "iata": "",
      "name": "Fazenda Sao Geraldo Airport",
      "city": "Piquerobi",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1476,
      "lat": -22.0038890839,
      "lon": -51.8111114502,
      "tz": "America\/Sao_Paulo"
  },
  "SDSQ": {
      "icao": "SDSQ",
      "iata": "",
      "name": "Usina Santa Rita Airport",
      "city": "Santa Rita Do Passa Quatro",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2067,
      "lat": -21.7236118317,
      "lon": -47.6569442749,
      "tz": "America\/Sao_Paulo"
  },
  "SDST": {
      "icao": "SDST",
      "iata": "",
      "name": "Fazenda Santa Terezinha da Barra Airport",
      "city": "Sao Carlos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2385,
      "lat": -21.8819446564,
      "lon": -47.7761116028,
      "tz": "America\/Sao_Paulo"
  },
  "SDSV": {
      "icao": "SDSV",
      "iata": "",
      "name": "Fazenda Santa Candida Airport",
      "city": "Guariba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2080,
      "lat": -21.3277778625,
      "lon": -48.1711120605,
      "tz": "America\/Sao_Paulo"
  },
  "SDTB": {
      "icao": "SDTB",
      "iata": "",
      "name": "Atibaia Airport",
      "city": "Atibaia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2592,
      "lat": -23.1277770996,
      "lon": -46.57472229,
      "tz": "America\/Sao_Paulo"
  },
  "SDTE": {
      "icao": "SDTE",
      "iata": "",
      "name": "Fazenda Tapijara Airport",
      "city": "Arandu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1929,
      "lat": -23.2199993134,
      "lon": -49.0759010315,
      "tz": "America\/Sao_Paulo"
  },
  "SDTF": {
      "icao": "SDTF",
      "iata": "",
      "name": "Tatui Airport",
      "city": "Tatui",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2083,
      "lat": -23.3318996429,
      "lon": -47.8782997131,
      "tz": "America\/Sao_Paulo"
  },
  "SDTI": {
      "icao": "SDTI",
      "iata": "",
      "name": "Tupi Paulista Airport",
      "city": "Tupi Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1198,
      "lat": -21.3930568695,
      "lon": -51.5988883972,
      "tz": "America\/Sao_Paulo"
  },
  "SDTK": {
      "icao": "SDTK",
      "iata": "",
      "name": "Parati Airport",
      "city": "Parati",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 10,
      "lat": -23.2243995667,
      "lon": -44.7202987671,
      "tz": "America\/Sao_Paulo"
  },
  "SDTN": {
      "icao": "SDTN",
      "iata": "",
      "name": "Usina Acucareira Santo Antonio Airport",
      "city": "Sertaozinho",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1969,
      "lat": -21.1219444275,
      "lon": -47.9522209167,
      "tz": "America\/Sao_Paulo"
  },
  "SDTO": {
      "icao": "SDTO",
      "iata": "0",
      "name": "Fazenda Cataco Airport",
      "city": "Ubarana",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1447,
      "lat": -21.2635993958,
      "lon": -49.7930984497,
      "tz": "America\/Sao_Paulo"
  },
  "SDTP": {
      "icao": "SDTP",
      "iata": "",
      "name": "Tupa Airport",
      "city": "Tupa",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1805,
      "lat": -21.8890991211,
      "lon": -50.5051002502,
      "tz": "America\/Sao_Paulo"
  },
  "SDTQ": {
      "icao": "SDTQ",
      "iata": "",
      "name": "Fazenda Santa Thereza Airport",
      "city": "Aparecida Do Rio Doce",
      "state": "Goias",
      "country": "BR",
      "elevation": 2149,
      "lat": -18.2327785492,
      "lon": -51.2022209167,
      "tz": "America\/Sao_Paulo"
  },
  "SDTR": {
      "icao": "SDTR",
      "iata": "",
      "name": "Fazenda Bandeirantes Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 499,
      "lat": -20.8833332062,
      "lon": -57.4177780151,
      "tz": "America\/Campo_Grande"
  },
  "SDTS": {
      "icao": "SDTS",
      "iata": "",
      "name": "Fazenda Planalto Airport",
      "city": "Jaraguari",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2329,
      "lat": -20.3413887024,
      "lon": -54.4158325195,
      "tz": "America\/Campo_Grande"
  },
  "SDTT": {
      "icao": "SDTT",
      "iata": "",
      "name": "Fazenda Barra do Traitu Airport",
      "city": "Aracatuba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1345,
      "lat": -21.2469444275,
      "lon": -50.4902763367,
      "tz": "America\/Sao_Paulo"
  },
  "SDTW": {
      "icao": "SDTW",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Terenos",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 919,
      "lat": -20.3261108398,
      "lon": -55.1005554199,
      "tz": "America\/Campo_Grande"
  },
  "SDTX": {
      "icao": "SDTX",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Ilha Solteira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1236,
      "lat": -20.5419445038,
      "lon": -51.3574981689,
      "tz": "America\/Sao_Paulo"
  },
  "SDTY": {
      "icao": "SDTY",
      "iata": "",
      "name": "Usina Sao Martinho Airport",
      "city": "Pradopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1713,
      "lat": -21.339799881,
      "lon": -48.1148986816,
      "tz": "America\/Sao_Paulo"
  },
  "SDTZ": {
      "icao": "SDTZ",
      "iata": "",
      "name": "Fazenda Santa Julia Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 492,
      "lat": -20.1191673279,
      "lon": -57.1119422913,
      "tz": "America\/Campo_Grande"
  },
  "SDUA": {
      "icao": "SDUA",
      "iata": "",
      "name": "Agrorural Sol Nascente Airport",
      "city": "Aveiro",
      "state": "Para",
      "country": "BR",
      "elevation": 108,
      "lat": -3.5086109638,
      "lon": -56.3158340454,
      "tz": "America\/Santarem"
  },
  "SDUB": {
      "icao": "SDUB",
      "iata": "UBT",
      "name": "Ubatuba Airport",
      "city": "Ubatuba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 13,
      "lat": -23.4410991669,
      "lon": -45.0755996704,
      "tz": "America\/Sao_Paulo"
  },
  "SDUD": {
      "icao": "SDUD",
      "iata": "",
      "name": "Usina Santa Adelia Airport",
      "city": "Jaboticabal",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1991,
      "lat": -21.3323001862,
      "lon": -48.3162002563,
      "tz": "America\/Sao_Paulo"
  },
  "SDUE": {
      "icao": "SDUE",
      "iata": "",
      "name": "Fazenda Figueira Airport",
      "city": "Penapolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1526,
      "lat": -21.5119438171,
      "lon": -50.1211128235,
      "tz": "America\/Sao_Paulo"
  },
  "SDUF": {
      "icao": "SDUF",
      "iata": "",
      "name": "Fazenda Touro Peru Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 492,
      "lat": -21.080556,
      "lon": -57.497222,
      "tz": "America\/Campo_Grande"
  },
  "SDUH": {
      "icao": "SDUH",
      "iata": "",
      "name": "Fazenda Sumare Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1001,
      "lat": -21.5424995422,
      "lon": -53.3430557251,
      "tz": "America\/Campo_Grande"
  },
  "SDUK": {
      "icao": "SDUK",
      "iata": "",
      "name": "Fazenda Xaimite Airport",
      "city": "Agua Boa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1601,
      "lat": -14.0966672897,
      "lon": -52.9019432068,
      "tz": "America\/Cuiaba"
  },
  "SDUL": {
      "icao": "SDUL",
      "iata": "",
      "name": "Usina Santa Lydia Airport",
      "city": "Ribeirao Preto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2270,
      "lat": -21.2016677856,
      "lon": -47.9205551147,
      "tz": "America\/Sao_Paulo"
  },
  "SDUN": {
      "icao": "SDUN",
      "iata": "ITP",
      "name": "Itaperuna Airport",
      "city": "Itaperuna",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 410,
      "lat": -21.2192993164,
      "lon": -41.8759002686,
      "tz": "America\/Sao_Paulo"
  },
  "SDUO": {
      "icao": "SDUO",
      "iata": "QGS",
      "name": "Alagoinhas Airport",
      "city": "Alagoinhas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 568,
      "lat": -12.1751003265,
      "lon": -38.3801994324,
      "tz": "America\/Bahia"
  },
  "SDUQ": {
      "icao": "SDUQ",
      "iata": "",
      "name": "Paraguacu Paulista Airport",
      "city": "Paraguacu Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1525,
      "lat": -22.4269008636,
      "lon": -50.6068992615,
      "tz": "America\/Sao_Paulo"
  },
  "SDUR": {
      "icao": "SDUR",
      "iata": "",
      "name": "Ibera Airport",
      "city": "Ponta Grossa",
      "state": "Parana",
      "country": "BR",
      "elevation": 2937,
      "lat": -25.0519447327,
      "lon": -50.1908340454,
      "tz": "America\/Sao_Paulo"
  },
  "SDUS": {
      "icao": "SDUS",
      "iata": "",
      "name": "Mayrowy Airport",
      "city": "Apiacas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 689,
      "lat": -7.9561109543,
      "lon": -57.8388900757,
      "tz": "America\/Cuiaba"
  },
  "SDUU": {
      "icao": "SDUU",
      "iata": "",
      "name": "Usina California Airport",
      "city": "Parapua",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1476,
      "lat": -21.91111,
      "lon": -50.86833,
      "tz": "America\/Sao_Paulo"
  },
  "SDUZ": {
      "icao": "SDUZ",
      "iata": "",
      "name": "Usina Sao Luiz Airport",
      "city": "Ourinhos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1608,
      "lat": -22.9483337402,
      "lon": -49.7661094666,
      "tz": "America\/Sao_Paulo"
  },
  "SDVA": {
      "icao": "SDVA",
      "iata": "",
      "name": "Fazenda Campo Vitoria Airport",
      "city": "Vargem Grande Do Sul",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2329,
      "lat": -21.8322219849,
      "lon": -46.9555549622,
      "tz": "America\/Sao_Paulo"
  },
  "SDVE": {
      "icao": "SDVE",
      "iata": "",
      "name": "Vera Cruz Airport",
      "city": "Vera Cruz",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2100,
      "lat": -22.2301998138,
      "lon": -49.817199707,
      "tz": "America\/Sao_Paulo"
  },
  "SDVG": {
      "icao": "SDVG",
      "iata": "VOT",
      "name": "Votuporanga Airport",
      "city": "Votuporanga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1667,
      "lat": -20.4631996155,
      "lon": -50.0045013428,
      "tz": "America\/Sao_Paulo"
  },
  "SDVH": {
      "icao": "SDVH",
      "iata": "",
      "name": "Fazenda Vale Eldorado Airport",
      "city": "Atibaia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2727,
      "lat": -23.0041675568,
      "lon": -46.6347236633,
      "tz": "America\/Sao_Paulo"
  },
  "SDVI": {
      "icao": "SDVI",
      "iata": "",
      "name": "Comandante Gastao Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1391,
      "lat": -14.65,
      "lon": -57.5,
      "tz": "America\/Cuiaba"
  },
  "SDVJ": {
      "icao": "SDVJ",
      "iata": "",
      "name": "Fazenda Santa Marta Airport",
      "city": "Santa Maria Das Barreiras",
      "state": "Para",
      "country": "BR",
      "elevation": 807,
      "lat": -8.7225,
      "lon": -50.455,
      "tz": "America\/Belem"
  },
  "SDVL": {
      "icao": "SDVL",
      "iata": "",
      "name": "Valenca Airport",
      "city": "Valenca",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 1903,
      "lat": -22.2427787781,
      "lon": -43.7136116028,
      "tz": "America\/Sao_Paulo"
  },
  "SDVN": {
      "icao": "SDVN",
      "iata": "",
      "name": "Fazenda Santissima Trindade Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1299,
      "lat": -23.286944,
      "lon": -54.841111,
      "tz": "America\/Campo_Grande"
  },
  "SDVQ": {
      "icao": "SDVQ",
      "iata": "",
      "name": "Fazenda Baia das Conchas Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 351,
      "lat": -21.19,
      "lon": -57.64,
      "tz": "America\/Campo_Grande"
  },
  "SDVS": {
      "icao": "SDVS",
      "iata": "0",
      "name": "Fazenda Vassoural Airport",
      "city": "Pontal",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1801,
      "lat": -21.0727996826,
      "lon": -48.034198761,
      "tz": "America\/Sao_Paulo"
  },
  "SDVU": {
      "icao": "SDVU",
      "iata": "",
      "name": "Aldeia Kremoro Airport",
      "city": "Peixoto De Azevedo",
      "state": "Para",
      "country": "BR",
      "elevation": 3281,
      "lat": -7.2097220421,
      "lon": -52.9052772522,
      "tz": "America\/Santarem"
  },
  "SDWC": {
      "icao": "SDWC",
      "iata": "",
      "name": "Aldeia Bau Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 699,
      "lat": -7.3602781296,
      "lon": -54.8297233582,
      "tz": "America\/Santarem"
  },
  "SDWD": {
      "icao": "SDWD",
      "iata": "",
      "name": "Fazenda California Airport",
      "city": "Barretos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1856,
      "lat": -20.4308338165,
      "lon": -48.6049995422,
      "tz": "America\/Sao_Paulo"
  },
  "SDWE": {
      "icao": "SDWE",
      "iata": "",
      "name": "Usina Sao Carlos Airport",
      "city": "Jaboticabal",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1919,
      "lat": -21.2841663361,
      "lon": -48.162776947,
      "tz": "America\/Sao_Paulo"
  },
  "SDWG": {
      "icao": "SDWG",
      "iata": "",
      "name": "Fazenda Itapiranga Airport",
      "city": "Maraba Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1394,
      "lat": -22.0630569458,
      "lon": -51.9247207642,
      "tz": "America\/Sao_Paulo"
  },
  "SDWH": {
      "icao": "SDWH",
      "iata": "",
      "name": "Fazenda Avanhandava Airport",
      "city": "Jose Bonifacio",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1444,
      "lat": -21.1886005402,
      "lon": -49.9361000061,
      "tz": "America\/Sao_Paulo"
  },
  "SDWI": {
      "icao": "SDWI",
      "iata": "",
      "name": "Fazenda Lagoa do Cavalo Airport",
      "city": "Gravata",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1709,
      "lat": -8.17,
      "lon": -35.63,
      "tz": "America\/Recife"
  },
  "SDWJ": {
      "icao": "SDWJ",
      "iata": "",
      "name": "Aldeia Kubenkroke Airport",
      "city": "Altamira",
      "state": "",
      "country": "BR",
      "elevation": 1099,
      "lat": -8.7252779007,
      "lon": -53.3902778625,
      "tz": "America\/Santarem"
  },
  "SDWK": {
      "icao": "SDWK",
      "iata": "",
      "name": "Junqueira Airport",
      "city": "Igarapava",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1850,
      "lat": -20.0016670227,
      "lon": -47.7675018311,
      "tz": "America\/Sao_Paulo"
  },
  "SDWL": {
      "icao": "SDWL",
      "iata": "",
      "name": "Tres Marias Airport",
      "city": "Itupeva",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2566,
      "lat": -23.1916675568,
      "lon": -47.0750007629,
      "tz": "America\/Sao_Paulo"
  },
  "SDWM": {
      "icao": "SDWM",
      "iata": "",
      "name": "Aldeia de Metuktire Airport",
      "city": "Peixoto De Azevedo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2953,
      "lat": -10.0636110306,
      "lon": -52.9966659546,
      "tz": "America\/Cuiaba"
  },
  "SDWN": {
      "icao": "SDWN",
      "iata": "",
      "name": "Fazenda Barreiro Grande Airport",
      "city": "Colombia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1857,
      "lat": -20.3474998474,
      "lon": -48.7724990845,
      "tz": "America\/Sao_Paulo"
  },
  "SDWR": {
      "icao": "SDWR",
      "iata": "",
      "name": "Fazenda Primavera Airport",
      "city": "Mirandopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1627,
      "lat": -21.0777778625,
      "lon": -51.144443512,
      "tz": "America\/Sao_Paulo"
  },
  "SDWU": {
      "icao": "SDWU",
      "iata": "",
      "name": "Fazenda Anahi Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 279,
      "lat": -21.7083320618,
      "lon": -57.7405548096,
      "tz": "America\/Campo_Grande"
  },
  "SDWX": {
      "icao": "SDWX",
      "iata": "",
      "name": "Pista Sol Nascente Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 541,
      "lat": -5.4908328056,
      "lon": -57.2158317566,
      "tz": "America\/Santarem"
  },
  "SDXB": {
      "icao": "SDXB",
      "iata": "",
      "name": "Cristalina Airport",
      "city": "Cristalina",
      "state": "Goias",
      "country": "BR",
      "elevation": 3937,
      "lat": -16.7910003662,
      "lon": -47.645198822,
      "tz": "America\/Sao_Paulo"
  },
  "SDXE": {
      "icao": "SDXE",
      "iata": "",
      "name": "Chacara Serradinho Airport",
      "city": "Jaboticabal",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2037,
      "lat": -21.264799118,
      "lon": -48.356098175,
      "tz": "America\/Sao_Paulo"
  },
  "SDXF": {
      "icao": "SDXF",
      "iata": "",
      "name": "Alto Paraiso Airport",
      "city": "Alto Paraiso",
      "state": "Goias",
      "country": "BR",
      "elevation": 4413,
      "lat": -14.1210002899,
      "lon": -47.5308990479,
      "tz": "America\/Sao_Paulo"
  },
  "SDXI": {
      "icao": "SDXI",
      "iata": "",
      "name": "Fazenda Cajueiro Airport",
      "city": "Sao Domingos Do Capim",
      "state": "Para",
      "country": "BR",
      "elevation": 577,
      "lat": -4.2386112213,
      "lon": -48.4766654968,
      "tz": "America\/Belem"
  },
  "SDXJ": {
      "icao": "SDXJ",
      "iata": "",
      "name": "Costa Rica Airport",
      "city": "Costa Rica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2428,
      "lat": -18.48667,
      "lon": -53.15333,
      "tz": "America\/Campo_Grande"
  },
  "SDXO": {
      "icao": "SDXO",
      "iata": "",
      "name": "Fazenda Santa Maria da Mata Airport",
      "city": "Sud Menucci",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1142,
      "lat": -20.7275009155,
      "lon": -50.9716682434,
      "tz": "America\/Sao_Paulo"
  },
  "SDXQ": {
      "icao": "SDXQ",
      "iata": "",
      "name": "Fazenda Flamboyant Airport",
      "city": "Castilho",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1112,
      "lat": -20.9069442749,
      "lon": -51.5755577087,
      "tz": "America\/Sao_Paulo"
  },
  "SDXR": {
      "icao": "SDXR",
      "iata": "",
      "name": "Fazenda Vaca Mocha Airport",
      "city": "Caracol",
      "state": "Concepcion",
      "country": "BR",
      "elevation": 463,
      "lat": -22.2255554199,
      "lon": -57.1547203064,
      "tz": "America\/Asuncion"
  },
  "SDXT": {
      "icao": "SDXT",
      "iata": "",
      "name": "Fazenda Boa Vista Airport",
      "city": "Rio Negro",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1100,
      "lat": -19.4355564117,
      "lon": -55.0627784729,
      "tz": "America\/Campo_Grande"
  },
  "SDXU": {
      "icao": "SDXU",
      "iata": "",
      "name": "Fazenda Aracatuba Airport",
      "city": "Pacaembu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1401,
      "lat": -21.3433322906,
      "lon": -51.2516670227,
      "tz": "America\/Sao_Paulo"
  },
  "SDYB": {
      "icao": "SDYB",
      "iata": "",
      "name": "Fazenda Sao Jose do Barreiro Airport",
      "city": "Itapetininga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2234,
      "lat": -23.7852783203,
      "lon": -48.3400001526,
      "tz": "America\/Sao_Paulo"
  },
  "SDYF": {
      "icao": "SDYF",
      "iata": "",
      "name": "Fazenda Irmaos Munaretto Airport",
      "city": "Lucas Do Rio Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1408,
      "lat": -13.1066665649,
      "lon": -55.993057251,
      "tz": "America\/Cuiaba"
  },
  "SDYI": {
      "icao": "SDYI",
      "iata": "",
      "name": "Fazenda Buritiz Airport",
      "city": "Buritizeiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3045,
      "lat": -17.9174995422,
      "lon": -45.4025001526,
      "tz": "America\/Sao_Paulo"
  },
  "SDYJ": {
      "icao": "SDYJ",
      "iata": "",
      "name": "Jose Martins da Silva Airport",
      "city": "Regente Feijo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1663,
      "lat": -22.2255554199,
      "lon": -51.3480567932,
      "tz": "America\/Sao_Paulo"
  },
  "SDYM": {
      "icao": "SDYM",
      "iata": "QGB",
      "name": "Limeira Airport",
      "city": "Limeira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2172,
      "lat": -22.603889,
      "lon": -47.411944,
      "tz": "America\/Sao_Paulo"
  },
  "SDYN": {
      "icao": "SDYN",
      "iata": "",
      "name": "Fazenda Esmeralda Airport",
      "city": "Taciba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1365,
      "lat": -22.5888881683,
      "lon": -51.2988891602,
      "tz": "America\/Sao_Paulo"
  },
  "SDYS": {
      "icao": "SDYS",
      "iata": "",
      "name": "Aeroportobelo Airport",
      "city": "Porto Belo",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 33,
      "lat": -27.173611,
      "lon": -48.631111,
      "tz": "America\/Sao_Paulo"
  },
  "SDYW": {
      "icao": "SDYW",
      "iata": "",
      "name": "Itapeva Airport",
      "city": "Itapeva",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2320,
      "lat": -23.941400528,
      "lon": -48.8818016052,
      "tz": "America\/Sao_Paulo"
  },
  "SDYX": {
      "icao": "SDYX",
      "iata": "",
      "name": "Campo Maior Airport",
      "city": "Campo Maior",
      "state": "Piaui",
      "country": "BR",
      "elevation": 490,
      "lat": -4.8541669846,
      "lon": -42.2605552673,
      "tz": "America\/Fortaleza"
  },
  "SDZA": {
      "icao": "SDZA",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Pirajui",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1804,
      "lat": -22.0055561066,
      "lon": -49.4111099243,
      "tz": "America\/Sao_Paulo"
  },
  "SDZH": {
      "icao": "SDZH",
      "iata": "",
      "name": "Fazenda Santa Helena Airport",
      "city": "Pindamonhangaba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1804,
      "lat": -22.8903999329,
      "lon": -45.4933013916,
      "tz": "America\/Sao_Paulo"
  },
  "SDZM": {
      "icao": "SDZM",
      "iata": "",
      "name": "Fazenda Sao Marcos Airport",
      "city": "Itapeva",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2254,
      "lat": -23.9223995209,
      "lon": -48.8182983398,
      "tz": "America\/Sao_Paulo"
  },
  "SDZR": {
      "icao": "SDZR",
      "iata": "",
      "name": "Fazenda Cruzeiro Airport",
      "city": "Guariba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2116,
      "lat": -21.4122219086,
      "lon": -48.3286094666,
      "tz": "America\/Sao_Paulo"
  },
  "SDZS": {
      "icao": "SDZS",
      "iata": "",
      "name": "Fazenda Bananeira Airport",
      "city": "Altair",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1673,
      "lat": -20.5799999237,
      "lon": -49.07970047,
      "tz": "America\/Sao_Paulo"
  },
  "SDZT": {
      "icao": "SDZT",
      "iata": "",
      "name": "Fazenda Posses do Rio Grande Airport",
      "city": "Guaraci",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1581,
      "lat": -20.3108329773,
      "lon": -48.9769439697,
      "tz": "America\/Sao_Paulo"
  },
  "SDZU": {
      "icao": "SDZU",
      "iata": "",
      "name": "Fazenda Anhumas Airport",
      "city": "Maraba Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1001,
      "lat": -22.1224994659,
      "lon": -52.1977767944,
      "tz": "America\/Sao_Paulo"
  },
  "SDZV": {
      "icao": "SDZV",
      "iata": "",
      "name": "Fazenda Gandu Airport",
      "city": "Duartina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1841,
      "lat": -22.4761104584,
      "lon": -49.4700012207,
      "tz": "America\/Sao_Paulo"
  },
  "SDZW": {
      "icao": "SDZW",
      "iata": "",
      "name": "Fazenda Ipanema Airport",
      "city": "Andradina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1181,
      "lat": -20.8116664886,
      "lon": -51.2941665649,
      "tz": "America\/Sao_Paulo"
  },
  "SDZX": {
      "icao": "SDZX",
      "iata": "",
      "name": "Fazenda Suri Airport",
      "city": "Campinapolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1050,
      "lat": -14.815556,
      "lon": -53.133056,
      "tz": "America\/Cuiaba"
  },
  "SDZZ": {
      "icao": "SDZZ",
      "iata": "",
      "name": "Sitio Sao Jose Airport",
      "city": "Nova Europa",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1790,
      "lat": -21.7977771759,
      "lon": -48.543888092,
      "tz": "America\/Sao_Paulo"
  },
  "SE01": {
      "icao": "SE01",
      "iata": "",
      "name": "Alba Elena Airport",
      "city": "Rocafuerte",
      "state": "Guayas",
      "country": "EC",
      "elevation": 150,
      "lat": -2.166670084,
      "lon": -79.2833023071,
      "tz": "America\/Guayaquil"
  },
  "SE02": {
      "icao": "SE02",
      "iata": "",
      "name": "Guabital Airport",
      "city": "Soledad",
      "state": "Guayas",
      "country": "EC",
      "elevation": 45,
      "lat": -2.7733900547,
      "lon": -79.7024993896,
      "tz": "America\/Guayaquil"
  },
  "SE03": {
      "icao": "SE03",
      "iata": "",
      "name": "Las Penas Airport",
      "city": "Isla Puna",
      "state": "Guayas",
      "country": "EC",
      "elevation": 3,
      "lat": -2.9471600056,
      "lon": -80.1007003784,
      "tz": "America\/Guayaquil"
  },
  "SE08": {
      "icao": "SE08",
      "iata": "",
      "name": "San Carlos Costa Airport",
      "city": "Lorenzo de Garaicoa",
      "state": "Guayas",
      "country": "EC",
      "elevation": 21,
      "lat": -2.0547199249,
      "lon": -79.4330978394,
      "tz": "America\/Guayaquil"
  },
  "SE09": {
      "icao": "SE09",
      "iata": "",
      "name": "San Rafael Airport",
      "city": "San Rafael",
      "state": "Guayas",
      "country": "EC",
      "elevation": 190,
      "lat": -2.1816699505,
      "lon": -79.2367019653,
      "tz": "America\/Guayaquil"
  },
  "SE10": {
      "icao": "SE10",
      "iata": "",
      "name": "Vinces Airport",
      "city": "Vinces",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 32,
      "lat": -1.5961099863,
      "lon": -79.7260971069,
      "tz": "America\/Guayaquil"
  },
  "SE11": {
      "icao": "SE11",
      "iata": "",
      "name": "Km 60 Airport",
      "city": "Consumulo",
      "state": "Santo-Domingo-de-los-Tsachilas",
      "country": "EC",
      "elevation": 1640,
      "lat": -0.5366669893,
      "lon": -79.3688964844,
      "tz": "America\/Guayaquil"
  },
  "SE13": {
      "icao": "SE13",
      "iata": "",
      "name": "Babahoyo North Airport",
      "city": "Babahoyo",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 29,
      "lat": -1.7033530474,
      "lon": -79.5380477905,
      "tz": "America\/Guayaquil"
  },
  "SE14": {
      "icao": "SE14",
      "iata": "",
      "name": "San Carlos Airport",
      "city": "Jose Real",
      "state": "Guayas",
      "country": "EC",
      "elevation": 126,
      "lat": -2.1665520668,
      "lon": -79.4337005615,
      "tz": "America\/Guayaquil"
  },
  "SE15": {
      "icao": "SE15",
      "iata": "",
      "name": "Quevedo North Airport",
      "city": "Quevedo",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 314,
      "lat": -0.999826014,
      "lon": -79.4153594971,
      "tz": "America\/Guayaquil"
  },
  "SE16": {
      "icao": "SE16",
      "iata": "",
      "name": "Loja Airport",
      "city": "Loja",
      "state": "Loja",
      "country": "EC",
      "elevation": 7684,
      "lat": -3.8814239502,
      "lon": -79.2347488403,
      "tz": "America\/Guayaquil"
  },
  "SE22": {
      "icao": "SE22",
      "iata": "",
      "name": "Celia Maria Airport",
      "city": "Pasaje",
      "state": "El-Oro",
      "country": "EC",
      "elevation": 110,
      "lat": -3.2793300152,
      "lon": -79.8009033203,
      "tz": "America\/Guayaquil"
  },
  "SE58": {
      "icao": "SE58",
      "iata": "",
      "name": "Banasur Airport",
      "city": "Buenavista",
      "state": "El-Oro",
      "country": "EC",
      "elevation": 30,
      "lat": -3.3409399986,
      "lon": -79.8638000488,
      "tz": "America\/Guayaquil"
  },
  "SE59": {
      "icao": "SE59",
      "iata": "",
      "name": "La Mina Airport",
      "city": "El Guabo",
      "state": "El-Oro",
      "country": "EC",
      "elevation": 13,
      "lat": -3.20291996,
      "lon": -79.7982025146,
      "tz": "America\/Guayaquil"
  },
  "SE60": {
      "icao": "SE60",
      "iata": "",
      "name": "La Puntilla Airport",
      "city": "La Puntilla",
      "state": "Canar",
      "country": "EC",
      "elevation": 45,
      "lat": -2.4428501129,
      "lon": -79.3989028931,
      "tz": "America\/Guayaquil"
  },
  "SEAM": {
      "icao": "SEAM",
      "iata": "ATF",
      "name": "Chachoan Airport",
      "city": "Ambato",
      "state": "Tungurahua",
      "country": "EC",
      "elevation": 8502,
      "lat": -1.2120699883,
      "lon": -78.5746002197,
      "tz": "America\/Guayaquil"
  },
  "SEAP": {
      "icao": "SEAP",
      "iata": "",
      "name": "Arapicos Airport",
      "city": "Sangay",
      "state": "Morona-Santiago",
      "country": "EC",
      "elevation": 2920,
      "lat": -1.8576200008,
      "lon": -77.9413986206,
      "tz": "America\/Guayaquil"
  },
  "SEAR": {
      "icao": "SEAR",
      "iata": "",
      "name": "Arajuno Airport",
      "city": "Arajuno",
      "state": "Napo",
      "country": "EC",
      "elevation": 1700,
      "lat": -1.235730052,
      "lon": -77.6854019165,
      "tz": "America\/Guayaquil"
  },
  "SEAS": {
      "icao": "SEAS",
      "iata": "",
      "name": "Ascazubi Airport",
      "city": "Ascazubi",
      "state": "Pichincha",
      "country": "EC",
      "elevation": 9517,
      "lat": -0.0833332986,
      "lon": -78.2833328247,
      "tz": "America\/Guayaquil"
  },
  "SEAY": {
      "icao": "SEAY",
      "iata": "",
      "name": "Ayangue Airport",
      "city": "San Pedro",
      "state": "Santa-Elena",
      "country": "EC",
      "elevation": 18,
      "lat": -1.9777779579,
      "lon": -80.739440918,
      "tz": "America\/Guayaquil"
  },
  "SEBF": {
      "icao": "SEBF",
      "iata": "",
      "name": "Buena Fe Airport",
      "city": "Buena Fe",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 320,
      "lat": -0.8981379867,
      "lon": -79.4855422974,
      "tz": "America\/Guayaquil"
  },
  "SECH": {
      "icao": "SECH",
      "iata": "",
      "name": "Chone Airport",
      "city": "Chone",
      "state": "Manabi",
      "country": "EC",
      "elevation": 51,
      "lat": -0.6951010227,
      "lon": -80.0886001587,
      "tz": "America\/Guayaquil"
  },
  "SECM": {
      "icao": "SECM",
      "iata": "",
      "name": "Hacienda Clementina Airport",
      "city": "Pozuelos",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 328,
      "lat": -1.7062699795,
      "lon": -79.3788986206,
      "tz": "America\/Guayaquil"
  },
  "SECO": {
      "icao": "SECO",
      "iata": "OCC",
      "name": "Francisco De Orellana Airport",
      "city": "Coca",
      "state": "Napo",
      "country": "EC",
      "elevation": 834,
      "lat": -0.4628860056,
      "lon": -76.9868011475,
      "tz": "America\/Guayaquil"
  },
  "SECQ": {
      "icao": "SECQ",
      "iata": "",
      "name": "Coaque Airport",
      "city": "Coaque",
      "state": "",
      "country": "EC",
      "elevation": 131,
      "lat": 9.999e-07,
      "lon": -80.0999984741,
      "tz": "America\/Guayaquil"
  },
  "SECR": {
      "icao": "SECR",
      "iata": "",
      "name": "Curaray Airport",
      "city": "Curacay",
      "state": "Pastaza",
      "country": "EC",
      "elevation": 832,
      "lat": -1.3780299425,
      "lon": -76.939201355,
      "tz": "America\/Guayaquil"
  },
  "SECU": {
      "icao": "SECU",
      "iata": "CUE",
      "name": "Mariscal Lamar Airport",
      "city": "Cuenca",
      "state": "Azuay",
      "country": "EC",
      "elevation": 8306,
      "lat": -2.8894701004,
      "lon": -78.9843978882,
      "tz": "America\/Guayaquil"
  },
  "SEGE": {
      "icao": "SEGE",
      "iata": "",
      "name": "Guale Airport",
      "city": "Guale",
      "state": "Manabi",
      "country": "EC",
      "elevation": 222,
      "lat": -1.6166700125,
      "lon": -80.2332992554,
      "tz": "America\/Guayaquil"
  },
  "SEGS": {
      "icao": "SEGS",
      "iata": "GPS",
      "name": "Seymour Airport",
      "city": "Baltra",
      "state": "Galapagos",
      "country": "EC",
      "elevation": 207,
      "lat": -0.4537580013,
      "lon": -90.2658996582,
      "tz": "Pacific\/Galapagos"
  },
  "SEGU": {
      "icao": "SEGU",
      "iata": "GYE",
      "name": "Simon Bolivar International Airport",
      "city": "Guayaquil",
      "state": "Guayas",
      "country": "EC",
      "elevation": 19,
      "lat": -2.15741992,
      "lon": -79.8835983276,
      "tz": "America\/Guayaquil"
  },
  "SEGZ": {
      "icao": "SEGZ",
      "iata": "",
      "name": "Gualaquiza Airport",
      "city": "Santiago",
      "state": "Morona-Santiago",
      "country": "EC",
      "elevation": 2640,
      "lat": -3.4232099056,
      "lon": -78.5670013428,
      "tz": "America\/Guayaquil"
  },
  "SEHI": {
      "icao": "SEHI",
      "iata": "",
      "name": "Cotachachi Airport",
      "city": "Cotacachi",
      "state": "Pichincha",
      "country": "EC",
      "elevation": 7824,
      "lat": -0.302940011,
      "lon": -78.2683029175,
      "tz": "America\/Guayaquil"
  },
  "SEIB": {
      "icao": "SEIB",
      "iata": "",
      "name": "Atahualpa Airport",
      "city": "Ibarra",
      "state": "Imbabura",
      "country": "EC",
      "elevation": 7304,
      "lat": 0.3384189904,
      "lon": -78.1363983154,
      "tz": "America\/Guayaquil"
  },
  "SEII": {
      "icao": "SEII",
      "iata": "IBB",
      "name": "General Villamil Airport",
      "city": "Isabela",
      "state": "Galapagos",
      "country": "EC",
      "elevation": 35,
      "lat": -0.942628026,
      "lon": -90.9530029297,
      "tz": "Pacific\/Galapagos"
  },
  "SEIS": {
      "icao": "SEIS",
      "iata": "",
      "name": "Isabel Maria Airport",
      "city": "America",
      "state": "Guayas",
      "country": "EC",
      "elevation": 20,
      "lat": -1.8666700125,
      "lon": -79.7332992554,
      "tz": "America\/Guayaquil"
  },
  "SEJI": {
      "icao": "SEJI",
      "iata": "JIP",
      "name": "Jipijapa Airport",
      "city": "Jipijapa",
      "state": "Manabi",
      "country": "EC",
      "elevation": 223,
      "lat": -1,
      "lon": -80.6666641235,
      "tz": "America\/Guayaquil"
  },
  "SEJM": {
      "icao": "SEJM",
      "iata": "",
      "name": "Jama Airport",
      "city": "Jama",
      "state": "Manabi",
      "country": "EC",
      "elevation": 37,
      "lat": -0.2014970034,
      "lon": -80.2654037476,
      "tz": "America\/Guayaquil"
  },
  "SEKK": {
      "icao": "SEKK",
      "iata": "",
      "name": "Km 192 Airport",
      "city": "Lagartera",
      "state": "Esmeraldas",
      "country": "EC",
      "elevation": 1247,
      "lat": 0.1842029989,
      "lon": -79.391998291,
      "tz": "America\/Guayaquil"
  },
  "SELI": {
      "icao": "SELI",
      "iata": "",
      "name": "Limoncocha Airport",
      "city": "Limoncocha",
      "state": "",
      "country": "EC",
      "elevation": 820,
      "lat": -0.4063630104,
      "lon": -76.6238021851,
      "tz": "America\/Guayaquil"
  },
  "SELJ": {
      "icao": "SELJ",
      "iata": "",
      "name": "Hacienda La Julia Airport",
      "city": "Julia",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 50,
      "lat": -1.7043800354,
      "lon": -79.5522994995,
      "tz": "America\/Guayaquil"
  },
  "SELM": {
      "icao": "SELM",
      "iata": "",
      "name": "Loma Larga Airport",
      "city": "Loma Larga",
      "state": "",
      "country": "EC",
      "elevation": 210,
      "lat": -1.5015799999,
      "lon": -79.4809036255,
      "tz": "America\/Guayaquil"
  },
  "SELT": {
      "icao": "SELT",
      "iata": "LTX",
      "name": "Cotopaxi International Airport",
      "city": "Latacunga",
      "state": "Cotopaxi",
      "country": "EC",
      "elevation": 9205,
      "lat": -0.906832993,
      "lon": -78.6157989502,
      "tz": "America\/Guayaquil"
  },
  "SEMA": {
      "icao": "SEMA",
      "iata": "MRR",
      "name": "Jose Maria Velasco Ibarra Airport",
      "city": "Macara",
      "state": "Loja",
      "country": "EC",
      "elevation": 1508,
      "lat": -4.3782300949,
      "lon": -79.9410018921,
      "tz": "America\/Guayaquil"
  },
  "SEMC": {
      "icao": "SEMC",
      "iata": "XMS",
      "name": "Coronel E Carvajal Airport",
      "city": "Macas",
      "state": "",
      "country": "EC",
      "elevation": 3452,
      "lat": -2.2991700172,
      "lon": -78.1207962036,
      "tz": "America\/Guayaquil"
  },
  "SEMH": {
      "icao": "SEMH",
      "iata": "MCH",
      "name": "General Manuel Serrano Airport",
      "city": "Machala",
      "state": "El-Oro",
      "country": "EC",
      "elevation": 11,
      "lat": -3.2688999176,
      "lon": -79.9616012573,
      "tz": "America\/Guayaquil"
  },
  "SEMO": {
      "icao": "SEMO",
      "iata": "",
      "name": "El Carmen Airport",
      "city": "Montalvo",
      "state": "Pastaza",
      "country": "EC",
      "elevation": 960,
      "lat": -2.0670099258,
      "lon": -76.9757003784,
      "tz": "America\/Guayaquil"
  },
  "SEMT": {
      "icao": "SEMT",
      "iata": "MEC",
      "name": "Eloy Alfaro International Airport",
      "city": "Manta",
      "state": "Manabi",
      "country": "EC",
      "elevation": 48,
      "lat": -0.9460780025,
      "lon": -80.6788024902,
      "tz": "America\/Guayaquil"
  },
  "SEMX": {
      "icao": "SEMX",
      "iata": "",
      "name": "Maragrosa Airport",
      "city": "Puerto Balao",
      "state": "Guayas",
      "country": "EC",
      "elevation": 18,
      "lat": -2.851099968,
      "lon": -79.8035964966,
      "tz": "America\/Guayaquil"
  },
  "SEMY": {
      "icao": "SEMY",
      "iata": "",
      "name": "Martinica Airport",
      "city": "Martinica",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 90,
      "lat": -1.7397199869,
      "lon": -79.6214981079,
      "tz": "America\/Guayaquil"
  },
  "SENL": {
      "icao": "SENL",
      "iata": "LGQ",
      "name": "Nueva Loja Airport",
      "city": "Lago Agrio",
      "state": "Sucumbios",
      "country": "EC",
      "elevation": 980,
      "lat": 0.0930560008,
      "lon": -76.8675003052,
      "tz": "America\/Guayaquil"
  },
  "SEPB": {
      "icao": "SEPB",
      "iata": "",
      "name": "Pedro Carbo Airport",
      "city": "Pecro Carbo",
      "state": "Guayas",
      "country": "EC",
      "elevation": 321,
      "lat": -1.8115930557,
      "lon": -80.232460022,
      "tz": "America\/Guayaquil"
  },
  "SEPC": {
      "icao": "SEPC",
      "iata": "",
      "name": "Patuca Airport",
      "city": "Patuca",
      "state": "Morona-Santiago",
      "country": "EC",
      "elevation": 1781,
      "lat": -2.7517199516,
      "lon": -78.2636032104,
      "tz": "America\/Guayaquil"
  },
  "SEPD": {
      "icao": "SEPD",
      "iata": "",
      "name": "Pedernales Airport",
      "city": "Pedernales",
      "state": "Manabi",
      "country": "EC",
      "elevation": 60,
      "lat": 0.0676190034,
      "lon": -80.0504989624,
      "tz": "America\/Guayaquil"
  },
  "SEPO": {
      "icao": "SEPO",
      "iata": "",
      "name": "Posorja Airport",
      "city": "Posorja",
      "state": "Guayas",
      "country": "EC",
      "elevation": 30,
      "lat": -2.6820299625,
      "lon": -80.2755966187,
      "tz": "America\/Guayaquil"
  },
  "SEPS": {
      "icao": "SEPS",
      "iata": "",
      "name": "Amable Calle Gutierrez Airport",
      "city": "Pasaje",
      "state": "El-Oro",
      "country": "EC",
      "elevation": 22,
      "lat": -3.3196699619,
      "lon": -79.769203186,
      "tz": "America\/Guayaquil"
  },
  "SEPT": {
      "icao": "SEPT",
      "iata": "PYO",
      "name": "Putumayo Airport",
      "city": "Puerto Putumayo",
      "state": "Sucumbios",
      "country": "EC",
      "elevation": 733,
      "lat": 0.0833330005,
      "lon": -75.9000015259,
      "tz": "America\/Guayaquil"
  },
  "SEPU": {
      "icao": "SEPU",
      "iata": "",
      "name": "Puna Airport",
      "city": "Puna",
      "state": "Guayas",
      "country": "EC",
      "elevation": 3,
      "lat": -2.7336800098,
      "lon": -79.9152984619,
      "tz": "America\/Guayaquil"
  },
  "SEPV": {
      "icao": "SEPV",
      "iata": "PVO",
      "name": "Reales Tamarindos Airport",
      "city": "Portoviejo",
      "state": "Manabi",
      "country": "EC",
      "elevation": 130,
      "lat": -1.0416500568,
      "lon": -80.4721984863,
      "tz": "America\/Guayaquil"
  },
  "SEPX": {
      "icao": "SEPX",
      "iata": "",
      "name": "Payo Airport",
      "city": "Hacienda Payo",
      "state": "Guayas",
      "country": "EC",
      "elevation": 101,
      "lat": -2.3099999428,
      "lon": -79.4599990845,
      "tz": "America\/Guayaquil"
  },
  "SEPZ": {
      "icao": "SEPZ",
      "iata": "",
      "name": "Los Perez Airport",
      "city": "Los Perez",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 75,
      "lat": -0.9537220001,
      "lon": -79.3751983643,
      "tz": "America\/Guayaquil"
  },
  "SEQE": {
      "icao": "SEQE",
      "iata": "",
      "name": "Quevedo Airport",
      "city": "Quevedo",
      "state": "Los-Rios",
      "country": "EC",
      "elevation": 350,
      "lat": -0.9894000292,
      "lon": -79.4651031494,
      "tz": "America\/Guayaquil"
  },
  "SEQM": {
      "icao": "SEQM",
      "iata": "UIO",
      "name": "Nuevo Aeropuerto Internacional Mariscal Sucre",
      "city": "Quito",
      "state": "Pichincha",
      "country": "EC",
      "elevation": 9200,
      "lat": -0.1291666667,
      "lon": -78.3575,
      "tz": "America\/Guayaquil"
  },
  "SERB": {
      "icao": "SERB",
      "iata": "",
      "name": "Chimborazo Airport",
      "city": "Riobamba",
      "state": "Chimborazo",
      "country": "EC",
      "elevation": 9151,
      "lat": -1.653429985,
      "lon": -78.6560974121,
      "tz": "America\/Guayaquil"
  },
  "SERO": {
      "icao": "SERO",
      "iata": "ETR",
      "name": "Coronel Artilleria Victor Larrea Airport",
      "city": "Santa Rosa",
      "state": "El-Oro",
      "country": "EC",
      "elevation": 170,
      "lat": -3.4351599217,
      "lon": -79.9777984619,
      "tz": "America\/Guayaquil"
  },
  "SESA": {
      "icao": "SESA",
      "iata": "SNC",
      "name": "General Ulpiano Paez Airport",
      "city": "Salinas",
      "state": "Santa-Elena",
      "country": "EC",
      "elevation": 18,
      "lat": -2.2049899101,
      "lon": -80.988899231,
      "tz": "America\/Guayaquil"
  },
  "SESC": {
      "icao": "SESC",
      "iata": "SUQ",
      "name": "Sucua Airport",
      "city": "Sucua",
      "state": "Morona-Santiago",
      "country": "EC",
      "elevation": 3116,
      "lat": -2.4830000401,
      "lon": -78.1669998169,
      "tz": "America\/Guayaquil"
  },
  "SESD": {
      "icao": "SESD",
      "iata": "",
      "name": "Santo Domingo de Los Colorados Airport",
      "city": "Santo Domingo de Los Colorades",
      "state": "Santo-Domingo-de-los-Tsachilas",
      "country": "EC",
      "elevation": 1714,
      "lat": -0.2482219934,
      "lon": -79.2144012451,
      "tz": "America\/Guayaquil"
  },
  "SESM": {
      "icao": "SESM",
      "iata": "PTZ",
      "name": "Rio Amazonas Airport",
      "city": "Shell Mera",
      "state": "Pastaza",
      "country": "EC",
      "elevation": 3465,
      "lat": -1.5052399635,
      "lon": -78.0626983643,
      "tz": "America\/Guayaquil"
  },
  "SEST": {
      "icao": "SEST",
      "iata": "SCY",
      "name": "San Cristobal Airport",
      "city": "San Cristobal",
      "state": "Galapagos",
      "country": "EC",
      "elevation": 62,
      "lat": -0.9102060199,
      "lon": -89.617401123,
      "tz": "Pacific\/Galapagos"
  },
  "SESV": {
      "icao": "SESV",
      "iata": "BHA",
      "name": "Los Perales Airport",
      "city": "Bahia de Caraquez",
      "state": "Manabi",
      "country": "EC",
      "elevation": 10,
      "lat": -0.6081110239,
      "lon": -80.4027023315,
      "tz": "America\/Guayaquil"
  },
  "SETA": {
      "icao": "SETA",
      "iata": "",
      "name": "Taura Airport",
      "city": "Taura",
      "state": "Guayas",
      "country": "EC",
      "elevation": 56,
      "lat": -2.2610399723,
      "lon": -79.6801986694,
      "tz": "America\/Guayaquil"
  },
  "SETE": {
      "icao": "SETE",
      "iata": "",
      "name": "Mayor Galo Torres Airport",
      "city": "Tena",
      "state": "Napo",
      "country": "EC",
      "elevation": 1708,
      "lat": -0.986766994,
      "lon": -77.8195037842,
      "tz": "America\/Guayaquil"
  },
  "SETG": {
      "icao": "SETG",
      "iata": "",
      "name": "Tenguel Airport",
      "city": "Boca de Tenguel",
      "state": "Guayas",
      "country": "EC",
      "elevation": 33,
      "lat": -2.9986000061,
      "lon": -79.7947006226,
      "tz": "America\/Guayaquil"
  },
  "SETH": {
      "icao": "SETH",
      "iata": "TSC",
      "name": "Taisha Airport",
      "city": "Taisha",
      "state": "Morona-Santiago",
      "country": "EC",
      "elevation": 1669,
      "lat": -2.3816699982,
      "lon": -77.5027999878,
      "tz": "America\/Guayaquil"
  },
  "SETI": {
      "icao": "SETI",
      "iata": "TPN",
      "name": "Tiputini Airport",
      "city": "Tiputini",
      "state": "Orellana",
      "country": "EC",
      "elevation": 997,
      "lat": -0.7761110067,
      "lon": -75.5263977051,
      "tz": "America\/Guayaquil"
  },
  "SETM": {
      "icao": "SETM",
      "iata": "LOH",
      "name": "Camilo Ponce Enriquez Airport",
      "city": "La Toma (Catamayo)",
      "state": "Loja",
      "country": "EC",
      "elevation": 4056,
      "lat": -3.9958899021,
      "lon": -79.3719024658,
      "tz": "America\/Guayaquil"
  },
  "SETN": {
      "icao": "SETN",
      "iata": "ESM",
      "name": "General Rivadeneira Airport",
      "city": "Tachina",
      "state": "Esmeraldas",
      "country": "EC",
      "elevation": 32,
      "lat": 0.9785190225,
      "lon": -79.6266021729,
      "tz": "America\/Guayaquil"
  },
  "SETR": {
      "icao": "SETR",
      "iata": "TPC",
      "name": "Tarapoa Airport",
      "city": "Tarapoa",
      "state": "Sucumbios",
      "country": "EC",
      "elevation": 814,
      "lat": -0.1229560003,
      "lon": -76.3377990723,
      "tz": "America\/Guayaquil"
  },
  "SETU": {
      "icao": "SETU",
      "iata": "TUA",
      "name": "Teniente Coronel Luis a Mantilla Airport",
      "city": "Tulcan",
      "state": "Carchi",
      "country": "EC",
      "elevation": 9649,
      "lat": 0.8095059991,
      "lon": -77.7080993652,
      "tz": "America\/Guayaquil"
  },
  "SEVR": {
      "icao": "SEVR",
      "iata": "",
      "name": "El Vergel Airport",
      "city": "El Vergel",
      "state": "Chimborazo",
      "country": "EC",
      "elevation": 9002,
      "lat": -1.75,
      "lon": -78.6166687012,
      "tz": "America\/Guayaquil"
  },
  "SFAL": {
      "icao": "SFAL",
      "iata": "PSY",
      "name": "Stanley Airport",
      "city": "Stanley",
      "state": "",
      "country": "FK",
      "elevation": 75,
      "lat": -51.6856994629,
      "lon": -57.7775993347,
      "tz": "Atlantic\/Stanley"
  },
  "SG67": {
      "icao": "SG67",
      "iata": "",
      "name": "Estancia Pai Quara Airport",
      "city": "Estancia Pai Quara",
      "state": "Amambay",
      "country": "PY",
      "elevation": 712,
      "lat": -23.2199993134,
      "lon": -55.9500007629,
      "tz": "America\/Asuncion"
  },
  "SGAS": {
      "icao": "SGAS",
      "iata": "ASU",
      "name": "Silvio Pettirossi International Airport",
      "city": "Asuncion",
      "state": "Central",
      "country": "PY",
      "elevation": 292,
      "lat": -25.2399997711,
      "lon": -57.5200004578,
      "tz": "America\/Asuncion"
  },
  "SGAY": {
      "icao": "SGAY",
      "iata": "AYO",
      "name": "Juan De Ayolas Airport",
      "city": "Ayolas",
      "state": "Misiones",
      "country": "PY",
      "elevation": 223,
      "lat": -27.3700008392,
      "lon": -56.8499984741,
      "tz": "America\/Asuncion"
  },
  "SGBA": {
      "icao": "SGBA",
      "iata": "",
      "name": "Bella Vista Sur Airport",
      "city": "Colonia Doctor Pastor Obligado",
      "state": "Itapua",
      "country": "PY",
      "elevation": 650,
      "lat": -27.052393,
      "lon": -55.578026,
      "tz": "America\/Asuncion"
  },
  "SGBN": {
      "icao": "SGBN",
      "iata": "BFA",
      "name": "Bahia Negra Airport",
      "city": "Bahia Negra",
      "state": "Alto-Paraguay",
      "country": "PY",
      "elevation": 277,
      "lat": -20.2245998383,
      "lon": -58.1791992188,
      "tz": "America\/Asuncion"
  },
  "SGCO": {
      "icao": "SGCO",
      "iata": "CIO",
      "name": "Teniente Col Carmelo Peralta Airport",
      "city": "Concepcion",
      "state": "Concepcion",
      "country": "PY",
      "elevation": 253,
      "lat": -23.4400005341,
      "lon": -57.4300003052,
      "tz": "America\/Asuncion"
  },
  "SGEN": {
      "icao": "SGEN",
      "iata": "ENO",
      "name": "Encarnacion Airport",
      "city": "Encarnacion",
      "state": "Itapua",
      "country": "PY",
      "elevation": 269,
      "lat": -27.2999992371,
      "lon": -55.9099998474,
      "tz": "America\/Asuncion"
  },
  "SGES": {
      "icao": "SGES",
      "iata": "AGT",
      "name": "Guarani International Airport",
      "city": "Ciudad del Este",
      "state": "Alto-Parana",
      "country": "PY",
      "elevation": 846,
      "lat": -25.4599990845,
      "lon": -54.8400001526,
      "tz": "America\/Asuncion"
  },
  "SGFI": {
      "icao": "SGFI",
      "iata": "FLM",
      "name": "Filadelfia Airport",
      "city": "Filadelfia",
      "state": "Boqueron",
      "country": "PY",
      "elevation": 423,
      "lat": -22.3600006104,
      "lon": -60.0499992371,
      "tz": "America\/Asuncion"
  },
  "SGGR": {
      "icao": "SGGR",
      "iata": "",
      "name": "Salto del Guaira Airport",
      "city": "Salto del Guaira",
      "state": "Canindeyu",
      "country": "PY",
      "elevation": 990,
      "lat": -24.032269,
      "lon": -54.350829,
      "tz": "America\/Asuncion"
  },
  "SGIB": {
      "icao": "SGIB",
      "iata": "",
      "name": "Itaipu Airport",
      "city": "Itaipu",
      "state": "Alto-Parana",
      "country": "PY",
      "elevation": 762,
      "lat": -25.4099998474,
      "lon": -54.6199989319,
      "tz": "America\/Asuncion"
  },
  "SGME": {
      "icao": "SGME",
      "iata": "ESG",
      "name": "Dr. Luis Maria Argana International Airport",
      "city": "Mariscal Estigarribia",
      "state": "Boqueron",
      "country": "PY",
      "elevation": 553,
      "lat": -22.0499992371,
      "lon": -60.6199989319,
      "tz": "America\/Asuncion"
  },
  "SGNB": {
      "icao": "SGNB",
      "iata": "",
      "name": "Villa Hayes Sarg Airport",
      "city": "Villa Hayes",
      "state": "Presidente-Hayes",
      "country": "PY",
      "elevation": 210,
      "lat": -25.1599998474,
      "lon": -57.5600013733,
      "tz": "America\/Asuncion"
  },
  "SGOL": {
      "icao": "SGOL",
      "iata": "OLK",
      "name": "Fuerte Olimpo Airport",
      "city": "Fuerte Olimpo",
      "state": "Alto-Paraguay",
      "country": "PY",
      "elevation": 275,
      "lat": -21.0452003479,
      "lon": -57.8824996948,
      "tz": "America\/Asuncion"
  },
  "SGOV": {
      "icao": "SGOV",
      "iata": "",
      "name": "Coronel Oviedo Airport",
      "city": "Coronel Oviedo",
      "state": "Caaguazu",
      "country": "PY",
      "elevation": 487,
      "lat": -25.5200004578,
      "lon": -56.4099998474,
      "tz": "America\/Asuncion"
  },
  "SGPC": {
      "icao": "SGPC",
      "iata": "",
      "name": "Pozo Colorado Airport",
      "city": "Pozo Colorado",
      "state": "Presidente-Hayes",
      "country": "PY",
      "elevation": 325,
      "lat": -23.499127,
      "lon": -58.785914,
      "tz": "America\/Asuncion"
  },
  "SGPG": {
      "icao": "SGPG",
      "iata": "",
      "name": "Pelayo Prats Gill Airstrip",
      "city": "",
      "state": "Boqueron",
      "country": "PY",
      "elevation": 690,
      "lat": -22.555819,
      "lon": -61.613992,
      "tz": "America\/Asuncion"
  },
  "SGPI": {
      "icao": "SGPI",
      "iata": "PIL",
      "name": "Carlos Miguel Gimenez Airport",
      "city": "Pilar",
      "state": "Neembucu",
      "country": "PY",
      "elevation": 249,
      "lat": -26.8799991608,
      "lon": -58.3199996948,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SGPJ": {
      "icao": "SGPJ",
      "iata": "PJC",
      "name": "Dr Augusto Roberto Fuster International Airport",
      "city": "Pedro Juan Caballero",
      "state": "Amambay",
      "country": "PY",
      "elevation": 1873,
      "lat": -22.6399993896,
      "lon": -55.8300018311,
      "tz": "America\/Asuncion"
  },
  "SGPO": {
      "icao": "SGPO",
      "iata": "",
      "name": "Puerto Pinasco Airport",
      "city": "Pinasco",
      "state": "Presidente-Hayes",
      "country": "PY",
      "elevation": 239,
      "lat": -22.6299991608,
      "lon": -57.8400001526,
      "tz": "America\/Asuncion"
  },
  "SGRO": {
      "icao": "SGRO",
      "iata": "",
      "name": "Rosario Airport",
      "city": "Rosario",
      "state": "San-Pedro",
      "country": "PY",
      "elevation": 236,
      "lat": -24.4300003052,
      "lon": -57.1199989319,
      "tz": "America\/Asuncion"
  },
  "SGSP": {
      "icao": "SGSP",
      "iata": "",
      "name": "San Pedro Airport",
      "city": "San Pedro del Ycuamandiyu",
      "state": "San-Pedro",
      "country": "PY",
      "elevation": 270,
      "lat": -24.082883,
      "lon": -57.088112,
      "tz": "America\/Asuncion"
  },
  "SGST": {
      "icao": "SGST",
      "iata": "",
      "name": "Santa Teresa Airport",
      "city": "Santa Teresa",
      "state": "Amambay",
      "country": "PY",
      "elevation": 582,
      "lat": -22.6200008392,
      "lon": -56.6300010681,
      "tz": "America\/Asuncion"
  },
  "SIAB": {
      "icao": "SIAB",
      "iata": "",
      "name": "Leda Mello Resende Airport",
      "city": "Tres Pontas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3050,
      "lat": -21.3719005585,
      "lon": -45.4948997498,
      "tz": "America\/Sao_Paulo"
  },
  "SIAD": {
      "icao": "SIAD",
      "iata": "",
      "name": "Estancia Regina Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 394,
      "lat": -21.0088882446,
      "lon": -57.3480567932,
      "tz": "America\/Campo_Grande"
  },
  "SIAF": {
      "icao": "SIAF",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1903,
      "lat": -13.7411108017,
      "lon": -58.8855552673,
      "tz": "America\/Cuiaba"
  },
  "SIAI": {
      "icao": "SIAI",
      "iata": "",
      "name": "Fazenda Gaivota Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 541,
      "lat": -16.2361106873,
      "lon": -56.918888092,
      "tz": "America\/Cuiaba"
  },
  "SIAM": {
      "icao": "SIAM",
      "iata": "0",
      "name": "Fazenda Palmital Airport",
      "city": "Morro Agudo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1709,
      "lat": -20.6963996887,
      "lon": -48.2863998413,
      "tz": "America\/Sao_Paulo"
  },
  "SIAO": {
      "icao": "SIAO",
      "iata": "",
      "name": "Fazenda Araras Airport",
      "city": "Corguinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1493,
      "lat": -19.6297225952,
      "lon": -55.2519454956,
      "tz": "America\/Campo_Grande"
  },
  "SIAP": {
      "icao": "SIAP",
      "iata": "",
      "name": "Ana Paula Airport",
      "city": "Acegua",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 584,
      "lat": -31.73777771,
      "lon": -54.0894432068,
      "tz": "America\/Sao_Paulo"
  },
  "SIAR": {
      "icao": "SIAR",
      "iata": "",
      "name": "Fazenda Bom Retiro Airport",
      "city": "Ibirarema",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1617,
      "lat": -22.7991676331,
      "lon": -50.0461120605,
      "tz": "America\/Sao_Paulo"
  },
  "SIAU": {
      "icao": "SIAU",
      "iata": "",
      "name": "Fazenda Jatoba Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 404,
      "lat": -16.4833335876,
      "lon": -56.2700004578,
      "tz": "America\/Cuiaba"
  },
  "SIAX": {
      "icao": "SIAX",
      "iata": "",
      "name": "Fazenda Beira Rio Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 870,
      "lat": -7.5502781868,
      "lon": -56.1725006104,
      "tz": "America\/Santarem"
  },
  "SIAY": {
      "icao": "SIAY",
      "iata": "",
      "name": "Auriflama Airport",
      "city": "Auriflama",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1277,
      "lat": -20.7419433594,
      "lon": -50.531665802,
      "tz": "America\/Sao_Paulo"
  },
  "SIBA": {
      "icao": "SIBA",
      "iata": "",
      "name": "Fazenda Jatoba Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 400,
      "lat": -20.0991668701,
      "lon": -55.9269447327,
      "tz": "America\/Campo_Grande"
  },
  "SIBC": {
      "icao": "SIBC",
      "iata": "",
      "name": "Vila de Tocos Airport",
      "city": "Campos Dos Goytacazes",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 26,
      "lat": -21.8777770996,
      "lon": -41.2947235107,
      "tz": "America\/Sao_Paulo"
  },
  "SIBD": {
      "icao": "SIBD",
      "iata": "",
      "name": "Benedito Mutran Airport",
      "city": "Maraba",
      "state": "Para",
      "country": "BR",
      "elevation": 551,
      "lat": -5.7585701942,
      "lon": -49.1762008667,
      "tz": "America\/Belem"
  },
  "SIBF": {
      "icao": "SIBF",
      "iata": "",
      "name": "Bunge Fertilizantes Airport",
      "city": "Cajati",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 249,
      "lat": -24.7191677094,
      "lon": -48.1186103821,
      "tz": "America\/Sao_Paulo"
  },
  "SIBK": {
      "icao": "SIBK",
      "iata": "",
      "name": "Sada Siderurgia Ltda Airport",
      "city": "Varzea Da Palma",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1759,
      "lat": -17.519443512,
      "lon": -44.7611122131,
      "tz": "America\/Sao_Paulo"
  },
  "SIBN": {
      "icao": "SIBN",
      "iata": "",
      "name": "Fazenda Canaa Airport",
      "city": "Piraju",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2021,
      "lat": -23.0713882446,
      "lon": -49.4324989319,
      "tz": "America\/Sao_Paulo"
  },
  "SIBO": {
      "icao": "SIBO",
      "iata": "",
      "name": "Sao Luiz Airport",
      "city": "Boa Esperanca",
      "state": "Parana",
      "country": "BR",
      "elevation": 1919,
      "lat": -24.2625007629,
      "lon": -52.7922210693,
      "tz": "America\/Sao_Paulo"
  },
  "SIBU": {
      "icao": "SIBU",
      "iata": "",
      "name": "Catole da Rocha Airport",
      "city": "Catole Da Rocha",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 892,
      "lat": -6.3625302315,
      "lon": -37.7561988831,
      "tz": "America\/Fortaleza"
  },
  "SIBV": {
      "icao": "SIBV",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Nova Alvorada Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -21.4486103058,
      "lon": -54.488609314,
      "tz": "America\/Campo_Grande"
  },
  "SIBW": {
      "icao": "SIBW",
      "iata": "",
      "name": "Conceicao Airport",
      "city": "Conceicao",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 1214,
      "lat": -7.5582299232,
      "lon": -38.4964981079,
      "tz": "America\/Fortaleza"
  },
  "SIBX": {
      "icao": "SIBX",
      "iata": "",
      "name": "Rosana Camargo Airport",
      "city": "Buritama",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1345,
      "lat": -21.0911102295,
      "lon": -50.2433319092,
      "tz": "America\/Sao_Paulo"
  },
  "SIBY": {
      "icao": "SIBY",
      "iata": "",
      "name": "Monteiro Airport",
      "city": "Monteiro",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 2031,
      "lat": -7.8782401085,
      "lon": -37.1399993896,
      "tz": "America\/Fortaleza"
  },
  "SIBZ": {
      "icao": "SIBZ",
      "iata": "",
      "name": "Itaporanga Airport",
      "city": "Itaporanga",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 958,
      "lat": -7.3116598129,
      "lon": -38.112701416,
      "tz": "America\/Fortaleza"
  },
  "SICA": {
      "icao": "SICA",
      "iata": "",
      "name": "Brejo das Freiras Airport",
      "city": "Sao Joao Do Rio Do Peixe",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 1017,
      "lat": -6.7041668892,
      "lon": -38.5027770996,
      "tz": "America\/Fortaleza"
  },
  "SICB": {
      "icao": "SICB",
      "iata": "",
      "name": "Pedro Simoes Pimenta Airport",
      "city": "Cuite",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 2139,
      "lat": -6.4875001907,
      "lon": -36.1399993896,
      "tz": "America\/Fortaleza"
  },
  "SICH": {
      "icao": "SICH",
      "iata": "",
      "name": "Fazenda Serrinha Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1188,
      "lat": -20.6608333588,
      "lon": -52.3400001526,
      "tz": "America\/Campo_Grande"
  },
  "SICJ": {
      "icao": "SICJ",
      "iata": "",
      "name": "Gaivota Aviacao Agricola Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1706,
      "lat": -13.4600000381,
      "lon": -58.7750015259,
      "tz": "America\/Cuiaba"
  },
  "SICK": {
      "icao": "SICK",
      "iata": "",
      "name": "Cidade Capelinha Airport",
      "city": "Capelinha",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3113,
      "lat": -17.6827774048,
      "lon": -42.5311126709,
      "tz": "America\/Sao_Paulo"
  },
  "SICM": {
      "icao": "SICM",
      "iata": "",
      "name": "Fazenda Nossa Senhora de Fatima Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 984,
      "lat": -21.5388889313,
      "lon": -52.1105575562,
      "tz": "America\/Campo_Grande"
  },
  "SICN": {
      "icao": "SICN",
      "iata": "",
      "name": "Fazenda Vista Bonita Airport",
      "city": "Sandovalina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1348,
      "lat": -22.5033321381,
      "lon": -51.8127784729,
      "tz": "America\/Sao_Paulo"
  },
  "SICO": {
      "icao": "SICO",
      "iata": "",
      "name": "SOLAG - Sol e Lua Aviacao Agricola Airport",
      "city": "Lucas Do Rio Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1453,
      "lat": -13.3197221756,
      "lon": -56.0469436646,
      "tz": "America\/Cuiaba"
  },
  "SICP": {
      "icao": "SICP",
      "iata": "",
      "name": "Doutor Walter Beckert Airport",
      "city": "Faxinal",
      "state": "Parana",
      "country": "BR",
      "elevation": 2779,
      "lat": -23.9716663361,
      "lon": -51.3236122131,
      "tz": "America\/Sao_Paulo"
  },
  "SICW": {
      "icao": "SICW",
      "iata": "",
      "name": "Fazenda Santa Fe Airport",
      "city": "Campo Alegre De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 3094,
      "lat": -17.4638881683,
      "lon": -47.8316688538,
      "tz": "America\/Sao_Paulo"
  },
  "SICY": {
      "icao": "SICY",
      "iata": "",
      "name": "Fazenda do Cedro Airport",
      "city": "Chapada",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2143,
      "lat": -28.1083335876,
      "lon": -53.1963882446,
      "tz": "America\/Sao_Paulo"
  },
  "SIDB": {
      "icao": "SIDB",
      "iata": "",
      "name": "Doutor Almir Lopes de Oliveira Melo Airport",
      "city": "Coelho Neto",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 174,
      "lat": -4.2688889503,
      "lon": -43.0005569458,
      "tz": "America\/Fortaleza"
  },
  "SIDD": {
      "icao": "SIDD",
      "iata": "",
      "name": "Fazenda Sao Joao do Monte Alto Airport",
      "city": "Maracaju",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1476,
      "lat": -21.4972229004,
      "lon": -55.2638893127,
      "tz": "America\/Campo_Grande"
  },
  "SIDG": {
      "icao": "SIDG",
      "iata": "",
      "name": "Fazenda Jatobasso Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2152,
      "lat": -22.4291667938,
      "lon": -55.5333328247,
      "tz": "America\/Campo_Grande"
  },
  "SIDM": {
      "icao": "SIDM",
      "iata": "",
      "name": "Fazenda Cibrapa Airport",
      "city": "Barra Do Garcas",
      "state": "",
      "country": "BR",
      "elevation": 821,
      "lat": -15.0383329391,
      "lon": -52.0983314514,
      "tz": "America\/Cuiaba"
  },
  "SIDN": {
      "icao": "SIDN",
      "iata": "",
      "name": "Fazenda Ipanema Airport",
      "city": "Ipero",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1985,
      "lat": -23.4255561829,
      "lon": -47.5813903809,
      "tz": "America\/Sao_Paulo"
  },
  "SIDR": {
      "icao": "SIDR",
      "iata": "",
      "name": "Retiro Piuva Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -19.8355560303,
      "lon": -56.7036094666,
      "tz": "America\/Campo_Grande"
  },
  "SIDS": {
      "icao": "SIDS",
      "iata": "",
      "name": "Fazenda Santa Lucinha Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 300,
      "lat": -18.0233325958,
      "lon": -56.7322235107,
      "tz": "America\/Campo_Grande"
  },
  "SIDY": {
      "icao": "SIDY",
      "iata": "",
      "name": "Fazenda Yanduy Airport",
      "city": "Lunardelle",
      "state": "Parana",
      "country": "BR",
      "elevation": 985,
      "lat": -24.0774993896,
      "lon": -51.819442749,
      "tz": "America\/Sao_Paulo"
  },
  "SIDZ": {
      "icao": "SIDZ",
      "iata": "",
      "name": "Estancia Ayrton Senna Airport",
      "city": "Caarapo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1509,
      "lat": -22.6561107635,
      "lon": -54.8222236633,
      "tz": "America\/Campo_Grande"
  },
  "SIEA": {
      "icao": "SIEA",
      "iata": "",
      "name": "Fazenda Ibipora Airport",
      "city": "Guararapes",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1181,
      "lat": -21.4633331299,
      "lon": -50.7966651917,
      "tz": "America\/Sao_Paulo"
  },
  "SIEB": {
      "icao": "SIEB",
      "iata": "",
      "name": "Fazenda Carrapicho Airport",
      "city": "Dois Irmaos Do Buriti",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 932,
      "lat": -20.7469444275,
      "lon": -55.3572235107,
      "tz": "America\/Campo_Grande"
  },
  "SIEC": {
      "icao": "SIEC",
      "iata": "",
      "name": "Fazenda Anhumas II Airport",
      "city": "Presidente Eptacio",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 955,
      "lat": -22.0424995422,
      "lon": -52.2913894653,
      "tz": "America\/Sao_Paulo"
  },
  "SIED": {
      "icao": "SIED",
      "iata": "",
      "name": "Colonial Airport",
      "city": "Verdelandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1621,
      "lat": -15.4513893127,
      "lon": -43.4052772522,
      "tz": "America\/Sao_Paulo"
  },
  "SIEF": {
      "icao": "SIEF",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1722,
      "lat": -20.5733337402,
      "lon": -54.7169456482,
      "tz": "America\/Campo_Grande"
  },
  "SIEG": {
      "icao": "SIEG",
      "iata": "",
      "name": "Fazenda Santa Edwiges Airport",
      "city": "Taquarussu",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 951,
      "lat": -22.653055191,
      "lon": -53.3930549622,
      "tz": "America\/Campo_Grande"
  },
  "SIEJ": {
      "icao": "SIEJ",
      "iata": "",
      "name": "Monte Verde Airport",
      "city": "Nova Monte Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 821,
      "lat": -9.947221756,
      "lon": -57.4997215271,
      "tz": "America\/Cuiaba"
  },
  "SIEL": {
      "icao": "SIEL",
      "iata": "",
      "name": "Fazenda Varzea Funda Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 709,
      "lat": -16.5836105347,
      "lon": -57.7322235107,
      "tz": "America\/Cuiaba"
  },
  "SIEN": {
      "icao": "SIEN",
      "iata": "",
      "name": "Fazenda Sao Marcos Airport",
      "city": "General Carneiro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1503,
      "lat": -15.404999733,
      "lon": -53.3422203064,
      "tz": "America\/Cuiaba"
  },
  "SIET": {
      "icao": "SIET",
      "iata": "",
      "name": "Estancia Tara Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 607,
      "lat": -20.0883331299,
      "lon": -56.7247238159,
      "tz": "America\/Campo_Grande"
  },
  "SIEV": {
      "icao": "SIEV",
      "iata": "",
      "name": "Fazenda Boca da Onca Airport",
      "city": "Bodoquena",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1050,
      "lat": -20.7669448853,
      "lon": -56.706111908,
      "tz": "America\/Campo_Grande"
  },
  "SIEZ": {
      "icao": "SIEZ",
      "iata": "",
      "name": "Fazenda Pedra Branca Airport",
      "city": "Tibagi",
      "state": "Parana",
      "country": "BR",
      "elevation": 2953,
      "lat": -24.6241664886,
      "lon": -50.4591674805,
      "tz": "America\/Sao_Paulo"
  },
  "SIFA": {
      "icao": "SIFA",
      "iata": "",
      "name": "Fazenda Sete Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -19.7855567932,
      "lon": -56.4738883972,
      "tz": "America\/Campo_Grande"
  },
  "SIFC": {
      "icao": "SIFC",
      "iata": "",
      "name": "Fazenda Cachoeirinha Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 722,
      "lat": -20.3822212219,
      "lon": -56.2688903809,
      "tz": "America\/Campo_Grande"
  },
  "SIFD": {
      "icao": "SIFD",
      "iata": "",
      "name": "Fazenda Dois Irmaos Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 591,
      "lat": -16.38722229,
      "lon": -57.2886123657,
      "tz": "America\/Cuiaba"
  },
  "SIFE": {
      "icao": "SIFE",
      "iata": "",
      "name": "Fazenda Dinora Airport",
      "city": "Nova Fatima",
      "state": "Parana",
      "country": "BR",
      "elevation": 2100,
      "lat": -23.4163894653,
      "lon": -50.6008338928,
      "tz": "America\/Sao_Paulo"
  },
  "SIFF": {
      "icao": "SIFF",
      "iata": "",
      "name": "Fazenda Firme Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 233,
      "lat": -19.2594432831,
      "lon": -57.0116653442,
      "tz": "America\/Campo_Grande"
  },
  "SIFH": {
      "icao": "SIFH",
      "iata": "",
      "name": "Fazenda Santa Rita do Araguaia Airport",
      "city": "Santa Rita Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2464,
      "lat": -17.3252773285,
      "lon": -53.1969451904,
      "tz": "America\/Cuiaba"
  },
  "SIFJ": {
      "icao": "SIFJ",
      "iata": "",
      "name": "Fazenda Jurema Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 709,
      "lat": -21.8052787781,
      "lon": -54.2463874817,
      "tz": "America\/Campo_Grande"
  },
  "SIFM": {
      "icao": "SIFM",
      "iata": "",
      "name": "Fazenda Mudanca Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 499,
      "lat": -9.9122219086,
      "lon": -60.8619422913,
      "tz": "America\/Cuiaba"
  },
  "SIFN": {
      "icao": "SIFN",
      "iata": "",
      "name": "Fazenda Planura Airport",
      "city": "Autazes",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 188,
      "lat": -3.593611002,
      "lon": -59.6522216797,
      "tz": "America\/Manaus"
  },
  "SIFQ": {
      "icao": "SIFQ",
      "iata": "",
      "name": "Condominio Menega Airport",
      "city": "Flores Da Cunha",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2566,
      "lat": -29.0444431305,
      "lon": -51.1388893127,
      "tz": "America\/Sao_Paulo"
  },
  "SIFR": {
      "icao": "SIFR",
      "iata": "",
      "name": "Fazenda Romaria Airport",
      "city": "Romaria",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3297,
      "lat": -18.8705997467,
      "lon": -47.5593986511,
      "tz": "America\/Sao_Paulo"
  },
  "SIFT": {
      "icao": "SIFT",
      "iata": "",
      "name": "Fazenda Travessao Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 906,
      "lat": -16.1477775574,
      "lon": -60.1166687012,
      "tz": "America\/Cuiaba"
  },
  "SIFU": {
      "icao": "SIFU",
      "iata": "",
      "name": "Fazenda Ribeirao Airport",
      "city": "Barra Do Pirai",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 1319,
      "lat": -22.415555954,
      "lon": -43.7752761841,
      "tz": "America\/Sao_Paulo"
  },
  "SIFV": {
      "icao": "SIFV",
      "iata": "",
      "name": "Primo Bitti Airport",
      "city": "Aracruz",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 88,
      "lat": -19.8264007568,
      "lon": -40.1007995605,
      "tz": "America\/Sao_Paulo"
  },
  "SIFW": {
      "icao": "SIFW",
      "iata": "",
      "name": "Fazenda Sao Lourenco Airport",
      "city": "Corumba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 400,
      "lat": -17.7183322906,
      "lon": -57.0013885498,
      "tz": "America\/Campo_Grande"
  },
  "SIFY": {
      "icao": "SIFY",
      "iata": "",
      "name": "Fazenda Yndiana Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 607,
      "lat": -21.7783336639,
      "lon": -57.4958343506,
      "tz": "America\/Campo_Grande"
  },
  "SIGF": {
      "icao": "SIGF",
      "iata": "",
      "name": "Fazenda Jopejo Airport",
      "city": "Chapada Dos Guimaraes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1020,
      "lat": -15,
      "lon": -55.8250007629,
      "tz": "America\/Cuiaba"
  },
  "SIGL": {
      "icao": "SIGL",
      "iata": "",
      "name": "Fazenda Boa Sorte Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1099,
      "lat": -21.9152774811,
      "lon": -53.5175018311,
      "tz": "America\/Campo_Grande"
  },
  "SIGM": {
      "icao": "SIGM",
      "iata": "",
      "name": "Fazenda Sao Benedito Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 469,
      "lat": -16.2705554962,
      "lon": -57.86277771,
      "tz": "America\/Cuiaba"
  },
  "SIGN": {
      "icao": "SIGN",
      "iata": "",
      "name": "Asa Delta Airport",
      "city": "General Carneiro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1585,
      "lat": -15.5713891983,
      "lon": -53.2336120605,
      "tz": "America\/Cuiaba"
  },
  "SIGO": {
      "icao": "SIGO",
      "iata": "",
      "name": "Fazenda Araguari Airport",
      "city": "Gaucha Do Norte",
      "state": "",
      "country": "BR",
      "elevation": 2126,
      "lat": -13.3366670609,
      "lon": -53.9577789307,
      "tz": "America\/Cuiaba"
  },
  "SIGS": {
      "icao": "SIGS",
      "iata": "",
      "name": "Fazenda Granja Santiago Airport",
      "city": "Paracatu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1946,
      "lat": -17.1622219086,
      "lon": -46.6599998474,
      "tz": "America\/Sao_Paulo"
  },
  "SIGT": {
      "icao": "SIGT",
      "iata": "",
      "name": "Fazenda Goiabeira Airport",
      "city": "Itabera",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2260,
      "lat": -23.9894447327,
      "lon": -49.1791687012,
      "tz": "America\/Sao_Paulo"
  },
  "SIGU": {
      "icao": "SIGU",
      "iata": "",
      "name": "Fazenda Cafe no Bule Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2199,
      "lat": -19.8152770996,
      "lon": -53.8258323669,
      "tz": "America\/Campo_Grande"
  },
  "SIGW": {
      "icao": "SIGW",
      "iata": "",
      "name": "Estancia Miranda Airport",
      "city": "Caracol",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 640,
      "lat": -21.9144439697,
      "lon": -57.1438903809,
      "tz": "America\/Campo_Grande"
  },
  "SIGX": {
      "icao": "SIGX",
      "iata": "",
      "name": "Fazenda Gairova Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -11.1977777481,
      "lon": -58.2366676331,
      "tz": "America\/Cuiaba"
  },
  "SIGY": {
      "icao": "SIGY",
      "iata": "",
      "name": "Fazenda Progresso Airport",
      "city": "Sebastiao Leal",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1476,
      "lat": -7.5025000572,
      "lon": -44.2033348083,
      "tz": "America\/Fortaleza"
  },
  "SIHB": {
      "icao": "SIHB",
      "iata": "",
      "name": "Fazenda Soberana Airport",
      "city": "Grajau",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 417,
      "lat": -5.4558329582,
      "lon": -46.0625,
      "tz": "America\/Fortaleza"
  },
  "SIHG": {
      "icao": "SIHG",
      "iata": "",
      "name": "Fazenda Ponderosa Airport",
      "city": "Pium",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 656,
      "lat": -10.0716667175,
      "lon": -49.9955558777,
      "tz": "America\/Araguaina"
  },
  "SIHI": {
      "icao": "SIHI",
      "iata": "",
      "name": "Fazenda Itaipavas Airport",
      "city": "Picarras",
      "state": "Para",
      "country": "BR",
      "elevation": 633,
      "lat": -6.6163887978,
      "lon": -48.9958343506,
      "tz": "America\/Belem"
  },
  "SIHJ": {
      "icao": "SIHJ",
      "iata": "",
      "name": "Sitio Flyer Airport",
      "city": "Palmas",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 958,
      "lat": -10.1080560684,
      "lon": -48.3263893127,
      "tz": "America\/Araguaina"
  },
  "SIHN": {
      "icao": "SIHN",
      "iata": "",
      "name": "Estancia Vacadiga Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 936,
      "lat": -21.7986106873,
      "lon": -56.5713882446,
      "tz": "America\/Campo_Grande"
  },
  "SIHV": {
      "icao": "SIHV",
      "iata": "",
      "name": "Fazenda Boa Sorte Airport",
      "city": "Paracatu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2002,
      "lat": -17.2111110687,
      "lon": -46.6144447327,
      "tz": "America\/Sao_Paulo"
  },
  "SIHW": {
      "icao": "SIHW",
      "iata": "",
      "name": "Agrishow Airport",
      "city": "Ribeirao Preto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1781,
      "lat": -21.2188892365,
      "lon": -47.8763885498,
      "tz": "America\/Sao_Paulo"
  },
  "SIIA": {
      "icao": "SIIA",
      "iata": "",
      "name": "Fazenda Parana Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 653,
      "lat": -11.6999998093,
      "lon": -58.2180557251,
      "tz": "America\/Cuiaba"
  },
  "SIID": {
      "icao": "SIID",
      "iata": "",
      "name": "Crepurizao Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 695,
      "lat": -6.8200001717,
      "lon": -56.8422203064,
      "tz": "America\/Santarem"
  },
  "SIIE": {
      "icao": "SIIE",
      "iata": "",
      "name": "Fazenda Cachoeira Branca Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1733,
      "lat": -20.8919448853,
      "lon": -53.4041671753,
      "tz": "America\/Campo_Grande"
  },
  "SIIG": {
      "icao": "SIIG",
      "iata": "",
      "name": "Eliza Camargo de Arruda Botelho Airport",
      "city": "Rancharia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1756,
      "lat": -22.3575000763,
      "lon": -51.1008338928,
      "tz": "America\/Sao_Paulo"
  },
  "SIII": {
      "icao": "SIII",
      "iata": "0",
      "name": "Doutor Heraclito da Motta Luiz Airport",
      "city": "Guaira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1798,
      "lat": -20.4330997467,
      "lon": -48.2243995667,
      "tz": "America\/Sao_Paulo"
  },
  "SIIK": {
      "icao": "SIIK",
      "iata": "",
      "name": "Fazenda Santa Lidia Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1811,
      "lat": -20.7316665649,
      "lon": -53.6852798462,
      "tz": "America\/Campo_Grande"
  },
  "SIIM": {
      "icao": "SIIM",
      "iata": "",
      "name": "Fazenda Girua Airport",
      "city": "Rio Verde Do Mato Grosso",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1408,
      "lat": -18.8024997711,
      "lon": -54.8477783203,
      "tz": "America\/Campo_Grande"
  },
  "SIIN": {
      "icao": "SIIN",
      "iata": "",
      "name": "Independencia Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 951,
      "lat": -7.5949997902,
      "lon": -56.7027778625,
      "tz": "America\/Santarem"
  },
  "SIIQ": {
      "icao": "SIIQ",
      "iata": "",
      "name": "Fazenda Aracoxim Airport",
      "city": "Sonora",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2198,
      "lat": -17.3974990845,
      "lon": -53.7186126709,
      "tz": "America\/Campo_Grande"
  },
  "SIIS": {
      "icao": "SIIS",
      "iata": "",
      "name": "Fazenda Uberaba Airport",
      "city": "Vila Alta",
      "state": "Parana",
      "country": "BR",
      "elevation": 1100,
      "lat": -23.6499996185,
      "lon": -53.8302764893,
      "tz": "America\/Sao_Paulo"
  },
  "SIIU": {
      "icao": "SIIU",
      "iata": "",
      "name": "Santa Marta Airport",
      "city": "Campo Florido",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2218,
      "lat": -19.643611908,
      "lon": -48.7008323669,
      "tz": "America\/Sao_Paulo"
  },
  "SIIX": {
      "icao": "SIIX",
      "iata": "",
      "name": "Fazenda Corrego Fundo Airport",
      "city": "Agua Boa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1207,
      "lat": -13.7369441986,
      "lon": -52.5894432068,
      "tz": "America\/Cuiaba"
  },
  "SIIZ": {
      "icao": "SIIZ",
      "iata": "",
      "name": "Fazenda Chalana Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2001,
      "lat": -14.1644439697,
      "lon": -57.162776947,
      "tz": "America\/Cuiaba"
  },
  "SIJE": {
      "icao": "SIJE",
      "iata": "",
      "name": "Fazenda Joana Estancia Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 476,
      "lat": -20.0069446564,
      "lon": -57.3916664124,
      "tz": "America\/Campo_Grande"
  },
  "SIJG": {
      "icao": "SIJG",
      "iata": "",
      "name": "Fazenda Jaguarete Airport",
      "city": "Guararapes",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1290,
      "lat": -21.4433326721,
      "lon": -50.7233314514,
      "tz": "America\/Sao_Paulo"
  },
  "SIJJ": {
      "icao": "SIJJ",
      "iata": "",
      "name": "Fazenda Salamanca Airport",
      "city": "Aral Moreira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1181,
      "lat": -22.8086109161,
      "lon": -55.5333328247,
      "tz": "America\/Campo_Grande"
  },
  "SIJK": {
      "icao": "SIJK",
      "iata": "",
      "name": "Vazante Airport",
      "city": "Vazante",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2457,
      "lat": -17.9549999237,
      "lon": -46.8616676331,
      "tz": "America\/Sao_Paulo"
  },
  "SIJM": {
      "icao": "SIJM",
      "iata": "",
      "name": "Fazenda Santa Maria do Porto da Capivara Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 328,
      "lat": -17.144443512,
      "lon": -56.5977783203,
      "tz": "America\/Cuiaba"
  },
  "SIJO": {
      "icao": "SIJO",
      "iata": "",
      "name": "Fazenda Sao Joao do Jatobazinho Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 381,
      "lat": -18.577222,
      "lon": -57.511111,
      "tz": "America\/Campo_Grande"
  },
  "SIJR": {
      "icao": "SIJR",
      "iata": "",
      "name": "Ely Rego Airport",
      "city": "Balsa Nova",
      "state": "Parana",
      "country": "BR",
      "elevation": 2592,
      "lat": -25.5394439697,
      "lon": -49.5999984741,
      "tz": "America\/Sao_Paulo"
  },
  "SIJU": {
      "icao": "SIJU",
      "iata": "",
      "name": "Fazenda Areia Branca Airport",
      "city": "Juina",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1073,
      "lat": -11.7838888168,
      "lon": -58.4533348083,
      "tz": "America\/Cuiaba"
  },
  "SIJZ": {
      "icao": "SIJZ",
      "iata": "",
      "name": "Fazenda Vera Paz Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 801,
      "lat": -7.3947219849,
      "lon": -56.7647209167,
      "tz": "America\/Santarem"
  },
  "SIKB": {
      "icao": "SIKB",
      "iata": "",
      "name": "Agua Boa Airport",
      "city": "Agua Boa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2815,
      "lat": -14.009721756,
      "lon": -52.1502761841,
      "tz": "America\/Cuiaba"
  },
  "SIKC": {
      "icao": "SIKC",
      "iata": "",
      "name": "Colider Airport",
      "city": "Colider",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1266,
      "lat": -10.764166832,
      "lon": -55.4611091614,
      "tz": "America\/Cuiaba"
  },
  "SIKJ": {
      "icao": "SIKJ",
      "iata": "",
      "name": "Fazenda Cristalino Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 919,
      "lat": -9.6677780151,
      "lon": -55.9766654968,
      "tz": "America\/Cuiaba"
  },
  "SIKK": {
      "icao": "SIKK",
      "iata": "",
      "name": "Calciolandia Airport",
      "city": "Arcos",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2180,
      "lat": -20.2505569458,
      "lon": -45.6450004578,
      "tz": "America\/Sao_Paulo"
  },
  "SIKM": {
      "icao": "SIKM",
      "iata": "",
      "name": "Fazenda Viamao Airport",
      "city": "Arame",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 863,
      "lat": -5.2891669273,
      "lon": -46.0016670227,
      "tz": "America\/Fortaleza"
  },
  "SIKN": {
      "icao": "SIKN",
      "iata": "",
      "name": "Fazenda Bonanza Airport",
      "city": "Arame",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 928,
      "lat": -5.1572217941,
      "lon": -45.9155578613,
      "tz": "America\/Fortaleza"
  },
  "SIKO": {
      "icao": "SIKO",
      "iata": "",
      "name": "Fazenda Pison Airport",
      "city": "Maues",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 541,
      "lat": -5.0199999809,
      "lon": -57.7802772522,
      "tz": "America\/Manaus"
  },
  "SIKP": {
      "icao": "SIKP",
      "iata": "",
      "name": "Fazenda Santa Teresa Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 404,
      "lat": -18.303611,
      "lon": -57.506944,
      "tz": "America\/Campo_Grande"
  },
  "SIKQ": {
      "icao": "SIKQ",
      "iata": "",
      "name": "Fazenda Samauma Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 633,
      "lat": -7.2361111641,
      "lon": -57.1058311462,
      "tz": "America\/Santarem"
  },
  "SIKX": {
      "icao": "SIKX",
      "iata": "",
      "name": "Fazenda Continental Airport",
      "city": "Colombia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1476,
      "lat": -20.2411117554,
      "lon": -48.6583328247,
      "tz": "America\/Sao_Paulo"
  },
  "SIKY": {
      "icao": "SIKY",
      "iata": "",
      "name": "Fazenda Beira Rio Airport",
      "city": "Costa Rica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2100,
      "lat": -18.7194442749,
      "lon": -53.3375015259,
      "tz": "America\/Campo_Grande"
  },
  "SIKZ": {
      "icao": "SIKZ",
      "iata": "",
      "name": "Fazenda Olho D`Agua Airport",
      "city": "Parnarama",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 390,
      "lat": -5.6666669846,
      "lon": -43.5349998474,
      "tz": "America\/Fortaleza"
  },
  "SILA": {
      "icao": "SILA",
      "iata": "",
      "name": "Fazenda Santa Ada Airport",
      "city": "Jatei",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1247,
      "lat": -22.7583332062,
      "lon": -53.844165802,
      "tz": "America\/Campo_Grande"
  },
  "SILB": {
      "icao": "SILB",
      "iata": "",
      "name": "Aviador Mario Luiz Spinelli Airport",
      "city": "Chapada Dos Guimaraes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1247,
      "lat": -15.0033330917,
      "lon": -55.5011100769,
      "tz": "America\/Cuiaba"
  },
  "SILC": {
      "icao": "SILC",
      "iata": "LVR",
      "name": "Municipal Bom Futuro Airport",
      "city": "Lucas do Rio Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1345,
      "lat": -13.0378611111,
      "lon": -55.95025,
      "tz": "America\/Cuiaba"
  },
  "SILD": {
      "icao": "SILD",
      "iata": "",
      "name": "Fazenda Colorado Airport",
      "city": "Bodoquena",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 512,
      "lat": -20.6305561066,
      "lon": -56.7719421387,
      "tz": "America\/Campo_Grande"
  },
  "SILG": {
      "icao": "SILG",
      "iata": "",
      "name": "Fazenda Panama Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1690,
      "lat": -13.76,
      "lon": -58.74722,
      "tz": "America\/Cuiaba"
  },
  "SILI": {
      "icao": "SILI",
      "iata": "",
      "name": "Ilha dos Porcos Grandes Airport",
      "city": "Angra Dos Reis",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 197,
      "lat": -23.058889389,
      "lon": -44.3144454956,
      "tz": "America\/Sao_Paulo"
  },
  "SILJ": {
      "icao": "SILJ",
      "iata": "",
      "name": "Do Gaucho Airport",
      "city": "Laranjal Do Jari",
      "state": "Amapa",
      "country": "BR",
      "elevation": 135,
      "lat": -0.8333330154,
      "lon": -52.0525016785,
      "tz": "America\/Belem"
  },
  "SILM": {
      "icao": "SILM",
      "iata": "",
      "name": "Fazenda Regalito Airport",
      "city": "Flores De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1598,
      "lat": -14.4705562592,
      "lon": -46.8725013733,
      "tz": "America\/Sao_Paulo"
  },
  "SILN": {
      "icao": "SILN",
      "iata": "",
      "name": "Fazenda Rio Capim Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 325,
      "lat": -3.5586109161,
      "lon": -48.6563873291,
      "tz": "America\/Belem"
  },
  "SILQ": {
      "icao": "SILQ",
      "iata": "",
      "name": "Clube de Ultraleves Aeroleve Airport",
      "city": "Cascavel",
      "state": "Parana",
      "country": "BR",
      "elevation": 2132,
      "lat": -24.9266662598,
      "lon": -53.4983329773,
      "tz": "America\/Sao_Paulo"
  },
  "SILS": {
      "icao": "SILS",
      "iata": "",
      "name": "Agua Branca Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 667,
      "lat": -6.390832901,
      "lon": -56.2849998474,
      "tz": "America\/Santarem"
  },
  "SILT": {
      "icao": "SILT",
      "iata": "",
      "name": "Fazenda Trez Irmaos Airport",
      "city": "Santa Fe Do Sul",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1460,
      "lat": -20.2277774811,
      "lon": -50.968334198,
      "tz": "America\/Sao_Paulo"
  },
  "SILU": {
      "icao": "SILU",
      "iata": "",
      "name": "Fazenda Cristo Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 427,
      "lat": -19.8658332825,
      "lon": -56.9872207642,
      "tz": "America\/Campo_Grande"
  },
  "SILW": {
      "icao": "SILW",
      "iata": "",
      "name": "Fazenda Mata Assombrada Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 912,
      "lat": -21.727222,
      "lon": -56.509167,
      "tz": "America\/Campo_Grande"
  },
  "SILX": {
      "icao": "SILX",
      "iata": "",
      "name": "Fazenda Jandaia Airport",
      "city": "Peixoto De Azevedo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1070,
      "lat": -10.51,
      "lon": -53.65,
      "tz": "America\/Cuiaba"
  },
  "SILY": {
      "icao": "SILY",
      "iata": "",
      "name": "Fazenda Tres Marias Airport",
      "city": "Vila Alta",
      "state": "Parana",
      "country": "BR",
      "elevation": 1300,
      "lat": -23.5119438171,
      "lon": -53.8041687012,
      "tz": "America\/Sao_Paulo"
  },
  "SILZ": {
      "icao": "SILZ",
      "iata": "",
      "name": "Fazenda Mariad Airport",
      "city": "Juazeiro",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1197,
      "lat": -9.3922224045,
      "lon": -40.3574981689,
      "tz": "America\/Bahia"
  },
  "SIMA": {
      "icao": "SIMA",
      "iata": "",
      "name": "Fazenda Mandiore Airport",
      "city": "Corumba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 299,
      "lat": -17.927778244,
      "lon": -57.624168396,
      "tz": "America\/Campo_Grande"
  },
  "SIME": {
      "icao": "SIME",
      "iata": "",
      "name": "Comandante Carlos Inacio Agnes Airport",
      "city": "Davinopolis",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 443,
      "lat": -5.576666832,
      "lon": -47.4227790833,
      "tz": "America\/Fortaleza"
  },
  "SIMF": {
      "icao": "SIMF",
      "iata": "",
      "name": "Fazenda Sao Miguel Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3287,
      "lat": -15.9730558395,
      "lon": -46.7094421387,
      "tz": "America\/Sao_Paulo"
  },
  "SIMG": {
      "icao": "SIMG",
      "iata": "",
      "name": "Fazenda Seis Palmas Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 312,
      "lat": -21.0994434357,
      "lon": -57.7158317566,
      "tz": "America\/Campo_Grande"
  },
  "SIMI": {
      "icao": "SIMI",
      "iata": "",
      "name": "Fazenda Boa Vista Airport",
      "city": "Tacuru",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1231,
      "lat": -23.6369438171,
      "lon": -55.1844444275,
      "tz": "America\/Campo_Grande"
  },
  "SIMJ": {
      "icao": "SIMJ",
      "iata": "",
      "name": "Capinopolis - Aviacao Agricola Buttarello Ltda. Airport",
      "city": "Capinopolis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2005,
      "lat": -18.6592006683,
      "lon": -49.5475997925,
      "tz": "America\/Sao_Paulo"
  },
  "SIMK": {
      "icao": "SIMK",
      "iata": "FRC",
      "name": "Franca Airport",
      "city": "Franca",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 3292,
      "lat": -20.5921993256,
      "lon": -47.382900238,
      "tz": "America\/Sao_Paulo"
  },
  "SIML": {
      "icao": "SIML",
      "iata": "",
      "name": "Codenorte Airport",
      "city": "Moju",
      "state": "Para",
      "country": "BR",
      "elevation": 220,
      "lat": -3.285556078,
      "lon": -49.206111908,
      "tz": "America\/Belem"
  },
  "SIMO": {
      "icao": "SIMO",
      "iata": "",
      "name": "Posto de Protecao Ambiental Santo Andre Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 365,
      "lat": -16.7708320618,
      "lon": -56.1725006104,
      "tz": "America\/Cuiaba"
  },
  "SIMQ": {
      "icao": "SIMQ",
      "iata": "",
      "name": "Dois de Maio Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 600,
      "lat": -18.2088890076,
      "lon": -55.7530555725,
      "tz": "America\/Campo_Grande"
  },
  "SIMR": {
      "icao": "SIMR",
      "iata": "",
      "name": "Fazenda Reunidas Airport",
      "city": "Rio Verde",
      "state": "Goias",
      "country": "BR",
      "elevation": 2362,
      "lat": -17.7525005341,
      "lon": -51.1519432068,
      "tz": "America\/Sao_Paulo"
  },
  "SIMU": {
      "icao": "SIMU",
      "iata": "",
      "name": "Fazenda Mutum Airport",
      "city": "Anaurilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 978,
      "lat": -22.2824993134,
      "lon": -52.8427772522,
      "tz": "America\/Campo_Grande"
  },
  "SIMV": {
      "icao": "SIMV",
      "iata": "",
      "name": "Fazenda Mata Velha Airport",
      "city": "Uberaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2733,
      "lat": -19.8383331299,
      "lon": -47.8497238159,
      "tz": "America\/Sao_Paulo"
  },
  "SIMW": {
      "icao": "SIMW",
      "iata": "",
      "name": "Fazenda Retiro do Cervo I Airport",
      "city": "Jaraguari",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1562,
      "lat": -20.3544445038,
      "lon": -53.9677772522,
      "tz": "America\/Campo_Grande"
  },
  "SIMX": {
      "icao": "SIMX",
      "iata": "",
      "name": "Fazenda Jangada Airport",
      "city": "Jangada",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 722,
      "lat": -15.2408332825,
      "lon": -56.5780563354,
      "tz": "America\/Cuiaba"
  },
  "SIMY": {
      "icao": "SIMY",
      "iata": "",
      "name": "Fazenda Porto Oculto Airport",
      "city": "Itaquirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 972,
      "lat": -23.2124996185,
      "lon": -54.0952796936,
      "tz": "America\/Campo_Grande"
  },
  "SIMZ": {
      "icao": "SIMZ",
      "iata": "",
      "name": "Fazenda Primeiro de Maio Airport",
      "city": "Sonora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 584,
      "lat": -17.8755569458,
      "lon": -54.9411125183,
      "tz": "America\/Campo_Grande"
  },
  "SINA": {
      "icao": "SINA",
      "iata": "",
      "name": "Sao Francisco Airport",
      "city": "Sao Pedro Do Parana",
      "state": "Parana",
      "country": "BR",
      "elevation": 1122,
      "lat": -22.7888889313,
      "lon": -53.1977767944,
      "tz": "America\/Sao_Paulo"
  },
  "SINC": {
      "icao": "SINC",
      "iata": "",
      "name": "Nova Conceicao Agroindustrial Airport",
      "city": "Baiao",
      "state": "Para",
      "country": "BR",
      "elevation": 49,
      "lat": -2.9230558872,
      "lon": -49.7852783203,
      "tz": "America\/Belem"
  },
  "SING": {
      "icao": "SING",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Nova Granada",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1739,
      "lat": -20.5566673279,
      "lon": -49.2883338928,
      "tz": "America\/Sao_Paulo"
  },
  "SINH": {
      "icao": "SINH",
      "iata": "",
      "name": "Fazenda Esperanca Airport",
      "city": "Novo Horizonte Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 804,
      "lat": -22.6494445801,
      "lon": -53.5644454956,
      "tz": "America\/Campo_Grande"
  },
  "SINJ": {
      "icao": "SINJ",
      "iata": "",
      "name": "Fazenda Novo Horizonte - TATTERSAL Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1205,
      "lat": -18.3680553436,
      "lon": -54.7769432068,
      "tz": "America\/Campo_Grande"
  },
  "SINK": {
      "icao": "SINK",
      "iata": "",
      "name": "Fazenda Negrinha Airport",
      "city": "Parapua",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1582,
      "lat": -21.8758335114,
      "lon": -50.8716659546,
      "tz": "America\/Sao_Paulo"
  },
  "SINM": {
      "icao": "SINM",
      "iata": "",
      "name": "Fazenda Itanhanga Airport",
      "city": "Nova Mutum",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 938,
      "lat": -13.101111412,
      "lon": -56.5299987793,
      "tz": "America\/Cuiaba"
  },
  "SINN": {
      "icao": "SINN",
      "iata": "",
      "name": "Fazenda Nova Holanda Airport",
      "city": "Balsas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1657,
      "lat": -8.8069438934,
      "lon": -46.3588905334,
      "tz": "America\/Fortaleza"
  },
  "SINO": {
      "icao": "SINO",
      "iata": "",
      "name": "Fazenda Mapisa Airport",
      "city": "Bom Jesus Das Selvas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 634,
      "lat": -4.4130558968,
      "lon": -46.5180549622,
      "tz": "America\/Fortaleza"
  },
  "SINP": {
      "icao": "SINP",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Jardim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -21.5080566406,
      "lon": -56.4247207642,
      "tz": "America\/Campo_Grande"
  },
  "SINQ": {
      "icao": "SINQ",
      "iata": "",
      "name": "Laranjal Airport",
      "city": "Laranjal Do Jari",
      "state": "Amapa",
      "country": "BR",
      "elevation": 250,
      "lat": -0.7947220206,
      "lon": -52.488609314,
      "tz": "America\/Belem"
  },
  "SINR": {
      "icao": "SINR",
      "iata": "",
      "name": "Fazenda Recanto Airport",
      "city": "Alcinopolis",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1231,
      "lat": -18.0113887787,
      "lon": -54.0161094666,
      "tz": "America\/Campo_Grande"
  },
  "SINS": {
      "icao": "SINS",
      "iata": "",
      "name": "Fazenda Cachoeira do Lontra Airport",
      "city": "Campo Grande",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1211,
      "lat": -21.2883338928,
      "lon": -53.7105560303,
      "tz": "America\/Campo_Grande"
  },
  "SINT": {
      "icao": "SINT",
      "iata": "",
      "name": "Fazenda Santa Adelia Airport",
      "city": "Santa Isabel",
      "state": "Goias",
      "country": "BR",
      "elevation": 2251,
      "lat": -15.1947221756,
      "lon": -49.3794441223,
      "tz": "America\/Sao_Paulo"
  },
  "SINU": {
      "icao": "SINU",
      "iata": "",
      "name": "Fazenda Xerez Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 936,
      "lat": -21.9244441986,
      "lon": -56.7086105347,
      "tz": "America\/Campo_Grande"
  },
  "SINV": {
      "icao": "SINV",
      "iata": "",
      "name": "Fazenda Santa Rita Airport",
      "city": "Custodia",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1634,
      "lat": -8.2283334732,
      "lon": -37.7563896179,
      "tz": "America\/Recife"
  },
  "SINZ": {
      "icao": "SINZ",
      "iata": "",
      "name": "Xanadu Airport",
      "city": "Fernandes Pinheiro",
      "state": "Parana",
      "country": "BR",
      "elevation": 2796,
      "lat": -25.4380569458,
      "lon": -50.5277786255,
      "tz": "America\/Sao_Paulo"
  },
  "SIOA": {
      "icao": "SIOA",
      "iata": "",
      "name": "Fazenda Perdizes Airport",
      "city": "Lassance",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1969,
      "lat": -17.6725006104,
      "lon": -44.5930557251,
      "tz": "America\/Sao_Paulo"
  },
  "SIOB": {
      "icao": "SIOB",
      "iata": "",
      "name": "Fazenda Guanabara Airport",
      "city": "Andradina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1362,
      "lat": -20.8211116791,
      "lon": -51.2705574036,
      "tz": "America\/Sao_Paulo"
  },
  "SIOC": {
      "icao": "SIOC",
      "iata": "",
      "name": "Santo Expedito Airport",
      "city": "Nova Independencia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1300,
      "lat": -21.1422214508,
      "lon": -51.5463905334,
      "tz": "America\/Sao_Paulo"
  },
  "SIOD": {
      "icao": "SIOD",
      "iata": "",
      "name": "Fazenda Santa Helena Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 952,
      "lat": -21.9972229004,
      "lon": -53.4247207642,
      "tz": "America\/Campo_Grande"
  },
  "SIOE": {
      "icao": "SIOE",
      "iata": "",
      "name": "Mocelin Airport",
      "city": "Dois Vizinhos",
      "state": "Parana",
      "country": "BR",
      "elevation": 1850,
      "lat": -25.793333,
      "lon": -53.034722,
      "tz": "America\/Sao_Paulo"
  },
  "SIOF": {
      "icao": "SIOF",
      "iata": "",
      "name": "Fazenda Cachoeira Airport",
      "city": "Nova Alvorada Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1247,
      "lat": -21.4311103821,
      "lon": -54.3769454956,
      "tz": "America\/Campo_Grande"
  },
  "SIOI": {
      "icao": "SIOI",
      "iata": "",
      "name": "Rio Arraias Airport",
      "city": "Uniao Do Sul",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 3773,
      "lat": -11.76222229,
      "lon": -54.3452796936,
      "tz": "America\/Cuiaba"
  },
  "SIOJ": {
      "icao": "SIOJ",
      "iata": "",
      "name": "Fazenda Soroteca Airport",
      "city": "Sao Jose Dos Quatro Marcos",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 749,
      "lat": -15.6313886642,
      "lon": -58.3083343506,
      "tz": "America\/Cuiaba"
  },
  "SIOK": {
      "icao": "SIOK",
      "iata": "",
      "name": "Fazenda Agua Doce Airport",
      "city": "Nobres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1313,
      "lat": -14.6422224045,
      "lon": -56.2388877869,
      "tz": "America\/Cuiaba"
  },
  "SIOL": {
      "icao": "SIOL",
      "iata": "",
      "name": "Fazenda Pontal Airport",
      "city": "Corumbaiba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1706,
      "lat": -18.3225002289,
      "lon": -48.844165802,
      "tz": "America\/Sao_Paulo"
  },
  "SIOM": {
      "icao": "SIOM",
      "iata": "",
      "name": "Rio Ouro Airport",
      "city": "Uniao Do Sul",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 3741,
      "lat": -11.5388889313,
      "lon": -54.1938896179,
      "tz": "America\/Cuiaba"
  },
  "SIOO": {
      "icao": "SIOO",
      "iata": "",
      "name": "Fazenda Piquiri Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 273,
      "lat": -22.0238895416,
      "lon": -57.9027786255,
      "tz": "America\/Campo_Grande"
  },
  "SIOP": {
      "icao": "SIOP",
      "iata": "",
      "name": "Fazenda Londrina II Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 272,
      "lat": -21.9722213745,
      "lon": -57.850276947,
      "tz": "America\/Campo_Grande"
  },
  "SIOQ": {
      "icao": "SIOQ",
      "iata": "",
      "name": "Fazenda Mato Alto Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1313,
      "lat": -23.5038890839,
      "lon": -54.3141670227,
      "tz": "America\/Campo_Grande"
  },
  "SIOR": {
      "icao": "SIOR",
      "iata": "",
      "name": "Fazenda Sao Miguel Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 358,
      "lat": -18.0980567932,
      "lon": -57.1533317566,
      "tz": "America\/Campo_Grande"
  },
  "SIOS": {
      "icao": "SIOS",
      "iata": "",
      "name": "Fazenda Santa Josefa Airport",
      "city": "Jatei",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1313,
      "lat": -22.769443512,
      "lon": -53.963054657,
      "tz": "America\/Campo_Grande"
  },
  "SIOT": {
      "icao": "SIOT",
      "iata": "",
      "name": "Fazenda Santa Verginia Airport",
      "city": "Santa Rita Do Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1092,
      "lat": -21.4894447327,
      "lon": -52.3838882446,
      "tz": "America\/Campo_Grande"
  },
  "SIOU": {
      "icao": "SIOU",
      "iata": "",
      "name": "Fazenda Santa Helena Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 378,
      "lat": -18.2980556488,
      "lon": -57.0147209167,
      "tz": "America\/Campo_Grande"
  },
  "SIOV": {
      "icao": "SIOV",
      "iata": "",
      "name": "Taua Airport",
      "city": "Prado",
      "state": "Bahia",
      "country": "BR",
      "elevation": 44,
      "lat": -16.9013881683,
      "lon": -39.1588897705,
      "tz": "America\/Bahia"
  },
  "SIOZ": {
      "icao": "SIOZ",
      "iata": "",
      "name": "Fazenda Santa Fe Airport",
      "city": "Santana Do Araguaia",
      "state": "",
      "country": "BR",
      "elevation": 591,
      "lat": -9.5880556107,
      "lon": -50.3572235107,
      "tz": "America\/Belem"
  },
  "SIPA": {
      "icao": "SIPA",
      "iata": "",
      "name": "Aeroclube de Sao Jose do Rio Pardo Airport",
      "city": "Sao Jose Do Rio Pardo",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2849,
      "lat": -21.6447219849,
      "lon": -46.9333343506,
      "tz": "America\/Sao_Paulo"
  },
  "SIPB": {
      "icao": "SIPB",
      "iata": "",
      "name": "CAVU - Clube de Aviacao Ultraleve Airport",
      "city": "Raposa",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 109,
      "lat": -2.4416670799,
      "lon": -44.1247215271,
      "tz": "America\/Fortaleza"
  },
  "SIPC": {
      "icao": "SIPC",
      "iata": "",
      "name": "Portal do Ceu Airport",
      "city": "Belem",
      "state": "Para",
      "country": "BR",
      "elevation": 16,
      "lat": -1.3147219419,
      "lon": -48.4294433594,
      "tz": "America\/Belem"
  },
  "SIPE": {
      "icao": "SIPE",
      "iata": "",
      "name": "Itapessoca Airport",
      "city": "Goiana",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 16,
      "lat": -7.6560101509,
      "lon": -34.8564987183,
      "tz": "America\/Recife"
  },
  "SIPG": {
      "icao": "SIPG",
      "iata": "",
      "name": "Fazenda Agua Preta Airport",
      "city": "Cocalinho",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 794,
      "lat": -14.1380996704,
      "lon": -51.4664001465,
      "tz": "America\/Cuiaba"
  },
  "SIPH": {
      "icao": "SIPH",
      "iata": "",
      "name": "Fazenda Palmeiras Airport",
      "city": "Caseara",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 722,
      "lat": -9.6019439697,
      "lon": -49.7738876343,
      "tz": "America\/Araguaina"
  },
  "SIPJ": {
      "icao": "SIPJ",
      "iata": "",
      "name": "Fazenda Marreco Airport",
      "city": "Sao Goncalo Do Abaete",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1969,
      "lat": -18.1086101532,
      "lon": -45.2155570984,
      "tz": "America\/Sao_Paulo"
  },
  "SIPK": {
      "icao": "SIPK",
      "iata": "",
      "name": "SESC Pantanal Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 348,
      "lat": -16.4974002838,
      "lon": -56.4221992493,
      "tz": "America\/Cuiaba"
  },
  "SIPL": {
      "icao": "SIPL",
      "iata": "",
      "name": "Fazenda Entre Serras Airport",
      "city": "Planalto Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1824,
      "lat": -14.6222219467,
      "lon": -54.4766654968,
      "tz": "America\/Cuiaba"
  },
  "SIPN": {
      "icao": "SIPN",
      "iata": "",
      "name": "Fazenda Progresso Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -21.5100002289,
      "lon": -57.6905555725,
      "tz": "America\/Campo_Grande"
  },
  "SIPO": {
      "icao": "SIPO",
      "iata": "",
      "name": "Sebastiao Jose Pereira Airport",
      "city": "Condeuba",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2260,
      "lat": -14.9241666794,
      "lon": -41.9566688538,
      "tz": "America\/Bahia"
  },
  "SIPQ": {
      "icao": "SIPQ",
      "iata": "",
      "name": "Fazenda Rancho Estrela Airport",
      "city": "Sao Miguel Do Passa Quatro",
      "state": "Goias",
      "country": "BR",
      "elevation": 3002,
      "lat": -16.9983329773,
      "lon": -48.7380561829,
      "tz": "America\/Sao_Paulo"
  },
  "SIPR": {
      "icao": "SIPR",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Parauna",
      "state": "Goias",
      "country": "BR",
      "elevation": 2451,
      "lat": -17.0011100769,
      "lon": -50.7427787781,
      "tz": "America\/Sao_Paulo"
  },
  "SIPU": {
      "icao": "SIPU",
      "iata": "",
      "name": "Fazenda Lagoa da Capa Airport",
      "city": "Perdizes",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3567,
      "lat": -19.4483337402,
      "lon": -47.36277771,
      "tz": "America\/Sao_Paulo"
  },
  "SIPY": {
      "icao": "SIPY",
      "iata": "",
      "name": "Ambrosio Airport",
      "city": "Portel",
      "state": "Para",
      "country": "BR",
      "elevation": 96,
      "lat": -1.9813890457,
      "lon": -50.8113899231,
      "tz": "America\/Belem"
  },
  "SIQA": {
      "icao": "SIQA",
      "iata": "",
      "name": "Senador Antonio Farias Airport",
      "city": "Baia Formosa",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 171,
      "lat": -6.4074997902,
      "lon": -35.0447235107,
      "tz": "America\/Fortaleza"
  },
  "SIQB": {
      "icao": "SIQB",
      "iata": "",
      "name": "Monte Verde Airport",
      "city": "Portel",
      "state": "Para",
      "country": "BR",
      "elevation": 249,
      "lat": -3.2741670609,
      "lon": -50.3213882446,
      "tz": "America\/Belem"
  },
  "SIQC": {
      "icao": "SIQC",
      "iata": "",
      "name": "Fazenda Paraiso Airport",
      "city": "Costa Rica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1592,
      "lat": -19.1572227478,
      "lon": -52.9958343506,
      "tz": "America\/Campo_Grande"
  },
  "SIQD": {
      "icao": "SIQD",
      "iata": "",
      "name": "Fazenda Estancia Regina Airport",
      "city": "Terenos",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1968,
      "lat": -20.640832901,
      "lon": -54.7905578613,
      "tz": "America\/Campo_Grande"
  },
  "SIQE": {
      "icao": "SIQE",
      "iata": "",
      "name": "Botelho Airport",
      "city": "Brasilia",
      "state": "Goias",
      "country": "BR",
      "elevation": 3399,
      "lat": -15.9416666031,
      "lon": -47.7202796936,
      "tz": "America\/Sao_Paulo"
  },
  "SIQG": {
      "icao": "SIQG",
      "iata": "",
      "name": "Fazenda Morro Alto Airport",
      "city": "Nova Canaa Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 958,
      "lat": -10.506111145,
      "lon": -55.7105560303,
      "tz": "America\/Cuiaba"
  },
  "SIQI": {
      "icao": "SIQI",
      "iata": "",
      "name": "Aerolis Airport",
      "city": "Palmares Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 20,
      "lat": -30.5422210693,
      "lon": -50.4708328247,
      "tz": "America\/Sao_Paulo"
  },
  "SIQJ": {
      "icao": "SIQJ",
      "iata": "",
      "name": "Morro do Chapeu II Airport",
      "city": "Chapada Dos Guimaraes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 989,
      "lat": -14.9582996368,
      "lon": -55.8007011414,
      "tz": "America\/Cuiaba"
  },
  "SIQK": {
      "icao": "SIQK",
      "iata": "",
      "name": "Rodrigues Airport",
      "city": "Nova Odessa",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1909,
      "lat": -22.7813892365,
      "lon": -47.3341674805,
      "tz": "America\/Sao_Paulo"
  },
  "SIQM": {
      "icao": "SIQM",
      "iata": "",
      "name": "Fazenda Bom Jardim Airport",
      "city": "Porteirao",
      "state": "Goias",
      "country": "BR",
      "elevation": 1693,
      "lat": -17.9002780914,
      "lon": -50.1236114502,
      "tz": "America\/Sao_Paulo"
  },
  "SIQN": {
      "icao": "SIQN",
      "iata": "",
      "name": "Fazenda Flor da Mata Airport",
      "city": "Sao Jose Do Xingu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1264,
      "lat": -10.7166671753,
      "lon": -52.8019447327,
      "tz": "America\/Cuiaba"
  },
  "SIQO": {
      "icao": "SIQO",
      "iata": "",
      "name": "Fazenda Mercedes Estancia Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 499,
      "lat": -18.3341674805,
      "lon": -55.4850006104,
      "tz": "America\/Campo_Grande"
  },
  "SIQQ": {
      "icao": "SIQQ",
      "iata": "",
      "name": "Aero Resende Airport",
      "city": "Bom Jesus",
      "state": "Goias",
      "country": "BR",
      "elevation": 6825,
      "lat": -18.1336116791,
      "lon": -49.7961120605,
      "tz": "America\/Sao_Paulo"
  },
  "SIQR": {
      "icao": "SIQR",
      "iata": "",
      "name": "Fazenda Triangulo Airport",
      "city": "Bonopolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 804,
      "lat": -13.5819444656,
      "lon": -49.8205566406,
      "tz": "America\/Sao_Paulo"
  },
  "SIQS": {
      "icao": "SIQS",
      "iata": "",
      "name": "Fazenda Terra do Sol Airport",
      "city": "Porto Estrela",
      "state": "",
      "country": "BR",
      "elevation": 722,
      "lat": -15.3763885498,
      "lon": -57.1297225952,
      "tz": "America\/Cuiaba"
  },
  "SIQU": {
      "icao": "SIQU",
      "iata": "",
      "name": "Alcoolvare Airport",
      "city": "Aparecida Do Taboado",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1280,
      "lat": -20.0863895416,
      "lon": -51.3088874817,
      "tz": "America\/Campo_Grande"
  },
  "SIQV": {
      "icao": "SIQV",
      "iata": "",
      "name": "Fazenda Sol Aberto Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1687,
      "lat": -12.8950004578,
      "lon": -58.5800018311,
      "tz": "America\/Cuiaba"
  },
  "SIQW": {
      "icao": "SIQW",
      "iata": "",
      "name": "Fazenda Santa Maria do Jauru Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 755,
      "lat": -15.8163890839,
      "lon": -58.5530548096,
      "tz": "America\/Cuiaba"
  },
  "SIQX": {
      "icao": "SIQX",
      "iata": "",
      "name": "Fazenda Barranquinho Airport",
      "city": "Porto Estrela",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 558,
      "lat": -15.6366672516,
      "lon": -57.4680557251,
      "tz": "America\/Cuiaba"
  },
  "SIQY": {
      "icao": "SIQY",
      "iata": "",
      "name": "Fazenda Amizade Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1264,
      "lat": -12.6280555725,
      "lon": -55.9449996948,
      "tz": "America\/Cuiaba"
  },
  "SIQZ": {
      "icao": "SIQZ",
      "iata": "",
      "name": "Fazenda Carolina Airport",
      "city": "Buritis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1871,
      "lat": -15.6491670609,
      "lon": -46.4430541992,
      "tz": "America\/Sao_Paulo"
  },
  "SIRA": {
      "icao": "SIRA",
      "iata": "",
      "name": "Fazenda Magiana Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1575,
      "lat": -12.3886108398,
      "lon": -55.2427787781,
      "tz": "America\/Cuiaba"
  },
  "SIRB": {
      "icao": "SIRB",
      "iata": "",
      "name": "Fazenda Mimoso Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1476,
      "lat": -21.7711105347,
      "lon": -54.3816680908,
      "tz": "America\/Campo_Grande"
  },
  "SIRC": {
      "icao": "SIRC",
      "iata": "",
      "name": "Fazenda Jarina Airport",
      "city": "Peixoto De Azevedo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -10.3252782822,
      "lon": -53.5852775574,
      "tz": "America\/Cuiaba"
  },
  "SIRD": {
      "icao": "SIRD",
      "iata": "",
      "name": "Rancho Jatoba Airport",
      "city": "Novo Santo Antonio",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 821,
      "lat": -12.2344436646,
      "lon": -50.9183311462,
      "tz": "America\/Cuiaba"
  },
  "SIRE": {
      "icao": "SIRE",
      "iata": "",
      "name": "Fazenda Rancho Estrela IV Airport",
      "city": "Sao Domingos",
      "state": "Goias",
      "country": "BR",
      "elevation": 2549,
      "lat": -13.6519441605,
      "lon": -46.5413894653,
      "tz": "America\/Sao_Paulo"
  },
  "SIRF": {
      "icao": "SIRF",
      "iata": "",
      "name": "Jose Mucio Monteiro Airport",
      "city": "Rio Formoso",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 479,
      "lat": -8.631111145,
      "lon": -35.2716674805,
      "tz": "America\/Recife"
  },
  "SIRG": {
      "icao": "SIRG",
      "iata": "",
      "name": "Fazenda Esperanca Airport",
      "city": "Alto Da Boa Vista",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1214,
      "lat": -12.0336112976,
      "lon": -51.6177787781,
      "tz": "America\/Cuiaba"
  },
  "SIRH": {
      "icao": "SIRH",
      "iata": "",
      "name": "Fazenda Morada da Lua Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1450,
      "lat": -18.2672214508,
      "lon": -54.3136100769,
      "tz": "America\/Campo_Grande"
  },
  "SIRI": {
      "icao": "SIRI",
      "iata": "",
      "name": "Barra Grande Airport",
      "city": "Marau",
      "state": "Bahia",
      "country": "BR",
      "elevation": 23,
      "lat": -13.9036111832,
      "lon": -38.9358329773,
      "tz": "America\/Bahia"
  },
  "SIRJ": {
      "icao": "SIRJ",
      "iata": "",
      "name": "Caima Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 115,
      "lat": -4.3035898209,
      "lon": -56.2181015015,
      "tz": "America\/Santarem"
  },
  "SIRN": {
      "icao": "SIRN",
      "iata": "",
      "name": "Fazenda Jao Airport",
      "city": "Nova Xavantina",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 854,
      "lat": -14.75833,
      "lon": -51.98972,
      "tz": "America\/Cuiaba"
  },
  "SIRQ": {
      "icao": "SIRQ",
      "iata": "",
      "name": "Fazenda Maranata Airport",
      "city": "Chupinguaia",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1000,
      "lat": -12.773888588,
      "lon": -60.8694458008,
      "tz": "America\/Porto_Velho"
  },
  "SIRR": {
      "icao": "SIRR",
      "iata": "",
      "name": "Lagoa dos Coqueiros Airport",
      "city": "Paranaita",
      "state": "",
      "country": "BR",
      "elevation": 1280,
      "lat": -9.2355556488,
      "lon": -56.98777771,
      "tz": "America\/Cuiaba"
  },
  "SIRS": {
      "icao": "SIRS",
      "iata": "",
      "name": "Fazenda Sao Sebastiao Airport",
      "city": "Campo Florido",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2461,
      "lat": -19.6424999237,
      "lon": -48.7894439697,
      "tz": "America\/Sao_Paulo"
  },
  "SIRT": {
      "icao": "SIRT",
      "iata": "",
      "name": "Marca de Casco Airport",
      "city": "Sao Jose Do Rio Claro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1158,
      "lat": -13.7274999619,
      "lon": -57.0216674805,
      "tz": "America\/Cuiaba"
  },
  "SIRU": {
      "icao": "SIRU",
      "iata": "",
      "name": "Fazenda Bahia Airport",
      "city": "Pedra Preta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 6234,
      "lat": -16.9213886261,
      "lon": -54.0741653442,
      "tz": "America\/Cuiaba"
  },
  "SIRV": {
      "icao": "SIRV",
      "iata": "",
      "name": "Fazenda Morro Branco Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 400,
      "lat": -16.1180553436,
      "lon": -58.7658348083,
      "tz": "America\/Cuiaba"
  },
  "SIRW": {
      "icao": "SIRW",
      "iata": "",
      "name": "Fazenda Serra Vermelha Airport",
      "city": "Sambaiba",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1687,
      "lat": -6.8727779388,
      "lon": -45.3027763367,
      "tz": "America\/Fortaleza"
  },
  "SISB": {
      "icao": "SISB",
      "iata": "",
      "name": "Fazenda Eldorado - Agropecuaria Paleana S\/A Airport",
      "city": "Bom Jesus Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1108,
      "lat": -12.1541671753,
      "lon": -51.6869430542,
      "tz": "America\/Cuiaba"
  },
  "SISD": {
      "icao": "SISD",
      "iata": "",
      "name": "Fazenda Sao Sebastiao Airport",
      "city": "Aparecida Do Rio Doce",
      "state": "Goias",
      "country": "BR",
      "elevation": 1982,
      "lat": -18.1480560303,
      "lon": -51.2716674805,
      "tz": "America\/Sao_Paulo"
  },
  "SISE": {
      "icao": "SISE",
      "iata": "",
      "name": "Fazenda Kanaxue Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 650,
      "lat": -12.015832901,
      "lon": -50.8666687012,
      "tz": "America\/Cuiaba"
  },
  "SISF": {
      "icao": "SISF",
      "iata": "",
      "name": "Fazenda Santa Feliciana Airport",
      "city": "Bonito",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2198,
      "lat": -20.941110611,
      "lon": -56.6733322144,
      "tz": "America\/Campo_Grande"
  },
  "SISI": {
      "icao": "SISI",
      "iata": "",
      "name": "Fazenda Sete Ilhas Airport",
      "city": "Acreuna",
      "state": "Goias",
      "country": "BR",
      "elevation": 1720,
      "lat": -17.6341667175,
      "lon": -50.4705543518,
      "tz": "America\/Sao_Paulo"
  },
  "SISJ": {
      "icao": "SISJ",
      "iata": "",
      "name": "Fazenda Sao Jose do Piquiri Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 427,
      "lat": -17.4541664124,
      "lon": -55.5591659546,
      "tz": "America\/Campo_Grande"
  },
  "SISL": {
      "icao": "SISL",
      "iata": "",
      "name": "Fazenda Santa Lucia Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 509,
      "lat": -16.8941669464,
      "lon": -55.9072227478,
      "tz": "America\/Cuiaba"
  },
  "SISN": {
      "icao": "SISN",
      "iata": "",
      "name": "Fazenda Nossa Senhora Aparecida Airport",
      "city": "Sonora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2001,
      "lat": -17.5450000763,
      "lon": -53.8938903809,
      "tz": "America\/Campo_Grande"
  },
  "SISO": {
      "icao": "SISO",
      "iata": "",
      "name": "Fazenda Santo Ambrosio Airport",
      "city": "Chaves",
      "state": "Para",
      "country": "BR",
      "elevation": 20,
      "lat": -0.0916669965,
      "lon": -49.5805549622,
      "tz": "America\/Belem"
  },
  "SISP": {
      "icao": "SISP",
      "iata": "0",
      "name": "Santa Paula Airport",
      "city": "Ipigua",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1860,
      "lat": -20.7038993835,
      "lon": -49.3913993835,
      "tz": "America\/Sao_Paulo"
  },
  "SISS": {
      "icao": "SISS",
      "iata": "",
      "name": "Fazenda Lagoa do Campo Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1542,
      "lat": -20.384721756,
      "lon": -53.4011116028,
      "tz": "America\/Campo_Grande"
  },
  "SISU": {
      "icao": "SISU",
      "iata": "",
      "name": "Fazenda Pontal do Lagoa Airport",
      "city": "Santa Rita Do Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1264,
      "lat": -20.8700008392,
      "lon": -53.1549987793,
      "tz": "America\/Campo_Grande"
  },
  "SISW": {
      "icao": "SISW",
      "iata": "",
      "name": "Fazenda Mato Grosso Airport",
      "city": "Campos de Julio",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1690,
      "lat": -13.82278,
      "lon": -59.16278,
      "tz": "America\/Cuiaba"
  },
  "SISX": {
      "icao": "SISX",
      "iata": "",
      "name": "Santa Terezinha Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 361,
      "lat": -19.3772220612,
      "lon": -56.0561103821,
      "tz": "America\/Campo_Grande"
  },
  "SISY": {
      "icao": "SISY",
      "iata": "",
      "name": "Piraquara Airport",
      "city": "Piraquara",
      "state": "Parana",
      "country": "BR",
      "elevation": 3212,
      "lat": -25.4513893127,
      "lon": -49.1438903809,
      "tz": "America\/Sao_Paulo"
  },
  "SISZ": {
      "icao": "SISZ",
      "iata": "",
      "name": "Santa Felicidade Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 821,
      "lat": -5.5758328438,
      "lon": -56.6397209167,
      "tz": "America\/Santarem"
  },
  "SITA": {
      "icao": "SITA",
      "iata": "",
      "name": "Nossa Senhora da Conceicao Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 958,
      "lat": -7.2202777863,
      "lon": -56.1666679382,
      "tz": "America\/Santarem"
  },
  "SITB": {
      "icao": "SITB",
      "iata": "",
      "name": "Fazenda Touro Branco Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 951,
      "lat": -23.0141677856,
      "lon": -53.7874984741,
      "tz": "America\/Campo_Grande"
  },
  "SITC": {
      "icao": "SITC",
      "iata": "",
      "name": "Carmine Castaldo Airport",
      "city": "Tabapora",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1889,
      "lat": -11.3022222519,
      "lon": -56.8366661072,
      "tz": "America\/Cuiaba"
  },
  "SITD": {
      "icao": "SITD",
      "iata": "",
      "name": "Cara Preta Airport",
      "city": "Itaituba",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 155,
      "lat": -5.1061110497,
      "lon": -57.4824981689,
      "tz": "America\/Santarem"
  },
  "SITF": {
      "icao": "SITF",
      "iata": "",
      "name": "Fazenda Deposito Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 365,
      "lat": 4.1083297729,
      "lon": -60.7332992554,
      "tz": "America\/Boa_Vista"
  },
  "SITH": {
      "icao": "SITH",
      "iata": "",
      "name": "Fazenda Itaipu Airport",
      "city": "Turvelandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1644,
      "lat": -17.6736106873,
      "lon": -50.3880577087,
      "tz": "America\/Sao_Paulo"
  },
  "SITJ": {
      "icao": "SITJ",
      "iata": "",
      "name": "Fazenda Sao Gabriel Airport",
      "city": "Balsas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 998,
      "lat": -7.4905557632,
      "lon": -46.144443512,
      "tz": "America\/Fortaleza"
  },
  "SITL": {
      "icao": "SITL",
      "iata": "",
      "name": "Fazenda Flores Airport",
      "city": "Tamboril",
      "state": "Ceara",
      "country": "BR",
      "elevation": 1148,
      "lat": -5.049167,
      "lon": -40.234167,
      "tz": "America\/Fortaleza"
  },
  "SITN": {
      "icao": "SITN",
      "iata": "",
      "name": "Fazenda Santo Antonio Diamandore Airport",
      "city": "Corumba",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 493,
      "lat": -18.2430553436,
      "lon": -57.5561103821,
      "tz": "America\/Campo_Grande"
  },
  "SITO": {
      "icao": "SITO",
      "iata": "",
      "name": "Muricoca Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 361,
      "lat": -7.1541671753,
      "lon": -56.6361122131,
      "tz": "America\/Santarem"
  },
  "SITR": {
      "icao": "SITR",
      "iata": "",
      "name": "Fazenda Corrego Limpo Airport",
      "city": "Terenos",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1509,
      "lat": -20.173333,
      "lon": -55.069444,
      "tz": "America\/Campo_Grande"
  },
  "SITT": {
      "icao": "SITT",
      "iata": "",
      "name": "Fazenda Guaira Airport",
      "city": "Balsas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1900,
      "lat": -7.0908331871,
      "lon": -45.9541664124,
      "tz": "America\/Fortaleza"
  },
  "SITU": {
      "icao": "SITU",
      "iata": "",
      "name": "Fazenda Santa Genoveva Airport",
      "city": "Turvelandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1526,
      "lat": -17.9400005341,
      "lon": -50.3422203064,
      "tz": "America\/Sao_Paulo"
  },
  "SITV": {
      "icao": "SITV",
      "iata": "",
      "name": "Fazenda Chapadao Airport",
      "city": "Santa Rita Do Trivelato",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1450,
      "lat": -13.4108333588,
      "lon": -55.6738891602,
      "tz": "America\/Cuiaba"
  },
  "SITW": {
      "icao": "SITW",
      "iata": "",
      "name": "Fazenda Santa Luzia Airport",
      "city": "Sao Raimundo Das Mangabeiras",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1720,
      "lat": -6.8233327866,
      "lon": -45.411945343,
      "tz": "America\/Fortaleza"
  },
  "SITX": {
      "icao": "SITX",
      "iata": "",
      "name": "Fazenda Chapeu de Pano Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 519,
      "lat": -19.7744445801,
      "lon": -55.5216674805,
      "tz": "America\/Campo_Grande"
  },
  "SITY": {
      "icao": "SITY",
      "iata": "",
      "name": "Fazenda Sao Geraldo Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 486,
      "lat": -19.4038887024,
      "lon": -55.86277771,
      "tz": "America\/Campo_Grande"
  },
  "SITZ": {
      "icao": "SITZ",
      "iata": "",
      "name": "Fazenda MRV Airport",
      "city": "Monte Azul",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1713,
      "lat": -14.9177780151,
      "lon": -43.3597221375,
      "tz": "America\/Sao_Paulo"
  },
  "SIUA": {
      "icao": "SIUA",
      "iata": "",
      "name": "Fazenda Barra do Lagoa Airport",
      "city": "Santa Rita Do Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -20.8727779388,
      "lon": -53.2108345032,
      "tz": "America\/Campo_Grande"
  },
  "SIUB": {
      "icao": "SIUB",
      "iata": "",
      "name": "Aerojaco Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1510,
      "lat": -13.5050001144,
      "lon": -55.1508331299,
      "tz": "America\/Cuiaba"
  },
  "SIUC": {
      "icao": "SIUC",
      "iata": "",
      "name": "Selva de Pedra Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 391,
      "lat": -7.2263889313,
      "lon": -56.6708335876,
      "tz": "America\/Santarem"
  },
  "SIUF": {
      "icao": "SIUF",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Peixoto De Azevedo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1231,
      "lat": -10.1569442749,
      "lon": -53.7016677856,
      "tz": "America\/Cuiaba"
  },
  "SIUG": {
      "icao": "SIUG",
      "iata": "",
      "name": "Fazenda Santa Irene Airport",
      "city": "Sandovalina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1116,
      "lat": -22.4950008392,
      "lon": -51.9094429016,
      "tz": "America\/Sao_Paulo"
  },
  "SIUH": {
      "icao": "SIUH",
      "iata": "",
      "name": "Fazenda Santa Ana Airport",
      "city": "Itauba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1038,
      "lat": -11.1397218704,
      "lon": -55.8797225952,
      "tz": "America\/Cuiaba"
  },
  "SIUJ": {
      "icao": "SIUJ",
      "iata": "",
      "name": "Pista Santa Tereza Airport",
      "city": "Itaituba",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 401,
      "lat": -5.1858329773,
      "lon": -57.5844421387,
      "tz": "America\/Santarem"
  },
  "SIUK": {
      "icao": "SIUK",
      "iata": "",
      "name": "Serra Verde Airport",
      "city": "Jacareacanga",
      "state": "Para",
      "country": "BR",
      "elevation": 840,
      "lat": -6.9250001907,
      "lon": -56.9913902283,
      "tz": "America\/Santarem"
  },
  "SIUL": {
      "icao": "SIUL",
      "iata": "",
      "name": "Agrosserra Airport",
      "city": "Sao Raimundo Das Mangabeiras",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1926,
      "lat": -6.7755560875,
      "lon": -45.8916664124,
      "tz": "America\/Fortaleza"
  },
  "SIUM": {
      "icao": "SIUM",
      "iata": "",
      "name": "Fazenda Planeste Airport",
      "city": "Balsas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1874,
      "lat": -8.5561113358,
      "lon": -46.8499984741,
      "tz": "America\/Araguaina"
  },
  "SIUO": {
      "icao": "SIUO",
      "iata": "",
      "name": "Fazenda Santa Adelia Airport",
      "city": "Sao Jose Do Xingu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1034,
      "lat": -10.7219438553,
      "lon": -52.2858314514,
      "tz": "America\/Cuiaba"
  },
  "SIUQ": {
      "icao": "SIUQ",
      "iata": "",
      "name": "Fazenda Primavera Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1660,
      "lat": -20.9775009155,
      "lon": -54.3341674805,
      "tz": "America\/Campo_Grande"
  },
  "SIUR": {
      "icao": "SIUR",
      "iata": "",
      "name": "Fazenda Ribeirao Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1549,
      "lat": -19.6075000763,
      "lon": -53.4997215271,
      "tz": "America\/Campo_Grande"
  },
  "SIUS": {
      "icao": "SIUS",
      "iata": "",
      "name": "Fazenda Dona Rosa S. Rezek Airport",
      "city": "Jatei",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1240,
      "lat": -22.7147216797,
      "lon": -54.0447235107,
      "tz": "America\/Campo_Grande"
  },
  "SIUT": {
      "icao": "SIUT",
      "iata": "",
      "name": "Sao Paulo do Piaui Airport",
      "city": "Prado",
      "state": "Bahia",
      "country": "BR",
      "elevation": 82,
      "lat": -17.012778,
      "lon": -39.179722,
      "tz": "America\/Bahia"
  },
  "SIUV": {
      "icao": "SIUV",
      "iata": "",
      "name": "Fazenda Sao Matheus Airport",
      "city": "Selviria",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1365,
      "lat": -20.3177776337,
      "lon": -51.7836112976,
      "tz": "America\/Campo_Grande"
  },
  "SIUW": {
      "icao": "SIUW",
      "iata": "",
      "name": "Candote Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1093,
      "lat": -20.8391990662,
      "lon": -52.3016014099,
      "tz": "America\/Campo_Grande"
  },
  "SIUX": {
      "icao": "SIUX",
      "iata": "",
      "name": "Pista Comunidade Sao Domingos Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 985,
      "lat": -6.4222221375,
      "lon": -56.0505561829,
      "tz": "America\/Santarem"
  },
  "SIUY": {
      "icao": "SIUY",
      "iata": "",
      "name": "Santos Dumont Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 870,
      "lat": -7.0127778053,
      "lon": -55.875,
      "tz": "America\/Santarem"
  },
  "SIUZ": {
      "icao": "SIUZ",
      "iata": "",
      "name": "Boa Esperanca Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 788,
      "lat": -7.6613888741,
      "lon": -55.9602775574,
      "tz": "America\/Santarem"
  },
  "SIVC": {
      "icao": "SIVC",
      "iata": "",
      "name": "Fazenda Iporanga Airport",
      "city": "Mutunopolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 1231,
      "lat": -13.7588891983,
      "lon": -49.4772224426,
      "tz": "America\/Sao_Paulo"
  },
  "SIVD": {
      "icao": "SIVD",
      "iata": "",
      "name": "Fazenda dos Tres Rios Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1723,
      "lat": -16.9449996948,
      "lon": -46.2737998962,
      "tz": "America\/Sao_Paulo"
  },
  "SIVE": {
      "icao": "SIVE",
      "iata": "",
      "name": "Fazenda Royal Airport",
      "city": "Itapetininga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2447,
      "lat": -23.506944,
      "lon": -48,
      "tz": "America\/Sao_Paulo"
  },
  "SIVG": {
      "icao": "SIVG",
      "iata": "",
      "name": "Clube Aerodesportivo Selva Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1280,
      "lat": -11.8755559921,
      "lon": -55.444442749,
      "tz": "America\/Cuiaba"
  },
  "SIVJ": {
      "icao": "SIVJ",
      "iata": "",
      "name": "Cirrus Sociedade Aerodesportiva Airport",
      "city": "Jaboticatubas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2402,
      "lat": -19.4527778625,
      "lon": -43.899723053,
      "tz": "America\/Sao_Paulo"
  },
  "SIVK": {
      "icao": "SIVK",
      "iata": "",
      "name": "Crispiano Airport",
      "city": "Monte Alegre De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1657,
      "lat": -13.2227783203,
      "lon": -46.9530563354,
      "tz": "America\/Sao_Paulo"
  },
  "SIVL": {
      "icao": "SIVL",
      "iata": "",
      "name": "Sitio Flor de Lys Airport",
      "city": "Junco Do Serido",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 1641,
      "lat": -6.9452781677,
      "lon": -36.6725006104,
      "tz": "America\/Fortaleza"
  },
  "SIVN": {
      "icao": "SIVN",
      "iata": "",
      "name": "Fazenda Sansara Airport",
      "city": "Montes Claros",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2149,
      "lat": -16.599993,
      "lon": -43.768225,
      "tz": "America\/Sao_Paulo"
  },
  "SIVO": {
      "icao": "SIVO",
      "iata": "",
      "name": "Fazenda Malu Airport",
      "city": "Bom Jesus Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1378,
      "lat": -12.448056221,
      "lon": -51.7741661072,
      "tz": "America\/Cuiaba"
  },
  "SIVP": {
      "icao": "SIVP",
      "iata": "",
      "name": "Fazenda Bandeirante Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 899,
      "lat": -14.4463891983,
      "lon": -50.5361099243,
      "tz": "America\/Sao_Paulo"
  },
  "SIVQ": {
      "icao": "SIVQ",
      "iata": "",
      "name": "Fazenda Annalu Airport",
      "city": "Deodapolis",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -22.0822219849,
      "lon": -54.2011108398,
      "tz": "America\/Campo_Grande"
  },
  "SIVR": {
      "icao": "SIVR",
      "iata": "",
      "name": "Fazenda Sao Raimundo Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 909,
      "lat": -7.625,
      "lon": -56.7400016785,
      "tz": "America\/Santarem"
  },
  "SIVU": {
      "icao": "SIVU",
      "iata": "",
      "name": "Joao Moteiro Airport",
      "city": "Vila Velha",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 16,
      "lat": -20.4227771759,
      "lon": -40.3333320618,
      "tz": "America\/Sao_Paulo"
  },
  "SIVV": {
      "icao": "SIVV",
      "iata": "",
      "name": "Fazenda Bororeo Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 489,
      "lat": -16.9274997711,
      "lon": -56.2330551147,
      "tz": "America\/Cuiaba"
  },
  "SIVW": {
      "icao": "SIVW",
      "iata": "",
      "name": "Banaer de Jacupiranga Airport",
      "city": "Jacupiranga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 89,
      "lat": -24.7152786255,
      "lon": -48.0263900757,
      "tz": "America\/Sao_Paulo"
  },
  "SIVY": {
      "icao": "SIVY",
      "iata": "",
      "name": "Fazenda Sapucai Airport",
      "city": "Tasso Fragoso",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1575,
      "lat": -8.2988891602,
      "lon": -45.7633323669,
      "tz": "America\/Fortaleza"
  },
  "SIVZ": {
      "icao": "SIVZ",
      "iata": "",
      "name": "Mata-Fome Airport",
      "city": "Itaqui",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 220,
      "lat": -29.1908321381,
      "lon": -56.5486106873,
      "tz": "America\/Sao_Paulo"
  },
  "SIWA": {
      "icao": "SIWA",
      "iata": "",
      "name": "Fazenda Estrela do Aripuana Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1060,
      "lat": -10.7488889694,
      "lon": -59.3686103821,
      "tz": "America\/Cuiaba"
  },
  "SIWB": {
      "icao": "SIWB",
      "iata": "",
      "name": "Fazenda Sperafico Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1965,
      "lat": -23.1902770996,
      "lon": -55.2866668701,
      "tz": "America\/Campo_Grande"
  },
  "SIWC": {
      "icao": "SIWC",
      "iata": "",
      "name": "Fazenda Santa Rita Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2054,
      "lat": -14.1338891983,
      "lon": -57.3661117554,
      "tz": "America\/Cuiaba"
  },
  "SIWE": {
      "icao": "SIWE",
      "iata": "",
      "name": "Nelson Pizzani Airport",
      "city": "Monte Carlo",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 3223,
      "lat": -27.2126998901,
      "lon": -50.9565010071,
      "tz": "America\/Sao_Paulo"
  },
  "SIWF": {
      "icao": "SIWF",
      "iata": "",
      "name": "Algodoeira Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2035,
      "lat": -14.1855564117,
      "lon": -57.5505561829,
      "tz": "America\/Cuiaba"
  },
  "SIWG": {
      "icao": "SIWG",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Rosario Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 735,
      "lat": -14.556388855,
      "lon": -55.8199996948,
      "tz": "America\/Cuiaba"
  },
  "SIWH": {
      "icao": "SIWH",
      "iata": "",
      "name": "Fazenda Frigorifico Atlas Airport",
      "city": "Santana Do Araguaia",
      "state": "Para",
      "country": "BR",
      "elevation": 2533,
      "lat": -9.4125003815,
      "lon": -50.4255561829,
      "tz": "America\/Belem"
  },
  "SIWI": {
      "icao": "SIWI",
      "iata": "",
      "name": "Guapirama Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1700,
      "lat": -13.9341669083,
      "lon": -57.2427787781,
      "tz": "America\/Cuiaba"
  },
  "SIWJ": {
      "icao": "SIWJ",
      "iata": "",
      "name": "Luiz Eduardo Magalhaes Airport",
      "city": "Campo Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2533,
      "lat": -15.5319442749,
      "lon": -55.1377792358,
      "tz": "America\/Cuiaba"
  },
  "SIWK": {
      "icao": "SIWK",
      "iata": "",
      "name": "Fazenda Santa Fe Airport",
      "city": "Santa Rita Do Trivelato",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1575,
      "lat": -14.0513887405,
      "lon": -55.3836097717,
      "tz": "America\/Cuiaba"
  },
  "SIWL": {
      "icao": "SIWL",
      "iata": "",
      "name": "Fazenda Espigao Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1690,
      "lat": -14.125,
      "lon": -56.9361114502,
      "tz": "America\/Cuiaba"
  },
  "SIWM": {
      "icao": "SIWM",
      "iata": "",
      "name": "Fazenda Bandeirantes Airport",
      "city": "Goiatuba",
      "state": "Goias",
      "country": "BR",
      "elevation": 1969,
      "lat": -17.9913883209,
      "lon": -49.5352783203,
      "tz": "America\/Sao_Paulo"
  },
  "SIWN": {
      "icao": "SIWN",
      "iata": "",
      "name": "Fazenda Vargem das Flores Airport",
      "city": "Joviania",
      "state": "Goias",
      "country": "BR",
      "elevation": 1477,
      "lat": -17.8427772522,
      "lon": -49.581943512,
      "tz": "America\/Sao_Paulo"
  },
  "SIWP": {
      "icao": "SIWP",
      "iata": "",
      "name": "Fazenda Agua Doce Airport",
      "city": "Laguna Carapa",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1201,
      "lat": -22.8672218323,
      "lon": -54.4408340454,
      "tz": "America\/Campo_Grande"
  },
  "SIWQ": {
      "icao": "SIWQ",
      "iata": "",
      "name": "Fazenda Mateira Airport",
      "city": "Santa Rita Do Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1234,
      "lat": -20.9661102295,
      "lon": -53.0783348083,
      "tz": "America\/Campo_Grande"
  },
  "SIWR": {
      "icao": "SIWR",
      "iata": "",
      "name": "Fazenda Amazonas Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 985,
      "lat": -13.7997217178,
      "lon": -50.4891662598,
      "tz": "America\/Sao_Paulo"
  },
  "SIWT": {
      "icao": "SIWT",
      "iata": "",
      "name": "Santa Helena Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 699,
      "lat": -7.8402781487,
      "lon": -56.6594429016,
      "tz": "America\/Santarem"
  },
  "SIWU": {
      "icao": "SIWU",
      "iata": "",
      "name": "Fazenda Nossa Senhora da Candelaria Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 365,
      "lat": -18.518611908,
      "lon": -57.5983314514,
      "tz": "America\/Campo_Grande"
  },
  "SIWV": {
      "icao": "SIWV",
      "iata": "",
      "name": "Boa Vista Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 699,
      "lat": -7.8561110497,
      "lon": -56.7144432068,
      "tz": "America\/Santarem"
  },
  "SIWY": {
      "icao": "SIWY",
      "iata": "",
      "name": "Fazenda Campo Augusta Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 483,
      "lat": -17.509721756,
      "lon": -55.7688903809,
      "tz": "America\/Campo_Grande"
  },
  "SIWZ": {
      "icao": "SIWZ",
      "iata": "",
      "name": "Fazenda Fazendinha Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 312,
      "lat": -19.4855556488,
      "lon": -56.4866676331,
      "tz": "America\/Campo_Grande"
  },
  "SIXA": {
      "icao": "SIXA",
      "iata": "",
      "name": "Fazenda Uniao Suzanopolis Airport",
      "city": "Suzanopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1398,
      "lat": -20.5316677094,
      "lon": -51.0916671753,
      "tz": "America\/Sao_Paulo"
  },
  "SIXB": {
      "icao": "SIXB",
      "iata": "",
      "name": "Fazenda Ilha Verde Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 312,
      "lat": -18.5055561066,
      "lon": -57.4022216797,
      "tz": "America\/Campo_Grande"
  },
  "SIXD": {
      "icao": "SIXD",
      "iata": "",
      "name": "Fazenda da Paz Airport",
      "city": "Santana Do Livramento",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 913,
      "lat": -30.8394432068,
      "lon": -55.5672225952,
      "tz": "America\/Montevideo"
  },
  "SIXE": {
      "icao": "SIXE",
      "iata": "",
      "name": "Aeroclube de Eldorado do Sul Airport",
      "city": "Eldorado Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 33,
      "lat": -30.05027771,
      "lon": -51.443611145,
      "tz": "America\/Sao_Paulo"
  },
  "SIXF": {
      "icao": "SIXF",
      "iata": "",
      "name": "Fazenda Soledade Airport",
      "city": "Campo Alegre De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 2717,
      "lat": -17.3833332062,
      "lon": -47.5355567932,
      "tz": "America\/Sao_Paulo"
  },
  "SIXG": {
      "icao": "SIXG",
      "iata": "",
      "name": "Fazenda Pouso Alegre Airport",
      "city": "Catalao",
      "state": "Goias",
      "country": "BR",
      "elevation": 2812,
      "lat": -18.0277786255,
      "lon": -47.4597206116,
      "tz": "America\/Sao_Paulo"
  },
  "SIXI": {
      "icao": "SIXI",
      "iata": "",
      "name": "Fazenda Antonio Andrade Airport",
      "city": "Itororo",
      "state": "Bahia",
      "country": "BR",
      "elevation": 827,
      "lat": -15.1802778244,
      "lon": -40.0927772522,
      "tz": "America\/Bahia"
  },
  "SIXL": {
      "icao": "SIXL",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Canarana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1152,
      "lat": -13.3105564117,
      "lon": -52.8922233582,
      "tz": "America\/Cuiaba"
  },
  "SIXM": {
      "icao": "SIXM",
      "iata": "",
      "name": "Comunidade Sao Leopoldo do Tapajos Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 492,
      "lat": -4.9722218513,
      "lon": -56.9208335876,
      "tz": "America\/Santarem"
  },
  "SIXO": {
      "icao": "SIXO",
      "iata": "",
      "name": "Fazenda Nossa Senhora de Fatima Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1070,
      "lat": -13.9844999313,
      "lon": -59.6584014893,
      "tz": "America\/Cuiaba"
  },
  "SIXP": {
      "icao": "SIXP",
      "iata": "",
      "name": "Fazenda Paiaguas Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2038,
      "lat": -14.0722999573,
      "lon": -57.4506988525,
      "tz": "America\/Cuiaba"
  },
  "SIXQ": {
      "icao": "SIXQ",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Paranhos",
      "state": "Canindeyu",
      "country": "BR",
      "elevation": 1500,
      "lat": -23.8472213745,
      "lon": -55.4358329773,
      "tz": "America\/Asuncion"
  },
  "SIXV": {
      "icao": "SIXV",
      "iata": "",
      "name": "Fazenda Rancho do Planalto Airport",
      "city": "Alcinopolis",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2789,
      "lat": -18.1394443512,
      "lon": -53.3647232056,
      "tz": "America\/Campo_Grande"
  },
  "SIXW": {
      "icao": "SIXW",
      "iata": "",
      "name": "Cimento Poty Airport",
      "city": "Caapora",
      "state": "",
      "country": "BR",
      "elevation": 1,
      "lat": -7.5316672325,
      "lon": -34.8616676331,
      "tz": "America\/Fortaleza"
  },
  "SIXX": {
      "icao": "SIXX",
      "iata": "",
      "name": "Usinas Braco Norte Airport",
      "city": "Guaranta Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1159,
      "lat": -9.6583328247,
      "lon": -54.9544448853,
      "tz": "America\/Cuiaba"
  },
  "SIXZ": {
      "icao": "SIXZ",
      "iata": "",
      "name": "Fazenda Spartacus Airport",
      "city": "Taquarivai",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2346,
      "lat": -24,
      "lon": -48.6083335876,
      "tz": "America\/Sao_Paulo"
  },
  "SIYE": {
      "icao": "SIYE",
      "iata": "",
      "name": "Fazenda Campo Helio Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 411,
      "lat": -19.0963897705,
      "lon": -55.5697212219,
      "tz": "America\/Campo_Grande"
  },
  "SIYF": {
      "icao": "SIYF",
      "iata": "",
      "name": "Fazenda Araras Airport",
      "city": "Itai",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2166,
      "lat": -23.3083324432,
      "lon": -49.0891685486,
      "tz": "America\/Sao_Paulo"
  },
  "SIYH": {
      "icao": "SIYH",
      "iata": "",
      "name": "Fazenda Cruzeiro Airport",
      "city": "Rio Verde Do Mato Grosso",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 508,
      "lat": -18.9819431305,
      "lon": -55.2916679382,
      "tz": "America\/Campo_Grande"
  },
  "SIYK": {
      "icao": "SIYK",
      "iata": "",
      "name": "Fazenda Sorriso Metalico Airport",
      "city": "Urutai",
      "state": "Goias",
      "country": "BR",
      "elevation": 2605,
      "lat": -17.4566669464,
      "lon": -48.2152786255,
      "tz": "America\/Sao_Paulo"
  },
  "SIYN": {
      "icao": "SIYN",
      "iata": "",
      "name": "Eletro Primavera LTDA Airport",
      "city": "Pimenta Bueno",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 749,
      "lat": -11.9008331299,
      "lon": -61.230556488,
      "tz": "America\/Porto_Velho"
  },
  "SIYO": {
      "icao": "SIYO",
      "iata": "",
      "name": "Fazenda Goias Barreiro Airport",
      "city": "Montividiu",
      "state": "Goias",
      "country": "BR",
      "elevation": 2848,
      "lat": -17.2555561066,
      "lon": -51.306388855,
      "tz": "America\/Sao_Paulo"
  },
  "SIYP": {
      "icao": "SIYP",
      "iata": "",
      "name": "Fazenda Shangri-la Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1057,
      "lat": -10.282500267,
      "lon": -56.469165802,
      "tz": "America\/Cuiaba"
  },
  "SIYQ": {
      "icao": "SIYQ",
      "iata": "",
      "name": "Tapura Airport",
      "city": "Tapura",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1310,
      "lat": -12.7916669846,
      "lon": -56.4986114502,
      "tz": "America\/Cuiaba"
  },
  "SIYR": {
      "icao": "SIYR",
      "iata": "",
      "name": "Santa Terezinha Airport",
      "city": "Tapajara",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2199,
      "lat": -28.0522212982,
      "lon": -51.9608345032,
      "tz": "America\/Sao_Paulo"
  },
  "SIYS": {
      "icao": "SIYS",
      "iata": "",
      "name": "Helibras Airport",
      "city": "Itajuba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2759,
      "lat": -22.4308338165,
      "lon": -45.4791679382,
      "tz": "America\/Sao_Paulo"
  },
  "SIYT": {
      "icao": "SIYT",
      "iata": "",
      "name": "Mamuru Airport",
      "city": "Juruti",
      "state": "Para",
      "country": "BR",
      "elevation": 99,
      "lat": -3.1169440746,
      "lon": -56.5861091614,
      "tz": "America\/Santarem"
  },
  "SIYW": {
      "icao": "SIYW",
      "iata": "",
      "name": "Fazenda Salvacao Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 601,
      "lat": -5.9522218704,
      "lon": -56.5433311462,
      "tz": "America\/Santarem"
  },
  "SIYX": {
      "icao": "SIYX",
      "iata": "",
      "name": "Fazenda Varginha Airport",
      "city": "Andradas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 4200,
      "lat": -21.9500007629,
      "lon": -46.5499992371,
      "tz": "America\/Sao_Paulo"
  },
  "SIYY": {
      "icao": "SIYY",
      "iata": "",
      "name": "Ilha do Caracara Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 610,
      "lat": -17.4585990906,
      "lon": -56.8405990601,
      "tz": "America\/Campo_Grande"
  },
  "SIZA": {
      "icao": "SIZA",
      "iata": "",
      "name": "Parque Manoelito Argolo Airport",
      "city": "Entre Rios",
      "state": "Bahia",
      "country": "BR",
      "elevation": 558,
      "lat": -11.9602775574,
      "lon": -38.0027770996,
      "tz": "America\/Bahia"
  },
  "SIZC": {
      "icao": "SIZC",
      "iata": "",
      "name": "Fazenda Fenix Airport",
      "city": "Faria Lemos",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1542,
      "lat": -20.7794437408,
      "lon": -41.9955558777,
      "tz": "America\/Sao_Paulo"
  },
  "SIZF": {
      "icao": "SIZF",
      "iata": "",
      "name": "Nossa Senhora das Gracas Airport",
      "city": "Altonia",
      "state": "Parana",
      "country": "BR",
      "elevation": 873,
      "lat": -23.9950008392,
      "lon": -53.956111908,
      "tz": "America\/Sao_Paulo"
  },
  "SIZG": {
      "icao": "SIZG",
      "iata": "",
      "name": "Fazenda Encontro das Aguas Airport",
      "city": "Sao Manuel",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1631,
      "lat": -22.6079006195,
      "lon": -48.3879013062,
      "tz": "America\/Sao_Paulo"
  },
  "SIZH": {
      "icao": "SIZH",
      "iata": "",
      "name": "Fazenda Vista Alegre Airport",
      "city": "Aparecida Do Taboado",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1483,
      "lat": -19.9930553436,
      "lon": -51.5141677856,
      "tz": "America\/Campo_Grande"
  },
  "SIZI": {
      "icao": "SIZI",
      "iata": "",
      "name": "Fazenda Vitoria Airport",
      "city": "Itaruma",
      "state": "Goias",
      "country": "BR",
      "elevation": 1854,
      "lat": -18.7527999878,
      "lon": -51.4730987549,
      "tz": "America\/Sao_Paulo"
  },
  "SIZK": {
      "icao": "SIZK",
      "iata": "",
      "name": "Fazenda Vera Cruz Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 995,
      "lat": -14.875,
      "lon": -52.1155548096,
      "tz": "America\/Cuiaba"
  },
  "SIZM": {
      "icao": "SIZM",
      "iata": "",
      "name": "Fazenda Douradinho Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1201,
      "lat": -21.640832901,
      "lon": -53.3005561829,
      "tz": "America\/Campo_Grande"
  },
  "SIZN": {
      "icao": "SIZN",
      "iata": "",
      "name": "Fazenda Santa Ana Airport",
      "city": "Corumbiara",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 847,
      "lat": -13.0041666031,
      "lon": -61.2400016785,
      "tz": "America\/Porto_Velho"
  },
  "SIZO": {
      "icao": "SIZO",
      "iata": "",
      "name": "Calcario Vale do Araguaia Airport",
      "city": "Cocalinho",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 860,
      "lat": -14.1902999878,
      "lon": -51.643901825,
      "tz": "America\/Cuiaba"
  },
  "SIZQ": {
      "icao": "SIZQ",
      "iata": "",
      "name": "Fazenda Real Airport",
      "city": "Botucatu",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 3078,
      "lat": -23.0361118317,
      "lon": -48.4405555725,
      "tz": "America\/Sao_Paulo"
  },
  "SIZR": {
      "icao": "SIZR",
      "iata": "",
      "name": "Fazenda Carolina Airport",
      "city": "Corumbiara",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 853,
      "lat": -12.9005556107,
      "lon": -61.1300010681,
      "tz": "America\/Porto_Velho"
  },
  "SIZS": {
      "icao": "SIZS",
      "iata": "",
      "name": "Agropecuaria Equus Airport",
      "city": "Gaucha",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1201,
      "lat": -13.5375003815,
      "lon": -53.4391670227,
      "tz": "America\/Cuiaba"
  },
  "SIZT": {
      "icao": "SIZT",
      "iata": "",
      "name": "Fazenda Buriti Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 299,
      "lat": -19.3544445038,
      "lon": -56.793056488,
      "tz": "America\/Campo_Grande"
  },
  "SIZX": {
      "icao": "SIZX",
      "iata": "JUA",
      "name": "Juara Sul Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 991,
      "lat": -11.2866668701,
      "lon": -57.5388908386,
      "tz": "America\/Cuiaba"
  },
  "SJAA": {
      "icao": "SJAA",
      "iata": "",
      "name": "Fazenda Sao Jose do Rio Branco Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1142,
      "lat": -15.0713891983,
      "lon": -59.1533317566,
      "tz": "America\/Cuiaba"
  },
  "SJAC": {
      "icao": "SJAC",
      "iata": "",
      "name": "Fazenda Rio Preto Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 886,
      "lat": -11.4755563736,
      "lon": -51.6872215271,
      "tz": "America\/Cuiaba"
  },
  "SJAE": {
      "icao": "SJAE",
      "iata": "",
      "name": "Fazenda Furnas Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1618,
      "lat": -13.1402778625,
      "lon": -51.801109314,
      "tz": "America\/Cuiaba"
  },
  "SJAF": {
      "icao": "SJAF",
      "iata": "",
      "name": "Fazenda Agua Limpa Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1804,
      "lat": -13.670832634,
      "lon": -57.8955574036,
      "tz": "America\/Cuiaba"
  },
  "SJAG": {
      "icao": "SJAG",
      "iata": "",
      "name": "Fazenda Paredao Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 804,
      "lat": -13.628610611,
      "lon": -60.2883338928,
      "tz": "America\/Cuiaba"
  },
  "SJAI": {
      "icao": "SJAI",
      "iata": "",
      "name": "Fazenda Davilandia Airport",
      "city": "Montividiu Do Norte",
      "state": "Goias",
      "country": "BR",
      "elevation": 1359,
      "lat": -13.1875,
      "lon": -48.75,
      "tz": "America\/Sao_Paulo"
  },
  "SJAK": {
      "icao": "SJAK",
      "iata": "",
      "name": "Fazenda F5 Airport",
      "city": "Tupaciguara",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1624,
      "lat": -18.3641662598,
      "lon": -48.8719444275,
      "tz": "America\/Sao_Paulo"
  },
  "SJAL": {
      "icao": "SJAL",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Acailandia",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1077,
      "lat": -4.6463890076,
      "lon": -47.2333335876,
      "tz": "America\/Fortaleza"
  },
  "SJAQ": {
      "icao": "SJAQ",
      "iata": "",
      "name": "Fazenda Renascenca Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2999,
      "lat": -16.1202774048,
      "lon": -46.3600006104,
      "tz": "America\/Sao_Paulo"
  },
  "SJAR": {
      "icao": "SJAR",
      "iata": "",
      "name": "Fazenda Bela Manha Airport",
      "city": "Santa Clara D`Oeste",
      "state": "",
      "country": "BR",
      "elevation": 1198,
      "lat": -20.0186004639,
      "lon": -50.8992004395,
      "tz": "America\/Sao_Paulo"
  },
  "SJAS": {
      "icao": "SJAS",
      "iata": "",
      "name": "Fazenda Sao Roque Airport",
      "city": "Reginopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1450,
      "lat": -21.6691665649,
      "lon": -49.1730575562,
      "tz": "America\/Sao_Paulo"
  },
  "SJAU": {
      "icao": "SJAU",
      "iata": "",
      "name": "Araguacema Airport",
      "city": "Araguacema",
      "state": "",
      "country": "BR",
      "elevation": 680,
      "lat": -8.8377199173,
      "lon": -49.5574989319,
      "tz": "America\/Araguaina"
  },
  "SJAW": {
      "icao": "SJAW",
      "iata": "",
      "name": "Fazenda Citricola Airport",
      "city": "Gaviao Peixoto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2057,
      "lat": -21.7252788544,
      "lon": -48.4519424438,
      "tz": "America\/Sao_Paulo"
  },
  "SJAX": {
      "icao": "SJAX",
      "iata": "",
      "name": "Fazenda Acalanto Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2657,
      "lat": -12.9650001526,
      "lon": -45.9761123657,
      "tz": "America\/Bahia"
  },
  "SJAY": {
      "icao": "SJAY",
      "iata": "",
      "name": "Fazenda Ventura Airport",
      "city": "Sao Desiderio",
      "state": "",
      "country": "BR",
      "elevation": 3127,
      "lat": -13.1608333588,
      "lon": -46.2008323669,
      "tz": "America\/Bahia"
  },
  "SJAZ": {
      "icao": "SJAZ",
      "iata": "",
      "name": "Fazenda Querubim Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2733,
      "lat": -12.7688894272,
      "lon": -46.0213890076,
      "tz": "America\/Bahia"
  },
  "SJBF": {
      "icao": "SJBF",
      "iata": "",
      "name": "Fazenda J.K. Airport",
      "city": "Mimoso De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 2136,
      "lat": -15.1588888168,
      "lon": -48.2838897705,
      "tz": "America\/Sao_Paulo"
  },
  "SJBK": {
      "icao": "SJBK",
      "iata": "",
      "name": "Iriri Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 581,
      "lat": -5.6791667938,
      "lon": -54.2449989319,
      "tz": "America\/Santarem"
  },
  "SJBN": {
      "icao": "SJBN",
      "iata": "",
      "name": "Aldeia Pykany Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 1100,
      "lat": -8.1666669846,
      "lon": -54.8297233582,
      "tz": "America\/Santarem"
  },
  "SJBR": {
      "icao": "SJBR",
      "iata": "",
      "name": "Fazenda Santa Luzia Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 401,
      "lat": -17.8766670227,
      "lon": -56.3888893127,
      "tz": "America\/Campo_Grande"
  },
  "SJBS": {
      "icao": "SJBS",
      "iata": "",
      "name": "Fazenda Livramento Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 443,
      "lat": -19.268611908,
      "lon": -56.0311126709,
      "tz": "America\/Campo_Grande"
  },
  "SJBT": {
      "icao": "SJBT",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 417,
      "lat": -19.5744438171,
      "lon": -56.099445343,
      "tz": "America\/Campo_Grande"
  },
  "SJBU": {
      "icao": "SJBU",
      "iata": "",
      "name": "Fazenda Baluarte Airport",
      "city": "Lagoa Dos Patos",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1720,
      "lat": -17.0127773285,
      "lon": -44.7902793884,
      "tz": "America\/Sao_Paulo"
  },
  "SJBW": {
      "icao": "SJBW",
      "iata": "",
      "name": "Fazenda Barbacena Airport",
      "city": "Sao Pedro Do Ivai",
      "state": "Parana",
      "country": "BR",
      "elevation": 1247,
      "lat": -23.8394432068,
      "lon": -51.9027786255,
      "tz": "America\/Sao_Paulo"
  },
  "SJBX": {
      "icao": "SJBX",
      "iata": "",
      "name": "Severino Lopes Airport",
      "city": "Sao Jose Do Mipibu",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 329,
      "lat": -6.0199999809,
      "lon": -35.2397232056,
      "tz": "America\/Fortaleza"
  },
  "SJBY": {
      "icao": "SJBY",
      "iata": "",
      "name": "Joao Silva Airport",
      "city": "Santa Ines",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 82,
      "lat": -3.6542799473,
      "lon": -45.345199585,
      "tz": "America\/Fortaleza"
  },
  "SJBZ": {
      "icao": "SJBZ",
      "iata": "",
      "name": "Fazenda Tres Barras Airport",
      "city": "Itaruma",
      "state": "Goias",
      "country": "BR",
      "elevation": 1411,
      "lat": -18.9572219849,
      "lon": -51.1086120605,
      "tz": "America\/Sao_Paulo"
  },
  "SJCA": {
      "icao": "SJCA",
      "iata": "",
      "name": "Comandante Vittorio Bonomi Airport",
      "city": "Mococa",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2133,
      "lat": -21.5303993225,
      "lon": -47.0465011597,
      "tz": "America\/Sao_Paulo"
  },
  "SJCD": {
      "icao": "SJCD",
      "iata": "",
      "name": "Fazenda Pingo D`Agua Airport",
      "city": "Pedro Gomes",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1303,
      "lat": -17.7211112976,
      "lon": -53.9375,
      "tz": "America\/Campo_Grande"
  },
  "SJCE": {
      "icao": "SJCE",
      "iata": "",
      "name": "Usina Eldorado Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1690,
      "lat": -21.8522224426,
      "lon": -54.0188903809,
      "tz": "America\/Campo_Grande"
  },
  "SJCF": {
      "icao": "SJCF",
      "iata": "",
      "name": "Fazenda Estrela D`Alva Airport",
      "city": "Canarana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1250,
      "lat": -13.1891670227,
      "lon": -52.3069458008,
      "tz": "America\/Cuiaba"
  },
  "SJCL": {
      "icao": "SJCL",
      "iata": "",
      "name": "Fazenda Aracatuba Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1100,
      "lat": -14.5313892365,
      "lon": -50.4086112976,
      "tz": "America\/Sao_Paulo"
  },
  "SJCM": {
      "icao": "SJCM",
      "iata": "",
      "name": "Fazenda Sao Marcus Airport",
      "city": "Pontal Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 4361,
      "lat": -16.1216678619,
      "lon": -52.9508323669,
      "tz": "America\/Cuiaba"
  },
  "SJCN": {
      "icao": "SJCN",
      "iata": "",
      "name": "Aero Agricola Cristalina Airport",
      "city": "Cristalina",
      "state": "Goias",
      "country": "BR",
      "elevation": 3544,
      "lat": -16.9069442749,
      "lon": -47.7083320618,
      "tz": "America\/Sao_Paulo"
  },
  "SJCO": {
      "icao": "SJCO",
      "iata": "",
      "name": "Fazenda Aparecida Airport",
      "city": "Penapolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1510,
      "lat": -21.4980564117,
      "lon": -50.1011123657,
      "tz": "America\/Sao_Paulo"
  },
  "SJCP": {
      "icao": "SJCP",
      "iata": "",
      "name": "Fazenda Sao Roberto Airport",
      "city": "Parauna",
      "state": "Goias",
      "country": "BR",
      "elevation": 2497,
      "lat": -17.0727787018,
      "lon": -50.8474998474,
      "tz": "America\/Sao_Paulo"
  },
  "SJCQ": {
      "icao": "SJCQ",
      "iata": "",
      "name": "Fazenda Serra Azul Airport",
      "city": "Baixa Grande",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1123,
      "lat": -11.935500145,
      "lon": -40.0507011414,
      "tz": "America\/Bahia"
  },
  "SJCW": {
      "icao": "SJCW",
      "iata": "",
      "name": "Jatapu Airport",
      "city": "Urucara",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 132,
      "lat": -1.7120499611,
      "lon": -58.5111999512,
      "tz": "America\/Manaus"
  },
  "SJCY": {
      "icao": "SJCY",
      "iata": "",
      "name": "Estancia Santa Rita Airport",
      "city": "Cuiaba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 617,
      "lat": -15.679444313,
      "lon": -55.9555549622,
      "tz": "America\/Cuiaba"
  },
  "SJCZ": {
      "icao": "SJCZ",
      "iata": "",
      "name": "Fazenda Paraiso Airport",
      "city": "Nova Ubirata",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1101,
      "lat": -12.4291667938,
      "lon": -54.2563896179,
      "tz": "America\/Cuiaba"
  },
  "SJDA": {
      "icao": "SJDA",
      "iata": "",
      "name": "Fazenda Cruzeiro Airport",
      "city": "Uberaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3160,
      "lat": -19.1922225952,
      "lon": -48.1172218323,
      "tz": "America\/Sao_Paulo"
  },
  "SJDD": {
      "icao": "SJDD",
      "iata": "",
      "name": "Fazenda Jumbo Airport",
      "city": "Taquarussu",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 854,
      "lat": -22.5138893127,
      "lon": -53.3419456482,
      "tz": "America\/Campo_Grande"
  },
  "SJDG": {
      "icao": "SJDG",
      "iata": "",
      "name": "Projeto Iriri Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 657,
      "lat": -6.1880559921,
      "lon": -54.0722236633,
      "tz": "America\/Santarem"
  },
  "SJDH": {
      "icao": "SJDH",
      "iata": "",
      "name": "Fazenda S.M. Airport",
      "city": "Jaborandi",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2891,
      "lat": -14.5500001907,
      "lon": -45.7666664124,
      "tz": "America\/Bahia"
  },
  "SJDI": {
      "icao": "SJDI",
      "iata": "",
      "name": "Fazenda Concordia Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1247,
      "lat": -22.8491668701,
      "lon": -54.206111908,
      "tz": "America\/Campo_Grande"
  },
  "SJDJ": {
      "icao": "SJDJ",
      "iata": "",
      "name": "Arenhart Airport",
      "city": "Uruguaiana",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 210,
      "lat": -29.8886108398,
      "lon": -57.1399993896,
      "tz": "America\/Sao_Paulo"
  },
  "SJDL": {
      "icao": "SJDL",
      "iata": "",
      "name": "Fazenda Paraiso Airport",
      "city": "Corumba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1313,
      "lat": -17.3077774048,
      "lon": -55.8452796936,
      "tz": "America\/Campo_Grande"
  },
  "SJDN": {
      "icao": "SJDN",
      "iata": "",
      "name": "Fazenda Campo Alegre Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 558,
      "lat": -19.456111908,
      "lon": -55.7408332825,
      "tz": "America\/Campo_Grande"
  },
  "SJDS": {
      "icao": "SJDS",
      "iata": "",
      "name": "Fabrica Fortaleza Airport",
      "city": "Eusebio",
      "state": "Ceara",
      "country": "BR",
      "elevation": 66,
      "lat": -3.9272220135,
      "lon": -38.5005569458,
      "tz": "America\/Fortaleza"
  },
  "SJDT": {
      "icao": "SJDT",
      "iata": "",
      "name": "Fazenda Sao Roque Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 453,
      "lat": -19.5183334351,
      "lon": -55.3119430542,
      "tz": "America\/Campo_Grande"
  },
  "SJDU": {
      "icao": "SJDU",
      "iata": "",
      "name": "Nossa Senhora do Loreto Airport",
      "city": "Uruguaiana",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 329,
      "lat": -29.728055954,
      "lon": -56.962223053,
      "tz": "America\/Sao_Paulo"
  },
  "SJDV": {
      "icao": "SJDV",
      "iata": "",
      "name": "Fazenda Redencao Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 312,
      "lat": -19.6186103821,
      "lon": -56.743057251,
      "tz": "America\/Campo_Grande"
  },
  "SJDW": {
      "icao": "SJDW",
      "iata": "",
      "name": "Fazenda Canada Airport",
      "city": "Caracol",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 548,
      "lat": -22.1269435883,
      "lon": -57.2894439697,
      "tz": "America\/Campo_Grande"
  },
  "SJDX": {
      "icao": "SJDX",
      "iata": "",
      "name": "Fazenda Bandeirantes Airport",
      "city": "Aquidauana",
      "state": "",
      "country": "BR",
      "elevation": 375,
      "lat": -19.4122219086,
      "lon": -55.5497207642,
      "tz": "America\/Campo_Grande"
  },
  "SJDY": {
      "icao": "SJDY",
      "iata": "",
      "name": "Fazenda da Barra Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1533,
      "lat": -18.6630554199,
      "lon": -54.068611145,
      "tz": "America\/Campo_Grande"
  },
  "SJEA": {
      "icao": "SJEA",
      "iata": "",
      "name": "Cadete 56-147 Santana Airport",
      "city": "Palmeiropolis",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 985,
      "lat": -12.8500003815,
      "lon": -48.225276947,
      "tz": "America\/Araguaina"
  },
  "SJED": {
      "icao": "SJED",
      "iata": "",
      "name": "Fazenda Abath Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 348,
      "lat": -18.6800003052,
      "lon": -57.2249984741,
      "tz": "America\/Campo_Grande"
  },
  "SJEF": {
      "icao": "SJEF",
      "iata": "",
      "name": "Fazenda Costa Rica Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 342,
      "lat": -19.7469444275,
      "lon": -56.0422210693,
      "tz": "America\/Campo_Grande"
  },
  "SJEG": {
      "icao": "SJEG",
      "iata": "",
      "name": "Estancia Esmeralda Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 525,
      "lat": -20.1605567932,
      "lon": -57.1580543518,
      "tz": "America\/Campo_Grande"
  },
  "SJEH": {
      "icao": "SJEH",
      "iata": "",
      "name": "Fazenda Santa Eliza Airport",
      "city": "Ribeirao Bonito",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2395,
      "lat": -22.1019439697,
      "lon": -48.2269439697,
      "tz": "America\/Sao_Paulo"
  },
  "SJEJ": {
      "icao": "SJEJ",
      "iata": "",
      "name": "Fazenda Bonanza Airport",
      "city": "Avai",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1713,
      "lat": -22.1550006866,
      "lon": -49.287776947,
      "tz": "America\/Sao_Paulo"
  },
  "SJEK": {
      "icao": "SJEK",
      "iata": "",
      "name": "Satelite Aviacao Agricola Airport",
      "city": "Canarana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 4594,
      "lat": -13.5455560684,
      "lon": -52.2638893127,
      "tz": "America\/Cuiaba"
  },
  "SJEL": {
      "icao": "SJEL",
      "iata": "",
      "name": "Eldorado Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 837,
      "lat": -6.9555559158,
      "lon": -56.4711112976,
      "tz": "America\/Santarem"
  },
  "SJEM": {
      "icao": "SJEM",
      "iata": "",
      "name": "Estancia Pousada dos Passaros Airport",
      "city": "Vera",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1231,
      "lat": -12.3191671371,
      "lon": -55.3338890076,
      "tz": "America\/Cuiaba"
  },
  "SJEN": {
      "icao": "SJEN",
      "iata": "",
      "name": "Pista PLA e Silva Airport",
      "city": "Santa Vitoria Do Palmar",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 10,
      "lat": -33.1438903809,
      "lon": -52.9763908386,
      "tz": "America\/Sao_Paulo"
  },
  "SJEQ": {
      "icao": "SJEQ",
      "iata": "",
      "name": "Fazenda Bom Jesus Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 404,
      "lat": -18.1827774048,
      "lon": -56.7825012207,
      "tz": "America\/Campo_Grande"
  },
  "SJER": {
      "icao": "SJER",
      "iata": "",
      "name": "Fazenda Canada Airport",
      "city": "Acreuna",
      "state": "Goias",
      "country": "BR",
      "elevation": 1841,
      "lat": -17.31888889,
      "lon": -50.32027778,
      "tz": "America\/Sao_Paulo"
  },
  "SJES": {
      "icao": "SJES",
      "iata": "",
      "name": "Fazenda Agua Pe Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 407,
      "lat": -18.1177787781,
      "lon": -56.6411094666,
      "tz": "America\/Campo_Grande"
  },
  "SJET": {
      "icao": "SJET",
      "iata": "",
      "name": "Fazenda Recreio Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 404,
      "lat": -17.5647220612,
      "lon": -56.7866668701,
      "tz": "America\/Campo_Grande"
  },
  "SJEU": {
      "icao": "SJEU",
      "iata": "",
      "name": "Fazenda Sao Cristovao Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 404,
      "lat": -17.8691673279,
      "lon": -56.1938896179,
      "tz": "America\/Campo_Grande"
  },
  "SJEV": {
      "icao": "SJEV",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Corumba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 401,
      "lat": -17.6483325958,
      "lon": -56.8861122131,
      "tz": "America\/Campo_Grande"
  },
  "SJEW": {
      "icao": "SJEW",
      "iata": "",
      "name": "Fazenda Horita Airport",
      "city": "Sao Desiderio",
      "state": "",
      "country": "BR",
      "elevation": 3163,
      "lat": -13.0111112595,
      "lon": -46.2380561829,
      "tz": "America\/Bahia"
  },
  "SJEX": {
      "icao": "SJEX",
      "iata": "",
      "name": "Fazenda Vitoria Airport",
      "city": "Sao Desiderio",
      "state": "",
      "country": "BR",
      "elevation": 2966,
      "lat": -13.010556221,
      "lon": -46.1077766418,
      "tz": "America\/Bahia"
  },
  "SJEY": {
      "icao": "SJEY",
      "iata": "",
      "name": "Fazenda Agronet Airport",
      "city": "Indiapora",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1562,
      "lat": -19.9905567169,
      "lon": -50.2661094666,
      "tz": "America\/Sao_Paulo"
  },
  "SJFB": {
      "icao": "SJFB",
      "iata": "",
      "name": "Fazenda Bacuri Airport",
      "city": "Riachao",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1815,
      "lat": -8.3827781677,
      "lon": -46.4422225952,
      "tz": "America\/Fortaleza"
  },
  "SJFF": {
      "icao": "SJFF",
      "iata": "",
      "name": "Frigo Estrela Airport",
      "city": "Estrela D`Oeste",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1595,
      "lat": -20.2947216034,
      "lon": -50.4105567932,
      "tz": "America\/Sao_Paulo"
  },
  "SJFG": {
      "icao": "SJFG",
      "iata": "",
      "name": "Fazenda Penedo Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 250,
      "lat": -5.5211110115,
      "lon": -57.1074981689,
      "tz": "America\/Santarem"
  },
  "SJFI": {
      "icao": "SJFI",
      "iata": "",
      "name": "CIFI Airport",
      "city": "Cuiaba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 644,
      "lat": -15.634721756,
      "lon": -55.9905548096,
      "tz": "America\/Cuiaba"
  },
  "SJFJ": {
      "icao": "SJFJ",
      "iata": "",
      "name": "Tres Marias da Pitangueiras Airport",
      "city": "Santo Antonio Do Leverger",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 522,
      "lat": -17.2350006104,
      "lon": -55.2266654968,
      "tz": "America\/Cuiaba"
  },
  "SJFK": {
      "icao": "SJFK",
      "iata": "",
      "name": "Fazenda Cachoeira Airport",
      "city": "Araguacu",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 752,
      "lat": -12.5966672897,
      "lon": -49.5180549622,
      "tz": "America\/Araguaina"
  },
  "SJFL": {
      "icao": "SJFL",
      "iata": "",
      "name": "Fazenda Campo Formoso Airport",
      "city": "Sandolandia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1083,
      "lat": -12.5616674423,
      "lon": -49.8633346558,
      "tz": "America\/Araguaina"
  },
  "SJFM": {
      "icao": "SJFM",
      "iata": "",
      "name": "Fazenda Macuco Airport",
      "city": "Eldorado",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1149,
      "lat": -23.8358325958,
      "lon": -54.231388092,
      "tz": "America\/Campo_Grande"
  },
  "SJFN": {
      "icao": "SJFN",
      "iata": "",
      "name": "Fazenda Brasil Novo Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 752,
      "lat": -18.3716678619,
      "lon": -54.9288902283,
      "tz": "America\/Campo_Grande"
  },
  "SJFO": {
      "icao": "SJFO",
      "iata": "",
      "name": "Fazenda California Airport",
      "city": "Porteirao",
      "state": "Goias",
      "country": "BR",
      "elevation": 1549,
      "lat": -18.0366668701,
      "lon": -50.1994438171,
      "tz": "America\/Sao_Paulo"
  },
  "SJFP": {
      "icao": "SJFP",
      "iata": "",
      "name": "Fazenda Perobal Airport",
      "city": "Corumbiara",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 984,
      "lat": -12.9108333588,
      "lon": -61.368888855,
      "tz": "America\/Porto_Velho"
  },
  "SJFQ": {
      "icao": "SJFQ",
      "iata": "",
      "name": "Fazenda Quatrirmas Airport",
      "city": "Itatinga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2441,
      "lat": -22.959444046,
      "lon": -48.6747207642,
      "tz": "America\/Sao_Paulo"
  },
  "SJFR": {
      "icao": "SJFR",
      "iata": "",
      "name": "Fronteira Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1492,
      "lat": -12.523888588,
      "lon": -58.2355575562,
      "tz": "America\/Cuiaba"
  },
  "SJFT": {
      "icao": "SJFT",
      "iata": "",
      "name": "Fazenda Triunfo Rio Turvo Airport",
      "city": "Nova Bandeirantes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1018,
      "lat": -10.0930557251,
      "lon": -57.7269439697,
      "tz": "America\/Cuiaba"
  },
  "SJFU": {
      "icao": "SJFU",
      "iata": "",
      "name": "Tabuleiro Airport",
      "city": "Bananeiras",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 1716,
      "lat": -6.6941671371,
      "lon": -35.630279541,
      "tz": "America\/Fortaleza"
  },
  "SJFV": {
      "icao": "SJFV",
      "iata": "",
      "name": "Fazenda Gaia Airport",
      "city": "Monte Alegre De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2762,
      "lat": -18.7600002289,
      "lon": -48.755279541,
      "tz": "America\/Sao_Paulo"
  },
  "SJFW": {
      "icao": "SJFW",
      "iata": "",
      "name": "Fazenda W. Egidio III Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2648,
      "lat": -15.9380559921,
      "lon": -46.4775009155,
      "tz": "America\/Sao_Paulo"
  },
  "SJFX": {
      "icao": "SJFX",
      "iata": "",
      "name": "Fazenda Santo Angelo Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2799,
      "lat": -12.798333168,
      "lon": -46.0958328247,
      "tz": "America\/Bahia"
  },
  "SJGA": {
      "icao": "SJGA",
      "iata": "",
      "name": "Aerorural Floramazon Airport",
      "city": "Aveiro",
      "state": "Para",
      "country": "BR",
      "elevation": 131,
      "lat": -3.7022221088,
      "lon": -56.0538902283,
      "tz": "America\/Santarem"
  },
  "SJGB": {
      "icao": "SJGB",
      "iata": "",
      "name": "Fazenda Ponte de Pedra Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1690,
      "lat": -13.5841665268,
      "lon": -57.463054657,
      "tz": "America\/Cuiaba"
  },
  "SJGC": {
      "icao": "SJGC",
      "iata": "",
      "name": "Fazenda Busato I Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1201,
      "lat": -12.9016666412,
      "lon": -45.5,
      "tz": "America\/Bahia"
  },
  "SJGD": {
      "icao": "SJGD",
      "iata": "",
      "name": "Fazenda Rio Brilhante Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2500,
      "lat": -12.8033332825,
      "lon": -45.7236099243,
      "tz": "America\/Bahia"
  },
  "SJGE": {
      "icao": "SJGE",
      "iata": "",
      "name": "Pista Nacoes Unidas Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 591,
      "lat": -6.0813889503,
      "lon": -56.2894439697,
      "tz": "America\/Santarem"
  },
  "SJGF": {
      "icao": "SJGF",
      "iata": "",
      "name": "Aerodromo Nova Era Airport",
      "city": "Pantano Grande",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 131,
      "lat": -30.2127780914,
      "lon": -52.4413871765,
      "tz": "America\/Sao_Paulo"
  },
  "SJGH": {
      "icao": "SJGH",
      "iata": "",
      "name": "Aeropel Airport",
      "city": "Sao Borja",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 300,
      "lat": -28.7163887024,
      "lon": -55.9925003052,
      "tz": "America\/Sao_Paulo"
  },
  "SJGI": {
      "icao": "SJGI",
      "iata": "",
      "name": "Fazenda Cypi Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -19.3380565643,
      "lon": -56.9077796936,
      "tz": "America\/Campo_Grande"
  },
  "SJGJ": {
      "icao": "SJGJ",
      "iata": "",
      "name": "Namocoli Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 282,
      "lat": -20.6088886261,
      "lon": -57.5541687012,
      "tz": "America\/Campo_Grande"
  },
  "SJGK": {
      "icao": "SJGK",
      "iata": "",
      "name": "Fazenda Rio liberdade Airport",
      "city": "Santana Do Araguaia",
      "state": "Para",
      "country": "BR",
      "elevation": 404,
      "lat": -9.4286108017,
      "lon": -51.0269432068,
      "tz": "America\/Belem"
  },
  "SJGN": {
      "icao": "SJGN",
      "iata": "",
      "name": "Fazenda Agromar Airport",
      "city": "Sao Jose Do Rio Claro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1201,
      "lat": -13.6625003815,
      "lon": -56.4827766418,
      "tz": "America\/Cuiaba"
  },
  "SJGO": {
      "icao": "SJGO",
      "iata": "",
      "name": "Eldorado Airport",
      "city": "Eldorado",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1079,
      "lat": -23.818611145,
      "lon": -54.2524986267,
      "tz": "America\/Campo_Grande"
  },
  "SJGP": {
      "icao": "SJGP",
      "iata": "",
      "name": "Condominio Fly-In Community Airport",
      "city": "Rondonopolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1047,
      "lat": -16.4461994171,
      "lon": -54.6641998291,
      "tz": "America\/Cuiaba"
  },
  "SJGR": {
      "icao": "SJGR",
      "iata": "",
      "name": "Fazenda Cavalgada Grande Airport",
      "city": "Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1050,
      "lat": -14.604722023,
      "lon": -50.1527786255,
      "tz": "America\/Sao_Paulo"
  },
  "SJGT": {
      "icao": "SJGT",
      "iata": "",
      "name": "Salto Augusto Airport",
      "city": "Apiacas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1559,
      "lat": -8.8927783966,
      "lon": -58.5497207642,
      "tz": "America\/Cuiaba"
  },
  "SJGU": {
      "icao": "SJGU",
      "iata": "",
      "name": "Araguatins Airport",
      "city": "Araguatins",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 411,
      "lat": -5.684440136,
      "lon": -48.1156005859,
      "tz": "America\/Araguaina"
  },
  "SJGW": {
      "icao": "SJGW",
      "iata": "",
      "name": "Estancia Floresta Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1280,
      "lat": -11.7827777863,
      "lon": -55.5391654968,
      "tz": "America\/Cuiaba"
  },
  "SJGX": {
      "icao": "SJGX",
      "iata": "",
      "name": "Da Praia Velha Airport",
      "city": "Camaqua",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 30,
      "lat": -31.1266670227,
      "lon": -51.5758323669,
      "tz": "America\/Sao_Paulo"
  },
  "SJGY": {
      "icao": "SJGY",
      "iata": "",
      "name": "Fazenda Barriguda Airport",
      "city": "Sao Domingos",
      "state": "Goias",
      "country": "BR",
      "elevation": 1965,
      "lat": -13.707777977,
      "lon": -46.7358322144,
      "tz": "America\/Sao_Paulo"
  },
  "SJGZ": {
      "icao": "SJGZ",
      "iata": "",
      "name": "Fazenda Aracatuba Airport",
      "city": "Anaurilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1231,
      "lat": -22.0900001526,
      "lon": -52.6749992371,
      "tz": "America\/Campo_Grande"
  },
  "SJHA": {
      "icao": "SJHA",
      "iata": "",
      "name": "Fazenda Janaina Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1296,
      "lat": -22.9830551147,
      "lon": -55.0200004578,
      "tz": "America\/Campo_Grande"
  },
  "SJHD": {
      "icao": "SJHD",
      "iata": "",
      "name": "Fazenda Modelo Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1375,
      "lat": -21.3822212219,
      "lon": -52.198890686,
      "tz": "America\/Campo_Grande"
  },
  "SJHE": {
      "icao": "SJHE",
      "iata": "",
      "name": "Fazenda Palmeira Airport",
      "city": "Ipiranga Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1201,
      "lat": -12.0938892365,
      "lon": -55.9977798462,
      "tz": "America\/Cuiaba"
  },
  "SJHF": {
      "icao": "SJHF",
      "iata": "",
      "name": "Bunge Airport",
      "city": "Urucui",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1837,
      "lat": -7.3722219467,
      "lon": -44.3961105347,
      "tz": "America\/Fortaleza"
  },
  "SJHG": {
      "icao": "SJHG",
      "iata": "CFO",
      "name": "Confresa Airport",
      "city": "Confresa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 781,
      "lat": -10.6344003677,
      "lon": -51.5635986328,
      "tz": "America\/Cuiaba"
  },
  "SJHH": {
      "icao": "SJHH",
      "iata": "",
      "name": "Fazenda Poruina Airport",
      "city": "Serranopolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 2287,
      "lat": -18.1200008392,
      "lon": -52.2177772522,
      "tz": "America\/Sao_Paulo"
  },
  "SJHJ": {
      "icao": "SJHJ",
      "iata": "",
      "name": "Fazenda Remanso Airport",
      "city": "Cacoal",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 831,
      "lat": -11.1822223663,
      "lon": -61.0888900757,
      "tz": "America\/Porto_Velho"
  },
  "SJHK": {
      "icao": "SJHK",
      "iata": "",
      "name": "Musica Airport",
      "city": "Dom Pedrito",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 394,
      "lat": -30.9361114502,
      "lon": -55.0808334351,
      "tz": "America\/Sao_Paulo"
  },
  "SJHL": {
      "icao": "SJHL",
      "iata": "",
      "name": "Frisama Eldorado Airport",
      "city": "Eldorado Do Carajas",
      "state": "Para",
      "country": "BR",
      "elevation": 384,
      "lat": -6.0947217941,
      "lon": -49.3613891602,
      "tz": "America\/Belem"
  },
  "SJHM": {
      "icao": "SJHM",
      "iata": "",
      "name": "Fazenda Globo Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1588,
      "lat": -12.9536113739,
      "lon": -58.5902786255,
      "tz": "America\/Cuiaba"
  },
  "SJHN": {
      "icao": "SJHN",
      "iata": "",
      "name": "Fazenda Belluno Airport",
      "city": "Alfredo Wagner",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 3445,
      "lat": -27.8172225952,
      "lon": -49.2338905334,
      "tz": "America\/Sao_Paulo"
  },
  "SJHO": {
      "icao": "SJHO",
      "iata": "",
      "name": "Fazenda Colorado Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1699,
      "lat": -13.9513893127,
      "lon": -57.105556488,
      "tz": "America\/Cuiaba"
  },
  "SJHP": {
      "icao": "SJHP",
      "iata": "",
      "name": "Monte Belo Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1624,
      "lat": -20.2763881683,
      "lon": -53.6616668701,
      "tz": "America\/Campo_Grande"
  },
  "SJHQ": {
      "icao": "SJHQ",
      "iata": "",
      "name": "Fazenda Sao Jose da Reunidas Airport",
      "city": "Sao Jose Do Xingu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1155,
      "lat": -11.0336112976,
      "lon": -52.3338890076,
      "tz": "America\/Cuiaba"
  },
  "SJHS": {
      "icao": "SJHS",
      "iata": "",
      "name": "Fazenda Sao Vicente Airport",
      "city": "Uberlandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2477,
      "lat": -19.3174991608,
      "lon": -48.3486099243,
      "tz": "America\/Sao_Paulo"
  },
  "SJHT": {
      "icao": "SJHT",
      "iata": "",
      "name": "Fazenda ANB Airport",
      "city": "Corguinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1194,
      "lat": -19.8316669464,
      "lon": -54.8294448853,
      "tz": "America\/Campo_Grande"
  },
  "SJHU": {
      "icao": "SJHU",
      "iata": "",
      "name": "Agropecuaria Vale do Guapore Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1149,
      "lat": -13.878610611,
      "lon": -60.0963897705,
      "tz": "America\/Cuiaba"
  },
  "SJHW": {
      "icao": "SJHW",
      "iata": "",
      "name": "Professor Mauricio Joppert Airport",
      "city": "Engenheiro Navarro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2221,
      "lat": -17.2936115265,
      "lon": -43.9586105347,
      "tz": "America\/Sao_Paulo"
  },
  "SJHX": {
      "icao": "SJHX",
      "iata": "",
      "name": "Frisama Acailandia Airport",
      "city": "Acailandia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 926,
      "lat": -4.9455561638,
      "lon": -47.4647216797,
      "tz": "America\/Fortaleza"
  },
  "SJHZ": {
      "icao": "SJHZ",
      "iata": "",
      "name": "Fazenda Dois Netos Airport",
      "city": "Paranatinga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1165,
      "lat": -13.2455558777,
      "lon": -54.0338897705,
      "tz": "America\/Cuiaba"
  },
  "SJIC": {
      "icao": "SJIC",
      "iata": "",
      "name": "Pedro da Costa Lima Airport",
      "city": "Santa Rita Do Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1050,
      "lat": -21.2444438934,
      "lon": -52.7861099243,
      "tz": "America\/Campo_Grande"
  },
  "SJID": {
      "icao": "SJID",
      "iata": "",
      "name": "Fazenda Goiaba Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1549,
      "lat": -19.9172210693,
      "lon": -52.6313896179,
      "tz": "America\/Campo_Grande"
  },
  "SJIE": {
      "icao": "SJIE",
      "iata": "",
      "name": "Aero Agricola Sapezal Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1739,
      "lat": -13.55027771,
      "lon": -58.8600006104,
      "tz": "America\/Cuiaba"
  },
  "SJIF": {
      "icao": "SJIF",
      "iata": "",
      "name": "Fazenda Nova Era Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1247,
      "lat": -23.3941669464,
      "lon": -54.7524986267,
      "tz": "America\/Campo_Grande"
  },
  "SJII": {
      "icao": "SJII",
      "iata": "",
      "name": "Fazenda Sao Marcos Airport",
      "city": "Dourados",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1395,
      "lat": -22.296667099,
      "lon": -54.9413871765,
      "tz": "America\/Campo_Grande"
  },
  "SJIJ": {
      "icao": "SJIJ",
      "iata": "",
      "name": "Fazenda Amparo Airport",
      "city": "Alto Taquari",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2766,
      "lat": -17.7819442749,
      "lon": -53.4611091614,
      "tz": "America\/Cuiaba"
  },
  "SJIL": {
      "icao": "SJIL",
      "iata": "",
      "name": "Fazenda Santa Barbara Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 427,
      "lat": -19.0986118317,
      "lon": -55.8477783203,
      "tz": "America\/Campo_Grande"
  },
  "SJIO": {
      "icao": "SJIO",
      "iata": "",
      "name": "Guararapes Airport",
      "city": "Santa Cecilia",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 3609,
      "lat": -26.9919433594,
      "lon": -50.3830566406,
      "tz": "America\/Sao_Paulo"
  },
  "SJIQ": {
      "icao": "SJIQ",
      "iata": "",
      "name": "Usina Coruripe - Filial Limeira do Oeste Airport",
      "city": "Limeira Do Oeste",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1384,
      "lat": -19.5233325958,
      "lon": -50.6555557251,
      "tz": "America\/Sao_Paulo"
  },
  "SJIR": {
      "icao": "SJIR",
      "iata": "",
      "name": "Engenheiro Joaquim Martins Airport",
      "city": "Iramaia",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1733,
      "lat": -13.3077783585,
      "lon": -40.8827781677,
      "tz": "America\/Bahia"
  },
  "SJIS": {
      "icao": "SJIS",
      "iata": "",
      "name": "Fazenda Futuro Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1598,
      "lat": -20.3466663361,
      "lon": -53.456943512,
      "tz": "America\/Campo_Grande"
  },
  "SJIT": {
      "icao": "SJIT",
      "iata": "",
      "name": "Itau de Minas Airport",
      "city": "Itau De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2520,
      "lat": -20.7553005219,
      "lon": -46.7501983643,
      "tz": "America\/Sao_Paulo"
  },
  "SJIU": {
      "icao": "SJIU",
      "iata": "",
      "name": "Fazenda Beija-Flor Airport",
      "city": "Esplanada",
      "state": "Bahia",
      "country": "BR",
      "elevation": 555,
      "lat": -11.7941665649,
      "lon": -38.0908317566,
      "tz": "America\/Bahia"
  },
  "SJIV": {
      "icao": "SJIV",
      "iata": "",
      "name": "Sao Joao Airport",
      "city": "Macambara",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 269,
      "lat": -29.0858325958,
      "lon": -56.2147216797,
      "tz": "America\/Sao_Paulo"
  },
  "SJIY": {
      "icao": "SJIY",
      "iata": "",
      "name": "Sao Leopoldo Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 814,
      "lat": -4.9791669846,
      "lon": -56.9272232056,
      "tz": "America\/Santarem"
  },
  "SJIZ": {
      "icao": "SJIZ",
      "iata": "",
      "name": "Modulo Aguapei Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 869,
      "lat": -15.868888855,
      "lon": -59.2188873291,
      "tz": "America\/Cuiaba"
  },
  "SJJA": {
      "icao": "SJJA",
      "iata": "",
      "name": "Fazenda Jamanxin Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 800,
      "lat": -8.375,
      "lon": -55.3061103821,
      "tz": "America\/Santarem"
  },
  "SJJB": {
      "icao": "SJJB",
      "iata": "",
      "name": "Fazenda Cacique Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1348,
      "lat": -20.7766666412,
      "lon": -52.7772216797,
      "tz": "America\/Campo_Grande"
  },
  "SJJF": {
      "icao": "SJJF",
      "iata": "",
      "name": "Pousada do Rio Roosevelt Airport",
      "city": "Novo Aripuana",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 292,
      "lat": -8.4944438934,
      "lon": -60.9625015259,
      "tz": "America\/Manaus"
  },
  "SJJG": {
      "icao": "SJJG",
      "iata": "",
      "name": "Fazenda Tanguru Airport",
      "city": "Querencia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1050,
      "lat": -12.7216672897,
      "lon": -52.3786125183,
      "tz": "America\/Cuiaba"
  },
  "SJJH": {
      "icao": "SJJH",
      "iata": "",
      "name": "Pousada Rio Xingu Airport",
      "city": "Vitoria Do Xingu",
      "state": "Para",
      "country": "BR",
      "elevation": 233,
      "lat": -3.2494440079,
      "lon": -51.7224998474,
      "tz": "America\/Belem"
  },
  "SJJJ": {
      "icao": "SJJJ",
      "iata": "",
      "name": "Fazenda Juliana Airport",
      "city": "Chupinguaia",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1132,
      "lat": -12.6824998856,
      "lon": -61.0888900757,
      "tz": "America\/Porto_Velho"
  },
  "SJJK": {
      "icao": "SJJK",
      "iata": "",
      "name": "Fazenda Taquaral Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 980,
      "lat": -10.0288887024,
      "lon": -56.7383003235,
      "tz": "America\/Cuiaba"
  },
  "SJJL": {
      "icao": "SJJL",
      "iata": "",
      "name": "Fazenda Campo Aberto Airport",
      "city": "Barreiras",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2418,
      "lat": -11.7882995605,
      "lon": -45.9646987915,
      "tz": "America\/Bahia"
  },
  "SJJM": {
      "icao": "SJJM",
      "iata": "",
      "name": "Michels Aviacao Agricola LTDA Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1884,
      "lat": -13.5322217941,
      "lon": -58.7652778625,
      "tz": "America\/Cuiaba"
  },
  "SJJN": {
      "icao": "SJJN",
      "iata": "",
      "name": "Fazenda Ponto de Apoio Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1320,
      "lat": -20.4663887024,
      "lon": -52.6875,
      "tz": "America\/Campo_Grande"
  },
  "SJJO": {
      "icao": "SJJO",
      "iata": "",
      "name": "Joao de Oliveira Bueno Airport",
      "city": "Monte Alegre De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1431,
      "lat": -13.1925001144,
      "lon": -47.1155548096,
      "tz": "America\/Araguaina"
  },
  "SJJP": {
      "icao": "SJJP",
      "iata": "",
      "name": "Sao Francisco Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 778,
      "lat": -7.8352780342,
      "lon": -56.5383338928,
      "tz": "America\/Santarem"
  },
  "SJJR": {
      "icao": "SJJR",
      "iata": "",
      "name": "Fazenda Recreio Airport",
      "city": "Andrelandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3810,
      "lat": -21.7194442749,
      "lon": -44.2094421387,
      "tz": "America\/Sao_Paulo"
  },
  "SJJT": {
      "icao": "SJJT",
      "iata": "",
      "name": "Fazenda Vale Verde Airport",
      "city": "Pium",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 581,
      "lat": -9.9611110687,
      "lon": -49.7519454956,
      "tz": "America\/Araguaina"
  },
  "SJJU": {
      "icao": "SJJU",
      "iata": "",
      "name": "Fazenda Barra Mansa Airport",
      "city": "Nova Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 725,
      "lat": -14.0494441986,
      "lon": -60.1519432068,
      "tz": "America\/Cuiaba"
  },
  "SJJV": {
      "icao": "SJJV",
      "iata": "",
      "name": "Fazenda Pilar Airport",
      "city": "Aveiro",
      "state": "Para",
      "country": "BR",
      "elevation": 499,
      "lat": -3.2933330536,
      "lon": -56.1847229004,
      "tz": "America\/Santarem"
  },
  "SJJW": {
      "icao": "SJJW",
      "iata": "",
      "name": "Fazenda Santa Emilia Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 443,
      "lat": -19.5055561066,
      "lon": -55.6130561829,
      "tz": "America\/Campo_Grande"
  },
  "SJJX": {
      "icao": "SJJX",
      "iata": "",
      "name": "Maca Aero Airport",
      "city": "Tailandia",
      "state": "Para",
      "country": "BR",
      "elevation": 179,
      "lat": -2.8980560303,
      "lon": -48.9586105347,
      "tz": "America\/Belem"
  },
  "SJKA": {
      "icao": "SJKA",
      "iata": "",
      "name": "Aerocampo Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1798,
      "lat": -13.6352777481,
      "lon": -57.8994445801,
      "tz": "America\/Cuiaba"
  },
  "SJKB": {
      "icao": "SJKB",
      "iata": "",
      "name": "Cotriguacu Airport",
      "city": "Cotriguacu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1506,
      "lat": -9.8994436264,
      "lon": -58.581943512,
      "tz": "America\/Cuiaba"
  },
  "SJKC": {
      "icao": "SJKC",
      "iata": "",
      "name": "Fazenda Costa Norte II Airport",
      "city": "Apiacas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 787,
      "lat": -9.2913894653,
      "lon": -58.0330543518,
      "tz": "America\/Cuiaba"
  },
  "SJKG": {
      "icao": "SJKG",
      "iata": "",
      "name": "Fazenda Jatoba Airport",
      "city": "Uruara",
      "state": "Para",
      "country": "BR",
      "elevation": 499,
      "lat": -3.5133330822,
      "lon": -54.0955543518,
      "tz": "America\/Santarem"
  },
  "SJKH": {
      "icao": "SJKH",
      "iata": "",
      "name": "Boa Fe Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 722,
      "lat": -7.4280557632,
      "lon": -56.3408317566,
      "tz": "America\/Santarem"
  },
  "SJKI": {
      "icao": "SJKI",
      "iata": "",
      "name": "Curiau Airport",
      "city": "Macapa",
      "state": "Amapa",
      "country": "BR",
      "elevation": 66,
      "lat": 0.2088889927,
      "lon": -51.0092010498,
      "tz": "America\/Belem"
  },
  "SJKJ": {
      "icao": "SJKJ",
      "iata": "",
      "name": "Fazenda Cangaia Airport",
      "city": "Sonora",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2549,
      "lat": -17.4427776337,
      "lon": -53.8088874817,
      "tz": "America\/Campo_Grande"
  },
  "SJKL": {
      "icao": "SJKL",
      "iata": "",
      "name": "Cana Airport",
      "city": "Normandia",
      "state": "Potaro-Siparuni",
      "country": "BR",
      "elevation": 994,
      "lat": 4.6188898087,
      "lon": -60.0717010498,
      "tz": "America\/Boa_Vista"
  },
  "SJKM": {
      "icao": "SJKM",
      "iata": "",
      "name": "Canawapai Airport",
      "city": "Uiramuta",
      "state": "Potaro-Siparuni",
      "country": "BR",
      "elevation": 1886,
      "lat": 4.8080601692,
      "lon": -60.0222015381,
      "tz": "America\/Boa_Vista"
  },
  "SJKN": {
      "icao": "SJKN",
      "iata": "",
      "name": "Caracana Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1988,
      "lat": 4.7047200203,
      "lon": -60.2653007507,
      "tz": "America\/Boa_Vista"
  },
  "SJKO": {
      "icao": "SJKO",
      "iata": "",
      "name": "Cararuau Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 594,
      "lat": 4.0630598068,
      "lon": -60.1650009155,
      "tz": "America\/Boa_Vista"
  },
  "SJKP": {
      "icao": "SJKP",
      "iata": "",
      "name": "Caraparu Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1194,
      "lat": 4.5411100388,
      "lon": -60.4631004333,
      "tz": "America\/Boa_Vista"
  },
  "SJKQ": {
      "icao": "SJKQ",
      "iata": "",
      "name": "Caramambatai (Mapae) Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1989,
      "lat": 5.1230602264,
      "lon": -60.5858001709,
      "tz": "America\/Boa_Vista"
  },
  "SJKR": {
      "icao": "SJKR",
      "iata": "",
      "name": "Pista KRB Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 706,
      "lat": -6.0805559158,
      "lon": -56.3891677856,
      "tz": "America\/Santarem"
  },
  "SJKS": {
      "icao": "SJKS",
      "iata": "",
      "name": "Catrimani I Airport",
      "city": "Mucajai",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1092,
      "lat": 2.3755600452,
      "lon": -63.0083007813,
      "tz": "America\/Boa_Vista"
  },
  "SJKT": {
      "icao": "SJKT",
      "iata": "",
      "name": "Contao Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 577,
      "lat": 4.17166996,
      "lon": -60.543598175,
      "tz": "America\/Boa_Vista"
  },
  "SJKU": {
      "icao": "SJKU",
      "iata": "",
      "name": "Cumaipa Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2188,
      "lat": 4.8138899803,
      "lon": -60.4735984802,
      "tz": "America\/Boa_Vista"
  },
  "SJKV": {
      "icao": "SJKV",
      "iata": "",
      "name": "Cumana I Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1191,
      "lat": 4.4702801704,
      "lon": -60.7780990601,
      "tz": "America\/Boa_Vista"
  },
  "SJKW": {
      "icao": "SJKW",
      "iata": "",
      "name": "Cumana II Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1191,
      "lat": 4.4269399643,
      "lon": -60.8238983154,
      "tz": "America\/Boa_Vista"
  },
  "SJKX": {
      "icao": "SJKX",
      "iata": "",
      "name": "Cutia Airport",
      "city": "Normandia",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "BR",
      "elevation": 496,
      "lat": 4.1700000763,
      "lon": -59.7486000061,
      "tz": "America\/Boa_Vista"
  },
  "SJKZ": {
      "icao": "SJKZ",
      "iata": "",
      "name": "Estevao Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 994,
      "lat": 4.4497199059,
      "lon": -60.439201355,
      "tz": "America\/Boa_Vista"
  },
  "SJLA": {
      "icao": "SJLA",
      "iata": "",
      "name": "Flechal Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1988,
      "lat": 4.6613898277,
      "lon": -60.2919006348,
      "tz": "America\/Boa_Vista"
  },
  "SJLB": {
      "icao": "SJLB",
      "iata": "",
      "name": "Fazenda Chaparral Airport",
      "city": "Lambari D`Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 949,
      "lat": -15.5019435883,
      "lon": -57.7672233582,
      "tz": "America\/Cuiaba"
  },
  "SJLC": {
      "icao": "SJLC",
      "iata": "",
      "name": "Hakoma Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1191,
      "lat": 2.7200000286,
      "lon": -63.5675010681,
      "tz": "America\/Boa_Vista"
  },
  "SJLD": {
      "icao": "SJLD",
      "iata": "",
      "name": "Fazenda Porto do Campo Airport",
      "city": "Lambari D`Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 594,
      "lat": -15.7155561447,
      "lon": -57.7113876343,
      "tz": "America\/Cuiaba"
  },
  "SJLE": {
      "icao": "SJLE",
      "iata": "",
      "name": "Halikato-U Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 994,
      "lat": 3.2249999046,
      "lon": -63.1994018555,
      "tz": "America\/Boa_Vista"
  },
  "SJLF": {
      "icao": "SJLF",
      "iata": "",
      "name": "Homoxi Airport",
      "city": "Iracema",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2284,
      "lat": 2.4977800846,
      "lon": -63.7296981812,
      "tz": "America\/Boa_Vista"
  },
  "SJLH": {
      "icao": "SJLH",
      "iata": "",
      "name": "Jacamim Airport",
      "city": "Bonfim",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "BR",
      "elevation": 495,
      "lat": 2.1761100292,
      "lon": -59.7839012146,
      "tz": "America\/Boa_Vista"
  },
  "SJLI": {
      "icao": "SJLI",
      "iata": "",
      "name": "Fazenda Lilliani Airport",
      "city": "Bom Jardim",
      "state": "Para",
      "country": "BR",
      "elevation": 958,
      "lat": -4.2136111259,
      "lon": -46.9749984741,
      "tz": "America\/Fortaleza"
  },
  "SJLJ": {
      "icao": "SJLJ",
      "iata": "",
      "name": "Jatapuzinho Airport",
      "city": "Caroebe",
      "state": "Roraima",
      "country": "BR",
      "elevation": 397,
      "lat": 0.5972219706,
      "lon": -59.221698761,
      "tz": "America\/Boa_Vista"
  },
  "SJLK": {
      "icao": "SJLK",
      "iata": "",
      "name": "Lago Grande I Airport",
      "city": "Bonfim",
      "state": "Roraima",
      "country": "BR",
      "elevation": 298,
      "lat": 3.4427800179,
      "lon": -60.3525009155,
      "tz": "America\/Boa_Vista"
  },
  "SJLL": {
      "icao": "SJLL",
      "iata": "",
      "name": "Maturuca Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1493,
      "lat": 4.4608302116,
      "lon": -60.1002998352,
      "tz": "America\/Boa_Vista"
  },
  "SJLM": {
      "icao": "SJLM",
      "iata": "",
      "name": "Leao de Ouro Airport",
      "city": "Pacaraima",
      "state": "",
      "country": "BR",
      "elevation": 298,
      "lat": 4.1516699791,
      "lon": -61.4277992249,
      "tz": "America\/Boa_Vista"
  },
  "SJLN": {
      "icao": "SJLN",
      "iata": "",
      "name": "Maloquinha Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1890,
      "lat": 4.726940155,
      "lon": -60.5905990601,
      "tz": "America\/Boa_Vista"
  },
  "SJLO": {
      "icao": "SJLO",
      "iata": "",
      "name": "Manalai Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2189,
      "lat": 5.0841698647,
      "lon": -60.3788986206,
      "tz": "America\/Boa_Vista"
  },
  "SJLP": {
      "icao": "SJLP",
      "iata": "",
      "name": "Manoa-Pium Airport",
      "city": "Bonfim",
      "state": "Roraima",
      "country": "BR",
      "elevation": 348,
      "lat": 2.9800000191,
      "lon": -60.0952987671,
      "tz": "America\/Boa_Vista"
  },
  "SJLQ": {
      "icao": "SJLQ",
      "iata": "",
      "name": "Maracana Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1791,
      "lat": 4.3649997711,
      "lon": -60.0167007446,
      "tz": "America\/Boa_Vista"
  },
  "SJLS": {
      "icao": "SJLS",
      "iata": "",
      "name": "Marupa Airport",
      "city": "Bonfim",
      "state": "Roraima",
      "country": "BR",
      "elevation": 495,
      "lat": 2.1252799034,
      "lon": -59.8213996887,
      "tz": "America\/Boa_Vista"
  },
  "SJLT": {
      "icao": "SJLT",
      "iata": "",
      "name": "Milho Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 358,
      "lat": 3.3855600357,
      "lon": -60.4077987671,
      "tz": "America\/Boa_Vista"
  },
  "SJLU": {
      "icao": "SJLU",
      "iata": "",
      "name": "Missao Catrinami Airport",
      "city": "Caracarai",
      "state": "Roraima",
      "country": "BR",
      "elevation": 397,
      "lat": 1.7408299446,
      "lon": -62.2863998413,
      "tz": "America\/Boa_Vista"
  },
  "SJLV": {
      "icao": "SJLV",
      "iata": "",
      "name": "Morro Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1792,
      "lat": 4.3572201729,
      "lon": -59.9700012207,
      "tz": "America\/Boa_Vista"
  },
  "SJLW": {
      "icao": "SJLW",
      "iata": "",
      "name": "Mudubim I Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1493,
      "lat": 4.42166996,
      "lon": -60.4897003174,
      "tz": "America\/Boa_Vista"
  },
  "SJLX": {
      "icao": "SJLX",
      "iata": "",
      "name": "Mutum Airport",
      "city": "Uiramuta",
      "state": "",
      "country": "BR",
      "elevation": 1792,
      "lat": 4.4530601501,
      "lon": -59.8539009094,
      "tz": "America\/Boa_Vista"
  },
  "SJLZ": {
      "icao": "SJLZ",
      "iata": "",
      "name": "Orinduque Airport",
      "city": "Uiramuta",
      "state": "Potaro-Siparuni",
      "country": "BR",
      "elevation": 1896,
      "lat": 4.7344398499,
      "lon": -60.0346984863,
      "tz": "America\/Boa_Vista"
  },
  "SJMA": {
      "icao": "SJMA",
      "iata": "",
      "name": "Fazenda Mundo Acabado Airport",
      "city": "Sonora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 525,
      "lat": -17.9355564117,
      "lon": -54.926109314,
      "tz": "America\/Campo_Grande"
  },
  "SJMD": {
      "icao": "SJMD",
      "iata": "",
      "name": "Paapiu Novo Airport",
      "city": "Iracema",
      "state": "Roraima",
      "country": "BR",
      "elevation": 696,
      "lat": 2.7227799892,
      "lon": -62.9502983093,
      "tz": "America\/Boa_Vista"
  },
  "SJME": {
      "icao": "SJME",
      "iata": "",
      "name": "Mato Grosso Airport",
      "city": "Pacaraima",
      "state": "Bolivar",
      "country": "BR",
      "elevation": 2783,
      "lat": 4.5705599785,
      "lon": -60.9030990601,
      "tz": "America\/Boa_Vista"
  },
  "SJMF": {
      "icao": "SJMF",
      "iata": "",
      "name": "Pacu Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 994,
      "lat": 4.1638898849,
      "lon": -60.2221984863,
      "tz": "America\/Boa_Vista"
  },
  "SJMH": {
      "icao": "SJMH",
      "iata": "",
      "name": "Palimiu Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 896,
      "lat": 3.3180599213,
      "lon": -62.9710998535,
      "tz": "America\/Boa_Vista"
  },
  "SJMI": {
      "icao": "SJMI",
      "iata": "",
      "name": "Parafuri Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1988,
      "lat": 3.2880599499,
      "lon": -63.8431015015,
      "tz": "America\/Boa_Vista"
  },
  "SJMJ": {
      "icao": "SJMJ",
      "iata": "",
      "name": "Pedra Branca Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1489,
      "lat": 4.4572200775,
      "lon": -60.2677993774,
      "tz": "America\/Boa_Vista"
  },
  "SJMK": {
      "icao": "SJMK",
      "iata": "",
      "name": "Pedra Preta Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2188,
      "lat": 4.7130599022,
      "lon": -60.476398468,
      "tz": "America\/Boa_Vista"
  },
  "SJML": {
      "icao": "SJML",
      "iata": "",
      "name": "Piolho Airport",
      "city": "Pacaraima",
      "state": "Bolivar",
      "country": "BR",
      "elevation": 2287,
      "lat": 4.7013897896,
      "lon": -60.7336006165,
      "tz": "America\/Boa_Vista"
  },
  "SJMM": {
      "icao": "SJMM",
      "iata": "",
      "name": "Pipi Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2585,
      "lat": 4.9299998283,
      "lon": -60.3218994141,
      "tz": "America\/Boa_Vista"
  },
  "SJMN": {
      "icao": "SJMN",
      "iata": "",
      "name": "Raposa Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 495,
      "lat": 3.8099999428,
      "lon": -60.0903015137,
      "tz": "America\/Boa_Vista"
  },
  "SJMO": {
      "icao": "SJMO",
      "iata": "",
      "name": "Santa Isabel Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1194,
      "lat": 4.4699997902,
      "lon": -60.8624992371,
      "tz": "America\/Boa_Vista"
  },
  "SJMP": {
      "icao": "SJMP",
      "iata": "",
      "name": "Santa Liberdade Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1890,
      "lat": 4.2874999046,
      "lon": -60.2569007874,
      "tz": "America\/Boa_Vista"
  },
  "SJMQ": {
      "icao": "SJMQ",
      "iata": "",
      "name": "Santa Maria de Normandia Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 847,
      "lat": 4.1702799797,
      "lon": -60.1377983093,
      "tz": "America\/Boa_Vista"
  },
  "SJMR": {
      "icao": "SJMR",
      "iata": "",
      "name": "Maragogipe Airport",
      "city": "Itaquirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 998,
      "lat": -23.4611110687,
      "lon": -54.2988891602,
      "tz": "America\/Campo_Grande"
  },
  "SJMT": {
      "icao": "SJMT",
      "iata": "",
      "name": "Sao Miguel Cachoeirinha Airport",
      "city": "Pacaraima",
      "state": "Bolivar",
      "country": "BR",
      "elevation": 1194,
      "lat": 4.4902801514,
      "lon": -60.9906005859,
      "tz": "America\/Boa_Vista"
  },
  "SJMU": {
      "icao": "SJMU",
      "iata": "",
      "name": "Sauparu Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2287,
      "lat": 4.9897198677,
      "lon": -60.4356002808,
      "tz": "America\/Boa_Vista"
  },
  "SJMV": {
      "icao": "SJMV",
      "iata": "",
      "name": "Serra do Sol Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2480,
      "lat": 4.9425001144,
      "lon": -60.4752998352,
      "tz": "America\/Boa_Vista"
  },
  "SJMW": {
      "icao": "SJMW",
      "iata": "",
      "name": "Soco Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1690,
      "lat": 4.4699997902,
      "lon": -60.1768989563,
      "tz": "America\/Boa_Vista"
  },
  "SJMX": {
      "icao": "SJMX",
      "iata": "",
      "name": "Suapi Airport",
      "city": "Pacaraima",
      "state": "Bolivar",
      "country": "BR",
      "elevation": 2487,
      "lat": 4.5752801895,
      "lon": -60.8582992554,
      "tz": "America\/Boa_Vista"
  },
  "SJMZ": {
      "icao": "SJMZ",
      "iata": "",
      "name": "Fazenda Mizote Airport",
      "city": "Sao Desiderio",
      "state": "",
      "country": "BR",
      "elevation": 2701,
      "lat": -12.9575004578,
      "lon": -45.9847221375,
      "tz": "America\/Bahia"
  },
  "SJNA": {
      "icao": "SJNA",
      "iata": "",
      "name": "Clube de Esportes Aereos e Nauticos do Para Airport",
      "city": "Belem",
      "state": "Para",
      "country": "BR",
      "elevation": 17,
      "lat": -1.2672220469,
      "lon": -48.4300003052,
      "tz": "America\/Belem"
  },
  "SJNB": {
      "icao": "SJNB",
      "iata": "",
      "name": "Ubaru Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1194,
      "lat": 4.5016698837,
      "lon": -60.8022003174,
      "tz": "America\/Boa_Vista"
  },
  "SJNC": {
      "icao": "SJNC",
      "iata": "",
      "name": "Uraricoera Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 794,
      "lat": 3.1461100578,
      "lon": -62.2303009033,
      "tz": "America\/Boa_Vista"
  },
  "SJND": {
      "icao": "SJND",
      "iata": "",
      "name": "Nova Descoberta Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 821,
      "lat": -6.618888855,
      "lon": -56.6925010681,
      "tz": "America\/Santarem"
  },
  "SJNE": {
      "icao": "SJNE",
      "iata": "",
      "name": "Wapum Airport",
      "city": "Bonfim",
      "state": "Roraima",
      "country": "BR",
      "elevation": 495,
      "lat": 1.9900000095,
      "lon": -59.9571990967,
      "tz": "America\/Boa_Vista"
  },
  "SJNF": {
      "icao": "SJNF",
      "iata": "",
      "name": "Wilimon Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1739,
      "lat": 4.6350002289,
      "lon": -60.1761016846,
      "tz": "America\/Boa_Vista"
  },
  "SJNG": {
      "icao": "SJNG",
      "iata": "",
      "name": "Xidea Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1988,
      "lat": 2.6069400311,
      "lon": -63.8694000244,
      "tz": "America\/Boa_Vista"
  },
  "SJNH": {
      "icao": "SJNH",
      "iata": "",
      "name": "Fazenda Nhu-Vera Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 492,
      "lat": -18.0272216797,
      "lon": -55.4602775574,
      "tz": "America\/Campo_Grande"
  },
  "SJNI": {
      "icao": "SJNI",
      "iata": "",
      "name": "Fazenda Mutum Airport",
      "city": "Nova Mutum",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1575,
      "lat": -13.9197216034,
      "lon": -56.0844421387,
      "tz": "America\/Cuiaba"
  },
  "SJNJ": {
      "icao": "SJNJ",
      "iata": "",
      "name": "Fazenda Lakanka Airport",
      "city": "Jussara",
      "state": "Goias",
      "country": "BR",
      "elevation": 892,
      "lat": -15.4324998856,
      "lon": -51.5905570984,
      "tz": "America\/Sao_Paulo"
  },
  "SJNK": {
      "icao": "SJNK",
      "iata": "",
      "name": "Fazenda Tres Perobas Airport",
      "city": "Santa Luzia D`Oeste",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1119,
      "lat": -12.0816669464,
      "lon": -61.6399993896,
      "tz": "America\/Porto_Velho"
  },
  "SJNL": {
      "icao": "SJNL",
      "iata": "",
      "name": "Fazenda Reata Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1791,
      "lat": -13.6202783585,
      "lon": -57.5808334351,
      "tz": "America\/Cuiaba"
  },
  "SJNM": {
      "icao": "SJNM",
      "iata": "",
      "name": "Fazenda Santa Luzia Airport",
      "city": "Nova Mutum",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1520,
      "lat": -13.9997215271,
      "lon": -55.9452781677,
      "tz": "America\/Cuiaba"
  },
  "SJNN": {
      "icao": "SJNN",
      "iata": "",
      "name": "Fazenda Botelho Airport",
      "city": "Nova Mamore",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 535,
      "lat": -10.2222223282,
      "lon": -64.3433303833,
      "tz": "America\/Porto_Velho"
  },
  "SJNO": {
      "icao": "SJNO",
      "iata": "",
      "name": "Fazenda Nazare Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 348,
      "lat": -18.6736106873,
      "lon": -57.1233329773,
      "tz": "America\/Campo_Grande"
  },
  "SJNP": {
      "icao": "SJNP",
      "iata": "NPR",
      "name": "Novo Progresso Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 794,
      "lat": -7.1258330345,
      "lon": -55.4008331299,
      "tz": "America\/Santarem"
  },
  "SJNQ": {
      "icao": "SJNQ",
      "iata": "",
      "name": "Destilaria Medasa Airport",
      "city": "Medeiros Neto",
      "state": "Bahia",
      "country": "BR",
      "elevation": 656,
      "lat": -17.4850006104,
      "lon": -40.1577796936,
      "tz": "America\/Bahia"
  },
  "SJNR": {
      "icao": "SJNR",
      "iata": "",
      "name": "Norte Jet Airport",
      "city": "Benevides",
      "state": "Para",
      "country": "BR",
      "elevation": 88,
      "lat": -1.3072220087,
      "lon": -48.2574996948,
      "tz": "America\/Belem"
  },
  "SJNS": {
      "icao": "SJNS",
      "iata": "",
      "name": "Ilha dos Macacos Airport",
      "city": "Paranaita",
      "state": "",
      "country": "BR",
      "elevation": 1266,
      "lat": -9.2255563736,
      "lon": -57.0224990845,
      "tz": "America\/Cuiaba"
  },
  "SJNU": {
      "icao": "SJNU",
      "iata": "",
      "name": "Fazenda Campo Alto Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1099,
      "lat": -13.0372219086,
      "lon": -51.7138900757,
      "tz": "America\/Cuiaba"
  },
  "SJNV": {
      "icao": "SJNV",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Gouvelandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1857,
      "lat": -18.431388855,
      "lon": -50.256111145,
      "tz": "America\/Sao_Paulo"
  },
  "SJNW": {
      "icao": "SJNW",
      "iata": "",
      "name": "Fazenda Nhandu Airport",
      "city": "Novo Mundo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1103,
      "lat": -9.6338891983,
      "lon": -55.2269439697,
      "tz": "America\/Cuiaba"
  },
  "SJNX": {
      "icao": "SJNX",
      "iata": "",
      "name": "Cosmos Aviacao Agricola Airport",
      "city": "Chapadao Do Ceu",
      "state": "Goias",
      "country": "BR",
      "elevation": 2730,
      "lat": -18.4080562592,
      "lon": -52.630279541,
      "tz": "America\/Sao_Paulo"
  },
  "SJNZ": {
      "icao": "SJNZ",
      "iata": "",
      "name": "Fazenda Tres Lagoas Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1746,
      "lat": -13.2527780533,
      "lon": -58.7291679382,
      "tz": "America\/Cuiaba"
  },
  "SJOG": {
      "icao": "SJOG",
      "iata": "",
      "name": "Ariquemes Airport",
      "city": "Ariquemes",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 530,
      "lat": -9.884721756,
      "lon": -63.0488891602,
      "tz": "America\/Porto_Velho"
  },
  "SJOH": {
      "icao": "SJOH",
      "iata": "",
      "name": "Juruti Airport",
      "city": "Juruti",
      "state": "Para",
      "country": "BR",
      "elevation": 108,
      "lat": -2.1866669655,
      "lon": -56.0902786255,
      "tz": "America\/Santarem"
  },
  "SJOI": {
      "icao": "SJOI",
      "iata": "",
      "name": "Fazenda Jaguatirica Airport",
      "city": "Porto Murtinho",
      "state": "Alto-Paraguay",
      "country": "BR",
      "elevation": 289,
      "lat": -22.0322227478,
      "lon": -57.9819450378,
      "tz": "America\/Asuncion"
  },
  "SJOJ": {
      "icao": "SJOJ",
      "iata": "",
      "name": "Simasa Airport",
      "city": "Acailandia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 656,
      "lat": -4.869166851,
      "lon": -47.3891677856,
      "tz": "America\/Fortaleza"
  },
  "SJOK": {
      "icao": "SJOK",
      "iata": "",
      "name": "Fazenda Modelo Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2041,
      "lat": -19.3155555725,
      "lon": -52.9766654968,
      "tz": "America\/Campo_Grande"
  },
  "SJOM": {
      "icao": "SJOM",
      "iata": "",
      "name": "Fazenda Karla Renata Airport",
      "city": "Castanheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 991,
      "lat": -10.6324996948,
      "lon": -58.4449996948,
      "tz": "America\/Cuiaba"
  },
  "SJON": {
      "icao": "SJON",
      "iata": "",
      "name": "Fazenda Sao Jose do Generoso Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -18.8724994659,
      "lon": -56.7891654968,
      "tz": "America\/Campo_Grande"
  },
  "SJOO": {
      "icao": "SJOO",
      "iata": "",
      "name": "Doutor Joao Osmar de Oliveira Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 864,
      "lat": -14.1341667175,
      "lon": -59.8197212219,
      "tz": "America\/Cuiaba"
  },
  "SJOR": {
      "icao": "SJOR",
      "iata": "",
      "name": "Fazenda Parabufalos Airport",
      "city": "Macapa",
      "state": "Amapa",
      "country": "BR",
      "elevation": 30,
      "lat": 1.0194400549,
      "lon": -50.2139015198,
      "tz": "America\/Belem"
  },
  "SJOU": {
      "icao": "SJOU",
      "iata": "",
      "name": "Marfrig Frigorifico Airport",
      "city": "Paranatinga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1628,
      "lat": -14.453332901,
      "lon": -54.0336112976,
      "tz": "America\/Cuiaba"
  },
  "SJOW": {
      "icao": "SJOW",
      "iata": "",
      "name": "Fazenda Guadalupe Airport",
      "city": "Santo Antonio Do Aracangua",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1188,
      "lat": -20.9816665649,
      "lon": -50.5269432068,
      "tz": "America\/Sao_Paulo"
  },
  "SJOX": {
      "icao": "SJOX",
      "iata": "",
      "name": "Fazenda Brinquinho Airport",
      "city": "Sao Desiderio",
      "state": "",
      "country": "BR",
      "elevation": 3145,
      "lat": -12.8249998093,
      "lon": -46.2449989319,
      "tz": "America\/Araguaina"
  },
  "SJOY": {
      "icao": "SJOY",
      "iata": "",
      "name": "Fazenda Sao Miguel Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2999,
      "lat": -12.6072216034,
      "lon": -46.2394447327,
      "tz": "America\/Araguaina"
  },
  "SJPA": {
      "icao": "SJPA",
      "iata": "",
      "name": "EMAL - Pimenta Bueno Airport",
      "city": "Pimenta Bueno",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1001,
      "lat": -11.6861114502,
      "lon": -60.6297225952,
      "tz": "America\/Porto_Velho"
  },
  "SJPG": {
      "icao": "SJPG",
      "iata": "",
      "name": "Pista Branca de Neve Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 545,
      "lat": -6.125,
      "lon": -56.3988876343,
      "tz": "America\/Santarem"
  },
  "SJPH": {
      "icao": "SJPH",
      "iata": "",
      "name": "Fazenda Palma Airport",
      "city": "Luziania",
      "state": "Goias",
      "country": "BR",
      "elevation": 3297,
      "lat": -16.1894435883,
      "lon": -48.0780563354,
      "tz": "America\/Sao_Paulo"
  },
  "SJPI": {
      "icao": "SJPI",
      "iata": "",
      "name": "Fazenda Pirapitinga Airport",
      "city": "Cocalzinho De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 2510,
      "lat": -15.4127779007,
      "lon": -48.6341667175,
      "tz": "America\/Sao_Paulo"
  },
  "SJPK": {
      "icao": "SJPK",
      "iata": "",
      "name": "Marcos da Cunha Airport",
      "city": "Luis Eduardo Magalhaes",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2815,
      "lat": -12.3613891602,
      "lon": -46.1341667175,
      "tz": "America\/Bahia"
  },
  "SJPL": {
      "icao": "SJPL",
      "iata": "",
      "name": "Fazenda Australia Airport",
      "city": "Deodapolis",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1116,
      "lat": -21.9741668701,
      "lon": -54.1477775574,
      "tz": "America\/Campo_Grande"
  },
  "SJPO": {
      "icao": "SJPO",
      "iata": "",
      "name": "Xingo Airport",
      "city": "Piranhas",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 791,
      "lat": -9.5877780914,
      "lon": -37.7783317566,
      "tz": "America\/Maceio"
  },
  "SJPP": {
      "icao": "SJPP",
      "iata": "",
      "name": "Aero Rural Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 879,
      "lat": -9.8569440842,
      "lon": -56.0044441223,
      "tz": "America\/Cuiaba"
  },
  "SJPQ": {
      "icao": "SJPQ",
      "iata": "",
      "name": "Avstal Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3205,
      "lat": -16.3197212219,
      "lon": -47.225276947,
      "tz": "America\/Sao_Paulo"
  },
  "SJPS": {
      "icao": "SJPS",
      "iata": "",
      "name": "Fazenda Monte Belo Airport",
      "city": "Barra Do Bugres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 636,
      "lat": -14.9799995422,
      "lon": -57.9916687012,
      "tz": "America\/Cuiaba"
  },
  "SJPU": {
      "icao": "SJPU",
      "iata": "",
      "name": "Pau D`Arco Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 866,
      "lat": -7.1199998856,
      "lon": -56.8144454956,
      "tz": "America\/Santarem"
  },
  "SJPV": {
      "icao": "SJPV",
      "iata": "",
      "name": "Fazenda Guimaraes Airport",
      "city": "Lucas Do Rio Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1378,
      "lat": -12.9763889313,
      "lon": -56.1836128235,
      "tz": "America\/Cuiaba"
  },
  "SJPW": {
      "icao": "SJPW",
      "iata": "",
      "name": "Fazenda Guimaraes II Airport",
      "city": "Lucas Do Rio Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1312,
      "lat": -12.9019441605,
      "lon": -56.3719444275,
      "tz": "America\/Cuiaba"
  },
  "SJPX": {
      "icao": "SJPX",
      "iata": "",
      "name": "Fazenda Cidade Verde Airport",
      "city": "Poxoreo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2028,
      "lat": -15.4152784348,
      "lon": -54.3722229004,
      "tz": "America\/Cuiaba"
  },
  "SJQA": {
      "icao": "SJQA",
      "iata": "",
      "name": "Fazenda Clarim Airport",
      "city": "Sao Desiderio",
      "state": "Goias",
      "country": "BR",
      "elevation": 3121,
      "lat": -13.2122220993,
      "lon": -46.2388877869,
      "tz": "America\/Bahia"
  },
  "SJQB": {
      "icao": "SJQB",
      "iata": "",
      "name": "Fazenda Symalu Airport",
      "city": "Cumaru Do Norte",
      "state": "Para",
      "country": "BR",
      "elevation": 601,
      "lat": -8.3275003433,
      "lon": -51.1049995422,
      "tz": "America\/Belem"
  },
  "SJQD": {
      "icao": "SJQD",
      "iata": "",
      "name": "Fazenda Sao Roque do Vale do Rio Negro Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 296,
      "lat": -19.3691673279,
      "lon": -56.3488883972,
      "tz": "America\/Campo_Grande"
  },
  "SJQE": {
      "icao": "SJQE",
      "iata": "",
      "name": "Bonacin Airport",
      "city": "Piraquara",
      "state": "Parana",
      "country": "BR",
      "elevation": 2999,
      "lat": -25.4077134595,
      "lon": -49.0504789352,
      "tz": "America\/Sao_Paulo"
  },
  "SJQF": {
      "icao": "SJQF",
      "iata": "",
      "name": "Fazenda Londrina Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1690,
      "lat": -13.3555555344,
      "lon": -57.7550010681,
      "tz": "America\/Cuiaba"
  },
  "SJQH": {
      "icao": "SJQH",
      "iata": "",
      "name": "Fazenda Tempero Seco Airport",
      "city": "Labrea",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 375,
      "lat": -9.3963890076,
      "lon": -66.679725647,
      "tz": "America\/Manaus"
  },
  "SJQI": {
      "icao": "SJQI",
      "iata": "",
      "name": "Porto Jofre Airport",
      "city": "Pocone",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 361,
      "lat": -17.3613891602,
      "lon": -56.7791671753,
      "tz": "America\/Campo_Grande"
  },
  "SJQK": {
      "icao": "SJQK",
      "iata": "",
      "name": "Barra do Vento Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 279,
      "lat": 2.6955599785,
      "lon": -60.814201355,
      "tz": "America\/Boa_Vista"
  },
  "SJQN": {
      "icao": "SJQN",
      "iata": "",
      "name": "Quirinopolis Airport",
      "city": "Quirinopolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 1922,
      "lat": -18.446100235,
      "lon": -50.409198761,
      "tz": "America\/Sao_Paulo"
  },
  "SJQQ": {
      "icao": "SJQQ",
      "iata": "",
      "name": "Fazenda Terra Alta Airport",
      "city": "Portel",
      "state": "Para",
      "country": "BR",
      "elevation": 66,
      "lat": -2.8650000095,
      "lon": -50.9927787781,
      "tz": "America\/Belem"
  },
  "SJQR": {
      "icao": "SJQR",
      "iata": "",
      "name": "Jacarezinho Airport",
      "city": "Valparaiso",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1312,
      "lat": -21.1480560303,
      "lon": -50.9011116028,
      "tz": "America\/Sao_Paulo"
  },
  "SJQS": {
      "icao": "SJQS",
      "iata": "",
      "name": "Paredao da Serra Airport",
      "city": "Chapada Dos Guimaraes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2117,
      "lat": -15.4719438553,
      "lon": -55.7200012207,
      "tz": "America\/Cuiaba"
  },
  "SJQT": {
      "icao": "SJQT",
      "iata": "",
      "name": "Fazenda Haras RPC Airport",
      "city": "Nova Lima",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 4209,
      "lat": -20.1686115265,
      "lon": -43.9269447327,
      "tz": "America\/Sao_Paulo"
  },
  "SJQV": {
      "icao": "SJQV",
      "iata": "",
      "name": "Usina Santa Luiza Airport",
      "city": "Motuca",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1978,
      "lat": -21.5091667175,
      "lon": -48.1972236633,
      "tz": "America\/Sao_Paulo"
  },
  "SJQX": {
      "icao": "SJQX",
      "iata": "",
      "name": "Fazenda Uruara Airport",
      "city": "Uruara",
      "state": "Para",
      "country": "BR",
      "elevation": 797,
      "lat": -3.5247220993,
      "lon": -53.3861122131,
      "tz": "America\/Santarem"
  },
  "SJQZ": {
      "icao": "SJQZ",
      "iata": "",
      "name": "Dona Iracema Airport",
      "city": "Porto Nacional",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 919,
      "lat": -10.6063890457,
      "lon": -48.3486099243,
      "tz": "America\/Araguaina"
  },
  "SJRA": {
      "icao": "SJRA",
      "iata": "",
      "name": "Pesqueiro Xingu Airport",
      "city": "Sao Felix Do Xingu",
      "state": "Para",
      "country": "BR",
      "elevation": 525,
      "lat": -5.7750000954,
      "lon": -52.6222229004,
      "tz": "America\/Belem"
  },
  "SJRB": {
      "icao": "SJRB",
      "iata": "",
      "name": "Fazenda VR Airport",
      "city": "Santa Cruz Do Xingu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1191,
      "lat": -10.1130561829,
      "lon": -52.5050010681,
      "tz": "America\/Cuiaba"
  },
  "SJRC": {
      "icao": "SJRC",
      "iata": "",
      "name": "Serra da Borda Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1575,
      "lat": -14.8272218704,
      "lon": -59.6880569458,
      "tz": "America\/Cuiaba"
  },
  "SJRG": {
      "icao": "SJRG",
      "iata": "RIG",
      "name": "Rio Grande Airport",
      "city": "Rio Grande",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 27,
      "lat": -32.081699,
      "lon": -52.163299,
      "tz": "America\/Sao_Paulo"
  },
  "SJRH": {
      "icao": "SJRH",
      "iata": "",
      "name": "Fazenda Galheiro Airport",
      "city": "Campo Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1788,
      "lat": -15.3080558777,
      "lon": -54.5997238159,
      "tz": "America\/Cuiaba"
  },
  "SJRI": {
      "icao": "SJRI",
      "iata": "",
      "name": "Condominio Boa Esperanca Airport",
      "city": "Baixa Grande Do Ribeiro",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1825,
      "lat": -8.4063892365,
      "lon": -45.4413871765,
      "tz": "America\/Fortaleza"
  },
  "SJRJ": {
      "icao": "SJRJ",
      "iata": "",
      "name": "Agromineral Mutum Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 752,
      "lat": -7.6402778625,
      "lon": -56.787776947,
      "tz": "America\/Santarem"
  },
  "SJRK": {
      "icao": "SJRK",
      "iata": "",
      "name": "Agropecuaria Sao Roberto Airport",
      "city": "Santana Do Araguaia",
      "state": "Para",
      "country": "BR",
      "elevation": 860,
      "lat": -9.2322216034,
      "lon": -50.9302787781,
      "tz": "America\/Belem"
  },
  "SJRL": {
      "icao": "SJRL",
      "iata": "",
      "name": "Fazenda do Acucar Airport",
      "city": "Nobres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -14.4586105347,
      "lon": -56.0527763367,
      "tz": "America\/Cuiaba"
  },
  "SJRO": {
      "icao": "SJRO",
      "iata": "",
      "name": "Fazenda Santa Elisa Airport",
      "city": "Auriflama",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1312,
      "lat": -20.5305557251,
      "lon": -50.6349983215,
      "tz": "America\/Sao_Paulo"
  },
  "SJRP": {
      "icao": "SJRP",
      "iata": "",
      "name": "Fazenda Santangelo Agropecuaria Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 450,
      "lat": -20.1324996948,
      "lon": -56.3711128235,
      "tz": "America\/Campo_Grande"
  },
  "SJRR": {
      "icao": "SJRR",
      "iata": "",
      "name": "Fazenda Barreiro Airport",
      "city": "Parauna",
      "state": "Goias",
      "country": "BR",
      "elevation": 2461,
      "lat": -17.0541667938,
      "lon": -50.9283332825,
      "tz": "America\/Sao_Paulo"
  },
  "SJRS": {
      "icao": "SJRS",
      "iata": "",
      "name": "Fazenda Guanabara Airport",
      "city": "Junqueiropolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1017,
      "lat": -21.3436107635,
      "lon": -51.4033317566,
      "tz": "America\/Sao_Paulo"
  },
  "SJRT": {
      "icao": "SJRT",
      "iata": "",
      "name": "Fazenda Arrendamento Airport",
      "city": "Paranatinga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1499,
      "lat": -14.3694438934,
      "lon": -54.0024986267,
      "tz": "America\/Cuiaba"
  },
  "SJRV": {
      "icao": "SJRV",
      "iata": "",
      "name": "PCH Garganta da Jararaca Airport",
      "city": "Nova Maringa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1394,
      "lat": -13.3861112595,
      "lon": -57.6155548096,
      "tz": "America\/Cuiaba"
  },
  "SJRW": {
      "icao": "SJRW",
      "iata": "",
      "name": "PCH Buriti Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1581,
      "lat": -19.3411102295,
      "lon": -52.6894454956,
      "tz": "America\/Campo_Grande"
  },
  "SJSA": {
      "icao": "SJSA",
      "iata": "",
      "name": "Fazenda Aimores Airport",
      "city": "Juti",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1263,
      "lat": -22.7897224426,
      "lon": -54.5977783203,
      "tz": "America\/Campo_Grande"
  },
  "SJSC": {
      "icao": "SJSC",
      "iata": "",
      "name": "Fazenda Sao Sebastiao do Castelo Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 358,
      "lat": -18.6611118317,
      "lon": -57.6238899231,
      "tz": "America\/Campo_Grande"
  },
  "SJSD": {
      "icao": "SJSD",
      "iata": "",
      "name": "Fazenda Sao Bento Airport",
      "city": "Sidrolandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1772,
      "lat": -21.0727787018,
      "lon": -55.1283340454,
      "tz": "America\/Campo_Grande"
  },
  "SJSE": {
      "icao": "SJSE",
      "iata": "",
      "name": "Serabi Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 1017,
      "lat": -6.3197221756,
      "lon": -55.7949981689,
      "tz": "America\/Santarem"
  },
  "SJSF": {
      "icao": "SJSF",
      "iata": "",
      "name": "Agropecuaria Barrinha Airport",
      "city": "Sao Francisco Sales",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1310,
      "lat": -19.8413887024,
      "lon": -49.688331604,
      "tz": "America\/Sao_Paulo"
  },
  "SJSH": {
      "icao": "SJSH",
      "iata": "",
      "name": "Sonhar Airport",
      "city": "Governador Celso Ramos",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 33,
      "lat": -27.3705558777,
      "lon": -48.6122207642,
      "tz": "America\/Sao_Paulo"
  },
  "SJSJ": {
      "icao": "SJSJ",
      "iata": "",
      "name": "Fazenda Sao Jose do Cangalha Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1201,
      "lat": -19.5155563354,
      "lon": -52.6047210693,
      "tz": "America\/Campo_Grande"
  },
  "SJSK": {
      "icao": "SJSK",
      "iata": "",
      "name": "Mario Spada Airport",
      "city": "Minas Novas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3271,
      "lat": -17.5283336639,
      "lon": -42.3836097717,
      "tz": "America\/Sao_Paulo"
  },
  "SJSL": {
      "icao": "SJSL",
      "iata": "",
      "name": "Fazenda Santa Luiza Airport",
      "city": "Icaraima",
      "state": "Parana",
      "country": "BR",
      "elevation": 1162,
      "lat": -23.3477783203,
      "lon": -53.5,
      "tz": "America\/Sao_Paulo"
  },
  "SJSM": {
      "icao": "SJSM",
      "iata": "",
      "name": "Fazenda Santa Rita Airport",
      "city": "Itaruma",
      "state": "Goias",
      "country": "BR",
      "elevation": 1451,
      "lat": -19.006111145,
      "lon": -50.9522209167,
      "tz": "America\/Sao_Paulo"
  },
  "SJSN": {
      "icao": "SJSN",
      "iata": "",
      "name": "Fazenda Carvalho Airport",
      "city": "Sao Luiz Do Norte",
      "state": "Goias",
      "country": "BR",
      "elevation": 1871,
      "lat": -14.8366670609,
      "lon": -49.3613891602,
      "tz": "America\/Sao_Paulo"
  },
  "SJSO": {
      "icao": "SJSO",
      "iata": "",
      "name": "Fazenda Divisao II Airport",
      "city": "Lucas Do Rio Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1358,
      "lat": -12.9536113739,
      "lon": -56.3183326721,
      "tz": "America\/Cuiaba"
  },
  "SJSR": {
      "icao": "SJSR",
      "iata": "",
      "name": "Fazenda Santa Rosa Airport",
      "city": "Mara Rosa",
      "state": "Goias",
      "country": "BR",
      "elevation": 1044,
      "lat": -13.9922218323,
      "lon": -49.7630577087,
      "tz": "America\/Sao_Paulo"
  },
  "SJSS": {
      "icao": "SJSS",
      "iata": "",
      "name": "Agrifor Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1100,
      "lat": -12.5083332062,
      "lon": -55.6952781677,
      "tz": "America\/Cuiaba"
  },
  "SJSU": {
      "icao": "SJSU",
      "iata": "",
      "name": "Fazenda Sao Marcus Airport",
      "city": "Vila Alta",
      "state": "Parana",
      "country": "BR",
      "elevation": 1083,
      "lat": -23.5641670227,
      "lon": -53.7972221375,
      "tz": "America\/Sao_Paulo"
  },
  "SJTA": {
      "icao": "SJTA",
      "iata": "",
      "name": "Cristal Airport",
      "city": "Bora",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1355,
      "lat": -22.1816673279,
      "lon": -50.4925003052,
      "tz": "America\/Sao_Paulo"
  },
  "SJTB": {
      "icao": "SJTB",
      "iata": "",
      "name": "Fazenda Buriti Airport",
      "city": "Chapada Dos Guimaraes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2451,
      "lat": -15.4650001526,
      "lon": -55.6225013733,
      "tz": "America\/Cuiaba"
  },
  "SJTF": {
      "icao": "SJTF",
      "iata": "",
      "name": "Fazenda Mequens Airport",
      "city": "Alta Floresta D`Oeste",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 552,
      "lat": -13.0619440079,
      "lon": -62.2574996948,
      "tz": "America\/Porto_Velho"
  },
  "SJTG": {
      "icao": "SJTG",
      "iata": "",
      "name": "Fazenda Calcario Tangara Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1831,
      "lat": -14.4502782822,
      "lon": -57.8005561829,
      "tz": "America\/Cuiaba"
  },
  "SJTI": {
      "icao": "SJTI",
      "iata": "",
      "name": "Fazenda Iapo Airport",
      "city": "Tibagi",
      "state": "Parana",
      "country": "BR",
      "elevation": 2700,
      "lat": -24.5277786255,
      "lon": -50.3658332825,
      "tz": "America\/Sao_Paulo"
  },
  "SJTJ": {
      "icao": "SJTJ",
      "iata": "",
      "name": "Fazenda Guanabara Airport",
      "city": "Nova Alvorada Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1450,
      "lat": -21.1166667938,
      "lon": -54.44972229,
      "tz": "America\/Campo_Grande"
  },
  "SJTK": {
      "icao": "SJTK",
      "iata": "",
      "name": "Ecofly Airport",
      "city": "Brotas",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2129,
      "lat": -22.2969436646,
      "lon": -48.1202774048,
      "tz": "America\/Sao_Paulo"
  },
  "SJTL": {
      "icao": "SJTL",
      "iata": "",
      "name": "Aero Agricola Gaivota Airport",
      "city": "Jaguapita",
      "state": "Parana",
      "country": "BR",
      "elevation": 1821,
      "lat": -22.9786109924,
      "lon": -51.5486106873,
      "tz": "America\/Sao_Paulo"
  },
  "SJTM": {
      "icao": "SJTM",
      "iata": "",
      "name": "Santa Cecilia Airport",
      "city": "Mirandopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1260,
      "lat": -20.8877773285,
      "lon": -50.9883346558,
      "tz": "America\/Sao_Paulo"
  },
  "SJTN": {
      "icao": "SJTN",
      "iata": "",
      "name": "Fazenda Alvorada do Caxingo Airport",
      "city": "Monte Alegre De Goias",
      "state": "",
      "country": "BR",
      "elevation": 1742,
      "lat": -13.375,
      "lon": -46.8974990845,
      "tz": "America\/Sao_Paulo"
  },
  "SJTO": {
      "icao": "SJTO",
      "iata": "",
      "name": "Sao Jose Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2083,
      "lat": -14.1438894272,
      "lon": -57.7805557251,
      "tz": "America\/Cuiaba"
  },
  "SJTP": {
      "icao": "SJTP",
      "iata": "",
      "name": "Boa Safra Airport",
      "city": "Tapura",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1321,
      "lat": -12.728055954,
      "lon": -56.5027770996,
      "tz": "America\/Cuiaba"
  },
  "SJTQ": {
      "icao": "SJTQ",
      "iata": "",
      "name": "Fazenda Santa tereza Airport",
      "city": "Petropolis",
      "state": "Rio-de-Janeiro",
      "country": "BR",
      "elevation": 2087,
      "lat": -22.368888855,
      "lon": -43.2513885498,
      "tz": "America\/Sao_Paulo"
  },
  "SJTS": {
      "icao": "SJTS",
      "iata": "",
      "name": "Terra Santa Airport",
      "city": "Terra Santa",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 83,
      "lat": -2.0727779865,
      "lon": -56.4888877869,
      "tz": "America\/Santarem"
  },
  "SJTT": {
      "icao": "SJTT",
      "iata": "",
      "name": "Fazenda Lagoa do Barro Airport",
      "city": "Novo Planalto",
      "state": "Goias",
      "country": "BR",
      "elevation": 926,
      "lat": -13.2386112213,
      "lon": -49.7094421387,
      "tz": "America\/Sao_Paulo"
  },
  "SJTU": {
      "icao": "SJTU",
      "iata": "",
      "name": "Fazenda Tucano Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1765,
      "lat": -13.582777977,
      "lon": -57.5594444275,
      "tz": "America\/Cuiaba"
  },
  "SJTV": {
      "icao": "SJTV",
      "iata": "",
      "name": "Fazenda Guerra Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -11.4741668701,
      "lon": -57.9211120605,
      "tz": "America\/Cuiaba"
  },
  "SJTZ": {
      "icao": "SJTZ",
      "iata": "",
      "name": "Dom Eliseu Airport",
      "city": "Dom Eliseu",
      "state": "",
      "country": "BR",
      "elevation": 800,
      "lat": -4.3027777672,
      "lon": -47.5816688538,
      "tz": "America\/Belem"
  },
  "SJUA": {
      "icao": "SJUA",
      "iata": "",
      "name": "Fazenda Ipanema Airport",
      "city": "Correntina",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3101,
      "lat": -13.6258325577,
      "lon": -46.1397209167,
      "tz": "America\/Bahia"
  },
  "SJUB": {
      "icao": "SJUB",
      "iata": "",
      "name": "Fazenda Rio Azul Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "",
      "country": "BR",
      "elevation": 896,
      "lat": -15.1833333969,
      "lon": -60.2216682434,
      "tz": "America\/Cuiaba"
  },
  "SJUC": {
      "icao": "SJUC",
      "iata": "",
      "name": "Jardim Paraiso Airport",
      "city": "Luis Eduardo Magalhaes",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2395,
      "lat": -12.108332634,
      "lon": -45.7775001526,
      "tz": "America\/Bahia"
  },
  "SJUD": {
      "icao": "SJUD",
      "iata": "",
      "name": "Usina Dourados Airport",
      "city": "Dourados",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1420,
      "lat": -21.990278244,
      "lon": -55.1130561829,
      "tz": "America\/Campo_Grande"
  },
  "SJUH": {
      "icao": "SJUH",
      "iata": "",
      "name": "Fazenda Lagoinha Airport",
      "city": "Pedrinopolis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3346,
      "lat": -19.2386112213,
      "lon": -47.4977798462,
      "tz": "America\/Sao_Paulo"
  },
  "SJUJ": {
      "icao": "SJUJ",
      "iata": "",
      "name": "Fazenda Juina Airport",
      "city": "Campos De Julio",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2215,
      "lat": -13.4536113739,
      "lon": -59.2602767944,
      "tz": "America\/Cuiaba"
  },
  "SJUK": {
      "icao": "SJUK",
      "iata": "",
      "name": "Fazenda Capitao Verdi Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2435,
      "lat": -14.3599996567,
      "lon": -57.9177780151,
      "tz": "America\/Cuiaba"
  },
  "SJUL": {
      "icao": "SJUL",
      "iata": "",
      "name": "Fazenda Rio Pardo Airport",
      "city": "Iaras",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2257,
      "lat": -22.8777770996,
      "lon": -49.037776947,
      "tz": "America\/Sao_Paulo"
  },
  "SJUQ": {
      "icao": "SJUQ",
      "iata": "",
      "name": "Fazenda Vista Alegre Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1349,
      "lat": -19.7391662598,
      "lon": -52.6061096191,
      "tz": "America\/Campo_Grande"
  },
  "SJUS": {
      "icao": "SJUS",
      "iata": "",
      "name": "Fazenda Acara Airport",
      "city": "Britania",
      "state": "Goias",
      "country": "BR",
      "elevation": 804,
      "lat": -15.0744438171,
      "lon": -51.1588897705,
      "tz": "America\/Sao_Paulo"
  },
  "SJUU": {
      "icao": "SJUU",
      "iata": "",
      "name": "Fazenda Parana Airport",
      "city": "Nova Roma",
      "state": "Goias",
      "country": "BR",
      "elevation": 1450,
      "lat": -14.0280561447,
      "lon": -46.8711128235,
      "tz": "America\/Sao_Paulo"
  },
  "SJVB": {
      "icao": "SJVB",
      "iata": "",
      "name": "Fazenda Sagrado Coracao de Jesus Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 499,
      "lat": -15.0916671753,
      "lon": -60.1216659546,
      "tz": "America\/Cuiaba"
  },
  "SJVC": {
      "icao": "SJVC",
      "iata": "",
      "name": "Ibiporanga da Felicidade Airport",
      "city": "Tanabi",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1779,
      "lat": -20.4591674805,
      "lon": -49.5336112976,
      "tz": "America\/Sao_Paulo"
  },
  "SJVD": {
      "icao": "SJVD",
      "iata": "",
      "name": "Nazare da Felicidade Airport",
      "city": "Buritizeiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2550,
      "lat": -17.8547210693,
      "lon": -45.2405548096,
      "tz": "America\/Sao_Paulo"
  },
  "SJVF": {
      "icao": "SJVF",
      "iata": "",
      "name": "Fazenda Colorado Airport",
      "city": "Figueirao",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1785,
      "lat": -18.8177776337,
      "lon": -53.8650016785,
      "tz": "America\/Campo_Grande"
  },
  "SJVL": {
      "icao": "SJVL",
      "iata": "",
      "name": "Fazenda Cachoeirinha Airport",
      "city": "Perdizes",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3281,
      "lat": -19.3702774048,
      "lon": -47.1288871765,
      "tz": "America\/Sao_Paulo"
  },
  "SJVM": {
      "icao": "SJVM",
      "iata": "",
      "name": "Estancia California Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 945,
      "lat": -9.8919439316,
      "lon": -56.1294441223,
      "tz": "America\/Cuiaba"
  },
  "SJVN": {
      "icao": "SJVN",
      "iata": "",
      "name": "Fazenda do Limao Airport",
      "city": "Almeirim",
      "state": "Para",
      "country": "BR",
      "elevation": 984,
      "lat": 0.2891669869,
      "lon": -53.8699989319,
      "tz": "America\/Santarem"
  },
  "SJVO": {
      "icao": "SJVO",
      "iata": "",
      "name": "Aragarcas Airport",
      "city": "Aragarcas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1061,
      "lat": -15.8993997574,
      "lon": -52.2411003113,
      "tz": "America\/Cuiaba"
  },
  "SJVP": {
      "icao": "SJVP",
      "iata": "",
      "name": "Vila Pitinga Airport",
      "city": "Presidente Figueiredo",
      "state": "",
      "country": "BR",
      "elevation": 876,
      "lat": -0.7991669774,
      "lon": -60.0838890076,
      "tz": "America\/Manaus"
  },
  "SJVQ": {
      "icao": "SJVQ",
      "iata": "",
      "name": "Trialcool Airport",
      "city": "Canapolis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2231,
      "lat": -18.8894443512,
      "lon": -49.2861099243,
      "tz": "America\/Sao_Paulo"
  },
  "SJVR": {
      "icao": "SJVR",
      "iata": "",
      "name": "Fazenda Sao Joaquim Airport",
      "city": "Jardim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1181,
      "lat": -21.4919433594,
      "lon": -56.4872207642,
      "tz": "America\/Campo_Grande"
  },
  "SJVS": {
      "icao": "SJVS",
      "iata": "",
      "name": "Vale do Paranaiba Airport",
      "city": "Capinopolis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1739,
      "lat": -18.7030563354,
      "lon": -49.6869430542,
      "tz": "America\/Sao_Paulo"
  },
  "SJVV": {
      "icao": "SJVV",
      "iata": "",
      "name": "Fazenda Catelani Airport",
      "city": "Minacu",
      "state": "Goias",
      "country": "BR",
      "elevation": 2461,
      "lat": -13.4733333588,
      "lon": -48.4591674805,
      "tz": "America\/Sao_Paulo"
  },
  "SJVW": {
      "icao": "SJVW",
      "iata": "",
      "name": "Campos Gerais Airport",
      "city": "Balsas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1706,
      "lat": -8.5169439316,
      "lon": -46.7469444275,
      "tz": "America\/Fortaleza"
  },
  "SJVX": {
      "icao": "SJVX",
      "iata": "",
      "name": "Pagrisa Airport",
      "city": "Ulianopolis",
      "state": "Para",
      "country": "BR",
      "elevation": 453,
      "lat": -3.7005560398,
      "lon": -47.7633323669,
      "tz": "America\/Belem"
  },
  "SJVY": {
      "icao": "SJVY",
      "iata": "",
      "name": "Haras Bela Vista Airport",
      "city": "Sao Miguel Do Tocantins",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 335,
      "lat": -5.5416669846,
      "lon": -47.5024986267,
      "tz": "America\/Araguaina"
  },
  "SJVZ": {
      "icao": "SJVZ",
      "iata": "",
      "name": "Fazenda Sao Paulo Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2012,
      "lat": -13.6102781296,
      "lon": -58.5691680908,
      "tz": "America\/Cuiaba"
  },
  "SJWA": {
      "icao": "SJWA",
      "iata": "",
      "name": "Usina Maracaju Airport",
      "city": "Maracaju",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1509,
      "lat": -21.3452777863,
      "lon": -55.4333343506,
      "tz": "America\/Campo_Grande"
  },
  "SJWB": {
      "icao": "SJWB",
      "iata": "",
      "name": "Pouso Alto Airport",
      "city": "Piracanjuba",
      "state": "Goias",
      "country": "BR",
      "elevation": 2428,
      "lat": -17.4577789307,
      "lon": -48.9830551147,
      "tz": "America\/Sao_Paulo"
  },
  "SJWC": {
      "icao": "SJWC",
      "iata": "",
      "name": "Fazenda Nossa Senhora da Muxima Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1532,
      "lat": -19.5872211456,
      "lon": -52.9519424438,
      "tz": "America\/Campo_Grande"
  },
  "SJWD": {
      "icao": "SJWD",
      "iata": "",
      "name": "Usina Passa Tempo Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 984,
      "lat": -21.5072212219,
      "lon": -54.7158317566,
      "tz": "America\/Campo_Grande"
  },
  "SJWE": {
      "icao": "SJWE",
      "iata": "",
      "name": "Fazenda Arariba Airport",
      "city": "Canarana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1279,
      "lat": -13.4047222137,
      "lon": -52.8483314514,
      "tz": "America\/Cuiaba"
  },
  "SJWG": {
      "icao": "SJWG",
      "iata": "",
      "name": "Itagro Airport",
      "city": "Alegrete",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 384,
      "lat": -29.7802772522,
      "lon": -55.6972236633,
      "tz": "America\/Sao_Paulo"
  },
  "SJWH": {
      "icao": "SJWH",
      "iata": "",
      "name": "Fazenda Santa Ines Airport",
      "city": "Nova Brasilandia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1330,
      "lat": -14.6511106491,
      "lon": -55.3838882446,
      "tz": "America\/Cuiaba"
  },
  "SJWI": {
      "icao": "SJWI",
      "iata": "",
      "name": "Fazenda Flor Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1420,
      "lat": -21.4969444275,
      "lon": -53.7497215271,
      "tz": "America\/Campo_Grande"
  },
  "SJWL": {
      "icao": "SJWL",
      "iata": "",
      "name": "Boca da Mata Airport",
      "city": "Gaucha Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1804,
      "lat": -13.5744438171,
      "lon": -53.9830551147,
      "tz": "America\/Cuiaba"
  },
  "SJWM": {
      "icao": "SJWM",
      "iata": "",
      "name": "Fazenda Sanga Funda Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1949,
      "lat": -13.0666666031,
      "lon": -58.7058334351,
      "tz": "America\/Cuiaba"
  },
  "SJWN": {
      "icao": "SJWN",
      "iata": "",
      "name": "Fazenda Santa Dulcina Airport",
      "city": "Taquarituba",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2096,
      "lat": -23.5499992371,
      "lon": -49.25,
      "tz": "America\/Sao_Paulo"
  },
  "SJWO": {
      "icao": "SJWO",
      "iata": "",
      "name": "Terra Santa Airport",
      "city": "Barra Do Bugres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 699,
      "lat": -14.8069438934,
      "lon": -57.9736099243,
      "tz": "America\/Cuiaba"
  },
  "SJWP": {
      "icao": "SJWP",
      "iata": "",
      "name": "Warpol Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2650,
      "lat": -12.6194438934,
      "lon": -45.9236106873,
      "tz": "America\/Bahia"
  },
  "SJWQ": {
      "icao": "SJWQ",
      "iata": "",
      "name": "Jose Dona Airport",
      "city": "Birigui",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1338,
      "lat": -21.2175006866,
      "lon": -50.3008346558,
      "tz": "America\/Sao_Paulo"
  },
  "SJWT": {
      "icao": "SJWT",
      "iata": "",
      "name": "Fazenda Sucuri Airport",
      "city": "Itiquira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1788,
      "lat": -17.3958320618,
      "lon": -54.7597236633,
      "tz": "America\/Cuiaba"
  },
  "SJWU": {
      "icao": "SJWU",
      "iata": "",
      "name": "Fazenda Ideal Airport",
      "city": "Nova Venecia",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 471,
      "lat": -18.6672210693,
      "lon": -40.3327789307,
      "tz": "America\/Sao_Paulo"
  },
  "SJWV": {
      "icao": "SJWV",
      "iata": "",
      "name": "Fazenda Sao Judas Airport",
      "city": "Sao Miguel Do Araguaia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1030,
      "lat": -13.3780555725,
      "lon": -50.4244422913,
      "tz": "America\/Sao_Paulo"
  },
  "SJWW": {
      "icao": "SJWW",
      "iata": "",
      "name": "Fazenda Cachoeira Preta Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -20.0213890076,
      "lon": -53.2161102295,
      "tz": "America\/Campo_Grande"
  },
  "SJWX": {
      "icao": "SJWX",
      "iata": "",
      "name": "Rio Alegre Airport",
      "city": "Euclides Da Cunha Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 981,
      "lat": -22.5750007629,
      "lon": -52.5030555725,
      "tz": "America\/Sao_Paulo"
  },
  "SJXA": {
      "icao": "SJXA",
      "iata": "",
      "name": "Aeromis Airport",
      "city": "Bage",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 886,
      "lat": -31.2922210693,
      "lon": -54.1561126709,
      "tz": "America\/Sao_Paulo"
  },
  "SJXB": {
      "icao": "SJXB",
      "iata": "",
      "name": "Aquapey Airport",
      "city": "Porto Esperdiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 600,
      "lat": -15.7563886642,
      "lon": -58.8019447327,
      "tz": "America\/Cuiaba"
  },
  "SJXC": {
      "icao": "SJXC",
      "iata": "",
      "name": "Usina Santa Cruz Airport",
      "city": "Santa Cruz Cabralia",
      "state": "Bahia",
      "country": "BR",
      "elevation": 410,
      "lat": -16.1830558777,
      "lon": -39.3580551147,
      "tz": "America\/Bahia"
  },
  "SJXG": {
      "icao": "SJXG",
      "iata": "",
      "name": "Fazenda Agroplan Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -11.6633329391,
      "lon": -52.8499984741,
      "tz": "America\/Cuiaba"
  },
  "SJXH": {
      "icao": "SJXH",
      "iata": "",
      "name": "Aguas Frias Airport",
      "city": "Irai",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1135,
      "lat": -27.2169437408,
      "lon": -53.2724990845,
      "tz": "America\/Sao_Paulo"
  },
  "SJXM": {
      "icao": "SJXM",
      "iata": "",
      "name": "Carolina de Assis Repetto Airport",
      "city": "Lima Duarte",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3452,
      "lat": -21.7322216034,
      "lon": -43.894443512,
      "tz": "America\/Sao_Paulo"
  },
  "SJXN": {
      "icao": "SJXN",
      "iata": "",
      "name": "Parque Fazenda Bom Futuro Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 361,
      "lat": -17.1913890839,
      "lon": -57.0338897705,
      "tz": "America\/Cuiaba"
  },
  "SJXS": {
      "icao": "SJXS",
      "iata": "",
      "name": "Veracel Airport",
      "city": "Belmonte",
      "state": "Bahia",
      "country": "BR",
      "elevation": 434,
      "lat": -16.1044445038,
      "lon": -39.3638877869,
      "tz": "America\/Bahia"
  },
  "SJXV": {
      "icao": "SJXV",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1256,
      "lat": -22.9311103821,
      "lon": -55.0544433594,
      "tz": "America\/Campo_Grande"
  },
  "SJXW": {
      "icao": "SJXW",
      "iata": "",
      "name": "Fazenda 3M Airport",
      "city": "Nova Alvorada Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1155,
      "lat": -21.6736106873,
      "lon": -54.0358314514,
      "tz": "America\/Campo_Grande"
  },
  "SJXX": {
      "icao": "SJXX",
      "iata": "",
      "name": "Recreio Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1968,
      "lat": -22.6794433594,
      "lon": -55.5405578613,
      "tz": "America\/Campo_Grande"
  },
  "SJYD": {
      "icao": "SJYD",
      "iata": "",
      "name": "Fazenda Kajussol Airport",
      "city": "Santa Luzia D`Oeste",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 636,
      "lat": -11.9647216797,
      "lon": -61.686668396,
      "tz": "America\/Porto_Velho"
  },
  "SJYE": {
      "icao": "SJYE",
      "iata": "",
      "name": "Agua Fria Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 994,
      "lat": 4.6205601692,
      "lon": -60.2771987915,
      "tz": "America\/Boa_Vista"
  },
  "SJYF": {
      "icao": "SJYF",
      "iata": "",
      "name": "Ajarani Airport",
      "city": "Caracarai",
      "state": "Roraima",
      "country": "BR",
      "elevation": 594,
      "lat": 2.0077800751,
      "lon": -61.4757995605,
      "tz": "America\/Boa_Vista"
  },
  "SJYG": {
      "icao": "SJYG",
      "iata": "",
      "name": "Alto Mucajai Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 237,
      "lat": 2.7650001049,
      "lon": -62.2214012146,
      "tz": "America\/Boa_Vista"
  },
  "SJYI": {
      "icao": "SJYI",
      "iata": "",
      "name": "Arai Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1500,
      "lat": 4.4438900948,
      "lon": -60.8471984863,
      "tz": "America\/Boa_Vista"
  },
  "SJYJ": {
      "icao": "SJYJ",
      "iata": "",
      "name": "Aratha-U Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 236,
      "lat": 3.1608300209,
      "lon": -63.7813987732,
      "tz": "America\/Boa_Vista"
  },
  "SJYK": {
      "icao": "SJYK",
      "iata": "",
      "name": "Baixo Catrimani Airport",
      "city": "Caracarai",
      "state": "Roraima",
      "country": "BR",
      "elevation": 171,
      "lat": 1.0711100101,
      "lon": -62.2182998657,
      "tz": "America\/Boa_Vista"
  },
  "SJYL": {
      "icao": "SJYL",
      "iata": "",
      "name": "Baixo Mucajai Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 499,
      "lat": 2.7369399071,
      "lon": -62.0194015503,
      "tz": "America\/Boa_Vista"
  },
  "SJYM": {
      "icao": "SJYM",
      "iata": "",
      "name": "Bala Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 348,
      "lat": 3.8372199535,
      "lon": -60.6032981873,
      "tz": "America\/Boa_Vista"
  },
  "SJYO": {
      "icao": "SJYO",
      "iata": "",
      "name": "Bananal Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 892,
      "lat": 4.6327800751,
      "lon": -60.5856018066,
      "tz": "America\/Boa_Vista"
  },
  "SJYP": {
      "icao": "SJYP",
      "iata": "",
      "name": "Bananeira Airport",
      "city": "Uiramuta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 892,
      "lat": 4.3211097717,
      "lon": -60.193901062,
      "tz": "America\/Boa_Vista"
  },
  "SJYR": {
      "icao": "SJYR",
      "iata": "",
      "name": "Caju Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 794,
      "lat": 4.7058300972,
      "lon": -60.5153007507,
      "tz": "America\/Boa_Vista"
  },
  "SJYS": {
      "icao": "SJYS",
      "iata": "",
      "name": "Camara Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 594,
      "lat": 3.9908299446,
      "lon": -60.1817016602,
      "tz": "America\/Boa_Vista"
  },
  "SJYT": {
      "icao": "SJYT",
      "iata": "",
      "name": "Campo Formoso Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1194,
      "lat": 4.7199997902,
      "lon": -60.7696990967,
      "tz": "America\/Boa_Vista"
  },
  "SJYU": {
      "icao": "SJYU",
      "iata": "",
      "name": "Campo Grande Airport",
      "city": "Pacaraima",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1194,
      "lat": 4.6855602264,
      "lon": -60.8027992249,
      "tz": "America\/Boa_Vista"
  },
  "SJYW": {
      "icao": "SJYW",
      "iata": "",
      "name": "Fazenda Santa Terezinha Airport",
      "city": "Santa Terezinha",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 748,
      "lat": -10.3313894272,
      "lon": -50.9402770996,
      "tz": "America\/Cuiaba"
  },
  "SJYY": {
      "icao": "SJYY",
      "iata": "",
      "name": "Rio do Sangue Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 935,
      "lat": -11.5222215652,
      "lon": -58.1772232056,
      "tz": "America\/Cuiaba"
  },
  "SJYZ": {
      "icao": "SJYZ",
      "iata": "",
      "name": "Fazenda Lagoa Encantada Airport",
      "city": "Primavera Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1968,
      "lat": -15.270277977,
      "lon": -54.2574996948,
      "tz": "America\/Cuiaba"
  },
  "SJZA": {
      "icao": "SJZA",
      "iata": "",
      "name": "Capao Alto Airport",
      "city": "Tapes",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 107,
      "lat": -30.6763896942,
      "lon": -51.5252761841,
      "tz": "America\/Sao_Paulo"
  },
  "SJZB": {
      "icao": "SJZB",
      "iata": "",
      "name": "Vale do Tucana Airport",
      "city": "Castanheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 879,
      "lat": -10.6894435883,
      "lon": -58.649723053,
      "tz": "America\/Cuiaba"
  },
  "SJZC": {
      "icao": "SJZC",
      "iata": "",
      "name": "Destilaria Sao Luiz Airport",
      "city": "Maraial",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1371,
      "lat": -8.7788887024,
      "lon": -35.7849998474,
      "tz": "America\/Recife"
  },
  "SJZD": {
      "icao": "SJZD",
      "iata": "",
      "name": "Fazenda Bandeiras Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 361,
      "lat": -17.8619441986,
      "lon": -56.7030563354,
      "tz": "America\/Campo_Grande"
  },
  "SJZE": {
      "icao": "SJZE",
      "iata": "",
      "name": "Fazenda Brioso Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1270,
      "lat": -20.2405567169,
      "lon": -52.3866653442,
      "tz": "America\/Campo_Grande"
  },
  "SJZF": {
      "icao": "SJZF",
      "iata": "",
      "name": "Fazenda Campo Triste Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1197,
      "lat": -20.6186103821,
      "lon": -52.0180549622,
      "tz": "America\/Campo_Grande"
  },
  "SJZG": {
      "icao": "SJZG",
      "iata": "",
      "name": "Leonel de Moura Brizola Airport",
      "city": "Confresa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 843,
      "lat": -10.6583328247,
      "lon": -51.4361114502,
      "tz": "America\/Cuiaba"
  },
  "SJZH": {
      "icao": "SJZH",
      "iata": "",
      "name": "Fazenda Rio do Fogo Airport",
      "city": "Nova Santa Helena",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 981,
      "lat": -10.8816671371,
      "lon": -54.9977798462,
      "tz": "America\/Cuiaba"
  },
  "SJZM": {
      "icao": "SJZM",
      "iata": "",
      "name": "Carlos Alberto Pinto Airport",
      "city": "Arraias",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1338,
      "lat": -12.6069440842,
      "lon": -46.7472229004,
      "tz": "America\/Araguaina"
  },
  "SJZO": {
      "icao": "SJZO",
      "iata": "",
      "name": "Fazenda Boi Branco Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 394,
      "lat": -18.465555191,
      "lon": -56.7633323669,
      "tz": "America\/Campo_Grande"
  },
  "SJZP": {
      "icao": "SJZP",
      "iata": "",
      "name": "Mathovi Airport",
      "city": "Novo Mundo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1070,
      "lat": -9.8330564499,
      "lon": -55.4647216797,
      "tz": "America\/Cuiaba"
  },
  "SJZQ": {
      "icao": "SJZQ",
      "iata": "",
      "name": "Fazenda Monte Alegre Airport",
      "city": "Corrente",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1444,
      "lat": -10.5641670227,
      "lon": -44.9469451904,
      "tz": "America\/Fortaleza"
  },
  "SJZR": {
      "icao": "SJZR",
      "iata": "",
      "name": "Fazenda Italia Airport",
      "city": "Urucui",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1280,
      "lat": -7.4447221756,
      "lon": -44.3533325195,
      "tz": "America\/Fortaleza"
  },
  "SJZS": {
      "icao": "SJZS",
      "iata": "",
      "name": "Fazenda Canel Airport",
      "city": "Urucui",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1280,
      "lat": -7.5399999619,
      "lon": -44.8224983215,
      "tz": "America\/Fortaleza"
  },
  "SJZT": {
      "icao": "SJZT",
      "iata": "",
      "name": "Fazenda Busato II Airport",
      "city": "Serra Ramalho",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1500,
      "lat": -13.2386112213,
      "lon": -43.7361106873,
      "tz": "America\/Bahia"
  },
  "SJZW": {
      "icao": "SJZW",
      "iata": "",
      "name": "Fazenda Regina Airport",
      "city": "Itatinga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2044,
      "lat": -23.2866668701,
      "lon": -48.7380561829,
      "tz": "America\/Sao_Paulo"
  },
  "SJZX": {
      "icao": "SJZX",
      "iata": "",
      "name": "Fazenda Rebojo Airport",
      "city": "Corumba",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 397,
      "lat": -20.0263881683,
      "lon": -57.918888092,
      "tz": "America\/Campo_Grande"
  },
  "SJZZ": {
      "icao": "SJZZ",
      "iata": "",
      "name": "Fazenda Passo Fundo Airport",
      "city": "Primavera Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2199,
      "lat": -15.0174999237,
      "lon": -53.9358329773,
      "tz": "America\/Cuiaba"
  },
  "SK53": {
      "icao": "SK53",
      "iata": "",
      "name": "Yaguarito Airport",
      "city": "Surimena",
      "state": "Meta",
      "country": "CO",
      "elevation": 836,
      "lat": 3.88,
      "lon": -73.339996,
      "tz": "America\/Bogota"
  },
  "SKAC": {
      "icao": "SKAC",
      "iata": "ACR",
      "name": "Araracuara Airport",
      "city": "Araracuara",
      "state": "Amazonas",
      "country": "CO",
      "elevation": 1250,
      "lat": -0.5833,
      "lon": -72.4083,
      "tz": "America\/Bogota"
  },
  "SKAD": {
      "icao": "SKAD",
      "iata": "ACD",
      "name": "Alcides Fernandez Airport",
      "city": "Acandi",
      "state": "Choco",
      "country": "CO",
      "elevation": 50,
      "lat": 8.51667,
      "lon": -77.3,
      "tz": "America\/Bogota"
  },
  "SKAG": {
      "icao": "SKAG",
      "iata": "HAY",
      "name": "Aguachica Airport",
      "city": "Aguachica",
      "state": "Cesar",
      "country": "CO",
      "elevation": 700,
      "lat": 8.3,
      "lon": -73.6306,
      "tz": "America\/Bogota"
  },
  "SKAM": {
      "icao": "SKAM",
      "iata": "AFI",
      "name": "Amalfi Airport",
      "city": "Amalfi",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 5507,
      "lat": 6.91667,
      "lon": -75.0667,
      "tz": "America\/Bogota"
  },
  "SKAN": {
      "icao": "SKAN",
      "iata": "ADN",
      "name": "Andes Airport",
      "city": "Andes",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 3900,
      "lat": 5.66667,
      "lon": -75.8833,
      "tz": "America\/Bogota"
  },
  "SKAP": {
      "icao": "SKAP",
      "iata": "API",
      "name": "Gomez Nino Apiay Air Base",
      "city": "Apiay",
      "state": "Meta",
      "country": "CO",
      "elevation": 1207,
      "lat": 4.07607,
      "lon": -73.5627,
      "tz": "America\/Bogota"
  },
  "SKAR": {
      "icao": "SKAR",
      "iata": "AXM",
      "name": "El Eden Airport",
      "city": "Armenia",
      "state": "Quindio",
      "country": "CO",
      "elevation": 3990,
      "lat": 4.45278,
      "lon": -75.7664,
      "tz": "America\/Bogota"
  },
  "SKAS": {
      "icao": "SKAS",
      "iata": "PUU",
      "name": "Tres De Mayo Airport",
      "city": "Puerto Asis",
      "state": "Putumayo",
      "country": "CO",
      "elevation": 815,
      "lat": 0.505228,
      "lon": -76.5008,
      "tz": "America\/Bogota"
  },
  "SKBC": {
      "icao": "SKBC",
      "iata": "ELB",
      "name": "Las Flores Airport",
      "city": "El Banco",
      "state": "Magdalena",
      "country": "CO",
      "elevation": 111,
      "lat": 9.04554,
      "lon": -73.9749,
      "tz": "America\/Bogota"
  },
  "SKBE": {
      "icao": "SKBE",
      "iata": "",
      "name": "Becerril Airport",
      "city": "Becerril",
      "state": "Cesar",
      "country": "CO",
      "elevation": 433,
      "lat": 9.66278,
      "lon": -73.2744,
      "tz": "America\/Bogota"
  },
  "SKBG": {
      "icao": "SKBG",
      "iata": "BGA",
      "name": "Palonegro Airport",
      "city": "Bucaramanga",
      "state": "Santander",
      "country": "CO",
      "elevation": 3897,
      "lat": 7.1265,
      "lon": -73.1848,
      "tz": "America\/Bogota"
  },
  "SKBN": {
      "icao": "SKBN",
      "iata": "",
      "name": "Buenavista Airport",
      "city": "Fonseca",
      "state": "La-Guajira",
      "country": "CO",
      "elevation": 850,
      "lat": 10.8879,
      "lon": -72.9004,
      "tz": "America\/Bogota"
  },
  "SKBO": {
      "icao": "SKBO",
      "iata": "BOG",
      "name": "El Dorado International Airport",
      "city": "Bogota",
      "state": "Bogota-D-C-",
      "country": "CO",
      "elevation": 8361,
      "lat": 4.70159,
      "lon": -74.1469,
      "tz": "America\/Bogota"
  },
  "SKBQ": {
      "icao": "SKBQ",
      "iata": "BAQ",
      "name": "Ernesto Cortissoz International Airport",
      "city": "Barranquilla",
      "state": "Atlantico",
      "country": "CO",
      "elevation": 98,
      "lat": 10.8896,
      "lon": -74.7808,
      "tz": "America\/Bogota"
  },
  "SKBR": {
      "icao": "SKBR",
      "iata": "",
      "name": "Berastegui Airport",
      "city": "Berastegui",
      "state": "Cordoba",
      "country": "CO",
      "elevation": 38,
      "lat": 8.88331,
      "lon": -75.6832,
      "tz": "America\/Bogota"
  },
  "SKBS": {
      "icao": "SKBS",
      "iata": "BSC",
      "name": "Jose Celestino Mutis Airport",
      "city": "Bahia Solano",
      "state": "Choco",
      "country": "CO",
      "elevation": 80,
      "lat": 6.20292,
      "lon": -77.3947,
      "tz": "America\/Bogota"
  },
  "SKBU": {
      "icao": "SKBU",
      "iata": "BUN",
      "name": "Gerardo Tobar Lopez Airport",
      "city": "Buenaventura",
      "state": "Valle-del-Cauca",
      "country": "CO",
      "elevation": 48,
      "lat": 3.81963,
      "lon": -76.9898,
      "tz": "America\/Bogota"
  },
  "SKCA": {
      "icao": "SKCA",
      "iata": "CPB",
      "name": "Capurgana Airport",
      "city": "Capurgana",
      "state": "Choco",
      "country": "CO",
      "elevation": 49,
      "lat": 8.63333,
      "lon": -77.35,
      "tz": "America\/Bogota"
  },
  "SKCB": {
      "icao": "SKCB",
      "iata": "",
      "name": "Carmen De Bolivar Airport",
      "city": "Carmen De Bolivar",
      "state": "Bolivar",
      "country": "CO",
      "elevation": 590,
      "lat": 9.68417,
      "lon": -75.1261,
      "tz": "America\/Bogota"
  },
  "SKCC": {
      "icao": "SKCC",
      "iata": "CUC",
      "name": "Camilo Daza International Airport",
      "city": "Cucuta",
      "state": "Norte-de-Santander",
      "country": "CO",
      "elevation": 1096,
      "lat": 7.92757,
      "lon": -72.5115,
      "tz": "America\/Bogota"
  },
  "SKCD": {
      "icao": "SKCD",
      "iata": "COG",
      "name": "Mandinga Airport",
      "city": "Condoto",
      "state": "Choco",
      "country": "CO",
      "elevation": 213,
      "lat": 5.08333,
      "lon": -76.7,
      "tz": "America\/Bogota"
  },
  "SKCG": {
      "icao": "SKCG",
      "iata": "CTG",
      "name": "Rafael Nunez International Airport",
      "city": "Cartagena",
      "state": "Bolivar",
      "country": "CO",
      "elevation": 4,
      "lat": 10.4424,
      "lon": -75.513,
      "tz": "America\/Bogota"
  },
  "SKCH": {
      "icao": "SKCH",
      "iata": "",
      "name": "El Pacifico Airport",
      "city": "Cartagena Del Chiara",
      "state": "Caqueta",
      "country": "CO",
      "elevation": 754,
      "lat": 1.35,
      "lon": -74.84,
      "tz": "America\/Bogota"
  },
  "SKCI": {
      "icao": "SKCI",
      "iata": "CCO",
      "name": "Carimagua Airport",
      "city": "Puerto Lopez",
      "state": "Meta",
      "country": "CO",
      "elevation": 700,
      "lat": 4.56417,
      "lon": -71.3364,
      "tz": "America\/Bogota"
  },
  "SKCL": {
      "icao": "SKCL",
      "iata": "CLO",
      "name": "Alfonso Bonilla Aragon International Airport",
      "city": "Cali",
      "state": "Valle-del-Cauca",
      "country": "CO",
      "elevation": 3162,
      "lat": 3.54322,
      "lon": -76.3816,
      "tz": "America\/Bogota"
  },
  "SKCM": {
      "icao": "SKCM",
      "iata": "CIM",
      "name": "Cimitarra Airport",
      "city": "Cimitarra",
      "state": "Santander",
      "country": "CO",
      "elevation": 740,
      "lat": 6.367,
      "lon": -73.967,
      "tz": "America\/Bogota"
  },
  "SKCN": {
      "icao": "SKCN",
      "iata": "RAV",
      "name": "Cravo Norte Airport",
      "city": "Cravo Norte",
      "state": "Arauca",
      "country": "CO",
      "elevation": 341,
      "lat": 6.31684,
      "lon": -70.2107,
      "tz": "America\/Bogota"
  },
  "SKCO": {
      "icao": "SKCO",
      "iata": "TCO",
      "name": "La Florida Airport",
      "city": "Tumaco",
      "state": "Narino",
      "country": "CO",
      "elevation": 8,
      "lat": 1.81442,
      "lon": -78.7492,
      "tz": "America\/Bogota"
  },
  "SKCP": {
      "icao": "SKCP",
      "iata": "BHF",
      "name": "Cupica Airport",
      "city": "Bahia Solano",
      "state": "Choco",
      "country": "CO",
      "elevation": 0,
      "lat": 6.691944,
      "lon": -77.477222,
      "tz": "America\/Bogota"
  },
  "SKCR": {
      "icao": "SKCR",
      "iata": "CUO",
      "name": "Caruru Airport",
      "city": "Caruru",
      "state": "Vaupes",
      "country": "CO",
      "elevation": 820,
      "lat": 1.0136,
      "lon": -71.2961,
      "tz": "America\/Bogota"
  },
  "SKCU": {
      "icao": "SKCU",
      "iata": "CAQ",
      "name": "Juan H White Airport",
      "city": "Caucasia",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 174,
      "lat": 7.96847,
      "lon": -75.1985,
      "tz": "America\/Bogota"
  },
  "SKCV": {
      "icao": "SKCV",
      "iata": "CVE",
      "name": "Covenas Airport",
      "city": "Covenas",
      "state": "",
      "country": "CO",
      "elevation": 31,
      "lat": 9.40092,
      "lon": -75.6913,
      "tz": "America\/Bogota"
  },
  "SKCZ": {
      "icao": "SKCZ",
      "iata": "CZU",
      "name": "Las Brujas Airport",
      "city": "Corozal",
      "state": "Sucre",
      "country": "CO",
      "elevation": 528,
      "lat": 9.33274,
      "lon": -75.2856,
      "tz": "America\/Bogota"
  },
  "SKEB": {
      "icao": "SKEB",
      "iata": "EBG",
      "name": "El Bagre Airport",
      "city": "El Bagre",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 180,
      "lat": 7.59647,
      "lon": -74.8089,
      "tz": "America\/Bogota"
  },
  "SKEJ": {
      "icao": "SKEJ",
      "iata": "EJA",
      "name": "Yariguies Airport",
      "city": "Barrancabermeja",
      "state": "Santander",
      "country": "CO",
      "elevation": 412,
      "lat": 7.02433,
      "lon": -73.8068,
      "tz": "America\/Bogota"
  },
  "SKFL": {
      "icao": "SKFL",
      "iata": "FLA",
      "name": "Gustavo Artunduaga Paredes Airport",
      "city": "Florencia",
      "state": "Caqueta",
      "country": "CO",
      "elevation": 803,
      "lat": 1.58919,
      "lon": -75.5644,
      "tz": "America\/Bogota"
  },
  "SKFR": {
      "icao": "SKFR",
      "iata": "",
      "name": "Furatena Airport",
      "city": "Quipama",
      "state": "Boyaca",
      "country": "CO",
      "elevation": 3850,
      "lat": 5.5275,
      "lon": -74.1869,
      "tz": "America\/Bogota"
  },
  "SKFU": {
      "icao": "SKFU",
      "iata": "FDA",
      "name": "Fundacion Airport",
      "city": "Fundacion",
      "state": "Magdalena",
      "country": "CO",
      "elevation": 150,
      "lat": 10.5333,
      "lon": -74.2,
      "tz": "America\/Bogota"
  },
  "SKGA": {
      "icao": "SKGA",
      "iata": "LGT",
      "name": "La Gaviota Airport",
      "city": "",
      "state": "Vichada",
      "country": "CO",
      "elevation": 0,
      "lat": 4.549722,
      "lon": -70.925,
      "tz": "America\/Bogota"
  },
  "SKGB": {
      "icao": "SKGB",
      "iata": "",
      "name": "Marco Fidel Suarez Air Base",
      "city": "Cali",
      "state": "Valle-del-Cauca",
      "country": "CO",
      "elevation": 3165,
      "lat": 3.45899,
      "lon": -76.4966,
      "tz": "America\/Bogota"
  },
  "SKGI": {
      "icao": "SKGI",
      "iata": "GIR",
      "name": "Santiago Vila Airport",
      "city": "Girardot",
      "state": "Tolima",
      "country": "CO",
      "elevation": 900,
      "lat": 4.27624,
      "lon": -74.7967,
      "tz": "America\/Bogota"
  },
  "SKGO": {
      "icao": "SKGO",
      "iata": "CRC",
      "name": "Santa Ana Airport",
      "city": "Cartago",
      "state": "Valle-del-Cauca",
      "country": "CO",
      "elevation": 2979,
      "lat": 4.75818,
      "lon": -75.9557,
      "tz": "America\/Bogota"
  },
  "SKGP": {
      "icao": "SKGP",
      "iata": "GPI",
      "name": "Juan Casiano Airport",
      "city": "Guapi",
      "state": "Cauca",
      "country": "CO",
      "elevation": 164,
      "lat": 2.57013,
      "lon": -77.8986,
      "tz": "America\/Bogota"
  },
  "SKGY": {
      "icao": "SKGY",
      "iata": "",
      "name": "Guaymaral Airport",
      "city": "Chia",
      "state": "Cundinamarca",
      "country": "CO",
      "elevation": 8390,
      "lat": 4.81233,
      "lon": -74.0649,
      "tz": "America\/Bogota"
  },
  "SKGZ": {
      "icao": "SKGZ",
      "iata": "GLJ",
      "name": "Garzon La Jagua Airport",
      "city": "Garzon",
      "state": "Huila",
      "country": "CO",
      "elevation": 2620,
      "lat": 2.1464,
      "lon": -75.6944,
      "tz": "America\/Bogota"
  },
  "SKHA": {
      "icao": "SKHA",
      "iata": "CPL",
      "name": "Chaparral Airport",
      "city": "Chaparral",
      "state": "Tolima",
      "country": "CO",
      "elevation": 2730,
      "lat": 3.717,
      "lon": -75.467,
      "tz": "America\/Bogota"
  },
  "SKHC": {
      "icao": "SKHC",
      "iata": "HTZ",
      "name": "Hato Corozal Airport",
      "city": "Hato Corozal",
      "state": "Casanare",
      "country": "CO",
      "elevation": 820,
      "lat": 6.15,
      "lon": -71.75,
      "tz": "America\/Bogota"
  },
  "SKIB": {
      "icao": "SKIB",
      "iata": "IBE",
      "name": "Perales Airport",
      "city": "Ibague",
      "state": "Tolima",
      "country": "CO",
      "elevation": 2999,
      "lat": 4.42161,
      "lon": -75.1333,
      "tz": "America\/Bogota"
  },
  "SKIG": {
      "icao": "SKIG",
      "iata": "IGO",
      "name": "Chigorodo Airport",
      "city": "Chigorodo",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 110,
      "lat": 7.68038,
      "lon": -76.6865,
      "tz": "America\/Bogota"
  },
  "SKIO": {
      "icao": "SKIO",
      "iata": "MMP",
      "name": "Cicuco Airport",
      "city": "Limon",
      "state": "Bolivar",
      "country": "CO",
      "elevation": 65,
      "lat": 9.26878,
      "lon": -74.6523,
      "tz": "America\/Bogota"
  },
  "SKIP": {
      "icao": "SKIP",
      "iata": "IPI",
      "name": "San Luis Airport",
      "city": "Ipiales",
      "state": "Narino",
      "country": "CO",
      "elevation": 9765,
      "lat": 0.861925,
      "lon": -77.6718,
      "tz": "America\/Bogota"
  },
  "SKLA": {
      "icao": "SKLA",
      "iata": "",
      "name": "Malaga Airport",
      "city": "Malaga",
      "state": "Santander",
      "country": "CO",
      "elevation": 6710,
      "lat": 6.70472,
      "lon": -72.7303,
      "tz": "America\/Bogota"
  },
  "SKLC": {
      "icao": "SKLC",
      "iata": "APO",
      "name": "Antonio Roldan Betancourt Airport",
      "city": "Carepa",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 46,
      "lat": 7.81196,
      "lon": -76.7164,
      "tz": "America\/Bogota"
  },
  "SKLG": {
      "icao": "SKLG",
      "iata": "LQM",
      "name": "Caucaya Airport",
      "city": "Puerto Leguizamo",
      "state": "Putumayo",
      "country": "CO",
      "elevation": 573,
      "lat": -0.182278,
      "lon": -74.7708,
      "tz": "America\/Bogota"
  },
  "SKLM": {
      "icao": "SKLM",
      "iata": "MCJ",
      "name": "Jorge Isaac Airport",
      "city": "La Mina-Maicao",
      "state": "La-Guajira",
      "country": "CO",
      "elevation": 281,
      "lat": 11.2325,
      "lon": -72.4901,
      "tz": "America\/Bogota"
  },
  "SKLP": {
      "icao": "SKLP",
      "iata": "LPD",
      "name": "La Pedrera Airport",
      "city": "La Pedrera",
      "state": "Amazonas",
      "country": "CO",
      "elevation": 590,
      "lat": -1.32861,
      "lon": -69.5797,
      "tz": "America\/Bogota"
  },
  "SKLT": {
      "icao": "SKLT",
      "iata": "LET",
      "name": "Alfredo Vasquez Cobo International Airport",
      "city": "Leticia",
      "state": "Amazonas",
      "country": "CO",
      "elevation": 277,
      "lat": -4.19355,
      "lon": -69.9432,
      "tz": "America\/Bogota"
  },
  "SKMA": {
      "icao": "SKMA",
      "iata": "",
      "name": "Madrid Air Base",
      "city": "Madrid",
      "state": "Cundinamarca",
      "country": "CO",
      "elevation": 8325,
      "lat": 4.7278,
      "lon": -74.2754,
      "tz": "America\/Bogota"
  },
  "SKMD": {
      "icao": "SKMD",
      "iata": "EOH",
      "name": "Enrique Olaya Herrera Airport",
      "city": "Medellin",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 4949,
      "lat": 6.220549,
      "lon": -75.590582,
      "tz": "America\/Bogota"
  },
  "SKME": {
      "icao": "SKME",
      "iata": "",
      "name": "Melgar Air Base",
      "city": "Melgar",
      "state": "Cundinamarca",
      "country": "CO",
      "elevation": 1028,
      "lat": 4.21644,
      "lon": -74.635,
      "tz": "America\/Bogota"
  },
  "SKMF": {
      "icao": "SKMF",
      "iata": "MFS",
      "name": "Miraflores Airport",
      "city": "Miraflores",
      "state": "Guaviare",
      "country": "CO",
      "elevation": 730,
      "lat": 1.35,
      "lon": -71.9444,
      "tz": "America\/Bogota"
  },
  "SKMG": {
      "icao": "SKMG",
      "iata": "MGN",
      "name": "Baracoa Airport",
      "city": "Magangue",
      "state": "Bolivar",
      "country": "CO",
      "elevation": 178,
      "lat": 9.28474,
      "lon": -74.8461,
      "tz": "America\/Bogota"
  },
  "SKMJ": {
      "icao": "SKMJ",
      "iata": "",
      "name": "Maicao Airport",
      "city": "Maicao",
      "state": "La-Guajira",
      "country": "CO",
      "elevation": 160,
      "lat": 11.3899,
      "lon": -72.239197,
      "tz": "America\/Bogota"
  },
  "SKML": {
      "icao": "SKML",
      "iata": "MTB",
      "name": "Montelibano Airport",
      "city": "Montelibano",
      "state": "Cordoba",
      "country": "CO",
      "elevation": 160,
      "lat": 7.97174,
      "lon": -75.4325,
      "tz": "America\/Bogota"
  },
  "SKMN": {
      "icao": "SKMN",
      "iata": "",
      "name": "Casanare Airport",
      "city": "Mani",
      "state": "Casanare",
      "country": "CO",
      "elevation": 600,
      "lat": 4.83333,
      "lon": -72.2667,
      "tz": "America\/Bogota"
  },
  "SKMP": {
      "icao": "SKMP",
      "iata": "MMP",
      "name": "San Bernardo Airport",
      "city": "Mompos",
      "state": "Magdalena",
      "country": "CO",
      "elevation": 60,
      "lat": 9.25872,
      "lon": -74.438,
      "tz": "America\/Bogota"
  },
  "SKMR": {
      "icao": "SKMR",
      "iata": "MTR",
      "name": "Los Garzones Airport",
      "city": "Monteria",
      "state": "Cordoba",
      "country": "CO",
      "elevation": 36,
      "lat": 8.82374,
      "lon": -75.8258,
      "tz": "America\/Bogota"
  },
  "SKMU": {
      "icao": "SKMU",
      "iata": "MVP",
      "name": "Fabio Alberto Leon Bentley Airport",
      "city": "Mitu",
      "state": "Vaupes",
      "country": "CO",
      "elevation": 680,
      "lat": 1.25366,
      "lon": -70.2339,
      "tz": "America\/Bogota"
  },
  "SKMZ": {
      "icao": "SKMZ",
      "iata": "MZL",
      "name": "La Nubia Airport",
      "city": "Manizales",
      "state": "Caldas",
      "country": "CO",
      "elevation": 6871,
      "lat": 5.0296,
      "lon": -75.4647,
      "tz": "America\/Bogota"
  },
  "SKNC": {
      "icao": "SKNC",
      "iata": "NCI",
      "name": "Necocli Airport",
      "city": "Necocli",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 19,
      "lat": 8.45,
      "lon": -76.7833,
      "tz": "America\/Bogota"
  },
  "SKNQ": {
      "icao": "SKNQ",
      "iata": "NQU",
      "name": "Reyes Murillo Airport",
      "city": "Nuqui",
      "state": "Choco",
      "country": "CO",
      "elevation": 12,
      "lat": 5.6964,
      "lon": -77.2806,
      "tz": "America\/Bogota"
  },
  "SKNV": {
      "icao": "SKNV",
      "iata": "NVA",
      "name": "Benito Salas Airport",
      "city": "Neiva",
      "state": "Huila",
      "country": "CO",
      "elevation": 1464,
      "lat": 2.95015,
      "lon": -75.294,
      "tz": "America\/Bogota"
  },
  "SKOC": {
      "icao": "SKOC",
      "iata": "OCV",
      "name": "Aguas Claras Airport",
      "city": "Ocana",
      "state": "Cesar",
      "country": "CO",
      "elevation": 3850,
      "lat": 8.31506,
      "lon": -73.3583,
      "tz": "America\/Bogota"
  },
  "SKOE": {
      "icao": "SKOE",
      "iata": "ORC",
      "name": "Orocue Airport",
      "city": "Orocue",
      "state": "Casanare",
      "country": "CO",
      "elevation": 434,
      "lat": 4.79222,
      "lon": -71.3564,
      "tz": "America\/Bogota"
  },
  "SKOL": {
      "icao": "SKOL",
      "iata": "",
      "name": "Puerto Lopez Airport",
      "city": "Puerto Lopez",
      "state": "Meta",
      "country": "CO",
      "elevation": 599,
      "lat": 4.089869,
      "lon": -72.976483,
      "tz": "America\/Bogota"
  },
  "SKOR": {
      "icao": "SKOR",
      "iata": "",
      "name": "Orito Airport",
      "city": "Orito",
      "state": "Putumayo",
      "country": "CO",
      "elevation": 1132,
      "lat": 0.669444,
      "lon": -76.88,
      "tz": "America\/Bogota"
  },
  "SKOT": {
      "icao": "SKOT",
      "iata": "OTU",
      "name": "Otu Airport",
      "city": "El Rhin",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 2060,
      "lat": 7.01037,
      "lon": -74.7155,
      "tz": "America\/Bogota"
  },
  "SKPA": {
      "icao": "SKPA",
      "iata": "RON",
      "name": "Juan Jose Rondon Airport",
      "city": "Paipa",
      "state": "Boyaca",
      "country": "CO",
      "elevation": 8205,
      "lat": 5.76454,
      "lon": -73.1054,
      "tz": "America\/Bogota"
  },
  "SKPB": {
      "icao": "SKPB",
      "iata": "",
      "name": "Puerto Bolivar Airport",
      "city": "Portete",
      "state": "La-Guajira",
      "country": "CO",
      "elevation": 90,
      "lat": 12.2215,
      "lon": -71.9848,
      "tz": "America\/Bogota"
  },
  "SKPC": {
      "icao": "SKPC",
      "iata": "PCR",
      "name": "German Olano Airport",
      "city": "Puerto Carreno",
      "state": "Vichada",
      "country": "CO",
      "elevation": 177,
      "lat": 6.18472,
      "lon": -67.4932,
      "tz": "America\/Bogota"
  },
  "SKPD": {
      "icao": "SKPD",
      "iata": "PDA",
      "name": "Obando Airport",
      "city": "Puerto Inirida",
      "state": "Guainia",
      "country": "CO",
      "elevation": 460,
      "lat": 3.85353,
      "lon": -67.9062,
      "tz": "America\/Bogota"
  },
  "SKPE": {
      "icao": "SKPE",
      "iata": "PEI",
      "name": "Matecana International Airport",
      "city": "Pereira",
      "state": "Risaralda",
      "country": "CO",
      "elevation": 4416,
      "lat": 4.81267,
      "lon": -75.7395,
      "tz": "America\/Bogota"
  },
  "SKPG": {
      "icao": "SKPG",
      "iata": "",
      "name": "Puerto Gaitan Airport",
      "city": "Puerto Gaitan",
      "state": "Meta",
      "country": "CO",
      "elevation": 510,
      "lat": 4.30444,
      "lon": -72.0844,
      "tz": "America\/Bogota"
  },
  "SKPI": {
      "icao": "SKPI",
      "iata": "PTX",
      "name": "Pitalito Airport",
      "city": "Pitalito",
      "state": "Huila",
      "country": "CO",
      "elevation": 4212,
      "lat": 1.85777,
      "lon": -76.0857,
      "tz": "America\/Bogota"
  },
  "SKPL": {
      "icao": "SKPL",
      "iata": "PLT",
      "name": "Plato Airport",
      "city": "Plato",
      "state": "Magdalena",
      "country": "CO",
      "elevation": 69,
      "lat": 9.8,
      "lon": -74.7833,
      "tz": "America\/Bogota"
  },
  "SKPN": {
      "icao": "SKPN",
      "iata": "NAR",
      "name": "Puerto Nare Airport",
      "city": "Armenia",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 476,
      "lat": 6.21002,
      "lon": -74.5906,
      "tz": "America\/Bogota"
  },
  "SKPP": {
      "icao": "SKPP",
      "iata": "PPN",
      "name": "Guillermo Leon Valencia Airport",
      "city": "Popayan",
      "state": "Cauca",
      "country": "CO",
      "elevation": 5687,
      "lat": 2.4544,
      "lon": -76.6093,
      "tz": "America\/Bogota"
  },
  "SKPQ": {
      "icao": "SKPQ",
      "iata": "PQE",
      "name": "German Olano Air Base",
      "city": "La Dorada",
      "state": "Cundinamarca",
      "country": "CO",
      "elevation": 566,
      "lat": 5.48361,
      "lon": -74.6574,
      "tz": "America\/Bogota"
  },
  "SKPR": {
      "icao": "SKPR",
      "iata": "PBE",
      "name": "Puerto Berrio Airport",
      "city": "Puerto Berrio",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 445,
      "lat": 6.46034,
      "lon": -74.4105,
      "tz": "America\/Bogota"
  },
  "SKPS": {
      "icao": "SKPS",
      "iata": "PSO",
      "name": "Antonio Narino Airport",
      "city": "Pasto",
      "state": "Narino",
      "country": "CO",
      "elevation": 5951,
      "lat": 1.39625,
      "lon": -77.2915,
      "tz": "America\/Bogota"
  },
  "SKPV": {
      "icao": "SKPV",
      "iata": "PVA",
      "name": "El Embrujo Airport",
      "city": "Providencia",
      "state": "San-Andres-y-Providencia",
      "country": "CO",
      "elevation": 10,
      "lat": 13.3569,
      "lon": -81.3583,
      "tz": "America\/Bogota"
  },
  "SKPZ": {
      "icao": "SKPZ",
      "iata": "PZA",
      "name": "Paz De Ariporo Airport",
      "city": "Paz De Ariporo",
      "state": "Casanare",
      "country": "CO",
      "elevation": 900,
      "lat": 5.87615,
      "lon": -71.8866,
      "tz": "America\/Bogota"
  },
  "SKQU": {
      "icao": "SKQU",
      "iata": "MQU",
      "name": "Jose Celestino Mutis Airport",
      "city": "Mariquita",
      "state": "Tolima",
      "country": "CO",
      "elevation": 1531,
      "lat": 5.21256,
      "lon": -74.8836,
      "tz": "America\/Bogota"
  },
  "SKRG": {
      "icao": "SKRG",
      "iata": "MDE",
      "name": "Jose Maria Cordova International Airport",
      "city": "Rionegro",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 6955,
      "lat": 6.16454,
      "lon": -75.4231,
      "tz": "America\/Bogota"
  },
  "SKRH": {
      "icao": "SKRH",
      "iata": "RCH",
      "name": "Almirante Padilla Airport",
      "city": "Riohacha",
      "state": "La-Guajira",
      "country": "CO",
      "elevation": 43,
      "lat": 11.5262,
      "lon": -72.926,
      "tz": "America\/Bogota"
  },
  "SKSA": {
      "icao": "SKSA",
      "iata": "RVE",
      "name": "Los Colonizadores Airport",
      "city": "Saravena",
      "state": "Arauca",
      "country": "CO",
      "elevation": 672,
      "lat": 6.951389,
      "lon": -71.856944,
      "tz": "America\/Bogota"
  },
  "SKSF": {
      "icao": "SKSF",
      "iata": "",
      "name": "Santa Fe De Antioquia Airport",
      "city": "",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 0,
      "lat": 6.500556,
      "lon": -75.8225,
      "tz": "America\/Bogota"
  },
  "SKSG": {
      "icao": "SKSG",
      "iata": "",
      "name": "San Gil Airport",
      "city": "San Gil",
      "state": "Santander",
      "country": "CO",
      "elevation": 5498,
      "lat": 6.58444,
      "lon": -73.1283,
      "tz": "America\/Bogota"
  },
  "SKSI": {
      "icao": "SKSI",
      "iata": "",
      "name": "Santiago I Airport",
      "city": "Mani",
      "state": "Casanare",
      "country": "CO",
      "elevation": 573,
      "lat": 4.73214,
      "lon": -72.323196,
      "tz": "America\/Bogota"
  },
  "SKSJ": {
      "icao": "SKSJ",
      "iata": "SJE",
      "name": "Jorge E. Gonzalez Torres Airport",
      "city": "San Jose Del Guaviare",
      "state": "Guaviare",
      "country": "CO",
      "elevation": 605,
      "lat": 2.57969,
      "lon": -72.6394,
      "tz": "America\/Bogota"
  },
  "SKSM": {
      "icao": "SKSM",
      "iata": "SMR",
      "name": "Simon Bolivar International Airport",
      "city": "Santa Marta",
      "state": "Magdalena",
      "country": "CO",
      "elevation": 22,
      "lat": 11.1196,
      "lon": -74.2306,
      "tz": "America\/Bogota"
  },
  "SKSO": {
      "icao": "SKSO",
      "iata": "SOX",
      "name": "Alberto Lleras Camargo Airport",
      "city": "Sogamoso",
      "state": "Boyaca",
      "country": "CO",
      "elevation": 8155,
      "lat": 5.67732,
      "lon": -72.9703,
      "tz": "America\/Bogota"
  },
  "SKSP": {
      "icao": "SKSP",
      "iata": "ADZ",
      "name": "Gustavo Rojas Pinilla International Airport",
      "city": "San Andres",
      "state": "San-Andres-y-Providencia",
      "country": "CO",
      "elevation": 19,
      "lat": 12.5836,
      "lon": -81.7112,
      "tz": "America\/Bogota"
  },
  "SKSV": {
      "icao": "SKSV",
      "iata": "SVI",
      "name": "Eduardo Falla Solano Airport",
      "city": "San Vicente Del Caguan",
      "state": "Caqueta",
      "country": "CO",
      "elevation": 920,
      "lat": 2.15217,
      "lon": -74.7663,
      "tz": "America\/Bogota"
  },
  "SKTA": {
      "icao": "SKTA",
      "iata": "TAU",
      "name": "Tauramena Airport",
      "city": "Tauramena",
      "state": "Casanare",
      "country": "CO",
      "elevation": 1462,
      "lat": 5.01281,
      "lon": -72.7424,
      "tz": "America\/Bogota"
  },
  "SKTB": {
      "icao": "SKTB",
      "iata": "TIB",
      "name": "Tibu Airport",
      "city": "Tibu",
      "state": "Norte-de-Santander",
      "country": "CO",
      "elevation": 194,
      "lat": 8.63152,
      "lon": -72.7304,
      "tz": "America\/Bogota"
  },
  "SKTD": {
      "icao": "SKTD",
      "iata": "TDA",
      "name": "Trinidad Airport",
      "city": "Trinidad",
      "state": "Casanare",
      "country": "CO",
      "elevation": 649,
      "lat": 5.43278,
      "lon": -71.6625,
      "tz": "America\/Bogota"
  },
  "SKTI": {
      "icao": "SKTI",
      "iata": "",
      "name": "Tolemaida Air Base",
      "city": "Las Ceibas",
      "state": "Cundinamarca",
      "country": "CO",
      "elevation": 1617,
      "lat": 4.2448,
      "lon": -74.6499,
      "tz": "America\/Bogota"
  },
  "SKTJ": {
      "icao": "SKTJ",
      "iata": "",
      "name": "Tunja Airport",
      "city": "Tunja",
      "state": "Boyaca",
      "country": "CO",
      "elevation": 8940,
      "lat": 5.54138,
      "lon": -73.3445,
      "tz": "America\/Bogota"
  },
  "SKTL": {
      "icao": "SKTL",
      "iata": "TLU",
      "name": "Tolu Airport",
      "city": "Tolu",
      "state": "Sucre",
      "country": "CO",
      "elevation": 16,
      "lat": 9.50945,
      "lon": -75.5854,
      "tz": "America\/Bogota"
  },
  "SKTM": {
      "icao": "SKTM",
      "iata": "TME",
      "name": "Gustavo Vargas Airport",
      "city": "Tame",
      "state": "Arauca",
      "country": "CO",
      "elevation": 1050,
      "lat": 6.45108,
      "lon": -71.7603,
      "tz": "America\/Bogota"
  },
  "SKTQ": {
      "icao": "SKTQ",
      "iata": "TQS",
      "name": "Tres Esquinas Air Base",
      "city": "Tres Esquinas",
      "state": "Caqueta",
      "country": "CO",
      "elevation": 585,
      "lat": 0.7459,
      "lon": -75.234,
      "tz": "America\/Bogota"
  },
  "SKTU": {
      "icao": "SKTU",
      "iata": "TRB",
      "name": "Gonzalo Mejia Airport",
      "city": "Turbo",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 6,
      "lat": 8.07453,
      "lon": -76.7415,
      "tz": "America\/Bogota"
  },
  "SKUA": {
      "icao": "SKUA",
      "iata": "",
      "name": "Marandua Air Base",
      "city": "Marandua",
      "state": "Vichada",
      "country": "CO",
      "elevation": 285,
      "lat": 5.52448,
      "lon": -68.6856,
      "tz": "America\/Bogota"
  },
  "SKUC": {
      "icao": "SKUC",
      "iata": "AUC",
      "name": "Santiago Perez Airport",
      "city": "Arauca",
      "state": "Arauca",
      "country": "CO",
      "elevation": 420,
      "lat": 7.06888,
      "lon": -70.7369,
      "tz": "America\/Bogota"
  },
  "SKUI": {
      "icao": "SKUI",
      "iata": "UIB",
      "name": "El Carano Airport",
      "city": "Quibdo",
      "state": "Choco",
      "country": "CO",
      "elevation": 204,
      "lat": 5.69076,
      "lon": -76.6412,
      "tz": "America\/Bogota"
  },
  "SKUL": {
      "icao": "SKUL",
      "iata": "ULQ",
      "name": "Farfan Airport",
      "city": "Tulua",
      "state": "Valle-del-Cauca",
      "country": "CO",
      "elevation": 3132,
      "lat": 4.08836,
      "lon": -76.2351,
      "tz": "America\/Bogota"
  },
  "SKUR": {
      "icao": "SKUR",
      "iata": "URR",
      "name": "Urrao Airport",
      "city": "Urrao",
      "state": "Antioquia",
      "country": "CO",
      "elevation": 6090,
      "lat": 6.32883,
      "lon": -76.1425,
      "tz": "America\/Bogota"
  },
  "SKVG": {
      "icao": "SKVG",
      "iata": "VGZ",
      "name": "Villa Garzon Airport",
      "city": "Villa Garzon",
      "state": "Putumayo",
      "country": "CO",
      "elevation": 1248,
      "lat": 0.981944,
      "lon": -76.604167,
      "tz": "America\/Bogota"
  },
  "SKVL": {
      "icao": "SKVL",
      "iata": "PYA",
      "name": "Velasquez Airport",
      "city": "Velasquez",
      "state": "Boyaca",
      "country": "CO",
      "elevation": 566,
      "lat": 5.93904,
      "lon": -74.457,
      "tz": "America\/Bogota"
  },
  "SKVN": {
      "icao": "SKVN",
      "iata": "",
      "name": "Villanueva Airport",
      "city": "Villanueva",
      "state": "Casanare",
      "country": "CO",
      "elevation": 1002,
      "lat": 4.62472,
      "lon": -72.9469,
      "tz": "America\/Bogota"
  },
  "SKVP": {
      "icao": "SKVP",
      "iata": "VUP",
      "name": "Alfonso Lopez Pumarejo Airport",
      "city": "Valledupar",
      "state": "Cesar",
      "country": "CO",
      "elevation": 483,
      "lat": 10.435,
      "lon": -73.2495,
      "tz": "America\/Bogota"
  },
  "SKVV": {
      "icao": "SKVV",
      "iata": "VVC",
      "name": "Vanguardia Airport",
      "city": "Villavicencio",
      "state": "Meta",
      "country": "CO",
      "elevation": 1394,
      "lat": 4.16787,
      "lon": -73.6138,
      "tz": "America\/Bogota"
  },
  "SKYA": {
      "icao": "SKYA",
      "iata": "AYG",
      "name": "Yaguara Airport",
      "city": "San Vicente Del Caguan",
      "state": "Caqueta",
      "country": "CO",
      "elevation": 812,
      "lat": 1.54417,
      "lon": -73.9333,
      "tz": "America\/Bogota"
  },
  "SKYP": {
      "icao": "SKYP",
      "iata": "EYP",
      "name": "El Yopal Airport",
      "city": "El Yopal",
      "state": "Casanare",
      "country": "CO",
      "elevation": 1028,
      "lat": 5.31911,
      "lon": -72.384,
      "tz": "America\/Bogota"
  },
  "SLAB": {
      "icao": "SLAB",
      "iata": "",
      "name": "Abopo Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 2752,
      "lat": -18.6319999695,
      "lon": -62.939201355,
      "tz": "America\/La_Paz"
  },
  "SLAG": {
      "icao": "SLAG",
      "iata": "MHW",
      "name": "Monteagudo Airport",
      "city": "El Banado",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 3674,
      "lat": -19.8269996643,
      "lon": -63.9609985352,
      "tz": "America\/La_Paz"
  },
  "SLAL": {
      "icao": "SLAL",
      "iata": "SRE",
      "name": "Alcantar\u00c3\u00ad International Airport",
      "city": "Sucre",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 10184,
      "lat": -19.24627,
      "lon": -65.15104,
      "tz": "America\/La_Paz"
  },
  "SLAM": {
      "icao": "SLAM",
      "iata": "",
      "name": "Arampampa Airport",
      "city": "Gral. Ballivian",
      "state": "Potosi",
      "country": "BO",
      "elevation": 10250,
      "lat": -17.8833332062,
      "lon": -66.0999984741,
      "tz": "America\/La_Paz"
  },
  "SLAN": {
      "icao": "SLAN",
      "iata": "",
      "name": "Angora Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 731,
      "lat": -14.432299614,
      "lon": -65.7687988281,
      "tz": "America\/La_Paz"
  },
  "SLAP": {
      "icao": "SLAP",
      "iata": "APB",
      "name": "Apolo Airport",
      "city": "Apolo",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 4639,
      "lat": -14.7356004715,
      "lon": -68.4119033813,
      "tz": "America\/La_Paz"
  },
  "SLAQ": {
      "icao": "SLAQ",
      "iata": "",
      "name": "Aiquile Airport",
      "city": "Campero",
      "state": "Cochabamba",
      "country": "BO",
      "elevation": 7208,
      "lat": -18.2154006958,
      "lon": -65.1877975464,
      "tz": "America\/La_Paz"
  },
  "SLAS": {
      "icao": "SLAS",
      "iata": "ASC",
      "name": "Ascencion De Guarayos Airport",
      "city": "Ascension de Guarayos",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 807,
      "lat": -15.9302997589,
      "lon": -63.1567001343,
      "tz": "America\/La_Paz"
  },
  "SLBA": {
      "icao": "SLBA",
      "iata": "BVL",
      "name": "Baures Airport",
      "city": "Baures",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 470,
      "lat": -13.5833330154,
      "lon": -63.5833320618,
      "tz": "America\/La_Paz"
  },
  "SLBB": {
      "icao": "SLBB",
      "iata": "",
      "name": "Bulo Bulo Airport",
      "city": "Carrasco",
      "state": "Cochabamba",
      "country": "BO",
      "elevation": 886,
      "lat": -17.2573337555,
      "lon": -64.3666687012,
      "tz": "America\/La_Paz"
  },
  "SLBF": {
      "icao": "SLBF",
      "iata": "",
      "name": "Blanca Flor Airport",
      "city": "Madre De Dios",
      "state": "Pando",
      "country": "BO",
      "elevation": 656,
      "lat": -11.7833337784,
      "lon": -66.8833312988,
      "tz": "America\/La_Paz"
  },
  "SLBH": {
      "icao": "SLBH",
      "iata": "",
      "name": "Buena Hora Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 656,
      "lat": -12.1333332062,
      "lon": -66.5333328247,
      "tz": "America\/La_Paz"
  },
  "SLBJ": {
      "icao": "SLBJ",
      "iata": "BJO",
      "name": "Bermejo Airport",
      "city": "Bermejo",
      "state": "Tarija",
      "country": "BO",
      "elevation": 1249,
      "lat": -22.7733001709,
      "lon": -64.3128967285,
      "tz": "America\/Argentina\/Salta"
  },
  "SLBN": {
      "icao": "SLBN",
      "iata": "",
      "name": "Bella Union Airport",
      "city": "Mamore",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -13.6333332062,
      "lon": -65.2833328247,
      "tz": "America\/La_Paz"
  },
  "SLBR": {
      "icao": "SLBR",
      "iata": "",
      "name": "Buen Retiro Itenez Airport",
      "city": "Itenes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 774,
      "lat": -13.4166669846,
      "lon": -63.4833335876,
      "tz": "America\/La_Paz"
  },
  "SLBS": {
      "icao": "SLBS",
      "iata": "",
      "name": "Bella Vista Itenez Airport",
      "city": "Itenes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 774,
      "lat": -14.2677001953,
      "lon": -64.2990036011,
      "tz": "America\/La_Paz"
  },
  "SLBT": {
      "icao": "SLBT",
      "iata": "",
      "name": "Buen Retiro Ballivian Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 886,
      "lat": -13.8999996185,
      "lon": -66.5500030518,
      "tz": "America\/La_Paz"
  },
  "SLBV": {
      "icao": "SLBV",
      "iata": "",
      "name": "Villa Vista Montano Airport",
      "city": "De Montano",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 519,
      "lat": -13.8500003815,
      "lon": -66.3666687012,
      "tz": "America\/La_Paz"
  },
  "SLCA": {
      "icao": "SLCA",
      "iata": "CAM",
      "name": "Camiri Airport",
      "city": "Camiri",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 2614,
      "lat": -20.0063991547,
      "lon": -63.5278015137,
      "tz": "America\/La_Paz"
  },
  "SLCB": {
      "icao": "SLCB",
      "iata": "CBB",
      "name": "Jorge Wilsterman International Airport",
      "city": "Cochabamba",
      "state": "Cochabamba",
      "country": "BO",
      "elevation": 8360,
      "lat": -17.4211006165,
      "lon": -66.1771011353,
      "tz": "America\/La_Paz"
  },
  "SLCC": {
      "icao": "SLCC",
      "iata": "",
      "name": "Copacabana Airport",
      "city": "Copacabana",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 12591,
      "lat": -16.1910991669,
      "lon": -69.0955963135,
      "tz": "America\/La_Paz"
  },
  "SLCD": {
      "icao": "SLCD",
      "iata": "",
      "name": "Canada Airport",
      "city": "Gutierrez",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1954,
      "lat": -20.0254001617,
      "lon": -63.089099884,
      "tz": "America\/La_Paz"
  },
  "SLCE": {
      "icao": "SLCE",
      "iata": "",
      "name": "Cabezas Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1328,
      "lat": -18.7954006195,
      "lon": -63.3003005981,
      "tz": "America\/La_Paz"
  },
  "SLCG": {
      "icao": "SLCG",
      "iata": "",
      "name": "Charagua Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 2040,
      "lat": -19.7856006622,
      "lon": -63.1884002686,
      "tz": "America\/La_Paz"
  },
  "SLCH": {
      "icao": "SLCH",
      "iata": "",
      "name": "Chimore Airport",
      "city": "Chimore",
      "state": "Cochabamba",
      "country": "BO",
      "elevation": 875,
      "lat": -16.9900188446,
      "lon": -65.1415023804,
      "tz": "America\/La_Paz"
  },
  "SLCI": {
      "icao": "SLCI",
      "iata": "",
      "name": "Caigua Airport",
      "city": "Gran Chaco",
      "state": "Tarija",
      "country": "BO",
      "elevation": 1440,
      "lat": -21.1666660309,
      "lon": -63.6333351135,
      "tz": "America\/La_Paz"
  },
  "SLCL": {
      "icao": "SLCL",
      "iata": "",
      "name": "Collpani Airport",
      "city": "Abaroa",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12185,
      "lat": -18.8916664124,
      "lon": -66.7833328247,
      "tz": "America\/La_Paz"
  },
  "SLCM": {
      "icao": "SLCM",
      "iata": "",
      "name": "Camiare Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 550,
      "lat": -14.0743331909,
      "lon": -65.6503295898,
      "tz": "America\/La_Paz"
  },
  "SLCN": {
      "icao": "SLCN",
      "iata": "",
      "name": "Charana Airport",
      "city": "Charana",
      "state": "",
      "country": "BO",
      "elevation": 13339,
      "lat": -17.5946998596,
      "lon": -69.4330978394,
      "tz": "America\/La_Paz"
  },
  "SLCO": {
      "icao": "SLCO",
      "iata": "CIJ",
      "name": "Capitan Anibal Arab Airport",
      "city": "Cobija",
      "state": "Pando",
      "country": "BO",
      "elevation": 889,
      "lat": -11.0403995514,
      "lon": -68.7829971313,
      "tz": "America\/La_Paz"
  },
  "SLCP": {
      "icao": "SLCP",
      "iata": "CEP",
      "name": "Concepcion Airport",
      "city": "Concepcion",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1620,
      "lat": -16.138299942,
      "lon": -62.0285987854,
      "tz": "America\/La_Paz"
  },
  "SLCR": {
      "icao": "SLCR",
      "iata": "",
      "name": "Comarapa Airport",
      "city": "M. Caballero",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 6186,
      "lat": -17.9136009216,
      "lon": -64.5177993774,
      "tz": "America\/La_Paz"
  },
  "SLCS": {
      "icao": "SLCS",
      "iata": "",
      "name": "Cerdas Airport",
      "city": "Nor Lipez",
      "state": "Potosi",
      "country": "BO",
      "elevation": 12640,
      "lat": -20.8083333333,
      "lon": -66.4011111111,
      "tz": "America\/La_Paz"
  },
  "SLCT": {
      "icao": "SLCT",
      "iata": "",
      "name": "Choreti Bolivia South America Airport",
      "city": "Mamore",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 656,
      "lat": -13.5144996643,
      "lon": -64.9652023315,
      "tz": "America\/La_Paz"
  },
  "SLCU": {
      "icao": "SLCU",
      "iata": "",
      "name": "Culpina Airport",
      "city": "Sud Cinti",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 9676,
      "lat": -20.8333339691,
      "lon": -64.9666671753,
      "tz": "America\/La_Paz"
  },
  "SLCV": {
      "icao": "SLCV",
      "iata": "",
      "name": "Cavinas Airport",
      "city": "Ballivian",
      "state": "",
      "country": "BO",
      "elevation": 656,
      "lat": -12.5497999191,
      "lon": -66.9124984741,
      "tz": "America\/La_Paz"
  },
  "SLCX": {
      "icao": "SLCX",
      "iata": "",
      "name": "Chive Airport",
      "city": "Iturralde",
      "state": "Pando",
      "country": "BO",
      "elevation": 618,
      "lat": -12.3778333664,
      "lon": -68.5676651001,
      "tz": "America\/La_Paz"
  },
  "SLCY": {
      "icao": "SLCY",
      "iata": "",
      "name": "Collpa Airport",
      "city": "A. Ibanez",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1170,
      "lat": -17.5650005341,
      "lon": -63.1747016907,
      "tz": "America\/La_Paz"
  },
  "SLDA": {
      "icao": "SLDA",
      "iata": "",
      "name": "Caranda Airport",
      "city": "Ichilo",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1082,
      "lat": -17.5166664124,
      "lon": -63.5166664124,
      "tz": "America\/La_Paz"
  },
  "SLDN": {
      "icao": "SLDN",
      "iata": "",
      "name": "El Desengano Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 730,
      "lat": -14.2735996246,
      "lon": -65.4400024414,
      "tz": "America\/La_Paz"
  },
  "SLEA": {
      "icao": "SLEA",
      "iata": "",
      "name": "El Cocal Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -13.5593996048,
      "lon": -66.0790023804,
      "tz": "America\/La_Paz"
  },
  "SLEC": {
      "icao": "SLEC",
      "iata": "",
      "name": "El Cairo Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -13.7833337784,
      "lon": -66.4000015259,
      "tz": "America\/La_Paz"
  },
  "SLEE": {
      "icao": "SLEE",
      "iata": "",
      "name": "El Escondido Airport",
      "city": "Tesoro",
      "state": "",
      "country": "BO",
      "elevation": 1246,
      "lat": -21.466999054,
      "lon": -63.3527984619,
      "tz": "America\/La_Paz"
  },
  "SLEF": {
      "icao": "SLEF",
      "iata": "",
      "name": "El Triunfo Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 486,
      "lat": -13.8833332062,
      "lon": -65.7666702271,
      "tz": "America\/La_Paz"
  },
  "SLEH": {
      "icao": "SLEH",
      "iata": "",
      "name": "El Rancho Airport",
      "city": "Mamore",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -13.0500001907,
      "lon": -65.0333328247,
      "tz": "America\/La_Paz"
  },
  "SLEI": {
      "icao": "SLEI",
      "iata": "",
      "name": "Espino Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 2313,
      "lat": -19.2999000549,
      "lon": -63.2089996338,
      "tz": "America\/La_Paz"
  },
  "SLEK": {
      "icao": "SLEK",
      "iata": "",
      "name": "El Condor Airport",
      "city": "Tesoro",
      "state": "Tarija",
      "country": "BO",
      "elevation": 1734,
      "lat": -21.7008342743,
      "lon": -63.4568328857,
      "tz": "America\/La_Paz"
  },
  "SLEO": {
      "icao": "SLEO",
      "iata": "",
      "name": "El Paraiso Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -14.3833332062,
      "lon": -65.8499984741,
      "tz": "America\/La_Paz"
  },
  "SLEP": {
      "icao": "SLEP",
      "iata": "",
      "name": "El Peru Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 528,
      "lat": -14.6046667099,
      "lon": -65.6183319092,
      "tz": "America\/La_Paz"
  },
  "SLER": {
      "icao": "SLER",
      "iata": "",
      "name": "Cerrillos Airport",
      "city": "Hernando Siles",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 3300,
      "lat": -20.0166664124,
      "lon": -64.0333328247,
      "tz": "America\/La_Paz"
  },
  "SLES": {
      "icao": "SLES",
      "iata": "",
      "name": "Estalsa Airport",
      "city": "Poopo",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12460,
      "lat": -18.4500007629,
      "lon": -66.9166641235,
      "tz": "America\/La_Paz"
  },
  "SLET": {
      "icao": "SLET",
      "iata": "SRZ",
      "name": "El Trompillo Airport",
      "city": "Santa Cruz",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1371,
      "lat": -17.8115997314,
      "lon": -63.1715011597,
      "tz": "America\/La_Paz"
  },
  "SLEV": {
      "icao": "SLEV",
      "iata": "",
      "name": "El Salvador Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 574,
      "lat": -14.4982995987,
      "lon": -66.7629013062,
      "tz": "America\/La_Paz"
  },
  "SLEZ": {
      "icao": "SLEZ",
      "iata": "",
      "name": "La Esperanza Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 1100,
      "lat": -14.7666664124,
      "lon": -65.5333328247,
      "tz": "America\/La_Paz"
  },
  "SLFA": {
      "icao": "SLFA",
      "iata": "",
      "name": "Fatima Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 1000,
      "lat": -15.3333330154,
      "lon": -66.8000030518,
      "tz": "America\/La_Paz"
  },
  "SLFL": {
      "icao": "SLFL",
      "iata": "",
      "name": "Florida Airport",
      "city": "Moira",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 600,
      "lat": -14.6141662598,
      "lon": -61.1974983215,
      "tz": "America\/La_Paz"
  },
  "SLFO": {
      "icao": "SLFO",
      "iata": "",
      "name": "Flor De Oro Airport",
      "city": "Moxos",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 560,
      "lat": -13.5548000336,
      "lon": -61.0041007996,
      "tz": "America\/La_Paz"
  },
  "SLFR": {
      "icao": "SLFR",
      "iata": "",
      "name": "Florencia Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 574,
      "lat": -15.6833333969,
      "lon": -65.5666656494,
      "tz": "America\/La_Paz"
  },
  "SLGP": {
      "icao": "SLGP",
      "iata": "",
      "name": "Guirapembi Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1493,
      "lat": -18.0559997559,
      "lon": -63.1631660461,
      "tz": "America\/La_Paz"
  },
  "SLGY": {
      "icao": "SLGY",
      "iata": "GYA",
      "name": "Capitan de Av. Emilio Beltran Airport",
      "city": "Guayaramerin",
      "state": "Rondonia",
      "country": "BO",
      "elevation": 557,
      "lat": -10.820599556,
      "lon": -65.3455963135,
      "tz": "America\/La_Paz"
  },
  "SLHA": {
      "icao": "SLHA",
      "iata": "",
      "name": "Cachascani Airport",
      "city": "Itenes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -13.5,
      "lon": -64.1999969482,
      "tz": "America\/La_Paz"
  },
  "SLHC": {
      "icao": "SLHC",
      "iata": "",
      "name": "Huacareta Airport",
      "city": "H. Silez",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 4668,
      "lat": -20.3908004761,
      "lon": -64.0154037476,
      "tz": "America\/La_Paz"
  },
  "SLHJ": {
      "icao": "SLHJ",
      "iata": "BVK",
      "name": "Huacaraje Airport",
      "city": "Itenes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 846,
      "lat": -13.5500001907,
      "lon": -63.7478981018,
      "tz": "America\/La_Paz"
  },
  "SLHS": {
      "icao": "SLHS",
      "iata": "",
      "name": "Chacobos Airport",
      "city": "Parapeti",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1437,
      "lat": -19.5948009491,
      "lon": -62.565700531,
      "tz": "America\/La_Paz"
  },
  "SLIG": {
      "icao": "SLIG",
      "iata": "",
      "name": "Inglaterra Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 780,
      "lat": -13.4437999725,
      "lon": -66.5059967041,
      "tz": "America\/La_Paz"
  },
  "SLIN": {
      "icao": "SLIN",
      "iata": "",
      "name": "Santa Catalina Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -15.5,
      "lon": -65.9166641235,
      "tz": "America\/La_Paz"
  },
  "SLIP": {
      "icao": "SLIP",
      "iata": "",
      "name": "Samaipata Airport",
      "city": "Florida",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 5600,
      "lat": -18.1833324432,
      "lon": -63.8833351135,
      "tz": "America\/La_Paz"
  },
  "SLIR": {
      "icao": "SLIR",
      "iata": "",
      "name": "Ibori Airport",
      "city": "Itenes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 775,
      "lat": -13.0500001907,
      "lon": -63.6666679382,
      "tz": "America\/La_Paz"
  },
  "SLIT": {
      "icao": "SLIT",
      "iata": "",
      "name": "Itaguazurenda Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1598,
      "lat": -19.7775001526,
      "lon": -63.0877990723,
      "tz": "America\/La_Paz"
  },
  "SLIX": {
      "icao": "SLIX",
      "iata": "",
      "name": "Ixiamas Airport",
      "city": "Iturralde",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 725,
      "lat": -13.7600002289,
      "lon": -68.1368331909,
      "tz": "America\/La_Paz"
  },
  "SLIY": {
      "icao": "SLIY",
      "iata": "",
      "name": "Intiraymi Airport",
      "city": "La Joya",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12467,
      "lat": -17.8083324432,
      "lon": -67.4433364868,
      "tz": "America\/La_Paz"
  },
  "SLIZ": {
      "icao": "SLIZ",
      "iata": "",
      "name": "Izozog Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1082,
      "lat": -18.6638336182,
      "lon": -63.0023345947,
      "tz": "America\/La_Paz"
  },
  "SLJA": {
      "icao": "SLJA",
      "iata": "",
      "name": "Jatata Airport",
      "city": "Madre De Dios",
      "state": "Pando",
      "country": "BO",
      "elevation": 575,
      "lat": -11.7728328705,
      "lon": -67.2559967041,
      "tz": "America\/La_Paz"
  },
  "SLJE": {
      "icao": "SLJE",
      "iata": "SJS",
      "name": "San Jose De Chiquitos Airport",
      "city": "San Jose de Chiquitos",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 974,
      "lat": -17.8307991028,
      "lon": -60.7430992126,
      "tz": "America\/La_Paz"
  },
  "SLJN": {
      "icao": "SLJN",
      "iata": "",
      "name": "San Juan Estancias Airport",
      "city": "Cercado",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 820,
      "lat": -14.1666669846,
      "lon": -64.7666702271,
      "tz": "America\/La_Paz"
  },
  "SLJO": {
      "icao": "SLJO",
      "iata": "SJB",
      "name": "San Joaquin Airport",
      "city": "San Joaquin",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 456,
      "lat": -13.0528001785,
      "lon": -64.6616973877,
      "tz": "America\/La_Paz"
  },
  "SLJS": {
      "icao": "SLJS",
      "iata": "",
      "name": "Josuani Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 498,
      "lat": -13.0833330154,
      "lon": -66.1666641235,
      "tz": "America\/La_Paz"
  },
  "SLJV": {
      "icao": "SLJV",
      "iata": "SJV",
      "name": "San Javier Airport",
      "city": "San Javier",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1702,
      "lat": -16.2707996368,
      "lon": -62.4702987671,
      "tz": "America\/La_Paz"
  },
  "SLLB": {
      "icao": "SLLB",
      "iata": "",
      "name": "Las Brizas Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -14.5166664124,
      "lon": -65.7666702271,
      "tz": "America\/La_Paz"
  },
  "SLLH": {
      "icao": "SLLH",
      "iata": "",
      "name": "Lago Huachi Airport",
      "city": "Itenes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 700,
      "lat": -14.0166664124,
      "lon": -63.5333328247,
      "tz": "America\/La_Paz"
  },
  "SLLI": {
      "icao": "SLLI",
      "iata": "",
      "name": "La India Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 540,
      "lat": -13.5738334656,
      "lon": -66.8356704712,
      "tz": "America\/La_Paz"
  },
  "SLLJ": {
      "icao": "SLLJ",
      "iata": "",
      "name": "Laja Airport",
      "city": "Los Andes",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 12103,
      "lat": -16.5338993073,
      "lon": -68.3005981445,
      "tz": "America\/La_Paz"
  },
  "SLLL": {
      "icao": "SLLL",
      "iata": "",
      "name": "Laguna Loa Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 720,
      "lat": -14.3000001907,
      "lon": -66.7833328247,
      "tz": "America\/La_Paz"
  },
  "SLLP": {
      "icao": "SLLP",
      "iata": "LPB",
      "name": "El Alto International Airport",
      "city": "La Paz \/ El Alto",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 13355,
      "lat": -16.513299942,
      "lon": -68.1922988892,
      "tz": "America\/La_Paz"
  },
  "SLLQ": {
      "icao": "SLLQ",
      "iata": "",
      "name": "Copaquilla Airport",
      "city": "L. Cabrera",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12150,
      "lat": -19.3333339691,
      "lon": -67.0833358765,
      "tz": "America\/La_Paz"
  },
  "SLLS": {
      "icao": "SLLS",
      "iata": "",
      "name": "Lagunillas Airport",
      "city": "Caraparicito",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 2950,
      "lat": -19.6000003815,
      "lon": -63.6500015259,
      "tz": "America\/La_Paz"
  },
  "SLLZ": {
      "icao": "SLLZ",
      "iata": "",
      "name": "San Lorenzo Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 825,
      "lat": -15.3833332062,
      "lon": -65.75,
      "tz": "America\/La_Paz"
  },
  "SLMB": {
      "icao": "SLMB",
      "iata": "",
      "name": "Mangabalito Airport",
      "city": "Velasco",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 1190,
      "lat": -13.7878332138,
      "lon": -68.3546676636,
      "tz": "America\/La_Paz"
  },
  "SLMD": {
      "icao": "SLMD",
      "iata": "",
      "name": "Madidi Airport",
      "city": "Iturralde",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 500,
      "lat": -13.3833332062,
      "lon": -68.6500015259,
      "tz": "America\/La_Paz"
  },
  "SLME": {
      "icao": "SLME",
      "iata": "",
      "name": "Mandeyepecua Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1338,
      "lat": -20.3980007172,
      "lon": -63.140499115,
      "tz": "America\/La_Paz"
  },
  "SLMG": {
      "icao": "SLMG",
      "iata": "MGD",
      "name": "Magdalena Airport",
      "city": "Magdalena",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 462,
      "lat": -13.2607483767,
      "lon": -64.0607643127,
      "tz": "America\/La_Paz"
  },
  "SLMM": {
      "icao": "SLMM",
      "iata": "",
      "name": "Mora Airport",
      "city": "Velasco",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 600,
      "lat": -14.608499527,
      "lon": -61.1918334961,
      "tz": "America\/La_Paz"
  },
  "SLMN": {
      "icao": "SLMN",
      "iata": "",
      "name": "Manuripi Airport",
      "city": "Manuripi",
      "state": "Pando",
      "country": "BO",
      "elevation": 705,
      "lat": -11.7384996414,
      "lon": -68.3546676636,
      "tz": "America\/La_Paz"
  },
  "SLMX": {
      "icao": "SLMX",
      "iata": "",
      "name": "Monos Arana Airport",
      "city": "Valle Grande",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1220,
      "lat": -17.3333339691,
      "lon": -64.1666641235,
      "tz": "America\/La_Paz"
  },
  "SLMZ": {
      "icao": "SLMZ",
      "iata": "",
      "name": "Mizque Airport",
      "city": "Mizque",
      "state": "Cochabamba",
      "country": "BO",
      "elevation": 7030,
      "lat": -17.9309997559,
      "lon": -65.3802032471,
      "tz": "America\/La_Paz"
  },
  "SLNL": {
      "icao": "SLNL",
      "iata": "",
      "name": "Canada Larga Airport",
      "city": "Chiquitos",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1197,
      "lat": -17.7000007629,
      "lon": -62.4166679382,
      "tz": "America\/La_Paz"
  },
  "SLNO": {
      "icao": "SLNO",
      "iata": "",
      "name": "Nuevo Mundo Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 548,
      "lat": -14.4333333969,
      "lon": -66.0666656494,
      "tz": "America\/La_Paz"
  },
  "SLNV": {
      "icao": "SLNV",
      "iata": "",
      "name": "Nieve Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 780,
      "lat": -14.0166664124,
      "lon": -65.8000030518,
      "tz": "America\/La_Paz"
  },
  "SLOR": {
      "icao": "SLOR",
      "iata": "ORU",
      "name": "Juan Mendoza Airport",
      "city": "Oruro",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12152,
      "lat": -17.962600708,
      "lon": -67.0762023926,
      "tz": "America\/La_Paz"
  },
  "SLPA": {
      "icao": "SLPA",
      "iata": "",
      "name": "Palmar Airport",
      "city": "Guanacos",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1342,
      "lat": -18.9716663361,
      "lon": -62.9753341675,
      "tz": "America\/La_Paz"
  },
  "SLPF": {
      "icao": "SLPF",
      "iata": "",
      "name": "Piso Firme Airport",
      "city": "Velasco",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 575,
      "lat": -13.6288337708,
      "lon": -61.7386665344,
      "tz": "America\/La_Paz"
  },
  "SLPG": {
      "icao": "SLPG",
      "iata": "",
      "name": "Progreso Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 754,
      "lat": -14.1784000397,
      "lon": -65.3756027222,
      "tz": "America\/La_Paz"
  },
  "SLPI": {
      "icao": "SLPI",
      "iata": "",
      "name": "Pitai Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 605,
      "lat": -15.8000001907,
      "lon": -65.6166687012,
      "tz": "America\/La_Paz"
  },
  "SLPL": {
      "icao": "SLPL",
      "iata": "",
      "name": "Padilla Airport",
      "city": "J. Mendoza",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 6770,
      "lat": -19.2994003296,
      "lon": -64.2940979004,
      "tz": "America\/La_Paz"
  },
  "SLPM": {
      "icao": "SLPM",
      "iata": "",
      "name": "Palmira Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 530,
      "lat": -13.7314996719,
      "lon": -66.4783325195,
      "tz": "America\/La_Paz"
  },
  "SLPN": {
      "icao": "SLPN",
      "iata": "",
      "name": "Porvenir Norte Airport",
      "city": "Velasco",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 595,
      "lat": -13.9873332977,
      "lon": -61.545665741,
      "tz": "America\/La_Paz"
  },
  "SLPO": {
      "icao": "SLPO",
      "iata": "POI",
      "name": "Capitan Nicolas Rojas Airport",
      "city": "Potosi",
      "state": "Potosi",
      "country": "BO",
      "elevation": 12913,
      "lat": -19.5431003571,
      "lon": -65.7237014771,
      "tz": "America\/La_Paz"
  },
  "SLPR": {
      "icao": "SLPR",
      "iata": "PUR",
      "name": "Puerto Rico Airport",
      "city": "Puerto Rico\/Manuripi",
      "state": "Pando",
      "country": "BO",
      "elevation": 597,
      "lat": -11.1076631546,
      "lon": -67.5511550903,
      "tz": "America\/La_Paz"
  },
  "SLPS": {
      "icao": "SLPS",
      "iata": "PSZ",
      "name": "Capitan Av. Salvador Ogaya G. airport",
      "city": "Puerto Suarez",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 505,
      "lat": -18.9752998352,
      "lon": -57.8205986023,
      "tz": "America\/La_Paz"
  },
  "SLPW": {
      "icao": "SLPW",
      "iata": "",
      "name": "El Porvenir Sur Airport",
      "city": "Luis Calvo",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 2238,
      "lat": -20.754699707,
      "lon": -63.209400177,
      "tz": "America\/La_Paz"
  },
  "SLPY": {
      "icao": "SLPY",
      "iata": "",
      "name": "Piray Airport",
      "city": "Sanja Honda",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1730,
      "lat": -18.3334999084,
      "lon": -63.1925010681,
      "tz": "America\/La_Paz"
  },
  "SLQN": {
      "icao": "SLQN",
      "iata": "",
      "name": "Coquinal Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 535,
      "lat": -13.1833333969,
      "lon": -67,
      "tz": "America\/La_Paz"
  },
  "SLRA": {
      "icao": "SLRA",
      "iata": "SRD",
      "name": "San Ramon Airport",
      "city": "San Ramon \/ Mamore",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 698,
      "lat": -13.2638998032,
      "lon": -64.6038970947,
      "tz": "America\/La_Paz"
  },
  "SLRB": {
      "icao": "SLRB",
      "iata": "RBO",
      "name": "Robore Airport",
      "city": "Robore",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 905,
      "lat": -18.3292007446,
      "lon": -59.7649993896,
      "tz": "America\/La_Paz"
  },
  "SLRF": {
      "icao": "SLRF",
      "iata": "",
      "name": "Refugio Airport",
      "city": "Velasco",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 610,
      "lat": -14.7627000809,
      "lon": -61.0320014954,
      "tz": "America\/La_Paz"
  },
  "SLRH": {
      "icao": "SLRH",
      "iata": "",
      "name": "Rancho Alegre Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 754,
      "lat": -12.1666669846,
      "lon": -65.7833328247,
      "tz": "America\/La_Paz"
  },
  "SLRI": {
      "icao": "SLRI",
      "iata": "RIB",
      "name": "Capitan Av. Selin Zeitun Lopez Airport",
      "city": "Riberalta",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 462,
      "lat": -11,
      "lon": -66,
      "tz": "America\/La_Paz"
  },
  "SLRL": {
      "icao": "SLRL",
      "iata": "",
      "name": "Rosal Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 482,
      "lat": -13.9750003815,
      "lon": -65.625,
      "tz": "America\/La_Paz"
  },
  "SLRP": {
      "icao": "SLRP",
      "iata": "",
      "name": "Rosapata Airport",
      "city": "Carangas",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12240,
      "lat": -18.6416664124,
      "lon": -67.5583343506,
      "tz": "America\/La_Paz"
  },
  "SLRQ": {
      "icao": "SLRQ",
      "iata": "RBQ",
      "name": "Rurenabaque Airport",
      "city": "Rurenabaque",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 676,
      "lat": -14.4279003143,
      "lon": -67.4968032837,
      "tz": "America\/La_Paz"
  },
  "SLRR": {
      "icao": "SLRR",
      "iata": "",
      "name": "Retiro Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 556,
      "lat": -14.4833335876,
      "lon": -66.3499984741,
      "tz": "America\/La_Paz"
  },
  "SLRS": {
      "icao": "SLRS",
      "iata": "",
      "name": "Rio Seco Airport",
      "city": "Campo Ingelmeco",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1836,
      "lat": -18.2008342743,
      "lon": -63.2560005188,
      "tz": "America\/La_Paz"
  },
  "SLRT": {
      "icao": "SLRT",
      "iata": "",
      "name": "Santa Rita Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 750,
      "lat": -13.9333333969,
      "lon": -66.3333358765,
      "tz": "America\/La_Paz"
  },
  "SLRX": {
      "icao": "SLRX",
      "iata": "",
      "name": "Rincon Del Tigre Airport",
      "city": "German Busch",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 664,
      "lat": -18.2008342743,
      "lon": -58.1699981689,
      "tz": "America\/La_Paz"
  },
  "SLRY": {
      "icao": "SLRY",
      "iata": "REY",
      "name": "Reyes Airport",
      "city": "Reyes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 935,
      "lat": -14.304400444,
      "lon": -67.3534011841,
      "tz": "America\/La_Paz"
  },
  "SLSA": {
      "icao": "SLSA",
      "iata": "SBL",
      "name": "Santa Ana Del Yacuma Airport",
      "city": "Santa Ana del Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 472,
      "lat": -13.7622003555,
      "lon": -65.4352035522,
      "tz": "America\/La_Paz"
  },
  "SLSB": {
      "icao": "SLSB",
      "iata": "SRJ",
      "name": "Capitan Av. German Quiroga G. Airport",
      "city": "San Borja",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 633,
      "lat": -14.8591995239,
      "lon": -66.7375030518,
      "tz": "America\/La_Paz"
  },
  "SLSD": {
      "icao": "SLSD",
      "iata": "",
      "name": "San Carlos Gutierrez Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 1820,
      "lat": -14.5611000061,
      "lon": -65.5271987915,
      "tz": "America\/La_Paz"
  },
  "SLSE": {
      "icao": "SLSE",
      "iata": "",
      "name": "Santa Teresita Airport",
      "city": "Angel Sandoval",
      "state": "Mato-Grosso",
      "country": "BO",
      "elevation": 756,
      "lat": -16.2849998474,
      "lon": -59.4951667786,
      "tz": "America\/Cuiaba"
  },
  "SLSF": {
      "icao": "SLSF",
      "iata": "",
      "name": "San Francisco Moxos Airport",
      "city": "Beni",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -14.9666662216,
      "lon": -65.6333312988,
      "tz": "America\/La_Paz"
  },
  "SLSG": {
      "icao": "SLSG",
      "iata": "",
      "name": "San Miguel De Gaser Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 700,
      "lat": -13.9020996094,
      "lon": -66.1567001343,
      "tz": "America\/La_Paz"
  },
  "SLSH": {
      "icao": "SLSH",
      "iata": "",
      "name": "Santa Ana De Huachi Airport",
      "city": "Sud Yungas",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 1640,
      "lat": -15.4833335876,
      "lon": -67.4833297729,
      "tz": "America\/La_Paz"
  },
  "SLSI": {
      "icao": "SLSI",
      "iata": "SNG",
      "name": "Capitan Av. Juan Cochamanidis S. Airport",
      "city": "San Ignacio de Velasco",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1354,
      "lat": -16.383600235,
      "lon": -60.9627990723,
      "tz": "America\/La_Paz"
  },
  "SLSJ": {
      "icao": "SLSJ",
      "iata": "",
      "name": "Salinas Airport",
      "city": "Ladislao Cabrera",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12027,
      "lat": -19.5,
      "lon": -67.3333358765,
      "tz": "America\/La_Paz"
  },
  "SLSK": {
      "icao": "SLSK",
      "iata": "",
      "name": "Santa Rosa Del Sara Airport",
      "city": "A. Ibanez",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 850,
      "lat": -17.1000003815,
      "lon": -63.6333351135,
      "tz": "America\/La_Paz"
  },
  "SLSM": {
      "icao": "SLSM",
      "iata": "SNM",
      "name": "San Ignacio de Moxos Airport",
      "city": "San Ignacio de Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 790,
      "lat": -14,
      "lon": -65.6339035034,
      "tz": "America\/La_Paz"
  },
  "SLSN": {
      "icao": "SLSN",
      "iata": "",
      "name": "Sanandita Airport",
      "city": "Gran Chaco",
      "state": "Tarija",
      "country": "BO",
      "elevation": 3200,
      "lat": -21.6499996185,
      "lon": -63.5999984741,
      "tz": "America\/La_Paz"
  },
  "SLSP": {
      "icao": "SLSP",
      "iata": "",
      "name": "Sipuati Airport",
      "city": "Gran Chaco",
      "state": "Tarija",
      "country": "BO",
      "elevation": 1400,
      "lat": -21.0734996796,
      "lon": -63.109500885,
      "tz": "America\/La_Paz"
  },
  "SLSQ": {
      "icao": "SLSQ",
      "iata": "",
      "name": "Santiago De Chiquitos Airport",
      "city": "Santiago de Chiquitos",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1990,
      "lat": -18.3458003998,
      "lon": -59.604801178,
      "tz": "America\/La_Paz"
  },
  "SLSR": {
      "icao": "SLSR",
      "iata": "SRB",
      "name": "Santa Rosa De Yacuma Airport",
      "city": "Santa Rosa",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 738,
      "lat": -14.0662002563,
      "lon": -66.7867965698,
      "tz": "America\/La_Paz"
  },
  "SLSS": {
      "icao": "SLSS",
      "iata": "",
      "name": "Saipiru Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 2039,
      "lat": -19.3911991119,
      "lon": -63.072101593,
      "tz": "America\/La_Paz"
  },
  "SLSU": {
      "icao": "SLSU",
      "iata": "SRE",
      "name": "Juana Azurduy De Padilla Airport",
      "city": "Sucre",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 9540,
      "lat": -19.0070991516,
      "lon": -65.2886962891,
      "tz": "America\/La_Paz"
  },
  "SLTA": {
      "icao": "SLTA",
      "iata": "",
      "name": "Taquipirenda Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 2340,
      "lat": -20.3432998657,
      "lon": -63.1394004822,
      "tz": "America\/La_Paz"
  },
  "SLTC": {
      "icao": "SLTC",
      "iata": "",
      "name": "Tres Cruces Airport",
      "city": "Chiquitos",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 880,
      "lat": -17.4316673279,
      "lon": -62.1051673889,
      "tz": "America\/La_Paz"
  },
  "SLTE": {
      "icao": "SLTE",
      "iata": "",
      "name": "Teoponte Airport",
      "city": "Larecaja",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 1400,
      "lat": -15.4983329773,
      "lon": -67.8166656494,
      "tz": "America\/La_Paz"
  },
  "SLTG": {
      "icao": "SLTG",
      "iata": "",
      "name": "Santiago Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 1000,
      "lat": -15.2278003693,
      "lon": -65.4501037598,
      "tz": "America\/La_Paz"
  },
  "SLTI": {
      "icao": "SLTI",
      "iata": "MQK",
      "name": "San Matias Airport",
      "city": "San Matias",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 403,
      "lat": -16.3391990662,
      "lon": -58.4019012451,
      "tz": "America\/Cuiaba"
  },
  "SLTJ": {
      "icao": "SLTJ",
      "iata": "TJA",
      "name": "Capitan Oriel Lea Plaza Airport",
      "city": "Tarija",
      "state": "Tarija",
      "country": "BO",
      "elevation": 6079,
      "lat": -21.5557003021,
      "lon": -64.7013015747,
      "tz": "America\/La_Paz"
  },
  "SLTL": {
      "icao": "SLTL",
      "iata": "",
      "name": "Toldos Airport",
      "city": "Nor Lipez",
      "state": "Potosi",
      "country": "BO",
      "elevation": 11800,
      "lat": -21.1686000824,
      "lon": -67.1789016724,
      "tz": "America\/La_Paz"
  },
  "SLTO": {
      "icao": "SLTO",
      "iata": "",
      "name": "Totora Airport",
      "city": "Oruro",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 12530,
      "lat": -17.798166275,
      "lon": -68.09349823,
      "tz": "America\/La_Paz"
  },
  "SLTR": {
      "icao": "SLTR",
      "iata": "TDD",
      "name": "Teniente Av. Jorge Henrich Arauz Airport",
      "city": "Trinidad",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 509,
      "lat": -14.8186998367,
      "lon": -64.9179992676,
      "tz": "America\/La_Paz"
  },
  "SLTU": {
      "icao": "SLTU",
      "iata": "",
      "name": "Turco Airport",
      "city": "Sajama",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12500,
      "lat": -18.1958332062,
      "lon": -68.141998291,
      "tz": "America\/La_Paz"
  },
  "SLTW": {
      "icao": "SLTW",
      "iata": "",
      "name": "Tita Airport",
      "city": "San Rafael",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1312,
      "lat": -18.4664001465,
      "lon": -62.1119995117,
      "tz": "America\/La_Paz"
  },
  "SLTX": {
      "icao": "SLTX",
      "iata": "",
      "name": "Tuichi Airport",
      "city": "Franz Tamayo",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 2360,
      "lat": -14.3166666031,
      "lon": -68.5833358765,
      "tz": "America\/La_Paz"
  },
  "SLTY": {
      "icao": "SLTY",
      "iata": "",
      "name": "Tiguipa Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -13.8000001907,
      "lon": -66.2333297729,
      "tz": "America\/La_Paz"
  },
  "SLTZ": {
      "icao": "SLTZ",
      "iata": "",
      "name": "Tupiza Airport",
      "city": "Sud Chichas",
      "state": "",
      "country": "BO",
      "elevation": 11300,
      "lat": -21.3446998596,
      "lon": -65.6156005859,
      "tz": "America\/La_Paz"
  },
  "SLUC": {
      "icao": "SLUC",
      "iata": "",
      "name": "Cuevo Airport",
      "city": "Cordillera",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 3440,
      "lat": -20.4804992676,
      "lon": -63.5281677246,
      "tz": "America\/La_Paz"
  },
  "SLUK": {
      "icao": "SLUK",
      "iata": "",
      "name": "Curahuara de Carangas Airport",
      "city": "Oruro",
      "state": "Oruro",
      "country": "BO",
      "elevation": 12920,
      "lat": -17.9857997894,
      "lon": -68.3943023682,
      "tz": "America\/La_Paz"
  },
  "SLUS": {
      "icao": "SLUS",
      "iata": "",
      "name": "Urusi Airport",
      "city": "Ballivian",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 805,
      "lat": -14.3166666031,
      "lon": -66.1666641235,
      "tz": "America\/La_Paz"
  },
  "SLUU": {
      "icao": "SLUU",
      "iata": "",
      "name": "Ulla Ulla Airport",
      "city": "Franz Tamayo",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 14360,
      "lat": -15.0333337784,
      "lon": -69.25,
      "tz": "America\/La_Paz"
  },
  "SLUY": {
      "icao": "SLUY",
      "iata": "UYU",
      "name": "Uyuni Airport",
      "city": "Quijarro",
      "state": "Potosi",
      "country": "BO",
      "elevation": 11136,
      "lat": -20.4463005066,
      "lon": -66.8483963013,
      "tz": "America\/La_Paz"
  },
  "SLVA": {
      "icao": "SLVA",
      "iata": "",
      "name": "Villa Aroma Airport",
      "city": "Aroma",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 12533,
      "lat": -17.3666667938,
      "lon": -67.75,
      "tz": "America\/La_Paz"
  },
  "SLVD": {
      "icao": "SLVD",
      "iata": "",
      "name": "Covendo Airport",
      "city": "Sud Yungas",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 4887,
      "lat": -15.8333330154,
      "lon": -67.0833358765,
      "tz": "America\/La_Paz"
  },
  "SLVE": {
      "icao": "SLVE",
      "iata": "",
      "name": "Venecia Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -14.6359996796,
      "lon": -66.7502975464,
      "tz": "America\/La_Paz"
  },
  "SLVG": {
      "icao": "SLVG",
      "iata": "VAH",
      "name": "Capitan Av. Vidal Villagomez Toledo Airport",
      "city": "Vallegrande",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 6551,
      "lat": -18.4825000763,
      "lon": -64.0994033813,
      "tz": "America\/La_Paz"
  },
  "SLVI": {
      "icao": "SLVI",
      "iata": "",
      "name": "Caranavi Airport",
      "city": "Nor Yungas",
      "state": "La-Paz",
      "country": "BO",
      "elevation": 2100,
      "lat": -15.75,
      "lon": -67.5666656494,
      "tz": "America\/La_Paz"
  },
  "SLVM": {
      "icao": "SLVM",
      "iata": "VLM",
      "name": "Teniente Coronel Rafael Pabon Airport",
      "city": "Villamontes",
      "state": "Tarija",
      "country": "BO",
      "elevation": 1305,
      "lat": -21.2551994324,
      "lon": -63.4056015015,
      "tz": "America\/La_Paz"
  },
  "SLVN": {
      "icao": "SLVN",
      "iata": "",
      "name": "Villa Negrita Airport",
      "city": "Yacuma",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 872,
      "lat": -13.2658004761,
      "lon": -65.8758010864,
      "tz": "America\/La_Paz"
  },
  "SLVR": {
      "icao": "SLVR",
      "iata": "VVI",
      "name": "Viru Viru International Airport",
      "city": "Santa Cruz",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1224,
      "lat": -17.6448001862,
      "lon": -63.1353988647,
      "tz": "America\/La_Paz"
  },
  "SLVT": {
      "icao": "SLVT",
      "iata": "",
      "name": "Tesoro\/La Vertiente Airport",
      "city": "Tesoro",
      "state": "Tarija",
      "country": "BO",
      "elevation": 1300,
      "lat": -21.2666664124,
      "lon": -63.3583335876,
      "tz": "America\/La_Paz"
  },
  "SLVU": {
      "icao": "SLVU",
      "iata": "",
      "name": "Vuelta Grande Airport",
      "city": "Luis Calvo",
      "state": "Chuquisaca",
      "country": "BO",
      "elevation": 1900,
      "lat": -20.90483284,
      "lon": -63.1921653748,
      "tz": "America\/La_Paz"
  },
  "SLVV": {
      "icao": "SLVV",
      "iata": "",
      "name": "Villa Elvira Airport",
      "city": "Mamore",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 722,
      "lat": -13.6443004608,
      "lon": -64.4408035278,
      "tz": "America\/La_Paz"
  },
  "SLVZ": {
      "icao": "SLVZ",
      "iata": "",
      "name": "Villazon Airport",
      "city": "M.Omiste",
      "state": "Potosi",
      "country": "BO",
      "elevation": 1100,
      "lat": -22.0776996613,
      "lon": -65.5888977051,
      "tz": "America\/Argentina\/Jujuy"
  },
  "SLYA": {
      "icao": "SLYA",
      "iata": "BYC",
      "name": "Yacuiba Airport",
      "city": "Yacuiba",
      "state": "Tarija",
      "country": "BO",
      "elevation": 2112,
      "lat": -21.960899353,
      "lon": -63.6516990662,
      "tz": "America\/La_Paz"
  },
  "SLYG": {
      "icao": "SLYG",
      "iata": "",
      "name": "Yabog Airport",
      "city": "Las Juntas",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1412,
      "lat": -18.654499054,
      "lon": -63.1328010559,
      "tz": "America\/La_Paz"
  },
  "SLYI": {
      "icao": "SLYI",
      "iata": "",
      "name": "Yapacani Airport",
      "city": "Ichilo",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 816,
      "lat": -17.0156669617,
      "lon": -64.0563354492,
      "tz": "America\/La_Paz"
  },
  "SLZB": {
      "icao": "SLZB",
      "iata": "",
      "name": "San Pedro Rb Airport",
      "city": "Moxos",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 720,
      "lat": -14.8666667938,
      "lon": -65.6500015259,
      "tz": "America\/La_Paz"
  },
  "SLZC": {
      "icao": "SLZC",
      "iata": "",
      "name": "San Carlos Airport",
      "city": "Ichilo",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1150,
      "lat": -17.4333324432,
      "lon": -63.6833343506,
      "tz": "America\/La_Paz"
  },
  "SLZF": {
      "icao": "SLZF",
      "iata": "",
      "name": "San Francisco Naciff Airport",
      "city": "Nacif",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 482,
      "lat": -13.7486000061,
      "lon": -64.4536972046,
      "tz": "America\/La_Paz"
  },
  "SLZJ": {
      "icao": "SLZJ",
      "iata": "",
      "name": "San Pedro Richard Airport",
      "city": "Itenes",
      "state": "El-Beni",
      "country": "BO",
      "elevation": 700,
      "lat": -14.2166662216,
      "lon": -63.6500015259,
      "tz": "America\/La_Paz"
  },
  "SLZV": {
      "icao": "SLZV",
      "iata": "",
      "name": "San Vicente Airport",
      "city": "Velasco",
      "state": "Santa-Cruz",
      "country": "BO",
      "elevation": 1000,
      "lat": -16.2737007141,
      "lon": -60.0906982422,
      "tz": "America\/Cuiaba"
  },
  "SMAF": {
      "icao": "SMAF",
      "iata": "",
      "name": "Afobakka Airstrip",
      "city": "Afobakka",
      "state": "Brokopondo",
      "country": "SR",
      "elevation": 80,
      "lat": 4.998505,
      "lon": -54.992033,
      "tz": "America\/Paramaribo"
  },
  "SMBN": {
      "icao": "SMBN",
      "iata": "ABN",
      "name": "Albina Airport",
      "city": "Albina",
      "state": "Marowijne",
      "country": "SR",
      "elevation": 19,
      "lat": 5.512720108,
      "lon": -54.0500984192,
      "tz": "America\/Cayenne"
  },
  "SMBO": {
      "icao": "SMBO",
      "iata": "BTO",
      "name": "Botopasi Airport",
      "city": "Botopasi",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 242,
      "lat": 4.2175110935,
      "lon": -55.4470539093,
      "tz": "America\/Paramaribo"
  },
  "SMCA": {
      "icao": "SMCA",
      "iata": "AAJ",
      "name": "Cayana Airstrip",
      "city": "Awaradam",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 360,
      "lat": 3.898681,
      "lon": -55.577907,
      "tz": "America\/Paramaribo"
  },
  "SMCI": {
      "icao": "SMCI",
      "iata": "",
      "name": "Coeroeni Airport",
      "city": "Coeroeni",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 479,
      "lat": 3.367000103,
      "lon": -57.3330001831,
      "tz": "America\/Guyana"
  },
  "SMCO": {
      "icao": "SMCO",
      "iata": "TOT",
      "name": "Totness Airport",
      "city": "Totness",
      "state": "Coronie",
      "country": "SR",
      "elevation": 10,
      "lat": 5.8658299446,
      "lon": -56.3274993896,
      "tz": "America\/Paramaribo"
  },
  "SMCT": {
      "icao": "SMCT",
      "iata": "",
      "name": "Cottica Airstrip",
      "city": "Lawa Cottica",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 330,
      "lat": 3.85216,
      "lon": -54.228201,
      "tz": "America\/Cayenne"
  },
  "SMDA": {
      "icao": "SMDA",
      "iata": "DRJ",
      "name": "Drietabbetje Airport",
      "city": "Drietabbetje",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 236,
      "lat": 4.1166667938,
      "lon": -54.6666717529,
      "tz": "America\/Paramaribo"
  },
  "SMDJ": {
      "icao": "SMDJ",
      "iata": "DOE",
      "name": "Djumu-Djomoe Airport",
      "city": "Djumu-Djomoe",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 290,
      "lat": 4.0057126106,
      "lon": -55.4816436768,
      "tz": "America\/Paramaribo"
  },
  "SMDO": {
      "icao": "SMDO",
      "iata": "LDO",
      "name": "Ladouanie Airport",
      "city": "Aurora",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 236,
      "lat": 4.3761082835,
      "lon": -55.4070568085,
      "tz": "America\/Paramaribo"
  },
  "SMDU": {
      "icao": "SMDU",
      "iata": "",
      "name": "Alalapadu Airstrip",
      "city": "Alalapadi",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 880,
      "lat": 2.5235,
      "lon": -56.3247,
      "tz": "America\/Paramaribo"
  },
  "SMGH": {
      "icao": "SMGH",
      "iata": "",
      "name": "Godo Holo Airstrip",
      "city": "Pikienkondre of Miranda",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 280,
      "lat": 4.0583,
      "lon": -54.7861,
      "tz": "America\/Paramaribo"
  },
  "SMJP": {
      "icao": "SMJP",
      "iata": "PBM",
      "name": "Johan Adolf Pengel International Airport",
      "city": "Zandery",
      "state": "Para",
      "country": "SR",
      "elevation": 59,
      "lat": 5.4528298378,
      "lon": -55.1878013611,
      "tz": "America\/Paramaribo"
  },
  "SMKA": {
      "icao": "SMKA",
      "iata": "",
      "name": "Kabalebo Airport",
      "city": "Kabalebo",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 535,
      "lat": 4.4060001373,
      "lon": -57.2229995728,
      "tz": "America\/Paramaribo"
  },
  "SMKE": {
      "icao": "SMKE",
      "iata": "",
      "name": "Kayser Airport",
      "city": "Kayserberg",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 849,
      "lat": 3.0999999046,
      "lon": -56.483001709,
      "tz": "America\/Paramaribo"
  },
  "SMMO": {
      "icao": "SMMO",
      "iata": "MOJ",
      "name": "Moengo Airstrip",
      "city": "Moengo",
      "state": "Marowijne",
      "country": "SR",
      "elevation": 49,
      "lat": 5.6076,
      "lon": -54.4003,
      "tz": "America\/Paramaribo"
  },
  "SMNI": {
      "icao": "SMNI",
      "iata": "ICK",
      "name": "Nieuw Nickerie Airport",
      "city": "Nieuw Nickerie",
      "state": "Nickerie",
      "country": "SR",
      "elevation": 9,
      "lat": 5.9555602074,
      "lon": -57.0393981934,
      "tz": "America\/Paramaribo"
  },
  "SMPA": {
      "icao": "SMPA",
      "iata": "OEM",
      "name": "Vincent Fayks Airport",
      "city": "Paloemeu",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 714,
      "lat": 3.345279932,
      "lon": -55.4425010681,
      "tz": "America\/Paramaribo"
  },
  "SMPG": {
      "icao": "SMPG",
      "iata": "",
      "name": "Poesoegroenoe(Pusugrunu) Airstrip",
      "city": "Poesoegroenoe",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 390,
      "lat": 4.397914,
      "lon": -55.793374,
      "tz": "America\/Paramaribo"
  },
  "SMSI": {
      "icao": "SMSI",
      "iata": "",
      "name": "Sipaliwini Airport",
      "city": "Sipaliwini",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 744,
      "lat": 1.9660500288,
      "lon": -56.0035018921,
      "tz": "America\/Paramaribo"
  },
  "SMSM": {
      "icao": "SMSM",
      "iata": "",
      "name": "Kwamalasoemoetoe Airport Airport",
      "city": "Kwamalasoemoetoe Airport",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 905,
      "lat": 2.333329916,
      "lon": -56.7833328247,
      "tz": "America\/Paramaribo"
  },
  "SMST": {
      "icao": "SMST",
      "iata": "SMZ",
      "name": "Stoelmanseiland Airport",
      "city": "Stoelmanseiland",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 187,
      "lat": 4.3499999046,
      "lon": -54.4166679382,
      "tz": "America\/Paramaribo"
  },
  "SMTA": {
      "icao": "SMTA",
      "iata": "",
      "name": "Tabiki Airstrip",
      "city": "Benzdorp",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 740,
      "lat": 3.619932,
      "lon": -54.142714,
      "tz": "America\/Paramaribo"
  },
  "SMTB": {
      "icao": "SMTB",
      "iata": "",
      "name": "Rudi Kappel Airport",
      "city": "Tafelberg",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 1112,
      "lat": 3.7829999924,
      "lon": -56.1500015259,
      "tz": "America\/Paramaribo"
  },
  "SMTP": {
      "icao": "SMTP",
      "iata": "KCB",
      "name": "Tepoe Airstrip",
      "city": "Kasikasima",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 596,
      "lat": 3.1500000954,
      "lon": -55.716999054,
      "tz": "America\/Paramaribo"
  },
  "SMWA": {
      "icao": "SMWA",
      "iata": "AGI",
      "name": "Wageningen Airport Airport",
      "city": "Wageningen Airport",
      "state": "Nickerie",
      "country": "SR",
      "elevation": 6,
      "lat": 5.7666668892,
      "lon": -56.6333312988,
      "tz": "America\/Paramaribo"
  },
  "SMWS": {
      "icao": "SMWS",
      "iata": "WSO",
      "name": "Washabo Airport",
      "city": "Washabo",
      "state": "Sipaliwini",
      "country": "SR",
      "elevation": 68,
      "lat": 5.2501502037,
      "lon": -57.1738014221,
      "tz": "America\/Guyana"
  },
  "SMZO": {
      "icao": "SMZO",
      "iata": "ORG",
      "name": "Zorg en Hoop Airport",
      "city": "Paramaribo",
      "state": "Paramaribo",
      "country": "SR",
      "elevation": 10,
      "lat": 5.8110799789,
      "lon": -55.190700531,
      "tz": "America\/Paramaribo"
  },
  "SN00": {
      "icao": "SN00",
      "iata": "",
      "name": "J Roesner Airport",
      "city": "Salina",
      "state": "Kansas",
      "country": "US",
      "elevation": 1211,
      "lat": 38.8469009399,
      "lon": -97.5503005981,
      "tz": "America\/Chicago"
  },
  "SN02": {
      "icao": "SN02",
      "iata": "",
      "name": "Wright International Airport",
      "city": "Kanorado",
      "state": "Kansas",
      "country": "US",
      "elevation": 3883,
      "lat": 39.4082984924,
      "lon": -102.0210037231,
      "tz": "America\/Denver"
  },
  "SN03": {
      "icao": "SN03",
      "iata": "",
      "name": "Lenora Municipal Airport",
      "city": "Lenora",
      "state": "Kansas",
      "country": "US",
      "elevation": 2373,
      "lat": 39.6293983459,
      "lon": -100.0149993896,
      "tz": "America\/Chicago"
  },
  "SN04": {
      "icao": "SN04",
      "iata": "",
      "name": "Roberts Memorial Airport",
      "city": "Emporia",
      "state": "Kansas",
      "country": "US",
      "elevation": 1185,
      "lat": 38.4230995178,
      "lon": -96.3719024658,
      "tz": "America\/Chicago"
  },
  "SN05": {
      "icao": "SN05",
      "iata": "",
      "name": "Halstead Airport",
      "city": "Halstead",
      "state": "Kansas",
      "country": "US",
      "elevation": 1412,
      "lat": 38.0313987732,
      "lon": -97.5092010498,
      "tz": "America\/Chicago"
  },
  "SN07": {
      "icao": "SN07",
      "iata": "",
      "name": "Beaumont Hotel Airport",
      "city": "Beaumont",
      "state": "Kansas",
      "country": "US",
      "elevation": 1617,
      "lat": 37.659198761,
      "lon": -96.5271987915,
      "tz": "America\/Chicago"
  },
  "SN08": {
      "icao": "SN08",
      "iata": "",
      "name": "Fisher Airport",
      "city": "Burrton",
      "state": "Kansas",
      "country": "US",
      "elevation": 1430,
      "lat": 37.9916992188,
      "lon": -97.6564025879,
      "tz": "America\/Chicago"
  },
  "SN09": {
      "icao": "SN09",
      "iata": "",
      "name": "Solomon Valley Airpark",
      "city": "Minneapolis",
      "state": "Kansas",
      "country": "US",
      "elevation": 1205,
      "lat": 39.113899231,
      "lon": -97.6830978394,
      "tz": "America\/Chicago"
  },
  "SN10": {
      "icao": "SN10",
      "iata": "",
      "name": "Belle Plaine Farms Airport",
      "city": "Belle Plaine",
      "state": "Kansas",
      "country": "US",
      "elevation": 1190,
      "lat": 37.3958015442,
      "lon": -97.2099990845,
      "tz": "America\/Chicago"
  },
  "SN12": {
      "icao": "SN12",
      "iata": "",
      "name": "Jenkinson Airport",
      "city": "Meade",
      "state": "Kansas",
      "country": "US",
      "elevation": 2737,
      "lat": 37.363899231,
      "lon": -100.4710006714,
      "tz": "America\/Chicago"
  },
  "SN13": {
      "icao": "SN13",
      "iata": "",
      "name": "Albers Airport",
      "city": "Cheney",
      "state": "Kansas",
      "country": "US",
      "elevation": 1380,
      "lat": 37.6582984924,
      "lon": -97.7744979858,
      "tz": "America\/Chicago"
  },
  "SN14": {
      "icao": "SN14",
      "iata": "",
      "name": "Schoolcraft Airport",
      "city": "Agricola",
      "state": "Kansas",
      "country": "US",
      "elevation": 1165,
      "lat": 38.4166984558,
      "lon": -95.5168991089,
      "tz": "America\/Chicago"
  },
  "SN16": {
      "icao": "SN16",
      "iata": "",
      "name": "Curtis Airport",
      "city": "Kechi",
      "state": "Kansas",
      "country": "US",
      "elevation": 1380,
      "lat": 37.8563995361,
      "lon": -97.2697982788,
      "tz": "America\/Chicago"
  },
  "SN17": {
      "icao": "SN17",
      "iata": "",
      "name": "Shupe Airport",
      "city": "Ashland",
      "state": "Kansas",
      "country": "US",
      "elevation": 1769,
      "lat": 37.0719985962,
      "lon": -99.6051025391,
      "tz": "America\/Chicago"
  },
  "SN18": {
      "icao": "SN18",
      "iata": "",
      "name": "Sills Air Park",
      "city": "Augusta",
      "state": "Kansas",
      "country": "US",
      "elevation": 1295,
      "lat": 37.6666984558,
      "lon": -97.016998291,
      "tz": "America\/Chicago"
  },
  "SN19": {
      "icao": "SN19",
      "iata": "",
      "name": "Flying H Ranch Airport",
      "city": "Augusta",
      "state": "Kansas",
      "country": "US",
      "elevation": 1400,
      "lat": 37.625,
      "lon": -97.0961990356,
      "tz": "America\/Chicago"
  },
  "SN20": {
      "icao": "SN20",
      "iata": "",
      "name": "Brady-Pippin Airport",
      "city": "Augusta",
      "state": "Kansas",
      "country": "US",
      "elevation": 1300,
      "lat": 37.7195014954,
      "lon": -97.0488967896,
      "tz": "America\/Chicago"
  },
  "SN21": {
      "icao": "SN21",
      "iata": "",
      "name": "Flory \/Private\/ Airport",
      "city": "Baldwin City",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 38.8227996826,
      "lon": -95.2878036499,
      "tz": "America\/Chicago"
  },
  "SN22": {
      "icao": "SN22",
      "iata": "",
      "name": "Hoelting Airport",
      "city": "Basehor",
      "state": "Kansas",
      "country": "US",
      "elevation": 960,
      "lat": 39.1122016907,
      "lon": -94.9561004639,
      "tz": "America\/Chicago"
  },
  "SN23": {
      "icao": "SN23",
      "iata": "",
      "name": "Overmiller Airport",
      "city": "Smith Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 2010,
      "lat": 39.9119987488,
      "lon": -98.6656036377,
      "tz": "America\/Chicago"
  },
  "SN25": {
      "icao": "SN25",
      "iata": "",
      "name": "Lucas Airport",
      "city": "Big Bow",
      "state": "Kansas",
      "country": "US",
      "elevation": 3172,
      "lat": 37.418598175,
      "lon": -101.5579986572,
      "tz": "America\/Chicago"
  },
  "SN26": {
      "icao": "SN26",
      "iata": "",
      "name": "Horttor Airport",
      "city": "Blue Mound",
      "state": "Kansas",
      "country": "US",
      "elevation": 970,
      "lat": 38.1777992249,
      "lon": -95.0029983521,
      "tz": "America\/Chicago"
  },
  "SN27": {
      "icao": "SN27",
      "iata": "",
      "name": "Bovinair Airport",
      "city": "Bonner Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 920,
      "lat": 39.0625,
      "lon": -94.9447021484,
      "tz": "America\/Chicago"
  },
  "SN28": {
      "icao": "SN28",
      "iata": "",
      "name": "Belcher Airport",
      "city": "Brookville",
      "state": "Kansas",
      "country": "US",
      "elevation": 1420,
      "lat": 38.86859894,
      "lon": -97.93779755,
      "tz": "America\/Chicago"
  },
  "SN29": {
      "icao": "SN29",
      "iata": "",
      "name": "Rucker Burdett Airport",
      "city": "Burdett",
      "state": "Kansas",
      "country": "US",
      "elevation": 2151,
      "lat": 38.1875,
      "lon": -99.533996582,
      "tz": "America\/Chicago"
  },
  "SN32": {
      "icao": "SN32",
      "iata": "",
      "name": "Rands Airport",
      "city": "Belle Plaine",
      "state": "Kansas",
      "country": "US",
      "elevation": 1210,
      "lat": 37.426399231,
      "lon": -97.2695007324,
      "tz": "America\/Chicago"
  },
  "SN33": {
      "icao": "SN33",
      "iata": "",
      "name": "Callaway Airpark",
      "city": "Clay Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1275,
      "lat": 39.4333000183,
      "lon": -97.1211013794,
      "tz": "America\/Chicago"
  },
  "SN34": {
      "icao": "SN34",
      "iata": "",
      "name": "Rucker Airport",
      "city": "Clearwater",
      "state": "Kansas",
      "country": "US",
      "elevation": 1300,
      "lat": 37.5750007629,
      "lon": -97.4919967651,
      "tz": "America\/Chicago"
  },
  "SN36": {
      "icao": "SN36",
      "iata": "",
      "name": "Wamsley Field",
      "city": "Conway Springs",
      "state": "Kansas",
      "country": "US",
      "elevation": 1335,
      "lat": 37.426399231,
      "lon": -97.637802124,
      "tz": "America\/Chicago"
  },
  "SN37": {
      "icao": "SN37",
      "iata": "",
      "name": "Harold K. Wells Airport",
      "city": "Elmdale",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 38.3870010376,
      "lon": -96.7296981812,
      "tz": "America\/Chicago"
  },
  "SN39": {
      "icao": "SN39",
      "iata": "",
      "name": "Baldock Farm Airport",
      "city": "Delphos",
      "state": "Kansas",
      "country": "US",
      "elevation": 1350,
      "lat": 39.25,
      "lon": -97.6502990723,
      "tz": "America\/Chicago"
  },
  "SN40": {
      "icao": "SN40",
      "iata": "",
      "name": "Olson Aerodrome",
      "city": "Derby",
      "state": "Kansas",
      "country": "US",
      "elevation": 1325,
      "lat": 37.523601532,
      "lon": -97.1984024048,
      "tz": "America\/Chicago"
  },
  "SN41": {
      "icao": "SN41",
      "iata": "",
      "name": "Ziggy Carline Airport",
      "city": "Girard",
      "state": "Kansas",
      "country": "US",
      "elevation": 1300,
      "lat": 37.5041999817,
      "lon": -94.8266983032,
      "tz": "America\/Chicago"
  },
  "SN42": {
      "icao": "SN42",
      "iata": "",
      "name": "Harrod Airport",
      "city": "Ottawa",
      "state": "Kansas",
      "country": "US",
      "elevation": 990,
      "lat": 38.5614013672,
      "lon": -95.2081985474,
      "tz": "America\/Chicago"
  },
  "SN43": {
      "icao": "SN43",
      "iata": "",
      "name": "Strafuss Airport",
      "city": "Effingham",
      "state": "Kansas",
      "country": "US",
      "elevation": 1050,
      "lat": 39.5041999817,
      "lon": -95.4710998535,
      "tz": "America\/Chicago"
  },
  "SN44": {
      "icao": "SN44",
      "iata": "",
      "name": "Bob Faler Airport",
      "city": "Elk City",
      "state": "Kansas",
      "country": "US",
      "elevation": 850,
      "lat": 37.3000984192,
      "lon": -95.9002990723,
      "tz": "America\/Chicago"
  },
  "SN45": {
      "icao": "SN45",
      "iata": "",
      "name": "Michael's Airport",
      "city": "Lawrence",
      "state": "Kansas",
      "country": "US",
      "elevation": 1105,
      "lat": 38.831401825,
      "lon": -95.2664031982,
      "tz": "America\/Chicago"
  },
  "SN46": {
      "icao": "SN46",
      "iata": "",
      "name": "Taylor Airport",
      "city": "Benton",
      "state": "Kansas",
      "country": "US",
      "elevation": 1340,
      "lat": 37.8718986511,
      "lon": -97.0721969604,
      "tz": "America\/Chicago"
  },
  "SN47": {
      "icao": "SN47",
      "iata": "",
      "name": "Converse Farm Airport",
      "city": "Eskridge",
      "state": "Kansas",
      "country": "US",
      "elevation": 1231,
      "lat": 38.7667007446,
      "lon": -96.1072006226,
      "tz": "America\/Chicago"
  },
  "SN48": {
      "icao": "SN48",
      "iata": "",
      "name": "Moore Field",
      "city": "Byers",
      "state": "Kansas",
      "country": "US",
      "elevation": 1992,
      "lat": 37.7882995605,
      "lon": -98.8498001099,
      "tz": "America\/Chicago"
  },
  "SN49": {
      "icao": "SN49",
      "iata": "",
      "name": "Mc Collough Airfield",
      "city": "Randall",
      "state": "Kansas",
      "country": "US",
      "elevation": 1465,
      "lat": 39.6852989197,
      "lon": -97.9711990356,
      "tz": "America\/Chicago"
  },
  "SN51": {
      "icao": "SN51",
      "iata": "",
      "name": "Meitl Airport",
      "city": "Dresden",
      "state": "Kansas",
      "country": "US",
      "elevation": 2725,
      "lat": 39.5896987915,
      "lon": -100.4280014038,
      "tz": "America\/Chicago"
  },
  "SN52": {
      "icao": "SN52",
      "iata": "",
      "name": "Pilot Pointe Estates Airport",
      "city": "Douglass",
      "state": "Kansas",
      "country": "US",
      "elevation": 1237,
      "lat": 37.48139954,
      "lon": -97.01200104,
      "tz": "America\/Chicago"
  },
  "SN53": {
      "icao": "SN53",
      "iata": "",
      "name": "Bonner Field",
      "city": "Fredonia",
      "state": "Kansas",
      "country": "US",
      "elevation": 950,
      "lat": 37.4333992004,
      "lon": -95.9169006348,
      "tz": "America\/Chicago"
  },
  "SN55": {
      "icao": "SN55",
      "iata": "",
      "name": "R J C Farms Inc Airport",
      "city": "Friend",
      "state": "Kansas",
      "country": "US",
      "elevation": 2900,
      "lat": 38.2153015137,
      "lon": -101.0029983521,
      "tz": "America\/Chicago"
  },
  "SN57": {
      "icao": "SN57",
      "iata": "",
      "name": "Witham Airport",
      "city": "Ottawa",
      "state": "Kansas",
      "country": "US",
      "elevation": 1110,
      "lat": 38.698600769,
      "lon": -95.3003005981,
      "tz": "America\/Chicago"
  },
  "SN58": {
      "icao": "SN58",
      "iata": "",
      "name": "Foster Field",
      "city": "El Dorado",
      "state": "Kansas",
      "country": "US",
      "elevation": 1440,
      "lat": 37.8699989319,
      "lon": -96.646697998,
      "tz": "America\/Chicago"
  },
  "SN59": {
      "icao": "SN59",
      "iata": "",
      "name": "Hermon Farm Airport",
      "city": "Gardner",
      "state": "Kansas",
      "country": "US",
      "elevation": 1060,
      "lat": 38.8527984619,
      "lon": -94.9100036621,
      "tz": "America\/Chicago"
  },
  "SN61": {
      "icao": "SN61",
      "iata": "",
      "name": "Yoder Airpark",
      "city": "Goddard",
      "state": "Kansas",
      "country": "US",
      "elevation": 1475,
      "lat": 37.6596984863,
      "lon": -97.6295013428,
      "tz": "America\/Chicago"
  },
  "SN62": {
      "icao": "SN62",
      "iata": "",
      "name": "Roberts Field",
      "city": "Bentley",
      "state": "Kansas",
      "country": "US",
      "elevation": 1384,
      "lat": 37.8741989136,
      "lon": -97.5124969482,
      "tz": "America\/Chicago"
  },
  "SN63": {
      "icao": "SN63",
      "iata": "",
      "name": "Gail Ballard Airport",
      "city": "Haviland",
      "state": "Kansas",
      "country": "US",
      "elevation": 2165,
      "lat": 37.6083984375,
      "lon": -99.1156997681,
      "tz": "America\/Chicago"
  },
  "SN64": {
      "icao": "SN64",
      "iata": "",
      "name": "Eck Field",
      "city": "Goddard",
      "state": "Kansas",
      "country": "US",
      "elevation": 1330,
      "lat": 37.6958007813,
      "lon": -97.5391998291,
      "tz": "America\/Chicago"
  },
  "SN65": {
      "icao": "SN65",
      "iata": "",
      "name": "Lake Waltanna Airport",
      "city": "Goddard",
      "state": "Kansas",
      "country": "US",
      "elevation": 1440,
      "lat": 37.5917015076,
      "lon": -97.6169967651,
      "tz": "America\/Chicago"
  },
  "SN66": {
      "icao": "SN66",
      "iata": "",
      "name": "Beesley Farms Airport",
      "city": "Gove",
      "state": "Kansas",
      "country": "US",
      "elevation": 2635,
      "lat": 38.8182983398,
      "lon": -100.4550018311,
      "tz": "America\/Chicago"
  },
  "SN67": {
      "icao": "SN67",
      "iata": "",
      "name": "Button Airport",
      "city": "Great Bend",
      "state": "Kansas",
      "country": "US",
      "elevation": 1845,
      "lat": 38.3833007813,
      "lon": -98.7503967285,
      "tz": "America\/Chicago"
  },
  "SN68": {
      "icao": "SN68",
      "iata": "",
      "name": "Lil Bird Airport",
      "city": "Valley Center",
      "state": "Kansas",
      "country": "US",
      "elevation": 1456,
      "lat": 37.8791999817,
      "lon": -97.3103027344,
      "tz": "America\/Chicago"
  },
  "SN69": {
      "icao": "SN69",
      "iata": "",
      "name": "Emmerson Airport",
      "city": "Hammond",
      "state": "Kansas",
      "country": "US",
      "elevation": 860,
      "lat": 37.9314002991,
      "lon": -94.6427001953,
      "tz": "America\/Chicago"
  },
  "SN70": {
      "icao": "SN70",
      "iata": "",
      "name": "Kaypod Airport",
      "city": "Harper",
      "state": "Kansas",
      "country": "US",
      "elevation": 1379,
      "lat": 37.2513999939,
      "lon": -97.954498291,
      "tz": "America\/Chicago"
  },
  "SN71": {
      "icao": "SN71",
      "iata": "",
      "name": "Bob Park Airport",
      "city": "Harper",
      "state": "Kansas",
      "country": "US",
      "elevation": 1396,
      "lat": 37.3027992249,
      "lon": -97.9642028809,
      "tz": "America\/Chicago"
  },
  "SN72": {
      "icao": "SN72",
      "iata": "",
      "name": "Graham Farms Airport",
      "city": "Harris",
      "state": "Kansas",
      "country": "US",
      "elevation": 985,
      "lat": 38.2999992371,
      "lon": -95.4400024414,
      "tz": "America\/Chicago"
  },
  "SN75": {
      "icao": "SN75",
      "iata": "",
      "name": "Sommers Airport",
      "city": "Highland",
      "state": "Kansas",
      "country": "US",
      "elevation": 1070,
      "lat": 39.8666992188,
      "lon": -95.3336029053,
      "tz": "America\/Chicago"
  },
  "SN76": {
      "icao": "SN76",
      "iata": "",
      "name": "Sunflower Aerodrome",
      "city": "Hutchinson",
      "state": "Kansas",
      "country": "US",
      "elevation": 1582,
      "lat": 37.926399231,
      "lon": -97.9061965942,
      "tz": "America\/Chicago"
  },
  "SN77": {
      "icao": "SN77",
      "iata": "",
      "name": "Johns Airport",
      "city": "Johnson",
      "state": "Kansas",
      "country": "US",
      "elevation": 3390,
      "lat": 37.4000015259,
      "lon": -101.766998291,
      "tz": "America\/Chicago"
  },
  "SN78": {
      "icao": "SN78",
      "iata": "",
      "name": "Albright Airport",
      "city": "Bucyrus",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 38.6981010437,
      "lon": -94.7188034058,
      "tz": "America\/Chicago"
  },
  "SN81": {
      "icao": "SN81",
      "iata": "",
      "name": "Hancock Airport",
      "city": "Piper",
      "state": "Kansas",
      "country": "US",
      "elevation": 940,
      "lat": 39.1819000244,
      "lon": -94.8700027466,
      "tz": "America\/Chicago"
  },
  "SN83": {
      "icao": "SN83",
      "iata": "",
      "name": "Highcrest Air Park",
      "city": "Lansing",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 39.2271995544,
      "lon": -94.9514007568,
      "tz": "America\/Chicago"
  },
  "SN84": {
      "icao": "SN84",
      "iata": "",
      "name": "Blaser's Airport",
      "city": "Leavenworth",
      "state": "Kansas",
      "country": "US",
      "elevation": 1000,
      "lat": 39.2708015442,
      "lon": -94.987701416,
      "tz": "America\/Chicago"
  },
  "SN85": {
      "icao": "SN85",
      "iata": "",
      "name": "Fox Fire Airport",
      "city": "Wellsville",
      "state": "Kansas",
      "country": "US",
      "elevation": 1058,
      "lat": 38.7336006165,
      "lon": -95.1022033691,
      "tz": "America\/Chicago"
  },
  "SN86": {
      "icao": "SN86",
      "iata": "",
      "name": "Arrow B Ranch Airport",
      "city": "Leoti",
      "state": "Kansas",
      "country": "US",
      "elevation": 3430,
      "lat": 38.7042007446,
      "lon": -101.4209976196,
      "tz": "America\/Chicago"
  },
  "SN87": {
      "icao": "SN87",
      "iata": "",
      "name": "Supreme Feeders Airport",
      "city": "Liberal",
      "state": "Kansas",
      "country": "US",
      "elevation": 2830,
      "lat": 37.2599983215,
      "lon": -100.9069976807,
      "tz": "America\/Chicago"
  },
  "SN88": {
      "icao": "SN88",
      "iata": "",
      "name": "Crosswind Airfield",
      "city": "Louisburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 38.5713996887,
      "lon": -94.7126998901,
      "tz": "America\/Chicago"
  },
  "SN89": {
      "icao": "SN89",
      "iata": "",
      "name": "Somerset Airport",
      "city": "Louisburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 38.5377998352,
      "lon": -94.7268981934,
      "tz": "America\/Chicago"
  },
  "SN90": {
      "icao": "SN90",
      "iata": "",
      "name": "D'Field Airport",
      "city": "Mc Louth",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 39.226398468,
      "lon": -95.1919021606,
      "tz": "America\/Chicago"
  },
  "SN91": {
      "icao": "SN91",
      "iata": "",
      "name": "Godfrey Airport",
      "city": "Madison",
      "state": "Kansas",
      "country": "US",
      "elevation": 1100,
      "lat": 38.1096992493,
      "lon": -96.1156005859,
      "tz": "America\/Chicago"
  },
  "SN92": {
      "icao": "SN92",
      "iata": "",
      "name": "Sunshine Ranch Airport",
      "city": "Manhattan",
      "state": "Kansas",
      "country": "US",
      "elevation": 1300,
      "lat": 39.3333015442,
      "lon": -96.700302124,
      "tz": "America\/Chicago"
  },
  "SN93": {
      "icao": "SN93",
      "iata": "",
      "name": "Dickson \/Private\/ Airport",
      "city": "Miller",
      "state": "Kansas",
      "country": "US",
      "elevation": 1140,
      "lat": 38.6458015442,
      "lon": -95.9891967773,
      "tz": "America\/Chicago"
  },
  "SN94": {
      "icao": "SN94",
      "iata": "",
      "name": "Amy Airport",
      "city": "Minneola",
      "state": "Kansas",
      "country": "US",
      "elevation": 2560,
      "lat": 37.4667015076,
      "lon": -100,
      "tz": "America\/Chicago"
  },
  "SN95": {
      "icao": "SN95",
      "iata": "",
      "name": "Roberts Air Field",
      "city": "Sylvia",
      "state": "Kansas",
      "country": "US",
      "elevation": 1765,
      "lat": 37.9775009155,
      "lon": -98.4041976929,
      "tz": "America\/Chicago"
  },
  "SN97": {
      "icao": "SN97",
      "iata": "",
      "name": "Brollier Airport",
      "city": "Moscow",
      "state": "Kansas",
      "country": "US",
      "elevation": 3056,
      "lat": 37.3264007568,
      "lon": -101.1979980469,
      "tz": "America\/Chicago"
  },
  "SN98": {
      "icao": "SN98",
      "iata": "",
      "name": "Anton Flying Uv Airport",
      "city": "Satanta",
      "state": "",
      "country": "US",
      "elevation": 2954,
      "lat": 37.4207992554,
      "lon": -100.9420013428,
      "tz": "America\/Chicago"
  },
  "SN99": {
      "icao": "SN99",
      "iata": "",
      "name": "Laflin Ranch Airport",
      "city": "Olsburg",
      "state": "Kansas",
      "country": "US",
      "elevation": 1190,
      "lat": 39.4000015259,
      "lon": -96.6169967651,
      "tz": "America\/Chicago"
  },
  "SNAB": {
      "icao": "SNAB",
      "iata": "",
      "name": "Araripina Airport",
      "city": "Araripina",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 2194,
      "lat": -7.5867600441,
      "lon": -40.5354995728,
      "tz": "America\/Recife"
  },
  "SNAC": {
      "icao": "SNAC",
      "iata": "",
      "name": "Fazenda Santa Rosa Airport",
      "city": "Xinguara",
      "state": "Para",
      "country": "BR",
      "elevation": 699,
      "lat": -7.036670208,
      "lon": -50.0889015198,
      "tz": "America\/Belem"
  },
  "SNAD": {
      "icao": "SNAD",
      "iata": "",
      "name": "Fazenda Aguia Branca Airport",
      "city": "Gurupi",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 410,
      "lat": -3.327778101,
      "lon": -46.7797203064,
      "tz": "America\/Fortaleza"
  },
  "SNAE": {
      "icao": "SNAE",
      "iata": "",
      "name": "Arcoverde Airport",
      "city": "Arcoverde",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 2080,
      "lat": -8.4078798294,
      "lon": -37.0881996155,
      "tz": "America\/Recife"
  },
  "SNAF": {
      "icao": "SNAF",
      "iata": "",
      "name": "Fazenda Agua Comprida Airport",
      "city": "Agua Comprida",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2211,
      "lat": -19.9469432831,
      "lon": -48.0336112976,
      "tz": "America\/Sao_Paulo"
  },
  "SNAG": {
      "icao": "SNAG",
      "iata": "",
      "name": "Araguari Airport",
      "city": "Araguari",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3107,
      "lat": -18.6683006287,
      "lon": -48.1904983521,
      "tz": "America\/Sao_Paulo"
  },
  "SNAH": {
      "icao": "SNAH",
      "iata": "",
      "name": "Adustina Airport",
      "city": "Adustina",
      "state": "Bahia",
      "country": "BR",
      "elevation": 892,
      "lat": -10.5809001923,
      "lon": -38.0887985229,
      "tz": "America\/Bahia"
  },
  "SNAI": {
      "icao": "SNAI",
      "iata": "APY",
      "name": "Alto Parnaiba Airport",
      "city": "Alto Parnaiba",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 968,
      "lat": -9.0836105347,
      "lon": -45.9505577087,
      "tz": "America\/Fortaleza"
  },
  "SNAJ": {
      "icao": "SNAJ",
      "iata": "",
      "name": "Fazenda Jaguare Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 436,
      "lat": -3.421667099,
      "lon": -48.3355560303,
      "tz": "America\/Belem"
  },
  "SNAL": {
      "icao": "SNAL",
      "iata": "APQ",
      "name": "Arapiraca Airport",
      "city": "Arapiraca",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 886,
      "lat": -9.7753601074,
      "lon": -36.6291999817,
      "tz": "America\/Maceio"
  },
  "SNAM": {
      "icao": "SNAM",
      "iata": "",
      "name": "Santo Antonio do Amparo Airport",
      "city": "Santo Antonio Do Amparo",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3601,
      "lat": -20.9134998322,
      "lon": -44.8928985596,
      "tz": "America\/Sao_Paulo"
  },
  "SNAP": {
      "icao": "SNAP",
      "iata": "",
      "name": "Janauba Airport",
      "city": "Janauba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1732,
      "lat": -15.732000351,
      "lon": -43.3231010437,
      "tz": "America\/Sao_Paulo"
  },
  "SNAR": {
      "icao": "SNAR",
      "iata": "AMJ",
      "name": "Cirilo Queiroz Airport",
      "city": "Almenara",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 640,
      "lat": -16.18389,
      "lon": -40.66722,
      "tz": "America\/Sao_Paulo"
  },
  "SNAS": {
      "icao": "SNAS",
      "iata": "",
      "name": "Tres Marias Airport",
      "city": "Tres Marias",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2579,
      "lat": -18.2224006653,
      "lon": -45.188999176,
      "tz": "America\/Sao_Paulo"
  },
  "SNAT": {
      "icao": "SNAT",
      "iata": "",
      "name": "Aracati Airport",
      "city": "Aracati",
      "state": "Ceara",
      "country": "BR",
      "elevation": 128,
      "lat": -4.5671401024,
      "lon": -37.7999992371,
      "tz": "America\/Fortaleza"
  },
  "SNAU": {
      "icao": "SNAU",
      "iata": "0",
      "name": "Aeroclube Airport",
      "city": "Aracaju",
      "state": "Sergipe",
      "country": "BR",
      "elevation": 23,
      "lat": -10.9033002853,
      "lon": -37.0830993652,
      "tz": "America\/Maceio"
  },
  "SNAV": {
      "icao": "SNAV",
      "iata": "",
      "name": "Agrovale Airport",
      "city": "Juazeiro",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1289,
      "lat": -9.50417,
      "lon": -40.36944,
      "tz": "America\/Bahia"
  },
  "SNAW": {
      "icao": "SNAW",
      "iata": "",
      "name": "Fazenda Boa Fe Airport",
      "city": "Conquista",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2461,
      "lat": -19.8769435883,
      "lon": -47.6797218323,
      "tz": "America\/Sao_Paulo"
  },
  "SNAY": {
      "icao": "SNAY",
      "iata": "",
      "name": "Fazenda Rio Lages Airport",
      "city": "Sao Felix Do Xingu",
      "state": "Para",
      "country": "BR",
      "elevation": 853,
      "lat": -6.2811107635,
      "lon": -51.7461128235,
      "tz": "America\/Belem"
  },
  "SNAZ": {
      "icao": "SNAZ",
      "iata": "",
      "name": "Amargosa Airport",
      "city": "Amargosa",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1394,
      "lat": -12.9935998917,
      "lon": -39.6399002075,
      "tz": "America\/Bahia"
  },
  "SNBB": {
      "icao": "SNBB",
      "iata": "",
      "name": "Fazenda Agronol Airport",
      "city": "Luis Eduardo Magalhaes",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2464,
      "lat": -11.947221756,
      "lon": -45.725276947,
      "tz": "America\/Bahia"
  },
  "SNBC": {
      "icao": "SNBC",
      "iata": "BDC",
      "name": "Barra do Corda Airport",
      "city": "Barra Do Corda",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 509,
      "lat": -5.5025,
      "lon": -45.215833,
      "tz": "America\/Fortaleza"
  },
  "SNBD": {
      "icao": "SNBD",
      "iata": "",
      "name": "Sobradinho Airport",
      "city": "Juazeiro",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1240,
      "lat": -9.4620103836,
      "lon": -40.8242988586,
      "tz": "America\/Bahia"
  },
  "SNBE": {
      "icao": "SNBE",
      "iata": "",
      "name": "Fazenda Botuvera Airport",
      "city": "Paranatinga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -14.4702777778,
      "lon": -53.6677777778,
      "tz": "America\/Cuiaba"
  },
  "SNBG": {
      "icao": "SNBG",
      "iata": "",
      "name": "Baixo Guandu - Aimores Airport",
      "city": "Baixo Guandu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 279,
      "lat": -19.4990005493,
      "lon": -41.0418014526,
      "tz": "America\/Sao_Paulo"
  },
  "SNBI": {
      "icao": "SNBI",
      "iata": "",
      "name": "Bacabal Airport",
      "city": "Bacabal",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 64,
      "lat": -4.2277097702,
      "lon": -44.8199996948,
      "tz": "America\/Fortaleza"
  },
  "SNBJ": {
      "icao": "SNBJ",
      "iata": "",
      "name": "Belo Jardim Airport",
      "city": "Belo Jardim",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 2100,
      "lat": -8.3452701569,
      "lon": -36.4412002563,
      "tz": "America\/Recife"
  },
  "SNBL": {
      "icao": "SNBL",
      "iata": "BVM",
      "name": "Belmonte Airport",
      "city": "Belmonte",
      "state": "Bahia",
      "country": "BR",
      "elevation": 33,
      "lat": -15.8717002869,
      "lon": -38.8718986511,
      "tz": "America\/Bahia"
  },
  "SNBM": {
      "icao": "SNBM",
      "iata": "",
      "name": "Cristiano Ferreira Varella Airport",
      "city": "Muriae",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 886,
      "lat": -21.1261005402,
      "lon": -42.3944015503,
      "tz": "America\/Sao_Paulo"
  },
  "SNBN": {
      "icao": "SNBN",
      "iata": "",
      "name": "Balbinot Airport",
      "city": "Portel",
      "state": "Para",
      "country": "BR",
      "elevation": 203,
      "lat": -2.8758330345,
      "lon": -50.4916687012,
      "tz": "America\/Belem"
  },
  "SNBO": {
      "icao": "SNBO",
      "iata": "0",
      "name": "Boquira Airport",
      "city": "Boquira",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1886,
      "lat": -12.790599823,
      "lon": -42.7289009094,
      "tz": "America\/Bahia"
  },
  "SNBR": {
      "icao": "SNBR",
      "iata": "BRA",
      "name": "Barreiras Airport",
      "city": "Barreiras",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2447,
      "lat": -12.0789003372,
      "lon": -45.0089988708,
      "tz": "America\/Bahia"
  },
  "SNBS": {
      "icao": "SNBS",
      "iata": "BSS",
      "name": "Balsas Airport",
      "city": "Balsas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 930,
      "lat": -7.5260300636,
      "lon": -46.0532989502,
      "tz": "America\/Fortaleza"
  },
  "SNBT": {
      "icao": "SNBT",
      "iata": "",
      "name": "Benedito Leite Airport",
      "city": "Benedito Leite",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 984,
      "lat": -7.2169442177,
      "lon": -44.5505561829,
      "tz": "America\/Fortaleza"
  },
  "SNBU": {
      "icao": "SNBU",
      "iata": "BMS",
      "name": "Socrates Mariani Bittencourt Airport",
      "city": "Brumado",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1673,
      "lat": -14.255399704,
      "lon": -41.8175010681,
      "tz": "America\/Bahia"
  },
  "SNBV": {
      "icao": "SNBV",
      "iata": "",
      "name": "Campo de Boi Airport",
      "city": "Ipixuna Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 186,
      "lat": -2.5233099461,
      "lon": -47.5173988342,
      "tz": "America\/Belem"
  },
  "SNBW": {
      "icao": "SNBW",
      "iata": "",
      "name": "Baiao Airport",
      "city": "Baiao",
      "state": "Para",
      "country": "BR",
      "elevation": 105,
      "lat": -2.8002779484,
      "lon": -49.6672210693,
      "tz": "America\/Belem"
  },
  "SNBX": {
      "icao": "SNBX",
      "iata": "BQQ",
      "name": "Barra Airport",
      "city": "Barra",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1345,
      "lat": -11.0808000565,
      "lon": -43.1474990845,
      "tz": "America\/Bahia"
  },
  "SNBY": {
      "icao": "SNBY",
      "iata": "",
      "name": "Bambui Airport",
      "city": "Bambui",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2523,
      "lat": -20.0363998413,
      "lon": -45.9723014832,
      "tz": "America\/Sao_Paulo"
  },
  "SNBZ": {
      "icao": "SNBZ",
      "iata": "",
      "name": "Paramirim Airport",
      "city": "Paramirim",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1788,
      "lat": -13.4461107254,
      "lon": -42.249168396,
      "tz": "America\/Bahia"
  },
  "SNCA": {
      "icao": "SNCA",
      "iata": "",
      "name": "Campo Belo Airport",
      "city": "Campo Belo",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3182,
      "lat": -20.8922996521,
      "lon": -45.3350982666,
      "tz": "America\/Sao_Paulo"
  },
  "SNCB": {
      "icao": "SNCB",
      "iata": "",
      "name": "Castanhal Airport",
      "city": "Prainha",
      "state": "Para",
      "country": "BR",
      "elevation": 502,
      "lat": -2.5236110687,
      "lon": -53.9397201538,
      "tz": "America\/Santarem"
  },
  "SNCC": {
      "icao": "SNCC",
      "iata": "",
      "name": "Calcoene Airport",
      "city": "Calcoene",
      "state": "Amapa",
      "country": "BR",
      "elevation": 59,
      "lat": 2.5147199631,
      "lon": -50.9522018433,
      "tz": "America\/Belem"
  },
  "SNCE": {
      "icao": "SNCE",
      "iata": "0",
      "name": "Campo do Meio Airport",
      "city": "Campo Do Meio",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2559,
      "lat": -21.1061000824,
      "lon": -45.8046989441,
      "tz": "America\/Sao_Paulo"
  },
  "SNCF": {
      "icao": "SNCF",
      "iata": "",
      "name": "Continental Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 485,
      "lat": -3.2952780724,
      "lon": -52.2591667175,
      "tz": "America\/Santarem"
  },
  "SNCG": {
      "icao": "SNCG",
      "iata": "",
      "name": "Campos Gerais Airport",
      "city": "Campos Gerais",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2838,
      "lat": -21.2261104584,
      "lon": -45.7791671753,
      "tz": "America\/Sao_Paulo"
  },
  "SNCI": {
      "icao": "SNCI",
      "iata": "",
      "name": "Cibrasa Airport",
      "city": "Capanema",
      "state": "Para",
      "country": "BR",
      "elevation": 174,
      "lat": -1.2309999466,
      "lon": -47.2005996704,
      "tz": "America\/Belem"
  },
  "SNCJ": {
      "icao": "SNCJ",
      "iata": "",
      "name": "Piquiatuba Airport",
      "city": "Santarem",
      "state": "Para",
      "country": "BR",
      "elevation": 407,
      "lat": -2.546667099,
      "lon": -54.7050018311,
      "tz": "America\/Santarem"
  },
  "SNCL": {
      "icao": "SNCL",
      "iata": "",
      "name": "Lorenzo Airport",
      "city": "Cairu",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3,
      "lat": -13.3894443512,
      "lon": -38.9099998474,
      "tz": "America\/Bahia"
  },
  "SNCP": {
      "icao": "SNCP",
      "iata": "CTP",
      "name": "Carutapera Airport",
      "city": "Carutapera",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 85,
      "lat": -1.2502779961,
      "lon": -46.0172233582,
      "tz": "America\/Fortaleza"
  },
  "SNCQ": {
      "icao": "SNCQ",
      "iata": "",
      "name": "Cocorobo Airport",
      "city": "Euclides Da Cunha",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1509,
      "lat": -10.5272216797,
      "lon": -39.0330543518,
      "tz": "America\/Bahia"
  },
  "SNCS": {
      "icao": "SNCS",
      "iata": "",
      "name": "Campos Sales Airport",
      "city": "Campos Sales",
      "state": "Ceara",
      "country": "BR",
      "elevation": 2001,
      "lat": -7.0530200005,
      "lon": -40.3585014343,
      "tz": "America\/Fortaleza"
  },
  "SNCT": {
      "icao": "SNCT",
      "iata": "",
      "name": "Ubaporanga Airport",
      "city": "Caratinga",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2067,
      "lat": -19.7252998352,
      "lon": -42.1120986938,
      "tz": "America\/Sao_Paulo"
  },
  "SNCU": {
      "icao": "SNCU",
      "iata": "CPU",
      "name": "Cururupu Airport",
      "city": "Cururupu",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 39,
      "lat": -1.821111,
      "lon": -44.866944,
      "tz": "America\/Fortaleza"
  },
  "SNCV": {
      "icao": "SNCV",
      "iata": "0",
      "name": "Campina Verde Airport",
      "city": "Campina Verde",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1805,
      "lat": -19.5380992889,
      "lon": -49.4949989319,
      "tz": "America\/Sao_Paulo"
  },
  "SNCW": {
      "icao": "SNCW",
      "iata": "",
      "name": "Centro de Lancamento de Alcantara Airport",
      "city": "Alcantara",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 148,
      "lat": -2.3729999065,
      "lon": -44.3964004517,
      "tz": "America\/Fortaleza"
  },
  "SNCX": {
      "icao": "SNCX",
      "iata": "QCH",
      "name": "Colatina Airport",
      "city": "Colatina",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 492,
      "lat": -19.4869995117,
      "lon": -40.5793991089,
      "tz": "America\/Sao_Paulo"
  },
  "SNCY": {
      "icao": "SNCY",
      "iata": "",
      "name": "Campo da Praia Airport",
      "city": "Coruripe",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 138,
      "lat": -10.2208328247,
      "lon": -36.2861099243,
      "tz": "America\/Maceio"
  },
  "SNCZ": {
      "icao": "SNCZ",
      "iata": "",
      "name": "Ponte Nova Airport",
      "city": "Ponte Nova",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1877,
      "lat": -20.4032993317,
      "lon": -42.9165000916,
      "tz": "America\/Sao_Paulo"
  },
  "SNDB": {
      "icao": "SNDB",
      "iata": "",
      "name": "Ruropolis Airport",
      "city": "Ruropolis",
      "state": "Para",
      "country": "BR",
      "elevation": 325,
      "lat": -4.0855560303,
      "lon": -54.9108314514,
      "tz": "America\/Santarem"
  },
  "SNDC": {
      "icao": "SNDC",
      "iata": "RDC",
      "name": "Redencao Airport",
      "city": "Redencao",
      "state": "Para",
      "country": "BR",
      "elevation": 670,
      "lat": -8.0332899094,
      "lon": -49.9799003601,
      "tz": "America\/Belem"
  },
  "SNDD": {
      "icao": "SNDD",
      "iata": "",
      "name": "Fazenda Eldorado Airport",
      "city": "Igarape Do Meio",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 118,
      "lat": -3.6922221184,
      "lon": -45.2700004578,
      "tz": "America\/Fortaleza"
  },
  "SNDF": {
      "icao": "SNDF",
      "iata": "",
      "name": "Fazenda Santa Lucia Airport",
      "city": "Santa Ines",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 272,
      "lat": -3.6549999714,
      "lon": -45.4511108398,
      "tz": "America\/Fortaleza"
  },
  "SNDH": {
      "icao": "SNDH",
      "iata": "",
      "name": "Aba Airport",
      "city": "Barreiras",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1682,
      "lat": -12.1602783203,
      "lon": -45.0224990845,
      "tz": "America\/Bahia"
  },
  "SNDI": {
      "icao": "SNDI",
      "iata": "",
      "name": "Pista Castanheirinho Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 623,
      "lat": -7.0997219086,
      "lon": -56.8522224426,
      "tz": "America\/Santarem"
  },
  "SNDJ": {
      "icao": "SNDJ",
      "iata": "",
      "name": "Santana dos Brejos Airport",
      "city": "Santana Dos Brejos",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1759,
      "lat": -12.976111412,
      "lon": -44.0391654968,
      "tz": "America\/Bahia"
  },
  "SNDN": {
      "icao": "SNDN",
      "iata": "LEP",
      "name": "Leopoldina Airport",
      "city": "Leopoldina",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 919,
      "lat": -21.4661006927,
      "lon": -42.7270011902,
      "tz": "America\/Sao_Paulo"
  },
  "SNDO": {
      "icao": "SNDO",
      "iata": "",
      "name": "Ouro Branco Airport",
      "city": "Paracatu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3066,
      "lat": -16.7163887024,
      "lon": -47.0177764893,
      "tz": "America\/Sao_Paulo"
  },
  "SNDP": {
      "icao": "SNDP",
      "iata": "",
      "name": "Palmares Airport",
      "city": "Acara",
      "state": "Para",
      "country": "BR",
      "elevation": 218,
      "lat": -2.2602779865,
      "lon": -48.5983314514,
      "tz": "America\/Belem"
  },
  "SNDQ": {
      "icao": "SNDQ",
      "iata": "",
      "name": "Fazenda Soya Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2549,
      "lat": -12.9211111069,
      "lon": -45.569442749,
      "tz": "America\/Bahia"
  },
  "SNDR": {
      "icao": "SNDR",
      "iata": "",
      "name": "Domingos Rego Airport",
      "city": "Timon",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 373,
      "lat": -5.0797901154,
      "lon": -42.8736991882,
      "tz": "America\/Fortaleza"
  },
  "SNDT": {
      "icao": "SNDT",
      "iata": "DTI",
      "name": "Diamantina Airport",
      "city": "Diamantina",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 4446,
      "lat": -18.232000351,
      "lon": -43.6503982544,
      "tz": "America\/Sao_Paulo"
  },
  "SNDU": {
      "icao": "SNDU",
      "iata": "",
      "name": "Ponta Do Sol Airport",
      "city": "Capitolio",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2854,
      "lat": -20.638333,
      "lon": -45.998056,
      "tz": "America\/Sao_Paulo"
  },
  "SNDV": {
      "icao": "SNDV",
      "iata": "DIQ",
      "name": "Divinopolis Airport",
      "city": "Divinopolis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2608,
      "lat": -20.1807003021,
      "lon": -44.8708992004,
      "tz": "America\/Sao_Paulo"
  },
  "SNDW": {
      "icao": "SNDW",
      "iata": "",
      "name": "Divisa Airport",
      "city": "Encruzilhada",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3051,
      "lat": -15.7172222137,
      "lon": -41.0172233582,
      "tz": "America\/Bahia"
  },
  "SNDX": {
      "icao": "SNDX",
      "iata": "",
      "name": "Cachoeira Airport",
      "city": "Coruripe",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 345,
      "lat": -10.0399999619,
      "lon": -36.3263893127,
      "tz": "America\/Maceio"
  },
  "SNEA": {
      "icao": "SNEA",
      "iata": "",
      "name": "Picarrao Airport",
      "city": "Estrela Do Sul",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3166,
      "lat": -18.7213897705,
      "lon": -47.8655548096,
      "tz": "America\/Sao_Paulo"
  },
  "SNEB": {
      "icao": "SNEB",
      "iata": "",
      "name": "Paragominas Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 508,
      "lat": -3.0252780914,
      "lon": -47.3169441223,
      "tz": "America\/Belem"
  },
  "SNEC": {
      "icao": "SNEC",
      "iata": "",
      "name": "Outeiros da Brisas Airport",
      "city": "Porto Seguro",
      "state": "Bahia",
      "country": "BR",
      "elevation": 130,
      "lat": -16.7130565643,
      "lon": -39.1430549622,
      "tz": "America\/Bahia"
  },
  "SNED": {
      "icao": "SNED",
      "iata": "CNV",
      "name": "Canavieiras Airport",
      "city": "Canavieiras",
      "state": "Bahia",
      "country": "BR",
      "elevation": 19,
      "lat": -15.6669998169,
      "lon": -38.95470047,
      "tz": "America\/Bahia"
  },
  "SNEE": {
      "icao": "SNEE",
      "iata": "",
      "name": "Jaua Airport",
      "city": "Camacari",
      "state": "Bahia",
      "country": "BR",
      "elevation": 76,
      "lat": -12.8058586121,
      "lon": -38.2437515259,
      "tz": "America\/Bahia"
  },
  "SNEH": {
      "icao": "SNEH",
      "iata": "",
      "name": "Mineracao Porquinho Airport",
      "city": "Itaituba",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 298,
      "lat": -5.167222023,
      "lon": -57.625831604,
      "tz": "America\/Manaus"
  },
  "SNEJ": {
      "icao": "SNEJ",
      "iata": "",
      "name": "Monte Verde Airport",
      "city": "Camanducaia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 5102,
      "lat": -22.8597221375,
      "lon": -46.0374984741,
      "tz": "America\/Sao_Paulo"
  },
  "SNEK": {
      "icao": "SNEK",
      "iata": "",
      "name": "Moria Airport",
      "city": "Dom Bosco",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1201,
      "lat": -16.8838882446,
      "lon": -46.2363891602,
      "tz": "America\/Sao_Paulo"
  },
  "SNEL": {
      "icao": "SNEL",
      "iata": "",
      "name": "Belterra Airport",
      "city": "Belterra",
      "state": "Para",
      "country": "BR",
      "elevation": 541,
      "lat": -2.6669440269,
      "lon": -54.900554657,
      "tz": "America\/Santarem"
  },
  "SNEM": {
      "icao": "SNEM",
      "iata": "0",
      "name": "Encanta Moca Airport",
      "city": "Recife",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 10,
      "lat": -8.0941696167,
      "lon": -34.8927993774,
      "tz": "America\/Recife"
  },
  "SNEN": {
      "icao": "SNEN",
      "iata": "",
      "name": "Fazenda Calumbi Airport",
      "city": "Fortuna",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 689,
      "lat": -5.8133330345,
      "lon": -44.1377792358,
      "tz": "America\/Fortaleza"
  },
  "SNEO": {
      "icao": "SNEO",
      "iata": "",
      "name": "Dezoito Airport",
      "city": "Neropolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 2700,
      "lat": -16.441110611,
      "lon": -49.19972229,
      "tz": "America\/Sao_Paulo"
  },
  "SNES": {
      "icao": "SNES",
      "iata": "",
      "name": "Esplanada Airport",
      "city": "Esplanada",
      "state": "Bahia",
      "country": "BR",
      "elevation": 164,
      "lat": -11.7505559921,
      "lon": -37.9502792358,
      "tz": "America\/Bahia"
  },
  "SNEU": {
      "icao": "SNEU",
      "iata": "",
      "name": "Euclides da Cunha Airport",
      "city": "Euclides Da Cunha",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1499,
      "lat": -10.527299881,
      "lon": -39.032699585,
      "tz": "America\/Bahia"
  },
  "SNEW": {
      "icao": "SNEW",
      "iata": "",
      "name": "Fazenda Congonhas Airport",
      "city": "Carneirinho",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1375,
      "lat": -19.6625995636,
      "lon": -50.9513015747,
      "tz": "America\/Sao_Paulo"
  },
  "SNEY": {
      "icao": "SNEY",
      "iata": "",
      "name": "Fazenda Lagoa do Morro Airport",
      "city": "Brejoes",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2666,
      "lat": -13.1024999619,
      "lon": -39.9036102295,
      "tz": "America\/Bahia"
  },
  "SNEZ": {
      "icao": "SNEZ",
      "iata": "",
      "name": "Fazenda Palmeira do Capim Airport",
      "city": "Novo Brasil",
      "state": "Goias",
      "country": "BR",
      "elevation": 1220,
      "lat": -15.959444046,
      "lon": -50.7138900757,
      "tz": "America\/Sao_Paulo"
  },
  "SNFA": {
      "icao": "SNFA",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1795,
      "lat": -16.7161998749,
      "lon": -46.5211982727,
      "tz": "America\/Sao_Paulo"
  },
  "SNFB": {
      "icao": "SNFB",
      "iata": "",
      "name": "Fazenda Minas Gerais Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2395,
      "lat": -14.777222,
      "lon": -59.046944,
      "tz": "America\/Cuiaba"
  },
  "SNFD": {
      "icao": "SNFD",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Buritizeiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1739,
      "lat": -17.0802783966,
      "lon": -45.4472236633,
      "tz": "America\/Sao_Paulo"
  },
  "SNFE": {
      "icao": "SNFE",
      "iata": "",
      "name": "Alfenas Airport",
      "city": "Alfenas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2871,
      "lat": -21.4314002991,
      "lon": -45.9320983887,
      "tz": "America\/Sao_Paulo"
  },
  "SNFF": {
      "icao": "SNFF",
      "iata": "",
      "name": "Feijo Airport",
      "city": "Fortaleza",
      "state": "Ceara",
      "country": "BR",
      "elevation": 82,
      "lat": -3.8094439507,
      "lon": -38.6169433594,
      "tz": "America\/Fortaleza"
  },
  "SNFG": {
      "icao": "SNFG",
      "iata": "",
      "name": "Fazenda Citropar Airport",
      "city": "Capitao Poco",
      "state": "Para",
      "country": "BR",
      "elevation": 220,
      "lat": -1.8416670561,
      "lon": -47.1841659546,
      "tz": "America\/Belem"
  },
  "SNFH": {
      "icao": "SNFH",
      "iata": "",
      "name": "Fazenda Dom Felipe Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1857,
      "lat": -22.55917,
      "lon": -55.37583,
      "tz": "America\/Campo_Grande"
  },
  "SNFI": {
      "icao": "SNFI",
      "iata": "",
      "name": "Fazenda Sao Jose do Parnaiba Airport",
      "city": "Tupaciguara",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1676,
      "lat": -18.3875007629,
      "lon": -48.8616676331,
      "tz": "America\/Sao_Paulo"
  },
  "SNFJ": {
      "icao": "SNFJ",
      "iata": "",
      "name": "Pousada Thaimacu Airport",
      "city": "Jacareacanga",
      "state": "Para",
      "country": "BR",
      "elevation": 751,
      "lat": -9.0627784729,
      "lon": -56.593334198,
      "tz": "America\/Santarem"
  },
  "SNFK": {
      "icao": "SNFK",
      "iata": "",
      "name": "Francisco Sa Airport",
      "city": "Francisco Sa",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2484,
      "lat": -16.4383335114,
      "lon": -43.4694442749,
      "tz": "America\/Sao_Paulo"
  },
  "SNFL": {
      "icao": "SNFL",
      "iata": "",
      "name": "Garimbo Tocantinzinho Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 590,
      "lat": -6.0505561829,
      "lon": -56.3022232056,
      "tz": "America\/Santarem"
  },
  "SNFN": {
      "icao": "SNFN",
      "iata": "",
      "name": "Fazenda Santa Fe Airport",
      "city": "Jardinopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1739,
      "lat": -20.94,
      "lon": -47.87,
      "tz": "America\/Sao_Paulo"
  },
  "SNFO": {
      "icao": "SNFO",
      "iata": "0",
      "name": "Formiga Airport",
      "city": "Formiga",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3337,
      "lat": -20.3952999115,
      "lon": -45.4836006165,
      "tz": "America\/Sao_Paulo"
  },
  "SNFP": {
      "icao": "SNFP",
      "iata": "",
      "name": "Fazenda Pirapo Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1273,
      "lat": -12.181388855,
      "lon": -55.7886123657,
      "tz": "America\/Cuiaba"
  },
  "SNFQ": {
      "icao": "SNFQ",
      "iata": "",
      "name": "Fazenda Paineira Airport",
      "city": "Sao Sebastiao Da Bela Vista",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3001,
      "lat": -22.1494445801,
      "lon": -45.7625007629,
      "tz": "America\/Sao_Paulo"
  },
  "SNFR": {
      "icao": "SNFR",
      "iata": "",
      "name": "Belem de Sao Francisco Airport",
      "city": "Belem De Sao Francisco",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1034,
      "lat": -8.7695903778,
      "lon": -38.9496002197,
      "tz": "America\/Recife"
  },
  "SNFS": {
      "icao": "SNFS",
      "iata": "",
      "name": "Fazenda Mimoso S\/A Airport",
      "city": "Barreiras",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2536,
      "lat": -11.871389389,
      "lon": -45.7372207642,
      "tz": "America\/Bahia"
  },
  "SNFT": {
      "icao": "SNFT",
      "iata": "",
      "name": "Fazenda Serinhaem Airport",
      "city": "Itubera",
      "state": "Bahia",
      "country": "BR",
      "elevation": 9,
      "lat": -13.8366670609,
      "lon": -38.9936103821,
      "tz": "America\/Bahia"
  },
  "SNFU": {
      "icao": "SNFU",
      "iata": "",
      "name": "Frutal Airport",
      "city": "Frutal",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1808,
      "lat": -20.0027999878,
      "lon": -48.9584007263,
      "tz": "America\/Sao_Paulo"
  },
  "SNFV": {
      "icao": "SNFV",
      "iata": "",
      "name": "Lago Joanes Airport",
      "city": "Simoes Filho",
      "state": "Bahia",
      "country": "BR",
      "elevation": 239,
      "lat": -12.6666669846,
      "lon": -38.3958320618,
      "tz": "America\/Bahia"
  },
  "SNFW": {
      "icao": "SNFW",
      "iata": "",
      "name": "Sao Joao do Piaui Airport",
      "city": "Sao Joao Do Piaui",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1004,
      "lat": -8.391111,
      "lon": -42.250556,
      "tz": "America\/Fortaleza"
  },
  "SNFX": {
      "icao": "SNFX",
      "iata": "SXX",
      "name": "Sao Felix do Xingu Airport",
      "city": "Sao Felix Do Xingu",
      "state": "Para",
      "country": "BR",
      "elevation": 656,
      "lat": -6.6402778625,
      "lon": -51.9900016785,
      "tz": "America\/Belem"
  },
  "SNFZ": {
      "icao": "SNFZ",
      "iata": "",
      "name": "Fazenda Villa Terezinha Airport",
      "city": "Bocaiuva",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2765,
      "lat": -17.405500412,
      "lon": -43.9403991699,
      "tz": "America\/Sao_Paulo"
  },
  "SNGA": {
      "icao": "SNGA",
      "iata": "GUZ",
      "name": "Guarapari Airport",
      "city": "Guarapari",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 28,
      "lat": -20.6464996338,
      "lon": -40.4919013977,
      "tz": "America\/Sao_Paulo"
  },
  "SNGB": {
      "icao": "SNGB",
      "iata": "",
      "name": "Gilbues Airport",
      "city": "Gilbues",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1476,
      "lat": -9.8336105347,
      "lon": -45.3672218323,
      "tz": "America\/Fortaleza"
  },
  "SNGC": {
      "icao": "SNGC",
      "iata": "",
      "name": "Pista Canaa Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 741,
      "lat": -7.193611145,
      "lon": -57.0488891602,
      "tz": "America\/Santarem"
  },
  "SNGD": {
      "icao": "SNGD",
      "iata": "GDP",
      "name": "Guadalupe Airport",
      "city": "Guadalupe",
      "state": "Piaui",
      "country": "BR",
      "elevation": 564,
      "lat": -6.7822198868,
      "lon": -43.5821990967,
      "tz": "America\/Fortaleza"
  },
  "SNGG": {
      "icao": "SNGG",
      "iata": "",
      "name": "Bom Jesus do Gurgueia Airport",
      "city": "Bom Jesus Do Gurgueia",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1066,
      "lat": -9.0572500229,
      "lon": -44.3711013794,
      "tz": "America\/Fortaleza"
  },
  "SNGH": {
      "icao": "SNGH",
      "iata": "",
      "name": "Guanhaes Airport",
      "city": "Guanhaes",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2618,
      "lat": -18.7077007294,
      "lon": -42.8389015198,
      "tz": "America\/Sao_Paulo"
  },
  "SNGI": {
      "icao": "SNGI",
      "iata": "GNM",
      "name": "Guanambi Airport",
      "city": "Guanambi",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1815,
      "lat": -14.2082004547,
      "lon": -42.7461013794,
      "tz": "America\/Bahia"
  },
  "SNGJ": {
      "icao": "SNGJ",
      "iata": "0",
      "name": "Grajau Airport",
      "city": "Grajau",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 773,
      "lat": -5.8002800941,
      "lon": -46.1338996887,
      "tz": "America\/Fortaleza"
  },
  "SNGK": {
      "icao": "SNGK",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Monte Alegre De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1984,
      "lat": -19.0483322144,
      "lon": -48.9722213745,
      "tz": "America\/Sao_Paulo"
  },
  "SNGM": {
      "icao": "SNGM",
      "iata": "GMS",
      "name": "Fazenda Canada Airport",
      "city": "Uberlandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2624,
      "lat": -18.8624992371,
      "lon": -48.4152793884,
      "tz": "America\/Sao_Paulo"
  },
  "SNGN": {
      "icao": "SNGN",
      "iata": "QGP",
      "name": "Garanhuns Airport",
      "city": "Garanhuns",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 2533,
      "lat": -8.834280014,
      "lon": -36.4715995789,
      "tz": "America\/Recife"
  },
  "SNGO": {
      "icao": "SNGO",
      "iata": "",
      "name": "Fazenda Nova Airport",
      "city": "Uberlandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2689,
      "lat": -19.0477771759,
      "lon": -48.5822219849,
      "tz": "America\/Sao_Paulo"
  },
  "SNGP": {
      "icao": "SNGP",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Tupaciguara",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2624,
      "lat": -18.5869445801,
      "lon": -48.8691673279,
      "tz": "America\/Sao_Paulo"
  },
  "SNGQ": {
      "icao": "SNGQ",
      "iata": "",
      "name": "Fazenda Estrela Dalva Airport",
      "city": "Rondon Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 446,
      "lat": -4.2508330345,
      "lon": -48.7680549622,
      "tz": "America\/Belem"
  },
  "SNGR": {
      "icao": "SNGR",
      "iata": "",
      "name": "Gorotire Airport",
      "city": "Gorotire",
      "state": "Para",
      "country": "BR",
      "elevation": 656,
      "lat": -7.7836108208,
      "lon": -51.1338882446,
      "tz": "America\/Belem"
  },
  "SNGS": {
      "icao": "SNGS",
      "iata": "",
      "name": "Aeroclube de Alagoas Airport",
      "city": "Maceio",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 285,
      "lat": -9.5874996185,
      "lon": -35.7577781677,
      "tz": "America\/Maceio"
  },
  "SNGT": {
      "icao": "SNGT",
      "iata": "",
      "name": "Gentio de Ouro Airport",
      "city": "Gentio De Ouro",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3501,
      "lat": -11.4416999817,
      "lon": -42.5181999207,
      "tz": "America\/Bahia"
  },
  "SNGU": {
      "icao": "SNGU",
      "iata": "",
      "name": "Gurupa Airport",
      "city": "Gurupa",
      "state": "Para",
      "country": "BR",
      "elevation": 66,
      "lat": -1.4169440269,
      "lon": -51.6338882446,
      "tz": "America\/Belem"
  },
  "SNGW": {
      "icao": "SNGW",
      "iata": "",
      "name": "Fazenda Cauaxi Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 754,
      "lat": -3.7633330822,
      "lon": -48.1738891602,
      "tz": "America\/Belem"
  },
  "SNGX": {
      "icao": "SNGX",
      "iata": "",
      "name": "Guaxupe Airport",
      "city": "Guaxupe",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2786,
      "lat": -21.3264007568,
      "lon": -46.7312011719,
      "tz": "America\/Sao_Paulo"
  },
  "SNHD": {
      "icao": "SNHD",
      "iata": "",
      "name": "Fazenda Floresta do Lobo Airport",
      "city": "Uberlandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3159,
      "lat": -19.0775,
      "lon": -48.130278,
      "tz": "America\/Sao_Paulo"
  },
  "SNHE": {
      "icao": "SNHE",
      "iata": "",
      "name": "Fazenda Inhumas do Chapadao Airport",
      "city": "Uberaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2792,
      "lat": -19.3022212982,
      "lon": -48.2041664124,
      "tz": "America\/Sao_Paulo"
  },
  "SNHG": {
      "icao": "SNHG",
      "iata": "",
      "name": "Fazenda Varjadao Airport",
      "city": "Sandolandia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 794,
      "lat": -12.341389,
      "lon": -49.621111,
      "tz": "America\/Araguaina"
  },
  "SNHJ": {
      "icao": "SNHJ",
      "iata": "",
      "name": "Usina de Jose Bonifacio Airport",
      "city": "Jose Bonifacio",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1404,
      "lat": -21.095,
      "lon": -49.914722,
      "tz": "America\/Sao_Paulo"
  },
  "SNHK": {
      "icao": "SNHK",
      "iata": "",
      "name": "Nacional Grafite I Airport",
      "city": "Itapecerica",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3051,
      "lat": -20.4344444275,
      "lon": -45.1383323669,
      "tz": "America\/Sao_Paulo"
  },
  "SNHL": {
      "icao": "SNHL",
      "iata": "",
      "name": "Lagoa da Taboca Airport",
      "city": "Joao Pinheiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1969,
      "lat": -17.1933326721,
      "lon": -45.6655578613,
      "tz": "America\/Sao_Paulo"
  },
  "SNHO": {
      "icao": "SNHO",
      "iata": "",
      "name": "Nacional de Grafite II Airport",
      "city": "Pedra Azul",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1968,
      "lat": -15.8361110687,
      "lon": -41.0763893127,
      "tz": "America\/Sao_Paulo"
  },
  "SNHP": {
      "icao": "SNHP",
      "iata": "",
      "name": "Herbert Mata Pires Airport",
      "city": "Itaberaba",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1188,
      "lat": -12.402463913,
      "lon": -40.4746551514,
      "tz": "America\/Bahia"
  },
  "SNHQ": {
      "icao": "SNHQ",
      "iata": "",
      "name": "Nacional de Grafite III Airport",
      "city": "Salto Da Divisa",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 984,
      "lat": -16.1811103821,
      "lon": -39.9319458008,
      "tz": "America\/Bahia"
  },
  "SNHR": {
      "icao": "SNHR",
      "iata": "",
      "name": "Fazenda Agua Santa Airport",
      "city": "Perdizes",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3548,
      "lat": -19.3630561829,
      "lon": -47.3594436646,
      "tz": "America\/Sao_Paulo"
  },
  "SNHS": {
      "icao": "SNHS",
      "iata": "",
      "name": "Santa Magalhaes Airport",
      "city": "Serra Talhada",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1571,
      "lat": -8.0623998642,
      "lon": -38.3288002014,
      "tz": "America\/Recife"
  },
  "SNHU": {
      "icao": "SNHU",
      "iata": "",
      "name": "FazendaBola Sete Airport",
      "city": "Itinga Do Maranhao",
      "state": "Para",
      "country": "BR",
      "elevation": 699,
      "lat": -4.57,
      "lon": -47.47,
      "tz": "America\/Fortaleza"
  },
  "SNHW": {
      "icao": "SNHW",
      "iata": "",
      "name": "Fazenda Agro-Marata Airport",
      "city": "Santa Luzia",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1001,
      "lat": -4.3222222328,
      "lon": -46.2283325195,
      "tz": "America\/Fortaleza"
  },
  "SNHX": {
      "icao": "SNHX",
      "iata": "",
      "name": "Fazenda Samello Airport",
      "city": "Paracatu",
      "state": "Goias",
      "country": "BR",
      "elevation": 2591,
      "lat": -17.3624992371,
      "lon": -47.4408340454,
      "tz": "America\/Sao_Paulo"
  },
  "SNIA": {
      "icao": "SNIA",
      "iata": "",
      "name": "Igarape-Acu Airport",
      "city": "Igarape-Acu",
      "state": "Para",
      "country": "BR",
      "elevation": 180,
      "lat": -1.1272219419,
      "lon": -47.5991668701,
      "tz": "America\/Belem"
  },
  "SNIB": {
      "icao": "SNIB",
      "iata": "",
      "name": "Itaberaba Airport",
      "city": "Itaberaba",
      "state": "Bahia",
      "country": "BR",
      "elevation": 929,
      "lat": -12.5,
      "lon": -40.2699012756,
      "tz": "America\/Bahia"
  },
  "SNIC": {
      "icao": "SNIC",
      "iata": "IRE",
      "name": "Irece Airport",
      "city": "Irece",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2561,
      "lat": -11.3399000168,
      "lon": -41.8470001221,
      "tz": "America\/Bahia"
  },
  "SNIE": {
      "icao": "SNIE",
      "iata": "",
      "name": "Caetite Airport",
      "city": "Caetite",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2854,
      "lat": -14.0121002197,
      "lon": -42.4945983887,
      "tz": "America\/Bahia"
  },
  "SNIF": {
      "icao": "SNIF",
      "iata": "",
      "name": "Fazenda Serra Dourada Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 443,
      "lat": -5.7172222137,
      "lon": -56.4361114502,
      "tz": "America\/Santarem"
  },
  "SNIG": {
      "icao": "SNIG",
      "iata": "QIG",
      "name": "Iguatu Airport",
      "city": "Iguatu",
      "state": "Ceara",
      "country": "BR",
      "elevation": 699,
      "lat": -6.34664011,
      "lon": -39.293800354,
      "tz": "America\/Fortaleza"
  },
  "SNII": {
      "icao": "SNII",
      "iata": "",
      "name": "Ouroland Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 699,
      "lat": -6.8169441223,
      "lon": -56.5505561829,
      "tz": "America\/Santarem"
  },
  "SNIK": {
      "icao": "SNIK",
      "iata": "",
      "name": "Itamarandiba Airport",
      "city": "Itamarandiba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3196,
      "lat": -17.8505554199,
      "lon": -42.8505554199,
      "tz": "America\/Sao_Paulo"
  },
  "SNIN": {
      "icao": "SNIN",
      "iata": "",
      "name": "Prainha Airport",
      "city": "Prainha",
      "state": "Para",
      "country": "BR",
      "elevation": 279,
      "lat": -1.8002779484,
      "lon": -53.4838905334,
      "tz": "America\/Santarem"
  },
  "SNIO": {
      "icao": "SNIO",
      "iata": "",
      "name": "Cipo Airport",
      "city": "Cipo",
      "state": "Bahia",
      "country": "BR",
      "elevation": 499,
      "lat": -11.1172218323,
      "lon": -38.5169448853,
      "tz": "America\/Bahia"
  },
  "SNIP": {
      "icao": "SNIP",
      "iata": "QIT",
      "name": "Itapetinga Airport",
      "city": "Itapetinga",
      "state": "Bahia",
      "country": "BR",
      "elevation": 915,
      "lat": -15.2445001602,
      "lon": -40.2771987915,
      "tz": "America\/Bahia"
  },
  "SNIR": {
      "icao": "SNIR",
      "iata": "",
      "name": "Fazenda Mata Escura Airport",
      "city": "Parnarama",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 712,
      "lat": -5.7427778244,
      "lon": -43.5988883972,
      "tz": "America\/Fortaleza"
  },
  "SNIS": {
      "icao": "SNIS",
      "iata": "",
      "name": "Fazenda Rio Largo Airport",
      "city": "Caxias",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 459,
      "lat": -5.1969442368,
      "lon": -43.5977783203,
      "tz": "America\/Fortaleza"
  },
  "SNIT": {
      "icao": "SNIT",
      "iata": "",
      "name": "Ibotirama Airport",
      "city": "Ibotirama",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1398,
      "lat": -12.1686000824,
      "lon": -43.2220993042,
      "tz": "America\/Bahia"
  },
  "SNIU": {
      "icao": "SNIU",
      "iata": "IPU",
      "name": "Ipiau Airport",
      "city": "Ipiau",
      "state": "Bahia",
      "country": "BR",
      "elevation": 492,
      "lat": -14.1338891983,
      "lon": -39.7338905334,
      "tz": "America\/Bahia"
  },
  "SNIV": {
      "icao": "SNIV",
      "iata": "",
      "name": "Fazenda Jumari Airport",
      "city": "Ipiacu",
      "state": "Goias",
      "country": "BR",
      "elevation": 1424,
      "lat": -18.646389,
      "lon": -49.8825,
      "tz": "America\/Sao_Paulo"
  },
  "SNIX": {
      "icao": "SNIX",
      "iata": "",
      "name": "Fazenda Masutti Airport",
      "city": "Campos De Julio",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1617,
      "lat": -13.553333,
      "lon": -59.100278,
      "tz": "America\/Cuiaba"
  },
  "SNIY": {
      "icao": "SNIY",
      "iata": "",
      "name": "Ibimirim Airport",
      "city": "Ibimirim",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1329,
      "lat": -8.5002784729,
      "lon": -37.6669425964,
      "tz": "America\/Recife"
  },
  "SNIZ": {
      "icao": "SNIZ",
      "iata": "",
      "name": "Fazenda Jaqueline Airport",
      "city": "Vilhena",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1772,
      "lat": -12.8825,
      "lon": -60.119444,
      "tz": "America\/Porto_Velho"
  },
  "SNJA": {
      "icao": "SNJA",
      "iata": "",
      "name": "Jardim de Angicos Airport",
      "city": "Jardim De Angicos",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 492,
      "lat": -5.6386108398,
      "lon": -35.9566688538,
      "tz": "America\/Fortaleza"
  },
  "SNJB": {
      "icao": "SNJB",
      "iata": "JCM",
      "name": "Jacobina Airport",
      "city": "Jacobina",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1640,
      "lat": -11.1632003784,
      "lon": -40.5531005859,
      "tz": "America\/Bahia"
  },
  "SNJC": {
      "icao": "SNJC",
      "iata": "",
      "name": "Fazenda Agua Boa Airport",
      "city": "Ribamar Fiquene",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 495,
      "lat": -5.9611110687,
      "lon": -47.3922233582,
      "tz": "America\/Fortaleza"
  },
  "SNJD": {
      "icao": "SNJD",
      "iata": "FEC",
      "name": "Joao Durval Carneiro Airport",
      "city": "Feira De Santana",
      "state": "Bahia",
      "country": "BR",
      "elevation": 768,
      "lat": -12.2003002167,
      "lon": -38.9067993164,
      "tz": "America\/Bahia"
  },
  "SNJE": {
      "icao": "SNJE",
      "iata": "",
      "name": "Fazenda Sertao do Formoso Airport",
      "city": "Jaborandi",
      "state": "Goias",
      "country": "BR",
      "elevation": 3028,
      "lat": -14.7936105728,
      "lon": -46.0030555725,
      "tz": "America\/Bahia"
  },
  "SNJG": {
      "icao": "SNJG",
      "iata": "",
      "name": "Fazenda Sao Jose da Lagoa Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1286,
      "lat": -14.5766666667,
      "lon": -50.7055555556,
      "tz": "America\/Sao_Paulo"
  },
  "SNJH": {
      "icao": "SNJH",
      "iata": "",
      "name": "Sao Jose do Jacuipe Airport",
      "city": "Sao Jose Do Jacuipe",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1391,
      "lat": -11.4527778625,
      "lon": -40.0422210693,
      "tz": "America\/Bahia"
  },
  "SNJI": {
      "icao": "SNJI",
      "iata": "",
      "name": "Fazenda Fortaleza de Santa Terezinha Airport",
      "city": "Jequitai",
      "state": "",
      "country": "BR",
      "elevation": 2077,
      "lat": -17.1868000031,
      "lon": -44.6495018005,
      "tz": "America\/Sao_Paulo"
  },
  "SNJJ": {
      "icao": "SNJJ",
      "iata": "",
      "name": "Fazenda Vo Anizio Airport",
      "city": "Dois Irmaos Do Buriti",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 837,
      "lat": -20.715278,
      "lon": -55.379722,
      "tz": "America\/Campo_Grande"
  },
  "SNJK": {
      "icao": "SNJK",
      "iata": "JEQ",
      "name": "Jequie Airport",
      "city": "Jequie",
      "state": "Bahia",
      "country": "BR",
      "elevation": 646,
      "lat": -13.877699852,
      "lon": -40.0716018677,
      "tz": "America\/Bahia"
  },
  "SNJL": {
      "icao": "SNJL",
      "iata": "",
      "name": "Fazenda do Rochedo Airport",
      "city": "Juiz De Fora",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2300,
      "lat": -21.8455562592,
      "lon": -43.1875,
      "tz": "America\/Sao_Paulo"
  },
  "SNJM": {
      "icao": "SNJM",
      "iata": "",
      "name": "Aeroporto Elias Breder Airport",
      "city": "Manhuacu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2723,
      "lat": -20.259721756,
      "lon": -42.1838874817,
      "tz": "America\/Sao_Paulo"
  },
  "SNJN": {
      "icao": "SNJN",
      "iata": "JNA",
      "name": "Januaria Airport",
      "city": "Januaria",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1575,
      "lat": -15.4737997055,
      "lon": -44.3855018616,
      "tz": "America\/Sao_Paulo"
  },
  "SNJO": {
      "icao": "SNJO",
      "iata": "",
      "name": "Aeroclube Airport",
      "city": "Joao Pessoa",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 16,
      "lat": -7.091989994,
      "lon": -34.8415985107,
      "tz": "America\/Fortaleza"
  },
  "SNJP": {
      "icao": "SNJP",
      "iata": "",
      "name": "Joao Pinheiro Airport",
      "city": "Joao Pinheiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2990,
      "lat": -17.7875003815,
      "lon": -46.1199989319,
      "tz": "America\/Sao_Paulo"
  },
  "SNJQ": {
      "icao": "SNJQ",
      "iata": "",
      "name": "Jequitinhonha Airport",
      "city": "Jequitinhonha",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 853,
      "lat": -16.4419002533,
      "lon": -41.0368995667,
      "tz": "America\/Sao_Paulo"
  },
  "SNJR": {
      "icao": "SNJR",
      "iata": "JDR",
      "name": "Prefeito Octavio de Almeida Neves Airport",
      "city": "Sao Joao Del Rei",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3117,
      "lat": -21.0849990845,
      "lon": -44.2247238159,
      "tz": "America\/Sao_Paulo"
  },
  "SNJS": {
      "icao": "SNJS",
      "iata": "",
      "name": "Jardim do Serido Airport",
      "city": "Jardim Do Serido",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 837,
      "lat": -6.5411109924,
      "lon": -36.7316665649,
      "tz": "America\/Fortaleza"
  },
  "SNJT": {
      "icao": "SNJT",
      "iata": "",
      "name": "Fazenda Caraibas Airport",
      "city": "Janauba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1811,
      "lat": -15.6541671753,
      "lon": -43.4016685486,
      "tz": "America\/Sao_Paulo"
  },
  "SNJV": {
      "icao": "SNJV",
      "iata": "",
      "name": "Fazenda Santa Monica Airport",
      "city": "Sao Joao Da Ponte",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2077,
      "lat": -16.002737,
      "lon": -43.746648,
      "tz": "America\/Sao_Paulo"
  },
  "SNJW": {
      "icao": "SNJW",
      "iata": "",
      "name": "Jaragua Airport",
      "city": "Sacramento",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1909,
      "lat": -20.0403003693,
      "lon": -47.4215011597,
      "tz": "America\/Sao_Paulo"
  },
  "SNJZ": {
      "icao": "SNJZ",
      "iata": "",
      "name": "Fazenda Santa Izabel do Porto Seguro da Jau Airport",
      "city": "Sao Felix Do Xingu",
      "state": "Para",
      "country": "BR",
      "elevation": 804,
      "lat": -7.0763888359,
      "lon": -52.9347229004,
      "tz": "America\/Santarem"
  },
  "SNKB": {
      "icao": "SNKB",
      "iata": "",
      "name": "Aeroclube Airport",
      "city": "Campina Grande",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 1926,
      "lat": -7.1783328056,
      "lon": -35.9902763367,
      "tz": "America\/Fortaleza"
  },
  "SNKC": {
      "icao": "SNKC",
      "iata": "",
      "name": "Cocos Airport",
      "city": "Cocos",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2018,
      "lat": -14.17277778,
      "lon": -44.54666667,
      "tz": "America\/Bahia"
  },
  "SNKD": {
      "icao": "SNKD",
      "iata": "",
      "name": "Conceicao do Mato Dentro Airport",
      "city": "Conceicao Do Mato Dentro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2196,
      "lat": -19.0202999115,
      "lon": -43.4338989258,
      "tz": "America\/Sao_Paulo"
  },
  "SNKE": {
      "icao": "SNKE",
      "iata": "CMP",
      "name": "Santana do Araguaia Airport",
      "city": "Santana Do Araguaia",
      "state": "Para",
      "country": "BR",
      "elevation": 597,
      "lat": -9.3199701309,
      "lon": -50.3284988403,
      "tz": "America\/Belem"
  },
  "SNKF": {
      "icao": "SNKF",
      "iata": "QDF",
      "name": "Conselheiro Lafaiete Airport",
      "city": "Conselheiro Lafaiete",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3478,
      "lat": -20.7385997772,
      "lon": -43.7974014282,
      "tz": "America\/Sao_Paulo"
  },
  "SNKH": {
      "icao": "SNKH",
      "iata": "",
      "name": "Creputia Airport",
      "city": "Creputia",
      "state": "Para",
      "country": "BR",
      "elevation": 984,
      "lat": -8.116944313,
      "lon": -57.1172218323,
      "tz": "America\/Santarem"
  },
  "SNKI": {
      "icao": "SNKI",
      "iata": "CDI",
      "name": "Cachoeiro do Itapemirim Airport",
      "city": "Cachoeiro Do Itapemirim",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 335,
      "lat": -20.8342990875,
      "lon": -41.1856002808,
      "tz": "America\/Sao_Paulo"
  },
  "SNKJ": {
      "icao": "SNKJ",
      "iata": "",
      "name": "Fazenda Rio Dourado Airport",
      "city": "Cumaru Do Norte",
      "state": "Para",
      "country": "BR",
      "elevation": 814,
      "lat": -8.3441667557,
      "lon": -51.4513893127,
      "tz": "America\/Belem"
  },
  "SNKK": {
      "icao": "SNKK",
      "iata": "",
      "name": "Caico Airport",
      "city": "Caico",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 600,
      "lat": -6.4391698837,
      "lon": -37.0783004761,
      "tz": "America\/Fortaleza"
  },
  "SNKL": {
      "icao": "SNKL",
      "iata": "0",
      "name": "Colinas Airport",
      "city": "Colinas",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 394,
      "lat": -5.9669399261,
      "lon": -44.2338981628,
      "tz": "America\/Fortaleza"
  },
  "SNKN": {
      "icao": "SNKN",
      "iata": "QCP",
      "name": "Currais Novos Airport",
      "city": "Currais Novos",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 1148,
      "lat": -6.2808327675,
      "lon": -36.5402793884,
      "tz": "America\/Fortaleza"
  },
  "SNKO": {
      "icao": "SNKO",
      "iata": "",
      "name": "Brotas de Macaubas Airport",
      "city": "Brotas De Macaubas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2667,
      "lat": -11.9994001389,
      "lon": -42.6348991394,
      "tz": "America\/Bahia"
  },
  "SNKQ": {
      "icao": "SNKQ",
      "iata": "",
      "name": "Klaus Mangold Airport",
      "city": "Ipixuna Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 200,
      "lat": -2.3919439316,
      "lon": -47.8450012207,
      "tz": "America\/Belem"
  },
  "SNKR": {
      "icao": "SNKR",
      "iata": "",
      "name": "Corrente Airport",
      "city": "Corrente",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1552,
      "lat": -10.4519443512,
      "lon": -45.136390686,
      "tz": "America\/Fortaleza"
  },
  "SNKS": {
      "icao": "SNKS",
      "iata": "",
      "name": "Santa Rita de Cassia Airport",
      "city": "Santa Rita De Cassia",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1476,
      "lat": -10.9502782822,
      "lon": -44.4672203064,
      "tz": "America\/Bahia"
  },
  "SNKT": {
      "icao": "SNKT",
      "iata": "",
      "name": "Crepurizinho Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 1092,
      "lat": -6.8444437981,
      "lon": -56.5894432068,
      "tz": "America\/Santarem"
  },
  "SNKU": {
      "icao": "SNKU",
      "iata": "",
      "name": "Canudos Airport",
      "city": "Canudos",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1247,
      "lat": -9.9005556107,
      "lon": -39.1336097717,
      "tz": "America\/Bahia"
  },
  "SNKV": {
      "icao": "SNKV",
      "iata": "",
      "name": "Fazenda Campo Verde Airport",
      "city": "Tartarugalzinho",
      "state": "Amapa",
      "country": "BR",
      "elevation": 49,
      "lat": 1.0427800417,
      "lon": -50.5167007446,
      "tz": "America\/Belem"
  },
  "SNKW": {
      "icao": "SNKW",
      "iata": "",
      "name": "Fazenda Boa Sorte Airport",
      "city": "Iacri",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1398,
      "lat": -21.918889,
      "lon": -50.636389,
      "tz": "America\/Sao_Paulo"
  },
  "SNKX": {
      "icao": "SNKX",
      "iata": "",
      "name": "Marborges Airport",
      "city": "Moju",
      "state": "Para",
      "country": "BR",
      "elevation": 114,
      "lat": -1.9716670513,
      "lon": -48.6280555725,
      "tz": "America\/Belem"
  },
  "SNKY": {
      "icao": "SNKY",
      "iata": "",
      "name": "Fazenda Cikel Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 299,
      "lat": -3.664444,
      "lon": -48.834444,
      "tz": "America\/Belem"
  },
  "SNKZ": {
      "icao": "SNKZ",
      "iata": "",
      "name": "Fazenda Remanso do Pito Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 912,
      "lat": -23.2013893127,
      "lon": -53.855556488,
      "tz": "America\/Campo_Grande"
  },
  "SNLA": {
      "icao": "SNLA",
      "iata": "",
      "name": "Fazenda Lagoa das Antas Airport",
      "city": "Canaa Dos Carajas",
      "state": "Para",
      "country": "BR",
      "elevation": 899,
      "lat": -6.5675001144,
      "lon": -49.7194442749,
      "tz": "America\/Belem"
  },
  "SNLB": {
      "icao": "SNLB",
      "iata": "LVB",
      "name": "Livramento do Brumado Airport",
      "city": "Livramento Do Brumado",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1559,
      "lat": -13.6506004333,
      "lon": -41.8339004517,
      "tz": "America\/Bahia"
  },
  "SNLC": {
      "icao": "SNLC",
      "iata": "",
      "name": "Las Casas Airport",
      "city": "Las Casas",
      "state": "Para",
      "country": "BR",
      "elevation": 1969,
      "lat": -7.9669442177,
      "lon": -50.0172233582,
      "tz": "America\/Belem"
  },
  "SNLD": {
      "icao": "SNLD",
      "iata": "",
      "name": "Fazenda Serra Azul Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 472,
      "lat": -5.6966671944,
      "lon": -56.494720459,
      "tz": "America\/Santarem"
  },
  "SNLF": {
      "icao": "SNLF",
      "iata": "",
      "name": "Filial Campo Florido Airport",
      "city": "Campo Florido",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1959,
      "lat": -19.8138885498,
      "lon": -48.7388877869,
      "tz": "America\/Sao_Paulo"
  },
  "SNLG": {
      "icao": "SNLG",
      "iata": "",
      "name": "Serra do Cipo Airport",
      "city": "Jaboticatubas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2621,
      "lat": -19.3986110687,
      "lon": -43.7463874817,
      "tz": "America\/Sao_Paulo"
  },
  "SNLH": {
      "icao": "SNLH",
      "iata": "",
      "name": "Lajinha Airport",
      "city": "Lajinha",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2051,
      "lat": -20.1402778625,
      "lon": -41.6091651917,
      "tz": "America\/Sao_Paulo"
  },
  "SNLI": {
      "icao": "SNLI",
      "iata": "",
      "name": "Abaete Airport",
      "city": "Abaete",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2178,
      "lat": -19.1555995941,
      "lon": -45.4948005676,
      "tz": "America\/Sao_Paulo"
  },
  "SNLJ": {
      "icao": "SNLJ",
      "iata": "",
      "name": "Agropecuaria Ceu Aberto Ltda Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 3428,
      "lat": -9.516667366,
      "lon": -61.25,
      "tz": "America\/Cuiaba"
  },
  "SNLK": {
      "icao": "SNLK",
      "iata": "",
      "name": "Agropecuaria Ferreira Penco Ltda Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 3297,
      "lat": -9.7158327103,
      "lon": -61.0466651917,
      "tz": "America\/Cuiaba"
  },
  "SNLM": {
      "icao": "SNLM",
      "iata": "",
      "name": "Fazenda Agropecuaria Rancho 11 Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -15.6141672134,
      "lon": -58.9002761841,
      "tz": "America\/Cuiaba"
  },
  "SNLN": {
      "icao": "SNLN",
      "iata": "",
      "name": "Linhares Airport",
      "city": "Linhares",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 131,
      "lat": -19.3551998138,
      "lon": -40.0694999695,
      "tz": "America\/Sao_Paulo"
  },
  "SNLO": {
      "icao": "SNLO",
      "iata": "SSO",
      "name": "Sao Lourenco Airport",
      "city": "Sao Lourenco",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2871,
      "lat": -22.0909004211,
      "lon": -45.0444984436,
      "tz": "America\/Sao_Paulo"
  },
  "SNLQ": {
      "icao": "SNLQ",
      "iata": "",
      "name": "Fazenda Conforto Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1001,
      "lat": -14.0657997131,
      "lon": -50.4159011841,
      "tz": "America\/Sao_Paulo"
  },
  "SNLT": {
      "icao": "SNLT",
      "iata": "",
      "name": "Paulistana Airport",
      "city": "Paulistana",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1181,
      "lat": -8.1663885117,
      "lon": -41.1538887024,
      "tz": "America\/Fortaleza"
  },
  "SNLU": {
      "icao": "SNLU",
      "iata": "",
      "name": "Fazenda Boa Luz Airport",
      "city": "Laranjeiras",
      "state": "Sergipe",
      "country": "BR",
      "elevation": 167,
      "lat": -10.8422222137,
      "lon": -37.2038879395,
      "tz": "America\/Maceio"
  },
  "SNLV": {
      "icao": "SNLV",
      "iata": "",
      "name": "Lavrinhas Airport",
      "city": "Lavrinhas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3478,
      "lat": -17.7358322144,
      "lon": -43.4594421387,
      "tz": "America\/Sao_Paulo"
  },
  "SNLX": {
      "icao": "SNLX",
      "iata": "",
      "name": "Fazenda Primavera I Airport",
      "city": "Rondon Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 1050,
      "lat": -4.8005561829,
      "lon": -48.3933334351,
      "tz": "America\/Belem"
  },
  "SNLY": {
      "icao": "SNLY",
      "iata": "",
      "name": "Lagoa da Prata Airport",
      "city": "Lagoa Da Prata",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2159,
      "lat": -20.0613994598,
      "lon": -45.554901123,
      "tz": "America\/Sao_Paulo"
  },
  "SNLZ": {
      "icao": "SNLZ",
      "iata": "",
      "name": "Fazenda Boca da Mata Airport",
      "city": "Divinopolis Do Tocantins",
      "state": "Para",
      "country": "BR",
      "elevation": 669,
      "lat": -9.4319438934,
      "lon": -49.5911102295,
      "tz": "America\/Araguaina"
  },
  "SNMA": {
      "icao": "SNMA",
      "iata": "MTE",
      "name": "Monte Alegre Airport",
      "city": "Monte Alegre",
      "state": "Para",
      "country": "BR",
      "elevation": 325,
      "lat": -1.9958000183,
      "lon": -54.0741996765,
      "tz": "America\/Santarem"
  },
  "SNMB": {
      "icao": "SNMB",
      "iata": "0",
      "name": "Mombaca Airport",
      "city": "Mombaca",
      "state": "Ceara",
      "country": "BR",
      "elevation": 748,
      "lat": -5.7441701889,
      "lon": -39.6213989258,
      "tz": "America\/Fortaleza"
  },
  "SNMC": {
      "icao": "SNMC",
      "iata": "",
      "name": "Macaubas Airport",
      "city": "Macaubas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2133,
      "lat": -13.0249996185,
      "lon": -42.6722984314,
      "tz": "America\/Bahia"
  },
  "SNMD": {
      "icao": "SNMD",
      "iata": "",
      "name": "Mundico Coelho Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 471,
      "lat": -6.8205561638,
      "lon": -56.8447227478,
      "tz": "America\/Santarem"
  },
  "SNME": {
      "icao": "SNME",
      "iata": "",
      "name": "Costa dos Coqueiros Airport",
      "city": "Mata De Sao Joao",
      "state": "Bahia",
      "country": "BR",
      "elevation": 233,
      "lat": -12.4897222519,
      "lon": -38.0236091614,
      "tz": "America\/Bahia"
  },
  "SNMF": {
      "icao": "SNMF",
      "iata": "",
      "name": "Agropastoril Monte Alegre Airport",
      "city": "Monte Belo",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2841,
      "lat": -21.3505554199,
      "lon": -46.2505569458,
      "tz": "America\/Sao_Paulo"
  },
  "SNMI": {
      "icao": "SNMI",
      "iata": "",
      "name": "Mina Caraiba Airport",
      "city": "Jaguarari",
      "state": "Ceara",
      "country": "BR",
      "elevation": 1476,
      "lat": -9.8347215652,
      "lon": -39.8833312988,
      "tz": "America\/Bahia"
  },
  "SNMJ": {
      "icao": "SNMJ",
      "iata": "",
      "name": "Maracas Airport",
      "city": "Maracas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3280,
      "lat": -13.4333000183,
      "lon": -40.4333000183,
      "tz": "America\/Bahia"
  },
  "SNMK": {
      "icao": "SNMK",
      "iata": "",
      "name": "Mocambinho Airport",
      "city": "Jaiba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1519,
      "lat": -15.093000412,
      "lon": -43.9790992737,
      "tz": "America\/Sao_Paulo"
  },
  "SNML": {
      "icao": "SNML",
      "iata": "",
      "name": "Manduca Leao Airport",
      "city": "Rio Largo",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 387,
      "lat": -9.5444440842,
      "lon": -35.8294448853,
      "tz": "America\/Maceio"
  },
  "SNMM": {
      "icao": "SNMM",
      "iata": "",
      "name": "Morada Nova de Minas Airport",
      "city": "Morada Nova De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1988,
      "lat": -18.5938892365,
      "lon": -45.3530578613,
      "tz": "America\/Sao_Paulo"
  },
  "SNMN": {
      "icao": "SNMN",
      "iata": "",
      "name": "Minas Novas Airport",
      "city": "Minas Novas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2461,
      "lat": -17.2338886261,
      "lon": -42.5838890076,
      "tz": "America\/Sao_Paulo"
  },
  "SNMO": {
      "icao": "SNMO",
      "iata": "",
      "name": "Morada Nova Airport",
      "city": "Morada Nova",
      "state": "Ceara",
      "country": "BR",
      "elevation": 509,
      "lat": -5.0836110115,
      "lon": -38.3836097717,
      "tz": "America\/Fortaleza"
  },
  "SNMP": {
      "icao": "SNMP",
      "iata": "",
      "name": "Fazenda Marocopa Airport",
      "city": "Paracatu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1752,
      "lat": -17.3230552673,
      "lon": -46.5380554199,
      "tz": "America\/Sao_Paulo"
  },
  "SNMR": {
      "icao": "SNMR",
      "iata": "",
      "name": "Marau Airport",
      "city": "Marau",
      "state": "Bahia",
      "country": "BR",
      "elevation": 66,
      "lat": -14.1172218323,
      "lon": -38.9838905334,
      "tz": "America\/Bahia"
  },
  "SNMS": {
      "icao": "SNMS",
      "iata": "0",
      "name": "Monte Santo de Minas Airport",
      "city": "Monte Santo De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2936,
      "lat": -21.1758003235,
      "lon": -46.9589004517,
      "tz": "America\/Sao_Paulo"
  },
  "SNMU": {
      "icao": "SNMU",
      "iata": "MVS",
      "name": "Mucuri Airport",
      "city": "Mucuri",
      "state": "Bahia",
      "country": "BR",
      "elevation": 276,
      "lat": -18.0489006042,
      "lon": -39.864200592,
      "tz": "America\/Bahia"
  },
  "SNMV": {
      "icao": "SNMV",
      "iata": "",
      "name": "Fazenda Flavia Airport",
      "city": "Alcinopolis",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1250,
      "lat": -18.3500003815,
      "lon": -53.75,
      "tz": "America\/Campo_Grande"
  },
  "SNMW": {
      "icao": "SNMW",
      "iata": "",
      "name": "Agropecuaria Paralelo Dez Ltda Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 3035,
      "lat": -9.9994440079,
      "lon": -61.0522232056,
      "tz": "America\/Cuiaba"
  },
  "SNMX": {
      "icao": "SNMX",
      "iata": "SBJ",
      "name": "Sao Mateus Airport",
      "city": "Sao Mateus",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 98,
      "lat": -18.7213001251,
      "lon": -39.8336982727,
      "tz": "America\/Sao_Paulo"
  },
  "SNMZ": {
      "icao": "SNMZ",
      "iata": "PTQ",
      "name": "Porto de Moz Airport",
      "city": "Porto De Moz",
      "state": "Para",
      "country": "BR",
      "elevation": 53,
      "lat": -1.7414499521,
      "lon": -52.2360992432,
      "tz": "America\/Belem"
  },
  "SNNB": {
      "icao": "SNNB",
      "iata": "",
      "name": "Fazenda Cana Brava Airport",
      "city": "Paracatu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1772,
      "lat": -17.1769447327,
      "lon": -46.2975006104,
      "tz": "America\/Sao_Paulo"
  },
  "SNNC": {
      "icao": "SNNC",
      "iata": "",
      "name": "Rio Capim Airport",
      "city": "Ipixuna Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 236,
      "lat": -2.8202779293,
      "lon": -47.8930549622,
      "tz": "America\/Belem"
  },
  "SNND": {
      "icao": "SNND",
      "iata": "",
      "name": "Berlin Ltda Airport",
      "city": "Ituiutaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1815,
      "lat": -18.935300827,
      "lon": -49.4726982117,
      "tz": "America\/Sao_Paulo"
  },
  "SNNE": {
      "icao": "SNNE",
      "iata": "",
      "name": "Sao Joao Nepomuceno Airport",
      "city": "Sao Joao Nepomuceno",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1322,
      "lat": -21.5422210693,
      "lon": -43.0211105347,
      "tz": "America\/Sao_Paulo"
  },
  "SNNF": {
      "icao": "SNNF",
      "iata": "",
      "name": "Nossa Senhora de Fatima Airport",
      "city": "Teresina",
      "state": "Piaui",
      "country": "BR",
      "elevation": 607,
      "lat": -5.0336108208,
      "lon": -42.7055549622,
      "tz": "America\/Fortaleza"
  },
  "SNNH": {
      "icao": "SNNH",
      "iata": "",
      "name": "Carinhanha Airport",
      "city": "Carinhanha",
      "state": "",
      "country": "BR",
      "elevation": 1483,
      "lat": -14.298500061,
      "lon": -43.7972984314,
      "tz": "America\/Bahia"
  },
  "SNNI": {
      "icao": "SNNI",
      "iata": "",
      "name": "Praia do Forte Airport",
      "city": "Mata De Sao Joao",
      "state": "Bahia",
      "country": "BR",
      "elevation": 49,
      "lat": -12.5649995804,
      "lon": -38.0216674805,
      "tz": "America\/Bahia"
  },
  "SNNJ": {
      "icao": "SNNJ",
      "iata": "",
      "name": "Fazenda Santa Cruz Airport",
      "city": "Iaciara",
      "state": "Goias",
      "country": "BR",
      "elevation": 1529,
      "lat": -14.2177782059,
      "lon": -46.8386116028,
      "tz": "America\/Sao_Paulo"
  },
  "SNNK": {
      "icao": "SNNK",
      "iata": "",
      "name": "Fazenda Teragro Airport",
      "city": "Matias Cardoso",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1512,
      "lat": -14.7694444656,
      "lon": -43.4152793884,
      "tz": "America\/Sao_Paulo"
  },
  "SNNM": {
      "icao": "SNNM",
      "iata": "",
      "name": "Entre Rios - Vitoria Airport",
      "city": "Guarapuava",
      "state": "Parana",
      "country": "BR",
      "elevation": 3602,
      "lat": -25.566110611,
      "lon": -51.4966659546,
      "tz": "America\/Sao_Paulo"
  },
  "SNNO": {
      "icao": "SNNO",
      "iata": "",
      "name": "Fazenda Sao Domingos Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -14.0638885498,
      "lon": -59.8663902283,
      "tz": "America\/Cuiaba"
  },
  "SNNP": {
      "icao": "SNNP",
      "iata": "",
      "name": "Nilo Pecanha Airport",
      "city": "Nilo Pecanha",
      "state": "Para",
      "country": "BR",
      "elevation": 984,
      "lat": -8.0669441223,
      "lon": -52.1672210693,
      "tz": "America\/Belem"
  },
  "SNNQ": {
      "icao": "SNNQ",
      "iata": "",
      "name": "Fazenda Santa Lucia Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1640,
      "lat": -23.03888889,
      "lon": -54.7475,
      "tz": "America\/Campo_Grande"
  },
  "SNNS": {
      "icao": "SNNS",
      "iata": "",
      "name": "Fazenda Jaibense Airport",
      "city": "Matias Cardoso",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1644,
      "lat": -14.9461107254,
      "lon": -43.6811103821,
      "tz": "America\/Sao_Paulo"
  },
  "SNNT": {
      "icao": "SNNT",
      "iata": "",
      "name": "Nova Ponte Airport",
      "city": "Nova Ponte",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3261,
      "lat": -19.1984004974,
      "lon": -47.7286987305,
      "tz": "America\/Sao_Paulo"
  },
  "SNNU": {
      "icao": "SNNU",
      "iata": "NNU",
      "name": "Nanuque Airport",
      "city": "Nanuque",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 591,
      "lat": -17.823299408,
      "lon": -40.3298988342,
      "tz": "America\/Sao_Paulo"
  },
  "SNNV": {
      "icao": "SNNV",
      "iata": "",
      "name": "Fazenda Estrela do Sul Airport",
      "city": "Pedra Preta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1099,
      "lat": -16.5705566406,
      "lon": -54.1708335876,
      "tz": "America\/Cuiaba"
  },
  "SNNX": {
      "icao": "SNNX",
      "iata": "",
      "name": "Fazenda Boa Vista Airport",
      "city": "Corumbiara",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 866,
      "lat": -12.8333330154,
      "lon": -61.3499984741,
      "tz": "America\/Porto_Velho"
  },
  "SNNY": {
      "icao": "SNNY",
      "iata": "",
      "name": "Fazenda Espirito Santo Airport",
      "city": "Comodoro",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1001,
      "lat": -13.2805557251,
      "lon": -60.331111908,
      "tz": "America\/Cuiaba"
  },
  "SNNZ": {
      "icao": "SNNZ",
      "iata": "",
      "name": "Fazenda Agua Turva Airport",
      "city": "Caracol",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 745,
      "lat": -22.1236114502,
      "lon": -57.1038894653,
      "tz": "America\/Campo_Grande"
  },
  "SNOB": {
      "icao": "SNOB",
      "iata": "QBX",
      "name": "Sobral Airport",
      "city": "Sobral",
      "state": "Ceara",
      "country": "BR",
      "elevation": 210,
      "lat": -3.6788899899,
      "lon": -40.3367996216,
      "tz": "America\/Fortaleza"
  },
  "SNOC": {
      "icao": "SNOC",
      "iata": "0",
      "name": "Morro do Chapeu Airport",
      "city": "Morro Do Chapeu",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3609,
      "lat": -11.5343999863,
      "lon": -41.1796989441,
      "tz": "America\/Bahia"
  },
  "SNOD": {
      "icao": "SNOD",
      "iata": "",
      "name": "Praia do Saco Airport",
      "city": "Estancia",
      "state": "Sergipe",
      "country": "BR",
      "elevation": 20,
      "lat": -11.4219436646,
      "lon": -37.3224983215,
      "tz": "America\/Maceio"
  },
  "SNOE": {
      "icao": "SNOE",
      "iata": "0",
      "name": "Oeiras Airport",
      "city": "Oeiras",
      "state": "Piaui",
      "country": "BR",
      "elevation": 820,
      "lat": -7.018889904,
      "lon": -42.1671981812,
      "tz": "America\/Fortaleza"
  },
  "SNOF": {
      "icao": "SNOF",
      "iata": "0",
      "name": "Ouro Fino Airport",
      "city": "Ouro Fino",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2818,
      "lat": -22.2975006104,
      "lon": -46.3932991028,
      "tz": "America\/Sao_Paulo"
  },
  "SNOG": {
      "icao": "SNOG",
      "iata": "",
      "name": "Ceara-Mirim Airport",
      "city": "Ceara-Mirim",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 171,
      "lat": -5.6612100601,
      "lon": -35.413898468,
      "tz": "America\/Fortaleza"
  },
  "SNOI": {
      "icao": "SNOI",
      "iata": "",
      "name": "Fazenda Rio Mutuca Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 951,
      "lat": -10.4263887405,
      "lon": -58.1613883972,
      "tz": "America\/Cuiaba"
  },
  "SNOJ": {
      "icao": "SNOJ",
      "iata": "",
      "name": "Minalpa - Mineracao Alto Parnaiba Ltda. Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -10.4166669846,
      "lon": -56.3886108398,
      "tz": "America\/Cuiaba"
  },
  "SNOK": {
      "icao": "SNOK",
      "iata": "",
      "name": "Fazenda Chao de Estrelas Airport",
      "city": "Aurora Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 126,
      "lat": -2.3272221088,
      "lon": -47.6511116028,
      "tz": "America\/Belem"
  },
  "SNOM": {
      "icao": "SNOM",
      "iata": "",
      "name": "Fazenda Guaporei Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 1001,
      "lat": -15.0066995621,
      "lon": -60.2206993103,
      "tz": "America\/Cuiaba"
  },
  "SNOO": {
      "icao": "SNOO",
      "iata": "",
      "name": "Fazenda Quatrilho Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1457,
      "lat": -13.35778,
      "lon": -55.61611,
      "tz": "America\/Cuiaba"
  },
  "SNOP": {
      "icao": "SNOP",
      "iata": "0",
      "name": "Propria Airport",
      "city": "Propria",
      "state": "Sergipe",
      "country": "BR",
      "elevation": 164,
      "lat": -10.26720047,
      "lon": -36.8335990906,
      "tz": "America\/Maceio"
  },
  "SNOQ": {
      "icao": "SNOQ",
      "iata": "",
      "name": "Fazenda Agropecuaria Crioulo Airport",
      "city": "Tacuru",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1263,
      "lat": -23.5988883972,
      "lon": -55.1425018311,
      "tz": "America\/Campo_Grande"
  },
  "SNOS": {
      "icao": "SNOS",
      "iata": "PSW",
      "name": "Municipal Jose Figueiredo Airport",
      "city": "Passos",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2697,
      "lat": -20.7322006226,
      "lon": -46.6618003845,
      "tz": "America\/Sao_Paulo"
  },
  "SNOT": {
      "icao": "SNOT",
      "iata": "",
      "name": "Fazenda Santa Fe do Guapore Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 951,
      "lat": -14.0116672516,
      "lon": -59.8866653442,
      "tz": "America\/Cuiaba"
  },
  "SNOU": {
      "icao": "SNOU",
      "iata": "FEJ",
      "name": "Novo Aerodromo de Feijo Airport",
      "city": "Feijo",
      "state": "Acre",
      "country": "BR",
      "elevation": 394,
      "lat": -8.140832901,
      "lon": -70.3472213745,
      "tz": "America\/Rio_Branco"
  },
  "SNOV": {
      "icao": "SNOV",
      "iata": "",
      "name": "Povoado de Camacari Airport",
      "city": "Coruripe",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 279,
      "lat": -10.1225004196,
      "lon": -36.2922210693,
      "tz": "America\/Maceio"
  },
  "SNOW": {
      "icao": "SNOW",
      "iata": "",
      "name": "Fazenda Turmalina Airport",
      "city": "Martinopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1378,
      "lat": -21.9822216034,
      "lon": -50.9722213745,
      "tz": "America\/Sao_Paulo"
  },
  "SNOX": {
      "icao": "SNOX",
      "iata": "ORX",
      "name": "Oriximina Airport",
      "city": "Oriximina",
      "state": "Para",
      "country": "BR",
      "elevation": 262,
      "lat": -1.7140799761,
      "lon": -55.8362007141,
      "tz": "America\/Santarem"
  },
  "SNOY": {
      "icao": "SNOY",
      "iata": "",
      "name": "Ouricuri Airport",
      "city": "Ouricuri",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1529,
      "lat": -7.8765201569,
      "lon": -40.0918006897,
      "tz": "America\/Recife"
  },
  "SNOZ": {
      "icao": "SNOZ",
      "iata": "",
      "name": "Coronel Alexandre Raposo Airport",
      "city": "Paco Do Lumiar",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 154,
      "lat": -2.5209701061,
      "lon": -44.1212997437,
      "tz": "America\/Fortaleza"
  },
  "SNPA": {
      "icao": "SNPA",
      "iata": "",
      "name": "Para de Minas Airport",
      "city": "Para De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2598,
      "lat": -19.8425998688,
      "lon": -44.6012001038,
      "tz": "America\/Sao_Paulo"
  },
  "SNPB": {
      "icao": "SNPB",
      "iata": "0",
      "name": "Pastos Bons Airport",
      "city": "Pastos Bons",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 197,
      "lat": -6.6669402122,
      "lon": -44.0839004517,
      "tz": "America\/Fortaleza"
  },
  "SNPC": {
      "icao": "SNPC",
      "iata": "PCS",
      "name": "Picos Airport",
      "city": "Picos",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1050,
      "lat": -7.0620598793,
      "lon": -41.5237007141,
      "tz": "America\/Fortaleza"
  },
  "SNPD": {
      "icao": "SNPD",
      "iata": "POJ",
      "name": "Patos de Minas Airport",
      "city": "Patos De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2793,
      "lat": -18.6728000641,
      "lon": -46.4911994934,
      "tz": "America\/Sao_Paulo"
  },
  "SNPE": {
      "icao": "SNPE",
      "iata": "",
      "name": "Penedo Airport",
      "city": "Penedo",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 197,
      "lat": -10.2658996582,
      "lon": -36.5514984131,
      "tz": "America\/Maceio"
  },
  "SNPF": {
      "icao": "SNPF",
      "iata": "",
      "name": "Fazenda Canabrava Airport",
      "city": "Parnarama",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 689,
      "lat": -5.5911111832,
      "lon": -43.317779541,
      "tz": "America\/Fortaleza"
  },
  "SNPI": {
      "icao": "SNPI",
      "iata": "0",
      "name": "Piata Airport",
      "city": "Piata",
      "state": "Bahia",
      "country": "BR",
      "elevation": 4118,
      "lat": -13.0005998611,
      "lon": -41.7750015259,
      "tz": "America\/Bahia"
  },
  "SNPJ": {
      "icao": "SNPJ",
      "iata": "0",
      "name": "Patrocinio Airport",
      "city": "Patrocinio",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3229,
      "lat": -18.9094009399,
      "lon": -46.9827003479,
      "tz": "America\/Sao_Paulo"
  },
  "SNPL": {
      "icao": "SNPL",
      "iata": "",
      "name": "Fazenda Pacuruxu Airport",
      "city": "Santa Mercedes",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1181,
      "lat": -21.2563896179,
      "lon": -51.7288894653,
      "tz": "America\/Sao_Paulo"
  },
  "SNPM": {
      "icao": "SNPM",
      "iata": "",
      "name": "Palmeiras Airport",
      "city": "Palmeiras",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2395,
      "lat": -12.50055556,
      "lon": -41.58388889,
      "tz": "America\/Bahia"
  },
  "SNPN": {
      "icao": "SNPN",
      "iata": "",
      "name": "Fazenda Agua Fria Airport",
      "city": "Lavinia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1378,
      "lat": -21.0302772522,
      "lon": -50.9258346558,
      "tz": "America\/Sao_Paulo"
  },
  "SNPO": {
      "icao": "SNPO",
      "iata": "0",
      "name": "Pompeu Airport",
      "city": "Pompeu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2313,
      "lat": -19.2033004761,
      "lon": -45.0233001709,
      "tz": "America\/Sao_Paulo"
  },
  "SNPP": {
      "icao": "SNPP",
      "iata": "",
      "name": "Morro de Sao Paulo Airport",
      "city": "Cairu",
      "state": "Bahia",
      "country": "BR",
      "elevation": 66,
      "lat": -13.4369440079,
      "lon": -38.9225006104,
      "tz": "America\/Bahia"
  },
  "SNPQ": {
      "icao": "SNPQ",
      "iata": "",
      "name": "Pesqueira Airport",
      "city": "Pesqueira",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 2067,
      "lat": -8.3741674423,
      "lon": -36.6377792358,
      "tz": "America\/Recife"
  },
  "SNPS": {
      "icao": "SNPS",
      "iata": "",
      "name": "Pontezinha Airport",
      "city": "Buritis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3277,
      "lat": -15.2601003647,
      "lon": -46.7624015808,
      "tz": "America\/Sao_Paulo"
  },
  "SNPT": {
      "icao": "SNPT",
      "iata": "",
      "name": "Passa Tempo Airport",
      "city": "Passa Tempo",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3143,
      "lat": -20.6505565643,
      "lon": -44.5005569458,
      "tz": "America\/Sao_Paulo"
  },
  "SNPU": {
      "icao": "SNPU",
      "iata": "0",
      "name": "Paraguacu Airport",
      "city": "Paraguacu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2838,
      "lat": -21.5592002869,
      "lon": -45.751701355,
      "tz": "America\/Sao_Paulo"
  },
  "SNPW": {
      "icao": "SNPW",
      "iata": "",
      "name": "Piaus Airport",
      "city": "Piaus",
      "state": "Para",
      "country": "BR",
      "elevation": 591,
      "lat": -8.8336105347,
      "lon": -50.0005569458,
      "tz": "America\/Belem"
  },
  "SNPX": {
      "icao": "SNPX",
      "iata": "PIV",
      "name": "Pirapora Airport",
      "city": "Pirapora",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1808,
      "lat": -17.3169002533,
      "lon": -44.8602981567,
      "tz": "America\/Sao_Paulo"
  },
  "SNPY": {
      "icao": "SNPY",
      "iata": "",
      "name": "Sao Sebastiao do Paraiso Airport",
      "city": "Sao Sebastiao Do Paraiso",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3110,
      "lat": -20.9482002258,
      "lon": -46.9832000732,
      "tz": "America\/Sao_Paulo"
  },
  "SNPZ": {
      "icao": "SNPZ",
      "iata": "",
      "name": "Pedra Azul Airport",
      "city": "Pedra Azul",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2625,
      "lat": -16.0672225952,
      "lon": -41.1672210693,
      "tz": "America\/Sao_Paulo"
  },
  "SNQA": {
      "icao": "SNQA",
      "iata": "",
      "name": "Fazenda Tracaja Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 138,
      "lat": -2.640556097,
      "lon": -47.8622207642,
      "tz": "America\/Belem"
  },
  "SNQB": {
      "icao": "SNQB",
      "iata": "",
      "name": "Fazenda Val Paraiso Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 256,
      "lat": -3.1683330536,
      "lon": -48.0713882446,
      "tz": "America\/Belem"
  },
  "SNQD": {
      "icao": "SNQD",
      "iata": "",
      "name": "Sousa Airport",
      "city": "Sousa",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 745,
      "lat": -6.7854399681,
      "lon": -38.2333984375,
      "tz": "America\/Fortaleza"
  },
  "SNQE": {
      "icao": "SNQE",
      "iata": "",
      "name": "Fazenda Serra Grande Airport",
      "city": "Parauapebas",
      "state": "Para",
      "country": "BR",
      "elevation": 682,
      "lat": -6.0688891411,
      "lon": -49.8400001526,
      "tz": "America\/Belem"
  },
  "SNQG": {
      "icao": "SNQG",
      "iata": "FLB",
      "name": "Cangapara Airport",
      "city": "Floriano",
      "state": "Piaui",
      "country": "BR",
      "elevation": 689,
      "lat": -6.8463897705,
      "lon": -43.0773010254,
      "tz": "America\/Fortaleza"
  },
  "SNQI": {
      "icao": "SNQI",
      "iata": "",
      "name": "Fazenda Camagril Agropecuaria Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2756,
      "lat": -14.1180562973,
      "lon": -57.3411102295,
      "tz": "America\/Cuiaba"
  },
  "SNQJ": {
      "icao": "SNQJ",
      "iata": "",
      "name": "Rancho Paraiso Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 499,
      "lat": -17.5227775574,
      "lon": -55.400554657,
      "tz": "America\/Campo_Grande"
  },
  "SNQL": {
      "icao": "SNQL",
      "iata": "",
      "name": "Bom Jesus Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 482,
      "lat": -15.7349996567,
      "lon": -57.3463897705,
      "tz": "America\/Cuiaba"
  },
  "SNQM": {
      "icao": "SNQM",
      "iata": "",
      "name": "Queimadas Airport",
      "city": "Queimadas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1050,
      "lat": -10.9883327484,
      "lon": -39.6205558777,
      "tz": "America\/Bahia"
  },
  "SNQO": {
      "icao": "SNQO",
      "iata": "",
      "name": "Fazenda Campanario Airport",
      "city": "Bodoquena",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 558,
      "lat": -20.375,
      "lon": -56.5419425964,
      "tz": "America\/Campo_Grande"
  },
  "SNQP": {
      "icao": "SNQP",
      "iata": "0",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Magda",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1437,
      "lat": -20.5674991608,
      "lon": -50.2303009033,
      "tz": "America\/Sao_Paulo"
  },
  "SNQR": {
      "icao": "SNQR",
      "iata": "",
      "name": "Fazenda Bahia - Don Bosco Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 3,
      "lat": -16.7277774811,
      "lon": -57.2333335876,
      "tz": "America\/Cuiaba"
  },
  "SNQS": {
      "icao": "SNQS",
      "iata": "",
      "name": "Fazenda Nossa Senhora Auxiliadora Airport",
      "city": "Ivinhema",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1001,
      "lat": -22.3044433594,
      "lon": -54.0305557251,
      "tz": "America\/Campo_Grande"
  },
  "SNQT": {
      "icao": "SNQT",
      "iata": "",
      "name": "Fazenda Estrela do Sul Airport",
      "city": "Umuarama",
      "state": "Parana",
      "country": "BR",
      "elevation": 1542,
      "lat": -23.7958335876,
      "lon": -53.4797210693,
      "tz": "America\/Sao_Paulo"
  },
  "SNQU": {
      "icao": "SNQU",
      "iata": "",
      "name": "Mucuge Airport",
      "city": "Mucuge",
      "state": "Bahia",
      "country": "BR",
      "elevation": 3510,
      "lat": -13.0312004089,
      "lon": -41.4440994263,
      "tz": "America\/Bahia"
  },
  "SNQV": {
      "icao": "SNQV",
      "iata": "",
      "name": "Curvelo Airport",
      "city": "Curvelo",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2205,
      "lat": -18.7493991852,
      "lon": -44.4570007324,
      "tz": "America\/Sao_Paulo"
  },
  "SNQW": {
      "icao": "SNQW",
      "iata": "",
      "name": "Cururu Airport",
      "city": "Jacareacanga",
      "state": "Para",
      "country": "BR",
      "elevation": 453,
      "lat": -7.5644440651,
      "lon": -57.7422218323,
      "tz": "America\/Santarem"
  },
  "SNQX": {
      "icao": "SNQX",
      "iata": "",
      "name": "Quixada Airport",
      "city": "Quixada",
      "state": "Ceara",
      "country": "BR",
      "elevation": 653,
      "lat": -4.9790701866,
      "lon": -38.9875984192,
      "tz": "America\/Fortaleza"
  },
  "SNQZ": {
      "icao": "SNQZ",
      "iata": "",
      "name": "Fazenda Bagagem Airport",
      "city": "Aral Moreira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1837,
      "lat": -22.8427772522,
      "lon": -55.5705566406,
      "tz": "America\/Campo_Grande"
  },
  "SNRA": {
      "icao": "SNRA",
      "iata": "",
      "name": "Fazenda Santo Andre Airport",
      "city": "Pratinha",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 4134,
      "lat": -19.7261104584,
      "lon": -46.3527793884,
      "tz": "America\/Sao_Paulo"
  },
  "SNRB": {
      "icao": "SNRB",
      "iata": "",
      "name": "Fazenda Rosa de Maio Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 102,
      "lat": -4.3152098656,
      "lon": -56.1074981689,
      "tz": "America\/Santarem"
  },
  "SNRD": {
      "icao": "SNRD",
      "iata": "PDF",
      "name": "Prado Airport",
      "city": "Prado",
      "state": "Bahia",
      "country": "BR",
      "elevation": 66,
      "lat": -17.2966995239,
      "lon": -39.2711982727,
      "tz": "America\/Bahia"
  },
  "SNRE": {
      "icao": "SNRE",
      "iata": "",
      "name": "Fazenda Rebeca Airport",
      "city": "Serra Dourada",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1575,
      "lat": -12.6827001572,
      "lon": -43.8714981079,
      "tz": "America\/Bahia"
  },
  "SNRH": {
      "icao": "SNRH",
      "iata": "",
      "name": "Rachid Saliba Airport",
      "city": "Boa Esperanca",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2625,
      "lat": -21.0394001007,
      "lon": -45.8722000122,
      "tz": "America\/Sao_Paulo"
  },
  "SNRJ": {
      "icao": "SNRJ",
      "iata": "",
      "name": "Brejo Airport",
      "city": "Brejo",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 328,
      "lat": -3.6669440269,
      "lon": -42.8338890076,
      "tz": "America\/Fortaleza"
  },
  "SNRM": {
      "icao": "SNRM",
      "iata": "",
      "name": "Remanso Airport",
      "city": "Remanso",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1381,
      "lat": -9.6502780914,
      "lon": -42.1172218323,
      "tz": "America\/Bahia"
  },
  "SNRN": {
      "icao": "SNRN",
      "iata": "",
      "name": "Fazenda Vinte de Maio Airport",
      "city": "Getulina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1509,
      "lat": -21.808889389,
      "lon": -49.9711112976,
      "tz": "America\/Sao_Paulo"
  },
  "SNRP": {
      "icao": "SNRP",
      "iata": "0",
      "name": "Rio Paranaiba Airport",
      "city": "Rio Paranaiba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3757,
      "lat": -19.2124996185,
      "lon": -46.2406005859,
      "tz": "America\/Sao_Paulo"
  },
  "SNRQ": {
      "icao": "SNRQ",
      "iata": "",
      "name": "Porto Rico Airport",
      "city": "Jacareacanga",
      "state": "Para",
      "country": "BR",
      "elevation": 331,
      "lat": -6.1041669846,
      "lon": -57.3794441223,
      "tz": "America\/Santarem"
  },
  "SNRR": {
      "icao": "SNRR",
      "iata": "",
      "name": "Fazenda Aratau Airport",
      "city": "Novo Repartimento",
      "state": "Para",
      "country": "BR",
      "elevation": 604,
      "lat": -4.151309967,
      "lon": -50.1632995605,
      "tz": "America\/Belem"
  },
  "SNRS": {
      "icao": "SNRS",
      "iata": "",
      "name": "Russas Airport",
      "city": "Russas",
      "state": "Ceara",
      "country": "BR",
      "elevation": 115,
      "lat": -4.9472699165,
      "lon": -38.0082015991,
      "tz": "America\/Fortaleza"
  },
  "SNRU": {
      "icao": "SNRU",
      "iata": "CAU",
      "name": "Caruaru Airport",
      "city": "Caruaru",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1891,
      "lat": -8.2823896408,
      "lon": -36.0135002136,
      "tz": "America\/Recife"
  },
  "SNRV": {
      "icao": "SNRV",
      "iata": "",
      "name": "Fazenda Rio Vermelho Airport",
      "city": "Sapucaia",
      "state": "Para",
      "country": "BR",
      "elevation": 450,
      "lat": -6.8827781677,
      "lon": -49.4638900757,
      "tz": "America\/Belem"
  },
  "SNRW": {
      "icao": "SNRW",
      "iata": "",
      "name": "Fazenda Barreiro Airport",
      "city": "Miracema Do Tocantins",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1033,
      "lat": -9.9072217941,
      "lon": -48.6349983215,
      "tz": "America\/Araguaina"
  },
  "SNRX": {
      "icao": "SNRX",
      "iata": "0",
      "name": "Riachao Airport",
      "city": "Riachao",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 1312,
      "lat": -7.350279808,
      "lon": -46.6338996887,
      "tz": "America\/Fortaleza"
  },
  "SNSA": {
      "icao": "SNSA",
      "iata": "",
      "name": "Fazenda Bom Sucesso Airport",
      "city": "Vazante",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2454,
      "lat": -17.6086101532,
      "lon": -46.7038879395,
      "tz": "America\/Sao_Paulo"
  },
  "SNSB": {
      "icao": "SNSB",
      "iata": "",
      "name": "Sao Bento Airport",
      "city": "Sao Bento",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 230,
      "lat": -2.7044439316,
      "lon": -44.8447227478,
      "tz": "America\/Fortaleza"
  },
  "SNSC": {
      "icao": "SNSC",
      "iata": "0",
      "name": "Sacramento Airport",
      "city": "Sacramento",
      "state": "",
      "country": "BR",
      "elevation": 3288,
      "lat": -19.8931007385,
      "lon": -47.4221992493,
      "tz": "America\/Sao_Paulo"
  },
  "SNSD": {
      "icao": "SNSD",
      "iata": "",
      "name": "Sao Luiz Airport",
      "city": "Sao Desiderio",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2664,
      "lat": -12.9194440842,
      "lon": -45.7144432068,
      "tz": "America\/Bahia"
  },
  "SNSE": {
      "icao": "SNSE",
      "iata": "",
      "name": "Sento Se Airport",
      "city": "Sento Se",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1306,
      "lat": -9.7413892746,
      "lon": -41.8411102295,
      "tz": "America\/Bahia"
  },
  "SNSF": {
      "icao": "SNSF",
      "iata": "",
      "name": "Fazenda Entre Rios Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1050,
      "lat": -21.3649997711,
      "lon": -57.1091651917,
      "tz": "America\/Campo_Grande"
  },
  "SNSG": {
      "icao": "SNSG",
      "iata": "",
      "name": "Salgueiro Airport",
      "city": "Salgueiro",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1539,
      "lat": -8.0471601486,
      "lon": -39.1363983154,
      "tz": "America\/Recife"
  },
  "SNSH": {
      "icao": "SNSH",
      "iata": "0",
      "name": "Sao Jose Airport",
      "city": "Santarem",
      "state": "Para",
      "country": "BR",
      "elevation": 440,
      "lat": -2.5744400024,
      "lon": -54.7313995361,
      "tz": "America\/Santarem"
  },
  "SNSI": {
      "icao": "SNSI",
      "iata": "",
      "name": "Santa Maria do Suacui Airport",
      "city": "Santa Maria Do Suacui",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1575,
      "lat": -18.1986103058,
      "lon": -42.4661102295,
      "tz": "America\/Sao_Paulo"
  },
  "SNSJ": {
      "icao": "SNSJ",
      "iata": "",
      "name": "Fazenda Fogliatelli Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1723,
      "lat": -13.5297222137,
      "lon": -58.5733337402,
      "tz": "America\/Cuiaba"
  },
  "SNSL": {
      "icao": "SNSL",
      "iata": "",
      "name": "Fazenda Ipitinga Airport",
      "city": "Almeirim",
      "state": "Para",
      "country": "BR",
      "elevation": 1680,
      "lat": 0.3411110044,
      "lon": -53.8147010803,
      "tz": "America\/Santarem"
  },
  "SNSM": {
      "icao": "SNSM",
      "iata": "",
      "name": "Salinopolis Airport",
      "city": "Salinopolis",
      "state": "Para",
      "country": "BR",
      "elevation": 88,
      "lat": -0.6956639886,
      "lon": -47.3347015381,
      "tz": "America\/Belem"
  },
  "SNSO": {
      "icao": "SNSO",
      "iata": "",
      "name": "Serro Airport",
      "city": "Serro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2428,
      "lat": -18.608499527,
      "lon": -43.4240989685,
      "tz": "America\/Sao_Paulo"
  },
  "SNSR": {
      "icao": "SNSR",
      "iata": "",
      "name": "Fazenda Santos Reis Airport",
      "city": "Guarda-Mor",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2165,
      "lat": -17.5478992462,
      "lon": -46.9786987305,
      "tz": "America\/Sao_Paulo"
  },
  "SNSS": {
      "icao": "SNSS",
      "iata": "",
      "name": "Salinas Airport",
      "city": "Salinas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2504,
      "lat": -16.2082996368,
      "lon": -42.3219985962,
      "tz": "America\/Sao_Paulo"
  },
  "SNST": {
      "icao": "SNST",
      "iata": "",
      "name": "Souto Soares Airport",
      "city": "Souto Soares",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2753,
      "lat": -12.0972995758,
      "lon": -41.6407012939,
      "tz": "America\/Bahia"
  },
  "SNSU": {
      "icao": "SNSU",
      "iata": "",
      "name": "Fazenda Pontao Airport",
      "city": "Xinguara",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 495,
      "lat": -6.881111145,
      "lon": -49.1622238159,
      "tz": "America\/Araguaina"
  },
  "SNSV": {
      "icao": "SNSV",
      "iata": "",
      "name": "Fazenda Sao Pedro Airport",
      "city": "Eldorado",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 591,
      "lat": -23.6616668701,
      "lon": -54.0774993896,
      "tz": "America\/Campo_Grande"
  },
  "SNSW": {
      "icao": "SNSW",
      "iata": "SFK",
      "name": "Soure Airport",
      "city": "Soure",
      "state": "Para",
      "country": "BR",
      "elevation": 43,
      "lat": -0.6994310021,
      "lon": -48.5209999084,
      "tz": "America\/Belem"
  },
  "SNSX": {
      "icao": "SNSX",
      "iata": "",
      "name": "Fazenda Sao Martinho Airport",
      "city": "Ilha Solteira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1148,
      "lat": -20.3572216034,
      "lon": -51.2511100769,
      "tz": "America\/Sao_Paulo"
  },
  "SNSY": {
      "icao": "SNSY",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Bonito",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1247,
      "lat": -20.9449996948,
      "lon": -56.4961128235,
      "tz": "America\/Campo_Grande"
  },
  "SNSZ": {
      "icao": "SNSZ",
      "iata": "",
      "name": "Fazenda Itaguassu Airport",
      "city": "Maracaju",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1401,
      "lat": -21.355556488,
      "lon": -55.619720459,
      "tz": "America\/Campo_Grande"
  },
  "SNTA": {
      "icao": "SNTA",
      "iata": "",
      "name": "Fazenda Sao Miguel Airport",
      "city": "Pompeia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1444,
      "lat": -21.8686103821,
      "lon": -50.1163902283,
      "tz": "America\/Sao_Paulo"
  },
  "SNTB": {
      "icao": "SNTB",
      "iata": "",
      "name": "Afogados da Ingazeira Airport",
      "city": "Afogados Da Ingazeira",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1844,
      "lat": -7.723610878,
      "lon": -37.618888855,
      "tz": "America\/Recife"
  },
  "SNTC": {
      "icao": "SNTC",
      "iata": "",
      "name": "Tambori Airport",
      "city": "Teresina",
      "state": "Piaui",
      "country": "BR",
      "elevation": 66,
      "lat": -5.1599998474,
      "lon": -42.7197227478,
      "tz": "America\/Fortaleza"
  },
  "SNTD": {
      "icao": "SNTD",
      "iata": "",
      "name": "Fazenda Mamoneira Airport",
      "city": "Natalandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1862,
      "lat": -16.5862007141,
      "lon": -46.5010986328,
      "tz": "America\/Sao_Paulo"
  },
  "SNTF": {
      "icao": "SNTF",
      "iata": "TXF",
      "name": "Teixeira de Freitas Airport",
      "city": "Teixeira De Freitas",
      "state": "Bahia",
      "country": "BR",
      "elevation": 344,
      "lat": -17.5244998932,
      "lon": -39.6684989929,
      "tz": "America\/Bahia"
  },
  "SNTG": {
      "icao": "SNTG",
      "iata": "",
      "name": "Afranio Airport",
      "city": "Afranio",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1703,
      "lat": -8.6105556488,
      "lon": -41.0002784729,
      "tz": "America\/Recife"
  },
  "SNTI": {
      "icao": "SNTI",
      "iata": "OBI",
      "name": "Obidos Airport",
      "city": "Obidos",
      "state": "Para",
      "country": "BR",
      "elevation": 328,
      "lat": -1.8671699762,
      "lon": -55.5144004822,
      "tz": "America\/Santarem"
  },
  "SNTJ": {
      "icao": "SNTJ",
      "iata": "",
      "name": "Santa Teresa Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 98,
      "lat": -4.2913889885,
      "lon": -56.0875015259,
      "tz": "America\/Santarem"
  },
  "SNTK": {
      "icao": "SNTK",
      "iata": "0",
      "name": "Monte Carmelo Airport",
      "city": "Monte Carmelo",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2904,
      "lat": -18.7171993256,
      "lon": -47.4831008911,
      "tz": "America\/Sao_Paulo"
  },
  "SNTL": {
      "icao": "SNTL",
      "iata": "",
      "name": "Tamboril Airport",
      "city": "Tamboril",
      "state": "Ceara",
      "country": "BR",
      "elevation": 1116,
      "lat": -4.8578300476,
      "lon": -40.3745994568,
      "tz": "America\/Fortaleza"
  },
  "SNTM": {
      "icao": "SNTM",
      "iata": "",
      "name": "Turmalina Airport",
      "city": "Turmalina",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2894,
      "lat": -17.2338886261,
      "lon": -42.7338905334,
      "tz": "America\/Sao_Paulo"
  },
  "SNTO": {
      "icao": "SNTO",
      "iata": "TFL",
      "name": "Juscelino Kubitscheck Airport",
      "city": "Teofilo Otoni",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1572,
      "lat": -17.8922996521,
      "lon": -41.5135993958,
      "tz": "America\/Sao_Paulo"
  },
  "SNTP": {
      "icao": "SNTP",
      "iata": "",
      "name": "Agropalma Airport",
      "city": "Tailandia",
      "state": "Para",
      "country": "BR",
      "elevation": 66,
      "lat": -2.5252780914,
      "lon": -48.7519454956,
      "tz": "America\/Belem"
  },
  "SNTQ": {
      "icao": "SNTQ",
      "iata": "0",
      "name": "Buritirama Airport",
      "city": "Buritirama",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1690,
      "lat": -10.7242002487,
      "lon": -43.6547012329,
      "tz": "America\/Bahia"
  },
  "SNTR": {
      "icao": "SNTR",
      "iata": "",
      "name": "Piritiba Airport",
      "city": "Piritiba",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1896,
      "lat": -11.7381000519,
      "lon": -40.5694999695,
      "tz": "America\/Bahia"
  },
  "SNTS": {
      "icao": "SNTS",
      "iata": "",
      "name": "Patos Airport",
      "city": "Patos",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 820,
      "lat": -7.0389900208,
      "lon": -37.2515983582,
      "tz": "America\/Fortaleza"
  },
  "SNTT": {
      "icao": "SNTT",
      "iata": "",
      "name": "Tranquilo Restolin Airport",
      "city": "Felixlandia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2009,
      "lat": -18.7581996918,
      "lon": -45.1076011658,
      "tz": "America\/Sao_Paulo"
  },
  "SNTU": {
      "icao": "SNTU",
      "iata": "",
      "name": "Turiacu Airport",
      "city": "Turiacu",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 33,
      "lat": -1.7669440508,
      "lon": -45.4505577087,
      "tz": "America\/Fortaleza"
  },
  "SNTV": {
      "icao": "SNTV",
      "iata": "",
      "name": "Fazenda Veredao Airport",
      "city": "Taiobeiras",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2559,
      "lat": -15.6553001404,
      "lon": -41.6540985107,
      "tz": "America\/Sao_Paulo"
  },
  "SNTW": {
      "icao": "SNTW",
      "iata": "",
      "name": "Senador Teotonio Vilela Airport",
      "city": "Teotonio Vilela",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 443,
      "lat": -9.896944046,
      "lon": -36.293056488,
      "tz": "America\/Maceio"
  },
  "SNTX": {
      "icao": "SNTX",
      "iata": "",
      "name": "Litoranea Airport",
      "city": "Cururupu",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 26,
      "lat": -1.3019440174,
      "lon": -44.9049987793,
      "tz": "America\/Fortaleza"
  },
  "SNTY": {
      "icao": "SNTY",
      "iata": "",
      "name": "Correntina Airport",
      "city": "Correntina",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2159,
      "lat": -13.3805561066,
      "lon": -44.6172218323,
      "tz": "America\/Bahia"
  },
  "SNUB": {
      "icao": "SNUB",
      "iata": "",
      "name": "Uba Airport",
      "city": "Uba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1115,
      "lat": -21.1210002899,
      "lon": -42.8819007874,
      "tz": "America\/Sao_Paulo"
  },
  "SNUC": {
      "icao": "SNUC",
      "iata": "",
      "name": "Acu Airport",
      "city": "Acu",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 394,
      "lat": -5.5950498581,
      "lon": -36.960899353,
      "tz": "America\/Fortaleza"
  },
  "SNUD": {
      "icao": "SNUD",
      "iata": "",
      "name": "Urbano Santos Airport",
      "city": "Urbano Santos",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 197,
      "lat": -3.1997220516,
      "lon": -43.386390686,
      "tz": "America\/Fortaleza"
  },
  "SNUF": {
      "icao": "SNUF",
      "iata": "",
      "name": "Fazenda Treis Coringas Airport",
      "city": "Uberaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3264,
      "lat": -19.5244445801,
      "lon": -47.8525009155,
      "tz": "America\/Sao_Paulo"
  },
  "SNUG": {
      "icao": "SNUG",
      "iata": "",
      "name": "Fazenda Eunice Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 787,
      "lat": -14.9555559158,
      "lon": -59.6227798462,
      "tz": "America\/Cuiaba"
  },
  "SNUH": {
      "icao": "SNUH",
      "iata": "",
      "name": "Piumhi Airport",
      "city": "Piumhi",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2444,
      "lat": -20.4386005402,
      "lon": -45.9928016663,
      "tz": "America\/Sao_Paulo"
  },
  "SNUI": {
      "icao": "SNUI",
      "iata": "",
      "name": "Aracuai Airport",
      "city": "Aracuai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1181,
      "lat": -16.8526000977,
      "lon": -42.0451011658,
      "tz": "America\/Sao_Paulo"
  },
  "SNUJ": {
      "icao": "SNUJ",
      "iata": "",
      "name": "Santa Maria da Boa Vista Airport",
      "city": "Santa Maria Da Boa Vista",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1253,
      "lat": -8.7819442749,
      "lon": -39.8722229004,
      "tz": "America\/Recife"
  },
  "SNUK": {
      "icao": "SNUK",
      "iata": "",
      "name": "Fazenda Lago Vermelho Airport",
      "city": "Buritizeiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1690,
      "lat": -17.0352783203,
      "lon": -45.4863891602,
      "tz": "America\/Sao_Paulo"
  },
  "SNUL": {
      "icao": "SNUL",
      "iata": "",
      "name": "Fazenda Aurora III Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1214,
      "lat": -21.4524993896,
      "lon": -53.3533325195,
      "tz": "America\/Campo_Grande"
  },
  "SNUM": {
      "icao": "SNUM",
      "iata": "",
      "name": "Sao Jose do Egito Airport",
      "city": "Sao Jose Do Egito",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 2133,
      "lat": -7.4869441986,
      "lon": -37.2911109924,
      "tz": "America\/Recife"
  },
  "SNUN": {
      "icao": "SNUN",
      "iata": "",
      "name": "Unai Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1998,
      "lat": -16.3565006256,
      "lon": -46.9277000427,
      "tz": "America\/Sao_Paulo"
  },
  "SNUP": {
      "icao": "SNUP",
      "iata": "",
      "name": "Usina Porto Rico Airport",
      "city": "Campo Alegre",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 404,
      "lat": -9.802778244,
      "lon": -36.2224998474,
      "tz": "America\/Maceio"
  },
  "SNUS": {
      "icao": "SNUS",
      "iata": "",
      "name": "Usina Delta I Airport",
      "city": "Delta",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2100,
      "lat": -19.9408321381,
      "lon": -47.7783317566,
      "tz": "America\/Sao_Paulo"
  },
  "SNUT": {
      "icao": "SNUT",
      "iata": "",
      "name": "Utinga Airport",
      "city": "Utinga",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1988,
      "lat": -12.1055002213,
      "lon": -41.0745010376,
      "tz": "America\/Bahia"
  },
  "SNUU": {
      "icao": "SNUU",
      "iata": "",
      "name": "Uaua Airport",
      "city": "Uaua",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1335,
      "lat": -9.8350000381,
      "lon": -39.4944458008,
      "tz": "America\/Bahia"
  },
  "SNUV": {
      "icao": "SNUV",
      "iata": "",
      "name": "Fazenda Nova Aurora Airport",
      "city": "Anaurilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 951,
      "lat": -22.2169437408,
      "lon": -52.7402763367,
      "tz": "America\/Campo_Grande"
  },
  "SNUW": {
      "icao": "SNUW",
      "iata": "",
      "name": "Sertania Airport",
      "city": "Sertania",
      "state": "Pernambuco",
      "country": "BR",
      "elevation": 1968,
      "lat": -8.0574998856,
      "lon": -37.2894439697,
      "tz": "America\/Recife"
  },
  "SNUX": {
      "icao": "SNUX",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1142,
      "lat": -21.4047222137,
      "lon": -53.3069458008,
      "tz": "America\/Campo_Grande"
  },
  "SNUY": {
      "icao": "SNUY",
      "iata": "",
      "name": "Buritis Airport",
      "city": "Buritis",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1870,
      "lat": -15.6316671371,
      "lon": -46.4286117554,
      "tz": "America\/Sao_Paulo"
  },
  "SNUZ": {
      "icao": "SNUZ",
      "iata": "",
      "name": "Usina Coruripe Airport",
      "city": "Coruripe",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 210,
      "lat": -10.0933332443,
      "lon": -36.1877784729,
      "tz": "America\/Maceio"
  },
  "SNVA": {
      "icao": "SNVA",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Cumaru Do Norte",
      "state": "Para",
      "country": "BR",
      "elevation": 1037,
      "lat": -7.9875001907,
      "lon": -50.6316680908,
      "tz": "America\/Belem"
  },
  "SNVB": {
      "icao": "SNVB",
      "iata": "VAL",
      "name": "Valenca Airport",
      "city": "Valenca",
      "state": "Bahia",
      "country": "BR",
      "elevation": 21,
      "lat": -13.296500206,
      "lon": -38.992401123,
      "tz": "America\/Bahia"
  },
  "SNVC": {
      "icao": "SNVC",
      "iata": "",
      "name": "Vicosa Airport",
      "city": "Vicosa",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2162,
      "lat": -20.7447223663,
      "lon": -42.8419456482,
      "tz": "America\/Sao_Paulo"
  },
  "SNVD": {
      "icao": "SNVD",
      "iata": "0",
      "name": "Santa Maria da Vitoria Airport",
      "city": "Santa Maria Da Vitoria",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1850,
      "lat": -13.4006004333,
      "lon": -44.2172012329,
      "tz": "America\/Bahia"
  },
  "SNVG": {
      "icao": "SNVG",
      "iata": "0",
      "name": "Volta Grande Airport",
      "city": "Conceicao Das Alagoas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1831,
      "lat": -20.0338993073,
      "lon": -48.2338981628,
      "tz": "America\/Sao_Paulo"
  },
  "SNVH": {
      "icao": "SNVH",
      "iata": "",
      "name": "Fazenda Santa Helena Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 492,
      "lat": -16.1686115265,
      "lon": -58.1383323669,
      "tz": "America\/Cuiaba"
  },
  "SNVI": {
      "icao": "SNVI",
      "iata": "QID",
      "name": "Melio Viana Airport",
      "city": "Tres Coracoes",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3232,
      "lat": -21.790599823,
      "lon": -45.2691993713,
      "tz": "America\/Sao_Paulo"
  },
  "SNVJ": {
      "icao": "SNVJ",
      "iata": "",
      "name": "Fazenda Salto Grande Airport",
      "city": "Jauru",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1444,
      "lat": -15.2533330917,
      "lon": -58.7933311462,
      "tz": "America\/Cuiaba"
  },
  "SNVK": {
      "icao": "SNVK",
      "iata": "",
      "name": "Fazenda Santa Terezinha Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1214,
      "lat": -20.5013885498,
      "lon": -52.368888855,
      "tz": "America\/Campo_Grande"
  },
  "SNVL": {
      "icao": "SNVL",
      "iata": "",
      "name": "Virgem da Lapa Airport",
      "city": "Virgem Da Lapa",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2477,
      "lat": -16.7738895416,
      "lon": -42.3875007629,
      "tz": "America\/Sao_Paulo"
  },
  "SNVM": {
      "icao": "SNVM",
      "iata": "",
      "name": "Fazenda Guaivira Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1112,
      "lat": -20.6644439697,
      "lon": -52.7672233582,
      "tz": "America\/Campo_Grande"
  },
  "SNVN": {
      "icao": "SNVN",
      "iata": "",
      "name": "Fazenda Santa Clara Airport",
      "city": "Rubiacea",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1352,
      "lat": -21.3033332825,
      "lon": -50.7522201538,
      "tz": "America\/Sao_Paulo"
  },
  "SNVO": {
      "icao": "SNVO",
      "iata": "",
      "name": "Fazenda Pontal Airport",
      "city": "Carneirinho",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1240,
      "lat": -19.978055954,
      "lon": -50.968334198,
      "tz": "America\/Sao_Paulo"
  },
  "SNVP": {
      "icao": "SNVP",
      "iata": "",
      "name": "Fazenda Aracatuba Airport",
      "city": "Santa Fe",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 541,
      "lat": -6.9769439697,
      "lon": -49.1994438171,
      "tz": "America\/Araguaina"
  },
  "SNVQ": {
      "icao": "SNVQ",
      "iata": "",
      "name": "Fazenda Santa Monica Airport",
      "city": "Piraque",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 600,
      "lat": -6.5230560303,
      "lon": -48.2658348083,
      "tz": "America\/Araguaina"
  },
  "SNVR": {
      "icao": "SNVR",
      "iata": "",
      "name": "Aeroclube da Bahia Airport",
      "city": "Vera Cruz",
      "state": "Bahia",
      "country": "BR",
      "elevation": 12,
      "lat": -13.0247001648,
      "lon": -38.6657981873,
      "tz": "America\/Bahia"
  },
  "SNVS": {
      "icao": "SNVS",
      "iata": "BVS",
      "name": "Breves Airport",
      "city": "Breves",
      "state": "Para",
      "country": "BR",
      "elevation": 98,
      "lat": -1.6365300417,
      "lon": -50.4435997009,
      "tz": "America\/Belem"
  },
  "SNVU": {
      "icao": "SNVU",
      "iata": "",
      "name": "Fazenda Itatuba Airport",
      "city": "Cassia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2395,
      "lat": -20.4308338165,
      "lon": -46.8600006104,
      "tz": "America\/Sao_Paulo"
  },
  "SNVV": {
      "icao": "SNVV",
      "iata": "",
      "name": "Valente Airport",
      "city": "Valente",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1322,
      "lat": -11.411110878,
      "lon": -39.4433326721,
      "tz": "America\/Bahia"
  },
  "SNVW": {
      "icao": "SNVW",
      "iata": "",
      "name": "Fazenda Sao Joao do Guapore Airport",
      "city": "Nova Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 574,
      "lat": -13.9977779388,
      "lon": -60.0741653442,
      "tz": "America\/Cuiaba"
  },
  "SNVY": {
      "icao": "SNVY",
      "iata": "",
      "name": "Fazenda Ouro Verde Airport",
      "city": "Paranhos",
      "state": "Canindeyu",
      "country": "BR",
      "elevation": 1299,
      "lat": -23.9769439697,
      "lon": -55.3652763367,
      "tz": "America\/Asuncion"
  },
  "SNVZ": {
      "icao": "SNVZ",
      "iata": "",
      "name": "Varzea da Palma Airport",
      "city": "Varzea Da Palma",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1719,
      "lat": -17.6450004578,
      "lon": -44.7105560303,
      "tz": "America\/Sao_Paulo"
  },
  "SNWA": {
      "icao": "SNWA",
      "iata": "",
      "name": "Guaxuma Airport",
      "city": "Coruripe",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 322,
      "lat": -9.9805555344,
      "lon": -36.2311096191,
      "tz": "America\/Maceio"
  },
  "SNWB": {
      "icao": "SNWB",
      "iata": "",
      "name": "Fazenda Sao Lucas Airport",
      "city": "Mirandopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1099,
      "lat": -20.9322223663,
      "lon": -50.956943512,
      "tz": "America\/Sao_Paulo"
  },
  "SNWC": {
      "icao": "SNWC",
      "iata": "CMC",
      "name": "Camocim Airport",
      "city": "Camocim",
      "state": "Ceara",
      "country": "BR",
      "elevation": 16,
      "lat": -2.8961799145,
      "lon": -40.858001709,
      "tz": "America\/Fortaleza"
  },
  "SNWD": {
      "icao": "SNWD",
      "iata": "",
      "name": "Fazenda Guavira Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1335,
      "lat": -23.4302787781,
      "lon": -54.6083335876,
      "tz": "America\/Campo_Grande"
  },
  "SNWE": {
      "icao": "SNWE",
      "iata": "",
      "name": "Fazenda Baia da Bugra Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 507,
      "lat": -20.278055191,
      "lon": -57.2338905334,
      "tz": "America\/Campo_Grande"
  },
  "SNWF": {
      "icao": "SNWF",
      "iata": "",
      "name": "Fazenda Cachoeirinha Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1476,
      "lat": -22.4380569458,
      "lon": -55.2316665649,
      "tz": "America\/Campo_Grande"
  },
  "SNWG": {
      "icao": "SNWG",
      "iata": "",
      "name": "Fazenda Ipora Airport",
      "city": "Itaquirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1476,
      "lat": -23.5869445801,
      "lon": -54.0577774048,
      "tz": "America\/Campo_Grande"
  },
  "SNWH": {
      "icao": "SNWH",
      "iata": "",
      "name": "Fazenda Sao Mateus Airport",
      "city": "Querencia Do Norte",
      "state": "Parana",
      "country": "BR",
      "elevation": 1050,
      "lat": -22.9666671753,
      "lon": -53.5433311462,
      "tz": "America\/Sao_Paulo"
  },
  "SNWI": {
      "icao": "SNWI",
      "iata": "",
      "name": "Fazenda Sao Joao II Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 594,
      "lat": -14.0688886642,
      "lon": -60.0091667175,
      "tz": "America\/Cuiaba"
  },
  "SNWJ": {
      "icao": "SNWJ",
      "iata": "",
      "name": "Fazenda Rancho Alegre Airport",
      "city": "Araputanga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1345,
      "lat": -15.2080564499,
      "lon": -58.6247215271,
      "tz": "America\/Cuiaba"
  },
  "SNWK": {
      "icao": "SNWK",
      "iata": "",
      "name": "Fazenda Santa Terezinha Airport",
      "city": "Pedra Preta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 722,
      "lat": -16.6744441986,
      "lon": -54.2663879395,
      "tz": "America\/Cuiaba"
  },
  "SNWL": {
      "icao": "SNWL",
      "iata": "",
      "name": "Fazenda Ranchinho Airport",
      "city": "Apore",
      "state": "Goias",
      "country": "BR",
      "elevation": 1788,
      "lat": -18.8333320618,
      "lon": -51.7911109924,
      "tz": "America\/Sao_Paulo"
  },
  "SNWM": {
      "icao": "SNWM",
      "iata": "",
      "name": "Fazenda Bonito Airport",
      "city": "Ilha Solteira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1181,
      "lat": -20.3827781677,
      "lon": -51.1955566406,
      "tz": "America\/Sao_Paulo"
  },
  "SNWN": {
      "icao": "SNWN",
      "iata": "",
      "name": "Fazenda Alianca Airport",
      "city": "Pedra Preta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 942,
      "lat": -16.668056488,
      "lon": -54.3858337402,
      "tz": "America\/Cuiaba"
  },
  "SNWO": {
      "icao": "SNWO",
      "iata": "",
      "name": "Fazenda Vera Cruz Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1001,
      "lat": -23.0177783966,
      "lon": -54.6244430542,
      "tz": "America\/Campo_Grande"
  },
  "SNWP": {
      "icao": "SNWP",
      "iata": "",
      "name": "Laginha Airport",
      "city": "Uniao Dos Palmares",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 262,
      "lat": -9.3138885498,
      "lon": -36.056388855,
      "tz": "America\/Maceio"
  },
  "SNWR": {
      "icao": "SNWR",
      "iata": "",
      "name": "Wilma Rabelo Airport",
      "city": "Senador Jose Porfirio",
      "state": "Para",
      "country": "BR",
      "elevation": 201,
      "lat": -2.636590004,
      "lon": -51.8269996643,
      "tz": "America\/Belem"
  },
  "SNWS": {
      "icao": "SNWS",
      "iata": "",
      "name": "Crateus Airport",
      "city": "Crateus",
      "state": "Ceara",
      "country": "BR",
      "elevation": 1034,
      "lat": -5.2113499641,
      "lon": -40.7042007446,
      "tz": "America\/Fortaleza"
  },
  "SNWU": {
      "icao": "SNWU",
      "iata": "",
      "name": "Fazenda Ponte Quinha Airport",
      "city": "Aral Moreira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1575,
      "lat": -23.0086116791,
      "lon": -55.5097236633,
      "tz": "America\/Campo_Grande"
  },
  "SNWZ": {
      "icao": "SNWZ",
      "iata": "",
      "name": "Uruba Airport",
      "city": "Atalaia",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 302,
      "lat": -9.4849996567,
      "lon": -35.9588890076,
      "tz": "America\/Maceio"
  },
  "SNXA": {
      "icao": "SNXA",
      "iata": "0",
      "name": "Machado Airport",
      "city": "Machado",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2969,
      "lat": -21.6781005859,
      "lon": -45.8410987854,
      "tz": "America\/Sao_Paulo"
  },
  "SNXB": {
      "icao": "SNXB",
      "iata": "",
      "name": "Caxambu Airport",
      "city": "Caxambu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2838,
      "lat": -21.9169998169,
      "lon": -44.9682006836,
      "tz": "America\/Sao_Paulo"
  },
  "SNXD": {
      "icao": "SNXD",
      "iata": "",
      "name": "Fazenda Beradao Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 764,
      "lat": -6.2727780342,
      "lon": -55.7858314514,
      "tz": "America\/Santarem"
  },
  "SNXF": {
      "icao": "SNXF",
      "iata": "",
      "name": "Retiro Santo Antonio da Fazenda Triunfo Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 394,
      "lat": -17.8924999237,
      "lon": -56.9975013733,
      "tz": "America\/Campo_Grande"
  },
  "SNXG": {
      "icao": "SNXG",
      "iata": "",
      "name": "Fazenda dos Castanhais Airport",
      "city": "Sapucaia",
      "state": "Para",
      "country": "BR",
      "elevation": 745,
      "lat": -6.7748699188,
      "lon": -49.3613014221,
      "tz": "America\/Belem"
  },
  "SNXH": {
      "icao": "SNXH",
      "iata": "",
      "name": "Itapecuru Airport",
      "city": "Codo",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 184,
      "lat": -4.5130558014,
      "lon": -44.0200004578,
      "tz": "America\/Fortaleza"
  },
  "SNXI": {
      "icao": "SNXI",
      "iata": "",
      "name": "Estancia Turistica de Pereira Barreto Airport",
      "city": "Pereira Barreto",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1289,
      "lat": -20.6247215271,
      "lon": -51.0849990845,
      "tz": "America\/Sao_Paulo"
  },
  "SNXK": {
      "icao": "SNXK",
      "iata": "",
      "name": "Fazenda Lagoa do Triunfo Airport",
      "city": "Sao Felix Do Xingu",
      "state": "Para",
      "country": "BR",
      "elevation": 558,
      "lat": -6.5369439125,
      "lon": -52.7691688538,
      "tz": "America\/Santarem"
  },
  "SNXL": {
      "icao": "SNXL",
      "iata": "",
      "name": "Primavera Airport",
      "city": "Querencia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1201,
      "lat": -12.6166667938,
      "lon": -52.1538887024,
      "tz": "America\/Cuiaba"
  },
  "SNXM": {
      "icao": "SNXM",
      "iata": "",
      "name": "Fazenda Itumbiara Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 886,
      "lat": -6.4202780724,
      "lon": -53.6113891602,
      "tz": "America\/Santarem"
  },
  "SNXN": {
      "icao": "SNXN",
      "iata": "",
      "name": "Heringer Airport",
      "city": "Balsas",
      "state": "Sergipe",
      "country": "BR",
      "elevation": 1021,
      "lat": -7.3752779961,
      "lon": -45.9897232056,
      "tz": "America\/Fortaleza"
  },
  "SNXO": {
      "icao": "SNXO",
      "iata": "",
      "name": "Fazenda Bom Sucesso Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 600,
      "lat": -7.8936109543,
      "lon": -56.3875007629,
      "tz": "America\/Santarem"
  },
  "SNXQ": {
      "icao": "SNXQ",
      "iata": "",
      "name": "Xique-Xique Airport",
      "city": "Xique-Xique",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1384,
      "lat": -10.8340997696,
      "lon": -42.6833992004,
      "tz": "America\/Bahia"
  },
  "SNXR": {
      "icao": "SNXR",
      "iata": "",
      "name": "Fazenda Conforto Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 771,
      "lat": -7.3933329582,
      "lon": -56.5230560303,
      "tz": "America\/Santarem"
  },
  "SNXS": {
      "icao": "SNXS",
      "iata": "QXC",
      "name": "Fazenda Sao Braz Airport",
      "city": "Barra De Santo Antonio",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 164,
      "lat": -9.3752775192,
      "lon": -35.5416679382,
      "tz": "America\/Maceio"
  },
  "SNXV": {
      "icao": "SNXV",
      "iata": "",
      "name": "Fazenda Lagoa das Conchas Airport",
      "city": "Colniza",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 348,
      "lat": -9.0872220993,
      "lon": -61.1869430542,
      "tz": "America\/Cuiaba"
  },
  "SNXW": {
      "icao": "SNXW",
      "iata": "",
      "name": "Chaves Airport",
      "city": "Chaves",
      "state": "Para",
      "country": "BR",
      "elevation": 20,
      "lat": -0.200277999,
      "lon": -49.9672203064,
      "tz": "America\/Belem"
  },
  "SNXX": {
      "icao": "SNXX",
      "iata": "",
      "name": "Maxaranguape Airport",
      "city": "Maxaranguape",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 302,
      "lat": -5.3841400146,
      "lon": -35.5284004211,
      "tz": "America\/Fortaleza"
  },
  "SNXY": {
      "icao": "SNXY",
      "iata": "",
      "name": "Cikel Brasil Verde Airport",
      "city": "Portel",
      "state": "Para",
      "country": "BR",
      "elevation": 98,
      "lat": -2.7002780437,
      "lon": -50.5286102295,
      "tz": "America\/Belem"
  },
  "SNYA": {
      "icao": "SNYA",
      "iata": "GGF",
      "name": "Almeirim Airport",
      "city": "Almeirim",
      "state": "Para",
      "country": "BR",
      "elevation": 584,
      "lat": -1.4919439554,
      "lon": -52.5783348083,
      "tz": "America\/Santarem"
  },
  "SNYB": {
      "icao": "SNYB",
      "iata": "",
      "name": "Ituiutaba Airport",
      "city": "Ituiutaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1985,
      "lat": -18.9997997284,
      "lon": -49.4866981506,
      "tz": "America\/Sao_Paulo"
  },
  "SNYC": {
      "icao": "SNYC",
      "iata": "",
      "name": "Rosa Branca Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 552,
      "lat": -7.0091671944,
      "lon": -56.9044456482,
      "tz": "America\/Santarem"
  },
  "SNYD": {
      "icao": "SNYD",
      "iata": "",
      "name": "Pilao Arcado Airport",
      "city": "Pilao Arcado",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1312,
      "lat": -10.0669441223,
      "lon": -42.4505577087,
      "tz": "America\/Bahia"
  },
  "SNYE": {
      "icao": "SNYE",
      "iata": "PHI",
      "name": "Pinheiro Airport",
      "city": "Pinheiro",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 125,
      "lat": -2.4836111069,
      "lon": -45.0672225952,
      "tz": "America\/Fortaleza"
  },
  "SNYF": {
      "icao": "SNYF",
      "iata": "",
      "name": "Fazenda Parnaiba Airport",
      "city": "Tasso Fragoso",
      "state": "",
      "country": "BR",
      "elevation": 1854,
      "lat": -8.5055561066,
      "lon": -46.1219444275,
      "tz": "America\/Fortaleza"
  },
  "SNYH": {
      "icao": "SNYH",
      "iata": "ITI",
      "name": "Agropecuaria Castanhais Airport",
      "city": "Cumaru Do Norte",
      "state": "Para",
      "country": "BR",
      "elevation": 1096,
      "lat": -8.6999998093,
      "lon": -51.1741676331,
      "tz": "America\/Belem"
  },
  "SNYI": {
      "icao": "SNYI",
      "iata": "",
      "name": "Fazenda HJ Airport",
      "city": "Unai",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3113,
      "lat": -16.6236114502,
      "lon": -47.2997207642,
      "tz": "America\/Sao_Paulo"
  },
  "SNYJ": {
      "icao": "SNYJ",
      "iata": "",
      "name": "Pista Sao Jorge Airport",
      "city": "Jacareacanga",
      "state": "Para",
      "country": "BR",
      "elevation": 810,
      "lat": -6.1336112022,
      "lon": -57.4041671753,
      "tz": "America\/Santarem"
  },
  "SNYL": {
      "icao": "SNYL",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Varjao De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3300,
      "lat": -18.4269447327,
      "lon": -46.0519447327,
      "tz": "America\/Sao_Paulo"
  },
  "SNYM": {
      "icao": "SNYM",
      "iata": "",
      "name": "Fazenda Flexas Airport",
      "city": "Sao Romao",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1594,
      "lat": -16.3763885498,
      "lon": -45.1230545044,
      "tz": "America\/Sao_Paulo"
  },
  "SNYN": {
      "icao": "SNYN",
      "iata": "",
      "name": "Fazenda Rio das Pedras Airport",
      "city": "Prata",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2300,
      "lat": -19.5516662598,
      "lon": -48.8375015259,
      "tz": "America\/Sao_Paulo"
  },
  "SNYO": {
      "icao": "SNYO",
      "iata": "",
      "name": "Fazenda Logradouro Vereda da Ponte Airport",
      "city": "Riachinho",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2848,
      "lat": -16.2991676331,
      "lon": -46.1594429016,
      "tz": "America\/Sao_Paulo"
  },
  "SNYP": {
      "icao": "SNYP",
      "iata": "",
      "name": "Chacara Paraiso Airport",
      "city": "Benevides",
      "state": "Para",
      "country": "BR",
      "elevation": 69,
      "lat": -1.3177779913,
      "lon": -48.2988891602,
      "tz": "America\/Belem"
  },
  "SNYQ": {
      "icao": "SNYQ",
      "iata": "",
      "name": "Pista Sao Pedro Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 459,
      "lat": -5.3205561638,
      "lon": -56.6647224426,
      "tz": "America\/Santarem"
  },
  "SNYR": {
      "icao": "SNYR",
      "iata": "",
      "name": "Fazenda Agua Azul Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 518,
      "lat": -5.947778225,
      "lon": -56.5547218323,
      "tz": "America\/Santarem"
  },
  "SNYS": {
      "icao": "SNYS",
      "iata": "",
      "name": "Destilaria WD Airport",
      "city": "Joao Pinheiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3199,
      "lat": -18.2205562592,
      "lon": -45.9911117554,
      "tz": "America\/Sao_Paulo"
  },
  "SNYT": {
      "icao": "SNYT",
      "iata": "",
      "name": "Ituacu Airport",
      "city": "Ituacu",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1804,
      "lat": -13.8283004761,
      "lon": -41.3022994995,
      "tz": "America\/Bahia"
  },
  "SNYU": {
      "icao": "SNYU",
      "iata": "",
      "name": "Iturama Airport",
      "city": "Iturama",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1558,
      "lat": -19.7179898013,
      "lon": -50.2201795578,
      "tz": "America\/Sao_Paulo"
  },
  "SNYV": {
      "icao": "SNYV",
      "iata": "",
      "name": "Fazenda Sococo Airport",
      "city": "Moju",
      "state": "Para",
      "country": "BR",
      "elevation": 102,
      "lat": -2.1197218895,
      "lon": -48.6458320618,
      "tz": "America\/Belem"
  },
  "SNYW": {
      "icao": "SNYW",
      "iata": "",
      "name": "Antonio Guerreiro Airport",
      "city": "Guimaraes",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 245,
      "lat": -2.109443903,
      "lon": -44.6511116028,
      "tz": "America\/Fortaleza"
  },
  "SNYX": {
      "icao": "SNYX",
      "iata": "",
      "name": "Sitio Vodo Airport",
      "city": "Cascavel",
      "state": "Ceara",
      "country": "BR",
      "elevation": 249,
      "lat": -4.0949997902,
      "lon": -38.2475013733,
      "tz": "America\/Fortaleza"
  },
  "SNYZ": {
      "icao": "SNYZ",
      "iata": "",
      "name": "Vicente Jose Airport",
      "city": "Russas",
      "state": "Ceara",
      "country": "BR",
      "elevation": 115,
      "lat": -4.8430562019,
      "lon": -38.144443512,
      "tz": "America\/Fortaleza"
  },
  "SNZA": {
      "icao": "SNZA",
      "iata": "PPY",
      "name": "Pouso Alegre Airport",
      "city": "Pouso Alegre",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2904,
      "lat": -22.2891998291,
      "lon": -45.9191017151,
      "tz": "America\/Sao_Paulo"
  },
  "SNZC": {
      "icao": "SNZC",
      "iata": "",
      "name": "Fazenda Aguas Claras Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1220,
      "lat": -23.3250007629,
      "lon": -54.5047225952,
      "tz": "America\/Campo_Grande"
  },
  "SNZD": {
      "icao": "SNZD",
      "iata": "",
      "name": "Fazenda Dona Hilda Airport",
      "city": "Montividiu Do Norte",
      "state": "Goias",
      "country": "BR",
      "elevation": 1424,
      "lat": -13.204167366,
      "lon": -48.6008338928,
      "tz": "America\/Araguaina"
  },
  "SNZE": {
      "icao": "SNZE",
      "iata": "",
      "name": "Aeroxingu Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 369,
      "lat": -3.171943903,
      "lon": -52.1916656494,
      "tz": "America\/Santarem"
  },
  "SNZF": {
      "icao": "SNZF",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Soure",
      "state": "Para",
      "country": "BR",
      "elevation": 66,
      "lat": -0.2713890076,
      "lon": -48.5750007629,
      "tz": "America\/Belem"
  },
  "SNZH": {
      "icao": "SNZH",
      "iata": "",
      "name": "Fazenda Pequena Holanda Airport",
      "city": "Alto Parnaiba",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 2835,
      "lat": -9.0100002289,
      "lon": -46.1447219849,
      "tz": "America\/Fortaleza"
  },
  "SNZK": {
      "icao": "SNZK",
      "iata": "",
      "name": "Fazenda Canada Airport",
      "city": "Itacarambi",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1510,
      "lat": -15.0242996216,
      "lon": -44.039100647,
      "tz": "America\/Sao_Paulo"
  },
  "SNZL": {
      "icao": "SNZL",
      "iata": "",
      "name": "Fazenda Santa Thereza Airport",
      "city": "Novo Horizonte",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1401,
      "lat": -21.5713882446,
      "lon": -49.3008346558,
      "tz": "America\/Sao_Paulo"
  },
  "SNZO": {
      "icao": "SNZO",
      "iata": "",
      "name": "Fazenda Bebida Velha Airport",
      "city": "Touros",
      "state": "Rio-Grande-do-Norte",
      "country": "BR",
      "elevation": 131,
      "lat": -5.2969441414,
      "lon": -35.5366668701,
      "tz": "America\/Fortaleza"
  },
  "SNZP": {
      "icao": "SNZP",
      "iata": "",
      "name": "Pocoes Airport",
      "city": "Pocoes",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2772,
      "lat": -14.522600174,
      "lon": -40.3450012207,
      "tz": "America\/Bahia"
  },
  "SNZQ": {
      "icao": "SNZQ",
      "iata": "",
      "name": "Pista Santa Lucia Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 1968,
      "lat": -7.2286109924,
      "lon": -56.2327766418,
      "tz": "America\/Santarem"
  },
  "SNZR": {
      "icao": "SNZR",
      "iata": "",
      "name": "Paracatu Airport",
      "city": "Paracatu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2359,
      "lat": -17.2425994873,
      "lon": -46.8830986023,
      "tz": "America\/Sao_Paulo"
  },
  "SNZT": {
      "icao": "SNZT",
      "iata": "",
      "name": "Fazenda Recreio Airport",
      "city": "Paragominas",
      "state": "Para",
      "country": "BR",
      "elevation": 499,
      "lat": -3.5063889027,
      "lon": -48.3333320618,
      "tz": "America\/Belem"
  },
  "SNZU": {
      "icao": "SNZU",
      "iata": "",
      "name": "Fazenda Nova Conceicao Airport",
      "city": "Tome Acu",
      "state": "Para",
      "country": "BR",
      "elevation": 243,
      "lat": -2.8555555344,
      "lon": -48.2327766418,
      "tz": "America\/Belem"
  },
  "SNZV": {
      "icao": "SNZV",
      "iata": "",
      "name": "Fazenda Jamaica Airport",
      "city": "Ulianopolis",
      "state": "Para",
      "country": "BR",
      "elevation": 361,
      "lat": -3.7255558968,
      "lon": -47.4761123657,
      "tz": "America\/Belem"
  },
  "SNZW": {
      "icao": "SNZW",
      "iata": "ITE",
      "name": "Itubera Airport",
      "city": "Itubera",
      "state": "Bahia",
      "country": "BR",
      "elevation": 13,
      "lat": -13.7322216034,
      "lon": -39.1416664124,
      "tz": "America\/Bahia"
  },
  "SNZY": {
      "icao": "SNZY",
      "iata": "",
      "name": "Fazenda Bandeira Airport",
      "city": "Tupa",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1450,
      "lat": -22.0005569458,
      "lon": -50.5977783203,
      "tz": "America\/Sao_Paulo"
  },
  "SNZZ": {
      "icao": "SNZZ",
      "iata": "",
      "name": "Muzzi Airport",
      "city": "Coruripe",
      "state": "Alagoas",
      "country": "BR",
      "elevation": 331,
      "lat": -9.9677782059,
      "lon": -36.198890686,
      "tz": "America\/Maceio"
  },
  "SOCA": {
      "icao": "SOCA",
      "iata": "CAY",
      "name": "Cayenne-Rochambeau Airport",
      "city": "Cayenne \/ Rochambeau",
      "state": "Guyane",
      "country": "GF",
      "elevation": 26,
      "lat": 4.8198099136,
      "lon": -52.3604011536,
      "tz": "America\/Cayenne"
  },
  "SOGS": {
      "icao": "SOGS",
      "iata": "GSI",
      "name": "Grand-Santi Airport",
      "city": "Grand-Santi",
      "state": "Guyane",
      "country": "GF",
      "elevation": 207,
      "lat": 4.2858333333,
      "lon": -54.3730555556,
      "tz": "America\/Cayenne"
  },
  "SOML": {
      "icao": "SOML",
      "iata": "",
      "name": "Malin Airport",
      "city": "Malin",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 5190,
      "lat": -7.6333298683,
      "lon": -78.6500015259,
      "tz": "America\/Lima"
  },
  "SOOA": {
      "icao": "SOOA",
      "iata": "MPY",
      "name": "Maripasoula Airport",
      "city": "Maripasoula",
      "state": "Guyane",
      "country": "GF",
      "elevation": 406,
      "lat": 3.6575000286,
      "lon": -54.0372009277,
      "tz": "America\/Cayenne"
  },
  "SOOG": {
      "icao": "SOOG",
      "iata": "OXP",
      "name": "Saint-Georges-de-l'Oyapock Airport",
      "city": "Saint-Georges-de-l'Oyapock Airport",
      "state": "Guyane",
      "country": "GF",
      "elevation": 46,
      "lat": 3.8975999355,
      "lon": -51.8041000366,
      "tz": "America\/Belem"
  },
  "SOOM": {
      "icao": "SOOM",
      "iata": "LDX",
      "name": "Saint-Laurent-du-Maroni Airport",
      "city": "Saint-Laurent-du-Maroni",
      "state": "Guyane",
      "country": "GF",
      "elevation": 16,
      "lat": 5.4830598831,
      "lon": -54.0344009399,
      "tz": "America\/Cayenne"
  },
  "SOOR": {
      "icao": "SOOR",
      "iata": "REI",
      "name": "Regina Airport",
      "city": "Regina",
      "state": "Guyane",
      "country": "GF",
      "elevation": 82,
      "lat": 4.3147201538,
      "lon": -52.1316986084,
      "tz": "America\/Cayenne"
  },
  "SOOS": {
      "icao": "SOOS",
      "iata": "XAU",
      "name": "Saul Airport",
      "city": "Saul",
      "state": "Guyane",
      "country": "GF",
      "elevation": 656,
      "lat": 3.6136100292,
      "lon": -53.2042007446,
      "tz": "America\/Cayenne"
  },
  "SOOY": {
      "icao": "SOOY",
      "iata": "",
      "name": "Sinnamary",
      "city": "Sinnamary",
      "state": "Guyane",
      "country": "GF",
      "elevation": 33,
      "lat": 5.3735799789,
      "lon": -52.9455986023,
      "tz": "America\/Cayenne"
  },
  "SPAA": {
      "icao": "SPAA",
      "iata": "",
      "name": "Caraz Airport",
      "city": "Ancash",
      "state": "Ancash",
      "country": "PE",
      "elevation": 7401,
      "lat": -9.0514287949,
      "lon": -77.8101425171,
      "tz": "America\/Lima"
  },
  "SPAB": {
      "icao": "SPAB",
      "iata": "",
      "name": "Huancabamba Airport",
      "city": "Huancabamba",
      "state": "Piura",
      "country": "PE",
      "elevation": 6312,
      "lat": -5.256770134,
      "lon": -79.4429016113,
      "tz": "America\/Lima"
  },
  "SPAC": {
      "icao": "SPAC",
      "iata": "",
      "name": "Ciro Alegria Airport",
      "city": "Bagua",
      "state": "Amazonas",
      "country": "PE",
      "elevation": 547,
      "lat": -4.5669999123,
      "lon": -77.9169998169,
      "tz": "America\/Lima"
  },
  "SPAG": {
      "icao": "SPAG",
      "iata": "",
      "name": "Aguaytia Airport",
      "city": "Aguaytia",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 5853,
      "lat": -9.0374097824,
      "lon": -75.5092010498,
      "tz": "America\/Lima"
  },
  "SPAI": {
      "icao": "SPAI",
      "iata": "",
      "name": "Urpay Airport",
      "city": "Pataz",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 10400,
      "lat": -7.7833333015,
      "lon": -78.0999984741,
      "tz": "America\/Lima"
  },
  "SPAL": {
      "icao": "SPAL",
      "iata": "",
      "name": "Alao Airport",
      "city": "El Dorado",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 1740,
      "lat": -6.5,
      "lon": -76.7166976929,
      "tz": "America\/Lima"
  },
  "SPAM": {
      "icao": "SPAM",
      "iata": "",
      "name": "Camana Airport",
      "city": "Camana",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 209,
      "lat": -16.6329994202,
      "lon": -72.6829986572,
      "tz": "America\/Lima"
  },
  "SPAN": {
      "icao": "SPAN",
      "iata": "",
      "name": "Sullana Airport",
      "city": "Sullana",
      "state": "Piura",
      "country": "PE",
      "elevation": 197,
      "lat": -4.9038887024,
      "lon": -80.6852798462,
      "tz": "America\/Lima"
  },
  "SPAO": {
      "icao": "SPAO",
      "iata": "APE",
      "name": "San Juan Aposento Airport",
      "city": "San Juan Aposento",
      "state": "Ica",
      "country": "PE",
      "elevation": 23,
      "lat": -15.3538999557,
      "lon": -75.167098999,
      "tz": "America\/Lima"
  },
  "SPAP": {
      "icao": "SPAP",
      "iata": "",
      "name": "Picota Airport",
      "city": "Picota",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 948,
      "lat": -6.9000000954,
      "lon": -76.3830032349,
      "tz": "America\/Lima"
  },
  "SPAR": {
      "icao": "SPAR",
      "iata": "ALD",
      "name": "Alerta Airport",
      "city": "Fortaleza",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 797,
      "lat": -11.6829996109,
      "lon": -69.3330001831,
      "tz": "America\/Lima"
  },
  "SPAS": {
      "icao": "SPAS",
      "iata": "AOP",
      "name": "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
      "city": "Andoas",
      "state": "Loreto",
      "country": "PE",
      "elevation": 728,
      "lat": -2.7961299419,
      "lon": -76.4665985107,
      "tz": "America\/Lima"
  },
  "SPAT": {
      "icao": "SPAT",
      "iata": "",
      "name": "Aguas Calientes Airport",
      "city": "Aguas Calientes",
      "state": "Huanuco",
      "country": "PE",
      "elevation": 9840,
      "lat": -8.8333301544,
      "lon": -74.6832962036,
      "tz": "America\/Lima"
  },
  "SPAY": {
      "icao": "SPAY",
      "iata": "ATG",
      "name": "Teniente General Gerardo Perez Pinedo Airport",
      "city": "Atalaya",
      "state": "Junin",
      "country": "PE",
      "elevation": 751,
      "lat": -10.7291002274,
      "lon": -73.7665023804,
      "tz": "America\/Lima"
  },
  "SPBA": {
      "icao": "SPBA",
      "iata": "",
      "name": "Barranca Airport",
      "city": "Barranca",
      "state": "Lima-region",
      "country": "PE",
      "elevation": 160,
      "lat": -10.75,
      "lon": -77.7666702271,
      "tz": "America\/Lima"
  },
  "SPBB": {
      "icao": "SPBB",
      "iata": "MBP",
      "name": "Moyobamba Airport",
      "city": "Moyobamba",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 2749,
      "lat": -6.0188889503,
      "lon": -76.9883270264,
      "tz": "America\/Lima"
  },
  "SPBC": {
      "icao": "SPBC",
      "iata": "",
      "name": "Caballococha Airport",
      "city": "Caballococha",
      "state": "Loreto",
      "country": "PE",
      "elevation": 328,
      "lat": -3.9168601036,
      "lon": -70.5082015991,
      "tz": "America\/Lima"
  },
  "SPBL": {
      "icao": "SPBL",
      "iata": "BLP",
      "name": "Huallaga Airport",
      "city": "Bellavista",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 980,
      "lat": -7.0605602264,
      "lon": -76.5821990967,
      "tz": "America\/Lima"
  },
  "SPBQ": {
      "icao": "SPBQ",
      "iata": "",
      "name": "Nuevo Acari Airport",
      "city": "La Bella Union",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 590,
      "lat": -15.4833335876,
      "lon": -74.6833343506,
      "tz": "America\/Lima"
  },
  "SPBR": {
      "icao": "SPBR",
      "iata": "IBP",
      "name": "Iberia Airport",
      "city": "Iberia",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 750,
      "lat": -11.4116001129,
      "lon": -69.4887008667,
      "tz": "America\/Lima"
  },
  "SPBS": {
      "icao": "SPBS",
      "iata": "",
      "name": "Bellavista Airport",
      "city": "Jeberos",
      "state": "Loreto",
      "country": "PE",
      "elevation": 675,
      "lat": -5.2883901596,
      "lon": -76.292098999,
      "tz": "America\/Lima"
  },
  "SPBT": {
      "icao": "SPBT",
      "iata": "",
      "name": "Obenteni Airport",
      "city": "Obenteni",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 3290,
      "lat": -10.7477779388,
      "lon": -74.2205581665,
      "tz": "America\/Lima"
  },
  "SPBY": {
      "icao": "SPBY",
      "iata": "",
      "name": "Bayovar",
      "city": "Bayovar",
      "state": "Piura",
      "country": "PE",
      "elevation": 88,
      "lat": -5.8307499886,
      "lon": -81.0258026123,
      "tz": "America\/Lima"
  },
  "SPCC": {
      "icao": "SPCC",
      "iata": "",
      "name": "Ciudad Constitucion Airport",
      "city": "Ciudad Constitucion",
      "state": "Pasco",
      "country": "PE",
      "elevation": 775,
      "lat": -9.865278244,
      "lon": -75.0088882446,
      "tz": "America\/Lima"
  },
  "SPCG": {
      "icao": "SPCG",
      "iata": "",
      "name": "Casa Grande Airport",
      "city": "Ascope",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 475,
      "lat": -7.743888855,
      "lon": -79.1863861084,
      "tz": "America\/Lima"
  },
  "SPCH": {
      "icao": "SPCH",
      "iata": "",
      "name": "Tocache Airport",
      "city": "Tocache",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 1500,
      "lat": -8.1829996109,
      "lon": -76.516998291,
      "tz": "America\/Lima"
  },
  "SPCL": {
      "icao": "SPCL",
      "iata": "PCL",
      "name": "Cap FAP David Abenzur Rengifo International Airport",
      "city": "Pucallpa",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 513,
      "lat": -8.3779401779,
      "lon": -74.5743026733,
      "tz": "America\/Lima"
  },
  "SPCM": {
      "icao": "SPCM",
      "iata": "",
      "name": "Contamana Airport",
      "city": "Contamana",
      "state": "Loreto",
      "country": "PE",
      "elevation": 480,
      "lat": -7.3366699219,
      "lon": -74.9914016724,
      "tz": "America\/Lima"
  },
  "SPCN": {
      "icao": "SPCN",
      "iata": "",
      "name": "Cunocuno Airport",
      "city": "Cunocuno",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 5300,
      "lat": -15.9772224426,
      "lon": -73.1083297729,
      "tz": "America\/Lima"
  },
  "SPCP": {
      "icao": "SPCP",
      "iata": "",
      "name": "Pucacaca Airport",
      "city": "Pucacaca",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 977,
      "lat": -6.8330001831,
      "lon": -76.3330001831,
      "tz": "America\/Lima"
  },
  "SPCR": {
      "icao": "SPCR",
      "iata": "",
      "name": "Acari Airbase Airport",
      "city": "Acari",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 540,
      "lat": -15.5,
      "lon": -74.6666717529,
      "tz": "America\/Lima"
  },
  "SPCT": {
      "icao": "SPCT",
      "iata": "",
      "name": "Chota Airport",
      "city": "Chota",
      "state": "Cajamarca",
      "country": "PE",
      "elevation": 7800,
      "lat": -6.5500001907,
      "lon": -78.6500015259,
      "tz": "America\/Lima"
  },
  "SPDO": {
      "icao": "SPDO",
      "iata": "",
      "name": "Mollendo Airport",
      "city": "Mollendo",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 9,
      "lat": -17.0454006195,
      "lon": -71.9837036133,
      "tz": "America\/Lima"
  },
  "SPDR": {
      "icao": "SPDR",
      "iata": "TDP",
      "name": "Trompeteros Airport",
      "city": "Corrientes",
      "state": "Loreto",
      "country": "PE",
      "elevation": 427,
      "lat": -3.8060100079,
      "lon": -75.0392990112,
      "tz": "America\/Lima"
  },
  "SPEB": {
      "icao": "SPEB",
      "iata": "",
      "name": "Pebas Airport",
      "city": "Loreto",
      "state": "Loreto",
      "country": "PE",
      "elevation": 383,
      "lat": -3.3333001137,
      "lon": -71.8167037964,
      "tz": "America\/Lima"
  },
  "SPEE": {
      "icao": "SPEE",
      "iata": "",
      "name": "El Estrecho Airport",
      "city": "",
      "state": "Loreto",
      "country": "PE",
      "elevation": 421,
      "lat": -2.4540600777,
      "lon": -72.6706008911,
      "tz": "America\/Bogota"
  },
  "SPEN": {
      "icao": "SPEN",
      "iata": "",
      "name": "Iscozasin Airport",
      "city": "Iscozasin",
      "state": "Pasco",
      "country": "PE",
      "elevation": 898,
      "lat": -10.1829996109,
      "lon": -75.1500015259,
      "tz": "America\/Lima"
  },
  "SPEO": {
      "icao": "SPEO",
      "iata": "CHM",
      "name": "Teniente FAP Jaime A De Montreuil Morales Airport",
      "city": "Chimbote",
      "state": "Apurimac",
      "country": "PE",
      "elevation": 69,
      "lat": -9.1496095657,
      "lon": -78.5238037109,
      "tz": "America\/Lima"
  },
  "SPEP": {
      "icao": "SPEP",
      "iata": "",
      "name": "Puerto Esperanza Airport",
      "city": "Esperanza",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 725,
      "lat": -9.7681303024,
      "lon": -70.7064971924,
      "tz": "America\/Lima"
  },
  "SPEQ": {
      "icao": "SPEQ",
      "iata": "",
      "name": "Cesar Torke Podesta Airport",
      "city": "Moquegua",
      "state": "Moquegua",
      "country": "PE",
      "elevation": 4480,
      "lat": -17.1790008545,
      "lon": -70.9308013916,
      "tz": "America\/Lima"
  },
  "SPEZ": {
      "icao": "SPEZ",
      "iata": "",
      "name": "Puerto Bermudez Airport",
      "city": "Oxapampa",
      "state": "Loreto",
      "country": "PE",
      "elevation": 840,
      "lat": -10.299200058,
      "lon": -74.9372024536,
      "tz": "America\/Lima"
  },
  "SPFA": {
      "icao": "SPFA",
      "iata": "",
      "name": "Fausa Airport",
      "city": "Fausa",
      "state": "Cusco",
      "country": "PE",
      "elevation": 14809,
      "lat": -14.709400177,
      "lon": -71.7311019897,
      "tz": "America\/Lima"
  },
  "SPGB": {
      "icao": "SPGB",
      "iata": "",
      "name": "Galilea Airport",
      "city": "",
      "state": "Amazonas",
      "country": "PE",
      "elevation": 597,
      "lat": -4.0318799019,
      "lon": -77.7587966919,
      "tz": "America\/Lima"
  },
  "SPGM": {
      "icao": "SPGM",
      "iata": "TGI",
      "name": "Tingo Maria Airport",
      "city": "Tingo Maria",
      "state": "Huanuco",
      "country": "PE",
      "elevation": 2010,
      "lat": -9.1330003738,
      "lon": -75.9499969482,
      "tz": "America\/Lima"
  },
  "SPGP": {
      "icao": "SPGP",
      "iata": "",
      "name": "Gueppi\u00ad Airport",
      "city": "",
      "state": "Loreto",
      "country": "PE",
      "elevation": 680,
      "lat": -0.1190560013,
      "lon": -75.2479019165,
      "tz": "America\/Bogota"
  },
  "SPGS": {
      "icao": "SPGS",
      "iata": "",
      "name": "Lagunas Airport",
      "city": "Cajamarca",
      "state": "Cajamarca",
      "country": "PE",
      "elevation": 12675,
      "lat": -6.9000000954,
      "lon": -78.6333312988,
      "tz": "America\/Lima"
  },
  "SPGT": {
      "icao": "SPGT",
      "iata": "",
      "name": "Puerto Victoria Airport",
      "city": "Puerto Victoria",
      "state": "Pasco",
      "country": "PE",
      "elevation": 782,
      "lat": -9.9026098251,
      "lon": -74.9641036987,
      "tz": "America\/Lima"
  },
  "SPGU": {
      "icao": "SPGU",
      "iata": "",
      "name": "Bagua Airport",
      "city": "Bagua",
      "state": "Amazonas",
      "country": "PE",
      "elevation": 1375,
      "lat": -5.6347222328,
      "lon": -78.5305557251,
      "tz": "America\/Lima"
  },
  "SPHA": {
      "icao": "SPHA",
      "iata": "",
      "name": "Chincha Airport",
      "city": "Chincha",
      "state": "Ica",
      "country": "PE",
      "elevation": 640,
      "lat": -13.3706998825,
      "lon": -76.1128997803,
      "tz": "America\/Lima"
  },
  "SPHC": {
      "icao": "SPHC",
      "iata": "",
      "name": "Chala Airport",
      "city": "Puerto Chala",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 112,
      "lat": -15.8699998856,
      "lon": -74.2473983765,
      "tz": "America\/Lima"
  },
  "SPHI": {
      "icao": "SPHI",
      "iata": "CIX",
      "name": "Capitan FAP Jose A Quinones Gonzales International Airport",
      "city": "Chiclayo",
      "state": "Lambayeque",
      "country": "PE",
      "elevation": 97,
      "lat": -6.7874798775,
      "lon": -79.8281021118,
      "tz": "America\/Lima"
  },
  "SPHL": {
      "icao": "SPHL",
      "iata": "",
      "name": "Olmos Airport",
      "city": "<Olmos",
      "state": "Lambayeque",
      "country": "PE",
      "elevation": 585,
      "lat": -5.9847221375,
      "lon": -79.7452774048,
      "tz": "America\/Lima"
  },
  "SPHO": {
      "icao": "SPHO",
      "iata": "AYP",
      "name": "Coronel FAP Alfredo Mendivil Duarte Airport",
      "city": "Ayacucho",
      "state": "Ayacucho",
      "country": "PE",
      "elevation": 8917,
      "lat": -13.154800415,
      "lon": -74.2043991089,
      "tz": "America\/Lima"
  },
  "SPHU": {
      "icao": "SPHU",
      "iata": "",
      "name": "Huancayo Airport",
      "city": "Huancayo",
      "state": "Junin",
      "country": "PE",
      "elevation": 10800,
      "lat": -12.069899559,
      "lon": -75.1985015869,
      "tz": "America\/Lima"
  },
  "SPHV": {
      "icao": "SPHV",
      "iata": "",
      "name": "Huanuco Viejo Airport",
      "city": "Huanuco Viejo",
      "state": "Huanuco",
      "country": "PE",
      "elevation": 6320,
      "lat": -9.926320076,
      "lon": -76.2339019775,
      "tz": "America\/Lima"
  },
  "SPHY": {
      "icao": "SPHY",
      "iata": "ANS",
      "name": "Andahuaylas Airport",
      "city": "Andahuaylas",
      "state": "Apurimac",
      "country": "PE",
      "elevation": 11300,
      "lat": -13.7063999176,
      "lon": -73.350402832,
      "tz": "America\/Lima"
  },
  "SPHZ": {
      "icao": "SPHZ",
      "iata": "ATA",
      "name": "Comandante FAP German Arias Graziani Airport",
      "city": "Anta",
      "state": "Ancash",
      "country": "PE",
      "elevation": 9097,
      "lat": -9.3474397659,
      "lon": -77.5983963013,
      "tz": "America\/Lima"
  },
  "SPIA": {
      "icao": "SPIA",
      "iata": "",
      "name": "Ica Airport",
      "city": "Ica",
      "state": "Ica",
      "country": "PE",
      "elevation": 1500,
      "lat": -14.2000999451,
      "lon": -75.5738983154,
      "tz": "America\/Lima"
  },
  "SPIL": {
      "icao": "SPIL",
      "iata": "UMI",
      "name": "Quince Air Base",
      "city": "Quince Mil",
      "state": "Cusco",
      "country": "PE",
      "elevation": 2047,
      "lat": -13.233300209,
      "lon": -70.7533035278,
      "tz": "America\/Lima"
  },
  "SPIN": {
      "icao": "SPIN",
      "iata": "",
      "name": "Inapari Airport",
      "city": "Inapari",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 917,
      "lat": -10.9790000916,
      "lon": -69.5594024658,
      "tz": "America\/La_Paz"
  },
  "SPIP": {
      "icao": "SPIP",
      "iata": "",
      "name": "Satipo Airport",
      "city": "Satipo",
      "state": "Junin",
      "country": "PE",
      "elevation": 2099,
      "lat": -11.2829999924,
      "lon": -74.6330032349,
      "tz": "America\/Lima"
  },
  "SPIR": {
      "icao": "SPIR",
      "iata": "",
      "name": "Patria Airport",
      "city": "Pillcopata",
      "state": "Cusco",
      "country": "PE",
      "elevation": 2224,
      "lat": -13.0329999924,
      "lon": -71.266998291,
      "tz": "America\/Lima"
  },
  "SPIS": {
      "icao": "SPIS",
      "iata": "",
      "name": "Pias Airport",
      "city": "Pataz",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 8500,
      "lat": -7.87169981,
      "lon": -77.547203064,
      "tz": "America\/Lima"
  },
  "SPIT": {
      "icao": "SPIT",
      "iata": "",
      "name": "Paita Airport",
      "city": "Paita Wesr",
      "state": "Piura",
      "country": "PE",
      "elevation": 230,
      "lat": -5.0859498978,
      "lon": -81.1528015137,
      "tz": "America\/Lima"
  },
  "SPIY": {
      "icao": "SPIY",
      "iata": "",
      "name": "Yauri Airport",
      "city": "Yauri",
      "state": "Cusco",
      "country": "PE",
      "elevation": 12972,
      "lat": -14.8000001907,
      "lon": -71.4329986572,
      "tz": "America\/Lima"
  },
  "SPIZ": {
      "icao": "SPIZ",
      "iata": "UCZ",
      "name": "Uchiza Airport",
      "city": "Uchiza",
      "state": "Huanuco",
      "country": "PE",
      "elevation": 1965,
      "lat": -8.4670000076,
      "lon": -76.3499984741,
      "tz": "America\/Lima"
  },
  "SPJA": {
      "icao": "SPJA",
      "iata": "RIJ",
      "name": "Juan Simons Vela Airport",
      "city": "Rioja",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 2707,
      "lat": -6.0678601265,
      "lon": -77.1600036621,
      "tz": "America\/Lima"
  },
  "SPJB": {
      "icao": "SPJB",
      "iata": "",
      "name": "Pampa Grande Airport",
      "city": "Pampa Grande",
      "state": "Cajamarca",
      "country": "PE",
      "elevation": 8727,
      "lat": -7.5999999046,
      "lon": -78.0500030518,
      "tz": "America\/Lima"
  },
  "SPJC": {
      "icao": "SPJC",
      "iata": "LIM",
      "name": "Jorge Chavez International Airport",
      "city": "Lima",
      "state": "Callao",
      "country": "PE",
      "elevation": 113,
      "lat": -12.021900177,
      "lon": -77.1143035889,
      "tz": "America\/Lima"
  },
  "SPJE": {
      "icao": "SPJE",
      "iata": "JAE",
      "name": "Shumba Airport",
      "city": "Jaen",
      "state": "Cajamarca",
      "country": "PE",
      "elevation": 2477,
      "lat": -5.5924801826,
      "lon": -78.7740020752,
      "tz": "America\/Lima"
  },
  "SPJI": {
      "icao": "SPJI",
      "iata": "JJI",
      "name": "Juanjui Airport",
      "city": "Juanjui",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 1148,
      "lat": -7.1690998077,
      "lon": -76.7285995483,
      "tz": "America\/Lima"
  },
  "SPJJ": {
      "icao": "SPJJ",
      "iata": "JAU",
      "name": "Francisco Carle Airport",
      "city": "Jauja",
      "state": "Junin",
      "country": "PE",
      "elevation": 11034,
      "lat": -11.7831001282,
      "lon": -75.4733963013,
      "tz": "America\/Lima"
  },
  "SPJL": {
      "icao": "SPJL",
      "iata": "JUL",
      "name": "Inca Manco Capac International Airport",
      "city": "Juliaca",
      "state": "Puno",
      "country": "PE",
      "elevation": 12552,
      "lat": -15.4671001434,
      "lon": -70.158203125,
      "tz": "America\/Lima"
  },
  "SPJN": {
      "icao": "SPJN",
      "iata": "SJA",
      "name": "San Juan de Marcona Airport",
      "city": "San Juan de Marcona",
      "state": "Ica",
      "country": "PE",
      "elevation": 144,
      "lat": -15.3524999619,
      "lon": -75.1371994019,
      "tz": "America\/Lima"
  },
  "SPJR": {
      "icao": "SPJR",
      "iata": "CJA",
      "name": "Mayor General FAP Armando Revoredo Iglesias Airport",
      "city": "Cajamarca",
      "state": "Cajamarca",
      "country": "PE",
      "elevation": 8781,
      "lat": -7.1391801834,
      "lon": -78.489402771,
      "tz": "America\/Lima"
  },
  "SPLA": {
      "icao": "SPLA",
      "iata": "",
      "name": "Luisiana Airport",
      "city": "Luisiana",
      "state": "Cusco",
      "country": "PE",
      "elevation": 0,
      "lat": -12.6667003632,
      "lon": -73.7332992554,
      "tz": "America\/Lima"
  },
  "SPLC": {
      "icao": "SPLC",
      "iata": "",
      "name": "Mariano Melgar",
      "city": "La Joya",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 3890,
      "lat": -16.7915000916,
      "lon": -71.8865966797,
      "tz": "America\/Lima"
  },
  "SPLD": {
      "icao": "SPLD",
      "iata": "",
      "name": "Celendin Airport",
      "city": "Celendin",
      "state": "Cajamarca",
      "country": "PE",
      "elevation": 8600,
      "lat": -6.8675999641,
      "lon": -78.1499023438,
      "tz": "America\/Lima"
  },
  "SPLG": {
      "icao": "SPLG",
      "iata": "",
      "name": "Lagarto Nuevo Airport",
      "city": "Lagarto",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 763,
      "lat": -12.6415996552,
      "lon": -69.8375015259,
      "tz": "America\/Lima"
  },
  "SPLN": {
      "icao": "SPLN",
      "iata": "RIM",
      "name": "San Nicolas Airport",
      "city": "Rodriguez de Mendoza",
      "state": "Amazonas",
      "country": "PE",
      "elevation": 5082,
      "lat": -6.3923101425,
      "lon": -77.5011978149,
      "tz": "America\/Lima"
  },
  "SPLO": {
      "icao": "SPLO",
      "iata": "ILQ",
      "name": "Ilo Airport",
      "city": "Ilo",
      "state": "Moquegua",
      "country": "PE",
      "elevation": 72,
      "lat": -17.6949996948,
      "lon": -71.34400177,
      "tz": "America\/Lima"
  },
  "SPLP": {
      "icao": "SPLP",
      "iata": "",
      "name": "Las Palmas Airport",
      "city": "Chorrillos",
      "state": "Lima-region",
      "country": "PE",
      "elevation": 250,
      "lat": -12.1606998444,
      "lon": -76.9989013672,
      "tz": "America\/Lima"
  },
  "SPLS": {
      "icao": "SPLS",
      "iata": "",
      "name": "Zorrillos Airport",
      "city": "Zorrillos",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 711,
      "lat": -8.4169998169,
      "lon": -75.1330032349,
      "tz": "America\/Lima"
  },
  "SPLT": {
      "icao": "SPLT",
      "iata": "",
      "name": "Lobitos Airport",
      "city": "Lobitos",
      "state": "Piura",
      "country": "PE",
      "elevation": 45,
      "lat": -4.4486112595,
      "lon": -81.2755584717,
      "tz": "America\/Lima"
  },
  "SPME": {
      "icao": "SPME",
      "iata": "TBP",
      "name": "Capitan FAP Pedro Canga Rodriguez Airport",
      "city": "Tumbes",
      "state": "Tumbes",
      "country": "PE",
      "elevation": 115,
      "lat": -3.5525300503,
      "lon": -80.381401062,
      "tz": "America\/Lima"
  },
  "SPMF": {
      "icao": "SPMF",
      "iata": "MZA",
      "name": "Manuel Prado Ugarteche Airport",
      "city": "Mazamari",
      "state": "Junin",
      "country": "PE",
      "elevation": 2247,
      "lat": -11.3254003525,
      "lon": -74.5355987549,
      "tz": "America\/Lima"
  },
  "SPMR": {
      "icao": "SPMR",
      "iata": "SMG",
      "name": "Santa Maria Airport",
      "city": "Santa Maria",
      "state": "Lima-region",
      "country": "PE",
      "elevation": 769,
      "lat": -11.9833335876,
      "lon": -77,
      "tz": "America\/Lima"
  },
  "SPMS": {
      "icao": "SPMS",
      "iata": "YMS",
      "name": "Moises Benzaquen Rengifo Airport",
      "city": "Yurimaguas",
      "state": "Loreto",
      "country": "PE",
      "elevation": 587,
      "lat": -5.8937702179,
      "lon": -76.1182022095,
      "tz": "America\/Lima"
  },
  "SPNC": {
      "icao": "SPNC",
      "iata": "HUU",
      "name": "Alferez Fap David Figueroa Fernandini Airport",
      "city": "Huanuco",
      "state": "Huanuco",
      "country": "PE",
      "elevation": 6070,
      "lat": -9.8788099289,
      "lon": -76.2048034668,
      "tz": "America\/Lima"
  },
  "SPNH": {
      "icao": "SPNH",
      "iata": "",
      "name": "Laguna Choclococha Airport",
      "city": "Choclococha",
      "state": "Huancavelica",
      "country": "PE",
      "elevation": 14965,
      "lat": -13.1656999588,
      "lon": -75.0719985962,
      "tz": "America\/Lima"
  },
  "SPNM": {
      "icao": "SPNM",
      "iata": "",
      "name": "Nuevo Mundo Airport",
      "city": "Nuevo Mundo",
      "state": "Cusco",
      "country": "PE",
      "elevation": 1090,
      "lat": -11.5415000916,
      "lon": -73.1421966553,
      "tz": "America\/Lima"
  },
  "SPNO": {
      "icao": "SPNO",
      "iata": "",
      "name": "Ancon Airport",
      "city": "Ancon",
      "state": "Lima-region",
      "country": "PE",
      "elevation": 38,
      "lat": -11.7946996689,
      "lon": -77.1809005737,
      "tz": "America\/Lima"
  },
  "SPNP": {
      "icao": "SPNP",
      "iata": "",
      "name": "Ventilla Airport",
      "city": "Ventilla",
      "state": "Puno",
      "country": "PE",
      "elevation": 13123,
      "lat": -15.8000001907,
      "lon": -70.0500030518,
      "tz": "America\/Lima"
  },
  "SPNR": {
      "icao": "SPNR",
      "iata": "",
      "name": "Ricran Airport",
      "city": "Ricran",
      "state": "Pasco",
      "country": "PE",
      "elevation": 13720,
      "lat": -10.7779502869,
      "lon": -76.2513122559,
      "tz": "America\/Lima"
  },
  "SPNT": {
      "icao": "SPNT",
      "iata": "",
      "name": "Intuto Airport",
      "city": "Intuto",
      "state": "Loreto",
      "country": "PE",
      "elevation": 650,
      "lat": -3.5829999447,
      "lon": -74.75,
      "tz": "America\/Lima"
  },
  "SPNU": {
      "icao": "SPNU",
      "iata": "",
      "name": "Manu Airport",
      "city": "Manu",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 1010,
      "lat": -12.2829999924,
      "lon": -70.8830032349,
      "tz": "America\/Lima"
  },
  "SPOA": {
      "icao": "SPOA",
      "iata": "SQU",
      "name": "Saposoa Airport",
      "city": "Plaza Saposoa",
      "state": "",
      "country": "PE",
      "elevation": 982,
      "lat": -6.9600300789,
      "lon": -76.7684020996,
      "tz": "America\/Lima"
  },
  "SPOL": {
      "icao": "SPOL",
      "iata": "",
      "name": "Collique Airport",
      "city": "Collique Bajo",
      "state": "Lima-region",
      "country": "PE",
      "elevation": 410,
      "lat": -11.9287004471,
      "lon": -77.0610961914,
      "tz": "America\/Lima"
  },
  "SPON": {
      "icao": "SPON",
      "iata": "",
      "name": "Orellana Airport",
      "city": "Orellana",
      "state": "Loreto",
      "country": "PE",
      "elevation": 424,
      "lat": -6.8967499733,
      "lon": -75.1521987915,
      "tz": "America\/Lima"
  },
  "SPOR": {
      "icao": "SPOR",
      "iata": "",
      "name": "Orcopampa Airport",
      "city": "Orcopampa",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 12200,
      "lat": -15.3170003891,
      "lon": -72.3499984741,
      "tz": "America\/Lima"
  },
  "SPOS": {
      "icao": "SPOS",
      "iata": "",
      "name": "Zorritos Airport",
      "city": "Contralmirante Villar",
      "state": "Tumbes",
      "country": "PE",
      "elevation": 95,
      "lat": -3.6737399101,
      "lon": -80.6523971558,
      "tz": "America\/Lima"
  },
  "SPOV": {
      "icao": "SPOV",
      "iata": "",
      "name": "Shiringayoc\/Hacienda Hda Mejia Airport",
      "city": "Leon Velarde",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 748,
      "lat": -11.8999996185,
      "lon": -69.1669998169,
      "tz": "America\/Lima"
  },
  "SPOY": {
      "icao": "SPOY",
      "iata": "",
      "name": "Atico Airport",
      "city": "Atico",
      "state": "Ayacucho",
      "country": "PE",
      "elevation": 9,
      "lat": -16.2322216034,
      "lon": -73.611946106,
      "tz": "America\/Lima"
  },
  "SPPA": {
      "icao": "SPPA",
      "iata": "",
      "name": "Puerto Ocopa Airport",
      "city": "Puerto Ocopa",
      "state": "Junin",
      "country": "PE",
      "elevation": 1217,
      "lat": -11.1330003738,
      "lon": -74.3499984741,
      "tz": "America\/Lima"
  },
  "SPPB": {
      "icao": "SPPB",
      "iata": "",
      "name": "Tipishsa Airport",
      "city": "Puerto Breu",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 783,
      "lat": -9.5330600739,
      "lon": -72.7568969727,
      "tz": "America\/Lima"
  },
  "SPPG": {
      "icao": "SPPG",
      "iata": "",
      "name": "Paramonga Airport",
      "city": "Paramonga",
      "state": "Lima-region",
      "country": "PE",
      "elevation": 16,
      "lat": -10.6669998169,
      "lon": -77.8330001831,
      "tz": "America\/Lima"
  },
  "SPPH": {
      "icao": "SPPH",
      "iata": "",
      "name": "Pampa Hermosa Airport",
      "city": "Pampa Hermosa",
      "state": "Loreto",
      "country": "PE",
      "elevation": 510,
      "lat": -7.1999998093,
      "lon": -75.3000030518,
      "tz": "America\/Lima"
  },
  "SPPL": {
      "icao": "SPPL",
      "iata": "",
      "name": "Playa Airport",
      "city": "Trismaje",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 2070,
      "lat": -8.5178203583,
      "lon": -76.4804992676,
      "tz": "America\/Lima"
  },
  "SPPM": {
      "icao": "SPPM",
      "iata": "",
      "name": "Pomacocha Airport",
      "city": "Pomacocha",
      "state": "Ayacucho",
      "country": "PE",
      "elevation": 10524,
      "lat": -15.1792001724,
      "lon": -73.2743988037,
      "tz": "America\/Lima"
  },
  "SPPN": {
      "icao": "SPPN",
      "iata": "",
      "name": "Palmas del Espino Airport",
      "city": "Palmas del Espino",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 1625,
      "lat": -8.2925901413,
      "lon": -76.4395980835,
      "tz": "America\/Lima"
  },
  "SPPO": {
      "icao": "SPPO",
      "iata": "",
      "name": "Pozuzo Airport",
      "city": "Pozuzo",
      "state": "",
      "country": "PE",
      "elevation": 2420,
      "lat": -10.0700998306,
      "lon": -75.5500030518,
      "tz": "America\/Lima"
  },
  "SPPP": {
      "icao": "SPPP",
      "iata": "",
      "name": "Huanacopampa Airport",
      "city": "Huanacopampa",
      "state": "Apurimac",
      "country": "PE",
      "elevation": 13130,
      "lat": -14.1559000015,
      "lon": -72.2837982178,
      "tz": "America\/Lima"
  },
  "SPPY": {
      "icao": "SPPY",
      "iata": "CHH",
      "name": "Chachapoyas Airport",
      "city": "Chachapoyas",
      "state": "Amazonas",
      "country": "PE",
      "elevation": 8333,
      "lat": -6.2018098831,
      "lon": -77.8561019897,
      "tz": "America\/Lima"
  },
  "SPPZ": {
      "icao": "SPPZ",
      "iata": "",
      "name": "Palcazu Airport",
      "city": "Porvenir",
      "state": "Pasco",
      "country": "PE",
      "elevation": 932,
      "lat": -9.8958330154,
      "lon": -75.1177749634,
      "tz": "America\/Lima"
  },
  "SPQI": {
      "icao": "SPQI",
      "iata": "",
      "name": "Kiteni Airport",
      "city": "La Convencion",
      "state": "Cusco",
      "country": "PE",
      "elevation": 2540,
      "lat": -12.6491003036,
      "lon": -73.0378036499,
      "tz": "America\/Lima"
  },
  "SPQJ": {
      "icao": "SPQJ",
      "iata": "",
      "name": "Jaqui Airport",
      "city": "Jaqui",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 0,
      "lat": -15.4666671753,
      "lon": -74.4333343506,
      "tz": "America\/Lima"
  },
  "SPQN": {
      "icao": "SPQN",
      "iata": "REQ",
      "name": "Requena Airport",
      "city": "Requena",
      "state": "Loreto",
      "country": "PE",
      "elevation": 393,
      "lat": -5.0669999123,
      "lon": -73.8669967651,
      "tz": "America\/Lima"
  },
  "SPQR": {
      "icao": "SPQR",
      "iata": "",
      "name": "Quiruvilca Airport",
      "city": "Qiruvilca",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 13509,
      "lat": -7.9666666985,
      "lon": -78.1999969482,
      "tz": "America\/Lima"
  },
  "SPQT": {
      "icao": "SPQT",
      "iata": "IQT",
      "name": "Coronel FAP Francisco Secada Vignetta International Airport",
      "city": "Iquitos",
      "state": "Loreto",
      "country": "PE",
      "elevation": 306,
      "lat": -3.7847399712,
      "lon": -73.3087997437,
      "tz": "America\/Lima"
  },
  "SPQU": {
      "icao": "SPQU",
      "iata": "AQP",
      "name": "Rodriguez Ballon International Airport",
      "city": "Arequipa",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 8405,
      "lat": -16.3411006927,
      "lon": -71.5830993652,
      "tz": "America\/Lima"
  },
  "SPRF": {
      "icao": "SPRF",
      "iata": "",
      "name": "San Rafael Airport",
      "city": "San Rafael",
      "state": "Puno",
      "country": "PE",
      "elevation": 14422,
      "lat": -14.2670001984,
      "lon": -70.4670028687,
      "tz": "America\/Lima"
  },
  "SPRG": {
      "icao": "SPRG",
      "iata": "",
      "name": "San Regis Airport",
      "city": "Chincha",
      "state": "Ica",
      "country": "PE",
      "elevation": 294,
      "lat": -13.5166664124,
      "lon": -76.0999984741,
      "tz": "America\/Lima"
  },
  "SPRL": {
      "icao": "SPRL",
      "iata": "",
      "name": "Imperial Airport",
      "city": "Imperial",
      "state": "Huancavelica",
      "country": "PE",
      "elevation": 0,
      "lat": -12.3166666031,
      "lon": -75.0666656494,
      "tz": "America\/Lima"
  },
  "SPRM": {
      "icao": "SPRM",
      "iata": "",
      "name": "Capitan FAP Leonardo Alvarino Herr Airport",
      "city": "San Ramon",
      "state": "Junin",
      "country": "PE",
      "elevation": 2600,
      "lat": -11.1286001205,
      "lon": -75.3505020142,
      "tz": "America\/Lima"
  },
  "SPRU": {
      "icao": "SPRU",
      "iata": "TRU",
      "name": "Capitan FAP Carlos Martinez De Pinillos International Airport",
      "city": "Trujillo",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 106,
      "lat": -8.081410408,
      "lon": -79.1088027954,
      "tz": "America\/Lima"
  },
  "SPSA": {
      "icao": "SPSA",
      "iata": "",
      "name": "Casma Airport",
      "city": "Casma",
      "state": "Ancash",
      "country": "PE",
      "elevation": 248,
      "lat": -9.4499998093,
      "lon": -78.3000030518,
      "tz": "America\/Lima"
  },
  "SPSE": {
      "icao": "SPSE",
      "iata": "",
      "name": "Sepahua Airport",
      "city": "Sepahua",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 886,
      "lat": -11.1417999268,
      "lon": -73.0335006714,
      "tz": "America\/Lima"
  },
  "SPSF": {
      "icao": "SPSF",
      "iata": "SQD",
      "name": "San Francisco Airport",
      "city": "San Francisco de Yeso",
      "state": "Amazonas",
      "country": "PE",
      "elevation": 8202,
      "lat": -6.6166801453,
      "lon": -77.7667007446,
      "tz": "America\/Lima"
  },
  "SPSI": {
      "icao": "SPSI",
      "iata": "",
      "name": "Siguas Airport",
      "city": "Siguas",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 4720,
      "lat": -16.3670005798,
      "lon": -72.1669998169,
      "tz": "America\/Lima"
  },
  "SPSJ": {
      "icao": "SPSJ",
      "iata": "",
      "name": "San Jose de Sisa Airport",
      "city": "El Dorado",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 1200,
      "lat": -6.6164898872,
      "lon": -76.6884002686,
      "tz": "America\/Lima"
  },
  "SPSL": {
      "icao": "SPSL",
      "iata": "",
      "name": "Lamas Airport",
      "city": "Lamas",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 2600,
      "lat": -6.4166665077,
      "lon": -76.5333328247,
      "tz": "America\/Lima"
  },
  "SPSN": {
      "icao": "SPSN",
      "iata": "",
      "name": "Shapaja Airport",
      "city": "Shapaja",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 700,
      "lat": -6.5797200203,
      "lon": -76.262802124,
      "tz": "America\/Lima"
  },
  "SPSO": {
      "icao": "SPSO",
      "iata": "PIO",
      "name": "Capitan FAP Renan Elias Olivera International Airport",
      "city": "Pisco",
      "state": "Ica",
      "country": "PE",
      "elevation": 39,
      "lat": -13.7448997498,
      "lon": -76.2202987671,
      "tz": "America\/Lima"
  },
  "SPSS": {
      "icao": "SPSS",
      "iata": "",
      "name": "Masisea Airport",
      "city": "Masisea",
      "state": "Ucayali",
      "country": "PE",
      "elevation": 0,
      "lat": -8.6052780151,
      "lon": -74.304725647,
      "tz": "America\/Lima"
  },
  "SPST": {
      "icao": "SPST",
      "iata": "TPP",
      "name": "Cadete FAP Guillermo Del Castillo Paredes Airport",
      "city": "Tarapoto",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 869,
      "lat": -6.5087399483,
      "lon": -76.3731994629,
      "tz": "America\/Lima"
  },
  "SPSY": {
      "icao": "SPSY",
      "iata": "SYC",
      "name": "Shiringayoc Airport",
      "city": "Shiringayoc",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 856,
      "lat": -11.8832998276,
      "lon": -69.1999969482,
      "tz": "America\/Lima"
  },
  "SPTA": {
      "icao": "SPTA",
      "iata": "",
      "name": "Nauta Airport",
      "city": "Nauta",
      "state": "Loreto",
      "country": "PE",
      "elevation": 370,
      "lat": -4.5332999229,
      "lon": -73.5500030518,
      "tz": "America\/Lima"
  },
  "SPTE": {
      "icao": "SPTE",
      "iata": "",
      "name": "San Francisco Airport",
      "city": "San Francisco",
      "state": "Ayacucho",
      "country": "PE",
      "elevation": 2499,
      "lat": -12.5329999924,
      "lon": -73.8000030518,
      "tz": "America\/Lima"
  },
  "SPTI": {
      "icao": "SPTI",
      "iata": "",
      "name": "Puerto Inca Airport",
      "city": "Puerto Inca",
      "state": "Huanuco",
      "country": "PE",
      "elevation": 583,
      "lat": -9.3830003738,
      "lon": -74.9670028687,
      "tz": "America\/Lima"
  },
  "SPTN": {
      "icao": "SPTN",
      "iata": "TCQ",
      "name": "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
      "city": "Tacna",
      "state": "Tacna",
      "country": "PE",
      "elevation": 1538,
      "lat": -18.0533008575,
      "lon": -70.2758026123,
      "tz": "America\/Lima"
  },
  "SPTP": {
      "icao": "SPTP",
      "iata": "",
      "name": "El Pato Air Base",
      "city": "El Pato",
      "state": "Piura",
      "country": "PE",
      "elevation": 286,
      "lat": -4.5498099327,
      "lon": -81.2240982056,
      "tz": "America\/Lima"
  },
  "SPTQ": {
      "icao": "SPTQ",
      "iata": "",
      "name": "Toquepala Airport",
      "city": "Toquepala",
      "state": "Tacna",
      "country": "PE",
      "elevation": 8536,
      "lat": -17.2994995117,
      "lon": -70.6528015137,
      "tz": "America\/Lima"
  },
  "SPTR": {
      "icao": "SPTR",
      "iata": "",
      "name": "Tournavista Airport",
      "city": "Tournavista",
      "state": "Huanuco",
      "country": "PE",
      "elevation": 649,
      "lat": -8.9169998169,
      "lon": -74.733001709,
      "tz": "America\/Lima"
  },
  "SPTU": {
      "icao": "SPTU",
      "iata": "PEM",
      "name": "Padre Aldamiz International Airport",
      "city": "Puerto Maldonado",
      "state": "Madre-de-Dios",
      "country": "PE",
      "elevation": 659,
      "lat": -12.6135997772,
      "lon": -69.2285995483,
      "tz": "America\/Lima"
  },
  "SPTY": {
      "icao": "SPTY",
      "iata": "",
      "name": "Tintay Airport",
      "city": "Tintay",
      "state": "Apurimac",
      "country": "PE",
      "elevation": 8809,
      "lat": -13.9591665268,
      "lon": -73.185836792,
      "tz": "America\/Lima"
  },
  "SPUC": {
      "icao": "SPUC",
      "iata": "",
      "name": "Huamachuco Airport",
      "city": "Huamachuco",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 11224,
      "lat": -7.8216400146,
      "lon": -78.0417022705,
      "tz": "America\/Lima"
  },
  "SPUR": {
      "icao": "SPUR",
      "iata": "PIU",
      "name": "Capitan FAP Guillermo Concha Iberico International Airport",
      "city": "Piura",
      "state": "Piura",
      "country": "PE",
      "elevation": 120,
      "lat": -5.2057499886,
      "lon": -80.6164016724,
      "tz": "America\/Lima"
  },
  "SPVA": {
      "icao": "SPVA",
      "iata": "",
      "name": "Hacienda El Valor Airport",
      "city": "El Valor",
      "state": "Amazonas",
      "country": "PE",
      "elevation": 0,
      "lat": -5.6624999046,
      "lon": -78.6447219849,
      "tz": "America\/Lima"
  },
  "SPVI": {
      "icao": "SPVI",
      "iata": "",
      "name": "Vicco Airport",
      "city": "Vicco",
      "state": "Pasco",
      "country": "PE",
      "elevation": 13464,
      "lat": -10.8478441238,
      "lon": -76.2469558716,
      "tz": "America\/Lima"
  },
  "SPVL": {
      "icao": "SPVL",
      "iata": "",
      "name": "Caraveli Airport",
      "city": "Caraveli",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 5675,
      "lat": -15.7670001984,
      "lon": -73.3669967651,
      "tz": "America\/Lima"
  },
  "SPVR": {
      "icao": "SPVR",
      "iata": "",
      "name": "Vitor Airport",
      "city": "Vitor",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 0,
      "lat": -16.4291992188,
      "lon": -71.8377990723,
      "tz": "America\/Lima"
  },
  "SPYA": {
      "icao": "SPYA",
      "iata": "",
      "name": "Luya Airport",
      "city": "Luya",
      "state": "Lambayeque",
      "country": "PE",
      "elevation": 298,
      "lat": -6.6916999817,
      "lon": -79.7016983032,
      "tz": "America\/Lima"
  },
  "SPYL": {
      "icao": "SPYL",
      "iata": "TYL",
      "name": "Capitan Montes Airport",
      "city": "",
      "state": "Piura",
      "country": "PE",
      "elevation": 282,
      "lat": -4.5766401291,
      "lon": -81.2540969849,
      "tz": "America\/Lima"
  },
  "SPYO": {
      "icao": "SPYO",
      "iata": "",
      "name": "Pacasmayo Airport",
      "city": "Pacasmayo",
      "state": "La-Libertad",
      "country": "PE",
      "elevation": 95,
      "lat": -7.4069399834,
      "lon": -79.568901062,
      "tz": "America\/Lima"
  },
  "SPYU": {
      "icao": "SPYU",
      "iata": "",
      "name": "Yauca Airport",
      "city": "Yauca",
      "state": "Arequipa",
      "country": "PE",
      "elevation": 488,
      "lat": -15.6189002991,
      "lon": -74.5361022949,
      "tz": "America\/Lima"
  },
  "SPZA": {
      "icao": "SPZA",
      "iata": "NZA",
      "name": "Maria Reiche Neuman Airport",
      "city": "",
      "state": "Ica",
      "country": "PE",
      "elevation": 1860,
      "lat": -14.8540000916,
      "lon": -74.9615020752,
      "tz": "America\/Lima"
  },
  "SPZH": {
      "icao": "SPZH",
      "iata": "",
      "name": "Pachiza Airport",
      "city": "Pachiza",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 1102,
      "lat": -7.2992801666,
      "lon": -76.774597168,
      "tz": "America\/Lima"
  },
  "SPZO": {
      "icao": "SPZO",
      "iata": "CUZ",
      "name": "Alejandro Velasco Astete International Airport",
      "city": "Cusco",
      "state": "Cusco",
      "country": "PE",
      "elevation": 10860,
      "lat": -13.5356998444,
      "lon": -71.9387969971,
      "tz": "America\/Lima"
  },
  "SPZT": {
      "icao": "SPZT",
      "iata": "",
      "name": "Chazuta Airport",
      "city": "Chazuta",
      "state": "San-Martin",
      "country": "PE",
      "elevation": 757,
      "lat": -6.5741667747,
      "lon": -76.1366653442,
      "tz": "America\/Lima"
  },
  "SSAB": {
      "icao": "SSAB",
      "iata": "",
      "name": "Ibaiti Airport",
      "city": "Ibaiti",
      "state": "Parana",
      "country": "BR",
      "elevation": 1968,
      "lat": -23.7621994019,
      "lon": -50.2630004883,
      "tz": "America\/Sao_Paulo"
  },
  "SSAD": {
      "icao": "SSAD",
      "iata": "",
      "name": "Fazenda Ribeirao Airport",
      "city": "Chapadao Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2736,
      "lat": -18.763999939,
      "lon": -52.9175987244,
      "tz": "America\/Campo_Grande"
  },
  "SSAE": {
      "icao": "SSAE",
      "iata": "",
      "name": "Arroio Grande Airport",
      "city": "Arroio Grande",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 161,
      "lat": -32.2241668701,
      "lon": -53.0672225952,
      "tz": "America\/Sao_Paulo"
  },
  "SSAF": {
      "icao": "SSAF",
      "iata": "",
      "name": "Aeroclube de Foz do Iguacu Airport",
      "city": "Santa Terezinha De Itaipu",
      "state": "Parana",
      "country": "BR",
      "elevation": 840,
      "lat": -25.3694438934,
      "lon": -54.4705543518,
      "tz": "America\/Sao_Paulo"
  },
  "SSAG": {
      "icao": "SSAG",
      "iata": "",
      "name": "Fazenda Agropecuaria Lobo Ltda Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1410,
      "lat": -19.7166671753,
      "lon": -52.7255554199,
      "tz": "America\/Campo_Grande"
  },
  "SSAJ": {
      "icao": "SSAJ",
      "iata": "",
      "name": "Fazenda Esperanca Airport",
      "city": "Bataipora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1109,
      "lat": -22.2755565643,
      "lon": -53.3119430542,
      "tz": "America\/Campo_Grande"
  },
  "SSAK": {
      "icao": "SSAK",
      "iata": "",
      "name": "Aeroclube Airport",
      "city": "Cruz Alta",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1542,
      "lat": -28.6577777863,
      "lon": -53.6105575562,
      "tz": "America\/Sao_Paulo"
  },
  "SSAM": {
      "icao": "SSAM",
      "iata": "",
      "name": "Amambai Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1542,
      "lat": -23.1427783966,
      "lon": -55.2077789307,
      "tz": "America\/Campo_Grande"
  },
  "SSAN": {
      "icao": "SSAN",
      "iata": "",
      "name": "Andira Airport",
      "city": "Andira",
      "state": "Parana",
      "country": "BR",
      "elevation": 1608,
      "lat": -23.027299881,
      "lon": -50.2275009155,
      "tz": "America\/Sao_Paulo"
  },
  "SSAP": {
      "icao": "SSAP",
      "iata": "APU",
      "name": "Apucarana Airport",
      "city": "Apucarana",
      "state": "Parana",
      "country": "BR",
      "elevation": 2641,
      "lat": -23.609500885,
      "lon": -51.3844985962,
      "tz": "America\/Sao_Paulo"
  },
  "SSAQ": {
      "icao": "SSAQ",
      "iata": "0",
      "name": "Aeroclube Airport",
      "city": "Passo Fundo",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1969,
      "lat": -28.2506008148,
      "lon": -52.5167007446,
      "tz": "America\/Sao_Paulo"
  },
  "SSAR": {
      "icao": "SSAR",
      "iata": "",
      "name": "Fazenda Jacare Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1214,
      "lat": -21.5030555725,
      "lon": -53.9674987793,
      "tz": "America\/Campo_Grande"
  },
  "SSAU": {
      "icao": "SSAU",
      "iata": "",
      "name": "Centeno Airport",
      "city": "Tapes",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 14,
      "lat": -30.7474994659,
      "lon": -51.4288902283,
      "tz": "America\/Sao_Paulo"
  },
  "SSAV": {
      "icao": "SSAV",
      "iata": "",
      "name": "Fazenda Amonguija Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 400,
      "lat": -21.6908321381,
      "lon": -57.4741668701,
      "tz": "America\/Campo_Grande"
  },
  "SSAW": {
      "icao": "SSAW",
      "iata": "",
      "name": "Fazenda Japecanga Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1493,
      "lat": -20.8052787781,
      "lon": -54.0780563354,
      "tz": "America\/Campo_Grande"
  },
  "SSAY": {
      "icao": "SSAY",
      "iata": "",
      "name": "Sitio Pouso do Aviador Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1946,
      "lat": -20.4885005951,
      "lon": -54.4828987122,
      "tz": "America\/Campo_Grande"
  },
  "SSBA": {
      "icao": "SSBA",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2017,
      "lat": -19.0319442749,
      "lon": -53.6711120605,
      "tz": "America\/Campo_Grande"
  },
  "SSBB": {
      "icao": "SSBB",
      "iata": "",
      "name": "Estrela Dalva Airport",
      "city": "Bodoquena",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1207,
      "lat": -20.6749992371,
      "lon": -56.629699707,
      "tz": "America\/Campo_Grande"
  },
  "SSBC": {
      "icao": "SSBC",
      "iata": "",
      "name": "Malibu Confinamento - MC Airport",
      "city": "Castilho",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1115,
      "lat": -20.7261104584,
      "lon": -51.5886116028,
      "tz": "America\/Sao_Paulo"
  },
  "SSBD": {
      "icao": "SSBD",
      "iata": "",
      "name": "Sobradinho Airport",
      "city": "Sobradinho",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1444,
      "lat": -29.3963890076,
      "lon": -52.9916687012,
      "tz": "America\/Sao_Paulo"
  },
  "SSBE": {
      "icao": "SSBE",
      "iata": "",
      "name": "Camapua Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1848,
      "lat": -19.598400116,
      "lon": -54.0210990906,
      "tz": "America\/Campo_Grande"
  },
  "SSBF": {
      "icao": "SSBF",
      "iata": "",
      "name": "Fazenda JL Airport",
      "city": "Aparecida Do Taboado",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1400,
      "lat": -20.0044441223,
      "lon": -51.3866653442,
      "tz": "America\/Campo_Grande"
  },
  "SSBG": {
      "icao": "SSBG",
      "iata": "BGV",
      "name": "Aeroclube de Bento Goncalves Airport",
      "city": "Bento Goncalves",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2209,
      "lat": -29.1483325958,
      "lon": -51.5363883972,
      "tz": "America\/Sao_Paulo"
  },
  "SSBH": {
      "icao": "SSBH",
      "iata": "",
      "name": "Fazenda Baia do Lara Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 453,
      "lat": -17.5677776337,
      "lon": -55.2883338928,
      "tz": "America\/Campo_Grande"
  },
  "SSBJ": {
      "icao": "SSBJ",
      "iata": "",
      "name": "Bom Jesus Airport",
      "city": "Bom Jesus",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 3514,
      "lat": -28.61277771,
      "lon": -50.4494438171,
      "tz": "America\/Sao_Paulo"
  },
  "SSBK": {
      "icao": "SSBK",
      "iata": "",
      "name": "Fazenda Araruna Airport",
      "city": "Sonora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1886,
      "lat": -17.5827789307,
      "lon": -54.6408348083,
      "tz": "America\/Campo_Grande"
  },
  "SSBL": {
      "icao": "SSBL",
      "iata": "BNU",
      "name": "Blumenau Airport",
      "city": "Blumenau",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 60,
      "lat": -26.8306007385,
      "lon": -49.0903015137,
      "tz": "America\/Sao_Paulo"
  },
  "SSBM": {
      "icao": "SSBM",
      "iata": "",
      "name": "Fazenda Lageado Airport",
      "city": "Dois Irmaos Do Buriti",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 932,
      "lat": -20.2975006104,
      "lon": -55.4258346558,
      "tz": "America\/Campo_Grande"
  },
  "SSBN": {
      "icao": "SSBN",
      "iata": "",
      "name": "Belem Novo Airport",
      "city": "Porto Alegre",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 20,
      "lat": -30.1877784729,
      "lon": -51.1827774048,
      "tz": "America\/Sao_Paulo"
  },
  "SSBO": {
      "icao": "SSBO",
      "iata": "",
      "name": "Palmares Airport",
      "city": "Bossoroca",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 787,
      "lat": -28.7222213745,
      "lon": -54.8908348083,
      "tz": "America\/Sao_Paulo"
  },
  "SSBQ": {
      "icao": "SSBQ",
      "iata": "",
      "name": "Frigorifico Marfrig Airport",
      "city": "Bataguassu",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1230,
      "lat": -21.7466678619,
      "lon": -52.4783325195,
      "tz": "America\/Campo_Grande"
  },
  "SSBR": {
      "icao": "SSBR",
      "iata": "0",
      "name": "Bandeirantes Airport",
      "city": "Bandeirantes",
      "state": "Parana",
      "country": "BR",
      "elevation": 1319,
      "lat": -23.0727996826,
      "lon": -50.4230995178,
      "tz": "America\/Sao_Paulo"
  },
  "SSBS": {
      "icao": "SSBS",
      "iata": "",
      "name": "Fazenda Boa Esperanca Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1155,
      "lat": -21.2491664886,
      "lon": -52.2894439697,
      "tz": "America\/Campo_Grande"
  },
  "SSBT": {
      "icao": "SSBT",
      "iata": "",
      "name": "Fazenda Santa Ilidia Airport",
      "city": "Bataipora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1033,
      "lat": -22.324300766,
      "lon": -53.0950012207,
      "tz": "America\/Campo_Grande"
  },
  "SSBU": {
      "icao": "SSBU",
      "iata": "",
      "name": "Fazenda Baguacu - Miragem Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1191,
      "lat": -21.4563884735,
      "lon": -57.1402778625,
      "tz": "America\/Campo_Grande"
  },
  "SSBV": {
      "icao": "SSBV",
      "iata": "",
      "name": "Bela Vista Airport",
      "city": "Bela Vista",
      "state": "",
      "country": "BR",
      "elevation": 770,
      "lat": -22.0816001892,
      "lon": -56.5386009216,
      "tz": "America\/Campo_Grande"
  },
  "SSBW": {
      "icao": "SSBW",
      "iata": "",
      "name": "Fazenda Boa Sorte Airport",
      "city": "Sidrolandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -20.9130554199,
      "lon": -55.0136108398,
      "tz": "America\/Campo_Grande"
  },
  "SSBX": {
      "icao": "SSBX",
      "iata": "",
      "name": "Fazenda Lageado Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 696,
      "lat": -18.4416675568,
      "lon": -54.7999992371,
      "tz": "America\/Campo_Grande"
  },
  "SSBY": {
      "icao": "SSBY",
      "iata": "",
      "name": "Orlando Chesini Ometto Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 443,
      "lat": -20.0995006561,
      "lon": -56.7938995361,
      "tz": "America\/Campo_Grande"
  },
  "SSBZ": {
      "icao": "SSBZ",
      "iata": "",
      "name": "Aeroclube Airport",
      "city": "Sapiranga",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 56,
      "lat": -29.6761112213,
      "lon": -50.981388092,
      "tz": "America\/Sao_Paulo"
  },
  "SSCA": {
      "icao": "SSCA",
      "iata": "",
      "name": "Fazenda Campo Bom Airport",
      "city": "Chapadao Do Sul",
      "state": "Goias",
      "country": "BR",
      "elevation": 2657,
      "lat": -18.7394447327,
      "lon": -52.6199989319,
      "tz": "America\/Campo_Grande"
  },
  "SSCB": {
      "icao": "SSCB",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Mundo Novo",
      "state": "Goias",
      "country": "BR",
      "elevation": 978,
      "lat": -13.7758331299,
      "lon": -49.9469451904,
      "tz": "America\/Sao_Paulo"
  },
  "SSCD": {
      "icao": "SSCD",
      "iata": "",
      "name": "Chapadao do Sul Airport",
      "city": "Chapadao Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2644,
      "lat": -18.8377780914,
      "lon": -52.4866676331,
      "tz": "America\/Campo_Grande"
  },
  "SSCE": {
      "icao": "SSCE",
      "iata": "",
      "name": "Aeroclube Airport",
      "city": "Bage",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 722,
      "lat": -31.3277778625,
      "lon": -54.1347236633,
      "tz": "America\/Sao_Paulo"
  },
  "SSCF": {
      "icao": "SSCF",
      "iata": "",
      "name": "Max Fontoura Airport",
      "city": "Campo Largo",
      "state": "Parana",
      "country": "BR",
      "elevation": 3140,
      "lat": -25.4322223663,
      "lon": -49.5299987793,
      "tz": "America\/Sao_Paulo"
  },
  "SSCH": {
      "icao": "SSCH",
      "iata": "",
      "name": "Fazenda Cachoeirao Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1053,
      "lat": -21.5972,
      "lon": -53.225,
      "tz": "America\/Campo_Grande"
  },
  "SSCI": {
      "icao": "SSCI",
      "iata": "",
      "name": "Coxim Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 957,
      "lat": -18.4776992798,
      "lon": -54.7146987915,
      "tz": "America\/Campo_Grande"
  },
  "SSCJ": {
      "icao": "SSCJ",
      "iata": "",
      "name": "Fazenda Celeiro Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1070,
      "lat": -21.5416679382,
      "lon": -54.6616668701,
      "tz": "America\/Campo_Grande"
  },
  "SSCK": {
      "icao": "SSCK",
      "iata": "CCI",
      "name": "Concordia Airport",
      "city": "Concordia",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2461,
      "lat": -27.1805992126,
      "lon": -52.0527000427,
      "tz": "America\/Sao_Paulo"
  },
  "SSCL": {
      "icao": "SSCL",
      "iata": "CSS",
      "name": "Cassilandia Airport",
      "city": "Cassilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1568,
      "lat": -19.1463890076,
      "lon": -51.6852798462,
      "tz": "America\/Campo_Grande"
  },
  "SSCM": {
      "icao": "SSCM",
      "iata": "",
      "name": "Fazenda Cacadinha Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1312,
      "lat": -21.6238899231,
      "lon": -54.8108329773,
      "tz": "America\/Campo_Grande"
  },
  "SSCN": {
      "icao": "SSCN",
      "iata": "QCN",
      "name": "Canela Airport",
      "city": "Canela",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2723,
      "lat": -29.3701992035,
      "lon": -50.8320007324,
      "tz": "America\/Sao_Paulo"
  },
  "SSCO": {
      "icao": "SSCO",
      "iata": "",
      "name": "Comandante Marilda Zaiden de Mesquita Airport",
      "city": "Capao Do Leao",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 33,
      "lat": -31.7741661072,
      "lon": -52.4513893127,
      "tz": "America\/Sao_Paulo"
  },
  "SSCP": {
      "icao": "SSCP",
      "iata": "CKO",
      "name": "Cornelio Procopio Airport",
      "city": "Cornelio Procopio",
      "state": "Parana",
      "country": "BR",
      "elevation": 1854,
      "lat": -23.1525001526,
      "lon": -50.6025009155,
      "tz": "America\/Sao_Paulo"
  },
  "SSCQ": {
      "icao": "SSCQ",
      "iata": "",
      "name": "Salca Airport",
      "city": "Cacequi",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 285,
      "lat": -29.8997211456,
      "lon": -54.9291687012,
      "tz": "America\/Sao_Paulo"
  },
  "SSCR": {
      "icao": "SSCR",
      "iata": "",
      "name": "Marechal Candido Rondon Airport",
      "city": "Marechal Candido Rondon",
      "state": "Parana",
      "country": "BR",
      "elevation": 1411,
      "lat": -24.5126991272,
      "lon": -54.0546989441,
      "tz": "America\/Sao_Paulo"
  },
  "SSCS": {
      "icao": "SSCS",
      "iata": "",
      "name": "Estancia Ema Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1968,
      "lat": -20.6197223663,
      "lon": -54.7219429016,
      "tz": "America\/Campo_Grande"
  },
  "SSCT": {
      "icao": "SSCT",
      "iata": "GGH",
      "name": "Cianorte Airport",
      "city": "Cianorte",
      "state": "Parana",
      "country": "BR",
      "elevation": 1837,
      "lat": -23.6914997101,
      "lon": -52.6418991089,
      "tz": "America\/Sao_Paulo"
  },
  "SSCU": {
      "icao": "SSCU",
      "iata": "",
      "name": "Fazenda Santa Marina Airport",
      "city": "Santa Maria Das Barreiras",
      "state": "Para",
      "country": "BR",
      "elevation": 1007,
      "lat": -8.8680562973,
      "lon": -50.4794425964,
      "tz": "America\/Belem"
  },
  "SSCV": {
      "icao": "SSCV",
      "iata": "",
      "name": "Capivari Airport",
      "city": "Capivari Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 14,
      "lat": -30.134721756,
      "lon": -50.5086097717,
      "tz": "America\/Sao_Paulo"
  },
  "SSCW": {
      "icao": "SSCW",
      "iata": "",
      "name": "Fazenda Sao Sebastiao Airport",
      "city": "Corumba",
      "state": "",
      "country": "BR",
      "elevation": 443,
      "lat": -18.0397224426,
      "lon": -56.0055541992,
      "tz": "America\/Campo_Grande"
  },
  "SSCX": {
      "icao": "SSCX",
      "iata": "",
      "name": "Fazenda Coxilha Airport",
      "city": "Passo Fundo",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2133,
      "lat": -28.1172218323,
      "lon": -52.3172225952,
      "tz": "America\/Sao_Paulo"
  },
  "SSDB": {
      "icao": "SSDB",
      "iata": "",
      "name": "Fazenda Kurupay Airport",
      "city": "Angelica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1280,
      "lat": -22.0408325195,
      "lon": -53.7311096191,
      "tz": "America\/Campo_Grande"
  },
  "SSDC": {
      "icao": "SSDC",
      "iata": "",
      "name": "Dionisio Cerqueira Airport",
      "city": "Dionisio Cerqueira",
      "state": "Misiones",
      "country": "BR",
      "elevation": 2723,
      "lat": -26.295834,
      "lon": -53.630654,
      "tz": "America\/Argentina\/Cordoba"
  },
  "SSDE": {
      "icao": "SSDE",
      "iata": "",
      "name": "Fazenda Barra Dourada Airport",
      "city": "Dourados",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -21.9880561829,
      "lon": -54.238609314,
      "tz": "America\/Campo_Grande"
  },
  "SSDF": {
      "icao": "SSDF",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 312,
      "lat": -20.0994434357,
      "lon": -57.3461112976,
      "tz": "America\/Campo_Grande"
  },
  "SSDG": {
      "icao": "SSDG",
      "iata": "",
      "name": "Fazenda Nazare Airport",
      "city": "Santa Filomena",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1962,
      "lat": -9.3544435501,
      "lon": -45.5613899231,
      "tz": "America\/Fortaleza"
  },
  "SSDH": {
      "icao": "SSDH",
      "iata": "",
      "name": "Fazenda Caranday Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 541,
      "lat": -20.2055568695,
      "lon": -57.063331604,
      "tz": "America\/Campo_Grande"
  },
  "SSDI": {
      "icao": "SSDI",
      "iata": "",
      "name": "Fazenda Bacatuba Airport",
      "city": "Buriti Bravo",
      "state": "Maranhao",
      "country": "BR",
      "elevation": 584,
      "lat": -5.6583328247,
      "lon": -43.7055549622,
      "tz": "America\/Fortaleza"
  },
  "SSDJ": {
      "icao": "SSDJ",
      "iata": "",
      "name": "Fazenda Bonanza Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1252,
      "lat": -19.0508327484,
      "lon": -54.0649986267,
      "tz": "America\/Campo_Grande"
  },
  "SSDK": {
      "icao": "SSDK",
      "iata": "",
      "name": "Fazenda Vista Alegre Airport",
      "city": "Novo Horizonte Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1099,
      "lat": -22.5977783203,
      "lon": -53.6908340454,
      "tz": "America\/Campo_Grande"
  },
  "SSDL": {
      "icao": "SSDL",
      "iata": "",
      "name": "Fazenda Ribeirao Airport",
      "city": "Ribeirao Goncalves",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1588,
      "lat": -8.0063886643,
      "lon": -45.338054657,
      "tz": "America\/Fortaleza"
  },
  "SSDM": {
      "icao": "SSDM",
      "iata": "",
      "name": "Aeroclube de Santana do Livramento Airport",
      "city": "Santana Do Livramento",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1073,
      "lat": -30.822221756,
      "lon": -55.5763893127,
      "tz": "America\/Montevideo"
  },
  "SSDN": {
      "icao": "SSDN",
      "iata": "",
      "name": "Fazenda Galileia Airport",
      "city": "Baixa Grande Do Ribeiro",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1932,
      "lat": -9.1044,
      "lon": -45.4166679382,
      "tz": "America\/Fortaleza"
  },
  "SSDO": {
      "icao": "SSDO",
      "iata": "DOU",
      "name": "Dourados Airport",
      "city": "Dourados",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1503,
      "lat": -22.2019004822,
      "lon": -54.9266014099,
      "tz": "America\/Campo_Grande"
  },
  "SSDP": {
      "icao": "SSDP",
      "iata": "",
      "name": "Fazenda Fortaleza do Guapore Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 738,
      "lat": -14.459444046,
      "lon": -60.1780548096,
      "tz": "America\/Cuiaba"
  },
  "SSDQ": {
      "icao": "SSDQ",
      "iata": "",
      "name": "Fazenda Marema Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 446,
      "lat": -20.1827774048,
      "lon": -56.6358337402,
      "tz": "America\/Campo_Grande"
  },
  "SSDS": {
      "icao": "SSDS",
      "iata": "",
      "name": "Fazenda Bandeirantes Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 935,
      "lat": -13.7072219849,
      "lon": -50.6477775574,
      "tz": "America\/Sao_Paulo"
  },
  "SSDT": {
      "icao": "SSDT",
      "iata": "",
      "name": "Fazenda Pau D`Arco Airport",
      "city": "Redencao",
      "state": "Para",
      "country": "BR",
      "elevation": 794,
      "lat": -7.943611145,
      "lon": -50.1763877869,
      "tz": "America\/Belem"
  },
  "SSDU": {
      "icao": "SSDU",
      "iata": "",
      "name": "Fazenda Modelo Airport",
      "city": "Pedro Gomes",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1309,
      "lat": -17.9916667938,
      "lon": -54.2236099243,
      "tz": "America\/Campo_Grande"
  },
  "SSDV": {
      "icao": "SSDV",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 508,
      "lat": -18.2161102295,
      "lon": -55.4652786255,
      "tz": "America\/Campo_Grande"
  },
  "SSDW": {
      "icao": "SSDW",
      "iata": "",
      "name": "Fazenda Potrich Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1197,
      "lat": -12.9244441986,
      "lon": -55.5191688538,
      "tz": "America\/Cuiaba"
  },
  "SSDX": {
      "icao": "SSDX",
      "iata": "",
      "name": "Fazenda da Coxilha Airport",
      "city": "Camaqua",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 33,
      "lat": -31,
      "lon": -51.7005577087,
      "tz": "America\/Sao_Paulo"
  },
  "SSDY": {
      "icao": "SSDY",
      "iata": "",
      "name": "Fazenda Duas Irmas Airport",
      "city": "Guiratinga",
      "state": "",
      "country": "BR",
      "elevation": 1410,
      "lat": -16.15666667,
      "lon": -53.00972222,
      "tz": "America\/Cuiaba"
  },
  "SSDZ": {
      "icao": "SSDZ",
      "iata": "",
      "name": "Fazenda Divisao Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1312,
      "lat": -23.1338882446,
      "lon": -54.6338882446,
      "tz": "America\/Campo_Grande"
  },
  "SSED": {
      "icao": "SSED",
      "iata": "",
      "name": "Fazenda Escalada Airport",
      "city": "Pedro Gomes",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1132,
      "lat": -17.9566669464,
      "lon": -54.4191665649,
      "tz": "America\/Campo_Grande"
  },
  "SSEE": {
      "icao": "SSEE",
      "iata": "",
      "name": "Regional do Vale do Taquari Airport",
      "city": "Estrela",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 105,
      "lat": -29.453332901,
      "lon": -51.9341659546,
      "tz": "America\/Sao_Paulo"
  },
  "SSEF": {
      "icao": "SSEF",
      "iata": "",
      "name": "Fazenda Passaredo Airport",
      "city": "Cajuru",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 4632,
      "lat": -21.326944,
      "lon": -47.231111,
      "tz": "America\/Sao_Paulo"
  },
  "SSEG": {
      "icao": "SSEG",
      "iata": "",
      "name": "Estancia da Gruta Airport",
      "city": "Pelotas",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 33,
      "lat": -31.9424991608,
      "lon": -52.4794425964,
      "tz": "America\/Sao_Paulo"
  },
  "SSEI": {
      "icao": "SSEI",
      "iata": "",
      "name": "Fazenda Santo Andre Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 489,
      "lat": -20.3386116028,
      "lon": -56.6983337402,
      "tz": "America\/Campo_Grande"
  },
  "SSEK": {
      "icao": "SSEK",
      "iata": "",
      "name": "Fazenda Lontra Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1299,
      "lat": -20.0177783966,
      "lon": -53.2524986267,
      "tz": "America\/Campo_Grande"
  },
  "SSEL": {
      "icao": "SSEL",
      "iata": "",
      "name": "Fazenda Jatiuca Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1601,
      "lat": -20.1697216034,
      "lon": -52.9330558777,
      "tz": "America\/Campo_Grande"
  },
  "SSEO": {
      "icao": "SSEO",
      "iata": "",
      "name": "Fazenda Mimoso Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1299,
      "lat": -20.7344436646,
      "lon": -53.5766677856,
      "tz": "America\/Campo_Grande"
  },
  "SSEP": {
      "icao": "SSEP",
      "iata": "0",
      "name": "Sao Sepe Airport",
      "city": "Sao Sepe",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 502,
      "lat": -30.1821994781,
      "lon": -53.5793991089,
      "tz": "America\/Sao_Paulo"
  },
  "SSEQ": {
      "icao": "SSEQ",
      "iata": "",
      "name": "Fazenda Rio Brilhante Airport",
      "city": "Angelica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1099,
      "lat": -21.9233322144,
      "lon": -53.9399986267,
      "tz": "America\/Campo_Grande"
  },
  "SSER": {
      "icao": "SSER",
      "iata": "ERM",
      "name": "Erechim Airport",
      "city": "Erechim",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2498,
      "lat": -27.6618995667,
      "lon": -52.2682991028,
      "tz": "America\/Sao_Paulo"
  },
  "SSES": {
      "icao": "SSES",
      "iata": "",
      "name": "Encruzilhada do Sul Airport",
      "city": "Encruzilhada Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1460,
      "lat": -30.558889389,
      "lon": -52.5825004578,
      "tz": "America\/Sao_Paulo"
  },
  "SSET": {
      "icao": "SSET",
      "iata": "",
      "name": "Tambau Airport",
      "city": "Tambau",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2451,
      "lat": -21.69722222,
      "lon": -47.31555556,
      "tz": "America\/Sao_Paulo"
  },
  "SSEU": {
      "icao": "SSEU",
      "iata": "",
      "name": "Monte Carmelo Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 915,
      "lat": -7.2830557823,
      "lon": -56.5769424438,
      "tz": "America\/Santarem"
  },
  "SSEV": {
      "icao": "SSEV",
      "iata": "",
      "name": "Sao Camilo Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 289,
      "lat": -18.0872211456,
      "lon": -57.1313896179,
      "tz": "America\/Campo_Grande"
  },
  "SSEW": {
      "icao": "SSEW",
      "iata": "",
      "name": "Sitio Limoeiro Airport",
      "city": "Pardinho",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 3084,
      "lat": -23.15,
      "lon": -48.31,
      "tz": "America\/Sao_Paulo"
  },
  "SSEX": {
      "icao": "SSEX",
      "iata": "",
      "name": "Estancia Caiman Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 361,
      "lat": -19.9661102295,
      "lon": -56.3108329773,
      "tz": "America\/Campo_Grande"
  },
  "SSEY": {
      "icao": "SSEY",
      "iata": "",
      "name": "Estancia Itapororo Airport",
      "city": "Alegrete",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 197,
      "lat": -29.433889389,
      "lon": -56.3005561829,
      "tz": "America\/Sao_Paulo"
  },
  "SSEZ": {
      "icao": "SSEZ",
      "iata": "",
      "name": "Espumoso Airport",
      "city": "Espumoso",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1198,
      "lat": -28.7374992371,
      "lon": -52.856388092,
      "tz": "America\/Sao_Paulo"
  },
  "SSFA": {
      "icao": "SSFA",
      "iata": "",
      "name": "Foz do Areia Airport",
      "city": "Pinhao",
      "state": "Parana",
      "country": "BR",
      "elevation": 3645,
      "lat": -25.9815006256,
      "lon": -51.6407012939,
      "tz": "America\/Sao_Paulo"
  },
  "SSFB": {
      "icao": "SSFB",
      "iata": "FBE",
      "name": "Francisco Beltrao Airport",
      "city": "Francisco Beltrao",
      "state": "Parana",
      "country": "BR",
      "elevation": 2100,
      "lat": -26.0592002869,
      "lon": -53.0634994507,
      "tz": "America\/Sao_Paulo"
  },
  "SSFD": {
      "icao": "SSFD",
      "iata": "",
      "name": "Fazenda Acaraja Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1224,
      "lat": -23.0011100769,
      "lon": -54.6994438171,
      "tz": "America\/Campo_Grande"
  },
  "SSFE": {
      "icao": "SSFE",
      "iata": "",
      "name": "Estancia Hercules Airport",
      "city": "Foz Do Iguacu",
      "state": "Alto-Parana",
      "country": "BR",
      "elevation": 558,
      "lat": -25.4606990814,
      "lon": -54.5988006592,
      "tz": "America\/Asuncion"
  },
  "SSFF": {
      "icao": "SSFF",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Nova Alvorada Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1204,
      "lat": -21.1786117554,
      "lon": -54.293056488,
      "tz": "America\/Campo_Grande"
  },
  "SSFG": {
      "icao": "SSFG",
      "iata": "",
      "name": "Fazenda Guanabara Airport",
      "city": "Paranapoema",
      "state": "Parana",
      "country": "BR",
      "elevation": 925,
      "lat": -22.6583328247,
      "lon": -52.1305541992,
      "tz": "America\/Sao_Paulo"
  },
  "SSFH": {
      "icao": "SSFH",
      "iata": "",
      "name": "Vale dos Dinassauros Airport",
      "city": "Uberaba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2438,
      "lat": -19.782222,
      "lon": -47.811944,
      "tz": "America\/Sao_Paulo"
  },
  "SSFI": {
      "icao": "SSFI",
      "iata": "",
      "name": "Fazenda Itamarati Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1837,
      "lat": -22.1881999969,
      "lon": -55.5778007507,
      "tz": "America\/Campo_Grande"
  },
  "SSFJ": {
      "icao": "SSFJ",
      "iata": "",
      "name": "Fazenda Retiro da Cachoeira Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1435,
      "lat": -20.8077774048,
      "lon": -54.619720459,
      "tz": "America\/Campo_Grande"
  },
  "SSFK": {
      "icao": "SSFK",
      "iata": "",
      "name": "Forte Coimbra Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 279,
      "lat": -19.9061107635,
      "lon": -57.7794456482,
      "tz": "America\/Campo_Grande"
  },
  "SSFL": {
      "icao": "SSFL",
      "iata": "",
      "name": "Joao Pereira dos Santos Filho Airport",
      "city": "Fronteiras",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1466,
      "lat": -7.0999999046,
      "lon": -40.6269454956,
      "tz": "America\/Fortaleza"
  },
  "SSFM": {
      "icao": "SSFM",
      "iata": "",
      "name": "Fazenda Marimbondo Airport",
      "city": "Conselheiro Mairinck",
      "state": "Parana",
      "country": "BR",
      "elevation": 1526,
      "lat": -23.5369434357,
      "lon": -50.0980567932,
      "tz": "America\/Sao_Paulo"
  },
  "SSFN": {
      "icao": "SSFN",
      "iata": "",
      "name": "Fazenda Novilho Airport",
      "city": "Caarapo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1706,
      "lat": -22.6191673279,
      "lon": -54.9258346558,
      "tz": "America\/Campo_Grande"
  },
  "SSFO": {
      "icao": "SSFO",
      "iata": "",
      "name": "Fazenda Novo Horizonte Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 354,
      "lat": -20.0397224426,
      "lon": -56.3202781677,
      "tz": "America\/Campo_Grande"
  },
  "SSFP": {
      "icao": "SSFP",
      "iata": "",
      "name": "Fazenda Sao Paulo Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1145,
      "lat": -20.8641662598,
      "lon": -52.1747207642,
      "tz": "America\/Campo_Grande"
  },
  "SSFQ": {
      "icao": "SSFQ",
      "iata": "",
      "name": "Fazenda Barra Nova Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1608,
      "lat": -21.3197212219,
      "lon": -54.7352790833,
      "tz": "America\/Campo_Grande"
  },
  "SSFR": {
      "icao": "SSFR",
      "iata": "",
      "name": "Fronteira Airport",
      "city": "Fronteira",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1598,
      "lat": -20.2775001526,
      "lon": -49.1908340454,
      "tz": "America\/Sao_Paulo"
  },
  "SSFU": {
      "icao": "SSFU",
      "iata": "",
      "name": "Fazenda Ligacao Airport",
      "city": "Sete Quedas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1739,
      "lat": -23.8480567932,
      "lon": -54.8161125183,
      "tz": "America\/Campo_Grande"
  },
  "SSFW": {
      "icao": "SSFW",
      "iata": "",
      "name": "Fazenda Grotao Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1063,
      "lat": -18.6774997711,
      "lon": -54.3922233582,
      "tz": "America\/Campo_Grande"
  },
  "SSFX": {
      "icao": "SSFX",
      "iata": "",
      "name": "Fazenda Primavera Airport",
      "city": "Nova Fatima",
      "state": "Parana",
      "country": "BR",
      "elevation": 2239,
      "lat": -23.4558334351,
      "lon": -50.5758323669,
      "tz": "America\/Sao_Paulo"
  },
  "SSGA": {
      "icao": "SSGA",
      "iata": "",
      "name": "Garibaldi Airport",
      "city": "Garibaldi",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2231,
      "lat": -29.2693996429,
      "lon": -51.5317001343,
      "tz": "America\/Sao_Paulo"
  },
  "SSGB": {
      "icao": "SSGB",
      "iata": "",
      "name": "Guaratuba Airport",
      "city": "Guaratuba",
      "state": "Parana",
      "country": "BR",
      "elevation": 49,
      "lat": -25.8815994263,
      "lon": -48.6120986938,
      "tz": "America\/Sao_Paulo"
  },
  "SSGC": {
      "icao": "SSGC",
      "iata": "",
      "name": "General Canrobert Pereira da Costa Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 566,
      "lat": -20.4533004761,
      "lon": -55.757598877,
      "tz": "America\/Campo_Grande"
  },
  "SSGE": {
      "icao": "SSGE",
      "iata": "",
      "name": "Fazenda Periquitos Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1180,
      "lat": -20.5069446564,
      "lon": -51.8391685486,
      "tz": "America\/Campo_Grande"
  },
  "SSGF": {
      "icao": "SSGF",
      "iata": "",
      "name": "Fazenda Formosa Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1575,
      "lat": -20.1994991302,
      "lon": -53.224899292,
      "tz": "America\/Campo_Grande"
  },
  "SSGG": {
      "icao": "SSGG",
      "iata": "",
      "name": "Fazenda Jacare de Chifre Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 381,
      "lat": -21.0277786255,
      "lon": -57.4972229004,
      "tz": "America\/Campo_Grande"
  },
  "SSGH": {
      "icao": "SSGH",
      "iata": "",
      "name": "Fazenda Capao Verde Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 410,
      "lat": -20.0736103058,
      "lon": -56.0683326721,
      "tz": "America\/Campo_Grande"
  },
  "SSGI": {
      "icao": "SSGI",
      "iata": "",
      "name": "Astral Airport",
      "city": "Eldorado Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 33,
      "lat": -30.0602779388,
      "lon": -51.4375,
      "tz": "America\/Sao_Paulo"
  },
  "SSGJ": {
      "icao": "SSGJ",
      "iata": "",
      "name": "Fazenda Poleiro Grande Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 331,
      "lat": -17.2497215271,
      "lon": -56.275554657,
      "tz": "America\/Campo_Grande"
  },
  "SSGK": {
      "icao": "SSGK",
      "iata": "",
      "name": "Fazenda Volta Grande Airport",
      "city": "Maracaju",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1699,
      "lat": -21.4558334351,
      "lon": -55.456943512,
      "tz": "America\/Campo_Grande"
  },
  "SSGM": {
      "icao": "SSGM",
      "iata": "",
      "name": "Fazenda Seriema Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1151,
      "lat": -21.6933326721,
      "lon": -56.5625,
      "tz": "America\/Campo_Grande"
  },
  "SSGN": {
      "icao": "SSGN",
      "iata": "",
      "name": "Fazenda Guaiba Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1401,
      "lat": -23.2391662598,
      "lon": -54.5411109924,
      "tz": "America\/Campo_Grande"
  },
  "SSGO": {
      "icao": "SSGO",
      "iata": "",
      "name": "Rosada Airport",
      "city": "Sao Gabriel D`Oeste",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2211,
      "lat": -19.4297218323,
      "lon": -54.587223053,
      "tz": "America\/Campo_Grande"
  },
  "SSGP": {
      "icao": "SSGP",
      "iata": "",
      "name": "Piquet Airport",
      "city": "Brasilia",
      "state": "Federal-District",
      "country": "BR",
      "elevation": 3281,
      "lat": -15.8538894653,
      "lon": -47.806388855,
      "tz": "America\/Sao_Paulo"
  },
  "SSGR": {
      "icao": "SSGR",
      "iata": "0",
      "name": "Guapore Airport",
      "city": "Guapore",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1558,
      "lat": -28.8946990967,
      "lon": -51.8553009033,
      "tz": "America\/Sao_Paulo"
  },
  "SSGS": {
      "icao": "SSGS",
      "iata": "",
      "name": "Fazenda Falcao Airport",
      "city": "Primavera Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1975,
      "lat": -15.399444,
      "lon": -54.181111,
      "tz": "America\/Cuiaba"
  },
  "SSGT": {
      "icao": "SSGT",
      "iata": "",
      "name": "Fazenda Capao Alto Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1050,
      "lat": -21.6988887787,
      "lon": -54.8138885498,
      "tz": "America\/Campo_Grande"
  },
  "SSGU": {
      "icao": "SSGU",
      "iata": "",
      "name": "Fazenda Gurucaia Airport",
      "city": "Santa Isabel Do Ivai",
      "state": "Parana",
      "country": "BR",
      "elevation": 886,
      "lat": -23.1933326721,
      "lon": -53.3283348083,
      "tz": "America\/Sao_Paulo"
  },
  "SSGW": {
      "icao": "SSGW",
      "iata": "",
      "name": "Goio-Ere Airport",
      "city": "Goio-Ere",
      "state": "Parana",
      "country": "BR",
      "elevation": 1608,
      "lat": -24.2203006744,
      "lon": -53.0444984436,
      "tz": "America\/Sao_Paulo"
  },
  "SSGX": {
      "icao": "SSGX",
      "iata": "",
      "name": "Fazenda Rio Negro Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 312,
      "lat": -19.5683326721,
      "lon": -56.2455558777,
      "tz": "America\/Campo_Grande"
  },
  "SSGY": {
      "icao": "SSGY",
      "iata": "QGA",
      "name": "Guaira Airport",
      "city": "Guaira",
      "state": "Canindeyu",
      "country": "BR",
      "elevation": 889,
      "lat": -24.0811004639,
      "lon": -54.1916999817,
      "tz": "America\/Campo_Grande"
  },
  "SSGZ": {
      "icao": "SSGZ",
      "iata": "",
      "name": "Fazenda Santa Helena do Pindo Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1050,
      "lat": -22.8950004578,
      "lon": -53.8677787781,
      "tz": "America\/Campo_Grande"
  },
  "SSHA": {
      "icao": "SSHA",
      "iata": "",
      "name": "Aeroclube de Aquidauana Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 620,
      "lat": -20.4808330536,
      "lon": -55.769443512,
      "tz": "America\/Campo_Grande"
  },
  "SSHB": {
      "icao": "SSHB",
      "iata": "",
      "name": "Fazenda Serra Dourada Airport",
      "city": "Ivate",
      "state": "Parana",
      "country": "BR",
      "elevation": 1007,
      "lat": -23.322221756,
      "lon": -53.4686126709,
      "tz": "America\/Sao_Paulo"
  },
  "SSHC": {
      "icao": "SSHC",
      "iata": "",
      "name": "Capitao Pedro Paranhos Airport",
      "city": "Guapirama",
      "state": "Parana",
      "country": "BR",
      "elevation": 1447,
      "lat": -23.429444,
      "lon": -50.167778,
      "tz": "America\/Sao_Paulo"
  },
  "SSHD": {
      "icao": "SSHD",
      "iata": "",
      "name": "Fazenda Bipandora II Airport",
      "city": "Itapora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1368,
      "lat": -22.0027770996,
      "lon": -54.9011116028,
      "tz": "America\/Campo_Grande"
  },
  "SSHF": {
      "icao": "SSHF",
      "iata": "",
      "name": "Sitio Serra Negra Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -18.0333328247,
      "lon": -57.4833335876,
      "tz": "America\/Campo_Grande"
  },
  "SSHG": {
      "icao": "SSHG",
      "iata": "",
      "name": "Antonio Joao Airport",
      "city": "Antonio Joao",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2277,
      "lat": -22.3593997955,
      "lon": -55.7639007568,
      "tz": "America\/Campo_Grande"
  },
  "SSHI": {
      "icao": "SSHI",
      "iata": "",
      "name": "Fazenda Dom Francisco Airport",
      "city": "Itaquirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1237,
      "lat": -23.2511100769,
      "lon": -54.0483322144,
      "tz": "America\/Campo_Grande"
  },
  "SSHJ": {
      "icao": "SSHJ",
      "iata": "",
      "name": "Fazenda Santa Rita Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1686,
      "lat": -22.2672214508,
      "lon": -55.4880561829,
      "tz": "America\/Campo_Grande"
  },
  "SSHK": {
      "icao": "SSHK",
      "iata": "",
      "name": "Fazenda Jaguarandy Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1968,
      "lat": -22.5897216797,
      "lon": -55.569442749,
      "tz": "America\/Campo_Grande"
  },
  "SSHL": {
      "icao": "SSHL",
      "iata": "",
      "name": "Fazenda Jussara Airport",
      "city": "Jussara",
      "state": "Parana",
      "country": "BR",
      "elevation": 1213,
      "lat": -23.5750007629,
      "lon": -52.4083328247,
      "tz": "America\/Sao_Paulo"
  },
  "SSHN": {
      "icao": "SSHN",
      "iata": "",
      "name": "Recanto das Aguias Airport",
      "city": "Iguaracu",
      "state": "Parana",
      "country": "BR",
      "elevation": 1637,
      "lat": -23.2450008392,
      "lon": -51.8755569458,
      "tz": "America\/Sao_Paulo"
  },
  "SSHQ": {
      "icao": "SSHQ",
      "iata": "",
      "name": "Fazenda Paraiso Airport",
      "city": "Terenos",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1280,
      "lat": -20.5616664886,
      "lon": -54.8641662598,
      "tz": "America\/Campo_Grande"
  },
  "SSHT": {
      "icao": "SSHT",
      "iata": "",
      "name": "Ingazeira Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 853,
      "lat": -10.1304620248,
      "lon": -56.7186355591,
      "tz": "America\/Cuiaba"
  },
  "SSHU": {
      "icao": "SSHU",
      "iata": "",
      "name": "Fazenda Centenario Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 426,
      "lat": -19.4580554962,
      "lon": -56.0452766418,
      "tz": "America\/Campo_Grande"
  },
  "SSHW": {
      "icao": "SSHW",
      "iata": "",
      "name": "Fazenda Macarai Airport",
      "city": "Itaquirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1056,
      "lat": -23.4174995422,
      "lon": -54.0125007629,
      "tz": "America\/Campo_Grande"
  },
  "SSHX": {
      "icao": "SSHX",
      "iata": "",
      "name": "Fazenda Jatoba Airport",
      "city": "Paranhos",
      "state": "Canindeyu",
      "country": "BR",
      "elevation": 1335,
      "lat": -23.9237995148,
      "lon": -55.3018989563,
      "tz": "America\/Campo_Grande"
  },
  "SSHZ": {
      "icao": "SSHZ",
      "iata": "HRZ",
      "name": "Horizontina Airport",
      "city": "Horizontina",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1063,
      "lat": -27.638299942,
      "lon": -54.339099884,
      "tz": "America\/Sao_Paulo"
  },
  "SSIB": {
      "icao": "SSIB",
      "iata": "",
      "name": "Fazenda Suely Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1574,
      "lat": -19.4799995422,
      "lon": -52.8125,
      "tz": "America\/Campo_Grande"
  },
  "SSIC": {
      "icao": "SSIC",
      "iata": "",
      "name": "APLIC Aviacao Agricola Ltda Airport",
      "city": "Dourados",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1405,
      "lat": -22.3066673279,
      "lon": -54.8244438171,
      "tz": "America\/Campo_Grande"
  },
  "SSID": {
      "icao": "SSID",
      "iata": "",
      "name": "Independencia Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1135,
      "lat": -22.2791996002,
      "lon": -53.3834991455,
      "tz": "America\/Campo_Grande"
  },
  "SSIE": {
      "icao": "SSIE",
      "iata": "",
      "name": "Teruel Ipanema Estancia Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1732,
      "lat": -20.5994434357,
      "lon": -54.6025009155,
      "tz": "America\/Campo_Grande"
  },
  "SSIF": {
      "icao": "SSIF",
      "iata": "",
      "name": "Fazenda Baunilha Airport",
      "city": "Itaquirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 970,
      "lat": -23.560300827,
      "lon": -54.0339012146,
      "tz": "America\/Campo_Grande"
  },
  "SSIG": {
      "icao": "SSIG",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 617,
      "lat": -20.2700004578,
      "lon": -55.7283325195,
      "tz": "America\/Campo_Grande"
  },
  "SSIH": {
      "icao": "SSIH",
      "iata": "",
      "name": "Fazenda Fortaleza Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1296,
      "lat": -20.9463882446,
      "lon": -52.7222213745,
      "tz": "America\/Campo_Grande"
  },
  "SSII": {
      "icao": "SSII",
      "iata": "",
      "name": "Fazenda Orion Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1001,
      "lat": -22.0227775574,
      "lon": -56.3241653442,
      "tz": "America\/Campo_Grande"
  },
  "SSIJ": {
      "icao": "SSIJ",
      "iata": "IJU",
      "name": "Ijui Airport",
      "city": "Ijui",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1197,
      "lat": -28.3687000275,
      "lon": -53.8465995789,
      "tz": "America\/Sao_Paulo"
  },
  "SSIM": {
      "icao": "SSIM",
      "iata": "",
      "name": "Boqueirao Alegre Airport",
      "city": "Quarai",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 732,
      "lat": -30.2549991608,
      "lon": -55.8394432068,
      "tz": "America\/Sao_Paulo"
  },
  "SSIO": {
      "icao": "SSIO",
      "iata": "",
      "name": "Candiota Airport",
      "city": "Candiota",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 741,
      "lat": -31.4941673279,
      "lon": -53.6908340454,
      "tz": "America\/Sao_Paulo"
  },
  "SSIP": {
      "icao": "SSIP",
      "iata": "",
      "name": "Fazenda Santa Otilia Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 919,
      "lat": -21.1905555725,
      "lon": -57.0416679382,
      "tz": "America\/Campo_Grande"
  },
  "SSIQ": {
      "icao": "SSIQ",
      "iata": "ITQ",
      "name": "Itaqui Airport",
      "city": "Itaqui",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 230,
      "lat": -29.1730995178,
      "lon": -56.5367012024,
      "tz": "America\/Sao_Paulo"
  },
  "SSIR": {
      "icao": "SSIR",
      "iata": "",
      "name": "Ibiruba Airport",
      "city": "Ibiruba",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1447,
      "lat": -28.6041679382,
      "lon": -53.0788879395,
      "tz": "America\/Sao_Paulo"
  },
  "SSIS": {
      "icao": "SSIS",
      "iata": "",
      "name": "Major Jose Ignacio Airport",
      "city": "Ibate",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1962,
      "lat": -22.047778,
      "lon": -48.097222,
      "tz": "America\/Sao_Paulo"
  },
  "SSIU": {
      "icao": "SSIU",
      "iata": "",
      "name": "Sitio Enel Airport",
      "city": "Pantano Grande",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 32,
      "lat": -30.1336116791,
      "lon": -52.3652763367,
      "tz": "America\/Sao_Paulo"
  },
  "SSIV": {
      "icao": "SSIV",
      "iata": "",
      "name": "Fazenda Experimental Airport",
      "city": "Ivinhema",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1194,
      "lat": -22.2972221375,
      "lon": -53.8472222222,
      "tz": "America\/Campo_Grande"
  },
  "SSIW": {
      "icao": "SSIW",
      "iata": "",
      "name": "Fazenda Damaro Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 344,
      "lat": -19.9775009155,
      "lon": -56.4361114502,
      "tz": "America\/Campo_Grande"
  },
  "SSIX": {
      "icao": "SSIX",
      "iata": "",
      "name": "Fazenda Campo Verde Airport",
      "city": "Taquarussu",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 984,
      "lat": -22.6516666412,
      "lon": -53.5077781677,
      "tz": "America\/Campo_Grande"
  },
  "SSIY": {
      "icao": "SSIY",
      "iata": "",
      "name": "Fazenda Santa Adelina Airport",
      "city": "Guaicara",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1411,
      "lat": -21.528889,
      "lon": -49.743333,
      "tz": "America\/Sao_Paulo"
  },
  "SSIZ": {
      "icao": "SSIZ",
      "iata": "",
      "name": "Condominio Fazenda da Grama Airport",
      "city": "Itupeva",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2257,
      "lat": -23.059167,
      "lon": -47.0625,
      "tz": "America\/Sao_Paulo"
  },
  "SSJA": {
      "icao": "SSJA",
      "iata": "JCB",
      "name": "Santa Terezinha Airport",
      "city": "Joacaba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2546,
      "lat": -27.1714000702,
      "lon": -51.5532989502,
      "tz": "America\/Sao_Paulo"
  },
  "SSJB": {
      "icao": "SSJB",
      "iata": "",
      "name": "Salenco Airport",
      "city": "Sao Gabriel D`Oeste",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1253,
      "lat": -18.8566665649,
      "lon": -54.3474998474,
      "tz": "America\/Campo_Grande"
  },
  "SSJC": {
      "icao": "SSJC",
      "iata": "",
      "name": "Fazenda do Jacui Airport",
      "city": "Guaiba",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 26,
      "lat": -29.9830551147,
      "lon": -51.3005561829,
      "tz": "America\/Sao_Paulo"
  },
  "SSJE": {
      "icao": "SSJE",
      "iata": "",
      "name": "Fazenda Sao Joaquim Airport",
      "city": "Selviria",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1325,
      "lat": -20.35222222,
      "lon": -51.55583333,
      "tz": "America\/Campo_Grande"
  },
  "SSJG": {
      "icao": "SSJG",
      "iata": "",
      "name": "Fazenda Santa Ana Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 351,
      "lat": -21.2163887024,
      "lon": -57.681388855,
      "tz": "America\/Campo_Grande"
  },
  "SSJH": {
      "icao": "SSJH",
      "iata": "",
      "name": "Fazenda Guarani Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 787,
      "lat": -22.1658325195,
      "lon": -53.5730552673,
      "tz": "America\/Campo_Grande"
  },
  "SSJI": {
      "icao": "SSJI",
      "iata": "",
      "name": "Jardim Airport",
      "city": "Jardim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1053,
      "lat": -21.4930992126,
      "lon": -56.1525993347,
      "tz": "America\/Campo_Grande"
  },
  "SSJJ": {
      "icao": "SSJJ",
      "iata": "",
      "name": "Fazenda Tupi Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1377,
      "lat": -21.8072223663,
      "lon": -53.1294441223,
      "tz": "America\/Campo_Grande"
  },
  "SSJK": {
      "icao": "SSJK",
      "iata": "0",
      "name": "Julio de Castilho Airport",
      "city": "Julio De Castilho",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1633,
      "lat": -29.1530990601,
      "lon": -53.7433013916,
      "tz": "America\/Sao_Paulo"
  },
  "SSJL": {
      "icao": "SSJL",
      "iata": "",
      "name": "Fazenda Jamaica Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1706,
      "lat": -19.3477783203,
      "lon": -53.7700004578,
      "tz": "America\/Campo_Grande"
  },
  "SSJM": {
      "icao": "SSJM",
      "iata": "",
      "name": "Fazenda Julio Martins Airport",
      "city": "Chapadao Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2621,
      "lat": -18.8425006866,
      "lon": -52.4994430542,
      "tz": "America\/Campo_Grande"
  },
  "SSJO": {
      "icao": "SSJO",
      "iata": "",
      "name": "Fazenda Angico Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 511,
      "lat": -19.3763885498,
      "lon": -57.4527778625,
      "tz": "America\/Campo_Grande"
  },
  "SSJP": {
      "icao": "SSJP",
      "iata": "",
      "name": "Fazenda Junqueira Airport",
      "city": "Colorado",
      "state": "Parana",
      "country": "BR",
      "elevation": 1427,
      "lat": -22.8950004578,
      "lon": -51.9291687012,
      "tz": "America\/Sao_Paulo"
  },
  "SSJQ": {
      "icao": "SSJQ",
      "iata": "",
      "name": "Fazenda Floresta Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1266,
      "lat": -12.81,
      "lon": -51.88,
      "tz": "America\/Cuiaba"
  },
  "SSJR": {
      "icao": "SSJR",
      "iata": "",
      "name": "Jaguarao Airport",
      "city": "Jaguarao",
      "state": "Cerro-Largo",
      "country": "BR",
      "elevation": 66,
      "lat": -32.5405578613,
      "lon": -53.3841667175,
      "tz": "America\/Sao_Paulo"
  },
  "SSJT": {
      "icao": "SSJT",
      "iata": "",
      "name": "Fazenda Rio Lagoa Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1082,
      "lat": -20.84889,
      "lon": -53.20667,
      "tz": "America\/Campo_Grande"
  },
  "SSJU": {
      "icao": "SSJU",
      "iata": "",
      "name": "Fazenda Santa Paula Airport",
      "city": "Inocencia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1200,
      "lat": -19.3813896179,
      "lon": -52.3097229004,
      "tz": "America\/Campo_Grande"
  },
  "SSJV": {
      "icao": "SSJV",
      "iata": "",
      "name": "Piratininga Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 150,
      "lat": -17.9424991608,
      "lon": -56.4799995422,
      "tz": "America\/Campo_Grande"
  },
  "SSJW": {
      "icao": "SSJW",
      "iata": "",
      "name": "Fazenda Jauru Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1017,
      "lat": -21.7824993134,
      "lon": -53.8922233582,
      "tz": "America\/Campo_Grande"
  },
  "SSJX": {
      "icao": "SSJX",
      "iata": "",
      "name": "Sao Bento Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 150,
      "lat": -19.478055954,
      "lon": -57.0141677856,
      "tz": "America\/Campo_Grande"
  },
  "SSJY": {
      "icao": "SSJY",
      "iata": "",
      "name": "Sao Joao Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 150,
      "lat": -17.7075004578,
      "lon": -56.2172203064,
      "tz": "America\/Campo_Grande"
  },
  "SSJZ": {
      "icao": "SSJZ",
      "iata": "",
      "name": "Fazenda Tres Unidos Airport",
      "city": "Terra Roxa",
      "state": "Parana",
      "country": "BR",
      "elevation": 1000,
      "lat": -24.0516662598,
      "lon": -54.0066680908,
      "tz": "America\/Sao_Paulo"
  },
  "SSKA": {
      "icao": "SSKA",
      "iata": "",
      "name": "Fazenda Campinas Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -18.9055557251,
      "lon": -56.3597221375,
      "tz": "America\/Campo_Grande"
  },
  "SSKB": {
      "icao": "SSKB",
      "iata": "",
      "name": "Fazenda Tupanciretan Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 312,
      "lat": -19.4744434357,
      "lon": -56.3772201538,
      "tz": "America\/Campo_Grande"
  },
  "SSKC": {
      "icao": "SSKC",
      "iata": "",
      "name": "Fazenda Uniao Airport",
      "city": "Inocencia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1758,
      "lat": -19.2924995422,
      "lon": -52.0608329773,
      "tz": "America\/Campo_Grande"
  },
  "SSKD": {
      "icao": "SSKD",
      "iata": "",
      "name": "Fazenda Uniao Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 410,
      "lat": -19.75,
      "lon": -55.4666671753,
      "tz": "America\/Campo_Grande"
  },
  "SSKE": {
      "icao": "SSKE",
      "iata": "",
      "name": "Campo Evelina Airport",
      "city": "Santa Monica",
      "state": "Parana",
      "country": "BR",
      "elevation": 984,
      "lat": -23.1211109161,
      "lon": -53.0763893127,
      "tz": "America\/Sao_Paulo"
  },
  "SSKF": {
      "icao": "SSKF",
      "iata": "",
      "name": "Porto Bonito Airport",
      "city": "Itaquirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1180,
      "lat": -23.3374996185,
      "lon": -53.869720459,
      "tz": "America\/Campo_Grande"
  },
  "SSKG": {
      "icao": "SSKG",
      "iata": "",
      "name": "Estancia Santa Maria Airport",
      "city": "Campo Grande",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2028,
      "lat": -20.5053005219,
      "lon": -54.5255012512,
      "tz": "America\/Campo_Grande"
  },
  "SSKH": {
      "icao": "SSKH",
      "iata": "",
      "name": "Fazenda Karl Hermann Isenberg Airport",
      "city": "Aral Moreira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1853,
      "lat": -22.9116668701,
      "lon": -55.5388908386,
      "tz": "America\/Campo_Grande"
  },
  "SSKI": {
      "icao": "SSKI",
      "iata": "",
      "name": "Fazenda Santa Helena Airport",
      "city": "Cornelio Procopio",
      "state": "",
      "country": "BR",
      "elevation": 1673,
      "lat": -23.340555191,
      "lon": -50.5830574036,
      "tz": "America\/Sao_Paulo"
  },
  "SSKJ": {
      "icao": "SSKJ",
      "iata": "",
      "name": "Fazenda Mestica Airport",
      "city": "Rio Branco Do Ivai",
      "state": "Parana",
      "country": "BR",
      "elevation": 1575,
      "lat": -24.3072223663,
      "lon": -51.4108314514,
      "tz": "America\/Sao_Paulo"
  },
  "SSKK": {
      "icao": "SSKK",
      "iata": "",
      "name": "Capao da Canoa Airport",
      "city": "Capao Da Canoa",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 33,
      "lat": -29.7636108398,
      "lon": -50.036945343,
      "tz": "America\/Sao_Paulo"
  },
  "SSKL": {
      "icao": "SSKL",
      "iata": "",
      "name": "Fazenda Piao Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1542,
      "lat": -23.4069442749,
      "lon": -55.2266654968,
      "tz": "America\/Campo_Grande"
  },
  "SSKM": {
      "icao": "SSKM",
      "iata": "CBW",
      "name": "Campo Mourao Airport",
      "city": "Campo Mourao",
      "state": "Parana",
      "country": "BR",
      "elevation": 1854,
      "lat": -24.0091991425,
      "lon": -52.3568000793,
      "tz": "America\/Sao_Paulo"
  },
  "SSKN": {
      "icao": "SSKN",
      "iata": "",
      "name": "Campo Novo Airport",
      "city": "Campo Novo",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1460,
      "lat": -27.6672000885,
      "lon": -53.8089981079,
      "tz": "America\/Sao_Paulo"
  },
  "SSKO": {
      "icao": "SSKO",
      "iata": "",
      "name": "Independencia Airport",
      "city": "Anastacio",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 544,
      "lat": -20.4761104584,
      "lon": -55.8336105347,
      "tz": "America\/Campo_Grande"
  },
  "SSKP": {
      "icao": "SSKP",
      "iata": "",
      "name": "Copacel Airport",
      "city": "Palotina",
      "state": "Parana",
      "country": "BR",
      "elevation": 1050,
      "lat": -24.308889389,
      "lon": -53.8422203064,
      "tz": "America\/Sao_Paulo"
  },
  "SSKQ": {
      "icao": "SSKQ",
      "iata": "",
      "name": "Fazenda Santa Rosa Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1001,
      "lat": -14.541111,
      "lon": -50.461944,
      "tz": "America\/Sao_Paulo"
  },
  "SSKR": {
      "icao": "SSKR",
      "iata": "",
      "name": "Fazenda Primavera Airport",
      "city": "Parauna",
      "state": "Goias",
      "country": "BR",
      "elevation": 2306,
      "lat": -17.17,
      "lon": -50.82,
      "tz": "America\/Sao_Paulo"
  },
  "SSKS": {
      "icao": "SSKS",
      "iata": "QDB",
      "name": "Cachoeira do Sul Airport",
      "city": "Cachoeira Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 253,
      "lat": -30.0018997192,
      "lon": -52.9407997131,
      "tz": "America\/Sao_Paulo"
  },
  "SSKT": {
      "icao": "SSKT",
      "iata": "",
      "name": "Aeroclube de Santa Catarina Airport",
      "city": "Sao Jose",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 20,
      "lat": -27.6116676331,
      "lon": -48.6727790833,
      "tz": "America\/Sao_Paulo"
  },
  "SSKU": {
      "icao": "SSKU",
      "iata": "QCR",
      "name": "Curitibanos Airport",
      "city": "Curitibanos",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 3133,
      "lat": -27.2824993134,
      "lon": -50.6114006042,
      "tz": "America\/Sao_Paulo"
  },
  "SSKV": {
      "icao": "SSKV",
      "iata": "",
      "name": "Retiro Sao Joao Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1738,
      "lat": -22.396944046,
      "lon": -55.4855575562,
      "tz": "America\/Campo_Grande"
  },
  "SSKW": {
      "icao": "SSKW",
      "iata": "OAL",
      "name": "Cacoal Airport",
      "city": "Cacoal",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 817,
      "lat": -11.493611,
      "lon": -61.175556,
      "tz": "America\/Porto_Velho"
  },
  "SSKX": {
      "icao": "SSKX",
      "iata": "",
      "name": "Fazenda Sao Luiz do Matao Airport",
      "city": "Carneirinho",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1250,
      "lat": -19.8722222222,
      "lon": -50.6547222222,
      "tz": "America\/Sao_Paulo"
  },
  "SSKY": {
      "icao": "SSKY",
      "iata": "",
      "name": "Fazenda Querencia Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2362,
      "lat": -14.4736111111,
      "lon": -58.3552777778,
      "tz": "America\/Cuiaba"
  },
  "SSKZ": {
      "icao": "SSKZ",
      "iata": "",
      "name": "Carazinho Airport",
      "city": "Carazinho",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1854,
      "lat": -28.3225002289,
      "lon": -52.8162002563,
      "tz": "America\/Sao_Paulo"
  },
  "SSLA": {
      "icao": "SSLA",
      "iata": "0",
      "name": "Laguna Airport",
      "city": "Laguna",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 20,
      "lat": -28.3766994476,
      "lon": -48.7428016663,
      "tz": "America\/Sao_Paulo"
  },
  "SSLB": {
      "icao": "SSLB",
      "iata": "",
      "name": "Fazenda Sao Paulino Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 394,
      "lat": -19.0252780914,
      "lon": -55.926109314,
      "tz": "America\/Campo_Grande"
  },
  "SSLC": {
      "icao": "SSLC",
      "iata": "",
      "name": "Fazenda Laguna Carem Airport",
      "city": "Laguna Carapa",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1755,
      "lat": -22.6733322144,
      "lon": -55.2366676331,
      "tz": "America\/Campo_Grande"
  },
  "SSLD": {
      "icao": "SSLD",
      "iata": "",
      "name": "Fazenda Lourdes Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -18.6438884735,
      "lon": -56.7594451904,
      "tz": "America\/Campo_Grande"
  },
  "SSLF": {
      "icao": "SSLF",
      "iata": "",
      "name": "Fazenda Palmeira Airport",
      "city": "Taquarussu",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 885,
      "lat": -22.5436115265,
      "lon": -53.4294433594,
      "tz": "America\/Campo_Grande"
  },
  "SSLG": {
      "icao": "SSLG",
      "iata": "",
      "name": "Sao Luis Gonzaga Airport",
      "city": "Sao Luis Gonzaga",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 656,
      "lat": -28.3763885498,
      "lon": -55.0374984741,
      "tz": "America\/Sao_Paulo"
  },
  "SSLI": {
      "icao": "SSLI",
      "iata": "LVB",
      "name": "Estancia Portal do Sol Airport",
      "city": "Itirapina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2425,
      "lat": -22.165278,
      "lon": -47.892778,
      "tz": "America\/Sao_Paulo"
  },
  "SSLJ": {
      "icao": "SSLJ",
      "iata": "",
      "name": "Fazenda Santa Izabel Airport",
      "city": "Flores De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1434,
      "lat": -14.265278,
      "lon": -47.0275,
      "tz": "America\/Sao_Paulo"
  },
  "SSLK": {
      "icao": "SSLK",
      "iata": "",
      "name": "Fazenda Aldeia Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 394,
      "lat": -18.2163887024,
      "lon": -55.1588897705,
      "tz": "America\/Campo_Grande"
  },
  "SSLL": {
      "icao": "SSLL",
      "iata": "",
      "name": "Fazenda Itaguassu Airport",
      "city": "Antonio Joao",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1788,
      "lat": -22.2436103821,
      "lon": -55.9316673279,
      "tz": "America\/Asuncion"
  },
  "SSLN": {
      "icao": "SSLN",
      "iata": "LOI",
      "name": "Helmuth Baungarten Airport",
      "city": "Lontras",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 1095,
      "lat": -27.1599998474,
      "lon": -49.5424995422,
      "tz": "America\/Sao_Paulo"
  },
  "SSLO": {
      "icao": "SSLO",
      "iata": "",
      "name": "Loanda Airport",
      "city": "Loanda",
      "state": "Parana",
      "country": "BR",
      "elevation": 1542,
      "lat": -22.9171009064,
      "lon": -53.1489982605,
      "tz": "America\/Sao_Paulo"
  },
  "SSLP": {
      "icao": "SSLP",
      "iata": "",
      "name": "Fazenda Lucero Pora Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 299,
      "lat": -21.9108333588,
      "lon": -57.6466674805,
      "tz": "America\/Campo_Grande"
  },
  "SSLQ": {
      "icao": "SSLQ",
      "iata": "",
      "name": "Fazenda Campanario Airport",
      "city": "Laguna Carapa",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1362,
      "lat": -22.7847213745,
      "lon": -55.0741653442,
      "tz": "America\/Campo_Grande"
  },
  "SSLR": {
      "icao": "SSLR",
      "iata": "",
      "name": "Ocorema Airport",
      "city": "Ladario",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 413,
      "lat": -19.1049995422,
      "lon": -57.593334198,
      "tz": "America\/Campo_Grande"
  },
  "SSLS": {
      "icao": "SSLS",
      "iata": "",
      "name": "Salto Santiago Airport",
      "city": "Laranjeiras Do Sul",
      "state": "Parana",
      "country": "BR",
      "elevation": 2087,
      "lat": -25.5149993896,
      "lon": -52.5574989319,
      "tz": "America\/Sao_Paulo"
  },
  "SSLT": {
      "icao": "SSLT",
      "iata": "ALQ",
      "name": "Alegrete Novo Airport",
      "city": "Alegrete",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 459,
      "lat": -29.8127002716,
      "lon": -55.8933982849,
      "tz": "America\/Sao_Paulo"
  },
  "SSLU": {
      "icao": "SSLU",
      "iata": "",
      "name": "Fazenda Aurora Airport",
      "city": "Jardim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 754,
      "lat": -21.5233325958,
      "lon": -56.6116676331,
      "tz": "America\/Campo_Grande"
  },
  "SSLW": {
      "icao": "SSLW",
      "iata": "",
      "name": "Fazenda Tres Cerros Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 853,
      "lat": -21.9733333588,
      "lon": -56.7975006104,
      "tz": "America\/Campo_Grande"
  },
  "SSLX": {
      "icao": "SSLX",
      "iata": "",
      "name": "Fazenda Brasilia do Sul Airport",
      "city": "Juti",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1296,
      "lat": -22.7049999237,
      "lon": -54.6083335876,
      "tz": "America\/Campo_Grande"
  },
  "SSLZ": {
      "icao": "SSLZ",
      "iata": "",
      "name": "Fazenda Vitoria do Ivinhema Airport",
      "city": "Ivinhema",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1296,
      "lat": -22.5197219849,
      "lon": -53.6647224426,
      "tz": "America\/Campo_Grande"
  },
  "SSMC": {
      "icao": "SSMC",
      "iata": "",
      "name": "Fazenda Sao Miguel da Catequese Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1209,
      "lat": -22.0877780914,
      "lon": -53.5200004578,
      "tz": "America\/Campo_Grande"
  },
  "SSMD": {
      "icao": "SSMD",
      "iata": "",
      "name": "Medianeira Airport",
      "city": "Medianeira",
      "state": "Parana",
      "country": "BR",
      "elevation": 1473,
      "lat": -25.3108997345,
      "lon": -54.0704994202,
      "tz": "America\/Sao_Paulo"
  },
  "SSME": {
      "icao": "SSME",
      "iata": "",
      "name": "Fazenda Ipiranga Airport",
      "city": "Anastacio",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 804,
      "lat": -20.980556488,
      "lon": -55.5658340454,
      "tz": "America\/Campo_Grande"
  },
  "SSMF": {
      "icao": "SSMF",
      "iata": "QMF",
      "name": "Mafra Airport",
      "city": "Mafra",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2690,
      "lat": -26.1588993073,
      "lon": -49.8321990967,
      "tz": "America\/Sao_Paulo"
  },
  "SSMH": {
      "icao": "SSMH",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Capao Do Leao",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 66,
      "lat": -31.9294433594,
      "lon": -52.4636116028,
      "tz": "America\/Sao_Paulo"
  },
  "SSMI": {
      "icao": "SSMI",
      "iata": "",
      "name": "Fazenda Morrinhos Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1148,
      "lat": -22.0838890076,
      "lon": -56.3005561829,
      "tz": "America\/Campo_Grande"
  },
  "SSMJ": {
      "icao": "SSMJ",
      "iata": "",
      "name": "Maracaju Airport",
      "city": "Maracaju",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1283,
      "lat": -21.6338882446,
      "lon": -55.1450004578,
      "tz": "America\/Campo_Grande"
  },
  "SSMK": {
      "icao": "SSMK",
      "iata": "",
      "name": "El Dorado Airport",
      "city": "Eldorado Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 20,
      "lat": -30.0733337402,
      "lon": -51.4097213745,
      "tz": "America\/Sao_Paulo"
  },
  "SSML": {
      "icao": "SSML",
      "iata": "",
      "name": "Fazenda Maria Luiza Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -23.5972213745,
      "lon": -54.6458320618,
      "tz": "America\/Campo_Grande"
  },
  "SSMN": {
      "icao": "SSMN",
      "iata": "",
      "name": "Kururuzinho Airport",
      "city": "Jacareacanga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1640,
      "lat": -8.88,
      "lon": -57.33,
      "tz": "America\/Cuiaba"
  },
  "SSMP": {
      "icao": "SSMP",
      "iata": "",
      "name": "Fazenda Tres Minas Airport",
      "city": "Itambe",
      "state": "Parana",
      "country": "BR",
      "elevation": 1391,
      "lat": -23.6727771759,
      "lon": -52.0097236633,
      "tz": "America\/Sao_Paulo"
  },
  "SSMR": {
      "icao": "SSMR",
      "iata": "",
      "name": "Manoel Ribas Airport",
      "city": "Manoel Ribas",
      "state": "Parana",
      "country": "BR",
      "elevation": 2822,
      "lat": -24.5296001434,
      "lon": -51.6519012451,
      "tz": "America\/Sao_Paulo"
  },
  "SSMS": {
      "icao": "SSMS",
      "iata": "",
      "name": "Fazenda Rancho Esperanca Airport",
      "city": "Aral Moreira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1522,
      "lat": -22.87,
      "lon": -55.5,
      "tz": "America\/Campo_Grande"
  },
  "SSMT": {
      "icao": "SSMT",
      "iata": "",
      "name": "Mostardas Airport",
      "city": "Mostardas",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 59,
      "lat": -31.1035995483,
      "lon": -50.9103012085,
      "tz": "America\/Sao_Paulo"
  },
  "SSMV": {
      "icao": "SSMV",
      "iata": "",
      "name": "Fazenda Boa Vista Airport",
      "city": "Pirassununga",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2434,
      "lat": -22.0425,
      "lon": -47.56,
      "tz": "America\/Sao_Paulo"
  },
  "SSMW": {
      "icao": "SSMW",
      "iata": "",
      "name": "Ica Airport",
      "city": "Peruibe",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 49,
      "lat": -24.3152770996,
      "lon": -47.0138893127,
      "tz": "America\/Sao_Paulo"
  },
  "SSMX": {
      "icao": "SSMX",
      "iata": "",
      "name": "Fazenda Planalto Airport",
      "city": "Nova Resende",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3868,
      "lat": -21.12,
      "lon": -46.42,
      "tz": "America\/Sao_Paulo"
  },
  "SSMY": {
      "icao": "SSMY",
      "iata": "",
      "name": "Sao Miguel do Iguacu Airport",
      "city": "Sao Miguel Do Iguacu",
      "state": "Parana",
      "country": "BR",
      "elevation": 951,
      "lat": -25.39888889,
      "lon": -54.23472222,
      "tz": "America\/Sao_Paulo"
  },
  "SSMZ": {
      "icao": "SSMZ",
      "iata": "",
      "name": "Fazenda Sao Mateus Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1070,
      "lat": -13.917222,
      "lon": -59.983333,
      "tz": "America\/Cuiaba"
  },
  "SSNA": {
      "icao": "SSNA",
      "iata": "",
      "name": "Estancia Miranda Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 436,
      "lat": -19.9799995422,
      "lon": -56.4338874817,
      "tz": "America\/Campo_Grande"
  },
  "SSNB": {
      "icao": "SSNB",
      "iata": "",
      "name": "Navirai Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1329,
      "lat": -23.0347995758,
      "lon": -54.1801986694,
      "tz": "America\/Campo_Grande"
  },
  "SSND": {
      "icao": "SSND",
      "iata": "",
      "name": "Santa Maria Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1119,
      "lat": -11.16,
      "lon": -52.82,
      "tz": "America\/Cuiaba"
  },
  "SSNF": {
      "icao": "SSNF",
      "iata": "",
      "name": "Fazenda Naisa Airport",
      "city": "Colombia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1739,
      "lat": -20.3297214508,
      "lon": -48.8100013733,
      "tz": "America\/Sao_Paulo"
  },
  "SSNG": {
      "icao": "SSNG",
      "iata": "QGF",
      "name": "Montenegro Airport",
      "city": "Montenegro",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 125,
      "lat": -29.7194004059,
      "lon": -51.4893989563,
      "tz": "America\/Sao_Paulo"
  },
  "SSNH": {
      "icao": "SSNH",
      "iata": "QHV",
      "name": "Novo Hamburgo Airport",
      "city": "Novo Hamburgo",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 66,
      "lat": -29.696100235,
      "lon": -51.0816993713,
      "tz": "America\/Sao_Paulo"
  },
  "SSNI": {
      "icao": "SSNI",
      "iata": "",
      "name": "Fazenda Novo Rumo Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1247,
      "lat": -22.9852771759,
      "lon": -53.8769454956,
      "tz": "America\/Campo_Grande"
  },
  "SSNJ": {
      "icao": "SSNJ",
      "iata": "",
      "name": "Fazenda Primavera Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 341,
      "lat": -19.5075,
      "lon": -55.90528,
      "tz": "America\/Campo_Grande"
  },
  "SSNK": {
      "icao": "SSNK",
      "iata": "",
      "name": "Sementes Petrovina Airport",
      "city": "Pedra Preta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2362,
      "lat": -16.8427772522,
      "lon": -54.0758323669,
      "tz": "America\/Cuiaba"
  },
  "SSNL": {
      "icao": "SSNL",
      "iata": "",
      "name": "Fazenda Essencia Airport",
      "city": "Nobres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1818,
      "lat": -14.316667,
      "lon": -55.766944,
      "tz": "America\/Cuiaba"
  },
  "SSNM": {
      "icao": "SSNM",
      "iata": "",
      "name": "Guaranta Airport",
      "city": "Guaranta Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 968,
      "lat": -9.9658327103,
      "lon": -54.8849983215,
      "tz": "America\/Cuiaba"
  },
  "SSNN": {
      "icao": "SSNN",
      "iata": "",
      "name": "Salvaterra II Airport",
      "city": "Joao Pinheiro",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1749,
      "lat": -17.1975002289,
      "lon": -45.7766685486,
      "tz": "America\/Sao_Paulo"
  },
  "SSNO": {
      "icao": "SSNO",
      "iata": "0",
      "name": "Nonoai Airport",
      "city": "Nonoai",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1969,
      "lat": -27.3672008514,
      "lon": -52.7338981628,
      "tz": "America\/Sao_Paulo"
  },
  "SSNP": {
      "icao": "SSNP",
      "iata": "0",
      "name": "Nova Prata Airport",
      "city": "Nova Prata",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2264,
      "lat": -28.8024997711,
      "lon": -51.6041984558,
      "tz": "America\/Sao_Paulo"
  },
  "SSNQ": {
      "icao": "SSNQ",
      "iata": "",
      "name": "Nioaque Airport",
      "city": "Nioaque",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 984,
      "lat": -21.1847229004,
      "lon": -55.8302764893,
      "tz": "America\/Campo_Grande"
  },
  "SSNR": {
      "icao": "SSNR",
      "iata": "",
      "name": "Fazenda Novo Horizonte Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 754,
      "lat": -18.318611145,
      "lon": -54.9355545044,
      "tz": "America\/Campo_Grande"
  },
  "SSNT": {
      "icao": "SSNT",
      "iata": "",
      "name": "Fazenda Rosa Mistica Airport",
      "city": "Amambai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1289,
      "lat": -23.2924995422,
      "lon": -55.0558319092,
      "tz": "America\/Campo_Grande"
  },
  "SSNU": {
      "icao": "SSNU",
      "iata": "",
      "name": "Santa Isabel Airport",
      "city": "Brotas",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2582,
      "lat": -22.2408332825,
      "lon": -48.0250015259,
      "tz": "America\/Sao_Paulo"
  },
  "SSNV": {
      "icao": "SSNV",
      "iata": "",
      "name": "Fazenda Novo Hamburgo Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 285,
      "lat": -19.2330551147,
      "lon": -57.0297203064,
      "tz": "America\/Campo_Grande"
  },
  "SSNW": {
      "icao": "SSNW",
      "iata": "",
      "name": "Fazenda Buriti Airport",
      "city": "Barreiras",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2408,
      "lat": -12.3024997711,
      "lon": -45.5139007568,
      "tz": "America\/Bahia"
  },
  "SSNY": {
      "icao": "SSNY",
      "iata": "",
      "name": "Fazenda Florida Airport",
      "city": "Caceres",
      "state": "",
      "country": "BR",
      "elevation": 541,
      "lat": -17.183611,
      "lon": -58.216667,
      "tz": "America\/Cuiaba"
  },
  "SSOC": {
      "icao": "SSOC",
      "iata": "",
      "name": "Fazenda Sans Souci - Sede Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 305,
      "lat": -19.0333328247,
      "lon": -56.9500007629,
      "tz": "America\/Campo_Grande"
  },
  "SSOD": {
      "icao": "SSOD",
      "iata": "",
      "name": "Fazenda Pouso Alegre Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 374,
      "lat": -20.2477779388,
      "lon": -56.4152793884,
      "tz": "America\/Campo_Grande"
  },
  "SSOE": {
      "icao": "SSOE",
      "iata": "SQX",
      "name": "Sao Miguel do Oeste Airport",
      "city": "Sao Miguel Do Oeste",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2180,
      "lat": -26.7816009521,
      "lon": -53.5035018921,
      "tz": "America\/Sao_Paulo"
  },
  "SSOF": {
      "icao": "SSOF",
      "iata": "",
      "name": "Fazenda Vale da Serra Airport",
      "city": "Monte Azul",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1575,
      "lat": -15.1888885498,
      "lon": -43.5311126709,
      "tz": "America\/Sao_Paulo"
  },
  "SSOG": {
      "icao": "SSOG",
      "iata": "APX",
      "name": "Arapongas Airport",
      "city": "Arapongas",
      "state": "Parana",
      "country": "BR",
      "elevation": 2599,
      "lat": -23.3528995514,
      "lon": -51.4916992188,
      "tz": "America\/Sao_Paulo"
  },
  "SSOI": {
      "icao": "SSOI",
      "iata": "",
      "name": "Pederneiras Airport",
      "city": "Pederneiras",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1788,
      "lat": -22.3072223663,
      "lon": -48.7719421387,
      "tz": "America\/Sao_Paulo"
  },
  "SSOJ": {
      "icao": "SSOJ",
      "iata": "",
      "name": "Fazenda Estrela Airport",
      "city": "Inocencia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1509,
      "lat": -19.979167,
      "lon": -51.735,
      "tz": "America\/Campo_Grande"
  },
  "SSOK": {
      "icao": "SSOK",
      "iata": "",
      "name": "14 Bis Airport",
      "city": "Londrina",
      "state": "Parana",
      "country": "BR",
      "elevation": 1932,
      "lat": -23.2141666412,
      "lon": -51.1858329773,
      "tz": "America\/Sao_Paulo"
  },
  "SSOL": {
      "icao": "SSOL",
      "iata": "",
      "name": "Lavras Airport",
      "city": "Lavras",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3146,
      "lat": -21.2406005859,
      "lon": -44.9639015198,
      "tz": "America\/Sao_Paulo"
  },
  "SSON": {
      "icao": "SSON",
      "iata": "",
      "name": "Fazenda Joalice Airport",
      "city": "Tacuru",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1476,
      "lat": -23.7877788544,
      "lon": -55.0291671753,
      "tz": "America\/Campo_Grande"
  },
  "SSOO": {
      "icao": "SSOO",
      "iata": "",
      "name": "Estancia J.D.S Airport",
      "city": "Fernao",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1903,
      "lat": -22.3372211456,
      "lon": -49.543056488,
      "tz": "America\/Sao_Paulo"
  },
  "SSOP": {
      "icao": "SSOP",
      "iata": "",
      "name": "Fazenda Cachoeirinha Airport",
      "city": "Aparecida Do Taboado",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1539,
      "lat": -20.0433330536,
      "lon": -51.4747238159,
      "tz": "America\/Campo_Grande"
  },
  "SSOQ": {
      "icao": "SSOQ",
      "iata": "",
      "name": "Fazenda Barranco Alto Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 502,
      "lat": -19.5777778625,
      "lon": -56.1522216797,
      "tz": "America\/Campo_Grande"
  },
  "SSOR": {
      "icao": "SSOR",
      "iata": "",
      "name": "Chico Ledur Airport",
      "city": "Alegrete",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 354,
      "lat": -29.8180561066,
      "lon": -55.7141685486,
      "tz": "America\/Sao_Paulo"
  },
  "SSOS": {
      "icao": "SSOS",
      "iata": "",
      "name": "Osorio Airport",
      "city": "Osorio",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 39,
      "lat": -29.9022216797,
      "lon": -50.2505569458,
      "tz": "America\/Sao_Paulo"
  },
  "SSOT": {
      "icao": "SSOT",
      "iata": "",
      "name": "Fazenda Saco da Tapera Airport",
      "city": "Sao Romao",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1946,
      "lat": -16.346944809,
      "lon": -45.3491668701,
      "tz": "America\/Sao_Paulo"
  },
  "SSOU": {
      "icao": "SSOU",
      "iata": "AIR",
      "name": "Fazenda Inhumas Airport",
      "city": "Paracatu",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1772,
      "lat": -17.0369434357,
      "lon": -46.2602767944,
      "tz": "America\/Sao_Paulo"
  },
  "SSOV": {
      "icao": "SSOV",
      "iata": "",
      "name": "Fazenda Hora Airport",
      "city": "Urucuia",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1837,
      "lat": -16.1466674805,
      "lon": -45.8250007629,
      "tz": "America\/Sao_Paulo"
  },
  "SSOW": {
      "icao": "SSOW",
      "iata": "",
      "name": "Comandante Carmelo Airport",
      "city": "Itaituba",
      "state": "Para",
      "country": "BR",
      "elevation": 974,
      "lat": -6.84,
      "lon": -56.586667,
      "tz": "America\/Santarem"
  },
  "SSOY": {
      "icao": "SSOY",
      "iata": "",
      "name": "FazendaMorada do Sol Airport",
      "city": "Nova Maringa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 971,
      "lat": -12.290556,
      "lon": -57.019444,
      "tz": "America\/Cuiaba"
  },
  "SSPB": {
      "icao": "SSPB",
      "iata": "PTO",
      "name": "Pato Branco Airport",
      "city": "Pato Branco",
      "state": "Parana",
      "country": "BR",
      "elevation": 2697,
      "lat": -26.2178001404,
      "lon": -52.6943016052,
      "tz": "America\/Sao_Paulo"
  },
  "SSPC": {
      "icao": "SSPC",
      "iata": "",
      "name": "Fazenda Santa Julia Airport",
      "city": "Cocalinho",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 866,
      "lat": -13.731667,
      "lon": -51.34,
      "tz": "America\/Cuiaba"
  },
  "SSPD": {
      "icao": "SSPD",
      "iata": "",
      "name": "Fazenda Paragua Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 922,
      "lat": -14.766667,
      "lon": -60.033333,
      "tz": "America\/Cuiaba"
  },
  "SSPE": {
      "icao": "SSPE",
      "iata": "",
      "name": "Essencia Retiro do Rio Cuiaba Airport",
      "city": "Nobres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 837,
      "lat": -14.435556,
      "lon": -55.609444,
      "tz": "America\/Cuiaba"
  },
  "SSPF": {
      "icao": "SSPF",
      "iata": "",
      "name": "Fazenda Sao Rafael Airport",
      "city": "Sao Jose Das Palmeiras",
      "state": "Parana",
      "country": "BR",
      "elevation": 1420,
      "lat": -24.7695007324,
      "lon": -54.1186981201,
      "tz": "America\/Sao_Paulo"
  },
  "SSPG": {
      "icao": "SSPG",
      "iata": "PNG",
      "name": "Paranagua Airport",
      "city": "Paranagua",
      "state": "Parana",
      "country": "BR",
      "elevation": 16,
      "lat": -25.5401000977,
      "lon": -48.5312004089,
      "tz": "America\/Sao_Paulo"
  },
  "SSPI": {
      "icao": "SSPI",
      "iata": "PVI",
      "name": "Paranavai Airport",
      "city": "Paranavai",
      "state": "Parana",
      "country": "BR",
      "elevation": 1526,
      "lat": -23.0898990631,
      "lon": -52.4884986877,
      "tz": "America\/Sao_Paulo"
  },
  "SSPJ": {
      "icao": "SSPJ",
      "iata": "",
      "name": "Fazenda Reserva Airport",
      "city": "Reserva Do Iguacu",
      "state": "Parana",
      "country": "BR",
      "elevation": 3438,
      "lat": -25.8505554199,
      "lon": -52.0172233582,
      "tz": "America\/Sao_Paulo"
  },
  "SSPK": {
      "icao": "SSPK",
      "iata": "",
      "name": "Porecatu Airport",
      "city": "Porecatu",
      "state": "Parana",
      "country": "BR",
      "elevation": 1424,
      "lat": -22.778799057,
      "lon": -51.3614006042,
      "tz": "America\/Sao_Paulo"
  },
  "SSPL": {
      "icao": "SSPL",
      "iata": "",
      "name": "Palmeira das Missoes Airport",
      "city": "Palmeira Da Missoes",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2083,
      "lat": -27.9018001556,
      "lon": -53.32970047,
      "tz": "America\/Sao_Paulo"
  },
  "SSPM": {
      "icao": "SSPM",
      "iata": "",
      "name": "Porto Murtinho Airport",
      "city": "Porto Murtinho",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 260,
      "lat": -21.7096004486,
      "lon": -57.8801994324,
      "tz": "America\/Asuncion"
  },
  "SSPN": {
      "icao": "SSPN",
      "iata": "PBB",
      "name": "Paranaiba Airport",
      "city": "Paranaiba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1446,
      "lat": -19.6511993408,
      "lon": -51.1994018555,
      "tz": "America\/Campo_Grande"
  },
  "SSPO": {
      "icao": "SSPO",
      "iata": "",
      "name": "Aplicacao Aviacao Agricola II Airport",
      "city": "Itapora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1165,
      "lat": -22.0091667175,
      "lon": -54.7897224426,
      "tz": "America\/Campo_Grande"
  },
  "SSPP": {
      "icao": "SSPP",
      "iata": "",
      "name": "Fazenda Palma Airport",
      "city": "Pelotas",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 39,
      "lat": -31.6569442749,
      "lon": -52.2077789307,
      "tz": "America\/Sao_Paulo"
  },
  "SSPQ": {
      "icao": "SSPQ",
      "iata": "",
      "name": "Santa Rosa do Purus Airport",
      "city": "Santa Rosa Do Purus",
      "state": "",
      "country": "BR",
      "elevation": 751,
      "lat": -9.446388889,
      "lon": -70.48277778,
      "tz": "America\/Rio_Branco"
  },
  "SSPR": {
      "icao": "SSPR",
      "iata": "",
      "name": "Porto Walter Airport",
      "city": "Porto Walter",
      "state": "Acre",
      "country": "BR",
      "elevation": 794,
      "lat": -8.271667,
      "lon": -72.745278,
      "tz": "America\/Rio_Branco"
  },
  "SSPS": {
      "icao": "SSPS",
      "iata": "",
      "name": "Palmas Airport",
      "city": "Palmas",
      "state": "Parana",
      "country": "BR",
      "elevation": 3609,
      "lat": -26.4720001221,
      "lon": -51.9758987427,
      "tz": "America\/Sao_Paulo"
  },
  "SSPT": {
      "icao": "SSPT",
      "iata": "",
      "name": "Palotina Airport",
      "city": "Palotina",
      "state": "Parana",
      "country": "BR",
      "elevation": 1220,
      "lat": -24.3432998657,
      "lon": -53.8287010193,
      "tz": "America\/Sao_Paulo"
  },
  "SSPV": {
      "icao": "SSPV",
      "iata": "",
      "name": "Marechal Thaumaturgo Airport",
      "city": "Marechal Thaumaturgo",
      "state": "Acre",
      "country": "BR",
      "elevation": 751,
      "lat": -8.959444,
      "lon": -72.779722,
      "tz": "America\/Rio_Branco"
  },
  "SSPX": {
      "icao": "SSPX",
      "iata": "",
      "name": "Manoel Urbano Airport",
      "city": "Manoel Urbano",
      "state": "Acre",
      "country": "BR",
      "elevation": 531,
      "lat": -8.849167,
      "lon": -69.266389,
      "tz": "America\/Rio_Branco"
  },
  "SSPY": {
      "icao": "SSPY",
      "iata": "",
      "name": "Porto Indio Airport",
      "city": "Corumba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 390,
      "lat": -17.6697216034,
      "lon": -57.7530555725,
      "tz": "America\/Campo_Grande"
  },
  "SSQA": {
      "icao": "SSQA",
      "iata": "",
      "name": "Fazenda Guapore Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 830,
      "lat": -13.7230558395,
      "lon": -60.3152770996,
      "tz": "America\/Cuiaba"
  },
  "SSQB": {
      "icao": "SSQB",
      "iata": "",
      "name": "Jordao Airport",
      "city": "Jordao",
      "state": "Acre",
      "country": "BR",
      "elevation": 1197,
      "lat": -9.189444,
      "lon": -71.946944,
      "tz": "America\/Rio_Branco"
  },
  "SSQC": {
      "icao": "SSQC",
      "iata": "",
      "name": "Siqueira Campos Airport",
      "city": "Siqueira Campos",
      "state": "Parana",
      "country": "BR",
      "elevation": 2313,
      "lat": -23.6744995117,
      "lon": -49.8153991699,
      "tz": "America\/Sao_Paulo"
  },
  "SSQF": {
      "icao": "SSQF",
      "iata": "",
      "name": "Fazenda Floresta Negra Airport",
      "city": "Sete Quedas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1263,
      "lat": -23.8141002655,
      "lon": -54.6987991333,
      "tz": "America\/Asuncion"
  },
  "SSQI": {
      "icao": "SSQI",
      "iata": "",
      "name": "Santa Anastacia Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1250,
      "lat": -12.5091666667,
      "lon": -55.6847222222,
      "tz": "America\/Cuiaba"
  },
  "SSQL": {
      "icao": "SSQL",
      "iata": "",
      "name": "Fazenda Matricha Airport",
      "city": "Uirapuru",
      "state": "Goias",
      "country": "BR",
      "elevation": 3609,
      "lat": -14.1141672134,
      "lon": -50.1222229004,
      "tz": "America\/Sao_Paulo"
  },
  "SSQM": {
      "icao": "SSQM",
      "iata": "0",
      "name": "Tanque Novo Airport",
      "city": "Tanque Novo",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2464,
      "lat": -13.545800209,
      "lon": -42.4799995422,
      "tz": "America\/Bahia"
  },
  "SSQN": {
      "icao": "SSQN",
      "iata": "0",
      "name": "Mundo Novo Airport",
      "city": "Mundo Novo",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1788,
      "lat": -11.7721996307,
      "lon": -40.4193992615,
      "tz": "America\/Bahia"
  },
  "SSQO": {
      "icao": "SSQO",
      "iata": "",
      "name": "Fazenda Itapora Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1590,
      "lat": -20.0827789307,
      "lon": -53.8886108398,
      "tz": "America\/Campo_Grande"
  },
  "SSQP": {
      "icao": "SSQP",
      "iata": "",
      "name": "Monte Santo Airport",
      "city": "Monte Santo",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1362,
      "lat": -10.4783334732,
      "lon": -39.2874984741,
      "tz": "America\/Bahia"
  },
  "SSQR": {
      "icao": "SSQR",
      "iata": "",
      "name": "Fazenda Primavera Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1286,
      "lat": -21.388611,
      "lon": -53.600556,
      "tz": "America\/Campo_Grande"
  },
  "SSQT": {
      "icao": "SSQT",
      "iata": "QAC",
      "name": "Castro Airport",
      "city": "Castro",
      "state": "Parana",
      "country": "BR",
      "elevation": 3314,
      "lat": -24.8075008392,
      "lon": -49.9603004456,
      "tz": "America\/Sao_Paulo"
  },
  "SSQU": {
      "icao": "SSQU",
      "iata": "",
      "name": "Fazenda Alegrete - Retiro Carabda Airport",
      "city": "Aquidauana",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 410,
      "lat": -20.0647220612,
      "lon": -55.7016677856,
      "tz": "America\/Campo_Grande"
  },
  "SSQZ": {
      "icao": "SSQZ",
      "iata": "",
      "name": "Luiz Eduardo Magalhaes Airport",
      "city": "Mimoso Do Oeste",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2408,
      "lat": -12.1066999435,
      "lon": -45.8945999146,
      "tz": "America\/Bahia"
  },
  "SSRA": {
      "icao": "SSRA",
      "iata": "",
      "name": "Fazenda Rancho Belo Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1384,
      "lat": -21.7083320618,
      "lon": -53.099445343,
      "tz": "America\/Campo_Grande"
  },
  "SSRB": {
      "icao": "SSRB",
      "iata": "",
      "name": "Rio Brilhante Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1024,
      "lat": -21.8477783203,
      "lon": -54.5277786255,
      "tz": "America\/Campo_Grande"
  },
  "SSRC": {
      "icao": "SSRC",
      "iata": "",
      "name": "Fazenda Santa Ines Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1084,
      "lat": -21.7030563354,
      "lon": -54.3683319092,
      "tz": "America\/Campo_Grande"
  },
  "SSRD": {
      "icao": "SSRD",
      "iata": "",
      "name": "Fazenda Sol Nascente Airport",
      "city": "Tacuru",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1443,
      "lat": -23.3974990845,
      "lon": -55.2483329773,
      "tz": "America\/Campo_Grande"
  },
  "SSRE": {
      "icao": "SSRE",
      "iata": "",
      "name": "Realeza Airport",
      "city": "Realeza",
      "state": "Parana",
      "country": "BR",
      "elevation": 1693,
      "lat": -25.8012008667,
      "lon": -53.5126991272,
      "tz": "America\/Sao_Paulo"
  },
  "SSRF": {
      "icao": "SSRF",
      "iata": "",
      "name": "Castro Alves Airport",
      "city": "Castro Alves",
      "state": "Bahia",
      "country": "BR",
      "elevation": 869,
      "lat": -12.7657003403,
      "lon": -39.4467010498,
      "tz": "America\/Bahia"
  },
  "SSRG": {
      "icao": "SSRG",
      "iata": "",
      "name": "Fazenda Rancho Grande Airport",
      "city": "Laguna Carapa",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1319,
      "lat": -22.7766666412,
      "lon": -54.9766654968,
      "tz": "America\/Campo_Grande"
  },
  "SSRH": {
      "icao": "SSRH",
      "iata": "",
      "name": "Fazenda Retiro do Marimbondo Airport",
      "city": "Anastacio",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 577,
      "lat": -20.778055191,
      "lon": -55.9827766418,
      "tz": "America\/Campo_Grande"
  },
  "SSRI": {
      "icao": "SSRI",
      "iata": "",
      "name": "Fazenda Dos Sonhos Airport",
      "city": "Anastacio",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 551,
      "lat": -20.7588882446,
      "lon": -56.0325012207,
      "tz": "America\/Campo_Grande"
  },
  "SSRJ": {
      "icao": "SSRJ",
      "iata": "",
      "name": "Fazenda Jequitiba Airport",
      "city": "Nova Alvorada Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1181,
      "lat": -21.8733329773,
      "lon": -53.8127784729,
      "tz": "America\/Campo_Grande"
  },
  "SSRK": {
      "icao": "SSRK",
      "iata": "",
      "name": "Campo Alegre de Lourdes Airport",
      "city": "Campo Alegre De Lourdes",
      "state": "Bahia",
      "country": "BR",
      "elevation": 1519,
      "lat": -9.5139102936,
      "lon": -42.995098114,
      "tz": "America\/Bahia"
  },
  "SSRL": {
      "icao": "SSRL",
      "iata": "",
      "name": "Fazenda Santa Ana Airport",
      "city": "Deodapolis",
      "state": "",
      "country": "BR",
      "elevation": 1001,
      "lat": -22.0372219086,
      "lon": -54.1888885498,
      "tz": "America\/Campo_Grande"
  },
  "SSRM": {
      "icao": "SSRM",
      "iata": "",
      "name": "Fazenda Sao Sebastiao Airport",
      "city": "Bonito",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 791,
      "lat": -21.019443512,
      "lon": -56.2761116028,
      "tz": "America\/Campo_Grande"
  },
  "SSRN": {
      "icao": "SSRN",
      "iata": "",
      "name": "Aeroclube de Rio Negrinho Airport",
      "city": "Rio Negrinho",
      "state": "Parana",
      "country": "BR",
      "elevation": 3025,
      "lat": -26.3199996948,
      "lon": -49.5205574036,
      "tz": "America\/Sao_Paulo"
  },
  "SSRO": {
      "icao": "SSRO",
      "iata": "",
      "name": "Fazenda Santa Rosa Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1217,
      "lat": -22.8916664124,
      "lon": -54.1169433594,
      "tz": "America\/Campo_Grande"
  },
  "SSRQ": {
      "icao": "SSRQ",
      "iata": "",
      "name": "Refugio Airport",
      "city": "Dom Pedrito",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 709,
      "lat": -30.9191665649,
      "lon": -54.4677772522,
      "tz": "America\/Sao_Paulo"
  },
  "SSRS": {
      "icao": "SSRS",
      "iata": "",
      "name": "Rancho Sumidor Airport",
      "city": "Rio Do Oeste",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 1476,
      "lat": -27.1919441223,
      "lon": -49.8313903809,
      "tz": "America\/Sao_Paulo"
  },
  "SSRT": {
      "icao": "SSRT",
      "iata": "",
      "name": "Fazenda Mangal Airport",
      "city": "Curua",
      "state": "Para",
      "country": "BR",
      "elevation": 289,
      "lat": -1.5413889885,
      "lon": -55.180557251,
      "tz": "America\/Santarem"
  },
  "SSRU": {
      "icao": "SSRU",
      "iata": "SQY",
      "name": "Sao Lourenco do Sul Airport",
      "city": "Sao Lourenco Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 28,
      "lat": -31.3833007813,
      "lon": -52.0327987671,
      "tz": "America\/Sao_Paulo"
  },
  "SSRX": {
      "icao": "SSRX",
      "iata": "",
      "name": "Fazenda Marrua Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 591,
      "lat": -12.779999733,
      "lon": -51.11277771,
      "tz": "America\/Cuiaba"
  },
  "SSRY": {
      "icao": "SSRY",
      "iata": "",
      "name": "Rio Pardo Airport",
      "city": "Rio Pardo",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 230,
      "lat": -29.9727783203,
      "lon": -52.3611106873,
      "tz": "America\/Sao_Paulo"
  },
  "SSRZ": {
      "icao": "SSRZ",
      "iata": "",
      "name": "Rosario do Sul Airport",
      "city": "Rosario Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 348,
      "lat": -30.2775001526,
      "lon": -54.92527771,
      "tz": "America\/Sao_Paulo"
  },
  "SSSA": {
      "icao": "SSSA",
      "iata": "",
      "name": "Fazenda Sant`anna do Apa Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1047,
      "lat": -22.0266666412,
      "lon": -56.193611145,
      "tz": "America\/Campo_Grande"
  },
  "SSSB": {
      "icao": "SSSB",
      "iata": "QOJ",
      "name": "Sao Borja Airport",
      "city": "Sao Borja",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 246,
      "lat": -28.6548995972,
      "lon": -56.0345993042,
      "tz": "America\/Sao_Paulo"
  },
  "SSSC": {
      "icao": "SSSC",
      "iata": "CSU",
      "name": "Santa Cruz do Sul Airport",
      "city": "Santa Cruz Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 646,
      "lat": -29.6840991974,
      "lon": -52.4122009277,
      "tz": "America\/Sao_Paulo"
  },
  "SSSD": {
      "icao": "SSSD",
      "iata": "0",
      "name": "Soledade Airport",
      "city": "Soledade",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2165,
      "lat": -28.8631000519,
      "lon": -52.540599823,
      "tz": "America\/Sao_Paulo"
  },
  "SSSG": {
      "icao": "SSSG",
      "iata": "0",
      "name": "Sao Gabriel Airport",
      "city": "Sao Gabriel",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 472,
      "lat": -30.3449993134,
      "lon": -54.2677993774,
      "tz": "America\/Sao_Paulo"
  },
  "SSSH": {
      "icao": "SSSH",
      "iata": "",
      "name": "Fazenda Santo Inacio Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 275,
      "lat": -19.1991672516,
      "lon": -57.0111122131,
      "tz": "America\/Campo_Grande"
  },
  "SSSO": {
      "icao": "SSSO",
      "iata": "",
      "name": "Fazenda Santa Odila Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 984,
      "lat": -21.4111118317,
      "lon": -52.0325012207,
      "tz": "America\/Campo_Grande"
  },
  "SSSP": {
      "icao": "SSSP",
      "iata": "",
      "name": "Fazenda Santa Delfina Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -20.0402774811,
      "lon": -56.3100013733,
      "tz": "America\/Campo_Grande"
  },
  "SSSQ": {
      "icao": "SSSQ",
      "iata": "0",
      "name": "Sao Joaquim Airport",
      "city": "Sao Joaquim",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 4462,
      "lat": -28.2588996887,
      "lon": -49.9157981873,
      "tz": "America\/Sao_Paulo"
  },
  "SSSR": {
      "icao": "SSSR",
      "iata": "",
      "name": "Fazenda Santa Rosa Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1476,
      "lat": -20.3163890839,
      "lon": -52.7333335876,
      "tz": "America\/Campo_Grande"
  },
  "SSSS": {
      "icao": "SSSS",
      "iata": "",
      "name": "Sao Francisco do Sul Airport",
      "city": "Sao Francisco Do Sul",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 49,
      "lat": -26.2211112976,
      "lon": -48.5644454956,
      "tz": "America\/Sao_Paulo"
  },
  "SSST": {
      "icao": "SSST",
      "iata": "",
      "name": "Santiago Airport",
      "city": "Santiago",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1460,
      "lat": -29.2150001526,
      "lon": -54.8422203064,
      "tz": "America\/Sao_Paulo"
  },
  "SSSW": {
      "icao": "SSSW",
      "iata": "",
      "name": "Fazenda Santa Maria de Inocencia Airport",
      "city": "Inocencia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1575,
      "lat": -19.7211112976,
      "lon": -51.9502792358,
      "tz": "America\/Campo_Grande"
  },
  "SSSX": {
      "icao": "SSSX",
      "iata": "",
      "name": "Fazenda Sans Souci - Retiro Mutum Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 300,
      "lat": -18.9333324432,
      "lon": -57.0166664124,
      "tz": "America\/Campo_Grande"
  },
  "SSSY": {
      "icao": "SSSY",
      "iata": "",
      "name": "Fazenda Silvana Airport",
      "city": "Nova Andradina",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 781,
      "lat": -22.5166664124,
      "lon": -53.5116653442,
      "tz": "America\/Campo_Grande"
  },
  "SSSZ": {
      "icao": "SSSZ",
      "iata": "0",
      "name": "Sertanopolis Airport",
      "city": "Sertanopolis",
      "state": "Parana",
      "country": "BR",
      "elevation": 1181,
      "lat": -23.0622005463,
      "lon": -51.0139007568,
      "tz": "America\/Sao_Paulo"
  },
  "SSTA": {
      "icao": "SSTA",
      "iata": "",
      "name": "Santa Maria Airport",
      "city": "Sao Gabriel",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 709,
      "lat": -30.2458324432,
      "lon": -54.8008346558,
      "tz": "America\/Sao_Paulo"
  },
  "SSTB": {
      "icao": "SSTB",
      "iata": "",
      "name": "Tres Barras Airport",
      "city": "Tres Barras",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2559,
      "lat": -26.135799408,
      "lon": -50.3109016418,
      "tz": "America\/Sao_Paulo"
  },
  "SSTD": {
      "icao": "SSTD",
      "iata": "",
      "name": "Fazenda Arco de Canto Airport",
      "city": "Andre Da Rocha",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2311,
      "lat": -28.5508327484,
      "lon": -51.5961112976,
      "tz": "America\/Sao_Paulo"
  },
  "SSTF": {
      "icao": "SSTF",
      "iata": "",
      "name": "Fazenda Triunfo Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 328,
      "lat": -17.8724994659,
      "lon": -57.1300010681,
      "tz": "America\/Campo_Grande"
  },
  "SSTG": {
      "icao": "SSTG",
      "iata": "",
      "name": "Fazenda Santa Terezinha Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 525,
      "lat": -18.4694442749,
      "lon": -57.0550003052,
      "tz": "America\/Campo_Grande"
  },
  "SSTH": {
      "icao": "SSTH",
      "iata": "",
      "name": "Fazenda Thalia Airport",
      "city": "Balsa Nova",
      "state": "Parana",
      "country": "BR",
      "elevation": 3346,
      "lat": -25.5205001831,
      "lon": -49.6819992065,
      "tz": "America\/Sao_Paulo"
  },
  "SSTI": {
      "icao": "SSTI",
      "iata": "",
      "name": "Arraial d`Ajuda Airport",
      "city": "Porto Seguro",
      "state": "Bahia",
      "country": "BR",
      "elevation": 279,
      "lat": -16.457778,
      "lon": -39.165833,
      "tz": "America\/Bahia"
  },
  "SSTJ": {
      "icao": "SSTJ",
      "iata": "",
      "name": "Walter Ewaldo Siegel Airport",
      "city": "Trombudo Central",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 1322,
      "lat": -27.3491668701,
      "lon": -49.818611145,
      "tz": "America\/Sao_Paulo"
  },
  "SSTK": {
      "icao": "SSTK",
      "iata": "",
      "name": "Fazenda Uniao de Iguatemi Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 794,
      "lat": -23.6827774048,
      "lon": -54.5397224426,
      "tz": "America\/Campo_Grande"
  },
  "SSTL": {
      "icao": "SSTL",
      "iata": "",
      "name": "Tres Lagoas Airport",
      "city": "Tres Lagoas",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1050,
      "lat": -20.7541999817,
      "lon": -51.6842002869,
      "tz": "America\/Campo_Grande"
  },
  "SSTN": {
      "icao": "SSTN",
      "iata": "",
      "name": "Alberto Bertelli Airport",
      "city": "Araraquara",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2283,
      "lat": -21.6786117554,
      "lon": -48.2119445801,
      "tz": "America\/Sao_Paulo"
  },
  "SSTO": {
      "icao": "SSTO",
      "iata": "0",
      "name": "Tres Passos Airport",
      "city": "Tres Passos",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1503,
      "lat": -27.5125007629,
      "lon": -53.8983001709,
      "tz": "America\/Sao_Paulo"
  },
  "SSTP": {
      "icao": "SSTP",
      "iata": "",
      "name": "Fazenda Baessa Airport",
      "city": "Turvelandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1591,
      "lat": -17.970833,
      "lon": -50.320556,
      "tz": "America\/Sao_Paulo"
  },
  "SSTQ": {
      "icao": "SSTQ",
      "iata": "",
      "name": "Silvestre Airport",
      "city": "Pedra Branca Do Amapari",
      "state": "Amapa",
      "country": "BR",
      "elevation": 459,
      "lat": 0.886389,
      "lon": -51.879444,
      "tz": "America\/Belem"
  },
  "SSTS": {
      "icao": "SSTS",
      "iata": "",
      "name": "Fazenda Bandeirantes Airport",
      "city": "Taquarussu",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 813,
      "lat": -22.6088886261,
      "lon": -53.4472236633,
      "tz": "America\/Campo_Grande"
  },
  "SSTU": {
      "icao": "SSTU",
      "iata": "",
      "name": "Fazenda Jauru Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1250,
      "lat": -18.7299995422,
      "lon": -54.1049995422,
      "tz": "America\/Campo_Grande"
  },
  "SSTY": {
      "icao": "SSTY",
      "iata": "",
      "name": "Usina Foz do Chopim Airport",
      "city": "Cruzeiro Do Iguacu",
      "state": "Parana",
      "country": "BR",
      "elevation": 1640,
      "lat": -25.5625,
      "lon": -53.1769447327,
      "tz": "America\/Sao_Paulo"
  },
  "SSUA": {
      "icao": "SSUA",
      "iata": "",
      "name": "Fazenda Sonora Estancia Airport",
      "city": "Sonora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1575,
      "lat": -17.631111145,
      "lon": -54.7700004578,
      "tz": "America\/Campo_Grande"
  },
  "SSUC": {
      "icao": "SSUC",
      "iata": "",
      "name": "Fazenda Aurora II Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1194,
      "lat": -21.4911117554,
      "lon": -53.2038879395,
      "tz": "America\/Campo_Grande"
  },
  "SSUL": {
      "icao": "SSUL",
      "iata": "",
      "name": "Ultraleve Clube Curitiba Airport",
      "city": "Sao Jose Dos Pinhais",
      "state": "Parana",
      "country": "BR",
      "elevation": 3215,
      "lat": -25.4769439697,
      "lon": -49.1541671753,
      "tz": "America\/Sao_Paulo"
  },
  "SSUM": {
      "icao": "SSUM",
      "iata": "UMU",
      "name": "Umuarama Airport",
      "city": "Umuarama",
      "state": "Parana",
      "country": "BR",
      "elevation": 1558,
      "lat": -23.7987003326,
      "lon": -53.3138008118,
      "tz": "America\/Sao_Paulo"
  },
  "SSUQ": {
      "icao": "SSUQ",
      "iata": "",
      "name": "Fazenda Santa Olinda Airport",
      "city": "Sidrolandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 915,
      "lat": -20.7549991608,
      "lon": -55.0433311462,
      "tz": "America\/Campo_Grande"
  },
  "SSUR": {
      "icao": "SSUR",
      "iata": "",
      "name": "Fazenda Agua Branca Airport",
      "city": "Santa Rita Do Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1264,
      "lat": -21.5836105347,
      "lon": -52.8722229004,
      "tz": "America\/Campo_Grande"
  },
  "SSUT": {
      "icao": "SSUT",
      "iata": "",
      "name": "Fazenda Agrofel Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 705,
      "lat": -14.4444437027,
      "lon": -58.2977790833,
      "tz": "America\/Cuiaba"
  },
  "SSUU": {
      "icao": "SSUU",
      "iata": "",
      "name": "Fazenda Colorado Airport",
      "city": "Lagoa Da Confusao",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 761,
      "lat": -10.7416667938,
      "lon": -49.4799995422,
      "tz": "America\/Araguaina"
  },
  "SSUV": {
      "icao": "SSUV",
      "iata": "QVB",
      "name": "Uniao da Vitoria Airport",
      "city": "Uniao Da Vitoria",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2467,
      "lat": -26.2334003448,
      "lon": -51.0677986145,
      "tz": "America\/Sao_Paulo"
  },
  "SSUX": {
      "icao": "SSUX",
      "iata": "0",
      "name": "Usina Mandu Airport",
      "city": "Guaira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1597,
      "lat": -20.4822006226,
      "lon": -48.3727989197,
      "tz": "America\/Sao_Paulo"
  },
  "SSUY": {
      "icao": "SSUY",
      "iata": "",
      "name": "Fazenda Menina Airport",
      "city": "Itapura",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1115,
      "lat": -20.640499115,
      "lon": -51.4768981934,
      "tz": "America\/Sao_Paulo"
  },
  "SSUZ": {
      "icao": "SSUZ",
      "iata": "",
      "name": "Fazenda Vista Alegre Airport",
      "city": "Sud Menucci",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1322,
      "lat": -20.6616668701,
      "lon": -50.8416671753,
      "tz": "America\/Sao_Paulo"
  },
  "SSVA": {
      "icao": "SSVA",
      "iata": "",
      "name": "Fazenda Visa Estancia Airport",
      "city": "Ladario",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 805,
      "lat": -19.0625,
      "lon": -57.6291656494,
      "tz": "America\/Campo_Grande"
  },
  "SSVB": {
      "icao": "SSVB",
      "iata": "",
      "name": "Fazenda Vaca Branca Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1086,
      "lat": -23.0672225952,
      "lon": -53.8199996948,
      "tz": "America\/Campo_Grande"
  },
  "SSVC": {
      "icao": "SSVC",
      "iata": "",
      "name": "Vacaria Airport",
      "city": "Vacaria",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 3215,
      "lat": -28.509721756,
      "lon": -50.9183311462,
      "tz": "America\/Sao_Paulo"
  },
  "SSVD": {
      "icao": "SSVD",
      "iata": "",
      "name": "Fazenda Alvorada Airport",
      "city": "Quarto Centenario",
      "state": "Parana",
      "country": "BR",
      "elevation": 1312,
      "lat": -24.2947216034,
      "lon": -53.25,
      "tz": "America\/Sao_Paulo"
  },
  "SSVE": {
      "icao": "SSVE",
      "iata": "",
      "name": "Fazenda Cerro Verde Airport",
      "city": "Tacuru",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1250,
      "lat": -23.8127784729,
      "lon": -55.0577774048,
      "tz": "America\/Campo_Grande"
  },
  "SSVF": {
      "icao": "SSVF",
      "iata": "",
      "name": "Rancho Donana Airport",
      "city": "Cerqueira Cesar",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2257,
      "lat": -22.9619445801,
      "lon": -49.1483345032,
      "tz": "America\/Sao_Paulo"
  },
  "SSVG": {
      "icao": "SSVG",
      "iata": "",
      "name": "Fazenda Santa Virginia Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2016,
      "lat": -22.2838897705,
      "lon": -55.6533317566,
      "tz": "America\/Campo_Grande"
  },
  "SSVH": {
      "icao": "SSVH",
      "iata": "",
      "name": "Fazenda Paqueta Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1398,
      "lat": -22.3669433594,
      "lon": -55.1277770996,
      "tz": "America\/Campo_Grande"
  },
  "SSVI": {
      "icao": "SSVI",
      "iata": "VIA",
      "name": "Videira Airport",
      "city": "Videira",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2756,
      "lat": -26.9997005463,
      "lon": -51.1418991089,
      "tz": "America\/Sao_Paulo"
  },
  "SSVJ": {
      "icao": "SSVJ",
      "iata": "",
      "name": "Fazenda Cedro Airport",
      "city": "Ponta Pora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2280,
      "lat": -21.8708324432,
      "lon": -55.7475013733,
      "tz": "America\/Campo_Grande"
  },
  "SSVK": {
      "icao": "SSVK",
      "iata": "",
      "name": "Fazenda Dom Arlindo Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1299,
      "lat": -22.7944431305,
      "lon": -54.1569442749,
      "tz": "America\/Campo_Grande"
  },
  "SSVL": {
      "icao": "SSVL",
      "iata": "",
      "name": "Fazenda Santa Claudina Airport",
      "city": "Caarapo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1476,
      "lat": -22.5216674805,
      "lon": -54.6777763367,
      "tz": "America\/Campo_Grande"
  },
  "SSVM": {
      "icao": "SSVM",
      "iata": "",
      "name": "Fazenda Santa Alaide Airport",
      "city": "Itapora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1499,
      "lat": -22.0025005341,
      "lon": -54.9741668701,
      "tz": "America\/Campo_Grande"
  },
  "SSVN": {
      "icao": "SSVN",
      "iata": "0",
      "name": "Veranopolis Airport",
      "city": "Veranopolis",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2133,
      "lat": -28.9349994659,
      "lon": -51.5682983398,
      "tz": "America\/Sao_Paulo"
  },
  "SSVO": {
      "icao": "SSVO",
      "iata": "",
      "name": "Usina de Moncoes Airport",
      "city": "Moncoes",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1437,
      "lat": -20.8716678619,
      "lon": -50.1269454956,
      "tz": "America\/Sao_Paulo"
  },
  "SSVP": {
      "icao": "SSVP",
      "iata": "CTQ",
      "name": "Santa Vitoria do Palmar Airport",
      "city": "Santa Vitoria Do Palmar",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 82,
      "lat": -33.5022239685,
      "lon": -53.344165802,
      "tz": "America\/Sao_Paulo"
  },
  "SSVQ": {
      "icao": "SSVQ",
      "iata": "",
      "name": "Fazenda Serrito Airport",
      "city": "Laguna Carapa",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1510,
      "lat": -22.4102783203,
      "lon": -54.988609314,
      "tz": "America\/Campo_Grande"
  },
  "SSVU": {
      "icao": "SSVU",
      "iata": "",
      "name": "Fazenda Serra do Cabral Airport",
      "city": "Lassance",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3773,
      "lat": -17.698889,
      "lon": -44.350278,
      "tz": "America\/Sao_Paulo"
  },
  "SSVW": {
      "icao": "SSVW",
      "iata": "",
      "name": "Fazenda Galera Airport",
      "city": "Conquista D`Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 975,
      "lat": -14.5130558014,
      "lon": -59.5569458008,
      "tz": "America\/Cuiaba"
  },
  "SSVX": {
      "icao": "SSVX",
      "iata": "",
      "name": "Fazenda Manduri Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 394,
      "lat": -18.5100002289,
      "lon": -56.4288902283,
      "tz": "America\/Campo_Grande"
  },
  "SSVY": {
      "icao": "SSVY",
      "iata": "0",
      "name": "Fazenda Tres Barras Airport",
      "city": "Pitangueiras",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1575,
      "lat": -20.9377994537,
      "lon": -48.1733016968,
      "tz": "America\/Sao_Paulo"
  },
  "SSWA": {
      "icao": "SSWA",
      "iata": "0",
      "name": "Venancio Aires Airport",
      "city": "Venancio Aires",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 226,
      "lat": -29.6131000519,
      "lon": -52.2172012329,
      "tz": "America\/Sao_Paulo"
  },
  "SSWC": {
      "icao": "SSWC",
      "iata": "",
      "name": "Fazenda Campo Verde Airport",
      "city": "Costa Rica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2608,
      "lat": -18.9330558777,
      "lon": -53.069442749,
      "tz": "America\/Campo_Grande"
  },
  "SSWE": {
      "icao": "SSWE",
      "iata": "",
      "name": "Fazenda California Airport",
      "city": "Inubia Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1450,
      "lat": -21.743888855,
      "lon": -50.9474983215,
      "tz": "America\/Sao_Paulo"
  },
  "SSWF": {
      "icao": "SSWF",
      "iata": "",
      "name": "Frederico Westphalen Airport",
      "city": "Frederico Westphalen",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1857,
      "lat": -27.3477783203,
      "lon": -53.4025001526,
      "tz": "America\/Sao_Paulo"
  },
  "SSWH": {
      "icao": "SSWH",
      "iata": "",
      "name": "Fazenda Agropecuaria Rossignolo Airport",
      "city": "Sao Carlos",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2300,
      "lat": -21.912222,
      "lon": -47.823611,
      "tz": "America\/Sao_Paulo"
  },
  "SSWK": {
      "icao": "SSWK",
      "iata": "",
      "name": "Guaiacuruzinho Airport",
      "city": "Santa Mariana",
      "state": "Parana",
      "country": "BR",
      "elevation": 1148,
      "lat": -23.0319442749,
      "lon": -50.4958343506,
      "tz": "America\/Sao_Paulo"
  },
  "SSWL": {
      "icao": "SSWL",
      "iata": "",
      "name": "Haras Fazenda Reunidas Sobral Airport",
      "city": "Santa Isabel Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 115,
      "lat": -1.2847219706,
      "lon": -48.213054657,
      "tz": "America\/Belem"
  },
  "SSWM": {
      "icao": "SSWM",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Londrina",
      "state": "Parana",
      "country": "BR",
      "elevation": 2461,
      "lat": -23.5,
      "lon": -51.2999992371,
      "tz": "America\/Sao_Paulo"
  },
  "SSWN": {
      "icao": "SSWN",
      "iata": "",
      "name": "Fazenda Figueira Branca Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 820,
      "lat": -16.2283325195,
      "lon": -57.5922203064,
      "tz": "America\/Cuiaba"
  },
  "SSWO": {
      "icao": "SSWO",
      "iata": "",
      "name": "Fazenda Santa Marta Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1083,
      "lat": -22.9663887024,
      "lon": -54.0758323669,
      "tz": "America\/Campo_Grande"
  },
  "SSWP": {
      "icao": "SSWP",
      "iata": "",
      "name": "Fazenda Araguaia Airport",
      "city": "Santa Maria Das Barreiras",
      "state": "Para",
      "country": "BR",
      "elevation": 797,
      "lat": -8.7425003052,
      "lon": -50.2075004578,
      "tz": "America\/Belem"
  },
  "SSWQ": {
      "icao": "SSWQ",
      "iata": "",
      "name": "Santa Anastacia II Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1283,
      "lat": -12.5275,
      "lon": -55.6247222222,
      "tz": "America\/Cuiaba"
  },
  "SSWR": {
      "icao": "SSWR",
      "iata": "",
      "name": "Fazenda Campana Airport",
      "city": "Rio Brilhante",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1113,
      "lat": -21.8880558014,
      "lon": -54.2952766418,
      "tz": "America\/Campo_Grande"
  },
  "SSWS": {
      "icao": "SSWS",
      "iata": "0",
      "name": "Cacapava do Sul Airport",
      "city": "Cacapava Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1312,
      "lat": -30.5494003296,
      "lon": -53.4592018127,
      "tz": "America\/Sao_Paulo"
  },
  "SSWT": {
      "icao": "SSWT",
      "iata": "",
      "name": "Cambara Airport",
      "city": "Cambara Do Sul",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 3527,
      "lat": -28.9997215271,
      "lon": -50.0761108398,
      "tz": "America\/Sao_Paulo"
  },
  "SSWV": {
      "icao": "SSWV",
      "iata": "",
      "name": "Fazenda Barra Grande Airport",
      "city": "Tomazinha",
      "state": "Parana",
      "country": "BR",
      "elevation": 1772,
      "lat": -23.590555191,
      "lon": -50.0411109924,
      "tz": "America\/Sao_Paulo"
  },
  "SSWW": {
      "icao": "SSWW",
      "iata": "",
      "name": "Fazenda Belem Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 262,
      "lat": -18.6741676331,
      "lon": -55.8947219849,
      "tz": "America\/Campo_Grande"
  },
  "SSWX": {
      "icao": "SSWX",
      "iata": "",
      "name": "Fazenda Retiro Campanha Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 262,
      "lat": -19.028055191,
      "lon": -56.2905578613,
      "tz": "America\/Campo_Grande"
  },
  "SSWZ": {
      "icao": "SSWZ",
      "iata": "",
      "name": "Fazenda Dona Nenem Airport",
      "city": "Presidente Olegario",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 3458,
      "lat": -18.5094432831,
      "lon": -46.2738876343,
      "tz": "America\/Sao_Paulo"
  },
  "SSXA": {
      "icao": "SSXA",
      "iata": "",
      "name": "Fazenda Congonhas Airport",
      "city": "Rancho Alegre",
      "state": "Parana",
      "country": "BR",
      "elevation": 1526,
      "lat": -23.0386104584,
      "lon": -50.9344444275,
      "tz": "America\/Sao_Paulo"
  },
  "SSXB": {
      "icao": "SSXB",
      "iata": "",
      "name": "Fazenda Alaska II Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 551,
      "lat": -17.1602993011,
      "lon": -56.5696983337,
      "tz": "America\/Cuiaba"
  },
  "SSXC": {
      "icao": "SSXC",
      "iata": "",
      "name": "Fazenda Alaska I Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 571,
      "lat": -17.0499992371,
      "lon": -56.0833320618,
      "tz": "America\/Cuiaba"
  },
  "SSXD": {
      "icao": "SSXD",
      "iata": "0",
      "name": "Sarandi Airport",
      "city": "Sarandi",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 2034,
      "lat": -27.9811000824,
      "lon": -52.8636016846,
      "tz": "America\/Sao_Paulo"
  },
  "SSXH": {
      "icao": "SSXH",
      "iata": "",
      "name": "Fazenda Jacamin Airport",
      "city": "Nova Mutum",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1099,
      "lat": -13.698056221,
      "lon": -56.2908325195,
      "tz": "America\/Cuiaba"
  },
  "SSXI": {
      "icao": "SSXI",
      "iata": "",
      "name": "Fazenda Macisa Airport",
      "city": "Tabapora",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1201,
      "lat": -11.179444313,
      "lon": -56.7911109924,
      "tz": "America\/Cuiaba"
  },
  "SSXK": {
      "icao": "SSXK",
      "iata": "",
      "name": "Fazenda Alvorada do Marape Airport",
      "city": "Tapura",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -12.8719444275,
      "lon": -56.7419433594,
      "tz": "America\/Cuiaba"
  },
  "SSXL": {
      "icao": "SSXL",
      "iata": "",
      "name": "Laguna Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 554,
      "lat": -20.4347229004,
      "lon": -56.3358345032,
      "tz": "America\/Campo_Grande"
  },
  "SSXM": {
      "icao": "SSXM",
      "iata": "",
      "name": "Fazenda Guaciara Airport",
      "city": "Jatei",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1443,
      "lat": -22.7866668701,
      "lon": -53.8827781677,
      "tz": "America\/Campo_Grande"
  },
  "SSXN": {
      "icao": "SSXN",
      "iata": "",
      "name": "Aereo Amazonia Airport",
      "city": "Altamira",
      "state": "Para",
      "country": "BR",
      "elevation": 820,
      "lat": -8.298333333,
      "lon": -55.11472222,
      "tz": "America\/Santarem"
  },
  "SSXO": {
      "icao": "SSXO",
      "iata": "",
      "name": "Pousada das Aguias Airport",
      "city": "Presidente Castelo Branco",
      "state": "Parana",
      "country": "BR",
      "elevation": 1932,
      "lat": -23.2888889313,
      "lon": -52.1291656494,
      "tz": "America\/Sao_Paulo"
  },
  "SSXP": {
      "icao": "SSXP",
      "iata": "",
      "name": "Fazenda Vitoria Airport",
      "city": "Centro Novo",
      "state": "Para",
      "country": "BR",
      "elevation": 523,
      "lat": -3.6202778816,
      "lon": -48.756111145,
      "tz": "America\/Belem"
  },
  "SSXQ": {
      "icao": "SSXQ",
      "iata": "",
      "name": "Fazenda Jota III Airport",
      "city": "Inocencia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1466,
      "lat": -19.3286113739,
      "lon": -51.9738883972,
      "tz": "America\/Campo_Grande"
  },
  "SSXR": {
      "icao": "SSXR",
      "iata": "",
      "name": "Galletti II Airport",
      "city": "Portel",
      "state": "Para",
      "country": "BR",
      "elevation": 974,
      "lat": -2.9513890743,
      "lon": -51.1461105347,
      "tz": "America\/Belem"
  },
  "SSXU": {
      "icao": "SSXU",
      "iata": "",
      "name": "Fazenda Fartura Airport",
      "city": "Tome Acu",
      "state": "Para",
      "country": "BR",
      "elevation": 276,
      "lat": -3.1577780247,
      "lon": -48.5400009155,
      "tz": "America\/Belem"
  },
  "SSXV": {
      "icao": "SSXV",
      "iata": "",
      "name": "Fazenda Santa Rita Airport",
      "city": "Navirai",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1201,
      "lat": -22.9500007629,
      "lon": -54.1122207642,
      "tz": "America\/Campo_Grande"
  },
  "SSXW": {
      "icao": "SSXW",
      "iata": "",
      "name": "Aero Porto Dal Lago Airport",
      "city": "Chapadao Do Sul",
      "state": "Goias",
      "country": "BR",
      "elevation": 2625,
      "lat": -18.8077774048,
      "lon": -52.600276947,
      "tz": "America\/Campo_Grande"
  },
  "SSXX": {
      "icao": "SSXX",
      "iata": "AXE",
      "name": "Xanxere Airport",
      "city": "Xanxere",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 2986,
      "lat": -26.8755569458,
      "lon": -52.3730545044,
      "tz": "America\/Sao_Paulo"
  },
  "SSXY": {
      "icao": "SSXY",
      "iata": "",
      "name": "Barradao Airport",
      "city": "Colniza",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 459,
      "lat": -9.6197223663,
      "lon": -60.6674995422,
      "tz": "America\/Cuiaba"
  },
  "SSXZ": {
      "icao": "SSXZ",
      "iata": "",
      "name": "Fazenda Santa Rosa Airport",
      "city": "Iturama",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1348,
      "lat": -19.7474994659,
      "lon": -50.1291656494,
      "tz": "America\/Sao_Paulo"
  },
  "SSYA": {
      "icao": "SSYA",
      "iata": "AAG",
      "name": "Arapoti Airport",
      "city": "Arapoti",
      "state": "Parana",
      "country": "BR",
      "elevation": 2641,
      "lat": -24.1039009094,
      "lon": -49.789100647,
      "tz": "America\/Sao_Paulo"
  },
  "SSYB": {
      "icao": "SSYB",
      "iata": "",
      "name": "Fazenda Santa Elza Airport",
      "city": "Costa Rica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1762,
      "lat": -18.6833324432,
      "lon": -53.2833328247,
      "tz": "America\/Campo_Grande"
  },
  "SSYC": {
      "icao": "SSYC",
      "iata": "",
      "name": "Fazenda Mimoso Airport",
      "city": "Chapadao Do Sul",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1959,
      "lat": -19.0494441986,
      "lon": -52.9638900757,
      "tz": "America\/Campo_Grande"
  },
  "SSYD": {
      "icao": "SSYD",
      "iata": "",
      "name": "Fazenda Andorinha I Airport",
      "city": "Pau D`Arco",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 643,
      "lat": -7.5480561256,
      "lon": -49.2080574036,
      "tz": "America\/Araguaina"
  },
  "SSYE": {
      "icao": "SSYE",
      "iata": "",
      "name": "Fazenda Roberta Airport",
      "city": "Araputanga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1033,
      "lat": -15.3208332062,
      "lon": -58.5016670227,
      "tz": "America\/Cuiaba"
  },
  "SSYF": {
      "icao": "SSYF",
      "iata": "",
      "name": "Fazenda do Cafe Airport",
      "city": "Monte Alegre De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2762,
      "lat": -18.8716678619,
      "lon": -48.7205543518,
      "tz": "America\/Sao_Paulo"
  },
  "SSYL": {
      "icao": "SSYL",
      "iata": "",
      "name": "Fazenda Tuju Puitan Airport",
      "city": "Iguatemi",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1142,
      "lat": -23.3411102295,
      "lon": -54.5688896179,
      "tz": "America\/Campo_Grande"
  },
  "SSYM": {
      "icao": "SSYM",
      "iata": "",
      "name": "Tuiuiu Airport",
      "city": "Miranda",
      "state": "",
      "country": "BR",
      "elevation": 548,
      "lat": -20.0869445801,
      "lon": -56.6163902283,
      "tz": "America\/Campo_Grande"
  },
  "SSYN": {
      "icao": "SSYN",
      "iata": "",
      "name": "Arrozeira Airport",
      "city": "Miranda",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 427,
      "lat": -20.0813884735,
      "lon": -56.6677780151,
      "tz": "America\/Campo_Grande"
  },
  "SSYO": {
      "icao": "SSYO",
      "iata": "",
      "name": "Cachoeira Rica Airport",
      "city": "Chapada Dos Guimaraes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1155,
      "lat": -15.2624998093,
      "lon": -55.6147232056,
      "tz": "America\/Cuiaba"
  },
  "SSYQ": {
      "icao": "SSYQ",
      "iata": "",
      "name": "Agropecuaria Monte Alverne Airport",
      "city": "Carazinho",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 1752,
      "lat": -28.3788890839,
      "lon": -52.8794441223,
      "tz": "America\/Sao_Paulo"
  },
  "SSYR": {
      "icao": "SSYR",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1214,
      "lat": -20.3477783203,
      "lon": -52.3233337402,
      "tz": "America\/Campo_Grande"
  },
  "SSYS": {
      "icao": "SSYS",
      "iata": "",
      "name": "Fazenda Imbauba Airport",
      "city": "Tres Lagoas",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1552,
      "lat": -19.9088897705,
      "lon": -52.5363883972,
      "tz": "America\/Campo_Grande"
  },
  "SSYT": {
      "icao": "SSYT",
      "iata": "",
      "name": "Itapiranga Airport",
      "city": "Itapiranga",
      "state": "Santa-Catarina",
      "country": "BR",
      "elevation": 1247,
      "lat": -27.1424999237,
      "lon": -53.685798645,
      "tz": "America\/Sao_Paulo"
  },
  "SSYU": {
      "icao": "SSYU",
      "iata": "",
      "name": "Fazenda Tres Pontes Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1621,
      "lat": -19.8269443512,
      "lon": -52.5866661072,
      "tz": "America\/Campo_Grande"
  },
  "SSYV": {
      "icao": "SSYV",
      "iata": "",
      "name": "Fazenda Progresso Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1650,
      "lat": -19.7263889313,
      "lon": -52.8513908386,
      "tz": "America\/Campo_Grande"
  },
  "SSYW": {
      "icao": "SSYW",
      "iata": "",
      "name": "Fazenda Terra Forte Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1460,
      "lat": -18.2683334351,
      "lon": -54.7825012207,
      "tz": "America\/Campo_Grande"
  },
  "SSYY": {
      "icao": "SSYY",
      "iata": "",
      "name": "Fazenda Fundaozinho Airport",
      "city": "Costa Rica",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1923,
      "lat": -18.9511108398,
      "lon": -53.1494445801,
      "tz": "America\/Campo_Grande"
  },
  "SSYZ": {
      "icao": "SSYZ",
      "iata": "",
      "name": "Fazenda Procomp II Airport",
      "city": "Aral Moreira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2080,
      "lat": -22.8511104584,
      "lon": -55.3961105347,
      "tz": "America\/Campo_Grande"
  },
  "SSZA": {
      "icao": "SSZA",
      "iata": "",
      "name": "Fazenda Sao Sebastiao da Formosa Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 331,
      "lat": -17.7222213745,
      "lon": -56.7533340454,
      "tz": "America\/Campo_Grande"
  },
  "SSZB": {
      "icao": "SSZB",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 331,
      "lat": -18.2066669464,
      "lon": -56.8094444275,
      "tz": "America\/Campo_Grande"
  },
  "SSZC": {
      "icao": "SSZC",
      "iata": "",
      "name": "Fazenda Lambari Airport",
      "city": "Coxim",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 590,
      "lat": -18.0197219849,
      "lon": -55.000831604,
      "tz": "America\/Campo_Grande"
  },
  "SSZF": {
      "icao": "SSZF",
      "iata": "",
      "name": "Fazenda Santa Gertrudes Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 150,
      "lat": -18.4777774811,
      "lon": -56.0216674805,
      "tz": "America\/Campo_Grande"
  },
  "SSZG": {
      "icao": "SSZG",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1575,
      "lat": -19.2572212219,
      "lon": -52.8963890076,
      "tz": "America\/Campo_Grande"
  },
  "SSZH": {
      "icao": "SSZH",
      "iata": "",
      "name": "Fazenda Procomp I Airport",
      "city": "Tacuru",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 928,
      "lat": -23.6461105347,
      "lon": -54.7147216797,
      "tz": "America\/Campo_Grande"
  },
  "SSZJ": {
      "icao": "SSZJ",
      "iata": "",
      "name": "Fazenda Torrao de Ouro Airport",
      "city": "Pedro Gomes",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 899,
      "lat": -18.1807003021,
      "lon": -54.5018005371,
      "tz": "America\/Campo_Grande"
  },
  "SSZK": {
      "icao": "SSZK",
      "iata": "",
      "name": "Sao Carlos Airport",
      "city": "Caracol",
      "state": "Concepcion",
      "country": "BR",
      "elevation": 410,
      "lat": -22.2147216797,
      "lon": -57.306388855,
      "tz": "America\/Asuncion"
  },
  "SSZL": {
      "icao": "SSZL",
      "iata": "",
      "name": "Fazenda Corrego Azul Airport",
      "city": "Brasilandia",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 994,
      "lat": -21.4544448853,
      "lon": -52.1686096191,
      "tz": "America\/Campo_Grande"
  },
  "SSZM": {
      "icao": "SSZM",
      "iata": "",
      "name": "Reserva Acurizal Airport",
      "city": "Corumba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 377,
      "lat": -17.8308334351,
      "lon": -57.5516662598,
      "tz": "America\/Campo_Grande"
  },
  "SSZN": {
      "icao": "SSZN",
      "iata": "",
      "name": "Prudentopolis Airport",
      "city": "Prudentopolis",
      "state": "Parana",
      "country": "BR",
      "elevation": 2451,
      "lat": -25.1819438934,
      "lon": -50.9236106873,
      "tz": "America\/Sao_Paulo"
  },
  "SSZP": {
      "icao": "SSZP",
      "iata": "",
      "name": "Fazenda Santa Adriana Airport",
      "city": "Jatei",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1499,
      "lat": -22.6961116791,
      "lon": -54.2158317566,
      "tz": "America\/Campo_Grande"
  },
  "SSZQ": {
      "icao": "SSZQ",
      "iata": "",
      "name": "Fazenda Panorama Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1197,
      "lat": -22.0389995575,
      "lon": -56.2694015503,
      "tz": "America\/Campo_Grande"
  },
  "SSZR": {
      "icao": "SSZR",
      "iata": "SRA",
      "name": "Santa Rosa Airport",
      "city": "Santa Rosa",
      "state": "Rio-Grande-do-Sul",
      "country": "BR",
      "elevation": 984,
      "lat": -27.9067001343,
      "lon": -54.520401001,
      "tz": "America\/Sao_Paulo"
  },
  "SSZS": {
      "icao": "SSZS",
      "iata": "0",
      "name": "Centenario do Sul Airport",
      "city": "Centenario Do Sul",
      "state": "Parana",
      "country": "BR",
      "elevation": 1591,
      "lat": -22.8307991028,
      "lon": -51.6035995483,
      "tz": "America\/Sao_Paulo"
  },
  "SSZT": {
      "icao": "SSZT",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Aguas De Santa Barbara",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 2146,
      "lat": -22.7941665649,
      "lon": -49.3411102295,
      "tz": "America\/Sao_Paulo"
  },
  "SSZU": {
      "icao": "SSZU",
      "iata": "",
      "name": "Fazenda Tres Marias Airport",
      "city": "Agua Clara",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1099,
      "lat": -19.4950008392,
      "lon": -53.0405578613,
      "tz": "America\/Campo_Grande"
  },
  "SSZV": {
      "icao": "SSZV",
      "iata": "",
      "name": "Fazenda Bordolandia Airport",
      "city": "Serra Nova Dourada",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1066,
      "lat": -12.0619440079,
      "lon": -51.5330543518,
      "tz": "America\/Cuiaba"
  },
  "SSZW": {
      "icao": "SSZW",
      "iata": "PGZ",
      "name": "Ponta Grossa Airport",
      "city": "Ponta Grossa",
      "state": "Parana",
      "country": "BR",
      "elevation": 2588,
      "lat": -25.1847000122,
      "lon": -50.1441001892,
      "tz": "America\/Sao_Paulo"
  },
  "SSZX": {
      "icao": "SSZX",
      "iata": "",
      "name": "Fazenda Lapa do Lobo Airport",
      "city": "Paranaiba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1447,
      "lat": -19.5272216797,
      "lon": -50.9702796936,
      "tz": "America\/Campo_Grande"
  },
  "SSZY": {
      "icao": "SSZY",
      "iata": "",
      "name": "Carneirinho Agroindustrial Airport",
      "city": "Carneirinho",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1414,
      "lat": -19.8099994659,
      "lon": -50.7813873291,
      "tz": "America\/Sao_Paulo"
  },
  "SSZZ": {
      "icao": "SSZZ",
      "iata": "",
      "name": "Fazenda Thaua Airport",
      "city": "Sonora",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 2024,
      "lat": -17.7130565643,
      "lon": -54.4088897705,
      "tz": "America\/Campo_Grande"
  },
  "SUAA": {
      "icao": "SUAA",
      "iata": "",
      "name": "Angel S Adami Airport",
      "city": "Montevideo",
      "state": "Montevideo",
      "country": "UY",
      "elevation": 174,
      "lat": -34.7891998291,
      "lon": -56.2647018433,
      "tz": "America\/Montevideo"
  },
  "SUAG": {
      "icao": "SUAG",
      "iata": "ATI",
      "name": "Artigas International Airport",
      "city": "Artigas",
      "state": "Artigas",
      "country": "UY",
      "elevation": 410,
      "lat": -30.4006996155,
      "lon": -56.507900238,
      "tz": "America\/Montevideo"
  },
  "SUCA": {
      "icao": "SUCA",
      "iata": "CYR",
      "name": "Laguna de Los Patos International Airport",
      "city": "Colonia",
      "state": "Colonia",
      "country": "UY",
      "elevation": 66,
      "lat": -34.456401825,
      "lon": -57.7705993652,
      "tz": "America\/Montevideo"
  },
  "SUCM": {
      "icao": "SUCM",
      "iata": "CAR",
      "name": "Carmelo International Airport",
      "city": "Carmelo",
      "state": "Colonia",
      "country": "UY",
      "elevation": 42,
      "lat": -33.9661102295,
      "lon": -58.3252792358,
      "tz": "America\/Montevideo"
  },
  "SUDU": {
      "icao": "SUDU",
      "iata": "DZO",
      "name": "Santa Bernardina International Airport",
      "city": "Durazno",
      "state": "Durazno",
      "country": "UY",
      "elevation": 305,
      "lat": -33.3588981628,
      "lon": -56.4991989136,
      "tz": "America\/Montevideo"
  },
  "SULS": {
      "icao": "SULS",
      "iata": "PDP",
      "name": "Capitan Corbeta CA Curbelo International Airport",
      "city": "Punta del Este",
      "state": "Maldonado",
      "country": "UY",
      "elevation": 95,
      "lat": -34.8550987244,
      "lon": -55.0942993164,
      "tz": "America\/Montevideo"
  },
  "SUME": {
      "icao": "SUME",
      "iata": "MER",
      "name": "Ricardo de Tomasi International Airport",
      "city": "Mercedes",
      "state": "Soriano",
      "country": "UY",
      "elevation": 68,
      "lat": -33.25,
      "lon": -58.0670013428,
      "tz": "America\/Montevideo"
  },
  "SUMO": {
      "icao": "SUMO",
      "iata": "MLZ",
      "name": "Cerro Largo International Airport",
      "city": "Melo",
      "state": "Cerro-Largo",
      "country": "UY",
      "elevation": 364,
      "lat": -32.3378982544,
      "lon": -54.2167015076,
      "tz": "America\/Montevideo"
  },
  "SUMU": {
      "icao": "SUMU",
      "iata": "MVD",
      "name": "Carrasco International \/General C L Berisso Airport",
      "city": "Montevideo",
      "state": "Canelones",
      "country": "UY",
      "elevation": 105,
      "lat": -34.8384017944,
      "lon": -56.0307998657,
      "tz": "America\/Montevideo"
  },
  "SUPE": {
      "icao": "SUPE",
      "iata": "MDO",
      "name": "El Jaguel \/ Punta del Este Airport",
      "city": "Maldonado",
      "state": "Maldonado",
      "country": "UY",
      "elevation": 66,
      "lat": -34.9169998169,
      "lon": -54.9169998169,
      "tz": "America\/Montevideo"
  },
  "SUPU": {
      "icao": "SUPU",
      "iata": "PDU",
      "name": "Tydeo Larre Borges Airport",
      "city": "Paysandu",
      "state": "Paysandu",
      "country": "UY",
      "elevation": 138,
      "lat": -32.3633003235,
      "lon": -58.0619010925,
      "tz": "America\/Montevideo"
  },
  "SURB": {
      "icao": "SURB",
      "iata": "",
      "name": "Rio Branco Airport",
      "city": "Jaguarao",
      "state": "Cerro-Largo",
      "country": "UY",
      "elevation": 55,
      "lat": -32.5830574036,
      "lon": -53.4541664124,
      "tz": "America\/Montevideo"
  },
  "SURV": {
      "icao": "SURV",
      "iata": "RVY",
      "name": "Presidente General Don Oscar D. Gestido International Airport",
      "city": "Rivera",
      "state": "Rivera",
      "country": "UY",
      "elevation": 712,
      "lat": -30.9745998383,
      "lon": -55.4762001038,
      "tz": "America\/Montevideo"
  },
  "SUSO": {
      "icao": "SUSO",
      "iata": "STY",
      "name": "Nueva Hesperides International Airport",
      "city": "Salto",
      "state": "Salto",
      "country": "UY",
      "elevation": 187,
      "lat": -31.4384994507,
      "lon": -57.9852981567,
      "tz": "America\/Montevideo"
  },
  "SUTB": {
      "icao": "SUTB",
      "iata": "TAW",
      "name": "Tacuarembo Airport",
      "city": "Tacuarembo",
      "state": "Tacuarembo",
      "country": "UY",
      "elevation": 440,
      "lat": -31.7490005493,
      "lon": -55.9258003235,
      "tz": "America\/Montevideo"
  },
  "SUTR": {
      "icao": "SUTR",
      "iata": "TYT",
      "name": "Treinta y Tres Airport",
      "city": "Treinta y Tres",
      "state": "Treinta-y-Tres",
      "country": "UY",
      "elevation": 337,
      "lat": -33.2000007629,
      "lon": -54.3499984741,
      "tz": "America\/Montevideo"
  },
  "SUVO": {
      "icao": "SUVO",
      "iata": "VCH",
      "name": "Vichadero Airport",
      "city": "Vichadero",
      "state": "Rivera",
      "country": "UY",
      "elevation": 488,
      "lat": -31.7670001984,
      "lon": -54.6170005798,
      "tz": "America\/Montevideo"
  },
  "SV02": {
      "icao": "SV02",
      "iata": "",
      "name": "San Diego De Cabrutica Airport",
      "city": "El Roble",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 492,
      "lat": 8.4044303894,
      "lon": -65.0155029297,
      "tz": "America\/Caracas"
  },
  "SV73": {
      "icao": "SV73",
      "iata": "",
      "name": "Uverito Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 300,
      "lat": 8.6703195572,
      "lon": -62.6254005432,
      "tz": "America\/Caracas"
  },
  "SV74": {
      "icao": "SV74",
      "iata": "",
      "name": "Aricuaisa Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 165,
      "lat": 9.581870079,
      "lon": -72.7880020142,
      "tz": "America\/Caracas"
  },
  "SV75": {
      "icao": "SV75",
      "iata": "",
      "name": "Machiques North Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 361,
      "lat": 10.0882997513,
      "lon": -72.5548019409,
      "tz": "America\/Caracas"
  },
  "SVAC": {
      "icao": "SVAC",
      "iata": "AGV",
      "name": "Oswaldo Guevara Mujica Airport",
      "city": "Acarigua",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 640,
      "lat": 9.5533752441,
      "lon": -69.2378692627,
      "tz": "America\/Caracas"
  },
  "SVAE": {
      "icao": "SVAE",
      "iata": "",
      "name": "Montellano Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 656,
      "lat": 10.3281002045,
      "lon": -72.4810028076,
      "tz": "America\/Caracas"
  },
  "SVAF": {
      "icao": "SVAF",
      "iata": "",
      "name": "Medano Alto Airport",
      "city": "Medano Alto",
      "state": "Apure",
      "country": "VE",
      "elevation": 459,
      "lat": 6.9333329201,
      "lon": -68.0166702271,
      "tz": "America\/Caracas"
  },
  "SVAH": {
      "icao": "SVAH",
      "iata": "",
      "name": "Cachimbo Airport",
      "city": "Coco de Mono",
      "state": "Yaracuy",
      "country": "VE",
      "elevation": 1000,
      "lat": 10.0333328247,
      "lon": -69.1666641235,
      "tz": "America\/Caracas"
  },
  "SVAI": {
      "icao": "SVAI",
      "iata": "",
      "name": "Arichuna Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 145,
      "lat": 7.8666667938,
      "lon": -67.0333328247,
      "tz": "America\/Caracas"
  },
  "SVAJ": {
      "icao": "SVAJ",
      "iata": "",
      "name": "Mata de Juajua Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 393,
      "lat": 8.8189296722,
      "lon": -65.8867034912,
      "tz": "America\/Caracas"
  },
  "SVAL": {
      "icao": "SVAL",
      "iata": "",
      "name": "Algodonal Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 574,
      "lat": 8.3500003815,
      "lon": -69.5166702271,
      "tz": "America\/Caracas"
  },
  "SVAM": {
      "icao": "SVAM",
      "iata": "",
      "name": "Mata de Cana Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 344,
      "lat": 7.3166670799,
      "lon": -69.1999969482,
      "tz": "America\/Caracas"
  },
  "SVAN": {
      "icao": "SVAN",
      "iata": "AAO",
      "name": "Anaco Airport",
      "city": "Anaco",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 721,
      "lat": 9.4302253723,
      "lon": -64.4707260132,
      "tz": "America\/Caracas"
  },
  "SVAO": {
      "icao": "SVAO",
      "iata": "",
      "name": "Altagracia de Orituco Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 1148,
      "lat": 9.8633327484,
      "lon": -66.2933349609,
      "tz": "America\/Caracas"
  },
  "SVAP": {
      "icao": "SVAP",
      "iata": "",
      "name": "Apurito Airport",
      "city": "Apurito",
      "state": "Apure",
      "country": "VE",
      "elevation": 295,
      "lat": 7.9188499451,
      "lon": -68.483001709,
      "tz": "America\/Caracas"
  },
  "SVAQ": {
      "icao": "SVAQ",
      "iata": "",
      "name": "Mata Oscura Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 196,
      "lat": 9.25,
      "lon": -68.4833297729,
      "tz": "America\/Caracas"
  },
  "SVAR": {
      "icao": "SVAR",
      "iata": "",
      "name": "Adicora Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 7,
      "lat": 11.9368000031,
      "lon": -69.8096008301,
      "tz": "America\/Caracas"
  },
  "SVAS": {
      "icao": "SVAS",
      "iata": "LPJ",
      "name": "Armando Schwarck Airport",
      "city": "Guayabal",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 266,
      "lat": 6.5780501366,
      "lon": -66.8169021606,
      "tz": "America\/Caracas"
  },
  "SVAT": {
      "icao": "SVAT",
      "iata": "",
      "name": "San Fernando de Atabapo Airport",
      "city": "San Fernando de Atabapo",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 301,
      "lat": 4.0510401726,
      "lon": -67.6996994019,
      "tz": "America\/Bogota"
  },
  "SVAU": {
      "icao": "SVAU",
      "iata": "",
      "name": "Agua Clara Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 328,
      "lat": 6.75,
      "lon": -68.3333358765,
      "tz": "America\/Caracas"
  },
  "SVAV": {
      "icao": "SVAV",
      "iata": "",
      "name": "El Alcaravan Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 128,
      "lat": 9.2733325958,
      "lon": -63.4183311462,
      "tz": "America\/Caracas"
  },
  "SVAW": {
      "icao": "SVAW",
      "iata": "",
      "name": "Merecure Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 220,
      "lat": 7.4833331108,
      "lon": -67.8499984741,
      "tz": "America\/Caracas"
  },
  "SVAX": {
      "icao": "SVAX",
      "iata": "",
      "name": "Los Andes Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 393,
      "lat": 10,
      "lon": -72.5833358765,
      "tz": "America\/Caracas"
  },
  "SVAY": {
      "icao": "SVAY",
      "iata": "",
      "name": "Araya Airport",
      "city": "",
      "state": "Sucre",
      "country": "VE",
      "elevation": 32,
      "lat": 10.5833330154,
      "lon": -64.2466659546,
      "tz": "America\/Caracas"
  },
  "SVAZ": {
      "icao": "SVAZ",
      "iata": "",
      "name": "Mata Palos Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 224,
      "lat": 8.4333333969,
      "lon": -68.5500030518,
      "tz": "America\/Caracas"
  },
  "SVBA": {
      "icao": "SVBA",
      "iata": "",
      "name": "Boca Anaro Airport",
      "city": "Boca de Anaro",
      "state": "Barinas",
      "country": "VE",
      "elevation": 298,
      "lat": 7.8333330154,
      "lon": -70.2666702271,
      "tz": "America\/Caracas"
  },
  "SVBC": {
      "icao": "SVBC",
      "iata": "BLA",
      "name": "General Jose Antonio Anzoategui International Airport",
      "city": "Barcelona",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 26,
      "lat": 10.1070995331,
      "lon": -64.689201355,
      "tz": "America\/Caracas"
  },
  "SVBD": {
      "icao": "SVBD",
      "iata": "",
      "name": "Los Camorucos Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 321,
      "lat": 7.6166667938,
      "lon": -69.0500030518,
      "tz": "America\/Caracas"
  },
  "SVBE": {
      "icao": "SVBE",
      "iata": "",
      "name": "Bernal Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 249,
      "lat": 9.6666669846,
      "lon": -72.5833358765,
      "tz": "America\/Caracas"
  },
  "SVBF": {
      "icao": "SVBF",
      "iata": "",
      "name": "Hato Uranon Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 410,
      "lat": 6.9666671753,
      "lon": -67.2166671753,
      "tz": "America\/Caracas"
  },
  "SVBG": {
      "icao": "SVBG",
      "iata": "",
      "name": "La Batalla Airport",
      "city": "La Cruz",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 273,
      "lat": 9.1999998093,
      "lon": -68.1666641235,
      "tz": "America\/Caracas"
  },
  "SVBH": {
      "icao": "SVBH",
      "iata": "",
      "name": "Los Belseres Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 3,
      "lat": 10.9416666031,
      "lon": -68.2666702271,
      "tz": "America\/Caracas"
  },
  "SVBI": {
      "icao": "SVBI",
      "iata": "BNS",
      "name": "Barinas Airport",
      "city": "Barinas",
      "state": "Barinas",
      "country": "VE",
      "elevation": 666,
      "lat": 8.6195697784,
      "lon": -70.2208023071,
      "tz": "America\/Caracas"
  },
  "SVBJ": {
      "icao": "SVBJ",
      "iata": "",
      "name": "Hato Viejo Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 524,
      "lat": 7.3216671944,
      "lon": -69.6699981689,
      "tz": "America\/Caracas"
  },
  "SVBK": {
      "icao": "SVBK",
      "iata": "",
      "name": "Bocono Airport",
      "city": "Bocono",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 5084,
      "lat": 9.2671003342,
      "lon": -70.2304992676,
      "tz": "America\/Caracas"
  },
  "SVBL": {
      "icao": "SVBL",
      "iata": "",
      "name": "El Libertador Airbase",
      "city": "",
      "state": "Aragua",
      "country": "VE",
      "elevation": 1450,
      "lat": 10.1833753586,
      "lon": -67.5573196411,
      "tz": "America\/Caracas"
  },
  "SVBM": {
      "icao": "SVBM",
      "iata": "BRM",
      "name": "Barquisimeto International Airport",
      "city": "Barquisimeto",
      "state": "Lara",
      "country": "VE",
      "elevation": 2042,
      "lat": 10.0427465439,
      "lon": -69.3586196899,
      "tz": "America\/Caracas"
  },
  "SVBN": {
      "icao": "SVBN",
      "iata": "",
      "name": "Bocon Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1462,
      "lat": 6.5999999046,
      "lon": -62.7333335876,
      "tz": "America\/Caracas"
  },
  "SVBP": {
      "icao": "SVBP",
      "iata": "",
      "name": "Jobito Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 138,
      "lat": 7.5999999046,
      "lon": -67.1999969482,
      "tz": "America\/Caracas"
  },
  "SVBQ": {
      "icao": "SVBQ",
      "iata": "",
      "name": "Bachaquero Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 43,
      "lat": 10.0015001297,
      "lon": -71.0837020874,
      "tz": "America\/Caracas"
  },
  "SVBR": {
      "icao": "SVBR",
      "iata": "",
      "name": "Carbonero Airport",
      "city": "",
      "state": "Yaracuy",
      "country": "VE",
      "elevation": 328,
      "lat": 10.4166669846,
      "lon": -68.6666641235,
      "tz": "America\/Caracas"
  },
  "SVBS": {
      "icao": "SVBS",
      "iata": "MYC",
      "name": "Escuela Mariscal Sucre Airport",
      "city": "Maracay",
      "state": "Aragua",
      "country": "VE",
      "elevation": 1338,
      "lat": 10.2499780655,
      "lon": -67.6494216919,
      "tz": "America\/Caracas"
  },
  "SVBT": {
      "icao": "SVBT",
      "iata": "",
      "name": "Boquemonte Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 1000,
      "lat": 9.1999998093,
      "lon": -69.75,
      "tz": "America\/Caracas"
  },
  "SVBU": {
      "icao": "SVBU",
      "iata": "",
      "name": "Central Venezuela Airport",
      "city": "Bobures",
      "state": "Zulia",
      "country": "VE",
      "elevation": 33,
      "lat": 9.1666669846,
      "lon": -71.0999984741,
      "tz": "America\/Caracas"
  },
  "SVBV": {
      "icao": "SVBV",
      "iata": "",
      "name": "Banco Verde Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 348,
      "lat": 8.733332634,
      "lon": -69.5333328247,
      "tz": "America\/Caracas"
  },
  "SVBW": {
      "icao": "SVBW",
      "iata": "",
      "name": "Hato El Burro Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 921,
      "lat": 7.1316671371,
      "lon": -63.4566688538,
      "tz": "America\/Caracas"
  },
  "SVBX": {
      "icao": "SVBX",
      "iata": "",
      "name": "La Cabana Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 215,
      "lat": 9.766667366,
      "lon": -72.6999969482,
      "tz": "America\/Caracas"
  },
  "SVCB": {
      "icao": "SVCB",
      "iata": "CBL",
      "name": "Ciudad Bolivar Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 197,
      "lat": 8.1221609116,
      "lon": -63.5369567871,
      "tz": "America\/Caracas"
  },
  "SVCD": {
      "icao": "SVCD",
      "iata": "CXA",
      "name": "Caicara del Orinoco Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 141,
      "lat": 7.6255102158,
      "lon": -66.1628036499,
      "tz": "America\/Caracas"
  },
  "SVCE": {
      "icao": "SVCE",
      "iata": "",
      "name": "Apruna Airport",
      "city": "Cuenca de Unare",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 204,
      "lat": 9.3503055573,
      "lon": -65.2806549072,
      "tz": "America\/Caracas"
  },
  "SVCG": {
      "icao": "SVCG",
      "iata": "CUV",
      "name": "Casigua El Cubo Airport",
      "city": "Casigua El Cubo",
      "state": "Zulia",
      "country": "VE",
      "elevation": 99,
      "lat": 8.7581396103,
      "lon": -72.5363006592,
      "tz": "America\/Caracas"
  },
  "SVCH": {
      "icao": "SVCH",
      "iata": "",
      "name": "Achaguas Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 187,
      "lat": 7.7399997711,
      "lon": -68.2399978638,
      "tz": "America\/Caracas"
  },
  "SVCI": {
      "icao": "SVCI",
      "iata": "",
      "name": "Punta de Oro Airport",
      "city": "",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 37,
      "lat": 9.5016670227,
      "lon": -70.9516677856,
      "tz": "America\/Caracas"
  },
  "SVCJ": {
      "icao": "SVCJ",
      "iata": "",
      "name": "San Carlos Airport",
      "city": "San Carlos",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 512,
      "lat": 9.6477203369,
      "lon": -68.5746994019,
      "tz": "America\/Caracas"
  },
  "SVCK": {
      "icao": "SVCK",
      "iata": "",
      "name": "Cano Lucas Airport",
      "city": "El Poblado",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 301,
      "lat": 9.4166669846,
      "lon": -68.8833312988,
      "tz": "America\/Caracas"
  },
  "SVCL": {
      "icao": "SVCL",
      "iata": "CLZ",
      "name": "Calabozo Airport",
      "city": "Guarico",
      "state": "Guarico",
      "country": "VE",
      "elevation": 328,
      "lat": 8.9246559143,
      "lon": -67.4170913696,
      "tz": "America\/Caracas"
  },
  "SVCM": {
      "icao": "SVCM",
      "iata": "",
      "name": "Canita Mendera Airport",
      "city": "Tinaco",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 662,
      "lat": 9.6833333969,
      "lon": -68.4166641235,
      "tz": "America\/Caracas"
  },
  "SVCN": {
      "icao": "SVCN",
      "iata": "CAJ",
      "name": "Canaima Airport",
      "city": "Canaima",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1450,
      "lat": 6.2319889069,
      "lon": -62.8544311523,
      "tz": "America\/Caracas"
  },
  "SVCO": {
      "icao": "SVCO",
      "iata": "VCR",
      "name": "Carora Airport",
      "city": "Carora",
      "state": "Lara",
      "country": "VE",
      "elevation": 1437,
      "lat": 10.1756029129,
      "lon": -70.0652160645,
      "tz": "America\/Caracas"
  },
  "SVCP": {
      "icao": "SVCP",
      "iata": "CUP",
      "name": "General Francisco Bermudez Airport",
      "city": "Carupano",
      "state": "Sucre",
      "country": "VE",
      "elevation": 33,
      "lat": 10.6600141525,
      "lon": -63.261680603,
      "tz": "America\/Caracas"
  },
  "SVCQ": {
      "icao": "SVCQ",
      "iata": "",
      "name": "Cazorla Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 639,
      "lat": 8.0606002808,
      "lon": -66.9699020386,
      "tz": "America\/Caracas"
  },
  "SVCR": {
      "icao": "SVCR",
      "iata": "CZE",
      "name": "Jose Leonardo Chirinos Airport",
      "city": "Coro",
      "state": "Falcon",
      "country": "VE",
      "elevation": 52,
      "lat": 11.4149436951,
      "lon": -69.6809005737,
      "tz": "America\/Caracas"
  },
  "SVCS": {
      "icao": "SVCS",
      "iata": "",
      "name": "Oscar Machado Zuluaga Airport",
      "city": "Caracas",
      "state": "Miranda",
      "country": "VE",
      "elevation": 2145,
      "lat": 10.286110878,
      "lon": -66.8161087036,
      "tz": "America\/Caracas"
  },
  "SVCT": {
      "icao": "SVCT",
      "iata": "",
      "name": "Caujarito Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 360,
      "lat": 6.8833332062,
      "lon": -68.3833312988,
      "tz": "America\/Caracas"
  },
  "SVCU": {
      "icao": "SVCU",
      "iata": "CUM",
      "name": "Cumana (Antonio Jose de Sucre) Airport",
      "city": "",
      "state": "Sucre",
      "country": "VE",
      "elevation": 14,
      "lat": 10.4503326416,
      "lon": -64.1304702759,
      "tz": "America\/Caracas"
  },
  "SVCV": {
      "icao": "SVCV",
      "iata": "",
      "name": "Cunaviche Airport",
      "city": "Cunaviche",
      "state": "Apure",
      "country": "VE",
      "elevation": 262,
      "lat": 7.4000000954,
      "lon": -67.4333343506,
      "tz": "America\/Caracas"
  },
  "SVCW": {
      "icao": "SVCW",
      "iata": "",
      "name": "Chinazon Airport",
      "city": "",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 98,
      "lat": 9.326666832,
      "lon": -70.9333343506,
      "tz": "America\/Caracas"
  },
  "SVCX": {
      "icao": "SVCX",
      "iata": "",
      "name": "Guachara Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 656,
      "lat": 7.3000001907,
      "lon": -68.3666687012,
      "tz": "America\/Caracas"
  },
  "SVCY": {
      "icao": "SVCY",
      "iata": "",
      "name": "Cuartel Yaruro Airport",
      "city": "Las Campanas",
      "state": "Apure",
      "country": "VE",
      "elevation": 442,
      "lat": 6.9333329201,
      "lon": -68.6333312988,
      "tz": "America\/Caracas"
  },
  "SVCZ": {
      "icao": "SVCZ",
      "iata": "",
      "name": "Capitan Manuel Rios Airbase",
      "city": "Carrizal",
      "state": "Aragua",
      "country": "VE",
      "elevation": 595,
      "lat": 9.3726501465,
      "lon": -66.9227981567,
      "tz": "America\/Caracas"
  },
  "SVDA": {
      "icao": "SVDA",
      "iata": "isl",
      "name": "La Tortuga Punta Delgada Airport",
      "city": "Isla La Tortuga",
      "state": "Dependencias-Federales",
      "country": "VE",
      "elevation": 26,
      "lat": 10.9366674423,
      "lon": -65.2233352661,
      "tz": "America\/Caracas"
  },
  "SVDB": {
      "icao": "SVDB",
      "iata": "",
      "name": "Dona Bella Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 651,
      "lat": 9.3333330154,
      "lon": -68.4333343506,
      "tz": "America\/Caracas"
  },
  "SVDC": {
      "icao": "SVDC",
      "iata": "",
      "name": "Cocuiza Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 230,
      "lat": 8.5900001526,
      "lon": -66.2549972534,
      "tz": "America\/Caracas"
  },
  "SVDF": {
      "icao": "SVDF",
      "iata": "",
      "name": "Punto Fijo Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 360,
      "lat": 6.8666667938,
      "lon": -68.7833328247,
      "tz": "America\/Caracas"
  },
  "SVDG": {
      "icao": "SVDG",
      "iata": "",
      "name": "Punta Gorda Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 859,
      "lat": 9.6499996185,
      "lon": -63.7216682434,
      "tz": "America\/Caracas"
  },
  "SVDM": {
      "icao": "SVDM",
      "iata": "",
      "name": "Dos Mosquises Airport",
      "city": "Isla Los Roques",
      "state": "Dependencias-Federales",
      "country": "VE",
      "elevation": 5,
      "lat": 11.7967996597,
      "lon": -66.8929977417,
      "tz": "America\/Caracas"
  },
  "SVDP": {
      "icao": "SVDP",
      "iata": "",
      "name": "La Divina Pastora Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 2581,
      "lat": 4.7300000191,
      "lon": -60.9650001526,
      "tz": "America\/Caracas"
  },
  "SVDR": {
      "icao": "SVDR",
      "iata": "",
      "name": "Cordereno Airport",
      "city": "Cordereno",
      "state": "Barinas",
      "country": "VE",
      "elevation": 268,
      "lat": 7.9333329201,
      "lon": -69.9000015259,
      "tz": "America\/Caracas"
  },
  "SVDS": {
      "icao": "SVDS",
      "iata": "",
      "name": "Codsa Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 2850,
      "lat": 4.7916669846,
      "lon": -61.1199989319,
      "tz": "America\/Caracas"
  },
  "SVDV": {
      "icao": "SVDV",
      "iata": "",
      "name": "Hato Veladero Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 239,
      "lat": 9.4499998093,
      "lon": -63.1749992371,
      "tz": "America\/Caracas"
  },
  "SVDW": {
      "icao": "SVDW",
      "iata": "",
      "name": "Ciudad Piar Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 879,
      "lat": 7.4937901497,
      "lon": -63.2697982788,
      "tz": "America\/Caracas"
  },
  "SVDX": {
      "icao": "SVDX",
      "iata": "",
      "name": "Hato Puga Airport",
      "city": "",
      "state": "Delta-Amacuro",
      "country": "VE",
      "elevation": 268,
      "lat": 8.4666671753,
      "lon": -62.4833335876,
      "tz": "America\/Caracas"
  },
  "SVDZ": {
      "icao": "SVDZ",
      "iata": "",
      "name": "Puerto Paez",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 146,
      "lat": 6.2333331108,
      "lon": -67.4336090088,
      "tz": "America\/Caracas"
  },
  "SVEB": {
      "icao": "SVEB",
      "iata": "",
      "name": "El Guayabo del Zulia Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 79,
      "lat": 8.6761598587,
      "lon": -72.3356018066,
      "tz": "America\/Caracas"
  },
  "SVED": {
      "icao": "SVED",
      "iata": "EOR",
      "name": "El Dorado Airport",
      "city": "Bolivar",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 318,
      "lat": 6.7333331108,
      "lon": -61.5833320618,
      "tz": "America\/Caracas"
  },
  "SVEE": {
      "icao": "SVEE",
      "iata": "",
      "name": "El Esterero Airport",
      "city": "El Saman de Apure",
      "state": "Apure",
      "country": "VE",
      "elevation": 590,
      "lat": 7.9083328247,
      "lon": -68.6966705322,
      "tz": "America\/Caracas"
  },
  "SVEF": {
      "icao": "SVEF",
      "iata": "",
      "name": "Empujeca Airport",
      "city": "",
      "state": "Yaracuy",
      "country": "VE",
      "elevation": 66,
      "lat": 10.595000267,
      "lon": -68.4950027466,
      "tz": "America\/Caracas"
  },
  "SVEG": {
      "icao": "SVEG",
      "iata": "",
      "name": "El Guayabo de Cojedes Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 23,
      "lat": 9.6999998093,
      "lon": -68.3333358765,
      "tz": "America\/Caracas"
  },
  "SVEJ": {
      "icao": "SVEJ",
      "iata": "",
      "name": "El Jabillal Airport",
      "city": "",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 354,
      "lat": 9.4499998093,
      "lon": -70.8000030518,
      "tz": "America\/Caracas"
  },
  "SVEK": {
      "icao": "SVEK",
      "iata": "",
      "name": "El Capitan Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 452,
      "lat": 10.1135997772,
      "lon": -72.5765991211,
      "tz": "America\/Caracas"
  },
  "SVEL": {
      "icao": "SVEL",
      "iata": "",
      "name": "El Callao (El Peru) Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 747,
      "lat": 7.3000001907,
      "lon": -61.7999992371,
      "tz": "America\/Caracas"
  },
  "SVEM": {
      "icao": "SVEM",
      "iata": "",
      "name": "El Paraiso Airport",
      "city": "El Paraiso",
      "state": "Barinas",
      "country": "VE",
      "elevation": 656,
      "lat": 8.3333330154,
      "lon": -70.375,
      "tz": "America\/Caracas"
  },
  "SVEN": {
      "icao": "SVEN",
      "iata": "",
      "name": "Encontrados Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 66,
      "lat": 9.1116666794,
      "lon": -72.2699966431,
      "tz": "America\/Caracas"
  },
  "SVEP": {
      "icao": "SVEP",
      "iata": "",
      "name": "El Palmar Airport",
      "city": "",
      "state": "Aragua",
      "country": "VE",
      "elevation": 1554,
      "lat": 10.2066669464,
      "lon": -67.4416656494,
      "tz": "America\/Caracas"
  },
  "SVER": {
      "icao": "SVER",
      "iata": "",
      "name": "El Respiro Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 387,
      "lat": 7.2166671753,
      "lon": -68.9833297729,
      "tz": "America\/Caracas"
  },
  "SVES": {
      "icao": "SVES",
      "iata": "",
      "name": "El Saman de Apure Airport",
      "city": "El Saman de Apure",
      "state": "Apure",
      "country": "VE",
      "elevation": 361,
      "lat": 7.9123501778,
      "lon": -68.6927032471,
      "tz": "America\/Caracas"
  },
  "SVET": {
      "icao": "SVET",
      "iata": "",
      "name": "El Manteco Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1000,
      "lat": 7.3610701561,
      "lon": -62.5321006775,
      "tz": "America\/Caracas"
  },
  "SVEW": {
      "icao": "SVEW",
      "iata": "",
      "name": "El Cenizo Airport",
      "city": "",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 426,
      "lat": 9.5500001907,
      "lon": -70.6833343506,
      "tz": "America\/Caracas"
  },
  "SVEX": {
      "icao": "SVEX",
      "iata": "",
      "name": "La Esperanza Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 256,
      "lat": 8.6999998093,
      "lon": -69.0749969482,
      "tz": "America\/Caracas"
  },
  "SVEY": {
      "icao": "SVEY",
      "iata": "",
      "name": "El Centro Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 318,
      "lat": 6.2800002098,
      "lon": -69.1166687012,
      "tz": "America\/Caracas"
  },
  "SVEZ": {
      "icao": "SVEZ",
      "iata": "EOZ",
      "name": "Elorza Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 295,
      "lat": 7.0833330154,
      "lon": -69.5333328247,
      "tz": "America\/Caracas"
  },
  "SVFE": {
      "icao": "SVFE",
      "iata": "",
      "name": "Hacienda Santa Fe Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 524,
      "lat": 9.5,
      "lon": -72.5833358765,
      "tz": "America\/Caracas"
  },
  "SVFG": {
      "icao": "SVFG",
      "iata": "",
      "name": "El Flagelo Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 663,
      "lat": 9.1883325577,
      "lon": -67.0166702271,
      "tz": "America\/Caracas"
  },
  "SVFK": {
      "icao": "SVFK",
      "iata": "",
      "name": "La Fe de Cojedes Airport",
      "city": "La Mosca",
      "state": "Guarico",
      "country": "VE",
      "elevation": 412,
      "lat": 9.483332634,
      "lon": -67.8166656494,
      "tz": "America\/Caracas"
  },
  "SVFL": {
      "icao": "SVFL",
      "iata": "",
      "name": "Fundacion Layera Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 219,
      "lat": 7.1999998093,
      "lon": -68.1333312988,
      "tz": "America\/Caracas"
  },
  "SVFM": {
      "icao": "SVFM",
      "iata": "",
      "name": "Francisco de Miranda Airport",
      "city": "La Carlota",
      "state": "Miranda",
      "country": "VE",
      "elevation": 2739,
      "lat": 10.4850330353,
      "lon": -66.8435134888,
      "tz": "America\/Caracas"
  },
  "SVFR": {
      "icao": "SVFR",
      "iata": "",
      "name": "El Frio Airport",
      "city": "Guarico",
      "state": "Apure",
      "country": "VE",
      "elevation": 219,
      "lat": 7.8233327866,
      "lon": -68.8983306885,
      "tz": "America\/Caracas"
  },
  "SVFS": {
      "icao": "SVFS",
      "iata": "",
      "name": "Fagotrans Airport",
      "city": "",
      "state": "Aragua",
      "country": "VE",
      "elevation": 590,
      "lat": 9.4016399384,
      "lon": -66.6430969238,
      "tz": "America\/Caracas"
  },
  "SVFT": {
      "icao": "SVFT",
      "iata": "",
      "name": "El Fuentero Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 420,
      "lat": 7.0999999046,
      "lon": -69.5833358765,
      "tz": "America\/Caracas"
  },
  "SVFU": {
      "icao": "SVFU",
      "iata": "",
      "name": "La Fortuna Airport",
      "city": "San Antonio de Caparo",
      "state": "Tachira",
      "country": "VE",
      "elevation": 417,
      "lat": 7.5833330154,
      "lon": -71.4666671753,
      "tz": "America\/Caracas"
  },
  "SVFX": {
      "icao": "SVFX",
      "iata": "",
      "name": "La Fe de Apure Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 442,
      "lat": 7.2133331299,
      "lon": -69.4966659546,
      "tz": "America\/Caracas"
  },
  "SVFY": {
      "icao": "SVFY",
      "iata": "",
      "name": "Finca Yacurito Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 1706,
      "lat": 9.0333328247,
      "lon": -69.0999984741,
      "tz": "America\/Caracas"
  },
  "SVGA": {
      "icao": "SVGA",
      "iata": "",
      "name": "Agropecuaria Gamma Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 495,
      "lat": 8.75,
      "lon": -70,
      "tz": "America\/Caracas"
  },
  "SVGC": {
      "icao": "SVGC",
      "iata": "",
      "name": "La Gran China Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 138,
      "lat": 9.7770795822,
      "lon": -72.4868011475,
      "tz": "America\/Caracas"
  },
  "SVGD": {
      "icao": "SVGD",
      "iata": "GDO",
      "name": "Guasdalito Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 426,
      "lat": 7.2333331108,
      "lon": -70.8000030518,
      "tz": "America\/Caracas"
  },
  "SVGE": {
      "icao": "SVGE",
      "iata": "",
      "name": "Ganaderia Pedernales Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 467,
      "lat": 9.2100000381,
      "lon": -69.5816650391,
      "tz": "America\/Caracas"
  },
  "SVGG": {
      "icao": "SVGG",
      "iata": "",
      "name": "Agropecuaria Los Araguaneyes Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 507,
      "lat": 9.5833330154,
      "lon": -63.0499992371,
      "tz": "America\/Caracas"
  },
  "SVGH": {
      "icao": "SVGH",
      "iata": "",
      "name": "La Guacharaca Airport",
      "city": "",
      "state": "Aragua",
      "country": "VE",
      "elevation": 984,
      "lat": 9.5349998474,
      "lon": -66.7249984741,
      "tz": "America\/Caracas"
  },
  "SVGI": {
      "icao": "SVGI",
      "iata": "GUI",
      "name": "Guiria Airport",
      "city": "",
      "state": "",
      "country": "VE",
      "elevation": 42,
      "lat": 10.5740776062,
      "lon": -62.3126678467,
      "tz": "America\/Caracas"
  },
  "SVGL": {
      "icao": "SVGL",
      "iata": "",
      "name": "Agua Linda Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 417,
      "lat": 7.6350002289,
      "lon": -70.8499984741,
      "tz": "America\/Caracas"
  },
  "SVGP": {
      "icao": "SVGP",
      "iata": "",
      "name": "Guesipo Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 641,
      "lat": 9.6333332062,
      "lon": -67.1833343506,
      "tz": "America\/Caracas"
  },
  "SVGR": {
      "icao": "SVGR",
      "iata": "",
      "name": "El Milagro Carabobo Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 2013,
      "lat": 10.1529998779,
      "lon": -68.3751983643,
      "tz": "America\/Caracas"
  },
  "SVGT": {
      "icao": "SVGT",
      "iata": "",
      "name": "Guasipati Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 977,
      "lat": 7.4749999046,
      "lon": -61.9000015259,
      "tz": "America\/Caracas"
  },
  "SVGU": {
      "icao": "SVGU",
      "iata": "GUQ",
      "name": "Guanare Airport",
      "city": "Guanare",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 606,
      "lat": 9.0269441605,
      "lon": -69.7551498413,
      "tz": "America\/Caracas"
  },
  "SVGX": {
      "icao": "SVGX",
      "iata": "",
      "name": "Las Guadalupes Airport",
      "city": "",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 984,
      "lat": 8.7550001144,
      "lon": -63.5250015259,
      "tz": "America\/Caracas"
  },
  "SVGZ": {
      "icao": "SVGZ",
      "iata": "",
      "name": "La Garza Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 237,
      "lat": 7.9499998093,
      "lon": -69.1166687012,
      "tz": "America\/Caracas"
  },
  "SVHA": {
      "icao": "SVHA",
      "iata": "",
      "name": "Hato Altamira de Bolivar Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1098,
      "lat": 7.5999999046,
      "lon": -63.2166671753,
      "tz": "America\/Caracas"
  },
  "SVHC": {
      "icao": "SVHC",
      "iata": "",
      "name": "Hacienda El Caimito Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 138,
      "lat": 10.1499996185,
      "lon": -72.1833343506,
      "tz": "America\/Caracas"
  },
  "SVHD": {
      "icao": "SVHD",
      "iata": "",
      "name": "Hacienda El Calvario Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 264,
      "lat": 9.8999996185,
      "lon": -72.5500030518,
      "tz": "America\/Caracas"
  },
  "SVHE": {
      "icao": "SVHE",
      "iata": "",
      "name": "Hato Rancho Alegre Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 534,
      "lat": 9.5666666031,
      "lon": -67.5666656494,
      "tz": "America\/Caracas"
  },
  "SVHG": {
      "icao": "SVHG",
      "iata": "HGE",
      "name": "Higuerote Airport",
      "city": "",
      "state": "Miranda",
      "country": "VE",
      "elevation": 10,
      "lat": 10.4624528885,
      "lon": -66.0927581787,
      "tz": "America\/Caracas"
  },
  "SVHH": {
      "icao": "SVHH",
      "iata": "",
      "name": "Churuguara Airport",
      "city": "Churuguara",
      "state": "Falcon",
      "country": "VE",
      "elevation": 2461,
      "lat": 10.8080997467,
      "lon": -69.6383972168,
      "tz": "America\/Caracas"
  },
  "SVHK": {
      "icao": "SVHK",
      "iata": "",
      "name": "Hato la Guaca Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 328,
      "lat": 9.3999996185,
      "lon": -67.7833328247,
      "tz": "America\/Caracas"
  },
  "SVHL": {
      "icao": "SVHL",
      "iata": "",
      "name": "Hacienda Carutal Airport",
      "city": "",
      "state": "Aragua",
      "country": "VE",
      "elevation": 1000,
      "lat": 9.7166671753,
      "lon": -66.7833328247,
      "tz": "America\/Caracas"
  },
  "SVHM": {
      "icao": "SVHM",
      "iata": "",
      "name": "Hato Los Mamones Airport",
      "city": "Hato Los Mamomes",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 594,
      "lat": 9.3333330154,
      "lon": -67.9166641235,
      "tz": "America\/Caracas"
  },
  "SVHN": {
      "icao": "SVHN",
      "iata": "",
      "name": "Hato La Chaconera Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 208,
      "lat": 8.1054096222,
      "lon": -68.7191009521,
      "tz": "America\/Caracas"
  },
  "SVHO": {
      "icao": "SVHO",
      "iata": "",
      "name": "El Oasis Airport",
      "city": "La Palmita",
      "state": "Barinas",
      "country": "VE",
      "elevation": 396,
      "lat": 8.3682203293,
      "lon": -69.9570999146,
      "tz": "America\/Caracas"
  },
  "SVHP": {
      "icao": "SVHP",
      "iata": "",
      "name": "Hacienda El Paso Airport",
      "city": "Las Vegas del Tuy",
      "state": "Falcon",
      "country": "VE",
      "elevation": 495,
      "lat": 10.7133331299,
      "lon": -69.2083358765,
      "tz": "America\/Caracas"
  },
  "SVHR": {
      "icao": "SVHR",
      "iata": "",
      "name": "Hato El Carito Airport",
      "city": "Los Marimares",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 256,
      "lat": 9.5,
      "lon": -67.9499969482,
      "tz": "America\/Caracas"
  },
  "SVHS": {
      "icao": "SVHS",
      "iata": "",
      "name": "Hato El Sesenta Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 499,
      "lat": 8.25,
      "lon": -65.5833358765,
      "tz": "America\/Caracas"
  },
  "SVHT": {
      "icao": "SVHT",
      "iata": "",
      "name": "Hato Altamira de Monagas Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 154,
      "lat": 9.3666667938,
      "lon": -63.0833320618,
      "tz": "America\/Caracas"
  },
  "SVHX": {
      "icao": "SVHX",
      "iata": "",
      "name": "Hato La Candelaria Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 254,
      "lat": 8.6833333969,
      "lon": -67.7166671753,
      "tz": "America\/Caracas"
  },
  "SVHY": {
      "icao": "SVHY",
      "iata": "",
      "name": "Hato Las Yeguas Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 1049,
      "lat": 8.720000267,
      "lon": -68.4716644287,
      "tz": "America\/Caracas"
  },
  "SVIC": {
      "icao": "SVIC",
      "iata": "ICA",
      "name": "Icabaru Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1574,
      "lat": 4.3363199234,
      "lon": -61.7396011353,
      "tz": "America\/Caracas"
  },
  "SVIE": {
      "icao": "SVIE",
      "iata": "ICC",
      "name": "Andres Miguel Salazar Marcano Airport",
      "city": "Isla de Coche",
      "state": "Nueva-Esparta",
      "country": "VE",
      "elevation": 10,
      "lat": 10.7944059372,
      "lon": -63.981590271,
      "tz": "America\/Caracas"
  },
  "SVIM": {
      "icao": "SVIM",
      "iata": "",
      "name": "Hato El Caiman Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 462,
      "lat": 9.1000003815,
      "lon": -69.6200027466,
      "tz": "America\/Caracas"
  },
  "SVJC": {
      "icao": "SVJC",
      "iata": "LSP",
      "name": "Josefa Camejo International Airport",
      "city": "Paraguana",
      "state": "Falcon",
      "country": "VE",
      "elevation": 75,
      "lat": 11.7807750702,
      "lon": -70.1514968872,
      "tz": "America\/Caracas"
  },
  "SVJF": {
      "icao": "SVJF",
      "iata": "",
      "name": "Juan Florencio Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 187,
      "lat": 7.4000000954,
      "lon": -67.9833297729,
      "tz": "America\/Caracas"
  },
  "SVJH": {
      "icao": "SVJH",
      "iata": "",
      "name": "Juancho Airport",
      "city": "Soledad",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 197,
      "lat": 8.1797199249,
      "lon": -63.5960006714,
      "tz": "America\/Caracas"
  },
  "SVJI": {
      "icao": "SVJI",
      "iata": "",
      "name": "Hato La Lejania Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 264,
      "lat": 9.0971803665,
      "lon": -63.118598938,
      "tz": "America\/Caracas"
  },
  "SVJJ": {
      "icao": "SVJJ",
      "iata": "",
      "name": "Curujujul Airport",
      "city": "Anzoategui",
      "state": "Apure",
      "country": "VE",
      "elevation": 321,
      "lat": 7.443333149,
      "lon": -67.7966690063,
      "tz": "America\/Caracas"
  },
  "SVJL": {
      "icao": "SVJL",
      "iata": "",
      "name": "Los Lajeros Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 132,
      "lat": 8.016667366,
      "lon": -67.3833312988,
      "tz": "America\/Caracas"
  },
  "SVJM": {
      "icao": "SVJM",
      "iata": "",
      "name": "San Juan de Los Morros Airport",
      "city": "San Juan de los Morros",
      "state": "Guarico",
      "country": "VE",
      "elevation": 1404,
      "lat": 9.906952858,
      "lon": -67.3796386719,
      "tz": "America\/Caracas"
  },
  "SVJO": {
      "icao": "SVJO",
      "iata": "",
      "name": "La Vigia Oeste Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 98,
      "lat": 9.6666669846,
      "lon": -67.0500030518,
      "tz": "America\/Caracas"
  },
  "SVJU": {
      "icao": "SVJU",
      "iata": "",
      "name": "Hato La Laguna del Junco Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 295,
      "lat": 9.1833333969,
      "lon": -68.0999984741,
      "tz": "America\/Caracas"
  },
  "SVKA": {
      "icao": "SVKA",
      "iata": "KAV",
      "name": "Kavanayen Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 3900,
      "lat": 5.632999897,
      "lon": -61.783000946,
      "tz": "America\/Caracas"
  },
  "SVKB": {
      "icao": "SVKB",
      "iata": "",
      "name": "La Calzada Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 291,
      "lat": 7.9833331108,
      "lon": -70.0999984741,
      "tz": "America\/Caracas"
  },
  "SVKC": {
      "icao": "SVKC",
      "iata": "",
      "name": "El Carrao Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 240,
      "lat": 8.5399999619,
      "lon": -68.8050003052,
      "tz": "America\/Caracas"
  },
  "SVKI": {
      "icao": "SVKI",
      "iata": "",
      "name": "Piscuri Airport",
      "city": "",
      "state": "Tachira",
      "country": "VE",
      "elevation": 726,
      "lat": 7.5466599464,
      "lon": -71.7883987427,
      "tz": "America\/Caracas"
  },
  "SVKL": {
      "icao": "SVKL",
      "iata": "",
      "name": "Las Clavellinas Airport",
      "city": "Las Clavellinasmer",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 66,
      "lat": 9.3249998093,
      "lon": -70.9749984741,
      "tz": "America\/Caracas"
  },
  "SVKN": {
      "icao": "SVKN",
      "iata": "",
      "name": "Cano Negro Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 328,
      "lat": 5.5,
      "lon": -65.6183319092,
      "tz": "America\/Caracas"
  },
  "SVKV": {
      "icao": "SVKV",
      "iata": "",
      "name": "Corral Viejo Airport",
      "city": "La Bombita",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 14,
      "lat": 9.6666669846,
      "lon": -70.9666671753,
      "tz": "America\/Caracas"
  },
  "SVLA": {
      "icao": "SVLA",
      "iata": "",
      "name": "Las Adjuntas Airport",
      "city": "Las Colmenas",
      "state": "Monagas",
      "country": "VE",
      "elevation": 534,
      "lat": 9.016667366,
      "lon": -63.75,
      "tz": "America\/Caracas"
  },
  "SVLB": {
      "icao": "SVLB",
      "iata": "",
      "name": "La Blanquilla Airport",
      "city": "La Blanquilla - Isla Margarita",
      "state": "Dependencias-Federales",
      "country": "VE",
      "elevation": 59,
      "lat": 11.8205785751,
      "lon": -64.5862121582,
      "tz": "America\/Caracas"
  },
  "SVLC": {
      "icao": "SVLC",
      "iata": "",
      "name": "El Lucero de Apure Airport",
      "city": "El Lucero",
      "state": "Apure",
      "country": "VE",
      "elevation": 262,
      "lat": 6.9833331108,
      "lon": -68.0666656494,
      "tz": "America\/Caracas"
  },
  "SVLD": {
      "icao": "SVLD",
      "iata": "",
      "name": "Las Brisas Airport",
      "city": "Mata de Vino",
      "state": "Apure",
      "country": "VE",
      "elevation": 328,
      "lat": 7.203332901,
      "lon": -69.5366668701,
      "tz": "America\/Caracas"
  },
  "SVLE": {
      "icao": "SVLE",
      "iata": "",
      "name": "El Lucero del Zulia Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 33,
      "lat": 10.4499998093,
      "lon": -71.3666687012,
      "tz": "America\/Caracas"
  },
  "SVLF": {
      "icao": "SVLF",
      "iata": "LFR",
      "name": "La Fria Airport",
      "city": "",
      "state": "Tachira",
      "country": "VE",
      "elevation": 305,
      "lat": 8.2391672134,
      "lon": -72.2710266113,
      "tz": "America\/Caracas"
  },
  "SVLG": {
      "icao": "SVLG",
      "iata": "",
      "name": "Las Majaguas Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 793,
      "lat": 9.6499996185,
      "lon": -68.9833297729,
      "tz": "America\/Caracas"
  },
  "SVLH": {
      "icao": "SVLH",
      "iata": "",
      "name": "Las Delicias Airport",
      "city": "Banco del Medio",
      "state": "Apure",
      "country": "VE",
      "elevation": 373,
      "lat": 7.1666669846,
      "lon": -69.3833312988,
      "tz": "America\/Caracas"
  },
  "SVLI": {
      "icao": "SVLI",
      "iata": "",
      "name": "La Alcancia Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 46,
      "lat": 7.3000001907,
      "lon": -67.3333358765,
      "tz": "America\/Caracas"
  },
  "SVLJ": {
      "icao": "SVLJ",
      "iata": "",
      "name": "La Vieja Airport",
      "city": "La Ceiba",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 1262,
      "lat": 9.6833333969,
      "lon": -64.1833343506,
      "tz": "America\/Caracas"
  },
  "SVLK": {
      "icao": "SVLK",
      "iata": "",
      "name": "Hato Macolla Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 296,
      "lat": 6.1666669846,
      "lon": -69.1666641235,
      "tz": "America\/Bogota"
  },
  "SVLL": {
      "icao": "SVLL",
      "iata": "",
      "name": "Callejas Airport",
      "city": "Callejas",
      "state": "Barinas",
      "country": "VE",
      "elevation": 209,
      "lat": 8.0083332062,
      "lon": -69.626663208,
      "tz": "America\/Caracas"
  },
  "SVLM": {
      "icao": "SVLM",
      "iata": "",
      "name": "Las Flores Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 118,
      "lat": 9.4979400635,
      "lon": -62.9013061523,
      "tz": "America\/Caracas"
  },
  "SVLN": {
      "icao": "SVLN",
      "iata": "",
      "name": "La Candelaria Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 420,
      "lat": 10.7266998291,
      "lon": -68.3335037231,
      "tz": "America\/Caracas"
  },
  "SVLO": {
      "icao": "SVLO",
      "iata": "",
      "name": "La Orchila Airport",
      "city": "",
      "state": "Dependencias-Federales",
      "country": "VE",
      "elevation": 5,
      "lat": 11.8072004318,
      "lon": -66.1796035767,
      "tz": "America\/Caracas"
  },
  "SVLP": {
      "icao": "SVLP",
      "iata": "",
      "name": "Las Lagunitas Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 344,
      "lat": 6.9333329201,
      "lon": -68.3666687012,
      "tz": "America\/Caracas"
  },
  "SVLS": {
      "icao": "SVLS",
      "iata": "",
      "name": "Las Corobas Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 295,
      "lat": 6.7666668892,
      "lon": -68.3499984741,
      "tz": "America\/Caracas"
  },
  "SVLT": {
      "icao": "SVLT",
      "iata": "",
      "name": "La Argentina Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 270,
      "lat": 7.5833330154,
      "lon": -69.1833343506,
      "tz": "America\/Caracas"
  },
  "SVLU": {
      "icao": "SVLU",
      "iata": "",
      "name": "El Lechozo Airport",
      "city": "El Lechozo",
      "state": "Guarico",
      "country": "VE",
      "elevation": 529,
      "lat": 9.3000001907,
      "lon": -66.6783294678,
      "tz": "America\/Caracas"
  },
  "SVLW": {
      "icao": "SVLW",
      "iata": "",
      "name": "Los Aguacates Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 1540,
      "lat": 10.0683326721,
      "lon": -68.0216674805,
      "tz": "America\/Caracas"
  },
  "SVLX": {
      "icao": "SVLX",
      "iata": "",
      "name": "La Yaguita Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 295,
      "lat": 6.9666671753,
      "lon": -69.3499984741,
      "tz": "America\/Caracas"
  },
  "SVLY": {
      "icao": "SVLY",
      "iata": "",
      "name": "La Yagua Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 393,
      "lat": 7.2833328247,
      "lon": -68.5333328247,
      "tz": "America\/Caracas"
  },
  "SVLZ": {
      "icao": "SVLZ",
      "iata": "",
      "name": "Las Cruces Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 494,
      "lat": 8.3288803101,
      "lon": -68.7519989014,
      "tz": "America\/Caracas"
  },
  "SVMA": {
      "icao": "SVMA",
      "iata": "",
      "name": "La Mona Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 2140,
      "lat": 10.1431999207,
      "lon": -68.1978988647,
      "tz": "America\/Caracas"
  },
  "SVMB": {
      "icao": "SVMB",
      "iata": "",
      "name": "El Embrujo Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 720,
      "lat": 8.671667099,
      "lon": -66.5049972534,
      "tz": "America\/Caracas"
  },
  "SVMC": {
      "icao": "SVMC",
      "iata": "MAR",
      "name": "La Chinita International Airport",
      "city": "Maracaibo",
      "state": "Zulia",
      "country": "VE",
      "elevation": 239,
      "lat": 10.5582084656,
      "lon": -71.7278594971,
      "tz": "America\/Caracas"
  },
  "SVMD": {
      "icao": "SVMD",
      "iata": "MRD",
      "name": "Alberto Carnevalli Airport",
      "city": "Merida",
      "state": "Merida",
      "country": "VE",
      "elevation": 5007,
      "lat": 8.58207798,
      "lon": -71.1610412598,
      "tz": "America\/Caracas"
  },
  "SVME": {
      "icao": "SVME",
      "iata": "",
      "name": "Mapire Airport",
      "city": "",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 196,
      "lat": 7.738629818,
      "lon": -64.7194976807,
      "tz": "America\/Caracas"
  },
  "SVMF": {
      "icao": "SVMF",
      "iata": "",
      "name": "Mata Charo Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 377,
      "lat": 8.5333328247,
      "lon": -69.9499969482,
      "tz": "America\/Caracas"
  },
  "SVMG": {
      "icao": "SVMG",
      "iata": "PMV",
      "name": "Del Caribe Santiago Marino International Airport",
      "city": "Isla Margarita",
      "state": "Nueva-Esparta",
      "country": "VE",
      "elevation": 74,
      "lat": 10.9126033783,
      "lon": -63.9665985107,
      "tz": "America\/Caracas"
  },
  "SVMH": {
      "icao": "SVMH",
      "iata": "",
      "name": "Macanillal Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 426,
      "lat": 7.3166670799,
      "lon": -68,
      "tz": "America\/Caracas"
  },
  "SVMI": {
      "icao": "SVMI",
      "iata": "CCS",
      "name": "Maiquetia (Simon Bolivar Internacional) Airport",
      "city": "Caracas",
      "state": "Vargas",
      "country": "VE",
      "elevation": 235,
      "lat": 10.6031169891,
      "lon": -66.9905853271,
      "tz": "America\/Caracas"
  },
  "SVMJ": {
      "icao": "SVMJ",
      "iata": "",
      "name": "Hacienda Las Lomas Airport",
      "city": "",
      "state": "Yaracuy",
      "country": "VE",
      "elevation": 214,
      "lat": 10.5983333588,
      "lon": -68.5350036621,
      "tz": "America\/Caracas"
  },
  "SVMK": {
      "icao": "SVMK",
      "iata": "",
      "name": "El Milagro Cojedes Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 262,
      "lat": 9.2299995422,
      "lon": -67.9700012207,
      "tz": "America\/Caracas"
  },
  "SVML": {
      "icao": "SVML",
      "iata": "",
      "name": "El Milagro Sureste Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 230,
      "lat": 7.2666668892,
      "lon": -67.2833328247,
      "tz": "America\/Caracas"
  },
  "SVMM": {
      "icao": "SVMM",
      "iata": "",
      "name": "Mamon Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 151,
      "lat": 11.266667366,
      "lon": -70.25,
      "tz": "America\/Caracas"
  },
  "SVMN": {
      "icao": "SVMN",
      "iata": "",
      "name": "Mene Grande Airport",
      "city": "Mene Grande",
      "state": "Zulia",
      "country": "VE",
      "elevation": 90,
      "lat": 9.8031597137,
      "lon": -70.8921966553,
      "tz": "America\/Caracas"
  },
  "SVMO": {
      "icao": "SVMO",
      "iata": "",
      "name": "El Milagro Oeste Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 295,
      "lat": 7.6833329201,
      "lon": -68.1833343506,
      "tz": "America\/Caracas"
  },
  "SVMP": {
      "icao": "SVMP",
      "iata": "",
      "name": "Metropolitano Airport",
      "city": "",
      "state": "Miranda",
      "country": "VE",
      "elevation": 574,
      "lat": 10.1331691742,
      "lon": -66.7878265381,
      "tz": "America\/Caracas"
  },
  "SVMQ": {
      "icao": "SVMQ",
      "iata": "",
      "name": "Fundo la Marquesena Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 646,
      "lat": 8.8416671753,
      "lon": -70.0333328247,
      "tz": "America\/Caracas"
  },
  "SVMS": {
      "icao": "SVMS",
      "iata": "",
      "name": "Guasimal Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 328,
      "lat": 7.7166671753,
      "lon": -67.9833297729,
      "tz": "America\/Caracas"
  },
  "SVMT": {
      "icao": "SVMT",
      "iata": "MUN",
      "name": "Maturin Airport",
      "city": "",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 224,
      "lat": 9.754529953,
      "lon": -63.1473999023,
      "tz": "America\/Caracas"
  },
  "SVMV": {
      "icao": "SVMV",
      "iata": "",
      "name": "Maroa Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 501,
      "lat": 2.6925001144,
      "lon": -67.5188980103,
      "tz": "America\/Caracas"
  },
  "SVMW": {
      "icao": "SVMW",
      "iata": "",
      "name": "Los Malabares Airport",
      "city": "",
      "state": "Tachira",
      "country": "VE",
      "elevation": 98,
      "lat": 7.6166667938,
      "lon": -72.4499969482,
      "tz": "America\/Caracas"
  },
  "SVMX": {
      "icao": "SVMX",
      "iata": "",
      "name": "Mata Airport",
      "city": "Las Fernandez",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 695,
      "lat": 9.1999998093,
      "lon": -64.0500030518,
      "tz": "America\/Caracas"
  },
  "SVMY": {
      "icao": "SVMY",
      "iata": "",
      "name": "Manapiare Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 656,
      "lat": 5.3083329201,
      "lon": -66.0500030518,
      "tz": "America\/Caracas"
  },
  "SVMZ": {
      "icao": "SVMZ",
      "iata": "",
      "name": "Mantecal Airport",
      "city": "Mantecal",
      "state": "Apure",
      "country": "VE",
      "elevation": 295,
      "lat": 7.5575799942,
      "lon": -69.1416015625,
      "tz": "America\/Caracas"
  },
  "SVNA": {
      "icao": "SVNA",
      "iata": "",
      "name": "Macana Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 200,
      "lat": 9.9230203629,
      "lon": -72.0804977417,
      "tz": "America\/Caracas"
  },
  "SVNB": {
      "icao": "SVNB",
      "iata": "",
      "name": "Maripa Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 200,
      "lat": 7.4000000954,
      "lon": -65.1833343506,
      "tz": "America\/Caracas"
  },
  "SVNE": {
      "icao": "SVNE",
      "iata": "",
      "name": "Las Mercedes Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 492,
      "lat": 7.7666668892,
      "lon": -70.2666702271,
      "tz": "America\/Caracas"
  },
  "SVNF": {
      "icao": "SVNF",
      "iata": "",
      "name": "Morichitos Airport",
      "city": "Morichito",
      "state": "Apure",
      "country": "VE",
      "elevation": 210,
      "lat": 7.3833332062,
      "lon": -68.8333358765,
      "tz": "America\/Caracas"
  },
  "SVNG": {
      "icao": "SVNG",
      "iata": "",
      "name": "El Mango Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 393,
      "lat": 10.2833328247,
      "lon": -72.4666671753,
      "tz": "America\/Caracas"
  },
  "SVNJ": {
      "icao": "SVNJ",
      "iata": "",
      "name": "Morichito II Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 361,
      "lat": 8.9666671753,
      "lon": -66.0833358765,
      "tz": "America\/Caracas"
  },
  "SVNK": {
      "icao": "SVNK",
      "iata": "",
      "name": "Hato Mikitole Airport",
      "city": "",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 328,
      "lat": 8.4177780151,
      "lon": -63.567779541,
      "tz": "America\/Caracas"
  },
  "SVNP": {
      "icao": "SVNP",
      "iata": "",
      "name": "Marisol Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 328,
      "lat": 9.1333332062,
      "lon": -69.0550003052,
      "tz": "America\/Caracas"
  },
  "SVNR": {
      "icao": "SVNR",
      "iata": "",
      "name": "El Morichal Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 492,
      "lat": 6.9833331108,
      "lon": -68.6333312988,
      "tz": "America\/Caracas"
  },
  "SVNT": {
      "icao": "SVNT",
      "iata": "",
      "name": "Mata de Turagua Airport",
      "city": "La Guarandinga",
      "state": "Apure",
      "country": "VE",
      "elevation": 354,
      "lat": 7.6999998093,
      "lon": -69.3000030518,
      "tz": "America\/Caracas"
  },
  "SVNV": {
      "icao": "SVNV",
      "iata": "",
      "name": "Las Nieves Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 866,
      "lat": 6.5833330154,
      "lon": -66.1999969482,
      "tz": "America\/Caracas"
  },
  "SVNX": {
      "icao": "SVNX",
      "iata": "",
      "name": "Morichal Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 272,
      "lat": 8.8281497955,
      "lon": -63.0950012207,
      "tz": "America\/Caracas"
  },
  "SVOA": {
      "icao": "SVOA",
      "iata": "",
      "name": "San Lorenzo De Barinas Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 374,
      "lat": 8.2847223282,
      "lon": -69.9166641235,
      "tz": "America\/Caracas"
  },
  "SVOB": {
      "icao": "SVOB",
      "iata": "",
      "name": "San Lorenzo De Falcon Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 137,
      "lat": 11.0500001907,
      "lon": -68.4833297729,
      "tz": "America\/Caracas"
  },
  "SVOC": {
      "icao": "SVOC",
      "iata": "",
      "name": "San Luis Capanaparo Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 202,
      "lat": 6.9666671753,
      "lon": -68.3333358765,
      "tz": "America\/Caracas"
  },
  "SVOD": {
      "icao": "SVOD",
      "iata": "",
      "name": "Santa Ana Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 262,
      "lat": 7.9833331108,
      "lon": -67.6500015259,
      "tz": "America\/Caracas"
  },
  "SVOE": {
      "icao": "SVOE",
      "iata": "",
      "name": "Sabaneta Airport",
      "city": "Sabaneta",
      "state": "Barinas",
      "country": "VE",
      "elevation": 530,
      "lat": 8.7441396713,
      "lon": -69.9138031006,
      "tz": "America\/Caracas"
  },
  "SVOF": {
      "icao": "SVOF",
      "iata": "",
      "name": "Santa Barbara de Monagas Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 804,
      "lat": 9.6984996796,
      "lon": -63.6222991943,
      "tz": "America\/Caracas"
  },
  "SVOG": {
      "icao": "SVOG",
      "iata": "",
      "name": "Santa Barbara del Amazonas Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 328,
      "lat": 3.9416670799,
      "lon": -67.0783309937,
      "tz": "America\/Caracas"
  },
  "SVOH": {
      "icao": "SVOH",
      "iata": "",
      "name": "San Silvestre Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 350,
      "lat": 8.3500003815,
      "lon": -70.0500030518,
      "tz": "America\/Caracas"
  },
  "SVOI": {
      "icao": "SVOI",
      "iata": "",
      "name": "Los Indios Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 328,
      "lat": 7.3000001907,
      "lon": -68.0666656494,
      "tz": "America\/Caracas"
  },
  "SVOJ": {
      "icao": "SVOJ",
      "iata": "",
      "name": "Ojo de Agua Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 328,
      "lat": 8.0666666031,
      "lon": -70.1833343506,
      "tz": "America\/Caracas"
  },
  "SVOK": {
      "icao": "SVOK",
      "iata": "",
      "name": "Oritupano Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 485,
      "lat": 9.0616674423,
      "lon": -63.4449996948,
      "tz": "America\/Caracas"
  },
  "SVOL": {
      "icao": "SVOL",
      "iata": "",
      "name": "San Roque Airport",
      "city": "Santa Ana",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 590,
      "lat": 9.3166666031,
      "lon": -64.6333312988,
      "tz": "America\/Caracas"
  },
  "SVON": {
      "icao": "SVON",
      "iata": "CBS",
      "name": "Oro Negro Airport",
      "city": "Cabimas",
      "state": "Zulia",
      "country": "VE",
      "elevation": 164,
      "lat": 10.3306999207,
      "lon": -71.3225021362,
      "tz": "America\/Caracas"
  },
  "SVOO": {
      "icao": "SVOO",
      "iata": "",
      "name": "Hato El Rosero Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 262,
      "lat": 7.6500000954,
      "lon": -69.4166641235,
      "tz": "America\/Caracas"
  },
  "SVOP": {
      "icao": "SVOP",
      "iata": "",
      "name": "Los Oripopos Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 278,
      "lat": 7.5999999046,
      "lon": -68.4333343506,
      "tz": "America\/Caracas"
  },
  "SVOQ": {
      "icao": "SVOQ",
      "iata": "",
      "name": "Santa Monica Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 836,
      "lat": 8.6666669846,
      "lon": -67.6666641235,
      "tz": "America\/Caracas"
  },
  "SVOR": {
      "icao": "SVOR",
      "iata": "",
      "name": "San Ramon Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 231,
      "lat": 8.0916671753,
      "lon": -66.4216690063,
      "tz": "America\/Caracas"
  },
  "SVOS": {
      "icao": "SVOS",
      "iata": "",
      "name": "Los Cocos Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 328,
      "lat": 7.4666671753,
      "lon": -68.6666641235,
      "tz": "America\/Caracas"
  },
  "SVOT": {
      "icao": "SVOT",
      "iata": "",
      "name": "San Pablo De Barinas Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 390,
      "lat": 7.7666668892,
      "lon": -70.6333312988,
      "tz": "America\/Caracas"
  },
  "SVOW": {
      "icao": "SVOW",
      "iata": "",
      "name": "Coco de Mono Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 380,
      "lat": 7.1999998093,
      "lon": -68.5166702271,
      "tz": "America\/Caracas"
  },
  "SVOZ": {
      "icao": "SVOZ",
      "iata": "",
      "name": "San Pablo Paeno Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 209,
      "lat": 7.7833328247,
      "lon": -68.5,
      "tz": "America\/Caracas"
  },
  "SVPA": {
      "icao": "SVPA",
      "iata": "PYH",
      "name": "Cacique Aramare Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 245,
      "lat": 5.619989872,
      "lon": -67.6061019897,
      "tz": "America\/Bogota"
  },
  "SVPB": {
      "icao": "SVPB",
      "iata": "",
      "name": "Punta Brava Airport",
      "city": "",
      "state": "",
      "country": "VE",
      "elevation": 19,
      "lat": 10.7839002609,
      "lon": -68.3436965942,
      "tz": "America\/Caracas"
  },
  "SVPC": {
      "icao": "SVPC",
      "iata": "PBL",
      "name": "General Bartolome Salom International Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 32,
      "lat": 10.4805002213,
      "lon": -68.0729980469,
      "tz": "America\/Caracas"
  },
  "SVPD": {
      "icao": "SVPD",
      "iata": "",
      "name": "Hato La Ponderosa Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 257,
      "lat": 7.0999999046,
      "lon": -67.9000015259,
      "tz": "America\/Caracas"
  },
  "SVPE": {
      "icao": "SVPE",
      "iata": "PDZ",
      "name": "Pedernales Airport",
      "city": "",
      "state": "Delta-Amacuro",
      "country": "VE",
      "elevation": 25,
      "lat": 9.9792404175,
      "lon": -62.2285995483,
      "tz": "America\/Caracas"
  },
  "SVPF": {
      "icao": "SVPF",
      "iata": "",
      "name": "Pariaguan Airport",
      "city": "Pariaguan",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 787,
      "lat": 8.8191204071,
      "lon": -64.7397003174,
      "tz": "America\/Caracas"
  },
  "SVPG": {
      "icao": "SVPG",
      "iata": "",
      "name": "El Progreso Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 984,
      "lat": 7.1333332062,
      "lon": -68.5833358765,
      "tz": "America\/Caracas"
  },
  "SVPH": {
      "icao": "SVPH",
      "iata": "PPH",
      "name": "Perai Tepuy Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 2797,
      "lat": 4.5666670799,
      "lon": -61.4833335876,
      "tz": "America\/Caracas"
  },
  "SVPI": {
      "icao": "SVPI",
      "iata": "",
      "name": "El Pauji Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 2716,
      "lat": 4.4666671753,
      "lon": -61.593334198,
      "tz": "America\/Caracas"
  },
  "SVPJ": {
      "icao": "SVPJ",
      "iata": "",
      "name": "Pesurca Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 726,
      "lat": 6.2833328247,
      "lon": -69.3166656494,
      "tz": "America\/Caracas"
  },
  "SVPK": {
      "icao": "SVPK",
      "iata": "",
      "name": "Las Palmeras Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 262,
      "lat": 7.6333332062,
      "lon": -69.3666687012,
      "tz": "America\/Caracas"
  },
  "SVPL": {
      "icao": "SVPL",
      "iata": "",
      "name": "El Pueblito Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1050,
      "lat": 7.8166670799,
      "lon": -62.7166671753,
      "tz": "America\/Caracas"
  },
  "SVPM": {
      "icao": "SVPM",
      "iata": "SCI",
      "name": "Paramillo Airport",
      "city": "",
      "state": "Tachira",
      "country": "VE",
      "elevation": 3314,
      "lat": 7.801320076,
      "lon": -72.2029037476,
      "tz": "America\/Caracas"
  },
  "SVPN": {
      "icao": "SVPN",
      "iata": "",
      "name": "Espino Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 689,
      "lat": 8.5635004044,
      "lon": -66.0183029175,
      "tz": "America\/Caracas"
  },
  "SVPO": {
      "icao": "SVPO",
      "iata": "",
      "name": "Hato El Porvenir Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 295,
      "lat": 7.5500001907,
      "lon": -68.25,
      "tz": "America\/Caracas"
  },
  "SVPP": {
      "icao": "SVPP",
      "iata": "",
      "name": "Luepa Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 4250,
      "lat": 5.7926797867,
      "lon": -61.4402008057,
      "tz": "America\/Caracas"
  },
  "SVPQ": {
      "icao": "SVPQ",
      "iata": "",
      "name": "El Pardillero Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 2001,
      "lat": 8.5982704163,
      "lon": -69.7608032227,
      "tz": "America\/Caracas"
  },
  "SVPR": {
      "icao": "SVPR",
      "iata": "PZO",
      "name": "General Manuel Carlos Piar International Airport",
      "city": "Puerto Ordaz-Ciudad Guayana",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 472,
      "lat": 8.2885303497,
      "lon": -62.7603988647,
      "tz": "America\/Caracas"
  },
  "SVPS": {
      "icao": "SVPS",
      "iata": "",
      "name": "Hacienda La Pastora Airport",
      "city": "San Pedro",
      "state": "Lara",
      "country": "VE",
      "elevation": 1084,
      "lat": 9.8900003433,
      "lon": -70.1216659546,
      "tz": "America\/Caracas"
  },
  "SVPT": {
      "icao": "SVPT",
      "iata": "PTM",
      "name": "Palmarito Airport",
      "city": "Palmarito",
      "state": "Apure",
      "country": "VE",
      "elevation": 347,
      "lat": 7.5666699409,
      "lon": -70.1832962036,
      "tz": "America\/Caracas"
  },
  "SVPU": {
      "icao": "SVPU",
      "iata": "",
      "name": "La Paragua Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 797,
      "lat": 6.8499999046,
      "lon": -63.3166656494,
      "tz": "America\/Caracas"
  },
  "SVPV": {
      "icao": "SVPV",
      "iata": "",
      "name": "Pedraza La Vieja Airport",
      "city": "Pedraza La Vieja",
      "state": "Barinas",
      "country": "VE",
      "elevation": 554,
      "lat": 7.9166669846,
      "lon": -71.0333328247,
      "tz": "America\/Caracas"
  },
  "SVPW": {
      "icao": "SVPW",
      "iata": "",
      "name": "Pelayo Airport",
      "city": "",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 574,
      "lat": 9,
      "lon": -63.6666679382,
      "tz": "America\/Caracas"
  },
  "SVPX": {
      "icao": "SVPX",
      "iata": "",
      "name": "El Pinal Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 268,
      "lat": 7.3666667938,
      "lon": -68.6833343506,
      "tz": "America\/Caracas"
  },
  "SVPY": {
      "icao": "SVPY",
      "iata": "",
      "name": "Palma Sola Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 32,
      "lat": 10.4899997711,
      "lon": -68.1750030518,
      "tz": "America\/Caracas"
  },
  "SVPZ": {
      "icao": "SVPZ",
      "iata": "",
      "name": "La Paz Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 344,
      "lat": 7.4883332253,
      "lon": -68.9933319092,
      "tz": "America\/Caracas"
  },
  "SVQA": {
      "icao": "SVQA",
      "iata": "",
      "name": "Santa Rosalia Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 446,
      "lat": 7.4666671753,
      "lon": -65.5666656494,
      "tz": "America\/Caracas"
  },
  "SVQB": {
      "icao": "SVQB",
      "iata": "",
      "name": "Santa Elena de Rio Claro Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 219,
      "lat": 7.0500001907,
      "lon": -67.7666702271,
      "tz": "America\/Caracas"
  },
  "SVQC": {
      "icao": "SVQC",
      "iata": "",
      "name": "Santa Maria La Tigra Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 164,
      "lat": 6.5,
      "lon": -67.1333312988,
      "tz": "America\/Caracas"
  },
  "SVQD": {
      "icao": "SVQD",
      "iata": "",
      "name": "Santa Juana Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 705,
      "lat": 8.9583330154,
      "lon": -65.1383361816,
      "tz": "America\/Caracas"
  },
  "SVQI": {
      "icao": "SVQI",
      "iata": "",
      "name": "Tucupido Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 377,
      "lat": 9.3500003815,
      "lon": -65.8000030518,
      "tz": "America\/Caracas"
  },
  "SVQJ": {
      "icao": "SVQJ",
      "iata": "",
      "name": "La Trinidad De Apure Airport",
      "city": "La Trinidad",
      "state": "Apure",
      "country": "VE",
      "elevation": 267,
      "lat": 7.2333331108,
      "lon": -69.0999984741,
      "tz": "America\/Caracas"
  },
  "SVQK": {
      "icao": "SVQK",
      "iata": "",
      "name": "Tierra Negra Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 924,
      "lat": 9.6783332825,
      "lon": -66.1633300781,
      "tz": "America\/Caracas"
  },
  "SVQL": {
      "icao": "SVQL",
      "iata": "",
      "name": "Tocomita Airport",
      "city": "Guri",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 590,
      "lat": 7.7577600479,
      "lon": -63.0822982788,
      "tz": "America\/Caracas"
  },
  "SVQN": {
      "icao": "SVQN",
      "iata": "",
      "name": "Torunos Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 337,
      "lat": 8.3166666031,
      "lon": -70.0500030518,
      "tz": "America\/Caracas"
  },
  "SVQO": {
      "icao": "SVQO",
      "iata": "",
      "name": "Tocuyito Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 1710,
      "lat": 10.0823001862,
      "lon": -68.1190032959,
      "tz": "America\/Caracas"
  },
  "SVQP": {
      "icao": "SVQP",
      "iata": "",
      "name": "Miquelon Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 18,
      "lat": 9.0500001907,
      "lon": -71.368057251,
      "tz": "America\/Caracas"
  },
  "SVQQ": {
      "icao": "SVQQ",
      "iata": "",
      "name": "Los Conucos Airport",
      "city": "",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 262,
      "lat": 9.8500003815,
      "lon": -65.3166656494,
      "tz": "America\/Caracas"
  },
  "SVQR": {
      "icao": "SVQR",
      "iata": "",
      "name": "Santa Rita Airport",
      "city": "El Diero",
      "state": "Apure",
      "country": "VE",
      "elevation": 367,
      "lat": 7.5166668892,
      "lon": -68.6833343506,
      "tz": "America\/Caracas"
  },
  "SVQS": {
      "icao": "SVQS",
      "iata": "",
      "name": "Santa Rita Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 210,
      "lat": 7.6666669846,
      "lon": -66.8000030518,
      "tz": "America\/Caracas"
  },
  "SVQT": {
      "icao": "SVQT",
      "iata": "",
      "name": "Los Quitasoles Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 492,
      "lat": 7.6333332062,
      "lon": -69.9250030518,
      "tz": "America\/Caracas"
  },
  "SVQU": {
      "icao": "SVQU",
      "iata": "",
      "name": "Santa Rosa de Guanare Airport",
      "city": "San Isidro",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 429,
      "lat": 8.7495803833,
      "lon": -69.7225036621,
      "tz": "America\/Caracas"
  },
  "SVQW": {
      "icao": "SVQW",
      "iata": "",
      "name": "Tamatal Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 379,
      "lat": 8.8333330154,
      "lon": -66.3666687012,
      "tz": "America\/Caracas"
  },
  "SVQX": {
      "icao": "SVQX",
      "iata": "",
      "name": "Tamayare Airport",
      "city": "El Paso",
      "state": "Lara",
      "country": "VE",
      "elevation": 2100,
      "lat": 10.5666666031,
      "lon": -70.1333312988,
      "tz": "America\/Caracas"
  },
  "SVQY": {
      "icao": "SVQY",
      "iata": "",
      "name": "Tapaquire Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 475,
      "lat": 7.9099998474,
      "lon": -64.0583343506,
      "tz": "America\/Caracas"
  },
  "SVQZ": {
      "icao": "SVQZ",
      "iata": "",
      "name": "Santa Rosa de Barinas Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 354,
      "lat": 8.4333333969,
      "lon": -69.7333297729,
      "tz": "America\/Caracas"
  },
  "SVRA": {
      "icao": "SVRA",
      "iata": "",
      "name": "Rio de Agua Airport",
      "city": "",
      "state": "Sucre",
      "country": "VE",
      "elevation": 33,
      "lat": 10.5717000961,
      "lon": -62.9922981262,
      "tz": "America\/Caracas"
  },
  "SVRB": {
      "icao": "SVRB",
      "iata": "",
      "name": "Cumarebo Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 26,
      "lat": 11.4866666794,
      "lon": -69.3550033569,
      "tz": "America\/Caracas"
  },
  "SVRC": {
      "icao": "SVRC",
      "iata": "",
      "name": "El Rocio Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 328,
      "lat": 10.7833328247,
      "lon": -68.5500030518,
      "tz": "America\/Caracas"
  },
  "SVRE": {
      "icao": "SVRE",
      "iata": "",
      "name": "Aracay Abajo Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 420,
      "lat": 8.3933334351,
      "lon": -65.8666687012,
      "tz": "America\/Caracas"
  },
  "SVRG": {
      "icao": "SVRG",
      "iata": "",
      "name": "Rancho Grande de Apure Airport",
      "city": "La Leona",
      "state": "Apure",
      "country": "VE",
      "elevation": 918,
      "lat": 7.3499999046,
      "lon": -69.3499984741,
      "tz": "America\/Caracas"
  },
  "SVRH": {
      "icao": "SVRH",
      "iata": "",
      "name": "Rancho Grande de Tachira Airport",
      "city": "",
      "state": "Tachira",
      "country": "VE",
      "elevation": 315,
      "lat": 7.6166667938,
      "lon": -71.9833297729,
      "tz": "America\/Caracas"
  },
  "SVRI": {
      "icao": "SVRI",
      "iata": "",
      "name": "Riecito Airport",
      "city": "Cano Riecito",
      "state": "Apure",
      "country": "VE",
      "elevation": 442,
      "lat": 6.7840600014,
      "lon": -68.8650970459,
      "tz": "America\/Caracas"
  },
  "SVRK": {
      "icao": "SVRK",
      "iata": "",
      "name": "Los Arrecifes Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 328,
      "lat": 9.1666669846,
      "lon": -67.75,
      "tz": "America\/Caracas"
  },
  "SVRL": {
      "icao": "SVRL",
      "iata": "",
      "name": "San Pancracio Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 2034,
      "lat": 8.516667366,
      "lon": -69.6416702271,
      "tz": "America\/Caracas"
  },
  "SVRM": {
      "icao": "SVRM",
      "iata": "",
      "name": "Hato La Romerena Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 665,
      "lat": 9.5,
      "lon": -67.6666641235,
      "tz": "America\/Caracas"
  },
  "SVRN": {
      "icao": "SVRN",
      "iata": "",
      "name": "El Terron Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 376,
      "lat": 9.3833332062,
      "lon": -63.2666664124,
      "tz": "America\/Caracas"
  },
  "SVRO": {
      "icao": "SVRO",
      "iata": "",
      "name": "El Rosario Airport",
      "city": "",
      "state": "Sucre",
      "country": "VE",
      "elevation": 693,
      "lat": 10.4366674423,
      "lon": -63.9166679382,
      "tz": "America\/Caracas"
  },
  "SVRQ": {
      "icao": "SVRQ",
      "iata": "",
      "name": "Rosetta Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 550,
      "lat": 5.4333329201,
      "lon": -66.1341705322,
      "tz": "America\/Caracas"
  },
  "SVRR": {
      "icao": "SVRR",
      "iata": "",
      "name": "Carrao Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1542,
      "lat": 5.9666671753,
      "lon": -62.25,
      "tz": "America\/Caracas"
  },
  "SVRS": {
      "icao": "SVRS",
      "iata": "LRV",
      "name": "Gran Roque Airport",
      "city": "Los Roques",
      "state": "Dependencias-Federales",
      "country": "VE",
      "elevation": 17,
      "lat": 11.9499998093,
      "lon": -66.6699981689,
      "tz": "America\/Caracas"
  },
  "SVRT": {
      "icao": "SVRT",
      "iata": "",
      "name": "Corocito Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 354,
      "lat": 7.0500001907,
      "lon": -68.5666656494,
      "tz": "America\/Caracas"
  },
  "SVRU": {
      "icao": "SVRU",
      "iata": "",
      "name": "Roblecito Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 459,
      "lat": 9.200220108,
      "lon": -66.3111114502,
      "tz": "America\/Caracas"
  },
  "SVRW": {
      "icao": "SVRW",
      "iata": "",
      "name": "Rosario Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 118,
      "lat": 9.1999998093,
      "lon": -72.3833312988,
      "tz": "America\/Caracas"
  },
  "SVRX": {
      "icao": "SVRX",
      "iata": "",
      "name": "Hacienda Rio Yaza Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 492,
      "lat": 9.8434696198,
      "lon": -72.547203064,
      "tz": "America\/Caracas"
  },
  "SVRY": {
      "icao": "SVRY",
      "iata": "",
      "name": "El Merey Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 242,
      "lat": 8.1666669846,
      "lon": -62.8333320618,
      "tz": "America\/Caracas"
  },
  "SVRZ": {
      "icao": "SVRZ",
      "iata": "",
      "name": "El Corozo Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 590,
      "lat": 8.4666671753,
      "lon": -66.0333328247,
      "tz": "America\/Caracas"
  },
  "SVSA": {
      "icao": "SVSA",
      "iata": "SVZ",
      "name": "San Antonio Del Tachira Airport",
      "city": "",
      "state": "Tachira",
      "country": "VE",
      "elevation": 1312,
      "lat": 7.8408298492,
      "lon": -72.4396972656,
      "tz": "America\/Bogota"
  },
  "SVSB": {
      "icao": "SVSB",
      "iata": "SBB",
      "name": "Santa Barbara de Barinas Airport",
      "city": "Santa Barbara",
      "state": "Barinas",
      "country": "VE",
      "elevation": 590,
      "lat": 7.8035140038,
      "lon": -71.1657180786,
      "tz": "America\/Caracas"
  },
  "SVSC": {
      "icao": "SVSC",
      "iata": "",
      "name": "San Carlos Rio Negro Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 367,
      "lat": 1.9166669846,
      "lon": -67.0500030518,
      "tz": "America\/Bogota"
  },
  "SVSD": {
      "icao": "SVSD",
      "iata": "",
      "name": "Sabana Dulce Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 390,
      "lat": 8.8166666031,
      "lon": -69.6333312988,
      "tz": "America\/Caracas"
  },
  "SVSE": {
      "icao": "SVSE",
      "iata": "SNV",
      "name": "Santa Elena de Uairen Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 2938,
      "lat": 4.5549998283,
      "lon": -61.1500015259,
      "tz": "America\/Caracas"
  },
  "SVSF": {
      "icao": "SVSF",
      "iata": "",
      "name": "San Antonio De Falcon Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 26,
      "lat": 11.0500001907,
      "lon": -68.3850021362,
      "tz": "America\/Caracas"
  },
  "SVSG": {
      "icao": "SVSG",
      "iata": "",
      "name": "San Antonio De Barinas Airport",
      "city": "Barinas",
      "state": "Barinas",
      "country": "VE",
      "elevation": 460,
      "lat": 8.5666666031,
      "lon": -70.0500030518,
      "tz": "America\/Caracas"
  },
  "SVSH": {
      "icao": "SVSH",
      "iata": "",
      "name": "San Juan De Rio Claro Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 144,
      "lat": 7.1841669083,
      "lon": -67.1680526733,
      "tz": "America\/Caracas"
  },
  "SVSI": {
      "icao": "SVSI",
      "iata": "",
      "name": "Agropecuaria San Francisco Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 249,
      "lat": 8.9499998093,
      "lon": -68.0833358765,
      "tz": "America\/Caracas"
  },
  "SVSJ": {
      "icao": "SVSJ",
      "iata": "",
      "name": "Central Bolivar Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 39,
      "lat": 8.9166669846,
      "lon": -71.9333343506,
      "tz": "America\/Caracas"
  },
  "SVSK": {
      "icao": "SVSK",
      "iata": "",
      "name": "Nuestra Senora del Socorro Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 485,
      "lat": 9.7783327103,
      "lon": -68.1750030518,
      "tz": "America\/Caracas"
  },
  "SVSL": {
      "icao": "SVSL",
      "iata": "",
      "name": "Hacienda Santa Elena de Mirand Airport",
      "city": "",
      "state": "Miranda",
      "country": "VE",
      "elevation": 233,
      "lat": 10.3166666031,
      "lon": -66.3000030518,
      "tz": "America\/Caracas"
  },
  "SVSM": {
      "icao": "SVSM",
      "iata": "",
      "name": "Fundo Santa Maria Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 400,
      "lat": 8.3216667175,
      "lon": -70.2466659546,
      "tz": "America\/Caracas"
  },
  "SVSN": {
      "icao": "SVSN",
      "iata": "",
      "name": "Los Siete Samanes Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 394,
      "lat": 8.8756399155,
      "lon": -66.8398971558,
      "tz": "America\/Caracas"
  },
  "SVSO": {
      "icao": "SVSO",
      "iata": "STD",
      "name": "Mayor Buenaventura Vivas International Airport",
      "city": "Santo Domingo",
      "state": "Tachira",
      "country": "VE",
      "elevation": 1083,
      "lat": 7.5653800964,
      "lon": -72.0351028442,
      "tz": "America\/Caracas"
  },
  "SVSP": {
      "icao": "SVSP",
      "iata": "SNF",
      "name": "Sub Teniente Nestor Arias Airport",
      "city": "San Felipe",
      "state": "Yaracuy",
      "country": "VE",
      "elevation": 761,
      "lat": 10.2786998749,
      "lon": -68.7552032471,
      "tz": "America\/Caracas"
  },
  "SVSQ": {
      "icao": "SVSQ",
      "iata": "",
      "name": "San Leonardo Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 222,
      "lat": 7.0333328247,
      "lon": -68.3333358765,
      "tz": "America\/Caracas"
  },
  "SVSR": {
      "icao": "SVSR",
      "iata": "SFD",
      "name": "San Fernando De Apure Airport",
      "city": "Inglaterra",
      "state": "Apure",
      "country": "VE",
      "elevation": 154,
      "lat": 7.8833198547,
      "lon": -67.4440002441,
      "tz": "America\/Caracas"
  },
  "SVSS": {
      "icao": "SVSS",
      "iata": "",
      "name": "Hacienda San Jose Airport",
      "city": "San Jose de Trujillo",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 98,
      "lat": 9.4666671753,
      "lon": -71.0333328247,
      "tz": "America\/Caracas"
  },
  "SVST": {
      "icao": "SVST",
      "iata": "SOM",
      "name": "San Tome Airport",
      "city": "",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 861,
      "lat": 8.9451465607,
      "lon": -64.1510848999,
      "tz": "America\/Caracas"
  },
  "SVSU": {
      "icao": "SVSU",
      "iata": "",
      "name": "San Benito Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 75,
      "lat": 10.3022222519,
      "lon": -72.0522232056,
      "tz": "America\/Caracas"
  },
  "SVSW": {
      "icao": "SVSW",
      "iata": "",
      "name": "San Francisco De Carabobo Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 1484,
      "lat": 10.1022224426,
      "lon": -68.0833358765,
      "tz": "America\/Caracas"
  },
  "SVSX": {
      "icao": "SVSX",
      "iata": "",
      "name": "San Juan de los Cayos Airport",
      "city": "",
      "state": "Falcon",
      "country": "VE",
      "elevation": 165,
      "lat": 11.1750001907,
      "lon": -68.4216690063,
      "tz": "America\/Caracas"
  },
  "SVSY": {
      "icao": "SVSY",
      "iata": "",
      "name": "San Francisco de Merida Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 99,
      "lat": 8.8000001907,
      "lon": -71.5833358765,
      "tz": "America\/Caracas"
  },
  "SVSZ": {
      "icao": "SVSZ",
      "iata": "STB",
      "name": "Santa Barbara del Zulia Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 32,
      "lat": 8.9745502472,
      "lon": -71.9432525635,
      "tz": "America\/Caracas"
  },
  "SVTB": {
      "icao": "SVTB",
      "iata": "",
      "name": "Turapa Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 390,
      "lat": 7.4000000954,
      "lon": -64.3666687012,
      "tz": "America\/Caracas"
  },
  "SVTC": {
      "icao": "SVTC",
      "iata": "TUV",
      "name": "Tucupita Airport",
      "city": "Tucupita",
      "state": "Delta-Amacuro",
      "country": "VE",
      "elevation": 16,
      "lat": 9.0889940262,
      "lon": -62.0941734314,
      "tz": "America\/Caracas"
  },
  "SVTD": {
      "icao": "SVTD",
      "iata": "",
      "name": "La Estacada Airport",
      "city": "La Estacada",
      "state": "Apure",
      "country": "VE",
      "elevation": 360,
      "lat": 7.4000000954,
      "lon": -69.0666656494,
      "tz": "America\/Caracas"
  },
  "SVTE": {
      "icao": "SVTE",
      "iata": "",
      "name": "Antabare Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1204,
      "lat": 6.4833331108,
      "lon": -62.8833312988,
      "tz": "America\/Caracas"
  },
  "SVTF": {
      "icao": "SVTF",
      "iata": "",
      "name": "La Trinidad de Ferro Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 393,
      "lat": 7.4000000954,
      "lon": -67.9499969482,
      "tz": "America\/Caracas"
  },
  "SVTG": {
      "icao": "SVTG",
      "iata": "",
      "name": "La Tigra Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 360,
      "lat": 7.6333332062,
      "lon": -68.6999969482,
      "tz": "America\/Caracas"
  },
  "SVTJ": {
      "icao": "SVTJ",
      "iata": "",
      "name": "Central Matilde Airport",
      "city": "central Matilde",
      "state": "Yaracuy",
      "country": "VE",
      "elevation": 866,
      "lat": 10.1578998566,
      "lon": -68.8616027832,
      "tz": "America\/Caracas"
  },
  "SVTK": {
      "icao": "SVTK",
      "iata": "",
      "name": "La Trinidad de Orichuna Airport",
      "city": "L Trinidad de Orichuna",
      "state": "Apure",
      "country": "VE",
      "elevation": 328,
      "lat": 7.1383328438,
      "lon": -69.7750015259,
      "tz": "America\/Caracas"
  },
  "SVTL": {
      "icao": "SVTL",
      "iata": "",
      "name": "La Centella Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 839,
      "lat": 6.9416670799,
      "lon": -65.8916702271,
      "tz": "America\/Caracas"
  },
  "SVTM": {
      "icao": "SVTM",
      "iata": "TMO",
      "name": "Tumeremo Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 345,
      "lat": 7.25,
      "lon": -61.4333343506,
      "tz": "America\/Caracas"
  },
  "SVTN": {
      "icao": "SVTN",
      "iata": "",
      "name": "La Trinidad de Barinas Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 150,
      "lat": 8.1333332062,
      "lon": -68.6666641235,
      "tz": "America\/Caracas"
  },
  "SVTO": {
      "icao": "SVTO",
      "iata": "",
      "name": "El Toco Airport",
      "city": "El Toco",
      "state": "Anzoategui",
      "country": "VE",
      "elevation": 392,
      "lat": 9.1999998093,
      "lon": -64.8499984741,
      "tz": "America\/Caracas"
  },
  "SVTP": {
      "icao": "SVTP",
      "iata": "",
      "name": "Playa Pintada Airport",
      "city": "",
      "state": "",
      "country": "VE",
      "elevation": 9,
      "lat": 10.1333332062,
      "lon": -65.4416656494,
      "tz": "America\/Caracas"
  },
  "SVTR": {
      "icao": "SVTR",
      "iata": "",
      "name": "Temblador Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 104,
      "lat": 9.016667366,
      "lon": -62.7333335876,
      "tz": "America\/Caracas"
  },
  "SVTT": {
      "icao": "SVTT",
      "iata": "",
      "name": "La Torta Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 672,
      "lat": 9.5112600327,
      "lon": -66.5539016724,
      "tz": "America\/Caracas"
  },
  "SVTU": {
      "icao": "SVTU",
      "iata": "",
      "name": "Turagua Airport",
      "city": "Turagua",
      "state": "Apure",
      "country": "VE",
      "elevation": 338,
      "lat": 7.7833328247,
      "lon": -69.2333297729,
      "tz": "America\/Caracas"
  },
  "SVTV": {
      "icao": "SVTV",
      "iata": "",
      "name": "Turen Airport",
      "city": "",
      "state": "Portuguesa",
      "country": "VE",
      "elevation": 521,
      "lat": 9.25,
      "lon": -69.1333312988,
      "tz": "America\/Caracas"
  },
  "SVTY": {
      "icao": "SVTY",
      "iata": "",
      "name": "Toromacho Airport",
      "city": "Las Mercedes del Llano",
      "state": "Guarico",
      "country": "VE",
      "elevation": 450,
      "lat": 9.130825803,
      "lon": -66.3911533356,
      "tz": "America\/Caracas"
  },
  "SVUA": {
      "icao": "SVUA",
      "iata": "",
      "name": "Mayupa Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1672,
      "lat": 6.1500000954,
      "lon": -62.7000007629,
      "tz": "America\/Caracas"
  },
  "SVUK": {
      "icao": "SVUK",
      "iata": "",
      "name": "San Ignacio Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 426,
      "lat": 9.4499998093,
      "lon": -68.3666687012,
      "tz": "America\/Caracas"
  },
  "SVUM": {
      "icao": "SVUM",
      "iata": "URM",
      "name": "Uriman Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1148,
      "lat": 5.3333330154,
      "lon": -62.7666664124,
      "tz": "America\/Caracas"
  },
  "SVUP": {
      "icao": "SVUP",
      "iata": "",
      "name": "Upata Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1056,
      "lat": 7.9799399376,
      "lon": -62.3227996826,
      "tz": "America\/Caracas"
  },
  "SVUQ": {
      "icao": "SVUQ",
      "iata": "",
      "name": "Uon Quen Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 2820,
      "lat": 4.9833331108,
      "lon": -61.7283325195,
      "tz": "America\/Caracas"
  },
  "SVUY": {
      "icao": "SVUY",
      "iata": "",
      "name": "Uruyen Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 1476,
      "lat": 5.6800498962,
      "lon": -62.4571990967,
      "tz": "America\/Caracas"
  },
  "SVVA": {
      "icao": "SVVA",
      "iata": "VLN",
      "name": "Arturo Michelena International Airport",
      "city": "Valencia",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 1411,
      "lat": 10.1497325897,
      "lon": -67.9283981323,
      "tz": "America\/Caracas"
  },
  "SVVB": {
      "icao": "SVVB",
      "iata": "",
      "name": "Bajo Verde Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 492,
      "lat": 9.0513887405,
      "lon": -66.4674987793,
      "tz": "America\/Caracas"
  },
  "SVVD": {
      "icao": "SVVD",
      "iata": "",
      "name": "Valle Grande Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 631,
      "lat": 8.266667366,
      "lon": -67.1666641235,
      "tz": "America\/Caracas"
  },
  "SVVG": {
      "icao": "SVVG",
      "iata": "VIG",
      "name": "Juan Pablo Perez Alfonso Airport",
      "city": "El Vigia",
      "state": "Merida",
      "country": "VE",
      "elevation": 250,
      "lat": 8.6241388321,
      "lon": -71.672668457,
      "tz": "America\/Caracas"
  },
  "SVVH": {
      "icao": "SVVH",
      "iata": "",
      "name": "Valle Hondo Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 301,
      "lat": 9.3666667938,
      "lon": -62.9166679382,
      "tz": "America\/Caracas"
  },
  "SVVK": {
      "icao": "SVVK",
      "iata": "",
      "name": "La Verdad Airport",
      "city": "Las Queseritas",
      "state": "Apure",
      "country": "VE",
      "elevation": 236,
      "lat": 7.1333332062,
      "lon": -69.0500030518,
      "tz": "America\/Caracas"
  },
  "SVVL": {
      "icao": "SVVL",
      "iata": "VLV",
      "name": "Dr. Antonio Nicolas Briceno Airport",
      "city": "Valera",
      "state": "Trujillo",
      "country": "VE",
      "elevation": 2060,
      "lat": 9.3404779434,
      "lon": -70.5840606689,
      "tz": "America\/Caracas"
  },
  "SVVM": {
      "icao": "SVVM",
      "iata": "",
      "name": "Buena Vista del Cano Medio Airport",
      "city": "",
      "state": "Cojedes",
      "country": "VE",
      "elevation": 475,
      "lat": 6.9166669846,
      "lon": -68.1500015259,
      "tz": "America\/Caracas"
  },
  "SVVP": {
      "icao": "SVVP",
      "iata": "VDP",
      "name": "Valle de La Pascua Airport",
      "city": "",
      "state": "Guarico",
      "country": "VE",
      "elevation": 410,
      "lat": 9.2220277786,
      "lon": -65.9935836792,
      "tz": "America\/Caracas"
  },
  "SVVQ": {
      "icao": "SVVQ",
      "iata": "",
      "name": "Venelac  Airport",
      "city": "Quebrada Arriba",
      "state": "Lara",
      "country": "VE",
      "elevation": 1969,
      "lat": 10.2427997589,
      "lon": -70.5018005371,
      "tz": "America\/Caracas"
  },
  "SVVR": {
      "icao": "SVVR",
      "iata": "",
      "name": "Hato La Vergarena Airport",
      "city": "",
      "state": "Monagas",
      "country": "VE",
      "elevation": 1082,
      "lat": 6.8333330154,
      "lon": -63.5833320618,
      "tz": "America\/Caracas"
  },
  "SVVS": {
      "icao": "SVVS",
      "iata": "",
      "name": "Villa del Rosario Airport",
      "city": "",
      "state": "Zulia",
      "country": "VE",
      "elevation": 259,
      "lat": 10.3166666031,
      "lon": -72.3000030518,
      "tz": "America\/Caracas"
  },
  "SVVV": {
      "icao": "SVVV",
      "iata": "",
      "name": "Venepal Airport",
      "city": "",
      "state": "Carabobo",
      "country": "VE",
      "elevation": 59,
      "lat": 10.5402002335,
      "lon": -68.2453994751,
      "tz": "America\/Caracas"
  },
  "SVVX": {
      "icao": "SVVX",
      "iata": "",
      "name": "Los Venados Airport",
      "city": "",
      "state": "Bolivar",
      "country": "VE",
      "elevation": 621,
      "lat": 6.5616669655,
      "lon": -66.001663208,
      "tz": "America\/Caracas"
  },
  "SVWA": {
      "icao": "SVWA",
      "iata": "",
      "name": "Mata de Agua Airport",
      "city": "Santa Barbara",
      "state": "Apure",
      "country": "VE",
      "elevation": 226,
      "lat": 7.3166670799,
      "lon": -67.9083328247,
      "tz": "America\/Caracas"
  },
  "SVWB": {
      "icao": "SVWB",
      "iata": "",
      "name": "La Bananera Airport",
      "city": "",
      "state": "",
      "country": "VE",
      "elevation": 49,
      "lat": 10.4794998169,
      "lon": -68.4728012085,
      "tz": "America\/Caracas"
  },
  "SVWE": {
      "icao": "SVWE",
      "iata": "",
      "name": "Bella Vista Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 266,
      "lat": 8.0116672516,
      "lon": -69.8316650391,
      "tz": "America\/Caracas"
  },
  "SVXF": {
      "icao": "SVXF",
      "iata": "",
      "name": "Hato Santa Clara Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 393,
      "lat": 7.1500000954,
      "lon": -69.25,
      "tz": "America\/Caracas"
  },
  "SVYA": {
      "icao": "SVYA",
      "iata": "",
      "name": "Yaure Airport",
      "city": "",
      "state": "Barinas",
      "country": "VE",
      "elevation": 482,
      "lat": 7.7166671753,
      "lon": -71.2333297729,
      "tz": "America\/Caracas"
  },
  "SVYG": {
      "icao": "SVYG",
      "iata": "",
      "name": "El Yagual Airport",
      "city": "",
      "state": "",
      "country": "VE",
      "elevation": 262,
      "lat": 7.4666671753,
      "lon": -68.4499969482,
      "tz": "America\/Caracas"
  },
  "SVYI": {
      "icao": "SVYI",
      "iata": "",
      "name": "El Yavi Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 557,
      "lat": 5.4583330154,
      "lon": -65.8983306885,
      "tz": "America\/Caracas"
  },
  "SVYP": {
      "icao": "SVYP",
      "iata": "",
      "name": "Yopita Airport",
      "city": "",
      "state": "Apure",
      "country": "VE",
      "elevation": 360,
      "lat": 7.1999998093,
      "lon": -69.3166656494,
      "tz": "America\/Caracas"
  },
  "SVYT": {
      "icao": "SVYT",
      "iata": "",
      "name": "Yutaje Airport",
      "city": "",
      "state": "Amazonas",
      "country": "VE",
      "elevation": 600,
      "lat": 5.6097798347,
      "lon": -66.1103973389,
      "tz": "America\/Caracas"
  },
  "SWAA": {
      "icao": "SWAA",
      "iata": "",
      "name": "Fazenda Ykarai Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 896,
      "lat": -14.5438890457,
      "lon": -50.5927772522,
      "tz": "America\/Sao_Paulo"
  },
  "SWAE": {
      "icao": "SWAE",
      "iata": "",
      "name": "Uaicas Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1083,
      "lat": 3.5450000763,
      "lon": -63.1822013855,
      "tz": "America\/Boa_Vista"
  },
  "SWAF": {
      "icao": "SWAF",
      "iata": "",
      "name": "Agroer Aviacao Agricola Airport",
      "city": "Rondonopolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 827,
      "lat": -16.4838886261,
      "lon": -54.6672210693,
      "tz": "America\/Cuiaba"
  },
  "SWAH": {
      "icao": "SWAH",
      "iata": "",
      "name": "Agropecuaria Floresta Sao Joaquim Ltda Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 325,
      "lat": -10.141667366,
      "lon": -60.6527786255,
      "tz": "America\/Cuiaba"
  },
  "SWAK": {
      "icao": "SWAK",
      "iata": "",
      "name": "Assuncao do Icana Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 492,
      "lat": 1.0663889647,
      "lon": -67.6005554199,
      "tz": "America\/Manaus"
  },
  "SWAN": {
      "icao": "SWAN",
      "iata": "",
      "name": "Arenapolis Airport",
      "city": "Arenapolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1329,
      "lat": -14.5002784729,
      "lon": -57.0172233582,
      "tz": "America\/Cuiaba"
  },
  "SWAO": {
      "icao": "SWAO",
      "iata": "",
      "name": "Anamoim Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 335,
      "lat": 1.7997200489,
      "lon": -67.3839035034,
      "tz": "America\/Manaus"
  },
  "SWAP": {
      "icao": "SWAP",
      "iata": "",
      "name": "Fazenda Apemag Airport",
      "city": "Barra Do Bugres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 951,
      "lat": -15.3336105347,
      "lon": -57.3172225952,
      "tz": "America\/Cuiaba"
  },
  "SWAQ": {
      "icao": "SWAQ",
      "iata": "",
      "name": "Erico Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 689,
      "lat": 3.7633299828,
      "lon": -62.4094009399,
      "tz": "America\/Boa_Vista"
  },
  "SWAR": {
      "icao": "SWAR",
      "iata": "",
      "name": "Fazenda Aruana Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 968,
      "lat": -13.082777977,
      "lon": -51.5863876343,
      "tz": "America\/Cuiaba"
  },
  "SWAT": {
      "icao": "SWAT",
      "iata": "",
      "name": "Fazenda Cachoeira Airport",
      "city": "Alto Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2795,
      "lat": -17.0891666412,
      "lon": -53.3316688538,
      "tz": "America\/Cuiaba"
  },
  "SWAU": {
      "icao": "SWAU",
      "iata": "",
      "name": "Fazenda Nossa Senhora Aparecida Airport",
      "city": "Pirajuba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2592,
      "lat": -19.95,
      "lon": -48.58,
      "tz": "America\/Sao_Paulo"
  },
  "SWAV": {
      "icao": "SWAV",
      "iata": "",
      "name": "Fazenda Agua Verde Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2037,
      "lat": -14.0727777481,
      "lon": -57.4547233582,
      "tz": "America\/Cuiaba"
  },
  "SWAY": {
      "icao": "SWAY",
      "iata": "0",
      "name": "Araguaiana Airport",
      "city": "Araguaiana",
      "state": "Goias",
      "country": "BR",
      "elevation": 994,
      "lat": -15.7238998413,
      "lon": -51.8363990784,
      "tz": "America\/Cuiaba"
  },
  "SWAZ": {
      "icao": "SWAZ",
      "iata": "",
      "name": "Santo Atanazio Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 394,
      "lat": 0.3997220099,
      "lon": -69.3005523682,
      "tz": "America\/Manaus"
  },
  "SWBA": {
      "icao": "SWBA",
      "iata": "0",
      "name": "Buriti Alegre Airport",
      "city": "Buriti Alegre",
      "state": "Goias",
      "country": "BR",
      "elevation": 2848,
      "lat": -18.1256008148,
      "lon": -49.0428009033,
      "tz": "America\/Sao_Paulo"
  },
  "SWBB": {
      "icao": "SWBB",
      "iata": "",
      "name": "Barra do Bugres Airport",
      "city": "Barra Do Bugres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 722,
      "lat": -15.0586109161,
      "lon": -57.1830558777,
      "tz": "America\/Cuiaba"
  },
  "SWBC": {
      "icao": "SWBC",
      "iata": "BAZ",
      "name": "Barcelos Airport",
      "city": "Barcelos",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 112,
      "lat": -0.9812920094,
      "lon": -62.9196014404,
      "tz": "America\/Manaus"
  },
  "SWBF": {
      "icao": "SWBF",
      "iata": "",
      "name": "Fazenda Boa Fortuna Airport",
      "city": "Pium",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 741,
      "lat": -10.0050001144,
      "lon": -49.4919433594,
      "tz": "America\/Araguaina"
  },
  "SWBG": {
      "icao": "SWBG",
      "iata": "",
      "name": "Uniao do Vale Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 810,
      "lat": -15.1999998093,
      "lon": -59.38722229,
      "tz": "America\/Cuiaba"
  },
  "SWBH": {
      "icao": "SWBH",
      "iata": "",
      "name": "Brejinho de Nazare Airport",
      "city": "Brejinho De Nazare",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 869,
      "lat": -11.0169439316,
      "lon": -48.6005554199,
      "tz": "America\/Araguaina"
  },
  "SWBI": {
      "icao": "SWBI",
      "iata": "",
      "name": "Barreirinha Airport",
      "city": "Barreirinha",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 69,
      "lat": -2.7924199104,
      "lon": -57.0578994751,
      "tz": "America\/Manaus"
  },
  "SWBJ": {
      "icao": "SWBJ",
      "iata": "",
      "name": "Fazenda Itarema Airport",
      "city": "Canarana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1339,
      "lat": -13.2550001144,
      "lon": -52.0400009155,
      "tz": "America\/Cuiaba"
  },
  "SWBL": {
      "icao": "SWBL",
      "iata": "",
      "name": "Balbina Airport",
      "city": "Presidente Figueiredo",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 564,
      "lat": -1.9248100519,
      "lon": -59.4123001099,
      "tz": "America\/Manaus"
  },
  "SWBN": {
      "icao": "SWBN",
      "iata": "",
      "name": "Fazenda Barreirinhos Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 377,
      "lat": -16.9463882446,
      "lon": -56.3927764893,
      "tz": "America\/Cuiaba"
  },
  "SWBO": {
      "icao": "SWBO",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Campos Verdes",
      "state": "Goias",
      "country": "BR",
      "elevation": 1542,
      "lat": -14.2747220993,
      "lon": -49.6491661072,
      "tz": "America\/Sao_Paulo"
  },
  "SWBQ": {
      "icao": "SWBQ",
      "iata": "",
      "name": "Barracao Queimado Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2067,
      "lat": -13.7336111069,
      "lon": -59.7172203064,
      "tz": "America\/Cuiaba"
  },
  "SWBR": {
      "icao": "SWBR",
      "iata": "RBB",
      "name": "Borba Airport",
      "city": "Borba",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 293,
      "lat": -4.4063401222,
      "lon": -59.6024017334,
      "tz": "America\/Manaus"
  },
  "SWBT": {
      "icao": "SWBT",
      "iata": "",
      "name": "Fazenda Beira Rio Airport",
      "city": "Nova Canaa Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 820,
      "lat": -10.3711109161,
      "lon": -55.818611145,
      "tz": "America\/Cuiaba"
  },
  "SWBU": {
      "icao": "SWBU",
      "iata": "",
      "name": "Fazenda Fartura Baixao Novo Airport",
      "city": "Peixoto De Azevedo",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1214,
      "lat": -10.2155561447,
      "lon": -55.0830574036,
      "tz": "America\/Cuiaba"
  },
  "SWBV": {
      "icao": "SWBV",
      "iata": "",
      "name": "Auaris Airport",
      "city": "Boa Vista",
      "state": "Bolivar",
      "country": "BR",
      "elevation": 2336,
      "lat": 4.016389,
      "lon": -64.518611,
      "tz": "America\/Boa_Vista"
  },
  "SWBW": {
      "icao": "SWBW",
      "iata": "",
      "name": "Agro Pousadas do Guapore Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 886,
      "lat": -14.7683334351,
      "lon": -60.0344429016,
      "tz": "America\/Cuiaba"
  },
  "SWBX": {
      "icao": "SWBX",
      "iata": "",
      "name": "Fazenda Santo Antonio do Paraiso Airport",
      "city": "Itiquira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 650,
      "lat": -17.4911117554,
      "lon": -55.2283325195,
      "tz": "America\/Cuiaba"
  },
  "SWBY": {
      "icao": "SWBY",
      "iata": "",
      "name": "Fazenda Goiasa Airport",
      "city": "Bom Jesus De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1820,
      "lat": -18.0505561829,
      "lon": -49.7005577087,
      "tz": "America\/Sao_Paulo"
  },
  "SWBZ": {
      "icao": "SWBZ",
      "iata": "",
      "name": "Jpo Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1155,
      "lat": -12.6052780151,
      "lon": -55.7647209167,
      "tz": "America\/Cuiaba"
  },
  "SWCA": {
      "icao": "SWCA",
      "iata": "CAF",
      "name": "Carauari Airport",
      "city": "Carauari",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 355,
      "lat": -4.8715200424,
      "lon": -66.8974990845,
      "tz": "America\/Manaus"
  },
  "SWCB": {
      "icao": "SWCB",
      "iata": "",
      "name": "Campos Belos Airport",
      "city": "Campos Belos",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 2198,
      "lat": -13.0088891983,
      "lon": -46.774723053,
      "tz": "America\/Sao_Paulo"
  },
  "SWCC": {
      "icao": "SWCC",
      "iata": "",
      "name": "Fazenda Curitiba Airport",
      "city": "Comodoro",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 705,
      "lat": -13.6222,
      "lon": -60.60139,
      "tz": "America\/Cuiaba"
  },
  "SWCD": {
      "icao": "SWCD",
      "iata": "",
      "name": "Fazenda Santa Monica Airport",
      "city": "Alexania",
      "state": "Goias",
      "country": "BR",
      "elevation": 3888,
      "lat": -15.9352998734,
      "lon": -48.6465988159,
      "tz": "America\/Sao_Paulo"
  },
  "SWCE": {
      "icao": "SWCE",
      "iata": "",
      "name": "Fazenda Cedro Airport",
      "city": "Santo Antonio Do Leverger",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2310,
      "lat": -16.2042999268,
      "lon": -55.3987007141,
      "tz": "America\/Cuiaba"
  },
  "SWCF": {
      "icao": "SWCF",
      "iata": "",
      "name": "Fazenda Confap Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 673,
      "lat": -13.7302780151,
      "lon": -60.4786109924,
      "tz": "America\/Cuiaba"
  },
  "SWCG": {
      "icao": "SWCG",
      "iata": "",
      "name": "Fazenda Varjao Grande Airport",
      "city": "Cacu",
      "state": "Goias",
      "country": "BR",
      "elevation": 2060,
      "lat": -18.4915008545,
      "lon": -51.2988014221,
      "tz": "America\/Sao_Paulo"
  },
  "SWCH": {
      "icao": "SWCH",
      "iata": "",
      "name": "Fazenda Charqueada Norte Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 820,
      "lat": -10.0002784729,
      "lon": -57.0672225952,
      "tz": "America\/Cuiaba"
  },
  "SWCJ": {
      "icao": "SWCJ",
      "iata": "",
      "name": "Coperjava Airport",
      "city": "Formoso Do Araguaia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 699,
      "lat": -11.8169441223,
      "lon": -49.7505569458,
      "tz": "America\/Araguaina"
  },
  "SWCK": {
      "icao": "SWCK",
      "iata": "",
      "name": "Fazenda Cocal Airport",
      "city": "Canarana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -12.6836109161,
      "lon": -52.7172203064,
      "tz": "America\/Cuiaba"
  },
  "SWCL": {
      "icao": "SWCL",
      "iata": "",
      "name": "Chacara Santa Eulalia Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 669,
      "lat": -9.546667099,
      "lon": -57.4158325195,
      "tz": "America\/Cuiaba"
  },
  "SWCM": {
      "icao": "SWCM",
      "iata": "",
      "name": "Fazenda Couto Magalhaes Airport",
      "city": "Agua Boa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1378,
      "lat": -13.990278244,
      "lon": -52.9269447327,
      "tz": "America\/Cuiaba"
  },
  "SWCN": {
      "icao": "SWCN",
      "iata": "",
      "name": "Fazenda Saudade Airport",
      "city": "Cocalinho",
      "state": "Goias",
      "country": "BR",
      "elevation": 784,
      "lat": -14.1433334351,
      "lon": -51.0149993896,
      "tz": "America\/Cuiaba"
  },
  "SWCO": {
      "icao": "SWCO",
      "iata": "",
      "name": "Fazenda Continental Airport",
      "city": "Cocalinho",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 919,
      "lat": -14.7155561447,
      "lon": -51.2097206116,
      "tz": "America\/Cuiaba"
  },
  "SWCP": {
      "icao": "SWCP",
      "iata": "",
      "name": "Campo Novo do Parecis Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1818,
      "lat": -13.628610611,
      "lon": -57.9263877869,
      "tz": "America\/Cuiaba"
  },
  "SWCQ": {
      "icao": "SWCQ",
      "iata": "CQS",
      "name": "Costa Marques Airport",
      "city": "Costa Marques",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 555,
      "lat": -12.4210996628,
      "lon": -64.2516021729,
      "tz": "America\/Porto_Velho"
  },
  "SWCR": {
      "icao": "SWCR",
      "iata": "",
      "name": "Porto Caracara Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 328,
      "lat": -17.8338890076,
      "lon": -57.1672210693,
      "tz": "America\/Campo_Grande"
  },
  "SWCT": {
      "icao": "SWCT",
      "iata": "",
      "name": "Complexo Turistico S. R. Pantanal Hoteis LTDA Airport",
      "city": "Pocone",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 334,
      "lat": -17.3680553436,
      "lon": -56.7847213745,
      "tz": "America\/Campo_Grande"
  },
  "SWCU": {
      "icao": "SWCU",
      "iata": "",
      "name": "Fazenda Canuana Airport",
      "city": "Formoso Do Araguaia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 787,
      "lat": -11.9774999619,
      "lon": -49.9080543518,
      "tz": "America\/Araguaina"
  },
  "SWCV": {
      "icao": "SWCV",
      "iata": "",
      "name": "Casalvasco Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 676,
      "lat": -15.489443779,
      "lon": -60.0855560303,
      "tz": "America\/Cuiaba"
  },
  "SWCW": {
      "icao": "SWCW",
      "iata": "0",
      "name": "Cavalcante Airport",
      "city": "Cavalcante",
      "state": "Goias",
      "country": "BR",
      "elevation": 2625,
      "lat": -13.7660999298,
      "lon": -47.4286003113,
      "tz": "America\/Sao_Paulo"
  },
  "SWCX": {
      "icao": "SWCX",
      "iata": "",
      "name": "Cabixi Airport",
      "city": "Cabixi",
      "state": "",
      "country": "BR",
      "elevation": 1247,
      "lat": -13.7002782822,
      "lon": -60.7005577087,
      "tz": "America\/Cuiaba"
  },
  "SWCY": {
      "icao": "SWCY",
      "iata": "",
      "name": "Fazenda Aparecida do Norte Airport",
      "city": "Nova Maringa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1004,
      "lat": -12.8447217941,
      "lon": -56.9769439697,
      "tz": "America\/Cuiaba"
  },
  "SWCZ": {
      "icao": "SWCZ",
      "iata": "0",
      "name": "Ceres Airport",
      "city": "Ceres",
      "state": "Goias",
      "country": "BR",
      "elevation": 1962,
      "lat": -15.3446998596,
      "lon": -49.6046981812,
      "tz": "America\/Sao_Paulo"
  },
  "SWDA": {
      "icao": "SWDA",
      "iata": "",
      "name": "Fazenda Duas Ancoras - Secao Sede Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 925,
      "lat": -14.9358329773,
      "lon": -52.1730575562,
      "tz": "America\/Cuiaba"
  },
  "SWDB": {
      "icao": "SWDB",
      "iata": "",
      "name": "Fazenda Eldorado Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1086,
      "lat": -15.5769443512,
      "lon": -52.2188873291,
      "tz": "America\/Cuiaba"
  },
  "SWDC": {
      "icao": "SWDC",
      "iata": "",
      "name": "Fazenda Arco-Iris Airport",
      "city": "Alto Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2516,
      "lat": -16.9383335114,
      "lon": -53.5783348083,
      "tz": "America\/Cuiaba"
  },
  "SWDE": {
      "icao": "SWDE",
      "iata": "",
      "name": "Fazenda Piratininga Airport",
      "city": "Sao Miguel Do Araguaia",
      "state": "Goias",
      "country": "BR",
      "elevation": 722,
      "lat": -12.8219442368,
      "lon": -50.3322219849,
      "tz": "America\/Sao_Paulo"
  },
  "SWDF": {
      "icao": "SWDF",
      "iata": "",
      "name": "Fazenda Braco Forte Airport",
      "city": "Novo Mundo",
      "state": "Para",
      "country": "BR",
      "elevation": 1417,
      "lat": -9.5249996185,
      "lon": -55.0422210693,
      "tz": "America\/Cuiaba"
  },
  "SWDG": {
      "icao": "SWDG",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Reginopolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1509,
      "lat": -21.744444,
      "lon": -49.164722,
      "tz": "America\/Sao_Paulo"
  },
  "SWDI": {
      "icao": "SWDI",
      "iata": "",
      "name": "Dois Irmaos Airport",
      "city": "Peixe",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 771,
      "lat": -11.7336111069,
      "lon": -49.3338890076,
      "tz": "America\/Araguaina"
  },
  "SWDJ": {
      "icao": "SWDJ",
      "iata": "",
      "name": "Fazenda Duas Ancoras - Secao JAO Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 886,
      "lat": -14.8575000763,
      "lon": -52.0366668701,
      "tz": "America\/Cuiaba"
  },
  "SWDK": {
      "icao": "SWDK",
      "iata": "",
      "name": "Fazenda Dona Olga Airport",
      "city": "Colorado D`Oeste",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 680,
      "lat": -13.0197219849,
      "lon": -61.1686096191,
      "tz": "America\/Porto_Velho"
  },
  "SWDM": {
      "icao": "SWDM",
      "iata": "DMT",
      "name": "Diamantino Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1476,
      "lat": -14.3768997192,
      "lon": -56.4003982544,
      "tz": "America\/Cuiaba"
  },
  "SWDN": {
      "icao": "SWDN",
      "iata": "DNO",
      "name": "Dianopolis Airport",
      "city": "Dianopolis",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 2001,
      "lat": -11.5953998566,
      "lon": -46.846698761,
      "tz": "America\/Araguaina"
  },
  "SWDO": {
      "icao": "SWDO",
      "iata": "",
      "name": "Rosario do Leste Airport",
      "city": "Rosario Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 699,
      "lat": -14.8169441223,
      "lon": -56.4172210693,
      "tz": "America\/Cuiaba"
  },
  "SWDQ": {
      "icao": "SWDQ",
      "iata": "",
      "name": "Fazenda Saltos do Poente Airport",
      "city": "Santo Antonio Do Leverger",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1352,
      "lat": -16.4358329773,
      "lon": -55.2711105347,
      "tz": "America\/Cuiaba"
  },
  "SWDS": {
      "icao": "SWDS",
      "iata": "",
      "name": "Fazenda Favo de Mel Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 846,
      "lat": -15.6608333588,
      "lon": -59.305557251,
      "tz": "America\/Cuiaba"
  },
  "SWDT": {
      "icao": "SWDT",
      "iata": "",
      "name": "Fazenda Pioneira Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2178,
      "lat": -14.2836112976,
      "lon": -57.7005577087,
      "tz": "America\/Cuiaba"
  },
  "SWDU": {
      "icao": "SWDU",
      "iata": "",
      "name": "Diauarum Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 820,
      "lat": -11.0336112976,
      "lon": -53.1172218323,
      "tz": "America\/Cuiaba"
  },
  "SWDV": {
      "icao": "SWDV",
      "iata": "",
      "name": "Descalvados Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 361,
      "lat": -16.7347221375,
      "lon": -57.7638893127,
      "tz": "America\/Cuiaba"
  },
  "SWDW": {
      "icao": "SWDW",
      "iata": "",
      "name": "Divisao Airport",
      "city": "Diamantino",
      "state": "",
      "country": "BR",
      "elevation": 1345,
      "lat": -13.116944313,
      "lon": -56.1672210693,
      "tz": "America\/Cuiaba"
  },
  "SWDY": {
      "icao": "SWDY",
      "iata": "",
      "name": "Fazenda Agua Fria Airport",
      "city": "Nova Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1175,
      "lat": -14.7547216415,
      "lon": -50.3774986267,
      "tz": "America\/Sao_Paulo"
  },
  "SWDZ": {
      "icao": "SWDZ",
      "iata": "",
      "name": "Fazenda Agua Limpa Airport",
      "city": "Indiavai",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1181,
      "lat": -15.2894439697,
      "lon": -58.5302772522,
      "tz": "America\/Cuiaba"
  },
  "SWEB": {
      "icao": "SWEB",
      "iata": "",
      "name": "Fazenda Arauna Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 951,
      "lat": -11.5675001144,
      "lon": -58.0261116028,
      "tz": "America\/Cuiaba"
  },
  "SWEC": {
      "icao": "SWEC",
      "iata": "ARS",
      "name": "Estancia das Cascatas Airport",
      "city": "Aragarcas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1017,
      "lat": -15.8966999054,
      "lon": -52.0956001282,
      "tz": "America\/Cuiaba"
  },
  "SWED": {
      "icao": "SWED",
      "iata": "",
      "name": "Fazenda Eldorado Airport",
      "city": "Arapoema",
      "state": "",
      "country": "BR",
      "elevation": 686,
      "lat": -7.7572221756,
      "lon": -49.1216659546,
      "tz": "America\/Araguaina"
  },
  "SWEE": {
      "icao": "SWEE",
      "iata": "",
      "name": "Estirao do Equador Airport",
      "city": "Atalaia Do Norte",
      "state": "Loreto",
      "country": "BR",
      "elevation": 98,
      "lat": -4.5237798691,
      "lon": -71.560798645,
      "tz": "America\/Eirunepe"
  },
  "SWEF": {
      "icao": "SWEF",
      "iata": "",
      "name": "Fazenda Europa Airport",
      "city": "Cocalinho",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 899,
      "lat": -14.80166667,
      "lon": -51.34,
      "tz": "America\/Cuiaba"
  },
  "SWEG": {
      "icao": "SWEG",
      "iata": "",
      "name": "Fazenda Duas Marias Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 446,
      "lat": -16.8333320618,
      "lon": -55.6691665649,
      "tz": "America\/Cuiaba"
  },
  "SWEI": {
      "icao": "SWEI",
      "iata": "ERN",
      "name": "Eirunepe Airport",
      "city": "Eirunepe",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 412,
      "lat": -6.6395301819,
      "lon": -69.8797988892,
      "tz": "America\/Eirunepe"
  },
  "SWEK": {
      "icao": "SWEK",
      "iata": "CQA",
      "name": "Canarana Airport",
      "city": "Canarana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1314,
      "lat": -13.5744438171,
      "lon": -52.2705574036,
      "tz": "America\/Cuiaba"
  },
  "SWEL": {
      "icao": "SWEL",
      "iata": "",
      "name": "Fazenda Santa Eulalia Airport",
      "city": "Araguaiana",
      "state": "",
      "country": "BR",
      "elevation": 853,
      "lat": -15.0583333969,
      "lon": -51.9166679382,
      "tz": "America\/Cuiaba"
  },
  "SWEM": {
      "icao": "SWEM",
      "iata": "",
      "name": "Emal - Empresa de Mineiracao Aripuana Ltda. Airport",
      "city": "Primavera Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1837,
      "lat": -14.618888855,
      "lon": -54.0033340454,
      "tz": "America\/Cuiaba"
  },
  "SWEN": {
      "icao": "SWEN",
      "iata": "",
      "name": "Estancia Recanto Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1017,
      "lat": -9.9419441223,
      "lon": -56.1005554199,
      "tz": "America\/Cuiaba"
  },
  "SWEO": {
      "icao": "SWEO",
      "iata": "",
      "name": "Itapara Sport Fishing Airport",
      "city": "Rorainopolis",
      "state": "Roraima",
      "country": "BR",
      "elevation": 262,
      "lat": 0.2097219974,
      "lon": -61.5499992371,
      "tz": "America\/Boa_Vista"
  },
  "SWER": {
      "icao": "SWER",
      "iata": "",
      "name": "Estancia Santana Airport",
      "city": "Cuiaba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 801,
      "lat": -15.4697217941,
      "lon": -56.063331604,
      "tz": "America\/Cuiaba"
  },
  "SWET": {
      "icao": "SWET",
      "iata": "",
      "name": "Fazenda Encantado Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 558,
      "lat": -13.3166666031,
      "lon": -58.7983322144,
      "tz": "America\/Cuiaba"
  },
  "SWEU": {
      "icao": "SWEU",
      "iata": "",
      "name": "Ecotur Univini Park Airport",
      "city": "Caracarai",
      "state": "Roraima",
      "country": "BR",
      "elevation": 266,
      "lat": 0.801388979,
      "lon": -61.6605987549,
      "tz": "America\/Boa_Vista"
  },
  "SWEW": {
      "icao": "SWEW",
      "iata": "",
      "name": "Fazenda Felicidade Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 656,
      "lat": -15.8422222137,
      "lon": -58.6316680908,
      "tz": "America\/Cuiaba"
  },
  "SWEX": {
      "icao": "SWEX",
      "iata": "",
      "name": "Fazenda Santa Escolastica Airport",
      "city": "Pedra Preta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1145,
      "lat": -16.8466663361,
      "lon": -54.4588890076,
      "tz": "America\/Cuiaba"
  },
  "SWEY": {
      "icao": "SWEY",
      "iata": "",
      "name": "Fazenda Santa Fe Airport",
      "city": "Santa Tereza De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1197,
      "lat": -13.5349998474,
      "lon": -48.9016685486,
      "tz": "America\/Sao_Paulo"
  },
  "SWEZ": {
      "icao": "SWEZ",
      "iata": "",
      "name": "Fazenda Santa Rita de Cassia Airport",
      "city": "Jangada",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 666,
      "lat": -15.2502784729,
      "lon": -56.4811096191,
      "tz": "America\/Cuiaba"
  },
  "SWFA": {
      "icao": "SWFA",
      "iata": "",
      "name": "Fazenda Pamplona Airport",
      "city": "Cristalina",
      "state": "Goias",
      "country": "BR",
      "elevation": 3281,
      "lat": -16.2505569458,
      "lon": -47.6172218323,
      "tz": "America\/Sao_Paulo"
  },
  "SWFC": {
      "icao": "SWFC",
      "iata": "",
      "name": "Fazenda Colorado Airport",
      "city": "Juscimeira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1191,
      "lat": -16.1672210693,
      "lon": -54.8427772522,
      "tz": "America\/Cuiaba"
  },
  "SWFD": {
      "icao": "SWFD",
      "iata": "",
      "name": "Fazenda Tombador Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 863,
      "lat": -11.7166671753,
      "lon": -58.0480575562,
      "tz": "America\/Cuiaba"
  },
  "SWFE": {
      "icao": "SWFE",
      "iata": "",
      "name": "Fazenda Colen Airport",
      "city": "Lucas Do Rio Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1345,
      "lat": -13.3144435883,
      "lon": -56.11277771,
      "tz": "America\/Cuiaba"
  },
  "SWFF": {
      "icao": "SWFF",
      "iata": "",
      "name": "Fazenda Figueira Branca Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -10.723610878,
      "lon": -54.8383331299,
      "tz": "America\/Cuiaba"
  },
  "SWFG": {
      "icao": "SWFG",
      "iata": "",
      "name": "Fazenda Guanabara Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1116,
      "lat": -12.029999733,
      "lon": -51.6775016785,
      "tz": "America\/Cuiaba"
  },
  "SWFH": {
      "icao": "SWFH",
      "iata": "",
      "name": "Fazenda Manchete Airport",
      "city": "Araguacema",
      "state": "",
      "country": "BR",
      "elevation": 640,
      "lat": -9.7469444275,
      "lon": -49.9808311462,
      "tz": "America\/Araguaina"
  },
  "SWFJ": {
      "icao": "SWFJ",
      "iata": "FEJ",
      "name": "Feijo Airport",
      "city": "Feijo",
      "state": "",
      "country": "BR",
      "elevation": 394,
      "lat": -8.1655797958,
      "lon": -70.3529968262,
      "tz": "America\/Rio_Branco"
  },
  "SWFK": {
      "icao": "SWFK",
      "iata": "",
      "name": "Fazenda Juquei Agropecuaria Airport",
      "city": "Padre Bernardo",
      "state": "Goias",
      "country": "BR",
      "elevation": 2297,
      "lat": -15.07472229,
      "lon": -48.238609314,
      "tz": "America\/Sao_Paulo"
  },
  "SWFL": {
      "icao": "SWFL",
      "iata": "",
      "name": "Fazenda Peixe Bravo Airport",
      "city": "Santa Terezinha",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 656,
      "lat": -10.116944313,
      "lon": -50.4172210693,
      "tz": "America\/Cuiaba"
  },
  "SWFN": {
      "icao": "SWFN",
      "iata": "",
      "name": "Flores Airport",
      "city": "Manaus",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 203,
      "lat": -3.0727779865,
      "lon": -60.0211105347,
      "tz": "America\/Manaus"
  },
  "SWFO": {
      "icao": "SWFO",
      "iata": "",
      "name": "Fazenda Bocaina Airport",
      "city": "Jangada",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1037,
      "lat": -15.3463888168,
      "lon": -56.7111091614,
      "tz": "America\/Cuiaba"
  },
  "SWFP": {
      "icao": "SWFP",
      "iata": "",
      "name": "Fazenda Paraiso Airport",
      "city": "Porto Alegre Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -10.6388893127,
      "lon": -52.0325012207,
      "tz": "America\/Cuiaba"
  },
  "SWFQ": {
      "icao": "SWFQ",
      "iata": "",
      "name": "Fazenda Alvorada Airport",
      "city": "Porto Nacional",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 943,
      "lat": -10.3920001984,
      "lon": -48.6791992188,
      "tz": "America\/Araguaina"
  },
  "SWFR": {
      "icao": "SWFR",
      "iata": "",
      "name": "Formosa Airport",
      "city": "Formosa",
      "state": "Goias",
      "country": "BR",
      "elevation": 3166,
      "lat": -15.555100441,
      "lon": -47.3456993103,
      "tz": "America\/Sao_Paulo"
  },
  "SWFS": {
      "icao": "SWFS",
      "iata": "",
      "name": "Fazenda Sucuri Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1575,
      "lat": -13.5313892365,
      "lon": -54.9338874817,
      "tz": "America\/Cuiaba"
  },
  "SWFT": {
      "icao": "SWFT",
      "iata": "",
      "name": "Fazenda Santa Marcia Airport",
      "city": "Caiaponia",
      "state": "Goias",
      "country": "BR",
      "elevation": 2822,
      "lat": -16.5808334351,
      "lon": -52.1425018311,
      "tz": "America\/Sao_Paulo"
  },
  "SWFU": {
      "icao": "SWFU",
      "iata": "",
      "name": "Fortuna Airport",
      "city": "Fortuna",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 866,
      "lat": -16.1669445038,
      "lon": -59.5005569458,
      "tz": "America\/Cuiaba"
  },
  "SWFW": {
      "icao": "SWFW",
      "iata": "",
      "name": "Fazenda Jangada Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 919,
      "lat": -14.7172222137,
      "lon": -51.9088897705,
      "tz": "America\/Cuiaba"
  },
  "SWFX": {
      "icao": "SWFX",
      "iata": "SXO",
      "name": "Sao Felix do Araguaia Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 650,
      "lat": -11.632399559,
      "lon": -50.6896018982,
      "tz": "America\/Cuiaba"
  },
  "SWFY": {
      "icao": "SWFY",
      "iata": "",
      "name": "Fazenda Ipe Airport",
      "city": "Vila Rica",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 718,
      "lat": -9.9669437408,
      "lon": -50.9505577087,
      "tz": "America\/Cuiaba"
  },
  "SWFZ": {
      "icao": "SWFZ",
      "iata": "",
      "name": "Serafino Ferruzzi Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 820,
      "lat": -9.9755563736,
      "lon": -56.6730575562,
      "tz": "America\/Cuiaba"
  },
  "SWGA": {
      "icao": "SWGA",
      "iata": "",
      "name": "Fazenda Guara Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1378,
      "lat": -14.8225002289,
      "lon": -60.0172233582,
      "tz": "America\/Cuiaba"
  },
  "SWGE": {
      "icao": "SWGE",
      "iata": "",
      "name": "Fazenda Pirigara Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 344,
      "lat": -17.0072212219,
      "lon": -56.4780578613,
      "tz": "America\/Cuiaba"
  },
  "SWGF": {
      "icao": "SWGF",
      "iata": "",
      "name": "Hidreletrica de Jirau Airport",
      "city": "Porto Velho",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 295,
      "lat": -9.288611,
      "lon": -64.623611,
      "tz": "America\/Porto_Velho"
  },
  "SWGH": {
      "icao": "SWGH",
      "iata": "",
      "name": "Fazenda Primavera Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1299,
      "lat": -16.2380561829,
      "lon": -57.6199989319,
      "tz": "America\/Cuiaba"
  },
  "SWGI": {
      "icao": "SWGI",
      "iata": "GRP",
      "name": "Gurupi Airport",
      "city": "Gurupi",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1148,
      "lat": -11.7396001816,
      "lon": -49.1321983337,
      "tz": "America\/Araguaina"
  },
  "SWGK": {
      "icao": "SWGK",
      "iata": "",
      "name": "Fazenda Sete Barras Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 801,
      "lat": -12.5597219467,
      "lon": -51.6886100769,
      "tz": "America\/Cuiaba"
  },
  "SWGL": {
      "icao": "SWGL",
      "iata": "",
      "name": "Goalcool Airport",
      "city": "Serranopolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 2067,
      "lat": -18.3913898468,
      "lon": -52.1044425964,
      "tz": "America\/Sao_Paulo"
  },
  "SWGM": {
      "icao": "SWGM",
      "iata": "",
      "name": "Ninho das Aguias Airport",
      "city": "Cuiaba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2464,
      "lat": -15.4899997711,
      "lon": -55.8147201538,
      "tz": "America\/Cuiaba"
  },
  "SWGN": {
      "icao": "SWGN",
      "iata": "AUX",
      "name": "Araguaina Airport",
      "city": "Araguaina",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 771,
      "lat": -7.2278699875,
      "lon": -48.2405014038,
      "tz": "America\/Araguaina"
  },
  "SWGO": {
      "icao": "SWGO",
      "iata": "",
      "name": "Goianesia Airport",
      "city": "Goianesia",
      "state": "Goias",
      "country": "BR",
      "elevation": 2303,
      "lat": -15.3400001526,
      "lon": -49.1361122131,
      "tz": "America\/Sao_Paulo"
  },
  "SWGP": {
      "icao": "SWGP",
      "iata": "",
      "name": "Palmeiras de Goias Airport",
      "city": "Palmeiras De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1995,
      "lat": -16.8269004822,
      "lon": -49.8788986206,
      "tz": "America\/Sao_Paulo"
  },
  "SWGQ": {
      "icao": "SWGQ",
      "iata": "",
      "name": "Fazenda Monte Alegre Airport",
      "city": "Nova Olimpia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 755,
      "lat": -14.7808332443,
      "lon": -57.3541679382,
      "tz": "America\/Cuiaba"
  },
  "SWGS": {
      "icao": "SWGS",
      "iata": "",
      "name": "Fazenda Divisa Airport",
      "city": "Santana Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 755,
      "lat": -9.7916669846,
      "lon": -50.8199996948,
      "tz": "America\/Belem"
  },
  "SWGT": {
      "icao": "SWGT",
      "iata": "",
      "name": "Fazenda Nova Airport",
      "city": "Jandaia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1844,
      "lat": -17.188056,
      "lon": -50.213333,
      "tz": "America\/Sao_Paulo"
  },
  "SWGU": {
      "icao": "SWGU",
      "iata": "",
      "name": "Fazenda Agua Fria Airport",
      "city": "Sandolandia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 738,
      "lat": -12.398888588,
      "lon": -50.0547218323,
      "tz": "America\/Araguaina"
  },
  "SWGV": {
      "icao": "SWGV",
      "iata": "",
      "name": "Fazenda Cocal Airport",
      "city": "Agua Boa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1004,
      "lat": -12.801943779,
      "lon": -52.6077766418,
      "tz": "America\/Cuiaba"
  },
  "SWGW": {
      "icao": "SWGW",
      "iata": "",
      "name": "Fazenda Santa Rita do Araguaia Airport",
      "city": "Jussara",
      "state": "Goias",
      "country": "BR",
      "elevation": 1047,
      "lat": -15.4377784729,
      "lon": -51.5986099243,
      "tz": "America\/Sao_Paulo"
  },
  "SWGZ": {
      "icao": "SWGZ",
      "iata": "",
      "name": "Fazenda Guanabara Airport",
      "city": "Nova Olimpia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 988,
      "lat": -14.7669448853,
      "lon": -57.2672233582,
      "tz": "America\/Cuiaba"
  },
  "SWHA": {
      "icao": "SWHA",
      "iata": "",
      "name": "Fazenda Boa Esperanca Soberbinho Airport",
      "city": "Guarinos",
      "state": "Goias",
      "country": "BR",
      "elevation": 1553,
      "lat": -14.6447219849,
      "lon": -49.8163871765,
      "tz": "America\/Sao_Paulo"
  },
  "SWHE": {
      "icao": "SWHE",
      "iata": "",
      "name": "Agropecuaria Cajabi Airport",
      "city": "Marvelandia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1352,
      "lat": -10.7344436646,
      "lon": -54.5344429016,
      "tz": "America\/Cuiaba"
  },
  "SWHF": {
      "icao": "SWHF",
      "iata": "",
      "name": "Fazenda Frigovale I Airport",
      "city": "Porto Nacional",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 860,
      "lat": -10.485833168,
      "lon": -48.3338890076,
      "tz": "America\/Araguaina"
  },
  "SWHG": {
      "icao": "SWHG",
      "iata": "",
      "name": "Santa Helena de Goias Airport",
      "city": "Santa Helena De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1591,
      "lat": -17.8838882446,
      "lon": -50.5672225952,
      "tz": "America\/Sao_Paulo"
  },
  "SWHJ": {
      "icao": "SWHJ",
      "iata": "",
      "name": "Fazenda Bela Vista Airport",
      "city": "Nova Monte Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 899,
      "lat": -10.0555562973,
      "lon": -56.9911117554,
      "tz": "America\/Cuiaba"
  },
  "SWHL": {
      "icao": "SWHL",
      "iata": "",
      "name": "Fazenda Maraba Airport",
      "city": "Campo Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2247,
      "lat": -15.2749996185,
      "lon": -55.1216659546,
      "tz": "America\/Cuiaba"
  },
  "SWHN": {
      "icao": "SWHN",
      "iata": "",
      "name": "Fazenda Nossa Senhora de Fatima Airport",
      "city": "Nova Monte Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 853,
      "lat": -10.1563892365,
      "lon": -56.9811096191,
      "tz": "America\/Cuiaba"
  },
  "SWHO": {
      "icao": "SWHO",
      "iata": "",
      "name": "Gleba Oscar Americano I Airport",
      "city": "Porto Dos Gauchos",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1017,
      "lat": -11.5336112976,
      "lon": -56.5505561829,
      "tz": "America\/Cuiaba"
  },
  "SWHP": {
      "icao": "SWHP",
      "iata": "GGB",
      "name": "Fazenda Olhos D`agua Airport",
      "city": "Agua Boa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1407,
      "lat": -14.0194444656,
      "lon": -52.1522216797,
      "tz": "America\/Cuiaba"
  },
  "SWHQ": {
      "icao": "SWHQ",
      "iata": "",
      "name": "Fazenda Santa Cruz Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "",
      "country": "BR",
      "elevation": 1060,
      "lat": -15.1891670227,
      "lon": -59.9391670227,
      "tz": "America\/Cuiaba"
  },
  "SWHS": {
      "icao": "SWHS",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Maurilandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1729,
      "lat": -18.0533332825,
      "lon": -50.3869438171,
      "tz": "America\/Sao_Paulo"
  },
  "SWHT": {
      "icao": "SWHT",
      "iata": "HUW",
      "name": "Humaita Airport",
      "city": "Humaita",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 230,
      "lat": -7.5321202278,
      "lon": -63.072101593,
      "tz": "America\/Manaus"
  },
  "SWHU": {
      "icao": "SWHU",
      "iata": "",
      "name": "Fazenda Izaura - Usina Cocal Airport",
      "city": "Racharia",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1542,
      "lat": -22.506667,
      "lon": -50.865278,
      "tz": "America\/Sao_Paulo"
  },
  "SWHY": {
      "icao": "SWHY",
      "iata": "",
      "name": "Fazenda Esperanca I Airport",
      "city": "Rondon Do Para",
      "state": "Para",
      "country": "BR",
      "elevation": 285,
      "lat": -4.13,
      "lon": -48.69,
      "tz": "America\/Belem"
  },
  "SWHZ": {
      "icao": "SWHZ",
      "iata": "",
      "name": "Fazenda Taquarucu Retiro Airport",
      "city": "Carlinda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1010,
      "lat": -10.3386106491,
      "lon": -55.9655570984,
      "tz": "America\/Cuiaba"
  },
  "SWIA": {
      "icao": "SWIA",
      "iata": "0",
      "name": "Iaciara Airport",
      "city": "Iaciara",
      "state": "Goias",
      "country": "BR",
      "elevation": 1854,
      "lat": -14.1063995361,
      "lon": -46.6693992615,
      "tz": "America\/Sao_Paulo"
  },
  "SWIB": {
      "icao": "SWIB",
      "iata": "",
      "name": "Fazenda Santa Laura Airport",
      "city": "Nova Santa Helena",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1096,
      "lat": -10.86277771,
      "lon": -54.9141654968,
      "tz": "America\/Cuiaba"
  },
  "SWID": {
      "icao": "SWID",
      "iata": "",
      "name": "Fazenda Indiara Airport",
      "city": "Sao Miguel Do Araguaia",
      "state": "Goias",
      "country": "BR",
      "elevation": 909,
      "lat": -13.3149003983,
      "lon": -49.7748985291,
      "tz": "America\/Sao_Paulo"
  },
  "SWIE": {
      "icao": "SWIE",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Mundo Novo",
      "state": "Goias",
      "country": "BR",
      "elevation": 984,
      "lat": -13.9194440842,
      "lon": -50.0099983215,
      "tz": "America\/Sao_Paulo"
  },
  "SWIG": {
      "icao": "SWIG",
      "iata": "",
      "name": "Fazenda Sao Jose Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 879,
      "lat": -15.0108327866,
      "lon": -59.2522201538,
      "tz": "America\/Cuiaba"
  },
  "SWII": {
      "icao": "SWII",
      "iata": "IPG",
      "name": "Ipiranga Airport",
      "city": "Santo Antonio Do Ica",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 131,
      "lat": -2.9390699863,
      "lon": -69.6940002441,
      "tz": "America\/Bogota"
  },
  "SWIJ": {
      "icao": "SWIJ",
      "iata": "",
      "name": "Fazenda Sao Jorge Airport",
      "city": "Itiquira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1683,
      "lat": -17.5180568695,
      "lon": -54.8263893127,
      "tz": "America\/Cuiaba"
  },
  "SWIK": {
      "icao": "SWIK",
      "iata": "",
      "name": "Fazenda Vanguarda Airport",
      "city": "Cocalinho",
      "state": "Goias",
      "country": "BR",
      "elevation": 935,
      "lat": -14.917222023,
      "lon": -51.2838897705,
      "tz": "America\/Cuiaba"
  },
  "SWIL": {
      "icao": "SWIL",
      "iata": "",
      "name": "Fazenda Taruma Airport",
      "city": "Pedra Preta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1499,
      "lat": -17.015832901,
      "lon": -54.3497238159,
      "tz": "America\/Cuiaba"
  },
  "SWIM": {
      "icao": "SWIM",
      "iata": "",
      "name": "Fazenda Tres Ranchos Airport",
      "city": "Montes Claros De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1142,
      "lat": -16.1266670227,
      "lon": -51.6697235107,
      "tz": "America\/Sao_Paulo"
  },
  "SWIN": {
      "icao": "SWIN",
      "iata": "",
      "name": "Fazenda Itamarati Norte Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2188,
      "lat": -14.2430000305,
      "lon": -57.995300293,
      "tz": "America\/Cuiaba"
  },
  "SWIO": {
      "icao": "SWIO",
      "iata": "",
      "name": "Fazenda Triangulo Airport",
      "city": "Edeia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1562,
      "lat": -17.621389389,
      "lon": -50.1416664124,
      "tz": "America\/Sao_Paulo"
  },
  "SWIP": {
      "icao": "SWIP",
      "iata": "",
      "name": "Ipameri Airport",
      "city": "Ipameri",
      "state": "Goias",
      "country": "BR",
      "elevation": 2729,
      "lat": -17.6872215271,
      "lon": -48.161945343,
      "tz": "America\/Sao_Paulo"
  },
  "SWIU": {
      "icao": "SWIU",
      "iata": "",
      "name": "Madeirinha Airport",
      "city": "Aripuana",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 476,
      "lat": -9.8463888168,
      "lon": -61.4174995422,
      "tz": "America\/Cuiaba"
  },
  "SWIV": {
      "icao": "SWIV",
      "iata": "",
      "name": "Aguia Branca do Paru Airport",
      "city": "Almeirim",
      "state": "Para",
      "country": "BR",
      "elevation": 479,
      "lat": -1.268056035,
      "lon": -53.261390686,
      "tz": "America\/Santarem"
  },
  "SWIW": {
      "icao": "SWIW",
      "iata": "",
      "name": "Brigadeiro Eduardo Gomes Airport",
      "city": "Nova Mutum",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1398,
      "lat": -13.817199707,
      "lon": -56.0360984802,
      "tz": "America\/Cuiaba"
  },
  "SWIX": {
      "icao": "SWIX",
      "iata": "",
      "name": "Fazenda Michelin Airport",
      "city": "Itiquira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1640,
      "lat": -17.3741664886,
      "lon": -54.7350006104,
      "tz": "America\/Cuiaba"
  },
  "SWIY": {
      "icao": "SWIY",
      "iata": "IDO",
      "name": "Santa Izabel do Morro Airport",
      "city": "Cristalandia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 647,
      "lat": -11.5722999573,
      "lon": -50.6661987305,
      "tz": "America\/Araguaina"
  },
  "SWJA": {
      "icao": "SWJA",
      "iata": "",
      "name": "Fazenda Juina Airport",
      "city": "Juina",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1100,
      "lat": -11.6427783966,
      "lon": -59.0875015259,
      "tz": "America\/Cuiaba"
  },
  "SWJB": {
      "icao": "SWJB",
      "iata": "",
      "name": "Fazenda Juba Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1667,
      "lat": -14.7449998856,
      "lon": -58.1283340454,
      "tz": "America\/Cuiaba"
  },
  "SWJC": {
      "icao": "SWJC",
      "iata": "",
      "name": "Jaciara Airport",
      "city": "Jaciara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1198,
      "lat": -15.9716672897,
      "lon": -54.9686126709,
      "tz": "America\/Cuiaba"
  },
  "SWJD": {
      "icao": "SWJD",
      "iata": "",
      "name": "Fazenda Genipapo Airport",
      "city": "Sao Domingos",
      "state": "Goias",
      "country": "BR",
      "elevation": 1279,
      "lat": -13.4522218704,
      "lon": -46.6794433594,
      "tz": "America\/Sao_Paulo"
  },
  "SWJE": {
      "icao": "SWJE",
      "iata": "",
      "name": "Fazenda Ibia Airport",
      "city": "Baliza",
      "state": "Goias",
      "country": "BR",
      "elevation": 2634,
      "lat": -16.396944046,
      "lon": -52.3286094666,
      "tz": "America\/Sao_Paulo"
  },
  "SWJF": {
      "icao": "SWJF",
      "iata": "",
      "name": "Fazenda Sao Marcelo Airport",
      "city": "Juruena",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 840,
      "lat": -10.233332634,
      "lon": -58.4944458008,
      "tz": "America\/Cuiaba"
  },
  "SWJG": {
      "icao": "SWJG",
      "iata": "",
      "name": "Fazenda Jaragua Airport",
      "city": "Arenapolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -14.4836111069,
      "lon": -57.4338874817,
      "tz": "America\/Cuiaba"
  },
  "SWJI": {
      "icao": "SWJI",
      "iata": "JPR",
      "name": "Ji-Parana Airport",
      "city": "Ji-Parana",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 598,
      "lat": -10.8708000183,
      "lon": -61.8465003967,
      "tz": "America\/Porto_Velho"
  },
  "SWJJ": {
      "icao": "SWJJ",
      "iata": "",
      "name": "Fazenda Januarinho Airport",
      "city": "Salto Do Ceu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 755,
      "lat": -15.05027771,
      "lon": -57.5005569458,
      "tz": "America\/Cuiaba"
  },
  "SWJK": {
      "icao": "SWJK",
      "iata": "",
      "name": "Fazenda Sao Paulo Airport",
      "city": "Britania",
      "state": "Goias",
      "country": "BR",
      "elevation": 984,
      "lat": -14.9763889313,
      "lon": -51.3355560303,
      "tz": "America\/Cuiaba"
  },
  "SWJL": {
      "icao": "SWJL",
      "iata": "",
      "name": "Fazenda Invernada Airport",
      "city": "Itaja",
      "state": "Goias",
      "country": "BR",
      "elevation": 1345,
      "lat": -19.0411109924,
      "lon": -51.2594451904,
      "tz": "America\/Sao_Paulo"
  },
  "SWJM": {
      "icao": "SWJM",
      "iata": "",
      "name": "Fazenda Bom Jesus Airport",
      "city": "Novo Alegre",
      "state": "Goias",
      "country": "BR",
      "elevation": 2067,
      "lat": -12.9663887024,
      "lon": -46.5652770996,
      "tz": "America\/Araguaina"
  },
  "SWJN": {
      "icao": "SWJN",
      "iata": "JIA",
      "name": "Juina Airport",
      "city": "Juina",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -11.4194440842,
      "lon": -58.7016677856,
      "tz": "America\/Cuiaba"
  },
  "SWJO": {
      "icao": "SWJO",
      "iata": "",
      "name": "Fazenda Sao Paulo - Retiro Santa Maria Airport",
      "city": "Britania",
      "state": "Goias",
      "country": "BR",
      "elevation": 984,
      "lat": -15.1400003433,
      "lon": -51.2544441223,
      "tz": "America\/Sao_Paulo"
  },
  "SWJP": {
      "icao": "SWJP",
      "iata": "",
      "name": "Bittencourt Airport",
      "city": "Japura",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 257,
      "lat": -1.4039299488,
      "lon": -69.4237976074,
      "tz": "America\/Bogota"
  },
  "SWJQ": {
      "icao": "SWJQ",
      "iata": "",
      "name": "Fazenda Colibri Airport",
      "city": "Selviria",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1391,
      "lat": -20.2269439697,
      "lon": -51.8458328247,
      "tz": "America\/Campo_Grande"
  },
  "SWJR": {
      "icao": "SWJR",
      "iata": "",
      "name": "Fazenda Uniao Airport",
      "city": "Santa Carmen",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1180,
      "lat": -11.9488887787,
      "lon": -54.5155563354,
      "tz": "America\/Cuiaba"
  },
  "SWJS": {
      "icao": "SWJS",
      "iata": "",
      "name": "Fazenda Samambaia Airport",
      "city": "Jussara",
      "state": "Goias",
      "country": "BR",
      "elevation": 692,
      "lat": -15.4861106873,
      "lon": -51.269443512,
      "tz": "America\/Sao_Paulo"
  },
  "SWJT": {
      "icao": "SWJT",
      "iata": "",
      "name": "Fazenda Joatao Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1165,
      "lat": -11.4939870834,
      "lon": -52.6180725098,
      "tz": "America\/Cuiaba"
  },
  "SWJU": {
      "icao": "SWJU",
      "iata": "JRN",
      "name": "Juruena Airport",
      "city": "Juruena",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 525,
      "lat": -10.3058328629,
      "lon": -58.4894447327,
      "tz": "America\/Cuiaba"
  },
  "SWJV": {
      "icao": "SWJV",
      "iata": "",
      "name": "Palmeiras do Javari Airport",
      "city": "Atalaia Do Norte",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 394,
      "lat": -5.127240181,
      "lon": -72.8099975586,
      "tz": "America\/Eirunepe"
  },
  "SWJW": {
      "icao": "SWJW",
      "iata": "JTI",
      "name": "Jatai Airport",
      "city": "Jatai",
      "state": "Goias",
      "country": "BR",
      "elevation": 2529,
      "lat": -17.8299007416,
      "lon": -51.7729988098,
      "tz": "America\/Sao_Paulo"
  },
  "SWJX": {
      "icao": "SWJX",
      "iata": "",
      "name": "Fazenda das Perobas Airport",
      "city": "Prudente De Morais",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2762,
      "lat": -19.5022220612,
      "lon": -44.1577796936,
      "tz": "America\/Sao_Paulo"
  },
  "SWJY": {
      "icao": "SWJY",
      "iata": "",
      "name": "Fazenda Campo Alegre III Airport",
      "city": "Alto Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2625,
      "lat": -16.8447227478,
      "lon": -53.7344436646,
      "tz": "America\/Cuiaba"
  },
  "SWJZ": {
      "icao": "SWJZ",
      "iata": "",
      "name": "Fazenda Morro Vermelho Airport",
      "city": "Juscimeira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1654,
      "lat": -15.9813890457,
      "lon": -54.5338897705,
      "tz": "America\/Cuiaba"
  },
  "SWKA": {
      "icao": "SWKA",
      "iata": "",
      "name": "Fazenda Canada Airport",
      "city": "Jussara",
      "state": "Goias",
      "country": "BR",
      "elevation": 951,
      "lat": -15.5952777863,
      "lon": -51.2572212219,
      "tz": "America\/Sao_Paulo"
  },
  "SWKB": {
      "icao": "SWKB",
      "iata": "",
      "name": "Fazenda Campina Airport",
      "city": "Boca Do Acre",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 699,
      "lat": -9.1424999237,
      "lon": -67.4458312988,
      "tz": "America\/Manaus"
  },
  "SWKC": {
      "icao": "SWKC",
      "iata": "CCX",
      "name": "Caceres Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 492,
      "lat": -16.0436000824,
      "lon": -57.629901886,
      "tz": "America\/Cuiaba"
  },
  "SWKE": {
      "icao": "SWKE",
      "iata": "",
      "name": "Cobrape Airport",
      "city": "Formoso Do Araguaia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 656,
      "lat": -11.50861,
      "lon": -49.84222,
      "tz": "America\/Araguaina"
  },
  "SWKG": {
      "icao": "SWKG",
      "iata": "",
      "name": "Fazenda Agua Limpa Airport",
      "city": "Nova Ubirata",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1623,
      "lat": -13.4072217941,
      "lon": -54.7519454956,
      "tz": "America\/Cuiaba"
  },
  "SWKH": {
      "icao": "SWKH",
      "iata": "",
      "name": "Fazenda Floresta Airport",
      "city": "Campo Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2050,
      "lat": -15.2211112976,
      "lon": -55.1327781677,
      "tz": "America\/Cuiaba"
  },
  "SWKJ": {
      "icao": "SWKJ",
      "iata": "",
      "name": "Fazenda Mutum Airport",
      "city": "Dom Aquino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1821,
      "lat": -15.3980560303,
      "lon": -54.6083335876,
      "tz": "America\/Cuiaba"
  },
  "SWKL": {
      "icao": "SWKL",
      "iata": "",
      "name": "Fazenda Primavera do Terebinto Airport",
      "city": "Alto Alegre Dos Parecis",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 590,
      "lat": -12.5577783585,
      "lon": -61.9961128235,
      "tz": "America\/Porto_Velho"
  },
  "SWKM": {
      "icao": "SWKM",
      "iata": "",
      "name": "Aero Agricola Rondon Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1390,
      "lat": -14.6441669464,
      "lon": -57.5041656494,
      "tz": "America\/Cuiaba"
  },
  "SWKO": {
      "icao": "SWKO",
      "iata": "CIZ",
      "name": "Coari Airport",
      "city": "Coari",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 131,
      "lat": -4.134059906,
      "lon": -63.132598877,
      "tz": "America\/Manaus"
  },
  "SWKP": {
      "icao": "SWKP",
      "iata": "",
      "name": "Chacara Veneza Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 866,
      "lat": -15.2425003052,
      "lon": -59.2852783203,
      "tz": "America\/Cuiaba"
  },
  "SWKQ": {
      "icao": "SWKQ",
      "iata": "",
      "name": "Serra da Capivara Airport",
      "city": "Sao Raimundo Nonato",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1362,
      "lat": -9.082778,
      "lon": -42.644444,
      "tz": "America\/Fortaleza"
  },
  "SWKR": {
      "icao": "SWKR",
      "iata": "",
      "name": "Fazenda Mangue Che II Airport",
      "city": "Itaruma",
      "state": "Goias",
      "country": "BR",
      "elevation": 1752,
      "lat": -18.6700000763,
      "lon": -51.5852775574,
      "tz": "America\/Sao_Paulo"
  },
  "SWKT": {
      "icao": "SWKT",
      "iata": "TLZ",
      "name": "Catalao Airport",
      "city": "Catalao",
      "state": "Goias",
      "country": "BR",
      "elevation": 2612,
      "lat": -18.2168006897,
      "lon": -47.8997001648,
      "tz": "America\/Sao_Paulo"
  },
  "SWKU": {
      "icao": "SWKU",
      "iata": "",
      "name": "Cucui Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 274,
      "lat": 1.1206099987,
      "lon": -66.841796875,
      "tz": "America\/Manaus"
  },
  "SWKV": {
      "icao": "SWKV",
      "iata": "",
      "name": "Fazenda Beira Rio Airport",
      "city": "Itauba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1096,
      "lat": -10.9008331299,
      "lon": -55.8583335876,
      "tz": "America\/Cuiaba"
  },
  "SWKX": {
      "icao": "SWKX",
      "iata": "0",
      "name": "Corixa Airport",
      "city": "Caceres",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 394,
      "lat": -16.383600235,
      "lon": -58.317199707,
      "tz": "America\/Cuiaba"
  },
  "SWKY": {
      "icao": "SWKY",
      "iata": "",
      "name": "Fazenda Recanto da Cachoeira Airport",
      "city": "Cristais Paulista",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 3064,
      "lat": -20.3397216797,
      "lon": -47.4730567932,
      "tz": "America\/Sao_Paulo"
  },
  "SWKZ": {
      "icao": "SWKZ",
      "iata": "",
      "name": "Fazenda Cerejal Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -11.717499733,
      "lon": -58.2594451904,
      "tz": "America\/Cuiaba"
  },
  "SWLA": {
      "icao": "SWLA",
      "iata": "",
      "name": "Fazenda Amalia Airport",
      "city": "Juina",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1030,
      "lat": -11.884721756,
      "lon": -58.6316680908,
      "tz": "America\/Cuiaba"
  },
  "SWLB": {
      "icao": "SWLB",
      "iata": "LBR",
      "name": "Labrea Airport",
      "city": "Labrea",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 190,
      "lat": -7.2789697647,
      "lon": -64.7695007324,
      "tz": "America\/Manaus"
  },
  "SWLC": {
      "icao": "SWLC",
      "iata": "RVD",
      "name": "General Leite de Castro Airport",
      "city": "Rio Verde",
      "state": "Goias",
      "country": "BR",
      "elevation": 2464,
      "lat": -17.8347225189,
      "lon": -50.956111908,
      "tz": "America\/Sao_Paulo"
  },
  "SWLD": {
      "icao": "SWLD",
      "iata": "",
      "name": "Porto Cajueiro Airport",
      "city": "Januaria",
      "state": "Bahia",
      "country": "BR",
      "elevation": 2510,
      "lat": -14.8858327866,
      "lon": -45.3386116028,
      "tz": "America\/Bahia"
  },
  "SWLE": {
      "icao": "SWLE",
      "iata": "",
      "name": "Pontes e Lacerda Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1148,
      "lat": -15.2311105728,
      "lon": -59.3411102295,
      "tz": "America\/Cuiaba"
  },
  "SWLF": {
      "icao": "SWLF",
      "iata": "",
      "name": "Fazenda Dona Laura Airport",
      "city": "Sao Jose Dos Quatro Marcos",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 725,
      "lat": -15.6202783585,
      "lon": -58.2088890076,
      "tz": "America\/Cuiaba"
  },
  "SWLG": {
      "icao": "SWLG",
      "iata": "",
      "name": "Recanto Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1335,
      "lat": -12.999444,
      "lon": -51.905556,
      "tz": "America\/Cuiaba"
  },
  "SWLI": {
      "icao": "SWLI",
      "iata": "",
      "name": "Fazenda Liberdade Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 361,
      "lat": -17.0080566406,
      "lon": -56.4661102295,
      "tz": "America\/Cuiaba"
  },
  "SWLK": {
      "icao": "SWLK",
      "iata": "",
      "name": "Fazenda Conceicao Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1164,
      "lat": -11.0002784729,
      "lon": -58.2152786255,
      "tz": "America\/Cuiaba"
  },
  "SWLM": {
      "icao": "SWLM",
      "iata": "",
      "name": "Fazenda Excalibur Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 971,
      "lat": -15.2944440842,
      "lon": -60.3663902283,
      "tz": "America\/Cuiaba"
  },
  "SWLN": {
      "icao": "SWLN",
      "iata": "",
      "name": "Fazenda Franciosi Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1837,
      "lat": -13.4322223663,
      "lon": -58.4727783203,
      "tz": "America\/Cuiaba"
  },
  "SWLP": {
      "icao": "SWLP",
      "iata": "",
      "name": "Fazenda Rosa - Paralelo 10 Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 535,
      "lat": -9.9686107635,
      "lon": -61.2297210693,
      "tz": "America\/Cuiaba"
  },
  "SWLR": {
      "icao": "SWLR",
      "iata": "",
      "name": "Fazenda Miura Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 820,
      "lat": -15.4405555725,
      "lon": -59.6572227478,
      "tz": "America\/Cuiaba"
  },
  "SWLT": {
      "icao": "SWLT",
      "iata": "",
      "name": "Fazenda Tatiana Airport",
      "city": "Marcelandia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1197,
      "lat": -10.433889389,
      "lon": -54.1672210693,
      "tz": "America\/Cuiaba"
  },
  "SWLV": {
      "icao": "SWLV",
      "iata": "",
      "name": "Santo Antonio do Lerverger Airport",
      "city": "Santo Antonio Do Leverger",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 544,
      "lat": -15.8534002304,
      "lon": -56.0873985291,
      "tz": "America\/Cuiaba"
  },
  "SWMA": {
      "icao": "SWMA",
      "iata": "",
      "name": "Aero Agricola Fulanete Airport",
      "city": "Barreiras",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 2828,
      "lat": -11.8336105347,
      "lon": -46.2666664124,
      "tz": "America\/Bahia"
  },
  "SWMB": {
      "icao": "SWMB",
      "iata": "",
      "name": "Fazenda Marimbondo Airport",
      "city": "Goiatuba",
      "state": "Goias",
      "country": "BR",
      "elevation": 2165,
      "lat": -17.9772224426,
      "lon": -49.5883331299,
      "tz": "America\/Sao_Paulo"
  },
  "SWMC": {
      "icao": "SWMC",
      "iata": "",
      "name": "Capao da Cruz Airport",
      "city": "Luis Antonio",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1936,
      "lat": -21.4583320618,
      "lon": -47.8877792358,
      "tz": "America\/Sao_Paulo"
  },
  "SWME": {
      "icao": "SWME",
      "iata": "",
      "name": "Mineiros Airport",
      "city": "Mineiros",
      "state": "Goias",
      "country": "BR",
      "elevation": 2707,
      "lat": -17.551399231,
      "lon": -52.5567016602,
      "tz": "America\/Sao_Paulo"
  },
  "SWMG": {
      "icao": "SWMG",
      "iata": "",
      "name": "Fazenda Caraibas Airport",
      "city": "Monte Alegre De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1637,
      "lat": -13.385556221,
      "lon": -46.7750015259,
      "tz": "America\/Sao_Paulo"
  },
  "SWMH": {
      "icao": "SWMH",
      "iata": "",
      "name": "Fazenda Bom Pastor Airport",
      "city": "Itapirapua",
      "state": "Goias",
      "country": "BR",
      "elevation": 895,
      "lat": -15.542222023,
      "lon": -51.0177764893,
      "tz": "America\/Sao_Paulo"
  },
  "SWMJ": {
      "icao": "SWMJ",
      "iata": "",
      "name": "Pousada Xaraes Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 318,
      "lat": -19.4958324432,
      "lon": -56.9599990845,
      "tz": "America\/Campo_Grande"
  },
  "SWMK": {
      "icao": "SWMK",
      "iata": "",
      "name": "Maturaca Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 354,
      "lat": 0.6282690167,
      "lon": -66.1150970459,
      "tz": "America\/Manaus"
  },
  "SWML": {
      "icao": "SWML",
      "iata": "",
      "name": "Monte Alegre de Goias Airport",
      "city": "Monte Alegre De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1821,
      "lat": -13.2497215271,
      "lon": -46.8713874817,
      "tz": "America\/Sao_Paulo"
  },
  "SWMM": {
      "icao": "SWMM",
      "iata": "",
      "name": "Buritizal Agropastoril Ltda Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 432,
      "lat": -9.30027771,
      "lon": -60.6749992371,
      "tz": "America\/Cuiaba"
  },
  "SWMN": {
      "icao": "SWMN",
      "iata": "",
      "name": "Fazenda Forquilha Airport",
      "city": "Matrincha",
      "state": "Goias",
      "country": "BR",
      "elevation": 1076,
      "lat": -15.2163887024,
      "lon": -50.8366661072,
      "tz": "America\/Sao_Paulo"
  },
  "SWMO": {
      "icao": "SWMO",
      "iata": "",
      "name": "Fazenda Maria Jose Airport",
      "city": "Itiquira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1716,
      "lat": -17.4527778625,
      "lon": -54.7422218323,
      "tz": "America\/Cuiaba"
  },
  "SWMQ": {
      "icao": "SWMQ",
      "iata": "",
      "name": "Fazenda Muiraquita Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 367,
      "lat": -10.4169435501,
      "lon": -60.5505561829,
      "tz": "America\/Cuiaba"
  },
  "SWMR": {
      "icao": "SWMR",
      "iata": "",
      "name": "Morro Redondo Airport",
      "city": "Morro Redondo",
      "state": "Goias",
      "country": "BR",
      "elevation": 2067,
      "lat": -14.2505559921,
      "lon": -48.0838890076,
      "tz": "America\/Sao_Paulo"
  },
  "SWMT": {
      "icao": "SWMT",
      "iata": "",
      "name": "Fazenda Centro da Mata Airport",
      "city": "Nova Ubirata",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1214,
      "lat": -12.6577777863,
      "lon": -54.8277778625,
      "tz": "America\/Cuiaba"
  },
  "SWMU": {
      "icao": "SWMU",
      "iata": "",
      "name": "Sumuru Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 1640,
      "lat": 4.3163900375,
      "lon": -60.7005996704,
      "tz": "America\/Boa_Vista"
  },
  "SWMV": {
      "icao": "SWMV",
      "iata": "",
      "name": "Paa-Piu Airport",
      "city": "Mucajai",
      "state": "Roraima",
      "country": "BR",
      "elevation": 984,
      "lat": 2.7269399166,
      "lon": -62.9505996704,
      "tz": "America\/Boa_Vista"
  },
  "SWMW": {
      "icao": "SWMW",
      "iata": "MBZ",
      "name": "Maues Airport",
      "city": "Maues",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 69,
      "lat": -3.3721699715,
      "lon": -57.7248001099,
      "tz": "America\/Manaus"
  },
  "SWMX": {
      "icao": "SWMX",
      "iata": "",
      "name": "Morrinhos Airport",
      "city": "Morrinhos",
      "state": "Goias",
      "country": "BR",
      "elevation": 2789,
      "lat": -17.7614994049,
      "lon": -49.1221008301,
      "tz": "America\/Sao_Paulo"
  },
  "SWMY": {
      "icao": "SWMY",
      "iata": "",
      "name": "Porto Fluvial Suia Missu Airport",
      "city": "Barra Do Garcas",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 623,
      "lat": -11.5669441223,
      "lon": -50.7005577087,
      "tz": "America\/Araguaina"
  },
  "SWMZ": {
      "icao": "SWMZ",
      "iata": "",
      "name": "Estancia Aeronaldo Airport",
      "city": "Rondonopolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1000,
      "lat": -16.4427776337,
      "lon": -54.7019424438,
      "tz": "America\/Cuiaba"
  },
  "SWNA": {
      "icao": "SWNA",
      "iata": "NVP",
      "name": "Novo Aripuana Airport",
      "city": "Novo Aripuana",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 118,
      "lat": -5.1180300713,
      "lon": -60.3648986816,
      "tz": "America\/Manaus"
  },
  "SWNB": {
      "icao": "SWNB",
      "iata": "",
      "name": "Nobres Airport",
      "city": "Nobres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 722,
      "lat": -14.7072219849,
      "lon": -56.3383331299,
      "tz": "America\/Cuiaba"
  },
  "SWNC": {
      "icao": "SWNC",
      "iata": "",
      "name": "Fazenda Procomp Airport",
      "city": "Nova Bandeirantes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 673,
      "lat": -9.4011106491,
      "lon": -58.2286109924,
      "tz": "America\/Cuiaba"
  },
  "SWNF": {
      "icao": "SWNF",
      "iata": "",
      "name": "Agropecuaria Tratex Airport",
      "city": "Colider",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1033,
      "lat": -10.8919439316,
      "lon": -55.5641670227,
      "tz": "America\/Cuiaba"
  },
  "SWNG": {
      "icao": "SWNG",
      "iata": "",
      "name": "Fazenda Nossa Senhora das Gracas Airport",
      "city": "Sorriso",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1279,
      "lat": -12.8966665268,
      "lon": -55.8344421387,
      "tz": "America\/Cuiaba"
  },
  "SWNH": {
      "icao": "SWNH",
      "iata": "",
      "name": "Aruana Airport",
      "city": "Aruana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 820,
      "lat": -14.9324998856,
      "lon": -51.0480995178,
      "tz": "America\/Sao_Paulo"
  },
  "SWNI": {
      "icao": "SWNI",
      "iata": "AQM",
      "name": "Nova Vida Airport",
      "city": "Ariquemes",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 410,
      "lat": -10.1780557632,
      "lon": -62.8255577087,
      "tz": "America\/Porto_Velho"
  },
  "SWNJ": {
      "icao": "SWNJ",
      "iata": "",
      "name": "Fazenda Itaquere Airport",
      "city": "Novo Sao Joaquim",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1749,
      "lat": -15.1283330917,
      "lon": -53.4916687012,
      "tz": "America\/Cuiaba"
  },
  "SWNK": {
      "icao": "SWNK",
      "iata": "BCR",
      "name": "Novo Campo Airport",
      "city": "Boca Do Acre",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 394,
      "lat": -8.8345603943,
      "lon": -67.3124008179,
      "tz": "America\/Manaus"
  },
  "SWNL": {
      "icao": "SWNL",
      "iata": "",
      "name": "Fazenda Aracatuba Airport",
      "city": "Lambari D`Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 493,
      "lat": -15.1908330917,
      "lon": -57.7075004578,
      "tz": "America\/Cuiaba"
  },
  "SWNM": {
      "icao": "SWNM",
      "iata": "",
      "name": "Normandia Airport",
      "city": "Normandia",
      "state": "Roraima",
      "country": "BR",
      "elevation": 282,
      "lat": 3.8663899899,
      "lon": -59.6171989441,
      "tz": "America\/Boa_Vista"
  },
  "SWNN": {
      "icao": "SWNN",
      "iata": "",
      "name": "Novo Intento Airport",
      "city": "Canta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 282,
      "lat": 2.7799999714,
      "lon": -60.6166687012,
      "tz": "America\/Boa_Vista"
  },
  "SWNO": {
      "icao": "SWNO",
      "iata": "",
      "name": "Nova Olinda do Norte Airport",
      "city": "Nova Olinda Do Norte",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 121,
      "lat": -3.8877780437,
      "lon": -59.0783348083,
      "tz": "America\/Manaus"
  },
  "SWNQ": {
      "icao": "SWNQ",
      "iata": "NQL",
      "name": "Niquelandia Airport",
      "city": "Niquelandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 2756,
      "lat": -14.4349002838,
      "lon": -48.4915008545,
      "tz": "America\/Sao_Paulo"
  },
  "SWNR": {
      "icao": "SWNR",
      "iata": "",
      "name": "Nortelandia Airport",
      "city": "Nortelandia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1460,
      "lat": -14.4836111069,
      "lon": -56.7672233582,
      "tz": "America\/Cuiaba"
  },
  "SWNS": {
      "icao": "SWNS",
      "iata": "APS",
      "name": "Anapolis Airport",
      "city": "Anapolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 3648,
      "lat": -16.3623008728,
      "lon": -48.9271011353,
      "tz": "America\/Sao_Paulo"
  },
  "SWNT": {
      "icao": "SWNT",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Cacu",
      "state": "Goias",
      "country": "BR",
      "elevation": 1493,
      "lat": -18.9650001526,
      "lon": -50.7986106873,
      "tz": "America\/Sao_Paulo"
  },
  "SWNV": {
      "icao": "SWNV",
      "iata": "",
      "name": "Aerodromo Nacional de Aviacao Airport",
      "city": "Goiania",
      "state": "Goias",
      "country": "BR",
      "elevation": 2707,
      "lat": -16.6254005432,
      "lon": -49.3493003845,
      "tz": "America\/Sao_Paulo"
  },
  "SWNW": {
      "icao": "SWNW",
      "iata": "",
      "name": "Fazenda Tuiuiu Airport",
      "city": "Nobres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1525,
      "lat": -13.7994441986,
      "lon": -55.4550018311,
      "tz": "America\/Cuiaba"
  },
  "SWNX": {
      "icao": "SWNX",
      "iata": "",
      "name": "Fazenda Independencia Airport",
      "city": "Santo Antonio Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1650,
      "lat": -14.8680562973,
      "lon": -53.5011100769,
      "tz": "America\/Cuiaba"
  },
  "SWOA": {
      "icao": "SWOA",
      "iata": "",
      "name": "Arnapar Airport",
      "city": "Goianira",
      "state": "Goias",
      "country": "BR",
      "elevation": 2625,
      "lat": -16.5394439697,
      "lon": -49.3988876343,
      "tz": "America\/Sao_Paulo"
  },
  "SWOB": {
      "icao": "SWOB",
      "iata": "FBA",
      "name": "Fonte Boa Airport",
      "city": "Fonte Boa",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 207,
      "lat": -2.5326099396,
      "lon": -66.0831985474,
      "tz": "America\/Manaus"
  },
  "SWOC": {
      "icao": "SWOC",
      "iata": "",
      "name": "Fazenda Sao Bento do Bocaja Airport",
      "city": "Caracol",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 443,
      "lat": -22.0252780914,
      "lon": -57.3647232056,
      "tz": "America\/Campo_Grande"
  },
  "SWOE": {
      "icao": "SWOE",
      "iata": "",
      "name": "Fazenda Duas Lagoas Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 688,
      "lat": -16.1708335876,
      "lon": -58.1872215271,
      "tz": "America\/Cuiaba"
  },
  "SWOF": {
      "icao": "SWOF",
      "iata": "",
      "name": "Ouro Fino Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 282,
      "lat": 2.9380600452,
      "lon": -60.7200012207,
      "tz": "America\/Boa_Vista"
  },
  "SWOG": {
      "icao": "SWOG",
      "iata": "",
      "name": "Fazenda Itaipu Airport",
      "city": "Sao Jose Do Rio Claro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1470,
      "lat": -13.7525,
      "lon": -56.96111,
      "tz": "America\/Cuiaba"
  },
  "SWOI": {
      "icao": "SWOI",
      "iata": "",
      "name": "Fazenda Juara Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1200,
      "lat": -11.8169441223,
      "lon": -57.5661125183,
      "tz": "America\/Cuiaba"
  },
  "SWOJ": {
      "icao": "SWOJ",
      "iata": "",
      "name": "Fazenda Nova Alvorada Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1092,
      "lat": -11.5733327866,
      "lon": -58.2697219849,
      "tz": "America\/Cuiaba"
  },
  "SWOK": {
      "icao": "SWOK",
      "iata": "",
      "name": "Fazenda Pontal Airport",
      "city": "Itiquira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2164,
      "lat": -17.3055553436,
      "lon": -53.9355545044,
      "tz": "America\/Cuiaba"
  },
  "SWOL": {
      "icao": "SWOL",
      "iata": "",
      "name": "Fazenda Roncador Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1351,
      "lat": -15.0961112976,
      "lon": -52.4000015259,
      "tz": "America\/Cuiaba"
  },
  "SWOM": {
      "icao": "SWOM",
      "iata": "",
      "name": "Fazenda Salto Bello II Airport",
      "city": "Novo Sao Joaquim",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1889,
      "lat": -14.9611110687,
      "lon": -53.4880561829,
      "tz": "America\/Cuiaba"
  },
  "SWOO": {
      "icao": "SWOO",
      "iata": "",
      "name": "Fazenda Tres Marias do Agua Limpa Airport",
      "city": "Britania",
      "state": "Goias",
      "country": "BR",
      "elevation": 1050,
      "lat": -15.0713891983,
      "lon": -51.3927764893,
      "tz": "America\/Sao_Paulo"
  },
  "SWOP": {
      "icao": "SWOP",
      "iata": "",
      "name": "Fazenda Agropecuaria Embu Airport",
      "city": "Nova Monte Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1188,
      "lat": -9.7875003815,
      "lon": -57.6244430542,
      "tz": "America\/Cuiaba"
  },
  "SWOQ": {
      "icao": "SWOQ",
      "iata": "",
      "name": "Fazenda Surucucu Airport",
      "city": "Luziania",
      "state": "Goias",
      "country": "BR",
      "elevation": 3148,
      "lat": -16.7433338165,
      "lon": -47.9916687012,
      "tz": "America\/Sao_Paulo"
  },
  "SWOR": {
      "icao": "SWOR",
      "iata": "",
      "name": "Fazenda Colorado Airport",
      "city": "Britania",
      "state": "Goias",
      "country": "BR",
      "elevation": 896,
      "lat": -15.4508333206,
      "lon": -51.2233314514,
      "tz": "America\/Sao_Paulo"
  },
  "SWOS": {
      "icao": "SWOS",
      "iata": "",
      "name": "Fazenda Tapajos Airport",
      "city": "Goianesia",
      "state": "Goias",
      "country": "BR",
      "elevation": 2289,
      "lat": -15.1719436646,
      "lon": -48.9530563354,
      "tz": "America\/Sao_Paulo"
  },
  "SWOT": {
      "icao": "SWOT",
      "iata": "",
      "name": "Fazenda Planorte Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2106,
      "lat": -13.943333,
      "lon": -58.895278,
      "tz": "America\/Cuiaba"
  },
  "SWOV": {
      "icao": "SWOV",
      "iata": "",
      "name": "Retiro Arrombadinho Airport",
      "city": "Itiquira",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 423,
      "lat": -17.5074996948,
      "lon": -55.4080543518,
      "tz": "America\/Campo_Grande"
  },
  "SWOW": {
      "icao": "SWOW",
      "iata": "",
      "name": "Moura Airport",
      "city": "Moura",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 43,
      "lat": -1.4669439793,
      "lon": -61.6172218323,
      "tz": "America\/Manaus"
  },
  "SWOX": {
      "icao": "SWOX",
      "iata": "",
      "name": "Estancia Barbosa Airport",
      "city": "Nossa Senhora Do Livramento",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 619,
      "lat": -15.7655563354,
      "lon": -56.4955558777,
      "tz": "America\/Cuiaba"
  },
  "SWOY": {
      "icao": "SWOY",
      "iata": "",
      "name": "Fazenda Duas Barras Airport",
      "city": "Pontal Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1239,
      "lat": -16.0252780914,
      "lon": -52.8122215271,
      "tz": "America\/Cuiaba"
  },
  "SWOZ": {
      "icao": "SWOZ",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Campo Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2164,
      "lat": -15.3105564117,
      "lon": -54.8991661072,
      "tz": "America\/Cuiaba"
  },
  "SWPA": {
      "icao": "SWPA",
      "iata": "",
      "name": "Pedro Afonso Airport",
      "city": "Pedro Afonso",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 630,
      "lat": -8.997618,
      "lon": -48.155375,
      "tz": "America\/Araguaina"
  },
  "SWPB": {
      "icao": "SWPB",
      "iata": "",
      "name": "Forte Principe da Beira Airport",
      "city": "Costa Marques",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 591,
      "lat": -12.4186105728,
      "lon": -64.4188919067,
      "tz": "America\/La_Paz"
  },
  "SWPC": {
      "icao": "SWPC",
      "iata": "",
      "name": "Pari Cachoeira Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 377,
      "lat": 0.26305601,
      "lon": -69.7955551147,
      "tz": "America\/Manaus"
  },
  "SWPD": {
      "icao": "SWPD",
      "iata": "",
      "name": "Pouso da Aguia Airport",
      "city": "Canta",
      "state": "Roraima",
      "country": "BR",
      "elevation": 282,
      "lat": 2.7836110592,
      "lon": -60.5877761841,
      "tz": "America\/Boa_Vista"
  },
  "SWPE": {
      "icao": "SWPE",
      "iata": "",
      "name": "Fazenda Lagoa da Mata Airport",
      "city": "Carlinda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 866,
      "lat": -10.1363887787,
      "lon": -55.5950012207,
      "tz": "America\/Cuiaba"
  },
  "SWPG": {
      "icao": "SWPG",
      "iata": "PBV",
      "name": "Porto dos Gauchos Airport",
      "city": "Porto Dos Gauchos",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1312,
      "lat": -11.5169439316,
      "lon": -57.3338890076,
      "tz": "America\/Cuiaba"
  },
  "SWPH": {
      "icao": "SWPH",
      "iata": "",
      "name": "Fazenda Paulo Abreu Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 978,
      "lat": -15.226111412,
      "lon": -52.1300010681,
      "tz": "America\/Cuiaba"
  },
  "SWPI": {
      "icao": "SWPI",
      "iata": "PIN",
      "name": "Parintins Airport",
      "city": "Parintins",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 87,
      "lat": -2.673019886,
      "lon": -56.7771987915,
      "tz": "America\/Manaus"
  },
  "SWPJ": {
      "icao": "SWPJ",
      "iata": "",
      "name": "Fazenda Sao Caetano Airport",
      "city": "Pontal Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1587,
      "lat": -16.0341663361,
      "lon": -53.0344429016,
      "tz": "America\/Cuiaba"
  },
  "SWPK": {
      "icao": "SWPK",
      "iata": "",
      "name": "Pocone Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 591,
      "lat": -16.2727775574,
      "lon": -56.6508331299,
      "tz": "America\/Cuiaba"
  },
  "SWPL": {
      "icao": "SWPL",
      "iata": "",
      "name": "Posto Leonardo Vilas Boas Airport",
      "city": "Chapada Dos Guimaraes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -12.1983327866,
      "lon": -53.3816680908,
      "tz": "America\/Cuiaba"
  },
  "SWPM": {
      "icao": "SWPM",
      "iata": "PBQ",
      "name": "Pimenta Bueno Airport",
      "city": "Pimenta Bueno",
      "state": "",
      "country": "BR",
      "elevation": 682,
      "lat": -11.6415996552,
      "lon": -61.1791000366,
      "tz": "America\/Porto_Velho"
  },
  "SWPN": {
      "icao": "SWPN",
      "iata": "",
      "name": "Parana Airport",
      "city": "Parana",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 886,
      "lat": -12.6197223663,
      "lon": -47.8725013733,
      "tz": "America\/Araguaina"
  },
  "SWPO": {
      "icao": "SWPO",
      "iata": "",
      "name": "Fazenda Porta do Amazonia Airport",
      "city": "Santa Terezinha",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 787,
      "lat": -10.2652778625,
      "lon": -51.2413902283,
      "tz": "America\/Cuiaba"
  },
  "SWPP": {
      "icao": "SWPP",
      "iata": "",
      "name": "Fazenda Pica-Pau Airport",
      "city": "Mozarlandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1001,
      "lat": -14.7530555725,
      "lon": -50.6650009155,
      "tz": "America\/Sao_Paulo"
  },
  "SWPQ": {
      "icao": "SWPQ",
      "iata": "PBX",
      "name": "Fazenda Piraguassu Airport",
      "city": "Porto Alegre Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 705,
      "lat": -10.8611106873,
      "lon": -51.6850013733,
      "tz": "America\/Cuiaba"
  },
  "SWPR": {
      "icao": "SWPR",
      "iata": "",
      "name": "Pires do Rio Airport",
      "city": "Pires Do Rio",
      "state": "Goias",
      "country": "BR",
      "elevation": 2428,
      "lat": -17.3190994263,
      "lon": -48.3348007202,
      "tz": "America\/Sao_Paulo"
  },
  "SWPS": {
      "icao": "SWPS",
      "iata": "",
      "name": "Porto Sao Sebastiao Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1106,
      "lat": -12.9669437408,
      "lon": -56.1838874817,
      "tz": "America\/Cuiaba"
  },
  "SWPT": {
      "icao": "SWPT",
      "iata": "",
      "name": "Fazenda Piratinga Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1706,
      "lat": -13.6172218323,
      "lon": -55.0133323669,
      "tz": "America\/Cuiaba"
  },
  "SWPV": {
      "icao": "SWPV",
      "iata": "",
      "name": "Planaltina Airport",
      "city": "Brasilia",
      "state": "Federal-District",
      "country": "BR",
      "elevation": 3806,
      "lat": -15.6788892746,
      "lon": -47.5441665649,
      "tz": "America\/Sao_Paulo"
  },
  "SWPW": {
      "icao": "SWPW",
      "iata": "",
      "name": "Pouso da Garca Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 377,
      "lat": -16.9672222137,
      "lon": -56.4172210693,
      "tz": "America\/Cuiaba"
  },
  "SWPY": {
      "icao": "SWPY",
      "iata": "",
      "name": "Primavera do Leste Airport",
      "city": "Primavera Do Leste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2149,
      "lat": -15.5655555725,
      "lon": -54.3377761841,
      "tz": "America\/Cuiaba"
  },
  "SWPZ": {
      "icao": "SWPZ",
      "iata": "",
      "name": "Oricanga de Abreu Airport",
      "city": "Posse",
      "state": "Goias",
      "country": "BR",
      "elevation": 2713,
      "lat": -14.1181001663,
      "lon": -46.3454017639,
      "tz": "America\/Sao_Paulo"
  },
  "SWQE": {
      "icao": "SWQE",
      "iata": "",
      "name": "Querari Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Vaupes",
      "country": "BR",
      "elevation": 492,
      "lat": 1.0913900137,
      "lon": -69.8506011963,
      "tz": "America\/Bogota"
  },
  "SWQG": {
      "icao": "SWQG",
      "iata": "",
      "name": "Fazenda Sossego Airport",
      "city": "Maracaju",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1706,
      "lat": -21.4572219849,
      "lon": -55.4211120605,
      "tz": "America\/Campo_Grande"
  },
  "SWQH": {
      "icao": "SWQH",
      "iata": "",
      "name": "Agropecuaria Nova Santana Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 797,
      "lat": -10.1472215652,
      "lon": -60.5386123657,
      "tz": "America\/Cuiaba"
  },
  "SWQI": {
      "icao": "SWQI",
      "iata": "",
      "name": "Caracarai Airport",
      "city": "Caracarai",
      "state": "Roraima",
      "country": "BR",
      "elevation": 180,
      "lat": 1.832874,
      "lon": -61.126896,
      "tz": "America\/Boa_Vista"
  },
  "SWQJ": {
      "icao": "SWQJ",
      "iata": "",
      "name": "Campos de Julio Airport",
      "city": "Campos De Julio",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2132,
      "lat": -13.4147224426,
      "lon": -59.2675018311,
      "tz": "America\/Cuiaba"
  },
  "SWQK": {
      "icao": "SWQK",
      "iata": "",
      "name": "Pedra Airport",
      "city": "Pedra Lavrada",
      "state": "Paraiba",
      "country": "BR",
      "elevation": 1781,
      "lat": -6.7666668892,
      "lon": -36.4811096191,
      "tz": "America\/Fortaleza"
  },
  "SWQL": {
      "icao": "SWQL",
      "iata": "",
      "name": "Fazenda Tres Companheiros Airport",
      "city": "Doverlandia",
      "state": "Goias",
      "country": "BR",
      "elevation": 2070,
      "lat": -16.6825008392,
      "lon": -52.2524986267,
      "tz": "America\/Sao_Paulo"
  },
  "SWQM": {
      "icao": "SWQM",
      "iata": "",
      "name": "Fazenda Boa Vista Airport",
      "city": "Acorizal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 688,
      "lat": -15.0894441605,
      "lon": -56.3733329773,
      "tz": "America\/Cuiaba"
  },
  "SWQP": {
      "icao": "SWQP",
      "iata": "",
      "name": "Rio Vermelho Acucar e Alcool Airport",
      "city": "Junqueiropolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1148,
      "lat": -21.3152770996,
      "lon": -51.3699989319,
      "tz": "America\/Sao_Paulo"
  },
  "SWQQ": {
      "icao": "SWQQ",
      "iata": "",
      "name": "Fazenda Fundao Alegre Airport",
      "city": "Camapua",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1391,
      "lat": -18.6280555725,
      "lon": -54.1038894653,
      "tz": "America\/Campo_Grande"
  },
  "SWQR": {
      "icao": "SWQR",
      "iata": "",
      "name": "Estancia Norteagro Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 279,
      "lat": 2.6758329868,
      "lon": -60.7786102295,
      "tz": "America\/Boa_Vista"
  },
  "SWQT": {
      "icao": "SWQT",
      "iata": "",
      "name": "Fazenda Cotriguacu Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 738,
      "lat": -9.864443779,
      "lon": -58.2291679382,
      "tz": "America\/Cuiaba"
  },
  "SWQU": {
      "icao": "SWQU",
      "iata": "",
      "name": "Fazenda Guapore Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 728,
      "lat": -14.5874996185,
      "lon": -60.1263885498,
      "tz": "America\/Cuiaba"
  },
  "SWQV": {
      "icao": "SWQV",
      "iata": "",
      "name": "Fazenda Maristela Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 833,
      "lat": -16.1166667938,
      "lon": -58.969165802,
      "tz": "America\/Cuiaba"
  },
  "SWQW": {
      "icao": "SWQW",
      "iata": "",
      "name": "Fazenda Bacaeri Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1050,
      "lat": -10.052778244,
      "lon": -56.8255577087,
      "tz": "America\/Cuiaba"
  },
  "SWQX": {
      "icao": "SWQX",
      "iata": "",
      "name": "Fazenda Barro Preto Airport",
      "city": "Monte Alegre De Goias",
      "state": "Goias",
      "country": "BR",
      "elevation": 1578,
      "lat": -13.3352775574,
      "lon": -46.90111111,
      "tz": "America\/Sao_Paulo"
  },
  "SWQZ": {
      "icao": "SWQZ",
      "iata": "",
      "name": "Fazenda Quatro Irmaos Airport",
      "city": "Ribas Do Rio Pardo",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1509,
      "lat": -20.8783321381,
      "lon": -53.5533332825,
      "tz": "America\/Campo_Grande"
  },
  "SWRA": {
      "icao": "SWRA",
      "iata": "AAI",
      "name": "Arraias Airport",
      "city": "Arraias",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1923,
      "lat": -13.023888588,
      "lon": -46.8855552673,
      "tz": "America\/Araguaina"
  },
  "SWRB": {
      "icao": "SWRB",
      "iata": "",
      "name": "Fazenda Rio dos Bugres Airport",
      "city": "Barra Do Bugres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1148,
      "lat": -14.8169441223,
      "lon": -56.9505577087,
      "tz": "America\/Cuiaba"
  },
  "SWRC": {
      "icao": "SWRC",
      "iata": "",
      "name": "Fazenda Sevilha Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 951,
      "lat": -12.6266670227,
      "lon": -51.9341659546,
      "tz": "America\/Cuiaba"
  },
  "SWRD": {
      "icao": "SWRD",
      "iata": "ROO",
      "name": "Rondonopolis Airport",
      "city": "Rondonopolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1467,
      "lat": -16.5860004425,
      "lon": -54.7248001099,
      "tz": "America\/Cuiaba"
  },
  "SWRF": {
      "icao": "SWRF",
      "iata": "",
      "name": "Fazenda Toloza Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1644,
      "lat": -13.1836109161,
      "lon": -57.9838905334,
      "tz": "America\/Cuiaba"
  },
  "SWRG": {
      "icao": "SWRG",
      "iata": "",
      "name": "Fazenda Rancho Maria e Tereza Airport",
      "city": "Guajara-Mirim",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 492,
      "lat": -10.8258333206,
      "lon": -65.2286148071,
      "tz": "America\/Porto_Velho"
  },
  "SWRI": {
      "icao": "SWRI",
      "iata": "",
      "name": "Fazenda Roncador I Airport",
      "city": "Querencia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 993,
      "lat": -12.1554002762,
      "lon": -52.2789993286,
      "tz": "America\/Cuiaba"
  },
  "SWRJ": {
      "icao": "SWRJ",
      "iata": "",
      "name": "Fazenda Panflora Airport",
      "city": "Rosario Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 757,
      "lat": -15.0833330154,
      "lon": -56.5527763367,
      "tz": "America\/Cuiaba"
  },
  "SWRK": {
      "icao": "SWRK",
      "iata": "",
      "name": "South West Rocks Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9333000183,
      "lon": 153.0330047607,
      "tz": "Australia\/Sydney"
  },
  "SWRL": {
      "icao": "SWRL",
      "iata": "",
      "name": "Fazenda Pouso Redondo Airport",
      "city": "Corumbiara",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1086,
      "lat": -12.9772224426,
      "lon": -61.0491676331,
      "tz": "America\/Porto_Velho"
  },
  "SWRM": {
      "icao": "SWRM",
      "iata": "",
      "name": "Santo Antonio do Descoberto Airport",
      "city": "Santo Antonio Do Descoberto",
      "state": "Goias",
      "country": "BR",
      "elevation": 3674,
      "lat": -15.9305562973,
      "lon": -48.3336105347,
      "tz": "America\/Sao_Paulo"
  },
  "SWRO": {
      "icao": "SWRO",
      "iata": "",
      "name": "Aeroclube de Rondonia Airport",
      "city": "Porto Velho",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 312,
      "lat": -8.7936105728,
      "lon": -63.8580551147,
      "tz": "America\/Porto_Velho"
  },
  "SWRP": {
      "icao": "SWRP",
      "iata": "AIR",
      "name": "Aripuana Airport",
      "city": "Aripuana",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 623,
      "lat": -10.2502775192,
      "lon": -59.3838882446,
      "tz": "America\/Cuiaba"
  },
  "SWRR": {
      "icao": "SWRR",
      "iata": "",
      "name": "Fazenda Serra Azul Airport",
      "city": "Planalto Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1542,
      "lat": -14.6677780151,
      "lon": -54.5675010681,
      "tz": "America\/Cuiaba"
  },
  "SWRS": {
      "icao": "SWRS",
      "iata": "",
      "name": "Fazenda Santa Monica Airport",
      "city": "Rondonopolis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1047,
      "lat": -16.9333324432,
      "lon": -54.9069442749,
      "tz": "America\/Cuiaba"
  },
  "SWRT": {
      "icao": "SWRT",
      "iata": "",
      "name": "Fazenda Santa Rita Airport",
      "city": "Santa Rita Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2346,
      "lat": -17.3477783203,
      "lon": -53.1930541992,
      "tz": "America\/Cuiaba"
  },
  "SWRU": {
      "icao": "SWRU",
      "iata": "0",
      "name": "Meruri Airport",
      "city": "Tesouro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1460,
      "lat": -15.5564002991,
      "lon": -53.0752983093,
      "tz": "America\/Cuiaba"
  },
  "SWRV": {
      "icao": "SWRV",
      "iata": "",
      "name": "Fazenda Vale do Rio Verde Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1378,
      "lat": -12.1669435501,
      "lon": -56.5338897705,
      "tz": "America\/Cuiaba"
  },
  "SWRW": {
      "icao": "SWRW",
      "iata": "",
      "name": "Fazenda Santa Alice Airport",
      "city": "Mirassol D`Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1115,
      "lat": -15.5874996185,
      "lon": -58.0444450378,
      "tz": "America\/Cuiaba"
  },
  "SWRX": {
      "icao": "SWRX",
      "iata": "",
      "name": "Fazenda Santa Cruz do Pessoe Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 764,
      "lat": -15.1649999619,
      "lon": -60.4458312988,
      "tz": "America\/Cuiaba"
  },
  "SWRY": {
      "icao": "SWRY",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Porto Estrela",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1853,
      "lat": -15.7375001907,
      "lon": -57.4705543518,
      "tz": "America\/Cuiaba"
  },
  "SWRZ": {
      "icao": "SWRZ",
      "iata": "",
      "name": "Fazenda Arrossensal Airport",
      "city": "Nortelandia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1306,
      "lat": -14.353099823,
      "lon": -56.7137985229,
      "tz": "America\/Cuiaba"
  },
  "SWSA": {
      "icao": "SWSA",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1476,
      "lat": -12.2891674042,
      "lon": -56.5338897705,
      "tz": "America\/Cuiaba"
  },
  "SWSB": {
      "icao": "SWSB",
      "iata": "",
      "name": "Fazenda Sabia Airport",
      "city": "Araguaiana",
      "state": "",
      "country": "BR",
      "elevation": 951,
      "lat": -15.233332634,
      "lon": -51.9772224426,
      "tz": "America\/Cuiaba"
  },
  "SWSC": {
      "icao": "SWSC",
      "iata": "",
      "name": "Fazenda Santa Cruz Airport",
      "city": "Sao Felix Do Araguaia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -11.6536111832,
      "lon": -52.7333335876,
      "tz": "America\/Cuiaba"
  },
  "SWSD": {
      "icao": "SWSD",
      "iata": "",
      "name": "Fazenda Santo Antonio Airport",
      "city": "Araguaiana",
      "state": "",
      "country": "BR",
      "elevation": 1079,
      "lat": -15.6238889694,
      "lon": -51.8138885498,
      "tz": "America\/Cuiaba"
  },
  "SWSF": {
      "icao": "SWSF",
      "iata": "",
      "name": "Fazenda Sao Francisco Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "",
      "country": "BR",
      "elevation": 863,
      "lat": -15.2241001129,
      "lon": -60.2172012329,
      "tz": "America\/Cuiaba"
  },
  "SWSG": {
      "icao": "SWSG",
      "iata": "",
      "name": "Fazenda Simarelli Airport",
      "city": "Campos De Julio",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2136,
      "lat": -13.6068000793,
      "lon": -59.3167991638,
      "tz": "America\/Cuiaba"
  },
  "SWSH": {
      "icao": "SWSH",
      "iata": "",
      "name": "Sao Raimundo II Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 932,
      "lat": -9.9663887024,
      "lon": -56.1527786255,
      "tz": "America\/Cuiaba"
  },
  "SWSI": {
      "icao": "SWSI",
      "iata": "OPS",
      "name": "Presidente Joao Batista Figueiredo Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1227,
      "lat": -11.8850002289,
      "lon": -55.5861091614,
      "tz": "America\/Cuiaba"
  },
  "SWSK": {
      "icao": "SWSK",
      "iata": "",
      "name": "Fazenda Sao Marco Airport",
      "city": "Boa Vista",
      "state": "Roraima",
      "country": "BR",
      "elevation": 295,
      "lat": 3.0497200489,
      "lon": -60.4838981628,
      "tz": "America\/Boa_Vista"
  },
  "SWSM": {
      "icao": "SWSM",
      "iata": "",
      "name": "Fazenda Santa Maria Airport",
      "city": "Lambari D`Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 525,
      "lat": -15.35833,
      "lon": -57.69444,
      "tz": "America\/Cuiaba"
  },
  "SWSP": {
      "icao": "SWSP",
      "iata": "",
      "name": "Sopave Norte Airport",
      "city": "Paranatinga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1690,
      "lat": -13.7252779007,
      "lon": -54.7383346558,
      "tz": "America\/Cuiaba"
  },
  "SWSQ": {
      "icao": "SWSQ",
      "iata": "",
      "name": "Sao Joaquim Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 541,
      "lat": 1.733055556,
      "lon": -69.38388889,
      "tz": "America\/Bogota"
  },
  "SWSR": {
      "icao": "SWSR",
      "iata": "",
      "name": "Sertania Airport",
      "city": "Sertania",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1772,
      "lat": -13.7502784729,
      "lon": -55.6338882446,
      "tz": "America\/Cuiaba"
  },
  "SWSS": {
      "icao": "SWSS",
      "iata": "",
      "name": "Fazenda Silmar Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 863,
      "lat": -16.1650009155,
      "lon": -60.1144447327,
      "tz": "America\/Cuiaba"
  },
  "SWST": {
      "icao": "SWST",
      "iata": "STZ",
      "name": "Santa Terezinha Airport",
      "city": "Santa Terezinha",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 663,
      "lat": -10.4647216797,
      "lon": -50.518611908,
      "tz": "America\/Araguaina"
  },
  "SWSU": {
      "icao": "SWSU",
      "iata": "",
      "name": "Fazenda Santa Juliana Airport",
      "city": "Ribeirao Cascalheira",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1230,
      "lat": -12.8522224426,
      "lon": -52.0208320618,
      "tz": "America\/Cuiaba"
  },
  "SWSV": {
      "icao": "SWSV",
      "iata": "",
      "name": "Fazenda Santa Silvia Airport",
      "city": "Cocalinho",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 837,
      "lat": -14.3886108398,
      "lon": -51.3805541992,
      "tz": "America\/Cuiaba"
  },
  "SWSX": {
      "icao": "SWSX",
      "iata": "",
      "name": "Seringal Nova Olinda Airport",
      "city": "Sena Mafureira",
      "state": "Acre",
      "country": "BR",
      "elevation": 673,
      "lat": -10.1044435501,
      "lon": -69.2119445801,
      "tz": "America\/Rio_Branco"
  },
  "SWSY": {
      "icao": "SWSY",
      "iata": "",
      "name": "Sao Simao Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 705,
      "lat": -15.9086112976,
      "lon": -60.1547203064,
      "tz": "America\/Cuiaba"
  },
  "SWSZ": {
      "icao": "SWSZ",
      "iata": "",
      "name": "Fazenda Santa Luzia Airport",
      "city": "Nova Bandeirantes",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 741,
      "lat": -9.7736110687,
      "lon": -58.0627784729,
      "tz": "America\/Cuiaba"
  },
  "SWTA": {
      "icao": "SWTA",
      "iata": "",
      "name": "Fazenda Santa Marcia Airport",
      "city": "Araputanga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 938,
      "lat": -15.4152784348,
      "lon": -58.4088897705,
      "tz": "America\/Cuiaba"
  },
  "SWTB": {
      "icao": "SWTB",
      "iata": "",
      "name": "Tabajara Airport",
      "city": "Ariquemes",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 722,
      "lat": -8.9169435501,
      "lon": -62.1172218323,
      "tz": "America\/Porto_Velho"
  },
  "SWTC": {
      "icao": "SWTC",
      "iata": "",
      "name": "Tocantinia Airport",
      "city": "Tocantinia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 656,
      "lat": -9.5836105347,
      "lon": -48.4505577087,
      "tz": "America\/Araguaina"
  },
  "SWTD": {
      "icao": "SWTD",
      "iata": "",
      "name": "Fazenda Sete de Setembro Airport",
      "city": "Agua Boa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1066,
      "lat": -13.881111145,
      "lon": -52.4000015259,
      "tz": "America\/Cuiaba"
  },
  "SWTE": {
      "icao": "SWTE",
      "iata": "",
      "name": "Fazenda Trescinco Airport",
      "city": "Diamantino",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1132,
      "lat": -13.9169435501,
      "lon": -56.6588897705,
      "tz": "America\/Cuiaba"
  },
  "SWTH": {
      "icao": "SWTH",
      "iata": "",
      "name": "Fazenda Trescinco Juruena Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 883,
      "lat": -10.2447223663,
      "lon": -58.2494430542,
      "tz": "America\/Cuiaba"
  },
  "SWTI": {
      "icao": "SWTI",
      "iata": "",
      "name": "Sao Vicente Airport",
      "city": "Nova Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 791,
      "lat": -14.5202999115,
      "lon": -59.7798995972,
      "tz": "America\/Cuiaba"
  },
  "SWTJ": {
      "icao": "SWTJ",
      "iata": "",
      "name": "Fazenda Cachoeira Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1070,
      "lat": -14.6033334732,
      "lon": -57.7972221375,
      "tz": "America\/Cuiaba"
  },
  "SWTK": {
      "icao": "SWTK",
      "iata": "",
      "name": "Fazenda Santa Maria do Guapore Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 797,
      "lat": -14.2019443512,
      "lon": -60.280834198,
      "tz": "America\/Cuiaba"
  },
  "SWTL": {
      "icao": "SWTL",
      "iata": "",
      "name": "Fazenda Corumbiara Airport",
      "city": "Pimenta Bueno",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 1371,
      "lat": -12.5005559921,
      "lon": -61.2836112976,
      "tz": "America\/Porto_Velho"
  },
  "SWTN": {
      "icao": "SWTN",
      "iata": "",
      "name": "Fazenda Arapucel Airport",
      "city": "Araputanga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1345,
      "lat": -15.0413894653,
      "lon": -58.7291679382,
      "tz": "America\/Cuiaba"
  },
  "SWTO": {
      "icao": "SWTO",
      "iata": "",
      "name": "ParaiSo do Tocantins Airport",
      "city": "Paraiso Do Tocantins",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1083,
      "lat": -10.174200058,
      "lon": -48.932800293,
      "tz": "America\/Araguaina"
  },
  "SWTP": {
      "icao": "SWTP",
      "iata": "IRZ",
      "name": "Tapuruquara Airport",
      "city": "Santa Isabel Do Rio Negro",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 223,
      "lat": -0.4169439971,
      "lon": -65.0338897705,
      "tz": "America\/Manaus"
  },
  "SWTQ": {
      "icao": "SWTQ",
      "iata": "",
      "name": "Termas Pousada do Rio Quente Airport",
      "city": "Rio Quente",
      "state": "Goias",
      "country": "BR",
      "elevation": 2247,
      "lat": -17.7686004639,
      "lon": -48.7616996765,
      "tz": "America\/Sao_Paulo"
  },
  "SWTR": {
      "icao": "SWTR",
      "iata": "",
      "name": "Taraqua Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "",
      "country": "BR",
      "elevation": 246,
      "lat": 0.1247219965,
      "lon": -68.5456008911,
      "tz": "America\/Manaus"
  },
  "SWTS": {
      "icao": "SWTS",
      "iata": "TGQ",
      "name": "Tangara da Serra Airport",
      "city": "Tangara Da Serra",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1460,
      "lat": -14.6619997025,
      "lon": -57.4435005188,
      "tz": "America\/Cuiaba"
  },
  "SWTU": {
      "icao": "SWTU",
      "iata": "AZL",
      "name": "Fazenda Tucunare Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1814,
      "lat": -13.4655278136,
      "lon": -58.8669347763,
      "tz": "America\/Cuiaba"
  },
  "SWTV": {
      "icao": "SWTV",
      "iata": "",
      "name": "Fazenda Rio Preto Airport",
      "city": "Salto Do Ceu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1354,
      "lat": -15.0622215271,
      "lon": -57.9686126709,
      "tz": "America\/Cuiaba"
  },
  "SWTW": {
      "icao": "SWTW",
      "iata": "",
      "name": "Fazenda Sao Lucas Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 833,
      "lat": -15.7063894272,
      "lon": -59.2416687012,
      "tz": "America\/Cuiaba"
  },
  "SWTX": {
      "icao": "SWTX",
      "iata": "",
      "name": "Fazenda Olho D`Agua Airport",
      "city": "Torixoreu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1201,
      "lat": -16.1452789307,
      "lon": -52.9713897705,
      "tz": "America\/Cuiaba"
  },
  "SWTY": {
      "icao": "SWTY",
      "iata": "QHN",
      "name": "Taguatinga Airport",
      "city": "Taguatinga",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1959,
      "lat": -12.433889389,
      "lon": -46.400554657,
      "tz": "America\/Araguaina"
  },
  "SWUA": {
      "icao": "SWUA",
      "iata": "SQM",
      "name": "Sao Miguel do Araguaia Airport",
      "city": "Sao Miguel Do Araguaia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1249,
      "lat": -13.3312997818,
      "lon": -50.1976013184,
      "tz": "America\/Sao_Paulo"
  },
  "SWUB": {
      "icao": "SWUB",
      "iata": "",
      "name": "Fazenda Serra Alegre Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1115,
      "lat": -15.6741666794,
      "lon": -59.1916656494,
      "tz": "America\/Cuiaba"
  },
  "SWUC": {
      "icao": "SWUC",
      "iata": "",
      "name": "Luciara Airport",
      "city": "Luciara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 689,
      "lat": -11.2002782822,
      "lon": -50.6838874817,
      "tz": "America\/Cuiaba"
  },
  "SWUE": {
      "icao": "SWUE",
      "iata": "",
      "name": "Fazenda Tapayuna Airport",
      "city": "Nova Canaa Do Norte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1197,
      "lat": -10.703332901,
      "lon": -55.936668396,
      "tz": "America\/Cuiaba"
  },
  "SWUI": {
      "icao": "SWUI",
      "iata": "",
      "name": "Pauini Airport",
      "city": "Pauini",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 397,
      "lat": -7.7224998474,
      "lon": -67.0283355713,
      "tz": "America\/Manaus"
  },
  "SWUJ": {
      "icao": "SWUJ",
      "iata": "",
      "name": "Tagua Agropecuaria Airport",
      "city": "Vera",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1230,
      "lat": -12.2049999237,
      "lon": -55.3777770996,
      "tz": "America\/Cuiaba"
  },
  "SWUK": {
      "icao": "SWUK",
      "iata": "",
      "name": "Uapui Cachoeira Airport",
      "city": "Sao Gabriel Da Cachoeira",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 249,
      "lat": 1.2163889408,
      "lon": -69.200553894,
      "tz": "America\/Manaus"
  },
  "SWUL": {
      "icao": "SWUL",
      "iata": "",
      "name": "Fazenda Furna Azul Airport",
      "city": "Ananas",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 407,
      "lat": -6.2180562019,
      "lon": -48.4213905334,
      "tz": "America\/Araguaina"
  },
  "SWUM": {
      "icao": "SWUM",
      "iata": "",
      "name": "Fazenda Rancho Novo Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -13.4425001144,
      "lon": -60.2727775574,
      "tz": "America\/Cuiaba"
  },
  "SWUN": {
      "icao": "SWUN",
      "iata": "",
      "name": "Fazenda Santa Eunice Airport",
      "city": "Nova Monte Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 886,
      "lat": -10.1719436646,
      "lon": -57.567779541,
      "tz": "America\/Cuiaba"
  },
  "SWUP": {
      "icao": "SWUP",
      "iata": "",
      "name": "Uirapuru Airport",
      "city": "Uirapuru",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1575,
      "lat": -14.0169439316,
      "lon": -59.3672218323,
      "tz": "America\/Cuiaba"
  },
  "SWUQ": {
      "icao": "SWUQ",
      "iata": "",
      "name": "Surucucu Airport",
      "city": "Alto Alegre",
      "state": "Roraima",
      "country": "BR",
      "elevation": 2854,
      "lat": 2.8352799416,
      "lon": -63.6469993591,
      "tz": "America\/Boa_Vista"
  },
  "SWUR": {
      "icao": "SWUR",
      "iata": "",
      "name": "Fazenda Vista Verde Airport",
      "city": "Palmeira Do Piaui",
      "state": "Piaui",
      "country": "BR",
      "elevation": 1945,
      "lat": -8.4916667938,
      "lon": -44.3894424438,
      "tz": "America\/Fortaleza"
  },
  "SWUS": {
      "icao": "SWUS",
      "iata": "",
      "name": "Sede Fazenda Sao Benedito Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1798,
      "lat": -14.2349996567,
      "lon": -57.7091674805,
      "tz": "America\/Cuiaba"
  },
  "SWUT": {
      "icao": "SWUT",
      "iata": "",
      "name": "Lavoura Fazenda Sao Benedito Airport",
      "city": "Campo Novo Do Parecis",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2001,
      "lat": -14.220000267,
      "lon": -57.7788887024,
      "tz": "America\/Cuiaba"
  },
  "SWUU": {
      "icao": "SWUU",
      "iata": "",
      "name": "Fazenda Uirapuru Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1148,
      "lat": -12.1336107254,
      "lon": -54.2005577087,
      "tz": "America\/Cuiaba"
  },
  "SWUW": {
      "icao": "SWUW",
      "iata": "",
      "name": "Fazenda Santa Barbara II Airport",
      "city": "Alto Paraguai",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 810,
      "lat": -15.1138887405,
      "lon": -56.8369445801,
      "tz": "America\/Cuiaba"
  },
  "SWUY": {
      "icao": "SWUY",
      "iata": "",
      "name": "Urucu Airport",
      "city": "Coari",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 210,
      "lat": -4.8842201233,
      "lon": -65.3554000854,
      "tz": "America\/Manaus"
  },
  "SWUZ": {
      "icao": "SWUZ",
      "iata": "",
      "name": "Brigadeiro Araripe Macedo Airport",
      "city": "Luziania",
      "state": "Goias",
      "country": "BR",
      "elevation": 3269,
      "lat": -16.2616996765,
      "lon": -47.9687004089,
      "tz": "America\/Sao_Paulo"
  },
  "SWVB": {
      "icao": "SWVB",
      "iata": "MTG",
      "name": "Vila Bela da Santissima Trindade Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 886,
      "lat": -15.0152778625,
      "lon": -59.9672203064,
      "tz": "America\/Cuiaba"
  },
  "SWVC": {
      "icao": "SWVC",
      "iata": "VLP",
      "name": "Vila Rica Airport",
      "city": "Vila Rica",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 892,
      "lat": -9.9794435501,
      "lon": -51.1422233582,
      "tz": "America\/Cuiaba"
  },
  "SWVD": {
      "icao": "SWVD",
      "iata": "",
      "name": "Fazenda Rio Verde Airport",
      "city": "Serranopolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 2592,
      "lat": -17.9691677094,
      "lon": -52.5777778625,
      "tz": "America\/Sao_Paulo"
  },
  "SWVE": {
      "icao": "SWVE",
      "iata": "",
      "name": "Fazenda Vista Alegre Airport",
      "city": "Bujari",
      "state": "Acre",
      "country": "BR",
      "elevation": 666,
      "lat": -9.788611412,
      "lon": -68.0072250366,
      "tz": "America\/Rio_Branco"
  },
  "SWVF": {
      "icao": "SWVF",
      "iata": "",
      "name": "Fazenda Sto Antonio Guapore - Sede Nova Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 709,
      "lat": -16.0094432831,
      "lon": -59.9280548096,
      "tz": "America\/Cuiaba"
  },
  "SWVH": {
      "icao": "SWVH",
      "iata": "",
      "name": "Fazenda Vaca Branca Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1149,
      "lat": -9.5244436264,
      "lon": -56.2397232056,
      "tz": "America\/Cuiaba"
  },
  "SWVI": {
      "icao": "SWVI",
      "iata": "",
      "name": "Fazenda Pirassununga Airport",
      "city": "Sao Valerio",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 787,
      "lat": -11.5177783966,
      "lon": -48.3111114502,
      "tz": "America\/Araguaina"
  },
  "SWVJ": {
      "icao": "SWVJ",
      "iata": "",
      "name": "Fazenda Uiapuru Airport",
      "city": "Nova Mutum",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1519,
      "lat": -13.6638889313,
      "lon": -56.0022201538,
      "tz": "America\/Cuiaba"
  },
  "SWVK": {
      "icao": "SWVK",
      "iata": "",
      "name": "Fazenda Vale do Rio Celeste Airport",
      "city": "Nova Ubirata",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1411,
      "lat": -13.0369443893,
      "lon": -55.3261108398,
      "tz": "America\/Cuiaba"
  },
  "SWVL": {
      "icao": "SWVL",
      "iata": "",
      "name": "Fazenda Vertente Airport",
      "city": "Santo Antonio Do Leverger",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2444,
      "lat": -15.8966665268,
      "lon": -55.3155555725,
      "tz": "America\/Cuiaba"
  },
  "SWVN": {
      "icao": "SWVN",
      "iata": "",
      "name": "Valdir Masutti Airport",
      "city": "Comodoro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2028,
      "lat": -13.6327781677,
      "lon": -59.7958335876,
      "tz": "America\/Cuiaba"
  },
  "SWVO": {
      "icao": "SWVO",
      "iata": "",
      "name": "Panorama Airport",
      "city": "Claudia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1017,
      "lat": -11.3702783585,
      "lon": -55.3805541992,
      "tz": "America\/Cuiaba"
  },
  "SWVP": {
      "icao": "SWVP",
      "iata": "",
      "name": "Fazenda Santa Isabel Airport",
      "city": "Cutias",
      "state": "Amapa",
      "country": "BR",
      "elevation": 33,
      "lat": 1.1566669941,
      "lon": -50.3302764893,
      "tz": "America\/Belem"
  },
  "SWVR": {
      "icao": "SWVR",
      "iata": "",
      "name": "Estancia Rosalia Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1460,
      "lat": -11.8999996185,
      "lon": -55.4591674805,
      "tz": "America\/Cuiaba"
  },
  "SWVS": {
      "icao": "SWVS",
      "iata": "",
      "name": "Fazenda Sto Antonio do Guapore - Baia do Padre Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 675,
      "lat": -15.859167099,
      "lon": -60.0447235107,
      "tz": "America\/Cuiaba"
  },
  "SWVT": {
      "icao": "SWVT",
      "iata": "",
      "name": "Fazenda Estrela D`Alva Airport",
      "city": "Querencia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1050,
      "lat": -12.1233329773,
      "lon": -52.6458320618,
      "tz": "America\/Cuiaba"
  },
  "SWVU": {
      "icao": "SWVU",
      "iata": "",
      "name": "Fazenda Ramalhete Airport",
      "city": "Rio Brilhante",
      "state": "",
      "country": "BR",
      "elevation": 1099,
      "lat": -21.7332992554,
      "lon": -54.5766983032,
      "tz": "America\/Campo_Grande"
  },
  "SWVV": {
      "icao": "SWVV",
      "iata": "",
      "name": "Fazenda Esperanca Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 692,
      "lat": -15.7519435883,
      "lon": -60.1047210693,
      "tz": "America\/Cuiaba"
  },
  "SWVY": {
      "icao": "SWVY",
      "iata": "",
      "name": "Fazenda Carvalho Airport",
      "city": "Corumba",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 361,
      "lat": -17.5680561066,
      "lon": -56.4880561829,
      "tz": "America\/Campo_Grande"
  },
  "SWVZ": {
      "icao": "SWVZ",
      "iata": "",
      "name": "EMAL - Empresa de Mineracao Aripuana Ltda Airport",
      "city": "Nobres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2362,
      "lat": -14.75,
      "lon": -56.3527793884,
      "tz": "America\/Cuiaba"
  },
  "SWWA": {
      "icao": "SWWA",
      "iata": "",
      "name": "Porangatu Airport",
      "city": "Porangatu",
      "state": "",
      "country": "BR",
      "elevation": 1201,
      "lat": -13.404800415,
      "lon": -49.1582984924,
      "tz": "America\/Sao_Paulo"
  },
  "SWWB": {
      "icao": "SWWB",
      "iata": "",
      "name": "Fazenda Rio Azul Airport",
      "city": "Claudia",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1083,
      "lat": -11.2577781677,
      "lon": -54.9269447327,
      "tz": "America\/Cuiaba"
  },
  "SWWD": {
      "icao": "SWWD",
      "iata": "",
      "name": "C.F. de Souza Bais Airport",
      "city": "Barcelos",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 207,
      "lat": -1.691388889,
      "lon": -63.785,
      "tz": "America\/Manaus"
  },
  "SWWF": {
      "icao": "SWWF",
      "iata": "",
      "name": "Hotel Baiazinha Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 676,
      "lat": -16.5697212219,
      "lon": -57.8313903809,
      "tz": "America\/Cuiaba"
  },
  "SWWG": {
      "icao": "SWWG",
      "iata": "",
      "name": "Fazenda Canamari Airport",
      "city": "Quirinopolis",
      "state": "Goias",
      "country": "BR",
      "elevation": 1699,
      "lat": -18.54806,
      "lon": -50.38111,
      "tz": "America\/Sao_Paulo"
  },
  "SWWI": {
      "icao": "SWWI",
      "iata": "",
      "name": "Fazenda Fortaleza Airport",
      "city": "General Carneiro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1995,
      "lat": -15.673333168,
      "lon": -53.5783348083,
      "tz": "America\/Cuiaba"
  },
  "SWWJ": {
      "icao": "SWWJ",
      "iata": "",
      "name": "Posto de Protecao Ambiental Nossa Senhora do Carmo Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 348,
      "lat": -16.6808338165,
      "lon": -56.2991676331,
      "tz": "America\/Cuiaba"
  },
  "SWWK": {
      "icao": "SWWK",
      "iata": "",
      "name": "Urucara Airport",
      "city": "Urucara",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 98,
      "lat": -2.5288898945,
      "lon": -57.7557983398,
      "tz": "America\/Manaus"
  },
  "SWWL": {
      "icao": "SWWL",
      "iata": "",
      "name": "Fazenda Agrocentro Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1119,
      "lat": -15.6967000961,
      "lon": -52.2639007568,
      "tz": "America\/Cuiaba"
  },
  "SWWM": {
      "icao": "SWWM",
      "iata": "",
      "name": "Fazenda Pupila Airport",
      "city": "Mantena",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 663,
      "lat": -18.771944046,
      "lon": -40.9494438171,
      "tz": "America\/Sao_Paulo"
  },
  "SWWN": {
      "icao": "SWWN",
      "iata": "",
      "name": "Fazenda Santa Amalia Airport",
      "city": "Alta Floresta",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1201,
      "lat": -10.3130559921,
      "lon": -56.5788879395,
      "tz": "America\/Cuiaba"
  },
  "SWWT": {
      "icao": "SWWT",
      "iata": "",
      "name": "Silvio Goncalves de Mello Airport",
      "city": "Morada Nova De Minas",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1988,
      "lat": -18.7055568695,
      "lon": -45.3355560303,
      "tz": "America\/Sao_Paulo"
  },
  "SWWU": {
      "icao": "SWWU",
      "iata": "",
      "name": "Uruacu Airport",
      "city": "Uruacu",
      "state": "Goias",
      "country": "BR",
      "elevation": 1706,
      "lat": -14.5255556107,
      "lon": -49.1377792358,
      "tz": "America\/Sao_Paulo"
  },
  "SWWV": {
      "icao": "SWWV",
      "iata": "",
      "name": "Posto de Protecao Ambiental Santa Maria Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 381,
      "lat": -16.7063884735,
      "lon": -56.0291671753,
      "tz": "America\/Cuiaba"
  },
  "SWWW": {
      "icao": "SWWW",
      "iata": "",
      "name": "Posto de Protecao Ambiental Sao Luiz Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 367,
      "lat": -16.6883335114,
      "lon": -56.1763877869,
      "tz": "America\/Cuiaba"
  },
  "SWWX": {
      "icao": "SWWX",
      "iata": "",
      "name": "Posto de Protecao Ambiental Sao Joaquim Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 338,
      "lat": -16.7461109161,
      "lon": -56.3699989319,
      "tz": "America\/Cuiaba"
  },
  "SWWY": {
      "icao": "SWWY",
      "iata": "",
      "name": "Posto de Protecao Ambiental Espirito Santo Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 354,
      "lat": -16.5872211456,
      "lon": -56.2799987793,
      "tz": "America\/Cuiaba"
  },
  "SWWZ": {
      "icao": "SWWZ",
      "iata": "",
      "name": "Darinha Airport",
      "city": "Cascavel",
      "state": "Ceara",
      "country": "BR",
      "elevation": 13,
      "lat": -4.1163887978,
      "lon": -38.250831604,
      "tz": "America\/Fortaleza"
  },
  "SWXA": {
      "icao": "SWXA",
      "iata": "",
      "name": "Fazenda Canadazinho Airport",
      "city": "Jussara",
      "state": "Goias",
      "country": "BR",
      "elevation": 889,
      "lat": -15.042222023,
      "lon": -51.400554657,
      "tz": "America\/Sao_Paulo"
  },
  "SWXC": {
      "icao": "SWXC",
      "iata": "",
      "name": "Fazenda Tropical Airport",
      "city": "Cerejeiras",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 981,
      "lat": -12.8860998154,
      "lon": -61.2983016968,
      "tz": "America\/Porto_Velho"
  },
  "SWXD": {
      "icao": "SWXD",
      "iata": "",
      "name": "Aerogardi Airport",
      "city": "Itapolis",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1762,
      "lat": -21.5611114502,
      "lon": -48.7986106873,
      "tz": "America\/Sao_Paulo"
  },
  "SWXG": {
      "icao": "SWXG",
      "iata": "",
      "name": "Xingu Airport",
      "city": "Barra Do Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1000,
      "lat": -12.0055561066,
      "lon": -53.4022216797,
      "tz": "America\/Cuiaba"
  },
  "SWXH": {
      "icao": "SWXH",
      "iata": "",
      "name": "Fazenda Sao Sebastiao Airport",
      "city": "Sao Desiderio",
      "state": "",
      "country": "BR",
      "elevation": 2999,
      "lat": -13.0633325577,
      "lon": -46.2572212219,
      "tz": "America\/Bahia"
  },
  "SWXJ": {
      "icao": "SWXJ",
      "iata": "",
      "name": "Chacara Sao Jose Airport",
      "city": "Cuiaba",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 568,
      "lat": -15.5419435501,
      "lon": -55.9838905334,
      "tz": "America\/Cuiaba"
  },
  "SWXK": {
      "icao": "SWXK",
      "iata": "",
      "name": "Fazenda Rio Alegre Airport",
      "city": "Sao Jose Do Rio Claro",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1240,
      "lat": -13.2742004395,
      "lon": -56.8922996521,
      "tz": "America\/Cuiaba"
  },
  "SWXL": {
      "icao": "SWXL",
      "iata": "",
      "name": "Fazenda Lago Grande Airport",
      "city": "Sandolandia",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 600,
      "lat": -12.1013889313,
      "lon": -49.9511108398,
      "tz": "America\/Araguaina"
  },
  "SWXM": {
      "icao": "SWXM",
      "iata": "MBK",
      "name": "Regional Orlando Villas Boas Airport",
      "city": "Matupa",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 886,
      "lat": -10.1702775955,
      "lon": -54.9527778625,
      "tz": "America\/Cuiaba"
  },
  "SWXO": {
      "icao": "SWXO",
      "iata": "",
      "name": "Fazenda Porto Seguro Airport",
      "city": "Sao Felix Do Xingu",
      "state": "Para",
      "country": "BR",
      "elevation": 800,
      "lat": -7.1316671371,
      "lon": -52.7272224426,
      "tz": "America\/Santarem"
  },
  "SWXP": {
      "icao": "SWXP",
      "iata": "",
      "name": "Fazenda Santa Luzia Airport",
      "city": "Sapezal",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1949,
      "lat": -13.7327775955,
      "lon": -58.8880577087,
      "tz": "America\/Cuiaba"
  },
  "SWXR": {
      "icao": "SWXR",
      "iata": "",
      "name": "Faria Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 787,
      "lat": -15.6458330154,
      "lon": -58.7727775574,
      "tz": "America\/Cuiaba"
  },
  "SWXS": {
      "icao": "SWXS",
      "iata": "",
      "name": "Fazenda Sao Joao do Ibipora Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 951,
      "lat": -15.7899999619,
      "lon": -59.636390686,
      "tz": "America\/Cuiaba"
  },
  "SWXT": {
      "icao": "SWXT",
      "iata": "",
      "name": "Fazenda Medalha Milagrosa Airport",
      "city": "Vila Bela Da Santissima Trindade",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 853,
      "lat": -16.1016674042,
      "lon": -60.0649986267,
      "tz": "America\/Cuiaba"
  },
  "SWXU": {
      "icao": "SWXU",
      "iata": "",
      "name": "Xapuri Airport",
      "city": "Xapuri",
      "state": "Acre",
      "country": "BR",
      "elevation": 492,
      "lat": -10.6336107254,
      "lon": -68.5505523682,
      "tz": "America\/Rio_Branco"
  },
  "SWXV": {
      "icao": "SWXV",
      "iata": "NOK",
      "name": "Xavantina Airport",
      "city": "Nova Xavantina",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1035,
      "lat": -14.6983003616,
      "lon": -52.3464012146,
      "tz": "America\/Cuiaba"
  },
  "SWXW": {
      "icao": "SWXW",
      "iata": "",
      "name": "Fazenda Sao Joao do Guapore Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 722,
      "lat": -15.9708328247,
      "lon": -59.8911094666,
      "tz": "America\/Cuiaba"
  },
  "SWXY": {
      "icao": "SWXY",
      "iata": "",
      "name": "Fazenda Transoeste Airport",
      "city": "Santa Terezinha",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 869,
      "lat": -10.3508329391,
      "lon": -50.6708335876,
      "tz": "America\/Cuiaba"
  },
  "SWXZ": {
      "icao": "SWXZ",
      "iata": "",
      "name": "Fazenda Taquarussu Airport",
      "city": "Comodoro",
      "state": "Rondonia",
      "country": "BR",
      "elevation": 899,
      "lat": -13.3125,
      "lon": -60.2933311462,
      "tz": "America\/Cuiaba"
  },
  "SWYA": {
      "icao": "SWYA",
      "iata": "",
      "name": "Fazenda Nossa Senhora Aparecida Airport",
      "city": "Jaciara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1082,
      "lat": -16.0441665649,
      "lon": -54.9516677856,
      "tz": "America\/Cuiaba"
  },
  "SWYC": {
      "icao": "SWYC",
      "iata": "",
      "name": "Jair Feliciano de Deus Airport",
      "city": "Mirassol D`Oeste",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 849,
      "lat": -15.6872215271,
      "lon": -58.1113891602,
      "tz": "America\/Cuiaba"
  },
  "SWYG": {
      "icao": "SWYG",
      "iata": "",
      "name": "Sao Jose Airport",
      "city": "Aracruz",
      "state": "Espirito-Santo",
      "country": "BR",
      "elevation": 187,
      "lat": -19.6216678619,
      "lon": -40.2011108398,
      "tz": "America\/Sao_Paulo"
  },
  "SWYH": {
      "icao": "SWYH",
      "iata": "",
      "name": "Fazenda Alianca Airport",
      "city": "Araputanga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -15.2924995422,
      "lon": -58.3144454956,
      "tz": "America\/Cuiaba"
  },
  "SWYI": {
      "icao": "SWYI",
      "iata": "",
      "name": "Barragem Manoel Alves Airport",
      "city": "Dianopolis",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 2067,
      "lat": -11.5516672134,
      "lon": -46.9838905334,
      "tz": "America\/Araguaina"
  },
  "SWYJ": {
      "icao": "SWYJ",
      "iata": "",
      "name": "Fazenda Agropecuaria FR Ltda. Airport",
      "city": "Novo Progresso",
      "state": "Para",
      "country": "BR",
      "elevation": 860,
      "lat": -7.6224999428,
      "lon": -56.0683326721,
      "tz": "America\/Santarem"
  },
  "SWYK": {
      "icao": "SWYK",
      "iata": "",
      "name": "Fazenda Ilha Camargo Airport",
      "city": "Pocone",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 358,
      "lat": -17.0625991821,
      "lon": -56.5836982727,
      "tz": "America\/Cuiaba"
  },
  "SWYM": {
      "icao": "SWYM",
      "iata": "",
      "name": "Fazenda Anhanguera Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 951,
      "lat": -14.6424999237,
      "lon": -59.4491004944,
      "tz": "America\/Cuiaba"
  },
  "SWYN": {
      "icao": "SWYN",
      "iata": "",
      "name": "Prainha Airport",
      "city": "Apui",
      "state": "Amazonas",
      "country": "BR",
      "elevation": 197,
      "lat": -7.1728701591,
      "lon": -59.8395996094,
      "tz": "America\/Manaus"
  },
  "SWYO": {
      "icao": "SWYO",
      "iata": "",
      "name": "Usina Branco Peres Airport",
      "city": "Adamantina",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1335,
      "lat": -21.4869441986,
      "lon": -51.025554657,
      "tz": "America\/Sao_Paulo"
  },
  "SWYP": {
      "icao": "SWYP",
      "iata": "",
      "name": "Fazenda Ribeiro do Ceu Airport",
      "city": "Nova Mutum",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1493,
      "lat": -13.9527778625,
      "lon": -55.7783317566,
      "tz": "America\/Cuiaba"
  },
  "SWYQ": {
      "icao": "SWYQ",
      "iata": "",
      "name": "Fazenda Nova Fronteira Airport",
      "city": "Sinop",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1017,
      "lat": -11.4678001404,
      "lon": -56.4375991821,
      "tz": "America\/Cuiaba"
  },
  "SWYR": {
      "icao": "SWYR",
      "iata": "",
      "name": "Fazenda Nossa Senhora do Pillar Airport",
      "city": "Jauru",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1001,
      "lat": -15.3458328247,
      "lon": -58.7952766418,
      "tz": "America\/Cuiaba"
  },
  "SWYT": {
      "icao": "SWYT",
      "iata": "",
      "name": "Fazenda Formosa Airport",
      "city": "Novo Acordo",
      "state": "Tocantins",
      "country": "BR",
      "elevation": 1132,
      "lat": -10.0130558014,
      "lon": -47.6816673279,
      "tz": "America\/Araguaina"
  },
  "SWYU": {
      "icao": "SWYU",
      "iata": "",
      "name": "Fazenda Entre Rios Airport",
      "city": "Aruana",
      "state": "Goias",
      "country": "BR",
      "elevation": 781,
      "lat": -14.4433326721,
      "lon": -50.8666687012,
      "tz": "America\/Sao_Paulo"
  },
  "SWYV": {
      "icao": "SWYV",
      "iata": "",
      "name": "Clube de Marte Ibira de Para-Quedismo Airport",
      "city": "Ibira",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1591,
      "lat": -21.0913887024,
      "lon": -49.2241668701,
      "tz": "America\/Sao_Paulo"
  },
  "SWYW": {
      "icao": "SWYW",
      "iata": "",
      "name": "Fazenda Adriana Airport",
      "city": "Alto Garcas",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2241,
      "lat": -16.834444046,
      "lon": -53.8352775574,
      "tz": "America\/Cuiaba"
  },
  "SWYY": {
      "icao": "SWYY",
      "iata": "",
      "name": "Fazenda Nova Viena Airport",
      "city": "Nova Xavantina",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 994,
      "lat": -14.8877782822,
      "lon": -52.2769432068,
      "tz": "America\/Cuiaba"
  },
  "SWZA": {
      "icao": "SWZA",
      "iata": "",
      "name": "Agrosan Airport",
      "city": "Juara",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -11.366944313,
      "lon": -58.1172218323,
      "tz": "America\/Cuiaba"
  },
  "SWZB": {
      "icao": "SWZB",
      "iata": "",
      "name": "Fazenda Arara Azul Airport",
      "city": "Barao De Melgaco",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 459,
      "lat": -17.0019435883,
      "lon": -56.4602775574,
      "tz": "America\/Cuiaba"
  },
  "SWZC": {
      "icao": "SWZC",
      "iata": "",
      "name": "Fazenda Corixo Airport",
      "city": "Porto Esperidiao",
      "state": "Santa-Cruz",
      "country": "BR",
      "elevation": 492,
      "lat": -16.2458324432,
      "lon": -58.894443512,
      "tz": "America\/Cuiaba"
  },
  "SWZD": {
      "icao": "SWZD",
      "iata": "",
      "name": "Fazenda Denusa Destilaria Nova Uniao Airport",
      "city": "Jandaia",
      "state": "Goias",
      "country": "BR",
      "elevation": 1699,
      "lat": -17.2558326721,
      "lon": -50.1422233582,
      "tz": "America\/Sao_Paulo"
  },
  "SWZE": {
      "icao": "SWZE",
      "iata": "",
      "name": "Fazenda Tamboril Airport",
      "city": "Pontes E Lacerda",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1099,
      "lat": -15.0622215271,
      "lon": -59.374168396,
      "tz": "America\/Cuiaba"
  },
  "SWZF": {
      "icao": "SWZF",
      "iata": "",
      "name": "Fazenda Araguari Airport",
      "city": "Araputanga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 902,
      "lat": -15.4947223663,
      "lon": -58.3994445801,
      "tz": "America\/Cuiaba"
  },
  "SWZG": {
      "icao": "SWZG",
      "iata": "",
      "name": "Fazenda Lagoa Encantada Airport",
      "city": "Porto Esperidiao",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 800,
      "lat": -16.0191669464,
      "lon": -58.8875007629,
      "tz": "America\/Cuiaba"
  },
  "SWZH": {
      "icao": "SWZH",
      "iata": "",
      "name": "Fazenda Cacapava Airport",
      "city": "Acreuna",
      "state": "Goias",
      "country": "BR",
      "elevation": 1918,
      "lat": -17.5750007629,
      "lon": -50.1749992371,
      "tz": "America\/Sao_Paulo"
  },
  "SWZJ": {
      "icao": "SWZJ",
      "iata": "",
      "name": "Fazenda Sao Joao Airport",
      "city": "Crixas",
      "state": "Goias",
      "country": "BR",
      "elevation": 1033,
      "lat": -14.5472221375,
      "lon": -50.6536102295,
      "tz": "America\/Sao_Paulo"
  },
  "SWZL": {
      "icao": "SWZL",
      "iata": "",
      "name": "Fazenda Americana Airport",
      "city": "Itambacuri",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 1106,
      "lat": -18.146944046,
      "lon": -41.7144432068,
      "tz": "America\/Sao_Paulo"
  },
  "SWZM": {
      "icao": "SWZM",
      "iata": "",
      "name": "Usina Sao Simao Airport",
      "city": "Sao Simao",
      "state": "Goias",
      "country": "BR",
      "elevation": 1716,
      "lat": -18.9864997864,
      "lon": -50.5635986328,
      "tz": "America\/Sao_Paulo"
  },
  "SWZN": {
      "icao": "SWZN",
      "iata": "",
      "name": "Fazenda Frances Airport",
      "city": "Acreuna",
      "state": "Goias",
      "country": "BR",
      "elevation": 2210,
      "lat": -17.322221756,
      "lon": -50.4258346558,
      "tz": "America\/Sao_Paulo"
  },
  "SWZO": {
      "icao": "SWZO",
      "iata": "",
      "name": "Fazenda Lagoa Funda Airport",
      "city": "Campo Verde",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 2165,
      "lat": -15.3427782059,
      "lon": -55.136390686,
      "tz": "America\/Cuiaba"
  },
  "SWZP": {
      "icao": "SWZP",
      "iata": "",
      "name": "Fazenda Vale Rico Airport",
      "city": "Guiratinga",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1050,
      "lat": -16.3858337402,
      "lon": -54.2022209167,
      "tz": "America\/Cuiaba"
  },
  "SWZQ": {
      "icao": "SWZQ",
      "iata": "",
      "name": "Fazenda Jussara Airport",
      "city": "Jussara",
      "state": "Goias",
      "country": "BR",
      "elevation": 1344,
      "lat": -15.8452777863,
      "lon": -50.0719451904,
      "tz": "America\/Sao_Paulo"
  },
  "SWZR": {
      "icao": "SWZR",
      "iata": "",
      "name": "Fazenda Santa Rita Airport",
      "city": "Itapirapua",
      "state": "Goias",
      "country": "BR",
      "elevation": 965,
      "lat": -15.3838891983,
      "lon": -51.0338897705,
      "tz": "America\/Sao_Paulo"
  },
  "SWZS": {
      "icao": "SWZS",
      "iata": "",
      "name": "Fazenda Nova Larga Airport",
      "city": "Caceres",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 787,
      "lat": -16.6583328247,
      "lon": -57.6708335876,
      "tz": "America\/Cuiaba"
  },
  "SWZT": {
      "icao": "SWZT",
      "iata": "",
      "name": "Fazenda do Brejo Airport",
      "city": "Paraopeba",
      "state": "Minas-Gerais",
      "country": "BR",
      "elevation": 2451,
      "lat": -19.2549991608,
      "lon": -44.4733314514,
      "tz": "America\/Sao_Paulo"
  },
  "SWZU": {
      "icao": "SWZU",
      "iata": "",
      "name": "Fazenda Rodoserv I Airport",
      "city": "Ivinhema",
      "state": "Sao-Paulo",
      "country": "BR",
      "elevation": 1001,
      "lat": -22.4727783203,
      "lon": -52.5958328247,
      "tz": "America\/Sao_Paulo"
  },
  "SWZV": {
      "icao": "SWZV",
      "iata": "",
      "name": "Fazenda Rodoserv III Airport",
      "city": "Bela Vista",
      "state": "Mato-Grosso-do-Sul",
      "country": "BR",
      "elevation": 1001,
      "lat": -21.8622226715,
      "lon": -56.7738876343,
      "tz": "America\/Campo_Grande"
  },
  "SWZW": {
      "icao": "SWZW",
      "iata": "",
      "name": "Fazenda Santa Fe do Xingu Airport",
      "city": "Sao Jose Do Xingu",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 1036,
      "lat": -10.3211107254,
      "lon": -52.2105560303,
      "tz": "America\/Cuiaba"
  },
  "SWZY": {
      "icao": "SWZY",
      "iata": "",
      "name": "Fazenda Sete Estrelas Airport",
      "city": "Brasnorte",
      "state": "Mato-Grosso",
      "country": "BR",
      "elevation": 984,
      "lat": -11.5772218704,
      "lon": -58.2341651917,
      "tz": "America\/Cuiaba"
  },
  "SYAH": {
      "icao": "SYAH",
      "iata": "AHL",
      "name": "Aishalton Airport",
      "city": "Aishalton",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 587,
      "lat": 2.4865300655,
      "lon": -59.3134002686,
      "tz": "America\/Guyana"
  },
  "SYAN": {
      "icao": "SYAN",
      "iata": "NAI",
      "name": "Annai Airport",
      "city": "Annai",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 301,
      "lat": 3.9594399929,
      "lon": -59.1241989136,
      "tz": "America\/Guyana"
  },
  "SYAP": {
      "icao": "SYAP",
      "iata": "",
      "name": "Apoteri Airport",
      "city": "Apoteri",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 301,
      "lat": 4.013879776,
      "lon": -58.6049995422,
      "tz": "America\/Guyana"
  },
  "SYAW": {
      "icao": "SYAW",
      "iata": "",
      "name": "Awaruwaunau Airport",
      "city": "Awaruwaunau",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 797,
      "lat": 2.6500000954,
      "lon": -59.2000007629,
      "tz": "America\/Guyana"
  },
  "SYBE": {
      "icao": "SYBE",
      "iata": "BCG",
      "name": "Bemichi Airport",
      "city": "Bemichi",
      "state": "Barima-Waini",
      "country": "GY",
      "elevation": 94,
      "lat": 7.6999998093,
      "lon": -59.1666603088,
      "tz": "America\/Guyana"
  },
  "SYBR": {
      "icao": "SYBR",
      "iata": "BMJ",
      "name": "Baramita Airport",
      "city": "Baramita",
      "state": "Barima-Waini",
      "country": "GY",
      "elevation": 328,
      "lat": 7.3701200485,
      "lon": -60.4879989624,
      "tz": "America\/Guyana"
  },
  "SYBT": {
      "icao": "SYBT",
      "iata": "GFO",
      "name": "Bartica A Airport",
      "city": "Bartica",
      "state": "Cuyuni-Mazaruni",
      "country": "GY",
      "elevation": 3,
      "lat": 6.3747701645,
      "lon": -58.6380996704,
      "tz": "America\/Guyana"
  },
  "SYCJ": {
      "icao": "SYCJ",
      "iata": "GEO",
      "name": "Cheddi Jagan International Airport",
      "city": "Georgetown",
      "state": "Demerara-Mahaica",
      "country": "GY",
      "elevation": 95,
      "lat": 6.4985499382,
      "lon": -58.2541007996,
      "tz": "America\/Guyana"
  },
  "SYEB": {
      "icao": "SYEB",
      "iata": "",
      "name": "Ebini Airport",
      "city": "Ebini",
      "state": "Upper-Demerara-Berbice",
      "country": "GY",
      "elevation": 147,
      "lat": 5.617000103,
      "lon": -57.766998291,
      "tz": "America\/Guyana"
  },
  "SYGO": {
      "icao": "SYGO",
      "iata": "OGL",
      "name": "Ogle Airport",
      "city": "Ogle",
      "state": "Demerara-Mahaica",
      "country": "GY",
      "elevation": 10,
      "lat": 6.8062801361,
      "lon": -58.1058998108,
      "tz": "America\/Guyana"
  },
  "SYIB": {
      "icao": "SYIB",
      "iata": "IMB",
      "name": "Imbaimadai Airport",
      "city": "Imbaimadai",
      "state": "Cuyuni-Mazaruni",
      "country": "GY",
      "elevation": 1646,
      "lat": 5.7081098557,
      "lon": -60.2942008972,
      "tz": "America\/Guyana"
  },
  "SYKK": {
      "icao": "SYKK",
      "iata": "",
      "name": "Kurukabaru Airport",
      "city": "Kurukabaru",
      "state": "",
      "country": "GY",
      "elevation": 3198,
      "lat": 4.7170000076,
      "lon": -59.983001709,
      "tz": "America\/Guyana"
  },
  "SYKM": {
      "icao": "SYKM",
      "iata": "KAR",
      "name": "Kamarang Airport",
      "city": "Kamarang",
      "state": "Cuyuni-Mazaruni",
      "country": "GY",
      "elevation": 1601,
      "lat": 5.8653402328,
      "lon": -60.614200592,
      "tz": "America\/Guyana"
  },
  "SYKR": {
      "icao": "SYKR",
      "iata": "KRM",
      "name": "Karanambo Airport",
      "city": "Karanambo",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 300,
      "lat": 3.751940012,
      "lon": -59.3097000122,
      "tz": "America\/Guyana"
  },
  "SYKS": {
      "icao": "SYKS",
      "iata": "KRG",
      "name": "Karasabai Airport",
      "city": "Karasabai",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 731,
      "lat": 4.0333299637,
      "lon": -59.5332984924,
      "tz": "America\/Guyana"
  },
  "SYKT": {
      "icao": "SYKT",
      "iata": "KTO",
      "name": "Kato Airport",
      "city": "Kato",
      "state": "Potaro-Siparuni",
      "country": "GY",
      "elevation": 2299,
      "lat": 4.6491599083,
      "lon": -59.8321990967,
      "tz": "America\/Guyana"
  },
  "SYKW": {
      "icao": "SYKW",
      "iata": "",
      "name": "Kwakwani Airport",
      "city": "Kwakwani",
      "state": "Upper-Demerara-Berbice",
      "country": "GY",
      "elevation": 147,
      "lat": 5.2833299637,
      "lon": -58.0499992371,
      "tz": "America\/Guyana"
  },
  "SYLD": {
      "icao": "SYLD",
      "iata": "",
      "name": "Linden Airport",
      "city": "Linden",
      "state": "Upper-Demerara-Berbice",
      "country": "GY",
      "elevation": 180,
      "lat": 5.9659199715,
      "lon": -58.2703018188,
      "tz": "America\/Guyana"
  },
  "SYLP": {
      "icao": "SYLP",
      "iata": "LUB",
      "name": "Lumid Pau Airport",
      "city": "Lumid Pau",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 656,
      "lat": 2.3939299583,
      "lon": -59.4410018921,
      "tz": "America\/Guyana"
  },
  "SYLT": {
      "icao": "SYLT",
      "iata": "LTM",
      "name": "Lethem Airport",
      "city": "Lethem",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 351,
      "lat": 3.3727600574,
      "lon": -59.7893981934,
      "tz": "America\/Boa_Vista"
  },
  "SYMB": {
      "icao": "SYMB",
      "iata": "USI",
      "name": "Mabaruma Airport",
      "city": "Mabaruma",
      "state": "Barima-Waini",
      "country": "GY",
      "elevation": 45,
      "lat": 8.1999998093,
      "lon": -59.7832984924,
      "tz": "America\/Guyana"
  },
  "SYMD": {
      "icao": "SYMD",
      "iata": "MHA",
      "name": "Mahdia Airport",
      "city": "Mahdia",
      "state": "Potaro-Siparuni",
      "country": "GY",
      "elevation": 300,
      "lat": 5.277490139,
      "lon": -59.1511001587,
      "tz": "America\/Guyana"
  },
  "SYMM": {
      "icao": "SYMM",
      "iata": "MYM",
      "name": "Monkey Mountain Airport",
      "city": "Monkey Mountain",
      "state": "Potaro-Siparuni",
      "country": "GY",
      "elevation": 1456,
      "lat": 4.4833297729,
      "lon": -59.6833000183,
      "tz": "America\/Guyana"
  },
  "SYMN": {
      "icao": "SYMN",
      "iata": "",
      "name": "Manari Airport",
      "city": "Manari",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 300,
      "lat": 3.4330000877,
      "lon": -59.5670013428,
      "tz": "America\/Guyana"
  },
  "SYMP": {
      "icao": "SYMP",
      "iata": "",
      "name": "Mountain Point Airport",
      "city": "Mountain Point",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 400,
      "lat": 3,
      "lon": -59.6329994202,
      "tz": "America\/Guyana"
  },
  "SYMR": {
      "icao": "SYMR",
      "iata": "MWJ",
      "name": "Matthews Ridge Airport",
      "city": "Matthews Ridge",
      "state": "Barima-Waini",
      "country": "GY",
      "elevation": 324,
      "lat": 7.488112,
      "lon": -60.184777,
      "tz": "America\/Guyana"
  },
  "SYMW": {
      "icao": "SYMW",
      "iata": "",
      "name": "Marurawana Airport",
      "city": "Marurawana",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 1017,
      "lat": 2.7297699451,
      "lon": -59.1497001648,
      "tz": "America\/Guyana"
  },
  "SYNA": {
      "icao": "SYNA",
      "iata": "QSX",
      "name": "New Amsterdam Airport",
      "city": "New Amsterdam",
      "state": "East-Berbice-Corentyne",
      "country": "GY",
      "elevation": 3,
      "lat": 6.244324,
      "lon": -57.474172,
      "tz": "America\/Guyana"
  },
  "SYOR": {
      "icao": "SYOR",
      "iata": "ORJ",
      "name": "Orinduik Airport",
      "city": "Orinduik",
      "state": "",
      "country": "GY",
      "elevation": 1797,
      "lat": 4.7252697945,
      "lon": -60.0349998474,
      "tz": "America\/Boa_Vista"
  },
  "SYPR": {
      "icao": "SYPR",
      "iata": "PRR",
      "name": "Paruma Airport",
      "city": "Paruma",
      "state": "Cuyuni-Mazaruni",
      "country": "GY",
      "elevation": 1765,
      "lat": 5.8154501915,
      "lon": -61.0554008484,
      "tz": "America\/Guyana"
  },
  "SYWI": {
      "icao": "SYWI",
      "iata": "",
      "name": "Wichabai Airport",
      "city": "Wichabai",
      "state": "Upper-Takutu-Upper-Essequibo",
      "country": "GY",
      "elevation": 518,
      "lat": 2.8666698933,
      "lon": -59.5332984924,
      "tz": "America\/Guyana"
  },
  "TA00": {
      "icao": "TA00",
      "iata": "",
      "name": "Rafter P Airport",
      "city": "Sweetwater",
      "state": "Texas",
      "country": "US",
      "elevation": 2190,
      "lat": 32.3842010498,
      "lon": -100.2789993286,
      "tz": "America\/Chicago"
  },
  "TA01": {
      "icao": "TA01",
      "iata": "",
      "name": "Phillips Farm Airport",
      "city": "Alvarado",
      "state": "",
      "country": "US",
      "elevation": 800,
      "lat": 32.3418006897,
      "lon": -97.2502975464,
      "tz": "America\/Chicago"
  },
  "TA02": {
      "icao": "TA02",
      "iata": "",
      "name": "Howard Field",
      "city": "Rhome",
      "state": "Texas",
      "country": "US",
      "elevation": 960,
      "lat": 33.1029014587,
      "lon": -97.4642028809,
      "tz": "America\/Chicago"
  },
  "TA03": {
      "icao": "TA03",
      "iata": "",
      "name": "Jo Na Acres Airport",
      "city": "Brookshire",
      "state": "Texas",
      "country": "US",
      "elevation": 160,
      "lat": 29.758600235,
      "lon": -95.9085998535,
      "tz": "America\/Chicago"
  },
  "TA05": {
      "icao": "TA05",
      "iata": "",
      "name": "Anxiety Aerodrome",
      "city": "Corsicana",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 32.0377006531,
      "lon": -96.5317001343,
      "tz": "America\/Chicago"
  },
  "TA06": {
      "icao": "TA06",
      "iata": "",
      "name": "Morris Manor Airport",
      "city": "Cumby",
      "state": "Texas",
      "country": "US",
      "elevation": 610,
      "lat": 33.1612014771,
      "lon": -95.8058013916,
      "tz": "America\/Chicago"
  },
  "TA07": {
      "icao": "TA07",
      "iata": "",
      "name": "Jet Ag Inc Airport",
      "city": "Dayton",
      "state": "Texas",
      "country": "US",
      "elevation": 70,
      "lat": 30.0072002411,
      "lon": -94.9669036865,
      "tz": "America\/Chicago"
  },
  "TA08": {
      "icao": "TA08",
      "iata": "",
      "name": "Flying M Airport",
      "city": "Floyd",
      "state": "Texas",
      "country": "US",
      "elevation": 585,
      "lat": 33.1496009827,
      "lon": -96.2739028931,
      "tz": "America\/Chicago"
  },
  "TA11": {
      "icao": "TA11",
      "iata": "",
      "name": "Tsa Gliderport",
      "city": "Midlothian",
      "state": "Texas",
      "country": "US",
      "elevation": 660,
      "lat": 32.3834991455,
      "lon": -97.0141983032,
      "tz": "America\/Chicago"
  },
  "TA13": {
      "icao": "TA13",
      "iata": "",
      "name": "Nebtex Land Co. Airport",
      "city": "Texline",
      "state": "Texas",
      "country": "US",
      "elevation": 4373,
      "lat": 36.256401062,
      "lon": -102.8700027466,
      "tz": "America\/Chicago"
  },
  "TA16": {
      "icao": "TA16",
      "iata": "",
      "name": "Travis Field Airport",
      "city": "Spur",
      "state": "Texas",
      "country": "US",
      "elevation": 2317,
      "lat": 33.442864,
      "lon": -100.854572,
      "tz": "America\/Chicago"
  },
  "TA17": {
      "icao": "TA17",
      "iata": "",
      "name": "Live Oak Ranch Airport",
      "city": "Brenham",
      "state": "Texas",
      "country": "US",
      "elevation": 387,
      "lat": 30.1872005463,
      "lon": -96.4332962036,
      "tz": "America\/Chicago"
  },
  "TA18": {
      "icao": "TA18",
      "iata": "",
      "name": "Sunset Airport",
      "city": "Forney",
      "state": "Texas",
      "country": "US",
      "elevation": 444,
      "lat": 32.7070999146,
      "lon": -96.4794006348,
      "tz": "America\/Chicago"
  },
  "TA19": {
      "icao": "TA19",
      "iata": "",
      "name": "Post Oak Airfield",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 1020,
      "lat": 32.6745986938,
      "lon": -97.7835998535,
      "tz": "America\/Chicago"
  },
  "TA21": {
      "icao": "TA21",
      "iata": "",
      "name": "Windmill Hill Airport",
      "city": "Lewisville",
      "state": "Texas",
      "country": "US",
      "elevation": 620,
      "lat": 33.0181999207,
      "lon": -97.1192016602,
      "tz": "America\/Chicago"
  },
  "TA22": {
      "icao": "TA22",
      "iata": "",
      "name": "Reward Ranch Airport",
      "city": "Bonham",
      "state": "Texas",
      "country": "US",
      "elevation": 615,
      "lat": 33.7672996521,
      "lon": -96.12159729,
      "tz": "America\/Chicago"
  },
  "TA23": {
      "icao": "TA23",
      "iata": "",
      "name": "Morris Lazy K Ranch Airport",
      "city": "Marquez",
      "state": "Texas",
      "country": "US",
      "elevation": 360,
      "lat": 31.2612991333,
      "lon": -96.2164001465,
      "tz": "America\/Chicago"
  },
  "TA24": {
      "icao": "TA24",
      "iata": "",
      "name": "Smoky Bend Airport",
      "city": "Mullin",
      "state": "Texas",
      "country": "US",
      "elevation": 1320,
      "lat": 31.4752006531,
      "lon": -98.7114028931,
      "tz": "America\/Chicago"
  },
  "TA25": {
      "icao": "TA25",
      "iata": "",
      "name": "Cook Canyon Ranch Airport",
      "city": "Ranger",
      "state": "Texas",
      "country": "US",
      "elevation": 1476,
      "lat": 32.431734,
      "lon": -98.594762,
      "tz": "America\/Chicago"
  },
  "TA26": {
      "icao": "TA26",
      "iata": "",
      "name": "Coyote Crossing Airport",
      "city": "Maypearl",
      "state": "Texas",
      "country": "US",
      "elevation": 580,
      "lat": 32.2963981628,
      "lon": -97.0568008423,
      "tz": "America\/Chicago"
  },
  "TA27": {
      "icao": "TA27",
      "iata": "",
      "name": "Flying 'K' Airport",
      "city": "Briggs",
      "state": "Texas",
      "country": "US",
      "elevation": 1132,
      "lat": 30.8379993439,
      "lon": -97.950302124,
      "tz": "America\/Chicago"
  },
  "TA28": {
      "icao": "TA28",
      "iata": "",
      "name": "Woods Nr 2 Airport",
      "city": "Brookshire",
      "state": "Texas",
      "country": "US",
      "elevation": 160,
      "lat": 29.7936000824,
      "lon": -95.925201416,
      "tz": "America\/Chicago"
  },
  "TA29": {
      "icao": "TA29",
      "iata": "",
      "name": "Flying D Ranch Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1630,
      "lat": 29.9258003235,
      "lon": -100.8399963379,
      "tz": "America\/Chicago"
  },
  "TA30": {
      "icao": "TA30",
      "iata": "",
      "name": "Old Forker Ultralightport",
      "city": "Sante Fe",
      "state": "Texas",
      "country": "US",
      "elevation": 15,
      "lat": 29.3187999725,
      "lon": -95.0858001709,
      "tz": "America\/Chicago"
  },
  "TA31": {
      "icao": "TA31",
      "iata": "",
      "name": "Tularosa Airport",
      "city": "Brackettville",
      "state": "Texas",
      "country": "US",
      "elevation": 1397,
      "lat": 29.4305000305,
      "lon": -100.2580032349,
      "tz": "America\/Chicago"
  },
  "TA33": {
      "icao": "TA33",
      "iata": "",
      "name": "Meyer Field",
      "city": "Rosharon",
      "state": "Texas",
      "country": "US",
      "elevation": 65,
      "lat": 29.3579998016,
      "lon": -95.6352005005,
      "tz": "America\/Chicago"
  },
  "TA34": {
      "icao": "TA34",
      "iata": "",
      "name": "Flying G Airport",
      "city": "Crystal City",
      "state": "Texas",
      "country": "US",
      "elevation": 675,
      "lat": 28.7668991089,
      "lon": -100.0830001831,
      "tz": "America\/Chicago"
  },
  "TA35": {
      "icao": "TA35",
      "iata": "",
      "name": "Faust Farm Airport",
      "city": "Brenham",
      "state": "Texas",
      "country": "US",
      "elevation": 8,
      "lat": 30.2726993561,
      "lon": -96.3550033569,
      "tz": "America\/Chicago"
  },
  "TA36": {
      "icao": "TA36",
      "iata": "",
      "name": "Lempa Airport",
      "city": "Cuero",
      "state": "Texas",
      "country": "US",
      "elevation": 247,
      "lat": 29.0058002472,
      "lon": -97.3116989136,
      "tz": "America\/Chicago"
  },
  "TA42": {
      "icao": "TA42",
      "iata": "",
      "name": "Fly-N-Fish Lodge Airport",
      "city": "Uncertain",
      "state": "Texas",
      "country": "US",
      "elevation": 195,
      "lat": 32.7128982544,
      "lon": -94.1249008179,
      "tz": "America\/Chicago"
  },
  "TA43": {
      "icao": "TA43",
      "iata": "",
      "name": "Anderosa Airpark",
      "city": "Corrigan",
      "state": "Texas",
      "country": "US",
      "elevation": 205,
      "lat": 31.0475006104,
      "lon": -94.8544006348,
      "tz": "America\/Chicago"
  },
  "TA44": {
      "icao": "TA44",
      "iata": "",
      "name": "Puesta del Sol Airport",
      "city": "Hebbronville",
      "state": "Texas",
      "country": "US",
      "elevation": 448,
      "lat": 27.07559967,
      "lon": -98.60169983,
      "tz": "America\/Chicago"
  },
  "TA46": {
      "icao": "TA46",
      "iata": "",
      "name": "Baum Airport",
      "city": "Kaufman",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 32.5625991821,
      "lon": -96.4011001587,
      "tz": "America\/Chicago"
  },
  "TA47": {
      "icao": "TA47",
      "iata": "",
      "name": "Richards Airport",
      "city": "Krum",
      "state": "Texas",
      "country": "US",
      "elevation": 785,
      "lat": 33.301399231,
      "lon": -97.2450027466,
      "tz": "America\/Chicago"
  },
  "TA49": {
      "icao": "TA49",
      "iata": "",
      "name": "Keno Field",
      "city": "Andice",
      "state": "Texas",
      "country": "US",
      "elevation": 1000,
      "lat": 30.7551002502,
      "lon": -97.7998962402,
      "tz": "America\/Chicago"
  },
  "TA50": {
      "icao": "TA50",
      "iata": "",
      "name": "Cielo Dorado Estates Airport",
      "city": "Santa Teresa\/El Paso",
      "state": "New-Mexico",
      "country": "US",
      "elevation": 3750,
      "lat": 31.9092998505,
      "lon": -106.6409988403,
      "tz": "America\/Denver"
  },
  "TA51": {
      "icao": "TA51",
      "iata": "",
      "name": "Eagle Airport",
      "city": "Brock",
      "state": "Texas",
      "country": "US",
      "elevation": 885,
      "lat": 32.6804008484,
      "lon": -97.9373016357,
      "tz": "America\/Chicago"
  },
  "TA52": {
      "icao": "TA52",
      "iata": "",
      "name": "Flying Bull Ranch Airport",
      "city": "Barksdale",
      "state": "Texas",
      "country": "US",
      "elevation": 1614,
      "lat": 29.8169002533,
      "lon": -99.9962005615,
      "tz": "America\/Chicago"
  },
  "TA53": {
      "icao": "TA53",
      "iata": "",
      "name": "Rocky Top Ranch Airport",
      "city": "Blanco",
      "state": "Texas",
      "country": "US",
      "elevation": 1500,
      "lat": 30.1301002502,
      "lon": -98.4091033936,
      "tz": "America\/Chicago"
  },
  "TA55": {
      "icao": "TA55",
      "iata": "",
      "name": "Del Valle Airport",
      "city": "Del Valle",
      "state": "Texas",
      "country": "US",
      "elevation": 460,
      "lat": 30.1676998138,
      "lon": -97.5927963257,
      "tz": "America\/Chicago"
  },
  "TA56": {
      "icao": "TA56",
      "iata": "",
      "name": "Blue Mountain Airport",
      "city": "Fort Davis",
      "state": "Texas",
      "country": "US",
      "elevation": 5000,
      "lat": 30.5531997681,
      "lon": -103.9540023804,
      "tz": "America\/Chicago"
  },
  "TA57": {
      "icao": "TA57",
      "iata": "",
      "name": "Texas Valley Air Field",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 31.6238002777,
      "lon": -96.9925003052,
      "tz": "America\/Chicago"
  },
  "TA58": {
      "icao": "TA58",
      "iata": "",
      "name": "Rancho Ecantado Airport",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 550,
      "lat": 28.3213996887,
      "lon": -99.4499969482,
      "tz": "America\/Chicago"
  },
  "TA59": {
      "icao": "TA59",
      "iata": "",
      "name": "Flamingo Airfield",
      "city": "Jacksboro",
      "state": "Texas",
      "country": "US",
      "elevation": 1160,
      "lat": 33.0993003845,
      "lon": -98.2894973755,
      "tz": "America\/Chicago"
  },
  "TA60": {
      "icao": "TA60",
      "iata": "",
      "name": "Hurn Airport",
      "city": "Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 555,
      "lat": 32.3942985535,
      "lon": -96.7794036865,
      "tz": "America\/Chicago"
  },
  "TA61": {
      "icao": "TA61",
      "iata": "",
      "name": "Kay Ranch Airport",
      "city": "Winona",
      "state": "Texas",
      "country": "US",
      "elevation": 315,
      "lat": 32.4734992981,
      "lon": -95.165802002,
      "tz": "America\/Chicago"
  },
  "TA63": {
      "icao": "TA63",
      "iata": "",
      "name": "Flattop Ridge Airport",
      "city": "Burnet",
      "state": "Texas",
      "country": "US",
      "elevation": 1340,
      "lat": 30.8229007721,
      "lon": -98.1109008789,
      "tz": "America\/Chicago"
  },
  "TA64": {
      "icao": "TA64",
      "iata": "",
      "name": "Persimmon Gap Ranch Airport",
      "city": "Marathon",
      "state": "Texas",
      "country": "US",
      "elevation": 2680,
      "lat": 29.700917,
      "lon": -103.15,
      "tz": "America\/Chicago"
  },
  "TA65": {
      "icao": "TA65",
      "iata": "",
      "name": "Sportsman's World Airport",
      "city": "Palo Pinto",
      "state": "Texas",
      "country": "US",
      "elevation": 1142,
      "lat": 32.8212013245,
      "lon": -98.4786987305,
      "tz": "America\/Chicago"
  },
  "TA66": {
      "icao": "TA66",
      "iata": "",
      "name": "Freedom Springs Ranch Airport",
      "city": "Pipe Creek",
      "state": "Texas",
      "country": "US",
      "elevation": 1400,
      "lat": 29.714099884,
      "lon": -98.8600006104,
      "tz": "America\/Chicago"
  },
  "TA67": {
      "icao": "TA67",
      "iata": "",
      "name": "Biggin Hill Airport",
      "city": "Shallowater",
      "state": "Texas",
      "country": "US",
      "elevation": 3330,
      "lat": 33.7484016418,
      "lon": -102.0670013428,
      "tz": "America\/Chicago"
  },
  "TA70": {
      "icao": "TA70",
      "iata": "",
      "name": "W J E Airport",
      "city": "Tennessee Colony",
      "state": "Texas",
      "country": "US",
      "elevation": 251,
      "lat": 31.7835006714,
      "lon": -95.8974990845,
      "tz": "America\/Chicago"
  },
  "TA73": {
      "icao": "TA73",
      "iata": "",
      "name": "Barclay's Roost Airport",
      "city": "Kerrville",
      "state": "Texas",
      "country": "US",
      "elevation": 1680,
      "lat": 29.9747009277,
      "lon": -99.1684036255,
      "tz": "America\/Chicago"
  },
  "TA75": {
      "icao": "TA75",
      "iata": "",
      "name": "Cotton Patch Airport",
      "city": "Lexington",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 30.3871994019,
      "lon": -97.1050033569,
      "tz": "America\/Chicago"
  },
  "TA77": {
      "icao": "TA77",
      "iata": "",
      "name": "Cottonpatch Aerodrome",
      "city": "Frisco",
      "state": "Texas",
      "country": "US",
      "elevation": 580,
      "lat": 33.1492996216,
      "lon": -96.8989028931,
      "tz": "America\/Chicago"
  },
  "TA78": {
      "icao": "TA78",
      "iata": "",
      "name": "Putty Ranch Airport",
      "city": "Hico",
      "state": "Texas",
      "country": "US",
      "elevation": 1143,
      "lat": 31.9724006653,
      "lon": -98.0614013672,
      "tz": "America\/Chicago"
  },
  "TA80": {
      "icao": "TA80",
      "iata": "",
      "name": "U U Ranch Airport",
      "city": "Whitesboro",
      "state": "Texas",
      "country": "US",
      "elevation": 740,
      "lat": 33.8931999207,
      "lon": -96.9281005859,
      "tz": "America\/Chicago"
  },
  "TA81": {
      "icao": "TA81",
      "iata": "",
      "name": "Tyra Ranch Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1500,
      "lat": 29.793800354,
      "lon": -100.8789978027,
      "tz": "America\/Chicago"
  },
  "TA82": {
      "icao": "TA82",
      "iata": "",
      "name": "R & J Livestock County Airport",
      "city": "Batesville",
      "state": "Texas",
      "country": "US",
      "elevation": 805,
      "lat": 29.0244007111,
      "lon": -99.5270004272,
      "tz": "America\/Chicago"
  },
  "TA83": {
      "icao": "TA83",
      "iata": "",
      "name": "Short Field",
      "city": "Mansfield",
      "state": "Texas",
      "country": "US",
      "elevation": 650,
      "lat": 32.5399017334,
      "lon": -97.192199707,
      "tz": "America\/Chicago"
  },
  "TA84": {
      "icao": "TA84",
      "iata": "",
      "name": "Miller Ranch Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 750,
      "lat": 28.3549995422,
      "lon": -99.9331970215,
      "tz": "America\/Chicago"
  },
  "TA85": {
      "icao": "TA85",
      "iata": "",
      "name": "Chambers Airport",
      "city": "Celeste",
      "state": "Texas",
      "country": "US",
      "elevation": 692,
      "lat": 33.3232002258,
      "lon": -96.2322006226,
      "tz": "America\/Chicago"
  },
  "TA86": {
      "icao": "TA86",
      "iata": "",
      "name": "Dennis's Flying Farm Airport",
      "city": "Canton",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 32.6059989929,
      "lon": -95.9349975586,
      "tz": "America\/Chicago"
  },
  "TA87": {
      "icao": "TA87",
      "iata": "",
      "name": "Carter-Norman Airport",
      "city": "Boyd",
      "state": "Texas",
      "country": "US",
      "elevation": 737,
      "lat": 33.1073989868,
      "lon": -97.5481033325,
      "tz": "America\/Chicago"
  },
  "TA89": {
      "icao": "TA89",
      "iata": "",
      "name": "Vaughan Ranch Airport",
      "city": "Mullin",
      "state": "",
      "country": "US",
      "elevation": 1310,
      "lat": 31.5237998962,
      "lon": -98.8003005981,
      "tz": "America\/Chicago"
  },
  "TA90": {
      "icao": "TA90",
      "iata": "",
      "name": "Green Acres Airport",
      "city": "Hockley",
      "state": "Texas",
      "country": "US",
      "elevation": 181,
      "lat": 29.9747009277,
      "lon": -95.8180007935,
      "tz": "America\/Chicago"
  },
  "TA93": {
      "icao": "TA93",
      "iata": "",
      "name": "Lm Ranch Airport",
      "city": "Sherman",
      "state": "Texas",
      "country": "US",
      "elevation": 770,
      "lat": 33.6333007813,
      "lon": -96.7335968018,
      "tz": "America\/Chicago"
  },
  "TA97": {
      "icao": "TA97",
      "iata": "",
      "name": "White Wing Ranch Airport",
      "city": "Orchard",
      "state": "Texas",
      "country": "US",
      "elevation": 125,
      "lat": 29.6375007629,
      "lon": -95.9688034058,
      "tz": "America\/Chicago"
  },
  "TAPA": {
      "icao": "TAPA",
      "iata": "ANU",
      "name": "V.C. Bird International Airport",
      "city": "St. George",
      "state": "Saint-George",
      "country": "AG",
      "elevation": 62,
      "lat": 17.1367,
      "lon": -61.792702,
      "tz": "America\/Antigua"
  },
  "TAPH": {
      "icao": "TAPH",
      "iata": "BBQ",
      "name": "Codrington Airport",
      "city": "Codrington",
      "state": "Barbuda",
      "country": "AG",
      "elevation": 15,
      "lat": 17.635799,
      "lon": -61.828602,
      "tz": "America\/Antigua"
  },
  "TAPT": {
      "icao": "TAPT",
      "iata": "",
      "name": "Coco Point Lodge Airstrip",
      "city": "Coco Point",
      "state": "Barbuda",
      "country": "AG",
      "elevation": 15,
      "lat": 17.555599,
      "lon": -61.765301,
      "tz": "America\/Antigua"
  },
  "TBPB": {
      "icao": "TBPB",
      "iata": "BGI",
      "name": "Sir Grantley Adams International Airport",
      "city": "Bridgetown",
      "state": "Christ-Church",
      "country": "BB",
      "elevation": 169,
      "lat": 13.0746002197,
      "lon": -59.4925003052,
      "tz": "America\/Barbados"
  },
  "TCYT": {
      "icao": "TCYT",
      "iata": "",
      "name": "Crystal Brook Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.4500007629,
      "lon": 144.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "TDCF": {
      "icao": "TDCF",
      "iata": "DCF",
      "name": "Canefield Airport",
      "city": "Canefield",
      "state": "Saint-Paul",
      "country": "DM",
      "elevation": 13,
      "lat": 15.3367004395,
      "lon": -61.39220047,
      "tz": "America\/Dominica"
  },
  "TDPD": {
      "icao": "TDPD",
      "iata": "DOM",
      "name": "Melville Hall Airport",
      "city": "Marigot",
      "state": "Saint-Andrew",
      "country": "DM",
      "elevation": 73,
      "lat": 15.5469999313,
      "lon": -61.2999992371,
      "tz": "America\/Dominica"
  },
  "TE01": {
      "icao": "TE01",
      "iata": "",
      "name": "Dillard Ranch Airport",
      "city": "Centerville",
      "state": "Texas",
      "country": "US",
      "elevation": 225,
      "lat": 31.3048992157,
      "lon": -95.7966003418,
      "tz": "America\/Chicago"
  },
  "TE02": {
      "icao": "TE02",
      "iata": "",
      "name": "Aresti Aerodrome",
      "city": "Godley",
      "state": "Texas",
      "country": "US",
      "elevation": 985,
      "lat": 32.4706993103,
      "lon": -97.5603027344,
      "tz": "America\/Chicago"
  },
  "TE04": {
      "icao": "TE04",
      "iata": "",
      "name": "Split B Ranch Airport",
      "city": "Canadian",
      "state": "Texas",
      "country": "US",
      "elevation": 2580,
      "lat": 35.7024993896,
      "lon": -100.2850036621,
      "tz": "America\/Chicago"
  },
  "TE06": {
      "icao": "TE06",
      "iata": "",
      "name": "Casey Field",
      "city": "Leonard",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 33.3792991638,
      "lon": -96.3169021606,
      "tz": "America\/Chicago"
  },
  "TE08": {
      "icao": "TE08",
      "iata": "",
      "name": "Flying W Airport",
      "city": "Wingate",
      "state": "Texas",
      "country": "US",
      "elevation": 2200,
      "lat": 32.1496009827,
      "lon": -100.1600036621,
      "tz": "America\/Chicago"
  },
  "TE09": {
      "icao": "TE09",
      "iata": "",
      "name": "Minard Pegasus Airport",
      "city": "Alvin",
      "state": "Texas",
      "country": "US",
      "elevation": 25,
      "lat": 29.3318996429,
      "lon": -95.2760009766,
      "tz": "America\/Chicago"
  },
  "TE10": {
      "icao": "TE10",
      "iata": "",
      "name": "Pierce Airport",
      "city": "Henrietta",
      "state": "Texas",
      "country": "US",
      "elevation": 885,
      "lat": 33.8153991699,
      "lon": -98.2281036377,
      "tz": "America\/Chicago"
  },
  "TE12": {
      "icao": "TE12",
      "iata": "",
      "name": "Cleveland Airport",
      "city": "La Vernia",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 29.3001995087,
      "lon": -98.1836013794,
      "tz": "America\/Chicago"
  },
  "TE13": {
      "icao": "TE13",
      "iata": "",
      "name": "Weiblen Airport",
      "city": "Castroville",
      "state": "Texas",
      "country": "US",
      "elevation": 830,
      "lat": 29.4001998901,
      "lon": -98.8874969482,
      "tz": "America\/Chicago"
  },
  "TE15": {
      "icao": "TE15",
      "iata": "",
      "name": "M & M Land Company Airport",
      "city": "Menard",
      "state": "Texas",
      "country": "US",
      "elevation": 1800,
      "lat": 30.8910007477,
      "lon": -99.632598877,
      "tz": "America\/Chicago"
  },
  "TE16": {
      "icao": "TE16",
      "iata": "",
      "name": "Cow Pasture Airport",
      "city": "Parker",
      "state": "Texas",
      "country": "US",
      "elevation": 795,
      "lat": 32.250425,
      "lon": -97.297236,
      "tz": "America\/Chicago"
  },
  "TE17": {
      "icao": "TE17",
      "iata": "",
      "name": "Heathrow Airport",
      "city": "Robinson",
      "state": "Texas",
      "country": "US",
      "elevation": 525,
      "lat": 31.4391002655,
      "lon": -97.1468963623,
      "tz": "America\/Chicago"
  },
  "TE21": {
      "icao": "TE21",
      "iata": "",
      "name": "Lobo Mountain Ranch Airport",
      "city": "Bandera",
      "state": "Texas",
      "country": "US",
      "elevation": 1540,
      "lat": 29.6513004303,
      "lon": -99.0864028931,
      "tz": "America\/Chicago"
  },
  "TE24": {
      "icao": "TE24",
      "iata": "",
      "name": "Horseshoe Lake Airport",
      "city": "Sanger",
      "state": "Texas",
      "country": "US",
      "elevation": 725,
      "lat": 33.3931999207,
      "lon": -97.2052993774,
      "tz": "America\/Chicago"
  },
  "TE26": {
      "icao": "TE26",
      "iata": "",
      "name": "Arrow 'S' Ranch Airport",
      "city": "Cambellton",
      "state": "Texas",
      "country": "US",
      "elevation": 275,
      "lat": 28.7835998535,
      "lon": -98.3499984741,
      "tz": "America\/Chicago"
  },
  "TE27": {
      "icao": "TE27",
      "iata": "",
      "name": "Harrison Piloncillo Ranch Airport",
      "city": "Catarina",
      "state": "Texas",
      "country": "US",
      "elevation": 520,
      "lat": 28.2544002533,
      "lon": -99.5991973877,
      "tz": "America\/Chicago"
  },
  "TE29": {
      "icao": "TE29",
      "iata": "",
      "name": "La Esperanza Ranch Airport",
      "city": "Encinal",
      "state": "Texas",
      "country": "US",
      "elevation": 750,
      "lat": 28.0730991364,
      "lon": -99.5742034912,
      "tz": "America\/Chicago"
  },
  "TE32": {
      "icao": "TE32",
      "iata": "",
      "name": "Rancho Blanco Airport",
      "city": "Laredo",
      "state": "Texas",
      "country": "US",
      "elevation": 385,
      "lat": 27.308599472,
      "lon": -99.4813995361,
      "tz": "America\/Chicago"
  },
  "TE33": {
      "icao": "TE33",
      "iata": "",
      "name": "Eagles Nest Gliderport",
      "city": "Midland",
      "state": "Texas",
      "country": "US",
      "elevation": 2985,
      "lat": 31.6835002899,
      "lon": -102.1839981079,
      "tz": "America\/Chicago"
  },
  "TE34": {
      "icao": "TE34",
      "iata": "",
      "name": "Reb Folbre's Place Airport",
      "city": "Millsap",
      "state": "Texas",
      "country": "US",
      "elevation": 940,
      "lat": 32.7625999451,
      "lon": -97.9728012085,
      "tz": "America\/Chicago"
  },
  "TE36": {
      "icao": "TE36",
      "iata": "",
      "name": "Gerum Farm Airport",
      "city": "Shiner",
      "state": "Texas",
      "country": "US",
      "elevation": 425,
      "lat": 29.3918991089,
      "lon": -97.1847000122,
      "tz": "America\/Chicago"
  },
  "TE37": {
      "icao": "TE37",
      "iata": "",
      "name": "Canyon Ranch Airport",
      "city": "Sonora",
      "state": "Texas",
      "country": "US",
      "elevation": 2306,
      "lat": 30.2691001892,
      "lon": -100.4509963989,
      "tz": "America\/Chicago"
  },
  "TE38": {
      "icao": "TE38",
      "iata": "",
      "name": "Loghouse \/Stol\/ STOLport",
      "city": "Goodrich",
      "state": "Texas",
      "country": "US",
      "elevation": 184,
      "lat": 30.5926990509,
      "lon": -94.9094009399,
      "tz": "America\/Chicago"
  },
  "TE39": {
      "icao": "TE39",
      "iata": "",
      "name": "Bucker Field",
      "city": "Era",
      "state": "Texas",
      "country": "US",
      "elevation": 877,
      "lat": 33.4431991577,
      "lon": -97.273399353,
      "tz": "America\/Chicago"
  },
  "TE40": {
      "icao": "TE40",
      "iata": "",
      "name": "Caselman Ranch Airport",
      "city": "Breckenridge",
      "state": "Texas",
      "country": "US",
      "elevation": 1387,
      "lat": 32.6748008728,
      "lon": -98.8262023926,
      "tz": "America\/Chicago"
  },
  "TE45": {
      "icao": "TE45",
      "iata": "",
      "name": "Buffalo Chips Airpark",
      "city": "Joshua",
      "state": "Texas",
      "country": "US",
      "elevation": 960,
      "lat": 32.4698982239,
      "lon": -97.4653015137,
      "tz": "America\/Chicago"
  },
  "TE47": {
      "icao": "TE47",
      "iata": "",
      "name": "Cross Wind Acres Airport",
      "city": "Rio Vista",
      "state": "Texas",
      "country": "US",
      "elevation": 760,
      "lat": 32.2089996338,
      "lon": -97.3178024292,
      "tz": "America\/Chicago"
  },
  "TE48": {
      "icao": "TE48",
      "iata": "",
      "name": "King's Ranch Airport",
      "city": "Lampasas",
      "state": "Texas",
      "country": "US",
      "elevation": 1025,
      "lat": 31.0449008942,
      "lon": -98.0428009033,
      "tz": "America\/Chicago"
  },
  "TE50": {
      "icao": "TE50",
      "iata": "",
      "name": "Hirok Airport",
      "city": "Milford",
      "state": "Texas",
      "country": "US",
      "elevation": 700,
      "lat": 32.1731987,
      "lon": -96.9661026001,
      "tz": "America\/Chicago"
  },
  "TE51": {
      "icao": "TE51",
      "iata": "",
      "name": "Lackorn Airport",
      "city": "Seguin",
      "state": "Texas",
      "country": "US",
      "elevation": 420,
      "lat": 29.539100647,
      "lon": -97.8349990845,
      "tz": "America\/Chicago"
  },
  "TE52": {
      "icao": "TE52",
      "iata": "",
      "name": "Chigger Field",
      "city": "Cresson",
      "state": "Texas",
      "country": "US",
      "elevation": 950,
      "lat": 32.5359992981,
      "lon": -97.5886001587,
      "tz": "America\/Chicago"
  },
  "TE55": {
      "icao": "TE55",
      "iata": "",
      "name": "Flyin Armadillo Airport",
      "city": "Jacksboro",
      "state": "Texas",
      "country": "US",
      "elevation": 995,
      "lat": 33.4216995239,
      "lon": -98.1905975342,
      "tz": "America\/Chicago"
  },
  "TE57": {
      "icao": "TE57",
      "iata": "",
      "name": "Haass Field",
      "city": "Hondo",
      "state": "Texas",
      "country": "US",
      "elevation": 945,
      "lat": 29.4447994232,
      "lon": -99.1228027344,
      "tz": "America\/Chicago"
  },
  "TE58": {
      "icao": "TE58",
      "iata": "",
      "name": "Bird Dog Airfield",
      "city": "Krum",
      "state": "Texas",
      "country": "US",
      "elevation": 960,
      "lat": 33.3064002991,
      "lon": -97.3416976929,
      "tz": "America\/Chicago"
  },
  "TE62": {
      "icao": "TE62",
      "iata": "",
      "name": "High Man Tower Airstrip",
      "city": "Robstown",
      "state": "Texas",
      "country": "US",
      "elevation": 90,
      "lat": 27.8542995453,
      "lon": -97.6822967529,
      "tz": "America\/Chicago"
  },
  "TE63": {
      "icao": "TE63",
      "iata": "",
      "name": "Arrowhead Ranch Airport",
      "city": "Tilden",
      "state": "Texas",
      "country": "US",
      "elevation": 296,
      "lat": 28.246099472,
      "lon": -98.5528030396,
      "tz": "America\/Chicago"
  },
  "TE67": {
      "icao": "TE67",
      "iata": "",
      "name": "Cibolo Sea-Willo Airpark",
      "city": "New Berlin",
      "state": "Texas",
      "country": "US",
      "elevation": 517,
      "lat": 29.4291000366,
      "lon": -98.1316986084,
      "tz": "America\/Chicago"
  },
  "TE68": {
      "icao": "TE68",
      "iata": "",
      "name": "Nuggs Flying M Airport",
      "city": "Pottsboro",
      "state": "Texas",
      "country": "US",
      "elevation": 725,
      "lat": 33.7440986633,
      "lon": -96.6598968506,
      "tz": "America\/Chicago"
  },
  "TE70": {
      "icao": "TE70",
      "iata": "",
      "name": "Palmer Field",
      "city": "Ponder",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 33.1986999512,
      "lon": -97.3170013428,
      "tz": "America\/Chicago"
  },
  "TE71": {
      "icao": "TE71",
      "iata": "",
      "name": "Ghsa-Wallis Glideport Gliderport",
      "city": "Wallis",
      "state": "Texas",
      "country": "US",
      "elevation": 127,
      "lat": 29.6110992432,
      "lon": -96.0279006958,
      "tz": "America\/Chicago"
  },
  "TE72": {
      "icao": "TE72",
      "iata": "",
      "name": "Haven Field",
      "city": "Waxahachie",
      "state": "Texas",
      "country": "US",
      "elevation": 595,
      "lat": 32.3270988464,
      "lon": -96.8402023315,
      "tz": "America\/Chicago"
  },
  "TE73": {
      "icao": "TE73",
      "iata": "",
      "name": "Griffith Ranch Airport",
      "city": "Winnie",
      "state": "Texas",
      "country": "US",
      "elevation": 21,
      "lat": 29.858499527,
      "lon": -94.3541030884,
      "tz": "America\/Chicago"
  },
  "TE74": {
      "icao": "TE74",
      "iata": "",
      "name": "Bucker Field",
      "city": "Point",
      "state": "Texas",
      "country": "US",
      "elevation": 485,
      "lat": 32.9536018372,
      "lon": -95.866897583,
      "tz": "America\/Chicago"
  },
  "TE75": {
      "icao": "TE75",
      "iata": "",
      "name": "Lexington Airfield",
      "city": "Lexington",
      "state": "Texas",
      "country": "US",
      "elevation": 470,
      "lat": 30.4125003815,
      "lon": -96.9666976929,
      "tz": "America\/Chicago"
  },
  "TE76": {
      "icao": "TE76",
      "iata": "",
      "name": "Vance Field",
      "city": "Alvord",
      "state": "Texas",
      "country": "US",
      "elevation": 925,
      "lat": 33.3683013916,
      "lon": -97.6821975708,
      "tz": "America\/Chicago"
  },
  "TE77": {
      "icao": "TE77",
      "iata": "",
      "name": "Clover Lake Farms Airport",
      "city": "Angleton",
      "state": "Texas",
      "country": "US",
      "elevation": 38,
      "lat": 29.2336997986,
      "lon": -95.4766998291,
      "tz": "America\/Chicago"
  },
  "TE78": {
      "icao": "TE78",
      "iata": "",
      "name": "Fossil Creek Ranch Airport",
      "city": "Camp Wood",
      "state": "Texas",
      "country": "US",
      "elevation": 1325,
      "lat": 29.5687007904,
      "lon": -99.9745025635,
      "tz": "America\/Chicago"
  },
  "TE81": {
      "icao": "TE81",
      "iata": "",
      "name": "Smither Field",
      "city": "Denton",
      "state": "Texas",
      "country": "US",
      "elevation": 655,
      "lat": 33.3097000122,
      "lon": -97.0927963257,
      "tz": "America\/Chicago"
  },
  "TE83": {
      "icao": "TE83",
      "iata": "",
      "name": "Westwind Ranch Airport",
      "city": "La Pryor",
      "state": "Texas",
      "country": "US",
      "elevation": 686,
      "lat": 28.9207992554,
      "lon": -99.7561035156,
      "tz": "America\/Chicago"
  },
  "TE84": {
      "icao": "TE84",
      "iata": "",
      "name": "Mockingbird Hill Airport",
      "city": "Mc Gregor",
      "state": "Texas",
      "country": "US",
      "elevation": 810,
      "lat": 31.4652004242,
      "lon": -97.4753036499,
      "tz": "America\/Chicago"
  },
  "TE85": {
      "icao": "TE85",
      "iata": "",
      "name": "Marmack Airport",
      "city": "Montgomery",
      "state": "Texas",
      "country": "US",
      "elevation": 295,
      "lat": 30.3421993256,
      "lon": -95.6613006592,
      "tz": "America\/Chicago"
  },
  "TE86": {
      "icao": "TE86",
      "iata": "",
      "name": "Heritage Airfield",
      "city": "New Berlin",
      "state": "Texas",
      "country": "US",
      "elevation": 555,
      "lat": 29.4444007874,
      "lon": -98.1128005981,
      "tz": "America\/Chicago"
  },
  "TE87": {
      "icao": "TE87",
      "iata": "",
      "name": "Magee Airport",
      "city": "Portland",
      "state": "Texas",
      "country": "US",
      "elevation": 35,
      "lat": 27.9158000946,
      "lon": -97.3202972412,
      "tz": "America\/Chicago"
  },
  "TE88": {
      "icao": "TE88",
      "iata": "",
      "name": "Bb Airpark",
      "city": "Rosharon",
      "state": "Texas",
      "country": "US",
      "elevation": 50,
      "lat": 29.3568992615,
      "lon": -95.4593963623,
      "tz": "America\/Chicago"
  },
  "TE89": {
      "icao": "TE89",
      "iata": "",
      "name": "Verhalen Airport",
      "city": "Scottsville",
      "state": "Texas",
      "country": "US",
      "elevation": 415,
      "lat": 32.5377006531,
      "lon": -94.2360992432,
      "tz": "America\/Chicago"
  },
  "TE90": {
      "icao": "TE90",
      "iata": "",
      "name": "Flying L Airport",
      "city": "Bandera",
      "state": "Texas",
      "country": "US",
      "elevation": 1380,
      "lat": 29.7588996887,
      "lon": -99.1524963379,
      "tz": "America\/Chicago"
  },
  "TE91": {
      "icao": "TE91",
      "iata": "",
      "name": "Harrison Farm Airport",
      "city": "Troup",
      "state": "Texas",
      "country": "US",
      "elevation": 445,
      "lat": 32.0392990112,
      "lon": -95.0828018188,
      "tz": "America\/Chicago"
  },
  "TE92": {
      "icao": "TE92",
      "iata": "",
      "name": "Wales Air Field",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 640,
      "lat": 31.5944004059,
      "lon": -97.3709030151,
      "tz": "America\/Chicago"
  },
  "TE95": {
      "icao": "TE95",
      "iata": "",
      "name": "Deiterich Ranch Airport",
      "city": "Bertram",
      "state": "Texas",
      "country": "US",
      "elevation": 1385,
      "lat": 30.7532997131,
      "lon": -98.146697998,
      "tz": "America\/Chicago"
  },
  "TE96": {
      "icao": "TE96",
      "iata": "",
      "name": "Crosswinds Airfield",
      "city": "Coupland",
      "state": "Texas",
      "country": "US",
      "elevation": 543,
      "lat": 30.4332008362,
      "lon": -97.4124984741,
      "tz": "America\/Chicago"
  },
  "TE97": {
      "icao": "TE97",
      "iata": "",
      "name": "Cameron Ranch Airport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 1760,
      "lat": 30.1877994537,
      "lon": -98.9582977295,
      "tz": "America\/Chicago"
  },
  "TE99": {
      "icao": "TE99",
      "iata": "",
      "name": "Barnstormer Airport",
      "city": "Peoria",
      "state": "Texas",
      "country": "US",
      "elevation": 625,
      "lat": 32.0214996338,
      "lon": -97.2537002563,
      "tz": "America\/Chicago"
  },
  "TFFA": {
      "icao": "TFFA",
      "iata": "DSD",
      "name": "La Desirade Airport",
      "city": "Grande Anse",
      "state": "Guadeloupe",
      "country": "GP",
      "elevation": 10,
      "lat": 16.2968997955,
      "lon": -61.084400177,
      "tz": "America\/Guadeloupe"
  },
  "TFFB": {
      "icao": "TFFB",
      "iata": "BBR",
      "name": "Baillif Airport",
      "city": "Basse Terre",
      "state": "Guadeloupe",
      "country": "GP",
      "elevation": 59,
      "lat": 16.013299942,
      "lon": -61.7421989441,
      "tz": "America\/Guadeloupe"
  },
  "TFFC": {
      "icao": "TFFC",
      "iata": "SFC",
      "name": "St-Francois Airport",
      "city": "St-Francois",
      "state": "Guadeloupe",
      "country": "GP",
      "elevation": 10,
      "lat": 16.2577991486,
      "lon": -61.2625007629,
      "tz": "America\/Guadeloupe"
  },
  "TFFF": {
      "icao": "TFFF",
      "iata": "FDF",
      "name": "Martinique Aime Cesaire International Airport",
      "city": "Fort-de-France",
      "state": "Martinique",
      "country": "MQ",
      "elevation": 16,
      "lat": 14.5909996033,
      "lon": -61.003200531,
      "tz": "America\/Martinique"
  },
  "TFFG": {
      "icao": "TFFG",
      "iata": "SFG",
      "name": "L'Esperance Airport",
      "city": "Grand Case",
      "state": "",
      "country": "MF",
      "elevation": 7,
      "lat": 18.099899292,
      "lon": -63.0471992493,
      "tz": "America\/Lower_Princes"
  },
  "TFFJ": {
      "icao": "TFFJ",
      "iata": "SBH",
      "name": "Gustaf III Airport",
      "city": "Gustavia",
      "state": "",
      "country": "BL",
      "elevation": 49,
      "lat": 17.9043998718,
      "lon": -62.8436012268,
      "tz": "America\/St_Barthelemy"
  },
  "TFFM": {
      "icao": "TFFM",
      "iata": "GBJ",
      "name": "Les Bases Airport",
      "city": "Grand Bourg",
      "state": "Guadeloupe",
      "country": "GP",
      "elevation": 16,
      "lat": 15.8687000275,
      "lon": -61.2700004578,
      "tz": "America\/Guadeloupe"
  },
  "TFFR": {
      "icao": "TFFR",
      "iata": "PTP",
      "name": "Pointe-a-Pitre Le Raizet",
      "city": "Pointe-a-Pitre Le Raizet",
      "state": "Guadeloupe",
      "country": "GP",
      "elevation": 36,
      "lat": 16.2653007507,
      "lon": -61.5317993164,
      "tz": "America\/Guadeloupe"
  },
  "TFFS": {
      "icao": "TFFS",
      "iata": "LSS",
      "name": "Terre-de-Haut Airport",
      "city": "Les Saintes",
      "state": "Guadeloupe",
      "country": "GP",
      "elevation": 46,
      "lat": 15.86439991,
      "lon": -61.5806007385,
      "tz": "America\/Guadeloupe"
  },
  "TGPY": {
      "icao": "TGPY",
      "iata": "GND",
      "name": "Point Salines International Airport",
      "city": "Saint George's",
      "state": "Saint-George",
      "country": "GD",
      "elevation": 41,
      "lat": 12.0041999817,
      "lon": -61.7862014771,
      "tz": "America\/Grenada"
  },
  "TGPZ": {
      "icao": "TGPZ",
      "iata": "CRU",
      "name": "Lauriston Airport",
      "city": "Carriacou Island",
      "state": "",
      "country": "GD",
      "elevation": 5,
      "lat": 12.476099968,
      "lon": -61.4728012085,
      "tz": "America\/Grenada"
  },
  "TIST": {
      "icao": "TIST",
      "iata": "STT",
      "name": "Cyril E. King Airport",
      "city": "Charlotte Amalie",
      "state": "Saint-Thomas-Island",
      "country": "VI",
      "elevation": 23,
      "lat": 18.3372993469,
      "lon": -64.9733963013,
      "tz": "America\/St_Thomas"
  },
  "TISX": {
      "icao": "TISX",
      "iata": "STX",
      "name": "Henry E Rohlsen Airport",
      "city": "Christiansted",
      "state": "Saint-Croix-Island",
      "country": "VI",
      "elevation": 74,
      "lat": 17.7019004822,
      "lon": -64.7985992432,
      "tz": "America\/St_Thomas"
  },
  "TJAB": {
      "icao": "TJAB",
      "iata": "ARE",
      "name": "Antonio Nery Juarbe Pol Airport",
      "city": "Arecibo",
      "state": "Arecibo",
      "country": "PR",
      "elevation": 23,
      "lat": 18.4500007629,
      "lon": -66.6753005981,
      "tz": "America\/Puerto_Rico"
  },
  "TJBQ": {
      "icao": "TJBQ",
      "iata": "BQN",
      "name": "Rafael Hernandez Airport",
      "city": "Aguadilla",
      "state": "Aguadilla",
      "country": "PR",
      "elevation": 237,
      "lat": 18.4948997498,
      "lon": -67.1294021606,
      "tz": "America\/Puerto_Rico"
  },
  "TJCP": {
      "icao": "TJCP",
      "iata": "CPX",
      "name": "Benjamin Rivera Noriega Airport",
      "city": "Culebra Island",
      "state": "Culebra",
      "country": "PR",
      "elevation": 49,
      "lat": 18.313289,
      "lon": -65.304324,
      "tz": "America\/Puerto_Rico"
  },
  "TJFA": {
      "icao": "TJFA",
      "iata": "FAJ",
      "name": "Diego Jimenez Torres Airport",
      "city": "Fajardo",
      "state": "Fajardo",
      "country": "PR",
      "elevation": 64,
      "lat": 18.3089008331,
      "lon": -65.6619033813,
      "tz": "America\/Puerto_Rico"
  },
  "TJIG": {
      "icao": "TJIG",
      "iata": "SIG",
      "name": "Fernando Luis Ribas Dominicci Airport",
      "city": "San Juan",
      "state": "San-Juan",
      "country": "PR",
      "elevation": 10,
      "lat": 18.4568004608,
      "lon": -66.0980987549,
      "tz": "America\/Puerto_Rico"
  },
  "TJMZ": {
      "icao": "TJMZ",
      "iata": "MAZ",
      "name": "Eugenio Maria De Hostos Airport",
      "city": "Mayaguez",
      "state": "Mayaguez",
      "country": "PR",
      "elevation": 28,
      "lat": 18.2556991577,
      "lon": -67.1484985352,
      "tz": "America\/Puerto_Rico"
  },
  "TJPS": {
      "icao": "TJPS",
      "iata": "PSE",
      "name": "Mercedita Airport",
      "city": "Ponce",
      "state": "Ponce",
      "country": "PR",
      "elevation": 29,
      "lat": 18.0083007813,
      "lon": -66.56300354,
      "tz": "America\/Puerto_Rico"
  },
  "TJRV": {
      "icao": "TJRV",
      "iata": "NRR",
      "name": "Jose Aponte de la Torre Airport",
      "city": "Ceiba",
      "state": "Ceiba",
      "country": "PR",
      "elevation": 38,
      "lat": 18.245300293,
      "lon": -65.6434020996,
      "tz": "America\/Puerto_Rico"
  },
  "TJSJ": {
      "icao": "TJSJ",
      "iata": "SJU",
      "name": "Luis Munoz Marin International Airport",
      "city": "San Juan",
      "state": "Carolina",
      "country": "PR",
      "elevation": 9,
      "lat": 18.4393997192,
      "lon": -66.0018005371,
      "tz": "America\/Puerto_Rico"
  },
  "TJVQ": {
      "icao": "TJVQ",
      "iata": "VQS",
      "name": "Antonio Rivera Rodriguez Airport",
      "city": "Vieques Island",
      "state": "Vieques",
      "country": "PR",
      "elevation": 49,
      "lat": 18.1347999573,
      "lon": -65.493598938,
      "tz": "America\/Puerto_Rico"
  },
  "TKPK": {
      "icao": "TKPK",
      "iata": "SKB",
      "name": "Robert L. Bradshaw International Airport",
      "city": "Basseterre",
      "state": "Saint-George-Basseterre",
      "country": "KN",
      "elevation": 170,
      "lat": 17.3111991882,
      "lon": -62.7187004089,
      "tz": "America\/St_Kitts"
  },
  "TKPN": {
      "icao": "TKPN",
      "iata": "NEV",
      "name": "Vance W. Amory International Airport",
      "city": "Charlestown",
      "state": "Saint-James-Windwa",
      "country": "KN",
      "elevation": 14,
      "lat": 17.2056999207,
      "lon": -62.5899009705,
      "tz": "America\/St_Kitts"
  },
  "TLPC": {
      "icao": "TLPC",
      "iata": "SLU",
      "name": "George F. L. Charles Airport",
      "city": "Castries",
      "state": "Castries",
      "country": "LC",
      "elevation": 22,
      "lat": 14.0202,
      "lon": -60.992901,
      "tz": "America\/St_Lucia"
  },
  "TLPL": {
      "icao": "TLPL",
      "iata": "UVF",
      "name": "Hewanorra International Airport",
      "city": "Vieux Fort",
      "state": "Vieux-Fort",
      "country": "LC",
      "elevation": 14,
      "lat": 13.7332,
      "lon": -60.952599,
      "tz": "America\/St_Lucia"
  },
  "TN01": {
      "icao": "TN01",
      "iata": "",
      "name": "Ray Airport",
      "city": "Rosemark",
      "state": "Tennessee",
      "country": "US",
      "elevation": 305,
      "lat": 35.3473014832,
      "lon": -89.8195037842,
      "tz": "America\/Chicago"
  },
  "TN07": {
      "icao": "TN07",
      "iata": "",
      "name": "Hawk Haven Airfield",
      "city": "Eagleville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 775,
      "lat": 35.6871986389,
      "lon": -86.6063995361,
      "tz": "America\/Chicago"
  },
  "TN08": {
      "icao": "TN08",
      "iata": "",
      "name": "Oliver Springs Inc Airport",
      "city": "Oliver Springs",
      "state": "Tennessee",
      "country": "US",
      "elevation": 790,
      "lat": 36.0376014709,
      "lon": -84.3069000244,
      "tz": "America\/New_York"
  },
  "TN09": {
      "icao": "TN09",
      "iata": "",
      "name": "Fergusons Flying Circus Airport",
      "city": "Philadelphia",
      "state": "Tennessee",
      "country": "US",
      "elevation": 910,
      "lat": 35.7140007019,
      "lon": -84.4110031128,
      "tz": "America\/New_York"
  },
  "TN10": {
      "icao": "TN10",
      "iata": "",
      "name": "Lake View Airport",
      "city": "Sharps Chapel",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1066,
      "lat": 36.3390007019,
      "lon": -83.7788009644,
      "tz": "America\/New_York"
  },
  "TN11": {
      "icao": "TN11",
      "iata": "",
      "name": "Cantwell Airport",
      "city": "Sneedville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1150,
      "lat": 36.501499176,
      "lon": -83.2555007935,
      "tz": "America\/New_York"
  },
  "TN12": {
      "icao": "TN12",
      "iata": "",
      "name": "Hudgin Air Airport",
      "city": "Lewisburg",
      "state": "Tennessee",
      "country": "US",
      "elevation": 725,
      "lat": 35.4735984802,
      "lon": -86.6800003052,
      "tz": "America\/Chicago"
  },
  "TN13": {
      "icao": "TN13",
      "iata": "",
      "name": "Barret Airport",
      "city": "Barretville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 240,
      "lat": 35.3750991821,
      "lon": -89.7667007446,
      "tz": "America\/Chicago"
  },
  "TN14": {
      "icao": "TN14",
      "iata": "",
      "name": "T-Top Airfield",
      "city": "Eagleville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 770,
      "lat": 35.6897010803,
      "lon": -86.6358032227,
      "tz": "America\/Chicago"
  },
  "TN16": {
      "icao": "TN16",
      "iata": "",
      "name": "Huntingdon Airport",
      "city": "Huntingdon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 400,
      "lat": 35.9944992065,
      "lon": -88.4611968994,
      "tz": "America\/Chicago"
  },
  "TN17": {
      "icao": "TN17",
      "iata": "",
      "name": "Vintage Field",
      "city": "Sweetwater",
      "state": "Tennessee",
      "country": "US",
      "elevation": 990,
      "lat": 35.5822982788,
      "lon": -84.4879989624,
      "tz": "America\/New_York"
  },
  "TN18": {
      "icao": "TN18",
      "iata": "",
      "name": "Richardson Strip",
      "city": "Eads",
      "state": "Tennessee",
      "country": "US",
      "elevation": 340,
      "lat": 35.2042999268,
      "lon": -89.6667022705,
      "tz": "America\/Chicago"
  },
  "TN20": {
      "icao": "TN20",
      "iata": "",
      "name": "Seymour Air Park Inc. Airport",
      "city": "Sevierville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1000,
      "lat": 35.8117980957,
      "lon": -83.774597168,
      "tz": "America\/New_York"
  },
  "TN21": {
      "icao": "TN21",
      "iata": "",
      "name": "Palmer Village-Napier Lake STOLport",
      "city": "Hohenwald",
      "state": "Tennessee",
      "country": "US",
      "elevation": 790,
      "lat": 35.4476013184,
      "lon": -87.5061035156,
      "tz": "America\/Chicago"
  },
  "TN23": {
      "icao": "TN23",
      "iata": "",
      "name": "Titan Field",
      "city": "Clarksville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 385,
      "lat": 36.4944000244,
      "lon": -87.3218994141,
      "tz": "America\/Chicago"
  },
  "TN24": {
      "icao": "TN24",
      "iata": "",
      "name": "Spencer Field",
      "city": "Manchester",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1080,
      "lat": 35.3875999451,
      "lon": -85.9625015259,
      "tz": "America\/Chicago"
  },
  "TN30": {
      "icao": "TN30",
      "iata": "",
      "name": "Cub Haven Airport",
      "city": "Niota",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1000,
      "lat": 35.5471992493,
      "lon": -84.5100021362,
      "tz": "America\/New_York"
  },
  "TN36": {
      "icao": "TN36",
      "iata": "",
      "name": "Flying I Ranch Airport",
      "city": "Ripley",
      "state": "Tennessee",
      "country": "US",
      "elevation": 350,
      "lat": 35.7620010376,
      "lon": -89.5512008667,
      "tz": "America\/Chicago"
  },
  "TN37": {
      "icao": "TN37",
      "iata": "",
      "name": "Anderson Airport",
      "city": "Toone",
      "state": "Tennessee",
      "country": "US",
      "elevation": 392,
      "lat": 35.4042015076,
      "lon": -89.0223007202,
      "tz": "America\/Chicago"
  },
  "TN38": {
      "icao": "TN38",
      "iata": "",
      "name": "Rutledge Field",
      "city": "Tullahoma",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1125,
      "lat": 35.3718986511,
      "lon": -86.2668991089,
      "tz": "America\/Chicago"
  },
  "TN39": {
      "icao": "TN39",
      "iata": "",
      "name": "Amacher Strip",
      "city": "Tullahoma",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1080,
      "lat": 35.3055992126,
      "lon": -86.2367019653,
      "tz": "America\/Chicago"
  },
  "TN41": {
      "icao": "TN41",
      "iata": "",
      "name": "100 Aker Wood Airport",
      "city": "Spring City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 809,
      "lat": 35.7728004456,
      "lon": -84.765296936,
      "tz": "America\/New_York"
  },
  "TN43": {
      "icao": "TN43",
      "iata": "",
      "name": "Isle-A-Port STOLport",
      "city": "Memphis",
      "state": "Tennessee",
      "country": "US",
      "elevation": 215,
      "lat": 35.0959014893,
      "lon": -90.1139984131,
      "tz": "America\/Chicago"
  },
  "TN44": {
      "icao": "TN44",
      "iata": "",
      "name": "Deerfield Resort Airport",
      "city": "Lafollette",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1350,
      "lat": 36.346698761,
      "lon": -84.0121994019,
      "tz": "America\/New_York"
  },
  "TN47": {
      "icao": "TN47",
      "iata": "",
      "name": "Needham's Airport",
      "city": "Arlington",
      "state": "Tennessee",
      "country": "US",
      "elevation": 289,
      "lat": 35.2890014648,
      "lon": -89.7412033081,
      "tz": "America\/Chicago"
  },
  "TN48": {
      "icao": "TN48",
      "iata": "",
      "name": "Turner Field",
      "city": "Dresden",
      "state": "Tennessee",
      "country": "US",
      "elevation": 401,
      "lat": 36.2775993347,
      "lon": -88.6537017822,
      "tz": "America\/Chicago"
  },
  "TN50": {
      "icao": "TN50",
      "iata": "",
      "name": "Indian Hill Farm Airport",
      "city": "New Hope",
      "state": "Tennessee",
      "country": "US",
      "elevation": 676,
      "lat": 34.9915008545,
      "lon": -85.6508026123,
      "tz": "America\/Chicago"
  },
  "TN51": {
      "icao": "TN51",
      "iata": "",
      "name": "Aydelotte STOLport",
      "city": "Springfield",
      "state": "Tennessee",
      "country": "US",
      "elevation": 545,
      "lat": 36.6427993774,
      "lon": -86.9764022827,
      "tz": "America\/Chicago"
  },
  "TN52": {
      "icao": "TN52",
      "iata": "",
      "name": "King Airport",
      "city": "Winchester",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1050,
      "lat": 35.1380996704,
      "lon": -86.0811004639,
      "tz": "America\/Chicago"
  },
  "TN53": {
      "icao": "TN53",
      "iata": "",
      "name": "Parr Field",
      "city": "Clarksville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 590,
      "lat": 36.5559005737,
      "lon": -87.4483032227,
      "tz": "America\/Chicago"
  },
  "TN56": {
      "icao": "TN56",
      "iata": "",
      "name": "Carey Airport",
      "city": "Mayland",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1960,
      "lat": 36.0722999573,
      "lon": -85.113899231,
      "tz": "America\/Chicago"
  },
  "TN58": {
      "icao": "TN58",
      "iata": "",
      "name": "Parsons Field",
      "city": "Brighton",
      "state": "Tennessee",
      "country": "US",
      "elevation": 330,
      "lat": 35.3917007446,
      "lon": -89.65650177,
      "tz": "America\/Chicago"
  },
  "TN62": {
      "icao": "TN62",
      "iata": "",
      "name": "Mc Afee Stol Patch STOLport",
      "city": "Johnson City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1925,
      "lat": 36.3722991943,
      "lon": -82.3915023804,
      "tz": "America\/New_York"
  },
  "TN64": {
      "icao": "TN64",
      "iata": "",
      "name": "West Wind Airpark",
      "city": "Sweetwater",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1000,
      "lat": 35.5442008972,
      "lon": -84.5327987671,
      "tz": "America\/New_York"
  },
  "TN65": {
      "icao": "TN65",
      "iata": "",
      "name": "Long Meadow Airstrip",
      "city": "Murfreesburg",
      "state": "",
      "country": "US",
      "elevation": 750,
      "lat": 35.6664009094,
      "lon": -86.4852981567,
      "tz": "America\/Chicago"
  },
  "TN66": {
      "icao": "TN66",
      "iata": "",
      "name": "Austin Field",
      "city": "Pikeville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1000,
      "lat": 35.7444992065,
      "lon": -85.047203064,
      "tz": "America\/Chicago"
  },
  "TN67": {
      "icao": "TN67",
      "iata": "",
      "name": "Myers-Smith Airport",
      "city": "Estill Springs",
      "state": "Tennessee",
      "country": "US",
      "elevation": 900,
      "lat": 35.2478981018,
      "lon": -86.1286010742,
      "tz": "America\/Chicago"
  },
  "TN68": {
      "icao": "TN68",
      "iata": "",
      "name": "Gibson STOLport",
      "city": "Murfreesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 805,
      "lat": 35.768699646,
      "lon": -86.5466995239,
      "tz": "America\/Chicago"
  },
  "TN70": {
      "icao": "TN70",
      "iata": "",
      "name": "Burkeen Field",
      "city": "Collierville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 365,
      "lat": 35.0261993408,
      "lon": -89.7025985718,
      "tz": "America\/Chicago"
  },
  "TN71": {
      "icao": "TN71",
      "iata": "",
      "name": "Cox Farm Airport",
      "city": "Lenoir City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1000,
      "lat": 35.7944984436,
      "lon": -84.3415985107,
      "tz": "America\/New_York"
  },
  "TN72": {
      "icao": "TN72",
      "iata": "",
      "name": "King Airport",
      "city": "Sevierville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1080,
      "lat": 35.8459014893,
      "lon": -83.4817962646,
      "tz": "America\/New_York"
  },
  "TN73": {
      "icao": "TN73",
      "iata": "",
      "name": "Doc Jones Field",
      "city": "Nashville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 770,
      "lat": 36.3233985901,
      "lon": -86.9593963623,
      "tz": "America\/Chicago"
  },
  "TN74": {
      "icao": "TN74",
      "iata": "",
      "name": "Will A Hildreth Farm Airport",
      "city": "Lenoir City",
      "state": "Tennessee",
      "country": "US",
      "elevation": 900,
      "lat": 35.8000984192,
      "lon": -84.3193969727,
      "tz": "America\/New_York"
  },
  "TN77": {
      "icao": "TN77",
      "iata": "",
      "name": "Whifferdill Airport",
      "city": "Chapmansboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 622,
      "lat": 36.32180023,
      "lon": -87.15070343,
      "tz": "America\/Chicago"
  },
  "TN79": {
      "icao": "TN79",
      "iata": "",
      "name": "Oakley Airport",
      "city": "Nashville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 400,
      "lat": 36.1722984314,
      "lon": -86.9597015381,
      "tz": "America\/Chicago"
  },
  "TN80": {
      "icao": "TN80",
      "iata": "",
      "name": "Schiff Airport",
      "city": "Cookeville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1260,
      "lat": 36.2333984375,
      "lon": -85.426399231,
      "tz": "America\/Chicago"
  },
  "TN81": {
      "icao": "TN81",
      "iata": "",
      "name": "Shultz Airport",
      "city": "Franklin",
      "state": "Tennessee",
      "country": "US",
      "elevation": 770,
      "lat": 35.7489013672,
      "lon": -86.8135986328,
      "tz": "America\/Chicago"
  },
  "TN83": {
      "icao": "TN83",
      "iata": "",
      "name": "Cedar Glade Aerodrome",
      "city": "Murfreesboro",
      "state": "Tennessee",
      "country": "US",
      "elevation": 770,
      "lat": 35.7223014832,
      "lon": -86.2750015259,
      "tz": "America\/Chicago"
  },
  "TN85": {
      "icao": "TN85",
      "iata": "",
      "name": "Hogue Airport",
      "city": "Spencer",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1800,
      "lat": 35.638999939,
      "lon": -85.3972015381,
      "tz": "America\/Chicago"
  },
  "TN86": {
      "icao": "TN86",
      "iata": "",
      "name": "Walden Ridge Airport",
      "city": "Rockwood",
      "state": "Tennessee",
      "country": "US",
      "elevation": 820,
      "lat": 35.7986984253,
      "lon": -84.7632980347,
      "tz": "America\/New_York"
  },
  "TN87": {
      "icao": "TN87",
      "iata": "",
      "name": "Montvale Airpark",
      "city": "Maryville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1030,
      "lat": 35.6926002502,
      "lon": -83.9988021851,
      "tz": "America\/New_York"
  },
  "TN89": {
      "icao": "TN89",
      "iata": "",
      "name": "Matthews Airport",
      "city": "Whitwell",
      "state": "Tennessee",
      "country": "US",
      "elevation": 700,
      "lat": 35.2583999634,
      "lon": -85.4526977539,
      "tz": "America\/Chicago"
  },
  "TN93": {
      "icao": "TN93",
      "iata": "",
      "name": "Cotton Patch Airport",
      "city": "Bolivar",
      "state": "Tennessee",
      "country": "US",
      "elevation": 465,
      "lat": 35.2822990417,
      "lon": -89.0291976929,
      "tz": "America\/Chicago"
  },
  "TN94": {
      "icao": "TN94",
      "iata": "",
      "name": "Robertson Farm Airport",
      "city": "Knoxville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1020,
      "lat": 36.0010986328,
      "lon": -84.0744018555,
      "tz": "America\/New_York"
  },
  "TN96": {
      "icao": "TN96",
      "iata": "",
      "name": "Fall Creek Field",
      "city": "Lebanon",
      "state": "Tennessee",
      "country": "US",
      "elevation": 675,
      "lat": 36.0411987305,
      "lon": -86.3582992554,
      "tz": "America\/Chicago"
  },
  "TN97": {
      "icao": "TN97",
      "iata": "",
      "name": "Triune Airfield",
      "city": "Nashville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 740,
      "lat": 35.8069000244,
      "lon": -86.612197876,
      "tz": "America\/Chicago"
  },
  "TN98": {
      "icao": "TN98",
      "iata": "",
      "name": "Sky Ranch Airport",
      "city": "Knoxville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 830,
      "lat": 35.8856010437,
      "lon": -83.9577026367,
      "tz": "America\/New_York"
  },
  "TN99": {
      "icao": "TN99",
      "iata": "",
      "name": "Pensinger Airport",
      "city": "Greeneville",
      "state": "Tennessee",
      "country": "US",
      "elevation": 1265,
      "lat": 36.2963981628,
      "lon": -82.760597229,
      "tz": "America\/New_York"
  },
  "TNCA": {
      "icao": "TNCA",
      "iata": "AUA",
      "name": "Queen Beatrix International Airport",
      "city": "Oranjestad",
      "state": "",
      "country": "AW",
      "elevation": 60,
      "lat": 12.5013999939,
      "lon": -70.0151977539,
      "tz": "America\/Aruba"
  },
  "TNCB": {
      "icao": "TNCB",
      "iata": "BON",
      "name": "Flamingo International Airport",
      "city": "Kralendijk",
      "state": "Bonaire",
      "country": "BQ",
      "elevation": 20,
      "lat": 12.1309995651,
      "lon": -68.2685012817,
      "tz": "America\/Kralendijk"
  },
  "TNCC": {
      "icao": "TNCC",
      "iata": "CUR",
      "name": "Hato International Airport",
      "city": "Willemstad",
      "state": "",
      "country": "CW",
      "elevation": 29,
      "lat": 12.1888999939,
      "lon": -68.9598007202,
      "tz": "America\/Curacao"
  },
  "TNCE": {
      "icao": "TNCE",
      "iata": "EUX",
      "name": "F. D. Roosevelt Airport",
      "city": "Sint Eustatius",
      "state": "Sint-Eustatius",
      "country": "BQ",
      "elevation": 129,
      "lat": 17.4965000153,
      "lon": -62.9794006348,
      "tz": "America\/Kralendijk"
  },
  "TNCM": {
      "icao": "TNCM",
      "iata": "SXM",
      "name": "Princess Juliana International Airport",
      "city": "Saint Martin",
      "state": "",
      "country": "SX",
      "elevation": 13,
      "lat": 18.0410003662,
      "lon": -63.1088981628,
      "tz": "America\/Lower_Princes"
  },
  "TNCS": {
      "icao": "TNCS",
      "iata": "SAB",
      "name": "Juancho E. Yrausquin Airport",
      "city": "Saba",
      "state": "Saba",
      "country": "BQ",
      "elevation": 60,
      "lat": 17.6450004578,
      "lon": -63.2200012207,
      "tz": "America\/Kralendijk"
  },
  "TQPF": {
      "icao": "TQPF",
      "iata": "AXA",
      "name": "Wallblake Airport",
      "city": "The Valley",
      "state": "",
      "country": "AI",
      "elevation": 127,
      "lat": 18.2047996521,
      "lon": -63.0550994873,
      "tz": "America\/Anguilla"
  },
  "TRPG": {
      "icao": "TRPG",
      "iata": "MNI",
      "name": "John A. Osborne Airport",
      "city": "Gerald's Park",
      "state": "Saint-Peter",
      "country": "MS",
      "elevation": 550,
      "lat": 16.7914009094,
      "lon": -62.1932983398,
      "tz": "America\/Montserrat"
  },
  "TS00": {
      "icao": "TS00",
      "iata": "",
      "name": "Fuller Airport",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 490,
      "lat": 32.7882003784,
      "lon": -97.1808013916,
      "tz": "America\/Chicago"
  },
  "TS01": {
      "icao": "TS01",
      "iata": "",
      "name": "Landry STOLport",
      "city": "Geogetown",
      "state": "Texas",
      "country": "US",
      "elevation": 780,
      "lat": 30.7126998901,
      "lon": -97.6502990723,
      "tz": "America\/Chicago"
  },
  "TS02": {
      "icao": "TS02",
      "iata": "",
      "name": "One Sixty Four Place Airport",
      "city": "Jacksboro",
      "state": "Texas",
      "country": "US",
      "elevation": 1200,
      "lat": 33.1195983887,
      "lon": -98.2305984497,
      "tz": "America\/Chicago"
  },
  "TS03": {
      "icao": "TS03",
      "iata": "",
      "name": "Bryant's Landing Airport",
      "city": "Walnut Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 914,
      "lat": 32.0615005493,
      "lon": -97.7378005981,
      "tz": "America\/Chicago"
  },
  "TS04": {
      "icao": "TS04",
      "iata": "",
      "name": "Rio Vista Ranch Airport",
      "city": "Del Rio",
      "state": "Texas",
      "country": "US",
      "elevation": 1774,
      "lat": 29.8358001709,
      "lon": -100.9779968262,
      "tz": "America\/Chicago"
  },
  "TS05": {
      "icao": "TS05",
      "iata": "",
      "name": "Progreso Airport",
      "city": "Progreso",
      "state": "Texas",
      "country": "US",
      "elevation": 70,
      "lat": 26.0837001801,
      "lon": -97.950302124,
      "tz": "America\/Chicago"
  },
  "TS07": {
      "icao": "TS07",
      "iata": "",
      "name": "Dry Creek Airport",
      "city": "Cypress",
      "state": "Texas",
      "country": "US",
      "elevation": 152,
      "lat": 29.9862995148,
      "lon": -95.6856002808,
      "tz": "America\/Chicago"
  },
  "TS08": {
      "icao": "TS08",
      "iata": "",
      "name": "Calaway Airport",
      "city": "Ganado",
      "state": "Texas",
      "country": "US",
      "elevation": 74,
      "lat": 29.0835990906,
      "lon": -96.5002975464,
      "tz": "America\/Chicago"
  },
  "TS11": {
      "icao": "TS11",
      "iata": "",
      "name": "Glenmar Airport",
      "city": "Campbell",
      "state": "Texas",
      "country": "US",
      "elevation": 531,
      "lat": 33.1071014404,
      "lon": -95.9300003052,
      "tz": "America\/Chicago"
  },
  "TS14": {
      "icao": "TS14",
      "iata": "",
      "name": "Smith Flying Service Airport",
      "city": "De Leon",
      "state": "Texas",
      "country": "US",
      "elevation": 1275,
      "lat": 32.1086997986,
      "lon": -98.4879989624,
      "tz": "America\/Chicago"
  },
  "TS15": {
      "icao": "TS15",
      "iata": "",
      "name": "Cibolo Creek Ranch Airport",
      "city": "Marfa",
      "state": "Texas",
      "country": "US",
      "elevation": 4400,
      "lat": 29.89290047,
      "lon": -104.262001,
      "tz": "America\/Chicago"
  },
  "TS18": {
      "icao": "TS18",
      "iata": "",
      "name": "Kingsland Estates Airport",
      "city": "Kingsland",
      "state": "Texas",
      "country": "US",
      "elevation": 873,
      "lat": 30.6543998718,
      "lon": -98.4785995483,
      "tz": "America\/Chicago"
  },
  "TS20": {
      "icao": "TS20",
      "iata": "",
      "name": "C Lazy T Ranch Airport",
      "city": "Seymour",
      "state": "Texas",
      "country": "US",
      "elevation": 1365,
      "lat": 33.5322990417,
      "lon": -99.1937026978,
      "tz": "America\/Chicago"
  },
  "TS21": {
      "icao": "TS21",
      "iata": "",
      "name": "Roy Ranch Airport",
      "city": "Justiceburg",
      "state": "Texas",
      "country": "US",
      "elevation": 2366,
      "lat": 33.084833,
      "lon": -101.114833,
      "tz": "America\/Chicago"
  },
  "TS22": {
      "icao": "TS22",
      "iata": "",
      "name": "Joye Ranch Airport",
      "city": "Smiley",
      "state": "Texas",
      "country": "US",
      "elevation": 280,
      "lat": 29.2416992188,
      "lon": -97.629699707,
      "tz": "America\/Chicago"
  },
  "TS25": {
      "icao": "TS25",
      "iata": "",
      "name": "Mynatt Field",
      "city": "South Sulphur",
      "state": "Texas",
      "country": "US",
      "elevation": 557,
      "lat": 33.2697982788,
      "lon": -96.0224990845,
      "tz": "America\/Chicago"
  },
  "TS27": {
      "icao": "TS27",
      "iata": "",
      "name": "River Field",
      "city": "Columbus",
      "state": "Texas",
      "country": "US",
      "elevation": 220,
      "lat": 29.71619987,
      "lon": -96.59140015,
      "tz": "America\/Chicago"
  },
  "TS29": {
      "icao": "TS29",
      "iata": "",
      "name": "Liberty Hill International Airport",
      "city": "Eddy",
      "state": "Texas",
      "country": "US",
      "elevation": 790,
      "lat": 31.2945995331,
      "lon": -97.2969970703,
      "tz": "America\/Chicago"
  },
  "TS35": {
      "icao": "TS35",
      "iata": "",
      "name": "West Liberty Airport",
      "city": "Dayton",
      "state": "Texas",
      "country": "US",
      "elevation": 80,
      "lat": 30.0583000183,
      "lon": -94.9781036377,
      "tz": "America\/Chicago"
  },
  "TS36": {
      "icao": "TS36",
      "iata": "",
      "name": "Silver Wings Airport",
      "city": "Fredricksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 2110,
      "lat": 30.2196006775,
      "lon": -99.1408004761,
      "tz": "America\/Chicago"
  },
  "TS39": {
      "icao": "TS39",
      "iata": "",
      "name": "Sherman Airport",
      "city": "Lockney",
      "state": "Texas",
      "country": "US",
      "elevation": 3285,
      "lat": 34.3120002747,
      "lon": -101.4120025635,
      "tz": "America\/Chicago"
  },
  "TS40": {
      "icao": "TS40",
      "iata": "",
      "name": "Celina Field",
      "city": "Celina",
      "state": "Texas",
      "country": "US",
      "elevation": 770,
      "lat": 33.3128013611,
      "lon": -96.7639007568,
      "tz": "America\/Chicago"
  },
  "TS42": {
      "icao": "TS42",
      "iata": "",
      "name": "Del-Tex Airport",
      "city": "Olton",
      "state": "Texas",
      "country": "US",
      "elevation": 3600,
      "lat": 34.1964988708,
      "lon": -102.1279983521,
      "tz": "America\/Chicago"
  },
  "TS44": {
      "icao": "TS44",
      "iata": "",
      "name": "Dry Branch Ranch Airport",
      "city": "Pattison",
      "state": "Texas",
      "country": "US",
      "elevation": 130,
      "lat": 29.8400001526,
      "lon": -96.0233001709,
      "tz": "America\/Chicago"
  },
  "TS47": {
      "icao": "TS47",
      "iata": "",
      "name": "Rock Creek Ranch Airport",
      "city": "Whitt",
      "state": "Texas",
      "country": "US",
      "elevation": 1130,
      "lat": 32.96849823,
      "lon": -98.0113983154,
      "tz": "America\/Chicago"
  },
  "TS50": {
      "icao": "TS50",
      "iata": "",
      "name": "Austinia Airport",
      "city": "Texas City",
      "state": "Texas",
      "country": "US",
      "elevation": 15,
      "lat": 29.4174995422,
      "lon": -94.998298645,
      "tz": "America\/Chicago"
  },
  "TS51": {
      "icao": "TS51",
      "iata": "",
      "name": "Barton Memorial Airport",
      "city": "Garrison",
      "state": "Texas",
      "country": "US",
      "elevation": 345,
      "lat": 31.8370990753,
      "lon": -94.4593963623,
      "tz": "America\/Chicago"
  },
  "TS53": {
      "icao": "TS53",
      "iata": "",
      "name": "Flying Cap Valley Airport",
      "city": "Grapevine",
      "state": "Texas",
      "country": "US",
      "elevation": 638,
      "lat": 32.9365005493,
      "lon": -97.1352996826,
      "tz": "America\/Chicago"
  },
  "TS54": {
      "icao": "TS54",
      "iata": "",
      "name": "Flying B Airport",
      "city": "Pickton",
      "state": "Texas",
      "country": "US",
      "elevation": 535,
      "lat": 33.0148010254,
      "lon": -95.4344024658,
      "tz": "America\/Chicago"
  },
  "TS55": {
      "icao": "TS55",
      "iata": "",
      "name": "Bat Cave Field",
      "city": "Garden Ridge",
      "state": "Texas",
      "country": "US",
      "elevation": 850,
      "lat": 29.6718997955,
      "lon": -98.3044967651,
      "tz": "America\/Chicago"
  },
  "TS57": {
      "icao": "TS57",
      "iata": "",
      "name": "Red Ace Ranch Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 900,
      "lat": 33.2417984009,
      "lon": -97.6211013794,
      "tz": "America\/Chicago"
  },
  "TS61": {
      "icao": "TS61",
      "iata": "",
      "name": "Little 'L' Ranch Airport",
      "city": "Glen Rose",
      "state": "Texas",
      "country": "US",
      "elevation": 585,
      "lat": 32.2117004395,
      "lon": -97.6421966553,
      "tz": "America\/Chicago"
  },
  "TS62": {
      "icao": "TS62",
      "iata": "",
      "name": "Norris Field",
      "city": "Jayton",
      "state": "Texas",
      "country": "US",
      "elevation": 1987,
      "lat": 33.2000999451,
      "lon": -100.5179977417,
      "tz": "America\/Chicago"
  },
  "TS63": {
      "icao": "TS63",
      "iata": "",
      "name": "Square Air Airport",
      "city": "Mc Kinney",
      "state": "Texas",
      "country": "US",
      "elevation": 655,
      "lat": 33.2458992004,
      "lon": -96.564201355,
      "tz": "America\/Chicago"
  },
  "TS65": {
      "icao": "TS65",
      "iata": "",
      "name": "Ducote Airpark",
      "city": "San Angelo",
      "state": "Texas",
      "country": "US",
      "elevation": 1974,
      "lat": 31.358499527,
      "lon": -100.6129989624,
      "tz": "America\/Chicago"
  },
  "TS67": {
      "icao": "TS67",
      "iata": "",
      "name": "Kitty Hawk Flying Field",
      "city": "Garden Ridge",
      "state": "Texas",
      "country": "US",
      "elevation": 860,
      "lat": 29.6252002716,
      "lon": -98.2780990601,
      "tz": "America\/Chicago"
  },
  "TS69": {
      "icao": "TS69",
      "iata": "",
      "name": "Barronena East Airport",
      "city": "Hebbronville",
      "state": "Texas",
      "country": "US",
      "elevation": 576,
      "lat": 27.4850006104,
      "lon": -98.6620025635,
      "tz": "America\/Chicago"
  },
  "TS70": {
      "icao": "TS70",
      "iata": "",
      "name": "Jack Miller STOLport",
      "city": "Mansfield",
      "state": "Texas",
      "country": "US",
      "elevation": 600,
      "lat": 32.5750999451,
      "lon": -97.0869979858,
      "tz": "America\/Chicago"
  },
  "TS71": {
      "icao": "TS71",
      "iata": "",
      "name": "Flying B Ranch Airport",
      "city": "Ovilla",
      "state": "Texas",
      "country": "US",
      "elevation": 780,
      "lat": 32.5279006958,
      "lon": -96.943901062,
      "tz": "America\/Chicago"
  },
  "TS73": {
      "icao": "TS73",
      "iata": "",
      "name": "Stubbs Strip",
      "city": "Fort Worth",
      "state": "Texas",
      "country": "US",
      "elevation": 755,
      "lat": 32.775100708,
      "lon": -97.4878005981,
      "tz": "America\/Chicago"
  },
  "TS74": {
      "icao": "TS74",
      "iata": "",
      "name": "Glass Airport",
      "city": "Sanger",
      "state": "Texas",
      "country": "US",
      "elevation": 810,
      "lat": 33.3625984192,
      "lon": -97.2945022583,
      "tz": "America\/Chicago"
  },
  "TS75": {
      "icao": "TS75",
      "iata": "",
      "name": "Mitchell Nr One Airport",
      "city": "Sanderson",
      "state": "Texas",
      "country": "US",
      "elevation": 2700,
      "lat": 30.5030002594,
      "lon": -102.158996582,
      "tz": "America\/Chicago"
  },
  "TS76": {
      "icao": "TS76",
      "iata": "",
      "name": "Redstone Ranch Airport",
      "city": "Stonewall",
      "state": "Texas",
      "country": "US",
      "elevation": 1460,
      "lat": 30.2502002716,
      "lon": -98.5947036743,
      "tz": "America\/Chicago"
  },
  "TS78": {
      "icao": "TS78",
      "iata": "",
      "name": "Loesch Ranch Airport",
      "city": "Booker",
      "state": "Texas",
      "country": "US",
      "elevation": 2804,
      "lat": 36.3917007446,
      "lon": -100.46900177,
      "tz": "America\/Chicago"
  },
  "TS80": {
      "icao": "TS80",
      "iata": "",
      "name": "Mc Alister Farm Airport",
      "city": "Electra",
      "state": "Texas",
      "country": "US",
      "elevation": 1125,
      "lat": 34.1305999756,
      "lon": -98.9030990601,
      "tz": "America\/Chicago"
  },
  "TS85": {
      "icao": "TS85",
      "iata": "",
      "name": "Diamond J Airport",
      "city": "Pottsboro",
      "state": "Texas",
      "country": "US",
      "elevation": 802,
      "lat": 33.8106002808,
      "lon": -96.7039031982,
      "tz": "America\/Chicago"
  },
  "TS87": {
      "icao": "TS87",
      "iata": "",
      "name": "Bridle Ridge Airport",
      "city": "Navasota",
      "state": "Texas",
      "country": "US",
      "elevation": 328,
      "lat": 30.4179992676,
      "lon": -96.0793991089,
      "tz": "America\/Chicago"
  },
  "TS89": {
      "icao": "TS89",
      "iata": "",
      "name": "Parker Airport",
      "city": "Granbury",
      "state": "Texas",
      "country": "US",
      "elevation": 925,
      "lat": 32.3511009216,
      "lon": -97.8087005615,
      "tz": "America\/Chicago"
  },
  "TS90": {
      "icao": "TS90",
      "iata": "",
      "name": "Bayless Airport",
      "city": "Rosharon",
      "state": "Texas",
      "country": "US",
      "elevation": 49,
      "lat": 29.4025001526,
      "lon": -95.4319000244,
      "tz": "America\/Chicago"
  },
  "TS92": {
      "icao": "TS92",
      "iata": "",
      "name": "Little Peach Airport",
      "city": "Temple",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 31.2145996094,
      "lon": -97.4266967773,
      "tz": "America\/Chicago"
  },
  "TS94": {
      "icao": "TS94",
      "iata": "",
      "name": "Rancho Buena Vista Airport",
      "city": "Bay View",
      "state": "Texas",
      "country": "US",
      "elevation": 20,
      "lat": 26.2511997223,
      "lon": -97.3007965088,
      "tz": "America\/Chicago"
  },
  "TS95": {
      "icao": "TS95",
      "iata": "",
      "name": "Aviasud Airpark",
      "city": "Beasley",
      "state": "Texas",
      "country": "US",
      "elevation": 100,
      "lat": 29.4864006042,
      "lon": -95.9308013916,
      "tz": "America\/Chicago"
  },
  "TS96": {
      "icao": "TS96",
      "iata": "",
      "name": "El Campo Airpark",
      "city": "El Campo",
      "state": "Texas",
      "country": "US",
      "elevation": 128,
      "lat": 29.2668991089,
      "lon": -96.3252029419,
      "tz": "America\/Chicago"
  },
  "TS98": {
      "icao": "TS98",
      "iata": "",
      "name": "Wings Over Texas Airport",
      "city": "Terrell",
      "state": "Texas",
      "country": "US",
      "elevation": 532,
      "lat": 32.7868995667,
      "lon": -96.3069000244,
      "tz": "America\/Chicago"
  },
  "TT01": {
      "icao": "TT01",
      "iata": "",
      "name": "Pagan Airstrip",
      "city": "Shomu-Shon",
      "state": "Northern-Islands",
      "country": "MP",
      "elevation": 34,
      "lat": 18.1243991852,
      "lon": 145.7689971924,
      "tz": "Pacific\/Saipan"
  },
  "TTCP": {
      "icao": "TTCP",
      "iata": "TAB",
      "name": "Tobago-Crown Point Airport",
      "city": "Scarborough",
      "state": "Tobago",
      "country": "TT",
      "elevation": 38,
      "lat": 11.1497001648,
      "lon": -60.8321990967,
      "tz": "America\/Port_of_Spain"
  },
  "TTPP": {
      "icao": "TTPP",
      "iata": "POS",
      "name": "Piarco International Airport",
      "city": "Port of Spain",
      "state": "Tunapuna\/Piarco",
      "country": "TT",
      "elevation": 58,
      "lat": 10.5953998566,
      "lon": -61.3372001648,
      "tz": "America\/Port_of_Spain"
  },
  "TUPA": {
      "icao": "TUPA",
      "iata": "NGD",
      "name": "Captain Auguste George Airport",
      "city": "Anegada",
      "state": "",
      "country": "VG",
      "elevation": 9,
      "lat": 18.7271995544,
      "lon": -64.3296966553,
      "tz": "America\/Tortola"
  },
  "TUPJ": {
      "icao": "TUPJ",
      "iata": "EIS",
      "name": "Terrance B. Lettsome International Airport",
      "city": "Road Town",
      "state": "",
      "country": "VG",
      "elevation": 15,
      "lat": 18.4447994232,
      "lon": -64.5429992676,
      "tz": "America\/Tortola"
  },
  "TUPW": {
      "icao": "TUPW",
      "iata": "VIJ",
      "name": "Virgin Gorda Airport",
      "city": "Spanish Town",
      "state": "",
      "country": "VG",
      "elevation": 9,
      "lat": 18.4463996887,
      "lon": -64.4274978638,
      "tz": "America\/Tortola"
  },
  "TVSB": {
      "icao": "TVSB",
      "iata": "BQU",
      "name": "J F Mitchell Airport",
      "city": "Bequia",
      "state": "Grenadines",
      "country": "VC",
      "elevation": 15,
      "lat": 12.9884004593,
      "lon": -61.2620010376,
      "tz": "America\/St_Vincent"
  },
  "TVSC": {
      "icao": "TVSC",
      "iata": "CIW",
      "name": "Canouan Airport",
      "city": "Canouan",
      "state": "Grenadines",
      "country": "VC",
      "elevation": 11,
      "lat": 12.6990003586,
      "lon": -61.3423995972,
      "tz": "America\/St_Vincent"
  },
  "TVSM": {
      "icao": "TVSM",
      "iata": "MQS",
      "name": "Mustique Airport",
      "city": "Mustique Island",
      "state": "Grenadines",
      "country": "VC",
      "elevation": 8,
      "lat": 12.8879003525,
      "lon": -61.1801986694,
      "tz": "America\/St_Vincent"
  },
  "TVSU": {
      "icao": "TVSU",
      "iata": "UNI",
      "name": "Union Island International Airport",
      "city": "Union Island",
      "state": "Grenadines",
      "country": "VC",
      "elevation": 16,
      "lat": 12.6001348495,
      "lon": -61.411945343,
      "tz": "America\/St_Vincent"
  },
  "TVSV": {
      "icao": "TVSV",
      "iata": "SVD",
      "name": "E. T. Joshua Airport",
      "city": "Kingstown",
      "state": "Saint-George",
      "country": "VC",
      "elevation": 66,
      "lat": 13.1443004608,
      "lon": -61.210899353,
      "tz": "America\/St_Vincent"
  },
  "TX00": {
      "icao": "TX00",
      "iata": "",
      "name": "Abilene Executive Airpark",
      "city": "Abilene",
      "state": "Texas",
      "country": "US",
      "elevation": 1822,
      "lat": 32.4488983154,
      "lon": -99.6200027466,
      "tz": "America\/Chicago"
  },
  "TX01": {
      "icao": "TX01",
      "iata": "",
      "name": "New Home Airport",
      "city": "New Home",
      "state": "Texas",
      "country": "US",
      "elevation": 3234,
      "lat": 33.3083992004,
      "lon": -101.9100036621,
      "tz": "America\/Chicago"
  },
  "TX02": {
      "icao": "TX02",
      "iata": "",
      "name": "Portlock Airfield",
      "city": "Abilene",
      "state": "Texas",
      "country": "US",
      "elevation": 1780,
      "lat": 32.5265007019,
      "lon": -99.6128997803,
      "tz": "America\/Chicago"
  },
  "TX03": {
      "icao": "TX03",
      "iata": "",
      "name": "Stapleton Field",
      "city": "Abbott",
      "state": "Texas",
      "country": "US",
      "elevation": 716,
      "lat": 31.8752002716,
      "lon": -97.0669021606,
      "tz": "America\/Chicago"
  },
  "TX05": {
      "icao": "TX05",
      "iata": "",
      "name": "Dryden Airport",
      "city": "Austin",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 30.26049995,
      "lon": -97.62529755,
      "tz": "America\/Chicago"
  },
  "TX07": {
      "icao": "TX07",
      "iata": "",
      "name": "Nix River Ranch Strip",
      "city": "Comstock",
      "state": "Texas",
      "country": "US",
      "elevation": 1820,
      "lat": 29.934299469,
      "lon": -101.2340011597,
      "tz": "America\/Chicago"
  },
  "TX09": {
      "icao": "TX09",
      "iata": "",
      "name": "Cunningham Airpark",
      "city": "New Braunfels",
      "state": "Texas",
      "country": "US",
      "elevation": 617,
      "lat": 29.675556,
      "lon": -98.012778,
      "tz": "America\/Chicago"
  },
  "TX11": {
      "icao": "TX11",
      "iata": "",
      "name": "Ross Planes Airport",
      "city": "Cross Plains",
      "state": "Texas",
      "country": "US",
      "elevation": 1765,
      "lat": 32.1399993896,
      "lon": -99.1586990356,
      "tz": "America\/Chicago"
  },
  "TX12": {
      "icao": "TX12",
      "iata": "",
      "name": "Kidd-Private Airport",
      "city": "Denison",
      "state": "Texas",
      "country": "US",
      "elevation": 705,
      "lat": 33.8083992004,
      "lon": -96.6085968018,
      "tz": "America\/Chicago"
  },
  "TX13": {
      "icao": "TX13",
      "iata": "",
      "name": "Mesa Vista Ranch Airport",
      "city": "Pampa",
      "state": "Texas",
      "country": "US",
      "elevation": 2772,
      "lat": 35.889278,
      "lon": -101.030139,
      "tz": "America\/Chicago"
  },
  "TX15": {
      "icao": "TX15",
      "iata": "",
      "name": "Beggs Ranch\/Aledo\/ Airport",
      "city": "Aledo",
      "state": "Texas",
      "country": "US",
      "elevation": 1060,
      "lat": 32.751499176,
      "lon": -97.6128005981,
      "tz": "America\/Chicago"
  },
  "TX16": {
      "icao": "TX16",
      "iata": "",
      "name": "Log Cabin Airport",
      "city": "Aledo",
      "state": "Texas",
      "country": "US",
      "elevation": 1100,
      "lat": 32.8025016785,
      "lon": -97.6091995239,
      "tz": "America\/Chicago"
  },
  "TX19": {
      "icao": "TX19",
      "iata": "",
      "name": "Russells Ranch Airport",
      "city": "Annona",
      "state": "Texas",
      "country": "US",
      "elevation": 400,
      "lat": 33.5362014771,
      "lon": -94.9072036743,
      "tz": "America\/Chicago"
  },
  "TX20": {
      "icao": "TX20",
      "iata": "",
      "name": "Steen Airport",
      "city": "Anson",
      "state": "Texas",
      "country": "US",
      "elevation": 1670,
      "lat": 32.7932014465,
      "lon": -99.8778991699,
      "tz": "America\/Chicago"
  },
  "TX21": {
      "icao": "TX21",
      "iata": "",
      "name": "Hornady Ranch Airport",
      "city": "Archer City",
      "state": "Texas",
      "country": "US",
      "elevation": 1165,
      "lat": 33.4779014587,
      "lon": -98.5475997925,
      "tz": "America\/Chicago"
  },
  "TX22": {
      "icao": "TX22",
      "iata": "",
      "name": "Leroux Airport",
      "city": "Argyle",
      "state": "Texas",
      "country": "US",
      "elevation": 670,
      "lat": 33.1017990112,
      "lon": -97.1549987793,
      "tz": "America\/Chicago"
  },
  "TX23": {
      "icao": "TX23",
      "iata": "",
      "name": "Flf Gliderport",
      "city": "Briggs",
      "state": "Texas",
      "country": "US",
      "elevation": 1150,
      "lat": 30.856300354,
      "lon": -97.9458999634,
      "tz": "America\/Chicago"
  },
  "TX24": {
      "icao": "TX24",
      "iata": "",
      "name": "Oaks Airport",
      "city": "Arp",
      "state": "Texas",
      "country": "US",
      "elevation": 510,
      "lat": 32.2751998901,
      "lon": -95.0836029053,
      "tz": "America\/Chicago"
  },
  "TX25": {
      "icao": "TX25",
      "iata": "",
      "name": "Lochridge Ranch Airport",
      "city": "Athens",
      "state": "Texas",
      "country": "US",
      "elevation": 391,
      "lat": 31.9892997742,
      "lon": -95.9511032104,
      "tz": "America\/Chicago"
  },
  "TX29": {
      "icao": "TX29",
      "iata": "",
      "name": "Flying O Airport",
      "city": "Bardwell",
      "state": "Texas",
      "country": "US",
      "elevation": 490,
      "lat": 32.2890014648,
      "lon": -96.7043991089,
      "tz": "America\/Chicago"
  },
  "TX31": {
      "icao": "TX31",
      "iata": "",
      "name": "Edwards Lucian Wells Ranch Airport",
      "city": "Big Spring",
      "state": "Texas",
      "country": "US",
      "elevation": 2505,
      "lat": 32.0736999512,
      "lon": -101.5640029907,
      "tz": "America\/Chicago"
  },
  "TX32": {
      "icao": "TX32",
      "iata": "",
      "name": "Bar V K Airport",
      "city": "Bolivar",
      "state": "Texas",
      "country": "US",
      "elevation": 765,
      "lat": 33.3763999939,
      "lon": -97.2433013916,
      "tz": "America\/Chicago"
  },
  "TX33": {
      "icao": "TX33",
      "iata": "",
      "name": "Haire Airport",
      "city": "Sanger",
      "state": "Texas",
      "country": "US",
      "elevation": 860,
      "lat": 33.3418006897,
      "lon": -97.3086013794,
      "tz": "America\/Chicago"
  },
  "TX34": {
      "icao": "TX34",
      "iata": "",
      "name": "Windy Tales Airport",
      "city": "Mineral Wells",
      "state": "Texas",
      "country": "US",
      "elevation": 980,
      "lat": 32.8484153748,
      "lon": -97.9843063354,
      "tz": "America\/Chicago"
  },
  "TX35": {
      "icao": "TX35",
      "iata": "",
      "name": "Key's Ranch Airport",
      "city": "Boxelder\/Lydia",
      "state": "Texas",
      "country": "US",
      "elevation": 345,
      "lat": 33.4431991577,
      "lon": -94.8141021729,
      "tz": "America\/Chicago"
  },
  "TX36": {
      "icao": "TX36",
      "iata": "",
      "name": "Green Ranch Airport",
      "city": "Breckenridge",
      "state": "Texas",
      "country": "US",
      "elevation": 1190,
      "lat": 32.8334999084,
      "lon": -99.0419998169,
      "tz": "America\/Chicago"
  },
  "TX37": {
      "icao": "TX37",
      "iata": "",
      "name": "Flying S Ranch Airport",
      "city": "Bridgeport",
      "state": "",
      "country": "US",
      "elevation": 850,
      "lat": 33.1318016052,
      "lon": -97.7686004639,
      "tz": "America\/Chicago"
  },
  "TX38": {
      "icao": "TX38",
      "iata": "",
      "name": "Duke Ranch Airport",
      "city": "Darrouzett",
      "state": "Texas",
      "country": "US",
      "elevation": 2705,
      "lat": 36.3795013428,
      "lon": -100.3430023193,
      "tz": "America\/Chicago"
  },
  "TX39": {
      "icao": "TX39",
      "iata": "",
      "name": "Ruby Field",
      "city": "Era",
      "state": "Texas",
      "country": "US",
      "elevation": 650,
      "lat": 33.4771995544,
      "lon": -97.2506027222,
      "tz": "America\/Chicago"
  },
  "TX40": {
      "icao": "TX40",
      "iata": "",
      "name": "Echo Lake Airport",
      "city": "Brownsboro",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 32.2542991638,
      "lon": -95.6557998657,
      "tz": "America\/Chicago"
  },
  "TX41": {
      "icao": "TX41",
      "iata": "",
      "name": "Pippen-York Ranch Airport",
      "city": "Blanco",
      "state": "Texas",
      "country": "US",
      "elevation": 1340,
      "lat": 30.091299057,
      "lon": -98.3647003174,
      "tz": "America\/Chicago"
  },
  "TX42": {
      "icao": "TX42",
      "iata": "",
      "name": "Fair Weather Field",
      "city": "Monaville",
      "state": "Texas",
      "country": "US",
      "elevation": 175,
      "lat": 29.9375,
      "lon": -96.0394439697,
      "tz": "America\/Chicago"
  },
  "TX43": {
      "icao": "TX43",
      "iata": "",
      "name": "Goode Field",
      "city": "Canton",
      "state": "Texas",
      "country": "US",
      "elevation": 445,
      "lat": 32.5098991394,
      "lon": -96.0027008057,
      "tz": "America\/Chicago"
  },
  "TX45": {
      "icao": "TX45",
      "iata": "",
      "name": "Hawkeye Hunting Club Airport",
      "city": "Center",
      "state": "Texas",
      "country": "US",
      "elevation": 260,
      "lat": 31.7667999268,
      "lon": -97.1294021606,
      "tz": "America\/Chicago"
  },
  "TX46": {
      "icao": "TX46",
      "iata": "",
      "name": "Blackwood Airpark",
      "city": "Cleburne",
      "state": "Texas",
      "country": "US",
      "elevation": 835,
      "lat": 32.4095993042,
      "lon": -97.3871994019,
      "tz": "America\/Chicago"
  },
  "TX47": {
      "icao": "TX47",
      "iata": "",
      "name": "Sandy Creek Ranch Airfield",
      "city": "Revenna",
      "state": "Texas",
      "country": "US",
      "elevation": 547,
      "lat": 33.7076835632,
      "lon": -96.2238464355,
      "tz": "America\/Chicago"
  },
  "TX48": {
      "icao": "TX48",
      "iata": "",
      "name": "Talley Airport",
      "city": "Clifton",
      "state": "Texas",
      "country": "US",
      "elevation": 880,
      "lat": 31.832222,
      "lon": -97.718889,
      "tz": "America\/Chicago"
  },
  "TX49": {
      "icao": "TX49",
      "iata": "",
      "name": "Biggin Hill Airport",
      "city": "Hockley",
      "state": "Texas",
      "country": "US",
      "elevation": 250,
      "lat": 30.1182994843,
      "lon": -95.8600006104,
      "tz": "America\/Chicago"
  },
  "TX51": {
      "icao": "TX51",
      "iata": "",
      "name": "Smith Airport",
      "city": "Corsicana",
      "state": "Texas",
      "country": "US",
      "elevation": 405,
      "lat": 32.2000999451,
      "lon": -96.3293991089,
      "tz": "America\/Chicago"
  },
  "TX52": {
      "icao": "TX52",
      "iata": "",
      "name": "Parrish Airstrip",
      "city": "Cranfills Gap",
      "state": "Texas",
      "country": "US",
      "elevation": 917,
      "lat": 31.7945995331,
      "lon": -97.7739028931,
      "tz": "America\/Chicago"
  },
  "TX54": {
      "icao": "TX54",
      "iata": "",
      "name": "Aero Estates Airport",
      "city": "Celina",
      "state": "Texas",
      "country": "US",
      "elevation": 605,
      "lat": 33.298500061,
      "lon": -96.863899231,
      "tz": "America\/Chicago"
  },
  "TX56": {
      "icao": "TX56",
      "iata": "",
      "name": "Douglass Ranch Airport",
      "city": "Freer",
      "state": "Texas",
      "country": "US",
      "elevation": 680,
      "lat": 27.7154998779,
      "lon": -98.7024993896,
      "tz": "America\/Chicago"
  },
  "TX61": {
      "icao": "TX61",
      "iata": "",
      "name": "Baker's Place Airport",
      "city": "Elgin",
      "state": "Texas",
      "country": "US",
      "elevation": 460,
      "lat": 30.3302001953,
      "lon": -97.4589004517,
      "tz": "America\/Chicago"
  },
  "TX62": {
      "icao": "TX62",
      "iata": "",
      "name": "Rhodes Ranch Airport",
      "city": "Mineola",
      "state": "Texas",
      "country": "US",
      "elevation": 405,
      "lat": 32.6334991455,
      "lon": -95.4391021729,
      "tz": "America\/Chicago"
  },
  "TX64": {
      "icao": "TX64",
      "iata": "",
      "name": "Lazy 9 Ranch Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 1020,
      "lat": 33.2993011475,
      "lon": -97.5272979736,
      "tz": "America\/Chicago"
  },
  "TX66": {
      "icao": "TX66",
      "iata": "",
      "name": "Rebel Field",
      "city": "Alta Loma",
      "state": "Texas",
      "country": "US",
      "elevation": 28,
      "lat": 29.3987998962,
      "lon": -95.0784988403,
      "tz": "America\/Chicago"
  },
  "TX67": {
      "icao": "TX67",
      "iata": "",
      "name": "Embry Ranch Airport",
      "city": "Keene",
      "state": "Texas",
      "country": "US",
      "elevation": 900,
      "lat": 32.3605995178,
      "lon": -97.3024978638,
      "tz": "America\/Chicago"
  },
  "TX68": {
      "icao": "TX68",
      "iata": "",
      "name": "Parker Place Airport",
      "city": "Ector",
      "state": "Texas",
      "country": "US",
      "elevation": 620,
      "lat": 33.5918006897,
      "lon": -96.2766036987,
      "tz": "America\/Chicago"
  },
  "TX70": {
      "icao": "TX70",
      "iata": "",
      "name": "Eberly Ranch Airport",
      "city": "Chappell Hill",
      "state": "Texas",
      "country": "US",
      "elevation": 280,
      "lat": 30.0518894196,
      "lon": -96.2182388306,
      "tz": "America\/Chicago"
  },
  "TX72": {
      "icao": "TX72",
      "iata": "",
      "name": "Wood Triple D Airport",
      "city": "Elgin",
      "state": "Texas",
      "country": "US",
      "elevation": 505,
      "lat": 30.3791007996,
      "lon": -97.1947021484,
      "tz": "America\/Chicago"
  },
  "TX73": {
      "icao": "TX73",
      "iata": "",
      "name": "Big Brown Creek Airstrip",
      "city": "Fairfield",
      "state": "Texas",
      "country": "US",
      "elevation": 323,
      "lat": 31.8309993744,
      "lon": -96.0522003174,
      "tz": "America\/Chicago"
  },
  "TX74": {
      "icao": "TX74",
      "iata": "",
      "name": "Thomas Flying Field",
      "city": "Parker",
      "state": "Texas",
      "country": "US",
      "elevation": 830,
      "lat": 32.2613983154,
      "lon": -97.2752990723,
      "tz": "America\/Chicago"
  },
  "TX75": {
      "icao": "TX75",
      "iata": "",
      "name": "Sandbur Ranches Private Airport",
      "city": "Franklin",
      "state": "Texas",
      "country": "US",
      "elevation": 455,
      "lat": 31.0097007751,
      "lon": -96.540802002,
      "tz": "America\/Chicago"
  },
  "TX78": {
      "icao": "TX78",
      "iata": "",
      "name": "Block Ranch Airport",
      "city": "Alvarado",
      "state": "Texas",
      "country": "US",
      "elevation": 755,
      "lat": 32.3292007446,
      "lon": -97.2319030762,
      "tz": "America\/Chicago"
  },
  "TX79": {
      "icao": "TX79",
      "iata": "",
      "name": "Crescent C Ranch Airport",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 277,
      "lat": 28.0888881683,
      "lon": -99.0488891602,
      "tz": "America\/Chicago"
  },
  "TX81": {
      "icao": "TX81",
      "iata": "",
      "name": "Robotek Airport",
      "city": "Gainesville",
      "state": "Texas",
      "country": "US",
      "elevation": 775,
      "lat": 33.5499992371,
      "lon": -97.0381011963,
      "tz": "America\/Chicago"
  },
  "TX82": {
      "icao": "TX82",
      "iata": "",
      "name": "Laguna-Not A-Park Airstrip",
      "city": "Laguna Park",
      "state": "Texas",
      "country": "US",
      "elevation": 620,
      "lat": 31.8635997772,
      "lon": -97.380897522,
      "tz": "America\/Chicago"
  },
  "TX89": {
      "icao": "TX89",
      "iata": "",
      "name": "Ganze Ranch Airstrip",
      "city": "Garland",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 32.9697990417,
      "lon": -96.6224975586,
      "tz": "America\/Chicago"
  },
  "TX91": {
      "icao": "TX91",
      "iata": "",
      "name": "Madeira Airpark",
      "city": "Garland",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 32.9076004028,
      "lon": -96.5969009399,
      "tz": "America\/Chicago"
  },
  "TX92": {
      "icao": "TX92",
      "iata": "",
      "name": "Green Airport",
      "city": "Georgetown",
      "state": "Texas",
      "country": "US",
      "elevation": 850,
      "lat": 30.5960006714,
      "lon": -97.6694030762,
      "tz": "America\/Chicago"
  },
  "TX93": {
      "icao": "TX93",
      "iata": "",
      "name": "Wright Ranch Airport",
      "city": "Glen Rose",
      "state": "Texas",
      "country": "US",
      "elevation": 900,
      "lat": 32.1668014526,
      "lon": -97.8336029053,
      "tz": "America\/Chicago"
  },
  "TX94": {
      "icao": "TX94",
      "iata": "",
      "name": "Flying R Ranch Airport",
      "city": "Era",
      "state": "Texas",
      "country": "US",
      "elevation": 880,
      "lat": 33.4528999329,
      "lon": -97.2767028809,
      "tz": "America\/Chicago"
  },
  "TX95": {
      "icao": "TX95",
      "iata": "",
      "name": "Coppenger Farm Airport",
      "city": "Godley",
      "state": "Texas",
      "country": "US",
      "elevation": 1010,
      "lat": 32.4140014648,
      "lon": -97.5322036743,
      "tz": "America\/Chicago"
  },
  "TX96": {
      "icao": "TX96",
      "iata": "",
      "name": "Maxwell Field",
      "city": "Sanger",
      "state": "Texas",
      "country": "US",
      "elevation": 825,
      "lat": 33.3992996216,
      "lon": -97.2570037842,
      "tz": "America\/Chicago"
  },
  "TX97": {
      "icao": "TX97",
      "iata": "",
      "name": "Cade Field",
      "city": "Cranfills Gap",
      "state": "Texas",
      "country": "US",
      "elevation": 1160,
      "lat": 31.7649002075,
      "lon": -97.7964019775,
      "tz": "America\/Chicago"
  },
  "TX98": {
      "icao": "TX98",
      "iata": "",
      "name": "Hawkins Private Airport",
      "city": "Godley",
      "state": "Texas",
      "country": "US",
      "elevation": 975,
      "lat": 32.4751014709,
      "lon": -97.5009002686,
      "tz": "America\/Chicago"
  },
  "TX99": {
      "icao": "TX99",
      "iata": "",
      "name": "Williams Field",
      "city": "Goodland",
      "state": "Texas",
      "country": "US",
      "elevation": 4085,
      "lat": 34.0068016052,
      "lon": -102.9869995117,
      "tz": "America\/Chicago"
  },
  "TXKF": {
      "icao": "TXKF",
      "iata": "BDA",
      "name": "L.F. Wade International International Airport",
      "city": "Hamilton",
      "state": "Saint-George\u02bcs",
      "country": "BM",
      "elevation": 12,
      "lat": 32.3639984131,
      "lon": -64.6787033081,
      "tz": "Atlantic\/Bermuda"
  },
  "UA34": {
      "icao": "UA34",
      "iata": "",
      "name": "Chardara Airport",
      "city": "Shardara",
      "state": "Ongtuestik-Qazaqstan",
      "country": "KZ",
      "elevation": 709,
      "lat": 41.2765007019,
      "lon": 67.9710006714,
      "tz": "Asia\/Almaty"
  },
  "UA36": {
      "icao": "UA36",
      "iata": "",
      "name": "Lugovoy Airport",
      "city": "Lugovoy",
      "state": "Zhambyl",
      "country": "KZ",
      "elevation": 2130,
      "lat": 42.9735984802,
      "lon": 72.7264022827,
      "tz": "Asia\/Almaty"
  },
  "UA66": {
      "icao": "UA66",
      "iata": "",
      "name": "Chirchik Airport",
      "city": "Chirchik",
      "state": "Toshkent",
      "country": "UZ",
      "elevation": 2296,
      "lat": 41.5167999268,
      "lon": 69.5754013062,
      "tz": "Asia\/Tashkent"
  },
  "UAAA": {
      "icao": "UAAA",
      "iata": "ALA",
      "name": "Almaty Airport",
      "city": "Almaty",
      "state": "Almaty-Oblysy",
      "country": "KZ",
      "elevation": 2234,
      "lat": 43.3521003723,
      "lon": 77.0404968262,
      "tz": "Asia\/Almaty"
  },
  "UAAH": {
      "icao": "UAAH",
      "iata": "BXH",
      "name": "Balkhash Airport",
      "city": "Balkhash",
      "state": "Qaraghandy",
      "country": "KZ",
      "elevation": 1446,
      "lat": 46.8932991028,
      "lon": 75.0049972534,
      "tz": "Asia\/Almaty"
  },
  "UAAL": {
      "icao": "UAAL",
      "iata": "USJ",
      "name": "Usharal Airport",
      "city": "Usharal",
      "state": "Almaty-Oblysy",
      "country": "KZ",
      "elevation": 1288,
      "lat": 46.190278043,
      "lon": 80.831389126,
      "tz": "Asia\/Almaty"
  },
  "UAAR": {
      "icao": "UAAR",
      "iata": "BXJ",
      "name": "Boralday Airport",
      "city": "Aima Ata",
      "state": "Almaty-Oblysy",
      "country": "KZ",
      "elevation": 2313,
      "lat": 43.3526000977,
      "lon": 76.8836975098,
      "tz": "Asia\/Almaty"
  },
  "UAAT": {
      "icao": "UAAT",
      "iata": "TDK",
      "name": "Taldykorgan Airport",
      "city": "Taldy Kurgan",
      "state": "Almaty-Oblysy",
      "country": "KZ",
      "elevation": 1925,
      "lat": 45.1262016296,
      "lon": 78.4469985962,
      "tz": "Asia\/Almaty"
  },
  "UACC": {
      "icao": "UACC",
      "iata": "NQZ",
      "name": "Astana International Airport",
      "city": "Astana",
      "state": "Aqmola",
      "country": "KZ",
      "elevation": 1165,
      "lat": 51.0222015381,
      "lon": 71.4669036865,
      "tz": "Asia\/Almaty"
  },
  "UACK": {
      "icao": "UACK",
      "iata": "KOV",
      "name": "Kokshetau Airport",
      "city": "Kokshetau",
      "state": "Aqmola",
      "country": "KZ",
      "elevation": 900,
      "lat": 53.3291015625,
      "lon": 69.5945968628,
      "tz": "Asia\/Almaty"
  },
  "UACP": {
      "icao": "UACP",
      "iata": "PPK",
      "name": "Petropavlosk South Airport",
      "city": "Petropavlosk",
      "state": "Soltuestik-Qazaqstan",
      "country": "KZ",
      "elevation": 453,
      "lat": 54.7747001648,
      "lon": 69.1838989258,
      "tz": "Asia\/Almaty"
  },
  "UADD": {
      "icao": "UADD",
      "iata": "DMB",
      "name": "Taraz Airport",
      "city": "Taraz",
      "state": "Zhambyl",
      "country": "KZ",
      "elevation": 2184,
      "lat": 42.8535995483,
      "lon": 71.3035964966,
      "tz": "Asia\/Almaty"
  },
  "UAFA": {
      "icao": "UAFA",
      "iata": "",
      "name": "Tamga Airport",
      "city": "Tamga",
      "state": "Issyk-Kul",
      "country": "KG",
      "elevation": 5655,
      "lat": 42.1530990601,
      "lon": 77.5639038086,
      "tz": "Asia\/Bishkek"
  },
  "UAFB": {
      "icao": "UAFB",
      "iata": "",
      "name": "Batken Airport",
      "city": "Batken",
      "state": "Batken",
      "country": "KG",
      "elevation": 3517,
      "lat": 40.0427017212,
      "lon": 70.8380966187,
      "tz": "Asia\/Bishkek"
  },
  "UAFE": {
      "icao": "UAFE",
      "iata": "",
      "name": "Kerben Airport",
      "city": "Kerben",
      "state": "Jalal-Abad",
      "country": "KG",
      "elevation": 4199,
      "lat": 41.484500885,
      "lon": 71.7337036133,
      "tz": "Asia\/Bishkek"
  },
  "UAFF": {
      "icao": "UAFF",
      "iata": "",
      "name": "Tokmok Airport",
      "city": "Tokmok",
      "state": "Chuey",
      "country": "KG",
      "elevation": 2770,
      "lat": 42.8288993835,
      "lon": 75.3359985352,
      "tz": "Asia\/Bishkek"
  },
  "UAFG": {
      "icao": "UAFG",
      "iata": "",
      "name": "Cholpon-Ata Airport",
      "city": "Cholpon-Ata",
      "state": "Issyk-Kul",
      "country": "KG",
      "elevation": 5420,
      "lat": 42.6537017822,
      "lon": 77.0567016602,
      "tz": "Asia\/Bishkek"
  },
  "UAFI": {
      "icao": "UAFI",
      "iata": "",
      "name": "Isfana Airport",
      "city": "Isfana",
      "state": "Batken",
      "country": "KG",
      "elevation": 4625,
      "lat": 39.8247374413,
      "lon": 69.5688199997,
      "tz": "Asia\/Bishkek"
  },
  "UAFJ": {
      "icao": "UAFJ",
      "iata": "",
      "name": "Jalal-Abad Airport",
      "city": "Jalal-Abad",
      "state": "Jalal-Abad",
      "country": "KG",
      "elevation": 2591,
      "lat": 40.9444007874,
      "lon": 72.9777984619,
      "tz": "Asia\/Bishkek"
  },
  "UAFN": {
      "icao": "UAFN",
      "iata": "",
      "name": "Naryn Airport",
      "city": "Naryn",
      "state": "Naryn",
      "country": "KG",
      "elevation": 6998,
      "lat": 41.4415016174,
      "lon": 76.1305999756,
      "tz": "Asia\/Bishkek"
  },
  "UAFR": {
      "icao": "UAFR",
      "iata": "",
      "name": "Balykchy Airport",
      "city": "Balykchy",
      "state": "Issyk-Kul",
      "country": "KG",
      "elevation": 5326,
      "lat": 42.4374008179,
      "lon": 76.1307983398,
      "tz": "Asia\/Bishkek"
  },
  "UAFS": {
      "icao": "UAFS",
      "iata": "",
      "name": "Kyzyl-Kiya Airport",
      "city": "Kyzyl-Kiya",
      "state": "Fergana",
      "country": "KG",
      "elevation": 2928,
      "lat": 40.2717018127,
      "lon": 72.0470962524,
      "tz": "Asia\/Bishkek"
  },
  "UAFT": {
      "icao": "UAFT",
      "iata": "",
      "name": "Talas Airport",
      "city": "Talas",
      "state": "Talas",
      "country": "KG",
      "elevation": 4153,
      "lat": 42.5059013367,
      "lon": 72.2630996704,
      "tz": "Asia\/Bishkek"
  },
  "UAFW": {
      "icao": "UAFW",
      "iata": "",
      "name": "Kant Air Base",
      "city": "Kant",
      "state": "Chuey",
      "country": "KG",
      "elevation": 2549,
      "lat": 42.8531990051,
      "lon": 74.846496582,
      "tz": "Asia\/Bishkek"
  },
  "UAFZ": {
      "icao": "UAFZ",
      "iata": "",
      "name": "Kazarman Airport",
      "city": "Kazarman",
      "state": "Jalal-Abad",
      "country": "KG",
      "elevation": 4298,
      "lat": 41.4102908129,
      "lon": 74.044418335,
      "tz": "Asia\/Bishkek"
  },
  "UAII": {
      "icao": "UAII",
      "iata": "CIT",
      "name": "Shymkent Airport",
      "city": "Shymkent",
      "state": "Ongtuestik-Qazaqstan",
      "country": "KZ",
      "elevation": 1385,
      "lat": 42.364200592,
      "lon": 69.4788970947,
      "tz": "Asia\/Almaty"
  },
  "UAIT": {
      "icao": "UAIT",
      "iata": "HSA",
      "name": "Hazrat Sultan International Airport",
      "city": "Turkistan",
      "state": "Ongtuestik-Qazaqstan",
      "country": "KZ",
      "elevation": 989,
      "lat": 43.2769012451,
      "lon": 68.1903991699,
      "tz": "Asia\/Almaty"
  },
  "UAKD": {
      "icao": "UAKD",
      "iata": "DZN",
      "name": "Zhezkazgan Airport",
      "city": "Zhezkazgan",
      "state": "Qaraghandy",
      "country": "KZ",
      "elevation": 1250,
      "lat": 47.7083015442,
      "lon": 67.7332992554,
      "tz": "Asia\/Almaty"
  },
  "UAKK": {
      "icao": "UAKK",
      "iata": "KGF",
      "name": "Sary-Arka Airport",
      "city": "Karaganda",
      "state": "Qaraghandy",
      "country": "KZ",
      "elevation": 1765,
      "lat": 49.6707992554,
      "lon": 73.3343963623,
      "tz": "Asia\/Almaty"
  },
  "UAOL": {
      "icao": "UAOL",
      "iata": "BXY",
      "name": "Krainiy Airport",
      "city": "Baikonur",
      "state": "Baikonur",
      "country": "KZ",
      "elevation": 299,
      "lat": 45.6220016,
      "lon": 63.2150002,
      "tz": "Asia\/Qyzylorda"
  },
  "UAON": {
      "icao": "UAON",
      "iata": "",
      "name": "Yubileyniy Airfield",
      "city": "Baikonur",
      "state": "Baikonur",
      "country": "KZ",
      "elevation": 328,
      "lat": 46.0550003052,
      "lon": 63.25,
      "tz": "Asia\/Qyzylorda"
  },
  "UAOO": {
      "icao": "UAOO",
      "iata": "KZO",
      "name": "Kzyl-Orda Southwest Airport",
      "city": "Kzyl-Orda",
      "state": "Qyzylorda",
      "country": "KZ",
      "elevation": 433,
      "lat": 44.7069015503,
      "lon": 65.5924987793,
      "tz": "Asia\/Qyzylorda"
  },
  "UARR": {
      "icao": "UARR",
      "iata": "URA",
      "name": "Uralsk Airport",
      "city": "Uralsk",
      "state": "",
      "country": "KZ",
      "elevation": 125,
      "lat": 51.1507987976,
      "lon": 51.5430984497,
      "tz": "Asia\/Oral"
  },
  "UASA": {
      "icao": "UASA",
      "iata": "",
      "name": "Ayaguz Airport",
      "city": "Ayaguz",
      "state": "East-Kazakhstan",
      "country": "KZ",
      "elevation": 2119,
      "lat": 47.918598175,
      "lon": 80.452796936,
      "tz": "Asia\/Almaty"
  },
  "UASB": {
      "icao": "UASB",
      "iata": "EKB",
      "name": "Ekibastuz Airport",
      "city": "Ekibastuz",
      "state": "Pavlodar",
      "country": "KZ",
      "elevation": 621,
      "lat": 51.5909996033,
      "lon": 75.2149963379,
      "tz": "Asia\/Almaty"
  },
  "UASK": {
      "icao": "UASK",
      "iata": "UKK",
      "name": "Ust-Kamennogorsk Airport",
      "city": "Ust Kamenogorsk",
      "state": "East-Kazakhstan",
      "country": "KZ",
      "elevation": 939,
      "lat": 50.0365982056,
      "lon": 82.4942016602,
      "tz": "Asia\/Almaty"
  },
  "UASP": {
      "icao": "UASP",
      "iata": "PWQ",
      "name": "Pavlodar Airport",
      "city": "Pavlodar",
      "state": "Pavlodar",
      "country": "KZ",
      "elevation": 410,
      "lat": 52.1949996948,
      "lon": 77.0738983154,
      "tz": "Asia\/Almaty"
  },
  "UASS": {
      "icao": "UASS",
      "iata": "PLX",
      "name": "Semey Airport",
      "city": "Semey",
      "state": "East-Kazakhstan",
      "country": "KZ",
      "elevation": 761,
      "lat": 50.351389,
      "lon": 80.234444,
      "tz": "Asia\/Almaty"
  },
  "UATA": {
      "icao": "UATA",
      "iata": "",
      "name": "Aralsk Airport",
      "city": "Aralsk",
      "state": "Qyzylorda",
      "country": "KZ",
      "elevation": 223,
      "lat": 46.8330001831,
      "lon": 61.6170005798,
      "tz": "Asia\/Qyzylorda"
  },
  "UATE": {
      "icao": "UATE",
      "iata": "SCO",
      "name": "Aktau Airport",
      "city": "Aktau",
      "state": "Mangghystau",
      "country": "KZ",
      "elevation": 73,
      "lat": 43.8600997925,
      "lon": 51.091999054,
      "tz": "Asia\/Aqtau"
  },
  "UATG": {
      "icao": "UATG",
      "iata": "GUW",
      "name": "Atyrau Airport",
      "city": "Atyrau",
      "state": "Atyrau",
      "country": "KZ",
      "elevation": -72,
      "lat": 47.1218986511,
      "lon": 51.8213996887,
      "tz": "Asia\/Atyrau"
  },
  "UATR": {
      "icao": "UATR",
      "iata": "",
      "name": "Chelkar Airport",
      "city": "Chelkar",
      "state": "Aqtobe",
      "country": "KZ",
      "elevation": 0,
      "lat": 47.9049987793,
      "lon": 59.6199989319,
      "tz": "Asia\/Aqtobe"
  },
  "UATT": {
      "icao": "UATT",
      "iata": "AKX",
      "name": "Aktobe Airport",
      "city": "Aktyubinsk",
      "state": "Aqtobe",
      "country": "KZ",
      "elevation": 738,
      "lat": 50.2458000183,
      "lon": 57.2066993713,
      "tz": "Asia\/Aqtobe"
  },
  "UAUR": {
      "icao": "UAUR",
      "iata": "AYK",
      "name": "Arkalyk North Airport",
      "city": "Arkalyk",
      "state": "",
      "country": "KZ",
      "elevation": 1266,
      "lat": 50.3185997009,
      "lon": 66.952796936,
      "tz": "Asia\/Qostanay"
  },
  "UAUU": {
      "icao": "UAUU",
      "iata": "KSN",
      "name": "Kostanay West Airport",
      "city": "Kostanay",
      "state": "Qostanay",
      "country": "KZ",
      "elevation": 595,
      "lat": 53.2069015503,
      "lon": 63.5503005981,
      "tz": "Asia\/Qostanay"
  },
  "UBBA": {
      "icao": "UBBA",
      "iata": "",
      "name": "Akstafa Airport",
      "city": "Akstafa",
      "state": "Agstafa",
      "country": "AZ",
      "elevation": 1085,
      "lat": 41.1223983765,
      "lon": 45.4248008728,
      "tz": "Asia\/Baku"
  },
  "UBBB": {
      "icao": "UBBB",
      "iata": "GYD",
      "name": "Heydar Aliyev International Airport",
      "city": "Baku",
      "state": "Baki",
      "country": "AZ",
      "elevation": 10,
      "lat": 40.4674987793,
      "lon": 50.0466995239,
      "tz": "Asia\/Baku"
  },
  "UBBG": {
      "icao": "UBBG",
      "iata": "GNJ",
      "name": "Ganja Airport",
      "city": "Ganja",
      "state": "Goygol-Rayon",
      "country": "AZ",
      "elevation": 1083,
      "lat": 40.737701416,
      "lon": 46.3176002502,
      "tz": "Asia\/Baku"
  },
  "UBBL": {
      "icao": "UBBL",
      "iata": "LLK",
      "name": "Lankaran International Airport",
      "city": "Lankaran",
      "state": "L\u0259nk\u0259ran",
      "country": "AZ",
      "elevation": 30,
      "lat": 38.7463989258,
      "lon": 48.8180007935,
      "tz": "Asia\/Baku"
  },
  "UBBN": {
      "icao": "UBBN",
      "iata": "NAJ",
      "name": "Nakhchivan Airport",
      "city": "Nakhchivan",
      "state": "Nakhichevan",
      "country": "AZ",
      "elevation": 2863,
      "lat": 39.1888008118,
      "lon": 45.4584007263,
      "tz": "Asia\/Baku"
  },
  "UBBQ": {
      "icao": "UBBQ",
      "iata": "GBB",
      "name": "Gabala International Airport",
      "city": "Gabala",
      "state": "Q\u01ddb\u01ddl\u01dd",
      "country": "AZ",
      "elevation": 935,
      "lat": 40.8266666667,
      "lon": 47.7125,
      "tz": "Asia\/Baku"
  },
  "UBBY": {
      "icao": "UBBY",
      "iata": "ZTU",
      "name": "Zaqatala International Airport",
      "city": "Zaqatala",
      "state": "Zaqatala",
      "country": "AZ",
      "elevation": 1279,
      "lat": 41.5622215271,
      "lon": 46.6672210693,
      "tz": "Asia\/Baku"
  },
  "UBEE": {
      "icao": "UBEE",
      "iata": "YLV",
      "name": "Yevlakh Airport",
      "city": "Yevlakh",
      "state": "Yevlax-City",
      "country": "AZ",
      "elevation": 49,
      "lat": 40.6319007874,
      "lon": 47.1418991089,
      "tz": "Asia\/Baku"
  },
  "UCFL": {
      "icao": "UCFL",
      "iata": "IKU",
      "name": "Issyk-Kul International Airport",
      "city": "Tamchy",
      "state": "Issyk-Kul",
      "country": "KG",
      "elevation": 5426,
      "lat": 42.585714,
      "lon": 76.701811,
      "tz": "Asia\/Bishkek"
  },
  "UCFM": {
      "icao": "UCFM",
      "iata": "FRU",
      "name": "Manas International Airport",
      "city": "Bishkek",
      "state": "Chuey",
      "country": "KG",
      "elevation": 2090,
      "lat": 43.061272,
      "lon": 74.477508,
      "tz": "Asia\/Bishkek"
  },
  "UCFO": {
      "icao": "UAFO",
      "iata": "OSS",
      "name": "Osh Airport",
      "city": "Osh",
      "state": "Osh",
      "country": "KG",
      "elevation": 2938,
      "lat": 40.608997,
      "lon": 72.793214,
      "tz": "Asia\/Bishkek"
  },
  "UCFP": {
      "icao": "UAFP",
      "iata": "",
      "name": "Karakol Airport",
      "city": "Karakol",
      "state": "Issyk-Kul",
      "country": "KG",
      "elevation": 5590,
      "lat": 42.508333,
      "lon": 78.407778,
      "tz": "Asia\/Bishkek"
  },
  "UD21": {
      "icao": "UD21",
      "iata": "",
      "name": "Yerevan Yegvard Airport",
      "city": "Yerevan",
      "state": "Kotayk",
      "country": "AM",
      "elevation": 4416,
      "lat": 40.2941017151,
      "lon": 44.5646018982,
      "tz": "Asia\/Yerevan"
  },
  "UDLS": {
      "icao": "UDLS",
      "iata": "",
      "name": "Stepanavan Airport",
      "city": "Stepanavan",
      "state": "Lori",
      "country": "AM",
      "elevation": 4836,
      "lat": 41.048500061,
      "lon": 44.3372001648,
      "tz": "Asia\/Yerevan"
  },
  "UDSG": {
      "icao": "UDSG",
      "iata": "LWN",
      "name": "Gyumri Shirak Airport",
      "city": "Gyumri",
      "state": "Shirak",
      "country": "AM",
      "elevation": 5000,
      "lat": 40.7504005432,
      "lon": 43.8592987061,
      "tz": "Asia\/Yerevan"
  },
  "UDYE": {
      "icao": "UDYE",
      "iata": "",
      "name": "Erebuni Airport",
      "city": "Yerevan",
      "state": "Yerevan",
      "country": "AM",
      "elevation": 2948,
      "lat": 40.1221008301,
      "lon": 44.4650001526,
      "tz": "Asia\/Yerevan"
  },
  "UDYZ": {
      "icao": "UDYZ",
      "iata": "EVN",
      "name": "Zvartnots International Airport",
      "city": "Yerevan",
      "state": "",
      "country": "AM",
      "elevation": 2838,
      "lat": 40.1473007202,
      "lon": 44.3959007263,
      "tz": "Asia\/Yerevan"
  },
  "UEBB": {
      "icao": "UEBB",
      "iata": "",
      "name": "Batagay Airport",
      "city": "Batagay",
      "state": "Chukot",
      "country": "RU",
      "elevation": 696,
      "lat": 67.6480026245,
      "lon": 134.6950073242,
      "tz": "Asia\/Vladivostok"
  },
  "UEBN": {
      "icao": "UEBN",
      "iata": "",
      "name": "Nizhneyansk Airport",
      "city": "Nizhneyansk",
      "state": "Chukot",
      "country": "RU",
      "elevation": 20,
      "lat": 71.43699646,
      "lon": 136.1900024414,
      "tz": "Asia\/Vladivostok"
  },
  "UEBT": {
      "icao": "UEBT",
      "iata": "UKG",
      "name": "Ust-Kuyga Airport",
      "city": "Ust-Kuyga",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 70.0110015869,
      "lon": 135.6450042725,
      "tz": "Asia\/Vladivostok"
  },
  "UEBW": {
      "icao": "UEBW",
      "iata": "",
      "name": "Verkhoyansk Airport",
      "city": "",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 67.5430984497,
      "lon": 133.3988037109,
      "tz": "Asia\/Vladivostok"
  },
  "UECT": {
      "icao": "UECT",
      "iata": "TLK",
      "name": "Talakan Airport",
      "city": "",
      "state": "Chukot",
      "country": "RU",
      "elevation": 1329,
      "lat": 59.876389,
      "lon": 111.044444,
      "tz": "Asia\/Yakutsk"
  },
  "UEEA": {
      "icao": "UEEA",
      "iata": "ADH",
      "name": "Aldan Airport",
      "city": "Aldan",
      "state": "Chukot",
      "country": "RU",
      "elevation": 2241,
      "lat": 58.6027984619,
      "lon": 125.408996582,
      "tz": "Asia\/Yakutsk"
  },
  "UEEE": {
      "icao": "UEEE",
      "iata": "YKS",
      "name": "Yakutsk Airport",
      "city": "Yakutsk",
      "state": "Chukot",
      "country": "RU",
      "elevation": 325,
      "lat": 62.0932998657,
      "lon": 129.7709960938,
      "tz": "Asia\/Yakutsk"
  },
  "UELL": {
      "icao": "UELL",
      "iata": "CNN",
      "name": "Chulman Airport",
      "city": "Chulman",
      "state": "Chukot",
      "country": "RU",
      "elevation": 2812,
      "lat": 56.913898468,
      "lon": 124.9140014648,
      "tz": "Asia\/Yakutsk"
  },
  "UEMA": {
      "icao": "UEMA",
      "iata": "MQJ",
      "name": "Moma Airport",
      "city": "Honuu",
      "state": "Chukot",
      "country": "RU",
      "elevation": 656,
      "lat": 66.4508590698,
      "lon": 143.2615509033,
      "tz": "Asia\/Srednekolymsk"
  },
  "UEMH": {
      "icao": "UEMH",
      "iata": "KDY",
      "name": "Tyopliy Klyuch Airport",
      "city": "Tyopliy Klyuch",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 62.7890014648,
      "lon": 136.8549957275,
      "tz": "Asia\/Khandyga"
  },
  "UEMM": {
      "icao": "UEMM",
      "iata": "GYG",
      "name": "Magan Airport",
      "city": "Magan",
      "state": "Chukot",
      "country": "RU",
      "elevation": 577,
      "lat": 62.1034812927,
      "lon": 129.5452880859,
      "tz": "Asia\/Yakutsk"
  },
  "UEMO": {
      "icao": "UEMO",
      "iata": "OLZ",
      "name": "Olyokminsk Airport",
      "city": "Olyokminsk",
      "state": "Chukot",
      "country": "RU",
      "elevation": 656,
      "lat": 60.3974990845,
      "lon": 120.471000671,
      "tz": "Asia\/Yakutsk"
  },
  "UEMT": {
      "icao": "UEMT",
      "iata": "USR",
      "name": "Ust-Nera Airport",
      "city": "Ust-Nera",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 64.5500030518,
      "lon": 143.1150054932,
      "tz": "Asia\/Ust-Nera"
  },
  "UEMU": {
      "icao": "UEMU",
      "iata": "UMS",
      "name": "Ust-Maya Airport",
      "city": "Ust-Maya",
      "state": "Chukot",
      "country": "RU",
      "elevation": 561,
      "lat": 60.3569984436,
      "lon": 134.4349975586,
      "tz": "Asia\/Khandyga"
  },
  "UENI": {
      "icao": "UENI",
      "iata": "VHV",
      "name": "Verkhnevilyuisk Airport",
      "city": "Verkhnevilyuisk",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 63.4580574036,
      "lon": 120.2691650391,
      "tz": "Asia\/Yakutsk"
  },
  "UENK": {
      "icao": "UENK",
      "iata": "",
      "name": "Kyzyl-Syr Airport",
      "city": "Kyzyl-Syr",
      "state": "Chukot",
      "country": "RU",
      "elevation": 331,
      "lat": 63.8849983215,
      "lon": 122.7770004272,
      "tz": "Asia\/Yakutsk"
  },
  "UENN": {
      "icao": "UENN",
      "iata": "NYR",
      "name": "Nyurba Airport",
      "city": "Nyurba",
      "state": "Chukot",
      "country": "RU",
      "elevation": 394,
      "lat": 63.2949981689,
      "lon": 118.3369979858,
      "tz": "Asia\/Yakutsk"
  },
  "UENS": {
      "icao": "UENS",
      "iata": "SUY",
      "name": "Suntar Airport",
      "city": "Suntar",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 62.1850013733,
      "lon": 117.6350021362,
      "tz": "Asia\/Yakutsk"
  },
  "UENW": {
      "icao": "UENW",
      "iata": "VYI",
      "name": "Vilyuisk Airport",
      "city": "Vilyuisk",
      "state": "Chukot",
      "country": "RU",
      "elevation": 361,
      "lat": 63.7566680908,
      "lon": 121.6933364868,
      "tz": "Asia\/Yakutsk"
  },
  "UERA": {
      "icao": "UERA",
      "iata": "",
      "name": "Aykhal Airport",
      "city": "",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 65.959197998,
      "lon": 111.5465011597,
      "tz": "Asia\/Yakutsk"
  },
  "UERL": {
      "icao": "UERL",
      "iata": "ULK",
      "name": "Lensk Airport",
      "city": "Lensk",
      "state": "Chukot",
      "country": "RU",
      "elevation": 801,
      "lat": 60.7206001282,
      "lon": 114.825996399,
      "tz": "Asia\/Yakutsk"
  },
  "UERO": {
      "icao": "UERO",
      "iata": "ONK",
      "name": "Olenyok Airport",
      "city": "Olenyok",
      "state": "Chukot",
      "country": "RU",
      "elevation": 847,
      "lat": 68.5149993896,
      "lon": 112.4800033569,
      "tz": "Asia\/Yakutsk"
  },
  "UERP": {
      "icao": "UERP",
      "iata": "PYJ",
      "name": "Polyarny Airport",
      "city": "Yakutia",
      "state": "Chukot",
      "country": "RU",
      "elevation": 1660,
      "lat": 66.4003982544,
      "lon": 112.029998779,
      "tz": "Asia\/Yakutsk"
  },
  "UERR": {
      "icao": "UERR",
      "iata": "MJZ",
      "name": "Mirny Airport",
      "city": "Mirny",
      "state": "Chukot",
      "country": "RU",
      "elevation": 1156,
      "lat": 62.5346984863,
      "lon": 114.0390014648,
      "tz": "Asia\/Yakutsk"
  },
  "UERS": {
      "icao": "UERS",
      "iata": "SYS",
      "name": "Saskylakh Airport",
      "city": "Saskylakh",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 71.9279022217,
      "lon": 114.0800018311,
      "tz": "Asia\/Yakutsk"
  },
  "UERT": {
      "icao": "UERT",
      "iata": "",
      "name": "Vitim Airport",
      "city": "Vitim",
      "state": "Chukot",
      "country": "RU",
      "elevation": 610,
      "lat": 59.4580001831,
      "lon": 112.56300354,
      "tz": "Asia\/Yakutsk"
  },
  "UESG": {
      "icao": "UESG",
      "iata": "BGN",
      "name": "Belaya Gora Airport",
      "city": "Belaya Gora",
      "state": "Sakha Republic",
      "country": "RU",
      "elevation": 82,
      "lat": 68.556944,
      "lon": 146.234722,
      "tz": "Asia\/Magadan"
  },
  "UESO": {
      "icao": "UESO",
      "iata": "CKH",
      "name": "Chokurdakh Airport",
      "city": "Chokurdah",
      "state": "Chukot",
      "country": "RU",
      "elevation": 151,
      "lat": 70.6231002808,
      "lon": 147.9019927979,
      "tz": "Asia\/Srednekolymsk"
  },
  "UESS": {
      "icao": "UESS",
      "iata": "CYX",
      "name": "Cherskiy Airport",
      "city": "Cherskiy",
      "state": "Chukot",
      "country": "RU",
      "elevation": 20,
      "lat": 68.7406005859,
      "lon": 161.3379974365,
      "tz": "Asia\/Srednekolymsk"
  },
  "UEST": {
      "icao": "UEST",
      "iata": "IKS",
      "name": "Tiksi Airport",
      "city": "Tiksi",
      "state": "Chukot",
      "country": "RU",
      "elevation": 26,
      "lat": 71.6977005005,
      "lon": 128.9029998779,
      "tz": "Asia\/Yakutsk"
  },
  "UESU": {
      "icao": "UESU",
      "iata": "ZKP",
      "name": "Zyryanka Airport",
      "city": "Zyryanka",
      "state": "Chukot",
      "country": "RU",
      "elevation": 181,
      "lat": 65.7367019653,
      "lon": 150.705001831,
      "tz": "Asia\/Srednekolymsk"
  },
  "UEVV": {
      "icao": "UEVV",
      "iata": "ZIX",
      "name": "Zhigansk Airport",
      "city": "Zhigansk",
      "state": "Chukot",
      "country": "RU",
      "elevation": 292,
      "lat": 66.7965011597,
      "lon": 123.361000061,
      "tz": "Asia\/Yakutsk"
  },
  "UEWD": {
      "icao": "UEWD",
      "iata": "",
      "name": "Deputatskiy Airport",
      "city": "Deputatskiy",
      "state": "Chukot",
      "country": "RU",
      "elevation": 0,
      "lat": 69.3926010132,
      "lon": 139.8903045654,
      "tz": "Asia\/Vladivostok"
  },
  "UG0U": {
      "icao": "UG0U",
      "iata": "",
      "name": "Gudauta Air Base",
      "city": "Gudauta",
      "state": "",
      "country": "GE",
      "elevation": 79,
      "lat": 43.1040000916,
      "lon": 40.5792999268,
      "tz": "Asia\/Tbilisi"
  },
  "UGEJ": {
      "icao": "UGEJ",
      "iata": "",
      "name": "Dzhermuk Airport",
      "city": "Dzhermuk",
      "state": "Vayots-Dzor",
      "country": "AM",
      "elevation": 0,
      "lat": 39.8240013123,
      "lon": 45.6739997864,
      "tz": "Asia\/Yerevan"
  },
  "UGKO": {
      "icao": "UGKO",
      "iata": "KUT",
      "name": "Kopitnari Airport",
      "city": "Kutaisi",
      "state": "",
      "country": "GE",
      "elevation": 223,
      "lat": 42.176700592,
      "lon": 42.4826011658,
      "tz": "Asia\/Tbilisi"
  },
  "UGMM": {
      "icao": "UGMM",
      "iata": "",
      "name": "Mukhrani Airport",
      "city": "",
      "state": "Shida-Kartli",
      "country": "GE",
      "elevation": 1807,
      "lat": 41.916,
      "lon": 44.566,
      "tz": "Asia\/Tbilisi"
  },
  "UGSB": {
      "icao": "UGSB",
      "iata": "BUS",
      "name": "Batumi International Airport",
      "city": "Batumi",
      "state": "Ajaria",
      "country": "GE",
      "elevation": 105,
      "lat": 41.6102981567,
      "lon": 41.5997009277,
      "tz": "Asia\/Tbilisi"
  },
  "UGSP": {
      "icao": "UGSP",
      "iata": "",
      "name": "Poti East Airport",
      "city": "Poti",
      "state": "Samegrelo-and-Zemo-Svaneti",
      "country": "GE",
      "elevation": 3,
      "lat": 42.1629024102,
      "lon": 41.7058074474,
      "tz": "Asia\/Tbilisi"
  },
  "UGSS": {
      "icao": "UGSS",
      "iata": "SUI",
      "name": "Sukhumi Dranda Airport",
      "city": "Sukhumi",
      "state": "Abkhazia",
      "country": "GE",
      "elevation": 53,
      "lat": 42.8582000732,
      "lon": 41.1281013489,
      "tz": "Asia\/Tbilisi"
  },
  "UGTB": {
      "icao": "UGTB",
      "iata": "TBS",
      "name": "Tbilisi International Airport",
      "city": "Tbilisi",
      "state": "",
      "country": "GE",
      "elevation": 1624,
      "lat": 41.6692008972,
      "lon": 44.95470047,
      "tz": "Asia\/Tbilisi"
  },
  "UHB1": {
      "icao": "UHB1",
      "iata": "",
      "name": "Birofeld Air Base",
      "city": "Birofeld",
      "state": "Jewish-Autonomous-Oblast",
      "country": "RU",
      "elevation": 0,
      "lat": 48.4599990845,
      "lon": 132.6600036621,
      "tz": "Asia\/Vladivostok"
  },
  "UHBB": {
      "icao": "UHBB",
      "iata": "BQS",
      "name": "Ignatyevo Airport",
      "city": "Blagoveschensk",
      "state": "Amur",
      "country": "RU",
      "elevation": 638,
      "lat": 50.4253997803,
      "lon": 127.4120025635,
      "tz": "Asia\/Yakutsk"
  },
  "UHBE": {
      "icao": "UHBE",
      "iata": "",
      "name": "Zeya Airport",
      "city": "Zeya",
      "state": "Amur",
      "country": "RU",
      "elevation": 0,
      "lat": 53.6904983521,
      "lon": 127.0907974243,
      "tz": "Asia\/Yakutsk"
  },
  "UHBI": {
      "icao": "UHBI",
      "iata": "GDG",
      "name": "Magdagachi Airport",
      "city": "Magdagachi",
      "state": "Amur",
      "country": "RU",
      "elevation": 0,
      "lat": 53.4733009338,
      "lon": 125.7949981689,
      "tz": "Asia\/Yakutsk"
  },
  "UHBP": {
      "icao": "UHBP",
      "iata": "",
      "name": "Ekimchan Airport",
      "city": "Ekimchan",
      "state": "Amur",
      "country": "RU",
      "elevation": 1781,
      "lat": 53.0760993958,
      "lon": 132.9510040283,
      "tz": "Asia\/Yakutsk"
  },
  "UHBS": {
      "icao": "UHBS",
      "iata": "",
      "name": "Svobodny Airport",
      "city": "Svobodny",
      "state": "Amur",
      "country": "RU",
      "elevation": 600,
      "lat": 51.4550018311,
      "lon": 128.0983276367,
      "tz": "Asia\/Yakutsk"
  },
  "UHBW": {
      "icao": "UHBW",
      "iata": "TYD",
      "name": "Tynda Airport",
      "city": "Tynda",
      "state": "Amur",
      "country": "RU",
      "elevation": 2001,
      "lat": 55.284198761,
      "lon": 124.7789993286,
      "tz": "Asia\/Yakutsk"
  },
  "UHHB": {
      "icao": "UHHB",
      "iata": "",
      "name": "Birobidzhan Yuzhniy Airfield",
      "city": "",
      "state": "Jewish-Autonomous-Oblast",
      "country": "RU",
      "elevation": 0,
      "lat": 48.5820007324,
      "lon": 133.0429992676,
      "tz": "Asia\/Vladivostok"
  },
  "UHHH": {
      "icao": "UHHH",
      "iata": "KHV",
      "name": "Khabarovsk-Novy Airport",
      "city": "Khabarovsk",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 244,
      "lat": 48.5279998779,
      "lon": 135.18800354,
      "tz": "Asia\/Vladivostok"
  },
  "UHHT": {
      "icao": "UHHT",
      "iata": "",
      "name": "Khabarovsk Airport",
      "city": "Khabarovsk",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 262,
      "lat": 48.5250015259,
      "lon": 135.1529998779,
      "tz": "Asia\/Vladivostok"
  },
  "UHHY": {
      "icao": "UHHY",
      "iata": "",
      "name": "Chumikan Airport",
      "city": "Chumikan",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 0,
      "lat": 54.6959991455,
      "lon": 135.2859954834,
      "tz": "Asia\/Vladivostok"
  },
  "UHK1": {
      "icao": "UHK1",
      "iata": "",
      "name": "Blagodatnoye Air Base",
      "city": "Blagodatnoye",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 0,
      "lat": 48.4129981995,
      "lon": 135.4149932861,
      "tz": "Asia\/Vladivostok"
  },
  "UHK2": {
      "icao": "UHK2",
      "iata": "",
      "name": "Garovka Air Base",
      "city": "Khabarovsk",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 0,
      "lat": 48.3950004578,
      "lon": 135.220993042,
      "tz": "Asia\/Vladivostok"
  },
  "UHKD": {
      "icao": "UHKD",
      "iata": "",
      "name": "Dzemgi Airport",
      "city": "Komsomolsk-na-Amur",
      "state": "",
      "country": "RU",
      "elevation": 89,
      "lat": 50.6055984497,
      "lon": 137.0809936523,
      "tz": "Asia\/Vladivostok"
  },
  "UHKG": {
      "icao": "UHKG",
      "iata": "",
      "name": "Kamenny Ruchey Naval Air Base",
      "city": "Sovetskaya Gavan",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 659,
      "lat": 49.2357025146,
      "lon": 140.1930999756,
      "tz": "Asia\/Vladivostok"
  },
  "UHKK": {
      "icao": "UHKK",
      "iata": "KXK",
      "name": "Komsomolsk-on-Amur Airport",
      "city": "Komsomolsk-on-Amur",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 92,
      "lat": 50.4090003967,
      "lon": 136.9340057373,
      "tz": "Asia\/Vladivostok"
  },
  "UHKM": {
      "icao": "UHKM",
      "iata": "GVN",
      "name": "Maygatka Airport.",
      "city": "Sovetskaya Gavan",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 768,
      "lat": 48.9269981384,
      "lon": 140.033996582,
      "tz": "Asia\/Vladivostok"
  },
  "UHMA": {
      "icao": "UHMA",
      "iata": "DYR",
      "name": "Ugolny Airport",
      "city": "Anadyr",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 194,
      "lat": 64.7349014282,
      "lon": 177.7409973145,
      "tz": "Asia\/Anadyr"
  },
  "UHMD": {
      "icao": "UHMD",
      "iata": "PVS",
      "name": "Provideniya Bay Airport",
      "city": "Chukotka",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 72,
      "lat": 64.3780975342,
      "lon": -173.2429962158,
      "tz": "Asia\/Anadyr"
  },
  "UHME": {
      "icao": "UHME",
      "iata": "",
      "name": "Zaliv Kresta",
      "city": "Egvekinot",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 0,
      "lat": 66.3583984375,
      "lon": -179.1076965332,
      "tz": "Asia\/Anadyr"
  },
  "UHMF": {
      "icao": "UHMF",
      "iata": "",
      "name": "Omsukchan Airport",
      "city": "Omsukchan",
      "state": "Magadan",
      "country": "RU",
      "elevation": 1732,
      "lat": 62.4570007324,
      "lon": 155.7449951172,
      "tz": "Asia\/Magadan"
  },
  "UHMG": {
      "icao": "UHMG",
      "iata": "",
      "name": "Chaybukha Airport",
      "city": "Chaybukha",
      "state": "Magadan",
      "country": "RU",
      "elevation": 207,
      "lat": 61.8349990845,
      "lon": 160.5480041504,
      "tz": "Asia\/Magadan"
  },
  "UHMI": {
      "icao": "UHMI",
      "iata": "",
      "name": "Mys Shmidta Airport",
      "city": "Mys Shmidta",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 20,
      "lat": 68.8683013916,
      "lon": -179.3730010986,
      "tz": "Asia\/Anadyr"
  },
  "UHMK": {
      "icao": "UHMK",
      "iata": "KPW",
      "name": "Keperveem Airport",
      "city": "Keperveem",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 623,
      "lat": 67.8450012207,
      "lon": 166.13999939,
      "tz": "Asia\/Anadyr"
  },
  "UHML": {
      "icao": "UHML",
      "iata": "",
      "name": "Lavrentiya Airport",
      "city": "Lavrentiya",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 30,
      "lat": 65.5800018311,
      "lon": -170.996673584,
      "tz": "Asia\/Anadyr"
  },
  "UHMM": {
      "icao": "UHMM",
      "iata": "GDX",
      "name": "Sokol Airport",
      "city": "Magadan",
      "state": "Magadan",
      "country": "RU",
      "elevation": 574,
      "lat": 59.9109992981,
      "lon": 150.7200012207,
      "tz": "Asia\/Magadan"
  },
  "UHMN": {
      "icao": "UHMN",
      "iata": "",
      "name": "Omolon Airport",
      "city": "Omolon",
      "state": "Magadan",
      "country": "RU",
      "elevation": 0,
      "lat": 65.2399978638,
      "lon": 160.5399932861,
      "tz": "Asia\/Anadyr"
  },
  "UHMO": {
      "icao": "UHMO",
      "iata": "KVM",
      "name": "Markovo Airport",
      "city": "Markovo",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 0,
      "lat": 64.6669998169,
      "lon": 170.4170074463,
      "tz": "Asia\/Anadyr"
  },
  "UHMP": {
      "icao": "UHMP",
      "iata": "PWE",
      "name": "Pevek Airport",
      "city": "Pevek",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 11,
      "lat": 69.7833023071,
      "lon": 170.5970001221,
      "tz": "Asia\/Anadyr"
  },
  "UHMR": {
      "icao": "UHMR",
      "iata": "",
      "name": "Beringovskiy Airport",
      "city": "Beringovsky",
      "state": "Chukotka",
      "country": "RU",
      "elevation": 3,
      "lat": 63.0200004578,
      "lon": 179.2929992676,
      "tz": "Asia\/Anadyr"
  },
  "UHMS": {
      "icao": "UHMS",
      "iata": "",
      "name": "Seymchan Airport",
      "city": "Seymchan",
      "state": "Magadan",
      "country": "RU",
      "elevation": 679,
      "lat": 62.9207801819,
      "lon": 152.4227752686,
      "tz": "Asia\/Magadan"
  },
  "UHMT": {
      "icao": "UHMT",
      "iata": "",
      "name": "Magadan-13 Airport",
      "city": "Magadan",
      "state": "Magadan",
      "country": "RU",
      "elevation": 164,
      "lat": 59.623298645,
      "lon": 150.9219970703,
      "tz": "Asia\/Magadan"
  },
  "UHMW": {
      "icao": "UHMW",
      "iata": "SWV",
      "name": "Severo-Evensk Airport",
      "city": "Evensk",
      "state": "Magadan",
      "country": "RU",
      "elevation": 0,
      "lat": 61.9216651917,
      "lon": 159.2299957275,
      "tz": "Asia\/Magadan"
  },
  "UHNA": {
      "icao": "UHNA",
      "iata": "",
      "name": "Munuk Airport",
      "city": "Ayan",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 0,
      "lat": 56.4676017761,
      "lon": 138.1779937744,
      "tz": "Asia\/Vladivostok"
  },
  "UHNB": {
      "icao": "UHNB",
      "iata": "BQG",
      "name": "Bogorodskoye Airport",
      "city": "Bogorodskoye",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 0,
      "lat": 52.38,
      "lon": 140.448,
      "tz": "Asia\/Vladivostok"
  },
  "UHNN": {
      "icao": "UHNN",
      "iata": "NLI",
      "name": "Nikolayevsk-na-Amure Airport",
      "city": "Nikolayevsk-na-Amure Airport",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 0,
      "lat": 53.1549987793,
      "lon": 140.6499938965,
      "tz": "Asia\/Vladivostok"
  },
  "UHOO": {
      "icao": "UHOO",
      "iata": "OHO",
      "name": "Okhotsk Airport",
      "city": "Okhotsk",
      "state": "Khabarovsk",
      "country": "RU",
      "elevation": 0,
      "lat": 59.4100646973,
      "lon": 143.0565032959,
      "tz": "Asia\/Vladivostok"
  },
  "UHPA": {
      "icao": "UHPA",
      "iata": "",
      "name": "Ust-Pakhachi Airport",
      "city": "Ust-Pakhachi",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 10,
      "lat": 60.5567016602,
      "lon": 169.1100006104,
      "tz": "Asia\/Kamchatka"
  },
  "UHPG": {
      "icao": "UHPG",
      "iata": "",
      "name": "Tigil Airport",
      "city": "Tigil",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 430,
      "lat": 57.7840003967,
      "lon": 158.7299957275,
      "tz": "Asia\/Kamchatka"
  },
  "UHPH": {
      "icao": "UHPH",
      "iata": "",
      "name": "Khalaktyrka Airport",
      "city": "Petropavlovsk-Kamchatskiy",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 0,
      "lat": 53.0250015259,
      "lon": 158.720993042,
      "tz": "Asia\/Kamchatka"
  },
  "UHPK": {
      "icao": "UHPK",
      "iata": "",
      "name": "Ust-Kamchatsk Airport",
      "city": "Ust-Kamchatsk",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 200,
      "lat": 56.2386016846,
      "lon": 162.68800354,
      "tz": "Asia\/Kamchatka"
  },
  "UHPL": {
      "icao": "UHPL",
      "iata": "",
      "name": "Palana Airport",
      "city": "Palana",
      "state": "",
      "country": "RU",
      "elevation": 112,
      "lat": 59.0816993713,
      "lon": 159.8880004883,
      "tz": "Asia\/Kamchatka"
  },
  "UHPM": {
      "icao": "UHPM",
      "iata": "",
      "name": "Milkovo Airport",
      "city": "Milkovo",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 410,
      "lat": 54.6805801392,
      "lon": 158.5485076904,
      "tz": "Asia\/Kamchatka"
  },
  "UHPP": {
      "icao": "UHPP",
      "iata": "PKC",
      "name": "Yelizovo Airport",
      "city": "Petropavlovsk-Kamchatsky",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 131,
      "lat": 53.1679000854,
      "lon": 158.453994751,
      "tz": "Asia\/Kamchatka"
  },
  "UHPT": {
      "icao": "UHPT",
      "iata": "",
      "name": "Tilichiki Airport",
      "city": "Tilichiki",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 7,
      "lat": 60.3828010559,
      "lon": 166.0260009766,
      "tz": "Asia\/Kamchatka"
  },
  "UHPU": {
      "icao": "UHPU",
      "iata": "",
      "name": "Ust-Khayryuzovo Airport",
      "city": "Ust-Khayryuzovo",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 0,
      "lat": 57.0999984741,
      "lon": 156.7350006104,
      "tz": "Asia\/Kamchatka"
  },
  "UHPX": {
      "icao": "UHPX",
      "iata": "",
      "name": "Nikolskoye Airport",
      "city": "Nikolskoye",
      "state": "Kamtsjatka",
      "country": "RU",
      "elevation": 49,
      "lat": 55.1783332825,
      "lon": 166.0483398438,
      "tz": "Asia\/Kamchatka"
  },
  "UHSB": {
      "icao": "UHSB",
      "iata": "BVV",
      "name": "Burevestnik Airport",
      "city": "Iturup Island",
      "state": "Sakhalin",
      "country": "RU",
      "elevation": 79,
      "lat": 44.9199981689,
      "lon": 147.6219940186,
      "tz": "Asia\/Ust-Nera"
  },
  "UHSH": {
      "icao": "UHSH",
      "iata": "OHH",
      "name": "Okha Airport",
      "city": "Okha",
      "state": "Sakhalin",
      "country": "RU",
      "elevation": 115,
      "lat": 53.5200004578,
      "lon": 142.88999939,
      "tz": "Asia\/Sakhalin"
  },
  "UHSK": {
      "icao": "UHSK",
      "iata": "EKS",
      "name": "Shakhtyorsk Airport",
      "city": "Shakhtersk",
      "state": "Sakhalin",
      "country": "RU",
      "elevation": 50,
      "lat": 49.1902999878,
      "lon": 142.082992554,
      "tz": "Asia\/Sakhalin"
  },
  "UHSM": {
      "icao": "UHSM",
      "iata": "DEE",
      "name": "Mendeleyevo Airport",
      "city": "Kunashir Island",
      "state": "Sakhalin",
      "country": "RU",
      "elevation": 584,
      "lat": 43.9584007263,
      "lon": 145.682998657,
      "tz": "Asia\/Ust-Nera"
  },
  "UHSO": {
      "icao": "UHSO",
      "iata": "ZZO",
      "name": "Zonalnoye Airport",
      "city": "Tymovskoye",
      "state": "Sakhalin",
      "country": "RU",
      "elevation": 479,
      "lat": 50.6692008972,
      "lon": 142.761001587,
      "tz": "Asia\/Sakhalin"
  },
  "UHSS": {
      "icao": "UHSS",
      "iata": "UUS",
      "name": "Yuzhno-Sakhalinsk Airport",
      "city": "Yuzhno-Sakhalinsk",
      "state": "Sakhalin",
      "country": "RU",
      "elevation": 59,
      "lat": 46.8886985779,
      "lon": 142.7180023193,
      "tz": "Asia\/Sakhalin"
  },
  "UHU1": {
      "icao": "UHU1",
      "iata": "",
      "name": "Zolotaya Dolina Air Base",
      "city": "Vladimiro-Aleksandrovskoye",
      "state": "Primorskiy",
      "country": "RU",
      "elevation": 0,
      "lat": 42.9599990845,
      "lon": 133.1150054932,
      "tz": "Asia\/Vladivostok"
  },
  "UHWP": {
      "icao": "UHWP",
      "iata": "TLY",
      "name": "Plastun Airport",
      "city": "Plastun",
      "state": "Primorskiy",
      "country": "RU",
      "elevation": 0,
      "lat": 44.8149986267,
      "lon": 136.2920074463,
      "tz": "Asia\/Vladivostok"
  },
  "UHWW": {
      "icao": "UHWW",
      "iata": "VVO",
      "name": "Vladivostok International Airport",
      "city": "Vladivostok",
      "state": "Primorskiy",
      "country": "RU",
      "elevation": 46,
      "lat": 43.3989982605,
      "lon": 132.1479949951,
      "tz": "Asia\/Vladivostok"
  },
  "UIAA": {
      "icao": "UIAA",
      "iata": "HTA",
      "name": "Chita-Kadala Airport",
      "city": "Chita",
      "state": "Transbaikal-Territory",
      "country": "RU",
      "elevation": 2272,
      "lat": 52.0262985229,
      "lon": 113.3059997559,
      "tz": "Asia\/Chita"
  },
  "UIAE": {
      "icao": "UIAE",
      "iata": "",
      "name": "Krasnokamensk Airport",
      "city": "Krasnokamensk",
      "state": "Transbaikal-Territory",
      "country": "RU",
      "elevation": 2139,
      "lat": 50.0331077576,
      "lon": 118.0611419678,
      "tz": "Asia\/Chita"
  },
  "UIAO": {
      "icao": "UIAO",
      "iata": "",
      "name": "Barguzin Airport",
      "city": "Barguzin",
      "state": "Respublika-Buryatiya",
      "country": "RU",
      "elevation": 0,
      "lat": 53.5870018005,
      "lon": 109.6999969482,
      "tz": "Asia\/Irkutsk"
  },
  "UIAR": {
      "icao": "UIAR",
      "iata": "",
      "name": "Chara Airport",
      "city": "Chara",
      "state": "Transbaikal-Territory",
      "country": "RU",
      "elevation": 2201,
      "lat": 56.9133338928,
      "lon": 118.2699966431,
      "tz": "Asia\/Chita"
  },
  "UIBB": {
      "icao": "UIBB",
      "iata": "BTK",
      "name": "Bratsk Airport",
      "city": "Bratsk",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1610,
      "lat": 56.3706016541,
      "lon": 101.6979980469,
      "tz": "Asia\/Irkutsk"
  },
  "UIBS": {
      "icao": "UIBS",
      "iata": "UIK",
      "name": "Ust-Ilimsk Airport",
      "city": "Ust-Ilimsk",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1339,
      "lat": 58.136100769,
      "lon": 102.5650024414,
      "tz": "Asia\/Irkutsk"
  },
  "UIBV": {
      "icao": "UIBV",
      "iata": "",
      "name": "Zheleznogorsk Airport",
      "city": "Zheleznogorsk-Ilimsky",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1946,
      "lat": 56.487701416,
      "lon": 104.1060028076,
      "tz": "Asia\/Irkutsk"
  },
  "UIIB": {
      "icao": "UIIB",
      "iata": "",
      "name": "Belaya Air Base",
      "city": "Usolye-Sibirskoye",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1503,
      "lat": 52.9150009155,
      "lon": 103.5749969482,
      "tz": "Asia\/Irkutsk"
  },
  "UIII": {
      "icao": "UIII",
      "iata": "IKT",
      "name": "Irkutsk Airport",
      "city": "Irkutsk",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1675,
      "lat": 52.2680015564,
      "lon": 104.388999939,
      "tz": "Asia\/Irkutsk"
  },
  "UIIR": {
      "icao": "UIIR",
      "iata": "",
      "name": "Irkutsk Northwest Airport",
      "city": "Irkutsk",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1470,
      "lat": 52.3683013916,
      "lon": 104.1829986572,
      "tz": "Asia\/Irkutsk"
  },
  "UIIV": {
      "icao": "UIIV",
      "iata": "",
      "name": "Zhigalovo Airport",
      "city": "",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 0,
      "lat": 54.7999992371,
      "lon": 105.1500015259,
      "tz": "Asia\/Irkutsk"
  },
  "UIKB": {
      "icao": "UIKB",
      "iata": "ODO",
      "name": "Bodaybo Airport",
      "city": "Bodaybo",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 919,
      "lat": 57.8661003113,
      "lon": 114.242996216,
      "tz": "Asia\/Irkutsk"
  },
  "UIKE": {
      "icao": "UIKE",
      "iata": "ERG",
      "name": "Yerbogachen Airport",
      "city": "Erbogachen",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 400,
      "lat": 61.2750015259,
      "lon": 108.029998779,
      "tz": "Asia\/Irkutsk"
  },
  "UIKK": {
      "icao": "UIKK",
      "iata": "KCK",
      "name": "Kirensk Airport",
      "city": "Kirensk",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 0,
      "lat": 57.7729988098,
      "lon": 108.0640029907,
      "tz": "Asia\/Irkutsk"
  },
  "UIKM": {
      "icao": "UIKM",
      "iata": "",
      "name": "Mama Airport",
      "city": "Mama",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 659,
      "lat": 58.313331604,
      "lon": 112.8899993896,
      "tz": "Asia\/Irkutsk"
  },
  "UIOO": {
      "icao": "UIOO",
      "iata": "",
      "name": "Ust-Ordynskiy Airport",
      "city": "Ust-Ordynskiy",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1775,
      "lat": 52.8199996948,
      "lon": 104.7399978638,
      "tz": "Asia\/Irkutsk"
  },
  "UITK": {
      "icao": "UITK",
      "iata": "",
      "name": "Kazachinskaya Airport",
      "city": "Kazachinskaya",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 1499,
      "lat": 56.2801513672,
      "lon": 107.567565918,
      "tz": "Asia\/Irkutsk"
  },
  "UITT": {
      "icao": "UITT",
      "iata": "UKX",
      "name": "Ust-Kut Airport",
      "city": "Ust-Kut",
      "state": "Irkutsk",
      "country": "RU",
      "elevation": 2188,
      "lat": 56.8567008972,
      "lon": 105.7300033569,
      "tz": "Asia\/Irkutsk"
  },
  "UIUB": {
      "icao": "UIUB",
      "iata": "",
      "name": "Bagdarin Airport",
      "city": "",
      "state": "Respublika-Buryatiya",
      "country": "RU",
      "elevation": 3084,
      "lat": 54.3692016602,
      "lon": 113.4789962769,
      "tz": "Asia\/Irkutsk"
  },
  "UIUN": {
      "icao": "UIUN",
      "iata": "",
      "name": "Nizhneangarsk Airport",
      "city": "Nizhneangarsk",
      "state": "Respublika-Buryatiya",
      "country": "RU",
      "elevation": 1545,
      "lat": 55.8008003235,
      "lon": 109.5950012207,
      "tz": "Asia\/Irkutsk"
  },
  "UIUU": {
      "icao": "UIUU",
      "iata": "UUD",
      "name": "Ulan-Ude Airport (Mukhino)",
      "city": "Ulan Ude",
      "state": "Respublika-Buryatiya",
      "country": "RU",
      "elevation": 1690,
      "lat": 51.807800293,
      "lon": 107.43800354,
      "tz": "Asia\/Irkutsk"
  },
  "UJAP": {
      "icao": "UJAP",
      "iata": "UJE",
      "name": "Ujae Atoll Airport",
      "city": "Ujae Atoll",
      "state": "Ujae-Atoll",
      "country": "MH",
      "elevation": 0,
      "lat": 8.9280595779,
      "lon": 165.7619934082,
      "tz": "Pacific\/Majuro"
  },
  "UK59": {
      "icao": "UK59",
      "iata": "",
      "name": "Chuhuiv Airport",
      "city": "Chuhuiv",
      "state": "Kharkiv",
      "country": "UA",
      "elevation": 453,
      "lat": 49.8381996155,
      "lon": 36.6412010193,
      "tz": "Europe\/Kiev"
  },
  "UK61": {
      "icao": "UK61",
      "iata": "",
      "name": "Cherlyany Airport",
      "city": "Gorodok",
      "state": "Lviv",
      "country": "UA",
      "elevation": 895,
      "lat": 49.7378997803,
      "lon": 23.6688995361,
      "tz": "Europe\/Kiev"
  },
  "UKBB": {
      "icao": "UKBB",
      "iata": "KBP",
      "name": "Boryspil International Airport",
      "city": "Kiev",
      "state": "Kyiv",
      "country": "UA",
      "elevation": 427,
      "lat": 50.3450012207,
      "lon": 30.8946990967,
      "tz": "Europe\/Kiev"
  },
  "UKBF": {
      "icao": "UKBF",
      "iata": "",
      "name": "Konotop Air Base",
      "city": "Konotop",
      "state": "Sumy",
      "country": "UA",
      "elevation": 476,
      "lat": 51.25,
      "lon": 33.1500015259,
      "tz": "Europe\/Kiev"
  },
  "UKCC": {
      "icao": "UKCC",
      "iata": "DOK",
      "name": "Donetsk International Airport",
      "city": "Donetsk",
      "state": "Donetsk",
      "country": "UA",
      "elevation": 791,
      "lat": 48.073600769,
      "lon": 37.7397003174,
      "tz": "Europe\/Kiev"
  },
  "UKCK": {
      "icao": "UKCK",
      "iata": "KRQ",
      "name": "Kramatorsk Airport",
      "city": "Kramatorsk",
      "state": "Donetsk",
      "country": "UA",
      "elevation": 646,
      "lat": 48.7056007385,
      "lon": 37.6288986206,
      "tz": "Europe\/Kiev"
  },
  "UKCM": {
      "icao": "UKCM",
      "iata": "MPW",
      "name": "Mariupol International Airport",
      "city": "Mariupol",
      "state": "Donetsk",
      "country": "UA",
      "elevation": 251,
      "lat": 47.0760993958,
      "lon": 37.4496002197,
      "tz": "Europe\/Kiev"
  },
  "UKCS": {
      "icao": "UKCS",
      "iata": "SEV",
      "name": "Sievierodonetsk Airport",
      "city": "Sievierodonetsk",
      "state": "Luhansk",
      "country": "UA",
      "elevation": 236,
      "lat": 48.9003301015,
      "lon": 38.5416698456,
      "tz": "Europe\/Kiev"
  },
  "UKCW": {
      "icao": "UKCW",
      "iata": "VSG",
      "name": "Luhansk International Airport",
      "city": "Luhansk",
      "state": "Luhansk",
      "country": "UA",
      "elevation": 636,
      "lat": 48.4174003601,
      "lon": 39.3740997314,
      "tz": "Europe\/Kiev"
  },
  "UKDB": {
      "icao": "UKDB",
      "iata": "ERD",
      "name": "Berdyansk Airport",
      "city": "Berdyansk",
      "state": "Zaporizhia",
      "country": "UA",
      "elevation": 171,
      "lat": 46.8149986267,
      "lon": 36.7580986023,
      "tz": "Europe\/Zaporozhye"
  },
  "UKDD": {
      "icao": "UKDD",
      "iata": "DNK",
      "name": "Dnipropetrovsk International Airport",
      "city": "Dnipropetrovsk",
      "state": "Dnipro",
      "country": "UA",
      "elevation": 481,
      "lat": 48.3572006226,
      "lon": 35.1006011963,
      "tz": "Europe\/Kiev"
  },
  "UKDE": {
      "icao": "UKDE",
      "iata": "OZH",
      "name": "Zaporizhzhia International Airport",
      "city": "Zaporizhia",
      "state": "Zaporizhia",
      "country": "UA",
      "elevation": 373,
      "lat": 47.8670005798,
      "lon": 35.315700531,
      "tz": "Europe\/Zaporozhye"
  },
  "UKDR": {
      "icao": "UKDR",
      "iata": "KWG",
      "name": "Kryvyi Rih International Airport",
      "city": "Kryvyi Rih",
      "state": "Kropyvnytskyi",
      "country": "UA",
      "elevation": 408,
      "lat": 48.0433006287,
      "lon": 33.2099990845,
      "tz": "Europe\/Kiev"
  },
  "UKFF": {
      "icao": "UKFF",
      "iata": "SIP",
      "name": "Simferopol International Airport",
      "city": "Simferopol",
      "state": "Republic-of-Crimea",
      "country": "UA",
      "elevation": 639,
      "lat": 45.0522003174,
      "lon": 33.9751014709,
      "tz": "Europe\/Simferopol"
  },
  "UKFG": {
      "icao": "UKFG",
      "iata": "",
      "name": "Gvardeyskoe Air Base",
      "city": "Sarabuz",
      "state": "Republic-of-Crimea",
      "country": "UA",
      "elevation": 721,
      "lat": 45.1150016785,
      "lon": 33.9770011902,
      "tz": "Europe\/Simferopol"
  },
  "UKFI": {
      "icao": "UKFI",
      "iata": "",
      "name": "Saki Air Base",
      "city": "Saki",
      "state": "Republic-of-Crimea",
      "country": "UA",
      "elevation": 36,
      "lat": 45.0929985046,
      "lon": 33.5950012207,
      "tz": "Europe\/Simferopol"
  },
  "UKFK": {
      "icao": "UKFK",
      "iata": "KHC",
      "name": "Kerch Airport",
      "city": "Kerch",
      "state": "Republic-of-Crimea",
      "country": "UA",
      "elevation": 171,
      "lat": 45.3725013733,
      "lon": 36.4014015198,
      "tz": "Europe\/Simferopol"
  },
  "UKFV": {
      "icao": "UKFV",
      "iata": "",
      "name": "Yevpatoriya Airport",
      "city": "Yevpatoriya",
      "state": "Republic-of-Crimea",
      "country": "UA",
      "elevation": 33,
      "lat": 45.2256011963,
      "lon": 33.376701355,
      "tz": "Europe\/Simferopol"
  },
  "UKFY": {
      "icao": "UKFY",
      "iata": "",
      "name": "Dzhankoy Airport",
      "city": "Dzhankoy",
      "state": "Republic-of-Crimea",
      "country": "UA",
      "elevation": 75,
      "lat": 45.7009010315,
      "lon": 34.4188995361,
      "tz": "Europe\/Simferopol"
  },
  "UKHD": {
      "icao": "UKHD",
      "iata": "",
      "name": "Kharkiv North Airport",
      "city": "Kharkiv",
      "state": "Kharkiv",
      "country": "UA",
      "elevation": 592,
      "lat": 50.0250015259,
      "lon": 36.268901825,
      "tz": "Europe\/Kiev"
  },
  "UKHH": {
      "icao": "UKHH",
      "iata": "HRK",
      "name": "Kharkiv International Airport",
      "city": "Kharkiv",
      "state": "Kharkiv",
      "country": "UA",
      "elevation": 508,
      "lat": 49.9248008728,
      "lon": 36.2900009155,
      "tz": "Europe\/Kiev"
  },
  "UKHP": {
      "icao": "UKHP",
      "iata": "PLV",
      "name": "Suprunovka Airport",
      "city": "Poltava",
      "state": "Poltava",
      "country": "UA",
      "elevation": 505,
      "lat": 49.5685997009,
      "lon": 34.3972015381,
      "tz": "Europe\/Kiev"
  },
  "UKHS": {
      "icao": "UKHS",
      "iata": "UMY",
      "name": "Sumy Airport",
      "city": "Sumy",
      "state": "Sumy",
      "country": "UA",
      "elevation": 594,
      "lat": 50.8582992554,
      "lon": 34.7625007629,
      "tz": "Europe\/Kiev"
  },
  "UKKE": {
      "icao": "UKKE",
      "iata": "CKC",
      "name": "Cherkasy International Airport",
      "city": "Cherkasy",
      "state": "Cherkasy",
      "country": "UA",
      "elevation": 375,
      "lat": 49.415599823,
      "lon": 31.995300293,
      "tz": "Europe\/Kiev"
  },
  "UKKG": {
      "icao": "UKKG",
      "iata": "KGO",
      "name": "Kirovograd Airport",
      "city": "Kirovograd",
      "state": "Kropyvnytskyi",
      "country": "UA",
      "elevation": 568,
      "lat": 48.5428009033,
      "lon": 32.2849998474,
      "tz": "Europe\/Kiev"
  },
  "UKKH": {
      "icao": "UKKH",
      "iata": "",
      "name": "Chepelevka Airport",
      "city": "Uzin",
      "state": "Kyiv",
      "country": "UA",
      "elevation": 568,
      "lat": 49.790599823,
      "lon": 30.441400528,
      "tz": "Europe\/Kiev"
  },
  "UKKK": {
      "icao": "UKKK",
      "iata": "IEV",
      "name": "Kiev Zhuliany International Airport",
      "city": "Kiev",
      "state": "Kyiv-City",
      "country": "UA",
      "elevation": 586,
      "lat": 50.4016990662,
      "lon": 30.4496994019,
      "tz": "Europe\/Kiev"
  },
  "UKKM": {
      "icao": "UKKM",
      "iata": "GML",
      "name": "Gostomel Airport",
      "city": "Kiev",
      "state": "Kyiv",
      "country": "UA",
      "elevation": 517,
      "lat": 50.6035003662,
      "lon": 30.1919002533,
      "tz": "Europe\/Kiev"
  },
  "UKKT": {
      "icao": "UKKT",
      "iata": "",
      "name": "Svyatoshyn Airport",
      "city": "Svyatoshyn",
      "state": "Kyiv-City",
      "country": "UA",
      "elevation": 582,
      "lat": 50.4788894653,
      "lon": 30.3858337402,
      "tz": "Europe\/Kiev"
  },
  "UKKV": {
      "icao": "UKKV",
      "iata": "ZTR",
      "name": "Zhytomyr Airport",
      "city": "",
      "state": "Zhytomyr",
      "country": "UA",
      "elevation": 0,
      "lat": 50.270556,
      "lon": 28.738611,
      "tz": "Europe\/Kiev"
  },
  "UKLC": {
      "icao": "UKLC",
      "iata": "UCK",
      "name": "Lutsk Airport",
      "city": "Lutsk",
      "state": "Volyn",
      "country": "UA",
      "elevation": 774,
      "lat": 50.6784041103,
      "lon": 25.487165451,
      "tz": "Europe\/Kiev"
  },
  "UKLH": {
      "icao": "UKLH",
      "iata": "HMJ",
      "name": "Khmelnytskyi Airport",
      "city": "Khmelnytskyi",
      "state": "Khmelnytskyi",
      "country": "UA",
      "elevation": 1150,
      "lat": 49.3596992493,
      "lon": 26.9333992004,
      "tz": "Europe\/Kiev"
  },
  "UKLI": {
      "icao": "UKLI",
      "iata": "IFO",
      "name": "Ivano-Frankivsk International Airport",
      "city": "Ivano-Frankivsk",
      "state": "Ivano-Frankivsk",
      "country": "UA",
      "elevation": 919,
      "lat": 48.8842010498,
      "lon": 24.6861000061,
      "tz": "Europe\/Kiev"
  },
  "UKLL": {
      "icao": "UKLL",
      "iata": "LWO",
      "name": "Lviv International Airport",
      "city": "Lviv",
      "state": "Lviv",
      "country": "UA",
      "elevation": 1071,
      "lat": 49.8125,
      "lon": 23.9561004639,
      "tz": "Europe\/Kiev"
  },
  "UKLN": {
      "icao": "UKLN",
      "iata": "CWC",
      "name": "Chernivtsi International Airport",
      "city": "Chernivtsi",
      "state": "Chernivtsi",
      "country": "UA",
      "elevation": 826,
      "lat": 48.2593002319,
      "lon": 25.9808006287,
      "tz": "Europe\/Kiev"
  },
  "UKLR": {
      "icao": "UKLR",
      "iata": "RWN",
      "name": "Rivne International Airport",
      "city": "Rivne",
      "state": "Rivne",
      "country": "UA",
      "elevation": 755,
      "lat": 50.6071014404,
      "lon": 26.1415996552,
      "tz": "Europe\/Kiev"
  },
  "UKLT": {
      "icao": "UKLT",
      "iata": "TNL",
      "name": "Ternopil International Airport",
      "city": "Ternopil",
      "state": "Ternopil",
      "country": "UA",
      "elevation": 1072,
      "lat": 49.5242004395,
      "lon": 25.7000999451,
      "tz": "Europe\/Kiev"
  },
  "UKLU": {
      "icao": "UKLU",
      "iata": "UDJ",
      "name": "Uzhhorod International Airport",
      "city": "Uzhhorod",
      "state": "Transcarpathia",
      "country": "UA",
      "elevation": 383,
      "lat": 48.6343002319,
      "lon": 22.2633991241,
      "tz": "Europe\/Bratislava"
  },
  "UKOH": {
      "icao": "UKOH",
      "iata": "KHE",
      "name": "Chernobayevka Airport",
      "city": "Kherson",
      "state": "Kherson",
      "country": "UA",
      "elevation": 148,
      "lat": 46.6758003235,
      "lon": 32.506401062,
      "tz": "Europe\/Kiev"
  },
  "UKOI": {
      "icao": "UKOI",
      "iata": "",
      "name": "Izmail Airport",
      "city": "Izmail",
      "state": "Odessa",
      "country": "UA",
      "elevation": 125,
      "lat": 45.3916664124,
      "lon": 28.8016662598,
      "tz": "Europe\/Kiev"
  },
  "UKOM": {
      "icao": "UKOM",
      "iata": "",
      "name": "Limans'Ke Airport",
      "city": "Limans'Ke",
      "state": "Odessa",
      "country": "UA",
      "elevation": 158,
      "lat": 46.6691665649,
      "lon": 30.0111103058,
      "tz": "Europe\/Kiev"
  },
  "UKON": {
      "icao": "UKON",
      "iata": "NLV",
      "name": "Mykolaiv International Airport",
      "city": "Nikolayev",
      "state": "Mykolaiv",
      "country": "UA",
      "elevation": 184,
      "lat": 47.0578994751,
      "lon": 31.9197998047,
      "tz": "Europe\/Kiev"
  },
  "UKOO": {
      "icao": "UKOO",
      "iata": "ODS",
      "name": "Odessa International Airport",
      "city": "Odessa",
      "state": "Odessa",
      "country": "UA",
      "elevation": 172,
      "lat": 46.4267997742,
      "lon": 30.6765003204,
      "tz": "Europe\/Kiev"
  },
  "UKWW": {
      "icao": "UKWW",
      "iata": "VIN",
      "name": "Vinnytsia\/Gavyryshivka Airport",
      "city": "Vinnitsa",
      "state": "Vinnyts'ka",
      "country": "UA",
      "elevation": 961,
      "lat": 49.242531,
      "lon": 28.613778,
      "tz": "Europe\/Kiev"
  },
  "ULAA": {
      "icao": "ULAA",
      "iata": "ARH",
      "name": "Talagi Airport",
      "city": "Archangelsk",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 62,
      "lat": 64.6003036499,
      "lon": 40.7167015076,
      "tz": "Europe\/Moscow"
  },
  "ULAE": {
      "icao": "ULAE",
      "iata": "",
      "name": "Mezen Airport",
      "city": "Mezen",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 46,
      "lat": 65.8783340454,
      "lon": 44.2150001526,
      "tz": "Europe\/Moscow"
  },
  "ULAH": {
      "icao": "ULAH",
      "iata": "VKV",
      "name": "Vaskovo Airport",
      "city": "Arkhangelsk",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 82,
      "lat": 64.4417037964,
      "lon": 40.4216995239,
      "tz": "Europe\/Moscow"
  },
  "ULAK": {
      "icao": "ULAK",
      "iata": "",
      "name": "Severomorsk-1 Naval Air Base",
      "city": "Severomorsk",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 239,
      "lat": 69.0316696167,
      "lon": 33.4183311462,
      "tz": "Europe\/Moscow"
  },
  "ULAL": {
      "icao": "ULAL",
      "iata": "LDG",
      "name": "Leshukonskoye Airport",
      "city": "Leshukonskoye",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 220,
      "lat": 64.8960037231,
      "lon": 45.7229995728,
      "tz": "Europe\/Moscow"
  },
  "ULAM": {
      "icao": "ULAM",
      "iata": "NNM",
      "name": "Naryan Mar Airport",
      "city": "Naryan Mar",
      "state": "Nenets",
      "country": "RU",
      "elevation": 36,
      "lat": 67.6399993896,
      "lon": 53.1218986511,
      "tz": "Europe\/Moscow"
  },
  "ULAN": {
      "icao": "ULAN",
      "iata": "",
      "name": "Shenkursk Airport",
      "city": "Shenkursk",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 0,
      "lat": 62.1170005798,
      "lon": 42.8829994202,
      "tz": "Europe\/Moscow"
  },
  "ULAO": {
      "icao": "ULAO",
      "iata": "",
      "name": "Onega Airport",
      "city": "Onega",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 59,
      "lat": 63.9111140154,
      "lon": 38.1258201599,
      "tz": "Europe\/Moscow"
  },
  "ULAP": {
      "icao": "ULAP",
      "iata": "",
      "name": "Karpogory Airport",
      "city": "Karpogory",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 0,
      "lat": 64.0130004883,
      "lon": 44.4550018311,
      "tz": "Europe\/Moscow"
  },
  "ULAR": {
      "icao": "ULAR",
      "iata": "",
      "name": "Kargopol Airport",
      "city": "Kargopol",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 420,
      "lat": 61.5099983215,
      "lon": 38.9249992371,
      "tz": "Europe\/Moscow"
  },
  "ULAS": {
      "icao": "ULAS",
      "iata": "CSH",
      "name": "Solovki Airport",
      "city": "Solovetsky Islands",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 60,
      "lat": 65.0299987793,
      "lon": 35.7333335876,
      "tz": "Europe\/Moscow"
  },
  "ULAT": {
      "icao": "ULAT",
      "iata": "",
      "name": "Pertominsk Airport",
      "city": "Pertominsk",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 56,
      "lat": 64.7900009155,
      "lon": 38.4199981689,
      "tz": "Europe\/Moscow"
  },
  "ULAV": {
      "icao": "ULAV",
      "iata": "",
      "name": "Nizhnyaya Pesha Airport",
      "city": "Nizhnyaya Pesha",
      "state": "Nenets",
      "country": "RU",
      "elevation": 32,
      "lat": 66.7529983521,
      "lon": 47.7430000305,
      "tz": "Europe\/Moscow"
  },
  "ULBC": {
      "icao": "ULBC",
      "iata": "",
      "name": "Cherepovets Airport",
      "city": "Cherepovets",
      "state": "Vologda",
      "country": "RU",
      "elevation": 377,
      "lat": 59.273601532,
      "lon": 38.0158004761,
      "tz": "Europe\/Moscow"
  },
  "ULBD": {
      "icao": "ULBD",
      "iata": "",
      "name": "Dolgoshchelye Airport",
      "city": "Dolgoshchelye",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 0,
      "lat": 66.0437011719,
      "lon": 43.4580993652,
      "tz": "Europe\/Moscow"
  },
  "ULBI": {
      "icao": "ULBI",
      "iata": "",
      "name": "Chizha Airport",
      "city": "Chizha",
      "state": "Nenets",
      "country": "RU",
      "elevation": 0,
      "lat": 67.0839996338,
      "lon": 44.3670005798,
      "tz": "Europe\/Moscow"
  },
  "ULBL": {
      "icao": "ULBL",
      "iata": "",
      "name": "Lopshenga Airport",
      "city": "Lopshenga",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 49,
      "lat": 64.9800033569,
      "lon": 37.6930007935,
      "tz": "Europe\/Moscow"
  },
  "ULBM": {
      "icao": "ULBM",
      "iata": "",
      "name": "Purnema Airport",
      "city": "Purnema",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 131,
      "lat": 64.3854002577,
      "lon": 37.4412345886,
      "tz": "Europe\/Moscow"
  },
  "ULBO": {
      "icao": "ULBO",
      "iata": "",
      "name": "Shoyna Airport",
      "city": "Shoyna",
      "state": "Nenets",
      "country": "RU",
      "elevation": 0,
      "lat": 67.8690032959,
      "lon": 44.1510009766,
      "tz": "Europe\/Moscow"
  },
  "ULBW": {
      "icao": "ULBW",
      "iata": "",
      "name": "Verkhnyaya Zolotitsa Airport",
      "city": "",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 0,
      "lat": 65.6910018921,
      "lon": 40.3804016113,
      "tz": "Europe\/Moscow"
  },
  "ULBZ": {
      "icao": "ULBZ",
      "iata": "",
      "name": "Letnyaya Zolotitsa Airport",
      "city": "Letnyaya Zolotitsa",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 16,
      "lat": 64.9599990845,
      "lon": 36.8300018311,
      "tz": "Europe\/Moscow"
  },
  "ULDA": {
      "icao": "ULDA",
      "iata": "",
      "name": "Rogachyovo Air Base",
      "city": "Belaya",
      "state": "Nenets",
      "country": "RU",
      "elevation": 272,
      "lat": 71.6166992188,
      "lon": 52.4782981873,
      "tz": "Europe\/Moscow"
  },
  "ULDD": {
      "icao": "ULDD",
      "iata": "AMV",
      "name": "Amderma Airport",
      "city": "Amderma",
      "state": "Nenets",
      "country": "RU",
      "elevation": 13,
      "lat": 69.7632980347,
      "lon": 61.5564002991,
      "tz": "Europe\/Moscow"
  },
  "ULDT": {
      "icao": "ULDT",
      "iata": "",
      "name": "Karatayka Airport",
      "city": "Karatayka",
      "state": "",
      "country": "RU",
      "elevation": 39,
      "lat": 68.75,
      "lon": 61.4249992371,
      "tz": "Europe\/Moscow"
  },
  "ULDW": {
      "icao": "ULDW",
      "iata": "VRI",
      "name": "Varandey Airport",
      "city": "",
      "state": "Nenets",
      "country": "RU",
      "elevation": 39,
      "lat": 68.8485031128,
      "lon": 58.2014007568,
      "tz": "Europe\/Moscow"
  },
  "ULEH": {
      "icao": "ULEH",
      "iata": "",
      "name": "Khorey-Ver Airport",
      "city": "Khorey-Ver",
      "state": "Nenets",
      "country": "RU",
      "elevation": 0,
      "lat": 67.4150009155,
      "lon": 58.0449981689,
      "tz": "Europe\/Moscow"
  },
  "ULER": {
      "icao": "ULER",
      "iata": "",
      "name": "Kharuta Airport",
      "city": "Kharuta",
      "state": "Komi",
      "country": "RU",
      "elevation": 0,
      "lat": 66.8470001221,
      "lon": 59.5449981689,
      "tz": "Europe\/Moscow"
  },
  "ULJC": {
      "icao": "ULJC",
      "iata": "",
      "name": "Tsenogora Airport",
      "city": "Tsenogora",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 262,
      "lat": 64.8870010376,
      "lon": 46.7210006714,
      "tz": "Europe\/Moscow"
  },
  "ULJO": {
      "icao": "ULJO",
      "iata": "",
      "name": "Olema Airport",
      "city": "Olema",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 164,
      "lat": 64.495,
      "lon": 46.14666,
      "tz": "Europe\/Moscow"
  },
  "ULKK": {
      "icao": "ULKK",
      "iata": "KSZ",
      "name": "Kotlas Airport",
      "city": "Kotlas",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 184,
      "lat": 61.2358016968,
      "lon": 46.6974983215,
      "tz": "Europe\/Moscow"
  },
  "ULKO": {
      "icao": "ULKO",
      "iata": "",
      "name": "Oktyabrskiy Airport",
      "city": "Oktyabrskiy",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 0,
      "lat": 61.0789985657,
      "lon": 43.1990013123,
      "tz": "Europe\/Moscow"
  },
  "ULKS": {
      "icao": "ULKS",
      "iata": "",
      "name": "Savvatiya Air Base",
      "city": "Kotlas",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 318,
      "lat": 60.9949989319,
      "lon": 46.8699989319,
      "tz": "Europe\/Moscow"
  },
  "ULKW": {
      "icao": "ULKW",
      "iata": "",
      "name": "Velsk Airport",
      "city": "Velsk",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 0,
      "lat": 61.0400009155,
      "lon": 42.0999984741,
      "tz": "Europe\/Moscow"
  },
  "ULLI": {
      "icao": "ULLI",
      "iata": "LED",
      "name": "Pulkovo Airport",
      "city": "St. Petersburg",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 78,
      "lat": 59.8003005981,
      "lon": 30.2625007629,
      "tz": "Europe\/Moscow"
  },
  "ULLK": {
      "icao": "ULLK",
      "iata": "",
      "name": "Krechevitsy Air Base",
      "city": "Novgorod",
      "state": "Novgorod",
      "country": "RU",
      "elevation": 85,
      "lat": 58.625,
      "lon": 31.3850002289,
      "tz": "Europe\/Moscow"
  },
  "ULLP": {
      "icao": "ULLP",
      "iata": "",
      "name": "Pushkin Airport",
      "city": "St. Petersburg",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 230,
      "lat": 59.6850013733,
      "lon": 30.338300705,
      "tz": "Europe\/Moscow"
  },
  "ULLS": {
      "icao": "ULLS",
      "iata": "",
      "name": "Siversky Air Base",
      "city": "Siversky",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 341,
      "lat": 59.3567008972,
      "lon": 30.036699295,
      "tz": "Europe\/Moscow"
  },
  "ULMA": {
      "icao": "ULMA",
      "iata": "",
      "name": "Umba Airport",
      "city": "Umba",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 122,
      "lat": 66.6969985962,
      "lon": 34.3870010376,
      "tz": "Europe\/Moscow"
  },
  "ULMK": {
      "icao": "ULMK",
      "iata": "KVK",
      "name": "Kirovsk-Apatity Airport",
      "city": "Apatity",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 515,
      "lat": 67.4633026123,
      "lon": 33.5882987976,
      "tz": "Europe\/Moscow"
  },
  "ULML": {
      "icao": "ULML",
      "iata": "",
      "name": "Lovozero Airport",
      "city": "Lovozero",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 499,
      "lat": 68.021697998,
      "lon": 35,
      "tz": "Europe\/Moscow"
  },
  "ULMM": {
      "icao": "ULMM",
      "iata": "MMK",
      "name": "Murmansk Airport",
      "city": "Murmansk",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 266,
      "lat": 68.7817001343,
      "lon": 32.7508010864,
      "tz": "Europe\/Moscow"
  },
  "ULMU": {
      "icao": "ULMU",
      "iata": "",
      "name": "Tetrino Airport",
      "city": "Tetrino",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 16,
      "lat": 66.0587670784,
      "lon": 38.2826328278,
      "tz": "Europe\/Moscow"
  },
  "ULMX": {
      "icao": "ULMX",
      "iata": "",
      "name": "Krasnoshchelye Airport",
      "city": "Krasnoshchelye",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 492,
      "lat": 67.3479995728,
      "lon": 37.0769996643,
      "tz": "Europe\/Moscow"
  },
  "ULNB": {
      "icao": "ULNB",
      "iata": "",
      "name": "Borovichi Airport",
      "city": "Borovichi",
      "state": "Novgorod",
      "country": "RU",
      "elevation": 0,
      "lat": 58.436000824,
      "lon": 33.888999939,
      "tz": "Europe\/Moscow"
  },
  "ULNR": {
      "icao": "ULNR",
      "iata": "",
      "name": "Staraya Russa Airport",
      "city": "Staraya Russa",
      "state": "Novgorod",
      "country": "RU",
      "elevation": 52,
      "lat": 57.9613990784,
      "lon": 31.3843994141,
      "tz": "Europe\/Moscow"
  },
  "ULOL": {
      "icao": "ULOL",
      "iata": "VLU",
      "name": "Velikiye Luki Airport",
      "city": "Velikiye Luki",
      "state": "Pskov",
      "country": "RU",
      "elevation": 328,
      "lat": 56.3810997009,
      "lon": 30.6081008911,
      "tz": "Europe\/Moscow"
  },
  "ULOO": {
      "icao": "ULOO",
      "iata": "PKV",
      "name": "Pskov Airport",
      "city": "Pskov",
      "state": "Pskov",
      "country": "RU",
      "elevation": 154,
      "lat": 57.7839012146,
      "lon": 28.3955993652,
      "tz": "Europe\/Moscow"
  },
  "ULPA": {
      "icao": "ULPA",
      "iata": "",
      "name": "Pudozh Airport",
      "city": "Pudozh",
      "state": "Karelia",
      "country": "RU",
      "elevation": 144,
      "lat": 61.8250007629,
      "lon": 36.5800018311,
      "tz": "Europe\/Moscow"
  },
  "ULPB": {
      "icao": "ULPB",
      "iata": "PES",
      "name": "Petrozavodsk Airport",
      "city": "Petrozavodsk",
      "state": "Karelia",
      "country": "RU",
      "elevation": 151,
      "lat": 61.8852005005,
      "lon": 34.1547012329,
      "tz": "Europe\/Moscow"
  },
  "ULPK": {
      "icao": "ULPK",
      "iata": "",
      "name": "Kalevala Airport",
      "city": "Kalevala",
      "state": "Karelia",
      "country": "RU",
      "elevation": 377,
      "lat": 65.2099990845,
      "lon": 31.1350002289,
      "tz": "Europe\/Moscow"
  },
  "ULPM": {
      "icao": "ULPM",
      "iata": "",
      "name": "Kostomuksha Airport",
      "city": "Kostomuksha",
      "state": "Karelia",
      "country": "RU",
      "elevation": 681,
      "lat": 64.6179962158,
      "lon": 30.6870002747,
      "tz": "Europe\/Moscow"
  },
  "ULPP": {
      "icao": "ULPP",
      "iata": "",
      "name": "Peski Airport",
      "city": "Petrozavodsk",
      "state": "Karelia",
      "country": "RU",
      "elevation": 0,
      "lat": 61.831943512,
      "lon": 34.2955551147,
      "tz": "Europe\/Moscow"
  },
  "ULPS": {
      "icao": "ULPS",
      "iata": "",
      "name": "Segezha Airport",
      "city": "Segezha",
      "state": "Karelia",
      "country": "RU",
      "elevation": 377,
      "lat": 63.7589988708,
      "lon": 34.283000946,
      "tz": "Europe\/Moscow"
  },
  "ULPW": {
      "icao": "ULPW",
      "iata": "",
      "name": "Sortavala Airport",
      "city": "",
      "state": "Karelia",
      "country": "RU",
      "elevation": 0,
      "lat": 61.7360992432,
      "lon": 30.6735992432,
      "tz": "Europe\/Moscow"
  },
  "ULSC": {
      "icao": "ULSC",
      "iata": "",
      "name": "Seltso Airfield",
      "city": "Seltso",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 0,
      "lat": 59.6170005798,
      "lon": 29.5429992676,
      "tz": "Europe\/Moscow"
  },
  "ULSG": {
      "icao": "ULSG",
      "iata": "",
      "name": "Gostilitsy Airfield",
      "city": "Gostilitsy",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 0,
      "lat": 59.7266998291,
      "lon": 29.6394996643,
      "tz": "Europe\/Moscow"
  },
  "ULSI": {
      "icao": "ULSI",
      "iata": "",
      "name": "Kusino Airfield",
      "city": "Kusino",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 95,
      "lat": 59.4021564205,
      "lon": 31.8155479431,
      "tz": "Europe\/Moscow"
  },
  "ULSK": {
      "icao": "ULSK",
      "iata": "",
      "name": "Korpikyulya Airfield",
      "city": "Gatchina",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 0,
      "lat": 59.6049995422,
      "lon": 30.2723007202,
      "tz": "Europe\/Moscow"
  },
  "ULSM": {
      "icao": "ULSM",
      "iata": "",
      "name": "Manushkino Airfield",
      "city": "Manushkino",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 0,
      "lat": 59.8718986511,
      "lon": 30.8027992249,
      "tz": "Europe\/Moscow"
  },
  "ULSO": {
      "icao": "ULSO",
      "iata": "",
      "name": "Kuznetsovo Airfield",
      "city": "Rozhdestveno",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 0,
      "lat": 59.2452011108,
      "lon": 29.9451999664,
      "tz": "Europe\/Moscow"
  },
  "ULWC": {
      "icao": "ULWC",
      "iata": "CEE",
      "name": "Cherepovets Airport",
      "city": "Cherepovets",
      "state": "Vologda",
      "country": "RU",
      "elevation": 377,
      "lat": 59.276667,
      "lon": 38.028333,
      "tz": "Europe\/Moscow"
  },
  "ULWK": {
      "icao": "ULWK",
      "iata": "",
      "name": "Kichmengskiy Gorodok Airport",
      "city": "Kichmengskiy Gorodok",
      "state": "Vologda",
      "country": "RU",
      "elevation": 0,
      "lat": 59.9770011902,
      "lon": 45.8250007629,
      "tz": "Europe\/Moscow"
  },
  "ULWR": {
      "icao": "ULWR",
      "iata": "",
      "name": "Vytegra Airport",
      "city": "Vytegra",
      "state": "Vologda",
      "country": "RU",
      "elevation": 217,
      "lat": 61.0320014954,
      "lon": 36.4350013733,
      "tz": "Europe\/Moscow"
  },
  "ULWU": {
      "icao": "ULWU",
      "iata": "VUS",
      "name": "Velikiy Ustyug Airport",
      "city": "Velikiy Ustyug",
      "state": "Vologda",
      "country": "RU",
      "elevation": 331,
      "lat": 60.7882995605,
      "lon": 46.2599983215,
      "tz": "Europe\/Moscow"
  },
  "ULWW": {
      "icao": "ULWW",
      "iata": "VGD",
      "name": "Vologda Airport",
      "city": "Vologda",
      "state": "Vologda",
      "country": "RU",
      "elevation": 387,
      "lat": 59.2825012207,
      "lon": 39.9444007874,
      "tz": "Europe\/Moscow"
  },
  "UMBB": {
      "icao": "UMBB",
      "iata": "BQT",
      "name": "Brest Airport",
      "city": "Brest",
      "state": "Brest",
      "country": "BY",
      "elevation": 468,
      "lat": 52.1082992554,
      "lon": 23.8980998993,
      "tz": "Europe\/Minsk"
  },
  "UMDD": {
      "icao": "UMDD",
      "iata": "",
      "name": "Lida Air Base",
      "city": "Lida",
      "state": "Grodnenskaya",
      "country": "BY",
      "elevation": 0,
      "lat": 53.877,
      "lon": 25.377,
      "tz": "Europe\/Minsk"
  },
  "UMGG": {
      "icao": "UMGG",
      "iata": "GME",
      "name": "Gomel Airport",
      "city": "Gomel",
      "state": "Gomel",
      "country": "BY",
      "elevation": 472,
      "lat": 52.5270004272,
      "lon": 31.0167007446,
      "tz": "Europe\/Minsk"
  },
  "UMII": {
      "icao": "UMII",
      "iata": "VTB",
      "name": "Vitebsk East Airport",
      "city": "Vitebsk",
      "state": "Vitebsk",
      "country": "BY",
      "elevation": 682,
      "lat": 55.126499176,
      "lon": 30.3495998383,
      "tz": "Europe\/Minsk"
  },
  "UMKK": {
      "icao": "UMKK",
      "iata": "KGD",
      "name": "Khrabrovo Airport",
      "city": "Kaliningrad",
      "state": "Kaliningrad",
      "country": "RU",
      "elevation": 42,
      "lat": 54.8899993896,
      "lon": 20.5925998688,
      "tz": "Europe\/Kaliningrad"
  },
  "UMLI": {
      "icao": "UMLI",
      "iata": "",
      "name": "Minsk Machulishchy Airport",
      "city": "Minsk",
      "state": "Minsk",
      "country": "BY",
      "elevation": 699,
      "lat": 53.7733001709,
      "lon": 27.5799999237,
      "tz": "Europe\/Minsk"
  },
  "UMMA": {
      "icao": "UMMA",
      "iata": "",
      "name": "Baranavichi Air Base",
      "city": "Baranavichi",
      "state": "Brest",
      "country": "BY",
      "elevation": 0,
      "lat": 53.0999984741,
      "lon": 26.0499992371,
      "tz": "Europe\/Minsk"
  },
  "UMMB": {
      "icao": "UMMB",
      "iata": "",
      "name": "Borovaya Airfield",
      "city": "Minsk",
      "state": "Minsk",
      "country": "BY",
      "elevation": 0,
      "lat": 53.5699996948,
      "lon": 27.3899993896,
      "tz": "Europe\/Minsk"
  },
  "UMMG": {
      "icao": "UMMG",
      "iata": "GNA",
      "name": "Hrodna Airport",
      "city": "Hrodna",
      "state": "Grodnenskaya",
      "country": "BY",
      "elevation": 443,
      "lat": 53.6020011902,
      "lon": 24.0538005829,
      "tz": "Europe\/Minsk"
  },
  "UMMM": {
      "icao": "UMMM",
      "iata": "MHP",
      "name": "Minsk 1 Airport",
      "city": "Minsk",
      "state": "",
      "country": "BY",
      "elevation": 748,
      "lat": 53.8644981384,
      "lon": 27.5396995544,
      "tz": "Europe\/Minsk"
  },
  "UMMO": {
      "icao": "UMMO",
      "iata": "",
      "name": "Osovtsy Air Base",
      "city": "Byaroza",
      "state": "Brest",
      "country": "BY",
      "elevation": 0,
      "lat": 52.5569992065,
      "lon": 24.8840007782,
      "tz": "Europe\/Minsk"
  },
  "UMMR": {
      "icao": "UMMR",
      "iata": "",
      "name": "Ross Air Base",
      "city": "Ross",
      "state": "Grodnenskaya",
      "country": "BY",
      "elevation": 0,
      "lat": 53.303,
      "lon": 24.369,
      "tz": "Europe\/Minsk"
  },
  "UMMS": {
      "icao": "UMMS",
      "iata": "MSQ",
      "name": "Minsk International Airport",
      "city": "Minsk",
      "state": "Minsk",
      "country": "BY",
      "elevation": 670,
      "lat": 53.8824996948,
      "lon": 28.0307006836,
      "tz": "Europe\/Minsk"
  },
  "UMNB": {
      "icao": "UMNB",
      "iata": "",
      "name": "Babruisk Air Base",
      "city": "Babruisk",
      "state": "Mogilev",
      "country": "BY",
      "elevation": 0,
      "lat": 53.1049995422,
      "lon": 29.2049999237,
      "tz": "Europe\/Minsk"
  },
  "UMNM": {
      "icao": "UMNM",
      "iata": "",
      "name": "Novo-Pashkovo Airport",
      "city": "Mahiylow",
      "state": "Mogilev",
      "country": "BY",
      "elevation": 0,
      "lat": 53.945,
      "lon": 30.244,
      "tz": "Europe\/Minsk"
  },
  "UMNV": {
      "icao": "UMNV",
      "iata": "",
      "name": "Pruzhany Air Base",
      "city": "Pruzhany",
      "state": "Brest",
      "country": "BY",
      "elevation": 0,
      "lat": 52.5822957318,
      "lon": 24.379863739,
      "tz": "Europe\/Minsk"
  },
  "UMOO": {
      "icao": "UMOO",
      "iata": "MVQ",
      "name": "Mogilev Airport",
      "city": "Mogilev",
      "state": "Mogilev",
      "country": "BY",
      "elevation": 637,
      "lat": 53.9548988342,
      "lon": 30.0951004028,
      "tz": "Europe\/Minsk"
  },
  "UNAA": {
      "icao": "UNAA",
      "iata": "ABA",
      "name": "Abakan Airport",
      "city": "Abakan",
      "state": "Khakasiya",
      "country": "RU",
      "elevation": 831,
      "lat": 53.7400016785,
      "lon": 91.3850021362,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNAU": {
      "icao": "UNAU",
      "iata": "",
      "name": "Shushenskoye Airport",
      "city": "Kazantsevo",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 0,
      "lat": 53.3849983215,
      "lon": 92.0449981689,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNBB": {
      "icao": "UNBB",
      "iata": "BAX",
      "name": "Barnaul Airport",
      "city": "Barnaul",
      "state": "Altai-Krai",
      "country": "RU",
      "elevation": 837,
      "lat": 53.3638000488,
      "lon": 83.5384979248,
      "tz": "Asia\/Barnaul"
  },
  "UNBG": {
      "icao": "UNBG",
      "iata": "RGK",
      "name": "Gorno-Altaysk Airport",
      "city": "Gorno-Altaysk",
      "state": "Altai",
      "country": "RU",
      "elevation": 965,
      "lat": 51.9667015076,
      "lon": 85.8332977295,
      "tz": "Asia\/Barnaul"
  },
  "UNBI": {
      "icao": "UNBI",
      "iata": "",
      "name": "Biysk Airport",
      "city": "Biysk",
      "state": "Altai-Krai",
      "country": "RU",
      "elevation": 620,
      "lat": 52.4799995422,
      "lon": 85.3399963379,
      "tz": "Asia\/Barnaul"
  },
  "UNBM": {
      "icao": "UNBM",
      "iata": "",
      "name": "Volchikha Airport",
      "city": "Volchikha",
      "state": "Altai-Krai",
      "country": "RU",
      "elevation": 0,
      "lat": 52.0250889138,
      "lon": 80.3378677368,
      "tz": "Asia\/Barnaul"
  },
  "UNBU": {
      "icao": "UNBU",
      "iata": "",
      "name": "Ust-Koksa Airport",
      "city": "Ust-Koksa Airport",
      "state": "Altai",
      "country": "RU",
      "elevation": 0,
      "lat": 50.2649993896,
      "lon": 85.7099990845,
      "tz": "Asia\/Barnaul"
  },
  "UNCC": {
      "icao": "UNCC",
      "iata": "",
      "name": "Novosibirsk North Airport",
      "city": "Novosibirsk",
      "state": "Novosibirsk",
      "country": "RU",
      "elevation": 558,
      "lat": 55.0917015076,
      "lon": 82.9067001343,
      "tz": "Asia\/Novosibirsk"
  },
  "UNCN": {
      "icao": "UNCN",
      "iata": "",
      "name": "Krasnoozyorsk Airport",
      "city": "Krasnoozyorskoye",
      "state": "Novosibirsk",
      "country": "RU",
      "elevation": 0,
      "lat": 53.992785208,
      "lon": 79.3133926392,
      "tz": "Asia\/Novosibirsk"
  },
  "UNEE": {
      "icao": "UNEE",
      "iata": "KEJ",
      "name": "Kemerovo Airport",
      "city": "Kemerovo",
      "state": "Kemerovo",
      "country": "RU",
      "elevation": 863,
      "lat": 55.2700996399,
      "lon": 86.1072006226,
      "tz": "Asia\/Novokuznetsk"
  },
  "UNIB": {
      "icao": "UNIB",
      "iata": "",
      "name": "Baykit Airport",
      "city": "Baykit",
      "state": "",
      "country": "RU",
      "elevation": 853,
      "lat": 61.676700592,
      "lon": 96.3550033569,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNII": {
      "icao": "UNII",
      "iata": "EIE",
      "name": "Yeniseysk Airport",
      "city": "Yeniseysk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 253,
      "lat": 58.4742012024,
      "lon": 92.1125030518,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNIJ": {
      "icao": "UNIJ",
      "iata": "",
      "name": "Yartsevo Airport",
      "city": "Yartsevo",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 210,
      "lat": 60.2550010681,
      "lon": 90.1949996948,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNIP": {
      "icao": "UNIP",
      "iata": "TGP",
      "name": "Podkamennaya Tunguska Airport",
      "city": "Bor",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 213,
      "lat": 61.5896987915,
      "lon": 89.9940032959,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNIS": {
      "icao": "UNIS",
      "iata": "",
      "name": "Severo-Eniseysk Airport",
      "city": "Severo-Eniseysk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 1706,
      "lat": 60.373298645,
      "lon": 93.0117034912,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNIT": {
      "icao": "UNIT",
      "iata": "",
      "name": "Tura Airport",
      "city": "Tura",
      "state": "",
      "country": "RU",
      "elevation": 2044,
      "lat": 64.3335113525,
      "lon": 100.4328918457,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNIW": {
      "icao": "UNIW",
      "iata": "",
      "name": "Vanavara Airport",
      "city": "Vanavara",
      "state": "",
      "country": "RU",
      "elevation": 892,
      "lat": 60.3596992493,
      "lon": 102.3239974976,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKB": {
      "icao": "UNKB",
      "iata": "",
      "name": "Boguchany Airport",
      "city": "Boguchany",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 446,
      "lat": 58.3806152344,
      "lon": 97.4723358154,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKI": {
      "icao": "UNKI",
      "iata": "",
      "name": "Kodinsk Airport",
      "city": "",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 0,
      "lat": 58.4794006348,
      "lon": 99.0939025879,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKK": {
      "icao": "UNKK",
      "iata": "",
      "name": "Krasnoyarsk Severny",
      "city": "Krasnoyarsk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 1030,
      "lat": 56.22647,
      "lon": 92.95693,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKL": {
      "icao": "UNKL",
      "iata": "KJA",
      "name": "Yemelyanovo Airport",
      "city": "Krasnoyarsk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 942,
      "lat": 56.1729011536,
      "lon": 92.4933013916,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKM": {
      "icao": "UNKM",
      "iata": "",
      "name": "Cheremshanka Airport",
      "city": "Krasnoyarsk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 0,
      "lat": 56.1769981384,
      "lon": 92.5400009155,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKO": {
      "icao": "UNKO",
      "iata": "",
      "name": "Sharypovo Airport",
      "city": "Sharypovo",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 1099,
      "lat": 55.454914093,
      "lon": 89.1738815308,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKS": {
      "icao": "UNKS",
      "iata": "ACS",
      "name": "Achinsk Airport",
      "city": "Achinsk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 1033,
      "lat": 56.2682991028,
      "lon": 90.5708007813,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNKY": {
      "icao": "UNKY",
      "iata": "KYZ",
      "name": "Kyzyl Airport",
      "city": "Kyzyl",
      "state": "Republic-of-Tyva",
      "country": "RU",
      "elevation": 2123,
      "lat": 51.6693992615,
      "lon": 94.4005966187,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UNLL": {
      "icao": "UNLL",
      "iata": "",
      "name": "Kolpashevo Airport",
      "city": "Kolpashevo",
      "state": "Tomsk",
      "country": "RU",
      "elevation": 243,
      "lat": 58.3252983093,
      "lon": 82.9325027466,
      "tz": "Asia\/Tomsk"
  },
  "UNNE": {
      "icao": "UNNE",
      "iata": "",
      "name": "Yeltsovka Airport",
      "city": "Novosibirsk",
      "state": "Novosibirsk",
      "country": "RU",
      "elevation": 617,
      "lat": 55.0923995972,
      "lon": 83.0045013428,
      "tz": "Asia\/Novosibirsk"
  },
  "UNNS": {
      "icao": "UNNS",
      "iata": "",
      "name": "Severnoye Airport",
      "city": "Severnoe",
      "state": "Novosibirsk",
      "country": "RU",
      "elevation": 0,
      "lat": 56.3349990845,
      "lon": 78.3550033569,
      "tz": "Asia\/Novosibirsk"
  },
  "UNNT": {
      "icao": "UNNT",
      "iata": "OVB",
      "name": "Tolmachevo Airport",
      "city": "Novosibirsk",
      "state": "Novosibirsk",
      "country": "RU",
      "elevation": 365,
      "lat": 55.0125999451,
      "lon": 82.6507034302,
      "tz": "Asia\/Novosibirsk"
  },
  "UNOK": {
      "icao": "UNOK",
      "iata": "",
      "name": "Kalachinsk Airport",
      "city": "Kalachinsk",
      "state": "Omsk",
      "country": "RU",
      "elevation": 361,
      "lat": 55.0200004578,
      "lon": 74.6100006104,
      "tz": "Asia\/Omsk"
  },
  "UNOO": {
      "icao": "UNOO",
      "iata": "OMS",
      "name": "Omsk Central Airport",
      "city": "Omsk",
      "state": "Omsk",
      "country": "RU",
      "elevation": 311,
      "lat": 54.966999054,
      "lon": 73.3105010986,
      "tz": "Asia\/Omsk"
  },
  "UNOS": {
      "icao": "UNOS",
      "iata": "",
      "name": "Omsk Severny Airport",
      "city": "Omsk",
      "state": "Omsk",
      "country": "RU",
      "elevation": 0,
      "lat": 54.9749984741,
      "lon": 73.5550003052,
      "tz": "Asia\/Omsk"
  },
  "UNOT": {
      "icao": "UNOT",
      "iata": "",
      "name": "Tara Airport",
      "city": "Tara",
      "state": "Omsk",
      "country": "RU",
      "elevation": 253,
      "lat": 56.9000015259,
      "lon": 74.3000030518,
      "tz": "Asia\/Omsk"
  },
  "UNOW": {
      "icao": "UNOW",
      "iata": "",
      "name": "Tevriz Airport",
      "city": "Tevriz",
      "state": "Omsk",
      "country": "RU",
      "elevation": 217,
      "lat": 57.5040016174,
      "lon": 72.3310012817,
      "tz": "Asia\/Omsk"
  },
  "UNRR": {
      "icao": "UNRR",
      "iata": "",
      "name": "Barabinsk Airport",
      "city": "Barabinsk",
      "state": "Novosibirsk",
      "country": "RU",
      "elevation": 0,
      "lat": 55.3600006104,
      "lon": 78.3000030518,
      "tz": "Asia\/Novosibirsk"
  },
  "UNSA": {
      "icao": "UNSA",
      "iata": "",
      "name": "Aleksandrovskoye Airport",
      "city": "Aleksandrovskoye",
      "state": "Tomsk",
      "country": "RU",
      "elevation": 0,
      "lat": 60.4430007935,
      "lon": 77.8389968872,
      "tz": "Asia\/Tomsk"
  },
  "UNSS": {
      "icao": "UNSS",
      "iata": "SWT",
      "name": "Strezhevoy Airport",
      "city": "Strezhevoy",
      "state": "Tomsk",
      "country": "RU",
      "elevation": 164,
      "lat": 60.709400177,
      "lon": 77.6600036621,
      "tz": "Asia\/Tomsk"
  },
  "UNTT": {
      "icao": "UNTT",
      "iata": "TOF",
      "name": "Bogashevo Airport",
      "city": "Tomsk",
      "state": "Tomsk",
      "country": "RU",
      "elevation": 597,
      "lat": 56.3802986145,
      "lon": 85.2082977295,
      "tz": "Asia\/Tomsk"
  },
  "UNWW": {
      "icao": "UNWW",
      "iata": "NOZ",
      "name": "Spichenkovo Airport",
      "city": "Novokuznetsk",
      "state": "Kemerovo",
      "country": "RU",
      "elevation": 1024,
      "lat": 53.8114013672,
      "lon": 86.8771972656,
      "tz": "Asia\/Novokuznetsk"
  },
  "UODD": {
      "icao": "UODD",
      "iata": "DKS",
      "name": "Dikson Airport",
      "city": "Dikson",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 47,
      "lat": 73.5178070068,
      "lon": 80.3796691895,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UODN": {
      "icao": "UODN",
      "iata": "",
      "name": "Nagurskoye Air Base",
      "city": "Murmansk",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 59,
      "lat": 80.8032073975,
      "lon": 47.6635856628,
      "tz": "Europe\/Moscow"
  },
  "UODS": {
      "icao": "UODS",
      "iata": "",
      "name": "Sredniy Ostrov Air Base",
      "city": "Khatanga",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 26,
      "lat": 79.5282974243,
      "lon": 91.0749969482,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UOHH": {
      "icao": "UOHH",
      "iata": "HTG",
      "name": "Khatanga Airport",
      "city": "Khatanga",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 95,
      "lat": 71.9781036377,
      "lon": 102.4909973145,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UOIC": {
      "icao": "UOIC",
      "iata": "",
      "name": "Snezhnogorsk Airport",
      "city": "Snezhnogorsk",
      "state": "",
      "country": "RU",
      "elevation": 102,
      "lat": 68.0766677856,
      "lon": 87.6449966431,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UOIG": {
      "icao": "UOIG",
      "iata": "",
      "name": "Svetlogorsk Airport",
      "city": "Svetlogorsk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 394,
      "lat": 66.8399963379,
      "lon": 88.4033355713,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UOII": {
      "icao": "UOII",
      "iata": "IAA",
      "name": "Igarka Airport",
      "city": "Igarka",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 82,
      "lat": 67.4372024536,
      "lon": 86.6219024658,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UOOO": {
      "icao": "UOOO",
      "iata": "NSK",
      "name": "Norilsk-Alykel Airport",
      "city": "Norilsk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 574,
      "lat": 69.3110961914,
      "lon": 87.3321990967,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UOOW": {
      "icao": "UOOW",
      "iata": "",
      "name": "Valek Airport",
      "city": "Valek",
      "state": "",
      "country": "RU",
      "elevation": 108,
      "lat": 69.3969039917,
      "lon": 88.3536834717,
      "tz": "Asia\/Krasnoyarsk"
  },
  "UOTT": {
      "icao": "UOTT",
      "iata": "THX",
      "name": "Turukhansk Airport",
      "city": "Turukhansk",
      "state": "Krasnoyarskiy",
      "country": "RU",
      "elevation": 128,
      "lat": 65.797203064,
      "lon": 87.9353027344,
      "tz": "Asia\/Krasnoyarsk"
  },
  "URFB": {
      "icao": "URFB",
      "iata": "UKS",
      "name": "Belbek Airport",
      "city": "Sevastopol",
      "state": "Republic-of-Crimea",
      "country": "UA",
      "elevation": 344,
      "lat": 44.688999176,
      "lon": 33.5709991455,
      "tz": "Europe\/Simferopol"
  },
  "URFF": {
      "icao": "URFF",
      "iata": "SIP",
      "name": "Simferopol International Airport",
      "city": "Simferopol",
      "state": "Republic-of-Crimea",
      "country": "RU",
      "elevation": 639,
      "lat": 45.0522003174,
      "lon": 33.9751014709,
      "tz": "Europe\/Simferopol"
  },
  "URKA": {
      "icao": "URKA",
      "iata": "AAQ",
      "name": "Anapa Airport",
      "city": "Anapa",
      "state": "Krasnodarskiy",
      "country": "RU",
      "elevation": 174,
      "lat": 45.0021018982,
      "lon": 37.3473014832,
      "tz": "Europe\/Moscow"
  },
  "URKE": {
      "icao": "URKE",
      "iata": "EIK",
      "name": "Yeysk Airport",
      "city": "Yeysk",
      "state": "Krasnodarskiy",
      "country": "RU",
      "elevation": 60,
      "lat": 46.68,
      "lon": 38.21,
      "tz": "Europe\/Moscow"
  },
  "URKG": {
      "icao": "URKG",
      "iata": "GDZ",
      "name": "Gelendzhik Airport",
      "city": "Gelendzhik",
      "state": "Krasnodarskiy",
      "country": "RU",
      "elevation": 98,
      "lat": 44.5820926295,
      "lon": 38.0124807358,
      "tz": "Europe\/Moscow"
  },
  "URKH": {
      "icao": "URKH",
      "iata": "",
      "name": "Khanskaya Airport",
      "city": "Maykop",
      "state": "Adygeya",
      "country": "RU",
      "elevation": 600,
      "lat": 44.6800003052,
      "lon": 40.0349998474,
      "tz": "Europe\/Moscow"
  },
  "URKK": {
      "icao": "URKK",
      "iata": "KRR",
      "name": "Krasnodar International Airport",
      "city": "Krasnodar",
      "state": "Krasnodarskiy",
      "country": "RU",
      "elevation": 118,
      "lat": 45.0346984863,
      "lon": 39.170501709,
      "tz": "Europe\/Moscow"
  },
  "URKM": {
      "icao": "URKM",
      "iata": "",
      "name": "Maykop Airport",
      "city": "Maykop",
      "state": "Adygeya",
      "country": "RU",
      "elevation": 679,
      "lat": 44.653301239,
      "lon": 40.0900001526,
      "tz": "Europe\/Moscow"
  },
  "URKR": {
      "icao": "URKR",
      "iata": "",
      "name": "Armavir Air Base",
      "city": "Armavir",
      "state": "Krasnodarskiy",
      "country": "RU",
      "elevation": 712,
      "lat": 44.9700012207,
      "lon": 41.1049995422,
      "tz": "Europe\/Moscow"
  },
  "URKS": {
      "icao": "URKS",
      "iata": "",
      "name": "Enem Airport",
      "city": "Enem",
      "state": "Adygeya",
      "country": "RU",
      "elevation": 62,
      "lat": 44.9459991455,
      "lon": 38.9319992065,
      "tz": "Europe\/Moscow"
  },
  "URME": {
      "icao": "URME",
      "iata": "",
      "name": "Yessentuki Airport",
      "city": "Yessentuki",
      "state": "Stavropol'skiy",
      "country": "RU",
      "elevation": 0,
      "lat": 44.06,
      "lon": 42.829,
      "tz": "Europe\/Moscow"
  },
  "URMG": {
      "icao": "URMG",
      "iata": "GRV",
      "name": "Grozny North Airport",
      "city": "Grozny",
      "state": "Chechnya",
      "country": "RU",
      "elevation": 0,
      "lat": 43.3883018494,
      "lon": 45.698600769,
      "tz": "Europe\/Moscow"
  },
  "URML": {
      "icao": "URML",
      "iata": "MCX",
      "name": "Uytash Airport",
      "city": "Makhachkala",
      "state": "Dagestan",
      "country": "RU",
      "elevation": 12,
      "lat": 42.8167991638,
      "lon": 47.6523017883,
      "tz": "Europe\/Moscow"
  },
  "URMM": {
      "icao": "URMM",
      "iata": "MRV",
      "name": "Mineralnyye Vody Airport",
      "city": "Mineralnyye Vody",
      "state": "Stavropol'skiy",
      "country": "RU",
      "elevation": 1054,
      "lat": 44.2251014709,
      "lon": 43.0819015503,
      "tz": "Europe\/Moscow"
  },
  "URMN": {
      "icao": "URMN",
      "iata": "NAL",
      "name": "Nalchik Airport",
      "city": "Nalchik",
      "state": "Kabardino-Balkariya",
      "country": "RU",
      "elevation": 1461,
      "lat": 43.5129013062,
      "lon": 43.6366004944,
      "tz": "Europe\/Moscow"
  },
  "URMO": {
      "icao": "URMO",
      "iata": "OGZ",
      "name": "Beslan Airport",
      "city": "Beslan",
      "state": "North-Ossetia",
      "country": "RU",
      "elevation": 1673,
      "lat": 43.2051010132,
      "lon": 44.6066017151,
      "tz": "Europe\/Moscow"
  },
  "URMS": {
      "icao": "URMS",
      "iata": "IGT",
      "name": "Magas Airport",
      "city": "Magas",
      "state": "Ingushetiya",
      "country": "RU",
      "elevation": 1165,
      "lat": 43.3222999573,
      "lon": 45.0125999451,
      "tz": "Europe\/Moscow"
  },
  "URMT": {
      "icao": "URMT",
      "iata": "STW",
      "name": "Stavropol Shpakovskoye Airport",
      "city": "Stavropol",
      "state": "Stavropol'skiy",
      "country": "RU",
      "elevation": 1486,
      "lat": 45.1091995239,
      "lon": 42.1128005981,
      "tz": "Europe\/Moscow"
  },
  "UROD": {
      "icao": "UROD",
      "iata": "",
      "name": "Dudinka Airport",
      "city": "Dudinka",
      "state": "",
      "country": "RU",
      "elevation": 82,
      "lat": 69.375,
      "lon": 86.1566696167,
      "tz": "Asia\/Krasnoyarsk"
  },
  "URRH": {
      "icao": "URRH",
      "iata": "",
      "name": "Shakhty Airport",
      "city": "Shakhty",
      "state": "Rostov",
      "country": "RU",
      "elevation": 0,
      "lat": 47.697,
      "lon": 40.287,
      "tz": "Europe\/Moscow"
  },
  "URRM": {
      "icao": "URRM",
      "iata": "",
      "name": "Morozovsk Air Base",
      "city": "Morozovsk",
      "state": "Rostov",
      "country": "RU",
      "elevation": 400,
      "lat": 48.313,
      "lon": 41.791,
      "tz": "Europe\/Moscow"
  },
  "URRP": {
      "icao": "URRP",
      "iata": "ROV",
      "name": "Platov International Airport",
      "city": "Rostov-on-Don",
      "state": "Rostov",
      "country": "RU",
      "elevation": 282,
      "lat": 47.50083333,
      "lon": 39.93361111,
      "tz": "Europe\/Moscow"
  },
  "URRR": {
      "icao": "URRR",
      "iata": "RVI",
      "name": "Rostov-na-Donu Airport",
      "city": "Rostov-on-Don",
      "state": "Rostov",
      "country": "RU",
      "elevation": 280,
      "lat": 47.2582015991,
      "lon": 39.8180999756,
      "tz": "Europe\/Moscow"
  },
  "URRS": {
      "icao": "URRS",
      "iata": "",
      "name": "Salsk Airport",
      "city": "Salsk",
      "state": "Rostov",
      "country": "RU",
      "elevation": 0,
      "lat": 46.4575500983,
      "lon": 41.4833450317,
      "tz": "Europe\/Moscow"
  },
  "URRT": {
      "icao": "URRT",
      "iata": "TGK",
      "name": "Taganrog Yuzhny Airport",
      "city": "Taganrog",
      "state": "Rostov",
      "country": "RU",
      "elevation": 118,
      "lat": 47.2000007629,
      "lon": 38.8499984741,
      "tz": "Europe\/Moscow"
  },
  "URRW": {
      "icao": "URRW",
      "iata": "",
      "name": "Veshenskaya Airport",
      "city": "",
      "state": "",
      "country": "RU",
      "elevation": 253,
      "lat": 49.6566009521,
      "lon": 41.7006988525,
      "tz": "Europe\/Moscow"
  },
  "URRY": {
      "icao": "URRY",
      "iata": "VLK",
      "name": "Zavetnoe Airport",
      "city": "",
      "state": "Rostov",
      "country": "RU",
      "elevation": 431,
      "lat": 46.5443000793,
      "lon": 43.6456985474,
      "tz": "Europe\/Moscow"
  },
  "URSS": {
      "icao": "URSS",
      "iata": "AER",
      "name": "Sochi International Airport",
      "city": "Sochi",
      "state": "Krasnodarskiy",
      "country": "RU",
      "elevation": 89,
      "lat": 43.4499015808,
      "lon": 39.9566001892,
      "tz": "Europe\/Moscow"
  },
  "URWA": {
      "icao": "URWA",
      "iata": "ASF",
      "name": "Astrakhan Airport",
      "city": "Astrakhan",
      "state": "Astrakhan",
      "country": "RU",
      "elevation": -65,
      "lat": 46.2832984924,
      "lon": 48.0063018799,
      "tz": "Europe\/Astrakhan"
  },
  "URWI": {
      "icao": "URWI",
      "iata": "ESL",
      "name": "Elista Airport",
      "city": "Elista",
      "state": "Kalmykiya",
      "country": "RU",
      "elevation": 501,
      "lat": 46.3739013672,
      "lon": 44.3308982849,
      "tz": "Europe\/Moscow"
  },
  "URWW": {
      "icao": "URWW",
      "iata": "VOG",
      "name": "Volgograd International Airport",
      "city": "Volgograd",
      "state": "Volgograd",
      "country": "RU",
      "elevation": 482,
      "lat": 48.7825012207,
      "lon": 44.345500946,
      "tz": "Europe\/Volgograd"
  },
  "USCC": {
      "icao": "USCC",
      "iata": "CEK",
      "name": "Chelyabinsk Balandino Airport",
      "city": "Chelyabinsk",
      "state": "Chelyabinsk",
      "country": "RU",
      "elevation": 769,
      "lat": 55.3058013916,
      "lon": 61.5032997131,
      "tz": "Asia\/Yekaterinburg"
  },
  "USCG": {
      "icao": "USCG",
      "iata": "",
      "name": "Chelyabinsk Shagol Airport",
      "city": "",
      "state": "Chelyabinsk",
      "country": "RU",
      "elevation": 830,
      "lat": 55.2599983215,
      "lon": 61.2999992371,
      "tz": "Asia\/Yekaterinburg"
  },
  "USCM": {
      "icao": "USCM",
      "iata": "MQF",
      "name": "Magnitogorsk International Airport",
      "city": "Magnitogorsk",
      "state": "Bashkortostan",
      "country": "RU",
      "elevation": 1430,
      "lat": 53.3931007385,
      "lon": 58.7556991577,
      "tz": "Asia\/Yekaterinburg"
  },
  "USCV": {
      "icao": "USCV",
      "iata": "",
      "name": "Kalachevo Airfield",
      "city": "Chelyabinsk",
      "state": "Chelyabinsk",
      "country": "RU",
      "elevation": 0,
      "lat": 54.952999115,
      "lon": 61.5,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDA": {
      "icao": "USDA",
      "iata": "SBT",
      "name": "Sabetta Airport",
      "city": "Sabetta",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 46,
      "lat": 71.215,
      "lon": 72.038,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDB": {
      "icao": "USDB",
      "iata": "BVJ",
      "name": "Bovanenkovo",
      "city": "Bovanenkovo",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 23,
      "lat": 70.315277778,
      "lon": 68.333611111,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDD": {
      "icao": "USDD",
      "iata": "SLY",
      "name": "Salekhard Airport",
      "city": "Salekhard",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 218,
      "lat": 66.5907974243,
      "lon": 66.611000061,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDH": {
      "icao": "USDH",
      "iata": "",
      "name": "Kharasavey Airport",
      "city": "Kharasavey",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 0,
      "lat": 71.0999984741,
      "lon": 66.7509994507,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDK": {
      "icao": "USDK",
      "iata": "YMK",
      "name": "Mys Kamenny Airport",
      "city": "Mys Kamennyi",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 98,
      "lat": 68.483001709,
      "lon": 73.5670013428,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDO": {
      "icao": "USDO",
      "iata": "",
      "name": "Tolka Airport",
      "city": "Tolka",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 92,
      "lat": 63.9900016785,
      "lon": 82.0500030518,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDP": {
      "icao": "USDP",
      "iata": "KKQ",
      "name": "Krasnoselkup Airport",
      "city": "Krasnoselkup",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 0,
      "lat": 65.717,
      "lon": 82.455,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDR": {
      "icao": "USDR",
      "iata": "",
      "name": "Yar-Sale Airport",
      "city": "",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 33,
      "lat": 66.8529968262,
      "lon": 70.8450012207,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDS": {
      "icao": "USDS",
      "iata": "TQL",
      "name": "Tarko-Sale Airport",
      "city": "Tarko-Sale",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 82,
      "lat": 64.9308013916,
      "lon": 77.8180999756,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDT": {
      "icao": "USDT",
      "iata": "",
      "name": "Tazovskiy Airport",
      "city": "Tazovskiy",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 92,
      "lat": 67.4841995239,
      "lon": 78.6444015503,
      "tz": "Asia\/Yekaterinburg"
  },
  "USDU": {
      "icao": "USDU",
      "iata": "UEN",
      "name": "Urengoy Airport",
      "city": "Urengoy",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 56,
      "lat": 65.9599990845,
      "lon": 78.43699646,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHB": {
      "icao": "USHB",
      "iata": "EZV",
      "name": "Berezovo Airport",
      "city": "",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 98,
      "lat": 63.9210014343,
      "lon": 65.0305023193,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHH": {
      "icao": "USHH",
      "iata": "HMA",
      "name": "Khanty Mansiysk Airport",
      "city": "Khanty-Mansiysk",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 76,
      "lat": 61.0284996033,
      "lon": 69.0860977173,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHI": {
      "icao": "USHI",
      "iata": "IRM",
      "name": "Igrim Airport",
      "city": "",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 79,
      "lat": 63.1987991333,
      "lon": 64.4393005371,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHK": {
      "icao": "USHK",
      "iata": "KXD",
      "name": "Kondinskoye Airport",
      "city": "Kondinskoye",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 131,
      "lat": 59.6550827026,
      "lon": 67.4300460815,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHL": {
      "icao": "USHL",
      "iata": "",
      "name": "Lugovoy Airport",
      "city": "Lugovoy",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 0,
      "lat": 59.7239990234,
      "lon": 65.8330001831,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHN": {
      "icao": "USHN",
      "iata": "NYA",
      "name": "Nyagan Airport",
      "city": "Nyagan",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 361,
      "lat": 62.1100006104,
      "lon": 65.6149978638,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHS": {
      "icao": "USHS",
      "iata": "OVS",
      "name": "Sovetskiy Airport",
      "city": "Sovetskiy",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 351,
      "lat": 61.3266220093,
      "lon": 63.6019134521,
      "tz": "Asia\/Yekaterinburg"
  },
  "USHU": {
      "icao": "USHU",
      "iata": "URJ",
      "name": "Uray Airport",
      "city": "Uray",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 190,
      "lat": 60.1032981873,
      "lon": 64.8266983032,
      "tz": "Asia\/Yekaterinburg"
  },
  "USII": {
      "icao": "USII",
      "iata": "IJK",
      "name": "Izhevsk Airport",
      "city": "Izhevsk",
      "state": "Udmurtiya",
      "country": "RU",
      "elevation": 531,
      "lat": 56.8280982971,
      "lon": 53.4575004578,
      "tz": "Europe\/Samara"
  },
  "USKK": {
      "icao": "USKK",
      "iata": "KVX",
      "name": "Pobedilovo Airport",
      "city": "Kirov",
      "state": "Kirov",
      "country": "RU",
      "elevation": 479,
      "lat": 58.5032997131,
      "lon": 49.3483009338,
      "tz": "Europe\/Kirov"
  },
  "USMM": {
      "icao": "USMM",
      "iata": "NYM",
      "name": "Nadym Airport",
      "city": "Nadym",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 49,
      "lat": 65.4809036255,
      "lon": 72.6988983154,
      "tz": "Asia\/Yekaterinburg"
  },
  "USMQ": {
      "icao": "USMQ",
      "iata": "",
      "name": "Yamburg",
      "city": "Yamburg",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 108,
      "lat": 67.988611,
      "lon": 75.098333,
      "tz": "Asia\/Yekaterinburg"
  },
  "USMU": {
      "icao": "USMU",
      "iata": "NUX",
      "name": "Novy Urengoy Airport",
      "city": "Novy Urengoy",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 210,
      "lat": 66.0693969727,
      "lon": 76.5203018188,
      "tz": "Asia\/Yekaterinburg"
  },
  "USNN": {
      "icao": "USNN",
      "iata": "NJC",
      "name": "Nizhnevartovsk Airport",
      "city": "Nizhnevartovsk",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 177,
      "lat": 60.9492988586,
      "lon": 76.4835968018,
      "tz": "Asia\/Yekaterinburg"
  },
  "USPB": {
      "icao": "USPB",
      "iata": "",
      "name": "Bakharevka Airport",
      "city": "Perm",
      "state": "",
      "country": "RU",
      "elevation": 564,
      "lat": 57.9516983032,
      "lon": 56.1949996948,
      "tz": "Asia\/Yekaterinburg"
  },
  "USPP": {
      "icao": "USPP",
      "iata": "PEE",
      "name": "Bolshoye Savino Airport",
      "city": "Perm",
      "state": "Perm",
      "country": "RU",
      "elevation": 404,
      "lat": 57.9145011902,
      "lon": 56.0211982727,
      "tz": "Asia\/Yekaterinburg"
  },
  "USPT": {
      "icao": "USPT",
      "iata": "",
      "name": "Berezniki Airport",
      "city": "Solikamsk",
      "state": "",
      "country": "RU",
      "elevation": 207,
      "lat": 59.5795478821,
      "lon": 56.8576812744,
      "tz": "Asia\/Yekaterinburg"
  },
  "USRK": {
      "icao": "USRK",
      "iata": "KGP",
      "name": "Kogalym International Airport",
      "city": "Kogalym",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 220,
      "lat": 62.1903991699,
      "lon": 74.5337982178,
      "tz": "Asia\/Yekaterinburg"
  },
  "USRN": {
      "icao": "USRN",
      "iata": "NFG",
      "name": "Nefteyugansk Airport",
      "city": "Nefteyugansk",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 115,
      "lat": 61.1082992554,
      "lon": 72.6500015259,
      "tz": "Asia\/Yekaterinburg"
  },
  "USRO": {
      "icao": "USRO",
      "iata": "NOJ",
      "name": "Noyabrsk Airport",
      "city": "Noyabrsk",
      "state": "Yamalo-Nenets",
      "country": "RU",
      "elevation": 446,
      "lat": 63.1833000183,
      "lon": 75.2699966431,
      "tz": "Asia\/Yekaterinburg"
  },
  "USRR": {
      "icao": "USRR",
      "iata": "SGC",
      "name": "Surgut Airport",
      "city": "Surgut",
      "state": "Khanty-Mansia",
      "country": "RU",
      "elevation": 200,
      "lat": 61.3437004089,
      "lon": 73.401802063,
      "tz": "Asia\/Yekaterinburg"
  },
  "USSK": {
      "icao": "USSK",
      "iata": "",
      "name": "Uktus Airport",
      "city": "Yekaterinburg",
      "state": "Sverdlovsk",
      "country": "RU",
      "elevation": 643,
      "lat": 56.7016983032,
      "lon": 60.7900009155,
      "tz": "Asia\/Yekaterinburg"
  },
  "USSS": {
      "icao": "USSS",
      "iata": "SVX",
      "name": "Koltsovo Airport",
      "city": "Yekaterinburg",
      "state": "Sverdlovsk",
      "country": "RU",
      "elevation": 764,
      "lat": 56.7430992126,
      "lon": 60.8027000427,
      "tz": "Asia\/Yekaterinburg"
  },
  "USTL": {
      "icao": "USTL",
      "iata": "",
      "name": "Plekhanovo Airport",
      "city": "Tyumen",
      "state": "Tyumenskaya",
      "country": "RU",
      "elevation": 308,
      "lat": 57.1433334351,
      "lon": 65.4683303833,
      "tz": "Asia\/Yekaterinburg"
  },
  "USTO": {
      "icao": "USTO",
      "iata": "TOX",
      "name": "Tobolsk Airport",
      "city": "Tobolsk",
      "state": "Tyumenskaya",
      "country": "RU",
      "elevation": 167,
      "lat": 58.135799408,
      "lon": 68.2319030762,
      "tz": "Asia\/Yekaterinburg"
  },
  "USTR": {
      "icao": "USTR",
      "iata": "TJM",
      "name": "Roshchino International Airport",
      "city": "Tyumen",
      "state": "Tyumenskaya",
      "country": "RU",
      "elevation": 378,
      "lat": 57.1896018982,
      "lon": 65.3243026733,
      "tz": "Asia\/Yekaterinburg"
  },
  "USUK": {
      "icao": "USUK",
      "iata": "",
      "name": "Kurtamysh Airport",
      "city": "Kurtamysh",
      "state": "Kurgan",
      "country": "RU",
      "elevation": 0,
      "lat": 54.9150009155,
      "lon": 64.4670028687,
      "tz": "Asia\/Yekaterinburg"
  },
  "USUU": {
      "icao": "USUU",
      "iata": "KRO",
      "name": "Kurgan Airport",
      "city": "Kurgan",
      "state": "Kurgan",
      "country": "RU",
      "elevation": 240,
      "lat": 55.4752998352,
      "lon": 65.4156036377,
      "tz": "Asia\/Yekaterinburg"
  },
  "UT00": {
      "icao": "UT00",
      "iata": "",
      "name": "Swains Creek Airport",
      "city": "Alton",
      "state": "Utah",
      "country": "US",
      "elevation": 7780,
      "lat": 37.4721984863,
      "lon": -112.6220016479,
      "tz": "America\/Denver"
  },
  "UT02": {
      "icao": "UT02",
      "iata": "",
      "name": "Sandy Ranch Airport",
      "city": "Torrey",
      "state": "Utah",
      "country": "US",
      "elevation": 5468,
      "lat": 38.0889015198,
      "lon": -111.0670013428,
      "tz": "America\/Denver"
  },
  "UT03": {
      "icao": "UT03",
      "iata": "",
      "name": "Hite Airport",
      "city": "Hanksville",
      "state": "Utah",
      "country": "US",
      "elevation": 3840,
      "lat": 37.8947016926,
      "lon": -110.379123688,
      "tz": "America\/Denver"
  },
  "UT06": {
      "icao": "UT06",
      "iata": "",
      "name": "Rogers Roost Airport",
      "city": "Thompson Springs",
      "state": "Utah",
      "country": "US",
      "elevation": 5100,
      "lat": 38.9665985107,
      "lon": -109.7200012207,
      "tz": "America\/Denver"
  },
  "UT07": {
      "icao": "UT07",
      "iata": "",
      "name": "Air Village Strip",
      "city": "Vernal",
      "state": "Utah",
      "country": "US",
      "elevation": 5800,
      "lat": 40.4249992371,
      "lon": -109.5920028687,
      "tz": "America\/Denver"
  },
  "UT08": {
      "icao": "UT08",
      "iata": "",
      "name": "Camp Williams Airfield",
      "city": "Camp Williams",
      "state": "Utah",
      "country": "US",
      "elevation": 4860,
      "lat": 40.4319000244,
      "lon": -111.930999756,
      "tz": "America\/Denver"
  },
  "UT09": {
      "icao": "UT09",
      "iata": "",
      "name": "Tavapats Ranch Airport",
      "city": "Green River",
      "state": "Utah",
      "country": "US",
      "elevation": 9200,
      "lat": 39.4832992554,
      "lon": -110.1510009766,
      "tz": "America\/Denver"
  },
  "UT10": {
      "icao": "UT10",
      "iata": "",
      "name": "Cedar Valley Airport",
      "city": "Cedar Fort",
      "state": "Utah",
      "country": "US",
      "elevation": 5000,
      "lat": 40.3572006226,
      "lon": -112.0179977417,
      "tz": "America\/Denver"
  },
  "UT13": {
      "icao": "UT13",
      "iata": "",
      "name": "Number 18 Airport",
      "city": "South Jordan",
      "state": "Utah",
      "country": "US",
      "elevation": 4820,
      "lat": 40.5638999939,
      "lon": -112.013999939,
      "tz": "America\/Denver"
  },
  "UT15": {
      "icao": "UT15",
      "iata": "",
      "name": "Flying Cal Ute Rancheros Airport",
      "city": "Paragonah",
      "state": "Utah",
      "country": "US",
      "elevation": 5764,
      "lat": 37.9286003113,
      "lon": -112.7689971924,
      "tz": "America\/Denver"
  },
  "UT17": {
      "icao": "UT17",
      "iata": "",
      "name": "Pfeiler Ranch Airport",
      "city": "Panguitch",
      "state": "Utah",
      "country": "US",
      "elevation": 7040,
      "lat": 37.9322013855,
      "lon": -112.3320007324,
      "tz": "America\/Denver"
  },
  "UT1M": {
      "icao": "UT1M",
      "iata": "",
      "name": "Kakaydy Airport",
      "city": "Goran",
      "state": "Surxondaryo",
      "country": "UZ",
      "elevation": 1207,
      "lat": 37.6242980957,
      "lon": 67.5176010132,
      "tz": "Asia\/Samarkand"
  },
  "UT1N": {
      "icao": "UT1N",
      "iata": "",
      "name": "Karshi South Airport",
      "city": "Karshi",
      "state": "Qashqadaryo",
      "country": "UZ",
      "elevation": 1194,
      "lat": 38.8018989563,
      "lon": 65.7746963501,
      "tz": "Asia\/Samarkand"
  },
  "UT1O": {
      "icao": "UT1O",
      "iata": "",
      "name": "Beleuli North Airport",
      "city": "Beleuli",
      "state": "Karakalpakstan",
      "country": "UZ",
      "elevation": 413,
      "lat": 44.0541000366,
      "lon": 57.5477981567,
      "tz": "Asia\/Samarkand"
  },
  "UT1P": {
      "icao": "UT1P",
      "iata": "",
      "name": "Kagan South Airport",
      "city": "Kagan",
      "state": "Bukhara",
      "country": "UZ",
      "elevation": 689,
      "lat": 39.6893997192,
      "lon": 64.5503997803,
      "tz": "Asia\/Samarkand"
  },
  "UT1Q": {
      "icao": "UT1Q",
      "iata": "",
      "name": "Pakhtakor Airport",
      "city": "Pakhtakor",
      "state": "Jizzax",
      "country": "UZ",
      "elevation": 1023,
      "lat": 40.2527008057,
      "lon": 67.9107971191,
      "tz": "Asia\/Tashkent"
  },
  "UT24": {
      "icao": "UT24",
      "iata": "",
      "name": "Strawberry Valley Estates Airport",
      "city": "Alton",
      "state": "Utah",
      "country": "US",
      "elevation": 8141,
      "lat": 37.4933013916,
      "lon": -112.6449966431,
      "tz": "America\/Denver"
  },
  "UT25": {
      "icao": "UT25",
      "iata": "",
      "name": "Monument Valley Airport",
      "city": "Monument Valley",
      "state": "Utah",
      "country": "US",
      "elevation": 5192,
      "lat": 37.0167007446,
      "lon": -110.2009963989,
      "tz": "America\/Denver"
  },
  "UT26": {
      "icao": "UT26",
      "iata": "",
      "name": "Bryce Woodland Estates Landing Strip",
      "city": "Hatch",
      "state": "Utah",
      "country": "US",
      "elevation": 7600,
      "lat": 37.584400177,
      "lon": -112.391998291,
      "tz": "America\/Denver"
  },
  "UT27": {
      "icao": "UT27",
      "iata": "",
      "name": "Hurricane Mesa Airport",
      "city": "Hurricane",
      "state": "Utah",
      "country": "US",
      "elevation": 5105,
      "lat": 37.2510986328,
      "lon": -113.2089996338,
      "tz": "America\/Denver"
  },
  "UT28": {
      "icao": "UT28",
      "iata": "",
      "name": "Sun Valley Estates Airport",
      "city": "Lund",
      "state": "Utah",
      "country": "US",
      "elevation": 5110,
      "lat": 37.9749984741,
      "lon": -113.4629974365,
      "tz": "America\/Denver"
  },
  "UT29": {
      "icao": "UT29",
      "iata": "",
      "name": "Jack's Airport",
      "city": "Tremonton",
      "state": "Utah",
      "country": "US",
      "elevation": 4440,
      "lat": 41.7338981628,
      "lon": -112.1940002441,
      "tz": "America\/Denver"
  },
  "UT30": {
      "icao": "UT30",
      "iata": "",
      "name": "Deer Springs Ranch Airport",
      "city": "Kanab",
      "state": "Utah",
      "country": "US",
      "elevation": 6485,
      "lat": 37.3424987793,
      "lon": -112.2239990234,
      "tz": "America\/Denver"
  },
  "UT37": {
      "icao": "UT37",
      "iata": "",
      "name": "Carmel Mountain Ranch Airport",
      "city": "Mount Carmel",
      "state": "Utah",
      "country": "US",
      "elevation": 5590,
      "lat": 37.2668991089,
      "lon": -112.7009963989,
      "tz": "America\/Denver"
  },
  "UT40": {
      "icao": "UT40",
      "iata": "",
      "name": "Payne Field",
      "city": "Syracuse",
      "state": "Utah",
      "country": "US",
      "elevation": 4228,
      "lat": 41.0983009338,
      "lon": -112.1159973145,
      "tz": "America\/Denver"
  },
  "UT41": {
      "icao": "UT41",
      "iata": "",
      "name": "Glenmar Ranch Airport",
      "city": "Cedar Fort",
      "state": "Utah",
      "country": "US",
      "elevation": 5030,
      "lat": 40.3394012451,
      "lon": -111.9990005493,
      "tz": "America\/Denver"
  },
  "UT42": {
      "icao": "UT42",
      "iata": "",
      "name": "Westwater Airport",
      "city": "Cisco",
      "state": "Utah",
      "country": "US",
      "elevation": 4660,
      "lat": 39.1467018127,
      "lon": -109.1449966431,
      "tz": "America\/Denver"
  },
  "UT43": {
      "icao": "UT43",
      "iata": "",
      "name": "Citabriair Airport",
      "city": "Kanarraville",
      "state": "Utah",
      "country": "US",
      "elevation": 5600,
      "lat": 37.636100769,
      "lon": -113.2470016479,
      "tz": "America\/Denver"
  },
  "UT47": {
      "icao": "UT47",
      "iata": "",
      "name": "Grassy Meadows\/Sky Ranch Landowners Assn Airport",
      "city": "Hurricane",
      "state": "Utah",
      "country": "US",
      "elevation": 3350,
      "lat": 37.1018981934,
      "lon": -113.314002991,
      "tz": "America\/Denver"
  },
  "UT48": {
      "icao": "UT48",
      "iata": "",
      "name": "Lbl Farms Airport",
      "city": "Clinton",
      "state": "Utah",
      "country": "US",
      "elevation": 4260,
      "lat": 41.1501998901,
      "lon": -112.0989990234,
      "tz": "America\/Denver"
  },
  "UT49": {
      "icao": "UT49",
      "iata": "",
      "name": "Desert Aviation Airport",
      "city": "Delta",
      "state": "Utah",
      "country": "US",
      "elevation": 4589,
      "lat": 39.459400177,
      "lon": -112.6549987793,
      "tz": "America\/Denver"
  },
  "UT53": {
      "icao": "UT53",
      "iata": "",
      "name": "Sky Ranch Airport",
      "city": "Moab",
      "state": "Utah",
      "country": "US",
      "elevation": 4875,
      "lat": 38.4878005981,
      "lon": -109.4489974976,
      "tz": "America\/Denver"
  },
  "UT54": {
      "icao": "UT54",
      "iata": "",
      "name": "Crystal Springs Ranch Airport",
      "city": "Leeds",
      "state": "Utah",
      "country": "US",
      "elevation": 3671,
      "lat": 37.2532997131,
      "lon": -113.3339996338,
      "tz": "America\/Denver"
  },
  "UT57": {
      "icao": "UT57",
      "iata": "",
      "name": "Sulphurdale Airport",
      "city": "Beaver",
      "state": "Utah",
      "country": "US",
      "elevation": 6060,
      "lat": 38.5724983215,
      "lon": -112.5920028687,
      "tz": "America\/Denver"
  },
  "UT59": {
      "icao": "UT59",
      "iata": "",
      "name": "Needles Outpost Airport",
      "city": "Monticello",
      "state": "Utah",
      "country": "US",
      "elevation": 4950,
      "lat": 38.1758003235,
      "lon": -109.7440032959,
      "tz": "America\/Denver"
  },
  "UT65": {
      "icao": "UT65",
      "iata": "",
      "name": "Goshute Airport",
      "city": "Ibapah",
      "state": "Utah",
      "country": "US",
      "elevation": 5460,
      "lat": 39.9980010986,
      "lon": -113.9769973755,
      "tz": "America\/Denver"
  },
  "UT67": {
      "icao": "UT67",
      "iata": "",
      "name": "Paradise Air Park",
      "city": "Tridell",
      "state": "Utah",
      "country": "US",
      "elevation": 5700,
      "lat": 40.4411010742,
      "lon": -109.8470001221,
      "tz": "America\/Denver"
  },
  "UT68": {
      "icao": "UT68",
      "iata": "",
      "name": "Tangri-La Airport",
      "city": "Moab",
      "state": "Utah",
      "country": "US",
      "elevation": 3940,
      "lat": 38.4728012085,
      "lon": -109.6600036621,
      "tz": "America\/Denver"
  },
  "UT69": {
      "icao": "UT69",
      "iata": "",
      "name": "Pelican Lake Airport",
      "city": "Randlett",
      "state": "Utah",
      "country": "US",
      "elevation": 4815,
      "lat": 40.1814002991,
      "lon": -109.6719970703,
      "tz": "America\/Denver"
  },
  "UT70": {
      "icao": "UT70",
      "iata": "",
      "name": "Garrison Airport",
      "city": "Garrison",
      "state": "Utah",
      "country": "US",
      "elevation": 5225,
      "lat": 38.9649009705,
      "lon": -114.0370025635,
      "tz": "America\/Denver"
  },
  "UT73": {
      "icao": "UT73",
      "iata": "",
      "name": "Maymanak Airport",
      "city": "Maynanak",
      "state": "Qashqadaryo",
      "country": "UZ",
      "elevation": 1047,
      "lat": 39.1377983093,
      "lon": 65.1652984619,
      "tz": "Asia\/Samarkand"
  },
  "UT74": {
      "icao": "UT74",
      "iata": "",
      "name": "Fry Canyon Field",
      "city": "Fry Canyon",
      "state": "Utah",
      "country": "US",
      "elevation": 5372,
      "lat": 37.6483001709,
      "lon": -110.1669998169,
      "tz": "America\/Denver"
  },
  "UT75": {
      "icao": "UT75",
      "iata": "",
      "name": "Mineral Canyon Strip",
      "city": "Moab",
      "state": "Utah",
      "country": "US",
      "elevation": 3900,
      "lat": 38.5315831779,
      "lon": -109.995889664,
      "tz": "America\/Denver"
  },
  "UT77": {
      "icao": "UT77",
      "iata": "",
      "name": "Kungrad Airport",
      "city": "Kungrad",
      "state": "Karakalpakstan",
      "country": "UZ",
      "elevation": 180,
      "lat": 43.0830001831,
      "lon": 58.8842010498,
      "tz": "Asia\/Samarkand"
  },
  "UT79": {
      "icao": "UT79",
      "iata": "",
      "name": "3-I Rocker Ranch Airport",
      "city": "Jensen",
      "state": "Utah",
      "country": "US",
      "elevation": 4865,
      "lat": 40.415599823,
      "lon": -109.34400177,
      "tz": "America\/Denver"
  },
  "UT80": {
      "icao": "UT80",
      "iata": "",
      "name": "Hamilton Fort Ranch Airport",
      "city": "Cedar City",
      "state": "Utah",
      "country": "US",
      "elevation": 5460,
      "lat": 37.6347007751,
      "lon": -113.158996582,
      "tz": "America\/Denver"
  },
  "UT82": {
      "icao": "UT82",
      "iata": "",
      "name": "Beryl Junction Airport",
      "city": "Beryl",
      "state": "Utah",
      "country": "US",
      "elevation": 5181,
      "lat": 37.7099990845,
      "lon": -113.6460037231,
      "tz": "America\/Denver"
  },
  "UT83": {
      "icao": "UT83",
      "iata": "",
      "name": "Thunder Ridge Airpark",
      "city": "Fruitland",
      "state": "Utah",
      "country": "US",
      "elevation": 7050,
      "lat": 40.2578010559,
      "lon": -110.8590011597,
      "tz": "America\/Denver"
  },
  "UT97": {
      "icao": "UT97",
      "iata": "",
      "name": "Happy Canyon Airport",
      "city": "Happy Canyon",
      "state": "Utah",
      "country": "US",
      "elevation": 4934,
      "lat": 38.1571998596,
      "lon": -110.2919998169,
      "tz": "America\/Denver"
  },
  "UT99": {
      "icao": "UT99",
      "iata": "",
      "name": "West Desert Airpark",
      "city": "Cedar Valley",
      "state": "Utah",
      "country": "US",
      "elevation": 4902,
      "lat": 40.2425994873,
      "lon": -112.0930023193,
      "tz": "America\/Denver"
  },
  "UTAA": {
      "icao": "UTAA",
      "iata": "ASB",
      "name": "Ashgabat Airport",
      "city": "Ashgabat",
      "state": "Ashgabat",
      "country": "TM",
      "elevation": 692,
      "lat": 37.9868011475,
      "lon": 58.361000061,
      "tz": "Asia\/Ashgabat"
  },
  "UTAK": {
      "icao": "UTAK",
      "iata": "KRW",
      "name": "Turkmenbashi Airport",
      "city": "Krasnovodsk",
      "state": "",
      "country": "TM",
      "elevation": 279,
      "lat": 40.0633010864,
      "lon": 53.0071983337,
      "tz": "Asia\/Ashgabat"
  },
  "UTAM": {
      "icao": "UTAM",
      "iata": "MYP",
      "name": "Mary Airport",
      "city": "Mary",
      "state": "",
      "country": "TM",
      "elevation": 728,
      "lat": 37.6194000244,
      "lon": 61.8967018127,
      "tz": "Asia\/Ashgabat"
  },
  "UTAT": {
      "icao": "UTAT",
      "iata": "TAZ",
      "name": "Dashoguz Airport",
      "city": "Dashoguz",
      "state": "Da\u015foguz",
      "country": "TM",
      "elevation": 272,
      "lat": 41.761100769,
      "lon": 59.8266983032,
      "tz": "Asia\/Ashgabat"
  },
  "UTAV": {
      "icao": "UTAV",
      "iata": "CRZ",
      "name": "Turkmenabat Airport",
      "city": "Turkmenabat",
      "state": "",
      "country": "TM",
      "elevation": 630,
      "lat": 39.0833015442,
      "lon": 63.6133003235,
      "tz": "Asia\/Ashgabat"
  },
  "UTDD": {
      "icao": "UTDD",
      "iata": "DYU",
      "name": "Dushanbe Airport",
      "city": "Dushanbe",
      "state": "Dushanbe",
      "country": "TJ",
      "elevation": 2575,
      "lat": 38.5433006287,
      "lon": 68.8249969482,
      "tz": "Asia\/Dushanbe"
  },
  "UTDK": {
      "icao": "UTDK",
      "iata": "TJU",
      "name": "Kulob Airport",
      "city": "Kulyab",
      "state": "Khatlon",
      "country": "TJ",
      "elevation": 2293,
      "lat": 37.9880981445,
      "lon": 69.8050003052,
      "tz": "Asia\/Dushanbe"
  },
  "UTDL": {
      "icao": "UTDL",
      "iata": "LBD",
      "name": "Khudzhand Airport",
      "city": "Khudzhand",
      "state": "Viloyati-Sughd",
      "country": "TJ",
      "elevation": 1450,
      "lat": 40.2154006958,
      "lon": 69.6947021484,
      "tz": "Asia\/Dushanbe"
  },
  "UTDT": {
      "icao": "UTDT",
      "iata": "KQT",
      "name": "Qurghonteppa International Airport",
      "city": "Kurgan-Tyube",
      "state": "Khatlon",
      "country": "TJ",
      "elevation": 1473,
      "lat": 37.8664016724,
      "lon": 68.8647003174,
      "tz": "Asia\/Dushanbe"
  },
  "UTKA": {
      "icao": "UTKA",
      "iata": "AZN",
      "name": "Andizhan Airport",
      "city": "Andizhan",
      "state": "Andijon",
      "country": "UZ",
      "elevation": 1515,
      "lat": 40.7276992798,
      "lon": 72.2939987183,
      "tz": "Asia\/Tashkent"
  },
  "UTKF": {
      "icao": "UTKF",
      "iata": "FEG",
      "name": "Fergana Airport",
      "city": "Fergana",
      "state": "Fergana",
      "country": "UZ",
      "elevation": 1980,
      "lat": 40.3587989807,
      "lon": 71.7450027466,
      "tz": "Asia\/Tashkent"
  },
  "UTKK": {
      "icao": "UTKK",
      "iata": "",
      "name": "Kokand Airport",
      "city": "Kokand",
      "state": "Fergana",
      "country": "UZ",
      "elevation": 1357,
      "lat": 40.5397987366,
      "lon": 70.9748001099,
      "tz": "Asia\/Tashkent"
  },
  "UTKN": {
      "icao": "UTKN",
      "iata": "NMA",
      "name": "Namangan Airport",
      "city": "Namangan",
      "state": "Namangan",
      "country": "UZ",
      "elevation": 1555,
      "lat": 40.9846000671,
      "lon": 71.5567016602,
      "tz": "Asia\/Tashkent"
  },
  "UTNM": {
      "icao": "UTNM",
      "iata": "",
      "name": "Muynak Airport",
      "city": "Muynak",
      "state": "Karakalpakstan",
      "country": "UZ",
      "elevation": 177,
      "lat": 43.7551994324,
      "lon": 59.0308990479,
      "tz": "Asia\/Samarkand"
  },
  "UTNN": {
      "icao": "UTNN",
      "iata": "NCU",
      "name": "Nukus Airport",
      "city": "Nukus",
      "state": "Karakalpakstan",
      "country": "UZ",
      "elevation": 246,
      "lat": 42.4883995056,
      "lon": 59.623298645,
      "tz": "Asia\/Samarkand"
  },
  "UTNT": {
      "icao": "UTNT",
      "iata": "",
      "name": "Turtkul Airport",
      "city": "Turtkul",
      "state": "Karakalpakstan",
      "country": "UZ",
      "elevation": 305,
      "lat": 41.5750007629,
      "lon": 60.9665985107,
      "tz": "Asia\/Samarkand"
  },
  "UTNU": {
      "icao": "UTNU",
      "iata": "UGC",
      "name": "Urgench Airport",
      "city": "Urgench",
      "state": "Xorazm",
      "country": "UZ",
      "elevation": 320,
      "lat": 41.5843009949,
      "lon": 60.6417007446,
      "tz": "Asia\/Samarkand"
  },
  "UTOD": {
      "icao": "UTOD",
      "iata": "",
      "name": "Khorog Airport",
      "city": "Khorog",
      "state": "Gorno-Badakhshan",
      "country": "TJ",
      "elevation": 6700,
      "lat": 37.502222,
      "lon": 71.513333,
      "tz": "Asia\/Dushanbe"
  },
  "UTSA": {
      "icao": "UTSA",
      "iata": "NVI",
      "name": "Navoi Airport",
      "city": "Navoi",
      "state": "Navoiy",
      "country": "UZ",
      "elevation": 0,
      "lat": 40.1171989441,
      "lon": 65.1707992554,
      "tz": "Asia\/Samarkand"
  },
  "UTSB": {
      "icao": "UTSB",
      "iata": "BHK",
      "name": "Bukhara Airport",
      "city": "Bukhara",
      "state": "",
      "country": "UZ",
      "elevation": 751,
      "lat": 39.7750015259,
      "lon": 64.4832992554,
      "tz": "Asia\/Samarkand"
  },
  "UTSH": {
      "icao": "UTSH",
      "iata": "",
      "name": "Shakhristabz Airport",
      "city": "Shakhristabz",
      "state": "Qashqadaryo",
      "country": "UZ",
      "elevation": 1900,
      "lat": 39.0690994263,
      "lon": 66.7543029785,
      "tz": "Asia\/Samarkand"
  },
  "UTSK": {
      "icao": "UTSK",
      "iata": "KSQ",
      "name": "Karshi Airport",
      "city": "Karshi",
      "state": "Qashqadaryo",
      "country": "UZ",
      "elevation": 1365,
      "lat": 38.8025,
      "lon": 65.773056,
      "tz": "Asia\/Samarkand"
  },
  "UTSL": {
      "icao": "UTSL",
      "iata": "KSQ",
      "name": "Karshi Khanabad Airport",
      "city": "Khanabad",
      "state": "Qashqadaryo",
      "country": "UZ",
      "elevation": 1365,
      "lat": 38.8335990906,
      "lon": 65.9215011597,
      "tz": "Asia\/Samarkand"
  },
  "UTSM": {
      "icao": "UTSM",
      "iata": "",
      "name": "Tandy Bulak Airport",
      "city": "Tandy Bulak",
      "state": "Navoiy",
      "country": "UZ",
      "elevation": 716,
      "lat": 41.7607917786,
      "lon": 64.6024017334,
      "tz": "Asia\/Samarkand"
  },
  "UTSN": {
      "icao": "UTSN",
      "iata": "AFS",
      "name": "Sugraly Airport",
      "city": "Zarafshan",
      "state": "Navoiy",
      "country": "UZ",
      "elevation": 1396,
      "lat": 41.613899231,
      "lon": 64.2332000732,
      "tz": "Asia\/Samarkand"
  },
  "UTSR": {
      "icao": "UTSR",
      "iata": "",
      "name": "Sariasiya Airport",
      "city": "Sariasiya",
      "state": "Surxondaryo",
      "country": "UZ",
      "elevation": 1949,
      "lat": 38.4105987549,
      "lon": 67.9451980591,
      "tz": "Asia\/Samarkand"
  },
  "UTSS": {
      "icao": "UTSS",
      "iata": "SKD",
      "name": "Samarkand Airport",
      "city": "Samarkand",
      "state": "",
      "country": "UZ",
      "elevation": 2224,
      "lat": 39.7005004883,
      "lon": 66.9838027954,
      "tz": "Asia\/Samarkand"
  },
  "UTST": {
      "icao": "UTST",
      "iata": "TMJ",
      "name": "Termez Airport",
      "city": "Termez",
      "state": "Surxondaryo",
      "country": "UZ",
      "elevation": 1027,
      "lat": 37.2867012024,
      "lon": 67.3099975586,
      "tz": "Asia\/Samarkand"
  },
  "UTSU": {
      "icao": "UTSU",
      "iata": "",
      "name": "Uchkuduk Airport",
      "city": "Uchkuduk",
      "state": "Navoiy",
      "country": "UZ",
      "elevation": 416,
      "lat": 42.0830993652,
      "lon": 63.4492988586,
      "tz": "Asia\/Samarkand"
  },
  "UTTP": {
      "icao": "UTTP",
      "iata": "",
      "name": "Tashkent East Airport",
      "city": "Tashkent",
      "state": "Toshkent",
      "country": "UZ",
      "elevation": 1574,
      "lat": 41.3126983643,
      "lon": 69.3914031982,
      "tz": "Asia\/Tashkent"
  },
  "UTTT": {
      "icao": "UTTT",
      "iata": "TAS",
      "name": "Tashkent International Airport",
      "city": "Tashkent",
      "state": "Toshkent-Shahri",
      "country": "UZ",
      "elevation": 1417,
      "lat": 41.257900238,
      "lon": 69.2811965942,
      "tz": "Asia\/Tashkent"
  },
  "UUBA": {
      "icao": "UUBA",
      "iata": "KMW",
      "name": "Kostroma Sokerkino Airport",
      "city": "Kostroma",
      "state": "Kostroma",
      "country": "RU",
      "elevation": 446,
      "lat": 57.7969017029,
      "lon": 41.0194015503,
      "tz": "Europe\/Moscow"
  },
  "UUBB": {
      "icao": "UUBB",
      "iata": "BKA",
      "name": "Bykovo Airport",
      "city": "Moscow",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 427,
      "lat": 55.6171989441,
      "lon": 38.0600013733,
      "tz": "Europe\/Moscow"
  },
  "UUBC": {
      "icao": "UUBC",
      "iata": "KLF",
      "name": "Grabtsevo Airport",
      "city": "Kaluga",
      "state": "Kaluga",
      "country": "RU",
      "elevation": 656,
      "lat": 54.5499992371,
      "lon": 36.3666687012,
      "tz": "Europe\/Moscow"
  },
  "UUBD": {
      "icao": "UUBD",
      "iata": "",
      "name": "Dyagilevo Air Base",
      "city": "",
      "state": "Rjazan",
      "country": "RU",
      "elevation": 440,
      "lat": 54.6446990967,
      "lon": 39.570400238,
      "tz": "Europe\/Moscow"
  },
  "UUBI": {
      "icao": "UUBI",
      "iata": "IWA",
      "name": "Ivanovo South Airport",
      "city": "Ivanovo",
      "state": "",
      "country": "RU",
      "elevation": 410,
      "lat": 56.9393997192,
      "lon": 40.9407997131,
      "tz": "Europe\/Moscow"
  },
  "UUBK": {
      "icao": "UUBK",
      "iata": "RYB",
      "name": "Staroselye Airport",
      "city": "Rybinsk",
      "state": "Jaroslavl",
      "country": "RU",
      "elevation": 423,
      "lat": 58.1041984558,
      "lon": 38.9294013977,
      "tz": "Europe\/Moscow"
  },
  "UUBL": {
      "icao": "UUBL",
      "iata": "",
      "name": "Semyazino Airport",
      "city": "Vladimir",
      "state": "Vladimir",
      "country": "RU",
      "elevation": 554,
      "lat": 56.126701355,
      "lon": 40.3149986267,
      "tz": "Europe\/Moscow"
  },
  "UUBM": {
      "icao": "UUBM",
      "iata": "",
      "name": "Myachkovo Airport",
      "city": "Moscow",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 410,
      "lat": 55.5600013733,
      "lon": 37.9850006104,
      "tz": "Europe\/Moscow"
  },
  "UUBN": {
      "icao": "UUBN",
      "iata": "",
      "name": "Zmeyevo Airport",
      "city": "Tver",
      "state": "Tverskaya",
      "country": "RU",
      "elevation": 472,
      "lat": 56.9150009155,
      "lon": 35.9350013733,
      "tz": "Europe\/Moscow"
  },
  "UUBP": {
      "icao": "UUBP",
      "iata": "BZK",
      "name": "Bryansk Airport",
      "city": "Bryansk",
      "state": "Brjansk",
      "country": "RU",
      "elevation": 663,
      "lat": 53.2141990662,
      "lon": 34.176399231,
      "tz": "Europe\/Moscow"
  },
  "UUBS": {
      "icao": "UUBS",
      "iata": "LNX",
      "name": "Smolensk South Airport",
      "city": "Smolensk",
      "state": "Smolensk",
      "country": "RU",
      "elevation": 820,
      "lat": 54.745,
      "lon": 32.065,
      "tz": "Europe\/Moscow"
  },
  "UUBW": {
      "icao": "UUBW",
      "iata": "ZIA",
      "name": "Ramenskoye Airport",
      "city": "Zhukovsky",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 377,
      "lat": 55.5532989502,
      "lon": 38.1500015259,
      "tz": "Europe\/Moscow"
  },
  "UUDD": {
      "icao": "UUDD",
      "iata": "DME",
      "name": "Domodedovo International Airport",
      "city": "Moscow",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 588,
      "lat": 55.4087982178,
      "lon": 37.9062995911,
      "tz": "Europe\/Moscow"
  },
  "UUDL": {
      "icao": "UUDL",
      "iata": "IAR",
      "name": "Tunoshna Airport",
      "city": "",
      "state": "Jaroslavl",
      "country": "RU",
      "elevation": 287,
      "lat": 57.5606994629,
      "lon": 40.1573982239,
      "tz": "Europe\/Moscow"
  },
  "UUEE": {
      "icao": "UUEE",
      "iata": "SVO",
      "name": "Sheremetyevo International Airport",
      "city": "Moscow",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 622,
      "lat": 55.9725990295,
      "lon": 37.4146003723,
      "tz": "Europe\/Moscow"
  },
  "UUEI": {
      "icao": "UUEI",
      "iata": "",
      "name": "Kimry Airport",
      "city": "Kimry",
      "state": "Tverskaya",
      "country": "RU",
      "elevation": 449,
      "lat": 56.7983016968,
      "lon": 37.3300018311,
      "tz": "Europe\/Moscow"
  },
  "UUEM": {
      "icao": "UUEM",
      "iata": "KLD",
      "name": "Migalovo Air Base",
      "city": "Tver",
      "state": "Tverskaya",
      "country": "RU",
      "elevation": 469,
      "lat": 56.8246994019,
      "lon": 35.7577018738,
      "tz": "Europe\/Moscow"
  },
  "UUMI": {
      "icao": "UUMI",
      "iata": "",
      "name": "Stupino Airfield",
      "city": "Stupino",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 584,
      "lat": 54.888,
      "lon": 38.147,
      "tz": "Europe\/Moscow"
  },
  "UUML": {
      "icao": "UUML",
      "iata": "",
      "name": "Severka Airfield",
      "city": "Voskresensk",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 433,
      "lat": 55.206,
      "lon": 38.677,
      "tz": "Europe\/Moscow"
  },
  "UUMO": {
      "icao": "UUMO",
      "iata": "OSF",
      "name": "Ostafyevo International Airport",
      "city": "Moscow",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 568,
      "lat": 55.5116996765,
      "lon": 37.5071983337,
      "tz": "Europe\/Moscow"
  },
  "UUMT": {
      "icao": "UUMT",
      "iata": "",
      "name": "Tretyakovo Airport",
      "city": "Lukhovitsy",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 515,
      "lat": 54.9049987793,
      "lon": 39.0266685486,
      "tz": "Europe\/Moscow"
  },
  "UUMU": {
      "icao": "UUMU",
      "iata": "CKL",
      "name": "Chkalovskiy Airport",
      "city": "Moscow",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 499,
      "lat": 55.8782997131,
      "lon": 38.0616989136,
      "tz": "Europe\/Moscow"
  },
  "UUMX": {
      "icao": "UUMX",
      "iata": "",
      "name": "Blagoveshchenye Airfield",
      "city": "Sergiev Posad",
      "state": "Moscow-Oblast",
      "country": "RU",
      "elevation": 0,
      "lat": 56.3260002136,
      "lon": 38.0999984741,
      "tz": "Europe\/Moscow"
  },
  "UUOB": {
      "icao": "UUOB",
      "iata": "EGO",
      "name": "Belgorod International Airport",
      "city": "Belgorod",
      "state": "Belgorod",
      "country": "RU",
      "elevation": 735,
      "lat": 50.6437988281,
      "lon": 36.5900993347,
      "tz": "Europe\/Moscow"
  },
  "UUOD": {
      "icao": "UUOD",
      "iata": "",
      "name": "Pridacha Airport",
      "city": "Voronezh",
      "state": "Voronezj",
      "country": "RU",
      "elevation": 344,
      "lat": 51.6522712708,
      "lon": 39.2555236816,
      "tz": "Europe\/Moscow"
  },
  "UUOK": {
      "icao": "UUOK",
      "iata": "URS",
      "name": "Kursk East Airport",
      "city": "Kursk",
      "state": "Kursk",
      "country": "RU",
      "elevation": 686,
      "lat": 51.7505989075,
      "lon": 36.2956008911,
      "tz": "Europe\/Moscow"
  },
  "UUOL": {
      "icao": "UUOL",
      "iata": "LPK",
      "name": "Lipetsk Airport",
      "city": "Lipetsk",
      "state": "Lipetsk",
      "country": "RU",
      "elevation": 584,
      "lat": 52.7028007507,
      "lon": 39.5377998352,
      "tz": "Europe\/Moscow"
  },
  "UUOO": {
      "icao": "UUOO",
      "iata": "VOZ",
      "name": "Voronezh International Airport",
      "city": "Voronezh",
      "state": "Voronezj",
      "country": "RU",
      "elevation": 514,
      "lat": 51.814201355,
      "lon": 39.229598999,
      "tz": "Europe\/Moscow"
  },
  "UUOR": {
      "icao": "UUOR",
      "iata": "OEL",
      "name": "Oryol Yuzhny Airport",
      "city": "Orel",
      "state": "Orjol",
      "country": "RU",
      "elevation": 656,
      "lat": 52.9347000122,
      "lon": 36.0022010803,
      "tz": "Europe\/Moscow"
  },
  "UUOS": {
      "icao": "UUOS",
      "iata": "",
      "name": "Stary Oskol Airport",
      "city": "Stary Oskol",
      "state": "Belgorod",
      "country": "RU",
      "elevation": 791,
      "lat": 51.3292160034,
      "lon": 37.7688484192,
      "tz": "Europe\/Moscow"
  },
  "UUOT": {
      "icao": "UUOT",
      "iata": "TBW",
      "name": "Donskoye Airport",
      "city": "Tambov",
      "state": "Tambov",
      "country": "RU",
      "elevation": 413,
      "lat": 52.806098938,
      "lon": 41.48279953,
      "tz": "Europe\/Moscow"
  },
  "UUUS": {
      "icao": "UUUS",
      "iata": "",
      "name": "Tushino Airport",
      "city": "Moscow",
      "state": "Moscow",
      "country": "RU",
      "elevation": 420,
      "lat": 55.8183326721,
      "lon": 37.4266662598,
      "tz": "Europe\/Moscow"
  },
  "UUWE": {
      "icao": "UUWE",
      "iata": "",
      "name": "Yermolino Airport",
      "city": "Balabanovo",
      "state": "Kaluga",
      "country": "RU",
      "elevation": 640,
      "lat": 55.2283325195,
      "lon": 36.6083335876,
      "tz": "Europe\/Moscow"
  },
  "UUWR": {
      "icao": "UUWR",
      "iata": "RZN",
      "name": "Turlatovo Airport",
      "city": "Ryazan",
      "state": "Rjazan",
      "country": "RU",
      "elevation": 502,
      "lat": 54.5559005737,
      "lon": 39.8552017212,
      "tz": "Europe\/Moscow"
  },
  "UUWV": {
      "icao": "UUWV",
      "iata": "",
      "name": "Klokovo Airfield",
      "city": "Tula",
      "state": "Tula",
      "country": "RU",
      "elevation": 499,
      "lat": 54.239,
      "lon": 37.6,
      "tz": "Europe\/Moscow"
  },
  "UUWW": {
      "icao": "UUWW",
      "iata": "VKO",
      "name": "Vnukovo International Airport",
      "city": "Moscow",
      "state": "Moscow",
      "country": "RU",
      "elevation": 685,
      "lat": 55.5914993286,
      "lon": 37.2615013123,
      "tz": "Europe\/Moscow"
  },
  "UUYH": {
      "icao": "UUYH",
      "iata": "UCT",
      "name": "Ukhta Airport",
      "city": "Ukhta",
      "state": "Komi",
      "country": "RU",
      "elevation": 482,
      "lat": 63.5668983459,
      "lon": 53.8046989441,
      "tz": "Europe\/Moscow"
  },
  "UUYI": {
      "icao": "UUYI",
      "iata": "INA",
      "name": "Inta Airport",
      "city": "Inta",
      "state": "Komi",
      "country": "RU",
      "elevation": 184,
      "lat": 66.0548324585,
      "lon": 60.1103210449,
      "tz": "Europe\/Moscow"
  },
  "UUYK": {
      "icao": "UUYK",
      "iata": "",
      "name": "Vuktyl Airport",
      "city": "Vuktyl",
      "state": "Komi",
      "country": "RU",
      "elevation": 358,
      "lat": 63.823299408,
      "lon": 57.2799987793,
      "tz": "Europe\/Moscow"
  },
  "UUYM": {
      "icao": "UUYM",
      "iata": "",
      "name": "Yemva Airport",
      "city": "Emva",
      "state": "Komi",
      "country": "RU",
      "elevation": 0,
      "lat": 62.6054000854,
      "lon": 50.925201416,
      "tz": "Europe\/Moscow"
  },
  "UUYP": {
      "icao": "UUYP",
      "iata": "PEX",
      "name": "Pechora Airport",
      "city": "Pechora",
      "state": "Komi",
      "country": "RU",
      "elevation": 98,
      "lat": 65.1211013794,
      "lon": 57.1307983398,
      "tz": "Europe\/Moscow"
  },
  "UUYS": {
      "icao": "UUYS",
      "iata": "USK",
      "name": "Usinsk Airport",
      "city": "Usinsk",
      "state": "Komi",
      "country": "RU",
      "elevation": 262,
      "lat": 66.004699707,
      "lon": 57.3671989441,
      "tz": "Europe\/Moscow"
  },
  "UUYT": {
      "icao": "UUYT",
      "iata": "",
      "name": "Ust-Kulom Airport",
      "city": "Ust-Kulom",
      "state": "Komi",
      "country": "RU",
      "elevation": 0,
      "lat": 61.6899986267,
      "lon": 53.7099990845,
      "tz": "Europe\/Moscow"
  },
  "UUYV": {
      "icao": "UUYV",
      "iata": "",
      "name": "Izhma Airport",
      "city": "Izhma",
      "state": "Komi",
      "country": "RU",
      "elevation": 230,
      "lat": 65.0317001343,
      "lon": 53.9700012207,
      "tz": "Europe\/Moscow"
  },
  "UUYW": {
      "icao": "UUYW",
      "iata": "VKT",
      "name": "Vorkuta Airport",
      "city": "Vorkuta",
      "state": "Komi",
      "country": "RU",
      "elevation": 604,
      "lat": 67.4886016846,
      "lon": 63.9930992126,
      "tz": "Europe\/Moscow"
  },
  "UUYX": {
      "icao": "UUYX",
      "iata": "UTS",
      "name": "Ust-Tsylma Airport",
      "city": "Ust-Tsylma",
      "state": "Komi",
      "country": "RU",
      "elevation": 262,
      "lat": 65.4372940063,
      "lon": 52.2003364563,
      "tz": "Europe\/Moscow"
  },
  "UUYY": {
      "icao": "UUYY",
      "iata": "SCW",
      "name": "Syktyvkar Airport",
      "city": "Syktyvkar",
      "state": "Komi",
      "country": "RU",
      "elevation": 342,
      "lat": 61.6469993591,
      "lon": 50.8451004028,
      "tz": "Europe\/Moscow"
  },
  "UWGB": {
      "icao": "UWGB",
      "iata": "",
      "name": "Bolshoye Boldino Airport",
      "city": "Bolshoye Boldino",
      "state": "Nizjnij-Novgorod",
      "country": "RU",
      "elevation": 0,
      "lat": 55.0200004578,
      "lon": 45.3149986267,
      "tz": "Europe\/Moscow"
  },
  "UWGG": {
      "icao": "UWGG",
      "iata": "GOJ",
      "name": "Nizhny Novgorod International Airport",
      "city": "Nizhny Novgorod",
      "state": "Novgorod",
      "country": "RU",
      "elevation": 256,
      "lat": 56.2300987244,
      "lon": 43.7840003967,
      "tz": "Europe\/Moscow"
  },
  "UWKB": {
      "icao": "UWKB",
      "iata": "UUA",
      "name": "Bugulma Airport",
      "city": "Bugulma",
      "state": "Tatarstan",
      "country": "RU",
      "elevation": 991,
      "lat": 54.6399993896,
      "lon": 52.801700592,
      "tz": "Europe\/Moscow"
  },
  "UWKD": {
      "icao": "UWKD",
      "iata": "KZN",
      "name": "Kazan International Airport",
      "city": "Kazan",
      "state": "Tatarstan",
      "country": "RU",
      "elevation": 411,
      "lat": 55.6062011719,
      "lon": 49.2787017822,
      "tz": "Europe\/Moscow"
  },
  "UWKE": {
      "icao": "UWKE",
      "iata": "NBC",
      "name": "Begishevo Airport",
      "city": "Nizhnekamsk",
      "state": "Tatarstan",
      "country": "RU",
      "elevation": 643,
      "lat": 55.5647010803,
      "lon": 52.0924987793,
      "tz": "Europe\/Moscow"
  },
  "UWKG": {
      "icao": "UWKG",
      "iata": "",
      "name": "Borisoglebskoye Airport",
      "city": "Kazan",
      "state": "Tatarstan",
      "country": "RU",
      "elevation": 213,
      "lat": 55.8666992188,
      "lon": 49.1333007813,
      "tz": "Europe\/Moscow"
  },
  "UWKI": {
      "icao": "UWKI",
      "iata": "",
      "name": "Chistopol Airport",
      "city": "Chistopol",
      "state": "Tatarstan",
      "country": "RU",
      "elevation": 604,
      "lat": 55.3057975769,
      "lon": 50.6159858704,
      "tz": "Europe\/Moscow"
  },
  "UWKJ": {
      "icao": "UWKJ",
      "iata": "JOK",
      "name": "Yoshkar-Ola Airport",
      "city": "Yoshkar-Ola",
      "state": "Mariy-El",
      "country": "RU",
      "elevation": 348,
      "lat": 56.7005996704,
      "lon": 47.9047012329,
      "tz": "Europe\/Moscow"
  },
  "UWKS": {
      "icao": "UWKS",
      "iata": "CSY",
      "name": "Cheboksary Airport",
      "city": "Cheboksary",
      "state": "Chuvashia",
      "country": "RU",
      "elevation": 558,
      "lat": 56.0903015137,
      "lon": 47.3473014832,
      "tz": "Europe\/Moscow"
  },
  "UWLL": {
      "icao": "UWLL",
      "iata": "ULV",
      "name": "Ulyanovsk Baratayevka Airport",
      "city": "Ulyanovsk",
      "state": "Ulyanovsk",
      "country": "RU",
      "elevation": 463,
      "lat": 54.2682991028,
      "lon": 48.2266998291,
      "tz": "Europe\/Ulyanovsk"
  },
  "UWLS": {
      "icao": "UWLS",
      "iata": "",
      "name": "Soldatskaya Tashla Airfield",
      "city": "",
      "state": "Ulyanovsk",
      "country": "RU",
      "elevation": 673,
      "lat": 54.0152837323,
      "lon": 48.3060264587,
      "tz": "Europe\/Ulyanovsk"
  },
  "UWLW": {
      "icao": "UWLW",
      "iata": "ULY",
      "name": "Ulyanovsk East Airport",
      "city": "Ulyanovsk",
      "state": "Ulyanovsk",
      "country": "RU",
      "elevation": 252,
      "lat": 54.4010009766,
      "lon": 48.8027000427,
      "tz": "Europe\/Ulyanovsk"
  },
  "UWOF": {
      "icao": "UWOF",
      "iata": "",
      "name": "Dombarovskiy Air Base",
      "city": "Dombarovskiy",
      "state": "Orenburg",
      "country": "RU",
      "elevation": 869,
      "lat": 50.7999992371,
      "lon": 59.5167007446,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWOO": {
      "icao": "UWOO",
      "iata": "REN",
      "name": "Orenburg Central Airport",
      "city": "Orenburg",
      "state": "Orenburg",
      "country": "RU",
      "elevation": 387,
      "lat": 51.7957992554,
      "lon": 55.4566993713,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWOR": {
      "icao": "UWOR",
      "iata": "OSW",
      "name": "Orsk Airport",
      "city": "Orsk",
      "state": "Orenburg",
      "country": "RU",
      "elevation": 909,
      "lat": 51.0724983215,
      "lon": 58.5956001282,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWOX": {
      "icao": "UWOX",
      "iata": "",
      "name": "Buzuluk Airport",
      "city": "Buzuluk",
      "state": "Orenburg",
      "country": "RU",
      "elevation": 0,
      "lat": 52.8129997253,
      "lon": 52.2220001221,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWPP": {
      "icao": "UWPP",
      "iata": "PEZ",
      "name": "Penza Airport",
      "city": "Penza",
      "state": "Penza",
      "country": "RU",
      "elevation": 614,
      "lat": 53.1105995178,
      "lon": 45.0210990906,
      "tz": "Europe\/Moscow"
  },
  "UWPS": {
      "icao": "UWPS",
      "iata": "SKX",
      "name": "Saransk Airport",
      "city": "Saransk",
      "state": "Mordoviya",
      "country": "RU",
      "elevation": 676,
      "lat": 54.1251296997,
      "lon": 45.2122573853,
      "tz": "Europe\/Moscow"
  },
  "UWSB": {
      "icao": "UWSB",
      "iata": "BWO",
      "name": "Balakovo Airport",
      "city": "Balakovo",
      "state": "Saratov",
      "country": "RU",
      "elevation": 95,
      "lat": 51.8582992554,
      "lon": 47.7456016541,
      "tz": "Europe\/Saratov"
  },
  "UWSG": {
      "icao": "UWSG",
      "iata": "GSV",
      "name": "Saratov Gagarin Airport",
      "city": "Saratov",
      "state": "Saratov",
      "country": "RU",
      "elevation": 103,
      "lat": 51.712778,
      "lon": 46.171111,
      "tz": "Europe\/Saratov"
  },
  "UWSK": {
      "icao": "UWSK",
      "iata": "",
      "name": "Krasny Kut Airport",
      "city": "Krasny Kut",
      "state": "Saratov",
      "country": "RU",
      "elevation": 177,
      "lat": 50.9580001831,
      "lon": 46.9469985962,
      "tz": "Europe\/Saratov"
  },
  "UWSS": {
      "icao": "UWSS",
      "iata": "RTW",
      "name": "Saratov Central Airport",
      "city": "Saratov",
      "state": "Saratov",
      "country": "RU",
      "elevation": 499,
      "lat": 51.5649986267,
      "lon": 46.0466995239,
      "tz": "Europe\/Saratov"
  },
  "UWUA": {
      "icao": "UWUA",
      "iata": "",
      "name": "Sibay Airport",
      "city": "Sibay",
      "state": "Bashkortostan",
      "country": "RU",
      "elevation": 1309,
      "lat": 52.687412262,
      "lon": 58.7148017883,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWUB": {
      "icao": "UWUB",
      "iata": "BCX",
      "name": "Beloretsk Airport",
      "city": "Beloretsk",
      "state": "Bashkortostan",
      "country": "RU",
      "elevation": 1827,
      "lat": 53.9380989075,
      "lon": 58.3400001526,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWUF": {
      "icao": "UWUF",
      "iata": "NEF",
      "name": "Neftekamsk Airport",
      "city": "Neftekamsk",
      "state": "Bashkortostan",
      "country": "RU",
      "elevation": 456,
      "lat": 56.1068992615,
      "lon": 54.3471984863,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWUK": {
      "icao": "UWUK",
      "iata": "OKT",
      "name": "Oktyabrskiy Airport",
      "city": "Kzyl-Yar",
      "state": "Bashkortostan",
      "country": "RU",
      "elevation": 377,
      "lat": 54.4399986267,
      "lon": 53.3883018494,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWUM": {
      "icao": "UWUM",
      "iata": "",
      "name": "Maksimovka Airport",
      "city": "Ufa",
      "state": "Bashkortostan",
      "country": "RU",
      "elevation": 325,
      "lat": 54.8300018311,
      "lon": 56.1683006287,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWUU": {
      "icao": "UWUU",
      "iata": "UFA",
      "name": "Ufa International Airport",
      "city": "Ufa",
      "state": "Bashkortostan",
      "country": "RU",
      "elevation": 449,
      "lat": 54.5574989319,
      "lon": 55.8744010925,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWWB": {
      "icao": "UWWB",
      "iata": "",
      "name": "Buguruslan Severny Airport",
      "city": "Buguruslan",
      "state": "Orenburg",
      "country": "RU",
      "elevation": 728,
      "lat": 53.7183990479,
      "lon": 52.3718986511,
      "tz": "Asia\/Yekaterinburg"
  },
  "UWWE": {
      "icao": "UWWE",
      "iata": "",
      "name": "Verkhneye Sancheleyevo Airfield",
      "city": "Tolyatti",
      "state": "Samara",
      "country": "RU",
      "elevation": 246,
      "lat": 53.6960966684,
      "lon": 49.491519928,
      "tz": "Europe\/Samara"
  },
  "UWWG": {
      "icao": "UWWG",
      "iata": "",
      "name": "Bezymyanka Airfield",
      "city": "Samara",
      "state": "Samara",
      "country": "RU",
      "elevation": 135,
      "lat": 53.2200012207,
      "lon": 50.3250007629,
      "tz": "Europe\/Samara"
  },
  "UWWS": {
      "icao": "UWWS",
      "iata": "",
      "name": "Smyshlyayevka Airport",
      "city": "Samara",
      "state": "Samara",
      "country": "RU",
      "elevation": 131,
      "lat": 53.2400016785,
      "lon": 50.375,
      "tz": "Europe\/Samara"
  },
  "UWWT": {
      "icao": "UWWT",
      "iata": "",
      "name": "Tolyatti Airport",
      "city": "Tolyatti",
      "state": "Samara",
      "country": "RU",
      "elevation": 230,
      "lat": 53.5962743924,
      "lon": 49.3781161308,
      "tz": "Europe\/Samara"
  },
  "UWWW": {
      "icao": "UWWW",
      "iata": "KUF",
      "name": "Kurumoch International Airport",
      "city": "Samara",
      "state": "Samara",
      "country": "RU",
      "elevation": 477,
      "lat": 53.504901886,
      "lon": 50.1642990112,
      "tz": "Europe\/Samara"
  },
  "UWWZ": {
      "icao": "UWWZ",
      "iata": "",
      "name": "Buguruslan Glavny Airfield",
      "city": "Buguruslan",
      "state": "Orenburg",
      "country": "RU",
      "elevation": 0,
      "lat": 53.6180000305,
      "lon": 52.4440002441,
      "tz": "Asia\/Yekaterinburg"
  },
  "VA00": {
      "icao": "VA00",
      "iata": "",
      "name": "Brook Hill Farm Airport",
      "city": "Steeles Tavern",
      "state": "Virginia",
      "country": "US",
      "elevation": 1600,
      "lat": 37.9281997681,
      "lon": -79.1781005859,
      "tz": "America\/New_York"
  },
  "VA02": {
      "icao": "VA02",
      "iata": "",
      "name": "Pace Airport",
      "city": "Ridgeway",
      "state": "Virginia",
      "country": "US",
      "elevation": 800,
      "lat": 36.5853996277,
      "lon": -79.8938980103,
      "tz": "America\/New_York"
  },
  "VA04": {
      "icao": "VA04",
      "iata": "",
      "name": "Barrows Airport",
      "city": "Fincastle",
      "state": "Virginia",
      "country": "US",
      "elevation": 1100,
      "lat": 37.492099762,
      "lon": -79.9287033081,
      "tz": "America\/New_York"
  },
  "VA06": {
      "icao": "VA06",
      "iata": "",
      "name": "Deer Run Airport",
      "city": "Floyd",
      "state": "Virginia",
      "country": "US",
      "elevation": 2400,
      "lat": 36.9943008423,
      "lon": -80.454498291,
      "tz": "America\/New_York"
  },
  "VA07": {
      "icao": "VA07",
      "iata": "",
      "name": "Burkes Garden Airport",
      "city": "Tazewell",
      "state": "Virginia",
      "country": "US",
      "elevation": 3060,
      "lat": 37.0956993103,
      "lon": -81.3693008423,
      "tz": "America\/New_York"
  },
  "VA08": {
      "icao": "VA08",
      "iata": "",
      "name": "Longbranch Airport",
      "city": "Kilmarnock",
      "state": "Virginia",
      "country": "US",
      "elevation": 20,
      "lat": 37.7170982361,
      "lon": -76.3588027954,
      "tz": "America\/New_York"
  },
  "VA10": {
      "icao": "VA10",
      "iata": "",
      "name": "Catawba Valley Airport",
      "city": "Blacksburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 1720,
      "lat": 37.2801017761,
      "lon": -80.301399231,
      "tz": "America\/New_York"
  },
  "VA11": {
      "icao": "VA11",
      "iata": "",
      "name": "White Oak Stand Airport",
      "city": "Chilhowie",
      "state": "Virginia",
      "country": "US",
      "elevation": 2100,
      "lat": 36.8078994751,
      "lon": -81.7012023926,
      "tz": "America\/New_York"
  },
  "VA12": {
      "icao": "VA12",
      "iata": "",
      "name": "Gravely Airport",
      "city": "Martinsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 750,
      "lat": 36.6468009949,
      "lon": -79.7969970703,
      "tz": "America\/New_York"
  },
  "VA13": {
      "icao": "VA13",
      "iata": "",
      "name": "Breezy Knoll Airport",
      "city": "Rustburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 785,
      "lat": 37.2653999329,
      "lon": -79.0428009033,
      "tz": "America\/New_York"
  },
  "VA14": {
      "icao": "VA14",
      "iata": "",
      "name": "Southampton Correctional Center Airport",
      "city": "Capron",
      "state": "Virginia",
      "country": "US",
      "elevation": 50,
      "lat": 36.7406997681,
      "lon": -77.2627029419,
      "tz": "America\/New_York"
  },
  "VA15": {
      "icao": "VA15",
      "iata": "",
      "name": "Stokes Airport",
      "city": "Cartersville",
      "state": "Virginia",
      "country": "US",
      "elevation": 295,
      "lat": 37.6562995911,
      "lon": -78.0449981689,
      "tz": "America\/New_York"
  },
  "VA16": {
      "icao": "VA16",
      "iata": "",
      "name": "Centreville Airport",
      "city": "Centreville",
      "state": "Virginia",
      "country": "US",
      "elevation": 300,
      "lat": 38.8792991638,
      "lon": -77.4843978882,
      "tz": "America\/New_York"
  },
  "VA17": {
      "icao": "VA17",
      "iata": "",
      "name": "Mulberry Run Airport",
      "city": "Strasburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 820,
      "lat": 39.035900116,
      "lon": -78.3921966553,
      "tz": "America\/New_York"
  },
  "VA18": {
      "icao": "VA18",
      "iata": "",
      "name": "Bundoran Airport",
      "city": "Charlottesville",
      "state": "Virginia",
      "country": "US",
      "elevation": 840,
      "lat": 37.96849823,
      "lon": -78.6821975708,
      "tz": "America\/New_York"
  },
  "VA19": {
      "icao": "VA19",
      "iata": "",
      "name": "Snow Hill Airport",
      "city": "Charlottesville",
      "state": "Virginia",
      "country": "US",
      "elevation": 268,
      "lat": 37.9539985657,
      "lon": -78.3692016602,
      "tz": "America\/New_York"
  },
  "VA1B": {
      "icao": "VA1B",
      "iata": "",
      "name": "Chanda Airport",
      "city": "",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 625,
      "lat": 19.9946994781,
      "lon": 79.2225036621,
      "tz": "Asia\/Kolkata"
  },
  "VA1C": {
      "icao": "VA1C",
      "iata": "",
      "name": "Birlagram Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1541,
      "lat": 23.4468994141,
      "lon": 75.4203033447,
      "tz": "Asia\/Kolkata"
  },
  "VA1D": {
      "icao": "VA1D",
      "iata": "",
      "name": "Muirpur Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 1122,
      "lat": 24.1252002716,
      "lon": 83.0406036377,
      "tz": "Asia\/Kolkata"
  },
  "VA1E": {
      "icao": "VA1E",
      "iata": "",
      "name": "Bhilai Airport",
      "city": "",
      "state": "Chhattisgarh",
      "country": "IN",
      "elevation": 1014,
      "lat": 21.2942008972,
      "lon": 81.3795013428,
      "tz": "Asia\/Kolkata"
  },
  "VA1F": {
      "icao": "VA1F",
      "iata": "",
      "name": "Sidhi Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1093,
      "lat": 24.402299881,
      "lon": 81.8147964478,
      "tz": "Asia\/Kolkata"
  },
  "VA1G": {
      "icao": "VA1G",
      "iata": "",
      "name": "Chorhata Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1000,
      "lat": 24.5034008026,
      "lon": 81.2202987671,
      "tz": "Asia\/Kolkata"
  },
  "VA1H": {
      "icao": "VA1H",
      "iata": "",
      "name": "Ondwa Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1450,
      "lat": 25.141500473,
      "lon": 74.6115036011,
      "tz": "Asia\/Kolkata"
  },
  "VA1J": {
      "icao": "VA1J",
      "iata": "",
      "name": "Dhana Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1706,
      "lat": 23.7535991669,
      "lon": 78.855796814,
      "tz": "Asia\/Kolkata"
  },
  "VA1K": {
      "icao": "VA1K",
      "iata": "",
      "name": "Raedhanpur Airport",
      "city": "",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 135,
      "lat": 23.2222995758,
      "lon": 68.8912963867,
      "tz": "Asia\/Kolkata"
  },
  "VA1L": {
      "icao": "VA1L",
      "iata": "",
      "name": "Amla Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 2435,
      "lat": 21.926399231,
      "lon": 78.1136016846,
      "tz": "Asia\/Kolkata"
  },
  "VA1M": {
      "icao": "VA1M",
      "iata": "",
      "name": "Karad Airport",
      "city": "",
      "state": "",
      "country": "IN",
      "elevation": 1890,
      "lat": 17.285900116,
      "lon": 74.1580963135,
      "tz": "Asia\/Kolkata"
  },
  "VA1N": {
      "icao": "VA1N",
      "iata": "",
      "name": "Nimach Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1617,
      "lat": 24.4307994843,
      "lon": 74.8677978516,
      "tz": "Asia\/Kolkata"
  },
  "VA1O": {
      "icao": "VA1O",
      "iata": "",
      "name": "Burhar Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1500,
      "lat": 23.235200882,
      "lon": 81.5037002563,
      "tz": "Asia\/Kolkata"
  },
  "VA1P": {
      "icao": "VA1P",
      "iata": "DIU",
      "name": "Diu Airport",
      "city": "Diu",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 31,
      "lat": 20.7131004333,
      "lon": 70.9210968018,
      "tz": "Asia\/Kolkata"
  },
  "VA22": {
      "icao": "VA22",
      "iata": "",
      "name": "Big River Ranch Airport",
      "city": "Clarksville",
      "state": "Virginia",
      "country": "US",
      "elevation": 375,
      "lat": 36.6750984192,
      "lon": -78.6949996948,
      "tz": "America\/New_York"
  },
  "VA23": {
      "icao": "VA23",
      "iata": "",
      "name": "Sanford Field",
      "city": "Coles Point",
      "state": "Virginia",
      "country": "US",
      "elevation": 15,
      "lat": 38.1189994812,
      "lon": -76.6136016846,
      "tz": "America\/New_York"
  },
  "VA24": {
      "icao": "VA24",
      "iata": "",
      "name": "Skovhus Airport",
      "city": "Concord",
      "state": "Virginia",
      "country": "US",
      "elevation": 712,
      "lat": 37.4142990112,
      "lon": -78.9642028809,
      "tz": "America\/New_York"
  },
  "VA25": {
      "icao": "VA25",
      "iata": "",
      "name": "Twin Towers Airport",
      "city": "Chase City",
      "state": "Virginia",
      "country": "US",
      "elevation": 470,
      "lat": 36.7643013,
      "lon": -78.360496521,
      "tz": "America\/New_York"
  },
  "VA26": {
      "icao": "VA26",
      "iata": "",
      "name": "Trent Farm Airstrip",
      "city": "Critz",
      "state": "Virginia",
      "country": "US",
      "elevation": 1130,
      "lat": 36.650100708,
      "lon": -80.1164016724,
      "tz": "America\/New_York"
  },
  "VA27": {
      "icao": "VA27",
      "iata": "",
      "name": "Moorefield's Airstrip",
      "city": "Critz",
      "state": "Virginia",
      "country": "US",
      "elevation": 1110,
      "lat": 36.5598983765,
      "lon": -80.137802124,
      "tz": "America\/New_York"
  },
  "VA28": {
      "icao": "VA28",
      "iata": "",
      "name": "Layne Farm Airstrip",
      "city": "Cullen",
      "state": "Virginia",
      "country": "US",
      "elevation": 560,
      "lat": 37.1212997437,
      "lon": -78.6436004639,
      "tz": "America\/New_York"
  },
  "VA2A": {
      "icao": "VA2A",
      "iata": "",
      "name": "Phalodi Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 781,
      "lat": 27.1128997803,
      "lon": 72.388999939,
      "tz": "Asia\/Kolkata"
  },
  "VA2B": {
      "icao": "VA2B",
      "iata": "",
      "name": "Meerut Sw Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 732,
      "lat": 28.9048995972,
      "lon": 77.6772994995,
      "tz": "Asia\/Kolkata"
  },
  "VA2C": {
      "icao": "VA2C",
      "iata": "",
      "name": "Gondia Airport",
      "city": "",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 990,
      "lat": 21.5261993408,
      "lon": 80.290397644,
      "tz": "Asia\/Kolkata"
  },
  "VA2D": {
      "icao": "VA2D",
      "iata": "",
      "name": "Ratlam Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1702,
      "lat": 23.3813991547,
      "lon": 75.0255966187,
      "tz": "Asia\/Kolkata"
  },
  "VA30": {
      "icao": "VA30",
      "iata": "",
      "name": "Berryvale Airport",
      "city": "Culpeper",
      "state": "Virginia",
      "country": "US",
      "elevation": 470,
      "lat": 38.5267982483,
      "lon": -77.9563980103,
      "tz": "America\/New_York"
  },
  "VA31": {
      "icao": "VA31",
      "iata": "",
      "name": "Apple Grove Airport",
      "city": "Kilmarnock",
      "state": "Virginia",
      "country": "US",
      "elevation": 15,
      "lat": 37.7625999451,
      "lon": -76.3462982178,
      "tz": "America\/New_York"
  },
  "VA32": {
      "icao": "VA32",
      "iata": "",
      "name": "Longs Airport",
      "city": "Edinburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 870,
      "lat": 38.8042984009,
      "lon": -78.5719985962,
      "tz": "America\/New_York"
  },
  "VA33": {
      "icao": "VA33",
      "iata": "",
      "name": "Beaver Dam Airpark",
      "city": "Surry",
      "state": "Virginia",
      "country": "US",
      "elevation": 100,
      "lat": 37.0443000793,
      "lon": -76.7682037354,
      "tz": "America\/New_York"
  },
  "VA34": {
      "icao": "VA34",
      "iata": "",
      "name": "Big Buffalo Airstrip",
      "city": "Farmville",
      "state": "Virginia",
      "country": "US",
      "elevation": 510,
      "lat": 37.2835006714,
      "lon": -78.4496994019,
      "tz": "America\/New_York"
  },
  "VA36": {
      "icao": "VA36",
      "iata": "",
      "name": "F. U. M. A. Airport",
      "city": "Fork Union",
      "state": "Virginia",
      "country": "US",
      "elevation": 400,
      "lat": 37.7445983887,
      "lon": -78.2703018188,
      "tz": "America\/New_York"
  },
  "VA38": {
      "icao": "VA38",
      "iata": "",
      "name": "Sirohi Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1000,
      "lat": 24.8950996399,
      "lon": 72.8459014893,
      "tz": "Asia\/Kolkata"
  },
  "VA41": {
      "icao": "VA41",
      "iata": "",
      "name": "High Hopes Airport",
      "city": "Withams",
      "state": "Virginia",
      "country": "US",
      "elevation": 9,
      "lat": 37.9346008301,
      "lon": -75.6099014282,
      "tz": "America\/New_York"
  },
  "VA42": {
      "icao": "VA42",
      "iata": "",
      "name": "Dogwood Airpark",
      "city": "Fredericksburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 180,
      "lat": 38.3585014343,
      "lon": -77.4522018433,
      "tz": "America\/New_York"
  },
  "VA43": {
      "icao": "VA43",
      "iata": "",
      "name": "Balcony Downs Airstrip",
      "city": "Glasgow",
      "state": "Virginia",
      "country": "US",
      "elevation": 820,
      "lat": 37.6473999023,
      "lon": -79.4247970581,
      "tz": "America\/New_York"
  },
  "VA44": {
      "icao": "VA44",
      "iata": "",
      "name": "Rose Retreat Farm Airport",
      "city": "Goochland",
      "state": "Virginia",
      "country": "US",
      "elevation": 250,
      "lat": 37.7168006897,
      "lon": -77.8839035034,
      "tz": "America\/New_York"
  },
  "VA45": {
      "icao": "VA45",
      "iata": "",
      "name": "Lous Airport",
      "city": "Gordonsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 480,
      "lat": 38.1278991699,
      "lon": -78.1635971069,
      "tz": "America\/New_York"
  },
  "VA46": {
      "icao": "VA46",
      "iata": "",
      "name": "Timber Ridge Airpark",
      "city": "Gore",
      "state": "Virginia",
      "country": "US",
      "elevation": 1024,
      "lat": 39.2961997986,
      "lon": -78.362197876,
      "tz": "America\/New_York"
  },
  "VA47": {
      "icao": "VA47",
      "iata": "",
      "name": "Jalgaon Airport",
      "city": "",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 800,
      "lat": 20.9622001648,
      "lon": 75.626701355,
      "tz": "Asia\/Kolkata"
  },
  "VA49": {
      "icao": "VA49",
      "iata": "",
      "name": "Robertson Airport",
      "city": "Hanover",
      "state": "Virginia",
      "country": "US",
      "elevation": 193,
      "lat": 37.7053985596,
      "lon": -77.3724975586,
      "tz": "America\/New_York"
  },
  "VA50": {
      "icao": "VA50",
      "iata": "",
      "name": "Woods Farm Airstrip",
      "city": "Hanover",
      "state": "Virginia",
      "country": "US",
      "elevation": 57,
      "lat": 37.7150993347,
      "lon": -77.3024978638,
      "tz": "America\/New_York"
  },
  "VA51": {
      "icao": "VA51",
      "iata": "",
      "name": "Banswara Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 700,
      "lat": 23.5897006989,
      "lon": 74.3126983643,
      "tz": "Asia\/Kolkata"
  },
  "VA52": {
      "icao": "VA52",
      "iata": "",
      "name": "Frank Field",
      "city": "Harrisonburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 1650,
      "lat": 38.4850997925,
      "lon": -78.9452972412,
      "tz": "America\/New_York"
  },
  "VA53": {
      "icao": "VA53",
      "iata": "",
      "name": "Dhulia Airport",
      "city": "",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 920,
      "lat": 20.9265003204,
      "lon": 74.7370986938,
      "tz": "Asia\/Kolkata"
  },
  "VA56": {
      "icao": "VA56",
      "iata": "",
      "name": "Wells Airport",
      "city": "Ivor",
      "state": "Virginia",
      "country": "US",
      "elevation": 88,
      "lat": 37.0035018921,
      "lon": -76.8274993896,
      "tz": "America\/New_York"
  },
  "VA57": {
      "icao": "VA57",
      "iata": "",
      "name": "Powhatan Airport",
      "city": "King George",
      "state": "Virginia",
      "country": "US",
      "elevation": 60,
      "lat": 38.248500824,
      "lon": -77.2191009521,
      "tz": "America\/New_York"
  },
  "VA58": {
      "icao": "VA58",
      "iata": "",
      "name": "River Bend Airport",
      "city": "Maurertown",
      "state": "Virginia",
      "country": "US",
      "elevation": 606,
      "lat": 38.9061012268,
      "lon": -78.4443969727,
      "tz": "America\/New_York"
  },
  "VA59": {
      "icao": "VA59",
      "iata": "",
      "name": "Weatherly & Son Airport",
      "city": "Chesapeake",
      "state": "Virginia",
      "country": "US",
      "elevation": 10,
      "lat": 36.6357002258,
      "lon": -76.1516036987,
      "tz": "America\/New_York"
  },
  "VA61": {
      "icao": "VA61",
      "iata": "",
      "name": "Scott Airpark",
      "city": "Lovettsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 529,
      "lat": 39.263999939,
      "lon": -77.6572036743,
      "tz": "America\/New_York"
  },
  "VA62": {
      "icao": "VA62",
      "iata": "",
      "name": "The Grass Patch Airport",
      "city": "Lovettsville",
      "state": "",
      "country": "US",
      "elevation": 530,
      "lat": 39.2593002319,
      "lon": -77.6572036743,
      "tz": "America\/New_York"
  },
  "VA63": {
      "icao": "VA63",
      "iata": "",
      "name": "Twin River Airport",
      "city": "Glasgow",
      "state": "Virginia",
      "country": "US",
      "elevation": 720,
      "lat": 37.679599762,
      "lon": -79.4203033447,
      "tz": "America\/New_York"
  },
  "VA64": {
      "icao": "VA64",
      "iata": "",
      "name": "Hill Top Airport",
      "city": "Amelia",
      "state": "Virginia",
      "country": "US",
      "elevation": 254,
      "lat": 37.4160003662,
      "lon": -77.9539031982,
      "tz": "America\/New_York"
  },
  "VA65": {
      "icao": "VA65",
      "iata": "",
      "name": "Ivy Hill Airport",
      "city": "Lynchburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 920,
      "lat": 37.3945999146,
      "lon": -79.324798584,
      "tz": "America\/New_York"
  },
  "VA66": {
      "icao": "VA66",
      "iata": "",
      "name": "Breeden Airport",
      "city": "Catlett",
      "state": "Virginia",
      "country": "US",
      "elevation": 210,
      "lat": 38.6076011658,
      "lon": -77.5693969727,
      "tz": "America\/New_York"
  },
  "VA67": {
      "icao": "VA67",
      "iata": "",
      "name": "Homeland Airport",
      "city": "Culpeper",
      "state": "Virginia",
      "country": "US",
      "elevation": 404,
      "lat": 38.570098877,
      "lon": -77.9349975586,
      "tz": "America\/New_York"
  },
  "VA68": {
      "icao": "VA68",
      "iata": "",
      "name": "Lakeview Aerodrome",
      "city": "Moneta",
      "state": "Virginia",
      "country": "US",
      "elevation": 1020,
      "lat": 37.1188011169,
      "lon": -79.6027984619,
      "tz": "America\/New_York"
  },
  "VA71": {
      "icao": "VA71",
      "iata": "",
      "name": "Aden Field",
      "city": "Manassas",
      "state": "Virginia",
      "country": "US",
      "elevation": 210,
      "lat": 38.6484985352,
      "lon": -77.5327987671,
      "tz": "America\/New_York"
  },
  "VA72": {
      "icao": "VA72",
      "iata": "",
      "name": "Covington Airport",
      "city": "Martinsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 940,
      "lat": 36.6001014709,
      "lon": -80.0386962891,
      "tz": "America\/New_York"
  },
  "VA73": {
      "icao": "VA73",
      "iata": "",
      "name": "Mazza Airport",
      "city": "Matoaca",
      "state": "Virginia",
      "country": "US",
      "elevation": 190,
      "lat": 37.2445983887,
      "lon": -77.4942016602,
      "tz": "America\/New_York"
  },
  "VA74": {
      "icao": "VA74",
      "iata": "",
      "name": "Abu Road Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 875,
      "lat": 24.4941997528,
      "lon": 72.78150177,
      "tz": "Asia\/Kolkata"
  },
  "VA75": {
      "icao": "VA75",
      "iata": "",
      "name": "Smith Field",
      "city": "Mc Dowell",
      "state": "Virginia",
      "country": "US",
      "elevation": 2020,
      "lat": 38.3026008606,
      "lon": -79.508102417,
      "tz": "America\/New_York"
  },
  "VA76": {
      "icao": "VA76",
      "iata": "",
      "name": "Baskerville Airport",
      "city": "Mc Kenney",
      "state": "Virginia",
      "country": "US",
      "elevation": 250,
      "lat": 36.9875984192,
      "lon": -77.771697998,
      "tz": "America\/New_York"
  },
  "VA77": {
      "icao": "VA77",
      "iata": "",
      "name": "Hanover Air Park",
      "city": "Mechanicsville",
      "state": "Virginia",
      "country": "US",
      "elevation": 196,
      "lat": 37.6940002441,
      "lon": -77.3768997192,
      "tz": "America\/New_York"
  },
  "VA79": {
      "icao": "VA79",
      "iata": "",
      "name": "Hickory Tree Farm Airport",
      "city": "Middleburg",
      "state": "Virginia",
      "country": "US",
      "elevation": 544,
      "lat": 38.9528999329,
      "lon": -77.7469024658,
      "tz": "America\/New_York"
  },
  "VA80": {
      "icao": "VA80",
      "iata": "",
      "name": "Woodle Airport",
      "city": "Midlothian",
      "state": "Virginia",
      "country": "US",
      "elevation": 315,
      "lat": 37.4788017273,
      "lon": -77.7018966675,
      "tz": "America\/New_York"
  },
  "VA81": {
      "icao": "VA81",
      "iata": "",
      "name": "Cub Field",
      "city": "Mineral",
      "state": "Virginia",
      "country": "US",
      "elevation": 396,
      "lat": 37.945400238,
      "lon": -77.8993988037,
      "tz": "America\/New_York"
  },
  "VA83": {
      "icao": "VA83",
      "iata": "",
      "name": "Fincastle Airport",
      "city": "Fincastle",
      "state": "Virginia",
      "country": "US",
      "elevation": 1140,
      "lat": 37.5223999023,
      "lon": -79.8519973755,
      "tz": "America\/New_York"
  },
  "VA85": {
      "icao": "VA85",
      "iata": "",
      "name": "New Castle International Airport",
      "city": "New Castle",
      "state": "Virginia",
      "country": "US",
      "elevation": 1320,
      "lat": 37.4835014343,
      "lon": -80.0998001099,
      "tz": "America\/New_York"
  },
  "VA86": {
      "icao": "VA86",
      "iata": "",
      "name": "Buddy Davis Field",
      "city": "New Market",
      "state": "Virginia",
      "country": "US",
      "elevation": 1042,
      "lat": 38.6893005371,
      "lon": -78.7063980103,
      "tz": "America\/New_York"
  },
  "VA87": {
      "icao": "VA87",
      "iata": "",
      "name": "Valley View Airport",
      "city": "Nokesville",
      "state": "Virginia",
      "country": "US",
      "elevation": 240,
      "lat": 38.6917991638,
      "lon": -77.5511016846,
      "tz": "America\/New_York"
  },
  "VA89": {
      "icao": "VA89",
      "iata": "",
      "name": "Chance Airport",
      "city": "Davis Wharf",
      "state": "Virginia",
      "country": "US",
      "elevation": 6,
      "lat": 37.5646018982,
      "lon": -75.9263000488,
      "tz": "America\/New_York"
  },
  "VA90": {
      "icao": "VA90",
      "iata": "",
      "name": "Hudgins Farm Ultralightport",
      "city": "Cobbs Creek",
      "state": "Virginia",
      "country": "US",
      "elevation": 43,
      "lat": 37.496799469,
      "lon": -76.4135971069,
      "tz": "America\/New_York"
  },
  "VA91": {
      "icao": "VA91",
      "iata": "",
      "name": "Johnson Field",
      "city": "Onancock",
      "state": "Virginia",
      "country": "US",
      "elevation": 38,
      "lat": 37.7048988342,
      "lon": -75.726600647,
      "tz": "America\/New_York"
  },
  "VA92": {
      "icao": "VA92",
      "iata": "",
      "name": "Peace and Plenty Farm Airport",
      "city": "Onancock",
      "state": "Virginia",
      "country": "US",
      "elevation": 8,
      "lat": 37.7224006653,
      "lon": -75.7857971191,
      "tz": "America\/New_York"
  },
  "VA93": {
      "icao": "VA93",
      "iata": "",
      "name": "Ayers Airport",
      "city": "Columbia Furnace",
      "state": "Virginia",
      "country": "US",
      "elevation": 1340,
      "lat": 38.9051017761,
      "lon": -78.6547012329,
      "tz": "America\/New_York"
  },
  "VA94": {
      "icao": "VA94",
      "iata": "",
      "name": "Plainview Airport",
      "city": "Powhatan",
      "state": "Virginia",
      "country": "US",
      "elevation": 400,
      "lat": 37.5357017517,
      "lon": -77.8914031982,
      "tz": "America\/New_York"
  },
  "VA95": {
      "icao": "VA95",
      "iata": "",
      "name": "Spring Valley Airport",
      "city": "Powhatan",
      "state": "Virginia",
      "country": "US",
      "elevation": 275,
      "lat": 37.5970993042,
      "lon": -77.8003005981,
      "tz": "America\/New_York"
  },
  "VA96": {
      "icao": "VA96",
      "iata": "",
      "name": "Faber Airport",
      "city": "Lovingston",
      "state": "Virginia",
      "country": "US",
      "elevation": 640,
      "lat": 37.8254013062,
      "lon": -78.7316970825,
      "tz": "America\/New_York"
  },
  "VA97": {
      "icao": "VA97",
      "iata": "",
      "name": "Harris Airport",
      "city": "Rectortown",
      "state": "Virginia",
      "country": "US",
      "elevation": 566,
      "lat": 38.9126014709,
      "lon": -77.8816986084,
      "tz": "America\/New_York"
  },
  "VA98": {
      "icao": "VA98",
      "iata": "",
      "name": "Reedville Airport",
      "city": "Reedville",
      "state": "Virginia",
      "country": "US",
      "elevation": 12,
      "lat": 37.8282012939,
      "lon": -76.2733001709,
      "tz": "America\/New_York"
  },
  "VA99": {
      "icao": "VA99",
      "iata": "",
      "name": "Federhart-Ophelia STOLport",
      "city": "Reedville",
      "state": "Virginia",
      "country": "US",
      "elevation": 13,
      "lat": 37.90599823,
      "lon": -76.2904968262,
      "tz": "America\/New_York"
  },
  "VAAH": {
      "icao": "VAAH",
      "iata": "AMD",
      "name": "Sardar Vallabhbhai Patel International Airport",
      "city": "Ahmedabad",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 189,
      "lat": 23.0771999359,
      "lon": 72.6346969604,
      "tz": "Asia\/Kolkata"
  },
  "VAAK": {
      "icao": "VAAK",
      "iata": "AKD",
      "name": "Akola Airport",
      "city": "",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 999,
      "lat": 20.6989994049,
      "lon": 77.0586013794,
      "tz": "Asia\/Kolkata"
  },
  "VAAU": {
      "icao": "VAAU",
      "iata": "IXU",
      "name": "Aurangabad Airport",
      "city": "Aurangabad",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1911,
      "lat": 19.8626995087,
      "lon": 75.3981018066,
      "tz": "Asia\/Kolkata"
  },
  "VABB": {
      "icao": "VABB",
      "iata": "BOM",
      "name": "Chhatrapati Shivaji International Airport",
      "city": "Mumbai",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 37,
      "lat": 19.0886993408,
      "lon": 72.8678970337,
      "tz": "Asia\/Kolkata"
  },
  "VABI": {
      "icao": "VABI",
      "iata": "PAB",
      "name": "Bilaspur Airport",
      "city": "",
      "state": "Chhattisgarh",
      "country": "IN",
      "elevation": 899,
      "lat": 21.9883995056,
      "lon": 82.111000061,
      "tz": "Asia\/Kolkata"
  },
  "VABJ": {
      "icao": "VABJ",
      "iata": "BHJ",
      "name": "Bhuj Airport",
      "city": "Bhuj",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 268,
      "lat": 23.2877998352,
      "lon": 69.6701965332,
      "tz": "Asia\/Kolkata"
  },
  "VABM": {
      "icao": "VABM",
      "iata": "IXG",
      "name": "Belgaum Airport",
      "city": "",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 2487,
      "lat": 15.8592996597,
      "lon": 74.6183013916,
      "tz": "Asia\/Kolkata"
  },
  "VABO": {
      "icao": "VABO",
      "iata": "BDQ",
      "name": "Vadodara Airport",
      "city": "Vadodara",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 129,
      "lat": 22.3362007141,
      "lon": 73.2263031006,
      "tz": "Asia\/Kolkata"
  },
  "VABP": {
      "icao": "VABP",
      "iata": "BHO",
      "name": "Bhopal Airport",
      "city": "Bhopal",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1719,
      "lat": 23.2875003815,
      "lon": 77.3374023438,
      "tz": "Asia\/Kolkata"
  },
  "VABV": {
      "icao": "VABV",
      "iata": "BHU",
      "name": "Bhavnagar Airport",
      "city": "Bhavnagar",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 44,
      "lat": 21.752199173,
      "lon": 72.1852035522,
      "tz": "Asia\/Kolkata"
  },
  "VADN": {
      "icao": "VADN",
      "iata": "NMB",
      "name": "Daman Airport",
      "city": "",
      "state": "Daman-and-Diu",
      "country": "IN",
      "elevation": 33,
      "lat": 20.4344005585,
      "lon": 72.8432006836,
      "tz": "Asia\/Kolkata"
  },
  "VADS": {
      "icao": "VADS",
      "iata": "",
      "name": "Deesa Airport",
      "city": "",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 485,
      "lat": 24.2679004669,
      "lon": 72.2043991089,
      "tz": "Asia\/Kolkata"
  },
  "VAGN": {
      "icao": "VAGN",
      "iata": "GUX",
      "name": "Guna Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1600,
      "lat": 24.6546993256,
      "lon": 77.3472976685,
      "tz": "Asia\/Kolkata"
  },
  "VAHB": {
      "icao": "VAHB",
      "iata": "HBX",
      "name": "Hubli Airport",
      "city": "Hubli",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 2171,
      "lat": 15.361700058,
      "lon": 75.0848999023,
      "tz": "Asia\/Kolkata"
  },
  "VAID": {
      "icao": "VAID",
      "iata": "IDR",
      "name": "Devi Ahilyabai Holkar Airport",
      "city": "Indore",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1850,
      "lat": 22.7217998505,
      "lon": 75.8011016846,
      "tz": "Asia\/Kolkata"
  },
  "VAJB": {
      "icao": "VAJB",
      "iata": "JLR",
      "name": "Jabalpur Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1624,
      "lat": 23.1777992249,
      "lon": 80.0520019531,
      "tz": "Asia\/Kolkata"
  },
  "VAJJ": {
      "icao": "VAJJ",
      "iata": "",
      "name": "Juhu Aerodrome",
      "city": "Mumbai",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 13,
      "lat": 19.0981006622,
      "lon": 72.834197998,
      "tz": "Asia\/Kolkata"
  },
  "VAJM": {
      "icao": "VAJM",
      "iata": "JGA",
      "name": "Jamnagar Airport",
      "city": "Jamnagar",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 69,
      "lat": 22.4654998779,
      "lon": 70.0126037598,
      "tz": "Asia\/Kolkata"
  },
  "VAKE": {
      "icao": "VAKE",
      "iata": "IXY",
      "name": "Kandla Airport",
      "city": "Kandla",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 96,
      "lat": 23.1126995087,
      "lon": 70.1003036499,
      "tz": "Asia\/Kolkata"
  },
  "VAKJ": {
      "icao": "VAKJ",
      "iata": "HJR",
      "name": "Khajuraho Airport",
      "city": "Khajuraho",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 728,
      "lat": 24.817199707,
      "lon": 79.9186019897,
      "tz": "Asia\/Kolkata"
  },
  "VAKP": {
      "icao": "VAKP",
      "iata": "KLH",
      "name": "Kolhapur Airport",
      "city": "",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1996,
      "lat": 16.6646995544,
      "lon": 74.2893981934,
      "tz": "Asia\/Kolkata"
  },
  "VAKS": {
      "icao": "VAKS",
      "iata": "IXK",
      "name": "Keshod Airport",
      "city": "",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 167,
      "lat": 21.3171005249,
      "lon": 70.270401001,
      "tz": "Asia\/Kolkata"
  },
  "VALT": {
      "icao": "VALT",
      "iata": "LTU",
      "name": "Murod Kond Airport",
      "city": "Latur",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 2136,
      "lat": 18.4115009308,
      "lon": 76.4646987915,
      "tz": "Asia\/Kolkata"
  },
  "VAND": {
      "icao": "VAND",
      "iata": "NDC",
      "name": "Nanded Airport",
      "city": "Nanded",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1250,
      "lat": 19.1833000183,
      "lon": 77.3167037964,
      "tz": "Asia\/Kolkata"
  },
  "VANP": {
      "icao": "VANP",
      "iata": "NAG",
      "name": "Dr. Babasaheb Ambedkar International Airport",
      "city": "Naqpur",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1033,
      "lat": 21.0921993256,
      "lon": 79.047203064,
      "tz": "Asia\/Kolkata"
  },
  "VANR": {
      "icao": "VANR",
      "iata": "ISK",
      "name": "Gandhinagar Airport",
      "city": "Nashik",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1959,
      "lat": 19.9636993408,
      "lon": 73.8076019287,
      "tz": "Asia\/Kolkata"
  },
  "VAOZ": {
      "icao": "VAOZ",
      "iata": "ISK",
      "name": "Ozar Air Force Station",
      "city": "Nasik",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1900,
      "lat": 20.1191005707,
      "lon": 73.912902832,
      "tz": "Asia\/Kolkata"
  },
  "VAPO": {
      "icao": "VAPO",
      "iata": "PNQ",
      "name": "Pune Airport",
      "city": "Pune",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1942,
      "lat": 18.5820999146,
      "lon": 73.9197006226,
      "tz": "Asia\/Kolkata"
  },
  "VAPR": {
      "icao": "VAPR",
      "iata": "PBD",
      "name": "Porbandar Airport",
      "city": "Porbandar",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 23,
      "lat": 21.6487007141,
      "lon": 69.6572036743,
      "tz": "Asia\/Kolkata"
  },
  "VARG": {
      "icao": "VARG",
      "iata": "RTC",
      "name": "Ratnagiri Airport",
      "city": "",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 305,
      "lat": 17.0135993958,
      "lon": 73.327796936,
      "tz": "Asia\/Kolkata"
  },
  "VARK": {
      "icao": "VARK",
      "iata": "RAJ",
      "name": "Rajkot Airport",
      "city": "Rajkot",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 441,
      "lat": 22.3092002869,
      "lon": 70.7795028687,
      "tz": "Asia\/Kolkata"
  },
  "VARP": {
      "icao": "VARP",
      "iata": "RPR",
      "name": "Raipur Airport",
      "city": "Raipur",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1041,
      "lat": 21.1804008484,
      "lon": 81.7388000488,
      "tz": "Asia\/Kolkata"
  },
  "VASD": {
      "icao": "VASD",
      "iata": "SAG",
      "name": "Shirdi Airport",
      "city": "Kakadi",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1909,
      "lat": 19.688611,
      "lon": 74.378889,
      "tz": "Asia\/Kolkata"
  },
  "VASL": {
      "icao": "VASL",
      "iata": "SSE",
      "name": "Solapur Airport",
      "city": "Solapur",
      "state": "Maharashtra",
      "country": "IN",
      "elevation": 1584,
      "lat": 17.6280002594,
      "lon": 75.9347991943,
      "tz": "Asia\/Kolkata"
  },
  "VASU": {
      "icao": "VASU",
      "iata": "STV",
      "name": "Surat Airport",
      "city": "",
      "state": "Gujarat",
      "country": "IN",
      "elevation": 16,
      "lat": 21.1140995026,
      "lon": 72.7417984009,
      "tz": "Asia\/Kolkata"
  },
  "VAUD": {
      "icao": "VAUD",
      "iata": "UDR",
      "name": "Maharana Pratap Airport",
      "city": "Udaipur",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1684,
      "lat": 24.6177005768,
      "lon": 73.8961029053,
      "tz": "Asia\/Kolkata"
  },
  "VCBI": {
      "icao": "VCBI",
      "iata": "CMB",
      "name": "Bandaranaike International Colombo Airport",
      "city": "Colombo",
      "state": "Western",
      "country": "LK",
      "elevation": 30,
      "lat": 7.1807599068,
      "lon": 79.8841018677,
      "tz": "Asia\/Colombo"
  },
  "VCCA": {
      "icao": "VCCA",
      "iata": "ACJ",
      "name": "Anuradhapura Air Force Base",
      "city": "Anuradhapura",
      "state": "North-Central",
      "country": "LK",
      "elevation": 324,
      "lat": 8.30148983,
      "lon": 80.4279022217,
      "tz": "Asia\/Colombo"
  },
  "VCCB": {
      "icao": "VCCB",
      "iata": "BTC",
      "name": "Batticaloa Airport",
      "city": "Batticaloa",
      "state": "Eastern-Province",
      "country": "LK",
      "elevation": 20,
      "lat": 7.7057600021,
      "lon": 81.6788024902,
      "tz": "Asia\/Colombo"
  },
  "VCCC": {
      "icao": "VCCC",
      "iata": "RML",
      "name": "Colombo Ratmalana Airport",
      "city": "Colombo",
      "state": "Western",
      "country": "LK",
      "elevation": 22,
      "lat": 6.8219900131,
      "lon": 79.8861999512,
      "tz": "Asia\/Colombo"
  },
  "VCCG": {
      "icao": "VCCG",
      "iata": "GOY",
      "name": "Amparai Airport",
      "city": "Amparai",
      "state": "Eastern-Province",
      "country": "LK",
      "elevation": 150,
      "lat": 7.3370800018,
      "lon": 81.6259002686,
      "tz": "Asia\/Colombo"
  },
  "VCCH": {
      "icao": "VCCH",
      "iata": "MNH",
      "name": "Hingurakgoda Air Force Base",
      "city": "Polonnaruwa Town",
      "state": "North-Central",
      "country": "LK",
      "elevation": 170,
      "lat": 8.0498104095,
      "lon": 80.9813995361,
      "tz": "Asia\/Colombo"
  },
  "VCCJ": {
      "icao": "VCCJ",
      "iata": "JAF",
      "name": "Kankesanturai Airport",
      "city": "Jaffna",
      "state": "Northern-Province",
      "country": "LK",
      "elevation": 33,
      "lat": 9.7923297882,
      "lon": 80.070098877,
      "tz": "Asia\/Colombo"
  },
  "VCCK": {
      "icao": "VCCK",
      "iata": "KCT",
      "name": "Koggala Airport",
      "city": "Galle",
      "state": "Southern",
      "country": "LK",
      "elevation": 10,
      "lat": 5.9936800003,
      "lon": 80.3202972412,
      "tz": "Asia\/Colombo"
  },
  "VCCN": {
      "icao": "VCCN",
      "iata": "KTY",
      "name": "Katukurunda Air Force Base",
      "city": "Kalutara",
      "state": "Western",
      "country": "LK",
      "elevation": 10,
      "lat": 6.5521202087,
      "lon": 79.9775009155,
      "tz": "Asia\/Colombo"
  },
  "VCCS": {
      "icao": "VCCS",
      "iata": "GIU",
      "name": "Sigiriya Air Force Base",
      "city": "Sigiriya",
      "state": "Central",
      "country": "LK",
      "elevation": 630,
      "lat": 7.9566698074,
      "lon": 80.7285003662,
      "tz": "Asia\/Colombo"
  },
  "VCCT": {
      "icao": "VCCT",
      "iata": "TRR",
      "name": "China Bay Airport",
      "city": "Trincomalee",
      "state": "Eastern-Province",
      "country": "LK",
      "elevation": 6,
      "lat": 8.5385103226,
      "lon": 81.1819000244,
      "tz": "Asia\/Colombo"
  },
  "VCCV": {
      "icao": "VCCV",
      "iata": "",
      "name": "Vavuniya Airport",
      "city": "Vavuniya",
      "state": "Northern-Province",
      "country": "LK",
      "elevation": 299,
      "lat": 8.7413902283,
      "lon": 80.4973983765,
      "tz": "Asia\/Colombo"
  },
  "VCCW": {
      "icao": "VCCW",
      "iata": "WRZ",
      "name": "Weerawila International Airport",
      "city": "Weerawila",
      "state": "Southern",
      "country": "LK",
      "elevation": 50,
      "lat": 6.2544898987,
      "lon": 81.2351989746,
      "tz": "Asia\/Colombo"
  },
  "VCRI": {
      "icao": "VCRI",
      "iata": "HRI",
      "name": "Mattala Rajapaksa International Airport",
      "city": "Mattala",
      "state": "Hambantota",
      "country": "LK",
      "elevation": 157,
      "lat": 6.288889,
      "lon": 81.123611,
      "tz": "Asia\/Colombo"
  },
  "VDBG": {
      "icao": "VDBG",
      "iata": "BBM",
      "name": "Battambang Airport",
      "city": "Battambang",
      "state": "",
      "country": "KH",
      "elevation": 59,
      "lat": 13.0956001282,
      "lon": 103.2239990234,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDKC": {
      "icao": "VDKC",
      "iata": "",
      "name": "Kompong Cham Airport",
      "city": "Kompong Cham",
      "state": "Kampong-Cham",
      "country": "KH",
      "elevation": 148,
      "lat": 12.0329999924,
      "lon": 105.4329986572,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDKH": {
      "icao": "VDKH",
      "iata": "KZC",
      "name": "Kampong Chhnang Airport",
      "city": "",
      "state": "Kampong-Chhnang",
      "country": "KH",
      "elevation": 56,
      "lat": 12.255200386,
      "lon": 104.5640029907,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDKK": {
      "icao": "VDKK",
      "iata": "KKZ",
      "name": "Kaoh Kong Airport",
      "city": "Kaoh Kong",
      "state": "Koh-Kong",
      "country": "KH",
      "elevation": 0,
      "lat": 11.6133971186,
      "lon": 102.997083664,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDKT": {
      "icao": "VDKT",
      "iata": "KTI",
      "name": "Kratie Airport",
      "city": "Kratie",
      "state": "Kratie",
      "country": "KH",
      "elevation": 0,
      "lat": 12.4879999161,
      "lon": 106.0550003052,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDMK": {
      "icao": "VDMK",
      "iata": "MWV",
      "name": "Mondulkiri Airport",
      "city": "Sen Monorom",
      "state": "Mondolkiri",
      "country": "KH",
      "elevation": 0,
      "lat": 12.4636479116,
      "lon": 107.187252045,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDPP": {
      "icao": "VDPP",
      "iata": "PNH",
      "name": "Phnom Penh International Airport",
      "city": "Phnom Penh",
      "state": "",
      "country": "KH",
      "elevation": 40,
      "lat": 11.5466003418,
      "lon": 104.84400177,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDRK": {
      "icao": "VDRK",
      "iata": "RBE",
      "name": "Ratanakiri Airport",
      "city": "Ratanakiri",
      "state": "Ratanakiri",
      "country": "KH",
      "elevation": 0,
      "lat": 13.7299995422,
      "lon": 106.9869995117,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDSR": {
      "icao": "VDSR",
      "iata": "REP",
      "name": "Angkor International Airport",
      "city": "Siem Reap",
      "state": "Siem-Reap",
      "country": "KH",
      "elevation": 60,
      "lat": 13.4106998444,
      "lon": 103.81300354,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDST": {
      "icao": "VDST",
      "iata": "TNX",
      "name": "Stung Treng Airport",
      "city": "Stung Treng",
      "state": "Stung-Treng",
      "country": "KH",
      "elevation": 203,
      "lat": 13.5319004059,
      "lon": 106.0149993896,
      "tz": "Asia\/Phnom_Penh"
  },
  "VDSV": {
      "icao": "VDSV",
      "iata": "KOS",
      "name": "Sihanoukville International Airport",
      "city": "Sihanukville",
      "state": "Kampot",
      "country": "KH",
      "elevation": 33,
      "lat": 10.57970047,
      "lon": 103.637001038,
      "tz": "Asia\/Phnom_Penh"
  },
  "VE23": {
      "icao": "VE23",
      "iata": "",
      "name": "Burnpur Airport",
      "city": "",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 310,
      "lat": 23.6315002441,
      "lon": 86.9755020142,
      "tz": "Asia\/Kolkata"
  },
  "VE24": {
      "icao": "VE24",
      "iata": "",
      "name": "Sookerating Airport",
      "city": "",
      "state": "Assam",
      "country": "IN",
      "elevation": 400,
      "lat": 27.5529003143,
      "lon": 95.570602417,
      "tz": "Asia\/Kolkata"
  },
  "VE36": {
      "icao": "VE36",
      "iata": "",
      "name": "Nuagaon Airport",
      "city": "",
      "state": "Odisha",
      "country": "IN",
      "elevation": 656,
      "lat": 20.5116996765,
      "lon": 83.448097229,
      "tz": "Asia\/Kolkata"
  },
  "VE41": {
      "icao": "VE41",
      "iata": "",
      "name": "Giridih Airport",
      "city": "",
      "state": "Jharkhand",
      "country": "IN",
      "elevation": 1000,
      "lat": 24.2019004822,
      "lon": 86.2898025513,
      "tz": "Asia\/Kolkata"
  },
  "VE44": {
      "icao": "VE44",
      "iata": "",
      "name": "Hashimara Air Force Station",
      "city": "Jalpaiguri",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 340,
      "lat": 26.698299408,
      "lon": 89.369102478,
      "tz": "Asia\/Kolkata"
  },
  "VE54": {
      "icao": "VE54",
      "iata": "",
      "name": "Daltonganj Airport",
      "city": "",
      "state": "Jharkhand",
      "country": "IN",
      "elevation": 803,
      "lat": 24.0193004608,
      "lon": 84.0951004028,
      "tz": "Asia\/Kolkata"
  },
  "VE62": {
      "icao": "VE62",
      "iata": "",
      "name": "Cuttack Airport",
      "city": "",
      "state": "Odisha",
      "country": "IN",
      "elevation": 115,
      "lat": 20.5496006012,
      "lon": 85.8862991333,
      "tz": "Asia\/Kolkata"
  },
  "VE67": {
      "icao": "VE67",
      "iata": "",
      "name": "Machuka Advanced Landing Ground",
      "city": "",
      "state": "Arunachal-Pradesh",
      "country": "IN",
      "elevation": 7900,
      "lat": 28.6024580064,
      "lon": 94.1261386871,
      "tz": "Asia\/Kolkata"
  },
  "VEDH": {
      "icao": "VEDH",
      "iata": "DBR",
      "name": "Darbhanga Airport",
      "city": "Darbhanga",
      "state": "Bihar",
      "country": "IN",
      "elevation": 171,
      "lat": 26.194722,
      "lon": 85.9175,
      "tz": "Asia\/Kolkata"
  },
  "VE91": {
      "icao": "VE91",
      "iata": "",
      "name": "Vijaynagar Advanced Landing Ground",
      "city": "",
      "state": "Sagain",
      "country": "IN",
      "elevation": 4167,
      "lat": 27.1935997009,
      "lon": 97.0037002563,
      "tz": "Asia\/Kolkata"
  },
  "VE96": {
      "icao": "VE96",
      "iata": "",
      "name": "Thuniabhand Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 2182,
      "lat": 22.0016002655,
      "lon": 78.9171981812,
      "tz": "Asia\/Kolkata"
  },
  "VEAL": {
      "icao": "VEAL",
      "iata": "",
      "name": "Savitri Jindal Airport",
      "city": "Angul",
      "state": "Odisha",
      "country": "IN",
      "elevation": 624,
      "lat": 20.910556,
      "lon": 85.035,
      "tz": "Asia\/Kolkata"
  },
  "VEAN": {
      "icao": "VEAN",
      "iata": "IXV",
      "name": "Along Airport",
      "city": "",
      "state": "Arunachal-Pradesh",
      "country": "IN",
      "elevation": 900,
      "lat": 28.1753005981,
      "lon": 94.8020019531,
      "tz": "Asia\/Kolkata"
  },
  "VEAT": {
      "icao": "VEAT",
      "iata": "IXA",
      "name": "Agartala Airport",
      "city": "Agartala",
      "state": "Tripura",
      "country": "IN",
      "elevation": 46,
      "lat": 23.8869991302,
      "lon": 91.2404022217,
      "tz": "Asia\/Dhaka"
  },
  "VEBA": {
      "icao": "VEBA",
      "iata": "",
      "name": "Behala Airport",
      "city": "Behala",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 10,
      "lat": 22.5042304993,
      "lon": 88.2934265137,
      "tz": "Asia\/Kolkata"
  },
  "VEBD": {
      "icao": "VEBD",
      "iata": "IXB",
      "name": "Bagdogra Airport",
      "city": "Siliguri",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 412,
      "lat": 26.6812000275,
      "lon": 88.3285980225,
      "tz": "Asia\/Kolkata"
  },
  "VEBG": {
      "icao": "VEBG",
      "iata": "RGH",
      "name": "Balurghat Airport",
      "city": "Balurghat",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 78,
      "lat": 25.2616996765,
      "lon": 88.7956008911,
      "tz": "Asia\/Kolkata"
  },
  "VEBI": {
      "icao": "VEBI",
      "iata": "SHL",
      "name": "Shillong Airport",
      "city": "Shillong",
      "state": "Meghalaya",
      "country": "IN",
      "elevation": 2910,
      "lat": 25.7035999298,
      "lon": 91.9786987305,
      "tz": "Asia\/Kolkata"
  },
  "VEBK": {
      "icao": "VEBK",
      "iata": "",
      "name": "Bokaro Airport",
      "city": "",
      "state": "Jharkhand",
      "country": "IN",
      "elevation": 715,
      "lat": 23.6434993744,
      "lon": 86.1489028931,
      "tz": "Asia\/Kolkata"
  },
  "VEBN": {
      "icao": "VEBN",
      "iata": "VNS",
      "name": "Lal Bahadur Shastri Airport",
      "city": "Varanasi",
      "state": "Uttar Pradesh",
      "country": "IN",
      "elevation": 270,
      "lat": 25.451167,
      "lon": 82.858667,
      "tz": "Asia\/Kolkata"
  },
  "VEBS": {
      "icao": "VEBS",
      "iata": "BBI",
      "name": "Biju Patnaik Airport",
      "city": "Bhubaneswar",
      "state": "Odisha",
      "country": "IN",
      "elevation": 138,
      "lat": 20.2444000244,
      "lon": 85.8178024292,
      "tz": "Asia\/Kolkata"
  },
  "VECA": {
      "icao": "VECA",
      "iata": "",
      "name": "Chabua Air Force Station",
      "city": "Dibrugarh",
      "state": "Assam",
      "country": "IN",
      "elevation": 367,
      "lat": 27.4622001648,
      "lon": 95.1176986694,
      "tz": "Asia\/Kolkata"
  },
  "VECC": {
      "icao": "VECC",
      "iata": "CCU",
      "name": "Netaji Subhash Chandra Bose International Airport",
      "city": "Kolkata",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 16,
      "lat": 22.6546993256,
      "lon": 88.4467010498,
      "tz": "Asia\/Kolkata"
  },
  "VECK": {
      "icao": "VECK",
      "iata": "",
      "name": "Chakulia Airport",
      "city": "",
      "state": "Jharkhand",
      "country": "IN",
      "elevation": 430,
      "lat": 22.4605998993,
      "lon": 86.7078018188,
      "tz": "Asia\/Kolkata"
  },
  "VECO": {
      "icao": "VECO",
      "iata": "COH",
      "name": "Cooch Behar Airport",
      "city": "",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 138,
      "lat": 26.330499649,
      "lon": 89.4672012329,
      "tz": "Asia\/Kolkata"
  },
  "VEDB": {
      "icao": "VEDB",
      "iata": "DBD",
      "name": "Dhanbad Airport",
      "city": "",
      "state": "Bihar",
      "country": "IN",
      "elevation": 847,
      "lat": 23.8339996338,
      "lon": 86.4253005981,
      "tz": "Asia\/Kolkata"
  },
  "VEDO": {
      "icao": "VEDO",
      "iata": "DGH",
      "name": "Deoghar Airport",
      "city": "Deoghar",
      "state": "Jharkhand",
      "country": "IN",
      "elevation": 833,
      "lat": 24.444722,
      "lon": 86.7025,
      "tz": "Asia\/Kolkata"
  },
  "VEDX": {
      "icao": "VEDX",
      "iata": "",
      "name": "Kalaikunda Air Force Station",
      "city": "",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 200,
      "lat": 22.3395004272,
      "lon": 87.2145004272,
      "tz": "Asia\/Kolkata"
  },
  "VEDZ": {
      "icao": "VEDZ",
      "iata": "DAE",
      "name": "Daporijo Airport",
      "city": "",
      "state": "Arunachal-Pradesh",
      "country": "IN",
      "elevation": 750,
      "lat": 27.9855003357,
      "lon": 94.2228012085,
      "tz": "Asia\/Kolkata"
  },
  "VEGK": {
      "icao": "VEGK",
      "iata": "GOP",
      "name": "Gorakhpur Airport",
      "city": "Gorakhpur",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 259,
      "lat": 26.7397003174,
      "lon": 83.4496994019,
      "tz": "Asia\/Kolkata"
  },
  "VEGT": {
      "icao": "VEGT",
      "iata": "GAU",
      "name": "Lokpriya Gopinath Bordoloi International Airport",
      "city": "Guwahati",
      "state": "Assam",
      "country": "IN",
      "elevation": 162,
      "lat": 26.1061000824,
      "lon": 91.585899353,
      "tz": "Asia\/Kolkata"
  },
  "VEGY": {
      "icao": "VEGY",
      "iata": "GAY",
      "name": "Gaya Airport",
      "city": "",
      "state": "Bihar",
      "country": "IN",
      "elevation": 380,
      "lat": 24.7443008423,
      "lon": 84.9512023926,
      "tz": "Asia\/Kolkata"
  },
  "VEHK": {
      "icao": "VEHK",
      "iata": "",
      "name": "Hirakud Airport",
      "city": "Sambalpur",
      "state": "Odisha",
      "country": "IN",
      "elevation": 658,
      "lat": 21.5802001953,
      "lon": 84.0056991577,
      "tz": "Asia\/Kolkata"
  },
  "VEIM": {
      "icao": "VEIM",
      "iata": "IMF",
      "name": "Imphal Airport",
      "city": "Imphal",
      "state": "Manipur",
      "country": "IN",
      "elevation": 2540,
      "lat": 24.7600002289,
      "lon": 93.896697998,
      "tz": "Asia\/Kolkata"
  },
  "VEJH": {
      "icao": "VEJH",
      "iata": "JRG",
      "name": "Jharsuguda Airport",
      "city": "Jharsuguda",
      "state": "Odisha",
      "country": "IN",
      "elevation": 757,
      "lat": 21.9134998322,
      "lon": 84.0503997803,
      "tz": "Asia\/Kolkata"
  },
  "VEJP": {
      "icao": "VEJP",
      "iata": "PYB",
      "name": "Jeypore Airport",
      "city": "Jeypore",
      "state": "Odisha",
      "country": "IN",
      "elevation": 1952,
      "lat": 18.8799991608,
      "lon": 82.5520019531,
      "tz": "Asia\/Kolkata"
  },
  "VEJS": {
      "icao": "VEJS",
      "iata": "IXW",
      "name": "Jamshedpur Airport",
      "city": "",
      "state": "Jharkhand",
      "country": "IN",
      "elevation": 505,
      "lat": 22.8131999969,
      "lon": 86.168800354,
      "tz": "Asia\/Kolkata"
  },
  "VEJT": {
      "icao": "VEJT",
      "iata": "JRH",
      "name": "Jorhat Airport",
      "city": "Jorhat",
      "state": "Assam",
      "country": "IN",
      "elevation": 311,
      "lat": 26.7315006256,
      "lon": 94.1754989624,
      "tz": "Asia\/Kolkata"
  },
  "VEKM": {
      "icao": "VEKM",
      "iata": "IXQ",
      "name": "Kamalpur Airport",
      "city": "",
      "state": "Tripura",
      "country": "IN",
      "elevation": 131,
      "lat": 24.1317005157,
      "lon": 91.814201355,
      "tz": "Asia\/Kolkata"
  },
  "VEKR": {
      "icao": "VEKR",
      "iata": "IXH",
      "name": "Kailashahar Airport",
      "city": "",
      "state": "Tripura",
      "country": "IN",
      "elevation": 79,
      "lat": 24.3082008362,
      "lon": 92.0072021484,
      "tz": "Asia\/Dhaka"
  },
  "VEKU": {
      "icao": "VEKU",
      "iata": "IXS",
      "name": "Silchar Airport",
      "city": "Silchar",
      "state": "Assam",
      "country": "IN",
      "elevation": 352,
      "lat": 24.9129009247,
      "lon": 92.9786987305,
      "tz": "Asia\/Kolkata"
  },
  "VEKW": {
      "icao": "VEKW",
      "iata": "IXN",
      "name": "Khowai Airport",
      "city": "Khowai",
      "state": "Tripura",
      "country": "IN",
      "elevation": 95,
      "lat": 24.0618991852,
      "lon": 91.6038970947,
      "tz": "Asia\/Dhaka"
  },
  "VELP": {
      "icao": "VELP",
      "iata": "AJL",
      "name": "Lengpui Airport",
      "city": "Aizawl",
      "state": "Mizoram",
      "country": "IN",
      "elevation": 1398,
      "lat": 23.8405990601,
      "lon": 92.6196975708,
      "tz": "Asia\/Kolkata"
  },
  "VELR": {
      "icao": "VELR",
      "iata": "IXI",
      "name": "North Lakhimpur Airport",
      "city": "Lilabari",
      "state": "Assam",
      "country": "IN",
      "elevation": 330,
      "lat": 27.2954998016,
      "lon": 94.0976028442,
      "tz": "Asia\/Kolkata"
  },
  "VEMH": {
      "icao": "VEMH",
      "iata": "LDA",
      "name": "Malda Airport",
      "city": "Malda",
      "state": "",
      "country": "IN",
      "elevation": 79,
      "lat": 25.033000946,
      "lon": 88.1330032349,
      "tz": "Asia\/Kolkata"
  },
  "VEMN": {
      "icao": "VEMN",
      "iata": "DIB",
      "name": "Dibrugarh Airport",
      "city": "Dibrugarh",
      "state": "Assam",
      "country": "IN",
      "elevation": 362,
      "lat": 27.4839000702,
      "lon": 95.0168991089,
      "tz": "Asia\/Kolkata"
  },
  "VEMR": {
      "icao": "VEMR",
      "iata": "DMU",
      "name": "Dimapur Airport",
      "city": "Dimapur",
      "state": "Nagaland",
      "country": "IN",
      "elevation": 487,
      "lat": 25.8838996887,
      "lon": 93.7711029053,
      "tz": "Asia\/Kolkata"
  },
  "VEMZ": {
      "icao": "VEMZ",
      "iata": "MZU",
      "name": "Muzaffarpur Airport",
      "city": "",
      "state": "Bihar",
      "country": "IN",
      "elevation": 174,
      "lat": 26.1191005707,
      "lon": 85.3136978149,
      "tz": "Asia\/Kolkata"
  },
  "VENP": {
      "icao": "VENP",
      "iata": "",
      "name": "Nawapara Airport",
      "city": "Nawapara",
      "state": "Odisha",
      "country": "IN",
      "elevation": 1058,
      "lat": 20.8700008392,
      "lon": 82.5195999146,
      "tz": "Asia\/Kolkata"
  },
  "VEPG": {
      "icao": "VEPG",
      "iata": "IXT",
      "name": "Pasighat Airport",
      "city": "Pasighat",
      "state": "Arunachal-Pradesh",
      "country": "IN",
      "elevation": 477,
      "lat": 28.0660991669,
      "lon": 95.3356018066,
      "tz": "Asia\/Kolkata"
  },
  "VEPH": {
      "icao": "VEPH",
      "iata": "",
      "name": "Panagarh Air Force Station",
      "city": "",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 240,
      "lat": 23.4743003845,
      "lon": 87.4274978638,
      "tz": "Asia\/Kolkata"
  },
  "VEPI": {
      "icao": "VEPI",
      "iata": "",
      "name": "Barrackpore Air Force Station",
      "city": "Barrackpore",
      "state": "West-Bengal",
      "country": "IN",
      "elevation": 18,
      "lat": 22.7810001373,
      "lon": 88.3591995239,
      "tz": "Asia\/Kolkata"
  },
  "VEPT": {
      "icao": "VEPT",
      "iata": "PAT",
      "name": "Lok Nayak Jayaprakash Airport",
      "city": "Patna",
      "state": "Bihar",
      "country": "IN",
      "elevation": 170,
      "lat": 25.591299057,
      "lon": 85.0879974365,
      "tz": "Asia\/Kolkata"
  },
  "VEPU": {
      "icao": "VEPU",
      "iata": "",
      "name": "Purnea Airport",
      "city": "",
      "state": "Bihar",
      "country": "IN",
      "elevation": 129,
      "lat": 25.7595996857,
      "lon": 87.4100036621,
      "tz": "Asia\/Kolkata"
  },
  "VERC": {
      "icao": "VERC",
      "iata": "IXR",
      "name": "Birsa Munda Airport",
      "city": "Ranchi",
      "state": "Jharkhand",
      "country": "IN",
      "elevation": 2148,
      "lat": 23.3143005371,
      "lon": 85.3217010498,
      "tz": "Asia\/Kolkata"
  },
  "VERK": {
      "icao": "VERK",
      "iata": "RRK",
      "name": "Rourkela Airport",
      "city": "",
      "state": "Odisha",
      "country": "IN",
      "elevation": 659,
      "lat": 22.2567005157,
      "lon": 84.8145980835,
      "tz": "Asia\/Kolkata"
  },
  "VERL": {
      "icao": "VERL",
      "iata": "",
      "name": "Raxaul Airport",
      "city": "",
      "state": "",
      "country": "IN",
      "elevation": 259,
      "lat": 26.9666996002,
      "lon": 84.8332977295,
      "tz": "Asia\/Kolkata"
  },
  "VERU": {
      "icao": "VERU",
      "iata": "RUP",
      "name": "Rupsi India Airport",
      "city": "",
      "state": "Assam",
      "country": "IN",
      "elevation": 131,
      "lat": 26.1396999359,
      "lon": 89.9100036621,
      "tz": "Asia\/Kolkata"
  },
  "VETJ": {
      "icao": "VETJ",
      "iata": "TEI",
      "name": "Tezu Airport",
      "city": "Tezu",
      "state": "Arunachal-Pradesh",
      "country": "IN",
      "elevation": 800,
      "lat": 27.9412002563,
      "lon": 96.1343994141,
      "tz": "Asia\/Kolkata"
  },
  "VETZ": {
      "icao": "VETZ",
      "iata": "TEZ",
      "name": "Tezpur Airport",
      "city": "",
      "state": "Assam",
      "country": "IN",
      "elevation": 240,
      "lat": 26.7091007233,
      "lon": 92.7846984863,
      "tz": "Asia\/Kolkata"
  },
  "VEUK": {
      "icao": "VEUK",
      "iata": "",
      "name": "Utkela Airport",
      "city": "",
      "state": "Odisha",
      "country": "IN",
      "elevation": 680,
      "lat": 20.0974006653,
      "lon": 83.1837997437,
      "tz": "Asia\/Kolkata"
  },
  "VEVZ": {
      "icao": "VEVZ",
      "iata": "VTZ",
      "name": "Vishakhapatnam Airport",
      "city": "Visakhapatnam",
      "state": "Andhra-Pradesh",
      "country": "IN",
      "elevation": 15,
      "lat": 17.721200943,
      "lon": 83.2245025635,
      "tz": "Asia\/Kolkata"
  },
  "VEZO": {
      "icao": "VEZO",
      "iata": "ZER",
      "name": "Zero Airport",
      "city": "",
      "state": "Arunachal-Pradesh",
      "country": "IN",
      "elevation": 5403,
      "lat": 27.588300705,
      "lon": 93.8281021118,
      "tz": "Asia\/Kolkata"
  },
  "VG00": {
      "icao": "VG00",
      "iata": "",
      "name": "Mears Field",
      "city": "Eastville",
      "state": "Virginia",
      "country": "US",
      "elevation": 22,
      "lat": 37.3698997498,
      "lon": -75.9468994141,
      "tz": "America\/New_York"
  },
  "VG01": {
      "icao": "VG01",
      "iata": "",
      "name": "Eureka Airport",
      "city": "Keysville",
      "state": "Virginia",
      "country": "US",
      "elevation": 420,
      "lat": 37.0624008179,
      "lon": -78.5616989136,
      "tz": "America\/New_York"
  },
  "VG05": {
      "icao": "VG05",
      "iata": "",
      "name": "Payne Airport",
      "city": "Tabscott",
      "state": "Virginia",
      "country": "US",
      "elevation": 463,
      "lat": 37.8796005249,
      "lon": -78.0575027466,
      "tz": "America\/New_York"
  },
  "VG06": {
      "icao": "VG06",
      "iata": "",
      "name": "Fulcher Family Farms Airport",
      "city": "Amherst",
      "state": "Virginia",
      "country": "US",
      "elevation": 980,
      "lat": 37.7251014709,
      "lon": -79.0830993652,
      "tz": "America\/New_York"
  },
  "VG07": {
      "icao": "VG07",
      "iata": "",
      "name": "Rular Airport",
      "city": "Remington",
      "state": "Virginia",
      "country": "US",
      "elevation": 305,
      "lat": 38.538898468,
      "lon": -77.8283004761,
      "tz": "America\/New_York"
  },
  "VG09": {
      "icao": "VG09",
      "iata": "",
      "name": "Starbase Airport",
      "city": "Cape Charles",
      "state": "Virginia",
      "country": "US",
      "elevation": 20,
      "lat": 37.2277984619,
      "lon": -75.9832992554,
      "tz": "America\/New_York"
  },
  "VG10": {
      "icao": "VG10",
      "iata": "",
      "name": "Cathro Airport",
      "city": "Charlottesville",
      "state": "Virginia",
      "country": "US",
      "elevation": 500,
      "lat": 37.927898407,
      "lon": -78.551399231,
      "tz": "America\/New_York"
  },
  "VG12": {
      "icao": "VG12",
      "iata": "",
      "name": "Simpsonville Airport",
      "city": "Rhoadesville",
      "state": "Virginia",
      "country": "US",
      "elevation": 400,
      "lat": 38.3334999084,
      "lon": -77.8664016724,
      "tz": "America\/New_York"
  },
  "VG16": {
      "icao": "VG16",
      "iata": "",
      "name": "Collins Airport",
      "city": "Patrick Springs",
      "state": "Virginia",
      "country": "US",
      "elevation": 1100,
      "lat": 36.6250991821,
      "lon": -80.1706008911,
      "tz": "America\/New_York"
  },
  "VG18": {
      "icao": "VG18",
      "iata": "",
      "name": "Sky Bryce Airport",
      "city": "Basye",
      "state": "Virginia",
      "country": "US",
      "elevation": 1263,
      "lat": 38.8158988953,
      "lon": -78.7703018188,
      "tz": "America\/New_York"
  },
  "VG19": {
      "icao": "VG19",
      "iata": "",
      "name": "Sawyer STOLport",
      "city": "New Church",
      "state": "Virginia",
      "country": "US",
      "elevation": 13,
      "lat": 37.9906997681,
      "lon": -75.5919036865,
      "tz": "America\/New_York"
  },
  "VG20": {
      "icao": "VG20",
      "iata": "",
      "name": "Onley Airport",
      "city": "Onley",
      "state": "Virginia",
      "country": "US",
      "elevation": 45,
      "lat": 37.6750984192,
      "lon": -75.7098999023,
      "tz": "America\/New_York"
  },
  "VG22": {
      "icao": "VG22",
      "iata": "",
      "name": "Rockfish Airpark",
      "city": "Wintergreen",
      "state": "Virginia",
      "country": "US",
      "elevation": 760,
      "lat": 37.8796005249,
      "lon": -78.8936004639,
      "tz": "America\/New_York"
  },
  "VG23": {
      "icao": "VG23",
      "iata": "",
      "name": "Smith Airport",
      "city": "Arcola",
      "state": "Virginia",
      "country": "US",
      "elevation": 365,
      "lat": 38.927898407,
      "lon": -77.5627975464,
      "tz": "America\/New_York"
  },
  "VG24": {
      "icao": "VG24",
      "iata": "",
      "name": "Mayers Airport",
      "city": "Ashland",
      "state": "Virginia",
      "country": "US",
      "elevation": 215,
      "lat": 37.7817993164,
      "lon": -77.4938964844,
      "tz": "America\/New_York"
  },
  "VG25": {
      "icao": "VG25",
      "iata": "",
      "name": "Robinson Airport",
      "city": "Bedford",
      "state": "Virginia",
      "country": "US",
      "elevation": 850,
      "lat": 37.3484992981,
      "lon": -79.5719985962,
      "tz": "America\/New_York"
  },
  "VG27": {
      "icao": "VG27",
      "iata": "",
      "name": "Windy Ridge Airport",
      "city": "Bedford",
      "state": "Virginia",
      "country": "US",
      "elevation": 750,
      "lat": 37.2692985535,
      "lon": -79.4358978271,
      "tz": "America\/New_York"
  },
  "VG28": {
      "icao": "VG28",
      "iata": "",
      "name": "Mann Airport",
      "city": "Boykins",
      "state": "Virginia",
      "country": "US",
      "elevation": 35,
      "lat": 36.5489997864,
      "lon": -77.1913986206,
      "tz": "America\/New_York"
  },
  "VG29": {
      "icao": "VG29",
      "iata": "",
      "name": "Branham Mill Airpark",
      "city": "Warsaw",
      "state": "Virginia",
      "country": "US",
      "elevation": 100,
      "lat": 37.9707984924,
      "lon": -76.7097015381,
      "tz": "America\/New_York"
  },
  "VG30": {
      "icao": "VG30",
      "iata": "",
      "name": "Scott Farm Strip",
      "city": "Cape Charles",
      "state": "Virginia",
      "country": "US",
      "elevation": 33,
      "lat": 37.2271003723,
      "lon": -75.9768981934,
      "tz": "America\/New_York"
  },
  "VG31": {
      "icao": "VG31",
      "iata": "",
      "name": "Sager Field",
      "city": "Broadway",
      "state": "Virginia",
      "country": "US",
      "elevation": 1280,
      "lat": 38.7134017944,
      "lon": -78.863899231,
      "tz": "America\/New_York"
  },
  "VG32": {
      "icao": "VG32",
      "iata": "",
      "name": "Eastview Airport",
      "city": "Buckingham",
      "state": "Virginia",
      "country": "US",
      "elevation": 500,
      "lat": 37.6142997742,
      "lon": -78.6035995483,
      "tz": "America\/New_York"
  },
  "VG33": {
      "icao": "VG33",
      "iata": "",
      "name": "Bull Farm Airport",
      "city": "Cape Charles",
      "state": "Virginia",
      "country": "US",
      "elevation": 18,
      "lat": 37.1545982361,
      "lon": -75.9569015503,
      "tz": "America\/New_York"
  },
  "VG34": {
      "icao": "VG34",
      "iata": "",
      "name": "Merifield Airport",
      "city": "Clarksville",
      "state": "Virginia",
      "country": "US",
      "elevation": 355,
      "lat": 36.5845985413,
      "lon": -78.5008010864,
      "tz": "America\/New_York"
  },
  "VG36": {
      "icao": "VG36",
      "iata": "",
      "name": "Keysville Airport",
      "city": "Keysville",
      "state": "Virginia",
      "country": "US",
      "elevation": 627,
      "lat": 37.0382003784,
      "lon": -78.4627990723,
      "tz": "America\/New_York"
  },
  "VG37": {
      "icao": "VG37",
      "iata": "",
      "name": "Umphlett Airstrip",
      "city": "Whaleyville",
      "state": "Virginia",
      "country": "US",
      "elevation": 75,
      "lat": 36.5892982483,
      "lon": -76.6380004883,
      "tz": "America\/New_York"
  },
  "VG38": {
      "icao": "VG38",
      "iata": "",
      "name": "Easter Field",
      "city": "Amelia",
      "state": "Virginia",
      "country": "US",
      "elevation": 380,
      "lat": 37.3695983887,
      "lon": -78.0830993652,
      "tz": "America\/New_York"
  },
  "VG39": {
      "icao": "VG39",
      "iata": "",
      "name": "Earth Airport",
      "city": "Cape Charles",
      "state": "Virginia",
      "country": "US",
      "elevation": 9,
      "lat": 37.2207984924,
      "lon": -76.0074996948,
      "tz": "America\/New_York"
  },
  "VG40": {
      "icao": "VG40",
      "iata": "",
      "name": "Woody Field Airport",
      "city": "Rocky Mount",
      "state": "Virginia",
      "country": "US",
      "elevation": 1150,
      "lat": 36.8835983276,
      "lon": -79.8523025513,
      "tz": "America\/New_York"
  },
  "VG42": {
      "icao": "VG42",
      "iata": "",
      "name": "Henshaw Airport",
      "city": "Hopewell",
      "state": "Virginia",
      "country": "US",
      "elevation": 85,
      "lat": 37.2523994446,
      "lon": -77.1527023315,
      "tz": "America\/New_York"
  },
  "VG43": {
      "icao": "VG43",
      "iata": "",
      "name": "Arrowpoint Airport",
      "city": "Madison",
      "state": "Virginia",
      "country": "US",
      "elevation": 315,
      "lat": 38.3064002991,
      "lon": -78.0988998413,
      "tz": "America\/New_York"
  },
  "VG52": {
      "icao": "VG52",
      "iata": "",
      "name": "Woodridge Field",
      "city": "Lovingston",
      "state": "Virginia",
      "country": "US",
      "elevation": 663,
      "lat": 37.8391990662,
      "lon": -78.8217010498,
      "tz": "America\/New_York"
  },
  "VG53": {
      "icao": "VG53",
      "iata": "",
      "name": "Venning's Landing Airport",
      "city": "Rocky Mount",
      "state": "Virginia",
      "country": "US",
      "elevation": 1100,
      "lat": 37.043598175,
      "lon": -79.801399231,
      "tz": "America\/New_York"
  },
  "VG55": {
      "icao": "VG55",
      "iata": "",
      "name": "Woodstock Airport",
      "city": "Woodstock",
      "state": "Virginia",
      "country": "US",
      "elevation": 965,
      "lat": 38.8819999695,
      "lon": -78.557800293,
      "tz": "America\/New_York"
  },
  "VG56": {
      "icao": "VG56",
      "iata": "",
      "name": "Midway Airport",
      "city": "Bloxom",
      "state": "Virginia",
      "country": "US",
      "elevation": 50,
      "lat": 37.8083000183,
      "lon": -75.6027984619,
      "tz": "America\/New_York"
  },
  "VG57": {
      "icao": "VG57",
      "iata": "",
      "name": "Maples Field",
      "city": "Manassas",
      "state": "Virginia",
      "country": "US",
      "elevation": 225,
      "lat": 38.6057014465,
      "lon": -77.5847015381,
      "tz": "America\/New_York"
  },
  "VG58": {
      "icao": "VG58",
      "iata": "",
      "name": "Abbott Airport",
      "city": "Rocky Mount",
      "state": "Virginia",
      "country": "US",
      "elevation": 981,
      "lat": 36.945333,
      "lon": -79.796667,
      "tz": "America\/New_York"
  },
  "VG64": {
      "icao": "VG64",
      "iata": "",
      "name": "Lee Field",
      "city": "West Point",
      "state": "Virginia",
      "country": "US",
      "elevation": 20,
      "lat": 37.5615,
      "lon": -76.852528,
      "tz": "America\/New_York"
  },
  "VGBR": {
      "icao": "VGBR",
      "iata": "BZL",
      "name": "Barisal Airport",
      "city": "Barisal",
      "state": "Barisal",
      "country": "BD",
      "elevation": 23,
      "lat": 22.8010005951,
      "lon": 90.3012008667,
      "tz": "Asia\/Dhaka"
  },
  "VGCB": {
      "icao": "VGCB",
      "iata": "CXB",
      "name": "Cox's Bazar Airport",
      "city": "Cox's Bazar",
      "state": "Chittagong",
      "country": "BD",
      "elevation": 12,
      "lat": 21.4521999359,
      "lon": 91.9638977051,
      "tz": "Asia\/Dhaka"
  },
  "VGCM": {
      "icao": "VGCM",
      "iata": "CLA",
      "name": "Comilla Airport",
      "city": "Comilla",
      "state": "Chittagong",
      "country": "BD",
      "elevation": 25,
      "lat": 23.4372005463,
      "lon": 91.1896972656,
      "tz": "Asia\/Dhaka"
  },
  "VGEG": {
      "icao": "VGEG",
      "iata": "CGP",
      "name": "Shah Amanat International Airport",
      "city": "Chittagong",
      "state": "Chittagong",
      "country": "BD",
      "elevation": 12,
      "lat": 22.2495994568,
      "lon": 91.8133010864,
      "tz": "Asia\/Dhaka"
  },
  "VGHS": {
      "icao": "VGHS",
      "iata": "DAC",
      "name": "Dhaka \/ Hazrat Shahjalal International Airport",
      "city": "Dhaka",
      "state": "Dhaka",
      "country": "BD",
      "elevation": 30,
      "lat": 23.843347,
      "lon": 90.397783,
      "tz": "Asia\/Dhaka"
  },
  "VGIS": {
      "icao": "VGIS",
      "iata": "IRD",
      "name": "Ishurdi Airport",
      "city": "Ishurdi",
      "state": "Rajshahi-Division",
      "country": "BD",
      "elevation": 45,
      "lat": 24.1525001526,
      "lon": 89.0494003296,
      "tz": "Asia\/Dhaka"
  },
  "VGJR": {
      "icao": "VGJR",
      "iata": "JSR",
      "name": "Jessore Airport",
      "city": "Jashahor",
      "state": "Khulna",
      "country": "BD",
      "elevation": 20,
      "lat": 23.1837997437,
      "lon": 89.1607971191,
      "tz": "Asia\/Dhaka"
  },
  "VGLM": {
      "icao": "VGLM",
      "iata": "LLJ",
      "name": "Lalmonirhat Airport",
      "city": "Lalmonirhat",
      "state": "Rangpur-Division",
      "country": "BD",
      "elevation": 106,
      "lat": 25.8875007629,
      "lon": 89.4330978394,
      "tz": "Asia\/Dhaka"
  },
  "VGRJ": {
      "icao": "VGRJ",
      "iata": "RJH",
      "name": "Shah Mokhdum Airport",
      "city": "Rajshahi",
      "state": "Rajshahi-Division",
      "country": "BD",
      "elevation": 64,
      "lat": 24.4372005463,
      "lon": 88.6165008545,
      "tz": "Asia\/Dhaka"
  },
  "VGSD": {
      "icao": "VGSD",
      "iata": "SPD",
      "name": "Saidpur Airport",
      "city": "Saidpur",
      "state": "Rangpur-Division",
      "country": "BD",
      "elevation": 125,
      "lat": 25.7591991425,
      "lon": 88.9088973999,
      "tz": "Asia\/Dhaka"
  },
  "VGSG": {
      "icao": "VGSG",
      "iata": "TKR",
      "name": "Thakurgaon Airport",
      "city": "Thakurgaon",
      "state": "Rangpur-Division",
      "country": "BD",
      "elevation": 176,
      "lat": 26.0163993835,
      "lon": 88.4036026001,
      "tz": "Asia\/Dhaka"
  },
  "VGSH": {
      "icao": "VGSH",
      "iata": "ZHM",
      "name": "Shamshernagar Airport",
      "city": "Shamshernagar",
      "state": "Sylhet",
      "country": "BD",
      "elevation": 56,
      "lat": 24.4169998169,
      "lon": 91.8830032349,
      "tz": "Asia\/Dhaka"
  },
  "VGSY": {
      "icao": "VGSY",
      "iata": "ZYL",
      "name": "Osmany International Airport",
      "city": "Sylhet",
      "state": "Sylhet",
      "country": "BD",
      "elevation": 50,
      "lat": 24.9631996155,
      "lon": 91.8667984009,
      "tz": "Asia\/Dhaka"
  },
  "VGTJ": {
      "icao": "VGTJ",
      "iata": "",
      "name": "Tejgaon Airport",
      "city": "Dhaka",
      "state": "Dhaka",
      "country": "BD",
      "elevation": 24,
      "lat": 23.778799057,
      "lon": 90.3826980591,
      "tz": "Asia\/Dhaka"
  },
  "VHHH": {
      "icao": "VHHH",
      "iata": "HKG",
      "name": "Chek Lap Kok International Airport",
      "city": "Hong Kong",
      "state": "",
      "country": "HK",
      "elevation": 28,
      "lat": 22.3089008331,
      "lon": 113.915000916,
      "tz": "Asia\/Hong_Kong"
  },
  "VHSK": {
      "icao": "VHSK",
      "iata": "",
      "name": "Shek Kong Air Base",
      "city": "Hong Kong",
      "state": "",
      "country": "HK",
      "elevation": 50,
      "lat": 22.4365997314,
      "lon": 114.0800018311,
      "tz": "Asia\/Hong_Kong"
  },
  "VI20": {
      "icao": "VI20",
      "iata": "",
      "name": "Bhilwara Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 955,
      "lat": 28.0771007538,
      "lon": 76.2050018311,
      "tz": "Asia\/Kolkata"
  },
  "VI40": {
      "icao": "VI40",
      "iata": "",
      "name": "Karnal Airport",
      "city": "",
      "state": "Haryana",
      "country": "IN",
      "elevation": 829,
      "lat": 29.714099884,
      "lon": 77.0374984741,
      "tz": "Asia\/Kolkata"
  },
  "VI43": {
      "icao": "VI43",
      "iata": "",
      "name": "Suratgarh New Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 554,
      "lat": 29.3878002167,
      "lon": 73.9039001465,
      "tz": "Asia\/Kolkata"
  },
  "VI57": {
      "icao": "VI57",
      "iata": "",
      "name": "Thoise Airport",
      "city": "",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 10046,
      "lat": 34.6525993347,
      "lon": 77.3758010864,
      "tz": "Asia\/Kolkata"
  },
  "VI65": {
      "icao": "VI65",
      "iata": "",
      "name": "Kargil Airport",
      "city": "",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 9604,
      "lat": 34.5242996216,
      "lon": 76.1558990479,
      "tz": "Asia\/Kolkata"
  },
  "VI66": {
      "icao": "VI66",
      "iata": "",
      "name": "Fukche Advanced Landing Ground",
      "city": "",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 13700,
      "lat": 32.9374008179,
      "lon": 79.2132034302,
      "tz": "Asia\/Kolkata"
  },
  "VI69": {
      "icao": "VI69",
      "iata": "",
      "name": "Jhunjhunu Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1110,
      "lat": 28.1067008972,
      "lon": 75.3756027222,
      "tz": "Asia\/Kolkata"
  },
  "VI70": {
      "icao": "VI70",
      "iata": "",
      "name": "Pilani New Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1100,
      "lat": 28.3488006592,
      "lon": 75.5936965942,
      "tz": "Asia\/Kolkata"
  },
  "VI71": {
      "icao": "VI71",
      "iata": "",
      "name": "Kalka Airport",
      "city": "",
      "state": "Haryana",
      "country": "IN",
      "elevation": 1700,
      "lat": 30.8245697021,
      "lon": 76.8836517334,
      "tz": "Asia\/Kolkata"
  },
  "VI73": {
      "icao": "VI73",
      "iata": "",
      "name": "Nagaur Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 950,
      "lat": 27.2082996368,
      "lon": 73.7114028931,
      "tz": "Asia\/Kolkata"
  },
  "VI75": {
      "icao": "VI75",
      "iata": "",
      "name": "Kalyanpur Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 400,
      "lat": 26.5203990936,
      "lon": 80.2329025269,
      "tz": "Asia\/Kolkata"
  },
  "VI76": {
      "icao": "VI76",
      "iata": "",
      "name": "Band Tal Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 790,
      "lat": 26.0000991821,
      "lon": 78.2618026733,
      "tz": "Asia\/Kolkata"
  },
  "VI82": {
      "icao": "VI82",
      "iata": "",
      "name": "Bharkot Airport",
      "city": "",
      "state": "Uttarakhand",
      "country": "IN",
      "elevation": 2983,
      "lat": 30.5828990936,
      "lon": 78.3234024048,
      "tz": "Asia\/Kolkata"
  },
  "VI88": {
      "icao": "VI88",
      "iata": "",
      "name": "Jallowal Airport",
      "city": "",
      "state": "Punjab",
      "country": "IN",
      "elevation": 741,
      "lat": 31.5599994659,
      "lon": 75.3413009644,
      "tz": "Asia\/Kolkata"
  },
  "VI90": {
      "icao": "VI90",
      "iata": "",
      "name": "Akbarpur Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 288,
      "lat": 26.447599411,
      "lon": 82.5681991577,
      "tz": "Asia\/Kolkata"
  },
  "VIAG": {
      "icao": "VIAG",
      "iata": "AGR",
      "name": "Agra Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 551,
      "lat": 27.1557998657,
      "lon": 77.960899353,
      "tz": "Asia\/Kolkata"
  },
  "VIAH": {
      "icao": "VIAH",
      "iata": "",
      "name": "Dhanipur Airstrip",
      "city": "Aligarth",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 0,
      "lat": 27.860856311,
      "lon": 78.1468677521,
      "tz": "Asia\/Kolkata"
  },
  "VIAL": {
      "icao": "VIAL",
      "iata": "IXD",
      "name": "Allahabad Airport",
      "city": "Allahabad",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 322,
      "lat": 25.4400997162,
      "lon": 81.7339019775,
      "tz": "Asia\/Kolkata"
  },
  "VIAM": {
      "icao": "VIAM",
      "iata": "",
      "name": "Ambala Air Force Station",
      "city": "",
      "state": "Haryana",
      "country": "IN",
      "elevation": 909,
      "lat": 30.3680992126,
      "lon": 76.8167037964,
      "tz": "Asia\/Kolkata"
  },
  "VIAR": {
      "icao": "VIAR",
      "iata": "ATQ",
      "name": "Sri Guru Ram Dass Jee International Airport Amritsar",
      "city": "Amritsar",
      "state": "Punjab",
      "country": "IN",
      "elevation": 756,
      "lat": 31.7096004486,
      "lon": 74.7973022461,
      "tz": "Asia\/Kolkata"
  },
  "VIAW": {
      "icao": "VIAW",
      "iata": "",
      "name": "Awantipur Air Force Station",
      "city": "",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 5393,
      "lat": 33.8765983582,
      "lon": 74.9757003784,
      "tz": "Asia\/Kolkata"
  },
  "VIAX": {
      "icao": "VIAX",
      "iata": "AIP",
      "name": "Adampur Air Force Station",
      "city": "",
      "state": "Punjab",
      "country": "IN",
      "elevation": 775,
      "lat": 31.4337997437,
      "lon": 75.7587966919,
      "tz": "Asia\/Kolkata"
  },
  "VIBK": {
      "icao": "VIBK",
      "iata": "BKB",
      "name": "Nal Airport",
      "city": "Bikaner",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 750,
      "lat": 28.0706005096,
      "lon": 73.2071990967,
      "tz": "Asia\/Kolkata"
  },
  "VIBL": {
      "icao": "VIBL",
      "iata": "",
      "name": "Bakshi Ka Talab Air Force Station",
      "city": "Lucknow",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 385,
      "lat": 26.9883003235,
      "lon": 80.8930969238,
      "tz": "Asia\/Kolkata"
  },
  "VIBN": {
      "icao": "VIBN",
      "iata": "VNS",
      "name": "Lal Bahadur Shastri Airport",
      "city": "Varanasi",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 266,
      "lat": 25.4524002075,
      "lon": 82.8592987061,
      "tz": "Asia\/Kolkata"
  },
  "VIBR": {
      "icao": "VIBR",
      "iata": "KUU",
      "name": "Kullu Manali Airport",
      "city": "",
      "state": "Himachal-Pradesh",
      "country": "IN",
      "elevation": 3573,
      "lat": 31.8766994476,
      "lon": 77.1544036865,
      "tz": "Asia\/Kolkata"
  },
  "VIBT": {
      "icao": "VIBT",
      "iata": "BUP",
      "name": "Bhatinda Air Force Station",
      "city": "",
      "state": "Punjab",
      "country": "IN",
      "elevation": 662,
      "lat": 30.2700996399,
      "lon": 74.7557983398,
      "tz": "Asia\/Kolkata"
  },
  "VIBW": {
      "icao": "VIBW",
      "iata": "",
      "name": "Bhiwani Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 720,
      "lat": 28.8369998932,
      "lon": 76.1791000366,
      "tz": "Asia\/Kolkata"
  },
  "VIBY": {
      "icao": "VIBY",
      "iata": "BEK",
      "name": "Bareilly Air Force Station",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 580,
      "lat": 28.4221000671,
      "lon": 79.4507980347,
      "tz": "Asia\/Kolkata"
  },
  "VICG": {
      "icao": "VICG",
      "iata": "IXC",
      "name": "Chandigarh Airport",
      "city": "Chandigarh",
      "state": "Chandigarh",
      "country": "IN",
      "elevation": 1012,
      "lat": 30.673500061,
      "lon": 76.7884979248,
      "tz": "Asia\/Kolkata"
  },
  "VICX": {
      "icao": "VICX",
      "iata": "",
      "name": "Chakeri Air Force Station",
      "city": "Kanpur",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 405,
      "lat": 26.4043006897,
      "lon": 80.4101028442,
      "tz": "Asia\/Kolkata"
  },
  "VIDD": {
      "icao": "VIDD",
      "iata": "",
      "name": "Safdarjung Airport",
      "city": "",
      "state": "NCT",
      "country": "IN",
      "elevation": 705,
      "lat": 28.5844993591,
      "lon": 77.2058029175,
      "tz": "Asia\/Kolkata"
  },
  "VIDF": {
      "icao": "VIDF",
      "iata": "",
      "name": "Pithorgarh Airport",
      "city": "",
      "state": "Uttarakhand",
      "country": "IN",
      "elevation": 4910,
      "lat": 29.5935993195,
      "lon": 80.2391967773,
      "tz": "Asia\/Kolkata"
  },
  "VIDN": {
      "icao": "VIDN",
      "iata": "DED",
      "name": "Dehradun Airport",
      "city": "Dehradun",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 1831,
      "lat": 30.189699173,
      "lon": 78.1802978516,
      "tz": "Asia\/Kolkata"
  },
  "VIDP": {
      "icao": "VIDP",
      "iata": "DEL",
      "name": "Indira Gandhi International Airport",
      "city": "New Delhi",
      "state": "NCT",
      "country": "IN",
      "elevation": 777,
      "lat": 28.5664997101,
      "lon": 77.1031036377,
      "tz": "Asia\/Kolkata"
  },
  "VIDX": {
      "icao": "VIDX",
      "iata": "",
      "name": "Hindon Air Force Station",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 700,
      "lat": 28.7077007294,
      "lon": 77.3589019775,
      "tz": "Asia\/Kolkata"
  },
  "VIGG": {
      "icao": "VIGG",
      "iata": "DHM",
      "name": "Kangra Airport",
      "city": "",
      "state": "Himachal-Pradesh",
      "country": "IN",
      "elevation": 2525,
      "lat": 32.1651000977,
      "lon": 76.2633972168,
      "tz": "Asia\/Kolkata"
  },
  "VIGR": {
      "icao": "VIGR",
      "iata": "GWL",
      "name": "Gwalior Airport",
      "city": "Gwalior",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 617,
      "lat": 26.2933006287,
      "lon": 78.2277984619,
      "tz": "Asia\/Kolkata"
  },
  "VIHR": {
      "icao": "VIHR",
      "iata": "HSS",
      "name": "Hissar Airport",
      "city": "",
      "state": "Haryana",
      "country": "IN",
      "elevation": 700,
      "lat": 29.1793994904,
      "lon": 75.7553024292,
      "tz": "Asia\/Kolkata"
  },
  "VIHX": {
      "icao": "VIHX",
      "iata": "",
      "name": "Halwara Air Force Station",
      "city": "",
      "state": "Punjab",
      "country": "IN",
      "elevation": 790,
      "lat": 30.748500824,
      "lon": 75.6297988892,
      "tz": "Asia\/Kolkata"
  },
  "VIJN": {
      "icao": "VIJN",
      "iata": "",
      "name": "Jhansi Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 801,
      "lat": 25.4911994934,
      "lon": 78.5584030151,
      "tz": "Asia\/Kolkata"
  },
  "VIJO": {
      "icao": "VIJO",
      "iata": "JDH",
      "name": "Jodhpur Airport",
      "city": "Jodhpur",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 717,
      "lat": 26.2511005402,
      "lon": 73.0488967896,
      "tz": "Asia\/Kolkata"
  },
  "VIJP": {
      "icao": "VIJP",
      "iata": "JAI",
      "name": "Jaipur International Airport",
      "city": "Jaipur",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1263,
      "lat": 26.8241996765,
      "lon": 75.8122024536,
      "tz": "Asia\/Kolkata"
  },
  "VIJR": {
      "icao": "VIJR",
      "iata": "JSA",
      "name": "Jaisalmer Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 751,
      "lat": 26.8887004852,
      "lon": 70.8649978638,
      "tz": "Asia\/Kolkata"
  },
  "VIJU": {
      "icao": "VIJU",
      "iata": "IXJ",
      "name": "Jammu Airport",
      "city": "Jammu",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 1029,
      "lat": 32.6890983582,
      "lon": 74.8374023438,
      "tz": "Asia\/Kolkata"
  },
  "VIKA": {
      "icao": "VIKA",
      "iata": "KNU",
      "name": "Kanpur Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 411,
      "lat": 26.441400528,
      "lon": 80.3648986816,
      "tz": "Asia\/Kolkata"
  },
  "VIKG": {
      "icao": "VIKG",
      "iata": "KQH",
      "name": "Kishangarh Airport, Ajmer",
      "city": "Kishangarh",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 1477,
      "lat": 26.591167,
      "lon": 74.816167,
      "tz": "Asia\/Kolkata"
  },
  "VIKO": {
      "icao": "VIKO",
      "iata": "KTU",
      "name": "Kota Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 896,
      "lat": 25.160200119,
      "lon": 75.8455963135,
      "tz": "Asia\/Kolkata"
  },
  "VILD": {
      "icao": "VILD",
      "iata": "LUH",
      "name": "Ludhiana Airport",
      "city": "",
      "state": "Punjab",
      "country": "IN",
      "elevation": 834,
      "lat": 30.8547000885,
      "lon": 75.9525985718,
      "tz": "Asia\/Kolkata"
  },
  "VILH": {
      "icao": "VILH",
      "iata": "IXL",
      "name": "Leh Kushok Bakula Rimpochee Airport",
      "city": "Leh",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 10682,
      "lat": 34.1358985901,
      "lon": 77.5465011597,
      "tz": "Asia\/Kolkata"
  },
  "VILK": {
      "icao": "VILK",
      "iata": "LKO",
      "name": "Chaudhary Charan Singh International Airport",
      "city": "Lucknow",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 410,
      "lat": 26.7605991364,
      "lon": 80.8892974854,
      "tz": "Asia\/Kolkata"
  },
  "VILP": {
      "icao": "VILP",
      "iata": "",
      "name": "Lalitpur Airport",
      "city": "Lalitpur",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 1204,
      "lat": 24.716999054,
      "lon": 78.4169998169,
      "tz": "Asia\/Kolkata"
  },
  "VIPK": {
      "icao": "VIPK",
      "iata": "IXP",
      "name": "Pathankot Air Force Station",
      "city": "",
      "state": "Punjab",
      "country": "IN",
      "elevation": 1017,
      "lat": 32.2337989807,
      "lon": 75.6345977783,
      "tz": "Asia\/Kolkata"
  },
  "VIPL": {
      "icao": "VIPL",
      "iata": "",
      "name": "Patiala Airport",
      "city": "",
      "state": "Haryana",
      "country": "IN",
      "elevation": 820,
      "lat": 30.3148002625,
      "lon": 76.3645019531,
      "tz": "Asia\/Kolkata"
  },
  "VIPT": {
      "icao": "VIPT",
      "iata": "PGH",
      "name": "Pantnagar Airport",
      "city": "Pantnagar",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 769,
      "lat": 29.0333995819,
      "lon": 79.4737014771,
      "tz": "Asia\/Kolkata"
  },
  "VIRB": {
      "icao": "VIRB",
      "iata": "",
      "name": "Fursatganj Airport",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 360,
      "lat": 26.248500824,
      "lon": 81.3805007935,
      "tz": "Asia\/Kolkata"
  },
  "VISA": {
      "icao": "VISA",
      "iata": "",
      "name": "Sirsa Air Force Station",
      "city": "",
      "state": "Haryana",
      "country": "IN",
      "elevation": 650,
      "lat": 29.5606002808,
      "lon": 75.0061035156,
      "tz": "Asia\/Kolkata"
  },
  "VISM": {
      "icao": "VISM",
      "iata": "SLV",
      "name": "Shimla Airport",
      "city": "",
      "state": "Himachal-Pradesh",
      "country": "IN",
      "elevation": 5072,
      "lat": 31.0818004608,
      "lon": 77.0680007935,
      "tz": "Asia\/Kolkata"
  },
  "VISP": {
      "icao": "VISP",
      "iata": "",
      "name": "Sarsawa Air Force Station",
      "city": "",
      "state": "Uttar-Pradesh",
      "country": "IN",
      "elevation": 891,
      "lat": 29.9939002991,
      "lon": 77.4253005981,
      "tz": "Asia\/Kolkata"
  },
  "VISR": {
      "icao": "VISR",
      "iata": "SXR",
      "name": "Sheikh ul Alam Airport",
      "city": "Srinagar",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 5429,
      "lat": 33.9870986938,
      "lon": 74.7742004395,
      "tz": "Asia\/Kolkata"
  },
  "VIST": {
      "icao": "VIST",
      "iata": "TNI",
      "name": "Satna Airport",
      "city": "",
      "state": "Madhya-Pradesh",
      "country": "IN",
      "elevation": 1060,
      "lat": 24.5622997284,
      "lon": 80.8548965454,
      "tz": "Asia\/Kolkata"
  },
  "VIUT": {
      "icao": "VIUT",
      "iata": "",
      "name": "Uttarlai Airport",
      "city": "",
      "state": "Rajasthan",
      "country": "IN",
      "elevation": 500,
      "lat": 25.8129997253,
      "lon": 71.4822998047,
      "tz": "Asia\/Kolkata"
  },
  "VIUX": {
      "icao": "VIUX",
      "iata": "",
      "name": "Udhampur Air Force Station",
      "city": "",
      "state": "Jammu-and-Kashmir",
      "country": "IN",
      "elevation": 2066,
      "lat": 32.9021987915,
      "lon": 75.1561965942,
      "tz": "Asia\/Kolkata"
  },
  "VLAP": {
      "icao": "VLAP",
      "iata": "AOU",
      "name": "Attopeu Airport",
      "city": "Attopeu",
      "state": "Attapu",
      "country": "LA",
      "elevation": 344,
      "lat": 14.814715357,
      "lon": 106.821699142,
      "tz": "Asia\/Vientiane"
  },
  "VLHS": {
      "icao": "VLHS",
      "iata": "OUI",
      "name": "Ban Huoeisay Airport",
      "city": "",
      "state": "Bokeo",
      "country": "LA",
      "elevation": 1380,
      "lat": 20.2572994232,
      "lon": 100.43699646,
      "tz": "Asia\/Bangkok"
  },
  "VLLB": {
      "icao": "VLLB",
      "iata": "LPQ",
      "name": "Luang Phabang International Airport",
      "city": "Luang Phabang",
      "state": "Louangphabang",
      "country": "LA",
      "elevation": 955,
      "lat": 19.8973007202,
      "lon": 102.1610031128,
      "tz": "Asia\/Vientiane"
  },
  "VLLN": {
      "icao": "VLLN",
      "iata": "LXG",
      "name": "Luang Namtha Airport",
      "city": "Luang Namtha",
      "state": "Loungnamtha",
      "country": "LA",
      "elevation": 1968,
      "lat": 20.966999054,
      "lon": 101.4000015259,
      "tz": "Asia\/Vientiane"
  },
  "VLOS": {
      "icao": "VLOS",
      "iata": "ODY",
      "name": "Oudomsay Airport",
      "city": "Oudomsay",
      "state": "Oudomxai",
      "country": "LA",
      "elevation": 1804,
      "lat": 20.6826992035,
      "lon": 101.9940032959,
      "tz": "Asia\/Vientiane"
  },
  "VLPS": {
      "icao": "VLPS",
      "iata": "PKZ",
      "name": "Pakse International Airport",
      "city": "Pakse",
      "state": "Champasak",
      "country": "LA",
      "elevation": 351,
      "lat": 15.1321001053,
      "lon": 105.78099823,
      "tz": "Asia\/Vientiane"
  },
  "VLPV": {
      "icao": "VLPV",
      "iata": "",
      "name": "Phonesavanh Airport",
      "city": "",
      "state": "Xiangkhoang",
      "country": "LA",
      "elevation": 3628,
      "lat": 19.4549007416,
      "lon": 103.2180023193,
      "tz": "Asia\/Vientiane"
  },
  "VLSB": {
      "icao": "VLSB",
      "iata": "ZBY",
      "name": "Sayaboury Airport",
      "city": "Sayaboury",
      "state": "Xiagnabouli",
      "country": "LA",
      "elevation": 1247,
      "lat": 19.2329998016,
      "lon": 101.733001709,
      "tz": "Asia\/Vientiane"
  },
  "VLSK": {
      "icao": "VLSK",
      "iata": "ZVK",
      "name": "Savannakhet Airport",
      "city": "",
      "state": "Savannahkhet",
      "country": "LA",
      "elevation": 509,
      "lat": 16.5566005707,
      "lon": 104.7600021362,
      "tz": "Asia\/Bangkok"
  },
  "VLSN": {
      "icao": "VLSN",
      "iata": "NEU",
      "name": "Sam Neua Airport",
      "city": "",
      "state": "Houaphan",
      "country": "LA",
      "elevation": 3281,
      "lat": 20.4183998108,
      "lon": 104.0670013428,
      "tz": "Asia\/Vientiane"
  },
  "VLSV": {
      "icao": "VLSV",
      "iata": "VNA",
      "name": "Saravane Airport",
      "city": "Saravane",
      "state": "Salavan",
      "country": "LA",
      "elevation": 574,
      "lat": 15.7094392077,
      "lon": 106.410698891,
      "tz": "Asia\/Vientiane"
  },
  "VLTK": {
      "icao": "VLTK",
      "iata": "THK",
      "name": "Thakhek Airport",
      "city": "Thakhek",
      "state": "Nakhon-Phanom",
      "country": "LA",
      "elevation": 449,
      "lat": 17.3999996185,
      "lon": 104.8000030518,
      "tz": "Asia\/Bangkok"
  },
  "VLVT": {
      "icao": "VLVT",
      "iata": "VTE",
      "name": "Wattay International Airport",
      "city": "Vientiane",
      "state": "Vientiane-Prefecture",
      "country": "LA",
      "elevation": 564,
      "lat": 17.9883003235,
      "lon": 102.56300354,
      "tz": "Asia\/Vientiane"
  },
  "VLXK": {
      "icao": "VLXK",
      "iata": "XKH",
      "name": "Xieng Khouang Airport",
      "city": "Xieng Khouang",
      "state": "Xiangkhoang",
      "country": "LA",
      "elevation": 3445,
      "lat": 19.4500007629,
      "lon": 103.1579971313,
      "tz": "Asia\/Vientiane"
  },
  "VMMC": {
      "icao": "VMMC",
      "iata": "MFM",
      "name": "Macau International Airport",
      "city": "Taipa",
      "state": "",
      "country": "MO",
      "elevation": 20,
      "lat": 22.1495990753,
      "lon": 113.5920028687,
      "tz": "Asia\/Macau"
  },
  "VNBG": {
      "icao": "VNBG",
      "iata": "BJH",
      "name": "Bajhang Airport",
      "city": "Bajhang",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 4100,
      "lat": 29.5389995575,
      "lon": 81.1854019165,
      "tz": "Asia\/Kathmandu"
  },
  "VNBJ": {
      "icao": "VNBJ",
      "iata": "BHP",
      "name": "Bhojpur Airport",
      "city": "Bhojpur",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 4000,
      "lat": 27.1473999023,
      "lon": 87.0507965088,
      "tz": "Asia\/Kathmandu"
  },
  "VNBL": {
      "icao": "VNBL",
      "iata": "BGL",
      "name": "Baglung Airport",
      "city": "Baglung",
      "state": "Western-Region",
      "country": "NP",
      "elevation": 3320,
      "lat": 28.2127990723,
      "lon": 83.6662979126,
      "tz": "Asia\/Kathmandu"
  },
  "VNBP": {
      "icao": "VNBP",
      "iata": "BHR",
      "name": "Bharatpur Airport",
      "city": "Bharatpur",
      "state": "Central-Region",
      "country": "NP",
      "elevation": 600,
      "lat": 27.6781005859,
      "lon": 84.429397583,
      "tz": "Asia\/Kathmandu"
  },
  "VNBR": {
      "icao": "VNBR",
      "iata": "BJU",
      "name": "Bajura Airport",
      "city": "Bajura",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 4300,
      "lat": 29.5020008087,
      "lon": 81.6689987183,
      "tz": "Asia\/Kathmandu"
  },
  "VNBT": {
      "icao": "VNBT",
      "iata": "BIT",
      "name": "Baitadi Airport",
      "city": "Baitadi",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 4200,
      "lat": 29.4652996063,
      "lon": 80.5492019653,
      "tz": "Asia\/Kathmandu"
  },
  "VNBW": {
      "icao": "VNBW",
      "iata": "BWA",
      "name": "Bhairahawa Airport",
      "city": "Bhairawa",
      "state": "Western-Region",
      "country": "NP",
      "elevation": 358,
      "lat": 27.5056991577,
      "lon": 83.4162979126,
      "tz": "Asia\/Kathmandu"
  },
  "VNCG": {
      "icao": "VNCG",
      "iata": "BDP",
      "name": "Bhadrapur Airport",
      "city": "Bhadrapur",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 300,
      "lat": 26.5708007812,
      "lon": 88.0795974731,
      "tz": "Asia\/Kathmandu"
  },
  "VNDG": {
      "icao": "VNDG",
      "iata": "DNP",
      "name": "Tulsipur Airport",
      "city": "Dang",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 2100,
      "lat": 28.1110992432,
      "lon": 82.2941970825,
      "tz": "Asia\/Kathmandu"
  },
  "VNDH": {
      "icao": "VNDH",
      "iata": "DHI",
      "name": "Dhangarhi Airport",
      "city": "Dhangarhi",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 690,
      "lat": 28.7532997131,
      "lon": 80.5819015503,
      "tz": "Asia\/Kathmandu"
  },
  "VNDL": {
      "icao": "VNDL",
      "iata": "DAP",
      "name": "Darchula Airport",
      "city": "Darchula",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 2132,
      "lat": 29.6692008972,
      "lon": 80.5484008789,
      "tz": "Asia\/Kathmandu"
  },
  "VNDP": {
      "icao": "VNDP",
      "iata": "DOP",
      "name": "Dolpa Airport",
      "city": "Dolpa",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 8200,
      "lat": 28.9857006073,
      "lon": 82.8190994263,
      "tz": "Asia\/Kathmandu"
  },
  "VNDR": {
      "icao": "VNDR",
      "iata": "",
      "name": "Dhorpatan Airport",
      "city": "Dhorpatan",
      "state": "Western-Region",
      "country": "NP",
      "elevation": 8950,
      "lat": 28.5170001984,
      "lon": 83.0329971313,
      "tz": "Asia\/Kathmandu"
  },
  "VNDT": {
      "icao": "VNDT",
      "iata": "SIH",
      "name": "Silgadi Doti Airport",
      "city": "Silgadi Doti",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 2100,
      "lat": 29.2630996704,
      "lon": 80.9359970093,
      "tz": "Asia\/Kathmandu"
  },
  "VNGK": {
      "icao": "VNGK",
      "iata": "GKH",
      "name": "Gorkha Airport",
      "city": "Gorkha",
      "state": "Western-Region",
      "country": "NP",
      "elevation": 1500,
      "lat": 28.0499992371,
      "lon": 84.483001709,
      "tz": "Asia\/Kathmandu"
  },
  "VNJI": {
      "icao": "VNJI",
      "iata": "JIR",
      "name": "Jiri Airport",
      "city": "Jiri",
      "state": "",
      "country": "NP",
      "elevation": 6000,
      "lat": 27.6170005798,
      "lon": 86.2170028687,
      "tz": "Asia\/Kathmandu"
  },
  "VNJL": {
      "icao": "VNJL",
      "iata": "JUM",
      "name": "Jumla Airport",
      "city": "Jumla",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 7700,
      "lat": 29.2742004395,
      "lon": 82.1932983398,
      "tz": "Asia\/Kathmandu"
  },
  "VNJP": {
      "icao": "VNJP",
      "iata": "JKR",
      "name": "Janakpur Airport",
      "city": "Janakpur",
      "state": "Central-Region",
      "country": "NP",
      "elevation": 256,
      "lat": 26.7087993622,
      "lon": 85.9224014282,
      "tz": "Asia\/Kathmandu"
  },
  "VNJS": {
      "icao": "VNJS",
      "iata": "JMO",
      "name": "Jomsom Airport",
      "city": "Jomsom",
      "state": "Western-Region",
      "country": "NP",
      "elevation": 9000,
      "lat": 28.780426,
      "lon": 83.723,
      "tz": "Asia\/Kathmandu"
  },
  "VNKL": {
      "icao": "VNKL",
      "iata": "",
      "name": "Kangel Danda Airport",
      "city": "",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 0,
      "lat": 27.4106333137,
      "lon": 86.6465950012,
      "tz": "Asia\/Kathmandu"
  },
  "VNKT": {
      "icao": "VNKT",
      "iata": "KTM",
      "name": "Tribhuvan International Airport",
      "city": "Kathmandu",
      "state": "Central-Region",
      "country": "NP",
      "elevation": 4390,
      "lat": 27.6965999603,
      "lon": 85.3591003418,
      "tz": "Asia\/Kathmandu"
  },
  "VNLD": {
      "icao": "VNLD",
      "iata": "LDN",
      "name": "Lamidanda Airport",
      "city": "Lamidanda",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 4100,
      "lat": 27.2530994415,
      "lon": 86.6699981689,
      "tz": "Asia\/Kathmandu"
  },
  "VNLK": {
      "icao": "VNLK",
      "iata": "LUA",
      "name": "Lukla Airport",
      "city": "Lukla",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 9380,
      "lat": 27.6868991852,
      "lon": 86.7296981812,
      "tz": "Asia\/Kathmandu"
  },
  "VNLT": {
      "icao": "VNLT",
      "iata": "LTG",
      "name": "Langtang Airport",
      "city": "Langtang",
      "state": "Central-Region",
      "country": "NP",
      "elevation": 12000,
      "lat": 28.2000007629,
      "lon": 85.5830001831,
      "tz": "Asia\/Kathmandu"
  },
  "VNMA": {
      "icao": "VNMA",
      "iata": "NGX",
      "name": "Manang Airport",
      "city": "Ngawal",
      "state": "Western-Region",
      "country": "NP",
      "elevation": 11001,
      "lat": 28.6413993835,
      "lon": 84.0892028809,
      "tz": "Asia\/Kathmandu"
  },
  "VNMG": {
      "icao": "VNMG",
      "iata": "MEY",
      "name": "Meghauli Airport",
      "city": "Meghauli",
      "state": "Central-Region",
      "country": "NP",
      "elevation": 600,
      "lat": 27.5774,
      "lon": 84.22875,
      "tz": "Asia\/Kathmandu"
  },
  "VNMN": {
      "icao": "VNMN",
      "iata": "XMG",
      "name": "Mahendranagar Airport",
      "city": "Mahendranagar",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 650,
      "lat": 28.9631996155,
      "lon": 80.1479034424,
      "tz": "Asia\/Kathmandu"
  },
  "VNNG": {
      "icao": "VNNG",
      "iata": "KEP",
      "name": "Nepalgunj Airport",
      "city": "Nepalgunj",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 540,
      "lat": 28.1035995483,
      "lon": 81.6669998169,
      "tz": "Asia\/Kathmandu"
  },
  "VNPK": {
      "icao": "VNPK",
      "iata": "PKR",
      "name": "Pokhara Airport",
      "city": "Pokhara",
      "state": "Western-Region",
      "country": "NP",
      "elevation": 2712,
      "lat": 28.2008991241,
      "lon": 83.9821014404,
      "tz": "Asia\/Kathmandu"
  },
  "VNPL": {
      "icao": "VNPL",
      "iata": "PPL",
      "name": "Phaplu Airport",
      "city": "Phaplu",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 7918,
      "lat": 27.5177868809,
      "lon": 86.5844535828,
      "tz": "Asia\/Kathmandu"
  },
  "VNRB": {
      "icao": "VNRB",
      "iata": "RJB",
      "name": "Rajbiraj Airport",
      "city": "Rajbiraj",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 250,
      "lat": 26.5170001984,
      "lon": 86.75,
      "tz": "Asia\/Kathmandu"
  },
  "VNRC": {
      "icao": "VNRC",
      "iata": "RHP",
      "name": "Ramechhap Airport",
      "city": "Ramechhap",
      "state": "Central-Region",
      "country": "NP",
      "elevation": 1555,
      "lat": 27.3939990997,
      "lon": 86.0614013672,
      "tz": "Asia\/Kathmandu"
  },
  "VNRK": {
      "icao": "VNRK",
      "iata": "RUK",
      "name": "Rukumkot Airport",
      "city": "Rukumkot",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 2500,
      "lat": 28.6270008087,
      "lon": 82.1949996948,
      "tz": "Asia\/Kathmandu"
  },
  "VNRP": {
      "icao": "VNRP",
      "iata": "RPA",
      "name": "Rolpa Airport",
      "city": "Rolpa",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 4100,
      "lat": 28.2670001984,
      "lon": 82.766998291,
      "tz": "Asia\/Kathmandu"
  },
  "VNRT": {
      "icao": "VNRT",
      "iata": "RUM",
      "name": "Rumjatar Airport",
      "city": "Rumjatar",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 4500,
      "lat": 27.3034992218,
      "lon": 86.5503997803,
      "tz": "Asia\/Kathmandu"
  },
  "VNSI": {
      "icao": "VNSI",
      "iata": "SIF",
      "name": "Simara Airport",
      "city": "Simara",
      "state": "Central-Region",
      "country": "NP",
      "elevation": 450,
      "lat": 27.1595001221,
      "lon": 84.9801025391,
      "tz": "Asia\/Kathmandu"
  },
  "VNSK": {
      "icao": "VNSK",
      "iata": "SKH",
      "name": "Surkhet Airport",
      "city": "Surkhet",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 2400,
      "lat": 28.5860004425,
      "lon": 81.6360015869,
      "tz": "Asia\/Kathmandu"
  },
  "VNSR": {
      "icao": "VNSR",
      "iata": "FEB",
      "name": "Sanfebagar Airport",
      "city": "Sanfebagar",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 2280,
      "lat": 29.2329998016,
      "lon": 81.2170028687,
      "tz": "Asia\/Kathmandu"
  },
  "VNST": {
      "icao": "VNST",
      "iata": "IMK",
      "name": "Simikot Airport",
      "city": "Simikot",
      "state": "Mid-Western",
      "country": "NP",
      "elevation": 9246,
      "lat": 29.9710998535,
      "lon": 81.818901062,
      "tz": "Asia\/Kathmandu"
  },
  "VNTH": {
      "icao": "VNTH",
      "iata": "",
      "name": "Thamkharka Airport",
      "city": "Khotang Bazar",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 5240,
      "lat": 27.04787,
      "lon": 86.858022,
      "tz": "Asia\/Kathmandu"
  },
  "VNTJ": {
      "icao": "VNTJ",
      "iata": "TPJ",
      "name": "Suketar Airport",
      "city": "Taplejung",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 7990,
      "lat": 27.3509,
      "lon": 87.69525,
      "tz": "Asia\/Kathmandu"
  },
  "VNTP": {
      "icao": "VNTP",
      "iata": "TPU",
      "name": "Tikapur Airport",
      "city": "Tikapur",
      "state": "Far-Western",
      "country": "NP",
      "elevation": 797,
      "lat": 28.5170001984,
      "lon": 81.1500015259,
      "tz": "Asia\/Kathmandu"
  },
  "VNTR": {
      "icao": "VNTR",
      "iata": "TMI",
      "name": "Tumling Tar Airport",
      "city": "Tumling Tar",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 1700,
      "lat": 27.3150005341,
      "lon": 87.1932983398,
      "tz": "Asia\/Kathmandu"
  },
  "VNVT": {
      "icao": "VNVT",
      "iata": "BIR",
      "name": "Biratnagar Airport",
      "city": "Biratnagar",
      "state": "Eastern-Region",
      "country": "NP",
      "elevation": 236,
      "lat": 26.4815006256,
      "lon": 87.263999939,
      "tz": "Asia\/Kathmandu"
  },
  "VO26": {
      "icao": "VO26",
      "iata": "",
      "name": "Kovilpatti Airport",
      "city": "",
      "state": "Mizoram",
      "country": "IN",
      "elevation": 325,
      "lat": 9.1538896561,
      "lon": 77.8211975098,
      "tz": "Asia\/Kolkata"
  },
  "VO52": {
      "icao": "VO52",
      "iata": "",
      "name": "Harihar Airport",
      "city": "",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 1750,
      "lat": 14.5350999832,
      "lon": 75.787399292,
      "tz": "Asia\/Kolkata"
  },
  "VOTK": {
      "icao": "VOTK",
      "iata": "TCR",
      "name": "Tuticorin Airport",
      "city": "Thoothukkudi",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 129,
      "lat": 8.724167,
      "lon": 78.025833,
      "tz": "Asia\/Kolkata"
  },
  "VO94": {
      "icao": "VO94",
      "iata": "",
      "name": "Campbell Bay Airport",
      "city": "",
      "state": "Andaman-and-Nicobar",
      "country": "IN",
      "elevation": 16,
      "lat": 7.0130400658,
      "lon": 93.9227981567,
      "tz": "Asia\/Kolkata"
  },
  "VO95": {
      "icao": "VO95",
      "iata": "",
      "name": "Hosur Airport",
      "city": "",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 3116,
      "lat": 12.6612997055,
      "lon": 77.7671966553,
      "tz": "Asia\/Kolkata"
  },
  "VOAR": {
      "icao": "VOAR",
      "iata": "",
      "name": "Arkonam Airport",
      "city": "",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 265,
      "lat": 13.0712003708,
      "lon": 79.6912002563,
      "tz": "Asia\/Kolkata"
  },
  "VOAT": {
      "icao": "VOAT",
      "iata": "AGX",
      "name": "Agatti Airport",
      "city": "",
      "state": "Laccadives",
      "country": "IN",
      "elevation": 14,
      "lat": 10.8236999512,
      "lon": 72.1760025024,
      "tz": "Asia\/Kolkata"
  },
  "VOBG": {
      "icao": "VOBG",
      "iata": "",
      "name": "HAL Airport",
      "city": "Bangalore",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 2912,
      "lat": 12.9499998093,
      "lon": 77.6681976318,
      "tz": "Asia\/Kolkata"
  },
  "VOBI": {
      "icao": "VOBI",
      "iata": "BEP",
      "name": "Bellary Airport",
      "city": "Bellary",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 30,
      "lat": 15.1627998352,
      "lon": 76.8827972412,
      "tz": "Asia\/Kolkata"
  },
  "VOBL": {
      "icao": "VOBL",
      "iata": "BLR",
      "name": "Bengaluru International Airport",
      "city": "Bangalore",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 3000,
      "lat": 13.1978998184,
      "lon": 77.7062988281,
      "tz": "Asia\/Kolkata"
  },
  "VOBR": {
      "icao": "VOBR",
      "iata": "",
      "name": "Bidar Air Force Station",
      "city": "",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 2178,
      "lat": 17.9081001282,
      "lon": 77.4870986938,
      "tz": "Asia\/Kolkata"
  },
  "VOBZ": {
      "icao": "VOBZ",
      "iata": "VGA",
      "name": "Vijayawada Airport",
      "city": "",
      "state": "Andhra-Pradesh",
      "country": "IN",
      "elevation": 82,
      "lat": 16.5303993225,
      "lon": 80.7967987061,
      "tz": "Asia\/Kolkata"
  },
  "VOCB": {
      "icao": "VOCB",
      "iata": "CJB",
      "name": "Coimbatore International Airport",
      "city": "Coimbatore",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 1324,
      "lat": 11.029999733,
      "lon": 77.0434036255,
      "tz": "Asia\/Kolkata"
  },
  "VOCC": {
      "icao": "VOCC",
      "iata": "",
      "name": "Willingdon Island Air Base",
      "city": "Kochi",
      "state": "Kerala",
      "country": "IN",
      "elevation": 8,
      "lat": 9.9473896027,
      "lon": 76.2731018066,
      "tz": "Asia\/Kolkata"
  },
  "VOCI": {
      "icao": "VOCI",
      "iata": "COK",
      "name": "Cochin International Airport",
      "city": "Cochin",
      "state": "Kerala",
      "country": "IN",
      "elevation": 30,
      "lat": 10.1520004272,
      "lon": 76.4019012451,
      "tz": "Asia\/Kolkata"
  },
  "VOCL": {
      "icao": "VOCL",
      "iata": "CCJ",
      "name": "Calicut International Airport",
      "city": "Calicut",
      "state": "Kerala",
      "country": "IN",
      "elevation": 342,
      "lat": 11.1367998123,
      "lon": 75.9552993774,
      "tz": "Asia\/Kolkata"
  },
  "VOCP": {
      "icao": "VOCP",
      "iata": "CDP",
      "name": "Cuddapah Airport",
      "city": "",
      "state": "Andhra-Pradesh",
      "country": "IN",
      "elevation": 430,
      "lat": 14.5100002289,
      "lon": 78.7727966309,
      "tz": "Asia\/Kolkata"
  },
  "VOCX": {
      "icao": "VOCX",
      "iata": "CBD",
      "name": "Car Nicobar Air Force Station",
      "city": "",
      "state": "Andaman-and-Nicobar",
      "country": "IN",
      "elevation": 5,
      "lat": 9.1525096893,
      "lon": 92.8196029663,
      "tz": "Asia\/Kolkata"
  },
  "VODG": {
      "icao": "VODG",
      "iata": "",
      "name": "Dundigul Air Force Academy",
      "city": "Hyderabad",
      "state": "Telangana",
      "country": "IN",
      "elevation": 2013,
      "lat": 17.627199173,
      "lon": 78.4033966064,
      "tz": "Asia\/Kolkata"
  },
  "VODK": {
      "icao": "VODK",
      "iata": "",
      "name": "Dunakonda Airport",
      "city": "Dunakonda",
      "state": "Andhra-Pradesh",
      "country": "IN",
      "elevation": 462,
      "lat": 15.8330001831,
      "lon": 79.5,
      "tz": "Asia\/Kolkata"
  },
  "VOGO": {
      "icao": "VOGO",
      "iata": "GOI",
      "name": "Dabolim Airport",
      "city": "Dabolim",
      "state": "Dabolim",
      "country": "IN",
      "elevation": 186,
      "lat": 15.380833,
      "lon": 73.827,
      "tz": "Asia\/Kolkata"
  },
  "VOHK": {
      "icao": "VOHK",
      "iata": "",
      "name": "Hakimpet Airport",
      "city": "",
      "state": "Telangana",
      "country": "IN",
      "elevation": 2020,
      "lat": 17.5534992218,
      "lon": 78.5249023438,
      "tz": "Asia\/Kolkata"
  },
  "VOHS": {
      "icao": "VOHS",
      "iata": "HYD",
      "name": "Rajiv Gandhi International Airport Shamshabad",
      "city": "Hyderabad",
      "state": "Telangana",
      "country": "IN",
      "elevation": 2024,
      "lat": 17.2313175201,
      "lon": 78.4298553467,
      "tz": "Asia\/Kolkata"
  },
  "VOHY": {
      "icao": "VOHY",
      "iata": "BPM",
      "name": "Begumpet Airport",
      "city": "Hyderabad",
      "state": "Telangana",
      "country": "IN",
      "elevation": 1742,
      "lat": 17.4531002045,
      "lon": 78.4675979614,
      "tz": "Asia\/Kolkata"
  },
  "VOJV": {
      "icao": "VOJV",
      "iata": "VDY",
      "name": "Vijayanagar Aerodrome (JSW)",
      "city": "",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 1670,
      "lat": 15.1749666667,
      "lon": 76.6349472222,
      "tz": "Asia\/Kolkata"
  },
  "VOKN": {
      "icao": "VOKN",
      "iata": "CNN",
      "name": "Kannur International Airport",
      "city": "Mattanur",
      "state": "Kerala",
      "country": "IN",
      "elevation": 249,
      "lat": 11.92,
      "lon": 75.55,
      "tz": "Asia\/Kolkata"
  },
  "VOKP": {
      "icao": "VOKP",
      "iata": "",
      "name": "Koppal Aerodrome",
      "city": "Koppal",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 1700,
      "lat": 15.3591003418,
      "lon": 76.2192993164,
      "tz": "Asia\/Kolkata"
  },
  "VOMD": {
      "icao": "VOMD",
      "iata": "IXM",
      "name": "Madurai Airport",
      "city": "Madurai",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 459,
      "lat": 9.8345098496,
      "lon": 78.0933990479,
      "tz": "Asia\/Kolkata"
  },
  "VOML": {
      "icao": "VOML",
      "iata": "IXE",
      "name": "Mangalore International Airport",
      "city": "Mangalore",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 337,
      "lat": 12.9612998962,
      "lon": 74.8900985718,
      "tz": "Asia\/Kolkata"
  },
  "VOMM": {
      "icao": "VOMM",
      "iata": "MAA",
      "name": "Chennai International Airport",
      "city": "Chennai",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 52,
      "lat": 12.9900054932,
      "lon": 80.1692962646,
      "tz": "Asia\/Kolkata"
  },
  "VOMY": {
      "icao": "VOMY",
      "iata": "MYQ",
      "name": "Mysore Airport",
      "city": "Mysore",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 2349,
      "lat": 12.3072004318,
      "lon": 76.6496963501,
      "tz": "Asia\/Kolkata"
  },
  "VONS": {
      "icao": "VONS",
      "iata": "",
      "name": "Nagarjuna Sagar Airport",
      "city": "",
      "state": "Andhra-Pradesh",
      "country": "IN",
      "elevation": 658,
      "lat": 16.5426998138,
      "lon": 79.3187026978,
      "tz": "Asia\/Kolkata"
  },
  "VOPB": {
      "icao": "VOPB",
      "iata": "IXZ",
      "name": "Vir Savarkar International Airport",
      "city": "Port Blair",
      "state": "Andaman-and-Nicobar",
      "country": "IN",
      "elevation": 14,
      "lat": 11.6412000656,
      "lon": 92.7296981812,
      "tz": "Asia\/Kolkata"
  },
  "VOPC": {
      "icao": "VOPC",
      "iata": "PNY",
      "name": "Pondicherry Airport",
      "city": "",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 118,
      "lat": 11.9687004089,
      "lon": 79.8100967407,
      "tz": "Asia\/Kolkata"
  },
  "VORM": {
      "icao": "VORM",
      "iata": "",
      "name": "Ramnad Naval Air Station",
      "city": "Ramnad",
      "state": "",
      "country": "IN",
      "elevation": 16,
      "lat": 9.3250904083,
      "lon": 78.971496582,
      "tz": "Asia\/Kolkata"
  },
  "VORY": {
      "icao": "VORY",
      "iata": "RJA",
      "name": "Rajahmundry Airport",
      "city": "Rajahmundry",
      "state": "Andhra-Pradesh",
      "country": "IN",
      "elevation": 151,
      "lat": 17.1103992462,
      "lon": 81.8181991577,
      "tz": "Asia\/Kolkata"
  },
  "VOSM": {
      "icao": "VOSM",
      "iata": "SXV",
      "name": "Salem Airport",
      "city": "",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 1008,
      "lat": 11.7833003998,
      "lon": 78.0655975342,
      "tz": "Asia\/Kolkata"
  },
  "VOSX": {
      "icao": "VOSX",
      "iata": "",
      "name": "Coimbatore Air Force Station",
      "city": "Sulur",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 1250,
      "lat": 11.0136003494,
      "lon": 77.1596984863,
      "tz": "Asia\/Kolkata"
  },
  "VOTJ": {
      "icao": "VOTJ",
      "iata": "TJV",
      "name": "Tanjore Air Force Base",
      "city": "Thanjavur",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 253,
      "lat": 10.7223997116,
      "lon": 79.101600647,
      "tz": "Asia\/Kolkata"
  },
  "VOTP": {
      "icao": "VOTP",
      "iata": "TIR",
      "name": "Tirupati Airport",
      "city": "Tirupati",
      "state": "Andhra-Pradesh",
      "country": "IN",
      "elevation": 350,
      "lat": 13.6324996948,
      "lon": 79.543296814,
      "tz": "Asia\/Kolkata"
  },
  "VOTR": {
      "icao": "VOTR",
      "iata": "TRZ",
      "name": "Tiruchirapally Civil Airport Airport",
      "city": "Tiruchirappally",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 288,
      "lat": 10.7653999329,
      "lon": 78.7097015381,
      "tz": "Asia\/Kolkata"
  },
  "VOTV": {
      "icao": "VOTV",
      "iata": "TRV",
      "name": "Trivandrum International Airport",
      "city": "Trivandrum",
      "state": "Kerala",
      "country": "IN",
      "elevation": 15,
      "lat": 8.4821195602,
      "lon": 76.9200973511,
      "tz": "Asia\/Kolkata"
  },
  "VOTX": {
      "icao": "VOTX",
      "iata": "",
      "name": "Tambaram Air Force Station",
      "city": "Chennai",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 90,
      "lat": 12.9071998596,
      "lon": 80.1219024658,
      "tz": "Asia\/Kolkata"
  },
  "VOVR": {
      "icao": "VOVR",
      "iata": "",
      "name": "Vellore Airport",
      "city": "Vellore",
      "state": "Tamil-Nadu",
      "country": "IN",
      "elevation": 764,
      "lat": 12.9088001251,
      "lon": 79.0668029785,
      "tz": "Asia\/Kolkata"
  },
  "VOWA": {
      "icao": "VOWA",
      "iata": "WGC",
      "name": "Warangal Airport",
      "city": "Warrangal",
      "state": "Telangana",
      "country": "IN",
      "elevation": 935,
      "lat": 17.9144001007,
      "lon": 79.6022033691,
      "tz": "Asia\/Kolkata"
  },
  "VOYK": {
      "icao": "VOYK",
      "iata": "",
      "name": "Yelahanka Air Force Station",
      "city": "Yelahanka",
      "state": "Karnataka",
      "country": "IN",
      "elevation": 3045,
      "lat": 13.1354999542,
      "lon": 77.6060028076,
      "tz": "Asia\/Kolkata"
  },
  "VQ10": {
      "icao": "VQ10",
      "iata": "",
      "name": "Yongphulla Airport",
      "city": "",
      "state": "Tashigang",
      "country": "BT",
      "elevation": 9000,
      "lat": 27.2563991547,
      "lon": 91.514503479,
      "tz": "Asia\/Thimphu"
  },
  "VQPR": {
      "icao": "VQPR",
      "iata": "PBH",
      "name": "Paro Airport",
      "city": "Paro",
      "state": "Paro",
      "country": "BT",
      "elevation": 7332,
      "lat": 27.4032001495,
      "lon": 89.4245986938,
      "tz": "Asia\/Thimphu"
  },
  "VRBK": {
      "icao": "VRBK",
      "iata": "HDK",
      "name": "Kulhudhuffushi Airport",
      "city": "Kulhudhuffushi",
      "state": "Haa Dhaalu Atoll",
      "country": "MV",
      "elevation": 3,
      "lat": 6.631111,
      "lon": 73.066667,
      "tz": "Indian\/Maldives"
  },
  "VREI": {
      "icao": "VREI",
      "iata": "IFU",
      "name": "Ifuru Airport",
      "city": "Ifuru",
      "state": "Ifuru",
      "country": "MV",
      "elevation": 6,
      "lat": 5.7083,
      "lon": 73.025,
      "tz": "Indian\/Maldives"
  },
  "VRMF": {
      "icao": "VRMF",
      "iata": "FVM",
      "name": "Fuvahmulah Airport",
      "city": "Fuvahmulah Island",
      "state": "Gnyaviyani-Atoll",
      "country": "MV",
      "elevation": 17,
      "lat": -0.3097222222,
      "lon": 73.435,
      "tz": "Indian\/Maldives"
  },
  "VRMG": {
      "icao": "VRMG",
      "iata": "GAN",
      "name": "Gan International Airport",
      "city": "Gan",
      "state": "Seenu",
      "country": "MV",
      "elevation": 6,
      "lat": -0.6933419704,
      "lon": 73.1556015015,
      "tz": "Indian\/Maldives"
  },
  "VRMH": {
      "icao": "VRMH",
      "iata": "HAQ",
      "name": "Hanimaadhoo Airport",
      "city": "Haa Dhaalu Atoll",
      "state": "Haa-Dhaalu-Atholhu",
      "country": "MV",
      "elevation": 4,
      "lat": 6.7442297935,
      "lon": 73.170501709,
      "tz": "Indian\/Maldives"
  },
  "VRMK": {
      "icao": "VRMK",
      "iata": "KDO",
      "name": "Kadhdhoo Airport",
      "city": "Kadhdhoo",
      "state": "Laamu",
      "country": "MV",
      "elevation": 4,
      "lat": 1.85916996,
      "lon": 73.5218963623,
      "tz": "Indian\/Maldives"
  },
  "VRMM": {
      "icao": "VRMM",
      "iata": "MLE",
      "name": "Male International Airport",
      "city": "Male",
      "state": "Kaafu-Atoll",
      "country": "MV",
      "elevation": 6,
      "lat": 4.1918301582,
      "lon": 73.5290985107,
      "tz": "Indian\/Maldives"
  },
  "VRMT": {
      "icao": "VRMT",
      "iata": "KDM",
      "name": "Kaadedhdhoo Airport",
      "city": "Huvadhu Atoll",
      "state": "Gaafu-Dhaalu-Atholhu",
      "country": "MV",
      "elevation": 2,
      "lat": 0.4881309867,
      "lon": 72.9969024658,
      "tz": "Indian\/Maldives"
  },
  "VRMV": {
      "icao": "VRMV",
      "iata": "VAM",
      "name": "Villa Airport",
      "city": "Maamigili",
      "state": "",
      "country": "MV",
      "elevation": 6,
      "lat": 3.4705555556,
      "lon": 72.8358333333,
      "tz": "Indian\/Maldives"
  },
  "VT01": {
      "icao": "VT01",
      "iata": "",
      "name": "Teal Farm Airport",
      "city": "Huntington",
      "state": "Vermont",
      "country": "US",
      "elevation": 1440,
      "lat": 44.2826004028,
      "lon": -72.9373016357,
      "tz": "America\/New_York"
  },
  "VT02": {
      "icao": "VT02",
      "iata": "",
      "name": "Red Fox Airport",
      "city": "Bondville",
      "state": "Vermont",
      "country": "US",
      "elevation": 1434,
      "lat": 43.1473007202,
      "lon": -72.8851013184,
      "tz": "America\/New_York"
  },
  "VT03": {
      "icao": "VT03",
      "iata": "",
      "name": "Maule's Roost Airport",
      "city": "Lincoln",
      "state": "Vermont",
      "country": "US",
      "elevation": 1100,
      "lat": 44.0886993408,
      "lon": -72.9809036255,
      "tz": "America\/New_York"
  },
  "VT06": {
      "icao": "VT06",
      "iata": "",
      "name": "Santa's Airport",
      "city": "Putney",
      "state": "Vermont",
      "country": "US",
      "elevation": 673,
      "lat": 43.0043983459,
      "lon": -72.4737014771,
      "tz": "America\/New_York"
  },
  "VT09": {
      "icao": "VT09",
      "iata": "",
      "name": "Spencer Airport",
      "city": "Addison",
      "state": "Vermont",
      "country": "US",
      "elevation": 310,
      "lat": 44.1136016846,
      "lon": -73.2902984619,
      "tz": "America\/New_York"
  },
  "VT10": {
      "icao": "VT10",
      "iata": "",
      "name": "Manning Personal Airstrip",
      "city": "Bridport",
      "state": "Vermont",
      "country": "US",
      "elevation": 255,
      "lat": 43.9813995361,
      "lon": -73.3856964111,
      "tz": "America\/New_York"
  },
  "VT11": {
      "icao": "VT11",
      "iata": "",
      "name": "Ass-Pirin Acres Airport",
      "city": "Addison",
      "state": "Vermont",
      "country": "US",
      "elevation": 130,
      "lat": 44.0686988831,
      "lon": -73.3733978271,
      "tz": "America\/New_York"
  },
  "VT12": {
      "icao": "VT12",
      "iata": "",
      "name": "E.A.Deeds Farm Airport",
      "city": "Charlotte",
      "state": "Vermont",
      "country": "US",
      "elevation": 127,
      "lat": 44.2911987305,
      "lon": -73.283203125,
      "tz": "America\/New_York"
  },
  "VT13": {
      "icao": "VT13",
      "iata": "",
      "name": "Holloway Airport",
      "city": "Chester",
      "state": "Vermont",
      "country": "US",
      "elevation": 1520,
      "lat": 43.3000984192,
      "lon": -72.7175979614,
      "tz": "America\/New_York"
  },
  "VT14": {
      "icao": "VT14",
      "iata": "",
      "name": "Axinn Airport",
      "city": "Weybridge",
      "state": "Vermont",
      "country": "US",
      "elevation": 450,
      "lat": 44.0451011658,
      "lon": -73.1895980835,
      "tz": "America\/New_York"
  },
  "VT15": {
      "icao": "VT15",
      "iata": "",
      "name": "Savage Island Airport",
      "city": "Grand Isle",
      "state": "Vermont",
      "country": "US",
      "elevation": 125,
      "lat": 44.7038993835,
      "lon": -73.2460021973,
      "tz": "America\/New_York"
  },
  "VT17": {
      "icao": "VT17",
      "iata": "",
      "name": "Smith Airport",
      "city": "Ludlow",
      "state": "Vermont",
      "country": "US",
      "elevation": 1502,
      "lat": 43.3689994812,
      "lon": -72.6743011475,
      "tz": "America\/New_York"
  },
  "VT20": {
      "icao": "VT20",
      "iata": "",
      "name": "Mach Personal Strip",
      "city": "Pawlet",
      "state": "Vermont",
      "country": "US",
      "elevation": 854,
      "lat": 43.3666992188,
      "lon": -73.1482009888,
      "tz": "America\/New_York"
  },
  "VT22": {
      "icao": "VT22",
      "iata": "",
      "name": "Shelburne Farms Airport",
      "city": "Shelburne",
      "state": "Vermont",
      "country": "US",
      "elevation": 130,
      "lat": 44.4014015198,
      "lon": -73.2670974731,
      "tz": "America\/New_York"
  },
  "VT23": {
      "icao": "VT23",
      "iata": "",
      "name": "Bostwick Farm Airport",
      "city": "Shelburne",
      "state": "Vermont",
      "country": "US",
      "elevation": 220,
      "lat": 44.3708992004,
      "lon": -73.2503967285,
      "tz": "America\/New_York"
  },
  "VT24": {
      "icao": "VT24",
      "iata": "",
      "name": "Torrey Airport",
      "city": "Shoreham",
      "state": "Vermont",
      "country": "US",
      "elevation": 280,
      "lat": 43.9319992065,
      "lon": -73.3701019287,
      "tz": "America\/New_York"
  },
  "VT25": {
      "icao": "VT25",
      "iata": "",
      "name": "Sky Acres Airport",
      "city": "South Burlington",
      "state": "Vermont",
      "country": "US",
      "elevation": 330,
      "lat": 44.4166984558,
      "lon": -73.1661987305,
      "tz": "America\/New_York"
  },
  "VT26": {
      "icao": "VT26",
      "iata": "",
      "name": "Allenholm Airport",
      "city": "South Hero",
      "state": "Vermont",
      "country": "US",
      "elevation": 140,
      "lat": 44.6344985962,
      "lon": -73.3068008423,
      "tz": "America\/New_York"
  },
  "VT29": {
      "icao": "VT29",
      "iata": "",
      "name": "Mad River Fly-In Airport",
      "city": "Waitsfield",
      "state": "Vermont",
      "country": "US",
      "elevation": 710,
      "lat": 44.226398468,
      "lon": -72.7954025269,
      "tz": "America\/New_York"
  },
  "VT30": {
      "icao": "VT30",
      "iata": "",
      "name": "Ketcham Lndg Area Airport",
      "city": "Waitsfield",
      "state": "Vermont",
      "country": "US",
      "elevation": 780,
      "lat": 44.1626014709,
      "lon": -72.8339996338,
      "tz": "America\/New_York"
  },
  "VT31": {
      "icao": "VT31",
      "iata": "",
      "name": "Carriers Skypark Airport",
      "city": "Washington",
      "state": "Vermont",
      "country": "US",
      "elevation": 1600,
      "lat": 44.1111984253,
      "lon": -72.4440002441,
      "tz": "America\/New_York"
  },
  "VT33": {
      "icao": "VT33",
      "iata": "",
      "name": "Yankee Kingdom Airport",
      "city": "West Addison",
      "state": "Vermont",
      "country": "US",
      "elevation": 200,
      "lat": 44.1055984497,
      "lon": -73.3778991699,
      "tz": "America\/New_York"
  },
  "VT34": {
      "icao": "VT34",
      "iata": "",
      "name": "Major W. Guth STOLport",
      "city": "Westminster",
      "state": "Vermont",
      "country": "US",
      "elevation": 819,
      "lat": 43.0601005554,
      "lon": -72.4919967651,
      "tz": "America\/New_York"
  },
  "VT38": {
      "icao": "VT38",
      "iata": "",
      "name": "Greenwoods Airfield",
      "city": "Alburg",
      "state": "Vermont",
      "country": "US",
      "elevation": 179,
      "lat": 45.0092010498,
      "lon": -73.2466964722,
      "tz": "America\/New_York"
  },
  "VT39": {
      "icao": "VT39",
      "iata": "",
      "name": "Taylor Airport",
      "city": "Wolcott",
      "state": "Vermont",
      "country": "US",
      "elevation": 1140,
      "lat": 44.6076011658,
      "lon": -72.4804000854,
      "tz": "America\/New_York"
  },
  "VT41": {
      "icao": "VT41",
      "iata": "",
      "name": "Moore's Field",
      "city": "Brattleboro",
      "state": "Vermont",
      "country": "US",
      "elevation": 310,
      "lat": 42.9241981506,
      "lon": -72.529800415,
      "tz": "America\/New_York"
  },
  "VT42": {
      "icao": "VT42",
      "iata": "",
      "name": "Two Tails Airport",
      "city": "Morrisville",
      "state": "Vermont",
      "country": "US",
      "elevation": 754,
      "lat": 44.5125999451,
      "lon": -72.6071014404,
      "tz": "America\/New_York"
  },
  "VT43": {
      "icao": "VT43",
      "iata": "",
      "name": "Onyon Airport",
      "city": "Townshend",
      "state": "Vermont",
      "country": "US",
      "elevation": 427,
      "lat": 43.0191993713,
      "lon": -72.6529006958,
      "tz": "America\/New_York"
  },
  "VT44": {
      "icao": "VT44",
      "iata": "",
      "name": "Perras Field",
      "city": "Morrisville",
      "state": "Vermont",
      "country": "US",
      "elevation": 820,
      "lat": 44.5266990662,
      "lon": -72.5814971924,
      "tz": "America\/New_York"
  },
  "VT45": {
      "icao": "VT45",
      "iata": "",
      "name": "Davis Private Airport",
      "city": "Jericho",
      "state": "Vermont",
      "country": "US",
      "elevation": 640,
      "lat": 44.5172996521,
      "lon": -72.9793014526,
      "tz": "America\/New_York"
  },
  "VT46": {
      "icao": "VT46",
      "iata": "",
      "name": "Northern Lights Airport",
      "city": "Alburg",
      "state": "Vermont",
      "country": "US",
      "elevation": 130,
      "lat": 44.8720016479,
      "lon": -73.2864990234,
      "tz": "America\/New_York"
  },
  "VT47": {
      "icao": "VT47",
      "iata": "",
      "name": "Miller's Pleasure Airfield",
      "city": "Vernon",
      "state": "Vermont",
      "country": "US",
      "elevation": 285,
      "lat": 42.7785987854,
      "lon": -72.5278015137,
      "tz": "America\/New_York"
  },
  "VT48": {
      "icao": "VT48",
      "iata": "",
      "name": "Fairholt Airport",
      "city": "Burlington",
      "state": "Vermont",
      "country": "US",
      "elevation": 350,
      "lat": 44.4613990784,
      "lon": -73.1959991455,
      "tz": "America\/New_York"
  },
  "VT52": {
      "icao": "VT52",
      "iata": "",
      "name": "Shaw Meadow Airport",
      "city": "Milton",
      "state": "Vermont",
      "country": "US",
      "elevation": 230,
      "lat": 44.6433982849,
      "lon": -73.1875991821,
      "tz": "America\/New_York"
  },
  "VT53": {
      "icao": "VT53",
      "iata": "",
      "name": "Staton Airport",
      "city": "Panton",
      "state": "Vermont",
      "country": "US",
      "elevation": 140,
      "lat": 44.1175994873,
      "lon": -73.3787002563,
      "tz": "America\/New_York"
  },
  "VT55": {
      "icao": "VT55",
      "iata": "",
      "name": "Brandon Airport",
      "city": "Randolph",
      "state": "Vermont",
      "country": "US",
      "elevation": 710,
      "lat": 43.9011993408,
      "lon": -72.582901001,
      "tz": "America\/New_York"
  },
  "VT56": {
      "icao": "VT56",
      "iata": "",
      "name": "West Burke Aerodrome",
      "city": "West Burke",
      "state": "Vermont",
      "country": "US",
      "elevation": 1285,
      "lat": 44.6130981445,
      "lon": -71.9809036255,
      "tz": "America\/New_York"
  },
  "VT59": {
      "icao": "VT59",
      "iata": "",
      "name": "Ardell Flying Field",
      "city": "Manchester",
      "state": "Vermont",
      "country": "US",
      "elevation": 1919,
      "lat": 43.2778015137,
      "lon": -72.9107971191,
      "tz": "America\/New_York"
  },
  "VT60": {
      "icao": "VT60",
      "iata": "",
      "name": "Hulett Landing Strip",
      "city": "West Pawlett",
      "state": "Vermont",
      "country": "US",
      "elevation": 720,
      "lat": 43.3301010132,
      "lon": -73.2425994873,
      "tz": "America\/New_York"
  },
  "VT62": {
      "icao": "VT62",
      "iata": "",
      "name": "Catamount Airfield",
      "city": "Cabot",
      "state": "Vermont",
      "country": "US",
      "elevation": 1350,
      "lat": 44.3903999329,
      "lon": -72.3506011963,
      "tz": "America\/New_York"
  },
  "VT63": {
      "icao": "VT63",
      "iata": "",
      "name": "Douglas Field",
      "city": "North Troy",
      "state": "Vermont",
      "country": "US",
      "elevation": 650,
      "lat": 44.995300293,
      "lon": -72.3789978027,
      "tz": "America\/New_York"
  },
  "VT64": {
      "icao": "VT64",
      "iata": "",
      "name": "Mountain View Farm Airport",
      "city": "Morristown",
      "state": "Vermont",
      "country": "US",
      "elevation": 1200,
      "lat": 44.5153007507,
      "lon": -72.6411972046,
      "tz": "America\/New_York"
  },
  "VT73": {
      "icao": "VT73",
      "iata": "",
      "name": "Melrose Springs Airfield",
      "city": "Bridport",
      "state": "Vermont",
      "country": "US",
      "elevation": 300,
      "lat": 43.944861,
      "lon": -73.316833,
      "tz": "America\/New_York"
  },
  "VTBD": {
      "icao": "VTBD",
      "iata": "DMK",
      "name": "Don Mueang International Airport",
      "city": "Bangkok",
      "state": "Bangkok",
      "country": "TH",
      "elevation": 9,
      "lat": 13.9125995636,
      "lon": 100.6070022583,
      "tz": "Asia\/Bangkok"
  },
  "VTBH": {
      "icao": "VTBH",
      "iata": "KKM",
      "name": "Sa Pran Nak Airport",
      "city": "",
      "state": "Lop-Buri",
      "country": "TH",
      "elevation": 95,
      "lat": 14.9493999481,
      "lon": 100.642997742,
      "tz": "Asia\/Bangkok"
  },
  "VTBI": {
      "icao": "VTBI",
      "iata": "",
      "name": "Khao E To Airport",
      "city": "",
      "state": "Prachin-Buri",
      "country": "TH",
      "elevation": 80,
      "lat": 14.0777997971,
      "lon": 101.3799972534,
      "tz": "Asia\/Bangkok"
  },
  "VTBK": {
      "icao": "VTBK",
      "iata": "KDT",
      "name": "Kamphaeng Saen Airport",
      "city": "Nakhon Pathom",
      "state": "Nakhon-Pathom",
      "country": "TH",
      "elevation": 30,
      "lat": 14.1020002365,
      "lon": 99.9171981812,
      "tz": "Asia\/Bangkok"
  },
  "VTBL": {
      "icao": "VTBL",
      "iata": "KKM",
      "name": "Khok Kathiam Airport",
      "city": "",
      "state": "Lop-Buri",
      "country": "TH",
      "elevation": 123,
      "lat": 14.8746004105,
      "lon": 100.663002014,
      "tz": "Asia\/Bangkok"
  },
  "VTBN": {
      "icao": "VTBN",
      "iata": "",
      "name": "Prachuap Khiri Khan Airport",
      "city": "Prachuap Khiri Khan",
      "state": "Prachuap-Khiri-Khan",
      "country": "TH",
      "elevation": 0,
      "lat": 12.4172000885,
      "lon": 99.8694000244,
      "tz": "Asia\/Bangkok"
  },
  "VTBO": {
      "icao": "VTBO",
      "iata": "TDX",
      "name": "Trat Airport",
      "city": "",
      "state": "Trat",
      "country": "TH",
      "elevation": 105,
      "lat": 12.274600029,
      "lon": 102.319000244,
      "tz": "Asia\/Bangkok"
  },
  "VTBP": {
      "icao": "VTBP",
      "iata": "",
      "name": "Prachuap Airport",
      "city": "",
      "state": "Prachuap-Khiri-Khan",
      "country": "TH",
      "elevation": 10,
      "lat": 11.7883996964,
      "lon": 99.8047027588,
      "tz": "Asia\/Bangkok"
  },
  "VTBS": {
      "icao": "VTBS",
      "iata": "BKK",
      "name": "Suvarnabhumi Airport",
      "city": "Bangkok",
      "state": "Samut-Prakan",
      "country": "TH",
      "elevation": 5,
      "lat": 13.6810998917,
      "lon": 100.7470016479,
      "tz": "Asia\/Bangkok"
  },
  "VTBT": {
      "icao": "VTBT",
      "iata": "",
      "name": "Bang Pra Airport",
      "city": "Bang Pra",
      "state": "Chon-Buri",
      "country": "TH",
      "elevation": 10,
      "lat": 13.2324259496,
      "lon": 100.956802368,
      "tz": "Asia\/Bangkok"
  },
  "VTBU": {
      "icao": "VTBU",
      "iata": "UTP",
      "name": "U-Tapao International Airport",
      "city": "Rayong",
      "state": "Rayong",
      "country": "TH",
      "elevation": 42,
      "lat": 12.6799001694,
      "lon": 101.0049972534,
      "tz": "Asia\/Bangkok"
  },
  "VTBW": {
      "icao": "VTBW",
      "iata": "",
      "name": "Watthana Nakhon Airport",
      "city": "",
      "state": "Sa-Kaeo",
      "country": "TH",
      "elevation": 200,
      "lat": 13.7687997818,
      "lon": 102.3150024414,
      "tz": "Asia\/Bangkok"
  },
  "VTCB": {
      "icao": "VTCB",
      "iata": "",
      "name": "Phayao Ban Chiang Kham Airport",
      "city": "Phayao Ban Chiang Kham",
      "state": "Phayao",
      "country": "TH",
      "elevation": 1272,
      "lat": 19.5,
      "lon": 100.2829971313,
      "tz": "Asia\/Bangkok"
  },
  "VTCC": {
      "icao": "VTCC",
      "iata": "CNX",
      "name": "Chiang Mai International Airport",
      "city": "Chiang Mai",
      "state": "Chiang-Mai",
      "country": "TH",
      "elevation": 1036,
      "lat": 18.7667999268,
      "lon": 98.962600708,
      "tz": "Asia\/Bangkok"
  },
  "VTCH": {
      "icao": "VTCH",
      "iata": "HGN",
      "name": "Mae Hong Son Airport",
      "city": "",
      "state": "Mae-Hong-Son",
      "country": "TH",
      "elevation": 929,
      "lat": 19.3013000488,
      "lon": 97.9757995605,
      "tz": "Asia\/Bangkok"
  },
  "VTCI": {
      "icao": "VTCI",
      "iata": "PYY",
      "name": "Mae Hong Son Airport",
      "city": "Mae Hong Son",
      "state": "Mae-Hong-Son",
      "country": "TH",
      "elevation": 1271,
      "lat": 19.3719997406,
      "lon": 98.43699646,
      "tz": "Asia\/Bangkok"
  },
  "VTCL": {
      "icao": "VTCL",
      "iata": "LPT",
      "name": "Lampang Airport",
      "city": "",
      "state": "Lampang",
      "country": "TH",
      "elevation": 811,
      "lat": 18.2709007263,
      "lon": 99.5042037964,
      "tz": "Asia\/Bangkok"
  },
  "VTCN": {
      "icao": "VTCN",
      "iata": "NNT",
      "name": "Nan Airport",
      "city": "",
      "state": "Nan",
      "country": "TH",
      "elevation": 685,
      "lat": 18.8078994751,
      "lon": 100.7829971313,
      "tz": "Asia\/Bangkok"
  },
  "VTCO": {
      "icao": "VTCO",
      "iata": "",
      "name": "Lamphun Airport",
      "city": "",
      "state": "Lamphun",
      "country": "TH",
      "elevation": 925,
      "lat": 18.546816,
      "lon": 99.012029,
      "tz": "Asia\/Bangkok"
  },
  "VTCP": {
      "icao": "VTCP",
      "iata": "PRH",
      "name": "Phrae Airport",
      "city": "",
      "state": "Phrae",
      "country": "TH",
      "elevation": 538,
      "lat": 18.1322002411,
      "lon": 100.1650009155,
      "tz": "Asia\/Bangkok"
  },
  "VTCR": {
      "icao": "VTCR",
      "iata": "",
      "name": "Chiang Rai Airport",
      "city": "",
      "state": "Chiang-Rai",
      "country": "TH",
      "elevation": 1312,
      "lat": 19.8854007721,
      "lon": 99.8267974854,
      "tz": "Asia\/Bangkok"
  },
  "VTCS": {
      "icao": "VTCS",
      "iata": "",
      "name": "Mae Sariang Airport",
      "city": "Mae Sariang",
      "state": "Mae-Hong-Son",
      "country": "TH",
      "elevation": 1030,
      "lat": 18.1830005646,
      "lon": 97.9329986572,
      "tz": "Asia\/Bangkok"
  },
  "VTCT": {
      "icao": "VTCT",
      "iata": "CEI",
      "name": "Chiang Rai International Airport",
      "city": "Chiang Rai",
      "state": "Chiang-Rai",
      "country": "TH",
      "elevation": 1280,
      "lat": 19.952299118,
      "lon": 99.8828964233,
      "tz": "Asia\/Bangkok"
  },
  "VTED": {
      "icao": "VTED",
      "iata": "BAO",
      "name": "Udorn Air Base",
      "city": "Ban Mak Khaen",
      "state": "Changwat-Udon-Thani",
      "country": "TH",
      "elevation": 0,
      "lat": 17.3829994202,
      "lon": 102.8000030518,
      "tz": "Asia\/Bangkok"
  },
  "VTPB": {
      "icao": "VTPB",
      "iata": "PHY",
      "name": "Phetchabun Airport",
      "city": "",
      "state": "Phetchabun",
      "country": "TH",
      "elevation": 450,
      "lat": 16.6760005951,
      "lon": 101.194999695,
      "tz": "Asia\/Bangkok"
  },
  "VTPH": {
      "icao": "VTPH",
      "iata": "HHQ",
      "name": "Hua Hin Airport",
      "city": "Hua Hin",
      "state": "Phetchaburi",
      "country": "TH",
      "elevation": 62,
      "lat": 12.6361999512,
      "lon": 99.951499939,
      "tz": "Asia\/Bangkok"
  },
  "VTPI": {
      "icao": "VTPI",
      "iata": "TKH",
      "name": "Takhli Airport",
      "city": "",
      "state": "Nakhon-Sawan",
      "country": "TH",
      "elevation": 107,
      "lat": 15.277299881,
      "lon": 100.29599762,
      "tz": "Asia\/Bangkok"
  },
  "VTPL": {
      "icao": "VTPL",
      "iata": "",
      "name": "Sak Long Airport",
      "city": "",
      "state": "Phetchabun",
      "country": "TH",
      "elevation": 500,
      "lat": 16.824300766,
      "lon": 101.2509994507,
      "tz": "Asia\/Bangkok"
  },
  "VTPM": {
      "icao": "VTPM",
      "iata": "MAQ",
      "name": "Mae Sot Airport",
      "city": "",
      "state": "Tak",
      "country": "TH",
      "elevation": 690,
      "lat": 16.6998996735,
      "lon": 98.5450973511,
      "tz": "Asia\/Bangkok"
  },
  "VTPN": {
      "icao": "VTPN",
      "iata": "",
      "name": "Nakhon Sawan Airport",
      "city": "",
      "state": "Nakhon-Sawan",
      "country": "TH",
      "elevation": 113,
      "lat": 15.6730003357,
      "lon": 100.1370010376,
      "tz": "Asia\/Bangkok"
  },
  "VTPO": {
      "icao": "VTPO",
      "iata": "THS",
      "name": "Sukhothai Airport",
      "city": "",
      "state": "Sukhothai",
      "country": "TH",
      "elevation": 179,
      "lat": 17.2380008698,
      "lon": 99.8181991577,
      "tz": "Asia\/Bangkok"
  },
  "VTPP": {
      "icao": "VTPP",
      "iata": "PHS",
      "name": "Phitsanulok Airport",
      "city": "",
      "state": "Phitsanulok",
      "country": "TH",
      "elevation": 154,
      "lat": 16.7828998566,
      "lon": 100.2789993286,
      "tz": "Asia\/Bangkok"
  },
  "VTPR": {
      "icao": "VTPR",
      "iata": "",
      "name": "Photharam Airport",
      "city": "Photharam",
      "state": "Ratchaburi",
      "country": "TH",
      "elevation": 88,
      "lat": 13.6686000824,
      "lon": 99.7313995361,
      "tz": "Asia\/Bangkok"
  },
  "VTPT": {
      "icao": "VTPT",
      "iata": "TKT",
      "name": "Tak Airport",
      "city": "",
      "state": "Tak",
      "country": "TH",
      "elevation": 478,
      "lat": 16.8959999084,
      "lon": 99.2533035278,
      "tz": "Asia\/Bangkok"
  },
  "VTPU": {
      "icao": "VTPU",
      "iata": "UTR",
      "name": "Uttaradit Airport",
      "city": "Uttaradit",
      "state": "Uttaradit",
      "country": "TH",
      "elevation": 262,
      "lat": 17.6170005798,
      "lon": 100.0999984741,
      "tz": "Asia\/Bangkok"
  },
  "VTPY": {
      "icao": "VTPY",
      "iata": "",
      "name": "Khunan Phumipol Airport",
      "city": "",
      "state": "Tak",
      "country": "TH",
      "elevation": 492,
      "lat": 17.2341995239,
      "lon": 99.0578994751,
      "tz": "Asia\/Bangkok"
  },
  "VTSA": {
      "icao": "VTSA",
      "iata": "",
      "name": "Khoun Khan Airport",
      "city": "",
      "state": "Satun",
      "country": "TH",
      "elevation": 18,
      "lat": 6.6613998413,
      "lon": 100.0800018311,
      "tz": "Asia\/Bangkok"
  },
  "VTSB": {
      "icao": "VTSB",
      "iata": "URT",
      "name": "Surat Thani Airport",
      "city": "Surat Thani",
      "state": "Surat-Thani",
      "country": "TH",
      "elevation": 20,
      "lat": 9.1325998306,
      "lon": 99.135597229,
      "tz": "Asia\/Bangkok"
  },
  "VTSC": {
      "icao": "VTSC",
      "iata": "NAW",
      "name": "Narathiwat Airport",
      "city": "",
      "state": "Narathiwat",
      "country": "TH",
      "elevation": 16,
      "lat": 6.5199198723,
      "lon": 101.7429962158,
      "tz": "Asia\/Bangkok"
  },
  "VTSE": {
      "icao": "VTSE",
      "iata": "CJM",
      "name": "Chumphon Airport",
      "city": "",
      "state": "Chumphon",
      "country": "TH",
      "elevation": 18,
      "lat": 10.7111997604,
      "lon": 99.3617019653,
      "tz": "Asia\/Bangkok"
  },
  "VTSF": {
      "icao": "VTSF",
      "iata": "NST",
      "name": "Nakhon Si Thammarat Airport",
      "city": "Nakhon Si Thammarat",
      "state": "Nakhon-Si-Thammarat",
      "country": "TH",
      "elevation": 13,
      "lat": 8.5396203995,
      "lon": 99.9447021484,
      "tz": "Asia\/Bangkok"
  },
  "VTSG": {
      "icao": "VTSG",
      "iata": "KBV",
      "name": "Krabi Airport",
      "city": "Krabi",
      "state": "Krabi",
      "country": "TH",
      "elevation": 82,
      "lat": 8.0991201401,
      "lon": 98.9861984253,
      "tz": "Asia\/Bangkok"
  },
  "VTSH": {
      "icao": "VTSH",
      "iata": "SGZ",
      "name": "Songkhla Airport",
      "city": "",
      "state": "Songkhla",
      "country": "TH",
      "elevation": 12,
      "lat": 7.186560154,
      "lon": 100.608001709,
      "tz": "Asia\/Bangkok"
  },
  "VTSK": {
      "icao": "VTSK",
      "iata": "PAN",
      "name": "Pattani Airport",
      "city": "",
      "state": "Pattani",
      "country": "TH",
      "elevation": 8,
      "lat": 6.7854599953,
      "lon": 101.1539993286,
      "tz": "Asia\/Bangkok"
  },
  "VTSM": {
      "icao": "VTSM",
      "iata": "USM",
      "name": "Samui Airport",
      "city": "Na Thon (Ko Samui Island)",
      "state": "Nakhon-Si-Thammarat",
      "country": "TH",
      "elevation": 64,
      "lat": 9.5477895737,
      "lon": 100.06199646,
      "tz": "Asia\/Bangkok"
  },
  "VTSN": {
      "icao": "VTSN",
      "iata": "",
      "name": "Cha Ian Airport",
      "city": "",
      "state": "Nakhon-Si-Thammarat",
      "country": "TH",
      "elevation": 44,
      "lat": 8.4711503983,
      "lon": 99.9555969238,
      "tz": "Asia\/Bangkok"
  },
  "VTSP": {
      "icao": "VTSP",
      "iata": "HKT",
      "name": "Phuket International Airport",
      "city": "Phuket",
      "state": "Phuket",
      "country": "TH",
      "elevation": 82,
      "lat": 8.1132001877,
      "lon": 98.3169021606,
      "tz": "Asia\/Bangkok"
  },
  "VTSR": {
      "icao": "VTSR",
      "iata": "UNN",
      "name": "Ranong Airport",
      "city": "",
      "state": "Ranong",
      "country": "TH",
      "elevation": 57,
      "lat": 9.7776203156,
      "lon": 98.5855026245,
      "tz": "Asia\/Bangkok"
  },
  "VTSS": {
      "icao": "VTSS",
      "iata": "HDY",
      "name": "Hat Yai International Airport",
      "city": "Hat Yai",
      "state": "Songkhla",
      "country": "TH",
      "elevation": 90,
      "lat": 6.9332098961,
      "lon": 100.392997742,
      "tz": "Asia\/Bangkok"
  },
  "VTST": {
      "icao": "VTST",
      "iata": "TST",
      "name": "Trang Airport",
      "city": "",
      "state": "Trang",
      "country": "TH",
      "elevation": 67,
      "lat": 7.5087399483,
      "lon": 99.6166000366,
      "tz": "Asia\/Bangkok"
  },
  "VTUD": {
      "icao": "VTUD",
      "iata": "UTH",
      "name": "Udon Thani Airport",
      "city": "Udon Thani",
      "state": "Changwat-Udon-Thani",
      "country": "TH",
      "elevation": 579,
      "lat": 17.3864002228,
      "lon": 102.788002014,
      "tz": "Asia\/Bangkok"
  },
  "VTUI": {
      "icao": "VTUI",
      "iata": "SNO",
      "name": "Sakon Nakhon Airport",
      "city": "",
      "state": "Sakon-Nakhon",
      "country": "TH",
      "elevation": 529,
      "lat": 17.1951007843,
      "lon": 104.1190032959,
      "tz": "Asia\/Bangkok"
  },
  "VTUJ": {
      "icao": "VTUJ",
      "iata": "PXR",
      "name": "Surin Airport",
      "city": "",
      "state": "Surin",
      "country": "TH",
      "elevation": 478,
      "lat": 14.8683004379,
      "lon": 103.4980010986,
      "tz": "Asia\/Bangkok"
  },
  "VTUK": {
      "icao": "VTUK",
      "iata": "KKC",
      "name": "Khon Kaen Airport",
      "city": "Khon Kaen",
      "state": "Khon-Kaen",
      "country": "TH",
      "elevation": 670,
      "lat": 16.4666004181,
      "lon": 102.783996582,
      "tz": "Asia\/Bangkok"
  },
  "VTUL": {
      "icao": "VTUL",
      "iata": "LOE",
      "name": "Loei Airport",
      "city": "",
      "state": "Loei",
      "country": "TH",
      "elevation": 860,
      "lat": 17.4391002655,
      "lon": 101.7220001221,
      "tz": "Asia\/Bangkok"
  },
  "VTUN": {
      "icao": "VTUN",
      "iata": "",
      "name": "Khorat Airport",
      "city": "",
      "state": "Nakhon-Ratchasima",
      "country": "TH",
      "elevation": 729,
      "lat": 14.9344997406,
      "lon": 102.07900238,
      "tz": "Asia\/Bangkok"
  },
  "VTUO": {
      "icao": "VTUO",
      "iata": "BFV",
      "name": "Buri Ram Airport",
      "city": "",
      "state": "Buriram",
      "country": "TH",
      "elevation": 590,
      "lat": 15.2294998169,
      "lon": 103.2529983521,
      "tz": "Asia\/Bangkok"
  },
  "VTUQ": {
      "icao": "VTUQ",
      "iata": "NAK",
      "name": "Nakhon Ratchasima Airport",
      "city": "",
      "state": "Nakhon-Ratchasima",
      "country": "TH",
      "elevation": 765,
      "lat": 14.9495000839,
      "lon": 102.31300354,
      "tz": "Asia\/Bangkok"
  },
  "VTUR": {
      "icao": "VTUR",
      "iata": "",
      "name": "Rob Muang Airport",
      "city": "",
      "state": "Roi-Et",
      "country": "TH",
      "elevation": 459,
      "lat": 16.070400238,
      "lon": 103.6460037231,
      "tz": "Asia\/Bangkok"
  },
  "VTUU": {
      "icao": "VTUU",
      "iata": "UBP",
      "name": "Ubon Ratchathani Airport",
      "city": "Ubon Ratchathani",
      "state": "Changwat-Ubon-Ratchathani",
      "country": "TH",
      "elevation": 406,
      "lat": 15.2512998581,
      "lon": 104.870002747,
      "tz": "Asia\/Bangkok"
  },
  "VTUV": {
      "icao": "VTUV",
      "iata": "ROI",
      "name": "Roi Et Airport",
      "city": "",
      "state": "Roi-Et",
      "country": "TH",
      "elevation": 451,
      "lat": 16.1168003082,
      "lon": 103.7740020752,
      "tz": "Asia\/Bangkok"
  },
  "VTUW": {
      "icao": "VTUW",
      "iata": "KOP",
      "name": "Nakhon Phanom Airport",
      "city": "",
      "state": "Nakhon-Phanom",
      "country": "TH",
      "elevation": 587,
      "lat": 17.3838005066,
      "lon": 104.6429977417,
      "tz": "Asia\/Bangkok"
  },
  "VTUZ": {
      "icao": "VTUZ",
      "iata": "",
      "name": "Nam Phung Dam Airport",
      "city": "Khon Kaen",
      "state": "Khon-Kaen",
      "country": "TH",
      "elevation": 0,
      "lat": 16.6522006989,
      "lon": 102.9670028687,
      "tz": "Asia\/Bangkok"
  },
  "VVBM": {
      "icao": "VVBM",
      "iata": "BMV",
      "name": "Buon Ma Thuot Airport",
      "city": "Buon Ma Thuot",
      "state": "Ha-N\u1ed9i",
      "country": "VN",
      "elevation": 1729,
      "lat": 12.668299675,
      "lon": 108.120002747,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVCA": {
      "icao": "VVCA",
      "iata": "VCL",
      "name": "Chu Lai International Airport",
      "city": "Dung Quat Bay",
      "state": "",
      "country": "VN",
      "elevation": 10,
      "lat": 15.4033002853,
      "lon": 108.706001282,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVCI": {
      "icao": "VVCI",
      "iata": "HPH",
      "name": "Cat Bi International Airport",
      "city": "Haiphong",
      "state": "H\u1ea3i-Phong",
      "country": "VN",
      "elevation": 6,
      "lat": 20.8194007874,
      "lon": 106.7249984741,
      "tz": "Asia\/Bangkok"
  },
  "VVCL": {
      "icao": "VVCL",
      "iata": "",
      "name": "Cam Ly Airport",
      "city": "",
      "state": "Lam-D\u1ed3ng",
      "country": "VN",
      "elevation": 4937,
      "lat": 11.9502844865,
      "lon": 108.411004543,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVCM": {
      "icao": "VVCM",
      "iata": "CAH",
      "name": "Ca Mau Airport",
      "city": "Ca Mau City",
      "state": "C\u1ea7n-Th\u01a1",
      "country": "VN",
      "elevation": 6,
      "lat": 9.1776666667,
      "lon": 105.177777778,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVCR": {
      "icao": "VVCR",
      "iata": "CXR",
      "name": "Cam Ranh Airport",
      "city": "Nha Trang",
      "state": "Khanh-Hoa",
      "country": "VN",
      "elevation": 40,
      "lat": 11.9982004166,
      "lon": 109.21900177,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVCS": {
      "icao": "VVCS",
      "iata": "VCS",
      "name": "Co Ong Airport",
      "city": "Con Ong",
      "state": "Ba-R\u1ecba-Vung-Tau",
      "country": "VN",
      "elevation": 20,
      "lat": 8.7318296433,
      "lon": 106.633003235,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVCT": {
      "icao": "VVCT",
      "iata": "VCA",
      "name": "Tra Noc Airport",
      "city": "Can Tho",
      "state": "Lao-Cai",
      "country": "VN",
      "elevation": 9,
      "lat": 10.085100174,
      "lon": 105.7119979858,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVDB": {
      "icao": "VVDB",
      "iata": "DIN",
      "name": "Dien Bien Phu Airport",
      "city": "Dien Bien Phu",
      "state": "Lai-Chau",
      "country": "VN",
      "elevation": 1611,
      "lat": 21.3974990845,
      "lon": 103.008003235,
      "tz": "Asia\/Bangkok"
  },
  "VVDH": {
      "icao": "VVDH",
      "iata": "VDH",
      "name": "Dong Hoi Airport",
      "city": "Dong Hoi",
      "state": "Yen-Bai",
      "country": "VN",
      "elevation": 59,
      "lat": 17.515,
      "lon": 106.590556,
      "tz": "Asia\/Bangkok"
  },
  "VVDL": {
      "icao": "VVDL",
      "iata": "DLI",
      "name": "Lien Khuong Airport",
      "city": "Dalat",
      "state": "Lam-D\u1ed3ng",
      "country": "VN",
      "elevation": 3156,
      "lat": 11.75,
      "lon": 108.3669967651,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVDN": {
      "icao": "VVDN",
      "iata": "DAD",
      "name": "Da Nang International Airport",
      "city": "Da Nang",
      "state": "Da-N\u1eb5ng",
      "country": "VN",
      "elevation": 33,
      "lat": 16.0438995361,
      "lon": 108.1989974976,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVGL": {
      "icao": "VVGL",
      "iata": "",
      "name": "Gia Lam Air Base",
      "city": "Hanoi",
      "state": "Ha-N\u1ed9i",
      "country": "VN",
      "elevation": 50,
      "lat": 21.0405006409,
      "lon": 105.8860015869,
      "tz": "Asia\/Bangkok"
  },
  "VVKP": {
      "icao": "VVKP",
      "iata": "",
      "name": "Kep Air Base",
      "city": "Kep",
      "state": "B\u1eafc-Giang",
      "country": "VN",
      "elevation": 55,
      "lat": 21.3945999146,
      "lon": 106.261001587,
      "tz": "Asia\/Bangkok"
  },
  "VVNB": {
      "icao": "VVNB",
      "iata": "HAN",
      "name": "Noi Bai International Airport",
      "city": "Hanoi",
      "state": "Ha-N\u1ed9i",
      "country": "VN",
      "elevation": 39,
      "lat": 21.221200943,
      "lon": 105.8069992065,
      "tz": "Asia\/Bangkok"
  },
  "VVNS": {
      "icao": "VVNS",
      "iata": "SQH",
      "name": "Na-San Airport",
      "city": "Son-La",
      "state": "S\u01a1n-La",
      "country": "VN",
      "elevation": 2133,
      "lat": 21.216999054,
      "lon": 104.0329971313,
      "tz": "Asia\/Bangkok"
  },
  "VVNT": {
      "icao": "VVNT",
      "iata": "NHA",
      "name": "Nha Trang Air Base",
      "city": "Nha Trang",
      "state": "Khanh-Hoa",
      "country": "VN",
      "elevation": 20,
      "lat": 12.2274999619,
      "lon": 109.192001343,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVPB": {
      "icao": "VVPB",
      "iata": "HUI",
      "name": "Phu Bai Airport",
      "city": "Hue",
      "state": "B\u1eafc-Ninh",
      "country": "VN",
      "elevation": 48,
      "lat": 16.4015007019,
      "lon": 107.70300293,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVPC": {
      "icao": "VVPC",
      "iata": "UIH",
      "name": "Phu Cat Airport",
      "city": "Quy Nohn",
      "state": "Khanh-Hoa",
      "country": "VN",
      "elevation": 80,
      "lat": 13.9549999237,
      "lon": 109.041999817,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVPK": {
      "icao": "VVPK",
      "iata": "PXU",
      "name": "Pleiku Airport",
      "city": "Pleiku",
      "state": "",
      "country": "VN",
      "elevation": 2434,
      "lat": 14.0045003891,
      "lon": 108.016998291,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVPQ": {
      "icao": "VVPQ",
      "iata": "PQC",
      "name": "Phu Quoc Airport",
      "city": "Duong Dong",
      "state": "Binh-Thu\u1eadn",
      "country": "VN",
      "elevation": 23,
      "lat": 10.2270002365,
      "lon": 103.967002869,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVPR": {
      "icao": "VVPR",
      "iata": "PHA",
      "name": "Phan Rang Airport",
      "city": "Phan Rang",
      "state": "Tuyen-Quang",
      "country": "VN",
      "elevation": 101,
      "lat": 11.6335000992,
      "lon": 108.952003479,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVPT": {
      "icao": "VVPT",
      "iata": "PHH",
      "name": "Phan Thiet Airport",
      "city": "Phan Thiet",
      "state": "Binh-Thu\u1eadn",
      "country": "VN",
      "elevation": 0,
      "lat": 10.9063997269,
      "lon": 108.0690002441,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVRG": {
      "icao": "VVRG",
      "iata": "VKG",
      "name": "Rach Gia Airport",
      "city": "Rach Gia",
      "state": "Binh-Thu\u1eadn",
      "country": "VN",
      "elevation": 7,
      "lat": 9.9580299723,
      "lon": 105.132379532,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVTH": {
      "icao": "VVTH",
      "iata": "TBB",
      "name": "Dong Tac Airport",
      "city": "Tuy Hoa",
      "state": "Vinh-Long",
      "country": "VN",
      "elevation": 20,
      "lat": 13.0495996475,
      "lon": 109.333999634,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVTS": {
      "icao": "VVTS",
      "iata": "SGN",
      "name": "Tan Son Nhat International Airport",
      "city": "Ho Chi Minh City",
      "state": "Ho-Chi-Minh-City",
      "country": "VN",
      "elevation": 33,
      "lat": 10.8187999725,
      "lon": 106.652000427,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VVTX": {
      "icao": "VVTX",
      "iata": "THD",
      "name": "Th\u1ecd Xu\u00e2n Airport",
      "city": "Thanh H\u00f3a",
      "state": "",
      "country": "VN",
      "elevation": 59,
      "lat": 19.901667,
      "lon": 105.467778,
      "tz": "Asia\/Bangkok"
  },
  "VVVH": {
      "icao": "VVVH",
      "iata": "VII",
      "name": "Vinh Airport",
      "city": "Vinh",
      "state": "Ngh\u1ec7-An",
      "country": "VN",
      "elevation": 23,
      "lat": 18.7376003265,
      "lon": 105.67099762,
      "tz": "Asia\/Bangkok"
  },
  "VVVT": {
      "icao": "VVVT",
      "iata": "VTG",
      "name": "Vung Tau Airport",
      "city": "Vung Tau",
      "state": "Ba-R\u1ecba-Vung-Tau",
      "country": "VN",
      "elevation": 13,
      "lat": 10.3725004196,
      "lon": 107.0950012207,
      "tz": "Asia\/Ho_Chi_Minh"
  },
  "VY02": {
      "icao": "VY02",
      "iata": "",
      "name": "New Magway Airport",
      "city": "Magway",
      "state": "Magway",
      "country": "MM",
      "elevation": 279,
      "lat": 20.1536006927,
      "lon": 94.9686965942,
      "tz": "Asia\/Yangon"
  },
  "VY03": {
      "icao": "VY03",
      "iata": "",
      "name": "Toungkomei Airport",
      "city": "Taungkamet",
      "state": "Tanintharyi",
      "country": "MM",
      "elevation": 92,
      "lat": 11.1493997574,
      "lon": 98.7359008789,
      "tz": "Asia\/Yangon"
  },
  "VYAN": {
      "icao": "VYAN",
      "iata": "VBA",
      "name": "Ann Airport",
      "city": "Aeng",
      "state": "Rakhine",
      "country": "MM",
      "elevation": 74,
      "lat": 19.7691993713,
      "lon": 94.0261001587,
      "tz": "Asia\/Yangon"
  },
  "VYAS": {
      "icao": "VYAS",
      "iata": "",
      "name": "Anisakan Airport",
      "city": "Anisakan",
      "state": "Mandalay",
      "country": "MM",
      "elevation": 3000,
      "lat": 21.9554004669,
      "lon": 96.4060974121,
      "tz": "Asia\/Yangon"
  },
  "VYBG": {
      "icao": "VYBG",
      "iata": "NYU",
      "name": "Bagan Airport",
      "city": "Nyaung U",
      "state": "Mandalay",
      "country": "MM",
      "elevation": 312,
      "lat": 21.1788005829,
      "lon": 94.9301986694,
      "tz": "Asia\/Yangon"
  },
  "VYBM": {
      "icao": "VYBM",
      "iata": "BMO",
      "name": "Banmaw Airport",
      "city": "Banmaw",
      "state": "Kachin",
      "country": "MM",
      "elevation": 370,
      "lat": 24.2689990997,
      "lon": 97.2462005615,
      "tz": "Asia\/Yangon"
  },
  "VYBP": {
      "icao": "VYBP",
      "iata": "VBP",
      "name": "Bokpyinn Airport",
      "city": "Bokpyinn",
      "state": "Tanintharyi",
      "country": "MM",
      "elevation": 100,
      "lat": 11.2670001984,
      "lon": 98.766998291,
      "tz": "Asia\/Yangon"
  },
  "VYCI": {
      "icao": "VYCI",
      "iata": "",
      "name": "Coco Island Airport",
      "city": "Coco Island",
      "state": "Yangon",
      "country": "MM",
      "elevation": 20,
      "lat": 14.141500473,
      "lon": 93.3684997559,
      "tz": "Asia\/Yangon"
  },
  "VYCZ": {
      "icao": "VYCZ",
      "iata": "VBC",
      "name": "Chanmyathazi Airport",
      "city": "Mandalay",
      "state": "Mandalay",
      "country": "MM",
      "elevation": 252,
      "lat": 21.9405002594,
      "lon": 96.0895996094,
      "tz": "Asia\/Yangon"
  },
  "VYDW": {
      "icao": "VYDW",
      "iata": "TVY",
      "name": "Dawei Airport",
      "city": "Dawei",
      "state": "Tanintharyi",
      "country": "MM",
      "elevation": 84,
      "lat": 14.1038999557,
      "lon": 98.2035980225,
      "tz": "Asia\/Yangon"
  },
  "VYEL": {
      "icao": "VYEL",
      "iata": "NYT",
      "name": "Naypyidaw Airport",
      "city": "Pyinmana",
      "state": "Mandalay",
      "country": "MM",
      "elevation": 302,
      "lat": 19.623500824,
      "lon": 96.2009963989,
      "tz": "Asia\/Yangon"
  },
  "VYGG": {
      "icao": "VYGG",
      "iata": "GAW",
      "name": "Gangaw Airport",
      "city": "Gangaw",
      "state": "Magway",
      "country": "MM",
      "elevation": 200,
      "lat": 22.1746997833,
      "lon": 94.1343994141,
      "tz": "Asia\/Yangon"
  },
  "VYGW": {
      "icao": "VYGW",
      "iata": "GWA",
      "name": "Gwa Airport",
      "city": "Gwa",
      "state": "Rakhine",
      "country": "MM",
      "elevation": 10,
      "lat": 17.6000003815,
      "lon": 94.5832977295,
      "tz": "Asia\/Yangon"
  },
  "VYHB": {
      "icao": "VYHB",
      "iata": "",
      "name": "Hmawbi Air Base",
      "city": "Hmawbi",
      "state": "Yangon",
      "country": "MM",
      "elevation": 69,
      "lat": 17.1166992188,
      "lon": 96.0667037964,
      "tz": "Asia\/Yangon"
  },
  "VYHH": {
      "icao": "VYHH",
      "iata": "HEH",
      "name": "Heho Airport",
      "city": "Heho",
      "state": "Shan",
      "country": "MM",
      "elevation": 3858,
      "lat": 20.7469997406,
      "lon": 96.7919998169,
      "tz": "Asia\/Yangon"
  },
  "VYHL": {
      "icao": "VYHL",
      "iata": "HOX",
      "name": "Hommalinn Airport",
      "city": "Hommalinn",
      "state": "Sagain",
      "country": "MM",
      "elevation": 534,
      "lat": 24.8995990753,
      "lon": 94.9140014648,
      "tz": "Asia\/Yangon"
  },
  "VYHN": {
      "icao": "VYHN",
      "iata": "TIO",
      "name": "Tilin Airport",
      "city": "Tilin",
      "state": "Magway",
      "country": "MM",
      "elevation": 1385,
      "lat": 21.7000007629,
      "lon": 94.0999984741,
      "tz": "Asia\/Yangon"
  },
  "VYKG": {
      "icao": "VYKG",
      "iata": "KET",
      "name": "Kengtung Airport",
      "city": "Kengtung",
      "state": "Shan",
      "country": "MM",
      "elevation": 2798,
      "lat": 21.3015995026,
      "lon": 99.6360015869,
      "tz": "Asia\/Yangon"
  },
  "VYKI": {
      "icao": "VYKI",
      "iata": "KHM",
      "name": "Kanti Airport",
      "city": "Kanti",
      "state": "Sagain",
      "country": "MM",
      "elevation": 6000,
      "lat": 25.9883003235,
      "lon": 95.6744003296,
      "tz": "Asia\/Yangon"
  },
  "VYKL": {
      "icao": "VYKL",
      "iata": "KMV",
      "name": "Kalay Airport",
      "city": "Kalemyo",
      "state": "Sagain",
      "country": "MM",
      "elevation": 499,
      "lat": 23.1888008118,
      "lon": 94.0511016846,
      "tz": "Asia\/Yangon"
  },
  "VYKP": {
      "icao": "VYKP",
      "iata": "KYP",
      "name": "Kyaukpyu Airport",
      "city": "Kyaukpyu",
      "state": "Rakhine",
      "country": "MM",
      "elevation": 20,
      "lat": 19.426399231,
      "lon": 93.5347976685,
      "tz": "Asia\/Yangon"
  },
  "VYKT": {
      "icao": "VYKT",
      "iata": "KAW",
      "name": "Kawthoung Airport",
      "city": "Kawthoung",
      "state": "Tanintharyi",
      "country": "MM",
      "elevation": 180,
      "lat": 10.0493001938,
      "lon": 98.5380020142,
      "tz": "Asia\/Yangon"
  },
  "VYKU": {
      "icao": "VYKU",
      "iata": "KYT",
      "name": "Kyauktu Airport",
      "city": "Kyauktu",
      "state": "Magway",
      "country": "MM",
      "elevation": 1250,
      "lat": 21.3999996185,
      "lon": 94.1333007813,
      "tz": "Asia\/Yangon"
  },
  "VYLK": {
      "icao": "VYLK",
      "iata": "LIW",
      "name": "Loikaw Airport",
      "city": "Loikaw",
      "state": "Kayah",
      "country": "MM",
      "elevation": 2940,
      "lat": 19.6914997101,
      "lon": 97.2147979736,
      "tz": "Asia\/Yangon"
  },
  "VYLN": {
      "icao": "VYLN",
      "iata": "",
      "name": "Lonekin Airport",
      "city": "Lonekin",
      "state": "Kachin",
      "country": "MM",
      "elevation": 1010,
      "lat": 25.6499996185,
      "lon": 96.3669967651,
      "tz": "Asia\/Yangon"
  },
  "VYLS": {
      "icao": "VYLS",
      "iata": "LSH",
      "name": "Lashio Airport",
      "city": "Lashio",
      "state": "Shan",
      "country": "MM",
      "elevation": 2450,
      "lat": 22.9778995514,
      "lon": 97.7521972656,
      "tz": "Asia\/Yangon"
  },
  "VYLY": {
      "icao": "VYLY",
      "iata": "",
      "name": "Lanywa Airport",
      "city": "Lanywa",
      "state": "Magway",
      "country": "MM",
      "elevation": 175,
      "lat": 20.9403991699,
      "lon": 94.8226013184,
      "tz": "Asia\/Yangon"
  },
  "VYMD": {
      "icao": "VYMD",
      "iata": "MDL",
      "name": "Mandalay International Airport",
      "city": "Mandalay",
      "state": "Mandalay",
      "country": "MM",
      "elevation": 300,
      "lat": 21.7021999359,
      "lon": 95.977897644,
      "tz": "Asia\/Yangon"
  },
  "VYME": {
      "icao": "VYME",
      "iata": "MGZ",
      "name": "Myeik Airport",
      "city": "Mkeik",
      "state": "Tanintharyi",
      "country": "MM",
      "elevation": 75,
      "lat": 12.4398002625,
      "lon": 98.6214981079,
      "tz": "Asia\/Yangon"
  },
  "VYMH": {
      "icao": "VYMH",
      "iata": "",
      "name": "Mong Hpayak Airport",
      "city": "Mong Hpayak",
      "state": "Shan",
      "country": "MM",
      "elevation": 1700,
      "lat": 20.8330001831,
      "lon": 99.9499969482,
      "tz": "Asia\/Yangon"
  },
  "VYMI": {
      "icao": "VYMI",
      "iata": "",
      "name": "Mongyai Airport",
      "city": "Mongyai",
      "state": "Shan",
      "country": "MM",
      "elevation": 2900,
      "lat": 22.4829998016,
      "lon": 98.0329971313,
      "tz": "Asia\/Yangon"
  },
  "VYMK": {
      "icao": "VYMK",
      "iata": "MYT",
      "name": "Myitkyina Airport",
      "city": "Myitkyina",
      "state": "Kachin",
      "country": "MM",
      "elevation": 475,
      "lat": 25.383600235,
      "lon": 97.3518981934,
      "tz": "Asia\/Yangon"
  },
  "VYML": {
      "icao": "VYML",
      "iata": "",
      "name": "Meiktila Air Base",
      "city": "Meiktila",
      "state": "Mandalay",
      "country": "MM",
      "elevation": 699,
      "lat": 20.8864002228,
      "lon": 95.892791748,
      "tz": "Asia\/Yangon"
  },
  "VYMM": {
      "icao": "VYMM",
      "iata": "MNU",
      "name": "Mawlamyine Airport",
      "city": "Mawlamyine",
      "state": "Mon",
      "country": "MM",
      "elevation": 52,
      "lat": 16.4447002411,
      "lon": 97.660697937,
      "tz": "Asia\/Yangon"
  },
  "VYMN": {
      "icao": "VYMN",
      "iata": "MGU",
      "name": "Manaung Airport",
      "city": "Manaung",
      "state": "Rakhine",
      "country": "MM",
      "elevation": 102,
      "lat": 18.8458003998,
      "lon": 93.6889038086,
      "tz": "Asia\/Yangon"
  },
  "VYMO": {
      "icao": "VYMO",
      "iata": "MOE",
      "name": "Momeik Airport",
      "city": "",
      "state": "Shan",
      "country": "MM",
      "elevation": 600,
      "lat": 23.0925006866,
      "lon": 96.6453018188,
      "tz": "Asia\/Yangon"
  },
  "VYMP": {
      "icao": "VYMP",
      "iata": "",
      "name": "Mongpyin Airport",
      "city": "Mongpyin",
      "state": "Shan",
      "country": "MM",
      "elevation": 1516,
      "lat": 21.3500003815,
      "lon": 99.0329971313,
      "tz": "Asia\/Yangon"
  },
  "VYMS": {
      "icao": "VYMS",
      "iata": "MOG",
      "name": "Mong Hsat Airport",
      "city": "Mong Hsat",
      "state": "Shan",
      "country": "MM",
      "elevation": 1875,
      "lat": 20.5167999268,
      "lon": 99.2567977905,
      "tz": "Asia\/Yangon"
  },
  "VYMT": {
      "icao": "VYMT",
      "iata": "MGK",
      "name": "Mong Tong Airport",
      "city": "Mong Tong",
      "state": "Shan",
      "country": "MM",
      "elevation": 1840,
      "lat": 20.2966995239,
      "lon": 98.8989028931,
      "tz": "Asia\/Yangon"
  },
  "VYMU": {
      "icao": "VYMU",
      "iata": "",
      "name": "Myauk U Airport",
      "city": "Myauk U",
      "state": "Rakhine",
      "country": "MM",
      "elevation": 60,
      "lat": 20.5669994354,
      "lon": 93.1999969482,
      "tz": "Asia\/Yangon"
  },
  "VYMW": {
      "icao": "VYMW",
      "iata": "MWQ",
      "name": "Magway Airport",
      "city": "Magway",
      "state": "Magway",
      "country": "MM",
      "elevation": 279,
      "lat": 20.165599823,
      "lon": 94.9413986206,
      "tz": "Asia\/Yangon"
  },
  "VYMY": {
      "icao": "VYMY",
      "iata": "NYW",
      "name": "Monywar Airport",
      "city": "Monywar",
      "state": "Sagain",
      "country": "MM",
      "elevation": 298,
      "lat": 22.2329998016,
      "lon": 95.1169967651,
      "tz": "Asia\/Yangon"
  },
  "VYNM": {
      "icao": "VYNM",
      "iata": "",
      "name": "Naungmom Airport",
      "city": "Naungmom",
      "state": "Kachin",
      "country": "MM",
      "elevation": 2200,
      "lat": 27.5,
      "lon": 97.8170013428,
      "tz": "Asia\/Yangon"
  },
  "VYNP": {
      "icao": "VYNP",
      "iata": "",
      "name": "Nampong Air Base",
      "city": "Myitkyina",
      "state": "Kachin",
      "country": "MM",
      "elevation": 459,
      "lat": 25.3544006348,
      "lon": 97.2951965332,
      "tz": "Asia\/Yangon"
  },
  "VYNS": {
      "icao": "VYNS",
      "iata": "NMS",
      "name": "Namsang Airport",
      "city": "Namsang",
      "state": "Shan",
      "country": "MM",
      "elevation": 3100,
      "lat": 20.890499115,
      "lon": 97.7359008789,
      "tz": "Asia\/Yangon"
  },
  "VYNT": {
      "icao": "VYNT",
      "iata": "NMT",
      "name": "Namtu Airport",
      "city": "Namtu",
      "state": "Shan",
      "country": "MM",
      "elevation": 2000,
      "lat": 23.0830001831,
      "lon": 97.3830032349,
      "tz": "Asia\/Yangon"
  },
  "VYPA": {
      "icao": "VYPA",
      "iata": "PAA",
      "name": "Hpa-N Airport",
      "city": "Hpa-N",
      "state": "Kayin",
      "country": "MM",
      "elevation": 150,
      "lat": 16.893699646,
      "lon": 97.6745986938,
      "tz": "Asia\/Yangon"
  },
  "VYPB": {
      "icao": "VYPB",
      "iata": "",
      "name": "Phonngbyin Airport",
      "city": "Phonngbyin",
      "state": "Sagain",
      "country": "MM",
      "elevation": 300,
      "lat": 24.25,
      "lon": 94.7829971313,
      "tz": "Asia\/Yangon"
  },
  "VYPE": {
      "icao": "VYPE",
      "iata": "",
      "name": "Paletwa Airport",
      "city": "Paletwa",
      "state": "Chin",
      "country": "MM",
      "elevation": 60,
      "lat": 21.2999992371,
      "lon": 92.9169998169,
      "tz": "Asia\/Yangon"
  },
  "VYPI": {
      "icao": "VYPI",
      "iata": "",
      "name": "Pearl Island Airport",
      "city": "Pearl Island",
      "state": "Tanintharyi",
      "country": "MM",
      "elevation": 5,
      "lat": 11.2829999924,
      "lon": 98.1999969482,
      "tz": "Asia\/Yangon"
  },
  "VYPK": {
      "icao": "VYPK",
      "iata": "PAU",
      "name": "Pauk Airport",
      "city": "Pauk",
      "state": "Magway",
      "country": "MM",
      "elevation": 249,
      "lat": 21.4491996765,
      "lon": 94.4869003296,
      "tz": "Asia\/Yangon"
  },
  "VYPL": {
      "icao": "VYPL",
      "iata": "",
      "name": "Pinlebu Airport",
      "city": "Pinlebu",
      "state": "Sagain",
      "country": "MM",
      "elevation": 830,
      "lat": 24.0830001831,
      "lon": 95.3669967651,
      "tz": "Asia\/Yangon"
  },
  "VYPN": {
      "icao": "VYPN",
      "iata": "BSX",
      "name": "Pathein Airport",
      "city": "Pathein",
      "state": "Ayeyarwady",
      "country": "MM",
      "elevation": 20,
      "lat": 16.8152008057,
      "lon": 94.7798995972,
      "tz": "Asia\/Yangon"
  },
  "VYPP": {
      "icao": "VYPP",
      "iata": "PPU",
      "name": "Hpapun Airport",
      "city": "Pa Pun",
      "state": "Kayin",
      "country": "MM",
      "elevation": 300,
      "lat": 18.0666999817,
      "lon": 97.449798584,
      "tz": "Asia\/Yangon"
  },
  "VYPT": {
      "icao": "VYPT",
      "iata": "PBU",
      "name": "Putao Airport",
      "city": "Putao",
      "state": "Kachin",
      "country": "MM",
      "elevation": 1500,
      "lat": 27.3299007416,
      "lon": 97.4263000488,
      "tz": "Asia\/Yangon"
  },
  "VYPU": {
      "icao": "VYPU",
      "iata": "PKK",
      "name": "Pakhokku Airport",
      "city": "Pakhokku",
      "state": "Magway",
      "country": "MM",
      "elevation": 151,
      "lat": 21.4043,
      "lon": 95.11125,
      "tz": "Asia\/Yangon"
  },
  "VYPW": {
      "icao": "VYPW",
      "iata": "",
      "name": "Palaw Airport",
      "city": "Palaw",
      "state": "Tanintharyi",
      "country": "MM",
      "elevation": 150,
      "lat": 12.9499998093,
      "lon": 98.6500015259,
      "tz": "Asia\/Yangon"
  },
  "VYPY": {
      "icao": "VYPY",
      "iata": "PRU",
      "name": "Pyay Airport",
      "city": "Pye",
      "state": "Bago",
      "country": "MM",
      "elevation": 120,
      "lat": 18.8244991302,
      "lon": 95.2659988403,
      "tz": "Asia\/Yangon"
  },
  "VYSA": {
      "icao": "VYSA",
      "iata": "",
      "name": "Saw Airport",
      "city": "Saw",
      "state": "Magway",
      "country": "MM",
      "elevation": 1100,
      "lat": 21.1669998169,
      "lon": 94.1669998169,
      "tz": "Asia\/Yangon"
  },
  "VYSL": {
      "icao": "VYSL",
      "iata": "",
      "name": "Salingyi Airport",
      "city": "Salingyi",
      "state": "Sagain",
      "country": "MM",
      "elevation": 264,
      "lat": 22.1000003815,
      "lon": 95.016998291,
      "tz": "Asia\/Yangon"
  },
  "VYSO": {
      "icao": "VYSO",
      "iata": "",
      "name": "Sedoktayar Airport",
      "city": "Sedoktayar",
      "state": "Magway",
      "country": "MM",
      "elevation": 500,
      "lat": 20.4500007629,
      "lon": 94.233001709,
      "tz": "Asia\/Yangon"
  },
  "VYST": {
      "icao": "VYST",
      "iata": "",
      "name": "Shante Air Base",
      "city": "Meiktila",
      "state": "Mandalay",
      "country": "MM",
      "elevation": 630,
      "lat": 20.9416999817,
      "lon": 95.9144973755,
      "tz": "Asia\/Yangon"
  },
  "VYSW": {
      "icao": "VYSW",
      "iata": "AKY",
      "name": "Sittwe Airport",
      "city": "Sittwe",
      "state": "Rakhine",
      "country": "MM",
      "elevation": 27,
      "lat": 20.1326999664,
      "lon": 92.8725967407,
      "tz": "Asia\/Yangon"
  },
  "VYTD": {
      "icao": "VYTD",
      "iata": "SNW",
      "name": "Thandwe Airport",
      "city": "Thandwe",
      "state": "Rakhine",
      "country": "MM",
      "elevation": 20,
      "lat": 18.4606990814,
      "lon": 94.3001022339,
      "tz": "Asia\/Yangon"
  },
  "VYTL": {
      "icao": "VYTL",
      "iata": "THL",
      "name": "Tachileik Airport",
      "city": "Tachileik",
      "state": "Shan",
      "country": "MM",
      "elevation": 1280,
      "lat": 20.4838008881,
      "lon": 99.9354019165,
      "tz": "Asia\/Yangon"
  },
  "VYTN": {
      "icao": "VYTN",
      "iata": "",
      "name": "Tanai Airport",
      "city": "Tanai",
      "state": "Kachin",
      "country": "MM",
      "elevation": 690,
      "lat": 26.3670005798,
      "lon": 96.733001709,
      "tz": "Asia\/Yangon"
  },
  "VYTO": {
      "icao": "VYTO",
      "iata": "",
      "name": "Taungoo Airport",
      "city": "Taungoo",
      "state": "Bago",
      "country": "MM",
      "elevation": 160,
      "lat": 19.0312995911,
      "lon": 96.4011993408,
      "tz": "Asia\/Yangon"
  },
  "VYTY": {
      "icao": "VYTY",
      "iata": "",
      "name": "Tanyang Airport",
      "city": "Tanyang",
      "state": "Shan",
      "country": "MM",
      "elevation": 250,
      "lat": 22.4829998016,
      "lon": 98.4000015259,
      "tz": "Asia\/Yangon"
  },
  "VYXG": {
      "icao": "VYXG",
      "iata": "",
      "name": "Kyaukhtu South Airport",
      "city": "Kyaukhtu South",
      "state": "Magway",
      "country": "MM",
      "elevation": 1345,
      "lat": 21.4067001343,
      "lon": 94.1303024292,
      "tz": "Asia\/Yangon"
  },
  "VYYE": {
      "icao": "VYYE",
      "iata": "XYE",
      "name": "Ye Airport",
      "city": "Ye",
      "state": "Mon",
      "country": "MM",
      "elevation": 30,
      "lat": 15.3000001907,
      "lon": 97.8669967651,
      "tz": "Asia\/Yangon"
  },
  "VYYY": {
      "icao": "VYYY",
      "iata": "RGN",
      "name": "Yangon International Airport",
      "city": "Yangon",
      "state": "Yangon",
      "country": "MM",
      "elevation": 109,
      "lat": 16.9073009491,
      "lon": 96.1332015991,
      "tz": "Asia\/Yangon"
  },
  "WA00": {
      "icao": "WA00",
      "iata": "",
      "name": "Mercer Ranch Airport",
      "city": "Alderdale",
      "state": "Washington",
      "country": "US",
      "elevation": 780,
      "lat": 45.9000015259,
      "lon": -119.90599823,
      "tz": "America\/Los_Angeles"
  },
  "WA01": {
      "icao": "WA01",
      "iata": "",
      "name": "Wirkkala Airport",
      "city": "Naselle",
      "state": "Washington",
      "country": "US",
      "elevation": 35,
      "lat": 46.3562011719,
      "lon": -123.8150024414,
      "tz": "America\/Los_Angeles"
  },
  "WA04": {
      "icao": "WA04",
      "iata": "",
      "name": "Kyles Airport",
      "city": "Snohomish",
      "state": "Washington",
      "country": "US",
      "elevation": 470,
      "lat": 47.8133010864,
      "lon": -122.0429992676,
      "tz": "America\/Los_Angeles"
  },
  "WA05": {
      "icao": "WA05",
      "iata": "",
      "name": "Apex Airpark",
      "city": "Silverdale",
      "state": "Washington",
      "country": "US",
      "elevation": 525,
      "lat": 47.6567993164,
      "lon": -122.733001709,
      "tz": "America\/Los_Angeles"
  },
  "WA07": {
      "icao": "WA07",
      "iata": "",
      "name": "Barker Airport",
      "city": "Mount Vernon",
      "state": "Washington",
      "country": "US",
      "elevation": 5,
      "lat": 48.3748016357,
      "lon": -122.3389968872,
      "tz": "America\/Los_Angeles"
  },
  "WA08": {
      "icao": "WA08",
      "iata": "",
      "name": "Zwainz Farms Airport",
      "city": "Reardan",
      "state": "Washington",
      "country": "US",
      "elevation": 2795,
      "lat": 47.7206993103,
      "lon": -117.908996582,
      "tz": "America\/Los_Angeles"
  },
  "WA09": {
      "icao": "WA09",
      "iata": "",
      "name": "Roche Harbor Airport",
      "city": "Roche Harbor",
      "state": "Washington",
      "country": "US",
      "elevation": 100,
      "lat": 48.6123008728,
      "lon": -123.138999939,
      "tz": "America\/Los_Angeles"
  },
  "WA10": {
      "icao": "WA10",
      "iata": "",
      "name": "Gosney Airport",
      "city": "Kaycee",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4830,
      "lat": 43.7221984863,
      "lon": -106.6669998169,
      "tz": "America\/Denver"
  },
  "WA12": {
      "icao": "WA12",
      "iata": "",
      "name": "Acme Field",
      "city": "Acme",
      "state": "Washington",
      "country": "US",
      "elevation": 301,
      "lat": 48.7095985413,
      "lon": -122.1780014038,
      "tz": "America\/Los_Angeles"
  },
  "WA14": {
      "icao": "WA14",
      "iata": "",
      "name": "Connell City Airport",
      "city": "Connell",
      "state": "Washington",
      "country": "US",
      "elevation": 925,
      "lat": 46.662399292,
      "lon": -118.8330001831,
      "tz": "America\/Los_Angeles"
  },
  "WA15": {
      "icao": "WA15",
      "iata": "",
      "name": "Coulee City Airport",
      "city": "Coulee City",
      "state": "Washington",
      "country": "US",
      "elevation": 1800,
      "lat": 47.6165008545,
      "lon": -119.2429962158,
      "tz": "America\/Los_Angeles"
  },
  "WA16": {
      "icao": "WA16",
      "iata": "",
      "name": "Banas Field",
      "city": "Brooklyn",
      "state": "Washington",
      "country": "US",
      "elevation": 180,
      "lat": 46.7997016907,
      "lon": -123.5410003662,
      "tz": "America\/Los_Angeles"
  },
  "WA17": {
      "icao": "WA17",
      "iata": "",
      "name": "Majerle Strip STOLport",
      "city": "Duvall",
      "state": "Washington",
      "country": "US",
      "elevation": 75,
      "lat": 47.7552986145,
      "lon": -121.9820022583,
      "tz": "America\/Los_Angeles"
  },
  "WA18": {
      "icao": "WA18",
      "iata": "",
      "name": "Decatur \/Jones\/ Airport",
      "city": "Decatur Island",
      "state": "Washington",
      "country": "US",
      "elevation": 125,
      "lat": 48.5101013184,
      "lon": -122.8030014038,
      "tz": "America\/Los_Angeles"
  },
  "WA1B": {
      "icao": "WA1B",
      "iata": "",
      "name": "Aru Airport",
      "city": "Fekfetal \/ Jerdera-Trangan Island",
      "state": "",
      "country": "ID",
      "elevation": 165,
      "lat": -6.5734200478,
      "lon": 134.1470031738,
      "tz": "Asia\/Jayapura"
  },
  "WA20": {
      "icao": "WA20",
      "iata": "",
      "name": "Gray Ranch Airport",
      "city": "Benge",
      "state": "Washington",
      "country": "US",
      "elevation": 1625,
      "lat": 46.8480987549,
      "lon": -118.1770019531,
      "tz": "America\/Los_Angeles"
  },
  "WA21": {
      "icao": "WA21",
      "iata": "",
      "name": "Elk Heights Airport",
      "city": "Elk",
      "state": "Washington",
      "country": "US",
      "elevation": 2020,
      "lat": 48.0019989014,
      "lon": -117.2630004883,
      "tz": "America\/Los_Angeles"
  },
  "WA22": {
      "icao": "WA22",
      "iata": "",
      "name": "Mirth Airport",
      "city": "Hansville",
      "state": "Washington",
      "country": "US",
      "elevation": 190,
      "lat": 47.9385986328,
      "lon": -122.608001709,
      "tz": "America\/Los_Angeles"
  },
  "WA23": {
      "icao": "WA23",
      "iata": "",
      "name": "Pine Bluff Airport",
      "city": "Springdale",
      "state": "Washington",
      "country": "US",
      "elevation": 2021,
      "lat": 48.0209999084,
      "lon": -117.7389984131,
      "tz": "America\/Los_Angeles"
  },
  "WA24": {
      "icao": "WA24",
      "iata": "",
      "name": "Pfister's Airport",
      "city": "Pasco",
      "state": "Washington",
      "country": "US",
      "elevation": 625,
      "lat": 46.396900177,
      "lon": -119.021003723,
      "tz": "America\/Los_Angeles"
  },
  "WA25": {
      "icao": "WA25",
      "iata": "",
      "name": "Green Valley Airfield",
      "city": "Granite Falls",
      "state": "Washington",
      "country": "US",
      "elevation": 300,
      "lat": 48.0959014893,
      "lon": -122.0159988403,
      "tz": "America\/Los_Angeles"
  },
  "WA26": {
      "icao": "WA26",
      "iata": "",
      "name": "Harrah Airport",
      "city": "Harrah",
      "state": "Washington",
      "country": "US",
      "elevation": 845,
      "lat": 46.4081993103,
      "lon": -120.6350021362,
      "tz": "America\/Los_Angeles"
  },
  "WA29": {
      "icao": "WA29",
      "iata": "",
      "name": "La Center View-Air Airport",
      "city": "La Center",
      "state": "Washington",
      "country": "US",
      "elevation": 811,
      "lat": 45.8956985474,
      "lon": -122.59400177,
      "tz": "America\/Los_Angeles"
  },
  "WA30": {
      "icao": "WA30",
      "iata": "",
      "name": "Lacrosse Municipal Airport",
      "city": "Lacrosse",
      "state": "Washington",
      "country": "US",
      "elevation": 1491,
      "lat": 46.7910003662,
      "lon": -117.9209976196,
      "tz": "America\/Los_Angeles"
  },
  "WA35": {
      "icao": "WA35",
      "iata": "",
      "name": "Clam Harbor Airport",
      "city": "Eastsound",
      "state": "Washington",
      "country": "US",
      "elevation": 220,
      "lat": 48.6222991943,
      "lon": -122.9469985962,
      "tz": "America\/Los_Angeles"
  },
  "WA37": {
      "icao": "WA37",
      "iata": "",
      "name": "Chinook Farms Airport",
      "city": "Kittitas",
      "state": "Washington",
      "country": "US",
      "elevation": 1880,
      "lat": 46.9103012085,
      "lon": -120.43699646,
      "tz": "America\/Los_Angeles"
  },
  "WA39": {
      "icao": "WA39",
      "iata": "",
      "name": "Woodfield Airport",
      "city": "Kennewick",
      "state": "Washington",
      "country": "US",
      "elevation": 445,
      "lat": 46.1277999878,
      "lon": -119.0179977417,
      "tz": "America\/Los_Angeles"
  },
  "WA41": {
      "icao": "WA41",
      "iata": "",
      "name": "Bear Canyon West Field",
      "city": "Morton",
      "state": "Washington",
      "country": "US",
      "elevation": 980,
      "lat": 46.5988998413,
      "lon": -122.4840011597,
      "tz": "America\/Los_Angeles"
  },
  "WA42": {
      "icao": "WA42",
      "iata": "",
      "name": "Stacey's Airport",
      "city": "Port Townsend",
      "state": "Washington",
      "country": "US",
      "elevation": 100,
      "lat": 47.9720001221,
      "lon": -122.7720031738,
      "tz": "America\/Los_Angeles"
  },
  "WA44": {
      "icao": "WA44",
      "iata": "TQQ",
      "name": "Maranggo Airport",
      "city": "Waha-Tomea Island",
      "state": "Southeast-Sulawesi",
      "country": "ID",
      "elevation": 169,
      "lat": -5.7645702362,
      "lon": 123.9169998169,
      "tz": "Asia\/Makassar"
  },
  "WA45": {
      "icao": "WA45",
      "iata": "",
      "name": "Olympic Field",
      "city": "Discovery Bay\/Maynard",
      "state": "Washington",
      "country": "US",
      "elevation": 500,
      "lat": 47.995300293,
      "lon": -122.9000015259,
      "tz": "America\/Los_Angeles"
  },
  "WA46": {
      "icao": "WA46",
      "iata": "",
      "name": "Daybreak Airport",
      "city": "La Center",
      "state": "Washington",
      "country": "US",
      "elevation": 25,
      "lat": 45.8302993774,
      "lon": -122.6370010376,
      "tz": "America\/Los_Angeles"
  },
  "WA47": {
      "icao": "WA47",
      "iata": "",
      "name": "Flying Rock Airpark",
      "city": "Ellensburg",
      "state": "Washington",
      "country": "US",
      "elevation": 2700,
      "lat": 47.1537017822,
      "lon": -120.6370010376,
      "tz": "America\/Los_Angeles"
  },
  "WA49": {
      "icao": "WA49",
      "iata": "",
      "name": "Flying R Ranch Airport",
      "city": "Cheney",
      "state": "Washington",
      "country": "US",
      "elevation": 2450,
      "lat": 47.5027999878,
      "lon": -117.6480026245,
      "tz": "America\/Los_Angeles"
  },
  "WA52": {
      "icao": "WA52",
      "iata": "",
      "name": "Deer Flat Airport",
      "city": "Deer Park",
      "state": "Washington",
      "country": "US",
      "elevation": 2200,
      "lat": 47.9569015503,
      "lon": -117.6039962769,
      "tz": "America\/Los_Angeles"
  },
  "WA56": {
      "icao": "WA56",
      "iata": "",
      "name": "Israel's Farm Airport",
      "city": "Sedro Woolley",
      "state": "Washington",
      "country": "US",
      "elevation": 65,
      "lat": 48.507598877,
      "lon": -122.0920028687,
      "tz": "America\/Los_Angeles"
  },
  "WA57": {
      "icao": "WA57",
      "iata": "",
      "name": "Bluecreek Airport",
      "city": "Addy",
      "state": "Washington",
      "country": "US",
      "elevation": 1740,
      "lat": 48.3138999939,
      "lon": -117.8399963379,
      "tz": "America\/Los_Angeles"
  },
  "WA58": {
      "icao": "WA58",
      "iata": "",
      "name": "Eliza Island Airport",
      "city": "Bellingham",
      "state": "Washington",
      "country": "US",
      "elevation": 8,
      "lat": 48.652606,
      "lon": -122.584617,
      "tz": "America\/Los_Angeles"
  },
  "WA59": {
      "icao": "WA59",
      "iata": "",
      "name": "Rake's Glen Airport",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 50,
      "lat": 48.1248016357,
      "lon": -123.1760025024,
      "tz": "America\/Los_Angeles"
  },
  "WA61": {
      "icao": "WA61",
      "iata": "",
      "name": "Thompson Airport",
      "city": "Kingston",
      "state": "Washington",
      "country": "US",
      "elevation": 50,
      "lat": 47.7983016968,
      "lon": -122.5299987793,
      "tz": "America\/Los_Angeles"
  },
  "WA62": {
      "icao": "WA62",
      "iata": "",
      "name": "Paradise Air Ranch Airport",
      "city": "Spangle",
      "state": "Washington",
      "country": "US",
      "elevation": 2352,
      "lat": 47.4706993103,
      "lon": -117.4189987183,
      "tz": "America\/Los_Angeles"
  },
  "WA63": {
      "icao": "WA63",
      "iata": "",
      "name": "Pleasant Farm Airport",
      "city": "Washougal",
      "state": "Washington",
      "country": "US",
      "elevation": 540,
      "lat": 45.562342,
      "lon": -122.256089,
      "tz": "America\/Los_Angeles"
  },
  "WA66": {
      "icao": "WA66",
      "iata": "",
      "name": "Spring Creek Ranch Airport",
      "city": "White Salmon",
      "state": "Washington",
      "country": "US",
      "elevation": 475,
      "lat": 45.8054008484,
      "lon": -121.5009994507,
      "tz": "America\/Los_Angeles"
  },
  "WA67": {
      "icao": "WA67",
      "iata": "",
      "name": "Green Mountain STOLport",
      "city": "Vancouver",
      "state": "Washington",
      "country": "US",
      "elevation": 270,
      "lat": 45.6631011963,
      "lon": -122.4729995728,
      "tz": "America\/Los_Angeles"
  },
  "WA68": {
      "icao": "WA68",
      "iata": "",
      "name": "Sky Valley Airstrip",
      "city": "Port Townsend",
      "state": "Washington",
      "country": "US",
      "elevation": 140,
      "lat": 48.076944,
      "lon": -122.840661,
      "tz": "America\/Los_Angeles"
  },
  "WA69": {
      "icao": "WA69",
      "iata": "",
      "name": "Wax Orchards Airport",
      "city": "Vashon",
      "state": "Washington",
      "country": "US",
      "elevation": 389,
      "lat": 47.4017982483,
      "lon": -122.4980010986,
      "tz": "America\/Los_Angeles"
  },
  "WA70": {
      "icao": "WA70",
      "iata": "",
      "name": "Frosty Creek Airport",
      "city": "Tonasket",
      "state": "Washington",
      "country": "US",
      "elevation": 3608,
      "lat": 48.5777015686,
      "lon": -118.9850006104,
      "tz": "America\/Los_Angeles"
  },
  "WA72": {
      "icao": "WA72",
      "iata": "",
      "name": "Zema Private Airport",
      "city": "Colville",
      "state": "Washington",
      "country": "US",
      "elevation": 1854,
      "lat": 48.6446990967,
      "lon": -117.93800354,
      "tz": "America\/Los_Angeles"
  },
  "WA73": {
      "icao": "WA73",
      "iata": "",
      "name": "Smith Airport",
      "city": "Colville",
      "state": "Washington",
      "country": "US",
      "elevation": 2240,
      "lat": 48.5250015259,
      "lon": -117.8529968262,
      "tz": "America\/Los_Angeles"
  },
  "WA74": {
      "icao": "WA74",
      "iata": "",
      "name": "Quincy Flying Service Airport",
      "city": "Quincy",
      "state": "Washington",
      "country": "US",
      "elevation": 1276,
      "lat": 47.2266998291,
      "lon": -119.908996582,
      "tz": "America\/Los_Angeles"
  },
  "WA76": {
      "icao": "WA76",
      "iata": "",
      "name": "Columbia Crest Winery Airport",
      "city": "Paterson",
      "state": "Washington",
      "country": "US",
      "elevation": 640,
      "lat": 45.956199646,
      "lon": -119.6200027466,
      "tz": "America\/Los_Angeles"
  },
  "WA77": {
      "icao": "WA77",
      "iata": "",
      "name": "Enumclaw Airport",
      "city": "Enumclaw",
      "state": "Washington",
      "country": "US",
      "elevation": 738,
      "lat": 47.1957015991,
      "lon": -122.0220031738,
      "tz": "America\/Los_Angeles"
  },
  "WA78": {
      "icao": "WA78",
      "iata": "",
      "name": "Sky River Ranch Airport",
      "city": "Washougal",
      "state": "Washington",
      "country": "US",
      "elevation": 1239,
      "lat": 45.6278991699,
      "lon": -122.1849975586,
      "tz": "America\/Los_Angeles"
  },
  "WA79": {
      "icao": "WA79",
      "iata": "",
      "name": "Walter Sutton's Private Strip",
      "city": "Amboy",
      "state": "Washington",
      "country": "US",
      "elevation": 451,
      "lat": 45.9332008362,
      "lon": -122.4179992676,
      "tz": "America\/Los_Angeles"
  },
  "WA80": {
      "icao": "WA80",
      "iata": "",
      "name": "Mc Clellan Field",
      "city": "Amboy",
      "state": "Washington",
      "country": "US",
      "elevation": 840,
      "lat": 45.8942985535,
      "lon": -122.547996521,
      "tz": "America\/Los_Angeles"
  },
  "WA84": {
      "icao": "WA84",
      "iata": "",
      "name": "Auburn Academy Airport",
      "city": "Auburn",
      "state": "Washington",
      "country": "US",
      "elevation": 416,
      "lat": 47.2807006836,
      "lon": -122.1500015259,
      "tz": "America\/Los_Angeles"
  },
  "WA87": {
      "icao": "WA87",
      "iata": "",
      "name": "Parkside Airpark",
      "city": "Battle Ground",
      "state": "Washington",
      "country": "US",
      "elevation": 275,
      "lat": 45.8158988953,
      "lon": -122.5530014038,
      "tz": "America\/Los_Angeles"
  },
  "WA88": {
      "icao": "WA88",
      "iata": "",
      "name": "Horse Fly Airport",
      "city": "Ferndale",
      "state": "Washington",
      "country": "US",
      "elevation": 70,
      "lat": 48.8928985596,
      "lon": -122.5569992065,
      "tz": "America\/Los_Angeles"
  },
  "WA90": {
      "icao": "WA90",
      "iata": "",
      "name": "Floathaven Airstrip",
      "city": "Bellingham",
      "state": "Washington",
      "country": "US",
      "elevation": 325,
      "lat": 48.7332000732,
      "lon": -122.3509979248,
      "tz": "America\/Los_Angeles"
  },
  "WA95": {
      "icao": "WA95",
      "iata": "",
      "name": "Skyqueen Airport",
      "city": "Centralia",
      "state": "Washington",
      "country": "US",
      "elevation": 530,
      "lat": 46.7186012268,
      "lon": -122.9110031128,
      "tz": "America\/Los_Angeles"
  },
  "WA96": {
      "icao": "WA96",
      "iata": "",
      "name": "Leisureland Airpark",
      "city": "Bremerton",
      "state": "Washington",
      "country": "US",
      "elevation": 430,
      "lat": 47.6074981689,
      "lon": -122.7740020752,
      "tz": "America\/Los_Angeles"
  },
  "WA97": {
      "icao": "WA97",
      "iata": "",
      "name": "Buena Airport",
      "city": "Buena",
      "state": "Washington",
      "country": "US",
      "elevation": 830,
      "lat": 46.4431991577,
      "lon": -120.341003418,
      "tz": "America\/Los_Angeles"
  },
  "WA98": {
      "icao": "WA98",
      "iata": "",
      "name": "Kobok Airport",
      "city": "Modjid-Celebes Island",
      "state": "",
      "country": "ID",
      "elevation": 114,
      "lat": 1.1018899679,
      "lon": 127.7030029297,
      "tz": "Asia\/Jayapura"
  },
  "WA99": {
      "icao": "WA99",
      "iata": "",
      "name": "Flying K Ranch Airport",
      "city": "Castle Rock",
      "state": "Washington",
      "country": "US",
      "elevation": 160,
      "lat": 46.3292999268,
      "lon": -122.8860015869,
      "tz": "America\/Los_Angeles"
  },
  "WAAA": {
      "icao": "WAAA",
      "iata": "UPG",
      "name": "Hasanuddin International Airport",
      "city": "Ujung Pandang-Celebes Island",
      "state": "South-Sulawesi",
      "country": "ID",
      "elevation": 47,
      "lat": -5.0616297722,
      "lon": 119.5540008545,
      "tz": "Asia\/Makassar"
  },
  "WAAG": {
      "icao": "WAAG",
      "iata": "",
      "name": "Malimpung Airport",
      "city": "Malimpung-Celebes Island",
      "state": "South-Sulawesi",
      "country": "ID",
      "elevation": 124,
      "lat": -3.7269439697,
      "lon": 119.7363891602,
      "tz": "Asia\/Makassar"
  },
  "WAAL": {
      "icao": "WAAL",
      "iata": "",
      "name": "Ponggaluku Airport",
      "city": "Wawooru-Celebes Island",
      "state": "Southeast-Sulawesi",
      "country": "ID",
      "elevation": 43,
      "lat": -4.3166670799,
      "lon": 122.4666671753,
      "tz": "Asia\/Makassar"
  },
  "WAAP": {
      "icao": "WAAP",
      "iata": "",
      "name": "Kolaka Airport",
      "city": "Kolaka-Celebes Island",
      "state": "Southeast-Sulawesi",
      "country": "ID",
      "elevation": 49,
      "lat": -4.0637698174,
      "lon": 121.6240005493,
      "tz": "Asia\/Makassar"
  },
  "WAAT": {
      "icao": "WAAT",
      "iata": "",
      "name": "Makale Airport",
      "city": "Makale-Celebes Island",
      "state": "South-Sulawesi",
      "country": "ID",
      "elevation": 2711,
      "lat": -3.1093599796,
      "lon": 119.856002808,
      "tz": "Asia\/Makassar"
  },
  "WABB": {
      "icao": "WABB",
      "iata": "BIK",
      "name": "Frans Kaisiepo Airport",
      "city": "Biak-Supiori Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 46,
      "lat": -1.1900199652,
      "lon": 136.108001709,
      "tz": "Asia\/Jayapura"
  },
  "WABD": {
      "icao": "WABD",
      "iata": "ONI",
      "name": "Moanamani Airport",
      "city": "Moanamani-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 5204,
      "lat": -3.9834020138,
      "lon": 136.0832977295,
      "tz": "Asia\/Jayapura"
  },
  "WABF": {
      "icao": "WABF",
      "iata": "FOO",
      "name": "Kornasoren Airfield",
      "city": "Kornasoren-Numfoor Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 13,
      "lat": -0.9363250136,
      "lon": 134.871994019,
      "tz": "Asia\/Jayapura"
  },
  "WABG": {
      "icao": "WABG",
      "iata": "WET",
      "name": "Wagethe Airport",
      "city": "Wagethe-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 5627,
      "lat": -4.0442299843,
      "lon": 136.2779998779,
      "tz": "Asia\/Jayapura"
  },
  "WABI": {
      "icao": "WABI",
      "iata": "NBX",
      "name": "Nabire Airport",
      "city": "Nabire-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 20,
      "lat": -3.3681800365,
      "lon": 135.4960021973,
      "tz": "Asia\/Jayapura"
  },
  "WABL": {
      "icao": "WABL",
      "iata": "ILA",
      "name": "Illaga Airport",
      "city": "Illaga-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 6307,
      "lat": -3.8332800865,
      "lon": 137.5829925537,
      "tz": "Asia\/Jayapura"
  },
  "WABN": {
      "icao": "WABN",
      "iata": "KOX",
      "name": "Kokonau Airport",
      "city": "Kokonau-Papua Island",
      "state": "",
      "country": "ID",
      "elevation": 9,
      "lat": -4.7166671753,
      "lon": 136.4333343506,
      "tz": "Asia\/Jayapura"
  },
  "WABO": {
      "icao": "WABO",
      "iata": "ZRI",
      "name": "Serui Airport",
      "city": "Serui-Japen Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 50,
      "lat": -1.8755799532,
      "lon": 136.2409973145,
      "tz": "Asia\/Jayapura"
  },
  "WABP": {
      "icao": "WABP",
      "iata": "TIM",
      "name": "Moses Kilangin Airport",
      "city": "Timika-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 103,
      "lat": -4.5282797813,
      "lon": 136.8869934082,
      "tz": "Asia\/Jayapura"
  },
  "WABT": {
      "icao": "WABT",
      "iata": "EWI",
      "name": "Enarotali Airport",
      "city": "Enarotali-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 5807,
      "lat": -3.9258999825,
      "lon": 136.3769989014,
      "tz": "Asia\/Jayapura"
  },
  "WABU": {
      "icao": "WABU",
      "iata": "",
      "name": "Biak Manuhua Airport",
      "city": "Biak-Supiori Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 33,
      "lat": -1.1735899448,
      "lon": 136.0800018311,
      "tz": "Asia\/Jayapura"
  },
  "WABW": {
      "icao": "WABW",
      "iata": "",
      "name": "Waren Airport",
      "city": "Celebes Island",
      "state": "West-Papua",
      "country": "ID",
      "elevation": 29,
      "lat": -1.6285300255,
      "lon": 134.1239929199,
      "tz": "Asia\/Jayapura"
  },
  "WADA": {
      "icao": "WADA",
      "iata": "AMI",
      "name": "Selaparang Airport",
      "city": "Mataram-Lombok Island",
      "state": "West-Nusa-Tenggara",
      "country": "ID",
      "elevation": 52,
      "lat": -8.5607099533,
      "lon": 116.095001221,
      "tz": "Asia\/Makassar"
  },
  "WADB": {
      "icao": "WADB",
      "iata": "BMU",
      "name": "Muhammad Salahuddin Airport",
      "city": "Bima-Sumbawa Island",
      "state": "West-Nusa-Tenggara",
      "country": "ID",
      "elevation": 3,
      "lat": -8.5396499634,
      "lon": 118.68699646,
      "tz": "Asia\/Makassar"
  },
  "WADD": {
      "icao": "WADD",
      "iata": "DPS",
      "name": "Ngurah Rai (Bali) International Airport",
      "city": "Denpasar-Bali Island",
      "state": "Bali",
      "country": "ID",
      "elevation": 14,
      "lat": -8.748169899,
      "lon": 115.1669998169,
      "tz": "Asia\/Makassar"
  },
  "WADL": {
      "icao": "WADL",
      "iata": "LOP",
      "name": "Bandara International Lombok Airport",
      "city": "Mataram",
      "state": "West-Nusa-Tenggara",
      "country": "ID",
      "elevation": 319,
      "lat": -8.757322,
      "lon": 116.276675,
      "tz": "Asia\/Makassar"
  },
  "WADS": {
      "icao": "WADS",
      "iata": "SWQ",
      "name": "Sumbawa Besar Airport",
      "city": "Sumbawa Island",
      "state": "West-Nusa-Tenggara",
      "country": "ID",
      "elevation": 16,
      "lat": -8.4890403748,
      "lon": 117.4120025635,
      "tz": "Asia\/Makassar"
  },
  "WADT": {
      "icao": "WADT",
      "iata": "TMC",
      "name": "Tambolaka Airport",
      "city": "Waikabubak-Sumba Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 161,
      "lat": -9.4097204208,
      "lon": 119.2440032959,
      "tz": "Asia\/Makassar"
  },
  "WADW": {
      "icao": "WADW",
      "iata": "WGP",
      "name": "Waingapu Airport",
      "city": "Waingapu-Sumba Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 33,
      "lat": -9.6692199707,
      "lon": 120.302001953,
      "tz": "Asia\/Makassar"
  },
  "WAHI": {
      "icao": "WAHI",
      "iata": "YIA",
      "name": "Yogyakarta International Airport",
      "city": "Yogyakarta",
      "state": "  Kulon Progo Regency",
      "country": "ID",
      "elevation": 7,
      "lat": -7.907459,
      "lon": 110.05448,
      "tz": "Asia\/Jakarta"
  },
  "WAJA": {
      "icao": "WAJA",
      "iata": "ARJ",
      "name": "Arso Airport",
      "city": "Arso-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 208,
      "lat": -2.9333329201,
      "lon": 140.7833404541,
      "tz": "Asia\/Jayapura"
  },
  "WAJB": {
      "icao": "WAJB",
      "iata": "BUI",
      "name": "Bokondini Airport",
      "city": "Bokondini-Papua Island",
      "state": "",
      "country": "ID",
      "elevation": 9104,
      "lat": -3.5836451054,
      "lon": 138.5334472656,
      "tz": "Asia\/Jayapura"
  },
  "WAJD": {
      "icao": "WAJD",
      "iata": "",
      "name": "Wakde Airport",
      "city": "Masi Masi Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 3,
      "lat": -1.935289979,
      "lon": 139.0200042725,
      "tz": "Asia\/Jayapura"
  },
  "WAJI": {
      "icao": "WAJI",
      "iata": "ZRM",
      "name": "Sarmi Airport",
      "city": "Sarmi-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 3,
      "lat": -1.8695499897,
      "lon": 138.75,
      "tz": "Asia\/Jayapura"
  },
  "WAJJ": {
      "icao": "WAJJ",
      "iata": "DJJ",
      "name": "Sentani Airport",
      "city": "Jayapura-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 289,
      "lat": -2.5769500732,
      "lon": 140.5160064697,
      "tz": "Asia\/Jayapura"
  },
  "WAJL": {
      "icao": "WAJL",
      "iata": "LHI",
      "name": "Lereh Airport",
      "city": "Lereh-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 1317,
      "lat": -3.1278998852,
      "lon": 139.9429931641,
      "tz": "Asia\/Jayapura"
  },
  "WAJM": {
      "icao": "WAJM",
      "iata": "LII",
      "name": "Mulia Airport",
      "city": "Mulia-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 6527,
      "lat": -3.7094700336,
      "lon": 137.9759979248,
      "tz": "Asia\/Jayapura"
  },
  "WAJO": {
      "icao": "WAJO",
      "iata": "OKL",
      "name": "Oksibil Airport",
      "city": "Oksibil-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 2500,
      "lat": -5.0910601616,
      "lon": 140.6100006104,
      "tz": "Asia\/Jayapura"
  },
  "WAJR": {
      "icao": "WAJR",
      "iata": "WAR",
      "name": "Waris Airport",
      "city": "Waris-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 1,
      "lat": -3.2224299908,
      "lon": 140.9790039063,
      "tz": "Asia\/Jayapura"
  },
  "WAJS": {
      "icao": "WAJS",
      "iata": "SEH",
      "name": "Senggeh Airport",
      "city": "Senggeh-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 914,
      "lat": -3.4333300591,
      "lon": 140.8170013428,
      "tz": "Asia\/Jayapura"
  },
  "WAJU": {
      "icao": "WAJU",
      "iata": "UBR",
      "name": "Ubrub Airport",
      "city": "Ubrub-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 1370,
      "lat": -3.666670084,
      "lon": 140.8500061035,
      "tz": "Asia\/Jayapura"
  },
  "WAJW": {
      "icao": "WAJW",
      "iata": "WMX",
      "name": "Wamena Airport",
      "city": "Wamena-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 5085,
      "lat": -4.1025099754,
      "lon": 138.9570007324,
      "tz": "Asia\/Jayapura"
  },
  "WAKD": {
      "icao": "WAKD",
      "iata": "MDP",
      "name": "Mindiptana Airport",
      "city": "Mindiptana-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 157,
      "lat": -5.75,
      "lon": 140.3670043945,
      "tz": "Asia\/Jayapura"
  },
  "WAKE": {
      "icao": "WAKE",
      "iata": "BXD",
      "name": "Bade Airport",
      "city": "Bade-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 59,
      "lat": -7.714630127,
      "lon": 139.5749969482,
      "tz": "Asia\/Jayapura"
  },
  "WAKG": {
      "icao": "WAKG",
      "iata": "",
      "name": "Agats Airport",
      "city": "Agats-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 17,
      "lat": -5.5414600372,
      "lon": 138.1333312988,
      "tz": "Asia\/Jayapura"
  },
  "WAKK": {
      "icao": "WAKK",
      "iata": "MKQ",
      "name": "Mopah Airport",
      "city": "Merauke-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 10,
      "lat": -8.5202903748,
      "lon": 140.4179992676,
      "tz": "Asia\/Jayapura"
  },
  "WAKO": {
      "icao": "WAKO",
      "iata": "OKQ",
      "name": "Okaba Airport",
      "city": "Okaba-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 23,
      "lat": -8.0882196426,
      "lon": 139.7189941406,
      "tz": "Asia\/Jayapura"
  },
  "WAKP": {
      "icao": "WAKP",
      "iata": "KEI",
      "name": "Kepi Airport",
      "city": "Kepi-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 67,
      "lat": -6.5943698883,
      "lon": 139.3569946289,
      "tz": "Asia\/Jayapura"
  },
  "WAKT": {
      "icao": "WAKT",
      "iata": "TMH",
      "name": "Tanah Merah Airport",
      "city": "Tanah Merah-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 57,
      "lat": -6.099219799,
      "lon": 140.2980041504,
      "tz": "Asia\/Jayapura"
  },
  "WALE": {
      "icao": "WALE",
      "iata": "",
      "name": "Melak Airport",
      "city": "Melak-Borneo Island",
      "state": "",
      "country": "ID",
      "elevation": 330,
      "lat": -0.2036110014,
      "lon": 115.7600021362,
      "tz": "Asia\/Makassar"
  },
  "WALG": {
      "icao": "WALG",
      "iata": "TJS",
      "name": "Tanjung Harapan Airport",
      "city": "Tanjung Selor-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 10,
      "lat": 2.8364100456,
      "lon": 117.3740005493,
      "tz": "Asia\/Makassar"
  },
  "WALJ": {
      "icao": "WALJ",
      "iata": "DTD",
      "name": "Datadawai Airport",
      "city": "Datadawai-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 229,
      "lat": 0.626,
      "lon": 116.6006,
      "tz": "Asia\/Makassar"
  },
  "WALK": {
      "icao": "WALK",
      "iata": "BEJ",
      "name": "Barau(Kalimaru) Airport",
      "city": "Tanjung Redep-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 59,
      "lat": 2.1554999352,
      "lon": 117.431999207,
      "tz": "Asia\/Makassar"
  },
  "WALL": {
      "icao": "WALL",
      "iata": "BPN",
      "name": "Sepinggan International Airport",
      "city": "Balikpapan-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 12,
      "lat": -1.2682700157,
      "lon": 116.8939971924,
      "tz": "Asia\/Makassar"
  },
  "WALQ": {
      "icao": "WALQ",
      "iata": "",
      "name": "Muara Badak Pujangan Airport",
      "city": "Muara Badak-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 49,
      "lat": -0.3060750067,
      "lon": 117.4160003662,
      "tz": "Asia\/Makassar"
  },
  "WALR": {
      "icao": "WALR",
      "iata": "TRK",
      "name": "Juwata Airport",
      "city": "Tarakan Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 20,
      "lat": 3.3266899586,
      "lon": 117.5660018921,
      "tz": "Asia\/Makassar"
  },
  "WALS": {
      "icao": "WALS",
      "iata": "AAP",
      "name": "Aji Pangeran Tumenggung Pranoto International Airport",
      "city": "Samarinda",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 82,
      "lat": -0.373611,
      "lon": 117.255556,
      "tz": "Asia\/Makassar"
  },
  "WALT": {
      "icao": "WALT",
      "iata": "TSX",
      "name": "Tanjung Santan Airport",
      "city": "Santan-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 121,
      "lat": -0.0929730013,
      "lon": 117.4530029297,
      "tz": "Asia\/Makassar"
  },
  "WALV": {
      "icao": "WALV",
      "iata": "BYQ",
      "name": "Bunyu Airport",
      "city": "Bunju Island",
      "state": "North-Kalimantan",
      "country": "ID",
      "elevation": 118,
      "lat": 3.4557199478,
      "lon": 117.866996765,
      "tz": "Asia\/Makassar"
  },
  "WALX": {
      "icao": "WALX",
      "iata": "",
      "name": "Mangkajang Airport",
      "city": "Mangkajang-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 47,
      "lat": 2.0075099468,
      "lon": 117.7490005493,
      "tz": "Asia\/Makassar"
  },
  "WAMA": {
      "icao": "WAMA",
      "iata": "GLX",
      "name": "Gamarmalamo Airport",
      "city": "Galela-Celebes Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 180,
      "lat": 1.8383300304,
      "lon": 127.7860031128,
      "tz": "Asia\/Jayapura"
  },
  "WAMB": {
      "icao": "WAMB",
      "iata": "",
      "name": "Kotamubagu Airport",
      "city": "Kotamubagu-Celebes Island",
      "state": "North-Sulawesi",
      "country": "ID",
      "elevation": 752,
      "lat": 0.7288889885,
      "lon": 124.3088912964,
      "tz": "Asia\/Makassar"
  },
  "WAMC": {
      "icao": "WAMC",
      "iata": "",
      "name": "Tentena Airport",
      "city": "Tentena-Celebes Island",
      "state": "",
      "country": "ID",
      "elevation": 1900,
      "lat": -2,
      "lon": 120.7666702271,
      "tz": "Asia\/Makassar"
  },
  "WAMD": {
      "icao": "WAMD",
      "iata": "",
      "name": "Jailolo\/Kuripasai Airport",
      "city": "Kuripasai-Celebes Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 50,
      "lat": 1.1169999838,
      "lon": 127.483001709,
      "tz": "Asia\/Jayapura"
  },
  "WAMG": {
      "icao": "WAMG",
      "iata": "GTO",
      "name": "Jalaluddin Airport",
      "city": "Gorontalo-Celebes Island",
      "state": "Gorontalo",
      "country": "ID",
      "elevation": 105,
      "lat": 0.6371189952,
      "lon": 122.8499984741,
      "tz": "Asia\/Makassar"
  },
  "WAMH": {
      "icao": "WAMH",
      "iata": "NAH",
      "name": "Naha Airport",
      "city": "Tahuna-Sangihe Island",
      "state": "North-Sulawesi",
      "country": "ID",
      "elevation": 16,
      "lat": 3.6832098961,
      "lon": 125.5279998779,
      "tz": "Asia\/Makassar"
  },
  "WAMI": {
      "icao": "WAMI",
      "iata": "TLI",
      "name": "Toli Toli Airport",
      "city": "Toli Toli-Celebes Island",
      "state": "",
      "country": "ID",
      "elevation": 40,
      "lat": -1.0297700167,
      "lon": 120.8170013428,
      "tz": "Asia\/Makassar"
  },
  "WAMJ": {
      "icao": "WAMJ",
      "iata": "GEB",
      "name": "Gebe Airport",
      "city": "Gebe Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 125,
      "lat": -0.0788889974,
      "lon": 129.4579925537,
      "tz": "Asia\/Jayapura"
  },
  "WAMK": {
      "icao": "WAMK",
      "iata": "KAZ",
      "name": "Kao Airport",
      "city": "Kao-Celebes Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 27,
      "lat": 1.1852799654,
      "lon": 127.8960037231,
      "tz": "Asia\/Jayapura"
  },
  "WAML": {
      "icao": "WAML",
      "iata": "PLW",
      "name": "Mutiara Airport",
      "city": "Palu-Celebes Island",
      "state": "Central-Sulawesi",
      "country": "ID",
      "elevation": 284,
      "lat": -0.9185420275,
      "lon": 119.9100036621,
      "tz": "Asia\/Makassar"
  },
  "WAMM": {
      "icao": "WAMM",
      "iata": "MDC",
      "name": "Sam Ratulangi Airport",
      "city": "Manado-Celebes Island",
      "state": "North-Sulawesi",
      "country": "ID",
      "elevation": 264,
      "lat": 1.5492600203,
      "lon": 124.9260025024,
      "tz": "Asia\/Makassar"
  },
  "WAMN": {
      "icao": "WAMN",
      "iata": "MNA",
      "name": "Melangguane Airport",
      "city": "Karakelong Island",
      "state": "North-Sulawesi",
      "country": "ID",
      "elevation": 3,
      "lat": 4.006939888,
      "lon": 126.672996521,
      "tz": "Asia\/Makassar"
  },
  "WAMP": {
      "icao": "WAMP",
      "iata": "PSJ",
      "name": "Kasiguncu Airport",
      "city": "Poso-Celebes Island",
      "state": "Central-Sulawesi",
      "country": "ID",
      "elevation": 174,
      "lat": -1.4167499542,
      "lon": 120.657997131,
      "tz": "Asia\/Makassar"
  },
  "WAMR": {
      "icao": "WAMR",
      "iata": "OTI",
      "name": "Pitu Airport",
      "city": "Gotalalamo-Morotai Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 49,
      "lat": 2.0459899902,
      "lon": 128.324996948,
      "tz": "Asia\/Jayapura"
  },
  "WAMT": {
      "icao": "WAMT",
      "iata": "TTE",
      "name": "Sultan Khairun Babullah Airport",
      "city": "Sango-Ternate Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 49,
      "lat": 0.8314139843,
      "lon": 127.3809967041,
      "tz": "Asia\/Jayapura"
  },
  "WAMU": {
      "icao": "WAMU",
      "iata": "",
      "name": "Wuasa Airport",
      "city": "Wuasa-Celebes Island",
      "state": "Central-Sulawesi",
      "country": "ID",
      "elevation": 1,
      "lat": -1.4249999523,
      "lon": 120.3163909912,
      "tz": "Asia\/Makassar"
  },
  "WAMW": {
      "icao": "WAMW",
      "iata": "LUW",
      "name": "Bubung Airport",
      "city": "Luwok-Celebes Island",
      "state": "Central-Sulawesi",
      "country": "ID",
      "elevation": 56,
      "lat": -1.0389200449,
      "lon": 122.7720031738,
      "tz": "Asia\/Makassar"
  },
  "WAMY": {
      "icao": "WAMY",
      "iata": "UOL",
      "name": "Buol Airport",
      "city": "Buol-Celebes Island",
      "state": "Central-Sulawesi",
      "country": "ID",
      "elevation": 5,
      "lat": 1.1590499878,
      "lon": 121.4280014038,
      "tz": "Asia\/Makassar"
  },
  "WAOC": {
      "icao": "WAOC",
      "iata": "BTW",
      "name": "Batu Licin Airport",
      "city": "Batu Licin-Borneo Island",
      "state": "South-Kalimantan",
      "country": "ID",
      "elevation": 3,
      "lat": -3.4124100208,
      "lon": 115.995002747,
      "tz": "Asia\/Makassar"
  },
  "WAOH": {
      "icao": "WAOH",
      "iata": "",
      "name": "Mekar Putih Airport",
      "city": "Mekar Putih-Laut Island",
      "state": "South-Kalimantan",
      "country": "ID",
      "elevation": 197,
      "lat": -3.9899098873,
      "lon": 116.1019973755,
      "tz": "Asia\/Makassar"
  },
  "WAOI": {
      "icao": "WAOI",
      "iata": "PKN",
      "name": "Iskandar Airport",
      "city": "Pangkalanbun-Borneo Island",
      "state": "Central-Kalimantan",
      "country": "ID",
      "elevation": 75,
      "lat": -2.7051999569,
      "lon": 111.672996521,
      "tz": "Asia\/Pontianak"
  },
  "WAOK": {
      "icao": "WAOK",
      "iata": "KBU",
      "name": "Stagen Airport",
      "city": "Laut Island",
      "state": "South-Kalimantan",
      "country": "ID",
      "elevation": 4,
      "lat": -3.2947199345,
      "lon": 116.1650009155,
      "tz": "Asia\/Makassar"
  },
  "WAOL": {
      "icao": "WAOL",
      "iata": "",
      "name": "Kuala Pembuang Airport",
      "city": "Kuala Pembuang",
      "state": "",
      "country": "ID",
      "elevation": 13,
      "lat": -2.8666698933,
      "lon": 112.5329971313,
      "tz": "Asia\/Pontianak"
  },
  "WAOM": {
      "icao": "WAOM",
      "iata": "MTW",
      "name": "Beringin Airport",
      "city": "Murateweh-Borneo Island",
      "state": "Central-Kalimantan",
      "country": "ID",
      "elevation": 126,
      "lat": -0.9403250217,
      "lon": 114.8938751221,
      "tz": "Asia\/Pontianak"
  },
  "WAON": {
      "icao": "WAON",
      "iata": "TJG",
      "name": "Warukin Airport",
      "city": "Tanta-Tabalong-Borneo Island",
      "state": "South-Kalimantan",
      "country": "ID",
      "elevation": 197,
      "lat": -2.2165598869,
      "lon": 115.435997009,
      "tz": "Asia\/Makassar"
  },
  "WAOO": {
      "icao": "WAOO",
      "iata": "BDJ",
      "name": "Syamsudin Noor Airport",
      "city": "Banjarmasin-Borneo Island",
      "state": "South-Kalimantan",
      "country": "ID",
      "elevation": 66,
      "lat": -3.4423599243,
      "lon": 114.7630004883,
      "tz": "Asia\/Makassar"
  },
  "WAOP": {
      "icao": "WAOP",
      "iata": "PKY",
      "name": "Tjilik Riwut Airport",
      "city": "Palangkaraya-Kalimantan Tengah",
      "state": "Central-Kalimantan",
      "country": "ID",
      "elevation": 82,
      "lat": -2.2251300812,
      "lon": 113.9430007935,
      "tz": "Asia\/Pontianak"
  },
  "WAOS": {
      "icao": "WAOS",
      "iata": "SMQ",
      "name": "Sampit(Hasan) Airport",
      "city": "Sampit-Borneo Island",
      "state": "Central-Kalimantan",
      "country": "ID",
      "elevation": 50,
      "lat": -2.4991900921,
      "lon": 112.9749984741,
      "tz": "Asia\/Pontianak"
  },
  "WAOU": {
      "icao": "WAOU",
      "iata": "",
      "name": "SANGGU Airport Buntok",
      "city": "Buntok",
      "state": "",
      "country": "ID",
      "elevation": 23,
      "lat": -1.6692999601,
      "lon": 114.8980026245,
      "tz": "Asia\/Pontianak"
  },
  "WAPA": {
      "icao": "WAPA",
      "iata": "AHI",
      "name": "Amahai Airport",
      "city": "Amahai-Seram Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 29,
      "lat": -3.3480000496,
      "lon": 128.925994873,
      "tz": "Asia\/Jayapura"
  },
  "WAPB": {
      "icao": "WAPB",
      "iata": "",
      "name": "Bula Airport",
      "city": "Bula-Seram Island",
      "state": "",
      "country": "ID",
      "elevation": 10,
      "lat": -3.1099600792,
      "lon": 130.5099945068,
      "tz": "Asia\/Jayapura"
  },
  "WAPC": {
      "icao": "WAPC",
      "iata": "NDA",
      "name": "Banda Airport Kepulauan",
      "city": "Banda Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 49,
      "lat": 5.1166701317,
      "lon": 97.1166992188,
      "tz": "Asia\/Jakarta"
  },
  "WAPD": {
      "icao": "WAPD",
      "iata": "DOB",
      "name": "Dobo Airport",
      "city": "Dobo-Kobror Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 15,
      "lat": -5.7722201347,
      "lon": 134.2120056152,
      "tz": "Asia\/Jayapura"
  },
  "WAPE": {
      "icao": "WAPE",
      "iata": "MAL",
      "name": "Mangole Airport Falabisahaya",
      "city": "Mangole Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 889,
      "lat": -1.87579,
      "lon": 125.8300018311,
      "tz": "Asia\/Jayapura"
  },
  "WAPG": {
      "icao": "WAPG",
      "iata": "NRE",
      "name": "Namrole Airport",
      "city": "Namrole-Buru Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 997,
      "lat": -3.8208100796,
      "lon": 126.7180023193,
      "tz": "Asia\/Jayapura"
  },
  "WAPH": {
      "icao": "WAPH",
      "iata": "LAH",
      "name": "Oesman Sadik Airport Labuha",
      "city": "Labuha-Halmahera Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 49,
      "lat": -0.6352589726,
      "lon": 127.5019989014,
      "tz": "Asia\/Jayapura"
  },
  "WAPI": {
      "icao": "WAPI",
      "iata": "SXK",
      "name": "Saumlaki Airport",
      "city": "Saumlaki-Yamdena Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 218,
      "lat": -7.9886097908,
      "lon": 131.3059997559,
      "tz": "Asia\/Jayapura"
  },
  "WAPK": {
      "icao": "WAPK",
      "iata": "BJK",
      "name": "Nangasuri Airport",
      "city": "Maikoor Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 82,
      "lat": -6.0661997795,
      "lon": 134.2740020752,
      "tz": "Asia\/Jayapura"
  },
  "WAPL": {
      "icao": "WAPL",
      "iata": "LUV",
      "name": "Dumatumbun Airport",
      "city": "Langgur-Seram Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 10,
      "lat": -5.6616201401,
      "lon": 132.7310028076,
      "tz": "Asia\/Jayapura"
  },
  "WAPN": {
      "icao": "WAPN",
      "iata": "SQN",
      "name": "Emalamo Sanana Airport",
      "city": "Sanana-Seram Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 39,
      "lat": -2.080509901,
      "lon": 125.9670028687,
      "tz": "Asia\/Jayapura"
  },
  "WAPO": {
      "icao": "WAPO",
      "iata": "",
      "name": "Larat Kepulauan Larat",
      "city": "Kepulauan Larat",
      "state": "Maluku",
      "country": "ID",
      "elevation": 80,
      "lat": -7.1333298683,
      "lon": 131.7830047607,
      "tz": "Asia\/Jayapura"
  },
  "WAPP": {
      "icao": "WAPP",
      "iata": "AMQ",
      "name": "Pattimura Airport Ambon",
      "city": "Ambon",
      "state": "Maluku",
      "country": "ID",
      "elevation": 33,
      "lat": -3.7102599144,
      "lon": 128.0890045166,
      "tz": "Asia\/Jayapura"
  },
  "WAPQ": {
      "icao": "WAPQ",
      "iata": "",
      "name": "Kisar Airport",
      "city": "Kisar-Kisar Island",
      "state": "",
      "country": "ID",
      "elevation": 6,
      "lat": -8.0184898376,
      "lon": 127.1969985962,
      "tz": "Asia\/Jayapura"
  },
  "WAPR": {
      "icao": "WAPR",
      "iata": "NAM",
      "name": "Namlea Airport",
      "city": "Namlea-Buru Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 41,
      "lat": -3.2355699539,
      "lon": 127.0999984741,
      "tz": "Asia\/Jayapura"
  },
  "WAPT": {
      "icao": "WAPT",
      "iata": "TAX",
      "name": "Taliabu Island Airport",
      "city": "Tikong-Taliabu Island",
      "state": "North-Maluku",
      "country": "ID",
      "elevation": 9,
      "lat": -1.642629981,
      "lon": 124.5589981079,
      "tz": "Asia\/Jayapura"
  },
  "WAPV": {
      "icao": "WAPV",
      "iata": "WBA",
      "name": "WahaiSeram Island",
      "city": "Seram Island",
      "state": "Maluku",
      "country": "ID",
      "elevation": 551,
      "lat": -2.8141698837,
      "lon": 129.4819946289,
      "tz": "Asia\/Jayapura"
  },
  "WARA": {
      "icao": "WARA",
      "iata": "MLG",
      "name": "Abdul Rachman Saleh Airport",
      "city": "Malang-Java Island",
      "state": "East-Java",
      "country": "ID",
      "elevation": 1726,
      "lat": -7.9265599251,
      "lon": 112.714996338,
      "tz": "Asia\/Jakarta"
  },
  "WARC": {
      "icao": "WARC",
      "iata": "CPF",
      "name": "Cepu Airport",
      "city": "Tjepu-Java Island",
      "state": "Central-Java",
      "country": "ID",
      "elevation": 131,
      "lat": -7.1948416667,
      "lon": 111.548166667,
      "tz": "Asia\/Jakarta"
  },
  "WARI": {
      "icao": "WARI",
      "iata": "",
      "name": "Iswahyudi Airport",
      "city": "Madiun-Java Island",
      "state": "East-Java",
      "country": "ID",
      "elevation": 361,
      "lat": -7.6157698631,
      "lon": 111.4339981079,
      "tz": "Asia\/Jakarta"
  },
  "WARJ": {
      "icao": "WARJ",
      "iata": "JOG",
      "name": "Adi Sutjipto International Airport",
      "city": "Yogyakarta-Java Island",
      "state": "Yogyakarta",
      "country": "ID",
      "elevation": 350,
      "lat": -7.7881798744,
      "lon": 110.4319992065,
      "tz": "Asia\/Jakarta"
  },
  "WARQ": {
      "icao": "WARQ",
      "iata": "SOC",
      "name": "Adi Sumarmo Wiryokusumo Airport",
      "city": "Sukarata(Solo)-Java Island",
      "state": "Central-Java",
      "country": "ID",
      "elevation": 421,
      "lat": -7.5160899162,
      "lon": 110.7570037842,
      "tz": "Asia\/Jakarta"
  },
  "WARR": {
      "icao": "WARR",
      "iata": "SUB",
      "name": "Juanda International Airport",
      "city": "Surabaya",
      "state": "East-Java",
      "country": "ID",
      "elevation": 9,
      "lat": -7.3798298836,
      "lon": 112.7870025635,
      "tz": "Asia\/Jakarta"
  },
  "WARS": {
      "icao": "WARS",
      "iata": "SRG",
      "name": "Achmad Yani Airport",
      "city": "Semarang-Java Island",
      "state": "Central-Java",
      "country": "ID",
      "elevation": 10,
      "lat": -6.9727301598,
      "lon": 110.375,
      "tz": "Asia\/Jakarta"
  },
  "WART": {
      "icao": "WART",
      "iata": "SUP",
      "name": "Trunojoyo Airport",
      "city": "Sumenep-Madura Island",
      "state": "East-Java",
      "country": "ID",
      "elevation": 10,
      "lat": -7.02425,
      "lon": 113.890205556,
      "tz": "Asia\/Jakarta"
  },
  "WARU": {
      "icao": "WARU",
      "iata": "",
      "name": "Dewadaru - Kemujan Island",
      "city": "",
      "state": "",
      "country": "ID",
      "elevation": 0,
      "lat": -5.8111109734,
      "lon": 110.4777755737,
      "tz": "Asia\/Jakarta"
  },
  "WASB": {
      "icao": "WASB",
      "iata": "NTI",
      "name": "Stenkol Airport",
      "city": "Bintuni-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 57,
      "lat": -2.1041600704,
      "lon": 133.5220031738,
      "tz": "Asia\/Jayapura"
  },
  "WASC": {
      "icao": "WASC",
      "iata": "RSK",
      "name": "Abresso Airport",
      "city": "Ransiki-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 52,
      "lat": -1.5084500313,
      "lon": 134.18699646,
      "tz": "Asia\/Jayapura"
  },
  "WASE": {
      "icao": "WASE",
      "iata": "KEQ",
      "name": "Kebar Airport",
      "city": "Kebar-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 2050,
      "lat": -0.6371009946,
      "lon": 133.1280059814,
      "tz": "Asia\/Jayapura"
  },
  "WASF": {
      "icao": "WASF",
      "iata": "FKQ",
      "name": "Fakfak Airport",
      "city": "Fakfak-Papua Island",
      "state": "",
      "country": "ID",
      "elevation": 462,
      "lat": -2.9201900959,
      "lon": 132.266998291,
      "tz": "Asia\/Jayapura"
  },
  "WASI": {
      "icao": "WASI",
      "iata": "INX",
      "name": "Inanwatan Airport",
      "city": "Inanwatan Airport-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 9,
      "lat": -2.1280999184,
      "lon": 132.1609954834,
      "tz": "Asia\/Jayapura"
  },
  "WASK": {
      "icao": "WASK",
      "iata": "KNG",
      "name": "Kaimana Airport",
      "city": "Kaimana-Papua Island",
      "state": "",
      "country": "ID",
      "elevation": 19,
      "lat": -3.6445200443,
      "lon": 133.6959991455,
      "tz": "Asia\/Jayapura"
  },
  "WASM": {
      "icao": "WASM",
      "iata": "RDE",
      "name": "Merdei Airport",
      "city": "Merdei-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 425,
      "lat": -1.5833300352,
      "lon": 133.3329925537,
      "tz": "Asia\/Jayapura"
  },
  "WASO": {
      "icao": "WASO",
      "iata": "BXB",
      "name": "Babo Airport",
      "city": "Babo-Papua Island",
      "state": "West-Papua",
      "country": "ID",
      "elevation": 10,
      "lat": -2.5322399139,
      "lon": 133.4389953613,
      "tz": "Asia\/Jayapura"
  },
  "WASR": {
      "icao": "WASR",
      "iata": "MKW",
      "name": "Rendani Airport",
      "city": "Manokwari-Papua Island",
      "state": "West-Papua",
      "country": "ID",
      "elevation": 23,
      "lat": -0.8918330073,
      "lon": 134.0489959717,
      "tz": "Asia\/Jayapura"
  },
  "WASS": {
      "icao": "WASS",
      "iata": "SOQ",
      "name": "Sorong (Jefman) Airport",
      "city": "Sorong-Papua Island",
      "state": "West-Papua",
      "country": "ID",
      "elevation": 10,
      "lat": -0.9263579845,
      "lon": 131.1210021973,
      "tz": "Asia\/Jayapura"
  },
  "WAST": {
      "icao": "WAST",
      "iata": "TXM",
      "name": "Teminabuan Airport",
      "city": "Atinjoe-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 141,
      "lat": -1.4447200298,
      "lon": 132.0209960938,
      "tz": "Asia\/Jayapura"
  },
  "WASW": {
      "icao": "WASW",
      "iata": "WSR",
      "name": "Wasior Airport",
      "city": "Wasior-Papua Island",
      "state": "",
      "country": "ID",
      "elevation": 565,
      "lat": -2.7335898876,
      "lon": 134.5180053711,
      "tz": "Asia\/Jayapura"
  },
  "WATB": {
      "icao": "WATB",
      "iata": "BJW",
      "name": "Bajawa Soa Airport",
      "city": "Bajawa",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 4326,
      "lat": -8.7074349801,
      "lon": 121.057426929,
      "tz": "Asia\/Makassar"
  },
  "WATC": {
      "icao": "WATC",
      "iata": "MOF",
      "name": "Maumere(Wai Oti) Airport",
      "city": "Maumere-Flores Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 115,
      "lat": -8.6406497955,
      "lon": 122.236999512,
      "tz": "Asia\/Makassar"
  },
  "WATE": {
      "icao": "WATE",
      "iata": "ENE",
      "name": "Ende (H Hasan Aroeboesman) Airport",
      "city": "Ende-Flores Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 49,
      "lat": -8.8492898941,
      "lon": 121.661003113,
      "tz": "Asia\/Makassar"
  },
  "WATG": {
      "icao": "WATG",
      "iata": "RTG",
      "name": "Satar Tacik Airport",
      "city": "Satar Tacik-Flores Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 3510,
      "lat": -8.5970096588,
      "lon": 120.4769973755,
      "tz": "Asia\/Makassar"
  },
  "WATM": {
      "icao": "WATM",
      "iata": "ARD",
      "name": "Mali Airport",
      "city": "Alor Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 10,
      "lat": -8.1323404312,
      "lon": 124.5970001221,
      "tz": "Asia\/Makassar"
  },
  "WATO": {
      "icao": "WATO",
      "iata": "LBJ",
      "name": "Komodo (Mutiara II) Airport",
      "city": "Labuan Bajo-Flores Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 66,
      "lat": -8.4866600037,
      "lon": 119.888999939,
      "tz": "Asia\/Makassar"
  },
  "WATT": {
      "icao": "WATT",
      "iata": "KOE",
      "name": "El Tari Airport",
      "city": "Kupang-Timor Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 335,
      "lat": -10.1716003418,
      "lon": 123.6709976196,
      "tz": "Asia\/Makassar"
  },
  "WAWB": {
      "icao": "WAWB",
      "iata": "BUW",
      "name": "Betoambari Airport",
      "city": "Bau Bau-Butung Island",
      "state": "Southeast-Sulawesi",
      "country": "ID",
      "elevation": 164,
      "lat": -5.4868798256,
      "lon": 122.5690002441,
      "tz": "Asia\/Makassar"
  },
  "WAWJ": {
      "icao": "WAWJ",
      "iata": "MJU",
      "name": "Tampa Padang Airport",
      "city": "Mamuju-Celebes Island",
      "state": "West-Sulawesi",
      "country": "ID",
      "elevation": 49,
      "lat": -2.583333,
      "lon": 119.033333,
      "tz": "Asia\/Makassar"
  },
  "WAWM": {
      "icao": "WAWM",
      "iata": "MXB",
      "name": "Andi Jemma Airport",
      "city": "Masamba-Celebes Island",
      "state": "South-Sulawesi",
      "country": "ID",
      "elevation": 164,
      "lat": -2.5580399036,
      "lon": 120.3239974976,
      "tz": "Asia\/Makassar"
  },
  "WAWP": {
      "icao": "WAWP",
      "iata": "KXB",
      "name": "Sangia Nibandera Airport",
      "city": "Kolaka",
      "state": "South-Sulawesi",
      "country": "ID",
      "elevation": 40,
      "lat": -4.341217,
      "lon": 121.523983,
      "tz": "Asia\/Makassar"
  },
  "WAWS": {
      "icao": "WAWS",
      "iata": "SQR",
      "name": "Soroako Airport",
      "city": "Soroako-Celebes Island",
      "state": "South-Sulawesi",
      "country": "ID",
      "elevation": 1388,
      "lat": -2.5311999321,
      "lon": 121.358001709,
      "tz": "Asia\/Makassar"
  },
  "WAWT": {
      "icao": "WAWT",
      "iata": "TTR",
      "name": "Pongtiku Airport",
      "city": "Tanah Toraja-Celebes Island",
      "state": "South-Sulawesi",
      "country": "ID",
      "elevation": 2884,
      "lat": -3.0447399616,
      "lon": 119.8219985962,
      "tz": "Asia\/Makassar"
  },
  "WAWW": {
      "icao": "WAWW",
      "iata": "KDI",
      "name": "Wolter Monginsidi Airport",
      "city": "Kendari-Celebes Island",
      "state": "Southeast-Sulawesi",
      "country": "ID",
      "elevation": 538,
      "lat": -4.0816102028,
      "lon": 122.4179992676,
      "tz": "Asia\/Makassar"
  },
  "WAXX": {
      "icao": "WAXX",
      "iata": "",
      "name": "Dominique Edward Osok Airport",
      "city": "Sorong-Papua Island",
      "state": "Papua",
      "country": "ID",
      "elevation": 10,
      "lat": -0.89517802,
      "lon": 131.2850036621,
      "tz": "Asia\/Jayapura"
  },
  "WBAK": {
      "icao": "WBAK",
      "iata": "",
      "name": "Anduki Airport",
      "city": "Anduki\/Seria",
      "state": "",
      "country": "BN",
      "elevation": 7,
      "lat": 4.6370000839,
      "lon": 114.382003784,
      "tz": "Asia\/Brunei"
  },
  "WBGA": {
      "icao": "WBGA",
      "iata": "",
      "name": "Long Atip Airport",
      "city": "Long Atip",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 300,
      "lat": 3.8169999123,
      "lon": 114.6999969482,
      "tz": "Asia\/Kuching"
  },
  "WBGB": {
      "icao": "WBGB",
      "iata": "BTU",
      "name": "Bintulu Airport",
      "city": "Bintulu",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 74,
      "lat": 3.1238501072,
      "lon": 113.019996643,
      "tz": "Asia\/Kuching"
  },
  "WBGC": {
      "icao": "WBGC",
      "iata": "BLG",
      "name": "Belaga Airport",
      "city": "Belaga",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 200,
      "lat": 2.6500000954,
      "lon": 113.766998291,
      "tz": "Asia\/Kuching"
  },
  "WBGD": {
      "icao": "WBGD",
      "iata": "LSM",
      "name": "Long Semado Airport",
      "city": "Long Semado",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 2150,
      "lat": 4.2170000076,
      "lon": 115.599998474,
      "tz": "Asia\/Kuching"
  },
  "WBGE": {
      "icao": "WBGE",
      "iata": "",
      "name": "Long Geng Airport",
      "city": "Long Geng",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 350,
      "lat": 2.617000103,
      "lon": 114.1330032349,
      "tz": "Asia\/Kuching"
  },
  "WBGF": {
      "icao": "WBGF",
      "iata": "LGL",
      "name": "Long Lellang Airport",
      "city": "Long Datih",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 1400,
      "lat": 3.4210000038,
      "lon": 115.1539993286,
      "tz": "Asia\/Kuching"
  },
  "WBGG": {
      "icao": "WBGG",
      "iata": "KCH",
      "name": "Kuching International Airport",
      "city": "Kuching",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 89,
      "lat": 1.4846999645,
      "lon": 110.3470001221,
      "tz": "Asia\/Kuching"
  },
  "WBGI": {
      "icao": "WBGI",
      "iata": "ODN",
      "name": "Long Seridan Airport",
      "city": "Long Seridan",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 607,
      "lat": 3.9670000076,
      "lon": 115.0500030518,
      "tz": "Asia\/Kuching"
  },
  "WBGJ": {
      "icao": "WBGJ",
      "iata": "LMN",
      "name": "Limbang Airport",
      "city": "Limbang",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 14,
      "lat": 4.8083000183,
      "lon": 115.0100021362,
      "tz": "Asia\/Brunei"
  },
  "WBGK": {
      "icao": "WBGK",
      "iata": "MKM",
      "name": "Mukah Airport",
      "city": "Mukah",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 13,
      "lat": 2.9063899517,
      "lon": 112.0800018311,
      "tz": "Asia\/Kuching"
  },
  "WBGL": {
      "icao": "WBGL",
      "iata": "LKH",
      "name": "Long Akah Airport",
      "city": "Long Akah",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 289,
      "lat": 3.2999999523,
      "lon": 114.7829971313,
      "tz": "Asia\/Kuching"
  },
  "WBGM": {
      "icao": "WBGM",
      "iata": "MUR",
      "name": "Marudi Airport",
      "city": "Marudi",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 103,
      "lat": 4.1789798737,
      "lon": 114.3290023804,
      "tz": "Asia\/Kuching"
  },
  "WBGN": {
      "icao": "WBGN",
      "iata": "BSE",
      "name": "Sematan Airport",
      "city": "Sematan",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 22,
      "lat": 1.8136099577,
      "lon": 109.7630004883,
      "tz": "Asia\/Kuching"
  },
  "WBGO": {
      "icao": "WBGO",
      "iata": "",
      "name": "Lio Matu Airport",
      "city": "Lio Matu",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 700,
      "lat": 3.1500000954,
      "lon": 115.233001709,
      "tz": "Asia\/Kuching"
  },
  "WBGP": {
      "icao": "WBGP",
      "iata": "KPI",
      "name": "Kapit Airport",
      "city": "Kapit",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 65,
      "lat": 2.0169999599,
      "lon": 112.9499969482,
      "tz": "Asia\/Kuching"
  },
  "WBGQ": {
      "icao": "WBGQ",
      "iata": "BKM",
      "name": "Bakalalan Airport",
      "city": "Bakalalan",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 2900,
      "lat": 3.9739999771,
      "lon": 115.6179962158,
      "tz": "Asia\/Kuching"
  },
  "WBGR": {
      "icao": "WBGR",
      "iata": "MYY",
      "name": "Miri Airport",
      "city": "Miri",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 59,
      "lat": 4.3220100403,
      "lon": 113.9869995117,
      "tz": "Asia\/Kuching"
  },
  "WBGS": {
      "icao": "WBGS",
      "iata": "SBW",
      "name": "Sibu Airport",
      "city": "Sibu",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 122,
      "lat": 2.2616000175,
      "lon": 111.9850006104,
      "tz": "Asia\/Kuching"
  },
  "WBGT": {
      "icao": "WBGT",
      "iata": "TGC",
      "name": "Tanjung Manis Airport",
      "city": "Tanjung Manis",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 15,
      "lat": 2.1778399944,
      "lon": 111.202003479,
      "tz": "Asia\/Kuching"
  },
  "WBGU": {
      "icao": "WBGU",
      "iata": "LSU",
      "name": "Long Sukang Airport",
      "city": "Long Sukang",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 1200,
      "lat": 4.5522198677,
      "lon": 115.4940032959,
      "tz": "Asia\/Kuching"
  },
  "WBGW": {
      "icao": "WBGW",
      "iata": "LWY",
      "name": "Lawas Airport",
      "city": "Lawas",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 5,
      "lat": 4.849170208,
      "lon": 115.4079971313,
      "tz": "Asia\/Kuching"
  },
  "WBGY": {
      "icao": "WBGY",
      "iata": "SGG",
      "name": "Simanggang Airport",
      "city": "Simanggang",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 35,
      "lat": 1.2170000076,
      "lon": 111.4499969482,
      "tz": "Asia\/Kuching"
  },
  "WBGZ": {
      "icao": "WBGZ",
      "iata": "BBN",
      "name": "Bario Airport",
      "city": "Bario",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 3350,
      "lat": 3.7338900566,
      "lon": 115.4789962769,
      "tz": "Asia\/Kuching"
  },
  "WBKA": {
      "icao": "WBKA",
      "iata": "SMM",
      "name": "Semporna Airport",
      "city": "Semporna",
      "state": "Sabah",
      "country": "MY",
      "elevation": 60,
      "lat": 4.4499998093,
      "lon": 118.5830001831,
      "tz": "Asia\/Kuching"
  },
  "WBKB": {
      "icao": "WBKB",
      "iata": "",
      "name": "Kota Belud Airport",
      "city": "Kota Belud",
      "state": "Sabah",
      "country": "MY",
      "elevation": 46,
      "lat": 6.3651599884,
      "lon": 116.4710006714,
      "tz": "Asia\/Kuching"
  },
  "WBKD": {
      "icao": "WBKD",
      "iata": "LDU",
      "name": "Lahad Datu Airport",
      "city": "Lahad Datu",
      "state": "Sabah",
      "country": "MY",
      "elevation": 45,
      "lat": 5.0322499275,
      "lon": 118.3239974976,
      "tz": "Asia\/Kuching"
  },
  "WBKE": {
      "icao": "WBKE",
      "iata": "TEL",
      "name": "Telupid Airport",
      "city": "Telupid",
      "state": "Sabah",
      "country": "MY",
      "elevation": 343,
      "lat": 5.6286101341,
      "lon": 117.1259994507,
      "tz": "Asia\/Kuching"
  },
  "WBKG": {
      "icao": "WBKG",
      "iata": "KGU",
      "name": "Keningau Airport",
      "city": "Keningau",
      "state": "Sabah",
      "country": "MY",
      "elevation": 1036,
      "lat": 5.3574900627,
      "lon": 116.1620025635,
      "tz": "Asia\/Kuching"
  },
  "WBKH": {
      "icao": "WBKH",
      "iata": "SXS",
      "name": "Sahabat [Sahabat 16] Airport",
      "city": "Sahabat",
      "state": "Sabah",
      "country": "MY",
      "elevation": 40,
      "lat": 5.0877799988,
      "lon": 119.09400177,
      "tz": "Asia\/Kuching"
  },
  "WBKK": {
      "icao": "WBKK",
      "iata": "BKI",
      "name": "Kota Kinabalu International Airport",
      "city": "Kota Kinabalu",
      "state": "Sabah",
      "country": "MY",
      "elevation": 10,
      "lat": 5.937210083,
      "lon": 116.0510025024,
      "tz": "Asia\/Kuching"
  },
  "WBKL": {
      "icao": "WBKL",
      "iata": "LBU",
      "name": "Labuan Airport",
      "city": "Labuan",
      "state": "Labuan",
      "country": "MY",
      "elevation": 101,
      "lat": 5.3006801605,
      "lon": 115.25,
      "tz": "Asia\/Kuching"
  },
  "WBKM": {
      "icao": "WBKM",
      "iata": "TMG",
      "name": "Tomanggong Airport",
      "city": "Tomanggong",
      "state": "Sabah",
      "country": "MY",
      "elevation": 26,
      "lat": 5.4000000954,
      "lon": 118.6500015259,
      "tz": "Asia\/Kuching"
  },
  "WBKN": {
      "icao": "WBKN",
      "iata": "GSA",
      "name": "Long Pasia Airport",
      "city": "Long Miau",
      "state": "Sabah",
      "country": "MY",
      "elevation": 3175,
      "lat": 4.4000000954,
      "lon": 115.7170028687,
      "tz": "Asia\/Kuching"
  },
  "WBKO": {
      "icao": "WBKO",
      "iata": "SPE",
      "name": "Sepulot Airport",
      "city": "Sepulot",
      "state": "Sabah",
      "country": "MY",
      "elevation": 800,
      "lat": 4.7329998016,
      "lon": 116.4670028687,
      "tz": "Asia\/Kuching"
  },
  "WBKP": {
      "icao": "WBKP",
      "iata": "PAY",
      "name": "Pamol Airport",
      "city": "Pamol",
      "state": "Sabah",
      "country": "MY",
      "elevation": 36,
      "lat": 5.9993000031,
      "lon": 117.3999176025,
      "tz": "Asia\/Kuching"
  },
  "WBKR": {
      "icao": "WBKR",
      "iata": "RNU",
      "name": "Ranau Airport",
      "city": "Ranau",
      "state": "Sabah",
      "country": "MY",
      "elevation": 1800,
      "lat": 5.9499998093,
      "lon": 116.6669998169,
      "tz": "Asia\/Kuching"
  },
  "WBKS": {
      "icao": "WBKS",
      "iata": "SDK",
      "name": "Sandakan Airport",
      "city": "Sandakan",
      "state": "Sabah",
      "country": "MY",
      "elevation": 46,
      "lat": 5.9008998871,
      "lon": 118.0589981079,
      "tz": "Asia\/Kuching"
  },
  "WBKT": {
      "icao": "WBKT",
      "iata": "KUD",
      "name": "Kudat Airport",
      "city": "Kudat",
      "state": "Sabah",
      "country": "MY",
      "elevation": 10,
      "lat": 6.9225001335,
      "lon": 116.8359985352,
      "tz": "Asia\/Kuching"
  },
  "WBKU": {
      "icao": "WBKU",
      "iata": "",
      "name": "Kuala Penyu Airport",
      "city": "Kuala Penyu",
      "state": "Sabah",
      "country": "MY",
      "elevation": 6,
      "lat": 5.5999999046,
      "lon": 115.5830001831,
      "tz": "Asia\/Kuching"
  },
  "WBKW": {
      "icao": "WBKW",
      "iata": "TWU",
      "name": "Tawau Airport",
      "city": "Tawau",
      "state": "Sabah",
      "country": "MY",
      "elevation": 57,
      "lat": 4.3201599121,
      "lon": 118.1279983521,
      "tz": "Asia\/Kuching"
  },
  "WBMU": {
      "icao": "WBMU",
      "iata": "MZV",
      "name": "Mulu Airport",
      "city": "Mulu",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 80,
      "lat": 4.0483298302,
      "lon": 114.8050003052,
      "tz": "Asia\/Kuching"
  },
  "WBSB": {
      "icao": "WBSB",
      "iata": "BWN",
      "name": "Brunei International Airport",
      "city": "Bandar Seri Begawan",
      "state": "",
      "country": "BN",
      "elevation": 73,
      "lat": 4.9442000389,
      "lon": 114.9280014038,
      "tz": "Asia\/Brunei"
  },
  "WI00": {
      "icao": "WI00",
      "iata": "",
      "name": "Norrie Brook Airport",
      "city": "Eland",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1240,
      "lat": 44.8739013672,
      "lon": -89.2306976318,
      "tz": "America\/Chicago"
  },
  "WI02": {
      "icao": "WI02",
      "iata": "",
      "name": "Turtle Airport",
      "city": "Beloit",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 911,
      "lat": 42.5069999695,
      "lon": -88.9148025513,
      "tz": "America\/Chicago"
  },
  "WI03": {
      "icao": "WI03",
      "iata": "",
      "name": "Horner Farms Airport",
      "city": "Union Grove",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 770,
      "lat": 42.7980995178,
      "lon": -88.1333999634,
      "tz": "America\/Chicago"
  },
  "WI05": {
      "icao": "WI05",
      "iata": "",
      "name": "Stocktrade Airport",
      "city": "Elk Mound",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 882,
      "lat": 44.8083000183,
      "lon": -91.6844024658,
      "tz": "America\/Chicago"
  },
  "WI07": {
      "icao": "WI07",
      "iata": "",
      "name": "Waupun Airport",
      "city": "Waupun",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 940,
      "lat": 43.6222000122,
      "lon": -88.7667999268,
      "tz": "America\/Chicago"
  },
  "WI09": {
      "icao": "WI09",
      "iata": "",
      "name": "Heitman Field Airport",
      "city": "Dousman",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 880,
      "lat": 42.9771995544,
      "lon": -88.4757995605,
      "tz": "America\/Chicago"
  },
  "WI10": {
      "icao": "WI10",
      "iata": "",
      "name": "Cedar Island Airport",
      "city": "Brule",
      "state": "",
      "country": "US",
      "elevation": 1229,
      "lat": 46.4499015808,
      "lon": -91.616897583,
      "tz": "America\/Chicago"
  },
  "WI11": {
      "icao": "WI11",
      "iata": "",
      "name": "Mumm Field",
      "city": "Orfordville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 945,
      "lat": 42.5914001465,
      "lon": -89.2406997681,
      "tz": "America\/Chicago"
  },
  "WI12": {
      "icao": "WI12",
      "iata": "",
      "name": "Lilac Time Airport",
      "city": "Birchwood",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1300,
      "lat": 45.7066001892,
      "lon": -91.6249008179,
      "tz": "America\/Chicago"
  },
  "WI13": {
      "icao": "WI13",
      "iata": "",
      "name": "Jennie'S Field",
      "city": "Wisconsin Rapids",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 993,
      "lat": 44.254833,
      "lon": -89.8395,
      "tz": "America\/Chicago"
  },
  "WI14": {
      "icao": "WI14",
      "iata": "",
      "name": "Baldwin Airport",
      "city": "Baldwin",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1104,
      "lat": 44.9665985107,
      "lon": -92.3877029419,
      "tz": "America\/Chicago"
  },
  "WI16": {
      "icao": "WI16",
      "iata": "",
      "name": "Jim Benson Field",
      "city": "Mineral Point",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1100,
      "lat": 42.853099823,
      "lon": -90.129699707,
      "tz": "America\/Chicago"
  },
  "WI18": {
      "icao": "WI18",
      "iata": "",
      "name": "Gateway Airport",
      "city": "Bloomer",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1021,
      "lat": 45.1333007813,
      "lon": -91.4502029419,
      "tz": "America\/Chicago"
  },
  "WI19": {
      "icao": "WI19",
      "iata": "",
      "name": "Cacic Airport",
      "city": "Montello",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 805,
      "lat": 43.7999992371,
      "lon": -89.4335021973,
      "tz": "America\/Chicago"
  },
  "WI1A": {
      "icao": "WI1A",
      "iata": "",
      "name": "Nusawiru Airport",
      "city": "Pangandaran-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 16,
      "lat": -7.720389843,
      "lon": 108.4899978638,
      "tz": "Asia\/Jakarta"
  },
  "WI1B": {
      "icao": "WI1B",
      "iata": "",
      "name": "Batujajar Airport",
      "city": "Bandung-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 2500,
      "lat": -6.9038600922,
      "lon": 107.475997925,
      "tz": "Asia\/Jakarta"
  },
  "WI1C": {
      "icao": "WI1C",
      "iata": "",
      "name": "Rumpin Airport",
      "city": "Rumpin-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 223,
      "lat": -6.3732199669,
      "lon": 106.6220016479,
      "tz": "Asia\/Jakarta"
  },
  "WI1G": {
      "icao": "WI1G",
      "iata": "",
      "name": "Gading Wonosari Airport",
      "city": "Wonosari-Java Island",
      "state": "Yogyakarta",
      "country": "ID",
      "elevation": 702,
      "lat": -7.9165902138,
      "lon": 110.5640029907,
      "tz": "Asia\/Jakarta"
  },
  "WI1S": {
      "icao": "WI1S",
      "iata": "",
      "name": "Simpangtiga Redelong Airport",
      "city": "Tingkeum-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 4636,
      "lat": 4.7208800316,
      "lon": 96.8511962891,
      "tz": "Asia\/Jakarta"
  },
  "WI20": {
      "icao": "WI20",
      "iata": "",
      "name": "Larson Studio Airport",
      "city": "Oconto Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 700,
      "lat": 44.8829994202,
      "lon": -88.0920028687,
      "tz": "America\/Chicago"
  },
  "WI21": {
      "icao": "WI21",
      "iata": "",
      "name": "Crane Field",
      "city": "Cadott",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 936,
      "lat": 44.9096984863,
      "lon": -91.2417984009,
      "tz": "America\/Chicago"
  },
  "WI22": {
      "icao": "WI22",
      "iata": "",
      "name": "Rocket City Airport",
      "city": "Hortonville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 905,
      "lat": 44.3231010437,
      "lon": -88.6007995605,
      "tz": "America\/Chicago"
  },
  "WI23": {
      "icao": "WI23",
      "iata": "",
      "name": "Cornucopia Field",
      "city": "Cornucopia",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 760,
      "lat": 46.8666000366,
      "lon": -91.0835037231,
      "tz": "America\/Chicago"
  },
  "WI24": {
      "icao": "WI24",
      "iata": "",
      "name": "Cardinal Ridge Airport",
      "city": "Mt Horeb",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1080,
      "lat": 42.8810997009,
      "lon": -89.7521972656,
      "tz": "America\/Chicago"
  },
  "WI25": {
      "icao": "WI25",
      "iata": "",
      "name": "Durand Municipal Airport",
      "city": "Durand",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 780,
      "lat": 44.538898468,
      "lon": -92.0140991211,
      "tz": "America\/Chicago"
  },
  "WI26": {
      "icao": "WI26",
      "iata": "",
      "name": "Mertinkes Airport",
      "city": "Eau Claire",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 920,
      "lat": 44.7050018311,
      "lon": -91.4654006958,
      "tz": "America\/Chicago"
  },
  "WI27": {
      "icao": "WI27",
      "iata": "",
      "name": "Skyport Airport",
      "city": "Menomonie",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 878,
      "lat": 44.9202003479,
      "lon": -91.974899292,
      "tz": "America\/Chicago"
  },
  "WI28": {
      "icao": "WI28",
      "iata": "",
      "name": "Walter's Agri-Center Airport",
      "city": "Rio Creek",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 717,
      "lat": 44.586101532,
      "lon": -87.5576019287,
      "tz": "America\/Chicago"
  },
  "WI29": {
      "icao": "WI29",
      "iata": "",
      "name": "Rush River Airport",
      "city": "Baldwin",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1078,
      "lat": 44.9054985046,
      "lon": -92.4834976196,
      "tz": "America\/Chicago"
  },
  "WI31": {
      "icao": "WI31",
      "iata": "",
      "name": "Minnesuing Airport",
      "city": "Lake Nebagamon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1207,
      "lat": 46.4143981934,
      "lon": -91.6510009766,
      "tz": "America\/Chicago"
  },
  "WI32": {
      "icao": "WI32",
      "iata": "",
      "name": "T-Bo Field Airport",
      "city": "Chippewa Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 940,
      "lat": 44.9873008728,
      "lon": -91.3303985596,
      "tz": "America\/Chicago"
  },
  "WI33": {
      "icao": "WI33",
      "iata": "",
      "name": "Ben Sutherland Airport",
      "city": "Minong",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1056,
      "lat": 46.1035995483,
      "lon": -91.9023971558,
      "tz": "America\/Chicago"
  },
  "WI36": {
      "icao": "WI36",
      "iata": "",
      "name": "Dolhun Field",
      "city": "Lake Tomahawk",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1625,
      "lat": 45.8265991211,
      "lon": -89.6201019287,
      "tz": "America\/Chicago"
  },
  "WI37": {
      "icao": "WI37",
      "iata": "",
      "name": "Rainbow Airport",
      "city": "Ojibwa",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1321,
      "lat": 45.8083000183,
      "lon": -91.0962982178,
      "tz": "America\/Chicago"
  },
  "WI40": {
      "icao": "WI40",
      "iata": "",
      "name": "Spiegel Field",
      "city": "Oregon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 960,
      "lat": 42.9132995605,
      "lon": -89.3523025513,
      "tz": "America\/Chicago"
  },
  "WI42": {
      "icao": "WI42",
      "iata": "",
      "name": "Pine Grove Airport",
      "city": "Rhinelander",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1630,
      "lat": 45.6571998596,
      "lon": -89.5298995972,
      "tz": "America\/Chicago"
  },
  "WI43": {
      "icao": "WI43",
      "iata": "",
      "name": "Atkins Ridge Airport",
      "city": "Daleyville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1090,
      "lat": 42.9488983154,
      "lon": -89.825302124,
      "tz": "America\/Chicago"
  },
  "WI46": {
      "icao": "WI46",
      "iata": "",
      "name": "Fun-Air Airport",
      "city": "Van Dyne",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 805,
      "lat": 43.8828010559,
      "lon": -88.5399017334,
      "tz": "America\/Chicago"
  },
  "WI47": {
      "icao": "WI47",
      "iata": "",
      "name": "Timberline Airport",
      "city": "Scandinavia",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 890,
      "lat": 44.4333000183,
      "lon": -89.150100708,
      "tz": "America\/Chicago"
  },
  "WI48": {
      "icao": "WI48",
      "iata": "",
      "name": "Edinger Field",
      "city": "Eureka",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 770,
      "lat": 44.004699707,
      "lon": -88.8709030151,
      "tz": "America\/Chicago"
  },
  "WI49": {
      "icao": "WI49",
      "iata": "",
      "name": "Blair Airport",
      "city": "Blair",
      "state": "",
      "country": "US",
      "elevation": 864,
      "lat": 44.2860984802,
      "lon": -91.2265014648,
      "tz": "America\/Chicago"
  },
  "WI50": {
      "icao": "WI50",
      "iata": "",
      "name": "Albany Airport",
      "city": "Albany",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 815,
      "lat": 42.7181015015,
      "lon": -89.4236984253,
      "tz": "America\/Chicago"
  },
  "WI51": {
      "icao": "WI51",
      "iata": "",
      "name": "Del Monte Airport",
      "city": "Arlington",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1064,
      "lat": 43.3213996887,
      "lon": -89.3209991455,
      "tz": "America\/Chicago"
  },
  "WI52": {
      "icao": "WI52",
      "iata": "",
      "name": "Schewe Airport",
      "city": "Clintonville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 810,
      "lat": 44.6178016663,
      "lon": -88.6668014526,
      "tz": "America\/Chicago"
  },
  "WI53": {
      "icao": "WI53",
      "iata": "",
      "name": "Desmet Airport",
      "city": "Barneveld",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1050,
      "lat": 42.9453010559,
      "lon": -89.8990020752,
      "tz": "America\/Chicago"
  },
  "WI54": {
      "icao": "WI54",
      "iata": "",
      "name": "Corinth Airport",
      "city": "Athens",
      "state": "",
      "country": "US",
      "elevation": 1425,
      "lat": 44.9994010925,
      "lon": -90.149597168,
      "tz": "America\/Chicago"
  },
  "WI55": {
      "icao": "WI55",
      "iata": "",
      "name": "Broken Prop Airport",
      "city": "Berlin",
      "state": "",
      "country": "US",
      "elevation": 767,
      "lat": 43.9860992432,
      "lon": -88.9834976196,
      "tz": "America\/Chicago"
  },
  "WI56": {
      "icao": "WI56",
      "iata": "",
      "name": "C Jeidy Farms Airport",
      "city": "Bloomington",
      "state": "",
      "country": "US",
      "elevation": 1010,
      "lat": 42.8816986084,
      "lon": -90.8607025146,
      "tz": "America\/Chicago"
  },
  "WI58": {
      "icao": "WI58",
      "iata": "",
      "name": "Winfield Airport",
      "city": "Bristol",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 770,
      "lat": 42.5222015381,
      "lon": -88.0279006958,
      "tz": "America\/Chicago"
  },
  "WI60": {
      "icao": "WI60",
      "iata": "",
      "name": "Deer Haven Ranch Airport",
      "city": "Cecil",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 811,
      "lat": 44.8328018188,
      "lon": -88.4632034302,
      "tz": "America\/Chicago"
  },
  "WI61": {
      "icao": "WI61",
      "iata": "",
      "name": "Ashenfelter Aerodrome",
      "city": "Grafton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 860,
      "lat": 43.3488998413,
      "lon": -87.9943008423,
      "tz": "America\/Chicago"
  },
  "WI62": {
      "icao": "WI62",
      "iata": "",
      "name": "Sss Aerodrome",
      "city": "Cedarburg",
      "state": "",
      "country": "US",
      "elevation": 800,
      "lat": 43.2695007324,
      "lon": -87.9981002808,
      "tz": "America\/Chicago"
  },
  "WI65": {
      "icao": "WI65",
      "iata": "",
      "name": "Dane Airport",
      "city": "Dane",
      "state": "",
      "country": "US",
      "elevation": 1035,
      "lat": 43.2221984863,
      "lon": -89.4682006836,
      "tz": "America\/Chicago"
  },
  "WI66": {
      "icao": "WI66",
      "iata": "",
      "name": "Smilin' Sam's Airport",
      "city": "Delavan",
      "state": "",
      "country": "US",
      "elevation": 980,
      "lat": 42.5872001648,
      "lon": -88.658203125,
      "tz": "America\/Chicago"
  },
  "WI67": {
      "icao": "WI67",
      "iata": "",
      "name": "Bennet Field",
      "city": "Ripon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 935,
      "lat": 43.8765983582,
      "lon": -88.9007034302,
      "tz": "America\/Chicago"
  },
  "WI69": {
      "icao": "WI69",
      "iata": "",
      "name": "Air Troy Estates - Restricted Airport",
      "city": "East Troy",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 820,
      "lat": 42.7999992371,
      "lon": -88.3333969116,
      "tz": "America\/Chicago"
  },
  "WI70": {
      "icao": "WI70",
      "iata": "",
      "name": "Swan Airport",
      "city": "Elkhorn",
      "state": "",
      "country": "US",
      "elevation": 930,
      "lat": 42.7070007324,
      "lon": -88.6376037598,
      "tz": "America\/Chicago"
  },
  "WI71": {
      "icao": "WI71",
      "iata": "",
      "name": "Weedhopper Meadow Airport",
      "city": "Elkhorn",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1030,
      "lat": 42.6875,
      "lon": -88.6019973755,
      "tz": "America\/Chicago"
  },
  "WI72": {
      "icao": "WI72",
      "iata": "",
      "name": "Martin Fierro Airport",
      "city": "Ellenboro",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 42.7999992371,
      "lon": -90.5718002319,
      "tz": "America\/Chicago"
  },
  "WI73": {
      "icao": "WI73",
      "iata": "",
      "name": "Happy Jacks Air Strip",
      "city": "Eureka",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 44,
      "lon": -88.8333969116,
      "tz": "America\/Chicago"
  },
  "WI74": {
      "icao": "WI74",
      "iata": "",
      "name": "Pfaffenroth Private Airport",
      "city": "Fisk",
      "state": "",
      "country": "US",
      "elevation": 872,
      "lat": 43.9249992371,
      "lon": -88.6676025391,
      "tz": "America\/Chicago"
  },
  "WI75": {
      "icao": "WI75",
      "iata": "",
      "name": "Bogus Creek Airport",
      "city": "Stockholm",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 950,
      "lat": 44.4989013672,
      "lon": -92.2031021118,
      "tz": "America\/Chicago"
  },
  "WI76": {
      "icao": "WI76",
      "iata": "",
      "name": "J Rock Airport",
      "city": "Fort Atkinson",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 836,
      "lat": 42.948600769,
      "lon": -88.7759017944,
      "tz": "America\/Chicago"
  },
  "WI77": {
      "icao": "WI77",
      "iata": "",
      "name": "Willow Creek Airport",
      "city": "Germantown",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 860,
      "lat": 43.2070007324,
      "lon": -88.1604003906,
      "tz": "America\/Chicago"
  },
  "WI78": {
      "icao": "WI78",
      "iata": "",
      "name": "Martins Aerodrome",
      "city": "Green Bay",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 808,
      "lat": 44.4258003235,
      "lon": -87.9349975586,
      "tz": "America\/Chicago"
  },
  "WI79": {
      "icao": "WI79",
      "iata": "",
      "name": "Town Line Airport",
      "city": "Greenwood",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1270,
      "lat": 44.8526992798,
      "lon": -90.5565032959,
      "tz": "America\/Chicago"
  },
  "WI81": {
      "icao": "WI81",
      "iata": "",
      "name": "Rigdon Private Airport",
      "city": "Hazel Green",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 880,
      "lat": 42.5242004395,
      "lon": -90.591003418,
      "tz": "America\/Chicago"
  },
  "WI84": {
      "icao": "WI84",
      "iata": "",
      "name": "Johnstown Center Airport",
      "city": "Janesville",
      "state": "",
      "country": "US",
      "elevation": 925,
      "lat": 42.6888999939,
      "lon": -88.8404006958,
      "tz": "America\/Chicago"
  },
  "WI86": {
      "icao": "WI86",
      "iata": "",
      "name": "Flaglor Airport",
      "city": "Kansasville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 42.660900116,
      "lon": -88.1359024048,
      "tz": "America\/Chicago"
  },
  "WI87": {
      "icao": "WI87",
      "iata": "",
      "name": "Pine River Airport",
      "city": "Merrill",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1370,
      "lat": 45.2033004761,
      "lon": -89.5243988037,
      "tz": "America\/Chicago"
  },
  "WI88": {
      "icao": "WI88",
      "iata": "",
      "name": "Mount Fuji Airport",
      "city": "Lake Geneva",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 42.625,
      "lon": -88.4501037598,
      "tz": "America\/Chicago"
  },
  "WI89": {
      "icao": "WI89",
      "iata": "",
      "name": "Lake Geneva Aire Estates Airport",
      "city": "Lake Geneva",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 880,
      "lat": 42.5722007751,
      "lon": -88.3673019409,
      "tz": "America\/Chicago"
  },
  "WI90": {
      "icao": "WI90",
      "iata": "",
      "name": "Speedwing Field",
      "city": "Babcock",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 970,
      "lat": 44.2961006165,
      "lon": -90.0437011719,
      "tz": "America\/Chicago"
  },
  "WI91": {
      "icao": "WI91",
      "iata": "",
      "name": "Larson Airport",
      "city": "Larsen",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 44.2028007507,
      "lon": -88.6396026611,
      "tz": "America\/Chicago"
  },
  "WI92": {
      "icao": "WI92",
      "iata": "",
      "name": "Wag-Aero Airport",
      "city": "Lyons",
      "state": "",
      "country": "US",
      "elevation": 905,
      "lat": 42.6610984802,
      "lon": -88.3611984253,
      "tz": "America\/Chicago"
  },
  "WI95": {
      "icao": "WI95",
      "iata": "",
      "name": "Chilcott Farms Airport",
      "city": "Paddock Lake",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 700,
      "lat": 42.5778007507,
      "lon": -88.0154037476,
      "tz": "America\/Chicago"
  },
  "WI97": {
      "icao": "WI97",
      "iata": "",
      "name": "Mathaire Field",
      "city": "Marshall",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 960,
      "lat": 43.2578010559,
      "lon": -89.0647964478,
      "tz": "America\/Chicago"
  },
  "WI98": {
      "icao": "WI98",
      "iata": "",
      "name": "Blackburn Airport",
      "city": "Cooksville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 888,
      "lat": 42.8582992554,
      "lon": -89.197303772,
      "tz": "America\/Chicago"
  },
  "WI99": {
      "icao": "WI99",
      "iata": "",
      "name": "Meier Airport",
      "city": "Milton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 880,
      "lat": 42.8135986328,
      "lon": -88.9971008301,
      "tz": "America\/Chicago"
  },
  "WIAG": {
      "icao": "WIAG",
      "iata": "AKQ",
      "name": "Menggala Airport",
      "city": "Menggala-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 98,
      "lat": -4.4894399643,
      "lon": 105.2170028687,
      "tz": "Asia\/Jakarta"
  },
  "WIAJ": {
      "icao": "WIAJ",
      "iata": "",
      "name": "Semplak Airport",
      "city": "",
      "state": "",
      "country": "ID",
      "elevation": 0,
      "lat": -6.5402777778,
      "lon": 106.755277778,
      "tz": "Asia\/Jakarta"
  },
  "WIAK": {
      "icao": "WIAK",
      "iata": "",
      "name": "Margahayu Airport",
      "city": "Bandung-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 2184,
      "lat": -6.9827799797,
      "lon": 107.572998047,
      "tz": "Asia\/Jakarta"
  },
  "WIAP": {
      "icao": "WIAP",
      "iata": "",
      "name": "Banyumas Airport",
      "city": "Banyumas-Java Island",
      "state": "",
      "country": "ID",
      "elevation": 92,
      "lat": -6.4648499489,
      "lon": 105.96900177,
      "tz": "Asia\/Jakarta"
  },
  "WIBB": {
      "icao": "WIBB",
      "iata": "PKU",
      "name": "Sultan Syarif Kasim Ii (Simpang Tiga) Airport",
      "city": "Pekanbaru-Sumatra Island",
      "state": "Riau",
      "country": "ID",
      "elevation": 102,
      "lat": 0.4607860148,
      "lon": 101.4449996948,
      "tz": "Asia\/Jakarta"
  },
  "WIBD": {
      "icao": "WIBD",
      "iata": "DUM",
      "name": "Pinang Kampai Airport",
      "city": "Dumai-Sumatra Island",
      "state": "Riau",
      "country": "ID",
      "elevation": 55,
      "lat": 1.6091899872,
      "lon": 101.4339981079,
      "tz": "Asia\/Jakarta"
  },
  "WIBR": {
      "icao": "WIBR",
      "iata": "RKO",
      "name": "Rokot Airport",
      "city": "Sipora Island",
      "state": "West-Sumatra",
      "country": "ID",
      "elevation": 1,
      "lat": -2.0991199017,
      "lon": 99.7006988525,
      "tz": "Asia\/Jakarta"
  },
  "WIBS": {
      "icao": "WIBS",
      "iata": "SEQ",
      "name": "Sungai Pakning Bengkalis Airport",
      "city": "Bengkalis-Sumatra Island",
      "state": "Riau",
      "country": "ID",
      "elevation": 3,
      "lat": 1.3700000048,
      "lon": 102.1399993896,
      "tz": "Asia\/Jakarta"
  },
  "WIBT": {
      "icao": "WIBT",
      "iata": "TJB",
      "name": "Tanjung Balai Airport",
      "city": "Karinmunbesar Island",
      "state": "Riau-Islands",
      "country": "ID",
      "elevation": 3,
      "lat": 1.0499999523,
      "lon": 103.3830032349,
      "tz": "Asia\/Jakarta"
  },
  "WICB": {
      "icao": "WICB",
      "iata": "",
      "name": "Budiarto Airport",
      "city": "Tangerang-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 151,
      "lat": -6.2931699753,
      "lon": 106.569999695,
      "tz": "Asia\/Jakarta"
  },
  "WICC": {
      "icao": "WICC",
      "iata": "BDO",
      "name": "Husein Sastranegara International Airport",
      "city": "Bandung-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 2436,
      "lat": -6.9006299973,
      "lon": 107.5759963989,
      "tz": "Asia\/Jakarta"
  },
  "WICD": {
      "icao": "WICD",
      "iata": "CBN",
      "name": "Penggung Airport",
      "city": "Cirebon-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 89,
      "lat": -6.7561402321,
      "lon": 108.540000916,
      "tz": "Asia\/Jakarta"
  },
  "WICM": {
      "icao": "WICM",
      "iata": "TSY",
      "name": "Cibeureum Airport",
      "city": "Tasikmalaya-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 1148,
      "lat": -7.3466000557,
      "lon": 108.246002197,
      "tz": "Asia\/Jakarta"
  },
  "WICT": {
      "icao": "WICT",
      "iata": "TKG",
      "name": "Radin Inten II (Branti) Airport",
      "city": "Bandar Lampung-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 282,
      "lat": -5.240556,
      "lon": 105.175556,
      "tz": "Asia\/Jakarta"
  },
  "WIDD": {
      "icao": "WIDD",
      "iata": "BTH",
      "name": "Hang Nadim Airport",
      "city": "Batam Island",
      "state": "",
      "country": "ID",
      "elevation": 126,
      "lat": 1.121029973,
      "lon": 104.1190032959,
      "tz": "Asia\/Jakarta"
  },
  "WIDE": {
      "icao": "WIDE",
      "iata": "PPR",
      "name": "Pasir Pangaraan Airport",
      "city": "Pasir Pengarayan-Sumatra Island",
      "state": "Riau",
      "country": "ID",
      "elevation": 151,
      "lat": 0.8454310298,
      "lon": 100.3700027466,
      "tz": "Asia\/Jakarta"
  },
  "WIDN": {
      "icao": "WIDN",
      "iata": "TNJ",
      "name": "Kijang Airport",
      "city": "Tanjung Pinang-Bintan Island",
      "state": "Riau-Islands",
      "country": "ID",
      "elevation": 52,
      "lat": 0.9226830006,
      "lon": 104.531997681,
      "tz": "Asia\/Jakarta"
  },
  "WIDS": {
      "icao": "WIDS",
      "iata": "SIQ",
      "name": "Dabo Airport",
      "city": "Pasirkuning-Singkep Island",
      "state": "Riau-Islands",
      "country": "ID",
      "elevation": 95,
      "lat": -0.4791890085,
      "lon": 104.5790023804,
      "tz": "Asia\/Jakarta"
  },
  "WIEE": {
      "icao": "WIEE",
      "iata": "PDG",
      "name": "Minangkabau Airport",
      "city": "Ketaping\/Padang-Sumatra Island",
      "state": "Ketaping",
      "country": "ID",
      "elevation": 18,
      "lat": -0.7869169712,
      "lon": 100.28099823,
      "tz": "Asia\/Jakarta"
  },
  "WIHH": {
      "icao": "WIHH",
      "iata": "HLP",
      "name": "Halim Perdanakusuma International Airport",
      "city": "Jakarta",
      "state": "Jakarta",
      "country": "ID",
      "elevation": 84,
      "lat": -6.2666101456,
      "lon": 106.8909988403,
      "tz": "Asia\/Jakarta"
  },
  "WIHL": {
      "icao": "WIHL",
      "iata": "CXP",
      "name": "Tunggul Wulung Airport",
      "city": "Cilacap-Java Island",
      "state": "",
      "country": "ID",
      "elevation": 69,
      "lat": -7.6450600624,
      "lon": 109.033996582,
      "tz": "Asia\/Jakarta"
  },
  "WIHP": {
      "icao": "WIHP",
      "iata": "PCB",
      "name": "Pondok Cabe Air Base",
      "city": "Jakarta",
      "state": "",
      "country": "ID",
      "elevation": 200,
      "lat": -6.3369598389,
      "lon": 106.7649993896,
      "tz": "Asia\/Jakarta"
  },
  "WIIG": {
      "icao": "WIIG",
      "iata": "PPJ",
      "name": "Pulau Panjang Airport",
      "city": "Tjipara-Java Island",
      "state": "Jakarta",
      "country": "ID",
      "elevation": 155,
      "lat": -5.644444,
      "lon": 106.5625,
      "tz": "Asia\/Jakarta"
  },
  "WIII": {
      "icao": "WIII",
      "iata": "CGK",
      "name": "Soekarno-Hatta International Airport",
      "city": "Jakarta",
      "state": "",
      "country": "ID",
      "elevation": 34,
      "lat": -6.1255698204,
      "lon": 106.65599823,
      "tz": "Asia\/Jakarta"
  },
  "WIIK": {
      "icao": "WIIK",
      "iata": "",
      "name": "Kalijati Airport",
      "city": "Kalijati-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 361,
      "lat": -6.5313901901,
      "lon": 107.658996582,
      "tz": "Asia\/Jakarta"
  },
  "WIIR": {
      "icao": "WIIR",
      "iata": "",
      "name": "Pelabuhan Ratu Airport",
      "city": "Pelabuhan Ratu-Java Island",
      "state": "West-Java",
      "country": "ID",
      "elevation": 65,
      "lat": -6.9766669273,
      "lon": 106.5327758789,
      "tz": "Asia\/Jakarta"
  },
  "WIKL": {
      "icao": "WIKL",
      "iata": "",
      "name": "Silampari Airport",
      "city": "Lubuk Linggau-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 371,
      "lat": -3.2777500153,
      "lon": 102.901000977,
      "tz": "Asia\/Jakarta"
  },
  "WIMA": {
      "icao": "WIMA",
      "iata": "",
      "name": "Labuhan Bilik Airport",
      "city": "Labuhan Bilik-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 18,
      "lat": 2.5129001141,
      "lon": 100.1790008545,
      "tz": "Asia\/Jakarta"
  },
  "WIMB": {
      "icao": "WIMB",
      "iata": "GNS",
      "name": "Binaka Airport",
      "city": "Gunung Sitoli-Nias Island",
      "state": "North-Sumatra",
      "country": "ID",
      "elevation": 20,
      "lat": 1.1663800478,
      "lon": 97.7046966553,
      "tz": "Asia\/Jakarta"
  },
  "WIME": {
      "icao": "WIME",
      "iata": "AEG",
      "name": "Aek Godang Airport",
      "city": "Padang Sidempuan-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 922,
      "lat": 1.4000999928,
      "lon": 99.4304962158,
      "tz": "Asia\/Jakarta"
  },
  "WIMG": {
      "icao": "WIMG",
      "iata": "",
      "name": "Tabing Airport",
      "city": "Padang-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 9,
      "lat": -0.8749889731,
      "lon": 100.351997375,
      "tz": "Asia\/Jakarta"
  },
  "WIMH": {
      "icao": "WIMH",
      "iata": "",
      "name": "Helvetia Airport",
      "city": "Helvetia-Sumatra Island",
      "state": "North-Sumatra",
      "country": "ID",
      "elevation": 49,
      "lat": 3.6830599308,
      "lon": 98.644203186,
      "tz": "Asia\/Jakarta"
  },
  "WIMK": {
      "icao": "WIMK",
      "iata": "MES",
      "name": "Soewondo Air Force Base",
      "city": "Medan",
      "state": "",
      "country": "ID",
      "elevation": 114,
      "lat": 3.559167,
      "lon": 98.671111,
      "tz": "Asia\/Jakarta"
  },
  "WIML": {
      "icao": "WIML",
      "iata": "",
      "name": "Kisaran Airport",
      "city": "Kisaran-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 96,
      "lat": 2.9737899303,
      "lon": 99.6009979248,
      "tz": "Asia\/Jakarta"
  },
  "WIMM": {
      "icao": "WIMM",
      "iata": "KNO",
      "name": "Polonia International Airport",
      "city": "Medan-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 114,
      "lat": 3.5580599308,
      "lon": 98.6716995239,
      "tz": "Asia\/Jakarta"
  },
  "WIMN": {
      "icao": "WIMN",
      "iata": "DTB",
      "name": "Sisingamangaraja XII International Airport",
      "city": "Siborong-Borong",
      "state": "North-Sumatra",
      "country": "ID",
      "elevation": 4660,
      "lat": 2.259722,
      "lon": 98.995278,
      "tz": "Asia\/Jakarta"
  },
  "WIMO": {
      "icao": "WIMO",
      "iata": "",
      "name": "Bandar Udara Lasondre",
      "city": "",
      "state": "",
      "country": "ID",
      "elevation": 5,
      "lat": -0.01924,
      "lon": 98.30097,
      "tz": "Asia\/Jakarta"
  },
  "WIMP": {
      "icao": "WIMP",
      "iata": "SIW",
      "name": "Parapat Airport",
      "city": "Parapat-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 3081,
      "lat": 2.6666669846,
      "lon": 98.9333343506,
      "tz": "Asia\/Jakarta"
  },
  "WIMR": {
      "icao": "WIMR",
      "iata": "",
      "name": "Pematang Siantar",
      "city": "Pematang Siantar-Sumatra Island",
      "state": "North-Sumatra",
      "country": "ID",
      "elevation": 1361,
      "lat": 2.9500000477,
      "lon": 99.0500030518,
      "tz": "Asia\/Jakarta"
  },
  "WIMS": {
      "icao": "WIMS",
      "iata": "FLZ",
      "name": "Dr Ferdinand Lumban Tobing Airport",
      "city": "Sibolga-Sumatra Island",
      "state": "North-Sumatra",
      "country": "ID",
      "elevation": 43,
      "lat": 1.555940032,
      "lon": 98.8889007568,
      "tz": "Asia\/Jakarta"
  },
  "WIMT": {
      "icao": "WIMT",
      "iata": "",
      "name": "Tebing Tinggi Airport",
      "city": "Tabbing Tinggi-Sumatra Island",
      "state": "North-Sumatra",
      "country": "ID",
      "elevation": 69,
      "lat": 3.3666670322,
      "lon": 99.1166687012,
      "tz": "Asia\/Jakarta"
  },
  "WIOB": {
      "icao": "WIOB",
      "iata": "",
      "name": "Bengkayang Airport",
      "city": "Bengkayang-Borneo Island",
      "state": "West-Kalimantan",
      "country": "ID",
      "elevation": 294,
      "lat": 0.8333330154,
      "lon": 109.4833297729,
      "tz": "Asia\/Pontianak"
  },
  "WIOD": {
      "icao": "WIOD",
      "iata": "TJQ",
      "name": "Buluh Tumbang (H A S Hanandjoeddin) Airport",
      "city": "Tanjung Pandan-Belitung Island",
      "state": "Bangka\u2013Belitung-Islands",
      "country": "ID",
      "elevation": 164,
      "lat": -2.7457199097,
      "lon": 107.754997253,
      "tz": "Asia\/Jakarta"
  },
  "WIOG": {
      "icao": "WIOG",
      "iata": "NPO",
      "name": "Nanga Pinoh I Airport",
      "city": "Nanga Pinoh-Borneo Island",
      "state": "West-Kalimantan",
      "country": "ID",
      "elevation": 123,
      "lat": -0.3488689959,
      "lon": 111.7480010986,
      "tz": "Asia\/Pontianak"
  },
  "WIOI": {
      "icao": "WIOI",
      "iata": "",
      "name": "Singkawang Airport",
      "city": "Sinkawang-Borneo Island",
      "state": "",
      "country": "ID",
      "elevation": 276,
      "lat": 1.0756800175,
      "lon": 109.68800354,
      "tz": "Asia\/Pontianak"
  },
  "WIOK": {
      "icao": "WIOK",
      "iata": "KTG",
      "name": "Ketapang(Rahadi Usman) Airport",
      "city": "Ketapang-Borneo Island",
      "state": "",
      "country": "ID",
      "elevation": 46,
      "lat": -1.8166400194,
      "lon": 109.9629974365,
      "tz": "Asia\/Pontianak"
  },
  "WIOM": {
      "icao": "WIOM",
      "iata": "MWK",
      "name": "Tarempa Airport",
      "city": "Matak Island",
      "state": "Riau",
      "country": "ID",
      "elevation": 10,
      "lat": 3.3481199741,
      "lon": 106.2580032349,
      "tz": "Asia\/Jakarta"
  },
  "WION": {
      "icao": "WION",
      "iata": "NTX",
      "name": "Ranai Airport",
      "city": "Ranai-Natuna Besar Island",
      "state": "Riau-Islands",
      "country": "ID",
      "elevation": 7,
      "lat": 3.9087100029,
      "lon": 108.3880004883,
      "tz": "Asia\/Jakarta"
  },
  "WIOO": {
      "icao": "WIOO",
      "iata": "PNK",
      "name": "Supadio Airport",
      "city": "Pontianak-Borneo Island",
      "state": "West-Kalimantan",
      "country": "ID",
      "elevation": 10,
      "lat": -0.150711,
      "lon": 109.4039993286,
      "tz": "Asia\/Pontianak"
  },
  "WIOP": {
      "icao": "WIOP",
      "iata": "PSU",
      "name": "Pangsuma Airport",
      "city": "Putussibau-Borneo Island",
      "state": "West-Kalimantan",
      "country": "ID",
      "elevation": 297,
      "lat": 0.8355780244,
      "lon": 112.93699646,
      "tz": "Asia\/Pontianak"
  },
  "WIOS": {
      "icao": "WIOS",
      "iata": "SQG",
      "name": "Sintang(Susilo) Airport",
      "city": "Sintang-Borneo Island",
      "state": "",
      "country": "ID",
      "elevation": 98,
      "lat": 0.0636190027,
      "lon": 111.4729995728,
      "tz": "Asia\/Pontianak"
  },
  "WIPA": {
      "icao": "WIPA",
      "iata": "DJB",
      "name": "Sultan Thaha Airport",
      "city": "Jambi-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 82,
      "lat": -1.6380200386,
      "lon": 103.6439971924,
      "tz": "Asia\/Jakarta"
  },
  "WIPD": {
      "icao": "WIPD",
      "iata": "",
      "name": "Banding Agung Airport",
      "city": "Pasar Bandingagung-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 2100,
      "lat": -4.7870202065,
      "lon": 103.9329986572,
      "tz": "Asia\/Jakarta"
  },
  "WIPF": {
      "icao": "WIPF",
      "iata": "",
      "name": "Kuala Tungkal Airport",
      "city": "Kuala Tungkal-Sumatra Island",
      "state": "Jambi",
      "country": "ID",
      "elevation": 12,
      "lat": -0.8166670203,
      "lon": 103.4666671753,
      "tz": "Asia\/Jakarta"
  },
  "WIPK": {
      "icao": "WIPK",
      "iata": "PGK",
      "name": "Pangkal Pinang (Depati Amir) Airport",
      "city": "Pangkal Pinang-Palaubangka Island",
      "state": "South-Sumatra",
      "country": "ID",
      "elevation": 109,
      "lat": -2.1621999741,
      "lon": 106.138999939,
      "tz": "Asia\/Jakarta"
  },
  "WIPL": {
      "icao": "WIPL",
      "iata": "BKS",
      "name": "Padang Kemiling (Fatmawati Soekarno) Airport",
      "city": "Bengkulu-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 50,
      "lat": -3.863699913,
      "lon": 102.3389968872,
      "tz": "Asia\/Jakarta"
  },
  "WIPO": {
      "icao": "WIPO",
      "iata": "",
      "name": "Gatot Subrato Airport",
      "city": "Batu Raja-Sumatra Island",
      "state": "Lampung",
      "country": "ID",
      "elevation": 450,
      "lat": -4.391769886,
      "lon": 104.4010009766,
      "tz": "Asia\/Jakarta"
  },
  "WIPP": {
      "icao": "WIPP",
      "iata": "PLM",
      "name": "Sultan Mahmud Badaruddin Ii Airport",
      "city": "Palembang-Sumatra Island",
      "state": "South-Sumatra",
      "country": "ID",
      "elevation": 49,
      "lat": -2.898250103,
      "lon": 104.6999969482,
      "tz": "Asia\/Jakarta"
  },
  "WIPQ": {
      "icao": "WIPQ",
      "iata": "PDO",
      "name": "Pendopo Airport",
      "city": "Talang Gudang-Sumatra Island",
      "state": "South-Sumatra",
      "country": "ID",
      "elevation": 184,
      "lat": -3.2860701084,
      "lon": 103.8799972534,
      "tz": "Asia\/Jakarta"
  },
  "WIPR": {
      "icao": "WIPR",
      "iata": "RGT",
      "name": "Japura Airport",
      "city": "Rengat-Sumatra Island",
      "state": "Riau-Islands",
      "country": "ID",
      "elevation": 62,
      "lat": -0.3528079987,
      "lon": 102.3349990845,
      "tz": "Asia\/Jakarta"
  },
  "WIPT": {
      "icao": "WIPT",
      "iata": "PDG",
      "name": "Minangkabau Airport",
      "city": "Ketaping\/Padang-Sumatra Island",
      "state": "West-Sumatra",
      "country": "ID",
      "elevation": 18,
      "lat": -0.7869169712,
      "lon": 100.28099823,
      "tz": "Asia\/Jakarta"
  },
  "WIPU": {
      "icao": "WIPU",
      "iata": "MPC",
      "name": "Muko Muko Airport",
      "city": "Muko Muko-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 24,
      "lat": -2.5418601036,
      "lon": 101.0879974365,
      "tz": "Asia\/Jakarta"
  },
  "WIPV": {
      "icao": "WIPV",
      "iata": "KLQ",
      "name": "Keluang Airport",
      "city": "Keluang-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 76,
      "lat": -2.623529911,
      "lon": 103.9550018311,
      "tz": "Asia\/Jakarta"
  },
  "WIPY": {
      "icao": "WIPY",
      "iata": "",
      "name": "Bentayan Airport",
      "city": "Bentayan-Sumatra Island",
      "state": "",
      "country": "ID",
      "elevation": 40,
      "lat": -2.434429884,
      "lon": 104.0920028687,
      "tz": "Asia\/Jakarta"
  },
  "WITA": {
      "icao": "WITA",
      "iata": "TPK",
      "name": "Teuku Cut Ali Airport",
      "city": "Tapak Tuan-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 103,
      "lat": 3.263420105,
      "lon": 97.1837997437,
      "tz": "Asia\/Jakarta"
  },
  "WITC": {
      "icao": "WITC",
      "iata": "MEQ",
      "name": "Seunagan Airport",
      "city": "Peureumeue-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 10,
      "lat": 4.25,
      "lon": 96.2170028687,
      "tz": "Asia\/Jakarta"
  },
  "WITG": {
      "icao": "WITG",
      "iata": "",
      "name": "Lasikin Airport",
      "city": "Lubang-Simeulue Island",
      "state": "",
      "country": "ID",
      "elevation": 19,
      "lat": 2.4102799892,
      "lon": 96.3255996704,
      "tz": "Asia\/Jakarta"
  },
  "WITL": {
      "icao": "WITL",
      "iata": "LSX",
      "name": "Lhok Sukon Airport",
      "city": "Lhok Sukon-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 28,
      "lat": 5.0695099831,
      "lon": 97.2592010498,
      "tz": "Asia\/Jakarta"
  },
  "WITM": {
      "icao": "WITM",
      "iata": "LSW",
      "name": "Malikus Saleh Airport",
      "city": "Lhok Seumawe-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 90,
      "lat": 5.2266798019,
      "lon": 96.950302124,
      "tz": "Asia\/Jakarta"
  },
  "WITN": {
      "icao": "WITN",
      "iata": "SBG",
      "name": "Maimun Saleh Airport",
      "city": "Sabang-We Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 393,
      "lat": 5.8741297722,
      "lon": 95.3396987915,
      "tz": "Asia\/Jakarta"
  },
  "WITS": {
      "icao": "WITS",
      "iata": "",
      "name": "Seumayam Airport",
      "city": "Seumayam-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 53,
      "lat": 3.75,
      "lon": 96.6333312988,
      "tz": "Asia\/Jakarta"
  },
  "WITT": {
      "icao": "WITT",
      "iata": "BTJ",
      "name": "Sultan Iskandarmuda Airport",
      "city": "Banda Aceh-Sumatra Island",
      "state": "Aceh",
      "country": "ID",
      "elevation": 65,
      "lat": 5.5235199928,
      "lon": 95.4204025269,
      "tz": "Asia\/Jakarta"
  },
  "WMAA": {
      "icao": "WMAA",
      "iata": "",
      "name": "Bahau Airport",
      "city": "Bahau",
      "state": "Negeri-Sembilan",
      "country": "MY",
      "elevation": 214,
      "lat": 2.8166699409,
      "lon": 102.4169998169,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAB": {
      "icao": "WMAB",
      "iata": "",
      "name": "Batu Pahat Airport",
      "city": "Batu Pahat",
      "state": "Johor",
      "country": "MY",
      "elevation": 30,
      "lat": 1.8602420092,
      "lon": 102.9010009766,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAC": {
      "icao": "WMAC",
      "iata": "",
      "name": "Benta Airport",
      "city": "Benta",
      "state": "Pahang",
      "country": "MY",
      "elevation": 250,
      "lat": 4.0500001907,
      "lon": 101.983001709,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAD": {
      "icao": "WMAD",
      "iata": "",
      "name": "Bentong Airport",
      "city": "Bentong",
      "state": "Pahang",
      "country": "MY",
      "elevation": 350,
      "lat": 3.5499999523,
      "lon": 101.8830032349,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAE": {
      "icao": "WMAE",
      "iata": "",
      "name": "Bidor Airport",
      "city": "Bidor",
      "state": "Perak",
      "country": "MY",
      "elevation": 128,
      "lat": 4.1163887978,
      "lon": 101.2819976807,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAG": {
      "icao": "WMAG",
      "iata": "",
      "name": "Dungun Airport",
      "city": "Dungun",
      "state": "",
      "country": "MY",
      "elevation": 22,
      "lat": 4.7862901688,
      "lon": 103.3820037842,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAH": {
      "icao": "WMAH",
      "iata": "",
      "name": "Grik Airport",
      "city": "Grik",
      "state": "",
      "country": "MY",
      "elevation": 420,
      "lat": 5.4327697754,
      "lon": 101.1230010986,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAJ": {
      "icao": "WMAJ",
      "iata": "",
      "name": "Jendarata Airport",
      "city": "Jendarata",
      "state": "Perak",
      "country": "MY",
      "elevation": 8,
      "lat": 3.8997199535,
      "lon": 100.9489974976,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAL": {
      "icao": "WMAL",
      "iata": "",
      "name": "Kuala Kerai Airport",
      "city": "Kuala Kerai",
      "state": "Kelantan",
      "country": "MY",
      "elevation": 137,
      "lat": 5.5386800766,
      "lon": 102.1949996948,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAN": {
      "icao": "WMAN",
      "iata": "SXT",
      "name": "Sungai Tiang Airport",
      "city": "Taman Negara",
      "state": "Pahang",
      "country": "MY",
      "elevation": 10,
      "lat": 4.3302779198,
      "lon": 102.3949966431,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAP": {
      "icao": "WMAP",
      "iata": "",
      "name": "Kluang Airport",
      "city": "Kluang",
      "state": "",
      "country": "MY",
      "elevation": 150,
      "lat": 2.0413899422,
      "lon": 103.306999207,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAQ": {
      "icao": "WMAQ",
      "iata": "",
      "name": "Labis Airport",
      "city": "Labis",
      "state": "",
      "country": "MY",
      "elevation": 179,
      "lat": 2.4003798962,
      "lon": 103.0400009155,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAU": {
      "icao": "WMAU",
      "iata": "MEP",
      "name": "Mersing Airport",
      "city": "Mersing",
      "state": "Johor",
      "country": "MY",
      "elevation": 10,
      "lat": 2.382999897,
      "lon": 103.8669967651,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAV": {
      "icao": "WMAV",
      "iata": "",
      "name": "Muar \/ Bakri Airport",
      "city": "Muar",
      "state": "Johor",
      "country": "MY",
      "elevation": 20,
      "lat": 2.0499999523,
      "lon": 102.6500015259,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMAZ": {
      "icao": "WMAZ",
      "iata": "",
      "name": "Segamat Airport",
      "city": "Segamat",
      "state": "Johor",
      "country": "MY",
      "elevation": 50,
      "lat": 2.5,
      "lon": 102.8330001831,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMBA": {
      "icao": "WMBA",
      "iata": "SWY",
      "name": "Sitiawan Airport",
      "city": "Sitiawan",
      "state": "Perak",
      "country": "MY",
      "elevation": 25,
      "lat": 4.2163882256,
      "lon": 100.6986083984,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMBB": {
      "icao": "WMBB",
      "iata": "",
      "name": "Sungei Patani Airport",
      "city": "Sungei Patani",
      "state": "Kedah",
      "country": "MY",
      "elevation": 90,
      "lat": 5.6176199913,
      "lon": 100.5429992676,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMBE": {
      "icao": "WMBE",
      "iata": "",
      "name": "Termeloh Airport",
      "city": "Temerloh",
      "state": "Pahang",
      "country": "MY",
      "elevation": 100,
      "lat": 3.4670000076,
      "lon": 102.3830032349,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMBF": {
      "icao": "WMBF",
      "iata": "",
      "name": "Ulu Bernam Airport",
      "city": "Ulu Bernam",
      "state": "",
      "country": "MY",
      "elevation": 10,
      "lat": 3.75,
      "lon": 101.1669998169,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMBH": {
      "icao": "WMBH",
      "iata": "",
      "name": "Kroh Airport",
      "city": "Kroh",
      "state": "",
      "country": "MY",
      "elevation": 1000,
      "lat": 5.6830000877,
      "lon": 100.983001709,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMBI": {
      "icao": "WMBI",
      "iata": "TPG",
      "name": "Taiping (Tekah) Airport",
      "city": "Taiping",
      "state": "Perak",
      "country": "MY",
      "elevation": 40,
      "lat": 4.867000103,
      "lon": 100.7170028687,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMBT": {
      "icao": "WMBT",
      "iata": "TOD",
      "name": "Pulau Tioman Airport",
      "city": "Pulau Tioman",
      "state": "Johor",
      "country": "MY",
      "elevation": 15,
      "lat": 2.8181800842,
      "lon": 104.1600036621,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMGK": {
      "icao": "WMGK",
      "iata": "",
      "name": "Gong Kedak Airport",
      "city": "",
      "state": "Terengganu",
      "country": "MY",
      "elevation": 20,
      "lat": 5.7995700836,
      "lon": 102.4840011597,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKA": {
      "icao": "WMKA",
      "iata": "AOR",
      "name": "Sultan Abdul Halim Airport",
      "city": "Alor Satar",
      "state": "",
      "country": "MY",
      "elevation": 15,
      "lat": 6.1896700859,
      "lon": 100.3980026245,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKB": {
      "icao": "WMKB",
      "iata": "BWH",
      "name": "Butterworth Airport",
      "city": "Butterworth",
      "state": "",
      "country": "MY",
      "elevation": 11,
      "lat": 5.4659199715,
      "lon": 100.3909988403,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKC": {
      "icao": "WMKC",
      "iata": "KBR",
      "name": "Sultan Ismail Petra Airport",
      "city": "Kota Baharu",
      "state": "Kelantan",
      "country": "MY",
      "elevation": 16,
      "lat": 6.16685009,
      "lon": 102.2929992676,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKD": {
      "icao": "WMKD",
      "iata": "KUA",
      "name": "Kuantan Airport",
      "city": "Kuantan",
      "state": "Pahang",
      "country": "MY",
      "elevation": 58,
      "lat": 3.7753899097,
      "lon": 103.2089996338,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKE": {
      "icao": "WMKE",
      "iata": "KTE",
      "name": "Kerteh Airport",
      "city": "Kerteh",
      "state": "Terengganu",
      "country": "MY",
      "elevation": 18,
      "lat": 4.5372200012,
      "lon": 103.4270019531,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKF": {
      "icao": "WMKF",
      "iata": "",
      "name": "Simpang Airport",
      "city": "Kuala Lumpur",
      "state": "",
      "country": "MY",
      "elevation": 111,
      "lat": 3.1122500896,
      "lon": 101.7030029297,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKI": {
      "icao": "WMKI",
      "iata": "IPH",
      "name": "Sultan Azlan Shah Airport",
      "city": "Ipoh",
      "state": "",
      "country": "MY",
      "elevation": 130,
      "lat": 4.567969799,
      "lon": 101.0920028687,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKJ": {
      "icao": "WMKJ",
      "iata": "JHB",
      "name": "Senai International Airport",
      "city": "Senai",
      "state": "Johor",
      "country": "MY",
      "elevation": 135,
      "lat": 1.6413099766,
      "lon": 103.6699981689,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKK": {
      "icao": "WMKK",
      "iata": "KUL",
      "name": "Kuala Lumpur International Airport",
      "city": "Kuala Lumpur",
      "state": "",
      "country": "MY",
      "elevation": 69,
      "lat": 2.745579958,
      "lon": 101.7099990845,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKL": {
      "icao": "WMKL",
      "iata": "LGK",
      "name": "Langkawi International Airport",
      "city": "Langkawi",
      "state": "Kedah",
      "country": "MY",
      "elevation": 29,
      "lat": 6.3297300339,
      "lon": 99.7286987305,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKM": {
      "icao": "WMKM",
      "iata": "MKZ",
      "name": "Malacca Airport",
      "city": "Malacca",
      "state": "",
      "country": "MY",
      "elevation": 35,
      "lat": 2.2633600235,
      "lon": 102.251998901,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKN": {
      "icao": "WMKN",
      "iata": "TGG",
      "name": "Sultan Mahmud Airport",
      "city": "Kuala Terengganu",
      "state": "Terengganu",
      "country": "MY",
      "elevation": 21,
      "lat": 5.3826398849,
      "lon": 103.1029968262,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMKP": {
      "icao": "WMKP",
      "iata": "PEN",
      "name": "Penang International Airport",
      "city": "Penang",
      "state": "",
      "country": "MY",
      "elevation": 11,
      "lat": 5.2971401215,
      "lon": 100.2770004272,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMLU": {
      "icao": "WMLU",
      "iata": "",
      "name": "Lutong Airport",
      "city": "",
      "state": "Sarawak",
      "country": "MY",
      "elevation": 0,
      "lat": 4.4499998093,
      "lon": 114,
      "tz": "Asia\/Kuching"
  },
  "WMPA": {
      "icao": "WMPA",
      "iata": "PKG",
      "name": "Pulau Pangkor Airport",
      "city": "Pangkor Island",
      "state": "",
      "country": "MY",
      "elevation": 19,
      "lat": 4.2447199821,
      "lon": 100.5530014038,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMPR": {
      "icao": "WMPR",
      "iata": "RDN",
      "name": "LTS Pulau Redang Airport",
      "city": "Redang",
      "state": "",
      "country": "MY",
      "elevation": 36,
      "lat": 5.7652797699,
      "lon": 103.0070037842,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WMSA": {
      "icao": "WMSA",
      "iata": "SZB",
      "name": "Sultan Abdul Aziz Shah International Airport",
      "city": "Subang",
      "state": "",
      "country": "MY",
      "elevation": 90,
      "lat": 3.1305799484,
      "lon": 101.5490036011,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WN00": {
      "icao": "WN00",
      "iata": "",
      "name": "Kimshan Ranch Airport",
      "city": "Quilcene",
      "state": "",
      "country": "US",
      "elevation": 80,
      "lat": 47.8400993347,
      "lon": -122.8600006104,
      "tz": "America\/Los_Angeles"
  },
  "WN03": {
      "icao": "WN03",
      "iata": "",
      "name": "Van Der Vaart Airport",
      "city": "Sheboygan",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 622,
      "lat": 43.6786003113,
      "lon": -87.7256011963,
      "tz": "America\/Chicago"
  },
  "WN04": {
      "icao": "WN04",
      "iata": "",
      "name": "Erickson Ranch Airport",
      "city": "Colville",
      "state": "Washington",
      "country": "US",
      "elevation": 2924,
      "lat": 48.3923988342,
      "lon": -118.0989990234,
      "tz": "America\/Los_Angeles"
  },
  "WN05": {
      "icao": "WN05",
      "iata": "",
      "name": "Harris Airport",
      "city": "Toledo",
      "state": "Washington",
      "country": "US",
      "elevation": 385,
      "lat": 46.5148010254,
      "lon": -122.7919998169,
      "tz": "America\/Los_Angeles"
  },
  "WN07": {
      "icao": "WN07",
      "iata": "DTR",
      "name": "Decatur Shores Airport",
      "city": "Decatur",
      "state": "Washington",
      "country": "US",
      "elevation": 38,
      "lat": 48.4998016357,
      "lon": -122.814002991,
      "tz": "America\/Los_Angeles"
  },
  "WN08": {
      "icao": "WN08",
      "iata": "",
      "name": "Kendall Airstrip",
      "city": "Kendall",
      "state": "",
      "country": "US",
      "elevation": 548,
      "lat": 48.9147987366,
      "lon": -122.1129989624,
      "tz": "America\/Los_Angeles"
  },
  "WN09": {
      "icao": "WN09",
      "iata": "",
      "name": "Bucky's Airpark",
      "city": "Plainfield",
      "state": "",
      "country": "US",
      "elevation": 1145,
      "lat": 44.1643981934,
      "lon": -89.508102417,
      "tz": "America\/Chicago"
  },
  "WN10": {
      "icao": "WN10",
      "iata": "",
      "name": "Mount St Helen's Aero Ranch Airport",
      "city": "Cougar",
      "state": "Washington",
      "country": "US",
      "elevation": 450,
      "lat": 45.9934005737,
      "lon": -122.3799972534,
      "tz": "America\/Los_Angeles"
  },
  "WN11": {
      "icao": "WN11",
      "iata": "",
      "name": "Red Roof Airport",
      "city": "Beecher",
      "state": "",
      "country": "US",
      "elevation": 963,
      "lat": 45.5858001709,
      "lon": -88.0037002563,
      "tz": "America\/Chicago"
  },
  "WN13": {
      "icao": "WN13",
      "iata": "",
      "name": "Vaughan Ranch Airfield",
      "city": "Port Orchard",
      "state": "",
      "country": "US",
      "elevation": 240,
      "lat": 47.4636993408,
      "lon": -122.5690002441,
      "tz": "America\/Los_Angeles"
  },
  "WN14": {
      "icao": "WN14",
      "iata": "",
      "name": "Pete's Airport",
      "city": "Deer Park",
      "state": "Washington",
      "country": "US",
      "elevation": 2160,
      "lat": 47.8415985107,
      "lon": -117.4469985962,
      "tz": "America\/Los_Angeles"
  },
  "WN15": {
      "icao": "WN15",
      "iata": "",
      "name": "Burnett Landing Airport",
      "city": "Wilkeson",
      "state": "Washington",
      "country": "US",
      "elevation": 800,
      "lat": 47.129699707,
      "lon": -122.0559997559,
      "tz": "America\/Los_Angeles"
  },
  "WN17": {
      "icao": "WN17",
      "iata": "",
      "name": "Hoverhawk Ranch Airport",
      "city": "Wenatchee",
      "state": "",
      "country": "US",
      "elevation": 2450,
      "lat": 47.3398017883,
      "lon": -120.3160018921,
      "tz": "America\/Los_Angeles"
  },
  "WN18": {
      "icao": "WN18",
      "iata": "",
      "name": "Becker's Landing Airport",
      "city": "Anacortes",
      "state": "",
      "country": "US",
      "elevation": 155,
      "lat": 48.4567985535,
      "lon": -122.5390014648,
      "tz": "America\/Los_Angeles"
  },
  "WN20": {
      "icao": "WN20",
      "iata": "",
      "name": "Van De Plasch Airport",
      "city": "Monroe",
      "state": "Washington",
      "country": "US",
      "elevation": 60,
      "lat": 47.8722991943,
      "lon": -121.922996521,
      "tz": "America\/Los_Angeles"
  },
  "WN21": {
      "icao": "WN21",
      "iata": "",
      "name": "Lawson Airpark",
      "city": "Port Angeles",
      "state": "Washington",
      "country": "US",
      "elevation": 650,
      "lat": 48.0815010071,
      "lon": -123.3980026245,
      "tz": "America\/Los_Angeles"
  },
  "WN23": {
      "icao": "WN23",
      "iata": "",
      "name": "Grand View International Airport",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 150,
      "lat": 48.1017990112,
      "lon": -123.1800003052,
      "tz": "America\/Los_Angeles"
  },
  "WN24": {
      "icao": "WN24",
      "iata": "",
      "name": "Cougar Flat Airstrip",
      "city": "Castle Rock",
      "state": "Washington",
      "country": "US",
      "elevation": 180,
      "lat": 46.2925987244,
      "lon": -122.9499969482,
      "tz": "America\/Los_Angeles"
  },
  "WN26": {
      "icao": "WN26",
      "iata": "",
      "name": "Schoepflin Airport",
      "city": "Palouse",
      "state": "Washington",
      "country": "US",
      "elevation": 2607,
      "lat": 46.8849983215,
      "lon": -117.077003479,
      "tz": "America\/Los_Angeles"
  },
  "WN27": {
      "icao": "WN27",
      "iata": "",
      "name": "Redoft Airport",
      "city": "Collins",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 815,
      "lat": 44.0792007446,
      "lon": -87.9757003784,
      "tz": "America\/Chicago"
  },
  "WN28": {
      "icao": "WN28",
      "iata": "",
      "name": "Sunny Slope Runway Airport",
      "city": "Egg Harbor",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 730,
      "lat": 44.98279953,
      "lon": -87.3095016479,
      "tz": "America\/Chicago"
  },
  "WN29": {
      "icao": "WN29",
      "iata": "",
      "name": "Blue Ribbon Airport",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 100,
      "lat": 48.1304016113,
      "lon": -123.2070007324,
      "tz": "America\/Los_Angeles"
  },
  "WN30": {
      "icao": "WN30",
      "iata": "",
      "name": "Clinesmith Ranch Airport",
      "city": "Benge",
      "state": "Washington",
      "country": "US",
      "elevation": 1600,
      "lat": 46.9198989868,
      "lon": -118.0920028687,
      "tz": "America\/Los_Angeles"
  },
  "WN31": {
      "icao": "WN31",
      "iata": "",
      "name": "Slinkard Airfield",
      "city": "Mesa",
      "state": "Washington",
      "country": "US",
      "elevation": 890,
      "lat": 46.6031990051,
      "lon": -119.06300354,
      "tz": "America\/Los_Angeles"
  },
  "WN33": {
      "icao": "WN33",
      "iata": "",
      "name": "Columbia Ag 2 Airport",
      "city": "Pasco",
      "state": "Washington",
      "country": "US",
      "elevation": 715,
      "lat": 46.3804016113,
      "lon": -118.987998962,
      "tz": "America\/Los_Angeles"
  },
  "WN34": {
      "icao": "WN34",
      "iata": "",
      "name": "Ethel International Airport",
      "city": "Ethel",
      "state": "Washington",
      "country": "US",
      "elevation": 580,
      "lat": 46.5308990479,
      "lon": -122.6839981079,
      "tz": "America\/Los_Angeles"
  },
  "WN35": {
      "icao": "WN35",
      "iata": "",
      "name": "Meadow Mist Airport",
      "city": "Ferndale",
      "state": "Washington",
      "country": "US",
      "elevation": 55,
      "lat": 48.9086990356,
      "lon": -122.5650024414,
      "tz": "America\/Los_Angeles"
  },
  "WN36": {
      "icao": "WN36",
      "iata": "",
      "name": "Kari Field",
      "city": "Olympia",
      "state": "Washington",
      "country": "US",
      "elevation": 224,
      "lat": 46.96900177,
      "lon": -122.8209991455,
      "tz": "America\/Los_Angeles"
  },
  "WN39": {
      "icao": "WN39",
      "iata": "",
      "name": "Knutson Field",
      "city": "Wyoceena",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 875,
      "lat": 43.4818992615,
      "lon": -89.320098877,
      "tz": "America\/Chicago"
  },
  "WN40": {
      "icao": "WN40",
      "iata": "",
      "name": "Coupeville Airpark",
      "city": "Coupeville",
      "state": "Washington",
      "country": "US",
      "elevation": 200,
      "lat": 48.1903991699,
      "lon": -122.6429977417,
      "tz": "America\/Los_Angeles"
  },
  "WN41": {
      "icao": "WN41",
      "iata": "",
      "name": "Redfern Aerodrome",
      "city": "Sprague",
      "state": "Washington",
      "country": "US",
      "elevation": 2050,
      "lat": 47.3044013977,
      "lon": -117.9619979858,
      "tz": "America\/Los_Angeles"
  },
  "WN42": {
      "icao": "WN42",
      "iata": "",
      "name": "Flying H Ranch Airport",
      "city": "Buckley",
      "state": "Washington",
      "country": "US",
      "elevation": 587,
      "lat": 47.1837997437,
      "lon": -122.1200027466,
      "tz": "America\/Los_Angeles"
  },
  "WN44": {
      "icao": "WN44",
      "iata": "",
      "name": "Podeweltz Airport",
      "city": "Merrill",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1470,
      "lat": 45.2393989563,
      "lon": -89.5690002441,
      "tz": "America\/Chicago"
  },
  "WN45": {
      "icao": "WN45",
      "iata": "",
      "name": "Take Five Airport",
      "city": "Yakima",
      "state": "Washington",
      "country": "US",
      "elevation": 1543,
      "lat": 46.77930069,
      "lon": -120.4440002,
      "tz": "America\/Los_Angeles"
  },
  "WN46": {
      "icao": "WN46",
      "iata": "",
      "name": "Battle Creek Airport",
      "city": "Oconomowoc",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 865,
      "lat": 43.0675010681,
      "lon": -88.5086975098,
      "tz": "America\/Chicago"
  },
  "WN47": {
      "icao": "WN47",
      "iata": "",
      "name": "Bear Valley Skyranch Airport",
      "city": "Mc Cleary",
      "state": "Washington",
      "country": "US",
      "elevation": 344,
      "lat": 47.0816993713,
      "lon": -123.2699966431,
      "tz": "America\/Los_Angeles"
  },
  "WN48": {
      "icao": "WN48",
      "iata": "",
      "name": "Kimbrel Farm Airport",
      "city": "Oakville",
      "state": "Washington",
      "country": "US",
      "elevation": 139,
      "lat": 46.7904014587,
      "lon": -123.3099975586,
      "tz": "America\/Los_Angeles"
  },
  "WN49": {
      "icao": "WN49",
      "iata": "",
      "name": "Flying S Airfield",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 20,
      "lat": 48.121799469,
      "lon": -123.087997437,
      "tz": "America\/Los_Angeles"
  },
  "WN51": {
      "icao": "WN51",
      "iata": "",
      "name": "Bayview Farms Airport",
      "city": "Burlington",
      "state": "Washington",
      "country": "US",
      "elevation": 6,
      "lat": 48.5209007263,
      "lon": -122.4649963379,
      "tz": "America\/Los_Angeles"
  },
  "WN53": {
      "icao": "WN53",
      "iata": "",
      "name": "Frontier Airpark",
      "city": "Lake Stevens",
      "state": "Washington",
      "country": "US",
      "elevation": 480,
      "lat": 48.1104011536,
      "lon": -122.06199646,
      "tz": "America\/Los_Angeles"
  },
  "WN54": {
      "icao": "WN54",
      "iata": "",
      "name": "Ellerport Airport",
      "city": "Newman Lake",
      "state": "Washington",
      "country": "US",
      "elevation": 2146,
      "lat": 47.7349014282,
      "lon": -117.0709991455,
      "tz": "America\/Los_Angeles"
  },
  "WN55": {
      "icao": "WN55",
      "iata": "",
      "name": "Randle-Kiona Airpark",
      "city": "Randle",
      "state": "Washington",
      "country": "US",
      "elevation": 934,
      "lat": 46.5115013123,
      "lon": -122.0059967041,
      "tz": "America\/Los_Angeles"
  },
  "WN59": {
      "icao": "WN59",
      "iata": "",
      "name": "Nelsons Nitch Airport",
      "city": "Onalaska",
      "state": "Washington",
      "country": "US",
      "elevation": 520,
      "lat": 46.53760147,
      "lon": -122.7180023,
      "tz": "America\/Los_Angeles"
  },
  "WN60": {
      "icao": "WN60",
      "iata": "",
      "name": "Swanton Ultralightport",
      "city": "Sequim",
      "state": "Washington",
      "country": "US",
      "elevation": 110,
      "lat": 48.1405982971,
      "lon": -123.18800354,
      "tz": "America\/Los_Angeles"
  },
  "WN61": {
      "icao": "WN61",
      "iata": "",
      "name": "Tai's Landing Airport",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2450,
      "lat": 47.7252006531,
      "lon": -117.2799987793,
      "tz": "America\/Los_Angeles"
  },
  "WN63": {
      "icao": "WN63",
      "iata": "",
      "name": "Bristol Airport",
      "city": "Bristol",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 735,
      "lat": 42.5367012024,
      "lon": -88.0039978027,
      "tz": "America\/Chicago"
  },
  "WN64": {
      "icao": "WN64",
      "iata": "",
      "name": "J K D Farms Airport",
      "city": "Ellensburg",
      "state": "Washington",
      "country": "US",
      "elevation": 1575,
      "lat": 46.9743003845,
      "lon": -120.4619979858,
      "tz": "America\/Los_Angeles"
  },
  "WN65": {
      "icao": "WN65",
      "iata": "",
      "name": "My Airport",
      "city": "Elma",
      "state": "Washington",
      "country": "US",
      "elevation": 80,
      "lat": 46.9725990295,
      "lon": -123.4769973755,
      "tz": "America\/Los_Angeles"
  },
  "WN72": {
      "icao": "WN72",
      "iata": "",
      "name": "Kinch Farms Airport",
      "city": "Washtucna",
      "state": "Washington",
      "country": "US",
      "elevation": 1650,
      "lat": 46.8648986816,
      "lon": -118.3249969482,
      "tz": "America\/Los_Angeles"
  },
  "WN74": {
      "icao": "WN74",
      "iata": "",
      "name": "Burnt Ridge Airstrip",
      "city": "Onalaska",
      "state": "Washington",
      "country": "US",
      "elevation": 1070,
      "lat": 46.5853996277,
      "lon": -122.6259994507,
      "tz": "America\/Los_Angeles"
  },
  "WN75": {
      "icao": "WN75",
      "iata": "",
      "name": "Erin Aero Airport",
      "city": "Hartford",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 43.2447013855,
      "lon": -88.3722991943,
      "tz": "America\/Chicago"
  },
  "WN76": {
      "icao": "WN76",
      "iata": "",
      "name": "Bergseth Field",
      "city": "Enumclaw",
      "state": "Washington",
      "country": "US",
      "elevation": 1100,
      "lat": 47.243598938,
      "lon": -121.9250030518,
      "tz": "America\/Los_Angeles"
  },
  "WN80": {
      "icao": "WN80",
      "iata": "",
      "name": "Walters Arv Ultralightport",
      "city": "Kelso",
      "state": "Washington",
      "country": "US",
      "elevation": 475,
      "lat": 46.0979003906,
      "lon": -122.8649978638,
      "tz": "America\/Los_Angeles"
  },
  "WN85": {
      "icao": "WN85",
      "iata": "",
      "name": "Morrisonville International Airport",
      "city": "Morrisonville",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 960,
      "lat": 43.2747001648,
      "lon": -89.350402832,
      "tz": "America\/Chicago"
  },
  "WN86": {
      "icao": "WN86",
      "iata": "",
      "name": "St Croix Valley Airport",
      "city": "Osceola",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1085,
      "lat": 45.2639007568,
      "lon": -92.62159729,
      "tz": "America\/Chicago"
  },
  "WN87": {
      "icao": "WN87",
      "iata": "",
      "name": "Bryan Airport",
      "city": "Enumclaw",
      "state": "Washington",
      "country": "US",
      "elevation": 630,
      "lat": 47.2085990906,
      "lon": -122.0930023193,
      "tz": "America\/Los_Angeles"
  },
  "WN88": {
      "icao": "WN88",
      "iata": "",
      "name": "Martin Airport",
      "city": "Ocean Park",
      "state": "Washington",
      "country": "US",
      "elevation": 10,
      "lat": 46.5192985535,
      "lon": -124.0319976807,
      "tz": "America\/Los_Angeles"
  },
  "WN89": {
      "icao": "WN89",
      "iata": "",
      "name": "Lee's Airport",
      "city": "Chattaroy",
      "state": "Washington",
      "country": "US",
      "elevation": 1890,
      "lat": 47.8876991272,
      "lon": -117.34400177,
      "tz": "America\/Los_Angeles"
  },
  "WN90": {
      "icao": "WN90",
      "iata": "",
      "name": "Taylorport Airport",
      "city": "Bruce",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1250,
      "lat": 45.375,
      "lon": -91.2918014526,
      "tz": "America\/Chicago"
  },
  "WN92": {
      "icao": "WN92",
      "iata": "",
      "name": "Sky Meadows Airpark",
      "city": "Spokane",
      "state": "Washington",
      "country": "US",
      "elevation": 2350,
      "lat": 47.6085014343,
      "lon": -117.1809997559,
      "tz": "America\/Los_Angeles"
  },
  "WN95": {
      "icao": "WN95",
      "iata": "",
      "name": "Brown Boy Airport",
      "city": "Vantage",
      "state": "Washington",
      "country": "US",
      "elevation": 1125,
      "lat": 46.8848991394,
      "lon": -119.8909988403,
      "tz": "America\/Los_Angeles"
  },
  "WN98": {
      "icao": "WN98",
      "iata": "",
      "name": "Florida North Airport",
      "city": "Birchwood",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1272,
      "lat": 45.6594009399,
      "lon": -91.5224990845,
      "tz": "America\/Chicago"
  },
  "WN99": {
      "icao": "WN99",
      "iata": "",
      "name": "Hayes Road Airport",
      "city": "Durand",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 900,
      "lat": 44.6571998596,
      "lon": -91.8086013794,
      "tz": "America\/Chicago"
  },
  "WPAT": {
      "icao": "WPAT",
      "iata": "AUT",
      "name": "Atauro Airport",
      "city": "Atauro",
      "state": "Dili",
      "country": "TL",
      "elevation": 29,
      "lat": -8.2105197906,
      "lon": 125.6159973145,
      "tz": "Asia\/Dili"
  },
  "WPDB": {
      "icao": "WPDB",
      "iata": "UAI",
      "name": "Suai Airport",
      "city": "Suai",
      "state": "Cova-Lima",
      "country": "TL",
      "elevation": 96,
      "lat": -9.3033103943,
      "lon": 125.2870025635,
      "tz": "Asia\/Dili"
  },
  "WPDL": {
      "icao": "WPDL",
      "iata": "DIL",
      "name": "Presidente Nicolau Lobato International Airport",
      "city": "Dili",
      "state": "Dili",
      "country": "TL",
      "elevation": 154,
      "lat": -8.5464000702,
      "lon": 125.526000977,
      "tz": "Asia\/Dili"
  },
  "WPEC": {
      "icao": "WPEC",
      "iata": "BCH",
      "name": "Cakung Airport",
      "city": "Baucau",
      "state": "Baucau",
      "country": "TL",
      "elevation": 1771,
      "lat": -8.4890298843,
      "lon": 126.401000977,
      "tz": "Asia\/Dili"
  },
  "WPFL": {
      "icao": "WPFL",
      "iata": "",
      "name": "Abisu Airfield",
      "city": "Fuiloro",
      "state": "Lautem",
      "country": "TL",
      "elevation": 0,
      "lat": -8.4436101913,
      "lon": 126.9889984131,
      "tz": "Asia\/Dili"
  },
  "WPMN": {
      "icao": "WPMN",
      "iata": "MPT",
      "name": "Maliana Airport",
      "city": "Maliana",
      "state": "Bobonaro",
      "country": "TL",
      "elevation": 0,
      "lat": -8.972240448,
      "lon": 125.2149963379,
      "tz": "Asia\/Dili"
  },
  "WPOC": {
      "icao": "WPOC",
      "iata": "OEC",
      "name": "Oecussi Airport",
      "city": "Oecussi-Ambeno",
      "state": "Oecusse",
      "country": "TL",
      "elevation": 0,
      "lat": -9.1980600357,
      "lon": 124.3430023193,
      "tz": "Asia\/Dili"
  },
  "WPVQ": {
      "icao": "WPVQ",
      "iata": "VIQ",
      "name": "Viqueque Airport",
      "city": "Viqueque",
      "state": "Viqueque",
      "country": "TL",
      "elevation": 500,
      "lat": -8.8837995529,
      "lon": 126.3730010986,
      "tz": "Asia\/Dili"
  },
  "WRBU": {
      "icao": "WRBU",
      "iata": "",
      "name": "Buntok Airport",
      "city": "Buntok-Borneo Island",
      "state": "Central-Kalimantan",
      "country": "ID",
      "elevation": 59,
      "lat": -1.7325099707,
      "lon": 114.8389968872,
      "tz": "Asia\/Pontianak"
  },
  "WRKA": {
      "icao": "WRKA",
      "iata": "ABU",
      "name": "Haliwen Airport",
      "city": "Atambua-Timor Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 1027,
      "lat": -9.0730495453,
      "lon": 124.9049987793,
      "tz": "Asia\/Makassar"
  },
  "WRKB": {
      "icao": "WRKB",
      "iata": "BJW",
      "name": "Padhameleda Airport",
      "city": "Bajawa-Flores Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 4317,
      "lat": -8.808139801,
      "lon": 120.9960021973,
      "tz": "Asia\/Makassar"
  },
  "WRKJ": {
      "icao": "WRKJ",
      "iata": "",
      "name": "Mena Airport",
      "city": "Mena-Timor Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 320,
      "lat": -9.183889389,
      "lon": 124.5891647339,
      "tz": "Asia\/Makassar"
  },
  "WRKL": {
      "icao": "WRKL",
      "iata": "LKA",
      "name": "Gewayentana Airport",
      "city": "Larantuka-Flores Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 57,
      "lat": -8.3466596603,
      "lon": 122.9810028076,
      "tz": "Asia\/Makassar"
  },
  "WRKM": {
      "icao": "WRKM",
      "iata": "",
      "name": "Kalabahi Airport",
      "city": "Kalabahi-Alor Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 33,
      "lat": -8.2166671753,
      "lon": 124.5666656494,
      "tz": "Asia\/Makassar"
  },
  "WRKN": {
      "icao": "WRKN",
      "iata": "",
      "name": "Naikliu Airport",
      "city": "Naikliu-Timor Island",
      "state": "",
      "country": "ID",
      "elevation": 60,
      "lat": -9.495470047,
      "lon": 123.7949981689,
      "tz": "Asia\/Makassar"
  },
  "WRKR": {
      "icao": "WRKR",
      "iata": "",
      "name": "Rote Airport",
      "city": "Namodale-Rote Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 300,
      "lat": -10.7166671753,
      "lon": 123.0666656494,
      "tz": "Asia\/Makassar"
  },
  "WRKS": {
      "icao": "WRKS",
      "iata": "SAU",
      "name": "Sawu Airport",
      "city": "Sawu-Sawu Island",
      "state": "East-Nusa-Tenggara",
      "country": "ID",
      "elevation": 530,
      "lat": -10.483300209,
      "lon": 121.9000015259,
      "tz": "Asia\/Makassar"
  },
  "WRLA": {
      "icao": "WRLA",
      "iata": "SGQ",
      "name": "Sanggata Airport",
      "city": "Sanggata-Timor Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 434,
      "lat": 0.5,
      "lon": 117.3333587646,
      "tz": "Asia\/Makassar"
  },
  "WRLB": {
      "icao": "WRLB",
      "iata": "LBW",
      "name": "Long Bawan Airport",
      "city": "Long Bawan-Borneo Island",
      "state": "North-Kalimantan",
      "country": "ID",
      "elevation": 3590,
      "lat": 3.867000103,
      "lon": 115.6829986572,
      "tz": "Asia\/Makassar"
  },
  "WRLC": {
      "icao": "WRLC",
      "iata": "BXT",
      "name": "Bontang Airport",
      "city": "Bontang-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 49,
      "lat": 0.1196909994,
      "lon": 117.474998474,
      "tz": "Asia\/Makassar"
  },
  "WRLF": {
      "icao": "WRLF",
      "iata": "NNX",
      "name": "Nunukan Airport",
      "city": "Nunukan-Nunukan Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 52,
      "lat": 4.1365299225,
      "lon": 117.6669998169,
      "tz": "Asia\/Kuching"
  },
  "WRLH": {
      "icao": "WRLH",
      "iata": "TNB",
      "name": "Tanah Grogot Airport",
      "city": "Tanah Grogot-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 17,
      "lat": -1.910130024,
      "lon": 116.202003479,
      "tz": "Asia\/Makassar"
  },
  "WRLL": {
      "icao": "WRLL",
      "iata": "",
      "name": "Balikpapan Airport",
      "city": "Seppingan-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 8,
      "lat": -1.2666670084,
      "lon": 116.8833312988,
      "tz": "Asia\/Makassar"
  },
  "WRLM": {
      "icao": "WRLM",
      "iata": "",
      "name": "Malinau Airport",
      "city": "Malinau-Borneo Island",
      "state": "South-Kalimantan",
      "country": "ID",
      "elevation": 96,
      "lat": -3.5527200699,
      "lon": 116.6259994507,
      "tz": "Asia\/Makassar"
  },
  "WRLN": {
      "icao": "WRLN",
      "iata": "",
      "name": "Long Mawang Airport",
      "city": "Long Mawang-Borneo Island",
      "state": "North-Kalimantan",
      "country": "ID",
      "elevation": 2343,
      "lat": 1.8141599894,
      "lon": 114.8840026855,
      "tz": "Asia\/Makassar"
  },
  "WRLP": {
      "icao": "WRLP",
      "iata": "LPU",
      "name": "Long Apung Airport",
      "city": "Long Apung-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 627,
      "lat": 1.704486,
      "lon": 114.970297,
      "tz": "Asia\/Makassar"
  },
  "WRLU": {
      "icao": "WRLU",
      "iata": "",
      "name": "Sangkulirang Airport",
      "city": "Sangkulirang-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 34,
      "lat": 1.0008900166,
      "lon": 117.9960021973,
      "tz": "Asia\/Makassar"
  },
  "WRLW": {
      "icao": "WRLW",
      "iata": "",
      "name": "Muara Wahau Airport",
      "city": "Muara Wahau-Borneo Island",
      "state": "East-Kalimantan",
      "country": "ID",
      "elevation": 135,
      "lat": 1.0328199863,
      "lon": 116.9110031128,
      "tz": "Asia\/Makassar"
  },
  "WRSP": {
      "icao": "WRSP",
      "iata": "",
      "name": "Surabaya Airport",
      "city": "Surabaya-Java Island",
      "state": "",
      "country": "ID",
      "elevation": 9,
      "lat": -7.2166671753,
      "lon": 112.7166671753,
      "tz": "Asia\/Jakarta"
  },
  "WS02": {
      "icao": "WS02",
      "iata": "",
      "name": "Polish Paradise Airport",
      "city": "Oxford",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1030,
      "lat": 43.7425003052,
      "lon": -89.716003418,
      "tz": "America\/Chicago"
  },
  "WS04": {
      "icao": "WS04",
      "iata": "",
      "name": "Zanadu Airport",
      "city": "Arkdale",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 950,
      "lat": 44.0325012207,
      "lon": -89.8650970459,
      "tz": "America\/Chicago"
  },
  "WS05": {
      "icao": "WS05",
      "iata": "",
      "name": "Lonely Pines Airport",
      "city": "Kennan",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1365,
      "lat": 45.4771995544,
      "lon": -90.6250991821,
      "tz": "America\/Chicago"
  },
  "WS06": {
      "icao": "WS06",
      "iata": "",
      "name": "Springstead Airport",
      "city": "Park Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1600,
      "lat": 45.987701416,
      "lon": -90.1634979248,
      "tz": "America\/Chicago"
  },
  "WS08": {
      "icao": "WS08",
      "iata": "",
      "name": "Five Corners Airways Airport",
      "city": "Appleton",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 815,
      "lat": 44.4179992676,
      "lon": -88.3769989014,
      "tz": "America\/Chicago"
  },
  "WS09": {
      "icao": "WS09",
      "iata": "",
      "name": "Rox Airport",
      "city": "Coloma",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1017,
      "lat": 44.0133018494,
      "lon": -89.5248031616,
      "tz": "America\/Chicago"
  },
  "WS10": {
      "icao": "WS10",
      "iata": "",
      "name": "Casey Lake Airport",
      "city": "Waupaca",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 44.408000946,
      "lon": -89.0158996582,
      "tz": "America\/Chicago"
  },
  "WS11": {
      "icao": "WS11",
      "iata": "",
      "name": "Frievalt Airport",
      "city": "Pound",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 830,
      "lat": 45.1002998352,
      "lon": -88.1333999634,
      "tz": "America\/Chicago"
  },
  "WS12": {
      "icao": "WS12",
      "iata": "",
      "name": "Elert Airport",
      "city": "De Forest",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 972,
      "lat": 43.2644004822,
      "lon": -89.3231964111,
      "tz": "America\/Chicago"
  },
  "WS13": {
      "icao": "WS13",
      "iata": "",
      "name": "Cloud Dancer Private Airport",
      "city": "Chetek",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1050,
      "lat": 45.3119010925,
      "lon": -91.5896987915,
      "tz": "America\/Chicago"
  },
  "WS14": {
      "icao": "WS14",
      "iata": "",
      "name": "Lake Ell Field",
      "city": "Amherst",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1113,
      "lat": 44.464099884,
      "lon": -89.3587036133,
      "tz": "America\/Chicago"
  },
  "WS15": {
      "icao": "WS15",
      "iata": "",
      "name": "Mill House Field",
      "city": "Wyocena",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 820,
      "lat": 43.4952011108,
      "lon": -89.2654037476,
      "tz": "America\/Chicago"
  },
  "WS16": {
      "icao": "WS16",
      "iata": "",
      "name": "Scherrico Meadows Airport",
      "city": "Mosinee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1345,
      "lat": 44.8471984863,
      "lon": -89.8014984131,
      "tz": "America\/Chicago"
  },
  "WS17": {
      "icao": "WS17",
      "iata": "",
      "name": "Pioneer Airport",
      "city": "Oshkosh",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 826,
      "lat": 43.9893989563,
      "lon": -88.5783996582,
      "tz": "America\/Chicago"
  },
  "WS19": {
      "icao": "WS19",
      "iata": "",
      "name": "Petit Cache Airport",
      "city": "Bayfield",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 760,
      "lat": 46.8935012817,
      "lon": -90.8207015991,
      "tz": "America\/Chicago"
  },
  "WS20": {
      "icao": "WS20",
      "iata": "",
      "name": "Young Tactical Landing Site Airport",
      "city": "Fort Mc Coy",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 912,
      "lat": 43.95299912,
      "lon": -90.66259766,
      "tz": "America\/Chicago"
  },
  "WS22": {
      "icao": "WS22",
      "iata": "",
      "name": "Wolfgram Airport",
      "city": "New London",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 775,
      "lat": 44.3946990967,
      "lon": -88.6894989014,
      "tz": "America\/Chicago"
  },
  "WS23": {
      "icao": "WS23",
      "iata": "",
      "name": "R & S Landing Strip",
      "city": "Merrill",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1309,
      "lat": 45.2160987854,
      "lon": -89.7472000122,
      "tz": "America\/Chicago"
  },
  "WS25": {
      "icao": "WS25",
      "iata": "",
      "name": "Shangrila Airport",
      "city": "Beecher",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 835,
      "lat": 45.5816001892,
      "lon": -87.8453979492,
      "tz": "America\/Chicago"
  },
  "WS26": {
      "icao": "WS26",
      "iata": "",
      "name": "Ranch Side Airport",
      "city": "Kewaunee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 710,
      "lat": 44.3591995239,
      "lon": -87.6070022583,
      "tz": "America\/Chicago"
  },
  "WS28": {
      "icao": "WS28",
      "iata": "",
      "name": "Coleman Airport",
      "city": "Portage",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 800,
      "lat": 43.5536003113,
      "lon": -89.3898010254,
      "tz": "America\/Chicago"
  },
  "WS29": {
      "icao": "WS29",
      "iata": "",
      "name": "Tachick Field",
      "city": "Pound",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 45.120300293,
      "lon": -88.1434020996,
      "tz": "America\/Chicago"
  },
  "WS30": {
      "icao": "WS30",
      "iata": "",
      "name": "Hexum Flight Park Ultralightport",
      "city": "Star Prairie",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 930,
      "lat": 45.1890983582,
      "lon": -92.5483016968,
      "tz": "America\/Chicago"
  },
  "WS31": {
      "icao": "WS31",
      "iata": "",
      "name": "Otto-Gibbons Airport",
      "city": "Wayside",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 44.2778015137,
      "lon": -87.9445037842,
      "tz": "America\/Chicago"
  },
  "WS32": {
      "icao": "WS32",
      "iata": "",
      "name": "Prescott Field",
      "city": "Wyocena",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 875,
      "lat": 43.4836006165,
      "lon": -89.2947998047,
      "tz": "America\/Chicago"
  },
  "WS33": {
      "icao": "WS33",
      "iata": "",
      "name": "Storytown Airfield",
      "city": "Oregon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 42.909198761,
      "lon": -89.4509963989,
      "tz": "America\/Chicago"
  },
  "WS36": {
      "icao": "WS36",
      "iata": "",
      "name": "Swan Field",
      "city": "Almond",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1140,
      "lat": 44.2714004517,
      "lon": -89.4371032715,
      "tz": "America\/Chicago"
  },
  "WS39": {
      "icao": "WS39",
      "iata": "",
      "name": "Pinewood Air Park",
      "city": "Goodnow",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1560,
      "lat": 45.6994018555,
      "lon": -89.662399292,
      "tz": "America\/Chicago"
  },
  "WS41": {
      "icao": "WS41",
      "iata": "",
      "name": "Rusmar Farms Airport",
      "city": "Roberts",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1050,
      "lat": 45.0121994019,
      "lon": -92.5513000488,
      "tz": "America\/Chicago"
  },
  "WS42": {
      "icao": "WS42",
      "iata": "",
      "name": "Owen-Withee Airport",
      "city": "Withee",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1286,
      "lat": 44.9500007629,
      "lon": -90.6000976563,
      "tz": "America\/Chicago"
  },
  "WS43": {
      "icao": "WS43",
      "iata": "",
      "name": "Birch Creek Airport",
      "city": "De Pere",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 813,
      "lat": 44.3554992676,
      "lon": -88.0584030151,
      "tz": "America\/Chicago"
  },
  "WS44": {
      "icao": "WS44",
      "iata": "",
      "name": "Diderrich Ranch Airport",
      "city": "Hawkins",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1403,
      "lat": 45.5365982056,
      "lon": -90.7049026489,
      "tz": "America\/Chicago"
  },
  "WS45": {
      "icao": "WS45",
      "iata": "",
      "name": "Circle K Airport",
      "city": "Irma",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1500,
      "lat": 45.3415985107,
      "lon": -89.6745986938,
      "tz": "America\/Chicago"
  },
  "WS46": {
      "icao": "WS46",
      "iata": "",
      "name": "J & L Aviation Airport",
      "city": "Ripon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 975,
      "lat": 43.8310012817,
      "lon": -88.8887023926,
      "tz": "America\/Chicago"
  },
  "WS49": {
      "icao": "WS49",
      "iata": "",
      "name": "Christie Aerodrome",
      "city": "Fort Atkinson",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 795,
      "lat": 42.9067001343,
      "lon": -88.8776016235,
      "tz": "America\/Chicago"
  },
  "WS51": {
      "icao": "WS51",
      "iata": "",
      "name": "Leeward Farm Airport",
      "city": "Soldiers Grove",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1055,
      "lat": 43.3527984619,
      "lon": -90.6809997559,
      "tz": "America\/Chicago"
  },
  "WS55": {
      "icao": "WS55",
      "iata": "",
      "name": "Barten Airport",
      "city": "Sharon",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 977,
      "lat": 42.5175018311,
      "lon": -88.7453994751,
      "tz": "America\/Chicago"
  },
  "WS60": {
      "icao": "WS60",
      "iata": "",
      "name": "Vern Air Park",
      "city": "Thorp",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1169,
      "lat": 44.9338989258,
      "lon": -90.8310012817,
      "tz": "America\/Chicago"
  },
  "WS62": {
      "icao": "WS62",
      "iata": "",
      "name": "Sugar Ridge Airport",
      "city": "Verona",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1080,
      "lat": 42.9636001587,
      "lon": -89.5810012817,
      "tz": "America\/Chicago"
  },
  "WS67": {
      "icao": "WS67",
      "iata": "",
      "name": "Vietmeier Airport",
      "city": "Herbster",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 795,
      "lat": 46.7999000549,
      "lon": -91.2085037231,
      "tz": "America\/Chicago"
  },
  "WS68": {
      "icao": "WS68",
      "iata": "",
      "name": "Krist Island Airport",
      "city": "Montello",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 770,
      "lat": 43.7816009521,
      "lon": -89.3078994751,
      "tz": "America\/Chicago"
  },
  "WS69": {
      "icao": "WS69",
      "iata": "",
      "name": "Log Cabin Airport",
      "city": "Eleva",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 850,
      "lat": 44.5750007629,
      "lon": -91.5470962524,
      "tz": "America\/Chicago"
  },
  "WS71": {
      "icao": "WS71",
      "iata": "",
      "name": "Flying J Airport",
      "city": "St Croix Falls",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1205,
      "lat": 45.4305000305,
      "lon": -92.6155014038,
      "tz": "America\/Chicago"
  },
  "WS72": {
      "icao": "WS72",
      "iata": "",
      "name": "Cain's Field",
      "city": "Stiles",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 640,
      "lat": 44.8527984619,
      "lon": -88.0120010376,
      "tz": "America\/Chicago"
  },
  "WS74": {
      "icao": "WS74",
      "iata": "",
      "name": "Al's Airway Airport",
      "city": "Watertown",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 825,
      "lat": 43.1631011963,
      "lon": -88.8245010376,
      "tz": "America\/Chicago"
  },
  "WS76": {
      "icao": "WS76",
      "iata": "",
      "name": "Black Dog Farm Airport",
      "city": "New London",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 898,
      "lat": 44.4706993103,
      "lon": -88.8245010376,
      "tz": "America\/Chicago"
  },
  "WS77": {
      "icao": "WS77",
      "iata": "",
      "name": "Circle T Airport",
      "city": "Grantsburg",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 895,
      "lat": 45.6839981079,
      "lon": -92.6871032715,
      "tz": "America\/Chicago"
  },
  "WS78": {
      "icao": "WS78",
      "iata": "",
      "name": "River Valley Airport",
      "city": "Rhinelander",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1525,
      "lat": 45.5503005981,
      "lon": -89.4878005981,
      "tz": "America\/Chicago"
  },
  "WS81": {
      "icao": "WS81",
      "iata": "",
      "name": "Alpha Hotel Airport",
      "city": "Webster",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1000,
      "lat": 45.9480018616,
      "lon": -92.1084976196,
      "tz": "America\/Chicago"
  },
  "WS87": {
      "icao": "WS87",
      "iata": "",
      "name": "Port Field",
      "city": "Twisp",
      "state": "Washington",
      "country": "US",
      "elevation": 1650,
      "lat": 48.331869,
      "lon": -120.058253,
      "tz": "America\/Los_Angeles"
  },
  "WS91": {
      "icao": "WS91",
      "iata": "",
      "name": "Sky Hollow Airport",
      "city": "Boscobel",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 707,
      "lat": 43.2085990906,
      "lon": -90.6636962891,
      "tz": "America\/Chicago"
  },
  "WS96": {
      "icao": "WS96",
      "iata": "",
      "name": "Hughes Airport",
      "city": "Monroe",
      "state": "Wisconsin",
      "country": "US",
      "elevation": 1010,
      "lat": 42.6208000183,
      "lon": -89.7673034668,
      "tz": "America\/Chicago"
  },
  "WSAC": {
      "icao": "WSAC",
      "iata": "",
      "name": "Changi Air Base",
      "city": "Singapore",
      "state": "",
      "country": "SG",
      "elevation": 22,
      "lat": 1.3441400528,
      "lon": 104.009002686,
      "tz": "Asia\/Singapore"
  },
  "WSAG": {
      "icao": "WSAG",
      "iata": "",
      "name": "Sembawang Air Base",
      "city": "Sembawang",
      "state": "",
      "country": "SG",
      "elevation": 86,
      "lat": 1.4252599478,
      "lon": 103.81300354,
      "tz": "Asia\/Singapore"
  },
  "WSAP": {
      "icao": "WSAP",
      "iata": "QPG",
      "name": "Paya Lebar Air Base",
      "city": "",
      "state": "",
      "country": "SG",
      "elevation": 65,
      "lat": 1.3604199886,
      "lon": 103.9100036621,
      "tz": "Asia\/Singapore"
  },
  "WSAT": {
      "icao": "WSAT",
      "iata": "TGA",
      "name": "Tengah Air Base",
      "city": "",
      "state": "",
      "country": "SG",
      "elevation": 50,
      "lat": 1.3872599602,
      "lon": 103.7089996338,
      "tz": "Asia\/Singapore"
  },
  "WSSL": {
      "icao": "WSSL",
      "iata": "XSP",
      "name": "Seletar Airport",
      "city": "Seletar",
      "state": "",
      "country": "SG",
      "elevation": 36,
      "lat": 1.4169499874,
      "lon": 103.8679962158,
      "tz": "Asia\/Kuala_Lumpur"
  },
  "WSSS": {
      "icao": "WSSS",
      "iata": "SIN",
      "name": "Singapore Changi International Airport",
      "city": "Singapore",
      "state": "North-East",
      "country": "SG",
      "elevation": 22,
      "lat": 1.3501900434,
      "lon": 103.9940032959,
      "tz": "Asia\/Singapore"
  },
  "WT01": {
      "icao": "WT01",
      "iata": "",
      "name": "Hillcrest Farms Airport",
      "city": "Ellensburg",
      "state": "Washington",
      "country": "US",
      "elevation": 1490,
      "lat": 46.949167,
      "lon": -120.481111,
      "tz": "America\/Los_Angeles"
  },
  "WT03": {
      "icao": "WT03",
      "iata": "",
      "name": "River View Airpark",
      "city": "Chelan",
      "state": "Washington",
      "country": "US",
      "elevation": 1210,
      "lat": 47.900278,
      "lon": -119.895833,
      "tz": "America\/Los_Angeles"
  },
  "WT21": {
      "icao": "WT21",
      "iata": "",
      "name": "Deer Park \/ Radial Flyer Airport",
      "city": "",
      "state": "Washington",
      "country": "US",
      "elevation": 2060,
      "lat": 47.894536,
      "lon": -117.439807,
      "tz": "America\/Los_Angeles"
  },
  "WT44": {
      "icao": "WT44",
      "iata": "",
      "name": "Michair Airport",
      "city": "Cathlamet",
      "state": "Washington",
      "country": "US",
      "elevation": 13,
      "lat": 46.177333,
      "lon": -123.385,
      "tz": "America\/Los_Angeles"
  },
  "WT77": {
      "icao": "WT77",
      "iata": "",
      "name": "Rocky Bay Airport",
      "city": "Vaughn",
      "state": "Washington",
      "country": "US",
      "elevation": 80,
      "lat": 47.355222,
      "lon": -122.790278,
      "tz": "America\/Los_Angeles"
  },
  "WT88": {
      "icao": "WT88",
      "iata": "",
      "name": "Hill Airport",
      "city": "Palouse",
      "state": "Washington",
      "country": "US",
      "elevation": 2700,
      "lat": 46.841,
      "lon": -117.056281,
      "tz": "America\/Los_Angeles"
  },
  "WV00": {
      "icao": "WV00",
      "iata": "",
      "name": "Deer Creek Farm Airport",
      "city": "Arbovale",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2650,
      "lat": 38.4707984924,
      "lon": -79.8005981445,
      "tz": "America\/New_York"
  },
  "WV01": {
      "icao": "WV01",
      "iata": "",
      "name": "Lee Massey Airport",
      "city": "Ansted",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1665,
      "lat": 38.1292991638,
      "lon": -81.0537033081,
      "tz": "America\/New_York"
  },
  "WV06": {
      "icao": "WV06",
      "iata": "",
      "name": "Lost Mountain Airport",
      "city": "Romney",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1960,
      "lat": 39.2850990295,
      "lon": -78.7382965088,
      "tz": "America\/New_York"
  },
  "WV08": {
      "icao": "WV08",
      "iata": "",
      "name": "Island Airport",
      "city": "Chesapeake",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 600,
      "lat": 38.2168006897,
      "lon": -81.533203125,
      "tz": "America\/New_York"
  },
  "WV09": {
      "icao": "WV09",
      "iata": "",
      "name": "Mike Ferrell Field",
      "city": "Corinne",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2220,
      "lat": 37.5587005615,
      "lon": -81.3507003784,
      "tz": "America\/New_York"
  },
  "WV10": {
      "icao": "WV10",
      "iata": "",
      "name": "Peterstown Airport",
      "city": "Peterstown",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1590,
      "lat": 37.4011993408,
      "lon": -80.8072967529,
      "tz": "America\/New_York"
  },
  "WV11": {
      "icao": "WV11",
      "iata": "",
      "name": "Swope Farm Airport",
      "city": "Fan Rock",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1802,
      "lat": 37.5665016174,
      "lon": -81.6193008423,
      "tz": "America\/New_York"
  },
  "WV12": {
      "icao": "WV12",
      "iata": "",
      "name": "Mallory Airport",
      "city": "South Charleston",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 880,
      "lat": 38.3350982666,
      "lon": -81.7317962646,
      "tz": "America\/New_York"
  },
  "WV15": {
      "icao": "WV15",
      "iata": "",
      "name": "Gerstell Farms Airport",
      "city": "Keyser",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 700,
      "lat": 39.4833984375,
      "lon": -78.926399231,
      "tz": "America\/New_York"
  },
  "WV17": {
      "icao": "WV17",
      "iata": "",
      "name": "Michaels Farms Airport",
      "city": "Martinsburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 510,
      "lat": 39.3933982849,
      "lon": -78.1557998657,
      "tz": "America\/New_York"
  },
  "WV18": {
      "icao": "WV18",
      "iata": "",
      "name": "Miller Field",
      "city": "Keyser",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1050,
      "lat": 39.4084014893,
      "lon": -79.0136032104,
      "tz": "America\/New_York"
  },
  "WV19": {
      "icao": "WV19",
      "iata": "",
      "name": "Moore Field",
      "city": "Kingwood",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1860,
      "lat": 39.4448013306,
      "lon": -79.689201355,
      "tz": "America\/New_York"
  },
  "WV21": {
      "icao": "WV21",
      "iata": "",
      "name": "Needwood Farm Airport",
      "city": "Harpers Ferry",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 440,
      "lat": 39.3306007385,
      "lon": -77.7947006226,
      "tz": "America\/New_York"
  },
  "WV22": {
      "icao": "WV22",
      "iata": "",
      "name": "Green Landings Airport",
      "city": "Hedgesville",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 510,
      "lat": 39.5746994019,
      "lon": -77.971496582,
      "tz": "America\/New_York"
  },
  "WV23": {
      "icao": "WV23",
      "iata": "",
      "name": "Louis Bennett Field",
      "city": "Weston",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1014,
      "lat": 39.0914993286,
      "lon": -80.4694976807,
      "tz": "America\/New_York"
  },
  "WV28": {
      "icao": "WV28",
      "iata": "",
      "name": "Ruth Field",
      "city": "Clarksburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1200,
      "lat": 39.3245010376,
      "lon": -80.3967971802,
      "tz": "America\/New_York"
  },
  "WV29": {
      "icao": "WV29",
      "iata": "",
      "name": "Valley Point Airport",
      "city": "Valley Point",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2000,
      "lat": 39.5797996521,
      "lon": -79.6498031616,
      "tz": "America\/New_York"
  },
  "WV30": {
      "icao": "WV30",
      "iata": "",
      "name": "Rainelle Airport",
      "city": "Rainelle",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 3446,
      "lat": 37.9486999512,
      "lon": -80.7164993286,
      "tz": "America\/New_York"
  },
  "WV32": {
      "icao": "WV32",
      "iata": "",
      "name": "New River Gorge Airport",
      "city": "Lansing",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1720,
      "lat": 38.0890007019,
      "lon": -81.0651016235,
      "tz": "America\/New_York"
  },
  "WV47": {
      "icao": "WV47",
      "iata": "",
      "name": "Rexroad Airport",
      "city": "Clarksburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1350,
      "lat": 39.1357002258,
      "lon": -80.2104034424,
      "tz": "America\/New_York"
  },
  "WV52": {
      "icao": "WV52",
      "iata": "",
      "name": "Nrao Green Bank Airport",
      "city": "Green Bank",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2710,
      "lat": 38.4306983948,
      "lon": -79.8255996704,
      "tz": "America\/New_York"
  },
  "WV53": {
      "icao": "WV53",
      "iata": "",
      "name": "Larew Airport",
      "city": "Newburg",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1790,
      "lat": 39.3720016479,
      "lon": -79.8927993774,
      "tz": "America\/New_York"
  },
  "WV57": {
      "icao": "WV57",
      "iata": "",
      "name": "Mckee Sky Ranch Airport",
      "city": "Terra Alta",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2757,
      "lat": 39.4631004333,
      "lon": -79.5425033569,
      "tz": "America\/New_York"
  },
  "WV59": {
      "icao": "WV59",
      "iata": "",
      "name": "Fayette Airport",
      "city": "Fayetteville",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1960,
      "lat": 38.0267982483,
      "lon": -81.1197967529,
      "tz": "America\/New_York"
  },
  "WV61": {
      "icao": "WV61",
      "iata": "",
      "name": "Buzzards Gap Ultralightport",
      "city": "Berkeley Springs",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 960,
      "lat": 39.4695014954,
      "lon": -78.2356033325,
      "tz": "America\/New_York"
  },
  "WV62": {
      "icao": "WV62",
      "iata": "",
      "name": "Windwood Fly-In Resort Airport",
      "city": "Davis",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 3210,
      "lat": 39.0550994873,
      "lon": -79.4313964844,
      "tz": "America\/New_York"
  },
  "WV63": {
      "icao": "WV63",
      "iata": "",
      "name": "Herold Airport",
      "city": "Craigsville",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2346,
      "lat": 38.3345985413,
      "lon": -80.6530990601,
      "tz": "America\/New_York"
  },
  "WV64": {
      "icao": "WV64",
      "iata": "",
      "name": "Scott Field",
      "city": "Mineral Wells",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 662,
      "lat": 39.1781005859,
      "lon": -81.5236968994,
      "tz": "America\/New_York"
  },
  "WV65": {
      "icao": "WV65",
      "iata": "",
      "name": "Carr Airport",
      "city": "Fairmont",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1670,
      "lat": 39.44929886,
      "lon": -80.01139832,
      "tz": "America\/New_York"
  },
  "WV66": {
      "icao": "WV66",
      "iata": "",
      "name": "Glendale Fokker Field",
      "city": "Glendale",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 648,
      "lat": 39.9486999512,
      "lon": -80.7594985962,
      "tz": "America\/New_York"
  },
  "WV67": {
      "icao": "WV67",
      "iata": "",
      "name": "Eastview Airport",
      "city": "Romney",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1590,
      "lat": 39.3946990967,
      "lon": -78.679397583,
      "tz": "America\/New_York"
  },
  "WV68": {
      "icao": "WV68",
      "iata": "",
      "name": "Durrett Ranches Airport",
      "city": "Belington",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2300,
      "lat": 38.9500007629,
      "lon": -80.0306015015,
      "tz": "America\/New_York"
  },
  "WV70": {
      "icao": "WV70",
      "iata": "",
      "name": "Fairview Airport",
      "city": "Elkins",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 2600,
      "lat": 38.956199646,
      "lon": -79.7886962891,
      "tz": "America\/New_York"
  },
  "WV71": {
      "icao": "WV71",
      "iata": "",
      "name": "Lieving Airport",
      "city": "Letart",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 635,
      "lat": 38.8992004395,
      "lon": -81.9297027588,
      "tz": "America\/New_York"
  },
  "WV76": {
      "icao": "WV76",
      "iata": "",
      "name": "Slate Run Airport",
      "city": "Spencer",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1000,
      "lat": 38.7775993347,
      "lon": -81.3104019165,
      "tz": "America\/New_York"
  },
  "WV77": {
      "icao": "WV77",
      "iata": "",
      "name": "Hinton-Alderson Airport",
      "city": "Pence Springs",
      "state": "West-Virginia",
      "country": "US",
      "elevation": 1520,
      "lat": 37.679599762,
      "lon": -80.7104034424,
      "tz": "America\/New_York"
  },
  "WY00": {
      "icao": "WY00",
      "iata": "",
      "name": "Red Reflet Ranch Airport",
      "city": "Ten Sleep",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4619,
      "lat": 43.96979904,
      "lon": -107.3880005,
      "tz": "America\/Denver"
  },
  "WY01": {
      "icao": "WY01",
      "iata": "",
      "name": "Dilts Ranch Airport",
      "city": "Bill",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4800,
      "lat": 43.3958015442,
      "lon": -105.5550003052,
      "tz": "America\/Denver"
  },
  "WY02": {
      "icao": "WY02",
      "iata": "",
      "name": "Iberlin Ranch Nr 3 Airport",
      "city": "Bill",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4400,
      "lat": 43.1916007996,
      "lon": -104.93800354,
      "tz": "America\/Denver"
  },
  "WY05": {
      "icao": "WY05",
      "iata": "",
      "name": "Skyview Airpark",
      "city": "Cheyenne",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5838,
      "lat": 41.2172012329,
      "lon": -104.5839996338,
      "tz": "America\/Denver"
  },
  "WY06": {
      "icao": "WY06",
      "iata": "",
      "name": "Luckinbill Airstrip",
      "city": "Cody",
      "state": "Montana",
      "country": "US",
      "elevation": 6600,
      "lat": 44.8382987976,
      "lon": -109.6220016479,
      "tz": "America\/Denver"
  },
  "WY07": {
      "icao": "WY07",
      "iata": "",
      "name": "Hardy Ranch Airport",
      "city": "Douglas",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5077,
      "lat": 43.3251991272,
      "lon": -105.6760025024,
      "tz": "America\/Denver"
  },
  "WY09": {
      "icao": "WY09",
      "iata": "",
      "name": "Sherwin Field Nr 1 Airport",
      "city": "Dull Center\/Sw Clareton\/",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4190,
      "lat": 43.5060997009,
      "lon": -104.8779983521,
      "tz": "America\/Denver"
  },
  "WY11": {
      "icao": "WY11",
      "iata": "",
      "name": "A A Ranch Airport",
      "city": "Encampment",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7880,
      "lat": 41.1582984924,
      "lon": -106.5579986572,
      "tz": "America\/Denver"
  },
  "WY12": {
      "icao": "WY12",
      "iata": "",
      "name": "Ohman Ranch Airport",
      "city": "Gillette",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4905,
      "lat": 43.926399231,
      "lon": -105.6529998779,
      "tz": "America\/Denver"
  },
  "WY13": {
      "icao": "WY13",
      "iata": "",
      "name": "Little Buffalo Ranch Airport",
      "city": "Gillette",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4919,
      "lat": 43.7860984802,
      "lon": -105.6640014648,
      "tz": "America\/Denver"
  },
  "WY14": {
      "icao": "WY14",
      "iata": "",
      "name": "Ipy Ranch Airport",
      "city": "Hulett",
      "state": "Wyoming",
      "country": "US",
      "elevation": 3960,
      "lat": 44.6375007629,
      "lon": -104.6890029907,
      "tz": "America\/Denver"
  },
  "WY15": {
      "icao": "WY15",
      "iata": "",
      "name": "Bunch Grass Intergalactic Airport",
      "city": "Powell",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4408,
      "lat": 44.6897010803,
      "lon": -108.6790008545,
      "tz": "America\/Denver"
  },
  "WY16": {
      "icao": "WY16",
      "iata": "",
      "name": "Robbins Airport",
      "city": "Medicine Bow",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7240,
      "lat": 42.2599983215,
      "lon": -105.8590011597,
      "tz": "America\/Denver"
  },
  "WY17": {
      "icao": "WY17",
      "iata": "",
      "name": "Ellis Ranch Airport",
      "city": "Medicine Bow",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6530,
      "lat": 42.021900177,
      "lon": -106.4240036011,
      "tz": "America\/Denver"
  },
  "WY18": {
      "icao": "WY18",
      "iata": "",
      "name": "Iberlin Ranch Nr 2 Airport",
      "city": "Midwest",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5020,
      "lat": 43.5583000183,
      "lon": -106.0090026855,
      "tz": "America\/Denver"
  },
  "WY19": {
      "icao": "WY19",
      "iata": "",
      "name": "Butler Airport",
      "city": "Pine Bluffs",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5295,
      "lat": 41.1058006287,
      "lon": -104.2070007324,
      "tz": "America\/Denver"
  },
  "WY22": {
      "icao": "WY22",
      "iata": "",
      "name": "Iberlin Ranch Nr 1 Airport",
      "city": "Savageton",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4500,
      "lat": 43.9500007629,
      "lon": -105.9339981079,
      "tz": "America\/Denver"
  },
  "WY23": {
      "icao": "WY23",
      "iata": "",
      "name": "Iberlin Strip",
      "city": "Sussex",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4331,
      "lat": 44.073299408,
      "lon": -106.2659988403,
      "tz": "America\/Denver"
  },
  "WY25": {
      "icao": "WY25",
      "iata": "",
      "name": "Snell - North Laramie River Airport",
      "city": "Wheatland",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4490,
      "lat": 42.3069000244,
      "lon": -104.93699646,
      "tz": "America\/Denver"
  },
  "WY27": {
      "icao": "WY27",
      "iata": "",
      "name": "Wagonhound Airport",
      "city": "Douglas",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5470,
      "lat": 42.5867004395,
      "lon": -105.5380020142,
      "tz": "America\/Denver"
  },
  "WY29": {
      "icao": "WY29",
      "iata": "",
      "name": "Vowers Ranch Airport",
      "city": "Chugwater",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5550,
      "lat": 41.6222000122,
      "lon": -104.7850036621,
      "tz": "America\/Denver"
  },
  "WY30": {
      "icao": "WY30",
      "iata": "",
      "name": "Kinky Creek Divide Airport",
      "city": "Dubois",
      "state": "Wyoming",
      "country": "US",
      "elevation": 8803,
      "lat": 43.3754997253,
      "lon": -110.1100006104,
      "tz": "America\/Denver"
  },
  "WY31": {
      "icao": "WY31",
      "iata": "",
      "name": "Melody Ranch Airport",
      "city": "Jackson",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6075,
      "lat": 43.4095993042,
      "lon": -110.7730026245,
      "tz": "America\/Denver"
  },
  "WY33": {
      "icao": "WY33",
      "iata": "",
      "name": "Antelope Run Ranch Airport",
      "city": "Daniel",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7470,
      "lat": 42.8168983459,
      "lon": -110.2779998779,
      "tz": "America\/Denver"
  },
  "WY34": {
      "icao": "WY34",
      "iata": "",
      "name": "Bridger Creek Airport",
      "city": "Lysite",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5350,
      "lat": 43.3404998779,
      "lon": -107.68699646,
      "tz": "America\/Denver"
  },
  "WY36": {
      "icao": "WY36",
      "iata": "",
      "name": "Bakers Field",
      "city": "Powell",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4350,
      "lat": 44.7901992798,
      "lon": -108.7340011597,
      "tz": "America\/Denver"
  },
  "WY37": {
      "icao": "WY37",
      "iata": "",
      "name": "Symons Airport",
      "city": "Sheridan",
      "state": "Wyoming",
      "country": "US",
      "elevation": 3681,
      "lat": 44.840801239,
      "lon": -106.8229980469,
      "tz": "America\/Denver"
  },
  "WY38": {
      "icao": "WY38",
      "iata": "",
      "name": "Orchard Ranch Airport",
      "city": "Ten Sleep",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5277,
      "lat": 43.6236000061,
      "lon": -107.4079971313,
      "tz": "America\/Denver"
  },
  "WY39": {
      "icao": "WY39",
      "iata": "",
      "name": "Star Valley Ranch Airport",
      "city": "Thayne",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6210,
      "lat": 42.9693984985,
      "lon": -110.966003418,
      "tz": "America\/Denver"
  },
  "WY41": {
      "icao": "WY41",
      "iata": "",
      "name": "Red Creek Ranch Airport",
      "city": "Rock Springs",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7050,
      "lat": 41.0666999817,
      "lon": -109.0889968872,
      "tz": "America\/Denver"
  },
  "WY42": {
      "icao": "WY42",
      "iata": "",
      "name": "Yu Ranch Airport",
      "city": "Meeteetse",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5170,
      "lat": 44.3074989319,
      "lon": -108.7369995117,
      "tz": "America\/Denver"
  },
  "WY45": {
      "icao": "WY45",
      "iata": "",
      "name": "Sloan Airport",
      "city": "Cheyenne",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6085,
      "lat": 41.1568984985,
      "lon": -104.7080001831,
      "tz": "America\/Denver"
  },
  "WY51": {
      "icao": "WY51",
      "iata": "",
      "name": "Platt Ranch Airport",
      "city": "Encampment",
      "state": "Wyoming",
      "country": "US",
      "elevation": 7545,
      "lat": 41.1650009155,
      "lon": -106.5950012207,
      "tz": "America\/Denver"
  },
  "WY55": {
      "icao": "WY55",
      "iata": "",
      "name": "Taylor Field",
      "city": "Sundance",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4950,
      "lat": 44.418598175,
      "lon": -104.59400177,
      "tz": "America\/Denver"
  },
  "WY59": {
      "icao": "WY59",
      "iata": "",
      "name": "Two Bar Ranch Airport",
      "city": "Wheatland",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5110,
      "lat": 41.9329986572,
      "lon": -105.0849990845,
      "tz": "America\/Denver"
  },
  "WY60": {
      "icao": "WY60",
      "iata": "",
      "name": "Heiner Airport",
      "city": "Afton",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6440,
      "lat": 42.861000061,
      "lon": -110.8980026245,
      "tz": "America\/Denver"
  },
  "WY61": {
      "icao": "WY61",
      "iata": "",
      "name": "Gas Hills Airstrip",
      "city": "Waltman",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6990,
      "lat": 42.8680992126,
      "lon": -107.4889984131,
      "tz": "America\/Denver"
  },
  "WY64": {
      "icao": "WY64",
      "iata": "",
      "name": "Lone Pine Flying Ranch Airport",
      "city": "Thayne",
      "state": "Wyoming",
      "country": "US",
      "elevation": 6000,
      "lat": 42.9574012756,
      "lon": -110.983001709,
      "tz": "America\/Denver"
  },
  "WY65": {
      "icao": "WY65",
      "iata": "",
      "name": "Madsen Airport",
      "city": "Gillette",
      "state": "Wyoming",
      "country": "US",
      "elevation": 4500,
      "lat": 44.3488998413,
      "lon": -105.3369979858,
      "tz": "America\/Denver"
  },
  "WY66": {
      "icao": "WY66",
      "iata": "",
      "name": "Chamberlain Brothers Ranch Airport",
      "city": "Douglas",
      "state": "Wyoming",
      "country": "US",
      "elevation": 5237,
      "lat": 42.7522010803,
      "lon": -105.6200027466,
      "tz": "America\/Denver"
  },
  "XA00": {
      "icao": "XA00",
      "iata": "",
      "name": "Prose Field",
      "city": "Justin",
      "state": "Texas",
      "country": "US",
      "elevation": 685,
      "lat": 33.1472015381,
      "lon": -97.2802963257,
      "tz": "America\/Chicago"
  },
  "XA02": {
      "icao": "XA02",
      "iata": "",
      "name": "Danz Ranch Airport",
      "city": "Johnson City",
      "state": "Texas",
      "country": "US",
      "elevation": 1230,
      "lat": 30.2801990509,
      "lon": -98.426399231,
      "tz": "America\/Chicago"
  },
  "XA03": {
      "icao": "XA03",
      "iata": "",
      "name": "Edgington Ranch Airport",
      "city": "Sanger",
      "state": "Texas",
      "country": "US",
      "elevation": 885,
      "lat": 33.4283981323,
      "lon": -97.2720031738,
      "tz": "America\/Chicago"
  },
  "XA04": {
      "icao": "XA04",
      "iata": "",
      "name": "Circle Eight Ranch Airport",
      "city": "Glen Rose",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 32.2375984192,
      "lon": -97.899597168,
      "tz": "America\/Chicago"
  },
  "XA05": {
      "icao": "XA05",
      "iata": "",
      "name": "Fairview Field",
      "city": "Sudan",
      "state": "Texas",
      "country": "US",
      "elevation": 3819,
      "lat": 34.1011009216,
      "lon": -102.6240005493,
      "tz": "America\/Chicago"
  },
  "XA07": {
      "icao": "XA07",
      "iata": "",
      "name": "Spectre Airport",
      "city": "Chico",
      "state": "Texas",
      "country": "US",
      "elevation": 835,
      "lat": 33.2750015259,
      "lon": -97.7166976929,
      "tz": "America\/Chicago"
  },
  "XA08": {
      "icao": "XA08",
      "iata": "",
      "name": "Los Cuernos Ranch Airport",
      "city": "Cotulla",
      "state": "Texas",
      "country": "US",
      "elevation": 340,
      "lat": 28.1660995483,
      "lon": -99.0915985107,
      "tz": "America\/Chicago"
  },
  "XA09": {
      "icao": "XA09",
      "iata": "",
      "name": "Menard Airport",
      "city": "Bellevue",
      "state": "Texas",
      "country": "US",
      "elevation": 1055,
      "lat": 33.5819015503,
      "lon": -98.0610961914,
      "tz": "America\/Chicago"
  },
  "XA10": {
      "icao": "XA10",
      "iata": "",
      "name": "Ponderosa Field",
      "city": "Ponder",
      "state": "Texas",
      "country": "US",
      "elevation": 840,
      "lat": 33.2318992615,
      "lon": -97.3387985229,
      "tz": "America\/Chicago"
  },
  "XA12": {
      "icao": "XA12",
      "iata": "",
      "name": "Mcfarlin Ranch Airport",
      "city": "Bertram",
      "state": "Texas",
      "country": "US",
      "elevation": 1200,
      "lat": 30.6947002411,
      "lon": -98.0327987671,
      "tz": "America\/Chicago"
  },
  "XA15": {
      "icao": "XA15",
      "iata": "",
      "name": "Holict Private Airport",
      "city": "Riesel",
      "state": "Texas",
      "country": "US",
      "elevation": 425,
      "lat": 31.4269008636,
      "lon": -96.9796981812,
      "tz": "America\/Chicago"
  },
  "XA16": {
      "icao": "XA16",
      "iata": "",
      "name": "Tightwaad Air Ranch Airport",
      "city": "Rosston",
      "state": "Texas",
      "country": "US",
      "elevation": 875,
      "lat": 33.4942016602,
      "lon": -97.4655990601,
      "tz": "America\/Chicago"
  },
  "XA17": {
      "icao": "XA17",
      "iata": "",
      "name": "Chuckster Airport",
      "city": "Tira",
      "state": "Texas",
      "country": "US",
      "elevation": 475,
      "lat": 33.3217010498,
      "lon": -95.5635986328,
      "tz": "America\/Chicago"
  },
  "XA23": {
      "icao": "XA23",
      "iata": "",
      "name": "Richardson Field",
      "city": "Pittsburg",
      "state": "Texas",
      "country": "US",
      "elevation": 415,
      "lat": 33.01779938,
      "lon": -94.97229767,
      "tz": "America\/Chicago"
  },
  "XA31": {
      "icao": "XA31",
      "iata": "",
      "name": "Lewis Ranch Airport",
      "city": "Leakey",
      "state": "Texas",
      "country": "US",
      "elevation": 1730,
      "lat": 29.8169002533,
      "lon": -99.7878036499,
      "tz": "America\/Chicago"
  },
  "XA32": {
      "icao": "XA32",
      "iata": "",
      "name": "Wolfe Field",
      "city": "Buckholts",
      "state": "Texas",
      "country": "US",
      "elevation": 500,
      "lat": 30.9115009308,
      "lon": -97.1713027954,
      "tz": "America\/Chicago"
  },
  "XA33": {
      "icao": "XA33",
      "iata": "",
      "name": "Thorny Woods Airport",
      "city": "Caddo Mills",
      "state": "Texas",
      "country": "US",
      "elevation": 540,
      "lat": 32.9586982727,
      "lon": -96.2643966675,
      "tz": "America\/Chicago"
  },
  "XA34": {
      "icao": "XA34",
      "iata": "",
      "name": "Allison Farm Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 850,
      "lat": 33.2416992188,
      "lon": -97.4280014038,
      "tz": "America\/Chicago"
  },
  "XA40": {
      "icao": "XA40",
      "iata": "",
      "name": "Hound Run Airport",
      "city": "Hallettsville",
      "state": "Texas",
      "country": "US",
      "elevation": 385,
      "lat": 29.5214996338,
      "lon": -96.9368972778,
      "tz": "America\/Chicago"
  },
  "XA41": {
      "icao": "XA41",
      "iata": "",
      "name": "Lone Star Flying Service Airport",
      "city": "Harper",
      "state": "Texas",
      "country": "US",
      "elevation": 2220,
      "lat": 30.3549995422,
      "lon": -99.2474975586,
      "tz": "America\/Chicago"
  },
  "XA42": {
      "icao": "XA42",
      "iata": "",
      "name": "Connies Aviation Airport",
      "city": "Kaufman",
      "state": "Texas",
      "country": "US",
      "elevation": 340,
      "lat": 32.3755989075,
      "lon": -96.3871002197,
      "tz": "America\/Chicago"
  },
  "XA43": {
      "icao": "XA43",
      "iata": "",
      "name": "Fall Creek Ranch Airport",
      "city": "Kerrville",
      "state": "Texas",
      "country": "US",
      "elevation": 2030,
      "lat": 29.9081001282,
      "lon": -99.2069015503,
      "tz": "America\/Chicago"
  },
  "XA44": {
      "icao": "XA44",
      "iata": "",
      "name": "Birchfield Ranch Airport",
      "city": "Lometa",
      "state": "Texas",
      "country": "US",
      "elevation": 1550,
      "lat": 31.2660999298,
      "lon": -98.4238967896,
      "tz": "America\/Chicago"
  },
  "XA45": {
      "icao": "XA45",
      "iata": "",
      "name": "Weedfalls Airport",
      "city": "Lone Oak",
      "state": "Texas",
      "country": "US",
      "elevation": 560,
      "lat": 33.0211982727,
      "lon": -95.9308013916,
      "tz": "America\/Chicago"
  },
  "XA46": {
      "icao": "XA46",
      "iata": "",
      "name": "Creekside Air Park",
      "city": "Mineola",
      "state": "Texas",
      "country": "US",
      "elevation": 450,
      "lat": 32.6935997009,
      "lon": -95.4543991089,
      "tz": "America\/Chicago"
  },
  "XA47": {
      "icao": "XA47",
      "iata": "",
      "name": "Tick Hill Airfield",
      "city": "Moody",
      "state": "Texas",
      "country": "US",
      "elevation": 645,
      "lat": 31.3097000122,
      "lon": -97.4848022461,
      "tz": "America\/Chicago"
  },
  "XA48": {
      "icao": "XA48",
      "iata": "",
      "name": "Dreamland Airport",
      "city": "Myra",
      "state": "Texas",
      "country": "US",
      "elevation": 945,
      "lat": 33.6417007446,
      "lon": -97.3150024414,
      "tz": "America\/Chicago"
  },
  "XA49": {
      "icao": "XA49",
      "iata": "",
      "name": "Oso Canyon Airport",
      "city": "Ozona",
      "state": "Texas",
      "country": "US",
      "elevation": 1820,
      "lat": 30.0249996185,
      "lon": -101.5459976196,
      "tz": "America\/Chicago"
  },
  "XA51": {
      "icao": "XA51",
      "iata": "",
      "name": "Smith Airport",
      "city": "Robinson",
      "state": "Texas",
      "country": "US",
      "elevation": 525,
      "lat": 31.4183006287,
      "lon": -97.1258010864,
      "tz": "America\/Chicago"
  },
  "XA52": {
      "icao": "XA52",
      "iata": "",
      "name": "Ehni Airport",
      "city": "Trinity",
      "state": "Texas",
      "country": "US",
      "elevation": 225,
      "lat": 30.923500061,
      "lon": -95.2935028076,
      "tz": "America\/Chicago"
  },
  "XA64": {
      "icao": "XA64",
      "iata": "",
      "name": "Nash Ranch Airport",
      "city": "Batesville",
      "state": "Texas",
      "country": "US",
      "elevation": 780,
      "lat": 28.9617004395,
      "lon": -99.3852996826,
      "tz": "America\/Chicago"
  },
  "XA65": {
      "icao": "XA65",
      "iata": "",
      "name": "Txaerosport Aerodrome",
      "city": "Dorchester",
      "state": "Texas",
      "country": "US",
      "elevation": 855,
      "lat": 33.5360984802,
      "lon": -96.6802978516,
      "tz": "America\/Chicago"
  },
  "XA66": {
      "icao": "XA66",
      "iata": "",
      "name": "El Jardin Ranch Airport",
      "city": "Encinal",
      "state": "Texas",
      "country": "US",
      "elevation": 482,
      "lat": 28.0750007629,
      "lon": -99.2863998413,
      "tz": "America\/Chicago"
  },
  "XA68": {
      "icao": "XA68",
      "iata": "",
      "name": "Akroville Airport",
      "city": "Slidell",
      "state": "Texas",
      "country": "US",
      "elevation": 895,
      "lat": 33.3917999268,
      "lon": -97.3553009033,
      "tz": "America\/Chicago"
  },
  "XA71": {
      "icao": "XA71",
      "iata": "",
      "name": "North Cedar Airport",
      "city": "Apple Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 250,
      "lat": 31.277299881,
      "lon": -94.9739990234,
      "tz": "America\/Chicago"
  },
  "XA72": {
      "icao": "XA72",
      "iata": "",
      "name": "Stocker Airport",
      "city": "Krum",
      "state": "Texas",
      "country": "US",
      "elevation": 770,
      "lat": 33.2769012451,
      "lon": -97.2893981934,
      "tz": "America\/Chicago"
  },
  "XA75": {
      "icao": "XA75",
      "iata": "",
      "name": "Double A Airport",
      "city": "Grandview",
      "state": "Texas",
      "country": "US",
      "elevation": 635,
      "lat": 32.3240013123,
      "lon": -97.0997009277,
      "tz": "America\/Chicago"
  },
  "XA77": {
      "icao": "XA77",
      "iata": "",
      "name": "Benny White Flying Airport",
      "city": "Lamesa",
      "state": "Texas",
      "country": "US",
      "elevation": 2943,
      "lat": 32.7486000061,
      "lon": -101.7789993286,
      "tz": "America\/Chicago"
  },
  "XA78": {
      "icao": "XA78",
      "iata": "",
      "name": "Melody Ranch Airport",
      "city": "Mountain Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 729,
      "lat": 33.4622993469,
      "lon": -97.0709991455,
      "tz": "America\/Chicago"
  },
  "XA84": {
      "icao": "XA84",
      "iata": "",
      "name": "Stoney Fork Landing Airport",
      "city": "Valley View",
      "state": "Texas",
      "country": "US",
      "elevation": 680,
      "lat": 33.4768981934,
      "lon": -97.1194000244,
      "tz": "America\/Chicago"
  },
  "XA85": {
      "icao": "XA85",
      "iata": "",
      "name": "Cougar Landing Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 595,
      "lat": 31.63330078,
      "lon": -97.3167038,
      "tz": "America\/Chicago"
  },
  "XA86": {
      "icao": "XA86",
      "iata": "",
      "name": "Driftwood Ranch Airport",
      "city": "Weatherford",
      "state": "Texas",
      "country": "US",
      "elevation": 710,
      "lat": 32.5670013428,
      "lon": -97.8048019409,
      "tz": "America\/Chicago"
  },
  "XA88": {
      "icao": "XA88",
      "iata": "",
      "name": "Dm Ranch Airport",
      "city": "Batesville",
      "state": "Texas",
      "country": "US",
      "elevation": 750,
      "lat": 28.9771995544,
      "lon": -99.5665969849,
      "tz": "America\/Chicago"
  },
  "XA89": {
      "icao": "XA89",
      "iata": "",
      "name": "Faith Ranch Airport",
      "city": "Carrizo Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 773,
      "lat": 28.20870018,
      "lon": -100.0189972,
      "tz": "America\/Chicago"
  },
  "XA90": {
      "icao": "XA90",
      "iata": "",
      "name": "Fly 1 On Airport",
      "city": "Golden",
      "state": "Texas",
      "country": "US",
      "elevation": 474,
      "lat": 32.7571983337,
      "lon": -95.5278015137,
      "tz": "America\/Chicago"
  },
  "XA91": {
      "icao": "XA91",
      "iata": "",
      "name": "Wildwood Airport",
      "city": "Village Mills",
      "state": "Texas",
      "country": "US",
      "elevation": 118,
      "lat": 30.533333,
      "lon": -94.438056,
      "tz": "America\/Chicago"
  },
  "XA92": {
      "icao": "XA92",
      "iata": "",
      "name": "Herd Ranch Airport",
      "city": "Menard",
      "state": "Texas",
      "country": "US",
      "elevation": 2185,
      "lat": 30.8700008392,
      "lon": -100.1029968262,
      "tz": "America\/Chicago"
  },
  "XA93": {
      "icao": "XA93",
      "iata": "",
      "name": "Tortuga Ranch Airport",
      "city": "Brundage",
      "state": "Texas",
      "country": "US",
      "elevation": 550,
      "lat": 28.6193008423,
      "lon": -99.6544036865,
      "tz": "America\/Chicago"
  },
  "XA95": {
      "icao": "XA95",
      "iata": "",
      "name": "Candelaria Airport",
      "city": "Candelaria",
      "state": "Texas",
      "country": "US",
      "elevation": 2913,
      "lat": 30.1494007111,
      "lon": -104.6829986572,
      "tz": "America\/Chicago"
  },
  "XA96": {
      "icao": "XA96",
      "iata": "",
      "name": "En Gedi Ranch Airport",
      "city": "Canadian",
      "state": "Texas",
      "country": "US",
      "elevation": 2800,
      "lat": 35.6624984741,
      "lon": -100.3239974976,
      "tz": "America\/Chicago"
  },
  "XA98": {
      "icao": "XA98",
      "iata": "",
      "name": "Jbj Ranch Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 890,
      "lat": 33.3027000427,
      "lon": -97.4580993652,
      "tz": "America\/Chicago"
  },
  "XA99": {
      "icao": "XA99",
      "iata": "",
      "name": "Flat Bush Airport",
      "city": "Decatur",
      "state": "Texas",
      "country": "US",
      "elevation": 862,
      "lat": 33.1343994141,
      "lon": -97.6070022583,
      "tz": "America\/Chicago"
  },
  "XBRO": {
      "icao": "XBRO",
      "iata": "SKL",
      "name": "Skye Bridge Ashaig Airport",
      "city": "Broadford",
      "state": "Scotland",
      "country": "GB",
      "elevation": 0,
      "lat": 57.2533988953,
      "lon": -5.8278999329,
      "tz": "Europe\/London"
  },
  "XIUW": {
      "icao": "XIUW",
      "iata": "",
      "name": "Ulan-Ude East Airport",
      "city": "Ulan Ude",
      "state": "Respublika-Buryatiya",
      "country": "RU",
      "elevation": 1670,
      "lat": 51.8499984741,
      "lon": 107.7379989624,
      "tz": "Asia\/Irkutsk"
  },
  "XLAP": {
      "icao": "XLAP",
      "iata": "",
      "name": "Plestsy Airport",
      "city": "Plesetsk",
      "state": "Arkhangelskaya",
      "country": "RU",
      "elevation": 427,
      "lat": 62.7167015076,
      "lon": 40.4883003235,
      "tz": "Europe\/Moscow"
  },
  "XLLL": {
      "icao": "XLLL",
      "iata": "",
      "name": "Soltsy-2 Air Base",
      "city": "Soltsy",
      "state": "Novgorod",
      "country": "RU",
      "elevation": 266,
      "lat": 58.1395454407,
      "lon": 30.3304195404,
      "tz": "Europe\/Moscow"
  },
  "XLLN": {
      "icao": "XLLN",
      "iata": "",
      "name": "Kasimovo Airfield",
      "city": "St. Petersburg",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 230,
      "lat": 60.198299408,
      "lon": 30.3349990845,
      "tz": "Europe\/Moscow"
  },
  "XLML": {
      "icao": "XLML",
      "iata": "",
      "name": "Luostari Air Base",
      "city": "Zapolyarnyj",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 249,
      "lat": 69.4000015259,
      "lon": 30.9883003235,
      "tz": "Europe\/Moscow"
  },
  "XLMV": {
      "icao": "XLMV",
      "iata": "",
      "name": "Severomorsk-3 Naval Air Base",
      "city": "Severomorsk",
      "state": "Murmansk",
      "country": "RU",
      "elevation": 564,
      "lat": 68.8666687012,
      "lon": 33.7166671753,
      "tz": "Europe\/Moscow"
  },
  "XLOS": {
      "icao": "XLOS",
      "iata": "",
      "name": "Ostrov Naval Air Base",
      "city": "Ostrov",
      "state": "Pskov",
      "country": "RU",
      "elevation": 220,
      "lat": 57.2999992371,
      "lon": 28.4333000183,
      "tz": "Europe\/Moscow"
  },
  "XLPD": {
      "icao": "XLPD",
      "iata": "",
      "name": "Derevyannoe Airport",
      "city": "Derevyannoe",
      "state": "Karelia",
      "country": "RU",
      "elevation": 131,
      "lat": 61.6069984436,
      "lon": 34.6209983826,
      "tz": "Europe\/Moscow"
  },
  "XLPO": {
      "icao": "XLPO",
      "iata": "",
      "name": "Lodeynoye Pole Air Base",
      "city": "Lodeynoye Pole",
      "state": "Leningradskaya-Oblast'",
      "country": "RU",
      "elevation": 56,
      "lat": 60.7099990845,
      "lon": 33.5699996948,
      "tz": "Europe\/Moscow"
  },
  "XLWF": {
      "icao": "XLWF",
      "iata": "",
      "name": "Fedotovo Naval Air Base",
      "city": "Kipelovo",
      "state": "Vologda",
      "country": "RU",
      "elevation": 574,
      "lat": 59.1883010864,
      "lon": 39.123298645,
      "tz": "Europe\/Moscow"
  },
  "XLWT": {
      "icao": "XLWT",
      "iata": "",
      "name": "Trufanovo Airfield",
      "city": "Vologda",
      "state": "Vologda",
      "country": "RU",
      "elevation": 417,
      "lat": 59.3089981079,
      "lon": 39.8499984741,
      "tz": "Europe\/Moscow"
  },
  "XRMB": {
      "icao": "XRMB",
      "iata": "",
      "name": "Budennovsk",
      "city": "Budennovsk",
      "state": "Stavropol'skiy",
      "country": "RU",
      "elevation": 361,
      "lat": 44.833333,
      "lon": 44.016667,
      "tz": "Europe\/Moscow"
  },
  "XRWL": {
      "icao": "XRWL",
      "iata": "",
      "name": "Lebyazhye Air Base",
      "city": "Kamyshin",
      "state": "Volgograd",
      "country": "RU",
      "elevation": 381,
      "lat": 50.2016983032,
      "lon": 45.2083015442,
      "tz": "Europe\/Volgograd"
  },
  "XS00": {
      "icao": "XS00",
      "iata": "",
      "name": "Flying D Airport",
      "city": "Llano",
      "state": "Texas",
      "country": "US",
      "elevation": 1460,
      "lat": 30.6371994019,
      "lon": -98.748298645,
      "tz": "America\/Chicago"
  },
  "XS01": {
      "icao": "XS01",
      "iata": "",
      "name": "Tschirhart Ranch Airport",
      "city": "Fredericksburg",
      "state": "Texas",
      "country": "US",
      "elevation": 2060,
      "lat": 30.4501991272,
      "lon": -98.8861999512,
      "tz": "America\/Chicago"
  },
  "XS03": {
      "icao": "XS03",
      "iata": "",
      "name": "Herbert Ranch Airport",
      "city": "Marble Falls",
      "state": "Texas",
      "country": "US",
      "elevation": 1140,
      "lat": 30.481300354,
      "lon": -98.2810974121,
      "tz": "America\/Chicago"
  },
  "XS05": {
      "icao": "XS05",
      "iata": "",
      "name": "H M Ranch Airport",
      "city": "Marion",
      "state": "Texas",
      "country": "US",
      "elevation": 751,
      "lat": 29.5988006592,
      "lon": -98.1710968018,
      "tz": "America\/Chicago"
  },
  "XS06": {
      "icao": "XS06",
      "iata": "",
      "name": "Flying B Ranch Airport",
      "city": "Grandview",
      "state": "Texas",
      "country": "US",
      "elevation": 665,
      "lat": 32.298500061,
      "lon": -97.1494979858,
      "tz": "America\/Chicago"
  },
  "XS07": {
      "icao": "XS07",
      "iata": "",
      "name": "W D Cornilius Ranch Airport",
      "city": "Markham",
      "state": "Texas",
      "country": "US",
      "elevation": 47,
      "lat": 28.9503002167,
      "lon": -96.0599975586,
      "tz": "America\/Chicago"
  },
  "XS08": {
      "icao": "XS08",
      "iata": "",
      "name": "Polk Ranch Airport",
      "city": "Mason",
      "state": "Texas",
      "country": "US",
      "elevation": 1840,
      "lat": 30.8768005371,
      "lon": -99.1995010376,
      "tz": "America\/Chicago"
  },
  "XS09": {
      "icao": "XS09",
      "iata": "",
      "name": "Estates Airpark",
      "city": "New Waverly",
      "state": "Texas",
      "country": "US",
      "elevation": 325,
      "lat": 30.5375003815,
      "lon": -95.4364013672,
      "tz": "America\/Chicago"
  },
  "XS10": {
      "icao": "XS10",
      "iata": "",
      "name": "Aransas National Wildlife Refuge Airport",
      "city": "Matagorda Island",
      "state": "Texas",
      "country": "US",
      "elevation": 10,
      "lat": 28.1170005798,
      "lon": -96.8003005981,
      "tz": "America\/Chicago"
  },
  "XS11": {
      "icao": "XS11",
      "iata": "",
      "name": "Idlewild Airport",
      "city": "Medina",
      "state": "Texas",
      "country": "US",
      "elevation": 1660,
      "lat": 29.776599884,
      "lon": -99.3591995239,
      "tz": "America\/Chicago"
  },
  "XS12": {
      "icao": "XS12",
      "iata": "",
      "name": "C F C Aviation Ranch Airport",
      "city": "Liberty Hill",
      "state": "Texas",
      "country": "US",
      "elevation": 1161,
      "lat": 30.6760005951,
      "lon": -98.0175018311,
      "tz": "America\/Chicago"
  },
  "XS13": {
      "icao": "XS13",
      "iata": "",
      "name": "T-4 Ranch Airport",
      "city": "Medina",
      "state": "Texas",
      "country": "US",
      "elevation": 1595,
      "lat": 29.8166007996,
      "lon": -99.1967010498,
      "tz": "America\/Chicago"
  },
  "XS14": {
      "icao": "XS14",
      "iata": "",
      "name": "Weese International Airport",
      "city": "Greenville",
      "state": "Texas",
      "country": "US",
      "elevation": 487,
      "lat": 32.9693984985,
      "lon": -96.070602417,
      "tz": "America\/Chicago"
  },
  "XS15": {
      "icao": "XS15",
      "iata": "",
      "name": "Womack Ranch Airport",
      "city": "Menard",
      "state": "Texas",
      "country": "US",
      "elevation": 2200,
      "lat": 30.7709999084,
      "lon": -99.9003982544,
      "tz": "America\/Chicago"
  },
  "XS17": {
      "icao": "XS17",
      "iata": "",
      "name": "Hensley Ranch Airport",
      "city": "Milano",
      "state": "Texas",
      "country": "US",
      "elevation": 460,
      "lat": 30.7091007233,
      "lon": -96.9019012451,
      "tz": "America\/Chicago"
  },
  "XS18": {
      "icao": "XS18",
      "iata": "",
      "name": "Tom J Moore Farm Airport",
      "city": "Millican",
      "state": "Texas",
      "country": "US",
      "elevation": 198,
      "lat": 30.3847007751,
      "lon": -96.2247009277,
      "tz": "America\/Chicago"
  },
  "XS20": {
      "icao": "XS20",
      "iata": "",
      "name": "Dos Arroyos Ranch Airport",
      "city": "Mountain Home",
      "state": "Texas",
      "country": "US",
      "elevation": 2058,
      "lat": 30.1541004181,
      "lon": -99.2977981567,
      "tz": "America\/Chicago"
  },
  "XS21": {
      "icao": "XS21",
      "iata": "",
      "name": "H & S Airfield",
      "city": "Damon",
      "state": "Texas",
      "country": "US",
      "elevation": 100,
      "lat": 29.3311004639,
      "lon": -95.7046966553,
      "tz": "America\/Chicago"
  },
  "XS22": {
      "icao": "XS22",
      "iata": "",
      "name": "Y O Ranch Airport",
      "city": "Mountain Home",
      "state": "Texas",
      "country": "US",
      "elevation": 2136,
      "lat": 30.2040996552,
      "lon": -99.6736984253,
      "tz": "America\/Chicago"
  },
  "XS23": {
      "icao": "XS23",
      "iata": "",
      "name": "Priour Ranch Airport",
      "city": "Mountain Home",
      "state": "Texas",
      "country": "US",
      "elevation": 2200,
      "lat": 30.1266002655,
      "lon": -99.4711990356,
      "tz": "America\/Chicago"
  },
  "XS24": {
      "icao": "XS24",
      "iata": "",
      "name": "Cherry Spraying Service Airport",
      "city": "Muldoon",
      "state": "Texas",
      "country": "US",
      "elevation": 370,
      "lat": 29.82970047,
      "lon": -97.0991973877,
      "tz": "America\/Chicago"
  },
  "XS25": {
      "icao": "XS25",
      "iata": "",
      "name": "Flying C Ranch Airport",
      "city": "Needville",
      "state": "Texas",
      "country": "US",
      "elevation": 70,
      "lat": 29.3847007751,
      "lon": -95.6829986572,
      "tz": "America\/Chicago"
  },
  "XS27": {
      "icao": "XS27",
      "iata": "",
      "name": "Farm Air Service Airport",
      "city": "Nome",
      "state": "Texas",
      "country": "US",
      "elevation": 45,
      "lat": 30.0377006531,
      "lon": -94.3960037231,
      "tz": "America\/Chicago"
  },
  "XS28": {
      "icao": "XS28",
      "iata": "",
      "name": "North Willis Airport",
      "city": "Nome",
      "state": "Texas",
      "country": "US",
      "elevation": 42,
      "lat": 30.026599884,
      "lon": -94.4626998901,
      "tz": "America\/Chicago"
  },
  "XS29": {
      "icao": "XS29",
      "iata": "",
      "name": "Sonny Broussard Landing Strip",
      "city": "Nome",
      "state": "Texas",
      "country": "US",
      "elevation": 35,
      "lat": 29.9908008575,
      "lon": -94.4224014282,
      "tz": "America\/Chicago"
  },
  "XS30": {
      "icao": "XS30",
      "iata": "",
      "name": "Burress Airport",
      "city": "Paris",
      "state": "Texas",
      "country": "US",
      "elevation": 484,
      "lat": 33.6050987244,
      "lon": -95.4819030762,
      "tz": "America\/Chicago"
  },
  "XS31": {
      "icao": "XS31",
      "iata": "",
      "name": "Graham Field",
      "city": "Sadler",
      "state": "Texas",
      "country": "US",
      "elevation": 695,
      "lat": 33.7018013,
      "lon": -96.8319015503,
      "tz": "America\/Chicago"
  },
  "XS33": {
      "icao": "XS33",
      "iata": "",
      "name": "Chesson Airport",
      "city": "Orangefield",
      "state": "Texas",
      "country": "US",
      "elevation": 10,
      "lat": 30.0624008179,
      "lon": -93.8646011353,
      "tz": "America\/Chicago"
  },
  "XS34": {
      "icao": "XS34",
      "iata": "",
      "name": "Skylark Airport",
      "city": "Ennis",
      "state": "Texas",
      "country": "US",
      "elevation": 430,
      "lat": 32.3125,
      "lon": -96.488889,
      "tz": "America\/Chicago"
  },
  "XS35": {
      "icao": "XS35",
      "iata": "",
      "name": "Trull Airport",
      "city": "Palacios",
      "state": "Texas",
      "country": "US",
      "elevation": 18,
      "lat": 28.739200592,
      "lon": -96.2191009521,
      "tz": "America\/Chicago"
  },
  "XS36": {
      "icao": "XS36",
      "iata": "",
      "name": "Tres Ninos Ranch Airport",
      "city": "Pandale",
      "state": "Texas",
      "country": "US",
      "elevation": 1690,
      "lat": 30.253528,
      "lon": -101.691631,
      "tz": "America\/Chicago"
  },
  "XS39": {
      "icao": "XS39",
      "iata": "",
      "name": "A&A Flying Service Airport",
      "city": "Pearland",
      "state": "Texas",
      "country": "US",
      "elevation": 49,
      "lat": 29.5363006592,
      "lon": -95.2640991211,
      "tz": "America\/Chicago"
  },
  "XS40": {
      "icao": "XS40",
      "iata": "",
      "name": "Jay Kay Ranch Airport",
      "city": "Pearsall",
      "state": "Texas",
      "country": "US",
      "elevation": 666,
      "lat": 28.8003005981,
      "lon": -99.0002975464,
      "tz": "America\/Chicago"
  },
  "XS42": {
      "icao": "XS42",
      "iata": "",
      "name": "Agricultural Supplies Airport",
      "city": "Pearsall",
      "state": "Texas",
      "country": "US",
      "elevation": 651,
      "lat": 28.9293994904,
      "lon": -99.0802993774,
      "tz": "America\/Chicago"
  },
  "XS43": {
      "icao": "XS43",
      "iata": "",
      "name": "Medina River Ranch Airport",
      "city": "Pipe Creek",
      "state": "Texas",
      "country": "US",
      "elevation": 1216,
      "lat": 29.6616001129,
      "lon": -98.9563980103,
      "tz": "America\/Chicago"
  },
  "XS44": {
      "icao": "XS44",
      "iata": "",
      "name": "Rancho Del Cielo Airport",
      "city": "Kent",
      "state": "Texas",
      "country": "US",
      "elevation": 4408,
      "lat": 31.0153007507,
      "lon": -104.2119979858,
      "tz": "America\/Chicago"
  },
  "XS46": {
      "icao": "XS46",
      "iata": "",
      "name": "Port O'Connor Private Airport",
      "city": "Port O'Connor",
      "state": "Texas",
      "country": "US",
      "elevation": 8,
      "lat": 28.4297008514,
      "lon": -96.4443969727,
      "tz": "America\/Chicago"
  },
  "XS47": {
      "icao": "XS47",
      "iata": "",
      "name": "Maurice Dauwe Farm Airport",
      "city": "Poteet",
      "state": "Texas",
      "country": "US",
      "elevation": 447,
      "lat": 29.0002002716,
      "lon": -98.5710983276,
      "tz": "America\/Chicago"
  },
  "XS48": {
      "icao": "XS48",
      "iata": "",
      "name": "Marlin'S Meadow Airport",
      "city": "Yantis",
      "state": "Texas",
      "country": "US",
      "elevation": 490,
      "lat": 32.953889,
      "lon": -95.5775,
      "tz": "America\/Chicago"
  },
  "XS49": {
      "icao": "XS49",
      "iata": "",
      "name": "Dean Ranch Airport",
      "city": "Shiner",
      "state": "Texas",
      "country": "US",
      "elevation": 400,
      "lat": 29.4902000427,
      "lon": -97.1219024658,
      "tz": "America\/Chicago"
  },
  "XS50": {
      "icao": "XS50",
      "iata": "",
      "name": "4-G Ranch Airport",
      "city": "Premont",
      "state": "Texas",
      "country": "US",
      "elevation": 272,
      "lat": 27.383600235,
      "lon": -98.3099975586,
      "tz": "America\/Chicago"
  },
  "XS51": {
      "icao": "XS51",
      "iata": "",
      "name": "Seeligson Ranch Airport",
      "city": "Premont",
      "state": "Texas",
      "country": "US",
      "elevation": 172,
      "lat": 27.404499054,
      "lon": -98.1446990967,
      "tz": "America\/Chicago"
  },
  "XS53": {
      "icao": "XS53",
      "iata": "",
      "name": "Price Ranch Airport",
      "city": "Big Wells",
      "state": "Texas",
      "country": "US",
      "elevation": 532,
      "lat": 28.5960998535,
      "lon": -99.5714035034,
      "tz": "America\/Chicago"
  },
  "XS55": {
      "icao": "XS55",
      "iata": "",
      "name": "Gizmo Field",
      "city": "Malone",
      "state": "Texas",
      "country": "US",
      "elevation": 551,
      "lat": 31.9293003082,
      "lon": -96.9389038086,
      "tz": "America\/Chicago"
  },
  "XS56": {
      "icao": "XS56",
      "iata": "",
      "name": "Bell Airfield",
      "city": "Raymondville",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 26.4486999512,
      "lon": -97.7919006348,
      "tz": "America\/Chicago"
  },
  "XS57": {
      "icao": "XS57",
      "iata": "",
      "name": "Havelka Haven Airport",
      "city": "Mc Gregor",
      "state": "Texas",
      "country": "US",
      "elevation": 711,
      "lat": 31.4654006958,
      "lon": -97.404800415,
      "tz": "America\/Chicago"
  },
  "XS58": {
      "icao": "XS58",
      "iata": "",
      "name": "Tri-County Air Service Airport",
      "city": "Raywood",
      "state": "Texas",
      "country": "US",
      "elevation": 70,
      "lat": 30.0440998077,
      "lon": -94.7058029175,
      "tz": "America\/Chicago"
  },
  "XS59": {
      "icao": "XS59",
      "iata": "",
      "name": "Mellon Ranch Airport",
      "city": "Refugio",
      "state": "Texas",
      "country": "US",
      "elevation": 38,
      "lat": 28.2807998657,
      "lon": -97.2114028931,
      "tz": "America\/Chicago"
  },
  "XS60": {
      "icao": "XS60",
      "iata": "",
      "name": "Mustang Community Airfield",
      "city": "Aubrey",
      "state": "Texas",
      "country": "US",
      "elevation": 605,
      "lat": 33.3184013367,
      "lon": -96.90599823,
      "tz": "America\/Chicago"
  },
  "XS61": {
      "icao": "XS61",
      "iata": "",
      "name": "Deep Creek Ranch Airport",
      "city": "Richland Springs",
      "state": "Texas",
      "country": "US",
      "elevation": 1388,
      "lat": 31.114900589,
      "lon": -99.00340271,
      "tz": "America\/Chicago"
  },
  "XS62": {
      "icao": "XS62",
      "iata": "",
      "name": "Casey Three Ranch Airport",
      "city": "Rio Frio",
      "state": "Texas",
      "country": "US",
      "elevation": 1586,
      "lat": 29.6333007813,
      "lon": -99.7286987305,
      "tz": "America\/Chicago"
  },
  "XS63": {
      "icao": "XS63",
      "iata": "",
      "name": "Texas Air Museum Airport",
      "city": "Rio Hondo",
      "state": "Texas",
      "country": "US",
      "elevation": 30,
      "lat": 26.2287006378,
      "lon": -97.560798645,
      "tz": "America\/Chicago"
  },
  "XS64": {
      "icao": "XS64",
      "iata": "",
      "name": "Farm Services Inc Airport",
      "city": "Rio Hondo",
      "state": "Texas",
      "country": "US",
      "elevation": 25,
      "lat": 26.2490005493,
      "lon": -97.5650024414,
      "tz": "America\/Chicago"
  },
  "XS66": {
      "icao": "XS66",
      "iata": "",
      "name": "Rabb Dusting Inc Airport",
      "city": "Robstown",
      "state": "Texas",
      "country": "US",
      "elevation": 83,
      "lat": 27.803899765,
      "lon": -97.743598938,
      "tz": "America\/Chicago"
  },
  "XS67": {
      "icao": "XS67",
      "iata": "",
      "name": "San Jose Island Airport",
      "city": "Rockport",
      "state": "Texas",
      "country": "US",
      "elevation": 10,
      "lat": 27.9444999695,
      "lon": -96.9850006104,
      "tz": "America\/Chicago"
  },
  "XS68": {
      "icao": "XS68",
      "iata": "",
      "name": "Sky Lane Ranch Airport",
      "city": "Brenham",
      "state": "Texas",
      "country": "US",
      "elevation": 290,
      "lat": 30.2569007874,
      "lon": -96.3285980225,
      "tz": "America\/Chicago"
  },
  "XS69": {
      "icao": "XS69",
      "iata": "",
      "name": "Hackberry Ranch Airport",
      "city": "Rocksprings",
      "state": "Texas",
      "country": "US",
      "elevation": 1829,
      "lat": 29.9113006592,
      "lon": -100,
      "tz": "America\/Chicago"
  },
  "XS70": {
      "icao": "XS70",
      "iata": "",
      "name": "Glover Airport",
      "city": "Mount Pleasant",
      "state": "Texas",
      "country": "US",
      "elevation": 400,
      "lat": 33.134601593,
      "lon": -95.0179977417,
      "tz": "America\/Chicago"
  },
  "XS71": {
      "icao": "XS71",
      "iata": "",
      "name": "San Christoval Ranch Airport",
      "city": "Pawnee",
      "state": "Texas",
      "country": "US",
      "elevation": 385,
      "lat": 28.7481002808,
      "lon": -98.0449981689,
      "tz": "America\/Chicago"
  },
  "XS72": {
      "icao": "XS72",
      "iata": "",
      "name": "F R Duke Farm Airport",
      "city": "Romayor",
      "state": "Texas",
      "country": "US",
      "elevation": 85,
      "lat": 30.483499527,
      "lon": -94.8335037231,
      "tz": "America\/Chicago"
  },
  "XS73": {
      "icao": "XS73",
      "iata": "",
      "name": "Double D Ranch Airport",
      "city": "Rosanky",
      "state": "Texas",
      "country": "US",
      "elevation": 520,
      "lat": 29.8736000061,
      "lon": -97.2882995605,
      "tz": "America\/Chicago"
  },
  "XS74": {
      "icao": "XS74",
      "iata": "",
      "name": "Diamondaire Airport",
      "city": "Waco",
      "state": "Texas",
      "country": "US",
      "elevation": 424,
      "lat": 31.6082000732,
      "lon": -97.1628036499,
      "tz": "America\/Chicago"
  },
  "XS75": {
      "icao": "XS75",
      "iata": "",
      "name": "West Ranch Airport",
      "city": "Round Mountain",
      "state": "Texas",
      "country": "US",
      "elevation": 1470,
      "lat": 30.4543991089,
      "lon": -98.4891967773,
      "tz": "America\/Chicago"
  },
  "XS76": {
      "icao": "XS76",
      "iata": "",
      "name": "Texas Menhaden Strip",
      "city": "Sabine Pass",
      "state": "Texas",
      "country": "US",
      "elevation": 6,
      "lat": 29.7241001129,
      "lon": -93.8703994751,
      "tz": "America\/Chicago"
  },
  "XS77": {
      "icao": "XS77",
      "iata": "",
      "name": "Seafood Warehouse Park Airport",
      "city": "Crystal Beach",
      "state": "Texas",
      "country": "US",
      "elevation": 9,
      "lat": 29.4654998779,
      "lon": -94.6274032593,
      "tz": "America\/Chicago"
  },
  "XS78": {
      "icao": "XS78",
      "iata": "",
      "name": "Las Culebras Airport",
      "city": "Brady",
      "state": "Texas",
      "country": "US",
      "elevation": 1387,
      "lat": 31.385417,
      "lon": -99.099833,
      "tz": "America\/Chicago"
  },
  "XS79": {
      "icao": "XS79",
      "iata": "",
      "name": "A W Ranch Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 540,
      "lat": 29.258600235,
      "lon": -98.4583969116,
      "tz": "America\/Chicago"
  },
  "XS80": {
      "icao": "XS80",
      "iata": "",
      "name": "Santiago Cattle Co. Airport",
      "city": "Comfort",
      "state": "Texas",
      "country": "US",
      "elevation": 1497,
      "lat": 29.972556,
      "lon": -98.934639,
      "tz": "America\/Chicago"
  },
  "XS86": {
      "icao": "XS86",
      "iata": "",
      "name": "T-Ranch Airport",
      "city": "Castroville",
      "state": "Texas",
      "country": "US",
      "elevation": 880,
      "lat": 29.2668991089,
      "lon": -98.9253005981,
      "tz": "America\/Chicago"
  },
  "XS88": {
      "icao": "XS88",
      "iata": "",
      "name": "Parson Field",
      "city": "Paris",
      "state": "Texas",
      "country": "US",
      "elevation": 475,
      "lat": 33.8573989868,
      "lon": -95.7048034668,
      "tz": "America\/Chicago"
  },
  "XS89": {
      "icao": "XS89",
      "iata": "",
      "name": "Yates Airport",
      "city": "San Antonio",
      "state": "Texas",
      "country": "US",
      "elevation": 971,
      "lat": 29.621099472,
      "lon": -98.4558029175,
      "tz": "America\/Chicago"
  },
  "XS90": {
      "icao": "XS90",
      "iata": "",
      "name": "Fentress Airpark",
      "city": "Fentress",
      "state": "Texas",
      "country": "US",
      "elevation": 460,
      "lat": 29.7682991028,
      "lon": -97.7752990723,
      "tz": "America\/Chicago"
  },
  "XS91": {
      "icao": "XS91",
      "iata": "",
      "name": "Pickle Plantation Airport",
      "city": "Poynor",
      "state": "Texas",
      "country": "US",
      "elevation": 580,
      "lat": 32.0864982605,
      "lon": -95.5992965698,
      "tz": "America\/Chicago"
  },
  "XS92": {
      "icao": "XS92",
      "iata": "",
      "name": "Jackson \/Bill\/ Airport",
      "city": "Paradise",
      "state": "Texas",
      "country": "US",
      "elevation": 800,
      "lat": 33.1459999084,
      "lon": -97.7125015259,
      "tz": "America\/Chicago"
  },
  "XS93": {
      "icao": "XS93",
      "iata": "",
      "name": "Diamond O Ranch Airport",
      "city": "Santa Elena",
      "state": "Texas",
      "country": "US",
      "elevation": 420,
      "lat": 26.716999054,
      "lon": -98.5586013794,
      "tz": "America\/Chicago"
  },
  "XS94": {
      "icao": "XS94",
      "iata": "",
      "name": "Corralitos Airport",
      "city": "San Ygnacio",
      "state": "Texas",
      "country": "US",
      "elevation": 360,
      "lat": 27.1114006042,
      "lon": -99.4225006104,
      "tz": "America\/Chicago"
  },
  "XS95": {
      "icao": "XS95",
      "iata": "",
      "name": "Marty Ranch Airport",
      "city": "Schulenburg",
      "state": "Texas",
      "country": "US",
      "elevation": 365,
      "lat": 29.6769008636,
      "lon": -96.9704971313,
      "tz": "America\/Chicago"
  },
  "XS99": {
      "icao": "XS99",
      "iata": "",
      "name": "Lake Water Wheel Airport",
      "city": "Shepherd",
      "state": "Texas",
      "country": "US",
      "elevation": 80,
      "lat": 30.4710006714,
      "lon": -94.9126968384,
      "tz": "America\/Chicago"
  },
  "XUBS": {
      "icao": "XUBS",
      "iata": "",
      "name": "Smolensk North Airport",
      "city": "Smolensk",
      "state": "Smolensk",
      "country": "RU",
      "elevation": 820,
      "lat": 54.824,
      "lon": 32.025,
      "tz": "Europe\/Moscow"
  },
  "XUMR": {
      "icao": "XUMR",
      "iata": "",
      "name": "Vyazma Airport",
      "city": "Vyazma",
      "state": "Smolensk",
      "country": "RU",
      "elevation": 794,
      "lat": 55.1483001709,
      "lon": 34.3833007812,
      "tz": "Europe\/Moscow"
  },
  "XWPD": {
      "icao": "XWPD",
      "iata": "",
      "name": "Sosnovka Airfield",
      "city": "Penza",
      "state": "Penza",
      "country": "RU",
      "elevation": 0,
      "lat": 53.2610015869,
      "lon": 45.2770004272,
      "tz": "Europe\/Moscow"
  },
  "XWSG": {
      "icao": "XWSG",
      "iata": "",
      "name": "Engels Airbase",
      "city": "Engels",
      "state": "Saratov",
      "country": "RU",
      "elevation": 128,
      "lat": 51.466667,
      "lon": 46.2,
      "tz": "Europe\/Saratov"
  },
  "YAAL": {
      "icao": "YAAL",
      "iata": "",
      "name": "Yarralin Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.4447002411,
      "lon": 130.8809967041,
      "tz": "Australia\/Darwin"
  },
  "YABA": {
      "icao": "YABA",
      "iata": "ALH",
      "name": "Albany Airport",
      "city": "Albany",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 233,
      "lat": -34.9432983398,
      "lon": 117.8089981079,
      "tz": "Australia\/Perth"
  },
  "YABI": {
      "icao": "YABI",
      "iata": "ABG",
      "name": "Abingdon Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.6166992188,
      "lon": 143.167007446,
      "tz": "Australia\/Brisbane"
  },
  "YABS": {
      "icao": "YABS",
      "iata": "",
      "name": "Albion Downs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.2833003998,
      "lon": 120.3830032349,
      "tz": "Australia\/Perth"
  },
  "YABU": {
      "icao": "YABU",
      "iata": "",
      "name": "Amburla Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.3332996368,
      "lon": 133.1829986572,
      "tz": "Australia\/Darwin"
  },
  "YACI": {
      "icao": "YACI",
      "iata": "",
      "name": "Arcadia Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2000007629,
      "lon": 148.6829986572,
      "tz": "Australia\/Brisbane"
  },
  "YACS": {
      "icao": "YACS",
      "iata": "",
      "name": "Acacia Downs Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.4167003632,
      "lon": 141.8999938965,
      "tz": "Australia\/Sydney"
  },
  "YADD": {
      "icao": "YADD",
      "iata": "",
      "name": "Arubiddy Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7999992371,
      "lon": 125.9329986572,
      "tz": "Australia\/Eucla"
  },
  "YADG": {
      "icao": "YADG",
      "iata": "",
      "name": "Aldinga Airport",
      "city": "Aldinga",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 110,
      "lat": -35.2900009155,
      "lon": 138.490005493,
      "tz": "Australia\/Adelaide"
  },
  "YADI": {
      "icao": "YADI",
      "iata": "",
      "name": "Adaminaby Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3346,
      "lat": -35.9977989197,
      "lon": 148.796005249,
      "tz": "Australia\/Sydney"
  },
  "YADM": {
      "icao": "YADM",
      "iata": "",
      "name": "Yandan Mine Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.27333333,
      "lon": 146.99,
      "tz": "Australia\/Brisbane"
  },
  "YADO": {
      "icao": "YADO",
      "iata": "",
      "name": "Andado Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.411699295,
      "lon": 135.2899932861,
      "tz": "Australia\/Darwin"
  },
  "YADS": {
      "icao": "YADS",
      "iata": "AWN",
      "name": "Alton Downs Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.5333003998,
      "lon": 139.266998291,
      "tz": "Australia\/Adelaide"
  },
  "YADY": {
      "icao": "YADY",
      "iata": "",
      "name": "Adaminaby Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.9832992554,
      "lon": 148.8000030518,
      "tz": "Australia\/Sydney"
  },
  "YAGD": {
      "icao": "YAGD",
      "iata": "AUD",
      "name": "Augustus Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 46,
      "lat": -18.5149993896,
      "lon": 139.8780059814,
      "tz": "Australia\/Brisbane"
  },
  "YAHD": {
      "icao": "YAHD",
      "iata": "",
      "name": "Ashburton Downs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.3833007813,
      "lon": 117.0329971313,
      "tz": "Australia\/Perth"
  },
  "YAKG": {
      "icao": "YAKG",
      "iata": "",
      "name": "Arckaringa Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9466991425,
      "lon": 134.7449951172,
      "tz": "Australia\/Adelaide"
  },
  "YALA": {
      "icao": "YALA",
      "iata": "MRP",
      "name": "Marla Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 328,
      "lat": -27.3332996368,
      "lon": 133.6269989014,
      "tz": "Australia\/Adelaide"
  },
  "YALC": {
      "icao": "YALC",
      "iata": "",
      "name": "Alcoota Station Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.8332996368,
      "lon": 134.4499969482,
      "tz": "Australia\/Darwin"
  },
  "YALG": {
      "icao": "YALG",
      "iata": "",
      "name": "Adels Grove Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.7000007629,
      "lon": 138.5330047607,
      "tz": "Australia\/Brisbane"
  },
  "YALH": {
      "icao": "YALH",
      "iata": "",
      "name": "Albilbah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.7000007629,
      "lon": 144.2830047607,
      "tz": "Australia\/Brisbane"
  },
  "YALM": {
      "icao": "YALM",
      "iata": "",
      "name": "Allambie Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2667007446,
      "lon": 134.3999938965,
      "tz": "Australia\/Darwin"
  },
  "YALN": {
      "icao": "YALN",
      "iata": "",
      "name": "Allandy Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.3833007813,
      "lon": 142.6000061035,
      "tz": "Australia\/Sydney"
  },
  "YALX": {
      "icao": "YALX",
      "iata": "AXL",
      "name": "Alexandria Homestead Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -19.0601997375,
      "lon": 136.7100067139,
      "tz": "Australia\/Darwin"
  },
  "YALY": {
      "icao": "YALY",
      "iata": "",
      "name": "Alderley Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.4832992554,
      "lon": 139.6329956055,
      "tz": "Australia\/Brisbane"
  },
  "YAMB": {
      "icao": "YAMB",
      "iata": "",
      "name": "RAAF Base Amberley",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 91,
      "lat": -27.6406002045,
      "lon": 152.7120056152,
      "tz": "Australia\/Brisbane"
  },
  "YAMC": {
      "icao": "YAMC",
      "iata": "AXC",
      "name": "Aramac Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 232,
      "lat": -22.9666996002,
      "lon": 145.2420043945,
      "tz": "Australia\/Brisbane"
  },
  "YAMJ": {
      "icao": "YAMJ",
      "iata": "",
      "name": "Ampilatwatja Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.6550006866,
      "lon": 135.2299957275,
      "tz": "Australia\/Darwin"
  },
  "YAMK": {
      "icao": "YAMK",
      "iata": "ADO",
      "name": "Andamooka Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 76,
      "lat": -30.4382991791,
      "lon": 137.1369934082,
      "tz": "Australia\/Adelaide"
  },
  "YAML": {
      "icao": "YAML",
      "iata": "",
      "name": "Armraynald Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.9666996002,
      "lon": 139.766998291,
      "tz": "Australia\/Brisbane"
  },
  "YAMM": {
      "icao": "YAMM",
      "iata": "AMX",
      "name": "Ammaroo Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.7383003235,
      "lon": 135.2420043945,
      "tz": "Australia\/Darwin"
  },
  "YAMT": {
      "icao": "YAMT",
      "iata": "AMT",
      "name": "Amata Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 695,
      "lat": -26.1082992554,
      "lon": 131.2070007324,
      "tz": "Australia\/Adelaide"
  },
  "YAND": {
      "icao": "YAND",
      "iata": "",
      "name": "Answer Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.6667003632,
      "lon": 140.983001709,
      "tz": "Australia\/Brisbane"
  },
  "YANG": {
      "icao": "YANG",
      "iata": "WLP",
      "name": "West Angelas Airport",
      "city": "",
      "state": "",
      "country": "AU",
      "elevation": 2340,
      "lat": -23.1355555556,
      "lon": 118.706975,
      "tz": "Australia\/Perth"
  },
  "YANJ": {
      "icao": "YANJ",
      "iata": "",
      "name": "Angatja Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.1000003815,
      "lon": 130.3999938965,
      "tz": "Australia\/Adelaide"
  },
  "YANK": {
      "icao": "YANK",
      "iata": "",
      "name": "Anna Creek Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.8966999054,
      "lon": 136.1699981689,
      "tz": "Australia\/Adelaide"
  },
  "YANL": {
      "icao": "YANL",
      "iata": "AYL",
      "name": "Anthony Lagoon Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -18.018079031,
      "lon": 135.535068512,
      "tz": "Australia\/Darwin"
  },
  "YANN": {
      "icao": "YANN",
      "iata": "",
      "name": "Anningie Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.8316993713,
      "lon": 133.125,
      "tz": "Australia\/Darwin"
  },
  "YANW": {
      "icao": "YANW",
      "iata": "",
      "name": "Annitowa Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.2000007629,
      "lon": 136.4479980469,
      "tz": "Australia\/Darwin"
  },
  "YAOR": {
      "icao": "YAOR",
      "iata": "",
      "name": "Ardmore Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.6499996185,
      "lon": 139.1829986572,
      "tz": "Australia\/Brisbane"
  },
  "YAPA": {
      "icao": "YAPA",
      "iata": "",
      "name": "Anna Plains Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -19.2667007446,
      "lon": 121.516998291,
      "tz": "Australia\/Perth"
  },
  "YAPH": {
      "icao": "YAPH",
      "iata": "ABH",
      "name": "Alpha Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1255,
      "lat": -23.6460990906,
      "lon": 146.5839996338,
      "tz": "Australia\/Brisbane"
  },
  "YAPO": {
      "icao": "YAPO",
      "iata": "",
      "name": "Apollo Bay Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.7832984924,
      "lon": 143.6329956055,
      "tz": "Australia\/Melbourne"
  },
  "YARA": {
      "icao": "YARA",
      "iata": "ARY",
      "name": "Ararat Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1008,
      "lat": -37.3093986511,
      "lon": 142.9889984131,
      "tz": "Australia\/Melbourne"
  },
  "YARD": {
      "icao": "YARD",
      "iata": "",
      "name": "Argadargada Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.676700592,
      "lon": 136.6779937744,
      "tz": "Australia\/Darwin"
  },
  "YARG": {
      "icao": "YARG",
      "iata": "GYL",
      "name": "Argyle Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 522,
      "lat": -16.6368999481,
      "lon": 128.4510040283,
      "tz": "Australia\/Perth"
  },
  "YARI": {
      "icao": "YARI",
      "iata": "",
      "name": "Arizona 1 Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.6667003632,
      "lon": 141.5670013428,
      "tz": "Australia\/Brisbane"
  },
  "YARK": {
      "icao": "YARK",
      "iata": "",
      "name": "Arkaroola Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.4067001343,
      "lon": 139.345993042,
      "tz": "Australia\/Adelaide"
  },
  "YARL": {
      "icao": "YARL",
      "iata": "",
      "name": "Ardlethan Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.3666992188,
      "lon": 146.8829956055,
      "tz": "Australia\/Sydney"
  },
  "YARM": {
      "icao": "YARM",
      "iata": "ARM",
      "name": "Armidale Airport",
      "city": "Armidale",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3556,
      "lat": -30.5280990601,
      "lon": 151.617004395,
      "tz": "Australia\/Sydney"
  },
  "YARN": {
      "icao": "YARN",
      "iata": "",
      "name": "Areyonga Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.0666999817,
      "lon": 132.266998291,
      "tz": "Australia\/Darwin"
  },
  "YARP": {
      "icao": "YARP",
      "iata": "",
      "name": "Arapunya Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.3166999817,
      "lon": 135.6999969482,
      "tz": "Australia\/Darwin"
  },
  "YARS": {
      "icao": "YARS",
      "iata": "",
      "name": "Ardrossan Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 92,
      "lat": -34.4416999817,
      "lon": 137.8829956055,
      "tz": "Australia\/Adelaide"
  },
  "YARY": {
      "icao": "YARY",
      "iata": "AAB",
      "name": "Arrabury Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 102,
      "lat": -26.7000007629,
      "lon": 141.0500030518,
      "tz": "Australia\/Brisbane"
  },
  "YASF": {
      "icao": "YASF",
      "iata": "",
      "name": "Ashford Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.3166999817,
      "lon": 151.0500030518,
      "tz": "Australia\/Sydney"
  },
  "YASS": {
      "icao": "YASS",
      "iata": "",
      "name": "Bakblok Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1624,
      "lat": -34.8866996765,
      "lon": 149.0149993896,
      "tz": "Australia\/Sydney"
  },
  "YATL": {
      "icao": "YATL",
      "iata": "",
      "name": "Atula Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2833003998,
      "lon": 136.3670043945,
      "tz": "Australia\/Darwin"
  },
  "YATN": {
      "icao": "YATN",
      "iata": "",
      "name": "Atherton Airport",
      "city": "Atherton",
      "state": "Queensland",
      "country": "AU",
      "elevation": 2450,
      "lat": -17.2616996765,
      "lon": 145.51499939,
      "tz": "Australia\/Brisbane"
  },
  "YATR": {
      "icao": "YATR",
      "iata": "",
      "name": "Amphitheatre Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.1833000183,
      "lon": 143.3999938965,
      "tz": "Australia\/Melbourne"
  },
  "YATY": {
      "icao": "YATY",
      "iata": "",
      "name": "Atley Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.2166996002,
      "lon": 119.25,
      "tz": "Australia\/Perth"
  },
  "YAUA": {
      "icao": "YAUA",
      "iata": "",
      "name": "Augathella Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.7549991608,
      "lon": 146.5870056152,
      "tz": "Australia\/Brisbane"
  },
  "YAUG": {
      "icao": "YAUG",
      "iata": "",
      "name": "Augusta Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.3666992188,
      "lon": 115.1500015259,
      "tz": "Australia\/Perth"
  },
  "YAUR": {
      "icao": "YAUR",
      "iata": "AUU",
      "name": "Aurukun Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 31,
      "lat": -13.3538999557,
      "lon": 141.720993042,
      "tz": "Australia\/Brisbane"
  },
  "YAUS": {
      "icao": "YAUS",
      "iata": "AWP",
      "name": "Austral Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.5,
      "lon": 137.75,
      "tz": "Australia\/Darwin"
  },
  "YAUV": {
      "icao": "YAUV",
      "iata": "AVG",
      "name": "Auvergne Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -15.6999998093,
      "lon": 130,
      "tz": "Australia\/Darwin"
  },
  "YAVD": {
      "icao": "YAVD",
      "iata": "",
      "name": "Avon Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.0333003998,
      "lon": 137.516998291,
      "tz": "Australia\/Darwin"
  },
  "YAVM": {
      "icao": "YAVM",
      "iata": "",
      "name": "Avonmore Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.5666999817,
      "lon": 144.5500030518,
      "tz": "Australia\/Melbourne"
  },
  "YAWT": {
      "icao": "YAWT",
      "iata": "",
      "name": "Agnes Water Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2000007629,
      "lon": 151.8970031738,
      "tz": "Australia\/Brisbane"
  },
  "YAYE": {
      "icao": "YAYE",
      "iata": "AYQ",
      "name": "Ayers Rock Connellan Airport",
      "city": "Ayers Rock",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 1626,
      "lat": -25.1861000061,
      "lon": 130.9759979248,
      "tz": "Australia\/Darwin"
  },
  "YAYR": {
      "icao": "YAYR",
      "iata": "AYR",
      "name": "Ayr Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 41,
      "lat": -19.584400177,
      "lon": 147.328994751,
      "tz": "Australia\/Brisbane"
  },
  "YBAB": {
      "icao": "YBAB",
      "iata": "",
      "name": "Baralaba",
      "city": "Baralaba",
      "state": "Queensland",
      "country": "AU",
      "elevation": 340,
      "lat": -24.1866666667,
      "lon": 149.845,
      "tz": "Australia\/Brisbane"
  },
  "YBAD": {
      "icao": "YBAD",
      "iata": "",
      "name": "Baradine Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9549999237,
      "lon": 149.0919952393,
      "tz": "Australia\/Sydney"
  },
  "YBAF": {
      "icao": "YBAF",
      "iata": "ACF",
      "name": "Brisbane Archerfield Airport",
      "city": "Brisbane",
      "state": "Queensland",
      "country": "AU",
      "elevation": 63,
      "lat": -27.5702991486,
      "lon": 153.007995605,
      "tz": "Australia\/Brisbane"
  },
  "YBAH": {
      "icao": "YBAH",
      "iata": "",
      "name": "Bauhinia Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.1333007813,
      "lon": 135.4669952393,
      "tz": "Australia\/Darwin"
  },
  "YBAL": {
      "icao": "YBAL",
      "iata": "",
      "name": "Balladonia Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.3499984741,
      "lon": 123.6179962158,
      "tz": "Australia\/Perth"
  },
  "YBAM": {
      "icao": "YBAM",
      "iata": "ABM",
      "name": "Bamaga Injinoo Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 34,
      "lat": -10.950799942,
      "lon": 142.4589996338,
      "tz": "Australia\/Brisbane"
  },
  "YBAN": {
      "icao": "YBAN",
      "iata": "",
      "name": "Mount Barnet Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.7371997833,
      "lon": 125.908996582,
      "tz": "Australia\/Perth"
  },
  "YBAO": {
      "icao": "YBAO",
      "iata": "",
      "name": "Braidwood Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.4500007629,
      "lon": 149.8500061035,
      "tz": "Australia\/Sydney"
  },
  "YBAR": {
      "icao": "YBAR",
      "iata": "BCI",
      "name": "Barcaldine Airport",
      "city": "Barcaldine",
      "state": "Queensland",
      "country": "AU",
      "elevation": 878,
      "lat": -23.5652999878,
      "lon": 145.307006836,
      "tz": "Australia\/Brisbane"
  },
  "YBAS": {
      "icao": "YBAS",
      "iata": "ASP",
      "name": "Alice Springs Airport",
      "city": "Alice Springs",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 1789,
      "lat": -23.8066997528,
      "lon": 133.9019927979,
      "tz": "Australia\/Darwin"
  },
  "YBAU": {
      "icao": "YBAU",
      "iata": "BDD",
      "name": "Badu Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 14,
      "lat": -10.1499996185,
      "lon": 142.1734,
      "tz": "Australia\/Brisbane"
  },
  "YBAW": {
      "icao": "YBAW",
      "iata": "BKP",
      "name": "Barkly Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 810,
      "lat": -20.4958333333,
      "lon": 138.474722222,
      "tz": "Australia\/Brisbane"
  },
  "YBBA": {
      "icao": "YBBA",
      "iata": "",
      "name": "Barraba Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.3833007813,
      "lon": 150.6000061035,
      "tz": "Australia\/Sydney"
  },
  "YBBC": {
      "icao": "YBBC",
      "iata": "",
      "name": "Bamboo Creek Airport",
      "city": "Bamboo Creek Gold Mine",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.9445991516,
      "lon": 120.1650009155,
      "tz": "Australia\/Perth"
  },
  "YBBE": {
      "icao": "YBBE",
      "iata": "BBE",
      "name": "Big Bell Airport",
      "city": "Big Bell",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.3285999298,
      "lon": 117.672996521,
      "tz": "Australia\/Perth"
  },
  "YBBL": {
      "icao": "YBBL",
      "iata": "",
      "name": "Billabalong Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.4167003632,
      "lon": 115.8330001831,
      "tz": "Australia\/Perth"
  },
  "YBBN": {
      "icao": "YBBN",
      "iata": "BNE",
      "name": "Brisbane International Airport",
      "city": "Brisbane",
      "state": "Queensland",
      "country": "AU",
      "elevation": 13,
      "lat": -27.3841991425,
      "lon": 153.1170043945,
      "tz": "Australia\/Brisbane"
  },
  "YBBO": {
      "icao": "YBBO",
      "iata": "",
      "name": "Bon Bon Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.4067001343,
      "lon": 135.4799957275,
      "tz": "Australia\/Adelaide"
  },
  "YBBT": {
      "icao": "YBBT",
      "iata": "",
      "name": "Boort Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 90,
      "lat": -36.1366996765,
      "lon": 143.7270050049,
      "tz": "Australia\/Melbourne"
  },
  "YBCB": {
      "icao": "YBCB",
      "iata": "",
      "name": "Bencubbin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.8332996368,
      "lon": 117.8669967651,
      "tz": "Australia\/Perth"
  },
  "YBCG": {
      "icao": "YBCG",
      "iata": "OOL",
      "name": "Gold Coast Airport",
      "city": "Gold Coast",
      "state": "Queensland",
      "country": "AU",
      "elevation": 21,
      "lat": -28.1644001007,
      "lon": 153.505004883,
      "tz": "Australia\/Brisbane"
  },
  "YBCH": {
      "icao": "YBCH",
      "iata": "",
      "name": "Beechworth Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 2000,
      "lat": -36.3933333333,
      "lon": 146.696388889,
      "tz": "Australia\/Melbourne"
  },
  "YBCK": {
      "icao": "YBCK",
      "iata": "BKQ",
      "name": "Blackall Airport",
      "city": "Blackall",
      "state": "Queensland",
      "country": "AU",
      "elevation": 928,
      "lat": -24.4277992249,
      "lon": 145.429000854,
      "tz": "Australia\/Brisbane"
  },
  "YBCL": {
      "icao": "YBCL",
      "iata": "",
      "name": "Boolcarrol Station Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.0499992371,
      "lon": 149.4170074463,
      "tz": "Australia\/Sydney"
  },
  "YBCM": {
      "icao": "YBCM",
      "iata": "",
      "name": "Coominya",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 340,
      "lat": -27.3907447666,
      "lon": 152.466545105,
      "tz": "Australia\/Brisbane"
  },
  "YBCR": {
      "icao": "YBCR",
      "iata": "",
      "name": "Batchelor Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -13.0666999817,
      "lon": 131.0330047607,
      "tz": "Australia\/Darwin"
  },
  "YBCS": {
      "icao": "YBCS",
      "iata": "CNS",
      "name": "Cairns International Airport",
      "city": "Cairns",
      "state": "Queensland",
      "country": "AU",
      "elevation": 10,
      "lat": -16.885799408,
      "lon": 145.755004883,
      "tz": "Australia\/Brisbane"
  },
  "YBCV": {
      "icao": "YBCV",
      "iata": "CTL",
      "name": "Charleville Airport",
      "city": "Charleville",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1003,
      "lat": -26.4132995605,
      "lon": 146.261993408,
      "tz": "Australia\/Brisbane"
  },
  "YBDF": {
      "icao": "YBDF",
      "iata": "BDW",
      "name": "Bedford Downs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1750,
      "lat": -17.286699295,
      "lon": 127.462997437,
      "tz": "Australia\/Perth"
  },
  "YBDG": {
      "icao": "YBDG",
      "iata": "BXG",
      "name": "Bendigo Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 705,
      "lat": -36.7393989563,
      "lon": 144.330001831,
      "tz": "Australia\/Melbourne"
  },
  "YBDP": {
      "icao": "YBDP",
      "iata": "",
      "name": "Bridport Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.0167007446,
      "lon": 147.4170074463,
      "tz": "Australia\/Hobart"
  },
  "YBDS": {
      "icao": "YBDS",
      "iata": "",
      "name": "Birthday Siding Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.2833003998,
      "lon": 134.516998291,
      "tz": "Australia\/Adelaide"
  },
  "YBDU": {
      "icao": "YBDU",
      "iata": "",
      "name": "Birrindudu Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -18.3833007813,
      "lon": 129.483001709,
      "tz": "Australia\/Darwin"
  },
  "YBDV": {
      "icao": "YBDV",
      "iata": "BVI",
      "name": "Birdsville Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 159,
      "lat": -25.8974990845,
      "lon": 139.3480072021,
      "tz": "Australia\/Brisbane"
  },
  "YBDX": {
      "icao": "YBDX",
      "iata": "",
      "name": "Barradale Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.8633003235,
      "lon": 114.9629974365,
      "tz": "Australia\/Perth"
  },
  "YBEB": {
      "icao": "YBEB",
      "iata": "BXF",
      "name": "Pumululu National Park",
      "city": "Bellburn",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 810,
      "lat": -17.545,
      "lon": 128.305,
      "tz": "Australia\/Perth"
  },
  "YBEC": {
      "icao": "YBEC",
      "iata": "",
      "name": "Beacon Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.4832992554,
      "lon": 117.8830032349,
      "tz": "Australia\/Perth"
  },
  "YBEE": {
      "icao": "YBEE",
      "iata": "",
      "name": "Beverley Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 116,
      "lat": -30.1867008209,
      "lon": 139.5579986572,
      "tz": "Australia\/Adelaide"
  },
  "YBEF": {
      "icao": "YBEF",
      "iata": "",
      "name": "Beaufort Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.4500007629,
      "lon": 143.25,
      "tz": "Australia\/Melbourne"
  },
  "YBEL": {
      "icao": "YBEL",
      "iata": "",
      "name": "Bothwell Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.3666992188,
      "lon": 147.0330047607,
      "tz": "Australia\/Hobart"
  },
  "YBEO": {
      "icao": "YBEO",
      "iata": "BTX",
      "name": "Betoota Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 91,
      "lat": -25.6417007446,
      "lon": 140.7830047607,
      "tz": "Australia\/Brisbane"
  },
  "YBEU": {
      "icao": "YBEU",
      "iata": "",
      "name": "Beulah 1 Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.9500007629,
      "lon": 142.4170074463,
      "tz": "Australia\/Melbourne"
  },
  "YBEV": {
      "icao": "YBEV",
      "iata": "",
      "name": "Beverley Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.1333007813,
      "lon": 116.9499969482,
      "tz": "Australia\/Perth"
  },
  "YBEW": {
      "icao": "YBEW",
      "iata": "",
      "name": "Beechworth Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.9167003632,
      "lon": 144.6329956055,
      "tz": "Australia\/Sydney"
  },
  "YBFR": {
      "icao": "YBFR",
      "iata": "",
      "name": "Balfour Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.2590332628,
      "lon": 144.886064529,
      "tz": "Australia\/Hobart"
  },
  "YBFT": {
      "icao": "YBFT",
      "iata": "",
      "name": "Beaufort",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1300,
      "lat": -37.495,
      "lon": 143.43,
      "tz": "Australia\/Melbourne"
  },
  "YBGB": {
      "icao": "YBGB",
      "iata": "",
      "name": "Beagle Bay Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.9832992554,
      "lon": 122.6500015259,
      "tz": "Australia\/Perth"
  },
  "YBGD": {
      "icao": "YBGD",
      "iata": "OCM",
      "name": "Boolgeeda Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1871,
      "lat": -22.54,
      "lon": 117.275,
      "tz": "Australia\/Perth"
  },
  "YBGI": {
      "icao": "YBGI",
      "iata": "",
      "name": "Balgair Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.0666999817,
      "lon": 125.6669998169,
      "tz": "Australia\/Perth"
  },
  "YBGO": {
      "icao": "YBGO",
      "iata": "BQW",
      "name": "Balgo Hill Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 439,
      "lat": -20.1483001709,
      "lon": 127.9729995728,
      "tz": "Australia\/Perth"
  },
  "YBGR": {
      "icao": "YBGR",
      "iata": "",
      "name": "Bridgewater Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 91,
      "lat": -36.6166992188,
      "lon": 143.9499969482,
      "tz": "Australia\/Melbourne"
  },
  "YBGT": {
      "icao": "YBGT",
      "iata": "",
      "name": "Budgerygar Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3700008392,
      "lon": 143.7729949951,
      "tz": "Australia\/Brisbane"
  },
  "YBGY": {
      "icao": "YBGY",
      "iata": "",
      "name": "Biniguy Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.5049991608,
      "lon": 150.1920013428,
      "tz": "Australia\/Sydney"
  },
  "YBHB": {
      "icao": "YBHB",
      "iata": "",
      "name": "Bathurst Harbour Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -43.4207000732,
      "lon": 146.1560058594,
      "tz": "Australia\/Hobart"
  },
  "YBHI": {
      "icao": "YBHI",
      "iata": "BHQ",
      "name": "Broken Hill Airport",
      "city": "Broken Hill",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 958,
      "lat": -32.0013999939,
      "lon": 141.472000122,
      "tz": "Australia\/Broken_Hill"
  },
  "YBHK": {
      "icao": "YBHK",
      "iata": "",
      "name": "Bushy Park Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.2667007446,
      "lon": 139.7169952393,
      "tz": "Australia\/Brisbane"
  },
  "YBHM": {
      "icao": "YBHM",
      "iata": "HTI",
      "name": "Hamilton Island Airport",
      "city": "Hamilton Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -20.3581008911,
      "lon": 148.95199585,
      "tz": "Australia\/Lindeman"
  },
  "YBIA": {
      "icao": "YBIA",
      "iata": "",
      "name": "Bingara Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.7999992371,
      "lon": 150.5330047607,
      "tz": "Australia\/Sydney"
  },
  "YBID": {
      "icao": "YBID",
      "iata": "",
      "name": "Binda",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2450,
      "lat": -34.3033333333,
      "lon": 149.36,
      "tz": "Australia\/Sydney"
  },
  "YBIE": {
      "icao": "YBIE",
      "iata": "BEU",
      "name": "Bedourie Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 300,
      "lat": -24.3460998535,
      "lon": 139.4600067139,
      "tz": "Australia\/Brisbane"
  },
  "YBIK": {
      "icao": "YBIK",
      "iata": "",
      "name": "Bindook Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.1666984558,
      "lon": 150.1000061035,
      "tz": "Australia\/Sydney"
  },
  "YBIL": {
      "icao": "YBIL",
      "iata": "BIW",
      "name": "Billiluna Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1000,
      "lat": -19.5666999817,
      "lon": 127.666999817,
      "tz": "Australia\/Perth"
  },
  "YBIN": {
      "icao": "YBIN",
      "iata": "",
      "name": "Biggenden Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5249996185,
      "lon": 152.0500030518,
      "tz": "Australia\/Brisbane"
  },
  "YBIR": {
      "icao": "YBIR",
      "iata": "",
      "name": "Birchip Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 340,
      "lat": -35.9996986389,
      "lon": 142.9170074463,
      "tz": "Australia\/Melbourne"
  },
  "YBIU": {
      "icao": "YBIU",
      "iata": "",
      "name": "Ballidu Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.5932998657,
      "lon": 116.7799987793,
      "tz": "Australia\/Perth"
  },
  "YBIZ": {
      "icao": "YBIZ",
      "iata": "BZP",
      "name": "Bizant Airport",
      "city": "Lakefield National Park",
      "state": "Queensland",
      "country": "AU",
      "elevation": 65,
      "lat": -14.7402777778,
      "lon": 144.119444444,
      "tz": "Australia\/Brisbane"
  },
  "YBJW": {
      "icao": "YBJW",
      "iata": "",
      "name": "Banjawarn Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.6667003632,
      "lon": 121.5999984741,
      "tz": "Australia\/Perth"
  },
  "YBKE": {
      "icao": "YBKE",
      "iata": "BRK",
      "name": "Bourke Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 352,
      "lat": -30.0391998291,
      "lon": 145.9519958496,
      "tz": "Australia\/Sydney"
  },
  "YBKS": {
      "icao": "YBKS",
      "iata": "",
      "name": "Barkly Wayside Inn Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -19.7091999054,
      "lon": 135.8190002441,
      "tz": "Australia\/Darwin"
  },
  "YBKT": {
      "icao": "YBKT",
      "iata": "BUC",
      "name": "Burketown Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 21,
      "lat": -17.7486000061,
      "lon": 139.533996582,
      "tz": "Australia\/Brisbane"
  },
  "YBLA": {
      "icao": "YBLA",
      "iata": "BLN",
      "name": "Benalla Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 569,
      "lat": -36.5518989563,
      "lon": 146.0070037842,
      "tz": "Australia\/Melbourne"
  },
  "YBLB": {
      "icao": "YBLB",
      "iata": "",
      "name": "Billabong Road House Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.8166999817,
      "lon": 114.6169967651,
      "tz": "Australia\/Perth"
  },
  "YBLC": {
      "icao": "YBLC",
      "iata": "LCN",
      "name": "Balcanoona Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 144,
      "lat": -30.5349998474,
      "lon": 139.3370056152,
      "tz": "Australia\/Adelaide"
  },
  "YBLD": {
      "icao": "YBLD",
      "iata": "",
      "name": "Brooklands Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.8250007629,
      "lon": 116.7959976196,
      "tz": "Australia\/Perth"
  },
  "YBLG": {
      "icao": "YBLG",
      "iata": "",
      "name": "Bollards Lagoon Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.9832992554,
      "lon": 140.8500061035,
      "tz": "Australia\/Adelaide"
  },
  "YBLH": {
      "icao": "YBLH",
      "iata": "",
      "name": "Bellalie Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.0333003998,
      "lon": 142.9499969482,
      "tz": "Australia\/Brisbane"
  },
  "YBLL": {
      "icao": "YBLL",
      "iata": "BLS",
      "name": "Bollon Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 182,
      "lat": -28.0583000183,
      "lon": 147.483001709,
      "tz": "Australia\/Brisbane"
  },
  "YBLM": {
      "icao": "YBLM",
      "iata": "",
      "name": "Blinman Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 1991,
      "lat": -31.1186008453,
      "lon": 138.7259979248,
      "tz": "Australia\/Adelaide"
  },
  "YBLN": {
      "icao": "YBLN",
      "iata": "BQB",
      "name": "Busselton Regional Airport",
      "city": "Busselton",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 55,
      "lat": -33.6884231567,
      "lon": 115.401596069,
      "tz": "Australia\/Perth"
  },
  "YBLT": {
      "icao": "YBLT",
      "iata": "",
      "name": "Ballarat Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1433,
      "lat": -37.5116996765,
      "lon": 143.7910003662,
      "tz": "Australia\/Melbourne"
  },
  "YBLU": {
      "icao": "YBLU",
      "iata": "",
      "name": "Bellevue Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.6149997711,
      "lon": 120.6119995117,
      "tz": "Australia\/Perth"
  },
  "YBMA": {
      "icao": "YBMA",
      "iata": "ISA",
      "name": "Mount Isa Airport",
      "city": "Mount Isa",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1121,
      "lat": -20.6639003754,
      "lon": 139.488998413,
      "tz": "Australia\/Brisbane"
  },
  "YBMC": {
      "icao": "YBMC",
      "iata": "MCY",
      "name": "Sunshine Coast Airport",
      "city": "Maroochydore",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -26.6033000946,
      "lon": 153.091003418,
      "tz": "Australia\/Brisbane"
  },
  "YBMD": {
      "icao": "YBMD",
      "iata": "",
      "name": "Bloomfield River Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.8999996185,
      "lon": 145.3329925537,
      "tz": "Australia\/Brisbane"
  },
  "YBMI": {
      "icao": "YBMI",
      "iata": "",
      "name": "Boomi Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -28.7332992554,
      "lon": 149.5829925537,
      "tz": "Australia\/Sydney"
  },
  "YBMK": {
      "icao": "YBMK",
      "iata": "MKY",
      "name": "Mackay Airport",
      "city": "Mackay",
      "state": "Queensland",
      "country": "AU",
      "elevation": 19,
      "lat": -21.1716995239,
      "lon": 149.179992676,
      "tz": "Australia\/Brisbane"
  },
  "YBMM": {
      "icao": "YBMM",
      "iata": "",
      "name": "Bamawm Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.25,
      "lon": 144.6329956055,
      "tz": "Australia\/Melbourne"
  },
  "YBMO": {
      "icao": "YBMO",
      "iata": "",
      "name": "Bombala Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2415,
      "lat": -36.9049987793,
      "lon": 149.1820068359,
      "tz": "Australia\/Sydney"
  },
  "YBMR": {
      "icao": "YBMR",
      "iata": "",
      "name": "Barmera Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.2566986084,
      "lon": 140.4579925537,
      "tz": "Australia\/Adelaide"
  },
  "YBMY": {
      "icao": "YBMY",
      "iata": "",
      "name": "Bamyili Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 215,
      "lat": -14.5200004578,
      "lon": 132.8829956055,
      "tz": "Australia\/Darwin"
  },
  "YBNA": {
      "icao": "YBNA",
      "iata": "BNK",
      "name": "Ballina Byron Gateway Airport",
      "city": "Ballina",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 7,
      "lat": -28.8339004517,
      "lon": 153.56199646,
      "tz": "Australia\/Sydney"
  },
  "YBNC": {
      "icao": "YBNC",
      "iata": "",
      "name": "Bannockburn Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.7999992371,
      "lon": 145.0829925537,
      "tz": "Australia\/Brisbane"
  },
  "YBNM": {
      "icao": "YBNM",
      "iata": "",
      "name": "Benmara Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.9367008209,
      "lon": 136.8999938965,
      "tz": "Australia\/Darwin"
  },
  "YBNS": {
      "icao": "YBNS",
      "iata": "BSJ",
      "name": "Bairnsdale Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 165,
      "lat": -37.8875007629,
      "lon": 147.5679931641,
      "tz": "Australia\/Melbourne"
  },
  "YBNY": {
      "icao": "YBNY",
      "iata": "",
      "name": "Brunchilly Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -18.8700008392,
      "lon": 134.4620056152,
      "tz": "Australia\/Darwin"
  },
  "YBOA": {
      "icao": "YBOA",
      "iata": "",
      "name": "Boonah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.0167007446,
      "lon": 152.6820068359,
      "tz": "Australia\/Brisbane"
  },
  "YBOC": {
      "icao": "YBOC",
      "iata": "",
      "name": "Booleroo Centre Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 418,
      "lat": -32.9049987793,
      "lon": 138.3600006104,
      "tz": "Australia\/Adelaide"
  },
  "YBOD": {
      "icao": "YBOD",
      "iata": "",
      "name": "Bodalla Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.2999992371,
      "lon": 143.3829956055,
      "tz": "Australia\/Brisbane"
  },
  "YBOG": {
      "icao": "YBOG",
      "iata": "",
      "name": "Boggabri Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.7166996002,
      "lon": 150.0500030518,
      "tz": "Australia\/Sydney"
  },
  "YBOI": {
      "icao": "YBOI",
      "iata": "GIC",
      "name": "Boigu Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 23,
      "lat": -9.2327804565,
      "lon": 142.218002319,
      "tz": "Australia\/Brisbane"
  },
  "YBOK": {
      "icao": "YBOK",
      "iata": "OKY",
      "name": "Oakey Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1335,
      "lat": -27.4113998413,
      "lon": 151.7350006104,
      "tz": "Australia\/Brisbane"
  },
  "YBOM": {
      "icao": "YBOM",
      "iata": "",
      "name": "Bombala Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -36.9166984558,
      "lon": 149.1670074463,
      "tz": "Australia\/Sydney"
  },
  "YBOO": {
      "icao": "YBOO",
      "iata": "",
      "name": "Catholic Agricultural College Bindoon Airstrip",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 935,
      "lat": -31.3402996063,
      "lon": 116.18800354,
      "tz": "Australia\/Perth"
  },
  "YBOP": {
      "icao": "YBOP",
      "iata": "",
      "name": "Boyup Brook Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.9000015259,
      "lon": 116.4670028687,
      "tz": "Australia\/Perth"
  },
  "YBOR": {
      "icao": "YBOR",
      "iata": "",
      "name": "Bordertown Airport",
      "city": "Bordertown",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 265,
      "lat": -36.2649993896,
      "lon": 140.712005615,
      "tz": "Australia\/Adelaide"
  },
  "YBOT": {
      "icao": "YBOT",
      "iata": "",
      "name": "Boatman Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.25,
      "lon": 146.9170074463,
      "tz": "Australia\/Brisbane"
  },
  "YBOU": {
      "icao": "YBOU",
      "iata": "BQL",
      "name": "Boulia Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 542,
      "lat": -22.9132995605,
      "lon": 139.8999938965,
      "tz": "Australia\/Brisbane"
  },
  "YBOV": {
      "icao": "YBOV",
      "iata": "",
      "name": "Border Village Airport",
      "city": "Border Village",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 308,
      "lat": -31.6394004822,
      "lon": 129.0119934082,
      "tz": "Australia\/Adelaide"
  },
  "YBOY": {
      "icao": "YBOY",
      "iata": "",
      "name": "Booylgoo Springs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.75,
      "lon": 119.9000015259,
      "tz": "Australia\/Perth"
  },
  "YBPI": {
      "icao": "YBPI",
      "iata": "BMP",
      "name": "Brampton Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 11,
      "lat": -20.8033008575,
      "lon": 149.2700042725,
      "tz": "Australia\/Brisbane"
  },
  "YBPN": {
      "icao": "YBPN",
      "iata": "PPP",
      "name": "Proserpine Whitsunday Coast Airport",
      "city": "Proserpine",
      "state": "Queensland",
      "country": "AU",
      "elevation": 82,
      "lat": -20.4950008392,
      "lon": 148.552001953,
      "tz": "Australia\/Brisbane"
  },
  "YBRA": {
      "icao": "YBRA",
      "iata": "",
      "name": "Benambra Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 2200,
      "lat": -36.9668665594,
      "lon": 147.699143887,
      "tz": "Australia\/Melbourne"
  },
  "YBRC": {
      "icao": "YBRC",
      "iata": "",
      "name": "Barrow Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.5333003998,
      "lon": 133.8829956055,
      "tz": "Australia\/Darwin"
  },
  "YBRJ": {
      "icao": "YBRJ",
      "iata": "",
      "name": "Burren Junction Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.1583003998,
      "lon": 148.9750061035,
      "tz": "Australia\/Sydney"
  },
  "YBRK": {
      "icao": "YBRK",
      "iata": "ROK",
      "name": "Rockhampton Airport",
      "city": "Rockhampton",
      "state": "Queensland",
      "country": "AU",
      "elevation": 34,
      "lat": -23.3819007874,
      "lon": 150.475006104,
      "tz": "Australia\/Brisbane"
  },
  "YBRL": {
      "icao": "YBRL",
      "iata": "BOX",
      "name": "Borroloola Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 55,
      "lat": -16.0753002167,
      "lon": 136.3020019531,
      "tz": "Australia\/Darwin"
  },
  "YBRM": {
      "icao": "YBRM",
      "iata": "BME",
      "name": "Broome International Airport",
      "city": "Broome",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 56,
      "lat": -17.9447002411,
      "lon": 122.2320022583,
      "tz": "Australia\/Perth"
  },
  "YBRN": {
      "icao": "YBRN",
      "iata": "BZD",
      "name": "Balranald Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 210,
      "lat": -34.6236000061,
      "lon": 143.5780029297,
      "tz": "Australia\/Sydney"
  },
  "YBRO": {
      "icao": "YBRO",
      "iata": "",
      "name": "Bruce Rock Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.8833007813,
      "lon": 118.1169967651,
      "tz": "Australia\/Perth"
  },
  "YBRS": {
      "icao": "YBRS",
      "iata": "",
      "name": "Barwon Heads Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 15,
      "lat": -38.258056,
      "lon": 144.4275,
      "tz": "Australia\/Melbourne"
  },
  "YBRU": {
      "icao": "YBRU",
      "iata": "BTD",
      "name": "Brunette Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 700,
      "lat": -18.6399993896,
      "lon": 135.93800354,
      "tz": "Australia\/Darwin"
  },
  "YBRW": {
      "icao": "YBRW",
      "iata": "BWQ",
      "name": "Brewarrina Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 414,
      "lat": -29.9738998413,
      "lon": 146.8170013428,
      "tz": "Australia\/Sydney"
  },
  "YBRY": {
      "icao": "YBRY",
      "iata": "BYP",
      "name": "Barimunya Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 2082,
      "lat": -22.6739006042,
      "lon": 119.1660003662,
      "tz": "Australia\/Perth"
  },
  "YBSG": {
      "icao": "YBSG",
      "iata": "",
      "name": "RAAF Scherger",
      "city": "Weipa",
      "state": "Queensland",
      "country": "AU",
      "elevation": 145,
      "lat": -12.6239004135,
      "lon": 142.0870056152,
      "tz": "Australia\/Brisbane"
  },
  "YBSP": {
      "icao": "YBSP",
      "iata": "",
      "name": "Bond Springs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.5149993896,
      "lon": 133.8430023193,
      "tz": "Australia\/Darwin"
  },
  "YBSR": {
      "icao": "YBSR",
      "iata": "",
      "name": "Blackstone Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.986700058,
      "lon": 128.2879943848,
      "tz": "Australia\/Perth"
  },
  "YBSS": {
      "icao": "YBSS",
      "iata": "",
      "name": "Bacchus Marsh Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 520,
      "lat": -37.7333333333,
      "lon": 144.421666667,
      "tz": "Australia\/Melbourne"
  },
  "YBTD": {
      "icao": "YBTD",
      "iata": "",
      "name": "Brighton Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.3500003815,
      "lon": 143.5330047607,
      "tz": "Australia\/Brisbane"
  },
  "YBTH": {
      "icao": "YBTH",
      "iata": "BHS",
      "name": "Bathurst Airport",
      "city": "Bathurst",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2435,
      "lat": -33.4094009399,
      "lon": 149.651992798,
      "tz": "Australia\/Sydney"
  },
  "YBTI": {
      "icao": "YBTI",
      "iata": "BRT",
      "name": "Bathurst Island Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 67,
      "lat": -11.769200325,
      "lon": 130.6199951172,
      "tz": "Australia\/Darwin"
  },
  "YBTK": {
      "icao": "YBTK",
      "iata": "",
      "name": "Bentinck Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 45,
      "lat": -17.0867023468,
      "lon": 139.5657806396,
      "tz": "Australia\/Brisbane"
  },
  "YBTL": {
      "icao": "YBTL",
      "iata": "TSV",
      "name": "Townsville Airport",
      "city": "Townsville",
      "state": "Queensland",
      "country": "AU",
      "elevation": 18,
      "lat": -19.2525005341,
      "lon": 146.7649993896,
      "tz": "Australia\/Brisbane"
  },
  "YBTN": {
      "icao": "YBTN",
      "iata": "",
      "name": "Barton Siding Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.5249996185,
      "lon": 132.6600036621,
      "tz": "Australia\/Adelaide"
  },
  "YBTR": {
      "icao": "YBTR",
      "iata": "BLT",
      "name": "Blackwater Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 657,
      "lat": -23.603099823,
      "lon": 148.8070068359,
      "tz": "Australia\/Brisbane"
  },
  "YBTS": {
      "icao": "YBTS",
      "iata": "",
      "name": "Battery Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.4333000183,
      "lon": 145.8500061035,
      "tz": "Australia\/Brisbane"
  },
  "YBTT": {
      "icao": "YBTT",
      "iata": "",
      "name": "Buttabone Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.3582992554,
      "lon": 147.6329956055,
      "tz": "Australia\/Sydney"
  },
  "YBTV": {
      "icao": "YBTV",
      "iata": "",
      "name": "Batavia Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -12.6667003632,
      "lon": 142.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YBUA": {
      "icao": "YBUA",
      "iata": "",
      "name": "Bundarra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.9666996002,
      "lon": 148.5330047607,
      "tz": "Australia\/Brisbane"
  },
  "YBUC": {
      "icao": "YBUC",
      "iata": "",
      "name": "Butch Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.8333015442,
      "lon": 115.0999984741,
      "tz": "Australia\/Perth"
  },
  "YBUD": {
      "icao": "YBUD",
      "iata": "BDB",
      "name": "Bundaberg Airport",
      "city": "Bundaberg",
      "state": "Queensland",
      "country": "AU",
      "elevation": 107,
      "lat": -24.9039001465,
      "lon": 152.319000244,
      "tz": "Australia\/Brisbane"
  },
  "YBUG": {
      "icao": "YBUG",
      "iata": "",
      "name": "Bulga Downs Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.5,
      "lon": 119.733001709,
      "tz": "Australia\/Perth"
  },
  "YBUI": {
      "icao": "YBUI",
      "iata": "",
      "name": "Burnie Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.0491981506,
      "lon": 145.8800048828,
      "tz": "Australia\/Hobart"
  },
  "YBUL": {
      "icao": "YBUL",
      "iata": "",
      "name": "Bulgunnia Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.1867008209,
      "lon": 134.9029998779,
      "tz": "Australia\/Adelaide"
  },
  "YBUN": {
      "icao": "YBUN",
      "iata": "BUY",
      "name": "Bunbury Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 53,
      "lat": -33.3782997131,
      "lon": 115.6770019531,
      "tz": "Australia\/Perth"
  },
  "YBUO": {
      "icao": "YBUO",
      "iata": "",
      "name": "Bulloo Downs Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.0167007446,
      "lon": 119.5670013428,
      "tz": "Australia\/Perth"
  },
  "YBUP": {
      "icao": "YBUP",
      "iata": "",
      "name": "Bunyip Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.0167007446,
      "lon": 145.75,
      "tz": "Australia\/Melbourne"
  },
  "YBUU": {
      "icao": "YBUU",
      "iata": "",
      "name": "Bungle Bungle Airport",
      "city": "Bungle Bungle",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -17.54529953,
      "lon": 128.3070068359,
      "tz": "Australia\/Perth"
  },
  "YBUX": {
      "icao": "YBUX",
      "iata": "",
      "name": "Bulleringa Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.6499996185,
      "lon": 143.8000030518,
      "tz": "Australia\/Brisbane"
  },
  "YBUY": {
      "icao": "YBUY",
      "iata": "",
      "name": "Bunyan Airfield",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2540,
      "lat": -36.1342008376,
      "lon": 149.131979942,
      "tz": "Australia\/Sydney"
  },
  "YBVA": {
      "icao": "YBVA",
      "iata": "",
      "name": "Balaklava Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.0843009949,
      "lon": 138.3339996338,
      "tz": "Australia\/Adelaide"
  },
  "YBVL": {
      "icao": "YBVL",
      "iata": "",
      "name": "Blackville Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.5832996368,
      "lon": 150.1829986572,
      "tz": "Australia\/Sydney"
  },
  "YBVY": {
      "icao": "YBVY",
      "iata": "",
      "name": "Bullo River Valley Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -15.4666996002,
      "lon": 129.766998291,
      "tz": "Australia\/Darwin"
  },
  "YBWG": {
      "icao": "YBWG",
      "iata": "",
      "name": "Bronzewing Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1645,
      "lat": -27.3656005859,
      "lon": 121.0360031128,
      "tz": "Australia\/Perth"
  },
  "YBWI": {
      "icao": "YBWI",
      "iata": "",
      "name": "Burdekin Falls Dam Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.6000003815,
      "lon": 147.0829925537,
      "tz": "Australia\/Brisbane"
  },
  "YBWM": {
      "icao": "YBWM",
      "iata": "BIP",
      "name": "Bulimba Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 470,
      "lat": -16.8808002472,
      "lon": 143.479003906,
      "tz": "Australia\/Brisbane"
  },
  "YBWN": {
      "icao": "YBWN",
      "iata": "ZBO",
      "name": "Bowen Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 8,
      "lat": -20.0182991028,
      "lon": 148.2149963379,
      "tz": "Australia\/Brisbane"
  },
  "YBWP": {
      "icao": "YBWP",
      "iata": "WEI",
      "name": "Weipa Airport",
      "city": "Weipa",
      "state": "Queensland",
      "country": "AU",
      "elevation": 63,
      "lat": -12.6786003113,
      "lon": 141.925003052,
      "tz": "Australia\/Brisbane"
  },
  "YBWR": {
      "icao": "YBWR",
      "iata": "",
      "name": "Bolwarra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.3999996185,
      "lon": 144.1829986572,
      "tz": "Australia\/Brisbane"
  },
  "YBWS": {
      "icao": "YBWS",
      "iata": "",
      "name": "Browse Is Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -14.111700058,
      "lon": 123.5469970703,
      "tz": "Australia\/Perth"
  },
  "YBWT": {
      "icao": "YBWT",
      "iata": "",
      "name": "Bowthorn Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.0832996368,
      "lon": 138.2749938965,
      "tz": "Australia\/Brisbane"
  },
  "YBWW": {
      "icao": "YBWW",
      "iata": "WTB",
      "name": "Toowoomba Wellcamp Airport",
      "city": "Wellcamp",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1509,
      "lat": -27.558333,
      "lon": 151.793333,
      "tz": "Australia\/Brisbane"
  },
  "YBWX": {
      "icao": "YBWX",
      "iata": "BWB",
      "name": "Barrow Island Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 26,
      "lat": -20.8644008636,
      "lon": 115.40599823,
      "tz": "Australia\/Perth"
  },
  "YBXH": {
      "icao": "YBXH",
      "iata": "",
      "name": "Branxholme Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.8672981262,
      "lon": 141.7019958496,
      "tz": "Australia\/Melbourne"
  },
  "YBYA": {
      "icao": "YBYA",
      "iata": "",
      "name": "Bryah Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5333003998,
      "lon": 118.733001709,
      "tz": "Australia\/Perth"
  },
  "YBYD": {
      "icao": "YBYD",
      "iata": "",
      "name": "Bonney Downs Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.2667007446,
      "lon": 119.8830032349,
      "tz": "Australia\/Perth"
  },
  "YBYI": {
      "icao": "YBYI",
      "iata": "",
      "name": "Bruny Island Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -43.2341995239,
      "lon": 147.3800048828,
      "tz": "Australia\/Hobart"
  },
  "YBYR": {
      "icao": "YBYR",
      "iata": "",
      "name": "Byrock Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.6667003632,
      "lon": 146.3999938965,
      "tz": "Australia\/Sydney"
  },
  "YBYS": {
      "icao": "YBYS",
      "iata": "BVZ",
      "name": "Beverley Springs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 385,
      "lat": -16.7332992554,
      "lon": 125.4329986572,
      "tz": "Australia\/Perth"
  },
  "YBYW": {
      "icao": "YBYW",
      "iata": "",
      "name": "Bayswater Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.4500007629,
      "lon": 145.5829925537,
      "tz": "Australia\/Brisbane"
  },
  "YBZA": {
      "icao": "YBZA",
      "iata": "",
      "name": "Breeza Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.3166999817,
      "lon": 150.516998291,
      "tz": "Australia\/Sydney"
  },
  "YCAB": {
      "icao": "YCAB",
      "iata": "",
      "name": "Caboolture Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 12,
      "lat": -27.0832996368,
      "lon": 152.983001709,
      "tz": "Australia\/Brisbane"
  },
  "YCAC": {
      "icao": "YCAC",
      "iata": "",
      "name": "Cattle Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.6166992188,
      "lon": 131.5500030518,
      "tz": "Australia\/Darwin"
  },
  "YCAE": {
      "icao": "YCAE",
      "iata": "",
      "name": "Campbell Town Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 710,
      "lat": -41.9672241211,
      "lon": 147.5305480957,
      "tz": "Australia\/Hobart"
  },
  "YCAG": {
      "icao": "YCAG",
      "iata": "CGV",
      "name": "Caiguna Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 87,
      "lat": -32.2649993896,
      "lon": 125.4929962158,
      "tz": "Australia\/Eucla"
  },
  "YCAH": {
      "icao": "YCAH",
      "iata": "CLH",
      "name": "Coolah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 504,
      "lat": -31.7733001709,
      "lon": 149.6100006104,
      "tz": "Australia\/Sydney"
  },
  "YCAI": {
      "icao": "YCAI",
      "iata": "",
      "name": "Callion Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.1166992188,
      "lon": 120.5830001831,
      "tz": "Australia\/Perth"
  },
  "YCAJ": {
      "icao": "YCAJ",
      "iata": "",
      "name": "Cadjebut Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.7000007629,
      "lon": 125.9000015259,
      "tz": "Australia\/Perth"
  },
  "YCAL": {
      "icao": "YCAL",
      "iata": "",
      "name": "Castlemaine Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.1333007813,
      "lon": 144.1670074463,
      "tz": "Australia\/Melbourne"
  },
  "YCAM": {
      "icao": "YCAM",
      "iata": "",
      "name": "Cannington Station Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.875,
      "lon": 140.8999938965,
      "tz": "Australia\/Brisbane"
  },
  "YCAN": {
      "icao": "YCAN",
      "iata": "",
      "name": "Cannon Hill Community Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.3667001724,
      "lon": 132.9499969482,
      "tz": "Australia\/Darwin"
  },
  "YCAR": {
      "icao": "YCAR",
      "iata": "CVQ",
      "name": "Carnarvon Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 13,
      "lat": -24.8805999756,
      "lon": 113.6719970703,
      "tz": "Australia\/Perth"
  },
  "YCAS": {
      "icao": "YCAS",
      "iata": "CSI",
      "name": "Casino Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 86,
      "lat": -28.8827991486,
      "lon": 153.0670013428,
      "tz": "Australia\/Sydney"
  },
  "YCBA": {
      "icao": "YCBA",
      "iata": "CAZ",
      "name": "Cobar Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 724,
      "lat": -31.5382995605,
      "lon": 145.7940063477,
      "tz": "Australia\/Sydney"
  },
  "YCBB": {
      "icao": "YCBB",
      "iata": "COJ",
      "name": "Coonabarabran Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2117,
      "lat": -31.3325004578,
      "lon": 149.266998291,
      "tz": "Australia\/Sydney"
  },
  "YCBE": {
      "icao": "YCBE",
      "iata": "CBY",
      "name": "Canobie Airport",
      "city": "Canobie",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.4794006348,
      "lon": 140.927001953,
      "tz": "Australia\/Brisbane"
  },
  "YCBG": {
      "icao": "YCBG",
      "iata": "",
      "name": "Hobart Cambridge Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 67,
      "lat": -42.8266983032,
      "lon": 147.4750061035,
      "tz": "Australia\/Hobart"
  },
  "YCBN": {
      "icao": "YCBN",
      "iata": "CBI",
      "name": "Cape Barren Island Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -40.3917007446,
      "lon": 148.016998291,
      "tz": "Australia\/Hobart"
  },
  "YCBO": {
      "icao": "YCBO",
      "iata": "",
      "name": "Cape Borda Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35.7566986084,
      "lon": 136.5980072021,
      "tz": "Australia\/Adelaide"
  },
  "YCBP": {
      "icao": "YCBP",
      "iata": "CPD",
      "name": "Coober Pedy Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 740,
      "lat": -29.0400009155,
      "lon": 134.720993042,
      "tz": "Australia\/Adelaide"
  },
  "YCBR": {
      "icao": "YCBR",
      "iata": "CRB",
      "name": "Collarenebri Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 152,
      "lat": -29.5216999054,
      "lon": 148.5820007324,
      "tz": "Australia\/Sydney"
  },
  "YCBY": {
      "icao": "YCBY",
      "iata": "",
      "name": "Daintree Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -16.2193412781,
      "lon": 145.4247283936,
      "tz": "Australia\/Brisbane"
  },
  "YCCA": {
      "icao": "YCCA",
      "iata": "CCL",
      "name": "Chinchilla Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1028,
      "lat": -26.7749996185,
      "lon": 150.6170043945,
      "tz": "Australia\/Brisbane"
  },
  "YCCF": {
      "icao": "YCCF",
      "iata": "",
      "name": "Cape Crawford Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.6833000183,
      "lon": 135.733001709,
      "tz": "Australia\/Darwin"
  },
  "YCCK": {
      "icao": "YCCK",
      "iata": "",
      "name": "Canteen Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.650800705,
      "lon": 135.5850067139,
      "tz": "Australia\/Darwin"
  },
  "YCCT": {
      "icao": "YCCT",
      "iata": "CNC",
      "name": "Coconut Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 3,
      "lat": -10.0500001907,
      "lon": 143.0700073242,
      "tz": "Australia\/Brisbane"
  },
  "YCCY": {
      "icao": "YCCY",
      "iata": "CNJ",
      "name": "Cloncurry Airport",
      "city": "Cloncurry",
      "state": "Queensland",
      "country": "AU",
      "elevation": 616,
      "lat": -20.6686000824,
      "lon": 140.503997803,
      "tz": "Australia\/Brisbane"
  },
  "YCDB": {
      "icao": "YCDB",
      "iata": "",
      "name": "Coondambo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.0666999817,
      "lon": 135.8829956055,
      "tz": "Australia\/Adelaide"
  },
  "YCDE": {
      "icao": "YCDE",
      "iata": "",
      "name": "Cobden Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.326666,
      "lon": 143.056666,
      "tz": "Australia\/Melbourne"
  },
  "YCDH": {
      "icao": "YCDH",
      "iata": "",
      "name": "Cadney Homestead Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 287,
      "lat": -27.9083003998,
      "lon": 134.0529937744,
      "tz": "Australia\/Adelaide"
  },
  "YCDL": {
      "icao": "YCDL",
      "iata": "",
      "name": "Cradle Mountain Airport",
      "city": "Middlesex",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.581231359,
      "lon": 145.93834877,
      "tz": "Australia\/Hobart"
  },
  "YCDO": {
      "icao": "YCDO",
      "iata": "CBX",
      "name": "Condobolin Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 650,
      "lat": -33.0643997192,
      "lon": 147.2089996338,
      "tz": "Australia\/Sydney"
  },
  "YCDR": {
      "icao": "YCDR",
      "iata": "CUD",
      "name": "Caloundra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 12,
      "lat": -26.7999992371,
      "lon": 153.1000061035,
      "tz": "Australia\/Brisbane"
  },
  "YCDS": {
      "icao": "YCDS",
      "iata": "",
      "name": "Childers Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2530800689,
      "lon": 152.335395813,
      "tz": "Australia\/Brisbane"
  },
  "YCDT": {
      "icao": "YCDT",
      "iata": "",
      "name": "Carandotta Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.9666996002,
      "lon": 138.6170043945,
      "tz": "Australia\/Brisbane"
  },
  "YCDU": {
      "icao": "YCDU",
      "iata": "CED",
      "name": "Ceduna Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 77,
      "lat": -32.1305999756,
      "lon": 133.7100067139,
      "tz": "Australia\/Adelaide"
  },
  "YCDV": {
      "icao": "YCDV",
      "iata": "",
      "name": "Caldervale Station Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1082992554,
      "lon": 146.8329925537,
      "tz": "Australia\/Brisbane"
  },
  "YCEE": {
      "icao": "YCEE",
      "iata": "CVC",
      "name": "Cleve Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 589,
      "lat": -33.7097015381,
      "lon": 136.5050048828,
      "tz": "Australia\/Adelaide"
  },
  "YCEL": {
      "icao": "YCEL",
      "iata": "",
      "name": "Capella Airport",
      "city": "Capella",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.1000003815,
      "lon": 148.0330047607,
      "tz": "Australia\/Brisbane"
  },
  "YCEM": {
      "icao": "YCEM",
      "iata": "",
      "name": "Coldstream Airport",
      "city": "Coldstream",
      "state": "Victoria",
      "country": "AU",
      "elevation": 76,
      "lat": -37.7276687622,
      "lon": 145.4083709717,
      "tz": "Australia\/Melbourne"
  },
  "YCES": {
      "icao": "YCES",
      "iata": "",
      "name": "Ceres Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.1483001709,
      "lon": 144.2579956055,
      "tz": "Australia\/Melbourne"
  },
  "YCFD": {
      "icao": "YCFD",
      "iata": "CFI",
      "name": "Camfield Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.0216999054,
      "lon": 131.3269958496,
      "tz": "Australia\/Darwin"
  },
  "YCFF": {
      "icao": "YCFF",
      "iata": "",
      "name": "Dadswells Bridge Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.9682998657,
      "lon": 142.5480041504,
      "tz": "Australia\/Melbourne"
  },
  "YCFH": {
      "icao": "YCFH",
      "iata": "",
      "name": "Clifton Hills Airport",
      "city": "Clifton Hills",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.0182991028,
      "lon": 138.891998291,
      "tz": "Australia\/Adelaide"
  },
  "YCFL": {
      "icao": "YCFL",
      "iata": "",
      "name": "Cape Flattery Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.9666996002,
      "lon": 145.3000030518,
      "tz": "Australia\/Brisbane"
  },
  "YCFN": {
      "icao": "YCFN",
      "iata": "",
      "name": "Clifton Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9283008575,
      "lon": 151.8470001221,
      "tz": "Australia\/Brisbane"
  },
  "YCGH": {
      "icao": "YCGH",
      "iata": "",
      "name": "Clonagh Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1333007813,
      "lon": 140.6829986572,
      "tz": "Australia\/Brisbane"
  },
  "YCGI": {
      "icao": "YCGI",
      "iata": "",
      "name": "Carnegie Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.7999992371,
      "lon": 122.9670028687,
      "tz": "Australia\/Perth"
  },
  "YCGO": {
      "icao": "YCGO",
      "iata": "LLG",
      "name": "Chillagoe Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1110,
      "lat": -17.1427993774,
      "lon": 144.529006958,
      "tz": "Australia\/Brisbane"
  },
  "YCHB": {
      "icao": "YCHB",
      "iata": "",
      "name": "Cherrabah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.4167003632,
      "lon": 152.1670074463,
      "tz": "Australia\/Brisbane"
  },
  "YCHK": {
      "icao": "YCHK",
      "iata": "CKW",
      "name": "Graeme Rowley Aerodrome",
      "city": "Christmas Creek mine",
      "state": "Western Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.355556,
      "lon": 119.652222,
      "tz": "Australia\/Perth"
  },
  "YCHL": {
      "icao": "YCHL",
      "iata": "",
      "name": "Charlton Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.25,
      "lon": 143.3000030518,
      "tz": "Australia\/Melbourne"
  },
  "YCHR": {
      "icao": "YCHR",
      "iata": "",
      "name": "Childra Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7000007629,
      "lon": 134.8329925537,
      "tz": "Australia\/Adelaide"
  },
  "YCHT": {
      "icao": "YCHT",
      "iata": "CXT",
      "name": "Charters Towers Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 955,
      "lat": -20.0431003571,
      "lon": 146.2729949951,
      "tz": "Australia\/Brisbane"
  },
  "YCIG": {
      "icao": "YCIG",
      "iata": "",
      "name": "Corrigin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.3333015442,
      "lon": 117.8170013428,
      "tz": "Australia\/Perth"
  },
  "YCIN": {
      "icao": "YCIN",
      "iata": "DCN",
      "name": "RAAF Base Curtin",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 300,
      "lat": -17.5813999176,
      "lon": 123.82800293,
      "tz": "Australia\/Perth"
  },
  "YCJU": {
      "icao": "YCJU",
      "iata": "",
      "name": "Conjuboy Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.6833000183,
      "lon": 144.75,
      "tz": "Australia\/Brisbane"
  },
  "YCKA": {
      "icao": "YCKA",
      "iata": "",
      "name": "Curdimurka Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.4766998291,
      "lon": 137.0879974365,
      "tz": "Australia\/Adelaide"
  },
  "YCKD": {
      "icao": "YCKD",
      "iata": "",
      "name": "Clarke Island Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -40.5167007446,
      "lon": 148.1329956055,
      "tz": "Australia\/Hobart"
  },
  "YCKI": {
      "icao": "YCKI",
      "iata": "CKI",
      "name": "Croker Island Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 16,
      "lat": -11.1649999619,
      "lon": 132.483001709,
      "tz": "Australia\/Darwin"
  },
  "YCKN": {
      "icao": "YCKN",
      "iata": "CTN",
      "name": "Cooktown Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 26,
      "lat": -15.4447002411,
      "lon": 145.1840057373,
      "tz": "Australia\/Brisbane"
  },
  "YCKY": {
      "icao": "YCKY",
      "iata": "",
      "name": "Cocklebiddy Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.0499992371,
      "lon": 126.0920028687,
      "tz": "Australia\/Eucla"
  },
  "YCLA": {
      "icao": "YCLA",
      "iata": "",
      "name": "Clare Station Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 266,
      "lat": -33.4068984985,
      "lon": 143.9400024414,
      "tz": "Australia\/Sydney"
  },
  "YCLE": {
      "icao": "YCLE",
      "iata": "",
      "name": "Callendale Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -37.2433013916,
      "lon": 140.4450073242,
      "tz": "Australia\/Adelaide"
  },
  "YCLG": {
      "icao": "YCLG",
      "iata": "",
      "name": "Coolgardie Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9500007629,
      "lon": 121.1500015259,
      "tz": "Australia\/Perth"
  },
  "YCLM": {
      "icao": "YCLM",
      "iata": "",
      "name": "Collymongle Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.4533004761,
      "lon": 148.7429962158,
      "tz": "Australia\/Sydney"
  },
  "YCLQ": {
      "icao": "YCLQ",
      "iata": "",
      "name": "Cape Leveque Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.3983001709,
      "lon": 122.9319992065,
      "tz": "Australia\/Perth"
  },
  "YCLT": {
      "icao": "YCLT",
      "iata": "",
      "name": "Collector Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.8833007813,
      "lon": 149.4170074463,
      "tz": "Australia\/Sydney"
  },
  "YCLY": {
      "icao": "YCLY",
      "iata": "",
      "name": "Coleambally Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.7999992371,
      "lon": 145.8500061035,
      "tz": "Australia\/Sydney"
  },
  "YCMH": {
      "icao": "YCMH",
      "iata": "",
      "name": "Camden Haven Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2,
      "lat": -31.6650009155,
      "lon": 152.7420043945,
      "tz": "Australia\/Sydney"
  },
  "YCMK": {
      "icao": "YCMK",
      "iata": "",
      "name": "Camel Creek Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.8500003815,
      "lon": 145.4669952393,
      "tz": "Australia\/Brisbane"
  },
  "YCMM": {
      "icao": "YCMM",
      "iata": "",
      "name": "Cummins Town Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.2583007813,
      "lon": 135.7079925537,
      "tz": "Australia\/Adelaide"
  },
  "YCMT": {
      "icao": "YCMT",
      "iata": "CMQ",
      "name": "Clermont Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 908,
      "lat": -22.7730998993,
      "lon": 147.6210021973,
      "tz": "Australia\/Brisbane"
  },
  "YCMU": {
      "icao": "YCMU",
      "iata": "CMA",
      "name": "Cunnamulla Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 630,
      "lat": -28.0300006866,
      "lon": 145.6219940186,
      "tz": "Australia\/Brisbane"
  },
  "YCMW": {
      "icao": "YCMW",
      "iata": "CML",
      "name": "Camooweal Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 241,
      "lat": -19.911699295,
      "lon": 138.125,
      "tz": "Australia\/Brisbane"
  },
  "YCNA": {
      "icao": "YCNA",
      "iata": "",
      "name": "Corona Station Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 748,
      "lat": -31.2908000946,
      "lon": 141.4519958496,
      "tz": "Australia\/Sydney"
  },
  "YCNF": {
      "icao": "YCNF",
      "iata": "NIF",
      "name": "Camp Nifty Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 295,
      "lat": -21.6716995239,
      "lon": 121.5869979858,
      "tz": "Australia\/Perth"
  },
  "YCNG": {
      "icao": "YCNG",
      "iata": "",
      "name": "Cooranga Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -36.8199996948,
      "lon": 140.2729949951,
      "tz": "Australia\/Adelaide"
  },
  "YCNH": {
      "icao": "YCNH",
      "iata": "",
      "name": "Carnarmah Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.6833000183,
      "lon": 115.8830032349,
      "tz": "Australia\/Perth"
  },
  "YCNK": {
      "icao": "YCNK",
      "iata": "CES",
      "name": "Cessnock Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 211,
      "lat": -32.7874984741,
      "lon": 151.3419952393,
      "tz": "Australia\/Sydney"
  },
  "YCNM": {
      "icao": "YCNM",
      "iata": "CNB",
      "name": "Coonamble Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 604,
      "lat": -30.9832992554,
      "lon": 148.3760070801,
      "tz": "Australia\/Sydney"
  },
  "YCNN": {
      "icao": "YCNN",
      "iata": "",
      "name": "Coonana Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.0167007446,
      "lon": 123.1669998169,
      "tz": "Australia\/Perth"
  },
  "YCNO": {
      "icao": "YCNO",
      "iata": "",
      "name": "Conargo Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.3166999817,
      "lon": 145.1170043945,
      "tz": "Australia\/Sydney"
  },
  "YCNQ": {
      "icao": "YCNQ",
      "iata": "",
      "name": "Coonawarra Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -37.2832984924,
      "lon": 140.8000030518,
      "tz": "Australia\/Adelaide"
  },
  "YCNR": {
      "icao": "YCNR",
      "iata": "",
      "name": "Cann River Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.5167007446,
      "lon": 149.1670074463,
      "tz": "Australia\/Melbourne"
  },
  "YCNS": {
      "icao": "YCNS",
      "iata": "",
      "name": "Coniston Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.1333007813,
      "lon": 132.5330047607,
      "tz": "Australia\/Darwin"
  },
  "YCNX": {
      "icao": "YCNX",
      "iata": "",
      "name": "Cooranga Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.0666999817,
      "lon": 150.766998291,
      "tz": "Australia\/Sydney"
  },
  "YCNY": {
      "icao": "YCNY",
      "iata": "",
      "name": "Century Mine Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 416,
      "lat": -18.7532997131,
      "lon": 138.7070007324,
      "tz": "Australia\/Brisbane"
  },
  "YCOD": {
      "icao": "YCOD",
      "iata": "ODL",
      "name": "Cordillo Downs Airport",
      "city": "Cordillo Downs",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 125,
      "lat": -26.745300293,
      "lon": 140.6380004883,
      "tz": "Australia\/Adelaide"
  },
  "YCOE": {
      "icao": "YCOE",
      "iata": "CUQ",
      "name": "Coen Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 532,
      "lat": -13.7608003616,
      "lon": 143.1139984131,
      "tz": "Australia\/Brisbane"
  },
  "YCOF": {
      "icao": "YCOF",
      "iata": "",
      "name": "Coffin Bay Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.6333007813,
      "lon": 135.516998291,
      "tz": "Australia\/Adelaide"
  },
  "YCOG": {
      "icao": "YCOG",
      "iata": "",
      "name": "Coongan Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.6833000183,
      "lon": 119.6669998169,
      "tz": "Australia\/Perth"
  },
  "YCOH": {
      "icao": "YCOH",
      "iata": "",
      "name": "Cohuna Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.8166999817,
      "lon": 144.1999969482,
      "tz": "Australia\/Melbourne"
  },
  "YCOI": {
      "icao": "YCOI",
      "iata": "CIE",
      "name": "Collie Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.3666992188,
      "lon": 116.2170028687,
      "tz": "Australia\/Perth"
  },
  "YCOL": {
      "icao": "YCOL",
      "iata": "",
      "name": "Coleraine Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.5999984741,
      "lon": 141.6999969482,
      "tz": "Australia\/Melbourne"
  },
  "YCOM": {
      "icao": "YCOM",
      "iata": "OOM",
      "name": "Cooma Snowy Mountains Airport",
      "city": "Cooma",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3088,
      "lat": -36.3005981445,
      "lon": 148.973999023,
      "tz": "Australia\/Sydney"
  },
  "YCOO": {
      "icao": "YCOO",
      "iata": "CDA",
      "name": "Cooinda Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 13,
      "lat": -12.9033002853,
      "lon": 132.5319976807,
      "tz": "Australia\/Darwin"
  },
  "YCOR": {
      "icao": "YCOR",
      "iata": "CWW",
      "name": "Corowa Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 469,
      "lat": -35.9947013855,
      "lon": 146.3569946289,
      "tz": "Australia\/Melbourne"
  },
  "YCOS": {
      "icao": "YCOS",
      "iata": "",
      "name": "Cosmo Newberry Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9850006104,
      "lon": 122.9029998779,
      "tz": "Australia\/Perth"
  },
  "YCOY": {
      "icao": "YCOY",
      "iata": "",
      "name": "Coral Bay Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 5,
      "lat": -23.1299991608,
      "lon": 113.7770004272,
      "tz": "Australia\/Perth"
  },
  "YCPD": {
      "icao": "YCPD",
      "iata": "",
      "name": "Cape Don Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -11.2982997894,
      "lon": 131.8269958496,
      "tz": "Australia\/Darwin"
  },
  "YCPG": {
      "icao": "YCPG",
      "iata": "",
      "name": "Coppins Gap Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.9500007629,
      "lon": 120.0329971313,
      "tz": "Australia\/Perth"
  },
  "YCPH": {
      "icao": "YCPH",
      "iata": "",
      "name": "Copper Hills Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9783000946,
      "lon": 134.3170013428,
      "tz": "Australia\/Adelaide"
  },
  "YCPN": {
      "icao": "YCPN",
      "iata": "",
      "name": "Carpentaria Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.7166996002,
      "lon": 144.3170013428,
      "tz": "Australia\/Brisbane"
  },
  "YCPP": {
      "icao": "YCPP",
      "iata": "",
      "name": "Copper Triangle Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.9817008972,
      "lon": 137.6620025635,
      "tz": "Australia\/Adelaide"
  },
  "YCRA": {
      "icao": "YCRA",
      "iata": "",
      "name": "Carinda Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 131,
      "lat": -30.4582996368,
      "lon": 147.7079925537,
      "tz": "Australia\/Sydney"
  },
  "YCRG": {
      "icao": "YCRG",
      "iata": "CYG",
      "name": "Corryong Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 963,
      "lat": -36.182800293,
      "lon": 147.8880004883,
      "tz": "Australia\/Melbourne"
  },
  "YCRK": {
      "icao": "YCRK",
      "iata": "CXQ",
      "name": "Christmas Creek Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.8833007813,
      "lon": 125.9169998169,
      "tz": "Australia\/Perth"
  },
  "YCRL": {
      "icao": "YCRL",
      "iata": "",
      "name": "Crookwell Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.5,
      "lon": 149.4499969482,
      "tz": "Australia\/Sydney"
  },
  "YCRN": {
      "icao": "YCRN",
      "iata": "",
      "name": "Cranbrook Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.2832984924,
      "lon": 117.5500030518,
      "tz": "Australia\/Perth"
  },
  "YCRT": {
      "icao": "YCRT",
      "iata": "",
      "name": "Carrieton Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4166984558,
      "lon": 138.516998291,
      "tz": "Australia\/Adelaide"
  },
  "YCRY": {
      "icao": "YCRY",
      "iata": "CDQ",
      "name": "Croydon Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 107,
      "lat": -18.2250003815,
      "lon": 142.2579956055,
      "tz": "Australia\/Brisbane"
  },
  "YCSI": {
      "icao": "YCSI",
      "iata": "",
      "name": "Cassilis Rotherw Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1545,
      "lat": -32.0022010803,
      "lon": 149.9609985352,
      "tz": "Australia\/Sydney"
  },
  "YCSL": {
      "icao": "YCSL",
      "iata": "",
      "name": "Consuelo Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.6333007813,
      "lon": 148.4669952393,
      "tz": "Australia\/Brisbane"
  },
  "YCSP": {
      "icao": "YCSP",
      "iata": "",
      "name": "Curtin Springs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3166999817,
      "lon": 131.75,
      "tz": "Australia\/Darwin"
  },
  "YCSV": {
      "icao": "YCSV",
      "iata": "KCE",
      "name": "Collinsville Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 180,
      "lat": -20.5967006683,
      "lon": 147.8600006104,
      "tz": "Australia\/Brisbane"
  },
  "YCTC": {
      "icao": "YCTC",
      "iata": "",
      "name": "Cotten Creek Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.7999992371,
      "lon": 122.5830001831,
      "tz": "Australia\/Perth"
  },
  "YCTH": {
      "icao": "YCTH",
      "iata": "",
      "name": "Chatsworth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.9666996002,
      "lon": 140.3000030518,
      "tz": "Australia\/Brisbane"
  },
  "YCTI": {
      "icao": "YCTI",
      "iata": "",
      "name": "Cockatoo Island Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.0832996368,
      "lon": 123.6169967651,
      "tz": "Australia\/Perth"
  },
  "YCTM": {
      "icao": "YCTM",
      "iata": "CMD",
      "name": "Cootamundra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1110,
      "lat": -34.6239013672,
      "lon": 148.0279998779,
      "tz": "Australia\/Sydney"
  },
  "YCTN": {
      "icao": "YCTN",
      "iata": "",
      "name": "Casterton Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.5999984741,
      "lon": 141.3999938965,
      "tz": "Australia\/Melbourne"
  },
  "YCTS": {
      "icao": "YCTS",
      "iata": "",
      "name": "Calton Hills Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1333007813,
      "lon": 139.4170074463,
      "tz": "Australia\/Brisbane"
  },
  "YCTY": {
      "icao": "YCTY",
      "iata": "",
      "name": "Cape Otway Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.8600006104,
      "lon": 143.5149993896,
      "tz": "Australia\/Melbourne"
  },
  "YCUA": {
      "icao": "YCUA",
      "iata": "CUG",
      "name": "Cudal Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 491,
      "lat": -33.278301239,
      "lon": 148.7630004883,
      "tz": "Australia\/Sydney"
  },
  "YCUD": {
      "icao": "YCUD",
      "iata": "",
      "name": "Cuddapan Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5900001526,
      "lon": 141.5079956055,
      "tz": "Australia\/Brisbane"
  },
  "YCUE": {
      "icao": "YCUE",
      "iata": "CUY",
      "name": "Cue Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 442,
      "lat": -27.4466991425,
      "lon": 117.9179992676,
      "tz": "Australia\/Perth"
  },
  "YCUN": {
      "icao": "YCUN",
      "iata": "",
      "name": "Cunderdin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 705,
      "lat": -31.6222000122,
      "lon": 117.2170028687,
      "tz": "Australia\/Perth"
  },
  "YCUR": {
      "icao": "YCUR",
      "iata": "",
      "name": "Cabramurra Airport",
      "city": "Cabramurra Township",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.9268875122,
      "lon": 148.3925476074,
      "tz": "Australia\/Sydney"
  },
  "YCUS": {
      "icao": "YCUS",
      "iata": "",
      "name": "Cummins Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 197,
      "lat": -34.2083015442,
      "lon": 135.641998291,
      "tz": "Australia\/Adelaide"
  },
  "YCUT": {
      "icao": "YCUT",
      "iata": "",
      "name": "Cuthero Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -33.0332984924,
      "lon": 142.016998291,
      "tz": "Australia\/Sydney"
  },
  "YCVG": {
      "icao": "YCVG",
      "iata": "",
      "name": "Calvin Grove Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.6967010498,
      "lon": 138.5780029297,
      "tz": "Australia\/Adelaide"
  },
  "YCVS": {
      "icao": "YCVS",
      "iata": "",
      "name": "Cervantes Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.4832992554,
      "lon": 115.0830001831,
      "tz": "Australia\/Perth"
  },
  "YCWA": {
      "icao": "YCWA",
      "iata": "CJF",
      "name": "Coondewanna Wa Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 2327,
      "lat": -22.966667,
      "lon": 118.802222,
      "tz": "Australia\/Perth"
  },
  "YCWE": {
      "icao": "YCWE",
      "iata": "",
      "name": "Cape Wessels Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -11,
      "lon": 136.75,
      "tz": "Australia\/Darwin"
  },
  "YCWH": {
      "icao": "YCWH",
      "iata": "",
      "name": "Commonwealth Hill Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.9617004395,
      "lon": 134.1430053711,
      "tz": "Australia\/Adelaide"
  },
  "YCWI": {
      "icao": "YCWI",
      "iata": "CWR",
      "name": "Cowarie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 52,
      "lat": -27.7117004395,
      "lon": 138.3280029297,
      "tz": "Australia\/Adelaide"
  },
  "YCWL": {
      "icao": "YCWL",
      "iata": "CCW",
      "name": "Cowell Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 39,
      "lat": -33.6666984558,
      "lon": 136.891998291,
      "tz": "Australia\/Adelaide"
  },
  "YCWN": {
      "icao": "YCWN",
      "iata": "",
      "name": "Corowa Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.4666996002,
      "lon": 143.3500061035,
      "tz": "Australia\/Brisbane"
  },
  "YCWO": {
      "icao": "YCWO",
      "iata": "",
      "name": "Chatsworth Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.8333015442,
      "lon": 142.7169952393,
      "tz": "Australia\/Melbourne"
  },
  "YCWR": {
      "icao": "YCWR",
      "iata": "CWT",
      "name": "Cowra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 966,
      "lat": -33.8446998596,
      "lon": 148.6490020752,
      "tz": "Australia\/Sydney"
  },
  "YCWS": {
      "icao": "YCWS",
      "iata": "",
      "name": "Cowes Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.5083007813,
      "lon": 145.2129974365,
      "tz": "Australia\/Melbourne"
  },
  "YCWW": {
      "icao": "YCWW",
      "iata": "",
      "name": "Canowindra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1073,
      "lat": -33.5428009033,
      "lon": 148.6629943848,
      "tz": "Australia\/Sydney"
  },
  "YCWY": {
      "icao": "YCWY",
      "iata": "COY",
      "name": "Coolawanyah Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.7833003998,
      "lon": 117.8000030518,
      "tz": "Australia\/Perth"
  },
  "YCXA": {
      "icao": "YCXA",
      "iata": "",
      "name": "Cooloola Village Airpark",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 9,
      "lat": -25.9750003815,
      "lon": 153,
      "tz": "Australia\/Brisbane"
  },
  "YCYT": {
      "icao": "YCYT",
      "iata": "",
      "name": "Crystal Brook Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.3833007813,
      "lon": 144.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "YDAG": {
      "icao": "YDAG",
      "iata": "",
      "name": "Dagworth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.9416999817,
      "lon": 143.5879974365,
      "tz": "Australia\/Brisbane"
  },
  "YDAJ": {
      "icao": "YDAJ",
      "iata": "DJR",
      "name": "Dajarra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 335,
      "lat": -21.7082996368,
      "lon": 139.5330047607,
      "tz": "Australia\/Brisbane"
  },
  "YDAL": {
      "icao": "YDAL",
      "iata": "",
      "name": "Dallas Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.5666999817,
      "lon": 146.1829986572,
      "tz": "Australia\/Sydney"
  },
  "YDAR": {
      "icao": "YDAR",
      "iata": "",
      "name": "Darlington Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.5727408735,
      "lon": 148.069181442,
      "tz": "Australia\/Hobart"
  },
  "YDAY": {
      "icao": "YDAY",
      "iata": "DBY",
      "name": "Dalby Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1137,
      "lat": -27.1553001404,
      "lon": 151.266998291,
      "tz": "Australia\/Brisbane"
  },
  "YDBI": {
      "icao": "YDBI",
      "iata": "DRN",
      "name": "Dirranbandi Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 567,
      "lat": -28.5916996002,
      "lon": 148.2169952393,
      "tz": "Australia\/Brisbane"
  },
  "YDBR": {
      "icao": "YDBR",
      "iata": "DNB",
      "name": "Dunbar Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -16.0499992371,
      "lon": 142.3999938965,
      "tz": "Australia\/Brisbane"
  },
  "YDBY": {
      "icao": "YDBY",
      "iata": "DRB",
      "name": "Derby Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 24,
      "lat": -17.3700008392,
      "lon": 123.6610031128,
      "tz": "Australia\/Perth"
  },
  "YDDF": {
      "icao": "YDDF",
      "iata": "DFP",
      "name": "Drumduff Airport",
      "city": "Drumduff",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -16.0529994965,
      "lon": 143.011993408,
      "tz": "Australia\/Brisbane"
  },
  "YDEG": {
      "icao": "YDEG",
      "iata": "",
      "name": "Delegate Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2546,
      "lat": -37.0614013672,
      "lon": 148.9420013428,
      "tz": "Australia\/Sydney"
  },
  "YDEK": {
      "icao": "YDEK",
      "iata": "",
      "name": "Denmark Airport",
      "city": "Denmark",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 230,
      "lat": -34.9449996948,
      "lon": 117.3970031738,
      "tz": "Australia\/Perth"
  },
  "YDER": {
      "icao": "YDER",
      "iata": "",
      "name": "Derrinallum Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 600,
      "lat": -37.9341666667,
      "lon": 143.227777778,
      "tz": "Australia\/Melbourne"
  },
  "YDEV": {
      "icao": "YDEV",
      "iata": "",
      "name": "Devoncourt Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.2166996002,
      "lon": 140.233001709,
      "tz": "Australia\/Brisbane"
  },
  "YDGA": {
      "icao": "YDGA",
      "iata": "DGD",
      "name": "Dalgaranga Gold Mine Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1459,
      "lat": -27.8302777778,
      "lon": 117.316388889,
      "tz": "Australia\/Perth"
  },
  "YDGI": {
      "icao": "YDGI",
      "iata": "",
      "name": "Dullingari Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.1333007813,
      "lon": 140.8820037842,
      "tz": "Australia\/Adelaide"
  },
  "YDGN": {
      "icao": "YDGN",
      "iata": "",
      "name": "Doongan Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -15.3999996185,
      "lon": 126.3000030518,
      "tz": "Australia\/Perth"
  },
  "YDGR": {
      "icao": "YDGR",
      "iata": "",
      "name": "Dalgaranga Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7914403561,
      "lon": 116.993322372,
      "tz": "Australia\/Perth"
  },
  "YDGT": {
      "icao": "YDGT",
      "iata": "",
      "name": "Dalgety Downs Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2833003998,
      "lon": 116.1999969482,
      "tz": "Australia\/Perth"
  },
  "YDGY": {
      "icao": "YDGY",
      "iata": "",
      "name": "De Grey Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1749992371,
      "lon": 119.1689987183,
      "tz": "Australia\/Perth"
  },
  "YDHL": {
      "icao": "YDHL",
      "iata": "",
      "name": "Dhalinbuy Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.4132995605,
      "lon": 136.5370025635,
      "tz": "Australia\/Darwin"
  },
  "YDIM": {
      "icao": "YDIM",
      "iata": "",
      "name": "Dimbulah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.1333007813,
      "lon": 145.0829925537,
      "tz": "Australia\/Brisbane"
  },
  "YDIX": {
      "icao": "YDIX",
      "iata": "DXD",
      "name": "Dixie Airport",
      "city": "New Dixie",
      "state": "Queensland",
      "country": "AU",
      "elevation": 580,
      "lat": -15.1174944348,
      "lon": 143.316049576,
      "tz": "Australia\/Brisbane"
  },
  "YDKI": {
      "icao": "YDKI",
      "iata": "DKI",
      "name": "Dunk Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 6,
      "lat": -17.9416999817,
      "lon": 146.1399993896,
      "tz": "Australia\/Brisbane"
  },
  "YDLC": {
      "icao": "YDLC",
      "iata": "",
      "name": "Dulacca Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.6333007813,
      "lon": 149.7169952393,
      "tz": "Australia\/Brisbane"
  },
  "YDLD": {
      "icao": "YDLD",
      "iata": "",
      "name": "Delmore Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.4585990906,
      "lon": 134.8840026855,
      "tz": "Australia\/Darwin"
  },
  "YDLH": {
      "icao": "YDLH",
      "iata": "",
      "name": "Dalhousie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.4349994659,
      "lon": 135.516998291,
      "tz": "Australia\/Adelaide"
  },
  "YDLK": {
      "icao": "YDLK",
      "iata": "DLK",
      "name": "Dulkaninna Airport",
      "city": "Dulkaninna",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.013299942,
      "lon": 138.4810028076,
      "tz": "Australia\/Adelaide"
  },
  "YDLL": {
      "icao": "YDLL",
      "iata": "",
      "name": "Dunolly Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.8499984741,
      "lon": 143.6999969482,
      "tz": "Australia\/Melbourne"
  },
  "YDLO": {
      "icao": "YDLO",
      "iata": "",
      "name": "Darlot Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.8833007813,
      "lon": 121.266998291,
      "tz": "Australia\/Perth"
  },
  "YDLQ": {
      "icao": "YDLQ",
      "iata": "DNQ",
      "name": "Deniliquin Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 316,
      "lat": -35.5593986511,
      "lon": 144.945999146,
      "tz": "Australia\/Sydney"
  },
  "YDLT": {
      "icao": "YDLT",
      "iata": "DDN",
      "name": "Delta Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -16.9916992188,
      "lon": 141.3170013428,
      "tz": "Australia\/Brisbane"
  },
  "YDLV": {
      "icao": "YDLV",
      "iata": "DLV",
      "name": "Delissaville Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 34,
      "lat": -12.5500001907,
      "lon": 130.6849975586,
      "tz": "Australia\/Darwin"
  },
  "YDLW": {
      "icao": "YDLW",
      "iata": "DYW",
      "name": "Daly Waters Airport",
      "city": "Daly Waters",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 700,
      "lat": -16.2646950664,
      "lon": 133.383378983,
      "tz": "Australia\/Darwin"
  },
  "YDME": {
      "icao": "YDME",
      "iata": "",
      "name": "Mount Dimer Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.4016990662,
      "lon": 119.8300018311,
      "tz": "Australia\/Perth"
  },
  "YDMF": {
      "icao": "YDMF",
      "iata": "",
      "name": "Diemal Find Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.6667003632,
      "lon": 119.2829971313,
      "tz": "Australia\/Perth"
  },
  "YDMG": {
      "icao": "YDMG",
      "iata": "DMD",
      "name": "Doomadgee Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 153,
      "lat": -17.9402999878,
      "lon": 138.8220062256,
      "tz": "Australia\/Brisbane"
  },
  "YDMN": {
      "icao": "YDMN",
      "iata": "DVR",
      "name": "Daly River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -13.7498064041,
      "lon": 130.6938781738,
      "tz": "Australia\/Darwin"
  },
  "YDMR": {
      "icao": "YDMR",
      "iata": "",
      "name": "Delamere Station Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -15.6199998856,
      "lon": 131.6369934082,
      "tz": "Australia\/Darwin"
  },
  "YDNB": {
      "icao": "YDNB",
      "iata": "",
      "name": "Doongmabulla Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.0666999817,
      "lon": 146.233001709,
      "tz": "Australia\/Brisbane"
  },
  "YDNI": {
      "icao": "YDNI",
      "iata": "NLF",
      "name": "Darnley Island Airport",
      "city": "Darnley Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -9.5833301544,
      "lon": 143.766998291,
      "tz": "Australia\/Brisbane"
  },
  "YDNK": {
      "icao": "YDNK",
      "iata": "",
      "name": "Darnick Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.8833007813,
      "lon": 143.5829925537,
      "tz": "Australia\/Sydney"
  },
  "YDNR": {
      "icao": "YDNR",
      "iata": "",
      "name": "Dunmore Manila Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1391,
      "lat": -30.6581001282,
      "lon": 150.875,
      "tz": "Australia\/Sydney"
  },
  "YDNV": {
      "icao": "YDNV",
      "iata": "",
      "name": "Dynevor Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.1000003815,
      "lon": 144.3500061035,
      "tz": "Australia\/Brisbane"
  },
  "YDOC": {
      "icao": "YDOC",
      "iata": "",
      "name": "Dochra Airfield",
      "city": "Singleton",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 185,
      "lat": -32.6500015259,
      "lon": 151.207992554,
      "tz": "Australia\/Sydney"
  },
  "YDOD": {
      "icao": "YDOD",
      "iata": "",
      "name": "Donald Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 409,
      "lat": -36.3602981567,
      "lon": 143.0079956055,
      "tz": "Australia\/Melbourne"
  },
  "YDON": {
      "icao": "YDON",
      "iata": "",
      "name": "Dowerin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.1833000183,
      "lon": 117.0500030518,
      "tz": "Australia\/Perth"
  },
  "YDOO": {
      "icao": "YDOO",
      "iata": "",
      "name": "Donors Hill Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.7166996002,
      "lon": 140.5500030518,
      "tz": "Australia\/Brisbane"
  },
  "YDOP": {
      "icao": "YDOP",
      "iata": "",
      "name": "Donnington Airpark",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 76,
      "lat": -19.611700058,
      "lon": 146.8399963379,
      "tz": "Australia\/Brisbane"
  },
  "YDOR": {
      "icao": "YDOR",
      "iata": "DRD",
      "name": "Dorunda Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 27,
      "lat": -16.5583000183,
      "lon": 141.8079986572,
      "tz": "Australia\/Brisbane"
  },
  "YDPD": {
      "icao": "YDPD",
      "iata": "DVP",
      "name": "Davenport Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 95,
      "lat": -24.1499996185,
      "lon": 141.108001709,
      "tz": "Australia\/Brisbane"
  },
  "YDPO": {
      "icao": "YDPO",
      "iata": "DPO",
      "name": "Devonport Airport",
      "city": "Devonport",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 33,
      "lat": -41.1697006226,
      "lon": 146.429992676,
      "tz": "Australia\/Hobart"
  },
  "YDPR": {
      "icao": "YDPR",
      "iata": "",
      "name": "Dneiper Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.6166992188,
      "lon": 135.1999969482,
      "tz": "Australia\/Darwin"
  },
  "YDPS": {
      "icao": "YDPS",
      "iata": "",
      "name": "Depot Springs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.8833007813,
      "lon": 120.0830001831,
      "tz": "Australia\/Perth"
  },
  "YDPT": {
      "icao": "YDPT",
      "iata": "",
      "name": "Depot Outcamp Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.5722007751,
      "lon": 125.18699646,
      "tz": "Australia\/Perth"
  },
  "YDPW": {
      "icao": "YDPW",
      "iata": "",
      "name": "Deep Well Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2999992371,
      "lon": 134.1329956055,
      "tz": "Australia\/Darwin"
  },
  "YDRA": {
      "icao": "YDRA",
      "iata": "DOX",
      "name": "Dongara Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.2999992371,
      "lon": 114.932998657,
      "tz": "Australia\/Perth"
  },
  "YDRC": {
      "icao": "YDRC",
      "iata": "",
      "name": "Dairy Creek Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2833003998,
      "lon": 115.8669967651,
      "tz": "Australia\/Perth"
  },
  "YDRD": {
      "icao": "YDRD",
      "iata": "DRY",
      "name": "Drysdale River Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 360,
      "lat": -15.7135703992,
      "lon": 126.38109684,
      "tz": "Australia\/Perth"
  },
  "YDRH": {
      "icao": "YDRH",
      "iata": "DHD",
      "name": "Durham Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 116,
      "lat": -27.0750007629,
      "lon": 141.8999938965,
      "tz": "Australia\/Brisbane"
  },
  "YDRI": {
      "icao": "YDRI",
      "iata": "DRR",
      "name": "Durrie Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 58,
      "lat": -25.6849994659,
      "lon": 140.2279968262,
      "tz": "Australia\/Brisbane"
  },
  "YDRL": {
      "icao": "YDRL",
      "iata": "",
      "name": "De Rose Hill Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.4333000183,
      "lon": 133.2579956055,
      "tz": "Australia\/Adelaide"
  },
  "YDUK": {
      "icao": "YDUK",
      "iata": "",
      "name": "Duketon Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9582996368,
      "lon": 122.3079986572,
      "tz": "Australia\/Perth"
  },
  "YDUM": {
      "icao": "YDUM",
      "iata": "",
      "name": "Dumbleyung Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.3333015442,
      "lon": 117.75,
      "tz": "Australia\/Perth"
  },
  "YDUN": {
      "icao": "YDUN",
      "iata": "",
      "name": "Dunwich Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 76,
      "lat": -27.5167007446,
      "lon": 153.4279937744,
      "tz": "Australia\/Brisbane"
  },
  "YDVE": {
      "icao": "YDVE",
      "iata": "",
      "name": "Dale River Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.2832984924,
      "lon": 116.5500030518,
      "tz": "Australia\/Perth"
  },
  "YDVR": {
      "icao": "YDVR",
      "iata": "DKV",
      "name": "Docker River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 589,
      "lat": -24.8600006104,
      "lon": 129.0700073242,
      "tz": "Australia\/Darwin"
  },
  "YDWF": {
      "icao": "YDWF",
      "iata": "",
      "name": "Delamere Range Facility Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 223,
      "lat": -15.7467002869,
      "lon": 131.9199981689,
      "tz": "Australia\/Darwin"
  },
  "YDWU": {
      "icao": "YDWU",
      "iata": "",
      "name": "Dalwallinu Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.2000007629,
      "lon": 116.6500015259,
      "tz": "Australia\/Perth"
  },
  "YDYD": {
      "icao": "YDYD",
      "iata": "",
      "name": "Derry Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.0832996368,
      "lon": 135.3329925537,
      "tz": "Australia\/Darwin"
  },
  "YDYS": {
      "icao": "YDYS",
      "iata": "DYA",
      "name": "Dysart Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 670,
      "lat": -22.6222000122,
      "lon": 148.3639984131,
      "tz": "Australia\/Brisbane"
  },
  "YEAB": {
      "icao": "YEAB",
      "iata": "",
      "name": "Euabalong Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -33.1166992188,
      "lon": 146.4499969482,
      "tz": "Australia\/Sydney"
  },
  "YEAT": {
      "icao": "YEAT",
      "iata": "",
      "name": "Yea Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.215801239,
      "lon": 146.4299926758,
      "tz": "Australia\/Melbourne"
  },
  "YECH": {
      "icao": "YECH",
      "iata": "ECH",
      "name": "Echuca Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 323,
      "lat": -36.1571998596,
      "lon": 144.7619934082,
      "tz": "Australia\/Melbourne"
  },
  "YECL": {
      "icao": "YECL",
      "iata": "EUC",
      "name": "Eucla Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7000007629,
      "lon": 128.8829956055,
      "tz": "Australia\/Eucla"
  },
  "YEDA": {
      "icao": "YEDA",
      "iata": "ETD",
      "name": "Etadunna Airport",
      "city": "Etadunna",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.7408008575,
      "lon": 138.5890045166,
      "tz": "Australia\/Adelaide"
  },
  "YEDE": {
      "icao": "YEDE",
      "iata": "",
      "name": "Edenhope Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.0167007446,
      "lon": 141.266998291,
      "tz": "Australia\/Melbourne"
  },
  "YEDM": {
      "icao": "YEDM",
      "iata": "",
      "name": "Edmund Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.75,
      "lon": 116.0999984741,
      "tz": "Australia\/Perth"
  },
  "YEEB": {
      "icao": "YEEB",
      "iata": "ENB",
      "name": "Eneabba Airport",
      "city": "Eneabba",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.8325004578,
      "lon": 115.2460021973,
      "tz": "Australia\/Perth"
  },
  "YEES": {
      "icao": "YEES",
      "iata": "",
      "name": "Elderslie Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 112,
      "lat": -32.5997009277,
      "lon": 151.3399963379,
      "tz": "Australia\/Sydney"
  },
  "YEGA": {
      "icao": "YEGA",
      "iata": "",
      "name": "Engonnia Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.3166999817,
      "lon": 145.8329925537,
      "tz": "Australia\/Sydney"
  },
  "YEIN": {
      "icao": "YEIN",
      "iata": "EIH",
      "name": "Einasleigh Airport",
      "city": "Einasleigh",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.5032997131,
      "lon": 144.093994141,
      "tz": "Australia\/Brisbane"
  },
  "YELD": {
      "icao": "YELD",
      "iata": "ELC",
      "name": "Elcho Island Airport",
      "city": "Elcho Island",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 101,
      "lat": -12.0193996429,
      "lon": 135.570999146,
      "tz": "Australia\/Darwin"
  },
  "YELE": {
      "icao": "YELE",
      "iata": "",
      "name": "Belele Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.3666992188,
      "lon": 118.016998291,
      "tz": "Australia\/Perth"
  },
  "YELG": {
      "icao": "YELG",
      "iata": "",
      "name": "Elengerah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.8500003815,
      "lon": 147.983001709,
      "tz": "Australia\/Sydney"
  },
  "YELK": {
      "icao": "YELK",
      "iata": "",
      "name": "Elkedra Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.1667003632,
      "lon": 135.4329986572,
      "tz": "Australia\/Darwin"
  },
  "YELL": {
      "icao": "YELL",
      "iata": "",
      "name": "Elliott Airport",
      "city": "Elliott",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.5274295807,
      "lon": 133.5298919678,
      "tz": "Australia\/Darwin"
  },
  "YELM": {
      "icao": "YELM",
      "iata": "",
      "name": "Elmore Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.4901793786,
      "lon": 144.647197723,
      "tz": "Australia\/Melbourne"
  },
  "YELN": {
      "icao": "YELN",
      "iata": "",
      "name": "Elliston Airport",
      "city": "http:\/\/www.elliston.sa.gov.au",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 20,
      "lat": -33.6383018494,
      "lon": 134.8999938965,
      "tz": "Australia\/Adelaide"
  },
  "YELR": {
      "icao": "YELR",
      "iata": "",
      "name": "Elderslie Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.6166992188,
      "lon": 151.3329925537,
      "tz": "Australia\/Sydney"
  },
  "YELS": {
      "icao": "YELS",
      "iata": "",
      "name": "Earlston Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.5962867737,
      "lon": 145.721862793,
      "tz": "Australia\/Melbourne"
  },
  "YELW": {
      "icao": "YELW",
      "iata": "",
      "name": "Eildon Weir Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.2083015442,
      "lon": 145.8329925537,
      "tz": "Australia\/Melbourne"
  },
  "YEMG": {
      "icao": "YEMG",
      "iata": "",
      "name": "Eromanga Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 166,
      "lat": -26.7000007629,
      "lon": 143.266998291,
      "tz": "Australia\/Brisbane"
  },
  "YEMJ": {
      "icao": "YEMJ",
      "iata": "",
      "name": "Emu Junction Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.6333007813,
      "lon": 132.1829986572,
      "tz": "Australia\/Adelaide"
  },
  "YEML": {
      "icao": "YEML",
      "iata": "EMD",
      "name": "Emerald Airport",
      "city": "Emerald",
      "state": "Queensland",
      "country": "AU",
      "elevation": 624,
      "lat": -23.5674991608,
      "lon": 148.179000854,
      "tz": "Australia\/Brisbane"
  },
  "YEMP": {
      "icao": "YEMP",
      "iata": "",
      "name": "Emu Park Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2567005157,
      "lon": 150.81300354,
      "tz": "Australia\/Brisbane"
  },
  "YEPL": {
      "icao": "YEPL",
      "iata": "",
      "name": "Epsilon Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.2833003998,
      "lon": 141.1999969482,
      "tz": "Australia\/Brisbane"
  },
  "YEPR": {
      "icao": "YEPR",
      "iata": "",
      "name": "Epenarra Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.4333000183,
      "lon": 135.266998291,
      "tz": "Australia\/Darwin"
  },
  "YEQO": {
      "icao": "YEQO",
      "iata": "",
      "name": "El Questro Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.0083007813,
      "lon": 127.9749984741,
      "tz": "Australia\/Perth"
  },
  "YERA": {
      "icao": "YERA",
      "iata": "",
      "name": "Errabiddy Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5,
      "lon": 117.1669998169,
      "tz": "Australia\/Perth"
  },
  "YERH": {
      "icao": "YERH",
      "iata": "",
      "name": "Earaheedy Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.6000003815,
      "lon": 121.5830001831,
      "tz": "Australia\/Perth"
  },
  "YERL": {
      "icao": "YERL",
      "iata": "",
      "name": "Erldunda Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2166996002,
      "lon": 133.25,
      "tz": "Australia\/Darwin"
  },
  "YERN": {
      "icao": "YERN",
      "iata": "ERB",
      "name": "Ernabella Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 707,
      "lat": -26.263299942,
      "lon": 132.1820068359,
      "tz": "Australia\/Adelaide"
  },
  "YERO": {
      "icao": "YERO",
      "iata": "",
      "name": "Erong Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5666999817,
      "lon": 116.6669998169,
      "tz": "Australia\/Perth"
  },
  "YERU": {
      "icao": "YERU",
      "iata": "",
      "name": "Erudina Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.4344997406,
      "lon": 139.4250030518,
      "tz": "Australia\/Adelaide"
  },
  "YESC": {
      "icao": "YESC",
      "iata": "",
      "name": "Escott Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -17.7250003815,
      "lon": 139.4170074463,
      "tz": "Australia\/Brisbane"
  },
  "YESP": {
      "icao": "YESP",
      "iata": "EPR",
      "name": "Esperance Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 470,
      "lat": -33.6843986511,
      "lon": 121.8229980469,
      "tz": "Australia\/Perth"
  },
  "YEUA": {
      "icao": "YEUA",
      "iata": "",
      "name": "Euroa Aerodrome",
      "city": "Euroa",
      "state": "Victoria",
      "country": "AU",
      "elevation": 555,
      "lat": -36.7447013855,
      "lon": 145.513000488,
      "tz": "Australia\/Melbourne"
  },
  "YEUD": {
      "icao": "YEUD",
      "iata": "",
      "name": "Eudamulla Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.4500007629,
      "lon": 115.5999984741,
      "tz": "Australia\/Perth"
  },
  "YEUL": {
      "icao": "YEUL",
      "iata": "",
      "name": "Eulalia Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.0333003998,
      "lon": 148.1750030518,
      "tz": "Australia\/Sydney"
  },
  "YEUO": {
      "icao": "YEUO",
      "iata": "",
      "name": "Eulo Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 152,
      "lat": -28.1667003632,
      "lon": 145.0420074463,
      "tz": "Australia\/Brisbane"
  },
  "YEVA": {
      "icao": "YEVA",
      "iata": "EVD",
      "name": "Eva Downs Airport",
      "city": "Eva Downs",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -18.0009994507,
      "lon": 134.863006592,
      "tz": "Australia\/Darwin"
  },
  "YEVD": {
      "icao": "YEVD",
      "iata": "EVH",
      "name": "Evans Head Aerodrome",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 20,
      "lat": -29.0932998657,
      "lon": 153.4199981689,
      "tz": "Australia\/Sydney"
  },
  "YEVP": {
      "icao": "YEVP",
      "iata": "",
      "name": "Everard Park Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.0167007446,
      "lon": 132.7169952393,
      "tz": "Australia\/Adelaide"
  },
  "YEXM": {
      "icao": "YEXM",
      "iata": "EXM",
      "name": "Exmouth Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.0333003998,
      "lon": 114.0999984741,
      "tz": "Australia\/Perth"
  },
  "YEYD": {
      "icao": "YEYD",
      "iata": "",
      "name": "Evelyn Downs Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.20470047,
      "lon": 134.4880065918,
      "tz": "Australia\/Adelaide"
  },
  "YFBS": {
      "icao": "YFBS",
      "iata": "FRB",
      "name": "Forbes Airport",
      "city": "Forbes",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 760,
      "lat": -33.3636016846,
      "lon": 147.934997559,
      "tz": "Australia\/Sydney"
  },
  "YFCK": {
      "icao": "YFCK",
      "iata": "",
      "name": "Fish Creek Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.7150001526,
      "lon": 146.0529937744,
      "tz": "Australia\/Melbourne"
  },
  "YFCU": {
      "icao": "YFCU",
      "iata": "",
      "name": "Fortescue River Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.2999992371,
      "lon": 116.1330032349,
      "tz": "Australia\/Perth"
  },
  "YFDF": {
      "icao": "YFDF",
      "iata": "KFE",
      "name": "Fortescue - Dave Forrest Aerodrome",
      "city": "Cloudbreak Village",
      "state": "Central-Sulawesi",
      "country": "AU",
      "elevation": 1555,
      "lat": 0,
      "lon": 119.429161111,
      "tz": "Asia\/Makassar"
  },
  "YFDN": {
      "icao": "YFDN",
      "iata": "",
      "name": "Federation Hsd Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 709,
      "lat": -35.0116996765,
      "lon": 147.3739929199,
      "tz": "Australia\/Sydney"
  },
  "YFFT": {
      "icao": "YFFT",
      "iata": "",
      "name": "Farrell Flat Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.8333015442,
      "lon": 138.766998291,
      "tz": "Australia\/Adelaide"
  },
  "YFIL": {
      "icao": "YFIL",
      "iata": "FLY",
      "name": "Finley Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.6666984558,
      "lon": 145.550003052,
      "tz": "Australia\/Sydney"
  },
  "YFLI": {
      "icao": "YFLI",
      "iata": "FLS",
      "name": "Flinders Island Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 10,
      "lat": -40.0917015076,
      "lon": 147.9929962158,
      "tz": "Australia\/Hobart"
  },
  "YFLO": {
      "icao": "YFLO",
      "iata": "FVL",
      "name": "Flora Valley Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.2833003998,
      "lon": 128.417007446,
      "tz": "Australia\/Perth"
  },
  "YFLS": {
      "icao": "YFLS",
      "iata": "",
      "name": "Flinders Island Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.7000007629,
      "lon": 134.516998291,
      "tz": "Australia\/Adelaide"
  },
  "YFNE": {
      "icao": "YFNE",
      "iata": "FIK",
      "name": "Finke Airport",
      "city": "Finke",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5946998596,
      "lon": 134.582992554,
      "tz": "Australia\/Darwin"
  },
  "YFOR": {
      "icao": "YFOR",
      "iata": "",
      "name": "Fortnum Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3332996368,
      "lon": 118.3669967651,
      "tz": "Australia\/Perth"
  },
  "YFRD": {
      "icao": "YFRD",
      "iata": "",
      "name": "Frome Downs Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.2166996002,
      "lon": 139.75,
      "tz": "Australia\/Adelaide"
  },
  "YFRG": {
      "icao": "YFRG",
      "iata": "",
      "name": "Fregon Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 528,
      "lat": -26.7749996185,
      "lon": 132.016998291,
      "tz": "Australia\/Adelaide"
  },
  "YFRI": {
      "icao": "YFRI",
      "iata": "",
      "name": "Friendly Beaches Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42,
      "lon": 148.2590026855,
      "tz": "Australia\/Hobart"
  },
  "YFRK": {
      "icao": "YFRK",
      "iata": "",
      "name": "Frankland Airport",
      "city": "Frankland River Grazing co",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 800,
      "lat": -34.4230003357,
      "lon": 117.0260009766,
      "tz": "Australia\/Perth"
  },
  "YFRT": {
      "icao": "YFRT",
      "iata": "FOS",
      "name": "Forrest Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 511,
      "lat": -30.8381004333,
      "lon": 128.1150054932,
      "tz": "Australia\/Perth"
  },
  "YFSA": {
      "icao": "YFSA",
      "iata": "",
      "name": "Forsayth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.5848007202,
      "lon": 143.5690002441,
      "tz": "Australia\/Brisbane"
  },
  "YFSK": {
      "icao": "YFSK",
      "iata": "",
      "name": "Fiskville Airport",
      "city": "Fiskville",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.6781143165,
      "lon": 144.220790863,
      "tz": "Australia\/Melbourne"
  },
  "YFST": {
      "icao": "YFST",
      "iata": "FOT",
      "name": "Forster (Wallis Is) Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 4,
      "lat": -32.2042007446,
      "lon": 152.4790039063,
      "tz": "Australia\/Sydney"
  },
  "YFTA": {
      "icao": "YFTA",
      "iata": "",
      "name": "Forrestania Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.5800018311,
      "lon": 119.7030029297,
      "tz": "Australia\/Perth"
  },
  "YFTN": {
      "icao": "YFTN",
      "iata": "",
      "name": "Mount Fitton Talc Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.9092006683,
      "lon": 139.4739990234,
      "tz": "Australia\/Adelaide"
  },
  "YFTZ": {
      "icao": "YFTZ",
      "iata": "FIZ",
      "name": "Fitzroy Crossing Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 368,
      "lat": -18.1819000244,
      "lon": 125.5589981079,
      "tz": "Australia\/Perth"
  },
  "YFVW": {
      "icao": "YFVW",
      "iata": "",
      "name": "Fairview Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.7890014648,
      "lon": 148.7200012207,
      "tz": "Australia\/Sydney"
  },
  "YFWY": {
      "icao": "YFWY",
      "iata": "",
      "name": "Faraway Bay Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -13.980799675,
      "lon": 127.1809997559,
      "tz": "Australia\/Perth"
  },
  "YGAD": {
      "icao": "YGAD",
      "iata": "",
      "name": "Garden Island (Military) Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 9,
      "lat": -32.2416992188,
      "lon": 115.6829986572,
      "tz": "Australia\/Perth"
  },
  "YGAH": {
      "icao": "YGAH",
      "iata": "",
      "name": "Greenbah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.1258277893,
      "lon": 149.4345703125,
      "tz": "Australia\/Sydney"
  },
  "YGAM": {
      "icao": "YGAM",
      "iata": "GBP",
      "name": "Gamboola Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -16.5499992371,
      "lon": 143.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YGAN": {
      "icao": "YGAN",
      "iata": "",
      "name": "Gan Gan Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -13.0666999817,
      "lon": 135.9499969482,
      "tz": "Australia\/Darwin"
  },
  "YGAR": {
      "icao": "YGAR",
      "iata": "",
      "name": "Gnaraloo Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 10,
      "lat": -23.7915496826,
      "lon": 113.5289382935,
      "tz": "Australia\/Perth"
  },
  "YGAW": {
      "icao": "YGAW",
      "iata": "",
      "name": "Gawler Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.6016998291,
      "lon": 138.7169952393,
      "tz": "Australia\/Adelaide"
  },
  "YGAY": {
      "icao": "YGAY",
      "iata": "GAH",
      "name": "Gayndah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 369,
      "lat": -25.6144008636,
      "lon": 151.6190032959,
      "tz": "Australia\/Brisbane"
  },
  "YGBI": {
      "icao": "YGBI",
      "iata": "GBL",
      "name": "South Goulburn Is Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 19,
      "lat": -11.6499996185,
      "lon": 133.3820037842,
      "tz": "Australia\/Darwin"
  },
  "YGBO": {
      "icao": "YGBO",
      "iata": "",
      "name": "Gabo Island Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.5666999817,
      "lon": 149.8999938965,
      "tz": "Australia\/Melbourne"
  },
  "YGBW": {
      "icao": "YGBW",
      "iata": "",
      "name": "Gunbower Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.9667015076,
      "lon": 144.3829956055,
      "tz": "Australia\/Melbourne"
  },
  "YGCR": {
      "icao": "YGCR",
      "iata": "",
      "name": "Gloucester Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.0499992371,
      "lon": 151.983001709,
      "tz": "Australia\/Sydney"
  },
  "YGDA": {
      "icao": "YGDA",
      "iata": "",
      "name": "Goodooga Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 459,
      "lat": -29.073299408,
      "lon": 147.3760070801,
      "tz": "Australia\/Sydney"
  },
  "YGDH": {
      "icao": "YGDH",
      "iata": "GUH",
      "name": "Gunnedah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 863,
      "lat": -30.9610996246,
      "lon": 150.2510070801,
      "tz": "Australia\/Sydney"
  },
  "YGDI": {
      "icao": "YGDI",
      "iata": "GOO",
      "name": "Goondiwindi Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 714,
      "lat": -28.5214004517,
      "lon": 150.3200073242,
      "tz": "Australia\/Brisbane"
  },
  "YGDN": {
      "icao": "YGDN",
      "iata": "GDD",
      "name": "Gordon Downs Airport",
      "city": "Gordon Downs",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 800,
      "lat": -18.6781005859,
      "lon": 128.5919952393,
      "tz": "Australia\/Perth"
  },
  "YGDR": {
      "icao": "YGDR",
      "iata": "",
      "name": "The Garden Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.286699295,
      "lon": 134.4400024414,
      "tz": "Australia\/Darwin"
  },
  "YGDS": {
      "icao": "YGDS",
      "iata": "GGD",
      "name": "Gregory Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 52,
      "lat": -18.625,
      "lon": 139.233001709,
      "tz": "Australia\/Brisbane"
  },
  "YGDV": {
      "icao": "YGDV",
      "iata": "",
      "name": "Galdeville Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.25,
      "lon": 142.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "YGDW": {
      "icao": "YGDW",
      "iata": "GTS",
      "name": "Granite Downs Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 337,
      "lat": -26.948299408,
      "lon": 133.6069946289,
      "tz": "Australia\/Adelaide"
  },
  "YGEL": {
      "icao": "YGEL",
      "iata": "GET",
      "name": "Geraldton Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 121,
      "lat": -28.7961006165,
      "lon": 114.7070007324,
      "tz": "Australia\/Perth"
  },
  "YGFN": {
      "icao": "YGFN",
      "iata": "GFN",
      "name": "Grafton Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 110,
      "lat": -29.7593994141,
      "lon": 153.0299987793,
      "tz": "Australia\/Sydney"
  },
  "YGGE": {
      "icao": "YGGE",
      "iata": "",
      "name": "Golden Grove Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.75,
      "lon": 116.9499969482,
      "tz": "Australia\/Perth"
  },
  "YGGI": {
      "icao": "YGGI",
      "iata": "",
      "name": "Goolgowi Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34,
      "lon": 145.7169952393,
      "tz": "Australia\/Sydney"
  },
  "YGGL": {
      "icao": "YGGL",
      "iata": "",
      "name": "Glen Garland Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.8667001724,
      "lon": 143.2830047607,
      "tz": "Australia\/Brisbane"
  },
  "YGGO": {
      "icao": "YGGO",
      "iata": "",
      "name": "Goonoo Goonoo Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.3166999817,
      "lon": 150.9170074463,
      "tz": "Australia\/Sydney"
  },
  "YGGS": {
      "icao": "YGGS",
      "iata": "",
      "name": "Gregory Springs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.7166996002,
      "lon": 144.3829956055,
      "tz": "Australia\/Brisbane"
  },
  "YGHG": {
      "icao": "YGHG",
      "iata": "",
      "name": "Glen Harding Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.2667007446,
      "lon": 145.1329956055,
      "tz": "Australia\/Brisbane"
  },
  "YGIA": {
      "icao": "YGIA",
      "iata": "GBW",
      "name": "Ginbata",
      "city": "Ginbata",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 509,
      "lat": -22.578666,
      "lon": 120.039409,
      "tz": "Australia\/Perth"
  },
  "YGIB": {
      "icao": "YGIB",
      "iata": "GBV",
      "name": "Gibb River Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 509,
      "lat": -16.4232997894,
      "lon": 126.4449996948,
      "tz": "Australia\/Perth"
  },
  "YGID": {
      "icao": "YGID",
      "iata": "",
      "name": "Gidgee Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.2700004578,
      "lon": 119.4049987793,
      "tz": "Australia\/Perth"
  },
  "YGIF": {
      "icao": "YGIF",
      "iata": "",
      "name": "Gifford Creek Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.0499992371,
      "lon": 116.2170028687,
      "tz": "Australia\/Perth"
  },
  "YGIG": {
      "icao": "YGIG",
      "iata": "",
      "name": "RAAF Gingin",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 247,
      "lat": -31.4652996063,
      "lon": 115.8629989624,
      "tz": "Australia\/Perth"
  },
  "YGIL": {
      "icao": "YGIL",
      "iata": "",
      "name": "Gilgandra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 320,
      "lat": -31.7033004761,
      "lon": 148.6380004883,
      "tz": "Australia\/Sydney"
  },
  "YGIR": {
      "icao": "YGIR",
      "iata": "",
      "name": "Giralia Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.6833000183,
      "lon": 114.3669967651,
      "tz": "Australia\/Perth"
  },
  "YGKL": {
      "icao": "YGKL",
      "iata": "GKL",
      "name": "Great Keppel Is Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 21,
      "lat": -23.1833000183,
      "lon": 150.9420013428,
      "tz": "Australia\/Brisbane"
  },
  "YGLA": {
      "icao": "YGLA",
      "iata": "GLT",
      "name": "Gladstone Airport",
      "city": "Gladstone",
      "state": "Queensland",
      "country": "AU",
      "elevation": 64,
      "lat": -23.8696994781,
      "lon": 151.223007202,
      "tz": "Australia\/Brisbane"
  },
  "YGLB": {
      "icao": "YGLB",
      "iata": "GUL",
      "name": "Goulburn Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2141,
      "lat": -34.8102989197,
      "lon": 149.7259979248,
      "tz": "Australia\/Sydney"
  },
  "YGLD": {
      "icao": "YGLD",
      "iata": "",
      "name": "Glendambo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9666996002,
      "lon": 135.7749938965,
      "tz": "Australia\/Adelaide"
  },
  "YGLE": {
      "icao": "YGLE",
      "iata": "GLG",
      "name": "Glengyle Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 91,
      "lat": -24.8083000183,
      "lon": 139.6000061035,
      "tz": "Australia\/Brisbane"
  },
  "YGLG": {
      "icao": "YGLG",
      "iata": "GEX",
      "name": "Geelong Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 43,
      "lat": -38.2249984741,
      "lon": 144.3329925537,
      "tz": "Australia\/Melbourne"
  },
  "YGLI": {
      "icao": "YGLI",
      "iata": "GLI",
      "name": "Glen Innes Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3433,
      "lat": -29.6749992371,
      "lon": 151.6889953613,
      "tz": "Australia\/Sydney"
  },
  "YGLN": {
      "icao": "YGLN",
      "iata": "",
      "name": "Glencoe Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.6166992188,
      "lon": 148.1999969482,
      "tz": "Australia\/Sydney"
  },
  "YGLO": {
      "icao": "YGLO",
      "iata": "GLM",
      "name": "Glenormiston Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 158,
      "lat": -22.888299942,
      "lon": 138.8249969482,
      "tz": "Australia\/Brisbane"
  },
  "YGLP": {
      "icao": "YGLP",
      "iata": "",
      "name": "Gallipolli Airport",
      "city": "Nicholson",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 865,
      "lat": -19.14181,
      "lon": 137.87412,
      "tz": "Australia\/Darwin"
  },
  "YGLS": {
      "icao": "YGLS",
      "iata": "",
      "name": "Giles Airport",
      "city": "Warakurna",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 579,
      "lat": -25.0436992645,
      "lon": 128.296005249,
      "tz": "Australia\/Perth"
  },
  "YGLY": {
      "icao": "YGLY",
      "iata": "",
      "name": "Glenayle Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2833003998,
      "lon": 122.0500030518,
      "tz": "Australia\/Perth"
  },
  "YGMD": {
      "icao": "YGMD",
      "iata": "",
      "name": "Goomadeer Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.1000003815,
      "lon": 133.6670074463,
      "tz": "Australia\/Darwin"
  },
  "YGMP": {
      "icao": "YGMP",
      "iata": "",
      "name": "Grampians Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.0499992371,
      "lon": 142.2830047607,
      "tz": "Australia\/Melbourne"
  },
  "YGNA": {
      "icao": "YGNA",
      "iata": "",
      "name": "Granada Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1000003815,
      "lon": 140.3670043945,
      "tz": "Australia\/Brisbane"
  },
  "YGNF": {
      "icao": "YGNF",
      "iata": "",
      "name": "Grenfell Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34,
      "lon": 148.1329956055,
      "tz": "Australia\/Sydney"
  },
  "YGNV": {
      "icao": "YGNV",
      "iata": "GVP",
      "name": "Greenvale Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.9832992554,
      "lon": 145.1170043945,
      "tz": "Australia\/Brisbane"
  },
  "YGNW": {
      "icao": "YGNW",
      "iata": "",
      "name": "Gnowangerup Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.9832992554,
      "lon": 118.0329971313,
      "tz": "Australia\/Perth"
  },
  "YGOM": {
      "icao": "YGOM",
      "iata": "",
      "name": "Goomalling Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.3666992188,
      "lon": 116.8830032349,
      "tz": "Australia\/Perth"
  },
  "YGOV": {
      "icao": "YGOV",
      "iata": "",
      "name": "Gabo Is aAd sSte Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.5699996948,
      "lon": 149.9179992676,
      "tz": "Australia\/Melbourne"
  },
  "YGPI": {
      "icao": "YGPI",
      "iata": "",
      "name": "Giles Point Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2667007446,
      "lon": 119.1669998169,
      "tz": "Australia\/Perth"
  },
  "YGPR": {
      "icao": "YGPR",
      "iata": "",
      "name": "Gunpowder Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.7000007629,
      "lon": 139.3670043945,
      "tz": "Australia\/Brisbane"
  },
  "YGPT": {
      "icao": "YGPT",
      "iata": "GPN",
      "name": "Garden Point Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 90,
      "lat": -11.4025001526,
      "lon": 130.4219970703,
      "tz": "Australia\/Darwin"
  },
  "YGRD": {
      "icao": "YGRD",
      "iata": "",
      "name": "Grovedale Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.2167015076,
      "lon": 144.3329925537,
      "tz": "Australia\/Melbourne"
  },
  "YGRL": {
      "icao": "YGRL",
      "iata": "",
      "name": "Great Lakes Vi Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 184,
      "lat": -37.8422012329,
      "lon": 148,
      "tz": "Australia\/Melbourne"
  },
  "YGRU": {
      "icao": "YGRU",
      "iata": "",
      "name": "Glen Ruth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.0832996368,
      "lon": 145.3999938965,
      "tz": "Australia\/Brisbane"
  },
  "YGSC": {
      "icao": "YGSC",
      "iata": "GSC",
      "name": "Gascoyne Junction Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.0499992371,
      "lon": 115.1999969482,
      "tz": "Australia\/Perth"
  },
  "YGTE": {
      "icao": "YGTE",
      "iata": "GTE",
      "name": "Groote Eylandt Airport",
      "city": "Groote Eylandt",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 53,
      "lat": -13.9750003815,
      "lon": 136.460006714,
      "tz": "Australia\/Darwin"
  },
  "YGTH": {
      "icao": "YGTH",
      "iata": "GFF",
      "name": "Griffith Airport",
      "city": "Griffith",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 439,
      "lat": -34.2508010864,
      "lon": 146.067001343,
      "tz": "Australia\/Sydney"
  },
  "YGTN": {
      "icao": "YGTN",
      "iata": "GTT",
      "name": "Georgetown Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 995,
      "lat": -18.3050003052,
      "lon": 143.5299987793,
      "tz": "Australia\/Brisbane"
  },
  "YGTO": {
      "icao": "YGTO",
      "iata": "GEE",
      "name": "Georgetown (Tas) Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.0800018311,
      "lon": 146.8399963379,
      "tz": "Australia\/Hobart"
  },
  "YGTP": {
      "icao": "YGTP",
      "iata": "",
      "name": "Greenthorpe Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -33.9832992554,
      "lon": 148.4170074463,
      "tz": "Australia\/Sydney"
  },
  "YGUL": {
      "icao": "YGUL",
      "iata": "",
      "name": "Gullewa Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.6333007813,
      "lon": 116.2829971313,
      "tz": "Australia\/Perth"
  },
  "YGUW": {
      "icao": "YGUW",
      "iata": "",
      "name": "Gunnawarra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.9500007629,
      "lon": 145.1670074463,
      "tz": "Australia\/Brisbane"
  },
  "YGVE": {
      "icao": "YGVE",
      "iata": "",
      "name": "Glendevie Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -43.2299995422,
      "lon": 147.0119934082,
      "tz": "Australia\/Hobart"
  },
  "YGWA": {
      "icao": "YGWA",
      "iata": "",
      "name": "Goolwa Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 32,
      "lat": -35.4817008972,
      "lon": 138.7519989014,
      "tz": "Australia\/Adelaide"
  },
  "YGWD": {
      "icao": "YGWD",
      "iata": "",
      "name": "Galway Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1833000183,
      "lon": 142.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YGYM": {
      "icao": "YGYM",
      "iata": "GYP",
      "name": "Gympie Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 260,
      "lat": -26.2828006744,
      "lon": 152.7019958496,
      "tz": "Australia\/Brisbane"
  },
  "YHAA": {
      "icao": "YHAA",
      "iata": "",
      "name": "Haasts Bluff Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 692,
      "lat": -23.4549999237,
      "lon": 131.8529968262,
      "tz": "Australia\/Darwin"
  },
  "YHAB": {
      "icao": "YHAB",
      "iata": "",
      "name": "Hideaway Bay Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1166992188,
      "lon": 148.4329986572,
      "tz": "Australia\/Brisbane"
  },
  "YHAE": {
      "icao": "YHAE",
      "iata": "",
      "name": "Harden Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.5574989319,
      "lon": 148.3880004883,
      "tz": "Australia\/Sydney"
  },
  "YHAG": {
      "icao": "YHAG",
      "iata": "",
      "name": "Haig Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31,
      "lon": 126.0830001831,
      "tz": "Australia\/Perth"
  },
  "YHAW": {
      "icao": "YHAW",
      "iata": "HWK",
      "name": "Wilpena Pound Airport",
      "city": "Hawker",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 321,
      "lat": -31.8559074402,
      "lon": 138.4680786133,
      "tz": "Australia\/Adelaide"
  },
  "YHAY": {
      "icao": "YHAY",
      "iata": "HXX",
      "name": "Hay Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 305,
      "lat": -34.5313987732,
      "lon": 144.8300018311,
      "tz": "Australia\/Sydney"
  },
  "YHBA": {
      "icao": "YHBA",
      "iata": "HVB",
      "name": "Hervey Bay Airport",
      "city": "Hervey Bay",
      "state": "Queensland",
      "country": "AU",
      "elevation": 60,
      "lat": -25.3188991547,
      "lon": 152.880004883,
      "tz": "Australia\/Brisbane"
  },
  "YHBK": {
      "icao": "YHBK",
      "iata": "",
      "name": "Holbrook Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 267,
      "lat": -35.6833000183,
      "lon": 147.3170013428,
      "tz": "Australia\/Sydney"
  },
  "YHBR": {
      "icao": "YHBR",
      "iata": "HUB",
      "name": "Humbert River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.4896697998,
      "lon": 130.630279541,
      "tz": "Australia\/Darwin"
  },
  "YHBY": {
      "icao": "YHBY",
      "iata": "HRY",
      "name": "Henbury Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.5499992371,
      "lon": 133.2169952393,
      "tz": "Australia\/Darwin"
  },
  "YHCT": {
      "icao": "YHCT",
      "iata": "",
      "name": "Heathcote Emergency Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3537,
      "lat": -31.2402992249,
      "lon": 151.4320068359,
      "tz": "Australia\/Sydney"
  },
  "YHDD": {
      "icao": "YHDD",
      "iata": "",
      "name": "Hodgson Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -15.2216997147,
      "lon": 134.0760040283,
      "tz": "Australia\/Darwin"
  },
  "YHDY": {
      "icao": "YHDY",
      "iata": "HIP",
      "name": "Headingly Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.3332996368,
      "lon": 138.2830047607,
      "tz": "Australia\/Brisbane"
  },
  "YHEC": {
      "icao": "YHEC",
      "iata": "",
      "name": "Heck Field Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7675,
      "lon": 153.339444,
      "tz": "Australia\/Brisbane"
  },
  "YHEG": {
      "icao": "YHEG",
      "iata": "",
      "name": "Hells Gate Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.4666996002,
      "lon": 138.3670043945,
      "tz": "Australia\/Brisbane"
  },
  "YHEW": {
      "icao": "YHEW",
      "iata": "",
      "name": "Hedlow Airport",
      "city": "Mulara",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2231006622,
      "lon": 150.604995728,
      "tz": "Australia\/Brisbane"
  },
  "YHGR": {
      "icao": "YHGR",
      "iata": "",
      "name": "Hugh River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.4167003632,
      "lon": 133.6999969482,
      "tz": "Australia\/Darwin"
  },
  "YHGS": {
      "icao": "YHGS",
      "iata": "",
      "name": "Hughes Siding Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.7166996002,
      "lon": 129.5149993896,
      "tz": "Australia\/Adelaide"
  },
  "YHHY": {
      "icao": "YHHY",
      "iata": "HIG",
      "name": "Highbury Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -16.4333000183,
      "lon": 143.1499938965,
      "tz": "Australia\/Brisbane"
  },
  "YHID": {
      "icao": "YHID",
      "iata": "HID",
      "name": "Horn Island Airport",
      "city": "Horn Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 43,
      "lat": -10.586400032,
      "lon": 142.289993286,
      "tz": "Australia\/Brisbane"
  },
  "YHIL": {
      "icao": "YHIL",
      "iata": "HLL",
      "name": "Hillside Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.75,
      "lon": 119.4169998169,
      "tz": "Australia\/Perth"
  },
  "YHKT": {
      "icao": "YHKT",
      "iata": "",
      "name": "Huckitta Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.9433002472,
      "lon": 135.4470062256,
      "tz": "Australia\/Darwin"
  },
  "YHLC": {
      "icao": "YHLC",
      "iata": "HCQ",
      "name": "Halls Creek Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1346,
      "lat": -18.2339000702,
      "lon": 127.6699981689,
      "tz": "Australia\/Perth"
  },
  "YHLG": {
      "icao": "YHLG",
      "iata": "",
      "name": "Hillgrove Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.6424999237,
      "lon": 145.7969970703,
      "tz": "Australia\/Brisbane"
  },
  "YHLM": {
      "icao": "YHLM",
      "iata": "",
      "name": "Hillman Farm Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.2639007568,
      "lon": 116.8150024414,
      "tz": "Australia\/Perth"
  },
  "YHLS": {
      "icao": "YHLS",
      "iata": "",
      "name": "Hillston Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 403,
      "lat": -33.4933013916,
      "lon": 145.5229949951,
      "tz": "Australia\/Sydney"
  },
  "YHMB": {
      "icao": "YHMB",
      "iata": "HMG",
      "name": "Hermannsburg Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 593,
      "lat": -23.9300003052,
      "lon": 132.8049926758,
      "tz": "Australia\/Darwin"
  },
  "YHML": {
      "icao": "YHML",
      "iata": "HLT",
      "name": "Hamilton Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 803,
      "lat": -37.6488990784,
      "lon": 142.0650024414,
      "tz": "Australia\/Melbourne"
  },
  "YHMT": {
      "icao": "YHMT",
      "iata": "",
      "name": "Hamilton Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.7199993134,
      "lon": 135.0729980469,
      "tz": "Australia\/Adelaide"
  },
  "YHOA": {
      "icao": "YHOA",
      "iata": "",
      "name": "Howard Island Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.095000267,
      "lon": 135.3619995117,
      "tz": "Australia\/Darwin"
  },
  "YHOO": {
      "icao": "YHOO",
      "iata": "HOK",
      "name": "Hooker Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 320,
      "lat": -18.3367004395,
      "lon": 130.6380004883,
      "tz": "Australia\/Darwin"
  },
  "YHOT": {
      "icao": "YHOT",
      "iata": "MHU",
      "name": "Mount Hotham Airport",
      "city": "Mount Hotham",
      "state": "Victoria",
      "country": "AU",
      "elevation": 4260,
      "lat": -37.0475006104,
      "lon": 147.333999634,
      "tz": "Australia\/Melbourne"
  },
  "YHOV": {
      "icao": "YHOV",
      "iata": "",
      "name": "Hodgson River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -15.516699791,
      "lon": 134.0670013428,
      "tz": "Australia\/Darwin"
  },
  "YHOY": {
      "icao": "YHOY",
      "iata": "",
      "name": "Hollins Bay Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.2667007446,
      "lon": 150.0330047607,
      "tz": "Australia\/Brisbane"
  },
  "YHPE": {
      "icao": "YHPE",
      "iata": "",
      "name": "Hopetoun Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.9000015259,
      "lon": 120.1579971313,
      "tz": "Australia\/Perth"
  },
  "YHPN": {
      "icao": "YHPN",
      "iata": "HTU",
      "name": "Hopetoun Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 256,
      "lat": -35.7153015137,
      "lon": 142.3600006104,
      "tz": "Australia\/Melbourne"
  },
  "YHRD": {
      "icao": "YHRD",
      "iata": "",
      "name": "Hungerford Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.9832992554,
      "lon": 144.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "YHRG": {
      "icao": "YHRG",
      "iata": "",
      "name": "Haddon Rig Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.4687995911,
      "lon": 147.8950042725,
      "tz": "Australia\/Sydney"
  },
  "YHSB": {
      "icao": "YHSB",
      "iata": "",
      "name": "Horseshoe Bend Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2166996002,
      "lon": 134.266998291,
      "tz": "Australia\/Darwin"
  },
  "YHSL": {
      "icao": "YHSL",
      "iata": "",
      "name": "Horseshoe Lights Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3500003815,
      "lon": 118.6169967651,
      "tz": "Australia\/Perth"
  },
  "YHSM": {
      "icao": "YHSM",
      "iata": "HSM",
      "name": "Horsham Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 445,
      "lat": -36.6697006226,
      "lon": 142.1730041504,
      "tz": "Australia\/Melbourne"
  },
  "YHTA": {
      "icao": "YHTA",
      "iata": "",
      "name": "Hiltaba Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.1425018311,
      "lon": 135.0989990234,
      "tz": "Australia\/Adelaide"
  },
  "YHTL": {
      "icao": "YHTL",
      "iata": "HAT",
      "name": "Heathlands Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -11.75,
      "lon": 142.5829925537,
      "tz": "Australia\/Brisbane"
  },
  "YHTN": {
      "icao": "YHTN",
      "iata": "",
      "name": "Herberton Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.4333000183,
      "lon": 145.3999938965,
      "tz": "Australia\/Brisbane"
  },
  "YHTR": {
      "icao": "YHTR",
      "iata": "",
      "name": "Hunter Island Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -40.5167007446,
      "lon": 144.733001709,
      "tz": "Australia\/Hobart"
  },
  "YHTS": {
      "icao": "YHTS",
      "iata": "",
      "name": "Harts Range Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.9850006104,
      "lon": 134.9179992676,
      "tz": "Australia\/Darwin"
  },
  "YHUG": {
      "icao": "YHUG",
      "iata": "HGD",
      "name": "Hughenden Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1043,
      "lat": -20.8150005341,
      "lon": 144.2250061035,
      "tz": "Australia\/Brisbane"
  },
  "YHVH": {
      "icao": "YHVH",
      "iata": "",
      "name": "Harvest Home Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.6833000183,
      "lon": 146.6499938965,
      "tz": "Australia\/Brisbane"
  },
  "YHYD": {
      "icao": "YHYD",
      "iata": "",
      "name": "Hyden Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4333000183,
      "lon": 118.8669967651,
      "tz": "Australia\/Perth"
  },
  "YIDK": {
      "icao": "YIDK",
      "iata": "IDK",
      "name": "Indulkana Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 401,
      "lat": -26.9666996002,
      "lon": 133.3249969482,
      "tz": "Australia\/Adelaide"
  },
  "YIDR": {
      "icao": "YIDR",
      "iata": "",
      "name": "Idracowra Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.0632991791,
      "lon": 133.733001709,
      "tz": "Australia\/Darwin"
  },
  "YIFL": {
      "icao": "YIFL",
      "iata": "IFL",
      "name": "Innisfail Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 46,
      "lat": -17.5594005585,
      "lon": 146.0119934082,
      "tz": "Australia\/Brisbane"
  },
  "YIFY": {
      "icao": "YIFY",
      "iata": "IFF",
      "name": "Iffley Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 43,
      "lat": -18.8999996185,
      "lon": 141.2169952393,
      "tz": "Australia\/Brisbane"
  },
  "YIGM": {
      "icao": "YIGM",
      "iata": "IGH",
      "name": "Ingham Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 49,
      "lat": -18.6606006622,
      "lon": 146.1519927979,
      "tz": "Australia\/Brisbane"
  },
  "YIGR": {
      "icao": "YIGR",
      "iata": "",
      "name": "Ingomar Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.6333007813,
      "lon": 134.7929992676,
      "tz": "Australia\/Adelaide"
  },
  "YIKL": {
      "icao": "YIKL",
      "iata": "",
      "name": "Baikal Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.763299942,
      "lon": 136.1629943848,
      "tz": "Australia\/Darwin"
  },
  "YIKM": {
      "icao": "YIKM",
      "iata": "IKP",
      "name": "Inkerman Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 6,
      "lat": -16.2749996185,
      "lon": 141.4420013428,
      "tz": "Australia\/Brisbane"
  },
  "YILA": {
      "icao": "YILA",
      "iata": "",
      "name": "Milawa Vineyard Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.4500007629,
      "lon": 146.4329986572,
      "tz": "Australia\/Melbourne"
  },
  "YILT": {
      "icao": "YILT",
      "iata": "",
      "name": "Milton Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.3208007813,
      "lon": 150.4389953613,
      "tz": "Australia\/Sydney"
  },
  "YILW": {
      "icao": "YILW",
      "iata": "",
      "name": "Inglewood Airport",
      "city": "Inglewood",
      "state": "Queensland",
      "country": "AU",
      "elevation": 840,
      "lat": -28.4167003632,
      "lon": 151.0829925537,
      "tz": "Australia\/Brisbane"
  },
  "YIMA": {
      "icao": "YIMA",
      "iata": "",
      "name": "Imanpa Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1333007813,
      "lon": 132.5700073242,
      "tz": "Australia\/Darwin"
  },
  "YIMB": {
      "icao": "YIMB",
      "iata": "",
      "name": "Kimba Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 232,
      "lat": -33.0999984741,
      "lon": 136.4600067139,
      "tz": "Australia\/Adelaide"
  },
  "YIMP": {
      "icao": "YIMP",
      "iata": "",
      "name": "Impadna Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1499996185,
      "lon": 133.5829925537,
      "tz": "Australia\/Darwin"
  },
  "YIMT": {
      "icao": "YIMT",
      "iata": "",
      "name": "Innamincka Township Airport",
      "city": "Innamincka Township",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7416992188,
      "lon": 140.7449951172,
      "tz": "Australia\/Adelaide"
  },
  "YING": {
      "icao": "YING",
      "iata": "",
      "name": "Ingelara Airport",
      "city": "Rewan",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.997027028,
      "lon": 148.333282471,
      "tz": "Australia\/Brisbane"
  },
  "YINJ": {
      "icao": "YINJ",
      "iata": "INJ",
      "name": "Injune Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 401,
      "lat": -25.8500003815,
      "lon": 148.5330047607,
      "tz": "Australia\/Brisbane"
  },
  "YINN": {
      "icao": "YINN",
      "iata": "INM",
      "name": "Innamincka Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 54,
      "lat": -27.7000007629,
      "lon": 140.733001709,
      "tz": "Australia\/Adelaide"
  },
  "YINO": {
      "icao": "YINO",
      "iata": "",
      "name": "Inverell North Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.7667007446,
      "lon": 151.1670074463,
      "tz": "Australia\/Sydney"
  },
  "YINV": {
      "icao": "YINV",
      "iata": "",
      "name": "Inverleigh Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.0167007446,
      "lon": 140.5670013428,
      "tz": "Australia\/Brisbane"
  },
  "YINW": {
      "icao": "YINW",
      "iata": "IVW",
      "name": "Inverway Airport",
      "city": "Inverway",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.8411006927,
      "lon": 129.643005371,
      "tz": "Australia\/Darwin"
  },
  "YISD": {
      "icao": "YISD",
      "iata": "",
      "name": "Isis Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2166996002,
      "lon": 144.6170043945,
      "tz": "Australia\/Brisbane"
  },
  "YISF": {
      "icao": "YISF",
      "iata": "ISI",
      "name": "Isisford Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 694,
      "lat": -24.2583007813,
      "lon": 144.4250030518,
      "tz": "Australia\/Brisbane"
  },
  "YISV": {
      "icao": "YISV",
      "iata": "",
      "name": "Innesvale Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -15.3832998276,
      "lon": 131.266998291,
      "tz": "Australia\/Darwin"
  },
  "YITT": {
      "icao": "YITT",
      "iata": "",
      "name": "Mitta Mitta Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 820,
      "lat": -36.5138787416,
      "lon": 147.359018326,
      "tz": "Australia\/Melbourne"
  },
  "YIVL": {
      "icao": "YIVL",
      "iata": "IVR",
      "name": "Inverell Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2667,
      "lat": -29.888299942,
      "lon": 151.1439971924,
      "tz": "Australia\/Sydney"
  },
  "YIVO": {
      "icao": "YIVO",
      "iata": "",
      "name": "Ivanhoe Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 330,
      "lat": -32.8833007813,
      "lon": 144.3099975586,
      "tz": "Australia\/Sydney"
  },
  "YJAB": {
      "icao": "YJAB",
      "iata": "JAB",
      "name": "Jabiru Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 85,
      "lat": -12.6583003998,
      "lon": 132.8930053711,
      "tz": "Australia\/Darwin"
  },
  "YJAK": {
      "icao": "YJAK",
      "iata": "",
      "name": "Jackson Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 106,
      "lat": -27.638299942,
      "lon": 142.4080047607,
      "tz": "Australia\/Brisbane"
  },
  "YJAM": {
      "icao": "YJAM",
      "iata": "",
      "name": "Jameson Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.8500003815,
      "lon": 127.6829986572,
      "tz": "Australia\/Perth"
  },
  "YJBY": {
      "icao": "YJBY",
      "iata": "",
      "name": "Jervis Bay Airport",
      "city": "Jervis Bay Territory",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 200,
      "lat": -35.1472015381,
      "lon": 150.6970062256,
      "tz": "Australia\/Sydney"
  },
  "YJCO": {
      "icao": "YJCO",
      "iata": "",
      "name": "Jericho Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.3666992188,
      "lon": 147.3170013428,
      "tz": "Australia\/Hobart"
  },
  "YJCW": {
      "icao": "YJCW",
      "iata": "",
      "name": "Jacobs Well Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7616996765,
      "lon": 153.3350067139,
      "tz": "Australia\/Brisbane"
  },
  "YJDA": {
      "icao": "YJDA",
      "iata": "JUN",
      "name": "Jundah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 145,
      "lat": -24.8416996002,
      "lon": 143.0579986572,
      "tz": "Australia\/Brisbane"
  },
  "YJEM": {
      "icao": "YJEM",
      "iata": "",
      "name": "Jemalong Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4099998474,
      "lon": 147.6699981689,
      "tz": "Australia\/Sydney"
  },
  "YJER": {
      "icao": "YJER",
      "iata": "",
      "name": "Jerilderie Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 110,
      "lat": -35.3699989319,
      "lon": 145.7250061035,
      "tz": "Australia\/Sydney"
  },
  "YJEY": {
      "icao": "YJEY",
      "iata": "",
      "name": "Jeedamya Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.3999996185,
      "lon": 121.266998291,
      "tz": "Australia\/Perth"
  },
  "YJGP": {
      "icao": "YJGP",
      "iata": "",
      "name": "Jerramungup Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.9000015259,
      "lon": 118.9000015259,
      "tz": "Australia\/Perth"
  },
  "YJIG": {
      "icao": "YJIG",
      "iata": "",
      "name": "Jiggalong Mission Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.3666992188,
      "lon": 120.7829971313,
      "tz": "Australia\/Perth"
  },
  "YJIN": {
      "icao": "YJIN",
      "iata": "QJD",
      "name": "Jindabyne Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1036,
      "lat": -36.426700592,
      "lon": 148.6020050049,
      "tz": "Australia\/Sydney"
  },
  "YJLC": {
      "icao": "YJLC",
      "iata": "JCK",
      "name": "Julia Creek Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 404,
      "lat": -20.6683006287,
      "lon": 141.7230072021,
      "tz": "Australia\/Brisbane"
  },
  "YJNB": {
      "icao": "YJNB",
      "iata": "JUR",
      "name": "Jurien Bay Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 15,
      "lat": -30.2999992371,
      "lon": 115.0329971313,
      "tz": "Australia\/Perth"
  },
  "YJNK": {
      "icao": "YJNK",
      "iata": "",
      "name": "Jinka Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.9500007629,
      "lon": 135.733001709,
      "tz": "Australia\/Darwin"
  },
  "YJNY": {
      "icao": "YJNY",
      "iata": "",
      "name": "Jonroy Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -16.5,
      "lon": 144.3999938965,
      "tz": "Australia\/Brisbane"
  },
  "YJRO": {
      "icao": "YJRO",
      "iata": "",
      "name": "Jericho Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.5832996368,
      "lon": 146.1670074463,
      "tz": "Australia\/Brisbane"
  },
  "YJST": {
      "icao": "YJST",
      "iata": "",
      "name": "Hubert Wilkins Airstrip",
      "city": "Jamestown",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.1920845053,
      "lon": 138.615531921,
      "tz": "Australia\/Adelaide"
  },
  "YJUK": {
      "icao": "YJUK",
      "iata": "",
      "name": "Tjukurla Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.3707103729,
      "lon": 128.7393341064,
      "tz": "Australia\/Perth"
  },
  "YJUN": {
      "icao": "YJUN",
      "iata": "",
      "name": "Jundee Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 562,
      "lat": -26.4216995239,
      "lon": 120.577003479,
      "tz": "Australia\/Perth"
  },
  "YJVM": {
      "icao": "YJVM",
      "iata": "",
      "name": "Jervois Mine Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.6333007813,
      "lon": 136.266998291,
      "tz": "Australia\/Darwin"
  },
  "YJVS": {
      "icao": "YJVS",
      "iata": "",
      "name": "Jervois Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 331,
      "lat": -22.9132995605,
      "lon": 136.1199951172,
      "tz": "Australia\/Darwin"
  },
  "YJWB": {
      "icao": "YJWB",
      "iata": "",
      "name": "Jowalbinna Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.733300209,
      "lon": 144.266998291,
      "tz": "Australia\/Brisbane"
  },
  "YKAE": {
      "icao": "YKAE",
      "iata": "",
      "name": "Kalannie Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.3666992188,
      "lon": 117.1330032349,
      "tz": "Australia\/Perth"
  },
  "YKAJ": {
      "icao": "YKAJ",
      "iata": "",
      "name": "Kajabbi Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.0333003998,
      "lon": 140.0330047607,
      "tz": "Australia\/Brisbane"
  },
  "YKAL": {
      "icao": "YKAL",
      "iata": "UBU",
      "name": "Kalumburu Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 29,
      "lat": -14.2882995605,
      "lon": 126.6320037842,
      "tz": "Australia\/Perth"
  },
  "YKAN": {
      "icao": "YKAN",
      "iata": "",
      "name": "Kanandah Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.8950004578,
      "lon": 124.8619995117,
      "tz": "Australia\/Perth"
  },
  "YKAP": {
      "icao": "YKAP",
      "iata": "",
      "name": "Kapunda Airport",
      "city": "Kapunda",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 1200,
      "lat": -34.25,
      "lon": 138.9170074463,
      "tz": "Australia\/Adelaide"
  },
  "YKAT": {
      "icao": "YKAT",
      "iata": "",
      "name": "Katoomba Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1000,
      "lat": -33.6683006287,
      "lon": 150.3229980469,
      "tz": "Australia\/Sydney"
  },
  "YKAY": {
      "icao": "YKAY",
      "iata": "",
      "name": "Kayrunnera Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.6833000183,
      "lon": 142.5330047607,
      "tz": "Australia\/Sydney"
  },
  "YKBG": {
      "icao": "YKBG",
      "iata": "",
      "name": "Koolyanobbing Range Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.8405990601,
      "lon": 119.5289993286,
      "tz": "Australia\/Perth"
  },
  "YKBL": {
      "icao": "YKBL",
      "iata": "KDB",
      "name": "Kambalda Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.1833000183,
      "lon": 121.5999984741,
      "tz": "Australia\/Perth"
  },
  "YKBN": {
      "icao": "YKBN",
      "iata": "",
      "name": "Kooralbyn Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 94,
      "lat": -28.0900001526,
      "lon": 152.8450012207,
      "tz": "Australia\/Brisbane"
  },
  "YKBR": {
      "icao": "YKBR",
      "iata": "KAX",
      "name": "Kalbarri Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 157,
      "lat": -27.6900005341,
      "lon": 114.2620010376,
      "tz": "Australia\/Perth"
  },
  "YKBY": {
      "icao": "YKBY",
      "iata": "KBY",
      "name": "Streaky Bay Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 69,
      "lat": -32.8358001709,
      "lon": 134.2929992676,
      "tz": "Australia\/Adelaide"
  },
  "YKCA": {
      "icao": "YKCA",
      "iata": "KBJ",
      "name": "Kings Canyon Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2600002289,
      "lon": 131.4900054932,
      "tz": "Australia\/Darwin"
  },
  "YKCK": {
      "icao": "YKCK",
      "iata": "",
      "name": "Killiecrankie Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -39.8488006592,
      "lon": 147.858001709,
      "tz": "Australia\/Hobart"
  },
  "YKCS": {
      "icao": "YKCS",
      "iata": "KCS",
      "name": "Kings Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 615,
      "lat": -24.4232997894,
      "lon": 131.8350067139,
      "tz": "Australia\/Darwin"
  },
  "YKDI": {
      "icao": "YKDI",
      "iata": "",
      "name": "Kadina Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 42,
      "lat": -33.9766998291,
      "lon": 137.6600036621,
      "tz": "Australia\/Adelaide"
  },
  "YKDL": {
      "icao": "YKDL",
      "iata": "",
      "name": "Kondoolka Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.0238990784,
      "lon": 134.8609924316,
      "tz": "Australia\/Adelaide"
  },
  "YKDM": {
      "icao": "YKDM",
      "iata": "",
      "name": "Kidman Springs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 88,
      "lat": -16.1166992188,
      "lon": 130.9530029297,
      "tz": "Australia\/Darwin"
  },
  "YKDN": {
      "icao": "YKDN",
      "iata": "",
      "name": "Kondinin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4667015076,
      "lon": 118.2829971313,
      "tz": "Australia\/Perth"
  },
  "YKEB": {
      "icao": "YKEB",
      "iata": "",
      "name": "Kellerberrin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.6667003632,
      "lon": 117.733001709,
      "tz": "Australia\/Perth"
  },
  "YKEL": {
      "icao": "YKEL",
      "iata": "",
      "name": "Kelvin Station Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3383,
      "lat": -30.6550006866,
      "lon": 151.6380004883,
      "tz": "Australia\/Sydney"
  },
  "YKEN": {
      "icao": "YKEN",
      "iata": "",
      "name": "Kenmore Park Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.3332996368,
      "lon": 132.4669952393,
      "tz": "Australia\/Adelaide"
  },
  "YKEP": {
      "icao": "YKEP",
      "iata": "",
      "name": "Lake Keepit Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.8911034242,
      "lon": 150.526149273,
      "tz": "Australia\/Sydney"
  },
  "YKER": {
      "icao": "YKER",
      "iata": "KRA",
      "name": "Kerang Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 254,
      "lat": -35.7513999939,
      "lon": 143.9389953613,
      "tz": "Australia\/Melbourne"
  },
  "YKFC": {
      "icao": "YKFC",
      "iata": "",
      "name": "Kingfisher Camp Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.8687992096,
      "lon": 138.2949981689,
      "tz": "Australia\/Brisbane"
  },
  "YKGA": {
      "icao": "YKGA",
      "iata": "",
      "name": "Kingoonya Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9016990662,
      "lon": 135.3049926758,
      "tz": "Australia\/Adelaide"
  },
  "YKHA": {
      "icao": "YKHA",
      "iata": "",
      "name": "Khancoban Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -36.2332992554,
      "lon": 148.1170043945,
      "tz": "Australia\/Sydney"
  },
  "YKHG": {
      "icao": "YKHG",
      "iata": "",
      "name": "Katherine Gorge Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -14.3886003494,
      "lon": 132.3930053711,
      "tz": "Australia\/Darwin"
  },
  "YKIA": {
      "icao": "YKIA",
      "iata": "",
      "name": "Kiana Station Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.2416992188,
      "lon": 136.1750030518,
      "tz": "Australia\/Darwin"
  },
  "YKID": {
      "icao": "YKID",
      "iata": "",
      "name": "Kidston Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 494,
      "lat": -18.8700008392,
      "lon": 144.1730041504,
      "tz": "Australia\/Brisbane"
  },
  "YKIG": {
      "icao": "YKIG",
      "iata": "",
      "name": "Kingston Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 2,
      "lat": -36.823299408,
      "lon": 139.875,
      "tz": "Australia\/Adelaide"
  },
  "YKII": {
      "icao": "YKII",
      "iata": "KNS",
      "name": "King Island Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 132,
      "lat": -39.8774986267,
      "lon": 143.8780059814,
      "tz": "Australia\/Currie"
  },
  "YKIL": {
      "icao": "YKIL",
      "iata": "",
      "name": "Kildurk Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.4316997528,
      "lon": 129.6150054932,
      "tz": "Australia\/Darwin"
  },
  "YKIR": {
      "icao": "YKIR",
      "iata": "KBB",
      "name": "Kirkimbie Station Airport",
      "city": "Kirkimbie",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.7791996002,
      "lon": 129.210006714,
      "tz": "Australia\/Darwin"
  },
  "YKIU": {
      "icao": "YKIU",
      "iata": "",
      "name": "Kaiuroo Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.1166992188,
      "lon": 149.3670043945,
      "tz": "Australia\/Brisbane"
  },
  "YKKG": {
      "icao": "YKKG",
      "iata": "KFG",
      "name": "Kalkgurung Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 646,
      "lat": -17.4319000244,
      "lon": 130.8079986572,
      "tz": "Australia\/Darwin"
  },
  "YKKH": {
      "icao": "YKKH",
      "iata": "",
      "name": "Kokatha Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.2917003632,
      "lon": 135.2420043945,
      "tz": "Australia\/Adelaide"
  },
  "YKLA": {
      "icao": "YKLA",
      "iata": "KOH",
      "name": "Koolatah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.9167003632,
      "lon": 142.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "YKLB": {
      "icao": "YKLB",
      "iata": "KKP",
      "name": "Koolburra Airport",
      "city": "Koolburra",
      "state": "Queensland",
      "country": "AU",
      "elevation": 350,
      "lat": -15.3189001083,
      "lon": 143.9550018311,
      "tz": "Australia\/Brisbane"
  },
  "YKLE": {
      "icao": "YKLE",
      "iata": "",
      "name": "Killarney Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 194,
      "lat": -16.25,
      "lon": 131.7469940186,
      "tz": "Australia\/Darwin"
  },
  "YKLG": {
      "icao": "YKLG",
      "iata": "",
      "name": "Kalinga Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.1999998093,
      "lon": 143.8500061035,
      "tz": "Australia\/Brisbane"
  },
  "YKLL": {
      "icao": "YKLL",
      "iata": "",
      "name": "Kallala Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.75,
      "lon": 138.8829956055,
      "tz": "Australia\/Brisbane"
  },
  "YKLN": {
      "icao": "YKLN",
      "iata": "",
      "name": "Killarney Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.3999996185,
      "lon": 143.5,
      "tz": "Australia\/Brisbane"
  },
  "YKLR": {
      "icao": "YKLR",
      "iata": "",
      "name": "Kalamurina Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7166996002,
      "lon": 138.2619934082,
      "tz": "Australia\/Adelaide"
  },
  "YKMB": {
      "icao": "YKMB",
      "iata": "KRB",
      "name": "Karumba Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 5,
      "lat": -17.4566993713,
      "lon": 140.8300018311,
      "tz": "Australia\/Brisbane"
  },
  "YKML": {
      "icao": "YKML",
      "iata": "KML",
      "name": "Kamileroi Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 91,
      "lat": -19.375,
      "lon": 140.0570068359,
      "tz": "Australia\/Brisbane"
  },
  "YKMP": {
      "icao": "YKMP",
      "iata": "KPS",
      "name": "Kempsey Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 54,
      "lat": -31.0743999481,
      "lon": 152.7700042725,
      "tz": "Australia\/Sydney"
  },
  "YKNC": {
      "icao": "YKNC",
      "iata": "",
      "name": "Kencherang Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -13.845000267,
      "lon": 141.5919952393,
      "tz": "Australia\/Brisbane"
  },
  "YKNG": {
      "icao": "YKNG",
      "iata": "KNI",
      "name": "Katanning Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.7167015076,
      "lon": 117.6330032349,
      "tz": "Australia\/Perth"
  },
  "YKNM": {
      "icao": "YKNM",
      "iata": "",
      "name": "Koonmarra Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.2833003998,
      "lon": 117.7829971313,
      "tz": "Australia\/Perth"
  },
  "YKNP": {
      "icao": "YKNP",
      "iata": "",
      "name": "Kununoppin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.1333007813,
      "lon": 117.9000015259,
      "tz": "Australia\/Perth"
  },
  "YKNT": {
      "icao": "YKNT",
      "iata": "",
      "name": "Kintore Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2649993896,
      "lon": 129.3869934082,
      "tz": "Australia\/Darwin"
  },
  "YKNV": {
      "icao": "YKNV",
      "iata": "",
      "name": "Kaniva Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.3833007813,
      "lon": 141.25,
      "tz": "Australia\/Melbourne"
  },
  "YKOG": {
      "icao": "YKOG",
      "iata": "",
      "name": "Koongarra Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36,
      "lon": 142.2169952393,
      "tz": "Australia\/Melbourne"
  },
  "YKOJ": {
      "icao": "YKOJ",
      "iata": "",
      "name": "Kojonup Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.7667007446,
      "lon": 117.1330032349,
      "tz": "Australia\/Perth"
  },
  "YKOK": {
      "icao": "YKOK",
      "iata": "",
      "name": "Kookynie Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.3500003815,
      "lon": 121.483001709,
      "tz": "Australia\/Perth"
  },
  "YKOL": {
      "icao": "YKOL",
      "iata": "",
      "name": "Kolendo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4157981873,
      "lon": 136.2980041504,
      "tz": "Australia\/Adelaide"
  },
  "YKOW": {
      "icao": "YKOW",
      "iata": "KWM",
      "name": "Kowanyama Airport",
      "city": "Kowanyama",
      "state": "Queensland",
      "country": "AU",
      "elevation": 35,
      "lat": -15.4856004715,
      "lon": 141.7510070801,
      "tz": "Australia\/Brisbane"
  },
  "YKPR": {
      "icao": "YKPR",
      "iata": "KPP",
      "name": "Kalpowar Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.8999996185,
      "lon": 144.1999969482,
      "tz": "Australia\/Brisbane"
  },
  "YKRV": {
      "icao": "YKRV",
      "iata": "",
      "name": "Kendall River Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -13.75,
      "lon": 142.1829986572,
      "tz": "Australia\/Brisbane"
  },
  "YKRY": {
      "icao": "YKRY",
      "iata": "KGY",
      "name": "Kingaroy Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1492,
      "lat": -26.5807991028,
      "lon": 151.841003418,
      "tz": "Australia\/Brisbane"
  },
  "YKSC": {
      "icao": "YKSC",
      "iata": "KGC",
      "name": "Kingscote Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 24,
      "lat": -35.7139015198,
      "lon": 137.5209960938,
      "tz": "Australia\/Adelaide"
  },
  "YKTA": {
      "icao": "YKTA",
      "iata": "",
      "name": "Kotta Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.1797981262,
      "lon": 144.5249938965,
      "tz": "Australia\/Melbourne"
  },
  "YKTH": {
      "icao": "YKTH",
      "iata": "",
      "name": "Keith Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -36.1082992554,
      "lon": 140.2420043945,
      "tz": "Australia\/Adelaide"
  },
  "YKTN": {
      "icao": "YKTN",
      "iata": "",
      "name": "Kyneton Airport",
      "city": "Kyneton",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1650,
      "lat": -37.2262620936,
      "lon": 144.44729805,
      "tz": "Australia\/Melbourne"
  },
  "YKUB": {
      "icao": "YKUB",
      "iata": "KUG",
      "name": "Kubin Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -10.2250003815,
      "lon": 142.2180023193,
      "tz": "Australia\/Brisbane"
  },
  "YKUW": {
      "icao": "YKUW",
      "iata": "",
      "name": "Kurweeton Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.0499992371,
      "lon": 143.1499938965,
      "tz": "Australia\/Melbourne"
  },
  "YKUY": {
      "icao": "YKUY",
      "iata": "",
      "name": "Kurray Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.2199993134,
      "lon": 148.5780029297,
      "tz": "Australia\/Brisbane"
  },
  "YKWG": {
      "icao": "YKWG",
      "iata": "",
      "name": "Kangaroo Well Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7849998474,
      "lon": 135.6380004883,
      "tz": "Australia\/Adelaide"
  },
  "YKYB": {
      "icao": "YKYB",
      "iata": "",
      "name": "Kyabram Airport",
      "city": "Private Airfield in the Shire of Campaspe",
      "state": "Victoria",
      "country": "AU",
      "elevation": 340,
      "lat": -36.3324823653,
      "lon": 144.972066879,
      "tz": "Australia\/Melbourne"
  },
  "YKYN": {
      "icao": "YKYN",
      "iata": "",
      "name": "Kynuna Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.6000003815,
      "lon": 141.9170074463,
      "tz": "Australia\/Brisbane"
  },
  "YLAG": {
      "icao": "YLAG",
      "iata": "",
      "name": "Lagoon Bay Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.8849983215,
      "lon": 147.9530029297,
      "tz": "Australia\/Hobart"
  },
  "YLAH": {
      "icao": "YLAH",
      "iata": "LWH",
      "name": "Lawn Hill Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 122,
      "lat": -18.5683002472,
      "lon": 138.6349945068,
      "tz": "Australia\/Brisbane"
  },
  "YLAK": {
      "icao": "YLAK",
      "iata": "",
      "name": "Lakeside Airpark",
      "city": "Lakeside",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.6816997528,
      "lon": 148.6280059814,
      "tz": "Australia\/Brisbane"
  },
  "YLAM": {
      "icao": "YLAM",
      "iata": "",
      "name": "Lambina Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.9132995605,
      "lon": 134.0579986572,
      "tz": "Australia\/Adelaide"
  },
  "YLAN": {
      "icao": "YLAN",
      "iata": "",
      "name": "Langawirra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.4500007629,
      "lon": 142.1329956055,
      "tz": "Australia\/Sydney"
  },
  "YLAO": {
      "icao": "YLAO",
      "iata": "",
      "name": "Lameroo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35.3666992188,
      "lon": 140.5500030518,
      "tz": "Australia\/Adelaide"
  },
  "YLAW": {
      "icao": "YLAW",
      "iata": "",
      "name": "Lawlers Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.0832996368,
      "lon": 120.5,
      "tz": "Australia\/Perth"
  },
  "YLBD": {
      "icao": "YLBD",
      "iata": "",
      "name": "Lombadina Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.5167007446,
      "lon": 122.9169998169,
      "tz": "Australia\/Perth"
  },
  "YLBG": {
      "icao": "YLBG",
      "iata": "",
      "name": "Mount Liebig Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2432994843,
      "lon": 131.2599945068,
      "tz": "Australia\/Darwin"
  },
  "YLBO": {
      "icao": "YLBO",
      "iata": "",
      "name": "Lake Bolac Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.6833000183,
      "lon": 142.8829956055,
      "tz": "Australia\/Melbourne"
  },
  "YLCG": {
      "icao": "YLCG",
      "iata": "",
      "name": "Lake Cargelligo Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 169,
      "lat": -33.278301239,
      "lon": 146.3699951172,
      "tz": "Australia\/Sydney"
  },
  "YLCS": {
      "icao": "YLCS",
      "iata": "",
      "name": "Locksley Field",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 165,
      "lat": -36.8149986267,
      "lon": 145.3480072021,
      "tz": "Australia\/Melbourne"
  },
  "YLDB": {
      "icao": "YLDB",
      "iata": "",
      "name": "Lady Barron (Flinders Is) Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -40.2000007629,
      "lon": 148.25,
      "tz": "Australia\/Hobart"
  },
  "YLDO": {
      "icao": "YLDO",
      "iata": "",
      "name": "Landor Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1000003815,
      "lon": 116.9000015259,
      "tz": "Australia\/Perth"
  },
  "YLEA": {
      "icao": "YLEA",
      "iata": "",
      "name": "Leeman Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.9832992554,
      "lon": 114.983001709,
      "tz": "Australia\/Perth"
  },
  "YLEC": {
      "icao": "YLEC",
      "iata": "LGH",
      "name": "Leigh Creek Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 856,
      "lat": -30.5983009338,
      "lon": 138.425994873,
      "tz": "Australia\/Adelaide"
  },
  "YLED": {
      "icao": "YLED",
      "iata": "",
      "name": "Lethbridge Park Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.9189987183,
      "lon": 144.1009979248,
      "tz": "Australia\/Melbourne"
  },
  "YLEE": {
      "icao": "YLEE",
      "iata": "",
      "name": "Leeton Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.5,
      "lon": 146.4329986572,
      "tz": "Australia\/Sydney"
  },
  "YLEG": {
      "icao": "YLEG",
      "iata": "",
      "name": "Leongatha Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 263,
      "lat": -38.4930992126,
      "lon": 145.8600006104,
      "tz": "Australia\/Melbourne"
  },
  "YLEO": {
      "icao": "YLEO",
      "iata": "LNO",
      "name": "Leonora Airport",
      "city": "Leonora",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1217,
      "lat": -28.8780994415,
      "lon": 121.3150024414,
      "tz": "Australia\/Perth"
  },
  "YLET": {
      "icao": "YLET",
      "iata": "",
      "name": "Lakes Entrance Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.8791999817,
      "lon": 147.9869995117,
      "tz": "Australia\/Melbourne"
  },
  "YLEV": {
      "icao": "YLEV",
      "iata": "LEL",
      "name": "Lake Evella Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 256,
      "lat": -12.4989004135,
      "lon": 135.8059997559,
      "tz": "Australia\/Darwin"
  },
  "YLFD": {
      "icao": "YLFD",
      "iata": "LFP",
      "name": "Lakefield Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.9333000183,
      "lon": 144.1999969482,
      "tz": "Australia\/Brisbane"
  },
  "YLGA": {
      "icao": "YLGA",
      "iata": "",
      "name": "Mulga Downs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.1072006226,
      "lon": 118.4710006714,
      "tz": "Australia\/Perth"
  },
  "YLGB": {
      "icao": "YLGB",
      "iata": "",
      "name": "La Grange Bay Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.6833000183,
      "lon": 121.8079986572,
      "tz": "Australia\/Perth"
  },
  "YLGC": {
      "icao": "YLGC",
      "iata": "",
      "name": "Lake Grace Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.1333007813,
      "lon": 118.4000015259,
      "tz": "Australia\/Perth"
  },
  "YLGD": {
      "icao": "YLGD",
      "iata": "",
      "name": "Longdown Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.6953010559,
      "lon": 147.1430053711,
      "tz": "Australia\/Hobart"
  },
  "YLGL": {
      "icao": "YLGL",
      "iata": "",
      "name": "Laglan Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.5,
      "lon": 146.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YLGN": {
      "icao": "YLGN",
      "iata": "",
      "name": "Loongana Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9500007629,
      "lon": 127.0329971313,
      "tz": "Australia\/Perth"
  },
  "YLGU": {
      "icao": "YLGU",
      "iata": "",
      "name": "Legune Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 26,
      "lat": -15.2166996002,
      "lon": 129.4479980469,
      "tz": "Australia\/Darwin"
  },
  "YLHI": {
      "icao": "YLHI",
      "iata": "LDH",
      "name": "Lord Howe Island Airport",
      "city": "Lord Howe Island",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 5,
      "lat": -31.5382995605,
      "lon": 159.07699585,
      "tz": "Australia\/Lord_Howe"
  },
  "YLHL": {
      "icao": "YLHL",
      "iata": "",
      "name": "Long Hill Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.3333015442,
      "lon": 146.5,
      "tz": "Australia\/Hobart"
  },
  "YLHR": {
      "icao": "YLHR",
      "iata": "IRG",
      "name": "Lockhart River Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 77,
      "lat": -12.7868995667,
      "lon": 143.3049926758,
      "tz": "Australia\/Brisbane"
  },
  "YLHS": {
      "icao": "YLHS",
      "iata": "LTP",
      "name": "Lyndhurst Airport",
      "city": "Lyndhurst",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.1958007812,
      "lon": 144.371002197,
      "tz": "Australia\/Brisbane"
  },
  "YLIL": {
      "icao": "YLIL",
      "iata": "",
      "name": "Lilydale Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 76,
      "lat": -37.6916999817,
      "lon": 145.3670043945,
      "tz": "Australia\/Melbourne"
  },
  "YLIM": {
      "icao": "YLIM",
      "iata": "",
      "name": "Limbunya Station Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.25,
      "lon": 129.8829956055,
      "tz": "Australia\/Darwin"
  },
  "YLIN": {
      "icao": "YLIN",
      "iata": "LDC",
      "name": "Lindeman Island Airport",
      "city": "Lindeman Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.4535999298,
      "lon": 149.039993286,
      "tz": "Australia\/Lindeman"
  },
  "YLIS": {
      "icao": "YLIS",
      "iata": "LSY",
      "name": "Lismore Airport",
      "city": "Lismore",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 35,
      "lat": -28.8302993774,
      "lon": 153.259994507,
      "tz": "Australia\/Sydney"
  },
  "YLKD": {
      "icao": "YLKD",
      "iata": "",
      "name": "Lucky Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.9167003632,
      "lon": 145,
      "tz": "Australia\/Brisbane"
  },
  "YLKE": {
      "icao": "YLKE",
      "iata": "",
      "name": "Lakes Entrance Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.8526992798,
      "lon": 147.9579925537,
      "tz": "Australia\/Melbourne"
  },
  "YLKG": {
      "icao": "YLKG",
      "iata": "",
      "name": "Lake King Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1276,
      "lat": -33.0792007446,
      "lon": 119.7659988403,
      "tz": "Australia\/Perth"
  },
  "YLKN": {
      "icao": "YLKN",
      "iata": "LNH",
      "name": "Lake Nash Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.9666996002,
      "lon": 137.9170074463,
      "tz": "Australia\/Darwin"
  },
  "YLLA": {
      "icao": "YLLA",
      "iata": "",
      "name": "Mobella Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.7982997894,
      "lon": 133.3450012207,
      "tz": "Australia\/Adelaide"
  },
  "YLLC": {
      "icao": "YLLC",
      "iata": "",
      "name": "Lilla Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5666999817,
      "lon": 134,
      "tz": "Australia\/Darwin"
  },
  "YLLD": {
      "icao": "YLLD",
      "iata": "",
      "name": "Langlo Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.5333003998,
      "lon": 145.766998291,
      "tz": "Australia\/Brisbane"
  },
  "YLLE": {
      "icao": "YLLE",
      "iata": "BBL",
      "name": "Ballera Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 385,
      "lat": -27.4083003998,
      "lon": 141.8079986572,
      "tz": "Australia\/Brisbane"
  },
  "YLLR": {
      "icao": "YLLR",
      "iata": "",
      "name": "Lake Leagur Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.9832992554,
      "lon": 143.8000030518,
      "tz": "Australia\/Melbourne"
  },
  "YLLV": {
      "icao": "YLLV",
      "iata": "",
      "name": "Lily Vale Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.483300209,
      "lon": 143.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YLMB": {
      "icao": "YLMB",
      "iata": "",
      "name": "Lambrook Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.1000003815,
      "lon": 149.9329986572,
      "tz": "Australia\/Sydney"
  },
  "YLMU": {
      "icao": "YLMU",
      "iata": "",
      "name": "Mungo Lodge Airport",
      "city": "Mungo",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -33.7458244463,
      "lon": 143.001308441,
      "tz": "Australia\/Sydney"
  },
  "YLND": {
      "icao": "YLND",
      "iata": "LKD",
      "name": "Lakeland Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.8332996368,
      "lon": 144.8500061035,
      "tz": "Australia\/Brisbane"
  },
  "YLNR": {
      "icao": "YLNR",
      "iata": "",
      "name": "Landor Races Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.9500007629,
      "lon": 116.9670028687,
      "tz": "Australia\/Perth"
  },
  "YLOC": {
      "icao": "YLOC",
      "iata": "",
      "name": "Lochinvar Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.7583007813,
      "lon": 121.016998291,
      "tz": "Australia\/Perth"
  },
  "YLOD": {
      "icao": "YLOD",
      "iata": "",
      "name": "Longwood Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.807800293,
      "lon": 145.4570007324,
      "tz": "Australia\/Melbourne"
  },
  "YLOH": {
      "icao": "YLOH",
      "iata": "",
      "name": "Louth Airport",
      "city": "Louth",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.5417003632,
      "lon": 145.1000061035,
      "tz": "Australia\/Sydney"
  },
  "YLOK": {
      "icao": "YLOK",
      "iata": "LOC",
      "name": "Lock Airport",
      "city": "Lock",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 350,
      "lat": -33.5442008972,
      "lon": 135.6929931641,
      "tz": "Australia\/Adelaide"
  },
  "YLOR": {
      "icao": "YLOR",
      "iata": "LOA",
      "name": "Lorraine Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 61,
      "lat": -18.9932994843,
      "lon": 139.9069976807,
      "tz": "Australia\/Brisbane"
  },
  "YLOU": {
      "icao": "YLOU",
      "iata": "",
      "name": "Louisa Downs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.7166996002,
      "lon": 126.7170028687,
      "tz": "Australia\/Perth"
  },
  "YLOV": {
      "icao": "YLOV",
      "iata": "LTV",
      "name": "Lotus Vale Airport",
      "city": "Lotus Vale",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.0482997894,
      "lon": 141.37600708,
      "tz": "Australia\/Brisbane"
  },
  "YLOX": {
      "icao": "YLOX",
      "iata": "",
      "name": "Loxton Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 38,
      "lat": -34.4749984741,
      "lon": 140.6629943848,
      "tz": "Australia\/Adelaide"
  },
  "YLOY": {
      "icao": "YLOY",
      "iata": "",
      "name": "Longwarry Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.1082992554,
      "lon": 145.7779998779,
      "tz": "Australia\/Melbourne"
  },
  "YLPR": {
      "icao": "YLPR",
      "iata": "",
      "name": "Lake Pedder Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -43.0499992371,
      "lon": 146.3329925537,
      "tz": "Australia\/Hobart"
  },
  "YLRA": {
      "icao": "YLRA",
      "iata": "LUU",
      "name": "Laura Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.5500001907,
      "lon": 144.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "YLRD": {
      "icao": "YLRD",
      "iata": "LHG",
      "name": "Lightning Ridge Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 540,
      "lat": -29.4566993713,
      "lon": 147.9839935303,
      "tz": "Australia\/Sydney"
  },
  "YLRE": {
      "icao": "YLRE",
      "iata": "LRE",
      "name": "Longreach Airport",
      "city": "Longreach",
      "state": "Queensland",
      "country": "AU",
      "elevation": 627,
      "lat": -23.4342002869,
      "lon": 144.279998779,
      "tz": "Australia\/Brisbane"
  },
  "YLRG": {
      "icao": "YLRG",
      "iata": "",
      "name": "Lorna Glen Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.2166996002,
      "lon": 121.5500030518,
      "tz": "Australia\/Perth"
  },
  "YLRN": {
      "icao": "YLRN",
      "iata": "",
      "name": "Lorraine Station Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.5333003998,
      "lon": 143.516998291,
      "tz": "Australia\/Brisbane"
  },
  "YLRS": {
      "icao": "YLRS",
      "iata": "LUT",
      "name": "New Laura Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.1833000183,
      "lon": 144.3670043945,
      "tz": "Australia\/Brisbane"
  },
  "YLSM": {
      "icao": "YLSM",
      "iata": "",
      "name": "Lismore Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.9333000183,
      "lon": 143.3500061035,
      "tz": "Australia\/Melbourne"
  },
  "YLSS": {
      "icao": "YLSS",
      "iata": "",
      "name": "Lansdowne Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.0499992371,
      "lon": 146.266998291,
      "tz": "Australia\/Brisbane"
  },
  "YLST": {
      "icao": "YLST",
      "iata": "LER",
      "name": "Leinster Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1631,
      "lat": -27.8432998657,
      "lon": 120.7030029297,
      "tz": "Australia\/Perth"
  },
  "YLSY": {
      "icao": "YLSY",
      "iata": "",
      "name": "Mount Lindsay Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.0182991028,
      "lon": 129.8849945068,
      "tz": "Australia\/Adelaide"
  },
  "YLTN": {
      "icao": "YLTN",
      "iata": "LVO",
      "name": "Laverton Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1530,
      "lat": -28.6135997772,
      "lon": 122.4240036011,
      "tz": "Australia\/Perth"
  },
  "YLTV": {
      "icao": "YLTV",
      "iata": "TGN",
      "name": "Latrobe Valley Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 180,
      "lat": -38.2071990967,
      "lon": 146.470001221,
      "tz": "Australia\/Melbourne"
  },
  "YLUC": {
      "icao": "YLUC",
      "iata": "",
      "name": "Lucy Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.4666996002,
      "lon": 136.266998291,
      "tz": "Australia\/Darwin"
  },
  "YLUI": {
      "icao": "YLUI",
      "iata": "",
      "name": "Lucindale Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -36.971698761,
      "lon": 140.3520050049,
      "tz": "Australia\/Adelaide"
  },
  "YLUL": {
      "icao": "YLUL",
      "iata": "",
      "name": "Mooleulooloo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.6333007813,
      "lon": 140.5330047607,
      "tz": "Australia\/Adelaide"
  },
  "YLUW": {
      "icao": "YLUW",
      "iata": "",
      "name": "Leeuwin Estate Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.0167007446,
      "lon": 115.0500030518,
      "tz": "Australia\/Perth"
  },
  "YLVB": {
      "icao": "YLVB",
      "iata": "",
      "name": "Lovely Banks Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.0332984924,
      "lon": 144.3329925537,
      "tz": "Australia\/Melbourne"
  },
  "YLVD": {
      "icao": "YLVD",
      "iata": "",
      "name": "Lake Everard Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7332992554,
      "lon": 135.0269927979,
      "tz": "Australia\/Adelaide"
  },
  "YLVK": {
      "icao": "YLVK",
      "iata": "",
      "name": "Laverak (Military) Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 9,
      "lat": -19.3216991425,
      "lon": 146.8020019531,
      "tz": "Australia\/Brisbane"
  },
  "YLVY": {
      "icao": "YLVY",
      "iata": "",
      "name": "Lake Varley Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.7083015442,
      "lon": 119.5130004883,
      "tz": "Australia\/Perth"
  },
  "YLWY": {
      "icao": "YLWY",
      "iata": "",
      "name": "Lake Way Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.9449996948,
      "lon": 120.4700012207,
      "tz": "Australia\/Perth"
  },
  "YLYD": {
      "icao": "YLYD",
      "iata": "",
      "name": "Lyndley Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.8332996368,
      "lon": 151.233001709,
      "tz": "Australia\/Brisbane"
  },
  "YLYK": {
      "icao": "YLYK",
      "iata": "",
      "name": "Lyndoch Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.6217002869,
      "lon": 138.9140014648,
      "tz": "Australia\/Adelaide"
  },
  "YLYN": {
      "icao": "YLYN",
      "iata": "",
      "name": "Lyndon Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.6333007813,
      "lon": 115.233001709,
      "tz": "Australia\/Perth"
  },
  "YLZI": {
      "icao": "YLZI",
      "iata": "LZR",
      "name": "Lizard Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 70,
      "lat": -14.6667003632,
      "lon": 145.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "YLZR": {
      "icao": "YLZR",
      "iata": "",
      "name": "Lizard Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.6833000183,
      "lon": 145.4499969482,
      "tz": "Australia\/Brisbane"
  },
  "YMAA": {
      "icao": "YMAA",
      "iata": "UBB",
      "name": "Mabuiag Island Airport",
      "city": "Mabuiag Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -9.9499998093,
      "lon": 142.1829986572,
      "tz": "Australia\/Brisbane"
  },
  "YMAC": {
      "icao": "YMAC",
      "iata": "",
      "name": "Macumba Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.2616996765,
      "lon": 135.6380004883,
      "tz": "Australia\/Adelaide"
  },
  "YMAD": {
      "icao": "YMAD",
      "iata": "",
      "name": "Madura Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.8833007813,
      "lon": 127.0329971313,
      "tz": "Australia\/Eucla"
  },
  "YMAG": {
      "icao": "YMAG",
      "iata": "",
      "name": "Manangatang Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.0499992371,
      "lon": 142.8670043945,
      "tz": "Australia\/Melbourne"
  },
  "YMAI": {
      "icao": "YMAI",
      "iata": "",
      "name": "Manguri Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.9799995422,
      "lon": 134.3719940186,
      "tz": "Australia\/Adelaide"
  },
  "YMAK": {
      "icao": "YMAK",
      "iata": "",
      "name": "Mabel Creek Station Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.9416999817,
      "lon": 134.3329925537,
      "tz": "Australia\/Adelaide"
  },
  "YMAU": {
      "icao": "YMAU",
      "iata": "",
      "name": "Mount Augusta Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2999992371,
      "lon": 116.9169998169,
      "tz": "Australia\/Perth"
  },
  "YMAV": {
      "icao": "YMAV",
      "iata": "AVV",
      "name": "Avalon Airport",
      "city": "Melbourne",
      "state": "Victoria",
      "country": "AU",
      "elevation": 35,
      "lat": -38.0393981934,
      "lon": 144.468994141,
      "tz": "Australia\/Melbourne"
  },
  "YMAY": {
      "icao": "YMAY",
      "iata": "ABX",
      "name": "Albury Airport",
      "city": "Albury",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 539,
      "lat": -36.0677986145,
      "lon": 146.9579925537,
      "tz": "Australia\/Melbourne"
  },
  "YMBA": {
      "icao": "YMBA",
      "iata": "MRG",
      "name": "Mareeba Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1560,
      "lat": -17.0692005157,
      "lon": 145.4190063477,
      "tz": "Australia\/Brisbane"
  },
  "YMBD": {
      "icao": "YMBD",
      "iata": "",
      "name": "Murray Bridge Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 55,
      "lat": -35.0666999817,
      "lon": 139.2270050049,
      "tz": "Australia\/Adelaide"
  },
  "YMBL": {
      "icao": "YMBL",
      "iata": "MBB",
      "name": "Marble Bar Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 194,
      "lat": -21.1632995605,
      "lon": 119.8330001831,
      "tz": "Australia\/Perth"
  },
  "YMBT": {
      "icao": "YMBT",
      "iata": "",
      "name": "Mount Beauty Airport",
      "city": "Mount Beauty",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1100,
      "lat": -36.7315756754,
      "lon": 147.168903351,
      "tz": "Australia\/Melbourne"
  },
  "YMBU": {
      "icao": "YMBU",
      "iata": "",
      "name": "Maryborough Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 766,
      "lat": -37.0331001282,
      "lon": 143.7089996338,
      "tz": "Australia\/Melbourne"
  },
  "YMBX": {
      "icao": "YMBX",
      "iata": "",
      "name": "Mundrabilla Airport",
      "city": "Mundrabilla",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 120,
      "lat": -31.8672008514,
      "lon": 127.8539962769,
      "tz": "Australia\/Eucla"
  },
  "YMBZ": {
      "icao": "YMBZ",
      "iata": "",
      "name": "Mount Ebenezer Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1667003632,
      "lon": 132.6329956055,
      "tz": "Australia\/Darwin"
  },
  "YMCE": {
      "icao": "YMCE",
      "iata": "",
      "name": "Mount Clere Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1000003815,
      "lon": 117.5830001831,
      "tz": "Australia\/Perth"
  },
  "YMCK": {
      "icao": "YMCK",
      "iata": "",
      "name": "Mckinley Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.2833003998,
      "lon": 141.2879943848,
      "tz": "Australia\/Brisbane"
  },
  "YMCL": {
      "icao": "YMCL",
      "iata": "",
      "name": "Mount Coolon Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 239,
      "lat": -21.3917007446,
      "lon": 147.3249969482,
      "tz": "Australia\/Brisbane"
  },
  "YMCO": {
      "icao": "YMCO",
      "iata": "XMC",
      "name": "Mallacoota Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 31,
      "lat": -37.5983009338,
      "lon": 149.7200012207,
      "tz": "Australia\/Melbourne"
  },
  "YMCR": {
      "icao": "YMCR",
      "iata": "MFP",
      "name": "Manners Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.1000003815,
      "lon": 137.983001709,
      "tz": "Australia\/Darwin"
  },
  "YMCS": {
      "icao": "YMCS",
      "iata": "",
      "name": "Macrossan Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.0167007446,
      "lon": 146.483001709,
      "tz": "Australia\/Brisbane"
  },
  "YMCT": {
      "icao": "YMCT",
      "iata": "MLR",
      "name": "Millicent Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 56,
      "lat": -37.5835990906,
      "lon": 140.3659973145,
      "tz": "Australia\/Adelaide"
  },
  "YMCU": {
      "icao": "YMCU",
      "iata": "",
      "name": "Mount Mcclure Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.4699993134,
      "lon": 120.9169998169,
      "tz": "Australia\/Perth"
  },
  "YMDA": {
      "icao": "YMDA",
      "iata": "",
      "name": "Mundubbera Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 122,
      "lat": -25.5916996002,
      "lon": 151.3170013428,
      "tz": "Australia\/Brisbane"
  },
  "YMDB": {
      "icao": "YMDB",
      "iata": "",
      "name": "Mundabullangana Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.5167007446,
      "lon": 118.0670013428,
      "tz": "Australia\/Perth"
  },
  "YMDG": {
      "icao": "YMDG",
      "iata": "DGE",
      "name": "Mudgee Airport",
      "city": "Mudgee",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1545,
      "lat": -32.5625,
      "lon": 149.610992432,
      "tz": "Australia\/Sydney"
  },
  "YMDI": {
      "icao": "YMDI",
      "iata": "MQA",
      "name": "Mandora Airport",
      "city": "Mandora",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -19.7383003235,
      "lon": 120.837997437,
      "tz": "Australia\/Perth"
  },
  "YMDK": {
      "icao": "YMDK",
      "iata": "",
      "name": "Mount Riddock Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.0333003998,
      "lon": 134.6829986572,
      "tz": "Australia\/Darwin"
  },
  "YMDN": {
      "icao": "YMDN",
      "iata": "",
      "name": "Merredin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 396,
      "lat": -31.5049991608,
      "lon": 118.3229980469,
      "tz": "Australia\/Perth"
  },
  "YMDR": {
      "icao": "YMDR",
      "iata": "",
      "name": "Minderoo Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22,
      "lon": 115.0500030518,
      "tz": "Australia\/Perth"
  },
  "YMDS": {
      "icao": "YMDS",
      "iata": "MNW",
      "name": "Macdonald Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.4666996002,
      "lon": 135.233001709,
      "tz": "Australia\/Darwin"
  },
  "YMDT": {
      "icao": "YMDT",
      "iata": "",
      "name": "Mundrabilla Motel Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.8267002106,
      "lon": 128.2299957275,
      "tz": "Australia\/Eucla"
  },
  "YMDV": {
      "icao": "YMDV",
      "iata": "",
      "name": "Mount Davies Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -26.1667003632,
      "lon": 129.1329956055,
      "tz": "Australia\/Adelaide"
  },
  "YMDW": {
      "icao": "YMDW",
      "iata": "",
      "name": "Maitland Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -16.2233009338,
      "lon": 144.7030029297,
      "tz": "Australia\/Brisbane"
  },
  "YMDY": {
      "icao": "YMDY",
      "iata": "",
      "name": "Mount Bundey Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 150,
      "lat": -12.8900003433,
      "lon": 131.9080047607,
      "tz": "Australia\/Darwin"
  },
  "YMDZ": {
      "icao": "YMDZ",
      "iata": "",
      "name": "Mardi Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.2000007629,
      "lon": 115.983001709,
      "tz": "Australia\/Perth"
  },
  "YMEB": {
      "icao": "YMEB",
      "iata": "",
      "name": "Mount Eba Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.1760997772,
      "lon": 135.671005249,
      "tz": "Australia\/Adelaide"
  },
  "YMED": {
      "icao": "YMED",
      "iata": "",
      "name": "Menindee Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.3666992188,
      "lon": 142.4049987793,
      "tz": "Australia\/Sydney"
  },
  "YMEI": {
      "icao": "YMEI",
      "iata": "",
      "name": "Mereenie Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 735,
      "lat": -23.9766998291,
      "lon": 131.56199646,
      "tz": "Australia\/Darwin"
  },
  "YMEK": {
      "icao": "YMEK",
      "iata": "MKR",
      "name": "Meekatharra Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1713,
      "lat": -26.611700058,
      "lon": 118.547996521,
      "tz": "Australia\/Perth"
  },
  "YMEL": {
      "icao": "YMEL",
      "iata": "",
      "name": "Melton Airport",
      "city": "Melton",
      "state": "Victoria",
      "country": "AU",
      "elevation": 204,
      "lat": -37.6166992188,
      "lon": 144.5670013428,
      "tz": "Australia\/Melbourne"
  },
  "YMEN": {
      "icao": "YMEN",
      "iata": "MEB",
      "name": "Melbourne Essendon Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 282,
      "lat": -37.728099823,
      "lon": 144.9019927979,
      "tz": "Australia\/Melbourne"
  },
  "YMEO": {
      "icao": "YMEO",
      "iata": "",
      "name": "Merton Airport",
      "city": "Merton",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1101,
      "lat": -36.967704,
      "lon": 145.707616,
      "tz": "Australia\/Melbourne"
  },
  "YMEP": {
      "icao": "YMEP",
      "iata": "",
      "name": "Merapah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -13.7166996002,
      "lon": 142.4170074463,
      "tz": "Australia\/Brisbane"
  },
  "YMER": {
      "icao": "YMER",
      "iata": "MIM",
      "name": "Merimbula Airport",
      "city": "Merimbula",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 7,
      "lat": -36.9085998535,
      "lon": 149.901000977,
      "tz": "Australia\/Sydney"
  },
  "YMES": {
      "icao": "YMES",
      "iata": "SXE",
      "name": "RAAF Base East Sale",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 23,
      "lat": -38.0988998413,
      "lon": 147.1490020752,
      "tz": "Australia\/Melbourne"
  },
  "YMEU": {
      "icao": "YMEU",
      "iata": "MLV",
      "name": "Merluna Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -13.0500001907,
      "lon": 142.483001709,
      "tz": "Australia\/Brisbane"
  },
  "YMEW": {
      "icao": "YMEW",
      "iata": "",
      "name": "Mingenew Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.2667007446,
      "lon": 115.4499969482,
      "tz": "Australia\/Perth"
  },
  "YMEY": {
      "icao": "YMEY",
      "iata": "",
      "name": "Mullaley Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.1000003815,
      "lon": 149.9170074463,
      "tz": "Australia\/Sydney"
  },
  "YMFD": {
      "icao": "YMFD",
      "iata": "",
      "name": "Mansfield Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1050,
      "lat": -37.0666999817,
      "lon": 146.117004395,
      "tz": "Australia\/Melbourne"
  },
  "YMGB": {
      "icao": "YMGB",
      "iata": "MGT",
      "name": "Milingimbi Airport",
      "city": "Milingimbi Island",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 53,
      "lat": -12.0944004059,
      "lon": 134.893997192,
      "tz": "Australia\/Darwin"
  },
  "YMGD": {
      "icao": "YMGD",
      "iata": "MNG",
      "name": "Maningrida Airport",
      "city": "Maningrida",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 123,
      "lat": -12.0560998917,
      "lon": 134.23399353,
      "tz": "Australia\/Darwin"
  },
  "YMGG": {
      "icao": "YMGG",
      "iata": "",
      "name": "Mulgathing Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.2292003632,
      "lon": 133.9859924316,
      "tz": "Australia\/Adelaide"
  },
  "YMGI": {
      "icao": "YMGI",
      "iata": "",
      "name": "Mungindi Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 600,
      "lat": -28.9666996002,
      "lon": 149.0579986572,
      "tz": "Australia\/Sydney"
  },
  "YMGN": {
      "icao": "YMGN",
      "iata": "GSN",
      "name": "Mount Gunson Airport",
      "city": "Mount Gunson",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 285,
      "lat": -31.4596996307,
      "lon": 137.1739959717,
      "tz": "Australia\/Adelaide"
  },
  "YMGO": {
      "icao": "YMGO",
      "iata": "",
      "name": "Murgoo Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.3666992188,
      "lon": 116.4169998169,
      "tz": "Australia\/Perth"
  },
  "YMGR": {
      "icao": "YMGR",
      "iata": "MGV",
      "name": "Margaret River (Station) Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 289,
      "lat": -18.6217002869,
      "lon": 126.883003235,
      "tz": "Australia\/Perth"
  },
  "YMGS": {
      "icao": "YMGS",
      "iata": "",
      "name": "Mount Morgans Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.7872009277,
      "lon": 122.033996582,
      "tz": "Australia\/Perth"
  },
  "YMGT": {
      "icao": "YMGT",
      "iata": "MQZ",
      "name": "Margaret River Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 374,
      "lat": -33.9305992126,
      "lon": 115.099998474,
      "tz": "Australia\/Perth"
  },
  "YMGV": {
      "icao": "YMGV",
      "iata": "MVU",
      "name": "Musgrave Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.7833003998,
      "lon": 143.5,
      "tz": "Australia\/Brisbane"
  },
  "YMHB": {
      "icao": "YMHB",
      "iata": "HBA",
      "name": "Hobart International Airport",
      "city": "Hobart",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 13,
      "lat": -42.836101532,
      "lon": 147.509994507,
      "tz": "Australia\/Hobart"
  },
  "YMHL": {
      "icao": "YMHL",
      "iata": "",
      "name": "Mount Holland Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.1183013916,
      "lon": 119.7679977417,
      "tz": "Australia\/Perth"
  },
  "YMHO": {
      "icao": "YMHO",
      "iata": "MHO",
      "name": "Mount House Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 289,
      "lat": -17.0550003052,
      "lon": 125.7099990845,
      "tz": "Australia\/Perth"
  },
  "YMHT": {
      "icao": "YMHT",
      "iata": "",
      "name": "Mount Hart Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.8199996948,
      "lon": 124.9079971313,
      "tz": "Australia\/Perth"
  },
  "YMHU": {
      "icao": "YMHU",
      "iata": "MCV",
      "name": "McArthur River Mine Airport",
      "city": "McArthur River Mine",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 131,
      "lat": -16.4424991608,
      "lon": 136.083999634,
      "tz": "Australia\/Darwin"
  },
  "YMHW": {
      "icao": "YMHW",
      "iata": "",
      "name": "Mount Howitt Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 105,
      "lat": -26.5116996765,
      "lon": 142.2830047607,
      "tz": "Australia\/Brisbane"
  },
  "YMIA": {
      "icao": "YMIA",
      "iata": "MQL",
      "name": "Mildura Airport",
      "city": "Mildura",
      "state": "Victoria",
      "country": "AU",
      "elevation": 167,
      "lat": -34.2291984558,
      "lon": 142.085998535,
      "tz": "Australia\/Melbourne"
  },
  "YMIB": {
      "icao": "YMIB",
      "iata": "",
      "name": "Mintabie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 366,
      "lat": -27.3283004761,
      "lon": 133.3049926758,
      "tz": "Australia\/Adelaide"
  },
  "YMIG": {
      "icao": "YMIG",
      "iata": "",
      "name": "Mittagong Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.4667015076,
      "lon": 150.5,
      "tz": "Australia\/Sydney"
  },
  "YMIJ": {
      "icao": "YMIJ",
      "iata": "",
      "name": "Minjilang Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 25,
      "lat": -11.1583003998,
      "lon": 132.5469970703,
      "tz": "Australia\/Darwin"
  },
  "YMIL": {
      "icao": "YMIL",
      "iata": "",
      "name": "Milgun Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.0832996368,
      "lon": 118.3330001831,
      "tz": "Australia\/Perth"
  },
  "YMIN": {
      "icao": "YMIN",
      "iata": "XML",
      "name": "Minlaton Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.75,
      "lon": 137.5330047607,
      "tz": "Australia\/Adelaide"
  },
  "YMIP": {
      "icao": "YMIP",
      "iata": "MIH",
      "name": "Mitchell Plateau Airport",
      "city": "Mitchell Plateau",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -14.7913999557,
      "lon": 125.8239974976,
      "tz": "Australia\/Perth"
  },
  "YMIR": {
      "icao": "YMIR",
      "iata": "MWY",
      "name": "Miralwyn Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.1499996185,
      "lon": 147.3350067139,
      "tz": "Australia\/Sydney"
  },
  "YMIS": {
      "icao": "YMIS",
      "iata": "",
      "name": "Millrose Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.3999996185,
      "lon": 120.9499969482,
      "tz": "Australia\/Perth"
  },
  "YMIT": {
      "icao": "YMIT",
      "iata": "MTQ",
      "name": "Mitchell Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1144,
      "lat": -26.4832992554,
      "lon": 147.93699646,
      "tz": "Australia\/Brisbane"
  },
  "YMIV": {
      "icao": "YMIV",
      "iata": "",
      "name": "Mount Ive Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4441986084,
      "lon": 136.0690002441,
      "tz": "Australia\/Adelaide"
  },
  "YMIX": {
      "icao": "YMIX",
      "iata": "",
      "name": "Middalya Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.8999996185,
      "lon": 114.766998291,
      "tz": "Australia\/Perth"
  },
  "YMJE": {
      "icao": "YMJE",
      "iata": "",
      "name": "Mount James Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.6166992188,
      "lon": 116.9329986572,
      "tz": "Australia\/Perth"
  },
  "YMJM": {
      "icao": "YMJM",
      "iata": "MJP",
      "name": "Manjimup Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 940,
      "lat": -34.2653007507,
      "lon": 116.1399993896,
      "tz": "Australia\/Perth"
  },
  "YMKA": {
      "icao": "YMKA",
      "iata": "",
      "name": "Mararanka Homestead Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -14.9316997528,
      "lon": 133.125,
      "tz": "Australia\/Darwin"
  },
  "YMKB": {
      "icao": "YMKB",
      "iata": "",
      "name": "Mukinbudin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9333000183,
      "lon": 118.25,
      "tz": "Australia\/Perth"
  },
  "YMKT": {
      "icao": "YMKT",
      "iata": "",
      "name": "Emkaytee (Unlic) Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.6167001724,
      "lon": 131.0500030518,
      "tz": "Australia\/Darwin"
  },
  "YMLA": {
      "icao": "YMLA",
      "iata": "",
      "name": "Malina Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.8833007813,
      "lon": 118.0329971313,
      "tz": "Australia\/Perth"
  },
  "YMLC": {
      "icao": "YMLC",
      "iata": "",
      "name": "Mole Creek Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.5332984924,
      "lon": 146.3329925537,
      "tz": "Australia\/Hobart"
  },
  "YMLD": {
      "icao": "YMLD",
      "iata": "",
      "name": "Maitland Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.3927993774,
      "lon": 137.7169952393,
      "tz": "Australia\/Adelaide"
  },
  "YMLK": {
      "icao": "YMLK",
      "iata": "",
      "name": "Minnamoolka Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.1833000183,
      "lon": 145.1670074463,
      "tz": "Australia\/Brisbane"
  },
  "YMLL": {
      "icao": "YMLL",
      "iata": "",
      "name": "Millungera Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.8582992554,
      "lon": 141.56199646,
      "tz": "Australia\/Brisbane"
  },
  "YMLN": {
      "icao": "YMLN",
      "iata": "",
      "name": "Monolon Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.2000007629,
      "lon": 143.233001709,
      "tz": "Australia\/Sydney"
  },
  "YMLR": {
      "icao": "YMLR",
      "iata": "",
      "name": "Muloorina Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.2467002869,
      "lon": 137.9100036621,
      "tz": "Australia\/Adelaide"
  },
  "YMLS": {
      "icao": "YMLS",
      "iata": "WLE",
      "name": "Miles Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 304,
      "lat": -26.8083000183,
      "lon": 150.1750030518,
      "tz": "Australia\/Brisbane"
  },
  "YMLT": {
      "icao": "YMLT",
      "iata": "LST",
      "name": "Launceston Airport",
      "city": "Launceston",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 562,
      "lat": -41.54529953,
      "lon": 147.214004517,
      "tz": "Australia\/Hobart"
  },
  "YMLX": {
      "icao": "YMLX",
      "iata": "",
      "name": "Milurie Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.5832996368,
      "lon": 121.7829971313,
      "tz": "Australia\/Perth"
  },
  "YMMB": {
      "icao": "YMMB",
      "iata": "MBW",
      "name": "Melbourne Moorabbin Airport",
      "city": "Melbourne",
      "state": "Victoria",
      "country": "AU",
      "elevation": 50,
      "lat": -37.9757995605,
      "lon": 145.1020050049,
      "tz": "Australia\/Melbourne"
  },
  "YMMI": {
      "icao": "YMMI",
      "iata": "WUI",
      "name": "Murrin Murrin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1535,
      "lat": -28.7052993774,
      "lon": 121.8909988403,
      "tz": "Australia\/Perth"
  },
  "YMML": {
      "icao": "YMML",
      "iata": "MEL",
      "name": "Melbourne International Airport",
      "city": "Melbourne",
      "state": "Victoria",
      "country": "AU",
      "elevation": 434,
      "lat": -37.6733016968,
      "lon": 144.8430023193,
      "tz": "Australia\/Melbourne"
  },
  "YMMN": {
      "icao": "YMMN",
      "iata": "",
      "name": "Millmerran Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.8633003235,
      "lon": 151.2749938965,
      "tz": "Australia\/Brisbane"
  },
  "YMMT": {
      "icao": "YMMT",
      "iata": "",
      "name": "Mount Margaret Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.9333000183,
      "lon": 143.3170013428,
      "tz": "Australia\/Brisbane"
  },
  "YMMU": {
      "icao": "YMMU",
      "iata": "MMM",
      "name": "Middlemount Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 547,
      "lat": -22.8024997711,
      "lon": 148.7050018311,
      "tz": "Australia\/Brisbane"
  },
  "YMNA": {
      "icao": "YMNA",
      "iata": "",
      "name": "Mount Allan Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.2749996185,
      "lon": 132.2169952393,
      "tz": "Australia\/Darwin"
  },
  "YMNB": {
      "icao": "YMNB",
      "iata": "",
      "name": "Mount Barry Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.2332992554,
      "lon": 135,
      "tz": "Australia\/Adelaide"
  },
  "YMND": {
      "icao": "YMND",
      "iata": "MTL",
      "name": "Maitland Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 85,
      "lat": -32.7033004761,
      "lon": 151.488006592,
      "tz": "Australia\/Sydney"
  },
  "YMNE": {
      "icao": "YMNE",
      "iata": "WME",
      "name": "Mount Keith Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1792,
      "lat": -27.2863998413,
      "lon": 120.5550003052,
      "tz": "Australia\/Perth"
  },
  "YMNF": {
      "icao": "YMNF",
      "iata": "",
      "name": "Manfred Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.4500007629,
      "lon": 116.5500030518,
      "tz": "Australia\/Perth"
  },
  "YMNG": {
      "icao": "YMNG",
      "iata": "",
      "name": "Mangalore Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 467,
      "lat": -36.8883018494,
      "lon": 145.1840057373,
      "tz": "Australia\/Melbourne"
  },
  "YMNK": {
      "icao": "YMNK",
      "iata": "ONR",
      "name": "Monkira Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 107,
      "lat": -24.8166999817,
      "lon": 140.5330047607,
      "tz": "Australia\/Brisbane"
  },
  "YMNN": {
      "icao": "YMNN",
      "iata": "",
      "name": "Mount Denison Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.1417007446,
      "lon": 132.0700073242,
      "tz": "Australia\/Darwin"
  },
  "YMNO": {
      "icao": "YMNO",
      "iata": "",
      "name": "Maneroo Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.3666992188,
      "lon": 143.8829956055,
      "tz": "Australia\/Brisbane"
  },
  "YMNP": {
      "icao": "YMNP",
      "iata": "",
      "name": "Murnpeowie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.5916996002,
      "lon": 139.0520019531,
      "tz": "Australia\/Adelaide"
  },
  "YMNS": {
      "icao": "YMNS",
      "iata": "MSF",
      "name": "Mount Swan Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.6000003815,
      "lon": 135.016998291,
      "tz": "Australia\/Darwin"
  },
  "YMNT": {
      "icao": "YMNT",
      "iata": "",
      "name": "Mornington Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -17.3936004639,
      "lon": 126.2289962769,
      "tz": "Australia\/Perth"
  },
  "YMNW": {
      "icao": "YMNW",
      "iata": "",
      "name": "Mount Weld Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.7716999054,
      "lon": 122.4400024414,
      "tz": "Australia\/Perth"
  },
  "YMNX": {
      "icao": "YMNX",
      "iata": "",
      "name": "Minura Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.8833007813,
      "lon": 121.8000030518,
      "tz": "Australia\/Perth"
  },
  "YMNY": {
      "icao": "YMNY",
      "iata": "OXY",
      "name": "Morney Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 104,
      "lat": -25.3582992554,
      "lon": 141.4329986572,
      "tz": "Australia\/Brisbane"
  },
  "YMOE": {
      "icao": "YMOE",
      "iata": "",
      "name": "Moonaree Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.9661006927,
      "lon": 135.875,
      "tz": "Australia\/Adelaide"
  },
  "YMOG": {
      "icao": "YMOG",
      "iata": "MMG",
      "name": "Mount Magnet Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1354,
      "lat": -28.1161003113,
      "lon": 117.8420028687,
      "tz": "Australia\/Perth"
  },
  "YMOM": {
      "icao": "YMOM",
      "iata": "",
      "name": "Moulamein Airport",
      "city": "Moulamein",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 233,
      "lat": -35.0583856071,
      "lon": 144.021320343,
      "tz": "Australia\/Sydney"
  },
  "YMOO": {
      "icao": "YMOO",
      "iata": "OOR",
      "name": "Mooraberree Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 85,
      "lat": -25.25,
      "lon": 140.983001709,
      "tz": "Australia\/Brisbane"
  },
  "YMOR": {
      "icao": "YMOR",
      "iata": "MRZ",
      "name": "Moree Airport",
      "city": "Moree",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 701,
      "lat": -29.4988994598,
      "lon": 149.845001221,
      "tz": "Australia\/Sydney"
  },
  "YMOT": {
      "icao": "YMOT",
      "iata": "MET",
      "name": "Moreton Airport",
      "city": "Moreton",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -12.4441995621,
      "lon": 142.638000488,
      "tz": "Australia\/Brisbane"
  },
  "YMOU": {
      "icao": "YMOU",
      "iata": "",
      "name": "Moura Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 147,
      "lat": -24.611700058,
      "lon": 149.9949951172,
      "tz": "Australia\/Brisbane"
  },
  "YMPA": {
      "icao": "YMPA",
      "iata": "MIN",
      "name": "Minnipa Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 155,
      "lat": -32.8432998657,
      "lon": 135.1450042725,
      "tz": "Australia\/Adelaide"
  },
  "YMPC": {
      "icao": "YMPC",
      "iata": "",
      "name": "RAAF Williams Point Cook Base",
      "city": "Point Cook",
      "state": "Victoria",
      "country": "AU",
      "elevation": 14,
      "lat": -37.9322013855,
      "lon": 144.7530059814,
      "tz": "Australia\/Melbourne"
  },
  "YMPE": {
      "icao": "YMPE",
      "iata": "",
      "name": "Mount Cooper Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.5167007446,
      "lon": 146.766998291,
      "tz": "Australia\/Brisbane"
  },
  "YMPH": {
      "icao": "YMPH",
      "iata": "",
      "name": "Mount Elephant Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.9000015259,
      "lon": 143.1670074463,
      "tz": "Australia\/Melbourne"
  },
  "YMPK": {
      "icao": "YMPK",
      "iata": "",
      "name": "Milton Park Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.3666992188,
      "lon": 133,
      "tz": "Australia\/Darwin"
  },
  "YMQA": {
      "icao": "YMQA",
      "iata": "MQE",
      "name": "Marqua Airport",
      "city": "Marqua",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.8057994843,
      "lon": 137.2510070801,
      "tz": "Australia\/Darwin"
  },
  "YMQD": {
      "icao": "YMQD",
      "iata": "",
      "name": "Mount Mcquoid Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -33.1100006104,
      "lon": 151.1380004883,
      "tz": "Australia\/Sydney"
  },
  "YMRA": {
      "icao": "YMRA",
      "iata": "",
      "name": "Maralinga Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.1632995605,
      "lon": 131.625,
      "tz": "Australia\/Adelaide"
  },
  "YMRB": {
      "icao": "YMRB",
      "iata": "MOV",
      "name": "Moranbah Airport",
      "city": "Moranbah",
      "state": "Queensland",
      "country": "AU",
      "elevation": 770,
      "lat": -22.057800293,
      "lon": 148.07699585,
      "tz": "Australia\/Brisbane"
  },
  "YMRE": {
      "icao": "YMRE",
      "iata": "RRE",
      "name": "Marree Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 50,
      "lat": -29.6632995605,
      "lon": 138.0650024414,
      "tz": "Australia\/Adelaide"
  },
  "YMRS": {
      "icao": "YMRS",
      "iata": "",
      "name": "Melrose Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9333000183,
      "lon": 121.3000030518,
      "tz": "Australia\/Perth"
  },
  "YMRT": {
      "icao": "YMRT",
      "iata": "",
      "name": "Mount Garnet Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 657,
      "lat": -17.7000007629,
      "lon": 145.1499938965,
      "tz": "Australia\/Brisbane"
  },
  "YMRW": {
      "icao": "YMRW",
      "iata": "MWB",
      "name": "Morawa Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 270,
      "lat": -29.2017002106,
      "lon": 116.0220031738,
      "tz": "Australia\/Perth"
  },
  "YMRY": {
      "icao": "YMRY",
      "iata": "MYA",
      "name": "Moruya Airport",
      "city": "Moruya",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 14,
      "lat": -35.8978004456,
      "lon": 150.143997192,
      "tz": "Australia\/Sydney"
  },
  "YMSF": {
      "icao": "YMSF",
      "iata": "MTD",
      "name": "Mount Sanford Station Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 231,
      "lat": -16.9783000946,
      "lon": 130.5549926758,
      "tz": "Australia\/Darwin"
  },
  "YMSK": {
      "icao": "YMSK",
      "iata": "",
      "name": "Mount Skinner Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.1833000183,
      "lon": 134.1170043945,
      "tz": "Australia\/Darwin"
  },
  "YMSO": {
      "icao": "YMSO",
      "iata": "",
      "name": "Mount Sandon Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1363,
      "lat": -31.3899993896,
      "lon": 151.4100036621,
      "tz": "Australia\/Sydney"
  },
  "YMSP": {
      "icao": "YMSP",
      "iata": "",
      "name": "Mount Surprise Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.1263008118,
      "lon": 144.2850036621,
      "tz": "Australia\/Brisbane"
  },
  "YMSS": {
      "icao": "YMSS",
      "iata": "",
      "name": "Murchison Shire Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.9167003632,
      "lon": 115.983001709,
      "tz": "Australia\/Perth"
  },
  "YMTA": {
      "icao": "YMTA",
      "iata": "",
      "name": "Mittebah Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -18.7999992371,
      "lon": 137,
      "tz": "Australia\/Darwin"
  },
  "YMTB": {
      "icao": "YMTB",
      "iata": "UTB",
      "name": "Muttaburra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 230,
      "lat": -22.5832996368,
      "lon": 144.5330047607,
      "tz": "Australia\/Brisbane"
  },
  "YMTC": {
      "icao": "YMTC",
      "iata": "",
      "name": "Mount Clarence Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.8332996368,
      "lon": 134.358001709,
      "tz": "Australia\/Adelaide"
  },
  "YMTG": {
      "icao": "YMTG",
      "iata": "MGB",
      "name": "Mount Gambier Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 212,
      "lat": -37.7456016541,
      "lon": 140.7850036621,
      "tz": "Australia\/Adelaide"
  },
  "YMTI": {
      "icao": "YMTI",
      "iata": "ONG",
      "name": "Mornington Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 33,
      "lat": -16.6625003815,
      "lon": 139.1779937744,
      "tz": "Australia\/Brisbane"
  },
  "YMTO": {
      "icao": "YMTO",
      "iata": "MNQ",
      "name": "Monto Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 757,
      "lat": -24.885799408,
      "lon": 151.1000061035,
      "tz": "Australia\/Brisbane"
  },
  "YMTP": {
      "icao": "YMTP",
      "iata": "",
      "name": "Mount Hope Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.1366996765,
      "lon": 135.3379974365,
      "tz": "Australia\/Adelaide"
  },
  "YMTW": {
      "icao": "YMTW",
      "iata": "",
      "name": "Martins Well Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.4736003876,
      "lon": 139.1109924316,
      "tz": "Australia\/Adelaide"
  },
  "YMTX": {
      "icao": "YMTX",
      "iata": "",
      "name": "Mount Dare Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.0617008209,
      "lon": 135.2469940186,
      "tz": "Australia\/Adelaide"
  },
  "YMTZ": {
      "icao": "YMTZ",
      "iata": "",
      "name": "Mount Elizabeth Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.4333000183,
      "lon": 126.0999984741,
      "tz": "Australia\/Perth"
  },
  "YMUA": {
      "icao": "YMUA",
      "iata": "",
      "name": "Monduran Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.875,
      "lon": 151.9170074463,
      "tz": "Australia\/Brisbane"
  },
  "YMUC": {
      "icao": "YMUC",
      "iata": "MUQ",
      "name": "Muccan Station Airport",
      "city": "Muccan Station",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 300,
      "lat": -20.6588993073,
      "lon": 120.0670013428,
      "tz": "Australia\/Perth"
  },
  "YMUE": {
      "icao": "YMUE",
      "iata": "",
      "name": "Mount Borradale Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.0982999802,
      "lon": 132.9069976807,
      "tz": "Australia\/Darwin"
  },
  "YMUG": {
      "icao": "YMUG",
      "iata": "MNE",
      "name": "Mungeranie Airport",
      "city": "Mungeranie",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.0091991425,
      "lon": 138.6569976807,
      "tz": "Australia\/Adelaide"
  },
  "YMUI": {
      "icao": "YMUI",
      "iata": "MYI",
      "name": "Murray Island Airport",
      "city": "Murray Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -9.9166698456,
      "lon": 144.0549926758,
      "tz": "Australia\/Brisbane"
  },
  "YMUJ": {
      "icao": "YMUJ",
      "iata": "",
      "name": "Munjina Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.3661003113,
      "lon": 118.6809997559,
      "tz": "Australia\/Perth"
  },
  "YMUK": {
      "icao": "YMUK",
      "iata": "MVK",
      "name": "Mulka Airport",
      "city": "Mulka",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.3477993011,
      "lon": 138.6499938965,
      "tz": "Australia\/Adelaide"
  },
  "YMUL": {
      "icao": "YMUL",
      "iata": "",
      "name": "Murray Field",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 56,
      "lat": -32.5083007813,
      "lon": 115.8420028687,
      "tz": "Australia\/Perth"
  },
  "YMUP": {
      "icao": "YMUP",
      "iata": "MUP",
      "name": "Mulga Park Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.8999996185,
      "lon": 131.6670074463,
      "tz": "Australia\/Darwin"
  },
  "YMUR": {
      "icao": "YMUR",
      "iata": "",
      "name": "Murwillumbah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -28.3316993713,
      "lon": 153.4129943848,
      "tz": "Australia\/Sydney"
  },
  "YMUX": {
      "icao": "YMUX",
      "iata": "",
      "name": "Mileura Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.3666992188,
      "lon": 117.3330001831,
      "tz": "Australia\/Perth"
  },
  "YMVG": {
      "icao": "YMVG",
      "iata": "MKV",
      "name": "Mount Cavenagh Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.9666996002,
      "lon": 133.1999969482,
      "tz": "Australia\/Darwin"
  },
  "YMVH": {
      "icao": "YMVH",
      "iata": "",
      "name": "Marvel Loch Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.4666996002,
      "lon": 119.5329971313,
      "tz": "Australia\/Perth"
  },
  "YMVM": {
      "icao": "YMVM",
      "iata": "",
      "name": "Mangrove Mountain Airport",
      "city": "Mangrove Mountain",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 920,
      "lat": -33.2851219177,
      "lon": 151.2127380371,
      "tz": "Australia\/Sydney"
  },
  "YMVN": {
      "icao": "YMVN",
      "iata": "",
      "name": "Morven Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.3999996185,
      "lon": 147.1000061035,
      "tz": "Australia\/Brisbane"
  },
  "YMVR": {
      "icao": "YMVR",
      "iata": "",
      "name": "Mount Vernon Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2332992554,
      "lon": 118.233001709,
      "tz": "Australia\/Perth"
  },
  "YMVY": {
      "icao": "YMVY",
      "iata": "",
      "name": "Mount Valley Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -14.0832996368,
      "lon": 133.8170013428,
      "tz": "Australia\/Darwin"
  },
  "YMWA": {
      "icao": "YMWA",
      "iata": "MXU",
      "name": "Mullewa Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 290,
      "lat": -28.4750003815,
      "lon": 115.516998291,
      "tz": "Australia\/Perth"
  },
  "YMWE": {
      "icao": "YMWE",
      "iata": "",
      "name": "Mount Wedge Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 559,
      "lat": -22.7371997833,
      "lon": 132.1510009766,
      "tz": "Australia\/Darwin"
  },
  "YMWM": {
      "icao": "YMWM",
      "iata": "",
      "name": "Mount William Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.2954958244,
      "lon": 142.603397369,
      "tz": "Australia\/Melbourne"
  },
  "YMWO": {
      "icao": "YMWO",
      "iata": "",
      "name": "Mahanewo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7182998657,
      "lon": 136.4329986572,
      "tz": "Australia\/Adelaide"
  },
  "YMWT": {
      "icao": "YMWT",
      "iata": "MWT",
      "name": "Moolawatana Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.9167003632,
      "lon": 139.75,
      "tz": "Australia\/Adelaide"
  },
  "YMWX": {
      "icao": "YMWX",
      "iata": "MXD",
      "name": "Marion Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.3666992188,
      "lon": 139.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YMYB": {
      "icao": "YMYB",
      "iata": "MBH",
      "name": "Maryborough Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 38,
      "lat": -25.513299942,
      "lon": 152.7149963379,
      "tz": "Australia\/Brisbane"
  },
  "YMYH": {
      "icao": "YMYH",
      "iata": "",
      "name": "Mallapunyah Springs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.9666996002,
      "lon": 135.7830047607,
      "tz": "Australia\/Darwin"
  },
  "YMYI": {
      "icao": "YMYI",
      "iata": "",
      "name": "Marymia Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.0932998657,
      "lon": 119.7549972534,
      "tz": "Australia\/Perth"
  },
  "YMYR": {
      "icao": "YMYR",
      "iata": "MYO",
      "name": "Myroodan Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.1166992188,
      "lon": 124.266998291,
      "tz": "Australia\/Perth"
  },
  "YMYT": {
      "icao": "YMYT",
      "iata": "RTY",
      "name": "Merty Merty Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.5832996368,
      "lon": 140.3170013428,
      "tz": "Australia\/Adelaide"
  },
  "YMYV": {
      "icao": "YMYV",
      "iata": "",
      "name": "Maryvale Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.6599998474,
      "lon": 134.0319976807,
      "tz": "Australia\/Darwin"
  },
  "YMYW": {
      "icao": "YMYW",
      "iata": "",
      "name": "Murray Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.0482997894,
      "lon": 134.6829986572,
      "tz": "Australia\/Darwin"
  },
  "YMYY": {
      "icao": "YMYY",
      "iata": "",
      "name": "Mary Valley Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.0500001907,
      "lon": 143.75,
      "tz": "Australia\/Brisbane"
  },
  "YMZI": {
      "icao": "YMZI",
      "iata": "",
      "name": "Menzies Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.6667003632,
      "lon": 121.016998291,
      "tz": "Australia\/Perth"
  },
  "YNAB": {
      "icao": "YNAB",
      "iata": "",
      "name": "Nabarlek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.3083000183,
      "lon": 133.31300354,
      "tz": "Australia\/Darwin"
  },
  "YNAN": {
      "icao": "YNAN",
      "iata": "",
      "name": "Nanango Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.6900005341,
      "lon": 151.983001709,
      "tz": "Australia\/Brisbane"
  },
  "YNAP": {
      "icao": "YNAP",
      "iata": "NMR",
      "name": "Nappa Merrie Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 55,
      "lat": -27.5583000183,
      "lon": 141.1329956055,
      "tz": "Australia\/Brisbane"
  },
  "YNAR": {
      "icao": "YNAR",
      "iata": "NRA",
      "name": "Narrandera Airport",
      "city": "Narrandera",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 474,
      "lat": -34.7022018433,
      "lon": 146.511993408,
      "tz": "Australia\/Sydney"
  },
  "YNAU": {
      "icao": "YNAU",
      "iata": "",
      "name": "Nannup Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.0332984924,
      "lon": 115.733001709,
      "tz": "Australia\/Perth"
  },
  "YNBR": {
      "icao": "YNBR",
      "iata": "NAA",
      "name": "Narrabri Airport",
      "city": "Narrabri",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 788,
      "lat": -30.3192005157,
      "lon": 149.82699585,
      "tz": "Australia\/Sydney"
  },
  "YNCD": {
      "icao": "YNCD",
      "iata": "",
      "name": "Noccundra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7999992371,
      "lon": 142.5829925537,
      "tz": "Australia\/Brisbane"
  },
  "YNCS": {
      "icao": "YNCS",
      "iata": "",
      "name": "New Crown Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.6667003632,
      "lon": 134.8329925537,
      "tz": "Australia\/Darwin"
  },
  "YNDG": {
      "icao": "YNDG",
      "iata": "",
      "name": "Newdegate Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.1166992188,
      "lon": 119,
      "tz": "Australia\/Perth"
  },
  "YNDR": {
      "icao": "YNDR",
      "iata": "",
      "name": "Nundroo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7800006866,
      "lon": 132.2230072021,
      "tz": "Australia\/Adelaide"
  },
  "YNDS": {
      "icao": "YNDS",
      "iata": "",
      "name": "Natal Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.0832996368,
      "lon": 146.1499938965,
      "tz": "Australia\/Brisbane"
  },
  "YNES": {
      "icao": "YNES",
      "iata": "",
      "name": "Nelson Springs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.2999992371,
      "lon": 129.2830047607,
      "tz": "Australia\/Darwin"
  },
  "YNGB": {
      "icao": "YNGB",
      "iata": "",
      "name": "Nagambie Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.7383003235,
      "lon": 145.125,
      "tz": "Australia\/Melbourne"
  },
  "YNGU": {
      "icao": "YNGU",
      "iata": "RPM",
      "name": "Ngukurr Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 45,
      "lat": -14.7228002548,
      "lon": 134.7469940186,
      "tz": "Australia\/Darwin"
  },
  "YNHE": {
      "icao": "YNHE",
      "iata": "",
      "name": "Newhaven Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.5332984924,
      "lon": 145.3170013428,
      "tz": "Australia\/Melbourne"
  },
  "YNHL": {
      "icao": "YNHL",
      "iata": "",
      "name": "Nhill Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 454,
      "lat": -36.3097000122,
      "lon": 141.6410064697,
      "tz": "Australia\/Melbourne"
  },
  "YNHP": {
      "icao": "YNHP",
      "iata": "",
      "name": "Northampton Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.3500003815,
      "lon": 114.6829986572,
      "tz": "Australia\/Perth"
  },
  "YNHS": {
      "icao": "YNHS",
      "iata": "NBH",
      "name": "Nambucca Heads Airport",
      "city": "Nambucca Heads",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.6499996185,
      "lon": 153,
      "tz": "Australia\/Sydney"
  },
  "YNHV": {
      "icao": "YNHV",
      "iata": "",
      "name": "New Haven Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.7283000946,
      "lon": 131.1450042725,
      "tz": "Australia\/Darwin"
  },
  "YNIC": {
      "icao": "YNIC",
      "iata": "NLS",
      "name": "Nicholson Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.0499992371,
      "lon": 128.8999938965,
      "tz": "Australia\/Perth"
  },
  "YNIG": {
      "icao": "YNIG",
      "iata": "",
      "name": "Nonning Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.521900177,
      "lon": 136.4940032959,
      "tz": "Australia\/Adelaide"
  },
  "YNIN": {
      "icao": "YNIN",
      "iata": "",
      "name": "Meningie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35.7000007629,
      "lon": 139.3329925537,
      "tz": "Australia\/Adelaide"
  },
  "YNKA": {
      "icao": "YNKA",
      "iata": "",
      "name": "Noonkanbah Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.5,
      "lon": 124.8330001831,
      "tz": "Australia\/Perth"
  },
  "YNMN": {
      "icao": "YNMN",
      "iata": "",
      "name": "New Moon Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.2000007629,
      "lon": 145.766998291,
      "tz": "Australia\/Brisbane"
  },
  "YNNT": {
      "icao": "YNNT",
      "iata": "",
      "name": "Nantarra Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.5333003998,
      "lon": 115.5,
      "tz": "Australia\/Perth"
  },
  "YNOC": {
      "icao": "YNOC",
      "iata": "",
      "name": "Nockatunga Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7166996002,
      "lon": 142.7120056152,
      "tz": "Australia\/Brisbane"
  },
  "YNOF": {
      "icao": "YNOF",
      "iata": "",
      "name": "Norfolk Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.3332996368,
      "lon": 138.3329925537,
      "tz": "Australia\/Brisbane"
  },
  "YNON": {
      "icao": "YNON",
      "iata": "",
      "name": "Noondoonia Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.2832984924,
      "lon": 123.6669998169,
      "tz": "Australia\/Perth"
  },
  "YNOO": {
      "icao": "YNOO",
      "iata": "",
      "name": "Nooyeah Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.0499992371,
      "lon": 143.5829925537,
      "tz": "Australia\/Brisbane"
  },
  "YNOR": {
      "icao": "YNOR",
      "iata": "",
      "name": "Norwood Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.25,
      "lon": 144.3329925537,
      "tz": "Australia\/Sydney"
  },
  "YNPA": {
      "icao": "YNPA",
      "iata": "",
      "name": "Nilpinna Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.4832992554,
      "lon": 135.8999938965,
      "tz": "Australia\/Adelaide"
  },
  "YNPB": {
      "icao": "YNPB",
      "iata": "",
      "name": "Napperby Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.5333003998,
      "lon": 133.7630004883,
      "tz": "Australia\/Darwin"
  },
  "YNPD": {
      "icao": "YNPD",
      "iata": "",
      "name": "Napier Downs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -17.3166999817,
      "lon": 124.8499984741,
      "tz": "Australia\/Perth"
  },
  "YNPU": {
      "icao": "YNPU",
      "iata": "",
      "name": "Nepabunna Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.6033000946,
      "lon": 138.9479980469,
      "tz": "Australia\/Adelaide"
  },
  "YNRB": {
      "icao": "YNRB",
      "iata": "",
      "name": "Narembeen Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 305,
      "lat": -32.1166992188,
      "lon": 118.4169998169,
      "tz": "Australia\/Perth"
  },
  "YNRC": {
      "icao": "YNRC",
      "iata": "NAC",
      "name": "Naracoorte Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 169,
      "lat": -36.9852981567,
      "lon": 140.7250061035,
      "tz": "Australia\/Adelaide"
  },
  "YNRG": {
      "icao": "YNRG",
      "iata": "NRG",
      "name": "Narrogin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 329,
      "lat": -32.9300003052,
      "lon": 117.0800018311,
      "tz": "Australia\/Perth"
  },
  "YNRL": {
      "icao": "YNRL",
      "iata": "",
      "name": "Naryilco Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.5499992371,
      "lon": 141.9170074463,
      "tz": "Australia\/Brisbane"
  },
  "YNRM": {
      "icao": "YNRM",
      "iata": "QRM",
      "name": "Narromine Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 782,
      "lat": -32.2146987915,
      "lon": 148.2250061035,
      "tz": "Australia\/Sydney"
  },
  "YNRR": {
      "icao": "YNRR",
      "iata": "",
      "name": "Nyrripi Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.6452999115,
      "lon": 130.5650024414,
      "tz": "Australia\/Darwin"
  },
  "YNRV": {
      "icao": "YNRV",
      "iata": "RVT",
      "name": "Ravensthorpe Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 197,
      "lat": -33.7971992493,
      "lon": 120.208000183,
      "tz": "Australia\/Perth"
  },
  "YNSH": {
      "icao": "YNSH",
      "iata": "NSV",
      "name": "Noosa Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1,
      "lat": -26.4232997894,
      "lon": 153.06300354,
      "tz": "Australia\/Brisbane"
  },
  "YNSM": {
      "icao": "YNSM",
      "iata": "NSM",
      "name": "Norseman Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 263,
      "lat": -32.2099990845,
      "lon": 121.7549972534,
      "tz": "Australia\/Perth"
  },
  "YNTI": {
      "icao": "YNTI",
      "iata": "",
      "name": "Abrolhos North Island",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1,
      "lat": -28.2998166667,
      "lon": 113.5958,
      "tz": "Australia\/Perth"
  },
  "YNTM": {
      "icao": "YNTM",
      "iata": "",
      "name": "Northam Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 152,
      "lat": -31.6299991608,
      "lon": 116.6829986572,
      "tz": "Australia\/Perth"
  },
  "YNTN": {
      "icao": "YNTN",
      "iata": "NTN",
      "name": "Normanton Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 73,
      "lat": -17.683599472,
      "lon": 141.0700073242,
      "tz": "Australia\/Brisbane"
  },
  "YNUB": {
      "icao": "YNUB",
      "iata": "NUR",
      "name": "Nullabor Motel Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 67,
      "lat": -31.4416999817,
      "lon": 130.9019927979,
      "tz": "Australia\/Adelaide"
  },
  "YNUD": {
      "icao": "YNUD",
      "iata": "",
      "name": "Nammuldi Mine Airstrip",
      "city": "Nammuldi Mine",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 267,
      "lat": -22.39186,
      "lon": 117.37623,
      "tz": "Australia\/Perth"
  },
  "YNUE": {
      "icao": "YNUE",
      "iata": "",
      "name": "Numery Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.9832992554,
      "lon": 135.3999938965,
      "tz": "Australia\/Darwin"
  },
  "YNUJ": {
      "icao": "YNUJ",
      "iata": "",
      "name": "Nudjaburra Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.9666996002,
      "lon": 137.9329986572,
      "tz": "Australia\/Darwin"
  },
  "YNUL": {
      "icao": "YNUL",
      "iata": "NLL",
      "name": "Nullagine Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 381,
      "lat": -21.9132995605,
      "lon": 120.1979980469,
      "tz": "Australia\/Perth"
  },
  "YNUM": {
      "icao": "YNUM",
      "iata": "NUB",
      "name": "Numbulwar Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 31,
      "lat": -14.2716999054,
      "lon": 135.7169952393,
      "tz": "Australia\/Darwin"
  },
  "YNUT": {
      "icao": "YNUT",
      "iata": "",
      "name": "Nutwood Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -15.8166999817,
      "lon": 134.1499938965,
      "tz": "Australia\/Darwin"
  },
  "YNVE": {
      "icao": "YNVE",
      "iata": "",
      "name": "Navarre Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.9166984558,
      "lon": 143.1000061035,
      "tz": "Australia\/Melbourne"
  },
  "YNVL": {
      "icao": "YNVL",
      "iata": "",
      "name": "Normanville Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.7832984924,
      "lon": 143.6999969482,
      "tz": "Australia\/Melbourne"
  },
  "YNVR": {
      "icao": "YNVR",
      "iata": "",
      "name": "Navarra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.8500003815,
      "lon": 143.6999969482,
      "tz": "Australia\/Brisbane"
  },
  "YNWL": {
      "icao": "YNWL",
      "iata": "",
      "name": "North Well Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.8460998535,
      "lon": 135.3090057373,
      "tz": "Australia\/Adelaide"
  },
  "YNWN": {
      "icao": "YNWN",
      "iata": "ZNE",
      "name": "Newman Airport",
      "city": "Newman",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1724,
      "lat": -23.4178009033,
      "lon": 119.803001404,
      "tz": "Australia\/Perth"
  },
  "YNWT": {
      "icao": "YNWT",
      "iata": "",
      "name": "Narwietooma Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2332992554,
      "lon": 132.6329956055,
      "tz": "Australia\/Darwin"
  },
  "YNYG": {
      "icao": "YNYG",
      "iata": "",
      "name": "Nyang Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.0333003998,
      "lon": 115.0329971313,
      "tz": "Australia\/Perth"
  },
  "YNYM": {
      "icao": "YNYM",
      "iata": "",
      "name": "Nymagee Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.0499992371,
      "lon": 146.3170013428,
      "tz": "Australia\/Sydney"
  },
  "YNYN": {
      "icao": "YNYN",
      "iata": "NYN",
      "name": "Nyngan Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 569,
      "lat": -31.5510997772,
      "lon": 147.2030029297,
      "tz": "Australia\/Sydney"
  },
  "YNYP": {
      "icao": "YNYP",
      "iata": "",
      "name": "Nypari Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.2000007629,
      "lon": 130.233001709,
      "tz": "Australia\/Adelaide"
  },
  "YOAD": {
      "icao": "YOAD",
      "iata": "",
      "name": "Old Andado Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3833007813,
      "lon": 135.4170074463,
      "tz": "Australia\/Darwin"
  },
  "YOAP": {
      "icao": "YOAP",
      "iata": "",
      "name": "One Arm Point Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -16.4333000183,
      "lon": 123.0500030518,
      "tz": "Australia\/Perth"
  },
  "YOAS": {
      "icao": "YOAS",
      "iata": "",
      "name": "The Oaks Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 909,
      "lat": -34.0839004517,
      "lon": 150.5590057373,
      "tz": "Australia\/Sydney"
  },
  "YOAY": {
      "icao": "YOAY",
      "iata": "",
      "name": "Oaky Creek Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 177,
      "lat": -23.0599994659,
      "lon": 148.4949951172,
      "tz": "Australia\/Brisbane"
  },
  "YOBR": {
      "icao": "YOBR",
      "iata": "",
      "name": "Old Bar Heritage Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 10,
      "lat": -31.9652996063,
      "lon": 152.591003418,
      "tz": "Australia\/Sydney"
  },
  "YODA": {
      "icao": "YODA",
      "iata": "",
      "name": "Ooldea Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.4566993713,
      "lon": 131.8229980469,
      "tz": "Australia\/Adelaide"
  },
  "YODL": {
      "icao": "YODL",
      "iata": "",
      "name": "Ourdel Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3500003815,
      "lon": 142.6329956055,
      "tz": "Australia\/Brisbane"
  },
  "YOEN": {
      "icao": "YOEN",
      "iata": "OPI",
      "name": "Oenpelli Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 30,
      "lat": -12.3249998093,
      "lon": 133.0059967041,
      "tz": "Australia\/Darwin"
  },
  "YOIT": {
      "icao": "YOIT",
      "iata": "",
      "name": "Orielton Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.0666999817,
      "lon": 144.1499938965,
      "tz": "Australia\/Brisbane"
  },
  "YOKE": {
      "icao": "YOKE",
      "iata": "",
      "name": "Oakvale Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.2667007446,
      "lon": 145.983001709,
      "tz": "Australia\/Brisbane"
  },
  "YOKV": {
      "icao": "YOKV",
      "iata": "",
      "name": "Oak Valley Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.5167007446,
      "lon": 130.8670043945,
      "tz": "Australia\/Adelaide"
  },
  "YOLA": {
      "icao": "YOLA",
      "iata": "XCO",
      "name": "Colac Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 137,
      "lat": -38.2867012024,
      "lon": 143.6799926758,
      "tz": "Australia\/Melbourne"
  },
  "YOLD": {
      "icao": "YOLD",
      "iata": "OLP",
      "name": "Olympic Dam Airport",
      "city": "Olympic Dam",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 343,
      "lat": -30.4850006104,
      "lon": 136.876998901,
      "tz": "Australia\/Adelaide"
  },
  "YOLW": {
      "icao": "YOLW",
      "iata": "ONS",
      "name": "Onslow Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 7,
      "lat": -21.6683006287,
      "lon": 115.1129989624,
      "tz": "Australia\/Perth"
  },
  "YOLY": {
      "icao": "YOLY",
      "iata": "",
      "name": "Oxley Station Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 518,
      "lat": -31.013299942,
      "lon": 147.7109985352,
      "tz": "Australia\/Sydney"
  },
  "YOMI": {
      "icao": "YOMI",
      "iata": "",
      "name": "Omicron Station Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -28.7667007446,
      "lon": 141.1999969482,
      "tz": "Australia\/Brisbane"
  },
  "YOMO": {
      "icao": "YOMO",
      "iata": "",
      "name": "Omeo Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.096698761,
      "lon": 147.5930023193,
      "tz": "Australia\/Melbourne"
  },
  "YOOD": {
      "icao": "YOOD",
      "iata": "ODD",
      "name": "Oodnadatta Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 118,
      "lat": -27.5617008209,
      "lon": 135.4470062256,
      "tz": "Australia\/Adelaide"
  },
  "YOOK": {
      "icao": "YOOK",
      "iata": "",
      "name": "Cook Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.6182994843,
      "lon": 130.4060058594,
      "tz": "Australia\/Adelaide"
  },
  "YOOM": {
      "icao": "YOOM",
      "iata": "MOO",
      "name": "Moomba Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 143,
      "lat": -28.0993995667,
      "lon": 140.1970062256,
      "tz": "Australia\/Adelaide"
  },
  "YOOO": {
      "icao": "YOOO",
      "iata": "",
      "name": "Mooloola Homestead Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.3332996368,
      "lon": 131.5,
      "tz": "Australia\/Darwin"
  },
  "YORA": {
      "icao": "YORA",
      "iata": "",
      "name": "Moonerah Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.6893997192,
      "lon": 126.5859985352,
      "tz": "Australia\/Eucla"
  },
  "YORB": {
      "icao": "YORB",
      "iata": "RBS",
      "name": "Orbost Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 28,
      "lat": -37.7900009155,
      "lon": 148.6100006104,
      "tz": "Australia\/Melbourne"
  },
  "YORG": {
      "icao": "YORG",
      "iata": "OAG",
      "name": "Orange Airport",
      "city": "Orange",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3115,
      "lat": -33.3816986084,
      "lon": 149.132995605,
      "tz": "Australia\/Sydney"
  },
  "YORL": {
      "icao": "YORL",
      "iata": "",
      "name": "Orleans Farm Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.7832984924,
      "lon": 122.9499969482,
      "tz": "Australia\/Perth"
  },
  "YORR": {
      "icao": "YORR",
      "iata": "",
      "name": "Orroroo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 381,
      "lat": -32.7733001709,
      "lon": 138.6649932861,
      "tz": "Australia\/Adelaide"
  },
  "YORT": {
      "icao": "YORT",
      "iata": "",
      "name": "Ooratippra Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.9057998657,
      "lon": 136.0690002441,
      "tz": "Australia\/Darwin"
  },
  "YORV": {
      "icao": "YORV",
      "iata": "ODR",
      "name": "Ord River Airport",
      "city": "Ord River",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 390,
      "lat": -17.3407993317,
      "lon": 128.9120025635,
      "tz": "Australia\/Perth"
  },
  "YORW": {
      "icao": "YORW",
      "iata": "",
      "name": "Orient Well Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.1991996765,
      "lon": 121.43800354,
      "tz": "Australia\/Perth"
  },
  "YOSB": {
      "icao": "YOSB",
      "iata": "OSO",
      "name": "Osborne Mine Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 285,
      "lat": -22.0816993713,
      "lon": 140.554992676,
      "tz": "Australia\/Brisbane"
  },
  "YOTN": {
      "icao": "YOTN",
      "iata": "",
      "name": "Ootann Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.4333000183,
      "lon": 144.6329956055,
      "tz": "Australia\/Brisbane"
  },
  "YOUN": {
      "icao": "YOUN",
      "iata": "",
      "name": "Youanmi Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.6149997711,
      "lon": 118.8420028687,
      "tz": "Australia\/Perth"
  },
  "YOUY": {
      "icao": "YOUY",
      "iata": "OYN",
      "name": "Ouyen Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.0890124268,
      "lon": 142.354488373,
      "tz": "Australia\/Melbourne"
  },
  "YPAC": {
      "icao": "YPAC",
      "iata": "",
      "name": "Pacific Haven Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.236700058,
      "lon": 152.5429992676,
      "tz": "Australia\/Brisbane"
  },
  "YPAD": {
      "icao": "YPAD",
      "iata": "ADL",
      "name": "Adelaide International Airport",
      "city": "Adelaide",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 20,
      "lat": -34.9449996948,
      "lon": 138.5310058594,
      "tz": "Australia\/Adelaide"
  },
  "YPAG": {
      "icao": "YPAG",
      "iata": "PUG",
      "name": "Port Augusta Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 56,
      "lat": -32.5069007874,
      "lon": 137.7169952393,
      "tz": "Australia\/Adelaide"
  },
  "YPAM": {
      "icao": "YPAM",
      "iata": "PMK",
      "name": "Palm Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 28,
      "lat": -18.7553005219,
      "lon": 146.5809936523,
      "tz": "Australia\/Brisbane"
  },
  "YPAY": {
      "icao": "YPAY",
      "iata": "",
      "name": "Papunya Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 620,
      "lat": -23.2467002869,
      "lon": 131.9029998779,
      "tz": "Australia\/Darwin"
  },
  "YPBH": {
      "icao": "YPBH",
      "iata": "",
      "name": "Peterborough Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.5999984741,
      "lon": 142.9170074463,
      "tz": "Australia\/Melbourne"
  },
  "YPBO": {
      "icao": "YPBO",
      "iata": "PBO",
      "name": "Paraburdoo Airport",
      "city": "Paraburdoo",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1406,
      "lat": -23.1711006165,
      "lon": 117.745002747,
      "tz": "Australia\/Perth"
  },
  "YPCC": {
      "icao": "YPCC",
      "iata": "CCK",
      "name": "Cocos (Keeling) Islands Airport",
      "city": "Cocos (Keeling) Islands",
      "state": "",
      "country": "CC",
      "elevation": 10,
      "lat": -12.1883001328,
      "lon": 96.8339004517,
      "tz": "Indian\/Cocos"
  },
  "YPCE": {
      "icao": "YPCE",
      "iata": "",
      "name": "Pooncarie Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 50,
      "lat": -33.3666992188,
      "lon": 142.5879974365,
      "tz": "Australia\/Sydney"
  },
  "YPCH": {
      "icao": "YPCH",
      "iata": "",
      "name": "Patchewollock Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.3666992188,
      "lon": 142.1499938965,
      "tz": "Australia\/Melbourne"
  },
  "YPCS": {
      "icao": "YPCS",
      "iata": "",
      "name": "Pinnacles Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.2000007629,
      "lon": 120.4329986572,
      "tz": "Australia\/Perth"
  },
  "YPDI": {
      "icao": "YPDI",
      "iata": "PDE",
      "name": "Pandie Pandie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.1166992188,
      "lon": 139.3999938965,
      "tz": "Australia\/Adelaide"
  },
  "YPDN": {
      "icao": "YPDN",
      "iata": "DRW",
      "name": "Darwin International Airport",
      "city": "Darwin",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 103,
      "lat": -12.4146995544,
      "lon": 130.8769989014,
      "tz": "Australia\/Darwin"
  },
  "YPDO": {
      "icao": "YPDO",
      "iata": "PRD",
      "name": "Pardoo Airport",
      "city": "Pardoo",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1175003052,
      "lon": 119.5899963379,
      "tz": "Australia\/Perth"
  },
  "YPDY": {
      "icao": "YPDY",
      "iata": "",
      "name": "Padthaway Station Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -36.6166992188,
      "lon": 140.483001709,
      "tz": "Australia\/Adelaide"
  },
  "YPEA": {
      "icao": "YPEA",
      "iata": "",
      "name": "RAAF Base Pearce",
      "city": "Bullsbrook",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 149,
      "lat": -31.6678009033,
      "lon": 116.0149993896,
      "tz": "Australia\/Perth"
  },
  "YPEC": {
      "icao": "YPEC",
      "iata": "BEO",
      "name": "Aeropelican Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2,
      "lat": -33.0666999817,
      "lon": 151.6479949951,
      "tz": "Australia\/Sydney"
  },
  "YPED": {
      "icao": "YPED",
      "iata": "",
      "name": "RAAF Base Edinburgh",
      "city": "Adelaide",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 67,
      "lat": -34.7024993896,
      "lon": 138.6210021973,
      "tz": "Australia\/Adelaide"
  },
  "YPEF": {
      "icao": "YPEF",
      "iata": "",
      "name": "Penfield Airfield",
      "city": "Sunbury",
      "state": "Victoria",
      "country": "AU",
      "elevation": 1150,
      "lat": -37.5133018494,
      "lon": 144.697998047,
      "tz": "Australia\/Melbourne"
  },
  "YPFT": {
      "icao": "YPFT",
      "iata": "",
      "name": "Cooma\/Polo Flat (Unlic) Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 823,
      "lat": -36.2299995422,
      "lon": 149.1499938965,
      "tz": "Australia\/Sydney"
  },
  "YPGH": {
      "icao": "YPGH",
      "iata": "",
      "name": "Pigeon Hole Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.8166999817,
      "lon": 131.2169952393,
      "tz": "Australia\/Darwin"
  },
  "YPGV": {
      "icao": "YPGV",
      "iata": "GOV",
      "name": "Gove Airport",
      "city": "Nhulunbuy",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 192,
      "lat": -12.2693996429,
      "lon": 136.817993164,
      "tz": "Australia\/Darwin"
  },
  "YPID": {
      "icao": "YPID",
      "iata": "",
      "name": "Phillip Island Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 13,
      "lat": -38.5233001709,
      "lon": 145.3269958496,
      "tz": "Australia\/Melbourne"
  },
  "YPIN": {
      "icao": "YPIN",
      "iata": "",
      "name": "Pinnacle Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.6667003632,
      "lon": 143.5500030518,
      "tz": "Australia\/Brisbane"
  },
  "YPIR": {
      "icao": "YPIR",
      "iata": "PPI",
      "name": "Port Pirie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 40,
      "lat": -33.238899231,
      "lon": 137.9949951172,
      "tz": "Australia\/Adelaide"
  },
  "YPIX": {
      "icao": "YPIX",
      "iata": "",
      "name": "Pia Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.1916999817,
      "lon": 116.2919998169,
      "tz": "Australia\/Perth"
  },
  "YPIY": {
      "icao": "YPIY",
      "iata": "",
      "name": "Pingelly Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.5400009155,
      "lon": 117.0729980469,
      "tz": "Australia\/Perth"
  },
  "YPJI": {
      "icao": "YPJI",
      "iata": "",
      "name": "Perenjori Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.4333000183,
      "lon": 116.2829971313,
      "tz": "Australia\/Perth"
  },
  "YPJT": {
      "icao": "YPJT",
      "iata": "JAD",
      "name": "Perth Jandakot Airport",
      "city": "Perth",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 99,
      "lat": -32.0974998474,
      "lon": 115.8809967041,
      "tz": "Australia\/Perth"
  },
  "YPKA": {
      "icao": "YPKA",
      "iata": "KTA",
      "name": "Karratha Airport",
      "city": "Karratha",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 29,
      "lat": -20.7122001648,
      "lon": 116.773002625,
      "tz": "Australia\/Perth"
  },
  "YPKE": {
      "icao": "YPKE",
      "iata": "",
      "name": "Peake Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.25,
      "lon": 135.8999938965,
      "tz": "Australia\/Adelaide"
  },
  "YPKG": {
      "icao": "YPKG",
      "iata": "KGI",
      "name": "Kalgoorlie Boulder Airport",
      "city": "Kalgoorlie",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1203,
      "lat": -30.7894001007,
      "lon": 121.461997986,
      "tz": "Australia\/Perth"
  },
  "YPKH": {
      "icao": "YPKH",
      "iata": "",
      "name": "Peak Hill Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.75,
      "lon": 148.1999969482,
      "tz": "Australia\/Sydney"
  },
  "YPKI": {
      "icao": "YPKI",
      "iata": "",
      "name": "Parakylia Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.3999996185,
      "lon": 136.391998291,
      "tz": "Australia\/Adelaide"
  },
  "YPKL": {
      "icao": "YPKL",
      "iata": "",
      "name": "Puckapunyal (Military) Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 168,
      "lat": -37,
      "lon": 145.06300354,
      "tz": "Australia\/Melbourne"
  },
  "YPKS": {
      "icao": "YPKS",
      "iata": "PKE",
      "name": "Parkes Airport",
      "city": "Parkes",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1069,
      "lat": -33.131401062,
      "lon": 148.238998413,
      "tz": "Australia\/Sydney"
  },
  "YPKT": {
      "icao": "YPKT",
      "iata": "PKT",
      "name": "Port Keats Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 91,
      "lat": -14.25,
      "lon": 129.529006958,
      "tz": "Australia\/Darwin"
  },
  "YPKU": {
      "icao": "YPKU",
      "iata": "KNX",
      "name": "Kununurra Airport",
      "city": "Kununurra",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 145,
      "lat": -15.7781000137,
      "lon": 128.707992554,
      "tz": "Australia\/Perth"
  },
  "YPLC": {
      "icao": "YPLC",
      "iata": "PLO",
      "name": "Port Lincoln Airport",
      "city": "Port Lincoln",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 36,
      "lat": -34.6053009033,
      "lon": 135.880004883,
      "tz": "Australia\/Adelaide"
  },
  "YPLG": {
      "icao": "YPLG",
      "iata": "",
      "name": "Pilliga Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.3666992188,
      "lon": 148.8829956055,
      "tz": "Australia\/Sydney"
  },
  "YPLI": {
      "icao": "YPLI",
      "iata": "",
      "name": "Palmers Island\/Yamba Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.4458007813,
      "lon": 153.266998291,
      "tz": "Australia\/Sydney"
  },
  "YPLL": {
      "icao": "YPLL",
      "iata": "",
      "name": "Peak Hill Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.6000003815,
      "lon": 118.6829986572,
      "tz": "Australia\/Perth"
  },
  "YPLM": {
      "icao": "YPLM",
      "iata": "LEA",
      "name": "Learmonth Airport",
      "city": "Exmouth",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 19,
      "lat": -22.2355995178,
      "lon": 114.088996887,
      "tz": "Australia\/Perth"
  },
  "YPLU": {
      "icao": "YPLU",
      "iata": "",
      "name": "Plutonic Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3332996368,
      "lon": 119.4250030518,
      "tz": "Australia\/Perth"
  },
  "YPMB": {
      "icao": "YPMB",
      "iata": "",
      "name": "Plumbago Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.0532989502,
      "lon": 139.8719940186,
      "tz": "Australia\/Adelaide"
  },
  "YPME": {
      "icao": "YPME",
      "iata": "",
      "name": "Palmer Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.8332996368,
      "lon": 130.9329986572,
      "tz": "Australia\/Darwin"
  },
  "YPMP": {
      "icao": "YPMP",
      "iata": "EDR",
      "name": "Pormpuraaw Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 10,
      "lat": -14.8966999054,
      "lon": 141.6089935303,
      "tz": "Australia\/Brisbane"
  },
  "YPMQ": {
      "icao": "YPMQ",
      "iata": "PQQ",
      "name": "Port Macquarie Airport",
      "city": "Port Macquarie",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 12,
      "lat": -31.4358005524,
      "lon": 152.863006592,
      "tz": "Australia\/Sydney"
  },
  "YPNC": {
      "icao": "YPNC",
      "iata": "",
      "name": "Pine Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -13.8332996368,
      "lon": 131.8329925537,
      "tz": "Australia\/Darwin"
  },
  "YPNG": {
      "icao": "YPNG",
      "iata": "",
      "name": "Penong Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.9167003632,
      "lon": 133,
      "tz": "Australia\/Adelaide"
  },
  "YPNI": {
      "icao": "YPNI",
      "iata": "",
      "name": "Prenti Downs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.5167007446,
      "lon": 122.8000030518,
      "tz": "Australia\/Perth"
  },
  "YPNN": {
      "icao": "YPNN",
      "iata": "",
      "name": "Pinnaroo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 76,
      "lat": -35.2532997131,
      "lon": 140.9429931641,
      "tz": "Australia\/Adelaide"
  },
  "YPNW": {
      "icao": "YPNW",
      "iata": "",
      "name": "Pannawonica Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.6166992188,
      "lon": 116.3170013428,
      "tz": "Australia\/Perth"
  },
  "YPOD": {
      "icao": "YPOD",
      "iata": "PTJ",
      "name": "Portland Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 265,
      "lat": -38.3180999756,
      "lon": 141.470993042,
      "tz": "Australia\/Melbourne"
  },
  "YPOK": {
      "icao": "YPOK",
      "iata": "MBF",
      "name": "Porepunkah Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 935,
      "lat": -36.7177307062,
      "lon": 146.890039444,
      "tz": "Australia\/Melbourne"
  },
  "YPOP": {
      "icao": "YPOP",
      "iata": "",
      "name": "Porphyry Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.7782993317,
      "lon": 122.2679977417,
      "tz": "Australia\/Perth"
  },
  "YPPD": {
      "icao": "YPPD",
      "iata": "PHE",
      "name": "Port Hedland International Airport",
      "city": "Port Hedland",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 33,
      "lat": -20.3777999878,
      "lon": 118.625999451,
      "tz": "Australia\/Perth"
  },
  "YPPF": {
      "icao": "YPPF",
      "iata": "",
      "name": "Adelaide Parafield Airport",
      "city": "Adelaide",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 57,
      "lat": -34.7933006287,
      "lon": 138.632995605,
      "tz": "Australia\/Adelaide"
  },
  "YPPH": {
      "icao": "YPPH",
      "iata": "PER",
      "name": "Perth International Airport",
      "city": "Perth",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 67,
      "lat": -31.9402999878,
      "lon": 115.9670028687,
      "tz": "Australia\/Perth"
  },
  "YPRA": {
      "icao": "YPRA",
      "iata": "",
      "name": "Prairie Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.3072013855,
      "lon": 144.1390075684,
      "tz": "Australia\/Melbourne"
  },
  "YPRE": {
      "icao": "YPRE",
      "iata": "",
      "name": "Premer Betoota Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1257,
      "lat": -31.4769001007,
      "lon": 149.9029998779,
      "tz": "Australia\/Sydney"
  },
  "YPSH": {
      "icao": "YPSH",
      "iata": "PEA",
      "name": "Penneshaw Airport",
      "city": "Ironstone",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35.7558462874,
      "lon": 137.962875366,
      "tz": "Australia\/Adelaide"
  },
  "YPTB": {
      "icao": "YPTB",
      "iata": "",
      "name": "Peterborough Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.0050010681,
      "lon": 138.858001709,
      "tz": "Australia\/Adelaide"
  },
  "YPTJ": {
      "icao": "YPTJ",
      "iata": "",
      "name": "Patjarr Airport",
      "city": "Patjarr",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1493,
      "lat": -24.6189002991,
      "lon": 126.327003479,
      "tz": "Australia\/Perth"
  },
  "YPTN": {
      "icao": "YPTN",
      "iata": "KTR",
      "name": "Tindal Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 443,
      "lat": -14.5211000443,
      "lon": 132.3780059814,
      "tz": "Australia\/Darwin"
  },
  "YPUA": {
      "icao": "YPUA",
      "iata": "",
      "name": "Palumpa Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -14.3332996368,
      "lon": 129.8670043945,
      "tz": "Australia\/Darwin"
  },
  "YPUN": {
      "icao": "YPUN",
      "iata": "",
      "name": "Punmu Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.0666999817,
      "lon": 123.1669998169,
      "tz": "Australia\/Perth"
  },
  "YPVD": {
      "icao": "YPVD",
      "iata": "",
      "name": "Plevna Downs Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.6667003632,
      "lon": 142.5829925537,
      "tz": "Australia\/Brisbane"
  },
  "YPWH": {
      "icao": "YPWH",
      "iata": "",
      "name": "Pittsworth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.7208003998,
      "lon": 151.6329956055,
      "tz": "Australia\/Brisbane"
  },
  "YPWR": {
      "icao": "YPWR",
      "iata": "UMR",
      "name": "Woomera Airfield",
      "city": "Woomera",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 548,
      "lat": -31.1441993713,
      "lon": 136.8170013428,
      "tz": "Australia\/Adelaide"
  },
  "YPXM": {
      "icao": "YPXM",
      "iata": "XCH",
      "name": "Christmas Island Airport",
      "city": "Christmas Island",
      "state": "",
      "country": "CX",
      "elevation": 916,
      "lat": -10.4505996704,
      "lon": 105.6900024414,
      "tz": "Indian\/Christmas"
  },
  "YPYD": {
      "icao": "YPYD",
      "iata": "",
      "name": "Pyramid Hill Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.0666999817,
      "lon": 144.1329956055,
      "tz": "Australia\/Melbourne"
  },
  "YPYF": {
      "icao": "YPYF",
      "iata": "",
      "name": "Paynes Find Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.25,
      "lon": 117.6669998169,
      "tz": "Australia\/Perth"
  },
  "YQBE": {
      "icao": "YQBE",
      "iata": "",
      "name": "Quambone Royona Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 489,
      "lat": -30.8791999817,
      "lon": 147.8419952393,
      "tz": "Australia\/Sydney"
  },
  "YQBK": {
      "icao": "YQBK",
      "iata": "",
      "name": "Quambatook Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.8622016907,
      "lon": 143.5099945068,
      "tz": "Australia\/Melbourne"
  },
  "YQDG": {
      "icao": "YQDG",
      "iata": "",
      "name": "Quairading Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32,
      "lon": 117.4000015259,
      "tz": "Australia\/Perth"
  },
  "YQDI": {
      "icao": "YQDI",
      "iata": "UIR",
      "name": "Quirindi Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1054,
      "lat": -31.4906005859,
      "lon": 150.5140075684,
      "tz": "Australia\/Sydney"
  },
  "YQLP": {
      "icao": "YQLP",
      "iata": "ULP",
      "name": "Quilpie Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 655,
      "lat": -26.6121997833,
      "lon": 144.2530059814,
      "tz": "Australia\/Brisbane"
  },
  "YQNS": {
      "icao": "YQNS",
      "iata": "UEE",
      "name": "Queenstown Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 264,
      "lat": -42.0750007629,
      "lon": 145.5319976807,
      "tz": "Australia\/Hobart"
  },
  "YQON": {
      "icao": "YQON",
      "iata": "",
      "name": "Quondong Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.1632995605,
      "lon": 140.4519958496,
      "tz": "Australia\/Adelaide"
  },
  "YQRN": {
      "icao": "YQRN",
      "iata": "",
      "name": "Quorn Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 293,
      "lat": -32.3199996948,
      "lon": 138.1020050049,
      "tz": "Australia\/Adelaide"
  },
  "YQUA": {
      "icao": "YQUA",
      "iata": "",
      "name": "Quarry2 Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 604,
      "lat": -30.2922000885,
      "lon": 134.5269927979,
      "tz": "Australia\/Adelaide"
  },
  "YQUE": {
      "icao": "YQUE",
      "iata": "",
      "name": "Questa Park Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.3917007446,
      "lon": 143.141998291,
      "tz": "Australia\/Sydney"
  },
  "YRAG": {
      "icao": "YRAG",
      "iata": "",
      "name": "Raglan Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.3773873391,
      "lon": 143.305664062,
      "tz": "Australia\/Melbourne"
  },
  "YRAM": {
      "icao": "YRAM",
      "iata": "",
      "name": "Raymore Homestead Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -26.1499996185,
      "lon": 143.016998291,
      "tz": "Australia\/Brisbane"
  },
  "YRAT": {
      "icao": "YRAT",
      "iata": "",
      "name": "Abrolhos Rat Island",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 16,
      "lat": -28.7197,
      "lon": 113.7845,
      "tz": "Australia\/Perth"
  },
  "YRAV": {
      "icao": "YRAV",
      "iata": "",
      "name": "Ravensthorpe Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.528301239,
      "lon": 119.9530029297,
      "tz": "Australia\/Perth"
  },
  "YRAW": {
      "icao": "YRAW",
      "iata": "",
      "name": "Rawlinna Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31,
      "lon": 125.3170013428,
      "tz": "Australia\/Perth"
  },
  "YRBB": {
      "icao": "YRBB",
      "iata": "",
      "name": "Rainbow Beach Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.8332996368,
      "lon": 153.0670013428,
      "tz": "Australia\/Brisbane"
  },
  "YRBE": {
      "icao": "YRBE",
      "iata": "",
      "name": "Robe Airport",
      "city": "Robe",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 65,
      "lat": -37.1753636288,
      "lon": 139.805231094,
      "tz": "Australia\/Adelaide"
  },
  "YRBM": {
      "icao": "YRBM",
      "iata": "",
      "name": "Redbank Mine Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.1833000183,
      "lon": 137.766998291,
      "tz": "Australia\/Darwin"
  },
  "YRBR": {
      "icao": "YRBR",
      "iata": "",
      "name": "Robinson River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.7182998657,
      "lon": 136.9450073242,
      "tz": "Australia\/Darwin"
  },
  "YRBT": {
      "icao": "YRBT",
      "iata": "",
      "name": "Rabbit Flat Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1833000183,
      "lon": 130.016998291,
      "tz": "Australia\/Darwin"
  },
  "YRBW": {
      "icao": "YRBW",
      "iata": "",
      "name": "Rainbow Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -35.9166984558,
      "lon": 141.983001709,
      "tz": "Australia\/Melbourne"
  },
  "YRDA": {
      "icao": "YRDA",
      "iata": "",
      "name": "Yardea Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4099998474,
      "lon": 135.4850006104,
      "tz": "Australia\/Adelaide"
  },
  "YRDM": {
      "icao": "YRDM",
      "iata": "",
      "name": "Redmont Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.9666996002,
      "lon": 119.016998291,
      "tz": "Australia\/Perth"
  },
  "YRDY": {
      "icao": "YRDY",
      "iata": "",
      "name": "Reedys Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.1317005157,
      "lon": 118.2799987793,
      "tz": "Australia\/Perth"
  },
  "YRED": {
      "icao": "YRED",
      "iata": "",
      "name": "Redcliffe Airport",
      "city": "Redcliffe",
      "state": "Queensland",
      "country": "AU",
      "elevation": 2,
      "lat": -27.2066993713,
      "lon": 153.0679931641,
      "tz": "Australia\/Brisbane"
  },
  "YREN": {
      "icao": "YREN",
      "iata": "RMK",
      "name": "Renmark Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 115,
      "lat": -34.1963996887,
      "lon": 140.6739959717,
      "tz": "Australia\/Adelaide"
  },
  "YRHL": {
      "icao": "YRHL",
      "iata": "",
      "name": "Red Hill Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.9666996002,
      "lon": 116.0670013428,
      "tz": "Australia\/Perth"
  },
  "YRID": {
      "icao": "YRID",
      "iata": "",
      "name": "Riddell Airport",
      "city": "Riddell",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.4799995422,
      "lon": 144.7180023193,
      "tz": "Australia\/Melbourne"
  },
  "YRKE": {
      "icao": "YRKE",
      "iata": "",
      "name": "Kuruc-A-Ruc South Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.9000015259,
      "lon": 143.7169952393,
      "tz": "Australia\/Melbourne"
  },
  "YRKS": {
      "icao": "YRKS",
      "iata": "",
      "name": "Rocklands Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.8666992188,
      "lon": 138.1000061035,
      "tz": "Australia\/Brisbane"
  },
  "YRLE": {
      "icao": "YRLE",
      "iata": "",
      "name": "Rocklea Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.8833007813,
      "lon": 117.4499969482,
      "tz": "Australia\/Perth"
  },
  "YRLL": {
      "icao": "YRLL",
      "iata": "",
      "name": "Rolleston Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.4617004395,
      "lon": 148.6320037842,
      "tz": "Australia\/Brisbane"
  },
  "YRMD": {
      "icao": "YRMD",
      "iata": "RCM",
      "name": "Richmond Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 676,
      "lat": -20.7019004822,
      "lon": 143.1150054932,
      "tz": "Australia\/Brisbane"
  },
  "YRNG": {
      "icao": "YRNG",
      "iata": "RAM",
      "name": "Ramingining Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 206,
      "lat": -12.3563995361,
      "lon": 134.8979949951,
      "tz": "Australia\/Darwin"
  },
  "YRNW": {
      "icao": "YRNW",
      "iata": "",
      "name": "Ringwood Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.8283004761,
      "lon": 134.9620056152,
      "tz": "Australia\/Darwin"
  },
  "YROB": {
      "icao": "YROB",
      "iata": "ROH",
      "name": "Robinhood Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 460,
      "lat": -18.8449993134,
      "lon": 143.7100067139,
      "tz": "Australia\/Brisbane"
  },
  "YROE": {
      "icao": "YROE",
      "iata": "RBU",
      "name": "Roebourne Airport",
      "city": "Roebourne",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.7616996765,
      "lon": 117.156997681,
      "tz": "Australia\/Perth"
  },
  "YROI": {
      "icao": "YROI",
      "iata": "RBC",
      "name": "Robinvale Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 87,
      "lat": -34.6500015259,
      "lon": 142.7830047607,
      "tz": "Australia\/Melbourne"
  },
  "YROK": {
      "icao": "YROK",
      "iata": "",
      "name": "Rocky River Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35.9300003052,
      "lon": 136.7200012207,
      "tz": "Australia\/Adelaide"
  },
  "YROM": {
      "icao": "YROM",
      "iata": "RMA",
      "name": "Roma Airport",
      "city": "Roma",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1032,
      "lat": -26.5450000763,
      "lon": 148.774993896,
      "tz": "Australia\/Brisbane"
  },
  "YROT": {
      "icao": "YROT",
      "iata": "",
      "name": "Rothsay Mine Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.2917003632,
      "lon": 116.8669967651,
      "tz": "Australia\/Perth"
  },
  "YROV": {
      "icao": "YROV",
      "iata": "",
      "name": "Ross River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.599199295,
      "lon": 134.516998291,
      "tz": "Australia\/Darwin"
  },
  "YRRB": {
      "icao": "YRRB",
      "iata": "RPB",
      "name": "Roper Bar Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -14.7383003235,
      "lon": 134.5180053711,
      "tz": "Australia\/Darwin"
  },
  "YRRG": {
      "icao": "YRRG",
      "iata": "",
      "name": "Rhodes Ridge Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.1082992554,
      "lon": 119.3330001831,
      "tz": "Australia\/Perth"
  },
  "YRRR": {
      "icao": "YRRR",
      "iata": "",
      "name": "Raymangirr Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -12.3332996368,
      "lon": 135.6499938965,
      "tz": "Australia\/Darwin"
  },
  "YRSB": {
      "icao": "YRSB",
      "iata": "RSB",
      "name": "Roseberth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 55,
      "lat": -25.8332996368,
      "lon": 139.6499938965,
      "tz": "Australia\/Brisbane"
  },
  "YRSH": {
      "icao": "YRSH",
      "iata": "",
      "name": "Rosehill Heliport Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 4,
      "lat": -33.8300966368,
      "lon": 151.024138927,
      "tz": "Australia\/Sydney"
  },
  "YRSK": {
      "icao": "YRSK",
      "iata": "",
      "name": "Ringer Soak Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -18.7886009216,
      "lon": 128.6239929199,
      "tz": "Australia\/Perth"
  },
  "YRSV": {
      "icao": "YRSV",
      "iata": "",
      "name": "Rosevale Resort Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.1333007813,
      "lon": 145.8999938965,
      "tz": "Australia\/Brisbane"
  },
  "YRSY": {
      "icao": "YRSY",
      "iata": "",
      "name": "Romsey (Riddell\/Penfield) Airport",
      "city": "Romsey",
      "state": "Victoria",
      "country": "AU",
      "elevation": 351,
      "lat": -37.391667,
      "lon": 144.738333,
      "tz": "Australia\/Melbourne"
  },
  "YRTI": {
      "icao": "YRTI",
      "iata": "RTS",
      "name": "Rottnest Island Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 12,
      "lat": -32.0066986084,
      "lon": 115.5400009155,
      "tz": "Australia\/Perth"
  },
  "YRTP": {
      "icao": "YRTP",
      "iata": "RTP",
      "name": "Rutland Plains Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -15.6433000565,
      "lon": 141.8430023193,
      "tz": "Australia\/Brisbane"
  },
  "YRUD": {
      "icao": "YRUD",
      "iata": "",
      "name": "Rudal River Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.5499992371,
      "lon": 122.1500015259,
      "tz": "Australia\/Perth"
  },
  "YRUG": {
      "icao": "YRUG",
      "iata": "",
      "name": "Rugby Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.3917007446,
      "lon": 148.9750061035,
      "tz": "Australia\/Sydney"
  },
  "YRVE": {
      "icao": "YRVE",
      "iata": "",
      "name": "Riveren Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.8999996185,
      "lon": 130.2169952393,
      "tz": "Australia\/Darwin"
  },
  "YRWA": {
      "icao": "YRWA",
      "iata": "",
      "name": "Rawlinna Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.0251998901,
      "lon": 125.1910018921,
      "tz": "Australia\/Perth"
  },
  "YRWH": {
      "icao": "YRWH",
      "iata": "",
      "name": "Ravensworth Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.6333007813,
      "lon": 144.2830047607,
      "tz": "Australia\/Sydney"
  },
  "YRXB": {
      "icao": "YRXB",
      "iata": "",
      "name": "Roxby Downs Station Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.7166996002,
      "lon": 136.7369995117,
      "tz": "Australia\/Adelaide"
  },
  "YRYH": {
      "icao": "YRYH",
      "iata": "RHL",
      "name": "Roy Hill Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.625815891,
      "lon": 119.959030151,
      "tz": "Australia\/Perth"
  },
  "YRYK": {
      "icao": "YRYK",
      "iata": "",
      "name": "Rawnsley Park Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.6535282135,
      "lon": 138.6095275879,
      "tz": "Australia\/Adelaide"
  },
  "YSAH": {
      "icao": "YSAH",
      "iata": "",
      "name": "Sandhill Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.7999992371,
      "lon": 119.6169967651,
      "tz": "Australia\/Perth"
  },
  "YSAI": {
      "icao": "YSAI",
      "iata": "",
      "name": "Saipem Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -23.7000007629,
      "lon": 131.8670043945,
      "tz": "Australia\/Darwin"
  },
  "YSAN": {
      "icao": "YSAN",
      "iata": "NDS",
      "name": "Sandstone Airport",
      "city": "Sandstone",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9799995422,
      "lon": 119.2969970703,
      "tz": "Australia\/Perth"
  },
  "YSAR": {
      "icao": "YSAR",
      "iata": "",
      "name": "Mount Sarah Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.0431003571,
      "lon": 135.2420043945,
      "tz": "Australia\/Adelaide"
  },
  "YSBG": {
      "icao": "YSBG",
      "iata": "",
      "name": "Strathbogie Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.8549995422,
      "lon": 145.7380065918,
      "tz": "Australia\/Melbourne"
  },
  "YSBK": {
      "icao": "YSBK",
      "iata": "BWU",
      "name": "Sydney Bankstown Airport",
      "city": "Sydney",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 29,
      "lat": -33.9244003296,
      "lon": 150.9880065918,
      "tz": "Australia\/Sydney"
  },
  "YSBO": {
      "icao": "YSBO",
      "iata": "",
      "name": "Stanbroke Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.5666999817,
      "lon": 139.7169952393,
      "tz": "Australia\/Brisbane"
  },
  "YSCA": {
      "icao": "YSCA",
      "iata": "",
      "name": "Scotia Sanctuary Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 177,
      "lat": -33.2071990967,
      "lon": 141.17300415,
      "tz": "Australia\/Sydney"
  },
  "YSCB": {
      "icao": "YSCB",
      "iata": "CBR",
      "name": "Canberra International Airport",
      "city": "Canberra",
      "state": "ACT",
      "country": "AU",
      "elevation": 1886,
      "lat": -35.3069000244,
      "lon": 149.1950073242,
      "tz": "Australia\/Sydney"
  },
  "YSCH": {
      "icao": "YSCH",
      "iata": "CFS",
      "name": "Coffs Harbour Airport",
      "city": "Coffs Harbour",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 18,
      "lat": -30.3206005096,
      "lon": 153.115997314,
      "tz": "Australia\/Sydney"
  },
  "YSCN": {
      "icao": "YSCN",
      "iata": "CDU",
      "name": "Camden Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 230,
      "lat": -34.0402984619,
      "lon": 150.68699646,
      "tz": "Australia\/Sydney"
  },
  "YSCO": {
      "icao": "YSCO",
      "iata": "NSO",
      "name": "Scone Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 745,
      "lat": -32.0372009277,
      "lon": 150.8320007324,
      "tz": "Australia\/Sydney"
  },
  "YSCR": {
      "icao": "YSCR",
      "iata": "SQC",
      "name": "Southern Cross Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 354,
      "lat": -31.2399997711,
      "lon": 119.3600006104,
      "tz": "Australia\/Perth"
  },
  "YSDL": {
      "icao": "YSDL",
      "iata": "",
      "name": "Sudley Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -12.75,
      "lon": 142.3670043945,
      "tz": "Australia\/Brisbane"
  },
  "YSDN": {
      "icao": "YSDN",
      "iata": "",
      "name": "Soudan Station Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.0499992371,
      "lon": 137.016998291,
      "tz": "Australia\/Darwin"
  },
  "YSDU": {
      "icao": "YSDU",
      "iata": "DBO",
      "name": "Dubbo City Regional Airport",
      "city": "Dubbo",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 935,
      "lat": -32.2167015076,
      "lon": 148.574996948,
      "tz": "Australia\/Sydney"
  },
  "YSDW": {
      "icao": "YSDW",
      "iata": "",
      "name": "Strathdownie Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.7317008972,
      "lon": 141.125,
      "tz": "Australia\/Melbourne"
  },
  "YSEN": {
      "icao": "YSEN",
      "iata": "",
      "name": "Serpentine Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 9,
      "lat": -32.3949661255,
      "lon": 115.8709869385,
      "tz": "Australia\/Perth"
  },
  "YSFI": {
      "icao": "YSFI",
      "iata": "",
      "name": "Sandfire Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -19.7667007446,
      "lon": 121.1669998169,
      "tz": "Australia\/Perth"
  },
  "YSFL": {
      "icao": "YSFL",
      "iata": "",
      "name": "Stonefield Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.3418769603,
      "lon": 139.308185577,
      "tz": "Australia\/Adelaide"
  },
  "YSFY": {
      "icao": "YSFY",
      "iata": "",
      "name": "Sandfly Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.9916992188,
      "lon": 147.2079925537,
      "tz": "Australia\/Hobart"
  },
  "YSGD": {
      "icao": "YSGD",
      "iata": "",
      "name": "Strathgordon Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.7950000763,
      "lon": 142.4329986572,
      "tz": "Australia\/Brisbane"
  },
  "YSGE": {
      "icao": "YSGE",
      "iata": "SGO",
      "name": "St George Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 656,
      "lat": -28.0496997833,
      "lon": 148.5950012207,
      "tz": "Australia\/Brisbane"
  },
  "YSGR": {
      "icao": "YSGR",
      "iata": "",
      "name": "South Grafton Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 6,
      "lat": -29.7082996368,
      "lon": 152.9279937744,
      "tz": "Australia\/Sydney"
  },
  "YSGT": {
      "icao": "YSGT",
      "iata": "SIX",
      "name": "Singleton Airport",
      "city": "Singleton",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 46,
      "lat": -32.600832,
      "lon": 151.193056,
      "tz": "Australia\/Sydney"
  },
  "YSGW": {
      "icao": "YSGW",
      "iata": "ZGL",
      "name": "South Galway Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 116,
      "lat": -25.6833000183,
      "lon": 142.108001709,
      "tz": "Australia\/Brisbane"
  },
  "YSHG": {
      "icao": "YSHG",
      "iata": "SGP",
      "name": "Shay Gap Airport",
      "city": "Shay Gap",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 620,
      "lat": -20.4246997833,
      "lon": 120.1409988403,
      "tz": "Australia\/Perth"
  },
  "YSHK": {
      "icao": "YSHK",
      "iata": "MJK",
      "name": "Shark Bay Airport",
      "city": "Monkey Mia",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 111,
      "lat": -25.8938999176,
      "lon": 113.577003479,
      "tz": "Australia\/Perth"
  },
  "YSHN": {
      "icao": "YSHN",
      "iata": "",
      "name": "Shannon River Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.75,
      "lon": 116.483001709,
      "tz": "Australia\/Perth"
  },
  "YSHR": {
      "icao": "YSHR",
      "iata": "JHQ",
      "name": "Shute Harbour Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 12,
      "lat": -20.2782993317,
      "lon": 148.7570037842,
      "tz": "Australia\/Brisbane"
  },
  "YSHT": {
      "icao": "YSHT",
      "iata": "SHT",
      "name": "Shepparton Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 374,
      "lat": -36.4289016724,
      "lon": 145.3930053711,
      "tz": "Australia\/Melbourne"
  },
  "YSHW": {
      "icao": "YSHW",
      "iata": "",
      "name": "Holsworthy (Military) Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 76,
      "lat": -33.9949989319,
      "lon": 150.9519958496,
      "tz": "Australia\/Sydney"
  },
  "YSIA": {
      "icao": "YSIA",
      "iata": "",
      "name": "Siam Airport",
      "city": "Siam",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.5574989319,
      "lon": 136.7089996338,
      "tz": "Australia\/Adelaide"
  },
  "YSII": {
      "icao": "YSII",
      "iata": "SBR",
      "name": "Saibai Island Airport",
      "city": "Saibai Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 15,
      "lat": -9.3783302307,
      "lon": 142.625,
      "tz": "Australia\/Brisbane"
  },
  "YSLE": {
      "icao": "YSLE",
      "iata": "",
      "name": "St Leonards Airfield",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 47,
      "lat": -38.1701011658,
      "lon": 144.6889953613,
      "tz": "Australia\/Melbourne"
  },
  "YSLG": {
      "icao": "YSLG",
      "iata": "",
      "name": "Silent Grove Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -17.0666999817,
      "lon": 125.25,
      "tz": "Australia\/Perth"
  },
  "YSLK": {
      "icao": "YSLK",
      "iata": "",
      "name": "Sea Lake Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 56,
      "lat": -35.5266990662,
      "lon": 142.8880004883,
      "tz": "Australia\/Melbourne"
  },
  "YSLN": {
      "icao": "YSLN",
      "iata": "",
      "name": "Strathleven Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -15.8983001709,
      "lon": 143.3829956055,
      "tz": "Australia\/Brisbane"
  },
  "YSLT": {
      "icao": "YSLT",
      "iata": "",
      "name": "Sale Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.1166992188,
      "lon": 147.0749969482,
      "tz": "Australia\/Melbourne"
  },
  "YSLV": {
      "icao": "YSLV",
      "iata": "",
      "name": "Sylvania Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.5583000183,
      "lon": 120.0469970703,
      "tz": "Australia\/Perth"
  },
  "YSMI": {
      "icao": "YSMI",
      "iata": "SIO",
      "name": "Smithton Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 31,
      "lat": -40.8349990845,
      "lon": 145.0839996338,
      "tz": "Australia\/Hobart"
  },
  "YSMP": {
      "icao": "YSMP",
      "iata": "SHU",
      "name": "Smith Point Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -11.1499996185,
      "lon": 132.149993896,
      "tz": "Australia\/Darwin"
  },
  "YSMR": {
      "icao": "YSMR",
      "iata": "STH",
      "name": "Strathmore Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.8500003815,
      "lon": 142.5670013428,
      "tz": "Australia\/Brisbane"
  },
  "YSMY": {
      "icao": "YSMY",
      "iata": "",
      "name": "Strathmay Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.8832998276,
      "lon": 142.8020019531,
      "tz": "Australia\/Brisbane"
  },
  "YSNB": {
      "icao": "YSNB",
      "iata": "SNB",
      "name": "Snake Bay Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 173,
      "lat": -11.4228000641,
      "lon": 130.654006958,
      "tz": "Australia\/Darwin"
  },
  "YSNF": {
      "icao": "YSNF",
      "iata": "NLK",
      "name": "Norfolk Island International Airport",
      "city": "Burnt Pine",
      "state": "",
      "country": "NF",
      "elevation": 371,
      "lat": -29.0415992737,
      "lon": 167.9389953613,
      "tz": "Pacific\/Norfolk"
  },
  "YSNT": {
      "icao": "YSNT",
      "iata": "",
      "name": "Snowtown Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.7999992371,
      "lon": 138.3670043945,
      "tz": "Australia\/Adelaide"
  },
  "YSNW": {
      "icao": "YSNW",
      "iata": "NOA",
      "name": "Nowra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 400,
      "lat": -34.9488983154,
      "lon": 150.5370025635,
      "tz": "Australia\/Sydney"
  },
  "YSOL": {
      "icao": "YSOL",
      "iata": "SLJ",
      "name": "Solomon Airport",
      "city": "Karijini National Park",
      "state": "Westrn-Australia",
      "country": "AU",
      "elevation": 2021,
      "lat": -22.255278,
      "lon": 117.761944,
      "tz": "Australia\/Perth"
  },
  "YSOW": {
      "icao": "YSOW",
      "iata": "",
      "name": "Southwell Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.516699791,
      "lon": 142.0330047607,
      "tz": "Australia\/Brisbane"
  },
  "YSPE": {
      "icao": "YSPE",
      "iata": "SNH",
      "name": "Stanthorpe Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 2938,
      "lat": -28.620300293,
      "lon": 151.9909973145,
      "tz": "Australia\/Brisbane"
  },
  "YSPF": {
      "icao": "YSPF",
      "iata": "",
      "name": "Springfield Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.2999992371,
      "lon": 144.8829956055,
      "tz": "Australia\/Brisbane"
  },
  "YSPI": {
      "icao": "YSPI",
      "iata": "",
      "name": "Springsure Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.1282997131,
      "lon": 148.0749969482,
      "tz": "Australia\/Brisbane"
  },
  "YSPK": {
      "icao": "YSPK",
      "iata": "SCG",
      "name": "Spring Creek Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.6333007813,
      "lon": 144.5670013428,
      "tz": "Australia\/Brisbane"
  },
  "YSPT": {
      "icao": "YSPT",
      "iata": "SHQ",
      "name": "Southport Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 2,
      "lat": -27.9150009155,
      "lon": 153.3730010986,
      "tz": "Australia\/Brisbane"
  },
  "YSPV": {
      "icao": "YSPV",
      "iata": "KSV",
      "name": "Springvale Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.5499992371,
      "lon": 140.6999969482,
      "tz": "Australia\/Brisbane"
  },
  "YSRD": {
      "icao": "YSRD",
      "iata": "",
      "name": "Sunrise Dam Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.1016998291,
      "lon": 122.4499969482,
      "tz": "Australia\/Perth"
  },
  "YSRI": {
      "icao": "YSRI",
      "iata": "XRH",
      "name": "RAAF Base Richmond",
      "city": "Richmond",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 67,
      "lat": -33.6006011963,
      "lon": 150.7810058594,
      "tz": "Australia\/Sydney"
  },
  "YSRN": {
      "icao": "YSRN",
      "iata": "SRN",
      "name": "Strahan Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 20,
      "lat": -42.1549987793,
      "lon": 145.2920074463,
      "tz": "Australia\/Hobart"
  },
  "YSRT": {
      "icao": "YSRT",
      "iata": "",
      "name": "Surat Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.1499996185,
      "lon": 149.0829925537,
      "tz": "Australia\/Brisbane"
  },
  "YSSY": {
      "icao": "YSSY",
      "iata": "SYD",
      "name": "Sydney Kingsford Smith International Airport",
      "city": "Sydney",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 21,
      "lat": -33.9460983276,
      "lon": 151.1770019531,
      "tz": "Australia\/Sydney"
  },
  "YSTA": {
      "icao": "YSTA",
      "iata": "",
      "name": "Saint Arnaud Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 639,
      "lat": -36.6366996765,
      "lon": 143.1860046387,
      "tz": "Australia\/Melbourne"
  },
  "YSTB": {
      "icao": "YSTB",
      "iata": "",
      "name": "Strathburn Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.4816999435,
      "lon": 142.8170013428,
      "tz": "Australia\/Brisbane"
  },
  "YSTC": {
      "icao": "YSTC",
      "iata": "",
      "name": "Stuart Creek Airport",
      "city": "Stuart Creek",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 122,
      "lat": -29.7166996002,
      "lon": 137.06300354,
      "tz": "Australia\/Adelaide"
  },
  "YSTH": {
      "icao": "YSTH",
      "iata": "HLS",
      "name": "St Helens Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 48,
      "lat": -41.3367004395,
      "lon": 148.2819976807,
      "tz": "Australia\/Hobart"
  },
  "YSTO": {
      "icao": "YSTO",
      "iata": "",
      "name": "Stonehenge Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 166,
      "lat": -24.3582992554,
      "lon": 143.3000030518,
      "tz": "Australia\/Brisbane"
  },
  "YSTR": {
      "icao": "YSTR",
      "iata": "",
      "name": "Strathearn Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.7667007446,
      "lon": 140.3329925537,
      "tz": "Australia\/Adelaide"
  },
  "YSTT": {
      "icao": "YSTT",
      "iata": "",
      "name": "Santa Teresa Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.1182994843,
      "lon": 134.3899993896,
      "tz": "Australia\/Darwin"
  },
  "YSTW": {
      "icao": "YSTW",
      "iata": "TMW",
      "name": "Tamworth Airport",
      "city": "Tamworth",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1334,
      "lat": -31.0839004517,
      "lon": 150.847000122,
      "tz": "Australia\/Sydney"
  },
  "YSUJ": {
      "icao": "YSUJ",
      "iata": "",
      "name": "Supplejack Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -19.2667007446,
      "lon": 129.9499969482,
      "tz": "Australia\/Darwin"
  },
  "YSVH": {
      "icao": "YSVH",
      "iata": "",
      "name": "Silver Hills Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.6166992188,
      "lon": 143.0829925537,
      "tz": "Australia\/Brisbane"
  },
  "YSVN": {
      "icao": "YSVN",
      "iata": "",
      "name": "Strathaven Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.8999996185,
      "lon": 142.9669952393,
      "tz": "Australia\/Brisbane"
  },
  "YSWA": {
      "icao": "YSWA",
      "iata": "",
      "name": "Swansea Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.1016998291,
      "lon": 148.0679931641,
      "tz": "Australia\/Hobart"
  },
  "YSWE": {
      "icao": "YSWE",
      "iata": "",
      "name": "Sweers Island Resort Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.1217002869,
      "lon": 139.5980072021,
      "tz": "Australia\/Brisbane"
  },
  "YSWG": {
      "icao": "YSWG",
      "iata": "WGA",
      "name": "Wagga Wagga City Airport",
      "city": "Wagga Wagga",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 724,
      "lat": -35.1652984619,
      "lon": 147.466003418,
      "tz": "Australia\/Sydney"
  },
  "YSWH": {
      "icao": "YSWH",
      "iata": "SWH",
      "name": "Swan Hill Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 234,
      "lat": -35.3758010864,
      "lon": 143.5330047607,
      "tz": "Australia\/Melbourne"
  },
  "YSWL": {
      "icao": "YSWL",
      "iata": "SWC",
      "name": "Stawell Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 807,
      "lat": -37.0717010498,
      "lon": 142.7409973145,
      "tz": "Australia\/Melbourne"
  },
  "YSYN": {
      "icao": "YSYN",
      "iata": "",
      "name": "Strathalbyn Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35.3116989136,
      "lon": 138.9900054932,
      "tz": "Australia\/Adelaide"
  },
  "YTAA": {
      "icao": "YTAA",
      "iata": "XTR",
      "name": "Tara Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 360,
      "lat": -27.1567001343,
      "lon": 150.4770050049,
      "tz": "Australia\/Brisbane"
  },
  "YTAB": {
      "icao": "YTAB",
      "iata": "TBL",
      "name": "Tableland Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -17.2833003998,
      "lon": 126.900001526,
      "tz": "Australia\/Perth"
  },
  "YTAD": {
      "icao": "YTAD",
      "iata": "",
      "name": "Tandou Lake Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.6333007813,
      "lon": 142.0829925537,
      "tz": "Australia\/Sydney"
  },
  "YTAM": {
      "icao": "YTAM",
      "iata": "XTO",
      "name": "Taroom Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 240,
      "lat": -25.801700592,
      "lon": 149.8999938965,
      "tz": "Australia\/Brisbane"
  },
  "YTAN": {
      "icao": "YTAN",
      "iata": "",
      "name": "Tanami Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.5736541748,
      "lon": 129.7401275635,
      "tz": "Australia\/Darwin"
  },
  "YTAR": {
      "icao": "YTAR",
      "iata": "TAQ",
      "name": "Tarcoola Airport",
      "city": "Tarcoola",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.7033004761,
      "lon": 134.583999634,
      "tz": "Australia\/Adelaide"
  },
  "YTBB": {
      "icao": "YTBB",
      "iata": "",
      "name": "Tumby Bay Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 11,
      "lat": -34.3616981506,
      "lon": 136.0950012207,
      "tz": "Australia\/Adelaide"
  },
  "YTBF": {
      "icao": "YTBF",
      "iata": "",
      "name": "Pattaya Airpark",
      "city": "",
      "state": "Chon-Buri",
      "country": "TH",
      "elevation": 100,
      "lat": 12.8325,
      "lon": 100.949444444,
      "tz": "Asia\/Bangkok"
  },
  "YTBG": {
      "icao": "YTBG",
      "iata": "",
      "name": "Talbingo Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.5833015442,
      "lon": 148.2830047607,
      "tz": "Australia\/Sydney"
  },
  "YTBR": {
      "icao": "YTBR",
      "iata": "TBK",
      "name": "Timber Creek Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 16,
      "lat": -15.6199998856,
      "lon": 130.4450073242,
      "tz": "Australia\/Darwin"
  },
  "YTCF": {
      "icao": "YTCF",
      "iata": "",
      "name": "Tracies Field",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.3166999817,
      "lon": 122.0670013428,
      "tz": "Australia\/Perth"
  },
  "YTCK": {
      "icao": "YTCK",
      "iata": "",
      "name": "Torrens Creek Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.7833003998,
      "lon": 145,
      "tz": "Australia\/Brisbane"
  },
  "YTCY": {
      "icao": "YTCY",
      "iata": "",
      "name": "Tarcoonyinna Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.75,
      "lon": 133.3500061035,
      "tz": "Australia\/Adelaide"
  },
  "YTDL": {
      "icao": "YTDL",
      "iata": "",
      "name": "Tidal River Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -39.034198761,
      "lon": 146.3269958496,
      "tz": "Australia\/Melbourne"
  },
  "YTDM": {
      "icao": "YTDM",
      "iata": "",
      "name": "Todmorden Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.1242008209,
      "lon": 134.7700042725,
      "tz": "Australia\/Adelaide"
  },
  "YTDN": {
      "icao": "YTDN",
      "iata": "",
      "name": "Tooradin Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 3,
      "lat": -38.2167015076,
      "lon": 145.4270019531,
      "tz": "Australia\/Melbourne"
  },
  "YTDR": {
      "icao": "YTDR",
      "iata": "TDR",
      "name": "Theodore Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 171,
      "lat": -24.9932994843,
      "lon": 150.0930023193,
      "tz": "Australia\/Brisbane"
  },
  "YTEC": {
      "icao": "YTEC",
      "iata": "",
      "name": "Tenneco Station Three Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.3367004395,
      "lon": 139.6649932861,
      "tz": "Australia\/Adelaide"
  },
  "YTEE": {
      "icao": "YTEE",
      "iata": "TQP",
      "name": "Trepell Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 891,
      "lat": -21.8349990845,
      "lon": 140.8880004883,
      "tz": "Australia\/Brisbane"
  },
  "YTEF": {
      "icao": "YTEF",
      "iata": "TEF",
      "name": "Telfer Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 970,
      "lat": -21.7150001526,
      "lon": 122.2289962769,
      "tz": "Australia\/Perth"
  },
  "YTEM": {
      "icao": "YTEM",
      "iata": "TEM",
      "name": "Temora Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 921,
      "lat": -34.4213981628,
      "lon": 147.5119934082,
      "tz": "Australia\/Sydney"
  },
  "YTEN": {
      "icao": "YTEN",
      "iata": "",
      "name": "Tenneco Station Five Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.9750003815,
      "lon": 139.2070007324,
      "tz": "Australia\/Adelaide"
  },
  "YTFA": {
      "icao": "YTFA",
      "iata": "",
      "name": "Truro Flat Airpark",
      "city": "Truro Flat",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.3983001709,
      "lon": 139.3849945068,
      "tz": "Australia\/Adelaide"
  },
  "YTFD": {
      "icao": "YTFD",
      "iata": "",
      "name": "Tenterfield Airport",
      "city": "Tenterfield",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -28.9915500425,
      "lon": 151.930103302,
      "tz": "Australia\/Sydney"
  },
  "YTGA": {
      "icao": "YTGA",
      "iata": "TAN",
      "name": "Tangalooma Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.1299991608,
      "lon": 153.363006592,
      "tz": "Australia\/Brisbane"
  },
  "YTGG": {
      "icao": "YTGG",
      "iata": "",
      "name": "Taggerty Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.3504981995,
      "lon": 145.6999969482,
      "tz": "Australia\/Melbourne"
  },
  "YTGI": {
      "icao": "YTGI",
      "iata": "",
      "name": "Trangie Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.9666996002,
      "lon": 148,
      "tz": "Australia\/Sydney"
  },
  "YTGM": {
      "icao": "YTGM",
      "iata": "XTG",
      "name": "Thargomindah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 433,
      "lat": -27.9864006042,
      "lon": 143.8110046387,
      "tz": "Australia\/Brisbane"
  },
  "YTGT": {
      "icao": "YTGT",
      "iata": "GTS",
      "name": "The Granites Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.5482997894,
      "lon": 130.3470001221,
      "tz": "Australia\/Darwin"
  },
  "YTGV": {
      "icao": "YTGV",
      "iata": "",
      "name": "The Grove Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.686000824,
      "lon": 148.1040039063,
      "tz": "Australia\/Sydney"
  },
  "YTHD": {
      "icao": "YTHD",
      "iata": "TDN",
      "name": "Theda Station Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -14.7881002426,
      "lon": 126.4960021973,
      "tz": "Australia\/Perth"
  },
  "YTHI": {
      "icao": "YTHI",
      "iata": "",
      "name": "Thistle Island Airport",
      "city": "Thistle Island",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 120,
      "lat": -35.0247001648,
      "lon": 136.1799926758,
      "tz": "Australia\/Adelaide"
  },
  "YTHR": {
      "icao": "YTHR",
      "iata": "",
      "name": "Three Rivers Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1333007813,
      "lon": 119.1169967651,
      "tz": "Australia\/Perth"
  },
  "YTHS": {
      "icao": "YTHS",
      "iata": "",
      "name": "Three Springs Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 900,
      "lat": -29.5279109299,
      "lon": 115.858039856,
      "tz": "Australia\/Perth"
  },
  "YTHV": {
      "icao": "YTHV",
      "iata": "",
      "name": "Thevenard Island Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.4666996002,
      "lon": 115,
      "tz": "Australia\/Perth"
  },
  "YTHY": {
      "icao": "YTHY",
      "iata": "TYG",
      "name": "Thylungra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 165,
      "lat": -26.0832996368,
      "lon": 143.4669952393,
      "tz": "Australia\/Brisbane"
  },
  "YTIB": {
      "icao": "YTIB",
      "iata": "TYB",
      "name": "Tibooburra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 584,
      "lat": -29.4510993958,
      "lon": 142.0579986572,
      "tz": "Australia\/Sydney"
  },
  "YTII": {
      "icao": "YTII",
      "iata": "",
      "name": "Trinidad Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.6000003815,
      "lon": 143.9170074463,
      "tz": "Australia\/Brisbane"
  },
  "YTIN": {
      "icao": "YTIN",
      "iata": "",
      "name": "Tintinara Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35.8666992188,
      "lon": 140.0670013428,
      "tz": "Australia\/Adelaide"
  },
  "YTIT": {
      "icao": "YTIT",
      "iata": "",
      "name": "Ti Tree Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.1317005157,
      "lon": 133.4199981689,
      "tz": "Australia\/Darwin"
  },
  "YTJI": {
      "icao": "YTJI",
      "iata": "",
      "name": "Tjirrkarli Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.0349998474,
      "lon": 125.4720001221,
      "tz": "Australia\/Perth"
  },
  "YTJU": {
      "icao": "YTJU",
      "iata": "",
      "name": "Tjuntjuntjarra Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.3582992554,
      "lon": 127.1279983521,
      "tz": "Australia\/Perth"
  },
  "YTKS": {
      "icao": "YTKS",
      "iata": "",
      "name": "Toorak Research Station Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 128,
      "lat": -21.0417003632,
      "lon": 141.7870025635,
      "tz": "Australia\/Brisbane"
  },
  "YTKY": {
      "icao": "YTKY",
      "iata": "TKY",
      "name": "Turkey Creek Airport",
      "city": "Turkey Creek",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -17.0408000946,
      "lon": 128.205993652,
      "tz": "Australia\/Perth"
  },
  "YTLG": {
      "icao": "YTLG",
      "iata": "",
      "name": "Taralga Grathawa Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3031,
      "lat": -34.2891998291,
      "lon": 149.7819976807,
      "tz": "Australia\/Sydney"
  },
  "YTLL": {
      "icao": "YTLL",
      "iata": "",
      "name": "Tullamore Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.6166992188,
      "lon": 147.6000061035,
      "tz": "Australia\/Sydney"
  },
  "YTLP": {
      "icao": "YTLP",
      "iata": "",
      "name": "Tilpa Airport",
      "city": "Tilpa",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.9333000183,
      "lon": 144.4170074463,
      "tz": "Australia\/Sydney"
  },
  "YTLT": {
      "icao": "YTLT",
      "iata": "",
      "name": "Tarlton Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.6333007813,
      "lon": 136.8000030518,
      "tz": "Australia\/Darwin"
  },
  "YTMB": {
      "icao": "YTMB",
      "iata": "",
      "name": "Tambo Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 457,
      "lat": -24.8500003815,
      "lon": 146.2830047607,
      "tz": "Australia\/Brisbane"
  },
  "YTML": {
      "icao": "YTML",
      "iata": "",
      "name": "Tamala Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.6667003632,
      "lon": 113.733001709,
      "tz": "Australia\/Perth"
  },
  "YTMN": {
      "icao": "YTMN",
      "iata": "",
      "name": "Tanami Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 427,
      "lat": -19.9167003632,
      "lon": 129.7250061035,
      "tz": "Australia\/Darwin"
  },
  "YTMO": {
      "icao": "YTMO",
      "iata": "PHQ",
      "name": "The Monument Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 949,
      "lat": -21.8111000061,
      "lon": 139.9239959717,
      "tz": "Australia\/Brisbane"
  },
  "YTMP": {
      "icao": "YTMP",
      "iata": "TPR",
      "name": "Tom Price Airport",
      "city": "Tom Price",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 2300,
      "lat": -22.7460002899,
      "lon": 117.869003296,
      "tz": "Australia\/Perth"
  },
  "YTMS": {
      "icao": "YTMS",
      "iata": "",
      "name": "Tambar Springs Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.3150005341,
      "lon": 149.8470001221,
      "tz": "Australia\/Sydney"
  },
  "YTMU": {
      "icao": "YTMU",
      "iata": "TUM",
      "name": "Tumut Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 878,
      "lat": -35.2627983093,
      "lon": 148.2409973145,
      "tz": "Australia\/Sydney"
  },
  "YTMY": {
      "icao": "YTMY",
      "iata": "TYP",
      "name": "Tobermorey Airport",
      "city": "Tobermorey",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.2558002472,
      "lon": 137.9530029297,
      "tz": "Australia\/Darwin"
  },
  "YTNB": {
      "icao": "YTNB",
      "iata": "",
      "name": "Tanbar Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 344,
      "lat": -25.8477993011,
      "lon": 141.9279937744,
      "tz": "Australia\/Brisbane"
  },
  "YTNC": {
      "icao": "YTNC",
      "iata": "",
      "name": "Tuncurry Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.1500015259,
      "lon": 152.483001709,
      "tz": "Australia\/Sydney"
  },
  "YTNE": {
      "icao": "YTNE",
      "iata": "",
      "name": "Tenneco Station One Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.7332992554,
      "lon": 140.0500030518,
      "tz": "Australia\/Adelaide"
  },
  "YTNG": {
      "icao": "YTNG",
      "iata": "THG",
      "name": "Thangool Airport",
      "city": "Biloela",
      "state": "Queensland",
      "country": "AU",
      "elevation": 644,
      "lat": -24.4939002991,
      "lon": 150.5760040283,
      "tz": "Australia\/Brisbane"
  },
  "YTNK": {
      "icao": "YTNK",
      "iata": "TCA",
      "name": "Tennant Creek Airport",
      "city": "Tennant Creek",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 1236,
      "lat": -19.6343994141,
      "lon": 134.1829986572,
      "tz": "Australia\/Darwin"
  },
  "YTNN": {
      "icao": "YTNN",
      "iata": "",
      "name": "Tenneco Station Four Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.1833000183,
      "lon": 139.3829956055,
      "tz": "Australia\/Adelaide"
  },
  "YTNR": {
      "icao": "YTNR",
      "iata": "",
      "name": "Tanumbirini Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -16.4500007629,
      "lon": 134.6499938965,
      "tz": "Australia\/Darwin"
  },
  "YTNS": {
      "icao": "YTNS",
      "iata": "",
      "name": "Toliness Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.3214511871,
      "lon": 146.0688171387,
      "tz": "Australia\/Brisbane"
  },
  "YTOA": {
      "icao": "YTOA",
      "iata": "",
      "name": "Toolachie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.4083003998,
      "lon": 140.8099975586,
      "tz": "Australia\/Adelaide"
  },
  "YTOB": {
      "icao": "YTOB",
      "iata": "",
      "name": "Toomba Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.9333000183,
      "lon": 145.5829925537,
      "tz": "Australia\/Brisbane"
  },
  "YTOC": {
      "icao": "YTOC",
      "iata": "TCW",
      "name": "Tocumwal Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 372,
      "lat": -35.8116989136,
      "lon": 145.608001709,
      "tz": "Australia\/Sydney"
  },
  "YTOG": {
      "icao": "YTOG",
      "iata": "",
      "name": "Togo Station Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.0821990967,
      "lon": 149.5319976807,
      "tz": "Australia\/Sydney"
  },
  "YTOK": {
      "icao": "YTOK",
      "iata": "",
      "name": "Torres Park Homestead Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.1166992188,
      "lon": 147.1670074463,
      "tz": "Australia\/Brisbane"
  },
  "YTOS": {
      "icao": "YTOS",
      "iata": "",
      "name": "Tenneco Station Two Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.5499992371,
      "lon": 139.9499969482,
      "tz": "Australia\/Adelaide"
  },
  "YTOT": {
      "icao": "YTOT",
      "iata": "",
      "name": "Tottenham Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 238,
      "lat": -32.248298645,
      "lon": 147.3679962158,
      "tz": "Australia\/Sydney"
  },
  "YTPE": {
      "icao": "YTPE",
      "iata": "",
      "name": "Tempe Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 1696,
      "lat": -24.3824996948,
      "lon": 132.4230041504,
      "tz": "Australia\/Darwin"
  },
  "YTQY": {
      "icao": "YTQY",
      "iata": "",
      "name": "Torquay Airport",
      "city": "Torquay",
      "state": "Victoria",
      "country": "AU",
      "elevation": 9,
      "lat": -38.2999992371,
      "lon": 144.3650054932,
      "tz": "Australia\/Melbourne"
  },
  "YTRB": {
      "icao": "YTRB",
      "iata": "",
      "name": "Torrumbarry Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.0666999817,
      "lon": 144.5330047607,
      "tz": "Australia\/Melbourne"
  },
  "YTRC": {
      "icao": "YTRC",
      "iata": "",
      "name": "Tarcombe Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.0832996368,
      "lon": 143.3999938965,
      "tz": "Australia\/Brisbane"
  },
  "YTRE": {
      "icao": "YTRE",
      "iata": "TRO",
      "name": "Taree Airport",
      "city": "Taree",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 38,
      "lat": -31.8885993958,
      "lon": 152.514007568,
      "tz": "Australia\/Sydney"
  },
  "YTRK": {
      "icao": "YTRK",
      "iata": "",
      "name": "Turee Creek Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.6166992188,
      "lon": 118.6169967651,
      "tz": "Australia\/Perth"
  },
  "YTST": {
      "icao": "YTST",
      "iata": "TTX",
      "name": "Truscott Mungalalu Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 181,
      "lat": -14.0896997452,
      "lon": 126.3809967041,
      "tz": "Australia\/Perth"
  },
  "YTTE": {
      "icao": "YTTE",
      "iata": "",
      "name": "Ti Tree Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -13.9849996567,
      "lon": 141.6569976807,
      "tz": "Australia\/Brisbane"
  },
  "YTTI": {
      "icao": "YTTI",
      "iata": "",
      "name": "Troughton Is Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 8,
      "lat": -13.7517004013,
      "lon": 126.1480026245,
      "tz": "Australia\/Perth"
  },
  "YTUA": {
      "icao": "YTUA",
      "iata": "",
      "name": "Triabunna Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.5136985779,
      "lon": 147.8979949951,
      "tz": "Australia\/Hobart"
  },
  "YTUC": {
      "icao": "YTUC",
      "iata": "",
      "name": "Tuckabiana Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.4750003815,
      "lon": 118.125,
      "tz": "Australia\/Perth"
  },
  "YTUG": {
      "icao": "YTUG",
      "iata": "",
      "name": "Trugananni Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -24.4666996002,
      "lon": 149.266998291,
      "tz": "Australia\/Brisbane"
  },
  "YTUN": {
      "icao": "YTUN",
      "iata": "",
      "name": "Tunbridge Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -42.0999984741,
      "lon": 147.3670043945,
      "tz": "Australia\/Hobart"
  },
  "YTUY": {
      "icao": "YTUY",
      "iata": "",
      "name": "Tully Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.9333000183,
      "lon": 145.9170074463,
      "tz": "Australia\/Brisbane"
  },
  "YTVA": {
      "icao": "YTVA",
      "iata": "",
      "name": "Talavera Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.2166996002,
      "lon": 143.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YTWB": {
      "icao": "YTWB",
      "iata": "TWB",
      "name": "Toowoomba Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 2086,
      "lat": -27.5428009033,
      "lon": 151.9160003662,
      "tz": "Australia\/Brisbane"
  },
  "YTWE": {
      "icao": "YTWE",
      "iata": "",
      "name": "Trelawney Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.8500003815,
      "lon": 146.7169952393,
      "tz": "Australia\/Brisbane"
  },
  "YTWN": {
      "icao": "YTWN",
      "iata": "",
      "name": "Tooraweenah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 421,
      "lat": -31.4416999817,
      "lon": 148.8999938965,
      "tz": "Australia\/Sydney"
  },
  "YTYA": {
      "icao": "YTYA",
      "iata": "",
      "name": "Tyabb Airport",
      "city": "Melbourne",
      "state": "Victoria",
      "country": "AU",
      "elevation": 88,
      "lat": -38.2667007446,
      "lon": 145.175003052,
      "tz": "Australia\/Melbourne"
  },
  "YTYH": {
      "icao": "YTYH",
      "iata": "",
      "name": "Tyagarah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -28.5946960394,
      "lon": 153.551101685,
      "tz": "Australia\/Sydney"
  },
  "YTYN": {
      "icao": "YTYN",
      "iata": "",
      "name": "Tieyon Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.2166996002,
      "lon": 133.9170074463,
      "tz": "Australia\/Adelaide"
  },
  "YUCH": {
      "icao": "YUCH",
      "iata": "",
      "name": "Ucharonidge Airport",
      "city": "Ucharonidge Station",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.6724014282,
      "lon": 134.2409057617,
      "tz": "Australia\/Darwin"
  },
  "YUDA": {
      "icao": "YUDA",
      "iata": "UDA",
      "name": "Undara Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.2000007629,
      "lon": 144.6000061035,
      "tz": "Australia\/Brisbane"
  },
  "YUDG": {
      "icao": "YUDG",
      "iata": "",
      "name": "Urandangi Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.5900001526,
      "lon": 138.358001709,
      "tz": "Australia\/Brisbane"
  },
  "YUDL": {
      "icao": "YUDL",
      "iata": "",
      "name": "Undilla Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.625,
      "lon": 138.6329956055,
      "tz": "Australia\/Brisbane"
  },
  "YUMB": {
      "icao": "YUMB",
      "iata": "",
      "name": "Umbeara Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -25.7467002869,
      "lon": 133.7019958496,
      "tz": "Australia\/Darwin"
  },
  "YUMU": {
      "icao": "YUMU",
      "iata": "",
      "name": "Umuwa Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.486700058,
      "lon": 132.0399932861,
      "tz": "Australia\/Adelaide"
  },
  "YUNY": {
      "icao": "YUNY",
      "iata": "CZY",
      "name": "Cluny Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 90,
      "lat": -24.5167007446,
      "lon": 139.6170043945,
      "tz": "Australia\/Brisbane"
  },
  "YUPG": {
      "icao": "YUPG",
      "iata": "",
      "name": "Urapunga Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -14.7166996002,
      "lon": 134.5670013428,
      "tz": "Australia\/Darwin"
  },
  "YUPH": {
      "icao": "YUPH",
      "iata": "",
      "name": "Upper Horton Wyl Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1253,
      "lat": -30.1047000885,
      "lon": 150.404006958,
      "tz": "Australia\/Sydney"
  },
  "YUSL": {
      "icao": "YUSL",
      "iata": "USL",
      "name": "Useless Loop Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.1667003632,
      "lon": 113.4000015259,
      "tz": "Australia\/Perth"
  },
  "YUTP": {
      "icao": "YUTP",
      "iata": "",
      "name": "Utopia Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.0550003052,
      "lon": 134.7899932861,
      "tz": "Australia\/Darwin"
  },
  "YUTS": {
      "icao": "YUTS",
      "iata": "",
      "name": "Utopia Station Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.2332992554,
      "lon": 134.5800018311,
      "tz": "Australia\/Darwin"
  },
  "YVAF": {
      "icao": "YVAF",
      "iata": "",
      "name": "Valley Field Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.8100013733,
      "lon": 147.2920074463,
      "tz": "Australia\/Hobart"
  },
  "YVIV": {
      "icao": "YVIV",
      "iata": "",
      "name": "Mount Vivian Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.5832996368,
      "lon": 135.7169952393,
      "tz": "Australia\/Adelaide"
  },
  "YVLG": {
      "icao": "YVLG",
      "iata": "",
      "name": "Valley of Lagoons Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.6667003632,
      "lon": 145.1000061035,
      "tz": "Australia\/Brisbane"
  },
  "YVNS": {
      "icao": "YVNS",
      "iata": "",
      "name": "Vaughan Springs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -22.3332996368,
      "lon": 130.8670043945,
      "tz": "Australia\/Darwin"
  },
  "YVRD": {
      "icao": "YVRD",
      "iata": "VCD",
      "name": "Victoria River Downs Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 89,
      "lat": -16.4021244049,
      "lon": 131.0049743652,
      "tz": "Australia\/Darwin"
  },
  "YVRS": {
      "icao": "YVRS",
      "iata": "VNR",
      "name": "Vanrook Station Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 43,
      "lat": -16.963300705,
      "lon": 141.9499969482,
      "tz": "Australia\/Brisbane"
  },
  "YVSH": {
      "icao": "YVSH",
      "iata": "",
      "name": "Vashon Head Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -11.1499996185,
      "lon": 131.983001709,
      "tz": "Australia\/Darwin"
  },
  "YVVA": {
      "icao": "YVVA",
      "iata": "",
      "name": "Victoria Valley Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.5,
      "lon": 142.2700042725,
      "tz": "Australia\/Melbourne"
  },
  "YVVL": {
      "icao": "YVVL",
      "iata": "",
      "name": "Violet Vale Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -14.733300209,
      "lon": 143.5829925537,
      "tz": "Australia\/Brisbane"
  },
  "YWAB": {
      "icao": "YWAB",
      "iata": "",
      "name": "Waldburg Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -24.75,
      "lon": 117.3669967651,
      "tz": "Australia\/Perth"
  },
  "YWAC": {
      "icao": "YWAC",
      "iata": "",
      "name": "Wauchope Airport",
      "city": "Wauchope",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.6499996185,
      "lon": 134.2169952393,
      "tz": "Australia\/Darwin"
  },
  "YWAG": {
      "icao": "YWAG",
      "iata": "",
      "name": "Wanaaring Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.7166996002,
      "lon": 144.1670074463,
      "tz": "Australia\/Sydney"
  },
  "YWAL": {
      "icao": "YWAL",
      "iata": "WLA",
      "name": "Wallal Airport",
      "city": "Wallal",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -19.7735996246,
      "lon": 120.649002075,
      "tz": "Australia\/Perth"
  },
  "YWAT": {
      "icao": "YWAT",
      "iata": "",
      "name": "Wattle Hills Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -12.6332998276,
      "lon": 143.0500030518,
      "tz": "Australia\/Brisbane"
  },
  "YWAV": {
      "icao": "YWAV",
      "iata": "WAV",
      "name": "Wave Hill Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 201,
      "lat": -17.3932991028,
      "lon": 131.1179962158,
      "tz": "Australia\/Darwin"
  },
  "YWAX": {
      "icao": "YWAX",
      "iata": "",
      "name": "Wanarn Airport",
      "city": "Wanarn",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.2975521088,
      "lon": 127.5564193726,
      "tz": "Australia\/Perth"
  },
  "YWBH": {
      "icao": "YWBH",
      "iata": "",
      "name": "Wallabadah Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.5333003998,
      "lon": 150.8670043945,
      "tz": "Australia\/Sydney"
  },
  "YWBI": {
      "icao": "YWBI",
      "iata": "",
      "name": "Warrabri Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 383,
      "lat": -21,
      "lon": 134.3970031738,
      "tz": "Australia\/Darwin"
  },
  "YWBL": {
      "icao": "YWBL",
      "iata": "WMB",
      "name": "Warrnambool Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 242,
      "lat": -38.29529953,
      "lon": 142.4470062256,
      "tz": "Australia\/Melbourne"
  },
  "YWBN": {
      "icao": "YWBN",
      "iata": "",
      "name": "Wedderburn Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 259,
      "lat": -34.1866989136,
      "lon": 150.8049926758,
      "tz": "Australia\/Sydney"
  },
  "YWBR": {
      "icao": "YWBR",
      "iata": "",
      "name": "Warburton Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1500,
      "lat": -26.1282997131,
      "lon": 126.5830001831,
      "tz": "Australia\/Perth"
  },
  "YWBS": {
      "icao": "YWBS",
      "iata": "SYU",
      "name": "Warraber Island Airport",
      "city": "Sue Islet",
      "state": "Queensland",
      "country": "AU",
      "elevation": 3,
      "lat": -10.2082996368,
      "lon": 142.8249969482,
      "tz": "Australia\/Brisbane"
  },
  "YWCA": {
      "icao": "YWCA",
      "iata": "WIO",
      "name": "Wilcannia Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 250,
      "lat": -31.5263996124,
      "lon": 143.375,
      "tz": "Australia\/Sydney"
  },
  "YWCH": {
      "icao": "YWCH",
      "iata": "WLC",
      "name": "Walcha Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31,
      "lon": 151.5670013428,
      "tz": "Australia\/Sydney"
  },
  "YWCK": {
      "icao": "YWCK",
      "iata": "WAZ",
      "name": "Warwick Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 1500,
      "lat": -28.1494007111,
      "lon": 151.9429931641,
      "tz": "Australia\/Brisbane"
  },
  "YWCM": {
      "icao": "YWCM",
      "iata": "",
      "name": "Wilson's Camp Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -20.1014003754,
      "lon": 129.1260070801,
      "tz": "Australia\/Darwin"
  },
  "YWDA": {
      "icao": "YWDA",
      "iata": "WND",
      "name": "Windarra Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.4750003815,
      "lon": 122.241996765,
      "tz": "Australia\/Perth"
  },
  "YWDC": {
      "icao": "YWDC",
      "iata": "",
      "name": "Wodgina Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.1417007446,
      "lon": 118.6920013428,
      "tz": "Australia\/Perth"
  },
  "YWDH": {
      "icao": "YWDH",
      "iata": "WNR",
      "name": "Windorah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 452,
      "lat": -25.4130992889,
      "lon": 142.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YWDJ": {
      "icao": "YWDJ",
      "iata": "",
      "name": "Windjana Grove Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -17.421387587,
      "lon": 124.925107956,
      "tz": "Australia\/Perth"
  },
  "YWDL": {
      "icao": "YWDL",
      "iata": "WON",
      "name": "Wondoola Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 58,
      "lat": -18.5750007629,
      "lon": 140.891998291,
      "tz": "Australia\/Brisbane"
  },
  "YWDR": {
      "icao": "YWDR",
      "iata": "",
      "name": "Collector2 Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 2274,
      "lat": -34.9388999939,
      "lon": 149.4100036621,
      "tz": "Australia\/Sydney"
  },
  "YWDS": {
      "icao": "YWDS",
      "iata": "",
      "name": "Woodside Au Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 354,
      "lat": -35.1333007813,
      "lon": 145.6670074463,
      "tz": "Australia\/Sydney"
  },
  "YWDT": {
      "icao": "YWDT",
      "iata": "",
      "name": "Wyandotte Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.75,
      "lon": 144.8329925537,
      "tz": "Australia\/Brisbane"
  },
  "YWDV": {
      "icao": "YWDV",
      "iata": "",
      "name": "Wandovale Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -19.6667003632,
      "lon": 144.8999938965,
      "tz": "Australia\/Brisbane"
  },
  "YWEC": {
      "icao": "YWEC",
      "iata": "",
      "name": "Wellclose Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.8500003815,
      "lon": 145.1000061035,
      "tz": "Australia\/Brisbane"
  },
  "YWED": {
      "icao": "YWED",
      "iata": "",
      "name": "Wedderburn Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.4333000183,
      "lon": 143.483001709,
      "tz": "Australia\/Melbourne"
  },
  "YWEE": {
      "icao": "YWEE",
      "iata": "",
      "name": "Wooleen Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.0816993713,
      "lon": 116.1500015259,
      "tz": "Australia\/Perth"
  },
  "YWEL": {
      "icao": "YWEL",
      "iata": "",
      "name": "Wellington Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 426,
      "lat": -32.4667015076,
      "lon": 148.983001709,
      "tz": "Australia\/Sydney"
  },
  "YWEO": {
      "icao": "YWEO",
      "iata": "",
      "name": "Wertaloona Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.6499996185,
      "lon": 139.3500061035,
      "tz": "Australia\/Adelaide"
  },
  "YWER": {
      "icao": "YWER",
      "iata": "",
      "name": "Wernadinga Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -18.1333007813,
      "lon": 139.9669952393,
      "tz": "Australia\/Brisbane"
  },
  "YWEX": {
      "icao": "YWEX",
      "iata": "",
      "name": "Well 33 Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -22.3666992188,
      "lon": 124.766998291,
      "tz": "Australia\/Perth"
  },
  "YWFD": {
      "icao": "YWFD",
      "iata": "",
      "name": "Western Field Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -19.2833003998,
      "lon": 125.4169998169,
      "tz": "Australia\/Perth"
  },
  "YWGN": {
      "icao": "YWGN",
      "iata": "",
      "name": "Wagin Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -33.3333015442,
      "lon": 117.3669967651,
      "tz": "Australia\/Perth"
  },
  "YWGT": {
      "icao": "YWGT",
      "iata": "WGT",
      "name": "Wangaratta Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 504,
      "lat": -36.4157981873,
      "lon": 146.3070068359,
      "tz": "Australia\/Melbourne"
  },
  "YWGW": {
      "icao": "YWGW",
      "iata": "",
      "name": "Wongawol Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.1333007813,
      "lon": 121.9670028687,
      "tz": "Australia\/Perth"
  },
  "YWHA": {
      "icao": "YWHA",
      "iata": "WYA",
      "name": "Whyalla Airport",
      "city": "Whyalla",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 41,
      "lat": -33.0588989258,
      "lon": 137.5140075684,
      "tz": "Australia\/Adelaide"
  },
  "YWHC": {
      "icao": "YWHC",
      "iata": "",
      "name": "White Cliffs Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 162,
      "lat": -30.8533000946,
      "lon": 143.0720062256,
      "tz": "Australia\/Sydney"
  },
  "YWHI": {
      "icao": "YWHI",
      "iata": "",
      "name": "Whitsunday Island Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.2667007446,
      "lon": 148.766998291,
      "tz": "Australia\/Brisbane"
  },
  "YWHL": {
      "icao": "YWHL",
      "iata": "",
      "name": "Walhallow Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.7667007446,
      "lon": 135.6499938965,
      "tz": "Australia\/Darwin"
  },
  "YWIB": {
      "icao": "YWIB",
      "iata": "",
      "name": "Mount Willoughby Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9832992554,
      "lon": 134.1499938965,
      "tz": "Australia\/Adelaide"
  },
  "YWIE": {
      "icao": "YWIE",
      "iata": "",
      "name": "Wirralie Gold Mine Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.1166992188,
      "lon": 147.266998291,
      "tz": "Australia\/Brisbane"
  },
  "YWIL": {
      "icao": "YWIL",
      "iata": "",
      "name": "Wilandra Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.2833003998,
      "lon": 142.6670074463,
      "tz": "Australia\/Sydney"
  },
  "YWIO": {
      "icao": "YWIO",
      "iata": "",
      "name": "Wilton Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.2332992554,
      "lon": 150.6670074463,
      "tz": "Australia\/Sydney"
  },
  "YWIS": {
      "icao": "YWIS",
      "iata": "",
      "name": "Williamson Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 89,
      "lat": -22.4733009338,
      "lon": 150.1779937744,
      "tz": "Australia\/Brisbane"
  },
  "YWIT": {
      "icao": "YWIT",
      "iata": "WIT",
      "name": "Wittenoom Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 444,
      "lat": -22.2182998657,
      "lon": 118.3479995728,
      "tz": "Australia\/Perth"
  },
  "YWJS": {
      "icao": "YWJS",
      "iata": "",
      "name": "Wee Jasper Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -35.2616996765,
      "lon": 148.6670074463,
      "tz": "Australia\/Sydney"
  },
  "YWKB": {
      "icao": "YWKB",
      "iata": "WKB",
      "name": "Warracknabeal Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 397,
      "lat": -36.3210983276,
      "lon": 142.4190063477,
      "tz": "Australia\/Melbourne"
  },
  "YWKI": {
      "icao": "YWKI",
      "iata": "",
      "name": "Waikerie Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 138,
      "lat": -34.1838989258,
      "lon": 140.0310058594,
      "tz": "Australia\/Adelaide"
  },
  "YWKM": {
      "icao": "YWKM",
      "iata": "",
      "name": "Wyalkatchem Airport",
      "city": "Wyalkatchem",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.2026341022,
      "lon": 117.378873825,
      "tz": "Australia\/Perth"
  },
  "YWKS": {
      "icao": "YWKS",
      "iata": "",
      "name": "Wilkins Runway",
      "city": "",
      "state": "",
      "country": "AQ",
      "elevation": 2529,
      "lat": -66.690833,
      "lon": 111.523611,
      "tz": "Antarctica\/Casey"
  },
  "YWKW": {
      "icao": "YWKW",
      "iata": "",
      "name": "Warkworth Airport",
      "city": "Warkworth",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -32.5491283809,
      "lon": 151.024246216,
      "tz": "Australia\/Sydney"
  },
  "YWLA": {
      "icao": "YWLA",
      "iata": "",
      "name": "Willowra Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -21.2766990662,
      "lon": 132.6230010986,
      "tz": "Australia\/Darwin"
  },
  "YWLB": {
      "icao": "YWLB",
      "iata": "",
      "name": "Welbourn Hill Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.3581008911,
      "lon": 134.0930023193,
      "tz": "Australia\/Adelaide"
  },
  "YWLG": {
      "icao": "YWLG",
      "iata": "WGE",
      "name": "Walgett Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 439,
      "lat": -30.0328006744,
      "lon": 148.1260070801,
      "tz": "Australia\/Sydney"
  },
  "YWLH": {
      "icao": "YWLH",
      "iata": "",
      "name": "Wallara Ranch Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -24.6499996185,
      "lon": 132.3170013428,
      "tz": "Australia\/Darwin"
  },
  "YWLM": {
      "icao": "YWLM",
      "iata": "NTL",
      "name": "Newcastle Airport",
      "city": "Williamtown",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 31,
      "lat": -32.7949981689,
      "lon": 151.8339996338,
      "tz": "Australia\/Sydney"
  },
  "YWLN": {
      "icao": "YWLN",
      "iata": "",
      "name": "Wooltana Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.4216995239,
      "lon": 139.4360046387,
      "tz": "Australia\/Adelaide"
  },
  "YWLP": {
      "icao": "YWLP",
      "iata": "",
      "name": "Wilsons Promontory Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -39.1310997009,
      "lon": 146.4250030518,
      "tz": "Australia\/Melbourne"
  },
  "YWLU": {
      "icao": "YWLU",
      "iata": "WUN",
      "name": "Wiluna Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 1649,
      "lat": -26.6291999817,
      "lon": 120.2210006714,
      "tz": "Australia\/Perth"
  },
  "YWLX": {
      "icao": "YWLX",
      "iata": "",
      "name": "Wallacia Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -33.8666992188,
      "lon": 150.6499938965,
      "tz": "Australia\/Sydney"
  },
  "YWMA": {
      "icao": "YWMA",
      "iata": "",
      "name": "Wonnaminta Stat Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 610,
      "lat": -30.6511001587,
      "lon": 142.3419952393,
      "tz": "Australia\/Sydney"
  },
  "YWMC": {
      "icao": "YWMC",
      "iata": "",
      "name": "William Creek Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 91,
      "lat": -28.9067001343,
      "lon": 136.3419952393,
      "tz": "Australia\/Adelaide"
  },
  "YWMG": {
      "icao": "YWMG",
      "iata": "",
      "name": "Weilmoringle Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.2332992554,
      "lon": 146.9329986572,
      "tz": "Australia\/Sydney"
  },
  "YWML": {
      "icao": "YWML",
      "iata": "",
      "name": "Westmoreland Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -17.3332996368,
      "lon": 138.233001709,
      "tz": "Australia\/Brisbane"
  },
  "YWMM": {
      "icao": "YWMM",
      "iata": "",
      "name": "Wollomombi Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.5333003998,
      "lon": 152.0829925537,
      "tz": "Australia\/Sydney"
  },
  "YWMP": {
      "icao": "YWMP",
      "iata": "WPK",
      "name": "Wrotham Park Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 152,
      "lat": -16.6583003998,
      "lon": 144.0019989014,
      "tz": "Australia\/Brisbane"
  },
  "YWMY": {
      "icao": "YWMY",
      "iata": "",
      "name": "Williambury Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.8666992188,
      "lon": 115.1500015259,
      "tz": "Australia\/Perth"
  },
  "YWNA": {
      "icao": "YWNA",
      "iata": "",
      "name": "Wilgena Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.7667007446,
      "lon": 134.7299957275,
      "tz": "Australia\/Adelaide"
  },
  "YWND": {
      "icao": "YWND",
      "iata": "WDI",
      "name": "Wondai Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 320,
      "lat": -26.2833003998,
      "lon": 151.858001709,
      "tz": "Australia\/Brisbane"
  },
  "YWNI": {
      "icao": "YWNI",
      "iata": "",
      "name": "Wathanin Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -13.6999998093,
      "lon": 141.5500030518,
      "tz": "Australia\/Brisbane"
  },
  "YWNL": {
      "icao": "YWNL",
      "iata": "",
      "name": "Wingellina Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -26.0666999817,
      "lon": 128.9499969482,
      "tz": "Australia\/Perth"
  },
  "YWNO": {
      "icao": "YWNO",
      "iata": "",
      "name": "Wonganoo Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.125,
      "lon": 121.3330001831,
      "tz": "Australia\/Perth"
  },
  "YWNS": {
      "icao": "YWNS",
      "iata": "",
      "name": "Wandsworth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -25.0499992371,
      "lon": 143.6670074463,
      "tz": "Australia\/Brisbane"
  },
  "YWOH": {
      "icao": "YWOH",
      "iata": "",
      "name": "Wongan Hills Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.8666992188,
      "lon": 116.733001709,
      "tz": "Australia\/Perth"
  },
  "YWOL": {
      "icao": "YWOL",
      "iata": "WOL",
      "name": "Wollongong Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 31,
      "lat": -34.5611000061,
      "lon": 150.7890014648,
      "tz": "Australia\/Sydney"
  },
  "YWOM": {
      "icao": "YWOM",
      "iata": "",
      "name": "Woolomin Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -31.3166999817,
      "lon": 151.1329956055,
      "tz": "Australia\/Sydney"
  },
  "YWON": {
      "icao": "YWON",
      "iata": "",
      "name": "Wonthaggi Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -38.471698761,
      "lon": 145.6230010986,
      "tz": "Australia\/Melbourne"
  },
  "YWOR": {
      "icao": "YWOR",
      "iata": "WLL",
      "name": "Wollogorang Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -17.2199993134,
      "lon": 137.9230041504,
      "tz": "Australia\/Darwin"
  },
  "YWOV": {
      "icao": "YWOV",
      "iata": "",
      "name": "Woodvale Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -36.6333007813,
      "lon": 144.1829986572,
      "tz": "Australia\/Melbourne"
  },
  "YWOX": {
      "icao": "YWOX",
      "iata": "",
      "name": "Woorlba Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.4000015259,
      "lon": 124.0329971313,
      "tz": "Australia\/Perth"
  },
  "YWPA": {
      "icao": "YWPA",
      "iata": "",
      "name": "Wirrealpa Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.1333007813,
      "lon": 138.9669952393,
      "tz": "Australia\/Adelaide"
  },
  "YWPE": {
      "icao": "YWPE",
      "iata": "",
      "name": "Walpole Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -34.9500007629,
      "lon": 116.6999969482,
      "tz": "Australia\/Perth"
  },
  "YWPL": {
      "icao": "YWPL",
      "iata": "",
      "name": "Winning Pool North Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.1000003815,
      "lon": 114.5329971313,
      "tz": "Australia\/Perth"
  },
  "YWRA": {
      "icao": "YWRA",
      "iata": "",
      "name": "Wooroona Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -20.4817008972,
      "lon": 138.1679992676,
      "tz": "Australia\/Brisbane"
  },
  "YWRC": {
      "icao": "YWRC",
      "iata": "",
      "name": "Wave Rock Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.426700592,
      "lon": 118.9079971313,
      "tz": "Australia\/Perth"
  },
  "YWRE": {
      "icao": "YWRE",
      "iata": "",
      "name": "Wirralie Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.2700004578,
      "lon": 146.0500030518,
      "tz": "Australia\/Brisbane"
  },
  "YWRL": {
      "icao": "YWRL",
      "iata": "",
      "name": "Warialda Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 347,
      "lat": -29.5333003998,
      "lon": 150.5330047607,
      "tz": "Australia\/Sydney"
  },
  "YWRN": {
      "icao": "YWRN",
      "iata": "QRR",
      "name": "Warren Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 669,
      "lat": -31.7332992554,
      "lon": 147.8029937744,
      "tz": "Australia\/Sydney"
  },
  "YWRR": {
      "icao": "YWRR",
      "iata": "",
      "name": "Warroora Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -23.4832992554,
      "lon": 113.8000030518,
      "tz": "Australia\/Perth"
  },
  "YWRT": {
      "icao": "YWRT",
      "iata": "",
      "name": "Waratah Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.4500007629,
      "lon": 145.5500030518,
      "tz": "Australia\/Hobart"
  },
  "YWRV": {
      "icao": "YWRV",
      "iata": "",
      "name": "Walker River Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 0,
      "lat": -13.5932998657,
      "lon": 135.7550048828,
      "tz": "Australia\/Darwin"
  },
  "YWSD": {
      "icao": "YWSD",
      "iata": "",
      "name": "Westward Downs Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.7250003815,
      "lon": 141.3999938965,
      "tz": "Australia\/Sydney"
  },
  "YWSG": {
      "icao": "YWSG",
      "iata": "",
      "name": "Watts Bridge Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 61,
      "lat": -27.0983009338,
      "lon": 152.4600067139,
      "tz": "Australia\/Brisbane"
  },
  "YWSI": {
      "icao": "YWSI",
      "iata": "",
      "name": "Wirrida Siding Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.5666999817,
      "lon": 134.516998291,
      "tz": "Australia\/Adelaide"
  },
  "YWSL": {
      "icao": "YWSL",
      "iata": "SXE",
      "name": "West Sale Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 93,
      "lat": -38.0917015076,
      "lon": 146.964996338,
      "tz": "Australia\/Melbourne"
  },
  "YWSX": {
      "icao": "YWSX",
      "iata": "",
      "name": "Westonia Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.3332996368,
      "lon": 118.6669998169,
      "tz": "Australia\/Perth"
  },
  "YWTL": {
      "icao": "YWTL",
      "iata": "WLO",
      "name": "Waterloo Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 132,
      "lat": -16.6299991608,
      "lon": 129.3200073242,
      "tz": "Australia\/Darwin"
  },
  "YWTN": {
      "icao": "YWTN",
      "iata": "WIN",
      "name": "Winton Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 638,
      "lat": -22.3635997772,
      "lon": 143.0859985352,
      "tz": "Australia\/Brisbane"
  },
  "YWTO": {
      "icao": "YWTO",
      "iata": "",
      "name": "Wentworth Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 37,
      "lat": -34.0882987976,
      "lon": 141.891998291,
      "tz": "Australia\/Sydney"
  },
  "YWTV": {
      "icao": "YWTV",
      "iata": "",
      "name": "Watson River Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -13.2166996002,
      "lon": 142.233001709,
      "tz": "Australia\/Brisbane"
  },
  "YWUD": {
      "icao": "YWUD",
      "iata": "WUD",
      "name": "Wudinna Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 310,
      "lat": -33.0433006287,
      "lon": 135.4470062256,
      "tz": "Australia\/Adelaide"
  },
  "YWVA": {
      "icao": "YWVA",
      "iata": "",
      "name": "Warnervale Airport",
      "city": "Warnervale",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 25,
      "lat": -33.240278,
      "lon": 151.429722,
      "tz": "Australia\/Sydney"
  },
  "YWVL": {
      "icao": "YWVL",
      "iata": "",
      "name": "Woodville Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 3707,
      "lat": -30.4160995483,
      "lon": 151.7550048828,
      "tz": "Australia\/Sydney"
  },
  "YWWA": {
      "icao": "YWWA",
      "iata": "WEW",
      "name": "Wee Waa Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 190,
      "lat": -30.2583007813,
      "lon": 149.4080047607,
      "tz": "Australia\/Sydney"
  },
  "YWWG": {
      "icao": "YWWG",
      "iata": "WRW",
      "name": "Warrawagine Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.8464596515,
      "lon": 120.705327988,
      "tz": "Australia\/Perth"
  },
  "YWWH": {
      "icao": "YWWH",
      "iata": "",
      "name": "Wentworth Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -22.1000003815,
      "lon": 147.6999969482,
      "tz": "Australia\/Brisbane"
  },
  "YWWI": {
      "icao": "YWWI",
      "iata": "WWI",
      "name": "Woodie Woodie Airport",
      "city": "Woodie Woodie",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.6627998352,
      "lon": 121.2340011597,
      "tz": "Australia\/Perth"
  },
  "YWWL": {
      "icao": "YWWL",
      "iata": "WWY",
      "name": "West Wyalong Airport",
      "city": "West Wyalong",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 859,
      "lat": -33.9371986389,
      "lon": 147.190994263,
      "tz": "Australia\/Sydney"
  },
  "YWYA": {
      "icao": "YWYA",
      "iata": "",
      "name": "Wyandra Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 244,
      "lat": -27.2667007446,
      "lon": 145.9900054932,
      "tz": "Australia\/Brisbane"
  },
  "YWYB": {
      "icao": "YWYB",
      "iata": "",
      "name": "Wynbring Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -30.5617008209,
      "lon": 133.5330047607,
      "tz": "Australia\/Adelaide"
  },
  "YWYF": {
      "icao": "YWYF",
      "iata": "",
      "name": "Wycheproof Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 107,
      "lat": -36.0583000183,
      "lon": 143.2429962158,
      "tz": "Australia\/Melbourne"
  },
  "YWYM": {
      "icao": "YWYM",
      "iata": "WYN",
      "name": "Wyndham Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 14,
      "lat": -15.5114002228,
      "lon": 128.1529998779,
      "tz": "Australia\/Perth"
  },
  "YWYY": {
      "icao": "YWYY",
      "iata": "BWT",
      "name": "Wynyard Airport",
      "city": "Burnie",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 62,
      "lat": -40.9989013672,
      "lon": 145.7310028076,
      "tz": "Australia\/Hobart"
  },
  "YXGS": {
      "icao": "YXGS",
      "iata": "",
      "name": "Gosford Airport",
      "city": "Gosford",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 853,
      "lat": -33.3680992126,
      "lon": 151.300003052,
      "tz": "Australia\/Sydney"
  },
  "YXTA": {
      "icao": "YXTA",
      "iata": "",
      "name": "Tangalooma Resort Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.2000007629,
      "lon": 153.3829956055,
      "tz": "Australia\/Brisbane"
  },
  "YYAA": {
      "icao": "YYAA",
      "iata": "",
      "name": "Yandama Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -29.6833000183,
      "lon": 141.4250030518,
      "tz": "Australia\/Sydney"
  },
  "YYAC": {
      "icao": "YYAC",
      "iata": "",
      "name": "Yacamunda Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -21.3833007813,
      "lon": 147.1000061035,
      "tz": "Australia\/Brisbane"
  },
  "YYAG": {
      "icao": "YYAG",
      "iata": "",
      "name": "Yagga Yagga Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.9666996002,
      "lon": 128.0829925537,
      "tz": "Australia\/Perth"
  },
  "YYAK": {
      "icao": "YYAK",
      "iata": "",
      "name": "Yalkulka Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -16.7332992554,
      "lon": 145.3329925537,
      "tz": "Australia\/Brisbane"
  },
  "YYAL": {
      "icao": "YYAL",
      "iata": "YLG",
      "name": "Yalgoo Airport",
      "city": "Yalgoo",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.3553009033,
      "lon": 116.683998108,
      "tz": "Australia\/Perth"
  },
  "YYAS": {
      "icao": "YYAS",
      "iata": "",
      "name": "Yass Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -34.8300018311,
      "lon": 149.0420074463,
      "tz": "Australia\/Sydney"
  },
  "YYBE": {
      "icao": "YYBE",
      "iata": "",
      "name": "Yarrabee Mine Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -23.2667007446,
      "lon": 149.016998291,
      "tz": "Australia\/Brisbane"
  },
  "YYDE": {
      "icao": "YYDE",
      "iata": "",
      "name": "Yandee Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -21.3332996368,
      "lon": 118.8669967651,
      "tz": "Australia\/Perth"
  },
  "YYDM": {
      "icao": "YYDM",
      "iata": "",
      "name": "Yundamindera Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.1166992188,
      "lon": 122.0329971313,
      "tz": "Australia\/Perth"
  },
  "YYEA": {
      "icao": "YYEA",
      "iata": "",
      "name": "Yeaburn Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 0,
      "lat": -37.1727981567,
      "lon": 145.3220062256,
      "tz": "Australia\/Melbourne"
  },
  "YYER": {
      "icao": "YYER",
      "iata": "",
      "name": "Yerilla Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -29.4675998688,
      "lon": 121.8300018311,
      "tz": "Australia\/Perth"
  },
  "YYGG": {
      "icao": "YYGG",
      "iata": "",
      "name": "Yagga Yagga Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -20.8906002045,
      "lon": 127.9489974976,
      "tz": "Australia\/Perth"
  },
  "YYKI": {
      "icao": "YYKI",
      "iata": "OKR",
      "name": "Yorke Island Airport",
      "city": "Yorke Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -9.7570295334,
      "lon": 143.4109954834,
      "tz": "Australia\/Brisbane"
  },
  "YYLD": {
      "icao": "YYLD",
      "iata": "",
      "name": "Yalda Downs Homestead Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 0,
      "lat": -30.2614002228,
      "lon": 142.9770050049,
      "tz": "Australia\/Sydney"
  },
  "YYLG": {
      "icao": "YYLG",
      "iata": "",
      "name": "Yallalong Homestead Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.4263000488,
      "lon": 115.5179977417,
      "tz": "Australia\/Perth"
  },
  "YYLR": {
      "icao": "YYLR",
      "iata": "KYF",
      "name": "Yeelirrie Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -27.2770600378,
      "lon": 120.095672607,
      "tz": "Australia\/Perth"
  },
  "YYMI": {
      "icao": "YYMI",
      "iata": "XMY",
      "name": "Yam Island Airport",
      "city": "Yam Island",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -9.9011096954,
      "lon": 142.7760009766,
      "tz": "Australia\/Brisbane"
  },
  "YYND": {
      "icao": "YYND",
      "iata": "YUE",
      "name": "Yuendumu Airport",
      "city": "",
      "state": "Northern-Territory",
      "country": "AU",
      "elevation": 2205,
      "lat": -22.2541999817,
      "lon": 131.7819976807,
      "tz": "Australia\/Darwin"
  },
  "YYNG": {
      "icao": "YYNG",
      "iata": "NGA",
      "name": "Young Airport",
      "city": "",
      "state": "New-South-Wales",
      "country": "AU",
      "elevation": 1267,
      "lat": -34.2555999756,
      "lon": 148.2480010986,
      "tz": "Australia\/Sydney"
  },
  "YYNR": {
      "icao": "YYNR",
      "iata": "",
      "name": "Yanrey Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 80,
      "lat": -22.5167007446,
      "lon": 114.8000030518,
      "tz": "Australia\/Perth"
  },
  "YYOO": {
      "icao": "YYOO",
      "iata": "",
      "name": "Yalymboo Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.8033008575,
      "lon": 136.8600006104,
      "tz": "Australia\/Adelaide"
  },
  "YYOR": {
      "icao": "YYOR",
      "iata": "ORR",
      "name": "Yorketown Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -35,
      "lon": 137.6170043945,
      "tz": "Australia\/Adelaide"
  },
  "YYRK": {
      "icao": "YYRK",
      "iata": "",
      "name": "York Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.8551006317,
      "lon": 116.7990036011,
      "tz": "Australia\/Perth"
  },
  "YYRM": {
      "icao": "YYRM",
      "iata": "",
      "name": "Yarram Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 15,
      "lat": -38.5666999817,
      "lon": 146.7550048828,
      "tz": "Australia\/Melbourne"
  },
  "YYRN": {
      "icao": "YYRN",
      "iata": "",
      "name": "Yamarna Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.1539993286,
      "lon": 123.672996521,
      "tz": "Australia\/Perth"
  },
  "YYRW": {
      "icao": "YYRW",
      "iata": "",
      "name": "Yarlarweelor Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -25.4582996368,
      "lon": 117.9749984741,
      "tz": "Australia\/Perth"
  },
  "YYTA": {
      "icao": "YYTA",
      "iata": "KYI",
      "name": "Yalata Mission Airport",
      "city": "Yalata Mission",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.4706001282,
      "lon": 131.8249969482,
      "tz": "Australia\/Adelaide"
  },
  "YYUM": {
      "icao": "YYUM",
      "iata": "",
      "name": "Yuinmery Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -28.5629997253,
      "lon": 119.0189971924,
      "tz": "Australia\/Perth"
  },
  "YYUN": {
      "icao": "YYUN",
      "iata": "",
      "name": "Yunta Airport",
      "city": "",
      "state": "South-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -32.5833015442,
      "lon": 139.5500030518,
      "tz": "Australia\/Adelaide"
  },
  "YYWA": {
      "icao": "YYWA",
      "iata": "",
      "name": "Yowah Airport",
      "city": "",
      "state": "Queensland",
      "country": "AU",
      "elevation": 0,
      "lat": -27.9333000183,
      "lon": 144.6170043945,
      "tz": "Australia\/Brisbane"
  },
  "YYWG": {
      "icao": "YYWG",
      "iata": "",
      "name": "Yarrawonga Airport",
      "city": "",
      "state": "Victoria",
      "country": "AU",
      "elevation": 423,
      "lat": -36.0289001465,
      "lon": 146.029006958,
      "tz": "Australia\/Melbourne"
  },
  "YZAN": {
      "icao": "YZAN",
      "iata": "",
      "name": "Zanthus Airport",
      "city": "",
      "state": "Western-Australia",
      "country": "AU",
      "elevation": 0,
      "lat": -31.0333003998,
      "lon": 123.5670013428,
      "tz": "Australia\/Perth"
  },
  "YZHN": {
      "icao": "YZHN",
      "iata": "",
      "name": "Zeehan Airport",
      "city": "",
      "state": "Tasmania",
      "country": "AU",
      "elevation": 0,
      "lat": -41.8833007813,
      "lon": 145.3500061035,
      "tz": "Australia\/Hobart"
  },
  "Z19M": {
      "icao": "Z19M",
      "iata": "",
      "name": "Al Abdaliyah Highway Strip",
      "city": "Al Abdaliyah",
      "state": "",
      "country": "KW",
      "elevation": 330,
      "lat": 29.04529953,
      "lon": 47.7907981873,
      "tz": "Asia\/Kuwait"
  },
  "Z23Z": {
      "icao": "Z23Z",
      "iata": "",
      "name": "Paraguari Airport",
      "city": "Paraguari",
      "state": "Paraguari",
      "country": "PY",
      "elevation": 500,
      "lat": -25.6000003815,
      "lon": -57.1500015259,
      "tz": "America\/Asuncion"
  },
  "ZBAA": {
      "icao": "ZBAA",
      "iata": "PEK",
      "name": "Beijing Capital International Airport",
      "city": "Beijing",
      "state": "Beijing",
      "country": "CN",
      "elevation": 116,
      "lat": 40.0801010132,
      "lon": 116.5849990845,
      "tz": "Asia\/Shanghai"
  },
  "ZBAD": {
      "icao": "ZBAD",
      "iata": "PKX",
      "name": "Beijing Daxing International Airport",
      "city": "Beijing",
      "state": "Beijing",
      "country": "CN",
      "elevation": 98,
      "lat": 39.509167,
      "lon": 116.410556,
      "tz": "Asia\/Shanghai"
  },
  "ZBBB": {
      "icao": "ZBBB",
      "iata": "",
      "name": "Beijing Xijiao Airport",
      "city": "Beijing",
      "state": "Beijing",
      "country": "CN",
      "elevation": 0,
      "lat": 39.9608001709,
      "lon": 116.2570037842,
      "tz": "Asia\/Shanghai"
  },
  "ZBCF": {
      "icao": "ZBCF",
      "iata": "CIF",
      "name": "Chifeng Airport",
      "city": "Chifeng",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 0,
      "lat": 42.2350006104,
      "lon": 118.9079971313,
      "tz": "Asia\/Shanghai"
  },
  "ZBCZ": {
      "icao": "ZBCZ",
      "iata": "CIH",
      "name": "Changzhi Airport",
      "city": "Changzhi",
      "state": "Shanxi",
      "country": "CN",
      "elevation": 0,
      "lat": 36.2475013733,
      "lon": 113.1259994507,
      "tz": "Asia\/Shanghai"
  },
  "ZBDS": {
      "icao": "ZBDS",
      "iata": "DSN",
      "name": "Ordos Ejin Horo Airport",
      "city": "Ordos",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 4557,
      "lat": 39.49,
      "lon": 109.861388889,
      "tz": "Asia\/Shanghai"
  },
  "ZBDT": {
      "icao": "ZBDT",
      "iata": "DAT",
      "name": "Datong Airport",
      "city": "Datong",
      "state": "Shanxi",
      "country": "CN",
      "elevation": 3442,
      "lat": 40.0602989197,
      "lon": 113.4820022583,
      "tz": "Asia\/Shanghai"
  },
  "ZBER": {
      "icao": "ZBER",
      "iata": "ERL",
      "name": "Erenhot Saiwusu International Airport",
      "city": "Erenhot",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 3301,
      "lat": 43.4225,
      "lon": 112.096666667,
      "tz": "Asia\/Shanghai"
  },
  "ZBGS": {
      "icao": "ZBGS",
      "iata": "AEB",
      "name": "Tian Yang Air Base",
      "city": "Baise",
      "state": "Guangxi",
      "country": "CN",
      "elevation": 0,
      "lat": 23.7206001282,
      "lon": 106.959999084,
      "tz": "Asia\/Shanghai"
  },
  "ZBHD": {
      "icao": "ZBHD",
      "iata": "HDG",
      "name": "Handan Airport",
      "city": "Handan",
      "state": "Hebei",
      "country": "CN",
      "elevation": 229,
      "lat": 36.5258333333,
      "lon": 114.425555556,
      "tz": "Asia\/Shanghai"
  },
  "ZBHH": {
      "icao": "ZBHH",
      "iata": "HET",
      "name": "Baita International Airport",
      "city": "Hohhot",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 3556,
      "lat": 40.851398468,
      "lon": 111.823997498,
      "tz": "Asia\/Shanghai"
  },
  "ZBLA": {
      "icao": "ZBLA",
      "iata": "HLD",
      "name": "Dongshan Airport",
      "city": "Hailar",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 2169,
      "lat": 49.2050018311,
      "lon": 119.824996948,
      "tz": "Asia\/Shanghai"
  },
  "ZBNY": {
      "icao": "ZBNY",
      "iata": "NAY",
      "name": "Beijing Nanyuan Airport",
      "city": "Beijing",
      "state": "Beijing",
      "country": "CN",
      "elevation": 0,
      "lat": 39.7827987671,
      "lon": 116.3880004883,
      "tz": "Asia\/Shanghai"
  },
  "ZBOW": {
      "icao": "ZBOW",
      "iata": "BAV",
      "name": "Baotou Airport",
      "city": "Baotou",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 3321,
      "lat": 40.5600013733,
      "lon": 109.9970016479,
      "tz": "Asia\/Shanghai"
  },
  "ZBSH": {
      "icao": "ZBSH",
      "iata": "SHP",
      "name": "Shanhaiguan Airport",
      "city": "Qinhuangdao",
      "state": "Hebei",
      "country": "CN",
      "elevation": 30,
      "lat": 39.9681015015,
      "lon": 119.731002808,
      "tz": "Asia\/Shanghai"
  },
  "ZBSJ": {
      "icao": "ZBSJ",
      "iata": "SJW",
      "name": "Shijiazhuang Daguocun International Airport",
      "city": "Shijiazhuang",
      "state": "Hebei",
      "country": "CN",
      "elevation": 233,
      "lat": 38.2807006836,
      "lon": 114.6969985962,
      "tz": "Asia\/Shanghai"
  },
  "ZBTJ": {
      "icao": "ZBTJ",
      "iata": "TSN",
      "name": "Tianjin Binhai International Airport",
      "city": "Tianjin",
      "state": "Tianjin",
      "country": "CN",
      "elevation": 10,
      "lat": 39.1244010925,
      "lon": 117.346000671,
      "tz": "Asia\/Shanghai"
  },
  "ZBTL": {
      "icao": "ZBTL",
      "iata": "TGO",
      "name": "Tongliao Airport",
      "city": "Tongliao",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 0,
      "lat": 43.5567016602,
      "lon": 122.1999969482,
      "tz": "Asia\/Shanghai"
  },
  "ZBUL": {
      "icao": "ZBUL",
      "iata": "HLH",
      "name": "Ulanhot Airport",
      "city": "Ulanhot",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 0,
      "lat": 46.195333,
      "lon": 122.008333,
      "tz": "Asia\/Shanghai"
  },
  "ZBXH": {
      "icao": "ZBXH",
      "iata": "XIL",
      "name": "Xilinhot Airport",
      "city": "Xilinhot",
      "state": "Inner-Mongolia",
      "country": "CN",
      "elevation": 0,
      "lat": 43.915599823,
      "lon": 115.9639968872,
      "tz": "Asia\/Shanghai"
  },
  "ZBYC": {
      "icao": "ZBYC",
      "iata": "YCU",
      "name": "Yuncheng Guangong Airport",
      "city": "Yuncheng",
      "state": "Shanxi",
      "country": "CN",
      "elevation": 1242,
      "lat": 35.116391,
      "lon": 111.031388889,
      "tz": "Asia\/Shanghai"
  },
  "ZBYN": {
      "icao": "ZBYN",
      "iata": "TYN",
      "name": "Taiyuan Wusu Airport",
      "city": "Taiyuan",
      "state": "Shanxi",
      "country": "CN",
      "elevation": 2575,
      "lat": 37.7468986511,
      "lon": 112.6279983521,
      "tz": "Asia\/Shanghai"
  },
  "ZGBH": {
      "icao": "ZGBH",
      "iata": "BHY",
      "name": "Beihai Airport",
      "city": "Beihai",
      "state": "Guangxi",
      "country": "CN",
      "elevation": 0,
      "lat": 21.5394001007,
      "lon": 109.2939987183,
      "tz": "Asia\/Shanghai"
  },
  "ZGCD": {
      "icao": "ZGCD",
      "iata": "CGD",
      "name": "Changde Airport",
      "city": "Changde",
      "state": "Hunan",
      "country": "CN",
      "elevation": 128,
      "lat": 28.9188995361,
      "lon": 111.63999939,
      "tz": "Asia\/Shanghai"
  },
  "ZGCJ": {
      "icao": "ZGCJ",
      "iata": "HJJ",
      "name": "Zhijiang Airport",
      "city": "Huaihua",
      "state": "Hunan",
      "country": "CN",
      "elevation": 882,
      "lat": 27.4411111111,
      "lon": 109.7,
      "tz": "Asia\/Shanghai"
  },
  "ZGDY": {
      "icao": "ZGDY",
      "iata": "DYG",
      "name": "Dayong Airport",
      "city": "Dayong",
      "state": "Hunan",
      "country": "CN",
      "elevation": 692,
      "lat": 29.1028003693,
      "lon": 110.4430007935,
      "tz": "Asia\/Shanghai"
  },
  "ZGFS": {
      "icao": "ZGFS",
      "iata": "FUO",
      "name": "Foshan Shadi Airport",
      "city": "Foshan",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 6,
      "lat": 23.0832996368,
      "lon": 113.069999695,
      "tz": "Asia\/Shanghai"
  },
  "ZGGG": {
      "icao": "ZGGG",
      "iata": "CAN",
      "name": "Guangzhou Baiyun International Airport",
      "city": "Guangzhou",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 50,
      "lat": 23.3924007416,
      "lon": 113.2990036011,
      "tz": "Asia\/Shanghai"
  },
  "ZGHA": {
      "icao": "ZGHA",
      "iata": "CSX",
      "name": "Changsha Huanghua Airport",
      "city": "Changsha",
      "state": "Hunan",
      "country": "CN",
      "elevation": 217,
      "lat": 28.1891994476,
      "lon": 113.2200012207,
      "tz": "Asia\/Shanghai"
  },
  "ZGHY": {
      "icao": "ZGHY",
      "iata": "HNY",
      "name": "Hengyang Airport",
      "city": "Hengyang",
      "state": "Hunan",
      "country": "CN",
      "elevation": 0,
      "lat": 26.9053001404,
      "lon": 112.6279983521,
      "tz": "Asia\/Shanghai"
  },
  "ZGHZ": {
      "icao": "ZGHZ",
      "iata": "HUZ",
      "name": "Huizhou Airport",
      "city": "Huizhou",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 50,
      "lat": 23.0499992371,
      "lon": 114.599998474,
      "tz": "Asia\/Shanghai"
  },
  "ZGKL": {
      "icao": "ZGKL",
      "iata": "KWL",
      "name": "Guilin Liangjiang International Airport",
      "city": "Guilin City",
      "state": "Guangxi",
      "country": "CN",
      "elevation": 570,
      "lat": 25.2180995941,
      "lon": 110.0390014648,
      "tz": "Asia\/Shanghai"
  },
  "ZGLD": {
      "icao": "ZGLD",
      "iata": "",
      "name": "Luoding Sulong Airport.",
      "city": "Louding City",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 190,
      "lat": 22.711169,
      "lon": 111.60134,
      "tz": "Asia\/Shanghai"
  },
  "ZGLG": {
      "icao": "ZGLG",
      "iata": "LLF",
      "name": "Lingling Airport",
      "city": "Yongzhou",
      "state": "Hunan",
      "country": "CN",
      "elevation": 340,
      "lat": 26.338661,
      "lon": 111.610043,
      "tz": "Asia\/Shanghai"
  },
  "ZGMX": {
      "icao": "ZGMX",
      "iata": "MXZ",
      "name": "Meixian Airport",
      "city": "Meixian",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 0,
      "lat": 24.3500003815,
      "lon": 116.1330032349,
      "tz": "Asia\/Shanghai"
  },
  "ZGNN": {
      "icao": "ZGNN",
      "iata": "NNG",
      "name": "Nanning Wuxu Airport",
      "city": "Nanning",
      "state": "Guangxi",
      "country": "CN",
      "elevation": 421,
      "lat": 22.6082992554,
      "lon": 108.1719970703,
      "tz": "Asia\/Shanghai"
  },
  "ZGOW": {
      "icao": "ZGOW",
      "iata": "SWA",
      "name": "Shantou Waisha Airport",
      "city": "Shantou",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 0,
      "lat": 23.4269008636,
      "lon": 116.7620010376,
      "tz": "Asia\/Shanghai"
  },
  "ZGSD": {
      "icao": "ZGSD",
      "iata": "ZUH",
      "name": "Zhuhai Airport",
      "city": "Zhuhai",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 0,
      "lat": 22.0063991547,
      "lon": 113.3759994507,
      "tz": "Asia\/Shanghai"
  },
  "ZGSZ": {
      "icao": "ZGSZ",
      "iata": "SZX",
      "name": "Shenzhen Bao'an International Airport",
      "city": "Shenzhen",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 13,
      "lat": 22.6392993927,
      "lon": 113.8109970093,
      "tz": "Asia\/Shanghai"
  },
  "ZGWZ": {
      "icao": "ZGWZ",
      "iata": "WUZ",
      "name": "Changzhoudao Airport",
      "city": "Wuzhou",
      "state": "Guangxi",
      "country": "CN",
      "elevation": 89,
      "lat": 23.4566993713,
      "lon": 111.2480010986,
      "tz": "Asia\/Shanghai"
  },
  "ZGXN": {
      "icao": "ZGXN",
      "iata": "XIN",
      "name": "Xingning Airport",
      "city": "Xingning",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 0,
      "lat": 24.1492004395,
      "lon": 115.7580032349,
      "tz": "Asia\/Shanghai"
  },
  "ZGZH": {
      "icao": "ZGZH",
      "iata": "LZH",
      "name": "Bailian Airport",
      "city": "Liuzhou",
      "state": "Guangxi",
      "country": "CN",
      "elevation": 295,
      "lat": 24.2075004578,
      "lon": 109.3909988403,
      "tz": "Asia\/Shanghai"
  },
  "ZGZJ": {
      "icao": "ZGZJ",
      "iata": "ZHA",
      "name": "Zhanjiang Airport",
      "city": "Zhanjiang",
      "state": "Guangdong",
      "country": "CN",
      "elevation": 0,
      "lat": 21.2143993378,
      "lon": 110.358001709,
      "tz": "Asia\/Shanghai"
  },
  "ZHAY": {
      "icao": "ZHAY",
      "iata": "AYN",
      "name": "Anyang Airport",
      "city": "Anyang",
      "state": "Henan",
      "country": "CN",
      "elevation": 0,
      "lat": 36.1338996887,
      "lon": 114.34400177,
      "tz": "Asia\/Shanghai"
  },
  "ZHCC": {
      "icao": "ZHCC",
      "iata": "CGO",
      "name": "Xinzheng Airport",
      "city": "Zhengzhou",
      "state": "Henan",
      "country": "CN",
      "elevation": 495,
      "lat": 34.5196990967,
      "lon": 113.841003418,
      "tz": "Asia\/Shanghai"
  },
  "ZHES": {
      "icao": "ZHES",
      "iata": "ENH",
      "name": "Enshi Airport",
      "city": "Enshi",
      "state": "Hubei",
      "country": "CN",
      "elevation": 1605,
      "lat": 30.3202991486,
      "lon": 109.48500061,
      "tz": "Asia\/Shanghai"
  },
  "ZHGH": {
      "icao": "ZHGH",
      "iata": "LHK",
      "name": "Guangzhou MR Air Base",
      "city": "Guanghua",
      "state": "Hubei",
      "country": "CN",
      "elevation": 0,
      "lat": 32.3894004822,
      "lon": 111.6949996948,
      "tz": "Asia\/Shanghai"
  },
  "ZHHH": {
      "icao": "ZHHH",
      "iata": "WUH",
      "name": "Wuhan Tianhe International Airport",
      "city": "Wuhan",
      "state": "Hubei",
      "country": "CN",
      "elevation": 113,
      "lat": 30.7838001251,
      "lon": 114.2080001831,
      "tz": "Asia\/Shanghai"
  },
  "ZHLY": {
      "icao": "ZHLY",
      "iata": "LYA",
      "name": "Luoyang Airport",
      "city": "Luoyang",
      "state": "Henan",
      "country": "CN",
      "elevation": 840,
      "lat": 34.7411003113,
      "lon": 112.388000488,
      "tz": "Asia\/Shanghai"
  },
  "ZHNY": {
      "icao": "ZHNY",
      "iata": "NNY",
      "name": "Nanyang Airport",
      "city": "Nanyang",
      "state": "Henan",
      "country": "CN",
      "elevation": 0,
      "lat": 32.9808006287,
      "lon": 112.6149978638,
      "tz": "Asia\/Shanghai"
  },
  "ZHSS": {
      "icao": "ZHSS",
      "iata": "SHS",
      "name": "Shashi Airport",
      "city": "Shashi",
      "state": "Hubei",
      "country": "CN",
      "elevation": 0,
      "lat": 30.3243999481,
      "lon": 112.28099823,
      "tz": "Asia\/Shanghai"
  },
  "ZHXF": {
      "icao": "ZHXF",
      "iata": "XFN",
      "name": "Xiangfan Airport",
      "city": "Xiangfan",
      "state": "Hubei",
      "country": "CN",
      "elevation": 0,
      "lat": 32.1506004333,
      "lon": 112.2910003662,
      "tz": "Asia\/Shanghai"
  },
  "ZHYC": {
      "icao": "ZHYC",
      "iata": "YIH",
      "name": "Yichang Airport",
      "city": "Yichang",
      "state": "Hubei",
      "country": "CN",
      "elevation": 0,
      "lat": 30.556549722,
      "lon": 111.479988333,
      "tz": "Asia\/Shanghai"
  },
  "ZJHK": {
      "icao": "ZJHK",
      "iata": "HAK",
      "name": "Haikou Meilan International Airport",
      "city": "Haikou",
      "state": "Hainan",
      "country": "CN",
      "elevation": 75,
      "lat": 19.9349002838,
      "lon": 110.4589996338,
      "tz": "Asia\/Shanghai"
  },
  "ZJSY": {
      "icao": "ZJSY",
      "iata": "SYX",
      "name": "Sanya Phoenix International Airport",
      "city": "Sanya",
      "state": "Hainan",
      "country": "CN",
      "elevation": 92,
      "lat": 18.3029003143,
      "lon": 109.4120025635,
      "tz": "Asia\/Shanghai"
  },
  "ZJYX": {
      "icao": "ZJYX",
      "iata": "",
      "name": "Woody Island Airport",
      "city": "Woody Island",
      "state": "",
      "country": "CN",
      "elevation": 0,
      "lat": 16.8327999115,
      "lon": 112.34400177,
      "tz": "Asia\/Shanghai"
  },
  "ZKHM": {
      "icao": "ZKHM",
      "iata": "RGO",
      "name": "Orang Airport",
      "city": "",
      "state": "Hamgyong-bukto",
      "country": "KP",
      "elevation": 12,
      "lat": 41.4281005859,
      "lon": 129.647003174,
      "tz": "Asia\/Pyongyang"
  },
  "ZKPY": {
      "icao": "ZKPY",
      "iata": "FNJ",
      "name": "Sunan International Airport",
      "city": "Pyongyang",
      "state": "South-Pyongan",
      "country": "KP",
      "elevation": 117,
      "lat": 39.2240982056,
      "lon": 125.6699981689,
      "tz": "Asia\/Pyongyang"
  },
  "ZKSD": {
      "icao": "ZKSD",
      "iata": "DSO",
      "name": "Sondok Airport",
      "city": "",
      "state": "Hamgyong-namdo",
      "country": "KP",
      "elevation": 12,
      "lat": 39.7452011108,
      "lon": 127.473999023,
      "tz": "Asia\/Pyongyang"
  },
  "ZKSE": {
      "icao": "ZKSE",
      "iata": "YJS",
      "name": "Samjiyon Airport",
      "city": "Samjiyon",
      "state": "Yanggang-do",
      "country": "KP",
      "elevation": 4547,
      "lat": 41.9058990479,
      "lon": 128.410003662,
      "tz": "Asia\/Pyongyang"
  },
  "ZLAK": {
      "icao": "ZLAK",
      "iata": "AKA",
      "name": "Ankang Airport",
      "city": "Ankang",
      "state": "Shaanxi",
      "country": "CN",
      "elevation": 0,
      "lat": 32.7080993652,
      "lon": 108.9309997559,
      "tz": "Asia\/Shanghai"
  },
  "ZLDH": {
      "icao": "ZLDH",
      "iata": "DNH",
      "name": "Dunhuang Airport",
      "city": "Dunhuang",
      "state": "Gansu",
      "country": "CN",
      "elevation": 0,
      "lat": 40.1610984802,
      "lon": 94.8091964722,
      "tz": "Asia\/Shanghai"
  },
  "ZLGM": {
      "icao": "ZLGM",
      "iata": "GOQ",
      "name": "Golmud Airport",
      "city": "Golmud",
      "state": "Qinghai",
      "country": "CN",
      "elevation": 0,
      "lat": 36.4006004333,
      "lon": 94.7861022949,
      "tz": "Asia\/Shanghai"
  },
  "ZLGY": {
      "icao": "ZLGY",
      "iata": "GYU",
      "name": "Guyuan Liupanshan Airport",
      "city": "Guyuan",
      "state": "Ningsia-Hui-Autonomous-Region",
      "country": "CN",
      "elevation": 5696,
      "lat": 36.0788888889,
      "lon": 106.216944444,
      "tz": "Asia\/Shanghai"
  },
  "ZLHZ": {
      "icao": "ZLHZ",
      "iata": "HZG",
      "name": "Hanzhong Airport",
      "city": "Hanzhong",
      "state": "Shaanxi",
      "country": "CN",
      "elevation": 0,
      "lat": 33.0635986328,
      "lon": 107.0080032349,
      "tz": "Asia\/Shanghai"
  },
  "ZLIC": {
      "icao": "ZLIC",
      "iata": "INC",
      "name": "Yinchuan Airport",
      "city": "Yinchuan",
      "state": "Ningsia-Hui-Autonomous-Region",
      "country": "CN",
      "elevation": 0,
      "lat": 38.4818992615,
      "lon": 106.0090026855,
      "tz": "Asia\/Shanghai"
  },
  "ZLJC": {
      "icao": "ZLJC",
      "iata": "JIC",
      "name": "Jinchuan Airport",
      "city": "Jinchang",
      "state": "Gansu",
      "country": "CN",
      "elevation": 4740,
      "lat": 38.5422222222,
      "lon": 102.348333333,
      "tz": "Asia\/Shanghai"
  },
  "ZLJN": {
      "icao": "ZLJN",
      "iata": "JNG",
      "name": "Jining Qufu Airport",
      "city": "Jining",
      "state": "Shandong",
      "country": "CN",
      "elevation": 134,
      "lat": 35.2927777778,
      "lon": 116.346666667,
      "tz": "Asia\/Shanghai"
  },
  "ZLJQ": {
      "icao": "ZLJQ",
      "iata": "JGN",
      "name": "Jiayuguan Airport",
      "city": "Jiayuguan",
      "state": "Gansu",
      "country": "CN",
      "elevation": 0,
      "lat": 39.8568992615,
      "lon": 98.3414001465,
      "tz": "Asia\/Shanghai"
  },
  "ZLLL": {
      "icao": "ZLLL",
      "iata": "LHW",
      "name": "Lanzhou Zhongchuan Airport",
      "city": "Lanzhou",
      "state": "Gansu",
      "country": "CN",
      "elevation": 6388,
      "lat": 36.5152015686,
      "lon": 103.620002747,
      "tz": "Asia\/Shanghai"
  },
  "ZLQY": {
      "icao": "ZLQY",
      "iata": "IQN",
      "name": "Qingyang Airport",
      "city": "Qingyang",
      "state": "Gansu",
      "country": "CN",
      "elevation": 0,
      "lat": 35.7997016907,
      "lon": 107.6029968262,
      "tz": "Asia\/Shanghai"
  },
  "ZLSN": {
      "icao": "ZLSN",
      "iata": "SIA",
      "name": "Xiguan Airport",
      "city": "Xi'an",
      "state": "Shaanxi",
      "country": "CN",
      "elevation": 0,
      "lat": 34.376701355,
      "lon": 109.1200027466,
      "tz": "Asia\/Shanghai"
  },
  "ZLTS": {
      "icao": "ZLTS",
      "iata": "THQ",
      "name": "Tianshui Maijishan Airport",
      "city": "Tianshui",
      "state": "Gansu",
      "country": "CN",
      "elevation": 3590,
      "lat": 34.5593986511,
      "lon": 105.86000061,
      "tz": "Asia\/Shanghai"
  },
  "ZLXN": {
      "icao": "ZLXN",
      "iata": "XNN",
      "name": "Xining Caojiabu Airport",
      "city": "Xining",
      "state": "Qinghai",
      "country": "CN",
      "elevation": 0,
      "lat": 36.5275001526,
      "lon": 102.0429992676,
      "tz": "Asia\/Shanghai"
  },
  "ZLXY": {
      "icao": "ZLXY",
      "iata": "XIY",
      "name": "Xi'an Xianyang International Airport",
      "city": "Xianyang",
      "state": "Shaanxi",
      "country": "CN",
      "elevation": 1572,
      "lat": 34.447101593,
      "lon": 108.7519989014,
      "tz": "Asia\/Shanghai"
  },
  "ZLYA": {
      "icao": "ZLYA",
      "iata": "ENY",
      "name": "Yan'an Airport",
      "city": "Yan'an",
      "state": "Shaanxi",
      "country": "CN",
      "elevation": 0,
      "lat": 36.6369018555,
      "lon": 109.5540008545,
      "tz": "Asia\/Shanghai"
  },
  "ZLYL": {
      "icao": "ZLYL",
      "iata": "UYN",
      "name": "Yulin Airport",
      "city": "Yulin",
      "state": "Shaanxi",
      "country": "CN",
      "elevation": 0,
      "lat": 38.2691993713,
      "lon": 109.7310028076,
      "tz": "Asia\/Shanghai"
  },
  "ZLZW": {
      "icao": "ZLZW",
      "iata": "ZHY",
      "name": "Zhongwei Shapotou Airport",
      "city": "Zhongwei",
      "state": "Ningxia Hui Autonomous Region",
      "country": "CN",
      "elevation": 8202,
      "lat": 37.572778,
      "lon": 105.154444,
      "tz": "Asia\/Shanghai"
  },
  "ZMAH": {
      "icao": "ZMAH",
      "iata": "AVK",
      "name": "Arvaikheer Airport",
      "city": "Arvaikheer",
      "state": "Oevorhangay",
      "country": "MN",
      "elevation": 5932,
      "lat": 46.2503013611,
      "lon": 102.8020019531,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMAT": {
      "icao": "ZMAT",
      "iata": "LTI",
      "name": "Altai Airport",
      "city": "Altai",
      "state": "Govi-Altay",
      "country": "MN",
      "elevation": 7260,
      "lat": 46.3763999939,
      "lon": 96.2210998535,
      "tz": "Asia\/Hovd"
  },
  "ZMBD": {
      "icao": "ZMBD",
      "iata": "",
      "name": "Binder Airport",
      "city": "Binder",
      "state": "Hentiy",
      "country": "MN",
      "elevation": 3422,
      "lat": 48.6067008972,
      "lon": 110.608001709,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMBH": {
      "icao": "ZMBH",
      "iata": "BYN",
      "name": "Bayankhongor Airport",
      "city": "Bayankhongor",
      "state": "Bayanhongor",
      "country": "MN",
      "elevation": 6085,
      "lat": 46.1632995605,
      "lon": 100.7040023804,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMBN": {
      "icao": "ZMBN",
      "iata": "UGA",
      "name": "Bulgan Airport",
      "city": "Bulgan",
      "state": "Bulgan",
      "country": "MN",
      "elevation": 4311,
      "lat": 48.8549995422,
      "lon": 103.4759979248,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMBR": {
      "icao": "ZMBR",
      "iata": "UGT",
      "name": "Bulagtai Resort Airport",
      "city": "Umnugobitour",
      "state": "Oemnogovi",
      "country": "MN",
      "elevation": 4659,
      "lat": 43.749304182,
      "lon": 104.114985466,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMBS": {
      "icao": "ZMBS",
      "iata": "HBU",
      "name": "Bulgan Sum Airport",
      "city": "",
      "state": "Hovd",
      "country": "MN",
      "elevation": 3921,
      "lat": 46.1006011963,
      "lon": 91.584197998,
      "tz": "Asia\/Hovd"
  },
  "ZMBU": {
      "icao": "ZMBU",
      "iata": "UUN",
      "name": "Baruun Urt Airport",
      "city": "",
      "state": "Suehbaatar",
      "country": "MN",
      "elevation": 3205,
      "lat": 46.6603012085,
      "lon": 113.2850036621,
      "tz": "Asia\/Choibalsan"
  },
  "ZMCD": {
      "icao": "ZMCD",
      "iata": "COQ",
      "name": "Choibalsan Airport",
      "city": "",
      "state": "East-Aimak",
      "country": "MN",
      "elevation": 2457,
      "lat": 48.1357002258,
      "lon": 114.6460037231,
      "tz": "Asia\/Choibalsan"
  },
  "ZMCK": {
      "icao": "ZMCK",
      "iata": "UBN",
      "name": "Chinggis Khaan International Airport",
      "city": "Ulaanbaatar",
      "state": "Ulaanbaatar",
      "country": "MN",
      "elevation": 4482,
      "lat": 47.651581,
      "lon": 106.821772,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMDZ": {
      "icao": "ZMDZ",
      "iata": "DLZ",
      "name": "Dalanzadgad Airport",
      "city": "Dalanzadgad",
      "state": "Oemnogovi",
      "country": "MN",
      "elevation": 4787,
      "lat": 43.5917015076,
      "lon": 104.4300003052,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMHH": {
      "icao": "ZMHH",
      "iata": "KHR",
      "name": "Kharkhorin Airport",
      "city": "",
      "state": "Oevorhangay",
      "country": "MN",
      "elevation": 4759,
      "lat": 47.246667,
      "lon": 102.826111,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMHU": {
      "icao": "ZMHU",
      "iata": "HJT",
      "name": "Khujirt Airport",
      "city": "",
      "state": "Oevorhangay",
      "country": "MN",
      "elevation": 5522,
      "lat": 46.9258003235,
      "lon": 102.7730026245,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMKD": {
      "icao": "ZMKD",
      "iata": "HVD",
      "name": "Khovd Airport",
      "city": "Khovd",
      "state": "Hovd",
      "country": "MN",
      "elevation": 4898,
      "lat": 47.9541015625,
      "lon": 91.6281967163,
      "tz": "Asia\/Hovd"
  },
  "ZMMN": {
      "icao": "ZMMN",
      "iata": "MXV",
      "name": "Moron Airport",
      "city": "Moron",
      "state": "Hovsgol",
      "country": "MN",
      "elevation": 4272,
      "lat": 49.6632995605,
      "lon": 100.0989990234,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMOT": {
      "icao": "ZMOT",
      "iata": "",
      "name": "Oyu Tolgoi",
      "city": "",
      "state": "Oemnogovi",
      "country": "MN",
      "elevation": 0,
      "lat": 43.0157638761,
      "lon": 106.839991808,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMSH": {
      "icao": "ZMSH",
      "iata": "",
      "name": "Sainshand Airport",
      "city": "",
      "state": "East-Gobi-Aymag",
      "country": "MN",
      "elevation": 3022,
      "lat": 44.9831008911,
      "lon": 110.1669998169,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMUB": {
      "icao": "ZMUB",
      "iata": "ULN",
      "name": "Chinggis Khaan International Airport",
      "city": "Ulan Bator",
      "state": "Ulaanbaatar",
      "country": "MN",
      "elevation": 4364,
      "lat": 47.8431015015,
      "lon": 106.766998291,
      "tz": "Asia\/Ulaanbaatar"
  },
  "ZMUG": {
      "icao": "ZMUG",
      "iata": "ULO",
      "name": "Ulaangom Airport",
      "city": "",
      "state": "Uvs",
      "country": "MN",
      "elevation": 0,
      "lat": 49.973333,
      "lon": 92.079722,
      "tz": "Asia\/Hovd"
  },
  "ZMUL": {
      "icao": "ZMUL",
      "iata": "ULG",
      "name": "Ulgii Mongolei Airport",
      "city": "",
      "state": "Bayan-Oelgiy",
      "country": "MN",
      "elevation": 5732,
      "lat": 48.9933013916,
      "lon": 89.9225006104,
      "tz": "Asia\/Hovd"
  },
  "ZPBS": {
      "icao": "ZPBS",
      "iata": "BSD",
      "name": "Baoshan Yunduan Airport",
      "city": "",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 0,
      "lat": 25.0533008575,
      "lon": 99.168296814,
      "tz": "Asia\/Shanghai"
  },
  "ZPDL": {
      "icao": "ZPDL",
      "iata": "DLU",
      "name": "Dali Airport",
      "city": "Xiaguan",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 0,
      "lat": 25.6494007111,
      "lon": 100.3190002441,
      "tz": "Asia\/Shanghai"
  },
  "ZPDQ": {
      "icao": "ZPDQ",
      "iata": "DIG",
      "name": "Diqing Airport",
      "city": "Shangri-La",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 0,
      "lat": 27.7936000824,
      "lon": 99.6772003174,
      "tz": "Asia\/Shanghai"
  },
  "ZPLC": {
      "icao": "ZPLC",
      "iata": "LNJ",
      "name": "Lincang Airport",
      "city": "Lincang",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 6127,
      "lat": 23.738333,
      "lon": 100.025,
      "tz": "Asia\/Shanghai"
  },
  "ZPJH": {
      "icao": "ZPJH",
      "iata": "JHG",
      "name": "Xishuangbanna Gasa Airport",
      "city": "Jinghong",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 1815,
      "lat": 21.9738998413,
      "lon": 100.7600021362,
      "tz": "Asia\/Shanghai"
  },
  "ZPLJ": {
      "icao": "ZPLJ",
      "iata": "LJG",
      "name": "Lijiang Airport",
      "city": "Lijiang",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 0,
      "lat": 26.6800003052,
      "lon": 100.246002197,
      "tz": "Asia\/Shanghai"
  },
  "ZPLX": {
      "icao": "ZPLX",
      "iata": "LUM",
      "name": "Mangshi Airport",
      "city": "Luxi",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 2890,
      "lat": 24.4011001587,
      "lon": 98.5317001343,
      "tz": "Asia\/Shanghai"
  },
  "ZPPP": {
      "icao": "ZPPP",
      "iata": "KMG",
      "name": "Kunming Wujiaba International Airport",
      "city": "Kunming",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 6217,
      "lat": 24.9923992157,
      "lon": 102.7440032959,
      "tz": "Asia\/Shanghai"
  },
  "ZPSM": {
      "icao": "ZPSM",
      "iata": "SYM",
      "name": "Simao Airport",
      "city": "Simao",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 0,
      "lat": 22.7933006287,
      "lon": 100.9589996338,
      "tz": "Asia\/Shanghai"
  },
  "ZPZT": {
      "icao": "ZPZT",
      "iata": "ZAT",
      "name": "Zhaotong Airport",
      "city": "Zhaotong",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 0,
      "lat": 27.3255996704,
      "lon": 103.7549972534,
      "tz": "Asia\/Shanghai"
  },
  "ZSAM": {
      "icao": "ZSAM",
      "iata": "XMN",
      "name": "Xiamen Gaoqi International Airport",
      "city": "Xiamen",
      "state": "Fujian",
      "country": "CN",
      "elevation": 59,
      "lat": 24.5440006256,
      "lon": 118.1279983521,
      "tz": "Asia\/Shanghai"
  },
  "ZSAQ": {
      "icao": "ZSAQ",
      "iata": "AQG",
      "name": "Anqing Airport",
      "city": "Anqing",
      "state": "Anhui",
      "country": "CN",
      "elevation": 0,
      "lat": 30.5821990967,
      "lon": 117.0500030518,
      "tz": "Asia\/Shanghai"
  },
  "ZSBB": {
      "icao": "ZSBB",
      "iata": "BFU",
      "name": "Bengbu Airport",
      "city": "Bengbu",
      "state": "Anhui",
      "country": "CN",
      "elevation": 100,
      "lat": 32.8477333333,
      "lon": 117.320244444,
      "tz": "Asia\/Shanghai"
  },
  "ZSCG": {
      "icao": "ZSCG",
      "iata": "CZX",
      "name": "Changzhou Airport",
      "city": "Changzhou",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 0,
      "lat": 31.9197006226,
      "lon": 119.7789993286,
      "tz": "Asia\/Shanghai"
  },
  "ZSCN": {
      "icao": "ZSCN",
      "iata": "KHN",
      "name": "Nanchang Changbei International Airport",
      "city": "Nanchang",
      "state": "Jiangxi",
      "country": "CN",
      "elevation": 143,
      "lat": 28.8649997711,
      "lon": 115.9000015259,
      "tz": "Asia\/Shanghai"
  },
  "ZSDY": {
      "icao": "ZSDY",
      "iata": "DOY",
      "name": "Dongying Shengli Airport",
      "city": "Dongying",
      "state": "Shandong",
      "country": "CN",
      "elevation": 15,
      "lat": 37.5085983276,
      "lon": 118.788002014,
      "tz": "Asia\/Shanghai"
  },
  "ZSFY": {
      "icao": "ZSFY",
      "iata": "FUG",
      "name": "Fuyang Xiguan Airport",
      "city": "Fuyang",
      "state": "Anhui",
      "country": "CN",
      "elevation": 104,
      "lat": 32.882157,
      "lon": 115.734364,
      "tz": "Asia\/Shanghai"
  },
  "ZSFZ": {
      "icao": "ZSFZ",
      "iata": "FOC",
      "name": "Fuzhou Changle International Airport",
      "city": "Fuzhou",
      "state": "Fujian",
      "country": "CN",
      "elevation": 46,
      "lat": 25.9351005554,
      "lon": 119.6630020142,
      "tz": "Asia\/Shanghai"
  },
  "ZSGS": {
      "icao": "ZSGS",
      "iata": "JGS",
      "name": "Jinggangshan Airport",
      "city": "Ji'an",
      "state": "Jiangxi",
      "country": "CN",
      "elevation": 281,
      "lat": 26.8568992615,
      "lon": 114.736999512,
      "tz": "Asia\/Shanghai"
  },
  "ZSGZ": {
      "icao": "ZSGZ",
      "iata": "KOW",
      "name": "Ganzhou Airport",
      "city": "Ganzhou",
      "state": "Jiangxi",
      "country": "CN",
      "elevation": 387,
      "lat": 25.825799942,
      "lon": 114.912002563,
      "tz": "Asia\/Shanghai"
  },
  "ZSHC": {
      "icao": "ZSHC",
      "iata": "HGH",
      "name": "Hangzhou Xiaoshan International Airport",
      "city": "Hangzhou",
      "state": "Zhejiang",
      "country": "CN",
      "elevation": 23,
      "lat": 30.2294998169,
      "lon": 120.4339981079,
      "tz": "Asia\/Shanghai"
  },
  "ZSJD": {
      "icao": "ZSJD",
      "iata": "JDZ",
      "name": "Jingdezhen Airport",
      "city": "Jingdezhen",
      "state": "Jiangxi",
      "country": "CN",
      "elevation": 112,
      "lat": 29.3386001587,
      "lon": 117.176002502,
      "tz": "Asia\/Shanghai"
  },
  "ZSJJ": {
      "icao": "ZSJJ",
      "iata": "JIU",
      "name": "Jiujiang Lushan Airport",
      "city": "Jiujiang",
      "state": "Jiangxi",
      "country": "CN",
      "elevation": 0,
      "lat": 29.476944,
      "lon": 115.801111,
      "tz": "Asia\/Shanghai"
  },
  "ZSJN": {
      "icao": "ZSJN",
      "iata": "TNA",
      "name": "Yaoqiang Airport",
      "city": "Jinan",
      "state": "Shandong",
      "country": "CN",
      "elevation": 76,
      "lat": 36.8572006226,
      "lon": 117.216003418,
      "tz": "Asia\/Shanghai"
  },
  "ZSJU": {
      "icao": "ZSJU",
      "iata": "JUZ",
      "name": "Quzhou Airport",
      "city": "Quzhou",
      "state": "Zhejiang",
      "country": "CN",
      "elevation": 0,
      "lat": 28.9657993317,
      "lon": 118.8990020752,
      "tz": "Asia\/Shanghai"
  },
  "ZSLD": {
      "icao": "ZSLD",
      "iata": "LCX",
      "name": "Longyan Guanzhishan Airport",
      "city": "Longyan",
      "state": "Fujian",
      "country": "CN",
      "elevation": 1225,
      "lat": 25.6746997833,
      "lon": 116.747001648,
      "tz": "Asia\/Shanghai"
  },
  "ZSLG": {
      "icao": "ZSLG",
      "iata": "LYG",
      "name": "Lianyungang Airport",
      "city": "Lianyungang",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 0,
      "lat": 34.571667,
      "lon": 118.873611,
      "tz": "Asia\/Shanghai"
  },
  "ZSLQ": {
      "icao": "ZSLQ",
      "iata": "HYN",
      "name": "Huangyan Luqiao Airport",
      "city": "Huangyan",
      "state": "Zhejiang",
      "country": "CN",
      "elevation": 0,
      "lat": 28.5622005463,
      "lon": 121.4290008545,
      "tz": "Asia\/Shanghai"
  },
  "ZSLY": {
      "icao": "ZSLY",
      "iata": "LYI",
      "name": "Shubuling Airport",
      "city": "Linyi",
      "state": "Shandong",
      "country": "CN",
      "elevation": 0,
      "lat": 35.0461006165,
      "lon": 118.4120025635,
      "tz": "Asia\/Shanghai"
  },
  "ZSNB": {
      "icao": "ZSNB",
      "iata": "NGB",
      "name": "Ningbo Lishe International Airport",
      "city": "Ningbo",
      "state": "Zhejiang",
      "country": "CN",
      "elevation": 13,
      "lat": 29.8267002106,
      "lon": 121.4619979858,
      "tz": "Asia\/Shanghai"
  },
  "ZSNJ": {
      "icao": "ZSNJ",
      "iata": "NKG",
      "name": "Nanjing Lukou Airport",
      "city": "Nanjing",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 49,
      "lat": 31.7420005798,
      "lon": 118.8619995117,
      "tz": "Asia\/Shanghai"
  },
  "ZSNT": {
      "icao": "ZSNT",
      "iata": "NTG",
      "name": "Nantong Airport",
      "city": "Nantong",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 16,
      "lat": 32.0708007812,
      "lon": 120.975997925,
      "tz": "Asia\/Shanghai"
  },
  "ZSOF": {
      "icao": "ZSOF",
      "iata": "HFE",
      "name": "Hefei Xinqiao International Airport",
      "city": "Hefei",
      "state": "Anhui",
      "country": "CN",
      "elevation": 207,
      "lat": 31.98898,
      "lon": 116.9638,
      "tz": "Asia\/Shanghai"
  },
  "ZSPD": {
      "icao": "ZSPD",
      "iata": "PVG",
      "name": "Shanghai Pudong International Airport",
      "city": "Shanghai",
      "state": "Shanghai",
      "country": "CN",
      "elevation": 13,
      "lat": 31.1434001923,
      "lon": 121.8050003052,
      "tz": "Asia\/Shanghai"
  },
  "ZSQD": {
      "icao": "ZSQD",
      "iata": "TAO",
      "name": "Liuting Airport",
      "city": "Qingdao",
      "state": "Shandong",
      "country": "CN",
      "elevation": 33,
      "lat": 36.2661018372,
      "lon": 120.374000549,
      "tz": "Asia\/Shanghai"
  },
  "ZSQZ": {
      "icao": "ZSQZ",
      "iata": "JJN",
      "name": "Quanzhou Airport",
      "city": "Quanzhou",
      "state": "Fujian",
      "country": "CN",
      "elevation": 0,
      "lat": 24.7964000702,
      "lon": 118.5899963379,
      "tz": "Asia\/Shanghai"
  },
  "ZSRG": {
      "icao": "ZSRG",
      "iata": "RUG",
      "name": "Rugao Air Base",
      "city": "Rugao",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 0,
      "lat": 32.2578849792,
      "lon": 120.5016555786,
      "tz": "Asia\/Shanghai"
  },
  "ZSSH": {
      "icao": "ZSSH",
      "iata": "HIA",
      "name": "Lianshui Airport",
      "city": "Huai'an",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 28,
      "lat": 33.7908333333,
      "lon": 119.125,
      "tz": "Asia\/Shanghai"
  },
  "ZSSL": {
      "icao": "ZSSL",
      "iata": "",
      "name": "Shanghai Longhua Airport",
      "city": "Shanghai",
      "state": "Shanghai",
      "country": "CN",
      "elevation": 0,
      "lat": 31.1669006348,
      "lon": 121.45400238,
      "tz": "Asia\/Shanghai"
  },
  "ZSSM": {
      "icao": "ZSSM",
      "iata": "SQJ",
      "name": "Sanming Shaxian Airport",
      "city": "Sanming",
      "state": "Fujian",
      "country": "CN",
      "elevation": 889,
      "lat": 26.428056,
      "lon": 117.845,
      "tz": "Asia\/Shanghai"
  },
  "ZSSS": {
      "icao": "ZSSS",
      "iata": "SHA",
      "name": "Shanghai Hongqiao International Airport",
      "city": "Shanghai",
      "state": "Shanghai",
      "country": "CN",
      "elevation": 10,
      "lat": 31.1979007721,
      "lon": 121.3359985352,
      "tz": "Asia\/Shanghai"
  },
  "ZSSZ": {
      "icao": "ZSSZ",
      "iata": "SZV",
      "name": "Guangfu Airport",
      "city": "Suzhou",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 0,
      "lat": 31.2630996704,
      "lon": 120.4010009766,
      "tz": "Asia\/Shanghai"
  },
  "ZSTX": {
      "icao": "ZSTX",
      "iata": "TXN",
      "name": "Tunxi International Airport",
      "city": "Huangshan",
      "state": "Anhui",
      "country": "CN",
      "elevation": 0,
      "lat": 29.7332992554,
      "lon": 118.2559967041,
      "tz": "Asia\/Shanghai"
  },
  "ZSWF": {
      "icao": "ZSWF",
      "iata": "WEF",
      "name": "Weifang Airport",
      "city": "Weifang",
      "state": "Shandong",
      "country": "CN",
      "elevation": 0,
      "lat": 36.6467018127,
      "lon": 119.1190032959,
      "tz": "Asia\/Shanghai"
  },
  "ZSWH": {
      "icao": "ZSWH",
      "iata": "WEH",
      "name": "Weihai Airport",
      "city": "Weihai",
      "state": "Shandong",
      "country": "CN",
      "elevation": 145,
      "lat": 37.1870994568,
      "lon": 122.2289962769,
      "tz": "Asia\/Shanghai"
  },
  "ZSWU": {
      "icao": "ZSWU",
      "iata": "WHU",
      "name": "Wuhu Air Base",
      "city": "Wuhu",
      "state": "Anhui",
      "country": "CN",
      "elevation": 0,
      "lat": 31.3906002045,
      "lon": 118.408996582,
      "tz": "Asia\/Shanghai"
  },
  "ZSWX": {
      "icao": "ZSWX",
      "iata": "WUX",
      "name": "Sunan Shuofang International Airport",
      "city": "Wuxi",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 24,
      "lat": 31.4944000244,
      "lon": 120.429000854,
      "tz": "Asia\/Shanghai"
  },
  "ZSWY": {
      "icao": "ZSWY",
      "iata": "WUS",
      "name": "Nanping Wuyishan Airport",
      "city": "Wuyishan",
      "state": "Fujian",
      "country": "CN",
      "elevation": 614,
      "lat": 27.7019004822,
      "lon": 118.0009994507,
      "tz": "Asia\/Shanghai"
  },
  "ZSWZ": {
      "icao": "ZSWZ",
      "iata": "WNZ",
      "name": "Wenzhou Yongqiang Airport",
      "city": "Wenzhou",
      "state": "Zhejiang",
      "country": "CN",
      "elevation": 0,
      "lat": 27.9122009277,
      "lon": 120.8519973755,
      "tz": "Asia\/Shanghai"
  },
  "ZSXZ": {
      "icao": "ZSXZ",
      "iata": "XUZ",
      "name": "Xuzhou Guanyin Airport",
      "city": "Xuzhou",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 0,
      "lat": 34.059056,
      "lon": 117.555278,
      "tz": "Asia\/Shanghai"
  },
  "ZSYN": {
      "icao": "ZSYN",
      "iata": "YNZ",
      "name": "Yancheng Airport",
      "city": "Yancheng",
      "state": "Jiangsu",
      "country": "CN",
      "elevation": 0,
      "lat": 33.425833,
      "lon": 120.203056,
      "tz": "Asia\/Shanghai"
  },
  "ZSYT": {
      "icao": "ZSYT",
      "iata": "YNT",
      "name": "Yantai Laishan Airport",
      "city": "Yantai",
      "state": "Shandong",
      "country": "CN",
      "elevation": 59,
      "lat": 37.4016990662,
      "lon": 121.3720016479,
      "tz": "Asia\/Shanghai"
  },
  "ZSYW": {
      "icao": "ZSYW",
      "iata": "YIW",
      "name": "Yiwu Airport",
      "city": "Yiwu",
      "state": "Zhejiang",
      "country": "CN",
      "elevation": 262,
      "lat": 29.3446998596,
      "lon": 120.031997681,
      "tz": "Asia\/Shanghai"
  },
  "ZSZS": {
      "icao": "ZSZS",
      "iata": "HSN",
      "name": "Zhoushan Airport",
      "city": "Zhoushan",
      "state": "Zhejiang",
      "country": "CN",
      "elevation": 3,
      "lat": 29.9342002869,
      "lon": 122.361999512,
      "tz": "Asia\/Shanghai"
  },
  "ZUAL": {
      "icao": "ZUAL",
      "iata": "NGQ",
      "name": "Ngari Gunsa Airport",
      "city": "Shiquanhe",
      "state": "Tibet",
      "country": "CN",
      "elevation": 14022,
      "lat": 32.1,
      "lon": 80.0530555556,
      "tz": "Asia\/Shanghai"
  },
  "ZUAS": {
      "icao": "ZUAS",
      "iata": "AVA",
      "name": "Anshun Huangguoshu Airport",
      "city": "Anshun",
      "state": "Beijing",
      "country": "CN",
      "elevation": 4812,
      "lat": 26.2605555556,
      "lon": 105.873333333,
      "tz": "Asia\/Shanghai"
  },
  "ZUBD": {
      "icao": "ZUBD",
      "iata": "BPX",
      "name": "Qamdo Bangda Airport",
      "city": "Bangda",
      "state": "Tibet",
      "country": "CN",
      "elevation": 14219,
      "lat": 30.5536003113,
      "lon": 97.1082992554,
      "tz": "Asia\/Shanghai"
  },
  "ZUCK": {
      "icao": "ZUCK",
      "iata": "CKG",
      "name": "Chongqing Jiangbei International Airport",
      "city": "Chongqing",
      "state": "Chongqing",
      "country": "CN",
      "elevation": 1365,
      "lat": 29.7192001343,
      "lon": 106.641998291,
      "tz": "Asia\/Shanghai"
  },
  "ZUDX": {
      "icao": "ZUDX",
      "iata": "DAX",
      "name": "Dachuan Airport",
      "city": "Dazhou",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 0,
      "lat": 31.1302,
      "lon": 107.4295,
      "tz": "Asia\/Shanghai"
  },
  "ZUGU": {
      "icao": "ZUGU",
      "iata": "GYS",
      "name": "Guangyuan Airport",
      "city": "Guangyuan",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 0,
      "lat": 32.3911018372,
      "lon": 105.702003479,
      "tz": "Asia\/Shanghai"
  },
  "ZUGY": {
      "icao": "ZUGY",
      "iata": "KWE",
      "name": "Longdongbao Airport",
      "city": "Guiyang",
      "state": "Guizhou",
      "country": "CN",
      "elevation": 3736,
      "lat": 26.5384998322,
      "lon": 106.8010025024,
      "tz": "Asia\/Shanghai"
  },
  "ZUJZ": {
      "icao": "ZUJZ",
      "iata": "JZH",
      "name": "Jiuzhai Huanglong Airport",
      "city": "Jiuzhaigou",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 11327,
      "lat": 32.8533333333,
      "lon": 103.682222222,
      "tz": "Asia\/Shanghai"
  },
  "ZULP": {
      "icao": "ZULP",
      "iata": "LIA",
      "name": "Liangping Airport",
      "city": "Liangping",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 0,
      "lat": 30.6793994904,
      "lon": 107.7860031128,
      "tz": "Asia\/Shanghai"
  },
  "ZULS": {
      "icao": "ZULS",
      "iata": "LXA",
      "name": "Lhasa Gonggar Airport",
      "city": "Lhasa",
      "state": "Tibet",
      "country": "CN",
      "elevation": 13136,
      "lat": 29.2978000641,
      "lon": 90.9119033813,
      "tz": "Asia\/Shanghai"
  },
  "ZULZ": {
      "icao": "ZULZ",
      "iata": "LZO",
      "name": "Luzhou Airport",
      "city": "Luzhou",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 0,
      "lat": 28.8521995544,
      "lon": 105.3929977417,
      "tz": "Asia\/Shanghai"
  },
  "ZUMY": {
      "icao": "ZUMY",
      "iata": "MIG",
      "name": "Mianyang Airport",
      "city": "Mianyang",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 0,
      "lat": 31.4281005859,
      "lon": 104.7409973145,
      "tz": "Asia\/Shanghai"
  },
  "ZUNC": {
      "icao": "ZUNC",
      "iata": "NAO",
      "name": "Nanchong Airport",
      "city": "Nanchong",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 0,
      "lat": 30.79545,
      "lon": 106.1626,
      "tz": "Asia\/Shanghai"
  },
  "ZUNZ": {
      "icao": "ZUNZ",
      "iata": "LZY",
      "name": "Nyingchi Airport",
      "city": "Nyingchi",
      "state": "Tibet",
      "country": "CN",
      "elevation": 9675,
      "lat": 29.3033008575,
      "lon": 94.3352966309,
      "tz": "Asia\/Shanghai"
  },
  "ZUQJ": {
      "icao": "ZUQJ",
      "iata": "JIQ",
      "name": "Qianjiang Wulingshan Airport",
      "city": "Chongqing",
      "state": "Chongqing",
      "country": "CN",
      "elevation": 1365,
      "lat": 29.514559,
      "lon": 108.83371499999998,
      "tz": "Asia\/Shanghai"
  },
  "ZUTC": {
      "icao": "ZUTC",
      "iata": "TCZ",
      "name": "Tengchong Tuofeng Airport",
      "city": "Tengchong",
      "state": "Yunnan",
      "country": "CN",
      "elevation": 6250,
      "lat": 24.9380555556,
      "lon": 98.4858333333,
      "tz": "Asia\/Shanghai"
  },
  "ZUTF": {
      "icao": "ZUTF",
      "iata": "TFU",
      "name": "Chengdu Tianfu International Airport",
      "city": "Chengdu",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 1444,
      "lat": 30.319,
      "lon": 104.445,
      "tz": "Asia\/Shanghai"
  },
  "ZUTR": {
      "icao": "ZUTR",
      "iata": "TEN",
      "name": "Tongren Fenghuang Airport",
      "city": "",
      "state": "Hunan",
      "country": "CN",
      "elevation": 0,
      "lat": 27.883333,
      "lon": 109.308889,
      "tz": "Asia\/Shanghai"
  },
  "ZUUU": {
      "icao": "ZUUU",
      "iata": "CTU",
      "name": "Chengdu Shuangliu International Airport",
      "city": "Chengdu",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 1625,
      "lat": 30.5785007477,
      "lon": 103.9469985962,
      "tz": "Asia\/Shanghai"
  },
  "ZUWX": {
      "icao": "ZUWX",
      "iata": "WXN",
      "name": "Wanxian Airport",
      "city": "Wanxian",
      "state": "Chongqing",
      "country": "CN",
      "elevation": 0,
      "lat": 30.8017,
      "lon": 108.433,
      "tz": "Asia\/Shanghai"
  },
  "ZUXC": {
      "icao": "ZUXC",
      "iata": "XIC",
      "name": "Xichang Qingshan Airport",
      "city": "Xichang",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 5112,
      "lat": 27.9890995026,
      "lon": 102.1839981079,
      "tz": "Asia\/Shanghai"
  },
  "ZUYB": {
      "icao": "ZUYB",
      "iata": "YBP",
      "name": "Yibin Caiba Airport",
      "city": "Yibin",
      "state": "Sichuan",
      "country": "CN",
      "elevation": 924,
      "lat": 28.8005555556,
      "lon": 104.545,
      "tz": "Asia\/Shanghai"
  },
  "ZUYI": {
      "icao": "ZUYI",
      "iata": "ACX",
      "name": "Xingyi Airport",
      "city": "Xingyi",
      "state": "Guizhou",
      "country": "CN",
      "elevation": 4150,
      "lat": 25.0863888889,
      "lon": 104.959444444,
      "tz": "Asia\/Shanghai"
  },
  "ZUZY": {
      "icao": "ZUZY",
      "iata": "ZYI",
      "name": "Zunyi Xinzhou Airport",
      "city": "Zunyi",
      "state": "Guizhou",
      "country": "CN",
      "elevation": 2920,
      "lat": 27.5895,
      "lon": 107.0007,
      "tz": "Asia\/Shanghai"
  },
  "ZWAK": {
      "icao": "ZWAK",
      "iata": "AKU",
      "name": "Aksu Airport",
      "city": "Aksu",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 0,
      "lat": 41.2625007629,
      "lon": 80.2917022705,
      "tz": "Asia\/Shanghai"
  },
  "ZWAT": {
      "icao": "ZWAT",
      "iata": "AAT",
      "name": "Altay Air Base",
      "city": "Altay",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 2460,
      "lat": 47.7498855591,
      "lon": 88.0858078003,
      "tz": "Asia\/Shanghai"
  },
  "ZWBL": {
      "icao": "ZWBL",
      "iata": "BPL",
      "name": "Alashankou Bole (Bortala) airport",
      "city": "Bole",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 1253,
      "lat": 44.895,
      "lon": 82.3,
      "tz": "Asia\/Shanghai"
  },
  "ZWCM": {
      "icao": "ZWCM",
      "iata": "IQM",
      "name": "Qiemo Airport",
      "city": "Qiemo",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 4108,
      "lat": 38.1493988037,
      "lon": 85.5327987671,
      "tz": "Asia\/Shanghai"
  },
  "ZWHM": {
      "icao": "ZWHM",
      "iata": "HMI",
      "name": "Hami Airport",
      "city": "Hami",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 2703,
      "lat": 42.8414001465,
      "lon": 93.6691970825,
      "tz": "Asia\/Shanghai"
  },
  "ZWKC": {
      "icao": "ZWKC",
      "iata": "KCA",
      "name": "Kuqa Airport",
      "city": "Kuqa",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 3524,
      "lat": 41.7181015015,
      "lon": 82.9869003296,
      "tz": "Asia\/Shanghai"
  },
  "ZWKL": {
      "icao": "ZWKL",
      "iata": "KRL",
      "name": "Korla Airport",
      "city": "Korla",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 0,
      "lat": 41.6977996826,
      "lon": 86.1288986206,
      "tz": "Asia\/Shanghai"
  },
  "ZWKM": {
      "icao": "ZWKM",
      "iata": "KRY",
      "name": "Karamay Airport",
      "city": "Karamay",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 0,
      "lat": 45.46655,
      "lon": 84.9527,
      "tz": "Asia\/Shanghai"
  },
  "ZWSH": {
      "icao": "ZWSH",
      "iata": "KHG",
      "name": "Kashgar Airport",
      "city": "Kashgar",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 4529,
      "lat": 39.5429000854,
      "lon": 76.0199966431,
      "tz": "Asia\/Shanghai"
  },
  "ZWSS": {
      "icao": "ZWSS",
      "iata": "SXJ",
      "name": "Shanshan Airport",
      "city": "Shanshan",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 0,
      "lat": 42.9117012024,
      "lon": 90.2474975586,
      "tz": "Asia\/Shanghai"
  },
  "ZWTC": {
      "icao": "ZWTC",
      "iata": "TCG",
      "name": "Tacheng Airport",
      "city": "Tacheng",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 0,
      "lat": 46.6725006104,
      "lon": 83.3407974243,
      "tz": "Asia\/Shanghai"
  },
  "ZWTN": {
      "icao": "ZWTN",
      "iata": "HTN",
      "name": "Hotan Airport",
      "city": "Hotan",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 4672,
      "lat": 37.0385017395,
      "lon": 79.8648986816,
      "tz": "Asia\/Shanghai"
  },
  "ZWWW": {
      "icao": "ZWWW",
      "iata": "URC",
      "name": "Urumqi Diwopu International Airport",
      "city": "Urumqi",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 2125,
      "lat": 43.9071006775,
      "lon": 87.4741973877,
      "tz": "Asia\/Shanghai"
  },
  "ZWYN": {
      "icao": "ZWYN",
      "iata": "YIN",
      "name": "Yining Airport",
      "city": "Yining",
      "state": "Xinjiang",
      "country": "CN",
      "elevation": 0,
      "lat": 43.9557991028,
      "lon": 81.3302993774,
      "tz": "Asia\/Shanghai"
  },
  "ZYAS": {
      "icao": "ZYAS",
      "iata": "AOG",
      "name": "Anshan Air Base",
      "city": "Anshan",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 0,
      "lat": 41.1053009033,
      "lon": 122.8539962769,
      "tz": "Asia\/Shanghai"
  },
  "ZYCC": {
      "icao": "ZYCC",
      "iata": "CGQ",
      "name": "Longjia Airport",
      "city": "Changchun",
      "state": "Jilin",
      "country": "CN",
      "elevation": 706,
      "lat": 43.9962005615,
      "lon": 125.684997559,
      "tz": "Asia\/Shanghai"
  },
  "ZYCH": {
      "icao": "ZYCH",
      "iata": "CNI",
      "name": "Changhai Airport",
      "city": "Changhai",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 80,
      "lat": 39.2666666667,
      "lon": 122.666944444,
      "tz": "Asia\/Shanghai"
  },
  "ZYCY": {
      "icao": "ZYCY",
      "iata": "CHG",
      "name": "Chaoyang Airport",
      "city": "Chaoyang",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 0,
      "lat": 41.5381011963,
      "lon": 120.4349975586,
      "tz": "Asia\/Shanghai"
  },
  "ZYDD": {
      "icao": "ZYDD",
      "iata": "DDG",
      "name": "Dandong Airport",
      "city": "Dandong",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 0,
      "lat": 40.0247001648,
      "lon": 124.286003113,
      "tz": "Asia\/Shanghai"
  },
  "ZYDQ": {
      "icao": "ZYDQ",
      "iata": "DQA",
      "name": "Saertu Airport",
      "city": "Daqing Shi",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 496,
      "lat": 46.7463888889,
      "lon": 125.140555556,
      "tz": "Asia\/Shanghai"
  },
  "ZYHB": {
      "icao": "ZYHB",
      "iata": "HRB",
      "name": "Taiping Airport",
      "city": "Harbin",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 457,
      "lat": 45.6234016418,
      "lon": 126.25,
      "tz": "Asia\/Shanghai"
  },
  "ZYHE": {
      "icao": "ZYHE",
      "iata": "HEK",
      "name": "Heihe Airport",
      "city": "Heihe",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 8530,
      "lat": 50.1716209371,
      "lon": 127.308883667,
      "tz": "Asia\/Shanghai"
  },
  "ZYJL": {
      "icao": "ZYJL",
      "iata": "JIL",
      "name": "Jilin Airport",
      "city": "Jilin",
      "state": "Jilin",
      "country": "CN",
      "elevation": 0,
      "lat": 44.0022010803,
      "lon": 126.3960037231,
      "tz": "Asia\/Shanghai"
  },
  "ZYJM": {
      "icao": "ZYJM",
      "iata": "JMU",
      "name": "Jiamusi Airport",
      "city": "Jiamusi",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 262,
      "lat": 46.8433990479,
      "lon": 130.464996338,
      "tz": "Asia\/Shanghai"
  },
  "ZYJZ": {
      "icao": "ZYJZ",
      "iata": "JNZ",
      "name": "Jinzhou Airport",
      "city": "Jinzhou",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 0,
      "lat": 41.101398468,
      "lon": 121.06199646,
      "tz": "Asia\/Shanghai"
  },
  "ZYLD": {
      "icao": "ZYLD",
      "iata": "LDS",
      "name": "Lindu Airport",
      "city": "Yichun",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 791,
      "lat": 47.7520555556,
      "lon": 129.019125,
      "tz": "Asia\/Shanghai"
  },
  "ZYLS": {
      "icao": "ZYLS",
      "iata": "YUS",
      "name": "Yushu Batang Airport",
      "city": "Yushu",
      "state": "Qinghai",
      "country": "CN",
      "elevation": 12816,
      "lat": 32.8363888889,
      "lon": 97.0363888889,
      "tz": "Asia\/Shanghai"
  },
  "ZYMD": {
      "icao": "ZYMD",
      "iata": "MDG",
      "name": "Mudanjiang Hailang International Airport",
      "city": "Mudanjiang",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 883,
      "lat": 44.5241012573,
      "lon": 129.569000244,
      "tz": "Asia\/Shanghai"
  },
  "ZYMH": {
      "icao": "ZYMH",
      "iata": "OHE",
      "name": "Gu-Lian Airport",
      "city": "Mohe",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 1836,
      "lat": 52.9127777778,
      "lon": 122.43,
      "tz": "Asia\/Shanghai"
  },
  "ZYQQ": {
      "icao": "ZYQQ",
      "iata": "NDG",
      "name": "Qiqihar Sanjiazi Airport",
      "city": "Qiqihar",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 477,
      "lat": 47.2396011353,
      "lon": 123.9179992676,
      "tz": "Asia\/Shanghai"
  },
  "ZYTH": {
      "icao": "ZYTH",
      "iata": "",
      "name": "Tahe Airport",
      "city": "Tahe",
      "state": "Heilongjiang",
      "country": "CN",
      "elevation": 1240,
      "lat": 52.2244444444,
      "lon": 124.720222222,
      "tz": "Asia\/Shanghai"
  },
  "ZYTL": {
      "icao": "ZYTL",
      "iata": "DLC",
      "name": "Zhoushuizi Airport",
      "city": "Dalian",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 107,
      "lat": 38.9656982422,
      "lon": 121.5390014648,
      "tz": "Asia\/Shanghai"
  },
  "ZYTN": {
      "icao": "ZYTN",
      "iata": "TNH",
      "name": "Tonghua Sanyuanpu Airport",
      "city": "Tonghua",
      "state": "Jilin",
      "country": "CN",
      "elevation": 1200,
      "lat": 42.2538888889,
      "lon": 125.703333333,
      "tz": "Asia\/Shanghai"
  },
  "ZYTX": {
      "icao": "ZYTX",
      "iata": "SHE",
      "name": "Taoxian Airport",
      "city": "Shenyang",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 198,
      "lat": 41.6398010254,
      "lon": 123.483001709,
      "tz": "Asia\/Shanghai"
  },
  "ZYXC": {
      "icao": "ZYXC",
      "iata": "XEN",
      "name": "Xingcheng Air Base",
      "city": "",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 30,
      "lat": 40.5802993774,
      "lon": 120.697998047,
      "tz": "Asia\/Shanghai"
  },
  "ZYYJ": {
      "icao": "ZYYJ",
      "iata": "YNJ",
      "name": "Yanji Chaoyangchuan Airport",
      "city": "Yanji",
      "state": "Jilin",
      "country": "CN",
      "elevation": 624,
      "lat": 42.8828010559,
      "lon": 129.451004028,
      "tz": "Asia\/Shanghai"
  },
  "ZYYY": {
      "icao": "ZYYY",
      "iata": "",
      "name": "Shenyang Dongta Airport",
      "city": "Shenyang",
      "state": "Liaoning",
      "country": "CN",
      "elevation": 0,
      "lat": 41.7844009399,
      "lon": 123.4960021973,
      "tz": "Asia\/Shanghai"
  }
}

const airports = Object.values(airportsMap)

// https://en.wikivoyage.org/wiki/Metropolitan_area_airport_codes
const codeMaps = {
  BJS: 'PEK',
  JKT: 'CGK',
  OSA: 'KIX',
  SPK: 'OKD',
  SEL: 'ICN',
  TYO: 'HND',
  BUH: 'OTP',
  EAP: 'BSL',
  LON: 'LHR',
  MIL: 'MXP',
  MOW: 'SVO',
  PAR: 'CDG',
  ROM: 'FCO',
  STO: 'ARN',
  CHI: 'ORD',
  DTT: 'DTW',
  QHO: 'IAH',
  QLA: 'LAX',
  QMI: 'MIA',
  YMQ: 'YUL',
  NYC: 'JFK',
  QSF: 'SFO',
  YTO: 'YYZ',
  WAS: 'IAD',
  BUE: 'EZE',
  RIO: 'CIG',
  SAO: 'GRU',

  TCI: 'TFS',
}

export default function getAirport(code) {
  const upperCode = code.toUpperCase()
  const _code = codeMaps[upperCode] || upperCode
  return airports.find(airport => airport.iata === _code)
}